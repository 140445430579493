<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
// const allowNumberKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
// const allowActionKeys = ['Backspace', 'Delete', 'Enter'];

const props = withDefaults(
  defineProps<{
    value: string;
    separator: string;
    focusOn: boolean;
    inputClasses: string;
    shouldAutoFocus: boolean;
    inputType: 'number' | 'tel' | 'password' | 'text';
    isLastChild: boolean;
    maxLength?: number;
  }>(),
  {
    shouldAutoFocus: true,
    inputType: 'number',
    maxLength: 4,
  }
);

const emit = defineEmits([
  'onChange',
  'onKeydown',
  'onPaste',
  'onFocus',
  'onBlur',
]);
const signleInputModel = ref('');

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      signleInputModel.value = newValue;
    }
  }
);
watch(
  () => props.focusOn,
  (newFocusValue, oldFocusValue) => {
    // Check if focusedInput changed
    // Prevent calling function if input already in focus
    if (oldFocusValue !== newFocusValue && inputRef.value && newFocusValue) {
      inputRef.value?.focus();
      inputRef.value?.select();
    }
  }
);

const inputRef = ref(null as any);
onMounted(() => {
  if (inputRef.value && props.focusOn && props.shouldAutoFocus) {
    inputRef.value?.focus();
  }
});

const handleOnChange = (event) => {
  const inputValue = event?.target?.value;
  if (inputValue.toString()?.length > props.maxLength)
    signleInputModel.value = inputValue.toString().slice(0, props.maxLength);

  return emit('onChange', signleInputModel.value);
};
const handleOnKeyDown = (event) => {
  emit('onKeydown', event);
};
const handleOnPaste = (event) => {
  return emit('onPaste', event);
};
const handleOnFocus = () => {
  inputRef.value?.select();
  return emit('onFocus');
};
const handleOnBlur = () => {
  return emit('onBlur');
};
</script>
<template>
  <div style="display: flex; align-items: center">
    <input
      ref="inputRef"
      v-model="signleInputModel"
      class="
        bg-white
        h-12
        text-xl text-current-600
        font-medium
        flex
        items-center
        text-center
        border border-gray-200
        focus:outline-none focus:shadow-current focus:border-current-100
        rounded
      "
      style="width: 6rem"
      :type="inputType"
      :maxlength="maxLength"
      :class="inputClasses"
      @input="handleOnChange"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    />
    <span v-if="!isLastChild && separator">
      <span class="pl-3" v-html="separator"></span>
    </span>
  </div>
</template>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
