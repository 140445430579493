<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m22.25 0h-20.5c-.965 0-1.75.785-1.75 1.75v2.5c0 .965.785 1.75 1.75 1.75h20.5c.965 0 1.75-.785 1.75-1.75v-2.5c0-.965-.785-1.75-1.75-1.75z"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m.75 11.5h14.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75z"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m.75 14.5h14.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75z"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m.75 20.5h14.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75z"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m.75 23.5h14.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75z"
        data-original="#000000"
        class=""
      ></path>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="21"
        cy="12"
        r="3"
        data-original="#000000"
        class=""
      ></circle>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="21"
        cy="21"
        r="3"
        data-original="#000000"
        class=""
      ></circle>
    </g>
  </svg>
</template>
