<script setup lang="ts">
import { DayoffExceptionType } from '@/domain/enums/DayoffEnums';
import { DayoffExceptionTypeUI } from './dayoff-ui-constant';
import { computed } from 'vue';

const props = defineProps<{
  exceptionType: DayoffExceptionType;
  size?: 'small' | null;
  isActive?: boolean;
  customClass?: 'border';
}>();

const classNameBySize = computed(() => {
  if (props.size == 'small') return 'h-4 px-2 text-2xs';
  if (props.size == 'middle') return 'h-6 px-2 text-xs';
  return 'px-1.5 py-1.5 text-sm';
});
</script>

<template>
  <div
    v-if="exceptionType"
    class="rounded flex-center border space-x-2"
    :class="classNameBySize"
    :style="
      isActive
        ? {
            backgroundColor: DayoffExceptionTypeUI[exceptionType]?.color,
            borderColor: DayoffExceptionTypeUI[exceptionType]?.color,
          }
        : {
            backgroundColor:
              DayoffExceptionTypeUI[exceptionType]?.backgroundColor,
            borderColor: DayoffExceptionTypeUI[exceptionType]?.color,
          }
    "
  >
    <span
      :style="
        isActive
          ? {
              color: '#fff',
            }
          : {
              color: DayoffExceptionTypeUI[exceptionType]?.color,
            }
      "
    >
      {{
        $t(DayoffExceptionTypeUI[exceptionType]?.textCode) ||
        DayoffExceptionTypeUI[exceptionType]?.textDefault
      }}
    </span>
  </div>
</template>
