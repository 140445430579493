import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import PrincipleService from '@/application/services/PrincipleService';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { ignoreUnicode } from '@/ui/plugins/utils';
import dayjs from 'dayjs';

const enableSortMultiple = false;
export default defineStore({
    id: storeId.principle,
    state: () => ({
        _taskService: TaskService.getInstance(),
        _principleService: PrincipleService.getInstance(),
        startLocalTime: 0,
        startWorkingStatus: '',
        startWorkingLocation: null,
        isStarting: false,
        checkinStatus: 'INIT',

        isLoading: false,
        overviewTask: [],
        planToTreatTasks: [],
        planToFinishTasks: [],
        overviewWorkInfo: [],
        dailyActions: {},
        userWorkingStatus: {},

        sortList: [] as any[],
        usersPrinciple: [] as any[],
        detailByUserId: {},
        statisticUserPrinciple: {},
        timeLimit: '',
        timeKeepingList: [],
    }),
    getters: {
        sortDataOrderByKey() {
            let result = {};
            const sortLength = this.sortList?.length;
            if (sortLength > 0) {
                const isShowIndex = sortLength > 1;
                result = this.sortList.reduce(
                    (previousValue, currentValue: any, index) => {
                        previousValue = {
                            ...previousValue,
                            ...{
                                [currentValue.key]: {
                                    sortIndex: isShowIndex ? index + 1 : null,
                                    orderBy: currentValue.orderBy,
                                },
                            },
                        };
                        return previousValue;
                    },
                    {}
                );
            }

            return result;
        },
    },
    actions: {
        async setStartLocalTime() {
            const res: any =
                await PrincipleService.getInstance().getCurrentTime();

            this.startLocalTime = new Date(res.result).getTime();
        },

        setStartWorkingStatus(status: string) {
            this.startWorkingStatus = status;
        },

        setStartWorkingLocation(location: any) {
            this.startWorkingLocation = location;
        },

        changeStarting(data: boolean) {
            this.isStarting = data;
        },

        changeCheckinStatus(status: string) {
            this.checkinStatus = status;
        },

        // move from vuex
        async fetchOverviewTasks() {
            this.isLoading = true;
            this.overviewTask = await this._taskService.getTaskListOverview();
            this.isLoading = false;
        },

        async fetchPlanToTreatTasks() {
            this.planToTreatTasks =
                await this._taskService.getTaskListPlannedToTreat();
        },

        async fetchPlanToFinishTasks() {
            this.planToFinishTasks =
                await this._taskService.getTaskListPlannedToFinish();
        },

        async fetchDailyActions() {
            this.isLoading = true;
            this.dailyActions = await this._principleService.getDailyActions();
            this.isLoading = false;
        },

        async getPrincipleTimeLimit() {
            const res = await this._principleService.getPrincipleTimeLimit();
            this.timeLimit = res['result'];
        },

        async fetchUserWorkingStatus(isLoading = false) {
            if (isLoading) {
                this.isLoading = true;
            }
            const data = await this._principleService.getUserWorkingStatus();

            const currentUtcTime = Date.now();

            const originLastTimeCheckin = data?.lastTimeCheckin
                ? new Date(data?.lastTimeCheckin)
                : new Date();
            const lastTimeCheckin =
                originLastTimeCheckin.getTime() -
                originLastTimeCheckin.getTimezoneOffset() * 60000;

            if (lastTimeCheckin > 0 && data.userStatus == 1)
                data.totalDurationTime += currentUtcTime - lastTimeCheckin;

            const originLastTimeCheckout = new Date(data.lastTimeCheckout);
            const lastTimeCheckout =
                originLastTimeCheckout.getTime() -
                originLastTimeCheckout.getTimezoneOffset() * 60000;

            if (lastTimeCheckout > 0 && data.userStatus == 0)
                data.totalLeaveTime += currentUtcTime - lastTimeCheckout;

            this.userWorkingStatus = data;
            this.isLoading = false;
        },

        async getAllPrinciple(date?: string | null) {
            await this.getPrincipleTimeLimit();
            const res =
                await this._principleService.getAllPrincipleByUserAndDay(date);
            const data = res['result'];
            let listUsersPrinciple = [];
            if (data) {
                const overviewTaskList = data?.overviewTaskList;
                listUsersPrinciple = data?.principle?.map((o) => {
                    o.timeLimit = o.idDay
                        ? dayjs
                              .utc(o?.idDay)
                              .add(1, 'day')
                              .startOf('day')
                              .subtract(7, 'hour')
                              .add(parseFloat(this.timeLimit), 'hour')
                              .format('YYYY-MM-DDTHH:mm:ss.SSS')
                        : null;
                    o.pauseList = arrayOrderBy(
                        data?.principlePauseDetail.filter(
                            (p) => p.principleId == o.id
                        ),
                        'checkIn',
                        'desc'
                    );
                    const totalTask = overviewTaskList.filter(
                        (t) => t.assigneeId == o.userId
                    ).length;
                    const countTaskOnday = overviewTaskList.filter(
                        (t) => t.assigneeId == o.userId && t.isPlannedToTreat
                    );
                    const countTaskOnFinish = overviewTaskList.filter(
                        (t) => t.assigneeId == o.userId && t.isPlannedToFinish
                    );
                    o.fullName = ignoreUnicode(o.fullname);
                    o.countTaskOnday = countTaskOnday.length;
                    o.countTaskOnFinish = countTaskOnFinish.length;
                    o.countTaskOnPending =
                        totalTask -
                        countTaskOnday.length -
                        countTaskOnFinish.length;
                    o.taskCodesOnday = countTaskOnday.map((t) => t.code) || [];
                    o.taskCodesOnFinish =
                        countTaskOnFinish.map((t) => t.code) || [];
                    return o;
                });
                this.usersPrinciple = listUsersPrinciple;
            }
            return this.usersPrinciple;
        },

        // onClickSort(key, name) {
        //   this.genSortDataList({ key, name });
        //   const iterate = this.sortList.map((o: any) => o.key),
        //     order = this.sortList.map((o: any) => o.orderBy);
        //   this.usersPrinciple = arrayOrderBy(this.usersPrinciple, iterate, order);
        //   console.log('usersPrinciple:', this.usersPrinciple);
        // },

        async getWorkInfoByDateRange(date) {
            const res = await this._principleService.getWorkInfoByDateRange(
                date
            );
            this.overviewWorkInfo = res['result'];
            this.overviewWorkInfo.forEach((el: any) =>
                (el.workInfo || []).reverse()
            );
            return this.overviewWorkInfo;
        },

        genSortDataList(sortObj) {
            const { key, name } = sortObj;
            let orderBy = 'asc';
            let sortData = [] as any[];
            const currentSortData: any = this.sortList;
            if (enableSortMultiple) {
                const indexExisted = currentSortData.findIndex(
                    (o: any) => o.key == key
                );
                if (indexExisted > -1) {
                    const currentOrderBy =
                        currentSortData[indexExisted].orderBy;
                    if (currentOrderBy == 'desc') {
                        sortData = sortData.filter((o: any) => o.key !== key);
                    } else {
                        orderBy = currentOrderBy == 'asc' ? 'desc' : 'asc';
                        sortData[indexExisted] = { key, name, orderBy };
                    }
                } else {
                    sortData = [...sortData, { key, name, orderBy }];
                }
            } else {
                const indexExisted = currentSortData.findIndex(
                    (o) => o.key == key
                );
                if (indexExisted > -1) {
                    const currentOrderBy =
                        currentSortData[indexExisted].orderBy;
                    if (currentOrderBy == 'asc') {
                        sortData = [];
                    } else {
                        orderBy = currentOrderBy == 'desc' ? 'asc' : 'desc';
                        sortData = [{ key, name, orderBy }];
                    }
                } else {
                    sortData = [{ key, name, orderBy: 'desc' }];
                }
            }
            this.sortList = sortData;

            this.onSortFilteredTasks();
        },

        onSortFilteredTasks() {
            if (this.sortList?.length > 0) {
                const iterate = [...this.sortList.map((o: any) => o.key)];
                const order = [...this.sortList.map((o: any) => o.orderBy)];
                this.usersPrinciple = arrayOrderBy(
                    this.usersPrinciple,
                    iterate,
                    order
                );
            } else {
                this.usersPrinciple = arrayOrderBy(
                    this.usersPrinciple,
                    'start',
                    'asc'
                );
            }
        },

        async getPrincipleDetail(userId, fromDate, toDate) {
            await this.getPrincipleTimeLimit();
            const res = await this._principleService.getPrincipleByUserId(
                userId,
                fromDate,
                toDate
            );
            const data = res['result'];
            if (data) {
                const dataDetail = data?.principle?.map((o) => {
                    o.timeLimit = o.idDay
                        ? dayjs
                              .utc(o?.idDay)
                              .add(1, 'day')
                              .startOf('day')
                              .subtract(o?.utcUser, 'hour')
                              .add(parseFloat(this.timeLimit), 'hour')
                              .format('YYYY-MM-DDTHH:mm:ss.SSS')
                        : null;
                    o.pauseList = arrayOrderBy(
                        data?.principlePauseDetail.filter(
                            (p) => p.principleId == o.id
                        ),
                        'checkIn',
                        'desc'
                    );
                    o.totalTaskOnDayList = data?.overviewTaskList.filter(
                        (e) =>
                            dayjs(e.planningDate).startOf('day').valueOf() ==
                                dayjs(o.utc).startOf('day').valueOf() &&
                            e.isPlannedToTreat
                    );
                    o.totalTaskFinishedOnDayList =
                        data?.overviewTaskList.filter(
                            (e) =>
                                dayjs(e.planningDate)
                                    .startOf('day')
                                    .valueOf() ==
                                    dayjs(o.utc).startOf('day').valueOf() &&
                                e.isPlannedToFinish
                        );

                    return o;
                });
                this.detailByUserId = {
                    ...this.detailByUserId,
                    [userId]: dataDetail,
                };
            }
        },

        async getUserPrincipleStatisticByRange(userId, fromDate, toDate) {
            const res =
                await this._principleService.getUserPrincipleStatisticByRange(
                    userId,
                    fromDate,
                    toDate
                );
            this.statisticUserPrinciple = res['result'] || {};
        },

        async getExportTimekeepings(data) {
            const res = await this._principleService.getExportTimekeepings(
                data
            );
            this.timeKeepingList = res['result'];
        },

        async exportTimekeepings(data) {
            const res = await this._principleService.exportTimekeepings(data);
            return res;
        },
    },
});
