<script setup lang="ts">
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
import { NotificationType } from '@/domain/enums/notification-enums';
import FilterNotification from '@/ui/modules/notification/FilterNotification.vue';
import bindingNotificationComposables from '@/ui/composables/notification/notification-list.composables';
import commonActionNotificationComposables from '@/ui/composables/notification/common-action-notification-composables';
import TaskDetail from '@/ui/modules/task/detail/TaskDetail.vue';
import { INotification } from '@/application/types/notification.types';
import DayoffDetail from '@/ui/components/dayoff/DayoffDetail.vue';
import GroupDetail from '@/ui/modules/group/detail/GroupDetail.vue';
import GedDetail from '@/ui/modules/ged/ged-modal/GedDetail.vue';
import { CalendarType } from '@/ui/common/constants/calendar';
import DetailViewMeeting from '@/ui/modules/calendar/detail-view-event/DetailViewMeeting.vue';
import DetailViewReminder from '@/ui/modules/calendar/detail-view-event/DetailViewReminder.vue';
import NotificationList from '@/ui/modules/notification/notification-list/NotificationList.vue';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const {
  rawNotifications,
  totalNotification,
  allNotifications,
  latestNotification,
  isLoadingMore,
  notificationTotalCount,
  isConditionReadAll,
  isLoading,
  category,
  notificationType,
  initData,
  onChangeCategory,
  onFilterUserIdNotification,
  onFilterGroupIdNotification,
  onFilterDomainIdNotification,
  onFilterTypesNotification,
  // loadMoreNotification,
  onShowOnlyUnread,
  // getNotificationsData,
  handleChangePagination,
  loadMoreNotification,
} = bindingNotificationComposables();

const {
  isOpenAlertReadAll,
  isLoadingMarkReadAll,
  onReadAllNotification,
  onMarkReadNotification,
  onDeleteNotification,
  handleUpdateUserNotifications,
  onClickNotification,
  onReactionNotification,
} = commonActionNotificationComposables({
  isPage: true,
});

const isOpenFilterNotification = ref(false);

const totalNotificationIsShowing = computed(
  () => allNotifications.value?.length
);

initData();

const currentNotification = ref<INotification>();

const handleClickNotification = (notification) => {
  if (!notification || !notification?.id) return;

  onClickNotification(notification);

  const index = allNotifications.value?.findIndex(
    (n) => n?.id == notification?.id
  );

  currentNotificationDetailIndex.value = index;

  currentNotification.value = notification;
};

const currentNotificationDetailIndex = ref<number>(-1);
const notificationContainerRef = ref<any>();

const findCurrentNotificationByIndex = () => {
  if (
    Number.isNaN(currentNotificationDetailIndex.value) ||
    currentNotificationDetailIndex.value < 0
  )
    return;

  currentNotification.value =
    allNotifications.value[currentNotificationDetailIndex.value];

  if (!currentNotification.value) return;
  if (!currentNotification.value?.status)
    handleUpdateUserNotifications(currentNotification.value?.id);

  const currentElement = document.getElementById(
    `notification-button-id_${currentNotification.value?.id}`
  );
  if (currentElement) {
    currentElement.focus();

    setTimeout(() => {
      currentElement.scrollIntoView(false);
    });
  }
};

const onNextToViewNotification = () => {
  currentNotificationDetailIndex.value =
    currentNotificationDetailIndex.value == notificationTotalCount.value
      ? 0
      : currentNotificationDetailIndex.value + 1;

  findCurrentNotificationByIndex();

  if (
    totalNotificationIsShowing.value - currentNotificationDetailIndex.value <
    5
  )
    handleChangePagination();
};
const onBackToViewNotification = () => {
  if (currentNotificationDetailIndex.value <= 0) return;

  currentNotificationDetailIndex.value =
    currentNotificationDetailIndex.value - 1;
  findCurrentNotificationByIndex();
};

const onKeydown = (key) => {
  switch (key) {
    case 'ArrowUp':
    case 'ArrowLeft':
      onBackToViewNotification();
      break;
    case 'ArrowDown':
    case 'ArrowRight':
      onNextToViewNotification();
      break;

    default:
      break;
  }
};
</script>

<template>
  <div
    ref="notificationContainerRef"
    class="
      bg-white
      h-full
      w-full
      m-auto
      flex flex-col
      rounded-lg
      divide-x
      relative
    "
  >
    <!-- header notification -->
    <div class="w-full py-2 bg-white">
      <div class="flex item-center justify-between border-b px-4">
        <div class="text-md font-medium flex space-x-3 py-3">
          <div class="space-x-2 items-center flex">
            <span class="text-current-800">{{
              $t('COMMON_NOTIFICATION_TITLE') || 'Notification'
            }}</span>
          </div>
        </div>

        <!-- TYPE NOTIFICATION -->
        <div class="flex item-center space-x-1">
          <div
            v-for="item in notificationType"
            :key="item.key"
            class="
              cursor-pointer
              flex-center
              pt-0.5
              pb-1.5
              px-1.5
              hover:text-current-500 hover:font-medium
              space-x-2
            "
            :class="
              category === item?.key
                ? 'border-b-2 border-current text-current-500 font-medium'
                : 'rounded text-gray-500'
            "
            @click.stop="onChangeCategory(item)"
          >
            <span class="text-sm">{{ $t(item?.label) }}</span>
            <span
              v-if="item?.totalUnread !== 0"
              class="px-1 text-xs rounded"
              :class="
                category == item.key
                  ? 'bg-current-500 text-white'
                  : 'bg-gray-100 text-gray-500'
              "
            >
              {{ item?.totalUnread }}
            </span>
          </div>
        </div>
        <div class="flex-center">
          <button
            v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
            class="
              flex-center
              rounded-full
              hover:fill-current
              fill-gray-500
              w-6
              h-6
            "
            @click="initData"
          >
            <SynIcon name="reload" class="w-4 h-4" />
          </button>
        </div>
      </div>

      <!-- ACTION IN TAB (SHOW UNREAD, MASK READ ALL) -->
      <div class="flex items-center justify-between py-2 px-4">
        <AtomSwitch
          v-model="notificationType[category].isOnlyUnread"
          @change="onShowOnlyUnread"
        >
          <span class="text-xs text-black">
            {{ $t('NOTIFICATION_ONLY_SHOW_UNREAD') || 'Only show unread' }}
          </span>
        </AtomSwitch>

        <div class="flex-center space-x-2">
          <div
            class="
              flex-center
              space-x-2
              w-max
              h-7
              py-1
              px-2
              text-sm
              border border-gray-50
              fill-gray-600
              text-gray-600
              rounded
              cursor-pointer
              hover:bg-current-50 hover:text-current-400 hover:fill-current-400
            "
            @click="isOpenAlertReadAll = true"
          >
            <SynIcon name="select-all-read" custom-class="w-4 h-4"></SynIcon>
            <span>{{
              $t('NOTIFICATION_MARK_READ_ALL') || 'I read it all'
            }}</span>
          </div>
          <FilterNotification
            :category="category"
            :actions-hide="['mark_all_as_reads']"
            @is-open-filter="(value) => (isOpenFilterNotification = value)"
            @on-mark-all-as-read="(value) => (isOpenAlertReadAll = value)"
            @on-filter-user="(userId) => onFilterUserIdNotification(userId)"
            @on-filter-group="(groupId) => onFilterGroupIdNotification(groupId)"
            @on-select-type="(type) => onFilterTypesNotification(type)"
            @on-filter-domain="
              (domainId) => onFilterDomainIdNotification(domainId)
            "
          />
        </div>
      </div>
    </div>

    <!-- content notification -->
    <div class="flex-1 min-h-0 overflow-hidden flex border-t">
      <!-- NOTIFICATION LIST -->
      <div class="flex-1 min-h-0 overflow-hidden flex flex-col">
        <NotificationList
          :all-notifications="allNotifications"
          :latest-notification="latestNotification"
          :current-notification="currentNotification"
          :is-loading="isLoading"
          :is-loading-more="isLoadingMore"
          :is-condition-read-all="isConditionReadAll"
          :category="category"
          :can-load-more="rawNotifications?.length < totalNotification"
          @on-load-more="loadMoreNotification"
          @on-click-notification="handleClickNotification"
          @on-mark-read-notification="onMarkReadNotification"
          @on-delete-notification="onDeleteNotification"
          @on-reaction-notification="onReactionNotification"
          @on-keydown="onKeydown"
        />
      </div>

      <!-- NOTIFICATION DETAIL -->
      <div
        class="border-l border-gray-100 h-full flex flex-col"
        style="width: 39rem"
      >
        <template
          v-if="
            currentNotification &&
            currentNotification?.type == NotificationType.TASK
          "
        >
          <TaskDetail
            v-if="currentNotification?.mainData?.taskId"
            :task-id="currentNotification?.mainData?.taskId"
          />
        </template>
        <template
          v-else-if="currentNotification?.type == NotificationType.DAYOFF"
        >
          <DayoffDetail
            v-if="currentNotification?.mainData?.dayoffId"
            :id="currentNotification?.mainData?.dayoffId"
            is-view-in-notification
          />
        </template>
        <template
          v-else-if="currentNotification?.type == NotificationType.GROUP"
        >
          <GroupDetail
            v-if="currentNotification?.mainData?.groupId"
            position="members"
            :group-id="currentNotification?.mainData?.groupId"
          />
        </template>
        <template v-else-if="currentNotification?.type == NotificationType.GED">
          <GedDetail
            v-if="
              currentNotification?.mainData?.groupId ||
              currentNotification.executorId
            "
            position="members"
            :id-resoucer="{
              id: currentNotification?.mainData?.groupId
                ? currentNotification.mainData?.groupId
                : currentNotification.executorId,
              isGroup: !!currentNotification.mainData?.groupId,
              folderId: currentNotification?.mainData?.sharedFolderId
                ? currentNotification?.mainData?.sharedFolderId
                : '',
              notification: currentNotification,
            }"
          />
        </template>
        <template
          v-else-if="currentNotification?.type == NotificationType.CALENDAR"
        >
          <DetailViewMeeting
            v-if="
              currentNotification?.mainData?.eventType == CalendarType.Meeting
            "
            :event="currentNotification?.mainData"
            :event-id="currentNotification?.mainData?.eventId"
          />
          <DetailViewReminder
            v-if="
              currentNotification?.mainData?.eventType == CalendarType.Reminder
            "
            :key="currentNotification?.mainData?.id"
            :event-id="currentNotification?.mainData?.id"
          />
        </template>

        <div v-else class="h-full flex flex-col px-8">
          <div class="h-1/3"></div>
          <div class="flex-1">
            <AtomNoData>
              <template #description>
                <div class="text-center text-gray-500 pt-4">
                  {{ $t('NOTIFICATION_HAVE_NOT_SELECT_ITEM_YET') }}
                </div>
              </template>
              <template #action><span></span></template>
            </AtomNoData>
          </div>
        </div>
      </div>
      <div class="w-8"></div>
    </div>
  </div>

  <SynDeleteModal
    v-if="isOpenAlertReadAll"
    :title="
      isConditionReadAll
        ? $t('NOTIFICATION_LABEL_ALERT_READ_ALL_WHEN_SHOW_UNREAD')
        : $t('NOTIFICATION_LABEL_ALERT_READ_ALL')
    "
    :label-btn-save="$t('COMMON_LABEL_CONFIRM')"
    :is-show-btn-save="!isConditionReadAll"
    :label-btn-cancel="isConditionReadAll ? $t('COMMON_LABEL_CLOSE') : ''"
    :is-loading="isLoadingMarkReadAll"
    @cancel="isOpenAlertReadAll = false"
    @on-save="onReadAllNotification"
  />
</template>
