<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="31.5"
    viewBox="0 0 36 31.5"
  >
    <path
      id="Icon_awesome-columns"
      data-name="Icon awesome-columns"
      d="M32.625,2.25H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h29.25A3.375,3.375,0,0,0,36,30.375V5.625A3.375,3.375,0,0,0,32.625,2.25Zm-16.875,27H4.5v-18H15.75Zm15.75,0H20.25v-18H31.5Z"
      transform="translate(0 -2.25)"
    />
  </svg>
</template>
