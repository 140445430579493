<template>
  <input
    class="form-checkbox h-5 w-5 text-gray-600 rounded"
    :value="modelValue"
    :type="inputType"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      enum: ['text', 'password'],
      default: 'text',
    },
  },
  emits: ['update:modelValue'],
});
</script>
