<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <clipPath id="a">
        <path d="M0 0h24v24H0z" opacity="1"></path>
      </clipPath>
      <g clip-path="url(#a)">
        <path
          fill-rule="evenodd"
          d="M8.956 0h6.088c1.363 0 2.447 0 3.321.071.896.074 1.66.227 2.359.583a6 6 0 0 1 2.622 2.622c.356.7.51 1.463.583 2.359C24 6.509 24 7.593 24 8.956v6.088c0 1.363 0 2.447-.071 3.321-.074.896-.227 1.66-.583 2.359a6 6 0 0 1-2.622 2.622c-.7.356-1.463.51-2.359.583-.874.071-1.958.071-3.321.071H8.956c-1.363 0-2.447 0-3.321-.071-.896-.074-1.66-.227-2.359-.583a6 6 0 0 1-2.622-2.622c-.356-.7-.51-1.463-.583-2.359C0 17.491 0 16.407 0 15.044V8.956C0 7.593 0 6.51.071 5.635c.074-.896.227-1.66.583-2.359A6 6 0 0 1 3.276.654c.7-.356 1.463-.51 2.359-.583C6.509 0 7.593 0 8.956 0zM5.797 2.065c-.771.063-1.243.182-1.613.371a4 4 0 0 0-1.748 1.748c-.189.37-.308.842-.371 1.613C2 6.581 2 7.583 2 9v6c0 1.417 0 2.419.065 3.203.063.772.182 1.243.371 1.613a4 4 0 0 0 1.748 1.748c.37.189.842.308 1.613.371C6.581 22 7.583 22 9 22h6c1.417 0 2.419 0 3.203-.065.772-.063 1.243-.182 1.613-.371a4 4 0 0 0 1.748-1.748c.189-.37.308-.842.371-1.613C22 17.419 22 16.417 22 15V9c0-1.417 0-2.419-.065-3.203-.063-.771-.182-1.243-.371-1.613a4 4 0 0 0-1.748-1.748c-.37-.189-.842-.308-1.613-.371C17.419 2 16.417 2 15 2H9c-1.417 0-2.419 0-3.203.065z"
          clip-rule="evenodd"
          opacity="1"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
