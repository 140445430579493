<script setup lang="ts">
import { IWorkflowStepType } from '@/application/types/workflow/workflow.types';
import WorkflowStepTypeEntity from '@/domain/entities/workflow/WorkflowStepTypeEntity';
import WorkflowStepTypeDetail from '@/ui/modules/workflow/workflow-step-type/WorkflowStepTypeDetail.vue';
import { computed, ref } from 'vue';
const props = defineProps<{
  viewMode?: 'VIEW' | 'EDIT' | 'ADD';
  stepTypeDetail: IWorkflowStepType;
}>();
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'updateSuccess', newData: any): void;
}>();

const isLoading = ref<boolean>(false);
const isDisableButton = computed<boolean>(() => {
  return !stepTypeDetailData.value?.name;
});

const stepTypeDetailData = ref<WorkflowStepTypeEntity>(
  new WorkflowStepTypeEntity({
    ...props?.stepTypeDetail,
  })
);
const viewMode = ref<'VIEW' | 'EDIT' | 'ADD'>(props.viewMode || 'VIEW');

const isUpdating = ref<boolean>(false);

const onSave = async () => {
  try {
    isUpdating.value = true;
    const res = await stepTypeDetailData.value.saveUpdate();
    isUpdating.value = false;

    viewMode.value = 'VIEW';
    emit('updateSuccess', res?.result || stepTypeDetailData.value);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  }
};
const onOpenEditMode = () => {
  viewMode.value = 'EDIT';
};
const onCancelEdit = () => {
  if (props.viewMode == 'ADD' || props.viewMode == 'EDIT') {
    emit('close');
    return;
  }
  stepTypeDetailData.value = new WorkflowStepTypeEntity({
    ...props?.stepTypeDetail,
  });
  viewMode.value = 'VIEW';
};
</script>
<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-style="max-height: 90%; max-width: 90%;width: 36rem"
    disable-click-outside
    @cancel="$emit('close')"
  >
    <template #header>
      <div class="w-full flex items-center">
        <div class="text-base min-w-max">
          {{
            $t(
              viewMode == 'ADD'
                ? 'WORKFLOW_LABEL_CREATE_NEW_WORKFLOW_STEP'
                : 'WORKFLOW_LABEl_STEP_TYPES'
            )
          }}
        </div>
      </div>
    </template>
    <template #body>
      <div class="px-6 w-full h-full overflow-auto small-scrollbar">
        <WorkflowStepTypeDetail
          v-model:step-type-detail="stepTypeDetailData"
          :view-mode="viewMode == 'VIEW' ? 'VIEW' : 'EDIT'"
        />
      </div>
    </template>
    <template #footer>
      <div class="w-full flex items-center justify-end">
        <div class="flex-center space-x-2 px-4 pb-4">
          <template v-if="viewMode == 'EDIT'">
            <SynButton type-text color="gray" @click="onCancelEdit">
              <span> {{ $t('COMMON_LABEL_CANCEL') }}</span>
            </SynButton>
            <SynButton
              :is-loading="isUpdating"
              :disabled="isDisableButton"
              @click="onSave"
            >
              <span> {{ $t('COMMON_LABEL_SAVE') }}</span>
            </SynButton>
          </template>
          <template v-else>
            <SynButton type-text color="gray" @click="$emit('close')">
              <span> {{ $t('COMMON_LABEL_CLOSE') }}</span>
            </SynButton>
            <SynButton
              :is-loading="isLoading"
              :disabled="isDisableButton"
              @click="onOpenEditMode"
            >
              <span> {{ $t('COMMON_LABEL_EDIT') }}</span>
            </SynButton>
          </template>
        </div>
      </div>
    </template>
  </SynModal>
</template>
