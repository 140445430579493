<script setup lang="ts">
import SearchTasks from '@/ui/modules/task/components/search/SearchTasks.vue';
import SearchAndSelectTasks from '@/ui/modules/task/components/search/SearchAndSelectTasks.vue';

defineProps<{
  title?: string;
  isSelectMultiple?: boolean;
  filterFunction?: Function;
}>();
const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onChoose', task: any): void;
}>();

const onConfirmSelectTask = (data) => {
  emit('onChoose', data);
};
</script>
<template>
  <SynModal
    z-index="z-50"
    container-style="width: 840px; max-width: 1200px;"
    disable-click-outside
    is-hidden-footer
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="px-4 flex items-center justify-between">
        {{ title }}
      </div>
    </template>
    <template #body>
      <div
        class="
          flex-1
          w-full
          h-full
          flex flex-col
          rounded-md
          overflow-hidden
          min-h-[32rem]
        "
      >
        <SearchAndSelectTasks
          v-if="isSelectMultiple"
          hidden-close-button
          view-task-mode="LIST"
          :filter-function="filterFunction"
          @on-confirm-select="onConfirmSelectTask"
        />
        <SearchTasks
          v-else
          hidden-close-button
          view-task-mode="LIST"
          :filter-function="filterFunction"
          @on-select-one="onConfirmSelectTask"
        />
      </div>
    </template>
  </SynModal>
</template>
