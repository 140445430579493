<script setup lang="ts">
import { computed, ref } from 'vue';
import { taskStates } from '@/ui/hooks/taskHook';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import { TaskLife } from '@/domain/enums/taskEnum';

const props = defineProps<{
  taskLife: number;
  mode?: string;
  readonly?: boolean;
  isLoading?: boolean;
  customClass?: string;
  isShowUserAction?: boolean;
  userActionId?: number | string;
  timeAction?: number | string;
  hasPermission?: boolean;
  isHiddenRemove?: boolean;
  approvalEnabled?: boolean;
}>();

const emit = defineEmits(['change-state', 'openChangeState', 'delete']);

const onChangeState = (state) => {
  if (props.taskLife != state) {
    emit('change-state', state);
  }
};

const taskStateList = computed(() => {
  if (!taskStates) return [];
  const stateList =
    props.mode == 'create'
      ? Object.values(taskStates).filter(
          (o) => o.key !== 3 && o.key !== 6 && o.key !== 5
        )
      : Object.values(taskStates);

  return stateList.sort((a, b) => {
    return a.index - b.index;
  });
});

const isShowOptions = ref(false);
const vigDropdownRef = ref<any>(null);

const forceOpen = () => {
  vigDropdownRef.value?.onForceOpen();
};

defineExpose({
  forceOpen,
});
</script>
<template>
  <VigDropdown
    ref="vigDropdownRef"
    :arrow="false"
    @on-dropdown-open="[(isShowOptions = true), $emit('openChangeState', true)]"
    @on-dropdown-close="
      [(isShowOptions = false), $emit('openChangeState', false)]
    "
  >
    <template #dropdown-toggle>
      <slot name="dropdown-toggle">
        <div
          :title="$t('TASK_STATE_LIST_TITLE')"
          class="
            py-2
            flex
            items-center
            justify-between
            font-medium
            rounded
            px-3
          "
          :class="[
            taskStates[taskLife]?.background,
            taskStates[taskLife]?.color,
            !readonly ? 'cursor-pointer hover:bg-current-50' : '',
            isLoading ? 'cursor-wait' : '',
            !customClass ? 'w-32' : customClass,
          ]"
        >
          <slot name="prefix"></slot>
          <template
            v-if="
              taskLife !== 3 ||
              (taskLife === 3 && !isShowUserAction && !userActionId)
            "
          >
            <div class="truncate" style="max-width: 7rem">
              {{
                $t(taskStates[taskLife]?.nameCode) || $t('TASK_STATE_CREATED')
              }}
            </div>
            <SynIcon
              v-if="!readonly"
              :name="'sort-down'"
              :class="[
                taskStates[taskLife]?.fill,
                !isShowOptions ? '-rotate-90' : '',
              ]"
              custom-class="h-2.5 w-2.5"
            />
          </template>
          <template v-if="isShowUserAction && userActionId && taskLife === 3">
            <div
              style="max-width: 15rem"
              class="
                flex
                items-center
                space-x-2
                rounded
                text-xs
                font-mediums
                text-gray-600
              "
            >
              <div class="w-3 h-3 flex-center rounded-full bg-green-500">
                <SynIcon
                  name="check"
                  custom-class="w-2.5 h-2.5 fill-white text-white"
                />
              </div>
              <span class="min-w-max">{{ $t('TASK_STATE_FINISHED_BY') }}</span>
              <UserByAction
                v-if="userActionId"
                :user-id="userActionId"
                is-show-action-user
                is-show-first-name
                avatar-class="w-4 h-4 text-xs min-w-max"
              />
              <span class="text-2xs truncate">
                (<SynLabelDateTime format="date_time" :datetime="timeAction" />)
              </span>
            </div>
          </template>
        </div>
      </slot>
    </template>
    <template #dropdown-menu>
      <div v-if="!readonly && !isLoading">
        <div v-for="status in taskStateList" :key="status?.key">
          <div
            class="
              p-2
              cursor-pointer
              dropdown-item
              text-sm
              font-medium
              truncate
            "
            :class="`hover:${status?.background} ${
              taskLife === status?.key ? status?.background : ''
            }`"
            @click="onChangeState(status?.key)"
          >
            <div class="flex items-center justify-between space-x-2">
              <span :class="status?.color" :title="$t(status?.nameCode)">
                <span
                  v-if="
                    approvalEnabled &&
                    status?.key == TaskLife.Finished &&
                    taskLife !== TaskLife.Finished
                  "
                >
                  {{ $t('COMMON_LABEL_APPROVAL_REQUEST') }}
                </span>
                <span v-else>
                  {{ $t(status?.nameCode) }}
                </span>
              </span>
              <span class="shrink-0">
                <SynIcon
                  :name="status?.iconName"
                  :custom-class="`h-4 w-4 ${status?.fill}`"
                />
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="hasPermission && !isHiddenRemove"
          class="
            list-li
            flex
            border-t
            shadow-sm
            justify-start
            items-center
            space-x-2
            text-sm
          "
          :class="isHiddenTool ? '' : 'border-b'"
          @click="$emit('delete')"
        >
          <SynIcon class="fill-red-500" is-active name="trash" />
          <span class="text-red-500">
            {{ $t('COMMON_LABEL_DELETE') }}
          </span>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
