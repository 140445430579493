<template>
  <svg
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <rect id="StateTodo" transform="translate(36.000000 55.000000)" />
      </g>
      <path
        id="path"
        d="M428.317 507.816C415.467 507.816 406.901 499.352 406.901 486.657L406.901 444.339L364.069 444.339C351.22 444.339 342.653 435.875 342.653 423.18C342.653 410.485 351.22 402.021 364.069 402.021L406.901 402.021L406.901 359.703C406.901 347.008 415.467 338.544 428.317 338.544C441.166 338.544 449.733 347.008 449.733 359.703L449.733 402.021L492.564 402.021C505.414 402.021 513.98 410.485 513.98 423.18C513.98 435.875 505.414 444.339 492.564 444.339L449.733 444.339L449.733 486.657C449.733 499.352 441.166 507.816 428.317 507.816Z"
        fill-rule="nonzero"
      />
      <path
        id="path"
        d="M256.99 507.816C115.646 507.816 0 393.557 0 253.908C0 114.259 115.646 0 256.99 0C293.397 0 327.662 6.34772 359.786 21.159C370.494 25.3908 374.777 38.0862 370.494 48.6657C366.211 59.2452 353.361 63.477 342.653 59.2452C316.954 48.6657 286.972 42.318 256.99 42.318C139.203 42.318 42.8317 137.534 42.8317 253.908C42.8317 370.283 139.203 465.498 256.99 465.498C271.981 465.498 286.972 463.382 301.963 461.266C312.671 459.15 325.521 465.498 327.662 478.193C329.804 488.773 323.379 501.468 310.53 503.584C293.397 505.7 274.123 507.816 256.99 507.816Z"
        fill-rule="nonzero"
      />
      <path
        id="path"
        d="M214.158 317.385C207.734 317.385 203.451 315.269 199.167 311.037L134.92 247.56C126.353 239.097 126.353 226.401 134.92 217.938C143.486 209.474 156.336 209.474 164.902 217.938L214.158 266.603L477.573 6.34772C486.139 -2.11591 498.989 -2.11591 507.555 6.34772C516.122 14.8113 516.122 27.5067 507.555 35.9703L229.149 311.037C224.866 315.269 220.583 317.385 214.158 317.385Z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
