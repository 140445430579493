/* eslint-disable no-unused-vars */
export enum StorageConstant {
    TOKEN = 'TOKEN',
    DEVICE_TOKEN = 'DEVICE_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    LANGUAGE = 'LANGUAGE',
    LOCATION = 'LOCATION',
    PHONE_COUNTRY_CODE = 'PHONE_COUNTRY_CODE',
    CURRENT_USER_ID = 'CURRENT_USER_ID',
    CurrentEmail = 'CURRENT_EMAIL',
    ORGANIZATIONS = 'ORGANIZATIONS',
    ACTIVE_ORG = 'ACTIVE_ORG',
    CURRENT_USER_ROLE = 'CURRENT_USER_ROLE',
    NUMBER_OF_LOGIN_TIME = 'NUMBER_OF_LOGIN_TIME',
    SETTING_PROGRESS = 'SETTING_PROGRESS',
    HAS_LOGIN = 'HAS_LOGIN',
    VOUCHER_INFO = 'VOUCHER_INFO',
    IS_SEARCH_ALL_ORG_MEMBERS = 'IS_SEARCH_ALL_ORG_MEMBERS',
    IS_SEARCH_MEMBERS_OUTSIDE_GROUP = 'IS_SEARCH_MEMBERS_OUTSIDE_GROUP',
    EXPIRE_REQUEST_PERMISSION_AT = 'EXPIRE_REQUEST_PERMISSION_AT',
    EXPIRE_REQUEST_SET_TASK_LIST_AS_DEFAULT_AT = 'EXPIRE_REQUEST_SET_TASK_LIST_AS_DEFAULT_AT',
    EXPIRE_PLAN_WARNING_AT = 'EXPIRE_PLAN_WARNING_AT',
    TASK_LIST_MOMENT_SHOW_TAB = 'TASK_LIST_MOMENT_SHOW_TAB',
    CURRENT_WHITEBOARD_ID = 'CURRENT_WHITEBOARD_ID',
    CURRENT_TASK_DETAIL_DRAWER_TOP = 'CURRENT_TASK_DETAIL_DRAWER_TOP',
    // ticket
    TICKET_TOKEN = 'TICKET_TOKEN',
    TICKET_ORGANIZATION_ID = 'TICKET_ORGANIZATION_ID',
    TICKET_USER_ID = 'TICKET_USER_ID',
    TICKET_GROUP_ID = 'TICKET_GROUP_ID',
    TICKET_DOMAIN_ID = 'TICKET_DOMAIN_ID',
    TICKET_EXTERNAL_ROOT_ID = 'TICKET_EXTERNAL_ROOT_ID',
    TICKET_EXTERNAL_UID = 'TICKET_EXTERNAL_UID',
    TICKET_EXTERNAL_FIRST_NAME = 'TICKET_EXTERNAL_FIRST_NAME',
    TICKET_EXTERNAL_LAST_NAME = 'TICKET_EXTERNAL_LAST_NAME',
    TICKET_EXTERNAL_EMAIL = 'TICKET_EXTERNAL_EMAIL',
    TICTOP_TAB_IDS = 'TICTOP_TAB_IDS',
    TICTOP_CURRENT_TAB_ID = 'TICTOP_CURRENT_TAB_ID',
    // deployment
    INFO_DEPLOYMENT = 'INFO_DEPLOYMENT',
    HAVE_AVATAR = 'HAVE_AVATAR',
    DASHBOARD_TEAMS = 'DASHBOARD_TEAMS',
    DASHBOARD_GROUPS = 'DASHBOARD_GROUPS',
    LAST_RELOAD_PAGE = 'LAST_RELOAD_PAGE',
    NEW_DEPLOY = 'NEW_DEPLOY',
    EMAIL = 'EMAIL',
    SUPPORTERS_DEFAULT = 'SUPPORTERS_DEFAULT',
    IS_INVITING = 'IS_INVITING',
}

const PERSISTENCE_ITEMS = [
    StorageConstant.LANGUAGE,
    StorageConstant.PHONE_COUNTRY_CODE,
    StorageConstant.LOCATION,
    StorageConstant.IS_INVITING,
];

export const getLocalStorage = (item: StorageConstant) => {
    return localStorage.getItem(item) || '';
};
export const setLocalStorage = (item: StorageConstant, value) => {
    localStorage.setItem(item, value);
};
export const removeLocalStorage = (item) => {
    localStorage.removeItem(item);
};
export const clearLocalStorage = ({ isSwitchingOrg }) => {
    const revertData = {};
    for (const item of PERSISTENCE_ITEMS) {
        revertData[item] = getLocalStorage(item);
    }
    if (isSwitchingOrg)
        revertData[StorageConstant.ORGANIZATIONS] = getLocalStorage(
            StorageConstant.ORGANIZATIONS
        );

    localStorage.clear();

    for (const item of PERSISTENCE_ITEMS) {
        setLocalStorage(item, revertData[item]);
    }

    if (isSwitchingOrg)
        setLocalStorage(
            StorageConstant.ORGANIZATIONS,
            revertData[StorageConstant.ORGANIZATIONS]
        );
};
export const getCurrentUserId = () => {
    return parseInt(getLocalStorage(StorageConstant.CURRENT_USER_ID));
};
export const getCurrentLanguage = () => {
    return getLocalStorage(StorageConstant.LANGUAGE);
};
export const getCurrentOrganizationId = () => {
    return parseInt(getLocalStorage(StorageConstant.ACTIVE_ORG));
};

window.dispatchEvent(
    new CustomEvent('token-localstorage-changed', {
        detail: {
            storage: localStorage.getItem('TOKEN'),
        },
    })
);
