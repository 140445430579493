import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import ChatService from '@/application/services/ChatService';
import myProfileStore from '@/store/auth/my-profile';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { chain, values } from 'lodash';
import { formatDate } from '@/ui/plugins/filters';
import {
    ChatConversationMemberModel,
    ChatConversationModel,
} from '@/application/models/chat/ChatConversationModel';
import chatStore from '@/store/chat/index';
import organizationStore from '@/store/organization';
import { ChatSupporterStatus } from '@/domain/enums/chat-enums';
import { isSameId } from '@/ui/helpers/utils';
import tictopConfig from '@/application/constants/tictop-config.const';
import systemConfig from '@/application/constants/system-config.const';
import permissionStore from '@/store/permission';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

interface ChatSupporting {
    supportingOrgId: string;
    supportingConversationsDraft: { [id: string]: ChatConversationModel };
}

export default defineStore({
    id: storeId.chatSupporting,
    state: () =>
        ({
            supportingOrgId: import.meta.env.VITE_CHAT_SUPPORT_ORG_ID,
            supportingConversationsDraft: {},
        } as ChatSupporting),
    getters: {
        isSupportingChat() {
            return (converId) =>
                chatStore().getConversationInfo(converId)?.supportingChat;
        },
        getSupportingUserId() {
            return (converId) => {
                const converInfo = chatStore().getConversationInfo(converId);
                const myUserId = myProfileStore().myProfile?.id;

                return Object.keys(converInfo?.members || {}).find(
                    (memberId) => memberId !== `${myUserId}`
                );
            };
        },
    },
    actions: {
        async verifyingChatSupportPermission() {
            const tictopChatSupportAllowed =
                permissionStore().tictopConfigs[
                    tictopConfig.CHAT_CHAT_SUP_TICTOP_CHAT_SUPPORT_ALLOWED
                ] === '1';
            if (!tictopChatSupportAllowed) {
                throw new Error('CHAT_SUPPORT_NOT_ALLOWED');
            }

            const orgDoc = await ChatService.getOrgSupportingInfo(
                this.supportingOrgId
            );
            const orgSupportingInfo = orgDoc?.data();

            const myProfile = myProfileStore().myProfile;

            if (!orgSupportingInfo) throw new Error('ORG_SUPPORT_NOT_FOUND');

            if (!isSameId(orgSupportingInfo?.id, myProfile?.organizationId)) {
                throw new Error('ORG_CURRENT_NOT_VALID');
            }

            const mySupporter: any = chain(
                orgSupportingInfo?.supporting?.supporters
            )
                .mapValues()
                .find((supporter: any) =>
                    isSameId(supporter?.id, myProfile?.id)
                )
                .value();

            if (mySupporter?.status !== ChatSupporterStatus.ACTIVE) {
                throw new Error('USER_IS_NOT_SUPPORTER');
            }

            return orgSupportingInfo;
        },

        prepareSupportersChatToOrgMember(
            orgSupportingInfo: any,
            supporters: any[],
            orgUserInfo: any
        ) {
            const converId = ChatService.getNewConversationId(
                this.supportingOrgId
            );

            const members = {
                [`${orgUserInfo?.id}`]: {
                    id: `${orgUserInfo?.id}`,
                    ..._prepareSupportingOrgMember(orgUserInfo),
                },
            };

            (supporters || [])?.forEach((supporter: any) => {
                members[supporter?.id] = _prepareSupporterMember(
                    supporter,
                    orgSupportingInfo
                );
            });

            this.supportingConversationsDraft[converId] = {
                id: converId,
                isGroup: true,
                ..._prepareSupportingOrgMember(orgUserInfo),
                supporterName: orgSupportingInfo?.supporting?.name,
                supporterAvatar: orgSupportingInfo?.supporting?.avatar,
                members,
            };

            return this.supportingConversationsDraft[converId];
        },

        prepareSupportingUserExternalLink(
            supportingMemberId: string | undefined
        ) {
            if (!supportingMemberId) return null;

            const _permissionStore = permissionStore();

            const tictopExternalLinkAllowed =
                _permissionStore.tictopConfigs[
                    tictopConfig.CHAT_CHAT_SUP_EXTERNAL_LINK_ALLOWED
                ] === '1';
            if (!tictopExternalLinkAllowed) return null;

            const systemConfigs = _permissionStore.allSystemConfigs || {};

            const externalLinkAllowed =
                systemConfigs[
                    systemConfig.CHAT_SYSTEM_C_MEMBER_EXTERNAL_LINK_ALLOWED
                ] === '1';
            if (!externalLinkAllowed) return null;

            const externalLinkUrl =
                systemConfigs[systemConfig.CHAT_SYSTEM_C_EXTERNAL_PATH];
            if (!externalLinkUrl) return null;

            return externalLinkUrl?.replace('{memberId}', supportingMemberId);
        },

        async getSupportingUserConversation() {
            // Get conversation by supporting user
            const existedConver = Object.entries(chatStore().userConversations)
                .filter(
                    ([id, conver]: [string, any]) =>
                        id && conver?.supportingChat && !conver?.supportingOrgId
                )
                .map(([id, conver]: [string, any]) => ({ id, ...conver }))
                .find(() => true);

            // If has conversation, go to it
            if (existedConver) return existedConver;

            // Get draft supporting chat
            const draftSupportingChat = Object.values(
                this.supportingConversationsDraft
            )?.find((chat) => chat?.supportingChat);
            if (draftSupportingChat) return draftSupportingChat;

            // If no conversation, create a draft
            const orgDoc = await ChatService.getOrgSupportingInfo(
                this.supportingOrgId
            );
            const orgInfo = orgDoc?.data();

            const converId = ChatService.getNewConversationId(
                this.supportingOrgId
            );
            const myProfile = myProfileStore().myProfile;
            const organizationInfo = organizationStore().organizationInfo;

            const members = {
                [`${myProfile?.id}`]: {
                    id: `${myProfile?.id}`,
                    ..._prepareSupportingMember(myProfile, organizationInfo),
                },
            };

            Object.values(orgInfo?.supporting?.supporters || {})
                ?.filter(
                    (supporter: any) =>
                        supporter?.status === ChatSupporterStatus.ACTIVE
                ) // Active supporters
                ?.forEach((supporter: any) => {
                    members[supporter?.id] = _prepareSupporterMember(
                        supporter,
                        orgInfo
                    );
                });

            this.supportingConversationsDraft[converId] = {
                id: converId,
                name: orgInfo?.supporting?.name,
                avatar: orgInfo?.supporting?.avatar,
                isGroup: true,
                supportingChat: true,
                supporterName: orgInfo?.supporting?.name,
                supporterAvatar: orgInfo?.supporting?.avatar,
                members,
            };

            return this.supportingConversationsDraft[converId];
        },

        addMessage(converId, message) {
            const userId = myProfileStore().myProfile?.id;

            this._verifyToCreateConversation(this.supportingOrgId, converId);

            _processMessageFilesName(
                chatStore().getConversationInfo(converId),
                message
            );

            return chatStore().addMessage(
                this.supportingOrgId,
                converId,
                userId,
                message
            );
        },

        async addConversationSupporters(
            converId,
            supporters: ChatConversationMemberModel[]
        ) {
            const orgDoc = await ChatService.getOrgSupportingInfo(
                this.supportingOrgId
            );
            const orgInfo = orgDoc?.data();

            // Add supporters locally
            const draftConverInfo = this.supportingConversationsDraft[converId];

            if (draftConverInfo) {
                (supporters || [])?.forEach((supporter: any) => {
                    draftConverInfo.members = draftConverInfo.members || {};
                    draftConverInfo.members[supporter?.id] =
                        _prepareSupporterMember(supporter, orgInfo);
                });
                return;
            }

            // Add supporters firebase
            const addSupporters = supporters?.map((supporter) =>
                _prepareSupporterMember(supporter, orgInfo)
            );

            const myProfile = myProfileStore().myProfile;

            return ChatService.addConversationSupporters(
                this.supportingOrgId,
                converId,
                addSupporters,
                myProfile?.id
            );
        },

        async leaveConversationSupporting(converId: string) {
            const _chatStore = chatStore();

            const converInfo = _chatStore.getConversationInfo(converId);
            if (!converInfo) return;

            const availableSupporters = Object.values(
                converInfo?.members || {}
            ).filter((member: any) => member?.isSupporter && !member?.removed);

            if (availableSupporters?.length <= 1) {
                settingGlobalModal({
                    type: 'warning',
                    iconName: 'warning',
                    title: translate('CHAT_LEAVE_PREVENT_MSG'),
                    content: translate('CHAT_SUPPORTING_LEAVE_PREVENT_MSG'),
                    confirmable: true,
                });
                return ask();
            }

            settingGlobalModal({
                type: 'confirm',
                title: translate('CHAT_LEAVE_CONFIRM_MSG'),
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
                closeable: true,
            });

            const confirmed = await ask();
            if (!confirmed) return;

            // Leave conversation firebase
            const myProfile = myProfileStore().myProfile;
            if (!myProfile?.id) return;

            // Delete draft supporting chat
            if (this.supportingConversationsDraft[converId]) {
                delete this.supportingConversationsDraft[converId];
            } else {
                await ChatService.leaveConversation(
                    this.supportingOrgId,
                    converId,
                    myProfile?.id
                );
            }

            // Remove user conversation from local
            _chatStore.deleteUserConversation(converId);
            _chatStore.removeBubbleChat({ id: converId });

            return true;
        },

        // USER CONVERSATION
        setSeenUserConversation(converId) {
            const userId = myProfileStore().myProfile?.id;
            const userOrgId =
                chatStore().getConversationInfo(converId)?.supportingOrgId;

            return ChatService.setSeenUserConversation(
                userOrgId,
                userId,
                converId
            );
        },

        // PRIVATE METHODS
        async _verifyToCreateConversation(orgId, converId) {
            const draftConverInfo = this.supportingConversationsDraft[converId];
            if (!draftConverInfo) return;

            const myProfile = myProfileStore().myProfile;
            const organizationInfo = organizationStore().organizationInfo;

            let submitConverInfo = { ...draftConverInfo };

            // If add from customer to supporter, update conver info
            if (!submitConverInfo.supportingOrgId) {
                submitConverInfo = {
                    ...submitConverInfo,
                    ..._prepareSupportingMember(myProfile, organizationInfo),
                };
            }

            const userId = myProfileStore().myProfile?.id;

            await ChatService.addConversation(
                orgId,
                converId,
                submitConverInfo,
                userId
            );

            // Add user conversation locally
            chatStore().addUserConversation(converId, {
                ...draftConverInfo,
                createdDate: new Date(),
            });

            // Remove conversation from Draft
            delete this.supportingConversationsDraft[converId];
        },
    },
});

const _processMessageFilesName = (converInfo, message) => {
    if (!message?.files?.length) return;

    const pastedPhotoIdxs = message.files.reduce((arr, file, index) => {
        if (file?.isPasted && file?.name === 'image.jpeg') arr.push(index);
        return arr;
    }, []);

    const fileName = _genImageName(converInfo);

    if (pastedPhotoIdxs.length === 1) {
        const photoIdx = pastedPhotoIdxs[0];
        const msgFile = message.files[photoIdx];
        message.files[photoIdx] = new File([msgFile], fileName, {
            type: msgFile?.type,
        });
    } else {
        pastedPhotoIdxs.forEach((photoIdx, index) => {
            const msgFile = message.files[photoIdx];
            const msgFileName = fileName.replace(
                '.jpeg',
                ` (${index + 1}).jpeg`
            );
            message.files[photoIdx] = new File([msgFile], msgFileName, {
                type: msgFile?.type,
            });
        });
    }
};

const _genImageName = (converInfo) => {
    const memberName = chain(values(converInfo?.members))
        .filter((member) => !member?.isSupporter)
        .map((member) => member?.name?.split(' ').pop())
        .head()
        .value();

    const chatName = `${converInfo?.supporterName}-${ignoreUnicode(
        memberName,
        false
    )}`;

    const time = formatDate(new Date(), 'YYYY-MM-DD hh.mm A');

    return `Photo-Chat ${chatName} ${time}.jpeg`;
};

const _prepareSupportingMember = (
    supportingUser,
    supportingOrgInfo: any = null
) => {
    return {
        name: supportingUser?.fullName,
        avatar: supportingUser?.avatarUrl,
        supportingChat: true,
        supportingMemberId: supportingUser?.publicMemberId,
        supportingOrgId: supportingOrgInfo?.id,
        supportingOrgName: supportingOrgInfo?.name,
        supportingOrgAvatar: supportingOrgInfo?.logoUrl,
        supportingOrgModel: supportingOrgInfo?.model,
    };
};
const _prepareSupportingOrgMember = (orgMemberInfo) => {
    return {
        name: orgMemberInfo?.fullName,
        avatar: orgMemberInfo?.avatarUrl,
        supportingChat: true,
        supportingMemberId: orgMemberInfo?.publicMemberId,
        supportingOrgId: orgMemberInfo?.organizationId,
        supportingOrgName: orgMemberInfo?.organizationName,
        supportingOrgAvatar: orgMemberInfo?.organizationLogoUrl,
        supportingOrgModel: orgMemberInfo?.organizationModel,
    };
};

const _prepareSupporterMember = (supporter, orgInfo) => {
    return {
        ...supporter,
        isSupporter: true,
        supportingChat: true,
        supportingOrgId: orgInfo?.id,
        supportingOrgName: orgInfo?.name,
        supportingOrgAvatar: orgInfo?.avatar,
        supportingOrgModel: orgInfo?.model,
    };
};
