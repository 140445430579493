<script setup lang="ts">
import { computed } from 'vue';
import { PaymentTransactionLog } from '@/application/interfaces/payment/TransactionInterfaces';
import VoucherCard from '@/ui/modules/voucher/VoucherCard.vue';
import { EPaymentVoucherResponseCode } from '@/application/interfaces/payment/TransactionInterfaces';
import {
  PaymentStatusText,
  // PaymentTransactionStatus,
  // VnpPaymentResponseCode,
} from '@/ui/common/constants/payment';
import { EPaymentPurpose } from '@/application/enums/PaymentEnums';
import { EPlanState } from '@/application/types/plan.types';
import { UI_BY_PLAN_STATE } from '@/application/constants/plan.const';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  transactionDetail: PaymentTransactionLog;
}>();

const methodPayment = {
  VN_PAY: {
    label: 'VNPAY',
  },
  MOMO: {
    label: 'MOMO',
  },
  VOUCHER: {
    label: 'VOUCHER',
  },
  ZALO_PAY: {
    label: 'ZALOPAY',
  },
};

const purposeString = computed(() => {
  if (props.transactionDetail?.licenseInfo) return '';
  const orderInfo = props.transactionDetail?.orderInfo;
  const translateParams = {
    planId: props.transactionDetail?.paymentPlanId,
    period: orderInfo?.period,
    totalUsers: orderInfo?.quantity,
    remainDays: orderInfo?.totalDaysApplied,
  };
  if (orderInfo?.currentStateCode === EPlanState.PAID)
    return translate(
      props.transactionDetail?.paymentPurpose == EPaymentPurpose.Extend
        ? UI_BY_PLAN_STATE[EPlanState.PAID]?.purposeMessage
        : UI_BY_PLAN_STATE[EPlanState.PAID]?.purposeBuyMoreMessage,
      translateParams
    );

  return translate(
    UI_BY_PLAN_STATE[orderInfo?.currentStateCode]?.purposeMessage,
    translateParams
  );
});

const isLifeTime = computed<boolean>(() => {
  return (
    props.transactionDetail?.paymentPurpose === EPaymentPurpose.BUY_LICENSE
  );
});

const translateText = (status) => {
  switch (status) {
    case PaymentStatusText.Paid:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_PAID',
        class: 'border-green-500 text-green-500',
      };
    case PaymentStatusText.Cancelled:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_CANCELLED',
        class: 'border-gray-500 text-gray-500',
      };
    case PaymentStatusText.Failed:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_FAILED',
        class: 'border-red-500 text-red-500',
      };
    case PaymentStatusText.Handling:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_HANDLING',
        class: 'border-orange-500 text-orange-500',
      };
    default:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_HANDLING',
        class: 'border-orange-500 text-orange-500',
      };
  }
};
</script>
<template>
  <div class="flex flex-col space-y-2 p-3 border border-gray-100 rounded">
    <span class="text-gray-700 font-medium pb-2 text-sm">{{
      $t('BILLING_MODAL_INFORMATION_PAYMENT_TITLE')
    }}</span>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">No.</span>
      <span>
        {{ transactionDetail?.transactionNo || transactionDetail?.bankTranNo }}
      </span>
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">{{
        $t('BILLING_MODAL_BANK_CODE_TITLE')
      }}</span>
      <span class="uppercase">{{ transactionDetail?.bankCode }}</span>
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">{{
        $t('BILLING_MODAL_TYPE_CARD_TITLE')
      }}</span>
      <span>{{ transactionDetail?.cardType }}</span>
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">{{
        $t('BILLING_MODAL_PAY_DATE_TITLE')
      }}</span>
      {{ $filters.dayjs(transactionDetail.payDate, 'MMM DD, YYYY hh:mm A') }}
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">
        {{ $t('BILLING_MODAL_EXPIRE_DATE_TITLE') }}
      </span>

      <span
        v-if="isLifeTime"
        class="
          rounded-full
          border border-gray-500
          px-2
          py-0.5
          text-gray-600 text-xs
          font-medium
        "
        >Unlimited</span
      >
      <span v-else>
        {{
          $filters.dayjs(
            transactionDetail.orderInfo?.endDate,
            'MMM DD, YYYY hh:mm A'
          )
        }}
      </span>
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">
        {{ $t('BILLING_MODAL_OPTION_DATE_TITLE') }}
      </span>
      <div class="flex space-x-2 items-center">
        <span v-if="props.transactionDetail.orderInfo.period == 0">
          {{ props.transactionDetail.orderInfo.orderDesc }}
        </span>

        <span v-else>
          {{ purposeString }}
        </span>

        <span
          v-if="isLifeTime"
          class="
            px-2
            py-1
            rounded-full
            bg-orange-50
            text-orange-500 text-xs
            font-medium
            w-max
          "
        >
          LIFETIME VERSION
        </span>
      </div>
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">
        {{ $t('BILLING_MODAL_METHOD_PAYMENT_TITLE') }}
      </span>
      <span class="font-bold text-gray-400">
        {{
          methodPayment[transactionDetail?.provider]?.label ||
          transactionDetail?.provider
        }}
      </span>
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">{{ $t('BILLING_MODAL_STATUS_TITLE') }}</span>
      <div
        class="
          min-w-max
          w-max
          flex-center
          px-2
          py-0.5
          border
          text-xs
          font-medium
          rounded-full
        "
        :class="translateText(transactionDetail?.paymentStatus)?.class"
      >
        <span>{{
          // $t(
          //     translateText(
          //         parserTextStatusPaymentVNPay(
          //             transactionDetail?.transactionStatus,
          //             transactionDetail?.responseCode
          //         )
          //     )?.label
          // )
          $t(translateText(transactionDetail?.paymentStatus)?.label)
        }}</span>
      </div>
    </div>
    <div
      v-if="transactionDetail?.voucherInfo?.code"
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400 w-1/2">
        {{ $t('VOUCHER_LABEL_VOUCHER_CODE') }}
      </span>
      <div class="flex-1">
        <VoucherCard
          hidden-close
          :voucher-detail="{
            ...transactionDetail?.voucherInfo,
            messageCode: EPaymentVoucherResponseCode.VOUCHER_VALID,
          }"
        />
      </div>
    </div>
    <div class="flex justify-between items-center text-sm mx-2 py-1">
      <span class="text-gray-400 font-medium">{{
        $t('BILLING_MODAL_AMOUNT_TITLE')
      }}</span>
      <div class="flex flex-col items-end">
        <span class="font-medium text-xl text-current-500">
          {{ $filters.currency(transactionDetail?.orderInfo?.amount, 'vi') }}
        </span>

        <span
          v-if="
            transactionDetail?.orderInfo?.amountBeforeDiscount > 0 &&
            transactionDetail?.orderInfo?.amountBeforeDiscount <
              transactionDetail?.orderInfo?.amount
          "
          class="text-sm line-through"
        >
          {{
            $filters.currency(
              transactionDetail?.orderInfo?.amountBeforeDiscount,
              'vi'
            )
          }}
        </span>
      </div>
    </div>
  </div>
</template>
