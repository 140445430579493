export default class LocationService {
    private static instance: LocationService;

    constructor() {}

    public static getInstance(): LocationService {
        if (!LocationService.instance) {
            LocationService.instance = new LocationService();
        }

        return LocationService.instance;
    }

    getCurrentLocation() {
        return new Promise((resolve, reject) => {
            const onError = (error) => {
                if (error?.code === error.PERMISSION_DENIED) {
                    return reject('LOCATION_PERMISSION_DENIED');
                }
                reject('LOCATION_GET_ERROR');
            };

            const onSuccess = async (position) => {
                resolve(
                    position?.coords
                        ? {
                              latitude: position?.coords?.latitude,
                              longitude: position?.coords?.longitude,
                          }
                        : null
                );
            };

            const options = {
                enableHighAccuracy: true, // Request high accuracy
                timeout: 5000, // Maximum time (in milliseconds) to wait for location information
                maximumAge: 0, // Maximum age (in milliseconds) of a possible cached position that is acceptable to return
            };

            navigator.geolocation.getCurrentPosition(
                onSuccess,
                onError,
                options
            );
        });
    }

    async getCurrentIp() {
        const res = await fetch('https://api.ipify.org?format=json');

        const data: any = await res?.json();

        return data?.ip;
    }

    calculateDistance(lat1, lon1, lat2, lon2) {
        const deg2rad = (deg) => {
            return deg * (Math.PI / 180);
        };

        const R = 6371; // Bán kính trái đất trong km
        const dLat = deg2rad(lat2 - lat1); // Đổi đơn vị từ độ sang radian
        const dLon = deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) *
                Math.cos(deg2rad(lat2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Khoảng cách giữa hai điểm
        return d * 1000; // Đơn vị mét
    }
}
