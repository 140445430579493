<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 479.995 479.995"
    style="enable-background: new 0 0 479.995 479.995"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M479.959,20.748c-0.009-0.112-0.02-0.223-0.032-0.334c-0.044-0.393-0.105-0.783-0.196-1.169    c-0.004-0.018-0.006-0.036-0.01-0.054c-0.1-0.41-0.231-0.812-0.382-1.208c-0.031-0.083-0.065-0.163-0.1-0.245    c-0.142-0.347-0.303-0.687-0.485-1.019c-0.025-0.046-0.046-0.093-0.072-0.138c-0.209-0.367-0.445-0.723-0.704-1.064    c-0.052-0.069-0.108-0.133-0.162-0.2c-0.211-0.265-0.438-0.519-0.679-0.766c-0.082-0.084-0.163-0.168-0.248-0.249    c-0.058-0.055-0.107-0.113-0.166-0.167c-0.262-0.237-0.533-0.458-0.813-0.663c-0.002-0.001-0.003-0.003-0.005-0.004    c-0.008-0.006-0.017-0.01-0.025-0.016c-0.346-0.252-0.706-0.472-1.074-0.674c-0.083-0.045-0.165-0.089-0.249-0.131    c-0.361-0.185-0.729-0.352-1.105-0.491c-0.021-0.008-0.042-0.019-0.064-0.026c-0.405-0.145-0.817-0.259-1.233-0.35    c-0.085-0.02-0.17-0.033-0.256-0.05c-0.352-0.067-0.706-0.117-1.063-0.148c-0.076-0.006-0.15-0.017-0.227-0.021    c-0.412-0.024-0.825-0.023-1.236,0.002c-0.088,0.006-0.175,0.017-0.263,0.025c-0.346,0.031-0.69,0.08-1.03,0.146    c-0.092,0.018-0.183,0.032-0.273,0.053c-0.411,0.092-0.817,0.208-1.216,0.352c-0.05,0.018-0.099,0.041-0.149,0.06    c-0.361,0.138-0.716,0.3-1.063,0.48c-0.045,0.024-0.092,0.037-0.137,0.062l-460,248.854c-3.503,1.895-5.547,5.686-5.205,9.654    c0.342,3.967,3.006,7.353,6.78,8.621l174.81,58.681l5.51,120.357c0.196,4.305,3.129,7.999,7.276,9.168    c0.899,0.253,1.811,0.376,2.713,0.376c3.26,0,6.388-1.6,8.28-4.395l64.839-95.759l124.271,41.716    c1.037,0.348,2.111,0.52,3.183,0.52c1.755,0,3.5-0.462,5.049-1.368c2.492-1.458,4.233-3.922,4.774-6.758l72.301-378.997    c0.009-0.046,0.008-0.093,0.017-0.139c0.074-0.42,0.13-0.844,0.149-1.271c0.001-0.022-0.001-0.045,0-0.068    C480.004,21.537,479.99,21.142,479.959,20.748z M186.361,319.044l-151.36-50.809L423.162,58.246L186.361,319.044z     M205.737,427.898l-3.777-82.521l48.605,16.316L205.737,427.898z M390.022,387.41l-182.957-61.416L453.519,54.566L390.022,387.41z    "
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
