import { v4 as uuidv4 } from 'uuid';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import {
    BucketObject,
    genBucketNameByModule,
    KindOfFile,
    ModuleSourceFile,
} from './AwsS3Type';
import { awsS3Delete, uploadFile, copyFile } from './aws-s3-services';
import dayjs from 'dayjs';

export default class AwsS3Class {
    module: ModuleSourceFile;
    bucketObj: BucketObject;
    organizationId: number;

    constructor(module: ModuleSourceFile) {
        this.module = module;
        this.bucketObj = genBucketNameByModule(module);
        this.organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;
    }

    async upload(
        fileDataBody: Blob | Buffer,
        contentType: string,
        kindOfFile: KindOfFile,
        fileName: string,
        extension: string,
        options?: {
            tagging?: string;
            isBase64?: boolean;
        }
    ) {
        try {
            let sourceKey;
            const _extension =
                extension || kindOfFile == 'PHOTO' ? 'jpeg' : 'txt';

            const fileId = `${kindOfFile}_${dayjs().format(
                'DD-MMM-YYYY_hh-mmA'
            )}_${uuidv4()}.${_extension}`;

            switch (kindOfFile) {
                case 'PHOTO':
                    sourceKey = `${this.organizationId}/original/${fileId}`;
                    break;
                case 'MEDIA':
                    sourceKey = `${this.organizationId}/media/${fileId}`;
                    break;

                default:
                    sourceKey = `${this.organizationId}/file/${fileId}`;
                    break;
            }

            const bucketName = this.bucketObj[kindOfFile?.toUpperCase()];
            const result = await uploadFile({
                Bucket: bucketName,
                Key: sourceKey,
                Body: fileDataBody,
                ContentType: contentType,
                ACL: 'public-read',
                Tagging: options?.tagging,
                Metadata: {
                    originalFileName: encodeURI(fileName),
                },
            });
            return {
                ...result,
                path: result?.Location?.slice(
                    result?.Location?.indexOf(`${this.organizationId}/`)
                ),
            };
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    }
    async clone(
        source,
        kindOfFile: KindOfFile,
        extension: string,
        options?: {
            bucket?: string;
            tagging?: string;
        }
    ) {
        let newSourceKey;

        const _extension = extension || kindOfFile == 'PHOTO' ? 'jpeg' : 'txt';

        const fileId = `${kindOfFile}_${uuidv4()}.${_extension}`;

        switch (kindOfFile) {
            case 'PHOTO':
                newSourceKey = `${this.organizationId}/original/${fileId}`;
                break;
            case 'MEDIA':
                newSourceKey = `${this.organizationId}/media/${fileId}`;
                break;

            default:
                newSourceKey = `${this.organizationId}/file/${fileId}`;
                break;
        }

        const bucketName = this.bucketObj[kindOfFile?.toUpperCase()];

        await copyFile({
            Bucket: bucketName,
            CopySource: encodeURI(
                `/${options?.bucket || bucketName}/${source}`
            ),
            Key: newSourceKey,
            ACL: 'public-read',
            Tagging: options?.tagging,
        });
        return {
            Bucket: bucketName,
            Key: newSourceKey,
        };
    }

    remove(fileKey, kindOfFile) {
        return awsS3Delete({
            Bucket: this.bucketObj[kindOfFile],
            Key: fileKey,
        });
    }
}
