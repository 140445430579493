<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    dataList: {
      id: any;
      name: string;
      avatar: string;
    }[];
    maxLength: number;
    trigger?: string;
    customClass?: string;
  }>(),
  {
    trigger: 'click',

    customClass: 'w-8 h-8',
  }
);

const showItemList = computed(() =>
  props.dataList?.length <= props.maxLength
    ? props.dataList
    : (props.dataList || [])?.slice(0, props.maxLength - 1)
);
const hiddenItemList = computed(() =>
  props.dataList?.length <= props.maxLength
    ? []
    : (props.dataList || [])?.slice(props.maxLength - 1, props.dataList?.length)
);
</script>

<template>
  <div v-if="showItemList?.length > 0" class="flex flex-wrap gap-1">
    <SynTag
      v-for="item in showItemList"
      :key="item?.id"
      size="tiny"
      custom-class="px-1 border border-current-100 bg-white text-gray-800"
    >
      {{ item?.name }}
    </SynTag>
    <div v-if="hiddenItemList.length" class="flex cursor-pointer relative">
      <vig-dropdown :trigger="trigger">
        <template #dropdown-toggle>
          <SynTag
            size="tiny"
            custom-class="px-1 border border-current-100 bg-white text-gray-800"
          >
            +{{ hiddenItemList?.length }}
          </SynTag>
        </template>
        <template #dropdown-menu>
          <div
            class="
              w-full
              bg-white
              rounded-md
              h-max
              max-h-48
              overflow-y-auto
              small-scrollbar
              px-0
            "
          >
            <div
              v-for="item in hiddenItemList"
              :key="item?.id"
              class="
                border-b border-gray-50
                py-2
                px-2
                flex
                items-center
                space-x-2
                relative
                hover-to-show__parent
              "
            >
              <div class="text-xs flex items-center space-x-1">
                <span class="font-medium">
                  {{ item?.name || 'User not active' }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </vig-dropdown>
    </div>
  </div>
</template>
