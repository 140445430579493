import { defineStore } from 'pinia';
import NotificationSettingService from '@/application/services/NotificationSettingService';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';

type NotificationSettingState = {
    _notificationSettingService: NotificationSettingService;
    notificationSetting: any;
    listNotificationGroupOfCurrentUser: any[];
};

export default defineStore({
    id: 'notificationSetting',
    state: () =>
        ({
            _notificationSettingService:
                NotificationSettingService.getInstance(),
            notificationSetting: {},
            listNotificationGroupOfCurrentUser: [],
        } as NotificationSettingState),
    getters: {},
    actions: {
        async getSettingNotification(refId, scope) {
            try {
                const res: any =
                    await this._notificationSettingService.getSettingNotification(
                        refId,
                        scope
                    );
                this.notificationSetting = res?.result;
                return res?.result;
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async setSettingNotification(refId, scope, func, timeOffHour) {
            try {
                const res =
                    await this._notificationSettingService.setSettingNotification(
                        {
                            refId: `${refId}`,
                            scope: scope,
                            function: func,
                            timeOffHour: timeOffHour,
                        }
                    );
                this.notificationSetting = res;
                this.getUserNotificationSettingInGroups();
                return res;
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async enableSettingNotification(notificationSettingId) {
            try {
                const res =
                    await this._notificationSettingService.enableSettingNotification(
                        {
                            id: notificationSettingId,
                        }
                    );
                this.notificationSetting = {};
                const index = this.listNotificationGroupOfCurrentUser.findIndex(
                    (el) => el['id'] == notificationSettingId
                );
                this.listNotificationGroupOfCurrentUser.splice(index, 1);
                return res;
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async getUserNotificationSettingInGroups() {
            try {
                const res: any =
                    await this._notificationSettingService.getUserNotificationSettingInGroups();
                const data = res?.result;
                if (!data || data.length == 0) return res;

                this.listNotificationGroupOfCurrentUser = data;

                groupStore().updateNotification(data);
                overviewGroupStore().updateNotificationAll(data);

                return res;
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async notificationNegative(data) {
            try {
                const res =
                    await this._notificationSettingService.notificationNegative(
                        data
                    );
                return res;
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async getFolderSettingNotification(folderId, parentFolderIds) {
            try {
                const res =
                    (await this._notificationSettingService.getFolderSettingNotification(
                        {
                            folderId,
                            parentFolderIds,
                        }
                    )) as any;
                console.log('hahahahahaha ', res?.result);
                return res?.result || {};
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async setFolderSettingNotification(
            folderId,
            events,
            applySubfolders,
            ignoreNotifyMe
        ) {
            try {
                const res =
                    await this._notificationSettingService.setFolderSettingNotification(
                        {
                            enable: true,
                            folderId,
                            events,
                            applySubfolders,
                            ignoreNotifyMe,
                        }
                    );
                return res;
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async turnOffFolderSettingNotification(folderId) {
            try {
                const res =
                    await this._notificationSettingService.turnOffFolderSettingNotification(
                        {
                            enable: false,
                            folderId,
                        }
                    );
                return res;
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async reactionOnMessage(data) {
            try {
                this._notificationSettingService.reactionOnMessage(data);
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
    },
});
