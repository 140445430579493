<script setup lang="ts">
import { CalendarReminderRepeatType } from '../../../common/constants/calendar';
import {
  getOnDateOfMonthString,
  getOnDateOfYearString,
  getOnDayOfWeekString,
} from '@/ui/hooks/calendarHook';
import dayjs from 'dayjs';
import { computed } from 'vue';
import { RRule } from 'rrule';
import VI from '@/ui/plugins/rrule/i18n/vi';
import FR from '@/ui/plugins/rrule/i18n/fr';
import appStore from '@/store/app';

const props = defineProps<{
  event: any;
}>();

const _appStore = appStore();

const repeatType = computed(
  () => props.event?.repeatType || props.event?.metadata?.repeatType
);

const repeatSpecificDaysOfWeek = computed(
  () =>
    props.event?.repeatSpecificDaysOfWeek ||
    props.event?.metadata?.repeatSpecificDaysOfWeek
);

const customRRuleText = computed(() => {
  const repeatRRules =
    props.event?.repeatRRules || props.event?.metadata?.repeatRRules;

  if (
    repeatType.value === CalendarReminderRepeatType.CustomRRule &&
    repeatRRules
  ) {
    const options = RRule.parseString(repeatRRules?.join('\n'));
    options.dtstart = new Date(props.event?.startDate);

    switch (_appStore.language) {
      case 'vi':
        return new RRule(options).toText((str) => {
          return Object.keys(VI.tokens).find((key) => VI.tokens[key].test(str));
        }, VI);
      case 'fr':
        return new RRule(options).toText((str) => {
          return Object.keys(FR.tokens).find((key) => FR.tokens[key].test(str));
        }, FR);
      default:
        return new RRule(options).toText();
    }
  }

  return null;
});
</script>

<template>
  <div
    v-if="repeatType && repeatType != CalendarReminderRepeatType.DoNotRepeat"
    class="flex items-center gap-4"
  >
    <div class="flex-center">
      <SynIcon name="reload" class="fill-gray-500" />
    </div>

    <div
      v-if="repeatType == CalendarReminderRepeatType.EveryDay"
      class="text-sm"
    >
      {{ $t('COMMON_LABEL_DAILY') }}
    </div>

    <div
      v-else-if="
        repeatType == CalendarReminderRepeatType.EverySpecificDayOfWeek
      "
      class="text-sm"
    >
      {{
        $t('COMMON_LABEL_WEEKLY') +
        (event?.startDate ? ` ${getOnDayOfWeekString(event.startDate)}` : '')
      }}
    </div>

    <div
      v-else-if="
        repeatType == CalendarReminderRepeatType.EverySpecificDayInMonth
      "
      class="text-sm"
    >
      {{
        $t('COMMON_LABEL_MONTHLY') +
        (event?.startDate ? ` ${getOnDateOfMonthString(event.startDate)}` : '')
      }}
    </div>

    <div
      v-else-if="
        repeatType == CalendarReminderRepeatType.EverySpecificDayInYear
      "
      class="text-sm"
    >
      {{
        $t('COMMON_LABEL_YEARLY') +
        (event?.startDate ? ` ${getOnDateOfYearString(event.startDate)}` : '')
      }}
    </div>

    <div
      v-else-if="
        repeatType == CalendarReminderRepeatType.CustomSpecificDayOfWeek
      "
      class="text-sm"
    >
      {{
        repeatSpecificDaysOfWeek
          ?.map((wd) => dayjs().day(wd).format('dddd'))
          .join(', ')
      }}
    </div>

    <div v-else-if="customRRuleText" class="text-sm">
      {{ customRRuleText }}
    </div>
  </div>
</template>
