<template>
  <div class="w-6 h-6 rounded flex-center">
    <div class="w-full h-full flex-center flex-col relative">
      <div class="w-full h-1/2 flex-center">
        <SynIcon
          name="sort-up"
          :custom-class="`h-2.5 w-2.5 ${
            orderBy == 'asc' ? 'fill-current' : ''
          }`"
        />
      </div>
      <div class="w-full h-1/2 flex-center">
        <SynIcon
          name="sort-down"
          :custom-class="`w-2.5 h-2.5 ${
            orderBy == 'desc' ? 'fill-current' : ''
          }`"
        />
      </div>
      <span
        v-if="sortIndex"
        class="h-3 text-white bg-current absolute -top-1.5 flex-center"
        style="font-size: 0.6rem; width: 0.6rem; right: -0.175rem"
      >
        {{ sortIndex }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SynSortIcon',
  components: {},
  props: {
    orderBy: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    sortIndex: {
      type: Number,
      default: null,
    },
  },
  emits: ['onSort'],
  setup() {
    return {};
  },
};
</script>
