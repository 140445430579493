<script setup lang="ts">
import { ref } from 'vue';
import ModalCalendarStatisticUser from './ModalCalendarStatisticUser.vue';
import UserById from '@/ui/components/user/UserById.vue';

defineProps({
  userId: {
    type: [String, Number],
    default: '',
  },
  isOnlyIcon: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'md',
  },
});

const isShowViewStatistic = ref(false);
</script>

<template>
  <div
    v-if="isOnlyIcon"
    :title="$t('DAYOFF_STATISTIC_TITLE_MODAL')"
    class="flex-center rounded-full w-8 h-8 hover:bg-gray-200 cursor-pointer"
    @click="isShowViewStatistic = true"
  >
    <syn-icon
      name="calendar-statistic"
      custom-class="w-6 h-6 fill-current"
      is-active
    ></syn-icon>
  </div>
  <div
    v-else
    :title="$t('DAYOFF_STATISTIC_TITLE_MODAL')"
    class="
      flex-center
      rounded-full
      w-8
      h-8
      cursor-pointer
      hover:bg-current-50
      relative
    "
    @click="isShowViewStatistic = true"
  >
    <UserById
      class="text-sm"
      :user-id="userId"
      avatar-class="w-8 h-8"
      is-hidden-name
    />
    <div class="w-6 h-6 rounded-full flex-center absolute -right-3 -bottom-1.5">
      <syn-icon
        name="calendar-statistic"
        custom-class="w-5 h-5 fill-current bg-white"
        is-active
      ></syn-icon>
    </div>
  </div>

  <ModalCalendarStatisticUser
    v-if="isShowViewStatistic"
    :user-id="userId"
    @cancel="(value) => (isShowViewStatistic = value)"
  />
</template>
