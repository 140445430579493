<script setup lang="ts">
import { ref } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import authStore from '@/store/auth';
import GetCode from '@/ui/pages/auth/GetCode.vue';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import { useField } from 'vee-validate';
import { StorageConstant, setLocalStorage } from '@/ui/hooks/storageHook';

const props = defineProps({
  email: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['cancel']);
const _authStore = authStore();

const stepVerifyCode = ref(true);
const stepResetPwd = ref(false);
const isLoadingSubmitButton = ref(false);
const msgError = ref('');
const isVerifyCode = ref(false);
const tokenResetPassword = ref({});

const onCancel = () => {
  emit('cancel', false);
};

const onResetPwd = async () => {
  isLoadingSubmitButton.value = true;
  try {
    await _authStore.forgotPassword(props.email);
  } finally {
    isLoadingSubmitButton.value = false;
  }
};

const onVerifyCode = async (code) => {
  isVerifyCode.value = true;
  try {
    const result =
      await _authStore.submitVerificationEmailUserTokenForForgotPassword({
        email: props.email,
        c: '',
        resetCode: code,
      });
    if (result?.message && !result?.email) {
      msgError.value = translate(ErrorNotificationLogin[result?.message]);
    } else msgError.value = '';
    isVerifyCode.value = false;

    tokenResetPassword.value = {
      token: result?.token,
      email: result?.email,
    };

    if (!tokenResetPassword.value.token || !tokenResetPassword.value.email)
      return;

    // router.push(`/verify-pwd-token?token=${token}&email=${email}`);
    stepResetPwd.value = true;
    stepVerifyCode.value = false;
  } catch (response) {
    isVerifyCode.value = false;
    msgError.value = translate(
      ErrorNotificationLogin[response.error.message || response.result?.message]
    );
  }
};

const { value: password, errorMessage: passError } = useField(
  'data.password',
  function (value) {
    if (!value) return translate('COMMON_THIS_FIELD_REQUIRED');
    if (value.length < 6) return translate('COMMON_LABEL_ALERT_PASS_CHARACTER');
    if (value !== passwordAgain.value && passwordAgain.value) {
      return translate('COMMON_LABEL_ALERT_ERROR_PASS');
    }
    return true;
  }
);

const { value: passwordAgain, errorMessage: passAgainError } = useField(
  'data.passwordAgain',
  function (value) {
    if (!value) return translate('COMMON_THIS_FIELD_REQUIRED');
    if (value.length < 6) return translate('COMMON_LABEL_ALERT_PASS_CHARACTER');
    if (value !== password.value && password.value) {
      return translate('COMMON_LABEL_ALERT_ERROR_PASS');
    }
    return true;
  }
);

const handleChangePWD = async () => {
  if (!password.value) {
    msgError.value = 'Password is not null';
    return;
  }

  isLoadingSubmitButton.value = true;

  try {
    const res = await _authStore.resetPassword({
      email: tokenResetPassword.value.email,
      resetToken: tokenResetPassword.value.token,
      newPassword: password.value,
    });

    if (res.error) {
      msgError.value = res.title;
      return;
    }

    // clearLocalStorage({ isSwitchingOrg: false });
    // router.push('/login');
    setLocalStorage(StorageConstant.TOKEN, res.result?.jwtToken);
    emit('cancel', true);
  } catch (error) {
    msgError.value = error?.message;
  } finally {
    isLoadingSubmitButton.value = false;
  }
};
</script>

<template>
  <syn-modal
    v-if="stepVerifyCode"
    z-index="z-50"
    container-class="md:w-1/3 h-max"
    style-body="py-8 px-2"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <span>
        {{ $t('USERMANAGEMENT_LABEL_CHANGE_PASSWORD') }}
      </span>
    </template>

    <template #body>
      <div class="flex items-center space-y-2 flex-wrap h-full">
        <div class="flex flex-wrap px-6 space-y-4">
          <get-code
            v-if="true"
            type="fotgotPWD"
            code-type="email"
            :email="email"
            :error-code="msgError"
            :is-loading="isVerifyCode"
            :is-loading-resend="isLoadingSubmitButton"
            @on-verify="(code) => onVerifyCode(code)"
            @resend-code="onResetPwd()"
          ></get-code>
        </div>
      </div>
    </template>
  </syn-modal>

  <syn-modal
    v-if="stepResetPwd"
    z-index="z-50"
    container-class="md:w-1/3 h-max"
    style-body="py-8 px-2"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <span>
        {{ $t('USERPROFILE_LABEL_RESET_PASSWORD') }}
      </span>
    </template>
    <template #body>
      <div class="px-2">
        <form>
          <div class="relative w-full flex mb-3 mt-3">
            <div
              class="
                flex
                items-center
                min-w-max
                pr-4
                text-sm text-gray-600
                font-medium
              "
            >
              <span>{{ $t('USERMANAGEMENT_LABEL_NEW_PASSWORD') }}</span>
            </div>
            <syn-form-input :error-message="passError">
              <syn-input
                v-model="password"
                class="w-full"
                autofocus
                :icon-right="showPWD ? 'eye' : 'eye-close'"
                :input-type="showPWD ? 'text' : 'password'"
                :placeholder="$t('USERMANAGEMENT_LABEL_NEW_PASSWORD')"
                :error-message="passError"
                @on-action="
                  (value) => {
                    showPWD = !showPWD;
                  }
                "
              ></syn-input>
            </syn-form-input>
          </div>
          <div class="relative flex w-full mb-3 mt-3">
            <div
              class="
                flex
                items-center
                min-w-max
                pr-4
                text-sm text-gray-600
                font-medium
              "
            >
              <span>{{ $t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM') }}</span>
            </div>
            <syn-form-input :error-message="passAgainError">
              <syn-input
                v-model="passwordAgain"
                class="w-full"
                input-type="password"
                :placeholder="$t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM')"
                :error-message="passAgainError"
                @enter="handleChangePWD()"
              ></syn-input>
            </syn-form-input>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="px-4 flex py-4 space-x-2 justify-end">
        <syn-button
          type-text
          color="gray"
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          @click="onCancel"
        >
        </syn-button>
        <syn-button
          class="m-auto"
          :label="$t('USERMANAGEMENT_LABEL_CHANGE_PASSWORD')"
          :is-loading="isLoadingSubmitButton"
          :disabled="
            !password || !passwordAgain || password !== passwordAgain
              ? true
              : false
          "
          @click="handleChangePWD()"
        >
        </syn-button>
      </div>
    </template>
  </syn-modal>
</template>
