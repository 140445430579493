<script setup lang="ts">
import { onMounted, ref, computed, nextTick } from 'vue';
import groupStore from '@/store/group';
import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import ModalDeleteGroup from '@/ui/components/group/ModalDeleteGroup.vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import router from '@/ui/router';
import myProfileStore from '@/store/auth/my-profile';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';

const emit = defineEmits(['showDetail', 'deletedSuccess']);

const isLoadingGroup = ref<boolean>(false);

const initComponent = async () => {
  isLoadingGroup.value = true;
  await _groupStore.getAllGroup();
  isLoadingGroup.value = false;
};

const _groupStore = groupStore();
const scrollTop = computed(() => _groupStore.scrollTopTable);
const scrollable = ref();

const allGroups = computed(() => _groupStore.allGroup);

onMounted(async () => {
  keySearch.value = _groupStore.filterObject.keySearch;
  statusSelect.value = _groupStore.filterObject.status;
  totalFilterItem.value = statusSelect.value != null;
  await nextTick();
  scrollable.value.scrollTop = scrollTop.value;
});

const filterGroup = computed(() => {
  const searchTxt = ignoreUnicode(keySearch.value);

  if (!searchTxt) {
    return statusSelect.value == null
      ? allGroups.value
      : allGroups.value.filter((e) => e.isPrivate === statusSelect.value);
  }

  const newArr = (allGroups.value || []).filter(
    (group) => group?.name && ignoreUnicode(group?.name).includes(searchTxt)
  );

  return statusSelect.value == null
    ? newArr
    : newArr.filter((e) => e.isPrivate === statusSelect.value);
});

const onReload = async () => {
  keySearch.value = '';
  statusSelect.value = null;
  _groupStore.resetFilter();
  initComponent();
};

// search
const keySearch = ref('');

// statusActive
const statusActive = [
  {
    status: false,
    label: 'COMMON_LABEL_PUBLIC',
    name: 'Public',
    key: 0,
  },
  {
    status: true,
    label: 'GROUP_LABEL_PRIVATE',
    name: 'Private',
    key: 1,
  },
  {
    status: null,
    label: 'COMMON_LABEL_ALL',
    name: 'All',
    key: -1,
  },
];

// FIRLTER STATUS
const totalFilterItem = ref(false);
const statusSelect = ref<boolean | null>(null);
const onChangeStatus = (statusChoose) => {
  totalFilterItem.value = statusChoose != null;
  statusSelect.value = statusChoose;
  _groupStore.filterObject.status = statusChoose;
};

const inputSearchGroup = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath.includes('group-management')
  ) {
    inputSearchGroup.value?.focus();
    e.preventDefault();
  }
});

const showDeleteGroup = ref(false);
const groupDeleteId = ref(0);
const groupDeleteName = ref('');
const groupDeleteCode = ref('');
const groupDelete = ref();
const alertAdminSystemNotHasPermission = ref(false);
const showModalRemove = (id, name, group) => {
  let canEdit =
    (!group?.isPrivate &&
      (myProfileStore().isAdmin || myProfileStore().isOwner)) ||
    group?.isAdminGroup;
  if (!canEdit && group?.groupUsers?.length > 0) {
    alertAdminSystemNotHasPermission.value = true;
  } else {
    showDeleteGroup.value = true;
    groupDeleteId.value = id;
    groupDeleteName.value = name;
    groupDeleteCode.value = group.code;
    groupDelete.value = group;
  }
};

const onScroll = (event) => {
  _groupStore.scrollTopTable = event.target.scrollTop;
};

const sortDataOrderByKey = computed(() => _groupStore.sortDataOrderByKey);

const onClickSort = (key, name) => {
  _groupStore.genSortDataList({ key, name });
  window.scrollTo(0, 0);
};

// show detail
const showDetail = (group) => {
  emit('showDetail', group);
};

const updateKeySearch = (value) => {
  _groupStore.filterObject.keySearch = value;
};

const onSearch = () => {
  keySearch.value = _groupStore.filterObject.keySearch;
};

initComponent();
</script>

<template>
  <div class="h-full bg-white flex flex-col space-y-2 p-2 overflow-y-hidden">
    <div class="flex w-full justify-between items-center py-1">
      <!-- search -->
      <div class="flex items-center w-80">
        <VigSearchBox
          ref="inputSearchGroup"
          class="w-full"
          :placeholder="$t('TASK_CREATE_GROUP_FORM_GROUP_NAME') + '...'"
          @update:model-value="updateKeySearch"
          @on-search="onSearch"
        />
      </div>

      <div class="flex items-center space-x-1">
        <span
          class="
            text-xs
            rounded
            py-1
            px-2
            border border-current-400
            text-current-500
          "
        >
          {{ `${$t('COMMON_LABEL_TOTAL')}: ${filterGroup?.length || 0}` }}
        </span>
        <span>|</span>
        <span class="rounded text-xs py-1 px-2 bg-orange-400 text-white">
          {{
            `${$t('GROUP_LABEL_PRIVATE')}: ${
              filterGroup.filter((el) => el.isPrivate)?.length || 0
            }`
          }}
        </span>
        <span class="rounded text-xs py-1 px-2 bg-current text-white">
          {{
            `${$t('COMMON_LABEL_PUBLIC')}: ${
              filterGroup.filter((el) => !el.isPrivate)?.length || 0
            }`
          }}
        </span>
      </div>
    </div>

    <!-- table -->
    <div
      ref="scrollable"
      class="flex-1 overflow-y-auto small-scrollbar"
      @scroll="onScroll"
    >
      <SynTableCustom>
        <template #header>
          <SynTr>
            <!-- <SynTh>{{ $t('TASK_TABLE_LABEL_NUM') }}</SynTh> -->
            <SynTh :label="$t('USERMANAGEMENT_TABLE_AVATAR')" />
            <SynTh :label="$t('TASK_CREATE_GROUP_FORM_GROUP_NAME')" />
            <SynTh :label="$t('USERMANAGEMENT_TABLE_STATUS')">
              <template #custom>
                <vig-dropdown>
                  <template #dropdown-toggle>
                    <div
                      v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER')"
                      class="w-6 h-6 flex-center relative"
                    >
                      <SynIcon
                        name="filter2"
                        class="cursor-pointer"
                        :custom-class="`w-3.5 h-3.5 ${
                          totalFilterItem ? 'fill-current' : 'fill-gray-500'
                        } `"
                      />
                    </div>
                  </template>
                  <template #dropdown-menu>
                    <div v-for="data in statusActive" :key="data?.key">
                      <div
                        class="
                          pl-2
                          pr-8
                          py-2
                          hover:bg-gray-50
                          cursor-pointer
                          w-32
                          dropdown-item
                          text-sm
                        "
                        :class="
                          data?.status == statusSelect
                            ? 'bg-current-50 font-medium text-current-500'
                            : ''
                        "
                        @click="onChangeStatus(data?.status)"
                      >
                        <span>{{ $t(data?.label) }}</span>
                      </div>
                    </div>
                  </template>
                </vig-dropdown>
              </template>
            </SynTh>
            <SynTh :label="$t('TASK_TABLE_LABEL_MEMBERS')" />
            <SynTh :label="$t('COMMON_LABEL_DESCRIPTION')" />
            <SynTh
              class=""
              style="width: 5rem"
              is-sort
              :label="$t('GROUP_LABEL_CREATE_DATE')"
              :order-data="sortDataOrderByKey['creationTime']"
              @on-sort="
                onClickSort('creationTime', $t('GROUP_LABEL_CREATE_DATE'))
              "
            />
            <SynTh position-text-header="justify-center" style="width: 5rem">
              <div class="flex-center w-full">
                <button
                  v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                  class="
                    flex-center
                    rounded-full
                    hover:fill-current
                    fill-gray-500
                    w-6
                    h-6
                  "
                  @click="onReload"
                >
                  <SynIcon name="reload" class="w-4 h-4" />
                </button>
              </div>
            </SynTh>
          </SynTr>
        </template>

        <template #body>
          <SynTableTrLoading v-if="isLoadingGroup">
            <div v-for="item in 6" :key="item" class="table-cell p-2 h-96">
              <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
            </div>
          </SynTableTrLoading>

          <template
            v-for="group in filterGroup"
            v-else-if="filterGroup.length > 0"
            :key="group.id"
          >
            <SynTr :id-task="'group-' + group?.id" class="hover:bg-gray-50">
              <SynTd
                class="h-full"
                style="width: 3rem"
                @click="showDetail(group)"
              >
                <div class="min-w-max flex items-center">
                  <syn-avatar
                    class="cursor-pointer text-current-800"
                    :src="group.avatar?.replace('original', 'mini')"
                    :name="group.name"
                    custom-class="w-8 h-8"
                  ></syn-avatar>
                </div>
              </SynTd>
              <SynTd
                class="cursor-pointer text-left"
                style="width: 20rem"
                @click="showDetail(group)"
              >
                <span
                  v-vig-tooltip="group.name"
                  class="
                    hover:text-current
                    font-semibold
                    overflow-hidden
                    text-overflow-hidden-line
                  "
                >
                  {{ group.name }}
                </span>
              </SynTd>

              <SynTd class="" style="width: 15rem">
                <span
                  class="px-2 py-0.5 rounded-full text-xs flex-center w-max"
                  :class="
                    group.isPrivate
                      ? 'bg-orange-400 text-white'
                      : 'bg-current text-white'
                  "
                >
                  {{
                    group.isPrivate
                      ? $t('GROUP_LABEL_PRIVATE')
                      : $t('COMMON_LABEL_PUBLIC')
                  }}
                </span>
              </SynTd>
              <SynTd style="width: 15rem">
                <div class="flex">
                  <SynListAvatar
                    :users="group.groupUsers"
                    type="USER"
                    :max-length="5"
                    custom-class="w-6 h-6"
                  />
                </div>
              </SynTd>

              <SynTd
                class="cursor-pointer text-overflow-hidden-line pr-2"
                style="max-width: 30rem"
              >
                <span
                  v-if="group?.code == 'GENERAL_GROUP'"
                  v-vig-tooltip="$t('COMMON_LABEL_DESCRIPTION_GENERAL_GROUP')"
                  class="text-overflow-hidden-line"
                >
                  {{ $t('COMMON_LABEL_DESCRIPTION_GENERAL_GROUP') }}
                </span>
                <span
                  v-else
                  v-vig-tooltip="group.description"
                  class="text-overflow-hidden-line"
                >
                  {{ group.description }}
                </span>
              </SynTd>

              <SynTd class="w-48">
                <SynLabelDateTime
                  format="date_time"
                  :datetime="group.creationTime"
                />
              </SynTd>
              <SynTd>
                <ThreeDotActions type="action" size="small" class="flex-center">
                  <div
                    class="list-li space-x-2 flex justify-start items-center"
                    @click="showDetail(group)"
                  >
                    <SynIcon
                      is-active
                      custom-class="fill-gray-500 w-4 h-4"
                      name="info"
                    />
                    <span class="text-gray-600">
                      {{ $t('COMMON_LABEL_VIEW_DETAIL') }}
                    </span>
                  </div>
                  <div
                    class="
                      list-li
                      border-t
                      space-x-2
                      flex
                      justify-start
                      items-center
                      text-red-600
                      fill-red-500
                    "
                    @click="showModalRemove(group.id, group.name, group)"
                  >
                    <SynIcon
                      name="trash"
                      is-active
                      custom-class="fill-red-500 w-4 h-4"
                    />
                    <span>
                      {{ $t('COMMON_LABEL_DELETE') }}
                    </span>
                  </div>
                </ThreeDotActions>
              </SynTd>
            </SynTr>
          </template>
          <template v-else>
            <SynTr class="h-full border-b-none" hover="hover:bg-current-50">
              <SynTd :colspan="10" style="height: calc(100vh - 16rem)">
                <SynAnimation
                  v-if="allGroups.length > 0"
                  name="searchIcon"
                  stype="width: 200px; height: 200px"
                  :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                />
                <SynAnimation
                  v-else
                  name="searchNotFound"
                  stype="width: 200px; height: 200px"
                  :label="$t('GROUP_TABLE_ALERT_NO_LIST_GROUP')"
                />
              </SynTd>
            </SynTr>
          </template>
        </template>
      </SynTableCustom>
    </div>
  </div>

  <!-- delete group -->
  <ModalDeleteGroup
    v-if="showDeleteGroup"
    :group-id="groupDeleteId"
    :group-name="groupDeleteName"
    :group-code="groupDeleteCode"
    :show-modal="showDeleteGroup"
    :group="groupDelete"
    position="manager"
    @cancel="(value) => (showDeleteGroup = value)"
    @deleted-success="$emit('deletedSuccess', groupDeleteId)"
  />

  <AlertModal
    v-if="alertAdminSystemNotHasPermission"
    :title-text="$t('COMON_NOT_ALLOW_PERFORM_ACTION')"
    :sub-title-text="$t('GROUP_DELETE_ALLOW_ADMIN_GROUP_ONLY')"
    container-class="w-1/3"
    :container-color="'current'"
    @cancel="alertAdminSystemNotHasPermission = false"
  />
</template>
