import loadScript from 'load-script';

declare const gapi: any;
declare const google: any;

export interface GoogleUser {
    email?: string;
    familyName?: string;
    givenName?: string;
    id?: string;
    displayName?: string;
    avatar?: string;
}

export default class BaseGoogleApi {
    private _gapiConfigs: any = null;
    private _authConfigs: any = null;
    private _tokenClient: any = null;
    private _token: any = null;

    public setGapiConfig(gapiConfigs) {
        this._gapiConfigs = gapiConfigs;
    }

    public setAuthConfig(authConfigs) {
        this._authConfigs = authConfigs;
    }

    public setToken(token) {
        this._token = token;
    }

    public getToken() {
        return this._token;
    }

    public async signIn() {
        await this.initClientAndSetToken();

        return new Promise<any>((resolve, reject) => {
            if (!this._tokenClient) return reject('_tokenClient not init');

            this._tokenClient.callback = async (resp) => {
                resolve(resp);
            };

            this._tokenClient.requestCode();
        });
    }

    public signOut() {
        // google?.accounts?.oauth2?.revoke(this._token?.access_token);
        // if (gapi?.client) gapi.client.setToken('');
        this._token = null;
    }

    public async getCurrentUser() {
        const token = await this.initClientAndSetToken();
        if (!token) return null;

        const res = await gapi.client.people.people.get({
            resourceName: 'people/me',
            personFields: 'names,emailAddresses,photos',
        });

        if (!res?.result) return null;

        const emailAddress = res?.result.emailAddresses?.find(() => true);
        const name = res?.result.names?.find(() => true);
        const photo = res?.result.photos?.find(() => true);

        return {
            email: emailAddress?.value,
            displayName: name?.displayName,
            givenName: name?.givenName,
            familyName: name?.familyName,
            avatar: photo?.url,
        } as GoogleUser;
    }

    public async initClientAndSetToken() {
        await Promise.all([this._initGapiClient(), this._initCodeClient()]);

        if (gapi?.client && this._token) {
            gapi.client.setToken(this._token);
        }

        return this._token;
    }

    private _initGapiClient() {
        if (!this._gapiConfigs) return;

        return new Promise<void>((resolve, reject) => {
            loadScript('https://apis.google.com/js/client.js', (err) => {
                if (err) return reject(err);

                if (typeof gapi === 'undefined') {
                    return reject('gapi is undefined');
                }

                gapi.load('client', async () => {
                    try {
                        await gapi.client.init(this._gapiConfigs);
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            });
        });
    }

    private _initCodeClient() {
        if (!this._authConfigs) return;

        return new Promise<void>((resolve, reject) => {
            loadScript('https://accounts.google.com/gsi/client', (err) => {
                if (err) return reject(err);

                if (typeof google === 'undefined') {
                    return reject('google is undefined');
                }

                this._tokenClient = google.accounts.oauth2.initCodeClient(
                    this._authConfigs
                );

                resolve();
            });
        });
    }
}
