<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import { TaskLife } from '@/domain/enums/taskEnum';
import TaskState from '@/ui/modules/task/components/TaskState.vue';
import EmbedChangeAssign from '@/embed/modules/task/EmbedChangeAssign.vue';
import UpdateDomain from '@/embed/modules/task/EmbedUpdateDomain.vue';
import { isImage } from '@/ui/hooks/fileHook';

import userStore from '@/embed/store/user-store';
import taskStore from '@/embed/store/task-store';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { allowedExtensionString } from '@/ui/modules/task/task-global-state';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import noteLogic from '@/ui/modules/task/general/note-logic';
import attachmentLogic from '@/ui/modules/task/general/attachment-logic';
import audioLogic from '@/ui/modules/task/general/audio-logic';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { formatTaskCode, TaskUrgency } from '@/ui/hooks/taskHook';
import ticketPlugin from '@/ticket/services/plugin-services';
import TaskCreateUpdatePayload from '@/domain/entities/task/TaskCreateUpdatePayload';

const _userStore = userStore();
const _taskStore = taskStore();
const myInfo = computed(() => _userStore.myInfo);
const taskDataConfig = computed(() => _taskStore.dataConfig);
const formData = ref(
  new TaskCreateUpdatePayload({
    important: true,
    priority: 1,
    assigneeId: myInfo.value?.id,
    creatorId: MyOrganizationSingleton.getInstance().myUserId,
  }) as TaskCreateUpdatePayload
);

const {
  isCreateNote,
  currentNote,
  isLoadingDrawData,
  onSaveNote,
  onCreateNote,
  onCancelNote,
  onRemoveNote,
  onEditNote,
} = noteLogic(formData);

const isDraging = ref(false);
const {
  addFiles,
  onChooseFiles,
  attachedFiles,
  attachedAudios,
  onRemoveAttachments,
} = attachmentLogic(formData, isDraging);

const { isOpenRecording, onCreateAudioDone, onCreateAudioCancel } =
  audioLogic(formData);

const onPasteImage = async (event) => {
  if (isCreateNote.value) {
    return;
  }
  var files = (event.clipboardData || event.originalEvent.clipboardData).files;
  // 1. if image file => open note
  if (files?.length == 1 && files[0].type.includes('image')) {
    onCreateNote(true, files);
    return;
  }
  // 2. If not image file => attachment
  if (files?.length > 0) {
    addFiles(files, null);
  }
};
onMounted(() => {});

const isValidate = ref(false);

const onDoItToday = (value) => {
  formData.value.doItToday(value);
};

const onFinishItToday = async (value) => {
  formData.value.finishItToday(value);
};

const onChangeTaskState = async (state: TaskLife) => {
  formData.value.changeState(state);
};

const onSavePrivate = (value) => {
  formData.value.changePrivate(value);
};

const onSaveDomain = async (domainData) => {
  formData.value.changeDomain(domainData);
};

const isCollaborate = computed(
  () =>
    formData.value?.assigneeId == getCurrentUserId() ||
    formData.value?.creatorId == getCurrentUserId()
);

const onResetForm = () => {
  isValidate.value = false;
  formData.value = new TaskCreateUpdatePayload({
    important: true,
    priority: 0,
    assigneeId: myInfo.value?.id,
    creatorId: MyOrganizationSingleton.getInstance().myUserId,
  });
};

const isCreatingLoading = ref(false);
const isResetForm = ref(false);

const errorMessageName = computed(() =>
  !formData.value?.name
    ? translate('TICKET_TASK_FORM_LABEL_SHORT_DESCRIPTION_REQUIRE') ||
      'Short description is require'
    : ''
);
const errorMessageDomain = computed(() =>
  !formData.value?.domainId
    ? translate('TICKET_TASK_FORM_LABEL_TYPE_OR_CATEGORY_REQUIRE') ||
      'Type or category is require'
    : ''
);
const errorMessageAssignee = computed(() =>
  !formData.value?.groupId && !formData.value?.assigneeId
    ? translate('TICKET_TASK_FORM_LABEL_RESPONSIBLE_IS_REQUIRED') ||
      'Responsible is require'
    : ''
);

const successMessage = ref('');
const openNotification = (taskCode) => {
  successMessage.value = `Ticket ${formatTaskCode(taskCode)} créé`;

  setTimeout(() => {
    successMessage.value = '';
  }, 4 * 1000);
};

const onSubmitForm = async () => {
  if (
    errorMessageName.value ||
    errorMessageDomain.value ||
    errorMessageAssignee.value ||
    formData.value?.urgency == null ||
    formData.value?.taskSchedule?.scheduleOptionKey == null ||
    isCreatingLoading.value
  ) {
    isValidate.value = true;
    return;
  }
  isValidate.value = false;
  try {
    isCreatingLoading.value = true;

    const newTask = await formData.value.create();

    isCreatingLoading.value = false;
    openNotification(newTask.code);
  } catch (error) {
    console.log(error);
    isCreatingLoading.value = false;
  }
};

const onChangeSchedule = (value) => {
  formData.value.changeDeadline({
    scheduleOptionKey: value,
    otherTimeKey: null,
    scheduleTime: '',
  });
};

const onChangeScheduleOther = (value) => {
  formData.value.changeDeadlineOther(value);
};

const onChangeAssign = async (group, user) => {
  // 1. change assign
  const groupId = group?.id;
  const assigneeId = user?.userId;

  formData.value.changeAssignee(groupId, assigneeId);
};

const isPreview = ref(false);
const currentFileIndex = ref(0);
const onOpenFiles = (index) => {
  currentFileIndex.value = index;
  isPreview.value = true;
};

const onCollapseNote = () => {
  window.parent.postMessage('collapse-note', '*');
};

const onCancelForm = () => {
  ticketPlugin.collapse();
};
</script>
<template>
  <div
    v-if="!isResetForm"
    class="
      w-full
      h-full
      overflow-y-auto
      flex flex-col
      text-sm
      bg-white
      relative
      space-y-4
    "
    style="max-width: 50rem"
    @paste="onPasteImage"
  >
    <div class="flex items-start space-x-2 justify-between text-xl px-4 pt-4">
      <div class="flex-1 flex-center space-x-2 relative z-20">
        <SynFormInput :error-message="isValidate && errorMessageName">
          <SynInputArea
            v-model="formData.name"
            autofocus
            maxlength="900"
            :rows="1"
            :placeholder="
              $t('TICKET_TASK_FORM_LABEL_DESCRIPTION') || 'Short description'
            "
          />
        </SynFormInput>
      </div>

      <!-- <div class="flex items-center space-x-1">
        <vig-dropdown>
          <template #dropdown-toggle>
            <PriorityTask class="cursor-pointer" :urgency="formData.urgency" />
          </template>
          <template #dropdown-menu>
            <div
              class="
                w-50
                py-1
                z-100
                list-ul
                rounded
                cursor-pointer
                dropdown-item
              "
            >
              <div
                v-for="(item, index) in [4, 3, 2, 1]"
                :key="index"
                class="list-li flex text-left justify-start"
                @click="onChangeUrgency(item)"
              >
                <PriorityTask is-label :urgency="item" />
              </div>
            </div>
          </template>
        </vig-dropdown>
      </div> -->
    </div>
    <div class="px-4 w-full">
      <SynFormInput
        class="w-max"
        :error-message="isValidate && errorMessageDomain"
      >
        <div class="flex-center hover-to-show__parent relative">
          <UpdateDomain
            :task-detail="formData"
            :user-id="formData.assigneeId"
            :group-id="formData.groupId"
            @change="onSaveDomain"
          />
        </div>
      </SynFormInput>
    </div>
    <div class="px-4 flex justify-between flex-wrap">
      <syn-tag
        class="bg-white w-max"
        custom-class="px-2"
        style="min-height: 2.5rem"
      >
        <div class="flex items-center">
          <div class="font-bold text-current-800">
            {{ $t('TASK_TABLE_LABEL_URGENCY') || 'Urgency' }}
          </div>

          <div class="flex flex-col">
            <div class="flex items-center flex-wrap">
              <syn-radio
                :key="TaskUrgency.UrgentImportant.key"
                v-model="formData.urgency"
                :value="TaskUrgency.UrgentImportant.key"
                class="p-2 rounded"
              >
                <span class="text-xs">
                  {{
                    $t('TASK_CREATE_FORM_URGENT_IMPORTANT') ||
                    'URGENT & IMPORTANT'
                  }}
                </span>
              </syn-radio>
              <syn-radio
                :key="TaskUrgency.Urgent.key"
                v-model="formData.urgency"
                :value="TaskUrgency.Urgent.key"
                class="p-2 rounded"
              >
                <span class="text-xs">
                  {{ $t('TASK_CREATE_FORM_URGENT') || 'URGENT' }}
                </span>
              </syn-radio>
              <syn-radio
                :key="TaskUrgency.Important.key"
                v-model="formData.urgency"
                :value="TaskUrgency.Important.key"
                class="p-2 rounded"
              >
                <span class="text-xs">
                  {{ $t('TASK_CREATE_FORM_IMPORTANT') || 'IMPORTANT' }}
                </span>
              </syn-radio>
              <syn-radio
                :key="TaskUrgency.Cool.key"
                v-model="formData.urgency"
                :value="TaskUrgency.Cool.key"
                class="p-2 rounded"
              >
                <span class="text-xs">
                  {{ $t('TASK_CREATE_FORM_COOL') || 'COOL' }}
                </span>
              </syn-radio>
            </div>
            <span
              v-if="isValidate && formData.urgency == null"
              class="text-red-500"
            >
              *
              {{
                $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') ||
                'is required'
              }}
            </span>
          </div>
        </div>
      </syn-tag>

      <TaskState
        :task-life="formData?.taskLife"
        @change-state="onChangeTaskState"
      />
    </div>
    <div
      class="
        flex-1
        min-h-0
        overflow-y-auto
        small-scrollbar
        px-4
        flex flex-col
        space-y-4
      "
    >
      <!-- Assign -->
      <div>
        <SynFormInput
          class="w-max"
          :error-message="isValidate && errorMessageAssignee"
        >
          <EmbedChangeAssign
            :group-id="formData.groupId"
            :user-id="formData.assigneeId"
            @change="onChangeAssign"
          />
        </SynFormInput>
      </div>
      <!-- Deadline -->
      <syn-tag v-if="formData.assigneeId" class="bg-white">
        <div class="flex flex-col space-y-2 w-full">
          <div class="flex items-center space-x-4 w-full">
            <div class="flex flex-col items-start justify-center space-y-2">
              <AtomSwitch
                v-model="formData.taskSchedule.doItToday"
                @change="onDoItToday"
              >
                <span class="font-semibold text-current-800">
                  {{ $t('TASK_CREATE_FORM_DOING_TODAY') || 'Do today' }}
                </span>
              </AtomSwitch>
              <AtomSwitch
                v-model="formData.taskSchedule.finishItToday"
                @change="onFinishItToday"
              >
                <span class="font-semibold text-current-800">
                  {{ $t('TASK_CREATE_FORM_FINISH_TODAY') || 'Finish today' }}
                </span>
              </AtomSwitch>
            </div>
            <div class="flex-1 flex flex-col">
              <div class="bg-current-50 p-2 rounded">
                <div class="flex items-center space-x-2">
                  <div class="font-bold text-current-800">
                    {{ $t('TASK_TABLE_LABEL_DEADLINE') }}
                  </div>
                  <div class="flex-1 flex flex-wrap">
                    <syn-radio
                      v-for="schedule in taskDataConfig.scheduleDayOptions"
                      :key="schedule.key"
                      v-model="formData.taskSchedule.scheduleOptionKey"
                      class="px-2 py-1"
                      :value="schedule.key"
                      :label="schedule.text"
                      :name="schedule.text"
                      @change="onChangeSchedule"
                    />
                  </div>
                </div>
              </div>
              <span
                v-if="isValidate && formData.scheduleTime == null"
                class="text-red-500"
              >
                *
                {{
                  $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') ||
                  'is required'
                }}
              </span>
            </div>
          </div>
          <div
            v-if="formData?.taskSchedule?.scheduleOptionKey == 4"
            class="flex items-center space-x-4 w-full"
          >
            <div class="font-bold text-current-800">
              {{ $t('TASK_CREATE_FORM_LABEL_OTHER_TIME') || 'Other time' }}
            </div>
            <div class="flex-1 flex items-center justify-center space-x-4">
              <syn-radio
                v-for="item in taskDataConfig.scheduleOtherTimeOptions"
                :key="item.key"
                v-model="formData.taskSchedule.otherTime"
                :value="item.key"
                :label="item.text"
                :name="item.text"
                @change="onChangeScheduleOther"
              />
            </div>
          </div>
          <div
            v-if="
              formData?.taskSchedule?.scheduleOptionKey == 4 &&
              formData?.taskSchedule?.otherTime == 3
            "
            class="flex items-center space-x-4"
          >
            <div class="font-bold text-current-800">
              {{
                $t('TASK_CREATE_FORM_LABEL_SPECIFIC_DATE') || 'Specific date'
              }}
            </div>
            <div class="flex-1 flex items-center justify-center space-x-4">
              <v-date-picker
                v-model="formData.scheduleTime"
                :locale="$locale"
                :model-config="dateConfig"
                color="current"
                :popover="{ visibility: 'click' }"
                :first-day-of-week="2"
              >
                <template #default="{ inputValue, inputEvents }">
                  <div class="relative">
                    <input
                      type="text"
                      class="vig-input"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                    <button
                      v-if="formData.scheduleTime"
                      class="
                        absolute
                        top-1/2
                        right-1
                        -mt-3
                        w-6
                        h-6
                        flex-center
                        rounded-full
                        hover:bg-gray-100
                      "
                      @click="formData.scheduleTime = ''"
                    >
                      <SynIcon name="Close" />
                    </button>
                    <SynIcon v-else name="calendar" />
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </syn-tag>

      <syn-tag v-if="!formData.assigneeId" class="bg-white m-1 w-max">
        <div class="flex items-center space-x-2">
          <div class="font-bold text-current-800">
            {{ $t('TASK_TABLE_LABEL_DEADLINE') }}
          </div>
          <div class="flex-1 flex flex-wrap">
            <v-date-picker
              v-model="formData.scheduleTime"
              :locale="$locale"
              color="current"
              :model-config="dateConfig"
              :popover="{ visibility: 'click' }"
              :first-day-of-week="2"
            >
              <template #default="{ inputValue, inputEvents }">
                <div class="relative">
                  <input
                    type="text"
                    class="vig-input"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                  <button
                    v-if="formData.scheduleTime"
                    class="
                      absolute
                      top-1/2
                      right-1
                      -mt-3
                      w-6
                      h-6
                      flex-center
                      rounded-full
                      hover:bg-gray-100
                    "
                    @click="formData.scheduleTime = ''"
                  >
                    <SynIcon name="Close" />
                  </button>
                  <SynIcon
                    v-else
                    class="absolute top-1/2 right-1 -mt-3 w-6 h-6 text-gray-500"
                    name="calendar"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </syn-tag>

      <!-- Desciption -->
      <div class="w-full flex flex-col">
        <div class="relative">
          <SynInputArea
            v-model="formData.description"
            style-name="max-height: 500px"
            class-name="bg-gray-100"
            :placeholder="
              $t('TICKET_TASK_FORM_LABEL_DETAILED_COMMENTARY') ||
              'Detailed commentary'
            "
            :rows="3"
          />
        </div>
      </div>
      <!-- Files -->
      <div>
        <div class="w-full flex items-center space-x-2 h-10">
          <div class="flex-center">
            <div>
              <label
                for="task-detail_attachment"
                class="
                  flex-center
                  space-x-2
                  cursor-pointer
                  hover:bg-current-100
                  px-2
                  py-1
                  rounded-md
                "
                :title="
                  $t('TASK_CREATE_FORM_LABEL_ADD_ATTACHMENT') ||
                  'Add attachment'
                "
              >
                <SynIcon name="attach" custom-class="fill-blue-500 h-4 w-4" />

                <span> {{ $t('TASK_CREATE_FORM_FILE') || 'Files' }} </span>
              </label>
              <input
                id="task-detail_attachment"
                type="file"
                multiple
                class="hidden opacity-0"
                :accept="allowedExtensionString"
                @change="onChooseFiles"
              />
            </div>
            <div
              class="
                flex-center
                space-x-2
                cursor-pointer
                hover:bg-current-100
                px-2
                py-1
                rounded-md
              "
              :title="$t('TICKET_TASK_FORM_LABEL_SKETCH') || 'Sketch'"
              @click="onCreateNote(false)"
            >
              <SynIcon
                name="noter"
                is-active
                custom-class="fill-orange-500 h-4 w-4 cursor-pointer"
              />
              <span>
                {{ $t('TICKET_TASK_FORM_LABEL_SKETCH') || 'Sketch' }}
              </span>
            </div>

            <!-- <SynIcon
              :title="$t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'"
              is-active
              name="microphone"
              color="blue"
              has-action
              @click="isOpenRecording = true"
            /> -->
          </div>
        </div>
        <div class="w-full items-center flex flex-wrap">
          <UploadPreview
            v-for="(file, index) in attachedFiles"
            :key="file"
            :file="file"
            @on-select="onOpenFiles(index)"
            @remove="onRemoveAttachments(file)"
          />
          <UploadPreview
            v-for="(file, index) in formData.notes?.filter(
              (o) => !o?.isDeleted
            )"
            :key="index"
            is-note
            :file="file"
            @on-select="
              onEditNote(
                file,
                attachedAudios.filter((o) => o?.noteAudioId)
              )
            "
            @remove="onRemoveNote(file)"
          />
        </div>
      </div>

      <!-- Advanced -->
      <div class="flex flex-col space-y-2">
        <div v-if="!formData.groupId && isCollaborate" class="w-full">
          <div class="flex items-center">
            <div
              class="
                flex
                items-center
                space-x-2
                border border-current-100
                p-2
                rounded
              "
            >
              <!-- <SynIcon name="private" style="fill: #128f90" /> -->
              <AtomSwitch v-model="formData.isPrivate" @change="onSavePrivate">
                <span class="text-current-800">
                  {{ $t('TASK_CREATE_MAKE_PRIVATE') }}
                </span>
              </AtomSwitch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between px-4 w-full bg-white">
      <syn-button
        :label="$t('COMMON_LABEL_RESET')"
        class="bg-gray-100"
        color="gray"
        type-text
        name-icon="reload"
        @click.stop="onResetForm"
      />
      <span
        v-if="successMessage"
        class="
          font-semibold
          text-current-800
          bg-current-100
          rounded-md
          px-4
          py-2
        "
      >
        {{ successMessage }}
      </span>
      <div class="flex-center space-x-2">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          color="gray"
          type-text
          @click="onCancelForm"
        />
        <SynButton
          :title="$t('COMMON_LABEL_CREATE')"
          :is-loading="isCreatingLoading"
          :label="$t('COMMON_LABEL_CREATE') || 'Create'"
          class="w-40"
          @click="onSubmitForm"
        />
      </div>
    </div>
    <!-- END -->
  </div>

  <CreateNoteModal
    v-if="isCreateNote"
    :draw-note-data="{
      ...currentNote,
      isLoading: isLoadingDrawData,
      images: attachedFiles.filter((o) => isImage(o)) || [],
      originFile: '',
      readonly: false,
    }"
    is-hidden-close
    @on-close="onCancelNote"
    @on-save="onSaveNote"
    @on-collapse="onCollapseNote"
  >
  </CreateNoteModal>

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onCreateAudioDone"
    @on-cancel="onCreateAudioCancel"
  />

  <ModalMultipleFileViewer
    v-if="isPreview"
    :start-index="currentFileIndex"
    :files="attachedFiles"
    @on-close="isPreview = false"
  />
</template>
