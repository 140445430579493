export const colorByAlphabet = {
    A: '#005353',
    B: '#F51480',
    C: '#2563EB',
    D: '#7C3AED',
    E: '#034C8C',
    F: '#F1C40F',
    G: '#85929E',
    H: '#ec4d4d',
    I: '#5DADE2',
    J: '#03658C',
    K: '#D411D0',
    L: '#6C11D4',
    M: '#C05D78',
    N: '#04BEC4',
    O: '#FA5B4B',
    P: '#00CBDB',
    Q: '#F22F1D',
    R: '#0095BF',
    S: '#CE225A',
    T: '#B41EEB',
    U: '#73c1c3',
    V: '#E38597',
    W: '#E95150',
    X: '#2E74E6',
    Y: '#D9042B',
    Z: '#002F42',
    0: '#670F4C',
    1: '#D97706',
    2: '#3D14F5',
    3: '#6F9B91',
    4: '#361F33',
    5: '#B9ACFA',
    6: '#F2B705',
    7: '#006480',
    8: '#56BEE3',
    9: '#56CC2B',
};

export const convertViToEn = (str, toUpperCase = false) => {
    if (!str) return '';

    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
    return toUpperCase ? str.toUpperCase() : str;
};

export const cleanString = (str) => {
    if (!str) return '';
    str = str.trim();
    str = str.replace(/[^a-zA-Z0-9 ]/g, '');
    return str;
};

export const getAvatarText = (name) => {
    if (!name) return '';

    const nameWords = cleanString(name).split(' ');

    return convertViToEn(nameWords[nameWords.length - 1].charAt(0), true);
};

export const getAvatarColor = (name) => {
    return colorByAlphabet[getAvatarText(name)] || '#4bb0b2';
};

// Helper function to convert hex to RGB
function hexToRgb(hex) {
    if (!hex)
        return {
            r: 0,
            g: 0,
            b: 0,
        };
    hex = hex.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}

// Helper function to convert RGB to hex
function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()}`;
}

// Function to mix multiple colors
export const mixColors = (colors) => {
    let totalR = 0,
        totalG = 0,
        totalB = 0;

    colors.forEach((color) => {
        const { r, g, b } = hexToRgb(color);
        totalR += r;
        totalG += g;
        totalB += b;
    });

    // Averaging the colors
    const avgR = Math.round(totalR / colors.length);
    const avgG = Math.round(totalG / colors.length);
    const avgB = Math.round(totalB / colors.length);

    // Returning the mixed color in hex format
    return rgbToHex(avgR, avgG, avgB);
};
export const genColorByName = (name) => {
    return mixColors(
        name?.split('')?.map((letter) => {
            return colorByAlphabet[convertViToEn(letter, true)];
        })
    );
};

// // Example usage with the given colors
// const colors = ['#005353', '#F51480', '#2563EB', '#7C3AED']; // A, B, C, D
