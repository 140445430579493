import { defineStore } from 'pinia';
import DashboardService from '@/application/services/DashboardService';
import storeId from '@/base/store/storeId';
import userStore from '@/store/user';
import { UserWorkingStatusType } from '@/ui/common/constants/constant';
import { getUserStatusPath } from '@/ui/modules/video-call/hooks/firebase-helper';
// import {
//     formatDateUser,
//     formatCurrentDateWithUtc,
// } from '@/ui/hooks/calendarHook';
import { updateMultiplePaths } from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import dayjs from 'dayjs';

export default defineStore({
    id: storeId.dashboard,
    state: () => ({
        _dashboardService: DashboardService.getInstance(),
        memberOndayList: [] as any[],
        userStatusList: [] as any[],
        userWorkingStatus: {},
        isHiddenSidebar: false,
    }),
    getters: {},
    actions: {
        async getMemberOnday() {
            const _userStore = userStore();
            const res = await this._dashboardService.getMemberOnday();
            const data = res['result'];
            // var merged = mergeById('id', _userStore.listShortInfosUser, data);
            const listShortUsers = _userStore.listShortInfosUser;
            this.memberOndayList = data.map((o) => {
                const index = listShortUsers.findIndex((e) => e.id == o.id);
                let adminType = 0;
                if (index > -1) adminType = listShortUsers[index].adminType;
                return { ...o, adminType };
            });
        },

        getUserStatus(userId) {
            return this.userStatusList[userId];
        },

        setUserStatus(userId, status) {
            if (!userId || !status) return;
            this.updateUserStatusList(userId, status);
            this.updateUserStatusInFirebase(userId, status);
        },

        updateUserStatusInFirebase(userId, status) {
            const orgUserStatusPath = getUserStatusPath(userId);

            updateMultiplePaths({
                [orgUserStatusPath]: status,
            });
        },

        getDetailUserInfo(userId) {
            return this.memberOndayList.find((x) => x.id === userId);
        },

        setSidebarHiddenState(isHidden) {
            this.isHiddenSidebar = isHidden;
        },

        updateUserStatusList(userId, value) {
            if (!userId || !value) return;
            this.userStatusList = { ...this.userStatusList, [userId]: value };
        },

        async updateUserWorkingStatusList(
            userId,
            status: 'online' | 'absent' | 'offline'
        ) {
            this.userWorkingStatus[userId] = {
                ...this.userWorkingStatus[userId],
                status,
            };
        },
        async updateUserWorkingStatusRealtime(data: any) {
            this.userWorkingStatus = data || {};

            const listStatus = [
                UserWorkingStatusType.Offline,
                UserWorkingStatusType.Online,
                UserWorkingStatusType.Pause,
            ];

            this.memberOndayList.forEach((el) => {
                if (
                    this.userWorkingStatus &&
                    this.userWorkingStatus[el?.id]?.status &&
                    listStatus.findIndex(
                        (li) => li === this.userWorkingStatus[el?.id]?.status
                    ) !== -1
                )
                    return;

                this.userWorkingStatus[el?.id] = {
                    status: 'offline',
                };
            });
            this.listenUserOvertimeOnListUserWorking();
        },

        listenUserOvertimeOnListUserWorking() {
            if (!this.userWorkingStatus) return;
            const _userStore = userStore();
            const listShortUsers = _userStore.listShortInfosUser;
            Object.entries(this.userWorkingStatus).forEach(
                ([userId, user]: any) => {
                    const indexIdUser = listShortUsers.findIndex(
                        (el) => el.userId == userId
                    );

                    if (indexIdUser === -1 || !user.lastUpdatedDate) {
                        this.userWorkingStatus[userId].status =
                            UserWorkingStatusType.Offline;
                        return;
                    }
                    const latestUpdateTime = dayjs.utc(user.lastUpdatedDate);
                    const isYesterday = dayjs
                        .utc(latestUpdateTime)
                        .isSame(dayjs().utc().subtract(1, 'day'), 'date');

                    const isToday = dayjs
                        .utc(latestUpdateTime)
                        .isSame(dayjs().utc(), 'date');

                    const currentUserDateTime = dayjs
                        .utc()
                        .add(listShortUsers[indexIdUser]?.utcUser, 'h');

                    let actualStatus = UserWorkingStatusType.Offline;
                    if (
                        (isYesterday && currentUserDateTime.hour() < 4) ||
                        isToday
                    )
                        actualStatus = user?.status;

                    this.userWorkingStatus[userId].status = actualStatus;
                }
            );
        },
    },
});
