<script setup lang="ts">
import workflowStore from '@/store/workflow';
import { computed } from 'vue';
const props = defineProps<{
  workflowId: string;
  workflowStepId: string;
}>();

const allWorkflowShortInfoByIds = computed<any>(() => {
  return workflowStore().allWorkflowShortInfoByIds;
});
const allWorkflowStepShortInfoByIds = computed<any>(() => {
  return workflowStore().allWorkflowStepShortInfoByIds;
});

const titleString = computed<string>(() => {
  if (
    props?.workflowId &&
    Object.prototype.hasOwnProperty.call(
      allWorkflowShortInfoByIds.value,
      props?.workflowId
    ) &&
    props?.workflowStepId &&
    Object.prototype.hasOwnProperty.call(
      allWorkflowStepShortInfoByIds.value,
      props?.workflowStepId
    )
  )
    return `${allWorkflowShortInfoByIds.value[props?.workflowId].name} - ${
      allWorkflowStepShortInfoByIds.value[props?.workflowStepId].name
    }`;
  if (
    props?.workflowId &&
    Object.prototype.hasOwnProperty.call(
      allWorkflowShortInfoByIds.value,
      props?.workflowId
    )
  )
    return `${allWorkflowShortInfoByIds.value[props?.workflowId].name}`;
  if (
    props?.workflowStepId &&
    Object.prototype.hasOwnProperty.call(
      allWorkflowStepShortInfoByIds.value,
      props?.workflowStepId
    )
  )
    return `${allWorkflowStepShortInfoByIds.value[props?.workflowStepId].name}`;
  return '';
});
const contentString = computed<string>(() => {
  if (
    props?.workflowId &&
    Object.prototype.hasOwnProperty.call(
      allWorkflowShortInfoByIds.value,
      props?.workflowId
    ) &&
    props?.workflowStepId &&
    Object.prototype.hasOwnProperty.call(
      allWorkflowStepShortInfoByIds.value,
      props?.workflowStepId
    )
  )
    return `${allWorkflowShortInfoByIds.value[props?.workflowId].code} - ${
      allWorkflowStepShortInfoByIds.value[props?.workflowStepId].name
    }`;
  if (
    props?.workflowId &&
    Object.prototype.hasOwnProperty.call(
      allWorkflowShortInfoByIds.value,
      props?.workflowId
    )
  )
    return `${allWorkflowShortInfoByIds.value[props?.workflowId].code}`;
  if (
    props?.workflowStepId &&
    Object.prototype.hasOwnProperty.call(
      allWorkflowStepShortInfoByIds.value,
      props?.workflowStepId
    )
  )
    return `${allWorkflowStepShortInfoByIds.value[props?.workflowStepId].name}`;
  return '';
});
</script>
<template>
  <span
    v-if="contentString"
    :title="titleString"
    class="w-full inline-flex text-purple-600"
  >
    <span>[</span>
    <p class="truncate text-sm">
      {{ contentString }}
    </p>
    <span>]</span>
  </span>
</template>
