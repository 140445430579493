<script setup lang="ts">
import { ref, computed, nextTick, watch, onMounted } from 'vue';
import userStore from '@/store/user';
import notificationStore from '@/store/notification/notification.store';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import groupStore from '@/store/group';
import domainStore from '@/store/scope';
import SynTabScroll from '@/ui/common/molecules/SynTab/SynTabScroll.vue';
import { arrayOrderBy, stringNomalize } from '@/ui/hooks/commonFunction';
import { ignoreUnicode } from '@/ui/plugins/utils';
// import SynSelect from '@/ui/common/atoms/SynSelect/SynSelect.vue';
import permissionStore from '@/store/permission';

const props = withDefaults(
  defineProps<{
    category?: string;
    actionsHide?: any[];
  }>(),
  {
    category: 'all',
  }
);

const userSelected = ref();
const groupSelected = ref();
const domainSelected = ref();
const notificationTypeSelected = ref();

const conferenceMenuList = {
  MEMBERS: {
    key: 'members',
    label: 'Members',
    labelKey: 'DASHBOARD_TABLE_LABEL_MEMBER',
    isSelected: false,
  },
  GROUPS: {
    key: 'groups',
    label: 'Groups',
    labelKey: 'COMMON_LABEL_GROUPS',
    isSelected: false,
  },
  DOMAINS: {
    key: 'domains',
    label: 'Domains',
    labelKey: 'COMMON_MODULE_PROJECT_MANAGEMENT',
    isSelected: false,
  },
  NOTIFICATION_TYPES: {
    key: 'types',
    label: 'Types',
    labelKey: 'COMMON_LABEL_NOTIFICATION_TYPES',
    isSelected: false,
  },
};

const currentTab = ref(conferenceMenuList['MEMBERS'].key);

const menuList = computed(() => {
  let data = [] as any;
  if (allUsers.value?.length) data = [...data, conferenceMenuList?.MEMBERS];
  if (allGroups.value?.length && props.category !== 'other')
    data = [...data, conferenceMenuList?.GROUPS];
  if (allDomains.value?.length && props.category !== 'other')
    data = [...data, conferenceMenuList?.DOMAINS];
  if (props.category === 'other' && menuNotificationType.value?.length)
    data = [...data, conferenceMenuList?.NOTIFICATION_TYPES];
  return data;
});

const emits = defineEmits([
  'isOpenFilter',
  'onMarkAllAsRead',
  'onFilterUser',
  'onFilterGroup',
  'onFilterDomain',
  'onSelectType',
]);

const _userStore = userStore();
const _notificationStore = notificationStore();
const _groupStore = groupStore();
const _domainStore = domainStore();

const filters = computed(() => _notificationStore.filters) as any;
const isVisitor = computed(() => _userStore.isVisitor);
const allUsers = computed(() => {
  const keySearch = stringNomalize(keySearchUserName.value?.toLowerCase());
  if (isVisitor.value) return [];
  const allUsers = _userStore.getDefaultUsers?.filter(
    (el) => el?.id !== userId?.value || el?.userId !== userId?.value
  );
  let allUsersFilter: any[];
  allUsersFilter = keySearch
    ? allUsers.filter((user) =>
        stringNomalize(user.name.toLowerCase()).includes(keySearch)
      )
    : [...allUsers];

  const allUsersOrderByName = allUsersFilter.map((o) => ({
    orderByName: ignoreUnicode(o.name),
    ...o,
  }));
  return arrayOrderBy(allUsersOrderByName, 'orderByName', 'asc');
});
const allGroups = computed(() => {
  const allMyGroups = _groupStore.allMyGroups.filter((el) =>
    el.groupUsers.some((o) => o.userId === userId.value)
  );
  const allMyGroupsOrderBy = allMyGroups.map((o) => ({
    orderByName: ignoreUnicode(o.name),
    ...o,
  }));
  return arrayOrderBy(allMyGroupsOrderBy, 'orderByName', 'asc');
});
const allDomains = computed(() => {
  const allMyDomains = _domainStore.allDomains.filter((el) =>
    el.members.some((o) => o.id === userId.value)
  );
  const allMyDomainsOrderBy = allMyDomains.map((o: any) => ({
    oderByName: ignoreUnicode(o.name),
    ...o,
  }));
  return arrayOrderBy(allMyDomainsOrderBy, 'oderByName', 'asc');
});

const userId = ref();
userId.value = getCurrentUserId();

const isOpenFilter = ref(false);

const keySearchUserName = ref(null) as any;

const numberOfUsers = computed(
  () =>
    _userStore.getDefaultUsers?.filter(
      (el) => el?.id !== userId?.value || el?.userId !== userId?.value
    ).length
);

watch(
  () => props.category,
  () => {
    conferenceMenuList.MEMBERS.isSelected = false;
    conferenceMenuList.GROUPS.isSelected = false;
    conferenceMenuList.DOMAINS.isSelected = false;
    conferenceMenuList.NOTIFICATION_TYPES.isSelected = false;
    currentTab.value = conferenceMenuList.MEMBERS.key;
    keySearchUserName.value = null;
    keepActionBy();
    keepGroupId();
    keepDomainId();
    keepNotificationType();
  }
);

const menuNotificationType = ref<any[]>([
  // {
  //   key: 'group',
  //   text: 'COMMON_LABEL_GROUP',
  // },
  // {
  //   key: 'dayoff',
  //   text: 'DAYOFF_REQUEST_TITLE',
  // },
  // {
  //   key: 'ged',
  //   text: 'GED_FILTER_DOCUMENT',
  // },
  // {
  //   key: 'calendar',
  //   text: 'CALENDAR_FILTER_TITLE_TYPE_EVENT',
  // },
  // {
  //   key: 'system',
  //   text: 'COMMON_LABEL_SYSTEM',
  // },
]);

onMounted(() => {
  if (permissionStore().organizationModel !== 'PERSONAL')
    menuNotificationType.value.push({
      key: 'dayoff',
      text: 'DAYOFF_REQUEST_TITLE',
    });

  menuNotificationType.value.push({
    key: 'calendar',
    text: 'CALENDAR_FILTER_TITLE_TYPE_EVENT',
  });
});

const keepActionBy = () => {
  let arrayActionBy = filters.value[props.category].actionBy;
  if (arrayActionBy?.length < 1) return (userSelected.value = null);
  else {
    let firstUId = arrayActionBy[0];
    if (firstUId) {
      conferenceMenuList.MEMBERS.isSelected = true;
      currentTab.value = conferenceMenuList.MEMBERS.key;
    }
    return (userSelected.value = allUsers.value.find(
      (el: any) => el?.id == firstUId
    ));
  }
};

const keepGroupId = () => {
  let arrayGroup = filters.value[props.category].groups || [];
  if (arrayGroup?.length < 1) return (groupSelected.value = null);
  else {
    let firstUId = arrayGroup[0];
    if (firstUId) {
      conferenceMenuList.GROUPS.isSelected = true;
      currentTab.value = conferenceMenuList.GROUPS.key;
    }
    return (groupSelected.value = allGroups.value.find(
      (el: any) => el?.id == firstUId
    ));
  }
};

const keepDomainId = () => {
  let arrayDomain = filters.value[props.category].domains || [];
  if (arrayDomain?.length < 1) return (domainSelected.value = null);
  else {
    let firstUId = arrayDomain[0];
    if (firstUId) {
      conferenceMenuList.DOMAINS.isSelected = true;
      currentTab.value = conferenceMenuList.DOMAINS.key;
    }
    return (domainSelected.value = allDomains.value.find(
      (el: any) => el?.id == firstUId
    ));
  }
};

const keepNotificationType = () => {
  let arrayNotiType = filters.value[props.category].types || [];
  if (arrayNotiType?.length < 1) return (notificationTypeSelected.value = null);
  else {
    let firstUId = arrayNotiType[0];
    if (firstUId) {
      conferenceMenuList.NOTIFICATION_TYPES.isSelected = true;
      currentTab.value = conferenceMenuList.NOTIFICATION_TYPES.key;
    }
    return (notificationTypeSelected.value = menuNotificationType.value?.find(
      (el) => el?.key == firstUId
    ));
  }
};

const openFilter = () => {
  isOpenFilter.value = true;
  emits('isOpenFilter', isOpenFilter.value);

  if (
    !userSelected.value &&
    !groupSelected.value &&
    !domainSelected.value &&
    !notificationTypeSelected.value
  )
    currentTab.value = conferenceMenuList.MEMBERS.key;

  if (userSelected.value && currentTab.value === conferenceMenuList.MEMBERS.key)
    handleScrollToUserSelected();
  if (groupSelected.value && currentTab.value === conferenceMenuList.GROUPS.key)
    handleScrollToGroupSelected();
  if (
    domainSelected.value &&
    currentTab.value === conferenceMenuList.DOMAINS.key
  )
    handleScrollToDomainSelected();
  if (
    notificationTypeSelected.value &&
    currentTab.value === conferenceMenuList.NOTIFICATION_TYPES.key
  )
    handleScrollToNotiTypeSelected();
};

const handleScrollToUserSelected = async () => {
  await nextTick();

  if (!userSelected.value) return;
  let idElementUserSelected = `#vig-filter-notification-${userSelected?.value?.id}`;
  (document as any).querySelector(idElementUserSelected).scrollIntoView({
    behavior: 'smooth',
  });
};

const handleScrollToGroupSelected = async () => {
  await nextTick();

  if (!groupSelected.value) return;
  let idElementGroupSelected = `#vig-filter-notification-${groupSelected?.value?.id}`;
  (document as any).querySelector(idElementGroupSelected).scrollIntoView({
    behavior: 'smooth',
  });
};

const handleScrollToDomainSelected = async () => {
  await nextTick();

  if (!domainSelected.value) return;
  let idElementDomainSelected = `#vig-filter-notification-${domainSelected?.value?.id}`;
  (document as any).querySelector(idElementDomainSelected).scrollIntoView({
    behavior: 'smooth',
  });
};

const handleScrollToNotiTypeSelected = async () => {
  await nextTick();

  if (!notificationTypeSelected.value) return;
  let idElementDomainSelected = `#vig-filter-notification-${notificationTypeSelected?.value?.key}`;
  (document as any).querySelector(idElementDomainSelected).scrollIntoView({
    behavior: 'smooth',
  });
};

const closeFilter = () => {
  isOpenFilter.value = false;
  emits('isOpenFilter', isOpenFilter.value);
};

const onResetClick = () => {
  onSelectAllUser();
  onSelectAllGroup();
  onSelectAllDomain();
  onSelectAllNotificationType();
};

const onSelectAllUser = () => {
  userSelected.value = null;
  conferenceMenuList.MEMBERS.isSelected = false;
  emits('onFilterUser', null);
};

const onSelectAllGroup = () => {
  groupSelected.value = null;
  conferenceMenuList.GROUPS.isSelected = false;
  emits('onFilterGroup', null);
};

const onSelectAllDomain = () => {
  domainSelected.value = null;
  conferenceMenuList.DOMAINS.isSelected = false;
  emits('onFilterDomain', null);
};

const onSelectAllNotificationType = () => {
  notificationTypeSelected.value = null;
  conferenceMenuList.NOTIFICATION_TYPES.isSelected = false;
  emits('onSelectType', null);
};

const onChooseUser = (user) => {
  userSelected.value = user;
  conferenceMenuList.MEMBERS.isSelected = true;
  emits('onFilterUser', user?.id);
};

const onChooseGroup = (group) => {
  groupSelected.value = group;
  conferenceMenuList.GROUPS.isSelected = true;
  emits('onFilterGroup', group?.id);
};

const onChooseDomain = (domain) => {
  domainSelected.value = domain;
  conferenceMenuList.DOMAINS.isSelected = true;
  emits('onFilterDomain', domain?.id);
};

const onChooseNotificationType = (type) => {
  notificationTypeSelected.value = type;
  conferenceMenuList.NOTIFICATION_TYPES.isSelected = true;
  emits('onSelectType', type.key);
};
const isOpen = ref<boolean>(false);
</script>

<template>
  <VigDropdown
    v-model:is-open="isOpen"
    placement="left-end"
    @on-dropdown-open="openFilter()"
    @on-dropdown-close="closeFilter()"
  >
    <template #dropdown-toggle>
      <div class="relative flex-center">
        <button
          class="
            rounded-full
            hover:bg-gray-50 hover:fill-current
            fill-gray-500
            p-1
            w-7
            h-7
            flex-center
          "
          :class="
            userSelected ||
            domainSelected ||
            groupSelected ||
            notificationTypeSelected
              ? 'bg-current-50'
              : 'bg-white'
          "
        >
          <SynIcon name="DotsVertical" />
        </button>
        <div
          v-if="
            userSelected ||
            groupSelected ||
            domainSelected ||
            notificationTypeSelected
          "
          class="absolute top-0 right-0 w-2.5 h-2.5 bg-current-500 rounded-full"
        ></div>
      </div>
    </template>

    <template #dropdown-menu>
      <div class="flex flex-col py-1" style="max-height: 27rem; width: 20rem">
        <div
          v-if="!actionsHide?.includes('mark_all_as_reads')"
          class="
            flex
            items-center
            text-gray-500
            fill-gray-500
            text-sm
            py-2
            cursor-pointer
            hover:bg-gray-100
            border-b
            px-2
            space-x-2
          "
          @click="$emit('onMarkAllAsRead', true)"
        >
          <SynIcon name="select-all-read" custom-class="w-4 h-4"></SynIcon>
          <div>
            {{ $t('NOTIFICATION_MARK_READ_ALL') || 'Mark all as read' }}
          </div>
        </div>

        <div class="py-1 flex-1 flex flex-col" style="min-height: 0">
          <template
            v-if="
              category === 'new' ||
              category === 'updated' ||
              category === 'finished' ||
              category === 'other'
            "
          >
            <div class="flex-1 flex flex-col" style="min-height: 0">
              <SynTabScroll v-model="currentTab" :menu-list="menuList">
                <template #prefix>
                  <div
                    class="
                      text-xs
                      flex-center
                      space-x-1
                      justify-end
                      absolute
                      right-0
                      p-2
                    "
                  >
                    <div class="h-6 border-l mx-2"></div>
                    <button
                      class="
                        px-2
                        py-1
                        bg-white
                        hover:bg-current-50
                        border border-current-500
                        text-current-500
                        rounded-full
                      "
                      @click="onResetClick"
                    >
                      {{ $t('COMMON_LABEL_RESET') || 'Reset' }}
                    </button>
                  </div>
                </template>
                <template #body>
                  <div
                    class="flex-1 overflow-auto small-scrollbar pb-3"
                    style="scroll-behavior: smooth"
                  >
                    <div class="flex flex-col">
                      <!--                Filter user-->
                      <div
                        :id="`syn-tab-scroll-item-${conferenceMenuList?.MEMBERS?.key}`"
                        class="flex-1 overflow-auto small-scrollbar p-1"
                      >
                        <div
                          v-if="allUsers?.length > 0"
                          class="
                            p-2
                            uppercase
                            text-sm
                            font-semibold
                            tracking-widest
                            text-gray-500
                          "
                        >
                          {{
                            $t(conferenceMenuList?.MEMBERS?.labelKey) ||
                            'Members'
                          }}
                        </div>
                        <div
                          class="
                            flex
                            items-center
                            text-gray-500
                            fill-gray-500
                            text-sm
                            py-2
                            cursor-pointer
                            hover:bg-gray-100
                            px-2
                            space-x-2
                          "
                          @click="onSelectAllUser"
                        >
                          <span
                            class="w-3.5 h-3.5 rounded-full flex-center"
                            :class="
                              userSelected
                                ? 'border bg-white'
                                : 'bg-current-400'
                            "
                          >
                            <SynIcon
                              v-if="!userSelected"
                              name="check"
                              custom-class="w-3 h-3 fill-white text-white"
                            ></SynIcon>
                          </span>

                          <div
                            :class="
                              !userSelected
                                ? 'font-medium text-current-600'
                                : ''
                            "
                          >
                            {{ $t('COMMON_LABEL_ALL') || 'All' }}
                          </div>
                        </div>
                        <div v-for="user in allUsers" :key="user">
                          <div
                            :id="`vig-filter-notification-${user?.id}`"
                            class="
                              flex
                              items-center
                              text-gray-500
                              fill-gray-500
                              text-sm
                              py-1.5
                              cursor-pointer
                              hover:bg-gray-100
                              border-b border-gray-50
                              px-2
                              space-x-2
                            "
                            @click="onChooseUser(user)"
                          >
                            <span
                              class="w-3.5 h-3.5 rounded-full flex-center"
                              :class="
                                !userSelected ||
                                (userSelected && userSelected?.id !== user?.id)
                                  ? 'border bg-white'
                                  : 'bg-current-400'
                              "
                            >
                              <SynIcon
                                v-if="
                                  userSelected && userSelected?.id === user?.id
                                "
                                name="check"
                                custom-class="w-3 h-3 fill-white text-white"
                              ></SynIcon>
                            </span>
                            <div class="flex space-x-2 items-center">
                              <div class="w-6 h-6 relative">
                                <syn-avatar
                                  :src="
                                    user.avatarUrl
                                      ? user.avatarUrl?.replace(
                                          'original',
                                          'mini'
                                        )
                                      : user.avatar
                                      ? user.avatar?.replace('original', 'mini')
                                      : ''
                                  "
                                  type="user"
                                  :name="user.name"
                                  custom-class="w-6 h-6"
                                />
                              </div>
                              <span
                                class="text-overflow-hidden-line"
                                :class="
                                  userSelected && userSelected?.id === user?.id
                                    ? 'font-medium text-current-600'
                                    : ''
                                "
                              >
                                {{ user.name }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--                Filter Group-->
                      <div
                        v-if="category !== 'other'"
                        :id="`syn-tab-scroll-item-${conferenceMenuList?.GROUPS?.key}`"
                        class="flex-1 overflow-auto small-scrollbar p-1"
                      >
                        <div
                          v-if="allGroups?.length > 0"
                          class="
                            p-2
                            uppercase
                            text-sm
                            font-semibold
                            tracking-widest
                            text-gray-500
                          "
                        >
                          {{
                            $t(conferenceMenuList?.GROUPS?.labelKey) || 'Groups'
                          }}
                        </div>
                        <div
                          class="
                            flex
                            items-center
                            text-gray-500
                            fill-gray-500
                            text-sm
                            py-2
                            cursor-pointer
                            hover:bg-gray-100
                            px-2
                            space-x-2
                          "
                          @click="onSelectAllGroup"
                        >
                          <span
                            class="w-3.5 h-3.5 rounded-full flex-center"
                            :class="
                              groupSelected
                                ? 'border bg-white'
                                : 'bg-current-400'
                            "
                          >
                            <SynIcon
                              v-if="!groupSelected"
                              name="check"
                              custom-class="w-3 h-3 fill-white text-white"
                            ></SynIcon>
                          </span>

                          <div
                            :class="
                              !groupSelected
                                ? 'font-medium text-current-600'
                                : ''
                            "
                          >
                            {{ $t('COMMON_LABEL_ALL') || 'All' }}
                          </div>
                        </div>
                        <div v-for="group in allGroups" :key="group">
                          <div
                            :id="`vig-filter-notification-${group?.id}`"
                            class="
                              flex
                              items-center
                              text-gray-500
                              fill-gray-500
                              text-sm
                              py-1.5
                              cursor-pointer
                              hover:bg-gray-100
                              border-b border-gray-50
                              px-2
                              space-x-2
                            "
                            @click="onChooseGroup(group)"
                          >
                            <span
                              class="w-3.5 h-3.5 rounded-full flex-center"
                              :class="
                                !groupSelected ||
                                (groupSelected &&
                                  groupSelected?.id !== group?.id)
                                  ? 'border bg-white'
                                  : 'bg-current-400'
                              "
                            >
                              <SynIcon
                                v-if="
                                  groupSelected &&
                                  groupSelected?.id === group?.id
                                "
                                name="check"
                                custom-class="w-3 h-3 fill-white text-white"
                              ></SynIcon>
                            </span>
                            <div class="flex space-x-2 items-center">
                              <div class="w-6 h-6 relative">
                                <syn-avatar
                                  :src="
                                    group.avatarUrl
                                      ? group.avatarUrl?.replace(
                                          'original',
                                          'mini'
                                        )
                                      : group.avatar
                                      ? group.avatar?.replace(
                                          'original',
                                          'mini'
                                        )
                                      : ''
                                  "
                                  type="user"
                                  :name="group.name"
                                  custom-class="w-6 h-6"
                                />
                              </div>
                              <span
                                class="text-overflow-hidden-line"
                                :class="
                                  groupSelected &&
                                  groupSelected?.id === group?.id
                                    ? 'font-medium text-current-600'
                                    : ''
                                "
                              >
                                {{ group.name }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--                Filter by domain-->
                      <div
                        v-if="category !== 'other'"
                        :id="`syn-tab-scroll-item-${conferenceMenuList?.DOMAINS?.key}`"
                        class="flex-1 overflow-auto small-scrollbar p-1"
                      >
                        <div
                          v-if="allDomains?.length > 0"
                          class="
                            p-2
                            uppercase
                            text-sm
                            font-semibold
                            tracking-widest
                            text-gray-500
                          "
                        >
                          {{
                            $t(conferenceMenuList?.DOMAINS?.labelKey) ||
                            'Domains'
                          }}
                        </div>
                        <div
                          class="
                            flex
                            items-center
                            text-gray-500
                            fill-gray-500
                            text-sm
                            py-2
                            cursor-pointer
                            hover:bg-gray-100
                            px-2
                            space-x-2
                          "
                          @click="onSelectAllDomain"
                        >
                          <span
                            class="w-3.5 h-3.5 rounded-full flex-center"
                            :class="
                              domainSelected
                                ? 'border bg-white'
                                : 'bg-current-400'
                            "
                          >
                            <SynIcon
                              v-if="!domainSelected"
                              name="check"
                              custom-class="w-3 h-3 fill-white text-white"
                            ></SynIcon>
                          </span>

                          <div
                            :class="
                              !domainSelected
                                ? 'font-medium text-current-600'
                                : ''
                            "
                          >
                            {{ $t('COMMON_LABEL_ALL') || 'All' }}
                          </div>
                        </div>
                        <div v-for="domain in allDomains" :key="domain">
                          <div
                            :id="`vig-filter-notification-${domain?.id}`"
                            class="
                              flex
                              items-center
                              text-gray-500
                              fill-gray-500
                              text-sm
                              py-1.5
                              cursor-pointer
                              hover:bg-gray-100
                              border-b border-gray-50
                              px-2
                              space-x-2
                            "
                            @click="onChooseDomain(domain)"
                          >
                            <span
                              class="w-3.5 h-3.5 rounded-full flex-center"
                              :class="
                                !domainSelected ||
                                (domainSelected &&
                                  domainSelected?.id !== domain?.id)
                                  ? 'border bg-white'
                                  : 'bg-current-400'
                              "
                            >
                              <SynIcon
                                v-if="
                                  domainSelected &&
                                  domainSelected?.id === domain?.id
                                "
                                name="check"
                                custom-class="w-3 h-3 fill-white text-white"
                              ></SynIcon>
                            </span>
                            <div class="flex space-x-2 items-center">
                              <span
                                class="text-overflow-hidden-line"
                                :class="
                                  domainSelected &&
                                  domainSelected?.id === domain?.id
                                    ? 'font-medium text-current-600'
                                    : ''
                                "
                              >
                                {{ domain.name }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--                      Filter by Notification Type-->
                      <div
                        v-if="category === 'other'"
                        :id="`syn-tab-scroll-item-${conferenceMenuList?.NOTIFICATION_TYPES?.key}`"
                        class="flex-1 overflow-auto small-scrollbar p-1"
                      >
                        <div
                          v-if="menuNotificationType?.length > 0"
                          class="
                            p-2
                            uppercase
                            text-sm
                            font-semibold
                            tracking-widest
                            text-gray-500
                          "
                        >
                          {{
                            $t(
                              conferenceMenuList?.NOTIFICATION_TYPES?.labelKey
                            ) || 'Domains'
                          }}
                        </div>
                        <div
                          class="
                            flex
                            items-center
                            text-gray-500
                            fill-gray-500
                            text-sm
                            py-2
                            cursor-pointer
                            hover:bg-gray-100
                            px-2
                            space-x-2
                          "
                          @click="onSelectAllNotificationType"
                        >
                          <span
                            class="w-3.5 h-3.5 rounded-full flex-center"
                            :class="
                              notificationTypeSelected
                                ? 'border bg-white'
                                : 'bg-current-400'
                            "
                          >
                            <SynIcon
                              v-if="!notificationTypeSelected"
                              name="check"
                              custom-class="w-3 h-3 fill-white text-white"
                            ></SynIcon>
                          </span>

                          <div
                            :class="
                              !notificationTypeSelected
                                ? 'font-medium text-current-600'
                                : ''
                            "
                          >
                            {{ $t('COMMON_LABEL_ALL') || 'All' }}
                          </div>
                        </div>
                        <div
                          v-for="type in menuNotificationType"
                          :key="type.key"
                        >
                          <div
                            :id="`vig-filter-notification-${type.key}`"
                            class="
                              flex
                              items-center
                              text-gray-500
                              fill-gray-500
                              text-sm
                              py-1.5
                              cursor-pointer
                              hover:bg-gray-100
                              border-b border-gray-50
                              px-2
                              space-x-2
                            "
                            @click="onChooseNotificationType(type)"
                          >
                            <span
                              class="w-3.5 h-3.5 rounded-full flex-center"
                              :class="
                                !notificationTypeSelected ||
                                (notificationTypeSelected &&
                                  notificationTypeSelected?.key !== type?.key)
                                  ? 'border bg-white'
                                  : 'bg-current-400'
                              "
                            >
                              <SynIcon
                                v-if="
                                  notificationTypeSelected &&
                                  notificationTypeSelected?.key === type?.key
                                "
                                name="check"
                                custom-class="w-3 h-3 fill-white text-white"
                              ></SynIcon>
                            </span>
                            <div class="flex space-x-2 items-center">
                              <span
                                class="text-overflow-hidden-line"
                                :class="
                                  notificationTypeSelected &&
                                  notificationTypeSelected?.key === type.key
                                    ? 'font-medium text-current-600'
                                    : ''
                                "
                              >
                                {{ $t(type.text) }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </SynTabScroll>
            </div>
          </template>

          <template v-else>
            <div class="flex flex-col space-y-1.5 px-2">
              <span class="font-medium text-sm">{{
                $t('NOTIFICATION_LABEL_FILTER_ACTIONBY')
              }}</span>
              <VigSearchBox
                v-if="numberOfUsers >= 10"
                :key="isOpen"
                v-model="keySearchUserName"
                class="w-full bg-white"
                input-class="text-sm"
                autofocus
              />
            </div>
            <div class="flex align-center justify-start w-full">
              <div
                class="
                  flex
                  items-center
                  text-gray-500
                  fill-gray-500
                  text-sm
                  py-2
                  cursor-pointer
                  px-2
                  space-x-2
                "
                @click="onSelectAllUser"
              >
                <span
                  class="w-3.5 h-3.5 rounded-full flex-center"
                  :class="userSelected ? 'border bg-white' : 'bg-current-400'"
                >
                  <SynIcon
                    v-if="!userSelected"
                    name="check"
                    custom-class="w-3 h-3 fill-white text-white"
                  ></SynIcon>
                </span>

                <div
                  :class="!userSelected ? 'font-medium text-current-600' : ''"
                >
                  {{ $t('COMMON_LABEL_ALL') || 'All' }}
                </div>
              </div>
            </div>
            <div class="h-64 overflow-hidden">
              <div class="h-full small-scrollbar overflow-auto">
                <template v-for="user in allUsers" :key="user">
                  <div
                    :id="`vig-filter-notification-${user?.id}`"
                    class="
                      flex
                      items-center
                      text-gray-500
                      fill-gray-500
                      text-sm
                      py-1.5
                      cursor-pointer
                      hover:bg-gray-100
                      border-b border-gray-50
                      px-2
                      space-x-2
                    "
                    @click="onChooseUser(user)"
                  >
                    <span
                      class="w-3.5 h-3.5 rounded-full flex-center"
                      :class="
                        !userSelected ||
                        (userSelected && userSelected?.id !== user?.id)
                          ? 'border bg-white'
                          : 'bg-current-400'
                      "
                    >
                      <SynIcon
                        v-if="userSelected && userSelected?.id === user?.id"
                        name="check"
                        custom-class="w-3 h-3 fill-white text-white"
                      ></SynIcon>
                    </span>
                    <div class="flex space-x-2 items-center">
                      <div class="w-6 h-6 relative">
                        <syn-avatar
                          :src="
                            user.avatarUrl
                              ? user.avatarUrl?.replace('original', 'mini')
                              : user.avatar
                              ? user.avatar?.replace('original', 'mini')
                              : ''
                          "
                          type="user"
                          :name="user.name"
                          custom-class="w-6 h-6"
                        />
                      </div>
                      <span
                        class="text-overflow-hidden-line"
                        :class="
                          userSelected && userSelected?.id === user?.id
                            ? 'font-medium text-current-600'
                            : ''
                        "
                      >
                        {{ user.name }}
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
