import { getFileExtension } from '@/ui/hooks/fileHook';
import {
    getKindOfFileByType,
    KindOfFile,
    ModuleSourceFile,
} from '@/ui/plugins/awss3/AwsS3Type';

export class FilePayloadClass {
    localId?: string;
    bucket: string;
    documentType: KindOfFile;
    extension: string;
    fileName: string;
    module: ModuleSourceFile;
    path: string;
    size: number;
    type: string;
    creationTime: Date;
    fileUrl: string;
    isUploadError?: boolean;

    constructor(data) {
        this.localId = data?.localId;
        this.bucket = data?.bucket;
        this.documentType =
            data?.documentType?.toLowerCase() ||
            getKindOfFileByType(data?.type || data?.contentType);
        this.fileName = data?.fileName;
        this.type = data?.type || data?.contentType;
        this.extension =
            data?.extension || getFileExtension(this.fileName, this.type);
        this.module = data?.module;
        this.path = data?.path;
        this.size = data?.size;
        this.creationTime = data?.creationTime || new Date();
        this.fileUrl = data?.fileUrl || '';
        this.isUploadError = false;
    }
}
export class NotePayloadClass {
    id: string;
    localId?: string;
    bucket: string;
    drawData: string;
    path: string;
    imagePath: string;
    fileUrl?: string;
    creationTime: Date;
    constructor(data) {
        this.id = data?.id;
        this.localId = data?.localId;
        this.bucket = data?.bucket;
        this.drawData =
            typeof data?.drawData == 'string'
                ? data?.drawData
                : JSON.stringify(data?.drawData);
        this.path = data?.path;
        this.imagePath = this.path;
        this.fileUrl = data?.fileUrl;
        this.creationTime = data?.creationTime || new Date();
    }
}
