// import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { TaskTemplateEntity } from '@/domain/entities/task/TaskTemplateEntity';
import ITaskWorkflowStepEntity from '@/domain/entities/task/TaskWorkflowEntity';
import TaskWorkflowStepEntity from '@/domain/entities/task/TaskWorkflowStepEntity';
import { TaskLife } from '@/domain/enums/taskEnum';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { getObjectDiff } from '@/ui/hooks/commonFunction';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ref } from 'vue';

export default function taskWorkflowStepComposables(
    originalWorkflowStep,
    workflowStepDefault,
    taskDetailDefault,
    isFinalStep
) {
    const originalWorkflowStepData = ref<ITaskWorkflowStepEntity>({
        ...(originalWorkflowStep || workflowStepDefault),
        taskId: taskDetailDefault?.id || undefined,
    });
    const workflowStepData = ref<TaskWorkflowStepEntity>(
        new TaskWorkflowStepEntity({
            ...workflowStepDefault,
            taskId: taskDetailDefault?.id,
            taskWorkflowId: taskDetailDefault?.workFlowTaskId,
        })
    );

    const currentTaskTemplate = ref<TaskTemplateEntity>(
        new TaskTemplateEntity({
            id: 'draft',
            groupId: workflowStepDefault?.groupId || undefined,
            assigneeId: workflowStepDefault?.assigneeId || undefined,
            domainId: workflowStepDefault?.domainId,
            important: workflowStepDefault?.important,
            priority: workflowStepDefault?.priority,
            description: workflowStepDefault?.taskDescription,
            startTime: workflowStepDefault?.startTime,
            scheduleTime: workflowStepDefault?.scheduleTime,
            taskSchedule: {
                scheduleOptionKey: workflowStepDefault?.scheduleDayOption,
                scheduleDateValue: workflowStepDefault?.scheduleTime,
            },
            files:
                workflowStepDefault?.files?.map((file) => {
                    return { ...file, editStatus: 'ORIGINAL' };
                }) || [],
            notes:
                workflowStepDefault?.notes?.map((file) => {
                    return { ...file, editStatus: 'ORIGINAL' };
                }) || [],
            taskTodos: workflowStepDefault?.todoLists,
            ...workflowStepDefault?.relatedTask,
            collaborators: workflowStepDefault?.collaborators
                ? workflowStepDefault?.collaborators?.map((u) => {
                      return {
                          memberUserId: u?.userId,
                          memberRoleId: u?.roleId,
                      };
                  })
                : [],
        })
    );

    const initData = (workflowStep, taskDetail) => {
        originalWorkflowStepData.value = {
            ...workflowStep,
            taskId: taskDetail?.id || undefined,
        };
        workflowStepData.value = new TaskWorkflowStepEntity({
            ...workflowStep,
            taskId: taskDetail?.id,
            taskWorkflowId: taskDetail?.workFlowTaskId,
        });

        currentTaskTemplate.value = new TaskTemplateEntity({
            id: 'draft',
            groupId: workflowStep?.groupId || undefined,
            assigneeId: workflowStep?.assigneeId || undefined,
            domainId: workflowStep?.domainId,
            important: workflowStep?.important,
            priority: workflowStep?.priority,
            description: workflowStep?.taskDescription,
            startTime: workflowStep?.startTime,
            scheduleTime: workflowStep?.scheduleTime,
            taskSchedule: {
                scheduleOptionKey: workflowStep?.scheduleDayOption,
                scheduleDateValue: workflowStep?.scheduleTime,
            },
            files:
                workflowStep?.files?.map((file) => {
                    return { ...file, editStatus: 'ORIGINAL' };
                }) || [],
            notes:
                workflowStep?.notes?.map((file) => {
                    return { ...file, editStatus: 'ORIGINAL' };
                }) || [],
            taskTodos: workflowStep?.todoLists,
            collaborators: workflowStepDefault?.collaborators
                ? workflowStepDefault?.collaborators?.map((u) => {
                      return {
                          memberUserId: u?.userId,
                          memberRoleId: u?.roleId,
                      };
                  })
                : [],
        });
    };
    const updateWorkflowStepFromTaskTemplate = (callback?) => {
        workflowStepData.value = updateStepFromTask(
            originalWorkflowStepData.value,
            workflowStepData.value,
            currentTaskTemplate.value
        );

        callback && callback();
    };

    const reopenStep = async () => {
        settingGlobalModal({
            type: 'notification',
            title: '',
            content:
                taskDetailDefault?.taskLife == TaskLife.Finished
                    ? translate('WORKFLOW_LABEL_WARNING_WHEN_REOPEN_STEP_2')
                    : translate('WORKFLOW_LABEL_WARNING_WHEN_REOPEN_STEP'),
            cancelLabel: translate('COMMON_LABEL_CANCEL'),
            confirmLabel: translate('COMMON_LABEL_CONFIRM'),
            confirmable: true,
            closeable: true,
        });
        const confirmed = await ask();
        if (!confirmed) return;

        new TaskDetailClass(taskDetailDefault).changeState(TaskLife.InProcess);

        workflowStepData.value.reopenTaskWorkflowStep();
    };

    const finishStep = async () => {
        settingGlobalModal({
            type: 'notification',
            title: '',
            content: isFinalStep
                ? translate('WORKFLOW_LABEL_WARNING_WHEN_FINISH_STEP_2')
                : translate('WORKFLOW_LABEL_WARNING_WHEN_FINISH_STEP'),
            cancelLabel: translate('COMMON_LABEL_CANCEL'),
            confirmLabel: translate('COMMON_LABEL_CONFIRM'),
            confirmable: true,
            closeable: true,
        });
        const confirmed = await ask();
        if (!confirmed) return;

        workflowStepData.value.completeTaskWorkflowStep();
    };

    const updateOriginalWorkflowStepData = (workflowStepDefault) => {
        originalWorkflowStepData.value = {
            ...workflowStepDefault,
            taskId: taskDetailDefault?.id || undefined,
        };
    };

    return {
        workflowStepData,
        currentTaskTemplate,
        reopenStep,
        finishStep,
        updateWorkflowStepFromTaskTemplate,
        initData,
        updateOriginalWorkflowStepData,
    };
}

export const updateStepFromTask = (
    originalWorkflowStepData,
    workflowStepDefault,
    currentTaskTemplate: TaskTemplateEntity
): TaskWorkflowStepEntity => {
    const workflowStepData = new TaskWorkflowStepEntity({
        ...workflowStepDefault,
    });
    workflowStepData.domainId = currentTaskTemplate?.domainId;

    const currentFiles = currentTaskTemplate?.files?.filter(
        (file) => file?.editStatus !== 'REMOVED'
    );
    const currentNotes = currentTaskTemplate?.notes?.filter(
        (file) => file?.editStatus !== 'REMOVED'
    );

    // switch (currentTaskTemplate?.taskLife) {
    //     case TaskLife.Archived:
    //     case TaskLife.Draft:
    //     case TaskLife.Duplicated:
    //     case TaskLife.Pending:
    //         workflowStepData.status = EWorkflowTaskStepStatus.WAITING;

    //         break;
    //     case TaskLife.Cancelled:
    //         workflowStepData.status = EWorkflowTaskStepStatus.CANCELED;
    //         break;
    //     case TaskLife.Created:
    //     case TaskLife.Todo:
    //         workflowStepData.status = EWorkflowTaskStepStatus.TODO;
    //         break;
    //     case TaskLife.Finished:
    //         workflowStepData.status = EWorkflowTaskStepStatus.COMPLETED;
    //         break;
    //     case TaskLife.InProcess:
    //         workflowStepData.status = EWorkflowTaskStepStatus.INPROGRESS;
    //         break;

    //     default:
    //         break;
    // }

    workflowStepData.priority =
        currentTaskTemplate?.urgency && currentTaskTemplate?.urgency > 0
            ? convertUrgencyToPriority(currentTaskTemplate?.urgency)
            : currentTaskTemplate?.priority || 0;

    workflowStepData.important =
        currentTaskTemplate?.urgency && currentTaskTemplate?.urgency > 0
            ? convertUrgencyToImportant(currentTaskTemplate?.urgency)
            : !!currentTaskTemplate?.important;
    workflowStepData.startTime = currentTaskTemplate?.startTime || undefined;
    workflowStepData.scheduleDayOption =
        currentTaskTemplate?.taskSchedule?.scheduleOptionKey;
    workflowStepData.scheduleByHour =
        currentTaskTemplate?.scheduleByHour || false;
    workflowStepData.scheduleTime =
        currentTaskTemplate?.scheduleTime || undefined;
    workflowStepData.scheduleTimezone =
        currentTaskTemplate?.scheduleTimezone || '';

    workflowStepData.taskDescription = currentTaskTemplate?.description;
    workflowStepData.totalFiles = currentFiles?.length || 0;
    workflowStepData.totalNotes = currentNotes?.length || 0;
    workflowStepData.totalTodoLists =
        currentTaskTemplate?.taskTodos?.length || 0;
    workflowStepData.groupId = currentTaskTemplate?.groupId;
    workflowStepData.assigneeId = currentTaskTemplate?.assigneeId;

    // Attachments
    workflowStepData.files = [...currentFiles];
    workflowStepData.notes = [...currentNotes]?.map((note) => {
        return {
            ...note,
            drawData: JSON.stringify(note?.drawData),
        };
    });
    workflowStepData.todoLists = currentTaskTemplate?.taskTodos || [];
    workflowStepData.collaborators = currentTaskTemplate?.collaborators
        ? currentTaskTemplate?.collaborators?.map((u) => {
              return {
                  userId: u?.memberUserId,
                  roleId: u?.memberRoleId,
              };
          })
        : [];
    // updateFields
    let differences = getObjectDiff(originalWorkflowStepData, workflowStepData);

    // const fullUpdateFields = [
    //     'groupId',
    //     'assigneeId',
    //     'domainId',
    //     'priority',
    //     'important',
    //     'scheduleDayOption',
    //     'scheduleByHour',
    //     'scheduleTime',
    //     'scheduleTimezone',
    //     'taskDescription',
    //     'collaborators',
    //     // 'files',
    //     // 'notes',
    //     // 'todoLists',
    // ];
    const ignoreUpdateFields = [
        'taskCode',
        'taskWorkflowId',
        'status',
        'departmentId',
        'relatedTask',
        'orgId',
        'creatorId',
        'creationTime',
        'lastModifierId',
        'lastModificationTime',
        'files',
        'notes',
        'todoLists',
    ];
    differences = differences.filter(
        (field) => !ignoreUpdateFields?.includes(field)
    );

    if (
        differences?.includes('groupId') ||
        differences?.includes('assigneeId') ||
        differences?.includes('domainId')
    ) {
        differences = [
            ...new Set([...differences, 'groupId', 'assigneeId', 'domainId']),
        ];
    }

    if (
        currentTaskTemplate?.files?.filter(
            (file) => file.editStatus == 'ADDED' || file.editStatus == 'REMOVED'
        )?.length > 0
    )
        differences.push('files');
    if (
        currentTaskTemplate?.notes?.filter(
            (file) =>
                file.editStatus == 'ADDED' ||
                file.editStatus == 'EDITED' ||
                file.editStatus == 'REMOVED'
        )?.length > 0
    )
        differences.push('notes');
    if (currentTaskTemplate?.updatedTodoList) differences.push('todoLists');

    workflowStepData.updateFields = differences;

    return workflowStepData;
};
