export default {
    install: (app) => {
        app.directive('scroll-infinite', {
            beforeMount(el, binding) {
                if (!el) return;
                el.onScrollEvent = () => {
                    if (!el.scrollTop) return;

                    if (
                        el.offsetHeight + Math.abs(el.scrollTop) >=
                        el.scrollHeight - 1
                    ) {
                        // 1px
                        binding.value();
                    }
                };

                el.addEventListener('scroll', el.onScrollEvent);
            },
            unmounted(el) {
                el.removeEventListener('scroll', el.onScrollEvent);
            },
        });
    },
};
