<script setup lang="ts">
import organizationStore from '@/store/organization';
import { computed, onMounted, ref, watch } from 'vue';
import { chain } from 'lodash';
import { getCompanyOwnName } from '@/ui/hooks/commonFunction';
import { getCurrentOrganizationId } from '@/ui/hooks/storageHook';
import SwitchToOrg from '@/ui/modules/organization/modal-organizations-notification/switch-to-org/SwitchToOrg.vue';

defineProps<{
  selectedOrganization: any;
}>();

const emits = defineEmits(['update:selectedOrganization']);

const _organizationStore = organizationStore();
const currentOrgId = getCurrentOrganizationId();
const hasNotifOrgById: { [orgId: number]: boolean } = {};

const organizations = ref<any[]>();
const organizationByIds = computed(() => _organizationStore.organizationByIds);

watch(
  () => organizationByIds.value,
  () => {
    _processHasNotifOrgIds();
    _processOrganizationsList();
  }
);

onMounted(async () => {
  _processHasNotifOrgIds();
  _processOrganizationsList();

  // if (!props.selectedOrganization) {
  //   const firstOrg = first(organizations.value);
  //   if (firstOrg) onOrganizationSelect(firstOrg);
  // }
});

const onOrganizationSelect = (org) => {
  emits('update:selectedOrganization', org);
};

const _processOrganizationsList = () => {
  organizations.value = chain(_organizationStore.organizationIds)
    .map((orgId) => ({
      ...organizationByIds.value[orgId],
      orgName: getCompanyOwnName(organizationByIds.value[orgId]?.name),
    }))
    .orderBy(
      [(org) => (hasNotifOrgById[org?.id] ? 1 : 0), 'lastLoginDate'],
      ['desc', 'desc']
    )
    .value();
};

const _processHasNotifOrgIds = () => {
  Object.values(organizationByIds.value)
    .filter(
      (org: any) => org?.unSeenConversationsCount || org?.notificationCount
    )
    .forEach((org: any) => (hasNotifOrgById[org?.id] = true));
};
</script>

<template>
  <div class="h-full overflow-auto small-scrollbar bg-current-800 p-2">
    <div
      v-for="org in organizations"
      :key="org?.id"
      role="button"
      class="
        flex
        items-center
        gap-3
        p-2
        mb-1
        rounded-lg
        text-white
        relative
        group
      "
      :class="
        org?.id === selectedOrganization?.id
          ? 'bg-current-50 bg-opacity-20'
          : 'text-opacity-50 hover:bg-current-50 hover:bg-opacity-10'
      "
      @click="onOrganizationSelect(org)"
    >
      <SynAvatar
        custom-class="w-10 h-10"
        :src="org?.logoUrl"
        :name="org?.orgName"
      />

      <div class="flex-1 overflow-hidden">
        <div class="flex items-center justify-between gap-2">
          <span class="font-semibold text-sm leading-4">
            {{ org?.orgName }}
          </span>
          <span
            v-if="org?.id === currentOrgId"
            class="
              text-xs
              px-1.5
              rounded-lg
              bg-current-900
              text-current-200
              mt-0.5
            "
          >
            {{ $t('COMMON_LABEL_CURRENT') }}
          </span>
        </div>

        <div class="flex items-center justify-between gap-3">
          <div class="flex items-center flex-wrap gap-x-3 gap-y-1">
            <span
              v-if="organizationByIds[org?.id]?.unSeenConversationsCount"
              class="relative mt-0.5"
            >
              <SynIcon name="Message" class="fill-current-200" />
              <span
                class="
                  absolute
                  top-0
                  -right-2
                  px-1
                  rounded-full
                  bg-red-500
                  text-white text-[10px]
                "
              >
                {{ organizationByIds[org?.id]?.unSeenConversationsCount }}</span
              >
            </span>
            <span
              v-if="organizationByIds[org?.id]?.notificationCount"
              class="relative mt-0.5"
            >
              <SynIcon name="Bell" class="fill-current-200" />
              <span
                class="
                  absolute
                  top-0
                  -right-2
                  px-1
                  rounded-full
                  bg-red-500
                  text-white text-[10px]
                "
              >
                {{ organizationByIds[org?.id]?.notificationCount }}</span
              >
            </span>
            <SwitchToOrg
              v-if="
                org?.id !== currentOrgId && org?.id === selectedOrganization?.id
              "
              class="mt-1"
              :organization="org"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
