<script setup lang="ts">
import { ref } from 'vue';
import SetupPersonalOrganization from '@/ui/modules/organization/setup/SetupPersonalOrganization.vue';
import organizationStore from '@/store/organization';

const emit = defineEmits(['onCancel', 'onFinish']);
const _organizationStore = organizationStore();

const setupOrgRef = ref(null as any);
const isCreateFetching = ref(false);
const onSubmit = async ({ data }: { mode: number; data: any }) => {
  isCreateFetching.value = true;

  const result = await _organizationStore.saveOrganizationSetting(data);
  isCreateFetching.value = false;
  emit('onFinish', result);
};
</script>

<template>
  <SynModal
    container-class="md:w-2/3 xl:w-1/2 py-4"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    disable-click-outside
    is-hidden-close
    style-body="p-0"
    :container-style="`max-height: 90%; max-width: 90%;`"
    @cancel="$emit('onCancel')"
  >
    <template #body>
      <div class="overflow-y-auto small-scrollbar">
        <SetupPersonalOrganization
          ref="setupOrgRef"
          custom-class="w-full"
          :is-submitting="isCreateFetching"
          @on-cancel="$emit('onCancel')"
          @on-submit="onSubmit"
        />
      </div>
    </template>
  </SynModal>
</template>
