const VI = {
    dayNames: [
        'Chủ nhật',
        'Thứ Hai',
        'Thứ Ba',
        'Thứ Tư',
        'Thứ Năm',
        'Thứ Sáu',
        'Thứ Bảy',
    ],
    monthNames: [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 18',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12',
    ],
    tokens: {
        SKIP: /^[ \r\n\t]+|^\.$/,
        number: /^[1-9][0-9]*/,
        numberAsText: /^(one|two|three)/i,
        mỗi: /^every/i,
        ngày: /^days?/i,
        'weekday(s)': /^weekdays?/i,
        tuần: /^weeks?/i,
        giờ: /^hours?/i,
        phút: /^minutes?/i,
        tháng: /^months?/i,
        năm: /^years?/i,
        vào: /^(on|in)/i,
        lúc: /^(at)/i,
        '': /^the/i,
        'đầu tiên': /^first/i,
        'thứ hai': /^second/i,
        'thứ ba': /^third/i,
        nth: /^([1-9][0-9]*)(\.|th|nd|rd|st)/i,
        'cuối cùng': /^last/i,
        trong: /^for/i,
        lần: /^times?/i,
        đến: /^(un)?til/i,
        'Thứ Hai': /^mo(n(day)?)?/i,
        'Thứ Ba': /^tu(e(s(day)?)?)?/i,
        'Thứ Tư': /^we(d(n(esday)?)?)?/i,
        'Thứ Năm': /^th(u(r(sday)?)?)?/i,
        'Thứ Sáu': /^fr(i(day)?)?/i,
        'Thứ Bảy': /^sa(t(urday)?)?/i,
        'Chủ Nhật': /^su(n(day)?)?/i,
        'Tháng 1': /^jan(uary)?/i,
        'Tháng 2': /^feb(ruary)?/i,
        'Tháng 3': /^mar(ch)?/i,
        'Tháng 4': /^apr(il)?/i,
        'Tháng 5': /^may/i,
        'Tháng 6': /^june?/i,
        'Tháng 7': /^july?/i,
        'Tháng 8': /^aug(ust)?/i,
        'Tháng 9': /^sep(t(ember)?)?/i,
        'Tháng 10': /^oct(ober)?/i,
        'Tháng 11': /^nov(ember)?/i,
        'Tháng 12': /^dec(ember)?/i,
        comma: /^(,\s*|(and|or)\s*)+/i,
    },
};
export default VI;
