import {
    StorageConstant,
    setLocalStorage,
    getLocalStorage,
} from '@/ui/hooks/storageHook';
import { AdminType, SettingProgressEnum } from '@/ui/common/constants/constant';

export class TicketSingleton {
    private static instance: TicketSingleton;

    organizationId: number;
    userId: number;
    groupId: number;
    domainId: number;
    externalRootUID: string;
    externalUID: string;
    externalFirstName: string;
    externalLastName: string;
    externalEmail: string;
    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */

    private constructor(
        organizationId: number,
        userId: number,
        groupId: number,
        domainId: number,
        externalRootUID: string,
        externalUID: string,
        externalFirstName: string,
        externalLastName: string,
        externalEmail: string
    ) {
        this.organizationId = organizationId;
        this.userId = userId;
        this.groupId = groupId;
        this.domainId = domainId;
        this.externalRootUID = externalRootUID;
        this.externalUID = externalUID;
        this.externalFirstName = externalFirstName;
        this.externalLastName = externalLastName;
        this.externalEmail = externalEmail;
    }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): TicketSingleton {
        if (!TicketSingleton.instance) {
            // Get from local storage
            const organizationId = getLocalStorage(
                StorageConstant.TICKET_ORGANIZATION_ID
            );
            const userId = getLocalStorage(StorageConstant.TICKET_USER_ID);
            const groupId = getLocalStorage(StorageConstant.TICKET_GROUP_ID);
            const domainId = getLocalStorage(StorageConstant.TICKET_DOMAIN_ID);
            const externalRootUID = getLocalStorage(
                StorageConstant.TICKET_EXTERNAL_ROOT_ID
            );
            const externalUID = getLocalStorage(
                StorageConstant.TICKET_EXTERNAL_UID
            );
            const firstName = getLocalStorage(
                StorageConstant.TICKET_EXTERNAL_FIRST_NAME
            );
            const lastName = getLocalStorage(
                StorageConstant.TICKET_EXTERNAL_LAST_NAME
            );
            const externalEmail = getLocalStorage(
                StorageConstant.TICKET_EXTERNAL_LAST_NAME
            );
            if (!organizationId || !userId || !groupId || !domainId) {
                throw 'error';
            }

            TicketSingleton.instance = new TicketSingleton(
                parseInt(organizationId),
                parseInt(userId),
                parseInt(groupId),
                parseInt(domainId),
                externalRootUID,
                externalUID,
                firstName,
                lastName,
                externalEmail
            );
        }

        return TicketSingleton.instance;
    }

    /**
     * Finally, any singleton should define some business logic, which can be
     * executed on its instance.
     */
    public static setInstance(
        organizationId: number,
        userId: number,
        groupId: number,
        domainId: number
    ): void {
        const externalRootUID = getLocalStorage(
            StorageConstant.TICKET_EXTERNAL_ROOT_ID
        );
        const externalUID = getLocalStorage(
            StorageConstant.TICKET_EXTERNAL_UID
        );
        const externalFirstName = getLocalStorage(
            StorageConstant.TICKET_EXTERNAL_FIRST_NAME
        );
        const externalLastName = getLocalStorage(
            StorageConstant.TICKET_EXTERNAL_LAST_NAME
        );
        const externalEmail = getLocalStorage(
            StorageConstant.TICKET_EXTERNAL_EMAIL
        );

        TicketSingleton.instance = new TicketSingleton(
            organizationId,
            userId,
            groupId,
            domainId,
            externalRootUID || '',
            externalUID || '',
            externalFirstName || '',
            externalLastName || '',
            externalEmail || ''
        );

        setLocalStorage(StorageConstant.TICKET_ORGANIZATION_ID, organizationId);
        setLocalStorage(StorageConstant.TICKET_USER_ID, userId);
        setLocalStorage(StorageConstant.TICKET_GROUP_ID, groupId);
        setLocalStorage(StorageConstant.TICKET_DOMAIN_ID, domainId);
    }

    public static setExternalUser(id, firstName, lastName) {
        if (id) {
            TicketSingleton.instance.externalUID = id;
            setLocalStorage(StorageConstant.TICKET_EXTERNAL_UID, id);
        }
        if (firstName) {
            TicketSingleton.instance.externalFirstName = firstName;
            setLocalStorage(
                StorageConstant.TICKET_EXTERNAL_FIRST_NAME,
                firstName
            );
        }

        if (lastName) {
            TicketSingleton.instance.externalLastName = lastName;
            setLocalStorage(
                StorageConstant.TICKET_EXTERNAL_LAST_NAME,
                lastName
            );
        }
    }
}

export class OrganizationSetting {
    id: number;
    name: string;
    logoUrl: string;
    isDefault: boolean;
    role: number | null;
    settingProgress: SettingProgressEnum | null;
    numberOfLogins: number;
    taskDrawer: TaskDrawer;
    constructor(
        id = 0,
        name = '',
        logoUrl = '',
        isDefault = false,
        role: AdminType | null = null,
        settingProgress: SettingProgressEnum | null = null,
        numberOfLogins = 0,
        taskDrawer: TaskDrawer = { currentTaskId: 0, currentTasks: [] }
    ) {
        this.id = id;
        this.name = name;
        this.logoUrl = logoUrl;
        this.isDefault = isDefault;
        this.role = role;
        this.settingProgress = settingProgress;
        this.numberOfLogins = numberOfLogins;
        this.taskDrawer = taskDrawer;
    }
}

interface TaskDrawer {
    currentTaskId: number;
    currentTasks: any[];
}
