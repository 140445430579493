import { stringNomalize } from '@/ui/hooks/commonFunction';
import { formatTaskCode } from '@/ui/hooks/taskHook';

export const getGroupShortName = (conversation) => {
    if (!conversation?.isGroup || conversation?.supportingChat)
        return conversation?.name?.at(0);

    if (conversation?.chatAboutTaskCode)
        return `${formatTaskCode(conversation?.chatAboutTaskCode)}`;

    const conversationName =
        conversation?.name?.split(' - ')?.length > 1
            ? conversation?.name?.split(' - ')[1] || conversation?.name
            : conversation?.name;
    const shortNameFull = stringNomalize(conversationName)
        ?.replaceAll(/[^a-zA-Z0-9]/g, ' ')
        ?.replaceAll(/\s+/g, ' ')
        ?.trim()
        ?.split(' ')
        .map((firstName) => firstName?.charAt(0))
        .join('');
    return shortNameFull?.length > 4
        ? `${shortNameFull?.substring(0, 3)}.${shortNameFull?.charAt(
              shortNameFull?.length - 1
          )}`
        : shortNameFull;
};
