<script setup lang="ts">
import { ref, watch } from 'vue';
import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';

const props = defineProps<{
  task: any;
  readonly: boolean;
}>();

const emits = defineEmits(['onDescriptionChange']);

const isEditting = ref<boolean>();
const edittingDescription = ref<string>();

watch(
  () => props.task?.id,
  () => {
    isEditting.value = false;
  }
);

const onConfirm = () => {
  emits('onDescriptionChange', edittingDescription.value);

  isEditting.value = false;
};

const onEditClick = () => {
  edittingDescription.value = props.task?.description;

  isEditting.value = true;
};
</script>

<template>
  <TaskDescriptionInput
    v-if="isEditting"
    v-model="edittingDescription"
    :current-task="task"
    :has-more-actions="false"
    @on-close="isEditting = false"
    @on-save="onConfirm"
  />

  <div
    v-else
    class="
      leading-6
      whitespace-pre-line
      rounded
      border border-transparent
      flex
      items-start
      justify-between
      gap-2
      group
    "
    :class="[
      !readonly && !task?.description ? 'cursor-pointer' : 'cursor-text',
      !readonly ? 'hover:border-gray-300' : '',
      task?.description ? 'p-1 pl-1' : 'px-2 py-1',
    ]"
    :style="{
      'padding-top: 12px; padding-bottom: 12px': task?.description,
    }"
  >
    <span
      class="task-detail_description break-words text-gray-500 text-sm"
      v-html="
        task?.description ||
        (!readonly ? $t('COMMON_LABEL_PLACEHOLDER_ADD_DESCRIPTION') : '')
      "
    ></span>
    <SynIcon
      v-if="!readonly"
      name="edit"
      is-active
      class="
        fill-current
        hover:fill-current-800
        cursor-pointer
        invisible
        group-hover:visible
      "
      :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
      @click="onEditClick"
    />
  </div>
</template>

<style scoped lang="scss">
.task-detail_description {
  a {
    @apply text-current-500;

    &:hover {
      @apply underline;
    }
  }
}
</style>
