<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import taskListStore from '@/store/task/task-list-store';
import taskDrawerStore from '@/store/task/drawer';
import taskTabViewStore from '@/store/task/task-tab-view';
import { TaskListTab } from '@/domain/enums/taskEnum';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import { keySearch } from '@/ui/modules/task/table/task-list-state';
import VigImage from '@/ui/common/atoms/VigImage/VigImage.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import permissionStore from '@/store/permission';
import taskCustomConfigStore from '@/store/task/task-custom-config';
import { ICustomTaskColumn } from '@/application/interfaces/tasks/task-interfaces';
import {
  EFilterTaskDefault,
  LIST_FILTER_TASK_DEFAULT,
  FILTER_APPROVAL_TASK_LIST,
} from '@/application/constants/task.const';
import taskStore from '@/store/task';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import TaskTable from '@/ui/modules/task/table/TaskTable.vue';
import TaskSimpleTable from '@/ui/modules/task/table/TaskSimpleTable.vue';

const props = withDefaults(
  defineProps<{
    type?: 'member' | 'group' | 'domain' | 'my_assign' | 'waiting_approval';
    createableTask?: boolean;
    infoOwner?: any;
    isFilterInTable?: boolean;
    isSimpleTable?: boolean;
    isShowAddNewButton?: boolean;
    location: 'MY_TASKS' | 'USER_TASKS' | 'GROUP_TASKS' | 'DOMAIN_TASKS';
  }>(),
  {
    type: 'member',
    createableTask: false,
    isFilterInTable: false,
    infoOwner: () => {},
  }
);

const emit = defineEmits([
  'reload',
  'onOpenFormCreateTask',
  'onSelectTaskDoToday',
  'addNewTask',
  'ready',
]);

const _taskDrawerStore = taskDrawerStore();
const _taskStore = taskStore();
const _taskListStore = taskListStore();
const _taskTabViewStore = taskTabViewStore();
const _taskCustomConfigStore = taskCustomConfigStore();
const taskColumnByCode = computed<ICustomTaskColumn[]>(
  () => _taskCustomConfigStore.taskColumnByCode
);

const currentTab = computed<TaskListTab>(() => _taskListStore.activeTab);
const currentTaskDrawerId = computed(() => _taskDrawerStore.currentTabId);
const isGroup = computed(() => props.type == 'group');
const isMyAssign = computed<boolean>(() => props.type == 'my_assign');
const isRelatedTab = computed(
  () => taskTabViewStore().currentTabId == EFilterTaskDefault.MY_RELATED
);
const isPinnedTaskTab = computed(
  () => taskTabViewStore().currentTabId == EFilterTaskDefault.PINNED
);
const isPersonalModel = computed(
  () => permissionStore().organizationModel === 'PERSONAL'
);
const isDomain = computed(() => props.type == 'domain');

const childrenTabActive = computed(() => _taskTabViewStore.childrenTabActive);
const taskIds = computed(() => _taskListStore.taskIds);
const selectedTaskIds = computed(() => _taskListStore.selectedTaskIds);
const isLoadingFilterTask = computed(() => _taskListStore.isLoadingFilterTask);
const isLoadMore = computed(() => _taskListStore.isLoadMore);
const allTaskByIds = computed(() => _taskStore.allTaskByIds);
const filterDataByKey = computed(() => _taskListStore.filterDataByKey);
const sortDataOrderByKey = computed(() => _taskListStore.sortDataOrderByKey);
const filteredTasks = computed(() => _taskListStore.filteredTasks);
const isHightLightOpenTask = computed(() => isHasOpenAttachment.value > 0);
const canLoadMore = computed(() => {
  const currentLength = taskIds.value?.length;
  if (currentLength <= 0) return false;
  return currentLength < filteredTasks.value?.length;
});

// update task list
const isLoadingMoreActionByTaskId = ref(0);
const isHasOpenAttachment = ref(null as any);

const initData = () => {
  _taskListStore.setDefault();
  emit('ready');
};

watch(
  () => props.type,
  () => {
    initData();
  }
);

const prepareDataUIOfTab = computed(() => {
  let result: any =
    isGroup.value || isDomain.value
      ? {
          name: props.infoOwner?.name,
          typeKey: isGroup.value
            ? 'COMMON_LABEL_GROUP'
            : isDomain.value
            ? 'DOMAIN_LABEL_TITLE'
            : '',
        }
      : {};
  const linkImg = `https://d1tvqetrcurhzb.cloudfront.net/common/images/`;

  if (isMyAssign.value)
    return {
      ...result,
      imgUrl:
        isGroup.value || isDomain.value
          ? `${linkImg}all-task-grouplist.png`
          : `${linkImg}all-task-mylist.png`,
      imgSize: 'h-40 w-auto',
      titleKey:
        isGroup.value || isDomain.value
          ? 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_TITLE'
          : 'TASK_LIST_DONT_HAVE_ALL_TASK_TITLE',
      subTitleKey:
        isGroup.value || isDomain.value
          ? 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_SUB_TITLE'
          : 'TASK_LIST_DONT_HAVE_ALL_TASK_SUB_TITLE',
    };

  if (currentTab.value == TaskListTab.WaitingApprovalTasks)
    return {
      ...result,
      ...(childrenTabActive.value &&
      Object.prototype.hasOwnProperty.call(
        FILTER_APPROVAL_TASK_LIST,
        childrenTabActive.value
      )
        ? FILTER_APPROVAL_TASK_LIST[childrenTabActive.value]?.noDataPage
            ?.default
        : {}),
    };

  return {
    ...result,
    ...(childrenTabActive.value &&
    Object.prototype.hasOwnProperty.call(
      LIST_FILTER_TASK_DEFAULT,
      childrenTabActive.value
    )
      ? isGroup.value || isDomain.value
        ? LIST_FILTER_TASK_DEFAULT[childrenTabActive.value]?.noDataPage?.group
        : LIST_FILTER_TASK_DEFAULT[childrenTabActive.value]?.noDataPage?.default
      : {}),
  };
});

const onOpenTaskDetail = (task) => {
  _taskDrawerStore.pushCurrentIds({ ...task, tabType: 'DETAIL' });
};

const onFilterByKeyOnColumn = (key, filters) => {
  console.log('🚀 Tictop ~ key, filters:', key, filters);
  _taskListStore.setFilterDataByKey(key, filters);
  _taskListStore.onFilterTaskOnColumn();
};

const onResetFilterOnColumn = (key) => {
  console.log('🚀 Tictop ~ key:', key);
  _taskListStore.resetFilterDataOrigin(key);
  _taskListStore.onFilterTaskOnColumn();
  _taskListStore.onSortFilteredTasks();
};
// End filter

const onClickSort = (key, name, orderFunction) => {
  _taskListStore.genSortDataList({ key, name, orderFunction });
  window.scrollTo(0, 0);
};

const onLoadMore = () => {
  if (!canLoadMore.value) return;
  _taskListStore.onLoadMore();
};

initData();

const screenCode = computed<string>(() => {
  if (props.location == 'MY_TASKS') return ALL_SCREENS.LOGIN_USER.TASK.LIST;
  if (props.location == 'GROUP_TASKS') return ALL_SCREENS.GROUP.TASK.LIST;
  if (props.location == 'DOMAIN_TASKS') return ALL_SCREENS.DOMAIN.TASK.LIST;
  return ALL_SCREENS.USER.TASK.LIST;
});

const onClickHiddenColumn = (code) => {
  const data = {
    ...taskColumnByCode.value[code],
    visible: false,
  };
  _taskCustomConfigStore.updateTaskColumnByCode(code, data);
};

const isFilterableInColumn = computed<boolean>(() => {
  return !isLoadingFilterTask.value;
});

const hiddenColumnList = computed<string[]>(() => {
  let result: string[] = [];
  if (isPersonalModel.value) result?.push('DOMAIN', 'REPORTER');
  return result;
});

defineExpose({
  initData,
});
</script>

<template>
  <div
    v-permission-screen="{
      screenCode: screenCode,
      isPage: true,
    }"
    class="h-full"
  >
    <div
      v-if="isSimpleTable"
      v-perfect-scrollbar
      v-scroll-infinite="
        () => {
          onLoadMore();
        }
      "
      class="h-full overflow-hidden relative"
    >
      <TaskSimpleTable
        is-hidden-column
        :task-list="taskIds?.map((id) => allTaskByIds[id])"
        :column-list="[
          'NAME',
          'GROUP',
          'DOMAIN',
          'URGENCY',
          'DEADLINE',
          'TASK_LIFE',
          'PLANNING',
          'ASSIGNEE',
          'CREATED_TIME',
        ]"
        :type="type"
        :is-filterable-in-column="isFilterableInColumn"
        :sort-data-order-by-key="sortDataOrderByKey"
        :filter-data-by-key="filterDataByKey"
        :is-show-add-new-button="isShowAddNewButton"
        @open-detail="onOpenTaskDetail"
        @on-filter-by-key-on-column="onFilterByKeyOnColumn"
        @on-reset-filter-on-column="onResetFilterOnColumn"
        @on-click-sort="onClickSort"
        @on-load-more="onLoadMore"
        @on-click-hidden-column="onClickHiddenColumn"
        @add-new-task="$emit('addNewTask')"
      />
    </div>
    <TaskTable
      v-else
      :task-ids="taskIds"
      :all-task-by-ids="allTaskByIds"
      :type="type"
      :selected-task-ids="selectedTaskIds"
      :hidden-column-list="hiddenColumnList"
      :is-filter-in-table="isFilterInTable"
      :is-filterable-in-column="isFilterableInColumn"
      :task-column-by-code="taskColumnByCode"
      :sort-data-order-by-key="sortDataOrderByKey"
      :filter-data-by-key="filterDataByKey"
      :is-related-tab="isRelatedTab"
      :is-pinned-task-tab="isPinnedTaskTab"
      :is-loading-filter-task="isLoadingFilterTask"
      :is-focused-on-task-id="isOpenTaskDrawer ? currentTaskDrawerId : 0"
      :is-hight-light-open-task="isHightLightOpenTask"
      :is-loading-more-action-by-task-id="isLoadingMoreActionByTaskId"
      :key-search="keySearch"
      :is-load-more="isLoadMore"
      :can-load-more="canLoadMore"
      @on-open-task-detail="onOpenTaskDetail"
      @on-filter-by-key-on-column="onFilterByKeyOnColumn"
      @on-reset-filter-on-column="onResetFilterOnColumn"
      @on-click-sort="onClickSort"
      @on-load-more="onLoadMore"
      @on-click-hidden-column="onClickHiddenColumn"
    >
      <template #custom-filter>
        <slot name="custom-filter"></slot>
      </template>
      <template #footer?>
        <SynTr
          v-if="
            childrenTabActive === EFilterTaskDefault.DO_TODAY &&
            currentTab === TaskListTab.MyList &&
            createableTask
          "
        >
          <SynTd :colspan="10">
            <div class="flex items-center space-x-2">
              <SynButton
                type-text
                :label="$t('TASK_LIST_CREATE_TASK_BUTTON_LABEL')"
                name-icon="plus"
                custom-class-icon="w-2.5 h-2.5"
                custom-class="rounded text-sm border border-dashed border-current-100"
                @click="$emit('onOpenFormCreateTask')"
              />
              <span class="text-gray-500">or</span>
              <SynButton
                type-text
                custom-class-icon="w-2.5 h-2.5"
                custom-class="rounded text-sm border border-dashed border-gray-200"
                color="gray"
                :label="$t('TASK_LIST_SELECT_TASK_BUTTON_LABEL')"
                @click="$emit('onSelectTaskDoToday')"
              />
            </div>
          </SynTd>
        </SynTr>
      </template>

      <template #no-data>
        <SynTr class="h-full border-none" hover="hover:bg-current-50">
          <syn-td :colspan="10">
            <template v-if="keySearch">
              <syn-animation
                class="pt-12"
                name="searchNotFound"
                stype="width: 160px; height: 160px"
                :title="$t('TASK_LIST_NO_SEARCH_TITLE')"
                :label="$t('TASK_LIST_NO_SEARCH__SUB_TITLE')"
              ></syn-animation>
            </template>
            <template v-else>
              <div class="flex-center flex-col pb-4 pt-12">
                <VigImage
                  :path="prepareDataUIOfTab?.imgUrl"
                  :custom-class="`${
                    prepareDataUIOfTab?.imgSize || 'h-42 w-96'
                  }`"
                />
                <span
                  class="text-current-700 pt-8"
                  style="font-size: 1.15rem"
                  v-html="
                    isGroup || isDomain
                      ? $t(prepareDataUIOfTab?.titleKey, {
                          owner: prepareDataUIOfTab?.name,
                          type: $t(prepareDataUIOfTab?.typeKey),
                        })
                      : $t(prepareDataUIOfTab?.titleKey)
                  "
                ></span>
                <span
                  v-if="
                    !(
                      isDomain &&
                      (childrenTabActive === EFilterTaskDefault.OVERDUE_DATE ||
                        childrenTabActive === EFilterTaskDefault.FINISHED)
                    )
                  "
                  class="text-gray-500 text-sm py-2"
                  v-html="
                    isGroup || isDomain
                      ? $t(prepareDataUIOfTab?.subTitleKey, {
                          owner: prepareDataUIOfTab?.name,
                          type: $t(prepareDataUIOfTab?.typeKey),
                        })
                      : $t(prepareDataUIOfTab?.subTitleKey)
                  "
                ></span>
                <div
                  v-if="
                    childrenTabActive === EFilterTaskDefault.DO_TODAY &&
                    createableTask
                  "
                  class="flex-center space-x-2 pt-8"
                >
                  <SynButton
                    :label="$t('TASK_LIST_CREATE_TASK_BUTTON_LABEL')"
                    @click="$emit('onOpenFormCreateTask')"
                  />
                  <SynButton
                    type-outline
                    :label="$t('TASK_LIST_SELECT_TASK_BUTTON_LABEL')"
                    @click="$emit('onSelectTaskDoToday')"
                  />
                </div>
              </div>
            </template>
          </syn-td>
        </SynTr>
      </template>
    </TaskTable>
  </div>
</template>
<style>
#task-list-scrollbar-container {
  position: relative;
}
</style>
