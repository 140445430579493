<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import Cropper from 'cropperjs';

const props = defineProps<{
  avatar?: any;
}>();

const imgAvatarRef = ref<HTMLImageElement>();
const isGifImage = computed<boolean>(
  () => props.avatar?.contentType === 'image/gif'
);

let _avatarCropper: Cropper | null;

watch(
  () => props.avatar,
  () => _initCropper()
);

onMounted(() => {
  _initCropper();
});

onUnmounted(() => {
  _destroyCropper();
});

const onResetClick = () => {
  if (_avatarCropper) _avatarCropper.reset();
};

const onZoomClick = (zoomValue) => {
  if (_avatarCropper) _avatarCropper.zoom(zoomValue);
};

const getCroppedAvatar = () => {
  return new Promise((resolve) => {
    if (isGifImage.value) return resolve(props.avatar);

    if (!_avatarCropper) return resolve(null);

    _avatarCropper.getCroppedCanvas({ fillColor: '#fff' }).toBlob((blob) => {
      if (!blob) return resolve(null);

      resolve({
        url: URL.createObjectURL(blob),
        contentType: 'image/png',
      });
    });
  });
};

const _initCropper = () => {
  setTimeout(() => {
    if (!imgAvatarRef.value?.src) return;

    if (isGifImage.value) {
      return _destroyCropper();
    }

    if (_avatarCropper) {
      return props.avatar?.url
        ? _avatarCropper.replace(props.avatar?.url)
        : _destroyCropper();
    }

    _avatarCropper = new Cropper(imgAvatarRef.value, {
      checkCrossOrigin: true,
      dragMode: 'none',
      aspectRatio: 1,
      autoCropArea: 1,
      responsive: true,
      background: false,
      toggleDragModeOnDblclick: false,
    });
  });
};

const _destroyCropper = () => {
  if (_avatarCropper) {
    _avatarCropper.destroy();
    _avatarCropper = null;
  }
};

defineExpose({
  getCroppedAvatar,
});
</script>

<template>
  <div class="h-full bg-gray-200 relative p-1">
    <div class="h-full relative">
      <img
        ref="imgAvatarRef"
        :src="avatar?.url"
        alt=""
        class="max-w-full h-full object-contain"
        :class="{ hidden: !isGifImage }"
      />
    </div>

    <div
      v-if="!isGifImage"
      class="absolute right-3 bottom-5 flex flex-col gap-2"
    >
      <button
        class="
          w-9
          h-9
          flex-center
          rounded-full
          shadow
          bg-black bg-opacity-50
          hover:bg-opacity-80
        "
        @click="onResetClick"
      >
        <SynIcon name="Refresh" custom-class="w-5 h-5 fill-white" />
      </button>
      <button
        class="
          w-9
          h-9
          flex-center
          rounded-full
          shadow
          bg-black bg-opacity-50
          hover:bg-opacity-80
        "
        @click="onZoomClick(0.1)"
      >
        <SynIcon name="Plus" class="fill-white" />
      </button>
      <button
        class="
          w-9
          h-9
          flex-center
          rounded-full
          shadow
          bg-black bg-opacity-50
          hover:bg-opacity-80
        "
        @click="onZoomClick(-0.1)"
      >
        <SynIcon name="Minus" custom-class="w-5 h-5 fill-white" />
      </button>
    </div>
  </div>
</template>
