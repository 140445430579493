import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

const CURRENT_VERSION = 5;
export enum EObjectStore {
    tasks = 'tasks',
    messages = 'messages',
    conversations = 'conversations',
    desktops = 'desktops',
    myConfigs = 'myConfigs',
    common = 'common',
}

const OBJECT_STORE_LIST = [
    {
        name: EObjectStore.tasks,
        keyPath: 'ownerId',
        indexs: [],
    },
    {
        name: EObjectStore.myConfigs,
        keyPath: 'code',
        indexs: [],
    },
    {
        name: EObjectStore.messages,
        keyPath: 'messageId',
        indexs: [
            {
                key: 'conversationId',
                unique: false,
            },
        ],
    },
    {
        name: EObjectStore.conversations,
        keyPath: 'conversationId',
        indexs: [],
    },
    {
        name: EObjectStore.common,
        indexs: [],
    },
    {
        name: EObjectStore.desktops,
        keyPath: 'desktopId',
        indexs: [],
    },
];

export enum ETransactionMode {
    readonly = 'readonly',
    readwrite = 'readwrite',
    versionchange = 'versionchange',
}

export default class TictopIndexDB {
    private static instance: IDBDatabase | any;
    constructor() {}

    public static async getInstance(): Promise<IDBDatabase | any> {
        const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
        if (!organizationId || organizationId == 'null')
            return Promise.reject('');

        if (!TictopIndexDB.instance) {
            await new Promise((resolve, reject) => {
                const dbName = `tictop/organizations/${organizationId}/main`;
                const request = window.indexedDB.open(dbName, CURRENT_VERSION);

                request.onupgradeneeded = (event: any) => {
                    if (!event) return;

                    // Save the IDBDatabase interface
                    const db = event.target?.result;
                    for (const { name, keyPath, indexs } of OBJECT_STORE_LIST) {
                        if (
                            event.oldVersion > 0 &&
                            event.oldVersion < CURRENT_VERSION &&
                            db.objectStoreNames.contains(name)
                        ) {
                            db.deleteObjectStore(name); // migrating data would be better
                            db.fine; // migrating data would be better
                        }
                        const currentObjectStore = db.createObjectStore(name, {
                            keyPath,
                        });

                        if (indexs?.length > 0) {
                            for (const indexData of indexs) {
                                currentObjectStore.createIndex(
                                    indexData?.key,
                                    indexData?.key,
                                    {
                                        unique: indexData?.unique,
                                    }
                                );
                            }
                        }
                    }
                };

                request.onsuccess = (event: any) => {
                    TictopIndexDB.instance = event.target.result;

                    resolve(true);
                };

                request.onerror = (event) => {
                    TictopIndexDB.instance = event;
                    reject(false);
                };
            });
        }

        return new Promise((resolve) => {
            resolve(TictopIndexDB.instance);
        });
    }
    public static async clearInstance() {
        TictopIndexDB.instance = null;
    }
}
