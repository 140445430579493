import { IFileStorageService } from '@/domain/interfaces/services/IFileStorageService';
import FileStorageRepository from '@/application/repositories/FileStorageRepository';

export default class FileStorageService implements IFileStorageService {
    private static instance: FileStorageService;

    _fileStorageRepository: FileStorageRepository;
    constructor() {
        this._fileStorageRepository = FileStorageRepository.getInstance();
    }

    public static getInstance(): FileStorageService {
        if (!FileStorageService.instance) {
            // Get from local storage
            FileStorageService.instance = new FileStorageService();
        }

        return FileStorageService.instance;
    }

    getFiles(params: any): Promise<any> {
        return this._fileStorageRepository.getFiles(params);
    }

    uploadFiles(data: any): Promise<any> {
        return this._fileStorageRepository.uploadFiles(data);
    }

    uploadFileToAws(
        organizationId: number,
        fileId: string,
        file: any
    ): Promise<any> {
        return this._fileStorageRepository.uploadFileToAws(
            organizationId,
            fileId,
            file
        );
    }

    deleteFiles(data: any): Promise<any> {
        return this._fileStorageRepository.deleteFiles(data);
    }

    moveFilesToTrash(data: any): Promise<any> {
        return this._fileStorageRepository.moveFilesToTrash(data);
    }

    restoreFilesFromTrash(data: any): Promise<any> {
        return this._fileStorageRepository.restoreFilesFromTrash(data);
    }

    deleteAllFilesFromTrash(): Promise<any> {
        return this._fileStorageRepository.deleteAllFilesFromTrash();
    }

    moveFilesToFolder(data: any): Promise<any> {
        return this._fileStorageRepository.moveFilesToFolder(data);
    }

    saveFileToMyResource(data: any): Promise<any> {
        return this._fileStorageRepository.saveFileToMyResource(data);
    }

    renameFile(data: any): Promise<any> {
        return this._fileStorageRepository.renameFile(data);
    }

    addFolder(data: any): Promise<any> {
        return this._fileStorageRepository.addFolder(data);
    }

    getFolder(folderId: any): Promise<any> {
        return this._fileStorageRepository.getFolder(folderId);
    }

    addLink(data: any): Promise<any> {
        return this._fileStorageRepository.addLink(data);
    }

    addDriveDocument(data: any): Promise<any> {
        return this._fileStorageRepository.addDriveDocument(data);
    }
}
