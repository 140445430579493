<script setup lang="ts">
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const emit = defineEmits(['onHideAction', 'onEdit', 'onDelete', 'onResend']);

defineProps<{
  selectedList: any[];
  loadingResend: boolean;
  loadingDelete: boolean;
}>();

const onHideAction = () => {
  emit('onHideAction');
};
</script>

<template>
  <div class="flex space-x-2 items-center">
    <div class="mr-2">
      <span class="text-gray-800 font-semibold">
        {{
          $t('GED_NUM_SELECTED', {
            number: selectedList?.length,
          })
        }}
      </span>
    </div>
    <div
      v-vig-tooltip="{
        arrow: true,
        content: $t('USERMANAGEMENT_PENDING_ACTION_RESEND'),
      }"
      class="
        flex-center
        cursor-pointer
        fill-green-500
        text-green-500
        p-2
        bg-white
        border border-gray-100
        rounded
        hover:bg-green-50
        w-8
        h-8
      "
      @click="emit('onResend')"
    >
      <SynIconCustom
        :name="loadingResend ? 'spinner' : 'plane-paper'"
        :class="[loadingResend ? 'animate-spin cursor-not-allowed' : '']"
        class="w-3 h-3"
      />
    </div>
    <div
      v-vig-tooltip="{
        arrow: true,
        content: $t('COMMON_LABEL_EDIT'),
      }"
      class="
        flex-center
        cursor-pointer
        fill-blue-500
        text-blue-500
        p-2
        bg-white
        border border-gray-100
        rounded
        hover:bg-blue-50
        w-8
        h-8
      "
      @click="emit('onEdit')"
    >
      <SynIcon name="edit" custom-class="w-3 h-3" />
    </div>
    <div
      v-vig-tooltip="{
        arrow: true,
        content: $t('COMMON_LABEL_DELETE'),
      }"
      class="
        flex-center
        cursor-pointer
        fill-red-500
        p-2
        bg-white
        border border-gray-100
        rounded
        hover:bg-red-50
        w-8
        h-8
      "
      @click="emit('onDelete')"
    >
      <SynIconCustom
        :name="loadingDelete ? 'spinner' : 'trash'"
        :class="[loadingDelete ? 'animate-spin cursor-not-allowed' : '']"
        class="w-3 h-3"
      />
    </div>
    <div
      class="
        px-2
        p-1
        space-x-1
        flex
        items-center
        rounded-full
        hover:bg-gray-50
        cursor-pointer
      "
      @click="onHideAction()"
    >
      <SynIcon name="close" custom-class="w-3 h-3 fill-gray-400" />
      <span class="text-sm text-gray-500">{{ $t('COMMON_LABEL_CANCEL') }}</span>
    </div>
  </div>
</template>
