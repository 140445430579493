<script setup lang="ts">
import { computed, ref, onMounted, nextTick } from 'vue';
import { emojis } from './emojis';
import { chain } from 'lodash';

withDefaults(
  defineProps<{
    toggleClass: string;
    closeOnSelect: boolean;
  }>(),
  {
    toggleClass: 'w-8 h-8 flex-center hover:bg-gray-200',
    closeOnSelect: true,
  }
);

const emits = defineEmits(['onEmojiClick', 'onOpen', 'onClose']);

const emojiArray = computed(() => {
  const result = chain(emojis)
    .groupBy('category')
    .map((value: any, key) => ({
      name: key,
      emojis: value,
      icon: value.shift().emoji,
    }))
    .value();

  return result;
});

const emojiGroups = ref([] as any);

const emojiActive = ref('Frequently Used');
const indexEmojiActive = ref(0);
const isOpentGroupEmoji = ref(false);

onMounted(() => {
  emojiActive.value = 'Frequently Used';
});

const onOpentGroupEmoji = async () => {
  emits('onOpen');
  isOpentGroupEmoji.value = true;
  await nextTick();
  emojiActive.value = 'Frequently Used';
  onChangeEmojiGroup(1, 'Smileys & Emotion');
  onChangeEmojiGroup(0, emojiActive.value);
};
const onCloseGroupEmoji = () => {
  emits('onClose');
  isOpentGroupEmoji.value = false;
};

const onEmojiClick = (emoji) => {
  emits('onEmojiClick', emoji);
};

const onChangeEmojiGroup = async (index, groupTabName) => {
  emojiActive.value = groupTabName || 'Frequently Used';

  const indexCategory = emojiGroups.value.findIndex(
    (el) => el?.category === groupTabName
  );
  if (indexCategory === -1) handlePushEmojiInArray(index);

  await nextTick();
  const element = document.getElementById(
    `syn-tab-scroll-item-${emojiActive.value}`
  );
  element && element.scrollIntoView({ block: 'start' });
};

const handlePushEmojiInArray = (index) => {
  if (!emojiArray.value[index]) return;
  emojiGroups.value[index] = emojiArray.value[index];
  emojiActive.value = emojiGroups.value[index]?.name;
};

const loadMoreEmoji = () => {
  indexEmojiActive.value = indexEmojiActive.value + 1;
  handlePushEmojiInArray(indexEmojiActive.value);
};
</script>

<template>
  <vig-dropdown
    @on-dropdown-open="onOpentGroupEmoji"
    @on-dropdown-close="onCloseGroupEmoji"
  >
    <template #dropdown-toggle>
      <button type="button" :class="toggleClass">
        <slot></slot>
      </button>
    </template>
    <template #dropdown-menu>
      <div class="flex items-center justify-around pt-2 pb-1">
        <template v-for="(groupTab, index) in emojiArray" :key="groupTab">
          <div
            v-twemoji="{ size: 'w-5 h-5' }"
            class="w-8 h-8 flex-center cursor-pointer hover:bg-gray-100 p-1.5"
            :class="
              groupTab.name === emojiActive
                ? 'border-b-2 border-current-500 rounded-t-md'
                : 'rounded-md'
            "
            :title="groupTab?.name"
            @click="onChangeEmojiGroup(index, groupTab?.name)"
          >
            <span class="">{{ groupTab.icon }}</span>
          </div>
        </template>
      </div>
      <div
        id="panelGroupEmoji"
        v-twemoji="{ size: 'w-7 h-7' }"
        v-scroll-infinite="loadMoreEmoji"
        class="w-96 h-96 overflow-auto small-scrollbar"
        style="scroll-behavior: smooth"
      >
        <template v-for="group in emojiGroups" :key="group">
          <div
            v-if="group?.emojis?.length"
            :id="`syn-tab-scroll-item-${group?.name}`"
            class="mb-2"
          >
            <div class="syn-tab-scroll-item p-2 font-medium text-sm">
              {{ group?.name }}
            </div>
            <div class="p-1 grid grid-cols-8 gap-1">
              <button
                v-for="emoji in group?.emojis"
                :key="emoji"
                :title="emoji.aliases"
                class="p-1 text-lg hover:bg-gray-200 rounded-md"
                :class="closeOnSelect ? 'dropdown-item' : ''"
                type="button"
                @click="onEmojiClick(emoji?.emoji)"
              >
                {{ emoji?.emoji }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </template>
  </vig-dropdown>
</template>

<style scoped>
.syn-tab-scroll-item {
  display: block;
  transition: opacity 0.35s linear;
}
.syn-tab-scroll-item[data-scroll='in'] {
  opacity: 1;
  transition-duration: 1s;
}
</style>
