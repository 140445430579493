import { onUnmounted } from 'vue';
import { TaskActionType } from '@/domain/enums/taskEnum';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
import taskListStore from '@/store/task/task-list-store';
import GroupDynamicModel from '@/ui/plugins/firebases/realtime-database/model/GroupDynamicModel';
import DomainDynamicModel from '@/ui/plugins/firebases/realtime-database/model/DomainDynamicModel';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import taskStore from '@/store/task';

export default function userTasksRealtime() {
    const _taskListStore = taskListStore();
    let unSubscribeLatestTaskActivity;
    let unSubscribeMultiTaskActivity;

    onUnmounted(() => {
        if (unSubscribeLatestTaskActivity) unSubscribeLatestTaskActivity();
        if (unSubscribeMultiTaskActivity) unSubscribeMultiTaskActivity();
    });

    const onListenFirebase = (
        module: 'user' | 'group' | 'domain',
        ownerId,
        callback?
    ) => {
        if (!ownerId) return;
        if (unSubscribeLatestTaskActivity) unSubscribeLatestTaskActivity();

        const fbDynamicModel = getDynamicModel(
            module,
            ownerId,
            'latestTaskActivity'
        );

        unSubscribeLatestTaskActivity = fbDynamicModel.onChange(
            async (updatedTask: {
                taskAction: TaskActionType;
                taskActionTime: number;
                taskId: number;
            }) => {
                const updatedTaskDetail =
                    await _taskListStore.updateListTaskById(
                        updatedTask?.taskId,
                        updatedTask?.taskAction,
                        ownerId
                    );

                if (callback && typeof callback == 'function')
                    callback({
                        ...updatedTask,
                        ...updatedTaskDetail,
                    });
            }
        );
    };

    let lastMultiUpdateTime;
    const onListenMultiTask = (
        module: 'user' | 'group' | 'domain',
        ownerId,
        callback?
    ) => {
        if (!ownerId) return;

        if (unSubscribeMultiTaskActivity) unSubscribeMultiTaskActivity();
        const fbDynamicModel = getDynamicModel(
            module,
            ownerId,
            'latestMultiTaskActivity'
        );
        unSubscribeMultiTaskActivity = fbDynamicModel.onChange(
            (snapshot: {
                action: TaskActionType;
                actionTime: number;
                taskIds: number[];
                data: any;
            }) => {
                const lastUpdateDistanceTime = Date.now() - lastMultiUpdateTime;
                const actionDate = new Date(snapshot?.actionTime).setHours(
                    0,
                    0,
                    0,
                    0
                );
                const today = new Date(Date.now()).setHours(0, 0, 0, 0);

                if (lastUpdateDistanceTime < 500 || actionDate !== today)
                    return;

                lastMultiUpdateTime = Date.now();
                if (snapshot?.taskIds?.length == 0) return;

                _onUpdateMultipleTasks(snapshot);

                if (callback && typeof callback == 'function') callback();
            }
        );
    };

    const getDynamicModel = (module, ownerId, path) => {
        let fbDynamicModel;
        switch (module) {
            case 'user':
                fbDynamicModel = new UserDynamicModel(
                    `user_${ownerId}/${path}`
                );
                break;
            case 'group':
                fbDynamicModel = new GroupDynamicModel(
                    `group_${ownerId}/${path}`
                );
                break;
            case 'domain':
                fbDynamicModel = new DomainDynamicModel(
                    `domain_${ownerId}/${path}`
                );
                break;

            default:
                break;
        }

        return fbDynamicModel;
    };

    const _onUpdateMultipleTasks = (snapshot: {
        action: TaskActionType;
        actionTime: number;
        taskIds: number[];
        data: any;
    }) => {
        for (const taskId of snapshot.taskIds) {
            if (snapshot?.action === TaskActionType.Delete) {
                _taskListStore.updateListTaskById(
                    taskId,
                    TaskActionType.Delete
                );
            } else {
                const updatedData =
                    snapshot?.action == TaskActionType.ChangePriority
                        ? {
                              ...snapshot?.data,
                              urgency: getUrgencyValue(
                                  snapshot?.data?.priority,
                                  snapshot?.data?.important
                              ),
                          }
                        : snapshot?.data;
                taskStore().updateAllTaskByIds(taskId, updatedData);
            }
        }
    };
    return {
        onListenFirebase,
        onListenMultiTask,
        getDynamicModel,
    };
}
