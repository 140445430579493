import { ref, computed } from 'vue';
import taskCommentStore from '@/ticket/store/ticket-comment';
import { cloneDeep } from '@/ui/hooks/commonFunction';

interface ICommentAttachmentInput {
    //files
    addedFiles: any[];
    removedFiles: any[];
    //notes
    addedNotes: any[];
    editedNotes: any[];
    removedNotes: any[];
}

export default function commentTaskComposable() {
    const _taskCommentStore = taskCommentStore();

    const listCommentByTaskId = computed(
        () => _taskCommentStore.listCommentByTaskId
    );

    const isOpenCommentTask = ref(false);
    const currentCommentEdit = ref({} as any);
    const isLoading = ref({
        get: false,
        send: false,
        update: false,
        remove: false,
        pin: false,
    });
    const commentObjectCreate = ref({
        content: '',
        attachments: {
            files: [],
            notes: [],
        },
        mentionUserIds: [],
        mentionGroup: false,
    });
    const commentTaskInputRef = ref<any>(null);

    const updateCacheStateByTaskId = (data) => {
        commentObjectCreate.value.content = data['content'] || '';
        commentObjectCreate.value.attachments.files =
            data['attachmentFiles'] || [];
        commentObjectCreate.value.attachments.notes = data['noteList'] || [];
        commentObjectCreate.value.mentionUserIds = data['mentionUserIds'] || [];
        commentObjectCreate.value.mentionGroup = data['mentionGroup'] || false;
    };

    const onUpdateContentValue = (content) => {
        // console.log('----onUpdateContentValue', content);
        commentObjectCreate.value.content = content;
    };

    const refreshCommentCreate = () => {
        commentObjectCreate.value.content = '';
        commentObjectCreate.value.attachments.notes = [];
        commentObjectCreate.value.attachments.files = [];
        commentObjectCreate.value.mentionUserIds = [];
        commentObjectCreate.value.mentionGroup = false;
    };

    const getAllComments = async (taskId) => {
        isLoading.value.get = true;
        await _taskCommentStore.getAllCommentsByTaskId(taskId);
        isLoading.value.get = false;
    };

    const onCreateComment = async (
        orgId,
        taskId,
        attachments: ICommentAttachmentInput
    ) => {
        isLoading.value.send = true;
        // console.log(' mentionUserIds mentionGroup', commentObjectCreate.value?.mentionUserIds, commentObjectCreate.value?.mentionGroup);

        if (commentObjectCreate.value?.mentionUserIds?.length > 0)
            commentObjectCreate.value.mentionGroup = false;

        await _taskCommentStore.createComment(
            orgId,
            taskId,
            commentObjectCreate.value.content,
            attachments?.addedFiles,
            attachments?.addedNotes,
            commentObjectCreate.value.mentionUserIds,
            commentObjectCreate.value.mentionGroup
        );
        refreshCommentCreate();
        isLoading.value.send = false;

        commentTaskInputRef.value?.resetForm();
    };

    const onEditComment = (data) => {
        currentCommentEdit.value = cloneDeep(data);
    };

    const onCloseEditComment = () => {
        // console.log('currentCommentEdit.value', currentCommentEdit.value);
        currentCommentEdit.value = {};
    };

    const onSaveUpdateComment = async (
        attachments: ICommentAttachmentInput
    ) => {
        isLoading.value.update = true;

        // console.log(' mentionUserIds mentionGroup', currentCommentEdit.value?.mentionUserIds, currentCommentEdit.value?.mentionGroup);

        if (currentCommentEdit.value?.mentionUserIds?.length > 0)
            currentCommentEdit.value.mentionGroup = false;

        await _taskCommentStore.onUpdateComment(
            currentCommentEdit.value?.id,
            currentCommentEdit.value?.content?.toString(),
            attachments,
            currentCommentEdit.value?.mentionUserIds || [],
            currentCommentEdit.value?.mentionGroup
        );
        currentCommentEdit.value = {};
        isLoading.value.update = false;
    };

    const onAddReactionComment = async (data) => {
        const indexCmt = listCommentByTaskId?.value?.findIndex(
            (el) => el?.id === data?.id
        );
        if (indexCmt == -1) return;
        await _taskCommentStore.addReactionComment(
            data?.id,
            data?.content,
            false
        );
    };

    const onRemoveReaction = async (data) => {
        const indexCmt = listCommentByTaskId?.value?.findIndex(
            (el: any) => el?.id === data?.commentId
        );
        if (indexCmt === -1) return;
        await _taskCommentStore.addReactionComment(
            data?.commentId,
            data?.reaction?.content,
            true
        );
    };

    const onDeleteComment = async (cmtId) => {
        isLoading.value.remove = true;
        await _taskCommentStore.deleteComment(cmtId);
        isLoading.value.remove = false;
    };

    const onPinComment = async (cmtId) => {
        isLoading.value.pin = true;
        await _taskCommentStore.pinnedComment(cmtId);
        isLoading.value.pin = false;
    };

    const resetData = () => {
        commentObjectCreate.value = {
            content: '',
            attachments: {
                files: [],
                notes: [],
            },
            mentionUserIds: [],
            mentionGroup: false,
        };
    };

    return {
        commentTaskInputRef,
        isOpenCommentTask,
        commentObjectCreate,
        isLoading,
        currentCommentEdit,
        onUpdateContentValue,
        onCreateComment,
        getAllComments,
        onAddReactionComment,
        onSaveUpdateComment,
        onEditComment,
        onCloseEditComment,
        onDeleteComment,
        onPinComment,
        onRemoveReaction,
        resetData,
        updateCacheStateByTaskId,
    };
}
