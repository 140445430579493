<script setup lang="ts">
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import settingStore from '@/store/setting';
import { computed, ref } from 'vue';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const _settingStore = settingStore();
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);

let { unsubscribeCallbacks } = listenFirebaseRealtime();

const isShowNotification = ref(false);

const realtimeData = ref<{
  enabled: boolean;
} | null>(null);

const handleManageDepartmentChange = (data) => {
  if (
    !data ||
    departmentManagementEnabled.value == data?.enabled ||
    Date.now() - data?.lastUpdatedDate > 1000 * 10
  )
    return;

  realtimeData.value = {
    enabled: data?.enabled,
  };

  isShowNotification.value = true;
};

const onListen = () => {
  const fbClass = new OrganizationDynamicModel(`operatingSettings`);
  unsubscribeCallbacks.value.push(
    fbClass.onChildAdded((data, key) => {
      switch (key) {
        case 'manageCalendar':
        case 'manageChat':
        case 'manageDayoff':
        case 'manageDomain':
        case 'manageGed':
        case 'manageStartWorking':
        case 'manageTask':
        case 'manageTimeWorking':
        case 'manageUser':
        case 'manageVideoCall':
          break;
        case 'manageDepartment':
          handleManageDepartmentChange(data);
          break;

        default:
          break;
      }
    }),
    fbClass.onChildChanged((data, key) => {
      switch (key) {
        case 'manageCalendar':
        case 'manageChat':
        case 'manageDayoff':
        case 'manageDomain':
        case 'manageGed':
        case 'manageStartWorking':
        case 'manageTask':
        case 'manageTimeWorking':
        case 'manageUser':
        case 'manageVideoCall':
          break;
        case 'manageDepartment':
          handleManageDepartmentChange(data);
          break;

        default:
          break;
      }
    }),
    fbClass.onChildRemoved((data, key) => {
      switch (key) {
        case 'manageCalendar':
        case 'manageChat':
        case 'manageDayoff':
        case 'manageDomain':
        case 'manageGed':
        case 'manageStartWorking':
        case 'manageTask':
        case 'manageTimeWorking':
        case 'manageUser':
        case 'manageVideoCall':
          break;
        case 'manageDepartment':
          handleManageDepartmentChange(data);
          break;

        default:
          break;
      }
    })
  );
};

const onCancel = () => {
  isShowNotification.value = false;
};
const onConfirm = () => {
  location.reload();
};

onListen();
</script>
<template>
  <SynModal
    v-if="isShowNotification && realtimeData"
    container-class="w-max"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    @cancel="onCancel"
  >
    <template #body>
      <div class="flex-center flex-col space-y-8" style="width: 40rem">
        <div class="flex-center w-full pt-4 text-current-400">
          <div class="w-20">
            <VigImage
              :path="`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/images/${
                realtimeData?.enabled
                  ? 'department_active'
                  : 'department_deactive'
              }.png`"
            />
          </div>
        </div>
        <div class="flex flex-col text-center space-y-4 px-8">
          <span
            class="text-gray-600"
            v-html="
              realtimeData?.enabled
                ? $t('DEPARTMENT_REALTIME_TURN_ON')
                : $t('DEPARTMENT_REALTIME_TURN_OFF')
            "
          >
          </span>
        </div>
        <div class="w-full flex items-center justify-end space-x-2">
          <SynButton
            :title="'Join an existed workspace'"
            class="rounded-full bg-current"
            @click="onConfirm"
          >
            <span>
              {{ $t('COMMON_LABEL_GOT_IT') || 'Got it' }}
            </span>
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
