<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
// import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  formatDate,
  currentTimeUser,
  currentTimeDevice,
} from '@/ui/hooks/calendarHook';
import {
  CalendarEventReminderStatusEnum,
  CalendarType,
  CalendarMeetingType,
} from '@/domain/enums/CalendarEnum';
import calendarStore from '@/store/calendar';
import UserById from '../../../components/user/UserById.vue';
import taskStore from '@/store/task';
import taskDrawerStore from '@/store/task/drawer';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
import dayjs from 'dayjs';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

const _calendarStore = calendarStore();
const _taskStore = taskStore();
const _taskDrawerStore = taskDrawerStore();

const isRinging = ref(false);
const eventReminderDataById = ref({});
const eventReminderIds = ref([] as string[]);
const currentEventReminderId = ref('' as string);

const beforeEventMinute = ref<number | null>(10);
let calendarEventReminderFbClass: UserDynamicModel;

const currentEventReminderData = computed(
  () => eventReminderDataById.value[currentEventReminderId.value]
);

const currentTime = ref(dayjs());

const remainMinutes = computed<number>(() => {
  return dayjs(currentEventReminderData.value?.startDate).diff(
    currentTime.value,
    'm'
  );
});

watch(
  () => remainMinutes.value,
  () => {
    if (remainMinutes.value <= 5) {
      beforeEventMinute.value = null;
      isHasReminderAgain.value = false;
    } else {
      if (
        beforeEventMinute.value &&
        beforeEventMinute.value > remainMinutes.value
      )
        beforeEventMinute.value = null;
    }
  }
);
const currentEventReminderMetadata = computed(
  () => currentEventReminderData.value?.metadata
);

const lisUserParticipants = computed(() =>
  currentEventReminderMetadata.value?.participant_ids?.length > 3
    ? currentEventReminderMetadata.value?.participant_ids.slice(0, 3)
    : currentEventReminderMetadata.value?.participant_ids
);

const labelUtcUser = computed(() => {
  let currentDateDevice = currentTimeDevice();
  let currentDateUser = currentTimeUser();
  let startDate = formatDate(currentEventReminderData.value.startDate);
  return currentDateDevice?.utcDate !== currentDateUser?.utcDate &&
    startDate?.minuteValue - currentDateUser?.minuteValue <= 0
    ? '(GMT ' + currentDateUser.utcDate + ')'
    : '';
});

let unscribleCalendarEventReminderChildAdded;
let unscribleCalendarEventReminderChildChanged;
let unscribleCalendarEventReminderChildRemoved;

const onInit = async () => {
  const userId = getCurrentUserId();
  if (!userId) return;
  // Update user status for Organization

  const userPath = `user_${userId}`;

  calendarEventReminderFbClass = new UserDynamicModel(
    `${userPath}/calendarEventReminders`
  );

  unscribleCalendarEventReminderChildAdded =
    calendarEventReminderFbClass.onChildAdded(handleUserHasNewReminder);
  unscribleCalendarEventReminderChildChanged =
    calendarEventReminderFbClass.onChildChanged(handleUserHasUpdatedReminder);
  unscribleCalendarEventReminderChildRemoved =
    calendarEventReminderFbClass.onChildRemoved(handleUserHasRemovedReminder);
};

const onTheNextReminder = () => {
  openOrCloseRinging(false);
  currentEventReminderId.value = '';

  if (eventReminderIds.value.length == 0) return;

  currentEventReminderId.value = eventReminderIds.value[0];
  openOrCloseRinging(true);
};

let intervalCurrentTime;
const openOrCloseRinging = (isOpen) => {
  isRinging.value = isOpen;
  if (isOpen) {
    intervalCurrentTime = setInterval(() => {
      currentTime.value = dayjs();
    }, 1 * 1000);
  } else clearInterval(intervalCurrentTime);
};

const handleUserHasNewReminder = (eventReminderData) => {
  const eventReminderId = eventReminderData?.id;
  const status = eventReminderData?.status;
  if (
    !eventReminderId ||
    (status !== CalendarEventReminderStatusEnum.New &&
      status !== CalendarEventReminderStatusEnum.Renew)
  )
    return;

  eventReminderIds.value = [
    ...new Set([...eventReminderIds.value, eventReminderId]),
  ];

  eventReminderDataById.value[eventReminderId] = eventReminderData;

  onTheNextReminder();
};

const handleUserHasUpdatedReminder = (eventReminderData) => {
  const eventReminderId = eventReminderData?.id;
  const status = eventReminderData?.status;
  if (!eventReminderId || !status) return;

  if (
    status == CalendarEventReminderStatusEnum.New ||
    status == CalendarEventReminderStatusEnum.Renew
  ) {
    eventReminderIds.value = [
      ...new Set([...eventReminderIds.value, eventReminderId]),
    ];

    eventReminderDataById.value[eventReminderId] = eventReminderData;
    onTheNextReminder();
  }

  if (status == CalendarEventReminderStatusEnum.Acknowledge) {
    handleUserHasRemovedReminder(eventReminderData);
  }
};

const handleUserHasRemovedReminder = (eventReminderData) => {
  const eventReminderId = eventReminderData?.id;
  const status = eventReminderData?.status;
  if (!eventReminderId || !status) return;

  const newSet = new Set([...eventReminderIds.value]);

  newSet.delete(eventReminderId);

  eventReminderIds.value = [...newSet];
  onTheNextReminder();
};

const onStopReminder = async () => {
  if (currentEventReminderData.value?.type == CalendarType.Task) {
    const taskId = parseInt(currentEventReminderData.value?.sourceId);
    await _taskStore.stopTaskReminder(currentEventReminderData.value?.id);
    const taskDetail = await _taskDrawerStore.getDetailById(taskId);
    _taskDrawerStore.lastUpdateTaskReminderTime[taskId] = new Date();
    onOpenTaskDetail(taskDetail);
    onTheNextReminder();
  } else {
    await _calendarStore.stopReminder(currentEventReminderId.value);
    isRinging.value = false;
  }
};

const onSnooze = async () => {
  if (!beforeEventMinute.value) return;

  const canSnooze = canSnoozeTime(beforeEventMinute.value);
  if (!canSnooze) return;

  const snoozeTime = dayjs(currentEventReminderData.value?.startDate)
    .subtract(beforeEventMinute.value, 'minute')
    .diff(dayjs(), 'minute');

  console.log('🚀 Tictop ~ snoozeTime:', snoozeTime);

  if (currentEventReminderData.value?.type == CalendarType.Task) {
    await _taskStore.renewTaskReminder(
      currentEventReminderData.value?.id,
      Math.max(snoozeTime, 1)
    );
    const taskId = parseInt(currentEventReminderData.value?.sourceId);
    _taskDrawerStore.lastUpdateTaskReminderTime[taskId] = new Date();
  } else {
    await _calendarStore.snoozeReminder(
      currentEventReminderId.value,
      Math.max(snoozeTime, 1)
    );
  }
  isRinging.value = false;
};

const onOpenTaskDetail = (task) => {
  _taskDrawerStore.pushCurrentIds({ ...task, tabType: 'DETAIL' });
};

onMounted(() => {
  onInit();
});

onUnmounted(() => {
  unscribleCalendarEventReminderChildAdded &&
    unscribleCalendarEventReminderChildAdded();
  unscribleCalendarEventReminderChildChanged &&
    unscribleCalendarEventReminderChildChanged();
  unscribleCalendarEventReminderChildRemoved &&
    unscribleCalendarEventReminderChildRemoved();
});

const isHasReminderAgain = ref<boolean>(true);

const onClickOk = () => {
  if (isHasReminderAgain.value) onSnooze();
  else onStopReminder();
};

const canSnoozeTime = (newBeforeMinute): boolean => {
  currentTime.value = dayjs();
  // const _remainMinutes = dayjs(currentEventReminderData.value?.startDate).diff(
  //   currentTime.value,
  //   'm'
  // );
  if (remainMinutes.value <= 0) {
    settingGlobalModal({
      type: 'warning',
      title: translate('COMMON_LABEL_NOTE'),
      content: translate('REMINDER_LABEL_THE_EVENT_HAS_TAKEN_PLACE'),
      confirmLabel: translate('COMMON_LABEL_GOT_IT'),
      confirmable: true,
    });

    ask();

    beforeEventMinute.value = null;
    return false;
  }
  if (newBeforeMinute >= remainMinutes.value) {
    settingGlobalModal({
      type: 'warning',
      title: translate('COMMON_LABEL_NOTE'),
      content: translate('REMINDER_LABEL_THE_EVENT_WILL_START_IN_X_MINUTES', {
        minute: remainMinutes.value || 0,
      }),
      confirmLabel: translate('COMMON_LABEL_GOT_IT'),
      confirmable: true,
    });

    ask();

    if (beforeEventMinute.value && beforeEventMinute.value >= newBeforeMinute)
      beforeEventMinute.value = null;
    return false;
  }

  return true;
};

const onChangeBeforeMinute = (newBeforeMinute) => {
  const canSnooze = canSnoozeTime(newBeforeMinute);
  if (!canSnooze) return;

  isHasReminderAgain.value = true;
  beforeEventMinute.value = newBeforeMinute;
};
</script>

<template>
  <syn-modal
    v-if="isRinging"
    z-index="z-50"
    disable-click-outside
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    container-class="w-[32rem]"
    style-body="p-0"
  >
    <template #body>
      <div class="py-4 px-2">
        <SynAnimation
          :name="
            currentEventReminderData?.type == CalendarType.Reminder ||
            currentEventReminderData?.type == CalendarType.Task
              ? 'event-reminder'
              : currentEventReminderMetadata?.meeting_type ==
                CalendarMeetingType.PhysicalMeeting
              ? 'event-meetingphysical'
              : 'event-mettingonl'
          "
          :stype="
            currentEventReminderMetadata?.meeting_type ==
              CalendarMeetingType.PhysicalMeeting ||
            currentEventReminderData?.type == CalendarType.Task
              ? 'width: 100px;'
              : 'width: 40px;'
          "
        />

        <div
          class="
            text-center
            px-2
            pt-1
            flex flex-col
            items-center
            justify-center
          "
        >
          <span
            v-if="currentEventReminderData?.type == CalendarType.Task"
            class="text-gray-700 mb-2"
            style="font-size: 15px"
            >{{ $t('COMMON_LABEL_REMINDER_TASK_TITLE') }}</span
          >
          <span class="text-current-800" style="font-size: 17px">{{
            currentEventReminderData?.title
          }}</span>
        </div>

        <div
          v-if="currentEventReminderData?.type != CalendarType.Task"
          class="flex-center flex-col pt-4 pb-4 min-w-max"
        >
          <div style="font-size: 14px">
            {{ formatDate(currentEventReminderData?.startDate).date }}
          </div>

          <div class="flex-center">
            <div
              v-if="currentEventReminderData?.type == CalendarType.Reminder"
              class="font-bold text-current-800"
            >
              {{ formatDate(currentEventReminderData?.startDate).time }}
            </div>
            <div v-else class="font-bold text-current-800">
              {{
                formatDate(currentEventReminderData?.startDate).time +
                ' - ' +
                formatDate(currentEventReminderData?.endDate).time
              }}
            </div>
            <span
              v-if="labelUtcUser"
              class="font-bold text-current-800 px-1"
              style="font-size: 14px"
              >{{ labelUtcUser }}</span
            >
          </div>

          <span
            v-if="currentEventReminderData?.type !== CalendarType.Task"
            class="text-sm text-orange-500"
          >
            <template v-if="remainMinutes > 0">
              <span>{{
                $t('CALENDAR_ALERT_LABEL_TIME_DIFF') +
                ' ' +
                $t('COMMON_LABEL_X_UPCOMING_MINUTES', {
                  min: remainMinutes,
                })
              }}</span>
            </template>
            <span v-else>{{
              $t('REMINDER_LABEL_THE_EVENT_HAS_TAKEN_PLACE')
            }}</span>
          </span>
        </div>

        <!-- DURATION TIME -->
        <!-- <template v-if="currentEventReminderMetadata?.meeting_duration">
          <div class="flex-center text-xs">
            <span class="px-1">{{
              $t('COMMON_LABEL_PLANED_TIME') + ': '
            }}</span>
            <span class="font-medium text-current-600">{{
              currentEventReminderMetadata?.meeting_duration +
              '  ' +
              $t('COMMON_LABEL_MINUTE')
            }}</span>
          </div>
        </template> -->

        <!-- PARTICIPANT -->
        <template
          v-if="
            currentEventReminderMetadata?.participant_ids?.length > 0 &&
            currentEventReminderData?.type != CalendarType.Task
          "
        >
          <div class="flex-center py-2">
            <UserById
              v-for="item in lisUserParticipants"
              :key="item"
              class="-ml-2 w-9 h-9"
              avatar-class="w-9 h-9"
              is-hidden-name
              :user-id="item"
            />
            <vig-dropdown
              v-if="currentEventReminderMetadata?.participant_ids?.length > 3"
              trigger="mouseenter"
              z-index="80"
              placement="right"
            >
              <template #dropdown-toggle>
                <div
                  v-if="
                    currentEventReminderMetadata?.participant_ids?.length > 3
                  "
                  class="
                    w-10
                    h-10
                    rounded-full
                    bg-current-50
                    flex-center
                    font-medium
                    z-80
                    border-2 border-white
                  "
                >
                  {{
                    '+' +
                    (currentEventReminderMetadata?.participant_ids?.length -
                      lisUserParticipants.length)
                  }}
                </div>
              </template>
              <template #dropdown-menu>
                <div class="flex flex-col">
                  <UserById
                    v-for="item in currentEventReminderMetadata?.participant_ids.slice(
                      3,
                      currentEventReminderMetadata?.participant_ids?.length
                    )"
                    :key="item"
                    class="py-1 pr-4 pl-1 text-sm"
                    avatar-class="w-9 h-9"
                    :user-id="item"
                  />
                </div>
              </template>
            </vig-dropdown>
          </div>
        </template>

        <!-- ACTION -->
        <div
          v-if="remainMinutes > 5"
          class="w-full flex-center px-2 pb-2 pt-6 gap-2"
        >
          <AtomSwitch v-model="isHasReminderAgain" />

          <span>
            {{ $t('COMMON_LABEL_SNOOZE') }}
          </span>
          <VigDropdown
            :append-to-body="false"
            z-index="50"
            placement="top"
            :arrow="false"
            @click.stop="() => {}"
          >
            <template #dropdown-toggle>
              <div
                class="
                  flex-center
                  space-x-1
                  h-8
                  cursor-pointer
                  px-2
                  border
                  rounded-r
                "
              >
                <span class="flex-center">
                  {{ beforeEventMinute }}
                </span>
                <span>
                  {{ $t('COMMON_LABEL_MINUTE') }}
                </span>
                <SynIcon name="chevron-double-down" />
              </div>
            </template>
            <template #dropdown-menu>
              <div
                class="
                  list-ul
                  text-xs
                  w-full
                  overflow-x-hidden overflow-auto
                  small-scrollbar
                "
              >
                <div
                  v-for="(item, index) in [5, 10, 15, 30, 60]"
                  :key="index"
                  class="
                    list-li
                    dropdown-item
                    px-4
                    py-2
                    text-xs text-black
                    hover:bg-gray-100 hover:text-current-700
                    cursor-pointer
                    break-words
                  "
                  @click.stop="onChangeBeforeMinute(item)"
                >
                  <span>
                    {{ item }}
                  </span>
                  <span>min</span>
                </div>
              </div>
            </template>
          </VigDropdown>
          <span class="lowercase">
            {{ $t('REMINDER_LABEL_BEFORE_THE_EVENT') }}
          </span>
        </div>
        <div class="flex justify-end px-2 pb-2 pt-2 space-x-2">
          <SynButton
            v-if="remainMinutes > 5"
            :disabled="isHasReminderAgain && !beforeEventMinute"
            @click="onClickOk"
          >
            {{
              $t(
                isHasReminderAgain
                  ? 'COMMON_LABEL_SNOOZE'
                  : 'COMMON_LABEL_CLOSE'
              )
            }}
          </SynButton>
          <SynButton v-else @click="onStopReminder">
            {{ $t('COMMON_LABEL_CLOSE') }}
          </SynButton>
        </div>
      </div>

      <audio class="hidden" autoplay>
        <source
          :src="`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/audio/coockoo.wav`"
          type="audio/ogg"
        />
      </audio>
    </template>
  </syn-modal>
</template>
<style scoped>
.animate-rec {
  animation-name: audio-pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes audio-pulse {
  0% {
    box-shadow: 0px 0px 5px 0px #dcf4f5;
  }
  65% {
    box-shadow: 0px 0px 5px 13px #94cdcf;
  }
  90% {
    box-shadow: 0px 0px 5px 13px #68c7c7;
  }
}
</style>
