<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import userStore from '@/store/user';
import CustomLollipopChart from '@/ui/common/plugins/chart/CustomLollipopChart.vue';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    size: number;
  };
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    assigneeId: number;
    fullName: string;
    avatar: string;
    overdueTasks: number;
    lastCreationTime: string;
  }[]
>([]);

const chartData = computed<
  {
    total: number;
    id: number;
    name: string;
    avatar: string;
  }[]
>(() => {
  return resultData.value?.map((o) => {
    return {
      total: o.overdueTasks,
      id: o.assigneeId,
      name: o.fullName,
      avatar: o.avatar,
    };
  });
});

const initComponent = async () => {
  isLoading.value = true;
  // const res = await WorkPerformanceRepository.getUserMostOverdueTask({
  //   ...props.filterData,
  // });

  const res = await WorkPerformanceRepository.getUserMostOverdueTask({
    ...props.filterData,
  });
  resultData.value = res?.result
    ?.filter((o) => o.overdueTasks > 0)
    ?.map((o) => {
      return {
        ...o,
        avatar: userStore().allUserByIds[o.assigneeId]?.avatar,
      };
    });

  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.size == old.size
    )
      return;

    initComponent();
  }
);
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <CustomLollipopChart
    v-else
    :chart-data="chartData"
    class="pt-4"
    :options="{
      backgroundColor: 'rgba(248, 113, 113, 0.2)',
      borderColor: 'rgba(248, 113, 113, 0.2)',
      hoverBackgroundColor: 'rgba(248, 113, 113, 0.5)',
      color: 'rgba(248, 113, 113, 1)',
    }"
  />
</template>
