import { ref } from 'vue';

export default function delayExecuteSomething(
    delayToExecuteMillisecond = 1000,
    maxDelayMillisecond = 10000
) {
    const latestActionTime = ref(0);
    const isWaiting = ref<boolean>(false);
    let timeoutToExecute;
    const delayData = {
        delayToExecuteMillisecond: delayToExecuteMillisecond,
        maxDelayMillisecond: maxDelayMillisecond,
    };

    const handleDelayExecuteSomeThings = (actionTime, callback) => {
        if (!latestActionTime.value) latestActionTime.value = actionTime;

        // const isWithinLimitExtendTime =
        //     actionTime &&
        //     Date.now() - actionTime - delayData.delayToExecuteMillisecond <
        //         delayData.maxDelayMillisecond;

        if (
            Date.now() - latestActionTime.value >=
            delayData.maxDelayMillisecond
        ) {
            handleClearTimeout(false);

            if (callback && typeof callback == 'function') callback();
            latestActionTime.value = 0;
        }

        handleClearTimeout(true);

        timeoutToExecute = setTimeout(() => {
            latestActionTime.value = 0;
            if (callback && typeof callback == 'function') callback();
            isWaiting.value = false;
        }, delayData.delayToExecuteMillisecond);
    };

    const handleClearTimeout = (_isWaiting) => {
        if (timeoutToExecute) clearTimeout(timeoutToExecute);
        isWaiting.value = _isWaiting;
    };

    return { isWaiting, handleDelayExecuteSomeThings, handleClearTimeout };
}
