import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';

export default defineStore({
    id: storeId.AUTH_REGISTER,
    state: () => ({
        _unauthService: UnAuthenticateService.getInstance(),
        email: '',
        phone: '',
        resetToken: '',
    }),
    getters: {},
    actions: {
        async checkPhone(phone: string): Promise<any> {
            const res = await this._unauthService.checkPhone(phone);
            return res?.result;
        },
        async checkUsername(username: string): Promise<any> {
            const res = await this._unauthService.checkUsername(username);
            return res?.result;
        },
        async checkEmail(email: string): Promise<any> {
            const res = await this._unauthService.checkEmail(email);
            return res?.result;
        },
        sendVerificationCodeByPhoneNumber(data: any): Promise<any> {
            return this._unauthService.sendVerificationCodeByPhoneNumber(data);
        },
        reSendVerificationCodeByPhoneNumber(data: any): Promise<any> {
            return this._unauthService.reSendVerificationCodeByPhoneNumber(
                data
            );
        },
        verifyUserPhoneNumber(data: any): Promise<any> {
            return this._unauthService.verifyUserPhoneNumber(data);
        },
        saveUserProfile(
            data: any,
            refId: string,
            voucherCode: string,
            advertisementInfo: any
        ): Promise<any> {
            return this._unauthService.saveUserProfile(
                data,
                refId,
                voucherCode,
                advertisementInfo
            );
        },
        registerWithUsername(
            data: any,
            refId: string,
            voucherCode: string,
            advertisementInfo: any
        ): Promise<any> {
            return this._unauthService.registerWithUsername(
                data,
                refId,
                voucherCode,
                advertisementInfo
            );
        },
    },
});
