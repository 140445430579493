<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import { taskListRouteByType } from '@/ui/hooks/commonHook';
import myProfile from '@/store/auth/my-profile';
import { GroupType } from '@/application/enums/GroupEnums';

const props = withDefaults(
  defineProps<{
    group: any;
    showModal: boolean;
    position: string;
  }>(),
  {
    position: 'members',
  }
);

const emit = defineEmits(['cancel', 'deletedSuccess']);

const router = useRouter();
const _groupStore = groupStore();
const showDeleteGroup = ref(props.showModal);
const isGroupChat = computed(() => props.group?.type === GroupType.CHAT);
const posititonModal = computed(() => props.position);
const systemGroup = computed(
  () =>
    props.group?.code == 'DELETED_GROUP' || props.group?.code == 'GENERAL_GROUP'
);
const options = ref([
  {
    id: 'op1',
    key: 'GROUP_LABEL_OPTION_DELETE_1',
    subDes: 'GROUP_LABEL_SUB_OPTION_DELETE_1',
    isSelected: true,
    opt: 1,
  },
  {
    id: 'op2',
    key: 'GROUP_LABEL_OPTION_DELETE_2',
    subDes: 'GROUP_LABEL_SUB_OPTION_DELETE_2',
    isSelected: false,
    opt: 2,
  },
  {
    id: 'op3',
    key: 'GROUP_LABEL_OPTION_DELETE_3',
    subDes: 'GROUP_LABEL_SUB_OPTION_DELETE_3',
    isSelected: false,
    opt: 3,
  },
]);

const subDescription = ref('GROUP_LABEL_SUB_OPTION_DELETE_1');

const changeOption = (key) => {
  options.value.forEach((op) => {
    if (op.id == key) {
      op.isSelected = true;
      subDescription.value = op.subDes;
    } else {
      op.isSelected = false;
    }
  });
};
const isLoadingDelete = ref(false);
const onDeleteGroup = async () => {
  isLoadingDelete.value = true;
  const res = await _groupStore.deleteGroup(
    props.group?.id,
    options.value.find((el) => el.isSelected)?.opt
  );

  if (res?.error?.message) {
    isLoadingDelete.value = false;
  } else {
    isLoadingDelete.value = false;
    showDeleteGroup.value = false;
    if (posititonModal.value == 'list') {
      _groupStore.fetchMyGroups();
      router.push(taskListRouteByType('USER', myProfile().myProfile?.id));
      emit('cancel', false);
    } else {
      overviewGroupStore().removeGroupById(props.group?.id);

      _groupStore.getAllGroup();

      emit('cancel', false);
    }
    emit('deletedSuccess', true);
  }
};

const onCancel = () => {
  emit('cancel', false);
};
</script>

<template>
  <syn-question-modal
    v-if="!systemGroup"
    z-index="z-50"
    :visible="showDeleteGroup"
    :show-confirm-btn="false"
    :show-delete-btn="true"
    :header-text="$t('GROUP_LABEL_DELETE') + ' - ' + group?.name"
    :delete-label="$t('GROUP_LABEL_DELETE')"
    :is-loading="isLoadingDelete"
    @on-delete="
      (value) => {
        value ? onDeleteGroup() : onCancel();
      }
    "
    @cancel="onCancel"
  >
    <template #content>
      <template v-if="isGroupChat">
        <div class="italic text-current-700">
          <span class="font-bold">{{ $t('COMMON_LABEL_NOTE') + ': ' }}</span>
          <span>{{ $t('GROUP_CHAT_DELETE_MSG') }}</span>
        </div>
      </template>

      <template v-else>
        <div class="px-4 pt-4 font-medium flex space-x-2 items-center text-md">
          <SynIcon name="trash" is-active custom-class="w-4 h-4 fill-current" />
          <span>{{ $t('GROUP_LABEL_CONFIRM_DELETE_GROUP') }}</span>
        </div>
        <div class="flex space-y-3 py-4 ml-8 flex-wrap text-sm cursor-pointer">
          <div
            v-for="opt in options"
            :key="opt?.id"
            class="flex space-x-2 w-full items-center"
          >
            <input
              :id="opt.id"
              type="radio"
              name="option_remove"
              :checked="opt.isSelected"
              class="
                text-current-500
                focus:border-current-400
                focus:ring
                focus:ring-offset-0
                focus:ring-current-400
                focus:ring-opacity-30
              "
              @change="changeOption(opt.id)"
            />
            <label class="cursor-pointer" :for="opt.id">{{
              $t(opt.key)
            }}</label>
          </div>
        </div>
        <div
          v-if="subDescription"
          class="px-4 pt-2 text-sm italic text-current-700 ml-4"
        >
          <span class="font-bold">{{ $t('COMMON_LABEL_NOTE') + ': ' }}</span>
          <span>{{ $t(subDescription) }}</span>
        </div>
      </template>
    </template>
  </syn-question-modal>

  <SynModal
    v-if="systemGroup"
    :visible="showDeleteGroup"
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    disable-click-outside
    is-hidden-header
    is-hidden-footer
    container-style="width:400px; min-height: 250px"
    @cancel="onCancel"
  >
    <template #body>
      <div class="flex-1 overflow-hidden flex flex-col p-8">
        <div class="flex-center p-6">
          <div class="flex-center h-16 w-16 ring ring-orange-300 rounded-full">
            <SynIcon
              name="warning-circle"
              custom-class="w-16 h-16 fill-orange-500"
            />
          </div>
        </div>
        <div class="flex-center">
          <span class="font-semibold">
            {{
              $t('GROUP_LABEL_REMOVE_SYSTEM_GROUP_TITLE') ||
              'Please do not delete this group'
            }}
          </span>
        </div>
        <div class="text-center">
          <span
            class="text-sm text-gray-600"
            v-html="
              $t('GROUP_LABEL_REMOVE_SYSTEM_GROUP_CONTENT', {
                groupName: group?.name,
              })
            "
          >
          </span>
        </div>
      </div>
    </template>
  </SynModal>
</template>
