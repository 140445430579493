import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

import BaseRequest from '@/base/api/base-request-ticket';

export default class TicketTaskApi extends BaseRequest {
    private static instance: TicketTaskApi;
    constructor() {
        super();
    }

    public static getInstance(): TicketTaskApi {
        if (!TicketTaskApi.instance) {
            // Get from local storage
            TicketTaskApi.instance = new TicketTaskApi();
        }

        return TicketTaskApi.instance;
    }

    getDomainScopeList(userId) {
        return this.request({
            url: `Domain/GetDomainScopeList?userId=${userId}`,
            method: 'get',
        });
    }

    getDomainScopeListByUserId(userId) {
        return this.request({
            url: `Domain/GetDomainScopeListByUserId?userId=${userId}`,
            method: 'get',
        });
    }

    getAll() {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);
        return this.request({
            url: `Domain/GetAll?lang=${lang}`,
            method: 'get',
        });
    }

    getAllDomainUserList() {
        return this.request({
            url: `Domain/GetAllDomainUserList`,
            method: 'get',
        });
    }
}
