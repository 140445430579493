import { IUpdateDeadlineForm, TaskSchedule } from '../TaskType';
import { getDefaultTaskSchedule } from '@/application/parsers/TaskParser';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import {
    convertUrgencyToPriority,
    convertUrgencyToImportant,
    getUrgencyValue,
    TaskUrgency,
} from '@/ui/hooks/taskHook';
import {
    PlanTypeEnum,
    ScheduleDayOptions,
    ScheduleOtherTimeOptions,
    TaskLife,
} from '@/domain/enums/taskEnum';
import TaskService from '@/application/services/task/TaskService';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { v4 as uuidv4 } from 'uuid';

export default class TaskCreatePayload {
    id: number;
    groupId: number | null;
    name: string;
    description: string;
    taskLife: number;
    scheduleTime: string;
    assigneeId: number | null;
    creatorId: number | null;
    lastModifierId: number | null;
    domainId: number | null;
    projectId: number | null;
    subprojectId: number | null;
    estimationTime: number;
    finisherId: number | null;
    taskSchedule: TaskSchedule;
    planType: number;
    priority: number | null;
    taskType: number;
    important: boolean | null;
    isPrivate: boolean;
    files: any[];
    notes: any[];
    attachments: any[];
    taggedMembers: any[];
    urgency: number | null;

    constructor(data: any) {
        this.id = data?.id;
        this.groupId = data?.groupId || null;
        this.name = data?.name || '';
        this.description = data?.description || '';
        this.taskLife = data?.taskLife || 0;
        this.scheduleTime = data?.scheduleTime || '';
        this.assigneeId = data?.assigneeId || null;
        this.creatorId = data?.creatorId || null;
        this.lastModifierId = data?.lastModifierId || null;
        this.domainId = data?.domainId || null;
        this.projectId = data?.projectId || null;
        this.subprojectId = data?.subprojectId || null;
        this.estimationTime = data?.estimationTime || 0;
        this.finisherId = data?.finisherId || null;
        this.taskSchedule = data?.taskSchedule
            ? {
                  ...data?.taskSchedule,
                  scheduleDateValue: this.scheduleTime,
              }
            : getDefaultTaskSchedule();
        this.planType = data?.planType || 0;
        this.priority = data?.priority;
        this.taskType = data?.taskType || 1;
        this.files = data?.files || [];
        this.notes = data?.notes || [];
        this.attachments = data?.attachments || [];
        this.taggedMembers = data?.taggedMembers || [];
        this.important = data?.important || false;
        this.isPrivate = data?.isPrivate || false;
        this.urgency = getUrgencyValue(data?.priority, data?.important);

        if (data.isTicket) return;
    }

    changeState(state: TaskLife) {
        this.taskLife = state;

        if (state !== TaskLife.Finished) return;
        this.finisherId = getCurrentUserId();
    }
    changeUrgency(urgency) {
        this.priority = convertUrgencyToPriority(urgency);
        this.important = convertUrgencyToImportant(urgency);
        this.urgency = urgency;
    }

    doItToday(isDoItToday: boolean) {
        if (isDoItToday) return;

        this.taskSchedule.finishItToday = false;
        this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.Nothing;
    }

    finishItToday(isFinishItToday: boolean) {
        this.updateDataByFinishToday(isFinishItToday);
    }

    changePrivate(isPrivate: any) {
        this.isPrivate = isPrivate;
    }

    changePriority(isHigh: boolean) {
        this.changeUrgency(
            isHigh ? TaskUrgency.UrgentImportant.key : TaskUrgency.Cool.key
        );
    }

    changeDomain(domainData: any) {
        this.domainId = domainData.domainId;
        this.projectId = domainData.projectId;
        this.subprojectId = domainData.subprojectId;
    }

    updateDataByFinishToday(isFinishItToday) {
        if (isFinishItToday) {
            this.taskSchedule.finishItToday = true;
            this.taskSchedule.doItToday = true;
            this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.OnDay;
            return;
        }

        this.taskSchedule.finishItToday = false;
        this.taskSchedule.planType = PlanTypeEnum.ToDo;
        this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.Nothing;
    }

    changeDeadline(deadlineDate: IUpdateDeadlineForm) {
        if (!deadlineDate) return;
        if (
            this.taskSchedule.finishItToday ||
            deadlineDate.scheduleOptionKey == ScheduleDayOptions.OnDay
        ) {
            this.finishItToday(
                deadlineDate.scheduleOptionKey == ScheduleDayOptions.OnDay
            );
        }

        this.taskSchedule.scheduleOptionKey = deadlineDate.scheduleOptionKey;
        this.taskSchedule.otherTime = deadlineDate.otherTimeKey;
        this.scheduleTime = deadlineDate.scheduleTime;

        this.getScheduleTime();
    }
    changeDeadlineOther(otherKey: ScheduleOtherTimeOptions) {
        if (!otherKey) return;

        this.taskSchedule.otherTime = otherKey;

        this.getScheduleTime();
    }
    changeAssignee(groupId, userId) {
        this.groupId = groupId;
        this.assigneeId = userId;
    }

    addFiles(files: any[]) {
        const newFiles = [...this.files, ...files];
        this.files = newFiles;
        this.attachments = [...this.attachments, ...newFiles];
    }

    addNote(noteData: any) {
        const newNote = {
            noteName: uuidv4(),
            fileUrl: noteData?.base64,
            type: 'image/jpeg',
            // size: noteData?.base64?.length * (3 / 4) - 2,
            isNote: true,
            isBase64: true,
            drawData: noteData?.drawData,
        };
        this.notes = [...this.notes, newNote];
        this.attachments = [...this.attachments, newNote];
    }

    removeFile(file: any) {
        if (!file) return;
        this.files = this.files.filter((o) => o.id !== file.id);
        this.attachments = this.attachments.filter((o) => o.id !== file.id);
    }
    removeNote(note: any) {
        if (!note) return;
        this.notes = this.notes.filter((o) => o.id !== note?.id);
        this.attachments = this.attachments.filter((o) => o.id !== note?.id);
    }

    getScheduleTime() {
        if (!TaskCreatePayload.userDayInfo) this.getUserDayInfo();
        let time;
        let options = [...TaskCreatePayload.userDayInfo];

        let key = this.taskSchedule.scheduleOptionKey;
        if (key == 4) {
            key = this.taskSchedule.otherTime || 0;
            options = [...TaskCreatePayload.userOtherDay];
        }

        const index = options?.findIndex((o) => o.scheduleOptionKey == key);
        if (index > -1) {
            time = options[index].scheduleDateValue;
        }

        time &&
            openNotification({
                type: AlertType.info,
                body: time,
                duration: 2000,
            });
        return time;
    }

    async getUserDayInfo() {
        const userId = this.assigneeId;
        if (userId) {
            const res: any =
                await TaskService.getInstance().getUserTaskSchedule(userId);

            TaskCreatePayload.userDayInfo = res.result;

            if (TaskCreatePayload.userDayInfo?.length > 0) {
                const index = TaskCreatePayload.userDayInfo.findIndex(
                    (o) => o.scheduleOptionKey == ScheduleDayOptions.OtherTime
                );

                if (index > -1) {
                    TaskCreatePayload.userOtherDay =
                        TaskCreatePayload.userDayInfo[index].otherTimes;
                }
            }
        }
    }

    static userDayInfo: any;
    static userOtherDay: any;
}
