<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
  onCreateGlobalSuccess,
} from '@/ui/modules/task/task-global-state';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { decryptUserId } from '@/ui/plugins/crypto/crypto';
import { taskListRouteByType } from '@/ui/hooks/commonHook';
import { getConfigScheduleColor, getUrgencyValue } from '@/ui/hooks/taskHook';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { TaskListTab } from '@/domain/enums/taskEnum';
import TaskService from '@/application/services/task/TaskService';
// import PrincipleService from '@/application/services/PrincipleService';

import myProfileStore from '@/store/auth/my-profile';
import taskStore from '@/store/task';
import taskListStore from '@/store/task/task-list-store';
import settingStore from '@/store/setting';
import startWorkingStore from '@/store/task/start-working-store';

import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import UserById from '@/ui/components/user/UserById.vue';

import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
import ColumnFilter from '@/ui/modules/task/table/ColumnFilter.vue';
import DomainById from '@/ui/modules/domains/component/DomainById.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import { EFilterTaskDefault } from '@/application/constants/task.const';

const emits = defineEmits<{
  (e: 'onCancel'): void;
  (e: 'onStart'): void;
}>();

const router = useRouter();
const route = useRoute();
const _taskStore = taskStore();
const _taskListStore = taskListStore();
const _settingStore = settingStore();
const _startWorkingStore = startWorkingStore();
const _taskService = TaskService.getInstance();

const typeStatus = computed(() => _startWorkingStore.typeStatus);
const myTimezone = computed(() => myProfileStore().getTimezone);

const startWorkingManagement = computed(
  () => _settingStore._organizationSetting.startWorkingManagement
);
const isDisableButtonSubmit = computed(
  () =>
    startWorkingManagement.value.mustSelectTask &&
    planToTreatTasks.value.filter((el: any) => el.isPlannedToTreat).length < 1
);

const isLoadingFetchTotoTask = ref(false);
const keySearch = ref('');
const keySearchToFinish = ref('');

const {
  currentStep: startWorkingStatus,
  stepperByKey: checkinStatusList,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    SELECT_TASKS: {
      key: 'SELECT_TASKS',
      previous: 'CANCEL',
      previousText: 'COMMON_LABEL_CANCEL',
      next: 'SELECT_TASKS_TO_FINISH',
      confirmText: 'COMMON_LABEL_CONTINUE',
      nextAction: () => {
        _startWorkingStore.updateListTaskToFinish();
        _startWorkingStore.setFilterDataOrigin('SELECT_TASKS_TO_FINISH');
        keySearchToFinish.value = '';
      },
      previousAction: () => {
        keySearch.value = '';
        emits('onCancel');
      },
    },
    SELECT_TASKS_TO_FINISH: {
      key: 'SELECT_TASKS_TO_FINISH',
      previous: 'SELECT_TASKS',
      next: 'STARTED',
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'COMMON_LABEL_SAVE',
      nextAction: () => {
        handleStartWD();
      },
      previousAction: () => {
        _startWorkingStore.resetFilterToTreatTask();
        keySearch.value = '';
        _startWorkingStore.setFilterDataOrigin('');
      },
    },
  },
  'SELECT_TASKS'
);
const initData = async () => {
  isLoadingFetchTotoTask.value = true;
  await _startWorkingStore.fetchListTask();
  isLoadingFetchTotoTask.value = false;
};

initData();

const onClickSort = (key, name) => {
  _startWorkingStore.genSortDataList({ key, name }, startWorkingStatus.value);
};

const onFilterByKeyOnColumn = (key, filters, status) => {
  _startWorkingStore.setFilterDataByKey(key, filters);
  _startWorkingStore.onFilterTaskOnColumn(status);
};

const onResetFilterOnColumn = (key, status) => {
  _startWorkingStore.resetFilterDataOrigin(key);
  _startWorkingStore.onFilterTaskOnColumn(status);
};

const resetSearch = () => {
  keySearch.value = '';
  keySearchToFinish.value = '';
};

const handleFinishTask = (task, index) => {
  _startWorkingStore.handleFinishTask(task, index);
};

const onCreateTaskGlobal = () => {
  newTaskDefaultPayload.value = {
    assigneeId: getCurrentUserId(),
  };
  isOpenCreateTaskDrawer.value = true;
  onCreateGlobalSuccess.value = _startWorkingStore.updateTodoList;
};

const isLoading = ref(false);

const handleStartWD = async () => {
  isLoading.value = true;
  await _taskService.saveTaskListPlannedToday(
    planToFinishTasks.value.map((o: any) => {
      return {
        isSelected: o.isSelected,
        taskId: o.id,
        planId: o.planId,
      };
    })
  );
  isLoading.value = false;

  if (
    route?.name == 'UserTasks' &&
    decryptUserId(route.params?.id) == getCurrentUserId()
  ) {
    router.replace(taskListRouteByType('USER', getCurrentUserId()));
    _taskListStore.$reset();

    _taskListStore.setDefaultInitData(TaskListTab.MyList);

    _taskListStore.setFilterPayload({
      listOwnerId: getCurrentUserId(),
    });
    await _taskListStore.fetchTasks(true, EFilterTaskDefault.DEFAULT);

    // Assigned tab
    _taskListStore.setFilterPayload({
      assignorId: getCurrentUserId(),
    });
    await _taskListStore.fetchTasks(true, EFilterTaskDefault.DEFAULT);
    emits('onCancel');
    return;
  }
  emits('onCancel');
  await router.push(taskListRouteByType('USER', getCurrentUserId()));
};

const filterDataByKey = computed(() => _startWorkingStore.filterDataByKey);
const planToTreatTasksDefault = computed(
  () => _startWorkingStore.planToTreatTasksDefault
);
const planToFinishTasksDefault = computed(
  () => _startWorkingStore.planToFinishTasksDefault
);
const planToTreatTasks = computed(() => {
  const result = _startWorkingStore.planToTreatTasks;
  const searchText = ignoreUnicode(keySearch.value);
  if (result && result.length > 0) {
    result.forEach(
      (el) => (el.urgency = getUrgencyValue(el.priority, el.important))
    );
  }
  if (!searchText || result?.length == 0) return result;
  return result.filter((o: any) => ignoreUnicode(o.name).includes(searchText));
});
const planToFinishTasks = computed(() => {
  const result = _startWorkingStore.planToFinishTasks;
  const searchText = ignoreUnicode(keySearchToFinish.value);
  if (!searchText || result?.length == 0) return result;
  return result.filter((o: any) => ignoreUnicode(o.name).includes(searchText));
});

const sortDataOrderByKey = computed(
  () => _startWorkingStore.sortDataOrderByKey
);

const handleSelectedTask = (task) => {
  _startWorkingStore.handleSelectedTask(task);
};

const handleSelectedTaskToFinish = (task) => {
  _startWorkingStore.handleSelectedTaskToFinish(task);
};

const getConfigScheduleName = (data) => {
  return _taskStore.getConfigScheduleName(data);
};
</script>

<template>
  <!--  {{ startWorkingStatus }}-->
  <SynModal
    v-if="
      (startWorkingStatus == 'SELECT_TASKS_TO_FINISH' &&
        planToFinishTasksDefault.length > 0) ||
      (startWorkingStatus == 'STARTED' && planToFinishTasks.length > 0) ||
      startWorkingStatus == 'SELECT_TASKS'
    "
    :style-body="'py-0'"
    z-index="z-40"
    disable-click-outside
    disable-esc-to-close
    container-class="md:w-2/3 md:h-4/5"
    @cancel="$emit('onCancel')"
  >
    <template #header>
      <span v-if="startWorkingStatus == 'SELECT_TASKS'">
        {{ $t('START_WORKING_LABEL_SELECT_TASKS') }}
      </span>
      <span
        v-if="
          startWorkingStatus == 'SELECT_TASKS_TO_FINISH' ||
          startWorkingStatus == 'STARTED'
        "
      >
        {{ $t('START_WORKING_LABEL_SELECT_TASKS_TO_FINISH') }}
      </span>
    </template>
    <template #body>
      <div class="flex-1 min-h-0 flex w-full overflow-y-hidden">
        <div
          class="
            h-full
            w-56
            sticky
            top-0
            border-r border-current-50
            2xl:block
            hidden
          "
          style="min-height: 100%; width: 20%"
        >
          <div
            class="
              absolute
              top-1/4
              text-xl
              px-4
              text-center text-md
              md:text-md
              font-mono
              text-current-400
            "
          >
            <span>
              {{
                startWorkingStatus == 'SELECT_TASKS'
                  ? $t('HEADER_LABEL_WELCOME_CHOOSE_TASK')
                  : $t('HEADER_LABEL_WELCOME_FINISH_TASK')
              }}
            </span>
          </div>
          <div
            class="w-full h-full bg-current-50 bg-opacity-70 flex-center"
            style="max-height: 100%"
          >
            <!-- <img
              v-if="startWorkingStatus == 'SELECT_TASKS'"
              :src="rocket"
              style="width: 200px; height: 200px;"
              class="absolute bottom-0 right-1"
            />
            <img
              v-if="startWorkingStatus == 'SELECT_TASKS_TO_FINISH'"
              :src="finishTask"
              style="width: 200px; height: 200px;"
              class="absolute bottom-0 right-1"
            /> -->
            <SynAnimation
              v-if="startWorkingStatus == 'SELECT_TASKS'"
              name="rocket"
              stype="width: 200px; height: 200px;"
              class="absolute bottom-0"
            />
            <SynAnimation
              v-if="startWorkingStatus == 'SELECT_TASKS_TO_FINISH'"
              name="startWD3"
              stype="width: 200px; height: 200px;"
              class="absolute bottom-0"
            />
          </div>
        </div>

        <div class="h-full overflow-y-auto flex-auto small-scrollbar">
          <!-- select_tasks_todo -->
          <SynTableCustom
            v-if="startWorkingStatus == 'SELECT_TASKS'"
            class="rounded"
          >
            <template #header>
              <SynTr>
                <SynTh class="w-12"></SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_URGENCY')"
                  :is-sort="planToTreatTasksDefault.length > 13"
                  :order-data="sortDataOrderByKey['urgency']"
                  @on-sort="
                    onClickSort('urgency', $t('TASK_TABLE_LABEL_URGENCY'))
                  "
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToTreatTasksDefault.length > 13"
                      type="urgency"
                      :data-filters="filterDataByKey['urgency']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn('urgency', data, 'SELECT_TASKS')
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn('urgency', 'SELECT_TASKS')
                      "
                    />
                  </template>
                </SynTh>

                <SynTh
                  :label="$t('TASK_TABLE_LABEL_TASKS')"
                  :is-sort="planToTreatTasksDefault.length > 13"
                  :order-data="sortDataOrderByKey['taskName']"
                  @on-sort="
                    onClickSort('taskName', $t('TASK_TABLE_LABEL_TASKS'))
                  "
                >
                  <template
                    v-if="
                      (planToTreatTasksDefault.length > 13 && !keySearch) ||
                      keySearch
                    "
                    #search
                  >
                    <div class="w-full flex items-center">
                      <VigSearchBox
                        v-model="keySearch"
                        class="font-normal ml-3 w-72"
                        input-class="text-sm"
                        :placeholder="
                          $t('COMMON_LABEL_SEARCH_PLACEHOLDER_TASK_NAME')
                        "
                        @click.stop="() => {}"
                      >
                        <template v-if="keySearch" #suffix>
                          <SynIcon
                            has-action
                            name="close"
                            @click="resetSearch()"
                          />
                        </template>
                      </VigSearchBox>
                    </div>
                  </template>
                </SynTh>
                <SynTh :label="$t('TASK_CREATE_FORM_DOMAIN')">
                  <template #custom>
                    <ColumnFilter
                      v-if="planToTreatTasksDefault.length > 13"
                      type="domain"
                      :data-filters="filterDataByKey['domainId']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'domainId',
                            data,
                            'SELECT_TASKS'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn('domainId', 'SELECT_TASKS')
                      "
                    />
                  </template>
                </SynTh>
                <SynTh :label="$t('COMMON_LABEL_FROM')">
                  <template #custom>
                    <ColumnFilter
                      v-if="planToTreatTasksDefault.length > 13"
                      type="user"
                      :data-filters="filterDataByKey['creatorId']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'creatorId',
                            data,
                            'SELECT_TASKS'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn('creatorId', 'SELECT_TASKS')
                      "
                    />
                  </template>
                </SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_DEADLINE')"
                  :is-sort="planToTreatTasksDefault.length <= 13 ? false : true"
                  :order-data="sortDataOrderByKey['scheduleTime']"
                  @on-sort="
                    onClickSort('scheduleTime', $t('TASK_TABLE_LABEL_DEADLINE'))
                  "
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToTreatTasksDefault.length > 13"
                      type="date"
                      :data-filters="filterDataByKey['scheduleTime']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'scheduleTime',
                            data,
                            'SELECT_TASKS'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn('scheduleTime', 'SELECT_TASKS')
                      "
                    />
                  </template>
                </SynTh>
                <SynTh class="w-12"></SynTh>
              </SynTr>
            </template>

            <template #body>
              <SynTr
                v-if="isLoadingFetchTotoTask"
                class="w-full h-full border-b-none"
              >
                <SynTd
                  v-for="item in 4"
                  :key="item"
                  class="p-2 py-4 justify-center"
                >
                  <div
                    class="p-2 w-full bg-gray-200 rounded-md animate-pulse"
                  ></div>
                </SynTd>
              </SynTr>
              <template v-else-if="planToTreatTasks.length > 0">
                <SynTr
                  v-for="(task, index) in planToTreatTasks"
                  :key="task.id"
                  class="
                    border-b border-gray-200
                    hover:bg-gray-50
                    overflow-y-auto
                    small-scrollbar
                  "
                  @click="handleSelectedTask(task)"
                >
                  <SynTd class="text-center">
                    <SynIcon
                      class="cursor-pointer fill-current text-current"
                      name="checkbox"
                      :is-active="task?.isPlannedToTreat || false"
                      @click.stop="handleSelectedTask(task)"
                    />
                  </SynTd>

                  <SynTd class="align-left relative">
                    <PriorityTask :urgency="task?.urgency" is-hidden-label />
                    <div
                      v-if="task.isPrivate"
                      class="absolute flex-center"
                      style="bottom: 0.3rem; right: 42px"
                    >
                      <SynIcon
                        name="Private"
                        custom-class="w-2.5 h-2.5 fill-current"
                      />
                    </div>
                  </SynTd>
                  <SynTd class="max-w-md">
                    <div class="flex space-y-1 break-words w-full flex-wrap">
                      <div
                        v-vig-tooltip="task.name"
                        class="
                          cursor-pointer
                          text-overflow-hidden
                          hover:text-current-500
                          overflow-hidden
                          w-full
                          text-xs
                          2xl:text-sm
                        "
                      >
                        <span class="font-semibold text-current-800">
                          {{
                            `${
                              task.subprojectName
                                ? task.subprojectName + ' - '
                                : ''
                            }`
                          }}
                        </span>
                        <span>
                          {{ task.name }}
                        </span>
                      </div>
                      <GroupById
                        v-if="task?.groupId"
                        :group-id="task.groupId"
                        class="px-2 text-2xs w-full justify-end"
                        avatar-class="w-4 h-4"
                      />
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="flex">
                      <DomainById
                        :domain-id="task?.domainId"
                        class="px-2 text-xs 2xl:text-sm"
                      />
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="flex">
                      <UserById
                        :user-id="task?.creatorId"
                        class="px-2 text-xs"
                        avatar-class="w-6 h-6"
                        is-hidden-name
                      />
                    </div>
                  </SynTd>

                  <SynTd>
                    <SynTag
                      class="flex justify-start w-max"
                      size="small"
                      :class="getConfigScheduleColor(task?.taskSchedule)"
                    >
                      {{
                        task.assigneeId && getConfigScheduleName(task)
                          ? getConfigScheduleName(task)
                          : $filters.dayjs(
                              task.scheduleTime,
                              'MMM DD, YYYY',
                              myTimezone || 0
                            ) ||
                            $t('TASK_DETAIL_LABEL_NO_SCHEDULE') ||
                            'No schedule'
                      }}
                    </SynTag>
                  </SynTd>
                  <SynTd class="p-2 text-center px-4" @click.stop>
                    <ThreeDotActions type="action">
                      <div
                        class="
                          space-x-2
                          flex
                          justify-start
                          items-center
                          text-sm
                          list-li
                        "
                        @click="handleFinishTask(task, index)"
                      >
                        <SynIcon
                          is-active
                          custom-class="fill-gray-500 w-4 h-4"
                          name="check-circle"
                        />
                        <span class="text-gray-600">
                          {{ $t('COMMON_LABEL_FINISH') }}
                        </span>
                      </div>
                    </ThreeDotActions>
                  </SynTd>
                </SynTr>
              </template>
              <template v-else>
                <SynTr class="h-full border-none">
                  <SynTd :colspan="7" class="">
                    <SynAnimation
                      :name="
                        typeStatus == 'LIST' ? 'searchNotFound' : 'searchIcon'
                      "
                      stype="width: 100px; height: 100px"
                      :label="
                        typeStatus == 'LIST'
                          ? $t('HEADER_LABEL_WELCOME_NOT_FOUND_TASK')
                          : $t('COMMON_LABEL_ALERT_NO_SEARCH')
                      "
                    />
                  </SynTd>
                </SynTr>
                <SynTr
                  v-if="typeStatus == 'LIST' && planToTreatTasks.length < 1"
                  class="h-full border-none w-full"
                >
                  <SynTd :colspan="7">
                    <div class="w-full flex-center">
                      <SynButton
                        name-icon="plus"
                        type-outline
                        :label="$t('COMMON_LABEL_CREATE_TASK')"
                        @click="onCreateTaskGlobal"
                      >
                      </SynButton>
                    </div>
                  </SynTd>
                </SynTr>
              </template>
            </template>
          </SynTableCustom>

          <!-- select_task_finish -->
          <SynTableCustom
            v-if="
              (startWorkingStatus == 'SELECT_TASKS_TO_FINISH' &&
                planToFinishTasksDefault.length > 0) ||
              (startWorkingStatus == 'STARTED' &&
                planToFinishTasksDefault.length > 0)
            "
            class="rounded"
          >
            <template #header>
              <SynTr class="h-12">
                <SynTh class="w-12"></SynTh>
                <SynTh
                  class="w-12"
                  :label="$t('TASK_TABLE_LABEL_URGENCY')"
                  :is-sort="
                    planToFinishTasksDefault.length <= 13 ? false : true
                  "
                  :order-data="sortDataOrderByKey['urgency']"
                  @on-sort="
                    onClickSort('urgency', $t('TASK_TABLE_LABEL_URGENCY'))
                  "
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToFinishTasksDefault.length > 13"
                      type="urgency"
                      :data-filters="filterDataByKey['urgency']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'urgency',
                            data,
                            'SELECT_TASKS_TO_FINISH'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn(
                          'urgency',
                          'SELECT_TASKS_TO_FINISH'
                        )
                      "
                    />
                  </template>
                </SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_TASKS')"
                  :is-sort="planToFinishTasksDefault.length > 13"
                  :order-data="sortDataOrderByKey['taskName']"
                  @on-sort="
                    onClickSort('taskName', $t('TASK_TABLE_LABEL_TASKS'))
                  "
                >
                  <template
                    v-if="
                      (planToFinishTasksDefault.length > 13 && !keySearch) ||
                      keySearch
                    "
                    #search
                  >
                    <div class="w-full flex items-center">
                      <VigSearchBox
                        v-model="keySearchToFinish"
                        class="font-normal ml-3 w-72"
                        input-class="text-sm"
                        :placeholder="
                          $t('COMMON_LABEL_SEARCH_PLACEHOLDER_TASK_NAME')
                        "
                        @click.stop="() => {}"
                      >
                        <template v-if="keySearchToFinish" #suffix>
                          <SynIcon
                            has-action
                            name="close"
                            @click="resetSearch()"
                          />
                        </template>
                      </VigSearchBox>
                    </div>
                  </template>
                </SynTh>
                <SynTh :label="$t('TASK_CREATE_FORM_DOMAIN')">
                  <template #custom>
                    <ColumnFilter
                      v-if="planToFinishTasksDefault.length > 13"
                      type="domain"
                      :data-filters="filterDataByKey['domainId']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'domainId',
                            data,
                            'SELECT_TASKS_TO_FINISH'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn(
                          'domainId',
                          'SELECT_TASKS_TO_FINISH'
                        )
                      "
                    />
                  </template>
                </SynTh>
                <SynTh :label="$t('COMMON_LABEL_FROM')">
                  <template #custom>
                    <ColumnFilter
                      v-if="planToFinishTasksDefault.length > 13"
                      type="user"
                      :data-filters="filterDataByKey['creatorId']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'creatorId',
                            data,
                            'SELECT_TASKS_TO_FINISH'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn(
                          'creatorId',
                          'SELECT_TASKS_TO_FINISH'
                        )
                      "
                    />
                  </template>
                </SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_DEADLINE')"
                  :is-sort="planToFinishTasksDefault.length > 13"
                  :order-data="sortDataOrderByKey['scheduleTime']"
                  @on-sort="
                    onClickSort('scheduleTime', $t('TASK_TABLE_LABEL_DEADLINE'))
                  "
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToFinishTasksDefault.length > 13"
                      type="date"
                      :data-filters="filterDataByKey['scheduleTime']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'scheduleTime',
                            data,
                            'SELECT_TASKS_TO_FINISH'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn(
                          'scheduleTime',
                          'SELECT_TASKS_TO_FINISH'
                        )
                      "
                    />
                  </template>
                </SynTh>
              </SynTr>
            </template>
            <template #body>
              <template v-if="planToFinishTasksDefault.length > 0">
                <SynTr
                  v-for="task in planToFinishTasks"
                  :key="task.id"
                  class="border-b border-gray-200 hover:bg-gray-50"
                  @click="handleSelectedTaskToFinish(task)"
                >
                  <SynTd class="text-center">
                    <SynIcon
                      class="cursor-pointer fill-current text-current"
                      name="checkbox"
                      :is-active="task?.isSelected || false"
                      @click.stop="handleSelectedTaskToFinish(task)"
                    />
                  </SynTd>
                  <SynTd class="text-center">
                    <PriorityTask :urgency="task?.urgency" is-hidden-label />
                  </SynTd>
                  <SynTd class="max-w-md">
                    <div class="flex space-y-1 break-words w-full flex-wrap">
                      <div
                        v-vig-tooltip="task?.name"
                        class="
                          cursor-pointer
                          text-overflow-hidden
                          hover:text-current-500
                          overflow-hidden
                          w-full
                          text-xs
                          2xl:text-sm
                        "
                      >
                        <span class="font-semibold text-current-800">
                          {{
                            `${
                              task?.subprojectName
                                ? task?.subprojectName + ' - '
                                : ''
                            }`
                          }}
                        </span>
                        <span class="text-overflow-hidden">
                          {{ task?.name }}
                        </span>
                      </div>

                      <GroupById
                        v-if="task?.groupId"
                        :group-id="task?.groupId"
                        class="px-2 text-2xs w-full justify-end"
                        avatar-class="w-4 h-4"
                      />
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="flex">
                      <DomainById
                        :domain-id="task?.domainId"
                        class="px-2 text-xs 2xl:text-sm"
                      />
                    </div>
                  </SynTd>
                  <SynTd>
                    <div class="flex">
                      <UserById
                        :user-id="task?.creatorId"
                        class="px-2 text-xs"
                        avatar-class="w-6 h-6"
                        is-hidden-name
                      />
                    </div>
                  </SynTd>
                  <SynTd>
                    <SynTag
                      class="flex justify-start w-max"
                      size="small"
                      :class="getConfigScheduleColor(task?.taskSchedule)"
                    >
                      {{
                        task.assigneeId && getConfigScheduleName(task)
                          ? getConfigScheduleName(task)
                          : $filters.dayjs(task.scheduleTime) ||
                            $t('TASK_DETAIL_LABEL_NO_SCHEDULE') ||
                            'No schedule'
                      }}
                    </SynTag>
                  </SynTd>
                </SynTr>
              </template>
              <template
                v-if="
                  (planToFinishTasks?.length == 0 && keySearchToFinish) ||
                  (planToFinishTasks?.length == 0 && filterDataByKey)
                "
              >
                <SynTr
                  class="h-full border-none hover:bg-current-50"
                  hover="hover:bg-current-50"
                >
                  <SynTd :colspan="5" class="">
                    <SynAnimation
                      :name="'searchIcon'"
                      stype="width: 100px; height: 100px"
                      :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                    />
                  </SynTd>
                </SynTr>
              </template>
            </template>
          </SynTableCustom>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="p-4 flex w-full items-center justify-between">
        <div class="justify-start">
          <SynButton
            v-if="planToTreatTasks.length"
            class="w-max"
            name-icon="plus"
            type-outline
            :label="$t('COMMON_LABEL_CREATE_TASK')"
            @click="onCreateTaskGlobal"
          >
          </SynButton>
        </div>

        <div class="flex items-center justify-end space-x-2">
          <SynButton
            type-outline
            :label="
              checkinStatusList[startWorkingStatus]?.previousText
                ? $t(checkinStatusList[startWorkingStatus]?.previousText)
                : ''
            "
            @click="onHandleGoToPreviousStep"
          />

          <SynButton
            v-if="checkinStatusList[startWorkingStatus]?.confirmText"
            class="w-max"
            :disabled="isDisableButtonSubmit"
            :is-loading="isLoading"
            :label="$t(checkinStatusList[startWorkingStatus]?.confirmText)"
            @click="onHandleGoToNextStep"
          />
        </div>
      </div>
    </template>
  </SynModal>

  <SynModal
    v-if="
      startWorkingStatus == 'SELECT_TASKS_TO_FINISH' &&
      planToFinishTasksDefault.length < 1
    "
    container-class="h-max w-max"
    is-hidden-header
    z-index="z-100"
    :style-body="'py-0 flex relative'"
    @cancel="$emit('onCancel')"
  >
    <template #body>
      <div class="px-4 py-8">
        <SynAnimation name="startWD3" stype="width: 250px; height: 250px;" />
        <span class="text-xl px-4 text-current-700">
          {{ $t('START_WORKING_TITLE_FINISH_CHOOSE_TASK') }}
        </span>
      </div>
    </template>
    <template #footer>
      <div class="p-4 flex justify-end pt-2 items-center space-x-2">
        <SynButton
          type-text
          color="gray"
          :label="$t('COMMON_LABEL_BACK')"
          @click="onHandleGoToPreviousStep"
        />
        <SynButton
          :label="$t('LABEL_FINISH_CHOOSE_TASK')"
          @click="onHandleGoToNextStep"
        />
      </div>
    </template>
  </SynModal>
</template>
