<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import DoughnutChart from '@/ui/common/plugins/chart/DoughnutChart.vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
  };
  size?: string;
}>();

const datasetByTaskStatus = computed(() => {
  return [
    {
      key: 'completedTasks',
      name: translate('REPORT_CARD_LABEL_WORKLOAD_BY_STATUS_COMPLETED'),
      data: resultData.value?.completedTasks || 0,
      color: '#4bb0b2',
      title: 'Finished Tasks',
      titleCode: 'REPORT_CARD_LABEL_WORKLOAD_BY_STATUS_COMPLETED',
    },
    {
      key: 'finishOnOverdueTasks',
      name: translate('REPORT_CARD_LABEL_WORKLOAD_BY_STATUS_COMPLETED_OVERDUE'),
      data: resultData.value?.finishOnOverdueTasks || 0,
      color: '#FDC89D',
      title: 'Overdue Finished Tasks',
      titleCode: 'REPORT_CARD_LABEL_WORKLOAD_BY_STATUS_COMPLETED_OVERDUE',
    },
    {
      key: 'overdueTasks',
      name: translate('REPORT_CARD_LABEL_WORKLOAD_BY_STATUS_OVERDUE'),
      data: resultData.value?.overdueTasks || 0,
      color: '#fca5a5',
      title: 'Overdue Tasks',
      titleCode: 'REPORT_CARD_LABEL_WORKLOAD_BY_STATUS_OVERDUE',
    },
    {
      key: 'unscheduledTasks',
      name: translate('REPORT_CARD_LABEL_WORKLOAD_BY_STATUS_UNSCHEDULED'),
      data: resultData.value?.unscheduledTasks || 0,
      color: '#9ca3af',
      title: 'Unschedule Tasks',
      titleCode: 'REPORT_CARD_LABEL_WORKLOAD_BY_STATUS_UNSCHEDULED',
    },
  ];
});

const hasData = computed<boolean>(() => {
  return (
    resultData.value?.unscheduledTasks > 0 ||
    resultData.value?.overdueTasks > 0 ||
    resultData.value?.finishOnOverdueTasks > 0 ||
    resultData.value?.completedTasks > 0
  );
});
const isLoading = ref<boolean>(true);
const resultData = ref<any>({
  completedTasks: 16,
  finishOnOverdueTasks: 6,
  overdueTasks: 136,
  totalTasks: 181,
  unscheduledTasks: 44,
});

const initComponent = async () => {
  isLoading.value = true;
  const res = await WorkPerformanceRepository.getWorkloadStatistic(
    props.filterData
  );
  resultData.value = res?.result;
  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  () => {
    initComponent();
  }
);
const options = {
  plugins: {
    legend: { display: false },
    tooltip: {
      // displayColors: false,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      titleColor: '#6b7280',
      bodyColor: '#6b7280',
      borderWidth: 1,
      borderColor: '#e5e7eb',
      callbacks: {
        label: (tooltipItems) => {
          console.log('🚀 Tictop ~ tooltipItems:', tooltipItems);
          const currentLabel =
            tooltipItems.dataset.labels?.length > 0 &&
            tooltipItems.dataIndex >= 0
              ? tooltipItems.dataset.labels[
                  tooltipItems.dataIndex
                ].toLowerCase()
              : translate('LABEL_TOTAL_TASK').toLowerCase();
          return tooltipItems?.formattedValue + ' ' + currentLabel;
        },
      },
    },
  },
};
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <div v-else class="flex-1 w-full h-full flex-center gap-12">
    <div class="flex-center">
      <DoughnutChart
        id-chart="performance_by-status"
        :prop-data="datasetByTaskStatus"
        :label-no-data="$t('COMMOM_LABEL_NOT_DATA_YET')"
        :is-show-description="false"
        is-show-progress
        :progress="25"
        cut-out="65%"
        :custom-class="size == 'lg' ? 'w-60 h-60' : 'w-56 h-56'"
        :options="options"
      >
        <template #progress>
          <div class="z-0 absolute flex-center flex-col m-auto">
            <span class="font-medium text-2xl">
              {{ $filters.numberToString(resultData?.totalTasks || 0) }}
            </span>
            <span class="text-gray-600 lowercase">
              {{ $t('TASK_TABLE_LABEL_TASKS') }}
            </span>
          </div>
        </template>
      </DoughnutChart>
    </div>
    <div v-if="hasData" class="flex-center flex-col space-y-1">
      <div
        v-for="(data, index) in datasetByTaskStatus"
        :key="index"
        class="w-full flex items-center justify-between"
      >
        <div class="flex-center space-x-2">
          <span
            class="w-3 h-3 rounded-full"
            :style="{
              backgroundColor: data?.color,
            }"
          ></span>
          <span class="text-sm">
            {{ $t(data?.titleCode) || data?.title }}
          </span>
        </div>
        <!-- <span class="text-sm">
          {{ $filters.numberToString(data?.data || 0) }}
        </span> -->
      </div>
    </div>
  </div>
</template>
