<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import UserRepository from '@/application/repositories/UserRepository';
import FormInfo from '@/ui/components/user/FormInfo.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import userStore from '@/store/user';
import { validateFormatDatetime } from '@/ui/hooks/commonFunction';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import {
  IUserDetail,
  adminTypeByKey,
} from '@/application/types/user/user.types';
import { getObjectDiff } from '@/ui/hooks/commonFunction';
import plansStore from '@/store/plans';
import { AdminType } from '@/ui/common/constants/constant';
import ModalUserStartWorkingConditions from '@/ui/pages/usermanagement/modal-start-working-conditions/ModalUserStartWorkingConditions.vue';

const props = defineProps<{
  userId: number;
  isEditable?: boolean;
}>();

const emit = defineEmits<{
  (e: 'updateUserSuccess', data: any): void;
}>();

enum EViewMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

const _planStore = plansStore();
const allUserDetailByIds = computed<any>(() => {
  return _userStore.allUserDetailByIds;
});

const loadingData = reactive<{
  init: boolean;
}>({ init: true });

const initComponent = async () => {
  if (
    allUserDetailByIds.value &&
    Object.prototype.hasOwnProperty.call(allUserDetailByIds.value, props.userId)
  ) {
    const userDetail = allUserDetailByIds.value[props.userId];

    currentUser.value = userDetail.infos;
  } else {
    loadingData.init = true;
    const result = await _userStore.getDetailUser(props.userId);

    currentUser.value = result;

    _userStore.allUserDetailByIds = {
      ..._userStore.allUserDetailByIds,
      [props.userId]: {
        ..._userStore.allUserDetailByIds[props.userId],
        infos: result,
      },
    };
  }
  currentUser.value = {
    ...currentUser.value,
    login: currentUser.value?.phone,
  };

  formData.data = {
    ...currentUser.value,
  };
  loadingData.init = false;
};

const _userStore = userStore();
const currentUser = ref<IUserDetail>();
const viewMode = ref<EViewMode>(EViewMode.VIEW);
const isMe = computed<boolean>(() => {
  return currentUser.value?.id == getCurrentUserId();
});

const onChangeViewMode = (newMode: EViewMode) => {
  viewMode.value = newMode;

  if (newMode == EViewMode.VIEW) {
    formData.data = {
      ...currentUser.value,
    };
  }
};

const isLoadingInfo = ref<boolean>(false);
const isSWConditionsOpen = ref<boolean>(false);

const filePayload = ref<any>();

const onSubmitAvatar = async () => {
  if (!filePayload.value) return;
  const res = await UserRepository.getInstance().uploadAvatar(
    filePayload.value
  );
  formData.data.avatar = res?.result?.path || '';
  formData.isChanged = true;

  saveInfoUser();
};

const messageError = ref('');
const isShowPopupWarning = ref(false);

const changeTotalPremiumMembers = computed<{
  owner: number;
  admin: number;
  manager: number;
  user: number;
  visitor: number;
}>(() => {
  const oldAdminType = currentUser.value?.adminType;
  const newAdminType = formData.data?.adminType;
  const isActivate = !currentUser.value?.active && formData.data?.active;
  const isDeactivate = currentUser.value?.active && !formData.data?.active;
  const isChangeAdminType = oldAdminType !== newAdminType;

  // Deactivate user
  if (isDeactivate) {
    return {
      owner: oldAdminType == AdminType.Owner ? -1 : 0,
      admin: oldAdminType == AdminType.Admin ? -1 : 0,
      manager: oldAdminType == AdminType.Manager ? -1 : 0,
      user: oldAdminType == AdminType.User ? -1 : 0,
      visitor: oldAdminType == AdminType.Visitor ? -1 : 0,
    };
  }
  // Activate user
  if (isActivate) {
    return {
      owner: newAdminType == AdminType.Owner ? 1 : 0,
      admin: newAdminType == AdminType.Admin ? 1 : 0,
      manager: newAdminType == AdminType.Manager ? 1 : 0,
      user: newAdminType == AdminType.User ? 1 : 0,
      visitor: newAdminType == AdminType.Visitor ? 1 : 0,
    };
  }

  if (isChangeAdminType) {
    return {
      owner:
        newAdminType == AdminType.Owner
          ? 1
          : oldAdminType == AdminType.Owner
          ? -1
          : 0,
      admin:
        newAdminType == AdminType.Admin
          ? 1
          : oldAdminType == AdminType.Admin
          ? -1
          : 0,
      manager:
        newAdminType == AdminType.Manager
          ? 1
          : oldAdminType == AdminType.Manager
          ? -1
          : 0,
      user:
        newAdminType == AdminType.User
          ? 1
          : oldAdminType == AdminType.User
          ? -1
          : 0,
      visitor:
        newAdminType == AdminType.Visitor
          ? 1
          : oldAdminType == AdminType.Visitor
          ? -1
          : 0,
    };
  }

  // not change
  return {
    owner: 0,
    admin: 0,
    manager: 0,
    user: 0,
    visitor: 0,
  };
});

const saveInfoUser = async () => {
  if (!formData.data?.id || !formData.isChanged) return;

  try {
    isLoadingInfo.value = true;
    console.log(formData.data);
    if (
      formData.data.birthday &&
      !validateFormatDatetime(formData.data.birthday)
    ) {
      isShowPopupWarning.value = true;
      messageError.value = translate('WARNING_ALERT_INVALID_FORMAT_DATETIME');
      isLoadingInfo.value = false;
    } else {
      if (
        (formData.data.birthday && formData.data.birthday.indexOf('.')) ||
        (formData.data.birthday && formData.data.birthday.indexOf('-'))
      ) {
        formData.data.birthday = formData.data.birthday.replaceAll('.', '/');
        formData.data.birthday = formData.data.birthday.replaceAll('-', '/');
      }
      if (formData.data?.active) {
        const isValidPlan =
          await _planStore.checkCurrentPaymentStageForUpdateUser(
            changeTotalPremiumMembers.value
          );

        if (!isValidPlan) return;
      }

      const res = await _userStore.saveProfile({
        id: formData.data?.id,
        firstName: formData.data?.firstName,
        lastName: formData.data?.lastName,
        login: formData.data?.login,
        avatar: formData.data?.avatar,
        active: formData.data?.active,
        timeOt: formData.data?.timeOt,
        birthday: formData.data?.birthday,
        address: formData.data?.address,
        description: formData.data?.description,
        holiday: formData.data?.holiday,
        email: formData.data?.email,
        identityCard: formData.data?.identityCard,
        skyper: formData.data?.skyper,
        phone: formData.data?.phone,
        utc: formData.data?.utc,
        adminType: formData.data?.adminType,
        positionId: formData.data?.positionId,
        password: formData.data?.password,
        notificationInfos: formData.data?.notificationInfos,
      });

      const newUserData = {
        ...formData.data,
        ...res?.result,
      };

      _userStore.allUserDetailByIds[props.userId].infos = {
        ...newUserData,
      };
      currentUser.value = {
        ...newUserData,
      };

      formData.isChanged = false;

      emit('updateUserSuccess', currentUser.value);
    }
    onChangeViewMode(EViewMode.VIEW);
  } catch (error) {
    onChangeViewMode(EViewMode.VIEW);
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isLoadingInfo.value = false;
  }
};

const formData = reactive<{
  data: any;
  isChanged: boolean;
}>({
  data: {},
  isChanged: false,
});

const changeUserInfo = ({ info }) => {
  const differences = getObjectDiff(currentUser.value, info);
  formData.isChanged = !!differences?.length;
  formData.data = info;
};

const isDisableSaveButton = computed<boolean>(() => {
  return isLoadingInfo.value || !formData.isChanged;
});
initComponent();
</script>
<template>
  <div
    class="flex flex-col h-full rounded-md border border-gray-100"
    style="max-width: 48rem"
  >
    <div class="flex px-4 pt-2 rounded-t-md items-center justify-between">
      <p class="font-semibold text-current-500">
        {{ $t('USERMANAGEMENT_LABEL_INFOMATION') }}
      </p>
      <div v-if="isEditable" class="flex items-center rounded-md">
        <div v-if="viewMode == 'EDIT'" class="text-xs flex space-x-2">
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            :label="$t('COMMON_LABEL_CANCEL')"
            :disabled="isLoadingInfo"
            @click="onChangeViewMode(EViewMode.VIEW)"
          />
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
            :label="$t('COMMON_LABEL_SAVE')"
            :is-loading="isLoadingInfo"
            :disabled="isDisableSaveButton"
            @click="saveInfoUser"
          />
        </div>
        <div
          v-else
          v-vig-tooltip="$t('COMMON_LABEL_EDIT')"
          class="
            hover:bg-current-100
            p-2
            rounded-full
            flex
            items-center
            cursor-pointer
            justify-center
          "
          @click="onChangeViewMode(EViewMode.EDIT)"
        >
          <SynIcon name="edit" class="fill-current" is-active />
        </div>
      </div>
    </div>
    <!-- HEADER -->
    <div
      v-if="viewMode == EViewMode.VIEW"
      class="bg-white text-sm flex items-center h-44 rounded-md"
    >
      <!-- avatar -->
      <div class="p-4">
        <div v-if="loadingData.init" class="flex-center space-x-3">
          <div class="h-32 w-32 bg-gray-200 animate-pulse rounded-full"></div>
        </div>

        <SynUploadAvatar
          v-else
          v-model="filePayload"
          :avatar-thumbnail-url="currentUser?.avatarUrl"
          :name="currentUser?.lastName + ' ' + currentUser?.firstName"
          :is-upload-direct="!!filePayload"
          :is-loading="isLoadingInfo"
          :is-edit="!currentUser?.avatarUrl || isMe"
          @on-save="onSubmitAvatar"
        />
      </div>

      <!-- info -->
      <div>
        <div v-if="loadingData.init" class="flex-center space-x-3">
          <div class="p-2 w-16 bg-gray-200 animate-pulse rounded-full"></div>
          <div class="p-2 w-full bg-gray-200 animate-pulse rounded-full"></div>
        </div>
        <div v-else class="flex flex-col gap-2">
          <div class="flex flex-col">
            <span class="text-xl text-gray-800 font-semibold">
              {{
                currentUser?.fullName ||
                `${currentUser?.firstName} ${currentUser?.lastName}`
              }}
            </span>
            <span class="text-sm italic text-gray-600 font-semibold">
              {{
                $t(
                  currentUser
                    ? adminTypeByKey[currentUser?.adminType]?.textCode
                    : 'USERMANAGEMENT_FORM_TYPE_USER'
                )
              }}
            </span>
          </div>
          <div class="flex items-center gap-2">
            <SynIcon name="phone2" custom-class="w-4 h-4 fill-gray-500" />
            <span class="text-gray-600">
              {{ currentUser?.phone }}
            </span>
          </div>
          <div class="flex items-center gap-2">
            <SynIcon
              name="email"
              is-active
              custom-class="w-4 h-4 fill-gray-500"
            />
            <span class="text-gray-600">
              {{ currentUser?.email }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- OTHER INFORMATION -->
    <div class="flex-1 min-h-0 h-full p-2 bg-white text-sm flex flex-col">
      <!-- info -->
      <div class="px-2 pb-4 flex-1 min-h-0 overflow-y-auto small-scrollbar">
        <div v-if="loadingData.init" class="flex-center space-x-3">
          <div class="p-2 w-16 bg-gray-200 animate-pulse rounded-full"></div>
          <div class="p-2 w-full bg-gray-200 animate-pulse rounded-full"></div>
        </div>
        <template v-else>
          <FormInfo
            v-if="viewMode == EViewMode.VIEW"
            :info="currentUser"
            :key-not-show="[
              'firstName',
              'lastName',
              'email',
              'phone',
              'adminType',
              'login',
              'positionId',
              'skyper',
            ]"
            :key-disable="['login', 'phone', 'email']"
            @update:info="changeUserInfo"
          />
          <FormInfo
            v-else
            :info="currentUser"
            is-edit
            :key-not-show="['login', 'positionId', 'skyper']"
            :key-disable="['login', 'phone', 'email']"
            @update:info="changeUserInfo"
          />

          <div v-if="viewMode == EViewMode.VIEW" class="mt-8 text-base">
            <div class="mb-1 font-medium text-gray-500">
              {{ $t('COMMON_LABEL_SETTINGS') }}
            </div>
            <div
              role="button"
              class="inline-block py-1 text-current-500 hover:underline"
              @click="isSWConditionsOpen = true"
            >
              {{ $t('SETTING_SW_CONDITION_SET') }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <AlertModal
    v-if="isShowPopupWarning"
    :title-text="$t('COMMON_LABEL_BE_WARNED')"
    :sub-title-text="messageError"
    container-class="w-1/4"
    name-icon="warning"
    :container-color="'orange'"
    @cancel="isShowPopupWarning = false"
  />

  <ModalUserStartWorkingConditions
    v-if="isSWConditionsOpen"
    :user="currentUser"
    @on-close="isSWConditionsOpen = false"
  />
</template>
