<script setup lang="ts">
import { onMounted, ref, computed, onUnmounted } from 'vue';
import UserTable from './UserTable.vue';
import UserDetailPage from './UserDetailPage.vue';
// import CreateNewUser from './createUser/CreateNewUser.vue';
import InviteUserModal from '@/ui/modules/member/invite-user/InviteUserModal.vue';
import PendingUsers from '@/ui/pages/usermanagement/PendingUsers.vue';
import organizationStore from '@/store/organization';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import userStore from '@/store/user';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import { IUserDetail } from '@/application/types/user/user.types';

const _organizationStore = organizationStore();
const _userStore = userStore();
const pendingUsersUpToDate = computed(() => _organizationStore.pendingUsers);

const initComponent = () => {
  userStore().getUserShortInfosIncludeDepartment();
};

const allUserByIds = computed<any>(() => {
  return _userStore.allUserByIds;
});

const userDetail = ref<IUserDetail>();

const isInvite = ref(false);

const onInviteUser = () => {
  isInvite.value = true;
};
const onCancelInvite = () => {
  isInvite.value = false;
};

const pendingUsersRef = ref<any>();
const onInviteSuccess = async () => {
  isInvite.value = false;

  if (
    pendingUsersRef.value &&
    typeof pendingUsersRef.value?.init == 'function'
  ) {
    pendingUsersRef.value?.init();
  } else _organizationStore.getPendingUsers();
};
const attachmentCode = computed(() => _organizationStore.attachmentCode);

onMounted(() => {
  init();
});

const init = async () => {
  const promise = [
    _organizationStore.getPendingUsers(),
    _organizationStore.getOrganizationAttachmentCode(),
  ];
  await Promise.all(promise);
};

onUnmounted(() => {
  userStore().resetFilterUser();
  userStore().clearUserDataCache();
});

const onGenerateAttachmentCode = async () => {
  settingGlobalModal({
    type: 'confirm',
    title:
      translate('COMMON_CONFIRM_BEFORE_GENERATE_ATTACHMENT_CODE') ||
      'Are you sure you want to generate a new code?',
    content:
      translate('COMMON_CONFIRM_BEFORE_GENERATE_ATTACHMENT_CODE_BODY') ||
      'Once the new code is generated, the current code will not be able use to attach your organization.',
    confirmable: true,
    closeable: true,
  });
  const confirmed = await ask();
  if (confirmed) {
    _organizationStore.generateOrganizationAttachmentCode();
  }
};

const onCopyAttachmentCode = () => {
  const copied = copyToClipBroard(attachmentCode.value);
  if (copied) {
    openNotification({
      body: translate('COMMON_LABEL_COPIED'),
      duration: 2000,
    });
  }
};

const userTabs = ref([] as any[]);

enum EViewMode {
  LIST = 'LIST',
  DETAIL = 'DETAIL',
  WAITING = 'WAITING',
}
const viewMode = ref<EViewMode>(EViewMode.LIST);
const currentUserId = ref();

const onChooseUser = async (user) => {
  const userId = user?.id;
  userDetail.value = user;
  const index = userTabs.value.findIndex((o) => o.id == userId);
  if (index == -1) {
    if (userTabs.value?.length == 4) userTabs.value.shift();
    userTabs.value = [...userTabs.value, user];
  }
  viewMode.value = EViewMode.DETAIL;
  currentUserId.value = userId;
};

const changeTab = (user) => {
  viewMode.value = EViewMode.DETAIL;
  currentUserId.value = user.id;
  userDetail.value = user;
};
const closeTab = (user) => {
  if (user.id == currentUserId.value) {
    currentUserId.value = null;
    viewMode.value = EViewMode.LIST;
  }
  userTabs.value = userTabs.value.filter((o: any) => o?.id !== user?.id);
};

const openTabByKey = (key: EViewMode) => {
  viewMode.value = key;
};

const userTableRef = ref<any>();
const updateUserSuccess = (updatedUser) => {
  if (userTableRef.value && typeof userTableRef.value?.updateUser == 'function')
    userTableRef.value?.updateUser(updatedUser);
};

initComponent();
</script>
<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.ADMIN.MEMBER,
      isPage: true,
    }"
    class="h-full w-full pt-1 flex flex-col"
  >
    <div class="flex justify-between items-end">
      <ul class="flex cursor-pointer text-sm space-x-0.5">
        <li
          class="py-2 px-4 font-semibold rounded-t-lg items-center inline-flex"
          :class="
            viewMode == EViewMode.LIST
              ? 'bg-white text-current-500 border-t border-r border-current-100 z-10'
              : 'text-gray-500 bg-gray-100 '
          "
          :style="
            viewMode === EViewMode.LIST
              ? 'margin-bottom: -1px'
              : 'margin-bottom: 0px'
          "
          @click="openTabByKey(EViewMode.LIST)"
        >
          <p class="truncate">
            {{ $t('USERMANAGEMENT_LABEL_USERS') }}
          </p>
        </li>
        <li
          v-if="pendingUsersUpToDate?.length > 0"
          class="py-2 px-4 font-semibold rounded-t-lg items-center inline-flex"
          :class="
            viewMode == EViewMode.WAITING
              ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
              : 'text-gray-500 bg-gray-100 '
          "
          :style="
            viewMode == EViewMode.WAITING
              ? 'margin-bottom: -1px'
              : 'margin-bottom: 0px'
          "
          @click="openTabByKey(EViewMode.WAITING)"
        >
          <p class="truncate">
            {{
              $t('USERMANAGEMENT_PENDING_TAB_LABEL') +
              ' ' +
              '(' +
              pendingUsersUpToDate?.length +
              ')'
            }}
          </p>
        </li>
        <li
          v-for="user in userTabs"
          :key="user"
          class="py-2 px-2 rounded-t-lg"
          :class="
            viewMode == EViewMode.DETAIL && currentUserId == user?.id
              ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
              : 'text-gray-500 bg-gray-100 '
          "
          :style="
            viewMode == EViewMode.DETAIL && currentUserId == user?.id
              ? 'margin-bottom: -1px'
              : 'margin-bottom: 0px'
          "
          @click="changeTab(user)"
        >
          <div class="flex-center space-x-2">
            <SynAvatar
              class="cursor-pointer text-current-800"
              :src="allUserByIds[user?.id]?.avatar?.replace('original', 'mini')"
              :name="
                allUserByIds[user?.id]?.lastName +
                ' ' +
                allUserByIds[user?.id]?.firstName
              "
              has-name
              custom-class="w-6 h-6"
            />
            <span
              class="
                w-6
                h-6
                rounded-full
                hover:bg-gray-50
                flex-center
                hover:fill-gray-500
              "
            >
              <SynIcon
                class="hover:text-current"
                name="close"
                @click.stop="closeTab(user)"
              />
            </span>
          </div>
        </li>
      </ul>
      <!-- syn button -->
      <div class="text-sm flex-center space-x-2 w-max mb-1.5 pr-2">
        <VigDropdown>
          <template #dropdown-toggle>
            <SynButton class="bg-white" type-outline>
              <div class="flex-center space-x-2">
                <span>
                  {{ $t('COMMON_ATTACHMENT_CODE') || 'Attachment code' }}
                </span>
                <SynIcon
                  v-vig-tooltip="{
                    content: `
                    <div class='px-2 py-4 w-80 text-left bg-white flex flex-col space-y-2'>
                      <span class='font-semibold text-current'>
                      ${
                        $t('COMMON_LABEL_WHAT_IS_ATTACHMENT_CODE') ||
                        'What is Attachment code?'
                      }
                      </span>
                      <span class='font-normal'>${
                        $t('COMMON_ATTACHMENT_EXPLAIN') ||
                        'User can using to join your organization'
                      }</span>
                    </div>`,
                    arrow: true,
                    placement: 'bottom',
                    delay: 0,
                    theme: 'light',
                    animation: 'scale',
                  }"
                  name="question"
                  custom-class="h-4 w-4 hover:fill-current cursor-default"
                />
              </div>
            </SynButton>
          </template>
          <template #dropdown-menu>
            <div class="flex items-center justify-between p-4">
              <span class="font-medium px-4"> {{ attachmentCode }} </span>
              <div class="flex-center">
                <SynIcon
                  class="dropdown-item"
                  has-action
                  name="copy"
                  @click="onCopyAttachmentCode"
                />
                <SynIcon
                  has-action
                  name="reload"
                  @click="onGenerateAttachmentCode"
                />
              </div>
            </div>
          </template>
        </VigDropdown>
        <SynButton
          v-permission="'FunctionOrganizationInvitation'"
          name-icon="add-user"
          :label="$t('USERMANAGEMENT_LABEL_INVITE_USER') || 'Invite'"
          @click="onInviteUser()"
        />
      </div>
    </div>

    <div
      class="
        flex-1
        min-h-0
        h-full
        shadow-md
        bg-white
        flex flex-col
        space-y-4
        overflow-y-hidden
        border-t border-current-100
      "
    >
      <!-- USER LIST -->
      <div v-show="viewMode == EViewMode.LIST" class="w-full h-full">
        <UserTable ref="userTableRef" @show-detail="onChooseUser" />
      </div>
      <div v-show="viewMode == EViewMode.WAITING" class="w-full h-full">
        <PendingUsers ref="pendingUsersRef" class="shadow-md" />
      </div>
      <template v-if="viewMode == EViewMode.DETAIL">
        <UserDetailPage
          v-if="userDetail?.id"
          :key="userDetail?.id"
          :user-id="userDetail?.id"
          is-edit
          @update-user-success="updateUserSuccess"
        />
      </template>
    </div>
  </div>
  <InviteUserModal
    v-if="isInvite"
    @on-invite-success="onInviteSuccess"
    @on-cancel="onCancelInvite"
    @on-close="onCancelInvite"
  />
</template>
