<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.5 31.5"
  >
    <path
      id="Icon_awesome-check-square"
      data-name="Icon awesome-check-square"
      d="M28.125,33.75H3.375A3.375,3.375,0,0,1,0,30.375V5.625A3.375,3.375,0,0,1,3.375,2.25h24.75A3.375,3.375,0,0,1,31.5,5.625v24.75A3.375,3.375,0,0,1,28.125,33.75ZM13.733,26.855,26.671,13.918a1.125,1.125,0,0,0,0-1.591L25.08,10.736a1.125,1.125,0,0,0-1.591,0L12.938,21.287,8.011,16.361a1.125,1.125,0,0,0-1.591,0L4.829,17.952a1.125,1.125,0,0,0,0,1.591l7.313,7.313a1.125,1.125,0,0,0,1.591,0Z"
      transform="translate(0 -2.25)"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
  >
    <path
      d="M49.001,0H10.999C4.934,0,0,4.934,0,10.999v38.003C0,55.066,4.934,60,10.999,60h38.002C55.065,60,60,55.066,60,49.001  V10.999C60,4.934,55.065,0,49.001,0z M58,49.001C58,53.963,53.963,58,49.001,58H10.999C6.037,58,2,53.963,2,49.001V10.999  C2,6.037,6.037,2,10.999,2h38.002C53.963,2,58,6.037,58,10.999V49.001z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
