<script setup lang="ts">
import { computed, ref } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useFormValidation } from '@/ui/common/composables/validations/form-validation.composable';
import { DriveProvider, FileUrlBase } from '@/ui/common/constants/drive';
import $constants from '@/ui/plugins/constants';
import FileStorageRepository from '@/application/repositories/FileStorageRepository';
import AddFileLocation from '@/ui/modules/ged/_commons/add-file-location/AddFileLocation.vue';

const props = defineProps<{
  location: any;
}>();

const emits = defineEmits(['onClose', 'onAdded']);

const { isValidGoogleDocUrl, isValidLinkName } = useFormValidation();

const document = ref<any>({ link: '', name: '' });
const isSaving = ref<boolean>(false);

const validUrlIcon = computed(() => {
  if (document.value?.link?.startsWith(FileUrlBase.Document)) {
    return 'GoogleDocument';
  }

  if (document.value?.link?.startsWith(FileUrlBase.Spreadsheet)) {
    return 'GoogleSpreadsheet';
  }

  if (document.value?.link?.startsWith(FileUrlBase.Presentation)) {
    return 'GooglePresentation';
  }

  return null;
});

const onFormSubmit = async () => {
  isSaving.value = true;

  let contentType;
  if (document.value?.link?.startsWith(FileUrlBase.Document)) {
    contentType = $constants.FILE.TYPE.GOOGLE_DOC;
  }
  if (document.value?.link?.startsWith(FileUrlBase.Spreadsheet)) {
    contentType = $constants.FILE.TYPE.GOOGLE_SHEET;
  }
  if (document.value?.link?.startsWith(FileUrlBase.Presentation)) {
    contentType = $constants.FILE.TYPE.GOOGLE_PRESENTATION;
  }

  const newDoc = {
    ...document.value,
    contentType,
    folderId: props.location?.folderId,
    groupId: props.location?.groupId,
    domainId: props.location?.domainId,
    userId: props.location?.userId,
    metadata: {
      driveProvider: DriveProvider.Google,
    },
  };

  try {
    const res: any = await FileStorageRepository.getInstance().addDriveDocument(
      newDoc
    );

    if (res?.result) emits('onAdded', res?.result);
  } catch (e) {
    console.log(e);
  }

  isSaving.value = false;
};
</script>

<template>
  <syn-modal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    style-body="py-4 px-2 flex flex-col"
    :disable-click-outside="true"
    :disable-esc-to-close="true"
    disable-auto-focus
    @cancel="$emit('onClose')"
  >
    <template #header>
      {{ $t('GED_GOOGLE_ADD_FROM_LINK') }}
    </template>

    <template #body>
      <Form id="form-drive-from-link" class="px-3" @submit="onFormSubmit">
        <div class="mb-3 flex">
          <div class="w-20 text-gray-500">{{ $t('GED_FOLDER_IN') }}</div>
          <AddFileLocation
            :location="location"
            :is-drive-document="true"
            class="font-semibold"
          />
        </div>

        <div class="flex items-center mb-3">
          <label for="form-drive-link-name" class="block w-20 text-gray-500">
            {{ $t('COMMON_LABEL_NAME') }}</label
          >
          <div class="flex-1">
            <Field
              id="form-drive-link-name"
              v-model="document.name"
              v-cusFocus="true"
              type="text"
              class="vig-input"
              name="fieldName"
              :rules="isValidLinkName"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              :validate-on-model-update="false"
            />
            <ErrorMessage
              name="fieldName"
              class="block mt-1 text-sm text-red-600"
            />
          </div>
        </div>

        <div class="flex items-center">
          <label for="form-drive-link-url" class="block w-20 text-gray-500">
            {{ $t('GED_LINK_URL') }}</label
          >
          <div class="flex-1 relative">
            <Field
              id="form-drive-link-url"
              v-model="document.link"
              type="text"
              class="vig-input pr-[2rem]"
              name="fieldUrl"
              :rules="isValidGoogleDocUrl"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              :validate-on-model-update="false"
            />
            <ErrorMessage
              name="fieldUrl"
              class="block mt-1 text-sm text-red-600"
            />
            <SynIcon
              v-if="validUrlIcon"
              :name="validUrlIcon"
              class="absolute right-2 top-3.5"
            />
          </div>
        </div>
      </Form>
    </template>

    <template #footer>
      <div class="px-3 py-2 flex justify-end space-x-2">
        <VigButton ghost color="gray" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') }}
        </VigButton>
        <VigButton
          type="submit"
          form="form-drive-from-link"
          :processing="isSaving"
        >
          <span>{{ $t('COMMON_LABEL_SAVE') }}</span>
        </VigButton>
      </div>
    </template>
  </syn-modal>
</template>
