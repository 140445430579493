<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 502.657 502.657"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M389.063,281.758c-13.827-69.587,52.223-110.399,52.223-110.399s-25.734-37.231-68.983-46.809
			c-43.228-9.599-64.863,2.394-86.456,11.389c-21.614,9.017-32.399,9.017-32.399,9.017c-31.191,0-53.992-33.025-113.419-17.99
			c-40.941,10.332-84.04,57.594-91.827,114.002c-7.787,56.429,9.017,128.432,41.416,184.236
			c32.399,55.847,65.403,76.835,88.828,77.439c23.426,0.582,46.809-16.782,75.002-21.01c28.215-4.185,45.579,10.225,73.254,18.012
			c27.567,7.766,37.231,0.561,68.983-26.446c31.838-27.007,60.657-104.467,60.657-104.467S402.89,351.388,389.063,281.758z"
          ></path>
          <path
            d="M327.845,79.531C358.475,48.319,348.1,0,348.1,0s-43.012,7.55-71.831,37.533c-28.797,30.005-24.03,77.396-24.03,77.396
			S297.214,110.723,327.845,79.531z"
          ></path>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
