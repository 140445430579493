<script setup lang="ts">
import { computed } from 'vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';
import {
  EFilterTaskBoard,
  EFilterTaskDefault,
  TASK_PIN_BY_CODE,
  TaskByDeadlineByKey,
} from '@/application/constants/task.const';
import { TaskListTab } from '@/domain/enums/taskEnum';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import taskListStore from '@/store/task/task-list-store';
import { taskStates } from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { TaskUrgency } from '@/ui/hooks/taskHook';
import SwitchTaskBoardItem from '@/ui/modules/task/board/switch-visible/SwitchTaskBoardItem.vue';
import domainStore from '@/store/scope';
import groupStore from '@/store/group';
import userStore from '@/store/user';

const props = withDefaults(
  defineProps<{
    listTaskOwnerId: any;
    type: 'USER' | 'GROUP' | 'DOMAIN';
  }>(),
  {
    listTaskOwnerId: getCurrentUserId(),
    type: 'USER',
  }
);
const _taskTabViewStore = taskTabViewStore();
const _taskListStore = taskListStore();

const sourceType = computed<'U' | 'G' | 'D'>(() => {
  if (props.type === 'GROUP') return 'G';
  if (props.type === 'DOMAIN') return 'D';
  return 'U';
});
const listTaskOwner = computed<any>(() => {
  if (props.type == 'DOMAIN') {
    const domainByIds = domainStore().domainByIds;
    return domainByIds[props.listTaskOwnerId];
  }
  if (props.type == 'GROUP') {
    const allGroupByIds = {
      ...groupStore().allGroupByIds,
      ...groupStore().myGroupByIds,
    };
    return allGroupByIds[props.listTaskOwnerId];
  }
  return userStore().allUserByIds[props.listTaskOwnerId];
});
const boardCurrentTabId = computed(() => _taskTabViewStore.boardCurrentTabId);
const activeTab = computed(() => _taskListStore.activeTab);

const listTabs = computed(() =>
  _taskTabViewStore.listTabs.filter((o) => {
    const isMyRelated =
      o?.id == EFilterTaskDefault.MY_RELATED &&
      (activeTab.value === TaskListTab.MyAssign || sourceType.value !== 'U');

    const isDefault = o?.isDefault;

    return !isMyRelated && isDefault;
  })
);
const listCustomTabs = computed<ICustomTaskFilter[]>(
  () => _taskTabViewStore.listCustomTabs
);

const listStatesPinned = computed<ICustomTaskFilter[]>(() => {
  if (!taskStates) return [];
  const stateList = Object.values(TASK_PIN_BY_CODE).filter(
    (o) => o.code !== 'UN_PIN'
  );
  return stateList?.map((o, index) => {
    return {
      id: o.code,
      name: '',
      index,
      color: o.color,
      visible: true,
      filterObject: {
        isDefault: false,
        listOwnerId: props.listTaskOwnerId,
        pinnedColor: o.code,
      },
    };
  });
});

// STATUS
const listStates = computed<ICustomTaskFilter[]>(() => {
  if (!taskStates) return [];
  const stateList = Object.values(taskStates).sort((a, b) => {
    return a.index - b.index;
  });
  return stateList?.map((o, index) => {
    return {
      id: o.key,
      name: translate(o.nameCode),
      index,
      color: o.colorHex,
      visible: true,
      filterObject: {
        isDefault: false,
        listOwnerId: props.listTaskOwnerId,
        assignorId: null,
        urgency: null,
        keyword: null,
        groupIds: null,
        domainId: null,
        domainIds: null,
        creatorIds: null,
        assigneeIds: null,
        createdFromDate: null,
        createdToDate: null,
        updatedFromDate: null,
        updatedToDate: null,
        status: `${o.key}`,
      },
    };
  });
});
const cardList = computed<any[]>(() => {
  return Object.values(TaskUrgency)?.map((urgency) => {
    return {
      ...urgency,
      id: urgency?.key,
      nameKey: urgency?.textCode,
      name: translate(urgency?.textCode),
      filterObject: urgency?.data,
    };
  });
});

const deadlineBoardList = computed<ICustomTaskFilter[]>(() => {
  if (!TaskByDeadlineByKey) return [];
  const stateList = Object.values(TaskByDeadlineByKey).sort((a, b) => {
    return a.index - b.index;
  });
  return stateList?.map((o, index) => {
    return {
      id: o.key,
      name: translate(o.nameCode),
      index,
      color: o.colorHex,
      visible: true,
      filterFunc: o.filterFunc,
      scheduleTime: o.scheduleTime,
    };
  });
});
</script>
<template>
  <div class="flex-center space-x-1">
    <template v-if="boardCurrentTabId === EFilterTaskBoard.PINNED">
      <template v-for="card in listStatesPinned" :key="card">
        <SwitchTaskBoardItem
          v-vig-tooltip="{
            content: `
            <div class='w-8 h-8 flex-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                version='1.1'
                xmlns:xlink='http://www.w3.org/1999/xlink'
                xmlns:svgjs='http://svgjs.com/svgjs'
                x='0'
                y='0'
                viewBox='0 0 48 48'
                xml:space='preserve'
                class='w-4 h-4'
                style='enable-background: new 0 0 512 512; fill: ${card?.color}'
              >
                <g>
                  <path
                    fill-rule='nonzero'
                    d='M25.536 6.908a4.01 4.01 0 0 1 5.656 0l9.9 9.9a4 4 0 0 1 0 5.656 3.993 3.993 0 0 1-5.657 0l-.707-.707-5.903 5.903c1.78 4.82.589 10.767-3.29 14.603a1.005 1.005 0 0 1-1.414 0l-8.485-8.485-6.364 6.364a1 1 0 0 1-1.414-1.414l6.364-6.364-8.485-8.485a.99.99 0 0 1 0-1.415c3.783-3.783 9.53-5.137 14.602-3.29l5.903-5.903-.706-.706a4.002 4.002 0 0 1 0-5.657z'
                    data-original='#000000'
                    class=''
                  ></path>
                </g>
              </svg>
            </div>
            `,
            arrow: false,
            placement: 'top',
            delay: 0,
            theme: 'light',
            animation: 'scale',
            zIndex: 40,
            hideAfter: true,
          }"
          :card-id="card?.id"
          prefix-id="BOARD_PINNED_"
        />
      </template>
    </template>
    <template v-if="boardCurrentTabId === EFilterTaskBoard.BY_STATUS">
      <template v-for="card in listStates" :key="card">
        <SwitchTaskBoardItem
          v-vig-tooltip="card?.name"
          :card-id="card?.id"
          prefix-id="BOARD_BY_STATUS_"
        />
      </template>
    </template>
    <template v-else-if="boardCurrentTabId === EFilterTaskBoard.BY_URGENCY">
      <template v-for="card in cardList" :key="card?.key">
        <SwitchTaskBoardItem
          v-vig-tooltip="card?.name"
          :card-id="card?.key"
          prefix-id="BOARD_BY_URGENCY_"
        />
      </template>
    </template>
    <template v-else-if="boardCurrentTabId === EFilterTaskBoard.BY_DEADLINE">
      <template v-for="card in deadlineBoardList" :key="card?.key">
        <SwitchTaskBoardItem
          v-vig-tooltip="card?.name"
          :card-id="card?.id"
          prefix-id="BOARD_BY_DEADLINE_"
        />
      </template>
    </template>
    <template v-else-if="boardCurrentTabId === EFilterTaskBoard.DEFAULT">
      <template v-for="card in listTabs" :key="card">
        <SwitchTaskBoardItem
          v-vig-tooltip="
            $t(card?.labelKey, {
              username: listTaskOwner?.isMe
                ? $t('COMMON_LABEL_ME')
                : listTaskOwner?.firstName,
            })
          "
          :card-id="card?.id"
          prefix-id="BOARD_BY_DEFAULT_"
        />
      </template>
      <template v-if="activeTab === TaskListTab.MyList">
        <template v-for="card in listCustomTabs" :key="card?.id">
          <SwitchTaskBoardItem
            v-vig-tooltip="card?.name"
            :card-id="card?.id"
            prefix-id="BY_CUSTOM_"
          />
        </template>
      </template>
    </template>
  </div>
</template>
