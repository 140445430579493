export const ROUTER_GROUP = {
    FAVORITES: {
        id: 'FAVORITES',
        index: 0,
        nameCode: 'SIDEBAR_LABEL_MY_FAVORITES',
    },
    FEATURE: {
        id: 'FEATURE',
        index: 1,
        nameCode: 'COMMON_LABEL_FEATURE',
    },
    // HOME: {
    //     id: 'HOME',
    //     index: 1,
    //     nameCode: 'COMMON_MODULE_OVERVIEW',
    // },
    // ORGANIZATION: {
    //     id: 'ORGANIZATION',
    //     index: 2,
    //     nameCode: 'COMMON_LABEL_ORGANIZATION',
    // },
    // PERSONAL: {
    //     id: 'PERSONAL',
    //     index: 3,
    //     nameCode: 'DAYOFF_REASON_PERSONAL',
    // },
    MANAGEMENT: {
        id: 'MANAGEMENT',
        index: 2,
        nameCode: 'SIDEBAR_LABEL_MANAGEMENT',
    },
    SETTING: {
        id: 'SETTING',
        index: 3,
        nameCode: 'COMMON_LABEL_SETTING',
    },
};
