<template>
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33.9 34.18"
  >
    <path
      style="
        fill: none;
        stroke: currentColor;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.9px;
      "
      class="cls-1"
      d="M16.95,.95C8.11,.95,.95,8.11,.95,16.95c0,3.77,1.3,7.23,3.48,9.96l-2.26,6.32,8.53-1.55c1.92,.82,4.04,1.27,6.26,1.27,8.84,0,16-7.16,16-16S25.79,.95,16.95,.95Z"
    />
    <g>
      <circle
        style="
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.9px;
        "
        class="cls-1"
        cx="11.06"
        cy="16.62"
        r=".95"
      />
      <circle
        style="
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.9px;
        "
        class="cls-1"
        cx="17.3"
        cy="16.62"
        r=".95"
      />
      <circle
        style="
          fill: none;
          stroke: currentColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.9px;
        "
        class="cls-1"
        cx="23.55"
        cy="16.62"
        r=".95"
      />
    </g>
  </svg>
</template>
