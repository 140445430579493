import UnAuthRepository from '@/application/repositories/UnauthRepository';
import { IRegisterLicensePayload } from '@/application/types/payment.types';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

export default class UnAuthService {
    private static instance: UnAuthService;
    _unauthRepository: UnAuthRepository;

    constructor() {
        this._unauthRepository = UnAuthRepository.getInstance();
    }

    public static getInstance(): UnAuthService {
        if (!UnAuthService.instance) {
            // Get from local storage
            UnAuthService.instance = new UnAuthService();
        }

        return UnAuthService.instance;
    }

    checkPhone(phone): Promise<any> {
        return this._unauthRepository.checkPhone(phone);
    }

    checkUsername(username): Promise<any> {
        return this._unauthRepository.checkUsername(username);
    }

    checkEmail(phone): Promise<any> {
        return this._unauthRepository.checkEmail(phone);
    }

    checkUserHasPasswordByEmail(data: string): Promise<any> {
        return this._unauthRepository.checkUserHasPasswordByEmail(data);
    }

    checkUserHasPasswordByPhone(data: string): Promise<any> {
        return this._unauthRepository.checkUserHasPasswordByPhone(data);
    }

    sendVerificationCodeByPhoneNumber(data): Promise<any> {
        return this._unauthRepository.sendVerificationCodeByPhoneNumber(data);
    }

    reSendVerificationCodeByPhoneNumber(data): Promise<any> {
        return this._unauthRepository.reSendVerificationCodeByPhoneNumber(data);
    }

    sendVerificationCodeByEmail(data: any): Promise<any> {
        return this._unauthRepository.sendVerificationCodeByEmail(data);
    }

    reSendVerificationCodeByEmail(data: any): Promise<any> {
        return this._unauthRepository.reSendVerificationCodeByEmail(data);
    }

    verifyUserPhoneNumber(data): Promise<any> {
        return this._unauthRepository.verifyUserPhoneNumber(data);
    }

    getInvitedUser(data): Promise<any> {
        return this._unauthRepository.getInvitedUser(data);
    }

    async saveUserProfile(
        data: any,
        refId: string,
        voucherCode: string,
        advertisementInfo: any
    ): Promise<any> {
        const res = await this._unauthRepository.saveUserProfile(
            data,
            refId,
            voucherCode,
            advertisementInfo
        );
        return res?.result;
    }

    async registerWithUsername(
        data: any,
        refId: string,
        voucherCode: string,
        advertisementInfo: any
    ): Promise<any> {
        const res = await this._unauthRepository.registerWithUsername(
            data,
            refId,
            voucherCode,
            advertisementInfo
        );
        return res?.result;
    }

    // Login
    verifyToken(): Promise<any> {
        const token = getLocalStorage(StorageConstant.TOKEN);
        return this._unauthRepository.verifyToken({ jwtToken: token });
    }

    loginWithPassword(payload): Promise<any> {
        return this._unauthRepository.loginWithPassword(payload);
    }

    loginByPhoneCode(data: any): Promise<any> {
        return this._unauthRepository.loginByPhoneCode(data);
    }

    loginByEmailCode(data: any): Promise<any> {
        return this._unauthRepository.loginByEmailCode(data);
    }

    getIpInfo(): Promise<any> {
        return this._unauthRepository.getIpInfo();
    }

    checkVoucherCode(voucherCode: string): Promise<any> {
        return this._unauthRepository.checkVoucherCode(voucherCode);
    }

    getVoucherCodeDetail(
        voucherCode: string,
        needToUpdate: boolean
    ): Promise<any> {
        return this._unauthRepository.getVoucherCodeDetail(
            voucherCode,
            needToUpdate
        );
    }

    getAllLicensePackage(): Promise<any> {
        return this._unauthRepository.getAllLicensePackage();
    }

    registerLicense(data: IRegisterLicensePayload): Promise<any> {
        return this._unauthRepository.registerLicense(data);
    }

    async getTaskShared(sharedId: string): Promise<any> {
        const res: any = await this._unauthRepository.getTaskShared(sharedId);
        return res?.result;
    }
}
