import { ETaskListMenuTab } from '@/application/constants/task.const';
import { TaskReccuringTemplateClass } from '@/domain/entities/task/TaskReccuringTemplateClass';

export enum ETaskRepeatType {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Yearly = 4,
    None = 99,
}

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export enum ETaskMonthlySubRepeatTypeModel {
    SpecificDayEachMonth = 1,
    FirstSameDayOfWeekInMonth = 2,
    SecondSameDayOfWeekInMonth = 3,
    ThirdSameDayOfWeekInMonth = 4,
    FourthSameDayOfWeekInMonth = 5,
    LastSameDayOfWeekInMonth = 6,
    FirstDayOfTheMonth = 7,
    LastDayOfTheMonth = 8,
}
export interface ITaskSubRepeatTypeModel {
    weeklyOptions?: DayOfWeek[];
    monthlyOptions?: ETaskMonthlySubRepeatTypeModel;
    monthlySpecificDay?: number;
    byMonth: number[];
    byMonthday: number[];
}

export interface ITaskRepeat {
    repeatType: ETaskRepeatType;
    subRepeatType?: ITaskSubRepeatTypeModel;
    startDate?: Date;
    currentTaskId?: number;
    templateId?: number;
    endDate?: Date;
    triggerOn?: string[] | null;
    isLatestTask?: boolean;
}
export interface ITaskReccurring {
    repeat: ITaskRepeat;
    template?: TaskReccuringTemplateClass;
}

// Generated by https://quicktype.io

export interface ICustomTaskColumn {
    code: string;
    name: string;
    index: number;
    visible: boolean;
    adjustable: boolean;
}

// Generated by https://quicktype.io

export interface ICustomTaskFilter {
    id: string;
    name: string;
    index: number;
    color: string;
    visible: boolean;
    filterObject: CustomFilters;
}

export interface CustomFilters {
    isDefault: boolean;
    listOwnerId?: number;
    urgency?: string;
    groupIds?: string;
    domainIds?: string;
    creatorIds?: string;
    status?: string;
    assignorId?: number;
    domainId?: number;
    assigneeIds?: string;
    createdFromDate?: string;
    createdToDate?: string;
    updatedFromDate?: string;
    updatedToDate?: string;
    keyword?: string;
    pinnedColor?: string;
    menuTab?: ETaskListMenuTab;
}

// Generated by https://quicktype.io

export interface ITaskListItem {
    id: number;
    code: string;
    name: string;
    groupId: number;
    isGroupMember: boolean;
    domainId: number;
    projectId: number;
    subprojectId: number;
    taskType: number;
    isPrivate: boolean;
    taskLife: number;
    originalEstimationTime: string;
    scheduleTime: string;
    isPlannedToTreat: boolean;
    isPlannedToFinish: boolean;
    ownerTimezone: string;
    taskSchedule: TaskSchedule;
    assigneeId: number;
    assignorId: number;
    priority: number;
    creatorId: number;
    creationTime: string;
    lastModifierId: number;
    lastModificationTime: string;
    finisherId: number;
    finishedTime: string;
    attachmentCount: number;
    noteCount: number;
    description: string;
    important: boolean;
    isDeleted: boolean;
    timezone: null;
    totalComment: number;
    isRepeat: boolean;
    pinnedColor: string;
}

export interface TaskSchedule {
    doItToday: boolean;
    finishItToday: boolean;
    planType: number;
    overdue: number;
    code: null;
    scheduleOptionKey: number;
    scheduleDateValue: null;
    currentUserDate: null;
    otherTime: number;
    otherTimes: null;
}

export interface ITaskReminder {
    id?: string;
    organizationId?: number;
    calendarId?: number;
    sourceId?: string;
    sourceType?: number;
    title?: string;
    description?: string;
    type?: number;
    status?: number;
    startDate?: Date;
    endDate?: Date;
    startTime?: null;
    endTime?: null;
    firstReminderTime?: Date;
    createdDate?: Date;
    updateDate?: Date;
    createdBy?: number;
    updateBy?: number;
}
export interface ITaskReminderInput {
    taskId: number;
    reminderTime: Date;
}
