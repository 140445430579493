<script setup lang="ts">
import { ref } from 'vue';
import StepTemplateTable from '@/ui/modules/workflow/step-template/StepTemplateTable.vue';

const searchText = ref<string>('');

const addNewStep = () => {
  if (stepTemplateTableRef.value?.onClickDetail)
    stepTemplateTableRef.value?.onClickDetail({}, 'ADD');
};

const onReloadStepTable = () => {
  searchText.value = '';
};

const stepTemplateTableRef = ref<any>(null);
</script>
<template>
  <div class="w-full h-full flex flex-col">
    <div
      class="w-full flex flex-col items-center justify-between px-2 py-2 gap-2"
    >
      <div class="w-full flex justify-start">
        <span class="font-semibold text-current-500">
          {{ $t('WORKFLOW_LABEl_STEPS') }}
        </span>
      </div>
      <div class="w-full flex items-center justify-between space-x-1">
        <VigSearchBox
          v-model="searchText"
          input-class="text-sm"
          style="width: 20rem"
          autofocus
          :placeholder="$t('COMMON_LABEL_SEARCH')"
        />
        <button
          class="w-8 h-8 rounded cursor-pointer flex-center bg-current-500"
          @click="addNewStep"
        >
          <SynIcon name="plus" custom-class="w-4 h-4 fill-white" />
        </button>
      </div>
    </div>
    <div class="flex-1 overflow-hidden">
      <StepTemplateTable
        ref="stepTemplateTableRef"
        :action-list="['DUPLICATE', 'EDIT', 'REMOVE']"
        :search-text="searchText"
        @reload="onReloadStepTable"
      />
    </div>
  </div>
</template>
