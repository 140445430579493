<script setup lang="ts">
import { computed, ref } from 'vue';
import WorkflowEntity, {
  IWorkflowEntity,
} from '@/domain/entities/workflow/WorkflowEntity';
import WorkflowAddNewModal from '@/ui/modules/workflow/workflow-detail/WorkflowAddNewModal.vue';
import workflowStore from '@/store/workflow';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';
import UpdateWorkflowStepsModal from '@/ui/modules/workflow/workflow-detail/UpdateWorkflowStepsModal.vue';
import WorkflowAddModal from '@/ui/modules/workflow/workflow-detail/WorkflowAddModal.vue';
import departmentStore from '@/store/department';
import groupStore from '@/store/group';
import domainStore from '@/store/scope';
import UpdateWorkflowSteps from '@/ui/modules/workflow/workflow-detail/UpdateWorkflowSteps.vue';
import workflowDetailComposables from '@/ui/modules/workflow/workflow-detail/workflow-detail.composables';

const props = withDefaults(
  defineProps<{
    workflowInfo: IWorkflowEntity;
    viewMode?: 'EDIT_STEP' | 'VIEW';
    actionList?: string[];
  }>(),
  {
    viewMode: 'VIEW',
    actionList: () => ['DUPLICATE', 'EDIT'],
  }
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'removeSuccess'): void;
  (e: 'addSuccess', newData: IWorkflowEntity): void;
  (e: 'updateSuccess', newData: IWorkflowEntity): void;
  (e: 'updateViewModel', viewModel: 'EDIT_STEP' | 'VIEW'): void;
}>();

const _workflowStore = workflowStore();
const _departmentStore = departmentStore();
const _groupStore = groupStore();
const _domainStore = domainStore();
const { workflowDetail, isLoading, onPreCheckToSave, initComponent } =
  workflowDetailComposables(props.workflowInfo);

const allDepartmentByIds = computed(() => _departmentStore.allDepartmentByIds);
const allGroupByIds = computed(() => _groupStore.allGroupByIds);
const domainByIds = computed(() => _domainStore.domainByIds);

const isEditInformation = ref<boolean>(false);
const isOpenAddNewModal = ref<boolean>(false);

const onRemove = async () => {
  const res = await workflowDetail.value.remove();
  if (!res) return;

  emit('removeSuccess');
};

const createWorkflowRef = ref<any>(null);
const updateSuccess = (newData) => {
  if (
    viewWorkflowInformationMode.value == 'ADD' ||
    viewWorkflowInformationMode.value == 'CLONE'
  ) {
    emit('addSuccess', newData);

    isEditInformation.value = false;
    isOpenAddNewModal.value = false;
    return;
  }

  workflowDetail.value = new WorkflowEntity(newData);
  isEditInformation.value = false;
  isOpenAddNewModal.value = false;

  emit('updateSuccess', workflowDetail.value);

  setTimeout(() => {
    if (createWorkflowRef.value?.initData) createWorkflowRef.value?.initData();
  });
};
const updateStepsSuccess = (newSteps, drawData) => {
  workflowDetail.value.steps = newSteps;
  workflowDetail.value.totalSteps = newSteps?.length;
  workflowDetail.value.drawData = drawData;
  workflowDetail.value.lastModificationTime = new Date().toString();

  isEditStepList.value = false;
  emit('updateSuccess', workflowDetail.value);
};

const openEditInformationModal = () => {
  workflowDefault.value = new WorkflowEntity(props.workflowInfo);
  viewWorkflowInformationMode.value = 'EDIT';
  isEditInformation.value = true;
};

const workflowDefault = ref<IWorkflowEntity | null>(null);
const viewWorkflowInformationMode = ref<'ADD' | 'EDIT' | 'CLONE'>('EDIT');
const actionDataListByKey = {
  DUPLICATE: {
    key: 'DUPLICATE',
    iconName: 'duplicate',
    color: '',
    handleClick: () => {
      viewWorkflowInformationMode.value = 'CLONE';
      workflowDefault.value = new WorkflowEntity({
        ...workflowDetail.value,
        id: '',
        name: _workflowStore.generateWorkflowName(props.workflowInfo?.name),
      });

      isOpenAddNewModal.value = true;
    },
  },
  EDIT: {
    key: 'EDIT',
    iconName: 'edit',
    color: '',
    handleClick: () => {
      isEditStepList.value = true;
    },
  },
  // REMOVE: {
  //   key: 'REMOVE',
  //   iconName: 'trash',
  //   color: 'red',
  //   handleClick: () => {
  //     onRemove();
  //   },
  // },
};
const actionDataList = computed<any[]>(() => {
  return props.actionList?.map((key) => actionDataListByKey[key]);
});
const isOpenMoreAction = ref<boolean>(false);
const isEditStepList = ref<boolean>(
  props.viewMode == 'EDIT_STEP' ? true : false
);

const onCloseUpdate = () => {
  if (props.viewMode == 'EDIT_STEP') emit('cancel');

  isEditStepList.value = false;
  initComponent();
};
</script>
<template>
  <div
    class="w-full h-full flex flex-col space-y-2 overflow-hidden"
    style="min-height: 36rem"
  >
    <div class="w-full h-full py-4 flex flex-col space-y-4">
      <!-- HEADER -->
      <div class="flex flex-col px-4">
        <div class="flex items-center justify-between">
          <span class="text-xl font-semibold">
            {{ workflowDetail?.name }}
          </span>
          <div class="flex-center space-x-2">
            <span
              v-if="
                workflowDetail?.totalTaskUsed &&
                workflowDetail?.totalTaskUsed > 0
              "
              class="px-2 py-1 rounded-md bg-orange-50 text-orange-500 text-xs"
            >
              {{
                $t('WORKFLOW_LABEL_WORKFLOW_TOTAL_TASK_IN_USER', {
                  totalTask: workflowDetail?.totalTaskUsed || '',
                })
              }}
            </span>
            <div
              v-for="action in actionDataList"
              :key="action?.key"
              class="
                w-8
                h-8
                rounded-md
                cursor-pointer
                flex-center
                border border-gray-100
                hover:shadow
              "
              @click="action?.handleClick()"
            >
              <SynIcon
                :name="action?.iconName"
                :custom-class="
                  action?.color ? `h-4 w-4 fill-${action?.color}-500` : ''
                "
              />
            </div>
            <VigDropdown
              :arrow="false"
              placement="bottom-end"
              @on-dropdown-open="isOpenMoreAction = true"
              @on-dropdown-close="isOpenMoreAction = false"
            >
              <template #dropdown-toggle>
                <button
                  class="
                    w-8
                    h-8
                    rounded-md
                    shadow
                    hover:bg-gray-100
                    flex-center
                  "
                  :class="isOpenMoreAction ? 'bg-gray-100' : ''"
                >
                  <SynIcon name="dots-vertical" custom-class="w-4 h-4" />
                </button>
              </template>
              <template #dropdown-menu>
                <div
                  class="
                    py-1
                    z-100
                    list-ul
                    rounded
                    cursor-pointer
                    dropdown-item
                  "
                  style="min-width: 11rem"
                >
                  <div
                    class="
                      list-li
                      flex
                      text-gray-600
                      justify-start
                      items-center
                      space-x-2
                      text-sm
                      relative
                    "
                    @click="openEditInformationModal"
                  >
                    <SynIcon name="info" custom-class="w-4 h-4 fill-blue-500" />
                    <span>
                      {{
                        $t('COMMON_LABEL_EDIT_INFORMATION') ||
                        'Edit information'
                      }}
                    </span>
                  </div>
                  <div
                    class="
                      list-li
                      flex
                      text-gray-600
                      justify-start
                      items-center
                      space-x-2
                      text-sm
                      relative
                    "
                    @click="onRemove"
                  >
                    <SynIcon name="trash" custom-class="w-4 h-4 fill-red-500" />
                    <span>
                      {{ $t('TASK_DETAIL_REMOVE') || 'Remove' }}
                    </span>
                  </div>
                </div>
              </template>
            </VigDropdown>
          </div>
        </div>
        <div>
          <span class="text-sm italic">
            {{ workflowDetail?.description }}
          </span>
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm italic">
            {{ $t('WORKFLOW_LABEl_TOTAL_TASK_IN_USE') }}
          </span>
          <span class="text-sm italic text-current-500">
            {{ workflowDetail?.totalTasks }}
          </span>
        </div>
      </div>
      <!-- Body -->
      <div v-if="false" class="flex items-center space-x-2 px-4">
        <!-- department -->
        <TaskDetailField v-if="workflowDetail?.departmentId">
          <template #fieldName>
            <span>{{ $t('COMMON_MODULE_MANAGEMENT_DEPARTMENT') }}</span>
          </template>
          <template #body>
            <div
              v-if="workflowDetail?.departmentId"
              class="relative flex items-center py-1 h-10 rounded w-48"
            >
              <div class="w-6 h-6 absolute left-2">
                <div class="relative flex items-center">
                  <SynAvatar
                    :src="
                      workflowDetail?.departmentId
                        ? allDepartmentByIds[
                            workflowDetail?.departmentId
                          ]?.avatarUrl?.replace('original', 'mini')
                        : ''
                    "
                    :name="
                      allDepartmentByIds[workflowDetail?.departmentId]?.name
                    "
                    :type="'department'"
                    custom-class="w-6 h-6"
                  />
                </div>
              </div>

              <div
                :title="
                  workflowDetail?.departmentId &&
                  allDepartmentByIds[workflowDetail?.departmentId]?.name
                    ? allDepartmentByIds[workflowDetail?.departmentId]?.name
                    : ''
                "
                class="
                  text-sm text-gray-500
                  h-full
                  w-full
                  focus:bg-white focus:outline-none focus:border-transparent
                  flex
                  items-center
                  pl-10
                  pr-8
                  text-overflow-hidden-line
                "
              >
                <span
                  v-if="allDepartmentByIds[workflowDetail?.departmentId]?.name"
                  class="truncate text-overflow-hidden-line"
                >
                  {{ allDepartmentByIds[workflowDetail?.departmentId]?.name }}
                </span>
              </div>
            </div>
            <span v-else class="text-gray-500 italic text-sm pl-5">
              {{ $t('COMMON_LABEL_NOTHING') }}
            </span>
          </template>
        </TaskDetailField>
        <!-- domain -->
        <TaskDetailField v-if="workflowDetail?.domainId">
          <template #fieldName>
            <span>{{ $t('TASK_CREATE_FORM_DOMAIN') }}</span>
          </template>
          <template #body>
            <div
              v-if="workflowDetail?.domainId"
              class="relative flex items-center py-1 h-10 rounded"
            >
              <div class="w-6 h-6 absolute left-2">
                <div class="relative flex items-center">
                  <SynAvatar
                    :name="domainByIds[workflowDetail?.domainId]?.name"
                    :type="'domain'"
                    custom-class="w-6 h-6"
                  />
                </div>
              </div>

              <div
                :title="
                  domainByIds[workflowDetail?.domainId]?.name
                    ? domainByIds[workflowDetail?.domainId]?.name
                    : ''
                "
                class="
                  text-sm text-gray-500
                  h-full
                  w-full
                  focus:bg-white focus:outline-none focus:border-transparent
                  flex
                  items-center
                  pl-10
                  pr-8
                  text-overflow-hidden-line
                "
              >
                <span
                  v-if="domainByIds[workflowDetail?.domainId]?.name"
                  class="truncate text-overflow-hidden-line"
                >
                  {{ domainByIds[workflowDetail?.domainId]?.name }}
                </span>
              </div>
            </div>
            <span v-else class="text-gray-500 italic text-sm pl-5">
              {{ $t('COMMON_LABEL_NOTHING') }}
            </span>
          </template>
        </TaskDetailField>
        <!-- Group -->
        <TaskDetailField v-if="workflowDetail?.groupId">
          <template #fieldName>
            <span>{{ $t('DOCUMENT_TITLE_GROUP') }}</span>
          </template>
          <template #body>
            <div
              v-if="workflowDetail?.groupId"
              class="relative flex items-center py-1 h-10 rounded w-48"
            >
              <div class="w-6 h-6 absolute left-2">
                <div class="relative flex items-center">
                  <SynAvatar
                    :src="
                      allGroupByIds[workflowDetail?.groupId]?.avatar?.replace(
                        'original',
                        'mini'
                      )
                    "
                    :name="allGroupByIds[workflowDetail?.groupId]?.name"
                    :type="'domain'"
                    custom-class="w-6 h-6"
                  />
                </div>
              </div>

              <div
                :title="
                  allGroupByIds[workflowDetail?.groupId]?.name
                    ? allGroupByIds[workflowDetail?.groupId]?.name
                    : ''
                "
                class="
                  text-sm text-gray-500
                  h-full
                  w-full
                  focus:bg-white focus:outline-none focus:border-transparent
                  flex
                  items-center
                  pl-10
                  pr-8
                  text-overflow-hidden-line
                "
              >
                <span
                  v-if="allGroupByIds[workflowDetail?.groupId]?.name"
                  class="truncate text-overflow-hidden-line"
                >
                  {{ allGroupByIds[workflowDetail?.groupId]?.name }}
                </span>
              </div>
            </div>
            <span v-else class="text-gray-500 italic text-sm pl-5">
              {{ $t('COMMON_LABEL_NOTHING') }}
            </span>
          </template>
        </TaskDetailField>
      </div>

      <!-- ALL STEPS -->
      <div
        v-if="!isLoading && !isEditStepList"
        class="flex-1 min-h-0 overflow-hidden flex flex-col"
      >
        <div class="px-4">
          <span class="font-semibold">
            {{ $t('WORKFLOW_LABEl_STEPS_IN_WORKFLOW') }} ({{
              workflowDetail?.steps?.length || 0
            }})
          </span>
        </div>
        <div
          class="w-full flex-1 min-h-0 overflow-auto small-scrollbar py-4 px-4"
        >
          <UpdateWorkflowSteps
            ref="updateWorkflowStepsRef"
            v-model:workflow-steps="workflowDetail.steps"
            v-model:draw-data="workflowDetail.drawData"
            :workflow-info="workflowDetail"
            view-mode="VIEW"
            @update-all-step="onPreCheckToSave(updateStepsSuccess)"
            @update:step="onSubmitUpdateStep"
          />
        </div>
      </div>
    </div>
  </div>
  <WorkflowAddNewModal
    v-if="isOpenAddNewModal"
    :view-mode="'CLONE'"
    :default-data="workflowDefault"
    @create-success="updateSuccess"
    @cancel="isOpenAddNewModal = false"
  />
  <WorkflowAddModal
    v-if="isEditInformation"
    :default-data="workflowDetail"
    :view-mode="'EDIT'"
    @update-success="updateSuccess"
    @cancel="isEditInformation = false"
  />
  <UpdateWorkflowStepsModal
    v-if="!isLoading && isEditStepList"
    :workflow-info="workflowDetail"
    @on-close="onCloseUpdate"
    @update-success="updateStepsSuccess"
  />
</template>
