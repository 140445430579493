<template>
  <div class="flex" :class="isLabel ? 'space-x-2' : ''">
    <div>
      <div
        v-if="urgency == 1"
        v-vig-tooltip="
          $t('TASK_CREATE_FORM_URGENT_IMPORTANT') || 'URGENT & IMPORTANT'
        "
        class="h-6 w-6 bg-red-300 flex-center rounded-full"
      >
        <SynIcon class="fill-red-500" name="high" custom-class="w-3 h-3" />
      </div>
      <div
        v-if="urgency == 2"
        v-vig-tooltip="$t('TASK_CREATE_FORM_URGENT') || 'URGENT'"
        class="h-6 w-6 bg-blue-200 flex-center rounded-full"
      >
        <SynIcon class="fill-red-500" name="high" custom-class="w-3 h-3" />
      </div>
      <div
        v-if="urgency == 3"
        v-vig-tooltip="$t('TASK_CREATE_FORM_IMPORTANT') || 'IMPORTANT'"
        class="h-6 w-6 flex-center rounded-full bg-red-200"
      >
        <SynIcon class="fill-red-500" name="square" custom-class="w-3 h-3" />
      </div>
      <div
        v-if="urgency == 4"
        v-vig-tooltip="$t('TASK_CREATE_FORM_COOL') || 'COOL'"
        class="h-6 w-6 flex-center rounded-full bg-blue-200"
      >
        <SynIcon class="fill-blue-500" name="square" custom-class="w-3 h-3" />
      </div>
    </div>
    <div>
      <label v-if="isLabel" class="capitalize text-sm">{{
        urgency == 1
          ? $t('TASK_CREATE_FORM_URGENT_IMPORTANT') || 'URGENT & IMPORTANT'
          : urgency == 2
          ? $t('TASK_CREATE_FORM_URGENT') || 'URGENT'
          : urgency == 3
          ? $t('TASK_CREATE_FORM_IMPORTANT') || 'IMPORTANT'
          : urgency == 4
          ? $t('TASK_CREATE_FORM_COOL') || 'COOL'
          : ''
      }}</label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    urgency: {
      type: Number,
      default: 0,
    },
    isLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
};
</script>
