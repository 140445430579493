<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
// import myProfileStore from '@/store/auth/my-profile';
// import settingStore from '@/store/setting';
// import domainStore from '@/store/scope';
// import dashboardStore from '@/store/dashboard';
import SettingLanguage from '@/ui/components/setting/SettingLanguage.vue';

import userStore from '@/embed/store/user-store';
import groupStore from '@/embed/store/group-store';
import taskStore from '@/embed/store/task-store';
import remoteConfigStore from '@/store/remoteConfig';

import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import EmbedUserById from '@/embed/modules/user/EmbedUserById.vue';
import embedOrganizationStore from '@/embed/store/organization-store';
import { getCompanyOwnName } from '@/ui/hooks/commonFunction';
import TabContainer from '@/ui/common/molecules/SynTab/TabContainer.vue';
import { clearLocalStorage } from '@/ui/hooks/storageHook';

const router = useRouter();
const _embedOrganizationStore = embedOrganizationStore();
const _userStore = userStore();
const _groupStore = groupStore();
const _taskStore = taskStore();
const _remoteConfigStore = remoteConfigStore();

const isLoading = ref(true);
onMounted(() => {
  initDashboardData();
});

const initDashboardData = async () => {
  try {
    isLoading.value = true;
    _embedOrganizationStore.getActiveOrganizationInfo();

    await _userStore.fetchCurrentLoginUser();
    const promise = [
      _userStore.getUsers(),
      _userStore.getDeactivatedUsers(),
      _groupStore.fetchMyGroups(),
      _taskStore.fetchDataConfig(),
      _taskStore.getCurrentTaskCycle(),
      _remoteConfigStore.fetchRemoteConfigs(),
    ];

    await Promise.all(promise);

    isLoading.value = false;
  } catch (error) {
    console.log('🚀 ', error);
    clearLocalStorage({
      isSwitchingOrg: false,
    });
    location.reload();
  }
};

const handleClickLogout = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: translate('HEADER_LOGOUT_CONFIRM_TITLE'),
    content: translate('HEADER_LOGOUT_CONFIRM_SUB'),
    confirmable: true,
    closeable: true,
  });
  const confirm = await ask();
  if (confirm) handleLogout();
};

const handleLogout = () => {
  isLoading.value = true;
  clearLocalStorage({
    isSwitchingOrg: false,
  });
  location.reload();
};

const myUserId = computed(() => _userStore.myInfo?.id);

const activeOrganization = computed(
  () => _embedOrganizationStore.activeOrganizationInfo
);

const currentTabId = ref(3);
const currentTabs = [
  {
    id: 1,
    name: 'Ticket',
    routerName: 'TicketTasksNew',
  },
  {
    id: 2,
    name: 'Histories',
    nameCode: 'COMMON_LABEL_HISTORIES',
    routerName: 'TicketTasksHistories',
  },
  {
    id: 3,
    name: 'My page',
    nameCode: 'COMMON_LABEL_MY_PAGE',
    routerName: 'EmbedPage',
  },
];

const onChangeTab = (tab) => {
  currentTabId.value = tab.id;

  router.push({ name: tab?.routerName });
};
</script>

<template>
  <div class="w-screen h-screen bg-transparent flex-center bg-white">
    <tab-container>
      <template #header>
        <div class="w-full flex-center bg-gray-200">
          <ul class="w-full flex-center cursor-pointer text-sm">
            <li
              v-for="tab in currentTabs"
              :key="tab.id"
              :class="
                tab.id == currentTabId
                  ? 'bg-white font-semibold text-current'
                  : 'text-current-900 bg-gray-200 hover:bg-gray-100'
              "
              class="hover-to-show__parent flex-center px-4 py-1"
              @click="onChangeTab(tab)"
            >
              {{ $t(tab.nameCode) || tab.name }}
            </li>
          </ul>
        </div>
      </template>
      <template #actions> <div></div> </template>
      <template #body>
        <syn-loading v-if="isLoading"></syn-loading>
        <div v-else class="py-2 h-full">
          <div class="flex flex-col overflow-hidden h-full divide-y">
            <header
              class="
                flex flex-wrap
                justify-between
                items-center
                px-3
                bg-white
                relative
              "
              style="height: 60px"
            >
              <div>
                <EmbedUserById :user-id="myUserId" />
              </div>
              <div class="flex items-center space-x-2">
                <div class="flex-center space-x-4 flex pl-6">
                  <syn-avatar
                    has-name
                    class="rounded p-2"
                    :src="activeOrganization?.logoUrl"
                    :name="getCompanyOwnName(activeOrganization?.name)"
                    type="group"
                    style="width: 14rem"
                    shape="square"
                  >
                    <template #name>
                      <div
                        class="
                          font-medium
                          text-current-800
                          overflow-hidden
                          flex-nowrap
                          text-ellipsis
                        "
                        style="white-space: nowrap; max-width: 10rem"
                      >
                        {{ getCompanyOwnName(activeOrganization?.name) }}
                      </div>
                    </template>
                  </syn-avatar>
                </div>
                <vig-dropdown class="hover:text-current bg-white shadow-none">
                  <template #dropdown-toggle>
                    <SynIcon class="border" name="dots-vertical" has-action />
                  </template>
                  <template #dropdown-menu>
                    <syn-dropdown-menu class="right-0 w-44">
                      <!-- language -->
                      <SettingLanguage />
                      <div
                        class="dropdown-item list-li text-red-500"
                        @click="handleClickLogout"
                      >
                        <div class="flex items-center">
                          <SynIcon name="logout" />
                          <span class="pl-2">{{
                            $t('COMMON_LABEL_LOGOUT') || 'Log out'
                          }}</span>
                        </div>
                      </div>
                    </syn-dropdown-menu>
                  </template>
                </vig-dropdown>
              </div>
            </header>
            <main
              class="
                flex-1
                h-full
                w-full
                overflow-x-hidden overflow-y-hidden
                bg-gray-100
              "
            >
              <slot></slot>
            </main>
          </div>
        </div>
      </template>
    </tab-container>
  </div>
</template>
