<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});
const beforeActivity = computed(() => props.beforeUpdate);
const afterActivity = computed(() => props.afterUpdate);
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 items-center justify-between pr-2 text-xs">
      <div
        v-if="beforeActivity?.IsPrivate !== null"
        class="flex items-center space-x-2"
      >
        <SynIcon
          :name="beforeActivity?.IsPrivate ? 'private' : 'global'"
          custom-class="w-3 h-3 fill-current"
        />
        <span>{{
          beforeActivity?.IsPrivate
            ? $t('COMMON_LABEL_PRIVATE')
            : $t('COMMON_LABEL_PUBLIC')
        }}</span>
      </div>
      <span v-else class="text-gray-500 text-xs">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
      <span>→</span>
    </div>
    <div class="flex w-1/2 justify-start pl-4 text-xs">
      <div
        v-if="afterActivity?.IsPrivate !== null"
        class="flex items-center space-x-2"
      >
        <SynIcon
          :name="afterActivity?.IsPrivate ? 'private' : 'global'"
          custom-class="w-3 h-3 fill-current"
        />
        <span>{{
          afterActivity?.IsPrivate
            ? $t('COMMON_LABEL_PRIVATE')
            : $t('COMMON_LABEL_PUBLIC')
        }}</span>
      </div>
      <span v-else class="text-gray-500 text-xs">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </div>
  </div>
</template>
