<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0,0,256,256"
  >
    <defs>
      <linearGradient
        id="color-1_joSAjc9l7dOp_gr1"
        x1="47.209"
        y1="52.361"
        x2="0.209"
        y2="3.236"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#e5a505"></stop>
        <stop offset="0.118" stop-color="#eaa904"></stop>
        <stop offset="0.595" stop-color="#f8b301"></stop>
        <stop offset="1" stop-color="#fdb700"></stop>
      </linearGradient>
      <linearGradient
        id="color-2_joSAjc9l7dOp_gr2"
        x1="20.049"
        y1="-4.488"
        x2="40.674"
        y2="19.637"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fed100"></stop>
        <stop offset="1" stop-color="#e36001"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(-19.2,-19.2) scale(1.15,1.15)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.33333,5.33333)">
          <path
            d="M37,45h-26c-1.657,0 -3,-1.343 -3,-3v-36c0,-1.657 1.343,-3 3,-3h19l10,10v29c0,1.657 -1.343,3 -3,3z"
            fill="url(#color-1_joSAjc9l7dOp_gr1)"
          ></path>
          <path
            d="M34,34h-20v-15h20zM18,30h12v-7h-12z"
            fill="#000000"
            opacity="0.05"
          ></path>
          <path
            d="M33.5,33.5h-19v-14h19zM17.5,30.5h13v-8h-13z"
            fill="#000000"
            opacity="0.07"
          ></path>
          <path
            d="M40,14h-11v-11h1l10,10z"
            fill="#000000"
            opacity="0.05"
          ></path>
          <path
            d="M40,13.5h-10.5v-10.5h0.5l10,10z"
            fill="#000000"
            opacity="0.07"
          ></path>
          <path
            d="M40,13h-10v-10z"
            fill="url(#color-2_joSAjc9l7dOp_gr2)"
          ></path>
          <path d="M33,33h-18v-13h18zM17,31h14v-9h-14z" fill="#f1f8e9"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
