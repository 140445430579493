import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import workflowStore from '@/store/workflow';
import WorkflowStepEntity, {
    IWorkflowStep,
} from '@/domain/entities/workflow/WorkflowStepEntity';

export interface IWorkflowEntity {
    id: string;
    isActive: boolean;
    orgId: number;
    groupId?: number;
    domainId?: number;
    departmentId?: number;
    code: string;
    name: string;
    description: string;
    creatorId: number;
    creationTime: string;
    lastModifierId: number;
    lastModificationTime: string;
    totalSteps: number;
    versionId: string;
    versionLatest: boolean;
    steps: IWorkflowStep[];

    totalTasks?: number;
    totalTaskUsed?: number;
    drawData: string;
}

export default class WorkflowEntity implements IWorkflowEntity {
    id: string;
    isActive: boolean;
    orgId: number;
    groupId?: number;
    domainId?: number;
    departmentId?: number;
    code: string;
    name: string;
    description: string;
    creatorId: number;
    creationTime: string;
    lastModifierId: number;
    lastModificationTime: string;
    totalSteps: number;
    versionId: string;
    versionLatest: boolean;
    steps: IWorkflowStep[];

    totalTaskUsed?: number;
    totalTasks?: number;
    drawData: string;

    constructor(data) {
        this.id = data?.id;
        this.isActive = data?.isActive;
        this.orgId = data?.orgId;
        this.groupId = data?.groupId;
        this.domainId = data?.domainId;
        this.departmentId = data?.departmentId;
        this.code = data?.code;
        this.name = data?.name;
        this.description = data?.description;
        this.creatorId = data?.creatorId;
        this.creationTime = data?.creationTime;
        this.lastModifierId = data?.lastModifierId;
        this.lastModificationTime = data?.lastModificationTime;
        this.totalSteps = data?.totalSteps;
        this.versionId = data?.versionId;
        this.versionLatest = data?.versionLatest;
        this.steps =
            data?.steps?.map((o) => {
                return {
                    ...o,
                    workflowId: this.id,
                };
            }) || [];

        this.totalTasks = data?.totalTasks;
        this.totalTaskUsed = data?.totalTaskUsed;
        this.drawData = data?.drawData || '';
    }

    async remove() {
        if (this.totalTasks && this.totalTasks > 0) {
            settingGlobalModal({
                type: 'notification',
                title:
                    translate('WORKFLOW_LABEl_STEP_CAN_NOT_REMOVE') ||
                    'Can not remove',
                content:
                    translate('WORKFLOW_LABEL_WORKFLOW_TOTAL_TASK_IN_USER', {
                        totalTask: this.totalTasks || '',
                    }) || '',
                closeable: true,
            });
            await ask();

            return Promise.resolve(false);
        }

        settingGlobalModal({
            type: 'notification',
            title: translate('COMMON_LABEL_CONFIRM'),
            content: translate('WORKFLOW_LABEL_CONFIRM_REMOVE', {
                name: this.name || '',
            }),
            cancelLabel: translate('COMMON_LABEL_CANCEL'),
            confirmLabel: translate('COMMON_LABEL_CONFIRM'),
            confirmable: true,
            closeable: true,
        });
        const confirmed = await ask();
        if (!confirmed) return false;
        return workflowStore().deleteWorkflow(this.id);
    }
    async updateSteps(newSteps) {
        this.steps = newSteps;
    }

    async checkName() {
        const workflowName = this.name;

        const isExistedWorkflow = workflowStore().checkNewWorkflowExisted({
            name: workflowName,
            departmentId: this.departmentId,
            domainId: this.domainId,
            groupId: this.groupId,
        });

        if (isExistedWorkflow) {
            settingGlobalModal({
                type: 'notification',
                title:
                    translate('WORKFLOW_LABEl_HAVE_EXISTED') ||
                    'Workflow has existed',
                content:
                    translate('WORKFLOW_LABEl_HAVE_EXISTED_DETAIL', {
                        workflowName:
                            workflowName?.replace(/\(\d+\)$/, '')?.trim() || '',
                    }) ||
                    "A Workflow with the same label already exists. Do you want to keep the label you've just inscribed?",
                cancelLabel: translate('COMMON_LABEL_CANCEL') || 'Discard',
                confirmLabel: translate('COMMON_LABEL_CONTINUE') || 'Create',
                confirmable: true,
                closeable: true,
            });
            return await ask();
        }

        return true;
    }

    async checkTotalSteps() {
        if (!this.steps || this.steps?.length == 0) {
            settingGlobalModal({
                type: 'notification',
                title: translate(
                    'WORKFLOW_LABEL_REQUIRES_AT_LEAST_2_STEPS_TITLE'
                ),
                content: translate('WORKFLOW_LABEL_REQUIRES_AT_LEAST_2_STEPS'),
                cancelLabel: translate('COMMON_LABEL_CANCEL') || 'Discard',
                closeable: true,
            });

            await ask();

            return Promise.resolve(false);
        }
        if (this.steps && this.steps?.length == 1) {
            settingGlobalModal({
                type: 'notification',
                title: translate(
                    'WORKFLOW_LABEL_REQUIRES_AT_LEAST_2_STEPS_TITLE'
                ),
                content: translate(
                    'WORKFLOW_LABEL_REQUIRES_AT_LEAST_2_STEPS_V2'
                ),
                cancelLabel: translate('COMMON_LABEL_CANCEL') || 'Discard',
                closeable: true,
            });

            await ask();

            return Promise.resolve(false);
        }
        return true;
    }
    async create(isClone?: boolean) {
        const isValidSteps = await this.checkTotalSteps();
        if (!isValidSteps) return Promise.resolve(false);

        const isValid = await this.checkName();

        if (!isValid) return Promise.resolve(false);

        if (this?.steps?.length > 0) {
            let index = 0;
            for await (const step of this.steps) {
                const _step = new WorkflowStepEntity(step);

                await _step.saveAttachment(isClone);

                this.steps[index] = _step;
                index++;
            }
        }

        const res = await workflowStore().createNewWorkflow({
            ...this,
        });
        return res?.result;
    }
    async updateWorkflowSteps(isApplyAllTask: boolean) {
        if (this?.steps?.length > 0) {
            let index = 0;
            for await (const step of this.steps) {
                const _step = new WorkflowStepEntity(step);

                await _step.saveAttachment();

                this.steps[index] = _step;
                index++;
            }
        }

        return WorkflowRepository.getInstance().updateWorkflowSteps(
            this.id,
            this.steps,
            this.drawData,
            !!isApplyAllTask
        );
    }
    async toggleActive() {
        return WorkflowRepository.getInstance().updateWorkflowActive(
            this.id,
            !this.isActive
        );
    }
}
