<template>
  <syn-modal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    style-body="py-4 px-2 flex flex-col"
    :disable-click-outside="true"
    :disable-esc-to-close="true"
    disable-auto-focus
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>{{ $t('GED_FOLDER_NEW') }}</div>
    </template>

    <template #body>
      <Form id="formNewFolder" class="p-3" @submit="onFormSubmit">
        <div class="mb-3 flex">
          <div class="w-32 text-gray-500">{{ $t('GED_FOLDER_IN') }}</div>
          <AddFileLocation
            :location="location"
            :is-drive-document="isDriveFolder"
            class="flex-1 font-semibold"
          />
        </div>
        <div class="flex items-center">
          <label
            for="formNewFolderFieldName"
            class="block w-32 text-gray-500"
            >{{ $t('GED_FOLDER_NAME') }}</label
          >
          <div class="flex-1">
            <Field
              id="formNewFolderFieldName"
              v-model="folderName"
              v-cusFocus="true"
              type="text"
              class="vig-input"
              name="fieldName"
              :rules="isValidFolderName"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              :validate-on-model-update="false"
            />
            <ErrorMessage
              name="fieldName"
              class="block mt-1 text-sm text-red-600"
            />
          </div>
        </div>
      </Form>
    </template>

    <template #footer>
      <div class="px-3 py-2 flex justify-end space-x-2">
        <vig-button ghost color="gray" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') }}
        </vig-button>
        <vig-button
          type="submit"
          form="formNewFolder"
          :processing="isSavingFolder"
        >
          <span>{{ $t('COMMON_LABEL_SAVE') }}</span>
        </vig-button>
      </div>
    </template>
  </syn-modal>
</template>

<script>
import { ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { translate } from '@/ui/plugins/i18n/myi18n';
import fileStorageStore from '@/store/fileStorage';
import $constants from '@/ui/plugins/constants';
import AddFileLocation from '@/ui/modules/ged/_commons/add-file-location/AddFileLocation.vue';

export default {
  components: { AddFileLocation, Form, Field, ErrorMessage },
  props: {
    location: {
      type: Object,
      default: null,
    },
    isDriveFolder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClose', 'onFolderAdded'],
  setup(props, { emit }) {
    const _fileStorageStore = fileStorageStore();

    const folderName = ref();

    const isSavingFolder = ref();

    const onFormSubmit = async () => {
      isSavingFolder.value = true;

      const newFolder = {
        name: folderName.value,
        category: $constants.FILE.CATEGORY.STORAGE,
        userId: props.location?.userId,
        groupId: props.location?.groupId,
        domainId: props.location?.domainId,
        projectId: props.location?.projectId,
        subProjectId: props.location?.subProjectId,
        folderId: props.location?.folderId,
        isDriveFolder: props.isDriveFolder,
      };

      try {
        emit('onFolderAdded', await _fileStorageStore.addFolder(newFolder));
      } catch (e) {
        console.log(e);
      }

      isSavingFolder.value = false;
    };

    return {
      folderName,
      isSavingFolder,
      onFormSubmit,
    };
  },
  methods: {
    isValidFolderName(value) {
      if (!value?.trim()) {
        return translate('GED_FOLDER_NAME_REQUIRED_MSG');
      }

      const limitChars = 200;
      if (value?.length > limitChars) {
        return translate('GED_FOLDER_NAME_MAX_LENGTH_MSG', {
          number: limitChars,
        });
      }

      const specialChars = /[<>:"\\/|?*]/;
      if (specialChars.test(value)) {
        return translate('GED_FOLDER_NAME_SPECIAL_CHARS_MSG');
      }

      return true;
    },
  },
};
</script>
