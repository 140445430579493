<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g id="draft">
        <path
          d="m60 44c0 5.1056-5 10-10 10a10.0472 10.0472 0 0 1 -10-9h-34a2 2 0 0 1 -2-2v-31a2 2 0 0 1 2-2h42a2 2 0 0 1 2 2v22a10.2774 10.2774 0 0 1 10 10z"
          fill="#fafafa"
          data-original="#fafafa"
        ></path>
        <path
          d="m44 18a4 4 0 1 1 -4-4 4 4 0 0 1 4 4z"
          fill="#e0e0e0"
          data-original="#e0e0e0"
        ></path>
        <path
          d="m5.3189 43.3708 14.8882-18.3913a1 1 0 0 1 1.5666.0153l6.2263 8.0052 6.2547-6.2547a1 1 0 0 1 1.45.0381l8.2953 9.2166s-4 2-4 9h-34a.9694.9694 0 0 1 -.6811-1.6292z"
          fill="#e0e0e0"
          data-original="#e0e0e0"
        ></path>
        <path
          d="m51 39v6a1 1 0 0 1 -2 0v-6a1 1 0 0 1 2 0zm-1 9a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm11-4a10.9958 10.9958 0 0 1 -21.8089 2h-33.1911a3.0033 3.0033 0 0 1 -3-3v-31a3.0033 3.0033 0 0 1 3-3h42a3.0033 3.0033 0 0 1 3 3v21.0507a11.0113 11.0113 0 0 1 10 10.9493zm-41.6069-19.5918a2.0162 2.0162 0 0 1 1.6162-.75 1.9839 1.9839 0 0 1 1.5732.8369l5.42 7.0887 5.5008-5.5008a1.9666 1.9666 0 0 1 1.524-.583 2.0044 2.0044 0 0 1 1.4512.7466l7.6848 8.446a10.9108 10.9108 0 0 1 4.8367-1.6419v-21.0507a1.001 1.001 0 0 0 -1-1h-42a1.0013 1.0013 0 0 0 -1 1v30.4zm15.5832 19.5918-14.0022-18.3164-14.6924 18.3164zm7.5927-8.0867-7.611-8.3679-5.7273 5.6454 8.2641 10.8092h1.5052a10.9607 10.9607 0 0 1 3.5689-8.0867zm16.431 8.0867a9 9 0 1 0 -9 9 9.01 9.01 0 0 0 9-9zm-24-26a5 5 0 1 1 5 5 5.0059 5.0059 0 0 1 -5-5zm2 0a3 3 0 1 0 3-3 3.0033 3.0033 0 0 0 -3 3z"
          fill="#9e9e9e"
          data-original="#9e9e9e"
        ></path>
      </g>
    </g>
  </svg>
</template>
