<script setup lang="ts">
import { computed, ref } from 'vue';
import _ from 'lodash';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import principleStore from '@/store/principle';

const props = defineProps<{
  userSelecteds: any[];
}>();

const emit = defineEmits(['eventChange']);

const _principleStore = principleStore();
const allUsers = computed(() => _principleStore.overviewWorkInfo);

const isLoading = ref(false);
const keySearch = ref('' as any);

const allUserList = computed(() => {
  const arrDefault = allUsers.value.filter(
    (user: any) =>
      !userSelectedList.value.some((el: any) => el.userId === user.userId)
  );

  let arrOnSearch: any;

  if (keySearch.value == '') {
    arrOnSearch = arrDefault;
  } else {
    arrOnSearch = arrDefault.filter((e: any) =>
      stringNomalize(e.fullName?.toLowerCase()).includes(
        stringNomalize(keySearch.value?.toLowerCase())
      )
    );
  }
  return arrOnSearch;
});

const userSelectedList = ref(JSON.parse(JSON.stringify(props.userSelecteds)));

const selectAllUser = () => {
  userSelectedList.value = [...allUsers.value];
};

const unSelectAllUser = () => {
  userSelectedList.value = [];
};

const resetSearch = () => {
  keySearch.value = '';
};

const onSelectUser = (user) => {
  userSelectedList.value.unshift(user);
  if (allUserList.value.length < 1) resetSearch();
  emit('eventChange', userSelectedList.value);
};

const removeUser = (user) => {
  _.remove(userSelectedList.value, (e: any) => e.userId == user.userId);
  emit('eventChange', userSelectedList.value);
};
</script>
<template>
  <div class="pt-3 pl-3 flex-1 flex flex-col min-h-0">
    <div class="flex items-center space-x-3">
      <div
        class="
          underline
          text-gray-500 text-sm
          hover:text-current-500
          cursor-pointer
        "
        @click="selectAllUser"
      >
        {{ $t('COMMON_LABEL_SELECT_ALL') }}
      </div>
      <div
        class="
          underline
          text-gray-500 text-sm
          hover:text-current-500
          cursor-pointer
        "
        @click="unSelectAllUser"
      >
        {{ $t('COMMON_LABEL_UN_SELECT_ALL') }}
      </div>
    </div>
    <div class="grid grid-cols-2 gap-3">
      <div class="w-full py-2">
        <VigSearchBox v-model="keySearch" autofocus @on-clear="resetSearch" />
      </div>
      <div class="py-3 px-4">
        <span class="font-medium">
          {{
            userSelectedList.length > 0
              ? $t('GED_NUM_SELECTED', {
                  number: userSelectedList.length || 0,
                })
              : $t('COMMON_LABEL_SELECT_MEMBER_NULL')
          }}
        </span>
      </div>
    </div>
    <div class="pl-2 flex-1 overflow-hidden grid gap-3 grid-cols-2">
      <div class="h-full small-scrollbar overflow-auto px-2">
        <template v-if="allUserList.length < 1">
          <SynAnimation
            name="searchIcon"
            stype="width: 100px; height: 100px"
            :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
          />
        </template>
        <template v-else>
          <div
            v-for="user in allUserList"
            :key="user"
            class="py-2 hover:bg-gray-100 cursor-pointer"
            @click="onSelectUser(user)"
          >
            <div class="flex items-center space-x-2 px-1 font-sans text-sm">
              <SynIcon
                name="checkbox"
                class="fill-current"
                has-action
                :is-active="user?.isSelected"
                custom-class="w-3.5 h-3.5"
              />
              <SynAvatarStatusActive
                :id="user?.userId"
                :avatar="user?.avatarUrl?.replace('original', 'mini')"
                :name="user?.fullName"
                :is-user="false"
                custom-class="w-6 h-6"
              >
              </SynAvatarStatusActive>
            </div>
          </div>
        </template>
      </div>

      <div class="h-full small-scrollbar overflow-auto pr-2">
        <template v-if="userSelectedList.length < 1">
          <div v-if="isLoading" class="flex-center space-x-3 mt-3">
            <div class="w-8 p-2 bg-gray-200 rounded-lg animate-pulse"></div>
            <div class="w-full bg-gray-200 p-2 rounded-lg animate-pulse"></div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="user in userSelectedList"
            :key="user"
            class="
              py-2
              cursor-pointer
              my-1
              p-0.5
              custom-display
              bg-current-50 bg-opacity-50
              rounded-md
            "
          >
            <div class="flex items-center px-2">
              <div class="flex-1 flex items-center space-x-2 font-sans text-sm">
                <div
                  class="flex w-full items-center space-x-2 font-sans text-sm"
                >
                  <SynAvatarStatusActive
                    :id="user?.userId"
                    :avatar="user?.avatarUrl?.replace('original', 'mini')"
                    :name="user?.fullName"
                    :is-user="false"
                    custom-class="w-6 h-6"
                  >
                  </SynAvatarStatusActive>
                </div>
              </div>

              <div class="flex items-center">
                <SynIcon
                  v-vig-tooltip="$t('COMMON_LABEL_DELETE')"
                  has-action
                  name="cancel"
                  color="gray"
                  custom-class="fill-gray-500 w-2 h-2"
                  @click="removeUser(user)"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item-domain:hover .item-cancel {
  display: block;
}

.item-cancel {
  display: none;
}

.custom-display:hover .action-text {
  display: block;
}

.action-text {
  display: none;
}
</style>
