<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="368.625px"
    height="368.625px"
    viewBox="0 0 368.625 368.625"
    style="enable-background: new 0 0 368.625 368.625"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M356.125,50.318H12.5c-6.903,0-12.5,5.597-12.5,12.5v242.988c0,6.902,5.597,12.5,12.5,12.5h343.625
			c6.902,0,12.5-5.598,12.5-12.5V62.818C368.625,55.916,363.027,50.318,356.125,50.318z M343.625,293.307H25V75.318h318.625V293.307
			z"
        />
        <path
          d="M57.755,134.201l120,73.937c2.01,1.239,4.283,1.858,6.557,1.858s4.547-0.619,6.557-1.858l120-73.937
			c5.877-3.621,7.707-11.322,4.086-17.199s-11.324-7.707-17.199-4.085l-113.444,69.896L70.869,112.917
			c-5.875-3.619-13.576-1.793-17.199,4.085C50.048,122.878,51.877,130.58,57.755,134.201z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
