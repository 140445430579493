<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 64 66"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M62 50a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H46a2 2 0 0 1-2-2V52a2 2 0 0 1 2-2v-4a6 6 0 0 1 6-6h4a6 6 0 0 1 6 6zm-14 4v8h12v-8zm10-4v-4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v4zm-27 8a2 2 0 0 1 0 4C13.88 62 0 48.12 0 31 0 13.88 13.88 0 31 0s31 13.88 31 31a2 2 0 0 1-4 0C58 16.088 45.912 4 31 4S4 16.088 4 31s12.088 27 27 27zm11-28a2 2 0 0 1 0 4H30a2 2 0 0 1-2-2V14a2 2 0 0 1 4 0v16z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>
