<script setup lang="ts">
import { computed, ref } from 'vue';
import domainStore from '@/store/scope';

const props = defineProps<{
  domainId: any;
  projectId: number;
  parentId: number;
}>();
const emit = defineEmits<{
  (e: 'onDeleteSuccess'): void;
  (e: 'onClose'): void;
}>();

const _domainStore = domainStore();

const isSubProject = computed(() => props.parentId);
const projectByIds = computed(() => _domainStore.projectByIds);
const projectDetail = computed(() => projectByIds.value[props.projectId]);

const initData = async () => {
  const res = await _domainStore.getTasksByDomain({
    domainId: props.domainId,
    projectId: isSubProject.value ? props.parentId : props.projectId,
    subProjectId: isSubProject.value ? props.projectId : null,
  });
  tasksByDomain.value = res?.result || [];
};
const tasksByDomain = ref([] as any[]);

const isDisableSubmitReject = computed(() => {
  return inputConfirmDelete.value !== 'delete';
});

const updateTaskPayload = ref({});

const isDeleting = ref(false);
const onSubmitReject = async () => {
  try {
    isDeleting.value = true;
    await _domainStore.rejectProject({
      projectId: props.projectId,
      relatedTasks: [...Object.values(updateTaskPayload.value)],
    });
    isDeleting.value = false;
    emit('onDeleteSuccess');
  } catch (error) {
    console.log('🚀 ~ onSubmitReject ~ error', error);
  }
};
const inputConfirmDelete = ref('');

initData();
</script>
<template>
  <div
    v-if="tasksByDomain?.length > 0 || projectDetail?.groups?.length > 0"
    class="flex-1 overflow-hidden flex flex-col p-8"
  >
    <div class="flex-center">
      <div class="flex-center h-16 w-16 ring ring-orange-300 rounded-full">
        <SynIcon
          name="warning-circle"
          custom-class="w-16 h-16 fill-orange-500"
        />
      </div>
    </div>
    <div class="flex-center">
      <span class="font-semibold">
        {{
          $t('DOMAIN_LABEL_REMOVE_SUB_DOMAIN') || 'Remove sub domain/project'
        }}
      </span>
    </div>
    <div class="text-center">
      <span class="text-sm text-gray-600">
        {{
          $t('DOMAIN_LABEL_CANNOT_DELETE_SUB_DOMAIN') ||
          'You can not delete this sub domain because there are active tasks related to the sub domain.'
        }}
      </span>
    </div>
    <div class="flex-center space-x-2 p-8">
      <template v-if="tasksByDomain?.length > 0">
        <div class="w-28 h-28 rounded-md flex-center flex-col border">
          <span class="text-4xl font-bold text-current">
            {{ tasksByDomain?.length }}
          </span>
          <span>tasks</span>
        </div>
      </template>
    </div>
  </div>
  <div v-else class="flex-1 overflow-hidden flex flex-col justify-between p-8">
    <div class="flex-center flex-col">
      <span class="font-semibold">
        {{
          $t('DOMAIN_LABEL_REMOVE_SUB_DOMAIN') || 'Remove sub domain/project'
        }}
      </span>
      <span class="text-xl font-semibold text-current">
        {{ projectDetail?.name }}
      </span>
    </div>
    <div class="pt-8">
      <span
        class="pt-2 text-sm text-gray-600"
        v-html="
          $t('DOMAIN_LABEL_TYPE_FOR_DELETE') ||
          'To confirm deletion, type delete in the text input field.'
        "
      >
      </span>
      <SynInput
        v-model="inputConfirmDelete"
        autofocus
        placeholder="delete"
        @enter="onSubmitReject"
      />
    </div>
    <div class="flex justify-end pt-2 items-center space-x-2">
      <SynButton
        :color="'red'"
        :label="$t('COMMON_LABEL_DELETE')"
        :disabled="isDisableSubmitReject"
        @click="onSubmitReject"
      />
    </div>
  </div>
</template>
