<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g data-name="Attache Image">
        <circle
          cx="8"
          cy="7.02"
          r="2"
          opacity="1"
          data-original="#303c42"
          class=""
        ></circle>
        <path
          d="M21.5 16h-5a1.5 1.5 0 0 0 0 3H20a.5.5 0 0 0 0-1h-3.5a.5.5 0 0 1 0-1h5a1.5 1.5 0 0 1 0 3h-5a2.5 2.5 0 0 1 0-5H22a.5.5 0 0 0 0-1h-5.5a3.5 3.5 0 0 0 0 7h5a2.5 2.5 0 0 0 0-5z"
          opacity="1"
          data-original="#303c42"
          class=""
        ></path>
        <path
          d="M16.5 13h3a.5.5 0 0 0 .5-.5V5a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h9.5a.5.5 0 0 0 .5-.5 4.5 4.5 0 0 1 4.5-4.5zM2 4h16a1 1 0 0 1 1 1v7h-1.81l-4.34-4.34a.5.5 0 0 0-.35-.15.5.5 0 0 0-.35.15L8.83 11a.5.5 0 0 0 0 .71L10 12.94a.5.5 0 0 1 0 .71.51.51 0 0 1-.71 0l-3-3a.5.5 0 0 0-.71 0L1 15.31V5a1 1 0 0 1 1-1z"
          opacity="1"
          data-original="#303c42"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
