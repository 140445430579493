import BaseGoogleApi from '@/ui/plugins/google/base-google-api';
import $constants from '@/ui/plugins/constants';
import { FileUrlBase } from '@/ui/common/constants/drive';

declare const gapi: any;

interface DriveFile {
    id?: string;
    name?: string;
    mimeType?: string;
    path?: string | null;
}

class GoogleDriveApi extends BaseGoogleApi {
    public initDriveClient() {
        this.setGapiConfig({
            apiKey: import.meta.env.VITE_GOOGLE_DRIVE_API_KEY,
            discoveryDocs: [
                'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
                'https://people.googleapis.com/$discovery/rest',
            ],
        });

        this.setAuthConfig({
            client_id: import.meta.env.VITE_GOOGLE_DRIVE_CLIENT_ID,
            scope: import.meta.env.VITE_GOOGLE_DRIVE_SCOPES,
            ux_mode: 'popup',
            callback: '',
        });
    }

    public async createFile(name: string, type: string, isShared: boolean) {
        const token = await this.initClientAndSetToken();
        if (!token) return null;

        const res = await gapi.client.drive.files.create({
            resource: {
                name,
                mimeType: type,
            },
        });

        const file = res?.result as DriveFile;
        if (file) file.path = this._prepareFilePath(file);

        if (file && isShared) {
            await gapi.client.drive.permissions.create({
                fileId: file.id,
                resource: {
                    type: 'anyone',
                    role: 'reader',
                },
            });
        }

        return file;
    }

    private _prepareFilePath(file: DriveFile) {
        switch (file?.mimeType) {
            case $constants.FILE.TYPE.GOOGLE_DOC:
                return `${FileUrlBase.Document}/d/${file?.id}`;
            case $constants.FILE.TYPE.GOOGLE_SHEET:
                return `${FileUrlBase.Spreadsheet}/d/${file?.id}`;
            case $constants.FILE.TYPE.GOOGLE_PRESENTATION:
                return `${FileUrlBase.Presentation}/d/${file?.id}`;
            default:
                return null;
        }
    }
}

export default new GoogleDriveApi();
