import TaskService from '@/application/services/task/TaskService';
import { ref } from 'vue';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import { rankDomainData } from '@/ui/common/molecules/SynAvatar/ranking-constants';
import getUserInfo from '@/ui/helpers/user-helper';
import datetimeComposable from '@/ui/composables/common/datetime-composable';

export default function finishedTasksComposable(departmentId?: number) {
    const isLoading = ref(false);
    const { formatDate } = datetimeComposable();

    const getAllDataForFinishedTaskPage = async () => {
        isLoading.value = true;
        await Promise.all([
            getFinishedTasks(),
            getFinishedTaskRankingByMember(),
            getFinishedTaskRankingByDomain(),
            getFinishedTaskOverview(),
        ]);
        isLoading.value = false;
    };

    const finishedTasks = ref<any[]>([]);

    const getFinishedTasks = async () => {
        const res: any = await TaskService.getInstance().getFinishedTasks(
            departmentId
        );

        if (!res?.result || res.result?.length == 0) return;

        finishedTasks.value = res.result.map((o) => {
            return {
                ...o,
                urgency: getUrgencyValue(o?.priority, o?.important),
                finishedTime:
                    new Date(o?.finishedTime).getTime() -
                    new Date(o?.finishedTime).getTimezoneOffset() * 60 * 1000,
            };
        });
    };

    const finishedTaskRankingByMember = ref<any>({});
    const getFinishedTaskRankingByMember = async () => {
        const res: any =
            await TaskService.getInstance().getFinishedTaskRankingByMember(
                departmentId
            );
        const dataList = res.result?.slice(0, 3);

        if (!dataList || dataList.length == 0) return;

        finishedTaskRankingByMember.value = dataList.reduce(
            (old, current, index) => {
                return {
                    ...old,
                    [index + 1]: {
                        ...current,
                        ...getUserInfo(current?.assigneeId),
                    },
                };
            },
            {}
        );
    };

    const finishedTaskRankingByDomain = ref<any[]>([]);

    const getFinishedTaskRankingByDomain = async () => {
        const res: any =
            await TaskService.getInstance().getFinishedTaskRankingByDomain(
                departmentId
            );
        const dataList = res.result?.slice(0, 4);

        if (!dataList || dataList.length == 0) return;
        let otherData: any = {};
        if (res.result?.length > dataList.length) {
            const otherDataList = res.result?.slice(4, res.result?.length);
            otherData =
                otherDataList?.length > 1
                    ? otherDataList.reduce(
                          (old, current) => {
                              const numberOfFinishedTask =
                                  old?.data + current?.numberOfTaskFinished;
                              return {
                                  ...current,
                                  name: 'Other',
                                  data: numberOfFinishedTask,
                                  color: rankDomainData[5]?.color,
                              };
                          },
                          { data: 0 }
                      )
                    : {
                          ...otherDataList[0],
                          name: otherDataList[0]?.domainName,
                          data: otherDataList[0]?.numberOfTaskFinished,
                          color: rankDomainData[5]?.color,
                      };
        }

        finishedTaskRankingByDomain.value = dataList?.map((o, index) => {
            return {
                ...o,
                name: o?.domainName,
                data: o?.numberOfTaskFinished,
                color: rankDomainData[index + 1]?.color,
            };
        });

        if (otherData?.data) finishedTaskRankingByDomain.value.push(otherData);
    };

    const finishedTaskLabels = ref<string[]>([]);
    const finishedTaskDatasets = ref<any[]>([]);
    const todayInfo = ref<any>({});
    const getFinishedTaskOverview = async () => {
        const res: any =
            await TaskService.getInstance().getFinishedTaskOverview(
                departmentId
            );

        const data = res?.result;
        if (!data || data.length == 0) return;

        finishedTaskLabels.value = data.map((o) =>
            formatDate(o?.date, 'day_month', {
                isIgnoreTimezone: true,
            })
        );
        finishedTaskDatasets.value = [
            {
                label: 'OVERVIEW_FINISHED_TASKS_PLAN_FINISHED_TODAY',
                data: data.map((o) => o.numberOfTaskToFinish),
                backgroundColor: '#FF8800',
                hoverBackgroundColor: '#666',
                minBarLength: 2,
            },
            {
                label: 'COMMON_LABEL_STATUS_FINISHED',
                data: data.map((o) => o.numberOfTaskFinished),
                backgroundColor: '#4BB0B2',
                hoverBackgroundColor: '#008d8d',
                minBarLength: 2,
            },
        ];

        const finishedToday = data.filter((o) => {
            const today = new Date();
            const checkDay = new Date(o.date);
            return (
                checkDay.getFullYear() === today.getFullYear() &&
                checkDay.getMonth() === today.getMonth() &&
                checkDay.getDate() === today.getDate()
            );
        });
        todayInfo.value = finishedToday?.length > 0 ? finishedToday[0] : {};
    };

    getAllDataForFinishedTaskPage();
    return {
        isLoading,
        todayInfo,
        finishedTasks,
        finishedTaskRankingByMember,
        finishedTaskRankingByDomain,
        finishedTaskDatasets,
        finishedTaskLabels,
        getAllDataForFinishedTaskPage,
        getFinishedTasks,
        getFinishedTaskRankingByMember,
        getFinishedTaskRankingByDomain,
        getFinishedTaskOverview,
    };
}
