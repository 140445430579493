<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M9.82.198C10.526.068 11.255 0 12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12c-.744 0-1.473-.068-2.18-.198a1 1 0 1 1 .36-1.967c.59.108 1.198.165 1.82.165 5.523 0 10-4.477 10-10S17.523 2 12 2c-.622 0-1.23.057-1.82.165A1 1 0 1 1 9.82.198zM7.365 1.97A1 1 0 0 1 7 3.337a10.056 10.056 0 0 0-3.384 3.21A1 1 0 1 1 1.94 5.453 12.056 12.056 0 0 1 6 1.606a1 1 0 0 1 1.367.365zm10.32 6.302a1 1 0 0 1 .04 1.414l-6.432 6.806a1.601 1.601 0 0 1-2.338 0l-2.683-2.838a1 1 0 0 1 1.454-1.374l2.398 2.537 6.148-6.505a1 1 0 0 1 1.414-.04zM10.41 15.12zM1.512 8.526a1 1 0 0 1 .747 1.2C2.09 10.456 2 11.216 2 12s.09 1.544.26 2.274a1 1 0 0 1-1.949.452C.108 13.85 0 12.936 0 12s.108-1.85.311-2.726a1 1 0 0 1 1.2-.748zm.72 8.636a1 1 0 0 1 1.385.292A10.056 10.056 0 0 0 7 20.663a1 1 0 1 1-1.002 1.731 12.056 12.056 0 0 1-4.058-3.848 1 1 0 0 1 .292-1.384z"
        clip-rule="evenodd"
        opacity="1"
        class=""
      ></path>
    </g>
  </svg>
</template>
