<script setup lang="ts">
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';

const props = defineProps<{
  message: ChatMessageModel;
  members: { [memberId: string]: any };
  isMyMessage: boolean;
  canUnpin: boolean;
}>();

defineEmits(['onUnpinClick']);

const myProfile = computed(() => myProfileStore().myProfile);

const isPinnedByMe = computed(
  () => props.message?.pinnedBy === myProfile.value.id?.toString()
);
</script>
<template>
  <div
    class="text-xs text-orange-600 mb-1"
    :class="{ 'text-right': isMyMessage }"
  >
    <SynIcon name="Pin" class="fill-orange-600" style="transform: scaleX(-1)" />

    <span>&nbsp;{{ $t('CHAT_MESSAGE_PINNED_BY') || 'Pinned by' }}</span>

    <span v-if="isPinnedByMe" class="lowercase"
      >&nbsp;{{ $t('COMMON_LABEL_YOU') || 'you' }}</span
    >

    <span v-else-if="members"
      >&nbsp;{{
        members[message?.pinnedBy]?.firstName ||
        members[message?.pinnedBy]?.name
      }}</span
    >

    <span v-if="canUnpin" class="text-gray-500">
      &nbsp;•&nbsp;
      <a href="javascript:;" @click="$emit('onUnpinClick')">
        {{ $t('CHAT_UNPIN') || 'Unpin' }}
      </a>
    </span>
  </div>
</template>
