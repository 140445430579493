<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue';
import registerStore from '@/store/auth/register';
import { SignUpInput } from '@/domain/entities/PayloadClass/AuthPayloadClass';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import {
  initGoogleCaptcha,
  destroyGoogleCaptcha,
} from '@/ui/components/layouts/logic/init-google-capcha';
import {
  clearLocalStorage,
  getLocalStorage,
  // setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import loginPageStore from '@/store/un-authenticate/login-store';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import { CountryCode } from 'libphonenumber-js';
import VoucherDetailModal from '@/ui/modules/voucher/VoucherDetailModal.vue';
import { GOOGLE_ANALYTICS_ELEMENT_ID } from '@/application/constants/register.const';
import organizationStore from '@/store/organization';
import remoteConfigStore from '@/store/remoteConfig';

const props = defineProps<{
  voucherCode: string;
  isRegisterWithUsername: boolean;
  isProcessD?: boolean;
  canBack?: boolean;
  isShowSelectPosition?: boolean;
  defaultPhoneNumber?: string;
  isHiddenAttach?: boolean;
}>();
const emit = defineEmits<{
  (e: 'onSentCodeSuccess', data: any): void;
  (e: 'onBack'): void;
  (e: 'onOpenAttachModal'): void;
}>();

const _loginPageStore = loginPageStore();
const _registerStore = registerStore();
const registerPayload = ref(
  new SignUpInput({
    phone:
      props.defaultPhoneNumber ||
      _registerStore.phone ||
      _loginPageStore.phone ||
      '',
  })
);

const supportingCountryCodeList = computed<string[]>(() => {
  return remoteConfigStore().supportingCountryCodeListForUsingPhoneNumber;
});
const responseErrorCode = ref('');
const isBlockLoginByCode = computed(() => {
  return (
    responseErrorCode.value == ErrorNotificationLogin.SEND_PHONE_CODE_BLOCKED
  );
});
const isBlockSendPhoneCode = computed(() => {
  return (
    responseErrorCode.value == ErrorNotificationLogin.LOGIN_BY_CODE_BLOCKED
  );
});

const initComponent = async () => {};

const isShowModalError = ref(false);

const isEuLocation = ref(false);
const initLocation = () => {
  const location = getLocalStorage(StorageConstant.LOCATION);

  isEuLocation.value = location == 'EU';
};

initLocation();

// FIRSTNAME
const {
  isTyping: isTypingFirstName,
  validationResult: validationResultFirstName,
  onValidation: onValidationFirstName,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
  ],
  750
);
// last name
const {
  isTyping: isTypingLastName,
  validationResult: validationResultLastName,
  onValidation: onValidationLastName,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
  ],
  0
);
// last name
const {
  isTyping: isTypingUsername,
  validationResult: validationResultUsername,
  onValidation: onValidationUsername,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
  ],
  0
);

// Phone
const {
  isTyping: isTypingPhone,
  isChecking: isCheckingPhone,
  validationResult: validationResultPhone,
  onValidation: onValidationPhone,
  reset: onResetValidationPhone,
} = atomFormInputComposables(
  [
    () => {
      return resultPhoneObject.value?.isValid
        ? ''
        : 'COMMON_INVALID_PHONE_NUMBER';
    },
    async (value) => {
      const resCheckExistPhone = await _registerStore.checkPhone(value);
      return resCheckExistPhone.existed
        ? 'COMMON_LABEL_REQUIRE_PHONE_IS_REGISTERED'
        : '';
    },
  ],
  0
);

const onClickSignIn = () => {
  _registerStore.phone =
    typeof registerPayload.value.phone == 'string'
      ? registerPayload.value.phone
      : '';
};

const resultPhoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});

const onBlurPhone = () => {
  if (!registerPayload.value?.phone || validationResultPhone.value?.isValid)
    return;

  onValidationPhone(registerPayload.value.phone);
};
const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  onResetValidationPhone();
  resultPhoneObject.value = phoneObj;
  if (!phoneObj || !phoneObj.number) return;

  registerPayload.value.phone = phoneObj?.number || '';
  if (phoneObj?.number?.length == 12)
    onValidationPhone(registerPayload.value.phone);

  responseErrorCode.value = '';

  const local = phoneObj?.countryCallingCode?.toLowerCase();
  currentLanguage.value = local == 'vn' ? 'vi' : local;
};
const isSendingVerificationCode = ref(false);
const sessionInfo = ref('');
const currentLanguage = ref('');
const resendCount = ref(0);

const onSendVerificationCode = async () => {
  if (
    isBlockLoginByCode.value ||
    isBlockSendPhoneCode.value ||
    isDisableRegisterButton.value ||
    isSendingVerificationCode.value
  )
    return;

  isSendingVerificationCode.value = true;

  const capchaTokenText = await initGoogleCaptcha(
    'g-recaptcha_register',
    'register_capcha-container'
  );

  responseErrorCode.value = '';

  try {
    const res = await _registerStore.sendVerificationCodeByPhoneNumber({
      phoneNumber: registerPayload.value.phone.trim(),
      captchaToken: capchaTokenText,
      type: 'sign_up',
      locale: currentLanguage.value,
    });
    sessionInfo.value = res?.result.sessionInfo;
    resendCount.value = 0;
    emit('onSentCodeSuccess', {
      payload: registerPayload.value,
      language: currentLanguage.value,
      sessionInfo: sessionInfo.value,
    });
  } catch (response: any) {
    if (
      response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED &&
      resendCount.value < 6
    ) {
      onSendVerificationCode();
      resendCount.value++;

      return;
    }
    parserErrorMessage(response);
  } finally {
    isSendingVerificationCode.value = false;
  }
};

const parserErrorMessage = (response) => {
  const errorCode = response?.error?.message;

  if (errorCode == ErrorNotificationLogin.LOGIN_BY_CODE_BLOCKED) {
    responseErrorCode.value = 'SIGNUP_BY_CODE_BLOCKED';
    return;
  }

  if (errorCode in ErrorNotificationLogin) {
    responseErrorCode.value = ErrorNotificationLogin[errorCode];
    isShowModalError.value =
      errorCode == ErrorNotificationLogin.SEND_VERIFICATION_FAILED;

    return;
  }

  responseErrorCode.value = 'SEND_VERIFICATION_FAILED';
};

onMounted(() => {
  if (registerPayload.value.phone)
    onValidationPhone(registerPayload.value.phone);
});

onUnmounted(() => {
  destroyGoogleCaptcha();
});

const isDisableRegisterButton = computed(
  () =>
    // first name
    isTypingFirstName.value ||
    !validationResultFirstName.value?.isValid ||
    // first name
    isTypingLastName.value ||
    !validationResultLastName.value?.isValid ||
    // first name
    (props.isRegisterWithUsername &&
      (isTypingUsername.value || !validationResultUsername.value?.isValid)) ||
    (!props.isRegisterWithUsername &&
      (isTypingPhone.value || !validationResultPhone.value?.isValid)) ||
    // block captcha
    isBlockLoginByCode.value ||
    isBlockSendPhoneCode.value
);

const isLoadingRefresh = ref(false);
const onRefreshPage = async () => {
  isLoadingRefresh.value = true;
  clearLocalStorage({ isSwitchingOrg: false });
  await window.location.reload();
  isLoadingRefresh.value = false;
};

const onApplyVoucherCode = () => {
  isOpenVoucherDetail.value = false;
};

const isOpenVoucherDetail = ref(true);
const jobTitles = computed<any[]>(() => {
  return organizationStore().jobTitles;
});

const onSelectJobTitle = (jobTitle) => {
  registerPayload.value.jobTitle = jobTitle?.code;
  registerPayload.value.jobTitleName = jobTitle?.name;
};
const onJobTitle = () => {
  registerPayload.value.jobTitle = '';
  registerPayload.value.jobTitleName = '';
};
initComponent();
</script>

<template>
  <section class="mt-4">
    <div
      class="
        relative
        flex flex-col
        min-w-0
        break-words
        w-full
        mb-6
        rounded-lg
        border-0
        lg:items-center
      "
    >
      <div class="flex-auto py-10 pt-0 w-full">
        <div class="relative flex space-x-3 mb-3 mt-3">
          <SynFormInput :error-message="validationResultLastName.message">
            <SynInput
              v-model="registerPayload.lastName"
              autofocus
              class="w-full"
              input-type="text"
              :max-length="50"
              :placeholder="$t('USERMANAGEMENT_TABLE_LAST_NAME') + '*'"
              @update:model-value="onValidationLastName"
            />
          </SynFormInput>
          <SynFormInput :error-message="validationResultFirstName.message">
            <SynInput
              v-model="registerPayload.firstName"
              class="w-full"
              input-type="text"
              :max-length="50"
              :placeholder="$t('USERMANAGEMENT_TABLE_FIRST_NAME') + '*'"
              :error-message="validationResultFirstName.message"
              @update:model-value="onValidationFirstName"
            />
          </SynFormInput>
        </div>

        <div
          v-if="isProcessD && isShowSelectPosition"
          class="relative w-full mb-3"
        >
          <SynFormInput>
            <VigSelect
              :placeholder="$t('REGISTER_LABEL_SELECT_POSITION_TITLE')"
              key-search="name"
              key-label="name"
              toggle-class="py-1 text-sm "
              :label-value="registerPayload.jobTitleName"
              :origin-options="jobTitles"
              @on-choose="onSelectJobTitle"
              @on-clear="onJobTitle"
            />
          </SynFormInput>
        </div>
        <div class="relative w-full mb-3">
          <SynFormInput
            v-if="isRegisterWithUsername"
            :error-message="validationResultUsername.message"
          >
            <SynInput
              v-model="registerPayload.username"
              class="w-full"
              input-type="text"
              :max-length="50"
              :placeholder="$t('USERMANAGEMENT_TABLE_LAST_NAME') + '*'"
              @update:model-value="onValidationUsername"
            />
          </SynFormInput>
          <SynFormInput
            v-else
            :is-checking="isCheckingPhone"
            :is-valid="validationResultPhone.isValid"
            :error-message="
              responseErrorCode
                ? $t(responseErrorCode)
                : validationResultPhone.message
            "
          >
            <AtomPhoneInput
              :value="registerPayload.phone"
              :input-options="{
                placeholder: `${$t('COMMON_LABEL_MOBILE_PHONE')}*`,
              }"
              :auto-format="false"
              :auto-default-country="false"
              :available-countries="supportingCountryCodeList"
              @change="onChangePhone"
              @blur="onBlurPhone"
            />
          </SynFormInput>
        </div>

        <div class="flex items-center justify-between">
          <AtomTooltip>
            <template #trigger>
              <span
                class="
                  text-sm
                  w-max
                  text-current-500
                  hover:text-current-700
                  cursor-pointer
                "
              >
                {{ $t('REGISTER_WHY_ASK_PHONE_TITLE') }}
              </span>
            </template>
            <template #body>
              <div class="p-4 w-72 bg-white text-current-800">
                <ul
                  class="pl-4 space-y-2"
                  style="list-style: circle"
                  v-html="$t('REGISTER_EXP_REQUIRED_PHONE')"
                ></ul>
              </div>
            </template>
          </AtomTooltip>

          <template v-if="voucherCode">
            <div class="flex-center space-x-2 rounded-md px-2">
              <SynIcon name="ticket-voucher" custom-class="h-8 w-8" />
              <span class="text-sm text-current-500 font-semibold">
                {{ voucherCode }}
              </span>
            </div>
          </template>
        </div>

        <div class="text-center pt-6">
          <div id="register_capcha-container" class="flex items-center">
            <AtomButton
              v-if="canBack"
              type="TEXT"
              color="gray"
              class="w-1/2"
              :label="$t('COMMON_LABEL_PREVIOUS')"
              @click="$emit('onBack')"
            />
            <div class="flex-1">
              <SynButton
                :id="
                  GOOGLE_ANALYTICS_ELEMENT_ID.REGISTER
                    .register_by_phone_submit_form_btn
                "
                class="w-full m-auto"
                :is-loading="isSendingVerificationCode"
                :disabled="isDisableRegisterButton"
                :label="$t('COMMON_LABEL_SIGN_UP')"
                @click.stop="onSendVerificationCode"
              />
            </div>
          </div>
          <!-- CONFIRM AGREE WITH TICTOP PRIVACY -->
          <div class="flex flex-col space-y-2 text-gray-600 text-sm pt-4">
            <span
              class="text-left italic text-sm"
              v-html="$t('REGISTER_LABEL_AGREE_TO_THE_TERMS_OF_USE')"
            >
            </span>
          </div>
          <div class="pt-4 text-base flex-center gap-2">
            <template v-if="isHiddenAttach">
              <label class="text-gray-500 text-sm">{{
                $t('COMMON_LABEL_QUESTION_LOGIN') + ' '
              }}</label>
              <RouterLink :to="{ name: 'Login' }">
                <label
                  class="
                    font-medium
                    text-current-600
                    cursor-pointer
                    hover:text-current-200
                    text-sm
                  "
                  @click="onClickSignIn"
                >
                  {{ $t('COMMON_MODULE_LOGIN') }}
                </label>
              </RouterLink>
            </template>
            <template v-else>
              <span class="text-gray-500 text-sm">{{
                $t('COMMON_LABEL_OR')
              }}</span>
              <router-link :to="{ name: 'Login' }">
                <span
                  class="
                    font-medium
                    text-current-600
                    cursor-pointer
                    hover:text-current-200
                    text-sm
                  "
                  @click="onClickSignIn"
                >
                  {{ $t('COMMON_MODULE_LOGIN') }}
                </span>
              </router-link>
              <span>-</span>
              <span
                class="
                  font-medium
                  text-current-600
                  cursor-pointer
                  hover:text-current-200
                  text-sm
                "
                @click="$emit('onOpenAttachModal')"
              >
                {{ $t('COMMON_LABEL_JOIN_THE_ORGANIZATION') }}
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SynQuestionModal
    :visible="isShowModalError"
    :show-confirm-btn="false"
    :delete-label="$t('COMMON_LABEL_TRY_AGAIN')"
    :is-loading="isLoadingRefresh"
    show-delete-btn
    header-text="Oops!!"
    @cancel="isShowModalError = false"
    @on-confirm="onRefreshPage"
  >
    <template #content>
      <syn-animation name="oops" stype="width: 200px;" :loop="false" />
      <div class="text-center text-red-700">
        {{ $t(responseErrorCode) }}
      </div>
    </template>
  </SynQuestionModal>
  <VoucherDetailModal
    v-if="isOpenVoucherDetail && voucherCode"
    :voucher-code="voucherCode"
    :plan-id="'PRO'"
    need-to-update
    @apply="onApplyVoucherCode"
  />
</template>
<style>
.page-template-default {
  overflow-x: hidden;
}
</style>
