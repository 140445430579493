<script setup lang="ts">
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';
import { computed, ref } from 'vue';
import SynInput from '@/ui/common/atoms/SynInput/SynInput.vue';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import groupStore from '@/store/group';
import departmentStore from '@/store/department';

const _groupStore = groupStore();
const _departmentStore = departmentStore();

const emit = defineEmits(['cancel', 'submitGenerateGroup']);
const props = defineProps<{
  departmentId: any;
}>();

const onCancel = () => {
  emit('cancel', false);
};
const groupName = ref('');
const isLoading = computed(() => _groupStore.isLoading);

const submitGenerateGroup = async () => {
  const res = await _groupStore.addDefaultDepartmentGroup(
    props.departmentId,
    groupName.value
  );
  if (!res) return;
  const updateObj = {
    avatar: res.avatar,
    avatarUrl: res.avatarUrl,
    code: res.code,
    id: res.id,
    name: res.name,
  };
  _departmentStore.updateAllDepartmentById(
    props.departmentId,
    'relatedGroup',
    updateObj
  );
  emit('submitGenerateGroup');
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/4"
    style-body="py-4 px-3 flex flex-col"
    :disable-click-outside="true"
    :disable-esc-to-close="true"
    disable-auto-focus
    @cancel="onCancel"
  >
    <template #header>
      <div class="px-1">{{ $t('DEPARTMENT_LABEL_GENERATE_GROUP') }}</div>
    </template>
    <template #body>
      <div class="flex items-center px-2">
        <SynInput
          v-model="groupName"
          :placeholder="$t('ORG_SETUP_PLACEHOLDER_NAME_GROUP')"
          autofocus
        />
      </div>
    </template>

    <template #footer>
      <div class="px-5 py-3 flex justify-end space-x-2">
        <SynButton
          color="gray"
          type-text
          :label="$t('COMMON_LABEL_CANCEL')"
          @click="onCancel"
        />
        <SynButton
          :disabled="!groupName?.trim()"
          :label="$t('DEPARTMENT_LABEL_BUTTON_GENERATE_GROUP')"
          :is-loading="isLoading"
          @click="submitGenerateGroup"
        />
      </div>
    </template>
  </SynModal>
</template>
