import BaseRepository from '@/base/repositories/BaseRepository';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import appStore from '@/store/app';

export default class CatalogRepository {
    private static instance: CatalogRepository;

    constructor() {}

    public static getInstance(): CatalogRepository {
        if (!CatalogRepository.instance) {
            // Get from local storage
            CatalogRepository.instance = new CatalogRepository();
        }

        return CatalogRepository.instance;
    }

    getCheckEmailPhone(emailOrPhone: any): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/CheckEmailPhone?emailOrPhone=${emailOrPhone}`,
            method: 'get',
        });
    }
    checkEmailPhoneInOrganization(emailOrPhone) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/CheckEmailPhoneInOrganization?emailOrPhone=${emailOrPhone}`,
            method: 'get',
        });
    }
    getCheckCode(code) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/CheckOrgCode?code=${code}`,
            method: 'get',
        });
    }
    getUserOrganizations() {
        const lang = appStore().language || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetUserOrganizations?lang=${lang}`,
            method: 'get',
        });
    }
    onFinishWithPersonalMode() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/FinishSetupPersonalWorkspace`,
            method: 'post',
        });
    }
    updateAndFinishSetupOrganization(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/UpdateAndFinishSetupOrganization`,
            method: 'post',
            data,
        });
    }
    updateP(data: number) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/P`,
            method: 'post',
            params: { p: data },
        });
    }
    getOrganizationConfigDefault() {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/OrganizationDefaultConfigs?lang=${lang
                .slice(0, 2)
                .toLowerCase()}`,
            method: 'get',
        });
    }
    getUserProfile() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetUserProfile`,
            method: 'get',
        });
    }
    saveOrganizationSetting(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/SaveOrganizationSetting`,
            method: 'post',
            data,
        });
    }
    registerNewOrganization(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Catalog/RegisterNewOrganization`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_CATALOG_REGISTER_ORGANIZATION_SUCCESS'
                ),
            }
        );
    }
    saveTeam(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/SaveTeam`,
            method: 'post',
            data,
        });
    }
    saveTeamDraft(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/SaveDraftTeam`,
            method: 'post',
            data,
        });
    }
    getDraftTeam() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetDraftTeam`,
            method: 'get',
        });
    }
    getOrganizationInfo(id) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetOrganizationInfo?organizationId=${id}`,
            method: 'get',
        });
    }
    getOrganizationAttachmentCode() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetOrganizationAttachmentCode`,
            method: 'get',
        });
    }
    generateOrganizationAttachmentCode() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GenerateOrganizationAttachmentCode`,
            method: 'get',
        });
    }
    updateOrganization(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Catalog/UpdateOrganization`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_CATALOG_UPDATE_ORGANIZATION_SUCCESS'
                ),
            }
        );
    }

    uploadAvatarAws(file) {
        const formData = new FormData();
        formData.append('fileData', file);
        return BaseRepository.requestWithAuthorize({
            url: `Media/UploadAvatarAws`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-organization-code': 'temp',
            },
        });
    }

    skipAddMember() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/SkipAddMember`,
            method: 'post',
        });
    }
    inviteUser(data) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/InviteUser?lang=${lang}`,
            method: 'post',
            data,
        });
    }
    getInvitedUser(params) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetInvitedUser`,
            method: 'get',
            params,
        });
    }
    /*
  Data:
  {
    "firstName": "string",
    "lastName": "string",
    "phone": "string",
    "email": "string",
    "isFixedPhone": true,
    "isFixedEmail": true,
    "inviteToken": "string"
  }
  */
    acceptedInvitation(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/AcceptedInvitation`,
            method: 'post',
            data,
        });
    }
    rejectedInvitation(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/RejectedInvitation`,
            method: 'post',
            data,
        });
    }

    attachOrganizationByCode(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/AttachOrganizationByCode`,
            method: 'post',
            data,
        });
    }

    checkOrganizationAttachCode(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/CheckOrganizationAttachCode2`,
            method: 'post',
            data,
        });
    }

    getPendingUsers() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetPendingUsers`,
            method: 'get',
        });
    }

    updateInvitations(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Catalog/UpdateInvitations`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_UPDATE_INVITATION_SUCCESS'
                ),
            }
        );
    }

    resendInvitation(data) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/ResendInvitation`,
            method: 'post',
            params: {
                lang: lang,
            },
            data,
        });
    }

    cancelInvitation(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/CancelInvitation`,
            method: 'post',
            data,
        });
    }

    getMyPendingInvitation() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetMyPendingInvitation`,
            method: 'get',
        });
    }

    checkUserInstallApp() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/CheckUserInstallApp`,
            method: 'get',
        });
    }
    getAllMyPendingInvitation() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetAllMyPendingInvitation`,
            method: 'get',
        });
    }
    usingTrialPackage(planId: string) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/UsingTrialPackage`,
            method: 'post',
            params: {
                planId,
            },
        });
    }
    payImmediately(planId: string) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/PayImmediately`,
            method: 'post',
            params: {
                planId,
            },
        });
    }
    getPaymentBonus() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetPaymentBonus`,
            method: 'post',
        });
    }

    searchAnyUsersByPhoneAndEmail(phone: string, email?: string) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/SearchChatUsers`,
            method: 'get',
            params: { phone, email },
        });
    }
}
