<script setup lang="ts">
import { computed, ref } from 'vue';
import FileIcon from '@/ui/modules/ged/_commons/file-icon/FileIcon.vue';

const props = defineProps({
  contentType: {
    type: String,
    default: '',
  },
  path: {
    type: String,
    default: '',
  },
  isNote: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'normal',
  },
});

defineEmits(['onSelect']);

const contentTypeImage = [
  'image/bmp',
  'image/cis-cod',
  'image/gif',
  'image/ief',
  'image/jpeg',
  'image/pipeg',
  'image/svg+xml',
  'image/tiff',
  'image/x-cmu-raster',
  'image/x-cmx',
  'image/x-icon',
  'image/x-portable-anymap',
  'image/x-portable-bitmap',
  'image/png',
  'image/webp',
  'image/apng',
  'image/avif',
];

const isImage = computed(
  () =>
    (props.contentType?.includes('image') &&
      contentTypeImage.includes(props?.contentType)) ||
    props.isNote
);
const isAudio = computed(() => props.contentType?.includes('audio'));
const isErrorImage = ref(false);
</script>

<template>
  <div class="m-0.5 relative" :class="customClass">
    <div
      v-if="isAudio"
      class="flex space-x-4 flex-wrap h-full cursor-pointer rounded"
      @click="$emit('onSelect')"
    >
      <VigAudio
        :path="path"
        type="view"
        :size="'custom'"
        :readonly="false"
        @click="$emit('onSelect')"
      />
    </div>
    <div
      v-else
      class="flex flex-col rounded hover:opacity-100 w-11 h-11"
      @click="onClickFile"
    >
      <article
        tabindex="0"
        class="
          group
          hasImage
          w-full
          h-full
          rounded-md
          focus:outline-none focus:shadow-outline
          bg-gray-100
          cursor-pointer
          relative
          shadow-sm
          syn-upload-preview
        "
      >
        <VigImage
          v-if="isImage && !isErrorImage"
          class="w-full h-full sticky object-cover rounded-md bg-fixed shadow"
          :path="path"
          size="small"
          @error="
            (value) =>
              !contentType?.includes('svg') ? (isErrorImage = value) : ''
          "
          @click="$emit('onSelect')"
        />
        <div
          v-else
          class="
            w-full
            h-full
            sticky
            object-cover
            rounded-md
            bg-fixed
            flex-center
            bg-gray-200
            shadow
            flex flex-col
          "
          @click="$emit('onSelect')"
        >
          <SynIcon
            v-if="isImage && isErrorImage"
            name="image-not-viewer"
            custom-class="w-6 h-6"
          />
          <FileIcon
            v-if="!isImage"
            :file-type="contentType"
            class-name="w-6 h-6"
          />
        </div>

        <div
          v-if="isNote"
          class="
            w-6
            h-6
            rounded-full
            absolute
            -right-2
            -top-2
            bg-gray-100
            flex
            items-center
            justify-center
            shadow
            z-10
            cursor-default
          "
        >
          <SynIcon name="noter" is-active class="fill-orange-500" />
        </div>
      </article>
    </div>
  </div>
</template>

<style scoped>
.syn-upload-preview .remove-button {
  display: none;
}

.syn-upload-preview .show-when-hover-button {
  display: none;
}

.syn-upload-preview:hover .remove-button {
  display: flex;
}

.syn-upload-preview:hover .show-when-hover-button {
  display: flex;
}

.syn-upload-preview:hover .type-button {
  display: none;
}
</style>
