<script setup lang="ts">
import { ref, computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import groupStore from '@/store/group';
import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import DepartmentSettingForm from '@/ui/modules/departments/components/DepartmentSettingForm.vue';
import DepartmentUpdateMemberModal from '@/ui/modules/departments/components/DepartmentUpdateMemberModal.vue';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';
import { GroupMemberRole } from '@/ui/common/constants/constant';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import { AdminType } from '@/ui/common/constants/constant';
import departmentStore from '@/store/department';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { IDepartmentDetail } from '@/application/types/department/department.types';
import DepartmentHasNotAdminWarning from '@/ui/modules/departments/components/DepartmentHasNotAdminWarning.vue';

const props = defineProps<{
  departmentId: any;
}>();
const emit = defineEmits([
  'updateValue',
  'isUpdateAvt',
  'groupDetailChanged',
  'deleteSuccess',
]);
const _departmentStore = departmentStore();

const allDepartmentByIds = computed(() => _departmentStore.allDepartmentByIds);
const departmentDetail = computed<IDepartmentDetail>(
  () => allDepartmentByIds.value[props.departmentId]
);

const _groupStore = groupStore();
const isOpenProfileUser = ref(false);
const alertAdminSystemNotHasPermission = ref(false);
const showAlertRemainOneAdmin = ref(false);
const showAlertSystemGroup = ref(false);
const currentUser = computed(() => myProfileStore().myProfile);
const isUpdateSuccess = ref(false);
const userId: any = ref();
const showDeleteModal = ref(false);
const isLeftUser = ref(false);
const profileId = ref();
const departmentTabKeys = {
  INFO: 'INFO',
  MEMBER: 'MEMBER',
  SETTING: 'SETTING',
};
const departmentTabs = ref({
  [departmentTabKeys.INFO]: {
    key: departmentTabKeys.INFO,
    isEdit: false,
    isSave: false,
    label: 'TASK_CREATE_GROUP_FORM_INFO_GROUP',
  },
  [departmentTabKeys.MEMBER]: {
    key: departmentTabKeys.MEMBER,
    isEdit: false,
    isSave: false,
    label: 'GROUP_LABEL_MEMBER',
  },
  [departmentTabKeys.SETTING]: {
    key: departmentTabKeys.SETTING,
    isEdit: false,
    isSave: false,
    label: 'HEADER_LABEL_SETTINGS',
  },
});

// watch(
//   () => props.departmentId,
//   () => {
//     console.log('🚀 Hyrin ~ props.departmentId', props.departmentId);
//     initComponent();
//   }
// );

const onEdit = (key) => {
  departmentTabs.value[key].isEdit = true;
};

const onCancelInfo = (key) => {
  departmentTabs.value[key].isEdit = false;
};

const onSaveInfo = async (key) => {
  departmentTabs.value[key].isSave = true;

  await _departmentStore.changeNameAndDescription({
    id: departmentDetail.value?.id,
    name: departmentDetail.value?.name,
    description: departmentDetail.value?.description,
  });

  departmentTabs.value[key].isSave = false;
  departmentTabs.value[key].isEdit = false;
};

const onSaveAvatar = async (key, file) => {
  departmentTabs.value[key].isSave = true;
  if (file) {
    const fileRes = await _groupStore.uploadAvatarAws(file);
    await _departmentStore.changeAvatar({
      departmentId: departmentDetail.value?.id,
      avatar: fileRes?.path,
    });
  }

  isUpdateSuccess.value = true;
  emit('isUpdateAvt', true);
  departmentTabs.value[key].isSave = false;
  departmentTabs.value[key].isEdit = false;
};

const canUpdateMemberGroup = computed<boolean>(() => {
  const currentMember = departmentDetail.value?.departmentUsers.find(
    (e) => e.id == myProfileStore().myProfile?.id
  );

  return (
    myProfileStore().isAdmin ||
    myProfileStore().isOwner ||
    currentMember?.role == GroupMemberRole.Admin
  );
});

// member
const showModalRemove = (user) => {
  if (!canUpdateMemberGroup.value) {
    alertAdminSystemNotHasPermission.value = true;
    return;
  } else if (
    user.role == GroupMemberRole.Admin &&
    departmentDetail.value?.departmentUsers.filter(
      (e) => e.role == GroupMemberRole.Admin
    ).length <= 1
  ) {
    showAlertRemainOneAdmin.value = true;
    return;
  }
  showDeleteModal.value = true;
  userId.value = user.userId;
  emit('updateValue');
};

const onDeleteUser = async (user) => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('DEPARTMENT_LABEL_REMOVE_USER_CONFIRM'),
    confirmable: true,
    closeable: true,
  });
  const confirmed = await ask();

  if (!confirmed) return;

  await _departmentStore.removeMembers({
    departmentId: departmentDetail.value?.id,
    memberIds: [user?.userId],
  });
};

const onUpdateMemberSuccess = () => {
  departmentTabs.value[departmentTabKeys.MEMBER].isSave = false;
  departmentTabs.value[departmentTabKeys.MEMBER].isEdit = false;
};

// profile
const chooseViewProfile = async (id) => {
  profileId.value = id;
  isOpenProfileUser.value = true;
};

const updateSettings = async (departmentSetting: {
  visibleForAllMember: boolean;
  visibleStatusWorking: boolean;
  visibleTimeWorking: boolean;
  visiblePersonalData: boolean;
}) => {
  console.log('🚀 Hyrin ~ settings', departmentSetting);
  await _departmentStore.updateSettings({
    departmentId: departmentDetail.value?.id,
    departmentSetting,
  });
};

const changeMemberRole = async (userId, role: GroupMemberRole) => {
  await _departmentStore.updateRoleUsers({
    departmentId: props.departmentId,
    memberInfos: [
      {
        userId,
        role,
      },
    ],
  });
};
</script>
<template>
  <div class="flex h-full space-x-2 p-2">
    <!-- NAME/AVT GROUP- -->
    <div class="flex flex-col h-full w-1/3">
      <div
        class="
          flex
          py-2
          px-2
          rounded-t-md
          items-center
          bg-current-50
          justify-between
          text-current-700
          font-medium
        "
      >
        <span>
          {{ $t(departmentTabs[departmentTabKeys.INFO].label) }}
        </span>
        <div class="flex items-center">
          <div
            v-if="!departmentTabs[departmentTabKeys.INFO].isEdit"
            v-vig-tooltip="$t('TASK_CREATE_GROUP_FORM_INFO_GROUP')"
            class="
              hover:bg-current-100
              p-2
              rounded-full
              flex
              items-center
              cursor-pointer
              justify-center
            "
            @click="onEdit(departmentTabKeys.INFO)"
          >
            <SynIcon
              v-permission="'FunctionGroupInfoEdit'"
              name="edit"
              is-active
              class="text-current-500 fill-current"
            />
          </div>
          <div v-else class="text-xs flex space-x-2">
            <SynButton
              v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
              color="gray"
              type-text
              :label="$t('COMMON_LABEL_CANCEL')"
              :disabled="departmentTabs[departmentTabKeys.INFO].isSave"
              @click="onCancelInfo(departmentTabKeys.INFO)"
            />
            <SynButton
              v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
              :label="$t('COMMON_LABEL_SAVE')"
              :is-loading="departmentTabs[departmentTabKeys.INFO].isSave"
              :disabled="departmentTabs[departmentTabKeys.INFO].isSave"
              @click="onSaveInfo(departmentTabKeys.INFO)"
            />
          </div>
        </div>
      </div>
      <div
        class="
          pt-4
          space-y-4
          bg-white
          border
          h-full
          text-sm
          border-gray-100
          overflow-y-scroll
          small-scrollbar
        "
      >
        <!-- avatar -->
        <UploadAvatarIcon
          :avatar-thumbnail-url="departmentDetail?.avatarUrl"
          :name="departmentDetail?.name"
          :is-loading="departmentTabs[departmentTabKeys.INFO].isSave"
          type-upload="GROUP"
          z-index="z-50"
          :is-update-success="isUpdateSuccess"
          @upload-avatar-aws="
            (file) => onSaveAvatar(departmentTabKeys.INFO, file)
          "
        />
        <!-- name -->
        <div class="text-center w-full">
          <div
            v-if="!departmentTabs[departmentTabKeys.INFO].isEdit"
            class="flex space-x-2 justify-center items-center"
          >
            <span
              class="font-medium text-md text-current-800"
              style="word-break: break-word"
              >{{ departmentDetail?.name }}</span
            >
          </div>
          <div v-else class="mx-3">
            <SynInput
              v-model="departmentDetail.name"
              class=""
              input-type="text"
              :placeholder="$t('TASK_CREATE_GROUP_FORM_GROUP_NAME')"
            />
          </div>
        </div>
        <!-- description -->
        <div class="text-center w-full">
          <div
            v-if="!departmentTabs[departmentTabKeys.INFO].isEdit"
            class="flex space-x-2 justify-center items-center"
          >
            <span
              class="text-current-800 w-4/5 text-sm rounded-md p-2 bg-gray-50"
              style="word-break: break-word"
              >{{
                departmentDetail?.description
                  ? departmentDetail?.description
                  : $t('COMMON_LABEL_NO_DESCRIPTION')
              }}
            </span>
          </div>
          <div v-else class="flex space-x-2 justify-center mx-3 items-center">
            <SynInputArea
              v-model="departmentDetail.description"
              input-type="text"
              :placeholder="$t('COMMON_LABEL_DESCRIPTION')"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- MEMBER -->
    <div class="flex flex-col h-full w-1/3">
      <div
        class="
          text-current-700
          font-medium
          flex
          bg-current-50
          justify-between
          py-2
          px-2
          rounded-t-md
          items-center
        "
      >
        <span>
          {{
            $t('GROUP_LABEL_MEMBER') +
            ' (' +
            departmentDetail?.departmentUsers?.length +
            ')'
          }}
        </span>
        <div
          v-vig-tooltip="$t('TASK_CREATE_GROUP_FORM_INVITE_COLLEAGUE')"
          class="
            hover:bg-current-100
            p-2
            rounded-full
            flex
            items-center
            cursor-pointer
            justify-center
          "
          @click.stop="onEdit(departmentTabKeys.MEMBER)"
        >
          <SynIcon
            name="edit"
            is-active
            class="text-current-500 fill-current"
          />
        </div>
      </div>
      <div
        class="
          bg-white
          border
          text-sm
          border-gray-100
          h-full
          overflow-y-auto
          small-scrollbar
          flex flex-col
        "
      >
        <DepartmentHasNotAdminWarning
          v-if="departmentDetail?.isHasNotAnyAdmin"
          type="SHOW_FULL"
        />
        <div v-if="departmentDetail?.departmentUsers.length < 1">
          <SynAnimation
            name="something-wrong"
            stype="width: 100px; height: 100px"
            :label="$t('COMMON_LABEL_NO_MEMBERS')"
          />
        </div>
        <div v-else class="flex flex-col flex-1 pb-4">
          <div
            v-for="user in departmentDetail?.departmentUsers"
            :key="user"
            class="py-2 hover:bg-gray-100 cursor-pointer"
          >
            <div class="flex items-center justify-between">
              <div
                v-vig-tooltip="user.lastName + ' ' + user.firstName"
                class="flex items-center space-x-2 px-6 font-sans"
                @click="chooseViewProfile(user.userId)"
              >
                <div class="w-8 h-8 relative">
                  <syn-avatar
                    :name="user.lastName + ' ' + user.firstName"
                    :src="user.avatarUrl?.replace('original', 'mini')"
                    custom-class="w-8 h-8"
                    :is-deactive="!user.isUserActive"
                  />
                  <div
                    v-if="!user.isUserActive"
                    class="
                      absolute
                      w-8
                      h-8
                      bg-gray-50
                      opacity-50
                      top-0
                      right-0
                      rounded-full
                    "
                  ></div>
                </div>
                <span
                  class="text-sm text-overflow-hidden-line"
                  :class="!user.isUserActive ? 'text-gray-500' : ''"
                >
                  {{ user.lastName + ' ' + user.firstName }}
                </span>
                <span
                  v-if="user.userId == currentUser.id"
                  class="italic text-sm"
                >
                  {{ '(' + $t('COMMON_LABEL_ME') + ')' }}
                </span>
                <span
                  v-if="user.adminType == AdminType.Visitor"
                  class="italic text-sm"
                >
                  {{ '(' + $t('COMMON_LABEL_VISITOR') + ')' }}
                </span>
                <span
                  v-if="user.role == GroupMemberRole.Admin"
                  class="italic text-sm text-gray-400"
                  >{{ '(' + $t('COMMON_LABEL_ADMIN') + ')' }}
                </span>
              </div>
              <div class="flex items-center font-sans">
                <ThreeDotActions type="action">
                  <div
                    @click="
                      changeMemberRole(
                        user.userId,
                        user.role == GroupMemberRole.Admin
                          ? GroupMemberRole.User
                          : GroupMemberRole.Admin
                      )
                    "
                  >
                    <SynDropdownItem
                      class="flex items-center space-x-2 list-li"
                    >
                      <SynIcon
                        name="check-circle"
                        is-active
                        :class="
                          user.role == GroupMemberRole.Admin
                            ? 'text-gray-600 fill-gray-500'
                            : 'text-gray-600 fill-gray-500'
                        "
                      />
                      <span
                        v-if="user.role == GroupMemberRole.Admin"
                        class="text-gray-500"
                      >
                        {{ $t('GROUP_UPDATE_MEMBER_UNSET_ADMIN') }}
                      </span>
                      <span v-else class="text-gray-500">
                        {{ $t('GROUP_UPDATE_MEMBER_SET_ADMIN') }}
                      </span>
                    </SynDropdownItem>
                  </div>
                  <div @click="onDeleteUser(user)">
                    <SynDropdownItem
                      class="flex items-center space-x-2 list-li-delete"
                    >
                      <SynIcon
                        name="trash"
                        custom-class="w-4 h-4 fill-red-500"
                        is-active
                      />
                      <span class="text-red-500">
                        {{ $t('TASK_DETAIL_REMOVE') }}
                      </span>
                    </SynDropdownItem>
                  </div>
                </ThreeDotActions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SETTING -->
    <div class="flex flex-col h-full w-1/3">
      <div
        class="
          flex
          bg-current-50
          justify-between
          cursor-pointer
          font-medium
          text-current-700
          rounded-t-md
          items-center
          px-2
          flex-wrap
          border-b
          py-3
        "
      >
        <span>
          {{ $t('HEADER_LABEL_SETTINGS') }}
        </span>
      </div>
      <div class="h-full border">
        <div class="w-full">
          <DepartmentSettingForm
            :department-id="departmentDetail?.id"
            :department-name="departmentDetail?.name"
            :settings="departmentDetail.departmentSetting"
            :related-group="departmentDetail?.relatedGroup"
            @delete-success="$emit('deleteSuccess')"
            @update-settings="updateSettings"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- add user -->
  <DepartmentUpdateMemberModal
    v-if="departmentTabs[departmentTabKeys.MEMBER].isEdit"
    :department-id="departmentDetail.id"
    :department-name="departmentDetail.name"
    :department-members="departmentDetail?.departmentUsers"
    @on-update-success="onUpdateMemberSuccess"
    @on-cancel="onCancelInfo(departmentTabKeys.MEMBER)"
  />

  <ModalProfileUser
    v-if="isOpenProfileUser"
    :profile-id="profileId"
    @cancel="isOpenProfileUser = false"
  >
    <div class="pr-4 flex py-3 justify-end">
      <SynButton
        v-vig-tooltip="$t('COMMON_LABEL_DELETE')"
        v-permission="'removeUserInGroup'"
        color="red"
        type-outline
        :label="$t('COMMON_LABEL_DELETE')"
        :class="isLeftUser ? 'cursor-not-allowed' : ''"
        :disabed="isLeftUser"
        name-icon="trash"
        @click="
          showModalRemove(profileId);
          isOpenProfileUser = false;
        "
      >
      </SynButton>
    </div>
  </ModalProfileUser>

  <AlertModal
    v-if="alertAdminSystemNotHasPermission"
    :title-text="$t('COMON_NOT_ALLOW_PERFORM_ACTION')"
    :sub-title-text="$t('GROUP_UPDATE_ALLOW_ADMIN_GROUP_ONLY')"
    container-class="w-1/3"
    :container-color="'current'"
    @cancel="alertAdminSystemNotHasPermission = false"
  />
  <AlertModal
    v-if="showAlertRemainOneAdmin"
    :title-text="$t('COMON_NOT_ALLOW_PERFORM_ACTION')"
    :sub-title-text="$t('GROUP_UPDATE_ADMIN_NOTALLOW')"
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="showAlertRemainOneAdmin = false"
  />
  <AlertModal
    v-if="showAlertSystemGroup"
    :title-text="$t('GROUP_LABEL_EDIT_SYSTEM_GROUP_TITLE')"
    :sub-title-text="
      $t('GROUP_LABEL_REMOVE_SYSTEM_GROUP_CONTENT', {
        groupName: departmentDetail?.name,
      })
    "
    :is-html-text="true"
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="showAlertSystemGroup = false"
  />
</template>
