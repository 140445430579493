<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import FilesSelectedActions from '@/ui/modules/ged/file-actions/files-selected-actions/FilesSelectedActions.vue';
import ButtonResourceAdd from '@/ui/modules/ged/resource-add/button-resource-add/ButtonResourceAdd.vue';
import NavFilter from '@/ui/modules/ged/filter/nav-filter/NavFilter.vue';
import router from '@/ui/router';
import FileSearchItem from '@/ui/modules/ged/files-list/file-search-item/FileSearchItem.vue';
import FileStorageService from '@/application/services/FileStorageService';

const props = defineProps({
  isViewByList: {
    type: Boolean,
    default: false,
  },
  isSelectionMode: {
    type: Boolean,
    default: false,
  },
  filesSelected: {
    type: Array,
    default: () => [],
  },
  fileFilters: {
    type: Object,
    default: null,
  },
  selectedFolder: {
    type: Object,
    default: null,
  },
  isShowAction: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  'onSearch',
  'onSelectionMode',
  'onSelectionAll',
  'onMoveToTrashClick',
  'onRestoreClick',
  'onDeletePermanentlyClick',
  'onMoveClick',
  'onApplyFilter',
  'onViewByList',
  'onReloadClick',
  'onUploadClick',
  'onUploadFilesSelected',
  'onFolderAdded',
]);

const inputSearchRef = ref(null as any);
const searchText = ref<string>();
const isShowNavFilter = ref(false);
// const isAddFolder = ref();

const filterCount = computed(() => {
  const { dateFrom, dateTo, fileTypes } = props.fileFilters || {};
  return Object.values({ dateFrom, dateTo, fileTypes })
    .reduce((arr, val) => arr.concat(val), [])
    .filter((val) => val).length;
});
const currentLocation = computed(() => {
  if (!props.fileFilters) return null;

  return {
    userId: props.fileFilters.userId,
    groupId: props.fileFilters.groupId,
    domainId: props.fileFilters.domainId,
    projectId: props.fileFilters.projectId,
    subProjectId: props.fileFilters.subProjectId,
    folderId: props.fileFilters.folderId,
    folder: props.selectedFolder,
  };
});

watch(
  () => props.fileFilters?.keyword,
  (keyword) => {
    searchText.value = keyword;
  }
);

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath.includes('resources')
  ) {
    inputSearchRef.value?.focus();
    e.preventDefault();
  }
});

const onGetSuggestions = async (searchText) => {
  if (!searchText?.trim()) return [];

  const params = {
    ...props.fileFilters,
    keyword: searchText?.trim(),
    sortBy: 'name',
    orderBy: 'asc',
    pageSize: 10,
    pageIndex: 0,
  };

  const res = await FileStorageService.getInstance().getFiles(params);

  return res?.result?.files;
};

const onSearchItemSelect = (item) => {
  emit('onSearch', searchText.value, item?.id ? item : null);
};
</script>

<template>
  <div
    class="
      flex
      items-center
      justify-between
      space-x-2
      px-3
      pb-2
      border-b border-gray-100
    "
  >
    <!--SEARCH-->
    <div class="w-1/4 max-w-xs">
      <VigTypeahead
        v-model:search-text="searchText"
        input-class="text-sm"
        :placeholder="$t('GED_SEARCH_LABEL')"
        :get-suggestions-fn="onGetSuggestions"
        @on-item-select="onSearchItemSelect"
      >
        <template #item="slotProps">
          <FileSearchItem :file="slotProps?.item" />
        </template>
      </VigTypeahead>
    </div>

    <!--SELECTION-->
    <FilesSelectedActions
      v-if="isSelectionMode"
      :files="filesSelected"
      :is-view-trash="fileFilters?.onlyDeletedFiles"
      @on-selection-mode="$emit('onSelectionMode', $event)"
      @on-move-to-trash-click="$emit('onMoveToTrashClick')"
      @on-restore-click="$emit('onRestoreClick')"
      @on-delete-permanently-click="$emit('onDeletePermanentlyClick')"
      @on-selection-all="$emit('onSelectionAll', $event)"
      @on-move-click="$emit('onMoveClick')"
    />
    <!--ACTIONS-->
    <div class="flex-center space-x-1">
      <vig-button
        v-vig-tooltip="$t('COMMON_LABEL_FILTERS')"
        :ghost="!isShowNavFilter"
        :light="isShowNavFilter"
        :color="!isShowNavFilter && filterCount <= 0 ? 'gray' : 'current'"
        padding="p-0"
        class="w-9 h-9 relative"
        @click="isShowNavFilter = !isShowNavFilter"
      >
        <SynIcon name="Filter" custom-class="w-5 h-5 fill-gray-500" />
        <div
          v-if="filterCount > 0"
          class="
            absolute
            -top-1
            -right-0.5
            bg-current-500
            text-white
            ring-1 ring-white
            text-xs
            font-semibold
            rounded-full
            w-5
            h-5
            flex-center
          "
        >
          {{ filterCount }}
        </div>
      </vig-button>

      <vig-button
        v-vig-tooltip="
          $t(
            isViewByList
              ? 'COMMON_LABEL_VIEW_BY_GRID'
              : 'COMMON_LABEL_VIEW_BY_LIST'
          )
        "
        ghost
        color="gray"
        padding="p-0"
        rounded="rounded"
        class="w-9 h-9"
        @click="$emit('onViewByList', !isViewByList)"
      >
        <SynIcon
          :name="isViewByList ? 'Grid2' : 'List'"
          custom-class="w-4 h-4"
        />
      </vig-button>

      <ButtonResourceAdd
        v-if="isShowAction && !fileFilters?.onlyDeletedFiles"
        :location="currentLocation"
        @on-folder-added="$emit('onFolderAdded', $event)"
        @on-upload-files-selected="$emit('onUploadFilesSelected', $event)"
        @on-upload-click="$emit('onUploadClick', $event)"
      />

      <vig-button
        v-vig-tooltip="$t('COMMON_LABEL_REFRESH')"
        ghost
        color="gray"
        padding="p-0"
        rounded="rounded"
        class="w-9 h-9"
        @click="$emit('onReloadClick')"
      >
        <SynIcon name="Reload" custom-class="w-4 h-4" />
      </vig-button>
    </div>
  </div>
  <div v-if="isShowNavFilter" class="bg-gray-50">
    <NavFilter
      :filter="fileFilters"
      @on-close="isShowNavFilter = false"
      @on-apply-filter="$emit('onApplyFilter', $event)"
    />
  </div>
</template>
