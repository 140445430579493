<script setup lang="ts">
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import { computed } from 'vue';
import { ChatExternalAcceptance } from '@/domain/enums/chat-enums';
import ChatExternalActions from '@/ui/modules/messaging/chat-external/chat-external-actions/ChatExternalActions.vue';

const props = defineProps<{
  conversation: ChatConversationModel;
}>();

defineEmits([
  'onShowFilesClick',
  'onShowPinnedMessagesClick',
  'onOpenNoteClick',
]);

const isExternalChatAccepted = computed(
  () =>
    props.conversation?.externalAcceptance === ChatExternalAcceptance.ACCEPTED
);
</script>

<template>
  <div
    class="
      flex
      items-center
      justify-between
      gap-3
      border-b border-yellow-500
      bg-yellow-50
      shadow
      px-3
      py-2
    "
  >
    <!--INFO-->
    <div class="flex items-center gap-2 text-lg">
      <SynAvatar
        custom-class="w-10 h-10"
        :src="conversation?.avatar"
        :name="conversation?.name"
        :short-name="conversation?.shortName"
      />
      <div>
        <div class="text-lg font-semibold">{{ conversation?.name }}</div>
        <div class="flex items-center gap-2 mt-0.5">
          <SynAvatar
            custom-class="w-5 h-5"
            :src="conversation?.externalOrgAvatar"
            :name="conversation?.externalOrgName"
          />
          <div class="text-sm text-gray-600">
            {{ conversation?.externalOrgName }}
          </div>
        </div>
      </div>
      <span
        class="
          px-2
          py-0.5
          ml-3
          text-sm
          font-semibold
          bg-yellow-300
          text-yellow-900
          rounded-full
          shadow
        "
        >{{ $t('CHAT_EXTERNAL') }}</span
      >
    </div>

    <!--ACTIONS-->
    <div v-if="isExternalChatAccepted" class="flex items-center gap-1">
      <VigButton
        v-vig-tooltip="{
          content: $t('CHAT_MINI_SHOW_FILES'),
          placement: 'bottom',
        }"
        ghost
        color="gray"
        rounded="rounded-full"
        icon="Files"
        @click="$emit('onShowFilesClick')"
      />
      <VigButton
        v-vig-tooltip="{
          content: $t('CHAT_MINI_SHOW_PINNED_MESSAGES'),
          placement: 'bottom',
        }"
        ghost
        color="gray"
        rounded="rounded-full"
        icon="Pin"
        @click="$emit('onShowPinnedMessagesClick')"
      />
      <ChatExternalActions
        @on-show-files-click="$emit('onShowFilesClick')"
        @on-show-pinned-messages-click="$emit('onShowPinnedMessagesClick')"
        @on-open-note-click="$emit('onOpenNoteClick')"
      >
        <VigButton
          ghost
          color="gray"
          rounded="rounded-full"
          icon="DotsVertical"
        />
      </ChatExternalActions>
    </div>
  </div>
</template>
