<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const allDay = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <div
    class="flex items-center space-x-2 px-2 py-1.5 rounded"
    :class="
      allDay
        ? `border-current border bg-current-50 text-current-600 `
        : 'bg-current-50 bg-opacity-60'
    "
  >
    <VigCheckbox v-model="allDay" checkbox-class="w-5 h-5">
      {{ $t('CALENDAR_REMINDER_ALL_DAY') }}
    </VigCheckbox>
  </div>
</template>

<style scoped></style>
