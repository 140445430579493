<script setup lang="ts">
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { computed } from 'vue';
import { isFolder } from '@/ui/helpers/file-utils';

const props = withDefaults(
  defineProps<{
    document: any;
    actions: (
      | 'OPEN_IN_BROWSER'
      | 'COPY_LINK'
      | 'RENAME'
      | 'MOVE_TO_FOLDER'
      | 'MOVE_TO_TRASH'
    )[];
  }>(),
  {
    actions: () => [
      'OPEN_IN_BROWSER',
      'COPY_LINK',
      'RENAME',
      'MOVE_TO_FOLDER',
      'MOVE_TO_TRASH',
    ],
  }
);

defineEmits(['onRenameClick', 'onMoveToFolderClick', 'onMoveToTrashClick']);

const isDriveFolder = computed(() => isFolder(props.document?.contentType));

const onOpenInBrowserClick = () => {
  if (!props.document?.link) return;

  window.open(props.document?.link, '_blank');
};

const onCopyClick = () => {
  if (!props.document?.link) return;

  copyToClipBroard(props.document?.link);

  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('GED_LABEL_COPY_URL_SUCCESS'),
    duration: 3000,
  });
};
</script>

<template>
  <VigDropdown z-index="100">
    <template #dropdown-toggle>
      <slot />
    </template>
    <template #dropdown-menu>
      <div>
        <VigButton
          v-if="actions?.includes('OPEN_IN_BROWSER') && !isDriveFolder"
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start text-sm dropdown-item"
          @click="onOpenInBrowserClick"
        >
          <SynIcon name="External" class="mr-2" />
          <span class="font-normal">{{
            $t('GED_GOOGLE_OPEN_IN_BROWSER')
          }}</span>
        </VigButton>
        <VigButton
          v-if="actions?.includes('COPY_LINK') && !isDriveFolder"
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start text-sm dropdown-item"
          @click="onCopyClick"
        >
          <SynIcon name="Link" class="mr-2" />
          <span class="font-normal">{{ $t('GED_GOOGLE_COPY_LINK') }}</span>
        </VigButton>
        <VigButton
          v-if="actions?.includes('RENAME')"
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start text-sm dropdown-item"
          @click="$emit('onRenameClick')"
        >
          <SynIcon name="Edit" class="mr-2" />
          <span class="font-normal">{{ $t('GED_FILE_RENAME') }}</span>
        </VigButton>
        <VigButton
          v-if="actions?.includes('MOVE_TO_FOLDER')"
          ghost
          color="gray"
          class="w-full justify-start text-sm dropdown-item"
          @click="$emit('onMoveToFolderClick')"
        >
          <SynIcon name="Back" class="transform rotate-180 mr-2" />
          <span class="font-normal">{{ $t('GED_FILE_MOVE_TO_FOLDER') }}</span>
        </VigButton>
        <hr />
        <VigButton
          v-if="actions?.includes('MOVE_TO_TRASH')"
          ghost
          color="red"
          rounded="rounded-0"
          class="w-full justify-start text-sm dropdown-item"
          @click="$emit('onMoveToTrashClick')"
        >
          <SynIcon name="Trash" class="mr-2 fill-red-500" />
          <span class="font-normal">{{ $t('GED_FILE_MOVE_TO_TRASH') }}</span>
        </VigButton>
      </div>
    </template>
  </VigDropdown>
</template>
