<script setup lang="ts">
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import {
  convertUrgencyToImportant,
  convertUrgencyToPriority,
  getUrgencyValue,
} from '@/ui/hooks/taskHook';
import { computed } from 'vue';

const props = defineProps<{
  task: any;
  readonly: boolean;
}>();

const emits = defineEmits(['onUrgencyChange']);

const taskUrgency = computed(() =>
  getUrgencyValue(props.task?.priority, props.task?.important)
);

const onUrgencyChange = (urgency) => {
  emits('onUrgencyChange', {
    priority: convertUrgencyToPriority(urgency),
    important: convertUrgencyToImportant(urgency),
  });
};
</script>

<template>
  <div class="flex">
    <VigDropdown :arrow="false">
      <template #dropdown-toggle>
        <div
          class="font-medium p-1 rounded"
          :class="{
            'cursor-pointer hover:bg-current-50': !readonly,
          }"
        >
          <PriorityTask size="mini" :urgency="taskUrgency" />
        </div>
      </template>
      <template v-if="!readonly" #dropdown-menu>
        <div class="w-50 py-1 list-ul rounded dropdown-item">
          <div
            v-for="(urgency, index) in [4, 3, 2, 1]"
            :key="index"
            class="list-li flex text-left justify-start pr-2"
            :class="taskUrgency === urgency ? 'bg-gray-100' : ''"
            @click="onUrgencyChange(urgency)"
          >
            <PriorityTask :urgency="urgency" size="mini" />
          </div>
        </div>
      </template>
    </VigDropdown>
  </div>
</template>
