<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import userStore from '@/store/user';
import organizationStore from '@/store/organization';
import { AdminType } from '@/ui/common/constants/constant';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { ResponseStatusCode } from '@/ui/common/constants/constant';
import FilterProfile from '@/ui/pages/usermanagement/component/FilterProfile.vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import MutipleActionsPendingUsers from '@/ui/pages/usermanagement/MutipleActionsPendingUsers.vue';
import EditPendingUsersModal from '@/ui/pages/usermanagement/detailUser/EditPendingUsersModal.vue';
import departmentStore from '@/store/department';
import groupStore from '@/store/group';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import domainStore from '@/store/scope';
import settingStore from '@/store/setting';

defineEmits(['showDetail']);
const _organizationStore = organizationStore();
const _userStore = userStore();
const _departmentStore = departmentStore();
const _groupStore = groupStore();
const _domainStore = domainStore();
const _settingStore = settingStore();

const allGroups = computed(() => _groupStore.allGroup);
const allDepartments = computed(() => _departmentStore.allDepartments);
const allDomains = computed(() => _domainStore.allDomains);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const pendingUsers = computed(() => _organizationStore.pendingUsers);

const isLoadingUsers = ref(false);
const rawPendingUsers = ref([] as any);
const loadingBtn = ref({ resend: false, cancel: false });
const errorOnResend = ref('');
const selectedUserList = ref([] as any);
const isEditPendingUsers = ref(false);
const infoUserSelected = ref();
const mode = ref('user');
const loadingWhenResendMultiple = ref(false);
const loadingWhenDeleteMultiple = ref(false);

const isCheckAll = computed(() => {
  return filteredUser.value.filter((e) => !e.isSelected).length <= 0;
});

const isIndeterminate = computed(() => {
  const selectedList = filteredUser.value.filter((e) => e.isSelected);
  return (
    selectedList.length > 0 && selectedList.length < filteredUser.value.length
  );
});
const closeErrorOnResend = async () => {
  errorOnResend.value = '';
  await init();
};

const filterData = reactive<{
  keySearch: string;
  adminType: AdminType | undefined;
}>({
  keySearch: '',
  adminType: undefined,
});

const filteredUser = computed<any>(() => {
  const searchTxt = ignoreUnicode(filterData.keySearch);

  let result = pendingUsers.value;

  if (filterData.keySearch)
    result = (result || []).filter(
      (user) =>
        user?.emailOrPhone &&
        ignoreUnicode(user?.emailOrPhone).includes(searchTxt)
    );

  if (filterData.adminType)
    result = (result || []).filter(
      (user) => user?.adminType == filterData.adminType
    );

  return result;
});

const init = async () => {
  isLoadingUsers.value = true;
  try {
    await _groupStore.getAllGroup();
    rawPendingUsers.value = await _organizationStore.getPendingUsers();

    selectedUserList.value = [];
    isLoadingUsers.value = false;
  } catch (e) {
    isLoadingUsers.value = false;
  }
};

init();

const onReload = async () => {
  filterData.keySearch = '';
  // selectedUserList.value = [];
  await init();
};

const onUpdate = async () => {
  isEditPendingUsers.value = false;
  await init();
};

const inviteeUser = (userId) => {
  const user = _userStore.listShortInfosUser.find((e) => e.id == userId);
  return user || {};
};

const infoDepartment = (departmentId) => {
  return allDepartments.value.find((e: any) => e.id == departmentId) || {};
};

const groupList = (groupIdList) => {
  return (
    groupIdList?.map((e: any) => allGroups.value.find((o) => o.id == e)) || []
  );
};

const domainList = (domainIdList) => {
  return (
    domainIdList?.map((e: any) => allDomains.value.find((o) => o.id == e)) || []
  );
};
const convertToLocalDate = (utcDate) => {
  if (!utcDate) return utcDate;
  if (!utcDate.includes('Z')) {
    utcDate = utcDate + 'Z';
  }
  return new Date(utcDate);
};

const showExpiredLabel = (date) => {
  if (!date) return true;
  return new Date() > convertToLocalDate(date);
};

const onChooseItem = (item) => {
  item.isSelected = !item.isSelected;
  selectedUserList.value = pendingUsers.value.filter((e: any) => e.isSelected);
};

const onCheckAll = () => {
  filteredUser.value.forEach((el) => (el.isSelected = true));
  selectedUserList.value = pendingUsers.value.filter((e: any) => e.isSelected);
};

const unCheckAll = () => {
  filteredUser.value.forEach((el) => (el.isSelected = false));
  selectedUserList.value = pendingUsers.value.filter((e: any) => e.isSelected);
};

const hideAction = () => {
  pendingUsers.value.forEach((el: any) => (el.isSelected = false));
  selectedUserList.value = [];
};

const onEdit = (item) => {
  mode.value = 'user';
  isEditPendingUsers.value = true;
  infoUserSelected.value = [item];
};

const onEditMultipleUsers = () => {
  mode.value = selectedUserList.value.length > 1 ? 'list' : 'user';
  isEditPendingUsers.value = true;
  infoUserSelected.value = selectedUserList.value;
};

const onResendInvitation = async (data, address) => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content:
      Array.isArray(data) && data.length > 1
        ? translate('USERMANAGEMENT_PENDING_MULTIPLE_CONFIRM_RESEND')
        : translate('USERMANAGEMENT_PENDING_CONFIRM_RESEND'),
    confirmable: true,
    closeable: true,
    confirmLabel: translate('COMMON_LABEL_AGREE'),
  });
  const confirmed = await ask();
  if (confirmed) {
    try {
      if (Array.isArray(data)) {
        loadingWhenResendMultiple.value = true;
        const userList = data?.map((e) => e.id) || [];
        const addresses = data?.map((e) => e.emailOrPhone).join(', ');
        await _organizationStore.resendInvitation(userList);
        loadingWhenResendMultiple.value = false;
        actionSuccess('resend', addresses);
      } else {
        loadingBtn.value.resend = true;
        loadingBtn.value.id = data;
        await _organizationStore.resendInvitation([data]);
        loadingBtn.value.resend = false;
        loadingBtn.value.id = 0;
        actionSuccess('resend', address);
      }
      await init();
    } catch (error) {
      errorOnResend.value =
        error?.error?.code == ResponseStatusCode.InviteUserDeleted
          ? 'COMMON_LABEL_PHONE_ACCOUNT_DELETED'
          : 'USERMANAGEMENT_PENDING_RESEND_ERROR';
      // errorOnResend.value =
      //   error?.error?.code == ResponseStatusCode.InviteJoinedByOtherMethods ||
      //   error?.error?.code == ResponseStatusCode.InviteUserDeleted;
    }
  }
};

const onCancelInvitation = async (data, address) => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content:
      Array.isArray(data) && data.length > 1
        ? translate('USERMANAGEMENT_PENDING_MULTIPLE_CONFIRM_CANCELED')
        : translate('USERMANAGEMENT_PENDING_CONFIRM_CANCELED'),
    confirmable: true,
    closeable: true,
    confirmLabel: translate('COMMON_LABEL_AGREE'),
  });
  const confirmed = await ask();
  if (confirmed) {
    if (Array.isArray(data)) {
      loadingWhenDeleteMultiple.value = true;
      const userList = data?.map((e) => e.id) || [];
      const addresses = data?.map((e) => e.emailOrPhone).join(', ');
      await _organizationStore.cancelInvitation(userList);
      loadingWhenDeleteMultiple.value = false;
      actionSuccess('cancel', addresses);
    } else {
      loadingBtn.value.cancel = true;
      loadingBtn.value.id = data;
      await _organizationStore.cancelInvitation([data]);
      loadingBtn.value.cancel = false;
      loadingBtn.value.id = 0;
      actionSuccess('cancel', address);
    }
    await init();
  }
};
const actionSuccess = (action, address) => {
  const contentMessage =
    action == 'resend'
      ? translate('USERMANAGEMENT_PENDING_ACTION_RESENDSUCCESS', {
          address: address,
        })
      : translate('USERMANAGEMENT_PENDING_ACTION_CANCELSUCCESS', {
          address: address,
        });
  openNotification({
    body: contentMessage,
    duration: 3000,
  });
};

const inputSearchRef = ref();

const totalFilterItem = ref(false);

const onChangeStatus = (adminType) => {
  filterData.adminType = adminType;
  totalFilterItem.value = adminType != '';
};

defineExpose({
  init,
});
</script>

<template>
  <div class="h-full bg-white flex flex-col space-y-2 px-2 overflow-y-hidden">
    <div class="flex w-full justify-between items-center h-10">
      <!-- search -->
      <div class="w-full flex justify-between items-center">
        <div class="w-80 flex items-center">
          <VigSearchBox
            ref="inputSearchRef"
            v-model="filterData.keySearch"
            class="w-full"
            :placeholder="$t('USERMANAGEMENT_PENDING_LABEL_SEARCH') + '...'"
          />
        </div>
        <MutipleActionsPendingUsers
          v-if="selectedUserList.length > 0"
          :selected-list="selectedUserList"
          :loading-resend="loadingWhenResendMultiple"
          :loading-delete="loadingWhenDeleteMultiple"
          @on-hide-action="hideAction"
          @on-edit="onEditMultipleUsers"
          @on-resend="onResendInvitation(selectedUserList, '')"
          @on-delete="onCancelInvitation(selectedUserList, '')"
        />
        <div class="flex items-center space-x-1">
          <span
            class="
              text-xs
              rounded
              py-1
              px-2
              border border-current-400
              text-current-500
            "
            >{{
              `${$t('COMMON_LABEL_TOTAL')}: ${filteredUser.length || 0}`
            }}</span
          >
          <span>|</span>
          <span class="rounded text-xs py-1 px-2 bg-current text-white">
            {{
              `${$t('COMMON_LABEL_TOTAL_SENDED')}: ${filteredUser.length || 0}`
            }}</span
          ><span class="rounded text-xs py-1 px-2 bg-red-400 text-white">{{
            `${$t('COMMON_LABEL_EXPIRED')}: ${
              filteredUser.filter((el) =>
                showExpiredLabel(el?.tokenExpirationDate)
              ).length || 0
            }`
          }}</span>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="flex-1 overflow-y-auto small-scrollbar shadow-md">
      <syn-table-custom>
        <template #header>
          <syn-tr>
            <SynTh v-if="filteredUser.length > 0" is-center style="width: 50px">
              <div
                class="
                  h-8
                  w-8
                  flex-center
                  rounded-full
                  cursor-pointer
                  hover:bg-current-50
                "
                @click="isCheckAll ? unCheckAll() : onCheckAll()"
              >
                <div
                  class="h-4 w-4 flex-center rounded border border-current-300"
                  :class="
                    isIndeterminate || isCheckAll
                      ? 'bg-current-300'
                      : 'bg-white'
                  "
                >
                  <SynIcon v-if="isCheckAll" name="check" class="text-white" />
                  <SynIcon
                    v-if="isIndeterminate"
                    name="minus-line"
                    custom-class="w-2.5 fill-white"
                  />
                </div>
              </div>
            </SynTh>
            <SynTh
              class="w-48 3xl:w-60"
              :label="$t('USERMANAGEMENT_PENDING_HEADER_EMAILORPHONE')"
            />
            <SynTh :label="$t('USERMANAGEMENT_PENDING_HEADER_INVITE_DATE')" />
            <SynTh :label="$t('USERMANAGEMENT_PENDING_HEADER_EXPIREDATE')" />
            <SynTh :label="$t('USERMANAGEMENT_PENDING_HEADER_ROLE')">
              <template #custom>
                <FilterProfile
                  :admin-type="filterData.adminType"
                  @on-change-status="(value) => onChangeStatus(value)"
                />
              </template>
            </SynTh>
            <SynTh
              v-if="departmentManagementEnabled"
              :label="$t('COMMON_LABEL_DEPARTMENT')"
            />
            <SynTh :label="$t('COMMON_LABEL_GROUP')" />
            <SynTh :label="$t('DOMAIN_LABEL_TITLE')" />
            <SynTh :label="$t('USERMANAGEMENT_PENDING_HEADER_INVITEE')" />
            <SynTh :label="$t('TASK_TABLE_LABEL_ACTIONS')" />
            <SynTh>
              <button
                v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                class="
                  flex-center
                  rounded-full
                  hover:fill-current
                  fill-gray-500
                  w-6
                  h-6
                "
                @click="onReload"
              >
                <SynIcon name="reload" class="w-4 h-4" />
              </button>
            </SynTh>
          </syn-tr>
        </template>

        <template #body>
          <syn-table-tr-loading v-if="isLoadingUsers">
            <div v-for="item in 10" :key="item" class="table-cell p-2 h-96">
              <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
            </div>
          </syn-table-tr-loading>

          <template
            v-for="user in filteredUser"
            v-else-if="filteredUser.length > 0"
            :key="user.id"
          >
            <syn-tr :id-task="user.id">
              <syn-td>
                <div class="w-full flex-center">
                  <div
                    class="
                      h-8
                      w-8
                      flex-center
                      rounded-full
                      cursor-pointer
                      hover:bg-current-50
                    "
                    @click="onChooseItem(user)"
                  >
                    <div
                      class="
                        cursor-pointer
                        h-4
                        w-4
                        flex-center
                        rounded
                        border border-current-300
                      "
                      :class="user.isSelected ? 'bg-current-300' : 'bg-white'"
                    >
                      <SynIcon
                        v-if="user.isSelected"
                        name="check"
                        class="text-white"
                      />
                    </div>
                  </div>
                </div>
              </syn-td>
              <syn-td>
                <div class="min-w-max flex items-center space-x-2">
                  <SynIcon
                    class="cursor-text fill-current"
                    has-action
                    :name="user.isEmailInvitedMethod ? 'email' : 'phone'"
                  />
                  <span
                    class="font-semibold min-w-max text-overflow-hidden-line"
                  >
                    {{ user.emailOrPhone }}
                  </span>
                </div>
              </syn-td>
              <syn-td class-custom="text-left">
                <SynLabelDateTime
                  format="date_time"
                  :datetime="user.invitedTime"
                  class="text-xs 3xl:text-sm"
                />
              </syn-td>
              <syn-td class="min-w-max text-xs 3xl:text-sm">
                <div
                  class="flex space-x-2"
                  :class="
                    showExpiredLabel(user.tokenExpirationDate)
                      ? 'text-red-500'
                      : ''
                  "
                >
                  <span>
                    <SynLabelDateTime
                      format="date_time"
                      :datetime="user.tokenExpirationDate"
                    />
                  </span>
                  <span
                    v-if="showExpiredLabel(user.tokenExpirationDate)"
                    style="font-size: 10px"
                    class=""
                    >{{ $t('COMMON_LABEL_EXPIRED') }}</span
                  >
                </div>
              </syn-td>
              <syn-td class="min-w-max">
                <span
                  class="px-2 py-0.5 rounded-full text-xs flex-center w-max"
                  :class="
                    user.adminType == AdminType.Admin
                      ? 'bg-orange-400 text-white'
                      : user.adminType == AdminType.User
                      ? 'bg-current text-white'
                      : user.adminType == AdminType.Visitor
                      ? 'bg-gray-400 text-white'
                      : user.adminType == AdminType.Owner
                      ? 'bg-orange-500 text-white'
                      : 'bg-current text-white'
                  "
                >
                  {{
                    user.adminType == AdminType.Admin
                      ? 'Admin'
                      : user.adminType == AdminType.User
                      ? 'User'
                      : user.adminType == AdminType.Visitor
                      ? 'Visitor'
                      : user.adminType == AdminType.Owner
                      ? 'Owner'
                      : 'User'
                  }}
                </span>
              </syn-td>
              <syn-td v-if="departmentManagementEnabled">
                <div
                  v-if="user.departmentId"
                  :title="infoDepartment(user.departmentId)?.name"
                  class="flex items-center space-x-2"
                >
                  <syn-avatar
                    class="text-current-800"
                    :src="
                      infoDepartment(user.departmentId)?.avatarUrl?.replace(
                        'original',
                        'mini'
                      )
                    "
                    type="department"
                    custom-class="w-6 h-6"
                    :name="infoDepartment(user.departmentId)?.name"
                  ></syn-avatar>
                  <span
                    class="
                      text-overflow-hidden-line
                      font-medium
                      text-xs
                      3xl:text-sm
                    "
                    >{{ infoDepartment(user.departmentId)?.name }}</span
                  >
                </div>
                <div v-else>
                  <span class="italic text-gray-400 text-xs 3xl:text-sm">{{
                    $t('COMMON_LABEL_NOT_SET')
                  }}</span>
                </div>
              </syn-td>
              <syn-td>
                <div v-if="user.groupIds.length > 0">
                  <div
                    v-if="groupList(user.groupIds).length == 1"
                    :title="groupList(user.groupIds)[0]?.name"
                    class="flex items-center space-x-2"
                  >
                    <syn-avatar
                      class="text-current-800"
                      :src="
                        groupList(user.groupIds)[0]?.avatarUrl?.replace(
                          'original',
                          'mini'
                        )
                      "
                      type="group"
                      custom-class="w-6 h-6"
                      :name="groupList(user.groupIds)[0]?.name"
                    ></syn-avatar>
                    <span
                      class="
                        text-overflow-hidden-line
                        font-medium
                        text-xs
                        3xl:text-sm
                      "
                      >{{ groupList(user.groupIds)[0]?.name }}</span
                    >
                  </div>
                  <div v-else class="w-max">
                    <VigDropdown trigger="mouseenter">
                      <template #dropdown-toggle>
                        <div
                          class="
                            px-2
                            py-1
                            bg-current-50
                            text-current-500
                            3xl:text-sm
                            text-xs
                            rounded
                            hover:bg-current-100
                          "
                          style="width: fit-content"
                        >
                          <span>{{ $t('COMMON_LABEL_MANY') }}</span>
                        </div>
                      </template>
                      <template #dropdown-menu>
                        <div
                          class="
                            max-h-56
                            overflow-auto
                            rounded
                            flex flex-col
                            small-scrollbar
                          "
                          style="max-width: 15rem"
                        >
                          <div
                            v-for="(item, index) in groupList(user.groupIds)"
                            :key="item?.id"
                            :title="item.name"
                            class="flex items-center space-x-2 px-2 py-2"
                            :class="
                              index !== groupList(user.groupIds).length - 1
                                ? 'border-b border-gray-100'
                                : ''
                            "
                          >
                            <syn-avatar
                              :src="item.avatarUrl?.replace('original', 'mini')"
                              type="group"
                              custom-class="w-6 h-6"
                              :name="item?.name"
                            ></syn-avatar>
                            <span
                              class="
                                text-sm text-overflow-hidden-line
                                font-medium
                              "
                              >{{ item.name }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </VigDropdown>
                  </div>
                </div>
                <div v-else>
                  <span class="italic text-gray-400 text-xs 3xl:text-sm">{{
                    $t('COMMON_LABEL_NOT_SET')
                  }}</span>
                </div>
              </syn-td>
              <syn-td>
                <div v-if="user.domainIds.length > 0">
                  <div v-if="domainList(user.domainIds).length == 1">
                    <span class="3xl:text-sm text-xs font-medium">
                      {{ domainList(user.domainIds)[0]?.name }}
                    </span>
                  </div>
                  <div v-else class="w-max">
                    <VigDropdown trigger="mouseenter">
                      <template #dropdown-toggle>
                        <div
                          class="
                            px-2
                            py-1
                            bg-current-50
                            text-current-500
                            3xl:text-sm
                            text-xs
                            rounded
                            hover:bg-current-100
                          "
                          style="width: fit-content"
                        >
                          <span>{{ $t('COMMON_LABEL_MANY') }}</span>
                        </div>
                      </template>
                      <template #dropdown-menu>
                        <div
                          class="
                            max-h-56
                            overflow-auto
                            p-2
                            rounded
                            space-y-2
                            flex flex-col
                            small-scrollbar
                          "
                          style="max-width: 10rem"
                        >
                          <div
                            v-for="item in domainList(user.domainIds)"
                            :key="item?.id"
                            :title="item.name"
                            class="flex items-center space-x-2"
                          >
                            <span
                              class="
                                text-sm
                                font-medium
                                text-overflow-hidden-line
                              "
                            >
                              {{ item.name }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </VigDropdown>
                  </div>
                </div>
                <div v-else>
                  <span class="italic text-gray-400 text-xs 3xl:text-sm">{{
                    $t('COMMON_LABEL_NOT_SET')
                  }}</span>
                </div>
              </syn-td>
              <syn-td>
                <div class="flex items-center space-x-2">
                  <syn-avatar
                    class="text-current-800 text-xs 3xl:text-sm"
                    :src="inviteeUser(user.invitee).profilePictureUrl"
                    custom-class="w-6 h-6"
                    :name="inviteeUser(user.invitee).name"
                  ></syn-avatar>
                  <span
                    class="
                      text-overflow-hidden-line
                      font-medium
                      text-xs
                      3xl:text-sm
                    "
                    >{{ inviteeUser(user.invitee).name }}</span
                  >
                </div>
              </syn-td>
              <syn-td class="overflow-hidden">
                <div class="flex space-x-2 mb-2">
                  <div
                    v-vig-tooltip="{
                      arrow: true,
                      content: $t('USERMANAGEMENT_PENDING_ACTION_RESEND'),
                    }"
                    class="
                      flex-center
                      cursor-pointer
                      fill-green-500
                      text-green-500
                      p-2
                      bg-white
                      border border-gray-100
                      rounded
                      hover:bg-green-50
                      w-8
                      h-8
                    "
                    @click="onResendInvitation(user.id, user?.emailOrPhone)"
                  >
                    <SynIconCustom
                      :name="
                        loadingBtn.resend && loadingBtn.id == user.id
                          ? 'spinner'
                          : 'plane-paper'
                      "
                      :class="[
                        loadingBtn.resend && loadingBtn.id == user.id
                          ? 'animate-spin'
                          : '',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                  <div
                    v-vig-tooltip="{
                      arrow: true,
                      content: $t('COMMON_LABEL_EDIT'),
                    }"
                    class="
                      flex-center
                      cursor-pointer
                      fill-blue-500
                      text-blue-500
                      p-2
                      bg-white
                      border border-gray-100
                      rounded
                      hover:bg-blue-50
                      w-8
                      h-8
                    "
                    @click="onEdit(user)"
                  >
                    <SynIcon custom-class="w-3 h-3" name="edit" />
                  </div>
                  <div
                    v-vig-tooltip="{
                      arrow: true,
                      content: $t('COMMON_LABEL_DELETE'),
                    }"
                    class="
                      flex-center
                      cursor-pointer
                      fill-red-500
                      p-2
                      bg-white
                      border border-gray-100
                      rounded
                      hover:bg-red-50
                      w-8
                      h-8
                    "
                    @click="onCancelInvitation(user.id, user?.emailOrPhone)"
                  >
                    <SynIconCustom
                      :name="
                        loadingBtn.cancel && loadingBtn.id == user.id
                          ? 'spinner'
                          : 'trash'
                      "
                      :class="[
                        loadingBtn.cancel && loadingBtn.id == user.id
                          ? 'animate-spin'
                          : '',
                      ]"
                      class="w-3 h-3"
                    />
                  </div>
                </div>
              </syn-td>
              <syn-td></syn-td>
            </syn-tr>
          </template>
          <template v-else>
            <syn-tr class="h-full" hover="hover:bg-current-50">
              <syn-td :colspan="10" style="height: calc(100vh - 20rem)">
                <syn-animation
                  name="searchIcon"
                  stype="width: 200px; height: 200px"
                  :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                />
              </syn-td>
            </syn-tr>
          </template>
        </template>
      </syn-table-custom>
    </div>
  </div>

  <SynModal
    v-if="errorOnResend"
    is-hidden-footer
    is-hidden-header
    z-index="z-10"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :container-style="'max-height: 300px;max-width: 800px;min-height:50px;min-width:60px;width: 90%; height: 90%'"
    @cancel="closeErrorOnResend"
  >
    <template #header>
      <div></div>
    </template>
    <template #body>
      <div class="w-full h-full flex-center text-current-800 text-lg">
        {{ $t(errorOnResend) }}
      </div>
    </template>
  </SynModal>

  <EditPendingUsersModal
    v-if="isEditPendingUsers"
    :user-selected-list="infoUserSelected"
    :mode="mode"
    @cancel="isEditPendingUsers = false"
    @on-save="onUpdate"
  ></EditPendingUsersModal>
</template>
