<script setup lang="ts">
import { ref } from 'vue';
import SelectOrganizationModel from '@/ui/modules/organization/setup/SelectOrganizationModel.vue';
import organizationStore from '@/store/organization';
import myProfileStore from '@/store/auth/my-profile';

const props = defineProps<{
  doNotNeedToCheckPersonal: boolean;
  typeKey: 'workspace_type_select' | 'workspace_type_select_register';
  notAllowPersonal?: boolean;
  isHomePage?: boolean;
}>();
const emit = defineEmits(['onCancel', 'onFinish']);
const _organizationStore = organizationStore();

const setupOrgRef = ref(null as any);
const isCreateFetching = ref(false);
const onSubmit = async ({ data }: { mode: number; data: any }) => {
  isCreateFetching.value = true;

  if (props.isHomePage) {
    console.log('🚀 Tictop ~ data:', data);
    await _organizationStore.registerNewOrganization(
      {
        organizationInfo: data,
        positions: [],
        taskTypes: [],
        domains: [],
      },
      true
    );
    isCreateFetching.value = false;
  } else {
    _organizationStore.updateDecideTime(11);
    await _organizationStore.updateAndFinishSetupOrganization(data);
    myProfileStore().fetchCurrentLoginUser();
    isCreateFetching.value = false;
    emit('onFinish', data);
  }
};
</script>

<template>
  <teleport to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
        z-50
      "
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <SelectOrganizationModel
        ref="setupOrgRef"
        :type-key="typeKey"
        :is-submitting="isCreateFetching"
        :do-not-need-to-check-personal="doNotNeedToCheckPersonal"
        :is-hidden-personal-model="notAllowPersonal"
        :is-home-page="isHomePage"
        @on-cancel="$emit('onCancel')"
        @on-submit="onSubmit"
      />
    </div>
  </teleport>
</template>
