export const CalendarsTypes = {
    DAYOFF: {
        key: 'DAYOFF',
        colorText: 'gray',
        textCode: 'COMMON_MODULE_DAYOFF',
        textDefault: 'Dayoff',
        iconName: 'Calendars',
    },
    EVENT: {
        key: 'EVENT',
        colorText: 'purple',
        textCode: 'CALENDAR_FILTER_TITLE_TYPE_EVENT',
        textDefault: 'Event',
        iconName: 'Event',
    },
    REMINDER: {
        key: 'REMINDER',
        colorText: 'green',
        textCode: 'CALENDAR_FILTER_TITLE_TYPE_REMINDER',
        textDefault: 'Reminder',
        iconName: 'AlarmClock',
    },
};
