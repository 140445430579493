import dayjs from 'dayjs';
// import { NextBillingStatus } from '@/ui/common/constants/payment';
// import { getUiByPlanState } from '@/application/constants/plan.const';

export const getStateCodeByRemainDays = (
    planRemainDays,
    lastDateAllowedToUse
) => {
    if (planRemainDays <= 0) {
        if (
            lastDateAllowedToUse &&
            dayjs().isBefore(dayjs(lastDateAllowedToUse))
        )
            return 'OVERDUE_LOWER';

        return 'EXPIRED';
    }

    if (planRemainDays == 1) return 'INCOMING_EXPIRED_HIGHER_TODAY';
    if (planRemainDays < 5) return 'INCOMING_EXPIRED_HIGHER';
    if (planRemainDays < 10) return 'INCOMING_EXPIRED';

    return 'DEFAULT';
};
// export const UITextStateCodePayment = (org) => {
//     const subStateCode =
//         getStateCodeByRemainDays(
//             org?.planRemainDays,
//             org?.lastDateAllowedToUse || new Date()
//         ) || 'DEFAULT';

//     const uiByPlanState = getUiByPlanState(org?.planId, org?.stateCode);

//     return uiByPlanState?.[subStateCode];
// switch (org?.stateCode) {
//     case uiByPlanState.TRIAL.code:
//         result = {
//             ...result,
//             borderCard:
//                 org?.planRemainDays < 0
//                     ? 'border-orange-100 hover:border-current cursor-pointer'
//                     : 'border-yellow-100 hover:border-current cursor-pointer',
//             text:
//                 org?.planRemainDays < 0
//                     ? uiByPlanState.OVERDUE_TRIAL[subStateCode]?.message
//                     : uiByPlanState.TRIAL[subStateCode]?.message,
//             colorText:
//                 org?.planRemainDays < 0
//                     ? 'text-orange-500'
//                     : 'text-yellow-500',
//             code: uiByPlanState.TRIAL.code,
//         };
//         break;
//     case uiByPlanState.OVERDUE_TRIAL.code:
//         result = {
//             ...result,
//             borderCard:
//                 'border-orange-100 hover:border-current cursor-pointer',
//             text: uiByPlanState.OVERDUE_TRIAL[subStateCode]?.message,
//             colorText: 'text-orange-500',
//             code: uiByPlanState.OVERDUE_TRIAL.code,
//         };
//         break;
//     case uiByPlanState.OVERDUE_PAID.code:
//         result = {
//             ...result,
//             borderCard:
//                 'border-orange-100 hover:border-current cursor-pointer',
//             text: uiByPlanState.OVERDUE_PAID[subStateCode]?.message,
//             colorText: 'text-orange-500',
//             code: uiByPlanState.OVERDUE_PAID.code,
//         };
//         break;
//     case uiByPlanState.BLOCK.code:
//         result = {
//             ...result,
//             borderCard:
//                 'border-red-100 hover:border-current cursor-pointer',
//             text: uiByPlanState.BLOCK[subStateCode]?.message,
//             colorText: 'text-red-500',
//             code: uiByPlanState.BLOCK.code,
//         };
//         break;
//     case uiByPlanState.DEFINITIVE_BLOCK.code:
//         result = {
//             ...result,
//             borderCard:
//                 'border-red-100 hover:border-current cursor-pointer',
//             text: uiByPlanState.DEFINITIVE_BLOCK[subStateCode]?.message,
//             colorText: 'text-red-500',
//             code: uiByPlanState.DEFINITIVE_BLOCK.code,
//         };
//         break;
//     default:
//         if (org?.nextBillingStatus === NextBillingStatus.NeedExtend) {
//             result = {
//                 ...result,
//                 borderCard:
//                     'border-orange-100 hover:border-current cursor-pointer',
//                 text: uiByPlanState.PAID[subStateCode]?.message,
//                 colorText: 'text-orange-500',
//             };
//         } else {
//             result = {
//                 ...result,
//                 borderCard:
//                     'border-gray-100 hover:border-current cursor-pointer',
//                 text: '',
//                 colorText: 'text-white',
//             };
//         }

//         break;
// }
// return result;
// };
