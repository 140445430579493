<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import whiteboardStore from '@/store/whiteboard';

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'createSuccess', newWhiteboard): void;
}>();

const configs = computed<{
  minX: number | null;
  minY: number | null;
  maxX: number | null;
  maxY: number | null;
}>(() => {
  return {
    minX: null,
    minY: null,
    maxX: null,
    maxY: null,
  };
});

const whiteboardPayload = ref<{
  title: string;
  maxIndex: number;
  shapeList: any[];
  configs: any;
}>({
  title: '',
  maxIndex: 0,
  shapeList: [],
  configs: {
    ...configs.value,
  },
});

const onSave = async () => {
  const newWhiteboard = await whiteboardStore().createWhiteboard(
    whiteboardPayload.value
  );
  emit('createSuccess', newWhiteboard);
};

const inputRef = ref<any>(null);
onMounted(() => {
  inputRef.value?.focus();
});
</script>

<template>
  <SynModal
    z-index="z-50"
    disable-click-outside
    style-body="p-6 flex flex-col space-y-2 "
    :container-class="'w-3/12 min-h-max'"
    :container-style="`min-width: 32rem`"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    @cancel="$emit('cancel')"
    @confirm="onSave"
  >
    <template #header>
      <div class="w-full flex">
        <span>Create new whiteboard</span>
      </div>
    </template>
    <template #body>
      <!-- TITLE -->
      <div class="flex flex-col">
        <input
          ref="inputRef"
          v-model="whiteboardPayload.title"
          class="
            bg-white
            w-full
            h-full
            focus:outline-none
            pb-2
            border-b-2
            focus:border-b-2 focus:border-current
            placeholder-gray-500 placeholder-opacity-75
            text-xl
            border-gray-200
          "
          :placeholder="$t('CALENDAR_LABEL_INPUTVALUE_TITLE')"
          required
        />
      </div>
    </template>
  </SynModal>
</template>
