<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 894.01 297"
  >
    <polygon
      class="cls-1"
      style="fill: #008e8e"
      points="169.89 50.16 148.39 66 148.39 233 191.39 233 191.39 66 169.89 50.16"
    />
    <polygon
      class="cls-2"
      style="fill: #ffb62f"
      points="191.39 59.28 191.39 43.58 169.89 27.35 148.39 43.58 148.39 59.35 148.49 59.35 169.99 43.12 191.39 59.28"
    />
    <polygon
      class="cls-3"
      style="fill: #f2cb05"
      points="191.39 36.93 191.39 21 169.89 4.54 148.39 21 148.39 37 148.49 37 169.99 20.54 191.39 36.93"
    />
    <path
      class="cls-1"
      style="fill: #008e8e"
      d="M1157,623.54l-36.84-40.83A44.19,44.19,0,0,1,1090,541.54a47.24,47.24,0,0,1,6-25.08,43.91,43.91,0,0,1,14.79-15.73c.26-.17.54-.33.8-.49l.49-.29a38.48,38.48,0,0,1,16-5.41c17.55-1.86,29.69,8.86,32,11a43.09,43.09,0,0,1,13,24l39.7,37.7a90.71,90.71,0,0,0,4.3-27.7c0-48.61-38-88-85-88s-85,39.39-85,88,38.06,88,85,88a94,94,0,0,0,18.25-2C1153.19,624.8,1155.54,624,1157,623.54Z"
      transform="translate(-513.46 -391.37)"
    />
    <path
      class="cls-1"
      style="fill: #008e8e"
      d="M1173,529.54a44.72,44.72,0,0,1,1,10c0,3.7-.72,21.32-15,34a44.1,44.1,0,0,1-27,11c-1.61,0-3.25-.14-3.25-.14a39.48,39.48,0,0,1-8.59-1.69L1157,623.54A89.7,89.7,0,0,0,1193,600.88a88.43,88.43,0,0,0,19.76-33.64Z"
      transform="translate(-513.46 -391.37)"
    />
    <path
      class="cls-4"
      style="fill: #005c5c; isolation: isolate; opacity: 0.27"
      d="M1171,521.54a49.49,49.49,0,0,1,3,18c-.08,4.14-.57,18-11,30a47.4,47.4,0,0,1-17,12,36.64,36.64,0,0,1-14,3c-.28,0-1.61,0-3.25-.14a43.88,43.88,0,0,1-17.75-5.86l38,47a84.82,84.82,0,0,0,65-64Z"
      transform="translate(-513.46 -391.37)"
    />
    <path
      class="cls-5"
      style="fill: #005c5c; isolation: isolate; opacity: 0.18"
      d="M1174,537.54a47.47,47.47,0,0,1-1,12,46.6,46.6,0,0,1-6,15c-.81,1.28-11.47,17.53-31,20a40.8,40.8,0,0,1-10,0l34,38a89.62,89.62,0,0,0,34-23,88.46,88.46,0,0,0,17-27Z"
      transform="translate(-513.46 -391.37)"
    />
    <path
      class="cls-3"
      style="fill: #f2cb05"
      d="M1089,540.37a43.17,43.17,0,0,0,1,10,40.65,40.65,0,0,0,5,12,49.38,49.38,0,0,0,14,15,44.88,44.88,0,0,0,7,4,35.32,35.32,0,0,0,20,3,43.57,43.57,0,0,0,19-7,44,44,0,0,0,16-20,47.79,47.79,0,0,0,2-30,46.57,46.57,0,0,0-9-18,43.64,43.64,0,0,0-22-14,41.86,41.86,0,0,0-24,1c-15.4,5.45-22.16,18.36-24,22A49.19,49.19,0,0,0,1089,540.37Z"
      transform="translate(-513.46 -391.37)"
    />
    <path
      class="cls-1"
      style="fill: #008e8e"
      d="M535.46,391.37h42v67h65v42h-65v52a33.46,33.46,0,0,0,27,30h38v43l-41-1a74.37,74.37,0,0,1-66-69v-55h-21q-.51-21-1-42h22Z"
      transform="translate(-513.46 -391.37)"
    />
    <path
      class="cls-1"
      style="fill: #008e8e"
      d="M848.28,522.37l31-32c-2.19-3.26-27.11-39.08-70-39-30.9.06-51.37,18.71-57,24-4.27,4-27,26.15-28,62-.22,7.46,0,32.67,18,56,3.39,4.39,26.78,33.66,67,34,43.1.35,67.73-32.83,70-36l-31-31c-1.24,2.42-9.84,18.48-29,23-13.88,3.26-29.48-.43-40-11-11.28-11.36-11.83-25.71-12-30a48,48,0,0,1,9-30c2-2.67,14.14-19.26,34-19C826.61,493.61,841.84,505.15,848.28,522.37Z"
      transform="translate(-513.46 -391.37)"
    />
    <path
      class="cls-1"
      style="fill: #008e8e"
      d="M920.67,391.37h42v67h65v42h-65v52a33.47,33.47,0,0,0,27,30h38v43l-41-1a74.37,74.37,0,0,1-66-69v-55h-21q-.49-21-1-42h22Z"
      transform="translate(-513.46 -391.37)"
    />
    <path
      class="cls-1"
      style="fill: #008e8e"
      d="M1407.46,539.37c-.08-8.8-.36-39.73-25-64-4.49-4.42-26.23-24.82-60-24-33.57.83-54.24,22-58,26l-17-20h-11v231h43v-75a80.89,80.89,0,0,0,43,15c37,1.57,60.64-24.23,64-28C1407.37,576.82,1407.54,549.17,1407.46,539.37Zm-45.66,18a44,44,0,0,1-16,20,43.57,43.57,0,0,1-19,7,38.92,38.92,0,0,1-10,0,39.36,39.36,0,0,1-10-3,45.34,45.34,0,0,1-7-4,49.63,49.63,0,0,1-14-15,41.19,41.19,0,0,1-5-12,43.72,43.72,0,0,1-1-10,49.32,49.32,0,0,1,5-22c1.84-3.64,8.61-16.55,24-22a41.69,41.69,0,0,1,24-1,43.49,43.49,0,0,1,22,14,46.43,46.43,0,0,1,9,18A47.65,47.65,0,0,1,1361.8,557.38Z"
      transform="translate(-513.46 -391.37)"
    />
  </svg>
</template>
