<script setup lang="ts">
import {
  onMounted,
  watch,
  // computed
} from 'vue';
import Chart from 'chart.js/auto';
// import appStore from '@/store/app';

const props = defineProps({
  customClass: {
    type: String,
    default: 'w-full',
  },
  datasets: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Object,
    default: () => {},
  },
  labelsData: {
    type: Array,
    default: () => [],
  },
});
let verticalBarChart: Chart;
// const _appStore = appStore();
// const language = computed(() => _appStore.language);

const initData = () => {
  const data = {
    labels: props.labelsData,
    datasets: props.datasets,
  };

  const config = {
    type: 'line',
    data: data,
    options: {
      ...props.options,
      locale: 'de',
      maintainAspectRatio: false,
      scales: {
        y: {
          stacked: true,
          grid: {
            display: true,
            color: '#f1f1f1',
          },
        },
        x: {
          grid: {
            display: true,
            color: '#f1f1f1',
          },
        },
      },
      plugins: {
        legend: { display: false },
      },
    },
  };

  const ctx = document.getElementById(
    'tictop-common-vertical-line-chart'
  ) as HTMLCanvasElement;

  if (!ctx) return;

  verticalBarChart = new Chart(ctx, config);
};

onMounted(() => {
  initData();
});

watch(
  () => props.datasets,
  () => {
    verticalBarChart.data.datasets = props.datasets;
    verticalBarChart?.update();
  },
  { deep: true }
);
</script>
<template>
  <div class="h-full w-full space-x-4">
    <div class="relative h-full" :class="customClass">
      <canvas id="tictop-common-vertical-line-chart" class="w-full"></canvas>
    </div>
  </div>
</template>
