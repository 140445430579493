<template>
  <div class="text-sm" :class="size === 'sm' ? 'px-2 py-1' : 'p-2'">
    <div class="flex items-center justify-between space-x-3">
      <span
        v-html="
          $t('CHAT_REPLYING_TO_USER', {
            user: replyToUser?.name,
          }) || `Replying to ${replyToUser?.name}`
        "
      ></span>
      <button
        type="button"
        class="
          w-5
          h-5
          flex-center
          p-0.5
          rounded-full
          hover:text-current-500 hover:bg-current-100
        "
        @click="$emit('onReplyToDelete')"
      >
        <SynIcon name="Close" />
      </button>
    </div>

    <div
      v-if="
        replyToMsg?.text &&
        !replyToMsg?.task &&
        !replyToMsg?.dayoff &&
        !replyToMsg?.eventData
      "
      class="text-gray-500 break-words"
      v-html="replyToMsg.text"
    ></div>
    <div
      v-else-if="
        (replyToMsg?.task && replyToMsg?.comment) ||
        (replyToMsg?.dayoff && replyToMsg?.comment) ||
        (replyToMsg?.eventData && replyToMsg?.comment)
      "
      class="text-gray-500 break-normal"
      v-html="replyToMsg.comment"
    ></div>

    <MessageTask
      v-else-if="replyToMsg?.task && !replyToMsg?.comment"
      class="my-2"
      :task="replyToMsg?.task"
      is-my-message
      :is-comment="!!replyToMsg?.comment"
      :members="members"
      is-readonly
    ></MessageTask>
    <div
      v-else-if="
        replyToMsg?.dayoff && replyToMsg?.dayoff?.id && !replyToMsg?.comment
      "
      class="
        my-0.5
        rounded
        border border-orange-500
        cursor-pointer
        hover:bg-current-50
        bg-white
        max-w-md
        xl:min-w-xs
      "
      :style="{
        borderColor: DayoffStatusUI[replyToMsg?.dayoff?.status]?.color,
      }"
    >
      <DayoffListItem
        :dayoff-data="replyToMsg?.dayoff"
        is-show-title
        is-hidden-name
      />
    </div>

    <div
      v-else-if="
        replyToMsg?.eventData &&
        replyToMsg?.eventData?.id &&
        !replyToMsg?.comment
      "
      class="
        my-0.5
        rounded
        border border-purple-500
        cursor-pointer
        bg-white
        max-w-md
        xl:min-w-xs
      "
    >
      <EventListItem :event-data="replyToMsg?.eventData" />
    </div>

    <div v-else-if="replyMsgFile" class="text-gray-500">{{ replyMsgFile }}</div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue';
import $constants from '@/ui/plugins/constants';
import { translate } from '@/ui/plugins/i18n/myi18n';
import MessageTask from '@/ui/modules/messaging/chat-panel/chat-message/message-task/MessageTask.vue';
import DayoffListItem from '@/ui/components/dayoff/atoms/DayoffListItem.vue';
import { DayoffStatusUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import EventListItem from '@/ui/components/calendars/atoms/EventListItem.vue';

export default defineComponent({
  components: {
    EventListItem,
    MessageTask,
    DayoffListItem,
  },
  props: {
    replyToMsg: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: '',
    },
    members: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['onReplyToDelete'],
  setup(props) {
    onMounted(() => {
      // console.log('replyToMsg', props.replyToMsg);
    });
    const replyToUser = computed(
      () => props.members[props.replyToMsg?.createdBy]
    );

    const replyMsgFile = computed(() => {
      const msgFiles = Object.values(props.replyToMsg?.files || {});

      if (
        msgFiles.every((file) =>
          file?.contentType?.startsWith($constants.FILE.TYPE.IMAGE)
        )
      ) {
        if (msgFiles?.length > 1) {
          return (
            translate('CHAT_REPLYING_NUM_PHOTOS', {
              number: msgFiles?.length,
            }) || `${msgFiles?.length} photos`
          );
        }

        return translate('CHAT_REPLYING_PHOTO') || 'Photo';
      }

      if (msgFiles?.length > 1) {
        return (
          translate('CHAT_REPLYING_NUM_FILES', { number: msgFiles?.length }) ||
          `${msgFiles?.length} files`
        );
      }

      if (msgFiles?.length) {
        return translate('CHAT_REPLYING_FILE') || 'File';
      }

      return null;
    });

    return {
      replyToUser,
      replyMsgFile,
      DayoffStatusUI,
    };
  },
});
</script>
