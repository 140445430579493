<template>
  <svg v-if="isActive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
    <path
      id="Icon_material-screen-share"
      data-name="Icon material-screen-share"
      d="M30,27a3,3,0,0,0,2.985-3L33,9a3,3,0,0,0-3-3H6A2.99,2.99,0,0,0,3,9V24a3,3,0,0,0,3,3H0v3H36V27ZM19.5,21.7V18.42c-4.17,0-6.915,1.275-9,4.08.84-4.005,3.165-7.995,9-8.805V10.5l6,5.595Z"
      transform="translate(0 -6)"
    />
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 31.5">
    <path
      id="Icon_material-stop-screen-share"
      data-name="Icon material-stop-screen-share"
      d="M31.83,27.03l3,3H36v-3Zm1.155-3L33,9.03a3,3,0,0,0-3-3H10.83l7.845,7.845c.27-.06.54-.1.825-.15v-3.2l6,5.6-2.37,2.2,8.31,8.31a2.974,2.974,0,0,0,1.545-2.61ZM3.585,2.6,1.665,4.5l2.31,2.31A2.976,2.976,0,0,0,3,9.03v15a3,3,0,0,0,3,3H0v3H27.195l4.065,4.065,1.905-1.9ZM10.5,22.53a11.588,11.588,0,0,1,3.1-6.09l2.385,2.385A9.34,9.34,0,0,0,10.5,22.53Z"
      transform="translate(0 -2.595)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
