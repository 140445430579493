<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  },
  typeContent: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  labelBtnSave: {
    type: String,
    default: '',
  },
  labelBtnCancel: {
    type: String,
    default: '',
  },
  isShowBtnSave: {
    type: Boolean,
    default: true,
  },
});
defineEmits(['cancel', 'onSave']);

const loading = computed(() => props.isLoading);
</script>
<template>
  <syn-modal
    container-class="max-w-max w-96"
    style-body="p-4"
    z-index="z-60"
    is-hidden-header
    @cancel="$emit('cancel', false)"
  >
    <template #body>
      <div class="flex-center flex-col h-full py-6">
        <div class="flex items-center justify-center flex-col">
          <syn-animation name="infomation" stype="width: 70px;" />
          <span
            v-if="title"
            class="text-base text-center font-normal mb-4"
            v-html="title"
          ></span>

          <div
            v-if="content"
            class="bg-current-50 rounded-md px-4 py-2 text-center"
          >
            <span v-if="typeContent" class="font-medium text-current-500">{{
              typeContent
            }}</span>
            <span v-if="content" class="break-normal">{{ content }}</span>
          </div>

          <slot></slot>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="p-4 flex justify-end items-center cursor-pointer space-x-2">
        <syn-button
          :label="labelBtnCancel ? labelBtnCancel : $t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="$emit('cancel', false)"
        >
        </syn-button>
        <syn-button
          v-if="isShowBtnSave"
          class=""
          custom-class-icon="w-3 h-3"
          :label="labelBtnSave ? labelBtnSave : 'Confirm'"
          :is-loading="loading"
          @click="$emit('onSave', false)"
        >
        </syn-button>
      </div>
    </template>
  </syn-modal>
</template>
