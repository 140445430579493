<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { GroupMemberRole } from '@/ui/common/constants/constant';
import SearchTaskOwners from '@/ui/components/search/SearchTaskOwners.vue';
import { IGroupUser } from '@/application/types/group/group.types';
import UserById from '@/ui/components/user/UserById.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ignoreUnicode } from '@/ui/plugins/utils';
import GroupDetailEntity, {
  IGroupDetailEntity,
} from '@/domain/entities/group/GroupDetailEntity';

const props = withDefaults(
  defineProps<{
    modelValue: IGroupUser[];
    groupDetail: IGroupDetailEntity;
  }>(),
  {}
);

const emit = defineEmits([
  'update:modelValue',
  'eventChange',
  'updatedTotalMember',
]);

// const _groupStore = groupStore();
const isLoading = ref(false);
const groupDetailDataRef = ref<GroupDetailEntity>(
  new GroupDetailEntity(props.groupDetail)
);
onMounted(() => {});

const membersPayload = ref<IGroupUser[]>(props.modelValue);
const searchText = ref('');
const filteredMembersPayload = computed<any>(() => {
  let result = membersPayload.value;

  const searchTxt = ignoreUnicode(searchText.value);

  if (searchTxt)
    return result.filter(
      (user) =>
        (user?.fullName && ignoreUnicode(user?.fullName).includes(searchTxt)) ||
        (user?.firstName &&
          ignoreUnicode(user?.firstName).includes(searchTxt)) ||
        (user?.lastName && ignoreUnicode(user?.lastName).includes(searchTxt)) ||
        (user?.name && ignoreUnicode(user?.name).includes(searchTxt))
    );

  return result;
});

const toggleAdminGroup = (id, oldRole) => {
  const index = membersPayload.value?.findIndex((u) => u?.userId == id);
  if (index == -1) return;

  if (!hasValidMembers(oldRole == GroupMemberRole.Admin ? -1 : +1)) return;

  membersPayload.value[index].role =
    oldRole == GroupMemberRole.Admin
      ? GroupMemberRole.User
      : GroupMemberRole.Admin;

  emit('update:modelValue', membersPayload.value);
};

const hasValidMembers = (addAdmin?) => {
  if (
    membersPayload.value.filter((e) => e.role == GroupMemberRole.Admin).length +
      (addAdmin || 0) <=
    0
  ) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMON_NOT_ALLOW_PERFORM_ACTION'),
      content: translate('GROUP_UPDATE_ADMIN_NOTALLOW'),
      confirmable: true,
    });
    ask();

    return false;
  }

  return true;
};

const isRemoveConfirmed = ref<boolean>(false);

const removeMember = async (user) => {
  if (!hasValidMembers(user?.role == GroupMemberRole.Admin ? -1 : 0)) return;

  let isConfirmed = true;
  if (!isRemoveConfirmed.value)
    isConfirmed = await groupDetailDataRef.value.confirmBeforeRemoveMember(
      {},
      {
        isConfirmOnly: true,
      }
    );

  if (!isConfirmed) return;

  isRemoveConfirmed.value = true;

  membersPayload.value = membersPayload.value?.filter(
    (u) => u?.userId !== user?.userId
  );

  emit('update:modelValue', membersPayload.value);
};

const onChooseOwner = (data) => {
  const index = membersPayload.value?.findIndex((u) => u?.userId == data?.id);
  if (index > -1)
    membersPayload.value = membersPayload.value?.filter(
      (u) => u?.userId !== data?.id
    );
  else
    membersPayload.value.push({
      ...data,
      userId: data?.id,
      role: GroupMemberRole.User,
    });

  emit('update:modelValue', membersPayload.value);
};
</script>
<template>
  <div class="pl-2 flex-1 overflow-hidden grid gap-3 grid-cols-2">
    <div class="h-full small-scrollbar overflow-auto px-2">
      <SearchTaskOwners
        :owner-type-list="['USER']"
        is-hidden-action-item
        is-select-multiple
        is-filter-when-selected
        is-hidden-selected-owners
        :selected-ids="membersPayload?.map((u) => `USER_${u?.userId}`)"
        @on-choose-owner="onChooseOwner"
      />
    </div>

    <template v-if="membersPayload.length < 1">
      <div class="h-full small-scrollbar overflow-auto pr-2">
        <div v-if="isLoading" class="flex-center space-x-3 mt-3">
          <div class="w-8 p-2 bg-gray-200 rounded-lg animate-pulse"></div>
          <div class="w-full bg-gray-200 p-2 rounded-lg animate-pulse"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="h-full small-scrollbar overflow-auto pr-2 relative">
        <div class="h-10 z-10 sticky top-0 bg-white pt-2">
          <VigSearchBox
            v-model="searchText"
            input-class="text-sm"
            style="width: 100%"
            autofocus
            :placeholder="$t('COMMON_LABEL_SEARCH')"
          />
        </div>
        <div class="font-semibold pt-2">
          <span class="text-sm font-semibold">
            {{ $t('SETTING_PLAN_PAYMENT_TOTAL_MEMBER_LABEL') }}:
            {{ membersPayload?.length || 0 }}
          </span>
        </div>
        <template
          v-if="filteredMembersPayload && filteredMembersPayload?.length > 0"
        >
          <div
            v-for="user in filteredMembersPayload"
            :key="user?.userId"
            class="
              py-2
              cursor-pointer
              my-1
              p-0.5
              custom-display
              bg-current-50 bg-opacity-50
              rounded-md
              hover-to-show__parent
              hover-to-hidden__parent
            "
          >
            <div class="flex justify-between items-center px-2">
              <div
                class="
                  flex-1 flex
                  items-center
                  justify-between
                  w-1/2
                  space-x-2
                  font-sans
                  text-sm
                "
              >
                <div
                  class="flex w-max items-center space-x-2 font-sans text-sm"
                >
                  <UserById
                    :user-id="user.userId"
                    class="flex-1"
                    avatar-class="w-8 h-8"
                  />
                </div>
              </div>

              <!--  TYPE -->
              <div class="flex items-center">
                <div>
                  <template v-if="user.role === GroupMemberRole.Admin">
                    <span
                      v-if="user.role === GroupMemberRole.Admin"
                      class="
                        rounded-md
                        text-xs
                        py-0.5
                        px-1
                        text-green-700
                        font-semibold
                        hover-to-hidden__children
                      "
                    >
                      {{ $t('COMMON_LABEL_ADMIN') }}
                    </span>
                  </template>
                  <small
                    class="
                      hover:font-medium
                      italic
                      action-text
                      pr-2
                      hover-to-show__children
                    "
                    :class="
                      user.role === GroupMemberRole.Admin
                        ? 'text-red-500 hover:text-red-700 '
                        : ' text-green-500 hover:text-green-700 '
                    "
                    @click="toggleAdminGroup(user.userId, user?.role)"
                  >
                    <span>{{
                      $t(
                        user.role === GroupMemberRole.Admin
                          ? 'GROUP_UPDATE_MEMBER_UNSET_ADMIN'
                          : 'GROUP_UPDATE_MEMBER_SET_ADMIN'
                      )
                    }}</span>
                  </small>
                </div>
                <SynIcon
                  v-vig-tooltip="$t('COMMON_LABEL_DELETE')"
                  has-action
                  name="cancel"
                  color="gray"
                  custom-class="fill-gray-500 w-2 h-2"
                  @click="removeMember(user)"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped>
.item-domain:hover .item-cancel {
  display: block;
}

.item-cancel {
  display: none;
}

.custom-display:hover .action-text {
  display: block;
}

.action-text {
  display: none;
}
</style>
