import {
    ETaskMonthlySubRepeatTypeModel,
    ETaskRepeatType,
} from '@/application/interfaces/tasks/task-interfaces';

export const repeatTypeByKeys = {
    [ETaskRepeatType.None]: {
        key: ETaskRepeatType.None,
        nameCode: 'TASK_LABEL_NO_RECURRENCE',
        name: 'No recurrence',
    },
    [ETaskRepeatType.Daily]: {
        key: ETaskRepeatType.Daily,
        nameCode: 'COMMON_LABEL_DAILY',
        name: 'Daily',
    },
    [ETaskRepeatType.Weekly]: {
        key: ETaskRepeatType.Weekly,
        nameCode: 'COMMON_LABEL_WEEKLY',
        name: 'Weekly',
    },
    [ETaskRepeatType.Monthly]: {
        key: ETaskRepeatType.Monthly,
        nameCode: 'COMMON_LABEL_MONTHLY',
        name: 'Monthly',
    },
    [ETaskRepeatType.Yearly]: {
        key: ETaskRepeatType.Yearly,
        nameCode: 'COMMON_LABEL_YEARLY',
        name: 'Yearly',
    },
};
export const repeatDayInWeekByKeys = {
    0: {
        key: 0,
        nameCode: 'COMMON_LABEL_DATE_SU',
        shortNameCode: 'COMMON_SHORT_LABEL_DATE_SUNDAY',
        name: 'Sunday',
        shortName: 'S',
    },
    1: {
        key: 1,
        nameCode: 'COMMON_LABEL_DATE_MO',
        shortNameCode: 'COMMON_SHORT_LABEL_DATE_MONDAY',
        name: 'Monday',
        shortName: 'M',
    },
    2: {
        key: 2,
        nameCode: 'COMMON_LABEL_DATE_TU',
        shortNameCode: 'COMMON_SHORT_LABEL_DATE_TUESDAY',
        name: 'Tuesday',
        shortName: 'T',
    },
    3: {
        key: 3,
        nameCode: 'COMMON_LABEL_DATE_WE',
        shortNameCode: 'COMMON_SHORT_LABEL_DATE_WEDNESDAY',
        name: 'Wednesday',
        shortName: 'W',
    },
    4: {
        key: 4,
        nameCode: 'COMMON_LABEL_DATE_TH',
        shortNameCode: 'COMMON_SHORT_LABEL_DATE_THURSDAY',
        name: 'Thusday',
        shortName: 'T',
    },
    5: {
        key: 5,
        nameCode: 'COMMON_LABEL_DATE_FR',
        shortNameCode: 'COMMON_SHORT_LABEL_DATE_FRIDAY',
        name: 'Friday',
        shortName: 'F',
    },
    6: {
        key: 6,
        nameCode: 'COMMON_LABEL_DATE_SA',
        shortNameCode: 'COMMON_SHORT_LABEL_DATE_SATURDAY',
        name: 'Saturday',
        shortName: 'S',
    },
};

export const repeatDayInMonthByKeys = {
    [ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth]: {
        key: ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth,
        nameCode: 'TASK_LABEL_REPEAT_EXACT_DAY',
        name: 'Same day each month',
    },
    [ETaskMonthlySubRepeatTypeModel.FirstSameDayOfWeekInMonth]: {
        key: ETaskMonthlySubRepeatTypeModel.FirstSameDayOfWeekInMonth,
        nameCode: 'TASK_LABEL_REPEAT_FIRST',
        name: 'First',
    },
    [ETaskMonthlySubRepeatTypeModel.SecondSameDayOfWeekInMonth]: {
        key: ETaskMonthlySubRepeatTypeModel.SecondSameDayOfWeekInMonth,
        nameCode: 'TASK_LABEL_REPEAT_SECOND',
        name: 'Second',
    },
    [ETaskMonthlySubRepeatTypeModel.ThirdSameDayOfWeekInMonth]: {
        key: ETaskMonthlySubRepeatTypeModel.ThirdSameDayOfWeekInMonth,
        nameCode: 'TASK_LABEL_REPEAT_THIRD',
        name: 'Third',
    },
    [ETaskMonthlySubRepeatTypeModel.FourthSameDayOfWeekInMonth]: {
        key: ETaskMonthlySubRepeatTypeModel.FourthSameDayOfWeekInMonth,
        nameCode: 'TASK_LABEL_REPEAT_FOURTH',
        name: 'Fourth',
    },
    [ETaskMonthlySubRepeatTypeModel.LastSameDayOfWeekInMonth]: {
        key: ETaskMonthlySubRepeatTypeModel.LastSameDayOfWeekInMonth,
        nameCode: 'TASK_LABEL_REPEAT_LAST',
        name: 'Last',
    },
    [ETaskMonthlySubRepeatTypeModel.FirstDayOfTheMonth]: {
        key: ETaskMonthlySubRepeatTypeModel.FirstDayOfTheMonth,
        nameCode: 'TASK_LABEL_REPEAT_FIRST_DAY_OF_MONTH',
        name: 'First day of month',
    },
    [ETaskMonthlySubRepeatTypeModel.LastDayOfTheMonth]: {
        key: ETaskMonthlySubRepeatTypeModel.LastDayOfTheMonth,
        nameCode: 'TASK_LABEL_REPEAT_LAST_DAY_OF_MONTH',
        name: 'Last day of month',
    },
};
