<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ILinkInfo } from '@/ui/common/atoms/atom-attach-link/atom-attach-link.types';
import { getDomainLink } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  linkUrl: string;
  linkInfo: ILinkInfo;
  disableRemoveButton?: boolean;
}>();

const emit = defineEmits(['onClosePreviewLink']);

onMounted(async () => {
  // console.log('link', props.link);
});

const onClosePreviewLink = (e) => {
  e.preventDefault();
  emit('onClosePreviewLink');
};

const isImageError = ref(false);
const onChangeImageError = () => {
  isImageError.value = true;
};

const imageUrl = computed<string>(() => {
  return props.linkInfo?.imageUrl || props.linkInfo?.favicon;
});
</script>

<template>
  <a
    :href="linkUrl"
    target="_blank"
    class="border bg-white flex align-center rounded h-16 relative w-full"
    draggable="false"
  >
    <div class="w-1/4 h-full p-1 rounded flex-center">
      <img
        v-if="imageUrl && !isImageError"
        :src="imageUrl"
        :onError="onChangeImageError"
        class="object-cover w-auto h-full rounded"
        draggable="false"
      />
      <div
        v-else
        class="bg-gray-200 bg-opacity-60 w-full h-full rounded flex-center"
      >
        <syn-icon name="image-thumb" custom-class="w-8 h-8"></syn-icon>
      </div>
    </div>
    <div class="w-3/4 flex flex-col py-1 pr-2 pl-0.5">
      <div class="w-full flex flex-col space-y-1 flex-1">
        <div
          :title="linkInfo?.title || linkUrl"
          class="font-medium truncate text-sm"
        >
          {{ linkInfo?.title || linkUrl }}
        </div>
        <div
          v-if="linkInfo?.description"
          :title="linkInfo?.description"
          class="text-gray-600 text-xs truncate"
        >
          {{ linkInfo?.description }}
        </div>
      </div>
      <div class="w-full flex justify-end">
        <div class="flex space-x-1 items-center pb-1">
          <syn-icon
            name="link"
            custom-class="w-2.5 h-2.5 fill-gray-500"
          ></syn-icon>
          <span class="text-gray-500 text-2xs align-bottom truncate">
            {{ getDomainLink(linkUrl) }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="!disableRemoveButton"
      :title="$t('CHAT_HIDE_PREVIEW_LINK')"
      class="
        absolute
        flex-center
        -right-2
        -top-2
        p-1
        m-1
        rounded-full
        bg-gray-400
        fill-white
        text-white
        cursor-pointer
      "
      @click.stop="onClosePreviewLink"
    >
      <SynIcon name="Close" custom-class="w-3 h-3" />
    </div>
  </a>
</template>
