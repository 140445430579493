<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M30 1H2a1 1 0 0 0 0 2h28a1 1 0 0 0 0-2zM26 22a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3zm-7-3V8a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1zM12 5H6a3 3 0 0 0-3 3v19a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3zm1 22a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"
        opacity="1"
        class=""
      ></path>
    </g>
  </svg>
</template>
