export interface IDesktopDrawerShapeGroup {
    id: string;
    name?: string;
    translateX: number;
    translateY: number;
    width: number;
    height: number;
    shapes: IDesktopDrawerShape[];

    addMembers(shapes: IDesktopDrawerShape[]): void;
}

export interface IDesktopDrawerShape {
    id: string;
    groupId?: string;
    type: ShapeType;
    translateX: number;
    translateY: number;
    zIndex: number;
    width: number;
    height: number;
    sourceId?: any;
    sourceData?: any;

    changePosition(newTranslateX, newTranslateY): void;
    resize(newWidth: number, newHeight: number): void;
    setGroup(groupId): void;
    actionUnGroup(): void;
    updateSize(newWidth: number, newHeight: number): void;
    updateSourceData(data: any): void;
}

export enum ShapeType {
    TASK = 'TASK',
    USER = 'USER',
    TODO_LIST = 'TODO_LIST',
    TEXT_NOTE = 'TEXT_NOTE',
    DRAW_NOTE = 'DRAW_NOTE',
    TEXT = 'TEXT',
    ARROW = 'ARROW',
    MEDIA_AUDIO = 'MEDIA_AUDIO',
    MEDIA_IMAGE = 'MEDIA_IMAGE',
    MEDIA_DOCUMENT = 'MEDIA_DOCUMENT',
    MEDIA_VIDEO = 'MEDIA_VIDEO',
    MEDIA_LINK = 'MEDIA_LINK',
}
export enum EWhiteboardTool {
    TASK = 'TASK',
    USER = 'USER',
    TODO_LIST = 'TODO_LIST',
    TEXT_NOTE = 'TEXT_NOTE',
    DRAW_NOTE = 'DRAW_NOTE',
    TEXT = 'TEXT',
    ARROW = 'ARROW',
    MEDIA_AUDIO = 'MEDIA_AUDIO',
    MEDIA_IMAGE = 'MEDIA_IMAGE',
    MEDIA_DOCUMENT = 'MEDIA_DOCUMENT',
    MEDIA_VIDEO = 'MEDIA_VIDEO',
    MEDIA_LINK = 'MEDIA_LINK',
    RECYCLE_CAN = 'RECYCLE_CAN',
    SELECT = 'SELECT',
    TOOL_DRAG = 'TOOL_DRAG',
}
