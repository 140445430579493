<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <linearGradient
        id="linear-gradient"
        xmlns="http://www.w3.org/2000/svg"
        gradientUnits="userSpaceOnUse"
        x1="2.502"
        x2="61.517"
        y1="32"
        y2="32"
      >
        <stop offset="0" stop-color="#ffde00"></stop>
        <stop offset="1" stop-color="#fd5900"></stop>
      </linearGradient>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m61.38 24.49-6.85 26.37a3.57212 3.57212 0 0 1 -3.45 2.67h-38.16a3.57212 3.57212 0 0 1 -3.45-2.67l-6.85-26.37a3.56411 3.56411 0 0 1 4.73-4.23l11.35 4.37a.575.575 0 0 0 .66-.18l9.83-12.6a3.55089 3.55089 0 0 1 5.62 0l9.83 12.6a.575.575 0 0 0 .66.18l11.35-4.37a3.59149 3.59149 0 0 1 4.73 4.23z"
        class=""
      ></path>
    </g>
  </svg>
</template>
