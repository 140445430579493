<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: {
      hour: string;
      minute: string;
      period: 'AM' | 'PM';
    };
    isAutoRun?: boolean;
    isHaveAction?: boolean;
  }>(),
  {}
);

const emit = defineEmits<{
  (
    e: 'update:modelValue',
    value: {
      hour: string;
      minute: string;
      period: 'AM' | 'PM';
    }
  ): void;
  (e: 'onChoose', value: number): void;
}>();
// Get references to DOM elements

const analogLockRef = ref<any>(null);

const currentModelValue = ref<{
  hour: string;
  minute: string;
  period: 'AM' | 'PM';
}>(props.modelValue);

const initData = (newValue) => {
  currentModelValue.value = { ...newValue };
  updateHandPosition();
};

onMounted(() => {
  initData(props.modelValue);

  if (props.isAutoRun) setInterval(updateHandPosition, 1000);
});
const updateHandPosition = () => {
  const hourHand: any = document.querySelector('.hour'),
    minuteHand: any = document.querySelector('.minute');

  // Get current time and calculate degrees for clock hands
  const hrToDeg = (parseInt(currentModelValue.value.hour) / 12) * 360;
  const minToDeg = (parseInt(currentModelValue.value.minute) / 60) * 360;
  // Rotate the clock hands to the appropriate degree based on the current time
  if (hourHand?.style) hourHand.style.transform = `rotate(${hrToDeg}deg)`;
  if (minuteHand?.style) minuteHand.style.transform = `rotate(${minToDeg}deg)`;
};
// call updateTime to set clock hands every second
//call updateTime function on page load

const labelClassByAction = computed<string[]>(() => {
  let result = ['w-8 h-8 text-center leading-8'];
  if (props.isHaveAction)
    result.push(
      'cursor-pointer hover:text-current-600 pointer-events-auto rounded-full hover:bg-current-100 '
    );
  return result;
});
const minuteLabelClassByAction = computed<string[]>(() => {
  let result = ['h-6 w-6 text-center leading-2'];
  if (props.isHaveAction)
    result.push(
      'cursor-pointer hover:text-current-600 pointer-events-auto rounded-full hover:bg-current-100 '
    );
  return result;
});

const labelByHour = computed<number[]>(() => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
});
const labelByMinute = computed<number[]>(() => {
  return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 0];
});

const isShowHourHand = computed<boolean>(() => {
  return true;
});
const isShowMinuteHand = computed<boolean>(() => {
  return true;
});
const isShowSecondHand = computed<boolean>(() => {
  return false;
});

const onClickToHourLabel = (item: number) => {
  currentModelValue.value.hour = `0${item}`.slice(-2);
  updateHandPosition();
  updateSelectedTime();
};
const onClickToMinuteLabel = (item: number) => {
  currentModelValue.value.minute = `0${item}`.slice(-2);
  updateHandPosition();
  updateSelectedTime();
};

const updateSelectedTime = () => {
  emit('update:modelValue', { ...currentModelValue.value });
};

defineExpose({
  initData,
});
</script>
<template>
  <div class="rss-analog-clock_container">
    <div ref="analogLockRef" class="rss-analog-clock_clock shadow">
      <label
        v-for="(item, index) in labelByHour"
        :key="item"
        :style="`--i: ${index + 1}`"
        class="hour-label"
      >
        <span
          :class="[
            labelClassByAction,
            parseInt(currentModelValue.hour) == item
              ? 'bg-current-50 text-current-600'
              : '',
          ]"
          @click.stop="onClickToHourLabel(item)"
        >
          {{ item }}
        </span>
      </label>
      <label
        v-for="(item, index) in labelByMinute"
        :key="item"
        :style="`--i: ${index + 1}`"
        class="minute-label"
      >
        <span
          :class="[
            minuteLabelClassByAction,
            parseInt(currentModelValue.minute) == item
              ? 'bg-current-50 text-current-600'
              : '',
          ]"
          @click.stop="onClickToMinuteLabel(item)"
        >
          {{ item }}
        </span>
      </label>

      <div class="indicator flex-center pointer-events-none">
        <span v-if="isShowHourHand" class="hand hour"></span>
        <span v-if="isShowMinuteHand" class="hand minute"></span>
        <span v-if="isShowSecondHand" class="hand second"></span>
      </div>
      <div
        class="bg-white h-6 w-16 flex-center absolute rounded-md border text-sm"
      >
        <span
          class="w-1/2 h-full text-center cursor-pointer text-current-500"
          :class="
            currentModelValue.period == 'AM'
              ? 'bg-current-100 text-current-600 hover:text-current-500 font-semibold'
              : 'hover:bg-current-50 hover:text-current-500'
          "
          @click="[(currentModelValue.period = 'AM'), updateSelectedTime()]"
        >
          AM
        </span>
        <span
          class="w-1/2 h-full text-center cursor-pointer text-current-500"
          :class="
            currentModelValue.period == 'PM'
              ? 'bg-current-100 text-current-600 hover:text-current-500 font-semibold'
              : 'hover:bg-current-50 hover:text-current-500'
          "
          @click="[(currentModelValue.period = 'PM'), updateSelectedTime()]"
        >
          PM
        </span>
      </div>
    </div>
  </div>
</template>
<style scoped>
:root {
  --primary-color: #f6f7fb;
  --white-color: #fff;
  --black-color: #18191a;
  --red-color: #e74c3c;
}
.rss-analog-clock_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.rss-analog-clock_container .rss-analog-clock_clock {
  display: flex;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: var(--white-color);
  position: relative;
}
.rss-analog-clock_clock .hour-label {
  position: absolute;
  inset: 0px;
  text-align: center;
  transform: rotate(calc(var(--i) * (360deg / 12)));
  pointer-events: none;
}
.rss-analog-clock_clock .hour-label span {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  color: var(--black-color);
  transform: rotate(calc(var(--i) * (-360deg / 12)));
}
.rss-analog-clock_clock .minute-label {
  position: absolute;
  inset: 32px;
  text-align: center;
  transform: rotate(calc(var(--i) * (360deg / 12)));
  pointer-events: none;
}
.rss-analog-clock_clock .minute-label span {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: var(--black-color);
  transform: rotate(calc(var(--i) * (-360deg / 12)));
}
.rss-analog-clock_container .indicator {
  position: absolute;
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
}

.indicator .hand {
  position: absolute;
  height: 82px;
  width: 1px;
  bottom: 0;
  border-radius: 25px;
  transform-origin: bottom;
  background: var(--red-color);
}
.hand.minute {
  height: 70px;
  width: 2px;
  background: var(--black-color);
}
.hand.hour {
  height: 52px;
  width: 3px;
  background: var(--black-color);
}
.mode-switch {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 400;
  display: inline-block;
  color: var(--white-color);
  background: var(--black-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>
