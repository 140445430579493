<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import LogoBrand from '@/ui/components/layouts/LogoBrand.vue';
import UserOrLogin from '@/ui/components/layouts/user-or-login/UserOrLogin.vue';
import { useRoute } from 'vue-router';
import SettingLanguageSmall from '@/ui/components/setting/SettingLanguageSmall.vue';

const isInitializing = ref<boolean>(false);

const _route = useRoute();
const _myProfileStore = myProfileStore();

const isHeaderFluid = computed(() =>
  ['CalendarSharingPage'].includes(_route.name)
);

onMounted(async () => {
  // isInitializing.value = true;

  try {
    const token = getLocalStorage(StorageConstant.TOKEN);

    if (token) await _myProfileStore.fetchCurrentLoginUser();
  } catch (e) {
    console.log(e);
  }

  // isInitializing.value = false;
});
</script>

<template>
  <SynLoading v-if="isInitializing" />

  <template v-else>
    <div class="fixed top-0 bottom-0 left-0 right-0">
      <div class="h-full relative overflow-auto small-scrollbar">
        <!--HEADER-->
        <div class="sticky top-0 bg-white shadow z-20 page-header">
          <div
            class="mx-auto px-2 py-2 flex items-center justify-between"
            :class="{ 'max-w-screen-lg': !isHeaderFluid }"
          >
            <router-link to="/" class="flex items-center gap-2">
              <LogoBrand />
            </router-link>

            <div class="flex-center gap-2">
              <UserOrLogin />
              <SettingLanguageSmall is-hidden-label button-class="px-2 py-1"/>
            </div>
          </div>
        </div>

        <div class="relative">
          <slot></slot>
        </div>
      </div>
    </div>
  </template>
</template>
