<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import {
  ScheduleDayOptions,
  ScheduleOtherTimeOptions,
} from '@/domain/enums/taskEnum';
import taskStore from '@/store/task';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import dayjs from 'dayjs';
import useUpdateDeadlineComposables from '@/ui/modules/task/detail/update-deadline/use-update-deadline.composables';
import ScheduleDatePicker from '@/ui/modules/task/detail/update-deadline/ScheduleDatePicker.vue';

const props = defineProps<{
  userId?: string | number;
  groupId?: string | number;
  scheduleKey?: number;
  otherTime?: number | null;
  schedule?: string;
  scheduleTimezone?: string;
  startTime?: string;
  isGetScheduleTimeByDefault?: boolean;
}>();

const emit = defineEmits([
  'cancel',
  'save',
  'isOpenChoose',
  'saveRealtime',
  'onChangeScheduleTime',
]);

const _taskStore = taskStore();

const taskDataConfigScheduleDayOptions = computed(
  () =>
    _taskStore.dataConfigs?.scheduleDayOptions?.filter(
      (o) => o?.active && o.key !== ScheduleDayOptions.OtherTime
    ) || []
);
const taskDataConfigOtherTimeOption = computed(() => {
  return (
    _taskStore.dataConfigs?.scheduleOtherTimeOptions?.filter(
      (o) => o?.active && o.key !== ScheduleOtherTimeOptions.SpecificDate
    ) || []
  );
});
const userOtherDay = computed(() => {
  const index = userDayInfo.value.findIndex(
    (o) => o?.scheduleOptionKey == ScheduleDayOptions.OtherTime
  );
  if (index > -1) {
    return userDayInfo.value[index].otherTimes;
  }
  return [];
});
const textDateChooseActive = computed(() => {
  if (scheduleOptionKey.value !== ScheduleDayOptions.OtherTime) return '';
  if (otherTimeKey.value === ScheduleOtherTimeOptions.SpecificDate) {
    return scheduleTime.value
      ? dayjs(scheduleTime.value)?.format('DD/MM/YYYY')
      : '';
  }
  const index = taskDataConfigOtherTimeOption.value?.findIndex(
    (o) => o.key == otherTimeKey.value
  );
  if (index > -1) return taskDataConfigOtherTimeOption.value[index].text;
  return '';
});
const scheduleOptionKey = ref(ScheduleDayOptions.OnDay as ScheduleDayOptions);
const otherTimeKey = ref<ScheduleOtherTimeOptions | undefined>(undefined);
const scheduleTime = ref('' as string);
const isOpenDropdownOtherTime = ref(false);
const isAutoFocus = ref(false);
const dateSpecificPickerRef = ref(null as any);
const dropdownSpecificDateRef = ref(null as any);

const { userDayInfo, getUserDay } = useUpdateDeadlineComposables();

const initComponent = async () => {
  if (props.userId) await getUserDay(props.userId);
  scheduleOptionKey.value = props.scheduleKey || 0;
  otherTimeKey.value = props.otherTime || undefined;
  scheduleTime.value =
    props.scheduleTimezone && props.schedule
      ? dayjs(props.schedule).tz(props.scheduleTimezone).format('YYYY-MM-DD')
      : props.schedule || '';

  scheduleTimeInput.value = props.schedule || '';

  if (props.isGetScheduleTimeByDefault) {
    onChooseDateSpecific(props.schedule);
  }
};

watch(
  () => props.scheduleKey,
  () => {
    if (props.scheduleKey !== scheduleOptionKey.value) initComponent();
  }
);
onMounted(async () => {
  dateSpecificPickerRef.value?.hidePopover();
});

const onChangeSchedule = (key?) => {
  scheduleTime.value = getScheduleTime(key);
  otherTimeKey.value = undefined;
  onSave();
};

const getScheduleTime = (_key?) => {
  let time;
  const key = _key >= 0 ? _key : scheduleOptionKey.value;

  const index = userDayInfo.value?.findIndex(
    (o) => o?.scheduleOptionKey == key
  );

  if (index > -1) {
    time = userDayInfo.value[index].scheduleDateValue;
  }
  return time;
};

const getScheduleOtherTime = () => {
  let time;
  const key = otherTimeKey.value;
  const options: any[] = [...(userOtherDay.value || [])];

  const index = options?.findIndex((o) => o.otherTime == key);
  if (index > -1) {
    time = options[index]?.scheduleDateValue;
  }

  return time;
};

const onChangeOtherTime = () => {
  scheduleOptionKey.value = ScheduleDayOptions.OtherTime;
  scheduleTime.value = getScheduleOtherTime();

  onSave();
};

const onChooseDateSpecific = (date) => {
  if (!date) {
    scheduleOptionKey.value = ScheduleDayOptions.Nothing;
    onChangeSchedule(ScheduleDayOptions.Nothing);
    return;
  }
  let dateFormat = dayjs.utc(date).format('YYYY/MM/DD');

  scheduleTime.value = dayjs.utc(dateFormat).format();
  if (props.userId) {
    const dayInfoList = [
      ...userDayInfo.value,
      // ...userOtherDay.value,
    ]?.filter((o) => o.scheduleDateValue);

    const findDateInData = dayInfoList?.find(
      (o) => o?.scheduleDateValue == dateFormat
    );
    console.log('🚀 Tictop ~ findDateInData:', dayInfoList, dateFormat);

    if (findDateInData) {
      scheduleOptionKey.value = Number.isInteger(
        findDateInData?.scheduleOptionKey
      )
        ? findDateInData?.scheduleOptionKey
        : ScheduleDayOptions.OtherTime;

      otherTimeKey.value =
        findDateInData?.otherTime || ScheduleOtherTimeOptions.SpecificDate;
    } else {
      scheduleOptionKey.value = ScheduleDayOptions.OtherTime;

      otherTimeKey.value = ScheduleOtherTimeOptions.SpecificDate;
    }
  } else {
    scheduleOptionKey.value = ScheduleDayOptions.OtherTime;

    otherTimeKey.value = ScheduleOtherTimeOptions.SpecificDate;
  }

  onSave();
};

const deadlineData = ref<{
  startTime?: string;
  scheduleTime?: string;
  updateMode?: 'START_ONLY' | 'END_ONLY' | 'BOTH';
}>({
  updateMode: 'END_ONLY',
});
const onChangeRangeTime = (data: {
  startTime?: string;
  scheduleTime?: string;
  updateMode?: 'START_ONLY' | 'END_ONLY' | 'BOTH';
}) => {
  deadlineData.value = data;

  switch (data?.updateMode) {
    case 'START_ONLY':
      onSave();
      break;

    default:
      onChooseDateSpecific(data?.scheduleTime);
      break;
  }
};

const onSave = () => {
  const today = props.userId
    ? userDayInfo.value?.find(
        (o) => o?.scheduleOptionKey == ScheduleDayOptions.OnDay
      ) || { scheduleDateValue: Date.now() }
    : { scheduleDateValue: Date.now() };
  const overdue = scheduleTime.value
    ? dayjs.utc(today?.scheduleDateValue).diff(scheduleTime.value, 'd')
    : 0;

  emit('save', {
    scheduleOptionKey: scheduleOptionKey.value,
    otherTimeKey: otherTimeKey.value,
    scheduleTime: scheduleTime.value ? scheduleTime.value : '',
    overdue,
    startTime: deadlineData.value?.startTime,
    updateMode: deadlineData?.value?.updateMode,
  });
  emit('isOpenChoose', false);

  const doNotCloseDropdown =
    (deadlineData.value?.updateMode == 'END_ONLY' &&
      scheduleOptionKey.value == ScheduleDayOptions.Nothing) ||
    (deadlineData.value?.updateMode == 'START_ONLY' &&
      !deadlineData.value?.startTime);

  if (!doNotCloseDropdown) dropdownSpecificDateRef.value?.onForceClose();
};

// const onSaveRealtime = () => {
//   emit('saveRealtime', {
//     scheduleOptionKey: scheduleOptionKey.value,
//     otherTimeKey: otherTimeKey.value,
//     scheduleTime: scheduleTime.value,
//   });
//   // emit('isOpenChoose', false);
//   // dropdownSpecificDateRef.value.onForceClose();
// };
//
// const onCancel = () => {
//   scheduleOptionKey.value = props.scheduleKey || 0;
//   otherTimeKey.value = props.otherTime || 0;
//   scheduleTime.value = props.schedule || '';
//   isOpenDropdownOtherTime.value = false;
//   // dropdownSpecificDateRef.value.onForceClose();
// };

const onCloseDropdownOtherTime = () => {
  isOpenDropdownOtherTime.value = false;
  isAutoFocus.value = false;
  // onSave();
};

const onOpenDropdownOtherTime = () => {
  scheduleTimeInput.value = scheduleTime.value;
  scheduleTimeCalendar.value = scheduleTime.value;
  isOpenDropdownOtherTime.value = true;
  isAutoFocus.value = true;
  emit('isOpenChoose', true);
};

const scheduleTimeInput = ref<string>();
const scheduleTimeCalendar = ref<string>();
initComponent();

const updateScheduleTime = (key) => {
  scheduleTime.value = getScheduleTime(key);

  emit(
    'onChangeScheduleTime',
    scheduleTime.value ? dayjs(scheduleTime.value).format('YYYY-MM-DD') : ''
  );
  dropdownSpecificDateRef.value?.onForceClose();
};

const onCloseDatePicker = () => {
  dropdownSpecificDateRef.value?.onForceClose();
};
defineExpose({
  initComponent,
  updateScheduleTime,
  onChooseDateSpecific,
});
</script>
<template>
  <div class="flex flex-col relative">
    <div v-if="userId" class="flex flex-col w-full">
      <div class="flex items-center w-full pb-1">
        <div class="flex-1 flex flex-wrap items-center">
          <SynRadioTag
            v-for="schedule in taskDataConfigScheduleDayOptions"
            :key="schedule.key"
            v-model="scheduleOptionKey"
            :value="schedule.key"
            :label="schedule.text"
            name="task-create-form_schedule"
            radio-name="task-create-form_priority"
            class="mr-1 mb-0.5 mt-0.5 relative"
            @change="onChangeSchedule"
          >
            <SynTag size="circle-small" custom-class="py-1 px-2">
              <span>{{ schedule.text }}</span>
            </SynTag>
          </SynRadioTag>

          <!-- OTHER TIME         -->
          <VigDropdown
            ref="dropdownSpecificDateRef"
            placement="top"
            hide-on-click="outside"
            @on-dropdown-open="onOpenDropdownOtherTime"
            @on-dropdown-close="onCloseDropdownOtherTime"
          >
            <template #dropdown-toggle>
              <div
                class="
                  flex-center
                  cursor-pointer
                  border border-current-50
                  rounded-full
                "
                :class="`${
                  scheduleTime &&
                  scheduleTime !== 'Invalid Date' &&
                  scheduleOptionKey === ScheduleDayOptions.OtherTime
                    ? 'bg-current fill-white text-white hover:bg-current-500 px-2 py-1'
                    : 'fill-gray-500 text-gray-500 hover:bg-current-50 p-1 h-6 w-6'
                }`"
              >
                <span
                  v-if="
                    scheduleTime &&
                    scheduleTime !== 'Invalid Date' &&
                    scheduleOptionKey === ScheduleDayOptions.OtherTime
                  "
                  class="text-xs font-medium pr-1"
                >
                  {{ textDateChooseActive }}
                </span>
                <SynIcon
                  name="dots-vertical"
                  class="rotate-90"
                  custom-class="w-4 h-4"
                />
              </div>
            </template>
            <template #dropdown-menu>
              <div
                class="flex flex-col p-3 space-y-2 rounded"
                style="min-width: 40rem; min-height: 20rem"
              >
                <ScheduleDatePicker
                  v-if="isOpenDropdownOtherTime"
                  :start-time="startTime"
                  :schedule-time="scheduleTime"
                  @on-change-schedule-time="onChangeRangeTime"
                  @close="onCloseDatePicker"
                >
                  <template #otherTime>
                    <div class="flex flex-wrap">
                      <template
                        v-for="schedule in taskDataConfigOtherTimeOption"
                        :key="schedule.key"
                      >
                        <SynRadioTag
                          v-model="otherTimeKey"
                          :value="schedule.key"
                          :label="schedule.text"
                          name="task-create-form_schedule-othertime"
                          radio-name="task-create-form_priority"
                          class="m-0.5"
                          @change="onChangeOtherTime"
                        >
                          <SynTag size="circle-small" custom-class="py-1 px-2">
                            <span>{{ schedule.text }}</span>
                          </SynTag>
                        </SynRadioTag>
                      </template>
                    </div>
                  </template>
                </ScheduleDatePicker>
                <!-- <template v-if="isOpenDropdownOtherTime">
                  <div class="flex justify-between space-x-1 items-center">
                    <span class="font-medium text-sm pb-3">{{
                      $t('TASK_SELECT_SPECIFIC_DATE_LABEL')
                    }}</span>
                  </div>

                  <div class="flex flex-col">
                    <div class="flex items-center space-x-1 w-full">
                      <AtomDatePicker
                        v-model="scheduleTimeInput"
                        class="datepicker_not_popover w-full"
                        :config="{ type: 'string', mask: 'YYYY/MM/DD' }"
                        @enter="onChooseDateSpecific(scheduleTimeInput)"
                      />

                      <SynButton
                        type-fill
                        color="current"
                        :label="$t('COMMON_LABEL_SAVE')"
                        :disabled="
                          scheduleTimeInput === '' ||
                          scheduleTimeInput === 'Invalid Date'
                        "
                        custom-class="rounded px-2 py-1 text-sm"
                        @click="onChooseDateSpecific(scheduleTimeInput)"
                      />
                    </div>
                  </div>

                  <div class="flex flex-wrap">
                    <template
                      v-for="schedule in taskDataConfigOtherTimeOption"
                      :key="schedule.key"
                    >
                      <SynRadioTag
                        v-model="otherTimeKey"
                        :value="schedule.key"
                        :label="schedule.text"
                        name="task-create-form_schedule-othertime"
                        radio-name="task-create-form_priority"
                        class="m-0.5"
                        @change="onChangeOtherTime"
                      >
                        <SynTag size="circle-small" custom-class="py-1 px-2">
                          <span>{{ schedule.text }}</span>
                        </SynTag>
                      </SynRadioTag>
                    </template>
                  </div>

                  <div class="w-full">
                    <v-date-picker
                      ref="dateSpecificPickerRef"
                      style="font-size: 13px !important"
                      class="
                        datepicker_view
                        border border-gray-100
                        text-sm
                        w-full
                        vig
                      "
                      :model-value="scheduleTime"
                      :timezone="'UTC'"
                      :columns="columns"
                      :config="{ type: 'string', mask: 'YYYY/MM/DD' }"
                      :popover="{ visibility: 'hidden' }"
                      color="teal"
                      :first-day-of-week="2"
                      :attributes="[
                        {
                          key: 'today',
                          dot: {
                            color: isCurrentDayOffSelect ? 'yellow' : 'teal',
                            class: isCurrentDayOffSelect ? 'mb-1 z-70' : '',
                          },
                          dates: new Date(),
                        },
                      ]"
                      @update:model-value="
                        (value) => onChooseDateSpecific(value)
                      "
                    />
                  </div>
                </template> -->
              </div>
            </template>
          </VigDropdown>
        </div>
      </div>
    </div>
    <div v-else>
      <AtomDatePicker
        :model-value="scheduleTime"
        :config="{ type: 'string', mask: 'YYYY/MM/DD' }"
        :autofocus="isAutoFocus"
        @update:model-value="onChooseDateSpecific"
      />
    </div>
    <slot name="scheduleTime"></slot>
  </div>
</template>

<style lang="scss">
.datepicker_not_popover {
  .vc-popover-content-wrapper.is-interactive {
    visibility: hidden !important;
    display: none !important;
  }
}

.datepicker_view {
  .vc-title {
    font-size: 14px !important;
  }

  .vc-weeks .vc-weekday {
    font-size: 12px !important;
  }

  .vc-day {
    font-size: 12px !important;
  }
}
</style>
