<script setup lang="ts">
import FilterAndSelectUser from '@/ui/components/search/FilterAndSelectUser.vue';
import UserById from '@/ui/components/user/UserById.vue';
import { computed, ref } from 'vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import createFormStore from '@/store/task/create-form';

const props = defineProps<{
  selectedUsers: any[];
  isIgnoreMySelf?: boolean;
  isCacheDefault?: boolean;
  mustHaveUsers?: any[];
}>();
const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'save', data: any): void;
  (e: 'removeMember', data: any): void;
}>();

const defaultData = computed(() => {
  console.log('🚀 Tictop ~ props.selectedUsers:', props.selectedUsers);
  if (props.selectedUsers && props.selectedUsers?.length > 0)
    return cloneDeep(props.selectedUsers);

  console.log(
    '🚀 Tictop ~ createFormStore().cacheMultiAssignees:',
    createFormStore().cacheMultiAssignees
  );
  if (
    createFormStore().cacheMultiAssignees &&
    createFormStore().cacheMultiAssignees?.length > 0
  )
    return cloneDeep(createFormStore().cacheMultiAssignees);

  return [];
});

const selectedContacts = ref<any[]>(cloneDeep(defaultData.value) || []);

const onChangeSelectAll = (isSelected, _selectedUsers) => {
  const selectedUsers =
    props.mustHaveUsers && props.mustHaveUsers?.length > 0
      ? _selectedUsers?.filter(
          (u) => !props.mustHaveUsers?.some((user) => user?.id == u?.id)
        )
      : _selectedUsers;

  if (isSelected) {
    selectedUsers.forEach((element) => {
      if (!selectedContacts.value?.some((u) => u?.id == element?.id))
        selectedContacts.value.push(element);
    });
  } else {
    selectedContacts.value = selectedContacts.value.filter(
      (u) => !selectedUsers.some((o) => o?.id == u?.id)
    );
  }
};

const onSelectUser = (user) => {
  const userId = user?.userId;
  const isExisted = selectedContacts.value?.some((u) => u?.id == userId);
  if (isExisted) {
    selectedContacts.value = selectedContacts.value?.filter(
      (u) => u?.id !== userId
    );
  } else {
    selectedContacts.value.push({
      contactId: `U-${user?.userId}`,
      id: userId,
      name: user.name,
      avatar: user.avatar,
      isAdd: false,
    });
  }
};

const onSave = () => {
  selectedContacts.value.forEach((el: any) => (el.isAdd = true));
  emit('save', selectedContacts.value);
};

const onRemoveMember = (id) => {
  selectedContacts.value = selectedContacts.value.filter((u) => u?.id !== id);
};
</script>

<template>
  <SynModal
    z-index="z-60"
    container-class="w-2/5"
    container-style="width: 650px; max-width: 1200px; height: 80%;"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    @cancel="$emit('cancel')"
  >
    <template #header>
      <slot name="header">
        <div>
          {{
            $t('CALENDAR_TITLE_ADD_PEOPLE_INVOLVED') || 'Add involved people'
          }}
        </div>
      </slot>
    </template>

    <template #body>
      <div class="flex overflow-hidden">
        <div class="w-1/2 px-3 flex-1 overflow-auto small-scrollbar">
          <FilterAndSelectUser
            is-hidden-action-item
            is-select-multiple
            is-filter-when-selected
            :selected-ids="selectedContacts?.map((u) => u?.id)"
            @on-choose="onSelectUser"
            @on-choose-all="onChangeSelectAll"
          />
        </div>
        <div
          class="
            w-1/2
            flex flex-col
            gap-1
            px-3
            flex-1
            overflow-auto
            small-scrollbar
          "
        >
          <template v-if="selectedContacts?.length > 0">
            <div class="flex gap-2 px-2 py-2">
              <span>
                {{
                  $t('GED_NUM_SELECTED', {
                    number: selectedContacts?.length || 0,
                  })
                }}
              </span>
            </div>
            <div
              v-for="user in selectedContacts"
              :key="user"
              class="
                p-2
                flex
                items-center
                space-x-2
                bg-current-50
                hover:bg-gray-100
                rounded
                text-sm
                hover-to-show__parent
                cursor-pointer
              "
              :class="
                mustHaveUsers?.some((u) => u?.id == user?.id)
                  ? 'bg-gray-50'
                  : ''
              "
            >
              <UserById
                :user-id="user.id"
                is-show-department
                class="flex-1"
                avatar-class="w-8 h-8"
              />
              <button
                v-if="
                  !mustHaveUsers ||
                  !mustHaveUsers?.some((u) => u?.id == user?.id)
                "
                class="p-1 rounded-full hover:bg-current-100 flex-center"
                @click="onRemoveMember(user?.id)"
              >
                <SynIcon name="Close" custom-class="w-4 h-4 fill-gray-500" />
              </button>
            </div>
          </template>
          <template v-else>
            <div class="pt-16">
              <syn-animation
                name="emptyBox"
                stype="width: 150px; height: 150px"
              />
            </div>
            <div class="bg-gray-50 py-4 rounded-lg m-2">
              <div class="text-center w-full text-sm px-1 text-gray-500">
                {{ $t('COMMON_LABEL_INVITE_YOUR_TEAM') }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex items-center px-3 py-2">
        <div
          class="
            flex-1
            max-h-32
            overflow-auto
            small-scrollbar
            flex flex-wrap
            space-y-1
          "
        ></div>
        <div class="flex-center space-x-2">
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
            color="gray"
            type-text
            :label="$t('COMMON_LABEL_CANCEL')"
            @click="$emit('cancel')"
          />
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_CONFIRM')"
            :label="$t('COMMON_LABEL_CONFIRM')"
            :disabled="
              !selectedContacts?.length || selectedContacts?.length == 0
            "
            @click="onSave()"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
