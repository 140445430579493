<script setup lang="ts">
import { ref, watch } from 'vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import EditButton from '@/ui/components/general/EditButton.vue';
import userStore from '@/store/user';

const props = withDefaults(
  defineProps<{
    userId: number | string;
    domains: any[];
  }>(),
  {}
);
const _userStore = userStore();

const allDomain = ref<any>({});

const initData = () => {
  allDomain.value = cloneDeep(props.domains);
};
initData();

watch(
  () => props.domains,
  () => {
    initData();
  }
);

const isEditting = ref(false);
const isSaving = ref(false);

const onCancel = () => {
  initData();
};

const onSave = async () => {
  isSaving.value = true;
  const domain = [] as any;
  const projectid = [] as any;

  allDomain.value.forEach((el) => {
    if (el.checked && el.anyProjects) {
      domain.push({
        domainId: el.domainId,
        anyProjects: true,
      });
    }
    if (el.checked && !el.anyProjects) {
      domain.push({
        domainId: el.domainId,
        anyProjects: false,
      });
    }

    el.projectScopeList.forEach((elchild) => {
      if (elchild.checked) {
        projectid.push(elchild.projectId);
      }
      elchild.subProject.forEach((elsub) => {
        if (elsub.checked) {
          projectid.push(elsub.projectId);
        }
      });
    });
  });
  const dataScope = {
    domains: domain,
    projectIds: projectid,
    userId: props.userId,
  };
  await _userStore.saveUserScopesDto(dataScope);
  _userStore.getDomainListById(props.userId);
  isSaving.value = false;

  isEditting.value = false;
};
</script>

<template>
  <div class="p-2 flex-1 min-h-0 h-full flex flex-col overflow-hidden">
    <div class="flex justify-end">
      <div class="flex items-center mx-2">
        <EditButton
          v-model="isEditting"
          :is-submitting="isSaving"
          @cancel="onCancel"
          @save="onSave"
        />
      </div>
    </div>
    <div class="flex-1 min-h-0 overflow-y-auto small-scrollbar">
      <!-- Edit -->
      <template v-if="isEditting">
        <template v-for="domain in allDomain" :key="domain">
          <!-- domain -->
          <AtomCheckbox
            v-model="domain.checked"
            class="py-1"
            :label="domain.domainName"
            icon-class="fill-current"
          />

          <!-- child domain -->
          <template v-if="domain.checked">
            <div class="flex">
              <span class="h-8 w-8"> </span>

              <div class="flex flex-col">
                <AtomCheckbox
                  v-if="domain.system"
                  class="py-1"
                  :model-value="true"
                  :label="$t('USERMANAGEMENT_LABEL_SELECT_ALL_PROJECT')"
                />
                <AtomCheckbox
                  v-else
                  v-model="domain.anyProjects"
                  class="py-1"
                  :label="$t('USERMANAGEMENT_LABEL_SELECT_ALL_PROJECT')"
                />

                <template v-if="!domain.anyProjects">
                  <template
                    v-for="project in domain.projectScopeList"
                    :key="project.key"
                  >
                    <AtomCheckbox
                      v-model="project.checked"
                      class="py-1"
                      :label="project.projectName"
                    />
                    <!-- sub project -->
                    <template
                      v-if="project.checked && project.subProject?.length > 0"
                    >
                      <div class="flex">
                        <span class="h-8 w-8"> </span>

                        <div class="flex flex-col">
                          <template
                            v-for="subproject in project.subProject"
                            :key="subproject?.key"
                          >
                            <AtomCheckbox
                              v-model="subproject.checked"
                              class="py-1"
                              :label="subproject.projectName"
                              icon-class="fill-yellow-500"
                            />
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </template>
      </template>
      <!-- Detail  -->
      <template v-else>
        <div v-for="domain in allDomain" :key="domain">
          <!-- domain -->
          <AtomCheckbox
            v-if="domain.checked"
            class="py-1 cursor-not-allowed"
            :model-value="domain.checked"
            :label="domain.domainName"
            icon-class="fill-current"
          />

          <!-- child domain -->
          <template
            v-if="
              domain.checked &&
              (domain.anyProjects ||
                domain?.projectScopeList?.filter((p) => p.checked).length > 0)
            "
          >
            <div class="flex">
              <span class="h-8 w-8"> </span>
              <div class="flex flex-col">
                <AtomCheckbox
                  v-if="domain.system || domain.anyProjects"
                  class="py-1 cursor-not-allowed"
                  :model-value="true"
                  :label="$t('USERMANAGEMENT_LABEL_SELECT_ALL_PROJECT')"
                />

                <template v-if="!domain.anyProjects">
                  <template
                    v-for="project in domain.projectScopeList"
                    :key="project.key"
                  >
                    <template v-if="project.checked">
                      <AtomCheckbox
                        class="py-1 cursor-not-allowed"
                        :model-value="project.checked"
                        :label="project.projectName"
                      />

                      <!-- sub project -->
                      <div v-if="project.subProject?.length > 0" class="flex">
                        <span class="h-8 w-8"> </span>
                        <div class="flex flex-col">
                          <template
                            v-for="subproject in project.subProject"
                            :key="subproject?.key"
                          >
                            <AtomCheckbox
                              v-if="subproject.checked"
                              class="py-1 cursor-not-allowed"
                              :model-value="subproject.checked"
                              :label="subproject.projectName"
                              icon-class="fill-yellow-500"
                            />
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
