<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 381 275"
  >
    <path
      d="M466.43,279H280.56C256.05,279,236,302,236,330.2V502.8C236,531,256.05,554,280.56,554H466.44C491,554,511,531,511,502.8V330.21C511,301.53,490.94,279,466.43,279Zm-15.74,71.27L332.44,477.33l69.56-.11c5.08,0,10.33,4,11.34,9.07a10,10,0,0,1-2.37,8.84,9.78,9.78,0,0,1-7.22,3.08l-95.24.15a11.43,11.43,0,0,1-11.26-10.48l-6.66-94.7a9.85,9.85,0,0,1,8.05-10.67,11.39,11.39,0,0,1,13,10.34l4.9,69.66L434.57,335.66a10.55,10.55,0,0,1,13.67-1.35C453.91,338.2,454.88,345.77,450.69,350.27Z"
      transform="translate(-236 -279)"
    />
    <path
      d="M589.74,307.89a16.39,16.39,0,0,0-6.27,3.58L526,357.38V475.72l57.84,45.92c10.69,8.67,23.95,3.57,30.21-11.23a40.8,40.8,0,0,0,3-15.3V337.48C617,318.61,604.11,303.3,589.74,307.89Z"
      transform="translate(-236 -279)"
    />
  </svg>
</template>
