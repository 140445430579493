<script setup lang="ts">
// *** IMPORTS ***
import { computed, onBeforeMount, ref } from 'vue';
import UsersSelect from '@/ui/modules/messaging/modal-new-group-chat/UsersSelect/UsersSelect.vue';
import {
  GroupChatRestriction,
  GroupMemberRole,
} from '@/ui/common/constants/constant';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { ignoreUnicode } from '@/ui/plugins/utils';
import GroupRepository from '@/application/repositories/GroupRepository';

// *** PROPS, EMITS ***
const props = defineProps<{
  group: any;
}>();

const emit = defineEmits(['onClose', 'onSaved']);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const selectedMembers = ref<any[]>();
const isSaving = ref<boolean>();

// *** COMPUTED ***
const groupMembers = computed(() =>
  arrayOrderBy(
    props.group?.groupUsers,
    ['role', (u) => ignoreUnicode(u?.fullName)],
    ['asc']
  )
);
const adminMembers = computed(() =>
  groupMembers.value?.filter((u: any) => u?.role == GroupMemberRole.Admin)
);

// *** WATCHES ***

// *** HOOKS ***
onBeforeMount(() => {
  selectedMembers.value = props.group?.groupUsers?.filter(
    (u) => u?.sendMessageAllowed || u?.role === GroupMemberRole.Admin
  );
});

// *** HANDLER FUNCTIONS ***
const onSaveClick = async () => {
  if (!props.group?.id) return;

  isSaving.value = true;

  try {
    const data = {
      groupId: props.group?.id,
      sendMessageRestriction: GroupChatRestriction.AllowSpecificMembers,
      allowedUserIds: selectedMembers.value?.map((m) => m?.userId),
    };

    const res =
      await GroupRepository.getInstance().updateGroupSendMessageRestriction(
        data
      );

    emit('onSaved', res?.result);
  } catch (e) {
    console.log(e);
  }

  isSaving.value = false;
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <SynModal
    container-class="w-11/12 md:w-[45rem] h-[80vh]"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>
        {{ $t('GROUP_SETTING_CHAT_RESTRICTION_SPECIFIC_MEMBERS_CHOOSE') }}
        <span class="text-current-500">{{ group?.name }}</span>
      </div>
    </template>

    <template #body>
      <UsersSelect
        v-model="selectedMembers"
        :users="groupMembers"
        :disabled-users="adminMembers"
      />
    </template>

    <template #footer>
      <div class="flex items-center justify-end px-4 py-2 gap-2">
        <VigButton light color="gray" tabindex="-1" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') }}
        </VigButton>
        <VigButton :processing="isSaving" @click="onSaveClick">
          <span>{{ $t('COMMON_LABEL_SAVE') }}</span>
        </VigButton>
      </div>
    </template>
  </SynModal>
</template>

<style scoped></style>
