<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import { chain, groupBy, sortBy } from 'lodash';
import TaskRepository from '@/application/repositories/TaskRepository';
import { useRoute } from 'vue-router';
import organizationStore from '@/store/organization';
import WorkflowDynamicInfoRepository from '@/application/repositories/workflow-dynamic-info/WorkflowDynamicInfoRepository';
import WorkflowTasksViewBoard from '@/ui/modules/workflow-task/workflow-tasks/WorkflowTasksViewBoard.vue';
import WorkflowTasksViewList from '@/ui/modules/workflow-task/workflow-tasks/WorkflowTasksViewList.vue';
import useWorkflowTasksFilter from '@/ui/modules/workflow-task/_composables/workflow-tasks-filter.composable';
import dayjs from 'dayjs';
import WorkflowTasksFilter from '@/ui/modules/workflow-task/workflow-tasks/WorkflowTasksFilter.vue';
import ApplyWorkFlowModal from '@/ui/modules/task/workflow/ApplyWorkFlowModal.vue';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
} from '@/ui/modules/task/task-global-state';
const props = defineProps<{
  workflowId: string;
}>();

const route = useRoute();
const {
  filterByKey,
  sortByKey,
  initFilterData,
  updateFilterColumn,
  resetFilterColumn,
  updateSortColumn,
} = useWorkflowTasksFilter();

const workflow = ref<any>();
const workflowSteps = ref<any[]>([]);
const tasksByStep = ref<{ [stepId: string]: any[] }>({});
const searchText = ref<string>('');
const viewMode = ref<'BOARD' | 'LIST'>('BOARD');
const viewListRef = ref();
const filterGlobal = ref();
const isLoadingWorkflow = ref<boolean>();
const isLoadingTasks = ref<boolean>(false);
const isRefreshingTasks = ref<boolean>();

let previousSearchText: string;
let previousTaskActionTime: any;
let unsubLatestTask: any;
let subLatestTaskTimer: any;

watch(
  () => props.workflowId,
  (workflowId) => {
    _getWorkflow(workflowId);
    _getWorkflowTasks(workflowId, true);
  }
);

onBeforeMount(() => {
  _getWorkflow(props.workflowId);
  _getWorkflowTasks(props.workflowId, true);
  _subscribeRealtimeTasks(props.workflowId);
});

onBeforeUnmount(() => {
  if (unsubLatestTask) unsubLatestTask();
});

const onSearch = () => {
  if (searchText.value === previousSearchText) return;

  _getWorkflowTasks(props.workflowId, false, false);

  previousSearchText = searchText.value;
};

const onViewModeToggle = async () => {
  viewMode.value = viewMode.value === 'BOARD' ? 'LIST' : 'BOARD';
  filterByKey.value = {};
  sortByKey.value = {};

  await _getWorkflowTasks(props.workflowId, true);

  setTimeout(() => viewListRef.value?.initPage());
};

const onRefreshClick = async () => {
  isRefreshingTasks.value = true;

  try {
    await _getWorkflowTasks(props.workflowId);
  } catch (e) {
    console.log(e);
  }

  isRefreshingTasks.value = false;
};

const onFilterGlobal = (data) => {
  filterGlobal.value = data;
  filterByKey.value = {};
  sortByKey.value = {};

  _getWorkflowTasks(props.workflowId, true);
};

const onFilterGlobalReset = () => {
  filterGlobal.value = null;
  filterByKey.value = {};
  sortByKey.value = {};

  _getWorkflowTasks(props.workflowId, true);
};

const onFilterColumn = (column, data) => {
  updateFilterColumn(column, data);

  _getWorkflowTasks(props.workflowId);
};

const onFilterColumnReset = (column) => {
  resetFilterColumn(column);

  _getWorkflowTasks(props.workflowId);
};

const onSortColumn = (column) => {
  updateSortColumn(column);

  _getWorkflowTasks(props.workflowId);
};

const _getWorkflow = async (workflowId) => {
  isLoadingWorkflow.value = true;

  try {
    const res = await WorkflowRepository.getInstance().getWorkflowById(
      workflowId
    );
    workflow.value = res?.result;
    workflowSteps.value = sortBy(workflow.value?.steps, 'stepOrder');
  } catch (e) {
    console.log(e);
  }

  isLoadingWorkflow.value = false;
};

const _getWorkflowTasks = async (
  workflowId,
  isInit = false,
  isReload = true,
  showLoading = true
) => {
  if (showLoading) {
    tasksByStep.value = {};
    isLoadingTasks.value = true;
  }

  try {
    const res = await TaskRepository.getInstance().getWorkflowTasksWithFilters(
      workflowId,
      {
        ..._prepareFilters(),
        ..._prepareSort(),
      }
    );

    const workflowTasks = res?.result;

    tasksByStep.value = groupBy(workflowTasks, 'workflowStepId');

    if (isInit) initFilterData(workflowTasks);

    if (isReload) setTimeout(() => viewListRef.value?.initPage());
  } catch (e) {
    console.log(e);
  }

  isLoadingTasks.value = false;
};

const _prepareFilters = () => {
  let assigneeIds = filterByKey.value?.assigneeId
    ?.filter((i) => i?.selected)
    .map((i) => i.id);
  if (!assigneeIds?.length)
    assigneeIds = filterGlobal.value?.assigneeIds
      ?.split(',')
      .filter((id) => id);

  let groupIds = filterByKey.value?.groupId
    ?.filter((i) => i?.selected)
    .map((i) => i.id);
  if (!groupIds?.length)
    groupIds = filterGlobal.value?.groupIds?.split(',').filter((id) => id);

  let domainIds = filterByKey.value?.domainId
    ?.filter((i) => i?.selected)
    .map((i) => i.id);
  if (!domainIds?.length)
    domainIds = filterGlobal.value?.domainIds?.split(',').filter((id) => id);

  let urgencies = filterByKey.value?.urgency
    ?.filter((i) => i?.selected)
    .map((i) => i.id);
  if (!urgencies?.length)
    urgencies = filterGlobal.value?.urgency?.split(',').filter((i) => i);

  let taskLifes = filterByKey.value?.taskLife
    ?.filter((i) => i?.selected)
    .map((i) => i.id);
  if (!taskLifes?.length)
    taskLifes = filterGlobal.value?.status?.split(',').filter((i) => i);

  const taskPlans = filterByKey.value?.taskPlan
    ?.filter((i) => i?.selected)
    .map((i) => i.id);

  let scheduleTimeFrom, scheduleTimeTo;
  if (filterByKey.value?.deadline?.filterType === 'TODAY') {
    scheduleTimeFrom = dayjs().startOf('day').toDate();
    scheduleTimeTo = dayjs().endOf('day').toDate();
  } else if (filterByKey.value?.deadline?.filterType === 'OTHER') {
    scheduleTimeFrom = filterByKey.value?.deadline?.start
      ? dayjs(filterByKey.value?.deadline?.start).startOf('day').toDate()
      : null;
    scheduleTimeTo = filterByKey.value?.deadline?.end
      ? dayjs(filterByKey.value?.deadline?.end).endOf('day').toDate()
      : null;
  }
  if (!scheduleTimeFrom)
    scheduleTimeFrom = filterGlobal.value?.deadlineFromDate;
  if (!scheduleTimeTo) scheduleTimeTo = filterGlobal.value?.deadlineToDate;

  let creationTimeFrom, creationTimeTo;
  if (filterByKey.value?.creationTime?.filterType === 'TODAY') {
    creationTimeFrom = dayjs().startOf('day').toDate();
    creationTimeTo = dayjs().endOf('day').toDate();
  } else if (filterByKey.value?.creationTime?.filterType === 'OTHER') {
    creationTimeFrom = filterByKey.value?.creationTime?.start
      ? dayjs(filterByKey.value?.creationTime?.start).startOf('day').toDate()
      : null;
    creationTimeTo = filterByKey.value?.creationTime?.end
      ? dayjs(filterByKey.value?.creationTime?.end).endOf('day').toDate()
      : null;
  }
  if (!creationTimeFrom) creationTimeFrom = filterGlobal.value?.createdFromDate;
  if (!creationTimeTo) creationTimeTo = filterGlobal.value?.createdToDate;

  return {
    assigneeIds,
    groupIds,
    domainIds,
    urgencies,
    taskLifes,
    taskPlans,
    scheduleTimeNone:
      filterByKey.value?.deadline?.filterType === 'NOTHING' ||
      filterGlobal.value?.isNoSchedule,
    scheduleTimeFrom,
    scheduleTimeTo,
    creationTimeFrom,
    creationTimeTo,
    modificationTimeFrom: filterGlobal.value?.updatedFromDate,
    modificationTimeTo: filterGlobal.value?.updatedToDate,
    isRepeat: filterGlobal.value?.isRepeat,
    isInWorkflow: filterGlobal.value?.isInWorkflow,
    isApproval: filterGlobal.value?.isApproval,
    isSubTask: filterGlobal.value?.isSubTask,
    keyword: searchText.value,
    isDefault:
      filterGlobal.value?.isDefault == undefined
        ? true
        : filterGlobal.value?.isDefault,
  };
};

const _prepareSort = () => {
  return (
    chain(Object.entries(sortByKey.value || {}))
      .filter(([key, val]) => key && val?.orderBy)
      .map(([key, val]) => ({
        sortBy: key,
        sortAsc: val.orderBy === 'asc',
      }))
      .head()
      .value() || {}
  );
};

const _subscribeRealtimeTasks = (workflowId) => {
  const myOrgId = organizationStore().organizationInfo?.id;
  if (!myOrgId || !workflowId) return;

  unsubLatestTask = WorkflowDynamicInfoRepository.subscribeWorkflowLatestTask(
    myOrgId,
    workflowId,
    (latestTask) => {
      if (
        previousTaskActionTime &&
        latestTask?.taskActionTime != previousTaskActionTime
      ) {
        // Refresh tasks after 300ms
        clearTimeout(subLatestTaskTimer);
        subLatestTaskTimer = setTimeout(() => {
          _getWorkflowTasks(props.workflowId, false, false, false);
        }, 300);
      }

      previousTaskActionTime =
        latestTask?.taskActionTime || new Date().getTime();
    }
  );
};

const isOpenWorkFlow = ref<boolean>(false);
const onAttach = (data) => {
  newTaskDefaultPayload.value = {
    taskWorkflow: data,
  };
  isOpenCreateTaskDrawer.value = true;
  isOpenWorkFlow.value = false;
};

const onOpenCreateNewTaskForm = () => {
  // isOpenCreateTaskDrawer.value = true;
  isOpenWorkFlow.value = true;
};
</script>

<template>
  <div class="h-full flex flex-col">
    <!--HEADER-->
    <div class="flex items-center justify-between px-3 py-2 gap-2 bg-white">
      <div class="px-1 flex-center gap-2">
        <router-link
          :to="{ path: route.path }"
          class="text-current-500 hover:text-current-600"
        >
          {{ $t('OVERVIEW_WORKFLOWS') }}
        </router-link>
        <SynIcon name="ChevronRight" custom-class="w-3 h-3 fill-gray-500" />
        <span class="font-semibold">{{ workflow?.name }}</span>
      </div>
      <div class="flex items-center gap-1">
        <AtomButton
          :label="$t('COMMON_LABEL_CREATE_TASK')"
          size="sm"
          @click="onOpenCreateNewTaskForm"
        />
        <SynSearch
          v-model="searchText"
          icon-prefix="search"
          autofocus
          is-show-reset
          :placeholder="$t('COMMON_LABEL_SEARCH_PLACEHOLDER_TASK')"
          @enter="onSearch"
          @blur="onSearch"
          @on-reset="onSearch"
        />
        <WorkflowTasksFilter
          @on-filter="onFilterGlobal"
          @on-reset="onFilterGlobalReset"
        />
        <VigButton
          v-vig-tooltip="
            $t(
              viewMode === 'BOARD'
                ? 'TASK_LIST_VIEW_LIST_LABEL'
                : 'TASK_LIST_VIEW_BOARD_LABEL'
            )
          "
          ghost
          rounded="rounded-full"
          color="gray"
          :icon="viewMode === 'BOARD' ? 'List' : 'ViewBoard'"
          @click="onViewModeToggle"
        />
        <VigButton
          ghost
          rounded="rounded-full"
          color="gray"
          icon="Reload"
          :processing="!!isRefreshingTasks"
          @click="onRefreshClick"
        />
      </div>
    </div>

    <!--BODY-->
    <div class="flex-1 overflow-hidden">
      <WorkflowTasksViewBoard
        v-if="viewMode === 'BOARD'"
        :workflow-steps="workflowSteps"
        :tasks-by-step="tasksByStep"
        :is-loading="isLoadingTasks"
      />

      <WorkflowTasksViewList
        v-else-if="viewMode === 'LIST'"
        ref="viewListRef"
        :workflow-steps="workflowSteps"
        :tasks-by-step="tasksByStep"
        :filter-by-key="filterByKey"
        :sort-by-key="sortByKey"
        :is-loading="isLoadingTasks"
        @on-filter="onFilterColumn"
        @on-filter-reset="onFilterColumnReset"
        @on-sort="onSortColumn"
      />
    </div>
  </div>

  <ApplyWorkFlowModal
    v-if="isOpenWorkFlow"
    :task-workflow="workflow"
    @cancel="isOpenWorkFlow = false"
    @on-attach="onAttach"
    @on-save="onAttach"
  />
</template>

<style scoped></style>
