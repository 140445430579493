<script setup lang="ts">
import { computed, ref } from 'vue';
import FileIcon from '@/ui/modules/ged/_commons/file-icon/FileIcon.vue';
import { arrayOrderBy, truncateLinkFile } from '@/ui/hooks/commonFunction';
import FileOnlyViewer from '@/ui/modules/task/components/activity-tasklog/FileOnlyViewer.vue';
import ModalMultipleFileViewerInfo from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewerInfo.vue';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});

const fileShowViewer = ref();

const onClickFile = (file) => {
  fileShowViewer.value = file;
};

const afterActivity = computed(() => props.afterUpdate);
const allAttachment = computed(() => {
  return arrayOrderBy(
    [
      ...(afterActivity.value?.AddedNotes?.map((el) => {
        return { ...el, isNote: true };
      }) || []),
      ...(afterActivity.value?.EditedNotes?.map((el) => {
        return { ...el, isNote: true };
      }) || []),
      ...(afterActivity.value?.AddedFiles || []),
    ],
    ['creationTime'],
    ['asc']
  );
});
</script>
<template>
  <div v-if="allAttachment?.length > 0" class="flex items-center w-full mb-1">
    <div class="flex justify-start mr-1 w-full">
      <div
        v-if="allAttachment?.length > 0"
        class="flex items-start text-xs w-full flex-wrap"
      >
        <div
          v-for="(file, index) in allAttachment"
          :key="index"
          class="relative w-max h-12 hover-to-hidden__parent mr-1 mt-1"
        >
          <FileOnlyViewer
            :content-type="file?.ContentType"
            :path="file?.Path"
            :title="file?.Name"
            :is-note="file?.isNote"
            @on-select="onClickFile(file)"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="afterActivity?.DeletedFiles?.length > 0"
    class="flex items-center justify-between w-full"
  >
    <div class="flex justify-start mr-1 w-1/2">
      <div
        v-if="afterActivity?.DeletedFiles?.length > 0"
        class="flex flex-col items-start text-xs space-y-1"
      >
        <template v-for="link in afterActivity?.DeletedFiles" :key="link">
          <div class="flex space-x-1 bg-white rounded">
            <div class="px-1 py-1">
              <file-icon
                :file-type="link?.ContentType"
                class-name="h-4 w-4"
              ></file-icon>
            </div>
            <span v-vig-tooltip="link?.Name" class="text-gray-600 px-2 py-1">{{
              truncateLinkFile(link?.Name, 25)
            }}</span>
          </div>
        </template>
      </div>
    </div>
    <span v-if="afterActivity?.DeletedFiles?.length > 0" class="px-2">→</span>

    <div
      v-if="afterActivity?.DeletedFiles?.length > 0"
      class="flex w-1/2 justify-start pl-4 text-xs text-gray-500"
    >
      <span>None</span>
    </div>
  </div>

  <ModalMultipleFileViewerInfo
    v-if="fileShowViewer"
    is-file-in-task
    :files="allAttachment"
    :editable="false"
    :content-type="fileShowViewer?.contentType"
    @on-close="fileShowViewer = null"
  >
  </ModalMultipleFileViewerInfo>
</template>
