<template>
  <div v-if="isLoading">
    <div
      class="
        animate-pulse
        my-2
        transform
        bg-white
        hover:scale-95
        transition
        duration-300
        cursor-pointer
        relative
        flex flex-col
        h-20
        max-w-sm
        overflow-hidden
        rounded-xl
        shadow-lg
      "
      :class="size == 'lg' ? 'lg:w-44 lg:h-52' : 'lg:w-40 lg:h-44'"
    >
      <div class="p-2">
        <div class="h-8 w-8 bg-gray-400 rounded"></div>
      </div>
      <div class="w-full flex flex-col items-center justify-center">
        <div class="h-4 w-2/3 my-2 bg-gray-400 rounded"></div>
        <div class="h-4 w-2/3 my-2 bg-gray-400 rounded"></div>
        <div class="h-4 w-2/3 my-2 bg-gray-400 rounded"></div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="
      my-2
      transform
      bg-white
      hover:scale-95
      transition
      duration-300
      cursor-pointer
      relative
      flex flex-col
      h-20
      max-w-sm
      overflow-hidden
      rounded-xl
      shadow-lg
    "
    :class="size == 'lg' ? 'lg:w-44 lg:h-52' : 'lg:w-40 lg:h-44'"
  >
    <div class="flex justify-between items-center p-2">
      <slot name="icon"></slot>
      <slot name="tag"></slot>
    </div>
    <div class="flex-1 text-gray-500 bg-white px-2 text-center inset-0">
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    subName: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
