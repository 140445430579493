<script setup lang="ts">
import { computed, onMounted, ref, watch, toRaw } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { ITaskTodoItemStatus } from '@/application/types/task/task.types';
import AtomChecklistItem from '@/ui/common/atoms/checklist/AtomChecklistItem.vue';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
// import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { TaskLife } from '@/domain/enums/taskEnum';

const props = defineProps<{
  modelValue: any[];
  readonly?: boolean;
  maxTitleLength?: number;
  needToUpdateItemId?: number;
  needToCheckViewable?: boolean;
  isSelectMode?: boolean;
  showMaxItem?: number;
  isHiddenAddNew?: number;
  isDisabledEditTitle?: boolean;
  isHiddenFinishedDefault?: boolean;
  customBodyStyle?: any;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: any[]): void;
  (e: 'remove-item', removedItem: any, isAbleRollback: boolean): void;
  (e: 'add', newItem: any): void;
  (e: 'clone', newItem: any): void;
  (e: 'updateItemName', item: any): void;
  (e: 'onClickTitle', item: any): void;
  (e: 'update:isHiddenFinishedDefault', item: any): void;
}>();

const TODO_LIST_ITEM_PREFIX = 'todo-list-item_';

const isHiddenFinished = ref<boolean>(!!props.isHiddenFinishedDefault);
const isCollapse = ref<boolean>(false);
const filteredByFinishedData = computed<any[]>(() => {
  let result = toRaw(props.modelValue);

  if (isHiddenFinished.value)
    result = result?.filter((o) => o.status !== ITaskTodoItemStatus.FINISHED);

  return result;
});

const filteredData = computed<any[]>(() => {
  let result = toRaw(filteredByFinishedData.value);

  if (!isCollapse.value) {
    result = props.showMaxItem
      ? result?.slice(-props.showMaxItem)
      : result || [
          {
            id: uuidv4(),
            title: '',
            shortDescription: '',
            description: '',
            status: ITaskTodoItemStatus.PENDING,
            index: 0,
          },
        ];
  }

  return result;
});

const todoListPayload = ref<any[]>(toRaw(filteredData.value));

const hasLoadMore = computed<boolean>(() => {
  if (!props.showMaxItem) return false;

  return (
    filteredByFinishedData.value?.length > props.showMaxItem &&
    filteredByFinishedData.value?.length > todoListPayload.value?.length - 1
  );
});

const initComponent = () => {
  todoListPayload.value = toRaw(filteredData.value);
};

let lastScrollTop = 0;
const stopPropagationScroll = (event) => {
  if (event?.target?.id == 'atom-normal-checklist') {
    const currentScrollTop = event.target.scrollTop;
    const isScrollDown = currentScrollTop > lastScrollTop;
    lastScrollTop = currentScrollTop;

    if (
      currentScrollTop == 0 ||
      (isScrollDown && event.target.scrollHeight == event.target?.innerHeight)
    )
      return;

    event?.stopPropagation();
  }
};

const stopBubbleDragEvent = () => {
  const divEl = document.getElementById('atom-normal-checklist');
  if (!divEl) return;
  divEl.addEventListener('dragenter', (event) => {
    event.stopPropagation();
  });
};

const stopBubbleScrollEvent = () => {
  const divEl = document.getElementById('atom-normal-checklist');
  if (!divEl) return;

  divEl.addEventListener('scroll', (event) => {
    stopPropagationScroll(event);
  });
  divEl.addEventListener('wheel', (event) => {
    stopPropagationScroll(event);
  });
  divEl.addEventListener('mousewheel', (event) => {
    stopPropagationScroll(event);
  });
};

onMounted(() => {
  // setTimeout(() => {
  //   const firstItem =
  //     todoListPayload.value?.todoItems?.length > 0
  //       ? todoListPayload.value?.todoItems[0]
  //       : null;

  //   if (firstItem?.id)
  //     document
  //       .getElementById(`${TODO_LIST_ITEM_PREFIX}${firstItem?.id}`)
  //       ?.focus();
  // });

  stopBubbleDragEvent();
  stopBubbleScrollEvent();
});

const updateItemIndex = () => {
  todoListPayload.value.forEach((element, index) => {
    element.index = index;
  });

  updateTodoList();
};

const addNewItem = (index, value, disableAutoFocus, cloneItem?) => {
  if (index >= todoListPayload.value?.length - 1 && !value) {
    document.getElementById(`${TODO_LIST_ITEM_PREFIX}_NEW`)?.focus();

    return;
  }

  const newItemId = uuidv4();

  const newItem = {
    id: newItemId,
    title: value || '',
    shortDescription: '',
    description: '',
    status: ITaskTodoItemStatus.PENDING,
    index: todoListPayload.value?.length,
    isViewable: true,
  };

  todoListPayload.value.splice(index + 1, 0, newItem);

  setTimeout(() => {
    const input: HTMLInputElement = document.getElementById(
      `${TODO_LIST_ITEM_PREFIX}${newItemId}`
    ) as HTMLInputElement;

    if (!input || disableAutoFocus) return;

    input.setSelectionRange(0, 0);

    input.focus();
  });

  updateItemIndex();
  // updateTodoList();
  if (value) {
    if (cloneItem?.id) emit('clone', cloneItem);
    else emit('add', newItem);
  }
};
const removeItem = (itemId, index) => {
  onFocusTo(Math.max(0, index - 1));

  const removedItem = todoListPayload.value?.find((o) => o.id == itemId);

  todoListPayload.value = todoListPayload.value?.filter(
    (item) => item?.id !== itemId
  );

  updateItemIndex();

  emit('remove-item', removedItem, true);
};

const onFocusTo = (index) => {
  if (index >= todoListPayload.value?.length) {
    // addNewItem(index, '');
    document.getElementById(`${TODO_LIST_ITEM_PREFIX}_NEW`)?.focus();
    return;
  }
  const focusToItem = todoListPayload.value[index];
  document
    .getElementById(`${TODO_LIST_ITEM_PREFIX}${focusToItem?.id}`)
    ?.focus();
};

const onChangeStatus = (item) => {
  item.status =
    item.status === ITaskTodoItemStatus.FINISHED
      ? ITaskTodoItemStatus.PENDING
      : ITaskTodoItemStatus.FINISHED;

  updateTodoList();
};

const updateTodoList = () => {
  emit('update:modelValue', todoListPayload.value);
};

const updateTitle = () => {
  updateTodoList();
};

const onChangeDrag = (o, n) => {
  const oldIndex = o - 1;
  const newIndex = n - 1;
  if (oldIndex < 0 || oldIndex >= todoListPayload.value?.length) {
    return;
  }
  // Remove the element from the original position
  const element = todoListPayload.value.splice(oldIndex, 1)[0];

  // Insert the element at the new position
  todoListPayload.value.splice(newIndex, 0, element);

  updateItemIndex();
};

const onAddNew = (index, value, cloneItem?) => {
  if (index >= todoListPayload.value?.length - 1 && !value) {
    document.getElementById(`${TODO_LIST_ITEM_PREFIX}_NEW`)?.focus();

    return;
  }

  addNewItem(index, value, true, cloneItem);

  newItemTitle.value = '';
};

const deleteCurrentItem = (index, oldValue) => {
  if (index <= 0) return;

  const previousItem = todoListPayload.value[index - 1];
  const oldEndPosition = previousItem.title.length;

  todoListPayload.value[index - 1].title += oldValue;

  const removedItem = todoListPayload.value[index];
  emit('remove-item', removedItem, false);

  todoListPayload.value.splice(index, 1);

  setTimeout(() => {
    const input: HTMLInputElement = document.getElementById(
      `${TODO_LIST_ITEM_PREFIX}${previousItem?.id}`
    ) as HTMLInputElement;

    if (!input) return;
    input.setSelectionRange(oldEndPosition, oldEndPosition);
    input.focus();
  });

  updateItemIndex();
};
const deleteAfter = (index) => {
  if (index >= todoListPayload.value?.length) return;

  deleteCurrentItem(index + 1, todoListPayload.value[index + 1]?.title || '');
};

const onBack = (index) => {
  onFocusTo(index - 1);
};

const onNext = (index) => {
  onFocusTo(index + 1);
};

const newItemTitle = ref<string>('');

const onBlurAddNew = () => {
  if (!newItemTitle.value) return;

  addNewItem(todoListPayload.value?.length || 0, newItemTitle.value, true);

  newItemTitle.value = '';
};

const focusOnItem = (id) => {
  if (!id) return;
  setTimeout(() => {
    const itemElm = document.getElementById(`${TODO_LIST_ITEM_PREFIX}${id}`);

    if (!itemElm) return;

    itemElm.focus();
  }, 100);
};

const onCopyTitle = (title) => {
  if (!title) return;
  copyToClipBroard(title);
  openNotification({
    body: translate('CHECK_LIST_TITLE_COPIED_MSG'),
    duration: 2000,
  });
};

const onBlur = (value, isUpdated, item) => {
  if (isUpdated && value) {
    emit('updateItemName', item);
  }
};
watch(
  () => props.needToUpdateItemId,
  () => {
    initComponent();
  }
);

const onClone = (task) => {
  onAddNew(task?.index || 0, task?.name, task);
};

const onToggleExpand = () => {
  isCollapse.value = !isCollapse.value;
  todoListPayload.value = toRaw(filteredData.value);
};

const onChangeHiddenFinished = () => {
  isHiddenFinished.value = !isHiddenFinished.value;

  todoListPayload.value = toRaw(filteredData.value);

  emit('update:isHiddenFinishedDefault', isHiddenFinished.value);
};

const isOpenMoreAction = ref<boolean>(false);

initComponent();
defineExpose({
  initComponent,
  focusOnItem,
  onClone,
});
</script>
<template>
  <section class="w-full flex flex-col">
    <!-- ITEM -->
    <div class="flex items-center gap-8 relative group">
      <slot name="title"></slot>
      <div
        class="flex-1 w-full flex items-center gap-1"
        :class="hasLoadMore ? 'justify-between' : 'justify-end'"
      >
        <div
          v-if="hasLoadMore"
          class="
            flex-center
            gap-1
            cursor-pointer
            text-gray-500
            hover:text-gray-700
            pointer-events-auto
          "
          @click="onToggleExpand"
        >
          <span class="text-xs underline z-10">
            {{
              $t(
                !isCollapse
                  ? 'TASK_CREATE_FORM_SHOW_MORE'
                  : 'TASK_CREATE_FORM_SHOW_LESS'
              )
            }}
          </span>
          <SynIcon
            name="ChevronDown2"
            custom-class="h-2.5 w-2.5"
            :class="isCollapse ? 'rotate-180' : ''"
          />
        </div>
        <div class="flex-center gap-2">
          <!-- <AtomSwitch
            v-if="isHiddenFinished"
            :model-value="isHiddenFinished"
            size="small"
            :label="$t('TASK_LABEL_HIDE_FINISHED_TASKS')"
            class="text-gray-500"
            @change="onChangeHiddenFinished"
          /> -->
          <div :class="isOpenMoreAction ? '' : 'hidden group-hover:block'">
            <VigDropdown
              v-model:is-open="isOpenMoreAction"
              :arrow="false"
              hide-on-click="toggle"
              placement="bottom-end"
            >
              <template #dropdown-toggle>
                <div
                  class="
                    w-6
                    h-6
                    rounded-md
                    cursor-pointer
                    hover:bg-current-50
                    flex-center
                    border border-gray-200
                  "
                  :class="isOpenMoreAction ? 'bg-gray-100' : ''"
                >
                  <SynIcon
                    name="DotsVertical"
                    custom-class="h-4 w-4 fill-gray-500 text-gray-500"
                  />
                </div>
              </template>
              <template #dropdown-menu>
                <div
                  ref="actionOnTaskBodyRef"
                  style="max-height: min(72vh, 600px)"
                  class="overflow-auto small-scrollbar"
                >
                  <div
                    class="
                      py-1
                      z-100
                      list-ul
                      rounded
                      cursor-pointer
                      dropdown-item
                    "
                    style="min-width: 13rem"
                  >
                    <slot name="more-action"></slot>

                    <div
                      class="list-li flex justify-start items-center text-sm"
                      @click="onChangeHiddenFinished"
                    >
                      <span class="text-gray-500 pl-2">
                        {{
                          $t(
                            isHiddenFinished
                              ? 'TASK_LABEL_SHOW_FINISHED_TASKS'
                              : 'TASK_LABEL_HIDE_FINISHED_TASKS'
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </VigDropdown>
          </div>
        </div>
      </div>
    </div>
    <div
      id="atom-normal-checklist"
      :key="needToUpdateItemId"
      class="w-full flex flex-col relative small-scrollbar"
      :style="customBodyStyle"
    >
      <div
        v-if="hasLoadMore"
        class="
          flex
          justify-center
          w-full
          cursor-pointer
          text-gray-400
          hover:text-current-500
          gap-1
        "
        :class="!isCollapse ? 'absolute items-start -top-0 h-10 z-10' : 'h-0'"
        style="
          background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 0.25),
            rgba(255, 255, 255, 0.75),
            #ffffff
          );
        "
        @click="onToggleExpand"
      ></div>
      <SortAble
        :disabled="readonly || isSelectMode"
        :options="{
          onMove: true,
        }"
        @on-change-drag="onChangeDrag"
      >
        <template #list-item>
          <template v-for="(item, index) in todoListPayload" :key="item?.id">
            <AtomCustomChecklistItem
              v-if="!needToCheckViewable || item?.isViewable"
              v-model:title="item.title"
              :data-item="item"
              :textarea-id="`${TODO_LIST_ITEM_PREFIX}${item?.id}`"
              :readonly="readonly"
              :max-title-length="maxTitleLength"
              can-copy
              :is-select-mode="isSelectMode"
              edit-title-only
              class="hover-to-show__parent"
              :class="
                item?.taskLife == TaskLife.Archived ||
                item?.taskLife == TaskLife.Cancelled ||
                item?.taskLife == TaskLife.Draft ||
                item?.taskLife == TaskLife.Duplicated ||
                item?.taskLife == TaskLife.Pending
                  ? 'text-gray-400'
                  : ''
              "
              :is-disabled-edit-title="isDisabledEditTitle"
              @update:title="updateTitle"
              @on-change-status="onChangeStatus(item)"
              @add="(value) => addNewItem(index, value, false)"
              @remove="removeItem(item?.id, index)"
              @back="onBack(index)"
              @next="onNext(index)"
              @delete="(value) => deleteCurrentItem(index, value)"
              @delete-after="deleteAfter(index)"
              @copy="onCopyTitle(item.title)"
              @blur="(value, isUpdated) => onBlur(value, isUpdated, item)"
              @on-click-title="$emit('onClickTitle', item)"
            >
              <template #prefix-icon>
                <slot name="prefix-icon" :item="item"> </slot>
              </template>
              <template #action>
                <slot name="action" :item="item"> </slot>
              </template>
            </AtomCustomChecklistItem>
            <div
              v-else
              class="w-full flex-center bg-gray-50"
              style="height: 2.5rem"
            >
              Không có quyền xem
            </div>
          </template>
        </template>
      </SortAble>
    </div>

    <!-- FOOTER -->
    <!-- <SynIcon name="plus" custom-class="w-3 h-3" /> -->

    <AtomChecklistItem
      v-if="!readonly && !isSelectMode && !isHiddenAddNew"
      v-model:title="newItemTitle"
      :textarea-id="`${TODO_LIST_ITEM_PREFIX}_NEW`"
      edit-title-only
      :placeholder="$t('SUB_TASK_ADD_NEW')"
      :focusing-placeholder="$t('SUB_TASK_SET_NAME')"
      :readonly="readonly"
      @add="onAddNew(todoListPayload?.length || 0, newItemTitle)"
      @delete="
        (value) => {
          deleteCurrentItem(todoListPayload?.length, value);
          newItemTitle = '';
        }
      "
      @back="onBack(todoListPayload?.length)"
      @blur="onBlurAddNew"
    >
      <template #prefix-icon>
        <div class="h-10 w-10 flex-center group">
          <SynIcon name="plus" custom-class="w-3 h-3" />
        </div>
      </template>
    </AtomChecklistItem>
  </section>
</template>
