<script setup lang="ts">
import { computed } from 'vue';
import { DayoffStatus } from '@/domain/enums/DayoffEnums';
import { DayoffStatusUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';

const props = defineProps<{
  status: DayoffStatus;
  size?: 'small' | null;
  isActive?: boolean;
  isHiddenName?: boolean;
}>();

const classNameBySize = computed(() => {
  if (props.size == 'small') return 'h-4 pl-1 pr-2 text-2xs';
  if (props.size == 'middle') return 'h-6 pl-2 pr-2 text-xs';
  return 'px-4 py-1.5';
});
</script>

<template>
  <div
    v-if="DayoffStatusUI[status]?.textCode"
    class="flex-center space-x-1 rounded-full border"
    :title="
      $t(DayoffStatusUI[status]?.textCode) ||
      DayoffStatusUI[status]?.textDefault
    "
    :class="[classNameBySize]"
    :style="
      isActive
        ? [
            {
              backgroundColor: DayoffStatusUI[status]?.backgroundColor,
            },
            {
              background: DayoffStatusUI[status].backgroundColor,
            },
            {
              borderColor: DayoffStatusUI[status]?.color,
            },
          ]
        : {
            borderColor: DayoffStatusUI[status]?.color,
          }
    "
  >
    <span
      class="w-1.5 h-1.5 rounded-full"
      :style="{
        background: DayoffStatusUI[status]?.color,
      }"
    ></span>
    <span
      v-if="!isHiddenName"
      :style="{
        color: DayoffStatusUI[status]?.color,
      }"
      class="min-w-max flex-center"
    >
      {{
        $t(DayoffStatusUI[status]?.textCode) ||
        DayoffStatusUI[status]?.textDefault
      }}
    </span>
  </div>
</template>
