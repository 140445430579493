import { ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { GroupMemberRole, GroupType } from '@/application/enums/GroupEnums';
import chatStore from '@/store/chat';
import groupStore from '@/store/group';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';
import { base64ToBlob } from '@/ui/helpers/utils';

export default function useChatGroup() {
    const _myProfileStore = myProfileStore();
    const _chatStore = chatStore();
    const _groupStore = groupStore();

    const isCreating = ref<boolean>();
    const adminGroupByUserId = ref<{ [userId: number]: boolean }>({});

    const { goToConversation } = useChatCommon();

    const onCreateGroupChat = async (groupChat) => {
        isCreating.value = true;

        let conversation: any = null;
        try {
            // Prepare info
            const groupInfo: any = {
                name: groupChat?.name,
                isPrivate: true,
                type: GroupType.CHAT,
                memberInfos: [],
                domainIds: [],
            };

            // Prepare members
            groupInfo.memberInfos = groupChat.members?.map((user) => ({
                userId: user?.id,
                role: adminGroupByUserId.value[user?.id]
                    ? GroupMemberRole.ADMIN
                    : GroupMemberRole.MEMBER,
            }));

            // Upload avatar
            groupInfo.avatar = await _uploadGroupAvatar(groupChat?.avatar);

            // Group chat about task
            if (groupChat.chatAboutTaskId) {
                groupInfo.chatAboutTaskId = groupChat.chatAboutTaskId;
                groupInfo.chatAboutTaskCode = groupChat.chatAboutTaskCode;
            }

            // Create group
            const res = await _groupStore.createAndUpdate(groupInfo);

            // Create group conversation
            if (res?.result) {
                conversation = await _createGroupConversation(res.result);
            }
        } catch (e) {
            console.log(e);
        }

        isCreating.value = false;

        return conversation;
    };

    const _uploadGroupAvatar = async (avatar) => {
        let blob: any;

        if (avatar?.url) {
            blob = await fetch(avatar?.url).then((res) => res.blob());
        } else if (avatar?.base64) {
            blob = base64ToBlob(avatar?.base64, avatar?.contentType);
        }

        if (blob) {
            const fileType = avatar?.contentType;
            const fileName = `avatar.${fileType?.split('/').pop()}`;
            const avatarFile = new File([blob], fileName, { type: fileType });

            const result = await _groupStore.uploadAvatarAws(avatarFile);

            return result?.path;
        }

        return null;
    };

    const _createGroupConversation = async (group) => {
        const conversation = await _chatStore.createGroupChat(
            group,
            _myProfileStore.myProfile?.id
        );

        // Add user conversation locally
        _chatStore.addUserConversation(conversation?.id, conversation);

        goToConversation(conversation);

        return conversation;
    };

    return {
        isCreating,
        adminGroupByUserId,
        onCreateGroupChat,
    };
}
