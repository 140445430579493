<script setup lang="ts">
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import SingleCallButton from '@/ui/modules/video-call/components/SingleCallButton.vue';

const props = defineProps<{
  contact: any;
  customClass?: string;
}>();

const myUserId = computed(() => myProfileStore().myProfile?.id);

const callReceiverIds = computed<number[]>(() => {
  if (props.contact?.id == myUserId.value) return [];
  if (props.contact?.isUser) return [props.contact?.id];

  return (props.contact?.groupUsers || []).map((user) => user?.userId);
});
</script>

<template>
  <SingleCallButton
    v-if="callReceiverIds?.length > 0"
    :receiver-ids="callReceiverIds"
    :custom-class="customClass"
  />
</template>
