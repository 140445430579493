import BaseRequest from '@/base/api/base-request';
import { AxiosInstance } from 'axios';

export default class NotificationSettingRepository {
    private static instance: NotificationSettingRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }
    public static getInstance(): NotificationSettingRepository {
        if (!NotificationSettingRepository.instance) {
            // Get from local storage
            NotificationSettingRepository.instance =
                new NotificationSettingRepository();
        }

        return NotificationSettingRepository.instance;
    }

    setSettingNotification(data) {
        return this.request({
            url: `UserNotificationSetting/SettingNotification`,
            method: 'post',
            data,
        });
    }
    enableSettingNotification(data) {
        return this.request({
            url: `UserNotificationSetting/EnableSettingNotification`,
            method: 'post',
            data,
        });
    }

    getSettingNotification(refId, scope) {
        return this.request({
            url: `UserNotificationSetting/GetSettingNotification?refId=${refId}&scope=${scope}`,
            method: 'get',
        });
    }

    getUserNotificationSettingInGroups() {
        return this.request({
            url: `UserNotificationSetting/GetUserNotificationSettingInGroups`,
            method: 'get',
        });
    }

    notificationNegative(data) {
        return this.request({
            url: `UserNotificationSetting/NotificationNegative`,
            method: 'post',
            data,
        });
    }

    getFolderSettingNotification(data) {
        return this.request({
            url: `UserNotificationSetting/GetFolderNotification`,
            method: 'post',
            data,
        });
    }

    setFolderSettingNotification(data) {
        return this.request({
            url: `UserNotificationSetting/UpdateFolderNotification`,
            method: 'post',
            data,
        });
    }

    turnOffFolderSettingNotification(data) {
        return this.request({
            url: `UserNotificationSetting/UpdateFolderNotification`,
            method: 'post',
            data,
        });
    }

    reactionOnMessage(data) {
        return this.request({
            url: `UserNotificationSetting/ReactionOnMessage`,
            method: 'post',
            data,
        });
    }
}
