<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path
      d="M3.439 3l-1.439-1.714 1.532-1.286 17.382 20.714-1.533 1.286-2.533-3.019h-5.848l-7 5.02v-5.02h-4v-15.981h3.439zm11.747 14l-10.068-11.999h-3.118v11.999h4v3.105l4.357-3.105h4.829zm8.814 1.981h-2.588l-1.662-1.981h2.25v-11.999h-12.319l-1.679-2.001h15.998v15.981z"
    />
  </svg>
</template>
