<script setup lang="ts">
import NewChat from '@/ui/modules/messaging/chat-icon/NewChat.vue';
import { computed, onMounted, onUnmounted, watch } from 'vue';
import { AppMessageTypeEnum } from '@/domain/enums/app-message-type.enum';
import chatStore from '@/store/chat';
import PostMessageService from '@/application/services/PostMessageService';
import useChatGroup from '@/ui/composables/chat/chat-group-composables';
import UserDocModel from '@/ui/plugins/firebases/firestore-database/models/UserDocModel';
import myProfileStore from '@/store/auth/my-profile';

const _chatStore = chatStore();
const _myProfileStore = myProfileStore();

const userId = computed(() => _myProfileStore.myProfile?.id);
const bubbleChatActive = computed(() => _chatStore.bubbleChatActive);
const allUsers = computed(() => _chatStore.allUsers);

const { onCreateGroupChat } = useChatGroup();

let _isPageOpening: boolean;

watch(
  () => bubbleChatActive.value,
  (data) => {
    PostMessageService.getInstance().postMessageToParent({
      type: AppMessageTypeEnum.tictopChatOpen,
      data,
    });
  }
);

watch(
  () => userId.value,
  (userId) => {
    if (userId && _isPageOpening) _setUserSeenAllConversations();
  }
);

onMounted(() => {
  window.addEventListener('message', _listenMessageFromWebComponent);

  if (_isPageOpening) _setUserSeenAllConversations();
});

onUnmounted(() => {
  window.removeEventListener('message', _listenMessageFromWebComponent);
});

const onGroupChatAddClick = () => {
  const data = {
    users: allUsers.value,
  };

  PostMessageService.getInstance().postMessageToParent({
    type: AppMessageTypeEnum.tictopGroupChatAddClick,
    data,
  });
};

const openNewChatSuccess = () => {};

const _listenMessageFromWebComponent = (event) => {
  const { type, data, requestId } = event?.data || {};

  switch (type) {
    case AppMessageTypeEnum.componentGroupChatAddRequest:
      _onCreateGroupChat(data, requestId);
      break;
    case AppMessageTypeEnum.componentIconChatOpenState:
      _isPageOpening = data;
      if (_isPageOpening) _setUserSeenAllConversations();
      break;
  }
};

const _setUserSeenAllConversations = () => {
  if (!userId.value) return;

  return new UserDocModel(userId.value).setSeenAll();
};

const _onCreateGroupChat = async (groupChat, requestId) => {
  let result;

  try {
    result = await onCreateGroupChat(groupChat);
  } catch (e) {
    console.log(e);
  }

  PostMessageService.getInstance().postMessageToParent({
    type: AppMessageTypeEnum.tictopGroupChatAddResponse,
    data: result,
    requestId,
  });
};
</script>

<template>
  <div class="h-full flex flex-col">
    <div class="flex items-center justify-between gap-3 px-4 pt-3">
      <div class="font-medium">Tin nhắn</div>
      <VigButton
        rounded="rounded-2xl"
        padding="px-3 py-1"
        class="text-sm"
        @click="onGroupChatAddClick"
      >
        <SynIcon name="GroupAdd" />
        <span>{{ $t('CHAT_GROUP_NEW') }}</span>
      </VigButton>
    </div>
    <NewChat
      :is-chat-page="false"
      :is-show-close="false"
      :is-focus-input-search="true"
      is-open-dropdown
      @success="openNewChatSuccess"
    />
  </div>
</template>

<style scoped></style>
