<script setup lang="ts">
import { TWorkflowActionType } from '@/application/types/workflow/workflow.types';
import { computed } from 'vue';

const props = defineProps<{
  workflow: any;
  actionList?: TWorkflowActionType[];
}>();

const emit = defineEmits<{
  (e: 'action', type: TWorkflowActionType): void;
}>();

const actionDataListByKey = {
  PREVIEW: {
    key: 'PREVIEW',
    title: 'COMMON_LABEL_PREVIEW',
    iconName: 'eye2',
    color: '',
    handleClick: () => {
      emit('action', 'PREVIEW');
    },
  },
  DUPLICATE: {
    key: 'DUPLICATE',
    title: 'COMMON_LABEL_CLONE',
    iconName: 'duplicate',
    color: '',
    handleClick: () => {
      emit('action', 'DUPLICATE');
    },
  },
  EDIT: {
    key: 'EDIT',
    title: 'COMMON_LABEL_EDIT',
    iconName: 'edit',
    color: '',
    handleClick: () => {
      emit('action', 'EDIT');
    },
  },
  REMOVE: {
    key: 'REMOVE',
    title: 'COMMON_LABEL_REMOVE',
    iconName: 'trash',
    color: 'red',
    handleClick: () => {
      emit('action', 'REMOVE');
    },
  },
  CHOOSE: {
    key: 'CHOOSE',
    title: 'WORKFLOW_TASK_USE_AND_CUSTOMIZE',
    iconName: 'ChevronRight',
    color: '',
    handleClick: () => {
      emit('action', 'CHOOSE');
    },
  },
  ACTIVE: {
    key: 'ACTIVE',
    title: 'COMMON_LABEL_ACTIVATE',
    iconName: 'check',
    color: '',
    handleClick: () => {
      emit('action', 'ACTIVE');
    },
  },
};
const actionDataList = computed<any[]>(() => {
  return props.actionList?.map((key) => actionDataListByKey[key]);
});

const actionTitle = (action) => {
  if (action?.key == 'ACTIVE') {
    return props.workflow?.isActive
      ? 'COMMON_LABEL_INACTIVE'
      : 'COMMON_LABEL_ACTIVATE';
  }
  return action?.title;
};
</script>
<template>
  <div class="flex-center space-x-1">
    <div
      v-for="action in actionDataList"
      :key="action?.key"
      :title="$t(actionTitle(action)) || action?.key"
      class="
        w-8
        h-8
        rounded-md
        cursor-pointer
        flex-center
        border border-gray-100
        hover:shadow
      "
      @click.stop="action?.handleClick()"
    >
      <template v-if="action?.key == 'ACTIVE'">
        <SynIcon
          :name="workflow?.isActive ? 'NotUse' : 'Check'"
          :custom-class="
            action?.color ? `h-4 w-4 fill-${action?.color}-500` : ''
          "
        />
      </template>
      <SynIcon
        v-else
        :name="action?.iconName"
        :custom-class="action?.color ? `h-4 w-4 fill-${action?.color}-500` : ''"
      />
    </div>
  </div>
</template>
