<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import planStore from '@/store/plans';
import { IPlanDetail } from '@/application/interfaces/PaymentInterfaces';
import calculatePaymentPurchaseCompos from '@/ui/composables/plans/plan-detail-calculate-demo';
import { IPaymentVoucherDetail } from '@/application/types/payment.types';
import VoucherCard from '@/ui/modules/voucher/VoucherCard.vue';
import { EPlanId } from '@/application/constants/plan.const';

const props = withDefaults(
  defineProps<{
    planId: string;
    totalUsers: number;
    maxShowFeatures: number;
    voucher: IPaymentVoucherDetail;
  }>(),
  {
    planId: 'PRO',
    totalUsers: 1,
    maxShowFeatures: 4,
  }
);

const _planStore = planStore();
const allPlanByIds = computed<any>(() => _planStore.allPlanByIds);

const currentPlan = computed<IPlanDetail>(
  () => allPlanByIds.value[props.planId]
);

const {
  billingPeriodId,
  billingPeriodInfo,
  discountByPeriods,
  totalUsers,
  calculatedPricing,
  getLastTotalAmountAfterApplyVoucher,
} = calculatePaymentPurchaseCompos(props.planId);

const filteredDiscountByPeriods = computed<any>(() => {
  // if (props.voucher?.maxPaymentTermOffer > 0)
  //   return discountByPeriods.value
  //     ?.filter((period) => period?.period < props.voucher?.maxPaymentTermOffer)
  //     ?.slice(0, 3);

  return discountByPeriods.value?.slice(0, 3)?.map((item) => {
    return {
      ...item,
      periodToShow: item?.period == 12 ? 1 : item?.period,
      unitToShow: item?.period == 12 ? 'YEAR' : item?.unit,
    };
  });
});

const overVoucherLimit = computed<string>(() => {
  if (!props.voucher?.code) return false;

  if (
    props.voucher?.maxUserOffer > 0 &&
    props.totalUsers > props.voucher?.maxUserOffer
  )
    return 'OVER_MAX_USER_LIMIT';
  if (
    props.voucher?.maxPaymentTermOffer > 0 &&
    billingPeriodInfo.value?.period > props.voucher?.maxPaymentTermOffer
  )
    return 'OVER_MAX_PERIOD_LIMIT';
  return false;
});

const lastTotalAmountAfterApplyVoucher = computed<number>(() => {
  return getLastTotalAmountAfterApplyVoucher(
    props.voucher,
    calculatedPricing.value?.lastTotalAmount
  );
});

onMounted(() => {
  totalUsers.value = props.totalUsers;
});

watch(
  () => props.totalUsers,
  () => {
    totalUsers.value = props.totalUsers;
  }
);

const titleFeaturePlan = (planId) => {
  switch (planId) {
    case EPlanId.PRO:
      return 'PAYMENT_LABEL_INSTANT_EFFICIENCY';
    case EPlanId.VIP:
      return 'PAYMENT_LABEL_SYNERGISTIC_VALUE';
    default:
      return 'PAYMENT_LABEL_UPGRADE_YOUR_TEAM';
  }
};

const isOpenVoucherInfo = ref<boolean>(false);
</script>
<template>
  <div class="w-full h-full plan-item flex flex-col shadow-xl relative border">
    <!-- item header -->
    <section class="relative flex-center space-x-4 rounded-t-md w-full">
      <div class="w-full h-full flex flex-col items-start relative pb-4">
        <span
          class="text-gray-400"
          :style="{
            fontSize: '1.125rem',
            color: '#8899a8',
            fontWeight: 500,
          }"
        >
          {{ currentPlan?.descriptionWebCommercial }}
        </span>
        <div class="flex items-start gap-2">
          <span class="md:text-3xl text-2xl font-bold uppercase text-current">
            {{ currentPlan?.name == 'FREE' ? 'BASIC' : currentPlan?.name }}
          </span>
        </div>

        <div
          v-if="currentPlan?.id == 'PRO'"
          class="
            absolute
            -right-4
            top-5
            w-max
            rounded-md
            bg-yellow-300
            text-current-800
            px-2
            py-2
          "
        >
          {{ $t('COMMON_LABEL_POPULAR') }}
        </div>
      </div>

      <div
        v-if="!currentPlan?.isActive"
        class="absolute"
        style="right: -1.4rem; top: -0.6rem"
      >
        <div class="flex-center relative">
          <SynIcon name="tag" custom-class="h-28" />
          <span
            class="absolute text-white text-center font-bold"
            style="
              top: 30%;
              left: 24%;
              font-size: 0.7rem;
              transform: rotate(46deg);
            "
          >
            COMING SOON
          </span>
        </div>
      </div>
    </section>
    <div class="w-full">
      <div class="border-t"></div>
    </div>

    <!-- item body -->
    <section
      class="
        flex-1
        min-h-0
        flex flex-col
        space-y-4
        overflow-y-auto overflow-x-hidden
        small-scrollbar
        relative
        pt-6
      "
    >
      <!-- calculate pricing -->
      <div class="flex flex-col gap-4">
        <div class="flex-center h-10">
          <AtomLabelSwitch
            v-model="billingPeriodId"
            :options="filteredDiscountByPeriods"
          >
            <template #label="{ item }">
              <span>
                {{ item?.periodToShow }}
                {{
                  item?.unitToShow == 'YEAR'
                    ? $t('COMMON_LABEL_YEAR', {
                        suffix: item?.periodToShow > 1 ? 's' : '',
                      })
                    : $t('COMMON_LABEL_MONTH', {
                        suffix: item?.periodToShow > 1 ? 's' : '',
                      })
                }}
              </span>
            </template>
            <template #suffix="{ key, active }">
              <AtomStarburst
                v-if="
                  (discountByPeriods?.find((o) => o.id === key)?.discount ||
                    0) > 0
                "
                class="absolute h-6 w-6 -top-4"
                :class="
                  active
                    ? ' bg-orange-500 text-white text-xs'
                    : ' bg-orange-400 text-white text-xs'
                "
              >
                -{{
                  (discountByPeriods?.find((o) => o.id === key)?.discount ||
                    0) * 100
                }}%
              </AtomStarburst>
            </template>
          </AtomLabelSwitch>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex items-center justify-start gap-1">
            <span
              :style="{
                fontSize: '2.5rem',
                fontWeight: 600,
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                color: '#263d55',
                lineHeight: '1.5',
                letterSpacing: '.4px',
              }"
            >
              {{
                $filters.currency(
                  calculatedPricing?.priceActualForOneUserInOneMonth,
                  'vi'
                )
              }}
            </span>
            <span
              class="text-gray-500"
              :style="{
                fontSize: '0.875rem',
                lineHeight: '22px',
                fontWeight: 500,
              }"
              v-html="$t('COMMOM_LABEL_UNIT_PRICE_PER_USER_MONTH')"
            >
            </span>
          </div>
          <div
            class="text-start pt-4 text-gray-500 italic text-sm"
            :style="{
              lineHeight: '22px',
            }"
          >
            {{
              $t('PLAN_LABEL_FOR_TOTAL_USER', {
                totalUsers,
                totalMonths: billingPeriodInfo?.period,
              })
            }}
          </div>
          <div
            class="flex items-start space-x-2"
            :style="{
              fontSize: '1.125rem',
            }"
          >
            <span
              class="font-normal"
              :style="{
                fontSize: '18px',
                fontFamily: 'Plus Jakarta Sans',
                color: '#263d55',
                fontWeight: 600,
              }"
            >
              {{ $filters.currency(calculatedPricing?.lastTotalAmount, 'vi') }}
            </span>
            <span
              v-if="
                calculatedPricing?.discountPercent > 0 &&
                calculatedPricing?.lastTotalAmount > 0
              "
              class="flex space-x-2 items-center text-gray-700"
            >
              <span
                class="line-through text-gray-500"
                :style="{
                  fontSize: '16px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 500,
                }"
              >
                {{
                  $filters.currency(
                    calculatedPricing?.lastTotalAmountNotDiscount,
                    'vi'
                  )
                }}
              </span>
              <span
                class="p-1 bg-orange-200 text-orange-600 rounded-full text-sm"
              >
                - {{ calculatedPricing?.discountPercent }}%
              </span>
            </span>
          </div>
        </div>
      </div>

      <!-- features -->
      <div
        v-if="
          currentPlan?.planFeatures && currentPlan?.planFeatures?.length > 0
        "
        class="py-4 flex flex-1 min-h-0 flex-col"
      >
        <span
          class="font-semibold text-gray-600"
          :style="{
            fontSize: '.875rem',
          }"
          v-html="
            $t(titleFeaturePlan(planId), {
              planId: planId == 'VIP' ? 'PRO' : '',
            })
          "
        >
        </span>
        <div
          class="min-h-0 overflow-auto small-scrollbar flex flex-col py-4 gap-3"
        >
          <div
            v-for="des in currentPlan.planFeatures?.slice(0, maxShowFeatures)"
            :key="des?.id"
            class="flex space-x-2 items-start"
          >
            <div class="w-4 h-4 flex-center pt-1">
              <div
                class="
                  w-4
                  h-4
                  flex-center
                  rounded-full
                  border border-current-300
                "
              >
                <SynIcon
                  name="check"
                  custom-class="w-3 h-3 fill-current text-current-500"
                />
              </div>
            </div>
            <span
              class="text-gray-600"
              :style="{
                fontSize: '.975rem',
                fontFamily: ' sans-serif',
                fontWeight: 500,
              }"
            >
              {{ des?.nameWebCommercial }}
            </span>
          </div>
        </div>
      </div>
    </section>
    <!-- actions -->
    <div>
      <template v-if="voucher?.code">
        <div class="w-full flex-center">
          <div
            class="
              px-2
              py-1
              rounded-md
              flex-center
              space-x-2
              hover:bg-gray-50 hover:ring-1
              ring-current-50
              cursor-pointer
            "
            @click="isOpenVoucherInfo = true"
          >
            <SynIcon name="ticket-voucher" custom-class="h-8 w-8" />
            <span class="text-sm text-gray-500 font-semibold">
              {{ voucher?.code }}
            </span>
          </div>
        </div>
        <div
          v-if="overVoucherLimit"
          class="text-center text-sm italic text-orange-400 pb-4"
        >
          {{
            $t(
              overVoucherLimit == 'OVER_MAX_USER_LIMIT'
                ? 'DECIDE_PAYMENT_PAY_WITH_VOUCHER_OVER_LIMIT_USER'
                : 'DECIDE_PAYMENT_PAY_WITH_VOUCHER_OVER_LIMIT_PERIOD',
              {
                totalUsers: voucher?.maxUserOffer,
                period: voucher?.maxPaymentTermOffer,
              }
            )
          }}
        </div>
        <div
          v-else
          class="text-center text-sm italic text-orange-400 pb-4"
          :style="{
            fontSize: '0.825rem',
          }"
        >
          {{
            $t('PAYMENT_PRICE_AFTER_APPLY_VOUCHER') ||
            'Giá sau khi áp dụng mã giảm giá'
          }}
          <span class="font-semibold">
            {{ $filters.currency(lastTotalAmountAfterApplyVoucher, 'vi') }}
          </span>
        </div>
      </template>

      <slot
        name="action"
        :current-plan-active="currentPlan?.isActive"
        :current-plan-id="planId"
        :period="billingPeriodInfo?.period"
        :is-over-voucher-limit="!!overVoucherLimit"
      ></slot>
    </div>
  </div>
  <SynModal
    v-if="isOpenVoucherInfo"
    z-index="z-100"
    disable-element-active
    container-class="w-max"
    is-hidden-footer
    is-hidden-header
    @cancel="isOpenVoucherInfo = false"
  >
    <template #body>
      <VoucherCard hidden-close :voucher-detail="voucher" />
    </template>
  </SynModal>
</template>
<style>
.plan-item {
  box-shadow: 0 4px 12px rgba(13, 10, 44, 0.06);
  border-radius: 24px;
  background-color: #fff;
  height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 40px 32px;
  font-family: sans-serif;
  line-height: 1.5;
  letter-spacing: 0.4px;
}
</style>
