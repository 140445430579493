<script setup lang="ts">
import { useRouter } from 'vue-router';
import { onMounted, onUnmounted } from 'vue';
import { encryptGroupId } from '@/ui/plugins/crypto/crypto';
import alertStore from '@/store/alert';
import { NotificationTypeEnum } from '@/ui/hooks/commonHook';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';

const _alertStore = alertStore();
const props = withDefaults(
  defineProps<{
    notificationId: number | undefined;
    groupId: number | string;
    groupName: string;
    groupAvatar: string;
    duration: number;
    type?: NotificationTypeEnum;
  }>(),
  {
    duration: 5000,
    type: 'group',
  }
);
const _useRouter = useRouter();

const { goToChatFromContact } = useChatCommon();

let timeoutFn;
const timeout = () => {
  timeoutFn = setTimeout(() => {
    removeNotificationBar();
  }, props.duration || 5000);
};
onMounted(() => {
  timeout();
});
onUnmounted(() => {
  timeoutFn && clearTimeout(timeoutFn);
});
const onMouseover = () => {
  timeoutFn && clearTimeout(timeoutFn);
};
const onMouseleave = () => {
  timeout();
};

const onMessageToUser = () => {
  if (!props?.groupId) return;

  const contact = {
    id: props?.groupId,
    name: props?.groupName,
    avatar: props?.groupAvatar,
  };

  goToChatFromContact(contact, true);

  removeNotificationBar();
};
const onGotoTaskClick = () => {
  if (!props?.groupId) return;

  _useRouter.push(`/tasks/groups/${encryptGroupId(props?.groupId)}`);

  removeNotificationBar();
};

const removeNotificationBar = () => {
  _alertStore.removeDataAlerts({ id: props.notificationId });
};
</script>

<template>
  <div
    class="
      w-full
      relative
      p-2
      rounded
      flex
      items-center
      justify-between
      transition-all
      mt-4
      bg-current-400
      text-white
    "
    @mouseover="onMouseover"
    @mouseleave="onMouseleave"
  >
    <!-- <div class="transform-gpu absolute wave -left-6 -top-3">
      <SynIcon name="tictop-logo" custom-class="w-8 h-8" />
    </div> -->
    <div class="flex items-center">
      <div class="w-8 h-8 shrink-0 flex-center bg-white rounded-full">
        <SynIcon name="tictop-logo" custom-class="w-7 h-7 rounded-full" />
      </div>
      <div class="flex flex-col space-y-2 px-2 flex-nowrap">
        <div class="text-ellipsis text-sm" style="white-space: pre-wrap">
          {{
            $t(
              type == 'group'
                ? 'API_ACTION_MESSAGE_GROUP_BE_ADDED'
                : 'API_ACTION_MESSAGE_GROUP_CREATE_SUCCESS'
            )
          }}
          <span class="font-semibold"> {{ groupName }}</span>
        </div>
        <div class="flex space-x-2">
          <span
            :title="
              $t('LIST_TASK_VIEW_USER_TASK', { user: groupName || 'group' })
            "
            class="
              w-6
              h-6
              flex-center
              fill-current
              cursor-pointer
              shadow-md
              rounded-full
              text-white
              bg-white
              hover:bg-current-100
            "
            @click="onGotoTaskClick"
          >
            <SynIcon name="note" is-active custom-class="w-3.5 h-3.5" />
          </span>

          <!-- CHAT -->
          <span
            v-permission="'Messages'"
            :title="
              $t('LIST_TASK_CHAT_TO_USER', {
                user: groupName || 'group',
              })
            "
            class="
              dropdown-item
              cursor-pointer
              flex-center
              w-6
              h-6
              shadow-md
              rounded-full
              fill-white
              text-white
              bg-blue-500
              hover:bg-blue-600
            "
            @click="onMessageToUser"
          >
            <SynIcon name="message" is-active custom-class="w-3.5 h-3.5" />
          </span>
        </div>
      </div>
    </div>
    <div
      class="
        cursor-pointer
        w-6
        h-6
        rounded-full
        hover:bg-white hover:bg-opacity-20
        flex-center
        absolute
        top-1
        right-1
        do-not-close-task-detail-drawer
      "
      @click.stop="removeNotificationBar"
    >
      <SynIcon name="close" custom-class="w-4 h-4 fill-white" />
    </div>
  </div>
</template>
