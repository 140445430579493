<script setup lang="ts">
// *** IMPORTS ***
import { ref } from 'vue';
import ModalCalendarSharings from '@/ui/modules/calendar/calendar-sharing/modal-calendar-sharings/ModalCalendarSharings.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';

// *** PROPS, EMITS ***
defineProps<{
  buttonClass: any;
}>();

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const isOpenModalSharings = ref<boolean>();

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <VigButton
    v-permission-function="{
      functionCode: ALL_FUNCTIONS.CALENDAR.SHARING,
      needToShowPremiumIcon: true,
      onContinue: () => {
        isOpenModalSharings = true;
      },
    }"
    ghost
    color="gray"
    :class="buttonClass"
    @click="isOpenModalSharings = true"
  >
    <slot />
  </VigButton>

  <ModalCalendarSharings
    v-if="isOpenModalSharings"
    @on-close="isOpenModalSharings = false"
  />
</template>

<style scoped></style>
