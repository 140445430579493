<script setup lang="ts">
// *** IMPORTS ***
import { computed, ref } from 'vue';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import systemConfig from '@/application/constants/system-config.const';

// *** PROPS, EMITS ***
const props = defineProps<{
  settings: any;
}>();

const emit = defineEmits(['onSettingChange']);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const proofOptions = ref<any[]>([
  {
    value: '1',
    text: 'SETTING_TASK_CREATE_LIST_ASSIGNEES_AUTO_PROOF_FINISH_ENABLED',
  },
  {
    value: '0',
    text: 'SETTING_TASK_CREATE_LIST_ASSIGNEES_AUTO_PROOF_FINISH_DISABLED',
  },
]);

// *** COMPUTED ***
const createSubtasksEnabledSystem = computed(
  () =>
    props.settings &&
    props.settings[systemConfig.ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM] ===
      '1'
);
const autoAssigneeSubtasksEnabledSystem = computed(
  () =>
    props.settings &&
    props.settings[
      systemConfig.ADVANCED_ASSIGNEE_SYSTEM_ENABLE_AUTOMATIC_CREATI
    ] === '1'
);

const createSubtasksEnabled = computed({
  get() {
    return (
      props.settings &&
      props.settings[systemConfig.ADVANCED_SUB_TASK_SUB_TASK_ALLOWED] === '1'
    );
  },
  set(value) {
    emit('onSettingChange', {
      key: systemConfig.ADVANCED_SUB_TASK_SUB_TASK_ALLOWED,
      value: value ? '1' : '0',
    });
  },
});

const autoAssigneeSubtasksEnabled = computed({
  get() {
    return (
      props.settings &&
      props.settings[
        systemConfig.ADVANCED_ASSIGNEE_ENABLE_AUTOMATIC_CREATION_OF_S
      ] === '1'
    );
  },
  set(value) {
    emit('onSettingChange', {
      key: systemConfig.ADVANCED_ASSIGNEE_ENABLE_AUTOMATIC_CREATION_OF_S,
      value: value ? '1' : '0',
    });
  },
});

const autoProofSubtasksOption = computed(
  () =>
    props.settings &&
    props.settings[
      systemConfig.ADVANCED_ASSIGNEE_ENABLING_PROOF_OF_COMPLETION_F
    ] === '1'
);

const proofEnabledSystem = computed(
  () =>
    props.settings &&
    props.settings[
      systemConfig.ADVANCED_COMPLETI_SYSTEM_COMPLETION_PROOF_ACTIVA
    ] === '1'
);

const proofEnabled = computed(
  () =>
    props.settings &&
    props.settings[
      systemConfig.ADVANCED_COMPLETI_COMPLETION_PROOF_ACTIVATED
    ] === '1'
);

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onChangeAutoProofOption = (opt) => {
  emit('onSettingChange', {
    key: systemConfig.ADVANCED_ASSIGNEE_ENABLING_PROOF_OF_COMPLETION_F,
    value: opt?.value,
  });
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div v-if="createSubtasksEnabledSystem">
    <MenuSettingRowItem
      name-icon="SubTask"
      :icon-is-active="false"
      :title-menu="$t('SETTING_ORGANIZATION_SUB_TASK_ALLOW')"
      :sub-title="$t('SETTING_ORGANIZATION_SUB_TASK_ALLOW_DESC')"
    >
      <template #action>
        <AtomSwitch v-model="createSubtasksEnabled" />
      </template>
      <template #subMenu>
        <div
          v-if="createSubtasksEnabled && autoAssigneeSubtasksEnabledSystem"
          class="pl-2 border-t border-dashed"
        >
          <MenuSettingRowItem
            :title-menu="$t('SETTING_TASK_CREATE_LIST_ASSIGNEES_ALLOWED')"
            :sub-title="$t('SETTING_TASK_CREATE_LIST_ASSIGNEES_ALLOWED_DESC')"
          >
            <template #action>
              <AtomSwitch v-model="autoAssigneeSubtasksEnabled" />
            </template>
          </MenuSettingRowItem>
          <MenuSettingRowItem
            v-if="
              autoAssigneeSubtasksEnabled && proofEnabledSystem && proofEnabled
            "
            :title-menu="
              $t('SETTING_TASK_CREATE_LIST_ASSIGNEES_AUTO_PROOF_FINISH')
            "
            :sub-title="
              $t('SETTING_TASK_CREATE_LIST_ASSIGNEES_AUTO_PROOF_FINISH_DESC')
            "
          >
            <template #action>
              <div class="flex-center gap-2">
                <div
                  v-for="opt in proofOptions"
                  :key="opt?.value"
                  class="flex-center space-x-2"
                >
                  <SynCheckboxLabel
                    class="ml-1 text-xs"
                    :is-check="opt?.value == autoProofSubtasksOption"
                    :text="$t(opt?.text)"
                    @click="onChangeAutoProofOption(opt)"
                  />
                </div>
              </div>
            </template>
          </MenuSettingRowItem>
        </div>
      </template>
    </MenuSettingRowItem>
  </div>
</template>

<style scoped></style>
