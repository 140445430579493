<script setup lang="ts">
import { computed } from 'vue';
import groupStore from '@/ticket/store/group-store';

const props = defineProps({
  id: {
    type: [String, Number],
    default: null,
  },
  isHiddenName: {
    type: Boolean,
    default: false,
  },
  isHiddenAvatar: {
    type: Boolean,
    default: false,
  },
  avatarClass: {
    type: String,
    default: 'w-6 h-6',
  },
});
const _groupStore = groupStore();
const allMyGroup = computed(() => _groupStore.myGroups);
const groupAvatar = computed(() => {
  const index = allMyGroup.value?.findIndex((o) => o.id == props.id);
  return index > -1
    ? allMyGroup.value[index].avatarUrl?.replace('original', 'mini')
    : '';
});
const groupName = computed(() => {
  const index = allMyGroup.value?.findIndex((o) => o.id == props.id);
  return index > -1 ? allMyGroup.value[index].name : '';
});
</script>

<template>
  <div class="flex items-center space-x-2 font-sans">
    <span v-if="!isHiddenAvatar" :class="avatarClass">
      <syn-avatar
        v-vig-tooltip="groupName"
        :src="groupAvatar"
        :name="groupName"
        :custom-class="avatarClass"
      />
    </span>
    <span
      v-if="!isHiddenName"
      class="min-w-max"
      style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
    >
      {{ groupName }}
    </span>
  </div>
</template>
