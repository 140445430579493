<script setup lang="ts">
import UserByAction from '@/ui/components/user/UserByAction.vue';
// import DayoffStatusAtom from '@/ui/components/dayoff/atoms/DayoffStatusAtom.vue';
// import getUserInfo from '@/ui/helpers/user-helper';
// import DayoffTypeAtom from '@/ui/components/dayoff/atoms/DayoffTypeAtom.vue';
import DayoffDateSessionAtom from '@/ui/components/dayoff/atoms/DayoffDateSessionAtom.vue';
import { DayoffType, DayoffExceptionType } from '@/domain/enums/DayoffEnums';
// import DayoffExceptionTypeAtom from '@/ui/components/dayoff/atoms/DayoffExceptionTypeAtom.vue';
import { computed } from 'vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import {
  DayoffStatusUI,
  DayoffTypeUI,
} from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  dayoffData: any;
  isShowTitle?: boolean;
  isHiddenName?: boolean;
}>();

const dayoffTitle = computed(() => {
  switch (props.dayoffData.type) {
    case DayoffType.EXCEPTION:
      // if (!props.dayoffData?.exception?.type)
      //   return translate('DAYOFF_REQUEST_TITLE');
      // if (props.dayoffData?.exception?.type === DayoffExceptionType.BREAK_TIME)
      //   return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME');
      // else if (
      //   props.dayoffData?.exception?.type === DayoffExceptionType.START_LATE
      // )
      //   return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME_START_LATE');
      // else return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME_STOP_EARLY');
      if (props.dayoffData?.exception?.type === DayoffExceptionType.BREAK_TIME)
        return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME');
      else if (
        props.dayoffData?.exception?.type === DayoffExceptionType.START_LATE
      )
        return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME_START_LATE');
      else if (
        props.dayoffData?.exception?.type === DayoffExceptionType.STOP_EARLY
      )
        return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME_STOP_EARLY');
      else return translate('DAYOFF_REQUEST_TITLE');

    case DayoffType.WFH:
      return translate('DAYOFF_REQUEST_TITLE_WORK_FROM_HOME');
    default:
      return translate('DAYOFF_REQUEST_TITLE') || 'Dayoff request';
  }
});
</script>

<template>
  <div class="flex flex-col">
    <div
      class="p-2 flex items-center space-x-3 rounded-t"
      :style="[
        { color: DayoffStatusUI[dayoffData.status].color },
        {
          background:
            dayoffData.status != 'PENDING'
              ? DayoffStatusUI[dayoffData.status].backgroundColor
              : DayoffStatusUI[dayoffData.status].backgroundColorForChat,
        },
      ]"
    >
      <div class="flex-center">
        <SynIcon
          :name="DayoffTypeUI[dayoffData.type].iconName"
          custom-class="w-3.5 h-3.5"
          :style="{ fill: DayoffStatusUI[dayoffData.status].color }"
        />
      </div>
      <div class="flex items-center space-x-2">
        <span class="text-xs font-medium">
          {{ dayoffTitle }}
        </span>
        <!--        <span class="text-xs italic"-->
        <!--          >({{ $t(DayoffStatusUI[dayoffData.status].textCode) }})</span-->
        <!--        >-->
      </div>
    </div>
    <div class="py-3 px-2 flex flex-col space-y-3">
      <div class="flex items-center space-x-3">
        <div class="flex-center">
          <SynIcon name="Timer" custom-class="w-3.5 h-3.5 fill-gray-500" />
        </div>
        <div class="flex-1 flex items-center text-xs space-x-2">
          <template v-if="dayoffData?.type !== DayoffType.EXCEPTION">
            <span class="">
              <SynLabelDateTime
                format="date"
                ignore-timezone
                :datetime="dayoffData.startDate"
              />
            </span>
            <span v-if="dayoffData.startDate !== dayoffData.endDate">
              -
              <SynLabelDateTime
                format="date"
                ignore-timezone
                :datetime="dayoffData.endDate"
              />
            </span>
          </template>
          <template v-else>
            <span class="flex items-center space-x-1 text-xs">
              <span>
                {{ $filters.dayjs(dayoffData.startDate, 'MMM DD, YYYY') }}
              </span>
              ,
              <span>
                {{
                  $filters.dayjs(dayoffData.exception?.startTime, ' hh:mm A')
                }}
              </span>
              <span
                v-if="
                  dayoffData.exception?.type === DayoffExceptionType.BREAK_TIME
                "
              >
                -
                {{ $filters.dayjs(dayoffData.exception.endTime, 'hh:mm A') }}
              </span>
            </span>
          </template>
          <span
            v-if="dayoffData?.type === DayoffType.EXCEPTION"
            class="text-gray-500 text-xs"
          >
            (<DayoffDateSessionAtom
              class="text-gray-500 text-xs"
              is-text-only
              :date-session="dayoffData?.exception?.daySession"
            />)
          </span>
          <span
            v-else-if="dayoffData?.dateOffs?.length > 1"
            class="text-gray-500 text-xs"
          >
            ({{ dayoffData.usedDay }}d)
          </span>
          <span
            v-else-if="dayoffData?.dateOffs?.length > 0"
            class="text-gray-500 text-xs"
          >
            (<DayoffDateSessionAtom
              class="text-gray-500 text-xs"
              is-text-only
              :date-session="dayoffData?.dateOffs[0]?.daySession"
            />)
          </span>
        </div>
      </div>

      <div class="flex items-center space-x-3">
        <div class="flex-center">
          <SynIcon name="user" custom-class="w-3.5 h-3.5 fill-gray-400" />
        </div>
        <div class="flex-1">
          <UserByAction
            :user-id="dayoffData.userId"
            is-show-action-user
            name-text-class="text-xs"
            avatar-class="w-5 h-5"
          />
        </div>
      </div>

      <div v-if="dayoffData?.requestNote" class="flex items-center space-x-3">
        <div class="flex-center">
          <SynIcon name="note" custom-class="w-3.5 h-3.5 fill-gray-400" />
        </div>
        <div
          class="flex-1 text-xs text-gray-700 italic text-overflow-hidden"
          style="max-height: 3rem; max-width: 18rem"
        >
          {{ dayoffData?.requestNote }}
        </div>
      </div>
    </div>
  </div>
</template>
