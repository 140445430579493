<script setup lang="ts">
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

withDefaults(
  defineProps<{
    taskDetail?: ITaskDetailClass;
    isLoading?: boolean;
    readonly?: boolean;
    dataItem?: any;
    objectLoading?: any;
    taskLife?: number;
    finisherId?: number;
  }>(),
  {}
);
const statusStepper = {
  Active: 2,
  DeActive: 0,
  InProcess: 1,
};
</script>

<template>
  <div
    class="vig-stepper_item flex-center space-x-1.5 py-1 pr-2 pl-1 rounded-full"
    :data-readonly="readonly"
    :data-disabled="`${dataItem?.disabled}`"
    :data-step="`${dataItem?.type}`"
    :data-active="`${dataItem?.statusStepper === statusStepper.Active}`"
    :data-loading="`${
      objectLoading?.isLoading && objectLoading?.state === dataItem?.key
    }`"
    :class="[
      isLoading ? 'cursor-pointer animate-pulse' : '',
      !readonly ? `cursor-pointer` : '',
      objectLoading?.isLoading ? 'cursor-not-allowed' : '',
      objectLoading?.isLoading && objectLoading?.state === dataItem?.key
        ? 'bg-current-500 text-white'
        : '',
    ]"
  >
    <div
      class="w-3.5 h-3.5 rounded-full flex-center"
      :class="[
        dataItem?.statusStepper === statusStepper.Active
          ? dataItem?.classActive
          : '',
        dataItem?.statusStepper === statusStepper.InProcess
          ? dataItem?.classInProcess
          : '',
        dataItem?.statusStepper === statusStepper.DeActive
          ? `border border-${dataItem?.color}-500`
          : '',
        dataItem?.disabled ? 'border border-gray-200' : '',
      ]"
    >
      <syn-icon
        v-if="
          objectLoading?.isLoading && objectLoading?.state === dataItem?.key
        "
        name="spinner"
        class="animate-spin"
        custom-class="w-4 h-4 fill-white text-white"
      />
      <syn-icon
        v-else
        class="vig-stepper_icon_check invisible"
        name="check"
        custom-class="w-4 h-4 fill-white text-white"
      />
    </div>
    <span
      class="vig-stepper_label text-xs max-w-[12rem] truncate"
      :data-loading="`${
        objectLoading?.isLoading && objectLoading?.state === dataItem?.key
      }`"
      :class="
        dataItem?.statusStepper !== statusStepper?.DeActive
          ? `font-medium`
          : 'text-gray-500'
      "
    >
      <slot>
        <span> {{ $t(dataItem?.label) }} </span>
      </slot>
    </span>
  </div>
</template>

<style scoped>
.vig-stepper_item[data-step='Todo']:not([data-readonly='true']):hover {
  background-color: #3b82f6;
}

.vig-stepper_item[data-step='InProcess']:not([data-readonly='true']):hover {
  background-color: #ec4899;
}

.vig-stepper_item[data-step='Finished']:not([data-readonly='true']):hover {
  background-color: #10b981;
}

.vig-stepper_item[data-step='Todo'][data-active='true'] {
  background-color: #3b82f6;
  color: white !important;
}

.vig-stepper_item[data-step='InProcess'][data-active='true'] {
  background-color: #ec4899;
  color: white !important;
}

.vig-stepper_item[data-step='Finished'][data-active='true'] {
  background-color: #10b981;
  color: white !important;
}

.vig-stepper_item[data-step='Finished'][data-disabled='true'] {
  cursor: text;
  border: 1px solid rgba(238, 234, 234, 0.65) !important;
  background-color: #f8f9fa !important;
  color: rgba(238, 234, 234);
}

.vig-stepper_label[data-loading='true'] {
  color: white !important;
}

.vig-stepper_item[data-disabled='false']:not([data-readonly='true']):hover
  .vig-stepper_label {
  color: #fff;
}

.vig-stepper_item[data-disabled='true']:hover {
  background-color: #f8f9fa !important;
  color: rgba(250, 250, 250, 0.65) !important;
}

.vig-stepper_item[data-active='true'] .vig-stepper_icon_check {
  visibility: visible;
}

.vig-stepper_item:not([data-readonly='true']):hover .vig-stepper_icon_check {
  visibility: visible;
}

.vig-stepper_label {
}
</style>
