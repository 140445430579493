<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import { useRoute, useRouter } from 'vue-router';
import myProfileStore from '@/store/auth/my-profile';
import WorkflowAddNewModal from '@/ui/modules/workflow/workflow-detail/WorkflowAddNewModal.vue';
import { orderBy } from 'lodash';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
import { arrToObj } from '@/ui/helpers/utils';
import WorkflowActions from '@/ui/modules/workflow/components/WorkflowActions.vue';
import { IWorkflowEntity } from '@/domain/entities/workflow/WorkflowEntity';
import { TWorkflowActionType } from '@/application/types/workflow/workflow.types';
import WorkflowDetailModal from '@/ui/modules/workflow/workflow-detail/WorkflowDetailModal.vue';

const route = useRoute();
const router = useRouter();
const myProfile = myProfileStore();

const workflows = ref<any[]>();
const taskCountByWorkflow = ref<{ [id: string]: number }>({});
const searchText = ref<string>('');
const isLoading = ref<boolean>();
const isLoadingTaskCount = ref<boolean>();
const isRefreshing = ref<boolean>();
const isOpenNewWorkflow = ref<boolean>();
const baseImageUrl = ref(`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web`);

const canManageWorkflow = computed(
  () => myProfile?.isOwner || myProfile?.isAdmin
);

let searchDelayTimer: any;
let previousSearchText: string = '';

onBeforeMount(() => {
  _getWorkflows();
  _getCountWorkflowsTasks();
});

const onSearch = () => {
  if (searchText.value === previousSearchText) return;

  clearTimeout(searchDelayTimer);

  searchDelayTimer = setTimeout(() => {
    _getWorkflows(false);
  }, 200);

  previousSearchText = searchText.value;
};

const onRefreshClick = async () => {
  isRefreshing.value = true;

  try {
    _getCountWorkflowsTasks();

    await _getWorkflows();
  } catch (e) {
    console.log(e);
  }

  isRefreshing.value = false;
};

const onCreateWorkflowClick = () => {
  isOpenNewWorkflow.value = true;
};

const onCreateWorkflowSuccess = () => {
  _getWorkflows(false);
  _getCountWorkflowsTasks(false);

  isOpenNewWorkflow.value = false;
};

const onWorkflowClick = (workflow) => {
  router.push({
    path: route.path,
    query: { ...route.query, workflowId: workflow?.id },
  });
};

const _getWorkflows = async (showLoading = true) => {
  if (showLoading) {
    workflows.value = [];
    isLoading.value = true;
  }

  try {
    const res = await WorkflowRepository.getInstance().getWorkflowsWithFilters({
      keyword: searchText.value,
    });
    workflows.value = orderBy(res?.result, 'creationTime', 'desc');
  } catch (e) {
    console.log(e);
  }

  isLoading.value = false;
};

const _getCountWorkflowsTasks = async (showLoading = true) => {
  if (showLoading) isLoadingTaskCount.value = true;

  try {
    const res = await WorkflowRepository.getInstance().countWorkflowsTasks();

    taskCountByWorkflow.value = arrToObj(
      res?.result,
      'workflowId',
      'taskCount'
    );
  } catch (e) {
    console.log(e);
  }

  isLoadingTaskCount.value = false;
};

const currentWorkflow = ref<IWorkflowEntity | undefined>(undefined);
const viewDetailMode = ref<'EDIT_STEP' | 'VIEW'>('VIEW');
const isOpenPreviewModal = ref<boolean>(false);
const onOpenDetailModal = (workflow, viewMode?) => {
  currentWorkflow.value = workflow;
  viewDetailMode.value = viewMode || 'VIEW';

  isOpenPreviewModal.value = true;
};
const onActionOnWorkflow = (actionType: TWorkflowActionType, workflow) => {
  switch (actionType) {
    case 'EDIT':
      onOpenDetailModal(workflow, 'EDIT_STEP');
      break;

    default:
      break;
  }
};
const updateWorkflowSuccess = (newData) => {
  const index = workflows.value?.findIndex(
    (wf) => wf?.id == currentWorkflow.value?.id
  );
  if (index > -1) {
    workflows.value[index] = newData;
  }

  isOpenPreviewModal.value = false;
};
</script>

<template>
  <div class="h-full flex flex-col">
    <!--HEADER-->
    <div class="flex items-center justify-between px-3 py-2 gap-2">
      <div class="px-1 font-semibold">{{ $t('OVERVIEW_WORKFLOWS') }}</div>
      <div class="flex align-center gap-2">
        <SynSearch
          v-model="searchText"
          icon-prefix="search"
          autofocus
          is-show-reset
          :placeholder="$t('COMMON_LABEL_SEARCH_PLACEHOLDER_TASK')"
          @enter="onSearch"
          @on-reset="onSearch"
          @blur="onSearch"
        />
        <VigButton
          ghost
          rounded="rounded-full"
          color="gray"
          icon="Reload"
          :processing="!!isRefreshing"
          @click="onRefreshClick"
        ></VigButton>
        <VigButton
          v-if="canManageWorkflow"
          icon="Plus"
          @click="onCreateWorkflowClick"
        ></VigButton>
      </div>
    </div>

    <!--BODY-->
    <div v-perfect-scrollbar class="flex-1 px-3">
      <SynTableCustom v-if="workflows?.length || isLoading">
        <template #header>
          <SynTh :label="$t('COMMON_LABEL_CODE')" class="w-[2rem]" />
          <SynTh :label="$t('COMMON_LABEL_NAME')" class="w-96" />
          <SynTh
            :label="$t('WORKFLOW_LABEL_NUM_OF_STEPS')"
            is-center
            class="w-32"
          />
          <SynTh
            :label="$t('COMMON_LABEL_NUM_OF_TASKS')"
            is-center
            class="w-32"
          />
          <SynTh
            :label="$t('COMMON_LABEL_CREATED_BY')"
            is-center
            class="w-32"
          />
          <SynTh
            :label="$t('COMMON_LABEL_CREATED_DATE')"
            is-center
            class="w-32"
          />
          <SynTh></SynTh>
          <!--          <SynTh :label="$t('COMMON_LABEL_DEPARTMENT')" />-->
          <!--          <SynTh :label="$t('TASK_CREATE_FORM_DOMAIN')" />-->
          <!--          <SynTh :label="$t('COMMON_LABEL_GROUP')" />-->
        </template>

        <template #body>
          <SynTr
            v-for="(workflow, index) in workflows"
            :key="workflow?.id"
            class="cursor-pointer hover:bg-gray-100 hover:text-current-500"
            :class="{ 'bg-white': index % 2 === 0 }"
            @click="onWorkflowClick(workflow)"
          >
            <SynTd padding="p-3" font-size="text-xs">
              {{ workflow?.code }}
            </SynTd>
            <SynTd padding="p-3">
              <router-link
                :to="{
                  path: route.path,
                  query: { ...route.query, workflowId: workflow?.id },
                }"
                class="font-semibold hover:text-current-500"
              >
                {{ workflow?.name }}
              </router-link>
            </SynTd>
            <SynTd padding="p-3" align="text-center">
              {{ workflow?.totalSteps }}
            </SynTd>
            <SynTd padding="p-3" align="text-center">
              <span v-if="!isLoadingTaskCount">
                {{ taskCountByWorkflow[workflow?.id] }}</span
              >
              <span
                v-else
                class="
                  inline-block
                  h-4
                  w-7
                  bg-gray-200
                  rounded-md
                  animate-pulse
                "
              ></span>
            </SynTd>
            <SynTd>
              <div class="flex-center">
                <UserByAction
                  :user-id="workflow?.creatorId"
                  is-hidden-name
                  is-show-action-user
                  avatar-class="w-7 h-7"
                />
              </div>
            </SynTd>
            <SynTd class="text-center">
              <SynLabelDateTime
                format="date"
                :datetime="workflow.creationTime"
              />
            </SynTd>
            <SynTd>
              <WorkflowActions
                :workflow="workflow"
                :action-list="['EDIT']"
                @action="
                  (actionType) => onActionOnWorkflow(actionType, workflow)
                "
              />
            </SynTd>
            <!--            <SynTd padding="p-3">-->
            <!--              <DepartmentById-->
            <!--                v-if="workflow?.departmentId"-->
            <!--                :department-id="workflow?.departmentId"-->
            <!--                :is-name-center="false"-->
            <!--                avatar-class="w-5 h-5"-->
            <!--              />-->
            <!--            </SynTd>-->
            <!--            <SynTd padding="p-3">-->
            <!--              <DomainById-->
            <!--                v-if="workflow?.domainId"-->
            <!--                :domain-id="workflow?.domainId"-->
            <!--                class="border rounded px-2 py-1 text-xs"-->
            <!--              />-->
            <!--            </SynTd>-->
            <!--            <SynTd padding="p-3">-->
            <!--              <GroupById-->
            <!--                v-if="workflow?.groupId"-->
            <!--                :group-id="workflow?.groupId"-->
            <!--              />-->
            <!--            </SynTd>-->
          </SynTr>

          <!--LOADING-->
          <SynTableTrLoading v-if="isLoading">
            <div v-for="item in 6" :key="item" class="table-cell p-2">
              <div class="p-2 w-full bg-gray-200 rounded-md"></div>
            </div>
          </SynTableTrLoading>
        </template>
      </SynTableCustom>

      <div
        v-if="!workflows?.length && !isLoading"
        class="h-full flex-center flex-col bg-white"
      >
        <VigImage
          :path="`${baseImageUrl}/images/workflow.jpg`"
          custom-class="w-96"
        />
        <div class="font-semibold mt-4">
          <span v-if="searchText">{{ $t('WORKFLOW_NO_FOUND') }}</span>
          <span v-else>{{ $t('WORKFLOW_NO_DATA') }}</span>
        </div>
        <div v-if="!searchText && canManageWorkflow" class="text-gray-500">
          {{ $t('WORKFLOW_NO_DATA_DESC') }}
        </div>
        <VigButton
          v-if="canManageWorkflow"
          class="mt-4"
          @click="onCreateWorkflowClick"
        >
          {{ $t('WORKFLOW_LABEl_ADD_NEW') }}
        </VigButton>
      </div>
    </div>
  </div>

  <WorkflowAddNewModal
    v-if="isOpenNewWorkflow"
    @cancel="isOpenNewWorkflow = false"
    @create-success="onCreateWorkflowSuccess"
  />

  <WorkflowDetailModal
    v-if="isOpenPreviewModal && currentWorkflow?.id"
    :workflow-info="currentWorkflow"
    :view-mode="viewDetailMode || 'VIEW'"
    @cancel="isOpenPreviewModal = false"
    @update-success="updateWorkflowSuccess"
  />
</template>

<style scoped></style>
