import { SettingProgressEnum } from '@/ui/common/constants/constant';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { AuthenticateCaseEnum } from '@/application/entities/authenticate/AuthenticateEnum';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import loginPageStore from '@/store/un-authenticate/login-store';
import router from '@/ui/router';
import { clearAllLocalData } from '@/ui/hooks/commonHook';
import { updateDeviceToken } from '@/ui/plugins/firebases/firebaseFcm';
import CatalogService from '@/application/services/CatalogService';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';

export interface ILoginResult {
    jwtToken: string;
    refreshToken: string;
    organizationCode: string;
    organizationId: number;
    organizationSelectRequire: boolean;
    organizationSettingProgress: SettingProgressEnum;
    currentSettingProgress: SettingProgressEnum;
    organizations: any;
    numberOfLogins: number;
    inviteCode?: string;
}

export default class AuthenticateClass {
    constructor() {}

    createNewAccount(data, refId, voucherCode) {
        return UnAuthenticateService.getInstance().saveUserProfile(
            data,
            refId,
            voucherCode,
            {
                utm_source: '',
                utm_medium: '',
                utm_campaign: '',
                fbclid: '',
            }
        );
    }

    async handleSuccess(loginResult: ILoginResult, isSwitchingOrg?) {
        if (!loginResult.jwtToken) return;

        // 0. Clean all previous org's data
        await clearAllLocalData(!!isSwitchingOrg);

        const currentProcess =
            loginResult.organizationSettingProgress ||
            loginResult.currentSettingProgress;

        MyOrganizationSingleton.setInstance(
            loginResult.organizations,
            loginResult.organizationId,
            loginResult.jwtToken,
            loginResult.refreshToken,
            loginResult.numberOfLogins,
            currentProcess
        );

        updateDeviceToken();
        if (loginResult?.inviteCode) {
            const newLoginResult =
                await CatalogService.getInstance().attachOrganizationByCode(
                    {
                        code: loginResult?.inviteCode,
                    },
                    {
                        isHiddenNotification: true,
                    }
                );

            if (newLoginResult?.jwtToken) {
                settingGlobalModal({
                    type: 'notification',
                    title: '',
                    content: translate(
                        newLoginResult?.name
                            ? 'ORGANIZATION_LABEL_JOINED_SUCCESSFULLY_WITH_ORG_NAME'
                            : 'ORGANIZATION_LABEL_JOINED_SUCCESSFULLY',
                        {
                            inviteCode: loginResult?.inviteCode,
                            organizationName: newLoginResult?.name,
                        }
                    ),
                    confirmLabel: translate('COMMON_LABEL_CONTINUE'),
                    confirmable: true,
                });
                await ask();
                this.handleSuccess(newLoginResult);
                return;
            } else if (newLoginResult?.id) {
                settingGlobalModal({
                    type: 'notification',
                    title: '',
                    content: translate(
                        newLoginResult?.name
                            ? 'ORGANIZATION_LABEL_JOINED_SUCCESSFULLY_WITH_ORG_NAME'
                            : 'ORGANIZATION_LABEL_JOINED_SUCCESSFULLY',
                        {
                            inviteCode: loginResult?.inviteCode,
                            organizationName: newLoginResult?.name,
                        }
                    ),
                    confirmLabel: translate('COMMON_LABEL_CONTINUE'),
                    confirmable: true,
                });
                await ask();
                new AuthenticateSelectOrganization(newLoginResult?.id).login();
                return;
            } else if (newLoginResult?.code) {
                const errorLabelCode = `ERROR_EXCEEDING_THE_LIMIT_VISITOR_${newLoginResult?.code}`;
                settingGlobalModal({
                    type: 'notification',
                    title: '',
                    content: translate(errorLabelCode, {
                        organizationName: newLoginResult?.name || '',
                        orgName: newLoginResult?.name || '',
                    }),
                    confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                    confirmable: true,
                });
                await ask();
            }
        }

        // 1. Has multiple organization => Select organization
        if (loginResult.organizationSelectRequire) {
            return {
                caseKey: AuthenticateCaseEnum.SelectOrganizationRequired,
                organizations: loginResult.organizations,
            };
        }
        // 2. Go to current organization
        if (
            currentProcess == SettingProgressEnum.SettingFinished ||
            currentProcess == SettingProgressEnum.SettingOrganization
        ) {
            await loginPageStore().prepareDataBeforeNextRoute();

            this.gotoNextRoute();
            return {
                caseKey: AuthenticateCaseEnum.GoToCurrentOrganization,
            };
        }

        // 3. Has not organization, and go to Home page
        if (
            currentProcess == SettingProgressEnum.NoWorkspace ||
            currentProcess == SettingProgressEnum.SelectInvitation
        ) {
            router.push({
                name: 'Home',
            });
            return {
                caseKey: AuthenticateCaseEnum.GoToHomePage,
            };
        }
    }

    gotoNextRoute() {
        // CuongNL: Redirect to chat support
        const redirectRoute =
            router?.currentRoute?.value?.query?.redirect?.toString();
        const nextRoute =
            redirectRoute?.startsWith('/chat-support/') ||
            redirectRoute?.startsWith('/embed-chats')
                ? redirectRoute
                : `/`;

        router.push(nextRoute);
    }
}
