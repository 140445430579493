import { arrayOrderBy } from './../../../ui/hooks/commonFunction';
import TaskWorkflowStepEntity, {
    ITaskWorkflowStep,
} from '@/domain/entities/task/TaskWorkflowStepEntity';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import TaskService from '@/application/services/task/TaskService';

export interface ITaskWorkflowEntity {
    id: string;
    taskId: number;
    workflowId?: string;
    orgId: number;
    status: string;
    completedTime: null;
    currentStepId: string;
    creatorId: number;
    creationTime: string;
    lastModifierId: number;
    lastModificationTime: string;
    code: string;
    name: string;
    description: null;
    steps: ITaskWorkflowStep[];
    drawData: string;
}

export default class TaskWorkflowEntity implements ITaskWorkflowEntity {
    id: string;
    taskId: number;
    workflowId?: string;
    orgId: number;
    status: string;
    completedTime: null;
    currentStepId: string;
    creatorId: number;
    creationTime: string;
    lastModifierId: number;
    lastModificationTime: string;
    code: string;
    name: string;
    description: null;
    steps: ITaskWorkflowStep[];
    drawData: string;

    constructor(data: any) {
        this.id = data?.id;
        this.taskId = data?.taskId;
        this.workflowId = data?.workflowId;
        this.orgId = data?.orgId;
        this.status = data?.status;
        this.completedTime = data?.completedTime;
        this.currentStepId = data?.currentStepId;
        this.creatorId = data?.creatorId;
        this.creationTime = data?.creationTime;
        this.lastModifierId = data?.lastModifierId;
        this.lastModificationTime = data?.lastModificationTime;
        this.code = data?.code;
        this.name = data?.name;
        this.description = data?.description;
        this.steps = arrayOrderBy(
            data?.steps,
            ['stepOrder'],
            ['asc']
        ) as ITaskWorkflowStep[];
        this.drawData = data?.drawData;
    }

    reopenTaskWorkflowStep(stepId: string) {
        return WorkflowRepository.getInstance().reopenTaskWorkflowStep(
            this.taskId,
            stepId
        );
    }

    finishCurrentStep() {
        return WorkflowRepository.getInstance().completeTaskWorkflowStep(
            this.taskId,
            this.currentStepId
        );
    }

    detach() {
        return WorkflowRepository.getInstance().detachWorkFlowFromTask(this.id);
    }
    async attachToTask(taskId) {
        const attachedWorkFlowSteps = this.steps?.map((step) => {
            return new TaskWorkflowStepEntity(step);
        });

        await Promise.all([
            ...attachedWorkFlowSteps.map((step) => step?.uploadFiles()),
            ...attachedWorkFlowSteps.map((step) => step?.uploadNotes()),
        ]);

        const payload: ITaskWorkflowAttachPayload = {
            workflowId: this.id || '',
            steps: [...attachedWorkFlowSteps],
        };

        const res = await TaskService.getInstance().attachWorkFlowToTask(
            taskId,
            payload
        );
        return res?.result;
    }
    async updateSteps() {
        if (!this.id) return;

        const payload = {
            steps: [...this.steps],
        };

        const res = await TaskService.getInstance().updateTaskWorkflowSteps(
            this.id,
            payload
        );
        this.lastModificationTime = new Date().toString();
        return res?.result;
    }
}

// Generated by https://quicktype.io

export interface ITaskWorkflowAttachPayload {
    workflowId: string;
    steps: ITaskWorkflowStep[];
}
