<script setup lang="ts">
import { ref, computed } from 'vue';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { NotificationSettingFunction } from '@/ui/common/constants/constant';

const props = withDefaults(
  defineProps<{
    scope: string;
    refId: number | string;
    name: string;
    position: string;
    isHiddenChangeFunction?: boolean;
    functionDefault?: 'ALL' | 'TASK' | 'MESSAGING';
  }>(),
  { refId: 0, position: 'list', functionDefault: 'ALL' }
);

const emit = defineEmits(['cancel', 'changeNotification']);
const refName = ref(props?.name);
const actionTextKey = ref('COMMON_LABEL_SAVE_CHANGE');
const _notificationSettingStore = notificationSettingStore();

const dataFunctionByKeys = {
  [NotificationSettingFunction.All]: {
    id: 'op3',
    key: 'NOTIFICATION_FUNC_LABEL_OPTION_3',
    subDes: 'NOTIFICATION_FUNC_SUB_OPTION_3',
    isSelected: true,
    opt: NotificationSettingFunction.All,
  },
  [NotificationSettingFunction.Task]: {
    id: 'op2',
    key: 'NOTIFICATION_FUNC_LABEL_OPTION_2',
    subDes: 'NOTIFICATION_FUNC_SUB_OPTION_2',
    isSelected: false,
    opt: NotificationSettingFunction.Task,
  },
  [NotificationSettingFunction.Messaging]: {
    id: 'op1',
    key: 'NOTIFICATION_FUNC_LABEL_OPTION_1',
    subDes: 'NOTIFICATION_FUNC_SUB_OPTION_1',
    isSelected: false,
    opt: NotificationSettingFunction.Messaging,
  },
};

const dataTimeoffByKeys = {
  60: {
    id: 'op1',
    key: 'NOTIFICATION_LABEL_OPTION_OFF_TIME_1',
    subDes: 'NOTIFICATION_SUB_OPTION_OFF_TIME_1',
    isSelected: true,
    opt: 60,
  },
  240: {
    id: 'op2',
    key: 'NOTIFICATION_LABEL_OPTION_OFF_TIME_2',
    subDes: 'NOTIFICATION_SUB_OPTION_OFF_TIME_2',
    isSelected: false,
    opt: 240,
  },
  1440: {
    id: 'op3',
    key: 'NOTIFICATION_LABEL_OPTION_OFF_TIME_3',
    subDes: 'NOTIFICATION_SUB_OPTION_OFF_TIME_3',
    isSelected: false,
    opt: 1440,
  },
  '-1': {
    id: 'op4',
    key: 'NOTIFICATION_LABEL_OPTION_OFF_TIME_4',
    subDes: 'NOTIFICATION_SUB_OPTION_OFF_TIME_4',
    isSelected: false,
    opt: -1,
  },
};

const subDescriptionFunc = computed(() => {
  if (!settingNotificationPayload.value?.function) return '';
  return dataFunctionByKeys[settingNotificationPayload.value.function].subDes;
});
// const subDescriptionOffTime = computed(() => {
//   if (!settingNotificationPayload.value?.timeoff) return '';
//   return dataFunctionByKeys[settingNotificationPayload.value.timeoff].subDes;
// });

const settingNotificationPayload = ref<{
  function: string;
  timeoff: 60 | 240 | 1440 | -1;
}>({
  function: NotificationSettingFunction.All,
  timeoff: 60,
});

const isLoadingDelete = ref(false);

const onActionSettingNotification = async () => {
  isLoadingDelete.value = true;

  const payload = { ...settingNotificationPayload.value };
  const settingNotificationResult =
    await _notificationSettingStore.setSettingNotification(
      props.refId,
      props.scope,
      payload.function,
      payload.timeoff
    );

  if (settingNotificationResult) {
    emit('changeNotification', {
      type: 'SETTING',
      result: settingNotificationResult,
    });
    const bodyMessage = genarateBodyMessageForActionSettingNotificaiton(
      payload.function
    );
    openNotification({
      title: translate('COMMON_LABEL_SUCCESS'),
      body: bodyMessage,
    });
  } else {
    openNotification({
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body:
        translate('NOTIFICATION_SETTING_ENABLE_ERROR_LABEL') ||
        'Notification settings failed',
    });
  }
  isLoadingDelete.value = false;
  onCancel();
  init();
};

const onCancel = () => {
  settingNotificationPayload.value = {
    function: NotificationSettingFunction.All,
    timeoff: 60,
  };

  emit('cancel', false);
};

const init = async () => {
  actionTextKey.value = 'NOTIFICATION_TITLE_HEADER_OFF_NOTIFICATION';
  console.log('🚀 Hyrin ~ init ~ props.functionDefault', props.functionDefault);
  if (props.functionDefault) {
    settingNotificationPayload.value.function = props.functionDefault;
  }
};

const genarateBodyMessageForActionSettingNotificaiton = (func) => {
  let bodyMessage = '';
  switch (func) {
    case NotificationSettingFunction.Messaging:
      bodyMessage = translate(
        'NOTIFICATION_SETTING_SUCCESS_LABEL_GROUP_OPT_MESSAGE',
        { group: refName.value }
      );

      break;
    case NotificationSettingFunction.Task:
      bodyMessage = translate(
        'NOTIFICATION_SETTING_SUCCESS_LABEL_GROUP_OPT_TASK',
        {
          group: refName.value,
        }
      );

      break;

    default:
      bodyMessage =
        translate('NOTIFICATION_SETTING_SUCCESS_LABEL_GROUP_OTP_ALL', {
          group: refName.value,
        }) || 'Turn off the notification of the group';
      break;
  }

  return bodyMessage;
};

init();
</script>

<template>
  <SynQuestionModal
    z-index="z-50"
    class="z-50"
    visible
    :show-confirm-btn="true"
    :show-delete-btn="false"
    :header-text="
      $t('NOTIFICATION_TITLE_HEADER_OFF_NOTIFICATION') + ' - ' + name
    "
    :confilm-label="$t(actionTextKey)"
    :is-loading="isLoadingDelete"
    @on-delete="onActionSettingNotification"
    @cancel="onCancel"
  >
    <template #content>
      <!-- SETTING FOR FUNCTION TASK OR MESSAGE OR ALL -->
      <section v-if="!isHiddenChangeFunction" class="flex flex-col">
        <div class="px-4 pt-4 font-medium flex space-x-2 items-center">
          <SynIcon
            name="filter"
            custom-class="w-5 h-5 fill-current text-current-500"
          />
          <span>{{ $t('NOTIFICATION_SETTING_FOR_FUNCTION_LABEL') }}</span>
        </div>
        <div class="flex space-y-3 py-4 ml-8 flex-wrap">
          <SynRadioTag
            v-for="item in Object.values(dataFunctionByKeys)"
            :key="item.id"
            v-model="settingNotificationPayload.function"
            :value="item.opt"
            radio-name="modal-notification-setting_function"
            style="margin: 0.9px"
          >
            <SynTag
              size="circle-small"
              custom-class="p-1 border border-current-100"
              style="min-width: 5rem"
            >
              <span class="text-sm">
                {{ $t(item.key) }}
              </span>
            </SynTag>
          </SynRadioTag>
        </div>
      </section>
      <div
        v-if="subDescriptionFunc"
        class="pb-6 ml-8 text-sm italic text-current-700"
      >
        <span class="font-bold">{{ $t('COMMON_LABEL_NOTE') + ': ' }}</span>
        <span>{{ $t(subDescriptionFunc) }}</span>
      </div>

      <!-- TURN OFF TIME -->
      <section class="flex flex-col">
        <div class="px-4 pt-4 font-medium flex space-x-2 items-center">
          <SynIcon
            name="timer"
            is-active
            custom-class="w-5 h-5 fill-current text-current-500"
          />
          <span>{{ $t('NOTIFICATION_SETTING_OFF_TIME_LABEL') }}</span>
        </div>
        <div class="flex flex-col py-4 ml-8 flex-wrap">
          <SynRadioTag
            v-for="item in Object.values(dataTimeoffByKeys)"
            :key="item.id"
            v-model="settingNotificationPayload.timeoff"
            :value="item.opt"
            radio-name="modal-notification-setting_timeoff"
            style="margin: 0.9px"
          >
            <span class="text-sm px-4">
              {{ $t(item.key) }}
            </span>
          </SynRadioTag>
        </div>
      </section>
      <!-- <div
          v-if="subDescriptionOffTime"
          class="px-4 pt-4 text-sm italic text-current-700"
        >
          <span class="font-bold">{{ $t('COMMON_LABEL_NOTE') + ': ' }}</span>
          <span>{{ $t(subDescriptionOffTime) }}</span>
        </div> -->
    </template>
  </SynQuestionModal>
</template>
