<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M256 512C114.613 512 0 397.387 0 256S114.613 0 256 0s256 114.613 256 256c-.168 141.316-114.684 255.832-256 256zm0-480C132.29 32 32 132.29 32 256s100.29 224 224 224 224-100.29 224-224c-.133-123.656-100.344-223.867-224-224zm0 0"
      ></path>
      <path
        d="M256 288c-46.32-2.363-82.047-41.668-80-88-2.047-46.332 33.68-85.637 80-88 46.32 2.363 82.047 41.668 80 88 2.047 46.332-33.68 85.637-80 88zm0-144c-28.617 2.406-50 27.352-48 56-2 28.648 19.383 53.594 48 56 28.617-2.406 50-27.352 48-56 2-28.648-19.383-53.594-48-56zm0 0"
      ></path>
      <path
        d="M160 368c-8.836 0-16-7.164-16-16 0-52.945 50.238-96 112-96 8.836 0 16 7.164 16 16s-7.164 16-16 16c-44.113 0-80 28.703-80 64 0 8.836-7.164 16-16 16zM304 400a16.002 16.002 0 0 1-11.313-4.688l-32-32c-6.062-6.277-5.976-16.257.196-22.43 6.172-6.171 16.152-6.257 22.43-.195L304 361.375l84.688-84.688c6.277-6.062 16.257-5.976 22.43.196 6.171 6.172 6.257 16.152.195 22.43l-96 96c-3 3-7.07 4.687-11.313 4.687zm0 0"
      ></path>
    </g>
  </svg>
</template>
