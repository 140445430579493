<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import tippy from 'tippy.js';

const {
  delay: propsDelay = [0, 0],
  theme: propsTheme = 'light',
  isHiddenArrow = false,
} = defineProps<{
  delay?: number | number[];
  theme?: string;
  isHiddenArrow?: boolean;
}>();
const dropdownToggle: any = ref(null);
const dropdownMenu: any = ref(null);

onMounted(() => {
  if (!dropdownToggle.value || !dropdownMenu.value) return;

  tippy(dropdownToggle.value, {
    appendTo: () => document.body,
    theme: propsTheme,
    content: dropdownMenu.value,
    allowHTML: true,
    trigger: 'mouseenter focus',
    interactive: true,
    animation: 'scale',
    placement: 'top',
    arrow: !isHiddenArrow,
    delay: propsDelay,
  });
});

onBeforeUnmount(() => {
  if (dropdownToggle.value?._tippy) {
    dropdownToggle.value?._tippy.hide();
    dropdownToggle.value?._tippy.destroy();
  }
});
</script>

<template>
  <div>
    <div ref="dropdownToggle">
      <slot name="trigger"></slot>
    </div>
    <div ref="dropdownMenu" className="text-base">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<style>
.tippy-box[data-theme~='rounded'] {
  border-radius: 9999px;
  background: #fff;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
}

.tippy-box[data-theme~='rounded'] .tippy-arrow {
  color: #fff;
}
</style>
