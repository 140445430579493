import { VecLike } from '../common/Vec2d';
import { ArrowInfo } from './arrow-types';

export function getSolidStraightArrowPath(
    info: ArrowInfo & { isStraight: true }
) {
    return getArrowPath(info.start.point, info.end.point);
}

function getArrowPath(start: VecLike, end: VecLike) {
    return `M${start.x},${start.y}L${end.x},${end.y}`;
}
