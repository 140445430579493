<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import FileIcon from '../../_commons/file-icon/FileIcon.vue';
import myProfileStore from '@/store/auth/my-profile';
import groupStore from '@/store/group';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ButtonChangeFileLocation from '../../_commons/button-change-file-location/ButtonChangeFileLocation.vue';
import UploadDropZone from '../upload-drop-zone/UploadDropZone.vue';
import FileRename from '../../_commons/file-rename/FileRename.vue';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';

const props = defineProps<{
  files: any[];
  fileFilters: any;
  selectedFolder: any;
}>();

const emit = defineEmits(['onClose', 'onUpload']);

const _groupStore = groupStore();
const _myProfileStore = myProfileStore();

const myProfile = computed(() => _myProfileStore.myProfile);
const allMyDomains = computed(() => _myProfileStore.myDomains);
const allMyGroups = computed(() => _groupStore.allMyGroups);

const uploadLocation = ref(
  props.fileFilters?.groupId || props.fileFilters?.domainId || null
);

const uploadLocationName = computed(() => {
  if (props.fileFilters?.groupId) {
    const groupInfo = allMyGroups.value?.find(
      (group) => group?.id === props.fileFilters?.groupId
    );
    return groupInfo?.name;
  }

  if (props.fileFilters?.domainId) {
    const groupInfo = allMyDomains.value?.find(
      (d) => d?.id === props.fileFilters?.domainId
    );
    return groupInfo?.name;
  }

  return translate('GED_MY_STORAGE');

  // if (!uploadLocation?.value) return translate('GED_MY_STORAGE');
  //
  // const groupInfo = allMyGroups.value?.find(
  //   (group) => group?.id === uploadLocation.value
  // );
  // return groupInfo?.name;
});

const folderLocationCanChange = computed(
  () =>
    false && // Resources by domain
    !props.fileFilters?.groupId &&
    !props.fileFilters?.domainId &&
    !props.fileFilters?.category &&
    props.fileFilters?.userId !== myProfile?.value.id &&
    !props.selectedFolder
);

const removedFiles = ref({});
const uploadFiles = ref([] as any);
const selectedFiles = computed(() =>
  (uploadFiles.value || []).filter((file, index) => !removedFiles.value[index])
);

const isDraggingFilesUpload = ref();
const renamingFileIndex = ref(-1 as number);

onMounted(() => {
  uploadFiles.value = props.files || [];
});

const onFileRemoveClick = (index) => {
  removedFiles.value[index] = true;
};

const onFileUndoClick = (index) => {
  removedFiles.value[index] = false;
};

const onFilesSelected = (event) => {
  const moreFiles = Array.from(event.target.files);

  uploadFiles.value = [...uploadFiles.value, ...moreFiles];

  event.target.value = null;
};

const onClickRenameFile = (index: number) => {
  renamingFileIndex.value = index;
};

const onFilesDragging = (isDragging) => {
  isDraggingFilesUpload.value = isDragging;
};

const onFilesDrop = (event) => {
  const droppedFiles = Array.from(event.dataTransfer.files).filter(
    (file: any) => file && file.type
  );

  uploadFiles.value = [...uploadFiles.value, ...droppedFiles];
};

const onFilesPaste = (event) => {
  if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
    return;
  }

  const items = (event.clipboardData || event.originalEvent.clipboardData)
    .items;

  // const pastedFiles = Array.from(items)
  //   .filter((item) => item && item.type)
  //   .map((item) => item.getAsFile());
  const pastedFiles = Array.from(items)
    .filter((item: any) => item && item.type)
    .map((item: any) => {
      const file = item.getAsFile();
      if (file.name === 'image.png' && file.type === 'image/png') {
        const fileData = new File([file], 'image.jpeg', {
          type: 'image/jpeg',
        });
        return fileData;
      } else {
        return file;
      }
    });

  uploadFiles.value = [...uploadFiles.value, ...pastedFiles];
};

const onRenameConfirm = (fileName) => {
  if (renamingFileIndex.value < 0) return;

  const file = uploadFiles.value[renamingFileIndex.value];

  uploadFiles.value[renamingFileIndex.value] = new File([file], fileName, {
    type: file?.type,
  });

  renamingFileIndex.value = -1;
};

const onUploadClick = () => {
  emit('onUpload', {
    files: selectedFiles,
    location: {
      folderId: props.fileFilters?.folderId,
      groupId: props.fileFilters?.groupId,
      domainId: props.fileFilters?.domainId,
      // groupId: uploadLocation.value,
    },
  });
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    container-style="height: 80vh"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="flex items-center justify-between" @paste="onFilesPaste">
        {{ $t('GED_UPLOAD_FILES') || 'Upload files' }}
        <label
          class="
            px-2
            py-1
            border border-current-100
            hover:ring
            hover:ring-current
            hover:ring-opacity-30
            hover:bg-current-50
            hover:border-current-500
            text-current-600 text-base
            flex-center
            space-x-2
            font-normal
            cursor-pointer
            rounded
          "
        >
          <SynIcon name="FileUpload" class="fill-current" />
          <span>{{ $t('GED_UPLOAD_MORE') || 'Add more' }}</span>
          <input
            type="file"
            class="hidden"
            multiple
            @change="onFilesSelected"
          />
        </label>
      </div>
    </template>

    <template #body>
      <div class="h-full flex flex-col" @paste="onFilesPaste">
        <div class="py-2 px-3 flex bg-gray-50 shadow">
          <div class="text-gray-500 mr-3">{{ $t('GED_FOLDER_IN') }}</div>
          <div class="font-semibold">
            {{ uploadLocationName }}
            <span
              v-for="parentFolder in selectedFolder?.folders"
              :key="parentFolder"
              >/ {{ parentFolder?.name }}&nbsp;</span
            >
            <span v-if="selectedFolder">/ {{ selectedFolder?.name }}</span>
          </div>
          <ButtonChangeFileLocation
            v-if="folderLocationCanChange"
            class="ml-3"
            :group-id="uploadLocation"
            @on-location-click="uploadLocation = $event"
          />
        </div>
        <div
          v-drop-zone="{ isDragging: onFilesDragging, onDrop: onFilesDrop }"
          class="flex-1 overflow-auto small-scrollbar relative"
        >
          <div
            v-for="(file, index) in uploadFiles"
            :key="file"
            class="
              px-4
              py-2.5
              border-b border-gray-100
              flex
              items-center
              space-x-2
            "
          >
            <div
              class="flex-1 flex items-center space-x-3"
              :class="removedFiles[index] ? 'line-through opacity-50' : ''"
            >
              <FileIcon :file-type="file?.type" class-name="w-6 h-6" />

              <div class="flex-1">
                <span
                  v-if="renamingFileIndex !== index || removedFiles[index]"
                  class="leading-4"
                >
                  {{ file?.name }}
                </span>
                <FileRename
                  v-else
                  :file="file"
                  @on-cancel="renamingFileIndex = -1"
                  @on-rename="onRenameConfirm"
                />
              </div>
            </div>

            <VigButton
              v-if="!removedFiles[index]"
              ghost
              color="gray"
              padding="p-1"
              rounded="rounded"
              @click="onClickRenameFile(index)"
            >
              <SynIcon name="Edit" is-active />
            </VigButton>
            <VigButton
              v-if="!removedFiles[index]"
              ghost
              color="red"
              padding="p-1"
              rounded="rounded"
              @click="onFileRemoveClick(index)"
            >
              <SynIcon name="Trash" class="fill-red-500" is-active />
            </VigButton>
            <VigButton
              v-else
              ghost
              color="gray"
              padding="p-2"
              rounded="rounded-full"
              @click="onFileUndoClick(index)"
            >
              <SynIcon name="Undo" class="fill-gray-500" />
            </VigButton>
          </div>

          <UploadDropZone
            v-if="isDraggingFilesUpload"
            class="absolute top-0 left-0 right-0 bottom-0"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex items-center px-3 py-2" @paste="onFilesPaste">
        <div class="flex-1 font-medium">
          {{
            $t('GED_NUM_SELECTED', { number: selectedFiles.length }) ||
            `${selectedFiles.length} selected`
          }}
        </div>
        <div class="flex-center space-x-2">
          <SynButton
            :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
            type-text
            color="gray"
            @click="$emit('onClose')"
          />
          <button
            :disabled="!selectedFiles.length"
            class="
              px-4
              py-2
              rounded
              font-semibold
              text-white
              bg-current
              hover:bg-current-400
              disabled:opacity-50
            "
            @click="onUploadClick"
          >
            {{ $t('COMMON_LABEL_UPLOAD') || 'Upload' }}
          </button>
        </div>
      </div>
    </template>
  </SynModal>
</template>
