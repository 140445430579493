export const FILE = {
    TYPE: {
        PDF: 'application/pdf',
        IMAGE: 'image/',
        AUDIO: 'audio/',
        VIDEO: 'video/',
        TEXT: 'text/plain',
        DOC: [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
        EXCEL: [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
        PPTX: [
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ],
        ZIP: [
            'application/zip',
            'application/x-zip-compressed',
            'application/octet-stream',
        ],
        FOLDER: 'application/vnd.tictop-apps.folder',
        LINK: 'application/vnd.tictop-apps.link',
        MSG: '',
        GOOGLE_DOC: 'application/vnd.google-apps.document',
        GOOGLE_SHEET: 'application/vnd.google-apps.spreadsheet',
        GOOGLE_PRESENTATION: 'application/vnd.google-apps.presentation',
    },
    SOURCE_FROM: {
        STORAGE: 'STORAGE',
        TASK: 'TASK',
        CHAT: 'CHAT',
        AVATAR: 'AVATAR',
    },
    CATEGORY: {
        STORAGE: 'STORAGE',
        TASK: 'TASK',
        CHAT: 'CHAT',
    },
    SYSTEM_TYPE: {
        SHARED_FOLDER: 'SHARED_FOLDER',
        GOOGLE_DRIVE_FOLDER: 'GOOGLE_DRIVE_FOLDER',
    },
};

export const IMAGE = {
    SIZE: {
        MINI: 'mini',
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
        XLARGE: 'xlarge',
        ORIGINAL: 'original',
    },
};
export const MESSAGE = {
    TYPE: {
        MEMBER_REMOVED: 'MEMBER_REMOVED',
        MEMBER_JOINED_ORG: 'MEMBER_JOINED_ORG',
    },
};
export const CALL = {
    TYPE: {
        AUDIO: 'audio',
        VIDEO: 'video',
    },
    PARTICIPANT_STATUS: {
        STARTED: 'started',
        RINGING: 'ringing',
        ON_CALL: 'on_call',
        MISSED: 'missed',
        HANGUP: 'hangup',
    },
    CALL_STATUS: {
        STARTED: 'started',
        ON_CALL: 'on_call',
        FAILED_TO_START: 'failed_to_start',
        HANGUP: 'hangup',
    },
};
export const UPLOAD = {
    STATUS: {
        PENDING: 1,
        UPLOADING: 2,
        UPLOAD_SUCCESS: 3,
        UPLOAD_FAIL: -1,
    },
};
export const REGEX = {
    LINK: /^(?:http(s)?:\/\/)?(?:localhost|[\w.-]+\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+$/,
};
export default {
    FILE: FILE,
    IMAGE: IMAGE,
    MESSAGE: MESSAGE,
    CALL: CALL,
    UPLOAD: UPLOAD,
    REGEX: REGEX,
};
