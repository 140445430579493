import {
    GenerateGroupPayload,
    GroupDeletePayload,
} from '@/domain/entities/PayloadClass/GroupPayloadClass';
import UserService from '@/application/services/UserService';
import GroupRepository, {
    ICreateOrUpdateGroupPayload,
} from '@/application/repositories/GroupRepository';
import { NotificationTypeEnum, openNotification } from '@/ui/hooks/commonHook';
import { GroupType } from '@/application/enums/GroupEnums';

export default class GroupService {
    private static instance: GroupService;

    _groupRepository: GroupRepository;
    _userService: UserService;

    constructor() {
        this._groupRepository = GroupRepository.getInstance();
        this._userService = UserService.getInstance();
    }

    public static getInstance(): GroupService {
        if (!GroupService.instance) {
            // Get from local storage
            GroupService.instance = new GroupService();
        }
        return GroupService.instance;
    }

    getDetail(groupId: number): Promise<any> {
        return this._groupRepository.getDetail(groupId);
    }

    getSameGroupOfUsers(userId: number): Promise<any> {
        return this._groupRepository.getSameGroupOfUsers(userId);
    }

    async createAndUpdate(payload: ICreateOrUpdateGroupPayload): Promise<any> {
        const res = await this._groupRepository.createAndUpdate(payload);
        const newGroup = res.result;
        if (!payload?.id && newGroup?.type !== GroupType.CHAT)
            openNotification({
                metaData: {
                    groupId: newGroup?.id,
                    groupName: newGroup?.name,
                    groupAvatar: newGroup?.avatar,
                },
                notificationType: NotificationTypeEnum.group_new,
            });
        return res;
    }

    getAllGroup() {
        return this._groupRepository.getAllGroup();
    }
    getDeletedGroupByUser() {
        return this._groupRepository.getDeletedGroupByUser();
    }

    addUserToGroup(data: []): Promise<any> {
        return this._groupRepository.addUserToGroup(data);
    }

    addOrUpdateGroupToUser(data: []): Promise<any> {
        return this._groupRepository.addOrUpdateGroupToUser(data);
    }

    changeGroupType(data: []): Promise<any> {
        return this._groupRepository.changeGroupType(data);
    }

    getAllGroupByUser(userId: number): Promise<any> {
        return this._groupRepository.getAllGroupByUser(userId);
    }

    getGroupChatByUser(userId: number): Promise<any> {
        return this._groupRepository.getGroupChatByUser(userId);
    }

    getGroupListOverview(): Promise<any> {
        return this._groupRepository.getGroupListOverview();
    }

    getOverviewGroupInfos(orderBy): Promise<any> {
        return this._groupRepository.getOverviewGroupInfos(orderBy);
    }

    getOverviewGroupTasks(): Promise<any> {
        return this._groupRepository.getOverviewGroupTasks();
    }

    getOrgGroupsByUser(orgId: number, userId: number): Promise<any> {
        return this._groupRepository.getOrgGroupsByUser(orgId, userId);
    }

    getOrgAllGroupsByUser(orgId: number, userId: number): Promise<any> {
        return this._groupRepository.getOrgAllGroupsByUser(orgId, userId);
    }

    getOrgGroupsChatByUser(orgId: number, userId: number): Promise<any> {
        return this._groupRepository.getOrgGroupsChatByUser(orgId, userId);
    }

    deleteGroup(payload: GroupDeletePayload): Promise<any> {
        return this._groupRepository.deleteGroup(payload);
    }
    async getDomainListById(userId: number): Promise<any> {
        return await this._userService.getDomainListById(userId);
    }

    getAllDomainOfUser(): any {
        return this._groupRepository.getAllDomainOfUser();
    }

    showLessPublicGroup(): Promise<any> {
        return this._groupRepository.showLessPublicGroup();
    }

    async uploadAvatar(file: File): Promise<any> {
        return await this._userService.uploadAvatar(file);
    }

    getPublicGroupSetting(): Promise<any> {
        return this._groupRepository.getPublicGroupSetting();
    }

    async uploadAvatarGroup(groupId: number, avatar: string): Promise<any> {
        return this._groupRepository.uploadAvatarGroup(groupId, avatar);
    }
    updateDomains(groupId: number, domainIds: any): Promise<any> {
        return this._groupRepository.updateDomains(groupId, domainIds);
    }
    breakLinkDefaultDepartmentGroup(groupId: number): Promise<any> {
        return this._groupRepository.breakLinkDefaultDepartmentGroup(groupId);
    }
    addDefaultDepartmentGroup(payload: GenerateGroupPayload): Promise<any> {
        return this._groupRepository.addDefaultDepartmentGroup(payload);
    }
}
