<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m21 0h-18c-1.654 0-3 1.346-3 3v18c0 1.654 1.346 3 3 3h18c1.654 0 3-1.346 3-3v-18c0-1.654-1.346-3-3-3zm1 21c0 .552-.448 1-1 1h-12v-5.5c0-.828-.672-1.5-1.5-1.5h-5.5v-12c0-.552.448-1 1-1h18c.552 0 1 .448 1 1z"
      ></path>
      <path
        d="m19.707 4.293c-.391-.391-1.023-.391-1.414 0l-4.293 4.293-2.293-2.293c-.286-.286-.716-.372-1.09-.217-.374.154-.617.52-.617.924v6c0 .553.447 1 1 1h6c.404 0 .77-.243.924-.617.155-.374.069-.804-.217-1.09l-2.293-2.293 4.293-4.293c.391-.391.391-1.023 0-1.414z"
      ></path>
    </g>
  </svg>
</template>
