<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import DayoffDetail from '@/ui/components/dayoff/DayoffDetail.vue';
const route = useRoute();

const id = computed(() => route.params?.id);
</script>

<template>
  <div class="w-screen h-screen flex flex-center bg-gray-100 p-4 rounded-lg">
    <div class="rounded shadow-2xl bg-white">
      <dayoff-detail :id="id" :is-screen="false" />
    </div>
  </div>
</template>
