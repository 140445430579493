<script setup lang="ts">
import { computed } from 'vue';
import { ETaskListModule } from '@/application/types/task/task.types';
import taskDrawerStore from '@/store/task/drawer';
import TaskTimeBlockingSingle from '@/ui/pages/tasks/calendar/TaskTimeBlockingSingle.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import myProfileStore from '@/store/auth/my-profile';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
  onCreateGlobalSuccess,
} from '@/ui/modules/task/task-global-state';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import planTaskCalendarByOwnerComposables from '@/ui/pages/tasks/calendar/plan-task-calendar-by-owner.composables';
import ActionById from '@/ui/components/user/ActionById.vue';

const props = defineProps<{
  userInfo: any;
  isHasTimeColumn?: boolean;
}>();
const _taskDrawerStore = taskDrawerStore();

const {
  containerElmRef,
  planTodayTasks,
  taskTimeBlockingSingleRef,
  isLoadingChart,
  changeStartEndTime,
} = planTaskCalendarByOwnerComposables({
  sourceType: ETaskListModule.USER,
  listOwnerId: props.userInfo?.id,
});

const openTaskDetail = (task) => {
  console.log('🚀 Tictop ~ task:', task);
  _taskDrawerStore.pushCurrentIds({ ...task, tabType: 'DETAIL' });
};

const myTimeZone = computed<any>(() => {
  return myProfileStore().timezone;
});

const onClickAddButton = (dateKey) => {
  newTaskDefaultPayload.value = _generateNewTaskPayload(dateKey);
  onCreateGlobalSuccess.value = () => {};
  isOpenCreateTaskDrawer.value = true;
};

const _generateNewTaskPayload = (scheduleTime) => {
  return {
    assigneeId: parseInt(props.userInfo?.id?.toString()) || getCurrentUserId(),
    scheduleTime,
  };
};
</script>
<template>
  <div
    ref="containerElmRef"
    class="shrink-0 h-auto relative"
    :style="{
      width: isHasTimeColumn ? '424px' : '364px',
      display: 'inline-table',
    }"
  >
    <div
      v-if="isLoadingChart"
      class="w-full h-full z-100 absolute top-0 left-0 cursor-wait"
    ></div>
    <div
      class="
        sticky
        top-0
        z-10
        w-full
        bg-white
        shadow-[rgba(0,0,0,0.1)_0px_4px_2px_-2px]
        group/card
      "
    >
      <div class="w full h-full flex">
        <div class="flex-1 flex-center border-r-2 border-gray-50">
          <div class="h-16 w-16 flex-center">
            <UserByAction
              :user-id="userInfo?.id"
              is-hidden-name
              is-show-user-notification
              avatar-class="w-12 h-12 text-xs font-medium"
            />
            <UserById
              :user-id="userInfo?.id"
              is-hidden-name
              avatar-class="w-12 h-12 text-xs font-medium"
            />
          </div>
          <div class="flex-1 flex flex-col justify-between px-2">
            <div class="flex-1 flex items-center justify-between">
              <div class="h-7 font-semibold">{{ userInfo?.name }}</div>
              <div class="flex-center">
                <span
                  v-if="planTodayTasks?.length"
                  class="
                    text-gray-500 text-sm
                    lowercase
                    group-hover/card:hidden
                  "
                >
                  {{ planTodayTasks?.length }} {{ $t('LABEL_TOTAL_TASK') }}
                </span>

                <div
                  class="
                    items-center
                    justify-center
                    gap-2
                    hidden
                    group-hover/card:flex
                  "
                >
                  <ActionById
                    v-if="userInfo?.id"
                    :contact-id="userInfo?.id"
                    :types="['list-task', 'chat']"
                    task-link-target="_blank"
                    :task-page-query="{
                      viewBy: 'CALENDAR',
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="h-6 flex items-center justify-between">
              <template v-if="userInfo?.workInfo?.startWD">
                <div class="flex-center gap-2">
                  <div
                    v-if="userInfo?.workInfo?.startWD"
                    :title="
                      $t('OVERVIEW_LABEL_START_TO_WORK') || 'Start to work'
                    "
                    class="flex-center gap-1"
                  >
                    <div
                      class="w-3 h-3 bg-current-500 flex-center rounded-full"
                    >
                      <SynIcon
                        name="start"
                        custom-class="w-1.5 h-1.5 fill-white text-white"
                      />
                    </div>
                    <div class="text-xs text-gray-500">
                      <SynLabelDateTime
                        format="time"
                        :datetime="userInfo?.workInfo?.startWD"
                      />
                    </div>
                  </div>

                  <div
                    v-if="userInfo?.workInfo?.stopWD"
                    :title="$t('OVERVIEW_LABEL_STOP_TO_WORK') || 'Stop to work'"
                    class="flex-center gap-1"
                  >
                    <div class="w-3 h-3 bg-red-400 flex-center rounded-full">
                      <SynIcon
                        name="stop-video"
                        custom-class="w-1 h-1 fill-white text-white"
                      />
                    </div>
                    <div class="text-xs text-gray-500">
                      <SynLabelDateTime
                        format="time"
                        :datetime="userInfo?.workInfo?.stopWD"
                      />
                    </div>
                  </div>
                  <!-- DURATION -->
                  <div
                    v-if="userInfo?.workInfo?.startWD"
                    :title="$t('OVERVIEW_LABEL_DURATION') || 'Duration'"
                    class="flex-center gap-1"
                  >
                    <div class="w-3 h-3 bg-blue-500 flex-center rounded-full">
                      <SynIcon
                        name="duration"
                        custom-class="w-2 h-2 fill-white text-white"
                      />
                    </div>
                    <div class="text-xs text-gray-500">
                      <span class="text-current-600 font-semibold">
                        {{
                          $filters.durationTime(
                            userInfo?.workInfo?.actualDuration * 60 * 60 * 1000,
                            'hh mm'
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <span v-else class="text-xs text-gray-500">
                {{ $t('OVERVIEW_LABEL_HAVE_NOT_START') || 'Have not started' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TaskTimeBlockingSingle
      ref="taskTimeBlockingSingleRef"
      v-model:is-loading="isLoadingChart"
      current-view-mode="timeGridDay"
      :source-type="ETaskListModule.USER"
      :events="planTodayTasks"
      :time-zone="myTimeZone"
      is-multiple
      :external-id="userInfo?.id"
      :is-hidden-time-column="!isHasTimeColumn"
      @on-click-add-button="onClickAddButton"
      @change-start-end-time="changeStartEndTime"
      @event-click="openTaskDetail"
    />
  </div>
</template>
