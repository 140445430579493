<script setup lang="ts">
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  document: any;
  isFullscreen: boolean;
}>();

defineEmits(['update:isFullscreen']);

const onCopyClick = () => {
  if (!props.document?.link) return;

  copyToClipBroard(props.document?.link);

  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('GED_LABEL_COPY_URL_SUCCESS'),
    duration: 3000,
  });
};

const onOpenNewTabClick = () => {
  if (!props.document?.link) return;

  window.open(props.document?.link, '_blank');
};
</script>

<template>
  <div class="flex items-center gap-2">
    <VigButton
      v-vig-tooltip="$t('GED_GOOGLE_COPY_LINK')"
      outline
      rounded="rounded-full"
      icon="Link"
      @click="onCopyClick"
    />
    <VigButton
      v-vig-tooltip="$t('GED_GOOGLE_OPEN_IN_BROWSER')"
      outline
      rounded="rounded-full"
      icon="Redirect"
      @click="onOpenNewTabClick"
    />
    <VigButton
      v-vig-tooltip="
        $t(
          isFullscreen
            ? 'GED_GOOGLE_FULLSCREEN_EXIT'
            : 'GED_GOOGLE_FULLSCREEN_VIEW'
        )
      "
      outline
      rounded="rounded-full"
      :icon="isFullscreen ? 'FullscreenExit' : 'Fullscreen'"
      @click="$emit('update:isFullscreen', !isFullscreen)"
    />
  </div>
</template>
