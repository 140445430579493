import {
    updateDataFirebase,
    getOrganizationPathFirebase,
} from '@/ui/plugins/firebases/firebaseRealtimeDatabase';

import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

export const getConferencePath = (callId): string => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    if (!organizationId) return '';
    const companyId = `organization_${organizationId}`;
    return `organizations_dynamic_info/${companyId}/audioVideoCall/${callId}`;
};

export const getUserConferencePath = (userId, videoId): string => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    if (!organizationId || !userId) return '';
    const companyId = `organization_${organizationId}`;
    const userPath = `user_${userId}`;
    return `users_dynamic_info/${companyId}/${userPath}/videoCalls/${videoId}`;
};

export const getUserStatusPath = (userId) => {
    const organizationFirebasePath = getOrganizationPathFirebase('userStatus');
    return `${organizationFirebasePath}/${userId}`;
};

export const updateAudioVideoCallData = (key, data) => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    if (!organizationId) return;
    const companyId = `organization_${organizationId}`;
    updateDataFirebase(
        `organizations_dynamic_info/${companyId}/audioVideoCall/${key}`,
        data
    );
};
export const updateFirebaseVideoCallForUser = (userId, videoId, data) => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    if (!userId || !organizationId) return;
    const companyId = `organization_${organizationId}`;
    const userPath = `user_${userId}`;
    updateDataFirebase(
        `users_dynamic_info/${companyId}/${userPath}/videoCalls/${videoId}`,
        data
    );
};

// const calculateVideoCallWindow = () => {
//   const w = 900;
//   const h = 500;
//   // Fixes dual-screen position Most browsers Firefox
//   const dualScreenLeft =
//     window.screenLeft !== undefined ? window.screenLeft : window.screenX;
//   const dualScreenTop =
//     window.screenTop !== undefined ? window.screenTop : window.screenY;

//   const width = window.innerWidth
//     ? window.innerWidth
//     : document.documentElement.clientWidth
//     ? document.documentElement.clientWidth
//     : screen.width;
//   const height = window.innerHeight
//     ? window.innerHeight
//     : document.documentElement.clientHeight
//     ? document.documentElement.clientHeight
//     : screen.height;

//   const systemZoom = width / window.screen.availWidth;
//   const left = (width - w) / 2 / systemZoom + dualScreenLeft;
//   const top = (height - h) / 2 / systemZoom + dualScreenTop;
//   return `
//     scrollbars=yes,
//     width=${w / systemZoom},
//     height=${h / systemZoom},
//     top=${top},
//     left=${left}
//     `;
// };
