<script setup lang="ts">
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import { computed } from 'vue';
import AtomSwitch from '@/ui/common/atoms/switchs/AtomSwitch.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const emits = defineEmits(['onCancel']);

const _taskTabViewStore = taskTabViewStore();
const listTabs = computed(() => _taskTabViewStore.listTabs);

const onCancel = () => {
  emits('onCancel');
};
</script>

<template>
  <SynModal
    container-class="w-1/3 h-4/5"
    style-body="py-4 flex flex-col"
    @cancel="onCancel"
  >
    <template #header>
      <span>{{ 'Management Tabs' }}</span>
    </template>
    <template #body>
      <div
        class="
          mx-4
          p-2.5
          m-2
          rounded
          border border-dashed border-current-300
          flex
          items-center
          cursor-pointer
          fill-gray-500
          text-gray-500
          hover:fill-current-500 hover:text-current-500
        "
      >
        <div class="flex items-center space-x-2">
          <SynIcon name="plus" custom-class="w-3 h-3" />
          <span class="text-sm">{{
            $t('TASK_LIST_CREATE_NEW_TAB_LABEL')
          }}</span>
        </div>
      </div>

      <div class="flex flex-col overflow-y-auto small-scrollbar px-4">
        <SortAble @on-change-drag="onChangeDrag">
          <template #list-item>
            <template v-for="tab in listTabs" :key="tab">
              <div
                class="
                  p-2.5
                  m-2
                  rounded
                  border border-gray-100
                  flex
                  items-center
                  justify-between
                  hover-to-show__parent
                "
              >
                <div class="flex items-center space-x-2">
                  <span
                    class="w-2 h-2 rounded"
                    :style="`background-color: ${tab?.color}`"
                  ></span>
                  <span class="truncate text-sm">{{ tab?.name }}</span>
                </div>
                <div class="flex-center space-x-2">
                  <div
                    class="
                      hover-to-show__children
                      flex-center
                      w-5
                      h-5
                      rounded
                      hover:bg-gray-200
                      fill-gray-400
                      text-gray-400
                      cursor-pointer
                    "
                  >
                    <SynIcon name="edit" is-active custom-class="w-3.5 h-3.5" />
                  </div>
                  <AtomSwitch
                    size="small"
                    :model-value="tab?.visible"
                    @change="tab.visible = !tab?.visible"
                  />
                </div>
              </div>
            </template>
          </template>
        </SortAble>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end px-4 pb-2 pt-4 space-x-2">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="onCancel()"
        />
        <SynButton
          :is-loading="isLoading"
          :label="$t('COMMON_LABEL_CONFIRM')"
          @click="onSave"
        />
      </div>
    </template>
  </SynModal>
</template>
