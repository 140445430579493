import { translate } from '@/ui/plugins/i18n/myi18n';
import { DayoffExceptionTypeUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';

// export const DATA_DAY_OFF_BY_TYPE = {
//     SCHEDULE: {
//         type: 'SCHEDULE',
//         label: 'DAYOFF_TYPE_SCHEDULE',
//         subData: {
//             HALF_DAY: {
//                 value: 1,
//                 label: translate('COMMOM_LABEL_DAY', { day: 1 }),
//                 color: '#076260',
//             },
//             FULL_DAY: {
//                 value: 2,
//                 label: translate('COMMOM_LABEL_DAY', { day: 0.5 }),
//                 color: '#4bb0b2',
//             },
//         },
//     },
//     WFH: {
//         type: 'WFH',
//         label: 'DAYOFF_TYPE_WFH',
//         subData: {
//             HALF_DAY: {
//                 value: 3,
//                 label: translate('COMMOM_LABEL_DAY', { day: 1 }),
//                 color: '#3b82f6',
//             },
//             FULL_DAY: {
//                 value: 4,
//                 label: translate('COMMOM_LABEL_DAY', { day: 0.5 }),
//                 color: '#93c5fd',
//             },
//         },
//     },
//     EXCEPTION: {
//         type: 'EXCEPTION',
//         label: 'DAYOFF_TYPE_EXCEPTION',
//         subData: {
//             STOP_EARLY: {
//                 value: 5,
//                 label: translate(DayoffExceptionTypeUI['STOP_EARLY']?.textCode),
//                 color: '#facc15',
//             },
//             START_LATE: {
//                 value: 6,
//                 label: translate(DayoffExceptionTypeUI['START_LATE']?.textCode),
//                 color: '#ea580c',
//             },
//             BREAK_TIME: {
//                 value: 7,
//                 label: translate(DayoffExceptionTypeUI['BREAK_TIME']?.textCode),
//                 color: '#b91c1c',
//             },
//         },
//     },
// };
export const DATA_DAY_OFF_BY_TYPE = {
    SCHEDULE_FULL_DAY: {
        type: 'SCHEDULE',
        label: 'DAYOFF_TYPE_SCHEDULE',
        value: 1,
        dayLabel: translate('COMMOM_LABEL_DAY', { day: 1 }),
        color: '#076260',
    },
    SCHEDULE_HALF_DAY: {
        type: 'SCHEDULE',
        label: 'DAYOFF_TYPE_SCHEDULE',
        value: 2,
        dayLabel: translate('COMMOM_LABEL_DAY', { day: 0.5 }),
        color: '#4bb0b2',
    },

    WFH_FULL_DAY: {
        type: 'WFH',
        label: 'DAYOFF_TYPE_WFH',
        dayLabel: translate('COMMOM_LABEL_DAY', { day: 1 }),
        value: 3,
        color: '#3b82f6',
    },
    WFH_HALF_DAY: {
        type: 'WFH',
        label: 'DAYOFF_TYPE_WFH',
        dayLabel: translate('COMMOM_LABEL_DAY', { day: 0.5 }),
        value: 4,
        color: '#93c5fd',
    },
    EXCEPTION_STOP_EARLY: {
        type: 'EXCEPTION',
        label: 'DAYOFF_TYPE_EXCEPTION',
        dayLabel: translate(DayoffExceptionTypeUI['STOP_EARLY']?.textCode),
        value: 5,
        color: '#facc15',
    },
    EXCEPTION_START_LATE: {
        type: 'EXCEPTION',
        label: 'DAYOFF_TYPE_EXCEPTION',
        dayLabel: translate(DayoffExceptionTypeUI['START_LATE']?.textCode),
        value: 6,
        color: '#ea580c',
    },
    EXCEPTION_BREAK_TIME: {
        type: 'EXCEPTION',
        label: 'DAYOFF_TYPE_EXCEPTION',
        dayLabel: translate(DayoffExceptionTypeUI['BREAK_TIME']?.textCode),
        value: 7,
        color: '#b91c1c',
    },
};
