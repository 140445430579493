<script lang="ts" setup>
import SharedLayout from '@/ui/components/layouts/SharedLayout.vue';
import { onBeforeMount } from 'vue';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { detectLanguageFromBrowserClient } from '@/ui/app-global-state';

onBeforeMount(() => {
  const location = getLocalStorage(StorageConstant.LOCATION);
  detectLanguageFromBrowserClient(location);
});
</script>

<template>
  <SharedLayout>
    <router-view />
  </SharedLayout>
</template>
