<script setup lang="ts">
import { ref, onUnmounted, toRaw, nextTick, onMounted } from 'vue';
import axios from 'axios';
import 'itl-drawer';

import PerfectScrollbar from '@/ui/plugins/scrollbar/perfect-scrollbar';

import {
  settingGlobalModal,
  ask,
  tell,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import ticketPlugin from '@/ticket/services/plugin-services';
import TaskService from '@/application/services/task/TaskService';
import $constants from '@/ui/plugins/constants';

const props = defineProps<{
  drawData?: any;
  noteId?: number | string;
  isLoading?: boolean;
  files?: any[];
  originFile?: string;
  readonly?: boolean;
  isHiddenClose?: boolean;
  pastedImage?: any;
  isRemoveAble?: any;
}>();

const emit = defineEmits(['cancel', 'onSave', 'onCollapse', 'onRemove']);
let ps;
const drawer = ref(null as any);
const isRecording = ref(false);
const audios = ref([] as any[]);
const fileList = ref<any[]>(props.files || []);
const isLoadingDrawer = ref(true);
const hasChanged = ref(false);
const setupDrawerWidget = async (drawDataObj) => {
  isLoadingDrawer.value = true;
  await nextTick();
  isLoadingDrawer.value = false;
  const language = localStorage.getItem('LANGUAGE');

  if (drawer.value) {
    drawer.value.addEventListener('imageAddClick', imageAddClick);
    drawer.value.addEventListener('audioAddClick', audioAddClick);
    drawer.value.addEventListener('audioDbClick', onDbClickAudio);
    drawer.value.setDrawerOptions({
      viewOnly: props.readonly,
      language: language,
    });
    drawer.value.addEventListener('hasChange', (event) => {
      hasChanged.value = event.detail?.hasChanged;
      setTimeout(() => {
        ps?.onScroll();
      }, 100);
    });
    if (drawDataObj) {
      setTimeout(() => {
        drawer.value.loadObjects(cloneDeep(drawDataObj));
      });
    }
    if (props.originFile) {
      const binary = await getFileFromAwsS3(props.originFile);
      drawer.value.pasteImage(binary);
    }
    if (props.pastedImage) {
      drawer.value.pasteImage(props.pastedImage);
    }
  }
};

const init = async () => {
  let drawDataObj =
    props.drawData && typeof props.drawData == 'string'
      ? { ...JSON.parse(props.drawData) }
      : { ...props.drawData };
  if (props.noteId && props.noteId !== '00000000-0000-0000-0000-000000000000') {
    isLoadingDrawer.value = true;
    const resDraw = await TaskService.getInstance().getDrawDataById(
      props.noteId
    );

    const result = resDraw['result'];
    const drawDataString = result?.drawData;
    drawDataObj = JSON.parse(drawDataString);
    isLoadingDrawer.value = false;
  }
  setupDrawerWidget({ ...drawDataObj, background: '#ffffff' });
};

onMounted(async () => {
  await init();
  try {
    const perfectScrollbarContainer =
      document.querySelectorAll('div.canvas-wrapper')[0];

    if (!perfectScrollbarContainer?.classList) return;

    perfectScrollbarContainer?.classList.add('relative');

    ps = new PerfectScrollbar(perfectScrollbarContainer);
  } catch (error) {
    console.log('🚀 ~ onMounted ~ error', error);
  }

  window.addEventListener('resize', () => {
    ps?.update();
  });
});

onUnmounted(() => {
  audios.value = [];
  drawer.value = null;
});
const onSave = () => {
  const base64 = drawer.value.getAsImageData();
  const drawData = drawer.value.getAsObjects();
  if (drawData?.objects?.length > 0) {
    emit('onSave', {
      base64,
      drawData,
      audios: toRaw(audios.value),
    });
  } else {
    emit('cancel');
  }
};
const onCancel = async () => {
  if (props.readonly) {
    emit('cancel');
    return;
  }
  if (hasChanged.value) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMMON_LABEL_UNSAVED_CHANGES') || 'Unsaved changes',
      content:
        translate('COMMON_LABEL_DO_YOU_WANT_TO_DISCARD_CHANGES') ||
        'Do you want to discard edits or continue editing?',
      cancelLabel: translate('COMMON_LABEL_DISCARD') || 'Discard',
      confirmLabel:
        translate('COMMON_LABEL_CONTINUE_EDITING') || 'Continue editing',
      confirmable: true,
      closeable: true,
      footerButtons: [
        {
          type: 'TEXT',
          labelCode: 'COMMON_LABEL_DISCARD',
          color: 'gray',
          onClick: () => {
            tell(true);
          },
        },
        {
          type: 'FILL',
          labelCode: 'COMMON_LABEL_CONTINUE_EDITING',
          color: 'current',
          onClick: () => {
            tell(false);
          },
        },
      ],
    });
    const confirmed = await ask();
    if (!confirmed) return;
  }

  emit('cancel');
};

const imageAddClick = () => {
  const imageInput = document.getElementById('draw-widget-attachment');

  imageInput?.click();
};

const getFileFromAwsS3 = async (file) => {
  // const params = {
  //   Bucket: bucketTictop.AttachmentPhoto,
  //   Key: key,
  // };
  // const objectAwsS3 = await getObjectFromAwsS3(params);
  // const newBlob = new Blob([objectAwsS3.Body], {
  //   type: objectAwsS3.ContentType,
  // });
  const fileUrl = file.fileUrl || file.path;
  const res = await axios.get(fileUrl, {
    responseType: 'blob',
  });
  return new File([res.data], file?.fileName || 'fileName.jpeg', {
    type: 'image/jpeg',
  });
};

const onSelectImage = async (file: any) => {
  let binary;
  if (file?.fileBlob) {
    binary = file.fileBlob;
  } else if (file?.fileUrl) {
    binary = await getFileFromAwsS3(file);
  } else {
    binary = file;
  }
  drawer.value.pasteImage(binary);
};
const audioAddClick = () => {
  isRecording.value = true;
};
const onCreateAudioDone = (recordings) => {
  if (recordings.length > 0) {
    recordings.forEach((record) => {
      const id = record.src;
      drawer.value.addAudio(id);
      audios.value = [
        ...audios.value,
        {
          ...record,
          noteAudioId: id,
          fileUrl: id,
          file: record,
          isRecorder: true,
          type: record?.type,
        },
      ];
    });
  }
  isRecording.value = false;
};
const onCreateAudioCancel = () => {
  isRecording.value = false;
};
const audioUrl = ref(null);
const onDbClickAudio = (event) => {
  audioUrl.value = event.detail?.audioUrl;
};
// const onCloseAudio = () => {
//   audioUrl.value = null;
// };
const onChooseFiles = (event) => {
  const files: File[] = Array.from(event.target?.files);

  const imageFiles = files.filter((file: File) =>
    file?.type?.startsWith($constants.FILE.TYPE.IMAGE)
  );

  imageFiles.forEach((file) => {
    fileList.value.push(file);

    onSelectImage(file);
  });
};

const onEmitHidden = () => {
  ticketPlugin.collapseNote();
};

defineExpose({
  onCancel,
});
</script>

<template>
  <div
    class="h-full w-full flex flex-col relative"
    style="
      max-height: 1920px;
      max-width: 1920px;
      min-height: 500px;
      min-width: 600px;
      width: 100%;
      height: 100%;
    "
  >
    <div class="flex-1 min-h-0 flex flex-col">
      <div
        v-if="isLoadingDrawer"
        class="absolute top-0 z-50 flex-center w-full h-full"
      >
        <SynLoadingSection />
      </div>
      <div class="flex-1 min-h-0 overflow-hidden relative">
        <itl-drawer ref="drawer"></itl-drawer>
      </div>

      <div
        class="flex items-center p-2 bg-gray-200"
        :class="isRemoveAble ? 'justify-between' : 'justify-end'"
      >
        <SynButton
          v-if="isRemoveAble"
          class="h-8"
          type-text
          color="red"
          @click="$emit('onRemove')"
        >
          <div class="flex-center space-x-2">
            <SynIcon name="trash" />
            <span class="text-sm">
              {{ $t('TASK_DETAIL_REMOVE') }}
            </span>
          </div>
        </SynButton>
        <div class="flex items-center justify-end space-x-4">
          <SynButton
            v-if="isHiddenClose"
            class="h-8 bg-white"
            type-text
            color="gray"
            @click="onEmitHidden"
          >
            <span class="text-sm">
              {{ $t('COMMON_LABEL_HIDDEN') }}
            </span>
          </SynButton>
          <syn-button
            class="h-8 bg-white"
            type-text
            color="gray"
            @click="onCancel"
          >
            <span class="text-sm">
              {{ $t('COMMON_LABEL_CANCEL') }}
            </span>
          </syn-button>
          <syn-button
            v-if="!readonly"
            :is-loading="isLoading"
            :label="$t('COMMON_LABEL_SAVE')"
            class="w-32 h-8"
            @click="onSave"
          />

          <div
            v-if="isHiddenClose"
            class="absolute top-2 right-2 flex-center space-x-2"
          >
            <SynIcon
              name="minus"
              has-action
              color="gray"
              @click="$emit('onCollapse')"
            />
            <SynIcon name="close" has-action color="gray" @click="onCancel" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <recorder-modal
    v-if="isRecording"
    @on-done="onCreateAudioDone"
    @on-cancel="onCreateAudioCancel"
  />

  <input
    id="draw-widget-attachment"
    type="file"
    multiple
    class="hidden opacity-0"
    accept=".jpg,.png,.gif,.jpeg"
    @change="onChooseFiles"
  />
</template>
<style>
itl-drawer svg {
  display: unset;
}

.panel-toolbar {
  padding-right: 120px;
}
</style>
