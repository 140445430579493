<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted } from 'vue';
import * as d3 from 'd3';
import CalHeatmap from 'cal-heatmap';
// import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
// import Legend from 'cal-heatmap/plugins/Legend';
import { getDynamicPosition } from '@/ui/hooks/commonFunction';
// import dayoffStatisticStore from '@/store/dayoff/dayoff-statistic';
// import {
//   DayoffDateSession,
//   DayoffType,
//   DayoffExceptionType,
// } from '@/domain/enums/DayoffEnums';
import StatisticOfDay from '@/ui/components/calendars/statistics-card/StatisticOfDay.vue';
// import { translate } from '@/ui/plugins/i18n/myi18n';
// import localeVi from '@/ui/modules/calendar/calendar-statistic/locale-vi.js';
import 'cal-heatmap/cal-heatmap.css';
import { DATA_DAY_OFF_BY_TYPE } from '@/application/constants/dayoff/dayoff.const';
import {
  DayoffDateSession,
  DayoffExceptionType,
  DayoffType,
} from '@/domain/enums/DayoffEnums';
import { translate } from '@/ui/plugins/i18n/myi18n';

const calHeatmapInstance = new CalHeatmap();

const props = defineProps({
  userId: {
    type: [String, Number],
    default: '',
  },
  dayoffUsed: {
    type: Array,
    default: () => [],
  },
  statisticDayoff: {
    type: Array,
    default: () => [],
  },
  totalDayoff: {
    type: Number,
    default: 0,
  },
});

// const emit = defineEmits(['onPreviousCalendar', 'onNextCalendar']);

// watch(
//   () => props.dayoffUsed,
//   () => {
//     refCalHeatMap.value.update(dataStatisticDayoffByDate?.value);
//   }
// );

// const _dayoffStatisticStore = dayoffStatisticStore();

// const refCalHeatMap = ref<CalHeatMap>(null);
// const totalDayoffInYear = 12;
const currentDateChoose = ref({});
const isShowTooltip = ref(false);
const isFocusInCard = ref(false);
const currentYear = ref(new Date().getFullYear());
const cardPosition = ref({ x: 0, y: 0 });
const cardDayoffSize = {
  width: 300,
  height: 75,
};

// const parserDataType = computed(() => _dayoffStatisticStore.parserDataType);
const dataStatisticDayoffByDate = computed(() => {
  return (props.dayoffUsed || [])?.map((o) => {
    let code = 'SCHEDULE_HALF_DAY';

    if (o?.type != DayoffType.EXCEPTION) {
      code = `${o?.type}_${
        o?.daySession === DayoffDateSession.ALL_DAY ? 'FULL_DAY' : 'HALF_DAY'
      }`;
    }
    if (o?.type === DayoffType.EXCEPTION && o?.exception) {
      if (o?.exception?.type == DayoffExceptionType.STOP_EARLY)
        code = `EXCEPTION_STOP_EARLY`;
      if (o?.exception?.type == DayoffExceptionType.START_LATE)
        code = `EXCEPTION_START_LATE`;
      if (o?.exception?.type == DayoffExceptionType.BREAK_TIME)
        code = `EXCEPTION_BREAK_TIME`;
    }
    return {
      ...o,
      ...DATA_DAY_OFF_BY_TYPE[code],
    };
  });
});

const statisticDayoffByTimestamp = computed<any>(() => {
  if (!props.dayoffUsed?.length) return {};

  return props.dayoffUsed?.reduce((currentResult: any, currentItem: any) => {
    const key = currentItem?.date;

    return {
      ...currentResult,
      [key]: {
        date: key,
        data: [...(currentResult[key]?.data || []), currentItem],
      },
    };
  }, {});
});

onMounted(() => {
  init();
  // handleHoverEvent();
  mapLocale();
});

// const monthMapVi = {
//   Jan: 'LABEL_JANUARY',
//   Feb: 'LABEL_FEBRUARY',
//   Mar: 'LABEL_MARCH',
//   Apr: 'LABEL_APRIL',
//   May: 'LABEL_MAY',
//   Jun: 'LABEL_JUNE',
//   Jul: 'LABEL_JULY',
//   Aug: 'LABEL_AUGUST',
//   Sep: 'LABEL_SEPTEMBER',
//   Oct: 'LABEL_OCTOBER',
//   Nov: 'LABEL_NOVEMBER',
//   Dec: 'LABEL_DECEMBER',
// };

const mapLocale = () => {
  // const cal = document.querySelectorAll('#cal-heatmap .graph-label');
  // cal?.forEach((e) => {
  //   const month = e?.innerHTML;
  //   if (month && Object.keys(monthMapVi).includes(month)) {
  //     e.innerHTML = translate(monthMapVi[month]);
  //   }
  // });
};

// const handleHoverEvent = () => {
//   let rect = document.querySelectorAll(
//     '#cal-heatmap .cal-heatmap-container .graph-rect'
//   );
//   rect?.forEach((el) => {
//     el.addEventListener('click', () => {

//     });
//   });
// };

const onClickOutSideCard = () => {
  isShowTooltip.value = false;
  isFocusInCard.value = false;
};

const onClickDate = (event, timestamp) => {
  // let datetime = new Date(date).getTime() / 1000;
  currentDateChoose.value = statisticDayoffByTimestamp.value[timestamp];
  console.log('🚀 Tictop ~ currentDateChoose.value:', currentDateChoose.value);

  setTimeout(() => {
    const targetPosition = event?.target?.getBoundingClientRect();

    cardPosition.value = getDynamicPosition({
      x: targetPosition.left,
      y: targetPosition.top,
      width: cardDayoffSize.width,
      height: cardDayoffSize.height,
      padding: 10,
    });
    console.log('🚀 Tictop ~ cardPosition.value:', cardPosition.value);
    isShowTooltip.value = true;
  }, 100);
};

const init = () => {
  // if (refCalHeatMap.value) refCalHeatMap.value.destroy();

  calHeatmapInstance.on('click', (event, timestamp) => {
    onClickDate(event, timestamp);
  });

  calHeatmapInstance.paint(
    {
      itemSelector: '#cal-heatmap_graph-statistic-by-user',
      data: {
        source: dataStatisticDayoffByDate.value,
        type: 'json',
        x: 'date',
        y: 'value',
        groupY: 'min',
      },
      range: 12,
      date: {
        start: new Date(currentYear.value, 1, 1),
        // min: new Date(currentYear.value, 1, 1),
        // max: new Date(currentYear.value + 1, 5, 1),
      },

      domain: {
        type: 'month',
        padding: [10, 10, 10, 10],
        label: {
          text: (timestamp: number) => {
            console.log('🚀 Tictop ~ timestamp:', timestamp);
            const monthNumber = new Date(timestamp).getMonth();
            switch (monthNumber) {
              case 0:
                return translate('LABEL_JANUARY');
              case 1:
                return translate('LABEL_FEBRUARY');
              case 2:
                return translate('LABEL_MARCH');
              case 3:
                return translate('LABEL_APRIL');
              case 4:
                return translate('LABEL_MAY');
              case 5:
                return translate('LABEL_JUNE');
              case 6:
                return translate('LABEL_JULY');
              case 7:
                return translate('LABEL_AUGUST');
              case 8:
                return translate('LABEL_SEPTEMBER');
              case 9:
                return translate('LABEL_OCTOBER');
              case 10:
                return translate('LABEL_NOVEMBER');
              case 11:
                return translate('LABEL_DECEMBER');

              default:
                return translate('LABEL_JANUARY');
            }

            return '';
          },
          position: 'top',
        },
      },
      subDomain: {
        type: 'xDay',
        radius: 2,
        width: 15,
        height: 15,
        label: 'D',
        color: (timestamp, value, backgroundColor) => {
          if (value) return d3.hcl(backgroundColor).l > 60 ? 'black' : 'white';

          return '#cbd5e1';
        },
      },
      scale: {
        color: {
          type: 'ordinal',
          domain: Object.values(DATA_DAY_OFF_BY_TYPE)
            ?.sort(function (a, b) {
              return a?.value - b?.value;
            })
            ?.map((o) => o?.value),
          range: Object.values(DATA_DAY_OFF_BY_TYPE)
            ?.sort(function (a, b) {
              return a?.value - b?.value;
            })
            ?.map((o) => o?.color),
        },
      },
    },
    []
  );

  // calHeatmapInstance.fill(dataJson);

  // refCalHeatMap.value = new CalHeatMap();
  // console.log('🚀 Tictop ~ refCalHeatMap.value:', refCalHeatMap.value);
  // if (!refCalHeatMap.value) return;

  // refCalHeatMap.value.paint({
  //   // displayLegend: false,
  //   // legend: [1, 2, 3, 4, 5, 6, 7],
  //   // afterLoadData: parserData,
  //   // subDomainTitleFormat: {
  //   //   empty: '{date}',
  //   //   filled: '{date}',
  //   // },
  //   // legendHorizontalPosition: 'right',

  // });
};

onUnmounted(() => {
  // refCalHeatMap.value.destroy();
});

// const onPreviousCalendar = async () => {
//   currentYear.value--;
//   emit('onPreviousCalendar', {
//     type: 'year',
//     value: currentYear.value,
//   });
//   isLoading.value = true;
//   await init();
//   handleHoverEvent();
//   isLoading.value = false;
//   // refCalHeatMap.value.previous(11);
// };

// const onNextCalendar = async () => {
//   currentYear.value++;
//   emit('onNextCalendar', {
//     type: 'year',
//     value: currentYear.value,
//   });
//   isLoading.value = true;
//   await init();
//   handleHoverEvent();
//   isLoading.value = false;
//   // refCalHeatMap.value.next(11);
// };
const distance = 650;
const onScrollDistance = (moveDistance) => {
  // calHeatmapInstance.next(1);
  // document.getElementById('cal-heatmap_graph-statistic-by-user')?.scrollBy({
  //   left: distance,
  //   behavior: 'smooth',
  // });

  var container = document.getElementById(
    'cal-heatmap_graph-statistic-by-user'
  );
  if (!container) return;

  let scrollAmount = 0;
  const slideTimer = setInterval(function () {
    container.scrollLeft += moveDistance;
    scrollAmount += Math.abs(moveDistance);
    if (
      container.scrollLeft == 0 ||
      container.scrollLeft >= container.scrollWidth ||
      scrollAmount >= distance
    ) {
      window.clearInterval(slideTimer);
    }
  }, 5);
};
</script>

<template>
  <div class="flex flex-col w-full">
    <slot name="infomation-statistic"></slot>
    <!-- CALENDAR HEATMAP -->
    <div class="relative flex-center p-5 rounded">
      <!-- <div
        id="cal-heatmap-yaxislabels"
        class="text-2xs flex items-start flex-col text-gray-400"
      >
        <div class="graph-label">
          <pre>{{ $t('COMMON_LABEL_MON') }}</pre>
        </div>
        <div class="graph-label text-white">
          <pre>{{ $t('COMMON_LABEL_TUE') }}</pre>
        </div>
        <div class="graph-label">
          <pre>{{ $t('COMMON_LABEL_WED') }}</pre>
        </div>
        <div class="graph-label text-white">
          <pre>{{ $t('COMMON_LABEL_THU') }}</pre>
        </div>
        <div class="graph-label">
          <pre>{{ $t('COMMON_LABEL_FRI') }}</pre>
        </div>
        <div class="graph-label text-white">
          <pre>{{ $t('COMMON_LABEL_SAT') }}</pre>
        </div>
        <div class="graph-label">
          <pre>{{ $t('COMMON_LABEL_SUN') }}</pre>
        </div>
      </div> -->
      <VigButton
        light
        color="gray"
        class="rounded-full"
        icon="ChevronLeft"
        @click="onScrollDistance(-10)"
      />

      <div
        id="cal-heatmap_graph-statistic-by-user"
        class="overflow-hidden"
      ></div>
      <VigButton
        light
        color="gray"
        class="rounded-full"
        icon="ChevronRight"
        @click="onScrollDistance(10)"
      />
    </div>
  </div>

  <teleport to="body">
    <div
      v-if="isShowTooltip && currentDateChoose"
      v-click-outside="onClickOutSideCard"
      class="
        absolute
        top-0
        left-0
        z-100
        bg-white
        rounded
        shadow-md
        border border-gray-100
      "
      :style="[
        `top: ${cardPosition.y}px`,
        `left: ${cardPosition.x}px`,
        'height: max-content',
        'width: 350px',
      ]"
    >
      <StatisticOfDay
        :datas="currentDateChoose?.data"
        :date="currentDateChoose?.date"
      />
    </div>
  </teleport>
</template>

<style>
#cal-heatmap-yaxislabels {
  float: left;
  margin-right: 10px;
  margin-bottom: 25px;
  padding-left: 15px;
}

#cal-heatmap-yaxislabels > * {
  margin-bottom: 1px;
  display: block;
}
</style>
