import { EObjectStore, ETransactionMode } from '@/indexedDB';
import IdbBase from '../base/idb-base';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import { ICommonIdb } from './common-idb.types';

const CURRENT_OBJECT_STORE_NAME = EObjectStore.common;

class CommonIdbEntity extends IdbBase {
    constructor() {
        super(CURRENT_OBJECT_STORE_NAME);
    }

    async saveCommonInformation(data: ICommonIdb) {
        const dataByKeys = { ...cloneDeep(data) };

        const { transaction, store } = await this._getTransactionByMode(
            ETransactionMode.readwrite
        );

        Object.keys(dataByKeys).forEach((key) => {
            store.put(dataByKeys[key], key);
        });

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve('save local config completed!');
            };

            transaction.onerror = () => {
                reject("can't save local completed!");
            };
        });
    }
    async saveDataByKey(key, value) {
        const { transaction, store } = await this._getTransactionByMode(
            ETransactionMode.readwrite
        );

        store.put(cloneDeep(value), key);

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve('save local config completed!');
            };

            transaction.onerror = () => {
                reject("can't save local completed!");
            };
        });
    }
}

export default new CommonIdbEntity();
