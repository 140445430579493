import {
    DayoffReason,
    DayoffStatus,
    DayoffType,
} from '@/domain/enums/DayoffEnums';
import DateoffDetailClass from './DateoffDetailClass';
import DateoffExceptionClass from './DateoffExceptionClass';
import DateoffFlowClass from './DateoffFlowClass';

export default class DayoffDetailClass {
    id: string; // keyword
    organizationId: number;
    userId: number; // user request
    year: number;
    month: number;
    startDate: number;
    endDate: number;

    startDateSession: number;
    endDateSession: number;

    requestDate: number;
    requestDay: number;
    usedDay: number;

    type: DayoffType; // SCHEDULE | EXCEPTION | WFH - keyword
    status: DayoffStatus; // ACCEPTED | REJECTED | CANCELLED | MARK_UNUSED | CLOSED | RE_OPENED - keyword
    previousStatus: DayoffStatus; // ACCEPTED | REFUSED | CANCELLED | MARK_UNUSED | CLOSED | RE_OPENED - keyword

    requestReason: DayoffReason; // keyword
    requestNote: string;

    rejectReason: DayoffReason;
    rejectNote: string;

    // date_offs: object;
    dateOffs: DateoffDetailClass[];
    exception: DateoffExceptionClass;
    requestFlow: DateoffFlowClass[];
    isDeleted: boolean;
    createdDate: number;
    createdBy: number;
    updatedDate: number;
    updatedBy: number;
    constructor(data) {
        this.id = data?.id;
        this.organizationId = data?.organizationId;
        this.userId = data?.userId;
        this.year = data?.year;
        this.month = data?.month;
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.startDateSession = data?.startDateSession;
        this.endDateSession = data?.endDateSession;
        this.requestDate = data?.requestDate;
        this.requestDay = data?.requestDay;
        this.usedDay = data?.usedDay;
        this.type = data?.type;
        this.status = data?.status;
        this.previousStatus = data?.previousStatus;
        this.requestReason = data?.requestReason;
        this.requestNote = data?.requestNote;
        this.rejectReason = data?.rejectReason;
        this.rejectNote = data?.rejectNote;
        this.dateOffs = data?.dateOffs;
        this.exception = data?.exception;
        this.requestFlow = data?.requestFlow;
        this.isDeleted = data?.isDeleted;
        this.createdDate = data?.createdDate;
        this.createdBy = data?.createdBy;
        this.updatedDate = data?.updatedDate;
        this.updatedBy = data?.updatedBy;
    }
}
