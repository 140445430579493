import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import datetimeComposable from '@/ui/composables/common/datetime-composable';
import { translate } from '@/ui/plugins/i18n/myi18n';

enum ESuggestDateRange {
    THIS_WEEK = 'THIS_WEEK',
    LAST_WEEK = 'LAST_WEEK',
    TWO_WEEKS_AGO = 'TWO_WEEKS_AGO',
    THIS_MONTH = 'THIS_MONTH',
    LAST_MONTH = 'LAST_MONTH',
    LAST_3_MONTHS = 'LAST_3_MONTHS',
}
export default function atomDateRange() {
    const currentChoice = ref<any>();
    const currentDateRange = ref<any>({
        fromDate: Date.now(),
        toDate: Date.now(),
    });
    const SUGGEST_RANGE_LIST_BY_KEY = {
        [ESuggestDateRange.THIS_WEEK]: {
            key: ESuggestDateRange.THIS_WEEK,
            title: 'This week',
            titleCode: 'SUGGEST_DATE_RANGE_LABEL_THIS_WEEK_MONDAY_TODAY',
            getData: () => {
                return {
                    start: dayjs().utc().day(1).startOf('day').format(),
                    end: dayjs().utc().startOf('day').format(),
                };
            },
        },
        [ESuggestDateRange.LAST_WEEK]: {
            key: ESuggestDateRange.LAST_WEEK,
            title: 'Last week',
            titleCode: 'SUGGEST_DATE_RANGE_LABEL_LAST_WEEK_MONDAY_SATURDAY',
            getData: () => {
                return {
                    start: dayjs().utc().day(-6).startOf('day').format(),
                    end: dayjs().utc().day(-1).startOf('day').format(),
                };
            },
        },
        [ESuggestDateRange.TWO_WEEKS_AGO]: {
            key: ESuggestDateRange.TWO_WEEKS_AGO,
            title: '2 weeks ago',
            titleCode: 'SUGGEST_DATE_RANGE_LABEL_2_WEEKS_AGO',
            getData: () => {
                return {
                    start: dayjs().utc().day(-13).startOf('day').format(),
                    end: dayjs().utc().day(-1).startOf('day').format(),
                };
            },
        },
        [ESuggestDateRange.THIS_MONTH]: {
            key: ESuggestDateRange.THIS_MONTH,
            title: 'This month',
            titleCode: 'SUGGEST_DATE_RANGE_LABEL_THIS_MONTH',
            getData: () => {
                return {
                    start: dayjs().utc().date(1).startOf('day').format(),
                    end: dayjs().utc().startOf('day').format(),
                };
            },
        },
        [ESuggestDateRange.LAST_MONTH]: {
            key: ESuggestDateRange.LAST_MONTH,
            title: 'Last month',
            titleCode: 'SUGGEST_DATE_RANGE_LABEL_LAST_MONTH',
            getData: () => {
                const lastMonth = dayjs().utc().subtract(1, 'month'); // Create a Day.js object for last month

                return {
                    start: lastMonth.startOf('month').format(),
                    end: lastMonth.endOf('month').format(),
                };
            },
        },
        [ESuggestDateRange.LAST_3_MONTHS]: {
            key: ESuggestDateRange.LAST_3_MONTHS,
            title: 'Last 3 months',
            titleCode: 'SUGGEST_DATE_RANGE_LABEL_LAST_3_MONTHS',
            getData: () => {
                const last3Month = dayjs().utc().subtract(3, 'month'); // Create a Day.js object for last month
                const lastMonth = dayjs().utc().subtract(1, 'month'); // Create a Day.js object for last month

                return {
                    start: last3Month.startOf('month').format(),
                    end: lastMonth.endOf('month').format(),
                };
            },
        },
    };

    const suggestRangeList = computed<any[]>(() => {
        return Object.values(SUGGEST_RANGE_LIST_BY_KEY);
    });

    const updateCurrentChoice = ({ fromDate, toDate }) => {
        currentDateRange.value = {
            fromDate,
            toDate,
        };
        const currentChoiceObject = suggestRangeList.value?.find((o) => {
            const data = o.getData();

            return (
                dayjs(data.start).isSame(fromDate, 'd') &&
                dayjs(data.end).isSame(toDate, 'd')
            );
        });

        currentChoice.value = currentChoiceObject?.key || 'CUSTOM';
    };
    const { formatDate } = datetimeComposable();

    const dataRangeString = computed<string>(() => {
        return currentChoice.value == 'CUSTOM'
            ? formatDate(currentDateRange.value?.fromDate, 'date', {
                  isIgnoreTimezone: true,
              }) +
                  ' - ' +
                  formatDate(currentDateRange.value?.toDate, 'date', {
                      isIgnoreTimezone: true,
                  })
            : translate(
                  SUGGEST_RANGE_LIST_BY_KEY[currentChoice.value]?.titleCode
              ) || SUGGEST_RANGE_LIST_BY_KEY[currentChoice.value]?.title;
    });
    return {
        suggestRangeList,
        currentChoice,
        SUGGEST_RANGE_LIST_BY_KEY,
        updateCurrentChoice,

        dataRangeString,
    };
}
