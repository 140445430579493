import { checkPermissionByModel } from '@/ui/hooks/permission/permission-by-function';
import { EFunctionPermission } from '@/application/types/block-function.types';

export default {
    install: (app) => {
        app.directive('permission-model', {
            mounted(element, binding) {
                if (!element || binding.value?.disabled) return;

                const permission = checkPermissionByModel();

                switch (permission) {
                    case EFunctionPermission.HIDDEN:
                        {
                            element?.parentNode?.removeChild(element);
                        }
                        break;

                    default:
                        break;
                }
            },
        });
    },
};
