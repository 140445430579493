<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import appStore from '@/store/app';
import groupStore from '@/store/group';
import userStore from '@/store/user';
import taskStore from '@/store/task';
import dashboardStore from '@/store/dashboard';
import domainStore from '@/store/scope';
import RouterInstanceClass from '@/ui/router/RouterInstanceClass';
import myProfileStore from '@/store/auth/my-profile';
import plansStore from '@/store/plans';
import permissionStore from '@/store/permission';
import taskListStore from '@/store/task/task-list-store';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import organizationStore from '@/store/organization';
import ToogleCollapseMenu from '@/ui/components/general/ToogleCollapseMenu.vue';
import PersonalSidebar from '@/ui/components/layouts/sidebar/PersonalSidebar.vue';
import settingStore from '@/store/setting';

const isOpenUserBar = false;

const _useRouter = useRouter();
const _myProfileStore = myProfileStore();
const _appStore = appStore();
const _taskStore = taskStore();
const _groupStore = groupStore();
const _userStore = userStore();
const _dashboardStore = dashboardStore();
const _domainStore = domainStore();
const _planStore = plansStore();
const isLoading = computed(() => _appStore.isLoading);
const _taskListStore = taskListStore();
const _organizationStore = organizationStore();
const _settingStore = settingStore();
const initData = async () => {
  try {
    _appStore.setIsLoading(true);
    await _myProfileStore.fetchCurrentLoginUser();
    await _settingStore.getOrganizationSettings();
    await Promise.all([
      permissionStore().getPermissionsByUser(),
      _organizationStore.getOrganizationInfo(''),
    ]);
    RouterInstanceClass.getInstance().generateRoutes();

    await RouterInstanceClass.getInstance().generateDynamicRoutes();

    const promise = [
      _dashboardStore.getMemberOnday(),
      _userStore.getShortInfosUser(),
      _groupStore.fetchMyGroups(),
      _taskStore.getCurrentTaskCycle(),
      _domainStore.fetchAllDomainProjects(),
      _taskStore.getDataConfig(),
      _taskStore.getCurrentTaskCycleForChat(),
    ];
    await Promise.all(promise);

    _planStore.getCurrentOrgPlans();
    _appStore.setIsLoading(false);
  } catch {
    _appStore.setIsLoading(false);
  }
};
const isHiddenSidebar = computed(() => _dashboardStore.isHiddenSidebar);
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);

const isPersonalModel = computed(
  () => permissionStore().organizationModel === 'PERSONAL'
);
const userProfile = computed(() => myProfileStore().myProfile);
const isInstallApp = computed(() => _organizationStore.isInstallMobileApp);

const isOpenModalView = ref(false);
const openModalView = (value) => {
  isOpenModalView.value = value;
};

const gotoMyTasks = () => {
  _taskListStore.lastUpdatedTime = Date.now();
  _useRouter.push({ name: 'MyTasks' });
};
initData();
</script>

<template>
  <syn-loading v-if="isLoading"></syn-loading>
  <div v-else class="flex w-screen h-screen">
    <div
      v-if="!isHiddenSidebar"
      class="
        sidebar
        h-full
        flex flex-col
        relative
        shadow-r
        z-10
        hover-to-show__parent_menu
      "
      :class="[
        !isOpenUserBar ? 'bg-white' : 'bg-current-800 rounded-tr-2xl',
        !isOpenMenuBar ? ' content_collapse' : 'w-64',
      ]"
    >
      <RouterLink to="/" class="w-full">
        <div
          class="flex items-center w-full px-3"
          :class="!isOpenUserBar ? '' : 'rounded-tr-2xl'"
          style="height: 52px"
        >
          <div class="relative flex content-center justify-center items-center">
            <SynIcon name="tictop-logo" custom-class="h-9" />
            <div
              v-if="!isPersonalModel"
              class="
                flex-center
                rounded-full
                w-5
                h-5
                absolute
                -right-3
                bottom-0
                logo-label
              "
              :class="!isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
            >
              <SynIcon
                name="crown"
                custom-class="fill-white text-white w-4 h-4"
              />
            </div>
            <!-- <SynIcon name="tictop-logo-premium" custom-class="h-7" /> -->
          </div>
          <div
            class="flex flex-col w-max truncate pl-2 logo-label"
            :class="[
              isPersonalModel ? '' : 'flex-1',
              !isOpenMenuBar ? 'opacity-0' : 'opacity-100',
            ]"
          >
            <div class="w-full flex items-center space-x-2">
              <SynIcon
                v-if="isPersonalModel"
                name="tictop-logo-label"
                custom-class="w-16"
              />
              <SynIcon
                v-else
                name="tictop-logo-label-premium"
                custom-class="w-16"
              />
              <AtomPremiumLabel v-if="!isPersonalModel" :label="'PRO'" />
            </div>
            <div
              class="min-w-max tracking-widest text-xs font-thin"
              :class="!isOpenUserBar ? 'text-current-700' : 'text-white'"
            >
              Working is fun!
            </div>
          </div>
        </div>
      </RouterLink>
      <!-- User Avatar -->
      <div class="flex-1 flex flex-col min-h-0">
        <div class="flex items-center px-2 py-4">
          <div class="flex hover-to-show__parent cursor-pointer">
            <SynAvatarStatusActive
              :id="userProfile?.id || userProfile?.userId"
              :avatar="userProfile?.avatarUrl"
              is-hidden-name
              :name="`${userProfile?.lastName ? userProfile?.lastName : ''} ${
                userProfile?.firstName ? userProfile?.firstName : ''
              }`"
              custom-class="h-12 w-12 text-gray-600"
              is-user
              is-update-avatar
              @click="openModalView(true)"
            />
            <ButtonAvatars
              size="w-2/5 h-2/5"
              :user-id="userProfile?.id || userProfile?.userId"
              :avatar="userProfile.avatarUrl"
            />
          </div>
          <div
            class="flex-1 flex flex-col pl-4 truncate logo-label"
            :class="isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
          >
            <div
              class="
                flex
                items-center
                w-full
                justify-between
                space-x-1
                relative
              "
            >
              <span class="text-current-800 font-medium">
                {{
                  `${userProfile?.lastName ? userProfile?.lastName : ''} ${
                    userProfile?.firstName ? userProfile?.firstName : ''
                  }`
                }}
              </span>
              <vig-dropdown
                class="hover:text-current shadow-none"
                placement="bottom-end"
              >
                <template #dropdown-toggle>
                  <div class="flex-center">
                    <SynIcon
                      name="dots-vertical"
                      custom-class="h-4 w-4 hover:text-current cursor-pointer"
                    />
                  </div>
                </template>
                <template #dropdown-menu>
                  <SynDropdownMenu class="right-0 w-44">
                    <router-link :to="{ name: 'Profile' }">
                      <syn-dropdown-item class="dropdown-item">
                        <div class="flex items-center">
                          <SynIcon
                            name="profile"
                            custom-class="w-4 h-4 fill-gray-500 text-gray-400"
                          />
                          <span class="pl-2">
                            {{ $t('COMMON_LABEL_PROFILE') || 'Profile' }}
                          </span>
                        </div>
                      </syn-dropdown-item>
                    </router-link>
                  </SynDropdownMenu>
                </template>
              </vig-dropdown>
            </div>
            <div class="flex items-center space-x-2 py-1">
              <button
                v-if="isOpenMenuBar"
                class="
                  font-semibold
                  py-2
                  px-2
                  capitalize
                  space-x-1
                  rounded
                  flex-center
                  min-w-max
                  border-current-100
                  hover:border-current-500
                  border
                  text-current-500
                  hover:bg-current-50 hover:bg-opacity-50
                  fill-current
                  relative
                  w-max
                "
                @click="gotoMyTasks"
              >
                <SynIcon name="note" custom-class="w-4 h-4" />
                <span class="text-xs">
                  {{ $t('TASKTAB_LABEL_MYTASK') }}
                </span>
                <!-- <div
                v-if="totalNotificationMyTask !== 0"
                class="
                  absolute
                  -right-3
                  -top-3
                  rounded-full
                  bg-white
                  p-0.5
                  flex-center
                "
              >
                <div
                  class="
                    rounded-full
                    bg-red-500
                    text-white text-center
                    w-5
                    h-5
                    flex
                    justify-center
                    items-center
                  "
                  style="font-size: 11px"
                >
                  {{ totalNotificationMyTask }}
                </div>
              </div> -->
              </button>
              <TotalPinnedTasksGlobal />
            </div>
          </div>
        </div>

        <div
          v-if="!isOpenMenuBar"
          class="px-2 mb-4 logo-label -mt-2"
          :class="!isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
        >
          <button
            class="
              font-semibold
              w-full
              py-2
              px-2
              capitalize
              space-x-1
              rounded
              flex-center
              min-w-max
              border-current-100
              hover:border-current-500
              border
              text-current-500
              hover:bg-current-50
              hover:bg-opacity-50
              hover:ring
              hover:ring-current
              hover:ring-opacity-30
              fill-current
              relative
            "
            @click="gotoMyTasks"
          >
            <SynIcon name="note" custom-class="w-4 h-4" />
          </button>
        </div>
        <div
          v-if="!isOpenMenuBar"
          class="px-2 mb-4 logo-label -mt-2"
          :class="!isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
        >
          <button
            class="
              w-full
              space-x-1
              rounded
              flex-center
              min-w-max
              border-current-100
              hover:border-current-500
              border
              hover:bg-current-50
              hover:bg-opacity-50
              hover:ring
              hover:ring-current
              hover:ring-opacity-30
            "
          >
            <TotalPinnedTasksGlobal />
          </button>
        </div>
        <!-- INSTALL APP -->
        <!--        <div v-if="!isInstallApp" class="w-full flex-center pb-2 px-2">-->
        <div v-if="!isInstallApp" class="w-full pb-2 px-2">
          <div
            class="
              border border-current-50
              rounded
              px-2
              py-2
              w-full
              bg-current-50 bg-opacity-30
            "
          >
            <SynLinkDownload
              class="
                px-0.5
                w-max
                text-sm
                font-serif
                text-current-600
                italic
                tracking-wider
              "
              :is-button-download="false"
              trigger="click"
              :is-text-download="isOpenMenuBar"
            />
          </div>
        </div>

        <div class="flex-1 flex flex-col relative min-h-0">
          <PersonalSidebar is-task-detail-layout />
        </div>
      </div>

      <ToogleCollapseMenu
        class="absolute z-50 -right-2 top-3 hover-to-show__children_menu"
      />
    </div>
    <div class="flex flex-col flex-1 overflow-hidden h-full">
      <slot></slot>
    </div>
  </div>

  <ModalProfileUser
    v-if="isOpenModalView"
    :profile-id="userProfile?.userId || userProfile?.id"
    @cancel="isOpenModalView = false"
  />
</template>
