<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import TaskTable from '@/ui/modules/task/table/TaskTable.vue';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import { keySearch } from '@/ui/modules/task/table/task-list-state';
import { ICustomTaskColumn } from '@/application/interfaces/tasks/task-interfaces';
import taskDrawerStore from '@/store/task/drawer';
import taskCustomConfigStore from '@/store/task/task-custom-config';
import taskManagementStore from '@/store/task/task-management.store';
import taskStore from '@/store/task';

const mode = ref<string>('members');
const _taskDrawerStore = taskDrawerStore();
const _taskCustomConfigStore = taskCustomConfigStore();
const _taskManagementStore = taskManagementStore();
const _taskStore = taskStore();

const taskIds = computed(() => _taskManagementStore.taskIds);

const selectedTaskIds = computed(() => _taskManagementStore.selectedTaskIds);

const isLoadingFilterTask = computed(
  () => _taskManagementStore.isLoadingFilterTask
);

const isLoadMore = computed(() => _taskManagementStore.isLoadMore);
const allTaskByIds = computed(() => _taskStore.allTaskByIds);
const filterDataByKey = computed(() => _taskManagementStore.filterDataByKey);

const sortDataOrderByKey = computed(
  () => _taskManagementStore.sortDataOrderByKey
);

const isHightLightOpenTask = computed(
  () => isHasOpenAttachment.value > 0 && !isOpenAttachmentDetail.value
);
const taskColumnByCode = computed<ICustomTaskColumn[]>(
  () => _taskCustomConfigStore.taskColumnByCode
);
const currentTaskDrawerId = computed(() => _taskDrawerStore.currentTabId);
const filteredTasks = computed(() => _taskManagementStore.filteredTasks);
const canLoadMore = computed(() => {
  const currentLength = taskIds.value?.length;
  if (currentLength <= 0) return false;
  return currentLength < filteredTasks.value?.length;
});
const initComponent = async () => {
  await _taskManagementStore.getTaskWithFilter(true);
  _taskManagementStore.setDefault();
};

onMounted(() => {});

const isHasOpenAttachment = ref(null as any);
const isOpenAttachmentDetail = ref(false);
const onOpenTaskDetail = (task) => {
  _taskDrawerStore.pushCurrentIds({ ...task, tabType: 'DETAIL' });
};
const onFilterByKeyOnColumn = (key, filters) => {
  _taskManagementStore.setFilterDataByKey(key, filters);
  _taskManagementStore.onFilterTaskOnColumn();
};

const onResetFilterOnColumn = (key) => {
  _taskManagementStore.resetFilterDataOrigin(key);
  _taskManagementStore.onFilterTaskOnColumn();
  _taskManagementStore.onSortFilteredTasks();
};

const onClickSort = (key, name, orderFunction) => {
  _taskManagementStore.genSortDataList({ key, name, orderFunction });
  window.scrollTo(0, 0);
};

const onOpenAttachmentDetail = (taskId) => {
  isHasOpenAttachment.value = taskId;
  isOpenAttachmentDetail.value = true;
};

const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;
};

const onLoadMore = () => {
  if (!canLoadMore.value) return;
  _taskManagementStore.onLoadMore();
};
initComponent();
</script>
<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.ADMIN.MEMBER,
      isPage: true,
    }"
    class="h-full w-full pt-3 flex flex-col"
  >
    <div class="flex-1 flex justify-between items-end">
      <ul class="flex cursor-pointer text-sm space-x-0.5">
        <li
          class="py-2 px-4 font-semibold rounded-t-lg items-center flex"
          :class="
            mode == 'members'
              ? 'bg-white text-current-500 border-t border-r border-current-100 z-10'
              : 'text-gray-500 bg-gray-100 '
          "
          :style="
            mode === 'members' ? 'margin-bottom: -1px' : 'margin-bottom: 0px'
          "
          @click="mode = 'members'"
        >
          {{ $t('COMMON_LABEL_SHARED_TASKS') }}
        </li>
      </ul>
    </div>

    <div
      class="
        h-full
        shadow-md
        bg-white
        flex flex-col
        space-y-4
        overflow-y-hidden
        border-t border-current-100
      "
    >
      <TaskTable
        :task-ids="taskIds"
        :all-task-by-ids="allTaskByIds"
        :type="'management'"
        :selected-task-ids="selectedTaskIds"
        is-filter-in-table
        is-filterable-in-column
        :task-column-by-code="taskColumnByCode"
        :sort-data-order-by-key="sortDataOrderByKey"
        :filter-data-by-key="filterDataByKey"
        :is-loading-filter-task="isLoadingFilterTask"
        :is-focused-on-task-id="isOpenTaskDrawer ? currentTaskDrawerId : 0"
        :is-hight-light-open-task="isHightLightOpenTask"
        :key-search="keySearch"
        :is-load-more="isLoadMore"
        :can-load-more="canLoadMore"
        is-shared-list
        is-block-hidden-column
        @on-open-task-detail="onOpenTaskDetail"
        @on-filter-by-key-on-column="onFilterByKeyOnColumn"
        @on-reset-filter-on-column="onResetFilterOnColumn"
        @on-click-sort="onClickSort"
        @on-open-attachment-detail="onOpenAttachmentDetail"
        @on-close-attachment="onCloseAttachment"
        @on-load-more="onLoadMore"
      >
        <template #no-data>
          <SynTr class="h-full border-none" hover="hover:bg-current-50">
            <syn-td :colspan="10">
              <template v-if="keySearch">
                <syn-animation
                  class="pt-12"
                  name="searchNotFound"
                  stype="width: 160px; height: 160px"
                  :title="$t('TASK_LIST_NO_SEARCH_TITLE')"
                  :label="$t('TASK_LIST_NO_SEARCH__SUB_TITLE')"
                ></syn-animation>
              </template>
              <template v-else>
                <div class="flex-center flex-col pb-4 pt-12">
                  <VigImage
                    :path="`https://d1tvqetrcurhzb.cloudfront.net/common/images/share+task.jpg`"
                    :custom-class="'h-42 w-80'"
                  />
                  <span
                    class="text-current-700 pt-8"
                    style="font-size: 1.15rem"
                    v-html="$t('SHARE_TASK_LIST_DONT_HAVE_ALL_TASK_TITLE')"
                  ></span>
                  <span
                    class="text-gray-500 text-sm py-2"
                    v-html="$t('SHARE_TASK_LIST_DONT_HAVE_ALL_TASK_SUB_TITLE')"
                  ></span>
                </div>
              </template>
            </syn-td>
          </SynTr>
        </template>
      </TaskTable>
    </div>
  </div>
</template>
