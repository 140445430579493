import { defineStore } from 'pinia';
import TaskService from '@/application/services/task/TaskService';
import storeId from '@/base/store/storeId';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import { IWorkflowStepType } from '@/application/types/workflow/workflow.types';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import { IStepTemplateEntity } from '@/domain/entities/workflow/StepTemplateEntity';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

export default defineStore({
    id: storeId.WORKFLOW_STEP,
    state: () => ({
        _taskService: TaskService.getInstance(),
        allStepType: [] as IWorkflowStepType[],
        allWorkflowSteps: [] as IStepTemplateEntity[],
    }),
    getters: {
        allWorkflowStepByIds(state): any {
            return state?.allWorkflowSteps?.reduce(
                (currentResult, currentItem) => {
                    return {
                        ...currentResult,
                        [currentItem?.id]: currentItem,
                    };
                },
                {}
            );
        },
        allWorkflowStepByNames(state): any {
            return state?.allWorkflowSteps?.reduce(
                (currentResult, currentItem) => {
                    const name = stringNomalize(currentItem?.name)
                        ?.toUpperCase()
                        ?.replace(/\(\d+\)$/, '')
                        ?.trim()
                        .replace(/ +/g, '_');

                    if (!currentResult[name]) {
                        currentResult[name] = {
                            total: 1,
                            departmentId: currentItem?.departmentId,
                            domainId: currentItem?.domainId,
                            groupId: currentItem?.groupId,
                        };
                    } else {
                        currentResult[name] = {
                            ...currentResult[name],
                            total: currentResult[name]?.total + 1,
                        };
                    }
                    return {
                        ...currentResult,
                    };
                },
                {}
            );
        },
    },
    actions: {
        async addWorkFlowStepTemplate(workflowStep: IStepTemplateEntity) {
            try {
                const res =
                    await WorkflowRepository.getInstance().addWorkFlowStepTemplate(
                        workflowStep
                    );

                if (res.result?.id) this.updateAllWorkflowSteps(res.result);
                return res;
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },
        async updateWorkFlowStepTemplate(workflowStep: IStepTemplateEntity) {
            try {
                const res =
                    await WorkflowRepository.getInstance().updateWorkFlowStepTemplate(
                        workflowStep
                    );

                if (res.result?.id) this.updateAllWorkflowSteps(res.result);
                return res;
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },
        async getAllStepType() {
            const res = await WorkflowRepository.getInstance().getAllStepType();
            this.allStepType = res?.result;
        },
        async getAllStepTemplates() {
            const res =
                await WorkflowRepository.getInstance().getAllStepTemplates();
            this.allWorkflowSteps = arrayOrderBy(
                res?.result,
                ['createdTime'],
                ['desc']
            ) as IStepTemplateEntity[];

            return res?.result;
        },
        async getWorkflowById(id: string) {
            const res = await WorkflowRepository.getInstance().getWorkflowById(
                id
            );

            return res?.result;
        },

        generateWorkflowName(originalName) {
            const convertedName = stringNomalize(originalName)
                ?.toUpperCase()
                ?.replace(/\(\d+\)$/, '')
                ?.trim()
                .replace(/ +/g, '_');

            const totalUsedName = Object.prototype.hasOwnProperty.call(
                this.allWorkflowStepByNames,
                convertedName
            )
                ? this.allWorkflowStepByNames[convertedName]?.total
                : 0;

            return totalUsedName > 0
                ? `${originalName?.replace(/\(\d+\)$/, '')?.trim()} (${
                      totalUsedName + 1
                  })`
                : originalName;
        },
        checkNewWorkflowExisted(newWorkflow: {
            name: string;
            departmentId?: number;
            domainId?: number;
            groupId?: number;
        }) {
            const convertedName = stringNomalize(newWorkflow?.name)
                ?.toUpperCase()
                ?.replace(/ +/g, '_')
                ?.trim();
            if (
                !Object.prototype.hasOwnProperty.call(
                    this.allWorkflowStepByNames,
                    convertedName
                )
            )
                return false;

            const { departmentId, domainId, groupId } =
                this.allWorkflowStepByNames[convertedName];

            return (
                newWorkflow.departmentId == departmentId &&
                newWorkflow.domainId == domainId &&
                newWorkflow.groupId == groupId
            );
        },

        async deleteWorkflowStep(workflowStepId: string): Promise<boolean> {
            try {
                const res =
                    await WorkflowRepository.getInstance().deleteWorkFlowStepTemplate(
                        workflowStepId
                    );
                if (!res) return false;

                this.allWorkflowSteps = this.allWorkflowSteps?.filter(
                    (o) => o.id !== workflowStepId
                );

                return true;
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
                return false;
            }
        },
        updateAllWorkflowSteps(newData: IStepTemplateEntity) {
            const index = this.allWorkflowSteps?.findIndex(
                (o) => o.id == newData?.id
            );
            if (index > -1)
                this.allWorkflowSteps[index] = {
                    ...this.allWorkflowSteps[index],
                    ...newData,
                };
            else this.allWorkflowSteps = [newData, ...this.allWorkflowSteps];
        },
    },
});
