<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0,0,256,256"
  >
    <defs>
      <linearGradient
        id="color-1_UECmBSgBOvPT_gr1"
        x1="4.725"
        y1="14.725"
        x2="23.055"
        y2="33.055"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#18884f"></stop>
        <stop offset="1" stop-color="#0b6731"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(-25.6,-25.6) scale(1.2,1.2)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.33333,5.33333)">
          <rect x="28" y="15" width="16" height="9" fill="#21a366"></rect>
          <path
            d="M44,24h-32v16c0,1.105 0.895,2 2,2h28c1.105,0 2,-0.895 2,-2z"
            fill="#185c37"
          ></path>
          <rect x="28" y="24" width="16" height="9" fill="#107c42"></rect>
          <rect x="12" y="15" width="16" height="9" fill="#3fa071"></rect>
          <path
            d="M42,6h-14v9h16v-7c0,-1.105 -0.895,-2 -2,-2z"
            fill="#33c481"
          ></path>
          <path
            d="M14,6h14v9h-16v-7c0,-1.105 0.895,-2 2,-2z"
            fill="#21a366"
          ></path>
          <path
            d="M22.319,13h-10.319v24h10.319c2.033,0 3.681,-1.648 3.681,-3.681v-16.638c0,-2.033 -1.648,-3.681 -3.681,-3.681z"
            fill="#000000"
            opacity="0.05"
          ></path>
          <path
            d="M22.213,36h-10.213v-22.667h10.213c1.724,0 3.121,1.397 3.121,3.121v16.425c-0.001,1.724 -1.398,3.121 -3.121,3.121z"
            fill="#000000"
            opacity="0.07"
          ></path>
          <path
            d="M22.106,35h-10.106v-21.333h10.106c1.414,0 2.56,1.146 2.56,2.56v16.213c0.001,1.414 -1.146,2.56 -2.56,2.56z"
            fill="#000000"
            opacity="0.09"
          ></path>
          <path
            d="M22,34h-16c-1.105,0 -2,-0.895 -2,-2v-16c0,-1.105 0.895,-2 2,-2h16c1.105,0 2,0.895 2,2v16c0,1.105 -0.895,2 -2,2z"
            fill="url(#color-1_UECmBSgBOvPT_gr1)"
          ></path>
          <path
            d="M9.807,19h2.386l1.936,3.754l2.046,-3.754h2.229l-3.071,5l3.141,5h-2.351l-2.11,-3.93l-2.101,3.93h-2.386l3.193,-5.018z"
            fill="#ffffff"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
