<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
// import myProfileStore from '@/store/auth/my-profile';
import { ignoreUnicode } from '@/ui/plugins/utils';
import departmentStore from '@/store/department';
import groupStore from '@/store/group';
import domainStore from '@/store/scope';
import SelectDependent from '@/ui/modules/workflow/SelectDependent.vue';
import WorkflowEntity, {
  IWorkflowEntity,
} from '@/domain/entities/workflow/WorkflowEntity';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

const props = withDefaults(
  defineProps<{
    viewMode: 'VIEW' | 'EDIT' | 'TASK_VIEW';
    workflowInfo: IWorkflowEntity;
    isRemovable?: boolean;
  }>(),
  {
    viewMode: 'VIEW',
  }
);

const emit = defineEmits<{
  (e: 'update:workflowInfo', data: WorkflowEntity): void;
  (e: 'removeSuccess'): void;
}>();

const workflowInformationData = ref<WorkflowEntity>(
  new WorkflowEntity(props.workflowInfo || {})
);

watch(
  () => props.workflowInfo?.id,
  () => {
    initData();
  }
);

const _departmentStore = departmentStore();
const _groupStore = groupStore();
const _domainStore = domainStore();

const domainsDefault = computed(() => _groupStore.domains);
const keySearchScope = ref('');
// const currentUser = computed(() => myProfileStore().myProfile);

const checkRequired = ref(false);

const initData = async () => {
  _groupStore.getDomainListById(getCurrentUserId(), true),
    (workflowInformationData.value = new WorkflowEntity(
      props.workflowInfo || {}
    ));
};

initData();

onMounted(() => {});

const allDepartment = computed(() => _departmentStore.allDepartments);
const allDepartmentByIds = computed(() => _departmentStore.allDepartmentByIds);
const allMyGroups = computed(() => _groupStore.allMyGroups);
const allGroupByIds = computed(() => _groupStore.allGroupByIds);

const domains = computed(() => {
  const searchTxt = ignoreUnicode(keySearchScope.value);

  if (!searchTxt) return domainsDefault.value;

  return (domainsDefault.value || []).filter(
    (domain) =>
      domain?.domainName &&
      ignoreUnicode(domain?.domainName).includes(searchTxt)
  );
});
const domainByIds = computed(() => _domainStore.domainByIds);

const onChangeSelectedInfo = (type, data) => {
  switch (type) {
    case 'department':
      workflowInformationData.value.departmentId = data?.id;

      break;
    case 'domain':
      workflowInformationData.value.domainId = data?.domainId;

      break;
    case 'group':
      workflowInformationData.value.groupId = data?.id;

      break;

    default:
      break;
  }

  updatePropData();
};
const removeSelectedInfo = (type) => {
  switch (type) {
    case 'department':
      workflowInformationData.value.departmentId = undefined;

      break;
    case 'domain':
      workflowInformationData.value.domainId = undefined;

      break;
    case 'group':
      workflowInformationData.value.groupId = undefined;

      break;
    default:
      break;
  }
  updatePropData();
};

const updatePropData = () => {
  emit('update:workflowInfo', workflowInformationData.value);
};

const isRemoving = ref<boolean>(false);
const onRemove = async () => {
  isRemoving.value = true;
  await workflowInformationData.value.remove();

  isRemoving.value = false;
  emit('removeSuccess');
};

defineExpose({
  initData,
});
</script>

<template>
  <!-- INFO GROUP -->
  <div class="w-full h-full flex flex-col space-y-4 p-4">
    <div class="flex-1 min-h-0 overflow-auto flex flex-col space-y-4">
      <template v-if="viewMode == 'EDIT'">
        <!-- Name -->
        <section class="flex w-full items-center justify-center space-x-4">
          <div class="w-full">
            <SynFormInput
              :title="$t('WORKFLOW_LABEl_NAME')"
              :error-message="
                checkRequired && workflowInformationData.name?.trim() === ''
                  ? 'TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED'
                  : ''
              "
            >
              <SynInput
                v-model="workflowInformationData.name"
                class="w-full"
                style-name="max-height: 500px;"
                autofocus
                :placeholder="$t('WORKFLOW_LABEL_NAME_PLACEHOLDER')"
                :error-message="
                  checkRequired && workflowInformationData.name?.trim() === ''
                    ? $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED')
                    : ''
                "
                :max-length="80"
                @update:model-value="updatePropData"
              />
            </SynFormInput>
          </div>
        </section>

        <!-- description -->
        <section class="flex w-full justify-center space-x-4">
          <SynFormInput :title="$t('COMMON_LABEL_DESCRIPTION')">
            <SynInputArea
              v-model="workflowInformationData.description"
              class="w-full"
              style-name="max-height: 500px;"
              :max-length="150"
              :placeholder="
                $t('WORKFLOW_LABEl_DESCRIPTION_PLACEHOLDER') || 'Description'
              "
              @update:model-value="updatePropData"
            />
          </SynFormInput>
        </section>

        <template v-if="false">
          <!-- department -->
          <section class="flex w-full justify-center space-x-4 items-center">
            <SynFormInput :title="$t('COMMON_MODULE_MANAGEMENT_DEPARTMENT')">
              <SelectDependent
                :avatar="
                  workflowInformationData?.departmentId
                    ? allDepartmentByIds[workflowInformationData?.departmentId]
                        .avatarUrl
                    : ''
                "
                :name="
                  workflowInformationData?.departmentId
                    ? allDepartmentByIds[workflowInformationData?.departmentId]
                        .name
                    : ''
                "
                :data="allDepartment"
                removable
                name-field="name"
                avatar-field="avatarUrl"
                has-avatar
                :placeholder="$t('USER_LABEL_CHANGE_DEPARTMENT_TITLE')"
                type="department"
                @chosen="(value) => onChangeSelectedInfo('department', value)"
                @reset="removeSelectedInfo('department')"
              />
            </SynFormInput>
          </section>

          <!-- domain -->
          <section class="w-full flex items-center space-x-2">
            <SynFormInput :title="$t('DOMAIN_LABEL_TITLE')">
              <SelectDependent
                :avatar="''"
                :name="
                  workflowInformationData?.domainId
                    ? domainByIds[workflowInformationData?.domainId].name
                    : ''
                "
                :data="domains"
                name-field="domainName"
                avatar-field=""
                removable
                has-avatar
                :placeholder="$t('DOMAIN_LABEL_SELECT_DOMAIN')"
                type="domain"
                @chosen="(value) => onChangeSelectedInfo('domain', value)"
                @reset="removeSelectedInfo('domain')"
              />
            </SynFormInput>
          </section>

          <!-- group -->
          <section class="w-full flex items-center space-x-2">
            <SynFormInput :title="$t('COMMON_LABEL_GROUP')">
              <SelectDependent
                :avatar="
                  workflowInformationData?.groupId
                    ? allGroupByIds[workflowInformationData?.groupId].avatarUrl
                    : ''
                "
                :name="
                  workflowInformationData?.groupId
                    ? allGroupByIds[workflowInformationData?.groupId].name
                    : ''
                "
                :data="allMyGroups"
                name-field="name"
                avatar-field="avatarUrl"
                has-avatar
                removable
                :placeholder="$t('COMMON_LABEl_SELECT_GROUP')"
                type="group"
                @chosen="(value) => onChangeSelectedInfo('group', value)"
                @reset="removeSelectedInfo('group')"
              />
            </SynFormInput>
          </section>
        </template>

        <!-- scope
        <section class="w-full flex justify-between">
          <div class="w-1/2 text-gray-500">
            <span>Scope</span>
          </div>
          <div class="w-full rounded-md flex justify-between flex-1">
            <SynSelect
              v-model="workflowInformationData.scope"
              :options="[
                { key: 'PUBLIC', value: 'Public' },
                { key: 'SPECIFIC_TASK', value: 'Specific task' },
              ]"
              key-field="key"
              :placeholder="'Select scope'"
              text-field="value"
            />
          </div>
        </section> -->
      </template>
      <template v-if="viewMode == 'VIEW'">
        <!-- Name -->
        <section class="flex w-full items-center justify-center space-x-4">
          <div class="w-full">
            <SynFormInput :title="$t('WORKFLOW_LABEl_NAME')">
              <span class="px-2 py-1 bg-gray-50 w-full">
                {{ workflowInformationData?.name }}
              </span>
            </SynFormInput>
          </div>
        </section>

        <!-- description -->
        <section
          v-if="workflowInformationData.description"
          class="flex w-full items-center justify-center space-x-4"
        >
          <div class="w-full">
            <SynFormInput :title="$t('COMMON_LABEL_DESCRIPTION')">
              <span class="px-2 py-1 bg-gray-50 w-full">
                {{ workflowInformationData.description }}
              </span>
            </SynFormInput>
          </div>
        </section>
        <div class="flex items-center space-x-2">
          <!-- department -->
          <TaskDetailField>
            <template #fieldName>
              <span>{{ $t('COMMON_MODULE_MANAGEMENT_DEPARTMENT') }}</span>
            </template>
            <template #body>
              <div
                v-if="workflowInformationData?.departmentId"
                class="relative flex items-center h-10 rounded w-48"
              >
                <div class="w-6 h-6 absolute left-2">
                  <div class="relative flex items-center">
                    <SynAvatar
                      :src="
                        allDepartmentByIds[
                          workflowInformationData?.departmentId
                        ]?.avatarUrl?.replace('original', 'mini')
                      "
                      :name="
                        allDepartmentByIds[
                          workflowInformationData?.departmentId
                        ]?.name
                      "
                      :type="'department'"
                      custom-class="w-6 h-6"
                    />
                  </div>
                </div>

                <div
                  :title="
                    allDepartmentByIds[workflowInformationData?.departmentId]
                      ?.name
                      ? allDepartmentByIds[
                          workflowInformationData?.departmentId
                        ]?.name
                      : ''
                  "
                  class="
                    text-sm text-gray-500
                    h-full
                    w-full
                    focus:bg-white focus:outline-none focus:border-transparent
                    flex
                    items-center
                    pl-10
                    pr-8
                    text-overflow-hidden-line
                  "
                >
                  <span
                    v-if="
                      allDepartmentByIds[workflowInformationData?.departmentId]
                        ?.name
                    "
                    class="truncate text-overflow-hidden-line"
                  >
                    {{
                      allDepartmentByIds[workflowInformationData?.departmentId]
                        ?.name
                    }}
                  </span>
                </div>
              </div>
            </template>
          </TaskDetailField>
          <!-- domain -->
          <TaskDetailField>
            <template #fieldName>
              <span>{{ $t('TASK_CREATE_FORM_DOMAIN') }}</span>
            </template>
            <template #body>
              <div
                v-if="workflowInformationData?.domainId"
                class="relative flex items-center h-10 rounded"
              >
                <div class="w-6 h-6 absolute left-2">
                  <div class="relative flex items-center">
                    <SynAvatar
                      :name="
                        domainByIds[workflowInformationData?.domainId]?.name
                      "
                      :type="'domain'"
                      custom-class="w-6 h-6"
                    />
                  </div>
                </div>

                <div
                  :title="
                    domainByIds[workflowInformationData?.domainId]?.name
                      ? domainByIds[workflowInformationData?.domainId]?.name
                      : ''
                  "
                  class="
                    text-sm text-gray-500
                    h-full
                    w-full
                    focus:bg-white focus:outline-none focus:border-transparent
                    flex
                    items-center
                    pl-10
                    pr-8
                    text-overflow-hidden-line
                  "
                >
                  <span
                    v-if="domainByIds[workflowInformationData?.domainId]?.name"
                    class="truncate text-overflow-hidden-line"
                  >
                    {{ domainByIds[workflowInformationData?.domainId]?.name }}
                  </span>
                </div>
              </div>
            </template>
          </TaskDetailField>
          <!-- Group -->
          <TaskDetailField>
            <template #fieldName>
              <span>{{ $t('DOCUMENT_TITLE_GROUP') }}</span>
            </template>
            <template #body>
              <div
                v-if="workflowInformationData?.groupId"
                class="relative flex items-center py-1 h-10 rounded w-48"
              >
                <div class="w-6 h-6 absolute left-2">
                  <div class="relative flex items-center">
                    <SynAvatar
                      :src="
                        allGroupByIds[
                          workflowInformationData?.groupId
                        ]?.avatar?.replace('original', 'mini')
                      "
                      :name="
                        allGroupByIds[workflowInformationData?.groupId]?.name
                      "
                      :type="'domain'"
                      custom-class="w-6 h-6"
                    />
                  </div>
                </div>

                <div
                  :title="
                    allGroupByIds[workflowInformationData?.groupId]?.name
                      ? allGroupByIds[workflowInformationData?.groupId]?.name
                      : ''
                  "
                  class="
                    text-sm text-gray-500
                    h-full
                    w-full
                    focus:bg-white focus:outline-none focus:border-transparent
                    flex
                    items-center
                    pl-10
                    pr-8
                    text-overflow-hidden-line
                  "
                >
                  <span
                    v-if="allGroupByIds[workflowInformationData?.groupId]?.name"
                    class="truncate text-overflow-hidden-line"
                  >
                    {{ allGroupByIds[workflowInformationData?.groupId]?.name }}
                  </span>
                </div>
              </div>
            </template>
          </TaskDetailField>
        </div>

        <!-- task -->
        <section class="w-full flex items-center space-x-2">
          <div class="w-1/2 text-gray-500">
            <span>
              {{ $t('WORKFLOW_LABEl_TOTAL_TASK_IN_USE') }}
            </span>
          </div>

          <div
            v-if="workflowInformationData?.totalTaskUsed"
            class="flex items-center"
          >
            {{ workflowInformationData?.totalTaskUsed }}
          </div>
        </section>
        <!-- Modified -->
        <section
          v-if="
            workflowInformationData.lastModificationTime !==
            workflowInformationData.creationTime
          "
          class="w-full flex items-center space-x-2"
        >
          <div class="w-full flex items-center">
            <div class="w-1/2 text-gray-500">
              <span>
                {{ $t('COMMON_LABEL_MODIFIED') }}
              </span>
            </div>

            <div class="flex items-center space-x-2">
              <SynLabelDateTime
                format="date"
                ignore-timezone
                :datetime="workflowInformationData.lastModificationTime"
              />
              <button
                class="w-6 h-6 rounded-full hover:bg-current-50 flex-center"
              >
                <SynIcon name="history" />
              </button>
            </div>
          </div>
        </section>
      </template>
    </div>
    <div
      v-if="isRemovable"
      class="
        flex
        justify-end
        items-center
        hover:text-current
        cursor-pointer
        space-x-2
      "
    >
      <SynButton
        :is-loading="isRemoving"
        color="red"
        :disabled="false"
        @click="onRemove"
      >
        <span> {{ $t('COMMON_LABEL_DELETE') }}</span>
      </SynButton>
    </div>
  </div>
</template>
