<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';
import { NotificationSettingFunction } from '@/ui/common/constants/constant';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  notificationSetting;
}>();

const UNLIMITED_TIME_OFF = dayjs('3000-01-01T00:00:00+00:00');
const notificationSettingFunc = computed(() => {
  let result = '';
  if (
    props.notificationSetting?.function == NotificationSettingFunction.Messaging
  )
    result = 'NOTIFICATION_FUNC_LABEL_OPTION_1';
  if (props.notificationSetting?.function == NotificationSettingFunction.Task)
    result = 'NOTIFICATION_FUNC_LABEL_OPTION_2';
  if (props.notificationSetting?.function == NotificationSettingFunction.All)
    result = 'NOTIFICATION_FUNC_LABEL_OPTION_3';

  return result ? translate(result)?.toLowerCase() : '';
});

const notificationSettingMsg = computed(() => {
  if (!props.notificationSetting || props.notificationSetting?.timeOff)
    return '';

  const timeOff = dayjs(props.notificationSetting?.offTime);

  const isUnlimitedTimeOff = UNLIMITED_TIME_OFF.diff(timeOff) == 0;
  const isTurnOffAll =
    props.notificationSetting?.function == NotificationSettingFunction.All;

  if (isUnlimitedTimeOff)
    return translate(
      isTurnOffAll
        ? 'NOTIFICATION_SETTING_MESSAGE_ALERT_UNTIL_TURN_ON'
        : 'NOTIFICATION_SETTING_MESSAGE_ALERT_LABEL_OPT_UNTIL_TURN_ON',
      {
        key: notificationSettingFunc.value,
      }
    );

  const offTimeFormat = timeOff.isSame(dayjs(), 'd')
    ? 'hh:mm A'
    : 'MMM DD, YYYY hh:mm A';

  const timeOffStr = dayjs(props.notificationSetting?.offTime).format(
    offTimeFormat
  );
  return translate(
    isTurnOffAll
      ? 'NOTIFICATION_SETTING_MESSAGE_ALERT_LABEL_OPT_ALL'
      : 'NOTIFICATION_SETTING_MESSAGE_ALERT_LABEL',
    {
      key: notificationSettingFunc.value,
      timeOff: `<span class='font-semibold text-current-600'>${timeOffStr}</span></br>`,
    }
  );
});
</script>

<template>
  <span class="text-gray-600">
    <span
      v-html="
        notificationSettingMsg
          ? notificationSettingMsg
          : $t('GROUP_NOTIFICATION_SETTING_SUB')
      "
    ></span>
  </span>
</template>
