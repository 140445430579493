import {
    arrayOrderBy,
    cloneDeep,
    stringNomalize,
} from '@/ui/hooks/commonFunction';
import { computed, ref } from 'vue';

export default function () {
    const originalDataList = ref<any[]>([]);
    const filteredDataList = ref<any[]>([]);
    const showingDataList = ref<any[]>([]);

    const initComposable = (originalData: any[]) => {
        const _originalData = originalData?.map((data) => {
            return {
                ...data,
                name: data?.name?.trim(),
            };
        });
        filterDataByKey.value = {};
        originalDataList.value = [..._originalData];
        filteredDataList.value = [..._originalData];
        showingDataList.value = sortDefault([..._originalData]);
    };
    const refreshComposable = (originalData: any[]) => {
        originalDataList.value = [...originalData];
        filteredDataList.value = [...originalData];
        onFilterByKey();
    };
    const genSortData = (currentSortData, sortObj, enableSortMultiple) => {
        const { key, name, orderFunction } = sortObj;
        let orderBy = 'asc';
        let sortData = [] as any[];
        if (enableSortMultiple) {
            const indexExisted = currentSortData.findIndex(
                (o: any) => o.key == key
            );
            if (indexExisted > -1) {
                const currentOrderBy = currentSortData[indexExisted].orderBy;
                if (currentOrderBy == 'desc') {
                    sortData = sortData.filter((o: any) => o.key !== key);
                } else {
                    orderBy = currentOrderBy == 'asc' ? 'desc' : 'asc';
                    sortData[indexExisted] = {
                        key,
                        name,
                        orderBy,
                        orderFunction,
                    };
                }
            } else {
                sortData = [...sortData, { key, name, orderBy, orderFunction }];
            }
        } else {
            const indexExisted = currentSortData.findIndex((o) => o.key == key);
            if (indexExisted > -1) {
                const currentOrderBy = currentSortData[indexExisted].orderBy;
                if (currentOrderBy == 'asc') {
                    sortData = [];
                } else {
                    orderBy = currentOrderBy == 'desc' ? 'asc' : 'desc';
                    sortData = [{ key, name, orderBy, orderFunction }];
                }
            } else {
                sortData = [{ key, name, orderBy: 'desc', orderFunction }];
            }
        }
        return sortData;
    };

    const sortList = ref<any[]>([]);

    const sortDefault = (tasks: any[]) => {
        if (!tasks || tasks.length == 0) return [];
        // return tasks;
        return arrayOrderBy(
            tasks,
            ['hasBeenJustCreated', 'name'],
            ['asc', 'asc']
        );
    };

    const onSortFilteredDataList = () => {
        if (!filteredDataList.value || filteredDataList.value.length == 0) {
            showingDataList.value = [];
        }

        let filteredData = [...new Set(filteredDataList.value)];
        if (sortList.value?.length > 0) {
            const iterate = [
                ...sortList.value.map((o: any) => o.orderFunction),
            ];

            const order = [...sortList.value.map((o: any) => o.orderBy)];

            filteredData = arrayOrderBy(filteredData, iterate, order);
        } else {
            filteredData = sortDefault(filteredData);
        }

        const pageSize = 30;

        showingDataList.value = [...filteredData].slice(0, pageSize);
    };

    const genSortDataList = (sortObj) => {
        sortList.value = genSortData(sortList.value, sortObj, false);

        onSortFilteredDataList();
    };

    const sortDataOrderByKey = computed(() => {
        let result = {};
        const sortLength = sortList.value?.length;
        if (sortLength > 0) {
            const isShowIndex = sortLength > 1;
            result = sortList.value.reduce(
                (previousValue, currentValue: any, index) => {
                    previousValue = {
                        ...previousValue,
                        ...{
                            [currentValue.key]: {
                                sortIndex: isShowIndex ? index + 1 : null,
                                orderBy: currentValue.orderBy,
                            },
                        },
                    };
                    return previousValue;
                },
                {}
            );
        }
        // console.log(' result-------------:', result);
        return result;
    });

    const filterDataByKey = ref<any>({});

    const onFilterByKey = () => {
        const filterData = cloneDeep(filterDataByKey.value);
        let filteredData = cloneDeep(originalDataList.value) || [];

        if (filterData) {
            Object.entries(filterData).forEach(([key, value]: any) => {
                switch (key) {
                    case 'keySearch':
                        {
                            const keySearch = value;
                            filteredData = !keySearch
                                ? [...filteredData]
                                : filteredData.filter(
                                      (t) =>
                                          stringNomalize(
                                              t.name?.toLowerCase()
                                          ).includes(
                                              stringNomalize(
                                                  keySearch?.toLowerCase()
                                              )
                                          ) ||
                                          stringNomalize(
                                              t.code?.toLowerCase()
                                          ).includes(
                                              stringNomalize(
                                                  keySearch?.toLowerCase()
                                              )
                                          )
                                  );
                        }
                        break;
                    case 'isActive':
                        {
                            if (value !== null)
                                filteredData = filteredData?.filter(
                                    (wf) => wf.isActive == value
                                );
                        }
                        break;

                    default:
                        {
                            const mapKey = 'id';
                            const selectedFilter = (value as any).filter(
                                (o) => o.selected
                            );
                            if (selectedFilter.length == 0) return;

                            filteredData = [
                                ...(filteredData?.filter((t) =>
                                    selectedFilter?.some(
                                        (f) =>
                                            f[mapKey] == t[key] ||
                                            (!f[mapKey] && !t[key])
                                    )
                                ) || []),
                            ];
                        }
                        break;
                }
            });
        }

        filteredDataList.value = filteredData.filter((o) => o.id);

        onSortFilteredDataList();
    };
    const onFilterByKeyOnColumn = (key, filters) => {
        setFilterDataByKey(key, filters);
        onFilterByKey();
    };
    const onResetFilterOnColumn = () => {
        // setFilterDataByKey(key, filters);
        // onFilterByKey();
    };

    const isLoadMore = ref<boolean>(false);
    const onLoadMore = () => {
        isLoadMore.value = true;
        const currentLength = showingDataList.value.length;
        if (currentLength < filteredDataList.value.length) {
            showingDataList.value = filteredDataList.value
                .map((o) => o.id)
                .slice(0, currentLength + 10);
        }
        isLoadMore.value = false;
    };

    const setFilterDataByKey = (key, filters) => {
        switch (key) {
            case 'keySearch':
                {
                    filterDataByKey.value[key] = filters;
                }
                break;

            default:
                {
                    filterDataByKey.value[key] = filters;
                    // filterDataByKey.value[key] = filterDataByKey.value[key].map(
                    //     (o) => {
                    //         const isFilter = filters.some((f) => f.id == o.id);
                    //         o.selected = isFilter;
                    //         return o;
                    //     }
                    // );
                }
                break;
        }
    };

    return {
        initComposable,
        genSortDataList,
        sortDataOrderByKey,
        onFilterByKeyOnColumn,
        filterDataByKey,
        onResetFilterOnColumn,
        showingDataList,
        onLoadMore,
        setFilterDataByKey,
        onFilterByKey,
        refreshComposable,
    };
}
