<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { isOpenTaskPinnedDrawer } from '@/ui/modules/task/task-global-state';
import myProfileStore from '@/store/auth/my-profile';

const _myProfileStore = myProfileStore();
const myTotalPinnedTasks = computed<number>(
  () => _myProfileStore.myTaskInformation?.totalPinned
);

watch(
  () => myTotalPinnedTasks.value,
  (current, old) => {
    if (current > old) openHighlight();
  }
);

const isOpenHighlight = ref<boolean>(false);
const openHighlight = () => {
  isOpenHighlight.value = true;

  setTimeout(() => {
    isOpenHighlight.value = false;
  }, 2000);
};

onMounted(() => {});

const togglePin = () => {
  isOpenTaskPinnedDrawer.value = !isOpenTaskPinnedDrawer.value;
};
</script>
<template>
  <div
    id="dashboard-layout-toggle-pinned-tasks-button"
    class="
      flex-center
      rounded
      w-max
      h-8
      p-2
      hover:bg-gray-100
      cursor-pointer
      relative
    "
    :class="isOpenHighlight || isOpenTaskPinnedDrawer ? ' bg-orange-100' : ''"
    style="min-width: 2rem"
    @click="togglePin"
  >
    <SynIcon name="pin" custom-class="w-4 h-4" />
    <span
      v-if="myTotalPinnedTasks > 0"
      class=""
      :class="
        isOpenHighlight
          ? 'animate-bounce font-semibold text-orange-500'
          : 'text-sm text-gray-600'
      "
    >
      {{ myTotalPinnedTasks }}
    </span>
  </div>
</template>
<style>
@-moz-keyframes blinkingTextAnimation {
  0% {
    color: #076260;
  }
  49% {
    color: #4bb0b2;
  }
  100% {
    color: #e0f1f2;
  }
}

@-webkit-keyframes blinkingTextAnimation {
  0% {
    color: #076260;
  }
  49% {
    color: #4bb0b2;
  }
  100% {
    color: #e0f1f2;
  }
}

@-o-keyframes blinkingTextAnimation {
  0% {
    color: #076260;
  }
  49% {
    color: #4bb0b2;
  }
  100% {
    color: #e0f1f2;
  }
}

@-ms-keyframes blinkingTextAnimation {
  0% {
    color: #076260;
  }
  49% {
    color: #4bb0b2;
  }
  100% {
    color: #e0f1f2;
  }
}

@keyframes blinkingTextAnimation {
  0% {
    color: #076260;
  }
  49% {
    color: #4bb0b2;
  }
  100% {
    color: #e0f1f2;
  }
}

.blinking-color_current {
  -webkit-animation: blinkingTextAnimation 1s infinite;
  -moz-animation: blinkingTextAnimation 1s infinite;
  -ms-animation: blinkingTextAnimation 1s infinite;
  -o-animation: blinkingTextAnimation 1s infinite;
  animation: blinkingTextAnimation 1s infinite;
}
</style>
