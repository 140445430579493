import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
export const linkUrl =
    CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT + '/web/avatar_user/';
export const linkUrlGroup =
    CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT + '/web/avatar_group/';

export const ImageDefault = (type) => {
    let listDefaultAnimal = [];
    let listDefaultGirl = [];
    let listDefaultGroup = [];
    let listDefaultBoy = [];
    let listShow = {};

    for (let el = 0; el < 20; el++) {
        listDefaultGroup[el] = linkUrlGroup + `group_${el}` + '.png';
    }
    for (let el = 0; el < 6; el++) {
        listDefaultGirl[el] = linkUrl + `girl_${el}` + '.png';
        listDefaultBoy[el] = linkUrl + `boy_${el}` + '.png';
    }
    for (let el = 0; el < 9; el++) {
        listDefaultAnimal[el] = linkUrl + `animal_${el}` + '.png';
    }
    if (type == 'USER') {
        listShow = {
            GIRLS: {
                key: 'GIRLS',
                objects: listDefaultGirl,
            },
            BOYS: {
                key: 'BOYS',
                objects: listDefaultBoy,
            },
            ANIMALS: {
                key: 'ANIMALS',
                objects: listDefaultAnimal,
            },
        };
    } else {
        listShow = {
            GROUPS: {
                key: 'GROUPS',
                objects: listDefaultGroup,
            },
        };
    }
    return listShow;
};
