<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import ConversationsList from '@/ui/modules/messaging/conversations-list/ConversationsList.vue';
import { orgNotifGlobalStore } from '@/store/organization/org-notif-global';
import ChatPanel from '@/ui/modules/messaging/chat-panel/ChatPanel.vue';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
// import ChatService from '@/application/services/ChatService';
// import myProfileStore from '@/store/auth/my-profile';
import ModalNotifTaskDetail from '@/ui/modules/task/detail/notif-task-detail/ModalNotifTaskDetail.vue';
import chatStore from '@/store/chat';
import chatExternalStore from '@/store/chat/chat-external';
import chatSupportingStore from '@/store/chat/chat-supporting';

const props = defineProps<{
  organization: any;
}>();

defineEmits([]);

const activeConversation = ref<any>();
const isShowMsgTaskDetailByCode = ref<string>();

const isPersonalModel = computed<boolean>(
  () => props.organization?.model === 'PERSONAL'
);
// const myUserId = computed(() => myProfileStore().myProfile?.id);
const userConversationIds = computed(
  () => orgNotifGlobalStore(props.organization?.id).userConversationIds
);
const conversationById = computed(
  () => orgNotifGlobalStore(props.organization?.id).conversationById
);
const isLoadingConversations = computed(
  () => !orgNotifGlobalStore(props.organization?.id).usersChatInfo
);
const activeConversationInfo = computed(() =>
  orgNotifGlobalStore(props.organization?.id).getConversationInfo(
    activeConversation.value?.id
  )
);

const members = computed(() =>
  orgNotifGlobalStore(props.organization?.id).getMembers(
    activeConversation.value?.id
  )
);

const orgUsers = computed(
  () => orgNotifGlobalStore(props.organization?.id).allOrgUsers
);

const orgGroups = computed(
  () => orgNotifGlobalStore(props.organization?.id).allOrgGroups
);

const orgDomains = computed(
  () => orgNotifGlobalStore(props.organization?.id).orgDomains
);

const orgProjects = computed(
  () => orgNotifGlobalStore(props.organization?.id).orgProjects
);

const activeConversationContact = computed(() =>
  activeConversationInfo.value?.isGroup
    ? orgGroups.value?.find(
        (g) => g?.conversationId == activeConversationInfo.value?.id
      )
    : orgUsers.value?.find(
        (u) => u?.conversationId == activeConversationInfo.value?.id
      )
);

// const currentOrgId = getCurrentOrganizationId();
const chatMsgAllowedActions = [
  ChatMessageAction.REPLY,
  ChatMessageAction.REACTION,
  ChatMessageAction.PIN,
  ChatMessageAction.COPY,
  ChatMessageAction.SET_BACKGROUND,
  ChatMessageAction.DELETE,
  ChatMessageAction.DOWNLOAD_FILE,
];

watch(
  () => props.organization?.id,
  (organizationId, oldOrganizationId) => {
    _clearData(oldOrganizationId);
    _initData(organizationId);
  }
);

watch(
  () => [props.organization, orgUsers.value],
  ([organization]) => _verifyToAutoAddPersonalChat(organization)
);

onMounted(async () => {
  _initData(props.organization?.id);
  _verifyToAutoAddPersonalChat(props.organization);
});

onUnmounted(() => {
  _clearData(props.organization?.id);
});

const onConversationClick = (converId) => {
  activeConversation.value = {
    id: converId,
    orgId: conversationById.value[converId]?.externalChat
      ? chatExternalStore().externalOrgId
      : conversationById.value[converId]?.supportingChat
      ? chatSupportingStore().supportingOrgId
      : props.organization?.id,
  };
};

const onMessageTaskClick = (task) => {
  isShowMsgTaskDetailByCode.value = task?.code;
};

const onSetUserConversationSeenAll = (converId) => {
  return chatStore().setSeenUserConversation(props.organization?.id, converId);
};

const _initData = (orgId) => {
  const orgStore = orgNotifGlobalStore(orgId);

  orgStore.getUserConversations().then(
    () => {}
    // Reset user unseen conversation
    // ChatService.resetUserUnSeenConversations(orgId, myUserId.value)
  );

  orgStore.getUsersGroupsForChat();
};

const _clearData = (orgId) => {
  activeConversation.value = null;

  orgNotifGlobalStore(orgId).clearChatData();
};

const _verifyToAutoAddPersonalChat = (organization) => {
  if (organization?.model === 'PERSONAL') {
    orgNotifGlobalStore(organization?.id).autoAddCurrentUserChat();
  }
};
</script>

<template>
  <div class="h-full flex">
    <!--CONVERSATIONS LIST-->
    <div class="flex-1 overflow-hidden">
      <ConversationsList
        :active-conversation="activeConversation"
        :organization-id="organization?.id"
        :members="members"
        :conversation-by-id="conversationById"
        :conversation-ids="userConversationIds"
        :is-personal-org="isPersonalModel"
        :is-loading="isLoadingConversations"
        @on-conversation-click="onConversationClick"
      />
    </div>

    <!--CHAT PANEL-->
    <div class="h-full w-2/3 flex flex-col overflow-hidden border-l">
      <!--      <SwitchToOrg-->
      <!--        v-if="organization?.id !== currentOrgId"-->
      <!--        :organization="organization"-->
      <!--      />-->

      <div class="flex-1 overflow-hidden">
        <ChatPanel
          v-if="activeConversationInfo"
          ref="chatPanelRef"
          size="lg"
          :organization-id="activeConversation?.orgId"
          :conversation-id="activeConversation?.id"
          :conversation="activeConversationInfo"
          :contact="activeConversationContact"
          :users="orgUsers"
          :allowed-actions="chatMsgAllowedActions"
          :is-loading="isLoadingConversations"
          @on-message-task-click="onMessageTaskClick"
          @on-set-user-conversation-seen-all="onSetUserConversationSeenAll"
        />
      </div>
    </div>
  </div>

  <ModalNotifTaskDetail
    v-if="isShowMsgTaskDetailByCode"
    :organization-id="organization?.id"
    :task-code="isShowMsgTaskDetailByCode"
    :users="orgUsers"
    :groups="orgGroups"
    :domains="orgDomains"
    :projects="orgProjects"
    :is-org-personal="isPersonalModel"
    @on-close="isShowMsgTaskDetailByCode = null"
  />
</template>
