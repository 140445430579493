<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 469.33333 469"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m453.332031 138.832031h-437.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h437.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m325.332031 245.5h-309.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h309.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m453.332031 352.167969h-437.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h437.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
      ></path>
    </g>
  </svg>
</template>
