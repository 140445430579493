<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    fill-rule="evenodd"
    class=""
  >
    <g>
      <path
        d="M13 19h2a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2h2V5H5v2a1 1 0 0 1-2 0V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V5h-6z"
      ></path>
    </g>
  </svg>
</template>
