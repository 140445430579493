<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { useResizeObserver } from '@vueuse/core';
import { onMounted, onUnmounted, ref, computed } from 'vue';
import TabContainer from '@/ui/common/molecules/SynTab/TabContainer.vue';
import taskDetailStore from '@/store/task/detail';
import {
  ITaskTodo,
  ITaskTodoItemStatus,
} from '@/application/types/task/task.types';
import TaskTodoListCreate from '@/ui/modules/task/components/check-list/TaskTodoListCreate.vue';
import TaskTodoListDetail from '@/ui/modules/task/components/check-list/TaskTodoListDetail.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import taskStore from '@/store/task';
import { ITaskConfigs } from '@/application/types/task/task-configs.types';

const CURRENT_COMPONENT_ID = 'task-todo-list-all';
const props = defineProps<{
  taskId?: number;
  taskCheckList?: any;
  todoList?: any;
  readonly?: boolean;
  isSharing?: boolean;
  organizationId?: number;
}>();
const emit = defineEmits(['update:totalTodoList', 'onCreateSuccess', 'onSave']);

const _taskDetailStore = taskDetailStore();
const _taskStore = taskStore();

const taskConfigs = computed<ITaskConfigs>(() => {
  return _taskStore.dataConfigs;
});
const maxTodoList = computed<number>(() => {
  return taskConfigs.value?.checkList?.maxTotalItems || 5;
});

const multipleTodoList = ref<ITaskTodo[]>([]);

const recentlyTodoList = computed<ITaskTodo[]>(() =>
  multipleTodoList.value?.slice(0, 5)
);

const moreTodoList = computed<ITaskTodo[]>(() =>
  multipleTodoList.value?.slice(5, multipleTodoList.value?.length || 0)
);

const currentTodoList = ref<ITaskTodo | null>();

const currentInMoreList = computed<boolean>(() => {
  return moreTodoList.value?.some(
    (data) => data?.id == currentTodoList.value?.id
  );
});

const isLoading = ref<boolean>(false);

const taskTodoListAllRef = ref<any>();
useResizeObserver(taskTodoListAllRef, (entries) => {
  const entry = entries[0];
  // const { width, height } = entry.contentRect
  // text.value = `width: ${width}, height: ${height}`
  const hasVerticalScrollbar =
    entry?.target?.scrollHeight > entry?.target?.clientHeight;

  if (hasVerticalScrollbar) {
    stopBubbleEvent();
  } else {
    openBubbleEvent();
  }
});

const initComponent = async (taskId?) => {
  isLoading.value = true;

  const currentTaskId = taskId || props.taskId;

  if (props.taskCheckList) {
    multipleTodoList.value = props.taskCheckList?.taskTodos || [];
  } else if (props.todoList) {
    multipleTodoList.value = props.todoList || [];
  } else if (!props.isSharing) {
    const res = await _taskDetailStore.getTaskCheckListByTaskId(
      currentTaskId,
      props.organizationId
    );

    multipleTodoList.value = res?.result?.taskTodos || [];

    emit('onSave', multipleTodoList.value);
  }

  currentTodoList.value =
    multipleTodoList.value?.length > 0 ? multipleTodoList.value[0] : null;
  isLoading.value = false;

  emit('update:totalTodoList', multipleTodoList.value?.length);

  if (currentTaskId) onLoadCache(currentTaskId);
};

const taskTodoListDetailRef = ref<any>();
const onSwitchTab = (data) => {
  currentTodoList.value = data;
};
const onCloseTab = async (data) => {
  if (data?.isDraft) {
    removeTodoListParent(data);
  } else {
    settingGlobalModal({
      type: 'notification',
      title: '',
      content:
        translate('TASK_TODO_LIST_CONFIRM_REMOVE', {
          name: data?.title || '',
        }) || 'Do you want to remove this tab',
      confirmLabel: translate('COMMON_LABEL_CONFIRM'),
      confirmable: true,
      closeable: true,
      cancelLabel: translate('COMMON_LABEL_CANCEL'),
    });
    const answer = await ask();
    if (answer) {
      _taskDetailStore.removeTaskCheckList(
        {
          taskId: props.taskId,
          taskTodoIds: [data?.id],
        },
        props.organizationId
      );

      removeTodoListParent(data);
    }
  }
};

const isAddNew = ref<boolean>(false);
const onClickNewTab = () => {
  if (multipleTodoList.value?.length == maxTodoList.value) return;

  isAddNew.value = true;

  const lang = getLocalStorage(StorageConstant.LANGUAGE);
  const namePrefix =
    lang == 'en'
      ? 'Check list'
      : lang == 'fr'
      ? 'Liste de contrôle'
      : 'Việc cần làm';

  currentTodoList.value = {
    id: uuidv4(),
    title: `${namePrefix}${
      multipleTodoList.value?.length > 0
        ? ' ' + multipleTodoList.value?.length
        : ''
    }`,
    description: '',
    isDraft: true,
    todoItems: [
      {
        id: uuidv4(),
        title: '',
        shortDescription: '',
        description: '',
        status: ITaskTodoItemStatus.PENDING,
        index: 0,
      },
    ],
  };

  multipleTodoList.value = [currentTodoList.value, ...multipleTodoList.value];

  onUpdateCache();
};

const removeTodoListParent = (todoList) => {
  multipleTodoList.value = multipleTodoList.value?.filter(
    (o) => o.id !== todoList?.id
  );
  onUpdateCache();

  if (currentTodoList.value?.id == todoList?.id) {
    currentTodoList.value =
      multipleTodoList.value?.length > 0 ? multipleTodoList.value[0] : null;
  }
};

const onCancelCreate = () => {
  removeTodoListParent(currentTodoList.value);

  onUpdateCache();
};

const onCreateSuccess = () => {
  if (!currentTodoList.value) return;
  currentTodoList.value.isDraft = false;

  onUpdateCache();
  emit('onCreateSuccess', multipleTodoList.value);
};

const stopPropagation = (event) => {
  console.log('🚀 Tictop ~ event:', event);
  // event.stopPropagation();
};

const stopBubbleEvent = () => {
  const divEl = document.getElementById(CURRENT_COMPONENT_ID);
  if (!divEl) return;
  divEl.addEventListener('scroll', (event) => {
    stopPropagation(event);
  });
  divEl.addEventListener('wheel', (event) => {
    stopPropagation(event);
  });
  divEl.addEventListener('mousewheel', (event) => {
    stopPropagation(event);
  });
};
const openBubbleEvent = () => {
  const divEl = document.getElementById(CURRENT_COMPONENT_ID);
  if (!divEl) return;
  divEl.removeEventListener('scroll', stopPropagation);
  divEl.removeEventListener('wheel', stopPropagation);
  divEl.removeEventListener('mousewheel', stopPropagation);
};

onMounted(() => {
  initComponent();

  // stopBubbleEvent();
});
onUnmounted(() => {
  openBubbleEvent();
});

const onLoadCache = (taskId) => {
  const currentTaskCacheState = _taskDetailStore.cacheStateById[taskId];

  if (currentTaskCacheState?.draftTodoList?.length > 0) {
    multipleTodoList.value = [
      ...currentTaskCacheState?.draftTodoList,
      ...multipleTodoList.value,
    ];

    currentTodoList.value = multipleTodoList.value[0];
  }
};
const onUpdateCache = () => {
  _taskDetailStore.setCacheState(props.taskId, {
    draftTodoList: multipleTodoList.value?.filter(
      (todoList) => todoList?.isDraft
    ),
  });
  emit('update:totalTodoList', multipleTodoList.value?.length);
};

const updateTodoListFromCreate = (todoList) => {
  const index = multipleTodoList.value?.findIndex((o) => o?.id == todoList?.id);
  if (index > -1) {
    multipleTodoList.value[index] = todoList;
    onUpdateCache();
  }
};

const isShowHiddenTab = ref<boolean>(false);

const onSaveDetail = (newTodoList) => {
  const index = multipleTodoList.value?.findIndex(
    (o) => o?.id == newTodoList?.id
  );
  if (index > -1) {
    multipleTodoList.value[index] = newTodoList;
    emit('onSave', multipleTodoList.value);
  }
};

defineExpose({
  onClickNewTab,
  initComponent,
});
</script>
<template>
  <div :id="CURRENT_COMPONENT_ID" class="w-full h-full">
    <div
      v-if="multipleTodoList?.length > 0 && !isLoading"
      class="w-full border rounded-md border-gray-300 relative flex flex-col"
      style="max-height: 100%"
    >
      <span
        v-if="multipleTodoList?.length == 1 && !readonly"
        class="
          absolute
          -top-2
          -right-2
          z-10
          w-6
          h-6
          rounded-full
          flex-center
          hover:text-black hover:bg-gray-300
          bg-gray-100
          cursor-pointer
          task-todo-list-all_hover-to-show
        "
        @click.stop="onCloseTab(currentTodoList)"
      >
        <SynIcon name="trash" custom-class="w-4 h-4 fill-red-500" />
      </span>
      <TabContainer class="rounded-md">
        <template #actions><span></span></template>
        <template v-if="multipleTodoList?.length > 1" #header>
          <div
            class="w-full flex-1 flex list-tab-container bg-gray-50"
            style="height: 2rem"
          >
            <!--            <div-->
            <!--              v-if="multipleTodoList?.length < maxTodoList && !readonly"-->
            <!--              class="h-8 w-8 flex-center hover:bg-current-50 cursor-pointer"-->
            <!--              @click.stop="onClickNewTab"-->
            <!--            >-->
            <!--              <SynIcon-->
            <!--                class="fill-current"-->
            <!--                custom-class="h-3 w-3"-->
            <!--                name="plus"-->
            <!--              />-->
            <!--            </div>-->
            <li
              v-for="data in recentlyTodoList"
              :key="data?.id"
              v-vig-tooltip="data?.title || ''"
              :class="[
                data?.id == currentTodoList?.id
                  ? 'bg-white font-semibold text-current'
                  : 'text-current-900 bg-gray-200 hover:bg-gray-100',
              ]"
              class="
                min-w-0
                hover-to-show__parent
                flex-center
                shadow-sm
                cursor-pointer
                relative
              "
              @click="onSwitchTab(data)"
              @auxclick="onCloseTab(data)"
            >
              <span
                class="px-2 truncate"
                :class="{
                  'text-gray-300': !data?.title,
                }"
                style="min-width: 3rem"
              >
                {{ data?.title || 'Title' }}
              </span>

              <span
                v-if="data?.isDraft"
                class="
                  absolute
                  top-0
                  right-0
                  w-2
                  h-2
                  rounded-full
                  bg-orange-500
                "
              >
              </span>
              <span
                v-if="!readonly"
                class="
                  w-4
                  h-4
                  rounded-full
                  bg-white
                  flex-center
                  hover:text-black hover:bg-gray-300
                "
                :class="[
                  data?.id == currentTodoList?.id
                    ? 'mr-1'
                    : 'hover-to-show__children absolute right-1',
                ]"
                @click.stop="onCloseTab(data)"
              >
                <SynIcon name="close" custom-class="w-3 h-3" />
              </span>
            </li>
            <VigDropdown
              v-if="moreTodoList?.length > 0"
              ref="hiddenTabDropdownRef"
              class="flex-1"
              :arrow="false"
              :z-index="60"
              @on-dropdown-open="isShowHiddenTab = true"
              @on-dropdown-close="isShowHiddenTab = false"
            >
              <template #dropdown-toggle>
                <div
                  :title="`${moreTodoList?.length} Other`"
                  class="
                    w-full
                    cursor-pointer
                    flex-center
                    space-x-2
                    shadow-sm
                    text-gray-700
                    hover:bg-gray-100
                    relative
                    px-2
                  "
                  :class="currentInMoreList ? 'bg-white' : 'bg-gray-200'"
                  style="min-width: 6rem; height: 2rem"
                >
                  <span v-if="currentInMoreList">
                    {{ currentTodoList?.title }}
                  </span>
                  <span v-else>
                    {{ $t('NOTIFICATION_LABEL_TYPE_OTHER') }} ({{
                      moreTodoList?.length
                    }})
                  </span>
                  <SynIcon
                    :name="'sort-down'"
                    custom-class="h-2.5 w-2.5 fill-gray-500"
                    :class="!isShowHiddenTab ? '-rotate-90' : ''"
                  />

                  <span
                    v-if="currentInMoreList && currentTodoList?.isDraft"
                    class="
                      absolute
                      top-0
                      right-0
                      w-2
                      h-2
                      rounded-full
                      bg-orange-500
                    "
                  >
                  </span>
                </div>
              </template>
              <template #dropdown-menu>
                <div
                  class="
                    flex flex-col
                    rounded-md
                    space-y-1
                    overflow-auto
                    small-scrollbar
                  "
                  style="max-height: 620px; width: 8rem"
                >
                  <div
                    v-for="tab in moreTodoList"
                    :key="tab?.id"
                    :title="tab?.title"
                    class="
                      flex
                      items-center
                      px-4
                      py-2
                      hover:bg-gray-100
                      rounded
                      relative
                      cursor-pointer
                      hover-to-show__parent
                      dropdown-item
                    "
                    @click="onSwitchTab(tab)"
                    @auxclick="onCloseTab(tab)"
                  >
                    <span class="text-sm text-gray-400 truncate">
                      {{ tab?.title }}
                    </span>

                    <span
                      class="
                        hover-to-show__children
                        absolute
                        right-1
                        flex-center
                        h-5
                        w-5
                        hover:bg-gray-200
                        rounded-full
                        cursor-pointer
                      "
                      @click.stop="onCloseTab(tab?.id)"
                    >
                      <SynIcon name="close" />
                    </span>

                    <span
                      v-if="tab?.isDraft"
                      class="
                        h-2
                        w-2
                        absolute
                        top-1
                        left-1
                        rounded-full
                        bg-orange-500
                      "
                    >
                    </span>
                  </div>
                </div>
              </template>
            </VigDropdown>
          </div>
        </template>

        <template #body>
          <div
            ref="taskTodoListAllRef"
            class="flex-1 min-h-0 flex flex-col"
            style="max-height: 100%"
          >
            <template v-if="currentTodoList?.id">
              <TaskTodoListCreate
                v-if="currentTodoList?.isDraft"
                :task-id="taskId"
                :todo-list="currentTodoList"
                :readonly="readonly"
                :organization-id="organizationId"
                :show-add-new-button="multipleTodoList?.length < maxTodoList"
                @on-cancel="onCancelCreate"
                @on-create-success="onCreateSuccess"
                @update:todo-list="updateTodoListFromCreate"
                @on-add-new="onClickNewTab"
              />
              <TaskTodoListDetail
                v-else
                ref="taskTodoListDetailRef"
                :task-id="taskId"
                :todo-list="currentTodoList"
                :readonly="readonly"
                :show-add-new-button="multipleTodoList?.length < maxTodoList"
                :organization-id="organizationId"
                @on-add-new="onClickNewTab"
                @on-save="onSaveDetail"
              />
            </template>
          </div>
        </template>
      </TabContainer>
    </div>
  </div>
</template>
<style>
.task-todo-list-all_hover-to-show {
  visibility: hidden;
}

#task-todo-list-all:hover .task-todo-list-all_hover-to-show {
  visibility: unset;
}
</style>
