<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 551.131 551.131"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="m413.285 526.7 113.411-113.412 24.35 24.35-113.41 113.412zM206.643 526.699l320.053-320.053 24.35 24.35L230.994 551.05zM0 526.697 526.696 0l24.35 24.35L24.35 551.047z"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
