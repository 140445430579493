<script setup lang="ts">
import { onMounted, ref } from 'vue';
import {
  browserPermissionState,
  handleRequestNotificationPermission,
} from '@/ui/app-global-state';
import { PermissionState } from '@/ui/hooks/navigatorPermission';
import {
  getLocalStorage,
  setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';

const HIDDEN_REQUEST_MODAL_IN_MILLISECONDS = 5 * 24 * 60 * 60 * 1000;
const GUID_LINK = 'https://support.google.com/chrome/answer/114662';

const isOpenWarningGrandNotification = ref(false);
const setExpireRequestPermissionTime = () => {
  setLocalStorage(StorageConstant.EXPIRE_REQUEST_PERMISSION_AT, Date.now());
};

const isOpenGuideModal = ref<boolean>(false);

const onOpenGrandNotification = async () => {
  setExpireRequestPermissionTime();
  const permission = await handleRequestNotificationPermission();
  if (permission !== PermissionState.granted) {
    isOpenGuideModal.value = true;
  }

  isOpenWarningGrandNotification.value = false;
};

onMounted(() => {
  isOpenWarningGrandNotification.value = true;
  const expireRequestPermissionTime =
    parseInt(
      getLocalStorage(StorageConstant.EXPIRE_REQUEST_PERMISSION_AT) || ''
    ) || 0;

  if (
    expireRequestPermissionTime > 0 &&
    Date.now() - expireRequestPermissionTime <
      HIDDEN_REQUEST_MODAL_IN_MILLISECONDS
  ) {
    return;
  }
  setTimeout(() => {
    isOpenWarningGrandNotification.value = true;
  }, 2000);
});

const onClose = () => {
  setExpireRequestPermissionTime();
  isOpenWarningGrandNotification.value = false;
};

const onConfirm = () => {
  // goto('https://support.google.com/chrome/answer/114662');
  window.open(GUID_LINK, '_blank');
  isOpenGuideModal.value = false;
};
</script>

<template>
  <AtomDialog
    v-if="browserPermissionState.notification !== PermissionState.granted"
    :dimension="
      isOpenWarningGrandNotification
        ? {
            bottom: '10px',
            right: `10px`,
          }
        : { bottom: '10px', right: `-10px` }
    "
    class="transition duration-500 transform"
    :class="[
      isOpenWarningGrandNotification
        ? 'translate-x-0 ease-out'
        : 'translate-x-96 ease-in',
    ]"
  >
    <template #body>
      <div class="px-8 py-6 rounded-md flex flex-col border relative w-96">
        <button
          :title="$t('COMMON_LABEL_CANCEL')"
          class="
            w-6
            h-6
            rounded-full
            cursor-pointer
            hover:text-current-500 hover:bg-gray-100
            absolute
            top-1
            right-1
            flex-center
            vig-modal-close
          "
          @click="onClose"
        >
          <SynIcon name="close" custom-class="w-4 h-4" />
        </button>
        <div class="flex-center space-x-4">
          <span>
            <SynIcon name="tictop-logo" custom-class="w-10 h-10" />
          </span>
          <span class="text-sm">
            {{
              $t('REQUEST_PERMISSION_OPEN_NOTIFICATION') ||
              'Turn on notification'
            }}
          </span>
        </div>

        <div class="w-full flex-center space-x-2 pt-6">
          <SynButton
            :label="$t('COMMON_LABEL_SKIP') || 'Skip'"
            class="text-sm w-1/2 bg-gray-400 hover:bg-gray-500"
            @click="onClose"
          />
          <SynButton
            :label="
              $t('COMMON_LABEL_TURN_ON_NOTIFICATION') || 'Turn on notification'
            "
            class="text-sm w-1/2"
            @click="onOpenGrandNotification"
          />
        </div>
      </div>
    </template>
  </AtomDialog>

  <SynModal
    v-if="isOpenGuideModal"
    container-class="w-max"
    style="max-width: 90%"
    container-style="min-width: 28rem"
    is-hidden-header
    :confirm-text="$t('COMMON_LABEL_GOT_IT')"
    :cancel-text="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
    z-index="z-100"
    @cancel="isOpenGuideModal = false"
    @confirm="onConfirm"
  >
    <template #body>
      <div class="flex-center flex-col h-full" style="max-width: 30rem">
        <div class="w-full flex items-center justify-center flex-col pt-4">
          <syn-animation name="infomation" stype="width: 75px;" class="pb-2" />
          <span>
            {{
              $t('REQUEST_PERMISSION_YOU_HAVE_BLOCK_BEFORE') ||
              'You have block notification before'
            }}
          </span>
        </div>
      </div>
    </template>
  </SynModal>
</template>
