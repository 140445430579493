<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import domainStore from '@/store/scope';
import DomainById from '@/ui/modules/domains/component/DomainById.vue';
import ProjectById from '@/ui/modules/domains/component/ProjectById.vue';

const props = withDefaults(
  defineProps<{
    domainDetail: any;
    itemActiveKey?: string;
    isReadonly?: boolean;
    isShowChildrenDefault?: boolean;
    projectIds?: number[];
    isShowAll?: boolean;
  }>(),
  {
    isShowChildrenDefault: true,
  }
);

const emit = defineEmits<{
  (
    e: 'changeItemActive',
    key: string,
    id: number | string,
    parentId: number | string
  ): void;
  (e: 'onClickAddNewProject'): void;
  (e: 'onClickAddNewSubProject', parentId: number | string): void;
}>();
const _domainStore = domainStore();

const domainByIds = computed(() => _domainStore.domainByIds);
const projectByIds = computed(() => _domainStore.projectByIds);
const domainOriginalDetail = computed(
  () => domainByIds.value[props.domainDetail?.id]
);

onMounted(() => {});

const isShowChildren = ref<boolean>(!!props.isShowChildrenDefault);
const changeViewMode = (key, id, parentId?) => {
  isShowChildren.value = true;

  emit('changeItemActive', key, id, parentId);
};
const onOpenAddNewProject = () => {
  emit('onClickAddNewProject');
};
const onOpenAddNewSubProject = (parentId?) => {
  emit('onClickAddNewSubProject', parentId);
};

const currentProjectActive = ref(0);

const onChangeProjectActive = (projectId, isOpen) => {
  currentProjectActive.value = !isOpen ? projectId : 0;
  changeViewMode('project', projectId);
};

const currentSubProjectActive = ref(0);

const onChangeSubProjectActive = (id, parentId) => {
  currentSubProjectActive.value = id;
  changeViewMode('sub_project', id, parentId);
};

const onClickToggleIcon = () => {
  isShowChildren.value = !isShowChildren.value;
};

const projectList = computed<any[]>(() => {
  return domainOriginalDetail.value?.projects?.filter(
    (projectId) =>
      props.isShowAll ||
      props.domainDetail?.projects?.some((id) => id == projectId)
  );
});
</script>
<template>
  <AtomTree
    :is-show-children="isShowChildren"
    :is-active="itemActiveKey == `domain_${domainDetail?.id}`"
    @on-click-parent="changeViewMode('domain', domainDetail?.id)"
    @on-click-toggle-icon="onClickToggleIcon"
  >
    <template #parent>
      <div
        class="w-full flex items-center justify-between space-x-2 text-current"
      >
        <DomainById :domain-id="domainDetail?.id" />

        <span
          v-if="domainDetail?.countActiveTasks > 0"
          v-vig-tooltip="
            $t('TASK_LABEL_THIS_IS_THE_TOTAL_NUMBER_OF_TASKS_IN_THIS_PROJECT')
          "
          class="px-1 rounded-full text-xs"
          style="font-size: 10.5px"
          :style="
            itemActiveKey == `domain_${domainDetail?.id}`
              ? `background-color: #008E8E; color: white;`
              : ''
          "
          :class="
            itemActiveKey == `domain_${domainDetail?.id}`
              ? ''
              : 'text-white bg-gray-400'
          "
        >
          {{ domainDetail?.countActiveTasks }}
        </span>
      </div>
    </template>
    <template #children>
      <div class="w-full flex flex-col text-sm gap-0.5">
        <template v-if="projectList?.length > 0">
          <AtomTree
            v-for="(projectId, index) in projectList"
            :key="index"
            :is-active="itemActiveKey == `project_${projectId}`"
            :is-show-children="currentProjectActive == projectId"
            @on-click-parent="
              (value) => onChangeProjectActive(projectId, value)
            "
            @on-click-toggle-icon="
              (value) =>
                onChangeProjectActive(
                  projectId,
                  currentProjectActive == projectId
                )
            "
          >
            <template #parent>
              <div
                class="
                  w-full
                  flex
                  items-center
                  justify-between
                  space-x-2
                  text-current
                "
              >
                <ProjectById
                  :project-id="projectId"
                  class="rounded text-blue-500"
                />

                <span
                  v-if="projectByIds[projectId]?.countActiveTasks > 0"
                  v-vig-tooltip="
                    $t(
                      'TASK_LABEL_THIS_IS_THE_TOTAL_NUMBER_OF_TASKS_IN_THIS_PROJECT'
                    )
                  "
                  class="px-1 rounded-full text-xs"
                  style="font-size: 10.5px"
                  :style="
                    itemActiveKey == `project_${projectId}`
                      ? `background-color: #3b82f6; color: white;`
                      : ''
                  "
                  :class="
                    itemActiveKey == `project_${projectId}`
                      ? ''
                      : 'text-white bg-gray-400'
                  "
                >
                  {{ projectByIds[projectId]?.countActiveTasks }}
                </span>
              </div>
            </template>
            <template #children>
              <div class="w-full flex flex-col text-xs">
                <template
                  v-if="
                    projectByIds[projectId]?.subProjects?.filter(
                      (projectId) =>
                        isShowAll ||
                        props.domainDetail?.projects?.some(
                          (id) => id == projectId
                        )
                    )?.length > 0
                  "
                >
                  <AtomTree
                    v-for="(subProjectId, i) in projectByIds[
                      projectId
                    ]?.subProjects?.filter(
                      (projectId) =>
                        isShowAll ||
                        props.domainDetail?.projects?.some(
                          (id) => id == projectId
                        )
                    )"
                    :key="i"
                    :is-have-children="false"
                    :is-active="itemActiveKey == `sub_project_${subProjectId}`"
                    @on-click-parent="
                      onChangeSubProjectActive(subProjectId, projectId)
                    "
                  >
                    <template #parent>
                      <div
                        class="
                          w-full
                          flex
                          items-center
                          justify-between
                          space-x-2
                          text-current
                        "
                      >
                        <ProjectById
                          :project-id="subProjectId"
                          class="text-orange-500"
                        />

                        <span
                          v-if="
                            projectByIds[subProjectId]?.countActiveTasks > 0
                          "
                          v-vig-tooltip="
                            $t(
                              'TASK_LABEL_THIS_IS_THE_TOTAL_NUMBER_OF_TASKS_IN_THIS_PROJECT'
                            )
                          "
                          class="px-1 rounded-full text-xs"
                          style="font-size: 10.5px"
                          :style="
                            itemActiveKey == `sub_project_${subProjectId}`
                              ? `background-color: #f97316; color: white;`
                              : ''
                          "
                          :class="
                            itemActiveKey == `sub_project_${subProjectId}`
                              ? ''
                              : 'text-white bg-gray-400'
                          "
                        >
                          {{ projectByIds[subProjectId]?.countActiveTasks }}
                        </span>
                      </div>
                    </template>
                  </AtomTree>
                </template>
                <div v-if="!isReadonly" class="w-full flex justify-start pt-2">
                  <span class="w-4"></span>
                  <div
                    class="
                      h-8
                      flex
                      items-center
                      space-x-2
                      border border-dashed border-orange-500
                      px-2
                      text-orange-500
                      rounded-md
                      hover:bg-orange-100
                      cursor-pointer
                    "
                    @click.stop="onOpenAddNewSubProject(projectId)"
                  >
                    <div class="h-4 w-4 rounded-full flex-center bg-orange-500">
                      <SynIcon custom-class="h-2 w-2 fill-white" name="plus" />
                    </div>
                    <span class="text-overflow-hidden-line">
                      {{
                        $t('DOMAIN_LABEL_CREATE_SUB_SUB_DOMAIN') ||
                        'Add sub sub domain/project'
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </AtomTree>
        </template>
        <div v-if="!isReadonly" class="w-full flex justify-start">
          <span class="w-4"></span>
          <div
            class="
              mt-2
              h-8
              flex
              items-center
              space-x-2
              border border-dashed border-blue-500
              px-2
              text-blue-500
              rounded-md
              hover:bg-blue-100
              cursor-pointer
            "
            @click.stop="onOpenAddNewProject"
          >
            <div class="h-4 w-4 rounded-full flex-center bg-blue-500">
              <SynIcon custom-class="h-2 w-2 fill-white" name="plus" />
            </div>
            <span>
              {{
                $t('DOMAIN_LABEL_CREATE_SUB_DOMAIN') || 'Add sub domain/project'
              }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </AtomTree>
</template>
