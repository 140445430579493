<script lang="ts" setup>
import { onBeforeMount, onMounted } from 'vue';
import PostMessageService from '@/application/services/PostMessageService';
import { AppMessageTypeEnum } from '@/domain/enums/app-message-type.enum';
import { initializeTwemoji } from '@/ui/plugins/twemoji/twemoji';

onBeforeMount(() => {
  initializeTwemoji();
});

onMounted(() => {
  PostMessageService.getInstance().postMessageToParent({
    type: AppMessageTypeEnum.tictopDocumentLoad,
  });
});
</script>

<template>
  <router-view />
</template>
