<script setup lang="ts">
import chatSupportingStore from '@/store/chat/chat-supporting';
import ChatService from '@/application/services/ChatService';
import { computed, onMounted, ref } from 'vue';
import { chain } from 'lodash';
import chatStore from '@/store/chat';
import VigButton from '@/ui/common/atoms/VigButton/VigButton.vue';
import { ChatSupporterStatus } from '@/domain/enums/chat-enums';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const props = defineProps<{
  conversationId: string;
}>();

const emits = defineEmits(['onClose']);

const _chatStore = chatStore();
const _chatSupportingStore = chatSupportingStore();

const converMemberById = ref({});
const orgSupporters = ref();

const availableSupporters = computed(() =>
  orgSupporters.value?.filter(
    (supporter) =>
      !converMemberById.value[supporter?.id] ||
      converMemberById.value[supporter?.id].removed
  )
);

const currentSupporters = computed(() =>
  chain(converMemberById.value)
    .mapValues()
    .filter((member: any) => member?.isSupporter && !member?.removed)
    .sortBy((member: any) =>
      member?.createdDate?.toDate ? member?.createdDate?.toDate() : null
    )
    .value()
);

onMounted(() => {
  _getConverSupporters(props.conversationId);
  _getOrgSupporters();
});

const onAddSupporterToConversation = (supporter) => {
  converMemberById.value[supporter?.id] = {
    ...supporter,
    isSupporter: true,
    isNew: true,
  };
};

const onRemoveSupporterFromConversation = (supporter) => {
  converMemberById.value[supporter?.id] = null;
};

const onSaveClick = async () => {
  const newSupporters = currentSupporters.value?.filter(
    (supporter: any) => supporter?.isNew
  );

  await _chatSupportingStore.addConversationSupporters(
    props.conversationId,
    newSupporters
  );

  emits('onClose');
};

const _getConverSupporters = async (converId) => {
  const converInfo = _chatStore.getConversationInfo(converId);

  converMemberById.value = converInfo?.members || {};
};

const _getOrgSupporters = async () => {
  const orgDoc = await ChatService.getOrgSupportingInfo(
    _chatSupportingStore.supportingOrgId
  );
  const orgInfo = orgDoc?.data();

  orgSupporters.value = chain(orgInfo?.supporting?.supporters)
    .mapValues()
    .filter({ status: ChatSupporterStatus.ACTIVE })
    .value();
};
</script>

<template>
  <syn-modal
    z-index="z-50"
    container-class="w-11/12"
    container-style="max-width: 40rem"
    style-body="p-5 flex flex-col relative"
    :is-hidden-header="true"
    @cancel="$emit('onClose')"
  >
    <template #body>
      <div class="h-full flex gap-5" style="min-height: 30vh">
        <div class="flex-1 overflow-auto small-scrollbar">
          <div class="text-xl mb-3">{{ $t('CHAT_SUPPORTERS_AVAILABLE') }}</div>
          <div
            v-for="supporter in availableSupporters"
            :key="supporter?.id"
            role="button"
            class="p-2 flex items-center gap-3 rounded hover:bg-gray-50"
            @click="onAddSupporterToConversation(supporter)"
          >
            <SynIcon name="Checkbox" class="fill-current" />
            <SynAvatar
              custom-class="w-8 h-8"
              :src="supporter?.avatar"
              :name="supporter?.name"
              has-name
            />
          </div>
          <div v-if="!availableSupporters?.length" class="text-gray-500 italic">
            {{ $t('CHAT_SUPPORTERS_NO_DATA') }}
          </div>
        </div>
        <div class="flex-1 overflow-auto small-scrollbar">
          <div class="text-xl mb-3 text-current-500">
            {{ $t('CHAT_SUPPORTERS_CURRENT') }}
          </div>
          <div
            v-for="supporter in currentSupporters"
            :key="supporter?.id"
            class="
              px-3
              py-2
              mb-1
              flex
              items-center
              justify-between
              gap-2
              rounded
              bg-current-50
            "
          >
            <SynAvatar
              custom-class="w-8 h-8"
              :src="supporter?.avatar"
              :name="supporter?.name"
              has-name
            />
            <VigButton
              v-if="supporter?.isNew"
              ghost
              color="red"
              rounded="rounded-full"
              icon="Close"
              @click="onRemoveSupporterFromConversation(supporter)"
            />
          </div>
          <div v-if="!currentSupporters?.length" class="text-gray-500 italic">
            {{ $t('CHAT_SUPPORTERS_NO_DATA') }}
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="px-4 py-3 flex justify-end items-center space-x-2">
        <VigButton ghost color="gray" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') }}
        </VigButton>
        <VigButton @click="onSaveClick">
          {{ $t('COMMON_LABEL_SAVE') }}
        </VigButton>
      </div>
    </template>
  </syn-modal>
</template>
