<script setup lang="ts">
import { ref } from 'vue';
import GroupDetailModal from '@/ui/modules/group/detail/GroupDetailModal.vue';
import { markBackgroundTextSearch } from '@/ui/hooks/commonFunction';

defineProps<{
  groupInfo: any;
  searchText: string;
}>();

defineEmits(['clickName']);

const isOpenDetailGroup = ref(false);
</script>
<template>
  <div class="flex-center space-x-2">
    <div
      class="relative w-16 h-16 flex-center cursor-pointer"
      :title="$t('COMMON_LABEL_PROFILE')"
      @click="isOpenDetailGroup = true"
    >
      <syn-avatar
        custom-class="w-12 h-12"
        :src="groupInfo?.avatarUrl"
        :name="groupInfo?.name"
        @click="isOpenDetailGroup = true"
      />
      <div
        v-if="groupInfo?.code == 'DELETED_GROUP'"
        class="
          bg-white
          flex-center
          rounded-full
          absolute
          right-2
          bottom-2
          w-6
          h-6
        "
      >
        <SynIcon
          class="fill-current"
          custom-class="w-2.5 h-2.5"
          name="cancel"
        />
      </div>
      <div
        v-if="groupInfo?.isMember && groupInfo?.code !== 'DELETED_GROUP'"
        class="
          bg-white
          flex-center
          rounded-full
          absolute
          right-1
          bottom-2
          w-5
          h-5
        "
      >
        <SynIcon
          class="fill-current"
          custom-class="w-3.5 h-3.5"
          name="user-check"
        />
      </div>
    </div>

    <div
      class="
        font-semibold
        cursor-pointer
        text-current-700 text-md
        flex
        space-x-2
        items-center
      "
      @click="$emit('clickName', true)"
    >
      <span
        :title="groupInfo?.name"
        class="text-overflow-hidden-line"
        v-html="markBackgroundTextSearch(groupInfo?.name, searchText)"
      ></span>

      <div
        :title="
          groupInfo?.isPrivate
            ? $t('COMMON_LABEL_GROUP_PRIVATE')
            : $t('COMMON_LABEL_GROUP_PUBLIC')
        "
        class="flex-center"
      >
        <SynIcon
          class="fill-current"
          custom-class="w-2.5 h-2.5"
          :name="groupInfo?.isPrivate ? 'private' : 'global'"
        />
      </div>
    </div>
  </div>
  <!-- <syn-preview-doc
    v-if="currentPreview && currentPreview.avatarUrl"
    :src="currentPreview.avatarUrl"
    is-image
    @close-preview="closePreview"
  ></syn-preview-doc> -->

  <GroupDetailModal
    v-if="isOpenDetailGroup"
    :group-id="groupInfo?.id"
    position="members"
    @cancel="isOpenDetailGroup = false"
  />
</template>
