<template>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g clip-path="url(#clip40_28)">
      <ellipse
        id="circle"
        cx="87.833313"
        cy="159.900024"
        rx="60.595238"
        ry="83.833321"
        fill-opacity="1.000000"
      />
      <ellipse
        id="circle"
        cx="427.166626"
        cy="159.900024"
        rx="60.595238"
        ry="83.833321"
        fill-opacity="1.000000"
      />
      <ellipse
        id="circle"
        cx="257.500000"
        cy="92.833374"
        rx="60.595238"
        ry="83.833321"
        fill-opacity="1.000000"
      />
      <path
        id="path"
        d="M366.57 327.56L366.57 495.23C366.59 506.44 362.55 512.03 354.45 512L160.54 512C152.44 512.03 148.4 506.44 148.42 495.23L148.42 327.56C148.42 312 150.58 297.03 154.88 282.65C159.19 268.27 165.32 255.58 173.27 244.57C181.23 233.57 190.4 225.09 200.79 219.13C211.19 213.17 222.01 210.2 233.26 210.2L281.73 210.2C292.98 210.2 303.8 213.17 314.2 219.13C324.59 225.09 333.77 233.57 341.72 244.57C349.67 255.58 355.8 268.27 360.11 282.65C364.41 297.03 366.57 312 366.57 327.56ZM129.53 280.8C123.83 278.44 118.01 277.26 112.07 277.26L63.59 277.26C55.55 277.26 47.83 279.39 40.4 283.64C32.98 287.9 26.42 293.96 20.74 301.82C15.06 309.68 10.68 318.74 7.61 329.01C4.53 339.28 3 349.98 3 361.1L3 495.23C3 506.41 7.03 512 15.11 512L126.27 512C124.88 506.57 124.18 500.98 124.19 495.23L124.19 327.56C124.19 311.23 126.06 295.52 129.53 280.8ZM388.72 512L499.88 512C507.96 512 512 506.41 512 495.23L512 361.1C512 349.98 510.46 339.28 507.38 329.01C504.31 318.74 499.93 309.68 494.25 301.82C488.57 293.96 482.01 287.9 474.59 283.64C467.17 279.39 459.44 277.26 451.4 277.26L402.92 277.26C396.85 277.26 391 278.5 385.46 280.8C388.93 295.52 390.81 311.23 390.81 327.56L390.81 495.23C390.81 501 390.09 506.66 388.72 512Z"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
      <circle
        id="circle"
        cx="362.000000"
        cy="362.000000"
        r="150.000000"
        fill="#FFFFFF"
        fill-opacity="1.000000"
      />
      <path
        id="path"
        d="M212 362C212 279.15 279.16 212 362 212C444.83 212 512 279.15 512 362C512 444.84 444.83 512 362 512C279.16 512 212 444.83 212 362ZM240.12 362C240.12 429.32 294.68 483.86 362 483.86C429.31 483.86 483.87 429.32 483.87 362C483.87 294.68 429.31 240.12 362 240.12C294.68 240.12 240.12 294.69 240.12 362ZM298.54 356.34L352.74 302.75C357.83 294.49 366.16 294.49 371.24 302.75L425.45 356.34C430.53 364.6 426.36 371.38 416.19 371.38L390.12 371.38L390.12 418.25C390.12 419.5 389.88 420.69 389.41 421.84C388.93 422.99 388.25 424 387.38 424.88C386.5 425.76 385.48 426.44 384.33 426.91C383.18 427.39 381.99 427.63 380.75 427.63L343.24 427.63C342 427.63 340.81 427.39 339.66 426.92C338.51 426.44 337.49 425.76 336.61 424.89C335.73 424.01 335.06 422.99 334.58 421.84C334.11 420.69 333.87 419.5 333.87 418.25L333.87 371.38L307.8 371.38C297.62 371.37 293.46 364.6 298.54 356.34Z"
        fill="#4BB0B2"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
