<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 158 211.89"
  >
    <g>
      <path
        class="cls-1"
        d="M20.21,11.89C9.05,11.89,0,20.94,0,32.1V191.68c0,11.16,9.05,20.21,20.21,20.21H59.79c11.16,0,20.21-9.05,20.21-20.21V11.89H20.21Zm43.79,169.65c0,7.93-6.42,14.35-14.35,14.35H30.35c-7.93,0-14.35-6.42-14.35-14.35V42.24c0-7.93,6.42-14.35,14.35-14.35h33.65V181.54Z"
      />
      <path
        class="cls-1"
        d="M128,70v42.44c0,7.43-6.02,13.45-13.45,13.45h-34.55V27.89h8.06c.3-5.74,1.81-11.15,4.28-16h-28.34V141.89h60c11.05,0,20-8.95,20-20v-55.23c-4.9,2.14-10.31,3.34-16,3.34Z"
      />
    </g>
    <circle class="cls-1 fill-blue-500 text-blue-500" cx="128" cy="30" r="30" />
  </svg>
</template>
