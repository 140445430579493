import { ref, getDatabase, onValue } from 'firebase/database';

class WorkflowDynamicInfoRepository {
    subscribeWorkflowLatestTask(
        orgId: number,
        workflowId: string,
        cb: (val) => void
    ) {
        return onValue(
            this._getRef(orgId, workflowId, 'latestTaskActivity'),
            (data) => cb(data?.val())
        );
    }

    private _getRef(orgId: number, workflowId: string, path: string) {
        return ref(
            getDatabase(),
            `workflows_dynamic_info/organization_${orgId}/workflow_${workflowId}/${path}`
        );
    }
}

export default new WorkflowDynamicInfoRepository();
