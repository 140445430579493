<script setup lang="ts">
import { computed, ref } from 'vue';
import WorkflowInformation from '@/ui/modules/workflow/WorkflowInformation.vue';
import WorkflowEntity, {
  IWorkflowEntity,
} from '@/domain/entities/workflow/WorkflowEntity';
import workflowStore from '@/store/workflow';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';

const props = withDefaults(
  defineProps<{
    defaultData?: IWorkflowEntity;
    viewMode?: 'ADD' | 'EDIT';
  }>(),
  {
    viewMode: 'ADD',
  }
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'createSuccess', newWorkflow: WorkflowEntity): void;
  (e: 'updateSuccess', newWorkflow: WorkflowEntity): void;
}>();
const onCloseModal = () => {
  emit('cancel');
};

const _workflowStore = workflowStore();

const errorMessageCode = ref<string>('');

const isLoadFinishCreate = ref<boolean>(false);

const isDisabledSummit = computed<boolean>(() => {
  return !workflowInformation.value?.name;
});

const workflowInformation = ref<WorkflowEntity>(
  new WorkflowEntity({
    ...(props.defaultData || {}),
    id: props.viewMode == 'ADD' ? '' : props.defaultData?.id,
  })
);

const onSave = async () => {
  try {
    isLoadFinishCreate.value = true;
    const workflowName = workflowInformation.value?.name?.trim();

    let res: any;

    if (props.viewMode == 'ADD') {
      const isExistedWorkflow = _workflowStore.checkNewWorkflowExisted({
        name: workflowName,
        departmentId: workflowInformation.value?.departmentInfo?.id,
        domainId: workflowInformation.value?.domainInfo?.id,
        groupId: workflowInformation.value?.groupInfo?.id,
      });

      let stopCreate = false;
      if (isExistedWorkflow) {
        settingGlobalModal({
          type: 'notification',
          title:
            translate('WORKFLOW_LABEl_HAVE_EXISTED') || 'Workflow has existed',
          content:
            translate('WORKFLOW_LABEl_HAVE_EXISTED_DETAIL', {
              workflowName: workflowName?.replace(/\(\d+\)$/, '')?.trim() || '',
            }) ||
            "A Workflow with the same label already exists. Do you want to keep the label you've just inscribed?",
          cancelLabel: translate('COMMON_LABEL_DISCARD') || 'Discard',
          confirmLabel: translate('COMMON_LABEL_CREATE') || 'Create',
          confirmable: true,
          closeable: true,
        });
        const confirmed = await ask();
        if (!confirmed) stopCreate = true;
      }
      if (stopCreate) {
        return;
      }
      res = await _workflowStore.createNewWorkflow({
        ...workflowInformation.value,
        name: workflowName,
      });
    } else {
      res = await WorkflowRepository.getInstance().updateWorkFlowInfo(
        workflowInformation.value
      );
    }

    emit('createSuccess', res?.result);
    emit('updateSuccess', res?.result);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isLoadFinishCreate.value = false;
  }
};
</script>

<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    :container-class="['h-4/5']"
    container-style="max-height: 90%; max-width: 90%;min-width: 36rem"
    disable-click-outside
    @cancel="onCloseModal"
  >
    <template #header>
      <div class="w-full flex items-center">
        <div class="text-base min-w-max">
          {{
            $t(
              viewMode == 'ADD'
                ? 'WORKFLOW_LABEl_ADD_NEW'
                : 'WORKFLOW_LABEL_EDIT_WORKFLOW_INFORMATION'
            )
          }}
        </div>
      </div>
    </template>
    <template #body>
      <div class="px-8">
        <WorkflowInformation
          v-model:workflow-info="workflowInformation"
          :view-mode="'EDIT'"
        />
      </div>
    </template>
    <template #footer>
      <div class="w-full flex items-center justify-between px-4 pb-4">
        <div class="flex-center space-x-2">
          <div v-if="errorMessageCode" class="flex-center space-x-2">
            <SynIcon name="info" class="fill-red-500" />
            <span class="text-red-500 text-sm">
              {{ $t(errorMessageCode) }}
            </span>
          </div>
        </div>
        <div class="flex-center space-x-2">
          <SynButton
            type-outline
            :label="$t('COMMON_LABEL_CANCEL')"
            :disabled="isLoadFinishCreate"
            @click="onCloseModal"
          />
          <SynButton
            :is-loading="isLoadFinishCreate"
            :disabled="isDisabledSummit"
            :label="
              $t(
                viewMode == 'ADD'
                  ? 'COMMON_LABEL_CONTINUE'
                  : 'COMMON_LABEL_SAVE'
              )
            "
            @click="onSave"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
