<script setup lang="ts">
import { INotificationMainDataTaskUpdate } from '@/application/types/notification.types';
import { taskStates } from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { computed } from 'vue';

const props = defineProps<{
  beforeUpdate: INotificationMainDataTaskUpdate;
  afterUpdate: INotificationMainDataTaskUpdate;
}>();

const beforeValue = computed(() => {
  if (!props.beforeUpdate?.taskLife) return '';
  return translate(taskStates[props.beforeUpdate?.taskLife]?.nameCode);
});
const afterValue = computed(() => {
  if (!props.afterUpdate?.taskLife) return '';
  return translate(taskStates[props.afterUpdate?.taskLife]?.nameCode);
});
</script>
<template>
  <div class="inline-flex space-x-1 text-overflow-hidden-line">
    <template v-if="beforeUpdate?.taskLife">
      <p>
        {{ $t('COMMON_LABEL_CHANGE_FROM') }}
      </p>
      <p
        class="text-overflow-hidden-line font-semibold italic"
        :class="taskStates[beforeUpdate?.taskLife]?.color"
        v-html="beforeValue"
      ></p>
    </template>

    <p>
      {{ $t('COMMON_LABEL_CHANGE_TO') }}
    </p>
    <p
      class="text-overflow-hidden-line font-semibold italic uppercase underline"
      :class="
        afterUpdate?.taskLife ? taskStates[afterUpdate?.taskLife]?.color : ''
      "
      v-html="afterValue"
    ></p>
  </div>
</template>
