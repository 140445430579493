<script setup lang="ts">
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import getUserInfo from '@/ui/helpers/user-helper';
import UserById from '@/ui/components/user/UserById.vue';
import CommentReactionList from '@/ui/modules/task/components/activity-comment/CommentReactionList.vue';
import CommentReactionButton from '@/ui/modules/task/components/activity-comment/CommentReactionButton.vue';
import ListReactionsInCommentModal from '@/ui/modules/task/components/activity-comment/ListReactionsInCommentModal.vue';
import CommentListFiles from '@/ui/modules/task/components/activity-comment/CommentListFiles.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import ActionInComment from '@/ui/modules/task/components/activity-comment/ActionInComment.vue';
import taskDetailStore from '@/store/task/detail';

const props = withDefaults(
  defineProps<{
    message: any;
    isEdit: boolean;
    inListPinned: boolean;
    isLoading: any;
    bgMsg: string;
    isShowLineDate: boolean;
  }>(),
  {
    bgMsg: 'bg-gray-50',
    isShowLineDate: true,
  }
);
const emits = defineEmits([
  'onReactionAdd',
  'onReactionsClick',
  'onEditComment',
  'onRemoveComment',
  'onPinComment',
  'onReactionRemove',
  'onFileClick',
  'onMessageDownload',
  'onClickTextComment',
]);

const _taskDetailStore = taskDetailStore();
const files = computed(() => props.message.attachments?.files || []);
const notes = computed(() => props.message.attachments?.notes || []);
const creatorInfo = computed(() => getUserInfo(props.message?.createdBy));
const creatorName = computed(() => {
  return (
    creatorInfo.value?.name ||
    `${creatorInfo.value?.lastName || ''} ${
      creatorInfo.value?.firstName || ''
    }`.trim()
  );
});
const currentId = getCurrentUserId();
const allAttachments = computed(() => {
  return arrayOrderBy(
    [
      ...files.value?.map((file) => {
        return {
          ...file,
          url_full: file.fileUrl,
          contentType: file?.type,
          name: file?.fileName,
        };
      }),
      ...notes?.value?.map((note) => {
        return {
          ...note,
          url_full: note.imagePath,
          contentType: 'image/jpeg',
        };
      }),
    ],
    ['creationTime'],
    ['asc']
  );
});
const reactionListByCmtId = ref({} as any);
const isHighLightCmt = computed(() => {
  if (
    _taskDetailStore?.navigatePoint?.id &&
    _taskDetailStore?.navigatePoint?.isScroll
  )
    return _taskDetailStore?.navigatePoint?.id;
  return null;
});

const onOpenViewReactionsModal = (reactions, id) => {
  reactionListByCmtId.value.reactions = reactions;
  reactionListByCmtId.value.commentId = id;
};

const onRemoveReaction = (value) => {
  emits('onReactionRemove', {
    reaction: value,
    commentId: reactionListByCmtId.value.commentId,
  });
  reactionListByCmtId.value = {};
};
</script>

<template>
  <div
    v-if="isShowLineDate && message?.isFirstCommentDay"
    class="flex-center relative w-full pb-1 pt-3"
    :class="`${bgMsg}`"
  >
    <div class="border-t w-3/4 border-gray-200" :class="`${bgMsg}`">
      <span
        class="
          absolute
          inset-0
          text-center
          flex-center
          text-xs
          font-medium
          text-gray-500
        "
      >
        <SynLabelDateTime
          class="px-3"
          :class="`${bgMsg}`"
          format="date"
          :datetime="message?.createdDate"
        />
      </span>
    </div>
  </div>
  <div
    :id="
      message?.pinned
        ? `detail-task-comment-pinned-${message?.id}`
        : `detail-task-comment-${message?.id}`
    "
    class="flex items-start w-full space-x-2 px-2 pb-1 task-message-item"
    :class="`${message?.pinned ? 'border-l-2 border-l-orange-500' : ''}
        ${bgMsg} ${message?.isLastCommentDay ? '' : 'border-b border-gray-100'}
        ${
          !message?.pinned &&
          isHighLightCmt === `detail-task-comment-${message?.id}`
            ? 'colorScrollComment cmt-focus'
            : ''
        }
      `"
  >
    <div class="w-6 h-6 pt-4 pr-1 pl-2">
      <UserById
        :user-id="message?.createdBy"
        is-hidden-name
        avatar-class="w-6 h-6"
      />
    </div>

    <!-- MSG   -->
    <div
      class="hover-to-show__parent flex flex-col relative w-full space-y-1 pl-3"
    >
      <div class="flex-1 rounded-r-xl rounded-bl-xl rounded-tl-md py-2 px-1">
        <!--  CREATOR    -->
        <div class="flex items-center justify-between space-x-2 text-sm">
          <div class="flex items-center space-x-2 py-0.5">
            <span class="font-medium">
              {{ creatorName }}
            </span>
            <span v-if="message?.createdBy === currentId" class="text-xs"
              >{{ `(${$t('COMMON_LABEL_ME')})` || '(Me)' }}
            </span>
            <span
              v-vig-tooltip="
                dayjs(message?.createdDate)?.format('MMM DD, YYYY, hh:mm A')
              "
              class="text-xs text-gray-500"
            >
              {{ dayjs(message?.createdDate).fromNow() }}
            </span>
            <span v-if="message?.isEdited" class="text-gray-500 italic text-xs"
              >{{ `(${$t('COMMOM_LABEL_EDITED')})` }}
            </span>
          </div>

          <div class="flex-center space-x-2 pr-2">
            <div class="flex items-center space-x-2 h-4">
              <CommentReactionList
                :reactions-list="message?.reactions || []"
                @click="
                  onOpenViewReactionsModal(message?.reactions, message?.id)
                "
              />
              <CommentReactionButton
                :reactions-list="message?.reactions || []"
                @on-emoji-click="
                  (data) =>
                    $emit('onReactionAdd', { content: data, id: message?.id })
                "
              />
            </div>
            <ActionInComment
              :is-my-comment="message?.createdBy === currentId"
              :message="message"
              :is-loading="isLoading"
              @on-edit-comment="$emit('onEditComment', message?.id)"
              @on-pin-comment="$emit('onPinComment')"
              @on-remove-comment="$emit('onRemoveComment')"
            />
          </div>
        </div>

        <!--  CONTENT    -->
        <div
          class="leading-5 whitespace-pre-line relative pt-1 text-gray-700 pr-4"
        >
          <span
            v-if="!isEdit"
            class="msg-text pr-4"
            style="word-break: break-word"
            @click="$emit('onClickTextComment', $event)"
            v-html="message.content"
          ></span>
          <slot name="input-edit"></slot>
        </div>

        <template v-if="message?.removedAllAttachments && !isEdit">
          <span
            class="
              py-3
              px-3
              flex-center
              bg-gray-100
              rounded
              text-xs text-gray-500
              mr-4
            "
            >{{ $t('COMMENT_LABEL_REMOVED_ALL_ATTACHMENTS') }}</span
          >
        </template>

        <!--  FILES - NOTE    -->
        <template v-if="(files?.length > 0 || notes?.length > 0) && !isEdit">
          <CommentListFiles
            :files="allAttachments"
            size="sm"
            @on-file-click="(file) => $emit('onFileClick', file)"
            @on-message-download="(files) => $emit('onMessageDownload', files)"
          />
        </template>
      </div>
    </div>
  </div>

  <ListReactionsInCommentModal
    v-if="
      reactionListByCmtId?.reactions?.length > 0 &&
      reactionListByCmtId.commentId
    "
    :reactions="reactionListByCmtId?.reactions"
    @on-cancel="
      (reactionListByCmtId.reactions = []),
        (reactionListByCmtId.commentId = null)
    "
    @on-reaction-remove="onRemoveReaction"
  />
</template>
<style scoped>
@keyframes change {
  0% {
    background-color: white;
  }
  35% {
    background-color: #e0f1f2;
  }
  70% {
    background-color: #f8ffff;
  }
  100% {
    background-color: white;
  }
}

.cmt-focus {
  @apply ring ring-current ring-opacity-30 z-40;
}

.colorScrollComment {
  animation-name: change;
  animation-duration: 1s;
}

ul,
menu {
  list-style: none;

  li {
    margin-left: 1rem;
  }

  li::before {
    content: '•';
    margin-right: 0.5rem;
  }
}
</style>
