<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import driveDocumentPageStore from '@/store/driveDocument/drive-document-page';
import DriveDocumentAddButton from '@/ui/modules/drive-document/drive-document-add/DriveDocumentAddButton.vue';
import DriveDocumentListCard from '@/ui/modules/drive-document/drive-documents-list/DriveDocumentListCard.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import FileStorageRepository from '@/application/repositories/FileStorageRepository';
import myProfileStore from '@/store/auth/my-profile';
import { chain, reject } from 'lodash';
import SelectMembers from '@/ui/modules/member/select-members/SelectMembers.vue';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import {
  ALL_SCREENS,
  checkPermissionByScreen,
} from '@/ui/hooks/permission/permission-by-function';
import { EScreenPermission } from '@/application/types/block-function.types';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
import { isFolder } from '@/ui/helpers/file-utils';
import FolderBreadcrumb from '@/ui/modules/ged/folder/folder-breadcrumb/FolderBreadcrumb.vue';
import ModalFolderSelect from '@/ui/modules/ged/folder/modal-folder-select/ModalFolderSelect.vue';
import fileStorageStore from '@/store/fileStorage';
import {
  AlertType,
  closeNotification,
  openNotification,
} from '@/ui/hooks/commonHook';
import { ResourceTab } from '@/domain/enums/resource-enums';
import { useRoute, useRouter } from 'vue-router';
import FileSearchItem from '@/ui/modules/ged/files-list/file-search-item/FileSearchItem.vue';
import GedFilterSubDomain from '@/ui/modules/ged/ged-page/filter-sub-domain/GedFilterSubDomain.vue';

const _router = useRouter();
const _route = useRoute();
const _drivePageStore = driveDocumentPageStore();
const _fileStorageStore = fileStorageStore();
const _myProfileStore = myProfileStore();

const documents = ref<any[]>([]);
const fileFilters = ref<any>({});
const selectedMember = ref<any>();
const searchText = ref<string>();
const isLoading = ref<boolean>(false);
const isReloading = ref<boolean>(false);
const canLoadMore = ref<boolean>(false);
const baseImageUrl = ref(`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web`);
const currentFolder = ref<any>();
const isMoveToFolder = ref<boolean>();
const moveToIgnoredFolderIds = ref<any[] | null>();
const moveToConfirmFunction = ref<any>();

const myProfile = computed(() => _myProfileStore.myProfile);
const allMyDomains = computed(() => _myProfileStore.myDomains);
const allUsers = computed(() => userStore().listShortInfosUser);
const allMyGroups = computed(() =>
  groupStore().allMyGroups?.filter((group) => group?.isMember)
);
const screenCode = computed<string>(() => {
  if (selectedMember.value?.isGroup) return ALL_SCREENS.GROUP.RESOURCE.STORAGE;

  if (
    selectedMember.value?.id &&
    selectedMember.value?.id == myProfile.value?.id
  ) {
    return ALL_SCREENS.LOGIN_USER.RESOURCE.STORAGE;
  }

  return ALL_SCREENS.USER.RESOURCE.STORAGE;
});
const isHasPermissionByPaymentState = computed<boolean>(() => {
  const _permission = checkPermissionByScreen(screenCode.value);

  return (
    _permission !== EScreenPermission.LOW_VISION &&
    _permission !== EScreenPermission.HIDDEN
  );
});
const currentLocation = computed(() => {
  return {
    userId:
      !selectedMember.value?.isGroup && !selectedMember.value?.isDomain
        ? selectedMember.value?.id
        : null,
    groupId: selectedMember.value?.isGroup ? selectedMember.value?.id : null,
    domainId: selectedMember.value?.isDomain ? selectedMember.value?.id : null,
    projectId: selectedMember.value?.isDomain
      ? fileFilters.value?.projectId
      : null,
    subProjectId: selectedMember.value?.isDomain
      ? fileFilters.value?.subProjectId
      : null,
    folderId: currentFolder.value?.id,
    folder: currentFolder.value,
  };
});

onBeforeMount(() => {
  selectedMember.value = myProfile.value;

  _getDriveDocuments();
});

const onMemberChange = () => {
  fileFilters.value.domainId = selectedMember.value?.isDomain
    ? selectedMember.value?.id
    : null;
  fileFilters.value.projectId = null;
  fileFilters.value.subProjectId = null;

  _openFolder(null);
};

const onSubDomainChange = () => {
  _openFolder(null);
};

const onGetSuggestions = async (searchText) => {
  if (!searchText?.trim()) return [];

  const query: any = {
    groupId: selectedMember.value?.isGroup ? selectedMember.value?.id : null,
    domainId: selectedMember.value?.isDomain ? selectedMember.value?.id : null,
    projectId: selectedMember.value?.isDomain
      ? fileFilters.value?.projectId
      : null,
    subProjectId: selectedMember.value?.isDomain
      ? fileFilters.value?.subProjectId
      : null,
    userId:
      !selectedMember.value?.isGroup && !selectedMember.value?.isDomain
        ? selectedMember.value?.id
        : null,
    folderId: currentFolder.value?.id,
    keyword: searchText?.trim(),
    sortBy: 'name',
    orderBy: 'asc',
    pageSize: 10,
    pageIndex: 0,
  };

  const res: any = await FileStorageRepository.getInstance().getDriveDocuments(
    query
  );

  return res?.result?.files;
};

const onSearchItemSelect = (document) => {
  if (document?.id) {
    onDocumentClick(document);
  } else {
    _getDriveDocuments();
  }
};

const onReloadClick = async () => {
  isReloading.value = true;

  try {
    await _getDriveDocuments();
  } catch (e) {
    console.log(e);
  }

  isReloading.value = false;
};

const onDocumentAdded = (document) => {
  _drivePageStore.openDocument(document);
};

const onFolderAdded = () => {
  _getDriveDocuments();
};

const onDocumentClick = (document) => {
  if (isFolder(document?.contentType)) {
    _openFolder(document);
  } else {
    _drivePageStore.openDocument(document);
  }
};

const onDocumentRenameConfirm = async (document, newName) => {
  if (!document || !newName) return;

  const data = {
    id: document?.id,
    contentType: document?.contentType,
    name: newName,
  };

  await FileStorageRepository.getInstance().renameFile(data);

  const updIndex = documents.value.findIndex((doc) => doc?.id === data?.id);
  if (updIndex > -1) documents.value[updIndex].name = newName;
};

const onDocumentMoveToFolderClick = (document) => {
  moveToIgnoredFolderIds.value = isFolder(document?.contentType)
    ? [document?.id]
    : null;

  moveToConfirmFunction.value = (location) =>
    _fileStorageStore.moveFilesToFolder([document], {
      ...location,
      isDriveFolder: true,
    });

  isMoveToFolder.value = true;
};

const onDocumentMoveToFolderSuccess = () => {
  isMoveToFolder.value = false;

  _getDriveDocuments();
};

const onDocumentDeleteClick = async (document) => {
  if (!document) return;

  const data = {
    files: [
      {
        id: document?.id,
        contentType: document?.contentType,
      },
    ],
  };

  await FileStorageRepository.getInstance().moveFilesToTrash(data);

  documents.value = reject(documents.value, { id: document?.id });

  openNotification({
    type: AlertType.success,
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('GED_MOVED_TO_TRASH_NUM_FILE_MSG', {
      number: data.files.length,
    }),
    onClick: (notif) => {
      closeNotification(notif);

      _router.push({
        query: {
          ..._route.query,
          resourceTab: ResourceTab.Files,
          view: 'TRASH',
        },
      });
    },
  });
};

const onFolderGoToClick = (folder) => {
  _openFolder(folder);
};

const _getDriveDocuments = async (isReload = true, isShowLoading = true) => {
  if (isShowLoading) isLoading.value = true;

  if (isReload) {
    documents.value = [];
    canLoadMore.value = false;
  }

  const query: any = {
    groupId: selectedMember.value?.isGroup ? selectedMember.value?.id : null,
    domainId: selectedMember.value?.isDomain ? selectedMember.value?.id : null,
    projectId: selectedMember.value?.isDomain
      ? fileFilters.value?.projectId
      : null,
    subProjectId: selectedMember.value?.isDomain
      ? fileFilters.value?.subProjectId
      : null,
    userId:
      !selectedMember.value?.isGroup && !selectedMember.value?.isDomain
        ? selectedMember.value?.id
        : null,
    folderId: currentFolder.value?.id,
    keyword: searchText.value,
    pageSize: 50,
    pageIndex: isReload ? 0 : documents.value?.length,
  };

  try {
    const res: any =
      await FileStorageRepository.getInstance().getDriveDocuments(query);
    const result = res?.result;

    documents.value = isReload
      ? result?.files
      : chain(documents.value).concat(result?.files).uniqBy('id').value();

    canLoadMore.value = (documents.value?.length || 0) < (result?.total || 0);
  } catch (e) {
    console.log(e);
  }

  isLoading.value = false;
};

const _openFolder = (folder) => {
  currentFolder.value = folder;
  searchText.value = '';

  _getDriveDocuments();
};
</script>

<template>
  <div class="h-full flex flex-col">
    <div
      class="
        px-3
        py-2
        flex
        gap-2
        items-center
        justify-between
        flex-wrap
        border-b border-gray-100
      "
    >
      <div class="flex items-center gap-2">
        <div class="text-lg leading-6 pb-3 pt-2">
          {{ $t('GED_GOOGLE_DOCS_OF') }}
        </div>
        <div class="max-w-sm min-w-[14rem]">
          <SelectMembers
            v-model="selectedMember"
            :members="allUsers"
            :groups="allMyGroups"
            :domains="allMyDomains"
            :show-nav-buttons="true"
            :show-only-my-groups="true"
            :show-other-members="false"
            toggle-class="rounded border px-2 py-1"
            @update:model-value="onMemberChange"
          />
        </div>
        <div
          v-if="selectedMember?.isDomain"
          class="min-w-[12rem] max-w-[20rem]"
        >
          <GedFilterSubDomain
            v-model:file-filters="fileFilters"
            toggle-class="rounded border px-2 py-1"
            @update:file-filters="onSubDomainChange"
          />
        </div>
      </div>

      <div
        class="flex items-center justify-between gap-2"
        :class="{ 'w-full': !!currentFolder }"
      >
        <FolderBreadcrumb
          v-if="currentFolder"
          :folder="currentFolder"
          :is-drive-document="true"
          @on-folder-click="onFolderGoToClick"
        />
        <div
          v-if="isHasPermissionByPaymentState"
          class="flex gap-2 items-center"
        >
          <VigTypeahead
            v-model:search-text="searchText"
            input-class="text-sm"
            :get-suggestions-fn="onGetSuggestions"
            @on-item-select="onSearchItemSelect"
          >
            <template #item="slotProps">
              <FileSearchItem :file="slotProps?.item" />
            </template>
          </VigTypeahead>

          <DriveDocumentAddButton
            :location="currentLocation"
            @on-added="onDocumentAdded"
            @on-folder-added="onFolderAdded"
          >
            <VigButton color="current" icon="Plus" />
          </DriveDocumentAddButton>

          <VigButton
            ghost
            color="gray"
            icon="Reload"
            :processing="isReloading"
            @click="onReloadClick"
          />
        </div>
      </div>
    </div>

    <div
      v-perfect-scrollbar
      v-permission-screen="{
        screenCode: screenCode,
        isListenUpdated: true,
        isPage: true,
      }"
      class="flex-1 p-3"
    >
      <!--DOCUMENTS-->
      <div
        v-if="documents?.length"
        class="grid auto-rows-max gap-3"
        style="grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr))"
      >
        <DriveDocumentListCard
          v-for="document in documents"
          :key="document?.id"
          :document="document"
          :current-storage="selectedMember"
          :current-folder="currentFolder"
          @on-click="onDocumentClick(document)"
          @on-rename-confirm="onDocumentRenameConfirm(document, $event)"
          @on-move-to-folder-click="onDocumentMoveToFolderClick(document)"
          @on-move-to-trash-click="onDocumentDeleteClick(document)"
        />
      </div>

      <!--NO DATA-->
      <div
        v-if="!documents?.length && !isLoading"
        class="h-full flex-center flex-col"
      >
        <VigImage
          :path="baseImageUrl + '/images/no_file.jpg'"
          custom-class="w-64"
        />
        <div class="font-semibold mb-4">
          <span v-if="!searchText">{{ $t('GED_GOOGLE_DOC_NO_DATA') }}</span>
          <span v-else>{{ $t('GED_GOOGLE_DOC_NOT_FOUND') }}</span>
        </div>
        <DriveDocumentAddButton
          v-if="!searchText"
          :location="currentLocation"
          @on-added="onDocumentAdded"
          @on-folder-added="onFolderAdded"
        >
          <VigButton color="current">
            <SynIcon name="Plus" class="mr-2" />
            {{ $t('COMMON_LABEL_ADD_NEW') }}
          </VigButton>
        </DriveDocumentAddButton>
      </div>

      <VigLoading v-if="isLoading" />
    </div>
  </div>

  <ModalFolderSelect
    v-if="isMoveToFolder"
    :group-id="selectedMember?.isGroup ? selectedMember?.id : null"
    :folder="currentFolder"
    :ignored-folder-ids="moveToIgnoredFolderIds"
    :confirm-function="moveToConfirmFunction"
    :function-name="$t('GED_FILE_MOVE_TO')"
    :is-drive-document="true"
    @on-close="isMoveToFolder = false"
    @on-confirm-processed="onDocumentMoveToFolderSuccess"
  />
</template>
