<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 488.471 488.471"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m427.412 122.118c0-33.723 27.337-61.059 61.059-61.059-33.721 0-61.059-27.337-61.059-61.059 0 33.721-27.337 61.059-61.059 61.059 33.721 0 61.059 27.335 61.059 61.059z"
      ></path>
      <path
        d="m442.677 183.176c0 25.291-20.503 45.794-45.794 45.794 25.292 0 45.794 20.503 45.794 45.794 0-25.292 20.503-45.794 45.794-45.794-25.292.001-45.794-20.502-45.794-45.794z"
      ></path>
      <path
        d="m213.706 183.176c0-50.583 41.005-91.588 91.588-91.588-50.583 0-91.588-41.005-91.588-91.588 0 50.583-41.005 91.588-91.588 91.588 50.583 0 91.588 41.006 91.588 91.588z"
      ></path>
      <path
        d="m335.824 91.588-335.824 335.824 61.059 61.059 335.824-335.824zm-52.115 97.909 43.17-43.17 15.265 15.265-43.17 43.17s-15.265-15.265-15.265-15.265z"
      ></path>
    </g>
  </svg>
</template>
