<script setup lang="ts">
import { ref, onMounted, nextTick, computed } from 'vue';
import Cropper from 'cropperjs';
import { ImageDefault } from '@/ui/components/photo-upload/imageDefault.js';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import groupStore from '@/store/group';

const props = withDefaults(
  defineProps<{
    zIndex?: string;
    src?: string;
    isDeletePhoto?: boolean;
    groupId?: string | number;
    isEdit?: boolean;
  }>(),
  {
    zIndex: 'z-80',
  }
);

const emit = defineEmits([
  'cancel',
  'confirm',
  'deleteImage',
  'getFile',
  'uploadAvatarSuccess',
]);

// const _userStore = userStore();
const _groupStore = groupStore();

const isChooseImage = ref(false);

const isLoadingUpload = ref(false);
const fileName = computed(() => _groupStore.fileName);

const isNotRenderCropper = ref(false);
const isQuestionShowImageOriginal = ref(false);

const isDeleteAvatar = ref(false);

const listShow = ref({});
const photoChose = ref(null);
let _imgChooseCropper;
const fileChoosePhotoRef = ref(null);

const photoChoseUpload = ref();
const listSrcImage = ref([]);
let _imgCropper;
const fileUploadPhotoRef = ref(null);
const fileSelect = ref();
const indexPhotoCutUpload = ref();

const statusUpload = ref({
  UPLOAD_PHOTO: {
    key: 'UPLOAD_PHOTO',
    label: 'PHOTO_LABEL_UPLOAD_PHOTO',
    isActive: true,
  },
  CHOOSE_PHOTO: {
    key: 'CHOOSE_PHOTO',
    label: 'PHOTO_LABEL_CHOOSE_PHOTO',
    isActive: false,
  },
});

onMounted(() => {
  listShow.value = ImageDefault('GROUP');
});

const changeTab = async (key) => {
  for (var k in statusUpload.value) {
    if (k == key) {
      statusUpload.value[k].isActive = true;
    } else {
      statusUpload.value[k].isActive = false;
    }
  }
  if (key == 'UPLOAD_PHOTO') {
    await nextTick();
    if (listSrcImage.value.length < 1) {
      if (props.src) {
        photoChoseUpload.value = 0;
        listSrcImage.value.push(props.src?.replace('mini', 'original'));
        chosseImageUploadCut(0);
      } else {
        photoChoseUpload.value = null;
        listSrcImage.value = [];
      }
    } else {
      chosseImageUploadCut(photoChoseUpload.value);
    }
  } else {
    await nextTick();
    if (!photoChose.value) {
      isChooseImage.value = false;
    } else {
      isChooseImage.value = true;
      _initViewerPhotoDefault(
        listShow.value[photoChose.value.key]?.objects[photoChose.value.index]
      );
    }
  }
};

// IMAGE DEFAULT IN TICTOP
const chosseImageCut = async (index, key) => {
  if (isChooseImage.value && photoChose.value.index == index) {
    isChooseImage.value = false;
    photoChose.value = null;
  } else {
    isChooseImage.value = true;
    photoChose.value = {};
    photoChose.value.index = index;
    photoChose.value.key = key;
    _initViewerPhotoDefault(listShow.value[key]?.objects[index]);
  }
};

const _initViewerPhotoDefault = (src) => {
  _imgChooseCropper && _imgChooseCropper.destroy();
  fileChoosePhotoRef.value.src = src;
  _imgChooseCropper = new Cropper(fileChoosePhotoRef.value, {
    checkCrossOrigin: true,
    dragMode: 'none',
    aspectRatio: 1 / 1,
    autoCrop: true,
    responsive: true,
    background: false,
    toggleDragModeOnDblclick: false,
    ready() {
      _zoomToPhotoOriginal();
    },
  });
};

const _zoomToPhotoOriginal = () => {
  const canvasData = _imgChooseCropper.getCanvasData();
  if (canvasData.naturalWidth < canvasData.width) {
    _imgChooseCropper.zoomTo(1.2);
  }
};

const onCutPhotoDefaultClick = () => {
  _imgChooseCropper.getCroppedCanvas({
    fillColor: '#fff',
  });
  const file = _imgChooseCropper
    .getCroppedCanvas({
      fillColor: '#fff',
    })
    .toDataURL('image/jpeg');
  let binary;
  binary = new File(
    [new Buffer.from(file?.replace(/^data:image\/\w+;base64,/, ''), 'base64')],
    'avatar.png',
    {
      type: 'image/png',
    }
  );
  return binary;
};

// IMAGE UPLOAD FROM COMPUTED
const _initViewer = (src) => {
  _imgCropper && _imgCropper.destroy();
  fileUploadPhotoRef.value.src = src;
  _imgCropper = new Cropper(fileUploadPhotoRef.value, {
    checkCrossOrigin: true,
    dragMode: 'move',
    aspectRatio: 1 / 1,
    autoCrop: true,
    responsive: true,
    background: false,
    toggleDragModeOnDblclick: false,
    ready() {
      _zoomToOriginal();
    },
  });
};

const onZoomClick = (value) => {
  _imgCropper && _imgCropper.zoom(value);
};

const onResetClick = () => {
  _imgCropper && _imgCropper.reset();
  _zoomToOriginal();
};

const _zoomToOriginal = () => {
  const canvasData = _imgCropper.getCanvasData();
  if (canvasData.naturalWidth < canvasData.width) {
    _imgCropper.zoomTo(1);
  }
};

const chooseAvatarFromComputer = async (e) => {
  if (e.target.files[0].type === 'image/gif') {
    isQuestionShowImageOriginal.value = true;
    isNotRenderCropper.value = true;
    fileSelect.value = e.target.files[0];
  }
  listSrcImage.value.push(URL.createObjectURL(e.target.files[0]));
  indexPhotoCutUpload.value = listSrcImage.value.length - 1;

  chosseImageUploadCut(listSrcImage.value.length - 1);
};

const onChangeQuestion = () => {
  // isNotRenderCropper.value = !isNotRenderCropper.value;
  if (!isNotRenderCropper.value) {
    chosseImageUploadCut(indexPhotoCutUpload.value);
  }
};

const chosseImageUploadCut = (index) => {
  photoChoseUpload.value = index;
  isChooseImage.value = true;
  indexPhotoCutUpload.value = index;
  _initViewer(listSrcImage.value[index]);
};

const onCutClick = () => {
  _imgCropper.getCroppedCanvas({
    fillColor: '#fff',
  });
  const file = _imgCropper
    .getCroppedCanvas({
      fillColor: '#fff',
    })
    .toDataURL('image/jpeg');
  let binary;
  binary = new File(
    [new Buffer.from(file?.replace(/^data:image\/\w+;base64,/, ''), 'base64')],
    'avatar.png',
    {
      type: 'image/png',
    }
  );
  return binary;
};

const handleUploadAvatarAws = async (file) => {
  if (!file) return;

  isLoadingUpload.value = true;
  try {
    await _groupStore.uploadAvatarAws(file);
  } catch (error) {
    isLoadingUpload.value = false;
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('COMMON_ERROR_UPLOAD_AVATAR'),
      confirmable: true,
      closeable: true,
    });
    const anser = await ask();
    if (anser) {
      emit('cancel', {
        value: true,
        avatars: [],
      });
    } else {
      emit('cancel', {
        value: false,
        avatars: [],
      });
    }
    return;
  }
};

const handleSaveAvatarGroup = async (isDelete = false) => {
  if (!props.groupId || (!fileName.value && !isDelete)) return;

  let res = {} as any;

  try {
    res = await _groupStore.updateAvatarGroup(
      props.groupId,
      !isDelete ? fileName.value : null
    );

    emit('uploadAvatarSuccess', res?.result);

    isLoadingUpload.value = false;
  } catch (error) {
    isLoadingUpload.value = false;
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('COMMON_ERROR_UPLOAD_AVATAR'),
      confirmable: true,
      closeable: true,
    });
    await ask();
  } finally {
    isLoadingUpload.value = false;
    emit('cancel', {
      value: false,
      avatars: res['result'] || [],
    });
  }
};

// HANDLE UPLOAD FILES
const onSaveImage = async () => {
  if (isNotRenderCropper.value) {
    if (props.isEdit) {
      emit('getFile', fileSelect.value);
      return;
    }
    await handleUploadAvatarAws(fileSelect.value);
  } else if (statusUpload.value['CHOOSE_PHOTO'].isActive) {
    if (props.isEdit) {
      emit('getFile', onCutPhotoDefaultClick());
      return;
    }
    let file = await onCutPhotoDefaultClick();
    await handleUploadAvatarAws(file);
  } else {
    if (props.isEdit) {
      emit('getFile', onCutClick());
      return;
    }
    let file = await onCutClick();
    await handleUploadAvatarAws(file);
  }

  if (!props.isEdit) await handleSaveAvatarGroup();
};

changeTab(statusUpload.value.UPLOAD_PHOTO.key);

// DELETE AVATAR
const handleDeleteAvatar = async () => {
  isDeleteAvatar.value = true;
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('PROFILE_ALERT_DELETE_AVATAR'),
    confirmable: true,
    closeable: true,
  });
  const anser = await ask();
  if (anser) {
    isDeleteAvatar.value = false;
    // emit('deleteImage', null);
    await handleSaveAvatarGroup(true);
  } else {
    isDeleteAvatar.value = false;
  }
};
</script>

<template>
  <SynModal
    :z-index="zIndex"
    container-class="h-3/5 w-1/3"
    container-style="min-width: 50rem"
    disable-click-outside
    is-hidden-header
    disable-esc-to-close
    style-body="p-0 overflow-hidden"
    @cancel="$emit('cancel', false)"
  >
    <template #header>
      <!-- <div class="flex-center space-x-4">
        
        <span>{{ $t('PROFILE_MODAL_TITLE_UPLOAD_AVATAR') }}</span>
      </div> -->
    </template>
    <template #body>
      <!-- <div class="h-full min-h-0 flex flex-col overflow-hidden"> -->
      <syn-tabs
        :option-status="statusUpload"
        positon="between"
        style-tab="padding: 10px 20px 0px 20px;"
        class="overflow-hidden"
        is-header
        @change-tab="(key) => changeTab(key)"
      >
        <template #header>
          <span class="font-medium text-lg">{{
            $t('PROFILE_GROUP_MODAL_TITLE_UPLOAD_AVATAR')
          }}</span>
        </template>
        <template #tabBody>
          <!-- UPLOAD PHOTO -->
          <section v-if="statusUpload['UPLOAD_PHOTO'].isActive" class="h-full">
            <div class="flex h-full px-2 pt-2">
              <div
                class="
                  flex flex-col
                  h-full
                  overflow-y-auto overflow-x-hidden
                  small-scrollbar
                "
                style="flex: 50%"
              >
                <div
                  class=""
                  :class="
                    listSrcImage.length > 0
                      ? 'grid grid-cols-4 gap-3 flex-wrap'
                      : 'flex-center h-full w-full'
                  "
                >
                  <div
                    class=""
                    :class="
                      listSrcImage.length > 0
                        ? ''
                        : 'flex-center space-y-4 flex-col'
                    "
                  >
                    <div class="relative">
                      <div class="shadow-md rounded-full bg-white w-14 h-14">
                        <input
                          ref="fileInputCreate"
                          type="file"
                          class="invisible"
                          accept="image/*"
                          @change="chooseAvatarFromComputer"
                        />
                      </div>
                      <div
                        v-vig-tooltip="$t('PHOTO_LABEL_UPLOAD_PHOTO')"
                        class="
                          w-16
                          h-16
                          p-4
                          absolute
                          top-0
                          bg-current-50
                          rounded-full
                          flex-center
                          cursor-pointer
                          fill-current
                          hover:bg-current-400 hover:fill-white
                        "
                        @click="$refs.fileInputCreate.click()"
                      >
                        <SynIconCustom name="plus" />
                      </div>
                    </div>

                    <div
                      v-if="listSrcImage.length <= 0"
                      class="flex-center flex-col text-gray-400 text-sm"
                    >
                      <svg
                        class="fill-gray-500"
                        width="50"
                        height="50"
                        viewBox="0 0 100 100"
                        style="enable-background: new 0 0 512 512"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            d="m83.969 22.354-4.685-8.561c-.105-.194-.292-.331-.507-.375-.214-.043-.44.009-.614.146l-7.491 5.89c-.326.256-.382.728-.126 1.053.256.326.728.383 1.053.126l5.678-4.464c-.446 2.992-2.628 15.357-9.831 28.896-7.583 14.255-22.454 32.777-50.238 40.06-.401.105-.641.515-.536.916.088.337.393.56.725.56.063 0 .127-.008.19-.024 28.333-7.426 43.486-26.316 51.208-40.855 7.577-14.264 9.675-27.26 10.013-29.669l3.844 7.024c.136.25.393.39.658.39.122 0 .245-.03.359-.092.366-.203.499-.658.3-1.021z"
                          ></path>
                        </g>
                      </svg>
                      <span>{{
                        $t('PHOTO_LABEL_LABEL_ICON_UPLOAD_PHOTO')
                      }}</span>
                    </div>
                  </div>

                  <div
                    v-for="(photo, index) in listSrcImage"
                    :key="photo"
                    class="cursor-pointer relative"
                    @click="chosseImageUploadCut(index)"
                  >
                    <div
                      v-if="photoChoseUpload == index"
                      class="
                        w-16
                        h-16
                        rounded-full
                        absolute
                        top-0
                        z-10
                        flex-center
                        bg-current-800 bg-opacity-40
                      "
                    >
                      <SynIcon name="check" custom-class="w-8 h-8 text-white" />
                    </div>
                    <div
                      class="
                        w-16
                        h-16
                        flex-center
                        rounded-full
                        border border-current-100
                      "
                    >
                      <img
                        style="clip-path: ellipse(45% 45% at 50% 50%)"
                        class="object-cover w-16 h-16"
                        :src="photo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col" style="flex: 50%">
                <div
                  v-if="!isNotRenderCropper"
                  class="h-full bg-gray-200 relative p-1"
                >
                  <div class="h-full">
                    <img
                      ref="fileUploadPhotoRef"
                      class="max-w-full h-full hidden"
                    />
                  </div>

                  <div class="absolute right-5 bottom-5">
                    <button
                      class="
                        p-2
                        flex-center
                        rounded-full
                        shadow-lg
                        bg-black bg-opacity-50
                        hover:bg-opacity-100
                        mb-2
                      "
                      @click="onResetClick"
                    >
                      <SynIcon
                        name="Refresh"
                        custom-class="w-5 h-5 text-white fill-white"
                      />
                    </button>
                    <button
                      class="
                        p-2
                        flex-center
                        rounded-full
                        shadow-lg
                        bg-black bg-opacity-50
                        hover:bg-opacity-100
                        mb-2
                      "
                      @click="onZoomClick(0.5)"
                    >
                      <SynIcon name="Plus" custom-class="w-5 h-5 fill-white" />
                    </button>
                    <button
                      class="
                        p-2
                        flex-center
                        rounded-full
                        shadow-lg
                        bg-black bg-opacity-50
                        hover:bg-opacity-100
                      "
                      @click="onZoomClick(-0.5)"
                    >
                      <SynIcon name="Minus" custom-class="w-5 h-5 fill-white" />
                    </button>
                  </div>
                </div>

                <div v-else class="h-full bg-gray-800 relative p-1">
                  <img
                    class="object-cover w-full h-full"
                    :src="listSrcImage[indexPhotoCutUpload]"
                  />
                </div>

                <div
                  v-if="isQuestionShowImageOriginal"
                  class="flex items-center space-x-2"
                  @click="onChangeQuestion()"
                >
                  <input
                    id="checkboxIsShowImage"
                    v-model="isNotRenderCropper"
                    type="checkbox"
                    class="vig-checkbox"
                  />
                  <label
                    class="flex items-center space-x-2 cursor-pointer"
                    for="checkboxIsShowImage"
                  >
                    {{ $t('COMMON_LABEL_IMAGE_SHOW_ORIGINAL') }}
                  </label>
                </div>
              </div>
            </div>
          </section>

          <!-- CHOOSE PHOTO -->
          <section
            v-if="statusUpload['CHOOSE_PHOTO'].isActive"
            class="h-full px-2 pt-2 flex-1 min-h-0"
          >
            <div class="flex flex-row h-full">
              <div class="h-full w-1/2 small-scrollbar overflow-auto px-2">
                <template v-for="section in listShow" :key="section">
                  <span
                    class="
                      font-medium
                      mb-3
                      pb-1
                      pt-2
                      w-full
                      text-xs
                      flex
                      items-start
                      border-b border-current-50
                    "
                    >{{ section.key }}</span
                  >
                  <div class="grid grid-cols-4 gap-3 flex-wrap pb-6">
                    <div
                      v-for="(avt, indexAvt) in section.objects"
                      :key="avt"
                      class="relative"
                      @click="chosseImageCut(indexAvt, section.key)"
                    >
                      <div
                        v-if="
                          photoChose?.index == indexAvt &&
                          photoChose?.key == section.key
                        "
                        class="
                          w-16
                          h-16
                          rounded-full
                          absolute
                          top-0
                          z-10
                          flex-center
                          bg-current-800 bg-opacity-40
                        "
                      >
                        <SynIcon
                          name="check"
                          custom-class="w-8 h-8 text-white"
                        />
                      </div>
                      <img
                        class="w-16 h-16 cursor-pointer rounded-full"
                        :src="avt"
                      />
                    </div>
                  </div>
                </template>
              </div>

              <div class="h-full w-1/2 small-scrollbar overflow-auto">
                <div class="h-full bg-gray-200 relative p-1">
                  <div class="h-full">
                    <img
                      ref="fileChoosePhotoRef"
                      class="max-w-full h-full hidden"
                    />
                  </div>

                  <div class="absolute right-5 bottom-5">
                    <button
                      class="
                        p-2
                        flex-center
                        rounded-full
                        shadow-lg
                        bg-black bg-opacity-50
                        hover:bg-opacity-100
                        mb-2
                      "
                      @click="onResetClick"
                    >
                      <SynIcon
                        name="Refresh"
                        custom-class="w-5 h-5 text-white fill-white"
                      />
                    </button>
                    <button
                      class="
                        p-2
                        flex-center
                        rounded-full
                        shadow-lg
                        bg-black bg-opacity-50
                        hover:bg-opacity-100
                        mb-2
                      "
                      @click="onZoomClick(0.5)"
                    >
                      <SynIcon name="Plus" custom-class="w-5 h-5 fill-white" />
                    </button>
                    <button
                      class="
                        p-2
                        flex-center
                        rounded-full
                        shadow-lg
                        bg-black bg-opacity-50
                        hover:bg-opacity-100
                      "
                      @click="onZoomClick(-0.5)"
                    >
                      <SynIcon name="Minus" custom-class="w-5 h-5 fill-white" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </template>
      </syn-tabs>
      <!-- </div> -->
    </template>

    <template #footer>
      <div
        class="p-4 flex justify-between items-center cursor-pointer space-x-2"
      >
        <div>
          <SynButton
            v-if="src && isDeletePhoto"
            :label="$t('COMMON_LABEL_DELETE_AVATAR')"
            type-outline
            name-icon="trash"
            color="red"
            @click="handleDeleteAvatar"
          />
        </div>

        <div class="flex justify-end items-center cursor-pointer space-x-2">
          <SynButton
            :label="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            @click="$emit('cancel', false)"
          />
          <SynButton
            :disabled="!isChooseImage || isLoadingUpload"
            :is-loading="isLoadingUpload"
            :label="$t('COMMON_LABEL_DONE')"
            @click="onSaveImage"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>

<style scoped>
.cropper-container .cropper-crop-box {
  border-radius: 50% !important;
}

.cropper-container .cropper-view-box {
  border-radius: 50% !important;
}
</style>
