<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <ellipse
          cx="256"
          cy="359.689"
          rx="37.981"
          ry="37.981"
          transform="matrix(.23 -.973 .973 .23 -152.884 526.201)"
          data-original="#000000"
        ></ellipse>
        <ellipse
          cx="75.333"
          cy="359.689"
          rx="37.981"
          ry="37.981"
          transform="matrix(.23 -.973 .973 .23 -292.042 350.368)"
          data-original="#000000"
        ></ellipse>
        <ellipse
          cx="436.667"
          cy="359.689"
          rx="37.981"
          ry="37.981"
          transform="matrix(.23 -.973 .973 .23 -13.726 702.035)"
          data-original="#000000"
        ></ellipse>
        <path
          d="m346.333 86.085c20.943 0 37.981-17.039 37.981-37.982s-17.038-37.981-37.981-37.981-37.981 17.039-37.981 37.981c0 20.943 17.038 37.982 37.981 37.982z"
          data-original="#000000"
        ></path>
        <path
          d="m165.667 86.085c20.943 0 37.981-17.039 37.981-37.982s-17.038-37.981-37.981-37.981-37.981 17.039-37.981 37.981c-.001 20.943 17.037 37.982 37.981 37.982z"
          data-original="#000000"
        ></path>
        <path
          d="m481.991 437.746-27.229-14.676h-36.191l-27.229 14.676c-18.51 9.977-30.009 29.238-30.009 50.265v13.868h150.667v-13.868c0-21.028-11.499-40.289-30.009-50.265z"
          data-original="#000000"
        ></path>
        <path
          d="m120.658 437.746-27.229-14.676h-36.192l-27.229 14.676c-18.509 9.976-30.008 29.237-30.008 50.265v13.868h150.667v-13.868c0-21.027-11.499-40.288-30.009-50.265z"
          data-original="#000000"
        ></path>
        <path
          d="m301.324 437.746-27.229-14.676h-36.191l-27.229 14.676c-18.51 9.977-30.009 29.237-30.009 50.265v13.868h150.666v-13.868c.001-21.028-11.498-40.289-30.008-50.265z"
          data-original="#000000"
        ></path>
        <path
          d="m241 175.688c0-21.028-11.499-40.288-30.009-50.265l-27.229-14.676h-36.191l-27.229 14.676c-18.51 9.977-30.009 29.238-30.009 50.265v15.478h150.667z"
          data-original="#000000"
        ></path>
        <path d="m0 220.836h512v30h-512z" data-original="#000000"></path>
        <path
          d="m421.667 175.688c0-21.027-11.499-40.288-30.009-50.265l-27.229-14.676h-36.191l-27.229 14.676c-18.51 9.977-30.009 29.237-30.009 50.265v15.478h150.667z"
          data-original="#000000"
        ></path>
      </g>
    </g>
  </svg>
</template>
