<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import groupStore from '@/store/group';

const props = withDefaults(
  defineProps<{
    modelValue?: any[];
    listTaskOwnerId?: number;
    maxItemShow?: number;
  }>(),
  {
    modelValue: () => [],
    listTaskOwnerId: 0,
    maxItemShow: 0,
  }
);
const emit = defineEmits([
  'onClose',
  'onConversationClick',
  'update:modelValue',
]);

const _groupStore = groupStore();

const mySameGroups = ref<any[]>([]);

const initData = () => {
  const sameGroups = _groupStore.getSameGroupByUserId(props.listTaskOwnerId);
  mySameGroups.value = sameGroups.map((group) => {
    return {
      ...group,
      isSelected: props.modelValue?.some((groupId) => groupId == group.id),
    };
  });
};
initData();

watch(
  () => props.listTaskOwnerId,
  () => {
    initData();
  }
);

watch(
  () => props.modelValue,
  () => {
    initData();
  }
);

onMounted(async () => {
  if (!_groupStore.allMyGroups || _groupStore.allMyGroups.length == 0) {
    await _groupStore.fetchMyGroups();
  }
});

const onUpdateModelValue = () => {
  const selectedGroups = mySameGroups.value.filter((o) => o.isSelected);
  emit(
    'update:modelValue',
    selectedGroups.map((o) => o.id)
  );
};
</script>
<template>
  <div v-if="mySameGroups.length <= maxItemShow" class="flex flex-col">
    <div v-if="mySameGroups?.length > 0" class="flex flex-wrap">
      <SynCheckboxTag
        v-for="group in mySameGroups"
        :key="group"
        v-model="group.isSelected"
        class="p-1"
        @update:model-value="onUpdateModelValue"
      >
        <div
          class="
            rounded-full
            text-xs
            p-1
            m-0.5
            flex-center
            space-x-2
            w-max
            float-left
            border border-current-50
            hover:bg-current-50 hover:text-current
          "
        >
          <SynAvatar
            custom-class="w-5 h-5"
            is-hidden-name
            :src="group?.avatar?.replace('original', 'mini')"
            :name="group?.name"
          />
          <span class="truncate" style="max-width: 8rem">
            qwdqwdqwd {{ group?.name }}
          </span>
        </div>
      </SynCheckboxTag>
    </div>
  </div>
</template>
