<script setup lang="ts">
import { computed } from 'vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import { SubActivityInTask } from '../../ui-enums/tasklog-enums';

const props = defineProps<{
  activity: any;
}>();

const subActivity = computed<SubActivityInTask>(() => {
  return props.activity?.subActivity;
});

const afterUpdate = computed<any>(() => {
  return covertSnakeCaseToCamelCase(props.activity?.afterUpdate);
});

const isParentTaskDeleted = computed<any>(
  () => afterUpdate.value?.parentTaskIsDeleted
);
</script>
<template>
  <span class="lowercase">
    <template v-if="subActivity == SubActivityInTask.ParentTaskAdded">
      <span v-html="$t('ACTIVITY_HISTORY_TASK_ACTION_PARENT_ADDED')"> </span>
    </template>

    <template v-else-if="subActivity == SubActivityInTask.ParentTaskRemoved">
      <span
        v-if="isParentTaskDeleted"
        v-html="$t('ACTIVITY_HISTORY_TASK_ACTION_DELETED_PARENT_REMOVE')"
      >
      </span>
      <span v-else v-html="$t('ACTIVITY_HISTORY_TASK_ACTION_PARENT_REMOVE')">
      </span>
    </template>
  </span>
</template>
