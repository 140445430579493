<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import Viewer from 'viewerjs';
import $constants from '@/ui/plugins/constants';

const props = defineProps<{
  name: string;
  path: string;
  size: string;
  isHiddenName: boolean;
}>();
let _imgViewer: any;

const fileImgRef = ref(null as any);
const imgSrc = ref(props.path);
// const isError = ref(false);

watch([() => props.path, () => props.size], () => {
  // console.log('🚀 ~ watch ~ _imgViewer', _imgViewer);
  // if (!_imgViewer) return;
  // _imgViewer.destroy();
  _processImageSrc();
  _initViewer();
});

onMounted(() => {
  _processImageSrc();
  _initViewer();
});

const onError = () => {
  if (imgSrc.value?.includes('original')) {
    imgSrc.value = props.path?.replace(
      'https://d1gwhdtl7sl1yr.cloudfront.net',
      'https://s3.ap-southeast-1.amazonaws.com/tictop.task-attachment-photo'
    );
  } else {
    imgSrc.value = props?.path;
  }
  // _initViewer();
  // _imgViewer.destroy();
  // _initViewer();
};

const onZoomClick = (value) => {
  _imgViewer && _imgViewer.zoom(value);
};

const onResetClick = () => {
  _imgViewer && _imgViewer.reset();
  _zoomToFit();
};

const _processImageSrc = () => {
  if (!props.size) return (imgSrc.value = props.path);

  imgSrc.value = props.path?.replace(
    `/${$constants.IMAGE.SIZE.ORIGINAL}/`,
    `/${props.size}/`
  );
};
const checkNaturalSizeAndUpdateMinZoomRatio = () => {
  const imageData = _imgViewer?.imageData;
  if (!imageData || !imageData?.naturalWidth || !imageData?.naturalHeight)
    return;

  const minWidth = 200;
  const minHeight = 100;
  const minZoomWidthRatio = minWidth / imageData?.naturalWidth;
  const minZoomHeightRatio = minHeight / imageData?.naturalHeight;

  _imgViewer.options.minZoomRatio = Math.min(
    minZoomWidthRatio,
    minZoomHeightRatio
  );
  _imgViewer.update();
};

const _initViewer = () => {
  if (_imgViewer) {
    // _imgViewer.hide(true);
    // _imgViewer.destroy();
    // if (!isError.value) {
    return setTimeout(() => {
      _imgViewer.update();

      checkNaturalSizeAndUpdateMinZoomRatio();
    });
    // } else {
    //   _imgViewer.hide(true);
    //   _imgViewer.destroy();
    // }
  }

  _imgViewer = new Viewer(fileImgRef.value, {
    inline: true,
    movable: true,
    zoomable: true,
    zoomOnWheel: true,
    button: false,
    toolbar: false,
    title: false,
    navbar: false,
    backdrop: false,
    zoomRatio: 0.5,
    transition: false,
    // minZoomRatio: 0.5, // case size very large, working not good
    maxZoomRatio: 3,
    loading: true,
    viewed() {
      // _zoomToFit();
    },
  });
  checkNaturalSizeAndUpdateMinZoomRatio();
};

const _zoomToFit = () => {
  const parentData = _imgViewer?.parentData;
  const imageData = _imgViewer?.imageData;

  const ratioW = (parentData?.width / imageData?.naturalWidth) * 0.9;
  const ratioH = (parentData?.height / imageData?.naturalHeight) * 0.9;
  _imgViewer?.zoomTo(Math.min(ratioW, ratioH, 1));

  // _imgViewer?.parentData and _imgViewer?.imageData are undefine, so result always is 1
  // _imgViewer.zoomTo(1);
};
</script>

<template>
  <div class="h-full bg-gray-800 relative flex-center">
    <img
      ref="fileImgRef"
      :src="imgSrc"
      class="hidden"
      alt=""
      @error="onError"
    />

    <div class="absolute right-5 bottom-0">
      <button
        class="
          p-2
          flex-center
          rounded-full
          shadow-lg
          bg-black bg-opacity-50
          hover:bg-opacity-100
          mb-2
        "
        @click="onResetClick"
      >
        <SynIcon name="Reset" custom-class="w-5 h-5 text-white" />
      </button>
      <button
        class="
          p-2
          flex-center
          rounded-full
          shadow-lg
          bg-black bg-opacity-50
          hover:bg-opacity-100
          mb-2
        "
        @click="onZoomClick(0.2)"
      >
        <SynIcon name="ZoomIn" class="w-5 h-5 fill-white" />
      </button>
      <button
        class="
          p-2
          flex-center
          rounded-full
          shadow-lg
          bg-black bg-opacity-50
          hover:bg-opacity-100
        "
        @click="onZoomClick(-0.2)"
      >
        <SynIcon name="ZoomOut" class="w-5 h-5 fill-white" />
      </button>
    </div>

    <div
      v-if="name && !isHiddenName"
      class="
        absolute
        right-20
        bottom-0
        px-3
        py-0.5
        rounded-md
        bg-black bg-opacity-50
        text-white
      "
    >
      {{ name }}
    </div>
  </div>
</template>

<style>
@import 'viewerjs/dist/viewer.min.css';

.viewer-container,
.viewer-canvas {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*width: 100% !important;*/
  /*height: 100% !important;*/
}

.viewer-canvas > img {
  /*width: auto !important;*/
  /*max-height: 100% !important;*/
  /*margin: 0 !important;*/
}
</style>
