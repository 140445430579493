<script setup lang="ts">
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import userStore from '@/ticket/store/user-store';
import groupStore from '@/ticket/store/group-store';
import taskStore from '@/ticket/store/task-store';
import TabContainer from '@/ui/common/molecules/SynTab/TabContainer.vue';
import {
  StorageConstant,
  setLocalStorage,
  getLocalStorage,
} from '@/ui/hooks/storageHook';
import organizationStore from '@/ticket/store/organization-store';
import { TicketSingleton } from '@/ticket/services/organization-service';
import { onChangeI18n } from '@/ui/plugins/i18n/myi18n';
import ticketPlugin from '@/ticket/services/plugin-services';
import { updateDeviceToken } from '@/ui/plugins/firebases/firebaseFcm';
import SettingLanguageSmallCircle from '@/ui/components/setting/SettingLanguageSmallCircle.vue';
import UserDynamicModel from '@/ticket/services/firebase/UserDynamicModel';
import { setDataFirebase } from '@/ui/plugins/firebases/firebaseRealtimeDatabase';

const router = useRouter();
const route = useRoute();
const _organizationStore = organizationStore();
document.onmouseup = () => {
  ticketPlugin.closeDragElement();
};

ticketPlugin.sentMeToken();

ticketPlugin.onListenGetToken(async () => {
  await initDashboardData();
  onListenTotalMyTaskNotificationFirebase();
});

const _userStore = userStore();
const _groupStore = groupStore();
const _taskStore = taskStore();

const isLoading = ref(true);

const currentTabId = ref(1);

const invalidToken = ref(false);

const initDashboardData = async () => {
  try {
    currentTabId.value =
      route.name === 'TicketTasksHistories'
        ? 2
        : route.name === 'TicketMyPage'
        ? 3
        : 1;
    isLoading.value = true;
    await _organizationStore.initData();
    const ticketInstance = TicketSingleton.getInstance();
    const promise = [
      _userStore.getUsers(),
      _userStore.getDeactivatedUsers(),
      _groupStore.fetchMyGroups(),
      _taskStore.fetchDataConfig(),
      _taskStore.getCurrentTaskCycle(),
      _userStore.getScopeListByUserId(ticketInstance.userId),
    ];

    await Promise.all(promise);

    invalidToken.value = false;
    isLoading.value = false;
  } catch (error) {
    console.log('🚀', error);
    // invalid token
    invalidToken.value = true;
    isLoading.value = false;
  }
};

const updateDataFirebaseSeenAll = async () => {
  const organizationId = TicketSingleton.getInstance().organizationId;
  const userId = TicketSingleton.getInstance().userId;
  if (!organizationId || !userId) return;

  const companyId = `organization_${organizationId}`;
  const userPath = `user_${userId}`;

  await setDataFirebase(
    `users_dynamic_info/${companyId}/${userPath}/totalMyTaskNotification`,
    0
  );
};

const onChangeTab = (tab) => {
  currentTabId.value = tab.id;

  router.push({ name: tab?.routerName });

  if (tab?.id == 2 && totalNotification.value > 0) updateDataFirebaseSeenAll();
};
const currentTabs = [
  {
    id: 1,
    name: 'Ticket',
    routerName: 'TicketTasksNew',
  },
  {
    id: 2,
    name: 'Histories',
    nameCode: 'COMMON_LABEL_HISTORIES',
    routerName: 'TicketTasksHistories',
  },
  // {
  //   id: 3,
  //   name: 'My page',
  //   nameCode: 'COMMON_LABEL_MY_PAGE',
  //   routerName: 'EmbedPage',
  // },
];

const onChangeLanguage = (key) => {
  setLocalStorage(StorageConstant.LANGUAGE, key);

  onChangeI18n(key);

  updateDeviceToken(key);
};
onChangeLanguage(
  getLocalStorage(StorageConstant.LANGUAGE)
    ? getLocalStorage(StorageConstant.LANGUAGE)
    : 'fr'
);

let unsubscribeTotalMyTaskNotification;
const totalNotification = ref(0);
const onListenTotalMyTaskNotificationFirebase = () => {
  if (unsubscribeTotalMyTaskNotification) unsubscribeTotalMyTaskNotification();
  const organizationId = TicketSingleton.getInstance().organizationId;
  const userId = TicketSingleton.getInstance().userId;
  if (!organizationId || !userId) return;

  const realtimeClass = new UserDynamicModel(
    organizationId,
    userId,
    'totalMyTaskNotification'
  );

  unsubscribeTotalMyTaskNotification = realtimeClass.onChange((data) => {
    ticketPlugin.pushNotification(data);

    totalNotification.value = data;
  });
};
</script>
<template>
  <syn-loading v-if="isLoading"></syn-loading>
  <div v-else class="w-screen h-screen bg-transparent flex-center bg-white">
    <TabContainer>
      <template #header>
        <div class="w-full flex-center bg-gray-200">
          <ul class="w-full flex-center cursor-pointer text-sm">
            <li
              v-for="tab in currentTabs"
              :key="tab.id"
              :class="
                tab.id == currentTabId
                  ? 'bg-white font-semibold text-current'
                  : 'text-current-900 bg-gray-200 hover:bg-gray-100'
              "
              class="hover-to-show__parent flex-center px-4 py-1 relative"
              @click="onChangeTab(tab)"
            >
              {{ $t(tab.nameCode) || tab.name }}
              <span
                v-if="tab.id == 2 && totalNotification > 0"
                class="
                  absolute
                  top-0
                  -right-1
                  rounded-full
                  w-4
                  h-4
                  bg-orange-500
                  flex-center
                  text-2xs text-white
                "
              >
                {{ totalNotification }}
              </span>
            </li>
          </ul>
        </div>
      </template>
      <template #actions>
        <div class="flex-center px-2">
          <SettingLanguageSmallCircle is-hidden-label>
            <template #current="{ icon }">
              <SynIcon :name="icon" has-action />
            </template>
          </SettingLanguageSmallCircle>
        </div>
      </template>
      <template #body>
        <div
          v-if="invalidToken && currentTabId !== 3"
          class="flex-center h-2/3 text-2xl font-semibold"
        >
          {{
            $t('TICKET_INVALID_TOKEN') ||
            'Invalid or expired security context token'
          }}
        </div>
        <div v-else class="py-2 h-full">
          <router-view></router-view>
        </div>
      </template>
    </TabContainer>
  </div>
</template>
