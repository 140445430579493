<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0,0,256,256"
    width="48px"
    height="48px"
  >
    <g transform="translate(-25.6,-25.6) scale(1.2,1.2)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.33333,5.33333)">
          <path
            d="M38.59,39c-0.535,0.93 -0.298,1.68 -1.195,2.197c-0.897,0.518 -1.93,0.803 -3.005,0.803h-20.78c-1.074,0 -2.106,-0.285 -3.004,-0.802c-0.898,-0.517 -0.661,-1.268 -1.196,-2.198l7.67,-9h13.84z"
            fill="#1e88e5"
          ></path>
          <path
            d="M27.463,6.999c1.073,-0.002 2.104,-0.716 3.001,-0.198c0.897,0.519 1.66,1.27 2.197,2.201l10.39,17.996c0.537,0.93 0.807,1.967 0.808,3.002c0.001,1.037 -1.267,2.073 -1.806,3.001l-11.127,-3.005l-6.924,-11.993z"
            fill="#fbc02d"
          ></path>
          <path
            d="M43.86,30c0,1.04 -0.27,2.07 -0.81,3l-3.67,6.35c-0.53,0.78 -1.21,1.4 -1.99,1.85l-6.47,-11.2z"
            fill="#e53935"
          ></path>
          <path
            d="M5.947,33.001c-0.538,-0.928 -1.806,-1.964 -1.806,-3c0.001,-1.036 0.27,-2.073 0.808,-3.004l10.39,-17.996c0.537,-0.93 1.3,-1.682 2.196,-2.2c0.897,-0.519 1.929,0.195 3.002,0.197l3.459,11.009l-6.922,11.989z"
            fill="#4caf50"
          ></path>
          <path
            d="M17.08,30l-6.47,11.2c-0.78,-0.45 -1.46,-1.07 -1.99,-1.85l-3.67,-6.35c-0.54,-0.93 -0.81,-1.96 -0.81,-3z"
            fill="#1565c0"
          ></path>
          <path
            d="M30.46,6.8l-6.46,11.2l-6.47,-11.2c0.78,-0.45 1.66,-0.73 2.6,-0.79l7.33,-0.01c1.08,0 2.11,0.28 3,0.8z"
            fill="#2e7d32"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
