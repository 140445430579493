export interface IReportCard {
    id: string;
    title: string;
    titleCode: string;
    type: EReportCardType;
    index: number;
    width: string;
    height: string;
    filters: any; // need define more
    orders?: any; // need define more
    metadata: any;
    configs: any;
}

export enum EReportCardType {
    COMPLETED_TASKS = 'COMPLETED_TASKS',
    INCOMPLETE_TASKS = 'INCOMPLETE_TASKS',
    OVERDUE_TASKS = 'OVERDUE_TASKS',
    TOTAL_TASKS = 'TOTAL_TASKS',
    TASK_STATUS = 'TASK_STATUS',
    TASK_PERFORMANCE = 'TASK_PERFORMANCE',
    DOMAIN_TASKS = 'DOMAIN_TASKS',
    DOMAIN_OVERDUE_TASKS = 'DOMAIN_OVERDUE_TASKS',
    EXCELLENCE_USERS = 'EXCELLENCE_USERS',
    ACTIVE_USERS = 'ACTIVE_USERS',
    MOST_OVERDUE = 'MOST_OVERDUE',
    MOST_TASKS = 'MOST_TASKS',
    CREATING_MOST = 'CREATING_MOST',
    ASSIGNED_MOST = 'ASSIGNED_MOST',
}
export enum EReportChartType {
    COLUMN = 'COLUMN',
    LINE = 'LINE',
    DONUT = 'DONUT',
    NUMBER = 'NUMBER',
    LOLLIPOP = 'LOLLIPOP',
    TABLE = 'TABLE',
}
