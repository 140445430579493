/* eslint-disable no-unused-vars */
export enum storeId {
    app = 'APP',
    task = 'TASK',
    taskDrawer = 'TASK_DRAWER',
    taskDetail = 'TASK_DETAIL',
    TASK_PINNED = 'TASK_PINNED',
    taskCreateForm = 'TASK_CREATE_FORM',
    taskList = 'TASK_LIST',
    TASK_MANAGEMENT = 'TASK_MANAGEMENT',
    TASK_CUSTOM_CONFIG = 'TASK_CUSTOM_CONFIG',
    taskActivity = 'TASK_ACTIVITY',
    taskComment = 'TASK_COMMENT',
    group = 'GROUP',
    DEPARTMENT = 'DEPARTMENT',
    user = 'USER',
    domain = 'DOMAIN',
    OVERVIEW_DOMAINS = 'OVERVIEW_DOMAINS',
    principle = 'PRINCIPLE',
    setting = 'SETTING',
    auth = 'AUTH',
    UN_AUTH = 'UN_AUTH',
    PAGE_LOGIN = 'PAGE_LOGIN',
    AUTH_REGISTER = 'AUTH_REGISTER',
    AUTH_MY_PROFILE = 'AUTH_MY_PROFILE',
    organization = 'ORGANIZATION',
    ORGANIZATION_INVITE_USER = 'ORGANIZATION_INVITE_USER',
    chat = 'CHAT',
    CHAT_PANEL = 'CHAT_PANEL',
    chatExternal = 'CHAT_EXTERNAL',
    chatSupporting = 'CHAT_SUPPORTING',
    dashboard = 'DASHBOARD',
    DASHBOARD_MONITOR = 'DASHBOARD_MONITOR',
    DASHBOARD_MONITORING_DEPARTMENT = 'DASHBOARD_MONITORING_DEPARTMENT',
    dashboardTeam = 'DASHBOARD_TEAM',
    dashboardGroup = 'DASHBOARD_GROUP',
    dashboardFinishedTask = 'DASHBOARD_FINISHED_TASK',
    dashboardCreatedTask = 'DASHBOARD_CREATED_TASK',
    DASHBOARD_PERFORMANCE = 'DASHBOARD_PERFORMANCE',
    remoteConfig = 'REMOTE_CONFIG',
    security = 'SECURITY',
    video = 'VIDEO',
    fileStorage = 'FILE_STORAGE',
    alert = 'ALERT',
    calendar = 'CALENDAR',
    CALENDAR_SYNC = 'CALENDAR_SYNC',
    conference = 'CONFERENCE',
    system = 'SYSTEM',
    DAYOFF_OVERVIEW = 'DAYOFF_OVERVIEW',
    DAYOFF_CREATE = 'DAYOFF_CREATE',
    DAYOFF_DETAIL = 'DAYOFF_DETAIL',
    DAYOFF_STATISTICS = 'DAYOFF_STATISTICS',
    CALENDARS_OVERVIEW = 'CALENDARS_OVERVIEW',
    DOMAIN_MANAGEMENT = 'DOMAIN_MANAGEMENT',
    PLANS_SETTING = 'PLANS_SETTING',
    PAYMENT_SETTING = 'PAYMENT_SETTING',
    export = 'EXPORT',
    import = 'IMPORT',
    startWorking = 'startWorking',
    taskTabView = 'TASK_TAB_VIEW',
    TASK_SMART_VIEW = 'TASK_SMART_VIEW',
    PERMISSION = 'PERMISSION',
    audio = 'AUDIO',
    orgNotifGlobal = 'ORG_NOTIF_GLOBAL',
    LOCAL_CONFIGS = 'LOCAL_CONFIGS',
    STATISTIC = 'STATISTIC',
    WORKFLOW = 'WORKFLOW',
    WORKFLOW_STEP = 'WORKFLOW_STEP',
    WHITEBOARD = 'WHITEBOARD',
    DRIVE_DOCUMENT = 'DRIVE_DOCUMENT',
    DRIVE_DOCUMENT_PAGE = 'DRIVE_DOCUMENT_PAGE',

    // embed
    embedUser = 'EMBED_USER',
    embedGroup = 'EMBED_GROUP',
    embedOrganization = 'EMBED_ORGANIZATION',
    embedTask = 'EMBED_TASK',
    embedRemoteConfig = 'EMBED_REMOTE_CONFIG',
    // ticket
    ticketUser = 'TICKET_USER',
    TICKET_MY_PROFILE = 'TICKET_MY_PROFILE',
    ticketGroup = 'TICKET_GROUP',
    ticketOrganization = 'TICKET_ORGANIZATION',
    ticketTask = 'TICKET_TASK',
    ticketRemoteConfig = 'TICKET_REMOTE_CONFIG',
    ticketComment = 'TICKET_COMMENT',

    //support
    supportCustomer = 'SUPPORT',
}

export default storeId;
