<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import AnalogClock from './AnalogClock.vue';
import AnalogClockSimple from './AnalogClockSimple.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = withDefaults(
  defineProps<{
    currentTime: {
      hour: string;
      minute: string;
      period: 'AM' | 'PM';
    };
    timezone: string;
    options?: {
      isShowTimezoneInput: boolean;
      isShowInput: boolean;
      isShowClock: boolean;
      isShowClockWhenFocusOnInput: boolean;
      timezoneList: any[];
    };
  }>(),
  {
    options: () => {
      return {
        isShowTimezoneInput: true,
        isShowInput: true,
        isShowClock: true,
        isShowClockWhenFocusOnInput: false,
        timezoneList: () => [],
      };
    },
  }
);

const emit = defineEmits<{
  (
    e: 'onChange',
    value: {
      hour: string;
      minute: string;
      period: 'AM' | 'PM';
      timezone: string;
    }
  ): void;
}>();

const currentOptions = computed<{
  isShowTimezoneInput: boolean;
  isShowInput: boolean;
  isShowClock: boolean;
  isShowClockWhenFocusOnInput: boolean;
}>(() => {
  return {
    ...props.options,
  };
});

const initData = () => {
  const { hour, period } = formatData(
    props.currentTime?.hour,
    props.currentTime?.hour,
    props.currentTime?.period
  );
  currentModelValue.value = {
    hour: hour,
    minute: props.currentTime?.minute
      ? `0${props.currentTime?.minute}`.slice(-2)
      : '00',
    period: period,
    timezone: props?.timezone || '',
  };
  updateClock();
};

watch(
  () => props.currentTime,
  (newCurrentTime: { hour: string; minute: string; period: 'AM' | 'PM' }) => {
    if (
      newCurrentTime.hour == currentModelValue.value?.hour &&
      newCurrentTime.minute == currentModelValue.value?.minute &&
      newCurrentTime.period == currentModelValue.value?.period
    )
      return;

    initData();
  },
  {
    deep: true,
  }
);

const currentModelValue = ref<{
  hour: string;
  minute: string;
  period: 'AM' | 'PM';
  timezone: string;
}>({
  hour: '',
  minute: '',
  period: 'AM',
  timezone: props?.timezone || '',
});

const formatData = (originHour, hourString, period) => {
  let _hour = hourString ? parseInt(hourString) : 12,
    _period = period;

  if (_hour > 24) {
    _hour = originHour;
  }
  if (_hour > 12) {
    _hour = _hour - 12;
    _period = 'PM';
  }

  return {
    hour: `0${_hour}`.slice(-2),
    period: _period,
  };
};

const handleOnChange = (event: any) => {
  const { hour, period } = formatData(
    currentModelValue.value.hour,
    event?.target?.value,
    currentModelValue.value.period
  );
  event.target.value = hour;

  currentModelValue.value.hour = hour;
  currentModelValue.value.period = period;

  updateClock();
  updateModelValue(currentModelValue.value);
};
const handleOnChangeMinute = (event: any) => {
  const inputValue = event?.target?.value;
  if (inputValue > 59) {
    event.target.value = currentModelValue.value.minute;
    return;
  }

  currentModelValue.value.minute = `0${inputValue}`.slice(-2);

  updateClock();
  updateModelValue(currentModelValue.value);
};

const analogClockRef = ref<any>(null);
const analogSimpleClockRef = ref<any>(null);
const updateClock = () => {
  if (
    analogClockRef.value &&
    typeof analogClockRef.value?.initData == 'function'
  )
    analogClockRef.value?.initData(currentModelValue.value);
  if (
    analogSimpleClockRef.value &&
    typeof analogSimpleClockRef.value?.initData == 'function'
  )
    analogSimpleClockRef.value?.initData(currentModelValue.value);
};
const updateModelValue = (data: {
  hour: string;
  minute: string;
  period: 'AM' | 'PM';
}) => {
  emit('onChange', {
    ...data,
    timezone: currentModelValue.value.timezone,
  });
};

const onChangePeriod = (period) => {
  currentModelValue.value.period = period;

  updateModelValue(currentModelValue.value);

  updateClock();
};

const selectType = ref<'SELECT_HOUR' | 'SELECT_MINUTE'>('SELECT_HOUR');
const onFocusOnMinuteInput = (focused: boolean) => {
  selectType.value = focused ? 'SELECT_MINUTE' : 'SELECT_HOUR';
  setTimeout(() => {
    updateClock();
  });
};

const timezoneDataByKey = {
  'Asia/Ho_Chi_Minh': {
    text: '(UTC+07:00) Hanoi',
    textCode: 'COMMON_LABEL_TIMEZONE_HANOI',
    timezone: 'Asia/Ho_Chi_Minh',
  },
  'Europe/Paris': {
    text: '(UTC+01:00) Paris',
    textCode: 'COMMON_LABEL_TIMEZONE_PARIS',
    timezone: 'Europe/Paris',
  },
};

const selectedTimezone = ref<{
  text: string;
  timezone: string;
}>({
  text:
    translate(timezoneDataByKey[props.timezone]?.textCode) ||
    timezoneDataByKey[props.timezone]?.text ||
    'Asia/Ho_Chi_Minh',
  timezone: props.timezone || 'Asia/Ho_Chi_Minh',
});

const onSelectTimezone = (utc) => {
  selectedTimezone.value = utc;
  currentModelValue.value.timezone = utc?.timezone;

  updateModelValue(currentModelValue.value);
};

initData();
</script>
<template>
  <div class="flex flex-col gap-4 rss-time-picker w-[16rem]">
    <!-- Time input -->

    <div
      v-if="
        currentOptions?.isShowTimezoneInput && options?.timezoneList?.length > 1
      "
      class="flex-center w-full truncate text-sm"
    >
      <VigSelect
        :placeholder="'Select timezone'"
        key-search="text"
        key-label="text"
        toggle-class="py-1"
        :label-value="selectedTimezone.text"
        :origin-options="options?.timezoneList"
        @on-choose="onSelectTimezone"
      />
    </div>
    <div
      v-if="
        currentOptions?.isShowInput ||
        (!currentOptions?.isShowInput && !currentOptions?.isShowClock)
      "
      class="flex-center gap-2"
    >
      <div class="flex-center space-x-2">
        <input
          ref="inputHourRef"
          :value="currentModelValue.hour"
          class="
            w-20
            h-16
            rounded-xl
            text-current-600
            bg-opacity-80
            font-medium
            focus:outline-none
            flex
            items-center
            text-center
            border border-gray-50
            focus:border-current-100 focus:ring-current-100 focus:bg-opacity-100
          "
          :class="
            selectType == 'SELECT_HOUR' ? 'bg-current-100' : 'bg-current-50'
          "
          min="0"
          max="12"
          maxlength="3"
          pattern="[0-12]"
          style="font-size: 2rem"
          type="number"
          @input="handleOnChange"
          @click.stop="onFocusOnMinuteInput(false)"
        />
        <span class="font-medium text-2xl"> : </span>
        <input
          ref="inputMinuteRef"
          :value="currentModelValue.minute"
          class="
            w-20
            h-16
            rounded-xl
            bg-opacity-80
            text-current-600
            font-medium
            focus:outline-none
            flex
            items-center
            text-center
            border border-gray-50
            focus:border-current-100 focus:ring-current-100 focus:bg-opacity-100
          "
          :class="
            selectType == 'SELECT_MINUTE' ? 'bg-current-100' : 'bg-current-50'
          "
          min="0"
          max="12"
          maxlength="3"
          type="number"
          pattern="[0-12]"
          style="font-size: 2rem"
          @input="handleOnChangeMinute"
          @click.stop="onFocusOnMinuteInput(true)"
        />
      </div>

      <div class="h-16 flex flex-col rounded-md border divide-y">
        <span
          class="h-1/2 px-2 leading-8 cursor-pointer"
          :class="
            currentModelValue.period == 'AM'
              ? 'bg-current-100 text-current-600 hover:text-current-500 font-semibold'
              : 'hover:bg-current-50 hover:text-current-500'
          "
          @click.stop="onChangePeriod('AM')"
        >
          AM
        </span>
        <span
          class="h-1/2 px-2 leading-8 cursor-pointer"
          :class="
            currentModelValue.period == 'PM'
              ? 'bg-current-100 text-current-600 hover:text-current-500 font-semibold'
              : 'hover:bg-current-50 hover:text-current-500'
          "
          @click.stop="onChangePeriod('PM')"
        >
          PM
        </span>
      </div>
    </div>

    <!-- Clock -->
    <div v-if="currentOptions?.isShowClock" class="flex-center">
      <AnalogClockSimple
        v-if="options?.isShowClockWhenFocusOnInput"
        ref="analogSimpleClockRef"
        v-model="currentModelValue"
        :select-type="selectType"
        is-have-action
        @update:model-value="updateModelValue"
      />
      <AnalogClock
        v-else
        ref="analogClockRef"
        v-model="currentModelValue"
        is-have-action
        @update:model-value="updateModelValue"
      />
    </div>
  </div>
</template>
<style>
.rss-time-picker input::-webkit-outer-spin-button,
.rss-time-picker input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.rss-time-picker input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
