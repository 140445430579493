<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 471.97 405.33"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          class="cls-1"
          d="m194.12,103.18h-79.72c-11.87,0-21.5,9.63-21.5,21.5s9.63,21.5,21.5,21.5h79.72c11.87,0,21.5-9.63,21.5-21.5s-9.63-21.5-21.5-21.5h0Z"
        />
        <path
          class="cls-1"
          d="m284.69,181.93H114.4c-11.87,0-21.5,9.63-21.5,21.5s9.63,21.5,21.5,21.5h170.28c11.87,0,21.5-9.63,21.5-21.5s-9.63-21.5-21.5-21.5h0Z"
        />
        <path
          class="cls-1"
          d="m316.11,362.33H107.03c-35.3,0-64.03-28.72-64.03-64.03V107.03c0-35.3,28.72-64.03,64.03-64.03h212.61c35.3,0,64.03,28.72,64.03,64.03v107.05c4.35-.6,8.79-.92,13.3-.92h0c10.36,0,20.34,1.64,29.7,4.66v-110.8c0-59.11-47.92-107.03-107.03-107.03H107.03C47.92,0,0,47.92,0,107.03v191.28c0,59.11,47.92,107.03,107.03,107.03h212.61c13.65,0,26.7-2.56,38.71-7.22-17.33-7.58-32-20.1-42.24-35.78Z"
        />
        <rect
          class="cls-1"
          x="321.97"
          y="234.66"
          width="150"
          height="150"
          rx="72.18"
          ry="72.18"
        />
      </g>
    </g>
  </svg>
</template>
