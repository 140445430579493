<template>
  <div
    class="h-full flex flex-col"
    :class="
      isFileChanged
        ? 'bg-current-100'
        : 'bg-white transition-colors duration-2000'
    "
  >
    <!--ACTIONS-->
    <div v-if="file" class="p-2 flex justify-end">
      <button
        v-if="
          file?.contentType !== $constants.FILE.TYPE.FOLDER &&
          file?.contentType !== $constants.FILE.TYPE.LINK &&
          !isGoogleFile(file?.contentType)
        "
        class="w-9 h-9 flex-center rounded-full hover:bg-gray-200 mr-1"
        @click="onDownloadClick"
      >
        <SynIcon name="Download" custom-class="w-5 h-5 fill-gray-500" />
      </button>
      <button
        class="w-9 h-9 flex-center rounded-full hover:bg-gray-200"
        @click="$emit('onCloseClick')"
      >
        <SynIcon name="Close" custom-class="w-5 h-5 fill-gray-500" />
      </button>
    </div>

    <!--FILE PREVIEW-->
    <div
      class="h-72 p-2 flex-center relative"
      :class="{
        'cursor-pointer': file?.contentType !== $constants.FILE.TYPE.FOLDER,
      }"
      @click="onFilePreviewClick"
    >
      <VigImage
        v-if="
          file?.path &&
          file?.contentType?.startsWith($constants.FILE.TYPE.IMAGE)
        "
        class="max-h-full max-w-full shadow"
        :path="file?.pathUrl"
        :size="$constants.IMAGE.SIZE.MEDIUM"
      />
      <audio
        v-else-if="
          file?.path &&
          file?.contentType?.startsWith($constants.FILE.TYPE.AUDIO)
        "
        class="rounded-full shadow"
        :src="file?.pathUrl"
        controls
      />
      <VigVideo
        v-else-if="
          file?.path &&
          file?.contentType?.startsWith($constants.FILE.TYPE.VIDEO)
        "
        class="shadow"
        :path="file?.pathUrl"
        :prevent-fullscreen="true"
        :click-to-play="false"
      />

      <div v-else class="w-28 h-28 relative">
        <file-icon
          :file-type="file?.contentType"
          class-name="w-full h-full fill-gray-500 text-gray-500"
        ></file-icon>
        <div
          v-if="
            file?.systemType === $constants.FILE.SYSTEM_TYPE.GOOGLE_DRIVE_FOLDER
          "
          class="
            absolute
            bottom-5
            right-3
            rounded-full
            bg-white
            flex-center
            p-1
            shadow
          "
        >
          <SynIcon name="GoogleDrive" />
        </div>
        <div
          v-if="file?.systemType === $constants.FILE.SYSTEM_TYPE.SHARED_FOLDER"
          class="
            absolute
            bottom-5
            right-3
            rounded-full
            bg-white
            flex-center
            p-1
            shadow
          "
        >
          <SynIcon name="Share" class="fill-current-500" />
        </div>
      </div>

      <div
        v-if="file && file?.sourceFrom !== $constants.FILE.SOURCE_FROM.STORAGE"
        class="
          absolute
          bottom-2
          right-2
          bg-gray-300
          rounded-full
          w-8
          h-8
          flex-center
        "
      >
        <SynIcon name="Lock" custom-class="w-5 h-5" />
      </div>
    </div>

    <!--FILE INFO-->
    <div class="px-4 py-3 text-center">
      <div v-if="!isRenamingFile" class="text-lg leading-5 break-all mb-2">
        {{ file?.name }}
        <vig-button
          v-if="canRename"
          ghost
          color="blue"
          padding="p-2"
          rounded="rounded-full"
          class="inline-flex"
          @click="isRenamingFile = true"
        >
          <SynIcon name="Edit" is-active />
        </vig-button>
      </div>
      <FileRename
        v-else
        :file="file"
        @on-cancel="isRenamingFile = false"
        @on-rename="onRenameConfirm"
      />
      <div
        v-if="
          file?.contentType === $constants.FILE.TYPE.LINK ||
          isGoogleFile(file?.contentType)
        "
        class="text-center"
      >
        <a
          :href="file?.link"
          target="_blank"
          class="text-current-500 text-sm break-all"
        >
          {{ file?.link }}</a
        >
      </div>
    </div>

    <!--CREATION INFO-->
    <div class="px-4 py-3 border-t">
      <table>
        <tbody>
          <tr>
            <td class="text-gray-400">
              {{ $t('COMMON_LABEL_CREATED_DATE') }}:
            </td>
            <td class="px-3">
              <SynLabelDateTime
                format="date_time"
                ignore-timezone
                :datetime="file?.creationTime"
              />
            </td>
          </tr>
          <tr>
            <td class="text-gray-400">{{ $t('GED_CREATOR_NAME') }}:</td>
            <td class="px-3">{{ getCreatorNameById(file?.creatorId) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--GOOGLE USER INFO-->
    <div
      v-if="isGoogleFile(file?.contentType) && file?.metadata?.driveUserEmail"
      class="px-4 py-3 border-t"
    >
      <div class="mb-2 text-gray-400">{{ $t('GED_GOOGLE_ACCOUNT') }}</div>
      <GoogleUserInfo :user="file?.metadata" />
    </div>

    <!--TASK INFO-->
    <div
      v-if="file?.metadata?.taskId"
      class="px-4 py-3 border-t cursor-pointer hover:bg-gray-100"
      @click="onOpenTaskDetail(file?.metadata)"
    >
      <div class="text-current font-semibold">
        {{
          $t('GED_FILE_TASK_CODE', {
            code: formatTaskCode(file?.metadata?.taskCode),
          })
        }}
      </div>
      <div>{{ file?.metadata?.taskName }}</div>
      <div class="text-sm text-gray-400">
        <SynLabelDateTime
          format="date_time"
          :datetime="file?.metadata?.taskCreationTime"
        />
      </div>
    </div>

    <div class="border-t"></div>

    <ModalFileViewerWithInfo
      v-if="isShowViewer"
      :file="file"
      @on-close="isShowViewer = false"
      @on-info-click="isShowViewer = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { saveAs } from 'file-saver';
import FileIcon from '../_commons/file-icon/FileIcon.vue';
import ModalFileViewerWithInfo from '../modal-file-viewer/ModalFileViewerWithInfo.vue';
import $constants from '../../../plugins/constants';
import axios from 'axios';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import VigButton from '../../../common/atoms/VigButton/VigButton.vue';
import FileRename from '../_commons/file-rename/FileRename.vue';
import fileStorageStore from '@/store/fileStorage';
import getUserInfo from '@/ui/helpers/user-helper';
import { isGoogleFile } from '@/ui/hooks/fileHook';
import GoogleUserInfo from '@/ui/components/google/GoogleUserInfo.vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';

export default defineComponent({
  components: {
    GoogleUserInfo,
    FileRename,
    VigButton,
    ModalFileViewerWithInfo,
    FileIcon,
  },
  props: {
    file: {
      type: Object,
      default: null,
      required: true,
    },
  },
  emits: ['onCloseClick', 'onRename'],
  setup(props, { emit }) {
    const _fileStorageStore = fileStorageStore();

    const isShowViewer = ref(false);
    const isFileChanged = ref(false);
    const isRenamingFile = ref(false);

    const canRename = computed(
      () =>
        !props.file?.deleted &&
        props.file?.systemType !== $constants.FILE.SYSTEM_TYPE.SHARED_FOLDER &&
        props.file?.systemType !==
          $constants.FILE.SYSTEM_TYPE.GOOGLE_DRIVE_FOLDER
    );

    watch(
      () => props.file,
      () => {
        _processFileChangedEffect();

        isRenamingFile.value = false;
      }
    );

    onMounted(() => {
      _processFileChangedEffect();
    });

    const onDownloadClick = async () => {
      if (!props.file?.pathUrl) return;

      try {
        const res = await axios.get(props.file.pathUrl, {
          responseType: 'blob',
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        });
        saveAs(res.data, props.file.name);
      } catch (e) {
        // console.log(e);
      }
    };

    const onFilePreviewClick = () => {
      if (props.file?.contentType === $constants.FILE.TYPE.FOLDER) return;

      if (props.file?.contentType === $constants.FILE.TYPE.LINK) {
        return window.open(props.file?.link, '_blank').focus();
      }

      isShowViewer.value = true;
    };

    const onRenameConfirm = async (fileName) => {
      const result = await _fileStorageStore.renameFile({
        id: props.file?.id,
        contentType: props.file?.contentType,
        name: fileName,
      });

      emit('onRename', result);

      isRenamingFile.value = false;
    };

    const _processFileChangedEffect = () => {
      isFileChanged.value = true;

      setTimeout(() => (isFileChanged.value = false));
    };
    const onOpenTaskDetail = (metadata) => {
      taskCodeGlobal.value = metadata?.taskCode;
      isShowTaskDetailGlobal.value = true;
    };

    const getCreatorNameById = (userId) => {
      return getUserInfo(userId)?.name;
    };

    return {
      isShowViewer,
      isFileChanged,
      isRenamingFile,
      canRename,
      onDownloadClick,
      onFilePreviewClick,
      onRenameConfirm,
      onOpenTaskDetail,
      getCreatorNameById,
    };
  },
  methods: {
    formatTaskCode() {
      return formatTaskCode;
    },
    isGoogleFile,
  },
});
</script>
