import { AdminType } from '@/ui/common/constants/constant';

export interface IUserDetail {
    id: number;
    publicId: string;
    publicMemberId: string;
    firstName: string;
    lastName: string;
    avatar: string;
    avatarUrl: string;
    avatarThumbnailUrl: string;
    isShowInAdministration: boolean;
    active: boolean;
    timeOt: null;
    birthday: null;
    address: null;
    description: null;
    holiday: null;
    email: string;
    identityCard: null;
    skyper: null;
    phone: string;
    login?: string;
    username: string;
    utc: string;
    isWorking: null;
    adminType: AdminType;
    currentSettingProgress: null;
    isCreateUser: boolean;
    adminTypeName: string;
    positionId: number;
    positionName: null;
    profileId: number;
    gender: null;
    password: null;
    hasPassword: boolean;
    notificationInfos: any[];
    departmentShortInfos: any[];
    organizationId: number;
    organizationCode: null;
    fullName: string;
    departmentInfo: any[];
}

export const adminTypeByKey = {
    [AdminType.Owner]: {
        key: AdminType.Owner,
        text: 'Owner',
        textCode: 'COMMON_LABEL_OWNER',
    },
    [AdminType.Admin]: {
        key: AdminType.Admin,
        text: 'Admin',
        textCode: 'COMMON_LABEL_ADMIN',
    },
    [AdminType.User]: {
        key: AdminType.User,
        text: 'User',
        textCode: 'USERMANAGEMENT_FORM_TYPE_USER',
    },
    [AdminType.Visitor]: {
        key: AdminType.Visitor,
        text: 'Visitor',
        textCode: 'COMMON_LABEL_VISITOR',
    },
};
