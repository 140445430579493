import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { IBaseNotification } from '@/ui/hooks/commonHook';
export default defineStore({
    id: storeId.alert,
    state: () => ({
        dataAlerts: [] as IBaseNotification[],
        rightBottomAlerts: [] as IBaseNotification[],
        nextId: 0 as number,
    }),
    getters: {},
    actions: {
        pushDataAlerts(data) {
            this.dataAlerts = [
                ...this.dataAlerts,
                { ...data, id: this.nextId++ },
            ];
        },
        removeDataAlerts(item) {
            this.dataAlerts = this.dataAlerts.filter(
                (alert) => alert.id !== item.id
            );
        },
        handleClickAlertItem(item) {
            if (typeof item?.callback === 'function') {
                // do something
                item?.callback();
                this.removeDataAlerts(item);
            }
        },
    },
});
