import { TaskSchedule } from '@/domain/entities/TaskType';
import { ScheduleDayOptions, PlanTypeEnum } from '@/domain/enums/taskEnum';

export function getDefaultTaskSchedule(): TaskSchedule {
    return {
        doItToday: false,
        finishItToday: false,
        overdue: null,
        code: '',
        scheduleOptionKey: ScheduleDayOptions.Nothing,
        scheduleDateValue: '',
        currentUserDate: null,
        otherTime: null,
        otherTimes: [],
        planType: PlanTypeEnum.Nothing,
    };
}
