<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import Sortable from 'sortablejs';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    customClass?: string;
    options?: {
      preventOnFilter: boolean;
      onMove: any;
    };
  }>(),
  {
    disabled: false,
    customClass: '',
    options: () => {
      return {
        preventOnFilter: false,
        onMove: true,
      };
    },
  }
);

const emit = defineEmits<{
  (e: 'onChangeDrag', oldIndex: any, newIndex: any): void;
  (e: 'update:isDraging', value: boolean): void;
  (e: 'onEndSort', value: any): void;
}>();

let tictopSortAble = ref(null);
let sortableInstance;
onMounted(() => {
  sortableInstance = new Sortable(tictopSortAble.value, {
    animation: 150,
    ghostClass: 'blue-background-class',
    chosenClass: 'chosenClass-item',
    filter: '.filtered',
    disabled: props.disabled,
    onStart: function (/**Event*/) {
      emit('update:isDraging', true);
    },
    onEnd: function (/**Event*/ evt) {
      if (evt.newIndex !== evt.oldIndex) {
        emit('onChangeDrag', evt.oldIndex, evt.newIndex);
        emit('onEndSort', evt);
      }
      emit('update:isDraging', false);
    },
    onMove: function () {
      /*
      params: (evt, originalEvent)
        evt.dragged; // dragged HTMLElement
        evt.draggedRect; // DOMRect {left, top, right, bottom}
        evt.related; // HTMLElement on which have guided
        evt.relatedRect; // DOMRect
        evt.willInsertAfter; // Boolean that is true if Sortable will insert drag element after target by default
        originalEvent.clientY; // mouse position
        // return false; — for cancel
        // return -1; — insert before target
        // return 1; — insert after target
        // return true; — keep default insertion point based on the direction
        // return void; — keep default insertion point based on the direction
      */
      return props.options?.onMove;
    },
  });
});

const onUpdateDisableSortable = (value) => {
  sortableInstance.option('disabled', value);
};

watch(
  () => props.disabled,
  (newValue) => {
    onUpdateDisableSortable(newValue);
  }
);
</script>

<template>
  <div
    ref="tictopSortAble"
    :class="[disabled ? '' : 'cursor-move', customClass]"
  >
    <slot name="prefix"></slot>
    <slot name="list-item"></slot>
  </div>
</template>

<style>
.chosenClass-item:not(.todo-list-item-readonly) ::before {
  @apply h-full border-l-2 border-current-400;
}
</style>
