import userStore from '@/store/user';
import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DepartmentService from '@/application/services/DepartmentService';
import myProfileStore from '@/store/auth/my-profile';
import groupStore from '@/store/group';
import { IDepartmentDetail } from '@/application/types/department/department.types';
import { GroupMemberRole } from '@/ui/common/constants/constant';

export default defineStore({
    id: storeId.DEPARTMENT,
    state: () => ({
        allDepartments: [] as IDepartmentDetail[],
        allDepartmentByIds: {} as { [key: number]: IDepartmentDetail },
        departmentList: [],
    }),
    getters: {
        visibleForAllDepartmentIds(state): number[] {
            return Object.keys(state.allDepartmentByIds)
                ?.filter((departmentId) => {
                    return state.allDepartmentByIds[departmentId]
                        ?.departmentSetting?.visibleForAllMember;
                })
                ?.map((dId) => parseInt(dId));
        },
    },
    actions: {
        async getAllByUser() {
            const res =
                await DepartmentService.getInstance().getDepartmentByUser();
            console.log('🚀 Hyrin ~ getAll ~ res', res);
            this.allDepartmentByIds = res?.result?.reduce(
                (oldObj, currentObj) => {
                    return {
                        ...oldObj,
                        [currentObj.id]: currentObj,
                    };
                },
                this.allDepartmentByIds
            );
        },
        async getAll() {
            const res = await DepartmentService.getInstance().getAll();

            const result = res?.result?.map((department) => {
                return {
                    ...department,
                    isHasNotAnyAdmin:
                        department?.departmentUsers?.length > 0 &&
                        department?.departmentUsers?.filter(
                            (user) => user?.role == GroupMemberRole.Admin
                        )?.length == 0,
                };
            });
            this.allDepartments = result;
            this.allDepartmentByIds = result?.reduce((oldObj, currentObj) => {
                return {
                    ...oldObj,
                    [currentObj.id]: currentObj,
                };
            }, this.allDepartmentByIds);
        },

        updateAllDepartmentById(departmentId: any, key: any, value: any) {
            this.allDepartmentByIds[departmentId][key] = value;
        },

        async shortInfoDepartment() {
            const res =
                await DepartmentService.getInstance().shortInfoDepartment();
            this.departmentList = res.result.map((o) => ({
                id: o.id,
                name: o.name,
                avatar: o.avatarUrl || o.avatarThumbUrl,
            }));
        },

        updateStoreAfterEdit() {
            return Promise.all([
                this.getAll(),
                myProfileStore().fetchCurrentLoginUser(),
                userStore().getShortInfosUser(),
                groupStore().fetchMyGroups(),
            ]);
        },
        async createNewDepartment(data: {
            name: string;
            description: string;
            isPrivate: boolean;
            avatar: string;
            memberInfos: any;
        }) {
            const res = await DepartmentService.getInstance().createOrUpdate(
                data
            );
            await this.updateStoreAfterEdit();
            return res;
        },
        async changeAvatar(data: { departmentId: number; avatar: string }) {
            await DepartmentService.getInstance().changeAvatar(data);

            this.updateStoreAfterEdit();
        },
        async changeNameAndDescription(data: {
            id: number;
            name: string;
            description: string;
        }) {
            await DepartmentService.getInstance().changeNameAndDescription(
                data
            );

            this.updateStoreAfterEdit();
        },
        async addMembers(data: {
            departmentId: number;
            memberInfos: { userId: number; role: 1 | 2 }[];
        }) {
            await DepartmentService.getInstance().addMembers(data);

            await this.updateStoreAfterEdit();
            userStore().getUserShortInfosIncludeDepartment();
        },
        async updateMembers(data: {
            departmentId: number;
            memberInfos: { userId: number; role: 1 | 2 }[];
        }) {
            await DepartmentService.getInstance().updateMembers(data);

            await this.updateStoreAfterEdit();
            userStore().getUserShortInfosIncludeDepartment();
        },
        async updateRoleUsers(data: {
            departmentId: number;
            memberInfos: { userId: number; role: 1 | 2 }[];
        }) {
            await DepartmentService.getInstance().updateRoleUsers(data);

            await this.updateStoreAfterEdit();

            userStore().getUserShortInfosIncludeDepartment();
        },
        async updateUserDepartments(data: {
            userId: number;
            departments: { departmentId: number; role: 1 | 2 }[];
        }) {
            await DepartmentService.getInstance().updateUserDepartments(data);

            await this.updateStoreAfterEdit();

            userStore().getUserShortInfosIncludeDepartment();
        },
        async updateSettings(data: {
            departmentId: number;
            departmentSetting: {
                visibleForAllMember: boolean;
                visibleStatusWorking: boolean;
                visibleTimeWorking: boolean;
                visiblePersonalData: boolean;
            };
        }) {
            await DepartmentService.getInstance().updateSettings(data);

            this.updateStoreAfterEdit();
        },
        async removeMembers(data: {
            departmentId: number;
            memberIds: number[];
        }) {
            await DepartmentService.getInstance().removeMembers(data);

            this.updateStoreAfterEdit();
        },
        async removeDepartment(data: {
            departmentId: number;
            isRemoveRelatedGroup: boolean;
            deleteOption?: number;
            deleteGroupOption?: number;
        }) {
            await DepartmentService.getInstance().deleteDepartment(data);

            this.updateStoreAfterEdit();
        },
    },
});
