<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.115"
    height="18"
    viewBox="0 0 11.115 18"
  >
    <path
      id="Icon_material-navigate-before"
      data-name="Icon material-navigate-before"
      d="M23.115,11.115,21,9l-9,9,9,9,2.115-2.115L16.245,18Z"
      transform="translate(-12 -9)"
    />
  </svg>
</template>
