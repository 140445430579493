<script setup lang="ts">
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { getBgColor, formatTaskCode } from '@/ui/hooks/taskHook';
import { taskStates } from '@/ui/hooks/taskHook';
import userStore from '@/ticket/store/user-store';
import SynTd from '@/ui/common/molecules/SynTableCustom/SynTd.vue';

const _userStore = userStore();
defineProps({
  task: {
    type: Object,
    default: () => {},
  },
});

defineEmits([
  'onOpenTaskDetail',
  'finish',
  'clone',
  'setPlanned',
  'delete',
  'edit',
  'copyLink',
  'onChangePriority',
  'onChangeEstimationTime',
  'onChangeFinishedPercentage',
  'onChangeTypeTask',
  'onOpenAttachmentDetail',
  'shareToChat',
]);
const myTimezone = computed(() => myProfileStore().getTimezone);

const getProjectName = (projectId) => {
  return _userStore.getProjectById(projectId);
};
const getTicketName = (name) => {
  if (!name) return '';
  if (name.length < 50) return name;
  return `${name.substring(0, 50)} ...`;
};
</script>

<template>
  <!-- taskLife -->
  <SynTd
    class="font-semibold whitespace-wrap relative text-xs"
    :class="`${getBgColor(task)}`"
  >
    <div class="flex flex-col text-xs text-current-800 whitespace-nowrap">
      <span
        class="hover:text-current-500 cursor-pointer"
        @click="$emit('onOpenTaskDetail')"
      >
        {{ formatTaskCode(task.code) }}
      </span>

      <span v-if="task.taskSchedule?.doItToday" class="text-xs">
        {{
          $t(
            task?.taskSchedule?.finishItToday
              ? 'TASK_CREATE_FORM_FINISH_TODAY'
              : 'TASK_CREATE_FORM_DOING_TODAY'
          ) || 'Finish today'
        }}
      </span>

      <span
        v-if="task.taskLife > 0"
        :class="taskStates[task.taskLife]['color']"
        :title="$t(taskStates[task.taskLife]['nameCode'])"
        class="text-xs"
      >
        {{ $t(taskStates[task.taskLife]['nameCode']) }}
      </span>
      <!-- <div v-if="task.important" class="flex-center mt-1">
        <SynIcon name="important-task" class="fill-red-500" />
        <span class="text-xs text-red-800 ml-1">
          {{ $t('TASK_CREATE_FORM_LABEL_IMPORTANT') || 'Important' }}
        </span>
      </div> -->
    </div>
  </SynTd>

  <!-- taskName -->
  <SynTd class="max-w-md relative" style="padding: 0">
    <div class="px-4 py-1 overflow-hidden w-full h-full text-overflow-hidden">
      <div class="flex flex-wrap flex-col space-y-1 break-words w-full">
        <span
          v-encode-html="{ text: getTicketName(task.name) }"
          class="
            text-overflow-hidden text-sm
            hover:text-current-500
            cursor-pointer
          "
          @click="$emit('onOpenTaskDetail')"
        >
        </span>

        <div class="flex w-full justify-between">
          <div class="flex rounded space-x-4">
            <span
              v-if="task.attachmentCount > 0"
              class="text-xs flex items-center text-blue-500"
            >
              <SynIcon name="attach" style="fill: #3b82f6" />
              {{ task.attachmentCount }}
            </span>
            <span
              v-if="task.noteCount > 0"
              class="text-xs flex items-center text-yellow-500 space-x-1"
            >
              <SynIcon name="noter" is-active class="fill-orange-500" />
              <span>{{ task.noteCount }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </SynTd>

  <!-- taskNameProject -->
  <SynTd>
    <div class="flex flex-col text-xs">
      {{ getProjectName(task.projectId) }}
    </div>
  </SynTd>

  <!-- taskPriority -->
  <SynTd class="flex-center">
    <div v-if="task.priority === 0" class="flex items-center space-x-2 text-xs">
      <div class="h-5 w-5 bg-red-300 flex-center rounded-full" :title="'High'">
        <SynIcon class="fill-red-500" name="high" custom-class="h-3 w-3" />
      </div>
    </div>
    <div v-else class="flex items-center space-x-2 text-xs">
      <div
        class="h-5 w-5 flex-center rounded-full bg-blue-200"
        :title="'Normal'"
      ></div>
    </div>
  </SynTd>

  <!-- created date -->
  <SynTd class="text-left whitespace-nowrap">
    <div class="flex flex-col text-2xs">
      <span :title="$t('TICKET_TASK_FORM_LABEL_CREATE_DATE') || 'Created date'">
        {{
          $filters.dayjs(
            task.creationTime,
            'MMM DD, YYYY, hh:mm A',
            myTimezone || 0
          )
        }}
      </span>
      <span
        v-if="task.lastModificationTime !== task.creationTime"
        :title="$t('TICKET_TASK_FORM_LABEL_UPDATE_DATE') || 'Updated date'"
      >
        {{
          $filters.dayjs(
            task.lastModificationTime,
            'MMM DD, YYYY, hh:mm A',
            myTimezone || 0
          )
        }}
      </span>
    </div>
  </SynTd>
</template>
