<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="8.69"
        x2="55.31"
        y1="52.722"
        y2="6.102"
      >
        <stop offset="0"></stop>
        <stop offset="1"></stop>
      </linearGradient>
      <g>
        <g>
          <path
            d="m23 30.044h18c.552 0 1-.447 1-1s-.448-1-1-1h-18c-.552 0-1 .447-1 1s.448 1 1 1zm35.926-20.325c-.693-.693-1.615-1.075-2.594-1.075h-48.665c-.979 0-1.901.382-2.593 1.075-.693.691-1.074 1.612-1.074 2.593v6.012c0 .967.392 1.913 1.075 2.595.692.692 1.613 1.073 2.593 1.073h.637v28.214c0 2.841 2.311 5.151 5.152 5.151h37.088c2.84 0 5.151-2.311 5.151-5.151v-28.215h.637c.979 0 1.901-.381 2.594-1.073.682-.683 1.073-1.628 1.073-2.595v-6.012c0-.979-.381-1.9-1.074-2.592zm-5.231 40.486c0 1.737-1.414 3.151-3.151 3.151h-37.088c-1.738 0-3.152-1.414-3.152-3.151v-28.214h43.391zm4.305-31.882c0 .44-.178.87-.487 1.18-.315.315-.734.488-1.181.488h-48.665c-.446 0-.865-.173-1.18-.487-.309-.311-.487-.74-.487-1.181v-6.012c0-.445.173-.864.489-1.179.315-.315.733-.489 1.179-.489h48.665c.445 0 .865.174 1.18.489.314.315.487.734.487 1.18z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
