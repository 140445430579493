/* eslint-disable no-unused-vars */
import {
    ScheduleDayOptions,
    ScheduleOtherTimeOptions,
    PlanTypeEnum,
} from '@/domain/enums/taskEnum';

export type TaskSchedule = {
    doItToday: boolean;
    finishItToday: boolean;
    overdue: number | null;
    code: string;
    scheduleOptionKey: ScheduleDayOptions;
    scheduleDateValue: string;
    currentUserDate: Date | null;
    otherTime: number | null;
    otherTimes: [];
    planType: PlanTypeEnum;
};
export enum TaskStatus {
    Deleted = 6000,
    Unavailable = 6403,
}

export interface IUpdateDeadlineForm {
    scheduleOptionKey: ScheduleDayOptions;
    otherTimeKey: ScheduleOtherTimeOptions | null;
    scheduleTime: string;
}
