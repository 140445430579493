<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from 'vue';
import { CalendarsTypes } from '@/ui/components/calendars/calendars-ui-constant';
import { CalendarTypeValue } from '@/ui/common/constants/calendar';
import dayoffCreate from '@/store/dayoff/dayoff-create';
import calendarStore from '@/store/calendar';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import settingStore from '@/store/setting';
import permissionStore from '@/store/permission';
import myProfileStore from '@/store/auth/my-profile';

const props = defineProps({
  size: {
    type: String,
    default: 'medium',
  },
});

const emit = defineEmits(['close']);

const _dayoffCreate = dayoffCreate();
const _eventStore = calendarStore();
const _calendarsOverviewStore = calendarsOverviewStore();
const _settingStore = settingStore();

const dayoffEnabled = computed(() => _settingStore.dayoffEnabled);
const calendarEnabled = computed(() => _settingStore.calendarEnabled);

const defaultStartDate = computed(
  () => _calendarsOverviewStore.defaultStartDate
);
const imVisitor = computed(() => myProfileStore().isVisitor);

const calendarsTypeList = computed(() => {
  let result;
  if (
    dayoffEnabled.value &&
    permissionStore().organizationModel !== 'PERSONAL' &&
    !imVisitor.value
  ) {
    result = {
      ...result,
      DAYOFF: CalendarsTypes.DAYOFF,
    };
  }
  if (calendarEnabled.value) {
    result = {
      ...result,
      EVENT: CalendarsTypes.EVENT,
      REMINDER: CalendarsTypes.REMINDER,
    };
  }
  return result;
});

const sizeBox = ref('h-24 w-24');
const sizeIcon = ref('w-6 h-6');
const classBoxIcon = ref('w-12 h-12 -top-6');

watch(
  () => props.size,
  () => {
    handleSize(props.size);
  }
);

const handleSize = (size) => {
  switch (size) {
    case 'mini':
      sizeBox.value = 'h-20 w-20';
      sizeIcon.value = 'w-4 h-4';
      classBoxIcon.value = 'w-8 h-8 -top-3';
      break;
    default:
      sizeBox.value = 'h-24 w-24';
      sizeIcon.value = 'w-6 h-6';
      classBoxIcon.value = 'w-12 h-12 -top-6';
      break;
  }
};

handleSize(props.size);

onUnmounted(() => {
  _calendarsOverviewStore.defaultStartDate = new Date();
});

const onClickNew = (key) => {
  switch (key) {
    case 'DAYOFF':
      _dayoffCreate.onOpenCreateForm(defaultStartDate.value);

      break;
    case 'EVENT':
      _eventStore.onOpenAddEvent(CalendarTypeValue.Meeting);
      break;
    case 'REMINDER':
      _eventStore.onOpenAddEvent(CalendarTypeValue.Reminder);
      break;

    default:
      break;
  }

  emit('close');
};
</script>

<template>
  <div class="flex-center p-4 space-x-4">
    <div
      v-for="calType in calendarsTypeList"
      :key="calType?.key"
      class="flex-center rounded cursor-pointer shadow relative"
      :class="[
        `${sizeBox}`,
        `text-${calType.colorText}-800`,
        `hover:border hover:border-${calType.colorText}-500`,
      ]"
      @click="onClickNew(calType.key)"
    >
      <div
        class="py-2 absolute p-1 rounded-full flex-center shadow"
        :class="[`bg-${calType.colorText}-50 ${classBoxIcon}`]"
      >
        <SynIcon
          :name="calType.iconName"
          is-active
          :custom-class="`${sizeIcon}`"
          :class="`fill-${calType.colorText}-500`"
        />
      </div>
      <div class="text-center flex-1 pt-2 font-semibold text-sm">
        {{ $t(calType.textCode) || calType.textDefault }}
      </div>
    </div>
  </div>
</template>
