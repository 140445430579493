<script setup lang="ts">
import { ref, computed, onMounted, nextTick, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: number | string | boolean;
    keyField: string;
    textField: string;
    placeholder?: string;
    options?: any[];
    widthClass?: string;
    customClass?: string;
    isIconBesideLabel?: boolean;
    nameIcon?: string;
    keyItemNotShow?: string;
    valueItemNotShow?: any[];
    errorMessage?: string;
    customBackground?: string;
    isShowIcon?: boolean;
    isTranslate?: boolean;
    isDisable?: boolean;
    minWidth?: string;
    getColorFn?: Function;
    stypeClassSelect?: string;
    zIndex?: number;
    appendToBody?: boolean;
    isSelectFlags?: boolean;
    placement?: string;
  }>(),
  {
    appendToBody: false,
    zIndex: 60,
    isShowIcon: true,
    widthClass: 'w-full',
    textField: 'name',
    keyField: 'id',
    placeholder: 'Select ...',
    customClass: 'py-1.5',
    placement: 'bottom-start',
  }
);

const emit = defineEmits(['update:modelValue', 'change']);
const isShowOptions = ref(false);
const optionStype = ref('');
onMounted(() => {
  nextTick().then(() => {
    const element = document.getElementById(`syn-select${randomId}`);
    optionStype.value = `
          width: ${element?.offsetWidth}px
          `;
    indexResult.value = props.options.findIndex(
      (o) => o[props.keyField] == props.modelValue
    );
    keyActive.value = `syn-select-item-${indexResult.value}`;
  });
});

watch(
  () => props.modelValue,
  () => {
    nextTick().then(() => {
      const element = document.getElementById(`syn-select${randomId}`);
      optionStype.value = `
          width: ${element?.offsetWidth}px
          `;
      indexResult.value = props.options.findIndex(
        (o) => o[props.keyField] == props.modelValue
      );
      keyActive.value = `syn-select-item-${indexResult.value}`;
    });
  }
);
const changeSelect = (option, index) => {
  keyActive.value = `syn-select-item-${index}`;
  emit('update:modelValue', option[props.keyField]);
  emit('change', option[props.keyField]);
  isShowOptions.value = false;
};
const selectLabel = computed(() => {
  const index = props.options?.findIndex(
    (o) => o[props.keyField] == props.modelValue
  );
  return index > -1 ? props.options[index][props.textField] : props.placeholder;
});

const selectIcon = computed(() => {
  const index = props.options.findIndex(
    (o) => o[props.keyField] == props.modelValue
  );
  return index > -1 ? props.options[index][props.nameIcon] : '';
});

const randomId = Math.random();
const indexResult = ref(-1);
const keyActive = ref('syn-select-item');
const onKeyDownSearch = () => {
  indexResult.value++;
  if (indexResult.value == props.options.length) {
    indexResult.value = 0;
  }
  keyActive.value = `syn-select-item-${indexResult.value}`;
  let element = document.getElementById(`syn-select-item-${indexResult.value}`);
  element.scrollIntoView({ block: 'center' });
};
const onKeyUpSearch = () => {
  indexResult.value--;
  keyActive.value = `syn-select-item-${indexResult.value}`;
  if (indexResult.value < 0) {
    indexResult.value = props.options.length - 1;
  }
  keyActive.value = `syn-select-item-${indexResult.value}`;
  let element = document.getElementById(`syn-select-item-${indexResult.value}`);
  element.scrollIntoView({ block: 'center' });
};

const onChooseResult = () => {
  let optionChoose = props.options[indexResult.value];
  isShowOptions.value = false;
  emit('update:modelValue', optionChoose[props.keyField]);
  emit('change', optionChoose[props.keyField]);
};

const onKeyTab = () => {
  isShowOptions.value = false;
};
</script>

<template>
  <vig-dropdown
    :placement="placement"
    :arrow="false"
    :z-index="zIndex"
    :append-to-body="appendToBody"
    @on-dropdown-open="isShowOptions = true"
    @on-dropdown-close="isShowOptions = false"
  >
    <template #dropdown-toggle>
      <div
        :id="`syn-select${randomId}`"
        class="rounded hover-to-show__parent relative"
        :class="widthClass"
        :title="isTranslate ? $t(selectLabel) : selectLabel"
        :style="`min-width: ${minWidth}`"
      >
        <SynIcon
          v-if="isIconBesideLabel"
          :name="selectIcon"
          class="absolute top-1/2 left-2.5 -mt-2 z-10"
          :class="getColorFn && getColorFn(modelValue)"
          custom-class="w-4 h-4 fill-gray-500"
        />

        <input
          :value="isTranslate ? $t(selectLabel) : selectLabel"
          readonly
          class="
            vig-input
            border
            px-4
            w-full
            rounded
            cursor-pointer
            truncate
            text-overflow-hidden-line
          "
          :class="[
            customClass,
            errorMessage
              ? 'border-red-500 w-full'
              : isIconBesideLabel
              ? 'px-10'
              : isDisable
              ? 'bg-gray-100 text-gray-500 hover:bg-gray-100 cursor-text'
              : isShowOptions
              ? 'bg-opacity-30 border-current ring ring-current ring-opacity-30'
              : widthClass,
            customBackground ? customBackground : 'bg-white',
            isSelectFlags ? 'pl-8' : '',
          ]"
          :style="
            isShowIcon
              ? 'justify-content: space-between;'
              : 'justify-content: space-around;'
          "
          @keyup.down="onKeyDownSearch"
          @keyup.up="onKeyUpSearch"
          @keyup.enter="onChooseResult"
          @keyup.tab="onKeyTab"
        />
        <div
          class="absolute top-1/2 right-2.5 -mt-1 flex items-center space-x-2"
          :class="customBackground ? customBackground : 'bg-white'"
        >
          <slot name="extentsions"></slot>
          <SynIcon
            v-if="isShowIcon && !isDisable"
            custom-class="w-1.5 h-1.5 flex-center fill-gray-500"
            :name="isShowOptions ? 'arrow-up' : 'arrow-down'"
          />
        </div>
      </div>
      <div
        v-if="errorMessage"
        class="text-red-400 text-left left-0 min-w-max text-xs"
      >
        {{ errorMessage }}
      </div>
    </template>
    <template v-if="isShowOptions" #dropdown-menu>
      <div
        v-if="!isDisable"
        v-perfect-scrollbar
        class="
          list-ul
          rounded-md
          w-full
          overflow-x-hidden overflow-auto
          small-scrollbar
        "
        style="max-height: 20rem"
        :style="optionStype"
        :class="widthClass"
      >
        <div
          v-for="(option, index) in options"
          :id="`syn-select-item-${index}`"
          :key="option[keyField]"
          class="dropdown-item"
          :class="keyActive == `syn-select-item-${index}` ? 'bg-gray-100' : ''"
          @click="changeSelect(option, index)"
        >
          <div
            v-if="!valueItemNotShow?.find((el) => el == option[keyItemNotShow])"
            :title="isTranslate ? $t(option[textField]) : option[textField]"
            class="
              list-li
              px-2
              py-2
              text-black
              hover:bg-gray-100 hover:text-current-700
              cursor-pointer
              break-words
              truncate
              dropdown-item
            "
          >
            <SynIcon
              v-if="isIconBesideLabel"
              custom-class="h-4 w-4"
              class="mr-2"
              :name="option[nameIcon]"
              :class="getColorFn && getColorFn(option[keyField])"
            />
            <span class="truncate dropdown-item">
              {{ isTranslate ? $t(option[textField]) : option[textField] }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </vig-dropdown>
</template>
