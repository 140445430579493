<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 128 128"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M39.76 16a2 2 0 0 0-3.47 0L14.1 55a2 2 0 0 0 1.74 3h44.37A2 2 0 0 0 62 55zM19.27 54 38 21l18.77 33zM80 58h24a8 8 0 0 0 8-8V26a8 8 0 0 0-8-8H80a8 8 0 0 0-8 8v24a8 8 0 0 0 8 8zm-4-32a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H80a4 4 0 0 1-4-4zM113.41 84.59l-16-16a7.65 7.65 0 0 0-10.82 0l-16 16a7.67 7.67 0 0 0 0 10.82l16 16a7.65 7.65 0 0 0 10.82 0l16-16a7.68 7.68 0 0 0 0-10.82zm-2.83 8-16 16a3.66 3.66 0 0 1-5.16 0l-16-16a3.64 3.64 0 0 1 0-5.16l16-16a3.72 3.72 0 0 1 5.16 0l16 16a3.65 3.65 0 0 1 0 5.16zM38 68a22 22 0 1 0 22 22 22 22 0 0 0-22-22zm0 40a18 18 0 1 1 18-18 18 18 0 0 1-18 18z"
      ></path>
    </g>
  </svg>
</template>
