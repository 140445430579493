<script setup lang="ts">
import { computed, ref } from 'vue';
import PlanManage from '@/ui/modules/settings/plans-billing/PlanManage.vue';
// import PaymentMethods from '@/ui/pages/settings/plans-billing/PaymentMethods.vue';
// import BillingInformation from '@/ui/pages/settings/plans-billing/BillingInformation.vue';
import BillingHistory from '@/ui/modules/settings/plans-billing/BillingHistory.vue';
import { tabPlanBilling } from '@/ui/common/constants/plans';
import EnterLicenseModal from '@/ui/modules/settings/payments/license/EnterLicenseModal.vue';
import plansStore from '@/store/plans';
import { ELicenseType, IOrgCurrentPlan } from '@/application/types/plan.types';

const _planStore = plansStore();
const currentOrgPlan = computed<IOrgCurrentPlan>(
  () => _planStore.currentOrgPlan
);
const isHiddenAddLicenseButton = computed(
  () =>
    currentOrgPlan?.value?.license == ELicenseType.LIFETIME ||
    currentOrgPlan?.value?.license == ELicenseType.EVALUATION
);
const plansSettingTabs = ref({
  MANAGE_PLAN: {
    key: tabPlanBilling.MANAGE_PLAN,
    label: 'SETTING_PLAN_MANAGE_PLAN',
    isActive: true,
  },
  // PAYMENT_METHOD: {
  //   key: tabPlanBilling.PAYMENT_METHOD,
  //   label: 'SETTING_PLAN_PAYMENT_METHOD',
  //   isActive: false,
  // },
  // BILLING_INFO: {
  //   key: tabPlanBilling.BILLING_INFO,
  //   label: 'SETTING_PLAN_BILLING_INFORMATION',
  //   isActive: false,
  // },
  BILLING_HISTORY: {
    key: tabPlanBilling.BILLING_HISTORY,
    label: 'SETTING_PLAN_BILLING_HISTORY',
    isActive: false,
  },
});

const changeTab = (key) => {
  for (let k in plansSettingTabs.value) {
    plansSettingTabs.value[k].isActive = k == key;
  }
};

const isOpenEnterLicense = ref<boolean>(false);
</script>

<template>
  <SynTabs
    :option-status="plansSettingTabs"
    class="overflow-hidden p-4 bg-white"
    @change-tab="changeTab"
  >
    <template #tabBody>
      <!--  MANAGE_PLAN    -->
      <div class="overflow-y-auto h-full w-full small-scrollbar">
        <template v-if="plansSettingTabs[tabPlanBilling.MANAGE_PLAN].isActive">
          <PlanManage />
        </template>

        <!--      &lt;!&ndash;  PAYMENT_METHOD    &ndash;&gt;-->
        <!--      <template v-if="plansSettingTabs[tabPlanBilling.PAYMENT_METHOD].isActive">-->
        <!--        <PaymentMethods/>-->
        <!--      </template>-->

        <!--      &lt;!&ndash;  BILLING_INFO    &ndash;&gt;-->
        <!--      <template v-if="plansSettingTabs[tabPlanBilling.BILLING_INFO].isActive">-->
        <!--        <BillingInformation/>-->
        <!--      </template>-->

        <!--  BILLING_HISTORY    -->
        <template
          v-if="plansSettingTabs[tabPlanBilling.BILLING_HISTORY].isActive"
        >
          <BillingHistory />
        </template>
      </div>
    </template>
    <template #prefix>
      <div v-if="!isHiddenAddLicenseButton" class="flex-center pr-3">
        <SynButton
          type-outline
          name-icon="license"
          :label="$t('COMMON_LABEL_ENTER_LICENSE_CODE')"
          @click="isOpenEnterLicense = true"
        />
      </div>
    </template>
  </SynTabs>

  <EnterLicenseModal
    v-if="isOpenEnterLicense"
    @on-cancel="isOpenEnterLicense = false"
  />
</template>
