<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { encryptGroupId, encryptUserId } from '@/ui/plugins/crypto/crypto';
import { taskListRouteByType } from '@/ui/hooks/commonHook';
import {
  getUserPathFirebase,
  updateDataFirebase,
} from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import PersonalSidebarItem from './PersonalSidebarItem.vue';
import { AdminType, FavoriteType } from '@/ui/common/constants/constant';
import favoriteStore from '@/store/favorite/favoriteStore';
import FavoriteButton from '@/ui/components/user/FavoriteButton.vue';
import ModalEditFavorite from '@/ui/modules/favotites/ModalEditFavorite.vue';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import NotificationButton from '@/ui/components/group/notification-button.vue';
import settingStore from '@/store/setting';
import appStore from '@/store/app';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';

const activeClass =
  'text-current-400 bg-current-50 bg-opacity-50 rounded-lg font-semibold';
const inactiveClass = 'transform hover:translate-x-1';

defineProps<{
  currentActive: string;
  type: string;
  onAction: Function;
}>();
defineEmits(['onCollapse']);

const _favoriteStore = favoriteStore();
const _settingStore = settingStore();

const router = useRouter();
const _appStore = appStore();

const chatEnabled = computed(() => _settingStore.chatEnabled);
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
const isCollapse = ref(false);

const myFavorites = computed(() => _favoriteStore.getMyFavorites);
const isActiveFavorite = ref(false);

const { goToChatFromContact } = useChatCommon();

const getMenuLinkUrl = (favorite) => {
  isActiveFavorite.value = true;
  if (favorite?.type === FavoriteType.Group) {
    return `/tasks/groups/${encryptGroupId(favorite?.refId)}`;
  }

  if (favorite?.type === FavoriteType.Member) {
    return `/tasks/users/${encryptUserId(favorite?.refId)}`;
  }

  if (favorite?.type === FavoriteType.Domain) {
    return `/tasks/domains/${encryptUserId(favorite?.refId)}`;
  }
  return `/`;
};

const chooseFavorite = async (favorite) => {
  let routeType: 'USER' | 'GROUP' | 'DOMAIN' = 'USER';

  switch (favorite?.type) {
    case FavoriteType.Member:
      routeType = 'USER';
      break;
    case FavoriteType.Group:
      routeType = 'GROUP';
      break;
    case FavoriteType.Domain:
      routeType = 'DOMAIN';
      break;
    default:
      routeType = 'USER';
      break;
  }

  console.log('favorite?.refId', favorite?.refId);
  console.log('favorite?.refId', favorite?.type);

  router.replace(taskListRouteByType(routeType, favorite?.refId));
};

// show setting group
const onMessageTo = (favorite) => {
  if (!favorite?.refId) return;

  const contact = {
    ...favorite,
    id: favorite.refId,
  };

  goToChatFromContact(contact, favorite?.type === FavoriteType.Group);
};
const onMouseLeave = (event) => {
  if (
    event.relatedTarget == favoriteItemRef.value ||
    favoriteListRef.value?.contains(event.relatedTarget)
  ) {
    return;
  }
  clearTimeout(timerCollapse);
  timerCollapse = setTimeout(() => {
    isCollapse.value = false;
  }, 500);
  // isCollapse.value = false;
};

let timerCollapse = null as any;

const onMouseOver = (event) => {
  if (
    event.relatedTarget == favoriteListRef.value ||
    event.relatedTarget == favoriteItemRef.value ||
    favoriteListRef.value?.contains(event.relatedTarget)
  ) {
    return;
  }
  clearTimeout(timerCollapse);
  timerCollapse = setTimeout(() => {
    isCollapse.value = true;
  }, 500);
};

const favoriteListRef = ref(null) as any;
const favoriteItemRef = ref(null);
const isEditFavorite = ref(false);

let timer = null as any;
const userId = ref();
userId.value = getCurrentUserId();
const onChangeDrag = (oldIndex, newIndex) => {
  const path = getUserPathFirebase(userId.value, 'favorites');
  let itemInsert = myFavorites.value[oldIndex];

  myFavorites.value.splice(oldIndex, 1);
  myFavorites.value.splice(newIndex, 0, itemInsert);
  myFavorites.value.map((el, index) => (el['index'] = index));

  clearTimeout(timer);
  timer = setTimeout(async () => {
    let objectAdd = {};
    myFavorites.value.forEach((el, index) => {
      objectAdd[`${el.type}_${el.refId}`] = {
        refId: el.refId,
        type: el.type,
        index: index,
      };
    });
    await updateDataFirebase(path, objectAdd);
  }, 5000);
};
</script>

<template>
  <div
    ref="favoriteItemRef"
    v-permission-model
    class="w-full"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
    @blur="onMouseLeave"
    @click="isCollapse = true"
  >
    <PersonalSidebarItem
      :is-active="currentActive == 'favorites'"
      :sub-length="myFavorites?.length"
      :is-collapse="isCollapse"
      :item-name="$t('SIDEBAR_LABEL_MY_FAVORITES') || 'Favorite'"
      :is-hidden-text="!isOpenMenuBar"
      is-disabled-collapse-icon
      icon-name="heart"
    >
      <template #suffix>
        <div
          v-permission="'FunctionGroupAdd'"
          class="
            w-6
            h-6
            flex-center
            rounded
            cursor-pointer
            hover:bg-current-100 hover:bg-opacity-50
          "
          @click.stop.prevent="isEditFavorite = true"
        >
          <SynIcon
            v-permission="'FunctionGroupAdd'"
            class="fill-current"
            custom-class="h-3 w-3"
            name="edit"
            is-active
          />
        </div>
      </template>
    </PersonalSidebarItem>
  </div>
  <div
    ref="favoriteListRef"
    class="
      flex-1
      w-60
      bg-white
      rounded
      small-scrollbar
      overflow-y-auto
      space-y-1
      min-h-0
      px-1
      cursor-pointer
      transition
    "
    :class="isCollapse ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
    @mouseleave="onMouseLeave"
    @mouseover="onMouseOver"
    @blur="onMouseLeave"
  >
    <template v-if="myFavorites?.length">
      <SortAble @on-change-drag="onChangeDrag">
        <template #list-item>
          <div
            v-for="(item, index) in myFavorites.filter((o) => o.id)"
            :key="item"
            class="
              list-li
              relative
              hover-to-show__parent
              flex
              items-center
              rounded-lg
              cursor-move
              h-12
              hover:text-current-400
              transition
              duration-300
            "
            :class="[
              currentActive ===
              (item?.type === 'group'
                ? `group_${item?.id}`
                : `user_${item?.id}`)
                ? activeClass
                : inactiveClass,
              !isOpenMenuBar ? 'px-0' : '',
              isCollapse
                ? 'translate-x-0 ease-out'
                : '-translate-x-full ease-in',
            ]"
            :style="{
              transitionDelay: `${100 + 30 * index}ms`,
            }"
            :href="getMenuLinkUrl(item)"
            @click.prevent="chooseFavorite(item)"
          >
            <div
              class="
                flex-center
                z-10
                rounded-full
                absolute
                left-2
                top-2
                w-4
                h-4
                hover-to-show__children
              "
            >
              <FavoriteButton
                :ref-id="item?.refId"
                custom-class="w-4 h-4"
                :type="item?.type"
              />
            </div>
            <a class="flex items-center space-x-2" :href="getMenuLinkUrl(item)">
              <div class="overflow-hidden md:flex hidden">
                <div class="relative">
                  <SynAvatarStatusActive
                    :id="item?.id"
                    :avatar="item?.avatar?.replace('original', 'mini')"
                    :is-hidden-name="!isOpenMenuBar"
                    :name="item?.name"
                    :is-user="item?.type === 'user'"
                    :type="
                      item?.type === FavoriteType.Group
                        ? 'group'
                        : item?.type === FavoriteType.Domain
                        ? 'domain'
                        : 'user'
                    "
                    custom-class="w-8 h-8"
                    :is-visitor="item?.adminType == AdminType.Visitor"
                  >
                    <template #avatar-suffix>
                      <div
                        v-if="item.isPrivate"
                        class="
                          absolute
                          bottom-0
                          bg-white
                          rounded-full
                          flex-center
                          w-4
                          h-4
                          right-0
                        "
                      >
                        <SynIcon
                          class="fill-current"
                          custom-class="w-2.5 h-2.5"
                          :name="
                            item.code == 'DELETED_GROUP'
                              ? 'cancel'
                              : item.isPrivate
                              ? 'private'
                              : ''
                          "
                        />
                      </div>
                    </template>
                  </SynAvatarStatusActive>
                </div>
                <NotificationButton
                  v-if="item?.type === 'group' && isOpenMenuBar"
                  :ref-id="item?.id"
                  :name="item?.name"
                  class-notification="w-3 h-3 fill-gray-500"
                />
              </div>
            </a>
            <div
              v-if="
                chatEnabled &&
                isOpenMenuBar &&
                ((item?.type === 'group' && item?.isMember) ||
                  item?.type !== 'group') &&
                item?.type !== 'domain'
              "
              v-permission="'Messages'"
              class="absolute hover-to-show__children flex-center right-1 w-max"
            >
              <SynIcon
                class="fill-current"
                has-action
                name="message"
                @click.stop="onMessageTo(item)"
              />
            </div>
          </div>
        </template>
      </SortAble>
    </template>
    <template v-else>
      <div class="bg-gray-50 py-4 rounded-lg m-2">
        <div class="text-center w-full text-sm px-1 text-gray-500">
          {{ $t('SIDEBAR_LABEL_FAVORITE_MESSAGE') }}
        </div>
      </div>
    </template>
  </div>

  <ModalEditFavorite
    v-if="isEditFavorite"
    @cancel="(value) => (isEditFavorite = value)"
  />
</template>
