<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M400.268 175.599a8.53 8.53 0 0 0-7.731-4.932h-101.12l99.797-157.568a8.529 8.529 0 0 0 .265-8.678A8.533 8.533 0 0 0 384.003 0H247.47a8.541 8.541 0 0 0-7.637 4.719l-128 256a8.522 8.522 0 0 0 .375 8.294 8.546 8.546 0 0 0 7.262 4.053h87.748l-95.616 227.089a8.55 8.55 0 0 0 3.413 10.59 8.55 8.55 0 0 0 10.983-1.775l273.067-324.267a8.541 8.541 0 0 0 1.203-9.104z"
        opacity="1"
        class=""
      ></path>
    </g>
  </svg>
</template>
