<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
// import getUserInfo from '@/ui/helpers/user-helper';
// import WorkflowSteps from '@/ui/modules/workflow/WorkflowSteps.vue';
import TaskWorkflowEntity, {
  ITaskWorkflowEntity,
} from '@/domain/entities/task/TaskWorkflowEntity';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
// import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import UpdateWorkflowSteps from '@/ui/modules/workflow/workflow-detail/UpdateWorkflowSteps.vue';

const props = withDefaults(
  defineProps<{
    taskWorkflow: ITaskWorkflowEntity;
    taskDetail: ITaskDetailClass;
    isLoading?: boolean;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'clickAddStep'): void;
  (e: 'onSaveStep', any): void;
  (e: 'update:currentStepId', id: string): void;
  (e: 'update:taskWorkflow', data: any): void;
  (e: 'update:workflowSteps', data: any): void;
}>();

const attachedWorkflowInfo = ref<TaskWorkflowEntity>(
  new TaskWorkflowEntity(props.taskWorkflow || {})
);

const isLoadingRelatedTask = ref<boolean>(false);
const initComponent = async () => {
  attachedWorkflowInfo.value = new TaskWorkflowEntity(props.taskWorkflow || {});

  // isLoadingRelatedTask.value = true;
  // const res = await WorkflowRepository.getInstance().getTaskWorkflowStepTasks(
  //   props.taskWorkflow?.id
  // );
  // attachedWorkflowInfo.value.steps.forEach((step) => {
  //   step.relatedTask = res?.result?.find((task) => task?.id == step?.taskId);
  // });
  // isLoadingRelatedTask.value = false;
};
watch(() => props.taskWorkflow, initComponent, { deep: true });
const onScrollToCurrentStep = () => {
  const element = document.getElementById(
    `step-id-${props.taskWorkflow?.currentStepId}`
  ) as HTMLDivElement;
  element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
};

// const updateTaskFlow = (newData) => {
//   WorkflowRepository.getInstance().updateTaskStep(newData);
// };

onMounted(async () => {
  setTimeout(() => {
    onScrollToCurrentStep();
  }, 50);
});

const onSaveAllSteps = (updatedWorkflowSteps) => {
  if (!attachedWorkflowInfo.value?.steps) return;

  attachedWorkflowInfo.value.steps = updatedWorkflowSteps;

  if (props.taskDetail?.id) {
    attachedWorkflowInfo.value.updateSteps();
  }

  emit('update:taskWorkflow', attachedWorkflowInfo.value);
};

const updateWorkflow = () => {
  emit('update:taskWorkflow', attachedWorkflowInfo.value);
};
const updateTaskStep = (taskStep) => {
  console.log('🚀 Tictop ~ taskStep:', taskStep);
};

initComponent();
</script>
<template>
  <div class="w-full flex px-2 pt-4 sticky top-0 z-10 bg-white">
    <div class="w-full flex items-center justify-between">
      <div class="truncate text-xl px-2" :title="taskWorkflow?.name">
        {{ taskWorkflow?.name }}
      </div>
    </div>
  </div>
  <div class="flex-1 min-h-0 w-full px-4 text-xs overflow-auto small-scrollbar">
    <SynLoading v-if="isLoading || isLoadingRelatedTask"></SynLoading>

    <UpdateWorkflowSteps
      v-else
      :key="attachedWorkflowInfo.lastModificationTime"
      ref="updateWorkflowStepsRef"
      v-model:workflow-steps="attachedWorkflowInfo.steps"
      v-model:draw-data="attachedWorkflowInfo.drawData"
      :workflow-info="attachedWorkflowInfo"
      view-mode="TASK_VIEW"
      @update:step="updateTaskStep"
      @update:workflow-steps="updateWorkflow"
      @update:draw-data="updateWorkflow"
      @update-all-step="onSaveAllSteps"
    />
  </div>
  <div class="p-4 px-8 flex justify-between items-center space-x-2">
    <div class="flex-1 flex-center text-base">
      <div
        v-html="
          $t('TASK_WORKFLOW_ATT_ACH_A_WORKFLOW_A_FOR_TASK_B', {
            workflowName: attachedWorkflowInfo?.name || '',
            taskCode:
              formatTaskCode(taskDetail?.code) ||
              $t('TASK_LABEL_YOU_ARE_CREATING') ||
              '',
          }) || 'Task workflow'
        "
      ></div>
    </div>
    <div class="flex-center gap-2">
      <AtomButton
        label="COMMON_LABEL_DONE2"
        size="lg"
        @click="$emit('cancel')"
      />
    </div>
  </div>
</template>
