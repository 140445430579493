<script setup lang="ts">
import { ref, computed } from 'vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import DayoffStatusAtom from '@/ui/components/dayoff/atoms/DayoffStatusAtom.vue';
import getUserInfo from '@/ui/helpers/user-helper';
import DayoffTypeAtom from '@/ui/components/dayoff/atoms/DayoffTypeAtom.vue';
import DayoffDetailModal from '@/ui/components/dayoff/DayoffDetailModal.vue';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import DayoffDetailClass from '@/domain/entities/dayoff/DayoffDetailClass';
import DayoffDateSessionAtom from '@/ui/components/dayoff/atoms/DayoffDateSessionAtom.vue';
import { DayoffType, DayoffExceptionType } from '@/domain/enums/DayoffEnums';
import DayoffExceptionTypeAtom from '@/ui/components/dayoff/atoms/DayoffExceptionTypeAtom.vue';

const _calendarsOverviewStore = calendarsOverviewStore();

// const initData = () => {
//   _calendarsOverviewStore.getMyActiveRequest();
// };

const isOpenVigDrawer = ref(true);

const currentDayoffDetailId = ref('');
const onOpenDetail = (dayoff: DayoffDetailClass) => {
  currentDayoffDetailId.value = dayoff.id;
};
const onCloseDetail = () => {
  currentDayoffDetailId.value = '';
};

const myActiveList = computed<DayoffDetailClass[]>(
  () => _calendarsOverviewStore.myActiveList
);

// initData();
</script>
<template>
  <div
    v-perfect-scrollbar
    class="
      flex
      h-full
      transition
      transform
      flex-1
      min-h-0
      overflow-y-auto
      small-scrollbar
      bg-white
      p-4
      flex-col
      space-y-4
    "
    :class="[
      isOpenVigDrawer ? 'translate-x-0 ease-out' : 'translate-x-full ease-in',
    ]"
    style="width: 100%; max-width: 100%"
  >
    <!-- <div class="flex-col">
        <div class="uppercase">pending requests</div>
        <div class="text-gray-600 text-sm">All employees pending requests</div>
      </div> -->
    <div v-if="myActiveList?.length > 0" class="flex flex-col divide-y">
      <div
        v-for="(dayoff, index) in myActiveList"
        :key="index"
        class="flex items-center justify-between py-2 cursor-pointer"
        @click="onOpenDetail(dayoff)"
      >
        <div class="flex-1 flex items-start space-x-2">
          <div class="pt-1">
            <UserByAction
              :user-id="dayoff.userId"
              is-hidden-name
              is-show-action-user
              avatar-class="w-8 h-8"
            />
          </div>
          <div class="flex-1 flex flex-col items-start">
            <div class="w-full flex items-center justify-between space-x-2">
              <span class="text-sm text-overflow-hidden-line font-medium">{{
                getUserInfo(dayoff.userId)?.name
              }}</span>
              <div class="flex-center space-x-1">
                <DayoffTypeAtom :type="dayoff.type" size="small" />
                <DayoffStatusAtom :status="dayoff.status" size="small" />
              </div>
            </div>
            <template
              v-if="
                dayoff.type === DayoffType.SCHEDULE ||
                dayoff.type === DayoffType.WFH
              "
            >
              <div class="flex-center space-x-2 text-xs text-gray-800">
                <span class="">
                  <SynLabelDateTime
                    format="date"
                    ignore-timezone
                    :datetime="dayoff.startDate"
                  />
                  <!-- {{ $filters.dayjs(dayoff.startDate, 'MMM DD, YYYY') }} -->
                </span>
                <template v-if="dayoff.startDate !== dayoff.endDate">
                  -
                  <SynLabelDateTime
                    format="date"
                    ignore-timezone
                    :datetime="dayoff.endDate"
                  />

                  <!-- - {{ $filters.dayjs(dayoff.endDate, 'MMM DD, YYYY') }} -->
                </template>
                <span
                  v-if="dayoff?.dateOffs?.length > 1"
                  class="text-current text-xs"
                  is-text-only
                  :date-session="dayoff?.dateOffs[0]?.daySession"
                >
                  (<DayoffDateSessionAtom
                    class="text-current text-xs"
                    is-text-only
                    :date-session="dayoff?.dateOffs[0]?.daySession"
                  />)
                </span>
              </div>
            </template>
            <template v-else>
              <div class="flex flex-col text-xs text-gray-800">
                <span class="w-32">
                  <DayoffExceptionTypeAtom
                    size="small"
                    is-active
                    :exception-type="dayoff.exception?.type"
                  />
                </span>
                <span class="flex items-center space-x-2 text-gray-500">
                  <span class="text-xs">
                    {{ $filters.dayjs(dayoff.startDate, 'dddd MMM DD, YYYY') }}
                  </span>
                  ,
                  <span class="text-xs">
                    {{
                      $filters.dayjs(dayoff.exception?.startTime, ' hh:mm A')
                    }}
                  </span>
                  <span
                    v-if="
                      dayoff.exception.type === DayoffExceptionType.BREAK_TIME
                    "
                    class="text-xs"
                  >
                    -
                    {{ $filters.dayjs(dayoff.exception.endTime, 'hh:mm A') }}
                  </span>
                </span>
              </div>
            </template>
            <div>
              <span
                v-if="dayoff?.requestNote"
                class="text-xs text-gray-700 italic"
              >
                {{ dayoff?.requestNote }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h-full flex flex-col space-y-2">
      <SynAnimation :name="'no-ticket-data'" :stype="'width: 200px;'" />
    </div>
  </div>

  <DayoffDetailModal
    v-if="currentDayoffDetailId"
    :id="currentDayoffDetailId"
    @close="onCloseDetail"
  />
</template>

<style lang="scss" scoped></style>
