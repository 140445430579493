import { computed } from 'vue';
import $constants from '@/ui/plugins/constants';
import { translate } from '@/ui/plugins/i18n/myi18n';
import myProfileStore from '@/store/auth/my-profile';
import groupStore from '@/store/group';
import userStore from '@/store/user';

export default function useResourcePrevent(fileFilters) {
    const myProfile = computed(() => myProfileStore().myProfile);
    const allUsers = computed(() => userStore().listShortInfosUser);
    const allMyGroups = computed(() =>
        groupStore().allMyGroups?.filter((group) => group?.isMember)
    );

    const preventAddResourcesMsg = computed(() => {
        if (fileFilters.value?.category === $constants.FILE.CATEGORY.TASK) {
            return translate('GED_RESOURCE_ADD_PREVENT_CATEGORY_MSG', {
                category: translate('GED_FILTER_TASK'),
            });
        }

        if (fileFilters.value?.category === $constants.FILE.CATEGORY.CHAT) {
            return translate('GED_RESOURCE_ADD_PREVENT_CATEGORY_MSG', {
                category: translate('GED_FILTER_CHAT'),
            });
        }

        if (
            fileFilters.value?.userId &&
            fileFilters.value?.userId !== myProfile.value?.id
        ) {
            const userInfo = allUsers.value?.find(
                (user) => user?.id === fileFilters.value?.userId
            );

            return translate('GED_RESOURCE_ADD_PREVENT_USER_MSG', {
                user: userInfo?.name,
            });
        }

        if (fileFilters.value?.groupId) {
            const groupInfo = allMyGroups.value?.find(
                (group) => group?.id === fileFilters.value?.groupId
            );

            if (!groupInfo?.isMember) {
                return translate('GED_RESOURCE_ADD_PREVENT_GROUP_MSG', {
                    group: groupInfo?.name,
                });
            }
        }

        return null;
    });

    return {
        preventAddResourcesMsg,
    };
}
