<template>
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g id="_21._Plane">
        <circle
          class="st0"
          style="opacity: 0.32; fill: #ffffff"
          cx="237.4"
          cy="256"
          r="232.7"
        />
        <path
          class="st1"
          style="fill: #ffffff"
          d="M130.3,409.6c0,15.4-12.5,27.9-27.9,27.9H91.8c-19.5-15.6-36.4-34.4-49.9-55.4c1-0.2,2.1-0.4,3.1-0.5    c7.7-16.3,27.1-23.2,43.4-15.6c6.8,3.2,12.3,8.7,15.6,15.6C118.8,382.5,130.3,394.8,130.3,409.6z"
        />
        <circle class="st2" style="fill: #dceef2" cx="27.9" cy="18.6" r="14" />
        <circle class="st3" style="fill: #ffa100" cx="493.4" cy="83.8" r="14" />
        <circle
          class="st2"
          style="fill: #dceef2"
          cx="470.1"
          cy="493.4"
          r="14"
        />
        <circle class="st4" style="fill: #ff4d69" cx="18.6" cy="465.5" r="14" />
        <path
          class="st5"
          style="fill: #027367"
          d="M237.4,204.8v4.7l-83.8-7.6c-11.2-1.1-19.4-11-18.3-22.2c0.9-9.7,8.6-17.4,18.3-18.3l37-3.4L237.4,204.8z"
        />
        <path
          class="st6"
          style="fill: #f29c6b"
          d="M358.4,233.8c-0.5,2.1-2.4,3.6-4.7,3.6H223.4c-1.1,0-2.1-0.4-2.9-1l-122.7-106h60.5c0.7,0,1.4,0.2,2.1,0.5    l195.5,97.7C357.8,229.5,358.9,231.7,358.4,233.8z"
        />
        <path
          class="st7"
          style="fill: #f26a1b"
          d="M158.3,130.3H97.7V93.1H121l37.2,32.6V130.3z"
        />
        <path
          class="st5"
          style="fill: #027367"
          d="M167.6,352.1l83.8-7.6v55.9l-83.8-7.6c-10.5-0.9-18.6-9.7-18.6-20.3C148.9,361.8,157,353,167.6,352.1z"
        />
        <circle
          class="st8"
          style="fill: #8fd9b6"
          cx="251.3"
          cy="372.4"
          r="27.9"
        />
        <path
          class="st9"
          style="fill: #f0b34b"
          d="M498,294.6v0.6c0,14.3-11.6,25.9-25.9,26H33c-7.1,0-14-1.6-20.4-4.7c-14.6-54.3-9-112,15.5-162.5l37,55.5    h340.9c11.1,0,21.5,4.9,28.6,13.4l4.3,5.2l31,37l14,7.1C492.7,276.3,498.1,285.1,498,294.6z"
        />
        <path
          class="st10"
          style="fill: #f25e3d"
          d="M158.3,279.3v41.9H33c-7.1,0-14-1.6-20.4-4.7c-14.6-54.3-9-112,15.5-162.5l37,55.5h27.9v4.7    c0,18,14.6,32.6,32.6,32.6S158.3,261.3,158.3,279.3z"
        />
        <g>
          <path
            class="st2"
            style="fill: #dceef2"
            d="M470.1,265.3h-65.2c-5.1,0-9.3-4.2-9.3-9.3v-18.6c0-5.1,4.2-9.3,9.3-9.3h34.1L470.1,265.3z"
          />
          <path
            class="st2"
            style="fill: #dceef2"
            d="M349.1,228.1h18.6c5.1,0,9.3,4.2,9.3,9.3V256c0,5.1-4.2,9.3-9.3,9.3h-18.6c-5.1,0-9.3-4.2-9.3-9.3v-18.6     C339.8,232.2,343.9,228.1,349.1,228.1z"
          />
          <path
            class="st2"
            style="fill: #dceef2"
            d="M293.2,228.1h18.6c5.1,0,9.3,4.2,9.3,9.3V256c0,5.1-4.2,9.3-9.3,9.3h-18.6c-5.1,0-9.3-4.2-9.3-9.3v-18.6     C283.9,232.2,288.1,228.1,293.2,228.1z"
          />
          <path
            class="st2"
            style="fill: #dceef2"
            d="M237.4,228.1H256c5.1,0,9.3,4.2,9.3,9.3V256c0,5.1-4.2,9.3-9.3,9.3h-18.6c-5.1,0-9.3-4.2-9.3-9.3v-18.6     C228.1,232.2,232.2,228.1,237.4,228.1z"
          />
          <path
            class="st2"
            style="fill: #dceef2"
            d="M181.5,228.1h18.6c5.1,0,9.3,4.2,9.3,9.3V256c0,5.1-4.2,9.3-9.3,9.3h-18.6c-5.1,0-9.3-4.2-9.3-9.3v-18.6     C172.2,232.2,176.4,228.1,181.5,228.1z"
          />
          <path
            class="st2"
            style="fill: #dceef2"
            d="M125.7,228.1h18.6c5.1,0,9.3,4.2,9.3,9.3V256c0,5.1-4.2,9.3-9.3,9.3h-18.6c-5.1,0-9.3-4.2-9.3-9.3v-18.6     C116.4,232.2,120.5,228.1,125.7,228.1z"
          />
        </g>
        <path
          class="st6"
          style="fill: #f29c6b"
          d="M355.8,302l-195.5,97.7c-0.6,0.3-1.4,0.5-2.1,0.5h-55.9c-2.6,0-4.7-2.1-4.7-4.6c0-1.4,0.6-2.8,1.7-3.7    l121-97.7c0.8-0.7,1.9-1,2.9-1h130.3c2.6,0,4.6,2.1,4.6,4.7C358.4,299.7,357.4,301.2,355.8,302z"
        />
        <path
          class="st7"
          style="fill: #f26a1b"
          d="M158.3,400.3H97.7v-37.2H121l37.2,32.6V400.3z"
        />
        <path
          class="st1"
          style="fill: #ffffff"
          d="M377,121c0,18-14.6,32.6-32.6,32.6h-65.2c-15.4,0.1-28-12.4-28-27.8c-0.1-13.7,9.9-25.4,23.4-27.7    c2.1-20.5,20.3-35.4,40.8-33.3c13.9,1.4,25.9,10.5,31,23.6C363.6,89.5,377,103.8,377,121z"
        />
      </g>
    </g>
  </svg>
</template>
