export enum EBaseNotificationBarKey {
    INIT = 'INIT',
    FREE = 'FREE',
    PAID = 'PAID',
    TRIAL = 'TRIAL',
    OVERDUE_TRIAL = 'OVERDUE_TRIAL',
    OVERDUE_PAID = 'OVERDUE_PAID',
    BLOCK = 'BLOCK',
    // VERIFY_EMAIL
    VERIFY_EMAIL = 'VERIFY_EMAIL',
}

const totalMillisecondInOneHour = 60 * 60 * 1000;
const _convertToMillisecond = (value, unit: 'd' | 'h') => {
    let result = value;
    switch (unit) {
        case 'd':
            result = value * 24 * totalMillisecondInOneHour;
            break;
        case 'h':
            result = value * totalMillisecondInOneHour;
            break;

        default:
            result = value;
            break;
    }

    return result;
};

export const notificationBarConfigByKey = {
    INIT: {
        periodOfMuteTime: _convertToMillisecond(3, 'd'),
    },
    PAID: {
        periodOfMuteTime: _convertToMillisecond(3, 'd'),
    },
    TRIAL: {
        periodOfMuteTime: _convertToMillisecond(3, 'd'),
    },
    OVERDUE_TRIAL: {
        periodOfMuteTime: _convertToMillisecond(3, 'd'),
    },
    OVERDUE_PAID: {
        periodOfMuteTime: _convertToMillisecond(3, 'd'),
    },
    BLOCK: {
        periodOfMuteTime: _convertToMillisecond(3, 'd'),
    },
    VERIFY_EMAIL: {
        periodOfMuteTime: _convertToMillisecond(2, 'd'),
    },
};
