<script setup lang="ts">
import { computed } from 'vue';
import supportStore from '@/store/support';

withDefaults(
  defineProps<{
    isLabel?: boolean;
    customClass?: string;
    classActive?: string;
  }>(),
  {
    customClass: '',
    classActive:
      'bg-current-500 fill-white ring ring-current-200 ring-opacity-30',
  }
);

const _supportStore = supportStore();
const isShowHelpPanel = computed(() => _supportStore.isOpenPanelHelp);

const onOpenShowHelpPanel = () => {
  _supportStore.setOpenPanelHelp(!isShowHelpPanel.value);
  _supportStore.setVideoActive({});
};

defineExpose({
  onOpenShowHelpPanel,
});
</script>

<template>
  <div
    class="cursor-pointer rounded"
    :class="`${customClass} ${isShowHelpPanel ? classActive : ''} ${
      isLabel
        ? 'w-full space-x-4 text-sm flex items-center'
        : 'w-8  h-8 flex-center'
    }`"
    @click.stop="onOpenShowHelpPanel"
  >
    <syn-icon name="help-icon" is-active custom-class=" w-6 h-6" />
    <span v-if="isLabel" class="truncate">{{
      $t('SUPPORT_LABEL_TEXT_TITLE')
    }}</span>
  </div>
</template>
