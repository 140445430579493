import BaseModel from './BaseModel';
import {
    collection,
    deleteField,
    doc,
    DocumentData,
    DocumentReference,
    onSnapshot,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export default class MessageDocModel extends BaseModel {
    docRef: DocumentReference<DocumentData>;

    constructor(conversationId: string, messageId: string) {
        super();

        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;
        this.docRef = doc(
            collection(
                doc(
                    collection(
                        doc(
                            collection(
                                firestoreDb,
                                'organizationConversations'
                            ),
                            `${organizationId}`
                        ),
                        'conversations'
                    ),
                    `${conversationId}`
                ),
                'messages'
            ),
            `${messageId}`
        );
    }

    remove(removerId) {
        return this.updateDoc(
            this.docRef,
            {
                removed: true,
                removedBy: `${removerId}`,
                removedDate: this.serverTimestamp(),
            },
            null
        );
    }
    getMessage() {
        return this.getDoc(this.docRef);
    }

    addReaction(content, userId) {
        const newReactionId = uuidv4();
        const newReaction = {
            content: content || null,
            createdBy: `${userId}`,
            createdDate: this.serverTimestamp(),
        };
        this.setDoc(
            this.docRef,
            { reactions: { [`${newReactionId}`]: newReaction } },
            { merge: true }
        );
    }

    updateReaction(reactionId, content) {
        this.setDoc(
            this.docRef,
            { reactions: { [`${reactionId}`]: { content: content || null } } },
            { merge: true }
        );
    }

    deleteReactions(reactionIds) {
        const delReactions = (reactionIds || []).reduce((obj, reactionId) => {
            obj[reactionId] = deleteField();
            return obj;
        }, {});

        return this.setDoc(
            this.docRef,
            { reactions: delReactions },
            { merge: true }
        );
    }

    async addBackgroundColorMsg(userId, color, isShowAll) {
        const newBgColor = {
            hex: color,
            isShowAll: `${isShowAll}`,
        };

        await this.setDoc(
            this.docRef,
            {
                customizes: {
                    [`backgroundColor`]: { [`${userId}`]: newBgColor },
                },
            },
            { merge: true }
        );

        return newBgColor;
    }

    deleteBackgroundColorMsg(userId) {
        return this.setDoc(
            this.docRef,
            {
                customizes: {
                    [`backgroundColor`]: { [`${userId}`]: deleteField() },
                },
            },
            { merge: true }
        );
    }

    subscibleDetailChanged(callback) {
        return onSnapshot(this.docRef, (snapshot) => {
            callback && callback(snapshot.data());
        });
    }

    updateConversationMessagePreviewLinks(dataObjLinks) {
        return this.updateDoc(this.docRef, { linkObjects: dataObjLinks }, null);
    }

    pinMessage(userId) {
        return this.updateDoc(this.docRef, {
            pinned: true,
            pinnedBy: `${userId}`,
            pinnedDate: this.serverTimestamp(),
        });
    }

    unpinMessage() {
        return this.updateDoc(this.docRef, {
            pinned: deleteField(),
            pinnedBy: deleteField(),
            pinnedDate: deleteField(),
        });
    }
}
