<script setup lang="ts">
import { computed, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { isSameId } from '@/ui/helpers/utils';
import {
  IDayOffFromMessage,
  ECalendarFromMessageCategory,
  IDayOffDetail,
  IEventFromMessage,
} from '@/application/types/calendar/day-off.types';
import DayOffShortDetail from '@/ui/components/dayoff/detail/DayOffShortDetail.vue';
import DayoffDetailModal from '@/ui/components/dayoff/DayoffDetailModal.vue';
import ModalViewMeeting from '@/ui/modules/calendar/modal-view-event/ModalViewMeeting.vue';
import ModalViewReminder from '@/ui/modules/calendar/modal-view-event/ModalViewReminder.vue';

const props = defineProps<{
  message: any;
  isMyMessage: boolean;
  conversation: any;
}>();

const myUserId = myProfileStore().myProfile?.id;

const createdCalendarActions = computed<any[]>(() => {
  if (!props.message?.createdCalendarActions) return [];

  return props.conversation?.externalChat || props.conversation?.supportingChat
    ? props.message?.createdCalendarActions?.filter((task) =>
        isSameId(task?.createdBy, myUserId)
      )
    : props.message?.createdCalendarActions;
});

const allDayOffCreatedFromMessage = computed<IDayOffFromMessage[]>(() => {
  if (
    !createdCalendarActions.value ||
    createdCalendarActions.value?.length == 0
  )
    return [];

  return createdCalendarActions.value?.filter(
    (calendar) => calendar?.category == ECalendarFromMessageCategory.DAY_OFF
  );
});
const allEventCreatedFromMessage = computed<IEventFromMessage[]>(() => {
  if (
    !createdCalendarActions.value ||
    createdCalendarActions.value?.length == 0
  )
    return [];

  return createdCalendarActions.value?.filter(
    (calendar) => calendar?.category == ECalendarFromMessageCategory.EVENT
  );
});
const allReminderCreatedFromMessage = computed<IEventFromMessage[]>(() => {
  if (
    !createdCalendarActions.value ||
    createdCalendarActions.value?.length == 0
  )
    return [];

  return createdCalendarActions.value?.filter(
    (calendar) => calendar?.category == ECalendarFromMessageCategory.REMINDER
  );
});

const dayOffList = computed<IDayOffDetail[]>(() => {
  return allDayOffCreatedFromMessage.value;
});

const currentDayOffDetailId = ref<string>('');
const currentEventId = ref<string>('');
const currentReminderId = ref<string>('');
</script>
<template>
  <div v-if="allDayOffCreatedFromMessage?.length" class="flex flex-col">
    <div class="text-xs inline-flex truncate">
      <p class="text-gray-400 shrink-0">
        {{ $t('CHAT_USED_TO_CREATED_DAY_OFF') }}&nbsp;
      </p>
      <template v-for="(dayOff, index) in dayOffList" :key="dayOff?.id">
        <span
          class="hover:underline cursor-pointer"
          @click="currentDayOffDetailId = dayOff?.id"
        >
          <DayOffShortDetail :day-off-detail="dayOff" is-hidden-request-note />
        </span>
        <span v-if="dayOffList?.length > 1 && index < dayOffList?.length - 1"
          >,
        </span>
      </template>
    </div>
  </div>
  <div v-if="allEventCreatedFromMessage?.length" class="flex flex-col">
    <div class="text-xs inline-flex truncate">
      <p class="text-gray-400 shrink-0">
        {{ $t('CHAT_USED_TO_CREATED_EVENT') }}&nbsp;
      </p>
      <template
        v-for="(eventData, index) in allEventCreatedFromMessage"
        :key="eventData?.id"
      >
        <span
          class="
            hover:underline
            cursor-pointer
            text-current-500
            max-w-[8rem]
            truncate
          "
          @click="currentEventId = eventData?.id"
        >
          {{ eventData?.title }}
        </span>
        <span
          v-if="
            allEventCreatedFromMessage?.length > 1 &&
            index < allEventCreatedFromMessage?.length - 1
          "
        >
          ,
        </span>
      </template>
    </div>
  </div>
  <div v-if="allReminderCreatedFromMessage?.length" class="flex flex-col">
    <div class="text-xs inline-flex truncate">
      <p class="text-gray-400 shrink-0">
        {{ $t('CHAT_USED_TO_CREATED_REMINDER') }}&nbsp;
      </p>
      <template
        v-for="(eventData, index) in allReminderCreatedFromMessage"
        :key="eventData?.id"
      >
        <span
          class="
            hover:underline
            cursor-pointer
            text-current-500
            max-w-[8rem]
            truncate
          "
          @click="currentReminderId = eventData?.id"
        >
          {{ eventData?.title }}
        </span>
        <span
          v-if="
            allReminderCreatedFromMessage?.length > 1 &&
            index < allReminderCreatedFromMessage?.length - 1
          "
        >
          ,
        </span>
      </template>
    </div>
  </div>

  <DayoffDetailModal
    v-if="currentDayOffDetailId"
    :id="currentDayOffDetailId"
    @close="currentDayOffDetailId = ''"
  />
  <ModalViewMeeting
    v-if="currentEventId"
    :event-id="currentEventId"
    @cancel="currentEventId = ''"
  />
  <ModalViewReminder
    v-if="currentReminderId"
    :event-id="currentReminderId"
    @cancel="currentReminderId = ''"
  />
</template>
