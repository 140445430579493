<script setup lang="ts">
import { computed, ref } from 'vue';
import { orderBy } from 'lodash';
import chatStore from '@/store/chat';
import { ignoreUnicode } from '@/ui/plugins/utils';
import myProfileStore from '@/store/auth/my-profile';
import ConversationItem from '../conversation-item/ConversationItem.vue';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import UserConversationDocModel from '@/ui/plugins/firebases/firestore-database/models/UserConversationDocModel';
import userStore from '@/store/user';

defineProps<{
  activeConversation?: any;
}>();

defineEmits(['onClose', 'onConversationClick']);

const _chatStore = chatStore();

const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
const myProfile = computed(() => myProfileStore().myProfile);

const searchText = ref(null);

const conversationById = computed(() => _chatStore.conversationById);
const members = computed(() => userStore().allUserByIds);

const archivedConversationIds = computed(() =>
  orderBy(
    _chatStore.userConversationIds?.filter(
      (conversId) =>
        !conversationById.value[conversId]?.deleted &&
        conversationById.value[conversId]?.closed
    ),
    [
      (conversId) =>
        conversationById.value[conversId]?.lastMessageTime ||
        conversationById.value[conversId]?.createdDate ||
        0,
    ],
    ['desc']
  )
);

const archivedConversationIdsFiltered = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return archivedConversationIds.value;

  return archivedConversationIds.value?.filter(
    (conversId) =>
      conversationById.value[conversId]?.name &&
      ignoreUnicode(conversationById.value[conversId]?.name).includes(searchTxt)
  );
});

const onUnarchiveClick = async (conversId) => {
  if (!organizationId || !myProfile?.value?.id) return;

  await new UserConversationDocModel(
    myProfile?.value?.id,
    conversId
  ).unArchive();
};
</script>

<template>
  <div class="h-full flex flex-col">
    <!--HEADER-->
    <div class="p-3 flex items-center gap-2">
      <VigButton
        ghost
        color="gray"
        rounded="rounded-full"
        icon="Back"
        @click="$emit('onClose')"
      />
      <div class="font-semibold text-xl mr-auto">
        {{ $t('CHAT_ARCHIVED_CONVERS') }}
      </div>
    </div>

    <!--CONVERSATIONS LIST-->
    <template v-if="archivedConversationIds?.length">
      <!--SEARCH BOX-->
      <div
        class="px-3 py-2 mb-1"
        :class="{ 'bg-gradient-to-r from-current-300 to-teal-400': searchText }"
      >
        <VigSearchBox
          v-model="searchText"
          autofocus
          :input-class="
            searchText
              ? 'bg-white border border-transparent'
              : 'bg-gray-100 border border-transparent'
          "
        />
      </div>

      <!--CONVERSATIONS LIST-->
      <div class="p-1 flex-1 overflow-auto small-scrollbar">
        <div
          v-for="conversId in archivedConversationIdsFiltered"
          :key="conversId"
          class="rounded-md overflow-hidden relative group/archived-convers"
        >
          <ConversationItem
            class="flex-1 overflow-auto"
            :conversation="conversationById[conversId]"
            :selected="activeConversation?.id === conversId"
            :members="members"
            @click="$emit('onConversationClick', conversId)"
          />
          <VigButton
            v-vig-tooltip="$t('CHAT_CONVERS_UNARCHIVE')"
            outline
            color="gray"
            rounded="rounded-full"
            icon="Unarchive"
            class="
              bg-white
              absolute
              right-2
              top-1/2
              -my-4
              invisible
              group-hover/archived-convers:visible
            "
            @click="onUnarchiveClick(conversId)"
          />
        </div>
      </div>
    </template>

    <!--NO DATA-->
    <div
      v-if="!archivedConversationIds?.length"
      class="p-3 flex-center flex-col text-gray-700 text-center"
    >
      <div class="">
        <SynAnimation name="emptyBox" stype="width: 150px; height: 150px" />
      </div>
      <em>{{
        $t('CHAT_ARCHIVED_CONVERS_NO_MSG') || 'No archived conversation'
      }}</em>
    </div>
  </div>
</template>
