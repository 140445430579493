<script setup lang="ts">
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { arrToObj } from '@/ui/helpers/utils';
import taskDrawerStore from '@/store/task/drawer';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import {
  formatTaskCode,
  getConfigScheduleColor,
  getDetailTaskUrl,
  taskStates,
} from '@/ui/hooks/taskHook';
import GroupById from '@/ui/components/group/GroupById.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';
import taskStore from '@/store/task';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import myProfileStore from '@/store/auth/my-profile';
import bindingInterfaceTaskComposables from '@/ui/composables/task/binding-interface-task';
import TaskApprovalShortInfo from '@/ui/modules/task/approval/TaskApprovalShortInfo.vue';
import ColumnFilter from '@/ui/modules/task/table/ColumnFilter.vue';
import TaskPlaningLabel from '@/ui/modules/task/detail/_commons/task-planing/TaskPlaningLabel.vue';
import useWorkflowTasksPaging from '@/ui/modules/workflow-task/_composables/workflow-tasks-paging.composable';
import WorkflowStepCodeLabel from '@/ui/modules/workflow/workflow-step/WorkflowStepCodeLabel.vue';

const props = defineProps<{
  workflowSteps: any[];
  tasksByStep: { [stepId: string]: any[] };
  sortByKey: { [key: string]: any };
  filterByKey: { [key: string]: any };
  isLoading: boolean;
}>();

defineEmits(['onSort', 'onFilter', 'onFilterReset']);

const PAGE_SIZE = 10;
const LOAD_MORE_SIZE = 10;

const _taskStore = taskStore();
const _taskDrawerStore = taskDrawerStore();

const { tasksByStep } = toRefs(props);

const {
  tasksByStepPaging,
  remainingTasksCountByStep,
  onLoadMoreStepTasks,
  updateStepTasks,
} = useWorkflowTasksPaging(tasksByStep, PAGE_SIZE);
const { isTaskHasJustCreate } = bindingInterfaceTaskComposables(null);

const stepExpandedById = ref<{ [stepId: string]: boolean }>({});

const myTimezone = computed(() => myProfileStore().timezone);
const currentTaskId = computed(() => _taskDrawerStore.currentTabId);

watch(
  () => props.tasksByStep,
  () => {
    _updateListTasks();
  }
);

onMounted(() => {
  _updateListTasks();
});

const onTaskClick = (task) => {
  if (!task) return;

  _taskDrawerStore.pushCurrentIds({
    id: task.id?.toString(),
    code: task.code,
    name: task.name,
    tabType: 'DETAIL',
  });
};

const getConfigScheduleName = (task) => {
  return _taskStore.getConfigScheduleName(task);
};

const _updateListTasks = () => {
  props.workflowSteps?.forEach((step) => {
    updateStepTasks(step?.id);
  });
};

const initPage = () => {
  stepExpandedById.value = props.tasksByStep
    ? arrToObj(
        props.workflowSteps,
        'id',
        (step) => !!props.tasksByStep[step?.id]?.length
      )
    : {};
};

defineExpose({
  initPage,
});
</script>

<template>
  <div class="h-full bg-gray-50 flex flex-col">
    <div class="border-b"></div>
    <div v-perfect-scrollbar class="flex-1">
      <SynTableCustom>
        <template #header>
          <SynTh />
          <SynTh
            :label="$t('TASK_TABLE_LABEL_TASKS')"
            is-sort
            :order-data="sortByKey ? sortByKey['code'] : null"
            @on-sort="$emit('onSort', 'code')"
          >
            <template #custom>
              <ColumnFilter
                type="group"
                class="pr-1"
                :data-filters="filterByKey ? filterByKey['groupId'] : null"
                @on-ok="(data) => $emit('onFilter', 'groupId', data)"
                @on-reset-filter="$emit('onFilterReset', 'groupId')"
              />
            </template>
          </SynTh>
          <SynTh :label="$t('TASK_CREATE_FORM_DOMAIN')">
            <template #custom>
              <ColumnFilter
                type="domain"
                class="pr-1"
                :data-filters="filterByKey ? filterByKey['domainId'] : null"
                @on-ok="(data) => $emit('onFilter', 'domainId', data)"
                @on-reset-filter="$emit('onFilterReset', 'domainId')"
              />
            </template>
          </SynTh>
          <SynTh
            :label="$t('TASK_TABLE_LABEL_URGENCY')"
            is-sort
            :order-data="sortByKey ? sortByKey['urgency'] : null"
            @on-sort="$emit('onSort', 'urgency')"
          >
            <template #custom>
              <ColumnFilter
                type="urgency"
                class="pr-1"
                :data-filters="filterByKey ? filterByKey['urgency'] : null"
                @on-ok="(data) => $emit('onFilter', 'urgency', data)"
                @on-reset-filter="$emit('onFilterReset', 'urgency')"
              />
            </template>
          </SynTh>
          <SynTh
            :label="$t('TASK_TABLE_LABEL_DEADLINE')"
            is-sort
            :order-data="sortByKey ? sortByKey['deadline'] : null"
            @on-sort="$emit('onSort', 'deadline')"
          >
            <template #custom>
              <ColumnFilter
                type="date"
                is-nothing
                class="pr-1"
                :data-filters="filterByKey ? filterByKey['deadline'] : null"
                @on-ok="(data) => $emit('onFilter', 'deadline', data)"
                @on-reset-filter="$emit('onFilterReset', 'deadline')"
              />
            </template>
          </SynTh>
          <SynTh
            :label="$t('TASK_EXPORT_COLUMNS_STATUS')"
            is-sort
            :order-data="sortByKey ? sortByKey['taskLife'] : null"
            @on-sort="$emit('onSort', 'taskLife')"
          >
            <template #custom>
              <ColumnFilter
                type="status"
                class="pr-1"
                :data-filters="filterByKey ? filterByKey['taskLife'] : null"
                @on-ok="(data) => $emit('onFilter', 'taskLife', data)"
                @on-reset-filter="$emit('onFilterReset', 'taskLife')"
              />
            </template>
          </SynTh>
          <SynTh :label="$t('TASK_FILTER_LABEL_PLANNED')">
            <template #custom>
              <ColumnFilter
                type="taskPlan"
                class="pr-1"
                :data-filters="filterByKey ? filterByKey['taskPlan'] : null"
                @on-ok="(data) => $emit('onFilter', 'taskPlan', data)"
                @on-reset-filter="$emit('onFilterReset', 'taskPlan')"
              />
            </template>
          </SynTh>
          <SynTh :label="$t('TASK_LABEL_ASSIGNEE')" is-center>
            <template #custom>
              <ColumnFilter
                type="user"
                class="pr-1"
                :data-filters="filterByKey ? filterByKey['assigneeId'] : null"
                @on-ok="(data) => $emit('onFilter', 'assigneeId', data)"
                @on-reset-filter="$emit('onFilterReset', 'assigneeId')"
              />
            </template>
          </SynTh>
          <SynTh
            :label="$t('TASK_TABLE_LABEL_CREATED_DATE')"
            is-sort
            :order-data="sortByKey ? sortByKey['creationTime'] : null"
            @on-sort="$emit('onSort', 'creationTime')"
          >
            <template #custom>
              <ColumnFilter
                type="date"
                class="pr-1"
                :data-filters="filterByKey ? filterByKey['creationTime'] : null"
                @on-ok="(data) => $emit('onFilter', 'creationTime', data)"
                @on-reset-filter="$emit('onFilterReset', 'creationTime')"
              />
            </template>
          </SynTh>
          <SynTh></SynTh>
        </template>

        <template #body>
          <template v-if="!isLoading">
            <template v-for="step in workflowSteps" :key="step?.id">
              <!--STEP INFO-->
              <SynTr class="cursor-pointer">
                <SynTd class="w-0">
                  <VigButton
                    ghost
                    color="gray"
                    padding="p-1.5"
                    :icon="
                      stepExpandedById[step?.id]
                        ? 'ChevronDown2'
                        : 'ChevronRight2'
                    "
                    @click="
                      stepExpandedById[step?.id] = !stepExpandedById[step?.id]
                    "
                  />
                </SynTd>
                <SynTd colspan="15">
                  <div class="flex items-center gap-1">
                    <WorkflowStepCodeLabel
                      :model-value="step?.code || ''"
                      readonly
                    />
                    <span class="font-semibold text-[0.9rem]">
                      {{ step?.name }}</span
                    >
                    <span class="text-gray-500 ml-2">
                      ({{
                        $t('WORKFLOW_NUM_OF_TASKS', {
                          number: tasksByStep[step?.id]?.length || 0,
                        })
                      }})</span
                    >
                  </div>
                </SynTd>
              </SynTr>

              <!--STEP TASK-->
              <template v-if="stepExpandedById[step?.id]">
                <SynTr
                  v-for="task in tasksByStepPaging[step?.id]"
                  :key="task?.id"
                  class="
                    bg-white
                    cursor-pointer
                    hover:bg-gray-100 hover:text-current-500
                    border-t border-gray-50
                  "
                  @click="onTaskClick(task)"
                >
                  <SynTd class="relative">
                    <TaskApprovalShortInfo
                      v-if="task?.approvalEnabled"
                      :approval-enabled="task?.approvalEnabled"
                      :approval-status="task?.approvalStatus"
                      :approvers="task?.approvers"
                      :approver-id="task?.approverId"
                      readonly
                    />

                    <span
                      v-if="currentTaskId == task?.id"
                      :class="
                        isOpenTaskDrawer ? 'border-current' : 'border-gray-300'
                      "
                      class="
                        absolute
                        left-0
                        top-0
                        h-full
                        border-2 border-current
                      "
                    >
                    </span>
                  </SynTd>

                  <!--TASK NAME-->
                  <SynTd class="max-w-[30vw] min-w-[20rem] relative">
                    <a
                      :title="task.name"
                      :href="getDetailTaskUrl(task.code, false)"
                      class="flex items-center gap-3"
                      @click.prevent
                    >
                      <div
                        class="
                          flex-1
                          overflow-hidden
                          flex flex-nowrap
                          items-center
                          gap-1.5
                        "
                      >
                        <div class="text-xs text-gray-500 mr-1.5">
                          {{ formatTaskCode(task?.code) }}
                        </div>
                        <div
                          v-encode-html="{ text: task?.name }"
                          class="truncate"
                        ></div>
                        <SynIcon
                          v-if="task?.isPrivate"
                          name="private"
                          custom-class="w-2.5 h-2.5 fill-gray-500 min-w-[0.625rem]"
                        />
                        <SynIcon
                          v-if="task?.isRepeat"
                          name="recurrence2"
                          class="fill-gray-500 min-w-[1rem]"
                        />
                      </div>

                      <div
                        v-if="task?.groupId"
                        class="min-w-[5rem] max-w-[30%]"
                      >
                        <GroupById
                          :group-id="task.groupId"
                          class="text-xs"
                          avatar-class="w-3 h-3"
                        />
                      </div>
                    </a>

                    <!--NEW LABEL-->
                    <span
                      v-if="isTaskHasJustCreate(task)"
                      class="
                        absolute
                        top-0
                        right-0
                        text-[0.6rem]
                        leading-4
                        bg-current
                        text-white
                        px-1.5
                        rounded-b-md
                      "
                    >
                      {{ $t('COMMON_LABEL_NEW') }}
                    </span>
                  </SynTd>

                  <!--DOMAIN-->
                  <SynTd>
                    <DomainProjectById
                      class="max-w-[10rem]"
                      :domain-id="task?.domainId"
                      :project-id="task?.projectId"
                      :sub-project-id="task?.subprojectId"
                    />
                  </SynTd>

                  <!--PRIORITY-->
                  <SynTd>
                    <PriorityTask
                      :priority="task?.priority"
                      :important="task?.important"
                      size="mini"
                      is-hidden-label
                    />
                  </SynTd>

                  <!--DEADLINE-->
                  <SynTd>
                    <div class="flex items-center gap-1">
                      <SynTag
                        :class="[getConfigScheduleColor(task?.taskSchedule)]"
                        class="border flex-center"
                        size="small"
                      >
                        <span
                          v-if="task.assigneeId && getConfigScheduleName(task)"
                        >
                          {{ getConfigScheduleName(task) }}
                        </span>
                        <SynLabelDateTime
                          v-else-if="task.scheduleTime"
                          format="date"
                          :datetime="task.scheduleTime"
                        />
                        <span v-else>
                          {{
                            $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule'
                          }}
                        </span>
                      </SynTag>
                      <UpdateDeadlineTime
                        v-if="task?.scheduleByHour"
                        :class="[
                          getConfigScheduleColor(task?.taskSchedule),
                          'text-xs border rounded px-1 h-6 flex-center',
                        ]"
                        :schedule-time="task?.scheduleTime"
                        :schedule-by-hour="task?.scheduleByHour"
                        :schedule-timezone="task?.scheduleTimezone"
                        :my-timezone="myTimezone"
                        readonly
                      />
                    </div>
                  </SynTd>

                  <!--STATUS-->
                  <SynTd>
                    <SynTag
                      v-if="task.taskLife"
                      class="rounded-full w-max px-2 text-xs"
                      size="small"
                      :class="[
                        taskStates[task?.taskLife]?.background,
                        taskStates[task?.taskLife]?.color,
                      ]"
                    >
                      {{ $t(taskStates[task?.taskLife]?.nameCode) }}
                    </SynTag>
                  </SynTd>

                  <!--PLANNING-->
                  <SynTd>
                    <TaskPlaningLabel :task="task" />
                  </SynTd>

                  <!--ASSIGNEE-->
                  <SynTd class="w-0 pl-0">
                    <div v-if="task?.assigneeId" class="flex-center">
                      <UserByAction
                        :user-id="task?.assigneeId"
                        is-hidden-name
                        is-show-action-user
                        avatar-class="w-7 h-7"
                      />
                    </div>
                  </SynTd>

                  <!--CREATE DATE-->
                  <SynTd class="whitespace-nowrap text-xs">
                    <SynLabelDateTime
                      format="date"
                      :datetime="task?.creationTime"
                    />
                  </SynTd>

                  <!--ACTIONS-->
                  <SynTd></SynTd>
                </SynTr>

                <!--NO TASK-->
                <SynTr v-if="!tasksByStep[step?.id]?.length">
                  <SynTd></SynTd>
                  <SynTd colspan="15" class="text-gray-500 italic text-sm">
                    {{ $t('WORKFLOW_LABEL_NO_TASK') }}
                  </SynTd>
                </SynTr>

                <!--STEP TASK SHOW MORE-->
                <SynTr v-if="remainingTasksCountByStep[step?.id] > 0">
                  <SynTd padding="py-1.5" colspan="15">
                    <div class="flex-center">
                      <a
                        href="javascript:"
                        class="text-sm underline text-gray-600"
                        @click="onLoadMoreStepTasks(step?.id, LOAD_MORE_SIZE)"
                        >{{
                          $t('WORKFLOW_VIEW_MORE_NUM_TASKS', {
                            number: remainingTasksCountByStep[step?.id],
                          })
                        }}
                      </a>
                    </div>
                  </SynTd>
                </SynTr>
              </template>

              <!--STEP DIVIDER-->
              <SynTr>
                <SynTd padding="py-1" colspan="15"></SynTd>
              </SynTr>
            </template>
          </template>

          <!--LOADING-->
          <SynTr v-else class="animate-pulse">
            <SynTd class="w-0">
              <div class="h-5 w-7 rounded bg-gray-200"></div>
            </SynTd>
            <SynTd>
              <div class="h-5 w-[20rem] rounded bg-gray-200"></div>
            </SynTd>
            <SynTd>
              <div class="h-5 w-[6rem] rounded bg-gray-200"></div>
            </SynTd>
            <SynTd align="text-center">
              <div class="h-5 w-5 rounded-full bg-gray-200 inline-block"></div>
            </SynTd>
            <SynTd>
              <div class="h-5 w-[6rem] rounded bg-gray-200"></div>
            </SynTd>
            <SynTd>
              <div class="h-5 w-[5rem] rounded bg-gray-200"></div>
            </SynTd>
            <SynTd>
              <div class="h-5 w-[5rem] rounded bg-gray-200"></div>
            </SynTd>
            <SynTd align="text-center">
              <div class="h-7 w-7 rounded-full bg-gray-200 inline-block"></div>
            </SynTd>
            <SynTd>
              <div class="h-5 w-[5rem] rounded bg-gray-200"></div>
            </SynTd>
            <SynTd></SynTd>
          </SynTr>
        </template>
      </SynTableCustom>
    </div>
  </div>
</template>

<style scoped></style>
