import { defineStore } from 'pinia';
import AuthService from '@/application/services/AuthService';
import storeId from '@/base/store/storeId';
// refactor later
import { setLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { UserCallingStatus } from '@/domain/enums/VideoCallEnum';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';

export default defineStore({
    id: storeId.auth,
    state: () => ({
        _authService: AuthService.getInstance(),
        _unAuthenticateService: UnAuthenticateService.getInstance(),
        email: '',
        phone: '',
        resetToken: '',
        callingStatus: {} as UserCallingStatus | null,
    }),
    getters: {},
    actions: {
        setCallingStatus(data: UserCallingStatus | null) {
            this.callingStatus = data;
        },
        selectOrganization(organizationId: number): Promise<any> {
            return this._authService.selectOrganization({
                OrganizationId: organizationId,
            });
        },
        async forgotPassword(email: string): Promise<any> {
            return await this._authService.forgotPassword({
                EmailAddress: email,
            });
        },
        async validateResetPasswordCode(payload): Promise<any> {
            const res = await this._authService.validateResetPasswordCode(
                payload
            );
            return res;
        },
        resetPassword(payload): Promise<any> {
            return this._authService.resetPassword(payload);
        },
        async loginWithPassword(payload: any): Promise<any> {
            const res = await this._authService.loginWithPassword(payload);
            return res;
        },
        async checkPhone(phone: string): Promise<any> {
            return await this._authService.checkPhone(phone);
        },
        async checkEmail(email: string): Promise<any> {
            const res = await this._authService.checkEmail(email);
            return res?.result;
        },
        async saveUserProfile(data: any): Promise<any> {
            const res = await this._authService.saveUserProfile(data);
            return res;
        },
        async verifyUserPhoneNumber(data: any): Promise<any> {
            const res = await this._authService.verifyUserPhoneNumber(data);
            return res;
        },
        async sendVerificationCodeByPhoneNumber(data: any): Promise<any> {
            const res =
                await this._unAuthenticateService.sendVerificationCodeByPhoneNumber(
                    data
                );
            return res;
        },
        async reSendVerificationCodeByPhoneNumber(data: any): Promise<any> {
            const res =
                await this._unAuthenticateService.reSendVerificationCodeByPhoneNumber(
                    data
                );
            return res;
        },
        async loginByPhoneCode(data: any): Promise<any> {
            const res = await this._authService.loginByPhoneCode(data);
            return res;
        },
        async loginByEmailCode(data: any): Promise<any> {
            const res = await this._authService.loginByEmailCode(data);
            return res;
        },
        async getCurrentUserLogin(): Promise<any> {
            const res = await this._authService.getCurrentLoginInformations();
            const data = res['result'];
            // SettingOrganization = "SETTING_ORGANIZATION";
            // SettingTeam = "SETTING_TEAM";
            // SettingFinished = "SETTING_FINISHED";
            MyOrganizationSingleton.setOrganizationSettingProgress(
                data['currentSettingProgress']
            );

            setLocalStorage(StorageConstant.CurrentEmail, data['email']);
            return data;
        },
        async verifyUserEmail(data: any): Promise<any> {
            const res = await this._authService.verifyUserEmail(data);
            return res;
        },
        async sendVerificationCodeByEmail(data: any): Promise<any> {
            const res = await this._authService.sendVerificationCodeByEmail(
                data
            );
            return res;
        },
        async reSendVerificationCodeByEmail(data: any): Promise<any> {
            const res = await this._authService.reSendVerificationCodeByEmail(
                data
            );
            return res;
        },
        async checkUserHasPasswordByEmail(data: string): Promise<any> {
            const res = await this._authService.checkUserHasPasswordByEmail(
                data
            );
            return res;
        },
        async checkUserHasPasswordByPhone(data: string): Promise<any> {
            const res = await this._authService.checkUserHasPasswordByPhone(
                data
            );
            return res;
        },
        async submitVerificationUserEmailToken(data: any): Promise<any> {
            const res =
                await this._authService.submitVerificationUserEmailToken(data);
            return res;
        },
        async submitVerificationEmailUserTokenForForgotPassword(
            data: any
        ): Promise<any> {
            const res =
                await this._authService.submitVerificationEmailUserTokenForForgotPassword(
                    data
                );
            return res;
        },
        async refresh(payload: any): Promise<any> {
            const res = await this._authService.refresh(payload);
            return res;
        },
        async logout(payload: any): Promise<any> {
            const res = await this._authService.logout(payload);
            return res;
        },
        async getUserOrganizationName(loginId: any): Promise<any> {
            const res = await this._authService.getUserOrganizationName(
                loginId
            );
            return res['result'];
        },
    },
});
