import {
    openNotification,
    AlertType,
    NotificationPositionType,
} from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

window.addEventListener('offline', () => {
    openNotification({
        type: AlertType.error,
        position: NotificationPositionType.TopCenter,
        title:
            translate('COMMON_YOU_ARE_OFFLINE') || 'You are currently offline',
        body:
            translate('COMMON_PLEASE_CHECK_OR_TRY_AGAIN') ||
            'Please check your internet connection or try again later',
    });
});

window.addEventListener('online', () => {
    // console.log('online');
    openNotification({
        type: AlertType.success,
        position: NotificationPositionType.TopCenter,
        body:
            translate('COMMON_YOU_ARE_ONLINE') ||
            'Your internet connection was restored.',
    });
});
