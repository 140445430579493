import { ref, computed, UnwrapRef, Ref } from 'vue';
import scopeStore from '@/store/scope';
import groupStore from '@/store/group';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

export const enum DomainTypeEnum {
    domain = 'domain',
    project = 'project',
    subProject = 'subProject',
}

export default function domainLogic(formData: Ref<UnwrapRef<any>>) {
    const _scopeStore = scopeStore();
    const currentGroup = computed(() =>
        formData.value?.groupId
            ? groupStore().myGroupByIds[formData.value?.groupId]
            : {}
    );
    const updateGroupAble = computed(() =>
        currentGroup.value?.isAdminGroup || !formData.value?.groupId
            ? true
            : false
    );
    const currentDomain = computed(() => {
        if (!formData.value?.domainId) return {};
        return _scopeStore.domainByIds[formData.value?.domainId];
    });
    const currentProject = computed(() => {
        if (!formData.value?.projectId) return {};
        return _scopeStore.projectByIds[formData.value?.projectId];
    });
    const allProjects = computed(() => {
        return _scopeStore.projectByIds;
    });
    const domainList = ref([] as any[]);

    const getDomainList = async () => {
        const ownerDomain = await _scopeStore.getDomainByOwnerId(
            formData.value?.assigneeId,
            formData.value?.groupId
        );

        domainList.value =
            ownerDomain?.length > 0
                ? ownerDomain.map((domain) => {
                      return {
                          ...domain,
                          ..._scopeStore.domainByIds[domain.domainId],
                      };
                  })
                : _scopeStore?.domains
                      ?.filter((domain) => {
                          return domain.members?.some(
                              (user) => user?.id == getCurrentUserId()
                          );
                      })
                      ?.map((o) => {
                          return {
                              ...o,
                              domainId: o?.id,
                              domainName: o?.name,
                          };
                      });
    };

    const projectsByDomainId: any = computed(() =>
        currentDomain.value?.projects?.map(
            (projectId) => allProjects.value[projectId]
        )
    );
    const projectsByOwner: any = computed(() => {
        if (!projectsByDomainId.value || projectsByDomainId.value?.length == 0)
            return [];
        if (!formData.value?.assigneeId) return projectsByDomainId.value;
        return projectsByDomainId.value?.filter((project) =>
            project?.members?.some((uId) => uId == formData.value?.assigneeId)
        );
    });
    const subProjectsByProjectId: any = computed(() => {
        return currentProject.value?.subProjects?.map(
            (projectId) => allProjects.value[projectId]
        );
    });

    const subProjectsByOwner: any = computed(() => {
        if (
            !subProjectsByProjectId.value ||
            subProjectsByProjectId.value?.length == 0
        )
            return [];
        if (!formData.value?.assigneeId) return subProjectsByProjectId.value;
        return subProjectsByProjectId.value?.filter((project) =>
            project?.members?.some((uId) => uId == formData.value?.assigneeId)
        );
    });

    const getDefaultDomainName = () => {
        if (formData.value?.domainId) {
            const domainInstance = _scopeStore.getDomainDetailById(
                formData.value?.domainId
            );

            formData.value.domainName = domainInstance.name;
        }
    };
    const getDefaultProjectName = () => {
        if (formData.value?.projectId) {
            const projectInstance = _scopeStore.getProjectDetailById(
                formData.value?.projectId
            );
            formData.value.projectName = projectInstance.name;
        }
    };
    const getDefaultSubProjectName = () => {
        if (formData.value?.subprojectId) {
            const projectInstance = _scopeStore.getProjectDetailById(
                formData.value?.subprojectId
            );
            formData.value.subprojectName = projectInstance.name;
        }
    };
    const initDomain = async () => {
        await getDomainList();

        getDefaultDomainName();
        getDefaultProjectName();
        getDefaultSubProjectName();
    };

    const removeDomainInfo = () => {
        formData.value.domainId = null;
        formData.value.domainName = '';
    };
    const removeProjectInfo = () => {
        formData.value.projectId = null;
        formData.value.projectName = '';
    };
    const removeSubProjectInfo = () => {
        formData.value.subprojectId = null;
        formData.value.subprojectName = '';
    };

    const removeDomain = (type: DomainTypeEnum = DomainTypeEnum.domain) => {
        switch (type) {
            case DomainTypeEnum.domain:
                removeDomainInfo();
                removeProjectInfo();
                removeSubProjectInfo();
                break;

            case DomainTypeEnum.project:
                removeProjectInfo();
                removeSubProjectInfo();
                break;

            case DomainTypeEnum.subProject:
                removeSubProjectInfo();
                break;

            default:
                break;
        }
    };
    const onChooseDomain = (
        type: DomainTypeEnum = DomainTypeEnum.domain,
        item: {
            domainId?: number;
            domainName?: string;
            id?: number;
            name?: string;
        }
    ) => {
        switch (type) {
            case DomainTypeEnum.domain:
                formData.value.domainId = item.domainId;
                formData.value.domainName = item.domainName;
                removeProjectInfo();
                removeSubProjectInfo();

                break;

            case DomainTypeEnum.project:
                formData.value.projectId = item.id;
                formData.value.projectName = item.name;
                removeSubProjectInfo();

                break;

            case DomainTypeEnum.subProject:
                formData.value.subprojectId = item.id;
                formData.value.subprojectName = item.name;
                break;

            default:
                break;
        }
    };

    const userHasCurrentDomain = computed(() =>
        domainList.value?.some((e) => e.domainId == formData.value.domainId)
    );

    const resetDomainAfterChangeAssign = (callback) => {
        removeProjectInfo();
        removeSubProjectInfo();
        if (domainList.value?.length > 0 && !userHasCurrentDomain.value)
            removeDomainInfo();

        callback && callback();
    };
    return {
        domainList,
        initDomain,
        getDomainList,
        removeDomain,
        onChooseDomain,
        updateGroupAble,
        resetDomainAfterChangeAssign,
        projectsByOwner,
        subProjectsByOwner,
    };
}
