<script setup lang="ts">
import { computed, ref } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import myProfileStore from '@/store/auth/my-profile';
import { AdminType } from '@/ui/common/constants/constant';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  members: {
    type: Array,
    default: () => [],
  },
  groups: {
    type: Array,
    default: () => [],
  },
  domains: {
    type: Array,
    default: () => [],
  },
  toggleClass: {
    type: String,
    default: 'rounded shadow bg-white px-3 py-2 justify-between',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  showNavButtons: {
    type: Boolean,
    default: true,
  },
  showOnlyMyGroups: {
    type: Boolean,
    default: false,
  },
  showOtherMembers: {
    type: Boolean,
    default: true,
  },
  isShowScrollToggle: {
    type: Boolean,
    default: true,
  },
  customItemClass: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'medium',
  },
});

const emit = defineEmits(['update:modelValue']);
const myProfile = computed(() => myProfileStore().myProfile);

const searchText = ref();
const inputSearchRef = ref();
const listMembersRef = ref();
const focusingMember = ref();
const isOpenDropdown = ref(false);

const filteredMembers = computed(() => {
  if (myProfile.value?.adminType == AdminType.Visitor) return [];
  let members: any = props.members || [];

  if (!props.showOtherMembers) {
    members = members.filter((member) => member?.id === myProfile.value?.id);
  }

  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return members;

  return members.filter(
    (user) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
  );
});

const filteredGroups = computed(() => {
  let groups: any = props.groups || [];

  if (props.showOnlyMyGroups) {
    groups = groups.filter((group) => group?.isMember);
  }

  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return groups;

  return groups.filter(
    (group) => group?.name && ignoreUnicode(group?.name).includes(searchTxt)
  );
});

const filteredDomains = computed(() => {
  let domains: any = props.domains || [];

  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return domains;

  return domains.filter(
    (domain) => domain?.name && ignoreUnicode(domain?.name).includes(searchTxt)
  );
});

const onDropdownOpen = () => {
  isOpenDropdown.value = true;
  searchText.value = null;
  inputSearchRef.value?.focus();

  // Scroll to current item
  focusingMember.value = value.value
    ? _prepareFocusableMember(
        value.value?.id,
        value.value.isGroup,
        value.value.isDomain
      )
    : null;

  setTimeout(() => _processScrollToFocusingMember('auto'));
};

const onDropdownClose = () => {
  isOpenDropdown.value = false;
};

const onDropdownItemClick = (member, isGroup = false, isDomain = false) => {
  emit('update:modelValue', {
    id: member?.id,
    name: member?.name,
    isGroup,
    isDomain,
  });
};

const onSearchChange = () => {
  focusingMember.value = null;
};

const onScrollToArea = (area, behavior = 'smooth') => {
  const areaEl = listMembersRef.value?.querySelector(`#${area}`);
  if (!areaEl) return;

  listMembersRef.value.scroll({
    left: 0,
    top: areaEl.offsetTop,
    behavior,
  });
};

const onKeyDown = () => {
  const focusableMembers = _prepareFocusableMembers();

  if (!focusableMembers.length) return;

  if (!focusingMember.value) {
    focusingMember.value = focusableMembers[0];
  } else {
    const focusingIdx = focusableMembers.findIndex(
      (member) =>
        member?.id === focusingMember.value.id &&
        member?.isGroup === focusingMember.value.isGroup
    );

    if (focusingIdx === focusableMembers.length - 1) {
      focusingMember.value = focusableMembers[0];
    }
    if (focusingIdx < focusableMembers.length - 1) {
      focusingMember.value = focusableMembers[focusingIdx + 1];
    }
  }

  _processScrollToFocusingMember();
};

const onKeyUp = () => {
  const focusableMembers = _prepareFocusableMembers();

  if (!focusableMembers.length) return;

  if (!focusingMember.value) {
    focusingMember.value = focusableMembers[0];
  } else {
    const focusingIdx = focusableMembers.findIndex(
      (member) =>
        member?.id === focusingMember.value.id &&
        member?.isGroup === focusingMember.value.isGroup
    );

    if (focusingIdx === 0) {
      focusingMember.value = focusableMembers[focusableMembers.length - 1];
    }
    if (focusingIdx > 0) {
      focusingMember.value = focusableMembers[focusingIdx - 1];
    }
  }

  _processScrollToFocusingMember();
};

const onKeyEnter = () => {
  const focusingEl = listMembersRef.value?.querySelector(
    focusingMember.value?.el
  );
  if (!focusingEl) return;

  focusingEl.click();
};

const _prepareFocusableMembers = () => {
  return (filteredMembers.value || [])
    .map((member) => _prepareFocusableMember(member?.id, false, false))
    .concat(
      (filteredGroups.value || []).map((group) =>
        _prepareFocusableMember(group?.id, true, false)
      )
    )
    .concat(
      (filteredDomains.value || []).map((domain) =>
        _prepareFocusableMember(domain?.id, false, true)
      )
    );
};

const _prepareFocusableMember = (id, isGroup, isDomain) => {
  if (isGroup) return { id, isGroup, el: `#sel-mem-group-${id}` };

  if (isDomain) return { id, isDomain, el: `#sel-mem-domain-${id}` };

  return { id, el: `#sel-mem-user-${id}` };
};

const _processScrollToFocusingMember = (behavior = 'smooth') => {
  const focusingEl = listMembersRef.value?.querySelector(
    focusingMember.value?.el
  );
  if (!focusingEl) return;

  if (
    focusingEl.offsetTop < listMembersRef.value?.scrollTop ||
    focusingEl.offsetTop + focusingEl.offsetHeight >
      listMembersRef.value?.scrollTop + listMembersRef.value.offsetHeight
  ) {
    listMembersRef.value.scroll({
      left: 0,
      top: focusingEl.offsetTop - 20,
      behavior,
    });
  }
};

const value = computed(() => {
  if (!props.modelValue) return null;

  if (props.modelValue.isGroup) {
    const groupValue: any = (props.groups || []).find(
      (group) => group && group.id === props.modelValue.id
    );
    if (!groupValue) return null;

    return {
      id: groupValue?.id,
      name: groupValue?.name,
      avatar: groupValue?.avatar,
      isGroup: true,
    };
  }

  if (props.modelValue.isDomain) {
    const domainVal: any = (props.domains || []).find(
      (domain) => domain && domain.id === props.modelValue.id
    );
    if (!domainVal) return null;

    return {
      id: domainVal?.id,
      name: domainVal?.name,
      isDomain: true,
    };
  }

  const memberValue: any = (props.members || []).find(
    (member) => member && member?.id === props.modelValue.id
  );
  if (!memberValue) return null;

  return {
    id: memberValue?.id,
    name: memberValue?.name,
    nameCode: memberValue?.nameCode,
    avatar: memberValue?.avatar,
    isGroup: false,
  };
});
</script>

<template>
  <vig-dropdown
    :arrow="false"
    :space="0"
    placement="bottom-start"
    @on-dropdown-open="onDropdownOpen"
    @on-dropdown-close="onDropdownClose"
  >
    <template #dropdown-toggle>
      <button
        type="button"
        class="w-full flex items-center text-left space-x-2"
        :disabled="readonly"
        :class="[
          toggleClass,
          { 'shadow-none bg-opacity-0 cursor-default': readonly },
        ]"
      >
        <div class="flex-1 overflow-hidden flex items-center space-x-2">
          <SynAvatar
            v-if="value?.id"
            :src="value?.avatar"
            :name="value?.name"
            :custom-class="size == 'medium' ? 'w-7 h-7' : 'w-6 h-6'"
            :type="
              value?.isGroup ? 'group' : value?.isDomain ? 'domain' : 'user'
            "
          />
          <span class="font-semibold truncate">{{
            $t(value?.nameCode) || value?.name
          }}</span>
          <em v-if="myProfile?.id === value?.id"
            >({{ $t('COMMON_LABEL_ME') }})</em
          >
          <span v-if="value?.isGroup">
            <SynIcon name="Group" class="fill-gray-500" is-active />
          </span>
        </div>
        <span v-if="!readonly" class="text-end">
          <SynIcon
            name="arrow-down"
            custom-class="w-2 h-2 fill-gray-500"
            :class="isOpenDropdown ? 'transform duration-100 -rotate-180' : ''"
          ></SynIcon>
        </span>
      </button>
    </template>
    <template v-if="!readonly" #dropdown-menu>
      <div
        class="w-72 flex flex-col py-2 space-y-2"
        style="height: 28rem"
        @keydown.down="onKeyDown"
        @keydown.up="onKeyUp"
        @keydown.enter="onKeyEnter"
      >
        <div class="px-2">
          <VigSearchBox
            ref="inputSearchRef"
            v-model="searchText"
            :input-class="size == 'medium' ? '' : 'text-sm'"
            @update:model-value="onSearchChange"
          />
        </div>
        <div v-if="showNavButtons" class="px-2 flex space-x-2">
          <vig-button
            v-if="
              isShowScrollToggle && myProfile?.adminType !== AdminType.Visitor
            "
            light
            color="gray"
            padding="px-2 py-1 text-xs"
            rounded="rounded-full"
            @click="onScrollToArea('area-mem-users')"
          >
            {{ $t('COMMON_LABEL_MEMBERS') }}
          </vig-button>
          <vig-button
            v-if="isShowScrollToggle && groups?.length"
            light
            color="gray"
            padding="px-2 py-1 text-xs"
            rounded="rounded-full"
            @click="onScrollToArea('area-mem-groups')"
          >
            {{ $t('COMMON_LABEL_GROUPS') }}
          </vig-button>
          <vig-button
            v-if="isShowScrollToggle && domains?.length"
            light
            color="gray"
            padding="px-2 py-1 text-xs"
            rounded="rounded-full"
            @click="onScrollToArea('area-mem-domains')"
          >
            {{ $t('USERMANAGEMENT_LABEL_DOMAIN') }}
          </vig-button>
        </div>
        <div
          ref="listMembersRef"
          class="flex-1 overflow-y-auto small-scrollbar"
        >
          <div class="relative">
            <div id="area-mem-users"></div>
            <button
              v-for="member in filteredMembers"
              :id="`sel-mem-user-${member?.id}`"
              :key="member"
              type="button"
              class="
                w-full
                flex
                items-center
                text-left
                space-x-2
                hover:bg-gray-100
                dropdown-item
                px-3
                py-1.5
              "
              :class="[
                size == 'medium' ? '' : 'text-sm',
                focusingMember?.id === member?.id && !focusingMember?.isGroup
                  ? 'bg-gray-100'
                  : '',
              ]"
              @click="onDropdownItemClick(member, false)"
            >
              <SynAvatar
                v-if="member?.id"
                :src="member?.avatar"
                :name="member?.name"
                :custom-class="size == 'medium' ? 'w-7 h-7' : 'w-6 h-6'"
              />
              <span class="flex-1 truncate">{{
                $t(member?.nameCode) || member?.name
              }}</span>
              <em v-if="myProfile?.id === member?.id"
                >({{ $t('COMMON_LABEL_ME') }})</em
              >
            </button>
            <div id="area-mem-groups"></div>
            <button
              v-for="group in filteredGroups"
              :id="`sel-mem-group-${group?.id}`"
              :key="group"
              type="button"
              class="
                w-full
                flex
                items-center
                text-left
                space-x-2
                hover:bg-gray-100
                dropdown-item
                px-3
                py-1.5
              "
              :class="[
                size == 'medium' ? '' : 'text-sm',
                focusingMember?.id === group?.id && focusingMember?.isGroup
                  ? 'bg-gray-100'
                  : '',
              ]"
              @click="onDropdownItemClick(group, true)"
            >
              <div
                class="flex-center relative"
                :class="size == 'medium' ? 'w-7 h-7' : 'w-6 h-6'"
              >
                <SynAvatar
                  :src="group?.avatar"
                  :name="group?.name"
                  :custom-class="size == 'medium' ? 'w-7 h-7' : 'w-6 h-6'"
                />
                <div
                  v-if="group?.isMember"
                  class="
                    bg-white
                    flex-center
                    rounded-full
                    absolute
                    -right-1
                    bottom-0
                    w-4
                    h-4
                  "
                >
                  <SynIcon
                    class="fill-current"
                    custom-class="w-3 h-3"
                    name="user-check"
                  />
                </div>
              </div>
              <span class="flex-1 truncate">{{ group?.name }}</span>
              <SynIcon name="Group" class="fill-gray-500" is-active />
            </button>
            <div id="area-mem-domains"></div>
            <button
              v-for="domain in filteredDomains"
              :id="`sel-mem-domain-${domain?.id}`"
              :key="domain?.id"
              type="button"
              class="
                w-full
                flex
                items-center
                text-left
                space-x-2
                hover:bg-gray-100
                dropdown-item
                px-3
                py-1.5
              "
              :class="[
                size == 'medium' ? '' : 'text-sm',
                focusingMember?.id === domain?.id && focusingMember?.isDomain
                  ? 'bg-gray-100'
                  : '',
              ]"
              @click="onDropdownItemClick(domain, false, true)"
            >
              <div
                class="flex-center relative"
                :class="size == 'medium' ? 'w-7 h-7' : 'w-6 h-6'"
              >
                <SynAvatar
                  type="domain"
                  :src="domain?.avatar"
                  :name="domain?.name"
                  :custom-class="size == 'medium' ? 'w-7 h-7' : 'w-6 h-6'"
                />
              </div>
              <span class="flex-1 truncate">{{ domain?.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </vig-dropdown>
</template>
