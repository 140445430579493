import { defineStore } from 'pinia';
import CalendarService from '@/application/services/CalendarService';

import {
    CalendarItem,
    CalendarItemSystem,
    CalendarUpdatePayload,
    CalendarCreatePayload,
} from '@/domain/entities/PayloadClass/CalendarPayloadClass';
import storeId from '@/base/store/storeId';
import {
    CalendarsEventFilter,
    CalendarsSystemEventsFilter,
} from '@/domain/entities/calendars/CalendarsOverviewFiltersClass';
import { CalendarTypeValue } from '@/ui/common/constants/calendar';
import { IEventDetail } from '@/application/types/calendar/event.types';

type CalendarState = {
    _calendarService: CalendarService;
    calendars: any;
    calendarsSystem: any;
    calendarDetailCurrent: IEventDetail | null;
    calendarDetailSymtem: any;
    currentDateStart: string;
    currentDateEnd: string;
    viewCalendar: string;
    isCalendarSystem: boolean;
    isFilter: boolean;
    openAddEventType: CalendarTypeValue | null;
};

export default defineStore({
    id: storeId.calendar,
    state: () =>
        ({
            _calendarService: CalendarService.getInstance(),
            calendars: [] as any,
            calendarsSystem: [] as any,
            calendarDetailCurrent: {},
            calendarDetailSymtem: {},
            currentDateStart: '',
            currentDateEnd: '',
            viewCalendar: 'dayGridMonth',
            isCalendarSystem: true,
            isFilter: false,
            openAddEventType: null,
        } as CalendarState),
    getters: {
        getCurrentDateStart(state): string {
            return state.currentDateStart;
        },

        getCurrentDateEnd(state): string {
            return state.currentDateEnd;
        },

        getViewCalendar(state): string {
            return state.viewCalendar;
        },

        getIsCalendarSystem(state): boolean {
            return state.isCalendarSystem;
        },

        getIsFilter(state): boolean {
            return state.isFilter;
        },
    },
    actions: {
        onOpenAddEvent(type: CalendarTypeValue | null): void {
            this.openAddEventType = type;
        },
        setCurrentDateStart(value: string): void {
            this.currentDateStart = value;
        },

        setCurrentDateEnd(value: string): void {
            this.currentDateEnd = value;
        },

        setViewCalendar(value: string): void {
            this.viewCalendar = value;
        },

        setIsCalendarSystem(value: boolean): void {
            this.isCalendarSystem = value;
        },

        setIsFilter(value: boolean): void {
            this.isFilter = value;
        },

        async getRangeEvents(data): Promise<any> {
            try {
                this.calendars = [];

                const res = await this._calendarService.getRangeEvents(
                    new CalendarsEventFilter(data)
                );
                let result = [];
                result = res['result'];
                result.forEach((date) => {
                    let arrayEvent = [];
                    arrayEvent = date['calendarEvents'];
                    arrayEvent.forEach((event) => {
                        const itemEvent = new CalendarItem(event);
                        this.calendars.push(itemEvent);
                    });
                });
                // this.currentDateStart = dayjs(data.startDate).format('YYYY-MM-DD');
                // this.currentDateEnd = dayjs(data.endDate).format('YYYY-MM-DD');
                return res['result'];
            } catch (err) {
                console.log(err);
            }
        },

        async getSystemEvents(data): Promise<any> {
            try {
                this.calendarsSystem = [];
                const res = await this._calendarService.getSystemEvents(
                    new CalendarsSystemEventsFilter(data)
                );
                let result = [];
                result = res['result'];
                result.forEach((date) => {
                    let arrayEvent = [];
                    arrayEvent = date['calendarEvents'];
                    arrayEvent.forEach((event) => {
                        const itemEvent = new CalendarItemSystem(event);
                        this.calendarsSystem.push(itemEvent);
                    });
                });
                return res['result'];
            } catch (err) {
                console.log(err);
            }
        },

        async addEvent(data): Promise<any> {
            const dataPayload = new CalendarCreatePayload(data);
            try {
                const res = await this._calendarService.addEvent(dataPayload);
                return res;
            } catch (error) {
                console.log(error);
            }
        },

        async getDetail(id): Promise<any> {
            if (!id) return;
            try {
                const res = await this._calendarService.getDetail(id);
                this.calendarDetailCurrent = res['result'];
                return res['result'];
            } catch (err) {
                this.calendarDetailCurrent = null;
                console.log(err);
            }
        },

        async getSystemEventDetail(id): Promise<any> {
            if (!id) return;
            try {
                const res = await this._calendarService.getSystemEventDetail(
                    id
                );
                this.calendarDetailSymtem = res['result'];
                return res['result'];
            } catch (error) {
                console.log(error);
            }
        },

        async updateEvent(data): Promise<any> {
            try {
                const dataDetail = new CalendarUpdatePayload(data);
                const res = await this._calendarService.updateEvent(dataDetail);
                this.calendarDetailCurrent = res['result'];
                return res['result'];
            } catch (error) {
                console.log(error);
            }
        },

        async updateTimeEvent(data): Promise<any> {
            try {
                const res = await this._calendarService.updateTimeEvent({
                    eventId: data.eventId,
                    startDate: data.startDate,
                    endDate: data.endDate,
                });
                return res['result'];
            } catch (error) {
                console.log(error);
            }
        },

        async deleteEvent(id): Promise<any> {
            try {
                const res = await this._calendarService.deleteEvent(id);
                return res['result'];
            } catch (error) {
                console.log(error);
            }
        },

        stopReminder(id): Promise<any> {
            return this._calendarService.stopReminder(id);
        },

        snoozeReminder(id, time): Promise<any> {
            return this._calendarService.snoozeReminder(id, time);
        },

        uploadFileInEvent(files): Promise<any> {
            return this._calendarService.uploadFileInEvent(files);
        },

        cloneFileInEvent(files): Promise<any> {
            return this._calendarService.cloneFileInEvent(files);
        },

        removeFileInEvent(files): Promise<any> {
            return this._calendarService.removeFileEvent(files);
        },
    },
});
