<template>
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    xmlns:svgjs="http://svgjs.com/svgjs"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 311 311.077"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--    class=""-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path-->
  <!--        d="M16.035 311.078c-4.097 0-8.195-1.558-11.308-4.695-6.25-6.25-6.25-16.383 0-22.633L283.789 4.687c6.25-6.25 16.383-6.25 22.633 0s6.25 16.383 0 22.637L27.363 306.383a16.045 16.045 0 0 1-11.328 4.695zm0 0"-->
  <!--      ></path>-->
  <!--      <path-->
  <!--        d="M295.117 311.078a15.879 15.879 0 0 1-11.308-4.695L4.727 27.324c-6.25-6.254-6.25-16.386 0-22.636s16.382-6.25 22.636 0L306.422 283.75c6.25 6.25 6.25 16.383 0 22.633-3.137 3.117-7.23 4.695-11.305 4.695zm0 0"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->

  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        fill-rule="evenodd"
        d="M5 17.586a1 1 0 1 0 1.415 1.415L12 13.414 17.586 19A1 1 0 0 0 19 17.586L13.414 12 19 6.414A1 1 0 0 0 17.585 5L12 10.586 6.414 5A1 1 0 0 0 5 6.414L10.586 12z"
        clip-rule="evenodd"
      ></path>
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
