<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g id="Layer_2" data-name="Layer 2">
        <path
          d="m15 23h-10a4 4 0 0 1 -4-4v-10a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4zm-10-16a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-10a2 2 0 0 0 -2-2z"
          class=""
        ></path>
        <path
          d="m19 19v-2a2 2 0 0 0 2-2v-10a2 2 0 0 0 -2-2h-10a2 2 0 0 0 -2 2h-2a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4z"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
