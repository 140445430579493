import { GroupType } from '@/application/enums/GroupEnums';
import { getBlobData } from '@/ui/components/file-viewer/copyToClipboard';
import { checkIsFileObject } from '@/ui/hooks/commonFunction';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { getObjectDataByUrl } from '@/ui/plugins/awss3/aws-s3-services';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { INewTaskDefaultPayload } from '@/ui/modules/task/task-global-state';

export interface IMessageDetailEntity {
    id: string;
    conversationId?: string;
    organizationId?: number;
    status: number;
    comment?: string;
    text?: string;
    mentionUserIds: string[];
    createdBy: string;
    createdDate: string;
    files: any;
    reactions: any;
}

export default class MessageDetailEntity implements IMessageDetailEntity {
    id: string;
    conversationId?: string;
    organizationId?: number;
    status: number;
    comment?: string;
    text?: string;
    mentionUserIds: string[];
    createdBy: string;
    createdDate: string;
    files: any;
    reactions: any;
    replyToMsg?: any;
    constructor(data) {
        this.id = data?.id;
        this.organizationId = data?.organizationId;
        this.conversationId = data?.conversationId;
        this.status = data?.status;
        this.text = data?.text;
        this.mentionUserIds = data?.mentionUserIds;
        this.createdBy = data?.createdBy;
        this.createdDate = data?.createdDate;
        this.files = data?.files;
        this.reactions = data?.reactions;
        this.replyToMsg = data?.replyToMsg;
    }

    async getDataForCreateTask(contact): Promise<INewTaskDefaultPayload> {
        const messageText = this._getTextMessageFromHtml();
        const files = await this._getFiles();

        const groupId =
            !contact?.isUser && contact?.type !== GroupType.CHAT
                ? contact?.id
                : 0;
        return {
            ...messageText,
            groupId,
            assigneeId: getCurrentUserId(),
            files,
            taskFromChat: {
                orgId: this.organizationId || '',
                conversationId: this.conversationId || '',
                messageId: this.id,
            },
        };
    }
    async getDataForCreateCalendar(isKeepHtmlTag?): Promise<any> {
        const messageText = this._getTextMessageFromHtml(isKeepHtmlTag);
        const files = await this._getFiles();

        return {
            ...messageText,
            files,
            sourceMessage: {
                orgId: this.organizationId || '',
                conversationId: this.conversationId || '',
                messageId: this.id,
            },
        };
    }

    _getTextMessageFromHtml(isKeepHtmlTag?): {
        name: string;
        description: string;
    } {
        const elementMessage = document.createElement('html');
        elementMessage.innerHTML = this.comment
            ? (this.comment || '')?.replaceAll('<br>', '\n ')
            : (this.text || '')?.replaceAll('<br>', '\n ');

        const originTextHasHtmlTag = this.comment
            ? (this.comment || '')?.replaceAll('<br>', '\n ')
            : (this.text || '')?.replaceAll('<br>', '\n ');

        const originText = isKeepHtmlTag
            ? originTextHasHtmlTag
            : elementMessage.innerText;
        return {
            name: originText?.length < 200 ? elementMessage.innerText : '',
            description: originText?.length >= 200 ? originText : '',
        };
    }

    _getFiles() {
        if (
            (!this.files || this.files?.length == 0) &&
            (!this.replyToMsg || this.replyToMsg?.files?.length == 0)
        )
            return [];

        return this._handleArrayFileCreateTask({
            ...(this.files || {}),
            ...(this.replyToMsg?.files || {}),
        });
    }

    async _handleArrayFileCreateTask(files) {
        let msg_files = [] as any;
        let f: any;
        for (f of Object.values(files)) {
            const blobData = await this._getBlobDataOfFile(f);
            if (!blobData) continue;
            msg_files = [
                ...msg_files,
                new File(
                    [blobData],
                    f?.name || f?.fileName || `tictop-chat-${Date.now()}.jpeg`,
                    {
                        type:
                            f?.type ||
                            f?.contentType ||
                            blobData.type ||
                            'image/jpeg',
                    }
                ),
            ];
        }
        return msg_files;
    }

    async _getBlobDataOfFile(file) {
        try {
            let blobData: Blob | false;
            const isFileObject = checkIsFileObject(file);
            if (isFileObject) {
                blobData = getBlobData(isFileObject);
            } else {
                const fileUrl =
                    file?.fileUrl ||
                    file?.imagePath ||
                    file?.url_full ||
                    file?.pathUrl;

                if (!fileUrl) throw false;
                const imageDataFromS3 = await getObjectDataByUrl(
                    fileUrl,
                    'blob'
                );
                blobData = imageDataFromS3.data;
            }
            return blobData;
        } catch (error) {
            openNotification({
                type: AlertType.warning,
                body: translate('CHAT_CANNOT_CREATE_NEW_TASK_WITH_THIS_FILE'),
                duration: 2000,
            });
            return null;
        }
    }
}
