<script setup lang="ts">
import { ICustomFilterPayload } from '@/domain/entities/task/FilterTask';
import { computed, onMounted, ref } from 'vue';
import { keySearch } from '@/ui/modules/task/table/task-list-state';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import FilterTaskByKey from '@/ui/modules/task/filter/FilterTaskByKey.vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    userId?: number;
    groupId?: number;
    domainId?: number;
    isOwnerList?: boolean;
    filterDefault?: any;
    filterByFieldList?: any;
    showFilterAllCheckboxCode?: string;
  }>(),
  {
    title: 'TASK_FILTER_GLOBALLY_LABEL',
    filterByFieldList: () => [
      'TASK_LIFE',
      'URGENCY',
      'TASK_TYPE',
      'CREATE_DATE',
      'UPDATED_DATE',
      'DEADLINE',
      'GROUPS',
      'DOMAIN',
      'CREATOR',
      'ASSIGNEE',
    ],
  }
);
const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onReset'): void;
  (e: 'onFilter', data: any): void;
  (e: 'update:filterCount', data: any): void;
}>();

const FILTER_DATA_BY_KEYS = {
  TASK_LIFE: {
    index: 1,
    key: 'TASK_LIFE',
    nameCode: 'COMMON_LABEL_STATUS',
    needPermissionByMode: false,
  },
  URGENCY: {
    index: 2,
    key: 'URGENCY',
    nameCode: 'TASK_TABLE_LABEL_URGENCY',
    needPermissionByMode: false,
  },
  TASK_TYPE: {
    index: 7,
    key: 'TASK_TYPE',
    nameCode: 'TASK_CREATE_FORM_LABEL_TASK_TYPE',
    needPermissionByMode: false,
  },
  CREATE_DATE: {
    index: 8,
    key: 'CREATE_DATE',
    nameCode: 'TICKET_TASK_FORM_LABEL_CREATE_DATE',
    needPermissionByMode: false,
  },
  UPDATED_DATE: {
    index: 9,
    key: 'UPDATED_DATE',
    nameCode: 'TICKET_TASK_FORM_LABEL_UPDATE_DATE',
    needPermissionByMode: false,
  },
  DEADLINE: {
    index: 4,
    key: 'DEADLINE',
    nameCode: 'TASK_TABLE_LABEL_DEADLINE',
    needPermissionByMode: false,
  },
  GROUPS: {
    index: 5,
    key: 'GROUPS',
    nameCode: 'COMMON_LABEL_GROUPS',
    needPermissionByMode: true,
  },
  DOMAIN: {
    index: 1,
    key: 'DOMAIN',
    nameCode: 'TASK_FILTER_LABEL_SCOPE',
    needPermissionByMode: true,
  },
  CREATOR: {
    index: 6,
    key: 'CREATOR',
    nameCode: 'TASK_TABLE_LABEL_CREATOR',
    needPermissionByMode: true,
  },
  ASSIGNEE: {
    index: 3,
    key: 'ASSIGNEE',
    nameCode: 'TASK_LABEL_ASSIGNEE',
    needPermissionByMode: true,
  },
};

const allFilterFields = computed<any>(() => {
  return arrayOrderBy(
    props.filterByFieldList?.map((key) => FILTER_DATA_BY_KEYS[key]),
    ['index'],
    ['asc']
  );
});

const filterData = ref<ICustomFilterPayload>({ ...props.filterDefault } || {});

const filterCount = computed(() => {
  const urgency: any[] = filterData.value?.urgency?.split(',') || [];
  const status: any[] = filterData.value?.status?.split(',') || [];
  const groupIds: any[] = filterData.value?.groupIds?.split(',') || [];
  const domainIds: any[] = filterData.value?.domainIds?.split(',') || [];
  const creatorIds: any[] = filterData.value?.creatorIds?.split(',') || [];
  const assigneeIds: any[] = filterData.value?.assigneeIds?.split(',') || [];

  return [
    ...urgency,
    ...status,
    ...(props.groupId ? [] : groupIds),
    ...(props.domainId ? [] : domainIds),
    ...creatorIds,
    ...assigneeIds,
    filterData.value?.createdFromDate,
    filterData.value?.createdToDate,
    filterData.value?.updatedFromDate,
    filterData.value?.updatedToDate,
    filterData.value?.deadlineFromDate,
    filterData.value?.deadlineToDate,
    filterData.value?.isRepeat,
    filterData.value?.isInWorkflow,
    filterData.value?.isApproval,
    filterData.value?.isNoSchedule,
  ].filter((val) => val).length;
});
const advancedFilterCount = computed(() => {
  let result = [] as any[];
  allFilterFields.value.slice(3).forEach((data) => {
    switch (data?.key) {
      case 'TASK_LIFE':
        result = [...result, filterData.value?.status];
        break;
      case 'URGENCY':
        result = [...result, filterData.value?.urgency];

        break;
      case 'TASK_TYPE':
        result = [
          ...result,
          filterData.value?.isRepeat,
          filterData.value?.isInWorkflow,
          filterData.value?.isApproval,
          filterData.value?.isSubTask,
        ];

        break;
      case 'CREATE_DATE':
        result = [
          ...result,
          filterData.value?.createdFromDate,
          filterData.value?.createdFromDate,
        ];

        break;
      case 'UPDATED_DATE':
        result = [
          ...result,
          filterData.value?.updatedFromDate,
          filterData.value?.updatedToDate,
        ];

        break;
      case 'DEADLINE':
        result = [
          ...result,
          filterData.value?.deadlineFromDate,
          filterData.value?.deadlineToDate,
          filterData.value?.isNoSchedule,
        ];

        break;
      case 'GROUPS':
        result = [...result, ...(filterData.value.groupIds?.split(',') || [])];
        break;
      case 'DOMAIN':
        result = [...result, ...(filterData.value.domainIds?.split(',') || [])];
        break;
      case 'CREATOR':
        result = [
          ...result,
          ...(filterData.value.creatorIds?.split(',') || []),
        ];
        break;
      case 'ASSIGNEE':
        result = [
          ...result,
          ...(filterData.value.assigneeIds?.split(',') || []),
        ];
        break;

      default:
        break;
    }
  });

  return result?.filter((data) => data)?.length;
});

onMounted(() => {
  emit('update:filterCount', filterCount.value);

  if (advancedFilterCount.value > 0) isShowFullSetup.value = true;
});

const onApply = () => {
  emit(
    'onFilter',
    props.isShowFilterAllCheckBox
      ? {
          ...filterData.value,
          isDefault: !isAllTasks.value,
        }
      : filterData.value
  );
  emit('update:filterCount', filterCount.value);
};

const onClose = () => {
  emit('onClose');
  emit('update:filterCount', filterCount.value);
};

const onReset = () => {
  clearData();

  emit('onReset');
  emit('update:filterCount', 0);
};
const clearData = () => {
  keySearch.value = '';
};

const editingKey = ref<string>('TASK_LIFE');

const updateFilterData = (key, data) => {
  filterData.value.isDefault = false;
  filterData.value.keyword = keySearch.value;
  switch (key) {
    case 'TASK_LIFE':
      filterData.value.status = data?.status;
      break;
    case 'URGENCY':
      filterData.value.urgency = data?.urgency;

      break;
    case 'TASK_TYPE':
      filterData.value.isRepeat = data?.isRepeat;
      filterData.value.isInWorkflow = data?.isInWorkflow;
      filterData.value.isApproval = data?.isApproval;
      filterData.value.isSubTask = data?.isSubTask;
      break;
    case 'CREATE_DATE':
      filterData.value.createdFromDate = data?.createdFromDate;
      filterData.value.createdToDate = data?.createdToDate;
      break;
    case 'UPDATED_DATE':
      filterData.value.updatedFromDate = data?.updatedFromDate;
      filterData.value.updatedToDate = data?.updatedToDate;
      break;
    case 'DEADLINE':
      filterData.value.deadlineFromDate = data?.deadlineFromDate;
      filterData.value.deadlineToDate = data?.deadlineToDate;
      filterData.value.isNoSchedule = data?.isNoSchedule;
      break;
    case 'GROUPS':
      filterData.value.groupIds = data?.groupIds;
      break;
    case 'DOMAIN':
      filterData.value.domainIds = data?.domainIds;
      break;
    case 'CREATOR':
      filterData.value.creatorIds = data?.creatorIds;
      break;
    case 'ASSIGNEE':
      filterData.value.assigneeIds = data?.assigneeIds;
      break;

    default:
      break;
  }
};

const isShowFullSetup = ref(false);

const isAllTasks = ref<boolean>(!filterData.value?.isDefault);

defineExpose({
  clearData,
});
</script>
<template>
  <div class="w-96 flex flex-col" style="max-height: calc(100vh - 15rem)">
    <div class="p-1 border-b flex items-center justify-between">
      <span class="w-8"></span>
      <span class="text-current-600 font-semibold">{{
        $t(isShowFilterAllCheckBox ? 'TASK_LABEL_ADVANCE_FILTERS' : title)
      }}</span>
      <button
        class="w-8 h-8 flex-center rounded-full hover:bg-gray-200 dropdown-item"
        @click="onClose"
      >
        <SynIcon name="Close" custom-class="w-5 h-5 fill-gray-500" />
      </button>
    </div>

    <div
      class="
        flex-1
        min-h-0
        flex flex-col
        overflow-y-auto
        small-scrollbar
        divide-y
      "
    >
      <div
        v-for="filter in allFilterFields.slice(0, 3)"
        :key="filter?.key"
        v-permission-model="{
          disabled: !filter.needPermissionByMode,
        }"
        class="flex flex-col p-2"
        :class="
          editingKey == filter?.key
            ? ''
            : 'bg-gray-100 hover:bg-gray-200 cursor-pointer'
        "
        @click="editingKey = filter?.key"
      >
        <div class="relative flex justify-between items-center w-full">
          <div class="font-semibold p-1 text-sm">
            {{ $t(filter.nameCode) }}
          </div>
          <SynIcon
            :name="'sort-down'"
            custom-class="h-2.5 w-2.5 fill-gray-500"
            :class="editingKey !== filter?.key ? '-rotate-90' : ''"
          />
        </div>
        <FilterTaskByKey
          :filter-key="filter?.key"
          :editing-key="editingKey"
          :user-id="userId"
          :group-id="groupId"
          :domain-id="domainId"
          :filter-default="filterData"
          @update-filter-data="updateFilterData"
        />
      </div>
      <template v-if="isShowFullSetup">
        <div
          v-for="filter in allFilterFields.slice(3)"
          :key="filter?.key"
          v-permission-model="{
            disabled: !filter.needPermissionByMode,
          }"
          class="flex flex-col p-2"
          :class="
            editingKey == filter?.key
              ? ''
              : 'bg-gray-100 hover:bg-gray-200 cursor-pointer'
          "
          @click="editingKey = filter?.key"
        >
          <div class="relative flex justify-between items-center w-full">
            <div class="font-semibold p-1 text-sm">
              {{ $t(filter.nameCode) }}
            </div>
            <SynIcon
              :name="'sort-down'"
              custom-class="h-2.5 w-2.5 fill-gray-500"
              :class="editingKey !== filter?.key ? '-rotate-90' : ''"
            />
          </div>
          <FilterTaskByKey
            :filter-key="filter?.key"
            :editing-key="editingKey"
            :user-id="userId"
            :group-id="groupId"
            :domain-id="domainId"
            :filter-default="filterData"
            @update-filter-data="updateFilterData"
          />
        </div>
      </template>

      <div
        class="w-full flex-center gap-2 px-3 py-2"
        :class="
          advancedFilterCount > 0
            ? 'text-current-500 fill-current'
            : 'fill-gray-500'
        "
        @click="isShowFullSetup = !isShowFullSetup"
      >
        <span class="cursor-pointer text-xs italic">
          {{
            $t(
              isShowFullSetup
                ? 'PLAN_LABEL_VIEW_LESS'
                : 'TASK_LABEL_ADVANCED_FILTER'
            )
          }}
          {{ advancedFilterCount > 0 ? `(${advancedFilterCount})` : '' }}
        </span>
      </div>
    </div>

    <div
      class="px-3 py-2 border-b border-gray-50 text-right flex"
      :class="showFilterAllCheckboxCode ? 'justify-between' : 'justify-end'"
    >
      <div v-if="showFilterAllCheckboxCode" class="flex-center gap-2">
        <AtomCheckbox
          v-model="isAllTasks"
          :label="$t('TASK_LABEL_FILTER_ALL')"
        />
        <AtomHelpDropdown title="TASK_LABEL_FILTER_ALL_TITLE">
          <template #content>
            <div class="pl-4" v-html="$t(showFilterAllCheckboxCode)"></div>
          </template>
        </AtomHelpDropdown>
      </div>
      <div class="flex-center">
        <AtomButton
          class="
            px-2
            py-1
            rounded
            bg-white
            hover:bg-current-50
            border border-current-50
            text-current-500 text-sm
            mr-2
            dropdown-item
          "
          type="TEXT"
          size="lg"
          @click="onReset"
        >
          {{ $t('COMMON_LABEL_RESET') || 'Reset' }}
        </AtomButton>
        <AtomButton
          class="
            px-2
            py-1
            rounded
            bg-current-500
            hover:bg-current-600
            text-white text-sm
            dropdown-item
            gap-2
          "
          size="lg"
          @click="onApply"
        >
          {{ $t('CALL_LABEL_APPLY') || 'Apply' }}
        </AtomButton>
      </div>
    </div>
  </div>
</template>
