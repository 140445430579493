<script setup lang="ts">
import { computed, ref } from 'vue';
import { truncateLinkFile } from '@/ui/hooks/commonFunction';
import { NoteAction } from '@/ui/modules/task/ui-enums/tasklog-enums';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import FileOnlyViewer from '@/ui/modules/task/components/activity-tasklog/FileOnlyViewer.vue';
import ModalFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalFileViewer.vue';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});

const fileShowViewer = ref();

const onClickFile = (file) => {
  fileShowViewer.value = file;
};

const afterActivity = computed(() => props.afterUpdate);
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 items-center justify-between pr-2">
      <div class="flex flex-col items-start text-xs space-y-1">
        <div
          v-if="afterActivity?.NoteAction === NoteAction.Delete"
          class="flex space-x-1 bg-white rounded"
        >
          <div class="px-1 py-1">
            <syn-icon
              name="noter"
              is-active
              custom-class="h-4 w-4 fill-orange-500"
            />
          </div>
          <span
            v-if="afterActivity?.NoteAction === NoteAction.Delete"
            :title="afterActivity?.NoteImage"
            class="text-gray-600 px-2 py-1"
          >
            {{ truncateLinkFile(afterActivity?.NoteId + '.jpeg', 25) }}
          </span>
          <!--          <a-->
          <!--            v-else-->
          <!--            :href="afterActivity?.NoteImage"-->
          <!--            :title="afterActivity?.NoteImage"-->
          <!--            class="text-blue-700 hover:text-blue-500 px-2 py-1 min-w-max"-->
          <!--            target="_blank"-->
          <!--            >{{ truncateLinkFile(afterActivity?.NoteImage, 30) }}</a-->
          <!--          >-->
        </div>

        <FileOnlyViewer
          v-else
          :content-type="afterActivity?.ContentType"
          :path="afterActivity?.NoteImage"
          :title="file?.NoteImage"
          :is-note="true"
          @on-select="onClickFile(afterActivity)"
        />
      </div>
      <span v-if="afterActivity?.NoteAction === NoteAction.Delete">→</span>
    </div>

    <div class="flex w-1/2 justify-start pl-4 text-xs text-gray-500">
      <span v-if="afterActivity?.NoteAction === NoteAction.Delete">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </div>
  </div>
  <ModalFileViewer
    v-if="fileShowViewer"
    is-file-in-task
    :file="fileShowViewer"
    :path="fileShowViewer?.NoteImage"
    :content-type="fileShowViewer?.ContentType"
    @on-close="fileShowViewer = null"
  >
  </ModalFileViewer>
</template>
