<script setup lang="ts">
import GedPage from '@/ui/modules/ged/ged-page/GedPage.vue';
import { onMounted, computed, ref, watch } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import $constants from '@/ui/plugins/constants';
import fileStorageStore from '@/store/fileStorage';

const _fileStorageStore = fileStorageStore();

const props = defineProps({
  idResoucer: {
    type: Object,
    default: () => {},
  },
});

defineEmits(['close']);

watch(
  () => props.idResoucer,
  () => {
    modalGedRef.value?.setFolderFromUrl(props.idResoucer.folderId);
    if (props.idResoucer?.notification) {
      _fileStorageStore.setListFileAddNew(
        props.idResoucer?.notification?.mainData?.gedDetailItems
      );
    }
  }
);

onMounted(() => {
  modalGedRef.value?.setFolderFromUrl(props.idResoucer.folderId);
  if (props.idResoucer?.notification) {
    _fileStorageStore.setListFileAddNew(
      props.idResoucer?.notification?.mainData?.gedDetailItems
    );
  }
});

const modalGedRef = ref();
const resourceFixedMember = computed(() => ({
  id: props.idResoucer?.id || getCurrentUserId(),
  isGroup: props.idResoucer?.isGroup,
}));
const resourceDefaultCategory = computed(
  () => $constants.FILE.CATEGORY.STORAGE
);
</script>
<template>
  <syn-modal
    style-body="py-3"
    container-class="w-2/3 h-full"
    container-style="min-width: 40rem; max-width: 1200px; min-height: 40rem;"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    disable-click-outside
    @cancel="$emit('close')"
  >
    <template #body>
      <GedPage
        v-if="idResoucer"
        ref="modalGedRef"
        :default-category="resourceDefaultCategory"
        is-modal
        :fixed-member="resourceFixedMember"
      />
    </template>
  </syn-modal>
</template>
