<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="m12 19.25c-3.911 0-7.822-1.931-11.705-5.791-.387-.384-.394-1.015-.015-1.408.378-.393.998-.4 1.385-.016 6.955 6.913 13.716 6.913 20.671 0 .386-.386 1.006-.378 1.385.016.378.393.372 1.024-.015 1.408-3.884 3.86-7.795 5.791-11.706 5.791z"
    />
    <path
      d="m12 22c-.552 0-1-.424-1-.947v-2.605c0-.523.448-.947 1-.947s1 .424 1 .947v2.605c0 .523-.448.947-1 .947z"
    />
    <path
      d="m22.948 18.25c-.301 0-.6-.132-.809-.389l-1.895-2.335c-.372-.459-.312-1.142.134-1.525.446-.382 1.11-.322 1.483.138l1.895 2.335c.372.459.312 1.142-.134 1.525-.197.169-.436.251-.674.251z"
    />
    <path
      d="m3.741 13.885c.34.41.35 1.039 0 1.458l-.53.639-1.371 1.638c-.2.25-.5.38-.79.38-.24 0-.49-.1-.69-.28-.44-.41-.48-1.109-.1-1.568l1.28-1.528.62-.749c.32-.39.84-.479 1.26-.25z"
    />
    <path
      d="m18.126 21c-.358 0-.704-.199-.884-.549l-1.25-2.43c-.259-.504-.075-1.131.413-1.4.487-.269 1.092-.077 1.353.427l1.25 2.43c.259.504.075 1.131-.413 1.4-.15.083-.311.122-.469.122z"
    />
    <path
      d="m5.874 21c-.158 0-.319-.039-.469-.121-.488-.269-.672-.895-.413-1.4l1.25-2.43c.259-.504.865-.696 1.353-.427s.672.895.413 1.4l-1.25 2.43c-.18.349-.526.548-.884.548z"
    />
  </svg>
</template>
