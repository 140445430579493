<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:se="http://svg-edit.googlecode.com"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    viewBox="0 0 427 365"
    width="427"
    height="365"
    style=""
  >
    <rect
      id="backgroundrect"
      width="100%"
      height="100%"
      x="0"
      y="0"
      fill="none"
      stroke="none"
      class=""
      style=""
    />
    <g style="" class="currentLayer">
      <path
        id="svg_5"
        fill="#000000"
        fill-opacity="1"
        stroke="none"
        stroke-opacity="1"
        stroke-width="20"
        stroke-dasharray="none"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-dashoffset=""
        fill-rule="nonzero"
        opacity="1"
        marker-start=""
        marker-mid=""
        marker-end=""
        d="M426.45956511296987,347.51649114893746 "
        class=""
      />
      <path
        id="svg_1"
        fill="none"
        fill-opacity="1"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="30"
        stroke-dasharray="none"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-dashoffset=""
        fill-rule="nonzero"
        opacity="1"
        marker-start=""
        marker-mid=""
        marker-end=""
        d="M198.3626470565796,242.85714197158813 C198.3626470565796,183.74111987214062 246.2466249571321,135.85714197158813 305.3626470565796,135.85714197158813 C364.4786691560271,135.85714197158813 412.3626470565796,183.74111987214062 412.3626470565796,242.85714197158813 C412.3626470565796,301.9731640710356 364.4786691560271,349.85714197158813 305.3626470565796,349.85714197158813 C246.2466249571321,349.85714197158813 198.3626470565796,301.9731640710356 198.3626470565796,242.85714197158813 z"
        class=""
      />
      <path
        id="svg_7"
        fill="none"
        fill-opacity="1"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="30"
        stroke-dasharray="none"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-dashoffset=""
        fill-rule="nonzero"
        opacity="1"
        marker-start=""
        marker-mid=""
        marker-end=""
        d="M182.32419653409505,306.78901987494027 L14.99999980546616,306.7582416475474 L15.549450354916708,14.999999889305663 L158.9560437615101,14.999999889305663 L207.85714266260902,67.74725263655841 L380.93406573953206,67.74725263655841 L380.93406573953206,119.9450548343606 "
        class=""
      />
      <path
        id="svg_2"
        fill="none"
        fill-opacity="1"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="30"
        stroke-dasharray="none"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-dashoffset=""
        fill-rule="nonzero"
        opacity="1"
        marker-start=""
        marker-mid=""
        marker-end=""
        d="M306.7582432094613,182.37363894552115 L306.7582432094613,302.7033136888577 "
        class=""
      />
      <path
        id="svg_4"
        fill="none"
        fill-opacity="1"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="30"
        stroke-dasharray="none"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-dashoffset=""
        fill-rule="nonzero"
        opacity="1"
        marker-start=""
        marker-mid=""
        marker-end=""
        d="M306.7582432094613,182.37363894552115 L306.7582432094613,302.7033136888577 "
        class=""
        transform="rotate(90.17904663085938 306.75823974609375,242.53848266601565) "
      />
    </g>
  </svg>
</template>
