import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import HomeService from '@/application/services/HomeService';
import {
    CapacityMember,
    TDayoffStatusByDate,
    TTaskActivityChart,
    TWorkingMember,
} from '@/store/dashboard/overview-monitor-store';
import { getAllMemberStartedToday } from '@/ui/modules/dashboard/helpers/remaining-workforce-helper';
export type TDepartmentOverview = {
    id: number;
    avatar: string;
    avatarUrl: string;
    name: string;
    description: string;
    activeTasks: number;
    finishedTasks: number;
    onlineCount: number;
    pauseCount: number;
    offlineCount: number;
    members: {
        departmentId: number;
        userId: number;
        firstName: string;
        lastName: string;
        avatar: string;
        avatarUrl: string;
    }[];
};

export default defineStore({
    id: storeId.DASHBOARD_MONITORING_DEPARTMENT,
    state: () => ({
        isLoadingWorkingStatus: true,
        timeCapacity: {} as {
            members: CapacityMember[];
        },
        workingMember: {} as TWorkingMember,
        task: {} as {
            totalTaskOnDay: number;
            totalFinished: number;
            totalCreated: number;
        },
        analyze: {} as {
            score: number;
            label: string;
        },
        taskActivityChart: [] as TTaskActivityChart[],
        dayoffStatusByDate: {} as TDayoffStatusByDate,
        departmentsOverview: [] as TDepartmentOverview[],
    }),
    getters: {},
    actions: {
        async getAllMonitorData(departmentId: number) {
            try {
                if (!departmentId) {
                    this.isLoadingWorkingStatus = false;

                    return;
                }
                this.isLoadingWorkingStatus = true;

                await Promise.all([
                    this.getWorkingStatus(departmentId),
                    this.getTaskActivityChart(departmentId),
                    this.getDayoffStatusByDate('', departmentId),
                    this.getDepartmentsOverview(departmentId),
                    this.calculateScorePerformanceOnDay(departmentId),
                ]);
            } finally {
                this.isLoadingWorkingStatus = false;
            }
        },
        async getWorkingStatus(departmentId: number) {
            const res = await HomeService.getInstance().getWorkingStatus(
                departmentId
            );
            const result = res?.result;
            this.timeCapacity.members = getAllMemberStartedToday(
                result?.timeCapacity?.members
            );
            this.workingMember = result?.workingMember;
            this.task = result?.task;
        },
        async calculateScorePerformanceOnDay(departmentId: number) {
            const res =
                await HomeService.getInstance().calculateScorePerformanceOnDay(
                    departmentId
                );
            this.analyze = res?.result;
        },
        async getTaskActivityChart(departmentId: number) {
            const res = await HomeService.getInstance().getTaskActivityChart(
                departmentId
            );
            this.taskActivityChart = res?.result;
        },
        async getDayoffStatusByDate(date, departmentId: number) {
            const res = await HomeService.getInstance().getDayoffStatusByDate(
                date,
                departmentId
            );
            this.dayoffStatusByDate = res?.result;
        },
        async getDepartmentsOverview(departmentId: number) {
            const res = await HomeService.getInstance().getDepartmentsOverview(
                departmentId
            );
            this.departmentsOverview = res?.result;
        },
        async getDepartmentsOverviewById(departmentId: number) {
            return HomeService.getInstance().getDepartmentsOverviewById(
                departmentId
            );
        },
    },
});
