import DomainRepository from '@/application/repositories/DomainRepository';
import ProjectRepository from '@/application/repositories/ProjectRepository';
// import { groupBy } from 'lodash';
// import DomainPresentClass from '@/domain/entities/PresentClass/DomainPresentClass';
// import ProjectPresentClass from '@/domain/entities/PresentClass/ProjectPresentClass';
// import groupStore from '@/store/group';
// import { cloneDeep } from '@/ui/hooks/commonFunction';
import {
    DomainAddMemberPayload,
    DomainAddOrUpdatePayload,
    DomainAddPayload,
    ProjectAddMemberPayload,
    ProjectAddOrUpdatePayload,
    ProjectAddPayload,
    UpdateDomainInformationPayload,
    UpdateProjectInformationPayload,
    UpdateUserDomainRelativePayload,
} from '@/application/types/domain/DomainPayload';
import {
    RejectDomainPayload,
    RejectProjectPayload,
} from '@/domain/entities/projects/ProjectPayload';
import DomainDetailClass from '@/domain/entities/domains/DomainDetailClass';
import ProjectDetailClass from '@/domain/entities/domains/ProjectDetailClass';

export default class DomainService {
    private static instance: DomainService;
    _domainRepository: DomainRepository;
    _projectRepository: ProjectRepository;

    constructor() {
        this._domainRepository = DomainRepository.getInstance();
        this._projectRepository = ProjectRepository.getInstance();
    }

    public static getInstance(): DomainService {
        if (!DomainService.instance) {
            // Get from local storage
            DomainService.instance = new DomainService();
        }

        return DomainService.instance;
    }

    async removeItem(type: string, id: number): Promise<void> {
        if (type === 'domain') {
            await this._domainRepository.deleteDomain(id);
        } else {
            await this._projectRepository.delete(id);
        }
    }

    deleteDomain(id: number): Promise<any> {
        return this._domainRepository.deleteDomain(id);
    }

    async fetchAllDomains(): Promise<DomainDetailClass[]> {
        const res: any = await this._domainRepository.getAll();
        const domainResult = res?.result;

        if (!domainResult) return [];
        const domainUsers = domainResult.domainUsers;
        const groupDomains = domainResult.domainGroups;
        const domainProjects = domainResult.domainProjects;

        const projectByDomainIds = domainProjects.reduce((oldObj, newObj) => {
            return {
                ...oldObj,
                [newObj.domainId]: newObj.projects,
            };
        }, {});

        return domainResult.domains.map((e) => {
            return new DomainDetailClass({
                ...e,
                type: 'domain',
                members: [
                    ...domainUsers
                        .filter((u) => u.domainId === e.id)
                        .map((u) => {
                            return {
                                id: u.userId,
                                anyProjects: u.anyProjects,
                            };
                        }),
                ].sort(),
                anyProjectMembers: [
                    ...domainUsers
                        .filter(
                            (domain) =>
                                domain.domainId === e.id && domain?.anyProjects
                        )
                        .map((u) => u.userId),
                ].sort(),
                groups: [
                    ...groupDomains
                        .filter((g) => g.domainId === e.id)
                        .map((g) => g.groupId),
                ],
                projects: projectByDomainIds[e.id]?.split(',')?.sort(),
            });
        });
    }

    async fetchAllProjects(): Promise<ProjectDetailClass[]> {
        const res: any = await this._projectRepository.getAll();
        const domainResult = res?.result;

        if (!domainResult) return [];

        const allProjects = domainResult.projects;
        const projectUsers = domainResult.projectUsers;
        const userByProjectIds = projectUsers.reduce((oldObj, newObj) => {
            return {
                ...oldObj,
                [newObj.projectId]: newObj?.users,
            };
        }, {});
        const subProjects = domainResult.subProjects;
        const subProjectsParentIds = subProjects.reduce((oldObj, newObj) => {
            return {
                ...oldObj,
                [newObj.parentId]: newObj.projects,
            };
        }, {});

        return allProjects.map((e) => {
            return new ProjectDetailClass({
                ...e,
                type: 'domain',
                members: userByProjectIds[e.id]?.split(',')?.sort(),
                subProjects: subProjectsParentIds[e.id]?.split(',')?.sort(),
            });
        });
    }

    createDomain(domain: DomainAddPayload): Promise<any> {
        return this._domainRepository.createDomain(domain);
    }

    updateInformation(domain: UpdateDomainInformationPayload): Promise<any> {
        return this._domainRepository.updateInformation(domain);
    }

    quicklyCreateDomain(domain: DomainAddOrUpdatePayload): Promise<any> {
        return this._domainRepository.quicklyCreateDomain(domain);
    }

    quicklyCreateProject(domain: ProjectAddOrUpdatePayload): Promise<any> {
        return this._domainRepository.quicklyCreateProject(domain);
    }

    createProject(project: ProjectAddPayload): Promise<any> {
        return this._projectRepository.createProject(project);
    }

    updateProjectInformation(
        project: UpdateProjectInformationPayload
    ): Promise<any> {
        return this._projectRepository.updateProjectInformation(project);
    }
    updateProjectMembers(domainId, projectId, data): Promise<any> {
        return this._projectRepository.updateProjectMembers(
            domainId,
            projectId,
            data
        );
    }

    addMembers(data: DomainAddMemberPayload): Promise<any> {
        return this._domainRepository.addMembers(data);
    }

    addProjectMembers(data: ProjectAddMemberPayload): Promise<any> {
        return this._domainRepository.addMembers(data);
    }
    updateMembers(domainId: number, data: any): Promise<any> {
        return this._domainRepository.updateMembers(domainId, data);
    }
    updateGroups(domainId: number, data: any): Promise<any> {
        return this._domainRepository.updateGroups(domainId, data);
    }

    updateUserDomainRelative(
        data: UpdateUserDomainRelativePayload
    ): Promise<any> {
        return this._domainRepository.updateUserDomainRelative(data);
    }

    removeMember(data: any): Promise<any> {
        return this._domainRepository.removeMember(data);
    }

    getDomainScopeListByUserId(userId): Promise<any> {
        return this._domainRepository.getDomainScopeListByUserId(userId);
    }

    rejectDomain(data: RejectDomainPayload): Promise<any> {
        return this._domainRepository.rejectDomain(data);
    }

    rejectProject(data: RejectProjectPayload): Promise<any> {
        return this._projectRepository.rejectProject(data);
    }

    getOverviewDomainTasks() {
        return this._domainRepository.getOverviewDomainTasks();
    }

    getOverviewDomainFullDetail() {
        return this._domainRepository.getOverviewDomainFullDetail();
    }

    getDomainTodayStatistic(domainId) {
        return this._domainRepository.getDomainTodayStatistic(domainId);
    }

    getDomainTaskCreateFinishStatistic(domainId, fromDate, toDate) {
        return this._domainRepository.getDomainTaskCreateFinishStatistic(
            domainId,
            fromDate,
            toDate
        );
    }

    getAllDomainTaskOverviews(domainId) {
        return this._domainRepository.getAllDomainTaskOverviews(domainId);
    }

    getDomainTasksMemberActive(domainId) {
        return this._domainRepository.getDomainTasksMemberActive(domainId);
    }

    getDomainFullDetail(domainId) {
        return this._domainRepository.getDomainFullDetail(domainId);
    }
    setVisibleDomain(domainId) {
        return this._domainRepository.setVisibleDomain(domainId);
    }
    updateDomainSupplement(data) {
        return this._domainRepository.updateDomainSupplement(data);
    }
    getCountTasksByDomain(isIncludeSub) {
        return this._domainRepository.getCountTasksByDomain(isIncludeSub);
    }
}
