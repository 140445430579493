export const OrderPlanStatus = {
    InitTransaction: -1,
    SuccessfulTransaction: 11,
    TransactionIsNotCompleted: 22,
    ErrorTransaction: 33,
    HandlingTransactions: 44,
};

export const PaymentTransactionStatus = {
    Success: '00',
    Failed: '02',
};

export const VnpPaymentResponseCode = {
    Success: '00',
    CancelledByCustomer: '24',
};

export const PaymentStatusText = {
    Paid: 'PAID',
    Failed: 'FAILED',
    Cancelled: 'CANCELLED',
    Handling: 'HANDLING',
};

export const PaymentRequestTokenStatus = {
    Initial: 'INITIAL',
    Handling: 'HANDLING',
    Success: 'SUCCESS',
    Failed: 'FAILED',
    Cancelled: 'CANCELLED',
};

export const PaymentTransactionLogStatus = {
    InProcessing: 'IN_PROCESSING',
    Complete: 'COMPLETE',
};

export const NextBillingStatus = {
    NeedExtend: 1,
    Expired: 2,
};
