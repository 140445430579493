<script setup lang="ts">
// *** IMPORTS ***
import { computed, ref } from 'vue';
import ChatApiRepository from '@/application/repositories/chat/ChatApiRepository';
import ChatMessage from '@/ui/modules/messaging/chat-panel/chat-message/ChatMessage.vue';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import userStore from '@/store/user';
import ModalMessageForward from '@/ui/modules/messaging/chat-panel/modal-message-forward/ModalMessageForward.vue';
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import VigProgress from '@/ui/common/atoms/VigProgress/VigProgress.vue';
import VigLoading from '@/ui/common/atoms/VigLoading/VigLoading.vue';
import SynLabelDateTime2 from '@/ui/common/molecules/SynDateTime/SynLabelDateTime2.vue';
import useChatMembers from '@/ui/composables/chat/chat-members-composables';

// *** PROPS, EMITS ***
const props = defineProps<{
  organizationId: number;
  conversationId: string;
  conversation: ChatConversationModel;
  members: any;
}>();

defineEmits([
  'onClose',
  'onGoToMessage',
  'onMessageReply',
  'onMessageViewFile',
  'onMessageTaskClick',
]);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const searchText = ref<string>();
const isSearching = ref<boolean>();
const isSearched = ref<boolean>();
const foundMessages = ref<any[]>();
const totalFound = ref<number>();
const forwardingMessage = ref<ChatMessageModel | null>();

// *** COMPUTED ***
const allUserByIds = computed(() => userStore().allUserByIds);
const canLoadMore = computed(
  () =>
    foundMessages.value?.length &&
    totalFound.value &&
    foundMessages.value.length < totalFound.value
);

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onSearch = () => {
  foundMessages.value = [];
  totalFound.value = 0;
  isSearched.value = false;

  if (!searchText.value?.trim()?.length || searchText.value.trim().length < 2) {
    return;
  }

  _searchMessages();
};

const onSearchMore = () => {
  if (canLoadMore.value) _searchMessages(false);
};

// *** PRIVATE FUNCTIONS ***
const _searchMessages = async (refresh = true) => {
  if (!props.conversationId) return;

  isSearching.value = true;

  try {
    const filter = {
      organizationId: `${props.organizationId}`,
      lastDeletedDate: props.conversation?.lastDeletedDate?.toDate
        ? props.conversation?.lastDeletedDate?.toDate()
        : props.conversation?.lastDeletedDate,
      keyword: searchText.value?.trim(),
      pageIndex: refresh ? 0 : foundMessages.value?.length,
      pageSize: 20,
    };

    const res = await ChatApiRepository.getInstance().getConversationMessages(
      props.conversationId,
      filter
    );

    foundMessages.value = (refresh ? [] : foundMessages.value)?.concat(
      res?.result?.messages
    );

    totalFound.value = res?.result?.total;
  } catch (e) {
    console.log(e);
  }

  isSearching.value = false;
  isSearched.value = true;
};

// *** EXPOSES ***
</script>

<template>
  <div class="h-full flex flex-col">
    <div class="">
      <div class="px-3 py-2 flex items-center gap-2 bg-current-100">
        <VigButton
          light
          rounded="rounded-full"
          padding="p-1.5"
          @click="$emit('onClose')"
        >
          <SynIcon name="Close" custom-class="w-5 h-5" />
        </VigButton>
        <div class="flex-1">
          <VigSearchBox
            v-model="searchText"
            autofocus
            :placeholder="$t('CHAT_SEARCH_IN_CONVER')"
            @on-search="onSearch"
          />
        </div>
      </div>
      <div
        v-if="isSearched"
        class="px-3 py-1 text-gray-500"
        :class="{ 'border-b': totalFound }"
      >
        <span
          v-if="totalFound"
          v-html="$t('CHAT_SEARCH_NUMBER_MESSAGE', { number: totalFound })"
        ></span>
        <template v-else>{{ $t('CHAT_SEARCH_NO_MESSAGE') }}</template>
      </div>
    </div>
    <div
      v-scroll-infinite-2="{
        onReachedBottom: onSearchMore,
      }"
      class="flex-1 overflow-auto small-scrollbar relative"
    >
      <VigLoading v-if="isSearching" />

      <div
        v-for="message in foundMessages"
        :key="message?.id"
        role="button"
        class="hover:bg-gray-50 border-b border-gray-100 overflow-hidden"
        @click="$emit('onGoToMessage', message?.id)"
      >
        <div class="flex justify-end text-xs text-gray-500 py-0.5 px-1">
          <SynLabelDateTime2
            :datetime="message?.createdDate"
            format="date_time"
          />
        </div>

        <ChatMessage
          :organization-id="organizationId"
          :conversation-id="conversationId"
          :conversation="conversation"
          :message="message"
          :message-id="message?.id"
          :members="members"
          is-show-pinned-only
          is-first-in-group
          is-last-in-group
          is-disabled
          @on-message-forward="forwardingMessage = message"
          @on-reply-click="$emit('onMessageReply', message)"
          @on-file-click="$emit('onMessageViewFile', $event)"
          @on-task-click="$emit('onMessageTaskClick', $event)"
          @on-message-create-task="$emit('onClose')"
        />
      </div>
    </div>
  </div>

  <ModalMessageForward
    v-if="forwardingMessage"
    :message="forwardingMessage"
    @on-close="forwardingMessage = null"
  />
</template>

<style scoped></style>
