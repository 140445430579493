<script setup lang="ts">
import { ref } from 'vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import SynLinkDownload from '@/ui/common/atoms/SynLinkDownload.vue';
import HomeHelpTab from '@/ui/modules/contact-help/helps/home-tab/HomeHelpTab.vue';
import FeatureQuestionAnswerTab from '@/ui/modules/contact-help/helps/fqa-tab/FeatureQuestionAnswerTab.vue';
import VideoHelpTab from '@/ui/modules/contact-help/helps/video-tab/VideoHelpTab.vue';
import VideoPlayerDraggable from '@/ui/modules/contact-help/helps/VideoPlayerDraggable.vue';
import helpComposables from '@/ui/composables/help-contact/help-composables';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';

import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import ChatSupportButton from '@/ui/modules/messaging/chat-support/chat-support-button/ChatSupportButton.vue';

const sizePanel = ref({
  SMALL: {
    key: 'SMALL',
    label: 'COMMON_LABEL_SMALL_VIEW',
    withIcon: 'w-3',
    width: 24,
    size: 'w-48',
    cols: 2,
    slidesPerView: 2,
    active: true,
  },
  MEDIUM: {
    key: 'MEDIUM',
    label: 'COMMON_LABEL_MEDIUM_VIEW',
    withIcon: 'w-4',
    width: 45,
    size: 'w-40 h-40',
    cols: 4,
    slidesPerView: 4,
    active: false,
  },
  LARGE: {
    key: 'LARGE',
    label: 'COMMON_LABEL_LARGE_VIEW',
    withIcon: 'w-6',
    width: 70,
    size: 'w-56 h-48',
    cols: 5,
    slidesPerView: 5,
    active: false,
  },
});
const tabsHelp = ref({
  HOME: {
    key: 'HOME',
    label: 'HELP_HOME_LABEL',
    isActive: true,
  },
  VIDEO: {
    key: 'VIDEO',
    label: 'HELP_VIDEO_LABEL',
    isActive: false,
  },
  FQA: {
    key: 'FQA',
    label: 'HELP_VIDEO_FQA',
    isActive: false,
  },
});

const sizeActive = ref(sizePanel.value.SMALL);
const tabActive = ref(tabsHelp.value.HOME);
const supportItemRef = ref(null);

const { isShowHelpPanel, videoActive, onExpandVideo, onClosePanel, onClose } =
  helpComposables();

const onChangeTabActive = (tab) => {
  tabsHelp.value[tab.key].isActive = true;
  tabActive.value = tab;
  Object.values(tabsHelp.value).forEach((el) => {
    el.isActive = el?.key === tab?.key;
  });
};

const onChangeSize = (size) => {
  sizePanel.value[size.key].active = true;
  sizeActive.value = size;
  Object.values(sizePanel.value).forEach((el) => {
    el.active = el?.key === size.key;
  });
};

const homeHelpTabRef = ref(null as any);
const isShowAlertChatSupport = ref(false);

const elementId = 'SHOW_HELP_PANEL';
</script>

<template>
  <div
    v-if="isShowHelpPanel"
    :id="elementId"
    ref="helpPanelRef"
    v-element-active="{ id: elementId, isActive: isShowHelpPanel }"
    class="
      absolute
      top-0
      right-0
      flex flex-col
      h-full
      z-60
      transition
      duration-300
      transform
      shadow-2xl shadow-current-100
    "
    :class="
      isShowHelpPanel ? 'translate-x-0 ease-out' : 'translate-x-full ease-in'
    "
    :style="`width: ${sizeActive.width}%;`"
    style="max-width: 100%; min-width: 22rem"
  >
    <!-- HEADER   -->
    <section
      class="
        flex flex-col
        bg-current
        shadow
        text-white
        pb-3
        pt-3
        px-3
        sticky
        top-0
        z-40
      "
    >
      <!--  TITLE    -->
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-1">
          <span class="text-xl pr-2">{{ $t('SUPPORT_LABEL_TEXT_TITLE') }}</span>
          <template v-for="size in Object.values(sizePanel)" :key="size">
            <span
              v-vig-tooltip="$t(size.label)"
              class="h-5 cursor-pointer"
              :class="`${size.withIcon} ${
                size.active
                  ? 'bg-current-50 hover:bg-current-500'
                  : 'border border-current-100 hover:bg-current-200 hover:bg-opacity-50'
              }`"
              @click="onChangeSize(size)"
            ></span>
          </template>
        </div>
        <div
          class="
            flex-center
            w-8
            h-8
            rounded
            hover:bg-gray-50 hover:bg-opacity-50
            cursor-pointer
          "
          @click="onClosePanel"
        >
          <syn-icon name="close" class="fill-white" />
        </div>
      </div>
      <!--  END TITLE    -->

      <!--  TAB MENU    -->
      <!--      <div class="flex items-center space-x-2 py-2">-->
      <!--        <div-->
      <!--            v-for="tab in Object.values(tabsHelp)"-->
      <!--            :key="tab"-->
      <!--            class="px-1.5 py-1 rounded-full flex-center text-xs cursor-pointer"-->
      <!--            :class="-->
      <!--            tab?.isActive-->
      <!--              ? 'bg-current hover:bg-current-500 text-white'-->
      <!--              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'-->
      <!--          "-->
      <!--            @click="onChangeTabActive(tab)"-->
      <!--        >-->
      <!--          {{ $t(tab.label) }}-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- END TAB MENU    -->
    </section>
    <!-- END HEADER   -->

    <div class="bg-white w-full pb-3 h-full overflow-y-auto small-scrollbar">
      <section v-if="tabActive?.key === tabsHelp?.HOME?.key" class="py-3">
        <HomeHelpTab
          ref="homeHelpTabRef"
          :size-active="sizeActive"
          @on-change-tab="(value) => onChangeTabActive(value)"
        />
      </section>

      <section v-if="tabActive?.key === tabsHelp?.FQA?.key" class="py-3">
        <FeatureQuestionAnswerTab
          :size-active="sizeActive"
          @on-back="onChangeTabActive(tabsHelp?.HOME)"
        />
      </section>

      <section v-if="tabActive?.key === tabsHelp?.VIDEO?.key" class="px-3">
        <VideoHelpTab :size-active="sizeActive" />
      </section>
    </div>

    <div class="absolute bottom-2 right-2 z-60 flex-center gap-2">
      <ChatSupportButton
        class="
          w-12
          h-12
          shadow-xl shadow-current-100
          flex-center
          bg-current-400
          rounded-full
          fill-white
          text-white
          cursor-pointer
          z-60
        "
        @on-chat-support-click="onClosePanel"
      >
        <syn-icon name="ChatSupport" custom-class="w-7 h-7" />
      </ChatSupportButton>

      <VigDropdown ref="supportItemRef" :z-index="60">
        <template #dropdown-toggle>
          <div
            class="
              w-12
              h-12
              shadow-xl shadow-current-100
              flex-center
              bg-current-400
              rounded-full
              fill-white
              text-white
              cursor-pointer
              z-60
            "
          >
            <syn-icon name="CustomerSupport" custom-class="w-7 h-7" />
          </div>
        </template>
        <template #dropdown-menu>
          <div
            class="flex flex-col rounded text-sm z-50 mx-4"
            style="width: 16rem"
          >
            <div
              class="flex items-center space-x-2 py-2 border-b border-gray-100"
            >
              <div
                class="
                  flex
                  items-center
                  space-x-2
                  text-gray-500
                  fill-current-350
                "
              >
                <syn-icon name="support" custom-class="w-3.5 h-3.5" />
                <span>Hotline:</span>
              </div>
              <span class="font-medium text-current-500"
                >(024) 99 99 96 98</span
              >
            </div>
            <div
              class="flex items-center space-x-2 py-2 border-b border-gray-100"
            >
              <div
                class="
                  flex
                  items-center
                  space-x-2
                  text-gray-500
                  fill-current-350
                "
              >
                <syn-icon name="email" custom-class="w-3.5 h-3.5" />
                <span>Email:</span>
              </div>
              <a
                class="text-current-500 italic"
                :href="`mailto:services@tictop.app?subject=${
                  $t('COMMON_LABEL_REQUEST_FOR_HELP') || 'Request for help'
                }`"
                >services@tictop.app</a
              >
            </div>
          </div>
        </template>
      </VigDropdown>
    </div>

    <!-- DOWNLOAD APP     -->
    <div
      class="
        flex
        items-center
        py-2
        px-3
        space-x-1
        sticky
        bg-gray-100
        shadow-md
        bottom-0
        z-40
        border-t border-gray-100
        w-full
      "
    >
      <a
        href="https://apps.apple.com/vn/app/tictop/id1582398478"
        target="_blank"
      >
        <div
          v-vig-tooltip="$t('COMMON_LABEL_GO_TO_APPSTORE')"
          class="
            w-6
            h-6
            flex-center
            rounded
            fill-current-350
            hover:bg-gray-100
            cursor-pointer
          "
        >
          <SynIcon name="apple-store-icon" custom-class="w-4 h-4" />
        </div>
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=vig.tictop.app"
        target="_blank"
      >
        <div
          v-vig-tooltip="$t('COMMON_LABEL_GO_TO_GOOGLE_PLAY')"
          class="
            w-6
            h-6
            flex-center
            rounded
            fill-current-350
            hover:bg-gray-100
            cursor-pointer
          "
        >
          <SynIcon name="google-play-icon" custom-class="w-3.5 h-3.5" />
        </div>
      </a>

      <SynLinkDownload :is-button-download="false" :z-index="70">
        <div
          class="
            w-6
            h-6
            flex-center
            rounded
            fill-current-350
            hover:bg-gray-100
            cursor-pointer
          "
        >
          <SynIcon name="qr-code-icon" custom-class="w-3.5 h-3.5" />
        </div>
      </SynLinkDownload>
    </div>
    <!-- END DOWNLOAD APP     -->
  </div>

  <VideoPlayerDraggable
    v-if="Object.keys(videoActive)?.length > 0"
    :video-active="videoActive?.video"
    @on-expand-video="(video) => onExpandVideo(videoActive?.folderId, video)"
    @on-close="onClose"
  />

  <SynModal
    v-if="isShowAlertChatSupport"
    :z-index="'z-60'"
    container-class="w-1/4"
    @cancel="isShowAlertChatSupport = false"
  >
    <template #body>
      <syn-animation
        name="customerService"
        stype="width: 250px; height: 250px;"
      ></syn-animation>
      <span class="text-center text-current-500 px-3">{{
        $t('HELP_VIDEO_CHAT_SUPPORT_ALERT')
      }}</span>
    </template>
    <template #footer>
      <div class="flex items-center justify-end px-4 py-4">
        <SynButton
          type-text
          color="gray"
          :label="$t('COMMON_LABEL_CLOSE')"
          @click="isShowAlertChatSupport = false"
        />
      </div>
    </template>
  </SynModal>
</template>
