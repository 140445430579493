import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { TicketSingleton } from '@/ticket/services/organization-service';
import AuthService from '@/ticket/services/auth-services';
export default defineStore({
    id: storeId.ticketOrganization,
    state: () => ({
        _authService: AuthService.getInstance(),
        activeOrganizationInfo: {} as any,
        domain: {} as any,
        projects: [] as any[],
    }),
    getters: {},
    actions: {
        async initData() {
            const res: any = await this._authService.getTokenInfo();
            const result = res.result;
            if (!result) return;
            this.domain = result.domain;
            this.projects = result.projects;
            TicketSingleton.setInstance(
                result?.tokenInfo?.organizationId,
                result?.tokenInfo?.userId,
                result?.tokenInfo?.groupId,
                result?.tokenInfo?.domainId
            );
        },
    },
});
