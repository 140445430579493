export const SignType = [
    {
        text: 'COMMON_LABEL_WORKING',
        value: 'W',
    },
    {
        text: 'COMMON_LABEL_TAKE_A_DAYOFF',
        value: 'N',
    },
    {
        text: 'COMMON_LABEL_HALF_DAY_BREAK',
        value: '1/2N',
    },
    {
        text: 'COMMON_LABEL_WORK_FROM_HOME_ALL_DAY',
        value: 'ON',
    },
    {
        text: 'COMMON_LABEL_WORK_FROM_HOME_HALF_A_DAY',
        value: '1/2ON',
    },
    {
        text: 'DAYOFF_EXCEPTION_TYPE_START_LATE',
        value: 'M',
    },
    {
        text: 'DAYOFF_EXCEPTION_TYPE_STOP_EARLY',
        value: 'S',
    },
    {
        text: 'DAYOFF_EXCEPTION_TYPE_BREAK_TIME',
        value: 'RN',
    },
    // {
    //     text: 'COMMON_LABEL_SUNDAY',
    //     value: 'CN',
    // },
];

export const ColumnExports = [
    {
        text: 'COMMON_LABEL_TOTAL',
        value: '',
    },
    {
        text: 'COMMON_MODULE_DAYOFF',
        value: 'day_off',
    },
    {
        text: 'DAYOFF_TYPE_WFH',
        value: 'work_from_home',
    },
    {
        text: 'DAYOFF_TYPE_EXCEPTION',
        value: 'exception',
    },
];

export enum ExportOptions {
    Hour = 'hour',
    Day = 'day',
}
