<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import chatStore from '@/store/chat';
import myProfileStore from '@/store/auth/my-profile';
import NewChat from '@/ui/modules/messaging/chat-icon/NewChat.vue';
import UserDocModel from '@/ui/plugins/firebases/firestore-database/models/UserDocModel';
import { useRouter } from 'vue-router';
import ModalNewGroupChat from '@/ui/modules/messaging/modal-new-group-chat/ModalNewGroupChat.vue';

defineProps({
  isShowSuffixIcon: {
    type: Boolean,
    default: false,
  },
  isChatPage: {
    type: Boolean,
    default: false,
  },
  isDropdownDisabled: {
    type: Boolean,
    default: false,
  },
});
const _chatStore = chatStore();

const myProfile = computed(() => myProfileStore().myProfile);

const unSeenConversationsCount = computed(
  () => _chatStore.unSeenConversationsCount
);

const router = useRouter();
const isOpenChat = ref();
const isCreateGroupChat = ref();
const isFocusInputChat = ref(false);

onBeforeMount(() => {
  // console.log('---Chat Icon getUsersGroupsForChat');
  // _chatStore.getUsersGroupsForChat();
});

onMounted(() => {
  window.addEventListener('keydown', (e) => {
    if (
      e.ctrlKey &&
      e.keyCode === 81 &&
      !router.currentRoute.value.fullPath.includes('/messages')
    ) {
      // console.log('CTRL + Q');
      vigChatIconRef.value?.onForceOpen();
      onDropdownOpen();
      isFocusInputChat.value = true;
      e.preventDefault();
    }
  });
});

const onDropdownOpen = () => {
  isOpenChat.value = true;
  _setUserSeenAllConversations();
};

const onCloseDropdown = () => {
  isOpenChat.value = false;
  isFocusInputChat.value = false;
  vigChatIconRef.value?.onForceClose();
};

const vigChatIconRef = ref(null as any);

const _setUserSeenAllConversations = () => {
  const userId = myProfile?.value?.id;
  if (!userId) return;

  return new UserDocModel(userId).setSeenAll();
};

const openNewChatSuccess = () => {
  vigChatIconRef.value?.onForceClose();
};
</script>
<template>
  <VigDropdown
    ref="vigChatIconRef"
    :disabled="isDropdownDisabled"
    @on-dropdown-open="onDropdownOpen"
    @on-dropdown-close="(isOpenChat = false), (isFocusInputChat = false)"
  >
    <template #dropdown-toggle>
      <div class="flex-center space-x-2 cursor-pointer">
        <slot name="dropdownToggle">
          <button
            :title="isOpenChat ? '' : $t('INTRODUCE_LABEL_MESSANGE')"
            class="h-8 w-8 flex-center hover:fill-current rounded-full relative"
          >
            <SynIcon
              name="Message"
              custom-class="h-5 w-5 fill-current text-current-500"
              size="medium"
              has-action
              :is-active="isOpenChat"
            />
            <!--Un seen conversations count-->
            <div
              v-if="unSeenConversationsCount"
              class="absolute -right-2 -top-1 rounded-full bg-white p-0.5"
            >
              <div
                class="
                  rounded-full
                  bg-red-500
                  text-white
                  w-5
                  h-5
                  flex
                  justify-center
                  items-center
                "
                style="font-size: 10px"
              >
                {{ unSeenConversationsCount }}
              </div>
            </div>
          </button>
        </slot>
        <SynIcon
          v-if="isShowSuffixIcon"
          class="fill-gray-500"
          custom-class="h-2.5 w-2.5"
          :name="isOpenChat ? 'arrow-up' : 'arrow-down'"
        />
      </div>
    </template>
    <template #dropdown-menu>
      <div
        v-permission-model
        class="flex flex-col rounded-md"
        :style="'z-index: 20; width: 29rem; min-height: 450px; max-height: min(80vh, 800px)'"
      >
        <div class="px-4 pt-2 bg-white">
          <div class="text-md items-center space-x-2 pt-2 flex font-medium">
            <span class="flex-1">{{
              $t('COMMOM_LABEL_MESSAGER') || 'Messenger'
            }}</span>
            <VigButton
              rounded="rounded-full"
              padding="px-3 py-1"
              class="text-sm"
              @click="isCreateGroupChat = true"
            >
              <SynIcon name="GroupAdd" />
              <span>{{ $t('CHAT_GROUP_NEW') }}</span>
            </VigButton>
            <div class="dropdown-item" @click="isOpenChat = false">
              <SynIcon
                name="close"
                custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                color="gray"
                has-action
              />
            </div>
          </div>
        </div>

        <NewChat
          v-if="isOpenChat"
          :is-focus-input-search="isFocusInputChat"
          :is-chat-page="isChatPage"
          :is-show-close="false"
          :is-open-dropdown="isOpenChat"
          :is-show-view-all="true"
          @success="openNewChatSuccess"
          @close="onCloseDropdown"
        />
        <!-- <div
          v-if="selectedUsers?.length > 0 || selectedGroup?.id"
          class="p-4 relative hover-to-show__parent"
          style="max-height: 50%"
        >
          <span class="float-left">To : </span>
          <div
            v-for="contact of selectedUsers"
            :key="contact"
            class="
              rounded-full
              shadow
              bg-current-100
              text-gray-800 text-sm
              p-1
              m-0.5
              flex-center
              space-x-2
              w-max
              float-left
            "
          >
            <syn-avatar
              custom-class="w-5 h-5"
              :src="contact?.avatar?.replace('original', 'mini')"
              :name="contact?.name"
            />
            <span>{{ contact?.name }}</span>
            <button
              class="
                p-0.5
                rounded-full
                bg-opacity-50
                hover:bg-opacity-100
                flex-center
              "
              @click.stop="onRemoveSelectedUser(contact)"
            >
              <SynIcon name="Close" custom-class="w-4 h-4"/>
            </button>
          </div>
          <span
            v-if="!isLoading"
            class="
              px-2
              rounded
              absolute
              bg-red-500
              text-white
              font-semibold
              bottom-2
              right-2
              cursor-pointer
              hover-to-show__children
            "
            @click="
              clearSelectedMember();
              clearSelectedFavorite();
              clearSelectedGroup();
            "
          >
            Clear
          </span>
        </div> -->
      </div>
    </template>
  </VigDropdown>

  <ModalNewGroupChat
    v-if="isCreateGroupChat"
    @on-close="isCreateGroupChat = false"
    @on-chat-click="[openNewChatSuccess(), (isCreateGroupChat = false)]"
    @on-created="[openNewChatSuccess(), (isCreateGroupChat = false)]"
  />
</template>
