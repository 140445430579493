<script setup lang="ts">
import { computed, ref } from 'vue';
import { useMouseInElement } from '@vueuse/core';
import { useElementHover } from '@vueuse/core';
import PieChart from '@/ui/common/plugins/chart/PieChart.vue';
import userStore from '@/store/user';
import {
  CapacityMember,
  ERemainingWorkforceStatus,
  TWorkingMember,
} from '@/store/dashboard/overview-monitor-store';

enum UserLastState {
  online = 'online',
  offline = 'offline',
  absent = 'absent',
}

const props = defineProps<{
  timeCapacity: {
    members: CapacityMember[];
  };
  workingMember: TWorkingMember;
}>();

const allUserByIds = computed(() => userStore().allUserByIds);

const workingMembers = computed(() => {
  return props.timeCapacity?.members
    ?.filter((user) => user?.lastState == UserLastState.online)
    ?.map((user) => {
      return {
        ...user,
        ...allUserByIds.value[user?.id],
      };
    });
});
const pausedMembers = computed(() => {
  return props.timeCapacity?.members
    ?.filter((user) => user?.lastState == UserLastState.absent)
    ?.map((user) => {
      return {
        ...user,
        ...allUserByIds.value[user?.id],
      };
    });
});
const stoppedMembers = computed(() => {
  return props.timeCapacity?.members
    ?.filter((user) => user?.workingStatus == ERemainingWorkforceStatus.stopped)
    ?.map((user) => {
      return {
        ...user,
        ...allUserByIds.value[user?.id],
      };
    });
});
const offMembers = computed(() => {
  return props.timeCapacity?.members
    ?.filter(
      (user) =>
        user?.workingStatus == ERemainingWorkforceStatus.offToday ||
        user?.workingStatus == ERemainingWorkforceStatus.notYetStated
    )
    ?.map((user) => {
      return {
        ...user,
        ...allUserByIds.value[user?.id],
      };
    });
});
const membersStartedToday = computed(() => {
  const startedWorkingMembers =
    workingMembers.value?.length +
    pausedMembers.value?.length +
    stoppedMembers.value?.length;
  return [
    {
      data: startedWorkingMembers,
      color: '#4bb0b2',
    },
    {
      data: Math.max(
        props.timeCapacity?.members?.length - startedWorkingMembers,
        0
      ),
      color: '#e5e7eb',
    },
  ];
});
const { x: mouseX, y: mouseY } = useMouseInElement(document.body);

const isOpenDetail = ref(false);
const dementionByMouse = ref<any>({});
let delayToShowDetail;
const detailModalRef = ref();
const isHoveredDetailModal = useElementHover(detailModalRef);
const onOpenDetail = () => {
  if (isOpenDetail.value) return;
  if (delayToShowDetail) clearTimeout(delayToShowDetail);

  delayToShowDetail = setTimeout(() => {
    isOpenDetail.value = true;
    dementionByMouse.value = {
      top: `${mouseY.value + 10}px`,
      left: `${mouseX.value + 10}px`,
    };
  }, 750);
};

const onMouseleaveCard = () => {
  if (delayToShowDetail) clearTimeout(delayToShowDetail);
  setTimeout(() => {
    if (isHoveredDetailModal.value) return;
    isOpenDetail.value = false;
  }, 500);
};
const onCloseDetailModal = () => {
  isOpenDetail.value = false;
};
</script>
<template>
  <div
    class="w-52 flex flex-col bg-white"
    style="box-shadow: 0px 2px 6px #9ca3af47; border-radius: 5px"
    @mouseover="onOpenDetail"
    @mouseleave="onMouseleaveCard"
  >
    <span class="text-gray-600 font-semibold text-sm text-center p-4">
      {{ $t('MONITOR_STATISTIC_MEMBERS_STARTED') }}
    </span>
    <div class="flex-1 flex-center px-8">
      <PieChart
        v-if="
          workingMembers?.length > 0 ||
          pausedMembers?.length > 0 ||
          stoppedMembers?.length > 0
        "
        :prop-data="membersStartedToday"
        is-hidden-title
        custom-class="w-36 flex-center"
      />
    </div>
  </div>

  <AtomDialog v-if="isOpenDetail" :dimension="dementionByMouse">
    <template #body>
      <div
        ref="detailModalRef"
        class="w-full p-4 flex flex-col space-y-4"
        style="min-width: 24rem"
        @mouseleave="onCloseDetailModal"
      >
        <!-- HEADER -->
        <section class="flex justify-between">
          <span class="text-gray-800 font-semibold">
            {{ $t('MONITOR_STATISTIC_MEMBERS_STARTED') }}
          </span>
        </section>

        <!-- body -->
        <section class="flex-1 w-full flex flex-col space-y-1">
          <div class="w-full flex flex-col bg-current-50 rounded">
            <!-- Online -->
            <div class="w-full flex items-center justify-between p-2">
              <div class="flex-center space-x-2">
                <span class="w-2 h-2 rounded-full bg-green-500"></span>
                <span class="text-gray-600 font-semibold text-sm">
                  {{ $t('COMMON_LABEL_START_ONDAY') }}
                </span>
              </div>
              <span
                class="
                  flex-1
                  text-right
                  pl-4
                  pr-16
                  text-current
                  font-semibold
                  w-10
                "
              >
                {{ workingMembers?.length }}
              </span>
              <div class="w-40">
                <SynListAvatar :users="workingMembers" :max-length="5" />
              </div>
            </div>

            <!-- Pause -->
            <div
              class="
                w-full
                flex
                items-center
                justify-between
                p-2
                border-t border-gray-100
              "
            >
              <div class="flex-center space-x-2">
                <span class="w-2 h-2 rounded-full bg-orange-500"></span>
                <span class="text-gray-600 font-semibold text-sm">
                  {{ $t('COMMON_LABEL_PAUSE_ONDAY') }}
                </span>
              </div>
              <span
                class="
                  flex-1
                  text-right
                  pl-4
                  pr-16
                  text-current
                  font-semibold
                  w-10
                "
              >
                {{ pausedMembers?.length }}
              </span>
              <div class="w-40">
                <SynListAvatar :users="pausedMembers" :max-length="5" />
              </div>
            </div>

            <!-- Stopped -->
            <div
              v-if="stoppedMembers?.length > 0"
              class="
                w-full
                flex
                items-center
                justify-between
                p-2
                border-t border-gray-100
              "
            >
              <div class="flex-center space-x-2">
                <span class="w-2 h-2 rounded-full bg-gray-500"></span>
                <span class="text-gray-500 font-semibold text-sm">
                  {{ $t('COMMON_LABEL_STOPED_ONDAY') }}
                </span>
              </div>
              <span
                class="
                  flex-1
                  text-right
                  pl-4
                  pr-16
                  text-current
                  font-semibold
                  w-10
                "
              >
                {{ stoppedMembers?.length }}
              </span>
              <div class="w-40">
                <SynListAvatar :users="stoppedMembers" :max-length="5" />
              </div>
            </div>
          </div>

          <div class="w-full flex flex-col bg-gray-50 rounded">
            <div class="w-full flex items-center justify-between p-2">
              <div class="flex-center space-x-2">
                <span class="w-2 h-2 rounded-full bg-gray-500"></span>
                <span class="text-gray-600 font-semibold text-sm">
                  {{ $t('COMMON_LABEL_STOP_ONDAY') }}
                </span>
              </div>
              <span
                class="flex-1 text-right pl-4 pr-16 text-current font-semibold"
              >
                {{ offMembers.length }}
              </span>
              <div class="w-40">
                <SynListAvatar :users="offMembers" :max-length="5" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </template>
  </AtomDialog>
</template>
