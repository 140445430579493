import ExportRepository from '@/application/repositories/ExportRepository';
import { IExportDataPayload } from '../types/task/task-export-data.types';

export default class ExportService {
    private static instance: ExportService;

    _exportRepository: ExportRepository;

    constructor() {
        this._exportRepository = ExportRepository.getInstance();
    }

    public static getInstance(): ExportService {
        if (!ExportService.instance) {
            // Get from local storage
            ExportService.instance = new ExportService();
        }
        return ExportService.instance;
    }

    exportTaskData(data: IExportDataPayload): Promise<any> {
        return this._exportRepository.exportTaskData(data);
    }

    exportGanttChart(data) {
        return this._exportRepository.exportGanttChart(data);
    }
}
