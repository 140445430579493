<script setup lang="ts">
import parsePhoneNumber from 'libphonenumber-js';
import { computed } from 'vue';

const props = defineProps<{
  phoneNumber: string;
  formatType?: 'NATIONAL' | 'INTERNATIONAL';
}>();

const phoneNumberFormated = computed(() => {
  if (props.formatType == 'NATIONAL')
    return parsePhoneNumber(props.phoneNumber)?.formatNational();
  return parsePhoneNumber(props.phoneNumber)?.formatInternational();
});
</script>
<template>
  <span>
    {{ phoneNumberFormated }}
  </span>
</template>
