<script setup lang="ts">
import { INotificationMainDataTaskUpdate } from '@/application/types/notification.types';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';

const props = defineProps<{
  beforeUpdate: INotificationMainDataTaskUpdate;
  afterUpdate: INotificationMainDataTaskUpdate;
}>();

const myTimezone = computed(() => myProfileStore().timezone);

const beforeData = computed<any>(() => {
  return {
    scheduleByHour: props.beforeUpdate?.scheduleByHour,
    scheduleTime: props.beforeUpdate?.scheduleTime,
    scheduleTimezone: props.beforeUpdate?.scheduleTimezone || myTimezone.value,
  };
});
const afterData = computed<any>(() => {
  return {
    scheduleByHour: props.afterUpdate?.scheduleByHour,
    scheduleTime: props.afterUpdate?.scheduleTime,
    scheduleTimezone: props.afterUpdate?.scheduleTimezone || myTimezone.value,
  };
});
</script>

<template>
  <div class="inline-flex space-x-1 text-overflow-hidden-line">
    <template v-if="beforeData?.scheduleTime">
      <p>
        {{ $t('COMMON_LABEL_CHANGE_FROM') }}
      </p>

      <p class="text-overflow-hidden-line font-semibold italic">
        <SynLabelDateTime2
          v-if="beforeData?.scheduleByHour"
          format="date_time"
          :datetime="beforeData?.scheduleTime"
          :timezone="beforeData?.scheduleTimezone"
        />
        <SynLabelDateTime
          v-else
          format="date"
          :datetime="beforeData?.scheduleTime"
        />
      </p>
    </template>

    <p>
      {{ $t('COMMON_LABEL_CHANGE_TO') }}
    </p>
    <p
      class="text-overflow-hidden-line font-semibold italic uppercase underline"
    >
      <template v-if="afterData?.scheduleTime">
        <SynLabelDateTime2
          v-if="afterData?.scheduleByHour"
          format="date_time"
          :datetime="afterData?.scheduleTime"
          :timezone="afterData?.scheduleTimezone"
        />
        <SynLabelDateTime
          v-else
          format="date"
          :datetime="afterData?.scheduleTime"
        />
      </template>

      <template v-else>
        {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
      </template>
    </p>
  </div>
</template>
