import { EPlanId } from '@/application/constants/plan.const';

export const tabPlanBilling = {
    MANAGE_PLAN: 'MANAGE_PLAN',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    BILLING_INFO: 'BILLING_INFO',
    BILLING_HISTORY: 'BILLING_HISTORY',
};

// export const paymentPlansId = {
//     FREE: 'FREE',
//     PRO: 'PRO',
//     VIP: 'VIP',
//     PERSONAL: 'PERSONAL',
// };

export const allPlanLevels = {
    FREE: 1,
    BASIC: 1,
    PRO: 2,
    VIP: 3,
};

export const paymentStatus = {
    FREE: {
        key: 'FREE',
        label: 'COMMOM_LABEL_FREE',
        color: 'current',
    },
    ACTIVE: {
        key: 'ACTIVE',
        label: 'SETTING_PLAN_PAYMENT_STATUS_ACTIVE_LABEL',
        color: 'current',
    },
    PENDING: {
        key: 'PENDING',
        label: 'SETTING_PLAN_PAYMENT_STATUS_PENDING_LABEL',
        color: 'orange',
    },
    PAID: {
        key: 'PAID',
        label: 'SETTING_PLAN_PAYMENT_STATUS_PAID_LABEL',
        color: 'current',
    },
};

export const paymentMethod = {
    MOMO: 'MOMO',
    VNPAY: 'VNPAY',
};

export const billingCycle = {
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY',
};

export const iconPlans = {
    FREE: 'Mountain',
    PRO: 'PlanEfficient',
    VIP: 'PlanExcellent',
};

export const paymentPlansInfo = {
    PRO: {
        planId: EPlanId.PRO,
        name: EPlanId.PRO,
        icon: 'PlanExcellent',
        priceMonthly: 8,
        priceYearly: 6.5,
        customClassHeader: 'bg-current-400 text-white',
        customClassBody: 'border border-current-200',
        subTitle: 'SETTING_PLAN_EXCELLENT_SUB_TITLE',
        descriptions: [
            'SETTING_PLAN_EXCELLENT_DES_1',
            'SETTING_PLAN_EXCELLENT_DES_2',
            'SETTING_PLAN_EXCELLENT_DES_3',
            'SETTING_PLAN_EXCELLENT_DES_4',
        ],
    },
};
export const PackagePrice = {
    PRO: {
        key: 'PRO',
    },
    VIP: {
        key: 'VIP',
    },
};
