<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 791.454 791.454"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g id="Vrstva_x0020_1_14_">
          <path
            clip-rule="evenodd"
            d="m202.669 0h264.71l224.322 233.811v454.727c0 56.689-46.227 102.916-102.916 102.916h-386.116c-56.932 0-102.916-46.227-102.916-102.916v-585.622c0-56.932 45.984-102.916 102.916-102.916z"
            fill="#a263e5"
            fill-rule="evenodd"
            data-original="#9900cc"
            class=""
          ></path>
          <g fill="#fff">
            <path
              clip-rule="evenodd"
              d="m467.136 0v231.865h224.565z"
              fill-rule="evenodd"
              opacity=".302"
              fill="#ffffff"
              data-original="#ffffff"
              class=""
            ></path>
            <path
              d="m466.406 520.175c7.542-12.652 12.652-28.709 13.625-44.524.487-10.219-1.703-20.924-6.569-30.899-7.786-15.814-20.924-24.573-33.819-33.332-9.489-6.326-18.491-12.408-24.817-20.924l-1.217-1.46c-3.649-5.109-8.029-10.948-8.759-15.814-.487-4.866-5.109-8.272-9.732-8.029-5.109.487-8.759 4.379-8.759 9.489v166.904c-7.786-4.866-17.518-7.786-28.223-7.786-25.79 0-46.714 16.788-46.714 37.468s20.924 37.468 46.714 37.468 46.957-16.788 46.957-37.468v-109.242c14.111 5.596 36.982 19.221 43.307 51.093-1.216 1.703-2.19 3.649-3.649 5.109-3.406 3.893-2.92 9.732.973 13.381 3.893 3.406 9.732 2.92 13.138-.973 2.433-2.92 4.866-6.083 6.812-9.732.245-.243.488-.486.732-.729z"
              fill="#ffffff"
              data-original="#ffffff"
              class=""
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
