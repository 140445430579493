import $constants from '@/ui/plugins/constants';

export const isImage = (fileType) =>
    fileType?.startsWith($constants.FILE.TYPE.IMAGE);

export const isAudio = (fileType) =>
    fileType?.startsWith($constants.FILE.TYPE.AUDIO);

export const isVideo = (fileType) =>
    fileType?.startsWith($constants.FILE.TYPE.VIDEO);

export const isFolder = (fileType) =>
    fileType?.startsWith($constants.FILE.TYPE.FOLDER);

export const isLink = (fileType) =>
    [
        $constants.FILE.TYPE.LINK,
        $constants.FILE.TYPE.GOOGLE_DOC,
        $constants.FILE.TYPE.GOOGLE_SHEET,
        $constants.FILE.TYPE.GOOGLE_PRESENTATION,
    ].includes(fileType);

export const isOffice = (fileType) =>
    [
        $constants.FILE.TYPE.DOC,
        $constants.FILE.TYPE.EXCEL,
        $constants.FILE.TYPE.PPTX,
    ].includes(fileType);

export const canDownload = (fileType) =>
    ![
        $constants.FILE.TYPE.FOLDER,
        $constants.FILE.TYPE.LINK,
        $constants.FILE.TYPE.GOOGLE_DOC,
        $constants.FILE.TYPE.GOOGLE_SHEET,
        $constants.FILE.TYPE.GOOGLE_PRESENTATION,
    ].includes(fileType);
