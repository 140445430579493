import {
    EGroupType,
    IGroupDomain,
    IGroupUser,
} from '@/application/types/group/group.types';
import groupStore from '@/store/group';
import myProfileStore from '@/store/auth/my-profile';
import { GroupMemberRole } from '@/ui/common/constants/constant';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import BreakLinkDepartmentGroup from '@/ui/modules/group/detail/BreakLinkDepartmentGroup.vue';
import { getGroupShortName } from '@/application/constants/chat/chat.const';

export interface IGroupDetailEntity {
    id: number;
    name: string;
    creatorId: number;
    creationTime: string;
    description: string;
    isPrivate: boolean;
    isActive: boolean;
    avatar: string;
    avatarPath: string;
    code: string;
    email: string;
    memberCount: number;
    taskCount: number;
    type: EGroupType;
    isMember: boolean;
    isAdminGroup: boolean;
    hasMoreTask?: boolean;
    lastCreatedGroupTask: string;
    groupDomains: IGroupDomain[];
    groupUsers: IGroupUser[];
    groupTasks: any[];
    notification?: any;
    isFavorite?: boolean;
}

export default class GroupDetailEntity implements IGroupDetailEntity {
    id: number;
    name: string;
    creatorId: number;
    creationTime: string;
    description: string;
    isPrivate: boolean;
    isActive: boolean;
    avatar: string;
    avatarPath: string;
    code: string;
    email: string;
    memberCount: number;
    taskCount: number;
    type: EGroupType;
    isMember: boolean;
    isAdminGroup: boolean;
    hasMoreTask: boolean;
    lastCreatedGroupTask: string;
    groupDomains: IGroupDomain[];
    groupUsers: IGroupUser[];
    groupTasks: any[];
    notification?: any;
    isFavorite?: boolean;
    isSystemGroup?: boolean;
    readonly?: boolean;
    shortName?: string;

    constructor(data) {
        this.id = data?.id;
        this.name = data?.name;
        this.creatorId = data?.creatorId;
        this.creationTime = data?.creationTime;
        this.description = data?.description;
        this.isPrivate = data?.isPrivate;
        this.isActive = data?.isActive;
        this.avatar = data?.avatar;
        this.avatarPath = data?.avatarPath;
        this.code = data?.code;
        this.email = data?.email;
        this.memberCount = data?.memberCount;
        this.taskCount = data?.taskCount;
        this.type = data?.type;
        this.isMember = data?.isMember;
        this.isAdminGroup = data?.isAdminGroup;
        this.hasMoreTask = data?.hasMoreTask;
        this.lastCreatedGroupTask = data?.lastCreatedGroupTask;
        this.groupDomains = data?.groupDomains || [];
        this.groupUsers = data?.groupUsers || [];
        this.groupTasks = data?.groupTasks || [];
        this.notification = data?.notification;
        this.isFavorite = data?.isFavorite;

        this.isSystemGroup =
            this.code == 'GENERAL_GROUP' || this.code == 'DELETED_GROUP';
        this.readonly = !(
            this.isAdminGroup ||
            (!this.isPrivate &&
                (myProfileStore().isAdmin || myProfileStore().isOwner))
        );
        this.shortName = getGroupShortName({
            isGroup: true,
            name: this.name,
        });
    }

    async updateMembers(newMemberList) {
        if (!this.hasPermission() || !this.hasValidMembers()) return false;

        const payload = {
            groupId: this.id,
            active: false,
            userIds: [],
            memberInfos: newMemberList,
        };
        await groupStore().addUserToGroup(payload);
        this.groupUsers = newMemberList;

        return true;
    }
    async onChangeMemberRole(user) {
        const newRole =
            user?.role == GroupMemberRole.Admin
                ? GroupMemberRole.User
                : GroupMemberRole.Admin;
        if (
            !this.hasPermission(true) ||
            !this.hasValidMembers(newRole == GroupMemberRole.Admin ? 1 : -1)
        )
            return false;

        const memberInfos = [...this.groupUsers];
        const index = memberInfos?.findIndex((u) => u?.userId == user?.userId);
        if (index == -1) return;
        memberInfos[index].role = newRole;

        const payload = {
            groupId: this.id,
            active: false,
            userIds: [],
            memberInfos,
        };
        await groupStore().addUserToGroup(payload);

        if (
            newRole == GroupMemberRole.Admin &&
            user?.userId == myProfileStore().myProfile?.id
        ) {
            this.isAdminGroup = true;
            this.readonly = !(
                this.isAdminGroup ||
                (!this.isPrivate &&
                    (myProfileStore().isAdmin || myProfileStore().isOwner))
            );
        }

        this.groupUsers = memberInfos;

        return true;
    }

    async confirmBeforeRemoveMember(user, options?) {
        const itMe = user?.userId
            ? user?.userId == myProfileStore().myProfile?.id
            : false;

        let titleCode = itMe
            ? 'GROUP_LABEL_LEAVE'
            : 'GROUP_LABEL_REMOVE_MEMBER';
        let contentCode = itMe
            ? 'GROUP_LABEL_CONFIRM_LEAVE_GROUP_AND_UNASSIGN'
            : 'GROUP_LABEL_CONFIRM_REMOVE_MEMBER_AND_UNASSIGN';

        if (this.type == EGroupType.CHAT) {
            titleCode = 'GROUP_LABEL_CONFIRM_LEAVE_GROUP';
            contentCode = 'GROUP_LABEL_CONFIRM_REMOVE_MEMBER';
        }

        settingGlobalModal({
            type: 'notification',
            title: translate(titleCode),
            content: translate(contentCode, {
                groupName: this.name || '',
            }),
            closeable: true,
            confirmable: true,
            confirmLabel: translate(
                options?.isConfirmOnly
                    ? 'COMMON_LABEL_GOT_IT'
                    : itMe
                    ? 'GROUP_LABEL_LEAVE'
                    : 'COMMON_LABEL_DELETE'
            ),
            options: {
                confirmButtonColor: 'red',
            },
        });
        return ask();
    }
    async onRemoveMember(user: IGroupUser) {
        if (
            !this.hasPermission() ||
            !this.hasValidMembers(user?.role == GroupMemberRole.Admin ? -1 : 0)
        )
            return false;

        const isConfirmRemove = await this.confirmBeforeRemoveMember(user);
        if (!isConfirmRemove) return;

        const memberInfos = this.groupUsers?.filter(
            (gu) => gu?.userId !== user?.userId
        );

        const payload = {
            groupId: this.id,
            active: false,
            userIds: [],
            memberInfos,
        };
        this.groupUsers = memberInfos;

        await groupStore().addUserToGroup(payload);

        return true;
    }

    hasPermission(isChangeUserRole?): boolean {
        if (this.isSystemGroup) {
            // warningType.value = EWarningType.IS_SYSTEM_GROUP;
            settingGlobalModal({
                type: 'notification',
                title: translate('GROUP_LABEL_EDIT_SYSTEM_GROUP_TITLE'),
                content: translate('GROUP_LABEL_REMOVE_SYSTEM_GROUP_CONTENT', {
                    groupName: this.name || '',
                }),
                confirmable: true,
            });
            ask();
            return false;
        }

        if (this.code?.includes('DEPARTMENT')) {
            // isShowAlertCannotEditDepartmentGroup.value = true;
            settingGlobalModal({
                type: 'notification',
                title: '',
                content: '',
                confirmable: false,
                isHiddenIcon: true,
                contentComponent: BreakLinkDepartmentGroup,
                contentComponentProps: {
                    group: this,
                },
            });

            ask();
            return false;
        }

        const isOrgAdmin = myProfileStore().isAdmin || myProfileStore().isOwner;

        if (
            this.isAdminGroup ||
            (isChangeUserRole && !this.isPrivate && isOrgAdmin)
        )
            return true;

        // warningType.value = EWarningType.YOU_ARE_NOT_GROUP_ADMIN;
        settingGlobalModal({
            type: 'notification',
            title: translate('COMON_NOT_ALLOW_PERFORM_ACTION'),
            content: translate(
                isChangeUserRole
                    ? 'GROUP_ONLY_ADMIN_HAVE_PERMISSION'
                    : 'GROUP_UPDATE_ALLOW_ADMIN_GROUP_ONLY'
            ),
            confirmable: true,
        });
        ask();
        return false;
    }
    hasValidMembers(addMemberTotal?) {
        if (!this.groupUsers || this.groupUsers?.length == 0) return false;

        if (
            this.groupUsers.filter((e) => e.role == GroupMemberRole.Admin)
                .length +
                (addMemberTotal || 0) <=
            0
        ) {
            //   showAlertRemainOneAdmin.value = true;
            settingGlobalModal({
                type: 'notification',
                title: translate('COMON_NOT_ALLOW_PERFORM_ACTION'),
                content: translate('GROUP_UPDATE_ADMIN_NOTALLOW'),
                confirmable: true,
            });
            ask();
            return false;
        }

        return true;
    }

    changePrivateSuccess(_isPrivate) {
        this.isPrivate = _isPrivate;
        this.readonly = !(
            this.isAdminGroup ||
            (!this.isPrivate &&
                (myProfileStore().isAdmin || myProfileStore().isOwner))
        );
        console.log('🚀 Tictop ~ this.readonly:', this.readonly);
    }
}
