<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { saveAs } from 'file-saver';
import axios from 'axios';
import JSZip from 'jszip';
import commentTaskComposable from '@/ticket/compossables/comment-task';
import taskCommentStore from '@/ticket/store/ticket-comment';
// import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TicketTaskCommentInput from '@/ticket/modules/task/TicketTaskCommentInput.vue';
import TicketMessageItem from '@/ticket/modules/task/TicketMessageItem.vue';
// import UserById from '@/ui/components/user/UserById.vue';
// import ModalFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalFileViewer.vue';
import ModalMultipleFileViewerInfo from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewerInfo.vue';
// import taskDetailStore from '@/store/task/detail';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps({
  taskId: {
    type: [String, Number],
    default: 0,
  },
  currentTask: {
    type: Object,
    default: () => {},
  },
  parentElementRef: {
    type: Object,
    default: null,
  },
});

defineEmits<{
  (e: 'onSaveSuccess', isNeedUpdateAttachments: boolean): void;
  (e: 'onFileInfoClick');
}>();

const _taskCommentStore = taskCommentStore();
// const _taskDetailStore = taskDetailStore();

// const currentId = getCurrentUserId();
const editCommentTaskPinnedInputRef = ref(null);
const listCommentByTaskId = computed(
  () => _taskCommentStore.listCommentByTaskId
);
const listAttachment = computed(() => _taskCommentStore.listAttachment);

const fileShowViewer = ref();

const listCommentPinned = computed(
  () => listCommentByTaskId.value?.filter((el) => el?.pinned) || []
);
// const isShowCommentPinned = ref(false);

const indexCurrentFile = ref(0 as any);

const {
  commentTaskInputRef,
  // commentObjectCreate,
  currentCommentEdit,
  isLoading,
  // resetData,
  getAllComments,
  // onCreateComment,
  onAddReactionComment,
  onSaveUpdateComment,
  onEditComment,
  onCloseEditComment,
  onDeleteComment,
  onPinComment,
  onRemoveReaction,
} = commentTaskComposable();

const initData = (currentTaskId) => {
  if (!currentTaskId) return;

  getAllComments(currentTaskId);
};

initData(props.taskId);

watch(
  () => props.taskId,
  (currentId) => {
    initData(currentId);
  }
);
// const onSendComment = async (attachments) => {
//   await onCreateComment(props.taskId, attachments);
//   emits(
//     'onSaveSuccess',
//     attachments?.addedFiles?.length > 0 || attachments?.addedNotes?.length > 0
//   );
// };

// const onExpandListPinned = () => {
//   isShowCommentPinned.value = !isShowCommentPinned.value;
// };

const onFileClick = async (file, id) => {
  await _taskCommentStore.getListAttachmentById(id);

  fileShowViewer.value = file;
  indexCurrentFile.value = listAttachment.value.findIndex(
    (f) => f.id === file?.id
  );
  if (indexCurrentFile.value === -1) indexCurrentFile.value = null;
};

const onMessageDownload = (files) => {
  if (files.length < 1) return;

  if (files.length === 1) {
    return axios
      .get(files[0].pathUrl || files[0].url_full, { responseType: 'blob' })
      .then((res) => {
        saveAs(res.data, files[0].name);
      });
  } else {
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(
        file.name,
        axios(file.pathUrl || file.url_full, { responseType: 'blob' }).then(
          (res) => res?.data
        )
      );
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `files-${new Date().getTime()}.zip`);
    });
  }
};

const listUserRelated = computed(() => [
  props?.currentTask?.creatorId,
  props?.currentTask?.assigneeId,
]);

const onClickTextComment = (event) => {
  const target = event?.target;

  if (
    target.getAttribute('data-type') == 'mention' &&
    target.getAttribute('data-mention-group') == 'true'
  ) {
    event.preventDefault();
    if (props.currentTask?.groupId) {
      let linkCurrent = target?.href?.split('groups')?.shift();
      window.open(
        `${linkCurrent}groups/${props.currentTask?.groupId}`,
        '_blank'
      );
    }
    if (!props?.currentTask?.groupId) {
      openNotification({
        type: AlertType.warning,
        title: translate(''),
        body: translate('COMMENT_ALERT_NOT_INCLUDE_GROUP', {
          code: props.currentTask?.code,
        }),
      });
    }
  }
};

// const onOpenInputComment = () => {
//   // console.log('onOpenInputComment');
//   _taskDetailStore.setNavigatePoint('other', 'commentTaskInputRefId');
// };

// const onCloseInputComment = () => {
//   // console.log('onCloseInputComment');
//   resetData();
//   // _taskDetailStore.setNavigatePoint('other', 'commentTaskInputRefId');
// };
</script>

<template>
  <template v-if="isLoading?.get">
    <div class="flex flex-col space-y-2 w-full px-4 py-2">
      <div class="w-1/4 bg-gray-200 h-3 rounded-full animate-pulse"></div>
      <div class="w-2/3 bg-gray-200 h-3 rounded-full animate-pulse"></div>
    </div>
  </template>
  <template v-else>
    <div
      class="w-full h-full flex flex-col relative"
      @click="commentTaskInputRef?.onClose()"
    >
      <div
        v-if="listCommentPinned?.length > 0"
        class="flex flex-col bg-orange-50 bg-opacity-70 h-full"
      >
        <TicketMessageItem
          v-for="cmtPinned in listCommentPinned"
          :key="cmtPinned"
          :message="cmtPinned"
          :is-show-line-date="false"
          class="mb-1 w-full"
          bg-msg="bg-white bg-opacity-20"
          :is-edit="
            currentCommentEdit &&
            currentCommentEdit?.id === cmtPinned?.id &&
            currentCommentEdit?.isPinned
          "
          :is-loading="isLoading"
          @on-reaction-add="onAddReactionComment"
          @on-edit-comment="onEditComment({ ...cmtPinned, isPinned: true })"
          @on-remove-comment="onDeleteComment(cmtPinned?.id)"
          @on-pin-comment="onPinComment(cmtPinned?.id)"
          @on-file-click="($event) => onFileClick($event, cmtPinned.id)"
          @on-click-text-comment="onClickTextComment"
        >
          <template
            v-if="
              currentCommentEdit &&
              currentCommentEdit?.id === cmtPinned?.id &&
              currentCommentEdit?.isPinned
            "
            #input-edit
          >
            <TicketTaskCommentInput
              ref="editCommentTaskPinnedInputRef"
              v-model="currentCommentEdit.content"
              v-model:mentionUserIds="currentCommentEdit.mentionUserIds"
              v-model:mentionGroup="currentCommentEdit.mentionGroup"
              edit-mode="EDIT"
              :group-id="currentTask?.groupId"
              :users-related="listUserRelated"
              :task-private="currentTask?.isPrivate"
              :files="cmtPinned?.attachments?.files"
              :notes="cmtPinned?.attachments?.notes"
              :is-loading-send="isLoading?.update"
              is-action-close
              @on-save="onSaveUpdateComment"
              @on-close="onCloseEditComment"
            />
          </template>
        </TicketMessageItem>
      </div>

      <div
        v-if="listCommentByTaskId?.length > 0"
        class="flex flex-col bg-gray-50"
      >
        <TicketMessageItem
          v-for="cmt in listCommentByTaskId"
          :key="cmt"
          :message="cmt"
          :is-edit="
            currentCommentEdit &&
            currentCommentEdit?.id === cmt?.id &&
            !currentCommentEdit?.isPinned
          "
          @on-reaction-add="onAddReactionComment"
          @on-edit-comment="onEditComment(cmt)"
          @on-remove-comment="onDeleteComment(cmt?.id)"
          @on-pin-comment="onPinComment(cmt?.id)"
          @on-reaction-remove="onRemoveReaction"
          @on-file-click="($event) => onFileClick($event, cmt.id)"
          @on-message-download="onMessageDownload"
          @on-click-text-comment="onClickTextComment"
        >
          <template
            v-if="
              currentCommentEdit &&
              currentCommentEdit?.id === cmt?.id &&
              !currentCommentEdit?.isPinned
            "
            #input-edit
          >
            <TicketTaskCommentInput
              ref="editCommentTaskInputRef"
              v-model="currentCommentEdit.content"
              v-model:mentionUserIds="currentCommentEdit.mentionUserIds"
              v-model:mentionGroup="currentCommentEdit.mentionGroup"
              edit-mode="EDIT"
              is-auto-focus
              :group-id="currentTask?.groupId"
              :users-related="listUserRelated"
              :task-private="currentTask?.isPrivate"
              :files="currentCommentEdit?.attachments?.files"
              :notes="currentCommentEdit?.attachments?.notes"
              :is-loading-send="isLoading?.update"
              is-action-close
              @on-save="onSaveUpdateComment"
              @on-close="onCloseEditComment"
            />
          </template>
        </TicketMessageItem>
      </div>

      <!--      <div-->
      <!--        id="commentTaskInputRefId"-->
      <!--        class="-->
      <!--          w-full-->
      <!--          flex-->
      <!--          items-start-->
      <!--          space-x-3-->
      <!--          sticky-->
      <!--          bottom-0-->
      <!--          z-40-->
      <!--          bg-white-->
      <!--          px-4-->
      <!--        "-->
      <!--        style="padding-bottom: 1rem; padding-top: 1rem"-->
      <!--        @click="commentTaskInputRef?.onClose()"-->
      <!--      >-->
      <!--        <div class="w-8 h-8 pt-0.5">-->
      <!--          <UserById-->
      <!--            :user-id="currentId"-->
      <!--            is-hidden-name-->
      <!--            avatar-class="w-8 h-8"-->
      <!--          />-->
      <!--        </div>-->

      <!--        <TaskCommentInput-->
      <!--          ref="commentTaskInputRef"-->
      <!--          v-model="commentObjectCreate.content"-->
      <!--          v-model:mentionUserIds="commentObjectCreate.mentionUserIds"-->
      <!--          v-model:mentionGroup="commentObjectCreate.mentionGroup"-->
      <!--          v-model:attachments="commentObjectCreate.attachments"-->
      <!--          :task-id="currentTask?.id"-->
      <!--          edit-mode="CREATE"-->
      <!--          :group-id="currentTask?.groupId"-->
      <!--          :task-private="currentTask?.isPrivate"-->
      <!--          :users-related="listUserRelated"-->
      <!--          :is-loading-send="isLoading?.send"-->
      <!--          @on-save="onSendComment"-->
      <!--          @on-open="onOpenInputComment"-->
      <!--          @on-close="onCloseInputComment"-->
      <!--          @on-focus="onOpenInputComment"-->
      <!--        />-->
      <!--      </div>-->
    </div>
  </template>

  <ModalMultipleFileViewerInfo
    v-if="fileShowViewer && indexCurrentFile !== null"
    is-file-in-task
    :start-index="indexCurrentFile"
    :files="listAttachment"
    :editable="false"
    :content-type="fileShowViewer?.contentType"
    @on-close="fileShowViewer = null"
    @on-info-click="
      $emit('onFileInfoClick', fileShowViewer);
      fileShowViewer = null;
    "
  >
  </ModalMultipleFileViewerInfo>
</template>

<style scoped>
@import '@/ui/plugins/ckeditor/css/content-styles.scss';
</style>
