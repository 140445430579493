<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    radioName?: string;
    label?: string;
    modelValue?: any;
    value?: any;
    customClass?: string;
    customActiveClass?: string;
    customInactiveClass?: string;
  }>(),
  {
    customClass: 'rounded-full',
    customActiveClass:
      'bg-current text-white border border-current hover:bg-current-500',
    customInactiveClass:
      'bg-white text-gray-800 border border-current-50 hover:bg-current-50',
  }
);

const emit = defineEmits(['update:modelValue', 'update:name', 'change']);

const inputId = `syn-radio-tag-${Date.now()}`;

const onChange = (value) => {
  emit('update:name', props.label);
  emit('update:modelValue', value);
  emit('change', value);
};
</script>

<template>
  <div class="flex">
    <input
      :id="inputId"
      type="radio"
      class="hidden"
      :checked="modelValue === value"
      :value="value"
      @keyup.enter.prevent="onChange(value)"
    />
    <label
      v-bind="$attrs"
      class="cursor-pointer"
      :for="inputId"
      :class="[
        modelValue === value ? customActiveClass : customInactiveClass,
        customClass,
      ]"
      @click="onChange(value)"
    >
      <slot>
        <div
          :class="
            modelValue === value
              ? 'border border-current'
              : 'border border-current-50'
          "
          class="
            relative
            whitespace-nowrap
            cursor-pointer
            py-1
            px-4
            rounded-full
            text-sm
          "
        >
          <div class="flex items-center space-x-1">
            <!-- <SynIcon name="check" v-if="modelValue" class="w-4" /> -->
            <span>{{ label }}</span>
          </div>
        </div>
      </slot>
    </label>
  </div>
</template>
