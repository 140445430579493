<script setup lang="ts">
import {
  computed,
  markRaw,
  nextTick,
  onUnmounted,
  ref,
  toRaw,
  watch,
} from 'vue';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import DeletePlugin from '@ckeditor/ckeditor5-typing/src/delete';
import AutoLinkPlugin from '@ckeditor/ckeditor5-link/src/autolink';
import MentionPlugin from '@ckeditor/ckeditor5-mention/src/mention';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';
// import Enter from '@ckeditor/ckeditor5-enter/src/enter';
// import SelectAll from '@ckeditor/ckeditor5-select-all/src/selectall';
// import Typing from '@ckeditor/ckeditor5-typing/src/typing';
// import Undo from '@ckeditor/ckeditor5-undo/src/undo';
// import ShiftEnterPlugin from '@/ui/plugins/ckeditor/enter/shift-enter-plugin';
// New
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import List from '@ckeditor/ckeditor5-list/src/list';
import PasteLinkPlugin from '@/ui/plugins/ckeditor/paste-link/paste-link';
import CustomLinkTaskTargetPlugin from '@/ui/plugins/ckeditor/autoformat/customlinktask';
import CustomLinkMentionPlugin from '@/ui/plugins/ckeditor/mentions/customlinkmention';
import { renderItemListMentionChat } from '@/ui/modules/messaging/chat-panel/chat-form/chat-input/chat-item-mention/RenderComponentChat.js';
import taskStore from '@/store/task';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { getDomainUrl } from '@/ui/hooks/commonHook';
import taskCommentStore from '@/store/task/task-comment';
import CkeditorCustomAction from '@/ui/common/plugins/ckeditor/CkeditorCustomAction.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import remoteConfigStore from '@/store/remoteConfig';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import attachmentFilesComposables from '@/ui/composables/task/attachments/attachment-files-composables';
// import { IUploadPreviewPropFile } from '@/ui/components/file-viewer/update-preview-type';
import ModalFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalFileViewer.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import AutoformatPlugin from '@/ui/plugins/ckeditor/autoformat/autoformat';
import { generateRandomString } from '@/ui/helpers/utils';

const props = withDefaults(
  defineProps<{
    currentTask: any;
    taskId: any;
    modelValue?: string;
    mentionGroup?: boolean;
    isValid?: boolean;
    mentionUserIds?: any[];
    attachments?: any;
    placeholder?: string;
    maxLength?: number;
    isAction?: boolean;
    isLoadingSend?: boolean;
    isActionClose?: boolean;
    editMode?: 'CREATE' | 'EDIT';
    isAutoFocus?: boolean;
    files?: any[];
    notes?: any[];
    groupId?: any;
    usersRelated?: any[];
    taskPrivate?: boolean;
    functionCode: string;
    isHiddenSaveButton?: boolean;
    isOpenFullMode?: boolean;
  }>(),
  {
    modelValue: '',
    mentionUserIds: () => [],
    attachments: {
      files: [],
      notes: [],
    },
    files: () => [],
    notes: () => [],
    isValid: true,
    placeholder: '',
    maxLength: 2500,
    isAction: true,
    isLoadingSend: false,
    isActionClose: false,
    editMode: 'CREATE',
    taskPrivate: false,
    functionCode: ALL_FUNCTIONS.TASK.COMMENT,
    groupId: undefined,
    usersRelated: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void;
  (e: 'update:cache', value: any): void;
  (e: 'update:mentionGroup', value: any): void;
  (e: 'update:isValid', value: any): void;
  (e: 'update:mentionUserIds', value: any): void;
  (e: 'update:attachments', value: any): void;
  (e: 'onGetLinkFromMsg', value: any): void;
  (e: 'onKeydown', value: any): void;
  (e: 'onFocus'): void;
  (e: 'blur'): void;
  (e: 'onClose'): void;
  (e: 'onOpen'): void;
  (e: 'onSave', attachments: any): Promise<any>;
}>();

const _taskStore = taskStore();
const _taskCommentStore = taskCommentStore();
const _remoteConfigStore = remoteConfigStore();

const webTaskUploadContentTypes = computed(
  () => _remoteConfigStore.webTaskUploadContentTypes
);

_taskStore.getCurrentTaskCycleForChat();

const currentCycle = computed<{
  currentCycle: number;
  letter: string;
  code: number;
}>(() => _taskStore.currentTaskCycle);

watch(
  () => currentCycle.value,
  () => {
    if (ckEditorInstance?.config) {
      ckEditorInstance?.config?.set('link', {
        defaultTaskLink: `${getDomainUrl()}/tasks/`,
        currentCycle: currentCycle.value,
        defaultProtocol: 'https://',
        addTargetToExternalLinks: true,
        decorators: {
          openInNewTab: {
            mode: 'manual',
            label: 'Open in a new tab',
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          },
        },
      });
    }
  }
);
const listMentionUser = computed(() => {
  return _taskCommentStore.getListUserMentionInComment(
    props?.currentTask?.groupId,
    [props?.currentTask?.creatorId, props?.currentTask?.assigneeId],
    props?.currentTask?.isPrivate
  );
});
const commentCacheByTaskIds = computed(
  () => _taskCommentStore.commentCacheByTaskIds
);
const allAttachments = computed<any[]>(() => {
  return arrayOrderBy(
    [
      ...attachmentFiles.value?.filter((file) => file.editStatus !== 'REMOVED'),
      ...noteList.value?.filter((note) => note.editStatus !== 'REMOVED'),
    ],
    ['creationTime'],
    ['asc']
  );
});

let ckEditorInstance;

const taskCommentFileInputId = `TASK_COMMENT_FILE_INPUT_ID_${generateRandomString(
  12
)}`;
const editorRef = ref();
const classicEditor = ref(ClassicEditor);
const listCommandsActive = ref([] as any[]);
const actionList = [
  'bold',
  'italic',
  'strikethrough',
  'underline',
  'bulletedList',
  'numberedList',
];

const totalCharacters = ref(0);
const isOpenFull = props.editMode === 'EDIT' ? ref(true) : ref(false);
const isNotValid = computed(() => totalCharacters.value > props.maxLength);
const pastedImage = ref<any>();
const previewFile = ref<any>();
const taskCommentInputRef = ref();

const {
  attachmentContainerRef,
  attachmentFiles,
  onChooseFiles,
  isOpenRecording,
  onCreateAudioDone,
  onRemoveFile,
  onAddFiles,
  // Note
  isOpenNote,
  drawNoteData,
  onOpenCreateNote,
  onSaveNote,
  noteList,
  onRemoveNote,
} = attachmentFilesComposables('TASK', {
  defaultFiles: props.attachments?.files || [],
  defaultNotes: props.attachments?.notes || [],
  onChange: () => {
    onUpdateAttachments();
  },
});

const execCommand = (
  commandName:
    | 'bold'
    | 'italic'
    | 'strikethrough'
    | 'underline'
    | 'bulletedList'
    | 'numberedList'
) => {
  ckEditorInstance.execute(commandName);
  calcListCommandsActive();
};

const editorConfig = computed(() => ({
  plugins: [
    Essentials,
    // Clipboard,
    // Enter,
    // SelectAll,
    // Typing,
    // Undo,
    List,
    Underline,
    Strikethrough,
    BoldPlugin,
    ItalicPlugin,
    LinkPlugin,
    ParagraphPlugin,
    DeletePlugin,
    WordCount,
    AutoformatPlugin,
    AutoLinkPlugin,
    MentionPlugin,
    PasteLinkPlugin,
    CustomLinkTaskTargetPlugin,
    CustomLinkMentionPlugin,
    // ShiftEnterPlugin,
  ],
  toolbar: {
    items: ['link'],
  },
  language: 'en',
  startupFocus: true,
  placeholder:
    props.placeholder || translate('COMMON_LABEL_PLACEHOLDER_COMMENT_TASK'),
  link: {
    defaultTaskLink: `${getDomainUrl()}/tasks/`,
    currentCycle: currentCycle.value,
    defaultProtocol: 'https://',
    addTargetToExternalLinks: true,
    decorators: {
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
  mention: {
    feeds: [
      {
        marker: '@',
        feed: getFeedItems,
        itemRenderer: customItemRenderer,
      },
    ],
  },
}));

const calcListCommandsActive = () => {
  listCommandsActive.value = actionList.filter(
    (el) => ckEditorInstance?.commands?.get(el).value
  );
};

const getFeedItems = (queryText) => {
  if (!queryText) return listMentionUser.value;

  const querySearch = ignoreUnicode(queryText);

  return listMentionUser.value.filter(
    (el) =>
      ignoreUnicode(el.name).includes(querySearch) ||
      ignoreUnicode(el.id).includes(querySearch)
  );
};

const customItemRenderer = (item) => {
  return renderItemListMentionChat(item);
};

const handleGetLinkFromMsg = (message) => {
  if (!message || !message.includes('<a') || !message.includes('</a>')) return;
  let elementMessage = document.createElement('html');
  elementMessage.innerHTML = message;

  let mentionArr = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        link?.getAttribute('data-mention') &&
        link?.getAttribute('userid') &&
        link?.getAttribute('data-type') == 'mention' &&
        link?.getAttribute('data-mention-group') == 'false'
    )
    .map((elLink) => elLink?.getAttribute('userid'));

  let mentionGroup = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        link?.getAttribute('data-mention') &&
        link?.getAttribute('userid') &&
        link?.getAttribute('data-type') == 'mention' &&
        link?.getAttribute('data-mention-group') == 'true'
    )
    .map((elLink) => elLink?.getAttribute('userid'));

  // console.log('mentionArr', mentionArr);
  // console.log('mentionGroup', mentionGroup);

  const mentionUserIds = mentionArr.filter(
    (a, i) => mentionArr.findIndex((s) => a === s) === i
  );

  if (mentionGroup?.length > 0) emit('update:mentionGroup', true);

  emit('update:mentionUserIds', mentionUserIds);
};

const handleUIOnFocus = () => {
  let elementPlaceholder = taskCommentInputRef.value?.querySelector(
    '.ck-placeholder'
  ) as HTMLElement;

  if (!elementPlaceholder) return;

  elementPlaceholder.setAttribute(
    'data-placeholder',
    translate('COMMON_LABEL_PLACEHOLDER_SHIFT_ENTER_COMMENT_TASK')
  );
};

const handleUIOnNotFocus = () => {
  let elementPlaceholder = taskCommentInputRef.value?.querySelector(
    '.ck-placeholder'
  ) as HTMLElement;
  if (!elementPlaceholder) return;

  elementPlaceholder.setAttribute(
    'data-placeholder',
    props.placeholder || translate('COMMON_LABEL_PLACEHOLDER_COMMENT_TASK')
  );
};

nextTick(() => {
  taskCommentInputRef.value.addEventListener('scroll', () => {
    // console.log('scroll', e);
    let elementBtnLinks = taskCommentInputRef.value?.querySelector(
      '.ck-toolbar__items'
    ) as HTMLElement;

    if (
      elementBtnLinks &&
      taskCommentInputRef.value?.scrollHeight -
        taskCommentInputRef.value.scrollTop <=
        369 &&
      isOpenFull.value
    ) {
      elementBtnLinks.style.visibility = 'visible';
    } else {
      elementBtnLinks.style.visibility = 'hidden';
    }
  });
});

// window.addEventListener("keydown", (e) => {
//   if (e.keyCode == 13 && e.shiftKey) {
//     console.log('shiftKey enter');
//   }
// });

const focusToEnd = (newValue) => {
  if (!editorRef.value || !ckEditorInstance) return;

  ckEditorInstance.setData(newValue);

  ckEditorInstance.editing.view.focus();
  const model = ckEditorInstance.editing.model;
  const document = model.document;
  model.change((writer) => {
    writer.setSelection(writer.createPositionAt(document.getRoot(), 'end'));
  });
};
const focusToInput = () => {
  setTimeout(() => {
    ckEditorInstance.editing.view.focus();
  });
};

const insertEmoji = (emoji) => {
  if (!editorRef.value) return;
  editorRef.value?.model.change((writer) => {
    writer.insertText(
      emoji,
      editorRef.value?.model.document.selection.getFirstPosition()
    );
  });

  ckEditorInstance.editing.view.focus();
};

const onInsertText = (text) => {
  if (!editorRef.value || !editorRef.value?.model || !text) return;
  editorRef.value?.model.change((writer) => {
    if (props.modelValue) {
      writer.insertElement(
        'paragraph',
        editorRef.value?.model.document.selection.getLastPosition(),
        'end'
      );
    }
    writer.insertText(
      text || '',
      editorRef.value?.model.document.selection.getLastPosition(),
      'end'
    );
  });
};

const loadCommentCacheByTaskIds = (taskId) => {
  // console.log('🚀 Hyrin ~ loadCommentCacheByTaskIds ~ taskId', taskId);
  const cacheData = commentCacheByTaskIds.value[taskId];

  // isOpenFull.value = true;

  emit('update:cache', cacheData);

  if (!cacheData) return;

  emit('update:modelValue', cacheData?.content);
  // ckEditorInstance?.setData(cacheData?.content);

  emit('update:mentionUserIds', cacheData?.mentionUserIds);

  attachmentFiles.value = [...cacheData?.attachmentFiles];

  noteList.value = [...cacheData?.noteList];
};

const onEditorReady = (editor) => {
  ckEditorInstance = editor;
  editorRef.value = markRaw(editor);
  editor.commands.get('shiftEnter').execute = function () {
    editor.commands.get('enter').execute(); // Call the enter command instead
  };
  editor.keystrokes.set('Enter', (evt, stop) => {
    stop();
    emit('update:modelValue', editor.getData());
    onSaveCommentInput();
  });
  editor.editing.view.document.on(
    'enter',
    (evt, data) => {
      if (!data?.isSoft) {
        // Stop executing next callbacks.
        evt.stop();
        // Prevent the default event action.
        data.preventDefault();
      }
    },
    { priority: 'high' }
  );

  ckEditorInstance.model.change((writer) => {
    writer.setSelection(editor.model.document.getRoot(), 'end');
  });

  if (props.isAutoFocus) {
    ckEditorInstance.editing.view.focus();
    emit('onOpen');
  }

  editor.editing.view.document.on('keydown', (evt) => {
    emit('onKeydown', evt);
  });

  totalCharacters.value = editor.plugins.get('WordCount')?.characters;

  editor.plugins.get('WordCount').on('update', (evt, stats) => {
    totalCharacters.value = stats.characters;
    emit('update:isValid', totalCharacters.value <= props.maxLength);
  });

  ckEditorInstance.editing.view.document.on(
    'change:isFocused',
    (evt, data, isFocused) => {
      if (isFocused) {
        handleUIOnFocus();
      } else {
        handleUIOnNotFocus();
      }
    }
  );
  if (props.taskId) loadCommentCacheByTaskIds(props.taskId);
};

const onChange = (messageText) => {
  emptyWarning.value = false;

  emit('update:modelValue', messageText);
  saveCommentCacheByTaskIds(props.taskId, messageText);
  handleGetLinkFromMsg(messageText);
  calcListCommandsActive();
};

const onOpenFiles = (file) => {
  // const file = attachments.value[index];
  if (file.isNote) {
    onOpenCreateNote(file);
    return;
  }

  previewFile.value = file;
};

const onClickContainer = (event) => {
  event?.stopPropagation();
  editorRef.value?.focus();
};

const onCloseTools = () => {
  isOpenFull.value = false;
};

const onClose = () => {
  // console.log('props.modelValue', props.modelValue);
  // if (props.modelValue) return;
  isOpenFull.value = false;
  resetForm();
  emit('onClose');
};

const emptyWarning = ref<boolean>(false);

const onUpdateAttachments = () => {
  emit('update:attachments', {
    files: attachmentFiles.value?.filter(
      (file) => file instanceof File || file.editStatus === 'ADDED'
    ),
    notes: noteList.value?.filter((note) => note.editStatus === 'ADDED'),
  });
};
const onSaveCommentInput = () => {
  // console.log('onSaveCommentInput', props.modelValue.trim()?.length);
  if (isDisabledSent.value) return;
  if (
    props.modelValue.trim()?.length == 0 &&
    attachmentFiles.value?.length == 0 &&
    noteList.value?.length == 0
  ) {
    emptyWarning.value = true;
    setTimeout(() => {
      emptyWarning.value = false;
    }, 1500);
    return;
  }

  emit('onSave', {
    //files
    addedFiles: attachmentFiles.value?.filter(
      (file) => file instanceof File || file.editStatus === 'ADDED'
    ),
    removedFiles: attachmentFiles.value?.filter(
      (file) => file.editStatus === 'REMOVED'
    ),
    //notes
    addedNotes: noteList.value?.filter((note) => note.editStatus === 'ADDED'),
    editedNotes: noteList.value?.filter((note) => note.editStatus === 'EDITED'),
    removedNotes: noteList.value?.filter(
      (note) => note.editStatus === 'REMOVED'
    ),
  });

  onClose();
};

const resetForm = () => {
  attachmentFiles.value = [];
  noteList.value = [];

  setTimeout(() => {
    document.getElementById('clear-focus-on-input')?.focus();
  });
};

const onPasteFiles = async (event) => {
  console.log('🚀 Tictop 1 1221 2121  ~ event:', event);
  // console.log('taskcommentInput');
  var files = (event.clipboardData || event.originalEvent.clipboardData).files;

  if (!files || files?.length == 0) return;

  // 1. if image file => open note
  if (files?.length == 1 && files[0].type.includes('image')) {
    onOpenCreateNote(null, true, files);
    pastedImage.value = files[0];
    return;
  }

  // 2. If not image file => attachment

  onAddFiles(files);

  onUpdateAttachments();
};

const onCloseNote = () => {
  // console.log('onCloseNote');
  isOpenNote.value = false;
  pastedImage.value = null;
};

const saveCommentCacheByTaskIds = (taskId, content) => {
  _taskCommentStore.updateCommentCacheByTaskIds(taskId, {
    content: content,
    mentionUserIds: props.mentionUserIds,
    attachmentFiles: [...toRaw(attachmentFiles.value)],
    noteList: toRaw(noteList.value),
  });
};

const onOpenMoreAction = (isOpen) => {
  isOpenFull.value = isOpen;
  if (isOpenFull.value) {
    let elementBtnLinks = taskCommentInputRef.value?.querySelector(
      '.ck-toolbar__items'
    ) as HTMLElement;
    if (elementBtnLinks) elementBtnLinks.style.visibility = 'visible';
  }
};

const onChooseFilesComment = ($event) => {
  onFocus();
  onChooseFiles($event);
  saveCommentCacheByTaskIds(props.taskId, props.modelValue);

  onUpdateAttachments();
};

const onSaveNoteComment = (nodeData) => {
  onFocus();

  pastedImage.value = null;
  onSaveNote(nodeData);
  saveCommentCacheByTaskIds(props.taskId, props.modelValue);

  onUpdateAttachments();
};
const onSaveAudio = (data) => {
  onFocus();

  onCreateAudioDone(data);

  saveCommentCacheByTaskIds(props.taskId, props.modelValue);

  onUpdateAttachments();
};

const doNotCheckFunctionPermission = ref<number>(0);

const onFocus = () => {
  onOpenMoreAction(true);
  emit('onFocus');
};
const onBlur = () => {
  if (isOpenRecording.value || isOpenNote.value) return;
  emit('blur');
  if (
    !props.isOpenFullMode &&
    !props.modelValue &&
    allAttachments.value?.length == 0
  )
    onOpenMoreAction(false);
};
const onContinue = () => {
  doNotCheckFunctionPermission.value = 1;
  onFocus();
  focusToInput();
};

onUnmounted(() => {
  // saveCommentCacheByTaskIds(props.taskId);
});

const isDisabledSent = computed<boolean>(() => {
  return (
    isNotValid.value ||
    props.isLoadingSend ||
    (props.modelValue?.trim()?.length < 1 && allAttachments.value?.length < 1)
  );
});

const onOpenSelectFile = () => {
  const el = document.getElementById(taskCommentFileInputId);
  if (el) el?.click();
};

defineExpose({
  focusToEnd,
  insertEmoji,
  onInsertText,
  onClose,
  resetForm,
  onCloseTools,
  focusToInput,
  onSaveCommentInput,
});
</script>
<template>
  <div
    v-click-outside="onBlur"
    class="flex-1 min-w-0 flex flex-col h-full w-full relative"
  >
    <span
      v-if="emptyWarning"
      class="text-xs italic text-orange-500 px-1 absolute left-0 -top-4 z-10"
    >
      {{ $t('COMMON_LABEL_ENTER_CONTENT') }}
    </span>

    <div
      ref="taskCommentInputRef"
      class="
        task-comment-input
        relative
        cursor-text
        bg-white
        overflow-hidden
        h-full
        w-full
        flex-col
      "
      :class="{
        'border-red-500 border': isNotValid || emptyWarning,
        'border-current-200 border':
          !isNotValid && editMode === 'CREATE' && !emptyWarning,
        'ck-hidden-toolbar__items': !isOpenFull,
      }"
      @click.stop="onClickContainer"
      @paste.stop="onPasteFiles"
    >
      <!-- Files -->
      <section
        v-if="allAttachments?.length > 0"
        ref="attachmentContainerRef"
        class="
          w-full
          items-center
          flex
          overflow-x-auto
          small-scrollbar
          gap-2
          p-4
        "
      >
        <div
          v-for="file in allAttachments?.filter(
            (f) => f?.editStatus !== 'REMOVED'
          )"
          :key="file?.localId"
          class="relative w-max h-16 hover-to-hidden__parent"
        >
          <UploadPreview
            class="m-0"
            :file="file"
            @on-select="onOpenFiles(file)"
            @remove="file.isNote ? onRemoveNote(file) : onRemoveFile(file)"
          />
        </div>
      </section>
      <div
        :key="doNotCheckFunctionPermission"
        v-permission-function="{
          functionCode: doNotCheckFunctionPermission ? '' : functionCode,
          onContinue: onContinue,
        }"
        class="flex items-end flex-row w-full"
      >
        <div class="w-full h-full flex-1">
          <Ckeditor
            :model-value="modelValue"
            :editor="classicEditor"
            :config="editorConfig"
            class="ckeditor-input w-full"
            @ready="onEditorReady"
            @update:model-value="onChange"
            @focus="onFocus"
          />
        </div>
        <div
          v-if="editMode !== 'EDIT' && !isOpenFull"
          class="h-full flex-center p-2 gap-2"
        >
          <div class="flex-center gap-1">
            <div
              :title="
                $t('TASK_CREATE_FORM_LABEL_ADD_ATTACHMENT') || 'Add attachment'
              "
              class="
                w-6
                h-6
                rounded
                cursor-pointer
                flex-center
                hover:text-current hover:fill-current hover:bg-current-50
              "
              @click.stop.prevent="onOpenSelectFile"
            >
              <SynIcon
                name="attach"
                custom-class="fill-current-500 text-current-500 h-3.5 w-3.5"
              />
            </div>
            <div
              class="
                w-6
                h-6
                rounded
                cursor-pointer
                flex-center
                hover:bg-current-50
              "
              @click.stop.prevent="onOpenCreateNote"
            >
              <SynIcon
                name="noter"
                is-active
                custom-class="fill-orange-500 h-3.5 w-3.5"
                :title="$t('TASK_CREATE_FORM_LABEL_ADD_NOTE') || 'Add note'"
              />
            </div>
            <div
              class="
                w-6
                h-6
                rounded
                cursor-pointer
                flex-center
                hover:bg-current-50
              "
              @click.stop.prevent="isOpenRecording = true"
            >
              <SynIcon
                :title="$t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'"
                is-active
                name="microphone"
                custom-class="fill-blue-500 text-blue-500 h-3.5 w-3.5"
              />
            </div>
          </div>
          <!-- <div
            v-vig-tooltip="$t('COMMON_LABEL_TOOL')"
            class="w-8 h-8 rounded border cursor-pointer flex-center"
            :class="
              isOpenFull
                ? 'border-current-50 fill-current-500 text-current-500 bg-current-50'
                : 'border-gray-100 fill-gray-500 text-gray-500 bg-gray-50'
            "
            @click="onOpenMoreAction()"
          >
            <SynIcon name="editor-text" custom-class="w-4 h-4 " />
          </div> -->
          <SynButton
            v-if="
              !isHiddenSaveButton &&
              (modelValue || allAttachments?.length > 0) &&
              !isOpenFull
            "
            name-icon="send"
            :disabled="isLoadingSend || isNotValid"
            :is-loading="isLoadingSend"
            custom-class="w-8 h-8 rounded px-1"
            @click.stop="onSaveCommentInput"
          />
        </div>
      </div>
      <div
        class="more-tool"
        :style="
          isOpenFull
            ? 'opacity: 100; display: block;'
            : 'opacity: 0; display: none;'
        "
      >
        <!-- actions button list -->
        <section
          class="px-2 pt-2 pb-1 flex items-end justify-between bg-white"
          style="bottom: 1.1rem"
        >
          <div class="pl-10 flex-center">
            <CkeditorCustomAction
              :action-list="actionList"
              :command-active="listCommandsActive"
              @execute="execCommand"
            >
              <template #moreAction>
                <div class="flex-center gap-1">
                  <div
                    :title="
                      $t('TASK_CREATE_FORM_LABEL_ADD_ATTACHMENT') ||
                      'Add attachment'
                    "
                    class="
                      w-6
                      h-6
                      rounded
                      cursor-pointer
                      flex-center
                      hover:bg-current-50 hover:text-current hover:fill-current
                    "
                    @click.stop.prevent="onOpenSelectFile"
                  >
                    <SynIcon
                      name="attach"
                      custom-class="fill-current-500 text-current-500 h-3.5 w-3.5"
                    />
                  </div>
                  <div
                    class="
                      w-6
                      h-6
                      rounded
                      cursor-pointer
                      flex-center
                      hover:bg-current-50
                    "
                    @click.stop.prevent="onOpenCreateNote"
                  >
                    <SynIcon
                      name="noter"
                      is-active
                      custom-class="fill-orange-500 h-3.5 w-3.5"
                      :title="
                        $t('TASK_CREATE_FORM_LABEL_ADD_NOTE') || 'Add note'
                      "
                    />
                  </div>
                  <div
                    class="
                      w-6
                      h-6
                      rounded
                      cursor-pointer
                      flex-center
                      hover:bg-current-50
                    "
                    @click.stop.prevent="isOpenRecording = true"
                  >
                    <SynIcon
                      :title="$t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'"
                      is-active
                      name="microphone"
                      custom-class="fill-blue-500 text-blue-500 h-3.5 w-3.5"
                    />
                  </div>
                </div>
              </template>
            </CkeditorCustomAction>
          </div>
          <div
            v-if="isAction && editMode === 'EDIT'"
            class="flex-center space-x-2 text-xs"
          >
            <SynButton
              :label="$t('COMMON_LABEL_CLOSE') || 'Close'"
              :custom-class="
                editMode === 'CREATE' ? 'rounded' : 'rounded px-2 py-1'
              "
              type-text
              color="gray"
              :disabled="isLoadingSend"
              @click.stop="onClose"
            />
            <SynButton
              :label="
                editMode === 'EDIT'
                  ? $t('COMMON_LABEL_UPDATE') || 'Update'
                  : $t('COMMON_LABEL_SEND') || 'Send'
              "
              :name-icon="editMode === 'EDIT' ? '' : 'send'"
              :custom-class="
                editMode === 'CREATE' ? 'rounded' : 'rounded px-2 py-1'
              "
              :is-loading="isLoadingSend"
              :disabled="isDisabledSent"
              @click.stop="onSaveCommentInput"
            />
          </div>
          <template v-else-if="editMode === 'CREATE'">
            <SynButton
              v-if="!isHiddenSaveButton"
              name-icon="send"
              :disabled="
                isLoadingSend ||
                isNotValid ||
                (!modelValue && allAttachments?.length == 0)
              "
              :is-loading="isLoadingSend"
              custom-class="w-8 h-8 rounded px-1"
              @click.stop="onSaveCommentInput"
            />
          </template>
        </section>
      </div>
    </div>
    <span v-if="isNotValid" class="text-xs text-red-500 italic">{{
      $t('TASK_COMMENT_ALERT_MAX_LENGTH', {
        number: `${totalCharacters}/ ${maxLength}`,
      })
    }}</span>
    <input
      :id="taskCommentFileInputId"
      type="file"
      multiple
      class="hidden opacity-0"
      :accept="webTaskUploadContentTypes"
      @change="onChooseFilesComment"
    />
    <input
      id="clear-focus-on-input"
      autocomplete="off"
      class="absolute w-0 h-0 p-0"
    />
  </div>

  <CreateNoteModal
    v-if="isOpenNote"
    :draw-note-data="drawNoteData"
    :pasted-image="pastedImage"
    @on-close="onCloseNote"
    @on-save="onSaveNoteComment"
  />

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onSaveAudio"
    @on-cancel="isOpenRecording = false"
  />
  <ModalFileViewer
    v-if="previewFile?.fileUrl"
    :file="previewFile"
    :path="previewFile?.fileUrl"
    :content-type="previewFile?.type"
    :name="previewFile?.name"
    @on-close="previewFile = null"
  />
</template>

<style lang="scss">
@import '@/ui/plugins/ckeditor/css/content-styles.scss';

.ck-hidden-toolbar__items {
  .ck.ck-toolbar {
    display: none;
  }
}

.focus {
  @apply focus:border-current focus:ring focus:ring-current focus:ring-opacity-30;
}

.task-comment-input {
  transition: height 0.2s ease-in-out !important;
  .ck.ck-editor {
    position: static;
  }

  .more-tool {
    transition: all 0.3s ease-in-out !important;
  }

  .hide-item {
    display: none;
  }

  .show-item {
    display: block;
  }

  .ck-toolbar__items {
    @apply w-6 h-6;
    position: absolute;
    bottom: 3px;
  }

  .ck-button {
    margin-bottom: 6px !important;
    height: 26px !important;
    width: 26px !important;
    cursor: pointer !important;
    border-radius: 0.375rem !important;
    background-color: #fff !important;
    padding: 0px !important;
    min-width: 0;
    min-height: 0;
    border: 0;
  }

  .ck-button:hover {
    background-color: rgb(224 241 242 / 1) !important;
  }

  .ck.ck-button:not(.ck-disabled):hover {
    @apply bg-current-50 rounded-md;
  }

  @apply leading-6 rounded-md w-full;

  ol {
    list-style: decimal;

    li {
      margin-left: 1rem;
    }
  }

  a {
    @apply text-current;
  }

  a:hover {
    @apply underline;
  }

  //.ck.ck-editor__main {
  //  @apply flex-1;
  //}

  .ck.ck-reset.ck-editor.ck-rounded-corners {
    @apply h-full;
    min-height: 3rem;
  }

  .ck.ck-editor__editable_inline {
    @apply pr-2 pl-4 leading-6 w-full border-current-300 text-gray-600;
  }

  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    @apply bg-white border-0 rounded-md shadow-none h-full;
  }

  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    @apply border-gray-300;
  }

  .ck.ck-reset.ck-editor.ck-rounded-corners.ck-rounded-corners {
    @apply flex flex-col-reverse border-0;
  }

  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    @apply border-0 rounded-b-md bg-white pl-4 opacity-80;
  }

  .ck.ck-content {
    word-break: break-word;
    scrollbar-width: thin !important;
  }

  .ck.ck-content a {
    @apply text-current-500;
  }

  .ck-content .mention {
    @apply bg-blue-50 text-current-500;
  }
}

.task-detail_description {
  @apply leading-6 text-gray-600;
  // ul,
  // menu {
  //   list-style: none;

  //   li {
  //     margin-left: 1rem;
  //   }

  //   li::before {
  //     content: '•';
  //     margin-right: 0.5rem;
  //   }
  // }

  ol {
    list-style: decimal;

    li {
      margin-left: 1rem;
    }
  }
}
</style>
