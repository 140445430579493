<script setup lang="ts">
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import ItemListCollapse from '@/ui/components/contact-help/helps/ItemListCollapse.vue';
import helpComposables from '@/ui/composables/help-contact/help-composables';
import appStore from '@/store/app';
import { computed } from 'vue';

withDefaults(
  defineProps<{
    sizeActive: any;
  }>(),
  {
    sizeActive: () => {},
  }
);
defineEmits(['onBack']);

const _appStore = appStore();

const currentLanguage = computed(() => _appStore.language);

const { allTopicsByIds, getAllFaqs, onShowAnswerByQuestion } =
  helpComposables();

getAllFaqs();

let timeScrollTopic;
let oldElement;

const onScrollToQnA = (idTopic) => {
  const element = document.getElementById(`${idTopic}`);
  element?.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
    inline: 'nearest',
  });

  if (timeScrollTopic) {
    oldElement?.classList.remove('topic_focus');
    clearTimeout(timeScrollTopic);
  }

  element?.classList.add('topic_focus');
  oldElement = element;

  timeScrollTopic = setTimeout(() => {
    element?.classList.remove('topic_focus');
  }, 2000);
};
</script>

<template>
  <div class="flex items-center space-x-2 text-gray-500 px-3">
    <button
      class="
        flex
        items-center
        space-x-1
        px-2
        py-1
        fill-gray-500
        bg-gray-50
        rounded
        hover:bg-gray-100
        text-sm
      "
      @click="$emit('onBack')"
    >
      <SynIcon name="arrow-left" />
      <span>{{ $t('COMMON_LABEL_BACK') }}</span>
    </button>
    <span>|</span>
    <span class="text-xl font-medium text-gray-700">{{
      $t('HELP_VIDEO_FQA')
    }}</span>
  </div>

  <div class="my-4 flex items-center flex-wrap px-3">
    <template v-for="t in Object.values(allTopicsByIds)" :key="t">
      <div
        class="
          border border-gray-100
          text-gray-500
          px-2
          py-1
          rounded
          cursor-pointer
          hover:bg-gray-100
          text-2xs
          mr-1
          mb-1
        "
        @click="onScrollToQnA(t?.id)"
      >
        {{ t?.topic[currentLanguage] }}
      </div>
    </template>
  </div>

  <template v-for="topic in Object.values(allTopicsByIds)" :key="topic">
    <div
      :id="`${topic?.id}`"
      class="
        flex
        items-center
        space-x-1
        rounded
        text-current-400
        font-medium
        text-xs
        w-max
        mt-6
        px-3
      "
    >
      <!--      <span class="w-max rounded-full">#</span>-->
      <span>{{ `# ${topic?.topic[currentLanguage]}` }}</span>
    </div>
    <div class="flex flex-col pt-1">
      <template v-for="qna in topic?.qnas" :key="qna">
        <ItemListCollapse
          v-if="qna?.question[currentLanguage] && qna?.answer[currentLanguage]"
          :is-collapse="qna?.isCollapse"
          icon-prefix="text-question"
          :name="qna?.question[currentLanguage]"
          @on-collapse="onShowAnswerByQuestion(qna?.id, topic.id)"
        >
          <template v-if="qna?.isCollapse" #body>
            <div class="py-3 px-6 text-sm text-gray-600 bg-gray-100">
              <SynIcon
                name="text-answer"
                custom-class="w-5 h-5 fill-gray-500 mb-1"
              />
              <span
                class="ck_editor_view"
                v-html="`: ${qna?.answer[currentLanguage]}`"
              ></span>
            </div>
          </template>
        </ItemListCollapse>
      </template>
    </div>
  </template>
</template>

<style lang="scss">
.topic_focus {
  @apply border border-current-400 ring-2 ring-current-300 ring-opacity-30;
}

.ck_editor_view {
  @apply leading-6;

  ul,
  menu {
    // list-style: none;

    li {
      margin-left: 1rem;
    }

    // li::before {
    //   content: '•';
    //   margin-right: 0.5rem;
    // }
  }

  ol {
    list-style: decimal;

    li {
      margin-left: 1rem;
    }
  }

  .ck.ck-content a {
    @apply text-current-500 underline;
  }
}
</style>
