<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { isValidEmail, isValidPhoneNumber } from '@/ui/hooks/commonFunction';
import { clearLocalStorage } from '@/ui/hooks/storageHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import authStore from '@/store/auth';
import GetCode from '@/ui/pages/auth/GetCode.vue';
import SettingLanguageSmallCircle from '@/ui/components/setting/SettingLanguageSmallCircle.vue';
import { AuthenticateType } from '@/ui/common/constants/constant';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import TabContainer from '@/ui/common/molecules/SynTab/TabContainer.vue';
import {
  AuthenticateUserPassword,
  AuthenticatePhoneCode,
  AuthenticateEmailCode,
} from '@/application/entities/un-authenticate/LoginEntitiesClass';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import { AuthenticateCaseEnum } from '@/application/entities/authenticate/AuthenticateEnum';
import {
  initGoogleCaptcha,
  destroyGoogleCaptcha,
} from '@/ui/components/layouts/logic/init-google-capcha';

const router = useRouter();
const _authStore = authStore();
const codeType = ref('email');
const showPWD = ref(false);
const isShowModalError = ref(false);
const isLoadingRefresh = ref(false);
onMounted(async () => {
  _authStore.phone ? (inputPhone.value = _authStore.phone) : '';
});

onUnmounted(() => {
  destroyGoogleCaptcha();
});

const statusLogin = ref({
  FORM_INFO: {
    active: true,
    labelBtn: 'COMMON_LABEL_NEXT',
  },
  FORM_PASSWORD: {
    active: false,
    labelBtn: 'COMMON_MODULE_LOGIN',
  },
  FORM_GET_CODE: {
    active: false,
    labelBtn: '',
  },
});
const errorPassword = ref('');
const errorCode = ref('');
const isBlockLoginByCode = ref(false);
const isBlockSendPhoneCode = ref(false);

const inputPassword = ref('' as string);
const passError = ref('' as string);

const onInputPassword = () => {
  errorPassword.value = '';
};

let lastCheckingEmail;
let timer;

const inputEmail = ref('' as string);
const emailError = ref('' as string);
const isTyping = ref(true);
const isFetchChecking = ref(false);

const onInputEmail = (value) => {
  isTyping.value = true;
  if (!value) return;

  errorCode.value = '';
  isBlockLoginByCode.value = false;
  isBlockSendPhoneCode.value = false;

  if (!isValidEmail(value)) {
    errorCode.value = '';
  }

  lastCheckingEmail = value;
  emailError.value = '';

  if (timer) clearTimeout(timer);

  timer = setTimeout(async () => {
    if (!isValidEmail(value)) {
      emailError.value = translate('COMMON_INVALID_EMAIL');
      return;
    }
    isFetchChecking.value = true;
    const resCheckExist = await _authStore.checkEmail(value);
    isFetchChecking.value = false;
    if (resCheckExist?.value === lastCheckingEmail && !resCheckExist?.existed) {
      errorCode.value = translate('COMMON_LABEL_REQUIRE_EMAIL_NOT_EXISTED');
    } else {
      isTyping.value = false;
      errorCode.value = '';
    }
  }, 500);
};

const inputPhone = ref('' as string);
const phoneError = ref('' as string);
const onInputPhone = async (value) => {
  if (!value) return;
  isTyping.value = true;
  errorCode.value = '';
  phoneError.value = '';

  if (timer) clearTimeout(timer);

  timer = setTimeout(async () => {
    if (!isValidPhoneNumber(value) || value[0] !== '+') {
      isTyping.value = false;
      errorCode.value = translate('COMMON_INVALID_PHONE_NUMBER');
      return;
    }

    isFetchChecking.value = true;
    const resCheckExistPhone = await _authStore.checkPhone(value);
    isFetchChecking.value = false;
    _authStore.phone = '';

    if (!resCheckExistPhone.existed) {
      phoneError.value = translate('COMMON_LABEL_REQUIRE_PHONE_NO_REGISTERED');
    } else {
      isBlockLoginByCode.value = false;
      isBlockSendPhoneCode.value = false;
      isTyping.value = false;
    }
  }, 500);
};

const changeTypeLogin = async (type) => {
  if (type == 'email') {
    if (inputEmail.value && isValidEmail(inputEmail.value)) {
      const resCheckExist = await _authStore.checkEmail(inputEmail.value);
      if (!resCheckExist?.existed) {
        errorCode.value = translate('COMMON_LABEL_REQUIRE_EMAIL_NOT_EXISTED');
      } else {
        errorCode.value = '';
      }
    } else {
      errorCode.value = '';
    }
  } else {
    if (
      isValidPhoneNumber(inputPhone.value) &&
      inputPhone.value &&
      inputPhone.value[0] === '+'
    ) {
      const resCheckExistPhone = await _authStore.checkPhone(inputPhone.value);
      if (!resCheckExistPhone.existed) {
        errorCode.value = translate('COMMON_LABEL_REQUIRE_PHONE_NO_REGISTERED');
      } else {
        errorCode.value = '';
      }
    } else {
      errorCode.value = '';
    }
  }
  codeType.value = type;
};

const onRefreshPage = async () => {
  isLoadingRefresh.value = true;
  clearLocalStorage({ isSwitchingOrg: false });
  await window.location.reload();
  isLoadingRefresh.value = false;
};

const onPrevious = () => {
  errorCode.value = '';
  if (statusLogin.value['FORM_PASSWORD'].active) {
    statusLogin.value['FORM_PASSWORD'].active = false;
    statusLogin.value['FORM_INFO'].active = true;
  } else if (statusLogin.value['FORM_GET_CODE'].active) {
    statusLogin.value['FORM_GET_CODE'].active = false;
    statusLogin.value['FORM_INFO'].active = true;
  }
};

const onChangeMethodLogin = async () => {
  errorCode.value = '';
  inputPassword.value = '';
  errorPassword.value = '';
  passError.value = '';
  statusLogin.value['FORM_PASSWORD'].active = true;
  statusLogin.value['FORM_GET_CODE'].active = false;
};

// HANDLER FORM PHONE/EMAIL
const hasPWD = ref(false);
const onCheckPassword = async () => {
  if (isDisabledNextButtonLogin.value) return;
  inputPassword.value = '';
  isSubmitLogin.value = true;
  if (codeType.value == 'email') {
    try {
      const resCheckHas = await _authStore.checkUserHasPasswordByEmail(
        inputEmail.value
      );
      if (resCheckHas) {
        hasPWD.value = true;
        statusLogin.value['FORM_INFO'].active = false;
        statusLogin.value['FORM_PASSWORD'].active = true;
        isSubmitLogin.value = false;
      } else {
        onSendCode(false);
        isSubmitLogin.value = false;
      }
    } catch (error) {
      isSubmitLogin.value = false;
    }
  } else if (codeType.value == 'phone') {
    try {
      const res = await _authStore.checkUserHasPasswordByPhone(
        inputPhone.value
      );
      if (res) {
        hasPWD.value = true;
        statusLogin.value['FORM_INFO'].active = false;
        statusLogin.value['FORM_PASSWORD'].active = true;
        isSubmitLogin.value = false;
      } else {
        onSendCode(false);
        isSubmitLogin.value = false;
      }
    } catch (error) {
      isSubmitLogin.value = false;
    }
  }
};

const capchaTokenText = ref('');

const resetCaptcha = async (type) => {
  capchaTokenText.value = await initGoogleCaptcha(
    'g-recaptcha-phone-login',
    'captcha-login'
  );

  if (!capchaTokenText.value) return;
  if (type == 'send') {
    onSendCode(false);
  } else {
    onResendCode();
  }
};

const isGetCode = ref(false);
const sessionInfo = ref('');
const currentLanguage = ref('');
const isSubmitLogin = ref(false);

const onSendCode = async (_isGetCode) => {
  capchaTokenText.value = await initGoogleCaptcha(
    'g-recaptcha-phone-login',
    'captcha-login'
  );

  if (isBlockLoginByCode.value || isBlockSendPhoneCode.value) return;
  isSubmitLogin.value = true;
  errorCode.value = '';
  const res = ref();
  try {
    if (codeType.value == 'phone') {
      res.value = await _authStore.sendVerificationCodeByPhoneNumber({
        phoneNumber: inputPhone.value,
        type: 'sign_in',
        captchaToken: capchaTokenText.value,
        locale: currentLanguage.value || 'en',
      });
      sessionInfo.value = res.value?.result.sessionInfo;
    } else {
      res.value = await _authStore.sendVerificationCodeByEmail({
        email: inputEmail.value,
        type: 'sign_in',
        locale: currentLanguage.value || 'en',
      });
    }
    statusLogin.value['FORM_INFO'].active = false;
    statusLogin.value['FORM_PASSWORD'].active = false;
    statusLogin.value['FORM_GET_CODE'].active = true;
    isGetCode.value = _isGetCode;
    isSubmitLogin.value = false;
  } catch (response: any) {
    if (response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      resetCaptcha('send');
    } else if (
      response.error.message == ErrorNotificationLogin.SEND_VERIFICATION_FAILED
    ) {
      parserErrorMessage(response);
      isShowModalError.value = true;
      isSubmitLogin.value = false;
    } else {
      isSubmitLogin.value = false;
      parserErrorMessage(response);
    }
  }
};

const onResendCode = async () => {
  capchaTokenText.value = await initGoogleCaptcha(
    'g-recaptcha-phone-login',
    'captcha-login'
  );

  if (isBlockLoginByCode.value || isBlockSendPhoneCode.value) return;
  isSubmitLogin.value = true;
  errorCode.value = '';
  const res = ref();
  try {
    if (codeType.value == 'email') {
      res.value = await _authStore.reSendVerificationCodeByEmail({
        email: inputEmail.value,
        type: 'sign_in',
        locale: currentLanguage.value || 'en',
      });
      isSubmitLogin.value = false;
    } else {
      res.value = await _authStore.reSendVerificationCodeByPhoneNumber({
        phoneNumber: inputPhone.value,
        captchaToken: capchaTokenText.value,
        type: 'sign_in',
        locale: currentLanguage.value || 'en',
      });
      sessionInfo.value = res.value?.result.sessionInfo;
      isSubmitLogin.value = false;
    }
  } catch (response: any) {
    if (response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      resetCaptcha('resend');
    } else if (
      response.error.message == ErrorNotificationLogin.SEND_VERIFICATION_FAILED
    ) {
      parserErrorMessage(response);
      isShowModalError.value = true;
      isSubmitLogin.value = false;
    } else {
      isSubmitLogin.value = false;
      parserErrorMessage(response);
    }
  }
};

const parserErrorMessage = (response) => {
  const responseMsg = response.error.message;
  isBlockLoginByCode.value =
    responseMsg == ErrorNotificationLogin.LOGIN_BY_CODE_BLOCKED;
  isBlockSendPhoneCode.value =
    responseMsg == ErrorNotificationLogin.SEND_PHONE_CODE_BLOCKED;
  errorCode.value = translate(ErrorNotificationLogin[responseMsg]);
  isSubmitLogin.value = false;
};

const onSignInWithPassword = async () => {
  isSubmitLogin.value = true;
  let acountName = inputPhone.value;
  if (codeType.value == 'email') acountName = inputEmail.value;
  try {
    const authenUserPass = new AuthenticateUserPassword(
      acountName,
      inputPassword.value,
      AuthenticateType.EmailPhonePassword,
      acountName
    );
    const res = await authenUserPass.login();

    isSubmitLogin.value = false;
    handleLoginSuccess(res);
  } catch (response: any) {
    errorPassword.value = translate(
      ErrorNotificationLogin[response.error.message]
    );
    isSubmitLogin.value = false;
  }
};

const isLoadingGetCode = ref(false);

const onLoginWithCodePhone = async (code) => {
  errorCode.value = '';
  isLoadingGetCode.value = true;
  try {
    const authenPhoneCode = new AuthenticatePhoneCode(
      inputPhone.value,
      code,
      sessionInfo.value
    );

    const res = await authenPhoneCode.login();

    isLoadingGetCode.value = false;

    // setLocalStorage('numberOfLogins', result.numberOfLogins);

    handleLoginSuccess(res);
  } catch (response) {
    parserErrorMessage(response);
    isLoadingGetCode.value = false;
  }
};

const onLoginWithCodeEmail = async (code) => {
  try {
    isLoadingGetCode.value = true;
    const authenEmailCodeClass = new AuthenticateEmailCode(
      inputEmail.value,
      code
    );
    const res = await authenEmailCodeClass.login();
    isLoadingGetCode.value = false;

    handleLoginSuccess(res);
  } catch (response) {
    parserErrorMessage(response);
    isLoadingGetCode.value = false;
  }
};

const isSelectOrganization = ref(false);
const myOrganizations = ref([] as any[]);

const loginErrorMessage = ref('');

const handleLoginSuccess = async (result) => {
  if (!result) return;

  switch (result.caseKey) {
    case AuthenticateCaseEnum.SelectOrganizationRequired:
      {
        isSelectOrganization.value = true;
        myOrganizations.value = result?.organizations;
      }
      break;

    case AuthenticateCaseEnum.HaveNotOrganization:
      loginErrorMessage.value =
        'You have not organization. Please go to tictop.app and continue.';
      break;

    case AuthenticateCaseEnum.WaitingForActivation:
      loginErrorMessage.value =
        'Your organization is not active. Please go to tictop.app and continue.';

      break;

    case AuthenticateCaseEnum.SettingTeam:
      loginErrorMessage.value =
        'You are not finished setting organization progress. Please go to tictop.app and continue.';

      break;

    case AuthenticateCaseEnum.SettingFinished:
      {
        router.push({
          path: '/embed',
        });
      }
      break;

    default:
      break;
  }
};

const onChangePhone = (phoneObj, phoneN) => {
  if (!phoneObj || !phoneN || phoneObj?.number == inputPhone.value) return;
  inputPhone.value = phoneN;

  if (phoneObj?.number) {
    const local = phoneObj?.countryCode?.toLowerCase();
    currentLanguage.value = local == 'vn' ? 'vi' : local;
    inputPhone.value = phoneObj.number;
  }
  if (!inputPhone.value?.startsWith('+')) return;

  onInputPhone(inputPhone.value);
};

const selectedOrgId = ref(0 as number);
const onSelectOrganization = async (org) => {
  selectedOrgId.value = org.id;
};

const onLoginAfterSelectOrganization = async () => {
  if (!selectedOrgId.value) return;

  const authenSelectOrganization = new AuthenticateSelectOrganization(
    selectedOrgId.value
  );

  isSubmitLogin.value = true;
  const res = await authenSelectOrganization.login();
  isSubmitLogin.value = false;

  handleLoginSuccess(res);
};

const isDisabledNextButtonLogin = computed(
  () =>
    isTyping.value ||
    errorCode.value ||
    emailError.value ||
    phoneError.value ||
    (!inputEmail.value && codeType.value == 'email') ||
    (!inputPhone.value && codeType.value == 'phone')
);

const currentTabId = ref(3);
const currentTabs = [
  {
    id: 1,
    name: 'Ticket',
    routerName: 'TicketTasksNew',
  },
  {
    id: 2,
    name: 'Histories',
    nameCode: 'COMMON_LABEL_HISTORIES',
    routerName: 'TicketTasksHistories',
  },
  {
    id: 3,
    name: 'My page',
    nameCode: 'COMMON_LABEL_MY_PAGE',
    routerName: 'EmbedPage',
  },
];

const onChangeTab = (tab) => {
  currentTabId.value = tab.id;

  router.push({ name: tab?.routerName });
};
</script>

<template>
  <div class="h-screen w-screen relative flex-center">
    <tab-container>
      <template #header>
        <div class="w-full flex-center bg-gray-200">
          <ul class="w-full flex-center cursor-pointer text-sm">
            <li
              v-for="tab in currentTabs"
              :key="tab.id"
              :class="
                tab.id == currentTabId
                  ? 'bg-white font-semibold text-current'
                  : 'text-current-900 bg-gray-200 hover:bg-gray-100'
              "
              class="hover-to-show__parent flex-center px-4 py-1"
              @click="onChangeTab(tab)"
            >
              {{ $t(tab.nameCode) || tab.name }}sudo systemctl restart nginx
            </li>
          </ul>
        </div>
      </template>
      <template #actions> <div></div> </template>
      <template #body>
        <div class="flex-1 min-h-0 flex-center flex-col w-full bg-current-50">
          <div class="p-6 flex justify-between w-full h-max relative">
            <div>
              <div v-if="!statusLogin['FORM_INFO'].active" class="w-8 h-8">
                <SynIcon
                  name="back"
                  class="fill-current text-current"
                  has-action
                  @click="onPrevious"
                />
              </div>

              <a
                v-else
                class="
                  flex-center
                  space-x-2
                  text-sm
                  hover:text-current
                  font-medium
                "
                href="http://tictop.vn"
                target="_blank"
              >
                <SynIcon
                  name="global"
                  custom-class="fill-current text-current w-4 h-4"
                />
                <span> tictop.vn </span>
              </a>
            </div>
            <SettingLanguageSmallCircle is-hidden-label />
          </div>

          <div class="flex-1 min-h-0 flex flex-col py-4">
            <div class="flex flex-col mb-4">
              <syn-logo class-logo="h-14" class-label="w-32" />
            </div>
            <!-- SELECT ORG -->
            <div
              v-if="isSelectOrganization"
              class="
                flex-1
                min-h-0
                overflow-y-auto
                small-scrollbar
                flex flex-col
              "
            >
              <div>{{ $t('ORGANIZATION_LABEL_SELECT_ORGANIZATION') }}:</div>
              <div class="flex flex-col space-y-2">
                <div
                  v-for="(org, index) in myOrganizations"
                  :key="index"
                  class="w-full rounded-md hover:bg-current-100 p-4"
                  :class="
                    selectedOrgId == org.id
                      ? 'bg-current-400 hover:bg-current-400 text-white'
                      : ''
                  "
                  @click="onSelectOrganization(org)"
                >
                  <syn-avatar
                    has-name
                    custom-class="w-10 h-10"
                    :src="org?.logoUrl"
                    :name="org.name"
                  />
                </div>
              </div>
            </div>
            <div v-if="isSelectOrganization" class="text-center w-full pt-4">
              <syn-button
                class="w-full m-auto"
                :is-loading="isSubmitLogin"
                :disabled="!selectedOrgId"
                :label="$t(statusLogin['FORM_INFO'].labelBtn)"
                @click="onLoginAfterSelectOrganization"
              >
              </syn-button>
            </div>
            <!-- LOGIN -->
            <section v-else>
              <div
                class="
                  flex
                  content-center
                  text-center
                  justify-center
                  w-full
                  my-1
                  font-roboto font-thin
                  text-3xl
                  mb-8
                "
              >
                {{
                  !isGetCode
                    ? $t('LOGIN_LABEL_HEADER')
                    : $t('LOGIN_LABEL_HEADER_VERIFY_CODE')
                }}
              </div>

              <!-- FORM LOGIN -->
              <section v-if="statusLogin['FORM_INFO'].active">
                <div class="w-full flex mb-6 space-x-1 text-sm">
                  <div
                    class="
                      w-full
                      flex-center flex-wrap
                      text-center
                      cursor-pointer
                      rounded-t-lg
                      hover:bg-current-50 hover:bg-opacity-40
                    "
                    :class="
                      codeType == 'email'
                        ? 'bg-current-50 bg-opacity-40 text-medium text-current-600'
                        : 'font-thin text-gray-600'
                    "
                    @click="changeTypeLogin('email')"
                  >
                    <div class="py-2 w-full">{{ $t('LOGIN_FORM_EMAIL') }}</div>
                    <div
                      class="w-full rounded-full"
                      :class="
                        codeType == 'email'
                          ? 'p-0.5 bg-current'
                          : 'p-px bg-gray-300'
                      "
                    ></div>
                  </div>
                  <div
                    class="
                      w-full
                      flex-center flex-wrap
                      text-center
                      cursor-pointer
                      rounded-t-lg
                      hover:bg-current-50 hover:bg-opacity-40
                    "
                    :class="
                      codeType == 'phone'
                        ? 'bg-current-50 bg-opacity-40 text-medium text-current-600'
                        : 'font-thin text-gray-600'
                    "
                    @click="changeTypeLogin('phone')"
                  >
                    <div class="py-2 w-full">
                      {{ $t('COMMON_LABEL_PHONE') }}
                    </div>
                    <div
                      class="w-full rounded-full"
                      :class="
                        codeType == 'phone'
                          ? 'p-0.5 bg-current'
                          : 'p-px bg-gray-300'
                      "
                    ></div>
                  </div>
                </div>

                <div
                  class="
                    relative
                    flex flex-col
                    min-w-0
                    break-words
                    w-full
                    mb-6
                    rounded-lg
                    border-0
                    lg:items-center
                  "
                >
                  <div class="flex-auto py-2 pt-0 w-full">
                    <div v-if="codeType == 'email'">
                      <!-- EMAIL -->
                      <div class="relative w-full mb-3 mt-3">
                        <syn-form-input
                          :error-message="errorCode ? errorCode : emailError"
                          :is-checking="isFetchChecking"
                          :is-valid="!isDisabledNextButtonLogin"
                        >
                          <div
                            class="
                              relative
                              text-gray-600
                              focus-within:text-gray-800
                              w-full
                              border
                              rounded-md
                              py-0.4
                            "
                          >
                            <vig-input-email
                              id="login-email"
                              v-model="inputEmail"
                              class="
                                bg-white
                                w-full
                                h-10
                                focus:outline-none
                                pr-6
                                pl-12
                                text-sm
                                rounded-md
                                vig-input
                                placeholder-gray-500 placeholder-opacity-75
                              "
                              :class="[
                                emailError ? 'border border-red-500' : '',
                              ]"
                              autofocus
                              :placeholder="$t('SIGN_UP_LABEL_HEADER_EMAIL')"
                              @keydown.enter="onCheckPassword"
                              @update:model-value="onInputEmail"
                            />
                            <div
                              class="
                                absolute
                                inset-y-0
                                left-4
                                flex
                                items-center
                                h-full
                                focus:outline-none focus:shadow-outline
                              "
                            >
                              <SynIcon
                                name="Email"
                                :class="{
                                  'fill-red-500 text-red-500': emailError,
                                }"
                              />
                            </div>
                          </div>
                        </syn-form-input>
                      </div>
                    </div>
                    <div v-else>
                      <!-- PHONE -->
                      <div class="relative w-full mb-3 mt-3">
                        <syn-form-input
                          :error-message="errorCode ? errorCode : phoneError"
                          :is-checking="isFetchChecking"
                          :is-valid="!isDisabledNextButtonLogin"
                        >
                          <SynTelInput
                            id="login-phone"
                            autofocus
                            :value="inputPhone"
                            :input-options="{
                              placeholder: $t('COMMON_LABEL_MOBILE_PHONE'),
                            }"
                            :auto-default-country="false"
                            @input="onChangePhone"
                            @enter="onCheckPassword"
                          />
                        </syn-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="text-center w-full pt-4">
                    <syn-button
                      class="w-full m-auto"
                      :is-loading="isSubmitLogin"
                      :disabled="isDisabledNextButtonLogin"
                      :label="$t(statusLogin['FORM_INFO'].labelBtn)"
                      @click="onCheckPassword"
                    >
                    </syn-button>
                  </div>
                </div>
              </section>

              <!-- PASSWORD -->
              <section v-if="statusLogin['FORM_PASSWORD'].active">
                <div class="relative w-full mb-3">
                  <syn-form-input
                    :error-message="errorPassword ? errorPassword : passError"
                  >
                    <syn-input
                      id="login-password"
                      v-model="inputPassword"
                      class="w-full"
                      autofocus
                      icon-prefix="lock"
                      :icon-right="showPWD ? 'eye' : 'eye-close'"
                      :input-type="showPWD ? 'text' : 'password'"
                      :placeholder="$t('LOGIN_FORM_PASSWORD')"
                      :error-message="passError"
                      @on-action="showPWD = !showPWD"
                      @enter="onSignInWithPassword"
                      @update:model-value="onInputPassword"
                    />
                  </syn-form-input>
                </div>

                <div class="w-full flex justify-end">
                  <small
                    :class="
                      isBlockLoginByCode || isBlockSendPhoneCode
                        ? 'cursor-not-allowed text-gray-400'
                        : 'text-current cursor-pointer hover:text-current-700'
                    "
                    @click="onSendCode(false)"
                  >
                    {{ $t('COMMON_LABEL_LOGIN_WITH_CODE') }}
                  </small>
                </div>
                <div class="w-full mt-4">
                  <syn-button
                    class="w-full m-auto"
                    :is-loading="isSubmitLogin"
                    :disabled="!inputPassword || inputPassword.length < 6"
                    :label="$t(statusLogin['FORM_PASSWORD'].labelBtn)"
                    @click="onSignInWithPassword"
                  >
                  </syn-button>
                  <small
                    v-if="isBlockLoginByCode || isBlockSendPhoneCode"
                    class="text-red-900"
                  >
                    {{ errorCode }}
                  </small>
                </div>
              </section>

              <!-- CODE -->
              <section
                v-if="statusLogin['FORM_GET_CODE'].active"
                class="w-full"
              >
                <get-code
                  type="login"
                  :code-type="codeType"
                  :phone="inputPhone"
                  :email="inputEmail"
                  :has-password="hasPWD"
                  :error-code="errorCode"
                  :block-code-login="isBlockLoginByCode"
                  :block-sending-code="isBlockSendPhoneCode"
                  :is-loading="isLoadingGetCode"
                  :is-loading-resend="isSubmitLogin"
                  @change-login="onChangeMethodLogin"
                  @resend-code="onResendCode"
                  @on-verify="
                    (value) =>
                      codeType == 'phone'
                        ? onLoginWithCodePhone(value)
                        : onLoginWithCodeEmail(value)
                  "
                ></get-code>
              </section>
            </section>
            <div id="captcha-login"></div>
          </div>
        </div>
      </template>
    </tab-container>
  </div>

  <syn-question-modal
    :visible="isShowModalError"
    :show-confirm-btn="false"
    :delete-label="$t('COMMON_LABEL_LOGIN_AGAIN')"
    :is-loading="isLoadingRefresh"
    header-text="Oops!!"
    show-delete-btn
    @cancel="(value) => (isShowModalError = value)"
    @on-confirm="onRefreshPage"
  >
    <template #content>
      <syn-animation name="oops" stype="width: 200px;" :loop="false" />
      <div class="text-center text-red-700">
        {{ errorCode }}
      </div>
    </template>
  </syn-question-modal>
</template>
