<script setup lang="ts">
import { computed, onMounted, watch } from 'vue';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import { useDocumentVisibility } from '@vueuse/core';

const props = withDefaults(
  defineProps<{
    propData: any[];
    customClass?: string;
    isShowDescription?: boolean;
    isShowProgress?: boolean;
    progress?: number;
    idChart?: string;
    cutOut?: number | string;
    textClass?: string;
    labelNoData?: string;
    options?: any;
  }>(),
  {
    customClass: 'w-40',
    isShowDescription: true,
    progress: 0,
    cutOut: '50%',
    textClass: 'text-4xl',
    labelNoData: 'Not data yet',
    idChart: Date.now().toString(),
  }
);
let pieChart: Chart;

const visibility = useDocumentVisibility();
watch(
  () => visibility.value,
  () => {
    if (pieChart && typeof pieChart?.update == 'function') pieChart?.update();
  }
);
const doughnutChartId = `tictop-common-doughnut-chart-${
  props.idChart
}_${Date.now()}`;

const datasets = computed(() => {
  return [
    {
      labels: props.propData.map((o: any) => o.name),
      data: props.propData.map((o: any) => o.data),
      backgroundColor: props.propData.map((o: any) => o.color),
      hoverOffset: 4,
      cutout: props.cutOut || 67,
    },
  ];
});

// watch(
//   () => props.propData,
//   () => {
//     initData();
//   }
// );

const initData = () => {
  if (!props.propData || props.propData?.length == 0) return;

  const data = {
    datasets: datasets.value,
  };

  const config: ChartConfiguration = {
    type: 'doughnut',
    data: data,
    options: {
      plugins: {
        legend: { display: false },
        tooltip: {
          // displayColors: false,
          backgroundColor: 'rgba(255, 255, 255, 1)',
          titleColor: '#6b7280',
          bodyColor: '#6b7280',
          borderWidth: 1,
          borderColor: '#e5e7eb',
          callbacks: {
            label: (tooltipItems) => {
              return tooltipItems?.formattedValue;
            },
          },
        },
      },
      ...props.options,
    },
  };

  const ctx = document.getElementById(doughnutChartId) as HTMLCanvasElement;

  if (!ctx) return;

  pieChart = new Chart(ctx, config);
};

onMounted(() => {
  initData();
});

watch(
  () => props.propData,
  () => {
    if (!pieChart) {
      initData();
      return;
    }
    if (!pieChart.data) return;

    pieChart.data.datasets = datasets.value;
    pieChart?.update();
  },
  { deep: true }
);

const hasData = computed<boolean>(() => {
  return datasets.value?.some((dataset) => {
    return dataset?.data?.filter((o) => o)?.length > 0;
  });
});
</script>
<template>
  <div
    class="w-full h-full flex-center"
    :class="isShowDescription ? 'justify-between' : ''"
  >
    <template v-if="isShowDescription && hasData">
      <div class="flex flex-col items-start space-y-2 pt-4">
        <div
          v-for="item in propData"
          :key="item"
          class="flex items-center space-x-2 w-full"
        >
          <span
            class="w-4 h-4 rounded-full border shrink-0 flex-none"
            :style="`background-color : ${item?.color}`"
          ></span>
          <span
            :title="item?.name"
            class="text-sm font-semibold text-overflow-hidden-line"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
    </template>
    <div
      v-if="
        hasData &&
        (progress > 0 || propData?.findIndex((o) => o?.data > 0) !== -1)
      "
      class="relative flex-center"
      :class="customClass"
    >
      <slot name="progress">
        <div
          v-if="isShowProgress && hasData"
          class="absolute flex-center"
          style="top: 40%; left: 33%"
        >
          <span class="font-medium text-current-400" :class="textClass">
            {{ `${progress}%` }}
          </span>
        </div>
      </slot>
      <canvas
        v-show="hasData"
        :id="doughnutChartId"
        class="w-full h-full z-10"
      ></canvas>
    </div>
    <div v-else class="w-full h-full flex-center">
      <VigImage
        path="https://d1tvqetrcurhzb.cloudfront.net/web/charts/pie-chart.png"
        object-fit="contain"
      />
    </div>
  </div>
</template>
