<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useWindowSize } from '@vueuse/core';
import performanceStore from '@/store/dashboard/performance.store.ts';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';

import { translate } from '@/ui/plugins/i18n/myi18n';
import { _getLastWeekPeriod } from '@/application/constants/task.const';
import dayjs from 'dayjs';
import datetimeComposable from '@/ui/composables/common/datetime-composable';
import settingStore from '@/store/setting';
import ReportCard from '@/ui/modules/dashboard/performance/cards/ReportCard.vue';
import { REPORT_CARD_LIST } from '@/application/constants/report/report.const';
import DepartmentSelect from '@/ui/modules/departments/components/DepartmentSelect.vue';
import { IDepartmentDetail } from '@/application/types/department/department.types';
import appStore from '@/store/app';
import myProfileStore from '@/store/auth/my-profile';
import departmentStore from '@/store/department';

const _performanceStore = performanceStore();
const _settingStore = settingStore();
const { width: windowWidth } = useWindowSize();

watch(
  () => windowWidth.value,
  (width) => {
    if (width < 1300) appStore().setOpenMenuBar(false);
  }
);

const isLoading = ref<boolean>(false);

const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const isOwner = computed<boolean>(() => {
  return myProfileStore().isOwner;
});
const isAdmin = computed<boolean>(() => {
  return myProfileStore().isAdmin;
});
const isUser = computed<boolean>(() => {
  return myProfileStore().isUser;
});
const myDepartment = computed<any>(() => {
  return myProfileStore().myDepartment;
});

const isRenderDepartmentComponent = ref(false);
const selectedDepartment = ref<IDepartmentDetail | null>(null);
const initData = async () => {
  isLoading.value = true;

  if (!isOwner.value && !isAdmin.value && !isUser.value)
    useRouter().push({
      name: 'OverviewMembers',
    });

  if (
    (isAdmin.value || isUser.value) &&
    myDepartment.value?.departmentId &&
    Object.prototype.hasOwnProperty.call(
      departmentStore().allDepartmentByIds,
      myDepartment.value?.departmentId
    )
  ) {
    selectedDepartment.value =
      departmentStore().allDepartmentByIds[myDepartment.value?.departmentId];
  }

  if (departmentManagementEnabled.value) {
    isRenderDepartmentComponent.value = true;
  }

  await Promise.all([
    _performanceStore.getStatisticOverview(),
    _performanceStore.getStatisticsByPlanToday(),
  ]);

  prepareDataForChart();
  isLoading.value = false;
};

// const statisticByUserIds = computed<any>(() => {
//   return _performanceStore.statisticByUserIds;
// });

const maxFinishedTask = ref(0);

const statisticByDate = computed<any>(() => {
  return _performanceStore.statisticByDate;
});

const labelsByPlanTodoDate = ref<string[]>([]);
const datasetsByPlanTodoDate = ref<any>([]);
const { formatDate } = datetimeComposable();

const prepareDataForChart = () => {
  const lastWeekPeriod = _getLastWeekPeriod();

  let start = dayjs(lastWeekPeriod?.start);
  const finishedTaskByDate: any[] = [];
  const planTodoTaskByDate: any[] = [];
  while (dayjs(start).isBefore(new Date(), 'day')) {
    const dateId = dayjs(start).format('YYYYMMDD');
    labelsByPlanTodoDate.value.push(
      formatDate(dayjs(start), 'date', { isIgnoreTimezone: false })
    );
    finishedTaskByDate.push(
      statisticByDate.value[dateId]
        ? statisticByDate.value[dateId]?.totalFinishedTasks
        : 0
    );
    planTodoTaskByDate.push(
      statisticByDate.value[dateId]
        ? statisticByDate.value[dateId]?.planToFinishTodayTasks
        : 0
    );

    start = dayjs(start).add(1, 'd');
  }

  maxFinishedTask.value = Math.max(
    ...[...finishedTaskByDate, ...planTodoTaskByDate]
  );
  datasetsByPlanTodoDate.value = [
    {
      label:
        translate('OVERVIEW_FINISHED_TASKS_FINISHED_TODAY') || 'Finished today',
      data: [...planTodoTaskByDate],
      backgroundColor: '#FF8800',
      borderColor: '#FF8800',
      borderWidth: 2,
      hoverBackgroundColor: '#666',
      minBarLength: 2,
    },
    {
      label: translate('COMMON_LABEL_STATUS_FINISHED') || 'Finished',
      data: [...finishedTaskByDate],
      backgroundColor: '#4BB0B2',
      borderColor: '#4BB0B2',
      hoverBackgroundColor: '#008d8d',
      minBarLength: 2,
    },
  ];
};

onMounted(() => {});

initData();

const filterDateObj = ref<{ start: string; end: string }>({
  start: dayjs().utc().date(1).startOf('day').format(),
  end: dayjs().utc().startOf('day').format(),
});
</script>

<template>
  <SynLoading v-if="isLoading" />
  <div
    v-else
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.OVERVIEW.PERFORMANCE,
    }"
    class="w-full h-full flex flex-col pl-2 pb-4 relative overflow-x-auto"
  >
    <div class="w-full p-4 flex justify-between">
      <div class="flex-center">
        <span
          v-if="selectedDepartment"
          v-html="
            $t('PERFORMANCE_LABEL_BY_DEPARTMENT', {
              departmentName: selectedDepartment?.name,
            })
          "
        >
        </span>
        <span v-else>
          {{ $t('PERFORMANCE_LABEL_BY_ORGANIZATION') }}
        </span>
      </div>
      <div class="flex-center">
        <div class="flex-center gap-2">
          <AtomDateRangePicker
            v-model="filterDateObj"
            timezone="UTC"
            :max-date="new Date()"
          />
        </div>
        <div v-if="isOwner">
          <DepartmentSelect
            v-if="departmentManagementEnabled"
            v-model="selectedDepartment"
            ignore-visible-setting
          />
        </div>
      </div>
    </div>
    <div
      class="
        w-full
        min-w-[60rem]
        flex
        items-start
        justify-start
        flex-wrap flex-1
        min-h-0
        overflow-auto
        small-scrollbar
      "
    >
      <ReportCard
        v-for="card in REPORT_CARD_LIST"
        :key="card?.id"
        :report-card="card"
        :filter-data="{
          dateFrom: filterDateObj.start,
          dateTo: filterDateObj.end,
          departmentIds: selectedDepartment?.id ? [selectedDepartment?.id] : [],
        }"
      />
    </div>
  </div>
</template>
