<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import UserDomainTree from '@/ui/modules/domains/component/UserDomainTree.vue';
import userStore from '@/store/user';
import UserById from '@/ui/components/user/UserById.vue';

const _userStore = userStore();
const allActiveUserIds = computed(() => {
  return _userStore.allActiveUsers?.map((user) => user?.id);
});

onMounted(() => {
  onSelectUser(
    allActiveUserIds.value?.length > 0 ? allActiveUserIds.value[0] : 0
  );
});

const currentUserId = ref(0);
const onSelectUser = (userId) => {
  currentUserId.value = userId;
};
</script>
<template>
  <div class="h-full w-full flex-1 min-h-0 flex overflow-hidden divide-x">
    <div
      class="max-h-full min-h-0 overflow-y-auto small-scrollbar space-y-2"
      style="width: 20rem"
    >
      <div
        v-for="userId in allActiveUserIds"
        :key="userId"
        class="p-2 flex items-center space-x-2 cursor-pointer rounded text-sm"
        :class="
          currentUserId === userId
            ? 'bg-current-50 border-l-4 border-current'
            : 'hover:bg-gray-100'
        "
        @click="onSelectUser(userId)"
      >
        <UserById :user-id="userId" avatar-class="h-8 w-8" />
      </div>
    </div>
    <UserDomainTree v-if="currentUserId" :user-id="currentUserId" />
  </div>
</template>

<style>
.outline-red {
  outline: 1px solid red;
}

.domain-min-height {
  min-height: 82px;
}
</style>
