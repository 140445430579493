// Generated by https://quicktype.io

import { v4 as uuidv4 } from 'uuid';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';
import { ScheduleDayOptions } from '@/domain/enums/taskEnum';
import { ITaskTodo } from '@/application/types/task/task.types';
import workflowStepStore from '@/store/workflow/workflow-step.store';
import { uploadFilesToAws } from '@/application/services/attachment/AttachmentClass';
import { uploadNotesToAws } from '@/application/services/attachment/NoteClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';

export interface IStepTemplateEntity {
    id: string;
    code: string;
    stepOrder: number;
    name: string;
    description?: string;
    assigneeId?: number;
    typeId?: number;
    groupId?: number;
    domainId?: number;
    departmentId?: number;
    startTime?: string;
    scheduleTime?: string;
    scheduleByHour?: boolean;
    scheduleTimezone?: string;
    scheduleDayOption: ScheduleDayOptions;
    priority: number;
    important: boolean;
    taskDescription: string;
    files: any[];
    notes: any[];
    todoLists?: ITaskTodo[];
    mustAction?: boolean;
    isActive?: boolean;

    totalFiles?: number;
    totalNotes?: number;
    totalTodoLists?: number;

    totalWorkflows?: number;
    workflowId?: string;

    nextSteps?: any[];
}
export default class StepTemplateEntity implements IStepTemplateEntity {
    id: string;
    stepOrder: number;
    name: string;
    code: string;
    description?: string;
    assigneeId?: number;
    typeId?: number;
    groupId?: number;
    domainId?: number;
    departmentId?: number;
    startTime?: string;
    scheduleByHour?: boolean;
    scheduleTime?: string;
    scheduleTimezone?: string;
    scheduleDayOption: ScheduleDayOptions;
    priority: number;
    important: boolean;
    taskDescription: string;
    files: any[];
    notes: any[];
    todoLists?: ITaskTodo[];
    mustAction?: boolean;
    isActive?: boolean;

    totalFiles?: number;
    totalNotes?: number;
    totalTodoLists?: number;

    totalWorkflows?: number;
    usedWorkFlows?: any[];
    collaborators?: { userId: number; roleId: string }[];
    nextSteps?: any[];
    workflowId?: string;

    constructor(data) {
        this.id = data?.id || uuidv4();
        this.stepOrder = data?.stepOrder || 0;
        this.name = data?.name || '';
        this.code = data?.code || '';
        this.description = data?.description || '';
        this.assigneeId = data?.assigneeId;
        this.typeId = data?.typeId;
        this.groupId = data?.groupId;
        this.domainId = data?.domainId;
        this.departmentId = data?.departmentId;
        this.startTime = data?.startTime;
        this.scheduleByHour = data?.scheduleByHour;
        this.scheduleTime = data?.scheduleTime;
        this.scheduleTimezone = data?.scheduleTimezone;
        this.scheduleDayOption = data?.scheduleDayOption;
        this.priority = data?.priority;
        this.important = data?.important;
        this.taskDescription = data?.taskDescription;
        this.files = data?.files || [];
        this.notes = data?.notes || [];
        this.todoLists = data?.todoLists || [];
        this.mustAction = data?.mustAction;
        this.isActive = data?.isActive;
        this.totalFiles = data?.totalFiles;
        this.totalNotes = data?.totalNotes;
        this.totalTodoLists = data?.totalTodoLists;
        this.totalWorkflows = data?.totalWorkflows;
        this.usedWorkFlows = data?.usedWorkFlows;
        this.collaborators = data?.collaborators || [];
        this.nextSteps = data?.nextSteps || [];
        this.workflowId = data?.workflowId;
    }

    async uploadFiles(isClone?: boolean) {
        this.files = await uploadFilesToAws(
            ModuleSourceFileEnum.task,
            this.files,
            {
                isClone: !!isClone,
            }
        );
    }
    async uploadNotes(isClone?: boolean) {
        this.notes = await uploadNotesToAws(this.notes, {
            isClone: !!isClone,
        });
    }
    async saveAddNew(isClone?: boolean) {
        await Promise.all([
            this.uploadFiles(isClone),
            this.uploadNotes(isClone),
        ]);

        return workflowStepStore().addWorkFlowStepTemplate(this);
    }
    async saveUpdate() {
        await Promise.all([this.uploadFiles(), this.uploadNotes()]);
        return workflowStepStore().updateWorkFlowStepTemplate(this);
    }

    checkEditable() {
        if (this.totalWorkflows && this.totalWorkflows > 0) {
            settingGlobalModal({
                type: 'notification',
                title: '',
                content:
                    (translate('WORKFLOW_LABEl_STEP_USE_IN_WORKFLOW', {
                        stepName: this.name || '',
                        totalWorkflow: this.totalWorkflows || '',
                    }) || '') +
                        ' ' +
                        translate('WORKFLOW_LABEL_UPDATE_STEP_WARNING') || '',
                closeable: true,
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_CONTINUE'),
            });

            return ask();
        }

        return true;
    }

    async remove() {
        if (this.totalWorkflows && this.totalWorkflows > 0) {
            settingGlobalModal({
                type: 'notification',
                title: '',
                content:
                    (translate('WORKFLOW_LABEl_STEP_USE_IN_WORKFLOW', {
                        stepName: this.name || '',
                        totalWorkflow: this.totalWorkflows || '',
                    }) || '') +
                    ' ' +
                    (translate('WORKFLOW_LABEL_REMOVE_STEP_WARNING') || ''),
                closeable: true,
                confirmable: false,
                confirmLabel: translate('TASK_DETAIL_REMOVE') || 'Confirm',
            });
            await ask();

            return Promise.resolve(false);
        } else {
            settingGlobalModal({
                type: 'confirm',
                title: '',
                content:
                    translate('WORKFLOW_LABEl_STEP_CONFIRM_REMOVE', {
                        stepName: this.name || '',
                    }) || 'Do you want to remove this note?',
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
                closeable: true,
            });

            const confirmed = await ask();
            if (!confirmed) return Promise.resolve(false);
        }

        return workflowStepStore().deleteWorkflowStep(this.id);
    }

    async changeUrgency(urgency) {
        this.priority = convertUrgencyToPriority(urgency);
        this.important = convertUrgencyToImportant(urgency);
    }
    async updateDeadline(data: {
        scheduleOptionKey: ScheduleDayOptions;
        scheduleTime: string;
    }) {
        this.scheduleDayOption = data?.scheduleOptionKey;
        this.scheduleTime = data?.scheduleTime;
    }
}
