<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { sumBy } from 'lodash';
import StartWorking from './StartWorking.vue';
import StopWorking from './StopWorking.vue';
import myProfileStore from '@/store/auth/my-profile';
import PrincipleService from '@/application/services/PrincipleService';
import principleStore from '@/store/principle';
import dashboardStore from '@/store/dashboard';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { onListenEventFirebase } from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { EUserWorkingStatus } from '@/application/types/common/common.types';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import { EWorkingStatus } from '@/application/constants/working.const';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import permissionStore from '@/store/permission';

const _principleStore = principleStore();
const _dashboardStore = dashboardStore();
const _permissionStore = permissionStore();

const isStarting = computed(() => _principleStore.isStarting);
const checkinStatus = computed(() => _principleStore.checkinStatus);
const timezone = computed(() => myProfileStore().getTimezone);
const isLoading = computed(() => _principleStore.isLoading);
const sumTimeout = computed(() =>
  sumBy(principleDetails.value, function (o: any) {
    return o?.duration;
  })
);
const userWorkingStatus = computed(
  () => _principleStore.userWorkingStatus
) as any;
const userStartTime = computed(() => userWorkingStatus.value?.userStartTime);
const principleDetails = computed(
  () => userWorkingStatus.value?.principleDetails
);

const currentStatus = computed<EWorkingStatus>(() => {
  if (new Date(userStartTime.value?.stopTime).getTime() > 0) {
    return EWorkingStatus.STOPPED;
  }

  if (!userStartTime.value?.startTime) return EWorkingStatus.HAVE_NOT_STARTED;

  const status = userWorkingStatus.value?.userStatus;
  if (status == EUserWorkingStatus.ONLINE) return EWorkingStatus.WORKING;

  if (status == EUserWorkingStatus.PAUSE) return EWorkingStatus.PAUSE;

  return EWorkingStatus.HAVE_NOT_STARTED;
});

const startDuration = ref(false);
const startLeaveDuration = ref(false);
const isStopping = ref(false);
const isCheckoutConfirm = ref(false);
const checkinCheckoutList = ref([] as any);
const newCheckoutItem = ref({} as any);
const loading = ref({} as any);
const durationCheckout = ref(0);
const durationWorking = ref(0);
let refreshInterval;

const autoRefreshButton = () => {
  refreshInterval = setInterval(async () => {
    refreshAllButton(false);
    _dashboardStore.listenUserOvertimeOnListUserWorking();
  }, 5 * 60 * 1000);
};

const onCheckFocusTab = () => {
  // console.log(document.hidden);
};

onCheckFocusTab();

const refreshAllButton = async (isLoading) => {
  startDuration.value = false;
  startLeaveDuration.value = false;
  await _principleStore.fetchUserWorkingStatus(isLoading);
  startDuration.value = true;
  startLeaveDuration.value = true;
};

onMounted(async () => {
  refreshAllButton(true);
  autoRefreshButton();
});

onUnmounted(() => {
  clearInterval(refreshInterval);
});

const onCancel = () => {
  _principleStore.changeStarting(false);
  _principleStore.changeCheckinStatus('INIT');
  isStopping.value = false;
  isCheckoutConfirm.value = false;
};

const onCheckout = async () => {
  loading.value.checkout = true;
  startDuration.value = false;
  startLeaveDuration.value = true;
  await PrincipleService.getInstance().checkInOutWorkingDay({
    type: 0,
  });
  await _principleStore.fetchUserWorkingStatus();
  loading.value.checkout = false;
  isCheckoutConfirm.value = false;
  newCheckoutItem.value = {
    checkout: new Date().getTime(),
  };
  checkinCheckoutList.value = [
    ...checkinCheckoutList.value,
    newCheckoutItem.value,
  ];
};

const onStarting = async () => {
  // Reset SW location
  _principleStore.setStartWorkingLocation(null);

  const canStartWorking = await _permissionStore.checkStartWorkingConditions();
  if (!canStartWorking) return;

  _principleStore.setStartLocalTime();
  _principleStore.setStartWorkingStatus('VIEW_TASKS');
  _principleStore.changeStarting(true);
  _principleStore.fetchOverviewTasks();
  // await _dashboardStore.getMemberOnday();
};

const updateDateAfterAction = async () => {
  await _principleStore.fetchUserWorkingStatus();
  loading.value.checkin = false;

  checkinCheckoutList.value.pop();
  newCheckoutItem.value.checkin = new Date().getTime();
  checkinCheckoutList.value = [
    ...checkinCheckoutList.value,
    newCheckoutItem.value,
  ];
};

const onCheckin = async () => {
  loading.value.checkin = true;
  startDuration.value = true;
  startLeaveDuration.value = false;
  await PrincipleService.getInstance().checkInOutWorkingDay({
    type: 1,
  });

  updateDateAfterAction();
};

const onRestart = async () => {
  const canRestartWorking =
    await _permissionStore.checkReStartWorkingConditions();
  if (!canRestartWorking) return;

  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('COMMON_LABEL_RESTART_WORKING_DAY_WARNING'),
    confirmable: true,
    confirmLabel: translate('COMMON_LABEL_RESTART') || 'Confirm',
    closeable: true,
  });
  const confirm = await ask();
  if (!confirm) return;

  loading.value.checkin = true;
  startDuration.value = true;
  startLeaveDuration.value = false;
  await PrincipleService.getInstance().restartWk({
    type: 1,
  });
  updateDateAfterAction();
};

const updateDurationWorking = (time) => {
  durationWorking.value = time;
};

const updateDurationCheckout = (time) => {
  durationCheckout.value = time;
};

const onListenFirebaseEvent = async () => {
  const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
  const userId = getCurrentUserId();
  if (!organizationId || !userId) return;
  const companyId = `organization_${organizationId}`;
  const userPath = `user_${userId}`;
  onListenEventFirebase(
    `users_dynamic_info/${companyId}/${userPath}/latestWDActivity`,
    async () => {
      refreshAllButton(false);

      await _dashboardStore.getMemberOnday();
    }
  );
};

const titleCodeByStatus = computed<string>(() => {
  if (currentStatus.value === EWorkingStatus.WORKING) {
    return 'CHECKIN_LABEL_WORKING';
  }
  if (currentStatus.value === EWorkingStatus.PAUSE) {
    return 'CHECKIN_LABEL_PAUSE';
  }
  if (currentStatus.value === EWorkingStatus.STOPPED) {
    return 'CHECKIN_LABEL_STOP';
  }

  return 'CHECKIN_LABEL_WORKING';
});

onListenFirebaseEvent();
</script>

<template>
  <div
    v-system-config="{
      functionCode: 'START_WO_FUNCTION_ALLOW_START_WORKING',
      functionValue: '1',
    }"
    class="w-max"
    data-title="Welcome!"
    data-intro="Hãy bắt đầu một ngày làm việc ở đây! Chọn công việc bạn sẽ làm trong ngày để bắt đầu. Bạn có thể theo dõi thời gian làm việc của mình trong 1 ngày."
  >
    <div
      v-if="isLoading"
      class="animate-pulse w-full flex items-center justify-center space-x-4"
    >
      <div
        v-for="pulse in 1"
        :key="pulse"
        class="p-4 w-32 bg-gray-300 rounded-full"
      ></div>
    </div>
    <div v-else class="w-full flex items-center justify-center">
      <!-- HAVE NOT STATED YET -->
      <template v-if="currentStatus === EWorkingStatus.HAVE_NOT_STARTED">
        <section
          class="flex space-x-2 bg-current-50 rounded-full items-center p-1"
        >
          <span class="pl-2 text-sm font-medium text-current-600 uppercase">
            {{ $t('HEADER_LABEL_START_WD') }}
          </span>
          <div
            v-permission-function="{
              functionCode: ALL_FUNCTIONS.WK_TIME.START_WD,
              onContinue: () => {
                onStarting();
              },
            }"
            class="
              w-8
              h-8
              bg-current-500
              hover:bg-current hover:shadow-lg
              flex-center
              rounded-full
              cursor-pointer
            "
            @click="onStarting"
          >
            <SynIcon
              name="start"
              custom-class="w-3.5 h-3.5 fill-white text-white"
            />
          </div>
        </section>
      </template>
      <!-- STARTED -->
      <template v-else>
        <section
          class="flex space-x-4 rounded-full items-center p-1"
          :class="
            currentStatus === EWorkingStatus.WORKING
              ? 'bg-current-50 text-current-600'
              : currentStatus === EWorkingStatus.PAUSE
              ? 'bg-orange-50 text-orange-600'
              : userWorkingStatus?.totalLeaveTime > 0
              ? 'bg-gray-50 text-gray-600'
              : 'bg-current-50'
          "
        >
          <span class="pl-2 text-sm">
            {{ $t(titleCodeByStatus) }}
          </span>

          <!-- Time -->
          <div class="flex-center space-x-2">
            <SynWatch
              v-if="currentStatus === EWorkingStatus.WORKING"
              style="width: 6rem"
              class="font-medium text-lg text-current-600 space-x-1"
              type="duration"
              :start="startDuration"
              :started-time="
                userWorkingStatus?.totalDurationTime > 0
                  ? userWorkingStatus?.totalDurationTime
                  : 0
              "
              @on-stop="updateDurationWorking"
            />
            <SynWatch
              v-if="currentStatus === EWorkingStatus.PAUSE"
              style="color: #ff6500; width: 6rem"
              class="blinking text-lg font-medium space-x-1"
              type="duration"
              :start="startLeaveDuration"
              :started-time="
                userWorkingStatus?.totalLeaveTime > 0
                  ? userWorkingStatus?.totalLeaveTime
                  : 0
              "
              @on-stop="updateDurationCheckout"
            />
            <template v-if="currentStatus === EWorkingStatus.STOPPED">
              <SynClock
                class="font-medium text-lg text-gray-600 space-x-1"
                :date-time="userWorkingStatus?.totalDurationTime"
                is-distance
              />

              <SynIcon name="Duration" custom-class="h-5 w-5" />
            </template>

            <VigDropdown
              v-if="principleDetails && principleDetails.length > 0"
              trigger="mouseenter"
            >
              <template #dropdown-toggle>
                <SynIconCustom
                  :custom-class="
                    currentStatus === EWorkingStatus.WORKING
                      ? 'fill-current text-current-500 w-3 h-3'
                      : currentStatus === EWorkingStatus.PAUSE
                      ? 'fill-orange-500 text-orange-500 w-3 h-3'
                      : 'fill-gray-500 text-gray-500 w-3 h-3'
                  "
                  class="cursor-pointer"
                  name="time-rest"
                  is-active
                />
              </template>

              <template #dropdown-menu>
                <div class="w-max bg-white rounded-md">
                  <div
                    class="
                      py-3
                      flex-center
                      space-x-1
                      font-medium
                      fill-current
                      text-sm text-current-500
                    "
                  >
                    <SynIcon name="time-rest" />
                    <span>{{
                      $t('DASHBOARD_LABEL_DESCRIPTION_PAUSE_TIME')
                    }}</span>
                  </div>
                  <div class="py-2 border-b">
                    <div
                      class="
                        grid grid-cols-3
                        text-sm text-current-800
                        whitespace-nowrap
                        space-x-2
                        text-center
                      "
                    >
                      <span>
                        {{ $t('CHECKIN_LABEL_FROM') }}
                      </span>
                      <span>
                        {{ $t('CHECKIN_LABEL_TO') }}
                      </span>
                      <span>
                        {{ $t('DASHBOARD_TABLE_LABEL_DUR') }}
                      </span>
                    </div>
                  </div>
                  <div class="overflow-y-auto h-max max-h-96 small-scrollbar">
                    <div
                      v-for="item in principleDetails"
                      :key="item"
                      class="py-2 px-2 border-b"
                    >
                      <div
                        class="
                          grid grid-cols-3
                          whitespace-nowrap
                          space-x-2
                          text-center
                        "
                        style="color: #ff6500"
                      >
                        <span class="text-xs">
                          {{
                            item.checkOut
                              ? $filters.dayjs(
                                  item.checkOut,
                                  'hh:mm A',
                                  timezone
                                )
                              : '--:--'
                          }}
                        </span>
                        <span class="text-xs">
                          {{
                            item.checkIn
                              ? $filters.dayjs(
                                  item.checkIn,
                                  'hh:mm A',
                                  timezone
                                )
                              : '--:--'
                          }}
                        </span>
                        <span>
                          <SynClock
                            class="text-xs"
                            :date-time="item.duration"
                            is-distance
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="px-2 py-2">
                    <div
                      class="
                        flex
                        justify-between
                        items-center
                        whitespace-nowrap
                        space-x-2
                      "
                    >
                      <span class="text-sm text-current-600">{{
                        $t('COMMON_LABEL_TOTAL')
                      }}</span>
                      <SynClock
                        v-if="sumTimeout > 0"
                        style="color: #fc684f"
                        class="font-medium"
                        :date-time="sumTimeout"
                        is-distance
                      />
                    </div>
                  </div>
                </div>
              </template>
            </VigDropdown>
          </div>

          <!-- Action -->
          <div class="flex space-x-1 items-center">
            <div
              v-if="currentStatus === EWorkingStatus.WORKING"
              v-vig-tooltip="{
                content: $t('HEADER_LABEL_PAUSE'),
                arrow: true,
              }"
              class="
                w-8
                h-8
                bg-orange-500
                hover:shadow-lg hover:bg-orange-400
                flex-center
                rounded-full
              "
              :class="
                loading?.checkout || loading?.stop
                  ? 'cursor-wait'
                  : 'cursor-pointer'
              "
              @click="onCheckout"
            >
              <SynIcon
                :name="loading?.checkout ? 'spinner' : 'pause'"
                :class="loading?.checkout ? 'animate-spin' : ''"
                custom-class="w-3.5 h-3.5 fill-white text-white"
              />
            </div>

            <div
              v-if="currentStatus === EWorkingStatus.WORKING"
              v-vig-tooltip="{
                content: $t('HEADER_LABEL_STOP'),
                arrow: true,
              }"
              class="
                w-8
                h-8
                bg-red-500
                hover:shadow-lg hover:bg-red-400
                flex-center
                rounded-full
                cursor-pointer
              "
              :class="
                loading?.stop || loading?.checkout
                  ? 'cursor-wait'
                  : 'cursor-pointer'
              "
              @click="isCheckoutConfirm = true"
            >
              <SynIcon
                :name="loading?.stop ? 'spinner' : 'stop-video'"
                :class="loading?.stop ? 'animate-spin' : ''"
                custom-class="w-3.5 h-3.5 fill-white text-white"
              />
            </div>

            <div
              v-if="currentStatus === EWorkingStatus.PAUSE"
              v-vig-tooltip="{
                content: $t('HEADER_COMFIRM_BEFORE_WORKING_AGAIN'),
                arrow: true,
              }"
              class="
                w-8
                h-8
                bg-green-400
                hover:shadow-lg
                flex-center
                rounded-full
                cursor-pointer
              "
              :class="
                loading?.checkin ? 'cursor-not-allowed' : 'cursor-pointer'
              "
              @click="onCheckin"
            >
              <SynIcon
                :name="loading?.checkin ? 'spinner' : 'start'"
                :class="loading?.checkin ? 'animate-spin' : ''"
                custom-class="w-3.5 h-3.5 fill-white text-white"
              />
            </div>
            <div
              v-if="currentStatus === EWorkingStatus.STOPPED"
              v-system-config="{
                functionCode: 'START_WO_FUNCTION_START_WORKING_FUNCTIONS',
                functionValue: 'RESTART',
              }"
              v-vig-tooltip="{
                content: $t('COMMON_LABEL_RESTART'),
                arrow: true,
              }"
              class="
                w-8
                h-8
                bg-green-400
                hover:shadow-lg
                flex-center
                rounded-full
                cursor-pointer
              "
              @click="onRestart"
            >
              <SynIcon
                name="reload"
                :class="loading?.checkin ? 'animate-spin' : ''"
                custom-class="w-3.5 h-3.5 fill-white text-white"
              />
            </div>
          </div>
        </section>
      </template>
    </div>
  </div>

  <StartWorking v-if="isStarting" @on-cancel="onCancel" />
  <StopWorking
    v-if="checkinStatus !== 'STOPED' && isCheckoutConfirm"
    @on-stop="isStopping = true"
    @on-cancel="isCheckoutConfirm = false"
  />
</template>

<style>
@-moz-keyframes blinkingText {
  0% {
    color: #e8697d;
  }
  49% {
    color: #e8697d;
  }
  100% {
    color: #fecaca;
  }
}

@-webkit-keyframes blinkingText {
  0% {
    color: #e8697d;
  }
  49% {
    color: #e8697d;
  }
  100% {
    color: #fecaca;
  }
}

@-o-keyframes blinkingText {
  0% {
    color: #e8697d;
  }
  49% {
    color: #e8697d;
  }
  100% {
    color: #fecaca;
  }
}

@-ms-keyframes blinkingText {
  0% {
    color: #e8697d;
  }
  49% {
    color: #e8697d;
  }
  100% {
    color: #fecaca;
  }
}

@keyframes blinkingText {
  0% {
    color: #e8697d;
  }
  49% {
    color: #e8697d;
  }
  100% {
    color: #fecaca;
  }
}

.blinking {
  -webkit-animation: blinkingText 1s infinite;
  -moz-animation: blinkingText 1s infinite;
  -ms-animation: blinkingText 1s infinite;
  -o-animation: blinkingText 1s infinite;
  animation: blinkingText 1s infinite;
}

.timeline {
  width: fit-content;
}

@media (min-width: 1024px) {
  .timeline {
    width: 47%;
    min-width: 627px;
  }
}
</style>
