<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 512.003 512.003"
    style="enable-background: new 0 0 512.003 512.003"
    xml:space="preserve"
  >
    <g>
      <path
        style="fill: #455a64"
        d="M327.212,83.104c16.704-21.536,29.344-52,24.768-83.104c-27.296,1.888-59.2,19.36-77.792,42.112   c-16.96,20.64-30.912,51.296-25.472,81.088C278.54,124.128,309.356,106.24,327.212,83.104z"
      />
      <path
        style="fill: #455a64"
        d="M459.852,171.776c-26.208-32.864-63.04-51.936-97.824-51.936c-45.92,0-65.344,21.984-97.248,21.984   c-32.896,0-57.888-21.92-97.6-21.92c-39.008,0-80.544,23.84-106.88,64.608c-37.024,57.408-30.688,165.344,29.312,257.28   c21.472,32.896,50.144,69.888,87.648,70.208c33.376,0.32,42.784-21.408,88-21.632c45.216-0.256,53.792,21.92,87.104,21.568   c37.536-0.288,67.776-41.28,89.248-74.176c15.392-23.584,21.12-35.456,33.056-62.08   C387.852,342.624,373.932,219.168,459.852,171.776z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
