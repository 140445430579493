import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import createTaskFormIndexDB from '@/indexedDB/task/create-task-form';
import TaskCreateUpdatePayload from '@/domain/entities/task/TaskCreateUpdatePayload';
import TaskCreateEntity from '@/domain/entities/task/TaskCreateEntity';
import settingStore from '@/store/setting';
import { TaskLife } from '@/domain/enums/taskEnum';

export default defineStore({
    id: storeId.taskCreateForm,
    state: () => ({
        cachedFormData: {},
        isLoadingCacheFormData: false,
        formDataCacheAfterCreate: undefined as undefined | TaskCreateEntity,
        cacheMultiAssignees: [] as any[],
    }),
    getters: {},
    actions: {
        setLoadingCacheData(data) {
            this.isLoadingCacheFormData = data;
        },
        async loadAllCache() {
            try {
                const dataInCache: any[] = await createTaskFormIndexDB
                    .getInstance()
                    .getAll();
                // console.log('🚀 Hyrin ~ loadAllCache ~ dataInCache', dataInCache);

                this.cachedFormData = dataInCache.reduce(
                    (oldValue, currentValue) => {
                        return {
                            ...oldValue,
                            [currentValue?.ownerId]: currentValue,
                        };
                    },
                    {}
                );
            } catch (error) {
                console.log('🚀 ~ getCachedFromIndexDb ~ error', error);
                return {};
            }
        },
        getCachedByOwnerId(ownerId: string) {
            return this.cachedFormData[ownerId];
        },
        async setCachedFormData(key, formData: TaskCreateUpdatePayload) {
            // console.log('🚀 ~ setCachedFormData ~ key', key, formData);
            const ownerId = `${key}`;
            this.cachedFormData[ownerId] = {
                ...formData,
            };
            try {
                await createTaskFormIndexDB
                    .getInstance()
                    .saveTask(ownerId, formData);
            } catch (error) {
                console.log('🚀 ~ setCachedFormData ~ error', error);
            }
        },
        async removeCacheByLocalId(localId) {
            const ownerId = `${localId}`;
            try {
                await createTaskFormIndexDB.getInstance().removeTask(ownerId);
            } catch (error) {
                console.log('🚀 ~ setCachedFormData ~ error', error);
            }
        },
        resetFormAfterCreate(newTask) {
            this.formDataCacheAfterCreate = new TaskCreateEntity({
                assigneeId: newTask?.assigneeId,
                groupId: newTask?.groupId,
                domainId: newTask?.domainId,
                domainName: newTask?.domainName,
                projectId: newTask?.projectId,
                projectName: newTask?.projectName,
                subprojectId: newTask?.subprojectId,
                subprojectName: newTask?.subprojectName,
                taskType: newTask?.taskType,
                planType: newTask?.planType,
                estimationTime: 1,
                priority: newTask?.priority == 1 ? 1 : null,
                taskSchedule: newTask?.taskSchedule,
                scheduleTime: newTask?.scheduleTime,
                isPrivate:
                    !settingStore()._organizationSetting?.taskManagement
                        ?.defaultPublicTask,
                urgency: 1,
                taskLife: TaskLife.Todo,
            });

            this.cacheMultiAssignees = newTask?.multiAssignees || [];
        },
    },
});
