<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import CheckInWorkingDay from '@/ui/modules/checkin/CheckInWorkingDay.vue';
import NotificationBell from '@/ui/modules/notification/NotificationBell.vue';
import SettingLanguage from '@/ui/components/setting/SettingLanguage.vue';
import userStore from '@/store/user';
import settingStore from '@/store/setting';
import ChatIcon from '@/ui/modules/messaging/chat-icon/ChatIcon.vue';
import { loginAgain } from '@/ui/hooks/commonHook';
import { onToggleSettingPage } from '@/ui/hooks/useSidebar';
import SearchGlobal from '@/ui/components/search/SearchGlobal.vue';
// import CallGroupButton from '@/ui/modules/video-call/components/CallGroupButton.vue';
import OrganizationHeader from '@/ui/modules/organization/OrganizationHeader.vue';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import appStore from '@/store/app';
import overviewTeamStore from '@/store/dashboard/overview-team-store';
import myProfileStore from '@/store/auth/my-profile';
import { currentActiveDepartmentId } from '@/ui/pages/overview/overview-member-state';
import plansStore from '@/store/plans';
import { ALL_FUNCTIONS_BY_MODEL } from '@/ui/hooks/permission/permission-by-function';
import organizationStore from '@/store/organization';
import ModalOrganizationsNotification from '@/ui/modules/organization/modal-organizations-notification/ModalOrganizationsNotification.vue';
import QuickActionMenuDropdown from '@/ui/components/common/quick-action/QuickActionMenuDropdown.vue';
import CallGroupButton from '@/ui/modules/video-call/components/CallGroupButton.vue';

const _overviewTeamStore = overviewTeamStore();
const _appStore = appStore();
const _settingStore = settingStore();
const _userStore = userStore();
const router = useRouter();
const route = useRoute();
const _organizationStore = organizationStore();

const chatEnabled = computed(() => _settingStore.chatEnabled);
const timeWorkingEnabled = computed(() => _settingStore.timeWorkingEnabled);
const videoCallEnabled = computed(() => _settingStore.videoCallEnabled);

const activeOrganization = computed(
  () => _organizationStore.organizationInfo
) as any;
const organizationIds = computed(() => _organizationStore.organizationIds);
const organizationByIds = computed(() => {
  return _organizationStore.organizationByIds;
});
const hasNotif = computed(() =>
  organizationIds.value?.some(
    (orgId) =>
      orgId !== activeOrganization.value?.id &&
      organizationByIds.value &&
      (organizationByIds.value[orgId]?.notificationCount ||
        organizationByIds.value[orgId]?.unSeenConversationsCount)
  )
);
const isOpenMessagesNotifs = ref(false);

let refreshPage;
const isLoadingHeader = ref(true);
onMounted(async () => {
  isLoadingHeader.value = true;
  try {
    refreshPage = setInterval(function () {
      _overviewTeamStore.getOverviewTeamInfo(
        currentActiveDepartmentId.value,
        true
      );
      plansStore().getCurrentOrgPlans(true);
    }, 5 * 60 * 1000);

    await myProfileStore().fetchCurrentLoginUser();
    await _userStore.getUserIdentity();

    isLoadingHeader.value = false;
  } catch (error) {
    isLoadingHeader.value = false;
  }
});
onUnmounted(() => {
  clearInterval(refreshPage);
});

const isLoadingLogout = ref(false);

const handleClickLogout = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: translate('HEADER_LOGOUT_CONFIRM_TITLE'),
    content: translate('HEADER_LOGOUT_CONFIRM_SUB'),
    confirmable: true,
    closeable: true,
  });
  const confirm = await ask();
  if (confirm) handleLogout();
};

const handleLogout = () => {
  isLoadingLogout.value = true;
  _appStore.setIsLoading(true);

  loginAgain();

  isLoadingLogout.value = false;
};

const onClickSetting = () => {
  onToggleSettingPage(true, router, route);
};
</script>

<template>
  <header
    class="
      flex flex-wrap
      justify-between
      items-center
      px-3
      bg-white
      relative
      shadow
    "
    style="height: 50px; z-index: 1"
  >
    <div
      v-permission-model="{
        functionCode: ALL_FUNCTIONS_BY_MODEL.SEARCH_GLOBAL,
      }"
      class="absolute left-4 flex space-x-10"
    >
      <SearchGlobal />
    </div>
    <div class="flex-1 flex-center">
      <CheckInWorkingDay v-if="timeWorkingEnabled" />
    </div>

    <div v-if="isLoadingHeader" class="flex-center space-x-3">
      <div
        v-if="chatEnabled"
        class="w-6 h-6 animate-pulse bg-gray-200 rounded-full"
      ></div>
      <div class="w-6 h-6 animate-pulse bg-gray-200 rounded-full"></div>
      <div class="w-6 h-6 animate-pulse bg-gray-200 rounded-full"></div>
    </div>

    <div v-else class="items-center flex space-x-1">
      <div class="space-x-1 items-center hidden md:flex">
        <div v-permission-model>
          <QuickActionMenuDropdown />
        </div>
        <div v-permission-model>
          <CallGroupButton v-if="videoCallEnabled" />
        </div>

        <div v-permission-model v-permission="'Messages'">
          <ChatIcon v-if="chatEnabled" />
        </div>
        <NotificationBell v-if="!isLoadingHeader" />
        <div class="flex-center space-x-4 pl-6">
          <OrganizationHeader />
        </div>
      </div>

      <VigDropdown class="hover:text-current bg-white shadow-none">
        <template #dropdown-toggle>
          <SynIcon
            name="dots-vertical"
            has-action
            custom-class="h-4 w-4 hover:text-current text-current"
          />
        </template>
        <template #dropdown-menu>
          <SynDropdownMenu class="right-0 min-w-[11rem]">
            <!-- language -->
            <setting-language />

            <div
              v-permission-model
              class="dropdown-item list-li"
              @click="onClickSetting"
            >
              <div class="flex items-center">
                <SynIcon name="setting" />
                <span class="pl-2">
                  <!-- {{
                    $t('COMMON_LABEL_PERSONAL_SETTINGS') || 'Personal Settings'
                  }} -->
                  {{ $t('SIDEBAR_LABEL_PREFERENCES') || 'Preferences' }}
                </span>
              </div>
            </div>
            <div
              v-if="organizationIds?.length > 1"
              class="dropdown-item list-li"
              @click="isOpenMessagesNotifs = true"
            >
              <div class="flex items-center">
                <span class="relative leading-4">
                  <SynIcon name="ChatBell" class="fill-current-500" />
                  <span
                    v-if="hasNotif"
                    class="
                      w-2
                      h-2
                      block
                      rounded-full
                      bg-red-500
                      shadow
                      absolute
                      -right-1
                      top-0
                    "
                  ></span>
                </span>
                <span class="pl-2">
                  {{ $t('NOTIFICATION_GLOBAL_VIEW') }}
                </span>
              </div>
            </div>
            <div
              class="dropdown-item list-li text-red-500"
              @click="handleClickLogout"
            >
              <div class="flex items-center">
                <SynIcon name="logout" class="fill-red-500" />
                <span class="pl-2">
                  {{ $t('COMMON_LABEL_LOGOUT') || 'Log out' }}
                </span>
              </div>
            </div>
          </SynDropdownMenu>
        </template>
      </VigDropdown>
    </div>
  </header>

  <ModalOrganizationsNotification
    v-if="isOpenMessagesNotifs"
    @on-close="isOpenMessagesNotifs = false"
  />
</template>
