<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import inviteUserStore from '@/store/organization/invite-user';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  clearLocalStorage,
  setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import GetCode from '@/ui/pages/auth/GetCode.vue';
import { isValidEmail } from '@/ui/hooks/commonFunction';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import InviteUserError from '../invite-user/invite-user-error.vue';
import { AcceptedInvitation } from '@/application/services/authenticate/authenticate-service';
import { AuthenticatePhoneCode } from '@/application/entities/un-authenticate/LoginEntitiesClass';
import { AuthenticateCaseEnum } from '@/application/entities/authenticate/AuthenticateEnum';
import {
  destroyGoogleCaptcha,
  initGoogleCaptcha,
} from '@/ui/components/layouts/logic/init-google-capcha';
import { CountryCode } from 'libphonenumber-js';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import remoteConfigStore from '@/store/remoteConfig';

defineProps({
  isHiddenClose: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['onClose']);

const isLoading = ref(true);

const router = useRouter();
const route = useRoute();
const _inviteUserStore = inviteUserStore();
const isVerifyingPhone = ref(false);
const getCodeErrorMessage = ref('');
const isShowModalError = ref(false);
const isLoadingRefresh = ref(false);

const firstName = ref('');
const firstNameError = ref('');
const lastName = ref('');
const lastNameError = ref('');
const hasError = ref(false);
const hasErrorMessage = ref('');
const hasErrorMessageOrgName = ref('');

const supportingCountryCodeList = computed<string[]>(() => {
  return remoteConfigStore().supportingCountryCodeListForUsingPhoneNumber;
});
const invitedMethod = ref('email');
const isSentViaEmail = computed(() => invitedMethod.value == 'email');
const isSentViaPhone = computed(() => invitedMethod.value == 'phone');
const accountExisted = ref(false);
const isNeedVerifyPhone = ref(false);
const organizationInfo = ref({} as any);
const isInited = ref(false);
const inviteToken = ref('');
const errorMessage = ref('');
const errorResponseCode = ref(0);
const onInit = async () => {
  // get invite info by code
  const urlToken = route.query?.c || '';
  const token = encodeURIComponent(urlToken?.toString());
  inviteToken.value = token;
  const params = {
    tokenCode: token,
  };
  try {
    const res = await _inviteUserStore.getInvitedUser(params);
    const data = res['result'];
    // setValues({
    //   firstName: data?.firstName,
    //   lastName: data?.lastName,
    //   email: data?.email,
    //   phone: data?.phone,
    // });

    if (data?.firstName) firstName.value = data?.firstName;
    if (data?.lastName) lastName.value = data?.lastName;
    if (data?.email) email.value = data?.email;
    if (data?.phone) phone.value = data?.phone;
    invitedMethod.value = data?.invitedMethod;
    accountExisted.value = data?.accountExisted;
    isNeedVerifyPhone.value = data?.isNeedVerifyPhone;
    organizationInfo.value = data?.organization;
    isInited.value = true;
  } catch (error: any) {
    errorResponseCode.value = error?.error?.code;
    errorMessage.value = error?.error?.message;
  }
  // 1. Verified phone: Join
  // 2. Not Verified phone: Verify phone before
  isLoading.value = false;
};
onInit();

const phone = ref('');

const {
  isChecking: isCheckingPhone,
  validationResult: validationResultPhone,
  onValidation: onValidationPhone,
} = atomFormInputComposables(
  [
    () => {
      return resultPhoneObject.value?.isValid
        ? ''
        : 'COMMON_INVALID_PHONE_NUMBER';
    },

    async (value) => {
      const resCheckExistPhone = await _inviteUserStore.checkPhone(value);
      return resCheckExistPhone.existed
        ? 'COMMON_LABEL_REQUIRE_PHONE_IS_EXISTED'
        : '';
    },
  ],
  1000
);

const resultPhoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});

const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  // 1. clear error
  resultPhoneObject.value = phoneObj;
  // 1. Set value for phone
  if (!isInited.value || !phoneObj || !phoneObj.number) return;
  const local = phoneObj.countryCallingCode.toLowerCase();
  currentLanguage.value = local == 'vn' ? 'vi' : local;
  phone.value = phoneObj.number;

  onValidationPhone(phoneObj.number);
};

const isFetchChecking = ref(false);
const emailIsValid = ref(false);
const email = ref('');
const emailError = ref('');

const {
  isChecking: isCheckingEmail,
  validationResult: validationResultEmail,
  onValidation: onValidationEmail,
} = atomFormInputComposables(
  [
    (value) => {
      const isValid = isValidEmail(value);
      return isValid ? '' : translate('COMMON_INVALID_EMAIL');
    },
    async (value) => {
      const res = await _inviteUserStore.checkEmail(value);
      return res.existed
        ? translate('COMMON_LABEL_REQUIRE_EMAIL_IS_EXISTED')
        : '';
    },
  ],
  1000
);

const onChangeEmail = async (event) => {
  if (!isInited.value) return;
  const value = event.target.value;

  onValidationEmail(value);
};

onMounted(async () => {
  await nextTick();
});

onUnmounted(() => {
  destroyGoogleCaptcha();
});

const onRefreshPage = async () => {
  isLoadingRefresh.value = true;
  clearLocalStorage({ isSwitchingOrg: false });
  await window.location.reload();
  isLoadingRefresh.value = false;
};

const capchaTokenText = ref('' as string);

let recaptchaVerifier: any;

const resetCaptcha = async (type) => {
  if (recaptchaVerifier) recaptchaVerifier.reset();

  const _capchaTokenText = await initGoogleCaptcha(
    'captcha-accept-invitation',
    'accept-invitation-without-token-modal_capcha-parent-container'
  );
  if (_capchaTokenText !== capchaTokenText.value) {
    if (type == 'send') {
      await onSentCodeToPhoneNumber();
    } else {
      await onResendCode();
    }
  }
};

const isFetchingSignup = ref(false);
const isFetchingJoin = ref(false);
const sessionInfo = ref('');
const currentLanguage = ref('');

const onFetchJoinNow = async () => {
  try {
    const acceptedClass = new AcceptedInvitation(
      firstName.value,
      lastName.value,
      phone.value,
      email.value,
      inviteToken.value
    );
    const res = await acceptedClass.acceptedWithoutToken(
      organizationInfo.value,
      'USER_ACCEPT_INVITATION_PAGE'
    );

    if (isNeedVerifyPhone.value) {
      // nhap code truoc khi login (login by phone code)
      onSentCodeToPhoneNumber();
      isVerifyingPhone.value = true;
      return;
    }

    // da verify phone roi thi ko can nhap code nua -> join luon
    handleLoginSuccess(res);
  } catch (error: any) {
    console.log(error);
    isFetchingJoin.value = false;

    if (error?.error?.code === 6107) {
      hasError.value = true;
      hasErrorMessage.value = 'ERROR_EXCEEDING_THE_LIMIT_VISITOR_6107';
      hasErrorMessageOrgName.value = error?.error?.organizationName;
    }
  }
};
const onSentCodeToPhoneNumber = async () => {
  getCodeErrorMessage.value = '';
  try {
    const capchaTokenText = await initGoogleCaptcha(
      'captcha-accept-invitation',
      'accept-invitation-without-token-modal_capcha-parent-container'
    );

    const resSend = await _inviteUserStore.sendVerificationCodeByPhoneNumber({
      phoneNumber: phone.value.trim(),
      captchaToken: capchaTokenText,
      type: isNeedVerifyPhone.value ? 'sign_up' : 'sign_in',
      locale: currentLanguage.value,
    });

    sessionInfo.value = resSend?.result.sessionInfo;

    isVerifyingPhone.value = true;

    isFetchingSignup.value = false;
  } catch (response: any) {
    if (response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      resetCaptcha('send');
    } else if (
      response.error.message == ErrorNotificationLogin.SEND_VERIFICATION_FAILED
    ) {
      parserErrorMessage(response);
      isShowModalError.value = true;
      isFetchingSignup.value = false;
    } else {
      isFetchingSignup.value = false;
      parserErrorMessage(response);
    }
  }
};

const isResend = ref(false);
const onResendCode = async () => {
  if (isResend.value) return;
  isResend.value = true;
  getCodeErrorMessage.value = '';
  const resSend = ref();
  try {
    resSend.value = await _inviteUserStore.reSendVerificationCodeByPhoneNumber({
      phoneNumber: phone.value.trim(),
      captchaToken: capchaTokenText.value,
      locale: currentLanguage.value,
      type: isNeedVerifyPhone.value ? 'sign_up' : 'sign_in',
    });
    sessionInfo.value = resSend.value?.result.sessionInfo;
    isResend.value = false;
  } catch (response: any) {
    if (response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      resetCaptcha('resend');
    } else if (
      response.error.message == ErrorNotificationLogin.SEND_VERIFICATION_FAILED
    ) {
      parserErrorMessage(response);
      isShowModalError.value = true;
      isResend.value = false;
    } else {
      isResend.value = false;
      parserErrorMessage(response);
    }
  }
};

const parserErrorMessage = (response) => {
  const responseMsg = response.error.message;
  if (
    responseMsg == ErrorNotificationLogin.SEND_PHONE_CODE_BLOCKED ||
    responseMsg == ErrorNotificationLogin.LOGIN_BY_CODE_BLOCKED
  ) {
    errorMessage.value = translate(ErrorNotificationLogin[responseMsg]);
    errorResponseCode.value = 6106;
  }

  getCodeErrorMessage.value = translate(ErrorNotificationLogin[responseMsg]);
};

// GET CODE
const isLoadingGetCode = ref(false);
const onConfirmCode = async (code) => {
  if (isNeedVerifyPhone.value) {
    onVerifyPhone(code);
    return;
  }
  onLoginWithCodePhone(code);
};

const onVerifyPhone = async (code) => {
  getCodeErrorMessage.value = '';
  isLoadingGetCode.value = true;

  const data = {
    phoneNumber: phone.value,
    code: code,
    sessionInfo: sessionInfo.value,
  };

  try {
    const resVerify = await _inviteUserStore.verifyUserPhoneNumber(data);
    isLoadingGetCode.value = false;

    if (!resVerify?.result) return;

    onFetchJoinNow();
  } catch (response: any) {
    isLoadingGetCode.value = false;
    const errorCode = response?.error?.message;
    switch (errorCode) {
      case ErrorNotificationLogin.CAPTCHA_CHECK_FAILED:
        resetCaptcha('resend');
        break;

      case ErrorNotificationLogin.SEND_VERIFICATION_FAILED:
        {
          parserErrorMessage(response);
          isShowModalError.value = true;
          isResend.value = false;
        }
        break;

      default:
        {
          parserErrorMessage(response);
          isResend.value = false;
        }
        break;
    }
  }
};

const onLoginWithCodePhone = async (code) => {
  try {
    isLoadingGetCode.value = true;
    const authenPhoneCode = new AuthenticatePhoneCode(
      phone.value,
      code,
      sessionInfo.value
    );

    const res = await authenPhoneCode.login();

    isLoadingGetCode.value = false;

    handleLoginSuccess(res);
  } catch (response) {
    parserErrorMessage(response);
    isLoadingGetCode.value = false;
  }
};

const onClickJoinOrSignUp = async () => {
  if (isNeedVerifyPhone.value) {
    // 1. Gui invite qua email va chua co tai khoan thi can verify phone
    if (isDisabledButton.value || isFetchingSignup.value) return;
    isFetchingSignup.value = true;
    await onSentCodeToPhoneNumber();
    isFetchingSignup.value = false;

    return;
  }

  // Cac truong hop con lai thi can accept va Login by code
  isFetchingJoin.value = true;

  await onFetchJoinNow();
};

const handleLoginSuccess = async (result) => {
  if (!result) return;

  if (result.caseKey == AuthenticateCaseEnum.GoToCurrentOrganization) {
    router.push({
      name: 'Home',
    });
    return;
  }
  history.replaceState({}, '', '/');
  setLocalStorage(StorageConstant.HAS_LOGIN, 'true');

  window.location.reload();
};
const isDisabledButton = computed(() => {
  // 1. Not VerifiedExisted account

  if (!accountExisted.value) {
    // Input phone
    if (
      isSentViaEmail.value &&
      (isCheckingPhone.value ||
        !phone.value ||
        !validationResultPhone.value.isValid)
    )
      return true;

    // Input email
    if (
      isSentViaPhone.value &&
      (isCheckingEmail.value ||
        !email.value?.trim() ||
        !validationResultEmail.value.isValid)
    )
      return true;

    return false;
  }

  // 2. Existed account => input name only
  return !firstName.value?.trim() ||
    !lastName.value?.trim() ||
    firstNameError.value ||
    lastNameError.value
    ? true
    : false;
});

const onBackTo = () => {
  isVerifyingPhone.value = false;
  getCodeErrorMessage.value = '';
};
</script>

<template>
  <teleport to="body">
    <div
      id="accept-invitation-without-token-modal_capcha-parent-container"
      class="absolute w-full inset-0 overflow-hidden flex-center z-50"
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div
        class="
          relative
          h-auto
          shadow-lg
          bg-white
          mx-auto
          rounded-md
          flex flex-col
        "
        style="max-height: 80%; width: 1200px"
      >
        <div class="h-full text-left flex flex-col overflow-y-auto">
          <!--Body-->
          <div
            class="
              h-full
              max-h-full
              flex
              bg-white
              overflow-x-hidden overflow-y-auto
              small-scrollbar
              rounded-md
              relative
            "
          >
            <div v-if="isLoading" class="flex-1">
              <SynLocalLoading />
            </div>
            <div v-else-if="errorMessage" class="flex-1 items-center p-4">
              <InviteUserError :error-code="errorResponseCode" />
              <!-- <invite-user-error :error-code="6103"> </invite-user-error> -->
              <!-- {{ errorMessage }} -->
            </div>
            <div v-else class="flex flex-col flex-1 p-12">
              <div v-if="isVerifyingPhone" class="absolute top-0 left-0 m-4">
                <SynIcon
                  name="back"
                  class="fill-current text-current"
                  has-action
                  @click="onBackTo"
                />
              </div>
              <section>
                <div
                  class="
                    flex-center
                    mt-4
                    text-center
                    flex-col
                    content-center
                    justify-center
                    w-full
                    my-1
                    font-roboto font-thin
                  "
                >
                  <span class="pb-4">
                    {{
                      $t('SIGN_UP_RECEIVED_INVITATION_FROM_ORGANIZATION') ||
                      'You have just received an invitation to join the organization'
                    }}
                  </span>

                  <div>
                    <SynAvatar
                      :src="organizationInfo?.logoUrl"
                      :name="organizationInfo?.name"
                      custom-class="w-12 h-12"
                    />
                  </div>
                  <div class="text-current font-semibold">
                    {{ organizationInfo?.name }}
                  </div>
                  <div v-if="isSentViaEmail">
                    {{
                      $t(
                        'SIGN_UP_RECEIVED_INVITATION_FROM_ORGANIZATION_TO_EMAIL'
                      ) || 'to email:'
                    }}

                    <span class="text-current font-semibold">
                      {{ email }}
                    </span>
                  </div>
                  <div v-if="isSentViaPhone">
                    {{
                      $t(
                        'SIGN_UP_RECEIVED_INVITATION_FROM_ORGANIZATION_TO_PHONE_NUMBER'
                      ) || 'to phone number:'
                    }}

                    <span class="text-current font-semibold">
                      {{ phone }}
                    </span>
                  </div>
                </div>

                <form>
                  <!-- ENTER CODE OTP -->
                  <section v-if="isVerifyingPhone">
                    <GetCode
                      :phone="phone"
                      :type="isNeedVerifyPhone ? 'signup' : 'login'"
                      :is-loading="isLoadingGetCode"
                      :is-loading-resend="isResend"
                      :error-code="getCodeErrorMessage"
                      @resend-code="onResendCode"
                      @on-verify="onConfirmCode"
                    />
                    <div
                      v-if="hasError"
                      class="
                        bg-orange-100
                        px-4
                        py-1
                        pt-2
                        rounded
                        shadow
                        text-orange-500 text-sm
                      "
                    >
                      <span
                        class="text-orange-500 text-sm"
                        v-html="
                          $t(hasErrorMessage, {
                            orgName: hasErrorMessageOrgName || '',
                          })
                        "
                      >
                      </span>
                    </div>
                  </section>

                  <!-- FORM LOGIN -->
                  <section v-else class="mt-4 w-full">
                    <div
                      class="
                        relative
                        flex flex-col
                        min-w-0
                        break-words
                        w-full
                        mb-6
                        rounded-lg
                        border-0
                        lg:items-center
                      "
                    >
                      <div class="flex-auto py-10 pt-0 w-full">
                        <!-- <form @submit.prevent="onSubmit"> -->
                        <div class="relative flex space-x-3 mb-3 mt-3">
                          <SynFormInput :error-message="firstNameError">
                            <SynInput
                              v-if="isInited"
                              v-model="firstName"
                              class="w-full"
                              autofocus
                              input-type="text"
                              :placeholder="
                                $t('USERMANAGEMENT_TABLE_FIRST_NAME') + '*'
                              "
                              :error-message="firstNameError"
                            />
                          </SynFormInput>
                          <SynFormInput :error-message="lastNameError">
                            <SynInput
                              v-if="isInited"
                              v-model="lastName"
                              class="w-full"
                              input-type="text"
                              :placeholder="
                                $t('USERMANAGEMENT_TABLE_LAST_NAME') + '*'
                              "
                              :error-message="lastNameError"
                            />
                          </SynFormInput>
                        </div>
                        <div v-if="accountExisted">
                          <div
                            v-if="isSentViaPhone"
                            class="
                              relative
                              w-full
                              mb-3
                              flex
                              items-center
                              px-4
                              py-2
                              space-x-4
                              bg-gray-200
                              rounded-md
                            "
                          >
                            <SynIcon name="email" class="fill-green-500" />
                            <span>
                              {{ email }}
                            </span>
                          </div>
                          <div
                            v-if="isSentViaEmail"
                            class="
                              relative
                              w-full
                              mb-3
                              flex
                              items-center
                              px-4
                              py-2
                              space-x-4
                              bg-gray-200
                              rounded-md
                            "
                          >
                            <SynIcon name="phone" class="fill-green-500" />
                            <span>
                              {{ phone }}
                            </span>
                          </div>
                        </div>
                        <template v-else>
                          <div
                            v-if="isSentViaPhone"
                            class="relative w-full mb-3"
                          >
                            <SynFormInput
                              :error-message="emailError"
                              :is-checking="isFetchChecking"
                              :is-valid="emailIsValid"
                            >
                              <SynInput
                                v-if="isInited"
                                v-model="email"
                                class="w-full"
                                icon-prefix="email"
                                input-type="text"
                                :placeholder="$t('LOGIN_FORM_EMAIL') + '*'"
                                :error-message="emailError"
                                :is-disabled="isSentViaEmail"
                                @input="onChangeEmail"
                              />
                            </SynFormInput>
                          </div>
                          <div v-if="isSentViaEmail" class="relative mb-3">
                            <SynFormInput
                              :is-checking="isCheckingPhone"
                              :is-valid="validationResultPhone.isValid"
                              :error-message="validationResultPhone.message"
                            >
                              <AtomPhoneInput
                                v-if="isInited"
                                :value="phone"
                                :disabled="isSentViaPhone"
                                :available-countries="supportingCountryCodeList"
                                @change="onChangePhone"
                              />
                            </SynFormInput>
                          </div>
                        </template>
                        <!-- </form> -->
                      </div>
                    </div>
                  </section>
                </form>
              </section>

              <div
                v-if="!isVerifyingPhone"
                class="flex items-center justify-end pt-4"
              >
                <div class="pt-8 flex items-center space-x-4">
                  <SynButton
                    class="w-full m-auto"
                    :is-loading="isFetchingSignup || isFetchingJoin"
                    :disabled="isDisabledButton"
                    :label="$t('COMMON_LABEL_JOIN_NOW')"
                    @click="onClickJoinOrSignUp()"
                  />
                </div>
              </div>
            </div>
            <!-- Right area -->
            <div
              class="flex flex-col justify-center ml-8 p-12 w-1/3 bg-gray-100"
            >
              <syn-animation name="businessTeam" stype="width: 500px;" />
              <div class="text-center text-current-800 text-2xl font-thin">
                {{ $t('INVITE_USER_LABEL_WELCOME') }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isHiddenClose"
          v-vig-tooltip="$t('COMMON_LABEL_CLOSE')"
          class="
            w-8
            h-8
            rounded-full
            cursor-pointer
            hover:text-current-500
            absolute
            -top-1
            -right-1
            flex-center
          "
          @click="$emit('onClose')"
        >
          <SynIcon name="close" custom-class="w-4 h-4" />
        </div>
      </div>
    </div>
  </teleport>
  <syn-question-modal
    :visible="isShowModalError"
    header-text="Oops!!"
    :show-confirm-btn="false"
    show-delete-btn
    :delete-label="$t('COMMON_LABEL_TRY_AGAIN')"
    :is-loading="isLoadingRefresh"
    @cancel="(value) => (isShowModalError = value)"
    @on-confirm="onRefreshPage"
  >
    <template #content>
      <syn-animation name="oops" stype="width: 200px;" :loop="false" />
      <div class="text-center text-red-700">
        {{ getCodeErrorMessage }}
      </div>
    </template>
  </syn-question-modal>
</template>
