<script setup lang="ts">
// *** IMPORTS ***
import { computed, onBeforeMount, ref, watch } from 'vue';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import systemConfig from '@/application/constants/system-config.const';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

// *** PROPS, EMITS ***
const props = defineProps<{
  settings: any;
}>();

const emit = defineEmits(['onSettingChange']);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const proofTypes = ref<any[]>([
  { value: 'IMAGE', text: 'COMMON_LABEL_IMAGE' },
  { value: 'DOCUMENT', text: 'COMMON_LABEL_DOCUMENT' },
  { value: 'NOTE', text: 'COMMON_LABEL_NOTE_2' },
  { value: 'COMMENT', text: 'COMMON_LABEL_COMMENT' },
]);
const proofTypeSelected = ref<any>({});

// *** COMPUTED ***
const proofEnabledSystem = computed(
  () =>
    props.settings &&
    props.settings[
      systemConfig.ADVANCED_COMPLETI_SYSTEM_COMPLETION_PROOF_ACTIVA
    ] === '1'
);

const proofEnabled = computed({
  get() {
    return (
      props.settings &&
      props.settings[
        systemConfig.ADVANCED_COMPLETI_COMPLETION_PROOF_ACTIVATED
      ] === '1'
    );
  },
  set(value) {
    emit('onSettingChange', {
      key: systemConfig.ADVANCED_COMPLETI_COMPLETION_PROOF_ACTIVATED,
      value: value ? '1' : '0',
    });
  },
});

const proofSkipAllowed = computed({
  get() {
    return (
      props.settings &&
      props.settings[
        systemConfig.ADVANCED_COMPLETI_ALLOWED_TO_SKIP_COMPLETION_PRO
      ] === '1'
    );
  },
  set(value) {
    emit('onSettingChange', {
      key: systemConfig.ADVANCED_COMPLETI_ALLOWED_TO_SKIP_COMPLETION_PRO,
      value: value ? '1' : '0',
    });
  },
});

// *** WATCHES ***
watch(
  () => props.settings,
  () => _processSelectedTypes()
);

// *** HOOKS ***
onBeforeMount(() => {
  _processSelectedTypes();
});

// *** HANDLER FUNCTIONS ***
const onProofTypeChange = (type) => {
  const selectedTypes = proofTypes.value
    .filter((t) => proofTypeSelected.value[t?.value])
    .map((t) => t?.value);

  // Verify at least 1 type selected
  if (selectedTypes.length <= 0) {
    setTimeout(() => (proofTypeSelected.value[type] = true));

    settingGlobalModal({
      type: 'notification',
      title: translate('COMMON_LABEL_ALERT'),
      content: translate('SETTING_TASK_FINISH_PROOF_TYPE_AT_LEAST_MSG'),
      confirmable: true,
    });

    return ask();
  }

  emit('onSettingChange', {
    key: systemConfig.ADVANCED_COMPLETI_REQUIRED_PROOF_TYPE,
    value: selectedTypes.join(';'),
  });
};

// *** PRIVATE FUNCTIONS ***
const _processSelectedTypes = () => {
  proofTypeSelected.value = props.settings
    ? props.settings[systemConfig.ADVANCED_COMPLETI_REQUIRED_PROOF_TYPE]
        ?.split(';')
        .reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {})
    : {};
};

// *** EXPOSES ***
</script>

<template>
  <div v-if="proofEnabledSystem">
    <MenuSettingRowItem
      name-icon="FileCheck"
      :icon-is-active="false"
      :title-menu="$t('SETTING_TASK_FINISH_PROOF_ENABLE')"
      :sub-title="$t('SETTING_TASK_FINISH_PROOF_ENABLE_DESC')"
    >
      <template #action>
        <AtomSwitch v-model="proofEnabled" />
      </template>
      <template #subMenu>
        <div v-if="proofEnabled" class="pl-8 pr-6 py-2 border-t border-dashed">
          <div class="font-medium text-gray-800">
            {{ $t('SETTING_TASK_FINISH_PROOF_TYPE_REQUIRED') }}
          </div>
          <div class="text-xs text-gray-600 mb-2">
            {{ $t('SETTING_TASK_FINISH_PROOF_TYPE_REQUIRED_DESC') }}
          </div>
          <VigCheckbox
            v-for="type in proofTypes"
            :key="type?.value"
            v-model="proofTypeSelected[type?.value]"
            @on-change="onProofTypeChange(type?.value)"
          >
            <span class="text-sm">
              {{ $t(type?.text) }}
            </span>
          </VigCheckbox>
        </div>

        <div v-if="proofEnabled" class="pl-2 border-t border-dashed">
          <MenuSettingRowItem
            :title-menu="$t('SETTING_TASK_FINISH_PROOF_SKIP_ALLOWED')"
            :sub-title="$t('SETTING_TASK_FINISH_PROOF_SKIP_ALLOWED_DESC')"
          >
            <template #action>
              <AtomSwitch v-model="proofSkipAllowed" />
            </template>
          </MenuSettingRowItem>
        </div>
      </template>
    </MenuSettingRowItem>
  </div>
</template>

<style scoped></style>
