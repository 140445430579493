<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import ChatInfoHeader from '@/ui/modules/messaging/chat-info-header/ChatInfoHeader.vue';
import ChatPanel from '@/ui/modules/messaging/chat-panel/ChatPanel.vue';
import chatStore from '@/store/chat';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import myProfileStore from '@/store/auth/my-profile';
import PostMessageService from '@/application/services/PostMessageService';
import { AppMessageTypeEnum } from '@/domain/enums/app-message-type.enum';
import {
  getAvatarColor,
  getAvatarText,
} from '@/ui/common/molecules/SynAvatar/colorByAlphabet';
import fileStorageStore from '@/store/fileStorage';
import focusOnConversationComposables from '@/ui/composables/chat/focus-on-conversation-composables';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';

const _myProfileStore = myProfileStore();
const _chatStore = chatStore();
const _fileStorageStore = fileStorageStore();

const chatWidgetRef = ref<any>();
const chatPanelRef = ref<any>();

const orgId = computed(() => _myProfileStore.myProfile?.organizationId);
const contacts = computed(() => _chatStore.getContacts);
const allUsers = computed(() => _chatStore.allUsers);
const conversationById = computed(() => _chatStore.conversationById);
const bubbleChats = computed(() =>
  _chatStore.bubbleChats?.map((bubbleChat) => ({
    ...bubbleChat,
    avatarColor: getAvatarColor(bubbleChat?.name),
    avatarText: getAvatarText(bubbleChat?.name),
  }))
);
const bubbleChatActive = computed(() => _chatStore.bubbleChatActive);
const activeConverInfo = computed(() =>
  _chatStore.getConversationInfo(bubbleChatActive.value?.id)
);
const activeConverContact = computed<any>(() =>
  contacts.value && bubbleChatActive.value?.id
    ? contacts.value[bubbleChatActive.value?.id]
    : null
);
const chatMsgAllowedActions = computed(() => [
  ChatMessageAction.REPLY,
  ChatMessageAction.FORWARD,
  ChatMessageAction.REACTION,
  ChatMessageAction.PIN,
  ChatMessageAction.COPY,
  ChatMessageAction.SET_BACKGROUND,
  ChatMessageAction.DELETE,
  ChatMessageAction.DOWNLOAD_FILE,
]);

focusOnConversationComposables(
  chatWidgetRef,
  () => {
    activeAndFocusOnConversationId.value = '';
    // isForceActive.value = false;
  },
  bubbleChatActive
);

watch(
  () => [bubbleChats.value, conversationById.value],
  () => {
    const preparedBubbles = bubbleChats.value?.map((bubble) => ({
      ...bubble,
      ...conversationById.value[bubble?.id],
    }));

    PostMessageService.getInstance().postMessageToParent({
      type: AppMessageTypeEnum.tictopBubbleChats,
      data: preparedBubbles,
    });
  }
);

watch(
  () => bubbleChatActive.value,
  (value) => {
    PostMessageService.getInstance().postMessageToParent({
      type: AppMessageTypeEnum.tictopBubbleChatActive,
      data: value,
    });
  }
);

watch(
  () => _chatStore.unSeenConversationsCount,
  (data) => {
    PostMessageService.getInstance().postMessageToParent({
      type: AppMessageTypeEnum.tictopChatUnseenCount,
      data,
    });
  }
);

onMounted(() => {
  document.addEventListener('click', _emitEventClickToParent);
  window.addEventListener('message', _listenMessageFromWebComponent);
});

onUnmounted(() => {
  document.removeEventListener('click', _emitEventClickToParent);
  window.removeEventListener('message', _listenMessageFromWebComponent);
});

const onOpenNoteClick = () => {
  return PostMessageService.getInstance().postMessageToParent({
    type: AppMessageTypeEnum.tictopNoteClick,
  });
};

const onMessageReply = () => {};

const onMessageViewFile = () => {};

const onSetUserConversationSeenAll = () => {
  return _chatStore.setSeenUserConversation(
    orgId.value,
    bubbleChatActive.value?.id
  );
};

const onFocusOnConversation = (chat) => {
  activeAndFocusOnConversationId.value = `${chat?.id}`;
};

const _listenMessageFromWebComponent = (event) => {
  const { type, data, requestId } = event?.data || {};

  switch (type) {
    case AppMessageTypeEnum.componentBubbleChatOpen:
      _chatStore.addBubbleChat(data);
      break;
    case AppMessageTypeEnum.componentBubbleChatRemove:
      _chatStore.removeBubbleChat(data);
      break;
    case AppMessageTypeEnum.componentBubbleChatRemoveAll:
      _chatStore.removeBubbleChatAll();
      break;
    case AppMessageTypeEnum.componentNoteSave:
      chatPanelRef.value.setNote(data);
      break;
    case AppMessageTypeEnum.componentRecordingStop:
      chatPanelRef.value.setStopRecording(new Blob([data]));
      break;
    case AppMessageTypeEnum.componentChatFilesGetRequest:
      _onGetChatFiles(data, requestId);
      break;
  }
};

const _emitEventClickToParent = () => {
  PostMessageService.getInstance().postMessageToParent({
    type: AppMessageTypeEnum.tictopDocumentClick,
  });
};

const _onGetChatFiles = async (filter, requestId) => {
  let result;

  try {
    const params = {
      orgId: orgId.value,
      conversationId: filter?.conversationId,
      category: 'CHAT',
      keyword: filter?.searchText,
      fileTypes: [filter?.fileType],
      pageSize: 50,
      // sortBy: sortBy.value,
      // orderBy: orderBy.value,
      pageIndex: filter?.pageIndex,
    };

    result = await _fileStorageStore.searchFiles(params);
  } catch (e) {
    console.log(e);
  }

  PostMessageService.getInstance().postMessageToParent({
    type: AppMessageTypeEnum.tictopChatFilesGetResponse,
    data: result,
    requestId,
  });
};
</script>

<template>
  <div
    v-if="bubbleChatActive?.id"
    ref="chatWidgetRef"
    class="h-full flex flex-col overflow-hidden"
    :class="[activeAndFocusOnConversationId ? 'bg-white' : 'bg-gray-50']"
    @click="onFocusOnConversation(bubbleChatActive)"
  >
    <ChatInfoHeader
      is-chat-widget
      :organization-id="orgId"
      :conversation-id="bubbleChatActive?.id"
      :conversation="activeConverInfo"
      :contact="activeConverContact"
      :is-embedded-app="true"
      @on-open-note-click="onOpenNoteClick"
      @on-message-reply="onMessageReply"
      @on-message-view-file="onMessageViewFile"
    />
    <div class="flex-1 h-full overflow-hidden">
      <ChatPanel
        ref="chatPanelRef"
        size="sm"
        :organization-id="orgId"
        :conversation-id="bubbleChatActive?.id"
        :conversation="activeConverInfo"
        :users="allUsers"
        :contact="activeConverContact"
        :actual-unseen="activeConverInfo?.unSeen"
        :allowed-actions="chatMsgAllowedActions"
        :is-embedded-app="true"
        @on-set-user-conversation-seen-all="onSetUserConversationSeenAll"
      />
    </div>
  </div>
</template>

<style scoped></style>
