<script setup lang="ts">
import { ref, watch } from 'vue';

interface Props {
  modelValue: number;
  currentDate: number;
  step: number;
  minTime: number;
  maxTime: number;
  timezone: number;
}

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);

watch(
  () => props.minTime,
  () => {
    updateListOptions();
  }
);
watch(
  () => props.maxTime,
  () => {
    updateListOptions();
  }
);
const timeAroundDayArr = ref([] as any[]);
const updateListOptions = () => {
  const arr = [] as any;
  const currentDay = new Date(props.currentDate);
  const diffTimeUtc = (props.timezone || 0) * 60 * 60 * 1000;
  const startDayTime =
    Date.UTC(
      currentDay.getUTCFullYear(),
      currentDay.getUTCMonth(),
      currentDay.getUTCDate(),
      0,
      0,
      0,
      0
    ) - diffTimeUtc;

  const maxTime =
    props.maxTime > 0
      ? props.maxTime
      : Date.UTC(
          currentDay.getUTCFullYear(),
          currentDay.getUTCMonth(),
          currentDay.getUTCDate(),
          24,
          0,
          0,
          0
        ) - diffTimeUtc;

  let minTime =
    props.minTime > 0 ? props.minTime + props.step * 60 * 1000 : startDayTime;
  while (minTime < maxTime) {
    arr.push(minTime);
    minTime = minTime + props.step * 60 * 1000;
  }

  timeAroundDayArr.value = arr || [];
};
const currentSelected = ref(Date.now());
const onChooseTime = (value) => {
  currentSelected.value = value;
  emit('update:modelValue', value);
};

updateListOptions();

defineExpose({ updateListOptions });
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <div
        class="
          w-24
          flex-center
          border-transparent
          hover:bg-current-100
          py-1
          px-2
          cursor-pointer
          flex-center
          focus:border-current
          focus:ring
          focus:border
          focus:ring-current
          focus:ring-opacity-30
          rounded
        "
      >
        {{ $filters.dayjsUtc(modelValue, ' hh:mm A', timezone) }}
      </div>
    </template>
    <template #dropdown-menu>
      <div
        v-perfect-scrollbar
        class="w-full h-max max-h-48 overflow-auto small-scrollbar"
      >
        <div
          v-for="(timestamp, index) in timeAroundDayArr"
          :id="`calendar-timepicker-${String(index)}`"
          :key="timestamp"
          :class="timestamp == modelValue ? 'bg-gray-100' : ''"
          class="dropdown-item cursor-pointer hover:bg-gray-100 px-4 py-1"
          @click="onChooseTime(timestamp)"
        >
          {{ $filters.dayjsUtc(timestamp, ' hh:mm A', timezone) }}
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
