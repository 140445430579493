<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  membersSeen: any[];
}>();

const SHOWING_LIMIT = 8;

const remainingMemberCount = computed(
  () => (props.membersSeen?.length || 0) - SHOWING_LIMIT
);

const showingMembers = computed(() =>
  remainingMemberCount.value > 1
    ? props.membersSeen?.slice(0, SHOWING_LIMIT)
    : props.membersSeen
);
</script>

<template>
  <VigDropdown placement="top">
    <template #dropdown-toggle>
      <div
        v-if="membersSeen?.length > 0"
        class="flex justify-end space-x-1 px-1 cursor-pointer w-max"
      >
        <template v-for="user of showingMembers" :key="user">
          <div
            v-vig-tooltip="
              $t('CHAT_SEEN_AT_TIME', {
                user: user?.name,
                time: $filters.dayjs(
                  user?.lastSeenMessageTime,
                  'hh:mm A, DD/MM/YYYY'
                ),
              }) ||
              `${user?.name} saw at ${$filters.dayjs(
                user?.lastSeenMessageTime,
                'MMM DD, YYYY, hh:mm A'
              )}`
            "
            class="w-3.5 h-3.5"
          >
            <SynAvatar
              v-if="user?.userId"
              :src="user?.avatar?.replace('original', 'mini')"
              :name="user?.name"
              custom-class="w-3.5 h-3.5 text-xs"
            />
          </div>
        </template>

        <div
          v-if="remainingMemberCount > 1"
          class="
            h-3.5
            text-[10px]
            whitespace-nowrap
            rounded-lg
            px-1
            bg-gray-200
          "
        >
          +{{ remainingMemberCount }}
        </div>
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="
          px-2
          py-4
          flex flex-col
          space-y-4
          overflow-y-auto
          small-scrollbar
          max-h-72
        "
      >
        <template v-for="useritem of membersSeen" :key="useritem">
          <div
            v-if="useritem?.userId"
            class="flex items-center justify-between space-x-4"
          >
            <SynAvatar
              :src="useritem?.avatar?.replace('original', 'mini')"
              :name="useritem?.name"
              has-name
              custom-class="w-6 h-6 text-sm"
            />
            <div v-if="useritem?.userId" class="text-xs text-gray-600">
              <i>{{ $t('COMMON_LABEL_SEEN') + ' ' }}</i>
              {{
                $filters.dayjs(
                  useritem?.lastSeenMessageTime,
                  'hh:mm A, DD/MM/YYYY'
                )
              }}
            </div>
          </div>
        </template>
      </div>
    </template>
  </VigDropdown>
</template>
