<script setup lang="ts">
import { ref } from 'vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

defineProps({
  isMyComment: {
    type: Boolean,
    default: false,
  },
  message: {
    type: Object,
    default: () => {},
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});
defineEmits(['onPinComment', 'onRemoveComment', 'onEditComment']);

const isOpenAction = ref(false);
const vigActionInComment = ref();

window.addEventListener(
  'scroll',
  (e: any) => {
    if (!isOpenAction.value) return;
    let el = e.target;
    while (el && el !== document && !isScrollable(el)) {
      // console.log('scroll', el);
      // el = el.parent;
    }
    onCloseAction();
    vigActionInComment.value.onForceClose();
  },
  true
);

const isScrollable = (el) => {
  return el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;
};

const onCloseAction = () => {
  isOpenAction.value = false;
};
</script>

<template>
  <VigDropdown
    ref="vigActionInComment"
    @on-dropdown-open="isOpenAction = true"
    @on-dropdown-close="onCloseAction"
  >
    <template #dropdown-toggle>
      <div
        v-vig-tooltip="$t('COMMON_LABEL_EDIT')"
        class="flex-center rounded cursor-pointer p-1 hover:bg-gray-200"
        :class="isOpenAction ? 'bg-gray-200' : ''"
      >
        <SynIcon
          name="dots-vertical"
          custom-class="w-3.5 h-3.5 fill-gray-500 text-gray-500"
        />
      </div>
    </template>
    <template #dropdown-menu>
      <div class="flex flex-col py-1 w-full" style="min-width: 11rem">
        <div
          class="list-li space-x-2 dropdown-item"
          @click.stop="$emit('onPinComment')"
        >
          <SynIcon
            name="pinnded"
            custom-class="w-3.5 h-3.5 fill-gray-500 text-gray-500"
            :class="message?.pinned ? 'rotate-45' : ''"
          />
          <span>{{
            !message?.pinned
              ? $t('COMMON_LABEL_PINNED')
              : $t('COMMON_LABEL_UNPINNED')
          }}</span>
        </div>
        <div
          v-if="isMyComment"
          class="list-li dropdown-item"
          @click.stop="$emit('onEditComment', message?.id)"
        >
          <SynIcon
            name="edit"
            custom-class="w-3.5 h-3.5 fill-gray-500 text-gray-500"
          />
          <span>{{ $t('COMMON_LABEL_EDIT') }}</span>
        </div>
        <div
          v-if="isMyComment"
          class="
            list-li
            dropdown-item
            border-t border-gray-100
            flex
            items-center
            justify-between
            w-full
          "
          :class="isLoading?.remove ? 'cursor-not-allowed' : ''"
          @click.stop="$emit('onRemoveComment')"
        >
          <div class="flex items-center space-x-2 text-red-500 fill-red-500">
            <SynIcon name="trash" custom-class="w-3.5 h-3.5" />
            <span>{{ $t('COMMON_LABEL_DELETE') }}</span>
          </div>
          <SynIcon
            v-if="isLoading?.remove"
            name="spinner"
            custom-class="animate-spin w-3.5 h-3.5"
          ></SynIcon>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
