<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 448.24 448.24"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          class="cls-2"
          d="m224.12,448.24c-59.86,0-116.15-23.31-158.48-65.64C23.31,340.26,0,283.98,0,224.12S23.31,107.97,65.64,65.64C107.97,23.31,164.25,0,224.12,0s116.15,23.31,158.48,65.64,65.64,98.61,65.64,158.48-23.31,116.15-65.64,158.48-98.61,65.64-158.48,65.64Zm0-405.24c-99.87,0-181.12,81.25-181.12,181.12s81.25,181.12,181.12,181.12h0c99.87,0,181.12-81.25,181.12-181.12S323.99,43,224.12,43Z"
        />
        <g class="cls-1">
          <path
            class="cls-3"
            d="m144.22,235.8c-5.92,0-11.82-2.43-16.07-7.2-7.9-8.87-7.11-22.46,1.76-30.35l78.94-70.28c7.55-6.72,18.75-7.28,26.93-1.34l80.87,58.72c9.61,6.98,11.74,20.42,4.76,30.03-6.98,9.61-20.42,11.74-30.03,4.76l-66.86-48.56-66.02,58.77c-4.1,3.65-9.2,5.44-14.29,5.44Z"
          />
          <path
            class="cls-3"
            d="m144.22,325.71c-5.92,0-11.82-2.43-16.07-7.2-7.9-8.87-7.11-22.46,1.76-30.35l78.94-70.28c7.55-6.72,18.75-7.28,26.93-1.34l80.87,58.73c9.61,6.98,11.74,20.42,4.76,30.03-6.98,9.61-20.42,11.74-30.03,4.76l-66.86-48.56-66.02,58.77c-4.1,3.65-9.2,5.44-14.29,5.44Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
