import { cloneDeep } from '@/ui/hooks/commonFunction';
import groupStore from '@/store/group';

export const getGroupDomainById = async (groupId, domainOfUser) => {
    const groupDomains = groupStore().getGroupDomainByGroupId(groupId);
    return cloneDeep(
        domainOfUser.length > 0
            ? groupDomains.map((domain) => {
                  const index = domainOfUser.findIndex(
                      (d) => d.domainId == domain.domainId
                  );
                  if (index > -1)
                      domain.projectScopeList =
                          domainOfUser[index].projectScopeList;
                  return domain;
              })
            : groupDomains
    );
};
