<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import DomainItem from '@/ui/modules/domains/domain/DomainItem.vue';
import domainStore from '@/store/scope';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import { DOMAIN_STATUS } from '@/ui/common/constants/domain';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
import router from '@/ui/router';

const _domainStore = domainStore();
const isLoadingDomain = computed(() => _domainStore.getLoading);
const keySearch = ref('');
const allDomains = computed(() => _domainStore.allDomains);
const allFilteredDomains = computed(() => {
  const filteredByNameList = keySearch.value
    ? allDomains.value.filter((domain) =>
        stringNomalize(domain.name?.toLowerCase()).includes(
          stringNomalize(keySearch.value?.toLowerCase())
        )
      )
    : [...allDomains.value];

  if (isFilterByVisible.value == DOMAIN_STATUS.VISIBLE.key)
    return filteredByNameList?.filter((domain) => !domain?.invisible);

  if (isFilterByVisible.value == DOMAIN_STATUS.INVISIBLE.key)
    return filteredByNameList?.filter((domain) => domain?.invisible);

  return filteredByNameList;
});

onMounted(() => {});

const onReload = async () => {
  keySearch.value = '';

  _domainStore.isLoadingDomain = true;

  await _domainStore.fetchAllDomainProjects();

  _domainStore.isLoadingDomain = false;
};

const onSearch = (keySearch) => {
  keySearch.value = keySearch;
};

const isFilterByVisible = ref<'ALL' | 'VISIBLE' | 'INVISIBLE'>('ALL');

const inputSearchDomain = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath.includes('project-management')
  ) {
    inputSearchDomain.value?.focus();
    e.preventDefault();
  }
});
</script>

<template>
  <div class="h-full flex-1 min-h-0 flex flex-col space-y-1 overflow-y-hidden">
    <!-- table -->
    <div class="w-full py-2 flex justify-between items-center">
      <div class="flex items-center w-80">
        <VigSearchBox
          ref="inputSearchDomain"
          v-model="keySearch"
          class="w-full"
          :placeholder="$t('PROJECTMANAGEMENT_LABEL_NEWDOMAIN_NAME') + '...'"
          @update:model-value="onSearch(keySearch)"
        />
      </div>
      <div class="flex-center space-x-4">
        <SynRadio
          v-for="(item, index) in (Object.values(DOMAIN_STATUS) as any)"
          :key="index"
          v-model="isFilterByVisible"
          :value="item.key"
          :label="$t(item.name) || item.name"
        />
      </div>
      <div class="flex items-center space-x-2">
        <span
          class="
            text-xs
            rounded
            py-1
            px-2
            border border-current-400
            text-current-500
          "
          >{{ $t('COMMON_LABEL_TOTAL') + ': ' + allDomains?.length || 0 }}</span
        >
      </div>
    </div>
    <div class="h-full flex-1 overflow-y-auto small-scrollbar">
      <SynTableCustom>
        <!-- HEADER -->
        <template #header>
          <SynTr>
            <SynTh :label="$t('PROJECTMANAGEMENT_LABEL_NAME')" />
            <SynTh :label="$t('PROJECTMANAGEMENT_LABEL_DESCRIPTION')" />
            <SynTh :label="$t('DASHBOARD_TABLE_LABEL_MEMBER')" />
            <SynTh :label="$t('TASK_TABLE_LABEL_CREATOR')" />
            <SynTh :label="$t('TASK_TABLE_LABEL_CREATED_DATE')" />
            <SynTh :label="$t('USERMANAGEMENT_TABLE_STATUS')">
              <template #custom>
                <vig-dropdown>
                  <template #dropdown-toggle>
                    <div
                      v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER')"
                      class="w-6 h-6 flex-center relative"
                    >
                      <SynIcon
                        name="filter2"
                        class="cursor-pointer"
                        :custom-class="`w-3.5 h-3.5 ${
                          isFilterByVisible !== DOMAIN_STATUS.ALL.key
                            ? 'fill-current'
                            : 'fill-gray-500'
                        } `"
                      />
                    </div>
                  </template>
                  <template #dropdown-menu>
                    <div
                      v-for="status in (Object.values(DOMAIN_STATUS) as any)"
                      :key="status"
                    >
                      <div
                        class="
                          pl-2
                          pr-8
                          py-2
                          hover:bg-gray-50
                          cursor-pointer
                          w-32
                          dropdown-item
                          text-sm
                        "
                        :class="
                          status?.key === isFilterByVisible
                            ? 'bg-current-50 font-medium text-current-500'
                            : ''
                        "
                        @click="isFilterByVisible = status?.key"
                      >
                        <span>{{ $t(status?.name) || status?.name }}</span>
                      </div>
                    </div>
                  </template>
                </vig-dropdown>
              </template>
            </SynTh>
            <SynTh position-text-header="justify-center">
              <div class="flex-center w-full">
                <button
                  v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                  class="
                    flex-center
                    rounded-full
                    hover:fill-current
                    fill-gray-500
                    w-6
                    h-6
                  "
                  @click="onReload"
                >
                  <SynIcon name="reload" class="w-4 h-4" />
                </button>
              </div>
            </SynTh>
          </SynTr>
        </template>
        <!-- BODY -->
        <template #body>
          <SynTableTrLoading v-if="isLoadingDomain">
            <div v-for="item in 6" :key="item" class="table-cell p-2 h-96">
              <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
            </div>
          </SynTableTrLoading>
          <!-- DOMAIN AREA -->
          <template v-else-if="allFilteredDomains?.length > 0">
            <DomainItem
              v-for="domain in allFilteredDomains"
              :key="domain.id"
              :domain-id="domain.id"
            />
          </template>
          <template v-else>
            <SynTr class="h-full" hover="hover:bg-current-50">
              <SynTd :colspan="10" style="height: calc(100vh - 20rem)">
                <SynAnimation
                  name="searchIcon"
                  stype="width: 200px; height: 200px"
                  :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                />
              </SynTd>
            </SynTr>
          </template>
        </template>
      </SynTableCustom>
    </div>
  </div>
</template>
