<script setup lang="ts"></script>

<template>
  <svg
    height="512"
    viewBox="0 0 32 32"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="a9148db4-8702-4948-b3d7-c33f0782daf4" data-name="Download">
      <path
        d="m28 24v-4a1 1 0 0 0 -2 0v4a1 1 0 0 1 -1 1h-18a1 1 0 0 1 -1-1v-4a1 1 0 0 0 -2 0v4a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3zm-6.38-5.22-5 4a1 1 0 0 1 -1.24 0l-5-4a1 1 0 0 1 1.24-1.56l3.38 2.7v-13.92a1 1 0 0 1 2 0v13.92l3.38-2.7a1 1 0 1 1 1.24 1.56z"
      />
    </g>
  </svg>
</template>

<style scoped></style>
