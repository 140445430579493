<script setup lang="ts">
import { onMounted } from 'vue';
import systemStore from '@/store/system';
import { StorageConstant } from '@/ui/hooks/storageHook';

const _systemStore = systemStore();
const onReload = () => {
  localStorage.setItem(StorageConstant.NEW_DEPLOY, 'false');
  window.location.reload();
};

let timeout;

onMounted(() => {
  if (timeout) clearTimeout(timeout);

  timeout = setTimeout(() => {
    _systemStore.isNewlyDeployed = false;
  }, 4000);
});
</script>

<template>
  <div
    class="
      announcement_tab
      w-full
      flex-center
      text-center
      flex-wrap
      py-1.5
      px-2
      text-sm
      relative
      bg-gradient-to-r
      from-cyan-400
      to-current-400
    "
  >
    <div class="flex-center space-x-2">
      <syn-icon
        name="blink"
        custom-class="fill-white text-white w-4 h-4"
      ></syn-icon>
      <span class="text-white items-center">{{
        $t('ANNOUNCEMENT_NEW_DEPLOYMENT_TEXT')
      }}</span>
      <div
        class="
          text-xs
          font-medium
          px-1.5
          py-1
          rounded
          bg-yellow-400
          hover:bg-yellow-500
          cursor-pointer
          ml-3
          text-white
        "
        @click="onReload"
      >
        {{ $t('NOTIFICATION_RELOAD') }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.announcement_tab {
  display: flex;
  transition: display 1s;
}
</style>
