<script setup lang="ts">
import { computed, ref } from 'vue';

import whiteboardStore from '@/store/whiteboard';
import { ICreateWhiteboardShapeInput } from '@/application/repositories/WhiteboardRepository';
import { ShapeType } from '@/ui/components/drawer/schema';
import DesktopDrawerShapeTask from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeTask.vue';
// import DesktopDrawerShapeArrow from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeArrow.vue';
import DesktopDrawerShapeText from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeText.vue';
import DesktopDrawerShapeUser from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeUser.vue';
import DesktopDrawerShapeDrawNote from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeDrawNote.vue';
import DesktopDrawerShapeTodoList from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeTodoList.vue';
import DesktopDrawerShapeMedia from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeMedia.vue';
import DesktopDrawerShapeLink from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeLink.vue';
import { DEFAULT_VALUE_BY_SHAPE } from '@/application/constants/whiteboard';
import CheckboxButton from '@/ui/components/common/CheckboxButton.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

withDefaults(
  defineProps<{
    isLoading?: boolean;
    drawNoteData: {
      isLoading: boolean;
      drawData: any;
      images: any[];
      originFile: string;
      readonly: boolean;
    };
    pastedImage?: any;
    isHiddenClose?: boolean;
  }>(),
  {
    isLoading: false,
    pastedImage: undefined,
    isHiddenClose: false,
    drawNoteData: () => {
      return {
        isLoading: false,
        drawData: {},
        images: [],
        originFile: '',
        readonly: false,
      };
    },
  }
);

const emit = defineEmits(['onClose', 'onSave', 'onCollapse', 'onRestore']);

const _whiteboardStore = whiteboardStore();

const recycleShapeList = computed<ICreateWhiteboardShapeInput[]>(() => {
  return _whiteboardStore.recycleShapeList;
});
const recycleShapeUserList = computed<ICreateWhiteboardShapeInput[]>(() => {
  return (
    recycleShapeList.value
      ?.filter((shape) => shape.type == ShapeType.USER)
      ?.map((shape) => {
        {
          return {
            ...shape,
            width: DEFAULT_VALUE_BY_SHAPE[ShapeType.USER].width,
            height: DEFAULT_VALUE_BY_SHAPE[ShapeType.USER].height,
          };
        }
      }) || []
  );
});
const recycleShapeTaskList = computed<ICreateWhiteboardShapeInput[]>(() => {
  return (
    recycleShapeList.value
      ?.filter((shape) => shape.type == ShapeType.TASK)
      ?.map((shape) => {
        {
          return {
            ...shape,
          };
        }
      }) || []
  );
});
const recycleShapeImageList = computed<ICreateWhiteboardShapeInput[]>(() => {
  return (
    recycleShapeList.value
      ?.filter(
        (shape) =>
          shape.type == ShapeType.DRAW_NOTE ||
          shape.type == ShapeType.MEDIA_IMAGE ||
          shape?.type == ShapeType.MEDIA_AUDIO ||
          shape?.type == ShapeType.MEDIA_DOCUMENT ||
          shape?.type == ShapeType.MEDIA_VIDEO
      )
      ?.map((shape) => {
        {
          return {
            ...shape,
            width: DEFAULT_VALUE_BY_SHAPE[ShapeType.USER].width,
            height: DEFAULT_VALUE_BY_SHAPE[ShapeType.USER].height,
          };
        }
      }) || []
  );
});
const recycleShapeTextList = computed<ICreateWhiteboardShapeInput[]>(() => {
  return (
    recycleShapeList.value
      ?.filter((shape) => shape.type == ShapeType.TEXT)
      ?.map((shape) => {
        {
          return {
            ...shape,
            width: DEFAULT_VALUE_BY_SHAPE[ShapeType.USER].width,
            height: DEFAULT_VALUE_BY_SHAPE[ShapeType.USER].height,
          };
        }
      }) || []
  );
});
const recycleShapeTodoList = computed<ICreateWhiteboardShapeInput[]>(() => {
  return (
    recycleShapeList.value
      ?.filter((shape) => shape.type == ShapeType.TODO_LIST)
      ?.map((shape) => {
        {
          return {
            ...shape,
          };
        }
      }) || []
  );
});
const recycleShapeLinkList = computed<ICreateWhiteboardShapeInput[]>(() => {
  return (
    recycleShapeList.value
      ?.filter((shape) => shape.type == ShapeType.MEDIA_LINK)
      ?.map((shape) => {
        {
          return {
            ...shape,
          };
        }
      }) || []
  );
});

const drawerWidgetRef = ref<any>(null);
const onCloseModal = () => {
  drawerWidgetRef.value?.onCancel && drawerWidgetRef.value?.onCancel();
};

const selectedShapeList = ref<any>([]);
const isSelecting = computed<any>(() => {
  return selectedShapeList.value?.length > 0;
});
const onSelectShape = (shape) => {
  const isExisted = selectedShapeList.value?.some((s) => s?.id == shape?.id);
  if (isExisted) {
    selectedShapeList.value = selectedShapeList.value?.filter(
      (s) => s?.id !== shape?.id
    );
  } else {
    const _shape = recycleShapeList.value?.find((s) => s?.id == shape?.id);

    if (_shape) selectedShapeList.value.push(_shape);
  }
};

const checkSelected = (shape) => {
  return selectedShapeList.value?.some((s) => s?.id == shape?.id);
};

const handleRestoreShape = () => {
  emit('onRestore', selectedShapeList.value);
  selectedShapeList.value.forEach((shape) => {
    _whiteboardStore.deleteRecycleShape(shape?.id);
  });
};

const isDisableButton = computed<boolean>(() => {
  return !selectedShapeList.value || selectedShapeList.value?.length == 0;
});

const handlePermanentlyDelete = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('WHITEBOARD_PERMANENTLY_DELETE_SHAPE_CONFIRM'),
    confirmable: true,
    confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
    closeable: true,
  });

  const confirmed = await ask();

  if (confirmed) {
    selectedShapeList.value.forEach((shape) => {
      _whiteboardStore.deleteRecycleShape(shape?.id);
    });

    selectedShapeList.value = [];
  }
};
</script>

<template>
  <SynModal
    is-hidden-footer
    is-hidden-header
    :is-hidden-close="isHiddenClose"
    disable-click-outside
    disable-esc-to-close
    disable-auto-focus
    disable-element-active
    style-body="p-0"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :container-style="`
          max-height: 90%;
          max-width: 90%;
          min-height:500px;
          min-width:600px;
          width: 100%;
          height: 100%;
          `"
    :background-style="`padding: 20px;`"
    @cancel="onCloseModal"
  >
    <template #body>
      <div
        class="relative flex flex-col overflow-hidden"
        style="
          max-height: 100%;
          max-width: 100%;
          min-height: 500px;
          min-width: 600px;
          width: 100%;
          height: 100%;
        "
      >
        <div class="text-lg font-medium text-gray-600 shadow-sm p-4">
          <div class="flex items-center">
            <span class="text-base"> Recycle shape </span>
          </div>
        </div>
        <div
          class="
            flex-1
            min-h-0
            overflow-auto
            small-scrollbar
            flex flex-col
            gap-4
            w-full
            p-4
          "
        >
          <div
            v-if="!recycleShapeList || recycleShapeList?.length == 0"
            class="flex-center flex-col"
          >
            <SynAnimation name="something-wrong" stype="width: 200px;" />
            <div>The store is empty</div>
          </div>
          <template v-else>
            <!-- USER -->
            <div
              v-if="recycleShapeUserList?.length > 0"
              class="flex flex-col gap-2"
            >
              <div>Users</div>
              <div class="flex items-center gap-2 flex-wrap">
                <div
                  v-for="shape in recycleShapeUserList"
                  :key="shape?.id"
                  class="
                    flex-center
                    border
                    rounded
                    relative
                    hover-to-show__parent
                  "
                >
                  <DesktopDrawerShapeUser :shape-entity="shape" />
                  <CheckboxButton
                    :is-selecting="isSelecting"
                    :is-selected="checkSelected(shape)"
                    @on-select="onSelectShape(shape)"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="recycleShapeTaskList?.length > 0"
              class="flex flex-col gap-2"
            >
              <div>Tasks</div>
              <div class="flex items-center gap-2 flex-wrap">
                <div
                  v-for="shape in recycleShapeTaskList"
                  :key="shape?.id"
                  class="
                    flex-center
                    border
                    rounded
                    relative
                    hover-to-show__parent
                  "
                >
                  <CheckboxButton
                    :is-selecting="isSelecting"
                    :is-selected="checkSelected(shape)"
                    @on-select="onSelectShape(shape)"
                  />
                  <DesktopDrawerShapeTask :shape-entity="shape" />
                </div>
              </div>
            </div>
            <div
              v-if="recycleShapeImageList?.length > 0"
              class="flex flex-col gap-2"
            >
              <div>Attachments</div>
              <div class="flex items-center gap-2 flex-wrap">
                <div
                  v-for="shape in recycleShapeImageList"
                  :key="shape?.id"
                  class="
                    flex-center
                    border
                    rounded
                    relative
                    hover-to-show__parent
                  "
                >
                  <CheckboxButton
                    :is-selecting="isSelecting"
                    :is-selected="checkSelected(shape)"
                    @on-select="onSelectShape(shape)"
                  />
                  <template v-if="shape?.type == ShapeType.DRAW_NOTE">
                    <DesktopDrawerShapeDrawNote :shape-entity="shape" />
                  </template>
                  <template
                    v-if="
                      shape?.type == ShapeType.MEDIA_AUDIO ||
                      shape?.type == ShapeType.MEDIA_IMAGE ||
                      shape?.type == ShapeType.MEDIA_DOCUMENT ||
                      shape?.type == ShapeType.MEDIA_VIDEO
                    "
                  >
                    <DesktopDrawerShapeMedia :shape-entity="shape" />
                  </template>
                </div>
              </div>
            </div>

            <div
              v-if="recycleShapeTodoList?.length > 0"
              class="flex flex-col gap-2"
            >
              <div>Todo list</div>
              <div class="flex items-center gap-2 flex-wrap">
                <div
                  v-for="shape in recycleShapeTodoList"
                  :key="shape?.id"
                  class="
                    flex-center
                    border
                    rounded
                    relative
                    hover-to-show__parent
                  "
                >
                  <CheckboxButton
                    :is-selecting="isSelecting"
                    :is-selected="checkSelected(shape)"
                    @on-select="onSelectShape(shape)"
                  />
                  <DesktopDrawerShapeTodoList :shape-entity="shape" readonly />
                </div>
              </div>
            </div>
            <div
              v-if="recycleShapeLinkList?.length > 0"
              class="flex flex-col gap-2"
            >
              <div>Links</div>
              <div class="flex items-center gap-2 flex-wrap">
                <div
                  v-for="shape in recycleShapeLinkList"
                  :key="shape?.id"
                  class="
                    flex-center
                    border
                    rounded
                    relative
                    hover-to-show__parent
                  "
                >
                  <CheckboxButton
                    :is-selecting="isSelecting"
                    :is-selected="checkSelected(shape)"
                    @on-select="onSelectShape(shape)"
                  />
                  <DesktopDrawerShapeLink :shape-entity="shape" />
                </div>
              </div>
            </div>
            <div
              v-if="recycleShapeTextList?.length > 0"
              class="flex flex-col gap-2"
            >
              <div>Text</div>
              <div class="flex items-center gap-2 flex-wrap">
                <div
                  v-for="shape in recycleShapeTextList"
                  :key="shape?.id"
                  class="
                    flex-center
                    border
                    rounded
                    relative
                    hover-to-show__parent
                  "
                >
                  <CheckboxButton
                    :is-selecting="isSelecting"
                    :is-selected="checkSelected(shape)"
                    @on-select="onSelectShape(shape)"
                  />
                  <DesktopDrawerShapeText :shape-entity="shape" />
                </div>
              </div>
            </div>
          </template>
        </div>
        <div
          class="
            p-4
            flex
            justify-end
            items-center
            hover:text-current
            cursor-pointer
            space-x-2
            vig-modal-footer
          "
        >
          <SynButton type-text color="gray" @click="$emit('onClose')">
            <span> {{ $t('COMMON_LABEL_CANCEL') }}</span>
          </SynButton>
          <SynButton :disabled="isDisableButton" @click="handleRestoreShape">
            <span class="do-not-close-dropdown">
              {{ $t('COMMON_LABEL_RESTORE') || 'Restore' }}</span
            >
          </SynButton>
          <SynButton
            color="red"
            :disabled="isDisableButton"
            @click="handlePermanentlyDelete"
          >
            <span class="do-not-close-dropdown">
              {{
                $t('COMMON_LABEL_PERMANENTLY_DELETE') || 'Permanently Delete'
              }}</span
            >
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
