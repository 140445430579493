<script setup lang="ts">
// *** IMPORTS ***
import { computed, onMounted, ref, watch } from 'vue';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import CalendarService from '@/application/services/CalendarService';
import { useRoute, useRouter } from 'vue-router';
import { CalendarSharingEventModel } from '@/application/models/calendar/CalendarSharingModel';
import { EventInput } from '@fullcalendar/common';
import { EventDetailItemClass } from '@/domain/entities/calendars/CalendarsClass';
import { renderItemEvent } from '@/ui/components/calendars/event-item/render-component';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarSharingViewSelection from '@/ui/pages/calendar-sharing/calendar-view-selection/CalendarSharingViewSelection.vue';
import {
  breakpointsSematic,
  useResizeObserver,
  useWindowSize,
} from '@vueuse/core';
import { getRRuleItemCalendar } from '@/application/parsers/CalendarParser';
import rrulePlugin from '@fullcalendar/rrule';
import tippy from 'tippy.js';
import SharingEventDetail from '@/ui/modules/calendar/calendar-sharing/sharing-event-detail/SharingEventDetail.vue';
import ModalSharingEventDetail from '@/ui/modules/calendar/calendar-sharing/modal-sharing-event-detail/ModalSharingEventDetail.vue';
import { isMobile } from '@/ui/helpers/utils';
import viLocale from '@fullcalendar/core/locales/vi';
import frLocale from '@fullcalendar/core/locales/fr';
import appStore from '@/store/app';
import { renderDayoffItemEvent } from '@/ui/components/calendars/event-item/dayoff-render-component';
import { DayoffDetailItemClass } from '@/domain/entities/dayoff/DayoffCalendarItemClass';
import { CalendarType } from '@/ui/common/constants/calendar';
import CalendarViewOptions from '@/ui/pages/calendar-sharing/calendar-view-options/CalendarViewOptions.vue';

// *** PROPS, EMITS ***

// *** PRIVATE VARIABLES ***
const _route = useRoute();
const _router = useRouter();
const _appStore = appStore();

let _calendar: Calendar;
let _previousView: string;

// *** COMPOSABLES ***
const { width: windowWidth, height: windowHeight } = useWindowSize();

// *** REFS ***
const isFetching = ref<boolean>(true);
const pageRef = ref<HTMLDivElement>();
const calendarRef = ref<HTMLDivElement>();
const calendarTitle = ref<string>();
const calendarView = ref<string>();
const calendarViewOptions = ref<any>({ isShowDescription: true });
const events = ref<CalendarSharingEventModel[]>();
const eventDetailRef = ref<HTMLDivElement>();
const eventDetail = ref<EventInput | null>();
const isOpenModalDetail = ref<boolean>();
const pageHeight = ref<number>();

// *** COMPUTED ***
const isMobileSize = computed(
  () => windowWidth.value < breakpointsSematic.mobileL
);
const isMobileView = computed(() => isMobileSize.value || isMobile());

// *** WATCHES ***
watch(
  () => _route.query,
  (query) => {
    if (_previousView && !query?.view) {
      _calendar?.changeView(_previousView);
    }
  }
);

watch(
  () => windowWidth.value,
  () => {
    _calculateEventWidthInListView();
  }
);

watch(
  () => isMobileView.value,
  (lang) => {
    _calendar?.render();
  }
);

watch(
  () => _appStore.language,
  (lang) => {
    _calendar?.setOption('locale', lang);
  }
);

useResizeObserver(pageRef, () => {
  _calculatePageHeight();
});

// *** HOOKS ***
onMounted(() => {
  _calculatePageHeight();
  _initCalendar();
  _getSharingInfo();
});

// *** HANDLER FUNCTIONS ***
const onPrevClick = () => {
  _calendar?.prev();
};

const onNextClick = () => {
  _calendar?.next();
};

const onViewClick = (view) => {
  _calendar?.changeView(view);
};

const onViewOptionChange = () => {
  _calendar?.render();
};

const onDateClick = (info) => {
  if (_checkToNavigateDayInMobile(info?.dateStr)) {
    return;
  }
};

const onEventClick = (info) => {
  // Open event detail
  eventDetail.value = {
    ...(info?.event?.extendedProps || {}),
    startDate: info?.event?.start,
    endDate: info?.event?.end,
  };

  // Open modal on mobile
  isOpenModalDetail.value = isMobileView.value;

  // Else, Open pover
  if (!isOpenModalDetail.value) _openEventDetailPopover(info);
};

// *** PRIVATE FUNCTIONS ***
const _initCalendar = () => {
  if (!calendarRef.value) return;

  _calendar = new Calendar(calendarRef.value, {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      interactionPlugin,
      rrulePlugin,
    ],
    locales: [viLocale, frLocale],
    locale: _appStore.language,
    height: '100%',
    nowIndicator: true,
    fixedWeekCount: false,
    firstDay: 1,
    dayMaxEventRows: 3,
    headerToolbar: {
      start: 'today prev,next',
      center: 'title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    views: {
      timeGrid: {
        dayMaxEventRows: 10,
      },
    },
    datesSet: () => {
      calendarTitle.value = _calendar.view?.title;
      calendarView.value = _calendar.view?.type;

      _calculateEventWidthInListView();
    },
    eventContent: (arg) => {
      if (!arg?.event?.extendedProps) return null;

      const options = {
        isShowDescription:
          calendarViewOptions.value?.isShowDescription &&
          (!isMobileView.value ||
            ['timeGridDay', 'listWeek'].includes(_calendar.view?.type)),
        isShowIconOnly:
          isMobileView.value &&
          ['dayGridMonth', 'timeGridWeek'].includes(_calendar.view?.type),
      };

      if (arg?.event?.extendedProps?.type === CalendarType.DayOff) {
        const dayOffDetail = _prepareDayOffDetail(arg?.event?.extendedProps);
        if (!dayOffDetail) return null;

        const dayOffEl = renderDayoffItemEvent(dayOffDetail, options);
        return { domNodes: [dayOffEl] };
      }

      const eventDetail = new EventDetailItemClass(arg?.event?.extendedProps);
      if (!eventDetail) return null;

      const eventEl = renderItemEvent(eventDetail, options);
      return { domNodes: [eventEl] };
    },
    dateClick: onDateClick,
    eventClick: onEventClick,
  });

  _calendar.render();
};

const _getSharingInfo = async () => {
  events.value = [];
  isFetching.value = true;

  try {
    const sharingId = _route.params['sharingId'];

    if (sharingId) {
      const res = await CalendarService.getInstance().getCalendarSharing(
        sharingId
      );

      if (!res?.result) _router.push({ name: 'NotFound404' });

      events.value = res?.result?.events;
    }

    // Refresh events in calendar
    _calendar?.removeAllEvents();

    events.value?.forEach((event) => {
      _calendar?.addEvent(_prepareCalendarEvent(event));
    });

    isFetching.value = false;
  } catch (e) {
    console.log(e);
  }
};

const _prepareCalendarEvent = (event: CalendarSharingEventModel) => {
  if (!event) return null;

  return {
    id: event.id,
    title: event.title,
    start: event.startDate,
    end: event.endDate,
    allDay: event.allDay,
    rrule: getRRuleItemCalendar(
      event.startDate,
      event.repeatType,
      event.repeatSpecificDaysOfWeek,
      event.repeatRRules
    ),
    extendedProps: event,
  } as EventInput;
};

const _prepareDayOffDetail = (event: CalendarSharingEventModel) => {
  if (!event) return null;

  return new DayoffDetailItemClass({
    id: event.id,
    startDate: event.startDate,
    endDate: event.endDate,
    type: event.dayOff?.type,
    status: event.dayOff?.status,
    exception: {
      type: event.dayOff?.exceptionType,
    },
    userName: event.dayOff?.user?.name,
    userAvatar: event.dayOff?.user?.avatar,
  });
};

const _openEventDetailPopover = (info) => {
  if (info?.el?._tippy) return info?.el?._tippy.hide();

  tippy(info?.el, {
    appendTo: () => document.body,
    content: eventDetailRef.value,
    theme: 'light',
    trigger: 'manual',
    placement: 'top',
    maxWidth: 'none',
    allowHTML: true,
    interactive: true,
    showOnCreate: true,
    hideOnClick: false,
    zIndex: 50,
    popperOptions: {
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom', 'auto'],
          },
        },
      ],
    },
    onShow() {
      setTimeout(() => info?.el?._tippy?.popperInstance?.update());
    },
    onHide() {
      eventDetail.value = null;
      setTimeout(() => info?.el?._tippy?.destroy());
    },
    onClickOutside(instance, event) {
      let isClickModal = false;

      const modals = document.querySelectorAll('.vig-modal-background');
      modals.forEach((modal) => {
        if (modal?.contains(event.target)) isClickModal = true;
      });

      if (isClickModal) return;

      instance.hide();
    },
  });
};

const _checkToNavigateDayInMobile = (dateStr) => {
  // Change to view day on mobile
  if (
    isMobileView.value &&
    ['dayGridMonth', 'timeGridWeek'].includes(_calendar?.view?.type)
  ) {
    _previousView = _calendar?.view?.type;

    _calendar?.gotoDate(dateStr);
    _calendar?.changeView('timeGridDay');

    _router.push({
      query: {
        view: _calendar.view?.type,
      },
    });

    return true;
  }

  return false;
};

const _calculatePageHeight = () => {
  const headerEl = document.querySelector('.page-header');

  pageHeight.value = window.innerHeight - (headerEl?.clientHeight || 0);
};

const _calculateEventWidthInListView = () => {
  const eventsListEl = document.querySelector(
    '.shared-calendar .fc-list.fc-listWeek-view'
  );
  if (!eventsListEl) return;

  const eventsRowEl = eventsListEl.querySelectorAll(
    '.fc-list-table .fc-list-event'
  );

  eventsRowEl?.forEach((rowEl) => {
    const eventTitleEl = rowEl?.querySelector('.fc-list-event-title > div');
    if (!eventTitleEl) return;

    if (_calendar.view?.type === 'listWeek') {
      const eventTimeEl = rowEl?.querySelector('.fc-list-event-time');
      const eventGraphicEl = rowEl?.querySelector('.fc-list-event-graphic');
      const paddingX = 14;

      eventTitleEl.style.width =
        eventsListEl.clientWidth -
        paddingX * 2 -
        (eventTimeEl?.clientWidth || 0) -
        (eventGraphicEl?.clientWidth || 0) +
        'px';
    } else {
      eventTitleEl.style.width = '100%';
    }
  });
};

// *** EXPOSES ***
</script>

<template>
  <div
    ref="pageRef"
    class="flex flex-col"
    :style="{ height: pageHeight + 'px' }"
  >
    <!--HEADER-->
    <div class="flex items-center justify-between gap-2 p-2 bg-gray-50">
      <div class="flex-center gap-1">
        <VigButton light color="gray" icon="ChevronLeft" @click="onPrevClick" />
        <VigButton
          light
          color="gray"
          icon="ChevronRight"
          @click="onNextClick"
        />
      </div>
      <div
        class="font-semibold leading-4"
        :class="{ 'text-lg': !isMobileSize }"
      >
        {{ calendarTitle }}
      </div>
      <div class="flex items-center gap-1">
        <CalendarSharingViewSelection
          v-model:calendarView="calendarView"
          @update:calendar-view="onViewClick"
        />
        <CalendarViewOptions
          v-model:view-options="calendarViewOptions"
          @update:view-options="onViewOptionChange"
        />
      </div>
    </div>

    <!--CALENDAR-->
    <div class="flex-1 overflow-hidden shared-calendar" ref="calendarRef"></div>
  </div>

  <!--EVENT DETAIL POPOVER-->
  <div ref="eventDetailRef">
    <SharingEventDetail
      v-if="eventDetail && !isOpenModalDetail"
      :event="eventDetail"
      class="min-w-[25rem] p-4"
    />
  </div>

  <!--EVENT DETAIL MODAL-->
  <ModalSharingEventDetail
    v-if="isOpenModalDetail"
    :event="eventDetail"
    @on-close="
      isOpenModalDetail = false;
      eventDetail = null;
    "
  />

  <SynLoading v-if="isFetching" />
</template>

<style>
.shared-calendar {
  .fc .fc-daygrid-day.fc-day-today,
  .fc .fc-timegrid-col.fc-day-today {
    background-color: #fefce8 !important;
  }

  th[role='columnheader'] {
    border-bottom: 1px solid #ddd !important;
  }
}
</style>
