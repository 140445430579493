<script setup lang="ts">
withDefaults(
  defineProps<{
    selectedIds?: any[];
    filteredUsers?: any[];
  }>(),
  {
    selectedIds: () => [],
    filteredUsers: () => [],
  }
);

const emit = defineEmits(['onChooseAll']);
const onChangeSelectAll = (isSelected) => {
  emit('onChooseAll', isSelected);
};
</script>
<template>
  <div class="flex items-center gap-2 text-sm">
    <div
      v-if="
        selectedIds?.length > 0 && selectedIds?.length < filteredUsers?.length
      "
      class="flex-center rounded-full cursor-pointer space-x-2"
      @click="onChangeSelectAll"
    >
      <div
        class="
          h-4
          w-4
          flex-center
          rounded
          border border-current-300
          bg-current-300
        "
      >
        <SynIcon name="minus-line" custom-class="w-2.5 fill-white" />
      </div>
      <span></span>
    </div>
    <AtomCheckbox
      v-else
      id="msg-forward-user-selected-all"
      :model-value="selectedIds?.length >= filteredUsers?.length"
      @update:model-value="onChangeSelectAll"
    />
    <label
      class="flex items-center space-x-2 cursor-pointer"
      for="msg-forward-user-selected-all"
    >
      <span>
        {{ $t('COMMON_LABEL_ALL') }}
        <template v-if="selectedIds?.length > 0">
          ({{ selectedIds?.length
          }}<template v-if="selectedIds?.length < filteredUsers?.length"
            >/{{ filteredUsers?.length }} </template
          >)
        </template>
      </span>
    </label>
  </div>
</template>
