import { ref } from 'vue';
import { TaskFromChat } from '@/domain/entities/task/TaskFromChat';
import { ITaskTodo } from '@/application/types/task/task.types';

export const isShowTaskDetailGlobal = ref(false);
export const taskIdGlobal = ref(0);
export const taskCodeGlobal = ref('');
export const isOpenTaskDrawer = ref(false);
export const isLoadingCacheNewTask = ref(true);
export const isOpenCreateTaskLocal = ref(false);
export const isOpenCreateTaskGroup = ref(false);
export const lastAutoCreateTime = ref(0);

export const allowedExtensionString =
    '.txt,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.gif,.bmp,.mp3,.zip,.rar';

// CREATE TASK GLOBAL IN BOTTOM
export const isOpenCreateTaskGlobal = ref(false);

// HANDLE CREATE TASK IN DRAWER
export const isOpenCreateTaskDrawer = ref(false);
export interface INewTaskDefaultPayload {
    groupId?: number;
    assigneeId?: number;
    name?: string;
    description?: string;
    files?: File[];
    checklist?: ITaskTodo[];
    taskFromChat?: TaskFromChat | null;
    scheduleTime?: string;
    domainId?: number | null;
    projectId?: number | null;
    subprojectId?: number | null;
    taskWorkflow?: any;
}
export const newTaskDefaultPayload = ref<INewTaskDefaultPayload | undefined>(
    undefined
);

export const onCreateGlobalSuccess = ref((_task) => {});
export const attachmentToTaskGlobal = ref<{
    isOpen: boolean;
    attachments?: File[];
    checklist?: ITaskTodo[];
    description?: string;
}>({
    isOpen: false,
});

export const allAutoCreateProcessByIds = ref<any>({});
export const allAutoCreateProcessIntervalByIds = ref<any>({});
export const global_runAutoCreateProcess = (newTabId, callback) => {
    allAutoCreateProcessByIds.value[newTabId] = {
        isRunning: true,
        remainTime: 10,
    };

    allAutoCreateProcessIntervalByIds.value[newTabId] = setInterval(() => {
        if (
            !Object.prototype.hasOwnProperty.call(
                allAutoCreateProcessByIds.value,
                newTabId
            )
        )
            return;

        allAutoCreateProcessByIds.value[newTabId].remainTime -= 1;
        if (allAutoCreateProcessByIds.value[newTabId].remainTime == 0) {
            allAutoCreateProcessByIds.value[newTabId].isRunning = false;
            if (allAutoCreateProcessIntervalByIds.value[newTabId])
                clearInterval(
                    allAutoCreateProcessIntervalByIds.value[newTabId]
                );

            if (callback) callback();
            delete allAutoCreateProcessIntervalByIds.value[newTabId];
        }
    }, 1000);
};

export const global_cancelAutoCreateProcess = (newTabId) => {
    if (
        Object.prototype.hasOwnProperty.call(
            allAutoCreateProcessByIds.value,
            newTabId
        )
    )
        allAutoCreateProcessByIds.value[newTabId] = {
            isRunning: false,
            remainTime: 0,
        };

    if (
        !Object.prototype.hasOwnProperty.call(
            allAutoCreateProcessIntervalByIds.value,
            newTabId
        )
    )
        return;

    if (allAutoCreateProcessIntervalByIds.value[newTabId])
        clearInterval(allAutoCreateProcessIntervalByIds.value[newTabId]);

    delete allAutoCreateProcessIntervalByIds.value[newTabId];
};
// PINNED TASKS
export const isOpenTaskPinnedDrawer = ref(false);
export const taskCreateNewRef = ref<any>();

export const configsByTaskIds = ref<any>({});
