<script setup lang="ts">
import { computed } from 'vue';
import { currentTimeDevice } from '@/ui/hooks/calendarHook';

import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const props = defineProps({
  isView: {
    type: Boolean,
    default: false,
  },
  propId: {
    type: String,
    default: '',
  },
  category: {
    type: String,
    default: 'all',
  },
  notificationItem: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits([
  'getNotificationLinkUrl',
  'handleClickMarkRead',
  'handleDeleteNotification',
]);

const notification = computed(() => props.notificationItem);

const handleCreateTimeNotification = (createdDate) => {
  if (!createdDate) {
    return;
  }
  let utcDate = currentTimeDevice().utcDate;

  const dayTimeZone = dayjs(createdDate).add(utcDate, 'hour');
  return dayjs(dayTimeZone).fromNow();
};

const handleClickMarkRead = (id) => {
  emit('handleClickMarkRead', id);
};
const handleDeleteNotification = (id) => {
  emit('handleDeleteNotification', id);
};
</script>

<template>
  <div
    :id="`noti-${notification?.id}-${propId}`"
    :class="!notification?.status ? 'bg-current-50 bg-opacity-30' : ''"
    class="
      item-noti
      relative
      border-b border-gray-200
      flex
      items-center
      px-2
      py-2
      text-sm text-gray-700
      hover:bg-current-50 hover:bg-opacity-50
      cursor-pointer
    "
  >
    <div class="relative flex items-center w-full">
      <syn-avatar-status
        :name="notification.departmentName || notification.department_name"
        :src="notification?.departmentAvatar?.replace('original', 'mini')"
        color-status="bg-current-400"
        name-icon="department"
      />
      <div class="w-full flex flex-col pl-3">
        <!-- P1: BODY NOTIFICATION -->
        <div class="flex flex-col pb-1">
          <a href="">
            <div
              :title="
                notification?.body ? notification?.body : notification?.taskName
              "
              class="pl-2 text-overflow-hidden"
              :class="
                !notification?.status
                  ? 'text-black font-medium'
                  : 'text-gray-700'
              "
            >
              {{
                notification?.body ? notification?.body : notification?.taskName
              }}
            </div>
          </a>
        </div>

        <!-- P3: GROUP NAME, EXECUTOR NAME -->
        <div
          class="flex items-center space-x-1 justify-between"
          style="padding-top: 2.5px"
        >
          <div class="flex flex-col">
            <div
              style="font-size: 11px"
              class="pl-2 w-full flex items-start space-x-1"
            >
              <span>
                <span>{{ $t('TASK_DETAIL_LABEL_BY') + ' ' }}</span>
                <span class="text-gray-600">
                  {{ notification?.executorFullName }}
                </span>
              </span>
            </div>
          </div>

          <div class="flex space-x-2 items-center">
            <span class="text-gray-500" style="font-size: 12px">
              {{
                handleCreateTimeNotification(
                  notification?.eventActionDate
                    ? notification?.eventActionDate
                    : notification?.createdDate
                )
              }}
            </span>
            <span
              v-if="!notification?.status"
              class="rounded-full w-2 h-2 bg-blue-500 mt-0.5"
            ></span>
          </div>
        </div>

        <!-- BUTTON: ACTION SEEN, REMOVE -->
        <div
          v-if="!isView"
          class="
            item-noti__action
            absolute
            right-0
            top-1
            flex
            items-center
            space-x-2
          "
        >
          <div
            v-if="!notification?.status"
            :title="$t('COMMON_LABEL_SEEN')"
            class="
              border border-green-500
              bg-white bg-opacity-70
              flex-center
              w-6
              h-6
              rounded-full
              text-green-500
              fill-green-500
              hover:fill-white hover:text-white hover:bg-green-500
            "
            @click.stop="handleClickMarkRead(notification?.id)"
          >
            <SynIcon name="check" size="small" />
          </div>

          <div
            :title="$t('NOTIFICATION_DELELTE_NOTIFICATION_SUB')"
            class="
              border border-red-500
              flex-center
              bg-white bg-opacity-70
              w-6
              h-6
              rounded-full
              text-red-500
              fill-red-500
              hover:fill-white hover:text-white hover:bg-red-500
            "
            @click.stop="handleDeleteNotification(notification?.id)"
          >
            <SynIcon
              name="cancel"
              size="small"
              custom-class="w-2.5 h-2.5"
              is-active
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item-noti:hover .item-noti__action {
  /* display: block; */
  visibility: visible;
}

.item-noti__action {
  /* display: none; */
  visibility: hidden;
}

.item-noti:active {
  visibility: visible;
}

.item-noti:active .item-noti__action {
  /* display: block; */
  visibility: visible;
}
</style>
