import { IPaymentConfig } from './../../../application/types/payment-configs.types';
import { IOrganizationDetail } from '@/application/types/organization/organization.type';
import {
    ELicenseType,
    EPlanState,
    IOrgCurrentPlan,
    IPlanCapacityByKey,
} from '@/application/types/plan.types';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import PaymentService from '@/application/services/payment/PaymentService';
import dayjs from 'dayjs';
import datetimeComposable from '@/ui/composables/common/datetime-composable';
import { EPlanId } from '@/application/constants/plan.const';
import {
    EPaymentPurpose,
    IPaymentPayload,
} from '@/application/types/payment.types';
export default class OrganizationPlan implements IOrgCurrentPlan {
    organizationId: number;
    license: ELicenseType;
    startDate: string;
    endDate: string;
    paymentMethod: null;
    billingCycle: null;
    period: null;
    periodUnit: null;
    nextBillingDate: null;
    planId: EPlanId;
    members: number;
    status: string;
    paymentStatus: string;
    lastInvoiceDate: null;
    lastInvoiceAmount: null;
    lastPayer: null;
    changedPlanDate: null;
    usageExceeded: boolean;
    payAtRegistration: boolean;
    stateCode: EPlanState;
    subStateCode:
        | 'DEFAULT'
        | 'INCOMING_EXPIRED'
        | 'INCOMING_EXPIRED_HIGHER'
        | 'INCOMING_EXPIRED_HIGHER_TODAY'
        | 'OVERDUE_LOWER';
    stateStartDate: string;
    stateEndDate: string;
    lastPayerName: null;
    lastPayerAvatar: null;
    lastPayerAvatarUrl: null;
    remainDay: number;
    isLifeTime: boolean;
    offer: {
        reason: string;
        endDate: string;
        remainDays: number;
        bonusDays: number;
    };
    firstDecidePaymentDate?: string;
    organizationInfo: IOrganizationDetail;
    paymentConfigs: IPaymentConfig;
    currentOrganizationCapacity: IPlanCapacityByKey;
    capacityByPlanIds: {
        fileTaskHistory: number;
        messageHistory: number;
        totalCall: number;
        totalGroup: number;
        totalMember: number;
        totalMessage: number;
        totalNote: number;
        totalStorage: number;
        totalStorageByUser: number;
        totalTask: number;
        totalVisitor: number;
    };

    constructor(
        data: any,
        orgInfo: any,
        _paymentConfigs: any,
        _currentOrganizationCapacity: any,
        _capacityByPlanIds: any
    ) {
        this.organizationId = data?.organizationId;
        this.license = data?.license;
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.paymentMethod = data?.paymentMethod;
        this.billingCycle = data?.billingCycle;
        this.period = data?.period;
        this.periodUnit = data?.periodUnit;
        this.nextBillingDate = data?.nextBillingDate;
        this.planId = data?.planId;
        this.members = data?.members;
        this.status = data?.status;
        this.paymentStatus = data?.paymentStatus;
        this.lastInvoiceDate = data?.lastInvoiceDate;
        this.lastInvoiceAmount = data?.lastInvoiceAmount;
        this.lastPayer = data?.lastPayer;
        this.changedPlanDate = data?.changedPlanDate;
        this.usageExceeded = data?.usageExceeded;
        this.payAtRegistration = data?.payAtRegistration;
        this.stateCode = data?.stateCode;
        this.subStateCode = data?.subStateCode;
        this.stateStartDate = data?.stateStartDate;
        this.stateEndDate = data?.stateEndDate;
        this.lastPayerName = data?.lastPayerName;
        this.lastPayerAvatar = data?.lastPayerAvatar;
        this.lastPayerAvatarUrl = data?.lastPayerAvatarUrl;
        this.remainDay = data?.remainDay;
        this.isLifeTime = data?.isLifeTime;
        this.offer = data?.offer;
        this.firstDecidePaymentDate = data?.firstDecidePaymentDate;

        this.organizationInfo = orgInfo;
        this.paymentConfigs = _paymentConfigs;
        this.currentOrganizationCapacity = _currentOrganizationCapacity;
        this.capacityByPlanIds = _capacityByPlanIds;
    }

    async canBuyMore(): Promise<{ isBuyMore: boolean; continue: boolean }> {
        if (this.stateCode !== EPlanState.PAID) {
            settingGlobalModal({
                type: 'warning',
                title: '',
                content:
                    translate('PLAN_EXPIRE_WARNING_BEFORE_BUY_MORE_USER') ||
                    'Please renew to continue using.',
                confirmLabel: translate('PAYMENT_EXTEND'),
                confirmable: true,
                closeable: true,
            });

            const answer = await ask();

            if (answer)
                return {
                    isBuyMore: false,
                    continue: true,
                };
            else
                return {
                    isBuyMore: false,
                    continue: false,
                };
        }

        return {
            isBuyMore: true,
            continue: true,
        };
    }
    getTotalUserBySize() {
        let totalUsers = 10;
        switch (this.organizationInfo?.size) {
            case '2-5':
                totalUsers = 5;
                break;
            case '6-10':
                totalUsers = 10;
                break;
            case '11-20':
                totalUsers = 20;
                break;
            case '21-50':
                totalUsers = 50;
                break;

            default:
                totalUsers = 10;
                break;
        }
        return totalUsers;
    }

    async useFreePackage() {
        try {
            const canDowngrade = await this.canDowngrade(EPlanId.FREE);
            if (!canDowngrade)
                return {
                    success: false,
                };

            settingGlobalModal({
                type: 'confirm',
                title: translate('COMMON_LABEL_NOTE'),
                content: translate('PAYMENT_LABEL_USE_FREE_VERSION_WARNING'),
                confirmLabel: translate(
                    'GROUP_COMFIRM_BREAK_LINK_DEPARTMENT_BUTTON'
                ),
                confirmable: true,
                closeable: true,
            });

            const answer = await ask();

            if (!answer)
                return {
                    success: false,
                };
            const purpose =
                this.planId == EPlanId.BASIC || this.planId == EPlanId.FREE
                    ? EPaymentPurpose.EXTEND
                    : EPaymentPurpose.DOWNGRADE;

            await PaymentService.getInstance().usingFreePackage();
            return {
                success: true,
                purpose,
            };

            // history.replaceState({}, '', '/');
            // location.reload();
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    }
    async canUpgrade(newPlanId: EPlanId) {
        // is using free
        if (
            ((newPlanId == EPlanId.BASIC && this.planId == EPlanId.BASIC) ||
                (newPlanId == EPlanId.FREE && this.planId == EPlanId.FREE)) &&
            this.stateCode !== 'INIT'
        ) {
            settingGlobalModal({
                type: 'confirm',
                title: translate('COMMON_LABEL_NOTE'),
                content: translate('PAYMENT_LABEL_YOUR_PLAN_IS_FREE'),
                confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                confirmable: true,
                closeable: false,
            });
            ask();
            return false;
        }
        if (
            this.stateCode == EPlanState.BLOCK ||
            this.stateCode == EPlanState.INIT ||
            this.stateCode == EPlanState.OVERDUE_PAID ||
            this.stateCode == EPlanState.OVERDUE_TRIAL ||
            this.stateCode == EPlanState.TRIAL ||
            this.stateCode == EPlanState.FREE
        ) {
            // if (
            //     typeof this.capacityByPlanIds[newPlanId]?.totalMember ==
            //         'number' &&
            //     this.currentOrganizationCapacity?.totalMember?.usage >
            //         this.capacityByPlanIds[newPlanId]?.totalMember
            // ) {
            //     settingGlobalModal({
            //         type: 'confirm',
            //         title: translate('COMMON_LABEL_NOTE'),
            //         content: translate(
            //             'PAYMENT_LABEL_CANNOT_UPGRADE_BECAUSE_OVER_MEMBER_CAPACITY',
            //             {
            //                 totalMembers:
            //                     this.capacityByPlanIds[newPlanId]
            //                         ?.totalMember || 5,
            //                 currentPlanId: this.planId,
            //                 newPlanId,
            //             }
            //         ),
            //         confirmLabel: translate('COMMON_LABEL_GOT_IT'),
            //         confirmable: true,
            //         closeable: true,
            //     });

            //     ask();
            //     return false;
            // }
            // if (
            //     typeof this.capacityByPlanIds[newPlanId]?.totalVisitor ==
            //         'number' &&
            //     this.currentOrganizationCapacity?.totalVisitor?.usage >
            //         this.capacityByPlanIds[newPlanId]?.totalVisitor
            // ) {
            //     settingGlobalModal({
            //         type: 'confirm',
            //         title: translate('COMMON_LABEL_NOTE'),
            //         content: translate(
            //             'PAYMENT_LABEL_CANNOT_UPGRADE_BECAUSE_OVER_VISITOR_CAPACITY',
            //             {
            //                 totalMembers:
            //                     this.capacityByPlanIds[newPlanId]
            //                         ?.totalVisitor || 5,
            //                 currentPlanId: this.planId,
            //                 newPlanId,
            //             }
            //         ),
            //         confirmLabel: translate('COMMON_LABEL_GOT_IT'),
            //         confirmable: true,
            //         closeable: true,
            //     });

            //     ask();
            //     return false;
            // }
            return true;
        }

        if (this.license == ELicenseType.LIFETIME) {
            const { formatDateTime } = datetimeComposable();
            settingGlobalModal({
                type: 'confirm',
                title: translate('COMMON_LABEL_NOTE'),
                content: translate(
                    'PAYMENT_LABEL_CANNOT_UPGRADE_WHILE_HAVING_STATE_LIFETIME',
                    {
                        currentPlanId: this.planId,
                        endDate: formatDateTime(this.endDate, 'date', {
                            isIgnoreTimezone: true,
                        }),
                        newPlanId,
                    }
                ),
                confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                confirmable: true,
                closeable: false,
            });
            ask();
            return false;
        } else if (dayjs().isBefore(this.endDate, 'day')) {
            const { formatDateTime } = datetimeComposable();
            settingGlobalModal({
                type: 'confirm',
                title: translate('COMMON_LABEL_NOTE'),
                content: translate(
                    'PAYMENT_LABEL_CANNOT_UPGRADE_WHILE_HAVING_STATE_PAID',
                    {
                        currentPlanId: this.planId,
                        endDate: formatDateTime(this.endDate, 'date', {
                            isIgnoreTimezone: true,
                        }),
                        newPlanId,
                    }
                ),
                confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                confirmable: true,
                closeable: false,
            });
            ask();
            return false;
        }

        return true;
    }
    async upgradeHigherPlan(newPlanId: EPlanId, callback) {
        try {
            const canUpgrade = await this.canUpgrade(newPlanId);
            if (!canUpgrade) return;

            if (callback && typeof callback == 'function') callback();
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    }
    async downgradeToLowerPlan(newPlanId: EPlanId, callback?) {
        try {
            const canDowngrade = await this.canDowngrade(newPlanId);
            if (!canDowngrade) return;

            if (callback && typeof callback == 'function') callback();

            return;
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    }
    async canDowngrade(newPlanId: EPlanId) {
        // is using free
        if (
            (newPlanId == EPlanId.BASIC || newPlanId == EPlanId.FREE) &&
            (this.planId == EPlanId.BASIC || this.planId == EPlanId.FREE) &&
            this.stateCode !== EPlanState.INIT &&
            this.stateCode !== EPlanState.OVERDUE
        ) {
            settingGlobalModal({
                type: 'confirm',
                title: translate('COMMON_LABEL_NOTE'),
                content: translate('PAYMENT_LABEL_YOUR_PLAN_IS_FREE'),
                confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                confirmable: true,
                closeable: false,
            });
            ask();
            return false;
        }

        if (
            this.stateCode == EPlanState.BLOCK ||
            this.stateCode == EPlanState.INIT ||
            this.stateCode == EPlanState.OVERDUE_PAID ||
            this.stateCode == EPlanState.OVERDUE_TRIAL ||
            this.stateCode == EPlanState.TRIAL ||
            this.stateCode == EPlanState.OVERDUE
        ) {
            if (
                typeof this.capacityByPlanIds[newPlanId]?.totalMember ==
                    'number' &&
                this.currentOrganizationCapacity?.totalMember?.usage >
                    this.capacityByPlanIds[newPlanId]?.totalMember
            ) {
                settingGlobalModal({
                    type: 'confirm',
                    title: translate('COMMON_LABEL_NOTE'),
                    content: translate(
                        newPlanId == EPlanId.FREE || newPlanId == EPlanId.BASIC
                            ? 'PAYMENT_LABEL_CANNOT_USE_FREE_BECAUSE_OVER_MEMBER_CAPACITY'
                            : 'PAYMENT_LABEL_CANNOT_DOWNGRADE_BECAUSE_OVER_MEMBER_CAPACITY',
                        {
                            totalMembers:
                                this.capacityByPlanIds[newPlanId]
                                    ?.totalMember || 5,
                            currentPlanId: this.planId,
                            newPlanId,
                        }
                    ),
                    confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                    confirmable: true,
                    closeable: true,
                });

                ask();
                return false;
            }
            if (
                typeof this.capacityByPlanIds[newPlanId]?.totalVisitor ==
                    'number' &&
                this.currentOrganizationCapacity?.totalVisitor?.usage >
                    this.capacityByPlanIds[newPlanId]?.totalVisitor
            ) {
                settingGlobalModal({
                    type: 'confirm',
                    title: translate('COMMON_LABEL_NOTE'),
                    content: translate(
                        newPlanId == EPlanId.FREE || newPlanId == EPlanId.BASIC
                            ? 'PAYMENT_LABEL_CANNOT_USE_FREE_BECAUSE_OVER_VISITOR_CAPACITY'
                            : 'PAYMENT_LABEL_CANNOT_DOWNGRADE_BECAUSE_OVER_VISITOR_CAPACITY',
                        {
                            totalMembers:
                                this.capacityByPlanIds[newPlanId]
                                    ?.totalVisitor || 5,
                            currentPlanId: this.planId,
                            newPlanId,
                        }
                    ),
                    confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                    confirmable: true,
                    closeable: true,
                });

                ask();
                return false;
            }
            return true;
        }

        if (this.license == ELicenseType.LIFETIME) {
            settingGlobalModal({
                type: 'confirm',
                title: translate('COMMON_LABEL_NOTE'),
                content: translate(
                    'PAYMENT_LABEL_CANNOT_DOWNGRADE_WHILE_HAVING_STATE_LIFETIME',
                    {
                        currentPlanId: this.planId,
                        newPlanId,
                    }
                ),
                confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                confirmable: true,
                closeable: false,
            });
            ask();
            return false;
        } else if (dayjs().isBefore(this.endDate, 'day')) {
            const { formatDateTime } = datetimeComposable();
            settingGlobalModal({
                type: 'confirm',
                title: translate('COMMON_LABEL_NOTE'),
                content: translate(
                    'PAYMENT_LABEL_CANNOT_USE_FREE_VERSION_CONTENT',
                    {
                        currentPlanId: this.planId,
                        endDate: formatDateTime(this.endDate, 'date', {
                            isIgnoreTimezone: true,
                        }),
                    }
                ),
                confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                confirmable: true,
                closeable: false,
            });
            ask();
            return false;
        }

        return true;
    }

    getBonusDays() {
        if (this.stateCode == EPlanState.INIT) {
            return this.paymentConfigs?.paymentBonus?.payWhenRegister;
        }

        if (this.stateCode == EPlanState.TRIAL) {
            const bonusWhenInitExpiredDate = dayjs(
                this.firstDecidePaymentDate
            ).add(
                this.paymentConfigs?.paymentBonus?.trialLimitDayApplyBonus,
                'day'
            );

            return dayjs().isBefore(bonusWhenInitExpiredDate)
                ? this.paymentConfigs?.paymentBonus?.trialBonusDayBeforeLimit
                : this.paymentConfigs?.paymentBonus?.trialBonusDayAfterLimit;
        }

        return 0;
    }

    canExtend() {
        return !dayjs().isBefore(dayjs(this.endDate));
    }

    getTotalUserForPayment(payload: IPaymentPayload) {
        if (payload?.purpose == EPaymentPurpose.GET_MORE)
            return Math.max(payload?.totalUsers || 0, 1);

        if (payload?.purpose == EPaymentPurpose.EXTEND)
            return this.currentOrganizationCapacity?.totalMember?.limitation;

        if (payload?.purpose == EPaymentPurpose.PURCHASE)
            return Math.max(
                payload?.totalUsers || 0,
                this.currentOrganizationCapacity?.totalMember?.usage
            );

        if (payload?.purpose == EPaymentPurpose.UPGRADE)
            return Math.max(
                payload?.totalUsers || 0,
                this.currentOrganizationCapacity?.totalMember?.usage
            );

        return this.getTotalUserBySize();
    }
    async createPayment(payload: IPaymentPayload) {
        try {
            const res = await PaymentService.getInstance().createPaymentToken({
                planId: payload.planId,
                purpose: payload.purpose || null,
                period: payload.period || 12,
                totalUsers: this.getTotalUserForPayment(payload),
            });

            const paymentRequestToken = res.result;
            if (paymentRequestToken)
                window.open(
                    `${
                        import.meta.env.VITE_BASE_PAYMENT_URL
                    }/payment/cart?token=${paymentRequestToken}&targetAppUrl=${
                        import.meta.env.VITE_DOMAIN_DEFAULT
                    }`,
                    '_blank'
                );
            return;
        } catch (e) {
            console.log(e);
        }
    }
}
