export default {
    install: (app) => {
        app.directive('scroll-infinite-2', {
            mounted(el, binding) {
                if (!el) return;

                const distance = binding.value?.distance || 100;

                el.lastScrollTop = el.scrollTop;
                el.previousScrollHeight = el.scrollHeight;
                el.previousScrollTop = el.scrollTop;

                el.onScroll = async () => {
                    // Handle scroll reach top
                    if (
                        el.lastScrollTop > distance &&
                        el.scrollTop <= distance
                    ) {
                        // Store scroll position
                        el.previousScrollHeight = el.scrollHeight;
                        el.previousScrollTop = el.scrollTop;

                        if (binding.value?.onReachedTop) {
                            await binding.value.onReachedTop();
                        }

                        // Restore scroll position
                        requestAnimationFrame(() => {
                            el.scrollTop =
                                el.previousScrollTop +
                                (el.scrollHeight - el.previousScrollHeight);
                        });
                    }

                    // Handle scroll reach bottom
                    const lastScrollBottom =
                        el.scrollHeight - el.lastScrollTop - el.offsetHeight;
                    const scrollBottom =
                        el.scrollHeight - el.scrollTop - el.offsetHeight;
                    if (
                        lastScrollBottom > distance &&
                        scrollBottom <= distance
                    ) {
                        // Store scroll position
                        el.previousScrollHeight = el.scrollHeight;
                        el.previousScrollTop = el.scrollTop;

                        if (binding.value?.onReachedBottom) {
                            await binding.value.onReachedBottom();
                        }
                    }

                    el.lastScrollTop = el.scrollTop;
                };

                el.addEventListener('scroll', el.onScroll);
            },
            unMounted(el) {
                if (!el) return;

                el.removeEventListener('scroll', el.onScroll);
            },
        });
    },
};
