import HomeRepository from '@/application/repositories/HomeRepository';
export default class HomeService {
    private static instance: HomeService;
    _secutityRepository: HomeRepository;
    constructor() {
        this._secutityRepository = HomeRepository.getInstance();
    }

    public static getInstance(): HomeService {
        if (!HomeService.instance) {
            // Get from local storage
            HomeService.instance = new HomeService();
        }

        return HomeService.instance;
    }

    getWorkingStatus(departmentId?: number): Promise<any> {
        return this._secutityRepository.getWorkingStatus(departmentId);
    }

    calculateScorePerformanceOnDay(departmentId?: number): Promise<any> {
        return this._secutityRepository.calculateScorePerformanceOnDay(
            departmentId
        );
    }

    getTaskActivityChart(departmentId?: number): Promise<any> {
        return this._secutityRepository.getTaskActivityChart(departmentId);
    }

    getDayoffStatusByDate(date, departmentId?: number): Promise<any> {
        return this._secutityRepository.getDayoffStatusByDate(
            date,
            departmentId
        );
    }
    getDepartmentsOverview(departmentId?: number): Promise<any> {
        return this._secutityRepository.getDepartmentsOverview(departmentId);
    }
    getDepartmentsOverviewById(departmentId: number): Promise<any> {
        return this._secutityRepository.getDepartmentsOverviewById(
            departmentId
        );
    }
}
