import ImportRepository from '@/application/repositories/ImportRepository';

export default class ImportService {
    private static instance: ImportService;

    _importRepository: ImportRepository;

    constructor() {
        this._importRepository = ImportRepository.getInstance();
    }

    public static getInstance(): ImportService {
        if (!ImportService.instance) {
            // Get from local storage
            ImportService.instance = new ImportService();
        }
        return ImportService.instance;
    }

    importTaskData(data: any): Promise<any> {
        return this._importRepository.importTaskData(data);
    }
}
