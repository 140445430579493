<script setup lang="ts">
import { computed, onMounted } from 'vue';
import userStore from '@/store/user';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
// import TaskCard from '@/ui/modules/overview/TaskCard.vue';
import ChatForm from '@/ui/modules/messaging/chat-panel/chat-form/ChatForm.vue';
import sendMessagesComposables from '@/ui/composables/chat/send-messages-composables';
import commonActionComposables from '@/ui/composables/chat/common-action-composables';
// import {_prepareMsgTask} from '@/ui/modules/messaging/chat-panel/chat-form/parser-msg-discuss';
import chatStore from '@/store/chat';
import {
  NotificationPositionType,
  openNotification,
} from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import { getCurrentOrganizationId } from '@/ui/hooks/storageHook';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const props = defineProps({
  groupId: { type: Number, default: 0 },
  userId: { type: Number, default: 0 },
  currentTask: { type: TaskDetailClass, default: {} },
  conversationId: { type: String, default: null },
});
const emits = defineEmits(['onClose']);

const _chatStore = chatStore();
const _userStore = userStore();

const allUserByIds = computed(() => _userStore.allUserByIds);
const organizationId = computed(() => getCurrentOrganizationId());

onMounted(async () => {
  await _chatStore.setIdConversationActive(props.conversationId);
});

const { onTyping } = commonActionComposables(organizationId);

const { onSendMessage } = sendMessagesComposables(organizationId);

const onSendMsgDiscuss = async (message) => {
  // if (!props?.currentTask?.id || !message?.text?.trim() || !message.value?.files?.length) return;
  // console.log('message', message);
  const newMsg = {
    ...message,
    task: message?.task || props.currentTask,
    comment: message.comment,
  };
  // newMsg.text =
  //     _prepareMsgTask(newMsg, allUserByIds.value)?.all ||
  //     _prepareMsgTask(newMsg, allUserByIds.value)?.task;
  // newMsg.comment = message.text || '';

  // console.log('newMsg', newMsg);

  await onSendMessage(props.conversationId, newMsg);

  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('CHAT_FORWARD_MSG_SUCCESS'),
    duration: 2000,
    position: NotificationPositionType.TopRight,
  });

  emits('onClose');
};

const onSendingMsgDiscuss = (message) => {
  activeAndFocusOnConversationId.value = `${props.conversationId}`;

  _chatStore.updateDraftMessage(props.conversationId, message);
};

const activeConverInfo = computed(() =>
  _chatStore.getConversationInfo(props.conversationId)
);
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-1/3"
    container-style="max-width: 28rem;"
    style-body="p-0"
    is-hidden-footer
    disable-click-outside
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="flex items-center space-x-2 pr-4">
        <span class="text-base min-w-max">{{
          $t('COMMON_LABEL_DISCUSS_ABOUT_WITH')
        }}</span>
        <UserById
          v-if="userId"
          class="text-sm font-normal"
          avatar-class="w-5 h-5"
          :user-id="userId"
        />
        <GroupById
          v-if="groupId"
          class="text-sm font-normal"
          avatar-class="w-5 h-5"
          :group-id="groupId"
        />
      </div>
    </template>
    <template #body>
      <div class="w-full">
        <ChatForm
          v-if="!activeConverInfo?.isGroupChatDisallowed"
          ref="formChatDiscuss"
          :conversation="activeConverInfo"
          :conversation-id="conversationId"
          :members="allUserByIds"
          :task="currentTask"
          readonly
          @on-typing="onTyping(conversationId, $event)"
          @on-send-message="(message) => onSendMsgDiscuss(message)"
          @on-message-change="(message) => onSendingMsgDiscuss(message)"
        />

        <div
          v-else-if="activeConverInfo?.isGroupChatDisallowed"
          class="
            w-full
            min-h-[3.5rem]
            bg-gray-100
            text-gray-500
            p-4
            flex-center
            space-x-2
          "
        >
          <SynIcon name="NotAllow" class="fill-gray-500" />
          <span class="text-sm font-semibold">
            {{ $t('CHAT_GROUP_NOT_ALLOWED_MSG') }}
          </span>
        </div>
      </div>
    </template>
  </SynModal>
</template>
