<script setup lang="ts">
// *** IMPORTS ***
import { computed } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import groupStore from '@/store/group';
import myProfileStore from '@/store/auth/my-profile';

// *** PROPS, EMITS ***
const props = defineProps<{
  location: any;
  isDriveDocument?: boolean;
}>();

// *** PRIVATE VARIABLES ***
const _myProfileStore = myProfileStore();
const _groupStore = groupStore();

// *** COMPOSABLES ***

// *** REFS ***

// *** COMPUTED ***
const allMyDomains = computed(() => _myProfileStore.myDomains);
const allMyGroups = computed(() =>
  _groupStore.allMyGroups?.filter((group) => group?.isMember)
);

const locationName = computed(() => {
  const groupInfo = allMyGroups.value?.find(
    (group) => group?.id === props.location?.groupId
  );
  const domainInfo = allMyDomains.value?.find(
    (group) => group?.id === props.location?.domainId
  );
  const projectInfo = domainInfo?.projectScopeList?.find(
    (project) => project?.projectId === props.location?.projectId
  );
  const subProjectInfo = domainInfo?.projectScopeList?.find(
    (project) => project?.projectId === props.location?.subProjectId
  );
  let domainName = domainInfo?.name;
  if (projectInfo) domainName += ` / ${projectInfo?.projectName}`;
  if (subProjectInfo) domainName += ` / ${subProjectInfo?.projectName}`;

  if (props.isDriveDocument) {
    if (props.location?.groupId) {
      return translate('GED_GOOGLE_DOC_OF_GROUP', {
        name: groupInfo?.name,
      });
    }
    if (props.location?.domainId) {
      return translate('GED_GOOGLE_DOC_OF_DOMAIN', {
        name: domainName,
      });
    }
    return translate('GED_GOOGLE_DOC_OF_ME');
  }

  if (props.location?.groupId) return groupInfo?.name;
  if (props.location?.domainId) return domainName;

  return translate('GED_MY_STORAGE');
});

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div>
    {{ locationName }}
    <span
      v-for="(parentFolder, index) in location?.folder?.folders"
      :key="parentFolder"
    >
      <template v-if="!(isDriveDocument && index === 0)"
        >/ {{ parentFolder?.name }}&nbsp;</template
      >
    </span>
    <span v-if="location?.folder">/ {{ location?.folder?.name }}</span>
  </div>
</template>

<style scoped></style>
