import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';

type AudioState = {
    playingAudioId: string | null;
};

export default defineStore({
    id: storeId.audio,
    state: () =>
        ({
            playingAudioId: null,
        } as AudioState),
    actions: {
        setPlayingAudioId(audioPlayerId) {
            this.playingAudioId = audioPlayerId;
        },
    },
});
