<script setup lang="ts">
import { computed, ref } from 'vue';
import domainStore from '@/store/scope';
import ListMember from '@/ui/modules/domains/component/ListMember.vue';
import UserById from '@/ui/components/user/UserById.vue';
import userStore from '@/store/user';
import DomainDetailModal from '@/ui/modules/domains/domain/DomainDetailModal.vue';
import DomainDeleteModal from '@/ui/modules/domains/domain/DomainDeleteModal.vue';
import { DOMAIN_STATUS } from '@/ui/common/constants/domain';
import AlertModal from '@/ui/components/modals/AlertModal.vue';

const props = defineProps<{
  domainId: number;
}>();

defineEmits(['toggleitem', 'editMember']);

const _domainStore = domainStore();

const domainByIds = computed(() => _domainStore.domainByIds);
const domainDetail = computed(() => domainByIds.value[props.domainId]);

const allUserByIds = computed(() => userStore().allUserByIds);
const membersInfo = computed(() =>
  domainDetail.value?.members?.map((user) => {
    return allUserByIds.value[user.id];
  })
);

const isOpenDetail = ref(false);

const isCreateProject = ref(false);

const domainDetailDefaultTab = ref<'INFORMATION' | 'MEMBERS' | 'GROUPS'>(
  'INFORMATION'
);
const onOpenDetail = (
  defaultTab: 'INFORMATION' | 'MEMBERS' | 'GROUPS' = 'INFORMATION'
) => {
  isOpenDetail.value = true;
  domainDetailDefaultTab.value = defaultTab;
};

const createProject = () => {
  isCreateProject.value = true;
  isOpenDetail.value = true;
};

const onOpenUpdateMembers = () => {
  domainDetailDefaultTab.value = 'MEMBERS';
  isOpenDetail.value = true;
};
const isShowRemoveModal = ref(false);
const onRemoveDomain = () => {
  isShowRemoveModal.value = true;
};

// const onDeleteProject = () => {
//   _domainStore.removeDomain(props.domainId);
//   askDeleting.value = false;
// };

const onCloseDomainDetailModal = () => {
  isOpenDetail.value = false;
  isCreateProject.value = false;
};

const onSetVisible = () => {
  if (domainDetail.value?.system) {
    isShowAlertCannotEditSystemDomain.value = true;
    return;
  }
  _domainStore.setVisibleDomain(props.domainId);
};

const isShowAlertCannotEditSystemDomain = ref(false);
</script>

<template>
  <SynTr class="hover:bg-gray-50 h-11">
    <SynTd
      class="cursor-pointer w-max hover:text-current"
      :title="domainDetail?.description"
      style="width: 20rem"
      @click="onOpenDetail('INFORMATION')"
    >
      <span class="font-semibold pl-2 text-overflow-hidden-line min-w-full">
        {{ domainDetail?.name }}
      </span>
    </SynTd>
    <SynTd
      :title="domainDetail?.description"
      class="cursor-pointer text-left"
      style="width: 30rem"
    >
      <span class="min-w-full text-overflow-hidden-line">{{
        domainDetail?.description
      }}</span>
    </SynTd>
    <SynTd class="w-56">
      <div class="flex">
        <ListMember
          v-if="membersInfo && membersInfo?.length > 0"
          is-editable
          :members="membersInfo"
          :show-user-number="5"
          @on-open-update-members="onOpenUpdateMembers"
        />
      </div>
    </SynTd>
    <SynTd class="w-60">
      <div class="flex">
        <UserById :user-id="domainDetail?.creatorId" avatar-class="h-6 w-6" />
      </div>
    </SynTd>
    <SynTd class="w-44">
      <span class="min-w-full text-overflow-hidden-line">
        <SynLabelDateTime
          format="date"
          :datetime="domainDetail?.creationTime"
        />
      </span>
    </SynTd>
    <SynTd class="w-44">
      <span
        class="pl-4 min-w-full text-overflow-hidden-line"
        :class="{
          'text-gray-500': domainDetail?.invisible,
          'text-green-500': !domainDetail?.invisible,
        }"
      >
        {{
          domainDetail?.invisible
            ? $t(DOMAIN_STATUS.INVISIBLE.name) || DOMAIN_STATUS.INVISIBLE.name
            : $t(DOMAIN_STATUS.VISIBLE.name) || DOMAIN_STATUS.VISIBLE.name
        }}
      </span>
    </SynTd>
    <SynTd class="w-12">
      <div class="flex justify-center">
        <VigDropdown>
          <template #dropdown-toggle>
            <SynIcon has-action name="dots-vertical" />
          </template>
          <template #dropdown-menu>
            <div
              class="list-li flex justify-start items-center dropdown-item w-40"
              @click="onOpenDetail('MEMBERS')"
            >
              <SynIcon
                is-active
                custom-class="fill-gray-500 w-4 h-4"
                name="info"
              />
              <span class="text-gray-600">
                {{ $t('COMMON_LABEL_DETAIL') }}
              </span>
            </div>
            <div
              class="list-li flex justify-start items-center dropdown-item w-40"
              @click="onOpenUpdateMembers"
            >
              <div class="w-4 h-4 flex-center">
                <SynIcon
                  is-active
                  custom-class="fill-gray-500 w-4 h-4"
                  name="groups"
                />
              </div>

              <span class="text-gray-600 truncate">
                {{ $t('PROJECT_LABEL_ADD_MEMBER_IN_DOMAIN') }}
              </span>
            </div>
            <div
              class="list-li flex justify-start items-center dropdown-item w-40"
              @click="createProject()"
            >
              <div class="w-4 h-4 flex-center">
                <SynIcon
                  is-active
                  custom-class="fill-gray-500 w-4 h-4"
                  name="project"
                />
              </div>

              <span class="text-gray-600">
                {{ $t('PROJECT_LABEL_SUBMENU_ADD_PROJECT') }}
              </span>
            </div>
            <div
              class="list-li flex justify-start items-center dropdown-item w-40"
              @click="onOpenDetail('INFORMATION')"
            >
              <SynIcon
                class="gray-current"
                is-active
                custom-class="fill-gray-500 w-4 h-4"
                name="edit"
              />
              <span class="text-gray-600">
                {{ $t('COMMON_LABEL_EDIT') }}
              </span>
            </div>
            <div
              class="
                border-t
                list-li
                flex
                justify-start
                items-center
                dropdown-item
                w-40
              "
              @click="onSetVisible"
            >
              <SynIcon
                class="gray-current"
                :is-active="!domainDetail?.invisible"
                :custom-class="` w-4 h-4 ${
                  domainDetail?.invisible ? 'fill-green-500' : 'fill-gray-500'
                }`"
                name="eye"
              />
              <span
                :class="{
                  'text-gray-500': !domainDetail?.invisible,
                  'text-green-500': domainDetail?.invisible,
                }"
              >
                {{
                  !domainDetail?.invisible
                    ? $t(DOMAIN_STATUS.INVISIBLE.name) ||
                      DOMAIN_STATUS.INVISIBLE.name
                    : $t(DOMAIN_STATUS.VISIBLE.name) ||
                      DOMAIN_STATUS.VISIBLE.name
                }}
              </span>
            </div>
            <div
              class="
                list-li
                flex
                justify-start
                items-center
                dropdown-item
                w-40
                text-red-600
                fill-red-500
              "
              @click="onRemoveDomain"
            >
              <SynIcon
                name="trash"
                is-active
                custom-class="fill-red-500 w-4 h-4"
              />
              <span>
                {{ $t('COMMON_LABEL_DELETE') }}
              </span>
            </div>
          </template>
        </VigDropdown>
      </div>
    </SynTd>
  </SynTr>
  <slot />

  <DomainDetailModal
    v-if="isOpenDetail"
    :domain-id="domainId"
    :default-tab="domainDetailDefaultTab"
    :create-project-mode-default="isCreateProject"
    @on-close="onCloseDomainDetailModal"
    @on-delete-success="onCloseDomainDetailModal"
  />

  <DomainDeleteModal
    v-if="isShowRemoveModal"
    :domain-id="domainId"
    @on-close="isShowRemoveModal = false"
  />

  <AlertModal
    v-if="isShowAlertCannotEditSystemDomain"
    is-html-text
    :title-text="$t('DOMAIN_LABEL_EDIT_SYSTEM_DOMAIN_TITLE')"
    :sub-title-text="
      $t('DOMAIN_LABEL_REMOVE_SYSTEM_DOMAIN_CONTENT', {
        domainName: domainDetail?.name || '',
      })
    "
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="isShowAlertCannotEditSystemDomain = false"
  />
</template>
