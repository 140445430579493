import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // Optional: if you need timezone support
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import IsTomorrow from 'dayjs/plugin/isTomorrow';
import IsToday from 'dayjs/plugin/isToday';
import IsYesterday from 'dayjs/plugin/isYesterday';
import isBetween from 'dayjs/plugin/isBetween';

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(IsTomorrow);
dayjs.extend(IsToday);
dayjs.extend(IsYesterday);
dayjs.extend(isBetween);

export default dayjs;
