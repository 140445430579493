import { defineStore } from 'pinia';
import GroupService from '@/application/services/GroupService';
import storeId from '@/base/store/storeId';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import { cloneDeep, arrayOrderBy } from '@/ui/hooks/commonFunction';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import ConversationCollectionModel from '@/ui/plugins/firebases/firestore-database/models/ConversationCollectionModel';
import { chain } from 'lodash';
import { GroupType } from '@/application/enums/GroupEnums';
import { IGroupDetail } from '@/application/types/group/group.types';
import { IGroupDetailEntity } from '@/domain/entities/group/GroupDetailEntity';
import { ICreateOrUpdateGroupPayload } from '@/application/repositories/GroupRepository';
import { getGroupShortName } from '@/application/constants/chat/chat.const';

type GroupState = {
    _groupService: GroupService;
    isLoading: boolean;
    isOverviewGroupTaskLoading: boolean;
    allGroup: IGroupDetailEntity[];
    allDeletedGroupIds: any[];
    allNormalGroupByIds: { number: IGroupDetailEntity } | {};
    allGroupChatByIds: any;
    domains: any[];
    domainsUser: any[]; // user + list domain of user
    fileName: string;
    myGroups: IGroupDetail[];
    myGroupByIds: any;
    scrollTopTable: number;
    sortList: any[];
    filterObject: {
        keySearch: string;
        status: boolean | null;
    };
};

const enableSortMultiple = false;
export default defineStore({
    id: storeId.group,
    state: () =>
        ({
            _groupService: GroupService.getInstance(),
            isLoading: false,
            isOverviewGroupTaskLoading: false,
            allGroup: [],
            allDeletedGroupIds: [],
            allNormalGroupByIds: {},
            allGroupChatByIds: {},
            domains: [],
            domainsUser: [],
            fileName: '',
            myGroups: [],
            myGroupByIds: {},
            scrollTopTable: 0,
            filterObject: {
                keySearch: '',
                status: null,
            },
            sortList: [] as any[],
        } as GroupState),
    getters: {
        sortDataOrderByKey() {
            let result = {};
            const sortLength = this.sortList?.length;
            if (sortLength > 0) {
                const isShowIndex = sortLength > 1;
                result = this.sortList.reduce(
                    (previousValue, currentValue: any, index) => {
                        previousValue = {
                            ...previousValue,
                            ...{
                                [currentValue.key]: {
                                    sortIndex: isShowIndex ? index + 1 : null,
                                    orderBy: currentValue.orderBy,
                                },
                            },
                        };
                        return previousValue;
                    },
                    {}
                );
            }

            return result;
        },

        allMyGroups(state): any[] {
            return arrayOrderBy(
                state.myGroups,
                ['isFavorite', 'name'],
                ['asc', 'asc']
            );
        },
        allMyGroupsIncludeChat(state): any[] {
            return arrayOrderBy(
                state.myGroups?.concat(
                    Object.values(state.allGroupChatByIds || {})
                ),
                ['isFavorite', 'name'],
                ['asc', 'asc']
            );
        },
        allActiveGroups(state): any[] {
            return state.allGroup?.filter((g) => g.isActive) ?? [];
        },
        allGroupByIds(state): { number: IGroupDetailEntity } | {} {
            return {
                ...state.allGroupChatByIds,
                ...state.allNormalGroupByIds,
            };
        },
    },
    actions: {
        async getDetailGroupById(groupId) {
            if (!groupId) return;

            const res = await this._groupService.getDetail(groupId);

            const newGroup = res?.result;

            this.updateGroupById(groupId, {
                ...newGroup,
                avatarPath: newGroup?.avatar,
                avatar: newGroup?.avatarUrl,
            });
            overviewGroupStore().updateOverviewGroupInfo(newGroup);

            return newGroup;
        },

        async getAllGroup() {
            const res = await this._groupService.getAllGroup();
            this.allGroup =
                res?.result && res?.result?.length > 0
                    ? res?.result.map((current) => {
                          return {
                              ...current,
                              avatarPath: current?.avatar,
                              avatar: current?.avatarUrl,
                          };
                      })
                    : [];
            this.allNormalGroupByIds =
                this.allGroup && this.allGroup?.length > 0
                    ? this.allGroup.reduce((old, current) => {
                          return {
                              ...old,
                              [current.id]: current,
                          };
                      }, this.allNormalGroupByIds)
                    : {};

            return this.allGroup;
        },
        async getAllDeletedGroup() {
            const res = await this._groupService.getDeletedGroupByUser();

            const result = res?.result;
            this.allDeletedGroupIds = result?.map((group) => group?.id);

            this.allNormalGroupByIds = result.reduce((old, current) => {
                return {
                    ...old,
                    [current.id]: {
                        ...current,
                        avatar: current?.avatarUrl,
                    },
                };
            }, this.allNormalGroupByIds);

            return this.allGroup;
        },
        async getGroupChatByUser() {
            const res = await this._groupService.getGroupChatByUser(
                getCurrentUserId()
            );

            const result = res?.result;

            this.allGroupChatByIds = result.reduce((old, current) => {
                return {
                    ...old,
                    [current.id]: {
                        ...current,
                        avatar: current?.avatarUrl,
                        shortName: getGroupShortName(current),
                    },
                };
            }, this.allGroupChatByIds);

            return this.allGroupChatByIds;
        },

        genSortDataList(sortObj) {
            const { key, name } = sortObj;
            let orderBy = 'asc';
            let sortData = [] as any[];
            const currentSortData: any = this.sortList;
            if (enableSortMultiple) {
                const indexExisted = currentSortData.findIndex(
                    (o: any) => o.key == key
                );
                if (indexExisted > -1) {
                    const currentOrderBy =
                        currentSortData[indexExisted].orderBy;
                    if (currentOrderBy == 'desc') {
                        sortData = sortData.filter((o: any) => o.key !== key);
                    } else {
                        orderBy = currentOrderBy == 'asc' ? 'desc' : 'asc';
                        sortData[indexExisted] = { key, name, orderBy };
                    }
                } else {
                    sortData = [...sortData, { key, name, orderBy }];
                }
            } else {
                const indexExisted = currentSortData.findIndex(
                    (o) => o.key == key
                );
                if (indexExisted > -1) {
                    const currentOrderBy =
                        currentSortData[indexExisted].orderBy;
                    if (currentOrderBy == 'asc') {
                        sortData = [];
                    } else {
                        orderBy = currentOrderBy == 'desc' ? 'asc' : 'desc';
                        sortData = [{ key, name, orderBy }];
                    }
                } else {
                    sortData = [{ key, name, orderBy: 'desc' }];
                }
            }
            this.sortList = sortData;

            this.onSortFilteredTasks();
        },

        onSortFilteredTasks() {
            if (this.sortList?.length > 0) {
                const iterate = [...this.sortList.map((o: any) => o.key)];
                const order = [...this.sortList.map((o: any) => o.orderBy)];
                this.allGroup = arrayOrderBy(
                    this.allGroup,
                    iterate,
                    order
                ) as IGroupDetailEntity[];
            } else {
                this.allGroup = arrayOrderBy(
                    this.allGroup,
                    'name',
                    'asc'
                ) as IGroupDetailEntity[];
            }
        },

        setValueSettingGroup(notification, idGroup) {
            const indexMyGroup = this.myGroups?.findIndex(
                (el) => el.id == idGroup
            );

            if (indexMyGroup > -1) {
                if (notification) {
                    this.myGroups[indexMyGroup].notification = notification;
                } else {
                    delete this.myGroups[indexMyGroup]['notification'];
                }
            }
        },

        async getDomainListById(userId, createGroupForm = false) {
            const domain: any[] = [];
            const resDomain = await this._groupService.getDomainListById(
                userId
            );
            const userDomain = [...resDomain['result']];
            this.domains = domain;
            if (userDomain?.length <= 0) return domain;

            userDomain.forEach(async function (el) {
                let checkDomain = el.system;
                const projectScope: any[] = [];

                if (el.projectScopeList.length > 0) {
                    let checkProject = false;
                    const project = el.projectScopeList.filter(
                        (pr) => pr.parentId == null
                    );
                    project.forEach((elpro) => {
                        if (elpro.userId !== null) {
                            checkProject = true;
                        } else {
                            checkProject = false;
                        }
                        let checkSubProject = false;
                        const sub = el.projectScopeList.filter(
                            (pr) => pr.parentId == elpro.projectId
                        );
                        const subProject: any[] = [];
                        sub.forEach((sub) => {
                            if (sub.userId !== null) {
                                checkSubProject = true;
                            } else {
                                checkSubProject = false;
                            }
                            subProject.push({
                                checked: checkSubProject,
                                projectId: sub.projectId,
                                projectName: sub.projectName,
                            });
                        });
                        projectScope.push({
                            checked: checkProject,
                            projectId: elpro.projectId,
                            projectName: elpro.projectName,
                            subProject: subProject,
                        });
                    });
                }
                if (el.userId && !createGroupForm) {
                    checkDomain = true;
                }
                // if (!createGroupForm || el.userId)
                domain.push({
                    system: el.system,
                    checked: checkDomain,
                    domainName: el.domainName,
                    domainId: el.domainId,
                    anyProjects: el.anyProjects,
                    projectScopeList: projectScope,
                });
            });
            return domain;
        },

        async getAllDomainOfUser() {
            const res = await this._groupService.getAllDomainOfUser();
            this.domainsUser = res['result'];
            return res['result'];
        },

        getGroupDomainByGroupId(groupId) {
            return cloneDeep(this.myGroupByIds[groupId]?.groupDomains || []);
        },

        async addDefaultDepartmentGroup(id, groupName) {
            const payload = {
                departmentId: id,
                name: groupName,
            };
            this.isLoading = true;
            let res;
            try {
                res = await this._groupService.addDefaultDepartmentGroup(
                    payload
                );
            } catch (error) {
                console.log('🚀 Hyrin ~ onGenerateGroup ~ error', error);
            } finally {
                this.isLoading = false;
            }
            return res['result'];
        },

        async getSameGroupOfUsers(userId) {
            const res = await this._groupService.getSameGroupOfUsers(userId);
            return res['result'];
        },

        async uploadAvatarAws(file) {
            const res = await this._groupService.uploadAvatar(file);
            this.fileName = res?.result?.path;
            return res?.result;
        },

        async updateAvatarGroup(groupId, avatar) {
            const res = await this._groupService.uploadAvatarGroup(
                groupId,
                avatar
            );

            if (res) {
                new ConversationCollectionModel().updateGroupChatInfo(groupId, {
                    avatar: res?.result?.avatarUrl,
                });
            }

            // this.getDetailGroupById(groupId);
            this.updateGroupById(groupId, {
                avatar: res?.result?.avatarUrl,
            });

            return res;
        },
        async updateDomains(groupId, domainIds) {
            await this._groupService.updateDomains(groupId, domainIds);
            this.fetchMyGroups();
        },

        updateAvatarLocal(groupId, avatarObj: any) {
            overviewGroupStore().updateAvatarGroupTeamLocal(groupId, avatarObj);

            this.allGroup?.length > 0 &&
                this.allGroup.map((el) =>
                    this.updateLinkAvatar(el, groupId, avatarObj)
                );

            this.myGroups?.length > 0 &&
                this.myGroups.map((el) =>
                    this.updateLinkAvatar(el, groupId, avatarObj)
                );
        },

        updateLinkAvatar(objectUser, groupId, listAvatar) {
            if (objectUser?.id === groupId || objectUser?.groupId === groupId) {
                return {
                    ...objectUser,
                    avatar: listAvatar?.avatar || objectUser.avatar || '',
                    avatarThumbnailUrl:
                        listAvatar?.avatarThumbnailUrl ||
                        objectUser.avatarThumbnailUrl ||
                        '',
                    avatarThumbUrl:
                        listAvatar?.avatarThumbnailUrl ||
                        objectUser?.avatarThumbUrl ||
                        '',
                    avatarUrl:
                        listAvatar?.avatarUrl || objectUser.avatarUrl || '',
                };
            }

            return { ...objectUser };
        },

        async createAndUpdate(group: ICreateOrUpdateGroupPayload) {
            const res = await this._groupService.createAndUpdate(group);
            const newGroup = res?.result;
            this.updateGroupById(newGroup?.id, {
                ...newGroup,
                avatarPath: newGroup?.avatar,
                avatar: newGroup?.avatarUrl,
            });
            overviewGroupStore().updateOverviewGroupInfo(newGroup);
            return res;
        },

        async addUserToGroup(payload) {
            if (!payload?.groupId) return;

            const res = await this._groupService.addUserToGroup(payload);

            // await this.getDetailGroupById(payload?.groupId);
            this.updateGroupById(payload?.groupId, {
                groupUsers: payload?.memberInfos,
            });
            return res;
        },

        async addOrUpdateGroupToUser(payload) {
            const res = await this._groupService.addOrUpdateGroupToUser(
                payload
            );
            return res;
        },

        async changeGroupType(payload) {
            const res = await this._groupService.changeGroupType(payload);
            return res;
        },

        async deleteGroup(id, option) {
            const payload = {
                groupId: id,
                deleteGroupOption: option,
            };
            const res = await this._groupService.deleteGroup(payload);
            return res;
        },

        async fetchMyGroups() {
            let res;
            try {
                res = await this._groupService.getAllGroupByUser(
                    getCurrentUserId()
                );
            } catch (e) {
                console.log(e);
                return;
            }

            this.myGroups = (res['result'] ?? []).map((e) => {
                return {
                    ...e,
                    avatar: e?.avatarThumnailUrl,
                    groupUsers: e?.groupUsers?.map((g) => {
                        return {
                            ...g,
                            name: g?.lastName + ' ' + g?.firstName,
                            avatar: g?.avatarThumbnailUrl,
                        };
                    }),
                };
            });

            this.myGroupByIds = this.myGroups.reduce((old, current) => {
                return {
                    ...old,
                    [current.id]: cloneDeep(current),
                };
            }, this.myGroupByIds);

            this.allNormalGroupByIds = this.myGroups.reduce((old, current) => {
                return {
                    ...old,
                    [current.id]: current,
                };
            }, this.allNormalGroupByIds);

            return Promise.resolve(this.myGroups);
        },

        async getAllUserMemberGroups(userId) {
            let groups = [] as any[];
            if (getCurrentUserId() == userId) {
                groups = this.myGroups;
            }
            const res = await this._groupService.getAllGroupByUser(userId);
            groups = (res['result'] ?? []).map((e) => {
                return {
                    ...e,
                    avatar: e.avatarThumnailUrl,
                    groupUsers: e.groupUsers.map((g) => {
                        return {
                            ...g,
                            name: g.lastName + ' ' + g.firstName,
                            avatar: g.avatarThumbnailUrl,
                        };
                    }),
                };
            });

            return arrayOrderBy(
                groups.filter((e) => e.isMember) || [],
                ['name'],
                ['asc']
            );
        },

        updateNotificationInListGroup() {
            const _notificationSettingStore = notificationSettingStore();
            return _notificationSettingStore.getUserNotificationSettingInGroups();
        },

        async updateNotification(listNotificationGroup) {
            this.myGroups = this.myGroups.map((el) => {
                const index = listNotificationGroup?.findIndex(
                    (noti) => noti['refId'] == el.id
                );
                if (index > -1) {
                    el.notification = listNotificationGroup[index];
                } else {
                    delete el['notification'];
                }
                return el;
            });
        },

        getDataGroup(groupId) {
            return this.myGroups.find((x) => x.id === groupId);
        },

        updateFavoriteGroupById(groupId, value) {
            const index = this.myGroups?.findIndex((o) => o.id === groupId);
            if (index > -1) {
                this.myGroups[index].isFavorite = value;
            }
        },

        getSameGroupByUserId(userId) {
            return this.myGroups.filter((group) => {
                const index = group.groupUsers.findIndex(
                    (o) => o.userId == userId
                );
                return index > -1;
            });
        },

        updateMyGroupsAfterDelete(deletedGroupId) {
            this.myGroups = this.myGroups.filter(
                (o) => o.id !== deletedGroupId
            );
            this.allDeletedGroupIds = [
                ...new Set([...this.allDeletedGroupIds, deletedGroupId]),
            ];
            if (this.allNormalGroupByIds[deletedGroupId]?.isActive) {
                this.allNormalGroupByIds[deletedGroupId].isActive = false;
            }

            if (this.allGroupChatByIds[deletedGroupId]) {
                this.allGroupChatByIds[deletedGroupId] = null;
            }
        },

        updateGroupById(groupId, newGroupDetail) {
            switch (newGroupDetail?.type) {
                case GroupType.CHAT:
                    this.allGroupChatByIds[groupId] = {
                        ...this.allGroupChatByIds[groupId],
                        ...newGroupDetail,
                    };
                    break;

                default:
                    {
                        const indexInAllGroup = this.allGroup?.findIndex(
                            (g) => g?.id == groupId
                        );
                        if (indexInAllGroup > -1) {
                            this.allGroup[indexInAllGroup] = {
                                ...this.allGroup[indexInAllGroup],
                                ...newGroupDetail,
                            };
                        } else {
                            this.allGroup = [...this.allGroup, newGroupDetail];
                        }
                        // All groups
                        this.allNormalGroupByIds[groupId] = {
                            ...this.allNormalGroupByIds[groupId],
                            ...newGroupDetail,
                        };

                        const index = this.allGroup?.findIndex(
                            (g) => g?.id == groupId
                        );
                        if (index > -1) {
                            this.allGroup[index] = {
                                ...this.allGroup[index],
                                ...newGroupDetail,
                            };
                        }

                        // My groups
                        this.myGroupByIds[groupId] = {
                            ...this.myGroupByIds[groupId],
                            ...newGroupDetail,
                        };

                        const index2 = this.myGroups?.findIndex(
                            (g) => g?.id == groupId
                        );
                        if (index2 > -1) {
                            this.myGroups[index2] = {
                                ...this.myGroups[index2],
                                ...newGroupDetail,
                            };
                        }
                    }
                    break;
            }
        },

        async updateMyGroupsFromRealtime(groupId) {
            try {
                const groupDetail = await this.getDetailGroupById(groupId);
                if (groupDetail?.type == GroupType.CHAT) return;

                let newGroupDetail = {
                    ...groupDetail,
                    avatar: groupDetail?.avatarUrl,
                };
                const index = this.myGroups.findIndex((o) => o.id == groupId);

                if (index >= 0) {
                    this.myGroups[index] = {
                        ...this.myGroups[index],
                        ...newGroupDetail,
                    };
                    newGroupDetail = {
                        ...this.myGroups[index],
                        ...groupDetail,
                        avatar: groupDetail?.avatarUrl,
                    };
                } else {
                    this.myGroups.push(newGroupDetail);
                }

                this.myGroupByIds[groupId] = newGroupDetail;
                this.allNormalGroupByIds[groupId] = newGroupDetail;
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },
        async breakLinkDefaultDepartmentGroup(groupId) {
            await this._groupService.breakLinkDefaultDepartmentGroup(groupId);

            this.updateGroupById(groupId, {
                ...this.allNormalGroupByIds[groupId],
                code: null,
            });
        },

        getExistedGroupsByMembers(members) {
            const getMembersKey = (mems) =>
                chain(mems)
                    .map((mem) => mem?.userId || mem?.id)
                    .sort()
                    .join('-')
                    .value();

            const membersKey = getMembersKey(members);

            return chain(Object.values(this.allNormalGroupByIds || {}))
                .concat(Object.values(this.allGroupChatByIds || {}))
                .uniqBy('id')
                .filter(
                    (group: any) =>
                        getMembersKey(group?.groupUsers) === membersKey
                )
                .value();
        },

        checkUserIsGroupMember(groupId, userId) {
            const allNormalGroupByIds = {
                ...this.allNormalGroupByIds,
                ...this.myGroupByIds,
            };

            return allNormalGroupByIds[groupId].groupUsers?.some(
                (user) => user?.userId == userId
            );
        },

        resetFilter() {
            this.scrollTopTable = 0;
            this.filterObject = {
                keySearch: '',
                status: null,
            };
        },
    },
});
