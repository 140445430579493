export const emojisFlash = [
    {
        emoji: '❤️',
        description: 'red heart',
        category: 'Frequently Used',
        aliases: ['heart'],
        tags: ['love'],
        unicode_version: '',
        ios_version: '6.0',
    },
    {
        emoji: '👍',
        description: 'thumbs up',
        category: 'Frequently Used',
        aliases: ['+1', 'thumbsup'],
        tags: ['approve', 'ok'],
        unicode_version: '6.0',
        ios_version: '6.0',
        skin_tones: true,
    },
    {
        emoji: '😆',
        description: 'grinning squinting face',
        category: 'Frequently Used',
        aliases: ['laughing', 'satisfied'],
        tags: ['happy', 'haha'],
        unicode_version: '6.0',
        ios_version: '6.0',
    },
    {
        emoji: '😭',
        description: 'loudly crying face',
        category: 'Frequently Used',
        aliases: ['sob'],
        tags: ['sad', 'cry', 'bawling'],
        unicode_version: '6.0',
        ios_version: '6.0',
    },
    {
        emoji: '😱',
        description: 'face screaming in fear',
        category: 'Frequently Used',
        aliases: ['scream'],
        tags: ['horror', 'shocked'],
        unicode_version: '6.0',
        ios_version: '6.0',
    },
    {
        emoji: '😡',
        description: 'pouting face',
        category: 'Frequently Used',
        aliases: ['rage', 'pout'],
        tags: ['angry'],
        unicode_version: '6.0',
        ios_version: '6.0',
    },
];
