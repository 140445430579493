<script setup lang="ts">
// *** IMPORTS ***
import { computed, ref, watch } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';

// *** PROPS, EMITS ***;
const props = withDefaults(
  defineProps<{
    searchText: string;
    getSuggestionsFn: (keyword) => any;
    placement?: string;
    delay?: number;
    inputClass?: string;
    placeholder?: string;
  }>(),
  {
    placement: 'bottom-start',
    delay: 200, // ms - Time for typing waiting to trigger search
    inputClass: '',
    placeholder: translate('COMMON_LABEL_SEARCH'),
  }
);

const emit = defineEmits(['update:searchText', 'onItemSelect']);

// *** PRIVATE VARIABLES ***
let searchTimer;
let currentSearchText = null;
let selectedSearchText;

// *** COMPOSABLES ***

// *** REFS ***
const dropdownRef = ref<any>();
const dropdownMenuRef = ref<any>();
const suggestionItems = ref<any[]>();
const focusingIndex = ref<number>(0);

// *** COMPUTED ***
const keyword = computed({
  get() {
    return props.searchText?.trim();
  },
  set(value) {
    emit('update:searchText', value?.trim());
  },
});

// *** WATCHES ***
watch(
  () => keyword.value,
  (value) => {
    _processDropdownOpenState();

    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      _processGetSuggestions(value);
    }, props.delay || 0);
  }
);

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onSearchBoxKeydown = (event) => {
  const totalItems = suggestionItems.value?.length || 0;

  // Select item
  if (event?.key === 'Enter') {
    if (focusingIndex.value > 0 && totalItems > 0) {
      _selectItem(
        suggestionItems.value![focusingIndex.value - 1],
        keyword.value
      );
    } else {
      _selectItem({ keyword: keyword.value }, keyword.value);
    }
  }
  // Move down
  else if (event?.key === 'ArrowDown') {
    focusingIndex.value++;
    if (focusingIndex.value > totalItems) focusingIndex.value = 0;
  }
  // Move up
  else if (event?.key === 'ArrowUp') {
    focusingIndex.value--;
    if (focusingIndex.value < 0) focusingIndex.value = totalItems;
  }
};

const onSearchBoxClick = () => {
  _processDropdownOpenState();
};

const onSearchBoxFocus = () => {
  _processDropdownOpenState();
};

const onSearchBoxBlur = () => {
  setTimeout(() => {
    if (currentSearchText == selectedSearchText) return;

    _selectItem({ keyword: keyword.value }, keyword.value);
  }, 200);
};

const onSearchBoxClear = () => {
  _processGetSuggestions(null);
  _selectItem(null, null);
};

const onItemMouseEnter = (index) => {
  focusingIndex.value = index;
};

const onItemClick = (item) => {
  _selectItem(item, keyword.value);
};

// *** PRIVATE FUNCTIONS ***
const _processGetSuggestions = async (text) => {
  if (text == currentSearchText) return;

  currentSearchText = text;

  if (!props.getSuggestionsFn) return;

  suggestionItems.value = await props.getSuggestionsFn(text);

  focusingIndex.value = 0;
};

const _processDropdownOpenState = () => {
  if (keyword.value) {
    dropdownRef.value?.onForceOpen();
  } else {
    dropdownRef.value?.onForceClose();
  }
};

const _selectItem = (item, keyword) => {
  emit('onItemSelect', item);

  selectedSearchText = keyword;

  dropdownRef.value?.onForceClose();
};

// *** EXPOSES ***
</script>

<template>
  <VigDropdown
    ref="dropdownRef"
    :placement="placement"
    :arrow="false"
    :space="0"
    :trigger="'manual'"
  >
    <template #dropdown-toggle>
      <VigSearchBox
        v-model="keyword"
        :input-class="inputClass"
        :placeholder="placeholder"
        @click="onSearchBoxClick"
        @on-keydown="onSearchBoxKeydown"
        @on-focus="onSearchBoxFocus"
        @blur="onSearchBoxBlur"
        @on-clear="onSearchBoxClear"
      />
    </template>

    <template #dropdown-menu>
      <div
        ref="dropdownMenuRef"
        v-perfect-scrollbar
        class="min-w-[20rem] max-w-[40rem] max-h-[30rem] py-1"
      >
        <!--SEARCH TEXT-->
        <div
          v-if="searchText?.trim()"
          role="button"
          :class="{ 'bg-gray-50': focusingIndex === 0 }"
          @mouseenter="onItemMouseEnter(0)"
          @click="onItemClick({ keyword })"
        >
          <div class="px-4 py-2 flex items-center space-x-3">
            <SynIcon name="Search" class="fill-gray-400" />
            <span class="flex-1 truncate text-sm">{{
              searchText?.trim()
            }}</span>
          </div>
        </div>

        <!--SUGGESTION ITEMS-->
        <div
          v-for="(item, index) in suggestionItems"
          :key="index"
          role="button"
          :class="{ 'bg-gray-100': focusingIndex === index + 1 }"
          @mouseenter="onItemMouseEnter(index + 1)"
          @click="onItemClick(item)"
        >
          <slot name="item" :item="item" />
        </div>
      </div>
    </template>
  </VigDropdown>
</template>

<style scoped></style>
