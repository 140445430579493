<template>
  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g id="calendar-Bold">
      <path
        d="m362.667 64a21.334 21.334 0 0 0 -42.667 0h-128a21.334 21.334 0 0 0 -42.667 0 106.792 106.792 0 0 0 -106.666 106.667v192a106.792 106.792 0 0 0 106.666 106.666h213.334a106.792 106.792 0 0 0 106.666-106.666v-192a106.792 106.792 0 0 0 -106.666-106.667zm-213.334 42.667v21.333a21.334 21.334 0 0 0 42.667 0v-21.333h128v21.333a21.334 21.334 0 0 0 42.667 0v-21.333a64.07 64.07 0 0 1 64 64h-341.334a64.07 64.07 0 0 1 64-64zm213.334 320h-213.334a64.071 64.071 0 0 1 -64-64v-149.334h341.334v149.334a64.071 64.071 0 0 1 -64 64zm0-149.334a21.334 21.334 0 1 1 -21.334-21.333 21.334 21.334 0 0 1 21.334 21.333zm-85.334 0a21.333 21.333 0 1 1 -21.333-21.333 21.333 21.333 0 0 1 21.333 21.333zm-85.333 0a21.334 21.334 0 1 1 -21.333-21.333 21.334 21.334 0 0 1 21.333 21.333zm170.667 85.334a21.334 21.334 0 1 1 -21.334-21.334 21.334 21.334 0 0 1 21.334 21.334zm-85.334 0a21.333 21.333 0 1 1 -21.333-21.334 21.333 21.333 0 0 1 21.333 21.334zm-85.333 0a21.334 21.334 0 1 1 -21.333-21.334 21.334 21.334 0 0 1 21.333 21.334z"
        data-name="calendar-Bold"
      />
    </g>
  </svg>
</template>
