import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ChatService from '@/application/services/ChatService';
import myProfileStore from '@/store/auth/my-profile';
import { chain, keyBy, map } from 'lodash';
import {
    genDirectConversationId,
    genGroupConversationId,
} from '@/ui/plugins/firebases/firestore-database/constants';
import GroupService from '@/application/services/GroupService';
import UserService from '@/application/services/UserService';
import NotificationService from '@/application/services/NotificationService';
import CommonService from '@/application/services/CommonService';
import appStore from '@/store/app';
import dayjs from 'dayjs';
import notificationStore from '@/store/notification/notification.store';
import { UserChatInfo } from '@/application/models/chat/UserChatInfo';

interface OrgChatState {
    organizationId: number;
    orgUsers: any[];
    orgDeactivatedUsers: any[];
    orgGroups: any[];
    orgDomains: any[];
    orgProjects: any[];
    userConversations: { [conversationId: string]: ChatConversationModel };
    userConversationIds: string[];
    userSettings: any[];
    notifications: any[];
    latestNotification: {
        today: number | string;
        yesterday: number | string;
        older: number | string;
    };
    notifUnreadCategories: any[];
    usersChatInfo: UserChatInfo[] | null;
}

let unsubscribeUserConversations, timeSubscribeUserConversations;

export const orgNotifGlobalStore: any = (orgId: number) =>
    defineStore({
        id: `${storeId.orgNotifGlobal}_${orgId}`,
        state: () =>
            ({
                organizationId: orgId,
                orgUsers: [],
                orgDeactivatedUsers: [],
                orgGroups: [],
                orgDomains: [],
                orgProjects: [],
                userConversations: {},
                userConversationIds: [],
                userSettings: [],
                notifications: [],
                latestNotification: {
                    today: '',
                    yesterday: '',
                    older: '',
                },
                notifUnreadCategories: [],
                usersChatInfo: null,
            } as OrgChatState),
        getters: {
            allOrgUsers: (state) => {
                return chain(state.orgUsers)
                    .concat(state.orgDeactivatedUsers)
                    .uniqBy('id')
                    .value();
            },
            allOrgGroups: (state) => {
                return state.orgGroups;
            },
            getMember: (state) => (conversId, userId) => {
                if (!userId) return null;

                const conversation = state.userConversations[conversId];

                const member = conversation?.members
                    ? conversation?.members[userId]
                    : null;

                const user = chain(state.orgUsers)
                    .concat(state.orgDeactivatedUsers)
                    .find((u) => `${u?.id}` === `${userId}`)
                    .value();

                return { ...member, ...user };
            },
            getMembers: (state) => (conversId) => {
                const conversation = state.userConversations[conversId];

                const members = conversation?.members;

                const users = chain(state.orgUsers)
                    .concat(state.orgDeactivatedUsers)
                    .uniqBy('id')
                    .keyBy('id')
                    .value();

                return { ...members, ...users };
            },
            getContacts: (state) => {
                const allUsers = chain(state.orgUsers)
                    .concat(state.orgDeactivatedUsers)
                    .uniqBy('id')
                    .keyBy('conversationId')
                    .value();

                const allGroups: any = keyBy(state.orgGroups, 'conversationId');

                return { ...allUsers, ...allGroups };
            },
            getConversationInfo() {
                return (converId) => {
                    const converInfo = this.conversationById[converId];

                    if (converInfo?.isGroup && converInfo?.contactId) {
                        const groupInfo = this.allOrgGroups?.find(
                            (group) => group?.id === converInfo?.contactId
                        );
                        converInfo.groupUsers = groupInfo?.groupUsers;
                    }

                    return converInfo;
                };
            },
            getConversationMembers() {
                return (converId) => {
                    const converInfo = this.userConversations[converId];
                    if (
                        converInfo?.externalChat ||
                        converInfo?.supportingChat
                    ) {
                        return converInfo?.members;
                    }
                    return this.getMembers(converId);
                };
            },
            conversationById: (state) => {
                return (state.userConversationIds || []).reduce(
                    (obj, converId) => {
                        const userConver = state.userConversations[converId];
                        const myUserId = myProfileStore().myProfile?.id;

                        let chatInfo;

                        if (
                            userConver?.externalChat ||
                            userConver?.supportingChat
                        ) {
                            chatInfo = { isActive: true, isAllowed: true };
                        } else if (userConver?.isGroup) {
                            chatInfo = state.usersChatInfo?.find(
                                (info) =>
                                    info?.isGroup &&
                                    genGroupConversationId(info?.id) ===
                                        converId
                            ) || { isDeleted: true };
                        } else {
                            chatInfo = state.usersChatInfo?.find(
                                (info) =>
                                    !info?.isGroup &&
                                    genDirectConversationId(
                                        info?.id,
                                        myUserId
                                    ) === converId
                            ) || { isDeleted: true };
                        }

                        obj[converId] = {
                            ...(userConver || {}),
                            ...(chatInfo || {}),
                            id: converId,
                            contactId: chatInfo?.id,
                            isDeactive: !chatInfo?.isActive,
                            isDisallowed: !chatInfo?.isAllowed,
                            isGroupChatDisallowed:
                                chatInfo?.isGroup &&
                                !chatInfo?.isGroupChatAllowed,
                        };

                        return obj;
                    },
                    {}
                );
            },
        },
        actions: {
            clearChatData() {
                if (unsubscribeUserConversations) {
                    unsubscribeUserConversations();
                }
            },
            clearNotifData() {
                this.notifications = [];
                this.notifUnreadCategories = [];
                this.userSettings = [];
            },
            clearData() {
                this.clearChatData();
                this.clearNotifData();
            },
            async getOrgUsers() {
                const res = await UserService.getInstance().getOrgUsers(
                    this.organizationId
                );

                const myUserId = myProfileStore().myProfile?.id;

                this.orgUsers = map(res?.success ? res.result : [], (user) =>
                    _mapUser(user, false, myUserId)
                );
            },
            async getOrgDeactivatedUsers() {
                const res =
                    await UserService.getInstance().getOrgDeactivatedUsers(
                        this.organizationId
                    );

                const myUserId = myProfileStore().myProfile?.id;

                this.orgDeactivatedUsers = map(
                    res?.success ? res.result : [],
                    (user) => _mapUser(user, true, myUserId)
                );
            },
            async getAllGroupsByUser() {
                const userId = myProfileStore().myProfile?.id;

                const res =
                    await GroupService.getInstance().getOrgAllGroupsByUser(
                        this.organizationId,
                        userId
                    );

                if (!res?.success || !res?.result) return (this.orgGroups = []);

                const activeGroups = map(res?.result?.activeGroups, (group) =>
                    _mapGroup(group, false)
                );

                const chatGroups = map(res?.result?.chatGroups, (group) =>
                    _mapGroup(group, false)
                );

                const deletedGroups = map(res?.result?.deletedGroups, (group) =>
                    _mapGroup(group, true)
                );

                this.orgGroups = [
                    ...activeGroups,
                    ...chatGroups,
                    ...deletedGroups,
                ];
            },
            async getOrgUserSettings() {
                const res: any =
                    await CommonService.getInstance().getOrgCurrentUserSetting(
                        this.organizationId
                    );

                this.userSettings = res?.result;
            },
            async getOrgDomains() {
                this.orgDomains = [];
            },
            async getOrgProjects() {
                this.orgProjects = [];
            },

            getUserConversations() {
                return new Promise((resolve) => {
                    if (unsubscribeUserConversations) {
                        unsubscribeUserConversations();
                    }

                    timeSubscribeUserConversations =
                        appStore().getCurrentTime();

                    const userId = myProfileStore().myProfile?.id;

                    unsubscribeUserConversations =
                        ChatService.subscribeUserConversations(
                            this.organizationId,
                            userId,
                            (snapshot) => {
                                snapshot?.docChanges()?.forEach((change) => {
                                    const conversationId = change?.doc?.id;
                                    const conversation = change?.doc?.data();

                                    switch (change?.type) {
                                        case 'added':
                                            this.userConversations[
                                                conversationId
                                            ] = conversation;
                                            if (
                                                !this.userConversationIds.includes(
                                                    conversationId
                                                )
                                            ) {
                                                this.userConversationIds.push(
                                                    conversationId
                                                );
                                            }

                                            // Conversation created after subscribe time,
                                            // refresh list contacts
                                            if (
                                                conversation?.createdDate
                                                    ?.toDate &&
                                                timeSubscribeUserConversations &&
                                                dayjs(
                                                    conversation?.createdDate?.toDate()
                                                ).isAfter(
                                                    timeSubscribeUserConversations
                                                )
                                            ) {
                                                if (conversation?.isGroup) {
                                                    this.getAllGroupsByUser();
                                                } else {
                                                    this.getOrgUsers();
                                                    this.getOrgDeactivatedUsers();
                                                }
                                            }
                                            break;
                                        case 'modified':
                                            this.userConversations[
                                                conversationId
                                            ] = conversation;
                                            break;
                                        case 'removed':
                                            delete this.userConversations[
                                                conversationId
                                            ];
                                            this.userConversationIds.splice(
                                                this.userConversationIds.indexOf(
                                                    conversationId
                                                )
                                            );
                                            break;
                                    }
                                });

                                resolve(this.userConversations);
                            }
                        );
                });
            },
            async getUsersGroupsForChat(refresh = false) {
                if (this.usersChatInfo && !refresh) return this.usersChatInfo;

                const res =
                    await UserService.getInstance().getUsersGroupsForChat(
                        this.organizationId
                    );

                return (this.usersChatInfo = res?.result || []);
            },

            async getOrgUserNotifications(category, pageSize, isLoadMore) {
                const pageIndex = isLoadMore ? this.notifications?.length : 0;
                if (!isLoadMore) this.notifications = [];

                const res =
                    await NotificationService.getInstance().getOrgUserNotifications(
                        this.organizationId,
                        category,
                        pageIndex,
                        pageSize
                    );

                const allNotifications = res?.result?.items;

                const notificationConfigByUser =
                    notificationStore().getNotificationConfigByUser(category);
                if (allNotifications?.length > 0) {
                    this.notifications =
                        notificationStore().prepareAllNotifications(
                            this.notifications,
                            allNotifications,
                            notificationConfigByUser
                        );
                }

                return {
                    notifications: this.notifications,
                    canLoadMore:
                        this.notifications?.length < res?.result?.totalCount,
                };
            },
            async getNotifUnreadInfo() {
                const res =
                    await NotificationService.getInstance().getOrgUserNotificationUnread(
                        this.organizationId
                    );

                this.notifUnreadCategories = res?.result;
            },
            updateLocalNotification(notifId, updData) {
                const notifIndex = this.notifications?.findIndex(
                    (notif) => notif?.id === notifId
                );

                if (notifIndex > -1) {
                    this.notifications[notifIndex] = {
                        ...this.notifications[notifIndex],
                        ...updData,
                    };
                }
            },
            deleteLocalNotification(notifId) {
                this.notifications = this.notifications?.filter(
                    (notif) => notif?.id !== notifId
                );
            },

            autoAddCurrentUserChat() {
                const myProfile = myProfileStore().myProfile;
                const myUser = this.orgUsers?.find(
                    (user) => user?.id === myProfile?.id
                );
                if (!myUser) return;

                const converId = genDirectConversationId(
                    myUser?.id,
                    myUser?.id
                );

                this.userConversations[converId] = {
                    ...this.userConversations[converId],
                    id: converId,
                    name: myUser?.name,
                    avatar: myUser?.avatar,
                };

                if (!this.userConversationIds.includes(converId)) {
                    this.userConversationIds.push(converId);
                }
            },
        },
    })();

const _mapUser = (user: any, isDeactive: boolean, myUserId: number) => {
    return {
        ...user,
        name: `${user?.lastName} ${user?.firstName}`,
        avatar: user?.profilePictureUrl || user?.avatar,
        isUser: true,
        isDeactive,
        conversationId: genDirectConversationId(myUserId, user?.id),
        // muteInfo: {},
    };
};

const _mapGroup = (group: any, isDeleted: boolean) => {
    return {
        ...group,
        avatar: group?.avatarUrl || group?.avatar,
        isUser: false,
        conversationId: genGroupConversationId(group?.id),
        // muteInfo: {},
        isDeleted,
    };
};
