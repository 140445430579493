export const companySizeRange = [
    {
        key: '2 - 5',
        value: '2-5',
    },
    {
        key: '6 - 10',
        value: '6-10',
    },
    {
        key: '11 - 20',
        value: '11-20',
    },
    {
        key: '21 - 50',
        value: '21-50',
    },
    {
        key: '51 - 100',
        value: '51-100',
    },
    {
        key: '101 - 250',
        value: '101-250',
    },
    {
        key: '251 - 500',
        value: '251-500',
    },
    {
        key: '501 - 999',
        value: '501-999',
    },
    {
        key: '1000 - 4999',
        value: '1000-4999',
    },
    {
        key: '5000 - 10000',
        value: '5000-10000',
    },
    {
        key: '10000+',
        value: '10000+',
    },
];
export const teamSizeRange = [
    {
        key: '2 - 5',
        value: '2-5',
    },
    {
        key: '6 - 10',
        value: '6-10',
    },
    {
        key: '11 - 20',
        value: '11-20',
    },
    {
        key: '21 - 50',
        value: '21-50',
    },
    {
        key: '50 - 100',
        value: '50+',
    },
];
export const memberRangeFull = [
    {
        key: '2 - 5',
        value: '2-5',
    },
    {
        key: '6 - 10',
        value: '6-10',
    },
    {
        key: '11 - 20',
        value: '11-20',
    },
    {
        key: '21 - 50',
        value: '21-50',
    },
    {
        key: '51 - 100',
        value: '51-100',
    },
    {
        key: '100 - 200',
        value: '100-200',
    },
    {
        key: '200 - 500',
        value: '200-500',
    },
    {
        key: '500 - 1000',
        value: '500-1000',
    },
    {
        key: '1000+',
        value: '1000+',
    },
];
