// Generated by https://quicktype.io

export interface IGroupDetail {
    id: number;
    name: string;
    creatorId: number;
    creationTime: string;
    description: string;
    isPrivate: boolean;
    isActive: boolean;
    avatar: string;
    code: string;
    email: string;
    avatarThumnailUrl: string;
    avatarUrl: string;
    memberCount: number;
    taskCount: number;
    type: EGroupType;
    isMember: boolean;
    isAdminGroup: boolean;
    hasMoreTask: boolean;
    lastCreatedGroupTask: string;
    groupDomains: IGroupDomain[];
    groupUsers: IGroupUser[];
    groupTasks: any[];
    notification?: any;
    isFavorite?: boolean;
}

export interface IGroupDomain {
    id: number;
    domainId: number;
    groupId: number;
    domainName: string;
    system: boolean;
    status: number;
}

export interface IGroupUser {
    userId: number;
    role: number;
    id?: number;
    groupId?: number;
    creatorId?: number;
    creationTime?: string;
    isActive?: boolean;
    isUserActive?: boolean;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    avatarUrl?: string;
    avatarThumbnailUrl?: string;
    adminType?: number;
}

export enum EGroupType {
    CHAT = 'CHAT',
    NORMAL = 'NORMAL',
    DOMAIN = 'DOMAIN',
}
