<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.0"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    enable-background="new 0 0 48 48"
    xml:space="preserve"
  >
    <g>
      <path
        fill="#4bb0b2"
        d="M40,12H22l-4-4H8c-2.2,0-4,1.8-4,4v8h40v-4C44,13.8,42.2,12,40,12z"
      />
    </g>
    <g>
      <path
        fill="#7fc6c9"
        d="M40,12H8c-2.2,0-4,1.8-4,4v20c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V16C44,13.8,42.2,12,40,12z"
      />
    </g>
  </svg>

  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    xmlns:svgjs="http://svgjs.com/svgjs"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 64 64"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--    class=""-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path-->
  <!--        fill="#4bb0b2"-->
  <!--        d="M59 10H24.4a1 1 0 0 0-.78 1.625l4.4 5.5A4.973 4.973 0 0 0 31.92 19H63a1 1 0 0 0 1-1v-3a5.006 5.006 0 0 0-5-5z"-->
  <!--        data-original="#ffb125"-->
  <!--        class=""-->
  <!--        opacity="1"-->
  <!--      ></path>-->
  <!--      <path-->
  <!--        fill="#7fc6c9"-->
  <!--        d="M64 17H31.92a2.995 2.995 0 0 1-2.338-1.124L24.58 9.624A6.969 6.969 0 0 0 19.12 7H5a5 5 0 0 0-5 5v40a5 5 0 0 0 5 5h54a5 5 0 0 0 5-5z"-->
  <!--        data-original="#fcd354"-->
  <!--        class=""-->
  <!--        opacity="1"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->
</template>
