<script setup lang="ts">
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    visible: boolean;
    headerText: string;
    showHeader: boolean;
    contentText: string;
    showCancelBtn: boolean;
    showConfirmBtn: boolean;
    showDeleteBtn: boolean;
    deleteLabel: string;
    confilmLabel: string;
    isLoading: boolean;
    disabledBtn: boolean;
    zIndex: string;
  }>(),
  {
    showHeader: true,
    showCancelBtn: true,
    showDeleteBtn: true,
    zIndex: 'z-30',
  }
);

const emit = defineEmits(['remove', 'onDelete', 'cancel', 'onConfirm']);

const isLoad = ref(false);
watch(
  () => props.isLoading,
  () => {
    isLoad.value = props.isLoading;
  }
);
const onClose = () => {
  emit('cancel', false);
};
const onConfirm = () => {
  emit('onDelete', true);
  emit('onConfirm', true);
};
</script>
<template>
  <SynModal
    v-if="visible"
    :z-index="zIndex"
    container-class="md:w-1/3"
    :confirm-text="$t('COMMON_LABEL_CONFIRM')"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :is-hidden-header="!showHeader"
    @cancel="onClose"
  >
    <template v-if="showHeader" #header>
      <div class="flex items-center">
        <span class="capitalize">{{ headerText }}</span>
      </div>
    </template>
    <template #body>
      <div class="text-lg" v-html="contentText"></div>
      <slot name="content"></slot>
    </template>
    <template #footer>
      <div class="p-4 flex justify-end items-center space-x-2">
        <SynButton
          v-if="showCancelBtn"
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="onClose"
        />
        <SynButton
          v-if="showDeleteBtn"
          :is-loading="isLoad"
          :disabled="isLoad || disabledBtn"
          :label="deleteLabel ? deleteLabel : $t('COMMON_LABEL_CONFIRM')"
          type-fill
          color="red"
          @click="onConfirm"
        />
        <SynButton
          v-if="showConfirmBtn"
          :is-loading="isLoad"
          :disabled="isLoad || disabledBtn"
          :label="confilmLabel ? confilmLabel : $t('COMMON_LABEL_CONFIRM')"
          @click="onConfirm"
        />
      </div>
    </template>
  </SynModal>
</template>
