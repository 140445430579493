<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import DomainById from '@/ui/modules/domains/component/DomainById.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import UserById from '@/ui/components/user/UserById.vue';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import domainStore from '@/store/scope';
import { ignoreUnicode } from '@/ui/plugins/utils';

const props = defineProps<{
  taskList?: any;
  isFinishTask?: boolean;
}>();
const emits = defineEmits([
  'isOpenFilter',
  'onFilterUser',
  'onChange',
  'update:taskList',
]);

const _userStore = userStore();
const _groupStore = groupStore();
const _domainStore = domainStore();
const originalTasks = ref<any>();

const isOpenFilter = ref(false);
const allUserByIds = computed(() => _userStore.allUserByIds);
const allGroupByIds = computed(() => _groupStore.myGroupByIds);
const allDomainByIds = computed(() => _domainStore.domainByIds);

const userList = computed(() => {
  const arrUserList = props.isFinishTask
    ? [...new Set(originalTasks.value?.map((task) => task?.assigneeId))]?.map(
        (userId: any) => allUserByIds.value[userId]
      )
    : [...new Set(originalTasks.value?.map((task) => task?.creatorId))]?.map(
        (userId: any) => allUserByIds.value[userId]
      );
  return arrUserList;
});

const groupList = computed(() => {
  return [...new Set(originalTasks.value?.map((task) => task?.groupId))]?.map(
    (groupId: any) => allGroupByIds.value[groupId]
  );
});

const domainList = computed(() => {
  return [...new Set(originalTasks.value?.map((task) => task?.domainId))]?.map(
    (domainId: any) => allDomainByIds.value[domainId]
  );
});

const currentIndex = ref(0);
const searchText = ref(null) as any;

const filterUser = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);
  if (!searchTxt) return userList.value;
  return userList.value.filter(
    (user) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
  );
});
const filterGroup = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);
  if (!searchTxt) return groupList.value;
  return groupList.value.filter(
    (group) => group?.name && ignoreUnicode(group?.name).includes(searchTxt)
  );
});
const filterDomain = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);
  if (!searchTxt) return domainList.value;
  return domainList.value.filter(
    (domain) => domain?.name && ignoreUnicode(domain?.name).includes(searchTxt)
  );
});
const isCheckFilterUser = (el) => {
  return props.isFinishTask
    ? (filter.value?.userId.length &&
        filter.value.userId.findIndex((o) => o == el?.assigneeId) !== -1) ||
        filter.value.userId.length < 1
    : (filter.value?.userId.length &&
        filter.value.userId.findIndex((o) => o == el?.creatorId) !== -1) ||
        filter.value.userId.length < 1;
};

const isCheckFilterGroup = (el) => {
  return (
    (filter.value?.groupId.length &&
      filter.value.groupId.findIndex((o) => o == el?.groupId) !== -1) ||
    filter.value.groupId.length < 1
  );
};

const isCheckFilterDomain = (el) => {
  return (
    (filter.value?.domainId.length &&
      filter.value.domainId.findIndex((o) => o == el?.domainId) !== -1) ||
    filter.value.domainId.length < 1
  );
};

const filteredTasks = computed(() => {
  return originalTasks.value?.filter(
    (el) =>
      isCheckFilterUser(el) && isCheckFilterGroup(el) && isCheckFilterDomain(el)
  );
});

const conferenceMenuList = {
  MEMBERS: {
    key: 'members',
    label: 'Members',
    labelKey: 'DASHBOARD_TABLE_LABEL_MEMBER',
  },
  GROUPS: {
    key: 'groups',
    label: 'Groups',
    labelKey: 'COMMON_LABEL_GROUPS',
  },
  DOMAINS: {
    key: 'domains',
    label: 'Domains',
    labelKey: 'COMMON_MODULE_PROJECT_MANAGEMENT',
  },
};

const currentTab = ref(conferenceMenuList['MEMBERS'].key);
const menuList = computed(() => {
  let data = [] as any;
  if (userList.value?.length) data = [...data, conferenceMenuList?.MEMBERS];
  if (groupList.value?.length) data = [...data, conferenceMenuList?.GROUPS];
  if (domainList.value?.length) data = [...data, conferenceMenuList?.DOMAINS];
  return data;
});

const openFilter = () => {
  isOpenFilter.value = true;
  emits('isOpenFilter', isOpenFilter.value);
};

const closeFilter = () => {
  isOpenFilter.value = false;
  emits('isOpenFilter', isOpenFilter.value);
};

const filter = ref({
  userId: [] as any[],
  groupId: [] as any[],
  domainId: [] as any[],
});

const onResetClick = () => {
  searchText.value = '';
  filter.value.userId = [];
  filter.value.groupId = [];
  filter.value.domainId = [];

  emits('update:taskList', filteredTasks.value);
};

const chooseAllMember = () => {
  filter.value.userId = [];
  emits('update:taskList', originalTasks.value);
};

const chooseAllGroup = () => {
  filter.value.groupId = [];
  emits('update:taskList', originalTasks.value);
};

const chooseAllDomain = () => {
  filter.value.domainId = [];
  emits('update:taskList', originalTasks.value);
};

const chooseMembers = (id: any) => {
  if (filter.value.userId.findIndex((el) => el == id) !== -1) {
    filter.value.userId = filter.value.userId.filter((o) => o !== id);
  } else {
    filter.value.userId.push(id);
  }
  emits('update:taskList', filteredTasks.value);
};

const chooseGroups = (id: any) => {
  if (filter.value.groupId.findIndex((el) => el == id) !== -1) {
    filter.value.groupId = filter.value.groupId.filter((o) => o !== id);
  } else filter.value.groupId.push(id);
  emits('update:taskList', filteredTasks.value);
};

const chooseDomains = (id: any) => {
  if (filter.value.domainId.findIndex((el) => el == id) !== -1) {
    filter.value.domainId = filter.value.domainId.filter((o) => o !== id);
  } else filter.value.domainId.push(id);
  emits('update:taskList', filteredTasks.value);
};

onMounted(() => {
  currentTab.value = conferenceMenuList['MEMBERS'].key;
  originalTasks.value = props.taskList;
});
</script>

<template>
  <VigDropdown
    placement="bottom-end"
    @on-dropdown-open="openFilter()"
    @on-dropdown-close="closeFilter()"
  >
    <template #dropdown-toggle>
      <div
        class="
          relative
          flex-center
          space-x-2
          ml-2
          cursor-pointer
          rounded-full
          hover:bg-current-50
          fill-current
          h-8
          w-8
        "
        :class="
          filter.userId?.length > 0 ||
          filter.groupId?.length > 0 ||
          filter.domainId?.length > 0
            ? 'bg-current-50'
            : 'bg-white'
        "
      >
        <SynIcon has-action name="filter2" />
        <div
          v-if="
            filter.userId?.length > 0 ||
            filter.groupId?.length > 0 ||
            filter.domainId?.length > 0
          "
          class="absolute top-0 right-0 w-2.5 h-2.5 bg-current-500 rounded-full"
        ></div>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="p-3 flex flex-col" style="max-height: 27rem; width: 22rem">
        <SynTabScroll v-model="currentTab" :menu-list="menuList">
          <template #prefix>
            <div
              class="
                text-xs
                flex-center
                space-x-1
                justify-end
                absolute
                right-0
                p-2
              "
            >
              <div class="h-6 border-l pr-3"></div>
              <button
                class="
                  px-2
                  py-1
                  bg-white
                  hover:bg-current-50
                  border border-current-500
                  text-current-500
                  rounded-full
                "
                @click="onResetClick"
              >
                {{ $t('COMMON_LABEL_RESET') || 'Reset' }}
              </button>
            </div>
          </template>
          <template #prefix-header>
            <div class="pt-4 px-2 flex-center space-x-4">
              <VigSearchBox
                v-model="searchText"
                v-model:current-index="currentIndex"
                :total-result="
                  filterUser?.length +
                  filterGroup?.length +
                  filterDomain?.length
                "
                class="w-full bg-white"
                input-class="text-sm"
                autofocus
              />
            </div>
          </template>
          <template #body>
            <div
              class="flex-1 overflow-auto small-scrollbar pb-3"
              style="scroll-behavior: smooth"
            >
              <div class="flex flex-col">
                <!--                Filter user-->
                <div
                  :id="`syn-tab-scroll-item-${conferenceMenuList?.MEMBERS?.key}`"
                  class="
                    flex-1
                    overflow-auto
                    small-scrollbar
                    p-1
                    flex flex-col
                    gap-1
                  "
                >
                  <div
                    v-if="userList?.length > 0"
                    class="
                      px-2
                      pt-3
                      pb-1
                      uppercase
                      text-sm
                      font-semibold
                      tracking-widest
                      text-gray-500
                    "
                  >
                    {{ $t(conferenceMenuList?.MEMBERS?.labelKey) || 'Members' }}
                  </div>
                  <div class="flex items-center">
                    <SynIcon
                      class="cursor-pointer fill-current text-current mr-3 ml-2"
                      name="checkbox"
                      :is-active="filter.userId?.length <= 0"
                      @click="chooseAllMember"
                    />
                    <span
                      class="text-sm"
                      :class="
                        filter.userId?.length <= 0
                          ? 'text-current font-medium'
                          : ''
                      "
                      >{{ $t('COMMON_LABEL_ALL') }}</span
                    >
                  </div>
                  <div
                    v-for="user in filterUser"
                    :key="user?.id"
                    class="flex items-center mt-2"
                  >
                    <SynIcon
                      class="cursor-pointer fill-current text-current mr-3 ml-2"
                      name="checkbox"
                      :is-active="
                        filter.userId.findIndex((o) => o === user?.id) !== -1
                      "
                      @click="chooseMembers(user?.id)"
                    />
                    <UserById
                      :user-id="user?.id"
                      avatar-class="w-5 h-5"
                      class="flex-1 text-sm text-overflow-hidden-line"
                      :class="
                        filter.userId.findIndex((o) => o === user?.id) !== -1
                          ? 'text-current font-medium'
                          : ''
                      "
                    />
                  </div>
                </div>

                <!--                Filter Group-->
                <div
                  :id="`syn-tab-scroll-item-${conferenceMenuList?.GROUPS?.key}`"
                  class="
                    flex-1
                    overflow-auto
                    small-scrollbar
                    p-1
                    flex flex-col
                    gap-1
                  "
                >
                  <div
                    v-if="groupList?.length > 0"
                    class="
                      px-2
                      pt-3
                      pb-1
                      uppercase
                      text-sm
                      font-semibold
                      tracking-widest
                      text-gray-500
                    "
                  >
                    {{ $t(conferenceMenuList?.GROUPS?.labelKey) || 'Groups' }}
                  </div>
                  <div class="flex items-center">
                    <SynIcon
                      class="cursor-pointer fill-current text-current mr-3 ml-2"
                      name="checkbox"
                      :is-active="filter.groupId.length <= 0"
                      @click="chooseAllGroup"
                    />
                    <span
                      class="text-sm"
                      :class="
                        filter.groupId.length <= 0
                          ? 'text-current font-medium'
                          : ''
                      "
                      >{{ $t('COMMON_LABEL_ALL') }}</span
                    >
                  </div>
                  <div
                    v-for="group in filterGroup"
                    :key="group?.id"
                    class="flex items-center mt-2"
                  >
                    <SynIcon
                      class="cursor-pointer fill-current text-current mr-3 ml-2"
                      name="checkbox"
                      :is-active="
                        filter.groupId.findIndex((o) => o === group?.id) !== -1
                      "
                      @click="chooseGroups(group?.id)"
                    />
                    <GroupById
                      :group-id="group?.id"
                      avatar-class="w-5 h-5"
                      class="flex-1 text-sm text-overflow-hidden-line"
                      :class="
                        filter.groupId.findIndex((o) => o === group?.id) !== -1
                          ? 'text-current font-medium'
                          : ''
                      "
                    />
                  </div>
                </div>

                <!--                Filter by domain-->
                <div
                  :id="`syn-tab-scroll-item-${conferenceMenuList?.DOMAINS?.key}`"
                  class="
                    flex-1
                    overflow-auto
                    small-scrollbar
                    p-1
                    flex flex-col
                    gap-1
                  "
                >
                  <div
                    v-if="domainList?.length > 0"
                    class="
                      px-2
                      pt-3
                      pb-1
                      uppercase
                      text-sm
                      font-semibold
                      tracking-widest
                      text-gray-500
                    "
                  >
                    {{ $t(conferenceMenuList?.DOMAINS?.labelKey) || 'Domains' }}
                  </div>
                  <div class="flex items-center">
                    <SynIcon
                      class="cursor-pointer fill-current text-current mr-3 ml-2"
                      name="checkbox"
                      :is-active="filter.domainId?.length <= 0"
                      @click="chooseAllDomain"
                    />
                    <span
                      class="text-sm"
                      :class="
                        filter.domainId?.length <= 0
                          ? 'text-current font-medium'
                          : ''
                      "
                      >{{ $t('COMMON_LABEL_ALL') }}</span
                    >
                  </div>
                  <div
                    v-for="id in filterDomain"
                    :key="id"
                    class="flex items-center mt-2"
                  >
                    <SynIcon
                      class="cursor-pointer fill-current text-current mr-3 ml-2"
                      name="checkbox"
                      :is-active="
                        filter.domainId.findIndex((o) => o === id?.id) !== -1
                      "
                      @click="chooseDomains(id?.id)"
                    />
                    <DomainById
                      :domain-id="id?.id"
                      avatar-class="w-5 h-5"
                      class="flex-1 text-sm text-overflow-hidden-line"
                      :class="
                        filter.domainId.findIndex((o) => o === id?.id) !== -1
                          ? 'text-current font-medium'
                          : ''
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </SynTabScroll>
      </div>
    </template>
  </VigDropdown>
</template>
