import { AxiosInstance } from 'axios';
import BaseRequest from '@/base/api/base-request';
export default class VideoCallRepository {
    private static instance: VideoCallRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): VideoCallRepository {
        if (!VideoCallRepository.instance) {
            // Get from local storage
            VideoCallRepository.instance = new VideoCallRepository();
        }

        return VideoCallRepository.instance;
    }

    conferenceSignature(data) {
        return this.request({
            url: `VideoCall/GenerateSignatureForVideoCall`,
            method: 'post',
            data,
        });
    }

    setVideoCallSession(data) {
        return this.request({
            url: `VideoCall/SetVideoCallSession`,
            method: 'post',
            data,
        });
    }

    setVideoCallParticipants(data) {
        return this.request({
            url: `VideoCall/SetVideoCallParticipants`,
            method: 'post',
            data,
        });
    }

    // AWS chime
    createConference(data) {
        return this.request({
            url: `VideoCall/StartCall`,
            method: 'post',
            data,
        });
    }

    getCallHistories() {
        return this.request({
            url: `VideoCall/GetCallHistories`,
            method: 'get',
        });
    }

    forceEndCall(data) {
        return this.request({
            url: `VideoCall/LeaveSession`,
            method: 'post',
            data,
        });
    }

    actionOnCall(data) {
        return this.request({
            url: `VideoCall/ActionOnCall`,
            method: 'post',
            data,
        });
    }

    answer(data) {
        return this.request({
            url: `VideoCall/Answer`,
            method: 'post',
            data,
        });
    }
}
