<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue';
import AtomKbdLabel from '@/ui/common/atoms/AtomLabels/AtomKbdLabel.vue';

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    isDisabled?: boolean;
    modelValue: string;
    inputType?: string;
    iconPrefix?: string;
    errorMessage?: string;
    name?: string;
    inputRef?: any;
    autofocus?: boolean;
    style?: string;
    required?: boolean;
    customClass?: string;
    sizeIconSearch?: string;
    widthMax?: string;
    // keyCode?:string;
    isShowShortCutKey?: boolean;
    isShowReset?: boolean;
    isAlwayShow?: boolean;
  }>(),
  {
    placeholder: 'Syn input',
    inputType: 'text',
    inputRef: null,
    sizeIconSearch: 'w-9 h-9',
    widthMax: '18rem',
    isShowShortCutKey: false,
    iconPrefix: '',
    errorMessage: '',
    name: '',
    style: '',
    customClass: '',
  }
);

const emits = defineEmits([
  'blur',
  'focus',
  'enter',
  'update:modelValue',
  'keydown',
  'change',
  'onOpen',
  'onClose',
  'onReset',
]);

const isShow = ref(!!props.isAlwayShow);
const synSearchRef = ref(null as any);
const synInputSearchRef = ref(null as any);

onMounted(() => {
  if (props.modelValue || props.isAlwayShow) showSearch();
});

watch(
  () => props.modelValue,
  async () => {
    if (props.modelValue?.trim()?.length > 0) {
      await showSearch();
    }
  }
);

const showSearch = async () => {
  isShow.value = true;
  emits('onOpen');
  await nextTick();

  synSearchRef.value.style.transition = 'width 0.2s';
  synInputSearchRef.value.style.display = 'block';
  synSearchRef.value.style.width = props.widthMax;

  if (props.autofocus)
    setTimeout(() => {
      synInputSearchRef.value.focus();
    });
};

const notShowSearch = () => {
  if (props.modelValue || props.isAlwayShow) return;
  if (!props.isAlwayShow) isShow.value = false;

  emits('onClose');
  if (synInputSearchRef.value) {
    synInputSearchRef.value.style.display = 'none';
  }
  if (synSearchRef.value) {
    synSearchRef.value.style.width = '2.25rem';
    synSearchRef.value.style.height = '2.25rem';
  }
};

let delayTime;
const onChange = (event) => {
  delayTime && clearTimeout(delayTime);

  delayTime = setTimeout(() => {
    emits('update:modelValue', event.target.value);
    emits('change', event);
  }, 300);
};

const onResetSearchClick = () => {
  clearTimeout(delayTime);

  setTimeout(() => {
    emits('update:modelValue', null);
    emits('change', null);
    emits('onReset', null);
  });
};

defineExpose({
  showSearch,
});
</script>

<template>
  <button
    ref="synSearchRef"
    v-click-outside="notShowSearch"
    class="
      flex-center
      relative
      text-gray-600
      focus-within:text-gray-800
      rounded-full
      hover:bg-gray-100
      cursor-pointer
    "
    :class="[customClass, sizeIconSearch]"
    @click="showSearch"
  >
    <div
      v-if="iconPrefix"
      class="
        absolute
        inset-y-0
        left-2
        flex
        items-center
        h-full
        focus:outline-none focus:shadow-outline
      "
    >
      <SynIcon :name="iconPrefix" :class="isShow ? 'fill-current' : ''" />
    </div>
    <input
      v-if="isShow"
      ref="synInputSearchRef"
      :name="name"
      class="h-full w-full hidden pl-9 py-0.4 text-sm rounded-full vig-input"
      :class="{
        'border border-red-500': errorMessage,
        'bg-white': !isDisabled,
        'bg-gray-100': isDisabled,
        'ring ring-current': modelValue,
      }"
      :placeholder="placeholder"
      :value="modelValue"
      :type="inputType"
      :style="style"
      :required="required"
      :disabled="isDisabled"
      @input="onChange"
      @keyup.enter.prevent="$emit('enter', modelValue)"
      @keydown="$emit('keydown')"
      @blur="$emit('blur', modelValue)"
    />
    <div
      v-if="isShowReset && modelValue"
      class="
        absolute
        inset-y-0
        right-1
        flex
        items-center
        h-full
        border-0
        focus:outline-none focus:shadow-outline
      "
    >
      <SynIcon
        has-action
        name="close2"
        custom-class="rounded-full w-3 h-3"
        @click="onResetSearchClick"
      />
    </div>
    <div
      v-else
      class="
        absolute
        inset-y-0
        right-1
        flex
        items-center
        h-full
        border-0
        focus:outline-none focus:shadow-outline
      "
    >
      <slot name="suffix"></slot>
    </div>
    <div
      v-if="isShowShortCutKey && !modelValue && isShow"
      class="absolute top-1/2 right-3 text-2xs"
      style="margin-top: -12px"
    >
      <AtomKbdLabel key-code="S" />
    </div>
  </button>
</template>
