<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 512.007 512.007"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m497 482.006h-16v-296c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v296h-30v-196c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v196h-30v-141c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v141h-30v-105c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v105h-16c-8.284 0-15 6.716-15 15s6.716 15 15 15h482c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m15 322.31c93.618 0 195.14-33.622 285.866-94.673 71.055-47.815 130.286-108.898 167.078-171.445l14.568 54.367c1.796 6.702 10.467 12.492 18.371 10.606 8.058-1.922 12.75-10.369 10.606-18.371l-24.563-91.67c-2.145-8.001-10.366-12.751-18.371-10.606l-91.67 24.563c-8.002 2.144-12.75 10.369-10.606 18.371 2.145 8.002 10.366 12.752 18.371 10.606l59.055-15.824c-34.325 59.646-91.053 118.394-159.588 164.513-85.828 57.756-181.402 89.563-269.117 89.563-8.284 0-15 6.716-15 15s6.716 15 15 15z"
      ></path>
    </g>
  </svg>
</template>
