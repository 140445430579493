<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M9 10c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm0-9C6.794 1 5 2.794 5 5s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zM9.08 21H.5a.5.5 0 0 1-.5-.5v-4C0 14.019 2.019 12 4.5 12h5.76a.5.5 0 0 1 0 1H4.5C2.57 13 1 14.57 1 16.5V20h8.08a.5.5 0 0 1 0 1zM17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.032 0-5.5 2.468-5.5 5.5s2.468 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.468-5.5-5.5-5.5z"
        opacity="1"
      ></path>
      <path
        d="M19.5 20a.502.502 0 0 1-.354-.146l-2-2A.504.504 0 0 1 17 17.5v-3a.5.5 0 0 1 1 0v2.793l1.854 1.854A.5.5 0 0 1 19.5 20z"
        opacity="1"
      ></path>
    </g>
  </svg>
</template>
