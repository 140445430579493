<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 441.411 441.411"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M364 440.706H76c-41.974 0-76-34.026-76-76v-288c0-41.974 34.026-76 76-76h288c41.974 0 76 34.026 76 76v288c0 41.973-34.026 76-76 76zm-288-416c-28.682.088-51.912 23.318-52 52v288c.088 28.682 23.318 51.912 52 52h288c28.682-.088 51.912-23.318 52-52v-288c-.088-28.682-23.318-51.912-52-52H76z"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M12 312.706a11.84 11.84 0 0 1-8.48-3.52c-4.679-4.685-4.679-12.275 0-16.96l98.08-98.08c21.554-21.693 55.931-23.825 80-4.96l77.12 60c14.389 11.433 35.081 10.192 48-2.88l3.68-3.68c21.616-21.689 56.097-23.62 80-4.48l46.56 37.12c5.087 4.223 5.934 11.707 1.92 16.96-4.179 5.167-11.732 6.022-16.96 1.92l-46.4-37.28c-14.373-11.401-35.007-10.232-48 2.72l-3.68 3.68c-21.443 21.231-55.276 23.349-79.2 4.96l-77.12-60c-14.389-11.433-35.081-10.192-48 2.88l-99.04 98.08a11.84 11.84 0 0 1-8.48 3.52zM332 152.706c-24.301 0-44-19.699-44-44s19.699-44 44-44 44 19.699 44 44-19.699 44-44 44zm0-64c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
