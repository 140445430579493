<script setup>
import { onMounted, ref } from 'vue';
import ModalFolderSelect from '@/ui/modules/ged/folder/modal-folder-select/ModalFolderSelect.vue';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
// import $constants from '@/ui/plugins/constants';

import fileStorageStore from '@/store/fileStorage';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import $constants from '@/ui/plugins/constants';

const _fileStorageStore = fileStorageStore();

const props = defineProps({
  file: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['onClose', 'onConfirm', 'onConfirmProcessed']);
const selectedFile = ref();

onMounted(() => {
  selectedFile.value = props.file;
});

const saveToConfirmFunction = async (location) => {
  if (!selectedFile.value) return;

  if (!selectedFile.value.category) {
    if (
      selectedFile.value.module === ModuleSourceFileEnum.task ||
      (selectedFile.value.taskId && selectedFile.value.isNote)
    ) {
      selectedFile.value.category = $constants.FILE.CATEGORY.TASK;
    }
  }

  const result = await _fileStorageStore.saveFileToMyResource(
    selectedFile.value,
    location
  );

  if (result?.result) {
    openNotification({
      title: translate('COMMON_LABEL_SUCCESS'),
      body: translate('NOTIFICATION_GED_SAVE_FILE_TO_MY_RESOURCE_SUCCESS'),
    });
  } else {
    openNotification({
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body: translate('NOTIFICATION_GED_SAVE_FILE_TO_MY_RESOURCE_ERROR'),
    });
  }
};

const onClose = () => {
  emit('onClose');
};
</script>

<template>
  <ModalFolderSelect
    :confirm-function="saveToConfirmFunction"
    :function-name="$t('GED_FILE_SAVE_TO')"
    :z-index="50"
    @on-close="onClose"
    @on-confirm-processed="onClose"
  />
</template>
