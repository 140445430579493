<script setup lang="ts">
import PrincipleService from '@/application/services/PrincipleService';
import dayjs from 'dayjs';
import { ref } from 'vue';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import TimeWorking from '@/ui/modules/working/TimeWorking.vue';

const props = defineProps<{
  userId: any;
}>();

const isLoading = ref(false);

const workingTimeList = ref<any>();
const initComponent = async () => {
  isLoading.value = true;
  const res = await PrincipleService.getInstance().getPrincipleByUserId(
    props.userId
  );

  const data = res['result'];
  if (data) {
    workingTimeList.value = data?.principle?.map((o) => {
      o.pauseList = arrayOrderBy(
        data?.principlePauseDetail.filter((p) => p.principleId == o.id),
        'checkIn',
        'desc'
      );
      o.totalTaskOnDayList = data?.overviewTaskList.filter(
        (e) =>
          dayjs(e.planningDate).startOf('day').valueOf() ==
            dayjs(o.utc).startOf('day').valueOf() && e.isPlannedToTreat
      );
      o.totalTaskFinishedOnDayList = data?.overviewTaskList.filter(
        (e) =>
          dayjs(e.planningDate).startOf('day').valueOf() ==
            dayjs(o.utc).startOf('day').valueOf() && e.isPlannedToFinish
      );

      return o;
    });
  }
  isLoading.value = false;
};
initComponent();

const calculateDuration = (principle: any) => {
  return principle.duration > 0
    ? formatDecimal(principle.duration)
    : principle.duration;
};

const formatDecimal = (duration) => {
  const hours = Math.floor(duration);
  const minutes = Math.floor((duration % 1) * 60);
  const durationObj = dayjs.duration({ hours, minutes });
  return hours > 0
    ? durationObj.format('H[h] m[m]')
    : durationObj.format('m[m]');
};

const onOpenTaskDetail = (taskCode) => {
  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;
};
</script>
<template>
  <!-- TABLE SHOW ONE USER -->
  <SynTableCustom class="rounded" style="width: 100%">
    <template #header>
      <SynTr>
        <SynTh :label="$t('DASHBOARD_TABLE_LABEL_DATE')" />
        <SynTh :label="$t('DASHBOARD_TABLE_LABEL_TIME_WD')" />
        <SynTh :label="$t('DASHBOARD_TABLE_LABEL_DUR') + '.(h)'" />
        <SynTh
          :label="$t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_ON_DAY')"
        />
        <SynTh
          :label="
            $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_FINISH_TODAY')
          "
        />
      </SynTr>
    </template>
    <template #body>
      <SynTableTrLoading v-if="isLoading">
        <div v-for="item in 5" :key="item" class="table-cell p-2">
          <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
        </div>
      </SynTableTrLoading>
      <template v-else>
        <SynTr v-for="principle in workingTimeList" :key="principle.id">
          <SynTd style="width: 250px">
            <SynTag class="bg-current-50" style="width: 80%">
              {{ $filters.dayjs(principle?.utc, 'MMM DD, YYYY') }}
            </SynTag>
          </SynTd>
          <SynTd style="width: 650px">
            <TimeWorking
              style="width: 90%"
              :start-time="principle.start"
              :pause-list="principle.pauseList"
              :stop-time="principle.stop"
              :timezone="principle?.utcZone"
            />
          </SynTd>
          <SynTd class="text-center" style="width: 100px">
            {{ calculateDuration(principle) }}
          </SynTd>
          <SynTd class="text-left" style="width: 100px">
            <div
              v-if="principle?.totalTaskOnDayList.length > 0"
              class="relative w-full"
            >
              <div class="flex-center">
                <VigDropdown class="relative" trigger="mouseenter">
                  <template #dropdown-toggle>
                    <SynTag
                      position="flex item-center justify-center"
                      class="bg-current-50 w-max items-center cursor-pointer"
                      size="small"
                    >
                      {{ principle?.totalTaskOnDayList.length }}
                    </SynTag>
                  </template>
                  <template #dropdown-menu>
                    <div
                      class="
                        w-max
                        bg-white
                        rounded-md
                        flex flex-col
                        item-center
                        justify-center
                        overflow-y-auto
                        small-scrollbar
                      "
                      style="max-height: 400px"
                    >
                      <div
                        v-for="item in principle.totalTaskOnDayList"
                        :key="item"
                        class="
                          py-2
                          px-3
                          border-b
                          cursor-pointer
                          dropdown-item
                          hover:bg-gray-50
                        "
                      >
                        <div
                          class="text-current-500"
                          @click="onOpenTaskDetail(item.code)"
                        >
                          <span class="text-xs font-bold">
                            {{ item.code }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                </VigDropdown>
              </div>
            </div>
          </SynTd>
          <SynTd class="text-left" style="width: 100px">
            <div
              v-if="principle?.totalTaskFinishedOnDayList.length > 0"
              class="relative w-full"
            >
              <div class="flex-center">
                <VigDropdown class="relative" trigger="mouseenter">
                  <template #dropdown-toggle>
                    <SynTag
                      position="flex item-center justify-center"
                      class="bg-current-50 w-max items-center"
                      size="small"
                    >
                      {{ principle?.totalTaskFinishedOnDayList.length }}
                    </SynTag>
                  </template>
                  <template #dropdown-menu>
                    <div
                      class="
                        w-max
                        bg-white
                        rounded-md
                        flex flex-col
                        item-center
                        justify-center
                      "
                      style="max-height: 400px"
                    >
                      <div
                        v-for="item in principle?.totalTaskFinishedOnDayList"
                        :key="item"
                        class="
                          py-2
                          px-3
                          border-b
                          cursor-pointer
                          dropdown-item
                          hover:bg-gray-50
                        "
                      >
                        <div
                          class="text-current-500"
                          @click="onOpenTaskDetail(item.code)"
                        >
                          <span class="text-xs font-bold">
                            {{ item.code }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                </VigDropdown>
              </div>
            </div>
          </SynTd>
        </SynTr>
      </template>
    </template>
  </SynTableCustom>
</template>
