<script setup lang="ts">
// import getUserInfo from '@/ui/helpers/user-helper';

import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { CalendarMeetingType } from '@/ui/common/constants/calendar';
import { computed } from 'vue';
import { CalendarSourceId } from '@/domain/enums/CalendarEnum';

const props = defineProps<{
  eventData: any;
}>();

const isGoogleCalendarEvent = computed<boolean>(() => {
  return props.eventData?.sourceId === CalendarSourceId.googleCalendar;
});

const eventIcon = computed<string>(() => {
  if (isGoogleCalendarEvent.value) return 'GoogleCalendar';
  return props.eventData?.meetingType === CalendarMeetingType.OnlineMeeting
    ? 'Video'
    : 'Event';
});

const eventTitle = computed<string>(() => {
  if (isGoogleCalendarEvent.value) return 'COMMON_LABEL_EVENT';
  return props.eventData?.meetingType === CalendarMeetingType.OnlineMeeting
    ? 'COMMON_LABEL_MEETING'
    : 'COMMON_LABEL_EVENT';
});
</script>

<template>
  <!--  <div class="flex-1 flex flex-col space-y-1 text-sm">-->
  <!--    <div :title="eventData?.title" class="space-x-1 flex items-center">-->
  <!--      &lt;!&ndash;      <span>{{ $t('CALENDAR_FILTER_TITLE_TYPE_EVENT') }}:</span>&ndash;&gt;-->
  <!--      <SynIcon-->
  <!--        custom-class="w-5 h-5 fill-purple-500"-->
  <!--        is-active-->
  <!--        :name="-->
  <!--          eventData.meetingType === CalendarMeetingType.OnlineMeeting-->
  <!--            ? 'video'-->
  <!--            : 'event'-->
  <!--        "-->
  <!--      />-->
  <!--      <span class="text-overflow-hidden-line text-gray-800 font-semibold">{{-->
  <!--        eventData?.title-->
  <!--      }}</span>-->
  <!--    </div>-->
  <!--    <div class="flex items-center space-x-2 text-sm px-1">-->
  <!--      <SynLabelDateTime-->
  <!--        format="date"-->
  <!--        ignore-timezone-->
  <!--        :datetime="eventData?.startDate"-->
  <!--      />-->
  <!--      <div class="flex items-center">-->
  <!--        (-->
  <!--        <span>{{ $filters.dayjs(eventData?.startDate, ' hh:mm A') }}</span>-->
  <!--        <span class="mx-1">-</span>-->
  <!--        <span>{{ $filters.dayjs(eventData?.endDate, ' hh:mm A') }}</span>-->
  <!--        )-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="flex flex-col">
    <div
      class="
        p-2
        flex
        items-center
        space-x-3
        rounded-t
        bg-purple-50
        text-purple-500
      "
    >
      <div class="flex-center">
        <SynIcon :name="eventIcon" custom-class="w-3.5 h-3.5 fill-purple-500" />
      </div>
      <div class="flex items-center space-x-2">
        <span class="text-xs font-medium">
          {{ $t(eventTitle) }}
        </span>
      </div>
    </div>
    <div class="px-2 py-3 flex flex-col space-y-3">
      <div class="flex items-center space-x-3">
        <div class="flex-center">
          <SynIcon name="Timer" custom-class="w-3.5 h-3.5 fill-gray-500" />
        </div>
        <div class="flex-1 flex items-center text-xs space-x-2">
          <SynLabelDateTime
            format="date"
            ignore-timezone
            :datetime="eventData?.startDate"
          />
          <div class="flex items-center">
            (
            <span>{{ $filters.dayjs(eventData?.startDate, ' hh:mm A') }}</span>
            <span class="mx-1">-</span>
            <span>{{ $filters.dayjs(eventData?.endDate, ' hh:mm A') }}</span>
            )
          </div>
        </div>
      </div>
      <div class="flex items-center space-x-3">
        <div class="flex-center">
          <SynIcon name="Tags" custom-class="w-3.5 h-3.5 fill-gray-400" />
        </div>
        <div
          class="flex-1 text-xs font-medium text-overflow-hidden"
          v-html="eventData?.title"
        ></div>
      </div>

      <div
        v-if="eventData?.workspace || eventData.metadata?.workspace"
        class="flex items-center space-x-3"
      >
        <div class="flex-center">
          <SynIcon name="location" custom-class="w-3.5 h-3.5 fill-gray-400" />
        </div>
        <div
          class="flex-1 text-xs text-gray-700"
          v-html="eventData?.workspace || eventData.metadata?.workspace"
        ></div>
      </div>

      <div v-if="eventData?.description" class="flex items-center space-x-3">
        <div class="flex-center">
          <SynIcon name="align-left" custom-class="w-3.5 h-3.5 fill-gray-400" />
        </div>
        <div
          class="flex-1 text-xs text-gray-700 italic text-overflow-hidden"
          style="max-width: 18rem"
          v-html="eventData?.description"
        ></div>
      </div>
    </div>
  </div>
</template>
