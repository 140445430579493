<template>
  <svg
    id="Coding_Badge"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Coding Badge"
    width="82.157"
    height="82.199"
    viewBox="0 0 82.157 82.199"
  >
    <path
      id="Path_1661"
      data-name="Path 1661"
      d="M51.093,31H20.284A1.284,1.284,0,0,0,19,32.284V72.078a1.284,1.284,0,0,0,1.592,1.284l15.1-3.851c16.136,4.031,15.173,3.851,15.4,3.851a1.284,1.284,0,0,0,1.284-1.284V32.284A1.284,1.284,0,0,0,51.093,31Z"
      transform="translate(5.39 8.799)"
      fill="#50d9d7"
    />
    <path
      id="Path_1662"
      data-name="Path 1662"
      d="M46.107,31V72.438L34.553,69.55,23,72.438V31Z"
      transform="translate(6.525 8.799)"
      fill="#00bebd"
    />
    <path
      id="Path_1663"
      data-name="Path 1663"
      d="M70.068,41.221a18.806,18.806,0,0,0-3.492,8.639A12.837,12.837,0,0,1,55.857,60.579a18.806,18.806,0,0,0-8.639,3.492,12.837,12.837,0,0,1-15.687,0,18.806,18.806,0,0,0-8.639-3.492A12.837,12.837,0,0,1,12.173,49.86a18.806,18.806,0,0,0-3.492-8.639,12.837,12.837,0,0,1,0-15.687,18.678,18.678,0,0,0,3.5-8.639A12.837,12.837,0,0,1,22.879,6.176a18.716,18.716,0,0,0,8.665-3.5,12.837,12.837,0,0,1,15.687,0,18.716,18.716,0,0,0,8.665,3.5A12.837,12.837,0,0,1,66.589,16.895a18.678,18.678,0,0,0,3.5,8.639,12.837,12.837,0,0,1-.026,15.687Z"
      transform="translate(1.704 0.004)"
      fill="#ffac00"
    />
    <circle
      id="Ellipse_298"
      data-name="Ellipse 298"
      cx="20.539"
      cy="20.539"
      r="20.539"
      transform="translate(20.539 12.842)"
      fill="#f3f3f3"
    />
    <g id="Group_4646" data-name="Group 4646" transform="translate(0 0.02)">
      <path
        id="Path_1664"
        data-name="Path 1664"
        d="M34.66,39.791a1.284,1.284,0,0,1-.911-.372l-5.135-5.135a1.284,1.284,0,0,1,0-1.823l5.135-5.135a1.289,1.289,0,0,1,1.823,1.823l-4.236,4.223L35.571,37.6a1.284,1.284,0,0,1-.911,2.2Zm12.837,0a1.284,1.284,0,0,1-.911-2.2l4.236-4.223-4.236-4.223a1.289,1.289,0,0,1,1.823-1.823l5.135,5.135a1.284,1.284,0,0,1,0,1.823l-5.135,5.135A1.284,1.284,0,0,1,47.5,39.791Zm-7.7,2.567a1.284,1.284,0,0,1-1.284-1.489l2.567-15.4a1.3,1.3,0,1,1,2.567.411l-2.567,15.4A1.284,1.284,0,0,1,39.795,42.359ZM6.419,17.968c-2.015,0-1.04-3.787-5.969-8.01a1.284,1.284,0,0,1,0-1.951A16.868,16.868,0,0,0,5.238.793,1.284,1.284,0,0,1,7.6.793a16.406,16.406,0,0,0,4.788,7.214,1.284,1.284,0,0,1,0,1.951A16.868,16.868,0,0,0,7.6,17.173,1.284,1.284,0,0,1,6.419,17.968Zm69.32,50.064c-2.015,0-1.04-3.851-5.969-8.01a1.284,1.284,0,0,1,0-1.951,16.868,16.868,0,0,0,4.788-7.214,1.284,1.284,0,0,1,2.362,0,16.406,16.406,0,0,0,4.788,7.214,1.284,1.284,0,0,1,0,1.951,16.868,16.868,0,0,0-4.788,7.214,1.284,1.284,0,0,1-1.181.8Z"
        transform="translate(0 -0.012)"
        fill="#ffac00"
      />
    </g>
  </svg>
</template>
