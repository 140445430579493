<script setup lang="ts">
import { computed, ref } from 'vue';
import paymentStore from '@/store/payment';
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';
import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';

import OrderInformationCard from '@/ui/modules/payments/billings/OrderInformationCard.vue';
import InvoiceInformationCard from '@/ui/modules/payments/billings/InvoiceInformationCard.vue';
import LicenseInformationCard from '@/ui/modules/payments/billings/LicenseInformationCard.vue';
import ActionDetailCard from '@/ui/modules/payments/billings/ActionDetailCard.vue';
import { EPaymentPurpose } from '@/application/enums/PaymentEnums';
import FileCard from '../../ged/files-list/file-card/FileCard.vue';
import ModalMultipleFileViewerInfo from '../../ged/modal-file-viewer/ModalMultipleFileViewerInfo.vue';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
const props = defineProps({
  transactionId: {
    default: '',
    type: String,
  },
});
defineEmits(['cancel']);

const _paymentStore = paymentStore();
const detailTransactionById = computed(() => _paymentStore.detailTransaction);

const fileShowViewer = ref<boolean>(false);

const file = ref<any>({
  name: 'invoice.pdf',
  contentType: 'application/pdf',
  path: '1000/5fb8befc-42fb-4a3e-9ed4-9ea9f690675c/tictop-certificate.pdf',
  pathUrl:
    'https://dmops4z5tql4w.cloudfront.net/1000/5fb8befc-42fb-4a3e-9ed4-9ea9f690675c/tictop-certificate.pdf',
  extension: '.pdf',
  category: 'STORAGE',
});
const isLoading = computed(() => _paymentStore.isLoadingDetailTransaction);

// const parserTextStatusPaymentVNPay = (transactionStatus, resCode) => {
//   if (transactionStatus === PaymentTransactionStatus.Success)
//     return PaymentStatusText.Paid;
//   if (
//       transactionStatus === PaymentTransactionStatus.Failed &&
//       resCode === VnpPaymentResponseCode.CancelledByCustomer
//   )
//     return PaymentStatusText.Cancelled;
//   return PaymentStatusText.Failed;
// };

const initData = async () => {
  await _paymentStore.getTransactionDetail(props?.transactionId);

  const lang = getLocalStorage(StorageConstant.LANGUAGE);
  const invoiceFiles = detailTransactionById.value?.invoiceFiles ?? [];

  file.value = invoiceFiles.find((x) => x.language === lang);
};
initData();

const onFileClick = () => {
  fileShowViewer.value = true;
  console.log('meomoe');
};
</script>

<template>
  <SynModal
    container-class="w-2/3 h-4/5"
    container-style="min-width: 60%;"
    @cancel="$emit('cancel', null)"
  >
    <template #header>
      <span class="font-medium">{{
        $t('BILLING_MODAL_HEADER_TRANSACTION_DETAIL')
      }}</span>
    </template>
    <template #body>
      <template v-if="isLoading">
        <div class="flex space-x-2 w-full h-full">
          <div class="w-2/3 h-full space-x-2 flex p-3 border border-gray-50">
            <span
              class="rounded-full w-12 animate-pulse bg-gray-200 h-4"
            ></span>
            <span
              class="rounded-full w-full animate-pulse bg-gray-200 h-4"
            ></span>
          </div>
          <div class="w-1/3 h-full flex">
            <span class="rounded w-full animate-pulse bg-gray-200 h-32"></span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex space-x-2 items-start">
          <div class="w-2/3 h-full flex flex-col space-y-3 p-3">
            <template
              v-if="
                detailTransactionById?.paymentPurpose ===
                EPaymentPurpose.APPLY_LICENSE
              "
            >
              <ActionDetailCard :transaction-detail="detailTransactionById" />
              <!-- License information -->
              <LicenseInformationCard
                :transaction-detail="detailTransactionById"
              />
            </template>

            <template v-else>
              <!-- Order Information -->
              <OrderInformationCard
                :transaction-detail="detailTransactionById"
              />

              <!-- Invoice information -->
              <InvoiceInformationCard
                :transaction-detail="detailTransactionById"
              />
              <!-- License information -->
              <LicenseInformationCard
                v-if="
                  detailTransactionById?.paymentPurpose ===
                  EPaymentPurpose.BUY_LICENSE
                "
                :transaction-detail="detailTransactionById"
              />
            </template>
          </div>

          <div class="w-1/3 rounded my-4">
            <div class="flex flex-col bg-gray-50 p-4">
              <span class="text-gray-600 font-medium pb-2 text-sm">
                {{ $t('BILLING_MODAL_INFORMATION_PAYER_TITLE') }}
              </span>
              <div
                v-if="
                  detailTransactionById?.createdBy === 1 ||
                  detailTransactionById?.payerName == 'ADMIN TICTOP' ||
                  detailTransactionById?.paymentPurpose ===
                    EPaymentPurpose.BUY_LICENSE
                "
                class="flex items-center space-x-2"
              >
                <div class="flex-center rounded-full w-8 h-8 bg-current-50">
                  <SynIcon name="TictopLogo" />
                </div>
                <span class="flex-1 font-normal">{{
                  detailTransactionById?.payerName
                }}</span>
              </div>
              <SynAvatar
                v-else
                :src="detailTransactionById?.payerAvatar"
                :name="detailTransactionById?.payerName"
                has-name
              />

              <div class="px-3 py-1 text-sm text-gray-500 fill-gray-500">
                <div class="flex items-center space-x-3">
                  <SynIcon name="email" custom-class="w-3 h-3" />
                  <div>{{ detailTransactionById?.payerEmail }}</div>
                </div>
                <div class="flex items-center space-x-3">
                  <SynIcon name="phone" custom-class="w-3 h-3" />
                  <div>{{ detailTransactionById?.payerPhone }}</div>
                </div>
              </div>
            </div>

            <div
              v-if="
                file && file.name && detailTransactionById.orderInfo.period != 0
              "
              class="flex flex-col bg-gray-50 p-4 mt-4"
            >
              <span class="text-gray-600 font-medium pb-2 text-sm">
                {{ $t('BILLING_MODAL_INVOICE_TITLE') }}
              </span>
              <div class="flex items-center space-x-2">
                <FileCard
                  :file="file"
                  :show-checkbox="false"
                  :show-actions-button="false"
                  :is-selection-mode="false"
                  :show-lock-file="false"
                  @on-file-click="onFileClick()"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </SynModal>

  <ModalMultipleFileViewerInfo
    v-if="fileShowViewer"
    :files="[file]"
    :editable="false"
    @on-close="fileShowViewer = false"
  >
  </ModalMultipleFileViewerInfo>
</template>
