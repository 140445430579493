import settingStore from '@/store/setting';
import permissionStore from '@/store/permission';
import allScreens from '@/ui/hooks/permission/all-screens.json';
import allFunctions from '@/ui/hooks/permission/all-functions.json';
import allFunctionsByModel from '@/ui/hooks/permission/all-functions-by-model.json';
import {
    EFunctionPermission,
    EScreenPermission,
} from '@/application/types/block-function.types';
import commonStore from '@/store/common/commonStore';

export const ALL_SCREENS = allScreens;
export const ALL_FUNCTIONS = allFunctions;
export const ALL_FUNCTIONS_BY_MODEL = allFunctionsByModel;

export const isEnabled = (functionName) => {
    const _settingStore = settingStore();
    let keyCheck;
    if (functionName == 'Messages') {
        keyCheck = 'chatEnabled';
    } else if (functionName == 'Resources') {
        keyCheck = 'gedEnabled';
    }
    if (functionName == 'Video') {
        keyCheck = 'videoCallEnabled';
    }
    const orgSetting = _settingStore._organizationSetting;
    const enabled = orgSetting[keyCheck];
    if (enabled != true) return false;
    return true;
};

export const checkPermissionByFunction = (functionCode: string) => {
    // function name has not defined

    const allFunctionPermission =
        permissionStore().functionPermissionsByCode || {};

    if (
        !Object.prototype.hasOwnProperty.call(
            allFunctionPermission,
            functionCode
        )
    )
        return EFunctionPermission.WARNING;

    return allFunctionPermission[functionCode].permission;
};
export const checkPermissionByScreen = (screen) => {
    const allScreenPermission = permissionStore().screenPermissionsByCode || {};

    if (!Object.prototype.hasOwnProperty.call(allScreenPermission, screen))
        return EScreenPermission.HIDDEN;

    return allScreenPermission[screen].permission;
    // return EPermission.WRITE;
};

export const checkPermissionByModel = () => {
    const model = permissionStore().organizationModel;

    if (model == 'PERSONAL') return EFunctionPermission.HIDDEN;

    return EFunctionPermission.ALLOW;
};

export const checkSystemConfig = (functionCode: string, checkValue: string) => {
    // const allSystemConfigs = {
    //   START_WO_FUNCTION_ALLOW_START_WORKING: '1',
    //   START_WO_FUNCTION_START_WORKING_SCREENS: 'HELLO_SCREEN',
    //   START_WO_FUNCTION_START_WORKING_FUNCTIONS:
    //     'START_STOP;PAUSE;RESTART;COUNT_TIME_FOR_ME;COUNT_TIME_FOR_ORG;',
    // };
    const allSystemConfigs = permissionStore().allSystemConfigs;
    if (!allSystemConfigs) return EFunctionPermission.HIDDEN;

    if (!Object.prototype.hasOwnProperty.call(allSystemConfigs, functionCode))
        return EFunctionPermission.HIDDEN;

    const currentActiveValueArray = allSystemConfigs[functionCode]?.split(';');

    const existedFunction = currentActiveValueArray
        ? currentActiveValueArray?.some((value) => value === checkValue)
        : false;

    return existedFunction
        ? EFunctionPermission.ALLOW
        : EFunctionPermission.HIDDEN;
};
export const checkPersonalConfig = (
    functionCode: string,
    checkValue: string
) => {
    const userConfigByKeys = commonStore().userConfigByKeys;
    if (!userConfigByKeys) return EFunctionPermission.ALLOW;

    if (!Object.prototype.hasOwnProperty.call(userConfigByKeys, functionCode))
        return EFunctionPermission.HIDDEN;

    const currentActiveValueArray =
        typeof userConfigByKeys[functionCode] == 'string'
            ? userConfigByKeys[functionCode]?.split(';')
            : [userConfigByKeys[functionCode]];

    const existedFunction = currentActiveValueArray
        ? currentActiveValueArray?.some((value) => value === checkValue)
        : false;

    return existedFunction
        ? EFunctionPermission.ALLOW
        : EFunctionPermission.HIDDEN;
};
