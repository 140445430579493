<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 489.94 448.66"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          class="cls-1"
          d="m394.55,161.8c7.19,19.5,11.12,40.57,11.12,62.54,0,99.99-81.35,181.33-181.33,181.33S43,324.32,43,224.33,124.35,43,224.33,43c45.22,0,86.63,16.64,118.42,44.12l31.82-29.38C333.29,20.42,280.38,0,224.33,0c-59.92,0-116.26,23.33-158.63,65.71C23.33,108.08,0,164.41,0,224.33s23.33,116.26,65.71,158.63c42.37,42.37,98.71,65.71,158.63,65.71s116.26-23.33,158.63-65.71c42.37-42.37,65.71-98.71,65.71-158.63,0-32.88-7.04-64.68-20.39-93.68l-33.73,31.15Z"
        />
        <path
          class="cls-1"
          d="m223.1,280.37c-5.46,0-10.91-2.07-15.09-6.18l-79.83-78.65c-8.46-8.33-8.56-21.95-.22-30.4s21.95-8.56,30.4-.23l65.22,64.26L453.86,16.51c8.72-8.06,22.32-7.52,30.38,1.21,8.06,8.72,7.51,22.33-1.21,30.38l-245.35,226.56c-4.12,3.81-9.36,5.7-14.58,5.7Z"
        />
      </g>
    </g>
  </svg>
</template>
