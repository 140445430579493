<script setup lang="ts">
import { computed } from 'vue';
import { sumBy } from 'lodash';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import dayjs from 'dayjs';

const props = defineProps<{
  startTime?: string;
  pauseList?: any;
  stopTime?: string;
  timezone?: string;
  timeLimit?: any;
}>();

const sumTimeout = computed(() => {
  const itemOnPause = props.pauseList.find((o) => !o.checkIn);
  if (itemOnPause) {
    const sumDuration = sumBy(props.pauseList, function (o: any) {
      return o.duration;
    });

    const timeNow = dayjs.utc().valueOf();
    const timeLimit = dayjs.utc(props.timeLimit).valueOf();

    return timeNow < timeLimit
      ? sumDuration + (timeNow - dayjs.utc(itemOnPause.checkOut).valueOf())
      : sumDuration + (timeLimit - dayjs.utc(itemOnPause.checkOut).valueOf());
  } else {
    return sumBy(props.pauseList, function (o: any) {
      return o.duration;
    });
  }
});

const formatMsToHourMinute = (ms) => {
  const hours = Math.floor(ms / 3600000); // 1 hour = 3600000 ms
  const minutes = Math.floor((ms % 3600000) / 60000); // 1 minute = 60000 ms
  return `${hours}h ${minutes}m`;
};

// const sumTime = () => {
//   if (props.pauseList.filter((e) => !e.checkIn) > 0) {
//     const sumDuration = props.pauseList
//       .map((o) => o.duration)
//       .reduce((total, currentValue) => total + currentValue, 0);
//     const itemOnPause = props.pauseList.find((o) => !o.checkIn);
//     const dateNow = dayjs().utc().valueOf();
//     const timePause =
//       sumDuration + (dateNow - dayjs.utc(itemOnPause.checkOut).valueOf());
//     return timePause;
//   } else {
//     return sumBy(props.pauseList, function (o: any) {
//       return o.duration;
//     });
//   }
// };
</script>

<template>
  <!--  <div class="w-full flex items-center">-->
  <!--    <SynTag class="w-20 bg-current-500 text-white rounded font-semibold">-->
  <!--      <span v-if="startTime">-->
  <!--        <SynLabelDateTime format="time" :datetime="startTime" />-->
  <!--      </span>-->
  <!--      <span v-else> &#45;&#45;:&#45;&#45; </span>-->
  <!--    </SynTag>-->

  <!--  <div class="flex-1 relative">-->
  <!--    <div class="w-full items-center justify-center flex">-->
  <!--      <div class="w-full h-0 flex items-center border-current border-b"></div>-->
  <!--      <div class="absolute m-auto">-->
  <div
    v-if="pauseList?.length <= 0"
    class="
      border
      flex
      items-center
      rounded-full
      bg-gray-50
      py-1
      space-x-2
      px-1
      w-24
      3xl:w-28
    "
  >
    <div
      class="w-6 h-6 flex-center rounded-full"
      style="background-color: #b79a78"
    >
      <SynIcon name="pause" custom-class="w-3 h-3 fill-white text-white" />
    </div>
    <span>00:00</span>
  </div>
  <div v-else class="w-24 3xl:w-28">
    <vig-dropdown
      :delay="[400, 0]"
      class="relative"
      trigger="mouseenter"
      placement="bottom"
    >
      <template #dropdown-toggle>
        <div
          class="
            border
            flex
            items-center
            rounded-full
            bg-white
            py-1
            space-x-2
            px-1
            w-24
            3xl:w-28
          "
        >
          <div class="w-6 h-6 bg-orange-500 flex-center rounded-full">
            <SynIcon
              name="pause"
              custom-class="w-3 h-3 fill-white text-white"
            />
          </div>
          <span>{{ formatMsToHourMinute(sumTimeout) }}</span>
        </div>
      </template>
      <template #dropdown-menu>
        <div class="w-max bg-white rounded-md px-2">
          <div
            class="
              py-3
              flex-center
              space-x-1
              font-medium
              fill-current
              text-sm text-current-500
            "
          >
            <SynIcon name="time-rest" />
            <span>{{ $t('DASHBOARD_LABEL_DESCRIPTION_PAUSE_TIME') }}</span>
          </div>
          <div class="py-2 border-b">
            <div
              class="
                grid grid-cols-3
                text-sm text-current-800
                whitespace-nowrap
                space-x-2
                text-center
              "
            >
              <span>
                {{ $t('CHECKIN_LABEL_FROM') }}
              </span>
              <span>
                {{ $t('CHECKIN_LABEL_TO') }}
              </span>
              <span>
                {{ $t('DASHBOARD_TABLE_LABEL_DUR') }}
              </span>
            </div>
          </div>
          <div v-for="item in pauseList" :key="item" class="py-2 px-2 border-b">
            <div
              class="grid grid-cols-3 whitespace-nowrap space-x-2 text-center"
              style="color: #ff6500"
            >
              <span class="text-xs">
                <SynLabelDateTime
                  v-if="item?.checkOut"
                  format="time"
                  :datetime="item?.checkOut"
                />
                <span v-else> --:-- </span>
              </span>
              <span class="text-xs">
                <SynLabelDateTime
                  v-if="item?.checkIn"
                  format="time"
                  :datetime="item?.checkIn"
                />
                <span v-else> --:-- </span>
              </span>
              <span>
                <SynClock
                  class="text-xs"
                  :date-time="item.duration"
                  is-distance
                />
              </span>
            </div>
          </div>
          <div v-if="sumTimeout > 0" class="px-2 py-2">
            <div
              class="
                flex
                justify-between
                items-center
                whitespace-nowrap
                space-x-2
              "
            >
              <span class="text-sm text-current-600">{{
                $t('COMMON_LABEL_TOTAL')
              }}</span>
              <SynClock
                style="color: #fc684f"
                class="font-medium"
                :date-time="sumTimeout"
                is-distance
              />
            </div>
          </div>
        </div>
      </template>
    </vig-dropdown>
  </div>
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--    <SynTag class="w-20 bg-gray-300 rounded font-semibold text-gray-700">-->
  <!--      <span>-->
  <!--        <SynLabelDateTime v-if="stopTime" format="time" :datetime="stopTime" />-->
  <!--        <span v-else> &#45;&#45;:&#45;&#45; </span>-->
  <!--      </span>-->
  <!--    </SynTag>-->
  <!--  </div>-->
</template>
