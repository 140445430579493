<script setup lang="ts">
import { computed } from 'vue';
import { sortBy } from 'lodash';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import MessageError from '../message-error/MessageError.vue';
import MessageLinks from '../message-links/MessageLinks.vue';
import GroupReaction from './GroupReaction.vue';
import ButtonReactions from '../button-reactions/ButtonReactions.vue';
import MessageFile from '@/ui/modules/messaging/chat-panel/chat-message/message-file/MessageFile.vue';
import { styleCustomizeMessage } from '@/ui/modules/messaging/chat-panel/chat-message/button-customize/calculate-color-customizes';
import calculateImageLayoutComposables from '@/ui/composables/chat/calculate-image-layout-composables';
import { ChatMessageAction } from '@/domain/enums/chat-enums';

const props = defineProps<{
  isExpandViewFile?: boolean;
  message?: any;
  size?: string;
  isMyMessage?: boolean;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  isErrorSend?: boolean;
  isDisabled?: boolean;
  members?: any;
  allowedActions?: ChatMessageAction[];
  isShowName?: boolean;
}>();

defineEmits<{
  (e: 'onClickTextMessage', value: any): void;
  (e: 'onHoverTextMessage', value: any): void;
  (e: 'onMouseLeaveTextMessage', value: any): void;
  (e: 'onClosePreviewLink', value: any): void;
  (e: 'onReactionsClick', value: any): void;
  (e: 'onReactionAdd', value: any): void;
  (e: 'update:isExpandViewFile', value: boolean): void;
  (e: 'onFileClick', value: any): void;
  (e: 'onMessageDownload', value: any): void;
}>();

const createdDate = computed(() => {
  if (!props.message?.createdDate) return new Date();

  return new Date(props.message.createdDate).getTime();
});

const hasReactions = computed(
  () => !!Object.keys(props.message?.reactions || {})?.length
);

const files = computed(() => {
  const msgFiles = Object.entries(props.message?.files || {}).map(
    ([id, file]: [string, any]) => ({ ...file, id })
  );
  return sortBy(msgFiles, ['index']);
});
const isOnlyOneFile = computed(() => files.value?.length == 1);

const styleMsg = computed(() =>
  styleCustomizeMessage(
    props?.message,
    props?.isMyMessage,
    props.allowedActions?.includes(ChatMessageAction.SET_BACKGROUND)
  )
);
const { containerDefaultBySize } = calculateImageLayoutComposables({
  size: props.size,
});

const containerClass = computed(() => {
  if (isOnlyOneFile.value) return { 'rounded-xl': true };

  return {
    'py-2': true,
    'px-4': true,
    'rounded-l-3xl': props.isMyMessage,
    'rounded-r-3xl': !props.isMyMessage,
    'rounded-b-3xl':
      (props.isLastInGroup && !files.value?.length) ||
      (!props.isExpandViewFile && files.value?.length),
    'rounded-t-3xl':
      props.isFirstInGroup && !props.message?.task && !props.message?.dayoff,
    'rounded-tr':
      props.isMyMessage &&
      props.message?.comment &&
      (props.message?.task || props.message?.dayoff),
    'rounded-tl':
      !props.isMyMessage &&
      props.message?.comment &&
      (props.message?.task || props.message?.dayoff),
    'rounded-br': props.isMyMessage && files.value?.length,
    'rounded-bl': !props.isMyMessage && files.value?.length,
    'border border-red-500': props.isErrorSend,
  };
});

const messageText = computed(() => {
  const location = getLocalStorage(StorageConstant.LOCATION);
  const needReplaceUrl =
    location == 'EU'
      ? import.meta.env.VITE_DOMAIN_DEFAULT
      : import.meta.env.VITE_DOMAIN_EU;
  const currentUrl =
    location == 'EU'
      ? import.meta.env.VITE_DOMAIN_EU
      : import.meta.env.VITE_DOMAIN_DEFAULT;

  return props.message?.text?.replaceAll(needReplaceUrl, currentUrl);
});
</script>

<template>
  <div
    v-if="
      (messageText &&
        !message?.task &&
        !message?.dayoff &&
        !message?.eventData) ||
      message?.comment
    "
    class="msg-content flex relative"
    :class="[
      isMyMessage ? 'flex-row-reverse' : 'flex-row',
      files?.length || message?.linkObjects?.length ? 'mb-0.5' : '',
    ]"
  >
    <div
      class="leading-5 whitespace-pre-line relative w-max-2xl"
      :class="containerClass"
      style="word-break: break-word"
      :style="[
        styleMsg?.border
          ? `${styleMsg?.all}; border: 2px solid ${styleMsg?.border}`
          : `${styleMsg?.all}`,
        isOnlyOneFile
          ? `max-width: ${containerDefaultBySize?.containerMaxWidth}px`
          : ``,
      ]"
    >
      <template v-if="isOnlyOneFile">
        <div
          class="cursor-pointer max-w-xl bg-gray-100 rounded-md"
          :style="`border: 2px solid ${styleMsg?.hexColor}; max-width: ${containerDefaultBySize?.containerMaxWidth}px;`"
        >
          <MessageFile
            :file="files[0]"
            :size="size"
            :length-files="files?.length"
            @on-file-click="$emit('onFileClick', files[0])"
          />
        </div>
      </template>
      <div v-if="isShowName" :class="isOnlyOneFile ? 'pl-2' : '-translate-y-1'">
        <span class="text-gray-500 text-xs">
          {{ members[message?.createdBy]?.name }}
        </span>
      </div>

      <section class="relative" :class="isOnlyOneFile ? 'p-2' : ''">
        <!-- Text -->
        <span
          v-if="
            !message?.task &&
            !message?.dayoff &&
            !message?.eventData &&
            messageText
          "
          v-twemoji="{ size: 'w-6 h-6' }"
          class="msg-text"
          :style="`${styleMsg?.color}`"
          @click="$emit('onClickTextMessage', $event)"
          @mouseover="$emit('onHoverTextMessage', $event)"
          @mouseleave="$emit('onMouseLeaveTextMessage', $event)"
          v-html="messageText"
        >
        </span>
        <!-- Comment -->
        <span
          v-else-if="message?.comment"
          v-twemoji="{ size: 'w-6 h-6' }"
          class="msg-text"
          v-html="message?.comment"
        ></span>

        <!-- LINKS -->
        <template v-if="message?.linkObjects?.length > 0">
          <div class="flex flex-wrap -mx-0.5 -my-0.5 py-2 w-max-2xl">
            <template v-for="link in message?.linkObjects" :key="link">
              <div
                v-if="link?.previewData?.isPreview"
                class="p-0.5 cursor-pointer h-max max-w-md message-file-item"
              >
                <MessageLinks
                  :link="link"
                  :is-my-message="isMyMessage"
                  :size="size"
                  @on-close-preview-link="$emit('onClosePreviewLink', link)"
                />
              </div>
            </template>
          </div>
        </template>

        <!-- DATE + COUNT FILES + REACTIONS -->
        <div
          class="flex items-end justify-between space-x-1"
          :class="{
            'justify-end': files?.length || (isMyMessage && !files?.length),
            'flex-row-reverse space-x-reverse': isMyMessage,
            'flex-row': !isMyMessage,
          }"
        >
          <div
            class="flex items-center space-x-1"
            :class="isMyMessage ? 'flex-row-reverse' : 'flex-row'"
          >
            <div
              v-if="isLastInGroup"
              class="text-2xs mt-0.5"
              :class="{
                'text-gray-500 text-right': isMyMessage,
                'text-gray-500 text-left': !isMyMessage,
                'pr-2':
                  (!isMyMessage && files?.length) ||
                  (!isMyMessage && hasReactions),
                'pl-2':
                  (isMyMessage && files?.length) ||
                  (isMyMessage && hasReactions),
              }"
              :style="`${styleMsg?.color}`"
            >
              <SynLabelDateTime
                format="time"
                :datetime="createdDate"
                ignore-timezone
              />
            </div>

            <template v-if="files?.length > 1">
              <div
                class="
                  px-2
                  py-0.5
                  rounded-full
                  cursor-pointer
                  text-2xs
                  fill-gray-500
                "
                :class="
                  isMyMessage
                    ? 'text-gray-500 hover:bg-current-100'
                    : 'text-gray-500 hover:text-gray-800 hover:bg-gray-400 hover:bg-opacity-40'
                "
                :style="`${styleMsg?.color}`"
                @click.stop="
                  $emit('update:isExpandViewFile', !isExpandViewFile)
                "
              >
                <span class="pr-1">{{ files?.length + ' file(s)' }}</span>
                <SynIcon
                  name="arrow-down"
                  :custom-class="isMyMessage ? 'w-2 h-2' : 'w-2 h-2'"
                  :class="[
                    isExpandViewFile ? '' : '-rotate-90',
                    'text-gray-500',
                  ]"
                />
              </div>
            </template>
          </div>

          <GroupReaction
            v-if="
              hasReactions &&
              !isDisabled &&
              allowedActions?.includes(ChatMessageAction.REACTION)
            "
            class="mt-1"
            :reactions="message?.reactions"
            :members="members"
            :is-in-group="true"
            :is-my-message="isMyMessage"
            @on-reaction-add="$emit('onReactionAdd', $event)"
            @on-reactions-click="$emit('onReactionsClick', $event)"
          />
        </div>

        <!-- BUTTON REACTION -->
        <div
          v-if="
            !hasReactions &&
            !isDisabled &&
            allowedActions?.includes(ChatMessageAction.REACTION)
          "
          class="w-6 h-6 absolute hover-to-show__children -bottom-2 z-10"
          :class="[isMyMessage ? 'left-0' : 'right-0']"
        >
          <ButtonReactions
            custom-class="bg-white border w-6 h-6"
            @on-emoji-click="$emit('onReactionAdd', $event)"
          />
        </div>
      </section>

      <MessageError
        v-if="isErrorSend"
        :is-my-message="isMyMessage"
        :is-first-in-group="isFirstInGroup"
        :is-last-in-group="isLastInGroup"
        :is-error="isErrorSend"
        :message="message"
        :files="files"
      />
    </div>
  </div>
</template>

<style>
.message-file-item {
  min-width: 10rem;
}

@media only screen and (min-width: 1280px) {
  .message-file-item {
    min-width: 17rem;
    width: 17rem;
  }
}
</style>
