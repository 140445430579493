<template>
  <video
    :src="path"
    :class="{ 'prevent-fullscreen': preventFullscreen }"
    controls
    style="width: 100%; height: 100%"
    @click="onVideoClick"
  />
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: null,
    },
    clickToPlay: {
      type: Boolean,
      default: true,
    },
    preventFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const onVideoClick = (event) => {
      if (props.clickToPlay) return;

      event.preventDefault();

      if (!event.target.paused) event.target.pause();
    };
    return {
      onVideoClick,
    };
  },
};
</script>

<style scoped>
video.prevent-fullscreen::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
</style>
