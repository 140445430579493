<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ICustomTaskColumn } from '@/application/interfaces/tasks/task-interfaces';
import taskCustomConfigStore from '@/store/task/task-custom-config';
import { CUSTOM_COLUMN_CONFIG_BY_CODE } from '@/ui/modules/task/constants/task-custom-config.const';

const _taskCustomConfigStore = taskCustomConfigStore();

const taskColumnByCode = computed<ICustomTaskColumn[]>(
  () => _taskCustomConfigStore.taskColumnByCode
);

const customColumnList = ref<ICustomTaskColumn[]>([]);

onMounted(() => {
  customColumnList.value = [...Object.values(taskColumnByCode.value)]?.filter(
    (item) => item.adjustable
  );
});

const onFilterColumn = (code, data) => {
  _taskCustomConfigStore.updateTaskColumnByCode(code, data);
};
</script>
<template>
  <div class="w-full h-full flex flex-col space-y-1">
    <AtomCheckbox
      v-for="item in customColumnList"
      :key="item.code"
      v-model="item.visible"
      class="px-4 py-2 rounded hover:bg-current-50 hover:shadow"
      :label="$t(CUSTOM_COLUMN_CONFIG_BY_CODE[item.code]?.nameCode)"
      @update:model-value="onFilterColumn(item.code, item)"
    />
  </div>
</template>
