<script setup lang="ts">
// *** IMPORTS ***
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import UserConversationDocModel from '@/ui/plugins/firebases/firestore-database/models/UserConversationDocModel';
import taskDrawerStore from '@/store/task/drawer';
import chatSupportingStore from '@/store/chat/chat-supporting';
import chatStore from '@/store/chat';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import ChatService from '@/application/services/ChatService';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ConversationDelete from '@/ui/modules/messaging/chat-action/conversation-delete/ConversationDelete.vue';

// *** PROPS, EMITS ***
const props = defineProps<{
  organizationId: number;
  conversation: ChatConversationModel;
  canViewGroupChatTask?: boolean;
}>();

const emit = defineEmits(['onLeave', 'onPinned', 'onTurnOffNotification']);

// *** PRIVATE VARIABLES ***
const _myProfileStore = myProfileStore();
const _chatStore = chatStore();
const _notificationSettingStore = notificationSettingStore();
const _permissionStore = permissionStore();

// *** COMPOSABLES ***

// *** REFS ***

// *** COMPUTED ***
const myProfile = computed(() => _myProfileStore.myProfile);
const canLeaveConversation = computed(() =>
  _chatStore.isMeSupporter(props.conversation)
);
const mutedConversations = computed(() => _chatStore.mutedConversations);
const isConversationMuted = computed(
  () =>
    props.conversation?.id &&
    mutedConversations.value &&
    mutedConversations.value[props.conversation.id]?.offTime > Date.now()
);

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onConversationMarkRead = async () => {
  const userId = myProfile?.value?.id;
  const converId = props.conversation?.id;
  if (!userId || !converId) return;

  new UserConversationDocModel(userId, converId).setSeenAll();
};

const onArchiveConversation = async () => {
  const orgId = props.organizationId;
  const userId = myProfile?.value?.id;
  const converId = props.conversation?.id;
  if (!orgId || !userId || !converId) return;

  ChatService.setConversationArchived(orgId, userId, converId, true);
};

const onLeaveConversationClick = async () => {
  const converId = props.conversation?.id;
  if (!converId) return;

  const result = await chatSupportingStore().leaveConversationSupporting(
    converId
  );

  if (result) emit('onLeave');
};

const onEnableSettingNotification = () => {
  const converId = props.conversation?.id;
  if (!converId || !mutedConversations.value) return;

  const muteId = mutedConversations.value[converId]?.muteId;
  if (!muteId) return;

  _notificationSettingStore.enableSettingNotification(muteId);
};

const onViewTaskClick = () => {
  if (!props.conversation?.chatAboutTaskId) return;

  taskDrawerStore().pushCurrentIds({
    id: props.conversation.chatAboutTaskId?.toString(),
    code: props.conversation.chatAboutTaskCode,
    name: '',
    tabType: 'DETAIL',
  });
};

const onSetConversationPinned = async () => {
  const orgId = props.organizationId;
  const converId = props.conversation?.id;
  const pinned = props.conversation?.pinned;
  const userId = myProfile?.value?.id;

  if (!orgId || !converId || !userId) return;

  const limitNumberPinned = parseInt(
    _permissionStore.allSystemConfigs
      ? _permissionStore.allSystemConfigs[
          systemConfig.CHAT_TICTOP_C_LIMIT_THE_NUMBER_OF_PINNED_CON
        ] || 0
      : 0
  );
  const pinnedConvers = await ChatService.getPinnedConversations(orgId, userId);

  if (!pinned && pinnedConvers?.length >= limitNumberPinned) {
    settingGlobalModal({
      type: 'warning',
      title: translate('COMON_NOT_ALLOW_PERFORM_ACTION'),
      content: translate('CHAT_CONVERS_PIN_LIMIT_NUMBER_MSG', {
        number: limitNumberPinned,
      }),
      confirmable: true,
    });
    return ask();
  }

  ChatService.setConversationPinned(orgId, userId, converId, !pinned);
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div>
    <VigDropdown>
      <template #dropdown-toggle>
        <button
          class="
            w-8
            h-8
            flex-center
            bg-white
            rounded-full
            border
            hover:bg-gray-50
          "
        >
          <SynIcon name="DotsVertical" />
        </button>
      </template>
      <template #dropdown-menu>
        <div class="text-sm">
          <button
            v-if="conversation?.unSeen"
            class="
              px-4
              py-2
              w-full
              hover:bg-gray-100
              flex
              items-center
              space-x-4
              dropdown-item
            "
            @click="onConversationMarkRead"
          >
            <SynIcon name="Check" />
            <span>{{ $t('CHAT_CONVERS_MARK_READ') || 'Mark as read' }}</span>
          </button>
          <button
            v-if="conversation?.chatAboutTaskId && canViewGroupChatTask"
            class="
              px-4
              py-2
              w-full
              hover:bg-gray-100
              flex
              items-center
              space-x-4
              dropdown-item
            "
            @click="onViewTaskClick"
          >
            <SynIcon name="Note" class="fill-gray-500" />
            <span>
              {{ $t('CHAT_VIEW_TASK') }}
              <span class="font-semibold">{{
                formatTaskCode(conversation?.chatAboutTaskCode)
              }}</span>
            </span>
          </button>
          <button
            class="
              px-4
              py-2
              w-full
              hover:bg-gray-100
              flex
              items-center
              space-x-4
              dropdown-item
            "
            @click="onSetConversationPinned"
          >
            <SynIcon name="Pin" class="fill-gray-500" />
            <span>
              {{
                $t(
                  conversation?.pinned
                    ? 'CHAT_CONVERS_UNPIN'
                    : 'CHAT_CONVERS_PIN'
                )
              }}
            </span>
          </button>
          <button
            class="
              px-4
              py-2
              w-full
              hover:bg-gray-100
              flex
              items-center
              space-x-4
              dropdown-item
            "
            @click="onArchiveConversation"
          >
            <SynIcon name="Archive" class="text-gray-500" />
            <span>
              {{ $t('CHAT_CONVERS_ARCHIVE') || 'Archive conversation' }}
            </span>
          </button>
          <button
            v-if="isConversationMuted"
            class="
              px-4
              py-2
              w-full
              hover:bg-gray-100
              flex
              items-center
              space-x-4
              dropdown-item
            "
            @click="onEnableSettingNotification"
          >
            <SynIcon name="bell" class="fill-gray-600" />
            <span>
              {{ $t('CHAT_CONVERS_NOTIF_TURN_ON') || 'Turn on notifications' }}
            </span>
          </button>
          <button
            v-else
            class="
              px-4
              py-2
              w-full
              hover:bg-gray-100
              flex
              items-center
              space-x-4
              dropdown-item
            "
            @click="$emit('onTurnOffNotification')"
          >
            <SynIcon name="notification-off" class="fill-gray-600" />
            <span>
              {{
                $t('CHAT_CONVERS_NOTIF_TURN_OFF') || 'Turn off notifications'
              }}
            </span>
          </button>
          <button
            v-if="canLeaveConversation"
            class="
              px-4
              py-2
              w-full
              hover:bg-gray-100
              flex
              items-center
              space-x-4
              dropdown-item
            "
            @click="onLeaveConversationClick"
          >
            <SynIcon name="Logout" />
            <span>
              {{ $t('CHAT_LEAVE') || 'Leave conversation' }}
            </span>
          </button>
          <ConversationDelete :conversation="conversation">
            <button
              class="
                px-4
                py-2
                w-full
                hover:bg-gray-100
                flex
                items-center
                space-x-4
                dropdown-item
                text-red-500
              "
            >
              <SynIcon name="Trash" class="fill-red-500" />
              <span>{{ $t('CHAT_DELETE') }}</span>
            </button>
          </ConversationDelete>
        </div>
      </template>
    </VigDropdown>
  </div>
</template>

<style scoped></style>
