<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import useSynTableCustom from '@/ui/common/molecules/SynTableCustom/use-syn-table-custom';
// import WorkflowStepsPreviewModal from '@/ui/modules/workflow/workflow-step/WorkflowStepsPreviewModal.vue';
import WorkflowEntity, {
  IWorkflowEntity,
} from '@/domain/entities/workflow/WorkflowEntity';
import workflowStore from '@/store/workflow';
import WorkflowAddNewModal from '@/ui/modules/workflow/workflow-detail/WorkflowAddNewModal.vue';

import WorkflowDetailModal from '@/ui/modules/workflow/workflow-detail/WorkflowDetailModal.vue';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import WorkflowActions from '@/ui/modules/workflow/components/WorkflowActions.vue';
import { TWorkflowActionType } from '@/application/types/workflow/workflow.types';

const props = withDefaults(
  defineProps<{
    actionList?: TWorkflowActionType[];
    filterObject?: {
      departmentId?: number;
      domainId?: number;
      groupId?: number;
      searchText?: string;
      isActive: boolean | null;
    };
    isCustomOpenDetail?: boolean;
    isApplyToTaskScreen?: boolean;
    viewType?: 'TABLE' | 'LIST';
    filterActiveOnly?: boolean;
  }>(),
  {
    filterObject: () => {
      return {
        searchText: '',
        isActive: null,
      };
    },
    actionList: () => [],
    viewType: 'TABLE',
  }
);
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'choose', data: any, viewMode?: any): void;
  (e: 'duplicate', step: any): void;
  (e: 'reload'): void;
  (e: 'onReady', data: any): void;
  (e: 'preview', data: any): void;
  (e: 'updateSuccess', data: any): void;
  (e: 'removeSuccess', data: any): void;
}>();

const _workflowStore = workflowStore();
const isLoadingList = ref<boolean>(false);

const workflowList = ref<IWorkflowEntity[]>([]);
const initComponent = async () => {
  isLoadingList.value = true;

  let allWorkflows;
  if (props.isApplyToTaskScreen) {
    const res = await WorkflowRepository.getInstance().getWorkflowsWithFilters(
      {}
    );
    allWorkflows = res?.result;
    workflowList.value = res?.result;
  } else {
    const result = await _workflowStore.getWorkflows();
    allWorkflows = result;
    workflowList.value = result?.filter((o) => o.versionLatest);
  }

  let filteredData: IWorkflowEntity[] = allWorkflows;

  if (props.filterObject?.departmentId) {
    filteredData = filteredData?.filter(
      (wf) =>
        !wf.departmentId || wf.departmentId == props.filterObject?.departmentId
    );
  }
  if (props.filterObject?.domainId) {
    filteredData = filteredData?.filter(
      (wf) => !wf.domainId || wf.domainId == props.filterObject?.domainId
    );
  }
  if (props.filterObject?.groupId) {
    filteredData = filteredData?.filter(
      (wf) => !wf.groupId || wf.groupId == props.filterObject?.groupId
    );
  }
  if (props.filterActiveOnly) {
    filteredData = filteredData?.filter((wf) => wf.isActive);
  }

  initComposable(filteredData);

  emit('onReady', allWorkflows);
  isLoadingList.value = false;
};

initComponent();

const onLoadMore = () => {};
const {
  initComposable,
  showingDataList,
  sortDataOrderByKey,
  genSortDataList,
  setFilterDataByKey,
  onFilterByKey,
  refreshComposable,
} = useSynTableCustom();

watch(
  () => props.filterObject?.searchText,
  () => {
    setFilterDataByKey('keySearch', props.filterObject.searchText);
    onFilterByKey();
  }
);
watch(
  () => props.filterObject?.isActive,
  () => {
    setFilterDataByKey('isActive', props.filterObject?.isActive);
    onFilterByKey();
  }
);
const onClickSort = (key, name, orderFunction) => {
  genSortDataList({ key, name, orderFunction });
};

const reload = () => {
  initComponent();
  emit('reload');
};

const canLoadMore = computed<boolean>(() => {
  return false;
});

const hasJustCreate = (data) => {
  const createdTime = new Date(data?.createdTime).getTime();

  return Date.now() - createdTime < 3 * 60 * 60 * 1000;
};

const isOpenPreviewModal = ref<boolean>(false);
const viewDetailMode = ref<'EDIT_STEP' | 'VIEW'>('VIEW');
const onClickDetail = (workflow) => {
  if (props.isApplyToTaskScreen) {
    emit('choose', workflow, 'CHOOSE');
    return;
  }

  if (props.isCustomOpenDetail) {
    emit('choose', workflow, 'VIEW');
    return;
  }

  onOpenDetailModal(workflow, 'VIEW');
};
const onOpenDetailModal = (workflow, viewMode?) => {
  currentWorkflow.value = workflow;
  viewDetailMode.value = viewMode || 'VIEW';

  isOpenPreviewModal.value = true;
};

const refresh = () => {
  refreshComposable(workflowList.value);
};

const currentWorkflow = ref<IWorkflowEntity | undefined>(undefined);
const isOpenNewWorkflow = ref<boolean>(false);
const addMode = ref<'ADD' | 'CLONE'>('ADD');
const onDuplicateWorkflow = (workflow) => {
  addMode.value = 'CLONE';
  currentWorkflow.value = {
    ...workflow,
    name: _workflowStore.generateWorkflowName(workflow?.name),
  };
  isOpenNewWorkflow.value = true;
};

const onCreateSuccess = (newData) => {
  isOpenNewWorkflow.value = false;
  workflowList.value = [newData, ...workflowList.value];
  refresh();
};

const updateWorkflowSuccess = (newData) => {
  const index = workflowList.value?.findIndex(
    (wf) => wf?.id == currentWorkflow.value?.id
  );
  if (index > -1) {
    workflowList.value[index] = newData;
    refresh();
  }

  isOpenPreviewModal.value = false;
};

const onChoosePreviewWorkflow = () => {
  emit('choose', currentWorkflow.value, 'CHOOSE');
  isOpenPreviewModal.value = false;
};

const onRemove = async (workflow) => {
  const res = await new WorkflowEntity(workflow).remove();

  if (!res) return;

  workflowList.value = workflowList.value?.filter(
    (s) => s?.id !== workflow?.id
  );
  refresh();

  emit('removeSuccess', workflow);
};
const onActive = async (workflow) => {
  await new WorkflowEntity(workflow).toggleActive();

  const index = workflowList.value?.findIndex((s) => s?.id == workflow?.id);

  workflowList.value[index].isActive = !workflow?.isActive;
  refresh();

  emit('updateSuccess', workflow);
};

const onActionOnWorkflow = (actionType: TWorkflowActionType, workflow) => {
  switch (actionType) {
    case 'PREVIEW':
      onOpenDetailModal(workflow);
      break;
    case 'DUPLICATE':
      onDuplicateWorkflow(workflow);
      break;
    case 'EDIT':
      onOpenDetailModal(workflow, 'EDIT_STEP');
      break;
    case 'REMOVE':
      onRemove(workflow);
      break;
    case 'CHOOSE':
      emit('choose', workflow, 'CHOOSE');
      break;
    case 'ACTIVE':
      onActive(workflow);
      break;

    default:
      break;
  }
};

defineExpose({
  refresh,
  initComponent,
});
</script>
<template>
  <div
    v-if="viewType == 'TABLE'"
    v-scroll-infinite="onLoadMore"
    v-bind="$attrs"
    class="h-full overflow-auto relative small-scrollbar"
  >
    <SynTableCustom class="rounded-none">
      <template #header>
        <SynTr class="rounded-none">
          <!--   CODE       -->
          <SynTh
            :label="$t('COMMON_LABEL_CODE')"
            is-sort
            style="width: 2rem"
            :order-data="sortDataOrderByKey['code']"
            @on-sort="onClickSort('code', $t('COMMON_LABEL_CODE'), 'code')"
          />
          <!--   NAME       -->
          <SynTh
            :label="$t('COMMON_LABEL_NAME')"
            is-sort
            style="width: 0; min-width: 20rem"
            :order-data="sortDataOrderByKey['name']"
            @on-sort="onClickSort('name', $t('TASK_TABLE_LABEL_TASKS'), 'name')"
          />

          <!--   total step       -->
          <SynTh
            :label="$t('WORKFLOW_LABEL_NUM_OF_STEPS')"
            is-sort
            position-text-header="justify-center"
            :order-data="sortDataOrderByKey['TOTAL_STEP']"
            @on-sort="
              onClickSort(
                'TOTAL_STEP',
                $t('WORKFLOW_LABEL_NUM_OF_STEPS'),
                (workflow) => {
                  return workflow?.totalStep;
                }
              )
            "
          />

          <!--   total task in use       -->
          <SynTh
            :label="$t('WORKFLOW_LABEl_TOTAL_TASK_IN_USE')"
            is-sort
            position-text-header="justify-center"
            :order-data="sortDataOrderByKey['TOTAL_TASKS']"
            @on-sort="
              onClickSort(
                'TOTAL_TASKS',
                $t('WORKFLOW_LABEl_TOTAL_TASK_IN_USE'),
                (workflow) => {
                  return workflow?.totalTasks;
                }
              )
            "
          />

          <!-- CREATE DATE -->
          <SynTh
            :label="$t('TASK_TABLE_LABEL_CREATED_DATE')"
            position-text-header="justify-center"
            is-sort
            :order-data="sortDataOrderByKey['creationTime']"
            @on-sort="
              onClickSort(
                'creationTime',
                $t('TASK_TABLE_LABEL_CREATED_DATE'),
                (workflow) => {
                  return workflow?.creationTime;
                }
              )
            "
          />
          <!-- CREATE BY -->
          <SynTh
            :label="$t('TASK_TABLE_LABEL_REPORTER')"
            position-text-header="justify-center"
            is-sort
            :order-data="sortDataOrderByKey['creatorId']"
            @on-sort="
              onClickSort(
                'creatorId',
                $t('TASK_TABLE_LABEL_REPORTER'),
                (workflow) => {
                  return workflow?.creatorId;
                }
              )
            "
          />

          <!--   RELOAD       -->
          <SynTh is-center class="w-40">
            <div class="flex-1 flex items-center justify-end">
              <SynIcon
                class="
                  text-gray-600
                  fill-gray-500
                  cursor-pointer
                  hover:fill-current
                "
                name="reload"
                :title="$t('TASK_LIST_LABEL_RELOAD') || 'Reload'"
                @click="reload"
              />
            </div>
          </SynTh>
        </SynTr>
      </template>
      <template #body>
        <syn-table-tr-loading v-if="isLoadingList" class="h-full">
          <div v-for="item in 6" :key="item" class="table-cell p-2">
            <div class="p-2 w-full bg-gray-200 rounded-md"></div>
          </div>
        </syn-table-tr-loading>
        <template v-else-if="showingDataList?.length > 0">
          <SynTr
            v-for="workflow in showingDataList"
            :key="workflow?.id"
            class="
              hover:bg-gray-50
              relative
              hover-to-show__parent
              hover-to-hidden__parent
            "
          >
            <SynTd class="max-w-md relative" style="padding: 0">
              <div class="flex-center w-full">
                <div
                  class="
                    flex-1
                    px-2
                    py-1
                    overflow-hidden
                    w-full
                    h-full
                    text-overflow-hidden
                  "
                  @click.stop.prevent="onClickDetail(workflow)"
                >
                  <div
                    class="flex flex-wrap flex-col space-y-1 break-words w-full"
                  >
                    <span
                      :title="workflow?.code"
                      class="
                        w-full
                        text-xs
                        py-1
                        hover:text-current-500
                        cursor-pointer
                      "
                      :class="workflow?.isActive ? '' : 'text-gray-400'"
                    >
                      {{ workflow?.code }}
                    </span>
                  </div>
                </div>
              </div>
            </SynTd>
            <SynTd class="max-w-md relative" style="padding: 0">
              <div
                class="absolute top-0 right-0 flex-center space-x-1"
                style="font-size: 0.6rem"
              >
                <span
                  v-if="hasJustCreate(workflow)"
                  class="bg-current text-white px-1.5 rounded-b-md z-10"
                  >New</span
                >
              </div>

              <div class="flex-center w-full">
                <div
                  class="
                    flex-1
                    px-2
                    py-1
                    overflow-hidden
                    w-full
                    h-full
                    text-overflow-hidden
                  "
                  @click.stop.prevent="onClickDetail(workflow)"
                >
                  <div
                    class="flex flex-wrap flex-col space-y-1 break-words w-full"
                  >
                    <span
                      :title="workflow?.name"
                      class="
                        py-1
                        hover:text-current-500
                        cursor-pointer
                        font-semibold
                      "
                      :class="workflow?.isActive ? '' : 'text-gray-400'"
                    >
                      {{ workflow?.name }}
                    </span>
                  </div>
                </div>
              </div>
            </SynTd>
            <SynTd class="text-center">
              <span :class="workflow?.isActive ? '' : 'text-gray-400'">
                {{ workflow?.totalSteps }}
              </span>
            </SynTd>
            <SynTd class="text-center">
              <span :class="workflow?.isActive ? '' : 'text-gray-400'">
                {{ workflow?.totalTasks }}
              </span>
            </SynTd>
            <SynTd class="text-left whitespace-nowrap">
              <div class="flex flex-col items-end text-xs">
                <SynLabelDateTime
                  format="date"
                  :datetime="workflow.creationTime"
                  :class="workflow?.isActive ? '' : 'text-gray-400'"
                />
              </div>
            </SynTd>
            <SynTd class="text-center">
              <div v-if="workflow.creatorId" class="flex-center">
                <UserByAction
                  :user-id="workflow.creatorId"
                  is-hidden-name
                  is-show-action-user
                  avatar-class="w-6 h-6"
                />
              </div>
            </SynTd>

            <SynTd>
              <WorkflowActions
                :workflow="workflow"
                :action-list="actionList"
                @action="
                  (actionType) => onActionOnWorkflow(actionType, workflow)
                "
              />
            </SynTd>
          </SynTr>
          <slot name="footer"></slot>
        </template>
        <template v-else>
          <slot name="no-data">
            <syn-td :colspan="6">
              <div class="w-full flex-center py-8">
                <span class="italic">
                  {{ $t('WORKFLOW_LABEl_HAVE_NOT_WORKFLOW') }}
                </span>
              </div>
            </syn-td>
          </slot>
        </template>
      </template>
    </SynTableCustom>
    <div v-if="canLoadMore" class="flex-center w-full p-2">
      <button
        class="px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300 text-sm"
        @click="onLoadMore"
      >
        {{ $t('GED_SHOW_MORE') || 'Show more' }}
      </button>
    </div>
  </div>
  <div
    v-else
    class="
      py-1
      z-100
      rounded
      cursor-pointer
      overflow-auto
      relative
      small-scrollbar
      max-h-[40rem]
      px-2
    "
    style="min-width: 11rem"
  >
    <div
      v-for="workflow in showingDataList"
      :key="workflow?.id"
      class="
        flex
        justify-between
        items-center
        h-9
        hover:bg-gray-50
        cursor-pointer
        px-2
        hover-to-show__parent
        relative
      "
      @click="$emit('choose', workflow, 'CHOOSE')"
    >
      <span class="text-gray-600 text-sm truncate">
        {{ workflow?.name }}
      </span>
      <WorkflowActions
        :action-list="actionList"
        @action="(actionType) => onActionOnWorkflow(actionType, workflow)"
      />
    </div>
  </div>

  <WorkflowAddNewModal
    v-if="isOpenNewWorkflow"
    :view-mode="addMode"
    :default-data="currentWorkflow"
    @cancel="isOpenNewWorkflow = false"
    @create-success="onCreateSuccess"
  />

  <WorkflowDetailModal
    v-if="isOpenPreviewModal && currentWorkflow?.id"
    :workflow-info="currentWorkflow"
    :view-mode="viewDetailMode || 'VIEW'"
    :action-list="actionList"
    @cancel="isOpenPreviewModal = false"
    @update-success="updateWorkflowSuccess"
    @choose="onChoosePreviewWorkflow"
  />
</template>
