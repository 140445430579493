<script setup lang="ts">
import { ITaskTodoItemStatus } from '@/application/types/task/task.types';
import { ref } from 'vue';
import CkSimpleInput from '@/ui/common/atoms/checklist/CkSimpleInput.vue';
import { isValidTaskCode } from '@/ui/hooks/taskHook';
import InformationPopupFromLink from '@/ui/components/popup/InformationPopupFromLink.vue';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';

defineProps<{
  title: string;
  dataItem?: any;
  textareaId: string;
  readonly?: boolean;
  editTitleOnly?: boolean;
  placeholder?: string;
  canCopy?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onChangeStatus', value: string): void;
  (e: 'update:title', value: string): void;
  (e: 'change', value: string): void;
  (e: 'remove'): void;
  (e: 'back'): void;
  (e: 'next'): void;
  (e: 'add', value: string): void;
  (e: 'delete', value: string): void;
  (e: 'deleteAfter'): void;
  (e: 'blur', value: string): void;
  (e: 'copy'): void;
}>();

const onUpdateModelValue = (currentValue) => {
  emit('update:title', currentValue);
};
const onBlur = (event) => {
  console.log('🚀 Tictop ~ event:', event);
  setTimeout(() => {
    emit('blur', event?.target?.value);
  });
  if (timeoutFocus) clearTimeout(timeoutFocus);
  isFocusing.value = false;
};

const onEnter = (data) => {
  emit('update:title', data?.currentValue || '');
  emit('add', data?.trimmedValue || '');
  if (timeoutFocus) clearTimeout(timeoutFocus);
};
const onBackspace = (currentValue) => {
  if (timeoutFocus) clearTimeout(timeoutFocus);
  isFocusing.value = false;
  emit('delete', currentValue);
};

const ckEditorSimpleInputRef = ref<any>(null);

let timeoutFocus;
const focusToStart = () => {
  isFocusing.value = true;
  if (timeoutFocus) clearTimeout(timeoutFocus);
  timeoutFocus = setTimeout(() => {
    if (
      ckEditorSimpleInputRef.value &&
      typeof ckEditorSimpleInputRef.value?.focusToStart == 'function'
    )
      ckEditorSimpleInputRef.value?.focusToStart();
    isFocusing.value = true;
  });
};
const focusToEnd = (insertText) => {
  isFocusing.value = true;
  if (timeoutFocus) clearTimeout(timeoutFocus);
  timeoutFocus = setTimeout(() => {
    if (
      ckEditorSimpleInputRef.value &&
      typeof ckEditorSimpleInputRef.value?.focusToEnd == 'function'
    )
      ckEditorSimpleInputRef.value?.focusToEnd();

    if (insertText && typeof insertText == 'string') insertToEnd(insertText);
  });
};
const insertToEnd = (value) => {
  if (
    ckEditorSimpleInputRef.value &&
    typeof ckEditorSimpleInputRef.value?.focusToEnd == 'function'
  )
    ckEditorSimpleInputRef.value?.insertToEnd(value);
};

const isFocusing = ref<boolean>(false);

const informationPopupRef = ref<any>(null);

const onHoverTextMessage = async (event) => {
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.onHoverTextMessage == 'function'
  )
    informationPopupRef.value?.onHoverTextMessage(event);
};

const onMouseLeaveTextMessage = (event) => {
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.onMouseLeaveTextMessage == 'function'
  )
    informationPopupRef.value?.onMouseLeaveTextMessage(event);
};

const onClickText = async (event) => {
  if (!isFocusing.value) focusToEnd('');

  const href = event?.target?.href;

  if (!href || !href?.includes('/tasks/')) return;

  const taskCode = href.split('/').pop();

  if (!isValidTaskCode(taskCode)) return;

  event.preventDefault();

  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.destroyCard == 'function'
  )
    informationPopupRef.value?.destroyCard();
};

defineExpose({
  focusToStart,
  focusToEnd,
  insertToEnd,
});
</script>
<template>
  <div
    v-bind="$attrs"
    class="
      w-full
      flex-center
      h-auto
      relative
      atom-checklist-input
      todo-list-item
    "
    :class="{ 'todo-list-item-readonly': readonly }"
    style="min-height: 2.5rem"
    @click="onClickText"
    @mouseover="isFocusing = true"
  >
    <div
      class="flex-1 min-w-0 w-full flex items-start relative hover:bg-gray-100"
    >
      <div class="absolute z-10 left-0 flex-center rounded-l">
        <div v-if="!readonly" class="flex-center" style="width: 1rem">
          <span class="flex-center todo-list-item_hover-to-show">
            <SynIcon
              v-if="!editTitleOnly"
              name="dragable"
              class="fill-gray-500"
            />
            <div v-else class="h-4"></div>
          </span>
        </div>
        <slot name="prefix-icon">
          <div
            class="h-10 w-10 flex-center group"
            :class="{ 'pointer-events-none': readonly }"
            @click="$emit('onChangeStatus')"
          >
            <div
              class="h-4 w-4 flex-center rounded border ring-current-300"
              :class="{
                'bg-current-300':
                  dataItem?.status === ITaskTodoItemStatus.FINISHED &&
                  !readonly,
                'bg-gray-400':
                  dataItem?.status === ITaskTodoItemStatus.FINISHED && readonly,
                'cursor-pointer group-hover:ring-1 border-current-300':
                  !readonly,
              }"
            >
              <SynIcon
                v-if="dataItem?.status === ITaskTodoItemStatus.FINISHED"
                name="check"
                class="text-white"
              />
            </div>
          </div>
        </slot>
      </div>
      <div
        v-if="readonly"
        class="flex-1"
        style="
          padding-left: 3.5rem;
          padding-right: 0.75rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        "
        v-html="title"
      ></div>
      <div
        v-else-if="!isFocusing && title"
        class="flex-1"
        style="
          padding-left: 3.5rem;
          padding-right: 0.75rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        "
        @click="onClickText"
        @mouseover="onHoverTextMessage"
        @mouseleave="onMouseLeaveTextMessage"
        v-html="title"
      ></div>
      <div
        v-show="isFocusing || !title"
        :class="isFocusing || !title ? 'flex-1 min-w-0 ' : 'absolute'"
      >
        <CkSimpleInput
          ref="ckEditorSimpleInputRef"
          :model-value="title"
          class="bg-transparent"
          @update:model-value="onUpdateModelValue"
          @on-enter="onEnter"
          @blur="onBlur"
          @delete-after="[(isFocusing = false), $emit('deleteAfter')]"
          @back="[(isFocusing = false), $emit('back')]"
          @next="[(isFocusing = false), $emit('next')]"
          @focus="isFocusing = true"
          @backspace="onBackspace"
        />
      </div>
      <div class="flex items-center todo-list-item_hover-to-show">
        <div
          v-if="canCopy"
          v-vig-tooltip="$t('COMMON_LABEL_COPY_TEXT') || 'Copy text'"
          class="
            flex-center
            h-9
            w-9
            cursor-pointer
            rounded-full
            hover:bg-current-50
          "
          @click="$emit('copy')"
        >
          <SynIcon name="duplicate" :custom-class="'w-4 h-4 fill-gray-500'" />
        </div>
        <div
          v-if="!readonly && !editTitleOnly"
          class="
            flex-center
            h-9
            w-9
            cursor-pointer
            rounded-full
            hover:bg-current-50
          "
          @click="$emit('remove')"
        >
          <SynIcon
            name="close-bold"
            :custom-class="'w-4 h-4 fill-gray-500'"
            size="small"
          />
        </div>
      </div>
    </div>
  </div>
  <InformationPopupFromLink ref="informationPopupRef" />
</template>
<style>
.atom-checklist-input {
  .ck.ck-content {
    max-height: max-content;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    background: transparent;
  }
}
.ck.ck-editor__editable_inline {
  @apply px-3 py-2 leading-5 w-full border-current-300;
}

.ck.ck-editor__editable_inline > :first-child {
  @apply mt-0;
}

.ck.ck-editor__editable_inline > :last-child {
  @apply mb-0;
}

.ck.ck-editor__editable {
  padding-left: 3.5rem;
  cursor: text;
}
.ck.ck-editor__editable,
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  @apply border-0 ring-0;
}

.ck.ck-toolbar {
  @apply border-0;
}

.ck.ck-content {
  @apply max-h-52;
}

.ck.ck-content a {
  @apply text-current-500;
}
.atom-checklist-input a {
  @apply text-current-500;
}

.ck-content .mention {
  @apply bg-blue-50 text-current-500;
}

.ck .ck-link_selected {
  background: none;
}

.ck.ck-balloon-panel {
  @apply shadow-md rounded-md border-none;
}

.actions-collapsed {
  .ck.ck-editor__editable_inline {
    padding-right: 35px;
  }
}

.todo-list-item_hover-to-show {
  visibility: hidden;
}

.todo-list-item:hover .todo-list-item_hover-to-show {
  visibility: unset;
}
</style>
