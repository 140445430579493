<script setup lang="ts">
import { ref, onMounted } from 'vue';
// import { clearDoubleSpaces } from '@/ui/hooks/commonFunction';

const props = withDefaults(
  defineProps<{
    modelValue: string | null;
    inputId?: string;
    placeholder?: string;
    isDisabled?: boolean;
    inputType?: 'text' | 'password' | 'date' | 'email';
    iconPrefix?: string;
    iconRight?: string;
    errorMessage?: string;
    name?: string;
    autofocus?: boolean;
    style?: string;
    required?: boolean;
    customClass?: string;
    checkInvalid?: any;
    maxLength?: number | string;
    isHiddenMaxLength?: boolean;
  }>(),
  {
    placeholder: 'Type something...',
    inputType: 'text',
    isHiddenMaxLength: false,
  }
);

const emit = defineEmits([
  'blur',
  'focus',
  'enter',
  'update:modelValue',
  'onAction',
  'mouseleave',
  'keydown',
  'change',
]);

onMounted(() => {
  if (props.autofocus)
    setTimeout(() => {
      inputRef.value?.focus();
    }, 50);
});

let timeout;
const onChange = (event) => {
  // const value = clearDoubleSpaces(event.target.value);
  const value = event.target.value;

  emit('update:modelValue', value);
  emit('change', event);

  if (timeout) clearTimeout(timeout);
  if (!value || typeof props.checkInvalid !== 'function') return;
  timeout = setTimeout(async () => {
    props.checkInvalid(value);
  }, 750);
};

const inputRef = ref(null as any);
const focus = () => {
  inputRef.value?.focus();
};
defineExpose({
  focus,
});
</script>

<template>
  <div class="flex-center flex-col relative w-full rounded-md">
    <div
      v-if="iconPrefix"
      class="
        absolute
        inset-y-0
        left-4
        flex
        items-center
        h-full
        focus:outline-none focus:shadow-outline
      "
    >
      <slot name="prefix">
        <SynIcon
          :name="iconPrefix"
          :class="{
            'fill-gray-500': !errorMessage,
            'fill-red-500 text-red-500': errorMessage,
          }"
        />
      </slot>
    </div>
    <input
      :id="inputId"
      ref="inputRef"
      :name="name"
      autocomplete="off"
      class="
        vig-input
        h-full
        text-black
        font-normal
        px-4
        placeholder-gray-500 placeholder-opacity-75
      "
      :class="[
        errorMessage ? 'vig-input-error' : '',
        iconPrefix ? 'pl-12' : '',
        maxLength && !isHiddenMaxLength ? 'pr-14' : '',
        isDisabled ? 'bg-gray-100' : 'bg-white',
        customClass,
      ]"
      :placeholder="placeholder"
      :value="modelValue"
      :type="inputType"
      :style="`${style}`"
      :required="required"
      :disabled="isDisabled"
      :maxlength="maxLength"
      @input="onChange"
      @keyup.enter.prevent="$emit('enter')"
      @focus="$emit('focus')"
      @blur="$emit('blur', $event)"
      @mouseleave="$emit('mouseleave')"
      @keydown="$emit('keydown')"
      @change="(event) => $emit('change', event)"
    />

    <div
      v-if="maxLength && !isHiddenMaxLength"
      class="
        text-gray-500
        absolute
        inset-y-0
        right-0
        flex
        items-center
        text-xs
        px-2
        mt-1
      "
    >
      <span>{{ modelValue?.length || 0 }}/{{ maxLength }}</span>
    </div>
    <div
      class="
        absolute
        inset-y-0
        right-2
        flex
        items-center
        h-full
        focus:outline-none focus:shadow-outline
      "
    >
      <slot name="suffix"></slot>
    </div>

    <div
      v-if="iconRight"
      class="
        absolute
        cursor-pointer
        inset-y-0
        right-4
        flex
        items-center
        h-full
        focus:outline-none focus:shadow-outline
      "
      @click.stop="$emit('onAction')"
    >
      <SynIcon
        :name="iconRight"
        class="fill-gray-500"
        :class="{ 'fill-red-500 text-red-500': errorMessage }"
      />
    </div>
  </div>
</template>
