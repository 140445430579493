<script setup lang="ts">
import { ref, onMounted } from 'vue';

const props = defineProps<{
  group: any;
  confirmText: string;
  isLoading: boolean;
}>();

const emit = defineEmits(['confirm-text', 'on-close', 'on-confirm']);

const confirmationText = ref('');
onMounted(() => {});

const onInputConfirmationText = () => {
  //emit('confirm-text', confirmationText.value);
};

const onClose = () => {
  emit('on-close');
};

const onConfirm = () => {
  if (confirmationText.value === props.confirmText) emit('on-confirm');
  else emit('on-close');
};
</script>
<template>
  <SynModal
    :container-style="'max-width: 500px'"
    z-index="z-50"
    @cancel="onClose"
  >
    <template #header>
      <span class="text-black">
        {{ $t('GROUP_CREATE_TITLE_STATUS_MAKE_PRIVATE') }}
      </span>
    </template>
    <template #body>
      <div class="w-full">
        <div class="py-2 flex flex-col">
          <span>{{ $t('GROUP_UPDATE_MAKE_PRIVATE_WARNING') }}</span>
          <label
            v-html="
              $t('GROUP_UPDATE_MAKE_PRIVATE_CONFIRM_ACTION', {
                confirmText: `<b>${confirmText}</b>`,
              })
            "
          ></label>

          <div class="w-full pt-3">
            <SynInput
              v-model="confirmationText"
              class="fill-current"
              autofocus
              placeholder=""
              @update:model-value="onInputConfirmationText"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="p-2 flex justify-end items-center">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="onClose"
        />
        <div class="px-2 hover:text-red-600 cursor-pointer">
          <SynButton
            :disabled="!confirmationText || isLoading"
            :label="$t('GROUP_CREATE_TITLE_STATUS_MAKE_PRIVATE')"
            :is-loading="isLoading"
            type-fill
            color="red"
            @click="onConfirm"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
