<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import userStore from '@/store/user';
import { AdminType } from '@/ui/common/constants/constant';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

defineEmits(['onCancel']);
const allActiveUsers = computed(() => userStore().allActiveUsers);
const defaultOwnerInfo = ref<any>({});
const defaultAdminInfo = ref<any>({});

onMounted(() => {
  for (const user of allActiveUsers.value) {
    if (user?.adminType === AdminType.Owner) defaultOwnerInfo.value = user;
    if (user?.adminType === AdminType.Admin) defaultAdminInfo.value = user;
  }
});

const IMAGE_PATH = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/plan-state/no-permission-payment.png`;
</script>
<template>
  <MoleculeNotificationPage :image-url="IMAGE_PATH">
    <template #title>
      <span></span>
    </template>
    <template #content>
      <span
        v-html="
          $t('PLAN_NOTIFICATION_MESSAGE_NO_PERMISSION_PAYMENT', {
            ownerName: defaultOwnerInfo?.name || '',
            adminName: defaultAdminInfo?.name || '',
          })
        "
      >
      </span>
    </template>

    <template #action>
      <span></span>
    </template>
  </MoleculeNotificationPage>
</template>
