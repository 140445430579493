<script setup lang="ts">
import { computed, inject } from 'vue';
import PROVIDER_IDS, {
  TDesktopDrawer,
} from '@/application/constants/system/provider-ids.const';
import { Box2dModel } from '../shapes/common/Box2d';
import { toDomPrecision } from '@/ui/components/drawer/shapes/common/utils';

const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);

const brush = computed<Box2dModel | null>(() => {
  return desktopDrawerInjected?.desktopDrawerInstance?.value.brush;
});
</script>
<template>
  <svg
    v-if="brush"
    class="tl-overlays__item"
    :style="{
      transform: `matrix(1, 0, 0, 1, ${brush?.x}, ${brush?.y})`,
    }"
  >
    <rect
      class="tl-brush tl-brush__default"
      :width="toDomPrecision(Math.max(1, brush?.w))"
      :height="toDomPrecision(Math.max(1, brush?.h))"
    />
  </svg>
</template>
