import { defineStore } from 'pinia';
import TaskService from '@/application/services/task/TaskService';
import GroupService from '@/application/services/GroupService';
import storeId from '@/base/store/storeId';
import {
    ScheduleDayOptions,
    ScheduleOtherTimeOptions,
} from '@/domain/enums/taskEnum';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
    IDeleteTaskUserRolePayload,
    TaskUserRole,
} from '@/application/types/task/task.types';
import { ITaskConfigs } from '@/application/types/task/task-configs.types';
import permissionStore from '@/store/permission';
import { ITaskReminderInput } from '@/application/interfaces/tasks/task-interfaces';
import datetimeComposable from '@/ui/composables/common/datetime-composable';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import dayjs from 'dayjs';

export default defineStore({
    id: storeId.task,
    state: () => ({
        dataConfigs: {} as ITaskConfigs,
        _taskService: TaskService.getInstance(),
        _groupService: GroupService.getInstance(),
        currentCycle: { currentCycle: 1, letter: '', code: 1 } as {
            currentCycle: number;
            letter: string;
            code: number;
        },
        currentTaskCycle: { currentCycle: 1, letter: '', code: 1 } as {
            currentCycle: number;
            letter: string;
            code: number;
        },
        userDayDeadlineInfo: [] as any[],
        rolePrincipalId: '' as string,
        roleCollaboratorId: '' as string,
        taskUserRoles: [] as TaskUserRole[],
        taskUserRoleByIds: {} as any,
        allTaskByIds: {} as any,
        taskConfigs: {} as { maxTodoListItems: number },
    }),
    getters: {},
    actions: {
        getDataConfig() {
            return this.getConfig();
        },

        async getConfig() {
            const res: any = await this._taskService.getDataConfig();

            const allSystemConfigs = permissionStore().allSystemConfigs;
            const settingMaxTodoListValue =
                Object.prototype.hasOwnProperty.call(
                    allSystemConfigs,
                    'ADVANCED_EXTENDED_CHECKLIST_PROPERTIES'
                )
                    ? allSystemConfigs['ADVANCED_EXTENDED_CHECKLIST_PROPERTIES']
                    : 2;
            const mapDataChecklist = {
                1: 1,
                2: 5,
                3: 10,
                4: 100,
            };

            this.dataConfigs = {
                ...res?.result,
                checkList: {
                    maxTotalItems: mapDataChecklist[settingMaxTodoListValue],
                },
            };
        },
        // async getTaskRole() {
        //   const res: any = await this._taskService.getAllTaskUserRole();

        //   this.taskUserRoles = res?.result || [];
        //   this.taskUserRoleByIds = this.taskUserRoles?.reduce(
        //     (oldObj, currentItem) => {
        //       return {
        //         ...oldObj,
        //         [currentItem?.id]: currentItem,
        //       };
        //     },
        //     {}
        //   );
        //   console.log(
        //     '🚀 Hyrin ~ getTaskRole ~ this.taskUserRoleByIds:',
        //     this.taskUserRoleByIds
        //   );
        //   this.rolePrincipalId =
        //     this.taskUserRoles.find((role) => role?.code === 'PRINCIPLE')?.id || '';
        //   this.roleCollaboratorId =
        //     this.taskUserRoles.find((role) => role?.code === 'COLLABORATOR')?.id ||
        //     '';
        // },
        fetchTaskDefaultByUserId(userId) {
            return this._taskService.getTaskWithFilter({
                listOwnerId: userId,
                isDefault: true,
            });
        },
        fetchGroupTaskDefault(groupId) {
            return this._taskService.getTaskWithFilter({
                groupIds: `${groupId}`,
                isDefault: true,
            });
        },

        async getUserTaskSchedule(userId) {
            const res = await this._taskService.getUserTaskSchedule(userId);
            this.userDayDeadlineInfo = res['result'];
            return res['result'];
        },

        async getCurrentTaskCycle(organizationId?) {
            const res = await this._taskService.getCurrentTaskCycle(
                organizationId
            );

            this.currentCycle = {
                currentCycle: Math.max(1, res?.result?.currentCycle),
                letter: res?.result?.letter?.trim() || 'A',
                code: res?.result?.code || 1,
            };
        },
        async getCurrentTaskCycleForChat(organizationId?) {
            // using when chat, and maybe chat with other organization

            const res = await this._taskService.getCurrentTaskCycle(
                organizationId
            );
            this.currentTaskCycle = {
                currentCycle: Math.max(1, res?.result?.currentCycle),
                letter: res?.result?.letter?.trim() || 'A',
                code: res?.result?.code || 1,
            };
        },
        getConfigName(sitKey, typeKey) {
            let sitName = '';
            if (
                this.dataConfigs &&
                this.dataConfigs[typeKey] &&
                this.dataConfigs[typeKey].length > 0
            ) {
                const index = this.dataConfigs[typeKey].findIndex(
                    (o) => o.key == sitKey
                );
                if (index > -1) sitName = this.dataConfigs[typeKey][index].text;
            }
            return sitName;
        },
        getConfigScheduleName(task: ITaskDetailClass) {
            const taskSchedule = task?.taskSchedule;
            const scheduleTime = task?.scheduleTime;
            if (
                taskSchedule?.scheduleOptionKey == ScheduleDayOptions.OtherTime
            ) {
                if (
                    taskSchedule.otherTime ==
                    ScheduleOtherTimeOptions.SpecificDate
                ) {
                    // return taskSchedule.overdue > 0
                    //   ? translate('TASK_TABLE_LABEL_DAY_OVERDUE', {
                    //       overdue: taskSchedule.overdue,
                    //     })
                    //   : translate('TASK_TABLE_LABEL_DAY_LEFT', {
                    //       overdue: taskSchedule.overdue * -1,
                    //     });
                    // return taskSchedule.overdue >= 0
                    //   ? taskSchedule.overdue * -1 + 'd'
                    //   : '+' + taskSchedule.overdue * -1 + 'd';
                    // // return taskSchedule.overdue > 0
                    // //   ? '-' + taskSchedule.overdue + 'd'
                    // //   : '+' + taskSchedule.overdue + 'd';
                    const { formatDateTime, formatDate } = datetimeComposable();
                    let scheduleTimeValue: any =
                        scheduleTime || taskSchedule.scheduleDateValue;

                    scheduleTimeValue = scheduleTimeValue
                        ? dayjs.utc(scheduleTimeValue).toDate()
                        : null;

                    return scheduleTimeValue
                        ? task?.scheduleByHour
                            ? formatDateTime(scheduleTimeValue, 'date', {
                                  isIgnoreTimezone: false,
                                  timezone: task?.scheduleTimezone,
                              })
                            : formatDate(scheduleTimeValue, 'date', {
                                  isIgnoreTimezone: false,
                              })
                        : false;
                }
                return this.getConfigName(
                    taskSchedule.otherTime,
                    'scheduleOtherTimeOptions'
                );
            }
            if (taskSchedule?.scheduleOptionKey == ScheduleDayOptions.Nothing) {
                return (
                    translate('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule'
                );
            }
            return this.getConfigName(
                taskSchedule?.scheduleOptionKey,
                'scheduleDayOptions'
            );
        },

        async createOrUpdateTaskUserRole(data: TaskUserRole) {
            await this._taskService.createOrUpdateTaskUserRole(data);
            this.taskUserRoleByIds[data.id] = data;

            return data;
        },
        getTaskUserAffectedByRole() {
            return this._taskService.getTaskUserAffectedByRole();
        },
        async deleteTaskUserRole(data: IDeleteTaskUserRolePayload) {
            await this._taskService.deleteTaskUserRole(data);
            this.taskUserRoles = this.taskUserRoles?.filter(
                (role) => role?.id !== data?.id
            );

            delete this.taskUserRoleByIds[data?.id];
        },
        updateAllTaskByIds(taskId, taskDetail) {
            this.allTaskByIds = {
                ...this.allTaskByIds,
                [taskId]: {
                    ...this.allTaskByIds[taskId],
                    ...taskDetail,
                },
            };
        },
        mergeAllTaskByIds(taskByIds) {
            this.allTaskByIds = {
                ...this.allTaskByIds,
                ...taskByIds,
            };
        },

        async setTaskReminder(taskId, data: ITaskReminderInput) {
            return await this._taskService.setReminderTask(taskId, data);
        },

        async renewTaskReminder(eventId, expiredTime: number) {
            return await this._taskService.renewReminderTask(
                eventId,
                expiredTime
            );
        },

        async stopTaskReminder(eventId) {
            return await this._taskService.stopReminderTask(eventId);
        },

        async getReminderByTaskId(taskId) {
            return await this._taskService.getReminderByTaskId(taskId);
        },

        async removeReminderByTaskId(taskId) {
            return await this._taskService.removeReminderByTaskId(taskId);
        },

        async reminderActionFromWebPush(eventId) {
            return await this._taskService.reminderActionFromWebPush(eventId);
        },
    },
});
