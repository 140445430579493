<script setup lang="ts">
import { ref } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  convertUrgencyToImportant,
  convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';
import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import TaskWorkflowStepUpdateSingle from '@/ui/modules/task/workflow/step/TaskWorkflowStepUpdateSingle.vue';
import {
  TYPE_LIST_BY_IDS,
  EUpdateType,
} from '@/ui/modules/task/workflow/step/task-workflow-step-update-single.const';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
const props = defineProps<{
  isNotNullUpdateData?: boolean;
  workflowSteps: ITaskWorkflowStep[];
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'onSave', updatedStepList: any[]): void;
}>();

const currentType = ref<{
  id: EUpdateType;
  name: string;
}>({
  id: EUpdateType.RESPONSIBLE,
  name: translate('DOMAIN_LABEL_RESPONSIBLE'),
});

const updatedStepList = ref<ITaskWorkflowStep[]>([...props.workflowSteps]);

const taskWorkflowStepUpdateRef = ref<any>(null);

const isShowApplySuccess = ref<boolean>(false);
const onSave = async () => {
  const updatedData =
    taskWorkflowStepUpdateRef.value &&
    typeof taskWorkflowStepUpdateRef.value?.getData == 'function'
      ? taskWorkflowStepUpdateRef.value?.getData()
      : undefined;

  if (
    props.isNotNullUpdateData &&
    !updatedData?.hasValue &&
    (currentType.value.id == EUpdateType.RESPONSIBLE ||
      currentType.value.id == EUpdateType.DOMAIN)
  ) {
    settingGlobalModal({
      type: 'confirm',
      title: translate(
        'WORKFLOW_UPDATE_MULTIPLE_RESPONSIBLE_DOMAIN_REQUIRE_TITLE'
      ),
      content: translate('WORKFLOW_UPDATE_MULTIPLE_RESPONSIBLE_DOMAIN_REQUIRE'),
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
      closeable: false,
    });

    ask();
    return;
  }

  if (updatedData && selectedIdList.value?.length > 0) {
    updatedStepList.value.forEach((step) => {
      if (selectedIdList.value?.includes(step.id)) {
        switch (currentType.value.id) {
          case EUpdateType.RESPONSIBLE:
            {
              step.groupId = updatedData?.taskTemplate?.groupId;
              step.assigneeId = updatedData?.taskTemplate?.assigneeId;
              step.updateFields = [
                ...new Set([
                  ...(step.updateFields || []),
                  'groupId',
                  'assigneeId',
                ]),
              ];

              if (
                step.collaborators?.some((u) => u?.userId == step.assigneeId)
              ) {
                step.collaborators = step.collaborators?.filter(
                  (u) => u?.userId !== step?.assigneeId
                );
                step.updateFields = [
                  ...new Set([...(step.updateFields || []), 'collaborators']),
                ];
              }
            }
            break;
          case EUpdateType.COLLABORATORS:
            {
              step.collaborators = updatedData?.taskTemplate?.collaborators
                ? updatedData?.taskTemplate?.collaborators
                    ?.filter((u) => u?.memberUserId !== step?.assigneeId)
                    ?.map((u) => {
                      return {
                        userId: u?.memberUserId,
                        roleId: u?.memberRoleId,
                      };
                    })
                : [];

              step.updateFields = [
                ...new Set([...(step.updateFields || []), 'collaborators']),
              ];
            }
            break;
          case EUpdateType.DOMAIN:
            {
              step.domainId = updatedData?.taskTemplate?.domainId;

              step.updateFields = [
                ...new Set([...(step.updateFields || []), 'domainId']),
              ];
            }
            break;

          case EUpdateType.URGENCY:
            {
              step.priority =
                updatedData?.taskTemplate?.urgency &&
                updatedData?.taskTemplate?.urgency > 0
                  ? convertUrgencyToPriority(updatedData?.taskTemplate?.urgency)
                  : updatedData?.taskTemplate?.priority || 1;

              step.important =
                updatedData?.taskTemplate?.urgency &&
                updatedData?.taskTemplate?.urgency > 0
                  ? convertUrgencyToImportant(
                      updatedData?.taskTemplate?.urgency
                    )
                  : !!updatedData?.taskTemplate?.priority;

              step.updateFields = [
                ...new Set([
                  ...(step.updateFields || []),
                  'priority',
                  'important',
                ]),
              ];
            }
            break;
          case EUpdateType.DEADLINE:
            {
              step.scheduleDayOption =
                updatedData?.taskTemplate?.taskSchedule?.scheduleOptionKey;
              step.scheduleTime = undefined;

              step.updateFields = [
                ...new Set([
                  ...(step.updateFields || []),
                  'scheduleTime',
                  'scheduleDayOption',
                ]),
              ];
            }
            break;
          case EUpdateType.APPROVAL:
            {
              step.approvalEnabled = updatedData?.taskTemplate?.approvalEnabled;
              step.approvalStatus = updatedData?.taskTemplate?.approvalStatus;
              step.approvers = updatedData?.taskTemplate?.approvers;

              step.updateFields = [
                ...new Set([
                  ...(step.updateFields || []),
                  'approvalEnabled',
                  'approvalStatus',
                  'approvers',
                ]),
              ];
            }
            break;

          default:
            break;
        }
      }
    });
  }

  emit('onSave', [...updatedStepList.value]);

  showSuccessAndClearData();
};
const latestUpdateTime = ref<number>(Date.now());
const showSuccessAndClearData = () => {
  isShowApplySuccess.value = true;
  setTimeout(() => {
    isShowApplySuccess.value = false;
  }, 3 * 1000);

  latestUpdateTime.value = Date.now();
  selectedIdList.value = [];
};

const selectedIdList = ref<string[]>([]);

const onChangeType = async (newType) => {
  if (selectedIdList.value?.length > 0) {
    settingGlobalModal({
      type: 'confirm',
      title: translate('WORKFLOW_UNSAVED_CHANGES'),
      content: translate('WORKFLOW_UNSAVED_CHANGES_CONTENT'),
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_CONTINUE') || 'Confirm',
      closeable: true,
    });

    const confirmed = await ask();

    if (confirmed) {
      onSave();
    } else return;
  }
  currentType.value = newType;
};
</script>
<template>
  <div class="flex flex-col flex-1 min-h-0 w-full px-4 gap-4">
    <div class="flex-1 min-h-0 flex flex-col w-full gap-4 pt-2">
      <!-- SET VALUE -->
      <section class="w-full flex-center">
        <!-- CHOOSE TYPE -->
        <div class="w-max flex flex-wrap gap-2 px-4">
          <SynRadioTag
            v-for="item in Object.values(TYPE_LIST_BY_IDS)"
            :key="item.id"
            model-value="currentType"
            :value="item"
            radio-name="dayoff-create-form_reason"
            custom-class="rounded"
            custom-active-class="bg-current-50"
            custom-inactive-class="opacity-70"
            @update:model-value="onChangeType"
          >
            <div
              class="w-max rounded px-4 py-2 flex-center space-x-2 border"
              :class="{
                'bg-current-500 text-white font-semibold':
                  item.id == currentType?.id,
              }"
            >
              <span class="text-sm w-max">
                {{ $t(item.nameCode) }}
              </span>
            </div>
          </SynRadioTag>
        </div>
      </section>

      <!-- CHOOSE STEP -->
      <section
        class="w-full flex-1 min-h-0 overflow-auto flex flex-col divide-y"
      >
        <TaskWorkflowStepUpdateSingle
          :key="`${currentType.id}_${latestUpdateTime}`"
          ref="taskWorkflowStepUpdateRef"
          v-model:selected-ids="selectedIdList"
          :workflow-steps="workflowSteps"
          :form-type="currentType.id"
          :current-type="currentType.id"
          view-mode="EDIT"
        />
      </section>
    </div>
    <div class="w-full flex items-center justify-between">
      <div></div>
      <div class="flex-center space-x-2 p-4">
        <span
          v-if="isShowApplySuccess"
          class="bg-current-500 rounded-md text-white px-2 py-1 animate-bounce"
        >
          {{ $t('WORKFLOW_APPLY_SUCCESS') }}!
        </span>
        <SynButton type-text color="gray" @click="$emit('cancel')">
          <span> {{ $t('COMMON_LABEL_CANCEL') }}</span>
        </SynButton>
        <SynButton :disabled="selectedIdList?.length == 0" @click="onSave">
          <span> {{ $t('COMMON_LABEL_APPLY') }}</span>
        </SynButton>
      </div>
    </div>
  </div>
</template>
