<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import dayjs from 'dayjs';
import DayoffTypeAtom from '@/ui/components/dayoff/atoms/DayoffTypeAtom.vue';
import dayoffDetailStore from '@/store/dayoff/dayoff-detail';
import DayoffPresentDetailClass from '@/domain/entities/dayoff/DayoffPresentDetailClass';
import UserById from '@/ui/components/user/UserById.vue';
import DayoffReasonAtom from '@/ui/components/dayoff/atoms/DayoffReasonAtom.vue';
import DayoffFlowAction from '@/ui/components/dayoff/atoms/DayoffFlowAction.vue';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import DayoffDateSessionAtom from '@/ui/components/dayoff/atoms/DayoffDateSessionAtom.vue';
import {
  DayoffActionTypeUI,
  DayoffStatusUI,
} from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import {
  DayoffDateSession,
  DayoffReason,
  DayoffType,
  DayoffActionType,
  DayoffStatus,
  DayoffExceptionType,
} from '@/domain/enums/DayoffEnums';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import { AdminType, GroupMemberRole } from '@/ui/common/constants/constant';
import dayoffCreate from '@/store/dayoff/dayoff-create';
import DayoffExceptionForm from '@/ui/components/dayoff/DayoffExceptionForm.vue';
import DateoffExceptionClass from '@/domain/entities/dayoff/DateoffExceptionClass';
import DayoffExceptionTypeAtom from '@/ui/components/dayoff/atoms/DayoffExceptionTypeAtom.vue';
// import myProfileStore from '@/store/auth/my-profile';
import DayoffExceptionReasonAtom from '@/ui/components/dayoff/atoms/DayoffExceptionReasonAtom.vue';
import StatisticUserIcon from '@/ui/modules/calendar/calendar-statistic/StatisticUserIcon.vue';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import myProfileStore from '@/store/auth/my-profile';
import settingStore from '@/store/setting';
// import SynLocalLoading from '@/ui/common/atoms/SynLocalLoading.vue';
import appStore from '@/store/app';

const props = withDefaults(
  defineProps<{
    id: string;
    isScreen?: boolean;
    isViewInNotification?: boolean;
    customClass?: string;
  }>(),
  {
    customClass: 'py-4',
  }
);

watch(
  () => props.id,
  () => {
    initData();
  }
);

const emit = defineEmits(['onClose', 'cancel']);

const _dayoffDetailStore = dayoffDetailStore();
const _dayoffCreate = dayoffCreate();
const _calendarsOverviewStore = calendarsOverviewStore();
const _settingStore = settingStore();
const _myProfileStore = myProfileStore();

const myDepartment = computed(() => _myProfileStore.myDepartment);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const isAdmin = computed(() => {
  return (
    hasRolePermission(AdminType.Admin) ||
    hasRolePermission(AdminType.Owner) ||
    (departmentManagementEnabled.value &&
      myDepartment.value?.role == GroupMemberRole.Admin)
  );
});
const dayoffDetail = ref({} as DayoffPresentDetailClass);

const isMyRequest = computed(
  () => dayoffDetail.value?.userId == getCurrentUserId()
);

const isEditable = computed(
  () => isMyRequest.value && dayoffDetail.value.status == DayoffStatus.PENDING
);
const currentRequestFlow = computed(() => dayoffDetail.value.presentFlow);
const isLoading = ref(true);

const isCurrentDateSelect = computed(
  () =>
    dayjs(new Date()).format('MM-DD-YYYY') ==
      dayjs(dateoffRange.value.start).format('MM-DD-YYYY') ||
    dayjs(new Date()).format('MM-DD-YYYY') ==
      dayjs(dateoffRange.value.end).format('MM-DD-YYYY')
);

const isCurrentDayOffSelect = computed(
  () =>
    dayjs(new Date()).format('MM-DD-YYYY') ==
      dayjs(dayoffRange.value.start).format('MM-DD-YYYY') ||
    dayjs(new Date()).format('MM-DD-YYYY') ==
      dayjs(dayoffRange.value.end).format('MM-DD-YYYY')
);

// const myTimezone = computed(() => myProfileStore().getTimezone);
const dayoffRange = ref({
  start: 0,
  end: 0,
});

const errorCode = ref('');
const initData = async () => {
  try {
    if (!props.id) return;
    isLoading.value = true;
    const res = await _dayoffDetailStore.getDetailById(props.id);
    dayoffDetail.value = new DayoffPresentDetailClass(res?.result);
    // const diffTimeUtc = (myTimezone.value || 0) * 60 * 60 * 1000;

    dayoffRange.value = {
      start: dayoffDetail.value.startDate,
      end: dayoffDetail.value.endDate,
    };
  } catch (error: any) {
    errorCode.value = error?.error?.code || 'true';
  } finally {
    isLoading.value = false;
  }
};

const onChangeWithAction = async (action) => {
  try {
    let promise;
    switch (action) {
      case DayoffActionType.ACCEPT:
        promise = dayoffDetail.value.accept();
        break;
      case DayoffActionType.REJECT:
        promise = dayoffDetail.value.reject('');
        break;
      case DayoffActionType.CANCEL:
      case DayoffActionType.MARK_UNUSED:
        promise = dayoffDetail.value.cancel();
        break;
      case DayoffActionType.AGREE:
        promise = dayoffDetail.value.agreeUnusedRequest();
        break;
      case DayoffActionType.DISAGREE:
        promise = dayoffDetail.value.disagreeUnusedRequest();
        break;
      case DayoffActionType.CLOSE:
        promise = dayoffDetail.value.close();
        break;
      case DayoffActionType.RE_OPEN:
        promise = dayoffDetail.value.reopen();
        break;
      default:
        break;
    }
    await promise;
    // Update pending list
    _calendarsOverviewStore.removeItemInPendingList(dayoffDetail.value.id);
    // Update dayoff calendar
    // _calendarsOverviewStore.onChangeViewDateCalendar();
    _calendarsOverviewStore.updateItemOfList(dayoffDetail.value);
    _calendarsOverviewStore.updateMyRequests(dayoffDetail.value);

    emit('onClose');
  } catch (error) {
    console.log('🚀 ~ onChangeWithAction ~ error', error);
  }
};

const isNewChat = ref();
const onChatAboutTask = () => {
  isNewChat.value = false;
  emit('onClose');
};

const isModifyMode = ref(false);
const dayoffUpdate = ref({} as DayoffPresentDetailClass);

const exceptionData = ref({
  type: DayoffExceptionType.START_LATE,
  startTime: 0,
  endTime: 0,
  daySession: DayoffDateSession.MORNING,
});
const onOpenModify = () => {
  dayoffUpdate.value = new DayoffPresentDetailClass(dayoffDetail.value);
  if (dayoffDetail.value.type === DayoffType.EXCEPTION) {
    exceptionData.value = dayoffUpdate.value.exception;
  } else {
    dateoffRange.value = {
      start: dayoffDetail.value.startDate,
      end: dayoffDetail.value.endDate,
    };
    totalDay.value = dayoffDetail.value.usedDay;
  }
  isModifyMode.value = true;
};
const onCancelModify = () => {
  isModifyMode.value = false;
  initData();
};

const dateoffRange = ref({
  start: Date.now(),
  end: 0,
});

const onChangeDayoffRange = async (rangeDate) => {
  dateoffRange.value = rangeDate;
  dayoffUpdate.value.startDate = rangeDate.start;
  dayoffUpdate.value.endDate = rangeDate.end;
  calculateTotalDay();
};

const calculateTotalDay = async () => {
  const dayOffObj = await _dayoffCreate.calculateTotalDay({
    startDate: dayoffUpdate.value.startDate,
    endDate: dayoffUpdate.value.endDate,
    startDateSession: dayoffUpdate.value.startDateSession,
    endDateSession: dayoffUpdate.value.endDateSession,
  });

  totalDay.value = dayOffObj.total;
};

const totalDay = ref(1);
const clearToDate = () => {
  dateoffRange.value = {
    start: dayoffDetail.value.startDate,
    end: dayoffDetail.value.startDate,
  };
};

// const updateViewCalendar = () => {
//   _calendarsOverviewStore.onChangeViewDateCalendar();
// };

const updateCalendarAndRightList = () => {
  _calendarsOverviewStore.getListDayoffs();

  _calendarsOverviewStore.getMyActiveRequest();

  if (isAdmin.value) _calendarsOverviewStore.getListPending();
};
const onSubmitModifyDayoff = async () => {
  try {
    if (dayoffUpdate.value.type === DayoffType.EXCEPTION) {
      dayoffUpdate.value.exception = new DateoffExceptionClass(
        exceptionData.value
      );

      dayoffUpdate.value.endDate = dayoffUpdate.value.startDate;
    }
    const res = await _dayoffCreate.modifyDayoff(dayoffUpdate.value);

    if (res?.success && res?.result) {
      dayoffDetail.value = new DayoffPresentDetailClass(res?.result);
      dayoffRange.value = {
        start: dayoffDetail.value.startDate,
        end: dayoffDetail.value.endDate,
      };
    }

    isModifyMode.value = false;

    updateCalendarAndRightList();
  } catch (error) {
    console.log('🚀 ~ onSubmitModifyDayoff ~ error', error);
  }
};

const onDeleteDayoff = async () => {
  await _dayoffCreate.remove(dayoffDetail.value.id);
  updateCalendarAndRightList();
  // updateViewCalendar();
  emit('onClose');
};

initData();
</script>
<template>
  <!--  <SynLocalLoading v-if="isLoading" />-->
  <div
    v-if="isLoading"
    class="h-80 flex-center bg-gray-300 opacity-50 loading-card"
  >
    <SynIcon name="spinner" class="animate-spin fill-current h-10 w-10" />
  </div>
  <div
    v-if="errorCode"
    id="task-detail-page"
    class="flex-center flex-1 min-h-0 w-full bg-white"
  >
    <div class="py-6 bg-opacity-40 rounded-xl flex flex-col">
      <syn-animation name="searchNotFound" stype="width: 150px; height:150px" />
      <div class="flex flex-col space-y-4 pt-8 text-center">
        <span
          class="font-semibold text-2xl text-current-500"
          v-html="$t('COMMON_SOMETHING_WRONG') || 'Something wrong'"
        >
        </span>
        <span class="text-gray-600">
          {{
            $t('COMMON_LABEL_PAGE_NOT_FOUND_BODY', { name: 'dayoff' }) ||
            'We are sorry.'
          }}
        </span>
        <syn-button
          type-text
          :label="'Refresh'"
          @click.stop="initData"
        ></syn-button>
        <!--        <span-->
        <!--            class="text-current cursor-pointer hover:text-current-800"-->
        <!--            @click.stop="initData"-->
        <!--        >-->
        <!--          Refresh-->
        <!--        </span>-->
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && !errorCode"
    class="flex-1 flex flex-col overflow-hidden"
    style="max-width: 960px"
  >
    <!-- Header -->
    <div class="flex items-center justify-between px-4" :class="customClass">
      <div class="text-gray-800 font-semibold text-lg">
        {{ $t('DAYOFF_DETAIL_TITLE') || 'Day off detail' }}
      </div>
      <div class="flex-center space-x-1">
        <div
          :title="$t('DAYOFF_REQUEST_DISCUSS_ABOUT_WITH')"
          class="
            flex-center
            rounded-full
            w-8
            h-8
            hover:bg-gray-200
            cursor-pointer
          "
          @click="isNewChat = true"
        >
          <SynIcon class="fill-current h-5 w-5" name="Message" is-active />
        </div>
        <StatisticUserIcon
          v-if="$permission('ViewCalendarStatistic')"
          :user-id="dayoffDetail?.userId"
          :is-only-icon="true"
        />
        <!--        <div-->
        <!--          v-if="isEditable"-->
        <!--          class="-->
        <!--            flex-center-->
        <!--            cursor-pointer-->
        <!--            p-1-->
        <!--            w-6-->
        <!--            h-6-->
        <!--            fill-current-->
        <!--            rounded-full-->
        <!--            hover:bg-current-50-->
        <!--          "-->
        <!--        >-->
        <!--          <SynIcon name="edit" is-active @click="onOpenModify" />-->
        <!--        </div>-->

        <ThreeDotActions v-if="isEditable" type="action">
          <!--          <div-->
          <!--            class="-->
          <!--              list-li-->
          <!--              flex-->
          <!--              text-gray-600-->
          <!--              justify-start-->
          <!--              items-center-->
          <!--              space-x-2-->
          <!--              text-sm-->
          <!--            "-->
          <!--            @click="isNewChat = true"-->
          <!--          >-->
          <!--            <SynIcon class="fill-gray-500" name="Message" is-active />-->
          <!--            <span>-->
          <!--              {{-->
          <!--                $t('DAYOFF_REQUEST_DISCUSS_ABOUT_WITH') ||-->
          <!--                'Discuss about this request with'-->
          <!--              }}-->
          <!--            </span>-->
          <!--          </div>-->
          <div
            v-if="isEditable"
            class="list-li flex justify-start items-center space-x-2 text-sm"
            @click="onOpenModify"
          >
            <SynIcon class="fill-gray-500" name="edit" is-active />
            <span>
              {{ $t('COMMON_LABEL_EDIT') || 'Edit' }}
            </span>
          </div>
          <div
            v-if="isEditable"
            class="
              list-li
              flex
              border-t
              justify-start
              items-center
              space-x-2
              text-sm
            "
            @click="onDeleteDayoff"
          >
            <SynIcon class="fill-red-500" is-active name="trash" />
            <span class="text-red-500">
              {{ $t('COMMON_LABEL_DELETE') }}
            </span>
          </div>
        </ThreeDotActions>
      </div>
    </div>
    <!-- Body -->
    <div
      class="
        flex-1
        min-h-0
        flex flex-col
        space-y-4
        items-stretch
        h-full
        overflow-hidden
        px-4
      "
    >
      <div class="flex items-stretch">
        <div
          v-if="!isViewInNotification"
          class="
            w-1/3
            h-full
            p-4
            relative
            bg-current-50 bg-opacity-30
            rounded-lg
            overflow-auto
            small-scrollbar
          "
          style="min-width: 250px; max-height: 24rem"
        >
          <SynStepper
            v-if="currentRequestFlow"
            :steps="Object.values(currentRequestFlow)"
            type="portrait"
          >
            <template #stepName="{ key }">
              {{
                $t(DayoffStatusUI[key]?.textCode) ||
                DayoffStatusUI[key]?.textDefault ||
                key
              }}
            </template>
            <template #subContent="{ slotProps }">
              <div class="w-full flex flex-col space-y-1">
                <div
                  v-if="slotProps?.actionBy"
                  class="flex-center space-x-2 text-sm text-gray-800"
                >
                  <span class="lowercase text-xs italic text-gray-600">
                    <span class="font-medium">
                      {{
                        $t(
                          DayoffActionTypeUI[slotProps?.actionType]?.textCode
                        ) ||
                        DayoffActionTypeUI[slotProps?.actionType]?.textDefault
                      }}
                    </span>
                    {{ $t('TASK_DETAIL_LABEL_BY') || 'by' }}
                  </span>
                  <UserById
                    class="font-medium"
                    :user-id="slotProps?.actionBy"
                    is-show-first-name
                  />
                </div>
                <div class="text-xs text-gray-500">
                  <SynLabelDateTime
                    v-if="slotProps?.actionTime"
                    format="date_time"
                    ignore-timezone
                    :datetime="slotProps?.actionTime"
                  />

                  <!-- {{
                                                                                                                  $filters.dayjs(slotProps?.actionTime, 'MMM DD, YYYY, hh:mm A')
                                                                                                                }} -->
                </div>
              </div>
            </template>
          </SynStepper>
        </div>
        <div
          v-if="isModifyMode"
          v-perfect-scrollbar
          class="h-72 p-2 flex-1 flex items-start justify-between space-x-2"
        >
          <div class="flex-1 flex flex-col space-y-4">
            <!-- Type -->
            <div class="flex items-center space-x-2">
              <DayoffTypeAtom
                :type="dayoffUpdate.type || DayoffType.SCHEDULE"
              />
            </div>
            <template
              v-if="
                dayoffUpdate.type == DayoffType.SCHEDULE ||
                dayoffUpdate.type == DayoffType.WFH
              "
            >
              <!-- Select date -->
              <div
                class="
                  flex
                  items-center
                  justify-between
                  space-x-2
                  border
                  rounded
                  p-2
                  bg-current-50
                "
              >
                <div>
                  <v-date-picker
                    v-model="dateoffRange"
                    :locale="appStore().language || 'en'"
                    color="current"
                    :attributes="[
                      {
                        key: 'today',
                        dot: {
                          color: isCurrentDateSelect ? 'yellow' : 'current',
                          class: isCurrentDateSelect ? 'mb-1 z-70' : '',
                        },
                        dates: new Date(),
                      },
                    ]"
                    :model-config="{ type: 'number', mask: 'DD-MM-YYYY' }"
                    :popover="{ visibility: 'click' }"
                    :first-day-of-week="2"
                    is-range
                    @update:model-value="onChangeDayoffRange"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <div class="flex justify-center items-center space-x-2">
                        <div class="flex flex-col space-y-2">
                          <input
                            :value="inputValue.start"
                            class="
                              w-full
                              border
                              px-2
                              py-1
                              rounded
                              focus:outline-none focus:border-current
                            "
                            v-on="inputEvents.start"
                          />
                          <div class="flex items-center space-x-1">
                            <SynRadioTag
                              v-model="dayoffUpdate.startDateSession"
                              :value="DayoffDateSession.ALL_DAY"
                              radio-name="dayoff-create-form"
                              @change="calculateTotalDay"
                            >
                              <div class="px-2 text-sm">
                                <DayoffDateSessionAtom
                                  is-text-only
                                  :date-session="DayoffDateSession.ALL_DAY"
                                />
                              </div>
                            </SynRadioTag>
                            <SynRadioTag
                              v-if="dateoffRange?.end == dateoffRange?.start"
                              v-model="dayoffUpdate.startDateSession"
                              :value="DayoffDateSession.MORNING"
                              radio-name="dayoff-create-form"
                              @change="calculateTotalDay"
                            >
                              <div class="px-2 text-sm">
                                <DayoffDateSessionAtom
                                  is-text-only
                                  :date-session="DayoffDateSession.MORNING"
                                />
                              </div>
                            </SynRadioTag>
                            <SynRadioTag
                              v-model="dayoffUpdate.startDateSession"
                              :value="DayoffDateSession.AFTERNOON"
                              radio-name="dayoff-create-form"
                              @change="calculateTotalDay"
                            >
                              <div class="px-2 text-sm">
                                <DayoffDateSessionAtom
                                  is-text-only
                                  :date-session="DayoffDateSession.AFTERNOON"
                                />
                              </div>
                            </SynRadioTag>
                          </div>
                        </div>
                        <div
                          v-if="dateoffRange?.end !== dateoffRange?.start"
                          class="flex flex-col space-y-2"
                        >
                          <div class="flex-center relative">
                            <input
                              id="dayoff-create-form_to-date-input"
                              :value="inputValue.end"
                              class="
                                w-full
                                border
                                px-2
                                py-1
                                rounded
                                focus:outline-none focus:border-current
                              "
                              v-on="inputEvents.end"
                            />
                            <span
                              v-if="inputValue.end"
                              class="absolute right-1"
                            >
                              <SynIcon
                                has-action
                                name="close"
                                @click="clearToDate"
                              />
                            </span>
                          </div>
                          <div class="flex items-center space-x-1">
                            <SynRadioTag
                              v-model="dayoffUpdate.endDateSession"
                              :value="DayoffDateSession.ALL_DAY"
                              radio-name="dayoff-create-form"
                              @change="calculateTotalDay"
                            >
                              <div class="px-2 text-sm">
                                <DayoffDateSessionAtom
                                  is-text-only
                                  :date-session="DayoffDateSession.ALL_DAY"
                                />
                              </div>
                            </SynRadioTag>
                            <SynRadioTag
                              v-model="dayoffUpdate.endDateSession"
                              :value="DayoffDateSession.MORNING"
                              radio-name="dayoff-create-form"
                              @change="calculateTotalDay"
                            >
                              <div class="px-2 text-sm">
                                <DayoffDateSessionAtom
                                  is-text-only
                                  :date-session="DayoffDateSession.MORNING"
                                />
                              </div>
                            </SynRadioTag>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </div>
                <div class="flex-center bg-white p-2 rounded relative">
                  <div class="flex items-end">
                    <span
                      class="text-current"
                      style="font-size: 3rem; line-height: 3rem"
                    >
                      {{ totalDay }}
                    </span>
                    <span class="text-sm">{{
                      $t(
                        totalDay > 1
                          ? 'DAYOFF_CREATE_FORM_DAYS'
                          : 'DAYOFF_CREATE_FORM_DAY'
                      )
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- REASON -->
              <div class="flex flex-col space-y-2">
                <!-- reason type -->
                <div class="flex space-x-2">
                  <SynRadioTag
                    v-for="reason in DayoffReason"
                    :key="reason"
                    v-model="dayoffUpdate.requestReason"
                    :value="reason"
                    radio-name="dayoff-create-form_reason"
                    custom-class="rounded"
                    custom-active-class="bg-current-50"
                    custom-inactive-class="opacity-70"
                  >
                    <DayoffReasonAtom
                      :is-active="dayoffUpdate.requestReason === reason"
                      :reason="reason"
                    />
                  </SynRadioTag>
                </div>
                <!-- reason note -->
                <div>
                  <SynInputArea
                    v-model="dayoffUpdate.requestNote"
                    :placeholder="
                      $t('DAYOFF_CREATE_FORM_NOTE_PLACEHOLDER') ||
                      'Note more detail in here...'
                    "
                  />
                </div>
              </div>
            </template>
            <template v-if="dayoffUpdate.type == DayoffType.EXCEPTION">
              <DayoffExceptionForm
                v-model:exceptionDataModel="exceptionData"
                v-model:startDateModel="dayoffUpdate.startDate"
                v-model:requestReasonModel="dayoffUpdate.requestReason"
                v-model:requestNoteModel="dayoffUpdate.requestNote"
              />
            </template>
          </div>
        </div>
        <div
          v-else
          class="pl-2 flex-1 flex items-start justify-between space-x-2"
          style="max-height: 24rem"
        >
          <div
            v-perfect-scrollbar
            class="flex flex-1 h-full flex-col space-y-4 border p-4 rounded-lg"
            :class="isScreen ? 'w-1/2' : 'w-min'"
            style="max-width: 23rem"
          >
            <!-- Type -->
            <div class="flex items-center space-x-2">
              <DayoffTypeAtom
                :type="dayoffDetail.type || DayoffType.SCHEDULE"
              />
            </div>

            <!-- Schedule type -->
            <template
              v-if="
                dayoffDetail.type == DayoffType.SCHEDULE ||
                dayoffDetail.type == DayoffType.WFH
              "
            >
              <div
                class="
                  flex
                  items-center
                  justify-between
                  pl-2
                  space-x-2
                  rounded
                  p-2
                  w-full
                  bg-current-50
                "
              >
                <div class="flex-1 flex items-start flex-col space-y-2">
                  <div class="flex flex-col space-y-2">
                    <span class="flex flex-col space-y-1">
                      <span class="flex items-center space-x-2">
                        <span
                          v-if="dayoffDetail.endDate !== dayoffDetail.startDate"
                          class="text-xs text-gray-800"
                        >
                          {{ $t('DAYOFF_DETAIL_FROM_DATE') }}
                        </span>
                        <span class="text-sm font-medium capitalize">
                          <SynLabelDateTime
                            format="date_day"
                            ignore-timezone
                            :datetime="dayoffDetail.startDate"
                          />
                        </span>
                      </span>
                      <DayoffDateSessionAtom
                        v-if="dayoffDetail?.dateOffs?.length > 0"
                        class="text-current text-xs"
                        :date-session="dayoffDetail?.dateOffs[0]?.daySession"
                      />
                    </span>
                    <span
                      v-if="dayoffDetail.endDate !== dayoffDetail.startDate"
                      class="flex flex-col items-stretch space-y-1"
                    >
                      <span class="flex items-center space-x-2">
                        <span class="text-xs text-gray-800">
                          {{ $t('DAYOFF_DETAIL_TO_DATE') }}
                        </span>

                        <span class="text-sm font-medium capitalize">
                          <SynLabelDateTime
                            format="date_day"
                            ignore-timezone
                            :datetime="dayoffDetail.endDate"
                          />
                        </span>
                      </span>
                      <DayoffDateSessionAtom
                        v-if="dayoffDetail?.dateOffs?.length > 0"
                        class="text-current text-xs"
                        :date-session="
                          dayoffDetail?.dateOffs[
                            dayoffDetail?.dateOffs.length - 1
                          ]?.daySession
                        "
                      />
                    </span>
                  </div>
                </div>

                <div class="flex-center bg-white p-2 rounded h-full relative">
                  <div class="flex items-end flex-wrap">
                    <span
                      class="text-current"
                      style="font-size: 3rem; line-height: 3rem"
                    >
                      {{ dayoffDetail.usedDay }}
                    </span>
                    <span class="text-sm">
                      {{
                        $t(
                          dayoffDetail.usedDay > 1
                            ? 'DAYOFF_CREATE_FORM_DAYS'
                            : 'DAYOFF_CREATE_FORM_DAY'
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="dayoffDetail.type == DayoffType.EXCEPTION">
              <div class="flex items-start flex-col space-y-2">
                <div class="flex flex-col space-y-2">
                  <span class="flex flex-col space-y-1">
                    <DayoffExceptionTypeAtom
                      is-active
                      :exception-type="dayoffDetail.exception?.type"
                    />
                    <span class="flex items-center space-x-2 w-max">
                      <span class="text-sm capitalize font-medium">
                        {{
                          $filters.dayjs(
                            dayoffDetail.startDate,
                            'dddd MMM DD, YYYY'
                          )
                        }}
                      </span>
                      ,
                      <span class="text-sm text-sm capitalize font-medium">
                        {{
                          $filters.dayjs(
                            dayoffDetail.exception?.startTime,
                            ' hh:mm A'
                          )
                        }}
                      </span>
                      <span
                        v-if="
                          dayoffDetail.exception.type ===
                          DayoffExceptionType.BREAK_TIME
                        "
                        class="text-sm text-sm capitalize font-medium"
                      >
                        -
                        {{
                          $filters.dayjs(
                            dayoffDetail.exception.endTime,
                            'hh:mm A'
                          )
                        }}
                      </span>
                    </span>
                    <DayoffDateSessionAtom
                      class="text-current text-xs"
                      :date-session="dayoffDetail?.exception?.daySession"
                    />
                  </span>
                </div>
              </div>
            </template>

            <!-- REASON -->
            <div class="flex flex-col flex-1 overflow-hidden space-y-2">
              <div class="flex items-center space-x-2">
                <span
                  v-if="
                    dayoffDetail.type === DayoffType.WFH &&
                    dayoffDetail.requestNote
                  "
                  class="text-sm text-gray-500"
                >
                  {{ $t('DAYOFF_DETAIL_LABEL_REASON') }}
                </span>
                <span
                  v-if="
                    dayoffDetail.type === DayoffType.EXCEPTION ||
                    dayoffDetail.type === DayoffType.SCHEDULE
                  "
                  class="text-sm text-gray-500"
                >
                  {{ $t('DAYOFF_DETAIL_LABEL_REASON') }}
                </span>
                <!-- reason type -->
                <div
                  v-if="
                    dayoffDetail.type === DayoffType.EXCEPTION ||
                    dayoffDetail.type === DayoffType.SCHEDULE
                  "
                >
                  <DayoffExceptionReasonAtom
                    v-if="dayoffDetail.type === DayoffType.EXCEPTION"
                    :reason="dayoffDetail.requestReason"
                  />
                  <DayoffReasonAtom
                    v-else
                    :reason="dayoffDetail.requestReason"
                  />
                </div>
              </div>

              <div class="flex flex-1 flex-col overflow-auto small-scrollbar">
                <!-- reason note -->
                <div
                  v-if="dayoffDetail.requestNote"
                  class="rounded text-sm"
                  style="max-width: fit-content; min-width: 100%"
                >
                  {{ dayoffDetail.requestNote }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="dayoffRange?.start > 0 && !isViewInNotification"
            class="h-72"
          >
            <v-date-picker
              v-model="dayoffRange"
              class="h-72 w-full"
              :locale="appStore().language || 'en'"
              is-range
              color="current"
              :attributes="[
                {
                  key: 'today',
                  dot: {
                    color: isCurrentDayOffSelect ? 'yellow' : 'current',
                    class: isCurrentDayOffSelect ? 'mb-1 z-70' : '',
                  },
                  dates: new Date(),
                },
              ]"
              :available-dates="{
                start: dayoffRange.start,
                end: dayoffRange.end,
              }"
              :first-day-of-week="2"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isViewInNotification"
        class="pl-2 flex items-stretch justify-between space-x-2"
      >
        <div
          v-perfect-scrollbar
          class="w-full p-4 relative bg-current-50 bg-opacity-30 rounded-lg"
          style="min-width: 250px; max-height: 100%"
        >
          <SynStepper
            v-if="currentRequestFlow"
            :steps="Object.values(currentRequestFlow)"
            type="portrait"
          >
            <template #stepName="{ key }">
              {{
                $t(DayoffStatusUI[key]?.textCode) ||
                DayoffStatusUI[key]?.textDefault ||
                key
              }}
            </template>
            <template #subContent="{ slotProps }">
              <div class="w-full flex flex-col space-y-1">
                <div
                  v-if="slotProps?.actionBy"
                  class="flex-center space-x-2 text-sm text-gray-800"
                >
                  <span class="lowercase text-xs italic text-gray-600">
                    <span class="font-medium">
                      {{
                        $t(
                          DayoffActionTypeUI[slotProps?.actionType]?.textCode
                        ) ||
                        DayoffActionTypeUI[slotProps?.actionType]?.textDefault
                      }}
                    </span>
                    {{ $t('TASK_DETAIL_LABEL_BY') || 'by' }}
                  </span>
                  <UserById
                    class="font-medium"
                    :user-id="slotProps?.actionBy"
                    is-show-first-name
                  />
                </div>
                <div class="text-xs text-gray-500">
                  <SynLabelDateTime
                    v-if="slotProps?.actionTime"
                    format="date_time"
                    ignore-timezone
                    :datetime="slotProps?.actionTime"
                  />

                  <!-- {{
                                                                                                                    $filters.dayjs(slotProps?.actionTime, 'MMM DD, YYYY, hh:mm A')
                                                                                                                  }} -->
                </div>
              </div>
            </template>
          </SynStepper>
        </div>
        <div
          v-if="dayoffRange?.start > 0"
          class="h-72"
          :class="[
            !props.isScreen ? '' : 'lg:hidden xl:hidden 3xl:block',
            props.isViewInNotification ? 'w-1/2' : '',
          ]"
        >
          <v-date-picker
            v-model="dayoffRange"
            :locale="appStore().language || 'en'"
            class="h-72 w-full"
            is-range
            color="teal"
            :attributes="[
              {
                key: 'today',
                dot: {
                  color: isCurrentDayOffSelect ? 'yellow' : 'blue',
                  class: isCurrentDayOffSelect ? 'mb-1 z-70' : '',
                },
                dates: new Date(),
              },
            ]"
            :available-dates="{
              start: dayoffRange.start,
              end: dayoffRange.end,
            }"
            :first-day-of-week="2"
          />
        </div>
      </div>
    </div>

    <!-- HISTORY -->

    <!-- Footer -->
    <div class="py-4 px-4 flex items-center justify-end">
      <div v-if="isModifyMode" class="flex-center space-x-2">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          type-text
          color="gray"
          @click="onCancelModify"
        />
        <SynButton
          :label="$t('COMMON_LABEL_SAVE') || 'Save'"
          @click="onSubmitModifyDayoff"
        />
      </div>
      <DayoffFlowAction
        v-else
        :request-status="dayoffDetail.status"
        :start-date="dayoffDetail.startDate"
        :requester-id="dayoffDetail.userId"
        @on-action="onChangeWithAction"
      />
    </div>
  </div>
  <ModalNewChat
    v-if="isNewChat"
    :title="
      $t('DAYOFF_REQUEST_DISCUSS_ABOUT_WITH') ||
      'Discuss about this request with'
    "
    :attached-dayoff="dayoffDetail"
    @on-close="isNewChat = false"
    @on-conversation-click="onChatAboutTask"
  />
</template>

<style lang="scss" scoped>
@media (max-width: 1750px) {
  .loading-card {
    width: 650px !important;
  }
}

@media (min-width: 1920px) {
  .loading-card {
    width: 910px !important;
  }
}
</style>
