<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="129.792"
    height="134.434"
    viewBox="0 0 129.792 134.434"
  >
    <defs>
      <filter
        id="Subtraction_5"
        x="0"
        y="0"
        width="129.792"
        height="134.434"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Group_4282"
      data-name="Group 4282"
      transform="translate(-1373.943 -274)"
    >
      <path
        id="Polygon_18"
        data-name="Polygon 18"
        d="M6,0l6,6H0Z"
        transform="translate(1377 280)"
        fill="#df7700"
      />
      <path
        id="Polygon_19"
        data-name="Polygon 19"
        d="M6,0l6,6H0Z"
        transform="translate(1495 390) rotate(90)"
        fill="#df7700"
      />
      <g
        transform="matrix(1, 0, 0, 1, 1373.94, 274)"
        filter="url(#Subtraction_5)"
      >
        <path
          id="Subtraction_5-2"
          data-name="Subtraction 5"
          d="M111.8,116.436,0,0H43.215L111.8,71.431v45.005Z"
          transform="translate(9 6)"
          fill="#ffac1c"
        />
      </g>
    </g>
  </svg>
</template>
