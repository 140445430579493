<script setup lang="ts">
// *** IMPORTS ***
import { computed } from 'vue';
import settingStore from '@/store/setting';
import { DomainTab } from '@/domain/enums/domain-enums';

// *** PROPS, EMITS ***
defineProps<{
  activeTab: any;
}>();
defineEmits(['update:activeTab']);

// *** PRIVATE VARIABLES ***
const _settingStore = settingStore();

// *** COMPOSABLES ***

// *** REFS ***

// *** COMPUTED ***
const gedEnabled = computed(() => _settingStore.gedEnabled);
const domainTabs = computed(() => {
  const tabs = [{ id: DomainTab.Tasks, name: 'Công việc' }];
  if (gedEnabled.value)
    tabs.push({ id: DomainTab.Resources, name: 'Tài nguyên' });
  return tabs;
});

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div
    class="
      flex
      items-center
      justify-between
      bg-gray-100
      border-b border-gray-100
    "
  >
    <div></div>
    <div class="h-full flex items-end">
      <div
        v-for="tab in domainTabs"
        :key="tab?.id"
        role="button"
        class="px-3 pt-2 pb-3 hover:text-current-500 relative text-sm tab-item"
        :class="{
          'active font-semibold text-current-500': activeTab?.id === tab?.id,
        }"
        @click="$emit('update:activeTab', tab)"
      >
        {{ tab?.name }}
      </div>
    </div>
    <div></div>
  </div>
</template>

<style scoped>
.tab-item.active:before {
  background: #4cb0b0;
  opacity: 1;
  width: calc(100% - 26px);
  height: 4px;
  left: 13px;
  bottom: 0;
  border: 0;
  border-radius: 5px 5px 0 0;
  box-shadow: none;
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
}
</style>
