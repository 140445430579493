import ShapeEntity from './ShapeEntity';

export default class ShapeUserEntity extends ShapeEntity {
    sourceData: {
        isStraight: boolean;
        start: {
            point: {
                x: number;
                y: number;
            };
            arrowhead: string;
        };
        end: {
            point: {
                x: number;
                y: number;
            };
            arrowhead: string;
        };
    };
    constructor(data) {
        super(data);
        this.sourceData =
            typeof data?.sourceData == 'string'
                ? JSON.parse(data?.sourceData)
                : data?.sourceData;
    }

    updateEndPosition(newTranslateX, newTranslateY) {
        this.sourceData = {
            ...this.sourceData,
            end: {
                point: {
                    x: newTranslateX,
                    y: newTranslateY,
                },
                arrowhead: this.sourceData?.end?.arrowhead || 'triangle',
            },
        };
    }
    updateStartPosition(newTranslateX, newTranslateY) {
        this.sourceData = {
            ...this.sourceData,
            start: {
                point: {
                    x: newTranslateX,
                    y: newTranslateY,
                },
                arrowhead: '',
            },
        };
    }
}
