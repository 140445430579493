import { defineStore } from 'pinia';
import TaskService from '@/application/services/task/TaskService';
import storeId from '@/base/store/storeId';

export default defineStore({
    id: storeId.taskActivity,
    state: () => ({
        _taskService: TaskService.getInstance(),
        currentTaskHistoryId: null,
    }),
    getters: {},
    actions: {
        setCurrentTaskHistoryId(idTask) {
            this.currentTaskHistoryId = idTask;
        },
        async getTaskActivityById(idTask, orderBy) {
            try {
                const res = await this._taskService.getTaskActivityById(
                    idTask,
                    orderBy || 'desc'
                );
                return res['result'];
            } catch (e) {
                console.log(e);
                return [];
            }
        },
        async getTaskGroupActivityById(orgId, idTask, orderBy) {
            try {
                if (!idTask) return;
                const res = await this._taskService.getTaskGroupActivityById(
                    orgId,
                    idTask,
                    orderBy || 'desc'
                );
                return res['result'];
            } catch (e) {
                console.log(e);
                return [];
            }
        },
    },
});
