<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="padding: 3px; enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m428 174.5c0-95.117-77.383-172.5-172.5-172.5s-172.5 77.383-172.5 172.5v162c0 95.117 77.383 172.5 172.5 172.5a171.729 171.729 0 0 0 111.283-40.81l19.847 30.564a22.5 22.5 0 0 0 37.741-24.508l-26.383-40.629a171.519 171.519 0 0 0 30.012-97.117zm-74.17 191.114a22.5 22.5 0 1 0 -37.74 24.508l25.932 39.936a127.429 127.429 0 0 1 -214.022-93.558v-162a127.5 127.5 0 0 1 255 0v162a126.738 126.738 0 0 1 -12.433 54.888z"
      ></path>
    </g>
  </svg>
</template>
