<script setup lang="ts">
import { ref } from 'vue';
defineProps<{ modelValue: number }>();
defineEmits<{
  (e: 'update:modelValue', value: number): void;
}>();
const isShowMore = ref<boolean>(false);
</script>
<template>
  <VigDropdown
    :arrow="false"
    placement="bottom-end"
    dropdown-item-class="department-select__dropdown-item"
    @on-dropdown-open="isShowMore = true"
    @on-dropdown-close="isShowMore = false"
  >
    <template #dropdown-toggle>
      <div class="flex-center cursor-pointer rounded-full h-8 relative">
        <SynIcon
          name="filter2"
          :custom-class="
            isShowMore
              ? 'w-4 h-4 fill-current-500'
              : 'w-4 h-4 fill-gray-500 text-gray-500 '
          "
        />
        <span
          v-if="modelValue !== 1"
          class="h-2 w-2 rounded-full bg-current-500 absolute top-1 -right-1"
        >
        </span>
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="
          flex flex-col
          department-select__dropdown-item
          p-1
          overflow-y-auto
          small-scrollbar
        "
        style="min-width: 14rem; max-height: 32rem"
      >
        <template v-for="key in [1, 2, 3]" :key="key">
          <div
            class="
              pl-2
              py-2
              space-x-2
              flex
              items-center
              hover:bg-current-50
              cursor-pointer
              department-select__dropdown-item
              text-sm
            "
            :class="key == modelValue ? 'bg-current-50 text-current-500' : ''"
            @click="$emit('update:modelValue', key)"
          >
            {{
              $t(
                key == 1
                  ? 'COMMON_LABEL_ALL'
                  : key == 2
                  ? 'COMMON_LABEL_DEPARTMENT'
                  : 'DOCUMENT_TITLE_GROUP'
              )
            }}
          </div>
        </template>
      </div>
    </template>
  </VigDropdown>
</template>
