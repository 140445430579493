<script setup lang="ts">
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

const emit = defineEmits(['onCloseClick', 'onEmptyTrashConfirm']);

const onEmptyTrashClick = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: translate('GED_TRASH_EMPTY_MSG'),
    confirmable: true,
    closeable: true,
  });

  const isConfirmed = await ask();
  if (isConfirmed) emit('onEmptyTrashConfirm');
};
</script>

<template>
  <div class="flex items-center space-x-3 px-4 py-2 bg-red-50">
    <div class="flex items-center space-x-2.5">
      <SynIcon name="Trash" custom-class="w-5 h-5 fill-red-500" />
      <div class="text-xl">{{ $t('GED_TRASH') }}</div>
    </div>
    <div class="text-gray-500">({{ $t('GED_TRASH_DESC') }})</div>
    <div class="flex-1">
      <VigButton
        outline
        color="gray"
        padding="pl-2 pr-4 py-1.5"
        rounded="rounded-full"
        @click="$emit('onCloseClick')"
      >
        <SynIcon name="Close" />
        <span class="text-sm">{{ $t('COMMON_LABEL_CLOSE') }}</span>
      </VigButton>
    </div>

    <VigDropdown>
      <template #dropdown-toggle>
        <VigButton ghost color="gray" icon="DotsVertical"></VigButton>
      </template>
      <template #dropdown-menu>
        <VigButton
          ghost
          color="gray"
          class="w-full justify-start space-x-3 dropdown-item"
          @click="onEmptyTrashClick"
        >
          <SynIcon name="Trash" />
          <span class="font-normal">{{ $t('GED_TRASH_EMPTY') }}</span>
        </VigButton>
      </template>
    </VigDropdown>
  </div>
</template>
