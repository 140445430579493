<script setup lang="ts">
const props = defineProps<{
  value: any;
  canRemove?: boolean;
}>();

const emits = defineEmits(['onRemove']);

const onTagClick = () => {
  if (props.canRemove) {
    emits('onRemove');
  }
};
</script>

<template>
  <div
    class="
      rounded-full
      shadow
      bg-current-100
      text-gray-800 text-sm
      p-1
      flex-center
      space-x-2
    "
    :class="{
      'hover:bg-current-200 cursor-pointer': canRemove,
    }"
    @click="onTagClick"
  >
    <SynAvatar
      custom-class="w-6 h-6"
      :src="value?.avatar?.replace('original', 'mini')"
      :name="value?.name"
    />

    <span>{{ value?.firstName || value?.name }}</span>

    <SynIcon v-if="canRemove" name="Close" class="text-gray-500" />
  </div>
</template>
