import { computed, Ref, ref, UnwrapRef } from 'vue';
import remoteConfigStore from '@/store/remoteConfig';
import { verifyFiles } from '@/ui/hooks/fileHook';

export interface IAudioRecord {
    addFiles(files: any[]): void;
}

export default function audioLogic(formData: Ref<UnwrapRef<IAudioRecord>>) {
    // Audio recording
    const isOpenRecording = ref(false);
    const onCreateAudio = () => {
        isOpenRecording.value = true;
    };

    const attachmentConfig = computed<any>(() => {
        return {
            extensions: remoteConfigStore().webTaskUploadContentTypes,
            contentTypes: remoteConfigStore().webTaskUploadContentTypes,
            maxSize: remoteConfigStore().webTaskUploadMaxFilesize,
        };
    });

    const onCreateAudioDone = (recordings) => {
        const resultFiles: any[] = [];
        verifyFiles(
            recordings?.map((record) => {
                return {
                    ...record,
                    fileBlob: record?.blob,
                    objectUrl: record?.src,
                };
            }),
            attachmentConfig.value?.extensions,
            attachmentConfig.value?.maxSize,
            (file) => {
                resultFiles.push(file);
            }
        );
        formData.value.addFiles(resultFiles);
        isOpenRecording.value = false;
    };

    const onCreateAudioCancel = () => {
        isOpenRecording.value = false;
    };

    return {
        isOpenRecording,
        onCreateAudio,
        onCreateAudioDone,
        onCreateAudioCancel,
    };
}
