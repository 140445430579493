import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import HomeService from '@/application/services/HomeService';
import { getAllMemberStartedToday } from '@/ui/modules/dashboard/helpers/remaining-workforce-helper';
export enum ERemainingWorkforceStatus {
    notYetStated = 'notYetStated',
    started = 'started',
    stopped = 'stopped',
    offToday = 'offToday',
}
export type CapacityMember = {
    id: number;
    startedTime: string;
    lastCheckIn: string;
    intentTime: number;
    timeWorking: number;
    lastState: 'online' | 'offline' | 'absent';
    dayOffInfo: any;
    workingStatus: ERemainingWorkforceStatus;
    styleData: { bg: string; text: string };
    remainPercent: number;
    workingTime: number;
    realIntentTime: number;
};

export type TDepartmentOverview = {
    id: number;
    avatar: string;
    avatarUrl: string;
    name: string;
    description: string;
    activeTasks: number;
    finishedTasks: number;
    onlineCount: number;
    pauseCount: number;
    offlineCount: number;
    members: {
        departmentId: number;
        userId: number;
        firstName: string;
        lastName: string;
        avatar: string;
        avatarUrl: string;
    };
};

export type TWorkingMember = {
    totalWorking: number;
    totalPaused: number;
    totalStopped: number;
};
export type TTaskActivityChart = {
    date: string;
    numberOfTaskFinished: number;
    numberOfTaskCreated: number;
};
export type TDayoffStatusByDate = {
    schedule: {
        total: number;
        members: {
            userId: number;
            avatar: string;
            avatarUrl: string;
        }[];
    };
    wfh: {
        total: number;
        members: {
            userId: number;
            avatar: string;
            avatarUrl: string;
        }[];
    };
    exception: {
        total: number;
        members: [
            {
                userId: number;
                avatar: string;
                avatarUrl: string;
            }
        ];
    };
};

export default defineStore({
    id: storeId.DASHBOARD_MONITOR,
    state: () => ({
        isLoadingWorkingStatus: false,
        isLoadingDepartmentWorkingStatus: false,
        timeCapacity: {} as {
            members: CapacityMember[];
        },
        workingMember: {} as TWorkingMember,
        task: {} as {
            totalTaskOnDay: number;
            totalFinished: number;
            totalCreated: number;
        },
        analyze: {} as {
            score: number;
            label: string;
        },
        taskActivityChart: [] as TTaskActivityChart[],
        dayoffStatusByDate: {} as TDayoffStatusByDate,
        departmentsOverview: [] as TDepartmentOverview[],
    }),
    getters: {},
    actions: {
        async getAllMonitorData() {
            this.isLoadingWorkingStatus = true;
            try {
                await Promise.all([
                    this.getWorkingStatus(),
                    this.getTaskActivityChart(),
                    this.getDayoffStatusByDate(''),
                    this.getDepartmentsOverview(),
                    this.calculateScorePerformanceOnDay(),
                ]);
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            } finally {
                this.isLoadingWorkingStatus = false;
            }
        },
        async getWorkingStatus() {
            const res = await HomeService.getInstance().getWorkingStatus();
            const result = res?.result;
            this.timeCapacity.members = getAllMemberStartedToday(
                result?.timeCapacity?.members
            );
            this.workingMember = result?.workingMember;
            this.task = result?.task;
        },
        async calculateScorePerformanceOnDay() {
            const res =
                await HomeService.getInstance().calculateScorePerformanceOnDay();
            this.analyze = res?.result;
        },
        async getTaskActivityChart() {
            const res = await HomeService.getInstance().getTaskActivityChart();
            this.taskActivityChart = res?.result;
        },
        async getDayoffStatusByDate(date) {
            const res = await HomeService.getInstance().getDayoffStatusByDate(
                date
            );
            this.dayoffStatusByDate = res?.result;
        },
        async getDepartmentsOverview() {
            const res =
                await HomeService.getInstance().getDepartmentsOverview();
            this.departmentsOverview = res?.result;
        },
    },
});
