<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="m84.234 78.581c-2.014-5.994-10.041-9.746-15.764-12.261-2.242-.982-8.449-2.648-9.195-5.471-.267-1.017-.231-1.976-.012-2.895-.345.066-.695.105-1.057.105h-3.729c-2.977 0-5.396-2.422-5.396-5.397 0-2.973 2.42-5.39 5.396-5.39h3.729c1.232 0 2.4.417 3.342 1.161 1.381-.184 2.713-.479 3.955-.866 1.631-3.417 2.903-7.503 3.188-11.02 1.217-15.048-8.008-23.852-21.235-22.33-9.617 1.107-15.362 8.278-15.983 17.51-.628 9.41 2.861 16.36 6.567 21.458 1.623 2.229 3.328 3.662 3.066 6.348-.304 3.176-3.7 4.061-6.129 5.037-2.878 1.156-5.978 2.91-7.442 3.721-5.043 2.785-10.578 6.139-11.822 10.727-2.755 10.168 6.549 13.248 14.23 14.67 6.592 1.216 14.025 1.312 20.139 1.312 11.059 0 30.945-.443 34.152-8.756.912-2.359.521-6.118 0-7.663z"
        ></path>
        <path
          d="m60.566 51.143c-.506-.771-1.371-1.283-2.358-1.283h-3.729c-1.556 0-2.811 1.257-2.811 2.805 0 1.554 1.255 2.813 2.811 2.813h3.729c1.089 0 2.013-.621 2.479-1.519 5.199-.409 9.721-1.997 12.895-4.342.729.47 1.591.745 2.521.745h.234c2.592 0 4.688-2.098 4.688-4.693v-9.368c0-1.866-1.094-3.476-2.672-4.224-.688-15.043-13.141-27.077-28.353-27.077s-27.667 12.034-28.352 27.077c-1.581.749-2.674 2.358-2.674 4.224v9.368c0 2.595 2.098 4.693 4.684 4.693h.237c2.588 0 4.687-2.098 4.687-4.693v-9.368c0-1.839-1.063-3.425-2.608-4.192.669-12.675 11.187-22.778 24.026-22.778 12.834 0 23.357 10.103 24.023 22.778-1.543.768-2.605 2.353-2.605 4.192v9.368c0 .622.121 1.201.334 1.742-2.732 1.955-6.709 3.348-11.186 3.732z"
        ></path>
      </g>
    </g>
  </svg>
</template>
