<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { useElementHover } from '@vueuse/core';
import { getDynamicPosition } from '@/ui/hooks/commonFunction';
import { emojisFlash } from '@/ui/common/molecules/VigEmojiPicker/emojis-flash.js';
import VigEmojiPicker from '@/ui/common/molecules/VigEmojiPicker/VigEmojiPicker.vue';

const props = defineProps({
  reactionsList: {
    type: Array,
    default: () => [],
  },
});
const emits = defineEmits(['onEmojiClick']);

const currentId = getCurrentUserId();
const myLastReaction = computed(() => {
  const indexLast =
    props?.reactionsList?.filter((el: any) => el?.createdBy === currentId)
      ?.length - 1;

  if (indexLast < 0) return {};
  return props?.reactionsList[indexLast];
});

const buttonReactionCommentTooltipRef = ref();
const isFocusOnTooltip = useElementHover(buttonReactionCommentTooltipRef);
const isCheckChangeEmoji = ref(0);
const isNewCheckChangeEmoji = ref(0);
watch(
  () => props.reactionsList,
  () => {
    isNewCheckChangeEmoji.value++;
    setTimeout(() => {
      isCheckChangeEmoji.value = isNewCheckChangeEmoji.value;
    }, 100);
  }
);

const cardPosition = ref({ x: 0, y: 0 });
const cardTaskSize = {
  width: 240,
  height: 45,
};
const isShowTooltip = ref(false);
let hoverTimer;
const onHoverReactionButton = (event) => {
  hoverTimer && clearTimeout(hoverTimer);
  hoverTimer = setTimeout(() => {
    const targetPosition = event?.target?.getBoundingClientRect();

    if (!targetPosition || !targetPosition.width || !targetPosition.height)
      return;
    cardPosition.value = getDynamicPosition({
      x: targetPosition.left,
      y: targetPosition.top,
      width: cardTaskSize.width,
      height: cardTaskSize.height,
      padding: 10,
    });

    isShowTooltip.value = true;
  }, 250);
};
let leaveTimer;

const onMouseLeaveReactionButton = () => {
  hoverTimer && clearTimeout(hoverTimer);
  leaveTimer && clearTimeout(leaveTimer);
  leaveTimer = setTimeout(() => {
    if (isFocusOnTooltip.value) return;
    isShowTooltip.value = false;
  }, 500);
};

const isOpenVigEmojiPicker = ref(false);
const onMouseLeaveTooltip = () => {
  setTimeout(() => {
    if (isOpenVigEmojiPicker.value || isFocusOnTooltip.value) return;
    isShowTooltip.value = false;
  }, 250);
};

const onClickEmoji = (content) => {
  if (myLastReaction.value && Object.keys(myLastReaction.value)?.length === 0) {
    emits('onEmojiClick', emojisFlash[0]?.emoji);
  } else emits('onEmojiClick', content);
};
</script>

<template>
  <button
    class="
      rounded-full
      flex-center
      hover:bg-current-100 hover:bg-opacity-100
      border
      bg-white
      w-6
      h-6
      positive
    "
    @mouseover="onHoverReactionButton"
    @mouseleave="onMouseLeaveReactionButton"
    @click.stop="onClickEmoji(myLastReaction?.content)"
  >
    <span class="w-4 h-4 flex-center">
      <template
        v-if="myLastReaction && Object.keys(myLastReaction).length === 0"
      >
        <SynIcon name="Heart" custom-class="w-3.5 h-3.5 fill-gray-500" />
      </template>
      <template v-else>
        <span
          v-twemoji="{ size: 'w-4 h-4' }"
          class="emoji-icon leading-none"
          v-text="myLastReaction?.content"
        >
        </span>
      </template>
    </span>
  </button>

  <teleport to="body">
    <div
      v-if="isShowTooltip"
      ref="buttonReactionCommentTooltipRef"
      v-twemoji="{ size: 'w-6 h-6 do-not-close-dropdown' }"
      class="
        absolute
        top-0
        left-0
        z-100
        rounded-full
        shadow
        p-1
        bg-white
        flex-center
        do-not-close-dropdown
      "
      :style="[
        `top: ${cardPosition.y}px`,
        `left: ${cardPosition.x}px`,
        'height: 45px',
        'width: 240px',
      ]"
      @mouseleave="onMouseLeaveTooltip"
    >
      <template v-for="emoji in emojisFlash" :key="emoji">
        <button
          :title="emoji.aliases"
          class="p-1 text-md hover:bg-gray-100 rounded-full hover:scale-125"
          type="button"
          @click.prevent="
            (event) => {
              event?.preventDefault();
              $emit('onEmojiClick', emoji?.emoji);
            }
          "
        >
          {{ emoji?.emoji }}
        </button>
      </template>
      <VigEmojiPicker
        v-if="false"
        toggle-class="w-6 h-6 rounded-full hover:bg-gray-100 flex-center"
        close-on-select
        @on-emoji-click="$emit('onEmojiClick', $event)"
        @on-open="isOpenVigEmojiPicker = true"
        @on-close="
          () => {
            isOpenVigEmojiPicker = false;
            onMouseLeaveTooltip();
          }
        "
      >
        <button
          type="button"
          class="
            p-1
            text-md
            hover:bg-gray-100
            rounded-full
            flex-center
            w-6
            h-6
            text-gray-500
            fill-gray-500
          "
        >
          <syn-icon name="dots-vertical" class="rotate-90"></syn-icon>
        </button>
      </VigEmojiPicker>
    </div>
  </teleport>
</template>

<style>
.emoji-icon img {
  padding: 0;
  margin: 0 !important;
}
</style>
