<template>
  <svg
    id="Group_3065"
    data-name="Group 3065"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.684 25.686"
  >
    <path
      id="Path_1016"
      data-name="Path 1016"
      d="M10.873,130.778l8.274,8.274-5.726,5.726A2.108,2.108,0,1,1,11.1,147.1L8.418,149.78a.419.419,0,0,1-.593,0l-2.242-2.242a.35.35,0,0,1,.118-.573,2.108,2.108,0,1,0-2.744-2.744.35.35,0,0,1-.573.118L.144,142.1a.419.419,0,0,1,0-.593l2.679-2.679A2.108,2.108,0,1,1,5.147,136.5Z"
      transform="translate(-0.021 -124.217)"
      fill="#008d8d"
    />
    <path
      id="Path_1017"
      data-name="Path 1017"
      d="M175.811,130.778l8.274,8.274-2.14,2.14-2.076-2.076a2.955,2.955,0,1,1-4.635-3.657c.047-.055.1-.109.149-.16a2.944,2.944,0,0,1,.362-.307l-2.074-2.074Z"
      transform="translate(-164.96 -124.217)"
      fill="#007f7f"
    />
    <path
      id="Path_1018"
      data-name="Path 1018"
      d="M200.531,11.794a2.1,2.1,0,0,0-1.365-.722L201.842,8.4a.419.419,0,0,0,0-.593l-2.679-2.679A2.108,2.108,0,1,0,196.84,2.8L194.161.123a.419.419,0,0,0-.593,0l-2.242,2.242a.35.35,0,0,0,.118.572A2.108,2.108,0,1,1,188.7,5.681a.35.35,0,0,0-.572-.118L185.591,8.1l2.468,2.468a.419.419,0,0,1-.127.679,2.108,2.108,0,1,0,2.787,2.786.419.419,0,0,1,.679-.126l2.467,2.467,2.972-2.972a2.108,2.108,0,1,0,3.694-1.608Z"
      transform="translate(-176.281 0)"
      fill="#f2cb05"
    />
  </svg>
</template>
