import axios from 'axios';
import { ref } from 'vue';

export const downloadFileWithProgress = (
    url: string,
    cb: (blob, error?) => void
) => {
    const process = ref<any>({
        completedPercent: 0,
        cancelToken: axios.CancelToken.source(),
    });

    (async () => {
        try {
            cb(
                await _downloadFile(
                    url,
                    process.value.cancelToken.token,
                    (progress) => {
                        const total = progress.total;
                        const current = progress.loaded;

                        process.value = {
                            ...process.value,
                            completedPercent: total
                                ? Math.round(((current || 0) / total) * 100)
                                : 0,
                        };
                    }
                )
            );
        } catch (e) {
            if (e instanceof axios.Cancel) {
                cb(null);
            } else {
                cb(null, e);
            }
        }
    })();

    return process;
};

const _downloadFile = async (
    url: string,
    cancelToken: any,
    onDownloadProgress: (p) => any,
    noCache = false
) => {
    try {
        const response = await axios.get(url, {
            responseType: 'blob',
            headers: noCache
                ? {
                      'Cache-Control': 'no-cache',
                      Pragma: 'no-cache',
                      Expires: '0',
                  }
                : {},
            cancelToken,
            onDownloadProgress,
        });

        return response?.data;
    } catch (e) {
        console.log(e);

        // Retry with no-cache
        if (!noCache) {
            return _downloadFile(url, cancelToken, onDownloadProgress, true);
        }

        throw e;
    }
};
