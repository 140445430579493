<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 314.573 330.68"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <circle
        cx="64.224"
        cy="111.216"
        r="22.5"
        transform="rotate(-2.18 64.534 111.7)"
        opacity="1"
        data-original="#000000"
        class=""
      ></circle>
      <path
        d="m127.974 285.399-15.782-75.07a7.778 7.778 0 0 0-.123-.78l-13.5-64.217 13.305-31.14-3.944-3.316c-3.699-3.112-4.935-8.38-3.006-12.81l23.78-54.606a10.993 10.993 0 0 1 4.215-4.906 7.97 7.97 0 0 0-5.749-2.445 8 8 0 0 0-8 8V56.4l-34.68 81.163-33.453 7.032a7.708 7.708 0 0 0-5.418 4.325L1.096 225.023a8 8 0 0 0 13.81 8.08l34.458-58.9 8.873 42.207-9.245 98.305c-.64 6.8 4.355 12.828 11.153 13.467 6.796.64 12.827-4.351 13.466-11.151l8.78-93.346 7.03-1.478 20.307 96.596c1.405 6.684 7.962 10.96 14.644 9.555a12.287 12.287 0 0 0 5.943-3.125 15.251 15.251 0 0 1-2.34-8.112v-31.722zM138.55 75.958l17.707 14.059a25.673 25.673 0 0 1 11.967-10.929l-17.03-13.52v-11.55a7.954 7.954 0 0 0-1.514-4.671 10.95 10.95 0 0 1-.803 2.897z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <circle
        cx="259.718"
        cy="107.94"
        r="22.5"
        transform="rotate(-20.45 259.548 107.996)"
        opacity="1"
        data-original="#000000"
        class=""
      ></circle>
      <path
        d="m241.334 220.363 18.022 100.141c1.21 6.722 7.64 11.187 14.358 9.978 6.72-1.21 11.19-7.636 9.98-14.358l-18.283-101.59 8.478-40.33 25.422 54.917a8.001 8.001 0 0 0 7.264 4.642 7.965 7.965 0 0 0 3.356-.742 7.999 7.999 0 0 0 3.9-10.62l-35.274-76.2a7.715 7.715 0 0 0-5.652-4.883l-35.77-7.52-33.57-26.652c-.019.104-.033.208-.054.312-1.11 5.478-3.966 10.246-7.895 13.806l29.247 23.22-11.81 56.179-24.987 74.405v42.053c0 4.135-1.65 7.885-4.315 10.65a12.22 12.22 0 0 0 3.482.518c5.118 0 9.891-3.233 11.61-8.353l34.035-101.351z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="m185.066 206.358 3.062-15.122 3.594 3.864a8 8 0 0 0 13.76-6.7l-7.43-46.944a7.73 7.73 0 0 0-6.196-7.588l-35.652-7.218-31.475-26.471 21.397-49.132a8.001 8.001 0 0 0-14.67-6.389l-23.78 54.606a8 8 0 0 0 2.185 9.316l34.019 28.611-12.57 62.085a7.74 7.74 0 0 0-.133 1.121c-.125.706-.203 1.43-.203 2.172v114.552c0 6.83 5.538 12.363 12.364 12.363 6.828 0 12.365-5.533 12.365-12.363V212.099l4.635.939V317.12c0 6.83 5.537 12.363 12.363 12.363 6.828 0 12.365-5.533 12.365-12.363V206.358z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M200.57 106.862c2.466-12.18-5.407-24.052-17.588-26.517-12.178-2.466-24.052 5.408-26.517 17.587s5.409 24.052 17.588 26.518c12.18 2.466 24.051-5.408 26.517-17.588zM157.504 26l4.021-17.98-13.892 10.638L141.893 0l-6.483 18.709-11.231-10.544 1.334 13.647-16.428-6.602 9.989 15.688 2.53-1.611-4.973-7.811 12.348 4.962-1.039-10.627 8.789 8.251 4.994-14.41 4.31 14.009 10.763-8.242-3.238 14.475 12.74-2.674-10.234 12.018 8.81 4.36-7.378 2.398.928 2.854 14.38-4.675-11.962-5.92 13.43-15.774z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
