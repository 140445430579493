<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import PerformanceOverview from '@/ui/modules/dashboard/performance/PerformanceOverview.vue';
import DepartmentPerformanceOverview from '@/ui/modules/dashboard/performance/DepartmentPerformanceOverview.vue';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import { AdminType } from '@/ui/common/constants/constant';
import myProfileStore from '@/store/auth/my-profile';
import settingStore from '@/store/setting';

const _settingStore = settingStore();
const _myProfileStore = myProfileStore();

const myDepartment = computed(() => _myProfileStore.myDepartment);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);

const isChecking = ref(false);
const isRenderDepartmentComponent = ref(false);

const initComponent = () => {
  isChecking.value = true;
  if (
    !hasRolePermission(AdminType.Admin) &&
    !hasRolePermission(AdminType.Owner) &&
    !hasRolePermission(AdminType.User)
  )
    useRouter().push({
      name: 'OverviewMembers',
    });

  if (departmentManagementEnabled.value && hasRolePermission(AdminType.Admin)) {
    isRenderDepartmentComponent.value = true;
  }

  isChecking.value = false;
};

initComponent();
</script>

<template>
  <SynPageLoading v-if="isChecking" />
  <template v-else>
    <DepartmentPerformanceOverview
      v-if="false"
      :department-id="myDepartment?.departmentId"
      mode="PAGE"
    />
    <PerformanceOverview />
  </template>
</template>
