<template>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>unarchive</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Icons"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Outlined" transform="translate(-273.000000, -1529.000000)">
        <g id="Content" transform="translate(100.000000, 1428.000000)">
          <g
            id="Outlined-/-Content-/-unarchive"
            transform="translate(170.000000, 98.000000)"
          >
            <g>
              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
              <path
                id="🔹-Icon-Color"
                d="M20.54,5.23 L19.15,3.55 C18.88,3.21 18.47,3 18,3 L6,3 C5.53,3 5.12,3.21 4.84,3.55 L3.46,5.23 C3.17,5.57 3,6.02 3,6.5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,6.5 C21,6.02 20.83,5.57 20.54,5.23 Z M6.24,5 L17.76,5 L18.59,6 L5.42,6 L6.24,5 Z M5,19 L5,8 L19,8 L19,19 L5,19 Z M8,14 L10.55,14 L10.55,17 L13.45,17 L13.45,14 L16,14 L12,10 L8,14 Z"
                fill="currentColor"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
