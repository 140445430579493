<script setup lang="ts">
import { ref } from 'vue';
import userStore from '@/store/user';
import authStore from '@/store/auth';
import GetCode from '@/ui/pages/auth/GetCode.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import BaseNotificationBar from '@/ui/components/notification/BaseNotificationBar.vue';

import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { EBaseNotificationBarKey } from '../notification/const/base-notification-bar.const';

withDefaults(
  defineProps<{
    size?: string;
    isOnSideBar?: boolean;
  }>(),
  {
    size: 'full',
  }
);

const emit = defineEmits(['cancel']);

const notificationBarCaseId = EBaseNotificationBarKey.VERIFY_EMAIL;
const _userStore = userStore();
const _authStore = authStore();
const isVerify = ref(true);
const isOpenCode = ref(false);
const isCodeReady = ref(false);
const email = ref();
const time = ref(60);
email.value = getLocalStorage(StorageConstant.CurrentEmail);
const message = ref('');
const isLoadingSend = ref(false);

const init = async () => {
  email.value = getLocalStorage(StorageConstant.CurrentEmail);
  await _userStore.getUserIdentity();
  email.value = _userStore.userIdentity?.email;
  if (email.value && email.value !== 'null') {
    const res = await _authStore.checkEmail(email.value);
    if (res?.isActivated) {
      isVerify.value = true;
    } else {
      isVerify.value = false;
    }
  }
};
init();

const onSendEmailCode = async () => {
  // handle send email code
  if (msgError.value) {
    msgError.value = '';
  }
  isLoadingSend.value = true;
  const res = await _userStore.sendVerificationUserEmail();
  if (!res?.result) {
    isLoadingSend.value = false;
  } else {
    isOpenCode.value = true;
    startCount();
    isLoadingSend.value = false;
  }
};

const onOpenModalEmailCode = async () => {
  isOpenCode.value = true;
  const res = await _userStore.checkHasSendVerificationUserEmail();
  if (!res?.result) {
    const resSendVerifycation = ref();
    try {
      resSendVerifycation.value = await _userStore.sendVerificationUserEmail();
    } catch (error: any) {
      msgError.value = translate(ErrorNotificationLogin[error?.message]);
    }
  }
};

const codeOTP = ref('');
const getCodeVerify = (code) => {
  codeOTP.value = code;
};

const isLoadSend = ref(false);
const msgError = ref('');
const isVerifySuccess = ref(false);
const onVeriryEmail = async () => {
  isLoadSend.value = true;
  try {
    const res = await _authStore.submitVerificationUserEmailToken({
      c: '',
      code: codeOTP.value,
      email: email.value,
    });

    if (res && !res.verifiedEmail && res.message) {
      isLoadSend.value = false;
      isVerifySuccess.value = false;
      msgError.value = translate(ErrorNotificationLogin[res.value.message]);
    } else {
      isLoadSend.value = false;
      isVerifySuccess.value = true;
      onCancel();
    }
  } catch (error: any) {
    isLoadSend.value = false;
    isVerifySuccess.value = false;
    msgError.value = translate(ErrorNotificationLogin[error?.message]);
  }
};

const isResendCode = ref(true);
const startCount = () => {
  time.value = 60;
  const countDownTime = setInterval(function () {
    if (time.value < 0) {
      clearInterval(countDownTime);
      isResendCode.value = true;
    } else {
      isResendCode.value = false;
      message.value = translate('COMMON_LABEL_COUNT_DOWN_TIME', {
        time: time.value,
      });
    }
    time.value -= 1;
  }, 1000);
};

const onCancel = () => {
  isVerify.value = !isVerify.value;
  emit('cancel', false);
};

const onCancelModal = () => {
  isOpenCode.value = false;
};
</script>

<template>
  <BaseNotificationBar
    v-if="!isVerify && email"
    :is-on-side-bar="isOnSideBar"
    :case-id="notificationBarCaseId"
    @close="onCancel"
  >
    <div
      class="w-full relative text-orange-600 text-sm bg-orange-100 space-x-2"
      :class="isOnSideBar ? 'rounded-md p-2' : 'h-8 flex-center px-2'"
    >
      <SynIcon name="email" custom-class="fill-orange-500 w-4 h-4" />
      <span v-if="size !== 'small'">
        {{
          $t('LOGIN_LABEL_SUB_HEADER_VERIFY_CODE_EMAIL', {
            email: email,
          })
        }}
      </span>
      <span v-else>
        {{ $t('COMMON_LABEL_VERIFY_EMAIL') }}
      </span>
      <span
        v-if="!isResendCode"
        id="countdown_verify"
        class="text-orange-800 hover:text-orange-400"
      >
        {{ message }}
      </span>
      <span
        v-else
        class="cursor-pointer text-orange-800 underline hover:text-orange-400"
        @click="onOpenModalEmailCode()"
        >{{ $t('COMMOM_LABEL_ENTER_CODE') }}
      </span>
      <!-- <span
                              v-else
                              class="cursor-pointer text-orange-800 underline hover:text-orange-400"
                              @click="onSendEmailCode()"
                              >{{
                                !numberOfSendCode
                                  ? $t('COMMON_LABEL_SEND_CODE')
                                  : $t('LOGIN_LABEL_RESEND_CODE')
                              }}
                            </span> -->
      <SynIcon
        v-if="isLoadingSend"
        name="spinner"
        class="animate-spin fill-white w-4 h-4"
      />
    </div>
  </BaseNotificationBar>

  <syn-modal
    v-if="isOpenCode"
    container-class="w-1/3"
    disable-click-outside
    @cancel="onCancelModal()"
  >
    <template #header>
      <span>{{ $t('COMMON_LABEL_VERIFY_EMAIL') }}</span>
    </template>
    <template #body>
      <get-code
        v-if="!isVerifySuccess"
        class="flex"
        type="getcode"
        code-type="email"
        :is-get-code-only="true"
        :email="email"
        :is-loading-resend="isLoadingSend"
        :is-loading="isLoadSend"
        :error-code="msgError"
        @code-ready="(value) => (isCodeReady = value)"
        @change-code="(code) => getCodeVerify(code)"
        @resend-code="onSendEmailCode()"
      ></get-code>
      <div v-else class="flex-center flex-wrap">
        <syn-animation name="success" stype="width:200px;" />
        <div class="w-full text-center text-xl font-medium text-green-600">
          {{ $t('COMMON_LABEL_SUCCESS') }}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <syn-button
          v-if="!isVerifySuccess"
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          :disabled="isLoadSend"
          @click="onCancelModal()"
        >
        </syn-button>
        <syn-button
          :is-loading="isLoadSend"
          :label="
            !isVerifySuccess
              ? $t('COMMON_LABEL_CONFIRM')
              : $t('COMMON_LABEL_DONE')
          "
          :disabled="isLoadSend || !isCodeReady ? true : false"
          @click="!isVerifySuccess ? onVeriryEmail() : onCancelModal()"
        >
        </syn-button>
      </div>
    </template>
  </syn-modal>
</template>
