<script setup lang="ts">
import { computed } from 'vue';
import userStore from '@/embed/store/user-store';
const props = defineProps<{
  userId: string | number;
  isHiddenName?: boolean;
  avatarClass?: string;
}>();

const _userStore = userStore();
const userInfo = computed(() => {
  return getUserInfo(props.userId);
});

const getUserInfo = (userId) => {
  const allUser = _userStore.allUsers;
  if (!allUser) return;

  const index = allUser.findIndex((o) => o.id == userId);
  return index > -1 ? allUser[index] : {};
};
</script>

<template>
  <div
    v-vig-tooltip="{
      content: userInfo?.lastName + ' ' + userInfo?.firstName,
    }"
    class="flex items-center space-x-2 font-sans"
  >
    <span class="relative" :class="avatarClass">
      <syn-avatar
        :src="userInfo?.avatar?.replace('original', 'mini')"
        :custom-class="avatarClass"
        :name="userInfo?.name"
      />
      <!--<span class="w-full h-full absolute bg-gray-100 opacity-50"></span>-->
    </span>
    <div v-if="!isHiddenName" class="flex flex-col text-overflow-hidden-line">
      <span>
        {{ userInfo?.name }}
      </span>
      <slot name="subName"></slot>
    </div>
  </div>
</template>
