<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import BpmnColorPickerModule from 'bpmn-js-color-picker';
import { v4 as uuidv4 } from 'uuid';
// import Modeler from 'bpmn-js/lib/Modeler';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer';
import {
  EVENT_BUS_KEYS,
  CUSTOM_TRANSLATE,
  ORIGINAL_DATA_XML,
} from '@/ui/common/plugins/diagram/bpmn.const';
import delayExecuteSomething from '@/ui/composables/app/delay-execute-something';
import CustomShapeRenderer from '@/ui/common/plugins/diagram/custom';

const props = defineProps<{
  drawData: string;
  readonly?: boolean;
  isSingleFlow?: boolean;
}>();
const emit = defineEmits<{
  (e: 'shapeAdd', data: any): void;
  (e: 'shapeChanged', data: any): void;
  (e: 'shapeRemoved', data: any): void;
  (e: 'connectionRemoved', data: any): void;
  (
    e: 'connectionChanged',
    data: {
      connectionId: string;
      connectionName: string;
      startId: string;
      targetId: string;
    }
  ): void;
  (e: 'drawDataChanged', data: string): void;
  (e: 'selectionChanged', data: string[]): void;
  (e: 'ready'): void;
}>();

const containerRef = ref<any>(null);
let bpmnModeler, bpmnModeling;

onMounted(async () => {
  if (props.readonly)
    bpmnModeler = new BpmnViewer({
      container: containerRef.value,
      keyboard: {
        bindTo: containerRef.value,
      },
      additionalModules: [
        CustomShapeRenderer,
        // BpmnColorPickerModule,
        CUSTOM_TRANSLATE,
      ],
    });
  else
    bpmnModeler = new BpmnModeler({
      container: containerRef.value,
      keyboard: {
        bindTo: containerRef.value,
      },
      additionalModules: [
        CustomShapeRenderer,
        BpmnColorPickerModule,
        CUSTOM_TRANSLATE,
      ],
    });
  // bpmnModeler.get('keyboard').bind(window);
  await bpmnModeler.importXML(props.drawData || ORIGINAL_DATA_XML);

  const canvas = bpmnModeler.get('canvas');

  canvas.zoom('fit-viewport', 'auto');
  canvas.zoom(1, { x: 0, y: 'auto' });

  if (props.readonly) initViewer();
  else initModeler();

  setTimeout(() => {
    emit('ready');
  }, 1000);
});

const initModeler = () => {
  const eventBus: any = bpmnModeler.get('eventBus');
  bpmnModeling = bpmnModeler.get('modeling');

  eventBus.on(
    EVENT_BUS_KEYS.COMMAND_STACK_SHAPE_CREATE_POST_EXECUTED,
    (event) => {
      const el = event?.context?.shape;

      if (el && el?.type !== 'label') {
        const originalId = el?.id?.replace('Activity_', '');
        const newId = uuidv4();
        bpmnModeling.updateProperties(el, {
          id: _generateId(newId),
          di: {
            id: `Activity_${newId}_di`,
          },
        });

        emit('shapeAdd', {
          id: newId,
          originalId,
        });

        // bpmnModeler.invoke(function (commandStack) {
        //   console.log('🚀 Tictop ~ commandStack:', commandStack);
        // });

        updateDrawData();
      }
    }
  );

  eventBus.on(EVENT_BUS_KEYS.SHAPE_CHANGED, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);

    emit('shapeChanged', {
      ...event?.element?.businessObject,
      color: {
        background: event?.element?.di['background-color'],
        border: event?.element?.di['border-color'],
      },
      id: event?.element?.id?.replace('Activity_', ''),
    });
    updateDrawData();
  });
  eventBus.on(EVENT_BUS_KEYS.SHAPE_REMOVED, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);
    emit('shapeRemoved', {
      ...event?.element,
      id: event?.element?.id?.replace('Activity_', ''),
    });
    updateDrawData();
  });

  eventBus.on(EVENT_BUS_KEYS.CONNECTION_CHANGED, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);

    const element = event?.element?.businessObject;
    emit('connectionChanged', {
      connectionId: element?.id,
      connectionName: element?.name || '',
      startId: element?.sourceRef?.id?.replace('Activity_', ''),
      targetId: element?.targetRef?.id?.replace('Activity_', ''),
    });
    updateDrawData();
  });

  eventBus.on(EVENT_BUS_KEYS.CONNECTION_REMOVED, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);
    emit('connectionRemoved', {
      connectionId: event?.element?.id,
    });
    updateDrawData();
  });
  eventBus.on(EVENT_BUS_KEYS.CONNECTION_CHANGED, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);

    const element = event?.element?.businessObject;
    emit('connectionChanged', {
      connectionId: element?.id,
      connectionName: element?.name || '',
      startId: element?.sourceRef?.id?.replace('Activity_', ''),
      targetId: element?.targetRef?.id?.replace('Activity_', ''),
    });
    updateDrawData();
  });
  eventBus.on(EVENT_BUS_KEYS.SELECTION_CHANGED, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);

    // const element = event?.element?.businessObject;
    emit(
      'selectionChanged',
      event?.newSelection?.reduce((currentArray, currentItem) => {
        let result: string[] = [];
        if (currentItem?.type?.includes('Task'))
          result.push(currentItem?.id?.replace('Activity_', ''));
        else if (currentItem?.type?.includes('SequenceFlow'))
          result = [
            currentItem?.source?.id?.replace('Activity_', ''),
            currentItem?.target?.id?.replace('Activity_', ''),
          ];
        return [...currentArray, ...result];
      }, [])
    );
    // updateDrawData();
  });
  // eventBus.on(EVENT_BUS_KEYS.KEYBOARD_KEYDOWN, (event) => {
  //   console.log('🚀 EventBus - ' + event?.type + ':', event);
  // const commandStack = bpmnModeler.get('commandStack');
  // console.log(
  //   '🚀 Tictop ~ commandStack:',
  //   commandStack,
  //   commandStack.canUndo()
  // );
  // commandStack.undo();
  // });
  eventBus.on(EVENT_BUS_KEYS.COMMAND_STACK_REVERT, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);

    if (
      event?.command == 'shape.delete' &&
      event?.context?.shape?.type == 'bpmn:Task'
    ) {
      emit('shapeAdd', {
        id: event?.context?.shape?.id?.replace('Activity_', ''),
      });
    }
    if (
      event?.command == 'shape.create' &&
      event?.context?.shape?.type == 'bpmn:Task'
    ) {
      emit('shapeRemoved', {
        id: event?.context?.shape?.id?.replace('Activity_', ''),
        isUndo: true,
      });
    }
  });
  eventBus.on(EVENT_BUS_KEYS.COMMAND_STACK_EXECUTED, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);

    // if (event?.command == 'shape.delete') {
    //   emit('shapeAdd', {
    //     id: event?.context?.shape?.id?.replace('Activity_', ''),
    //   });
    // }
    if (event?.command == 'shape.create') {
      emit('shapeAdd', {
        id: event?.context?.shape?.id?.replace('Activity_', ''),
        isRedo: true,
      });
    }
  });
  eventBus.on(EVENT_BUS_KEYS.CONTEXT_PAD_OPEN, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);
    if (!props.isSingleFlow || !event || !event?.current?.html) return;

    const currentShape = event?.current?.target;
    if (currentShape?.outgoing?.length > 0) {
      const contextPadElm = event?.current?.html;

      contextPadElm?.childNodes.forEach((child) => {
        contextPadElm?.removeChild(child);
      });
    }
  });
};
const initViewer = () => {
  const eventBus: any = bpmnModeler.get('eventBus');

  eventBus.on(EVENT_BUS_KEYS.SELECTION_CHANGED, (event) => {
    // console.log('🚀 EventBus - ' + event?.type + ':', event);

    // const element = event?.element?.businessObject;
    emit(
      'selectionChanged',
      event?.newSelection?.reduce((currentArray, currentItem) => {
        let result: string[] = [];
        if (currentItem?.type?.includes('Task'))
          result.push(currentItem?.id?.replace('Activity_', ''));
        else if (currentItem?.type?.includes('SequenceFlow'))
          result = [
            currentItem?.source?.id?.replace('Activity_', ''),
            currentItem?.target?.id?.replace('Activity_', ''),
          ];
        return [...currentArray, ...result];
      }, [])
    );
    // updateDrawData();
  });
};

const exportDiagram = async () => {
  try {
    var result = await bpmnModeler.saveXML({ format: true });

    return result.xml;
  } catch (err) {
    console.error('could not save BPMN 2.0 diagram', err);
  }
};

const { handleDelayExecuteSomeThings } = delayExecuteSomething();
const updateDrawData = () => {
  handleDelayExecuteSomeThings(Date.now(), async () => {
    const xlmString = await exportDiagram();
    emit('drawDataChanged', xlmString);
  });
};

const handleChangeShape = (shape: {
  id: string;
  name: string;
  code: string;
  isStart: boolean;
  isInit?: boolean;
}) => {
  // console.log('🚀 Tictop ~ shape:', shape);
  const elementRegistry = bpmnModeler.get('elementRegistry');

  const element = elementRegistry.get(_generateId(shape?.id));

  bpmnModeling.updateProperties(element, {
    name: shape.name,
    code: shape.code,
    status: shape.status,
    isStart: shape.isStart,
    di: {
      name: shape.name,
      code: shape.code,
      status: shape.status,
      isStart: shape.isStart,
    },
  });

  updateDrawData();
};
const handleClearUndoHistories = () => {
  try {
    bpmnModeler.invoke((commandStack) => {
      commandStack.clear();
    });
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  }
};
const handleFocusShape = (shapeId) => {
  //   // console.log('🚀 Tictop ~ shape:', shape);
  const elementRegistry = bpmnModeler.get('elementRegistry');

  const element = elementRegistry.get(_generateId(shapeId));

  bpmnModeler.get('selection').select(element);

  //   updateDrawData();
};

const handleRemoveShape = (shape: { id: string; name: string }) => {
  const elementRegistry = bpmnModeler.get('elementRegistry');

  const element = elementRegistry.get(_generateId(shape?.id));
  if (!element) return false;

  bpmnModeling.removeElements([element]);

  updateDrawData();
  return true;
};
const handleRemoveConnection = (connection: { id: string; name: string }) => {
  const elementRegistry = bpmnModeler.get('elementRegistry');

  const element = elementRegistry.get(connection?.id);

  bpmnModeling.removeElements([element]);

  updateDrawData();
};

const _generateId = (id) => {
  return `Activity_${id}`;
};

onUnmounted(async () => {
  const xlmString = await exportDiagram();
  emit('drawDataChanged', xlmString);
});

const canUndo = () => {
  return bpmnModeler.get('commandStack')._getUndoAction();
};
const handleUndo = () => {
  bpmnModeler.get('commandStack').undo();
};
const handleRedo = () => {
  bpmnModeler.get('commandStack').redo();
};

defineExpose({
  exportDiagram,
  handleChangeShape,
  handleRemoveShape,
  handleFocusShape,
  handleRemoveConnection,
  handleUndo,
  handleRedo,
  canUndo,
  handleClearUndoHistories,
});
</script>
<template>
  <div
    ref="containerRef"
    class="
      w-full
      h-full
      focus:outline-none
      focus-visible:outline-none
      bg-gray-50
      rounded-md
    "
    tabindex="0"
  ></div>
</template>
<style>
@import 'bpmn-js/dist/assets/bpmn-js.css';
@import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
@import 'bpmn-js/dist/assets/diagram-js.css';
@import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
@import 'bpmn-js-color-picker/colors/color-picker.css';
.djs-container {
  height: 100%;
  padding: 0;
  margin: 0;
  @apply rounded-md;
}

.entry[data-action='hand-tool'],
.entry[data-action='lasso-tool'],
.entry[data-action='space-tool'],
.entry[data-action='append.intermediate-event'],
.entry[data-action='create.subprocess-expanded'],
.entry[data-action='create.data-store'],
.entry[data-action='create.participant-expanded'],
.entry[data-action='create.group'],
.entry[data-action='append.text-annotation'],
.entry[data-action='replace'],
.entry[data-action='create.data-object'],
.entry[data-action='create.intermediate-event'],
.entry[data-action='create.start-event'],
.entry[data-action='create.end-event'],
.entry[data-action='create.exclusive-gateway'],
.entry[data-action='append.end-event'],
.entry[data-action='append.gateway'],
.entry[data-action='global-connect-tool'],
.group[data-group='tools'],
.bjs-powered-by {
  display: none;
}
.group[data-group='event'],
.group[data-group='gateway'] {
  @apply flex flex-col;
}
.djs-palette.two-column.open {
  width: 48px;
  @apply border border-gray-300 rounded-md;
}

.djs-context-pad.open {
  @apply flex w-max;
}
</style>
