import ShapeEntity from './ShapeEntity';

export default class ShapeMediaEntity extends ShapeEntity {
    sourceData?: any;
    constructor(data) {
        super(data);
        this.sourceData =
            typeof data?.sourceData == 'string'
                ? JSON.parse(data?.sourceData)
                : data?.sourceData;
    }
}
