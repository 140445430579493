<script setup lang="ts">
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { isSameId } from '@/ui/helpers/utils';

const props = defineProps<{
  message: any;
  isMyMessage: boolean;
  conversation: any;
}>();

defineEmits(['onTaskClick', 'onTaskMouseOver', 'onTaskMouseLeave']);

const myUserId = myProfileStore().myProfile?.id;

const createdTasks = computed(() =>
  props.conversation?.externalChat || props.conversation?.supportingChat
    ? props.message?.createdTasks?.filter((task) =>
        isSameId(task?.createdBy, myUserId)
      )
    : props.message?.createdTasks
);
</script>
<template>
  <div v-if="createdTasks?.length" class="flex flex-col mt-0.5">
    <div
      class="text-xs"
      @click="$emit('onTaskClick', $event)"
      @mouseover="$emit('onTaskMouseOver', $event)"
      @mouseleave="$emit('onTaskMouseLeave', $event)"
    >
      <span class="text-gray-400"
        >{{ $t('CHAT_USED_TO_CREATED_TASK') }}&nbsp;</span
      >

      <template v-for="(task, index) in createdTasks" :key="task?.taskId">
        <a
          target="_blank"
          data-type="task"
          :href="'/tasks/' + task?.taskCode"
          rel="noopener noreferrer"
          class="text-current-500"
          >#{{ formatTaskCode(task?.taskCode) }}</a
        >
        <span v-if="index < createdTasks?.length - 1">, </span>
      </template>
    </div>
  </div>
</template>
