<script setup lang="ts">
import { computed } from 'vue';
import chatStore from '@/store/chat';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import GroupNotificationStatus from '@/ui/modules/group/detail/GroupNotificationStatus.vue';

const props = defineProps<{
  conversationId: string;
}>();

const _chatStore = chatStore();
const _notificationSettingStore = notificationSettingStore();

const muteInformation = computed(
  () => _chatStore.mutedConversations[props.conversationId]
);

const enableSettingNotification = (id) => {
  _notificationSettingStore.enableSettingNotification(id);
};
</script>

<template>
  <div
    v-if="muteInformation?.offTime > Date.now()"
    class="flex-center space-x-2 text-sm bg-gray-100 border-b py-1 px-2"
  >
    <SynIcon name="notification-off" class="fill-gray-600" />
    <GroupNotificationStatus
      :notification-setting="muteInformation"
      class="text-sm"
    />

    <SynButton
      type-outline
      class="text-sm"
      custom-class="h-6 text-sm rounded"
      color="orange"
      @click="enableSettingNotification(muteInformation?.muteId)"
    >
      <span class="text-sm font-normal">
        {{ $t('CHAT_CONVERS_NOTIF_REOPEN') }}
      </span>
    </SynButton>
  </div>
</template>
