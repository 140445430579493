export interface IPermissionByScreen {
    pageAndScreenCode: string;
    pageAndScreenName: string;
    id: number;
    stateCode: string;
    permission: EScreenPermission;
    limitation: null;
    limitationUnit: null;
    stateName: string;
    planId: null;
}
export enum EScreenPermission {
    NOT_SET = 'NOT_SET',
    LOW_VISION = 'LOW_VISION',
    SHOW = 'SHOW',
    HIDDEN = 'HIDDEN',
}
export enum EFunctionPermission {
    ALLOW = 'ALLOW',
    WARNING = 'WARNING',
    BLOCK = 'BLOCK',
    LIMITED = 'LIMITED',
    UN_SET = 'UN_SET',
    HIDDEN = 'HIDDEN',
}

export interface IPermissionByFunction {
    functionCode: string;
    functionName: string;
    id: number;
    stateCode: string;
    permission: null;
    limitation: null;
    limitationUnit: null;
    stateName: string;
    planId: null;
}
