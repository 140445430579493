<script setup lang="ts">
import { onMounted, ref } from 'vue';
import AvatarSelect from '@/ui/modules/avatars/ModalSetAvatar/AvatarSelect.vue';
import AvatarCrop from '@/ui/modules/avatars/ModalSetAvatar/AvatarCrop.vue';

const props = defineProps<{
  modalTitle: string;
  currentAvatar?: any;
  avatarType?: 'USER' | 'GROUP';
}>();

const emits = defineEmits(['onClose', 'onConfirm', 'onDelete']);

const avatarsList = ref<any[]>([]);
const selectedAvatarUpload = ref<any>();
const selectedDefaultImage = ref<any>();
const uploadAvatarCropRef = ref();
const defaultImgCropRef = ref();

const avatarTabs = ref({
  UPLOAD_PHOTO: {
    key: 'UPLOAD_PHOTO',
    label: 'PHOTO_LABEL_UPLOAD_PHOTO',
    isActive: true,
  },
  CHOOSE_PHOTO: {
    key: 'CHOOSE_PHOTO',
    label: 'PHOTO_LABEL_CHOOSE_PHOTO',
    isActive: false,
  },
});

onMounted(() => {
  if (props.currentAvatar) {
    avatarsList.value = [props.currentAvatar];

    selectedAvatarUpload.value = avatarsList.value[0];
  }
});

const onTabChange = (tab) => {
  for (let t in avatarTabs.value) {
    avatarTabs.value[t].isActive = t === tab;
  }
};

const onAvatarUpload = (newImageUrl) => {
  avatarsList.value = [...avatarsList.value, newImageUrl];
};

const onDeleteAvatar = () => {
  emits('onDelete');
};

const onConfirmClick = async () => {
  if (avatarTabs.value['CHOOSE_PHOTO']?.isActive) {
    const avatar = await defaultImgCropRef.value?.getCroppedAvatar();
    return emits('onConfirm', avatar);
  }

  const avatar = await uploadAvatarCropRef.value?.getCroppedAvatar();
  emits('onConfirm', avatar);
};
</script>

<template>
  <syn-modal
    z-index="z-80"
    container-class="h-3/5 w-1/3"
    container-style="min-width: 50rem"
    disable-click-outside
    is-hidden-header
    disable-esc-to-close
    style-body="p-0 overflow-hidden"
    @cancel="$emit('onClose', false)"
  >
    <template #body>
      <SynTabs
        :option-status="avatarTabs"
        positon="between"
        style-tab="padding: 10px 20px 0px 20px;"
        class="overflow-hidden"
        is-header
        @change-tab="(key) => onTabChange(key)"
      >
        <template #header>
          <span class="font-medium text-lg">
            {{ modalTitle || 'Set avatar' }}</span
          >
        </template>
        <template #tabBody>
          <!-- UPLOAD PHOTO -->
          <section
            v-if="avatarTabs['UPLOAD_PHOTO']?.isActive"
            class="flex-1 overflow-hidden flex"
          >
            <div class="flex-1 overflow-auto small-scrollbar px-4">
              <AvatarSelect
                v-model:selected-avatar="selectedAvatarUpload"
                :avatars-list="avatarsList"
                :can-upload="true"
                @on-avatar-upload="onAvatarUpload"
              />
            </div>

            <div class="flex-1 overflow-hidden pr-4">
              <AvatarCrop
                ref="uploadAvatarCropRef"
                :avatar="selectedAvatarUpload"
              />
            </div>
          </section>

          <!-- CHOOSE PHOTO -->
          <section
            v-if="avatarTabs['CHOOSE_PHOTO']?.isActive"
            class="flex-1 overflow-hidden flex"
          >
            <div class="flex-1 overflow-auto small-scrollbar px-4">
              <AvatarSelect
                v-model:selected-avatar="selectedDefaultImage"
                :default-image-type="avatarType"
              />
            </div>

            <div class="flex-1 overflow-hidden pr-4">
              <AvatarCrop
                ref="defaultImgCropRef"
                :avatar="selectedDefaultImage"
              />
            </div>
          </section>
        </template>
      </SynTabs>
    </template>

    <template #footer>
      <div class="px-4 py-2 flex justify-between items-center gap-2">
        <VigButton
          v-if="currentAvatar"
          ghost
          color="red"
          @click="onDeleteAvatar"
        >
          <SynIcon name="trash" />
          <span>{{ $t('COMMON_LABEL_DELETE_AVATAR') }}</span>
        </VigButton>
        <VigButton light color="gray" class="ml-auto" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') }}
        </VigButton>
        <VigButton @click="onConfirmClick">
          {{ $t('COMMON_LABEL_DONE') }}
        </VigButton>
      </div>
    </template>
  </syn-modal>
</template>
