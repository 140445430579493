<script setup lang="ts">
import { GroupMemberRole } from '@/ui/common/constants/constant';
import { AdminType } from '@/ui/common/constants/constant';
import getUserInfo from '@/ui/helpers/user-helper';
import { computed, onMounted } from 'vue';

const props = withDefaults(
  defineProps<{
    users?: any[];
    usersId?: any[];
    groups?: any[];
    type?: string;
    customClass?: string;
    maxLength?: number;
    isSlotNumber?: boolean;
    slotNumberKey?: string;
    trigger?: string;
    canBeRemoved?: boolean;
  }>(),
  {
    type: 'USER',
    customClass: 'w-8 h-8',
    maxLength: 3,
    isSlotNumber: false,
    slotNumberKey: '',
    trigger: 'click',
  }
);

const emit = defineEmits(['removeItem']);

const usersList = computed(() => {
  if (props.usersId && !props.users) {
    return props.usersId?.map((el) => getUserInfo(el));
  }
  return props.users && props.users?.length > 0
    ? props.users?.map((user) => {
        return {
          ...getUserInfo(user?.userId || user?.memberId || user?.id),
          ...user,
        };
      })
    : [];
});

const membersInfo = computed(() => {
  if (props.type == 'USER') {
    return usersList.value?.map((user) => {
      if (!user) return {};
      return {
        id: user?.id || user?.userId || user.memberId,
        name: user?.fullName
          ? user?.fullName
          : user?.lastName && user?.firstName
          ? `${user?.lastName} ${user?.firstName}`
          : '',
        avatarUrl: (
          user?.avatarUrl ||
          user?.profilePictureThumbsUrl ||
          user?.avatarThumbnailUrl
        )?.replace('original', 'mini'),
        adminType: user?.adminType || '',
        role: user.role || '',
        isDeactive: !!user?.isDeactive,
        ...user,
      };
    });
  }

  return props.groups?.map((group) => {
    if (!group) return {};
    return {
      id: group?.id || group?.groupId,
      name: group.name,
      avatarUrl: (group?.avatarUrl || group?.avatarThumbnailUrl)?.replace(
        'original',
        'mini'
      ),
      isPrivate: group?.isPrivate || false,
      isMember: group?.isMember || false,
      ...group,
    };
  });
});

const memberOutside = computed(() =>
  membersInfo.value?.length <= props.maxLength
    ? membersInfo.value
    : (membersInfo.value || [])?.slice(0, props.maxLength - 1)
);

const memberDropdown = computed(() =>
  membersInfo.value?.length <= props.maxLength
    ? []
    : (membersInfo.value || [])?.slice(
        props.maxLength - 1,
        membersInfo.value?.length
      )
);

const onRemoveUser = (user) => {
  emit('removeItem', user);
};

onMounted(() => {});
</script>

<template>
  <div v-if="memberOutside?.length > 0" class="flex">
    <div
      v-for="contact in memberOutside"
      :key="contact?.id"
      v-vig-tooltip="{
        arrow: true,
        content: !contact?.name
          ? $t('COMMON_LABEL_DELETED_USER')
          : `
          ${contact?.name || contact?.fullName}
          ${
            contact?.adminType === AdminType.Visitor
              ? '(' + $t('COMMON_LABEL_VISITOR') + ')'
              : ''
          }
          ${
            contact?.role === GroupMemberRole.Admin
              ? '(' + $t('COMMON_LABEL_ADMIN') + ')'
              : ''
          }  `,
      }"
      class="-mr-1 flex-center flex-col relative hover-to-show__parent"
    >
      <SynAvatar
        :src="contact?.avatarUrl || contact?.avatarThumbnailUrl"
        :custom-class="`${customClass} border border-white`"
        :name="contact?.name || contact?.fullName"
        :is-deactive="contact?.isDeactive"
      />
      <button
        v-if="canBeRemoved"
        class="
          w-3.5
          h-3.5
          rounded-full
          flex-center
          cursor-pointer
          bg-current-50
          hover:bg-current-100
          hover-to-show__children
          absolute
          top-0
          right-0
          z-10
        "
        @click="onRemoveUser(contact)"
      >
        <SynIcon name="Close" custom-class="w-3 h-3 fill-gray-600" />
      </button>
      <div v-if="isSlotNumber" class="h-6 flex flex-col">
        <span
          v-if="slotNumberKey && contact[slotNumberKey] > 0"
          class="
            rounded-full
            bg-current-50
            text-current-600
            px-1
            py-0.5
            text-xs
            mt-1
            w-max
          "
          >{{ contact[slotNumberKey] || 0 }}</span
        >
      </div>
    </div>
    <div v-if="memberDropdown.length" class="flex cursor-pointer relative">
      <vig-dropdown :trigger="trigger">
        <template #dropdown-toggle>
          <div
            class="
              rounded-full
              bg-gray-200
              text-gray-700
              font-medium
              flex
              justify-center
              items-center
              cursor-pointer
              p-0.5
              shadow
            "
            style="font-size: 70%"
            :class="`${customClass} ${
              memberDropdown?.length > 10 ? 'min-w-max' : ''
            }`"
          >
            +{{ memberDropdown?.length }}
          </div>
        </template>
        <template #dropdown-menu>
          <div
            class="
              w-full
              bg-white
              rounded-md
              h-max
              max-h-48
              overflow-y-auto
              small-scrollbar
              px-0
            "
          >
            <div
              v-for="contact in membersInfo"
              :key="contact?.id"
              class="
                border-b border-gray-50
                py-2
                px-2
                flex
                items-center
                space-x-2
                relative
                hover-to-show__parent
              "
            >
              <syn-avatar
                class="text-current-800 justify-start text-xs"
                :src="
                  contact?.avatarUrl?.replace('original', 'mini') ||
                  contact?.avatarThumbnailUrl?.replace('original', 'mini')
                "
                :name="contact?.name || contact?.fullName || 'No name'"
                :is-deactive="contact?.isDeactive"
                custom-class="w-5 h-5"
              ></syn-avatar>
              <div class="text-xs flex items-center space-x-1">
                <span v-if="contact?.name" class="font-medium">
                  {{ contact?.fullName || contact?.name || 'User not active' }}
                </span>
                <span v-else class="font-medium text-gray-500">
                  {{ $t('COMMON_LABEL_DELETED_USER') }}
                </span>
                <span
                  v-if="contact?.adminType === AdminType.Visitor"
                  class="italic text-2xs"
                  >{{ '(' + $t('COMMON_LABEL_VISITOR') + ')' }}</span
                >
                <span
                  v-if="contact.role === GroupMemberRole.Admin"
                  class="italic text-2xs text-gray-400"
                  >{{ '(' + $t('COMMON_LABEL_ADMIN') + ')' }}
                </span>
              </div>
              <button
                v-if="canBeRemoved"
                class="
                  w-3
                  h-3
                  rounded-full
                  flex-center
                  cursor-pointer
                  bg-current-50
                  hover:bg-current-100
                  hover-to-show__children
                  absolute
                  top-0
                  right-0
                  z-10
                "
                @click="onRemoveUser(contact)"
              >
                <SynIcon name="close" custom-class="w-2 h-2 " />
              </button>
            </div>
          </div>
        </template>
      </vig-dropdown>
    </div>
  </div>
</template>
<style scoped>
.min-h-4 {
  min-height: 4rem; /* 64px */
}

.min-h-7 {
  min-height: 7rem;
}

.min-h-10 {
  min-height: 10rem;
}
</style>
