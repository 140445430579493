<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  history: any;
}>();

const beforeUpdate = computed<any>(() => {
  // console.log('🚀 Tictop ~ props.activity:', props.activity);
  return covertSnakeCaseToCamelCase(props.history?.beforeUpdate);
});
const afterUpdate = computed<any>(() => {
  return covertSnakeCaseToCamelCase(props.history?.afterUpdate);
});

const isEnableCase = computed<any>(() => {
  return (
    !beforeUpdate.value?.approvalEnabled && afterUpdate.value?.approvalEnabled
  );
});
const isChangeCase = computed<any>(() => {
  return (
    beforeUpdate.value?.approvalEnabled && afterUpdate.value?.approvalEnabled
  );
});
const isCancelCase = computed<any>(() => {
  return !afterUpdate.value?.approvalEnabled;
});

onMounted(() => {
  //   console.log('🚀 Tictop ~ history 1 212 21:', props.history);
});
</script>
<template>
  <span class="text-gray-950 text-overflow-hidden-line">
    <span v-if="isEnableCase">
      {{ $t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_ADD_APPROVER') }}
    </span>
    <span v-else-if="isChangeCase">
      {{ $t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_CHANGED') }}
    </span>
    <span v-else-if="isCancelCase">
      {{ $t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST_CANCEL') }}
    </span></span
  >
</template>
