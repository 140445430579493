<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { getWishByStartHour } from '@/ui/hooks/commonHook';
import appStore from '@/store/app';
import checkinStore from '@/store/principle';
import TaskService from '@/application/services/task/TaskService';
import myProfileStore from '@/store/auth/my-profile';

const props = defineProps({
  isLoading: {
    default: false,
    type: Boolean,
  },
});

defineEmits(['onNext', 'cancel']);

const _appStore = appStore();
const _checkinStore = checkinStore();
const _taskService = TaskService.getInstance();

const startLocalTime = computed(() => _checkinStore.startLocalTime);
const imageWel = computed(() => _appStore.welcomeImage);
const getHour: any = inject('getHour');
const timezone = computed(() => myProfileStore().getTimezone);

const userProfile = computed(() => myProfileStore().myProfile);
const hourStart = computed(() => getHour(startLocalTime.value, timezone.value));
const isLoadingCheckin = computed(() => props.isLoading);
const activedTasks = ref(0);
const completedTasks = ref(0);
const overdueTasks = ref(0);
const quoteNumber = Math.floor(Math.random() * 14);
onMounted(async () => {
  const res: any = await _taskService.getTaskOverviewStatistic();

  const data = res?.result;

  activedTasks.value = data?.activedTasks;
  completedTasks.value = data?.completedTasks;
  overdueTasks.value = data?.overdueTasks;
});

const overviewTask = computed(() => {
  return {
    ACTIVE_TASK: {
      key: 'ACTIVE_TASK',
      icon: 'ListTask',
      color: 'current',
      bgColor: 'current',
      label: 'LABEL_HEADER_TASK_ACTIVE',
      data: activedTasks.value,
    },
    FINISHED_TASK: {
      key: 'FINISHED_TASK',
      data: completedTasks.value,
      label: 'LABEL_HEADER_TASK_FINISHED',
      color: 'green-400',
      bgColor: 'green',
      icon: 'CircleCheckbox',
    },
    OVERDUE_TASK: {
      key: 'OVERDUE_TASK',
      data: overdueTasks.value,
      label: 'LABEL_HEADER_TASK_OVERDUE',
      color: 'red-400',
      bgColor: 'red',
      icon: 'OverdueTask',
    },
  };
});
</script>

<template>
  <SynModal
    is-hidden-header
    is-hidden-footer
    z-index="z-40"
    style-body=""
    container-style="width: 850px; height: 80%; max-height: 600px"
    has-element-active
    @cancel="$emit('cancel')"
  >
    <template #body>
      <div
        class="
          overflow-y-auto
          shadow-lg
          bg-white
          mx-auto
          rounded-md
          flex flex-col
          relative
          h-full
          w-full
        "
        style="max-width: 2000px"
        :style="{
          'background-image': `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.35)), url(${imageWel})`,
          'background-size': '100% 100%',
        }"
      >
        <div
          class="w-full h-full bg-current-800 bg-opacity-5 absolute top-0"
        ></div>
        <div
          class="
            relative
            p-6
            h-full
            max-h-full
            overflow-y-auto
            flex-grow
            welcome-body
          "
        >
          <div class="grid grid-cols-3 relative" style="z-index: 1">
            <div
              class="
                w-full
                flex-col
                font-roboto
                space-y-4
                bg-current-100
                py-4
                px-4
                bg-opacity-10
                rounded-md
              "
              style="backdrop-filter: blur(4px)"
            >
              <!-- <div>{{ $filters.dayjs(new Date(), 'MMM,YYYY')}}</div> -->
              <div class="w-full flex flex-col items-center">
                <span class="text-current-600 uppercase font-thin">
                  {{ $filters.dayjs(new Date(), 'MMMM, YYYY') }}
                </span>
                <span
                  class="2xl:text-9xl text-6xl font-bold"
                  style="
                    color: #fff;
                    letter-spacing: -0.05em;
                    text-shadow: 4px 0 #008e8e;
                  "
                  >{{ $filters.dayjs(new Date(), 'DD') }}
                </span>
                <span
                  class="
                    2xl:text-2xl
                    text-xl
                    font-thin
                    text-current-500
                    capitalize
                  "
                  >{{ $filters.dayjs(new Date(), 'dddd') }}</span
                >
              </div>

              <div class="flex justify-center rounded-xl text-center italic">
                <div class="2xl:text-lg text-sm text-current-600">
                  <span class="2xl:text-2xl text-sm text-current-800 h-2">
                    “
                  </span>
                  {{
                    $t(`HEADER_LABEL_WELCOME_QUOTE_${quoteNumber}`) ||
                    $t(`HEADER_LABEL_WELCOME_QUOTE_1`)
                  }}
                  <span class="2xl:text-2xl text-sm text-current-800 h-2">
                    ”
                  </span>
                </div>
              </div>
            </div>

            <!-- user profile, task -->
            <div
              class="
                w-full
                col-span-2
                flex-col
                items-center
                justify-center
                space-y-4
                m-auto
                text-center text-current-600
              "
            >
              <div class="2xl:w-40 2xl:h-40 m-auto w-28 h-28">
                <syn-avatar
                  class="cursor-pointer text-current-800"
                  :src="userProfile?.avatarUrl"
                  :name="userProfile?.firstName"
                  size="small"
                  custom-class="2xl:w-40 2xl:h-40 w-28 h-28 text-5xl"
                />
              </div>
              <div class="2xl:text-3xl font-medium text-lg">
                {{ $t('HEADER_LABEL_WELCOME_HELLO') }},
                {{
                  `${userProfile?.lastName ? userProfile?.lastName : ''} ${
                    userProfile?.firstName ? userProfile?.firstName : ''
                  }`
                }}
              </div>
              <div>
                <span class="text-gray-700">
                  {{ $t(getWishByStartHour(hourStart)) || 'Have a nice day!' }}
                </span>
              </div>
              <div class="pt-8 flex-center items-center w-full">
                <div class="flex-center min-w-max space-x-2">
                  <div
                    v-for="item in Object.values(overviewTask)"
                    :key="item"
                    :class="`flex items-center space-x-2 rounded-md bg-opacity-70 bg-${item?.bgColor}-50 py-4 pr-4 pl-2`"
                  >
                    <div
                      :class="`flex-center w-8 h-8 rounded-full bg-${item?.color}`"
                    >
                      <SynIcon
                        :name="item?.icon"
                        is-active
                        custom-class="w-3.5 h-3.5 fill-white text-white"
                      />
                    </div>
                    <div class="flex flex-col">
                      <div class="flex space-x-1 items-end">
                        <span
                          :class="`text-3xl font-medium text-${item?.color}`"
                        >
                          {{ item?.data }}
                        </span>
                        <span class="text-xs mb-1 text-gray-500">
                          {{ $t('TASK_TABLE_LABEL_TASKS')?.toLowerCase() }}
                        </span>
                      </div>
                      <span class="text-sm text-overflow-hidden-line">
                        {{ $t(item?.label)?.toLowerCase() }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="flex justify-center space-x-2 items-center">
                <syn-tag
                  size="large"
                  class="w-max bg-white shadow-md text-gray-600"
                >
                  <div>
                    <span>
                      {{
                        $t('HEADER_LABEL_WELCOME_START_TIME') ||
                          'Today, you started at'
                      }}:
                    </span>
                    <span class="font-medium text-current-600">
                      {{ $filters.dayjs(startLocalTime, 'HH:mm', timezone) }}
                    </span>
                  </div>
                </syn-tag>
              </div> -->
            </div>
          </div>
          <div class="w-full">
            <div
              class="
                absolute
                bottom-12
                right-12
                flex
                space-x-2
                items-center
                text-current-800
                z-10
              "
            >
              <div class="flex justify-center space-x-2 items-center">
                <syn-tag
                  size="large"
                  class="w-max bg-white shadow-md text-gray-600"
                >
                  <div>
                    <span>
                      {{
                        $t('HEADER_LABEL_WELCOME_START_TIME') ||
                        'Today, you started at'
                      }}:
                    </span>

                    <span class="font-medium text-current-600">
                      <SynLabelDateTime
                        format="time"
                        :datetime="startLocalTime"
                      />
                    </span>
                  </div>
                </syn-tag>
              </div>

              <SynButton
                class="w-max"
                :is-loading="isLoading"
                :label="$t('COMMON_LABEL_CONTINUE')"
                custom-class="pr-1"
                @click="$emit('onNext')"
              >
                <SynIcon
                  :custom-class="'w-6 h-6 fill-white'"
                  name="arrow-right"
                />
              </SynButton>
              <!-- <syn-button
                :label="$t('COMMON_LABEL_CONTINUE')"
                @click="$emit('onNext')"
              ></syn-button> -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </SynModal>
</template>
<style>
.welcome-body:before {
  content: '';
  /* background-image: linear-gradient(
    to right,
    #fffbeb,
    #f472b6,
    rgba(255, 251, 235, 0)
  ); */
  /* opacity: 0.75; */
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
