import DayoffRepository from '@/application/repositories/DayoffRepository';
import { CalendarsDayoffFilter } from '@/domain/entities/calendars/CalendarsOverviewFiltersClass';
import DayoffModifyPayloadClass from '@/domain/entities/dayoff/DayoffModifyPayloadClass';
import DayoffPayloadClass from '@/domain/entities/dayoff/DayoffPayloadClass';
import { DayoffStatus } from '@/domain/enums/DayoffEnums';
export default class DayoffService {
    private static instance: DayoffService;

    constructor() {}

    public static getInstance(): DayoffService {
        if (!DayoffService.instance) {
            // Get from local storage
            DayoffService.instance = new DayoffService();
        }

        return DayoffService.instance;
    }
    getListDayoffs(data: CalendarsDayoffFilter): Promise<any> {
        return DayoffRepository.getInstance().getListDayoffs(data);
    }
    getDetailById(id): Promise<any> {
        return DayoffRepository.getInstance().getDetailById(id);
    }
    createRequest(data: DayoffPayloadClass): Promise<any> {
        return DayoffRepository.getInstance().createRequest(data);
    }
    modifyRequest(data: DayoffModifyPayloadClass): Promise<any> {
        return DayoffRepository.getInstance().modifyRequest(data);
    }
    remove(id: string): Promise<any> {
        return DayoffRepository.getInstance().remove(id);
    }

    getListByStatus(data: {
        status: DayoffStatus[];
        year: number | null;
    }): Promise<any> {
        return DayoffRepository.getInstance().getListByStatus(data);
    }
    getMyActiveRequest(year): Promise<any> {
        return DayoffRepository.getInstance().getMyActiveRequest(year);
    }
    acceptRequest(id): Promise<any> {
        return DayoffRepository.getInstance().acceptRequest(id);
    }
    rejectRequest(id, data): Promise<any> {
        return DayoffRepository.getInstance().rejectRequest(id, data);
    }

    cancelRequest(id): Promise<any> {
        return DayoffRepository.getInstance().cancelRequest(id);
    }
    agreeUnusedRequest(id): Promise<any> {
        return DayoffRepository.getInstance().agreeUnusedRequest(id);
    }
    disagreeUnusedRequest(id): Promise<any> {
        return DayoffRepository.getInstance().disagreeUnusedRequest(id);
    }
    reopenRequest(id): Promise<any> {
        return DayoffRepository.getInstance().reopenRequest(id);
    }
    closeRequest(id): Promise<any> {
        return DayoffRepository.getInstance().closeRequest(id);
    }
    deleteRequest(id): Promise<any> {
        return DayoffRepository.getInstance().deleteRequest(id);
    }
    genDateOffRange(data: {
        startDate: number;
        endDate: number;
        startDateSession: number;
        endDateSession: number;
    }): Promise<any> {
        return DayoffRepository.getInstance().genDateOffRange(data);
    }
    getMembersOffWorkByDate(date): Promise<any> {
        return DayoffRepository.getInstance().getMembersOffWorkByDate(date);
    }
    getDayoffUsedByUser(fromDate, toDate, userId): Promise<any> {
        return DayoffRepository.getInstance().getDayoffUsedByUser(
            fromDate,
            toDate,
            userId
        );
    }
    getStatisticByUserAndRange(fromDate, toDate, userId): Promise<any> {
        return DayoffRepository.getInstance().getStatisticByUserAndRange(
            fromDate,
            toDate,
            userId
        );
    }
}
