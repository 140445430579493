<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import systemStore from '@/store/system';
import { formatDate } from '@/ui/hooks/calendarHook';
import {
  EDeploymentState,
  severity,
  systemKey,
  typeDeployments,
} from '@/ui/common/constants/system';
import ModalDescriptionDeploy from './ModalDescriptionDeploy.vue';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import SystemsInfoModel from '@/ui/plugins/firebases/realtime-database/model/SystemsInfoModel';

const router = useRouter();
const route = useRoute();
const _systemStore = systemStore();
const isOpenViewInfoDeployment = ref(false);
const deploymentInfo = computed(() => _systemStore.deploymentInfo);
const colorBar = computed(() => {
  if (deploymentInfo?.value?.severity == severity.NORMAL.index)
    return 'bg-yellow-200 text-yellow-800';
  if (deploymentInfo?.value?.severity == severity.HIGH.index)
    return 'bg-orange-200 text-orange-600';
  return 'bg-red-300 text-red-600';
});
const classTextDetail = computed(() => {
  if (deploymentInfo?.value?.severity == severity.NORMAL.index)
    return 'hover:text-yellow-600 ';
  if (deploymentInfo?.value?.severity == severity.HIGH.index)
    return 'hover:text-orange-400 ';
  return 'hover:text-red-500 ';
});

const deploymentState = computed<EDeploymentState>(() => {
  return _systemStore.stateDeploymentActive;
});

onMounted(() => {
  onListenDeploymentSystemFirebase();
  _systemStore.getCurrentDeploymentState(router);
});

let { unsubscribeCallbacks } = listenFirebaseRealtime();
const onListenDeploymentSystemFirebase = () => {
  const fbSystemsInfoClass = new SystemsInfoModel(systemKey?.DEPLOYMENTS);

  unsubscribeCallbacks.value.push(
    fbSystemsInfoClass.onChildAdded((data, key) => {
      _systemStore.updateDataFromFirebase(data, key);
    })
  );
  unsubscribeCallbacks.value.push(
    fbSystemsInfoClass.onChildChanged((data, key) => {
      _systemStore.updateDataFromFirebase(data, key);

      if (data.state !== EDeploymentState.UPCOMING)
        _systemStore.handleCheckRouterDeployment(data, key, router, route);
    })
  );
};
</script>
<template>
  <div
    v-if="deploymentState !== EDeploymentState.NORMAL"
    class="flex-center text-center flex-wrap py-1 text-sm relative"
    :class="colorBar"
  >
    <span
      v-if="deploymentInfo?.type === typeDeployments.MAINTENANCE"
      v-html="
        `${$t('SYSTEM_DEPLOYMENT_BAR_LABEL_UPCOMING_MAINTENANCE', {
          startTime: formatDate(deploymentInfo?.startTime).time,
          endTime: formatDate(deploymentInfo?.endTime).time,
          date: formatDate(deploymentInfo?.startTime).formatDDMMYYY,
        })}`
      "
    ></span>
    <span
      v-if="deploymentInfo?.type === typeDeployments.UPGRADE"
      v-html="
        `${$t('SYSTEM_DEPLOYMENT_BAR_LABEL_UPCOMING_UDGRADE', {
          startTime: formatDate(deploymentInfo?.startTime).time,
          endTime: formatDate(deploymentInfo?.endTime).time,
          date: formatDate(deploymentInfo?.startTime).formatDDMMYYY,
        })}`
      "
    ></span>
    <span class="pl-1">{{ $t('SYSTEM_DEPLOYMENT_SAVE_FLOW_WORKING') }}</span>
    <span class="px-1 py-0 font-medium" style="width: 4rem">
      <syn-count-down
        class="p-0"
        format="hh:mm:ss"
        auto-start
        style="font-size: 1.5vh"
        :total-miniseconds="
          parseInt(deploymentInfo?.startTime) - new Date().getTime()
        "
      />
    </span>
    <span
      v-if="
        deploymentInfo?.description &&
        Object.keys(deploymentInfo?.description).length > 0
      "
      class="cursor-pointer underline absolute right-0 pr-8"
      :class="classTextDetail"
      @click="isOpenViewInfoDeployment = true"
      >{{ $t('SYSTEM_DEPLOYMENT_VIEW_INFO_DEPLOYMENT') }}
    </span>
  </div>

  <ModalDescriptionDeploy
    v-if="isOpenViewInfoDeployment"
    :description="deploymentInfo?.description"
    :type="deploymentInfo?.type"
    :severity="deploymentInfo?.severity"
    @cancel="(value) => (isOpenViewInfoDeployment = value)"
  />
</template>
