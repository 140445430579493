<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import departmentStore from '@/store/department';

const props = defineProps<{
  departmentId: number;
  departmentName: string;
}>();

const emit = defineEmits(['cancel', 'deletedSuccess']);

const _departmentStore = departmentStore();

const allDepartmentByIds = computed<any>(
  () => _departmentStore.allDepartmentByIds
);
const departmentInfo = computed<any>(
  () => allDepartmentByIds.value[props.departmentId]
);

onMounted(() => {
  if (departmentInfo.value?.departmentUsers?.length == 0)
    removeRelateGroup.value = true;
});

const isLoadingDelete = ref(false);
const onDeleteGroup = async () => {
  isLoadingDelete.value = true;
  try {
    await _departmentStore.removeDepartment({
      departmentId: props.departmentId,
      isRemoveRelatedGroup: removeRelateGroup.value,
      deleteGroupOption: deleteGroupOption.value,
    });

    emit('deletedSuccess');
  } catch (error) {
    console.log('🚀 Hyrin ~ onDeleteGroup ~ error', error);
  } finally {
    isLoadingDelete.value = false;
  }
};

const onCancel = () => {
  emit('cancel', false);
};

const option = ref([
  {
    id: 'op1',
    key: 'GROUP_LABEL_OPTION_DELETE_1',
    subDes: 'GROUP_LABEL_SUB_OPTION_DELETE_1',
    isSelected: true,
    opt: 1,
  },
  {
    id: 'op2',
    key: 'GROUP_LABEL_OPTION_DELETE_2',
    subDes: 'GROUP_LABEL_SUB_OPTION_DELETE_2',
    isSelected: false,
    opt: 2,
  },
  {
    id: 'op3',
    key: 'GROUP_LABEL_OPTION_DELETE_3',
    subDes: 'GROUP_LABEL_SUB_OPTION_DELETE_3',
    isSelected: false,
    opt: 3,
  },
]);

const subDescription = ref('GROUP_LABEL_SUB_OPTION_DELETE_1');

const deleteGroupOption = ref(1);
const changeOption = (key) => {
  option.value.forEach((op) => {
    if (op.id == key) {
      op.isSelected = true;
      subDescription.value = op.subDes;
      deleteGroupOption.value = op.opt;
    } else op.isSelected = false;
  });
};

const removeRelateGroup = ref(false);
</script>

<template>
  <SynQuestionModal
    z-index="z-50"
    visible
    :show-confirm-btn="false"
    :show-delete-btn="true"
    :delete-label="$t('DEPARTMENT_LABEL_REMOVE')"
    :is-loading="isLoadingDelete"
    @on-confirm="onDeleteGroup"
    @cancel="onCancel"
  >
    <template #content>
      <div class="flex-center flex-col h-full">
        <div class="w-full pb-4 flex items-center justify-center flex-col">
          <syn-animation name="infomation" stype="width: 75px;" />
          <span
            class="text-center break-normal pb-4 font-semibold"
            v-html="$t('DEPARTMENT_LABEL_REMOVE_CONFIRM', { departmentName })"
          >
          </span>
          <div class="px-4 text-sm italic text-current-700">
            <span class="font-bold">{{ $t('COMMON_LABEL_NOTE') + ': ' }}</span>
            <span>{{ $t('DEPARTMENT_LABEL_REMOVE_WARNING') }}</span>
          </div>
        </div>

        <div
          class="w-full p-4 rounded-md first-letter:flex flex-col bg-gray-50"
        >
          <div class="w-full flex items-center justify-between">
            <span class="font-semibold text-sm">
              {{ $t('DEPARTMENT_REMOVE_RELATED_GROUP_TITLE') }}
            </span>
            <AtomSwitch
              v-model="removeRelateGroup"
              :disabled="departmentInfo?.departmentUsers?.length == 0"
            />
          </div>
          <template v-if="removeRelateGroup">
            <!-- <div class="pt-4 flex space-x-2 items-center">
              <span>{{ $t('GROUP_LABEL_CONFIRM_DELETE_GROUP') }}</span>
            </div> -->
            <div
              class="flex space-y-3 py-4 ml-8 flex-wrap text-sm cursor-pointer"
            >
              <div
                v-for="opt in option"
                :key="opt.id"
                class="flex space-x-2 w-full items-center"
              >
                <input
                  :id="opt.id"
                  type="radio"
                  name="option_remove"
                  :checked="opt.isSelected"
                  class="
                    text-current-500
                    focus:border-current-400
                    focus:ring
                    focus:ring-offset-0
                    focus:ring-current-400
                    focus:ring-opacity-30
                  "
                  @change="changeOption(opt.id)"
                />
                <label class="cursor-pointer" :for="opt.id">
                  {{ $t(opt.key) }}
                </label>
              </div>
            </div>
            <div
              v-if="subDescription"
              class="px-4 pt-2 text-sm italic text-current-700 ml-4"
            >
              <span class="font-bold">{{
                $t('COMMON_LABEL_NOTE') + ': '
              }}</span>
              <span>{{ $t(subDescription) }}</span>
            </div>
          </template>
        </div>
      </div>
    </template>
  </SynQuestionModal>
</template>
