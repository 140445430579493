<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import organizationStore from '@/store/organization';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { AuthenticateCaseEnum } from '@/application/entities/authenticate/AuthenticateEnum';
import AuthenticateClass from '@/application/entities/authenticate/AuthenticateClass';
import { setLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

const regexCode = /^(?=.{9}$)[A-Z]*\d[A-Z]*\d[A-Z]*\d[A-Z]*$/;
defineEmits(['onCancel', 'onConfirm']);

const router = useRouter();
const _organizationStore = organizationStore();
const attachmentCode = ref('');
const isFetchingJoin = ref(false);
const handleClickJoinNow = async () => {
  try {
    isFetchingJoin.value = true;
    const payload = {
      code: attachmentCode.value?.toUpperCase(),
    };
    const res = await _organizationStore.attachOrganizationByCode(payload);
    const result = res?.result;

    const authenClass = new AuthenticateClass(
      result?.jwtToken,
      result?.refreshToken,
      result?.organizationCode,
      result?.organizationId,
      result?.organizationSelectRequire,
      result?.currentSettingProgress,
      result?.organizations,
      result?.numberOfLogins
    );

    const loginResult = await authenClass.handleSuccess();

    handleLoginSuccess(loginResult);

    window.location.reload();
  } catch (error) {
    isFetchingJoin.value = false;
    attachmentCodeError.value =
      translate('COMMON_ATTACHMENT_CODE_INVALID') ||
      'Sorry, the attachment code is incorrect.';
  }
};

const attachmentCodeError = ref('');
const attachmentCodeIsValid = ref(false);
const isChecking = ref(false);
let timer;
const onChangeAttachmentCode = async (event) => {
  // 1. clear error
  attachmentCodeError.value = '';
  attachmentCodeIsValid.value = false;
  // 2. clear timeout
  clearTimeout(timer);
  isChecking.value = true;
  // 3. start timeout
  const value = event.target.value;
  if (value) {
    timer = setTimeout(async () => {
      // 2.1 Check valid
      if (!regexCode.test(value.toUpperCase())) {
        isChecking.value = false;
        attachmentCodeError.value =
          translate('COMMON_INVALID_CODE') || 'Code invalid';
        return;
      }
      isChecking.value = false;
      attachmentCodeIsValid.value = true;
    }, 750);
  } else {
    attachmentCodeError.value = translate('COMMON_THIS_FIELD_REQUIRED');
  }
};
const isDisabledButton = computed(() => {
  return attachmentCodeError.value || !attachmentCode.value || isChecking.value;
});

const handleLoginSuccess = async (result) => {
  if (!result) return;

  switch (result.caseKey) {
    case AuthenticateCaseEnum.WaitingForActivation:
      {
        router.push({
          name: 'WaitingActivation',
        });
      }
      break;

    case AuthenticateCaseEnum.SettingFinished:
      {
        router.push({
          name: 'Home',
        });
      }
      break;

    default:
      history.replaceState({}, '', '/');
      setLocalStorage(StorageConstant.HAS_LOGIN, 'true');

      window.location.reload();
      break;
  }
};
</script>

<template>
  <syn-modal
    container-class="md:w-1/2"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    @cancel="$emit('onCancel')"
    @confirm="$emit('onConfirm')"
  >
    <template #body>
      <div class="py-6 px-4 bg-opacity-40 rounded-xl flex space-x-8">
        <div class="flex-1 flex flex-col space-y-4 pt-8">
          <span class="text-sm font-semibold pb-4">
            {{ $t('SIGN_UP_LABEL_HEADER_STEP_3') }}
          </span>
          <syn-form-input
            :error-message="attachmentCodeError"
            :is-valid="attachmentCodeIsValid"
          >
            <syn-input
              v-model="attachmentCode"
              :custom-class="'uppercase text-center'"
              autofocus
              :placeholder="$t('COMMON_ENTER_CODE_HERE') || 'Enter code here'"
              :error-message="attachmentCodeError"
              @input="onChangeAttachmentCode"
            ></syn-input>
          </syn-form-input>
          <div class="flex justify-end">
            <syn-button
              class="w-40"
              :is-loading="isFetchingJoin"
              :disabled="isDisabledButton"
              @click="handleClickJoinNow"
            >
              <span>{{ $t('COMMON_ATTACH_NOW') || 'Attach now' }}</span>
            </syn-button>
          </div>
        </div>
        <syn-animation name="teamwork" stype="width: 200px" />
      </div>
    </template>
  </syn-modal>
</template>
