<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import dayjs from 'dayjs';
import { ICalendarDetail } from '@/domain/entities/PayloadClass/CalendarPayloadClass';
// import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import calendarStore from '@/store/calendar';
import myProfileStore from '@/store/auth/my-profile';
import TimePicker from '@/ui/components/calendars/event-attribute/TimePicker.vue';
import NoticeOption from '@/ui/components/calendars/event-attribute/NoticeOption.vue';
import RepeatNoticeOption from '@/ui/components/calendars/event-attribute/RepeatNoticeOption.vue';
import {
  CalendarType,
  CalendarSourceType,
  CalendarSourceId,
  CalendarId,
  CalendarNotificationType,
  CalendarReminderRepeatType,
  CalendarMeetingType,
  CalendarDayInWeek,
  CalendarStatus,
} from '@/ui/common/constants/calendar';
import {
  covertToDateObj,
  formatDatetimeUTC,
  formatDateWithTimeUTC,
} from '@/ui/components/calendars/event-attribute/date-time-format';
import taskStore from '@/store/task';
import { ITaskReminderInput } from '@/application/interfaces/tasks/task-interfaces';
import { useScreens } from 'vue-screen-utils';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { formatTaskCode } from '@/ui/hooks/taskHook';
// import datetimeComposable from '@/ui/composables/common/datetime-composable';
import appStore from '@/store/app';

const props = defineProps<{
  type: 'CALENDAR' | 'CHAT' | 'TASK' | undefined;
  dateStart?: string;
  isEdit: boolean;
  isClone: boolean;
  defaultData?: {
    id?: string;
    title?: string;
    description?: string;
    startDate?: number;
    endDate?: number;
  };
  eventDetail?: ICalendarDetail;
  taskDetail?: any;
}>();

const emit = defineEmits([
  'cancel',
  'onUpdateSuccess',
  'onCreateSuccess',
  'setReminderTaskSuccess',
]);

const { mapCurrent } = useScreens({
  xs: '0px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
});

const timezone = computed(() => myProfileStore().getTimezone);
// const timezoneUser = computed(() => parseInt(timezone.value) || 0);
const columns = mapCurrent({ lg: 1 }, 1);
const dateSpecificPickerRef = ref(null as any);

const dateInWeek = ref([
  {
    label: CalendarDayInWeek.Monday.label,
    key: CalendarDayInWeek.Monday.key,
    selected: true,
  },
  {
    label: CalendarDayInWeek.Tuesday.label,
    key: CalendarDayInWeek.Tuesday.key,
    selected: true,
  },
  {
    label: CalendarDayInWeek.Wednesday.label,
    key: CalendarDayInWeek.Wednesday.key,
    selected: true,
  },
  {
    label: CalendarDayInWeek.Thursday.label,
    key: CalendarDayInWeek.Thursday.key,
    selected: true,
  },
  {
    label: CalendarDayInWeek.Friday.label,
    key: CalendarDayInWeek.Friday.key,
    selected: true,
  },
  {
    label: CalendarDayInWeek.Saturday.label,
    key: CalendarDayInWeek.Saturday.key,
    selected: false,
  },
  {
    label: CalendarDayInWeek.Sunday.label,
    key: CalendarDayInWeek.Sunday.key,
    selected: false,
  },
]);

const isEqualOrGreaterDate = (d1, d2) => {
  return new Date(d1.toDateString()) >= new Date(d2.toDateString());
};

// const locate = getLocalStorage(StorageConstant.LANGUAGE);
const modelConfig = ref({ type: 'string', mask: 'YYYY-MM-DD' });
const masks = ref({ input: 'WWWW, MMM DD, YYYY' });

const _calendarStore = calendarStore();

const _taskStore = taskStore();

const isLoadingSave = ref(false);

const formData = ref({
  calendarId: CalendarId.Event,
  sourceId: '',
  sourceType: CalendarSourceType.Event,
  title: props.defaultData?.title || '',
  description: props.defaultData?.description || '',
  type: CalendarType.Reminder,
  status: CalendarStatus.Created,
  startDate: props.defaultData?.startDate || Date.now(),
  endDate: props.defaultData?.endDate || Date.now(),
  allDay: true,
  repeatType: CalendarReminderRepeatType.DoNotRepeat,
  notificationType: CalendarNotificationType.NoticeAfter15Minutes,
  workspace: '',
  files: [],
  notes: [],
  meetingType: CalendarMeetingType.PhysicalMeeting,
  meetingDuration: 15,
  participantIds: [],
  repeatSpecificDaysOfWeek: [],

  ...props.defaultData,
}) as any;

const formDataTask = ref<ITaskReminderInput>({
  taskId: props.taskDetail?.id || 0,
  reminderTime: props.taskDetail?.taskReminderInfo?.startDate
    ? dayjs(props.taskDetail?.taskReminderInfo?.startDate).toDate()
    : new Date(),
});

const formatDateStringAllDay = (date) => {
  const timeObj = covertToDateObj(date);
  return formatDatetimeUTC(timeObj);
};

const onChangeDate = (date) => {
  if (props.type == 'TASK') {
    formDataTask.value.reminderTime = date;
    // console.log(date, 'reminder time when change date!');
  } else {
    formData.value.startDate = date;
    formData.value.endDate = date;
  }
};

const chooseTime = (time) => {
  if (props.type == 'TASK') {
    formDataTask.value.reminderTime = time;
    // console.log(
    //   formDataTask.value.reminderTime,
    //   'reminder time when change time!'
    // );
  } else {
    formData.value.startDate = time;
    formData.value.endDate = time;
  }
};

const onChangeTime = (isAllDay) => {
  formData.value.allDay = isAllDay;
  formData.value.startDate = formatDateStringAllDay(formData.value.startDate);
  formData.value.endDate = formatDateStringAllDay(formData.value.endDate);
};

const onChangeTitle = (event) => {
  formData.value.title = event.target.value;
};

const onSave = async () => {
  if (!formData?.value?.title?.trim()) return;

  isLoadingSave.value = true;

  if (formData.value.allDay) {
    formData.value.startDate = formatDateWithTimeUTC(formData.value.startDate);
    formData.value.endDate = formatDateWithTimeUTC(formData.value.endDate);
  }

  if (
    formData.value?.repeatType ===
    CalendarReminderRepeatType?.CustomSpecificDayOfWeek
  ) {
    formData.value.repeatSpecificDaysOfWeek = dateInWeek?.value
      ?.filter((el) => el?.selected)
      ?.map((o) => o?.key);
  }

  formData.value.type = CalendarType.Reminder;

  formData.value.sourceType = CalendarSourceType.Event;

  formData.value.sourceId = CalendarSourceId.Reminder;

  formData.value.calendarId = CalendarId.Event;

  if (formData.value?.id && !props.isClone) {
    await _calendarStore.updateEvent(formData.value);
    isLoadingSave.value = false;
    emit('onUpdateSuccess', formData.value);
  } else {
    const res = await _calendarStore.addEvent(formData.value);
    isLoadingSave.value = false;
    emit('onCreateSuccess', res?.result);
  }
  isLoadingSave.value = false;
};

onMounted(() => {
  if (props.type == 'TASK') {
    // dateSpecificPickerRef.value?.hidePopover();
    formData.value.allDay = false;
  }
});

// const { formatDate } = datetimeComposable();
//
// const convertedTime = (
//   dateTime,
//   format: 'date' | 'time' | 'date_time' | 'date_day'
// ) => {
//   return formatDate(dateTime, format, {
//     isIgnoreTimezone: false,
//   });
// };

const onSaveWithTaskType = async () => {
  isLoadingSave.value = true;
  try {
    await _taskStore.setTaskReminder(props.taskDetail?.id, formDataTask.value);
    emit('setReminderTaskSuccess', true);
    openNotification({
      type: AlertType.success,
      title: translate('COMMON_LABEL_SUCCESS'),
      body: props.isEdit
        ? translate('API_ACTION_MESSAGE_UPDATE_REMINDER_TASK_SUCCESS', {
            taskCode: formatTaskCode(props.taskDetail?.code),
          })
        : translate('API_ACTION_MESSAGE_SET_REMINDER_TASK_SUCCESS', {
            taskCode: formatTaskCode(props.taskDetail?.code),
          }),
      duration: 3000,
    });
  } catch (err) {
    console.log(err);
    const errorMessage = err?.error?.message;
    openNotification({
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body:
        errorMessage == 'REMINDER TIME INVALID'
          ? translate('API_ACTION_MESSAGE_REMINDER_TIME_INVALID')
          : errorMessage,
      duration: 3000,
    });
  } finally {
    isLoadingSave.value = false;
  }
};

const isDisabled = computed(() => {
  return (
    ((!formData.value.title?.trim() ||
      (formData.value?.repeatType ===
        CalendarReminderRepeatType.CustomSpecificDayOfWeek &&
        dateInWeek?.value?.filter((el) => el?.selected)?.length < 1)) &&
      props.type != 'TASK') ||
    (props.type == 'TASK' &&
      dayjs(formDataTask.value.reminderTime).isBefore(dayjs()))
  );
});
</script>

<template>
  <div class="flex-1 overflow-hidden">
    <div
      class="h-full small-scrollbar pr-4 pb-1 space-y-2"
      :style="type != 'TASK' ? 'min-height: 15rem' : ''"
    >
      <!-- TITLE -->
      <div v-if="type != 'TASK'" class="py-2 pl-10">
        <input
          v-cusFocus="true"
          class="
            bg-white
            w-full
            h-full
            focus:outline-none
            pt-4
            pb-2
            border-b-2
            placeholder-gray-500 placeholder-opacity-75
          "
          :class="'border-gray-200 focus:border-b-2 focus:border-current'"
          :placeholder="$t('CALENDAR_LABEL_INPUTVALUE_TITLE')"
          :value="formData.title"
          required
          autofocus
          @input="onChangeTitle"
        />
      </div>

      <!--  DATE    -->
      <div class="flex items-center space-x-2 pt-1">
        <div v-if="type !== 'TASK'" class="w-8 h-8 flex items-center">
          <SynIcon
            name="calendars"
            custom-class="w-5 h-5 fill-gray-500"
            is-active
          />
        </div>
        <div
          class="flex space-x-2 w-full pl-1"
          :class="type != 'TASK' ? 'items-center' : ''"
        >
          <v-date-picker
            v-if="type == 'TASK'"
            ref="dateSpecificPickerRef"
            v-model="formDataTask.reminderTime"
            :locale="appStore().language || 'en'"
            style="font-size: 13px !important"
            class="datepicker_view border border-gray-100 text-sm w-full vig"
            :model-config="modelConfig"
            color="current"
            :columns="columns"
            :popover="{ visibility: 'hidden' }"
            :attributes="[
              {
                key: 'today',
                dot: {
                  color:
                    dayjs(new Date()).format('MM-DD-YYYY') ===
                    dayjs(formDataTask.reminderTime).format('MM-DD-YYYY')
                      ? 'yellow'
                      : 'current',
                  class:
                    dayjs(new Date()).format('MM-DD-YYYY') ===
                    dayjs(formDataTask.reminderTime).format('MM-DD-YYYY')
                      ? 'mb-1 z-70'
                      : '',
                },
                dates: new Date(),
              },
            ]"
            :masks="masks"
            :min-date="new Date()"
            :max-date="new Date().setMonth(new Date().getMonth() + 12)"
            :first-day-of-week="2"
            @update:model-value="onChangeDate"
          />
          <v-date-picker
            v-else
            v-model="formData.startDate"
            :locale="appStore().language || 'en'"
            class="z-60"
            :model-config="modelConfig"
            color="current"
            :attributes="[
              {
                key: 'today',
                dot: {
                  color:
                    dayjs(new Date()).format('MM-DD-YYYY') ===
                    dayjs(formData.startDate).format('MM-DD-YYYY')
                      ? 'yellow'
                      : 'current',
                  class:
                    dayjs(new Date()).format('MM-DD-YYYY') ===
                    dayjs(formData.startDate).format('MM-DD-YYYY')
                      ? 'mb-1 z-70'
                      : '',
                },
                dates: new Date(),
              },
            ]"
            :masks="masks"
            :min-date="new Date()"
            :first-day-of-week="2"
            @update:model-value="onChangeDate"
          >
            <template #default="{ inputValue, inputEvents }">
              <input
                class="bg-white border px-4 py-1.5 rounded vig-input w-64"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>

          <div class="flex items-center space-x-2" style="height: fit-content">
            <TimePicker
              v-if="!formData.allDay"
              class="w-24"
              :is-edit="isEdit || isClone"
              :time="
                type == 'TASK' ? formDataTask.reminderTime : formData.startDate
              "
              @choose-time="(value) => chooseTime(value)"
            />
            <SynExplain
              :label-question="
                $t('CALENDAR_QUESTION_LABEL_TIMEZONE', { utc: timezone })
              "
              :label-answer="$t('CALENDAR_ANSWER_LABEL_TIMEZONE')"
            />
          </div>
        </div>
      </div>
      <!--  REPEAT    -->
      <div v-if="type != 'TASK'" class="flex items-start space-x-2">
        <div class="w-8 h-8 mt-2 flex items-center">
          <SynIcon
            name="reload"
            custom-class="w-4 h-4 fill-gray-500 transform rotate-180"
            is-active
          />
        </div>
        <div class="flex flex-col">
          <div class="flex space-x-2 w-full pl-0.5 flex-wrap">
            <RepeatNoticeOption
              class="py-2 w-64"
              width-class="w-64"
              :date="formData.startDate"
              :repeat-notice-choose="formData.repeatType"
              @change-repeat-notice="(value) => (formData.repeatType = value)"
            />
            <div class="flex items-center min-w-max">
              <div
                class="flex items-center space-x-2 px-2 py-1.5 rounded w-full"
                :class="
                  formData.allDay
                    ? `border-current border bg-current-50 text-current-600 `
                    : 'bg-current-50 bg-opacity-60'
                "
              >
                <input
                  id="calendar_event_reminder_allday"
                  v-model="formData.allDay"
                  type="checkbox"
                  class="vig-checkbox w-5 h-5"
                  :class="
                    isEqualOrGreaterDate(
                      new Date(formData.startDate),
                      new Date()
                    )
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed'
                  "
                  @change="onChangeTime(formData.allDay)"
                />
                <label
                  class="flex items-center space-x-2 min-w-max"
                  for="calendar_event_reminder_allday"
                >
                  {{ $t('CALENDAR_REMINDER_ALL_DAY') }}
                </label>
              </div>
            </div>
          </div>

          <div
            v-if="
              false &&
              formData.repeatType ===
                CalendarReminderRepeatType.CustomSpecificDayOfWeek
            "
            class="
              flex flex-col
              mx-1
              p-2
              bg-gray-50 bg-opacity-50
              rounded
              border border-gray-100
            "
          >
            <span class="font-medium text-xs pb-2">{{
              $t('COMMON_LABEL_REPEAT_ON')
            }}</span>
            <div class="flex items-center flex-wrap">
              <template v-for="item in dateInWeek" :key="item">
                <div
                  class="
                    flex-center
                    px-2
                    py-1
                    rounded-full
                    cursor-pointer
                    text-xs
                    mr-1
                    my-0.5
                  "
                  :class="
                    item.selected
                      ? 'bg-current-500 text-white hover:bg-opacity-80'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  "
                  @click="item.selected = !item.selected"
                >
                  {{ $t(item?.label) }}
                </div>
              </template>
            </div>
            <span
              v-if="dateInWeek?.filter((el) => el?.selected)?.length < 1"
              class="text-red-500 text-xs"
              >{{ `* ${$t('COMMON_LIMIT_CHOOSE_DATE')}` }}</span
            >
          </div>
        </div>
      </div>

      <!--  NOTICE    -->
      <div v-if="type != 'TASK'" class="flex items-start space-x-2 w-full">
        <div class="w-8 h-8 flex items-center">
          <SynIcon name="bell" custom-class="w-4 h-4 fill-gray-500" is-active />
        </div>
        <div class="flex w-full pl-1">
          <NoticeOption
            class="w-64"
            width-class="w-64"
            :notice-choose="formData?.notificationType || 'NONE'"
            @on-change-notice="(value) => (formData.notificationType = value)"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- ACTION -->
  <div class="pt-3 flex justify-end items-center space-x-2">
    <SynButton
      type-text
      color="gray"
      :label="$t('COMMON_LABEL_CANCEL')"
      @click="$emit('cancel', false)"
    />
    <SynButton
      :disabled="isDisabled"
      :is-loading="isLoadingSave"
      :label="
        type == 'TASK' && !isEdit
          ? $t('CALENDAR_CREATE_NEW_REMINDER')
          : type == 'TASK' && isEdit
          ? $t('COMMON_LABEL_UPDATE')
          : $t('COMMON_LABEL_SAVE')
      "
      @click="props.type == 'TASK' ? onSaveWithTaskType() : onSave()"
    />
  </div>
</template>

<style lang="scss">
.datepicker_not_popover {
  .vc-popover-content-wrapper.is-interactive {
    visibility: hidden !important;
    display: none !important;
  }
}

.datepicker_view {
  .vc-title {
    font-size: 14px !important;
  }

  .vc-weeks .vc-weekday {
    font-size: 12px !important;
  }

  .vc-day {
    font-size: 12px !important;
  }
}
</style>
