export class ChangeDomainTaskInput {
    taskId: number;
    domainId: number;
    projectId?: number;
    subProjectId?: number;
    constructor(data: any) {
        this.taskId = data?.taskId;
        this.domainId = data?.domainId;
        this.projectId = data?.projectId;
        this.subProjectId = data?.subProjectId;
    }
}
export class RejectDomainPayload {
    domainId: number;
    relatedTasks: ChangeDomainTaskInput[];
    constructor(data: any) {
        this.domainId = data.domainId;
        this.relatedTasks = data.relatedTasks || [];
    }
}
export class RejectProjectPayload {
    projectId: number;
    relatedTasks: ChangeDomainTaskInput[];
    constructor(data: any) {
        this.projectId = data.projectId;
        this.relatedTasks = data.relatedTasks || [];
    }
}
