import TaskCreateUpdatePayload from '@/domain/entities/task/TaskCreateUpdatePayload';
import { cloneDeep } from '@/ui/hooks/commonFunction';

export const _prepareTaskIndexDBSchema = (
    ownerId: string,
    formData: TaskCreateUpdatePayload
) => {
    return {
        ownerId,
        name: formData?.name,
        assigneeId: formData?.assigneeId,
        // attachments: cloneDeep(formData?.attachments),
        creatorId: formData?.assigneeId,
        description: formData?.description,
        domainId: formData?.domainId,
        domainName: formData?.domainName,
        files: cloneDeep(formData?.files),
        finisherId: formData?.finisherId,
        groupId: formData?.groupId,
        id: formData?.id,
        important: formData?.important,
        isPrivate: formData?.isPrivate,
        lastModifierId: formData?.lastModifierId,
        notes: cloneDeep(formData?.notes),
        planType: formData?.planType,
        priority: formData?.priority,
        projectId: formData?.projectId,
        projectName: formData?.projectName,
        subprojectId: formData?.subprojectId,
        subprojectName: formData?.subprojectName,
        taskSchedule: cloneDeep(formData?.taskSchedule),
        taskLife: formData?.taskLife,
        taskType: formData?.taskType,
        urgency: formData?.urgency,
        taskTodos: cloneDeep(formData?.taskTodos),
        taskWorkflow: formData?.taskWorkflow
            ? cloneDeep(formData?.taskWorkflow)
            : undefined,
        taskFromChat: cloneDeep(formData.taskFromChat),
        localId: formData?.localId,
        scheduleTime: formData?.scheduleTime,
        scheduleByHour: formData?.scheduleByHour,
        scheduleTimezone: formData?.scheduleTimezone,
        subTasks: cloneDeep(formData?.subTasks),
        subTaskIds: formData?.subTaskIds,
        parentId: formData?.parentId,
        parentCode: formData?.parentCode,
        parentName: formData?.parentName,
        multiAssignees: cloneDeep(formData.multiAssignees),
        isClone: formData.isClone,
    };
};
