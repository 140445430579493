export default function clearAll() {
    const modules = import.meta.glob(['./**/*.{js,ts}'], { eager: true });
    console.log('🚀 Tictop ~ modules:', modules);

    Object.values(modules).forEach((module) => {
        if (!module || !module.default || typeof module.default !== 'function')
            return;
        if (module.default() && typeof module.default().$reset == 'function')
            module.default().$reset();
    });
}
