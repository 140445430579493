<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import userStore from '@/store/user';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { COLOR_LIST } from '@/application/constants/report/report.const';
import { translate } from '@/ui/plugins/i18n/myi18n';
import atomDateRangPicker from '@/ui/common/atoms/date-pickers/atomDateRangPicker';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    size: number;
    orderBy: number;
  };
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    assigneeId: number;
    fullName: string;
    avatar: string;
    assignedTasks: number;
    lastCreationTime: string;
    lastAssignedTaskId: number;
    lastAssignedTaskName: string;
    color: string;
    percent: number;
  }[]
>([]);
const { dataRangeString, updateCurrentChoice } = atomDateRangPicker();

onMounted(() => {
  updateCurrentChoice({
    fromDate: props.filterData.dateFrom,
    toDate: props.filterData.dateTo,
  });
});
const initComponent = async () => {
  isLoading.value = true;
  const res = await WorkPerformanceRepository.getUserMostAssignedTask({
    ...props.filterData,
  });

  const dataList = res?.result;
  const colorByIds: any = [
    ...(arrayOrderBy(dataList, ['total'], ['desc']) || []),
  ]?.reduce((currentObj: any, currentItem: any, currentIndex: number) => {
    return {
      ...currentObj,
      [currentItem?.assigneeId]: COLOR_LIST[currentIndex],
    };
  }, {});

  const maxTotalValue = Math.max(...dataList?.map((o) => o?.assignedTasks));

  resultData.value = dataList?.map((o) => {
    return {
      ...o,
      avatar: userStore().allUserByIds[o.assigneeId].avatar,
      color: colorByIds[o?.assigneeId],
      percent:
        o.assignedTasks && maxTotalValue
          ? Math.floor((o.assignedTasks / maxTotalValue) * 100)
          : 0,
    };
  });

  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.size == old.size &&
      data.orderBy == old.orderBy
    )
      return;
    initComponent();
  }
);

const genTooltipFunction = (item) => {
  return `<div class='p-2 w-max text-left bg-white flex flex-col space-y-2 rounded-xl'>
        <span class='font-semibold text-current'>
        ${
          item?.assignedTasks +
          ' ' +
          translate('LABEL_TOTAL_TASK')?.toLowerCase()
        }
        </span>
        <span class='font-normal'>${dataRangeString.value}</span>
      </div>`;
};
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <div
    v-else
    class="
      flex-1
      min-h-0
      overflow-auto
      small-scrollbar
      w-full
      h-full
      flex
      justify-center
      items-start
      pt-4
    "
  >
    <SynTableCustom v-if="resultData?.length > 0">
      <template #body>
        <SynTr
          v-for="(task, index) in resultData"
          :key="task.assigneeId"
          :class="index == resultData?.length - 1 ? '' : 'border-b'"
        >
          <SynTd class="flex-center">
            <UserByAction
              v-if="task.assigneeId"
              :user-id="task?.assigneeId"
              is-show-action-user
              avatar-class="w-9 h-9"
              is-hidden-name
            />
          </SynTd>

          <SynTd>
            <div class="flex flex-col gap-1">
              <span class="text-gray-900">
                {{ task?.lastAssignedTaskName }}
              </span>
              <span class="text-xs text-gray-500">
                {{ $filters.fromNow(task?.lastCreationTime) }}
                {{ $t('COMMON_LABEL_AGO') || 'ago' }}
              </span>
            </div>
          </SynTd>

          <SynTd class="w-1/3 text-xs text-center">
            <div class="px-4 flex items-center gap-2">
              <div class="flex-1">
                <AtomProcess
                  :percent="task.percent"
                  :color="task.color || '#b1dcde'"
                  class="h-3"
                  :tooltip-content="genTooltipFunction(task)"
                  placement-tooltip="top"
                />
              </div>
            </div>
          </SynTd>
        </SynTr>
      </template>
    </SynTableCustom>
    <div v-else class="w-full h-full flex-center flex-col space-y-6">
      <div class="w-full grid grid-cols-4 gap-2">
        <div class="col-span-2 bg-gray-100 rounded-md h-4"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-4"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-4"></div>
        <!-- body -->
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
      </div>
    </div>
  </div>
</template>
