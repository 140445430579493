<template>
  <div class="flex w-full h-11 justify-end right-0 md:text-sm">
    <select
      class="border-gray-300 rounded-md"
      :value="itemInPage"
      @change="changeItem"
    >
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
    </select>
    <div class="mx-4 flex items-center bg-gray-100 px-3 rounded-md">
      <p>{{ ' Page ' + pageIndex + ' of ' + pageSize }}</p>
    </div>
    <div class="flex">
      <syn-button
        :name-icon="'arrow-left'"
        :label="''"
        :disable="pageIndex < 2 ? true : false"
        type-outline
        @click="showPagePrevious"
      ></syn-button>
      <syn-button
        :name-icon="'arrow-right'"
        :label="''"
        type-outline
        :disable="pageIndex > pageSize ? true : false"
        @click="showPageNext"
      ></syn-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemInPage: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 100,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  emits: ['showPrevious', 'showNext', 'changeSize'],
  setup(props, { emit }) {
    const showPagePrevious = () => {
      if (props.pageIndex > 1) {
        emit('showPrevious', props.pageIndex - 1);
      }
    };
    const showPageNext = () => {
      if (props.pageIndex < props.pageSize) {
        emit('showNext', props.pageIndex + 1);
      }
    };
    const changeItem = (e) => {
      emit('changeSize', e.target.value);
    };
    return {
      showPagePrevious,
      showPageNext,
      changeItem,
    };
  },
};
</script>
