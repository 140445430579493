<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 299.5 300"
  >
    <path
      style="fill: #008e8e"
      d="M369.85,310.37v120a120,120,0,0,1-120,120h0v-240a60,60,0,0,1,60-60h0a60,60,0,0,1,60,60Z"
      transform="translate(-249.85 -250.37)"
    />
    <path
      style="fill: #f2cb05"
      d="M291.66,253a60,60,0,0,0-41.81,57.19V318a60,60,0,0,0,59.5,52.37h60.5V310.21A60,60,0,0,0,314,250.37h-4.67A60.06,60.06,0,0,0,291.66,253Z"
      transform="translate(-249.85 -250.37)"
    />
    <circle style="fill: #005167" cx="60" cy="59.81" r="50" />
    <path
      style="isolation: isolate; fill: #8fcdcd"
      d="M466.72,392.46h0a13.29,13.29,0,0,1,18.14-4.86l11.49,6.64a13.28,13.28,0,0,1,4.87,18.14h0a13.28,13.28,0,0,1-18.14,4.85l-11.49-6.63A13.29,13.29,0,0,1,466.72,392.46Z"
      transform="translate(-249.85 -250.37)"
    />
    <path
      style="isolation: isolate; fill: #61b9b9"
      d="M432.25,433.67h0a13.28,13.28,0,0,1,18.78,0l9.38,9.38a13.27,13.27,0,0,1,0,18.78h0a13.27,13.27,0,0,1-18.77,0l-9.39-9.38A13.28,13.28,0,0,1,432.25,433.67Z"
      transform="translate(-249.85 -250.37)"
    />
    <path
      style="fill: #008e8e"
      d="M410,502.46h0a13.28,13.28,0,0,1-18.15-4.84v0l-6.64-11.5a13.28,13.28,0,0,1,23-13.28l6.64,11.5A13.28,13.28,0,0,1,410,502.46Z"
      transform="translate(-249.85 -250.37)"
    />
    <path
      style="fill: #f2cb05"
      d="M314,250.37a60,60,0,0,1,55.83,59.84v60.16h59.5a120,120,0,0,0,120-120Z"
      transform="translate(-249.85 -250.37)"
    />
  </svg>
</template>
