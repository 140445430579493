<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import DomainTag from '@/ui/modules/task/components/DomainTag.vue';
import domainLogic, {
  DomainTypeEnum,
} from '@/ui/modules/task/general/domain-logic';
// import { AddNewType } from '@/ui/modules/task/general/task-create-form-types';
// import TaskCreateUpdatePayload from '@/domain/entities/task/TaskCreateUpdatePayload';
import DomainById from '@/ui/modules/domains/component/DomainById.vue';
import ProjectById from '@/ui/modules/domains/component/ProjectById.vue';
import domainStore from '@/store/scope';
import DomainCreateModal from '@/ui/modules/domains/domain/DomainCreateModal.vue';
import permissionStore from '@/store/permission';

const props = withDefaults(
  defineProps<{
    taskDetail: any;
    isUpdateInstance?: boolean;
    isOnlyVerifiedData?: boolean;
    createable?: boolean;
    updateDomainOnly?: boolean;
    selectOnly?: boolean;
    isUpdateDomainOnly?: boolean;
  }>(),
  {
    isUpdateInstance: true,
    isOnlyVerifiedData: false,
  }
);

const emit = defineEmits(['cancel', 'save', 'onAddNewDomain']);
const formData = ref({
  domainId: props.taskDetail?.domainId,
  domainName: props.taskDetail?.domainName,
  projectId: props.taskDetail?.projectId,
  projectName: props.taskDetail?.projectName,
  subprojectId: props.taskDetail?.subprojectId,
  subprojectName: props.taskDetail?.subprojectName,
  assigneeId: props.taskDetail?.assigneeId,
  groupId: props.taskDetail?.groupId,
});

const isCreateableDomain = computed(
  () => props.createable && domainStore().isCreateableDomain
);

const {
  domainList,
  projectsByOwner,
  subProjectsByOwner,
  initDomain: initUpdateDomain,
  removeDomain,
  onChooseDomain,
  getDomainList,
  resetDomainAfterChangeAssign,
} = domainLogic(formData);

onMounted(() => {
  domainStore().checkQuicklyCreateDomainPermission();
});

const removeScopeByType = (type: DomainTypeEnum = DomainTypeEnum.domain) => {
  removeDomain(type);
  onSave();
};
const onSave = () => {
  emit('save', formData.value);
};

const onUpdateDomain = (type: DomainTypeEnum = DomainTypeEnum.domain, item) => {
  onChooseDomain(type, item);

  onSave();
};

const initDomain = async (defaultData?) => {
  const taskDetail = defaultData || props.taskDetail;
  formData.value = {
    domainId: taskDetail?.domainId,
    domainName: taskDetail?.domainName,
    projectId: taskDetail?.projectId,
    projectName: taskDetail?.projectName,
    subprojectId: taskDetail?.subprojectId,
    subprojectName: taskDetail?.subprojectName,
    assigneeId: taskDetail?.assigneeId,
    groupId: taskDetail?.groupId,
  };

  await initUpdateDomain();
  if (permissionStore().organizationModel === 'PERSONAL') {
    const generalDomain = domainList.value?.find((d) => d.system);
    onUpdateDomain(DomainTypeEnum.domain, {
      domainId: generalDomain?.id,
      domainName: generalDomain?.name,
    });
  }
};

watch(
  [
    () => props.taskDetail?.domainId,
    () => props.taskDetail?.projectId,
    () => props.taskDetail?.subprojectId,
    () => props.taskDetail?.assigneeId,
    () => props.taskDetail?.groupId,
  ],
  () => {
    initDomain();
  }
);

initDomain();

const updateDomainAfterAssigneeChanged = async (userId, groupId) => {
  formData.value.assigneeId = userId;
  formData.value.groupId = groupId;
  await getDomainList();
  resetDomainAfterChangeAssign(() => {
    emit('save', formData.value);
  });
};

const isOpenCreateModal = ref(false);

const onOpenAddNew = () => {
  isOpenCreateModal.value = true;
};

const onCloseAddNew = () => {
  isOpenCreateModal.value = false;
};
const onCreateProjectSuccess = () => {
  isOpenCreateModal.value = false;
  initDomain();
};

defineExpose({
  removeDomain,
  initDomain,
  updateDomainAfterAssigneeChanged,
});
</script>
<template>
  <div class="flex items-center flex-wrap relative max-w-full">
    <div
      v-if="
        formData?.domainId ||
        formData?.projectId ||
        formData?.subprojectId ||
        formData?.domainName ||
        formData?.projectName ||
        formData?.subprojectName ||
        formData?.domainId ||
        formData?.projectId ||
        formData?.subprojectId
      "
      class="flex items-center flex-wrap mr-1"
    >
      <DomainTag
        v-if="formData?.domainId || formData?.domainName"
        class="w-max mr-0.5 mb-0.5"
        removeable
        @remove="removeScopeByType(DomainTypeEnum.domain)"
      >
        <DomainById v-if="formData?.domainId" :domain-id="formData?.domainId" />
        <span v-else>
          {{ formData?.domainName }}
        </span>
      </DomainTag>
      <DomainTag
        v-if="formData?.projectId || formData?.projectName"
        class="w-max mr-0.5 mb-0.5"
        removeable
        @remove="removeScopeByType(DomainTypeEnum.project)"
      >
        <ProjectById
          v-if="formData?.projectId"
          :project-id="formData?.projectId"
        />
        <span v-else>
          {{ formData?.projectName }}
        </span>
      </DomainTag>
      <DomainTag
        v-if="formData?.subprojectId || formData?.subprojectName"
        class="w-max mr-0.5 mb-0.5"
        removeable
        @remove="removeScopeByType(DomainTypeEnum.subProject)"
      >
        <ProjectById
          v-if="formData?.subprojectId"
          :project-id="formData?.subprojectId"
        />
        <span v-else>
          {{ formData?.subprojectName }}
        </span>
      </DomainTag>
    </div>
    <div
      v-if="
        (!formData?.domainId && !formData?.domainName) ||
        (!formData?.projectId && !formData?.projectName) ||
        (!formData?.subprojectId && !formData?.subprojectName)
      "
      class="flex items-start flex-wrap"
    >
      <template v-if="!formData?.domainId && !formData?.domainName">
        <template v-if="selectOnly || domainList?.length > 5">
          <VigSelect
            :placeholder="$t('COMMOM_LABEL_PLACEHOLDER_SELECT_DOMAIN')"
            key-search="domainName"
            key-label="domainName"
            :origin-options="domainList"
            class="mr-0.5 mb-0.5 do-not-close-dropdown"
            @on-choose="(item) => onUpdateDomain(DomainTypeEnum.domain, item)"
          />
        </template>
        <template v-else>
          <SynRadio
            v-for="item in domainList"
            :key="item.id"
            v-model="formData.domainId"
            v-model:name="formData.domainName"
            class="
              bg-gray-50
              hover:bg-gray-100
              mr-0.5
              mb-0.5
              py-1
              px-2
              rounded-full
            "
            :value="item.domainId"
            :label="item.domainName"
            @change="onSave"
          >
            <DomainById :domain-id="item.id" />
          </SynRadio>
        </template>
      </template>
      <template
        v-if="
          !updateDomainOnly &&
          !formData?.projectId &&
          !formData?.projectName &&
          projectsByOwner?.length > 0 &&
          !isUpdateDomainOnly
        "
      >
        <template v-if="projectsByOwner?.length > 5">
          <VigSelect
            :placeholder="$t('COMMOM_LABEL_PLACEHOLDER_SELECT_SUB_DOMAIN')"
            key-search="name"
            key-label="name"
            class="mr-0.5 mb-0.5 do-not-close-dropdown"
            :origin-options="projectsByOwner"
            @on-choose="(item) => onUpdateDomain(DomainTypeEnum.project, item)"
          />
        </template>
        <template v-else>
          <SynRadio
            v-for="project in projectsByOwner"
            :key="project.id"
            v-model="formData.projectId"
            v-model:name="formData.projectName"
            class="
              bg-gray-50
              hover:bg-gray-100
              py-1
              px-2
              rounded-full
              mr-0.5
              mb-0.5
            "
            :value="project.id"
            :label="project.name"
            @change="onSave"
          />
        </template>
      </template>
      <template
        v-if="
          !formData?.subprojectId &&
          !formData?.subprojectName &&
          subProjectsByOwner?.length > 0 &&
          !isUpdateDomainOnly
        "
      >
        <template v-if="subProjectsByOwner?.length > 5">
          <VigSelect
            :placeholder="$t('COMMOM_LABEL_PLACEHOLDER_SELECT_SUB_SUB_DOMAIN')"
            key-search="name"
            key-label="name"
            class="mr-0.5 mb-0.5 do-not-close-dropdown"
            :origin-options="subProjectsByOwner"
            @on-choose="
              (item) => onUpdateDomain(DomainTypeEnum.subProject, item)
            "
          />
        </template>
        <template v-else>
          <SynRadio
            v-for="project in subProjectsByOwner"
            :key="project.id"
            v-model="formData.subprojectId"
            v-model:name="formData.subprojectName"
            class="
              mr-0.5
              mb-0.5
              bg-gray-50
              hover:bg-gray-100
              py-1
              px-2
              rounded-full
            "
            :value="project.id"
            :label="project.name"
            :name="project.name"
            @change="onSave"
          />
        </template>
      </template>
    </div>

    <div
      v-if="isCreateableDomain"
      class="
        mx-1
        h-6
        w-6
        rounded-full
        flex-center
        bg-current-500
        hover:bg-current-300
        cursor-pointer
      "
      @click.stop="onOpenAddNew"
    >
      <SynIcon custom-class="h-3 w-3 fill-white" name="plus" />
    </div>
  </div>
  <DomainCreateModal
    v-if="isCreateableDomain && isOpenCreateModal"
    :domain-id="formData.domainId"
    :parent-id="formData.projectId"
    @on-close="onCloseAddNew"
    @on-create-success="onCreateProjectSuccess"
  />
</template>
