<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import orgStore from '@/store/organization';
import userStore from '@/store/user';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { isValidPhoneNumber } from '@/ui/hooks/commonFunction';
import organizationStore from '@/store/organization';
import { memberRangeFull } from '@/ui/common/constants/org';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';

defineProps({
  parentLink: {
    type: String,
    default: 'settings/organization',
  },
});

const _organizationStore = organizationStore();
const _orgStore = orgStore();
const _userStore = userStore();

const idOrg = getLocalStorage(StorageConstant.ACTIVE_ORG);
const fileName = computed(() => _userStore.fileName);
const organizationTypes = computed(() => _orgStore.organizationTypes);
const orgInfo = computed(() => _orgStore.organizationInfo);
const organizationModels = computed(
  () => _organizationStore.organizationModels
);
const currentOrgType = ref(null as any);

const attachmentCode = computed(() => _organizationStore.attachmentCode);
const membersRange = computed(() =>
  orgInfo.value?.model === 'PERSONAL'
    ? [
        { key: translate('COMMON_LABEL_PERSONAL'), value: null },
        ...memberRangeFull,
      ]
    : memberRangeFull
);

const addressDetail = ref();
const isLoading = ref(false);
const isEdit = ref(false);
const isAddAvatar = ref(false);
const file = ref();
const isLoadingSaveInfo = ref(false);
const isChange = ref({
  name: false,
  phone: false,
  address: false,
  member: false,
  websiteUrl: false,
  taxCode: false,
});
const infoPayload = ref({
  id: idOrg,
  name: '',
  logo: '',
  model: 'COMPANY',
  type: '',
  size: '11-20',
  address: '',
  websiteUrl: '',
  taxCode: '',
  addressDetail: '',
  phone: '',
  subType: '',
} as any);

onMounted(async () => {
  await _orgStore.getOrganizationConfigDefault();
  await _organizationStore.getOrganizationAttachmentCode();
  await init();
  currentOrgType.value = orgInfo.value.type;
});

const init = async () => {
  isLoading.value = true;
  try {
    await _orgStore.getOrganizationInfo(idOrg);
  } catch (e) {
    isLoading.value = false;
  }
  infoPayload.value.id = idOrg;
  updateInfoPayload(orgInfo.value);
  isLoading.value = false;
};

const updateInfoPayload = (info) => {
  infoPayload.value = {
    ...infoPayload.value,
    name: info['name'],
    logo: info['logo'],
    model: info['model'],
    type: info['type'],
    size: info['size'],
    address: info['address'],
    addressDetail: info['addressDetail'],
    phone: info['phone'],
    subType: info['subType'],
    websiteUrl: info['websiteUrl'],
    taxCode: info['taxCode'],
  };
};

const onGenerateAttachmentCode = async () => {
  settingGlobalModal({
    type: 'confirm',
    title:
      translate('COMMON_CONFIRM_BEFORE_GENERATE_ATTACHMENT_CODE') ||
      'Are you sure you want to generate a new code?',
    content:
      translate('COMMON_CONFIRM_BEFORE_GENERATE_ATTACHMENT_CODE_BODY') ||
      'Once the new code is generated, the current code will not be able use to attach your organization.',
    confirmable: true,
    closeable: true,
  });
  const confirmed = await ask();
  if (confirmed) {
    await _organizationStore.generateOrganizationAttachmentCode();
  }
};

const onCopyAttachmentCode = () => {
  const copied = copyToClipBroard(
    attachmentCode.value || orgInfo?.value?.attachmentCode
  );
  if (copied) {
    openNotification({
      body: translate('COMMON_LABEL_COPIED'),
      duration: 2000,
    });
  }
};

const onAddressSelect = (value) => {
  if (value) {
    addressDetail.value = JSON.stringify(value);
  }
  isChange.value.address = true;
};

const handleUpdateAvatarOrg = async (file) => {
  if (!file) return;
  isLoadingSaveInfo.value = true;
  try {
    await _userStore.uploadAvatarAws(file);
  } catch (e) {
    isLoadingSaveInfo.value = false;
  }
  infoPayload.value.logo = fileName.value;
  infoPayload.value.address =
    addressDetail.value?.fullAddress || infoPayload.value.address;

  try {
    await _orgStore.updateOrganization({
      ...infoPayload.value,
      addressDetail: addressDetail.value,
    });
  } catch (e) {
    isLoadingSaveInfo.value = false;
  }
  await init();
  isLoadingSaveInfo.value = false;
  isAddAvatar.value = !isAddAvatar.value;
};

const handleSaveInfo = async () => {
  if (!infoPayload.value?.name?.trim()) return;
  isLoadingSaveInfo.value = true;

  infoPayload.value.logo = orgInfo.value.logo;
  infoPayload.value.address =
    addressDetail.value?.fullAddress || infoPayload.value.address;

  try {
    await _orgStore.updateOrganization({
      ...infoPayload.value,
      addressDetail: addressDetail.value,
    });
  } catch (e) {
    isLoadingSaveInfo.value = false;
  }

  await init();
  isLoadingSaveInfo.value = false;
  isEdit.value = false;
};

const onChangeMembers = (rangeValue) => {
  infoPayload.value.size = rangeValue;
  isChange.value.member = true;
};

const onEdit = () => {
  isEdit.value = true;
};

const onCancel = () => {
  isEdit.value = false;
  updateInfoPayload(orgInfo.value);
  currentOrgType.value = orgInfo.value.type;
};

const onChangeOptionOrgType = (item) => {
  infoPayload.value.type = item;
  if (item !== 'OTHER') infoPayload.value.subType = undefined;
};
//
// const onResetOrgType = () => {
//   currentOrgType.value = '';
//   infoPayload.value.type = '';
// };

const labelNameByType = computed(() => {
  if (infoPayload.value.model == 'COMPANY')
    return translate('ORGANIZATION_LABEL_NAME_COMPANY');
  if (infoPayload.value.model == 'TEAM')
    return translate('ORGANIZATION_LABEL_NAME_TEAM');
  if (infoPayload.value.model == 'GROUP')
    return translate('ORGANIZATION_LABEL_NAME_GROUP');
  return translate('ORG_SETUP_NAME_PERSONAL');
});
const placeholderByType = computed(() => {
  let placeholderKey = 'ORG_SETUP_PLACEHOLDER_NAME_COMPANY';
  switch (infoPayload.value?.model) {
    case 'TEAM':
      placeholderKey = 'ORG_SETUP_PLACEHOLDER_NAME_TEAM';

      break;
    case 'GROUP':
      placeholderKey = 'ORG_SETUP_PLACEHOLDER_NAME_GROUP';

      break;
    case 'PERSONAL':
      placeholderKey = 'ORG_SETUP_PLACEHOLDER_NAME_PERSONAL';

      break;

    default:
      break;
  }
  return translate(placeholderKey);
});
</script>

<template>
  <!--  HEADER -->
  <div class="py-3 px-3 flex items-center justify-between border-b">
    <a
      class="
        flex-center
        cursor-pointer
        space-x-2
        font-medium
        text-current-500
        fill-current
        py-1
        px-2
        rounded
        hover:bg-current-50 hover:bg-opacity-50 hover:text-current-600
      "
      :href="parentLink"
    >
      <SynIcon name="arrow-left" />
      <span>{{ $t('COMMON_LABEL_ORGANIZATION') }}</span>
    </a>
  </div>

  <!-- CONTENT -->
  <div class="flex-1 flex flex-col pt-3 w-full min-h-0 overflow-hidden">
    <!-- public information   -->
    <div class="flex-1 min-h-0 flex flex-col space-y-2 w-full px-6">
      <div class="flex-1 flex w-full min-h-0">
        <div class="flex flex-col space-y-4 px-1" style="width: 45%">
          <!--  MODEL -->
          <div class="flex align-center justify-between">
            <div class="flex items-center justify-between">
              <span class="font-medium">{{
                $t('COMMON_LABEL_INFORMATION')
              }}</span>
            </div>
            <div class="flex-center space-x-1">
              <SynButton
                v-if="!isEdit"
                name-icon="edit"
                :label="$t('COMMON_LABEL_EDIT')"
                type-outline
                custom-class="text-xs rounded px-2 py-1"
                @click="onEdit()"
              />
              <template v-else>
                <SynButton
                  :label="$t('COMMON_LABEL_CANCEL')"
                  type-text
                  color="gray"
                  :disabled="isLoadingSaveInfo"
                  custom-class="text-xs rounded px-2 py-1"
                  @click="onCancel"
                />
                <SynButton
                  :label="$t('COMMON_LABEL_SAVE')"
                  :disabled="
                    !(
                      isLoadingSaveInfo ||
                      Object.values(isChange).includes(true) ||
                      currentOrgType !== orgInfo.type ||
                      infoPayload.subType !== orgInfo.subType
                    )
                  "
                  custom-class="text-xs rounded px-2 py-1"
                  @click="handleSaveInfo"
                />
              </template>
            </div>
          </div>
          <div
            class="flex flex-col mt-4 small-scrollbar overflow-auto space-y-4"
          >
            <div class="flex flex-col">
              <span class="text-gray-600 text-sm">
                {{ $t('COMMON_LABEL_MODEL') }}
              </span>
              <div class="flex items-center space-x-2">
                <template v-for="item in organizationModels" :key="item">
                  <div
                    class="flex-center rounded-full px-2 py-1 text-sm space-x-1"
                    :class="
                      orgInfo?.model === item.code
                        ? 'text-white bg-current-500 border border-current-500'
                        : 'border text-gray-500 border-gray-200 bg-gray-50'
                    "
                    style="min-width: 4rem"
                  >
                    <SynIcon
                      v-if="orgInfo?.model === item.code"
                      name="circle-checkbox"
                      custom-class="fill-white w-3.5 h-3.5"
                      is-active
                    />
                    <span>{{ item.name }}</span>
                  </div>
                </template>
              </div>
            </div>

            <!--            TYPE-->
            <div
              v-if="
                organizationTypes?.find((el) => el.code === orgInfo?.type)?.name
              "
              class="flex flex-col space-y-1"
            >
              <span class="text-gray-600 text-sm">
                {{ $t('ORGANIZATION_LABEL_TYPE') }}
              </span>
              <div v-if="!isEdit" class="px-3 py-2 rounded-md bg-gray-50">
                <div v-if="orgInfo.type === 'OTHER'">
                  <span class="mr-2">{{ orgInfo.subType }}</span>
                  <span class="italic text-gray-400"
                    >(
                    {{
                      organizationTypes?.find(
                        (el) => el.code === currentOrgType
                      )?.name
                    }}
                    )</span
                  >
                </div>
                <span v-else>{{
                  organizationTypes?.find((el) => el.code === currentOrgType)
                    ?.name
                }}</span>
              </div>
              <div v-else class="flex align-center space-x-2">
                <SynSelect
                  v-model="currentOrgType"
                  :options="organizationTypes"
                  key-field="code"
                  text-field="name"
                  style="width: 40%"
                  @change="onChangeOptionOrgType"
                />
                <div v-if="currentOrgType === 'OTHER'" class="flex-1">
                  <SynInput
                    v-model="infoPayload.subType"
                    autofocus
                    custom-class="text-normal"
                    :placeholder="
                      $t('SETUP_ORGANIZATION_TELL_US_YOUR_BUSINESS')
                    "
                  />
                </div>
              </div>
            </div>

            <!--  NAME -->
            <div class="flex flex-col space-y-1">
              <span class="text-gray-600 text-sm">
                {{ labelNameByType }}
                <span class="text-red-500">*</span></span
              >
              <div
                v-if="!isEdit"
                class="flex items-center px-3 py-2 bg-gray-50 rounded"
                :class="
                  !orgInfo?.name?.trim() ? 'text-gray-500' : 'font-normal'
                "
              >
                {{ orgInfo?.name || $t('ORGANIZATION_LABEL_NAME_COMPANY') }}
              </div>
              <div v-else class="w-full">
                <SynFormInput
                  :error-message="
                    isEdit && !infoPayload?.name?.trim()
                      ? 'COMMON_THIS_FIELD_REQUIRED'
                      : ''
                  "
                >
                  <SynInput
                    v-model="infoPayload.name"
                    autofocus
                    :placeholder="placeholderByType"
                    :max-length="60"
                    :error-message="
                      isEdit && !infoPayload?.name?.trim()
                        ? $t('COMMON_THIS_FIELD_REQUIRED')
                        : ''
                    "
                    @change="isChange.name = true"
                  />
                </SynFormInput>
              </div>
            </div>

            <!--  PHONE -->
            <div
              v-if="orgInfo?.model !== 'PERSONAL'"
              class="flex flex-col space-y-1"
            >
              <span class="text-gray-600 text-sm">
                {{ $t('USERMANAGEMENT_FORM_PHONE') }}
              </span>
              <div
                v-if="!isEdit"
                class="flex items-center px-3 py-2 bg-gray-50 rounded"
                :class="
                  !orgInfo?.phone?.trim() ? 'text-gray-500' : 'font-normal'
                "
              >
                <span v-if="orgInfo?.phone">
                  {{ orgInfo?.phone }}
                </span>
                <span v-else class="text-sm text-gray-400 italic">
                  {{ $t('COMMON_LABEL_NOT_SET') }}
                </span>
              </div>
              <div v-else class="w-full">
                <SynFormInput
                  :error-message="
                    (isEdit && !infoPayload?.phone && isChange.phone) ||
                    (isEdit &&
                    !isValidPhoneNumber(infoPayload?.phone) &&
                    isChange.phone
                      ? 'COMMON_INVALID_PHONE_NUMBER'
                      : '')
                  "
                >
                  <SynTelInput
                    :value="infoPayload?.phone"
                    :error="
                      (isEdit && infoPayload?.phone) ||
                      (isEdit && !isValidPhoneNumber(infoPayload?.phone)
                        ? $t('COMMON_INVALID_PHONE_NUMBER')
                        : '')
                    "
                    :auto-defacult-country="false"
                    @input="infoPayload.phone = $event.number"
                    @blur="infoPayload.phone = $event"
                    @change="isChange.phone = true"
                  />
                </SynFormInput>
              </div>
            </div>

            <!--  ADDRESS -->
            <div
              v-if="orgInfo?.model !== 'PERSONAL'"
              class="flex flex-col space-y-1"
            >
              <span class="text-gray-600 text-sm">{{
                $t('USERMANAGEMENT_TABLE_ADDRESS')
              }}</span>
              <div
                v-if="!isEdit"
                class="flex items-center px-3 py-2 bg-gray-50 rounded"
                :class="
                  !orgInfo?.address?.trim() ? 'text-gray-500' : ' font-normal '
                "
              >
                <span v-if="orgInfo?.address">
                  {{ orgInfo?.address }}
                </span>
                <span v-else class="text-sm text-gray-400 italic">
                  {{ $t('COMMON_LABEL_NOT_SET') }}
                </span>
              </div>
              <div v-else class="w-full">
                <SynFormInput>
                  <VigAddressBox
                    v-model="infoPayload.address"
                    :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_ADDRESS')"
                    class="vig-input text-sm"
                    @on-address-select="onAddressSelect"
                  />
                </SynFormInput>
              </div>
            </div>

            <!--  WEBSITE -->
            <div
              v-if="orgInfo?.model !== 'PERSONAL'"
              class="flex flex-col space-y-1"
            >
              <span class="text-gray-600 text-sm">{{
                $t('USERMANAGEMENT_TABLE_WEBSITE')
              }}</span>
              <div
                v-if="!isEdit"
                class="flex items-center px-3 py-2 bg-gray-50 rounded"
                :class="
                  !orgInfo?.websiteUrl?.trim()
                    ? 'text-gray-500'
                    : ' font-normal '
                "
              >
                <span v-if="orgInfo?.websiteUrl">
                  {{ orgInfo?.websiteUrl }}
                </span>
                <span v-else class="text-sm text-gray-400 italic">
                  {{ $t('COMMON_LABEL_NOT_SET') }}
                </span>
              </div>
              <div v-else class="w-full">
                <SynInput
                  v-model="infoPayload.websiteUrl"
                  custom-class="text-sm"
                  :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_WEBSITE')"
                  @change="isChange.websiteUrl = true"
                />
              </div>
            </div>

            <!--  TAX_CODE -->
            <div
              v-if="orgInfo?.model !== 'PERSONAL'"
              class="flex flex-col space-y-1"
            >
              <span class="text-gray-600 text-sm">{{
                $t('USERMANAGEMENT_TABLE_TAXCODE')
              }}</span>
              <div
                v-if="!isEdit"
                class="flex items-center px-3 py-2 bg-gray-50 rounded"
                :class="
                  !orgInfo?.taxCode?.trim() ? 'text-gray-500' : ' font-normal '
                "
              >
                <span v-if="orgInfo?.taxCode">
                  {{ orgInfo?.taxCode }}
                </span>
                <span v-else class="text-sm text-gray-400 italic">
                  {{ $t('COMMON_LABEL_NOT_SET') }}
                </span>
              </div>
              <div v-else class="w-full">
                <SynInput
                  v-model="infoPayload.taxCode"
                  custom-class="text-sm"
                  :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_TAXCODE')"
                  @change="isChange.taxCode = true"
                />
              </div>
            </div>

            <!--  MEMBER -->
            <div
              v-if="membersRange && orgInfo?.model !== 'PERSONAL'"
              class="flex flex-col space-y-1"
            >
              <span class="text-gray-600 text-sm">
                {{
                  $t(
                    infoPayload.model == 'TEAM'
                      ? 'ORGANIZATION_LABEL_TEAM_SIZE'
                      : 'ORGANIZATION_LABEL_ORGANIZATION_SIZE'
                  )
                }}
              </span>
              <div class="flex items-center flex-wrap">
                <template v-for="(range, index) in membersRange" :key="index">
                  <div
                    class="mr-2 mb-1 flex-center rounded-full px-2 py-1 text-xs"
                    :class="{
                      'bg-gray-50 text-gray-500':
                        !isEdit && infoPayload.size !== range.value,
                      'border border-gray-200':
                        infoPayload.size !== range.value,
                      'text-white bg-current-500 border border-current-500':
                        infoPayload.size === range.value,
                      'cursor-pointer': isEdit,
                      '3xl:flex-1 3xl:mr-0':
                        memberRangeFull.length - 1 === index,
                    }"
                    @click="isEdit ? onChangeMembers(range?.value) : ''"
                  >
                    {{ range.key }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- INFO DIF   -->
        <div class="flex flex-col w-full xl:w-5/12 px-6 pb-6">
          <!--  AVATAR    -->
          <div class="flex flex-col space-y-2 w-full">
            <div class="flex flex-col space-y-1 w-max">
              <UploadAvatarIcon
                :file="file"
                :avatar-thumbnail-url="
                  orgInfo?.logoThumbnailUrl || orgInfo?.logoUrl
                "
                :is-update-success="isAddAvatar"
                :is-loading="isLoadingSaveInfo"
                :name="orgInfo?.name"
                is-show-button
                rounded="full"
                type-upload="GROUP"
                @upload-avatar-aws="(file) => handleUpdateAvatarOrg(file)"
              />
            </div>
          </div>
          <!--  GENERAL    -->
          <div class="mt-4 font-medium text-gray-700">
            {{ $t('COMMON_LABEL_LINK_INFORMATION') }}
          </div>
          <div
            class="
              bg-gray-50
              rounded
              mt-2
              p-3
              flex flex-col
              space-y-4
              text-gray-500 text-sm
            "
          >
            <div class="flex items-center justify-between">
              <span>{{ $t('ORGANIZATION_LABEL_REGISTER_DATE') }}</span>
              <span class="font-medium">
                <SynLabelDateTime
                  format="date"
                  :datetime="orgInfo?.registerdDate"
                />
              </span>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex-center space-x-1">
                <span>{{ $t('COMMON_ATTACHMENT_CODE') }}</span>
                <SynIcon
                  v-vig-tooltip="{
                    content: `
                    <div class='p-4 w-80 text-left bg-white flex flex-col space-y-2'>
                      <span class='font-semibold text-current'>
                      ${
                        $t('COMMON_LABEL_WHAT_IS_ATTACHMENT_CODE') ||
                        'What is Attachment code?'
                      }
                      </span>
                      <span class='font-normal'>${
                        $t('COMMON_ATTACHMENT_EXPLAIN') ||
                        'User can using to join your organization'
                      }</span>
                    </div>`,
                    arrow: true,
                    placement: 'bottom',
                    delay: 0,
                    theme: 'light',
                    animation: 'scale',
                  }"
                  name="question"
                  custom-class="h-3.5 w-3.5 hover:fill-current cursor-default"
                />
              </div>
              <div class="flex space-x-1 items-center">
                <span
                  class="font-medium px-3 h-6 bg-gray-200 flex-center rounded"
                  >{{ attachmentCode || orgInfo?.attachmentCode }}</span
                >
                <div
                  class="
                    flex-center
                    w-6
                    h-6
                    p-1
                    border
                    fill-gray-500
                    text-gray-500
                    rounded
                    cursor-pointer
                    hover:bg-gray-100
                  "
                  @click="onCopyAttachmentCode"
                >
                  <SynIcon name="copy" custom-class="w-3.5 h-3.5" />
                </div>
                <div
                  class="
                    flex-center
                    w-6
                    h-6
                    p-1
                    border
                    fill-gray-500
                    text-gray-500
                    rounded
                    cursor-pointer
                    hover:bg-gray-100
                  "
                  @click="onGenerateAttachmentCode"
                >
                  <SynIcon name="reload" custom-class="w-3 h-3" />
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <span>{{
                $t('TITLE_TOTAL_ACTIVE_MEMBERS') +
                ` (${orgInfo?.members?.length})`
              }}</span>
              <SynListAvatar
                v-if="orgInfo?.members?.length > 0"
                :users="orgInfo?.members"
                :custom-class="'w-6 h-6 text-xs'"
                :max-length="5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
