<script setup>
import { ref, computed } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { CalendarNotificationType } from '@/ui/common/constants/calendar';
import SynSelect from '@/ui/common/atoms/SynSelect/SynSelect.vue';

const emit = defineEmits(['onChangeNotice']);
const props = defineProps({
  noticeChoose: {
    type: String,
    default: CalendarNotificationType.NoticeAfter15Minutes,
  },
  widthClass: {
    type: String,
    default: 'w-full',
  },
  placement: {
    type: String,
    default: 'bottom-start',
  },
});

const noticed = computed(() => props.noticeChoose);
const noticeChoose = ref(noticed.value);

const noticeOption = [
  {
    label: translate('CALENDAR_NOTICE_ITEM_NOT_NOTICE'),
    time: CalendarNotificationType.DonotNotice,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM', { time: '0' }),
    time: CalendarNotificationType.NoticeAfter0Minutes,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM', { time: '5' }),
    time: CalendarNotificationType.NoticeAfter5Minutes,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM', { time: '10' }),
    time: CalendarNotificationType.NoticeAfter10Minutes,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM', { time: '15' }),
    time: CalendarNotificationType.NoticeAfter15Minutes,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM', { time: '30' }),
    time: CalendarNotificationType.NoticeAfter30Minutes,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOUR', { time: '1' }),
    time: CalendarNotificationType.NoticeAfter1Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '2' }),
    time: CalendarNotificationType.NoticeAfter2Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '3' }),
    time: CalendarNotificationType.NoticeAfter3Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '4' }),
    time: CalendarNotificationType.NoticeAfter4Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '5' }),
    time: CalendarNotificationType.NoticeAfter5Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '6' }),
    time: CalendarNotificationType.NoticeAfter6Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '7' }),
    time: CalendarNotificationType.NoticeAfter7Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '8' }),
    time: CalendarNotificationType.NoticeAfter8Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '9' }),
    time: CalendarNotificationType.NoticeAfter9Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '10' }),
    time: CalendarNotificationType.NoticeAfter10Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '11' }),
    time: CalendarNotificationType.NoticeAfter11Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_DAYS', { time: '0,5' }),
    time: CalendarNotificationType.NoticeAfter12Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_HOURS', { time: '18' }),
    time: CalendarNotificationType.NoticeAfter18Hour,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_DAY', { time: '1' }),
    time: CalendarNotificationType.NoticeAfter1Days,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_DAYS', { time: '2' }),
    time: CalendarNotificationType.NoticeAfter2Days,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_DAYS', { time: '3' }),
    time: CalendarNotificationType.NoticeAfter3Days,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_DAYS', { time: '4' }),
    time: CalendarNotificationType.NoticeAfter4Days,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_WEEK', { time: '1' }),
    time: CalendarNotificationType.NoticeAfter1Weeks,
  },
  {
    label: translate('CALENDAR_NOTICE_ITEM_WEEKS', { time: '2' }),
    time: CalendarNotificationType.NoticeAfter2Weeks,
  },
];

const onChangeOption = (value) => {
  emit('onChangeNotice', value);
};
</script>
<template>
  <SynSelect
    v-model="noticeChoose"
    :options="noticeOption"
    :placement="placement"
    key-field="time"
    text-field="label"
    :width-class="widthClass"
    @change="onChangeOption"
  />
</template>
