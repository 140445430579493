<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
// import { useRouter } from 'vue-router';
import ActionInBoard from '@/ui/modules/task/board/board-container/ActionInBoard.vue';
// import taskTabViewStore from "@/store/task/task-tab-view";
// import TaskBoardCardCompact from '@/ui/modules/task/board/task-card/TaskBoardCardCompact.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskAttachmentDetail from '@/ui/modules/task/detail/TaskAttachmentDetail.vue';
import TaskBoardCardLoading from '@/ui/modules/task/board/task-card/TaskBoardCardLoading.vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import {
  EFilterTaskDefault,
  ITaskSoftLocal,
  TASK_SORT_BY,
} from '@/application/constants/task.const';
import { arrayOrderBy, stringNomalize } from '@/ui/hooks/commonFunction';
import { getUrgencyValue, sortDefaultTaskList } from '@/ui/hooks/taskHook';
const MAX_ROW_FIRST_TIME = 10;
import TaskService from '@/application/services/task/TaskService';
import { FilterQuery } from '@/domain/entities/task/FilterTask';
// import myProfileStore from '@/store/auth/my-profile';
// import { encryptGroupId, encryptUserId } from '@/ui/plugins/crypto/crypto';
// import chatStore from '@/store/chat';
// import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import { LIST_FILTER_TASK_DEFAULT } from '@/application/constants/task.const';
import domainStore from '@/store/scope';
import groupStore from '@/store/group';
import userStore from '@/store/user';
import { translate } from '@/ui/plugins/i18n/myi18n';
import taskDrawerStore from '@/store/task/drawer';

const props = withDefaults(
  defineProps<{
    type: 'USER' | 'GROUP' | 'DOMAIN';
    ownerId?: number | string;
    isReadonly: boolean;
    selectable?: boolean;
    selectedTaskIds?: string[];
  }>(),
  {
    type: 'USER',
    ownerId: getCurrentUserId(),
    selectedTaskIds: () => [],
  }
);

const emit = defineEmits([
  'onCollapseBoard',
  'onClickDetail',
  'onAddNewTask',
  'onClose',
  'onConfirmSelect',
]);
// const _useRouter = useRouter();

// const _chatStore = chatStore();
const allTaskByIds = ref<any>();
// const myUserId = computed(() => myProfileStore().myProfile?.id);

const currentSortData = ref<ITaskSoftLocal>({
  ...TASK_SORT_BY.DEFAULT,
} as ITaskSoftLocal);

const taskIds = ref<any[]>([]);
const sortedTaskIds = ref<any[]>([]);
const isLoading = ref<boolean>(false);

const listTaskIdShowing = ref<string[]>([]);
const boardShowFinishedTask = ref<boolean>(false);

const isGroup = computed<any>(() => props.type === 'GROUP');
const isDomain = computed<any>(() => props.type === 'DOMAIN');
const listTaskOwner = computed<any>(() => {
  if (props.type == 'DOMAIN') {
    const domainByIds = domainStore().domainByIds;
    return domainByIds[props.ownerId];
  }
  if (props.type == 'GROUP') {
    const allGroupByIds = {
      ...groupStore().allGroupByIds,
      ...groupStore().myGroupByIds,
    };
    return allGroupByIds[props.ownerId];
  }
  return userStore().allUserByIds[props.ownerId];
});
const initComponent = async () => {
  isLoading.value = true;
  const payload = new FilterQuery({
    isDefault: true,
    listOwnerId: props.type === 'USER' ? props.ownerId : null,
    groupIds: props.type === 'GROUP' ? `${props.ownerId}` : null,
  });
  let res;

  switch (currentTabFilter.value?.id) {
    case EFilterTaskDefault.DO_TODAY:
      res = await TaskService.getInstance().getDoTodayTask(payload);
      break;
    case EFilterTaskDefault.OVERDUE_DATE:
      res = await TaskService.getInstance().getOverdueTask(payload);
      break;
    case EFilterTaskDefault.MY_RELATED:
      {
        const promiseList = [
          TaskService.getInstance().getCollaboratorTask(payload),
        ];
        if (boardShowFinishedTask.value)
          promiseList.push(
            TaskService.getInstance().getCollaboratorTask({
              ...payload,
              isDefault: false,
              status: '3',
            })
          );
        const resAll: any[] = await Promise.all(promiseList);

        const taskList = resAll.reduce((currentObject, currentItem) => {
          return [
            ...currentObject,
            ...currentItem?.result?.filter(
              (task) => !currentObject?.some((t) => t?.id == task?.id)
            ),
          ];
        }, []);

        res = {
          result: taskList,
        };
      }
      break;
    case EFilterTaskDefault.FINISHED:
      res = await TaskService.getInstance().getFinishedTask(payload);
      break;
    case EFilterTaskDefault.OTHERS:
      res = await TaskService.getInstance().getOtherTask(payload);
      break;
    default:
      res = await TaskService.getInstance().getTaskWithFilter(payload);
      break;
  }
  const result = res?.result;

  allTaskByIds.value = result?.reduce((currentObject, currentItem) => {
    return {
      ...currentObject,
      [currentItem?.id]: {
        ...currentItem,
        urgency: getUrgencyValue(currentItem?.priority, currentItem?.important),
      },
    };
  }, {});

  taskIds.value = allTaskByIds.value ? Object.keys(allTaskByIds.value) : [];
  isLoading.value = false;
};

onMounted(async () => {
  await initComponent();

  onSortData();
});

watch(
  () => props.ownerId,
  () => {
    initComponent();
  }
);

watch(
  () => isLoading.value,
  () => {
    onSortData();
  }
);

const isHasOpenAttachment = ref(null as any);
const currentTaskId = ref(0 as number);
const isOpenAttachmentDetail = ref(false);
const onOpenAttachmentDetail = (taskId) => {
  if (isHasOpenAttachment.value) isHasOpenAttachment.value = null;
  currentTaskId.value = taskId;
  isHasOpenAttachment.value = taskId;
  isOpenAttachmentDetail.value = true;
};

const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;
};

const isHovered = ref<boolean>(false);
const onHoverCard = () => {
  if (isHovered.value) return;
  onLoadMore();

  isHovered.value = true;
};
const onLoadMore = () => {
  listTaskIdShowing.value =
    sortedTaskIds.value?.length > 0
      ? [
          ...sortedTaskIds.value?.slice(
            0,
            listTaskIdShowing.value?.length + MAX_ROW_FIRST_TIME
          ),
        ]
      : [];
};

const onSortData = () => {
  const currentIterate =
    currentSortData.value?.key === 'DEFAULT'
      ? []
      : currentSortData.value?.orderData?.iterate;
  const currentOrder =
    currentSortData.value?.key === 'DEFAULT'
      ? []
      : currentSortData.value?.orderData?.order;

  sortedTaskIds.value = arrayOrderBy(
    taskIds.value?.map((taskId) => allTaskByIds.value[taskId]),
    [...currentIterate, ...sortDefaultTaskList.iterate],
    [...currentOrder, ...sortDefaultTaskList.order]
  )?.map((task: any) => task?.id);

  listTaskIdShowing.value =
    sortedTaskIds.value?.length > 0
      ? [...sortedTaskIds.value?.slice(0, MAX_ROW_FIRST_TIME)]
      : [];
};

const onChangeIterate = (sortData) => {
  currentSortData.value = sortData;

  onSortData();
};
const onChangeOrder = (orderData) => {
  currentSortData.value.orderData.order = [orderData];

  onSortData();
};

const onSearch = (keySearch) => {
  if (!keySearch) {
    onSortData();
    return;
  }
  sortedTaskIds.value = taskIds.value
    ?.map((taskId) => allTaskByIds.value[taskId])
    .filter(
      (t) =>
        stringNomalize(t.name?.toLowerCase()).includes(
          stringNomalize(keySearch?.toLowerCase())
        ) ||
        stringNomalize(t.code?.toLowerCase()).includes(
          stringNomalize(keySearch?.toLowerCase())
        ) ||
        stringNomalize(t.displayName?.toLowerCase()).includes(
          stringNomalize(keySearch?.toLowerCase())
        )
    )
    ?.map((task) => task?.id);

  listTaskIdShowing.value =
    sortedTaskIds.value?.length > 0
      ? [...sortedTaskIds.value?.slice(0, MAX_ROW_FIRST_TIME)]
      : [];
};

// const closeBubbleChatPanel = () => {
//   _chatStore.closeBubbleChat();

//   setTimeout(() => {
//     activeAndFocusOnConversationId.value = '';
//   });
// };

// const onGotoTaskClick = () => {
//   closeBubbleChatPanel();

//   if (props.type === 'USER') {
//     const redirectPath =
//       props.ownerId == myUserId.value
//         ? '/tasks/my-tasks'
//         : `/tasks/users/${encryptUserId(props.ownerId)}`;

//     _useRouter.push(redirectPath);
//   } else {
//     _useRouter.push(`/tasks/groups/${encryptGroupId(props.ownerId)}`);
//   }
// };

const currentTabFilter = ref<any>({
  id: EFilterTaskDefault.DEFAULT,
  active: true,
  name: '',
  key: 'All',
  labelKey: 'TASK_LIST_VIEW_ALL_LABEL',
  value: '3',
  color: '#008E8E',
  totalTask: 0,
  isDefault: true,
  visible: true,
});
const onChangeTaskFilter = (item) => {
  console.log('🚀 Hyrin ~ onChangeTaskFilter ~ item:', item);
  currentTabFilter.value = item;
  initComponent();
};

const prepareDataUIOfTab = computed(() => {
  let result = {} as any;
  const linkImg = `https://d1tvqetrcurhzb.cloudfront.net/common/images/`;
  switch (currentTabFilter.value?.id) {
    case EFilterTaskDefault.DO_TODAY:
      result = {
        imgUrl:
          isGroup.value || isDomain.value
            ? `${linkImg}do-today-group-task.png`
            : `${linkImg}do-today-task.png`,
        imgSize: 'h-40 w-auto',
        titleKey:
          isGroup.value || isDomain.value
            ? 'TASK_LIST_DONT_HAVE_TODAY_TASK_GROUP_TITLE'
            : 'TASK_LIST_DONT_HAVE_TODAY_TASK_TITLE',
        subTitleKey:
          isGroup.value || isDomain.value
            ? 'TASK_LIST_DONT_HAVE_TODAY_TASK_GROUP_SUB_TITLE'
            : 'TASK_LIST_DONT_HAVE_TODAY_TASK_SUB_TITLE',
      };
      break;
    case EFilterTaskDefault.OVERDUE_DATE:
      result = {
        imgUrl: `${linkImg}overdue_task_1.png`,
        imgSize: 'h-40 w-auto',
        titleKey:
          isGroup.value || isDomain.value
            ? 'TASK_LIST_DONT_HAVE_OVERDUE_TASK_GROUP_TITLE'
            : 'TASK_LIST_DONT_HAVE_OVERDUE_TASK_TITLE',
        subTitleKey:
          isGroup.value || isDomain.value
            ? 'TASK_LIST_DONT_HAVE_OVERDUE_TASK_GROUP_SUB_TITLE'
            : 'TASK_LIST_DONT_HAVE_OVERDUE_TASK_SUB_TITLE',
      };
      break;
    case EFilterTaskDefault.FINISHED:
      result = {
        imgUrl: `${linkImg}finished_task_1.png`,
        imgSize: 'h-40 w-auto',
        titleKey:
          isGroup.value || isDomain.value
            ? 'TASK_LIST_DONT_HAVE_FINISHED_TASK_GROUP_TITLE'
            : 'TASK_LIST_DONT_HAVE_FINISHED_TASK_TITLE',
        subTitleKey:
          isGroup.value || isDomain.value
            ? 'TASK_LIST_DONT_HAVE_FINISHED_TASK_GROUP_SUB_TITLE'
            : 'TASK_LIST_DONT_HAVE_FINISHED_TASK_SUB_TITLE',
      };
      break;
    default:
      result = {
        imgUrl:
          isGroup.value || isDomain.value
            ? `${linkImg}all-task-grouplist.png`
            : `${linkImg}all-task-mylist.png`,
        imgSize: 'h-40 w-auto',
        titleKey:
          isGroup.value || isDomain.value
            ? 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_TITLE'
            : 'TASK_LIST_DONT_HAVE_ALL_TASK_TITLE',
        subTitleKey:
          isGroup.value || isDomain.value
            ? 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_SUB_TITLE'
            : 'TASK_LIST_DONT_HAVE_ALL_TASK_SUB_TITLE',
      };
      break;
  }
  if (isGroup.value || isDomain.value) {
    result = {
      ...result,
      name: listTaskOwner.value?.isMe
        ? translate('COMMON_LABEL_ME')
        : listTaskOwner.value?.name,
      typeKey: isGroup.value
        ? 'COMMON_LABEL_GROUP'
        : isDomain.value
        ? 'DOMAIN_LABEL_TITLE'
        : '',
    };
  }
  return result;
});

const onClickDetail = (taskDetail) => {
  taskDrawerStore().pushCurrentIds({
    ...taskDetail,
    tabType: 'DETAIL',
  });
  emit('onClickDetail', taskDetail);
};

const onConfirmSelect = () => {
  emit('onConfirmSelect', allTaskByIds.value);
};
</script>

<template>
  <div
    v-bind="$attrs"
    class="flex flex-col h-full hover-to-show__parent"
    style="width: 25rem"
    @mouseover="onHoverCard"
  >
    <div class="p-2 flex items-center justify-between bg-gray-50">
      <VigDropdown :arrow="false" placement="bottom-start">
        <template #dropdown-toggle>
          <div
            class="
              px-2
              flex-center
              space-x-2
              h-6
              rounded
              cursor-pointer
              hover:bg-gray-100
            "
            :style="`background-color: ${currentTabFilter?.color}20; color: ${currentTabFilter?.color}`"
          >
            <span class="text-sm">
              {{
                $t(currentTabFilter?.labelKey, {
                  username: listTaskOwner?.isMe
                    ? $t('COMMON_LABEL_ME')
                    : (listTaskOwner?.firstName || listTaskOwner?.name),
                })
              }}
            </span>
          </div>
        </template>
        <template #dropdown-menu>
          <section class="flex flex-col py-1 text-sm" style="min-width: 12rem">
            <div
              v-for="(item, index) in Object.values(LIST_FILTER_TASK_DEFAULT)"
              :key="index"
              class="
                list-li
                flex
                items-center
                space-x-3
                fill-gray-500
                text-gray-800 text-sm
                dropdown-item
              "
              @click.stop="onChangeTaskFilter(item)"
            >
              {{
                $t(item?.labelKey, {
                  username: listTaskOwner?.isMe
                    ? $t('COMMON_LABEL_ME')
                    : (listTaskOwner?.firstName || listTaskOwner?.name),
                })
              }}
            </div>
          </section>
        </template>
      </VigDropdown>
      <ActionInBoard
        :current-sort-data="currentSortData"
        readonly
        is-hidden-collapse-button
        is-show-close-button
        @on-change-iterate="onChangeIterate"
        @on-change-order="onChangeOrder"
        @on-search="onSearch"
      >
        <template #suffix>
          <div
            class="flex-center w-6 h-6 rounded cursor-pointer hover:bg-gray-100"
            @click="$emit('onClose')"
          >
            <SynIcon
              name="close-bold"
              custom-class="w-5 h-5 fill-gray-500 text-gray-500"
            />
          </div>
        </template>
      </ActionInBoard>
    </div>

    <div
      v-scroll-infinite="onLoadMore"
      class="
        bg-gray-50
        rounded
        flex-1
        overflow-y-auto overflow-x-hidden
        small-scrollbar
        relative
      "
    >
      <TaskBoardCardLoading v-if="isLoading" class="m-2" />

      <template v-else-if="listTaskIdShowing?.length > 0">
        <TaskCardItem
          v-for="taskId in listTaskIdShowing"
          :key="taskId"
          class="m-2"
          :selectable="selectable"
          readonly
          disabled-open-drawer
          :task-detail="allTaskByIds[taskId]"
          :is-selected="selectedTaskIds?.some((id) => id == taskId)"
          :is-selecting="selectedTaskIds && selectedTaskIds?.length > 0"
          @on-show-files="onOpenAttachmentDetail(taskId)"
          @on-click-detail="onClickDetail(allTaskByIds[taskId])"
        />
      </template>

      <template v-else>
        <div class="flex-center flex-col pb-4 pt-12">
          <VigImage
            :path="prepareDataUIOfTab?.imgUrl"
            :custom-class="`${prepareDataUIOfTab?.imgSize || 'h-42 w-96'}`"
          />
          <span
            class="text-current-700 pt-8 text-sm"
            v-html="
              isGroup || isDomain
                ? $t(prepareDataUIOfTab?.titleKey, {
                    owner: prepareDataUIOfTab?.name,
                    type: $t(prepareDataUIOfTab?.typeKey),
                  })
                : $t(prepareDataUIOfTab?.titleKey)
            "
          ></span>
          <span
            v-if="
              !(
                isDomain &&
                (currentTabFilter?.id === EFilterTaskDefault.OVERDUE_DATE ||
                  currentTabFilter?.id === EFilterTaskDefault.FINISHED)
              )
            "
            class="text-gray-500 text-xs py-2"
            v-html="
              isGroup || isDomain
                ? $t(prepareDataUIOfTab?.subTitleKey, {
                    owner: prepareDataUIOfTab?.name,
                    type: $t(prepareDataUIOfTab?.typeKey),
                  })
                : $t(prepareDataUIOfTab?.subTitleKey)
            "
          ></span>
        </div>
      </template>
    </div>
    <div v-if="selectedTaskIds?.length > 0" class="px-4 py-2 flex justify-end">
      <div class="flex-center space-x-2">
        <SynButton label="Cancel" type-text color="gray" />
        <SynButton @click="onConfirmSelect">
          <span>Select {{ selectedTaskIds?.length }} tasks</span>
        </SynButton>
      </div>
    </div>
  </div>

  <TaskAttachmentDetail
    v-if="isOpenAttachmentDetail"
    :task-id="currentTaskId"
    @on-close="onCloseAttachment"
  />
</template>
