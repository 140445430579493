<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { GroupMemberRole } from '@/ui/common/constants/constant';
import DepartmentAddMembers from '@/ui/modules/departments/components/DepartmentAddMembers.vue';
import departmentStore from '@/store/department';

const props = withDefaults(
  defineProps<{
    departmentId: number;
    departmentName: string;
    departmentMembers: any[];
  }>(),
  {}
);
const emit = defineEmits(['onCancel', 'onUpdateSuccess']);

const _departmentStore = departmentStore();

onMounted(() => {});

const selectedMembers = ref<any>([]);

const onCancel = () => {
  emit('onCancel');
};

const isSaveUser = ref(false);
const onSaveUpdateMembers = async () => {
  isSaveUser.value = true;
  const newMembers = selectedMembers.value.map((el: any) => {
    return {
      userId: el.userId,
      role: el.role ?? GroupMemberRole.User,
    };
  });
  const payload = {
    departmentId: props.departmentId,
    memberInfos: newMembers || [],
  };

  await _departmentStore.updateMembers(payload);

  emit('onUpdateSuccess');
  isSaveUser.value = false;
};
</script>
<template>
  <SynModal
    z-index="z-50"
    container-class="md:w-1/4"
    container-style="min-width: 44rem;max-height: 48rem"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <div class="w-full flex items-center text-base space-x-2">
        <span class="w-max">
          {{ $t('TASK_CREATE_GROUP_FORM_INVITE_COLLEAGUE') + ':' }}
        </span>
        <span class="text-current">{{ departmentName }}</span>
      </div>
    </template>
    <template #body>
      <!-- invite members -->
      <DepartmentAddMembers
        v-model:member-list="selectedMembers"
        :department-id="departmentId"
        :default-list="departmentMembers"
        :department-name="departmentName"
        is-select-user-step
      />
    </template>
    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          color="gray"
          type-text
          :label="$t('COMMON_LABEL_CANCEL')"
          :class="isSaveUser ? 'cursor-not-allowed' : ''"
          @click="onCancel"
        />
        <SynButton
          :is-loading="isSaveUser"
          :label="$t('COMMON_LABEL_SAVE')"
          :disabled="
            isSaveUser ||
            (selectedMembers?.length > 0 &&
              selectedMembers?.filter(
                (user) => user.role == GroupMemberRole.Admin
              )?.length == 0)
          "
          @click="onSaveUpdateMembers"
        />
      </div>
    </template>
  </SynModal>
</template>
