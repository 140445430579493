import request from '@/base/api/base-unauthorize-request';

export function refresh(data) {
    return request({
        url: 'refresh',
        method: 'post',
        data,
    });
}

export function logout(data) {
    return request({
        url: 'logout',
        method: 'post',
        data,
    });
}
