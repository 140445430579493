<script setup lang="ts">
import { computed, ref } from 'vue';
import UpdateMemberDomainSingle from '@/ui/modules/domains/component/UpdateMemberDomainSingle.vue';

import userStore from '@/store/user';
import domainStore from '@/store/scope';
// import { UpdateUserDomainRelativePayload } from '@/application/types/domain/DomainPayload';
const props = withDefaults(
  defineProps<{
    userId: number;
    isAnyProjects: boolean;
    domainId?: number | string;
    parentId?: number | string | null;
    projectId?: number | string | null;
    isLoadingButton?: boolean;
    domainName?: string;
    userProjectIds?: any[];
    isNeedGetProject: boolean;
  }>(),
  {
    domainName: '',
  }
);

const emit = defineEmits<{
  (e: 'onClose'): void;
  (
    e: 'onSubmit',
    data: {
      anyProjects: boolean;
      projectIds: number[];
    }
  ): void;
  (e: 'update:isAnyProjects', value: boolean): boolean;
}>();

const _userStore = userStore();
const _domainStore = domainStore();

const allUserByIds = computed(() => _userStore.allUserByIds);
const domainByIds = computed(() => _domainStore.domainByIds);
const userDetail = computed(() => allUserByIds.value[props.userId]);
const domainDetail = computed(() => {
  if (!props.domainId) return {};
  return domainByIds.value[props.domainId];
});

const onClose = () => {
  emit('onClose');
};

const onSubmit = async () => {
  const projectIdList = [] as number[];
  if (props.domainId) {
    userProjects.value?.forEach((project) => {
      if (!project?.id) return;
      if (project?.checked)
        projectIdList.push(parseInt(project?.id?.toString()));

      project?.subProjects?.forEach((subProject) => {
        if (!subProject?.id) return;
        if (subProject?.checked)
          projectIdList.push(parseInt(subProject?.id?.toString()));
      });
    });
    // Update any projects for user
    // await _domainStore.updateUserDomainRelative(
    //   new UpdateUserDomainRelativePayload({
    //     domainId: props.domainId,
    //     userId: props.userId,
    //     anyProjects: props.isAnyProjects,
    //     projectIds: projectIdList.toString(),
    //   })
    // );
  }
  emit('onSubmit', {
    anyProjects: !!props.isAnyProjects,
    projectIds: projectIdList,
  });
};

const userProjects = ref();
const onUserProjects = (data) => {
  userProjects.value = data;
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-1/2"
    style-body="p-0 flex flex-col"
    :container-style="'min-height: 250px; max-width: 600px'"
    :disable-click-outside="true"
    @cancel="onClose"
  >
    <template #header>
      <div class="flex space-x-2 font-semibold text-black">
        <span> {{ $t('COMMON_LABEL_ASSOCIATION') }}:</span>
        <span>{{ userDetail?.name }}</span>
        <template v-if="domainId">
          <span>-</span>
          <span class="text-current">
            {{ domainDetail?.name || domainName }}
          </span>
        </template>
      </div>
    </template>
    <template #body>
      <div class="p-4 flex-1 min-h-0 overflow-hidden flex flex-col">
        <UpdateMemberDomainSingle
          :is-any-projects="isAnyProjects"
          :user-id="userId"
          :domain-id="domainId"
          :parent-id="parentId"
          :project-id="projectId"
          :domain-name="domainName"
          :user-project-ids="userProjectIds"
          :is-need-get-project="isNeedGetProject"
          @update:is-any-projects="
            (value) => $emit('update:isAnyProjects', !!value)
          "
          @update:user-projects="onUserProjects"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end px-3 py-2">
        <div class="flex-center space-x-2">
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
            color="gray"
            type-text
            :label="$t('COMMON_LABEL_CANCEL')"
            @click="$emit('onClose')"
          />
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_CONFIRM')"
            :label="$t('COMMON_LABEL_CONFIRM')"
            :is-loading="isLoadingButton"
            @click="onSubmit"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
