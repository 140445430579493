import { translate } from '@/ui/plugins/i18n/myi18n';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { fileByExtension } from './file-constants';
import $constants from '@/ui/plugins/constants';

export function validateFile(
    file,
    allowedExtensions: string,
    sizeLimit = 25 // megabyte
): any {
    const sizeLimitByte = sizeLimit * 1048576;

    // destructuring file name and size from file object
    const { name: fileName, size: fileSize } = file;
    /*
     * if filename is apple.png, we split the string to get ["apple","png"]
     * then apply the pop() method to return the file extension
     *
     */
    if (!fileName || !fileName) return { isValid: true };

    const fileExtension = fileName.split('.').pop().toLowerCase();
    /* 
    check if the extension of the uploaded file is included 
    in our array of allowed file extensions
  */
    if (
        allowedExtensions !== '*' &&
        !allowedExtensions.includes(fileExtension)
    ) {
        return {
            fileName: fileName,
            errorTitle: translate('COMMON_LABEL_ERROR'),
            errorBody:
                translate('COMMON_FILE_ERROR_EXTENTION_NOT_VALID') ||
                `Extention is not valid`,
        };
    }
    if (fileSize > sizeLimitByte) {
        return {
            fileName: fileName,
            errorTitle: translate('COMMON_LABEL_ERROR'),
            errorBody:
                translate('COMMON_FILE_ERROR_FILE_SIZE_TOO_BIG') ||
                `File size too big`,
        };
    }
    return { isValid: true };
}

export const verifyFiles = async (
    files,
    allowedExtensions,
    maxSize,
    callback
) => {
    const errorFiles = [] as any[];
    for (let file of files) {
        file = mapContentTypeOfFile(file);
        const checkFile = validateFile(file, allowedExtensions, maxSize);
        if (checkFile?.isValid) {
            callback && callback(file);
        } else {
            errorFiles.push(checkFile);
        }
    }

    if (errorFiles?.length > 0) {
        let liElements = '';
        errorFiles.forEach((file) => {
            liElements =
                liElements +
                `<li class="flex items-center space-x-4 justify-between border-b">
        <span class="text-overflow-hidden-line w-2/3">${file.fileName}</span>
        <span class="text-xs text-red-400">${file.errorBody}</span>
      </li>`;
        });
        const content = `
        <div class="flex flex-col space-y-4" style="width: 32rem">
          <div class="flex items-start justify-start flex-col space-y-4 text-current text-left break-words">
           ${
               allowedExtensions == '*'
                   ? ''
                   : '<span>* ' +
                     translate('COMMON_FILE_ERROR_INVALID_EXTENSION', {
                         extension: allowedExtensions?.toString(),
                     }) +
                     '</span>'
           } 
          <span>
            * ${translate('COMMON_FILE_ERROR_INVALID_SIZE', {
                size: `${maxSize}Mb`,
            })}
            </span>
          </div>
          <ul class="w-full ">
            ${liElements}
          </ul>
        </div>
      `;

        settingGlobalModal({
            type: 'notification',
            title: '',
            content,
            confirmable: true,
        });

        await ask();
    }
};

export function getFileExtension(fileName, contentType?: string) {
    if (!fileName && contentType?.includes('audio')) return '.mp3';
    return fileName?.split('.').pop();
}

export function getFileNameFromPath(fileName) {
    return fileName?.split('/').pop();
}

export function getFileType(file) {
    if (file?.type) return file?.type;

    const extension = getFileExtension(
        file?.name || file?.fileName,
        file?.type
    );
    if (extension == 'doc') {
        return 'application/msword';
    }
    if (extension == 'docx') {
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    if (extension == 'xls') {
        return 'application/vnd.ms-excel';
    }
    if (extension == 'xlsx') {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    if (extension == 'ppt') {
        return 'application/vnd.ms-powerpoint';
    }
    if (extension == 'pptx') {
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
    if (extension == 'zip') {
        return 'application/zip';
    }
    if (extension == 'rar') {
        return 'application/octet-stream';
    }
}

export function isImage(file) {
    if (!file) return false;

    const fileName = file?.name || file?.fileUrl || '';
    const fileType = file?.type || file?.contentType;
    return (
        fileType?.includes('image') ||
        fileName?.match(/\.(jpeg|jpg|gif|png)$/) != null
    );
}

export const isOfficeFile = (fileType) => {
    return (
        $constants.FILE.TYPE.DOC.includes(fileType) ||
        $constants.FILE.TYPE.EXCEL.includes(fileType) ||
        $constants.FILE.TYPE.PPTX.includes(fileType)
    );
};

export const isGoogleFile = (fileType) => {
    const googleFileTypes = [
        $constants.FILE.TYPE.GOOGLE_DOC,
        $constants.FILE.TYPE.GOOGLE_SHEET,
        $constants.FILE.TYPE.GOOGLE_PRESENTATION,
    ];

    return googleFileTypes?.includes(fileType);
};

const mapContentTypeOfFile = (file: File) => {
    const { name: fileName, type: fileType } = file;
    if (fileType) return file;

    const fileExtension = fileName
        ? fileName.split('.').pop()?.toLowerCase()
        : 'txt';

    const contentType = getContentTypeViaExtension(fileExtension);
    return new File([file], fileName, {
        type: contentType,
    });
};

const getContentTypeViaExtension = (extentsion) => {
    return fileByExtension[extentsion]?.contentType;
};
