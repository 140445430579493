<template>
  <div class="">
    <div v-if="title" class="mb-2 font-semibold text-gray-600">
      {{ title }} :
    </div>
    <slot name="subTitle"></slot>
    <div
      class="bg-white rounded-2xl tracking-wide shadow-lg w-full flex h-full"
    >
      <div
        v-if="iconPrefix"
        class="flex items-center px-2 bg-gray-200 rounded-l-2xl"
      >
        <SynIcon :name="iconPrefix" class="w-6 h-6" />
      </div>
      <div class="w-full inline-block h-full m-auto">
        <div v-if="isLoading" class="animate-pulse flex space-x-4 w-full p-4">
          <div class="rounded-full bg-gray-400 h-12 w-12"></div>
          <div class="flex-1 space-y-4 py-1">
            <div class="h-4 bg-gray-400 rounded w-3/4"></div>
            <div class="space-y-2">
              <div class="h-4 bg-gray-400 rounded"></div>
              <div class="h-4 bg-gray-400 rounded w-5/6"></div>
            </div>
          </div>
        </div>
        <slot v-else></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SynDashboardCard',
  components: {},
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    iconPrefix: {
      type: String,
      default: '',
    },
  },
  setup: () => {},
});
</script>
