<template>
  <div v-if="isLoading">
    <div
      class="
        animate-pulse
        my-2
        transform
        bg-white
        hover:scale-95
        transition
        duration-300
        cursor-pointer
        relative
        flex
        h-20
        max-w-sm
        overflow-hidden
        rounded-xl
        shadow-lg
        lg:w-40 lg:h-40
      "
    >
      <div
        class="p-3 text-center w-full flex flex-col items-center justify-center"
      >
        <div class="h-8 w-8 bg-gray-400 rounded"></div>
        <div class="h-4 bg-gray-400 rounded w-1/3 space-y-2"></div>
        <div class="py-2"></div>
        <div class="h-4 w-2/3 bg-gray-400 rounded"></div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="
      my-2
      transform
      bg-white
      hover:scale-95
      transition
      duration-300
      cursor-pointer
      relative
      flex
      h-20
      max-w-sm
      overflow-hidden
      rounded-xl
      shadow-lg
      lg:w-40 lg:h-40
    "
  >
    <div class="text-gray-500 bg-white p-3 m-auto text-center inset-0">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
