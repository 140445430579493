<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import ModalSetAvatar from '@/ui/modules/avatars/ModalSetAvatar/ModalSetAvatar.vue';
import UsersSelect from '@/ui/modules/messaging/modal-new-group-chat/UsersSelect/UsersSelect.vue';
import { AdminType } from '@/ui/common/constants/constant';
import myProfileStore from '@/store/auth/my-profile';
import chatStore from '@/store/chat';
import groupStore from '@/store/group';
import {
  genDirectConversationId,
  genGroupConversationId,
} from '@/ui/plugins/firebases/firestore-database/constants';
import ConversationItem from '@/ui/modules/messaging/conversation-item/ConversationItem.vue';
import userStore from '@/store/user';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';
import useChatGroup from '@/ui/composables/chat/chat-group-composables';

const props = defineProps<{
  chatAboutTask: any;
  modalTitle?: string;
}>();

const emits = defineEmits(['onClose', 'onChatClick', 'onCreated']);

const _myProfileStore = myProfileStore();
const _chatStore = chatStore();
const _groupStore = groupStore();

const groupChatInfo = ref<{ avatar?: any; name?: string }>({});
const isSetAvatar = ref<boolean>();
const isNameChanged = ref<boolean>();
const selectedUsers = ref<any[]>([]);
const disabledUsers = ref<any[]>([]);
const currentStep = ref<number>(1);
const existedChats = ref<any[]>();
const isAskExistedGroups = ref<boolean>();

const conversationById = computed(() => _chatStore.conversationById);
const contacts = computed(() => _chatStore.getContacts);
const members = computed(() => userStore().allUserByIds);

const { goToConversation } = useChatCommon();
const { isCreating, adminGroupByUserId, onCreateGroupChat } = useChatGroup();

onMounted(() => {
  // Add current user to group members
  selectedUsers.value.push(_myProfileStore.myProfile);
  disabledUsers.value.push(_myProfileStore.myProfile);
  adminGroupByUserId.value = {};

  if (props.chatAboutTask) {
    // Add task creator to group members
    const taskCreator = members.value[props.chatAboutTask.creatorId];
    if (taskCreator) {
      if (!selectedUsers.value.some((u) => u?.id === taskCreator.id)) {
        selectedUsers.value.push(taskCreator);
        disabledUsers.value.push(taskCreator);
      }
      adminGroupByUserId.value[taskCreator.id] = true;
    }

    // Add task assignee to group members
    const taskAssignee = members.value[props.chatAboutTask.assigneeId];
    if (taskAssignee) {
      if (!selectedUsers.value.some((u) => u?.id === taskAssignee.id)) {
        selectedUsers.value.push(taskAssignee);
        disabledUsers.value.push(taskAssignee);
      }
      adminGroupByUserId.value[taskAssignee.id] = true;
    }

    // Add task collaborators to group members
    props.chatAboutTask.collaborators?.forEach((c) => {
      const taskCollab = members.value[c?.memberUserId];
      if (
        taskCollab &&
        !selectedUsers.value.some((u) => u?.id === taskCollab.id)
      ) {
        selectedUsers.value.push(taskCollab);
      }
    });
  } else {
    adminGroupByUserId.value[_myProfileStore.myProfile?.id] = true;
  }

  selectedUsers.value = [...selectedUsers.value];
});

const onNextClick = () => {
  if (currentStep.value === 1) {
    // Check existed chat if not chat about task
    if (!props.chatAboutTask) {
      existedChats.value = _groupStore
        .getExistedGroupsByMembers(selectedUsers.value)
        .map((group: any) => ({
          ...group,
          conversationId: genGroupConversationId(group?.id),
        }));
    }

    // Case 2 members, show direct conversation
    if (selectedUsers.value?.length === 2) {
      const conversationId = genDirectConversationId(
        selectedUsers.value[0]?.id,
        selectedUsers.value[1]?.id
      );
      existedChats.value?.unshift({
        ...contacts.value[conversationId],
        conversationId,
      });
    }

    currentStep.value =
      currentStep.value + (existedChats.value?.length ? 1 : 2);
  } else if (currentStep.value === 2) {
    currentStep.value++;
  }

  if (!isNameChanged.value) {
    groupChatInfo.value.name = _chatStore.genGroupChatName(
      selectedUsers.value,
      props.chatAboutTask
    );
  }
};

const onBackClick = () => {
  currentStep.value =
    currentStep.value -
    (currentStep.value === 3 && !existedChats.value?.length ? 2 : 1);
};

const onExistedChatClick = (conversation) => {
  goToConversation(conversation);

  emits('onChatClick', conversation);
};

const onCreateClick = async () => {
  const groupChat: any = {
    name: groupChatInfo.value?.name,
    avatar: groupChatInfo.value?.avatar,
    members: selectedUsers.value,
  };

  // Group chat about task
  if (props.chatAboutTask) {
    groupChat.chatAboutTaskId = props.chatAboutTask.id;
    groupChat.chatAboutTaskCode = props.chatAboutTask.code;
  }

  const conversation = await onCreateGroupChat(groupChat);

  if (conversation) emits('onCreated', conversation);
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    container-style="min-width: 40rem; max-width: 1200px; height: 80vh"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>{{ $t(modalTitle || 'CHAT_GROUP_NEW') }}</div>
    </template>

    <template #body>
      <div class="h-full">
        <!--MEMBERS-->
        <div v-if="currentStep === 1" class="h-full flex flex-col px-4">
          <div class="text-current-600 text-2xl text-center mb-5">
            {{ $t('COMMON_LABEL_MEMBERS') }}
          </div>

          <div class="flex-1 overflow-hidden">
            <UsersSelect
              v-model="selectedUsers"
              :disabled-users="disabledUsers"
            />
          </div>
        </div>

        <!--EXISTED GROUPS-->
        <div
          v-if="currentStep === 2"
          class="
            h-full
            flex flex-col
            items-center
            px-4
            overflow-auto
            small-scrollbar
          "
        >
          <div class="text-xl text-current-600 mb-3 mt-20">
            {{ $t('CHAT_EXISTED_CHAT_MEMBERS') }}
          </div>
          <div
            v-for="chat of existedChats"
            :key="chat?.conversationId"
            class="w-2/3 rounded-lg border overflow-hidden mb-2"
          >
            <ConversationItem
              :conversation="conversationById[chat?.conversationId]"
              :members="members"
              @click="
                onExistedChatClick(conversationById[chat?.conversationId])
              "
            />
          </div>
        </div>

        <!--INFO-->
        <form
          v-if="currentStep === 3"
          id="form-group-info"
          class="h-full flex px-4 overflow-auto small-scrollbar"
          @submit.prevent="onCreateClick"
        >
          <div class="w-2/3 mx-auto">
            <div class="text-current-600 text-2xl text-center mb-5">
              {{ $t('TASK_CREATE_GROUP_FORM_INFO_GROUP') }}
            </div>

            <!--AVATAR-->
            <div class="w-full flex-center mb-4">
              <div class="w-32 h-32 relative group/avatar">
                <div
                  v-if="!groupChatInfo?.avatar"
                  role="button"
                  class="
                    h-full
                    rounded-full
                    border
                    flex-center
                    hover:bg-gray-100
                  "
                  @click="isSetAvatar = true"
                >
                  <SynIcon
                    name="Camera"
                    custom-class="w-8 h-8"
                    class="fill-gray-400"
                  />
                </div>
                <template v-else>
                  <VigImage
                    :path="groupChatInfo?.avatar?.url"
                    class="w-full h-full rounded-full border"
                  />
                  <VigButton
                    light
                    rounded="rounded-full"
                    padding="p-2"
                    class="absolute bottom-2 -right-2"
                    @click="isSetAvatar = true"
                  >
                    <SynIcon name="Edit" is-active />
                  </VigButton>
                </template>
              </div>
            </div>

            <!--NAME-->
            <div class="text-current-600 font-medium">
              {{ $t('TASK_CREATE_GROUP_FORM_GROUP_NAME') }}
            </div>
            <SynInput
              v-model="groupChatInfo.name"
              max-length="50"
              placeholder=""
              autofocus
              class="mb-4"
              @change="isNameChanged = true"
            />

            <!--MEMBERS SELECTED-->
            <div class="text-current-600 font-medium mb-2">
              {{ $t('COMMON_LABEL_MEMBERS') }}
            </div>
            <SynAvatarStatusActive
              v-for="user in selectedUsers"
              :id="user?.id"
              :key="user?.id"
              :avatar="user?.avatarUrl || user?.avatar"
              custom-class="h-6 w-6"
              class="mb-2"
              :name="user?.fullName || user?.name"
              :is-user="true"
              :is-visitor="user?.adminType === AdminType.Visitor"
            />
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="flex items-center justify-end px-3 py-2 gap-2">
        <VigButton light color="gray" tabindex="-1" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') || 'Cancel' }}
        </VigButton>
        <VigButton
          v-if="currentStep > 1"
          color="gray"
          tabindex="-1"
          @click="onBackClick"
        >
          {{ $t('COMMON_LABEL_BACK') || 'Back' }}
        </VigButton>
        <VigButton
          v-if="currentStep === 1"
          :disabled="selectedUsers?.length < 2"
          @click="onNextClick"
        >
          {{ $t('COMMON_LABEL_NEXT') || 'Next' }}
        </VigButton>
        <VigButton v-if="currentStep === 2" @click="onNextClick">
          {{ $t('TASK_CREATE_GROUP_NEW') || 'New group' }}
        </VigButton>
        <VigButton
          v-if="currentStep === 3 && !isAskExistedGroups"
          type="submit"
          form="form-group-info"
          :disabled="selectedUsers?.length < 2 || !groupChatInfo?.name"
          :processing="isCreating"
        >
          <span>{{ $t('COMMON_LABEL_CREATE') || 'Create' }}</span>
        </VigButton>
      </div>
    </template>
  </SynModal>

  <ModalSetAvatar
    v-if="isSetAvatar"
    :modal-title="$t('TASK_CREATE_GROUP_NEW_AVATAR')"
    avatar-type="GROUP"
    :current-avatar="groupChatInfo?.avatar"
    @on-close="isSetAvatar = false"
    @on-confirm="
      groupChatInfo.avatar = $event;
      isSetAvatar = false;
    "
    @on-delete="
      groupChatInfo.avatar = null;
      isSetAvatar = false;
    "
  />
</template>
