<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.25 29.25"
  >
    <path
      data-name="Icon ionic-ios-checkmark-circle"
      d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm7.488,10.582-9.4,9.443H16.08a1.27,1.27,0,0,1-.816.387,1.231,1.231,0,0,1-.823-.4L10.5,19.448a.28.28,0,0,1,0-.4L11.756,17.8a.272.272,0,0,1,.394,0l3.122,3.122,8.578-8.641a.278.278,0,0,1,.2-.084h0a.255.255,0,0,1,.2.084l1.23,1.273A.277.277,0,0,1,25.488,13.957Z"
      transform="translate(-3.375 -3.375)"
    />
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
    <path
      data-name="Icon feather-circle"
      d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z"
      transform="translate(-1.5 -1.5)"
      fill="none"
      stroke="#818a91"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
