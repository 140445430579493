<script setup language="ts">
import { ref, onMounted } from 'vue';
import Cropper from 'cropperjs';
import { ImageDefault } from '@/ui/components/photo-upload/imageDefault.js';

const props = defineProps({
  typeUpload: {
    type: String,
    default: 'USER',
  },
});

const emits = defineEmits(['chooseImage']);

const listShow = ref({});
const photoChose = ref(null);
let _imgChooseCropper;
const fileChoosePhotoRef = ref(null);
const isChooseImage = ref(false);

onMounted(() => {
  listShow.value = ImageDefault(props.typeUpload);
});

const chosseImageCut = async (index, key) => {
  if (isChooseImage.value && photoChose.value.index == index) {
    isChooseImage.value = false;
    photoChose.value = null;
  } else {
    isChooseImage.value = true;
    photoChose.value = {};
    photoChose.value.index = index;
    photoChose.value.key = key;

    _initViewerPhotoDefault(listShow.value[key]?.objects[index]);
  }
  emits('chooseImage', onCutPhotoDefaultClick());
};

const _initViewerPhotoDefault = (src) => {
  _imgChooseCropper && _imgChooseCropper.destroy();
  fileChoosePhotoRef.value.src = src;

  _imgChooseCropper = new Cropper(fileChoosePhotoRef.value, {
    checkCrossOrigin: true,
    dragMode: 'none',
    aspectRatio: 1 / 1,
    autoCrop: true,
    responsive: true,
    background: false,
    toggleDragModeOnDblclick: false,
    ready() {
      _zoomToPhotoOriginal();
    },
  });
};

const _zoomToPhotoOriginal = () => {
  const canvasData = _imgChooseCropper.getCanvasData();
  if (canvasData.naturalWidth < canvasData.width) {
    _imgChooseCropper.zoomTo(1.2);
  }
  emits('chooseImage', onCutPhotoDefaultClick());
};

const onCutPhotoDefaultClick = () => {
  _imgChooseCropper.getCroppedCanvas({
    fillColor: '#fff',
  });
  const file = _imgChooseCropper
    .getCroppedCanvas({
      fillColor: '#fff',
    })
    .toDataURL('image/jpeg');
  let binary;
  binary = new File(
    [new Buffer.from(file?.replace(/^data:image\/\w+;base64,/, ''), 'base64')],
    'avatar.png',
    {
      type: 'image/png',
    }
  );
  return binary;
};
</script>
<template>
  <section class="h-full flex-1 min-h-0 w-96">
    <div class="flex flex-row h-full">
      <div class="h-full w-full small-scrollbar overflow-auto px-4">
        <template v-for="section in listShow" :key="section">
          <span
            class="
              font-medium
              mb-3
              pb-1
              pt-2
              w-full
              text-xs
              flex
              items-start
              border-b border-current-50
            "
            >{{ section.key }}</span
          >
          <div class="grid grid-cols-4 gap-3 flex-wrap pb-5">
            <div
              v-for="(avt, indexAvt) in section.objects"
              :key="avt"
              class="relative"
              @click="chosseImageCut(indexAvt, section.key)"
            >
              <div
                v-if="
                  photoChose?.index == indexAvt &&
                  photoChose?.key == section.key
                "
                class="
                  w-14
                  h-14
                  rounded-full
                  absolute
                  top-0
                  z-10
                  flex-center
                  bg-current-800 bg-opacity-40
                "
              >
                <SynIcon name="check" custom-class="w-8 h-8 text-white" />
              </div>
              <img class="w-14 h-14 cursor-pointer rounded-full" :src="avt" />
            </div>
          </div>
        </template>
      </div>

      <div class="h-full w-0 small-scrollbar overflow-auto">
        <div class="h-full bg-gray-200 relative">
          <div class="h-full">
            <img ref="fileChoosePhotoRef" class="max-w-full h-full hidden" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
