<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import myProfileStore from '@/store/auth/my-profile';
import SettingLanguage from '@/ui/components/setting/SettingLanguage.vue';
import { loginAgain } from '@/ui/hooks/commonHook';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const srcLogoLabel = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/logo_brand.svg`;
const userProfile = computed(() => myProfileStore().myProfile);
const router = useRouter();
const handleLogout = () => {
  loginAgain();
};

onMounted(async () => {
  await myProfileStore().fetchCurrentCatalogUser();
});

const onChangeHome = () => {
  router.push('/');
};
</script>

<template>
  <div class="flex flex-1 flex-col h-screen font-roboto">
    <header
      class="
        h-16
        flex flex-wrap
        justify-between
        items-center
        px-6
        bg-white
        border-b-2
        shadow-xl
      "
    >
      <div class="flex items-center justify-center min-w-min space-x-4">
        <div @click="onChangeHome()">
          <syn-img :src="srcLogoLabel" class="w-32 cursor-pointer" />
        </div>
      </div>
      <div class="flex-center">
        <div>
          <syn-avatar
            class="cursor-pointer text-current-800 pr-2"
            custom-class="w-8 h-8"
            :src="userProfile?.avatarUrl?.replace('original', 'mini')"
            :name="`${userProfile?.lastName ? userProfile?.lastName : ''} ${
              userProfile?.firstName ? userProfile?.firstName : ''
            }`"
            has-name
          ></syn-avatar>
        </div>

        <syn-dropdown>
          <SynIcon has-action name="dots-vertical" size="medium" />
          <template #dropdown-list>
            <syn-dropdown-menu class="right-0 w-44">
              <!-- <router-link :to="{ name: 'ProfilePublic' }">
                <syn-dropdown-item>
                  <div class="flex items-center">
                    <SynIcon name="profile"/>
                    <span class="pl-2">
                      {{ $t('COMMON_LABEL_PROFILE') || 'Profile' }}
                    </span>
                  </div>
                </syn-dropdown-item>
              </router-link> -->
              <setting-language is-setting-organization />
              <syn-dropdown-item class="text-red-500" @click="handleLogout()">
                <div class="flex items-center">
                  <SynIcon name="logout" />
                  <span class="pl-2">{{
                    $t('COMMON_LABEL_LOGOUT') || 'Log out'
                  }}</span>
                </div>
              </syn-dropdown-item>
            </syn-dropdown-menu>
          </template>
        </syn-dropdown>
      </div>
    </header>
    <div class="flex flex-1 overflow-hidden relative">
      <main
        class="relative w-full overflow-x-hidden overflow-y-hidden bg-gray-50"
      >
        <div class="relative h-full ml-4 mr-3 py-2">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
