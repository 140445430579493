import { v4 as uuidv4 } from 'uuid';
import { IDesktopDrawerShape } from '../schema';
import ShapeGroupEntity from './ShapeGroupEntity';
import ShapeTaskEntity from './ShapeTaskEntity';
import { ShapeType } from '@/ui/components/drawer/schema';
import ShapeNoteEntity from '@/ui/components/drawer/entities/ShapeNoteEntity';
import ShapeTextEntity from './ShapeTextEntity';
import ShapeTodoListEntity from './ShapeTodoListEntity';
import ShapeUserEntity from './ShapeUserEntity';
import ShapeMediaEntity from '@/ui/components/drawer/entities/ShapeMediaEntity';
import DesktopIndexedDBSingleton from '@/indexedDB/desktop/DesktopIndexedDBSingleton';
import { Box2dModel } from '../shapes/common/Box2d';
import WhiteboardService from '@/application/services/WhiteboardService';
import whiteboardStore from '@/store/whiteboard';
import { DEFAULT_VALUE_BY_SHAPE } from '@/application/constants/whiteboard';
import ShapeArrowEntity from './ShapeArrowEntity';
import { cloneDeep } from 'lodash';

export type DesktopDrawerStatus = 'SELECT' | 'TEXT_EDITING';
export default class DesktopDrawerEntity {
    id: string;
    title: string;
    shapeGroups: ShapeGroupEntity[];
    selectedShapeGroup?: ShapeGroupEntity;
    draftShapeGroup: ShapeGroupEntity;
    selectedShapeIds: string[];
    shapeList: IDesktopDrawerShape[];
    maxIndex: number;
    translateX: number;
    translateY: number;
    scale: number;
    status: DesktopDrawerStatus;
    brush: Box2dModel | null;
    lastModificationTime: number;
    lastAccessTime: number;
    timeoutSaveChangeLocal?: any;
    timeoutSaveChange?: any;

    configs?: {
        minX: number | null;
        minY: number | null;
        maxX: number | null;
        maxY: number | null;
    };

    constructor(data) {
        this.id = data?.id;
        this.title = data?.title;
        this.translateX = data?.translateX || 0;
        this.translateY = data?.translateY || 0;
        this.scale = data?.scale || 1;
        this.selectedShapeIds = data?.selectedShapeIds || [];
        this.draftShapeGroup = new ShapeGroupEntity(data?.currentShapeGroup);

        this.shapeGroups = data?.shapeGroups || [];
        this.shapeList =
            data?.shapeList
                ?.filter((shape) => shape?.id)
                ?.map((shape) => {
                    switch (shape?.type) {
                        case ShapeType.ARROW:
                            return new ShapeArrowEntity(shape);
                        case ShapeType.DRAW_NOTE:
                            return new ShapeNoteEntity(shape);
                        case ShapeType.TASK:
                            return new ShapeTaskEntity(shape);
                        case ShapeType.TEXT:
                            return new ShapeTextEntity(shape);
                        case ShapeType.TEXT_NOTE:
                            break;
                        case ShapeType.TODO_LIST:
                            return new ShapeTodoListEntity(shape);
                        case ShapeType.USER:
                            return new ShapeUserEntity(shape);
                        case ShapeType.MEDIA_AUDIO:
                        case ShapeType.MEDIA_IMAGE:
                        case ShapeType.MEDIA_LINK:
                        case ShapeType.MEDIA_VIDEO:
                            return new ShapeMediaEntity(shape);
                        default:
                            break;
                    }
                }) || [];
        this.maxIndex =
            this.shapeList?.length > 0
                ? Math.max(...this.shapeList?.map((shape) => shape?.zIndex))
                : 2000;

        this.status = 'SELECT';
        this.brush = null;
        this.lastModificationTime = data?.lastModificationTime;
        this.lastAccessTime = data?.lastAccessTime;
        this.configs = data?.configs;
    }

    getTranslateShape(newTranslateX, newTranslateY) {
        let _newTranslateX = newTranslateX;
        if (typeof this.configs?.minX == 'number')
            _newTranslateX = Math.max(_newTranslateX, this.configs?.minX);
        if (typeof this.configs?.maxX == 'number')
            _newTranslateX = Math.min(_newTranslateX, this.configs?.maxX);

        let _newTranslateY = newTranslateY;
        if (typeof this.configs?.minY == 'number')
            _newTranslateY = Math.max(_newTranslateY, this.configs?.minY);
        if (typeof this.configs?.maxY == 'number')
            _newTranslateY = Math.min(_newTranslateY, this.configs?.maxY);

        return {
            translateX: _newTranslateX,
            translateY: _newTranslateY,
        };
    }
    getTranslateContainer(newTranslateX, newTranslateY) {
        let _newTranslateX = newTranslateX;
        if (typeof this.configs?.minX == 'number')
            _newTranslateX = Math.min(_newTranslateX, this.configs?.minX);
        if (typeof this.configs?.maxX == 'number')
            _newTranslateX = Math.max(_newTranslateX, -this.configs?.maxX);

        let _newTranslateY = newTranslateY;
        if (typeof this.configs?.minY == 'number')
            _newTranslateY = Math.min(_newTranslateY, this.configs?.minY);
        if (typeof this.configs?.maxY == 'number')
            _newTranslateY = Math.max(_newTranslateY, -this.configs?.maxY);

        return {
            translateX: _newTranslateX,
            translateY: _newTranslateY,
        };
    }
    changePosition(newTranslateX, newTranslateY, callback?) {
        const newTranslate = this.getTranslateContainer(
            newTranslateX,
            newTranslateY
        );

        this.translateX = newTranslate?.translateX;
        this.translateY = newTranslate?.translateY;

        if (callback && typeof callback == 'function') callback();

        this.onSaveChangeRealtime();
    }

    setSelectedShapeIds(shape: IDesktopDrawerShape | null) {
        if (!shape || !shape?.id) {
            this.selectedShapeIds = [];
            this.draftShapeGroup.setMembers([]);
            return;
        }

        this.selectedShapeIds = [shape.id];

        this.draftShapeGroup.setMembers([shape]);
    }

    addShapeToGroup(shape: IDesktopDrawerShape) {
        this.selectedShapeIds.push(shape?.id);

        this.draftShapeGroup.addMembers([shape]);
    }
    updateGroupShapes(shapes: IDesktopDrawerShape[]) {
        this.selectedShapeIds = shapes?.map((s) => s?.id);

        this.draftShapeGroup.setMembers(shapes);
    }
    removeShapeOutGroup(shape: IDesktopDrawerShape) {
        this.selectedShapeIds = this.selectedShapeIds?.filter(
            (id) => id !== shape?.id
        );

        this.draftShapeGroup.removeMembers([shape]);
    }

    toggleSelectedShapeIds(shape: IDesktopDrawerShape) {
        if (!shape || !shape?.id) return;

        const shapeId = shape?.id;
        const isExisted = this.selectedShapeIds?.some((id) => id == shapeId);

        if (isExisted) this.removeShapeOutGroup(shape);
        else this.addShapeToGroup(shape);
    }

    createNewShapeGroupFromDraftGroup() {
        const newGroupId = uuidv4();
        const newGroup = new ShapeGroupEntity({
            ...this.draftShapeGroup,
            id: newGroupId,
        });

        this.shapeGroups.push(newGroup);

        newGroup.shapes.forEach((shape) => {
            shape.setGroup(newGroupId);
        });
    }

    setCurrentGroup(groupId?) {
        if (groupId)
            this.selectedShapeGroup = this.shapeGroups?.find(
                (group) => group.id == groupId
            );
        else this.selectedShapeGroup = undefined;
    }

    actionUnGroup() {
        if (!this.selectedShapeGroup?.id) return;

        this.selectedShapeGroup?.shapes.forEach((shape) => {
            shape.actionUnGroup();
        });

        this.shapeGroups = this.shapeGroups?.filter(
            (g) => g.id !== this.selectedShapeGroup?.id
        );

        this.selectedShapeGroup = undefined;
    }
    updateMaxIndex() {
        this.maxIndex =
            this.shapeList?.length > 0
                ? Math.max(...this.shapeList?.map((shape) => shape?.zIndex))
                : 2000;
    }

    addShape(
        shapeType: ShapeType,
        data: any,
        options?: {
            translateX?: number;
            translateY?: number;
            width?: number;
            height?: number;
            saveImmediately?: boolean;
        }
    ) {
        const filteredData = Object.keys(data).reduce((acc, key) => {
            if (typeof data[key] !== 'function') {
                acc[key] = data[key];
            }
            return acc;
        }, {});

        const newShape = this.generateShapeData(
            shapeType,
            cloneDeep(filteredData),
            options
        );
        if (newShape) this.shapeList.push(newShape);

        this.updateMaxIndex();

        this.onSaveChangeRealtime(options?.saveImmediately);

        return newShape;
    }

    updateStatus(st: DesktopDrawerStatus) {
        this.status = st;
    }

    updateShape(shapeId, updateType: 'SOURCE_DATA', data) {
        const currentShape = this.shapeList?.find(
            (shape) => shape?.id == shapeId
        );

        if (!currentShape) return;

        switch (updateType) {
            case 'SOURCE_DATA':
                currentShape.updateSourceData(data);

                break;

            default:
                break;
        }

        this.onSaveChangeRealtime();
    }

    removeShapeById(shapeId, callback?) {
        const currentShape = this.shapeList?.find(
            (shape) => shape?.id == shapeId
        );

        if (!currentShape) return;

        this.shapeList = this.shapeList?.filter(
            (shape) => shape?.id !== shapeId
        );

        if (callback) callback();
        this.setSelectedShapeIds(null);
        if (
            currentShape?.type !== ShapeType.USER &&
            currentShape?.type !== ShapeType.TASK
        ) {
            whiteboardStore().addDeletedShapeToBin({
                ...currentShape,
                whiteboardId: this.id,
            });
        }

        this.onSaveChangeRealtime();
    }

    addBrush(offsetX, offsetY) {
        this.brush = {
            w: 0,
            h: 0,
            x: offsetX,
            y: offsetY,
        };
    }
    updateBrushByNewPosition(newX, newY) {
        if (!this.brush) return;

        const brushEndX = this.brush.x + this.brush.w;
        const brushEndY = this.brush.y + this.brush.h;

        const selectedShapes = this.shapeList?.filter(
            (shape) =>
                this.brush &&
                shape?.translateX >= this.brush?.x &&
                shape?.translateX < newX &&
                shape?.translateY >= this.brush?.y &&
                shape?.translateY < newY &&
                shape?.translateX + shape?.width < brushEndX &&
                shape?.translateY + shape?.height < brushEndY
        );
        this.updateGroupShapes(selectedShapes);

        this.updateBrush(newX - this.brush.x, newY - this.brush.y);
    }
    updateBrush(width, height) {
        if (!this.brush) return;
        this.brush.w = width;
        this.brush.h = height;
    }
    removeBrush() {
        this.brush = null;
    }

    onSaveChangeRealtime(saveImmediately?) {
        if (saveImmediately) {
            this.onSaveChangeLocal();
            this.onSaveChange();
            if (this.timeoutSaveChangeLocal)
                clearTimeout(this.timeoutSaveChangeLocal);

            if (this.timeoutSaveChange) clearTimeout(this.timeoutSaveChange);
        } else {
            // update local
            if (this.timeoutSaveChangeLocal)
                clearTimeout(this.timeoutSaveChangeLocal);

            this.timeoutSaveChangeLocal = setTimeout(() => {
                this.onSaveChangeLocal();
                this.timeoutSaveChangeLocal = null;
            }, 500);

            // call api
            if (this.timeoutSaveChange) clearTimeout(this.timeoutSaveChange);

            this.timeoutSaveChange = setTimeout(() => {
                this.onSaveChange();
                this.timeoutSaveChange = null;
            }, 2 * 1000);
        }
    }

    onSaveChangeLocal() {
        if (this.timeoutSaveChangeLocal) {
            clearTimeout(this.timeoutSaveChangeLocal);
            this.timeoutSaveChangeLocal = null;
        }

        this.lastModificationTime = Date.now();
        const DesktopIndexedDBSingletonInstance =
            DesktopIndexedDBSingleton.getInstance();
        const dataPayload = DesktopIndexedDBSingletonInstance.toRawData(
            this.id,
            this
        );

        DesktopIndexedDBSingletonInstance.put(dataPayload);
    }

    onSaveChange() {
        if (this.timeoutSaveChange) {
            clearTimeout(this.timeoutSaveChange);
            this.timeoutSaveChange = null;
        }

        this.lastModificationTime = Date.now();
        const DesktopIndexedDBSingletonInstance =
            DesktopIndexedDBSingleton.getInstance();
        const dataPayload = DesktopIndexedDBSingletonInstance.toRawData(
            this.id,
            this
        );

        DesktopIndexedDBSingletonInstance.put(dataPayload);
        WhiteboardService.updateWhiteboard(dataPayload);
    }
    generateShapeData(
        shapeType: ShapeType,
        sourceData,
        options?: {
            translateX?: number;
            translateY?: number;
            width?: number;
            height?: number;
        }
    ) {
        const newShape = {
            id: uuidv4(),
            translateX: (options?.translateX || 100) - this.translateX,
            translateY: (options?.translateY || 100) - this.translateY,
            zIndex: this.maxIndex + 1,
            sourceId: sourceData?.id,
            sourceData: sourceData,
        };
        switch (shapeType) {
            case ShapeType.TASK:
                return new ShapeTaskEntity({
                    ...newShape,
                    type: shapeType,
                    width: 340,
                    height: 92,
                });
            case ShapeType.USER:
                return new ShapeUserEntity({
                    ...newShape,
                    type: shapeType,
                    width: DEFAULT_VALUE_BY_SHAPE[ShapeType.USER].width,
                    height: DEFAULT_VALUE_BY_SHAPE[ShapeType.USER].height,
                });
            case ShapeType.DRAW_NOTE:
                return new ShapeNoteEntity({
                    ...newShape,
                    type: shapeType,
                    width: options?.width || 100,
                    height: options?.height || 100,
                });
            case ShapeType.MEDIA_IMAGE:
                return new ShapeMediaEntity({
                    ...newShape,
                    type: shapeType,
                    width: options?.width || 100,
                    height: options?.height || 100,
                });
            case ShapeType.MEDIA_AUDIO:
                return new ShapeMediaEntity({
                    ...newShape,
                    type: shapeType,
                    width: 340,
                    height: 64,
                });
            case ShapeType.MEDIA_DOCUMENT:
            case ShapeType.MEDIA_VIDEO:
                return new ShapeMediaEntity({
                    ...newShape,
                    type: shapeType,
                    width: 120,
                    height: 120,
                });
            case ShapeType.MEDIA_LINK:
                return new ShapeMediaEntity({
                    ...newShape,
                    type: shapeType,
                    width: 352,
                    height: 64,
                });
            case ShapeType.TEXT:
                return new ShapeTextEntity({
                    ...newShape,
                    type: shapeType,
                    width: 340,
                    height: 84,
                });
            case ShapeType.TODO_LIST:
                return new ShapeTodoListEntity({
                    ...newShape,
                    type: shapeType,
                    width: 440,
                    height: 320,
                });
            case ShapeType.ARROW:
                return new ShapeArrowEntity({
                    ...newShape,
                    type: shapeType,
                });

            default:
                break;
        }
        return null;
    }
}
