<script setup>
import { ref, computed } from 'vue';
import fileStorageStore from '@/store/fileStorage';
import groupStore from '@/store/group';
import ButtonChangeFileLocation from '../../_commons/button-change-file-location/ButtonChangeFileLocation.vue';
import DrawerWidget from '@/ui/components/DrawerWidget/DrawerWidget.vue';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { formatDate } from '@/ui/plugins/filters';
import { ignoreUnicode } from '@/ui/plugins/utils';
import myProfileStore from '@/store/auth/my-profile';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
import AddFileLocation from '@/ui/modules/ged/_commons/add-file-location/AddFileLocation.vue';

const props = defineProps({
  location: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['onClose']);

const _fileStorageStore = fileStorageStore();
const _groupStore = groupStore();

const _organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
const myProfile = computed(() => myProfileStore().myProfile);

const noteLocation = ref(props.location?.groupId);

// const noteLocationName = computed(() => {
//   if (!noteLocation?.value) return translate('GED_MY_STORAGE');
//
//   const groupInfo = allMyGroups.value?.find(
//     (group) => group?.id === noteLocation.value
//   );
//   return groupInfo?.name;
// });

const noteLocationCanChange = computed(
  () =>
    false && // Add by domain resources
    !props.location?.groupId &&
    !props.location?.category &&
    props.location?.userId !== myProfile?.value.id &&
    !props.location.folderId
);

const onSaveNote = async (data) => {
  try {
    const newNote = {
      name: _genFileName('Note', 'jpeg'),
      type: 'image/jpeg',
      url: data?.base64,
      size: atob(data?.base64?.split(';base64,').pop()).length,
      drawData: data?.drawData,
    };

    const location = {
      folderId: props.location?.folderId,
      // groupId: noteLocation.value,
      groupId: props.location?.groupId,
      domainId: props.location?.domainId,
    };

    const uploadedFiles = await _fileStorageStore.uploadFiles(
      _organizationId,
      [newNote],
      location
    );

    _fileStorageStore.setRenamingFile(
      uploadedFiles?.length ? uploadedFiles[0] : null
    );

    _fileStorageStore.getFiles(props.location);

    emit('onClose');
  } catch (e) {
    console.log(e);
  }
};

const _genFileName = (type, ext) => {
  let noteName;

  const allMyGroups = _groupStore.allMyGroups;
  const groupInfo = (allMyGroups || []).find(
    (group) => group?.id === noteLocation.value
  );

  if (groupInfo) {
    noteName = ignoreUnicode(groupInfo?.name, false);
  } else {
    noteName = ignoreUnicode(
      myProfile.value?.fullName?.split(' ').pop(),
      false
    );
  }

  const time = formatDate(new Date(), 'YYYY-MM-DD hh.mm A');

  return `${type}-Storage ${noteName} ${time}.${ext}`;
};
</script>

<template>
  <SynModalNoFocus
    z-index="z-50"
    disable-click-outside
    disable-esc-to-close
    is-hidden-footer
    :container-style="'max-height: 1000px;max-width: 2000px;min-height:500px;min-width:600px;width: 90%; height: 90%'"
    style-body="p-0 flex flex-col"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="flex items-center space-x-4">
        <div>{{ $t('GED_NOTE_NEW') }}</div>

        <div class="flex text-base space-x-2">
          <div class="text-gray-500 font-normal">{{ $t('GED_FOLDER_IN') }}</div>
          <AddFileLocation
            :location="location"
            class="font-semibold text-black"
          />
          <button-change-file-location
            v-if="noteLocationCanChange"
            class="ml-3"
            :group-id="noteLocation"
            @on-location-click="noteLocation = $event"
          />
        </div>
      </div>
    </template>

    <template #body>
      <div
        class="relative"
        style="
          max-height: 1000px;
          max-width: 2000px;
          min-height: 500px;
          min-width: 600px;
          width: 100%;
          height: 100%;
        "
      >
        <DrawerWidget @cancel="$emit('onClose')" @on-save="onSaveNote" />
      </div>
    </template>
  </SynModalNoFocus>
</template>
