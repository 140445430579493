<script setup lang="ts">
import { computed } from 'vue';
import getUserInfo from '@/ui/helpers/user-helper';
import dashboardStore from '@/store/dashboard';
import settingStore from '@/store/setting';
import FavoriteButton from '@/ui/components/user/FavoriteButton.vue';
import { AdminType } from '@/ui/common/constants/constant';
import { decryptUserId, encryptUserId } from '@/ui/plugins/crypto/crypto';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';

const props = defineProps({
  userId: {
    type: [String, Number],
    default: null,
  },
  user: {
    type: Object,
    default: null,
  },
  isHiddenName: {
    type: Boolean,
    default: false,
  },
  avatarClass: {
    type: String,
    default: 'w-6 h-6',
  },
  isShowActionUser: {
    type: Boolean,
    default: false,
  },
});
defineEmits(['openProfile']);

const _dashboardStore = dashboardStore();
const _settingStore = settingStore();
const chatEnabled = computed(() => _settingStore.chatEnabled);

const userWorkingStatus = computed(() => _dashboardStore.userWorkingStatus);
const userInfo = computed(() => {
  return props.user || getUserInfo(decryptUserId(props.userId));
});

const { goToChatFromContact } = useChatCommon();

const onMessageToUser = (user) => {
  if (!user?.id) return;

  goToChatFromContact(user, false);
};
const chooseUser = (user) => {
  // router.replace(taskListRouteReplace({ userId: user.id }));
  // _chatStore.closeBubbleChat();
  window.open(`/tasks/users/${encryptUserId(user.id)}`, '_blank');
};
</script>

<template>
  <div class="w-max bg-white rounded-md py-2 px-4">
    <div class="flex items-center space-x-4 font-sans py-1">
      <syn-avatar-notification
        :src="userInfo?.avatar?.replace('original', 'mini')"
        :name="userInfo?.name"
        :status="userWorkingStatus[userInfo?.id]?.status || 'offline'"
        custom-class="h-14 w-14"
      />
      <div class="flex flex-col space-y-2">
        <div class="flex space-x-2 items-center">
          <div class="flex-center space-x-2">
            <span class="font-medium text-sm">
              {{ userInfo?.name }}
            </span>
            <SynTag
              v-if="userInfo?.adminType == AdminType.Visitor"
              size="tiny"
              custom-class="px-1 border border-current-100 bg-white text-gray-800"
            >
              <span class="italic">
                {{ $t('COMMON_LABEL_VISITOR') || 'Visitor' }}
              </span>
            </SynTag>
          </div>
          <SynIcon
            class="cursor-pointer"
            custom-clas="w-3 h-3 fill-current text-current-500"
            name="info"
            @click="$emit('openProfile', userInfo?.id)"
          />
        </div>

        <div class="flex space-x-2">
          <div
            :title="
              $t('LIST_TASK_VIEW_USER_TASK', {
                user: userInfo?.firstName,
              })
            "
            class="
              dropdown-item
              cursor-pointer
              flex-center
              w-7
              h-7
              shadow-md
              rounded-full
              fill-white
              text-white
              bg-current-500
              hover:bg-current-200 hover:fill-current
            "
            @click="chooseUser(userInfo)"
          >
            <SynIconCustom name="note" is-active custom-class="w-4 h-4" />
          </div>
          <div
            v-if="chatEnabled"
            v-permission="'Messages'"
            :title="$t('LIST_TASK_CHAT_TO_USER', { user: userInfo?.firstName })"
            class="
              dropdown-item
              cursor-pointer
              flex-center
              w-7
              h-7
              shadow-md
              rounded-full
              fill-white
              text-white
              bg-blue-500
              hover:bg-blue-200 hover:fill-blue-500
            "
            @click="onMessageToUser(userInfo)"
          >
            <SynIconCustom name="message" is-active custom-class="w-4 h-4" />
          </div>
          <div
            v-if="userInfo?.adminType !== AdminType.Visitor"
            class="
              flex-center
              cursor-pointer
              fill-white
              rounded-full
              shadow-lg
              w-7
              h-7
              p-2
              hover:bg-current-200 hover:fill-current
            "
          >
            <FavoriteButton :ref-id="userInfo?.id" type="member" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
