<script setup lang="ts">
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import myProfileStore from '@/store/auth/my-profile';

dayjs.extend(utc);
dayjs.extend(tz);

const props = defineProps<{
  time: string;
  isEdit: boolean;
}>();
const emit = defineEmits(['chooseTime']);

const utcDate = computed(() => myProfileStore().getTimezone);
const timezone = computed(() => {
  let timeZone = myProfileStore().getTimezone;
  if (timeZone == '+7') return 'Asia/Bangkok';
  if (timeZone == '-5') return 'America/New_York';
  else return 'Europe/Paris';
});
const locate = getLocalStorage(StorageConstant.LANGUAGE);
const modelConfig = ref({ type: 'string', mask: 'YYYY-MM-DD' });
const masks = ref({ input: 'MMM DD, YYYY' });

watch(
  () => props.time,
  () => {
    timeObject.value.day = dayjs(new Date(props.time))
      .tz(timezone.value)
      .format('MM-DD-YYYY');
  }
);

const filterdatetimeStart = computed(() => {
  let objDate = {
    hour: props.time
      ? dayjs(new Date(props.time)).tz(timezone.value).get('hour')
      : null,
    minute: props.time
      ? dayjs(new Date(props.time)).tz(timezone.value).get('minute')
      : null,
    text: props.time
      ? dayjs(new Date(props.time)).tz(timezone.value).format('hh:mm A')
      : null,
    day: props.time
      ? dayjs(new Date(props.time)).tz(timezone.value).format('MM-DD-YYYY')
      : null,
  };
  return objDate;
});

const filterdatetimeCurrent = computed(() => {
  let objDate = {
    hour: dayjs(new Date()).tz(timezone.value).get('hour'),
    minute: dayjs(new Date()).tz(timezone.value).get('minute'),
    day: dayjs(new Date()).tz(timezone.value).format('MM-DD-YYYY'),
  };
  return objDate;
});

const timeObject = ref(filterdatetimeStart.value);

const timeAroundDayArr = computed(() => {
  let arr = [] as any;
  let index = 0;
  let current = new Date();
  current.setHours(0, 0, 0, 0);
  let times = dayjs(current);
  while (index < 96) {
    let valueTime = dayjs(times).add(15, 'minute');
    let minute = valueTime.get('hour') * 60 + valueTime.get('minute');
    arr.push({
      offset: minute,
      hour: valueTime.get('hour'),
      minute: valueTime.get('minute'),
      text: valueTime.format('hh:mm A'),
      timejs: valueTime,
    });
    times = dayjs(times).add(15, 'minute');
    index++;
  }

  return arr || [];
});
const formatDatetimeUTC = (datetime) => {
  let formatDate = new Date(
    datetime.day +
      ' ' +
      datetime.hour +
      ':' +
      datetime.minute +
      ' ' +
      'GMT' +
      utcDate.value +
      '00'
  );
  return formatDate;
};
const setValueDataTimedate = (timeStart, dateStart?) => {
  timeObject.value.hour = timeStart.hour;
  timeObject.value.minute = timeStart.minute;
  timeObject.value.text = timeStart.text;

  if (dateStart) timeObject.value.day = dateStart;
};

const handleTimeCurrent = () => {
  for (let i = 0; i < timeAroundDayArr.value.length; i++) {
    let offsetCurrent =
      filterdatetimeCurrent.value.hour * 60 +
      filterdatetimeCurrent.value.minute;

    // if (
    //   offsetCurrent < timeAroundDayArr.value[i].offset &&
    //   offsetCurrent > timeAroundDayArr.value[i - 1].offset
    // ) {
    //   if (timeAroundDayArr.value[i].offset - offsetCurrent <= 1) {
    //     setValueDataTimedate(timeAroundDayArr.value[i + 1]);
    //     indexCurrentTimeStart.value = i + 1;
    //   } else {
    //     setValueDataTimedate(timeAroundDayArr.value[i]);
    //     indexCurrentTimeStart.value = i;
    //   }
    // }

    const currentTime = timeAroundDayArr.value[i];
    const previousTime = timeAroundDayArr.value[i - 1];

    if (
      offsetCurrent <= currentTime.offset &&
      offsetCurrent > previousTime.offset
    ) {
      const nextTime = timeAroundDayArr.value[i + 1];
      const isOffsetWithinRange = currentTime.offset - offsetCurrent <= 1;

      setValueDataTimedate(isOffsetWithinRange ? nextTime : currentTime);
      indexCurrentTimeStart.value = isOffsetWithinRange ? i + 1 : i;
    }
  }
};
onMounted(async () => {
  await nextTick();
  if (!props.isEdit) {
    handleTimeCurrent();
    emit('chooseTime', formatDatetimeUTC(timeObject.value));
  } else {
    for (let i = 0; i < timeAroundDayArr.value.length; i++) {
      if (
        timeAroundDayArr.value[i].hour === timeObject.value.hour &&
        timeObject.value.minute == timeAroundDayArr.value[i].minute
      ) {
        indexCurrentTimeStart.value = i;
        setValueDataTimedate(timeAroundDayArr.value[i]);
        break;
      }
      if (
        timeAroundDayArr.value[i].hour === timeObject.value.hour &&
        timeAroundDayArr.value[i].minute - (timeObject.value.minute || 0) <=
          15 &&
        timeAroundDayArr.value[i].minute - (timeObject.value.minute || 0) >= 0
      ) {
        indexCurrentTimeStart.value = i;
        break;
      }

      if (timeAroundDayArr.value[i].hour > (timeObject.value.hour || 0)) {
        indexCurrentTimeStart.value = i;
        break;
      }
    }
  }
});

const onChooseTime = (time, index) => {
  indexCurrentTimeStart.value = index;
  setValueDataTimedate(time);
  emit('chooseTime', formatDatetimeUTC(timeObject.value));
};

let timer;
const indexCurrentTimeStart = ref(0);
const isOpenTimeStart = ref(false);
const onChangeTime = async () => {
  isOpenTimeStart.value = true;
  if (timer) clearTimeout(timer);
  timer = setTimeout(async () => {
    let elementStart = document.getElementById(
      `calendar-timepicker-${indexCurrentTimeStart.value}`
    );
    elementStart && elementStart.scrollIntoView({ block: 'center' });
  }, 100);
};

const disabledTimeOption = (hour, minute) => {
  const currentDate = new Date();

  const _selectedDate = new Date(new Date(props.time).toDateString());
  const _currentDate = new Date(new Date().toDateString());

  if (_selectedDate > _currentDate) return false;
  if (_selectedDate < _currentDate) return true;

  return (
    hour < currentDate.getHours() ||
    (currentDate.getHours() == hour && minute < currentDate.getMinutes())
  );
};
</script>

<template>
  <div class="flex">
    <vig-dropdown
      :arrow="false"
      placement="bottom"
      :model-config="modelConfig"
      :masks="masks"
      :locale="locate"
      @on-dropdown-open="onChangeTime()"
      @on-dropdown-close="isOpenTimeStart = false"
    >
      <template #dropdown-toggle>
        <div
          class="
            w-24
            flex-center
            rounded
            border-transparent
            hover:bg-current-50
            bg-current-50 bg-opacity-60
            py-1.5
            px-2
            cursor-pointer
            flex-center
            focus:border-current
            focus:ring
            focus:border
            focus:ring-current
            focus:ring-opacity-30
          "
        >
          {{ timeObject?.text }}
        </div>
      </template>
      <template #dropdown-menu>
        <div
          v-perfect-scrollbar
          class="w-full h-max max-h-48 overflow-auto small-scrollbar"
        >
          <button
            v-for="(hour, index) in timeAroundDayArr"
            :id="`calendar-timepicker-${index}`"
            :key="hour"
            :class="{
              'bg-gray-100': indexCurrentTimeStart == index,
              'cursor-not-allowed': disabledTimeOption(hour.hour, hour.minute),
              'cursor-pointer': !disabledTimeOption(hour.hour, hour.minute),
            }"
            class="dropdown-item hover:bg-gray-100 px-4 py-1 block"
            :disabled="disabledTimeOption(hour.hour, hour.minute)"
            @click="onChooseTime(hour, index)"
          >
            {{ hour.text }}
          </button>
        </div>
      </template>
    </vig-dropdown>
  </div>
</template>
