<script setup lang="ts">
import permissionStore from '@/store/permission';
import { computed, onMounted } from 'vue';

defineEmits<{
  (e: 'cancel'): void;
}>();

const _permissionStore = permissionStore();

const limitData = computed(() => _permissionStore.allSystemConfigs);

onMounted(() => console.log(limitData.value));
</script>
<template>
  <SynModal
    container-class="w-max"
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    container-style="min-width: 500px; min-height: 280px; max-width: 500px"
    @cancel="$emit('cancel')"
  >
    <template #body>
      <div
        class="flex-1 flex flex-col justify-between"
        style="max-width: 40rem"
      >
        <div class="flex-center flex-col space-y-2">
          <div class="flex-center w-full p-2">
            <SynAnimation name="fileError" :stype="'width: 100px;'" />
          </div>
          <div class="flex flex-col text-center px-8">
            <span class="text-gray-500">
              {{
                $t('LIMIT_TASK_IMPORT_COUNT', {
                  count:
                    limitData?.ADVANCED_FUNCTION_LIMIT_TOTAL_ROW_IMPORT_TASK,
                })
              }}
            </span>
          </div>
        </div>
        <div class="w-full flex items-center justify-end">
          <SynButton class="bg-current" @click="$emit('cancel')">
            <span>
              {{ $t('COMMON_LABEL_GOT_IT') || 'Got it' }}
            </span>
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
