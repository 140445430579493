// prettier-ignore
<script setup lang="ts">
import { ref } from 'vue';
import GroupDetailModal from '@/ui/modules/group/detail/GroupDetailModal.vue';

defineProps<{
  groups?: any[];
}>();
const isShowInfoGroup = ref(false);
const selectedGroupId = ref(0);
const onSelectGroup = (group) => {
  isShowInfoGroup.value = true;
  selectedGroupId.value = group.id;
};
</script>
<template>
  <div
    class="
      flex flex-col
      text-current-700
      justify-start
      cursor-pointer
      flex-wrap
      rounded-t-md
      divide-y divide-current-50
    "
  >
    <div
      v-for="group in groups"
      :key="group"
      class="hover:bg-gray-100 rounded p-2"
      :title="group.name"
      @click="onSelectGroup(group)"
    >
      <div class="flex items-center">
        <div>
          <div class="min-w-max flex items-center">
            <SynAvatarStatusActive
              :id="group?.id"
              :avatar="group?.avatar?.replace('original', 'mini')"
              :name="group?.name"
              :is-user="false"
              is-hidden-name
              custom-class="w-8 h-8"
            >
              <template #avatar-suffix>
                <div
                  v-if="group.isPrivate"
                  class="
                    absolute
                    bottom-0
                    bg-white
                    rounded-full
                    flex-center
                    w-4
                    h-4
                    right-0
                  "
                >
                  <SynIcon
                    class="fill-current"
                    custom-class="w-2.5 h-2.5"
                    :name="
                      group.code == 'DELETED_GROUP'
                        ? 'cancel'
                        : group.isPrivate
                        ? 'private'
                        : ''
                    "
                  />
                </div>
              </template>
            </SynAvatarStatusActive>
          </div>
        </div>
        <div class="flex-1 flex items-center justify-between">
          <div class="flex flex-col space-y-1 pl-2">
            <div class="flex items-center">
              <span class="text-overflow-hidden-line text-sm font-medium">
                {{ group.name }}
              </span>
            </div>
            <div class="flex">
              <span
                class="px-2 rounded-full text-xs flex-center w-max italic"
                :class="
                  group.isAdminGroup
                    ? 'border border-orange-400 text-orange-400'
                    : 'border border-current-400 text-current'
                "
              >
                {{
                  group.isAdminGroup
                    ? $t('COMMON_LABEL_ADMIN')
                    : $t('COMMON_LABEL_MEMBER')
                }}
              </span>
            </div>
          </div>

          <div>
            <SynListAvatar
              :users="group.groupUsers"
              :custom-class="'w-6 h-6 text-xs'"
              :max-length="2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <GroupDetailModal
    v-if="isShowInfoGroup"
    :group-id="selectedGroupId"
    position="list"
    current-tab="INFO_GROUP"
    @cancel="isShowInfoGroup = false"
  />
</template>
