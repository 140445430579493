import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import FullCalendarEventClass from './FullCalendarEventClass';

export default class TaskCalendarViewClass extends FullCalendarEventClass {
    extendedProps: TaskDetailClass;

    constructor(data: any) {
        super(data);

        this.extendedProps = new TaskDetailClass(data?.extendedProps);
    }
}
