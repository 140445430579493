<template>
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    xmlns:svgjs="http://svgjs.com/svgjs"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 35 35"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path-->
  <!--        d="M17.5 22.131a1.249 1.249 0 0 1-1.25-1.25V2.187a1.25 1.25 0 0 1 2.5 0v18.694a1.25 1.25 0 0 1-1.25 1.25z"-->
  <!--        fill="#000000"-->
  <!--        data-original="#000000"-->
  <!--      ></path>-->
  <!--      <path-->
  <!--        d="M17.5 22.693a3.189 3.189 0 0 1-2.262-.936l-6.751-6.751a1.249 1.249 0 0 1 1.767-1.767l6.751 6.751a.7.7 0 0 0 .99 0l6.751-6.751a1.25 1.25 0 0 1 1.768 1.767l-6.752 6.751a3.191 3.191 0 0 1-2.262.936z"-->
  <!--      ></path>-->
  <!--      <path-->
  <!--        d="M31.436 34.063H3.564A3.318 3.318 0 0 1 .25 30.749v-8.738a1.25 1.25 0 0 1 2.5 0v8.738a.815.815 0 0 0 .814.814h27.872a.815.815 0 0 0 .814-.814v-8.738a1.25 1.25 0 1 1 2.5 0v8.738a3.318 3.318 0 0 1-3.314 3.314z"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    xmlns:svgjs="http://svgjs.com/svgjs"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 24 24"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path-->
  <!--        d="M21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1zm-9.71 1.71a1 1 0 0 0 .33.21.94.94 0 0 0 .76 0 1 1 0 0 0 .33-.21l4-4a1 1 0 0 0-1.42-1.42L13 12.59V3a1 1 0 0 0-2 0v9.59l-2.29-2.3a1 1 0 1 0-1.42 1.42z"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    xmlns:svgjs="http://svgjs.com/svgjs"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 512 512"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--    class=""-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path-->
  <!--        d="M512 480c0 17.673-14.327 32-32 32H32c-17.673 0-32-14.327-32-32s14.327-32 32-32h448c17.673 0 32 14.326 32 32zM142.623 177.378c8.189 0 16.379-3.124 22.627-9.373l58.75-58.75v246.746c0 17.673 14.327 32 32 32s32-14.327 32-32V109.255l58.75 58.75c12.497 12.497 32.758 12.497 45.255 0s12.497-32.758 0-45.255L278.627 9.373c-12.497-12.497-32.758-12.497-45.255 0L119.995 122.75c-12.497 12.497-12.497 32.758 0 45.255 6.249 6.249 14.438 9.373 22.628 9.373z"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M30 24v4c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2v-4a1 1 0 1 1 2 0v4h24v-4a1 1 0 1 1 2 0z"
      ></path>
      <path
        d="M8.293 10.707a.999.999 0 0 1 0-1.414l6.999-6.999a1.004 1.004 0 0 1 1.416 0l6.999 6.999a.999.999 0 1 1-1.414 1.414L17 5.414V23a1 1 0 1 1-2 0V5.414l-5.293 5.293a.999.999 0 0 1-1.414 0z"
      ></path>
    </g>
  </svg>
</template>
