<template>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        id="path"
        d="M261.328 21C178.143 21 102.56 65.7748 61.3661 137.151L46.6694 33.9708L18 38.1478L39.4296 188.741L188.207 167.05L184.08 138.03L86.126 152.32C122.18 89.6645 188.351 50.3125 261.328 50.3125C373.11 50.3125 464.041 142.354 464.041 255.5C464.041 368.646 373.11 460.688 261.328 460.688C149.546 460.688 58.615 368.646 58.615 255.5L29.656 255.5C29.656 384.841 133.619 490 261.328 490C389.037 490 493 384.841 493 255.5C493 126.159 389.037 21 261.328 21Z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
