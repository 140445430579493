<script setup lang="ts">
import useResourceFilterCategories from '@/ui/composables/resource/resource-filter-categories-composables';
import { computed } from 'vue';
import $constants from '@/ui/plugins/constants';

const props = defineProps<{
  selectedCategoryId: string;
  selectedMember?: any;
}>();

defineEmits(['update:selectedCategoryId']);

const { categories } = useResourceFilterCategories();

const categoriesFiltered = computed(() => {
  if (props.selectedMember?.isDomain) {
    return categories.value?.filter(
      (cate) => cate?.id === $constants.FILE.CATEGORY.STORAGE
    );
  }

  return categories.value;
});
</script>

<template>
  <div class="h-full px-3 py-2 flex space-x-2">
    <button
      v-for="category of categoriesFiltered"
      :key="category"
      :title="$t(category?.title)"
      class="h-full py-2 px-3 rounded relative text-sm"
      :class="
        category?.id === selectedCategoryId
          ? 'bg-current text-white'
          : 'bg-white hover:bg-gray-100 text-gray-600 bg-gray-50'
      "
      style="max-width: 9rem"
      @click="$emit('update:selectedCategoryId', category?.id)"
    >
      <span class="block truncate">{{
        $t(category?.name) || category?.name
      }}</span>
    </button>
  </div>
</template>
