<script setup lang="ts">
import TaskPlanCalendarByUser from '@/ui/modules/overview/member/TaskPlanCalendarByUser.vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { computed } from 'vue';
import overviewTeamStore from '@/store/dashboard/overview-team-store';
import userStore from '@/store/user';
import TaskTimeBlockingSingle from '@/ui/pages/tasks/calendar/TaskTimeBlockingSingle.vue';
import principleStore from '@/store/principle';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import dayjs from 'dayjs';

defineProps<{
  departmentId?: number;
  isHiddenTask?: boolean;
}>();

const _overviewTeamStore = overviewTeamStore();

const overviewTeamUserIds: any = computed(
  () => _overviewTeamStore.overviewTeamUserIds
);
const overviewTeamInfo: any = computed(
  () => _overviewTeamStore.overviewTeamInfo
);
const searchText = computed(() => _overviewTeamStore.keySearchTeam);
const filterMembers = computed(() => {
  let result = overviewTeamUserIds.value?.map((userId) => {
    return {
      ...overviewTeamInfo.value[userId].userInfo,
      ...userStore().allUserByIds[userId],
      workInfo: overviewTeamInfo.value[userId]?.workInfo,
    };
  });

  const searchTxt = ignoreUnicode(searchText.value);
  if (searchTxt)
    result = (result || []).filter((member) => {
      const revertName = ignoreUnicode(
        member?.lastName + ' ' + member?.firstName
      );
      return (
        member?.fullName.includes(searchTxt) || revertName.includes(searchTxt)
      );
    });

  return arrayOrderBy(
    result,
    [
      (u) => (u?.workInfo?.online ? 1 : 2),
      (u) => u?.workInfo?.startIndex,
      (u) => ignoreUnicode(u?.firstName),
    ],
    ['asc', 'asc', 'asc']
  );
});
const isStarting = computed(() => principleStore().isStarting);

const onCalendarReady = () => {
  const isMorning = dayjs().isBefore(dayjs().set('hour', 12).set('minute', 30));
  const selectorString = isMorning
    ? '.fc-timegrid-slot[data-time="06:00:00"]'
    : '.fc-timegrid-slot[data-time="13:00:00"]';
  const element = document.querySelector(selectorString);

  // Scroll the element into view
  if (element) element.scrollIntoView();
};
</script>
<template>
  <div v-if="!isStarting" class="h-full w-auto flex overflow-auto gap-1">
    <div
      ref="containerElmRef"
      class="shrink-0 h-auto sticky left-0 z-20"
      :style="{
        width: '61px',
        display: 'inline-table',
      }"
    >
      <div
        class="
          h-16
          sticky
          top-0
          left-0
          z-10
          w-full
          bg-white
          shadow-[rgba(0,0,0,0.1)_0px_4px_2px_-2px]
        "
      ></div>
      <TaskTimeBlockingSingle
        ref="taskTimeBlockingSingleRef"
        current-view-mode="timeGridDay"
        is-multiple
        is-show-time-only
        @ready="onCalendarReady"
      />
    </div>
    <TaskPlanCalendarByUser
      v-for="member in filterMembers"
      :key="member?.id"
      :user-info="member"
    />
  </div>
</template>
