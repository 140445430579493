import PermissionRepository from '@/application/repositories/PermissionRepository';

export default class PermissionService {
    private static instance: PermissionService;
    _permissionRepository: PermissionRepository;

    constructor() {
        this._permissionRepository = PermissionRepository.getInstance();
    }

    public static getInstance(): PermissionService {
        if (!PermissionService.instance) {
            // Get from local storage
            PermissionService.instance = new PermissionService();
        }
        return PermissionService.instance;
    }

    getPermissionsByUser(): Promise<any> {
        return this._permissionRepository.getPermissionsByUser();
    }
}
