<script setup lang="ts">
import { onMounted, ref } from 'vue';
import taskStore from '@/ticket/store/task-store';
import { TicketSingleton } from '@/ticket/services/organization-service';
import TaskListTableCell from '@/ticket/modules/task/TaskListTableCell.vue';
import TicketTaskDetailModal from '@/ticket/modules/task/TicketTaskDetailModal.vue';
import { chain } from 'lodash';
import { sortDefaultTaskList } from '@/ui/hooks/taskHook';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
const _taskStore = taskStore();
const taskHistories = ref([] as any[]);

onMounted(() => {
  getTasksWithFilter('current', true);
  getTasksWithFilter('finished', false);
  getTasksWithFilter('archived', false);
});

const isLoading = ref(false);
const getTasksWithFilter = async (filter, isNeedUpdate) => {
  isLoading.value = true;
  const ticketSingletonInstance = TicketSingleton.getInstance();
  const res = await _taskStore.getHistories({
    listOwnerId: ticketSingletonInstance.userId,
    status: filter, // 'finished' | 'archived'
    groupId: ticketSingletonInstance.groupId,
    organizationId: ticketSingletonInstance.organizationId,
    externalRootUID: ticketSingletonInstance.externalRootUID,
    externalUID: ticketSingletonInstance.externalUID,
  });

  const tasks = res.result;
  filterStatus.value[filter].totalItem = tasks?.length || 0;
  isLoading.value = false;

  if (isNeedUpdate) {
    taskHistories.value = sortTaskDefault(
      tasks.map((task) => {
        return {
          ...task,
          urgency: getUrgencyValue(task?.priority, task?.important),
        };
      })
    );
  }
};

const sortTaskDefault = (tasks) => {
  if (!tasks || tasks.length == 0) return [];
  const result = chain(tasks)
    .orderBy(sortDefaultTaskList.iterate, sortDefaultTaskList.order)
    .value();
  return result;
};

const currentTab = ref('current');

const filterStatus = ref({
  current: {
    key: 'current',
    label: translate('COMMON_LABEL_CURRENT') || 'Current',
    labelCode: 'COMMON_LABEL_CURRENT',
    totalItem: 0,
  },
  finished: {
    key: 'finished',
    label: translate('TASK_STATE_EXECUTED') || 'Executed',
    labelCode: 'TASK_STATE_EXECUTED',
    totalItem: 0,
  },
  archived: {
    key: 'archived',
    label: translate('TASK_STATE_ARCHIVED') || 'Archived',
    labelCode: 'TASK_STATE_ARCHIVED',
    totalItem: 0,
  },
});
const onChangeTab = (tab) => {
  currentTab.value = tab.key;

  getTasksWithFilter(tab.key, true);
};

const isOpenTaskDetail = ref(false);
const currentTaskDetail = ref({} as any);
const onOpenTaskDetail = (task) => {
  currentTaskDetail.value = task;
  isOpenTaskDetail.value = true;
};
</script>

<template>
  <div class="h-full flex flex-col space-y-1">
    <div class="flex space-x-2 item-center justify-between pt-1 border-b pr-2">
      <div class="flex item-center space-x-1">
        <div
          v-for="item in Object.values(filterStatus)"
          :key="item.key"
          class="
            cursor-pointer
            flex-center
            text-xs
            py-0.5
            px-2
            hover:bg-gray-50
          "
          :class="
            currentTab == item.key
              ? 'border-b-2 border-current text-current-500 font-medium'
              : 'rounded'
          "
          @click.stop="onChangeTab(item)"
        >
          <span class="text-xs">{{ $t(item?.labelCode) || item?.label }}</span>
          <span v-if="item?.totalItem !== 0" class="px-1 text-xs">
            {{ '(' + item?.totalItem + ')' }}
          </span>
        </div>
      </div>
      <span
        class="flex-center h-5 w-5 hover:bg-current-50 cursor-pointer rounded"
        @click.stop="getTasksWithFilter(currentTab, true)"
      >
        <SynIcon name="reload" custom-class="h-3 w-3" />
      </span>
    </div>
    <div class="flex-1 min-h-0 bg-white overflow-y-auto small-scrollbar">
      <div
        v-if="isLoading"
        class="absolute w-full h-full bg-current-50 opacity-50 flex-center"
      >
        <SynPageLoading is-hidden-button />
      </div>
      <syn-table-custom class="">
        <template #header>
          <syn-tr>
            <syn-th :label="$t('TASK_CREATE_FORM_LABEL_STATUS')"> </syn-th>
            <syn-th :label="'Ticket'" style="min-width: 12rem"> </syn-th>
            <syn-th class="text-sm" :label="'Type'"> </syn-th>
            <syn-th :label="$t('TASK_TABLE_LABEL_PRIORITY')"> </syn-th>
            <syn-th :label="$t('TASK_TABLE_LABEL_CREATED_DATE')"> </syn-th>
          </syn-tr>
        </template>
        <template #body>
          <template v-if="taskHistories?.length > 0">
            <syn-tr
              v-for="(task, index) in taskHistories"
              :key="task.id"
              :class="index % 2 == 0 ? 'bg-gray-50' : ''"
              :id-task="task.id"
            >
              <TaskListTableCell
                v-if="task"
                :task="task"
                @on-open-task-detail="onOpenTaskDetail(task)"
              />
            </syn-tr>
          </template>
        </template>
      </syn-table-custom>
    </div>
  </div>

  <TicketTaskDetailModal
    v-if="isOpenTaskDetail"
    :task-code="currentTaskDetail.code"
    @close="isOpenTaskDetail = false"
  />
</template>
