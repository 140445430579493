<script setup lang="ts">
import { onMounted, onUnmounted, computed } from 'vue';
import alertStore from '@/store/alert';
import { AlertType } from '@/ui/hooks/commonHook';
import { encryptUserId } from '@/ui/plugins/crypto/crypto';
// import { translate } from '@/ui/plugins/i18n/myi18n';

const props = withDefaults(
  defineProps<{
    notification: any;
    notificationValue: any;
    type: string;
    title?: string;
    body?: string;
    duration: number;
    avatar?: string;
  }>(),
  {
    type: 'info',
    duration: 5000,
    title: '',
    body: '',
    avatar: '',
  }
);

const _alertStore = alertStore();
const notificationTypeClass = computed(() => {
  if (props.type == AlertType.error) {
    return 'bg-red-400 text-white';
  }
  if (props.type == AlertType.info) {
    return 'bg-gray-300';
  }
  if (props.type == AlertType.warning) {
    return 'bg-orange-200 text-current-800';
  }
  return `bg-current-400 text-white`;
});

const metaData = computed(() => props.notification?.metaData);

const hasAction = computed(() => metaData.value?.hasAction);

let timeoutFn;
const timeout = () => {
  timeoutFn = setTimeout(
    () => _alertStore.removeDataAlerts(props.notification),
    props.duration || 5000
  );
};
onMounted(() => {
  timeout();
});
onUnmounted(() => {
  clearTimeout(timeoutFn);
});

const onClose = (notification) => {
  _alertStore.removeDataAlerts(notification);
};
const onMouseover = () => {
  clearTimeout(timeoutFn);
};
const onMouseleave = () => {
  timeout();
};
const onClick = (notification) => {
  // _alertStore.handleClickAlertItem(props.notification);
  onClose(notification);
  window.open(
    `/tasks/users/${encryptUserId(props.notificationValue.member_id)}`,
    '_self'
  );
};
</script>

<template>
  <div
    class="
      w-full
      relative
      p-2
      rounded
      flex
      space-x-2
      items-start
      justify-between
      transition-all
      bg-opacity-80
      mt-4
    "
    :class="`${notificationTypeClass} ${hasAction ? 'cursor-pointer' : ''}`"
    @mouseover="onMouseover"
    @mouseleave="onMouseleave"
  >
    <div class="shrink-0 flex-center">
      <SynAvatar
        custom-class="w-8 h-8"
        :src="notificationValue?.member_avatar?.replace('original', 'small')"
        :name="notificationValue.member_name"
      />
    </div>

    <div class="flex-1 flex flex-col">
      <div class="text-ellipsis text-sm py-2" style="white-space: pre-wrap">
        {{ title }}
      </div>
      <div v-if="notificationValue?.start_working === 'true'">
        <div
          v-if="parseInt(notificationValue?.task_plan_todo_today ?? '0') > 0"
          class="text-ellipsis text-sm italic"
          style="white-space: pre-wrap"
        >
          {{
            $t('NOTIFICATION_START_WORKING_TASK_TODO', {
              taskCount: notificationValue?.task_plan_todo_today,
            })
          }}
        </div>
        <div
          v-if="parseInt(notificationValue?.task_plan_finish_today ?? '0') > 0"
          class="text-ellipsis text-sm italic"
          style="white-space: pre-wrap"
        >
          {{
            $t('NOTIFICATION_START_WORKING_TASK_TOFINISH', {
              taskCount: notificationValue?.task_plan_finish_today,
            })
          }}
        </div>
      </div>
      <div v-else>
        <div class="text-ellipsis text-sm italic" style="white-space: pre-wrap">
          {{
            $t('NOTIFICATION_STOP_WORKING_TASK_FINISH', {
              taskCount: notificationValue?.task_finished_today,
            })
          }}
        </div>
        <div class="text-ellipsis text-sm italic" style="white-space: pre-wrap">
          {{
            $t('NOTIFICATION_STOP_WORKING_TASK_FINISH_PERCENTAGE', {
              taskCount: notificationValue?.percentage_finished_task,
            })
          }}
        </div>
      </div>
      <div class="flex justify-end text-xs mt-3">
        <syn-button
          :label="
            $t('LIST_TASK_VIEW_USER_TASK_WORKING_STATUS_NOTIFY', {
              user: notificationValue?.member_name,
            })
          "
          color="white"
          type-outline
          @click="onClick(notification)"
        >
        </syn-button>
      </div>
      <div
        class="
          cursor-pointer
          w-6
          h-6
          rounded-full
          hover:bg-white hover:bg-opacity-20
          flex-center
          absolute
          top-1
          right-1
          do-not-close-task-detail-drawer
        "
        @click.stop="onClose(notification)"
      >
        <SynIcon name="close" custom-class="w-4 h-4 fill-white" />
      </div>
    </div>
  </div>
  <!-- <audio class="hidden" autoplay>
      <source
        src="https://s3.ap-southeast-1.amazonaws.com/tictop.source-default/web/audio/coockoo.wav"
        type="audio/ogg"
      />
    </audio> -->
</template>
