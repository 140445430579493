<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  CalendarReminderRepeatType,
  Weekday,
} from '@/ui/common/constants/calendar';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  getOnDateOfMonthString,
  getOnDateOfYearString,
  getOnDayOfWeekString,
} from '@/ui/hooks/calendarHook';
import dayjs from 'dayjs';

const props = defineProps<{
  modelValue: number | null;
  daysOfWeek?: number[];
  startDate?: Date;
}>();

const emit = defineEmits(['update:modelValue', 'update:daysOfWeek']);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const repeatOptions = computed(() => {
  return [
    {
      value: CalendarReminderRepeatType.DoNotRepeat,
      text: translate('CALENDAR_REMINDER_NO_REPEAT'),
    },
    {
      value: CalendarReminderRepeatType.EveryDay,
      text: translate('COMMON_LABEL_DAILY'),
    },
    {
      value: CalendarReminderRepeatType.EverySpecificDayOfWeek,
      text:
        translate('COMMON_LABEL_WEEKLY') +
        (props?.startDate ? ` ${getOnDayOfWeekString(props.startDate)}` : ''),
    },
    {
      value: CalendarReminderRepeatType.EverySpecificDayInMonth,
      text:
        translate('COMMON_LABEL_MONTHLY') +
        (props?.startDate ? ` ${getOnDateOfMonthString(props.startDate)}` : ''),
    },
    {
      value: CalendarReminderRepeatType.EverySpecificDayInYear,
      text:
        translate('COMMON_LABEL_YEARLY') +
        (props?.startDate ? ` ${getOnDateOfYearString(props.startDate)}` : ''),
    },
    {
      value: CalendarReminderRepeatType.CustomSpecificDayOfWeek,
      text: translate('CALENDAR_REMINDER_REPEAT_CUSTOM_DAY_WEEK'),
    },
  ];
});

const weekdays = ref(
  [
    Weekday.MO,
    Weekday.TU,
    Weekday.WE,
    Weekday.TH,
    Weekday.FR,
    Weekday.SA,
    Weekday.SU,
  ].map((wd) => ({ value: wd, text: dayjs().day(wd).format('dd') }))
);

const onWeekdayClick = (weekday) => {
  let selectedWeekdays;

  if (props.daysOfWeek?.includes(weekday)) {
    selectedWeekdays = props.daysOfWeek?.filter((d) => d !== weekday);
  } else {
    selectedWeekdays = (props.daysOfWeek || []).concat([weekday]);
  }

  emit('update:daysOfWeek', selectedWeekdays);
};
</script>

<template>
  <div>
    <SynSelect
      v-model="model"
      :options="repeatOptions"
      key-field="value"
      text-field="text"
    />

    <div
      v-if="model === CalendarReminderRepeatType.CustomSpecificDayOfWeek"
      class="flex gap-2 mt-2"
    >
      <VigButton
        v-for="weekday in weekdays"
        :key="weekday.value"
        padding="p-1"
        rounded="rounded-full"
        class="w-8 h-8"
        :outline="!daysOfWeek?.includes(weekday.value)"
        :color="daysOfWeek?.includes(weekday.value) ? 'current' : 'gray'"
        @click="onWeekdayClick(weekday.value)"
      >
        <span class="font-normal text-sm">{{ weekday?.text }}</span>
      </VigButton>
    </div>
  </div>
</template>

<style scoped></style>
