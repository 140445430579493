<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M18.5 23h-15A2.503 2.503 0 0 1 1 20.5v-15C1 4.122 2.122 3 3.5 3h9a.5.5 0 0 1 0 1h-9C2.673 4 2 4.673 2 5.5v15c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5v-9a.5.5 0 0 1 1 0v9c0 1.378-1.122 2.5-2.5 2.5zM22.5 9a.5.5 0 0 1-.5-.5V2h-6.5a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5z"
        opacity="1"
        class=""
      ></path>
      <path
        d="M10.5 14a.5.5 0 0 1-.354-.853l12-12a.5.5 0 0 1 .707.707l-12 12A.498.498 0 0 1 10.5 14z"
        opacity="1"
        class=""
      ></path>
    </g>
  </svg>
</template>
