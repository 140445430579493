<script setup lang="ts">
import { ref } from 'vue';
import { StartWorkingConditionModel } from '@/application/models/setting/StartWorkingConditionModel';
import UsersSelect from '@/ui/modules/messaging/modal-new-group-chat/UsersSelect/UsersSelect.vue';
import { AdminType, CurrentUserConfig } from '@/ui/common/constants/constant';
import SWSettingsForMembers from '@/ui/modules/settings/start-working/modal-settings-for-members/SWSettingsForMembers.vue';
import commonStore from '@/store/common/commonStore';

// *** PROPS, EMITS ***
const emit = defineEmits(['onClose']);

// *** PRIVATE VARIABLES ***
const _commonStore = commonStore();

// *** COMPOSABLES ***

// *** REFS ***
const step = ref<number>(1);
const selectedUsers = ref<any[]>();
const sWCondition = ref<StartWorkingConditionModel | null>();
const isApplying = ref<boolean>();

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onApplyClick = async () => {
  isApplying.value = true;

  try {
    const data = {
      userIds: selectedUsers.value?.map((u) => u?.id),
      key: CurrentUserConfig.StartWorkingConditionsSpecific,
      value: sWCondition.value ? JSON.stringify(sWCondition.value) : null,
    };

    await _commonStore.setMultiUsersSetting(data);

    emit('onClose');
  } catch (e) {
    console.log(e);
  }

  isApplying.value = true;
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <SynModal
    container-class="h-[90%] w-11/12 xl:w-[75rem]"
    style-body="px-4"
    disable-esc-to-close
    disable-click-outside
    @cancel="$emit('onClose')"
  >
    <template #header> {{ $t('SETTING_SW_CONDITION_SPECIFIC') }}</template>
    <template #body>
      <!--SELECT USERS-->
      <template v-if="step === 1">
        <div class="text-current-600 text-2xl text-center mb-5">
          {{ $t('SETTING_SW_CONDITION_SELECT_MEMBERS') }}
        </div>
        <div class="flex-1 overflow-hidden flex justify-center">
          <div class="h-full w-full max-w-[44rem]">
            <UsersSelect v-model="selectedUsers" />
          </div>
        </div>
      </template>

      <!--SELECT CONDITIONS-->
      <template v-if="step === 2">
        <div class="text-current-600 text-2xl text-center mb-5">
          {{ $t('SETTING_SW_CONDITION_SET') }}
        </div>
        <div class="flex-1 overflow-hidden flex gap-4">
          <!--SELECTED USERS-->
          <div class="h-full w-64 flex flex-col">
            <div class="flex items-center gap-2 mb-2">
              <span class="font-semibold text-gray-600">{{
                $t('COMMON_LABEL_MEMBERS')
              }}</span>
              <VigButton ghost icon="Edit" @click="step = step - 1" />
            </div>
            <div v-perfect-scrollbar class="flex-1">
              <SynAvatarStatusActive
                v-for="user in selectedUsers"
                :id="user?.id"
                :key="user?.id"
                :avatar="user?.avatar"
                custom-class="h-6 w-6 mb-2"
                :name="user?.name"
                :is-user="true"
                :is-visitor="user?.adminType === AdminType.Visitor"
              />
            </div>
          </div>

          <!--SETTINGS CONDITIONS-->
          <div class="flex-1 overflow-hidden">
            <SWSettingsForMembers v-model:setting="sWCondition" />
          </div>
        </div>
      </template>
    </template>
    <template #footer>
      <div class="flex items-center justify-end px-4 py-3 gap-2">
        <VigButton
          light
          color="gray"
          :label="$t('COMMON_LABEL_CANCEL')"
          @click="$emit('onClose')"
        />
        <VigButton
          v-if="step === 2"
          color="gray"
          :label="$t('COMMON_LABEL_BACK')"
          @click="step = step - 1"
        />
        <VigButton
          v-if="step === 1"
          :label="$t('COMMON_LABEL_NEXT')"
          :disabled="!selectedUsers?.length"
          @click="step = step + 1"
        />
        <VigButton
          v-if="step === 2"
          :label="$t('COMMON_LABEL_APPLY')"
          :processing="isApplying"
          @click="onApplyClick"
        />
      </div>
    </template>
  </SynModal>
</template>

<style scoped></style>
