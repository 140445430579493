<script setup lang="ts">
import { onMounted } from 'vue';
import { ref } from 'vue';

const props = defineProps<{
  modelValue: string;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const selectedYear = ref<number>(2024);
const selectedMonth = ref<number>(1);

let timeoutToSave;
const onSave = () => {
  console.log('🚀 Tictop ~ selectedYear.value:', selectedYear.value);
  const monthString = `0${selectedMonth.value}`;
  emit('update:modelValue', `${monthString.slice(-2)}-${selectedYear.value}`);
};

const dataByMonthNumber = {
  1: { key: 1, labelCode: 'LABEL_JANUARY' },
  2: { key: 2, labelCode: 'LABEL_FEBRUARY' },
  3: { key: 3, labelCode: 'LABEL_MARCH' },
  4: { key: 4, labelCode: 'LABEL_APRIL' },
  5: { key: 5, labelCode: 'LABEL_MAY' },
  6: { key: 6, labelCode: 'LABEL_JUNE' },
  7: { key: 7, labelCode: 'LABEL_JULY' },
  8: { key: 8, labelCode: 'LABEL_AUGUST' },
  9: { key: 9, labelCode: 'LABEL_SEPTEMBER' },
  10: { key: 10, labelCode: 'LABEL_OCTOBER' },
  11: { key: 11, labelCode: 'LABEL_NOVEMBER' },
  12: { key: 12, labelCode: 'LABEL_DECEMBER' },
};
const yearList = ref<number[]>([]);

onMounted(() => {
  yearList.value = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6].map(
    (o) => o + selectedYear.value
  );
});

const viewMode = ref<'SELECT_MONTH' | 'SELECT_YEAR'>('SELECT_MONTH');

const onPrevious = () => {
  if (viewMode.value == 'SELECT_MONTH') {
    selectedYear.value -= 1;
    yearList.value = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6].map(
      (o) => o + selectedYear.value
    );
  } else {
    selectedYear.value -= 10;
    yearList.value = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6].map(
      (o) => o + selectedYear.value
    );
  }

  if (timeoutToSave) clearTimeout(timeoutToSave);

  timeoutToSave = setTimeout(() => {
    onSave();
  }, 500);
};
const onNext = () => {
  if (viewMode.value == 'SELECT_MONTH') {
    selectedYear.value += 1;
    yearList.value = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6].map(
      (o) => o + selectedYear.value
    );
  } else {
    selectedYear.value += 10;
    yearList.value = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6].map(
      (o) => o + selectedYear.value
    );
  }

  if (timeoutToSave) clearTimeout(timeoutToSave);

  timeoutToSave = setTimeout(() => {
    onSave();
  }, 500);
};

const onSelectYear = (year) => {
  selectedYear.value = year;
  viewMode.value = 'SELECT_MONTH';

  onSave();
};
const onSelectMonth = (month) => {
  selectedMonth.value = month;

  onSave();
};
const regex = /([0][1-9]|[1][0-2])-[1-9][0-9][0-9]{2}/;

const onChangeInput = (event) => {
  if (!event?.target?.value) return;

  if (regex.test(event?.target?.value)) {
    emit('update:modelValue', event?.target?.value);
  } else {
    event.target.value = props.modelValue;
  }
};
</script>
<template>
  <VigDropdown ref="vigDropdownRef" :arrow="false" placement="bottom-start">
    <template #dropdown-toggle>
      <SynInput :model-value="modelValue" @blur="onChangeInput">
        <template #suffix>
          <SynIcon name="calendar" />
        </template>
      </SynInput>
    </template>
    <template #dropdown-menu>
      <div class="w-64 flex flex-col">
        <div class="flex items-center justify-between p-2">
          <SynIcon name="previous" has-action @click="onPrevious" />
          <div v-if="viewMode == 'SELECT_YEAR'" class="flex-1 flex-center">
            <span class="cursor-pointer">
              {{ yearList.at(0) }} ~ {{ yearList.at(yearList?.length - 1) }}
            </span>
          </div>
          <div v-else class="flex-1 flex-center">
            <span class="cursor-pointer" @click="viewMode = 'SELECT_YEAR'">
              {{ selectedYear }}
            </span>
          </div>
          <SynIcon name="next" has-action @click="onNext" />
        </div>
        <div class="grid grid-cols-3 gap-1 p-2 text-sm">
          <template v-if="viewMode == 'SELECT_YEAR'">
            <div
              v-for="year in yearList"
              :key="year"
              class="
                h-12
                flex-center
                hover:bg-gray-50
                cursor-pointer
                rounded
                shadow-sm
              "
              :class="[selectedYear == year ? 'bg-current-50' : '']"
              @click="onSelectYear(year)"
            >
              {{ year }}
            </div>
          </template>
          <template v-else>
            <div
              v-for="month in 12"
              :key="month"
              class="
                h-12
                flex-center
                hover:bg-gray-50
                cursor-pointer
                rounded
                shadow-sm
              "
              :class="[selectedMonth == month ? 'bg-current-50' : '']"
              @click="onSelectMonth(month)"
            >
              {{ $t(dataByMonthNumber[month]?.labelCode) }}
            </div>
          </template>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
