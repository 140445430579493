<script setup lang="ts">
import { computed, ref } from 'vue';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import { checkValidUrl } from '@/ui/hooks/commonFunction';
import commonStore from '@/store/common/commonStore';
import { ILinkInfo } from '@/ui/common/atoms/atom-attach-link/atom-attach-link.types';

const emit = defineEmits<{
  (e: 'done', data: { url: string; info: ILinkInfo }): void;
  (e: 'onCancel'): void;
}>();

const currentLink = ref<{ url: string; info: ILinkInfo }>({
  url: '',
  info: {
    title: '',
    description: '',
    imageUrl: '',
    domain: '',
    favicon: '',
  },
});
const isDisableButton = computed(
  () => isTypingLink.value || !currentLink.value || !currentLink.value?.url
);

const isLoadInformation = ref<boolean>(false);
const getUrlInformation = async () => {
  if (!currentLink.value?.url || !checkValidUrl(currentLink.value?.url)) {
    isLoadInformation.value = false;
    return;
  }

  const prefix = currentLink.value?.url.split('.')[0];
  const hasPrefix = ['http', 'https', 'www'].some((o) => prefix?.includes(o));
  if (!hasPrefix) {
    currentLink.value.url = `https://${currentLink.value.url}`;
  }

  const linkUrl = currentLink.value?.url;

  currentLink.value.info = await commonStore().getPreviewLinkInfo(linkUrl);

  isLoadInformation.value = false;
};
const {
  isTyping: isTypingLink,
  validationResult: validationResultFirstName,
  onValidation: onValidationFirstName,
} = atomFormInputComposables(
  [
    (value) => {
      if (!value.trim()) {
        isLoadInformation.value = false;
        return 'COMMON_THIS_FIELD_REQUIRED';
      }

      const isValid = checkValidUrl(value);
      if (!isValid) {
        isLoadInformation.value = false;
        return 'COMMON_LABEL_LINK_IS_NOT_VALID';
      } else {
        getUrlInformation();
      }

      return '';
    },
  ],
  750
);

const onCancel = () => {
  emit('onCancel');
};

const onDone = () => {
  emit('done', currentLink.value);
};

const removeInfo = () => {
  currentLink.value.info = {
    title: '',
    description: '',
    imageUrl: '',
    domain: '',
    favicon: '',
  };
};

const onChange = () => {
  isLoadInformation.value = true;
  onValidationFirstName(currentLink.value.url);
};
</script>

<template>
  <SynModal
    container-class="md:w-96"
    z-index="z-60"
    disable-click-outside
    disable-element-active
    @cancel="onCancel"
  >
    <template #header>
      <div class="flex items-center">
        <span class="px-2">{{ $t('GED_FILTER_LINK') }}</span>
      </div>
    </template>
    <template #body>
      <div class="w-full flex flex-col space-y-4">
        <SynFormInput :error-message="validationResultFirstName.message">
          <SynInput
            v-model="currentLink.url"
            class="w-full"
            autofocus
            input-type="text"
            :placeholder="$t('GED_FILTER_LINK') + '*'"
            :error-message="validationResultFirstName.message"
            @update:model-value="onChange"
          />
        </SynFormInput>

        <AtomLinkInformation
          v-if="currentLink.url"
          :is-loading="isLoadInformation"
          :link-url="currentLink?.url"
          :link-info="currentLink?.info"
          @on-close-preview-link="removeInfo"
        />
      </div>
    </template>

    <template #footer>
      <div class="w-full p-4 flex items-center justify-end">
        <div class="flex-center space-x-2">
          <SynButton
            :label="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            @click="onCancel"
          />
          <SynButton
            :disabled="isDisableButton"
            :is-loading="isLoadInformation"
            :label="$t('COMMON_LABEL_DONE')"
            @click="onDone"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
