<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.119 27.991">
    <path
      id="Icon_awesome-sort"
      data-name="Icon awesome-sort"
      d="M2.883,20.25H19.617a1.69,1.69,0,0,1,1.2,2.883L12.445,31.5a1.681,1.681,0,0,1-2.384,0L1.688,23.133A1.69,1.69,0,0,1,2.883,20.25Zm17.93-7.383L12.445,4.5a1.681,1.681,0,0,0-2.384,0L1.688,12.867a1.69,1.69,0,0,0,1.2,2.883H19.617A1.69,1.69,0,0,0,20.813,12.867Z"
      transform="translate(-1.191 -4.004)"
    />
  </svg>
</template>
