import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import ImportService from '@/application/services/ImportSevice';

export default defineStore({
    id: storeId.import,
    state: () => ({
        _importService: ImportService.getInstance(),
    }),
    getters: {},
    actions: {
        async importTaskData(csvFile) {
            try {
                const res = await this._importService.importTaskData(csvFile);
                return res['result'];
            } catch (error) {
                console.log(error);
            }
        },
    },
});
