<script setup lang="ts">
import { IStepTemplateEntity } from '@/domain/entities/workflow/StepTemplateEntity';
import WorkflowStepDetail from '@/ui/modules/workflow/workflow-step/WorkflowStepDetail.vue';
const props = defineProps<{
  viewMode?: 'VIEW' | 'EDIT' | 'ADD' | 'CLONE';
  workflowId?: string;
  stepDetail: IStepTemplateEntity;
  readonly?: boolean;
  actionList?: string[];
  isUpdateImmediately?: boolean;
  scope?: {
    departmentId?: number;
    groupId?: number;
    domainId?: number;
  };
  stepByIds?: any;
}>();
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'updateSuccess', newData: any): void;
  (e: 'removeSuccess'): void;
  (e: 'onSave', data: any): void;
}>();

const updateSuccess = (newData) => {
  emit('updateSuccess', newData);
  if (props.viewMode == 'ADD' || props.viewMode == 'EDIT') emit('close');
};
</script>
<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    :container-style="'max-height: 95%;min-height: 24rem; min-width: 32rem; max-width: 44rem'"
    disable-click-outside
    is-hidden-footer
    is-hidden-header
    @cancel="$emit('close')"
  >
    <template #body>
      <WorkflowStepDetail
        :step-detail="stepDetail"
        :view-mode="viewMode"
        :readonly="readonly"
        :action-list="actionList"
        :is-update-immediately="isUpdateImmediately"
        :scope="scope"
        :step-by-ids="stepByIds"
        @close="$emit('close')"
        @update-success="updateSuccess"
        @remove-success="$emit('removeSuccess')"
        @on-save="(data) => $emit('onSave', data)"
      />
    </template>
    <template #footer> </template>
  </SynModal>
</template>
