<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  checkValidSetTaskAsPrivate,
  TaskDetailClass,
} from '@/domain/entities/task/TaskPresentClass';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { parseHTML } from '@/ui/hooks/commonFunction';
import orgStore from '@/store/organization';
import getUserInfo from '@/ui/helpers/user-helper';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import getGroupInfo from '@/ui/helpers/group-helper';

const props = defineProps<{
  currentTask: TaskDetailClass;
  isOnlyShowPrivateIcon?: boolean;
}>();
const emit = defineEmits(['change']);

const _orgStore = orgStore();

const onMakePublic = () => {
  emit('change', false);
};
const onMakePrivate = () => {
  const isValid = checkValidSetTaskAsPrivate(
    props.currentTask,
    getCurrentUserId()
  );
  if (!isValid) return;

  emit('change', true);
};

const ownerTaskInfo = computed<any>(() => {
  if (props.currentTask?.groupId)
    return getGroupInfo(props.currentTask?.groupId);
  return getUserInfo(props.currentTask?.assigneeId);
});

const modePrivate = computed<
  | 'USER_TASK_PUBLIC'
  | 'USER_TASK_PRIVATE'
  | 'GROUP_TASK_PUBLIC'
  | 'GROUP_TASK_PRIVATE'
>(() => {
  if (props.currentTask?.groupId) {
    return ownerTaskInfo.value?.isPrivate
      ? 'GROUP_TASK_PRIVATE'
      : 'GROUP_TASK_PUBLIC';
  }
  return props.currentTask?.isPrivate
    ? 'USER_TASK_PRIVATE'
    : 'USER_TASK_PUBLIC';
});

const orgInfo = computed(() => _orgStore.organizationInfo);

const textPrivateParser = computed(() => {
  // GROUP_TASK_PRIVATE
  if (modePrivate.value == 'GROUP_TASK_PRIVATE')
    return parseHTML(
      translate('TASK_CREATE_IS_PUBLIC_GROUP', {
        groupName: getGroupInfo(props.currentTask?.groupId)?.name,
      })
    );

  // USER_TASK_PRIVATE
  if (modePrivate.value == 'USER_TASK_PRIVATE') {
    if (props.currentTask?.assigneeId === props.currentTask?.creatorId) {
      return parseHTML(translate('TASK_CREATE_IS_PRIVATE'));
    }
    if (props.currentTask?.assigneeId == getCurrentUserId()) {
      return parseHTML(
        translate('TASK_CREATE_IS_PRIVATE_WITH_OTHER', {
          name: getUserInfo(props.currentTask?.creatorId)?.name,
        })
      );
    }
    if (props.currentTask?.creatorId == getCurrentUserId()) {
      return parseHTML(
        translate('TASK_CREATE_IS_PRIVATE_WITH_OTHER', {
          name: getUserInfo(props.currentTask?.assigneeId)?.name,
        })
      );
    }
  }

  return parseHTML(
    translate('TASK_CREATE_IS_PUBLIC', { orgName: orgInfo.value?.name })
  );
});
const isOpenDropdown = ref<boolean>(false);
</script>
<template>
  <div
    v-if="
      isOnlyShowPrivateIcon
        ? modePrivate == 'GROUP_TASK_PRIVATE' ||
          modePrivate == 'USER_TASK_PRIVATE'
        : true
    "
    class="flex-center pin-task-current"
  >
    <div
      :title="textPrivateParser || ''"
      class="flex-center rounded w-6 h-6 relative"
      :class="isOpenDropdown ? 'bg-gray-100' : ''"
    >
      <SynIcon
        :name="
          modePrivate == 'GROUP_TASK_PRIVATE' ||
          modePrivate == 'USER_TASK_PRIVATE'
            ? 'private'
            : 'global'
        "
        class="fill-gray-500"
        custom-class="h-3 w-3"
      />
    </div>

    <div
      v-if="!isOnlyShowPrivateIcon"
      class="w-4 h-8 flex items-center flex-col"
      :class="isOpenDropdown ? '' : 'pin-task-current__hover-to-show'"
    >
      <VigDropdown
        @on-dropdown-open="isOpenDropdown = true"
        @on-dropdown-close="isOpenDropdown = false"
      >
        <template #dropdown-toggle>
          <div class="w-4 h-8 cursor-pointer rounded flex items-center">
            <SynIcon
              name="chevron-right"
              :custom-class="
                isOpenDropdown
                  ? 'w-2 h-2 fill-gray-500 -rotate-90'
                  : 'w-2 h-2 fill-gray-500 rotate-90'
              "
            />
          </div>
        </template>
        <template #dropdown-menu>
          <div class="p-2">
            <div
              v-if="currentTask.isPrivate"
              class="
                flex-center
                space-x-2
                px-2
                py-1
                hover:bg-current-50
                cursor-pointer
                dropdown-item
                rounded
              "
              @click="onMakePublic"
            >
              <span class="text-sm text-current">
                {{ $t('TASK_DETAIL_LABEL_MAKE_PUBLIC') }}
              </span>
              <SynIcon
                name="global"
                class="fill-current"
                custom-class="h-3 w-3"
              />
            </div>
            <div
              v-else
              class="
                flex-center
                space-x-2
                px-2
                py-1
                hover:bg-current-50
                cursor-pointer
                dropdown-item
                rounded
              "
              @click="onMakePrivate"
            >
              <span class="text-sm text-gray-500">
                {{ $t('TASK_DETAIL_LABEL_MAKE_PRIVATE') }}
              </span>
              <SynIcon
                name="private"
                class="fill-gray-500"
                custom-class="h-3 w-3"
              />
            </div>
          </div>
        </template>
      </VigDropdown>
    </div>
  </div>
</template>
