<script setup lang="ts">
import {
  computed,
  onMounted,
  watch,
  // computed
} from 'vue';
import Chart from 'chart.js/auto';
import { useDocumentVisibility } from '@vueuse/core';
// import appStore from '@/store/app';

const props = withDefaults(
  defineProps<{
    datasets: any[];
    labelsData: any[];
    options?: any;
    customClass?: string;
  }>(),
  {
    customClass: 'w-full',
  }
);

const visibility = useDocumentVisibility();
watch(
  () => visibility.value,
  () => {
    if (verticalBarChart && typeof verticalBarChart?.update == 'function')
      verticalBarChart?.update();
  }
);

const lineChartId = `line-chart-id-${Date.now()}`;
let verticalBarChart: Chart;
// const _appStore = appStore();
// const language = computed(() => _appStore.language);

const initData = () => {
  const data = {
    labels: props.labelsData,
    datasets: props.datasets,
  };

  const config = {
    type: 'line',
    data: data,
    options: {
      locale: 'de',
      maintainAspectRatio: false,
      scales: {
        y: {
          // stacked: true,
          // grid: {
          //   display: true,
          //   color: '#f1f1f1',
          // },
          ticks: {
            callback: function (value, index) {
              return index % 2 == 0 ? value : '';
            },
          },
          min: 0,
        },
        // x: {
        //   grid: {
        //     display: true,
        //     color: '#f1f1f1',
        //   },
        // },
      },
      plugins: {
        legend: { display: false },
      },
      ...props.options,
    },
  };

  const ctx = document.getElementById(lineChartId) as HTMLCanvasElement;

  if (!ctx) return;

  verticalBarChart = new Chart(ctx, config);
};

onMounted(() => {
  initData();
});

watch(
  () => props.datasets,
  () => {
    verticalBarChart.data.datasets = props.datasets;
    verticalBarChart?.update();
  },
  { deep: true }
);

const hasData = computed<boolean>(() => {
  return props.datasets?.some((dataset) => {
    return dataset?.data?.filter((o) => o).length > 0;
  });
});
</script>
<template>
  <div class="h-full w-full space-x-4">
    <div class="relative h-full" :class="customClass">
      <canvas v-show="hasData" :id="lineChartId" class="w-full"></canvas>

      <div v-if="!hasData" class="w-full h-full flex-center">
        <VigImage
          path="https://d1tvqetrcurhzb.cloudfront.net/web/charts/line-chart.png"
          object-fit="contain"
        />
      </div>
    </div>
  </div>
</template>
