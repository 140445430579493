<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import DomainDetail from '@/ui/modules/domains/domain/DomainDetail.vue';
import domainStore from '@/store/scope';
import ProjectDetail from '@/ui/modules/domains/projects/ProjectDetail.vue';
import DomainTree from '@/ui/modules/domains/component/DomainTree.vue';
import DomainCreateModal from '@/ui/modules/domains/domain/DomainCreateModal.vue';
import AlertModal from '@/ui/components/modals/AlertModal.vue';

const props = withDefaults(
  defineProps<{
    domainId: any;
    defaultTab?: 'INFORMATION' | 'MEMBERS' | 'GROUPS';
    editModeDefault?: boolean;
    createProjectModeDefault?: boolean;
    removeModeDefault?: boolean;
  }>(),
  {
    defaultTab: 'INFORMATION',
    createProjectModeDefault: false,
  }
);
const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onDeleteSuccess'): void;
  (e: 'onSaveSuccess'): void;
}>();
const _domainStore = domainStore();

const showAlertSystemDomain = ref(false);
const domainByIds = computed(() => _domainStore.domainByIds);
const domainDetail = computed(() => domainByIds.value[props.domainId]);

enum ViewModeEnum {
  Domain = 'domain',
  Project = 'project',
  SubProject = 'sub_project',
}

const viewMode = ref(ViewModeEnum.Domain as ViewModeEnum);
const itemActiveKey = ref(`domain_${props.domainId}`);
const currentProjectId = ref(0);
const currentParentId = ref(0);

const projectByIds = computed(() => _domainStore.projectByIds);
const currentProjectDetail = computed(() => {
  if (!currentProjectId.value) return {};
  return projectByIds.value[currentProjectId.value];
});

onMounted(() => {
  if (props.createProjectModeDefault) onOpenAddNewProject();
});

const onClose = () => {
  emit('onClose');
};

const changeViewMode = (key, id, parentId?) => {
  console.log('🚀 Tictop ~ key, id, parentId:', key, id, parentId);
  itemActiveKey.value = `${key}_${id}`;
  viewMode.value = key;
  currentProjectId.value = id;
  currentParentId.value = parentId;
  isAddNewProject.value = false;
};

const isAddNewProject = ref(false);

const onOpenAddNewProject = () => {
  if (domainDetail.value.system) {
    showAlertSystemDomain.value = true;
    return;
  }
  isAddNewProject.value = true;
};
const onOpenAddNewSubProject = (parentId) => {
  if (domainDetail.value.system) {
    showAlertSystemDomain.value = true;
    return;
  }
  currentParentId.value = parentId;
  isAddNewProject.value = true;
};

const onCreateProjectSuccess = (newProjectId) => {
  changeViewMode(
    currentParentId.value ? ViewModeEnum.SubProject : ViewModeEnum.Project,
    newProjectId,
    currentParentId.value
  );

  isAddNewProject.value = false;
};
const onDeleteProjectSuccess = () => {
  if (currentParentId.value) {
    currentProjectId.value = currentParentId.value;
    currentParentId.value = 0;
  } else changeViewMode(ViewModeEnum.Domain, null, null);
};

// const isCreatingProject = computed(
//   () => isAddNewProject.value && !currentParentId.value
// );
// const isCreatingSubProject = computed(
//   () => isAddNewProject.value && currentParentId.value
// );

const onCloseAddNew = () => {
  isAddNewProject.value = false;
  currentParentId.value = 0;
};
</script>
<template>
  <SynModal
    container-class="w-1/2"
    container-style="min-width: 64rem; max-width: 1200px; min-height: 44rem;
      max-height: 90%;"
    :confirm-text="$t('COMMON_LABEL_EDIT')"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    is-hidden-footer
    :disable-click-outside="false"
    @cancel="onClose"
  >
    <template #header>
      <div class="flex items-center space-x-4">
        <span>
          {{ $t('TASK_CREATE_FORM_DOMAIN') }}
        </span>

        <div class="flex-1 flex-center space-x-2 px-4">
          <span class="font-normal">
            {{ $t('DOMAIN_LABEL_YOU_ARE_ON') || 'You are on' }}
          </span>
          <span
            v-if="viewMode == ViewModeEnum.Domain"
            class="font-normal text-current"
          >
            {{ domainDetail?.name }}
          </span>
          <span v-else class="font-normal text-current">
            {{ currentProjectDetail?.name }}
          </span>
        </div>
      </div>
    </template>

    <template #body>
      <div class="flex-1 h-full flex divide-x overflow-hidden">
        <div class="w-1/3 min-h-0 overflow-y-auto small-scrollbar">
          <DomainTree
            :domain-detail="domainByIds[domainId]"
            :item-active-key="itemActiveKey"
            is-show-children-default
            is-show-all
            @change-item-active="changeViewMode"
            @on-click-add-new-project="onOpenAddNewProject"
            @on-click-add-new-sub-project="onOpenAddNewSubProject"
          />
        </div>
        <DomainDetail
          v-if="viewMode === ViewModeEnum.Domain"
          :key="domainId"
          :domain-id="domainId"
          :edit-mode-default="editModeDefault"
          :remove-mode-default="removeModeDefault"
          :default-tab="defaultTab"
          @on-close="onClose"
          @on-delete-success="$emit('onDeleteSuccess')"
          @on-save-success="$emit('onSaveSuccess')"
        />
        <ProjectDetail
          v-else
          :key="currentProjectId"
          :domain-id="domainId"
          :project-id="currentProjectId"
          :parent-id="currentParentId"
          @on-close="onClose"
          @on-delete-success="onDeleteProjectSuccess"
        />
      </div>
    </template>
  </SynModal>
  <DomainCreateModal
    v-if="isAddNewProject"
    :domain-id="domainId"
    :parent-id="currentParentId"
    @on-close="onCloseAddNew"
    @on-create-success="onCreateProjectSuccess"
  />
  <alert-modal
    v-if="showAlertSystemDomain"
    :title-text="$t('DOMAIN_LABEL_EDIT_SYSTEM_DOMAIN_TITLE')"
    :sub-title-text="
      $t('DOMAIN_LABEL_REMOVE_SYSTEM_DOMAIN_CONTENT', {
        domainName: domainDetail.name,
      })
    "
    :is-html-text="true"
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="showAlertSystemDomain = false"
  />
</template>

<style>
.outline-red {
  outline: 1px solid red;
}

.domain-min-height {
  min-height: 82px;
}
</style>
