<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M473.655,88.275H38.345C17.167,88.275,0,105.442,0,126.62V385.38  c0,21.177,17.167,38.345,38.345,38.345h435.31c21.177,0,38.345-17.167,38.345-38.345V126.62  C512,105.442,494.833,88.275,473.655,88.275z"
        fill="#f5f5f5"
        data-original="#f5f5f5"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M305.009,182.531c-40.563-27.042-95.35-15.986-122.374,24.506  c-13.555,20.211-8.045,47.674,12.235,61.195c20.265,13.521,47.64,8.03,61.161-12.252c13.521-20.281,40.914-25.704,61.178-12.254  c20.298,13.521,25.757,40.984,12.217,61.195C356.468,264.362,345.537,209.574,305.009,182.531"
        fill="#ff4b55"
        data-original="#ff4b55"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M182.634,207.038c-13.555,20.211-8.045,47.674,12.235,61.195c20.265,13.521,47.64,8.03,61.161-12.252  c13.521-20.281,40.914-25.704,61.178-12.254c20.298,13.521,25.757,40.984,12.217,61.195  c-27.006,40.632-81.775,51.549-122.338,24.507C166.561,302.39,155.593,247.602,182.634,207.038"
        fill="#41479b"
        data-original="#41479b"
        class=""
      ></path>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          style=""
          d="M349.92,149.189l16.035,24.102c1.347,2.025,0.802,4.759-1.219,6.112l-4.066,2.723   c-2.029,1.358-4.775,0.812-6.129-1.22l-16.055-24.096c-1.351-2.027-0.803-4.766,1.223-6.119l4.086-2.728   C345.825,146.608,348.568,147.158,349.92,149.189z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M374.66,186.35l16.087,24.087c1.358,2.034,0.804,4.786-1.237,6.134l-4.084,2.699   c-2.026,1.34-4.754,0.789-6.103-1.23l-16.078-24.062c-1.354-2.026-0.81-4.767,1.217-6.122l4.075-2.724   C370.563,183.777,373.305,184.322,374.66,186.35z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M367.089,137.731l40.829,61.273c1.352,2.028,0.803,4.768-1.225,6.12l-4.102,2.734   c-2.028,1.352-4.769,0.804-6.121-1.224l-40.843-61.269c-1.353-2.029-0.803-4.771,1.227-6.123l4.115-2.739   C362.998,135.156,365.737,135.703,367.089,137.731z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M384.211,126.291l16.07,24.149c1.354,2.034,0.798,4.78-1.241,6.127l-4.087,2.7   c-2.028,1.34-4.757,0.789-6.105-1.234l-16.082-24.117c-1.353-2.028-0.804-4.769,1.224-6.122l4.099-2.732   C380.117,123.71,382.859,124.259,384.211,126.291z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M408.967,163.531l16.045,24.099c1.35,2.026,0.803,4.762-1.22,6.115l-4.075,2.724   c-2.029,1.356-4.775,0.809-6.127-1.223l-16.045-24.099c-1.349-2.026-0.803-4.762,1.22-6.115l4.075-2.724   C404.87,160.951,407.614,161.497,408.967,163.531z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M132.721,293.982l40.824,61.207c1.352,2.027,0.806,4.767-1.222,6.12l-4.088,2.73   c-2.028,1.354-4.769,0.807-6.123-1.222l-40.824-61.207c-1.353-2.027-0.806-4.767,1.222-6.12l4.089-2.73   C128.626,291.406,131.368,291.953,132.721,293.982z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M115.582,305.43l16.028,24.041c1.351,2.026,0.806,4.761-1.217,6.116l-4.066,2.722   c-2.027,1.357-4.771,0.812-6.126-1.217l-16.047-24.035c-1.354-2.027-0.808-4.768,1.22-6.122l4.086-2.728   C111.487,302.854,114.229,303.401,115.582,305.43z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M140.351,342.604l16.046,24.102c1.35,2.026,0.803,4.763-1.222,6.115l-4.078,2.727   c-2.029,1.356-4.772,0.809-6.126-1.222l-16.056-24.097c-1.351-2.027-0.804-4.766,1.222-6.118l4.088-2.73   C136.255,340.024,138.998,340.573,140.351,342.604z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M98.442,316.875l40.798,61.21c1.351,2.026,0.804,4.764-1.219,6.118l-4.077,2.726   c-2.028,1.356-4.771,0.809-6.125-1.22l-40.822-61.202c-1.353-2.028-0.804-4.769,1.224-6.122l4.102-2.734   C94.349,314.299,97.09,314.847,98.442,316.875z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M121.294,210.441l40.818-61.257c1.353-2.03,4.095-2.578,6.124-1.224l4.087,2.729   c2.027,1.353,2.573,4.093,1.222,6.12l-40.834,61.223c-1.35,2.023-4.081,2.573-6.108,1.231l-4.071-2.695   C120.494,215.22,119.94,212.473,121.294,210.441z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M104.147,199.008l40.825-61.269c1.353-2.03,4.097-2.578,6.126-1.222l4.077,2.726   c2.024,1.353,2.57,4.09,1.22,6.116l-40.814,61.272c-1.353,2.03-4.095,2.579-6.124,1.224l-4.088-2.729   C103.343,203.774,102.796,201.035,104.147,199.008z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M86.99,187.624l40.829-61.33c1.353-2.031,4.098-2.58,6.127-1.224l4.077,2.726   c2.023,1.353,2.57,4.087,1.222,6.114l-40.804,61.339c-1.351,2.03-4.094,2.581-6.123,1.228l-4.1-2.734   C86.189,192.391,85.641,189.652,86.99,187.624z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M338.493,355.188l16.047-24.035c1.355-2.029,4.099-2.574,6.126-1.217l4.066,2.722   c2.023,1.354,2.567,4.09,1.216,6.116l-16.028,24.04c-1.353,2.029-4.095,2.577-6.123,1.223l-4.086-2.728   C337.685,359.956,337.139,357.216,338.493,355.188z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M363.243,318.141l16.073-24.154c1.351-2.03,4.094-2.58,6.123-1.227l4.096,2.73   c2.029,1.353,2.577,4.096,1.223,6.124l-16.107,24.116c-1.351,2.022-4.082,2.571-6.109,1.227l-4.062-2.692   C362.445,322.915,361.891,320.172,363.243,318.141z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M355.626,366.698l16.057-24.098c1.352-2.029,4.093-2.578,6.122-1.225l4.104,2.737   c2.027,1.352,2.575,4.09,1.225,6.119l-16.047,24.1c-1.351,2.029-4.09,2.579-6.12,1.228l-4.115-2.739   C354.824,371.469,354.274,368.727,355.626,366.698z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M380.403,329.463l16.066-24.042c1.354-2.025,4.092-2.571,6.119-1.22l4.102,2.734   c2.03,1.353,2.577,4.096,1.221,6.125l-16.066,24.043c-1.353,2.025-4.092,2.571-6.118,1.22l-4.103-2.734   C379.594,334.235,379.047,331.492,380.403,329.463z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M372.771,378.081l16.075-24.056c1.35-2.019,4.077-2.569,6.103-1.23l4.086,2.7   c2.04,1.348,2.595,4.097,1.239,6.131l-16.063,24.088c-1.352,2.028-4.093,2.575-6.121,1.224l-4.098-2.732   C371.962,382.852,371.415,380.109,372.771,378.081z"
          fill="#464655"
          data-original="#464655"
        ></path>
        <path
          style=""
          d="M397.554,340.969l16.035-24.085c1.353-2.031,4.098-2.58,6.127-1.223l4.072,2.722   c2.025,1.354,2.57,4.093,1.218,6.119l-16.049,24.053c-1.349,2.024-4.083,2.573-6.11,1.229l-4.06-2.69   C396.754,345.745,396.201,343,397.554,340.969z"
          fill="#464655"
          data-original="#464655"
        ></path>
      </g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
