<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <circle
            cx="256"
            cy="114.526"
            r="114.526"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M256,256c-111.619,0-202.105,90.487-202.105,202.105c0,29.765,24.13,53.895,53.895,53.895h296.421    c29.765,0,53.895-24.13,53.895-53.895C458.105,346.487,367.619,256,256,256z"
            data-original="#000000"
            style=""
            class=""
          />
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
