<script setup lang="ts">
import { ref } from 'vue';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';

const isNewChat = ref<boolean>();
</script>
<template>
  <VigButton
    light
    rounded="rounded-full"
    padding="p-0"
    class="w-12 h-12 shadow-xl"
    @click="isNewChat = true"
  >
    <SynIcon name="ChatAdd" custom-class="w-6 h-6" />
  </VigButton>

  <ModalNewChat
    v-if="isNewChat"
    @on-close="isNewChat = false"
    @on-conversation-click="isNewChat = false"
  />
</template>
