<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g fill-rule="evenodd" clip-rule="evenodd">
        <path
          d="M11.402 24.402a1.5 1.5 0 0 1 2.121 0L16 26.879l2.477-2.477a1.5 1.5 0 0 1 2.121 2.121l-3.537 3.538a1.5 1.5 0 0 1-2.122 0l-3.537-3.538a1.5 1.5 0 0 1 0-2.121z"
        ></path>
        <path
          d="M16 18.5a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-3 0v-9a1.5 1.5 0 0 1 1.5-1.5zM14.94 1.94a1.5 1.5 0 0 1 2.12 0l3.538 3.537a1.5 1.5 0 0 1-2.121 2.121L16 5.121l-2.477 2.477a1.5 1.5 0 0 1-2.121-2.121z"
        ></path>
        <path
          d="M16 1.5A1.5 1.5 0 0 1 17.5 3v9a1.5 1.5 0 0 1-3 0V3A1.5 1.5 0 0 1 16 1.5zM7.598 11.402a1.5 1.5 0 0 1 0 2.121L5.121 16l2.477 2.477a1.5 1.5 0 1 1-2.121 2.121l-3.538-3.537a1.5 1.5 0 0 1 0-2.122l3.538-3.537a1.5 1.5 0 0 1 2.121 0z"
        ></path>
        <path
          d="M1.5 16A1.5 1.5 0 0 1 3 14.5h9a1.5 1.5 0 0 1 0 3H3A1.5 1.5 0 0 1 1.5 16zM24.402 11.402a1.5 1.5 0 0 1 2.121 0l3.538 3.537a1.5 1.5 0 0 1 0 2.122l-3.538 3.537a1.5 1.5 0 0 1-2.121-2.121L26.879 16l-2.477-2.477a1.5 1.5 0 0 1 0-2.121z"
        ></path>
        <path
          d="M18.5 16a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 0 3h-9a1.5 1.5 0 0 1-1.5-1.5z"
        ></path>
      </g>
    </g>
  </svg>
</template>
