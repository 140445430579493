<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { TaskLife } from '@/domain/enums/taskEnum';

const props = defineProps<{
  task: any;
  readonly: boolean;
}>();

const emits = defineEmits(['onPlanningChange', 'onStateChange']);

const doItToday = ref<boolean>();
const finishItToday = ref<boolean>();

watch(
  () => props.task,
  (task) => {
    doItToday.value = task?.taskSchedule?.doItToday;
    finishItToday.value = task?.taskSchedule?.finishItToday;
  }
);

onMounted(() => {
  doItToday.value = props.task?.taskSchedule?.doItToday;
  finishItToday.value = props.task?.taskSchedule?.finishItToday;
});

const onDoItTodayChange = () => {
  const planType = doItToday.value ? 1 : 0;

  emits('onPlanningChange', planType);

  _verifyToChangeState();
};

const onFinishItTodayChange = () => {
  const planType = finishItToday.value ? 2 : 1;

  emits('onPlanningChange', planType);

  _verifyToChangeState();
};

const _verifyToChangeState = () => {
  if (
    props.task.taskLife !== TaskLife.Todo &&
    props.task.taskLife !== TaskLife.InProcess
  ) {
    emits('onStateChange', TaskLife.Todo);
  }
};
</script>

<template>
  <div class="flex flex-wrap gap-x-6 gap-y-3">
    <AtomSwitch
      v-model="doItToday"
      :disabled="readonly"
      :label="$t('TASK_CREATE_FORM_DOING_TODAY') || 'Do today'"
      class="text-current-800 text-xs"
      @change="onDoItTodayChange"
    />

    <AtomSwitch
      v-model="finishItToday"
      :disabled="readonly"
      :label="$t('TASK_CREATE_FORM_FINISH_TODAY') || 'Finish today'"
      class="text-current-800 text-xs"
      @change="onFinishItTodayChange"
    />
  </div>
</template>
