import { chain, pullAt } from 'lodash';

export const generateRandomString = (length) => {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    let result = '';
    let counter = 0;

    while (counter < length) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
    }

    return result;
};

export const arrToObj = (
    arr: any,
    key: string | ((item: any) => any) | null = null,
    value: string | ((item: any) => any) | null = null
) => {
    // @ts-ignore
    return chain(arr).keyBy(key).mapValues(value).value();
};

export const getFullName = (obj: any) => {
    return (
        obj?.name ||
        obj.fullName ||
        obj.fullname ||
        `${obj?.lastName} ${obj?.firstName}`
    );
};

export const isSameId = (idFirst: any, idSecond: any) => {
    return idFirst && idSecond && `${idFirst}` === `${idSecond}`;
};

export const getShortName = (name: string) => {
    return name
        ?.split(' ')
        .filter((s) => !!s)
        .pop();
};

export const base64ToBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: any[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

export const moveItemInArray = (
    array: any[],
    fromIndex: number,
    toIndex: number
) => {
    if (!array) return array;

    // Pull out the element at fromIndex
    const [element] = pullAt(array, fromIndex);

    // Insert the element at toIndex
    array.splice(toIndex, 0, element);

    return array;
};

export const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
    );
};
