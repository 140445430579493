<script setup lang="ts">
import { computed, ref, watch } from 'vue';
// import myProfileStore from '@/store/auth/my-profile';
import {
  getConfigScheduleColor,
  getBgColor,
  formatTaskCode,
  getDetailTaskUrl,
} from '@/ui/hooks/taskHook';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import { taskStates } from '@/ui/hooks/taskHook';
import TaskState from '@/ui/modules/task/components/TaskState.vue';
import taskDrawerStore from '@/store/task/drawer';
import UpdateDeadline from '@/ui/modules/task/detail/UpdateDeadline.vue';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import ChangeGroupAndUser from '../general/ChangeGroupAndUser.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import UpdateDomainModal from '@/ui/modules/task/detail/UpdateDomainModal.vue';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';
import UpdateDomainDropdown from '@/ui/modules/task/detail/UpdateDomainDropdown.vue';
// import { ScheduleDayOptions } from '@/domain/enums/taskEnum';
import { ICustomTaskColumn } from '@/application/interfaces/tasks/task-interfaces';
import bindingInterfaceTaskComposables from '@/ui/composables/task/binding-interface-task';
import commonActionsTaskComposables from '@/ui/composables/task/common-actions-task';
// import TaskCard from '@/ui/modules/overview/TaskCard.vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import taskListStore from '@/store/task/task-list-store';
import {
  ScheduleDayOptions,
  TaskLife,
  TaskListTab,
} from '@/domain/enums/taskEnum';
import taskCustomConfigStore from '@/store/task/task-custom-config';
import TaskDetailAttachmentInfo from '@/ui/modules/task/components/task-list/TaskDetailAttachmentInfo.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import {
  EFilterTaskDefault,
  TASK_PIN_BY_CODE,
} from '@/application/constants/task.const';
import GroupByAction from '@/ui/components/group/GroupByAction.vue';
import SetPrivateTask from '@/ui/modules/task/detail/action-on-task/SetPrivateTask.vue';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import myProfileStore from '@/store/auth/my-profile';
import TaskWorkflowShortInfo from '@/ui/modules/task/table/TaskWorkflowShortInfo.vue';
import {
  TaskApprovalStatus,
  EActionOnTask,
} from '@/application/types/task/task.types';
import ActionOnTask from '@/ui/modules/task/detail/action-on-task/ActionOnTask.vue';
import settingStore from '@/store/setting';
import TaskSharingModal from '@/ui/modules/task/sharing/modal-task-sharing/TaskSharingModal.vue';
import TaskSnapshotsModal from '@/ui/modules/task/sharing/modal-task-shapshots/TaskSnapshotsModal.vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import dayjs from 'dayjs';
import TaskDeadlineBlockingShortInfo from '@/ui/modules/task/deadline-blocking/TaskDeadlineBlockingShortInfo.vue';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';
import TaskApprovalModal from '@/ui/modules/task/approval/TaskApprovalModal.vue';
import WorkflowStepNextSteps from '@/ui/modules/workflow/workflow-step/WorkflowStepNextSteps.vue';
import SynTd from '@/ui/common/molecules/SynTableCustom/SynTd.vue';

const props = withDefaults(
  defineProps<{
    task: ITaskDetailClass;
    type: 'member' | 'group' | 'domain' | 'my_assign';
    isLoadingMoreActionByTaskId?: number;
    isSelecting: boolean;
    isSharedList?: boolean;
  }>(),
  {
    type: 'member',
    isLoadingMoreActionByTaskId: 0,
  }
);

const emit = defineEmits([
  'onOpenTaskDetail',
  'finish',
  'clone',
  'delete',
  'edit',
  'copyLink',
  'onOpenAttachmentDetail',
  'onCloseAttachment',
  'shareToChat',
  'goToTask',
]);

const _settingStore = settingStore();
const _taskListStore = taskListStore();
const _taskDrawerStore = taskDrawerStore();
const _taskCustomConfigStore = taskCustomConfigStore();
const taskColumnByCode = computed<ICustomTaskColumn[]>(
  () => _taskCustomConfigStore.taskColumnByCode
);
const chatEnabled = computed(() => _settingStore.chatEnabled);
const myTimezone = computed(() => myProfileStore().timezone);

const isGroup = computed(() => props.type == 'group');
const isMyAssign = computed(() => props.type == 'my_assign');
const isDomain = computed(() => props.type == 'domain');
const isRelatedTab = computed(
  () => taskTabViewStore().currentTabId == EFilterTaskDefault.MY_RELATED
);

const isPinnedTaskTab = computed(
  () => taskTabViewStore().currentTabId == EFilterTaskDefault.PINNED
);
// const myTimezone = computed(() => myProfileStore().getTimezone);
const currentTaskId = computed(() => _taskDrawerStore.currentTabId);
const overdueTime = computed<string>(() => {
  if (
    (props.task?.scheduleByHour &&
      props.task?.taskSchedule?.scheduleOptionKey ==
        ScheduleDayOptions.OnDay) ||
    (props.task?.taskSchedule?.overdue && props.task?.taskSchedule?.overdue > 0)
  )
    return dayjs(props.task?.scheduleTime).fromNow(true);
  return '';
});
const isPersonalModel = computed<boolean>(
  () => permissionStore().organizationModel === 'PERSONAL'
);

const {
  isTaskHasJustCreate,
  isEditable,
  hasPermission,
  getConfigScheduleName,
} = bindingInterfaceTaskComposables(props.task);

const {
  taskForClone,
  isUpdatingTask,
  isOpenRequestConfirmModal,
  viewModeProvideEvidence,
  workflowData,
  selectNextStepDropdownRef,
  onCloneTask,
  onSaveCloneTask,
  onChangeTaskState,
  onChangeUrgency,
  onDelete,
  onSaveDomain,
  onSaveDeadline,
  onUpdateStartTime,
  changeDeadlineTime,
  onChangeAssign,
  onCloseUpdateDomainModal,
  onSaveDomainAfterChangeGroup,
  onDoItToday,
  onFinishItToday,
  onTogglePin,
  updateTaskDetail,
  onToggleLockDeadline,
  onChangeApprovalStatus,
  onNextStepInWorkflow,
} = commonActionsTaskComposables({
  currentTask: props?.task,
  currentTaskId: props?.task?.id,
});

watch(
  () => props.task,
  (newTask) => {
    updateTaskDetail(newTask);
  },
  { deep: true }
);
const onClickTaskDetail = () => {
  emit('onOpenTaskDetail');
};

const isOpenUpdateDeadline = ref(false);
const scheduleRef = ref(null as any);

const onCancelDeadline = () => {
  isOpenUpdateDeadline.value = false;
  scheduleRef.value?.onForceClose();
};

const isOpenChangeAssign = ref(false);
const changeAssignRef = ref(null as any);
const onCancelChangeAssign = () => {
  changeAssignRef.value?.onForceClose();
};

const isOpenUpdateDomainModal = ref(false);
const isOpenChangeState = ref(false);

const isSelected = computed<boolean>(() =>
  _taskListStore.selectedTaskIds.includes(props.task?.id)
);
const activeTab = computed<TaskListTab>(() => _taskListStore.activeTab);
const onChoose = (event) => {
  event.stopPropagation();
  _taskListStore.onChooseTask(props.task?.id, !isSelected.value);
};
const onChangeDeadline = (data) => {
  onSaveDeadline(data);

  onCancelDeadline();
};

const taskStateRef = ref<any>(null);

const onToggleFinish = (value) => {
  if (value) {
    onChangeTaskState(TaskLife.Finished);
  } else {
    taskStateRef.value?.forceOpen();
  }
};

const currentPin = computed<any>(() => {
  if (!props.task?.pinnedColor) return TASK_PIN_BY_CODE.UN_PIN;
  return TASK_PIN_BY_CODE[props.task?.pinnedColor];
});

const hasAttachment = computed<boolean>(() => {
  return (
    props.task.attachmentCount > 0 ||
    props.task.noteCount > 0 ||
    (props.task.groupId && !isGroup.value) ||
    props.task?.description ||
    props.task?.taskTodoTotal > 0 ||
    props.task?.workFlowTaskId ||
    props.task?.totalSubTask > 0
  );
});

const onChangeDeadlineTime = ({ dateTime, timezone }) => {
  changeDeadlineTime({
    scheduleTime: dateTime,
    scheduleByHour: true,
    scheduleTimezone: timezone,
  });
};

const onRemoveScheduleTime = async ({ dateTime }) => {
  changeDeadlineTime({
    scheduleTime: dateTime,
    scheduleByHour: false,
    scheduleTimezone: '',
  });
};

const isOpenTaskSharing = ref();
const isOpenTaskSnapshots = ref();

const onSelectActionOnTask = (actionKey) => {
  switch (actionKey) {
    case EActionOnTask.FINISH:
      onChangeTaskState(TaskLife.Finished);
      break;
    case EActionOnTask.DISCUSS:
      emit('shareToChat');
      break;
    case EActionOnTask.CLONE:
      onCloneTask();
      break;
    case EActionOnTask.RELOAD:
      break;
    case EActionOnTask.SET_REPEAT:
      break;
    case EActionOnTask.COPY_LINK:
      emit('copyLink');
      break;
    case EActionOnTask.SHARE:
      isOpenTaskSharing.value = true;
      break;
    case EActionOnTask.SNAPSHOT:
      isOpenTaskSnapshots.value = true;
      break;
    case EActionOnTask.WORKFLOW:
      break;
    case EActionOnTask.APPROVAL:
      break;
    case EActionOnTask.REMINDER_TASK:
      break;
    case EActionOnTask.PIN:
      onTogglePin(
        !props.task?.pinnedColor || props.task?.pinnedColor == 'UN_PIN'
          ? TASK_PIN_BY_CODE.PIN_GRAY
          : ''
      );
      break;
    case EActionOnTask.EXPAND:
      break;
    case EActionOnTask.VIEW_DETAIL:
      onClickTaskDetail();
      break;

    default:
      break;
  }
};

const goToTask = (task) => {
  emit('goToTask', task);
};
const canChangeDeadline = computed<boolean>(() => {
  if (!props.task?.scheduleTimeLocked) return true;

  const allSystemConfigs = permissionStore().allSystemConfigs;
  const allAllowedPositions =
    allSystemConfigs[
      systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_BY
    ]?.split(';');
  if (
    !allAllowedPositions.some((key) => {
      switch (key) {
        case 'OWNER_ADMIN':
          if (myProfileStore().isAdmin || myProfileStore().isOwner) return true;
          break;
        case 'CREATOR':
          if (myProfileStore().myProfile?.id == props.task?.creatorId)
            return true;
          break;
        case 'ASSIGNEE':
          if (myProfileStore().myProfile?.id == props.task?.assigneeId)
            return true;
          break;

        default:
          break;
      }

      return false;
    })
  ) {
    return false;
  }
  return true;
});
</script>

<template>
  <!-- taskLife -->
  <SynTd
    class="relative whitespace-wrap h-full items-center"
    :class="`${getBgColor(task)}`"
    style="padding: 0"
  >
    <span
      v-if="currentTaskId == task?.id"
      :class="isOpenTaskDrawer ? 'border-current' : 'border-gray-300'"
      class="absolute left-0 top-0 h-full border-2"
    />
    <span
      v-if="task?.pinnedColor && task?.pinnedColor !== 'UN_PIN'"
      class="absolute -left-1 -top-2 -rotate-90"
    >
      <SynIcon
        name="pin"
        custom-class="w-5 h-5"
        is-active
        :style="{
          fill: currentPin?.color,
        }"
      />
    </span>
    <div class="flex-center">
      <div class="m-auto h-10 flex-center flex-col">
        <div
          v-if="isEditable && activeTab !== TaskListTab.WaitingApprovalTasks"
          class="group h-8 w-8 flex-center rounded-full cursor-pointer"
          :class="{
            'hover-to-show__children': !isSelecting,
          }"
          @click.stop="onChoose"
        >
          <div
            class="
              cursor-pointer
              h-4
              w-4
              flex-center
              rounded
              border border-current-300
              group-hover:border-2
            "
            :class="{
              'bg-current-300': isSelected,
            }"
          >
            <SynIcon v-if="isSelected" name="check" class="text-white" />
          </div>
        </div>

        <span
          class="text-xs text-gray-800"
          :class="{
            'hover-to-hidden__children pl-1':
              !isSelecting &&
              activeTab !== TaskListTab.WaitingApprovalTasks &&
              isEditable,
          }"
        >
          {{ formatTaskCode(task.code) }}
        </span>
      </div>
    </div>
  </SynTd>
  <!-- taskLife -->
  <SynTd
    v-if="taskColumnByCode['STATUS']?.visible"
    class="relative whitespace-wrap h-full"
    :class="`${getBgColor(task)}`"
    style="padding: 0"
  >
    <!-- <span
      v-if="task?.taskLife"
      class="p-1 rounded-full absolute top-2 left-2"
      :class="task?.taskLife ? `bg-${taskStates[task?.taskLife].colorKey}` : ''"
    ></span> -->

    <div
      class="flex-center w-full"
      :class="
        task?.taskLife <= 0
          ? 'h-12 justify-center hover-to-show__parent'
          : 'h-12'
      "
    >
      <div class="flex-1 flex flex-col">
        <span
          v-if="
            task?.approvalEnabled &&
            task?.approvalStatus &&
            task?.approvalStatus == TaskApprovalStatus.WAITING
          "
          class="text-xs pl-2"
        >
          {{ $t('TASK_LABEL_STAGE_WAITING_FOR_APPROVING') }}
        </span>
        <TaskState
          v-else
          ref="taskStateRef"
          class="flex-1 w-full"
          :task-life="task?.taskLife"
          :readonly="!isEditable"
          :has-permission="hasPermission"
          :approval-enabled="task?.approvalEnabled"
          @change-state="onChangeTaskState"
          @open-change-state="(value) => (isOpenChangeState = value)"
          @delete="onDelete"
        >
          <template #dropdown-toggle>
            <div
              :title="$t(taskStates[task.taskLife]['nameCode'])"
              class="
                w-full
                flex
                items-center
                cursor-pointer
                rounded
                border border-current-200 border-opacity-0
                space-x-1
                pl-2
                relative
              "
              :class="[
                taskStates[task.taskLife]['color'],
                isOpenChangeState ? 'border-opacity-100' : '',
                isEditable ? 'hover:border-opacity-100' : '',
              ]"
              style="max-width: 6rem"
            >
              <span class="text-xs py-1" style="white-space: nowrap">
                {{ $t(taskStates[task.taskLife]['nameCode']) }}
              </span>

              <div
                v-if="isEditable"
                class="
                  w-5
                  h-5
                  absolute
                  right-0
                  flex-center
                  rounded-full
                  bg-current-50
                "
                :class="`${
                  isOpenChangeState ? 'flex' : 'hover-to-show__children'
                }`"
              >
                <SynIcon
                  :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                  name="edit"
                  custom-class="w-3 h-3 fill-current"
                  is-active
                />
              </div>
            </div>
          </template>
        </TaskState>
        <span
          v-if="task?.taskSchedule?.doItToday"
          class="w-max text-xs pl-2"
          :class="`${getBgColor(task)}`"
        >
          {{
            $t(
              task?.taskSchedule?.finishItToday
                ? 'TASK_CREATE_FORM_FINISH_TODAY'
                : 'TASK_CREATE_FORM_DOING_TODAY'
            )
          }}
        </span>
      </div>
      <div v-if="hasPermission" class="h-full flex-center flex-col">
        <div
          v-if="
            isEditable &&
            (!task?.approvalEnabled ||
              !task?.approvalStatus ||
              task?.approvalStatus !== TaskApprovalStatus.WAITING)
          "
          :title="$t('TASK_UPDATE_MULTIPLE_SET_FINISHED') || 'Planned'"
          class="w-8 h-8 flex-center flex-col cursor-pointer rounded-full group"
          @click="
            onToggleFinish(task?.taskLife === TaskLife.Finished ? false : true)
          "
        >
          <div class="flex-1 py-1">
            <SynIcon
              name="set-finished"
              :custom-class="
                task?.taskLife === TaskLife.Finished
                  ? 'fill-green-500 w-4 h-4 group-hover:w-8 group-hover:fill-gray-500'
                  : 'fill-gray-500 w-4 h-4 group-hover:w-8 group-hover:fill-green-500'
              "
            />
          </div>
          <span
            v-if="hasAttachment"
            class="task-list-table__set-finished-padding"
            :class="task.groupId && !isGroup ? 'pb-2' : ''"
          >
          </span>

          <VigDropdown ref="selectNextStepDropdownRef">
            <template v-if="workflowData?.currentTaskStep" #dropdown-menu>
              <WorkflowStepNextSteps
                v-model="workflowData.workflowApprovalNextStepId"
                :next-steps="workflowData?.currentTaskStep?.nextSteps"
                :step-by-ids="workflowData?.stepByIds"
                view-mode="SELECT"
                @update:model-value="onNextStepInWorkflow"
              />
            </template>
          </VigDropdown>
        </div>
      </div>
    </div>
  </SynTd>

  <!-- taskName -->
  <SynTd class="max-w-md relative" style="padding: 0">
    <div
      class="absolute top-0 right-0.5 flex-center space-x-1"
      style="font-size: 0.6rem"
    >
      <span
        v-if="isTaskHasJustCreate(task)"
        class="bg-current text-white px-1.5 rounded-b-md z-10"
        >New</span
      >
    </div>

    <div class="flex-center w-full">
      <div
        class="
          flex-1
          px-2
          py-1
          overflow-hidden
          w-full
          h-full
          text-overflow-hidden
        "
        @click.stop.prevent="onClickTaskDetail"
      >
        <div class="flex flex-wrap flex-col break-words w-full">
          <section
            v-if="task?.parentId"
            class="
              w-1/2
              items-center
              inline-flex
              text-sm
              gap-2
              hover:underline
              cursor-pointer
            "
            @click.stop="
              goToTask({
                id: task?.parentId,
                name: task?.parentName,
                code: task?.parentCode,
              })
            "
          >
            <p class="inline text-gray-600 text-xs truncate">
              {{ task?.parentName }}
            </p>
            <SynIcon name="SortDownThin" custom-class="h-2 w-2 fill-gray-600" />
          </section>
          <a
            v-if="task?.workflowId || task?.workflowStepId"
            :href="getDetailTaskUrl(task.code, false)"
          >
            <TaskWorkflowShortInfo
              :workflow-id="task?.workflowId || ''"
              :workflow-step-id="task?.workflowStepId || ''"
            />
          </a>
          <a
            :href="getDetailTaskUrl(task.code, false)"
            class="w-full hover:text-current-500 cursor-pointer"
            :class="task?.workflowId || task?.workflowStepId ? 'pb-2' : 'py-2'"
          >
            <div v-vig-tooltip="task.name" class="flex items-center space-x-2">
              <span
                v-encode-html="{ text: task.name }"
                class="text-overflow-hidden-line relative"
              >
              </span>
              <div class="flex-center">
                <SetPrivateTask
                  v-if="!isGroup && !isPersonalModel"
                  :current-task="task"
                  is-only-show-private-icon
                />
                <SynIcon
                  v-if="task?.isRepeat"
                  name="recurrence2"
                  custom-class="h-4 w-4 fill-gray-500 mt-0.5"
                />
              </div>
            </div>
          </a>
          <div
            v-if="hasAttachment || task?.approvalEnabled"
            class="w-full"
            :class="task.groupId && !isGroup ? 'pb-2' : ''"
          >
            <TaskDetailAttachmentInfo
              :task="task"
              :total-files="task?.attachmentCount"
              :total-notes="task?.noteCount"
              :total-comments="task?.totalComment"
              :total-todo-lists="task?.taskTodoTotal"
              :total-sub-tasks="task?.totalSubTask"
              @on-open-attachment-detail="$emit('onOpenAttachmentDetail')"
              @on-close-attachment="$emit('onCloseAttachment')"
              @open-task-detail="$emit('onOpenTaskDetail')"
            >
              <template #suffix>
                <div
                  v-if="task.groupId && !isGroup"
                  class="w-full flex items-center text-gray-500"
                  style="width: 6rem"
                >
                  <!--                <GroupById-->
                  <!--                  :group-id="task.groupId"-->
                  <!--                  class="px-2 text-xs hover-to-show__parent"-->
                  <!--                  avatar-class="w-4 h-4"-->
                  <!--                />-->
                  <GroupByAction
                    :group-id="task.groupId"
                    avatar-class="h-4 w-4"
                    text-name-class="text-xs"
                  />
                </div>
              </template>
            </TaskDetailAttachmentInfo>
          </div>
        </div>
      </div>
    </div>
  </SynTd>

  <!-- TASK NAME PROJECT -->
  <SynTd
    v-if="taskColumnByCode['DOMAIN']?.visible"
    v-permission-model
    class="h-full p-0"
    style="padding: 0px !important"
  >
    <UpdateDomainDropdown
      v-if="isEditable"
      class="p-0 h-full flex-center"
      :task-detail="task"
      @on-save="onSaveDomain"
    >
      <DomainProjectById
        class="h-full"
        :domain-id="task.domainId"
        :project-id="task.projectId"
        :sub-project-id="task.subprojectId"
      />
    </UpdateDomainDropdown>
    <DomainProjectById
      v-else
      :domain-id="task.domainId"
      :project-id="task.projectId"
      :sub-project-id="task.subprojectId"
    />
  </SynTd>
  <SynTd v-if="isSharedList" class="h-full p-0" style="padding: 0px !important">
    <div class="flex">
      <SynListAvatar
        v-if="task?.shareList && task?.shareList?.length > 0"
        :users-id="[...new Set(task.shareList?.map((data) => data?.creatorId))]"
        type="USER"
        :max-length="5"
        custom-class="w-8 h-8"
      />
    </div>
  </SynTd>

  <!-- TASK PRIORITY -->
  <SynTd v-if="taskColumnByCode['URGENCY']?.visible">
    <div class="flex-center">
      <vig-dropdown v-if="isEditable">
        <template #dropdown-toggle>
          <div class="flex-center hover-to-show__parent w-4 h-4 cursor-pointer">
            <PriorityTask :urgency="task.urgency" is-hidden-label />
            <div class="w-8 h-8 flex-center cursor-pointer">
              <div
                class="
                  w-5
                  h-5
                  flex-center
                  rounded-full
                  bg-current-50
                  hover-to-show__children
                  absolute
                  -right-7
                "
              >
                <SynIcon
                  :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                  name="edit"
                  custom-class="w-3 h-3 fill-current"
                  is-active
                />
              </div>
            </div>
          </div>
        </template>
        <template #dropdown-menu>
          <div
            class="w-50 py-1 z-100 list-ul rounded cursor-pointer dropdown-item"
          >
            <div
              v-for="(item, indexU) in [4, 3, 2, 1]"
              :key="indexU"
              class="list-li flex text-left justify-start"
              @click="onChangeUrgency(item)"
            >
              <PriorityTask :urgency="item" />
            </div>
          </div>
        </template>
      </vig-dropdown>
      <PriorityTask v-else :urgency="task.urgency" is-hidden-label />
    </div>
  </SynTd>
  <!-- DO TODAY -->
  <SynTd v-if="taskColumnByCode['DO_TODAY']?.visible">
    <div class="flex-center">
      <AtomSwitch
        :model-value="task?.taskSchedule?.doItToday"
        size="small"
        :disabled="!isEditable"
        @change="onDoItToday"
      />
    </div>
  </SynTd>
  <!-- FINISH TO DAY -->
  <SynTd v-if="taskColumnByCode['FINISH_TODAY']?.visible">
    <div class="flex-center">
      <AtomSwitch
        :model-value="task?.taskSchedule?.finishItToday"
        size="small"
        :disabled="!isEditable"
        @change="onFinishItToday"
      />
    </div>
  </SynTd>

  <!-- TASK SCHEDULE -->
  <SynTd v-if="taskColumnByCode['DEADLINE']?.visible">
    <div class="w-full flex justify-end">
      <div class="w-full flex items-center justify-start gap-2">
        <template v-if="isEditable && canChangeDeadline">
          <VigDropdown
            ref="scheduleRef"
            children-class-list="vc-day-content vc-focusable"
            hide-on-click="toggle"
            @on-dropdown-open="isOpenUpdateDeadline = true"
            @on-dropdown-close="isOpenUpdateDeadline = false"
          >
            <template #dropdown-toggle>
              <div
                class="
                  flex-center
                  hover-to-show__parent
                  w-max
                  cursor-pointer
                  hover:bg-current-50
                "
              >
                <SynTag
                  :class="[getConfigScheduleColor(task?.taskSchedule)]"
                  class="border bg-white flex-center hover:bg-current-50"
                  size="small"
                >
                  <span v-if="task.assigneeId && getConfigScheduleName(task)">
                    {{ getConfigScheduleName(task) }}
                  </span>
                  <SynLabelDateTime
                    v-else-if="task.scheduleTime"
                    format="date"
                    :datetime="task.scheduleTime"
                  />
                  <span v-else>
                    {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
                  </span>
                </SynTag>
              </div>
            </template>
            <template #dropdown-menu>
              <div class="p-2 w-[25rem]">
                <UpdateDeadline
                  v-if="isOpenUpdateDeadline"
                  :schedule-key="task?.taskSchedule?.scheduleOptionKey"
                  :other-time="task.taskSchedule.otherTime"
                  :schedule="task.scheduleTime"
                  :schedule-timezone="task.scheduleTimezone"
                  :user-id="task.assigneeId"
                  :group-id="task.groupId"
                  :start-time="task.startTime"
                  @cancel="onCancelDeadline"
                  @save="onChangeDeadline"
                  @on-change-start-time="onUpdateStartTime"
                >
                  <template #scheduleTime>
                    <UpdateDeadlineTime
                      v-if="!task?.scheduleByHour"
                      :schedule-time="task?.scheduleTime"
                      :schedule-by-hour="task?.scheduleByHour"
                      :schedule-timezone="task?.scheduleTimezone"
                      :my-timezone="myTimezone"
                      :readonly="!isEditable || !canChangeDeadline"
                      @on-change="onChangeDeadlineTime"
                      @on-remove-time="onRemoveScheduleTime"
                    />
                  </template>
                </UpdateDeadline>
              </div>
            </template>
          </VigDropdown>
          <UpdateDeadlineTime
            v-if="task?.scheduleByHour"
            :class="[getConfigScheduleColor(task?.taskSchedule)]"
            :schedule-time="task?.scheduleTime"
            :schedule-by-hour="task?.scheduleByHour"
            :schedule-timezone="task?.scheduleTimezone"
            :my-timezone="myTimezone"
            :readonly="!isEditable || !canChangeDeadline"
            @on-change="onChangeDeadlineTime"
            @on-remove-time="onRemoveScheduleTime"
          />
        </template>

        <template v-else>
          <SynTag
            :class="[getConfigScheduleColor(task?.taskSchedule)]"
            class="border bg-white flex-center hover:bg-current-50"
            size="small"
          >
            <span v-if="task.assigneeId && getConfigScheduleName(task)">
              {{ getConfigScheduleName(task) }}
            </span>
            <SynLabelDateTime
              v-else-if="task.scheduleTime"
              format="date"
              :datetime="task.scheduleTime"
            />
            <span v-else>
              {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
            </span>
          </SynTag>
        </template>

        <TaskDeadlineBlockingShortInfo
          v-if="task?.scheduleTimeLocked"
          :readonly="!isEditable || !canChangeDeadline"
          @on-cancel-block-deadline="onToggleLockDeadline(false)"
        />
      </div>
    </div>
  </SynTd>
  <!-- OVERDUE -->
  <SynTd v-if="taskColumnByCode['OVERDUE']?.visible">
    <div class="flex-center">
      <span v-if="task.scheduleTime" class="text-xs">
        {{ overdueTime }}
      </span>
    </div>
  </SynTd>

  <!-- ASSIGNEE -->
  <SynTd
    v-if="isGroup || isMyAssign || isDomain || isRelatedTab || isPinnedTaskTab"
  >
    <div class="flex-center">
      <VigDropdown
        v-if="isEditable"
        ref="changeAssignRef"
        :hide-on-click="'outside'"
        @on-dropdown-open="isOpenChangeAssign = true"
        @on-dropdown-close="isOpenChangeAssign = false"
      >
        <template #dropdown-toggle>
          <div class="flex-center hover-to-show__parent cursor-pointer">
            <UserByAction
              v-if="task.assigneeId"
              :user-id="task.assigneeId"
              is-hidden-name
              is-show-action-user
              avatar-class="w-7 h-7"
            />
            <SynTag v-else class="bg-transparent border-none" size="small" />

            <div
              class="
                flex-center
                w-5
                h-5
                hover-to-show__children
                absolute
                -top-1
                -right-8
                bg-current-50
                rounded-full
              "
            >
              <SynIcon
                :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                name="edit"
                custom-class="w-3 h-3 fill-current"
                is-active
              />
            </div>
          </div>
        </template>
        <template #dropdown-menu>
          <div class="p-2" style="width: 28rem">
            <ChangeAssign
              v-if="isOpenChangeAssign"
              v-permission="'FunctionTaskAssign'"
              is-update-instant
              :group-id="task.groupId"
              :user-id="task.assigneeId"
              :domain-id="task.domainId"
              @on-cancel="onCancelChangeAssign"
              @on-save="onChangeAssign"
            />
          </div>
        </template>
      </VigDropdown>
      <template v-else>
        <div class="flex-center">
          <UserByAction
            v-if="task.assigneeId"
            :user-id="task.assigneeId"
            is-hidden-name
            is-show-action-user
            avatar-class="w-7 h-7"
          />
          <SynTag v-else class="bg-transparent border-none" size="small" />
        </div>
      </template>
    </div>
  </SynTd>

  <!-- taskCreator -->
  <SynTd
    v-if="!isMyAssign && taskColumnByCode['REPORTER']?.visible"
    v-permission-model
  >
    <div v-if="task.creatorId" class="flex-center">
      <UserByAction
        :user-id="task.creatorId"
        is-hidden-name
        is-show-action-user
        avatar-class="w-7 h-7"
      />
    </div>
  </SynTd>

  <!-- created date -->
  <SynTd
    v-if="taskColumnByCode['CREATED_TIME']?.visible"
    class="text-left whitespace-nowrap"
  >
    <div class="flex flex-col items-end text-xs">
      <span>
        <SynLabelDateTime format="date" :datetime="task.creationTime" />
      </span>
      <span style="font-size: 0.75em">
        <SynLabelDateTime format="time" :datetime="task.creationTime" />
      </span>
    </div>
  </SynTd>

  <SynTd
    v-if="taskColumnByCode['UPDATED_TIME']?.visible"
    class="text-left whitespace-nowrap"
  >
    <div
      v-if="task.lastModificationTime"
      class="flex flex-col items-end text-xs"
    >
      <SynLabelDateTime format="date" :datetime="task.lastModificationTime" />
      <span style="font-size: 0.75em">
        <SynLabelDateTime format="time" :datetime="task.lastModificationTime" />
      </span>
    </div>
  </SynTd>

  <!-- taskAction -->
  <SynTd class="px-5">
    <div class="relative">
      <syn-local-loading
        v-if="isLoadingMoreActionByTaskId == task.id || isUpdatingTask"
      />
      <ActionOnTask
        v-else
        :current-task="task"
        :chat-enabled="chatEnabled"
        :readonly="!isEditable"
        :task-code="task?.code"
        :current-pin-code="task?.pinnedColor"
        :action-list="[
          EActionOnTask.FINISH,
          EActionOnTask.DISCUSS,
          EActionOnTask.CLONE,
          EActionOnTask.SHARE,
          EActionOnTask.SNAPSHOT,
          EActionOnTask.PIN,
          EActionOnTask.COPY_LINK,
          EActionOnTask.VIEW_DETAIL,
          EActionOnTask.SUB_TASK,
        ]"
        is-hidden-tool
        @select-action="onSelectActionOnTask"
        @delete="onDelete"
        @copy-link="$emit('copyLink')"
        @on-toggle-pin="onTogglePin"
        @on-open-detail="onClickTaskDetail"
        @on-finish-task="onChangeTaskState(TaskLife.Finished)"
        @update:loading="(value) => (isUpdatingTask = value)"
      />
    </div>
  </SynTd>

  <ChangeGroupAndUser
    v-if="taskForClone?.id > 0"
    v-permission="'FunctionTaskAssign'"
    :group-id="task?.groupId || null"
    :user-id="task?.assigneeId || getCurrentUserId()"
    :is-loading-save="isUpdatingTask"
    @on-cancel="taskForClone = {}"
    @on-save="onSaveCloneTask"
  >
    <div class="mx-2 border rounded">
      <TaskCardItem :task-detail="task" readonly />
    </div>
  </ChangeGroupAndUser>

  <UpdateDomainModal
    v-if="isOpenUpdateDomainModal"
    :task-detail="{
      ...task,
      assigneeId: task?.assigneeId,
      groupId: task?.groupId,
      domainId: null,
      projectId: null,
      subprojectId: null,
    }"
    @cancel="onCloseUpdateDomainModal"
    @save="onSaveDomainAfterChangeGroup"
  />

  <TaskSharingModal
    v-if="isOpenTaskSharing"
    :task="task"
    @on-close="isOpenTaskSharing = false"
  />

  <TaskSnapshotsModal
    v-if="isOpenTaskSnapshots"
    :task="task"
    @on-close="isOpenTaskSnapshots = false"
  />

  <TaskApprovalModal
    v-if="isOpenRequestConfirmModal"
    :task="task"
    :approval-status="TaskApprovalStatus.WAITING"
    :view-mode="viewModeProvideEvidence"
    @on-close="isOpenRequestConfirmModal = false"
    @confirm="
      (comment) => onChangeApprovalStatus(TaskApprovalStatus.WAITING, comment)
    "
  />
</template>

<style>
.task-table-item_action__container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(239, 249, 250, 0.9) 50%,
    rgba(239, 249, 250, 1) 100%
  );
}

.task-table-item_action {
  display: none;
}

.task-table-item_row:hover .task-table-item_action {
  display: flex;
}

.task-list-table__set-finished-padding::before {
  content: '';
  display: block;
  height: 1.6rem;
}
</style>
