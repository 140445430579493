import { defineStore } from 'pinia';
import CommonService from '@/application/services/CommonService';
import { IUserSettingPayload } from '@/application/repositories/CommonRepository';
import { CurrentUserConfig } from '@/ui/common/constants/constant';
import taskTabViewStore from '@/store/task/task-tab-view';
import { ETaskViewType } from '@/application/constants/task.const';
import {
    ICurrentUserSetting,
    IPersonalSetting,
} from '@/application/types/common/common.types';
import { PERSONAL_SETTING_BY_KEYS } from '@/application/constants/system/common-settings.const';
import myProfileStore from '@/store/auth/my-profile';

export default defineStore({
    id: 'commonStore',
    state: () => ({
        userConfig: [] as ICurrentUserSetting[],
        userConfigByKeys: {} as any,
        _commonService: CommonService.getInstance(),
        sessionId: `session_id_${Date.now()}`,
    }),
    getters: {},
    actions: {
        async getCurrentUserSetting(isInit?) {
            try {
                // need implement store to localStorage

                const res = await this._commonService.getCurrentUserSetting();
                const userConfig = res['result'];

                this.userConfig = [
                    {
                        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN,
                        value: undefined,
                    },
                    {
                        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW,
                        value: undefined,
                    },
                    {
                        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS,
                        value: undefined,
                    },
                    {
                        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS,
                        value: undefined,
                    },
                    {
                        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_REPORT,
                        value: undefined,
                    },
                    ...userConfig,
                ];
                this.userConfigByKeys = this.userConfig?.reduce(
                    (currentObj, currentItem) => {
                        let value = currentItem?.value;

                        switch (currentItem?.key) {
                            case IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN:
                            case IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW:
                            case IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS:
                            case IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS:
                            case IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_REPORT:
                                if (value == '1') {
                                    value = true;
                                } else if (value == '0') {
                                    value = false;
                                } else {
                                    const adminType =
                                        myProfileStore().myProfile?.adminType;

                                    const defaultValue =
                                        PERSONAL_SETTING_BY_KEYS[
                                            currentItem?.key
                                        ]
                                            ? PERSONAL_SETTING_BY_KEYS[
                                                  currentItem?.key
                                              ]?.defaultValueByRole[adminType]
                                            : '0';

                                    value = defaultValue == '1' ? true : false;
                                }
                                break;
                            case CurrentUserConfig.OnlyUnreadAllNotification:
                            case CurrentUserConfig.OnlyUnreadFinishedNotification:
                            case CurrentUserConfig.OnlyUnreadNewNotification:
                            case CurrentUserConfig.OnlyUnreadNotification:
                            case CurrentUserConfig.OnlyUnreadOtherNotification:
                            case CurrentUserConfig.OnlyUnreadUpdatedNotification:
                                value =
                                    value == '1' ||
                                    value == 'true' ||
                                    value == true;
                                break;

                            case CurrentUserConfig.StartWorkingConditionsSpecific:
                                value = value ? JSON.parse(value) : null;
                                break;
                            default:
                                break;
                        }
                        return {
                            ...currentObj,
                            [currentItem?.key]: value,
                        };
                    },
                    {}
                );

                if (
                    isInit &&
                    [ETaskViewType.LIST, ETaskViewType.BOARD].includes(
                        this.userConfigByKeys[
                            CurrentUserConfig.ViewTaskListDefault
                        ]
                    ) &&
                    this.userConfigByKeys[
                        CurrentUserConfig.ViewTaskListDefault
                    ] !== ETaskViewType.LIST
                ) {
                    taskTabViewStore().setCustomizeViewActive(
                        this.userConfigByKeys[
                            CurrentUserConfig.ViewTaskListDefault
                        ]
                    );
                }
                return userConfig;
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async updateCurrentUserSetting(data: IUserSettingPayload) {
            try {
                await this._commonService.updateCurrentUserSetting(data);
                await this.getCurrentUserSetting();
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async setCurrentUserSetting(data: { key: string; value: string }) {
            try {
                await this._commonService.setCurrentUserSetting(data);
                await this.getCurrentUserSetting();
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
            }
        },
        async setMultiUsersSetting(data: any) {
            await this._commonService.setMultiUsersSetting(data);

            const myUserId = myProfileStore().myProfile?.id;

            if (data?.userIds?.includes(myUserId)) {
                await this.getCurrentUserSetting();
            }
        },
        async getPreviewLinkInfo(link) {
            try {
                const res = await this._commonService.getPreviewLinkInfo(link);
                const previewData = res['result'];

                return previewData;
            } catch (e) {
                console.log(e);
                return null;
            }
        },
    },
});
