<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import TaskWorkflowShortInfo from '@/ui/modules/task/table/TaskWorkflowShortInfo.vue';

const props = defineProps<{
  activity: any;
}>();

const afterUpdate = computed<any>(() => {
  return covertSnakeCaseToCamelCase(props.activity?.afterUpdate);
});

onMounted(() => {
  // console.log('🚀 Tictop ~ props:', props.activity);
});
</script>
<template>
  <span class="inline-flex flex-wrap">
    <span class="lowercase"> đã áp dụng luồng công việc </span>
    <TaskWorkflowShortInfo
      :workflow-id="afterUpdate?.workflowId || ''"
      :workflow-step-id="afterUpdate?.workflowStepId || ''"
    />
  </span>
</template>
