import BaseRepository from '@/base/repositories/BaseRepository';
import { IWorkflowEntity } from '@/domain/entities/workflow/WorkflowEntity';
import { IStepTemplateEntity } from '@/domain/entities/workflow/StepTemplateEntity';
import { EWorkflowTaskStepStatus } from '../types/workflow/workflow.types';
import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import { IAddTaskWorkflowPayload } from '../types/workflow/workflow-repository.types';

export default class WorkflowRepository {
    private static instance: WorkflowRepository;

    constructor() {}

    public static getInstance(): WorkflowRepository {
        if (!WorkflowRepository.instance) {
            // Get from local storage
            WorkflowRepository.instance = new WorkflowRepository();
        }

        return WorkflowRepository.instance;
    }

    updateWorkFlowInfo(data: IWorkflowEntity) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/UpdateWorkFlowInfo`,
            method: 'put',
            params: {
                workflowId: data?.id,
            },
            data,
        });
    }
    getWorkflowsStepsShortInfo() {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetWorkflowsStepsShortInfo`,
            method: 'get',
        });
    }
    updateTaskStep(data: IStepTemplateEntity) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/UpdateTaskStep`,
            method: 'post',
            data: {
                ...data,
                updateOption: 'SKIP_UPDATE_TEMPLATE',
            },
        });
    }
    deleteWorkFlowStepTemplate(stepId: string) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/DeleteWorkFlowStepTemplate`,
            method: 'delete',
            params: {
                stepId,
            },
        });
    }
    deleteWorkflow(workflowId: string) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/DeleteWorkflow`,
            method: 'delete',
            params: {
                workflowId,
            },
        });
    }
    getAllStepTemplates() {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetWorkflowStepTemplates`,
            method: 'get',
        });
    }
    updateWorkflowSteps(
        workflowId,
        workflowSteps: IStepTemplateEntity[],
        drawData,
        isCreateNewVersion
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/UpdateWorkflowSteps`,
            method: 'put',
            params: {
                workflowId,
                isCreateNewVersion,
            },
            data: {
                steps: workflowSteps,
                drawData,
            },
        });
    }
    addWorkFlowStepTemplate(data: IStepTemplateEntity) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/AddWorkflowStepTemplate`,
            method: 'post',
            data,
        });
    }
    updateWorkFlowStepTemplate(data: IStepTemplateEntity) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/UpdateWorkFlowStepTemplate`,
            method: 'put',
            params: {
                stepId: data?.id,
            },
            data,
        });
    }
    updateWorkflowStep(workflowId, data: IStepTemplateEntity) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/UpdateWorkflowStep`,
            method: 'put',
            params: {
                workflowId,
                stepId: data?.id,
            },
            data,
        });
    }
    getAllStepType() {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetAllStepType`,
            method: 'get',
        });
    }
    // moveWorkFlowTaskStep(data: {
    //     workFlowTaskId: string;
    //     moveType: 'MOVE_PREVIOUS' | 'MOVE_NEXT';
    // }) {
    //     return BaseRepository.requestWithAuthorize({
    //         url: `WorkFlow/MoveWorkFlowTaskStep`,
    //         method: 'post',
    //         data,
    //     });
    // }
    updateTaskWorkflowStep(
        taskWorkflowId: number,
        stepId: string,
        data: ITaskWorkflowStep
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/UpdateTaskWorkflowStep`,
            method: 'put',
            params: {
                taskWorkflowId,
                stepId,
            },
            data,
        });
    }
    completeTaskWorkflowStep(taskId: number, stepId: string) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/CompleteTaskWorkflowStep`,
            method: 'put',
            params: {
                taskId,
                stepId,
            },
        });
    }
    reopenTaskWorkflowStep(taskId: number, stepId: string) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/ReopenTaskWorkflowStep`,
            method: 'put',
            params: {
                taskId,
                stepId,
            },
        });
    }
    detachWorkFlowFromTask(workFlowTaskId: any) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/DetachWorkFlowFromTask`,
            method: 'post',
            params: {
                workFlowTaskId,
            },
        });
    }
    updateStatusTaskStep(data: {
        id: string;
        status: EWorkflowTaskStepStatus;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/UpdateStatusTaskStep`,
            method: 'post',
            data,
        });
    }
    addOrUpdateWorkFlowStepType(data: IAddOrUpdateWorkFlowStepTypePayload) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/AddOrUpdateWorkFlowStepType`,
            method: 'post',
            data,
        });
    }
    deleteWorkFlowStepType(workFlowStepTypeId: number) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/DeleteWorkFlowStepType`,
            method: 'post',
            params: {
                workFlowStepTypeId,
            },
        });
    }
    updateTaskStepData(stepId: string, data: ITaskWorkflowStep) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/UpdateTaskStepData`,
            method: 'post',
            data: {
                ...data,
                id: stepId,
            },
        });
    }
    getWorkFlowStepTemplate(stepId: string) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetWorkFlowStepTemplate`,
            method: 'get',
            params: {
                stepId,
            },
        });
    }

    createWorkFlow(workflow: IWorkflowEntity) {
        const data = {
            ...workflow,
            steps: workflow.steps,
        };
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/AddWorkflow`,
            method: 'post',
            data,
        });
    }
    getWorkflows() {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetWorkflows`,
            method: 'get',
        });
    }
    getWorkflowsWithFilters(data) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetWorkflowsWithFilters`,
            method: 'post',
            data,
        });
    }
    countWorkflowsTasks() {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/CountWorkflowsTasks`,
            method: 'get',
        });
    }
    getWorkflowById(workflowId: string) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/GetWorkflow`,
            method: 'get',
            params: {
                workflowId,
            },
        });
    }

    addTaskWorkflow(data: IAddTaskWorkflowPayload) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/AddTaskWithWorkflow`,
            method: 'post',
            data,
        });
    }

    updateWorkflowActive(workflowId, isActive: boolean) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkFlow/UpdateWorkflowActive`,
            method: 'put',
            params: {
                workflowId,
            },
            data: {
                isActive,
            },
        });
    }
}

export interface IAddOrUpdateWorkFlowStepTypePayload {
    id: number;
    index: number;
    name: string;
}
