<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 300">
    <path
      style="fill: #008e8e"
      d="M119.82,239.88h120a120,120,0,0,1,120,120v0a0,0,0,0,1,0,0h-240a60,60,0,0,1-60-60v0a60,60,0,0,1,60-60Z"
      transform="translate(359.88 -59.82) rotate(90)"
    />
    <circle style="fill: #055268" cx="60" cy="60" r="50" />
  </svg>
</template>
