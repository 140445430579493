<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 468 468"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M132 185h75v283h-75zM261 245h75v223h-75zM390 139h75v329h-75zM3 292h75v176H3z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M424.918 0c-20.972 0-37.972 16.989-37.972 37.946 0 4.905.941 9.588 2.637 13.891L316.4 114.18a37.818 37.818 0 0 0-18.057-4.558c-10.853 0-20.635 4.557-27.555 11.852L207.612 95.6c.005-.245.019-.488.019-.735 0-20.957-17.001-37.946-37.973-37.946s-37.972 16.989-37.972 37.946c0 2.121.182 4.198.517 6.224l-65.635 38.807a37.814 37.814 0 0 0-23.484-8.139c-20.972 0-37.973 16.989-37.973 37.946s17.001 37.946 37.973 37.946 37.972-16.989 37.972-37.946a38.11 38.11 0 0 0-.517-6.224l65.635-38.807a37.815 37.815 0 0 0 23.485 8.139c10.853 0 20.635-4.557 27.555-11.852l63.176 25.874c-.005.245-.019.488-.019.734 0 20.957 17.001 37.946 37.973 37.946s37.972-16.989 37.972-37.946c0-4.391-.755-8.604-2.128-12.527l73.991-63.032a37.835 37.835 0 0 0 16.74 3.884c20.972 0 37.973-16.989 37.973-37.946S445.89 0 424.918 0z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
