<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M24.2 30H5.8C3.7 30 2 28.3 2 26.2V7.8C2 5.7 3.7 4 5.8 4h12c.6 0 1 .4 1 1s-.4 1-1 1h-12C4.8 6 4 6.8 4 7.8v18.5c0 1 .8 1.8 1.8 1.8h18.5c1 0 1.8-.8 1.8-1.8v-12c0-.6.4-1 1-1s1 .4 1 1v12c-.1 2-1.8 3.7-3.9 3.7z"
        class=""
      ></path>
      <path
        d="M28 2h-5c-.6 0-1 .4-1 1s.4 1 1 1h3.6L15.3 15.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L28 5.4V9c0 .6.4 1 1 1s1-.4 1-1V4c0-1.1-.9-2-2-2z"
        class=""
      ></path>
    </g>
  </svg>
</template>
