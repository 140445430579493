<script setup lang="ts">
import { EPaymentPurpose } from '@/application/enums/PaymentEnums';
import { PackagePrice, iconPlans } from '@/ui/common/constants/plans';
import plansStore from '@/store/plans';

const props = defineProps<{
  actionName: EPaymentPurpose;
  currentPlanId: 'FREE' | 'EFFICIENT' | 'EXCELLENT' | 'BASIC';
  previousPlanId: 'FREE' | 'EFFICIENT' | 'EXCELLENT' | 'BASIC';
  billingCycle: 'YEARLY' | 'MONTHLY';
  expireDate: any;
  totalUsers: number;
  needToReloadPage: boolean;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
}>();
const _planStore = plansStore();

const onReloadPage = () => {
  emit('cancel');
  if (props.needToReloadPage) location.reload();
  else {
    _planStore.getAllPlanCapacities();
    _planStore.getCurrentOrgPlans();
  }
};

const getCustomClass = (planId) => {
  switch (planId) {
    case PackagePrice.PRO.key:
      return {
        customClassBody: 'border-current-100',
        customClassHeader: 'bg-current-300 text-white border-current-100',
      };
    case PackagePrice.VIP.key:
      return {
        customClassBody: 'border-current-200',
        customClassHeader: 'border-current-200 bg-current-400 text-white',
      };
    default:
      break;
  }
};

const dataByAction = {
  PURCHASE: {
    actionCode: 'PAYMENT_PLAN_PAYING',
  },
  UPGRADE: {
    actionCode: 'PAYMENT_UPGRADE',
  },
  EXTEND: {
    actionCode: 'PAYMENT_EXTEND',
  },
  GET_MORE: {
    actionCode: 'PAYMENT_EXTEND',
  },
};
</script>
<template>
  <SynModal
    container-class="w-max"
    z-index="z-100"
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    disable-element-active
    @cancel="$emit('cancel')"
  >
    <template #background>
      <AtomCongraturationBackground />
    </template>
    <template #body>
      <div class="flex-center flex-col space-y-8">
        <div
          class="flex-center w-full p-4"
          :class="getCustomClass(currentPlanId)?.customClassHeader"
        >
          <SynIcon :name="iconPlans[currentPlanId]" custom-class="w-24 h-24" />
        </div>
        <div class="flex flex-col text-center space-y-4 px-8">
          <template v-if="currentPlanId == 'FREE' || currentPlanId == 'BASIC'">
            <span class="text-lg font-semibold text-gray-600 uppercase">
              {{
                $t(
                  actionName == EPaymentPurpose.DOWNGRADE
                    ? 'PAYMENT_LABEL_SUCCESSFULLY_DOWNGRADED_TO_THE_BASIC_PLAN'
                    : 'PAYMENT_LABEL_SUCCESSFULLY_EXTEND_THE_BASIC_PLAN'
                ) || 'Thanks for upgrading!'
              }}
            </span>
            <span
              class="text-gray-500 max-w-[40rem]"
              v-html="
                $t(
                  actionName == EPaymentPurpose.DOWNGRADE
                    ? 'PAYMENT_LABEL_SUCCESSFULLY_DOWNGRADED_TO_THE_BASIC_PLAN_DESCRIPTION'
                    : 'PAYMENT_LABEL_SUCCESSFULLY_EXTEND_THE_BASIC_PLAN_DESCRIPTION'
                )
              "
            >
            </span>
          </template>
          <template v-else>
            <span class="text-lg font-semibold text-gray-600 uppercase">
              {{ $t('PAYMENT_THANK_FOR_UPGRADE') || 'Thanks for upgrading!' }}
            </span>
            <span
              v-if="actionName == EPaymentPurpose.BuyMore"
              class="text-gray-500"
              v-html="
                $t('PAYMENT_BUY_MORE_SUCCESS_FOR_PLAN', {
                  planId: currentPlanId,
                  totalUsers: totalUsers,
                  endDate: $filters.dayjs(expireDate, 'DD/MM/YYYY'),
                })
              "
            >
            </span>
            <span
              v-else-if="actionName == EPaymentPurpose.APPLY_LICENSE"
              class="text-gray-500"
              v-html="
                $t('PAYMENT_YOU_ARE_ON_LIFETIME', {
                  planId: currentPlanId,
                })
              "
            >
            </span>

            <span
              v-else
              class="text-gray-500"
              v-html="
                $t('PAYMENT_YOU_ARE_ON', {
                  actionName:
                    $t(dataByAction[actionName]?.actionCode) || 'upgrade',
                  planId: currentPlanId,
                  expireDate:
                    expireDate === 2524582800000
                      ? 'Unlimited'
                      : $filters.dayjs(expireDate, 'DD/MM/YYYY'),
                })
              "
            >
            </span>
          </template>
        </div>
        <div class="w-full flex items-center justify-end space-x-2">
          <SynButton
            :title="'Join an existed workspace'"
            class="bg-current"
            @click="onReloadPage"
          >
            <span>
              {{ $t('COMMON_LABEL_GOT_IT') || 'Got it' }}
            </span>
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
