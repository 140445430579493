<script setup lang="ts">
import { ref } from 'vue';
import SearchTaskOwners from '@/ui/components/search/SearchTaskOwners.vue';
const props = defineProps<{
  isSelectMultiple?: boolean;
}>();
const emit = defineEmits(['onClose', 'onConfirmSelect', 'onSelectOne']);

const selectedUserIds = ref<string[]>([]);
const onChooseOwner = (owner) => {
  if (!props.isSelectMultiple) {
    emit('onSelectOne', owner);

    return;
  }
  const isExisted = selectedUserIds.value?.some((id) => id == owner?.id);

  if (isExisted)
    selectedUserIds.value = selectedUserIds.value?.filter(
      (id) => id !== owner?.id
    );
  else selectedUserIds.value?.push(owner?.id);
};

const onConfirmSelect = () => {
  emit('onConfirmSelect', [...selectedUserIds.value]);

  selectedUserIds.value = [];
};
</script>
<template>
  <div class="w-full h-full flex flex-col">
    <div class="w-full flex-1 min-h-0">
      <SearchTaskOwners
        :owner-type-list="['USER']"
        is-hidden-action-item
        :is-select-multiple="isSelectMultiple"
        :selected-ids="selectedUserIds?.map((userId) => `USER_${userId}`)"
        @on-choose-owner="onChooseOwner"
      />
    </div>
    <div v-if="selectedUserIds?.length > 0" class="px-4 py-2 flex justify-end">
      <div class="flex-center space-x-2">
        <SynButton label="Cancel" type-text color="gray" />
        <SynButton @click="onConfirmSelect">
          <span>Select {{ selectedUserIds?.length }} users</span>
        </SynButton>
      </div>
    </div>
  </div>
</template>
