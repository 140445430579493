<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 490.66667 490.66667"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m404.230469 284.679688 15.085937 15.085937-119.453125 119.449219-15.085937-15.082032zm0 0"
        data-original="#000000"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m299.769531 284.679688 119.453125 119.453124-15.085937 15.082032-119.453125-119.449219zm0 0"
        data-original="#000000"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m352 490.667969c-76.398438 0-138.667969-62.269531-138.667969-138.667969s62.269531-138.667969 138.667969-138.667969 138.667969 62.269531 138.667969 138.667969-62.269531 138.667969-138.667969 138.667969zm0-256c-64.667969 0-117.332031 52.664062-117.332031 117.332031s52.664062 117.332031 117.332031 117.332031 117.332031-52.664062 117.332031-117.332031-52.664062-117.332031-117.332031-117.332031zm0 0"
        data-original="#000000"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m192 213.332031c-58.800781 0-106.667969-47.867187-106.667969-106.664062 0-58.800781 47.867188-106.667969 106.667969-106.667969s106.667969 47.867188 106.667969 106.667969c0 58.796875-47.867188 106.664062-106.667969 106.664062zm0-192c-47.066406 0-85.332031 38.269531-85.332031 85.335938 0 47.066406 38.265625 85.332031 85.332031 85.332031s85.332031-38.265625 85.332031-85.332031c0-47.066407-38.265625-85.335938-85.332031-85.335938zm0 0"
        data-original="#000000"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m248 437.332031h-248v-10.664062c0-105.867188 86.132812-192 192-192 26.132812 0 51.464844 5.199219 75.332031 15.332031l-8.398437 19.601562c-21.199219-9.066406-43.734375-13.601562-66.933594-13.601562-90.535156 0-164.800781 70.800781-170.398438 160h226.398438zm0 0"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>
