import { IPersonalSetting } from '@/application/types/common/common.types';
import { AdminType } from '@/ui/common/constants/constant';

export const PERSONAL_SETTING_BY_KEYS = {
    [IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN]: {
        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN,
        titleCode: 'SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN_TITLE',
        subTitleCode: 'SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN_SUB_TITLE',
        iconName: 'project',
        defaultValueByRole: {
            [AdminType.Owner]: '1',
            [AdminType.Admin]: '1',
            [AdminType.User]: '0',
            [AdminType.Visitor]: '0',
        },
    },
    [IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW]: {
        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW,
        titleCode: 'SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW_TITLE',
        subTitleCode: 'SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW_SUB_TITLE',
        iconName: 'Flow',
        defaultValueByRole: {
            [AdminType.Owner]: '1',
            [AdminType.Admin]: '1',
            [AdminType.User]: '1',
            [AdminType.Visitor]: '0',
        },
    },
    [IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS]: {
        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS,
        titleCode: 'SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS_TITLE',
        subTitleCode: 'SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS_SUB_TITLE',
        iconName: 'note',
        defaultValueByRole: {
            [AdminType.Owner]: '1',
            [AdminType.Admin]: '1',
            [AdminType.User]: '1',
            [AdminType.Visitor]: '0',
        },
    },
    [IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS]: {
        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS,
        titleCode: 'SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS_TITLE',
        subTitleCode: 'SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS_SUB_TITLE',
        iconName: 'CheckCircle',
        defaultValueByRole: {
            [AdminType.Owner]: '1',
            [AdminType.Admin]: '1',
            [AdminType.User]: '1',
            [AdminType.Visitor]: '0',
        },
    },
    [IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_REPORT]: {
        key: IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_REPORT,
        titleCode: 'SETTING_ORGANIZATION_HIDE_REPORT_MENU_TITLE',
        subTitleCode: 'SETTING_ORGANIZATION_HIDE_REPORT_MENU_SUB_TITLE',
        iconName: 'Report',
        defaultValueByRole: {
            [AdminType.Owner]: '1',
            [AdminType.Admin]: '1',
            [AdminType.User]: '1',
            [AdminType.Visitor]: '0',
        },
    },
};
