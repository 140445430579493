<script setup lang="ts">
import { computed } from 'vue';
import taskTabViewStore from '@/store/task/task-tab-view';
// import {getCurrentUserId} from '@/ui/hooks/storageHook';
import ListTabsFilter from '@/ui/modules/task/tabs-list-task/list-tabs/ListTabsFilter.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
// import AddTabsModal from '@/ui/modules/task/tabs-list-task/create-new-tabs/AddTabsModal.vue';
import TabTaskTag from '@/ui/modules/task/components/tabs/TabTaskTag.vue';
import {
  EFilterTaskBoard,
  EFilterTaskDefault,
  ETaskViewType,
  // LIST_FILTER_TASK_DEFAULT,
} from '@/application/constants/task.const';
import SwitchTaskBoard from '@/ui/modules/task/board/switch-visible/SwitchTaskBoard.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { TaskListTab } from '@/domain/enums/taskEnum';
import taskListStore from '@/store/task/task-list-store';
import ApprovalTabsFilter from '@/ui/modules/task/tabs-list-task/list-tabs/ApprovalTabsFilter.vue';
import {
  ECustomFilterSourceType,
  ETaskListModule,
} from '@/application/types/task/task.types';

const props = withDefaults(
  defineProps<{
    listTaskOwnerId?: number;
    type?: 'MEMBER' | 'DOMAIN' | 'GROUP' | 'IA';
    isShowTab?: boolean;
    isHiddenCustomTab?: boolean;
    isHiddenOtherTab?: boolean;
    isApprovalList?: boolean;
    viewModeList?: ETaskViewType[];
    sourceType: ETaskListModule;
    customFilterSourceType?: ECustomFilterSourceType;
  }>(),
  {
    listTaskOwnerId: 0,
    type: 'MEMBER',
    isShowTab: true,
    viewModeList: () => [ETaskViewType.LIST, ETaskViewType.BOARD],
  }
);

const emit = defineEmits(['onChangeTab', 'onChangeView']);
const _taskListStore = taskListStore();

const isMyTaskPage = computed(
  () => props.listTaskOwnerId == getCurrentUserId()
);
const activeTab = computed(() => _taskListStore.activeTab);

const _taskTabViewStore = taskTabViewStore();
const viewCustomizeActive = computed<ETaskViewType>(
  () => _taskTabViewStore.customizeView
);
const listBoardTabs = computed(() => {
  return _taskTabViewStore.listBoardTabs.filter((o) => {
    if (o?.id == EFilterTaskDefault.PINNED)
      return activeTab.value == TaskListTab.MyList && isMyTaskPage.value;
    if (o?.id == EFilterTaskDefault.DEFAULT)
      return viewCustomizeActive.value === ETaskViewType.BOARD;

    return true;
  });
});
const boardCurrentTabId = computed(() => _taskTabViewStore.boardCurrentTabId);
const boardShowFinishedTask = computed(
  () => _taskTabViewStore.boardShowFinishedTask
);
const boardShowFinishedTaskByDeadline = computed(
  () => _taskTabViewStore.boardShowFinishedTaskByDeadline
);

const viewModeByKeys = {
  [ETaskViewType.LIST]: {
    key: ETaskViewType.LIST,
    nameIcon: 'view-list',
    label: 'TASK_LIST_VIEW_LIST_LABEL',
    systemSettings: [],
    functionCode: '',
  },
  [ETaskViewType.BOARD]: {
    key: ETaskViewType.BOARD,
    nameIcon: 'view-board',
    label: 'TASK_LIST_VIEW_BOARD_LABEL',
    systemSettings: [],
    functionCode: '',
  },
  [ETaskViewType.GANTT]: {
    key: ETaskViewType.GANTT,
    nameIcon: 'ViewModeGantt',
    label: 'TASK_LIST_VIEW_GANTT_LABEL',
    systemSettings: [
      {
        functionCode: 'ADVANCED_GANTT_CH_GANTT_CHART_ALLOWED',
        functionValue: '1',
      },
      {
        functionCode: 'ADVANCED_GANTT_CH_GANTT_CHART_ALLOWED_SYSTEM',
        functionValue: '1',
      },
    ],
    functionCode: '',
  },
  [ETaskViewType.CALENDAR]: {
    key: ETaskViewType.CALENDAR,
    nameIcon: 'calendars',
    label: 'TASK_LIST_VIEW_CALENDAR_LABEL',
    systemSettings: [],
    functionCode: '',
  },
};

const onChangeViewTask = (view) => {
  _taskTabViewStore.setCustomizeViewActive(view?.key);
  // _taskListStore.initFilterPayload(props.listTaskOwnerId, props.sourceType);
  // _taskListStore.updateFilterPayload(
  //   LIST_FILTER_TASK_DEFAULT[taskTabViewStore().currentTabId]
  // );
  emit('onChangeView', view?.key);
};

const onChangeBoardTab = (tab) => {
  _taskTabViewStore.changeCustomTabInBoard(tab?.id);
  _taskListStore.resetFilterPayload();
};

const onChangeBoardShowFinishedTask = (value) => {
  _taskTabViewStore.onChangeBoardShowFinishedTask(value);
};
const onShowFinishedTaskInDeadline = (value) => {
  _taskTabViewStore.onShowFinishedTaskInDeadline(value);
};
</script>

<template>
  <div
    id="filterTabsId"
    class="flex-1 flex items-center w-full px-2 py-1.5 justify-between"
  >
    <div class="flex-1 flex items-center space-x-4">
      <div v-if="viewModeList?.length > 0" class="flex-center space-x-2">
        <template v-for="viewMode in viewModeList" :key="viewMode">
          <div
            v-vig-tooltip="$t(viewModeByKeys[viewMode]?.label)"
            v-system-config="viewModeByKeys[viewMode]?.systemSettings"
            v-permission-function="{
              functionCode: viewModeByKeys[viewMode]?.functionCode,
              onContinue: () => {
                onChangeViewTask(viewModeByKeys[viewMode]);
              },
            }"
            class="w-8 h-8 flex-center rounded cursor-pointer"
            :class="
              viewModeByKeys[viewMode]?.key === viewCustomizeActive
                ? 'bg-current-50 fill-current-500 hover:bg-current-100'
                : 'fill-gray-500 hover:bg-gray-100'
            "
            @click="onChangeViewTask(viewModeByKeys[viewMode])"
          >
            <SynIcon :name="viewModeByKeys[viewMode]?.nameIcon" />
          </div>
        </template>
      </div>
      <template v-if="viewCustomizeActive === ETaskViewType.LIST">
        <template v-if="isShowTab">
          <ApprovalTabsFilter
            v-if="isApprovalList"
            :list-task-owner-id="listTaskOwnerId"
            :type="type"
            is-show-all
            @on-change-tab="(value) => $emit('onChangeTab', value)"
          />
          <template v-else>
            <ListTabsFilter
              :list-task-owner-id="listTaskOwnerId"
              :type="type"
              is-show-all
              :is-hidden-custom-tab="isHiddenCustomTab"
              :is-hidden-other-tab="isHiddenOtherTab"
              :custom-filter-source-type="customFilterSourceType"
              @on-change-tab="(value) => $emit('onChangeTab', value)"
            />
          </template>
        </template>

        <span v-else></span>
      </template>
      <template v-else-if="viewCustomizeActive === ETaskViewType.BOARD">
        <!--  viewCustomizeActive === ETaskViewType.BOARD -->
        <div class="flex-1 flex items-center justify-between">
          <div class="flex-center space-x-8 text-sm">
            <!-- <span class="font-medium text-gray-600">
            {{ $t('COMMON_LABEL_BOARD') }}
          </span>
          <AddTabsModal
            :source-type="sourceType"
            :list-task-owner-id="listTaskOwnerId"
          /> -->
            <div class="flex-center space-x-2 text-sm">
              <template v-for="tab in listBoardTabs" :key="tab?.key">
                <TabTaskTag
                  :is-active="tab?.id === boardCurrentTabId"
                  :color="tab.color"
                  :name="$t(tab?.labelKey)"
                  :total="tab?.totalTask"
                  readonly
                  :tab-detail="tab"
                  @on-select-tab="onChangeBoardTab(tab)"
                />
              </template>
            </div>
            <AtomSwitch
              v-if="boardCurrentTabId === EFilterTaskBoard.BY_URGENCY"
              :model-value="boardShowFinishedTask"
              :label="$t('TASK_LIST_SHOW_FINISHED_TASKS')"
              @update:model-value="onChangeBoardShowFinishedTask"
            />
            <AtomSwitch
              v-if="boardCurrentTabId === EFilterTaskBoard.BY_DEADLINE"
              :model-value="boardShowFinishedTaskByDeadline"
              :label="$t('TASK_LIST_SHOW_FINISHED_TASKS')"
              @update:model-value="onShowFinishedTaskInDeadline"
            />
          </div>
          <div :key="boardCurrentTabId" class="flex-center gap-2">
            <slot name="filter"> </slot>
            <SwitchTaskBoard
              :list-task-owner-id="listTaskOwnerId"
              :type="type"
            />
          </div>
        </div>
      </template>
      <template v-else-if="viewCustomizeActive === ETaskViewType.CALENDAR">
        <!--  viewCustomizeActive === ETaskViewType.BOARD -->
        <div class="flex-1 flex items-center justify-between">
          <div class="flex-center space-x-8 text-sm"></div>
          <div class="flex-center gap-2">
            <slot name="filter"> </slot>
          </div>
        </div>
      </template>
    </div>

    <div class="flex items-center space-x-2">
      <span>
        <slot name="actions"></slot>
      </span>
    </div>
  </div>
</template>
