<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g id="_13._Goals">
        <circle
          class="st0"
          style="display: none; fill: #e7efff"
          cx="256"
          cy="274.6"
          r="232.7"
        />
        <circle
          class="st1"
          style="
            opacity: 0.32;
            fill: #dceef2;
            stroke: #ffffff;
            stroke-width: 6;
            stroke-miterlimit: 10;
          "
          cx="256"
          cy="274.6"
          r="232.7"
        />
        <circle class="st2" style="fill: #dceef2" cx="88.4" cy="37.2" r="14" />
        <circle class="st3" style="fill: #f0b34b" cx="18.6" cy="432.9" r="14" />
        <circle
          class="st4"
          style="fill: #ffa100"
          cx="423.6"
          cy="181.5"
          r="14"
        />
        <circle
          class="st2"
          style="fill: #dceef2"
          cx="493.4"
          cy="414.3"
          r="14"
        />
        <path
          class="st5"
          style="fill: #005c5c"
          d="M218.8,451.5H104.8c-37-31.5-63.1-73.9-74.8-121l37.2-62.6c6.9-11.6,22-15.5,33.6-8.6c3.5,2.1,6.5,5,8.6,8.6    L218.8,451.5z"
        />
        <path
          class="st6"
          style="fill: #008e8e"
          d="M481.9,330.5c-11.7,47.2-37.8,89.5-74.8,121H293.2L402.5,268c6.9-11.6,22-15.5,33.6-8.6    c3.5,2.1,6.5,5,8.6,8.6L481.9,330.5z"
        />
        <path
          class="st7"
          style="fill: #00afb7"
          d="M439.5,417.8c-79.1,101.3-225.3,119.4-326.6,40.3c-15-11.7-28.6-25.3-40.3-40.3    c102.4-186.2,122.4-222.9,122.9-223.4c1.2-1.4,20.4-24.6,60.5-24.6s59.4,23.3,60.5,24.6C317.1,194.9,336.9,231.4,439.5,417.8    L439.5,417.8z"
        />
        <path
          class="st8"
          style="fill: #88f2e8"
          d="M437.5,420.4c-46.2,57.7-117,90-190.8,86.9c-2.6-33.3,22.3-62.4,55.6-65c7.4-0.6,14.9,0.2,22,2.4    c9-21.3,33.5-31.4,54.8-22.4c3.7,1.6,7.2,3.7,10.3,6.2C403.9,419.7,421.1,416.8,437.5,420.4z"
        />
        <path
          class="st8"
          style="fill: #88f2e8"
          d="M265.3,507.3c-73.8,2.9-144.6-29.3-190.8-86.9c16.4-3.6,33.5-0.7,47.7,8.1c17.9-14.7,44.3-12,59,5.9    c2.6,3.1,4.7,6.6,6.2,10.3c32-9.7,65.8,8.3,75.5,40.2C265.1,492.2,265.9,499.8,265.3,507.3z"
        />
        <path
          class="st9"
          style="fill: #ff4d69"
          d="M332.8,51.8L265.3,14c0-5.1-4.2-9.3-9.3-9.3c-5.1,0-9.3,4.2-9.3,9.3v121h18.6V97.7l67.4-37.9    c2.2-1.3,2.9-4.2,1.6-6.4C334,52.8,333.4,52.2,332.8,51.8z"
        />
        <g>
          <path
            class="st10"
            style="fill: #ffffff"
            d="M316.5,194.5c-9.7,11.8-27.1,13.6-38.9,3.9c-0.7-0.6-1.4-1.2-2.1-1.9c-10.9-10.8-28.4-10.8-39.3,0     c-10.9,10.7-28.4,10.6-39.1-0.2c-0.6-0.6-1.1-1.2-1.6-1.8l19.3-35c12.6-22.9,41.4-31.1,64.3-18.5c7.8,4.3,14.2,10.7,18.5,18.5     L316.5,194.5z"
          />
          <path
            class="st10"
            style="fill: #ffffff"
            d="M144.3,344.4c0,7.7-6.3,14-14,14h-27.9c-7.7-0.1-13.9-6.4-13.8-14.1c0.1-6,3.9-11.3,9.6-13.1     c2.2-10.1,12.1-16.4,22.1-14.3c7.1,1.5,12.7,7.1,14.3,14.3C140.3,333,144.3,338.4,144.3,344.4z"
          />
          <path
            class="st10"
            style="fill: #ffffff"
            d="M395.6,251.3c0,10.3-8.3,18.6-18.6,18.6h-46.5c-10.3,0-18.6-8.3-18.6-18.6c0-10.3,8.3-18.6,18.6-18.6     c0-12.9,10.4-23.3,23.3-23.3c12.9,0,23.3,10.4,23.3,23.3C387.3,232.7,395.6,241.1,395.6,251.3z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
