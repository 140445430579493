<script setup lang="ts">
import { onMounted, onUnmounted, ref, inject, watch } from 'vue';
import { runUpdateTimeAgo } from './time-ago';

const fromNow: any = inject('fromNow');

const props = defineProps({
  datetime: {
    type: [String, Number, Date],
    default: 0,
  },
  suffix: {
    type: Boolean,
    default: true,
  },
});

let timer;

const timeAgoValue = ref('');

onMounted(() => {
  getValue();
});

const getValue = () => {
  timeAgoValue.value = fromNow(props.datetime, props.suffix);

  timer && clearTimeout(timer);

  runUpdateTimeAgo(
    props.datetime,
    {
      relativeDate: new Date(),
    },
    timer,
    () => {
      timeAgoValue.value = fromNow(props.datetime, props.suffix);
    }
  );
};

watch(
  () => props.datetime,
  () => {
    getValue();
  }
);

onUnmounted(() => {
  timer && clearTimeout(timer);
});
</script>
<template>
  <!-- <span>{{ timeAgoValue }}</span> -->
  <span class="needs-tobe-rendered">
    {{ timeAgoValue }}
    <!-- {{ $t('COMMON_LABEL_AGO') || 'ago' }} -->
  </span>
</template>
