import AuthService from '@/application/services/AuthService';
import CatalogService from '@/application/services/CatalogService';
import AuthenticateClass from '@/application/entities/authenticate/AuthenticateClass';

export class AuthenticateSelectOrganization implements IAuthenticate {
    organizationId: number;
    _authService: AuthService;

    constructor(organizationId: number) {
        this.organizationId = organizationId;
        this._authService = AuthService.getInstance();
    }

    async login(isSwitchingOrg?) {
        const res: any = await this._authService.selectOrganization({
            OrganizationId: this.organizationId,
        });

        // handle login success
        return new AuthenticateClass().handleSuccess(
            res?.result,
            !!isSwitchingOrg
        );
    }
}

export class AcceptedInvitation {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    inviteToken: string;
    _catalogService: CatalogService;

    constructor(
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        inviteToken: string
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
        this.email = email;
        this.inviteToken = inviteToken;
        this._catalogService = CatalogService.getInstance();
    }

    accepted(organization, location) {
        return this._catalogService.acceptedInvitation(
            {
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                email: this.email,
                inviteToken: this.inviteToken,
            },
            organization,
            location
        );
    }

    reject() {
        return this._catalogService.rejectedInvitation({
            token: this.inviteToken,
            reason: '',
        });
    }

    async acceptedWithoutToken(organizationInfo, location) {
        const res = await this._catalogService.acceptedInvitation(
            {
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                email: this.email,
                inviteToken: this.inviteToken,
            },
            organizationInfo,
            location
        );

        return new AuthenticateClass().handleSuccess(res?.result);
    }
}

interface IAuthenticate {
    login: any;
}
