<script setup lang="ts">
import datetimeComposable, {
  FormatType,
} from '@/ui/composables/common/datetime-composable';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    format?: FormatType;
    datetime: any;
    ignoreTimezone?: boolean;
    timezone?: string;
  }>(),
  {
    format: 'date',
    datetime: Date.now(),
    ignoreTimezone: false,
  }
);

const { formatDateTime, myTimezone } = datetimeComposable();

const convertedTime = computed<any>(() => {
  return formatDateTime(props.datetime, props.format, {
    isIgnoreTimezone: props.ignoreTimezone,
    timezone: props.timezone,
  });
});

const timezoneDataByKey = {
  'Asia/Ho_Chi_Minh': {
    text: '(UTC+07:00)',
    timezone: 'Asia/Ho_Chi_Minh',
    flag: 'FlagVietnam',
    offset: '+07:00',
  },
  'Europe/Paris': {
    text: '(UTC+01:00)',
    timezone: 'Europe/Paris',
    flag: 'FlagFrance',
    offset: '+01:00',
  },
  'America/New_York': {
    text: '(UTC-05:00)',
    timezone: 'Europe/Paris',
    flag: 'FlagUnited',
    offset: '-05:00',
  },
};

const gmtString = computed<string>(() => {
  if (props.timezone !== myTimezone.value)
    return props.timezone ? timezoneDataByKey[props.timezone]?.text : '';
  return '';
});

const timezoneData = computed<{ flag: string; offset: string } | null>(() => {
  if (props.timezone !== myTimezone.value)
    return props.timezone ? timezoneDataByKey[props.timezone] : null;
  return null;
});
</script>

<template>
  <div :title="`${convertedTime} ${gmtString}`" class="flex-center gap-1 w-max">
    <span>
      {{ convertedTime }}
    </span>
    <span v-if="timezoneData" class="flex-center text-xs">
      <SynIcon :name="timezoneData?.flag" custom-class="w-3 h-3 mt-0.5" />
    </span>
  </div>
</template>
