<script setup lang="ts">
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    readonly?: boolean;
    customClass?: string;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const codeInputValue = ref<string>(props.modelValue);

const inputRef = ref<any>(null);
const dropdownRef = ref<any>(null);

const listenOpenDropdown = (isOpen) => {
  if (isOpen) {
    setTimeout(() => {
      inputRef.value?.focus();
    });
  } else {
    emit('update:modelValue', codeInputValue.value);
  }
};

const onForceClose = () => {
  dropdownRef.value?.onForceClose();
};
</script>
<template>
  <VigDropdown
    ref="dropdownRef"
    placement="top"
    @click.stop="() => {}"
    @update:is-open="listenOpenDropdown"
  >
    <template #dropdown-toggle>
      <div
        class="
          text-xs
          rounded
          flex-center
          text-white
          shrink-0
          px-1.5
          min-w-[1.5rem]
          cursor-pointer
          h-4
        "
        :class="customClass ? customClass : 'bg-gray-500'"
      >
        {{ modelValue }}
      </div>
    </template>
    <template v-if="!readonly" #dropdown-menu>
      <div class="p-4">
        <SynInput
          ref="inputRef"
          v-model="codeInputValue"
          autofocus
          :placeholder="$t('COMMON_LABEL_CODE')"
          @enter="onForceClose"
        />
      </div>
    </template>
  </VigDropdown>
</template>
