<script setup lang="ts">
import { computed } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskBoardCardDeadline from '@/ui/modules/task/board/board-container/TaskBoardCardDeadline.vue';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { TaskByDeadlineByKey } from '@/application/constants/task.const';

withDefaults(
  defineProps<{
    currentContactId: any;
    type: 'USER' | 'GROUP' | 'DOMAIN' | 'IA';
  }>(),
  {
    currentContactId: getCurrentUserId(),
    type: 'USER',
  }
);
const listStates = computed<ICustomTaskFilter[]>(() => {
  if (!TaskByDeadlineByKey) return [];
  const stateList = Object.values(TaskByDeadlineByKey).sort((a, b) => {
    return a.index - b.index;
  });
  return stateList?.map((o, index) => {
    return {
      id: o.key,
      name: translate(o.nameCode),
      index,
      color: o.colorHex,
      visible: true,
      filterFunc: o.filterFunc,
      scheduleTime: o.scheduleTime,
      filterObject: o.filterObject,
    };
  });
});
</script>

<template>
  <div
    v-drag-to-scroll="{ targetClass: 'drag-to-scroll-target' }"
    class="h-full flex overflow-auto"
  >
    <template v-for="card in listStates" :key="card">
      <TaskBoardCardDeadline
        class="text-xs mx-1.5"
        :type="type"
        :card-id="card?.id"
        :card-information="card"
        :source-id="currentContactId"
      />
    </template>
  </div>
</template>
