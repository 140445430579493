<script setup lang="ts">
import { computed, ref } from 'vue';
// import { v4 as uuidv4 } from 'uuid';
import organizationStore from '@/store/organization';
import myProfileStore from '@/store/auth/my-profile';
import {
  EOrganizationModel,
  IOrganizationInfoPayload,
} from '@/application/types/organization/organization.type';
import inputValueComp from '@/ui/common/composables/form/input-value-comp';
import { dataByOrganizationModel } from '@/application/constants/register.const';
import { companySizeRange } from '@/ui/common/constants/org';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';
import userStore from '@/store/user';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  doNotNeedToCheckPersonal: boolean;
  typeKey: 'workspace_type_select' | 'workspace_type_select_register';
  notAllowPersonal?: boolean;
}>();
const emit = defineEmits(['onCancel', 'onFinish']);
const _organizationStore = organizationStore();
const _userStore = userStore();

const isCreateFetching = ref(false);

const infoPayload = ref<IOrganizationInfoPayload>({
  name: '',
  logo: '',
  model: EOrganizationModel.PERSONAL,
  type: '',
  size: '',
  address: '',
  addressDetail: '',
  phone: '',
  websiteUrl: '',
  taxCode: '',
  subType: '',
  personalType: props.notAllowPersonal ? 1 : undefined,
});
const { value: organizationName, errorMessage: nameError } = inputValueComp(
  (value) => {
    if (!value?.trim()) return 'COMMON_THIS_FIELD_REQUIRED';
    return '';
  }
);

const organizationTypes = computed(() => _organizationStore.organizationTypes);
// const currentOrganizationInfo = computed<any>(
//   () => _organizationStore.organizationInfo
// );
// const isShowFullSize = ref<boolean>(false);
const memberSizeRangeByMode = computed<any>(() => {
  // if (infoPayload.value?.model == 'TEAM') return teamSizeRange;
  // if (isShowFullSize.value) return companySizeRange;
  // return teamSizeRange?.slice(0, 4);
  return companySizeRange;
});

enum REGISTER_STEP_KEYS {
  FIRST_STEP = 'FIRST_STEP',
  ORGANIZATION_SETUP = 'ORGANIZATION_SETUP',
  PERSONAL_SETUP = 'PERSONAL_SETUP',
}

const isShowError = ref<boolean>(false);

const {
  currentStep,
  stepperByKey,
  updateCurrentStep,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    [REGISTER_STEP_KEYS.FIRST_STEP]: {
      key: REGISTER_STEP_KEYS.FIRST_STEP,
      previous: '',
      previousAction: () => {},
      nextAction: () => {
        if (
          infoPayload.value.size !== '1' ||
          (infoPayload.value.size == '1' && infoPayload.value.personalType == 1)
        ) {
          infoPayload.value.model = EOrganizationModel.COMPANY;

          updateCurrentStep(REGISTER_STEP_KEYS.ORGANIZATION_SETUP);
        } else {
          infoPayload.value.model = EOrganizationModel.PERSONAL;

          updateCurrentStep(REGISTER_STEP_KEYS.PERSONAL_SETUP);
        }
      },
      buttonBack: '',
      buttonNext: 'COMMON_LABEL_NEXT',
    },
    [REGISTER_STEP_KEYS.ORGANIZATION_SETUP]: {
      key: REGISTER_STEP_KEYS.ORGANIZATION_SETUP,
      previous: REGISTER_STEP_KEYS.FIRST_STEP,
      previousAction: () => {
        isCreateFetching.value = false;
      },
      nextAction: () => {
        if (!infoPayload.value.model) {
          isShowError.value = true;
          return;
        }
        onSubmit();
      },
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_FINISH',
    },
    [REGISTER_STEP_KEYS.PERSONAL_SETUP]: {
      key: REGISTER_STEP_KEYS.PERSONAL_SETUP,
      previous: REGISTER_STEP_KEYS.FIRST_STEP,
      previousAction: () => {
        isCreateFetching.value = false;
        isShowError.value = false;
      },
      nextAction: () => {
        onSubmit();
      },
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_FINISH',
    },
  },
  REGISTER_STEP_KEYS.FIRST_STEP
);

const isDisableNextButton = computed(() => {
  if (currentStep.value == REGISTER_STEP_KEYS.FIRST_STEP) {
    if (!organizationName.value || !infoPayload.value.size) return true;

    if (infoPayload.value.size == '1') return !infoPayload.value.personalType;

    return false;
  }
  if (currentStep.value == REGISTER_STEP_KEYS.ORGANIZATION_SETUP) {
    return !infoPayload.value.type;
  }

  return false;
});

const onSubmit = async () => {
  isCreateFetching.value = true;
  const data = {
    ...infoPayload.value,
    name: organizationName.value,
    model: infoPayload.value?.model || EOrganizationModel.PERSONAL,
  };

  _organizationStore.updateDecideTime(12);
  await _organizationStore.updateAndFinishSetupOrganization(data);
  myProfileStore().fetchCurrentLoginUser();
  isCreateFetching.value = false;
  emit('onFinish', data);
};

const isShowOtherType = ref(false);
const onChangeOrganizationType = (type, index) => {
  infoPayload.value.type = type;

  isShowOtherType.value = index == organizationTypes.value?.length - 1;
};
const avatarFile = ref();
const isAddAvatar = ref(false);

const onUploadAvatar = async (file) => {
  if (!file) return;

  avatarFile.value = file;

  let fileName = '';

  try {
    const result = await _userStore.uploadAvatarAws(file);
    fileName = result?.path;
    // info.value.avatar = fileName.value;
    // infoUserUpdate.value.avatar = fileName.value;
  } catch (error) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('COMMON_ERROR_UPLOAD_AVATAR'),
      confirmable: true,
      closeable: true,
    });
    ask();
  }

  if (!fileName) return;

  try {
    await _userStore.updateAvatarUser(
      myProfileStore().myProfile?.id,
      fileName,
      undefined
    );

    isAddAvatar.value = true;
  } catch (error) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('COMMON_ERROR_UPLOAD_AVATAR'),
      confirmable: true,
      closeable: true,
    });
    ask();
  }
};
</script>

<template>
  <teleport to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
        z-50
      "
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div
        class="
          p-8
          mx-auto
          border-current
          flex
          items-center
          flex-col
          gap-8
          justify-between
          bg-white
          rounded-2xl
          relative
        "
        style="max-width: 50rem"
      >
        <div class="flex-1 flex flex-col space-y-6 items-center text-gray-800">
          <!-- Body -->
          <div
            class="absolute -top-10 w-20 h-20 z-60 flex-center rounded-full"
            :style="{
              background:
                dataByOrganizationModel[infoPayload?.model]?.background,
            }"
          >
            <SynIcon
              :name="
                currentStep == REGISTER_STEP_KEYS.FIRST_STEP
                  ? 'workspace'
                  : infoPayload?.model?.toLowerCase()
              "
              custom-class="w-12 h-12 fill-white"
            />
          </div>
          <template v-if="currentStep == REGISTER_STEP_KEYS.FIRST_STEP">
            <!-- REGISTER_STEP_KEYS.FIRST_STEP -->

            <div class="flex-center flex-col space-y-4 px-8">
              <div class="w-full text-center">
                <span>
                  {{ $t('REGISTER_LABEL_CREATE_YOUR_WORKSPACE') }}
                </span>
              </div>
              <form
                ref="formRef"
                action="#"
                class="
                  mt-6
                  flex flex-col
                  space-y-3
                  overflow-hidden
                  small-scrollbar
                "
                style="width: 26rem"
              >
                <!--  NAME -->
                <div class="w-full flex flex-col space-y-1">
                  <SynFormInput :error-message="nameError">
                    <SynInput
                      ref="organizationNameRef"
                      v-model="organizationName"
                      custom-class="text-sm"
                      autofocus
                      :placeholder="
                        $t('REGISTER_LABEL_COMPANY_NAME_TEAM_NAME_WORKSPACE') ||
                        ''
                      "
                      :error-message="nameError"
                    />
                  </SynFormInput>
                </div>

                <!--  SIZE        -->
                <div class="my-2 flex flex-col space-y-1">
                  <div class="flex items-center text-sm w-44 text-gray-600">
                    <span>
                      {{
                        $t(
                          infoPayload.model == 'TEAM'
                            ? 'ORGANIZATION_LABEL_TEAM_SIZE'
                            : 'ORGANIZATION_LABEL_ORGANIZATION_SIZE'
                        )
                      }}
                      *
                    </span>
                  </div>

                  <SynFormInput>
                    <div
                      class="flex items-center flex-wrap gap-1"
                      style="width: 26rem"
                    >
                      <div
                        class="
                          w-max
                          flex-center
                          rounded-full
                          px-3
                          py-1.5
                          cursor-pointer
                          text-xs
                          mb-2
                        "
                        :class="
                          infoPayload.size === '1'
                            ? 'text-white bg-current-500  '
                            : 'border border-gray-300'
                        "
                        style="min-width: 4rem"
                        @click="infoPayload.size = '1'"
                      >
                        1
                      </div>
                      <template
                        v-for="range in memberSizeRangeByMode"
                        :key="range.key"
                      >
                        <div
                          class="
                            w-max
                            flex-center
                            rounded-full
                            px-3
                            py-1.5
                            cursor-pointer
                            text-xs
                            mb-2
                          "
                          :class="
                            infoPayload.size === range.value
                              ? 'text-white bg-current-500  '
                              : 'border border-gray-300'
                          "
                          style="min-width: 4rem"
                          @click="infoPayload.size = range.value"
                        >
                          {{ range.key }}
                        </div>
                      </template>
                    </div>
                  </SynFormInput>
                </div>
                <!--  PERSONAL TYPE        -->
                <div
                  v-if="infoPayload?.size == '1' && !notAllowPersonal"
                  class="flex flex-col gap-2"
                >
                  <SynRadio
                    v-model="infoPayload.personalType"
                    class="bg-gray-50 hover:bg-gray-100 p-2 rounded-md"
                    :value="1"
                  >
                    <span
                      v-html="$t('REGISTER_LABEL_PERSONAL_TYPE_1_DESCRIPTION')"
                    >
                    </span>
                  </SynRadio>
                  <SynRadio
                    v-model="infoPayload.personalType"
                    class="bg-gray-50 hover:bg-gray-100 p-2 rounded-md"
                    :value="2"
                  >
                    <span
                      v-html="$t('REGISTER_LABEL_PERSONAL_TYPE_2_DESCRIPTION')"
                    >
                    </span>
                  </SynRadio>
                </div>
              </form>
            </div>
          </template>
          <template
            v-else-if="currentStep == REGISTER_STEP_KEYS.ORGANIZATION_SETUP"
          >
            <!-- REGISTER_STEP_KEYS.ORGANIZATION_SETUP -->
            <div class="flex flex-col gap-4">
              <div class="flex-center w-full flex-wrap">
                <div
                  v-for="(item, index) in organizationTypes"
                  :key="index"
                  class="w-max p-2"
                >
                  <SynCheckboxLabel
                    class="text-base"
                    :is-check="infoPayload.type === item.code"
                    :text="item.name"
                    @click="onChangeOrganizationType(item.code, index)"
                  />
                </div>
              </div>
              <div class="flex-center">
                <div class="w-max">
                  <SynInput
                    v-if="isShowOtherType"
                    v-model="infoPayload.subType"
                    class="w-96"
                    :max-length="100"
                    :placeholder="
                      $t('SETUP_ORGANIZATION_TELL_US_YOUR_BUSINESS')
                    "
                  />
                </div>
              </div>
            </div>
          </template>
          <template
            v-else-if="currentStep == REGISTER_STEP_KEYS.PERSONAL_SETUP"
          >
            <!-- REGISTER_STEP_KEYS.PERSONAL_SETUP -->
            <div class="text-center">
              {{ $t('FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_ADD_AVATAR') }}
            </div>

            <UploadAvatarIcon
              is-show-button
              class="mt-6"
              :file="avatarFile"
              :name="organizationName?.trim() || infoPayload?.model"
              size="medium"
              :text-style="`font-size: 2.5rem; font-weight: 600;`"
              type-upload="GROUP"
              type="group"
              z-index="z-50"
              :is-update-success="isAddAvatar"
              @upload-avatar="onUploadAvatar"
            />
          </template>
        </div>

        <div class="w-full flex justify-between items-center space-x-2">
          <div class="flex items-center gap-4 relative">
            <template
              v-if="currentStep == REGISTER_STEP_KEYS.ORGANIZATION_SETUP"
            >
              <span class="text-sm"
                >{{ $t('COMMON_LABEL_ORGANIZATION') }} *
              </span>
              <div class="flex-center gap-2">
                <SynRadio
                  v-model="infoPayload.model"
                  class="bg-gray-50 hover:bg-gray-100 py-1 px-2 rounded-md"
                  :value="EOrganizationModel.COMPANY"
                >
                  <span class="text-sm">
                    {{ $t('REGISTER_LABEL_ORGANIZATION_TYPE_1') }}
                  </span>
                </SynRadio>
                <SynRadio
                  v-model="infoPayload.model"
                  class="bg-gray-50 hover:bg-gray-100 py-1 px-2 rounded-md"
                  :value="EOrganizationModel.TEAM"
                >
                  <span class="text-sm">
                    {{ $t('REGISTER_LABEL_ORGANIZATION_TYPE_2') }}
                  </span>
                </SynRadio>
              </div>
            </template>
            <span
              v-if="isShowError"
              class="text-red-500 text-xs absolute left-0 -bottom-4"
            >
              * {{ $t('REGISTER_LABEL_PLEASE_SELECT_YOUR_ORGANIZATION_TYPE') }}
            </span>
          </div>
          <div class="flex-center gap-2">
            <AtomButton
              v-if="stepperByKey[currentStep]['buttonBack']"
              type="TEXT"
              color="gray"
              :label="$t(stepperByKey[currentStep]['buttonBack'])"
              @click="onHandleGoToPreviousStep"
            />
            <AtomButton
              :label="$t(stepperByKey[currentStep]['buttonNext'])"
              :disabled="isDisableNextButton"
              :is-loading="isCreateFetching"
              @click="onHandleGoToNextStep"
            />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
