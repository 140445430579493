<template>
  <vig-dropdown append-to-body :z-index="50" @on-dropdown-open="onDropdownOpen">
    <template #dropdown-toggle>
      <vig-button
        v-vig-tooltip="$t('COMMON_LABEL_FILTERS')"
        ghost
        :color="filterCount ? 'current' : 'gray'"
        padding="p-0"
        rounded="rounded"
        class="w-9 h-9 relative"
      >
        <SynIcon name="Filter" custom-class="w-5 h-5" />
        <div
          v-if="filterCount"
          class="
            absolute
            -top-1
            -right-0.5
            bg-current-500
            text-white
            ring-1 ring-white
            text-xs
            font-semibold
            rounded-full
            w-5
            h-5
            flex-center
          "
        >
          {{ filterCount }}
        </div>
      </vig-button>
    </template>
    <template #dropdown-menu>
      <div class="w-80">
        <div class="p-1 border-b flex items-center justify-between">
          <span class="w-8"></span>
          <span class="text-current-600 font-semibold">{{
            $t('TASK_FILTER_LABEL_FILTER')
          }}</span>
          <button
            class="
              w-8
              h-8
              flex-center
              rounded-full
              bg-gray-100
              hover:bg-gray-200
              dropdown-item
            "
          >
            <SynIcon name="Close" custom-class="w-5 h-5" />
          </button>
        </div>
        <div class="px-3 py-2">
          <div class="flex space-x-2">
            <div class="flex-1">
              <div class="text-gray-500 mb-1">
                {{ $t('GED_FILTER_DATE_FROM') || 'Date from' }}
              </div>
              <AtomDatePicker
                v-model="filterObj.dateFrom"
                :max-date="filterObj.dateTo"
              />
            </div>
            <div class="flex-1">
              <div class="text-gray-500 mb-1">
                {{ $t('GED_FILTER_DATE_TO') || 'to' }}
              </div>
              <AtomDatePicker
                v-model="filterObj.dateTo"
                :min-date="filterObj.dateFrom"
              />
            </div>
          </div>
          <div v-if="isFilterType">
            <div class="text-gray-500 mb-1">
              {{ $t('GED_FILTER_FILE_TYPE') || 'File type' }}
            </div>
            <div class="flex flex-wrap">
              <button
                v-for="type of fileTypes"
                :key="type"
                class="px-3 py-1.5 mb-1 mr-1 rounded-full border"
                :class="
                  filterObj?.fileTypes[type?.value]
                    ? 'border-gray-500 bg-gray-500 text-white'
                    : ''
                "
                @click="onFileTypeClick(type)"
              >
                {{ $t(type?.text) || type?.text }}
              </button>
            </div>
          </div>
        </div>
        <div class="px-3 py-2 text-right">
          <button
            class="
              px-3
              py-1.5
              rounded
              bg-white
              border border-current-500
              hover:bg-current-50
              text-current-500 text-sm
              mr-2
              dropdown-item
            "
            @click="onResetClick"
          >
            {{ $t('COMMON_LABEL_RESET') || 'Reset' }}
          </button>
          <button
            class="
              px-3
              py-1.5
              rounded
              bg-current-500
              hover:bg-current-600
              text-white text-sm
              dropdown-item
            "
            @click="onApplyClick"
          >
            {{ $t() || 'OK' }}
          </button>
        </div>
      </div>
    </template>
  </vig-dropdown>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import dayjs from 'dayjs';

export default defineComponent({
  components: { VigDropdown },
  props: {
    filter: {
      type: Object,
      default: null,
    },
    isFilterType: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onApplyFilter'],
  setup(props, { emit }) {
    const dateConfig = ref({ type: 'string', mask: 'YYYY-MM-DD' });
    const fileTypes = ref([
      { value: 'FOLDER', text: 'GED_FOLDER' },
      { value: 'IMAGE', text: 'GED_FILE_PHOTO' },
      { value: 'AUDIO', text: 'GED_FILE_AUDIO' },
      { value: 'VIDEO', text: 'GED_FILE_VIDEO' },
      { value: 'PDF', text: 'GED_FILE_PDF' },
      { value: 'DOC', text: 'GED_FILE_DOCUMENT' },
      { value: 'EXCEL', text: 'GED_FILE_SPREADSHEETS' },
      { value: 'PPTX', text: 'GED_FILE_PRESENTATION' },
    ]);

    const filterObj = ref({ dateFrom: null, dateTo: null, fileTypes: {} });
    const filterCount = computed(() => {
      const { dateFrom, dateTo, fileTypes } = props.filter || {};
      return Object.values({ dateFrom, dateTo, fileTypes })
        .reduce((arr, val) => arr.concat(val), [])
        .filter((val) => val).length;
    });

    const onDropdownOpen = () => {
      filterObj.value = {
        dateFrom: props.filter?.dateFrom
          ? dayjs(props.filter?.dateFrom).format('YYYY-MM-DD')
          : null,
        dateTo: props.filter?.dateTo
          ? dayjs(props.filter?.dateTo).format('YYYY-MM-DD')
          : null,
        fileTypes: (props.filter?.fileTypes || []).reduce((obj, type) => {
          obj[type] = true;
          return obj;
        }, {}),
      };
    };

    const onFileTypeClick = (type) => {
      if (!filterObj.value?.fileTypes) return;

      filterObj.value.fileTypes[type?.value] =
        !filterObj.value.fileTypes[type?.value];
    };

    const onApplyClick = () => {
      emit('onApplyFilter', {
        dateFrom: filterObj.value?.dateFrom
          ? dayjs(filterObj.value?.dateFrom).startOf('day').format()
          : null,
        dateTo: filterObj.value?.dateTo
          ? dayjs(filterObj.value?.dateTo).endOf('day').format()
          : null,
        fileTypes: fileTypes.value
          ?.filter((type) => filterObj.value?.fileTypes[type?.value])
          .map((type) => type.value),
      });
    };

    const onResetClick = () => {
      filterObj.value = { dateFrom: null, dateTo: null, fileTypes: {} };

      emit('onApplyFilter', {
        dateFrom: null,
        dateTo: null,
        fileTypes: [],
      });
    };

    return {
      dateConfig,
      fileTypes,
      filterObj,
      filterCount,
      onDropdownOpen,
      onFileTypeClick,
      onApplyClick,
      onResetClick,
    };
  },
});
</script>
