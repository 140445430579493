<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g>
        <path
          d="m68 456c0 22 18 40 40 40h296c22 0 40-18 40-40v-400c0-22-18-40-40-40h-156c-22 0-40 18-40 40v80c0 22-18 40-40 40h-72.8c-15.2 0-27.2-12.4-27.2-27.2z"
          fill="#ffffff"
          data-original="#ffffff"
          class=""
        ></path>
        <path
          d="m95.2 256h112.8c22 0 40-18 40-40v-160c0-22 18-40 40-40h-40c-22 0-40 18-40 40v80c0 22-18 40-40 40h-72.8c-15.2 0-27.2-12.4-27.2-27.2v80c0 14.8 12.4 27.2 27.2 27.2z"
          fill="#aeb3b7"
          data-original="#aeb3b7"
          class=""
        ></path>
        <g fill="#444b54">
          <path
            d="m364 388h-136c-6.8 0-12-5.2-12-12s5.2-12 12-12h136c6.8 0 12 5.2 12 12s-5.2 12-12 12z"
            fill="#444b54"
            data-original="#444b54"
            class=""
          ></path>
          <path
            d="m168 388h-20c-6.8 0-12-5.2-12-12s5.2-12 12-12h20c6.8 0 12 5.2 12 12s-5.2 12-12 12z"
            fill="#444b54"
            data-original="#444b54"
            class=""
          ></path>
          <path
            d="m364 328h-136c-6.8 0-12-5.2-12-12s5.2-12 12-12h136c6.8 0 12 5.2 12 12s-5.2 12-12 12z"
            fill="#444b54"
            data-original="#444b54"
            class=""
          ></path>
          <path
            d="m168 328h-20c-6.8 0-12-5.2-12-12s5.2-12 12-12h20c6.8 0 12 5.2 12 12s-5.2 12-12 12z"
            fill="#444b54"
            data-original="#444b54"
            class=""
          ></path>
          <path
            d="m364 268h-136c-6.8 0-12-5.2-12-12s5.2-12 12-12h136c6.8 0 12 5.2 12 12s-5.2 12-12 12z"
            fill="#444b54"
            data-original="#444b54"
            class=""
          ></path>
          <path
            d="m168 268h-20c-6.8 0-12-5.2-12-12s5.2-12 12-12h20c6.8 0 12 5.2 12 12s-5.2 12-12 12z"
            fill="#444b54"
            data-original="#444b54"
            class=""
          ></path>
          <path
            d="m404 508h-296c-28.8 0-52-23.2-52-52v-220c0-6.8 5.2-12 12-12s12 5.2 12 12v220c0 15.6 12.4 28 28 28h296c15.6 0 28-12.4 28-28v-400c0-15.6-12.4-28-28-28h-156c-15.6 0-28 12.4-28 28v80c0 28.8-23.2 52-52 52h-72.8c-21.6 0-39.2-17.6-39.2-39.2 0-11.6 5.2-22.4 13.6-30l74.4-64c5.2-4.4 12.8-3.6 16.8 1.2 4.4 5.2 3.6 12.8-1.2 16.8l-74.4 64c-3.2 3.2-5.2 7.2-5.2 12 0 8.4 6.8 15.2 15.2 15.2h72.8c15.6 0 28-12.4 28-28v-80c0-28.8 23.2-52 52-52h156c28.8 0 52 23.2 52 52v400c0 28.8-23.2 52-52 52z"
            fill="#444b54"
            data-original="#444b54"
            class=""
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
