<script setup lang="ts">
import {
  computed,
  onMounted,
  watch,
  // computed
} from 'vue';
import Chart from 'chart.js/auto';
import { useDocumentVisibility } from '@vueuse/core';

import { translate } from '@/ui/plugins/i18n/myi18n';

const props = withDefaults(
  defineProps<{
    customClass?: string;
    labels?: string[];
    datasets: any[];
    options: any;
    isHiddenLegend?: boolean;
    plugins?: any[];
  }>(),
  {
    customClass: '',
    labels: () => [],
    plugins: () => [],
  }
);

const visibility = useDocumentVisibility();
watch(
  () => visibility.value,
  () => {
    if (verticalBarChart && typeof verticalBarChart?.update == 'function')
      verticalBarChart?.update();
  }
);

let verticalBarChart: Chart;

const canvas_id = `tictop-common-lollipop-chart_${Math.random() * 100}`;

const lollipopChart = {
  id: 'lollipopChart',
  afterDatasetsDraw(chart) {
    const { ctx } = chart;
    ctx.save();

    for (let i = 0; i < chart.getDatasetMeta(0).data.length; i++) {
      const element = chart.getDatasetMeta(0).data[i];
      const _data = chart.getDatasetMeta(0)._dataset?.data[i];
      const { x, y } = element;
      // const bgColor = chart.getDatasetMeta(0)._dataset.backgroundColor;
      circle(x, y, _data);
    }

    function circle(xPosition, yPosition, data) {
      ctx.font = '14px sans-serif';
      ctx.fillStyle = props.options?.color || '#6b7280';
      ctx.fillText(`${data}`, xPosition - 5, yPosition - 16);
      ctx.save();

      ctx.beginPath();
      ctx.fillStyle = props.options?.color || '#4BB0B2';
      ctx.arc(xPosition, yPosition, 10, 0, Math.PI * 2, false);
      ctx.fill();
      ctx.closePath();
      ctx.restore();
    }
  },
};
const initData = () => {
  const labels = [
    'COMMON_LABEL_MONDAY_VERTICAL_CHART',
    'COMMON_LABEL_TUESDAY_VERTICAL_CHART',
    'COMMON_LABEL_WEDNESDAY_VERTICAL_CHART',
    'COMMON_LABEL_THURSDAY_VERTICAL_CHART',
    'COMMON_LABEL_FRIDAY_VERTICAL_CHART',
    'COMMON_LABEL_SATURDAY_VERTICAL_CHART',
  ];
  const labelLanguages = labels.map((e) => translate(e));
  const data = {
    labels: props.labels || labelLanguages,
    datasets: props.datasets,
  };
  const config = computed(() => {
    return {
      type: 'bar',
      data: data,
      options: {
        locale: 'de',

        plugins: {
          legend: { display: !props?.isHiddenLegend },
        },
        ...props.options,
      },
      plugins: [lollipopChart, ...props.plugins],
    };
  });

  const ctx = document.getElementById(canvas_id) as HTMLCanvasElement;

  if (!ctx) return;

  verticalBarChart = new Chart(ctx, config.value);
};

onMounted(() => {
  initData();
});

watch(
  () => props.datasets,
  () => {
    verticalBarChart.data.datasets = props.datasets;
    // verticalLineChart.data.datasets
    verticalBarChart?.update();
  },
  { deep: true }
);

const hasData = computed<boolean>(() => {
  return props.datasets?.some((dataset) => {
    return dataset?.data?.filter((o) => o)?.length > 0;
  });
});
</script>
<template>
  <div class="w-full h-full flex-center space-x-4">
    <div class="w-full h-full relative" :class="customClass">
      <canvas v-show="hasData" :id="canvas_id" class="w-full h-full"></canvas>

      <div v-if="!hasData" class="w-full h-full flex-center">
        <VigImage
          path="https://d1tvqetrcurhzb.cloudfront.net/web/charts/lollipop-chart.png"
          object-fit="contain"
          class="h-full w-full"
        />
      </div>
    </div>
  </div>
</template>
