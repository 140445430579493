<script setup lang="ts">
import UserById from '@/ui/components/user/UserById.vue';
import UpdateDomainDropdown from '@/ui/modules/task/detail/UpdateDomainDropdown.vue';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';

const props = defineProps<{
  formData: {
    assigneeId: number;
    groupId: number;
    domainId: number;
    projectId: number;
    subprojectId: number;
  };
  modelValue: {
    assigneeId: number;
    groupId: number;
    domainId: number;
    projectId: number;
    subprojectId: number;
    isSelecting: boolean;
    id?: number;
    name?: string;
  }[];
  isSubTasks?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', data: any[]): void;
}>();

const onRemove = (dataItem: any) => {
  if (props.isSubTasks)
    emit(
      'update:modelValue',
      props.modelValue?.map((o) => {
        return {
          ...o,
          isSelecting: o?.id == dataItem?.id ? false : o?.isSelecting,
        };
      })
    );
  else
    emit(
      'update:modelValue',
      props.modelValue?.filter((o) => o?.assigneeId !== dataItem?.assigneeId)
    );
};
const onSelect = (dataItem: any) => {
  emit(
    'update:modelValue',
    props.modelValue?.map((o) => {
      return {
        ...o,
        isSelecting: o?.id == dataItem?.id ? true : o?.isSelecting,
      };
    })
  );
};

const onSaveDomain = (data) => {
  emit(
    'update:modelValue',
    props.modelValue?.map((o) => {
      return o?.assigneeId == data?.assigneeId
        ? {
            ...o,
            ...data,
          }
        : o;
    })
  );
};
</script>

<template>
  <div class="w-full h-full flex flex-col">
    <template v-for="item in modelValue" :key="item">
      <div
        v-if="item?.assigneeId"
        class="
          hover-to-show__parent
          rounded-md
          hover:bg-gray-100
          min-h-[3rem]
          flex
          items-center
          gap-2
          px-2
        "
      >
        <div class="flex-1 flex items-center">
          <template v-if="isSubTasks">
            <div
              class="flex-1"
              :class="item?.isSelecting ? '' : 'line-through'"
            >
              {{ item?.name }}
            </div>
            <div>
              <UserById
                :user-id="item?.assigneeId"
                is-hidden-name
                avatar-class="h-5 w-5"
              />
            </div>
          </template>
          <template v-else>
            <div class="w-1/2">
              <UserById :user-id="item?.assigneeId" />
            </div>
            <UpdateDomainDropdown
              v-if="formData?.domainId || item.domainId"
              class="p-0 h-full flex-center"
              :task-detail="item"
              @on-save="onSaveDomain"
            >
              <DomainProjectById
                :key="`${item.domainId}_${item.projectId}_${item.subprojectId}`"
                class="h-full"
                :domain-id="item.domainId"
                :project-id="item.projectId"
                :sub-project-id="item.subprojectId"
              />
            </UpdateDomainDropdown>
          </template>
        </div>
        <div v-if="isSubTasks" class="flex-center w-16">
          <div
            v-if="item?.isSelecting"
            class="
              w-8
              h-8
              flex-center
              cursor-pointer
              rounded-full
              hover:bg-current-50
            "
            @click="onRemove(item)"
          >
            <div class="cursor-pointer h-4 w-4 flex-center rounded">
              <SynIcon name="close" class="fill-gray-500" />
            </div>
          </div>
          <template v-else>
            <div
              class="
                w-8
                h-8
                flex-center
                cursor-pointer
                rounded-full
                hover:bg-current-50
              "
              @click="onSelect(item)"
            >
              <div class="cursor-pointer h-4 w-4 flex-center rounded">
                <SynIcon name="undo" class="fill-gray-500" />
              </div>
            </div>
          </template>
        </div>
        <div v-else class="flex-center">
          <div
            class="
              w-8
              h-8
              flex-center
              cursor-pointer
              rounded-full
              hover:bg-current-50
            "
            @click="onRemove(item)"
          >
            <div class="cursor-pointer h-4 w-4 flex-center rounded">
              <SynIcon name="close" class="fill-gray-500" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
