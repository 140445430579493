<script setup lang="ts">
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
defineProps<{
  uploadingFiles: any[];
}>();

defineEmits<{
  (e: 'onSelectNote', file: any): void;
  (e: 'reUpload', file: any): void;
}>();

const getConjectureUploadSpeedBySize = () => {
  return Math.random() * 10;
};
</script>

<template>
  <div
    v-for="(file, index) in uploadingFiles"
    :key="index"
    class="relative w-max h-16"
    v-bind="$attrs"
  >
    <UploadPreview :file="file" :is-note="file.isNote" readonly is-hidden-info>
      <div
        class="w-full h-full absolute top-0 rounded-md flex-center"
        @click="file.isNote && $emit('reUpload', file)"
      >
        <div
          class="
            w-full
            h-full
            rounded-md
            flex-center
            hover-to-show__parent
            hover-to-hidden__parent
            bg-opacity-50
          "
          :class="
            file?.isUploadedFail
              ? 'bg-red-200 cursor-pointer'
              : 'bg-gray-100 animate-pulse'
          "
        >
          <template v-if="file?.isUploadedFail">
            <div
              class="w-8 h-8 flex-center bg-white rounded-full opacity-100"
              @click.stop="$emit('reUpload', file)"
            >
              <SynIcon
                name="warning"
                class="fill-red-500 hover-to-hidden__children"
              />
              <SynIcon
                name="upload"
                class="fill-blue-500 hover-to-show__children"
              />
            </div>
          </template>
        </div>
      </div>
    </UploadPreview>

    <AtomProcess
      v-if="!file?.isUploadedFail"
      :percent="1"
      :max-percent="90"
      :speed="getConjectureUploadSpeedBySize()"
      is-auto-run
      class="absolute -bottom-1"
    />
  </div>
</template>
