import {
    DayoffStatus,
    DayoffActionType,
    DayoffRole,
} from '@/domain/enums/DayoffEnums';

export const DayoffTypeUI = {
    SCHEDULE: {
        color: '#4bb0b2',
        backgroundColor: '#b1dcde',
        textDefault: 'Schedule',
        textCode: 'DAYOFF_TYPE_SCHEDULE',
        iconName: 'event-day-off',
    },
    WFH: {
        color: '#3b82f6',
        backgroundColor: 'rgb(59,130,246,0.6)',
        textDefault: 'Work From Home',
        textCode: 'DAYOFF_TYPE_WFH',
        iconName: 'wfh-day-off',
    },
    EXCEPTION: {
        color: '#64748b',
        backgroundColor: '#f1f5f9',
        textDefault: 'Exception',
        textCode: 'DAYOFF_TYPE_EXCEPTION',
        iconName: 'break-day-off',
    },
};

export const DayoffStatusUI = {
    PENDING: {
        // color: '#ea580c',
        // backgroundColor: 'rgb(253, 186, 116, 1)',
        color: '#f97316',
        // backgroundColor:
        //     'repeating-linear-gradient(300deg, rgb(255, 228, 200), rgb(255, 228, 200) 7%, rgb(255, 234, 213) 7%, rgb(255, 234, 213) 12%)',
        backgroundColor:
            'repeating-linear-gradient(300deg, rgb(255, 228, 200) 0, rgb(255, 228, 200) 0.5vw, rgb(255, 234, 213) 0.5vw, rgb(255, 234, 213) 1vw)',
        backgroundColorForChat:
            'repeating-linear-gradient(rgb(255, 228, 200), rgb(255, 234, 213))',
        backgroundIcon: '#fdba74',
        typeColor: 'orange',
        backgroundColorDot: 'bg-orange-400',
        className: 'text-pink-500 border-pink-500',
        textDefault: 'PENDING',
        textCode: 'DAYOFF_STATUS_PENDING',
        actionTypeList: [
            DayoffActionType.CANCEL,
            DayoffActionType.REJECT,
            DayoffActionType.ACCEPT,
        ],
        isChecked: false,
    },
    ACCEPTED: {
        // color: '#4bb0b2',
        // backgroundColor: 'rgb(127, 198, 201, 1)',
        color: '#008e8e',
        backgroundColor: '#e0f1f2',
        backgroundIcon: '#b1dcde',
        typeColor: 'current',
        backgroundColorDot: 'bg-current-400',
        className: 'text-green-500 border-green-500',
        textDefault: 'ACCEPTED',
        textCode: 'DAYOFF_STATUS_ACCEPTED',
        actionTypeList: [
            DayoffActionType.CANCEL,
            DayoffActionType.MARK_UNUSED,
            DayoffActionType.CLOSE,
        ],
        isChecked: false,
    },
    REJECTED: {
        // color: '#ef4444',
        // backgroundColor: 'rgb(252, 165, 165, 1)',
        color: '#ef4444',
        backgroundColor: '#fee2e2',
        backgroundIcon: '',
        typeColor: 'red',
        backgroundColorDot: 'bg-red-400',
        className: 'text-red-500 border-red-400',
        textDefault: 'REJECTED',
        textCode: 'DAYOFF_STATUS_REJECTED',
        actionTypeList: [],
        isChecked: false,
    },
    CANCELLED: {
        // color: '#6b7280',
        // backgroundColor: 'rgb(209, 213, 219, 1)',
        color: '#6b7280',
        backgroundColor: '#e5e7eb',
        backgroundIcon: '',
        typeColor: 'gray',
        backgroundColorDot: 'bg-gray-500',
        className: 'text-gray-500 border-gray-400',
        textDefault: 'CANCELLED',
        textCode: 'DAYOFF_STATUS_CANCELLED',
        actionTypeList: [],
        isChecked: false,
    },
    MARK_UNUSED: {
        // color: '#3b82f6',
        // backgroundColor: 'rgb(147, 197, 253, 1)',
        color: '#6b7280',
        backgroundColor: '#e5e7eb',
        backgroundIcon: '',
        typeColor: 'blue',
        backgroundColorDot: 'bg-blue-400',
        className: 'text-pink-500 border-pink-500',
        textDefault: 'MARK_UNUSED',
        textCode: 'DAYOFF_STATUS_MARK_UNUSED',
        actionTypeList: [
            DayoffActionType.CLOSE,
            DayoffActionType.DISAGREE,
            DayoffActionType.AGREE,
        ],
        isChecked: false,
    },
    CLOSED: {
        // color: '#6b7280',
        // backgroundColor: 'rgb(209, 213, 219, 1)',
        color: '#6b7280',
        backgroundColor: '#e5e7eb',
        backgroundIcon: '',
        typeColor: 'gray',
        backgroundColorDot: 'bg-gray-500',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'CLOSED',
        textCode: 'DAYOFF_STATUS_CLOSED',
        actionTypeList: [DayoffActionType.RE_OPEN],
        isChecked: false,
    },
};

export const DayoffActionTypeUI = {
    SUBMIT: {
        color: '#4bb0b2',
        backgroundColor: '#b1dcde',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Request',
        textCode: 'DAYOFF_ACTION_TYPE_SUBMIT',
        role: DayoffRole.USER,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_REQUEST',
    },

    MARK_UNUSED: {
        color: '#3b82f6',
        backgroundColor: '#dbeafe',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Mark unused',
        textCode: 'DAYOFF_ACTION_TYPE_MARK_UNUSED',
        role: DayoffRole.USER,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_MARK_UNUSED',
    },
    AGREE: {
        color: '#4bb0b2',
        backgroundColor: '#b1dcde',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Agree',
        textCode: 'DAYOFF_ACTION_TYPE_AGREE',
        role: DayoffRole.ADMIN,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_AGREE',
    },
    DISAGREE: {
        color: '#f97316',
        backgroundColor: '#ffedd5',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Disagree',
        textCode: 'DAYOFF_ACTION_TYPE_DISAGREE',
        role: DayoffRole.ADMIN,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_DISAGREE',
    },
    RE_OPEN: {
        color: '#3b82f6',
        backgroundColor: '#dbeafe',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Reopen',
        textCode: 'DAYOFF_ACTION_TYPE_RE_OPEN',
        role: DayoffRole.ADMIN,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_REOPEN',
    },
    CLOSE: {
        color: '#6b7280',
        backgroundColor: '#f3f4f6',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Closed',
        textCode: 'DAYOFF_ACTION_TYPE_CLOSE',
        role: DayoffRole.USER,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_CLOSE',
    },
    CANCEL: {
        color: '#6b7280',
        backgroundColor: '#f3f4f6',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Cancel',
        textCode: 'DAYOFF_ACTION_TYPE_CANCEL',
        role: DayoffRole.USER,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_CANCEL',
    },
    REJECT: {
        color: '#f97316',
        backgroundColor: '#ffedd5',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Reject',
        textCode: 'DAYOFF_ACTION_TYPE_REJECT',
        role: DayoffRole.ADMIN,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_REJECT',
    },
    ACCEPT: {
        color: '#4bb0b2',
        backgroundColor: '#b1dcde',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'Accepted',
        textCode: 'DAYOFF_ACTION_TYPE_ACCEPT',
        role: DayoffRole.ADMIN,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_ACCEPT',
    },
    MODIFY: {
        color: '#3b82f6',
        backgroundColor: '#dbeafe',
        className: 'text-gray-500 border-gray-500',
        textDefault: 'MODIFY',
        textCode: 'DAYOFF_ACTION_TYPE_MODIFY',
        role: DayoffRole.USER,
        actionContentCode: 'DAYOFF_ACTION_TYPE_CONTENT_MODIFY',
    },
};

export const DayoffReasonUI = {
    TRAVELING: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'TRAVELING',
        textCode: 'DAYOFF_REASON_TRAVELING',
    },
    SICK: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'SICK',
        textCode: 'DAYOFF_REASON_SICK',
    },
    URGENT_WORK: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'URGENT_WORK',
        textCode: 'DAYOFF_REASON_URGENT_WORK',
    },
    VISIT_HOMETOWN: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'VISIT_HOMETOWN',
        textCode: 'DAYOFF_REASON_VISIT_HOMETOWN',
    },
    FAMILY: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'FAMILY',
        textCode: 'DAYOFF_REASON_FAMILY',
    },
    PERSONAL: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'PERSONAL',
        textCode: 'DAYOFF_REASON_PERSONAL',
    },
    OTHERS: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'OTHERS',
        textCode: 'DAYOFF_REASON_OTHERS',
    },
};
export const DayoffExceptionReasonUI = {
    TRAFFIC_JAM: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'TRAFFIC JAM',
        textCode: 'DAYOFF_REASON_TRAFFIC_JAM',
    },
    SICK: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'SICK',
        textCode: 'DAYOFF_REASON_SICK',
    },
    URGENT_WORK: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'URGENT_WORK',
        textCode: 'DAYOFF_REASON_URGENT_WORK',
    },
    OTHERS: {
        color: '#ea580c',
        backgroundColor: '#ffedd5',
        textDefault: 'OTHERS',
        textCode: 'DAYOFF_REASON_OTHERS',
    },
};
export const DayoffDateSessionUI = {
    1: {
        color: 'blue',
        textDefault: 'MORNING',
        textCode: 'DAYOFF_DATE_SESSION_MORNING',
        textLabelOffWork: 'DAYOFF_LABEL_MEMBER_OFF_MORNING',
    },
    2: {
        color: 'blue',
        textDefault: 'AFTERNOON',
        textCode: 'DAYOFF_DATE_SESSION_AFTERNOON',
        textLabelOffWork: 'DAYOFF_LABEL_MEMBER_OFF_AFTERNOON',
    },
    3: {
        color: 'blue',
        textDefault: 'ALL_DAY',
        textCode: 'DAYOFF_DATE_SESSION_ALL_DAY',
        textLabelOffWork: 'DAYOFF_LABEL_MEMBER_OFF_ALL_DAY',
    },
};
export const DayoffExceptionTypeUI = {
    START_LATE: {
        color: '#64748b',
        backgroundColor: '#f1f5f9',
        textDefault: 'START_LATE',
        textCode: 'DAYOFF_EXCEPTION_TYPE_START_LATE',
        iconName: '',
    },
    STOP_EARLY: {
        color: '#64748b',
        backgroundColor: '#f1f5f9',
        textDefault: 'STOP_EARLY',
        textCode: 'DAYOFF_EXCEPTION_TYPE_STOP_EARLY',
        iconName: '',
    },
    BREAK_TIME: {
        color: '#64748b',
        backgroundColor: '#f1f5f9',
        textDefault: 'BREAK_TIME',
        textCode: 'DAYOFF_EXCEPTION_TYPE_BREAK_TIME',
        iconName: '',
    },
};
export const DayoffDateoffStatusUI = {
    USED: {
        color: 'blue',
        textDefault: 'USED',
        textCode: 'USED',
    },
    NOT_USED: {
        color: 'blue',
        textDefault: 'NOT_USED',
        textCode: 'NOT_USED',
    },
};

export const DayoffNextStatus = {
    PENDING: DayoffStatus.ACCEPTED,
    ACCEPTED: DayoffStatus.CLOSED,
    REJECTED: '',
    CANCELLED: '',
    MARK_UNUSED: DayoffStatus.CLOSED,
    CLOSED: '',
};
