<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { getLocalStorage } from '@/ui/hooks/storageHook';
// import FormNotiUser from './usercomponent/FormNotiUser.vue';
import FormInfoUser from './usercomponent/FormInfoUser.vue';
import FormScopeUser from './usercomponent/FormScopeUser.vue';
import FormMyGroups from './usercomponent/FormMyGroups.vue';
import userStore from '@/store/user';
import authStore from '@/store/auth';
// import groupStore from '@/store/group';
import { fetchOaInfo, fetchUserFollowInfo } from '@/ui/pages/zalo/ZaloOALogic';
// import MyGroups from '@/ui/components/group/MyGroups.vue';
import permissionStore from '@/store/permission';
import WorkingTimeByUser from '@/ui/modules/working/WorkingTimeByUser.vue';

defineProps({
  idUser: {
    type: Number,
    default: 0,
  },
});
const _userStore = userStore();
const _authStore = authStore();
// const _groupStore = groupStore();
const userDefault = computed(() => _userStore.userProfile) as any;
const myProfile = computed(() => myProfileStore().myProfile);
// const allMyGroups = computed(
//   () => _groupStore.allMyGroups.filter((e) => e.isMember) || []
// );
const isPersonalModel = computed<boolean>(
  () => permissionStore().organizationModel === 'PERSONAL'
);
const isVerify = ref(true);
const numberOfSendCode = ref();
numberOfSendCode.value = getLocalStorage('NUM_SEND_CODE');
const init = async () => {
  if (!userDefault.value) {
    await _userStore.getProfileUserCurrent();
  }
};

onMounted(async () => {
  var promise = [init()];
  Promise.all(promise).then(async () => {
    fetchOaInfo(), fetchUserFollowInfo();
    const res = ref();
    if (userDefault.value?.email) {
      res.value = await _authStore.checkEmail(userDefault.value?.email);
    }
    if (res.value?.isActivated) {
      isVerify.value = true;
    } else {
      isVerify.value = false;
    }
  });
});
</script>
<template>
  <div
    class="
      relative
      flex
      w-full
      p-2
      flex-wrap
      md:space-y-12
      lg:space-y-0
      xl:space-y-0 xl:overflow-y-hidden
      overflow-y-auto
      small-scrollbar
    "
    style="height: calc(100% - 0.6rem)"
  >
    <!-- infomation -->
    <div style="flex: 35%; max-width: 40rem" class="h-full flex flex-col">
      <FormInfoUser :user-info="myProfile" :edit="false" />
    </div>
    <!-- scope -->
    <div v-permission-model style="flex: 20%" class="h-full flex flex-col">
      <FormScopeUser />
    </div>
    <!-- notification -->
    <div v-permission-model class="h-full flex flex-col" style="flex: 15%">
      <!-- <div class="flex flex-1 flex-col" style="height: 70%">
        <div
          class="
            flex
            bg-current-50
            justify-between
            py-2
            pl-4
            rounded-t-md
            items-center
            justify-between
            space-x-2
          "
        >
          <p class="font-semibold text-current-500 py-1">Groups</p>
        </div>
        <div
          class="
            h-full
            border
            bg-white
            py-2
            px-4
            font-semibold
            border-gray-100
            text-current-600
            overflow-y-auto
            small-scrollbar
            justify-between
          "
        >
          <div>
            <my-groups :groups="allMyGroups" />
          </div>
        </div>
      </div> -->
      <div class="flex-1 min-h-0">
        <FormMyGroups />
      </div>
      <!-- <div class="mt-2" style="height: 30%">
        <FormNotiUser :user-noti="myProfile" :is-edit="false" />
      </div> -->
    </div>
    <!-- <div style="flex: 20%; height: 80%;">
      <form-change-password></form-change-password>
    </div> -->
    <div v-if="isPersonalModel" class="flex-1 flex flex-col px-2">
      <div
        class="
          flex
          py-3
          pl-4
          rounded-t-md
          items-center
          bg-current-50
          justify-between
        "
      >
        <p class="font-semibold text-current-500">
          {{ $t('DOCUMENT_TITLE_WORKING_TIME') }}
        </p>
      </div>
      <div class="flex-1 bg-white">
        <WorkingTimeByUser :user-id="myProfile?.id" />
      </div>
    </div>
  </div>
</template>
