<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import groupStore from '@/store/group';
import domainStore from '@/store/scope';

const props = withDefaults(
  defineProps<{
    modelValue: any[];
    listTaskOwnerId?: number;
    domainId?: number;
    minItemShow?: number;
    isShowResultOnly?: boolean;
  }>(),
  {
    listTaskOwnerId: 0,
    minItemShow: 0,
  }
);
const emit = defineEmits([
  'onClose',
  'onConversationClick',
  'update:modelValue',
]);

const _groupStore = groupStore();
const _domainStore = domainStore();

const mySameGroups = ref<any[]>([]);
const domainByIds = computed(() => _domainStore.domainByIds);

const allGroupByIds = computed(() => {
  return {
    ..._groupStore.allGroupByIds,
    ..._groupStore.myGroupByIds,
  };
});
const initData = () => {
  let groups: any[] = [];
  if (props.domainId) {
    groups = domainByIds.value[props.domainId]?.groups?.map((groupId) => {
      if (
        !allGroupByIds.value ||
        !Object.prototype.hasOwnProperty.call(allGroupByIds.value, groupId)
      )
        return {};

      return allGroupByIds.value[groupId];
    });
  } else groups = _groupStore.getSameGroupByUserId(props.listTaskOwnerId);

  mySameGroups.value = [...groups].map((group) => {
    return {
      ...group,
      isSelected: props.modelValue?.some((groupId) => groupId == group.id),
    };
  });
};
initData();

watch(
  () => props.listTaskOwnerId,
  () => {
    initData();
  }
);

watch(
  () => props.modelValue,
  () => {
    initData();
  }
);

onMounted(async () => {
  if (!_groupStore.allMyGroups || _groupStore.allMyGroups.length == 0) {
    await _groupStore.fetchMyGroups();
  }
});

const selectedGroups = computed(() => {
  return (mySameGroups.value || []).filter((user) => user?.isSelected);
});

const onGroupClick = (contact) => {
  if (!contact) return;

  const index = mySameGroups.value.indexOf(contact);
  if (index > -1) {
    mySameGroups.value[index].isSelected =
      !mySameGroups.value[index].isSelected;
  }
  emit(
    'update:modelValue',
    selectedGroups.value?.map((o) => o.id)
  );
};
const onRemoveSelectedUser = (contact) => {
  if (!contact) return;

  const index = mySameGroups.value.indexOf(contact);
  if (index > -1) {
    mySameGroups.value[index].isSelected = false;
  }

  emit(
    'update:modelValue',
    selectedGroups.value?.map((o) => o.id)
  );
};

const searchText = ref<string>('');
const filteredGroups = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);
  let result = mySameGroups.value;

  if (searchTxt)
    result = (result || []).filter(
      (user) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
    );

  if (selectedGroups.value?.length > 0)
    result = (result || []).filter(
      (group) =>
        group?.id && !selectedGroups.value?.some((g) => g?.id == group?.id)
    );

  return result;
});
</script>

<template>
  <div
    v-if="mySameGroups.length > minItemShow"
    class="flex flex-col overflow-y-auto small-scrollbar w-full"
  >
    <div v-if="selectedGroups?.length > 0">
      <div
        v-for="group of selectedGroups"
        :key="group"
        class="
          rounded-full
          bg-current-50
          text-gray-800 text-sm
          flex-center
          space-x-2
          w-max
          p-1
          m-0.5
          float-left
        "
      >
        <syn-avatar
          custom-class="w-5 h-5"
          :src="group?.avatar?.replace('original', 'mini')"
          :name="group?.name"
        />
        <span class="truncate" style="max-width: 8rem">{{ group?.name }}</span>
        <button
          class="
            p-0.5
            rounded-full
            bg-opacity-50
            hover:bg-current-400 hover:fill-white hover:text-white
            flex-center
          "
          @click.stop="onRemoveSelectedUser(group)"
        >
          <SynIcon name="Close" custom-class="w-4 h-4" />
        </button>
      </div>
    </div>
    <template v-if="!isShowResultOnly">
      <div class="py-2">
        <VigSearchBox v-model="searchText" input-class="text-sm" />
      </div>
      <div
        class="px-2 flex-1 overflow-auto small-scrollbar pb-3"
        style="max-height: 200px"
      >
        <div v-if="filteredGroups?.length">
          <div
            v-for="g in filteredGroups"
            :key="g?.id"
            class="p-2 flex items-center gap-2 cursor-pointer hover:bg-gray-100"
            @click="onGroupClick(g)"
          >
            <div class="flex-center w-4 h-4">
              <SynIcon
                name="checkbox"
                :is-active="g.isSelected"
                class="fill-current"
                custom-class="w-4 h-4"
              />
            </div>
            <div class="flex-center space-x-2">
              <SynAvatar
                :src="g?.avatar"
                :name="g?.name"
                custom-class="w-6 h-6"
              />
              <span class="truncate text-sm" style="max-width: 16rem">
                {{ g?.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
