<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';

import userStore from '@/embed/store/user-store';
import groupStore from '@/embed/store/group-store';

const props = defineProps({
  groupId: { type: Number, default: 0 },
  userId: { type: Number, default: 0 },
});

const emit = defineEmits(['change']);

const _groupStore = groupStore();
const _userStore = userStore();
const isVisitor = computed(() => _userStore.isVisitor);
const allUser = computed(() => _userStore.listShortInfosUser);
const allAssignableUsers = computed(() =>
  allUser.value.filter(() => !isVisitor.value)
);
const allGroup = computed(() =>
  _groupStore.allMyGroups.filter((e) => e.isMember)
);
onMounted(() => {
  groupList.value = cloneDeep(allGroup.value);
  const indexG = allGroup.value.findIndex((o) => o['id'] == props.groupId);
  if (indexG > -1) {
    assignedGroup.value = {
      ...allGroup.value[indexG],
    };
  }
  const indexU = allUser.value.findIndex((o) => o['userId'] == props.userId);
  if (indexU > -1) {
    onChangeAssignee(allUser.value[indexU]);
  }
});
const assignedGroup = ref({} as any);
const assignedUser = ref({} as any);
const errorMessage = ref('');

const onChangeGroupAssignee = (item) => {
  if (!item || !item.id) return;
  errorMessage.value = '';
  assignedGroup.value = item;
  const indexG = allGroup.value.findIndex((o) => o['id'] == item.id);
  if (indexG > -1) {
    assignedGroup.value = {
      ...allGroup.value[indexG],
      ...item,
    };
  }
  // If current user is not in group, reset it
  if (
    !assignedGroup.value?.groupUsers?.some(
      (o) => o.userId == assignedUser.value.userId
    )
  ) {
    assignedUser.value = {};
  }
  emit('change', assignedGroup.value, assignedUser.value);
};
const onRemoveGroup = () => {
  assignedGroup.value = {};
  emit('change', assignedGroup.value, assignedUser.value);
};
const onRemoveUser = () => {
  assignedUser.value = {};
  groupList.value = cloneDeep(allGroup.value);
  emit('change', assignedGroup.value, assignedUser.value);
};
const groupList = ref([]);
const onChangeAssignee = async (item) => {
  assignedUser.value = item;
  errorMessage.value = '';

  emit('change', assignedGroup.value, assignedUser.value);
};
</script>

<template>
  <div class="flex flex-col">
    <div class="flex flex-wrap border border-current-50 rounded px-1 w-max">
      <SynSelectAssign
        input-class="text-sm"
        removeable
        :placeholder="$t('COMMON_LABEL_GROUP')"
        assign-group
        type="group"
        :data="groupList || allGroup"
        :name="assignedGroup.name"
        :avatar="assignedGroup.avatarUrl?.replace('original', 'mini')"
        @chosen="(group) => onChangeGroupAssignee(group)"
        @reset="onRemoveGroup()"
      />
      <SynSelectAssign
        input-class="text-sm"
        :placeholder="$t('TASK_LABEL_ASSIGNEE')"
        removeable
        :data="
          assignedGroup?.id
            ? assignedGroup.groupUsers?.map((x) => {
                x.favoriteId = x.userId;
                return x;
              })
            : allAssignableUsers
        "
        :name="assignedUser.name"
        :avatar="assignedUser.avatar?.replace('original', 'mini')"
        @chosen="(user) => onChangeAssignee(user)"
        @reset="onRemoveUser()"
      />
    </div>

    <div class="flex items-center">
      <div class="px-2 text-sm text-red-500">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
