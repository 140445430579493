<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  srcVideo: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  video: {
    type: Object,
    default: () => {},
  },
  height: {
    type: Number,
    default: 160,
  },
  width: {
    type: [Number, String],
    default: '',
  },
  isPinned: {
    type: Boolean,
    default: false,
  },
  thumbnail: {
    type: Object,
    default: () => {},
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});
defineEmits(['onPinnedVideo']);

const iframeRef = ref(null as any);
</script>

<template>
  <div class="flex flex-col">
    <!--    {{ isComplete }}-->
    <!-- ACTION   -->
    <slot name="header">
      <div class="flex items-center justify-end w-full py-1 px-1">
        <div
          class="
            flex-center
            cursor-pointer
            p-1
            w-8
            h-8
            hover:fill-current-400
            fill-white
            bg-current-50 bg-opacity-30
            hover:bg-gray-50 hover:bg-opacity-50
            rounded
          "
          @click="$emit('onPinnedVideo')"
        >
          <SynIcon
            :name="isPinned ? 'expand' : 'mini-player'"
            custom-class="w-5 h-5"
          />
        </div>
      </div>
    </slot>
    <!-- END ACTION   -->

    <!-- VIDEO   -->
    <div class="flex-center">
      <iframe
        v-if="!isDisabled"
        ref="iframeRef"
        :title="title"
        class="rounded"
        :height="height || '160'"
        :width="width || '100%'"
        :src="srcVideo"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
      <div
        v-else
        class="
          w-full
          h-full
          bg-current-50 bg-opacity-10
          rounded
          text-white
          flex-center
          text-center
          px-4
          pt-4
          pb-4
          mb-5
        "
        :style="`height: ${height || '160'}px; width: ${width}px`"
      >
        {{ $t('HELP_VIDEO_NOT_SUPPORT_LANGUAGE_ALERT') }}
      </div>
    </div>
    <!-- END VIDEO   -->

    <slot name="footer"></slot>
  </div>
</template>
