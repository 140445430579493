<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import overviewMonitorStore, {
  TDepartmentOverview,
} from '@/store/dashboard/overview-monitor-store';
import MonitorByDepartmentIdModal from '@/ui/modules/dashboard/monitor/MonitorByDepartmentIdModal.vue';
import DepartmentCreateFormModal from '@/ui/modules/departments/DepartmentCreateFormModal.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import departmentStore from '@/store/department';
import { IDepartmentDetail } from '@/application/types/department/department.types';
import DepartmentHasNotAdminWarning from '@/ui/modules/departments/components/DepartmentHasNotAdminWarning.vue';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const _overviewMonitorStore = overviewMonitorStore();
const _departmentStore = departmentStore();

const departmentsOverview = computed(
  () => _overviewMonitorStore.departmentsOverview
);
const allDepartmentByIds = computed<{ [key: number]: IDepartmentDetail }>(
  () => _departmentStore.allDepartmentByIds
);

const currentDepartmentId = ref<number | null>(null);
const totalTasks = ref<number | null>(null);
const onOpenMonitoringDepartment = (department: TDepartmentOverview) => {
  totalTasks.value = department.activeTasks + department.finishedTasks;
  currentDepartmentId.value = department.id;
};

const onCloseMonitoringDepartmentModal = () => {
  currentDepartmentId.value = null;
  totalTasks.value = 0;
};

const isShowAddDepartment = ref(false);

onMounted(() => {});
</script>
<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.OVERVIEW.DEPARTMENT,
    }"
    class="w-full h-full"
  >
    <div
      v-if="departmentsOverview?.length > 0"
      class="
        w-full
        h-full
        grid
        auto-rows-max
        overflow-auto
        small-scrollbar
        pt-12
      "
      :style="'grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr))'"
    >
      <div
        v-for="department in departmentsOverview"
        :key="department.id"
        class="w-full px-5 pb-12 relative"
        style="height: 20rem"
      >
        <div
          class="w-full h-full relative px-8 bg-white"
          style="box-shadow: 0px 3px 6px #00000029; border-radius: 30px"
        >
          <div
            class="absolute -top-6 left-6 cursor-pointer"
            @click="onOpenMonitoringDepartment(department)"
          >
            <SynAvatar
              :src="department?.avatarUrl"
              :name="department?.name"
              custom-class="h-14 w-14 "
            />
          </div>
          <div class="w-full h-full flex-center flex-col space-y-4">
            <!-- NAME -->
            <section class="flex-center flex-col">
              <div class="flex-center gap-2">
                <span
                  class="
                    font-medium
                    text-lg text-gray-700 text-overflow-hidden-line
                    cursor-pointer
                    hover:text-current-600
                  "
                  @click="onOpenMonitoringDepartment(department)"
                >
                  {{ department?.name }}
                </span>

                <DepartmentHasNotAdminWarning
                  v-if="allDepartmentByIds[department?.id]?.isHasNotAnyAdmin"
                />
              </div>
              <div>
                <span class="text-gray-400 text-sm text-overflow-hidden-line">
                  {{ department?.description }}
                </span>
              </div>
            </section>
            <!-- STATUS -->
            <section class="flex-center flex-col space-y-3">
              <div>
                <SynListAvatar
                  :users="department.members"
                  :custom-class="'w-10 h-10 text-sm'"
                  :max-length="5"
                />
              </div>
              <div class="flex-center space-x-2">
                <span
                  class="
                    rounded-full
                    bg-current-50
                    text-current-600
                    px-4
                    py-1
                    text-sm
                  "
                >
                  {{ department?.onlineCount }}
                  Online
                </span>
                <span
                  class="
                    rounded-full
                    bg-orange-50
                    text-orange-600
                    px-4
                    py-1
                    text-sm
                  "
                >
                  {{ department?.pauseCount }}
                  Pause
                </span>
                <span
                  class="
                    rounded-full
                    bg-gray-50
                    text-gray-600
                    px-4
                    py-1
                    text-sm
                  "
                >
                  {{ department?.offlineCount }}
                  Offline
                </span>
              </div>
            </section>
            <!-- Task status -->
            <section
              class="w-full flex justify-between pt-6 border-t border-gray-100"
            >
              <div class="flex space-x-1">
                <span
                  :title="department?.activeTasks?.toString()"
                  class="text-4xl font-semibold text-current"
                >
                  {{ $filters.numberToString(department?.activeTasks) }}
                </span>
                <div class="flex flex-col pt-1">
                  <span class="text-gray-600 text-xs">
                    {{ $t('TASK_TABLE_LABEL_TASKS') || 'Tasks' }}
                  </span>
                  <span class="text-current text-sm font-semibold">
                    {{ $t('COMMON_LABEL_ACTIVE') }}
                  </span>
                </div>
              </div>
              <div class="flex space-x-1">
                <span
                  v-vig-tooltip="{
                    content: `${department?.finishedTasks?.toString()} ${
                      $t('TASK_TABLE_LABEL_TASKS') || 'Tasks'
                    }`,
                  }"
                  class="text-4xl font-semibold text-green-400"
                >
                  {{ $filters.numberToString(department?.finishedTasks) }}
                </span>
                <div class="flex flex-col pt-1">
                  <span class="text-gray-600 text-xs">
                    {{ $t('TASK_TABLE_LABEL_TASKS') || 'Tasks' }}
                  </span>
                  <span class="text-green-400 text-sm font-semibold">
                    {{ $t('TASK_STATE_DESCRIPTION_FINISHED') || 'Finished' }}
                  </span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="flex-center flex-col pt-8 w-full h-full bg-white">
      <div class="w-64">
        <VigImage
          :path="`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/images/department.jpg`"
        />
      </div>
      <div class="font-semibold pt-4">
        {{ $t('DEPARTMENT_LABEL_NO_DATA') }}
      </div>
      <div class="text-gray-500">
        {{ $t('DEPARTMENT_LABEL_NO_DATA_SUB') }}
      </div>
      <div class="pt-4">
        <SynButton
          :label="$t('DEPARTMENT_LABEL_CREATE_NEW') || 'Create'"
          name-icon="department"
          @click="isShowAddDepartment = true"
        />
      </div>
    </div>
  </div>

  <MonitorByDepartmentIdModal
    v-if="currentDepartmentId"
    :department-id="currentDepartmentId"
    :total-tasks="totalTasks"
    @close="onCloseMonitoringDepartmentModal"
  />

  <DepartmentCreateFormModal
    v-if="isShowAddDepartment"
    @cancel="isShowAddDepartment = false"
  />
</template>
