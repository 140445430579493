<script setup lang="ts">
import { computed, ref } from 'vue';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { formatTaskCode } from '@/ui/hooks/taskHook';

defineProps<{
  readonly?: boolean;
  task?: any;
}>();
const emit = defineEmits(['onCancelBlockDeadline']);

const allSystemConfigs = computed<any>(() => {
  return permissionStore().allSystemConfigs;
});

const allowedPeople = computed<string>(() => {
  const allAllowedPositions =
    allSystemConfigs.value[
      systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_BY
    ]?.split(';');
  return allAllowedPositions?.reduce((currentResult, currentKey) => {
    const textCode =
      currentKey == 'OWNER_ADMIN'
        ? 'COMMON_LABEL_ADMIN_OR_OWNER'
        : currentKey == 'CREATOR'
        ? 'TASK_TABLE_LABEL_CREATOR'
        : currentKey == 'ASSIGNEE'
        ? 'COMMON_LABEL_ASSIGNEE'
        : '';
    return currentResult + `<li>- ${translate(textCode)}</li>`;
  }, '');
});

const isOpenDropdown = ref<boolean>(false);

const onCancelBlockDeadline = () => {
  emit('onCancelBlockDeadline');
};
</script>

<template>
  <div class="flex-center space-x-1 pin-task-current">
    <AtomTooltip>
      <template #trigger>
        <slot>
          <VigDropdown
            v-if="!readonly"
            @on-dropdown-open="isOpenDropdown = true"
            @on-dropdown-close="isOpenDropdown = false"
          >
            <template #dropdown-toggle>
              <div
                class="flex-center gap-0.5 cursor-pointer hover-to-show__parent"
              >
                <SynIcon
                  name="LockDeadline"
                  custom-class="h-3 w-3 fill-gray-500"
                />
                <div class="w-4 h-4 cursor-pointer rounded flex items-center">
                  <SynIcon
                    name="chevron-right"
                    :custom-class="
                      isOpenDropdown
                        ? 'w-2 h-2 fill-gray-500 -rotate-90'
                        : 'w-2 h-2 fill-gray-500 rotate-90 hover-to-show__children'
                    "
                  />
                </div>
              </div>
            </template>
            <template #dropdown-menu>
              <div class="p-1">
                <div
                  class="
                    flex
                    items-center
                    space-x-2
                    p-2
                    hover:bg-current-50
                    cursor-pointer
                    dropdown-item
                  "
                  @click="onCancelBlockDeadline"
                >
                  <span class="text-sm text-red-500">
                    {{ $t('TASK_LABEL_UNLOCK_UPDATE_DEADLINE') }}
                  </span>
                </div>
              </div>
            </template>
          </VigDropdown>
          <div v-else class="flex-center">
            <SynIcon name="LockDeadline" custom-class="h-3 w-3 fill-gray-500" />
          </div>
        </slot>
      </template>
      <template #body>
        <div class="flex flex-col p-4 gap-2">
          <div class="flex flex-col flex-wrap gap-2 text-sm">
            <span
              v-html="
                $t('TASK_DEADLINE_LOCKING_FEATURE_YOU_HAVE_NOT_PERMISSION', {
                  allowedPeople: allowedPeople,
                  taskCode: formatTaskCode(task?.code) || '',
                })
              "
            >
            </span>
          </div>
        </div>
      </template>
    </AtomTooltip>
  </div>
</template>
