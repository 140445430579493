<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import registerStore from '@/store/auth/register';
import SettingLanguageSmall from '@/ui/components/setting/SettingLanguageSmall.vue';
import { isValidEmail } from '@/ui/hooks/commonFunction';
import AuthenticateClass, {
  ILoginResult,
} from '@/application/entities/authenticate/AuthenticateClass';
import { destroyGoogleCaptcha } from '@/ui/components/layouts/logic/init-google-capcha';
import {
  getLocalStorage,
  setLocalStorage,
  // setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import RegisterForm from '@/ui/modules/register/RegisterForm.vue';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import VerifyPhoneNumber from '@/ui/modules/register/VerifyPhoneNumber.vue';
import { SignUpInput } from '@/domain/entities/PayloadClass/AuthPayloadClass';
import { useRoute } from 'vue-router';
// import { IVoucherResponse } from '@/application/types/CommonTypes';
import TictopWebSocket, {
  USER_REG_EVENT,
  USER_REG_SUB_TYPE,
} from '@/ui/plugins/websocket/ws-instance';
import SystemsInfoModel from '@/ui/plugins/firebases/realtime-database/model/SystemsInfoModel';
import { systemKey } from '@/ui/common/constants/system';
import RegisterFormWithUsername from '@/ui/modules/register/RegisterFormWithUsername.vue';
import { validatePassword } from '@/ui/hooks/commonFunction';
import { GOOGLE_ANALYTICS_ELEMENT_ID } from '@/application/constants/register.const';
import organizationStore from '@/store/organization';
import AttachOrganizationModal from '@/ui/modules/organization/setup/AttachOrganizationModal.vue';

const props = defineProps<{
  isProcessD?: boolean;
  isShowSelectPosition?: boolean;
  processMode?: 'A' | 'B' | 'C';
  defaultPhoneNumber?: string;
  canBack?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onBack'): void;
  (e: 'created-account-success', loginResult: ILoginResult): void;
}>();

const router = useRouter();
let ws: TictopWebSocket;
const _registerStore = registerStore();
const route = useRoute();
const refId = computed(() => route.query?.d_id?.toString() || '');
const voucherCode = computed(() => route.query?.v_code?.toString() || '');

const linkWebsite = ref('http://tictop.vn');
const linkWebsiteLabel = ref('tictop.vn') as any;

const isEuLocation = ref(false);

const currentLanguage = ref('');
const registerPayload = ref(new SignUpInput({}));
const currentSessionInfo = ref({});

const {
  currentStep,
  // stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    INPUT_FORM: {
      key: 'INPUT_FORM',
      next: 'INPUT_VERIFY_CODE',
      previous: '',
      nextAction: ({ payload, language, sessionInfo }) => {
        registerPayload.value = payload;
        currentLanguage.value = language;
        currentSessionInfo.value = sessionInfo;

        ws.send(
          registerType.value == USER_REG_SUB_TYPE.REGISTER_WITH_USERNAME
            ? USER_REG_EVENT.CLICK_REGISTER_INPUT_PASSWORD
            : USER_REG_EVENT.CLICK_REGISTER_SEND_PHONE_CODE_BTN,
          registerType.value,
          {
            ...registerPayload.value,
            ...advertisementInfo.value,
          }
        );
      },
    },
    INPUT_VERIFY_CODE: {
      key: 'INPUT_VERIFY_CODE',
      previous: 'INPUT_FORM',
      next: 'INPUT_EMAIL',
      previousAction: () => {
        // responseErrorCode.value = '';
      },
      nextAction: (optCode: string) => {
        ws.send(
          registerType.value == USER_REG_SUB_TYPE.REGISTER_WITH_USERNAME
            ? USER_REG_EVENT.CLICK_ENTER_PASSWORD
            : USER_REG_EVENT.CLICK_ENTER_PHONE_CODE,
          registerType.value,
          {
            ...registerPayload.value,
            phone_code: optCode,
            ...advertisementInfo.value,
          }
        );
      },
    },
    INPUT_EMAIL: {
      key: 'INPUT_EMAIL',
      previous: 'INPUT_VERIFY_CODE',
      previousAction: () => {
        // responseErrorCode.value = '';
      },
    },
  },
  'INPUT_FORM'
);

const {
  isTyping: isTypingNewPassword,
  validationResult: validationResultNewPassword,
  onValidation: onValidationNewPassword,
  reset: onResetValidationNewPassword,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
    (value) => {
      return validatePassword(value) ? '' : 'COMMON_VALID_PASSWORD_WARNING';
    },
  ],
  0
);
const {
  isTyping: isTypingVerifyPassword,
  validationResult: validationResultVerifyPassword,
  onValidation: onValidationVerifyPassword,
  reset: onResetValidationVerifyPassword,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
    (value) => {
      return value !== registerPayload.value?.password
        ? 'CHANGE_PASSWORD_CONFIRM_PASS_NOT_MATCH'
        : '';
    },
  ],
  0
);

const localOnHandleGoToNextStep = (data) => {
  onHandleGoToNextStep(data);
  if (currentStep.value === 'INPUT_EMAIL') {
    ws.send(USER_REG_EVENT.PAGE_ENTER_EMAIL_ADDRESS_INIT, registerType.value, {
      ...registerPayload.value,
      ...advertisementInfo.value,
    });
  }
};

const initLocation = () => {
  const location = getLocalStorage(StorageConstant.LOCATION);
  linkWebsite.value =
    location == 'EU' ? 'http://tictop.eu' : 'http://tictop.vn';
  linkWebsiteLabel.value = linkWebsite.value?.split('://').pop();

  isEuLocation.value = location == 'EU';
};

initLocation();

// Email
const {
  isTyping: isTypingEmail,
  isChecking: isCheckingEmail,
  validationResult: validationResultEmail,
  onValidation: onValidationEmail,
  reset: onResetValidationEmail,
} = atomFormInputComposables([
  (value) => {
    return isValidEmail(value) ? '' : 'COMMON_INVALID_EMAIL';
  },
  async (value) => {
    const resCheckExist = await _registerStore.checkEmail(value);

    ws.send(USER_REG_EVENT.ENTER_EMAIL_ADDRESS, registerType.value, {
      ...registerPayload.value,
      email: value,
      ...advertisementInfo.value,
    });

    return resCheckExist?.existed ? 'COMMON_LABEL_REQUIRE_EMAIL_EXISTED' : '';
  },
]);

// const handleAddVoucherResponse = (addVoucherResponse: IVoucherResponse) => {
//   setLocalStorage(
//     StorageConstant.VOUCHER_INFO,
//     JSON.stringify(addVoucherResponse)
//   );
// };

// enter email => complete
const isComplete = ref(false);
const inviteCode = computed<string>(() => {
  return route.query?.inviteCode?.toString();
});
const onCompleteAccount = async () => {
  ws.send(USER_REG_EVENT.CLICK_COMPLETE_REGISTER, registerType.value, {
    ...registerPayload.value,
    ...advertisementInfo.value,
  });

  isComplete.value = true;

  let result;
  if (isRegisterWithUsername.value) {
    result = await _registerStore.registerWithUsername(
      {
        ...registerPayload.value,
        isVerifiedEmail: false,
        isVerifiedPhone: true,
      },
      refId.value,
      voucherCode.value,
      advertisementInfo.value
    );
  } else {
    result = await _registerStore.saveUserProfile(
      {
        ...registerPayload.value,
        isVerifiedEmail: false,
        isVerifiedPhone: true,
      },
      refId.value,
      voucherCode.value,
      advertisementInfo.value
    );
  }

  if (voucherCode.value)
    setLocalStorage(
      StorageConstant.VOUCHER_INFO,
      JSON.stringify(voucherCode.value)
    );
  // handleAddVoucherResponse(result?.voucherApplied as IVoucherResponse);
  if (props.isProcessD) {
    emit('created-account-success', {
      ...result,
      fullName: `${registerPayload.value?.lastName} ${registerPayload.value.firstName}`,
    });
    organizationStore().updateDecideTime(14);
  } else {
    await new AuthenticateClass().handleSuccess({
      ...result,
      inviteCode: route.query?.inviteCode?.toString(),
    });
    organizationStore().updateDecideTime(
      props.processMode == 'A'
        ? 11
        : props.processMode == 'B'
        ? 12
        : props.processMode == 'C'
        ? 13
        : 14
    );
  }

  isComplete.value = false;
};

let fbSystemsInfoClass: SystemsInfoModel;

const registerWithPhoneOrUsername = ref<number>(0); // -1 username
const isRegisterWithUsername = computed<boolean>(() => {
  return registerWithPhoneOrUsername.value == -1;
});

const registerType = ref<USER_REG_SUB_TYPE>(
  USER_REG_SUB_TYPE.REGISTER_WITH_PHONE
);

const advertisementInfo = ref<any>({});
onMounted(() => {
  advertisementInfo.value = {
    ...route.query,
    utm_source: route.query?.utm_source?.toString() || '',
    utm_medium: route.query?.utm_medium?.toString() || '',
    utm_campaign: route.query?.utm_campaign?.toString() || '',
    fbclid: route.query?.fbclid?.toString() || '',
  };
  fbSystemsInfoClass = new SystemsInfoModel(
    systemKey.register_with_phone_or_username
  );

  fbSystemsInfoClass.getOnce((value) => {
    registerWithPhoneOrUsername.value = value?.username;

    registerType.value =
      registerWithPhoneOrUsername.value === -1
        ? USER_REG_SUB_TYPE.REGISTER_WITH_USERNAME
        : USER_REG_SUB_TYPE.REGISTER_WITH_PHONE;

    // TODO: check stable of this state
    ws = new TictopWebSocket(
      USER_REG_EVENT.REGISTRATION_PAGE_INIT,
      registerType.value,
      null
    );

    if (registerWithPhoneOrUsername.value === 0) {
      return;
    }

    fbSystemsInfoClass.updateData({
      username: registerWithPhoneOrUsername.value * -1,
    });
  });
});

onUnmounted(() => {
  ws?.close();
  destroyGoogleCaptcha();
});

const isDisabledCompleteAccount = computed(
  () =>
    isTypingEmail.value ||
    !validationResultEmail.value?.isValid ||
    !validationResultNewPassword.value?.isValid ||
    !validationResultVerifyPassword.value?.isValid
);

const onChangeEmail = (value) => {
  if (!value) return;
  onResetValidationEmail();
  if (!isValidEmail(value)) return;

  onValidationEmail(value);
};

const onBlurEmail = () => {
  if (!registerPayload.value?.email || validationResultEmail.value?.isValid)
    return;

  onValidationEmail(registerPayload.value?.email);

  ws.send(USER_REG_EVENT.ENTER_EMAIL_ADDRESS, registerType.value, {
    ...registerPayload.value,
    ...advertisementInfo.value,
  });
};

const showPWD = ref<boolean>(false);
const showConfirmPassword = ref<boolean>(false);
const verifyPassword = ref<string>('');

const onResendCode = () => {
  ws.send(USER_REG_EVENT.CLICK_RESEND_PHONE_CODE, registerType.value, {
    ...registerPayload.value,
    ...advertisementInfo.value,
  });
};

const isDisabledButton = computed<boolean>(() => {
  return (
    !registerPayload.value?.password ||
    !verifyPassword.value ||
    verifyPassword.value !== registerPayload.value?.password ||
    isTypingNewPassword.value ||
    !validationResultNewPassword.value?.isValid ||
    isTypingVerifyPassword.value ||
    !validationResultVerifyPassword.value?.isValid
  );
});

const isOpenAttachOrg = ref(false);

const onAttachOrgSuccess = (code, nextAction) => {
  console.log('🚀 Tictop ~ code:', code);

  isOpenAttachOrg.value = false;
  router.push({
    name: nextAction,
    query: {
      inviteCode: code,
    },
  });
};
</script>

<template>
  <div
    class="
      flex-center
      content-center
      items-center
      justify-center
      h-full
      w-full
      flex-col
    "
  >
    <!-- HEADER -->
    <section class="flex justify-between w-full p-2">
      <div class="cursor-pointer flex-center">
        <div
          v-if="currentStep == 'INPUT_VERIFY_CODE'"
          class="
            w-8
            h-8
            cursor-pointer
            rounded-full
            hover:bg-current-50
            flex-center
          "
        >
          <SynIcon
            name="back"
            class="fill-current text-current"
            custom-class="w-5 h-5"
            @click="onHandleGoToPreviousStep"
          />
        </div>
        <div v-else class="flex-center space-x-2 ml-8 cursor-pointer">
          <div class="w-3 h-3 flex-center">
            <SynIcon
              name="global"
              custom-class="fill-current text-current w-3 h-3"
            />
          </div>
          <a
            class="text-sm hover:text-current font-medium"
            :href="linkWebsite"
            target="_blank"
          >
            {{ `www.${linkWebsiteLabel}` }}
          </a>
        </div>
      </div>
      <SettingLanguageSmall />
    </section>

    <!-- BODY -->
    <section class="flex-1 min-h-0 small-scrollbar w-full flex-center flex-col">
      <div class="flex flex-col mb-4">
        <syn-logo class-logo="h-14" class-label="w-32" />
      </div>
      <section class="w-2/3">
        <div
          class="
            flex-center
            mt-4
            text-center
            flex-col
            content-center
            justify-center
            w-full
            my-1
            font-roboto font-thin
          "
        >
          <span v-if="currentStep == 'INPUT_FORM'" class="w-full text-3xl">
            {{ $t('SIGNUP_LABEL_HEADER') }}
          </span>
          <template v-if="currentStep == 'INPUT_VERIFY_CODE'">
            <span class="text-xl">
              {{
                $t(
                  isRegisterWithUsername
                    ? 'REGISTER_LABEL_ENTER_PASSWORD'
                    : 'LOGIN_LABEL_HEADER_VERIFY_CODE'
                )
              }}
            </span>
          </template>
        </div>

        <div>
          <!-- FORM REGISTER -->
          <section v-show="currentStep == 'INPUT_FORM'">
            <RegisterFormWithUsername
              v-if="isRegisterWithUsername"
              :is-process-d="isProcessD"
              :can-back="canBack"
              :is-show-select-position="isShowSelectPosition"
              :is-hidden-attach="!!inviteCode"
              @on-next="onHandleGoToNextStep"
              @on-back="$emit('onBack')"
              @on-open-attach-modal="isOpenAttachOrg = true"
            />
            <RegisterForm
              v-else
              :voucher-code="voucherCode"
              :is-process-d="isProcessD"
              :can-back="canBack"
              :is-show-select-position="isShowSelectPosition"
              :default-phone-number="defaultPhoneNumber"
              :is-hidden-attach="!!inviteCode"
              @on-sent-code-success="onHandleGoToNextStep"
              @on-back="$emit('onBack')"
              @on-open-attach-modal="isOpenAttachOrg = true"
            />
          </section>

          <template v-if="currentStep == 'INPUT_VERIFY_CODE'">
            <div
              v-if="isRegisterWithUsername"
              class="flex-center flex-col space-y-6 w-full py-4"
            >
              <div class="w-96 flex flex-col space-y-4">
                <div class="flex flex-col w-full">
                  <div
                    class="
                      flex
                      items-center
                      pr-4
                      text-sm text-gray-600
                      font-medium
                    "
                  >
                    <span
                      >{{ $t('LOGIN_FORM_PASSWORD') }}
                      <span class="text-red-600">*</span>
                    </span>
                  </div>
                  <div class="w-full">
                    <SynFormInput
                      is-html
                      :error-message="validationResultNewPassword.message"
                    >
                      <SynInput
                        v-model="registerPayload.password"
                        :placeholder="
                          $t('REGISTER_USERNAME_PASSWORD_PLACEHOLDER')
                        "
                        :icon-right="showPWD ? 'eye' : 'eye-close'"
                        :input-type="showPWD ? 'text' : 'password'"
                        @on-action="showPWD = !showPWD"
                        @update:model-value="onResetValidationNewPassword"
                        @blur="
                          onValidationNewPassword(registerPayload.password)
                        "
                      />
                    </SynFormInput>
                  </div>
                </div>
                <div class="flex flex-col w-full">
                  <div
                    class="
                      flex
                      items-center
                      pr-4
                      text-sm text-gray-600
                      font-medium
                    "
                  >
                    <span
                      >{{ $t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM') }}
                      <span class="text-red-600">*</span>
                    </span>
                  </div>
                  <div class="w-full">
                    <SynFormInput
                      :error-message="validationResultVerifyPassword.message"
                    >
                      <SynInput
                        v-model="verifyPassword"
                        :icon-right="showConfirmPassword ? 'eye' : 'eye-close'"
                        :input-type="showConfirmPassword ? 'text' : 'password'"
                        :placeholder="
                          $t('REGISTER_USERNAME_CONFIRM_PASSWORD_PLACEHOLDER')
                        "
                        @on-action="showConfirmPassword = !showConfirmPassword"
                        @blur="onValidationVerifyPassword(verifyPassword)"
                        @update:model-value="onResetValidationVerifyPassword"
                      />
                    </SynFormInput>
                  </div>
                </div>
              </div>
              <SynButton
                :id="
                  GOOGLE_ANALYTICS_ELEMENT_ID.REGISTER
                    .register_by_username_submit_password_btn
                "
                class="w-96"
                :label="$t('COMMON_LABEL_CONTINUE')"
                :disabled="isDisabledButton"
                @click="localOnHandleGoToNextStep"
              />
            </div>
            <!-- ENTER CODE OTP -->
            <VerifyPhoneNumber
              v-else
              :phone-number="registerPayload.phone"
              :locale="currentLanguage"
              :session-info-default="currentSessionInfo"
              @on-verify-success="localOnHandleGoToNextStep"
              @on-resend-code="onResendCode"
            />
          </template>

          <!-- ENTER EMAIL -->
          <section v-if="currentStep == 'INPUT_EMAIL'">
            <div class="flex flex-wrap text-center justify-center">
              <SynAnimation name="verifyMail" stype="width: 150px;" />
              <label class="w-full text-bold text-current-600">
                {{ $t('SIGN_UP_LABEL_HEADER_EMAIL') }}
              </label>
              <label class="w-full text-sm text-gray-500">
                {{ $t('VERIFY_ACCOUNT_ALERT_SEND_CODE') }}
              </label>
            </div>
            <div class="relative flex flex-col space-y-4 my-3">
              <div class="w-full flex flex-col">
                <div
                  class="
                    flex
                    items-center
                    pr-4
                    text-sm text-gray-600
                    font-medium
                  "
                >
                  <span>
                    {{ $t('LOGIN_FORM_EMAIL') }}
                    <span class="text-red-600">*</span>
                  </span>
                </div>
                <SynFormInput
                  :is-checking="isCheckingEmail"
                  :is-valid="validationResultEmail.isValid"
                  :error-message="validationResultEmail.message"
                >
                  <div
                    class="
                      relative
                      text-gray-600
                      focus-within:text-gray-800
                      w-full
                      rounded-md
                      py-0.4
                    "
                  >
                    <VigInputEmail
                      v-model="registerPayload.email"
                      class="
                        bg-white
                        w-full
                        h-10
                        focus:outline-none
                        pr-6
                        pl-12
                        text-sm
                        rounded-md
                        vig-input
                        placeholder-gray-500 placeholder-opacity-75
                      "
                      autofocus
                      :placeholder="$t('LOGIN_FORM_EMAIL')"
                      @update:model-value="onChangeEmail"
                      @blur="onBlurEmail"
                    />
                    <div
                      class="
                        absolute
                        inset-y-0
                        left-4
                        flex
                        items-center
                        h-full
                        focus:outline-none focus:shadow-outline
                      "
                    >
                      <SynIcon
                        name="Email"
                        custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                      />
                    </div>
                    <div
                      class="
                        absolute
                        inset-y-0
                        left-4
                        flex
                        items-center
                        h-full
                        focus:outline-none focus:shadow-outline
                      "
                    >
                      <SynIcon
                        name="Email"
                        custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                      />
                    </div>
                  </div>
                </SynFormInput>
              </div>
              <!-- PASSWORD -->
              <div class="w-full flex flex-col space-y-4">
                <div class="flex flex-col w-full">
                  <div
                    class="
                      flex
                      items-center
                      pr-4
                      text-sm text-gray-600
                      font-medium
                    "
                  >
                    <span
                      >{{ $t('LOGIN_FORM_PASSWORD') }}
                      <span class="text-red-600">*</span>
                    </span>
                  </div>
                  <div class="w-full">
                    <SynFormInput
                      is-html
                      :error-message="validationResultNewPassword.message"
                    >
                      <SynInput
                        v-model="registerPayload.password"
                        :placeholder="
                          $t('REGISTER_USERNAME_PASSWORD_PLACEHOLDER')
                        "
                        :icon-right="showPWD ? 'eye' : 'eye-close'"
                        :input-type="showPWD ? 'text' : 'password'"
                        @on-action="showPWD = !showPWD"
                        @update:model-value="onResetValidationNewPassword"
                        @blur="
                          onValidationNewPassword(registerPayload.password)
                        "
                      />
                    </SynFormInput>
                  </div>
                </div>
                <div class="flex flex-col w-full">
                  <div
                    class="
                      flex
                      items-center
                      pr-4
                      text-sm text-gray-600
                      font-medium
                    "
                  >
                    <span
                      >{{ $t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM') }}
                      <span class="text-red-600">*</span>
                    </span>
                  </div>
                  <div class="w-full">
                    <SynFormInput
                      :error-message="validationResultVerifyPassword.message"
                    >
                      <SynInput
                        v-model="verifyPassword"
                        :icon-right="showConfirmPassword ? 'eye' : 'eye-close'"
                        :input-type="showConfirmPassword ? 'text' : 'password'"
                        :placeholder="
                          $t('REGISTER_USERNAME_CONFIRM_PASSWORD_PLACEHOLDER')
                        "
                        @on-action="showConfirmPassword = !showConfirmPassword"
                        @blur="onValidationVerifyPassword(verifyPassword)"
                        @update:model-value="onResetValidationVerifyPassword"
                      />
                    </SynFormInput>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-6">
              <SynButton
                :id="
                  isRegisterWithUsername
                    ? GOOGLE_ANALYTICS_ELEMENT_ID.REGISTER
                        .register_by_username_submit_email_btn
                    : GOOGLE_ANALYTICS_ELEMENT_ID.REGISTER
                        .register_by_phone_submit_email_btn
                "
                class="w-full m-auto"
                :is-loading="isComplete"
                :disabled="isDisabledCompleteAccount"
                :label="$t('SIGN_UP_LABEL_COMPLETE_ACCOUT')"
                @click="onCompleteAccount"
              >
              </SynButton>
            </div>
          </section>
        </div>
      </section>
    </section>

    <!-- LINK DOWNLOAD  -->
    <section class="flex justify-center py-4 w-full">
      <SynLinkDownload class="font-medium" />
    </section>
  </div>
  <AttachOrganizationModal
    v-if="isOpenAttachOrg"
    is-not-authenticated
    @on-success="onAttachOrgSuccess"
    @on-cancel="isOpenAttachOrg = false"
  />
</template>
