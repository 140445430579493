import AuthRepository from '@/application/repositories/AuthRepository';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
export default class AuthService {
    private static instance: AuthService;
    _authRepository: AuthRepository;

    constructor() {
        this._authRepository = AuthRepository.getInstance();
    }

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            // Get from local storage
            AuthService.instance = new AuthService();
        }

        return AuthService.instance;
    }
    selectOrganization(payload): Promise<any> {
        const deviceToken = getLocalStorage(StorageConstant.DEVICE_TOKEN);
        const jwtToken = getLocalStorage(StorageConstant.TOKEN);
        const refreshToken = getLocalStorage(StorageConstant.REFRESH_TOKEN);

        return this._authRepository.selectOrganization({
            ...payload,
            removingDeviceToken: deviceToken,
            jwtToken,
            refreshToken,
        });
    }
    async forgotPassword(payload): Promise<any> {
        const res = await this._authRepository.forgotPassword(payload);
        return res;
    }
    async validateResetPasswordCode(payload): Promise<any> {
        const res = await this._authRepository.validateResetPasswordCode(
            payload
        );
        return res;
    }
    async resetPassword(payload): Promise<any> {
        const res = await this._authRepository.resetPassword(payload);
        return res;
    }
    loginWithPassword(payload): Promise<any> {
        return this._authRepository.loginWithPassword(payload);
    }
    getCurrentLoginInformations(): Promise<any> {
        return this._authRepository.getCurrentLoginInformations();
    }
    async checkPhone(data: string): Promise<any> {
        const res = await this._authRepository.checkPhone(data);
        return res?.result;
    }
    async checkEmail(data: string): Promise<any> {
        return this._authRepository.checkEmail(data);
    }
    async saveUserProfile(data: any): Promise<any> {
        const res = await this._authRepository.saveUserProfile(data);
        return res?.result;
    }
    async verifyUserPhoneNumber(data: any): Promise<any> {
        const res = await this._authRepository.verifyUserPhoneNumber(data);
        return res;
    }
    async loginByPhoneCode(data: any): Promise<any> {
        const res = await this._authRepository.loginByPhoneCode(data);
        return res;
    }
    async loginByEmailCode(data: any): Promise<any> {
        const res = await this._authRepository.loginByEmailCode(data);
        return res;
    }
    async verifyUserEmail(data: any): Promise<any> {
        const res = await this._authRepository.verifyUserEmail(data);
        return res?.result;
    }
    async sendVerificationCodeByEmail(data: any): Promise<any> {
        const res = await this._authRepository.sendVerificationCodeByEmail(
            data
        );
        return res;
    }
    async reSendVerificationCodeByEmail(data: any): Promise<any> {
        const res = await this._authRepository.reSendVerificationCodeByEmail(
            data
        );
        return res;
    }
    async checkUserHasPasswordByEmail(data: string): Promise<any> {
        const res = await this._authRepository.checkUserHasPasswordByEmail(
            data
        );
        return res?.result;
    }
    async checkUserHasPasswordByPhone(data: string): Promise<any> {
        const res = await this._authRepository.checkUserHasPasswordByPhone(
            data
        );
        return res?.result;
    }
    async submitVerificationUserEmailToken(data: string): Promise<any> {
        const res = await this._authRepository.submitVerificationUserEmailToken(
            data
        );
        return res?.result;
    }
    async submitVerificationEmailUserTokenForForgotPassword(
        data: any
    ): Promise<any> {
        const res =
            await this._authRepository.submitVerificationEmailUserTokenForForgotPassword(
                data
            );
        return res?.result;
    }
    async refresh(data: any): Promise<any> {
        const res = await this._authRepository.refresh(data);
        return res?.result;
    }
    async logout(data: any): Promise<any> {
        const deviceToken = getLocalStorage(StorageConstant.DEVICE_TOKEN);
        const jwtToken = getLocalStorage(StorageConstant.TOKEN);
        const refreshToken = getLocalStorage(StorageConstant.REFRESH_TOKEN);

        const res = await this._authRepository.logout({
            ...data,
            RemovingDeviceToken: deviceToken,
            jwtToken: jwtToken,
            refreshToken: refreshToken,
        });

        return res?.result;
    }
    getUserOrganizationName(loginId: any): Promise<any> {
        return this._authRepository.getUserOrganizationName(loginId);
    }
    getCurrentSubscriptionProcess(): Promise<any> {
        return this._authRepository.getCurrentSubscriptionProcess();
    }
    getCurrentSubscriptionProcess2(): Promise<any> {
        return this._authRepository.getCurrentSubscriptionProcess2();
    }
}
