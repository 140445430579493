<script setup lang="ts">
import {
  onMounted,
  ref,
  onBeforeUnmount,
  watch,
  provide,
  inject,
  computed,
} from 'vue';
import tippy, { Placement } from 'tippy.js';
import { TriggerType } from '@/ui/common/atoms/VigDropdown/vig-dropdown-types';

const props = withDefaults(
  defineProps<{
    isOpen?: boolean;
    placement?: Placement | Placement[];
    arrow?: boolean;
    appendToBody?: boolean;
    hideOnClick?: boolean | string;
    autoOpen?: boolean;
    zIndex?: number | string;
    delay?: (number | null)[];
    duration?: number[];
    trigger?: TriggerType;
    space?: number;
    disabled?: boolean;
    isShowDropdown?: boolean;
    theme?: string;
    childrenClassList?: string;
    dropdownItemClass?: string;
  }>(),
  {
    placement: 'bottom',
    arrow: true,
    appendToBody: true,
    hideOnClick: true,
    autoOpen: false,
    zIndex: 50,
    delay: () => [0, 0],
    duration: () => [0, 0],
    trigger: 'click',
    space: 10,
    disabled: false,
    isShowDropdown: false,
    theme: 'light',
    dropdownItemClass: 'dropdown-item',
  }
);

const emit = defineEmits([
  'onDropdownOpen',
  'onDropdownClose',
  'update:isOpen',
]);

const dropdownToggle = ref(null);
const dropdownMenu = ref(null);
const isShow = ref(false);
const currentId = ref<string>('');

watch(
  () => props.disabled,
  () => {
    _processDropdownState();
  }
);

watch(
  () => props.isShowDropdown,
  () => {
    isShow.value = props.isShowDropdown;
  }
);

onMounted(() => {
  let placement = props.placement;
  let fallbackPlacements = ['auto'];

  if (Array.isArray(props.placement) && props.placement.length) {
    placement = props.placement[0];
    fallbackPlacements = props.placement
      .slice(1, props.placement.length)
      .concat(fallbackPlacements);
  }

  tippy(dropdownToggle.value, {
    appendTo: () => (props.appendToBody ? document.body : dropdownToggle.value),
    content: dropdownMenu.value,
    allowHTML: true,
    theme: props.theme,
    trigger: props.trigger,
    duration: props.duration || [0, 0],
    delay: props.delay || [0, 0],
    offset: [0, props.space],
    interactive: true,
    animation: 'scale-extreme',
    maxWidth: 'none',
    hideOnClick: props.hideOnClick === 'outside' ? false : props.hideOnClick,
    arrow: props.arrow,
    placement,
    zIndex: props.zIndex,
    showOnCreate: props.autoOpen,
    popperOptions: {
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements,
          },
        },
      ],
    },
    onCreate(instance) {
      instance.onDropdownItemClick = () => {
        instance.hide();
      };
    },
    onShow(instance) {
      emit('update:isOpen', true);
      emit('onDropdownOpen');

      instance.popper
        .querySelectorAll(`.${props.dropdownItemClass}`)
        .forEach((el) =>
          el.addEventListener('click', instance.onDropdownItemClick)
        );

      setTimeout(() => dropdownToggle.value?._tippy?.popperInstance?.update());
    },
    onHide(instance) {
      emit('update:isOpen', false);
      emit('onDropdownClose');

      instance.popper
        .querySelectorAll(`.${props.dropdownItemClass}`)
        .forEach((el) =>
          el.removeEventListener('click', instance.onDropdownItemClick)
        );
    },
    onClickOutside(instance, event) {
      if (isShow.value && props.hideOnClick === 'toggle') return;
      const target = event.target;

      const isChild = _isDescendant(dropdownMenu.value, target);

      if (isChild) return;

      instance.hide();
    },
  });

  _processDropdownState();

  const parentId = inject('vig-dropdown-id', '') as string;
  const lastItemIndex =
    parentId && parentId?.split('_') && parentId?.split('_')?.length > 1
      ? parseInt(parentId?.split('_')?.pop() || '0')
      : 0;

  currentId.value =
    parentId && lastItemIndex >= 0
      ? `${parentId}_${lastItemIndex + 1}`
      : `vig-dropdown-id-${Date.now()}`;

  provide(`vig-dropdown-id`, currentId.value);
});

const _isDescendant = (parent, child) => {
  const isThisScope = child?.classList?.value
    ?.toString()
    ?.split(' ')
    .some((o) => {
      return props.childrenClassList?.includes(o);
    });
  if (isThisScope) return true;

  let node = child.parentNode;
  while (node !== null) {
    if (node?.id && node?.id?.startsWith(currentId.value)) {
      return true;
    }
    if (
      node === parent ||
      node?.classList?.value
        ?.toString()
        ?.split(' ')
        .some((o) => {
          return o == 'do-not-close-dropdown';
        })
    ) {
      return true;
    }

    node = node.parentNode;
  }
  return false;
};

onBeforeUnmount(() => {
  if (dropdownToggle.value?._tippy) {
    dropdownToggle.value?._tippy.hide();
    dropdownToggle.value?._tippy.destroy();
  }
});

const _processDropdownState = () => {
  if (!dropdownToggle.value?._tippy) return;

  if (props.disabled) {
    dropdownToggle.value?._tippy.disable();
  } else {
    dropdownToggle.value?._tippy.enable();
  }
};

const onForceClose = () => {
  if (dropdownToggle.value?._tippy) {
    dropdownToggle.value?._tippy.hide();
    dropdownToggle.value?._tippy.clearDelayTimeouts();
  }
};

const onForceOpen = () => {
  if (dropdownToggle.value?._tippy) {
    dropdownToggle.value?._tippy.show();
  }
};
const update = () => {
  if (dropdownToggle.value?._tippy) {
    dropdownToggle.value?._tippy?.setContent(dropdownToggle.value);
  }
};

defineExpose({
  onForceClose,
  onForceOpen,
  update,
});
</script>

<template>
  <div>
    <div ref="dropdownToggle" class="w-full h-full">
      <slot name="dropdown-toggle"></slot>
    </div>
    <div
      :id="currentId"
      ref="dropdownMenu"
      rin-data="123123"
      className="text-base min-w-full"
    >
      <slot name="dropdown-menu"></slot>
    </div>
  </div>
</template>

<style lang="postcss">
.tippy-box[data-theme~='light'] .tippy-content {
  @apply p-0;
}

/*.tippy-box[data-theme~='light'] {*/
/*  @apply overflow-hidden;*/
/*}*/

.tippy-box[data-theme~='translucent'] .tippy-content {
  @apply p-1;
}

.tippy-box[data-theme~='transparent'] {
  @apply bg-transparent p-0;
}
</style>
