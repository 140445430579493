import {
    genFilePathByType,
    uploadFile,
    copyFile,
} from '@/ui/plugins/awss3/aws-s3-services';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import $constants from '@/ui/plugins/constants';

class ChatFileRepository {
    async uploadMessageFile(
        organizationId: number,
        fileId: string,
        file: any
    ): Promise<any> {
        const path = genFilePathByType(
            organizationId,
            fileId,
            file?.name,
            file?.type
        );

        console.log('🚀 Tictop ~ s3Payload.file?.type:', file?.type);
        const s3Payload = {
            Bucket: this._getFileBucketByType(file?.type),
            Key: path,
            Body: file.url
                ? Buffer.from(
                      file.url.replace(/^data:image\/\w+;base64,/, ''),
                      'base64'
                  )
                : file,
            ContentType: file?.type,
            ACL: 'public-read',
        };

        const { Location } = await uploadFile(s3Payload);

        return Location?.slice(Location?.indexOf(`${organizationId}/`));
    }

    async forwardMessageFile(
        organizationId: number,
        fileId: string,
        msgFile: any
    ): Promise<any> {
        const path = genFilePathByType(
            organizationId,
            fileId,
            msgFile?.name,
            msgFile?.contentType
        );
        const bucket = this._getFileBucketByType(msgFile?.contentType);

        const s3Payload = {
            Bucket: bucket,
            CopySource: `${bucket}/${msgFile?.url}`,
            Key: path,
            ContentType: msgFile?.contentType,
            ACL: 'public-read',
        };

        await copyFile(s3Payload);

        return path;
    }

    async uploadGroupChatAvatar(
        organizationId: number,
        fileId: string,
        file: any
    ): Promise<any> {
        const path = `${organizationId}/original/avatars/${fileId}/${file?.name}`;

        const s3Payload = {
            Bucket: this._getFileBucketByType(file?.type),
            Key: path,
            Body: file.url
                ? Buffer.from(
                      file.url.replace(/^data:image\/\w+;base64,/, ''),
                      'base64'
                  )
                : file,
            ContentType: file?.type,
            ACL: 'public-read',
        };

        const { Location } = await uploadFile(s3Payload);

        return Location?.slice(Location?.indexOf(`${organizationId}/`));
    }

    private _getFileBucketByType(contentType) {
        if (contentType?.startsWith($constants.FILE.TYPE.IMAGE)) {
            return bucketTictop.MessagingPhoto;
        }

        if (
            contentType?.startsWith($constants.FILE.TYPE.AUDIO) ||
            contentType?.startsWith($constants.FILE.TYPE.VIDEO)
        ) {
            return bucketTictop.MessagingMedia;
        }

        return bucketTictop.MessagingFile;
    }
}

export default new ChatFileRepository();
