<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
// import TaskWorkflow from '@/ui/modules/task/workflow/TaskWorkflow.vue';
// import FormAddStep from '@/ui/modules/workflow/FormAddStep.vue';
import WorkflowList from '@/ui/modules/workflow/WorkflowList.vue';
// import WorkflowSteps from '@/ui/modules/workflow/WorkflowSteps.vue';
import TaskWorkflowEntity, {
  ITaskWorkflowEntity,
} from '@/domain/entities/task/TaskWorkflowEntity';
import myProfileStore from '@/store/auth/my-profile';
import settingStore from '@/store/setting';
import { IWorkflowEntity } from '@/domain/entities/workflow/WorkflowEntity';
import WorkflowAddNewModal from '@/ui/modules/workflow/workflow-detail/WorkflowAddNewModal.vue';
// import WorkflowDetailModal from '@/ui/modules/workflow/workflow-detail/WorkflowDetailModal.vue';
// import TaskWorkflowStepUpdateMultipleModal from '@/ui/modules/task/workflow/step/TaskWorkflowStepUpdateMultipleModal.vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import workflowStore from '@/store/workflow';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import UpdateWorkflowSteps from '@/ui/modules/workflow/workflow-detail/UpdateWorkflowSteps.vue';

const props = withDefaults(
  defineProps<{
    taskWorkflow?: ITaskWorkflowEntity;
    taskId?: number;
    taskCode?: string;
    domainId?: number;
    groupId?: number;
    taskDetail: ITaskDetailClass;
  }>(),
  {}
);
const emits = defineEmits<{
  (e: 'cancel'): void;
  (e: 'onSave', data: TaskWorkflowEntity): void;
  (e: 'onAttach', data: TaskWorkflowEntity): void;
  (e: 'changeSize', data: 'SM' | 'MD'): void;
}>();

const _settingStore = settingStore();
const _myProfileStore = myProfileStore();
const _workflowStore = workflowStore();

const permittedEdit = computed<boolean>(() => {
  return _myProfileStore.isAdmin || _myProfileStore.isOwner;
});

const attachedWorkflowInfo = ref<TaskWorkflowEntity>(
  new TaskWorkflowEntity({})
);
const originalWorkflowDetail = ref<ITaskWorkflowEntity | undefined>();

// const onChangeStepSelected = (steps) => {
//   attachedWorkflowInfo.value.workFlowSteps = steps || [];
// };

const myDepartment = computed(() => _myProfileStore.myDepartment);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);

const filterObject = ref<{
  departmentId?: number;
  domainId?: number;
  groupId?: number;
  searchText?: string;
}>({
  departmentId: departmentManagementEnabled.value
    ? myDepartment.value?.departmentId
    : undefined,
  domainId: props.domainId,
  groupId: props.groupId,
  searchText: '',
});

onMounted(async () => {
  if (props.taskWorkflow) {
    // Creating new task
    attachedWorkflowInfo.value = new TaskWorkflowEntity({
      ...props.taskWorkflow,
    });
    if (!props.taskDetail?.id) {
      originalWorkflowDetail.value = await _workflowStore.getWorkflowById(
        props.taskWorkflow?.id
      );
    }
  }

  if (departmentManagementEnabled.value)
    filterObject.value.departmentId = myDepartment.value?.departmentId;
});

const onSelectExistedWorkflow = async (selectedWorkflow, viewMode) => {
  if (viewMode == 'CHOOSE') {
    originalWorkflowDetail.value = await _workflowStore.getWorkflowById(
      selectedWorkflow?.id
    );
    attachedWorkflowInfo.value = new TaskWorkflowEntity({
      ...selectedWorkflow,
      ...originalWorkflowDetail.value,
    });

    emits('changeSize', 'MD');
    isOpenEditWorkflow.value = false;
  } else {
    currentWorkflow.value = selectedWorkflow;
    viewWorkflowDetailMode.value = viewMode;

    isOpenEditWorkflow.value = true;
  }
};

// const createWorkflowStepRef = ref<any>(null);

const isAttachLoading = ref<boolean>(false);

const onSaveWorkflow = async () => {
  if (!props.taskId) {
    emits('onAttach', attachedWorkflowInfo.value);

    return;
  }
  try {
    isAttachLoading.value = true;

    const result = await attachedWorkflowInfo.value.attachToTask(props.taskId);
    emits('onSave', result);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isAttachLoading.value = false;
  }
};

const currentWorkflow = ref<IWorkflowEntity | null>(null);
const isOpenNewWorkflow = ref<boolean>(false);
const isOpenEditWorkflow = ref<boolean>(false);

const viewWorkflowDetailMode = ref<'EDIT_STEP' | 'VIEW'>('VIEW');
const onCreateSuccess = (newWorkflow) => {
  isOpenNewWorkflow.value = false;

  currentWorkflow.value = newWorkflow;

  // viewWorkflowDetailMode.value = 'EDIT_STEP';

  // isOpenEditWorkflow.value = true;

  updateWorkflowSuccess();
};

const isDisabledSaveButton = computed<boolean>(() => {
  return attachedWorkflowInfo.value?.steps?.some((step) => {
    return (!step?.groupId && !step?.assigneeId) || !step?.domainId;
  });
});

const workflowListRef = ref<any>(null);
const updateWorkflowSuccess = () => {
  if (workflowListRef.value?.initComponent)
    workflowListRef.value?.initComponent();

  isOpenEditWorkflow.value = false;
};

const workflowDefaultData = ref<IWorkflowEntity | undefined>(undefined);

const openCreateNewWorkflow = (defaultData) => {
  workflowDefaultData.value = defaultData || undefined;
  isOpenNewWorkflow.value = true;
};

const onClose = () => {
  if (!props.taskWorkflow?.id && attachedWorkflowInfo.value?.id) {
    onDetachWorkflow();
    return;
  }
  emits('cancel');
};

const onDetachWorkflow = () => {
  attachedWorkflowInfo.value = new TaskWorkflowEntity({});

  emits('changeSize', 'SM');
};

defineExpose({
  onSaveWorkflow,
  onClose,
});
</script>
<template>
  <div
    class="flex-1 min-h-0 w-full h-full text-xs flex flex-col"
    style="min-width: 40rem"
  >
    <!-- EDIT -->
    <template v-if="taskDetail?.workFlowTaskId">
      <div
        class="flex-1 min-h-0 overflow-hidden w-full flex flex-col space-y-3"
      >
        <div
          class="
            w-full
            flex-1
            min-h-0
            overflow-y-auto
            small-scrollbar
            flex flex-col
            space-y-2
          "
        >
          <div class="w-full">
            <!-- <TaskWorkflow
              v-model:task-workflow="attachedWorkflowInfo"
              :task-detail="taskDetail"
              @update:current-step-id="
                (value) => (attachedWorkflowInfo.currentStepId = value)
              "
            /> -->
            <UpdateWorkflowSteps
              ref="updateWorkflowStepsRef"
              v-model:workflow-steps="attachedWorkflowInfo.steps"
              v-model:draw-data="attachedWorkflowInfo.drawData"
              :workflow-info="attachedWorkflowInfo"
              view-mode="TASK_VIEW"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- APPLY NEW -->
    <template v-else-if="attachedWorkflowInfo?.id">
      <div class="flex-1 min-h-0 flex flex-col">
        <div
          class="w-full flex items-center justify-between text-base p-4 px-8"
        >
          <div
            class="truncate text-xl"
            :title="originalWorkflowDetail?.name || attachedWorkflowInfo?.name"
          >
            {{ originalWorkflowDetail?.name || attachedWorkflowInfo?.name }}
          </div>
        </div>
        <div
          class="flex-1 min-h-0 w-full h-full text-xs flex flex-col"
          style="max-width: 100%; min-width: 40rem"
        >
          <div class="flex-1 min-h-0 overflow-y-auto small-scrollbar px-8">
            <div class="w-full h-full py-8 flex flex-col">
              <!-- <WorkflowSteps
                v-if="!isOpenUpdateMultipleSteps"
                v-model:workflow-steps="attachedWorkflowInfo.steps"
                :original-workflow-steps="originalWorkflowDetail?.steps"
                :view-mode="'ATTACH_TO_TASK'"
              /> -->
              <UpdateWorkflowSteps
                ref="updateWorkflowStepsRef"
                v-model:workflow-steps="attachedWorkflowInfo.steps"
                v-model:draw-data="attachedWorkflowInfo.drawData"
                :workflow-info="attachedWorkflowInfo"
                view-mode="ATTACH_TO_TASK"
              />
            </div>
          </div>
          <div class="p-4 px-8 flex justify-between items-center space-x-2">
            <div class="flex-1 flex-center text-base">
              <div
                v-html="
                  $t('TASK_WORKFLOW_ATT_ACH_A_WORKFLOW_A_FOR_TASK_B', {
                    workflowName: attachedWorkflowInfo?.name || '',
                    taskCode:
                      formatTaskCode(taskCode) ||
                      $t('TASK_LABEL_YOU_ARE_CREATING') ||
                      '',
                  }) || 'Task workflow'
                "
              ></div>
            </div>
            <div class="flex-center gap-2">
              <AtomButton
                label="COMMON_LABEL_CANCEL"
                type="TEXT"
                size="lg"
                @click="onClose"
              />

              <AtomButton
                label="COMMON_LABEL_APPLY"
                size="lg"
                :disabled="isDisabledSaveButton"
                :is-loading="isAttachLoading"
                @click="onSaveWorkflow"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="w-full flex-1 min-h-0 h-full flex flex-col space-y-3">
        <!-- HEADER -->
        <div class="w-full flex items-center justify-between pt-4 px-4">
          <span class="text-base italic">
            {{ $t('TASK_WORK_FLOW_NOT_APPLY') }}
          </span>
          <div class="flex-center space-x-1">
            <VigSearchBox
              v-model="filterObject.searchText"
              input-class="text-sm"
              style="width: 20rem"
              autofocus
              :placeholder="$t('COMMON_LABEL_SEARCH')"
            />
            <button
              v-if="permittedEdit"
              class="w-8 h-8 rounded cursor-pointer flex-center bg-current-500"
              @click="openCreateNewWorkflow"
            >
              <SynIcon name="plus" custom-class="w-4 h-4 fill-white" />
            </button>
          </div>
        </div>
        <!-- BODY -->
        <div class="flex-1 min-h-0 flex flex-col">
          <WorkflowList
            ref="workflowListRef"
            class="pl-4"
            is-apply-to-task-screen
            :filter-object="filterObject"
            :task-id="taskId"
            :action-list="
              permittedEdit ? ['DUPLICATE', 'EDIT', 'CHOOSE'] : ['CHOOSE']
            "
            filter-active-only
            @choose="onSelectExistedWorkflow"
          />
        </div>
        <!-- <div
        class="
          border border-gray-300 border-dashed
          bg-gray-50
          rounded
          w-full
          p-2
          flex flex-col
          space-y-2
          items-center
          cursor-pointer
        "
        @click="isOpenPreviewBeforeAttach = true"
      >
        <div class="p-2 text-xl">Click to create new workflow</div>
      </div> -->
      </div>
    </template>
  </div>

  <WorkflowAddNewModal
    v-if="isOpenNewWorkflow"
    :default-data="workflowDefaultData"
    @cancel="isOpenNewWorkflow = false"
    @create-success="onCreateSuccess"
  />
</template>
