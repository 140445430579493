<script setup lang="ts">
import SetRepeats from '@/ui/modules/task/detail/SetRepeats.vue';
import { ref, computed } from 'vue';
import { ETaskRepeatType } from '@/application/interfaces/tasks/task-interfaces';

import {
  ITaskRepeat,
  ETaskMonthlySubRepeatTypeModel,
} from '@/application/interfaces/tasks/task-interfaces';
import { repeatTypeByKeys } from '../constants/constant-task-repeats';
import dayjs from 'dayjs';
defineProps<{
  taskRepeat: any;
  startDate: any;
  originTemplate: any;
  isUpdateTemplate?: boolean;
}>();

defineEmits<{
  (e: 'onSave', data: any): void;
  (e: 'cancel'): void;
}>();

const setRepeatsRef = ref<any>(null);
const errorMessage = ref({
  startDate: '',
  endDate: '',
});
const onSaveRepeat = () => {
  if (isEmptyStartDate()) {
    errorMessage.value.startDate =
      'COMMON_LABEL_ERROR_MESSAGE_START_DATE_REPEAT_TASK';
  }
  if (isEmptyEndDate()) {
    errorMessage.value.endDate =
      'COMMON_LABEL_ERROR_MESSAGE_END_DATE_REPEAT_TASK';
  }
  if (isEmptyStartDate() || isEmptyEndDate()) {
    return;
  }

  setRepeatsRef.value?.onSave();
};

const isEmptyEndDate = () => {
  return (
    !endDate.value &&
    repeatType.value !== ETaskRepeatType.None &&
    repeatEndKey.value == 2 //set end date
  );
};

const isEmptyStartDate = () => {
  return !startDate.value && repeatType.value !== ETaskRepeatType.None;
};

const endDate = ref();
const startDate = ref();
const repeatEndKey = ref();
const repeatType = ref<ETaskRepeatType>();
// const isDisableInfiniteRepeat = ref<boolean>();
const taskRepeatData = ref<ITaskRepeat>({
  repeatType: ETaskRepeatType.None,
  subRepeatType: {
    weeklyOptions: [0],
    monthlyOptions: ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth,
    byMonth: [],
    byMonthday: [],
  },
  startDate: new Date(),
  endDate: undefined,
  triggerOn: null,
});
const updateData = (data) => {
  taskRepeatData.value = data;
  startDate.value = data?.startDate;
  endDate.value = data?.endDate;
  repeatType.value = data?.repeatType;
  repeatEndKey.value = data?.repeatEndKey;
  // isDisableInfiniteRepeat.value = data?.isDisableInfiniteRepeat;
  if (!isEmptyStartDate()) errorMessage.value.startDate = '';
  if (!isEmptyEndDate()) errorMessage.value.endDate = '';
};

const isDisableButton = computed<boolean>(() => {
  if (repeatType.value == ETaskRepeatType.None) return false;
  return (
    !startDate.value ||
    (repeatType.value == ETaskRepeatType.Weekly &&
      (!taskRepeatData.value?.subRepeatType?.weeklyOptions ||
        taskRepeatData.value?.subRepeatType?.weeklyOptions?.length == 0))
  );
});

const isConflictNextRepeatDateWithEndDate = computed<boolean>(() => {
  if (
    repeatType.value == ETaskRepeatType.None ||
    !taskRepeatData.value?.endDate
  )
    return false;

  const dayjsUtc = dayjs.utc().startOf('day');
  let nextRepeatDate = dayjsUtc;
  if (repeatType.value == ETaskRepeatType.Daily) {
    nextRepeatDate = dayjsUtc.add(1, 'day');
  }
  if (repeatType.value == ETaskRepeatType.Weekly) {
    // Filter numbers greater than x
    const weeklyOptions =
      taskRepeatData.value?.subRepeatType?.weeklyOptions?.map((x) =>
        parseInt(x?.toString())
      );

    if (!weeklyOptions || weeklyOptions?.length == 0) return false;

    const numbersGreaterThanX =
      weeklyOptions?.filter((num) => num > new Date().getDay()) || [];

    // If there are numbers greater than x, return the smallest one, otherwise return null
    if (numbersGreaterThanX.length > 0) {
      nextRepeatDate = dayjs
        .utc()
        .add(Math.min(...numbersGreaterThanX) - new Date().getDay(), 'day');
    } else {
      nextRepeatDate = dayjs
        .utc()
        .add(
          7 - new Date().getDay() + Math.min(...(weeklyOptions || [])),
          'day'
        );
    }
  }
  if (repeatType.value == ETaskRepeatType.Monthly) {
    {
      switch (taskRepeatData.value?.subRepeatType?.monthlyOptions) {
        case ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth:
          {
            const repeatDateOfMonth =
              taskRepeatData.value?.subRepeatType?.byMonthday &&
              taskRepeatData.value?.subRepeatType?.byMonthday?.length > 0
                ? taskRepeatData.value?.subRepeatType?.byMonthday[0]
                : null;

            if (!repeatDateOfMonth) return false;

            nextRepeatDate = dayjs
              .utc()
              .isAfter(dayjsUtc.date(repeatDateOfMonth), 'day')
              ? dayjsUtc.date(repeatDateOfMonth).add(1, 'month')
              : dayjsUtc.date(repeatDateOfMonth);
          }
          break;
        case ETaskMonthlySubRepeatTypeModel.FirstDayOfTheMonth:
          {
            const repeatDateOfMonth = 1;

            nextRepeatDate = dayjs
              .utc()
              .isAfter(dayjsUtc.date(repeatDateOfMonth), 'day')
              ? dayjsUtc.date(repeatDateOfMonth).add(1, 'month')
              : dayjsUtc.date(repeatDateOfMonth);
          }
          break;
        case ETaskMonthlySubRepeatTypeModel.LastDayOfTheMonth:
          {
            nextRepeatDate = dayjsUtc.isAfter(dayjsUtc.endOf('month'), 'day')
              ? dayjsUtc.endOf('month').add(1, 'month')
              : dayjsUtc.endOf('month');
          }
          break;

        default:
          break;
      }
    }
  }
  if (repeatType.value == ETaskRepeatType.Yearly) {
    // Use the current year
    const year = dayjsUtc.year();

    const month =
      taskRepeatData.value?.subRepeatType?.byMonth &&
      taskRepeatData.value?.subRepeatType?.byMonth?.length > 0
        ? taskRepeatData.value?.subRepeatType?.byMonth[0]
        : null;

    const day =
      taskRepeatData.value?.subRepeatType?.byMonthday &&
      taskRepeatData.value?.subRepeatType?.byMonthday?.length > 0
        ? taskRepeatData.value?.subRepeatType?.byMonthday[0]
        : null;

    if (!month || !day) return false;

    // Create the date using the provided day and month
    const repeatDate = dayjs.utc(`${year}-${month}-${day}`).startOf('day');

    nextRepeatDate = dayjsUtc.isAfter(repeatDate, 'day')
      ? repeatDate.add(1, 'year')
      : repeatDate;
  }

  return dayjs
    .utc(taskRepeatData.value?.endDate)
    .isBefore(nextRepeatDate, 'day');
});
const actionNameCode = computed<string>(() => {
  if (
    !repeatType.value ||
    !Object.prototype.hasOwnProperty.call(repeatTypeByKeys, repeatType.value)
  )
    return '';

  return repeatTypeByKeys[repeatType.value].nameCode;
});
</script>
<template>
  <SynModal
    z-index="z-50"
    container-class="w-max"
    :container-style="'max-height: 95%;min-height: 24rem; min-width: 24rem; overflow: hidden; position: unset;'"
    disable-click-outside
    is-hidden-close
    header-class="p-4 border-b"
    footer-class="border-t"
    style-body-string="position: static; overflow: hidden; padding: 0"
    :is-disable-button="isDisableButton"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    @cancel="$emit('cancel')"
    @confirm="onSaveRepeat"
  >
    <template #header>
      <div class="font-semibold text-current-800">
        {{ $t('COMMON_LABEL_TASK_RECURRENCE') || 'Task recurrence' }}
      </div>
    </template>
    <template #body>
      <SetRepeats
        ref="setRepeatsRef"
        :task-repeat="taskRepeat"
        :start-date="startDate"
        :edit-mode="taskRepeat?.templateId ? 'EDIT' : 'CREATE'"
        :origin-template="originTemplate"
        :is-update-template="isUpdateTemplate"
        :error-message-end-date="errorMessage.endDate"
        :error-message-start-date="errorMessage.startDate"
        trigger-for-update="click"
        @on-save="(value) => $emit('onSave', value)"
        @update-data="updateData"
      />
    </template>
    <template #footer>
      <div
        class="
          p-4
          flex
          justify-between
          items-center
          hover:text-current
          cursor-pointer
          vig-modal-footer
        "
      >
        <div>
          <span
            v-if="isConflictNextRepeatDateWithEndDate"
            class="text-orange-500 text-sm"
          >
            {{
              $t('TASK_LABEL_REPEAT_CONFLIC_NEXT_REPEAT_WITH_END_DATE', {
                optionName: $t(actionNameCode) || '',
              })
            }}
          </span>
        </div>
        <div class="flex-center gap-2">
          <SynButton type-text color="gray" @click="$emit('cancel')">
            <span class="do-not-close-dropdown">
              {{ $t('COMMON_LABEL_CANCEL') }}</span
            >
          </SynButton>
          <SynButton
            class="do-not-close-dropdown"
            :disabled="isDisableButton"
            @click="onSaveRepeat"
          >
            <span class="do-not-close-dropdown">
              {{ $t('COMMON_LABEL_SAVE') }}</span
            >
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
