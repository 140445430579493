<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 612 612"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M204 267.75h-76.5v-76.5h-51v76.5H0v51h76.5v76.5h51v-76.5H204v-51zm255 25.5c43.35 0 76.5-33.15 76.5-76.5s-33.15-76.5-76.5-76.5c-7.65 0-15.3 2.55-22.95 2.55 15.3 22.95 22.95 45.9 22.95 73.95s-7.65 51-22.95 73.95c7.65 0 15.3 2.55 22.95 2.55zm-127.5 0c43.35 0 76.5-33.15 76.5-76.5s-33.15-76.5-76.5-76.5-76.5 33.15-76.5 76.5 33.15 76.5 76.5 76.5zm168.3 56.1c20.4 17.851 35.7 43.351 35.7 71.4v51H612v-51c0-38.25-61.2-63.75-112.2-71.4zm-168.3-5.1c-51 0-153 25.5-153 76.5v51h306v-51c0-51-102-76.5-153-76.5z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
