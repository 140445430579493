export enum NotificationType {
    TASK = 'TASK',
    DAYOFF = 'DAYOFF',
    GROUP = 'GROUP',
    GED = 'GED',
    CALENDAR = 'CALENDAR',
    DEPARTMENT = 'DEPARTMENT',
}

export enum NotificationCategory {
    ALL = 'all',
    NEW = 'new',
    UPDATED = 'updated',
    FINISHED = 'finished',
    OTHER = 'other',
}
