<script setup lang="ts">
import { AdminType } from '@/ui/common/constants/constant';
import { computed, onMounted, ref, watch } from 'vue';
import userStore from '@/store/user';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { arrToObj } from '@/ui/helpers/utils';
import { GroupMemberRole } from '@/application/enums/GroupEnums';

const props = defineProps<{
  modelValue: any[];
  users: any[];
  disabledUsers: any[];
  isIgnoreMe?: boolean;
}>();

const emits = defineEmits(['update:modelValue']);

const _userStore = userStore();

const selectedUserById = ref<{ [userId: number]: boolean }>({});
const searchText = ref<string>();

const usersList = computed(() => props.users || _userStore.allActiveUsers);
const disabledUserById = computed(() =>
  arrToObj(
    props.disabledUsers,
    (u) => u?.userId || u?.id,
    () => true
  )
);

const unSelectedUsers = computed<any[]>(() =>
  usersList.value?.filter(
    (user) =>
      (!props.isIgnoreMe || !user?.isMe) &&
      !selectedUserById.value[user?.userId || user?.id]
  )
);

const selectedUsers = computed<any[]>(() =>
  usersList.value?.filter(
    (user) => selectedUserById.value[user?.userId || user?.id]
  )
);

const filteredUsers = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return unSelectedUsers.value;

  return (unSelectedUsers.value || []).filter(
    (user) =>
      (user?.fullName && ignoreUnicode(user?.fullName).includes(searchTxt)) ||
      (user?.name && ignoreUnicode(user?.name).includes(searchTxt))
  );
});

watch(
  () => props.modelValue,
  () => _writeModel()
);

onMounted(() => {
  _writeModel();
});

const onUserSelectChange = () => {
  _emitModel();
};

const onUserRemoveClick = (userId) => {
  selectedUserById.value[userId] = false;

  _emitModel();
};

const _writeModel = () => {
  selectedUserById.value = arrToObj(
    props.modelValue,
    (u) => u?.userId || u?.id,
    () => true
  );
};

const _emitModel = () => {
  const selectedUsers = usersList.value?.filter(
    (user) => selectedUserById.value[user?.userId || user?.id]
  );

  emits('update:modelValue', selectedUsers);

  if (filteredUsers.value?.length == 0) searchText.value = '';
};
</script>

<template>
  <div class="h-full flex flex-col gap-1">
    <!--HEADER-->
    <div class="flex items-center gap-4">
      <div class="flex-1 p-2">
        <VigSearchBox
          v-model="searchText"
          class="w-full"
          input-class="text-sm"
          autofocus
        />
      </div>
      <div class="flex-1 font-medium text-current-600">
        <span>
          {{ $t('GED_NUM_SELECTED', { number: selectedUsers?.length }) }}</span
        >
      </div>
    </div>

    <!--BODY-->
    <div class="flex-1 overflow-hidden flex items-start gap-4">
      <!--USERS LIST-->
      <div class="h-full flex-1 overflow-auto small-scrollbar">
        <label
          v-for="user in filteredUsers"
          :key="user?.userId || user?.id"
          :for="`ckb-select-user-${user?.userId || user?.id}`"
          role="button"
          class="
            flex
            items-center
            px-3
            py-2
            space-x-3
            rounded
            hover:bg-gray-100
          "
        >
          <input
            :id="`ckb-select-user-${user?.userId || user?.id}`"
            v-model="selectedUserById[user?.userId || user?.id]"
            type="checkbox"
            class="vig-checkbox"
            @change="onUserSelectChange"
          />
          <SynAvatarStatusActive
            :id="user?.userId || user?.id"
            :avatar="user?.avatarUrl || user?.avatar"
            custom-class="h-6 w-6"
            :name="user?.fullName || user?.name"
            :is-user="true"
            :is-visitor="user?.adminType === AdminType.Visitor"
          />
        </label>

        <!--NO SEARCH RESULT-->
        <div v-if="searchText && !filteredUsers?.length" class="py-16">
          <SynAnimation
            name="searchIcon"
            :loop="false"
            stype="width: 100px; height: 100px"
            :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
          />
        </div>
      </div>

      <!--USERS SELECTED-->
      <div
        class="h-full flex-1 overflow-auto small-scrollbar flex flex-col gap-1"
      >
        <div
          v-for="user in selectedUsers"
          :key="user?.userId || user?.id"
          class="flex items-center px-3 py-2 space-x-3 bg-current-50 rounded"
        >
          <SynAvatarStatusActive
            :id="user?.userId || user?.id"
            :avatar="user?.avatarUrl || user?.avatar"
            custom-class="h-6 w-6"
            :name="user?.fullName || user?.name"
            :is-user="true"
            :is-visitor="user?.adminType === AdminType.Visitor"
          />
          <div
            v-if="user?.groupId && user?.role === GroupMemberRole.ADMIN"
            class="font-semibold text-xs text-current-500"
          >
            {{ $t('COMMON_LABEL_ADMIN') }}
          </div>
          <VigButton
            v-if="!user?.isMe && !disabledUserById[user?.userId || user?.id]"
            ghost
            padding="p-1"
            rounded="rounded-full"
            icon="Close"
            @click="onUserRemoveClick(user?.userId || user?.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
