<script setup lang="ts">
import { watch, ref, onMounted, computed } from 'vue';
import $constants from '@/ui/plugins/constants';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const linkDefault = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/images/`;
const props = withDefaults(
  defineProps<{
    path: string;
    size?: 'original' | 'mini' | 'small' | 'large';
    customClass?: string;
    errorType?: 'IMAGE' | 'AVATAR';
    isCheckError?: boolean;
    objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
    isNoSelect?: boolean;
  }>(),
  {
    errorType: 'IMAGE',
    isCheckError: true,
    objectFit: 'cover',
  }
);

const emit = defineEmits(['error']);

const imgSrc = ref();

watch([() => props.path, () => props.size], () => {
  _processImgSrc();
});

onMounted(() => {
  _processImgSrc();
});

const isErrorMsg = ref(false);
const isLoaded = ref(0);

const onError = () => {
  if (isLoaded.value > 1) return;
  imgSrc.value = imgSrc.value?.replace(
    `/${props.size}/`,
    `/${$constants.IMAGE.SIZE.ORIGINAL}/`
  );
  isErrorMsg.value = true;
  emit('error', true);
};

const onLoaded = () => {
  if (isLoaded.value > 0) return;
  isLoaded.value++;
  isErrorMsg.value = false;

  emit('error', false);
};

const _processImgSrc = () => {
  isErrorMsg.value = false;
  isLoaded.value = 0;
  if (!props.size) return (imgSrc.value = props.path);

  const currentSize = Object.values($constants.IMAGE.SIZE)
    .filter((size) => props.path?.includes(size))
    ?.toString();

  imgSrc.value = currentSize
    ? props.path?.replace(`/${currentSize}/`, `/${props.size}/`)
    : props.path;
};

const srcView = computed(() => {
  if (!props.isCheckError) return imgSrc.value;
  if (!isErrorMsg.value) return imgSrc.value;
  else {
    if (props?.errorType === 'IMAGE')
      return linkDefault + 'image-not-viewer.png';
    else return linkDefault + 'user_default.png';
  }
});
</script>

<template>
  <span
    v-if="isLoaded == 0"
    class="w-full h-full opacity-10 absolute z-10 inset-0 animate-pulse"
  >
  </span>
  <img
    v-bind="$attrs"
    :src="srcView"
    class="relative vig-image"
    :no-select="isNoSelect ? 'true' : 'false'"
    :class="customClass"
    :style="`object-fit: ${objectFit}`"
    :onerror="onError"
    :onload="onLoaded"
    alt=""
  />
</template>
<style lang="scss">
.vig-image[no-select='true'] {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  pointer-events: none;
}
</style>
