<template>
  <svg
    viewBox="0 0 511.552 511.553"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="path"
      d="M479.615 171.46C468.689 174.718 464.866 181.806 468.146 192.725C477.56 224.445 479.756 256.656 474.736 289.36C469.716 322.064 457.959 352.134 439.465 379.57C420.971 407.005 397.509 429.184 369.077 446.107C340.645 463.03 309.962 473.078 277.028 476.252C244.094 479.427 212.056 475.423 180.916 464.242C149.775 453.061 122.509 435.771 99.1153 412.373C75.722 388.974 58.4385 361.703 47.2646 330.56C36.0908 299.417 32.0948 267.379 35.2766 234.446C38.4583 201.512 48.5138 170.832 65.4429 142.404C82.3719 113.976 104.557 90.518 131.996 72.0305C159.436 53.543 189.509 41.7929 222.214 36.7802C254.919 31.7675 287.13 33.9713 318.847 43.3917C329.759 46.6457 336.841 42.8171 340.095 31.9058C343.349 20.9945 339.521 13.9118 328.609 10.6578C291.992 -0.22406 254.802 -2.77295 217.042 3.01114C179.282 8.79523 144.561 22.3593 112.88 43.7032C81.1982 65.0471 55.5852 92.1302 36.0406 124.952C16.4961 157.775 4.88879 193.198 1.21881 231.222C-2.45123 269.245 2.16693 306.235 15.0732 342.189C27.9795 378.143 47.9399 409.625 74.9545 436.634C101.969 463.643 133.455 483.598 169.412 496.497C205.369 509.396 242.359 514.007 280.382 510.329C318.405 506.652 353.826 495.037 386.644 475.486C419.462 455.935 446.54 430.317 467.878 398.631C489.215 366.945 502.773 332.222 508.549 294.46C514.326 256.699 511.77 219.51 500.88 182.895C497.637 171.932 490.549 168.121 479.615 171.46Z"
      fill-rule="nonzero"
    />
    <path
      id="path"
      d="M171.937 256.077L163.609 288.572C158.283 306.715 162.117 322.656 175.112 336.392C184.459 345.527 195.666 349.987 208.733 349.773C213.567 349.76 218.323 349.146 223.001 347.93L255.462 339.601C267.432 336.43 277.763 330.434 286.455 321.613L490.47 117.632C502.148 107.573 508.618 94.8633 509.88 79.5023C511.143 64.1412 506.834 50.5456 496.955 38.7154L472.789 14.5831C460.967 4.7171 447.383 0.415222 432.036 1.67746C416.69 2.9397 403.992 9.40335 393.941 21.0685L189.96 225.084C181.121 233.768 175.114 244.099 171.937 256.077ZM196.65 297.037L204.978 264.576C206.623 258.639 209.649 253.519 214.058 249.216L418.073 45.1666C423.784 39.0586 430.816 35.7931 439.167 35.3703C442.799 35.1848 445.973 36.2998 448.691 38.7154L472.823 62.8477C478.523 68.548 477.26 82.5426 466.338 93.4653L262.323 297.48C258.022 301.893 252.902 304.92 246.963 306.56L214.502 314.888C208.924 316.87 203.838 315.994 199.244 312.26C195.497 307.677 194.632 302.602 196.65 297.037Z"
      fill-rule="nonzero"
    />
  </svg>
</template>
