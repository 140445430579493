import clickOutSide from '@/ui/directive/clickOutSide';
import scrollInfinite from '@/ui/directive/scrollInfinite';
import scrollInfinite2 from '@/ui/directive/scrollInfinite2';
import twemoji from '@/ui/directive/twemoji';
import vigTooltip from '@/ui/directive/vigTooltip';
import vigFocus from '@/ui/directive/vigFocus';
import dropZone from '@/ui/directive/dropZone';
import permission from '@/ui/directive/permission';
import perfectScrollbar from '@/ui/directive/perfect-scrollbar';
import vIntersectionObserver from '@/ui/directive/v-intersection-observer';
import vListenScroll from '@/ui/directive/v-listen-scroll';
import elementActive from '@/ui/directive/v-element-active';
import addTabIndex from '@/ui/directive/addTabIndex';
import permissionFunction from '@/ui/directive/permission-function';
import permissionScreen from '@/ui/directive/permission-screen';
import encodeHtml from '@/ui/directive/encode-html';
import permissionModel from '@/ui/directive/permission-model';
import dragToScroll from '@/ui/directive/drag-to-scroll';
import systemConfig from '@/ui/directive/system-config';
import permissionByAdminType from '@/ui/directive/permission-by-admin-type';
import personalConfig from '@/ui/directive/personal-config';

export default function usingDirective(app) {
    app.use(clickOutSide);
    app.use(scrollInfinite);
    app.use(scrollInfinite2);
    app.use(twemoji);
    app.use(vigTooltip);
    app.use(vigFocus);
    app.use(dropZone);
    app.use(permission);
    app.use(perfectScrollbar);
    app.use(vIntersectionObserver);
    app.use(vListenScroll);
    app.use(elementActive);
    app.use(addTabIndex);
    app.use(permissionFunction);
    app.use(permissionScreen);
    app.use(encodeHtml);
    app.use(permissionModel);
    app.use(dragToScroll);
    app.use(systemConfig);
    app.use(permissionByAdminType);
    app.use(personalConfig);
}
