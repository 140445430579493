<script setup>
import dayjs from 'dayjs';

const props = defineProps({
  modelValue: {
    type: Date,
    default: new Date(),
  },
  inputClass: {
    type: String,
    default: '',
  },
  minDate: {
    type: Date,
    default: null,
  },
  maxDate: {
    type: Date,
    default: null,
  },
  minWidth: {
    type: String,
    default: '',
  },
  dateConfig: {
    type: String,
    default: () => {
      return { type: 'string', mask: 'YYYY-MM-DD' };
    },
  },
  masks: {
    type: Object,
    default: () => {},
  },
  locate: {
    type: String,
    default: 'en',
  },
  placeholder: {
    type: String,
    default: 'Type something...',
  },
});
defineEmits(['update:modelValue']);

const attrs = [
  {
    key: 'today',
    dot: {
      color:
        dayjs(new Date()).format('MM-DD-YYYY') ===
        dayjs(props.modelValue).format('MM-DD-YYYY')
          ? 'yellow'
          : 'current',
      class:
        dayjs(new Date()).format('MM-DD-YYYY') ===
        dayjs(props.modelValue).format('MM-DD-YYYY')
          ? 'mb-1 z-70'
          : '',
    },
    dates: new Date(),
  },
];
</script>

<template>
  <v-date-picker
    :value="modelValue"
    :locale="$locale"
    :model-config="dateConfig"
    :attributes="attrs"
    :popover="{ visibility: 'click' }"
    :min-date="minDate"
    :max-date="maxDate"
    :masks="masks"
    color="current"
    :first-day-of-week="2"
    @update:model-value="(value) => $emit('update:modelValue', value)"
  >
    <template #default="{ inputEvents }">
      <div class="relative">
        <input
          type="text"
          class="vig-input"
          :class="inputClass"
          :value="modelValue"
          :placeholder="placeholder"
          v-on="inputEvents"
        />
        <button
          v-if="modelValue"
          class="
            absolute
            top-1/2
            right-1
            -mt-3
            w-6
            h-6
            flex-center
            rounded-full
            hover:bg-gray-100
          "
          @click="$emit('update:modelValue', null)"
        >
          <SynIcon name="Close" />
        </button>
        <slot name="extend"></slot>
      </div>
    </template>
  </v-date-picker>
</template>
