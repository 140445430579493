<script setup lang="ts">
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { TaskListTab } from '@/domain/enums/taskEnum';
// import UserById from '@/ui/components/user/UserById.vue';
import { ref } from 'vue';
import FilterTaskTableColumn from '@/ui/modules/task/filter/FilterTaskTableColumn.vue';
import taskCustomConfigStore from '@/store/task/task-custom-config';
import SettingViewDefaultItem from '@/ui/modules/task/filter/SettingViewDefaultItem.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
defineProps<{
  currentTab: TaskListTab;
  actionHidden?: any[];
  userId?: number;
  domainId?: number;
  isAllowImportTask?: boolean;
}>();

defineEmits<{
  (e: 'onShowProfile'): void;
  (e: 'onExportTask'): void;
  (e: 'onImportTask'): void;
  (e: 'onManagerTab'): void;
}>();

const isOpenFilterColumn = ref<boolean>(false);

const onCloseDropdown = () => {
  taskCustomConfigStore().fetchUpdateTaskColumn();
  isOpenFilterColumn.value = false;
};
</script>

<template>
  <VigDropdown :arrow="false" @on-dropdown-close="onCloseDropdown">
    <template #dropdown-toggle>
      <button
        class="w-8 h-8 flex-center rounded-full hover:bg-gray-100 relative"
      >
        <SynIcon
          name="dots-vertical"
          class="w-4 h-4"
          custom-class="fill-gray-500"
        />
      </button>
    </template>
    <template #dropdown-menu>
      <div
        class="w-48 flex flex-col py-1 rounded text-sm text-gray-600 relative"
      >
        <!-- <div
          v-if="!actionHidden?.includes('profile')"
          v-permission-model
          class="list-li flex items-center space-x-2 dropdown-item"
          @click="$emit('onShowProfile')"
        >
          <UserById :user-id="userId" is-hidden-name />
          <span>{{ $t('COMMON_LABEL_PROFILE') }}</span>
        </div> -->
        <div
          v-if="!actionHidden?.includes('information')"
          class="list-li flex items-center space-x-2 dropdown-item"
          @click="$emit('onShowProfile')"
        >
          <div class="flex-center w-6 h-6">
            <syn-icon
              name="edit"
              is-active
              class="fill-gray-500 text-gray-500"
            />
          </div>
          <span>{{ $t('COMMON_LABEL_EDIT') }}</span>
        </div>
        <div
          class="list-li flex items-center justify-between space-x-2"
          @click="isOpenFilterColumn = !isOpenFilterColumn"
        >
          <div class="flex-center space-x-2">
            <div class="flex-center w-6 h-6">
              <syn-icon
                name="edit"
                is-active
                class="fill-gray-500 text-gray-500"
              />
            </div>
            <span>
              {{ $t('TASK_TABLE_FILTER_COLUMN') || 'Filter column' }}
            </span>
          </div>
          <div class="flex-center w-6 h-6">
            <SynIcon
              :name="'sort-down'"
              custom-class="h-2.5 w-2.5 fill-gray-500"
              :class="!isOpenFilterColumn ? '-rotate-90' : ''"
            />
          </div>
        </div>
        <div
          v-if="!actionHidden?.includes('export_task')"
          v-permission-function="{
            functionCode: ALL_FUNCTIONS.TASK.EXPORT_TASKS,
            onContinue: () => $emit('onExportTask'),
          }"
          class="list-li flex items-center space-x-2 dropdown-item"
          @click="$emit('onExportTask')"
        >
          <div class="flex-center w-6 h-6">
            <syn-icon name="export" class="fill-gray-600" />
          </div>
          <span>{{ $t('TASK_LABEL_EXPORTING_CSV_FILE') }}</span>
        </div>
        <div
          v-if="isAllowImportTask"
          v-system-config="{
            functionCode: 'ADVANCED_FUNCTION_ALLOWED_IMPORT_TASK',
            functionValue: '1',
          }"
          v-permission-function="{
            functionCode: ALL_FUNCTIONS.TASK.IMPORT_TASKS,
            onContinue: () => $emit('onImportTask'),
          }"
          class="list-li flex items-center space-x-2 dropdown-item"
          @click="$emit('onImportTask')"
        >
          <div class="flex-center w-6 h-6">
            <syn-icon name="import" class="fill-gray-600" />
          </div>
          <span>{{ $t('TASK_LABEL_IMPORTING_FILE') }}</span>
        </div>

        <div
          v-if="
            userId === getCurrentUserId() &&
            !actionHidden?.includes('manager_tab')
          "
          class="list-li flex items-center space-x-2 dropdown-item"
          @click="$emit('onManagerTab')"
        >
          <div class="flex-center w-6 h-6">
            <syn-icon name="view-board" class="fill-gray-500 text-gray-500" />
          </div>
          <span>{{ $t('SIDEBAR_LABEL_MANAGEMENT') }}</span>
        </div>

        <div
          v-if="isOpenFilterColumn"
          class="
            absolute
            top-0
            left-0
            rounded-md
            p-4
            transform
            -translate-x-full
            bg-white
            shadow-xl
            overflow-auto
            small-scrollbar
          "
          style="max-width: 60rem"
        >
          <FilterTaskTableColumn />
        </div>
        <SettingViewDefaultItem class="pl-2" />
      </div>
    </template>
  </VigDropdown>
</template>
