<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import LineChart from '@/ui/common/plugins/chart/LineChart.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import datetimeComposable from '@/ui/composables/common/datetime-composable';
import { roundToNearestMultipleOfFive } from '@/ui/hooks/commonFunction';
const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    viewType: 1 | 2 | 3 | 4;
  };
  isHiddenLegend?: boolean;
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    creationTime: string;
    completedTasks: number;
    overdueTasks: number;
    activeTasks: number;
    timeLegend: string;
  }[]
>([]);

const datasets = ref<any>([]);

const { formatDate } = datetimeComposable();

const initComponent = async () => {
  isLoading.value = true;
  const res = await WorkPerformanceRepository.getWorkPerformance({
    dateFrom: props.filterData?.dateFrom,
    dateTo: props.filterData?.dateTo,
    departmentIds: props.filterData?.departmentIds,
    viewType: props.filterData?.viewType,
  });
  resultData.value = res?.result;

  prepareDataForChart();

  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.viewType == old.viewType
    )
      return;

    initComponent();
  }
);

const labelsData = ref<string[]>([]);
const prepareDataForChart = () => {
  const isSkipLabel = resultData.value?.length > 10;
  labelsData.value = resultData.value?.map((data, index) => {
    if (isSkipLabel && index % 2 == 0) return '';

    return props.filterData?.viewType == 1
      ? formatDate(data?.creationTime, 'day_month', {
          isIgnoreTimezone: true,
        })
      : data?.timeLegend;
  });

  datasets.value = [
    {
      label: 'COMMON_LABEL_TASK_ACTIVE' || 'Active',
      data: resultData.value?.map((data) => data?.activeTasks),
      backgroundColor: '#60a5fa',
      borderColor: '#60a5fa',
      hoverBackgroundColor: '#666',
      minBarLength: 2,
      borderWidth: 1.5,
    },
    {
      label: 'TASK_UPDATE_MULTIPLE_SET_FINISHED' || 'Finished',
      data: resultData.value?.map((data) => data?.completedTasks),
      backgroundColor: '#4bb0b2',
      borderColor: '#4bb0b2',
      hoverBackgroundColor: '#666',
      minBarLength: 2,
      borderWidth: 1.5,
    },
    {
      label: 'COMMON_LABEL_OVERDUE' || 'Overdue',
      data: resultData.value?.map((data) => data?.overdueTasks),
      backgroundColor: '#fca5a5',
      borderColor: '#fca5a5',
      hoverBackgroundColor: '#666',
      minBarLength: 2,
      borderWidth: 1.5,
    },
  ];
};
const datasetsOptions = computed(() => {
  const maxValue = Math.max(
    ...resultData.value?.map((data) => {
      return (
        Math.max(
          ...[data.activeTasks, data.completedTasks, data.overdueTasks]
        ) || 0
      );
    })
  );

  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        border: {
          display: false,
        },
        grid: {
          color: function () {
            return '#e5e7eb';
          },
          drawBorder: false,
        },

        min: 0,
        suggestedMax: roundToNearestMultipleOfFive(maxValue + maxValue * 0.1, {
          min: maxValue + 1,
          isRoundUp: true,
        }),
        grace: '5%',
        ticks: {
          callback: (tickValue) => {
            if (Number.isInteger(tickValue)) return tickValue;
          },
        },
      },
      x: {
        offset: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        // displayColors: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: '#6b7280',
        bodyColor: '#6b7280',
        borderWidth: 1.5,

        borderColor: '#e5e7eb',
        callbacks: {
          label: (tooltipItems) => {
            return (
              tooltipItems?.formattedValue +
              ' ' +
              translate(tooltipItems?.dataset?.label).toLowerCase()
            );
          },
        },
      },
    },
  };
});
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <div v-else class="flex-1 h-full w-full flex-center flex-col gap-4 p-4">
    <div class="w-full h-full flex-1 bg-white rounded-md relative flex-center">
      <div class="absolute -left-4 w-0 flex-center">
        <span>
          <p class="w-max break-keep -rotate-90 text-gray-500 text-xs">
            {{ $t('COMMON_LABEL_TASK_COUNT') }}
          </p>
        </span>
      </div>
      <!-- <VerticalBarChart
      v-if="datasets?.length > 0"
      :labels="labelsByPlanTodoDate"
      :datasets="datasets"
      :options="datasetsOptions"
      :is-show-legend="false"
    /> -->
      <LineChart
        v-if="datasets?.length > 0"
        :datasets="datasets"
        :labels-data="labelsData"
        :options="datasetsOptions"
      />
    </div>
    <div class="bg-white flex-center gap-2">
      <div v-for="(item, index) in datasets" :key="index" class="flex-center">
        <div class="flex-center gap-1">
          <span
            class="w-4 border-2"
            :style="{
              borderColor: item?.borderColor,
            }"
          ></span>
          <span class="text-xs text-gray-500">
            {{ $t(item?.label) || item?.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
