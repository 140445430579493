<script setup lang="ts">
// import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';
import { computed, ref } from 'vue';
import { TaskApprovalStatus } from '@/application/types/task/task.types';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import TaskCommentInput from '@/ui/common/plugins/ckeditor/TaskCommentInput.vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { EEvidenceType } from '@/application/constants/system-config.const';
// import TaskWorkflowChooseNextStep from '@/ui/modules/task/workflow/TaskWorkflowChooseNextStep.vue';

const props = defineProps<{
  task: ITaskDetailClass;
  approvalStatus?: TaskApprovalStatus;
  viewMode:
    | 'FINISH_TASK_WITH_EVIDENCE'
    | 'REQUEST_APPROVAL_WITH_EVIDENCE'
    | 'APPROVE_OR_REJECT';
}>();

const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'confirm', comment: any): void;
}>();

const commentObjectCreate = ref({
  isCreateComment: true,
  isSkipProvidingEvidence: props.viewMode == 'APPROVE_OR_REJECT',
  content: '',
  attachments: {
    files: [] as any[],
    notes: [] as any[],
  },
  mentionUserIds: [],
  mentionGroup: false,
});

const onCancelComment = () => {};

const commentTaskInputRef = ref<any>(null);
const onClickApprovalButton = () => {
  if (
    commentObjectCreate.value?.content ||
    commentObjectCreate.value?.attachments?.files?.length > 0 ||
    commentObjectCreate.value?.attachments?.notes?.length > 0
  )
    commentTaskInputRef.value?.onSaveCommentInput();
  else onSave();
};
const onSave = (attachments?) => {
  if (isDisabled.value) return;

  emit('confirm', {
    ...commentObjectCreate.value,
    isCreateComment: !commentObjectCreate.value.isSkipProvidingEvidence,
    attachments: {
      files: attachments?.addedFiles || [],
      notes: attachments?.addedNotes || [],
    },
    nextStepId: selectedNextStepId.value,
  });
};

// const onFinishAndNextStep = (nextStepId) => {
//   emit('confirm', {
//     nextStepId,
//   });
// };

// const isNextStepInWorkflow = computed<boolean>(() => {
//   return !!props.task?.workFlowTaskId;
// });
const isProvidingEvidenceWhenFinish = computed<boolean>(() => {
  return !!props.task?.finishProofEnabled;
});

const evidenceList = computed<any[]>(() => {
  if (
    !isProvidingEvidenceWhenFinish.value ||
    !props.task?.finishProofTypes ||
    props.task?.finishProofTypes?.length == 0
  )
    return [];

  return props.task?.finishProofTypes
    ?.split(';')
    ?.filter((key) => key)
    ?.map((key) => {
      return {
        key,
        textCode: `TASK_LABEL_PROVIDE_EVIDENCE_${key?.toUpperCase()}`,
      };
    });
});

const isDisabled = computed<boolean>(() => {
  // if (isNextStepInWorkflow.value) return !selectedNextStepId.value;
  if (props.viewMode == 'APPROVE_OR_REJECT') return false;

  if (
    !evidenceList.value ||
    evidenceList.value?.length == 0 ||
    commentObjectCreate.value?.isSkipProvidingEvidence
  )
    return false;

  return !evidenceList.value?.some((o) => {
    switch (o.key) {
      case EEvidenceType.IMAGE:
        return (
          commentObjectCreate.value?.attachments?.files?.filter((f) =>
            f?.type?.startsWith('image')
          )?.length > 0
        );
      case EEvidenceType.DOCUMENT:
        return (
          commentObjectCreate.value?.attachments?.files?.filter(
            (f) => !f?.type?.startsWith('image')
          )?.length > 0
        );
      case EEvidenceType.NOTE:
        return commentObjectCreate.value?.attachments?.notes?.length > 0;
      case EEvidenceType.COMMENT:
        return !!commentObjectCreate.value?.content;

      default:
        return false;
    }
  });
});

const uiByApprovalStatus = computed<{
  titleCode: string;
  labelButtonCode: string;
  color: string;
}>(() => {
  let result = {
    titleCode: 'TASK_LABEL_APPROVE_FOR_TASK',
    labelButtonCode: 'COMMON_LABEL_APPROVAL',
    color: 'current',
  };
  switch (props.viewMode) {
    case 'FINISH_TASK_WITH_EVIDENCE':
      result = {
        titleCode: 'TASK_LABEL_REQUEST_FINISH_TASK',
        labelButtonCode: 'COMMON_LABEL_FINISH',
        color: 'current',
      };
      break;
    case 'REQUEST_APPROVAL_WITH_EVIDENCE':
      result = {
        titleCode: 'TASK_LABEL_REQUEST_APPROVE_FOR_TASK',
        labelButtonCode: 'COMMON_LABEL_APPROVAL_REQUEST',
        color: 'current',
      };
      break;
    case 'APPROVE_OR_REJECT':
      {
        if (props.approvalStatus == TaskApprovalStatus.WAITING)
          result = {
            titleCode: 'TASK_LABEL_REQUEST_APPROVE_FOR_TASK',
            labelButtonCode: 'COMMON_LABEL_APPROVAL_REQUEST',
            color: 'current',
          };
        if (props.approvalStatus == TaskApprovalStatus.REJECTED)
          result = {
            titleCode: 'TASK_LABEL_REJECT_FOR_TASK',
            labelButtonCode: 'TASK_LABEL_REJECT',
            color: 'red',
          };
      }
      break;

    default:
      break;
  }

  return result;
});

const selectedNextStepId = ref<string | null>(null);
</script>

<template>
  <SynModal
    container-class="w-11/12 max-w-lg"
    container-style=""
    style-body="p-0"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div
        v-html="
          $t(uiByApprovalStatus?.titleCode, {
            taskCode: formatTaskCode(task?.code) || '',
          })
        "
      ></div>
    </template>

    <template #body>
      <div class="px-4 flex flex-col gap-4 pt-2">
        <!-- <TaskWorkflowChooseNextStep
          v-if="task?.workFlowTaskId"
          v-model="selectedNextStepId"
          view-mode="TASK_VIEW"
          :task-id="task?.id"
          :task-detail="task"
          :default-task-workflow="task.taskWorkflow"
          @save="onFinishAndNextStep"
        /> -->
        <div
          v-if="viewMode == 'FINISH_TASK_WITH_EVIDENCE'"
          class="w-full flex flex-col"
        >
          <template v-if="commentObjectCreate.isSkipProvidingEvidence">
            <div>
              <span class="text-sm">
                {{ $t('TASK_LABEL_FINISH_WITHOUT_EVIDENCE') }}
              </span>
            </div>
          </template>
          <template v-else>
            <span
              class="
                italic
                cursor-pointer
                text-sm text-gray-500
                inline
                space-x-1
              "
            >
              <span class=""
                >{{
                  $t(
                    evidenceList?.length <= 1
                      ? 'TASK_LABEL_PROVIDE_EVIDENCE_DESCRIPTION_SHORT'
                      : 'TASK_LABEL_PROVIDE_EVIDENCE_DESCRIPTION'
                  )
                }}:</span
              >
              <span class="text-current font-semibold uppercase">
                <template v-for="(evi, index) in evidenceList" :key="evi?.key">
                  {{ $t(evi?.textCode) || evi?.textCode || 'Evidence'
                  }}<template v-if="index < evidenceList?.length - 1"
                    >,
                  </template> </template
                >.
              </span>
            </span>
          </template>
        </div>
        <div
          v-else-if="viewMode == 'REQUEST_APPROVAL_WITH_EVIDENCE'"
          class="w-full flex flex-col"
        >
          <template v-if="commentObjectCreate.isSkipProvidingEvidence">
            <div>
              <span class="text-sm">
                {{ $t('TASK_LABEL_REQUEST_APPROVAL_WITHOUT_EVIDENCE') }}
              </span>
            </div>
          </template>
          <template v-else>
            <span
              class="
                italic
                cursor-pointer
                text-sm text-gray-500
                inline
                space-x-1
              "
            >
              <span class=""
                >{{
                  $t(
                    evidenceList?.length <= 1
                      ? 'TASK_LABEL_PROVIDE_EVIDENCE_DESCRIPTION_SHORT_REQUEST_APPROVAL'
                      : 'TASK_LABEL_PROVIDE_EVIDENCE_DESCRIPTION_REQUEST_APPROVAL'
                  )
                }}:</span
              >
              <span class="text-current font-semibold uppercase">
                <template v-for="(evi, index) in evidenceList" :key="evi?.key">
                  {{ $t(evi?.textCode) || evi?.textCode || 'Evidence'
                  }}<template v-if="index < evidenceList?.length - 1"
                    >,
                  </template> </template
                >.
              </span>
            </span>
          </template>
        </div>
        <div v-else class="flex gap-2">
          <span
            class="italic"
            v-html="
              $t(
                approvalStatus == TaskApprovalStatus.REJECTED
                  ? 'TASK_LABEL_REJECT_CONFIRM_TITLE'
                  : 'TASK_LABEL_APPROVAL_CONFIRM_TITLE'
              )
            "
          >
          </span>

          <AtomTooltip v-if="approvalStatus == TaskApprovalStatus.REJECTED">
            <template #trigger>
              <div class="flex-center rounded w-6 h-6 relative">
                <SynIcon name="info" custom-class="w-4 h-4 fill-orange-500" />
              </div>
            </template>
            <template #body>
              <div
                class="p-4 text-left bg-white flex flex-col space-y-2 rounded"
              >
                <div class="flex gap-2">
                  <span class="text-orange-500 text-sm">
                    {{ $t('TASK_LABEL_CANCEL_REJECT_DESCRIPTION') }}
                  </span>
                </div>
              </div>
            </template>
          </AtomTooltip>
        </div>

        <!-- EVIDENCE -->

        <div v-if="!commentObjectCreate.isSkipProvidingEvidence" class="pt-2">
          <!-- <TaskDescriptionInput
            ref="taskDescriptionRef"
            v-model="myComment"
            :placeholder="$t('TASK_CREATE_FORM_LABEL_NOTE')"
            :is-action="false"
            custom-class="task-approval-input"
            :max-length="300"
            is-hidden-max-length
            @on-close="onCancelComment"
          /> -->
          <TaskCommentInput
            ref="commentTaskInputRef"
            v-model="commentObjectCreate.content"
            v-model:mentionUserIds="commentObjectCreate.mentionUserIds"
            v-model:mentionGroup="commentObjectCreate.mentionGroup"
            v-model:attachments="commentObjectCreate.attachments"
            :current-task="task"
            :notes="commentObjectCreate?.attachments?.notes"
            :files="commentObjectCreate?.attachments?.files"
            edit-mode="CREATE"
            is-hidden-save-button
            is-open-full-mode
            is-auto-focus
            @on-close="onCancelComment"
            @on-save="onSave"
          />
        </div>

        <span
          v-else-if="viewMode == 'APPROVE_OR_REJECT'"
          class="italic underline cursor-pointer text-sm text-gray-500"
          @click="commentObjectCreate.isSkipProvidingEvidence = false"
        >
          {{ $t('GED_NOTE_NEW') }}
        </span>
      </div>
    </template>
    <template #footer>
      <div class="p-4 flex justify-between items-center">
        <div>
          <div
            v-if="
              (viewMode == 'FINISH_TASK_WITH_EVIDENCE' ||
                viewMode == 'REQUEST_APPROVAL_WITH_EVIDENCE') &&
              task?.finishProofSkipAllowed
            "
            class="flex"
          >
            <AtomSwitch
              v-model="commentObjectCreate.isSkipProvidingEvidence"
              :label="$t('TASK_LABEL_SKIP_EVIDENCE')"
              size="small"
              @update:model-value="
                commentObjectCreate.isCreateComment =
                  !commentObjectCreate.isSkipProvidingEvidence
              "
            />
          </div>
        </div>
        <div class="flex-center gap-2">
          <SynButton type-text color="gray" @click="$emit('onClose')">
            <span> {{ $t('COMMON_LABEL_CANCEL') }}</span>
          </SynButton>
          <SynButton
            :color="uiByApprovalStatus?.color"
            :disabled="isDisabled"
            @click="onClickApprovalButton"
          >
            <span>
              {{ $t(uiByApprovalStatus?.labelButtonCode) }}
            </span>
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
