<template>
  <svg
    v-if="!isActive"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 511.99995 511"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m494.503906 130.847656-115.933594-115.933594c-9.5625-9.566406-19.265624-14.414062-28.832031-14.414062-13.132812 0-28.472656 9.992188-28.472656 38.148438v37.066406c-164.273437 7.226562-301.015625 132.4375-321.152344 296.894531-.832031 6.777344 3.019531 13.257813 9.367188 15.769531 1.796875.710938 3.664062 1.054688 5.511719 1.054688 4.675781 0 9.214843-2.191406 12.105468-6.140625 55.34375-75.617188 144.207032-120.761719 237.703125-120.761719 18.835938 0 37.777344 1.84375 56.464844 5.492188v39.636718c0 28.15625 15.339844 38.148438 28.472656 38.148438h.003907c9.566406 0 19.265624-4.851563 28.828124-14.414063l115.933594-115.933593c23.328125-23.328126 23.328125-61.285157 0-84.613282zm-21.207031 63.40625-115.933594 115.929688c-2.230469 2.230468-4.074219 3.636718-5.433593 4.488281-.359376-1.5625-.667969-3.859375-.667969-7.011719v-51.742187c0-6.949219-4.78125-12.992188-11.546875-14.59375-24.660156-5.832031-49.863282-8.785157-74.914063-8.785157-51.859375 0-103.445312 12.546876-149.179687 36.292969-26.90625 13.96875-51.867188 31.816407-73.9375 52.738281 39.609375-126.421874 157.828125-216.1875 294.582031-216.1875 8.28125 0 14.996094-6.714843 14.996094-15v-51.734374c0-3.152344.308593-5.449219.667969-7.015626 1.359374.851563 3.203124 2.261719 5.433593 4.488282l115.933594 115.933594c11.632813 11.632812 11.632813 30.5625 0 42.199218zm0 0"
      ></path>
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 511.99972 511"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m494.503906 130.847656-115.933594-115.933594c-9.5625-9.566406-19.265624-14.414062-28.832031-14.414062-13.132812 0-28.472656 9.992188-28.472656 38.148438v37.066406c-164.273437 7.226562-301.015625 132.4375-321.152344 296.894531-.832031 6.777344 3.019531 13.257813 9.367188 15.769531 1.796875.710938 3.664062 1.050782 5.511719 1.050782 4.679687 0 9.21875-2.191407 12.105468-6.140626 55.34375-75.613281 144.207032-120.757812 237.703125-120.757812 18.835938 0 37.777344 1.84375 56.464844 5.492188v39.636718c0 28.152344 15.335937 38.144532 28.472656 38.148438 9.566407 0 19.269531-4.851563 28.832031-14.414063l115.933594-115.933593c23.328125-23.328126 23.328125-61.285157 0-84.613282zm0 0"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
