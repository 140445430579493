<script setup lang="ts">
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    fromValue: number;
    toValue: number;
    min?: number;
    max?: number;
    prefix?: string;
    gapMin: number;
    disabled?: boolean;
  }>(),
  {
    min: 0,
    max: 10000,
    prefix: '$',
    gapMin: 0,
  }
);

const emit = defineEmits<{
  (e: 'update:fromValue', value: number): void;
  (e: 'update:toValue', value: number): void;
}>();

const percent1 = computed(() => {
  if (!props.fromValue || !props.max) return 0;
  return (props.fromValue / props.max) * 100;
});
const percent2 = computed(() => {
  if (!props.toValue || !props.max) return 0;
  return (props.toValue / props.max) * 100;
});

const fromValueRef = ref(props.fromValue);

const onInputFromValue = (event) => {
  const currentValue = event?.target?.value;
  if (props.toValue - currentValue - props.gapMin < 0) {
    fromValueRef.value = props.fromValue;
    emit('update:fromValue', props.toValue);
    return;
  }
  emit('update:fromValue', currentValue);
};
const toValueRef = ref(props.toValue);

const onInputToValue = (event) => {
  const currentValue = event?.target?.value;
  if (currentValue - props.fromValue - props.gapMin < 0) {
    toValueRef.value = props.toValue;
    emit('update:toValue', props.fromValue);
    return;
  }
  emit('update:toValue', currentValue);
};
</script>

<template>
  <div class="flex flex-col w-full space-y-4">
    <div class="w-full relative">
      <div
        class="slider-track"
        :style="`background: linear-gradient(to right, #dadae5 ${percent1}% , #4bb0b2 ${percent1}% , #4bb0b2 ${percent2}%, #dadae5 ${percent2}%)`"
      ></div>
      <input
        ref="fromValueRef"
        :value="fromValue"
        type="range"
        :min="min"
        :max="max"
        :disabled="disabled"
        :class="disabled ? 'cursor-not-allowed' : ''"
        @input="onInputFromValue"
      />
      <input
        ref="toValueRef"
        :value="toValue"
        type="range"
        :min="min"
        :max="max"
        :disabled="disabled"
        :class="disabled ? 'cursor-not-allowed' : ''"
        @input="onInputToValue"
      />
    </div>
    <div class="w-full flex items-center justify-between">
      <div class="flex-center space-x-2 text-sm">
        <span class="font-semibold">
          {{ $t('COMMON_LABEL_MIN') }}
        </span>
        <span class="text-gray-600"> {{ prefix }}{{ fromValue }} </span>
      </div>
      <div class="flex-center space-x-2 text-sm">
        <span class="font-semibold">
          {{ $t('COMMON_LABEL_MAX') }}
        </span>
        <span class="text-gray-600"> {{ prefix }}{{ toValue }} </span>
      </div>
    </div>
  </div>
</template>
<style>
input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
}

.slider-track {
  width: 100%;
  height: 5px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
}

input[type='range']::-moz-range-track {
  -moz-appearance: none;
  height: 5px;
}

input[type='range']::-ms-track {
  appearance: none;
  height: 5px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.5em;
  width: 1.5em;
  background-color: #4bb0b2;
  cursor: pointer;
  margin-top: -9px;
  pointer-events: auto;
  border-radius: 50%;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  border-radius: 50%;
  background-color: #4bb0b2;
  pointer-events: auto;
}

input[type='range']::-ms-thumb {
  appearance: none;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  border-radius: 50%;
  background-color: #4bb0b2;
  pointer-events: auto;
}

input[type='range']:active::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 3px solid #4bb0b2;
}
</style>
