<script setup lang="ts">
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import {
  DayoffTypeUI,
  DayoffDateSessionUI,
  DayoffExceptionTypeUI,
} from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import { DayoffType, DayoffExceptionType } from '@/domain/enums/DayoffEnums';
import settingStore from '@/store/setting';
// import DayoffExceptionTypeAtom from '@/ui/components/dayoff/atoms/DayoffExceptionTypeAtom.vue';

const props = defineProps({
  workInfo: {
    type: Object,
    default: () => {},
  },
  userInfo: {
    type: Object,
    default: () => {},
  },
  size: {
    type: String,
    default: 'small',
  },
  workOffInfo: {
    type: Object,
    default: () => {},
  },
});
const _settingStore = settingStore();
const timeUtc = computed(() => {
  return _settingStore.timezoneList.map((el) => {
    return {
      ...el,
      nameIcon:
        el.countryCode === 'VN'
          ? 'flag-vietnam'
          : el.countryCode === 'FR'
          ? 'flag-france'
          : 'flag-united-state',
    };
  });
});

const myProfile = computed(() => myProfileStore().myProfile);
const workOffInfo = computed(() => props.workOffInfo);
const userInfo = computed(() => props.userInfo);
</script>

<template>
  <div
    class="w-full flex-center flex-col space-y-3 text-xs px-2 rounded-md p-1"
  >
    <div class="text-center">
      <span v-if="!workOffInfo && !workInfo?.startWD" class="break-words">{{
        $t('OVERVIEW_LABEL_HAVE_NOT_START') || 'Have not started'
      }}</span>
      <div v-if="workOffInfo" class="flex-center flex-wrap space-x-1">
        <!-- SCHEDULE -->
        <div class="flex items-center space-x-1">
          <SynIcon
            :name="DayoffTypeUI[workOffInfo?.type].iconName"
            :style="{
              fill: DayoffTypeUI[workOffInfo?.type].color,
            }"
            custom-class="w-3 h-3"
          />
          <span
            v-if="workOffInfo?.type === DayoffType.SCHEDULE"
            class="text-center"
          >
            {{
              $t(DayoffDateSessionUI[workOffInfo?.session]?.textLabelOffWork)
            }}
          </span>
          <span
            v-if="
              workOffInfo?.type === DayoffType.EXCEPTION &&
              workOffInfo.exception
            "
            class="text-center"
          >
            {{
              $t(
                DayoffExceptionTypeUI[workOffInfo?.exception?.type]?.textCode
              ) ||
              DayoffExceptionTypeUI[workOffInfo?.exception?.type]?.textDefault
            }}
          </span>
        </div>

        <!-- EXCEPTION -->
        <span
          v-if="
            workOffInfo?.type === DayoffType.EXCEPTION && workOffInfo.exception
          "
          class="flex-center items-center space-x-1 flex-wrap text-center"
        >
          <span
            v-if="
              workOffInfo?.exception?.type !== DayoffExceptionType.BREAK_TIME
            "
          >
            (<SynLabelDateTime
              format="time"
              :datetime="workOffInfo?.exception?.startTime"
            />)
          </span>
          <span v-else>
            (<SynLabelDateTime
              format="time"
              :datetime="workOffInfo?.exception?.startTime"
            />
            -
            <SynLabelDateTime
              format="time"
              :datetime="workOffInfo?.exception?.endTime"
            />)
          </span>
        </span>
        <span v-if="workOffInfo?.type === DayoffType.WFH">
          {{ $t('DAYOFF_LABEL_MEMBER_OFF_REMOTE') }}
          <span>
            ({{ $t(DayoffDateSessionUI[workOffInfo?.session]?.textCode) }})
          </span>
        </span>
      </div>
    </div>

    <section class="flex-center flex-col space-y-2">
      <!-- START WD -->
      <div
        v-if="workInfo?.startWD"
        :title="$t('OVERVIEW_LABEL_START_TO_WORK') || 'Start to work'"
        class="w-full flex items-center justify-start space-x-1 flex-wrap"
      >
        <div class="w-4 h-4 bg-current-500 flex-center rounded-full">
          <SynIcon
            name="start"
            custom-class="w-1.5 h-1.5 fill-white text-white"
          />
        </div>
        <div class="flex flex-col">
          <span class="text-current-600 font-semibold">
            <SynLabelDateTime format="time" :datetime="workInfo?.startWD" />
          </span>
          <span
            v-if="userInfo?.utcUser && userInfo?.utcUser !== myProfile?.utc"
            class="flex-center space-x-1 text-2xs text-gray-500 font-medium"
          >
            <syn-icon
              :name="
                timeUtc?.find((el) => el?.utc === userInfo?.utcUser)?.nameIcon
              "
              custom-class="w-3 h-4"
            />
            <span>{{
              `GMT ${timeUtc?.find((el) => el?.utc === userInfo?.utcUser)?.utc}`
            }}</span>
          </span>
        </div>
      </div>
      <!-- STOP -->
      <div
        v-if="workInfo?.stopWD"
        :title="$t('OVERVIEW_LABEL_STOP_TO_WORK') || 'Stop to work'"
        class="w-full flex items-center justify-start space-x-1 flex-wrap"
      >
        <div class="w-4 h-4 bg-red-400 flex-center rounded-full">
          <SynIcon
            name="stop-video"
            color="white"
            custom-class="w-1.5 h-1.5 fill-white text-white"
          />
        </div>
        <span class="text-current-600 font-semibold">
          <SynLabelDateTime format="time" :datetime="workInfo?.stopWD" />
        </span>
      </div>
      <!-- DURATION -->
      <div
        v-if="workInfo?.startWD"
        :title="$t('OVERVIEW_LABEL_DURATION') || 'Duration'"
        class="w-full flex items-center justify-start space-x-1 flex-wrap"
      >
        <div class="w-4 h-4 bg-blue-500 flex-center rounded-full">
          <SynIcon name="duration" custom-class="w-4 h-4 fill-white" />
        </div>
        <span class="text-current-600 font-semibold">
          {{
            $filters.durationTime(
              workInfo?.actualDuration * 60 * 60 * 1000,
              'hh mm'
            )
          }}
        </span>
      </div>
    </section>
  </div>
</template>
