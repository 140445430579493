<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import calendarStore from '@/store/calendar';
import {
  CalendarReminderRepeatType,
  CalendarNotificationTypeValue,
  CalendarReminderRepeatTypeValue,
  ByWeekDay,
} from '@/ui/common/constants/calendar';
import UserById from '@/ui/components/user/UserById.vue';
import SynDeleteModal from '@/ui/common/molecules/SynModal/SynDeleteModal.vue';
import ReminderCreateForm from '@/ui/modules/calendar/reminder/ReminderCreateForm.vue';
import CalendarEventDate from '@/ui/modules/calendar/detail-view-event/CalendarEventDate.vue';
import ReminderCreateModal from '@/ui/modules/calendar/reminder/ReminderCreateModal.vue';

const props = withDefaults(
  defineProps<{
    eventId?: string;
    customClass?: string;
  }>(),
  {
    customClass: 'p-4',
  }
);

const emit = defineEmits(['cancel', 'update', 'edit', 'onCloneSuccess']);

const _calendarStore = calendarStore();
const eventDetail = computed(() => _calendarStore.calendarDetailCurrent);
const isEdit = ref(false);
const isLoading = ref(false);
const isLoadingRemove = ref(false);
const isRemoveEvent = ref(false);
const isCloneReminder = ref(false);

const initData = async () => {
  if (!props.eventId) return;

  isLoading.value = true;
  await _calendarStore.getDetail(props.eventId);
  isLoading.value = false;
};

onMounted(() => {
  initData();
});

const onRemove = async () => {
  isLoadingRemove.value = true;
  try {
    await _calendarStore.deleteEvent(eventDetail.value.id);
    isLoadingRemove.value = false;
  } catch (e) {
    isLoadingRemove.value = false;
  }
  emit('cancel', true);
};

const onUpdate = async () => {
  isEdit.value = false;
  // await _calendarStore.getDetail(props.eventId);
  emit('update');
};
const onCancel = async () => {
  isEdit.value = false;
  // isLoading.value = true;
  // await _calendarStore.getDetail(props.eventId);
  // isLoading.value = false;
};

const onOpenEditForm = () => {
  isEdit.value = true;
  emit('edit');
};
</script>
<template>
  <div class="h-full" :class="customClass" style="width: 36rem">
    <div
      v-if="isLoading"
      class="flex items-center space-y-2 pt-4 pb-8 px-4 flex-col"
    >
      <div class="flex items-center space-x-2 w-full">
        <div class="rounded-full bg-gray-200 animate-pulse w-8 h-4"></div>
        <div class="rounded-full bg-gray-200 animate-pulse w-full h-4"></div>
      </div>
      <div class="flex items-center space-x-2 w-full">
        <div class="rounded-full animate-pulse w-8 h-4"></div>
        <div class="rounded-md bg-gray-200 animate-pulse w-full h-10"></div>
      </div>
    </div>
    <div v-else class="px-2 pb-2 flex flex-col relative">
      <template v-if="!isEdit">
        <!-- ACTION -->
        <div class="flex items-center justify-between">
          <div class="flex-center gap-2">
            <SynIcon name="AlarmClock" custom-class="w-5 h-5 fill-green-500" />
            <span class="font-semibold text-green-500">{{
              $t('CALENDAR_FILTER_TITLE_TYPE_REMINDER')
            }}</span>
          </div>
          <div class="flex items-center gap-1">
            <template v-if="!isEdit">
              <VigButton
                v-vig-tooltip="$t('COMMON_LABEL_CLONE')"
                ghost
                color="gray"
                icon="Duplicate"
                @click.stop="isCloneReminder = true"
              />
              <VigButton
                v-vig-tooltip="$t('TASK_DETAIL_EDIT')"
                ghost
                color="gray"
                icon="Edit"
                @click.stop="onOpenEditForm"
              />
              <VigButton
                v-vig-tooltip="$t('TASK_DETAIL_REMOVE')"
                ghost
                color="gray"
                icon="Trash"
                @click.stop="isRemoveEvent = true"
              />
            </template>
          </div>
        </div>

        <!-- TITLE -->
        <div class="text-2xl mt-1">
          {{ eventDetail?.title }}
        </div>

        <!--TIME & CREATOR-->
        <div class="flex items-center justify-between gap-3 mt-3">
          <!--TIME-->
          <div class="flex-center gap-3">
            <SynIcon name="Calendars" class="fill-gray-500" />
            <CalendarEventDate :event="eventDetail" />
          </div>

          <!--CREATOR-->
          <div>
            <div class="text-gray-500 text-2xs text-right">
              {{ $t('COMMON_LABEL_CREATED_BY') }}
            </div>
            <UserById
              :user-id="eventDetail?.createdBy"
              is-show-first-name
              avatar-class="w-4 h-4"
              class="text-sm"
            />
          </div>
        </div>

        <!-- NOTIFICATION TYPE -->
        <div
          v-if="eventDetail.metadata?.notificationType"
          class="flex items-center gap-3 mt-3"
        >
          <div :title="$t('COMMON_LABEL_ALERT')" class="flex-center">
            <SynIcon name="Bell" class="fill-gray-500" />
          </div>
          <div class="text-sm">
            {{
              $t(
                CalendarNotificationTypeValue[
                  eventDetail.metadata?.notificationType
                ]?.label,
                {
                  time: CalendarNotificationTypeValue[
                    eventDetail.metadata?.notificationType
                  ]?.time,
                }
              )
            }}
          </div>
        </div>

        <!-- REPEAT TYPE -->
        <template v-if="eventDetail.metadata?.repeatType">
          <div class="flex items-center gap-3 mt-3">
            <div
              :title="$t('CALENDAR_FILTER_TITLE_TYPE_REMINDER')"
              class="flex-center"
            >
              <SynIcon name="Reload" class="fill-gray-500" />
            </div>
            <div class="text-sm">
              {{
                CalendarReminderRepeatTypeValue(eventDetail?.startDate)[
                  eventDetail.metadata?.repeatType
                ]?.label || ''
              }}
            </div>
          </div>
          <div
            v-if="
              eventDetail?.repeatType ===
              CalendarReminderRepeatType.CustomSpecificDayOfWeek
            "
            class="flex items-center flex-wrap gap-1 mt-1 pl-8"
          >
            <div
              v-for="item in eventDetail?.metadata?.repeatSpecificDaysOfWeek"
              :key="item"
              class="rounded px-2 py-1 text-center text-xs bg-current-50"
            >
              {{ $t(ByWeekDay[item].label) }}
            </div>
          </div>
        </template>
      </template>

      <template v-if="isEdit">
        <ReminderCreateForm
          view-mode="UPDATE"
          :is-edit="isEdit"
          :date-start="eventDetail.startDate"
          :default-data="eventDetail"
          :event-detail="eventDetail"
          @cancel="onCancel"
          @on-update-success="onUpdate"
        />
      </template>
    </div>
  </div>

  <ReminderCreateModal
    v-if="isCloneReminder"
    :is-edit="false"
    :is-clone="true"
    :default-data="eventDetail"
    @cancel="isCloneReminder = false"
    @on-create-success="
      isCloneReminder = false;
      $emit('onCloneSuccess');
    "
  />

  <SynDeleteModal
    v-if="isRemoveEvent"
    :content="eventDetail?.title"
    :type-content="$t('CALENDAR_FILTER_TITLE_TYPE_EVENT') + ' - '"
    :title="$t('CALENDAR_REMOVE_EVENT_CONTENT')"
    @cancel="(value) => (isRemoveEvent = value)"
    @on-save="onRemove()"
  />
</template>
