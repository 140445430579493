import {
    AudioVideoCallStatusEnum,
    CallParticipantStatus,
    CallHostStatus,
    ConferenceTypeEnum,
    ConferenceHosterType,
    ActionOnCallType,
    CallType,
    AnswerState,
    CallDeviceState,
} from '@/domain/enums/VideoCallEnum';
import { getTimestampUtc } from '@/ui/hooks/commonFunction';
import dashboardStore from '@/store/dashboard';
import { UserStatusEnum } from '@/domain/enums/UserStatusEnum';
import {
    settingGlobalModal,
    ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { getNearestMediaRegion } from '@/application/api/chimeAws.js';
import { v4 as uuidv4 } from 'uuid';
import chatStore from '@/store/chat';
import {
    getConferencePath,
    getUserConferencePath,
} from '@/ui/modules/video-call/hooks/firebase-helper';
import $constants from '@/ui/plugins/constants';
import { openVideoCallPage } from '@/ui/modules/video-call/hooks/conference-helper';
import { updateMultiplePaths } from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import { translate } from '@/ui/plugins/i18n/myi18n';
import VideoCallService from '@/application/services/VideoCallService';

export class ConferenceClass {
    type: ConferenceTypeEnum;
    hoster: ConferenceHosterType;
    status: AudioVideoCallStatusEnum;
    createdDate: number;
    duration: number;
    participants: any[];

    _videoCallService: VideoCallService;

    _dashboardStore = dashboardStore();
    _chatStore = chatStore();
    constructor(
        type: ConferenceTypeEnum,
        hoster: ConferenceHosterType,
        status: AudioVideoCallStatusEnum,
        duration: number,
        participants: any[]
    ) {
        this.type = type;
        this.hoster = hoster;
        this.status = status || AudioVideoCallStatusEnum.Started;
        this.createdDate = getTimestampUtc();
        this.duration = duration || 15;
        this.participants = participants;

        this._videoCallService = VideoCallService.getInstance();
    }

    async start() {
        const myCallStatus: string = this._dashboardStore.getUserStatus(
            this.hoster.id
        );

        if (myCallStatus?.includes(UserStatusEnum.OnCall)) {
            settingGlobalModal({
                type: 'notification',
                title: '',
                content:
                    translate('COMMON_LABEL_YOU_ARE_ON_ANOTHER_CALL') ||
                    'You are on another call',
                confirmLabel: translate('COMMON_LABEL_CLOSE'),
                confirmable: true,
            });
            await ask();
            return;
        }
        // 0. Prepare data
        const res: any = await this.handleCreateConferenceOnServer();

        // update chat message
        const conferenceInfo = res?.result;

        const callId = conferenceInfo?.meetingId;
        if (!callId) return;

        // 1. Handle Firebase
        // const durationMiliseconds = this.duration * 60 * 1000;
        // const planedEndTime = createdDate + durationMiliseconds;
        const participants = this.getParticipantWithJoinToken(
            conferenceInfo?.tokens
        );

        let messageContents = [] as any[];

        const conferencePath = getConferencePath(callId);

        let firebaseUpdates = {
            [`${conferencePath}/updatedMessageInCall`]: 0,
        } as any;

        this.participants.forEach((participant: any) => {
            const _userId = participant?.id;

            const userPath = getUserConferencePath(_userId, callId);

            firebaseUpdates = {
                ...firebaseUpdates,
                [`${userPath}/message`]: participants[_userId]?.message,
                [`${conferencePath}/participants/${_userId}/message`]:
                    participants[_userId]?.message,
            };
            messageContents = [
                ...messageContents,
                {
                    userId: _userId,
                    ...participants[_userId]?.message,
                    call: {
                        type:
                            this.type == ConferenceTypeEnum.Video
                                ? $constants.CALL.TYPE.VIDEO
                                : $constants.CALL.TYPE.AUDIO,
                        callStatus: $constants.CALL.CALL_STATUS.STARTED,
                        participantStatus:
                            $constants.CALL.PARTICIPANT_STATUS.RINGING,
                        createdDate: this.createdDate,
                        participants: {
                            [this.hoster.id]: {
                                status: $constants.CALL.PARTICIPANT_STATUS
                                    .STARTED,
                            },
                            ...this.participants.reduce((pValue, cValue) => {
                                const uId = cValue.id;
                                return {
                                    ...pValue,
                                    [uId]: {
                                        status: $constants.CALL
                                            .PARTICIPANT_STATUS.RINGING,
                                    },
                                };
                            }, {}),
                        },
                    },
                },
            ];
        });
        // Update Firebase
        updateMultiplePaths(firebaseUpdates);
        // 4. Open video call page
        openVideoCallPage(participants[this.hoster.id].joinToken, [
            {
                name: this.hoster.name,
                avatar: this.hoster.avatar?.split('/')?.pop() || '',
            },
            ...this.participants?.map((par) => {
                return {
                    name: par?.name,
                    avatar: par?.avatar?.split('/')?.pop() || '',
                };
            }),
        ]);

        this._chatStore.addMessageToUserMultiple(messageContents);
    }

    private async handleCreateConferenceOnServer() {
        if (!this.participants || this.participants?.length <= 0) return;
        const nestestRegion = await getNearestMediaRegion();

        return this._videoCallService.createConference({
            region: nestestRegion || 'ap-southeast-1',
            receivers: this.participants,
            type: this.type == ConferenceTypeEnum.Audio ? 1 : 2,
            duration: this.duration,
        });
    }

    private getParticipantWithJoinToken(tokens) {
        const participantTokenById = tokens?.reduce((o, n) => {
            return { ...o, [n.userId]: n.joinToken };
        }, {});
        return this.participants.reduce(
            (previousValue, currentValue) => {
                const userId = currentValue.id;

                const messageId = uuidv4();

                const conversationId =
                    this._chatStore.checkExistedConversation(userId);

                return {
                    ...previousValue,
                    ...{
                        [userId]: {
                            id: userId,
                            name: currentValue.name,
                            avatar: currentValue.avatar,
                            status: CallParticipantStatus.Ringing,
                            joinToken: participantTokenById[userId],
                            message: {
                                messageId: messageId,
                                conversationId: conversationId,
                            },
                        },
                    },
                };
            },
            {
                [this.hoster.id]: {
                    id: this.hoster.id,
                    name: this.hoster.name,
                    avatar: this.hoster.avatar,
                    isHost: true,
                    status: CallHostStatus.Started,
                    joinToken: participantTokenById[this.hoster.id],
                },
            }
        );
    }
}

export function getMyCallHistories() {
    return VideoCallService.getInstance().getCallHistories();
}

export function forceEndCallByJoinToken(joinToken: string): Promise<any> {
    return VideoCallService.getInstance().forceEndCall({
        JoinToken: joinToken,
        CurrentTime: getTimestampUtc(),
    });
}

export function actionOnRingingModal(
    meetingId: string,
    joinToken: string,
    actionOnCallType: ActionOnCallType,
    callType: CallType,
    hosterId
): Promise<any> {
    return VideoCallService.getInstance().actionOnCall({
        meetingId,
        joinToken,
        actionOnCall: actionOnCallType,
        callType,
        callerId: hosterId,
    });
}

export function participantAnswer(
    joinToken: string,
    answerState: AnswerState,
    cameraState: CallDeviceState
): Promise<any> {
    return VideoCallService.getInstance().answer({
        joinToken: joinToken,
        answerState: answerState,
        cameraState: cameraState,
    });
}
