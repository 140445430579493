<script setup lang="ts">
import { computed } from 'vue';
import { TDayoffStatusByDate } from '@/store/dashboard/overview-monitor-store';
import { DayoffType } from '@/domain/enums/DayoffEnums';
import { DayoffTypeUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import userStore from '@/store/user';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  dayoffStatusByDate: TDayoffStatusByDate;
}>();

const emit = defineEmits<{
  (e: 'changeDay', date: Date): void;
}>();

const _userStore = userStore();

const allUserByIds = computed(() => _userStore.allUserByIds);

const dayoffStatusToday = computed(() => {
  return Object.entries(props.dayoffStatusByDate)?.map(([key, value]) => {
    return {
      ...value,
      key,
      members: value.members.map((user) => {
        return allUserByIds.value[user.userId];
      }),
    };
  });
});

const getTitleByKey = (key) => {
  if (key == 'schedule') return translate('DAYOFF_TYPE_SCHEDULE') || 'Schedule';
  if (key == 'wfh') return 'WFH';
  if (key == 'exception')
    return translate('DAYOFF_TYPE_EXCEPTION') || 'Exception';
  return '';
};
const getDayoffTypeKey = (key) => {
  if (key == 'schedule') return DayoffType.SCHEDULE;
  if (key == 'wfh') return DayoffType.WFH;
  if (key == 'exception') return DayoffType.EXCEPTION;
  return DayoffType.SCHEDULE;
};

const onChangeDay = (newDate) => {
  emit('changeDay', newDate);
};
</script>
<template>
  <div
    class="flex-1 flex flex-col space-y-2 p-4 bg-white"
    style="
      min-width: 20rem;
      box-shadow: 0px 2px 6px #9ca3af47;
      border-radius: 5px;
    "
  >
    <div class="flex-center h-2/5">
      <AtomSmallCalendar @change="onChangeDay" />
    </div>
    <div class="flex-1 flex space-x-2 pt-4">
      <div
        v-for="dayoff in dayoffStatusToday"
        :key="dayoff.key"
        class="
          w-1/3
          flex
          items-center
          flex-col
          space-y-2
          bg-current-50
          rounded-lg
          pt-4
          relative
        "
      >
        <div class="w-2/3 flex-center flex-col pt-2">
          <div class="flex-center absolute top-0 -translate-y-6">
            <span class="w-10 h-10 shadow-md flex-center rounded-full bg-white">
              <SynIcon
                :name="DayoffTypeUI[getDayoffTypeKey(dayoff.key)]?.iconName"
                :style="{
                  fill: DayoffTypeUI[getDayoffTypeKey(dayoff.key)]?.color,
                }"
                :custom-class="
                  getDayoffTypeKey(dayoff.key) !== DayoffType.SCHEDULE
                    ? 'w-5 h-5'
                    : ''
                "
              />
            </span>
          </div>
          <span class="text-current-600 font-semibold">{{ dayoff.total }}</span>
          <span class="text-gray-600 text-xs font-semibold text-center">
            {{ getTitleByKey(dayoff.key) }}
          </span>
        </div>
        <div class="flex">
          <SynListAvatar
            :users="dayoff.members"
            :custom-class="'w-6 h-6 text-sm'"
            :max-length="4"
          />
        </div>
      </div>
    </div>
  </div>
</template>
