<script setup lang="ts">
import { computed, ref } from 'vue';
import groupStore from '@/store/group';
import { ignoreUnicode } from '@/ui/plugins/utils';
import GroupById from '@/ui/components/group/GroupById.vue';
import domainStore from '@/store/scope';
import DomainDetailClass from '@/domain/entities/domains/DomainDetailClass';
import DomainGroupLabel from '@/ui/modules/domains/component/DomainGroupLabel.vue';
import TaskService from '@/application/services/task/TaskService';

const props = withDefaults(
  defineProps<{
    modelValue: any[];
    domainId: number | string;
    parentId?: number | string | null;
    isEditting: boolean;
    autofocus?: boolean;
    domainName?: string;
  }>(),
  {
    domainName: '',
  }
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void;
}>();

const _groupStore = groupStore();

const _domainStore = domainStore();

const domainByIds = computed(() => _domainStore.domainByIds);
const parentDomain = computed<DomainDetailClass>(() => {
  if (!props.domainId) return {};
  return domainByIds.value[props.domainId];
});
const allGroupByIds = computed(() => _groupStore.allGroupByIds);
const path =
  'https://d1tvqetrcurhzb.cloudfront.net/common/images/team-member.jpg';

const initData = () => {
  console.log(parentDomain.value);
};

const searchText = ref('');

const filteredGroups = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);
  if (searchTxt)
    return (_groupStore.allActiveGroups || []).filter(
      (group) =>
        group?.name &&
        ignoreUnicode(group?.name).includes(searchTxt) &&
        !props.modelValue?.some((id) => id == group?.id) &&
        group?.code !== 'DELETED_GROUP'
    );
  return _groupStore.allActiveGroups?.filter(
    (group) =>
      !props.modelValue?.some((id) => id == group?.id) &&
      group?.code !== 'DELETED_GROUP'
  );
});

initData();

const currentIndex = ref(null);

const onChooseResult = () => {
  if (currentIndex.value == null) return;

  const contact = filteredGroups.value[currentIndex.value];

  if (!contact || !contact?.id) return;

  onSelectGroup(contact?.id);
};

const currentGroupTasks = ref([]);
const currentGroupDetail = ref<any>({});
const isCannotDetachGroup = ref(false);
const unSelectedGroup = async (groupId) => {
  currentGroupDetail.value = allGroupByIds.value[groupId];
  const res = await TaskService.getInstance().getTaskWithFilter({
    groupIds: `${groupId}`,
    isDefault: false,
    domainIds: `${props.domainId}`,
  });

  currentGroupTasks.value = res?.result;

  if (currentGroupTasks.value?.length > 0) return;
  if (
    currentGroupDetail.value?.groupDomains?.length == 1 &&
    currentGroupDetail.value?.groupDomains[0].domainId == props.domainId
  )
    return (isCannotDetachGroup.value = true);

  emit(
    'update:modelValue',
    props.modelValue.filter((id) => id !== groupId)
  );
};

const onSelectGroup = (groupId) => {
  if (props.modelValue?.some((id) => id == groupId)) unSelectedGroup(groupId);
  else emit('update:modelValue', [...props.modelValue, groupId]);

  setTimeout(() => {
    if (filteredGroups.value?.length == 0) searchText.value = '';
  });
};
</script>

<template>
  <div
    class="flex-1 min-h-0 overflow-hidden"
    :class="isEditting ? 'grid grid-cols-2 ' : ''"
  >
    <div v-if="isEditting" class="flex-1 min-h-0 flex flex-col p-4">
      <VigSearchBox
        v-model="searchText"
        v-model:current-index="currentIndex"
        class="p-1"
        :total-result="filteredGroups?.length"
        :autofocus="autofocus"
        @enter="onChooseResult"
      />
      <div class="flex-1 overflow-auto small-scrollbar">
        <div v-if="filteredGroups?.length">
          <div
            v-for="(group, index) in filteredGroups"
            :key="group"
            class="p-2 flex items-center space-x-3 cursor-pointer"
            :class="index == currentIndex ? 'bg-gray-100' : ''"
            @click="onSelectGroup(group?.id)"
          >
            <SynIcon
              name="checkbox"
              class="fill-current cursor-pointer flex-none"
              :is-active="modelValue?.some((id) => id == group?.id)"
            />
            <GroupById :group-id="group?.id" avatar-class="w-8 h-8" />
          </div>
        </div>
      </div>
    </div>
    <div class="h-full flex flex-col flex-1 min-h-0">
      <div
        v-if="isEditting && modelValue?.length > 0"
        class="p-4 flex space-x-2"
      >
        {{
          $t('GED_NUM_SELECTED', {
            number: modelValue?.length || 0,
          })
        }}
      </div>
      <div
        class="
          flex flex-col
          space-y-2
          flex-1
          min-h-0
          overflow-auto
          small-scrollbar
          px-4
        "
      >
        <template v-if="modelValue?.length > 0">
          <div
            class="
              flex-1
              min-h-0
              overflow-y-auto
              small-scrollbar
              flex flex-col
              space-y-1
            "
          >
            <DomainGroupLabel
              v-for="groupId of modelValue"
              :key="groupId"
              :group-id="groupId"
              :is-edit="isEditting"
              @on-remove="unSelectedGroup(groupId)"
            />
          </div>
        </template>
        <template v-else-if="isEditting">
          <div class="flex flex-col w-full h-full items-center justify-center">
            <div class="w-full h-32 flex-center grow-0 shrink-0">
              <VigImage :path="path" :custom-class="`w-2/3`" />
            </div>
            <div class="">
              <div class="flex-center flex-col space-y-1">
                <span
                  class="text-gray-500 pt-8 text-sm text-center"
                  v-html="
                    $t('COMMON_LABEL_SELECT_GROUP_FOR_DOMAIN', {
                      project: domainName,
                    })
                  "
                >
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col w-full h-full mt-3">
            <div class="w-full h-32 flex-center grow-0 shrink-0">
              <VigImage :path="path" :custom-class="`w-1/2`" />
            </div>
            <div class="">
              <div class="flex-center flex-col space-y-1">
                <span class="text-gray-500 pt-8 text-sm text-center">
                  {{ $t('COMMON_LABEL_NO_DATA') }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <SynModal
    v-if="currentGroupTasks?.length > 0 || isCannotDetachGroup"
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    disable-click-outside
    is-hidden-header
    is-hidden-footer
    container-style="width:400px; min-height: 250px"
    @cancel="
      () => {
        currentGroupTasks = [];
        currentGroupDetail = {};
        isCannotDetachGroup = false;
      }
    "
  >
    <template #body>
      <div class="flex-1 overflow-hidden flex flex-col p-8">
        <div class="flex-center">
          <div class="flex-center h-16 w-16 ring ring-orange-300 rounded-full">
            <SynIcon
              name="warning-circle"
              custom-class="w-16 h-16 fill-orange-500"
            />
          </div>
        </div>
        <div class="flex-center">
          <span class="font-semibold">
            {{ $t('DOMAIN_LABEL_DETACH_GROUP') || 'Detach group' }}
          </span>
        </div>
        <div class="flex-center">
          <span class="font-semibold text-2xl text-current">
            {{ currentGroupDetail?.name }}
          </span>
        </div>
        <div class="text-center">
          <span
            v-if="currentGroupTasks?.length > 0"
            class="text-sm text-gray-600"
          >
            {{
              $t('DOMAIN_LABEL_CANNOT_DETACH_GROUP_1') ||
              "You cannot detach this group from this domain, because there are tasks in this group that are linked with this domain. Please change the domain's tasks first, or move the tasks to another group."
            }}
          </span>
          <span v-else class="text-sm text-gray-600">
            {{
              $t('DOMAIN_LABEL_CANNOT_DETACH_GROUP_2') ||
              ' You cannot detach this group from this domain, because a group has to be attached at least to one domain. Please go to the group setting to odify the domain association.'
            }}
          </span>
        </div>
        <div class="flex-center space-x-2 p-8">
          <template v-if="currentGroupTasks?.length > 0">
            <div class="w-28 h-28 rounded-md flex-center flex-col border">
              <span class="text-4xl font-bold text-current">
                {{ currentGroupTasks?.length }}
              </span>
              <span>
                {{ $t('TASKTAB_LABEL_TASKS') }}
              </span>
            </div>
          </template>
          <template v-else>
            <div class="w-28 h-28 rounded-md flex-center flex-col border">
              <span class="text-4xl font-bold text-current"> 1 </span>
              <span>
                {{ $t('TASK_CREATE_FORM_DOMAIN') }}
              </span>
            </div>
          </template>
        </div>
      </div>
    </template>
  </SynModal>
</template>
