<script setup lang="ts">
import { onMounted, ref, watch, computed } from 'vue';
import { colorByAlphabet, convertViToEn, cleanString } from './colorByAlphabet';
const props = withDefaults(
  defineProps<{
    modelValue?: any;
    type?: string;
    avatarThumbnailUrl?: string;
    srcPreview?: string;
    size?: string;
    label?: string;
    name?: string;
    isUploadDirect?: boolean;
    isLoading?: boolean;
    isEdit?: boolean;
  }>(),
  {
    size: 'medium',
    isEdit: true,
    label: 'Upload Avatar',
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', file: any): void;
  (e: 'cancel'): void;
  (e: 'onSave'): void;
}>();
const srcImgPreview = ref(props.srcPreview);
const textAva = computed(() => {
  if (!props?.name) return 'A';
  return cleanString(props.name)
    .split(' ')
    [cleanString(props.name).split(' ').length - 1].charAt(0);
});

onMounted(() => {
  if (props.modelValue) {
    srcImgPreview.value = URL.createObjectURL(props.modelValue);
  }
});

watch(
  () => props.isLoading,
  () => {
    isLoadingUpload.value = props.isLoading;
    isChooseImage.value = false;
  }
);

const sizeClass = computed(() => {
  if (props.size == 'medium') {
    return 'w-32 h-32';
  } else if (props.size == 'small') {
    return 'w-16 h-16';
  } else return 'w-80 w-80';
});

const isChooseImage = ref(false);
const chooseAvatar = async (e) => {
  isChooseImage.value = true;
  const newFile = e.target.files[0];
  srcImgPreview.value = URL.createObjectURL(newFile);

  await emit('update:modelValue', newFile);
};

const cancelUploadFile = () => {
  srcImgPreview.value = '';
  isChooseImage.value = false;
  emit('update:modelValue', null);
};

const isLoadingUpload = ref(props.isLoading);
const uploadFile = () => {
  isLoadingUpload.value = true;
  isChooseImage.value = false;
  isLoadingUpload.value = false;
  emit('onSave');
};
</script>

<template>
  <div class="text-center w-full">
    <div class="relative flex justify-center">
      <div class="shadow-md border rounded-full bg-white" :class="sizeClass">
        <input
          id="upload-avatar-input"
          ref="fileInputCreate"
          type="file"
          class="invisible"
          accept="image/png, image/jpeg"
          @change="chooseAvatar"
        />
      </div>
      <div class="absolute top-0 flex justify-center" :class="sizeClass">
        <img
          v-if="
            srcImgPreview || avatarThumbnailUrl?.replace('original', 'mini')
          "
          style="clip-path: ellipse(45% 45% at 50% 50%)"
          class="object-cover shadow"
          :class="sizeClass"
          :src="
            srcImgPreview || avatarThumbnailUrl?.replace('original', 'mini')
          "
        />
        <div v-else class="w-full h-full">
          <SynIcon
            v-if="!name"
            name="user"
            class="w-2/3 h-2/3 pt-8 hover:bg-opacity-0"
            fill="#e0e0e0"
          />
          <div
            v-else
            class="
              w-full
              h-full
              object-cover
              flex-center
              text-white
              shadow
              font-thin
              rounded-full
            "
            :style="`background: ${
              colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
            }`"
          >
            <span v-if="name" class="uppercase" style="font-size: 1.1em">{{
              textAva
            }}</span>
            <SynIcon
              v-else
              :name="type == 'user' ? 'user' : 'group'"
              class="fill-white text-white"
            />
          </div>
        </div>

        <SynIcon
          v-if="isLoadingUpload"
          name="spinner"
          class="
            animate-spin
            fill-white
            w-8
            h-8
            absolute
            bottom-1/2
            hover:bg-opacity-0
          "
        />

        <label
          v-if="isEdit"
          v-permission="
            type == 'group'
              ? 'FunctionUploadAvatarGroup'
              : 'FunctionUploadAvatarUser'
          "
          for="upload-avatar-input"
          class="
            absolute
            bottom-0
            flex
            items-center
            justify-center
            right-0
            w-1/3
            h-1/3
            rounded-full
            bg-current-50 bg-opacity-90
            cursor-pointer
            hover:bg-current-100
          "
          :class="
            isLoadingUpload ? 'cursor-not-allowed hover:bg-current-50' : ''
          "
        >
          <SynIcon
            name="camera"
            class="w-1/2 h-1/2 fill-current font-bold hover:bg-current-100"
          />
        </label>
      </div>
    </div>

    <!-- <div class="p-4 text-medium text-current-800">{{ label }}</div> -->
    <div
      v-if="isUploadDirect && isChooseImage"
      class="flex space-x-2 mt-2 justify-center items-center"
    >
      <div
        class="
          bg-red-50
          p-3
          rounded-full
          flex
          items-center
          hover:bg-red-200
          cursor-pointer
        "
        @click="cancelUploadFile"
      >
        <SynIcon
          v-if="!isLoading"
          name="cancel"
          custom-class="w-4 h-4 fill-red-500 text-red-600"
        />
      </div>
      <div
        class="
          bg-green-50
          p-3
          rounded-full
          flex
          items-center
          hover:bg-green-200
          cursor-pointer
        "
        @click="uploadFile"
      >
        <SynIcon
          name="check"
          custom-class="w-4 h-4 fill-green-500 text-green-600"
        />
      </div>
    </div>
  </div>
</template>
