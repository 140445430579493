<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.115"
    height="18"
    viewBox="0 0 11.115 18"
  >
    <path
      id="Icon_material-navigate-next"
      data-name="Icon material-navigate-next"
      d="M15,9l-2.115,2.115L19.755,18l-6.87,6.885L15,27l9-9Z"
      transform="translate(-12.885 -9)"
    />
  </svg>
</template>
