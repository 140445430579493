<script setup lang="ts">
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { GroupType } from '@/application/enums/GroupEnums';
import { translate } from '@/ui/plugins/i18n/myi18n';
import chatStore from '@/store/chat';
import { computed, ref } from 'vue';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import { getShortName } from '@/ui/helpers/utils';
import taskDrawerStore from '@/store/task/drawer';
import { formatTaskCode } from '../../../hooks/taskHook';
import ConversationDelete from '@/ui/modules/messaging/chat-action/conversation-delete/ConversationDelete.vue';

const props = withDefaults(
  defineProps<{
    currentContactActive: any;
    conversationId: any;
    conversation: ChatConversationModel;
    isHideAction: any;
    isChatDisabled: boolean;
    isChatWidget: boolean;
    isTurningOffNotification?: boolean;
    isSpeedChat?: boolean;
  }>(),
  {
    isHideAction: [], //'view-page-chat', 'show-files', 'show-members', 'show-pinned-messages'
  }
);

const emits = defineEmits([
  'onGotoTaskClick',
  'onOpenCreateTask',
  'onShowFilesClick',
  'onShowPinnedMessagesClick',
  'onChatViewDetail',
  'onOpenNoteClick',
  'onViewGroupMember',
  'onViewGroupSetting',
  'onViewTaskList',
  'onToggleNotification',
  'onToggleNotification',
]);

const _chatStore = chatStore();

const hasVisibleTaskListConversationIds = computed<string[]>(
  () => _chatStore.hasVisibleTaskListConversationIds
);

const shortName = computed(() =>
  props.conversation?.isGroup
    ? props.conversation?.name
    : getShortName(props.conversation?.name) || '...'
);

const onGotoTaskClick = async () => {
  if (
    !props.currentContactActive?.isUser &&
    props.currentContactActive?.type === GroupType.CHAT
  ) {
    const content = props.currentContactActive?.isAdminGroup
      ? translate('GROUP_TASKS_ACTIVE_ADMIN_MSG')
      : translate('GROUP_TASKS_ACTIVE_USER_MSG');

    settingGlobalModal({
      type: 'notification',
      title: translate('GROUP_TASKS_DISABLED'),
      content,
      confirmable: true,
      closeable: false,
    });
    return await ask();
  }

  emits('onGotoTaskClick', props.currentContactActive);
};

const actionExtendRef = ref<any>(null);

const onViewTaskList = async () => {
  if (
    !props.currentContactActive?.isUser &&
    props.currentContactActive?.type === GroupType.CHAT
  ) {
    const content = props.currentContactActive?.isAdminGroup
      ? translate('GROUP_TASKS_ACTIVE_ADMIN_MSG')
      : translate('GROUP_TASKS_ACTIVE_USER_MSG');

    settingGlobalModal({
      type: 'notification',
      title: translate('GROUP_TASKS_DISABLED'),
      content,
      confirmable: true,
      closeable: false,
    });
    return await ask();
  }
  emits('onViewTaskList', props.currentContactActive);

  setTimeout(() => {
    if (actionExtendRef.value?.onForceClose) {
      actionExtendRef.value?.onForceClose();
    }
  }, 800);
};

const onOpenCreateTask = async () => {
  if (
    !props.currentContactActive?.isUser &&
    props.currentContactActive?.type === GroupType.CHAT
  ) {
    const content = props.currentContactActive?.isAdminGroup
      ? translate('GROUP_TASKS_ACTIVE_ADMIN_MSG')
      : translate('GROUP_TASKS_ACTIVE_USER_MSG');

    settingGlobalModal({
      type: 'notification',
      title: translate('GROUP_TASKS_DISABLED'),
      content,
      confirmable: true,
      closeable: false,
    });
    return await ask();
  }

  emits('onOpenCreateTask', props.currentContactActive);
};

const onViewChatAboutTaskClick = () => {
  if (!props.conversation?.chatAboutTaskId) return;

  taskDrawerStore().pushCurrentIds({
    id: props.conversation.chatAboutTaskId?.toString(),
    code: props.conversation.chatAboutTaskCode || '',
    name: '',
    tabType: 'DETAIL',
  });
};
</script>

<template>
  <VigDropdown ref="actionExtendRef" placement="bottom-end">
    <template #dropdown-toggle>
      <slot></slot>
    </template>
    <template #dropdown-menu>
      <div class="text-sm" style="max-width: 32rem">
        <VigButton
          v-if="conversation?.chatAboutTaskId"
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="onViewChatAboutTaskClick"
        >
          <SynIcon name="Note" />
          <span class="font-normal flex-1 text-left text-overflow-hidden-line">
            {{ $t('CHAT_VIEW_TASK') }}
            <span class="font-semibold">{{
              formatTaskCode(conversation.chatAboutTaskCode)
            }}</span>
          </span>
        </VigButton>
        <VigButton
          v-if="
            !isChatDisabled &&
            !conversation?.isTaskDisallowed &&
            !conversation?.chatAboutTaskId &&
            !isSpeedChat
          "
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="onViewTaskList"
        >
          <SynIcon name="Note" />
          <span class="font-normal flex-1 text-left text-overflow-hidden-line">
            {{ $t('CHAT_MINI_GO_TO_TASKS', { name: shortName }) }}
          </span>
          <AtomSwitch
            :model-value="
              hasVisibleTaskListConversationIds?.includes(conversationId)
            "
            size="small"
            @click="onViewTaskList"
          />
        </VigButton>
        <VigButton
          v-if="
            !isChatDisabled &&
            !conversation?.isTaskDisallowed &&
            !conversation?.chatAboutTaskId
          "
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="onOpenCreateTask"
        >
          <SynIcon name="plus" />
          <span class="font-normal text-overflow-hidden-line">
            {{ $t('CHAT_MINI_CREATE_TASK_FOR', { name: shortName }) }}
          </span>
        </VigButton>
        <VigButton
          v-if="!isHideAction?.includes('show-files')"
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onShowFilesClick', currentContactActive)"
        >
          <SynIcon name="Files" />
          <span class="font-normal">{{ $t('CHAT_MINI_SHOW_FILES') }}</span>
        </VigButton>
        <VigButton
          v-if="!isHideAction?.includes('show-pinned-messages')"
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onShowPinnedMessagesClick')"
        >
          <SynIcon name="Pin" />
          <span class="font-normal">{{
            $t('CHAT_MINI_SHOW_PINNED_MESSAGES')
          }}</span>
        </VigButton>
        <VigButton
          v-if="!isHideAction?.includes('view-page-chat') && !isSpeedChat"
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onChatViewDetail', currentContactActive)"
        >
          <SynIcon name="Redirect" />
          <span class="font-normal">{{
            $t('CHAT_MINI_GO_TO_CHAT_SCREEN')
          }}</span>
        </VigButton>
        <VigButton
          v-if="!isChatDisabled && !conversation?.isGroupChatDisallowed"
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onOpenNoteClick', conversationId)"
        >
          <SynIcon name="Noter" is-active class="fill-orange-500" />
          <span class="font-normal">{{
            $t('CHAT_MINI_SHOW_DESIGN_SCREEN')
          }}</span>
        </VigButton>
        <VigButton
          v-if="
            !isChatDisabled &&
            !!currentContactActive?.groupUsers &&
            !isHideAction?.includes('show-members')
          "
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onViewGroupMember', currentContactActive?.id)"
        >
          <SynIcon name="Groups" />
          <span class="font-normal">{{ $t('CHAT_MINI_VIEW_MEMBERS') }}</span>
        </VigButton>
        <template v-if="currentContactActive?.isAdminGroup && !isChatDisabled">
          <VigButton
            v-if="!!currentContactActive?.groupUsers"
            ghost
            color="gray"
            class="w-full space-x-4 justify-start dropdown-item"
            @click="$emit('onViewGroupSetting', currentContactActive?.id)"
          >
            <SynIcon name="settings" />
            <span class="font-normal">{{
              $t('COMMOM_LABEL_GROUP_SETTING')
            }}</span>
          </VigButton>
        </template>
        <VigButton
          v-if="
            !isChatDisabled &&
            !conversation?.isTaskDisallowed &&
            !conversation?.chatAboutTaskId
          "
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="onGotoTaskClick"
        >
          <SynAvatar
            :src="conversation?.avatarUrl || conversation?.avatar"
            :name="conversation?.name"
            custom-class="w-4 h-4"
          />
          <span class="font-normal flex-1 text-left text-overflow-hidden-line">
            {{ $t('CHAT_MINI_GO_TO_HOME', { name: shortName }) }}
          </span>
        </VigButton>

        <template v-if="conversation?.isGroup && !isChatDisabled">
          <VigButton
            ghost
            color="gray"
            class="w-full space-x-4 justify-start dropdown-item"
            @click="$emit('onToggleNotification', currentContactActive?.id)"
          >
            <SynIcon :name="isTurningOffNotification ? 'Bell' : 'BellMute'" />
            <span class="font-normal">{{
              $t(
                isTurningOffNotification
                  ? 'CHAT_CONVERS_NOTIF_TURN_ON'
                  : 'CHAT_CONVERS_NOTIF_TURN_OFF'
              )
            }}</span>
          </VigButton>
        </template>

        <ConversationDelete :conversation="conversation">
          <VigButton
            ghost
            color="red"
            class="w-full space-x-4 justify-start dropdown-item"
          >
            <SynIcon name="Trash" />
            <span class="font-normal">{{ $t('CHAT_DELETE') }}</span>
          </VigButton>
        </ConversationDelete>
      </div>
    </template>
  </VigDropdown>
</template>
