import DashboardRepository from '@/application/repositories/DashboardRepository';

export default class DashboardService {
    private static instance: DashboardService;
    _dashboardRepository: DashboardRepository;
    constructor() {
        this._dashboardRepository = DashboardRepository.getInstance();
    }

    public static getInstance(): DashboardService {
        if (!DashboardService.instance) {
            // Get from local storage
            DashboardService.instance = new DashboardService();
        }

        return DashboardService.instance;
    }

    getDashboardOverview(departmentId?: number) {
        return this._dashboardRepository.getDashboardOverview(departmentId);
    }
    getOverviewTeamTasks(departmentId?: number) {
        return this._dashboardRepository.getOverviewTeamTasks(departmentId);
    }
    getMemberOnday() {
        return this._dashboardRepository.getMemberOnday();
    }
}
