<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 490.667 490.667"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M244.587 241.557c32.128-18.389 54.08-52.629 54.08-92.224 0-58.816-47.851-106.667-106.667-106.667S85.333 90.517 85.333 149.333c0 39.595 21.952 73.835 54.08 92.224C59.051 262.656 0 330.603 0 411.136v26.197C0 443.221 4.779 448 10.667 448s10.667-4.779 10.667-10.667v-26.197C21.333 325.611 97.899 256 192 256s170.667 69.611 170.667 155.136v26.197c0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667v-26.197c-.001-80.533-59.052-148.501-139.414-169.579zm-137.92-92.224C106.667 102.272 144.939 64 192 64s85.333 38.272 85.333 85.333-38.272 85.333-85.333 85.333-85.333-38.271-85.333-85.333z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M388.224 241.835c23.125-15.296 38.443-41.451 38.443-71.168 0-47.061-38.272-85.333-85.333-85.333-5.888 0-10.667 4.779-10.667 10.667s4.779 10.667 10.667 10.667c35.285 0 64 28.715 64 64s-28.715 64-64 64c-5.888 0-10.667 4.779-10.667 10.667S335.445 256 341.333 256c70.592 0 128 53.056 128 118.293v20.373c0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667v-20.373c0-61.653-43.008-114.026-102.443-132.458z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
