<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="1024"
    height="1024"
    viewBox="0 0 1024 1024"
  >
    <title></title>
    <g id="icomoon-ignore"></g>
    <path
      d="M100 0.88c-38 4.88-70.96 28.32-88.24 62.72-6.16 12.24-10.32 28-11.44 42.56l-0.56 7.44h113.84v-113.6l-4.16 0.080c-2.32 0.080-6.56 0.4-9.44 0.8z"
    ></path>
    <path d="M227.2 56.8v56.8h114.4v-113.6h-114.4v56.8z"></path>
    <path d="M455.2 56.8v56.8h113.6v-113.6h-113.6v56.8z"></path>
    <path d="M682.4 56.8v56.8h114.4v-113.6h-114.4v56.8z"></path>
    <path
      d="M910.4 56.72v56.88h113.84l-0.56-7.44c-4.24-56.96-48.88-101.6-105.84-105.84l-7.44-0.56v56.96z"
    ></path>
    <path d="M0 284.4v57.2h113.6v-114.4h-113.6v57.2z"></path>
    <path d="M910.4 284.4v57.2h113.6v-114.4h-113.6v57.2z"></path>
    <path
      d="M290.080 231.2c-7.92 2-16.4 6.080-22.56 10.72-7.52 5.68-32.4 31.44-35.84 37.040-10.64 17.68-12.080 37.92-4.080 56.64 4.24 9.92 7.12 12.96 90.080 96l81.52 81.6-81.52 81.6c-84.16 84.24-85.76 86-90.4 96.8-8.4 19.76-5.52 43.12 7.36 60 5.76 7.68 31.52 32.48 37.12 35.92 20.64 12.4 45.12 12.24 64.8-0.48 2.32-1.52 38.96-37.36 85.2-83.44 44.64-44.48 81.84-81.44 82.72-82.16 1.6-1.36 3.68 0.64 83.6 80.32 82 81.76 86.4 85.92 95.52 89.84 6.72 2.88 13.92 4.48 22 4.96 6.96 0.32 9.6 0.16 15.6-1.28 8.48-2.080 16.8-5.92 23.28-10.8 7.52-5.68 32.4-31.44 35.84-37.040 10.64-17.68 12.080-37.92 4.080-56.64-3.92-9.12-8-13.44-89.84-95.28-44.96-44.96-81.76-82-81.76-82.4 0-0.32 36.96-37.6 82.16-82.88 48.8-48.8 83.28-83.92 84.88-86.4 12.72-19.84 12.88-44.24 0.48-64.88-3.44-5.68-28.32-31.44-35.92-37.12-10.080-7.6-20.64-11.36-33.6-12-13.28-0.64-24 2.32-36 9.92-4.32 2.8-23.84 21.76-87.28 85.12l-81.6 81.6-82-81.76c-81.84-81.68-86.48-86-95.52-89.92-11.68-4.96-27.12-6.4-38.32-3.6z"
    ></path>
    <path d="M0 512v56.8h113.6v-113.6h-113.6v56.8z"></path>
    <path d="M910.4 512v56.8h113.6v-113.6h-113.6v56.8z"></path>
    <path d="M0 739.6v57.2h113.6v-114.4h-113.6v57.2z"></path>
    <path d="M910.4 739.6v57.2h113.6v-114.4h-113.6v57.2z"></path>
    <path
      d="M0.32 917.76c4.24 57.040 48.88 101.68 105.92 105.92l7.36 0.56v-113.84h-113.84l0.56 7.36z"
    ></path>
    <path d="M227.2 967.2v56.8h114.4v-113.6h-114.4v56.8z"></path>
    <path d="M455.2 967.2v56.8h113.6v-113.6h-113.6v56.8z"></path>
    <path d="M682.4 967.2v56.8h114.4v-113.6h-114.4v56.8z"></path>
    <path
      d="M910.4 967.28v56.96l7.44-0.56c56.96-4.24 101.6-48.88 105.84-105.92l0.56-7.36h-113.84v56.88z"
    ></path>
  </svg>
</template>
