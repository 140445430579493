<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { translate } from '@/ui/plugins/i18n/myi18n';
import securityStore from '@/store/security';
const router = useRouter();
const _securityStore = securityStore();
const timeRemaining = ref(0);
const message = ref('');
onMounted(async () => {
  try {
    const data = await _securityStore.fetchBlockStatus();
    if (!data?.isBlocked) {
      router.push('/');
      return;
    }
  } catch (error: any) {
    timeRemaining.value = parseInt(
      error?.error?.timeBlockRemaining?.toString()
    );
  }
  if (timeRemaining.value > 0) startCount();
});
let countDownTime;
const startCount = () => {
  countDownTime = setInterval(function () {
    if (timeRemaining.value <= 0) {
      message.value = '';
      clearInterval(countDownTime);
      router.push('/');
    } else {
      message.value = translate('SUSPICIOUS_REQUEST_COUNTDOWN_TIME', {
        minute: Math.floor(timeRemaining.value / 60),
        second: timeRemaining.value % 60,
      });
    }
    timeRemaining.value -= 1;
  }, 1000);
};

onUnmounted(() => {
  clearInterval(countDownTime);
});
</script>

<template>
  <div class="h-screen w-screen bg-current-50 flex-center flex-col space-y-2">
    <div class="flex-center flex-col space-y-2">
      <span
        class="text-7xl font-bold tracking-wider uppercase text-white"
        style="text-shadow: 0px 0px 5px #1a9793"
      >
        {{ $t('COMMON_LABEL_SORRY') }}
      </span>
      <span class="font-thin text-current-700 text-2xl text-center">
        {{ $t('SUSPICIOUS_REQUEST_WARNING') }}
      </span>
      <syn-animation name="blockCode" stype="width: 150px;" />
      <span v-if="timeRemaining > 0" class="text-orange-600 text-md">
        {{ message }}
      </span>
      <div v-else class="flex space-y-2 flex-col">
        <router-link to="/">
          <syn-button :label="$t('COMMON_LABEL_CONTINUE_TO_WORD')" type-outline>
          </syn-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
