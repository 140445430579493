import { ref } from 'vue';
import chatStore from '@/store/chat';

export const isOpenSpeedChatDrawer = ref(false);
export const lastCloseSpeedChatDrawerAt = ref(0);

export const closeSpeedChatDrawer = () => {
    if (!isOpenSpeedChatDrawer.value) return;
    lastCloseSpeedChatDrawerAt.value = Date.now();

    isOpenSpeedChatDrawer.value = false;

    chatStore().closeBubbleChat();
};
