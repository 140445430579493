<template>
  <svg
    v-if="!isActive"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 511.94 371.2"
  >
    <path
      d="M279.33,206.57H244.07a13.33,13.33,0,0,0-12.32,8.48,70.21,70.21,0,0,1-52.91-49.87A12.32,12.32,0,0,0,190.65,153V116.69a12.65,12.65,0,0,0-11-11.93h-59.4a8.31,8.31,0,0,0-8.32,8.32h0v2.16a145.63,145.63,0,0,0,6.32,42.94A173.4,173.4,0,0,0,236.75,277.44a199.7,199.7,0,0,0,42.94,6.32h4.48a8.33,8.33,0,0,0,8.33-8.33V218.36a8.17,8.17,0,0,0-.86-3.31A13.48,13.48,0,0,0,279.33,206.57Z"
      transform="translate(-8.57 -8.5)"
    />
    <path
      d="M520.46,299.86V88.36a34.14,34.14,0,0,0-17.44-30,40.33,40.33,0,0,0-31.45-2l-70,24.56V57.65A47.65,47.65,0,0,0,355.45,8.52H57.7A47.66,47.66,0,0,0,8.57,54.66V330.57A49.13,49.13,0,0,0,57.7,379.7H352.44a49.13,49.13,0,0,0,49.13-49.13V309.2l69,22.84a33.46,33.46,0,0,0,15.23,3.69,31.38,31.38,0,0,0,18.18-5.9A32.43,32.43,0,0,0,520.46,299.86Zm-28.29-1.37c0,2.43,0,6.79-3.4,8a10.82,10.82,0,0,0-3.39,2.18,19.65,19.65,0,0,1-6.31,0l-3.15-1.45L405.12,283a12.83,12.83,0,0,1-6.31-9.45,12.12,12.12,0,1,0-24.24,0h0v55.27A24.25,24.25,0,0,1,350.33,353H59.38a24.25,24.25,0,0,1-24.24-24.25V59.42A22.8,22.8,0,0,1,56.38,35.17h0a24.36,24.36,0,0,1,3,0h291a22.8,22.8,0,0,1,24.25,21.25,24.36,24.36,0,0,1,0,3v55.77a12.12,12.12,0,0,0,24.24,0h0a12.65,12.65,0,0,1,5.09-9.21l72.74-24.26a15.79,15.79,0,0,1,11.4,0,9.49,9.49,0,0,1,4.61,8.74Z"
      transform="translate(-8.57 -8.5)"
    />
  </svg>
  <svg
    v-else
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 511.94 371.2"
  >
    <path
      d="M655.34,506.53V295a34.14,34.14,0,0,0-17.44-30,40.33,40.33,0,0,0-31.45-2l-70,24.56V264.32a47.65,47.65,0,0,0-46.12-49.13H192.58a47.66,47.66,0,0,0-49.13,46.14V537.24a49.13,49.13,0,0,0,49.13,49.13H487.32a49.13,49.13,0,0,0,49.13-49.13V515.87l69,22.84a33.46,33.46,0,0,0,15.23,3.69,31.4,31.4,0,0,0,18.18-5.9A32.43,32.43,0,0,0,655.34,506.53Zm-228-24.43a8.33,8.33,0,0,1-8.33,8.33h-4.49a199.7,199.7,0,0,1-42.93-6.32A173.4,173.4,0,0,1,253.15,364.86a145.61,145.61,0,0,1-6.32-42.93v-2.17h0a8.31,8.31,0,0,1,8.31-8.32h59.41a12.65,12.65,0,0,1,11,11.93V359.7a12.32,12.32,0,0,1-11.81,12.15,70.24,70.24,0,0,0,52.91,49.88A13.32,13.32,0,0,1,379,413.24h35.26a13.47,13.47,0,0,1,12.31,8.49,8.08,8.08,0,0,1,.86,3.3Z"
      transform="translate(-143.45 -215.17)"
    />
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
