<script setup lang="ts">
import { computed, ref } from 'vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import groupStore from '@/store/group';
import { IGroupDetailEntity } from '@/domain/entities/group/GroupDetailEntity';

const props = defineProps<{
  modelValue: IGroupDetailEntity | null;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', data: IGroupDetailEntity): void;
}>();

const _groupStore = groupStore();

const allGroupByIds = computed<{ [key: number]: IGroupDetailEntity }>(() => {
  return _groupStore.allGroupByIds;
});
const allMyGroups = computed<any[]>(() => {
  return _groupStore.allMyGroups;
});

const isShowMore = ref<boolean>(false);

const groupActiveInMore = computed<IGroupDetailEntity | undefined>(() => {
  return props.modelValue;
});

const onChoose = (groupId) => {
  emit('update:modelValue', allGroupByIds.value[groupId]);
};
</script>
<template>
  <div class="w-full flex items-center justify-between p-2 space-x-2">
    <slot name="prefix"></slot>

    <div class="flex items-center gap-2">
      <VigDropdown
        :arrow="false"
        placement="bottom-end"
        dropdown-item-class="group-select__dropdown-item"
        @on-dropdown-open="isShowMore = true"
        @on-dropdown-close="isShowMore = false"
      >
        <template #dropdown-toggle>
          <div
            class="
              flex
              items-center
              justify-between
              cursor-pointer
              rounded-full
              border
              py-0.5
              h-8
              pl-1
              pr-2
              gap-2
            "
            :class="[
              isShowMore
                ? 'ring-2 ring-current-100 ring-opacity-50 border-current-200 fill-current-400'
                : 'fill-gray-500',
              groupActiveInMore?.id || !modelValue?.id
                ? ' bg-current-500 text-white hover:bg-current-600 fill-white'
                : 'hover:bg-current-50',
            ]"
          >
            <GroupById
              v-if="groupActiveInMore?.id"
              :group-id="groupActiveInMore?.id"
              class="text-sm"
            />

            <span v-else class="text-sm pl-2 truncate">
              {{ $t('GROUP_LABEL_USER_HAS_ANY_GROUP') }}
            </span>

            <SynIcon
              name="dots-vertical"
              :custom-class="
                groupActiveInMore?.id || !modelValue?.id
                  ? 'w-4 h-4 rotate-90 fill-white'
                  : 'w-4 h-4 fill-gray-500 text-gray-500 rotate-90'
              "
            />
          </div>
        </template>
        <template #dropdown-menu>
          <div
            class="
              flex flex-col
              group-select__dropdown-item
              p-1
              overflow-y-auto
              small-scrollbar
            "
            style="min-width: 14rem; max-height: 32rem; max-width: 16rem"
          >
            <div
              class="
                pl-2
                py-2
                space-x-2
                flex
                items-center
                hover:bg-current-50
                cursor-pointer
                group-select__dropdown-item
                text-sm
              "
              :class="!modelValue?.id ? 'bg-current-50 text-current-500' : ''"
              @click="onChoose(null)"
            >
              <span class="pl-8 text-sm underline">
                {{ $t('GROUP_LABEL_USER_HAS_ANY_GROUP') }}
              </span>
            </div>

            <template
              v-for="group in Object.values(allMyGroups)"
              :key="group?.id"
            >
              <div
                class="
                  pl-2
                  py-2
                  space-x-2
                  flex
                  items-center
                  hover:bg-current-50
                  cursor-pointer
                  group-select__dropdown-item
                  text-sm
                "
                :class="
                  group?.id == modelValue?.id
                    ? 'bg-current-50 text-current-500'
                    : ''
                "
                @click="onChoose(group?.id)"
              >
                <GroupById
                  :group-id="group?.id"
                  class="text-sm"
                  :is-name-center="false"
                />
              </div>
            </template>
          </div>
        </template>
      </VigDropdown>
      <slot name="suffix"></slot>
    </div>
  </div>
</template>
