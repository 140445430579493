import { ref } from 'vue';
import ZaloService from '@/application/services/ZaloService';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

export const oaInfo = ref({});
export const userFollowInfo = ref({});

export const fetchOaInfo = async () => {
    const res = await ZaloService.getInstance().getOAInfo();
    oaInfo.value = res.result ?? {};
};

export const fetchUserFollowInfo = async () => {
    const userId = getCurrentUserId();
    const res = await ZaloService.getInstance().getUserFollowedInfo(userId);
    userFollowInfo.value = res.result;
};

export const fetchUserIdFollowInfo = async (userId) => {
    const res = await ZaloService.getInstance().getUserFollowedInfo(userId);
    userFollowInfo.value = res.result;
};

export const oaAccessTokenCallback = async (queryCallback) => {
    await ZaloService.getInstance().gaveOaAccessToken(queryCallback);
};
