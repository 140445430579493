<template>
  <syn-modal
    z-index="z-50"
    container-class="md:w-1/3 h-max"
    style-body="py-8 px-4"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <span>
        {{ $t('PROFILE_SET_UP_PASSWORD') }}
      </span>
    </template>
    <template #body>
      <!-- GET CODE -->
      <section v-if="!isEmailVerify && keySetup == 'GET_CODE'">
        <get-code-set-password
          class="flex"
          :email="email"
          :is-loading-resend="isLoadingSend"
          :is-loading="isLoadSend"
          :error-code="msgError"
          @code-ready="(value) => (isCodeReady = value)"
          @change-code="(code) => getCodeVerify(code)"
          @resend-code="onSendEmailCode()"
        ></get-code-set-password>
      </section>

      <!-- SETUP PWD -->
      <section v-if="keySetup == 'SET_PWD'">
        <div class="flex items-center space-y-2 flex-wrap h-full">
          <div class="flex flex-wrap space-y-4">
            <div class="flex space-x-2 items-center">
              <div>
                <SynIcon name="lock" class="fill-current" />
              </div>
              <div>
                <div class="font-medium text-current-800">
                  {{ $t('PROFILE_SET_UP_PASSWORD') }}
                </div>
                <div class="text-sm text-gray-500">
                  {{ $t('PROFILE_SET_UP_PASSWORD_LABEL') }}
                </div>
              </div>
            </div>
            <div class="flex w-full">
              <div class="flex items-center pr-4 text-sm text-current-800 w-48">
                <span>{{ $t('USERMANAGEMENT_LABEL_NEW_PASSWORD') + '*' }}</span>
              </div>
              <div class="w-full">
                <syn-form-input
                  :error-message="
                    setupPasswordError && isError ? setupPasswordError : ''
                  "
                >
                  <syn-input
                    v-model="setupPassword"
                    autofocus
                    :placeholder="$t('LOGIN_FORM_PASSWORD')"
                    :error-message="
                      setupPasswordAgnError && isError
                        ? setupPasswordAgnError
                        : ''
                    "
                    :icon-right="showPWDsetup ? 'eye' : 'eye-close'"
                    :input-type="showPWDsetup ? 'text' : 'password'"
                    @on-action="
                      (value) => {
                        showPWDsetup = !showPWDsetup;
                      }
                    "
                  ></syn-input>
                </syn-form-input>
              </div>
            </div>
            <div class="flex w-full">
              <div class="flex items-center pr-4 text-sm text-current-800 w-48">
                <span>{{
                  $t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM') + '*'
                }}</span>
              </div>
              <div class="w-full">
                <syn-form-input
                  :error-message="
                    setupPasswordAgnError && isError
                      ? setupPasswordAgnError
                      : ''
                  "
                >
                  <syn-input
                    v-model="setupPasswordAgn"
                    input-type="password"
                    :placeholder="
                      $t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM')
                    "
                    :error-message="
                      setupPasswordAgnError && isError
                        ? setupPasswordAgnError
                        : ''
                    "
                    @enter="onSetupPassword()"
                  ></syn-input>
                </syn-form-input>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>

    <template #footer>
      <div class="px-4 flex py-4 space-x-2 justify-end">
        <syn-button
          type-text
          color="gray"
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          @click="onCancel"
        >
        </syn-button>
        <syn-button
          :label="
            keySetup == 'SET_PWD'
              ? $t('COMMON_LABEL_SAVE')
              : $t('COMMON_LABEL_CONTINUE')
          "
          :is-loading="isSavePass || isLoadSend"
          :disabled="
            (setupPassword &&
              setupPasswordAgn &&
              setupPassword === setupPasswordAgn &&
              keySetup == 'SET_PWD') ||
            (isCodeReady && keySetup == 'GET_CODE')
              ? false
              : true
          "
          @click="keySetup == 'SET_PWD' ? onSetupPassword() : onVeriryEmail()"
        >
        </syn-button>
      </div>
    </template>
  </syn-modal>
</template>
<script>
import { ref } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { AdminType } from '@/ui/common/constants/constant';
import userStore from '@/store/user';
import { useField } from 'vee-validate';
import authStore from '@/store/auth';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import GetCodeSetPassword from '@/ui/pages/auth/GetCodeSetPassword.vue';
export default {
  components: {
    GetCodeSetPassword,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    isEmailVerify: {
      type: Boolean,
      default: false,
    },
    adminType: {
      type: Number,
      default: 2,
    },
  },
  emits: ['cancel'],
  setup(props, { emit }) {
    const _userStore = userStore();
    const isError = ref(false);
    const showPWDsetup = ref(false);
    const _authStore = authStore();
    const keySetup = ref('GET_CODE');

    const init = async () => {
      if (props.isEmailVerify) {
        keySetup.value = 'SET_PWD';
      } else {
        keySetup.value = 'GET_CODE';
      }
      const resCheck = await _userStore.checkHasSendVerificationUserEmail();
      if (!resCheck?.result) {
        const res = ref();
        try {
          res.value = await _userStore.sendVerificationUserEmail();
        } catch (response) {
          msgError.value = translate(
            ErrorNotificationLogin[response.value.message]
          );
        }
      }
    };
    init();

    const { value: setupPassword, errorMessage: setupPasswordError } = useField(
      'data.setupPassword',
      function (value) {
        if (!value) return translate('COMMON_THIS_FIELD_REQUIRED');
        if (value.length < 6) {
          isError.value = true;
          return translate('COMMON_LABEL_ALERT_PASS_CHARACTER');
        }
        if (value !== setupPasswordAgn.value && setupPasswordAgn.value) {
          isError.value = true;
          return translate('COMMON_LABEL_ALERT_ERROR_PASS');
        } else {
          isError.value = false;
        }
        return true;
      }
    );
    const { value: setupPasswordAgn, errorMessage: setupPasswordAgnError } =
      useField('data.setupPasswordAgn', function (value) {
        if (!value) return translate('COMMON_THIS_FIELD_REQUIRED');
        if (value.length < 6) {
          isError.value = true;
          return translate('COMMON_LABEL_ALERT_PASS_CHARACTER');
        }
        if (value !== setupPassword.value && setupPassword.value) {
          isError.value = true;
          return translate('COMMON_LABEL_ALERT_ERROR_PASS');
        } else {
          isError.value = false;
        }
        return true;
      });

    const isSavePass = ref(false);
    const onSetupPassword = async () => {
      const pass = setupPassword.value;
      isSavePass.value = true;
      try {
        await _userStore.setPassword(pass);
        isSavePass.value = false;
        emit('cancel', false);
      } catch (error) {
        isSavePass.value = false;
      }
    };
    const onCancel = () => {
      emit('cancel', false);
    };

    const codeOTP = ref('');
    const isCodeReady = ref(false);
    const getCodeVerify = (code) => {
      codeOTP.value = code;
    };

    const isLoadSend = ref(false);
    const msgError = ref('');

    const onVeriryEmail = async () => {
      isLoadSend.value = true;
      const res = ref();
      try {
        res.value = await _authStore.submitVerificationUserEmailToken({
          c: '',
          code: codeOTP.value,
          email: props.email,
        });
        if (res.value && !res.value.verifiedEmail && res.value.message) {
          isLoadSend.value = false;
          keySetup.value = 'GET_CODE';
          msgError.value = translate(ErrorNotificationLogin[res.value.message]);
        } else {
          isLoadSend.value = false;
          keySetup.value = 'SET_PWD';
        }
      } catch (response) {
        isLoadSend.value = false;
        keySetup.value = 'GET_CODE';
        msgError.value = translate(
          ErrorNotificationLogin[response.value.message]
        );
      }
    };

    const isLoadingSend = ref(false);

    const onSendEmailCode = async () => {
      if (msgError.value) {
        msgError.value = '';
      }
      isLoadingSend.value = true;
      const res = await _userStore.sendVerificationUserEmail();
      if (!res?.result) {
        isLoadingSend.value = false;
      } else {
        isLoadingSend.value = false;
      }
    };

    return {
      keySetup,
      isError,
      setupPassword,
      setupPasswordError,
      setupPasswordAgn,
      setupPasswordAgnError,
      isSavePass,
      AdminType,
      showPWDsetup,
      onSetupPassword,
      onCancel,
      codeOTP,
      getCodeVerify,
      isLoadSend,
      msgError,
      onVeriryEmail,
      isLoadingSend,
      onSendEmailCode,
      isCodeReady,
    };
  },
};
</script>
