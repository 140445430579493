<script setup lang="ts">
import { computed } from 'vue';
import UserById from '@/ui/components/user/UserById.vue';
import { CalendarSourceId } from '@/domain/enums/CalendarEnum';

const props = defineProps<{
  event: any;
}>();

const googleCreator = computed(() => ({
  name: props.event?.synchronization?.eventCreatedBy,
}));
</script>

<template>
  <template v-if="event?.sourceId === CalendarSourceId.googleCalendar">
    <UserById
      v-if="googleCreator?.name"
      :user="googleCreator"
      is-hidden-avatar
      text-class="text-sm max-w-[12rem]"
    />
  </template>

  <UserById
    v-else
    :user-id="event?.createdBy"
    is-show-first-name
    avatar-class="w-4 h-4"
    class="text-sm"
  />
</template>
