<script setup lang="ts">
import { computed } from 'vue';
import TaskState from '@/ui/modules/task/components/TaskState.vue';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});
const beforeActivity = computed(() => props.beforeUpdate);
const afterActivity = computed(() => props.afterUpdate);
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 items-center justify-between pr-2">
      <TaskState
        v-if="beforeActivity?.taskLife !== null"
        custom-class="py-0.5 w-full space-x-2 text-xs"
        style="min-width: max-content"
        :task-life="beforeActivity?.TaskLife"
        readonly
      />
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
      <span>→</span>
    </div>

    <div class="flex w-1/2 justify-start pl-4">
      <TaskState
        v-if="afterActivity?.taskLife !== null"
        custom-class="py-0.5 w-full space-x-2 text-xs"
        style="min-width: max-content"
        :task-life="afterActivity?.TaskLife"
        readonly
      />
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </div>
  </div>
</template>
