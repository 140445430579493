import { IPaymentRepository } from '@/domain/interfaces/repositories/IPaymentRepository';
import BaseRepository from '@/base/repositories/BaseRepository';
import { IPaymentPayload } from '../types/payment.types';

export default class PaymentRepository implements IPaymentRepository {
    private static instance: PaymentRepository;

    constructor() {}

    public static getInstance(): PaymentRepository {
        if (!PaymentRepository.instance) {
            PaymentRepository.instance = new PaymentRepository();
        }
        return PaymentRepository.instance;
    }

    getPaymentConfigs() {
        return BaseRepository.requestWithAuthorize({
            url: `Payment/GetPaymentConfigs`,
            method: 'get',
        });
    }

    getPaymentHistories() {
        return BaseRepository.requestWithAuthorize({
            url: `Payment/GetPaymentHistories`,
            method: 'get',
        });
    }

    createPaymentToken(data: IPaymentPayload): any {
        return BaseRepository.requestWithAuthorize({
            url: 'Payment/CreateSession',
            method: 'post',
            data,
        });
    }

    getTransactionDetail(idTransaction): any {
        return BaseRepository.requestWithAuthorize({
            url: `Payment/GetTransactionDetail?id=${idTransaction}`,
            method: 'get',
        });
    }

    getAvailableVouchers(): any {
        return BaseRepository.requestWithAuthorize({
            url: `Voucher/GetAvailableVouchers`,
            method: 'get',
        });
    }
    checkLicense(data: { licenseKey: string }): any {
        return BaseRepository.requestWithAuthorize({
            url: `License/Check`,
            method: 'post',
            data,
        });
    }
    verifyLicense(data: { licenseKey: string }): any {
        return BaseRepository.requestWithAuthorize({
            url: `License/Verify`,
            method: 'post',
            data,
        });
    }
    applyLicense(data: { licenseKey: string; code: string }): any {
        return BaseRepository.requestWithAuthorize({
            url: `License/Applied`,
            method: 'post',
            data,
        });
    }
    usingFreePackage(): any {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/UsingFreePackage`,
            method: 'post',
        });
    }
}
