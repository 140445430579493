import { defineStore } from 'pinia';
import TaskService from '@/application/services/task/TaskService';
import storeId from '@/base/store/storeId';
import userStore from '@/store/user';
import dayjs from 'dayjs';
import groupStore from '@/store/group';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { chain } from 'lodash';

export default defineStore({
    id: storeId.taskComment,
    state: () => ({
        _taskService: TaskService.getInstance(),
        listCommentByTaskId: [] as any[],
        listAttachment: [] as any[],
        commentCacheByTaskIds: {} as any,
        isLoading: false,
    }),
    getters: {},
    actions: {
        getListUserMentionInComment(groupId, usersRelated, taskPrivate) {
            const _userStore = userStore();
            const _groupStore = groupStore();
            const myGroup = _groupStore.myGroups || [];
            let listUserInCurrentGroup: any[] = [];
            let isDirectMess = true;
            let indexCurrentGroup = -1;
            // const fullUrlArray = window.location.href?.split('/#');
            // const baseDomainUrl = fullUrlArray?.length
            //     ? fullUrlArray[0]
            //     : import.meta.env.VITE_DOMAIN_DEFAULT;
            const baseDomainUrl = import.meta.env.VITE_DOMAIN_DEFAULT;

            if (groupId) {
                isDirectMess = false;
                indexCurrentGroup = myGroup.findIndex(
                    (el) => el?.id == groupId
                );

                if (indexCurrentGroup !== -1) {
                    listUserInCurrentGroup =
                        myGroup[indexCurrentGroup]?.groupUsers || [];
                }
            }

            let listMentionCmt = chain(_userStore.listShortInfosUser || [])
                .map((x) => {
                    return {
                        id: `@${x?.firstName?.trim()}`,
                        userId: x?.id,
                        name: x?.name,
                        avatar:
                            x?.avatar ||
                            x?.profilePictureUrl ||
                            x.profilePictureUrl,
                        link: `${baseDomainUrl}/tasks/users/${x?.id}`,
                        isInGroup:
                            listUserInCurrentGroup.findIndex(
                                (el) => el['userId'] === x?.id
                            ) !== -1 || false,
                        isDirectMessage: isDirectMess,
                        mentionGroup: false,
                        adminType: x?.adminType,
                    };
                })
                .orderBy([(x) => (x.isInGroup ? 1 : 2), 'name'])
                .value();

            if (!groupId && taskPrivate) {
                const listRelated = usersRelated?.filter(
                    (el) => el != getCurrentUserId()
                );
                listMentionCmt =
                    listMentionCmt?.filter((el) =>
                        listRelated?.includes(el?.userId)
                    ) || [];
            }
            // if (!groupId && !taskPrivate) return listMentionCmt;
            if (groupId) {
                const currentG = myGroup[indexCurrentGroup];

                if (currentG?.isPrivate) {
                    listMentionCmt = listMentionCmt?.filter(
                        (el) => el?.isInGroup
                    );
                }

                listMentionCmt.unshift({
                    id: `@${translate('COMMON_LABEL_GROUP')}`,
                    userId: currentG?.id,
                    name: currentG?.name,
                    avatar:
                        currentG?.avatar ||
                        currentG?.avatarUrl ||
                        currentG.avatarThumnailUrl,
                    link: `${baseDomainUrl}/tasks/groups/${currentG?.id}`,
                    isInGroup: true,
                    isDirectMessage: false,
                    mentionGroup: true,
                    adminType: 3,
                });
            }

            return listMentionCmt;
        },

        async getAllCommentsByTaskId(orgId, idTask) {
            try {
                const res = await this._taskService.getAllComments(
                    orgId,
                    idTask
                );

                this.listCommentByTaskId = res['result'];
                this.orderByListComment(this.listCommentByTaskId);

                return res['result'];
            } catch (e) {
                console.log(e);
            }
        },

        getListAttachmentById(id) {
            const listAttachment = this.listCommentByTaskId.filter(
                (o) =>
                    o.id == id &&
                    (o.attachments.files.length > 0 ||
                        o.attachments.notes.length > 0)
            );
            const arr = [] as any[];
            listAttachment.forEach((e) => {
                e.attachments.files.forEach((o) => arr.push(o));
                e.attachments.notes.forEach((el) =>
                    arr.push({ ...el, isNote: true, contentType: 'image/jpeg' })
                );
            });
            this.listAttachment = [...new Set(arr)];
        },

        async createComment(
            orgId,
            taskId,
            content: string,
            attachmentFiles,
            notes,
            mentionUserIds,
            mentionGroup,
            replyTo
        ) {
            try {
                const res = await this._taskService.createComment(
                    orgId,
                    taskId,
                    content,
                    attachmentFiles,
                    notes,
                    mentionUserIds,
                    mentionGroup,
                    replyTo
                );
                const newCmt = res['result'];
                this.updateListCommentLocal(newCmt, 'all');
                // this.getAllCommentsByTaskId(taskId);
            } catch (e) {
                console.log(e);
            }
        },

        async onUpdateComment(
            cmtId,
            content,
            attachments: {
                //files
                addedFiles: any[];
                removedFiles: any[];
                //notes
                addedNotes: any[];
                editedNotes: any[];
                removedNotes: any[];
            },
            mentionUserIds,
            mentionGroup
        ) {
            try {
                const res = await this._taskService.updateComment(
                    cmtId,
                    content,
                    mentionUserIds,
                    mentionGroup
                );
                const newComment = res.result;

                if (
                    attachments?.addedFiles?.length > 0 ||
                    attachments?.removedFiles?.length > 0 ||
                    attachments?.addedNotes?.length > 0 ||
                    attachments?.editedNotes?.length > 0 ||
                    attachments?.removedNotes?.length > 0
                ) {
                    this.updateCommentAttachments(cmtId, attachments);

                    return;
                }

                this.updateListCommentLocal(newComment, 'all');
            } catch (e) {
                console.log(e);
            }
        },
        async updateCommentAttachments(
            cmtId,
            attachments: {
                //files
                addedFiles: any[];
                removedFiles: any[];
                //notes
                addedNotes: any[];
                editedNotes: any[];
                removedNotes: any[];
            }
        ) {
            try {
                this.isLoading = true;
                const res = await this._taskService.updateCommentAttachments(
                    cmtId,
                    attachments
                );
                this.updateListCommentLocal(res?.result, 'all');
                this.getListAttachmentById(cmtId);
                this.isLoading = false;
                return;
            } catch (e) {
                console.log(e);
                this.isLoading = false;
            }
        },

        async addReactionComment(cmtId, content, uncheck, type = 'EMOTION') {
            if (!content || !cmtId) return;
            const covertData = {
                content: content,
                remove: uncheck,
                type,
            };
            try {
                const res = await this._taskService.reactionComment(
                    cmtId,
                    covertData
                );
                const updateCmt = res['result'];
                this.updateListCommentLocal(updateCmt, 'reactions');
            } catch (e) {
                console.log(e);
            }
        },

        async deleteComment(cmtId) {
            if (!cmtId) return;
            try {
                await this._taskService.deleteComment(cmtId);
                const indexCmt = this.listCommentByTaskId.findIndex(
                    (el) => el?.id === cmtId
                );
                if (indexCmt !== -1) {
                    this.listCommentByTaskId.splice(indexCmt, 1);
                }
            } catch (e) {
                console.log(e);
            }
        },

        async pinnedComment(cmtId) {
            if (!cmtId) return;
            try {
                const res = await this._taskService.pinnedComment(cmtId);
                const updateCmt = res['result'];
                this.updateListCommentLocal(updateCmt, 'pinned');
            } catch (e) {
                console.log(e);
            }
        },

        updateListCommentLocal(cmt, key) {
            const indexCmt = this.listCommentByTaskId.findIndex(
                (el) => el?.id === cmt?.id
            );
            if (indexCmt !== -1) {
                if (key === 'all') {
                    this.listCommentByTaskId[indexCmt] = cmt;
                } else {
                    this.listCommentByTaskId[indexCmt][key] = cmt[key];
                }
            } else {
                this.listCommentByTaskId.push(cmt);
            }
            this.orderByListComment(this.listCommentByTaskId);
        },

        orderByListComment(lists) {
            lists
                ?.filter((el) => !el?.replyTo)
                ?.forEach((el, index) => {
                    const formatCurrentDay = dayjs(el?.createdDate).format(
                        'DD/MM/YYYY'
                    );
                    const formatPreviousDay = dayjs(
                        lists[index - 1]?.createdDate
                    ).format('DD/MM/YYYY');
                    const formatNextDay = dayjs(
                        lists[index + 1]?.createdDate
                    ).format('DD/MM/YYYY');

                    el.isFirstCommentDay =
                        formatCurrentDay !== formatPreviousDay;
                    el.isLastCommentDay = formatCurrentDay !== formatNextDay;
                });

            return lists;
        },

        updateCommentCacheByTaskIds(
            taskId,
            data: {
                content: string;
                mentionUserIds: any[];
                attachmentFiles: any[];
                noteList: any[];
            }
        ) {
            if (
                !data.content &&
                data?.attachmentFiles?.length == 0 &&
                data?.noteList?.length == 0 &&
                Object.prototype.hasOwnProperty.call(
                    this.commentCacheByTaskIds,
                    taskId
                )
            ) {
                delete this.commentCacheByTaskIds[taskId];
            }

            this.commentCacheByTaskIds = {
                ...this.commentCacheByTaskIds,
                [taskId]: data,
            };
        },

        async deleteAttachmentInComment(cmtId, data) {
            if (!cmtId) return;
            try {
                const res = await this._taskService.deleteAttachmentInComment(
                    cmtId,
                    data
                );
                const newComment = res.result;
                this.updateListCommentLocal(newComment, 'all');
            } catch (err) {
                console.log('File: task-comment.ts - L: 340 - ', err);
            }
        },
    },
});
