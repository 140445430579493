import TaskCreatePayload from '@/domain/entities/task/TaskPayloadClass';
import TaskService from '@/application/services/task/TaskService';
import {
    PlanTypeEnum,
    ScheduleDayOptions,
    TaskLife,
} from '@/domain/enums/taskEnum';
import {
    ask,
    settingGlobalModal,
    tell,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
    formatTaskCode,
    getScheduleTimeAfterChangeDate,
    getUrgencyValue,
} from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import TaskOriginalClass, { ITaskOriginalClass } from './TaskOriginalClass';
import groupStore from '@/store/group';
import { NotificationTypeEnum, openNotification } from '@/ui/hooks/commonHook';
import myProfileStore from '@/store/auth/my-profile';
import { getDefaultTaskSchedule } from '@/application/parsers/TaskParser';
import {
    ITaskReccurring,
    ITaskReminder,
} from '@/application/interfaces/tasks/task-interfaces';
import {
    ETaskFileStatus,
    ISubTaskDetail,
    ITaskApprover,
    ITaskTodo,
    IUpdateCollaboratorPayload,
    TaskApprovalStatus,
} from '@/application/types/task/task.types';
import { IWorkflowEntity } from '../workflow/WorkflowEntity';
// import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';
import TaskWorkflowEntity, { ITaskWorkflowEntity } from './TaskWorkflowEntity';
// import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import { ITaskWorkflowStep } from './TaskWorkflowStepEntity';
import { updateStepFromTask } from '@/ui/composables/task/workflow/task-workflow-step.composables';
import { TaskTemplateEntity } from './TaskTemplateEntity';
import userStore from '@/store/user';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';
import ConfirmRemoveTask from '@/ui/modules/task/detail/action-on-task/ConfirmRemoveTask.vue';
import dayjs from 'dayjs';

export interface ITaskDetailClass extends ITaskOriginalClass {
    code: string;
    creationTime: Date;
    lastModificationTime: Date | null;
    finishedTime: Date | null;
    isGroupMember: boolean | null;
    attachmentCount: number;
    noteCount: number;
    errorCode: number | null;
    attachments: any[];
    urgency: number;
    totalComment: number;
    taskTodoTotal: number;
    taskTodoItemTotal: number;
    taskTodoItemTotalFinished: number;
    taskSnapshotTotal: number;
    pinnedColor: string;
    todoList?: ITaskTodo[];
    sharedId: string;
    isPlannedToFinish?: boolean;
    isPlannedToTreat?: boolean;
    shareList?: any[];
    workFlowTaskId?: string;
    workflowId?: string;
    workflowStepId?: string;

    // Approval
    approvalEnabled?: boolean;
    approvalStatus?: TaskApprovalStatus;
    approverApprovalDate?: any;
    approverApprovalStatus?: number;
    approverId?: number;
    approvers?: ITaskApprover[];
    taskReminderInfo?: ITaskReminder;
    subTasks?: ISubTaskDetail[];
    totalSubTask?: number;

    isCreateListAssignees?: boolean;
    finishProofEnabled?: boolean;
    finishProofTypes?: string;
    finishProofSkipAllowed?: boolean;

    planningStartTime?: string;
    planningEndTime?: string;
}

const prepareInitData = (data) => {
    return {
        ...data,
        code: data?.code || '',
        creationTime: data?.creationTime,
        lastModificationTime: data?.lastModificationTime,
        finishedTime: data?.finishedTime,
        isGroupMember: data?.isGroupMember,
        attachmentCount: data?.attachmentCount || 0,
        attachments: data?.attachments || [],
        urgency:
            data?.urgency | getUrgencyValue(data?.priority, data?.important),
        noteCount: data?.noteCount || 0,
        errorCode: data?.errorCode,
        totalComment: data?.totalComment || 0,
        taskTodoTotal: data?.taskTodoTotal || 0,
        taskTodoItemTotal: data?.taskTodoItemTotal || 0,
        taskTodoItemTotalFinished: data?.taskTodoItemTotalFinished || 0,
        pinnedColor: data?.pinnedColor || '',
        taskSnapshotTotal: data?.taskSnapshotTotal || '',
        todoList: data?.checklist || null,
        sharedId: data?.sharedId || null,
        workFlowTaskId: data?.workFlowTaskId,
        shareList: data?.shareList || null,
        taskWorkflow: data?.taskWorkflow || undefined,
        taskReminderInfo: data?.taskReminderInfo || undefined,

        approvalEnabled: data?.approvalEnabled,
        approvalStatus: data?.approvalStatus,
        approverApprovalDate: data?.approverApprovalDate,
        approverApprovalStatus: data?.approverApprovalStatus,
        approverId: data?.approverId,
        approvers: data?.approvers,
        subTasks: data?.subTasks,
        totalSubTask: data?.totalSubTask,
        isCreateListAssignees: data?.isCreateListAssignees,

        finishProofEnabled: data?.finishProofEnabled,
        finishProofTypes: data?.finishProofTypes,
        finishProofSkipAllowed: data?.finishProofSkipAllowed,

        workflowStepId: data?.workflowStepId,
    };
};
export class TaskDetailClass
    extends TaskOriginalClass
    implements ITaskDetailClass
{
    code: string;
    creationTime: Date;
    lastModificationTime: Date | null;
    finishedTime: Date | null;
    workflowNextStepTaskId?: number;
    isGroupMember: boolean | null;
    attachmentCount: number;
    noteCount: number;
    errorCode: number | null;
    attachments: any[];
    urgency: number;
    totalComment: number;
    taskTodoTotal: number;
    taskTodoItemTotal: number;
    taskTodoItemTotalFinished: number;
    taskSnapshotTotal: number;
    pinnedColor: string;
    todoList?: ITaskTodo[];
    sharedId: string;
    workFlowTaskId?: string;
    shareList: any[];
    taskWorkflow?: ITaskWorkflowEntity;
    taskReminderInfo?: ITaskReminder;
    totalSubTask?: number;

    // Approval
    approvalEnabled?: boolean;
    approvalStatus?: TaskApprovalStatus;
    approverApprovalDate?: any;
    approverApprovalStatus?: number;
    approverId?: number;
    approvers?: ITaskApprover[];
    subTasks?: ISubTaskDetail[];
    isCreateListAssignees?: boolean;

    finishProofEnabled?: boolean;
    finishProofTypes?: string;
    finishProofSkipAllowed?: boolean;

    workflowStepId?: string;
    chatGroupId?: number;

    planningStartTime?: string;
    planningEndTime?: string;

    constructor(_data: any) {
        const data = prepareInitData(_data);
        super(data);

        this.code = data?.code || '';
        this.creationTime = data?.creationTime;
        this.lastModificationTime = data?.lastModificationTime;
        this.finishedTime = data?.finishedTime;
        this.isGroupMember = data?.isGroupMember;
        this.attachmentCount = data?.attachmentCount || 0;
        this.attachments = data?.attachments || [];
        this.urgency =
            data?.urgency || getUrgencyValue(data?.priority, data?.important);
        this.noteCount = data?.noteCount || 0;
        this.errorCode = data?.errorCode;
        this.totalComment = data?.totalComment || 0;
        this.taskTodoTotal = data?.taskTodoTotal || 0;
        this.taskTodoItemTotal = data?.taskTodoItemTotal || 0;
        this.taskTodoItemTotalFinished = data?.taskTodoItemTotalFinished || 0;
        this.pinnedColor = data?.pinnedColor || '';
        this.taskSnapshotTotal = data?.taskSnapshotTotal || '';
        this.todoList = data?.checklist || null;
        this.sharedId = data?.sharedId || null;
        this.workFlowTaskId = data?.workFlowTaskId;
        this.shareList = data?.shareList || null;
        this.taskWorkflow = data?.taskWorkflow || undefined;
        this.taskReminderInfo = data?.taskReminderInfo || undefined;

        this.approvalEnabled = data?.approvalEnabled;
        this.approvalStatus = data?.approvalStatus;
        this.approverApprovalDate = data?.approverApprovalDate;
        this.approverApprovalStatus = data?.approverApprovalStatus;
        this.approverId = data?.approverId;
        this.approvers = data?.approvers;
        this.subTasks = data?.subTasks;
        this.totalSubTask = data?.totalSubTask;
        this.isCreateListAssignees = data?.isCreateListAssignees;

        this.finishProofEnabled = data?.finishProofEnabled;
        this.finishProofTypes = data?.finishProofTypes;
        this.finishProofSkipAllowed = data?.finishProofSkipAllowed;

        this.workflowStepId = data?.workflowStepId;
        this.chatGroupId = data?.chatGroupId;

        this.planningStartTime = data?.planningStartTime;
        this.planningEndTime = data?.planningEndTime;
    }

    async clone() {
        const clonedTask = await TaskService.getInstance().clone(this);
        // const oldTaskCodeShort = this._taskService.getTaskCodeShort(task.code);
        const cloneTaskCodeShort = TaskService.getInstance().getTaskCodeShort(
            clonedTask.code
        );

        let groupName = '';
        if (clonedTask.groupId > 0) {
            const _groupStore = groupStore();
            groupName =
                _groupStore.allGroupByIds[clonedTask.groupId]?.name || '';
        }
        const myProfile = myProfileStore().myProfile;

        const notificationValue = {
            ...clonedTask,
            task_id: clonedTask.id,
            task_name: translate('NOTIFICATION_LABEL_CLONE_TITLE', {
                taskCode: cloneTaskCodeShort,
            }),
            task_code: clonedTask.code,
            organization_id: clonedTask.organizationId,
            group_id: clonedTask.groupId,
            group_name: groupName,
            executor_name: myProfile.lastName + ' ' + myProfile.firstName,
            icon: myProfile.avatarUrl,
            taskId: clonedTask.id,
            type: 'TASK',
            taskCode: clonedTask.code,
            priority: clonedTask.priority || 0,
            important: clonedTask.important || false,
            taskLife: clonedTask.taskLife || 0,
            isPrivate: clonedTask.isPrivate || false,
            isPlannedToTreat: clonedTask.isPlannedToTreat || false,
            isPlannedToFinish: clonedTask.isPlannedToTreat || false,
            actionType: 'Cloned',
        };

        openNotification({
            metaData: {
                hasAction: true,
                isOpenTaskDetail: true,
                taskCode: clonedTask.code,
                cloneTaskCodeShort,
            },
            notificationValue,
            notificationType: NotificationTypeEnum.task,
        });

        // Update task via realtime funciton
        // this.updateListTaskById(clonedTask?.id, TaskActionType.AddNew);
        return true;
    }

    async rename(newName): Promise<boolean> {
        try {
            const res = await TaskService.getInstance().renameTask({
                id: this.id,
                name: newName,
            });
            if (!res?.success) return Promise.resolve(false);

            this.name = newName;
            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async changeUrgency(urgency): Promise<boolean> {
        try {
            const res = await TaskService.getInstance().changeUrgency(
                this.id,
                urgency
            );
            if (!res?.success) return Promise.resolve(false);

            this.urgency = urgency;
            this.priority = convertUrgencyToPriority(urgency);
            this.important = convertUrgencyToImportant(urgency);
            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeAssignee(
        groupId,
        assigneeId,
        collaborators?
    ): Promise<boolean> {
        try {
            const res = await TaskService.getInstance().changeAssigneeTask({
                id: this.id,
                groupId: groupId,
                assigneeId: assigneeId,
                collaborators: collaborators || [],
            });

            if (!res?.success) return Promise.resolve(false);

            this.groupId = groupId;
            this.assigneeId = assigneeId;
            this.isPrivate = groupId > 0 ? false : this.isPrivate;
            this.isGroupMember = groupId > 0 ? true : this.isGroupMember;

            this.collaborators = this.collaborators?.filter(
                (user) => user?.memberUserId !== assigneeId
            );

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async doItToday(
        isDoItToday: boolean,
        planningData?: {
            isUpdatePlanningTime: boolean;
            planningStartTime: string | null;
            planningEndTime: string | null;
        },
        isFinishItToday?: boolean
    ): Promise<boolean> {
        try {
            this.taskSchedule.doItToday = isDoItToday;
            const planType = isFinishItToday ? 2 : isDoItToday ? 1 : 0;
            if (
                isDoItToday &&
                this.taskLife !== TaskLife.Todo &&
                this.taskLife !== TaskLife.InProcess &&
                this.taskLife !== TaskLife.Cancelled &&
                this.taskLife !== TaskLife.Finished
            ) {
                this.changeState(TaskLife.Todo);
            }

            let res;
            if (
                this.taskSchedule.finishItToday ||
                this.taskSchedule.scheduleOptionKey == ScheduleDayOptions.OnDay
            ) {
                this.taskSchedule.finishItToday = false;

                if (this.canTaskChangeDeadline(true)) {
                    this.taskSchedule.scheduleOptionKey =
                        ScheduleDayOptions.Nothing;
                    this.scheduleByHour = false;
                    this.scheduleTime = '';
                }

                res = await TaskService.getInstance().setPlanned({
                    ...(planningData || {}),
                    taskId: this.id,
                    planType,
                    isUpdateScheduleTime: planningData?.isUpdatePlanningTime
                        ? false
                        : this.canTaskChangeDeadline(true),
                    scheduleTime: this.scheduleTime || undefined,
                    scheduleByHour: this.scheduleByHour,
                    scheduleTimezone: '',
                });
            } else {
                res = await TaskService.getInstance().setPlanned({
                    ...(planningData || {}),
                    taskId: this.id,
                    planType,
                });
            }

            return Promise.resolve(res?.success);
        } catch (error) {
            return Promise.reject(false);
        }
    }

    async finishItToday(isFinishItToday: boolean): Promise<boolean> {
        try {
            const planType = isFinishItToday ? 2 : 1;

            this.updateDataByFinishToday(isFinishItToday);
            const res = await TaskService.getInstance().setPlanned({
                taskId: this.id,
                planType,
                isUpdateScheduleTime: this.canTaskChangeDeadline(true),
                scheduleTime: this.scheduleTime || undefined,
                scheduleByHour: this.scheduleByHour,
                scheduleTimezone: this.scheduleTimezone,
            });

            if (
                this.taskLife !== TaskLife.Todo &&
                this.taskLife !== TaskLife.InProcess &&
                this.taskLife !== TaskLife.Cancelled
            ) {
                this.changeState(TaskLife.Todo);
            }

            if (!res?.success) {
                this.updateDataByFinishToday(!isFinishItToday);

                return Promise.resolve(false);
            }

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async checkChangeStatusWhenHasWorkflow(state: TaskLife) {
        if (!this.workFlowTaskId) return true;

        if (state == TaskLife.Finished) {
            settingGlobalModal({
                type: 'confirm',
                title: '',
                content:
                    translate('WORKFLOW_LABEL_WARNING_WHEN_FINISH_TASK', {
                        workflowName: this.taskWorkflow?.name || '',
                    }) || 'Do you want to remove this note?',
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
                closeable: true,
            });

            const confirmed = await ask();
            if (!confirmed) return false;

            // if (this.taskWorkflow?.steps) {
            //     const lastStep = this.taskWorkflow?.steps?.pop();
            //     if (lastStep?.id)
            //         await WorkflowRepository.getInstance().updateStatusTaskStep(
            //             {
            //                 id: lastStep?.id,
            //                 status: EWorkflowTaskStepStatus.COMPLETED,
            //             }
            //         );
            //     return true;
            // }

            return true;
        }

        return true;
    }

    async changeState(state: TaskLife, comment?: any): Promise<any> {
        const taskLifeBk = this.taskLife;
        const finisherIdBk = this.finisherId;

        try {
            // let res: any = {};
            // if (state == TaskLife.Finished && this.workFlowTaskId)
            //     res = await TaskService.getInstance().finishTaskInWorkflow(
            //         this.id
            //     );
            // else
            //     res = await TaskService.getInstance().changeState(
            //         this.id,
            //         state
            //     );
            if (state == TaskLife.Cancelled) this.doItToday(false);

            let res: any = {};
            if (state == TaskLife.Finished && this.approvalEnabled) {
                res = await TaskService.getInstance().requestTaskApproval(
                    this.id,
                    {
                        isCreateComment: false,
                        content: '',
                        attachments: {
                            files: [],
                            notes: [],
                        },
                        mentionUserIds: [],
                        mentionGroup: false,
                    },
                    undefined
                );
                this.approvalStatus = TaskApprovalStatus.WAITING;
            } else {
                res = await TaskService.getInstance().changeState(
                    this.id,
                    state,
                    comment
                );

                if (!res?.success) {
                    return Promise.resolve(false);
                }
                this.taskLife = state;
                if (state == TaskLife.Finished) {
                    this.finisherId = getCurrentUserId();
                    this.finishedTime = res?.result?.finishedTime || Date.now();
                    this.workflowNextStepTaskId =
                        res?.result?.workflowNextStepTaskId;
                }
            }

            return res?.result;
        } catch (error) {
            this.errorCode = 6100; // todo

            this.taskLife = taskLifeBk;
            this.finisherId = finisherIdBk;
            return Promise.reject(false);
        }
    }

    async changeApprovalStatus(
        approvalStatus: TaskApprovalStatus,
        comment: any,
        workflowApprovalNextStepId: string | undefined
    ): Promise<any> {
        try {
            let res: any = {};
            if (approvalStatus == TaskApprovalStatus.WAITING) {
                res = await TaskService.getInstance().requestTaskApproval(
                    this.id,
                    comment,
                    workflowApprovalNextStepId
                );
            } else {
                res = await TaskService.getInstance().setTaskApprovalStatus(
                    this.id,
                    approvalStatus,
                    comment,
                    workflowApprovalNextStepId
                );
            }
            if (!res?.success) {
                return Promise.resolve(false);
            }
            this.approvalStatus = approvalStatus;

            if (
                approvalStatus == TaskApprovalStatus.APPROVED ||
                approvalStatus == TaskApprovalStatus.REJECTED
            ) {
                this.totalComment += 1;
            }
            if (approvalStatus == TaskApprovalStatus.APPROVED) {
                this.taskLife = TaskLife.Finished;

                this.approverId = getCurrentUserId();
                this.approverApprovalDate = new Date();
                this.workflowNextStepTaskId =
                    res?.result?.workflowNextStepTaskId;

                return Promise.resolve(this.workflowNextStepTaskId);
            }

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo

            return Promise.reject(false);
        }
    }

    async changeDescription(
        description: string,
        options?: {
            mentionGroup: boolean;
            mentionUserIds: any[];
        }
    ): Promise<boolean> {
        try {
            const res = await TaskService.getInstance().changeDescription({
                id: this.id,
                description: description,
                mentionGroup: options?.mentionGroup,
                mentionUserIds: options?.mentionUserIds,
            });

            if (!res?.success) return Promise.resolve(false);

            this.description = description;

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeDomain(domainData: any): Promise<boolean> {
        if (!domainData || !domainData?.domainId) return Promise.resolve(false);
        try {
            const res = await TaskService.getInstance().changeDomain({
                ...domainData,
                taskId: this.id,
            });

            if (!res?.success) return Promise.resolve(false);
            this.domainId = domainData.domainId;
            this.projectId = domainData.projectId;
            this.subprojectId = domainData.subprojectId;

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changePrivate(isPrivate: any): Promise<boolean> {
        try {
            const isContinue = this.confirmBeforeChangePrivate(isPrivate);

            if (!isContinue) return false;

            const res = await TaskService.getInstance().changePrivate({
                taskId: this.id,
                isPrivate: isPrivate,
            });

            if (!res?.success) return Promise.resolve(false);
            this.isPrivate = isPrivate;

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeDeadline(deadlineDate: {
        scheduleOptionKey: number;
        otherTimeKey: number;
        scheduleTime: string;
        overdue: number;
        startTime?: string;
        updateMode?: 'START_ONLY' | 'END_ONLY' | 'BOTH';
    }): Promise<any> {
        if (!deadlineDate) return Promise.resolve(false);
        if (
            this.scheduleTimeLocked &&
            deadlineDate?.scheduleOptionKey == ScheduleDayOptions.Nothing
        ) {
            settingGlobalModal({
                type: 'confirm',
                title: translate(
                    'TASK_NOTE_WHEN_USING_DEADLINE_LOCKING_FEATURE'
                ),
                content: translate(
                    'TASK_DEADLINE_LOCKING_YOU_CAN_NOT_SET_DEADLINE_TO_NOTHING'
                ),
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
                closeable: false,
            });

            ask();
            return;
        }

        try {
            switch (deadlineDate.updateMode) {
                case 'START_ONLY':
                    return this.changeStartEndTime({
                        startTime: deadlineDate?.startTime || '',
                        changeStartTimeOnly: true,
                    });
                case 'BOTH':
                case 'END_ONLY': {
                    if (deadlineDate.updateMode == 'BOTH') {
                        this.startTime = deadlineDate?.startTime;
                    }

                    const needToUpdatePlan =
                        this.taskLife !== TaskLife.Finished &&
                        (deadlineDate?.scheduleOptionKey ==
                            ScheduleDayOptions.OnDay ||
                            (deadlineDate?.scheduleOptionKey !==
                                ScheduleDayOptions.OnDay &&
                                this.taskSchedule?.finishItToday == true));

                    this.taskSchedule.scheduleOptionKey =
                        deadlineDate.scheduleOptionKey;
                    this.taskSchedule.otherTime = deadlineDate.otherTimeKey;
                    this.taskSchedule.overdue = deadlineDate.overdue;

                    const newScheduleTime = getScheduleTimeAfterChangeDate(
                        this.scheduleTime,
                        deadlineDate.scheduleTime,
                        this.scheduleByHour,
                        this.scheduleTimezone
                    );

                    this.scheduleTime = newScheduleTime;

                    switch (deadlineDate.scheduleOptionKey) {
                        case ScheduleDayOptions.OnDay:
                            if (needToUpdatePlan) {
                                this.taskSchedule.finishItToday = true;
                                this.taskSchedule.doItToday = true;
                                this.taskLife = TaskLife.Todo;
                            }

                            break;
                        case ScheduleDayOptions.Nothing:
                            if (needToUpdatePlan) {
                                this.taskSchedule.finishItToday = false;
                            }
                            this.scheduleByHour = false;
                            break;

                        default:
                            this.taskSchedule.finishItToday = false;

                            break;
                    }

                    return deadlineDate.updateMode == 'BOTH'
                        ? this.changeStartEndTime({
                              startTime: deadlineDate?.startTime || '',
                              scheduleTime: newScheduleTime || undefined,
                              scheduleByHour: !!this.scheduleByHour,
                              scheduleTimezone: this.scheduleTimezone,
                              isFinishToday: needToUpdatePlan
                                  ? this.taskSchedule.finishItToday
                                  : undefined,
                              changeStartTimeOnly: false,
                          })
                        : TaskService.getInstance().changeScheduleTime({
                              taskId: this.id,
                              scheduleTime: newScheduleTime,
                              isFinishToday: needToUpdatePlan
                                  ? this.taskSchedule.finishItToday
                                  : undefined,
                              scheduleByHour: !!this.scheduleByHour,
                              scheduleTimezone: this.scheduleTimezone,
                          });
                }

                default:
                    break;
            }
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeDeadlineTime(data: {
        scheduleTime: string;
        scheduleByHour: boolean;
        scheduleTimezone?: string;
        isFinishToday?: boolean;
    }): Promise<boolean> {
        try {
            this.scheduleTime = data?.scheduleTime
                ? dayjs.utc(data?.scheduleTime).format('YYYY-MM-DDTHH:mm:ssZ')
                : data?.scheduleTime;
            this.scheduleByHour = data.scheduleByHour;
            this.scheduleTimezone = data.scheduleTimezone;

            const res: any = await TaskService.getInstance().changeScheduleTime(
                {
                    taskId: this.id,
                    ...data,
                }
            );

            if (!res?.success) return Promise.resolve(false);

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeStartEndTime(data: {
        startTime: string;
        scheduleTime?: string;
        scheduleByHour?: boolean;
        scheduleTimezone?: string;
        isFinishToday?: boolean;
        changeStartTimeOnly?: boolean;
    }): Promise<boolean> {
        try {
            const newStartTime = data.startTime || undefined;
            this.startTime = newStartTime;
            this.scheduleTime = data.scheduleTime || '';
            this.scheduleByHour = data.scheduleByHour;
            this.scheduleTimezone = data.scheduleTimezone;

            const res: any = await TaskService.getInstance().changeStartEndTime(
                {
                    taskId: this.id,
                    ...data,
                    startTime: newStartTime,
                    changeStartTimeOnly: !!data?.changeStartTimeOnly,
                }
            );

            if (!res?.success) return Promise.resolve(false);

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async getAttachmentData() {
        if (this.attachmentCount > 0 || this.noteCount > 0) {
            const media = await TaskService.getInstance().getTaskMedia(this.id);
            this.files = media.files.map((o) => {
                return {
                    ...o,
                    contentType: o.type,
                    isMedia: o.documentType == 'MEDIA',
                };
            });
            this.notes = media.notes.map((o) => {
                return {
                    ...o,
                    isNote: true,
                    fileUrl: o.imagePath,
                    contentType: 'image/jpeg',
                    extension: '.jpeg',
                };
            });
            this.updateAttachments();
        }
    }

    async getTodoList() {
        const res = await TaskService.getInstance().getTaskCheckListByTaskId(
            this.id
        );
        this.todoList = res?.result?.taskTodos;
    }

    async getReminderByTaskId() {
        const res = await TaskService.getInstance().getReminderByTaskId(
            this.id
        );

        if (res?.result) this.taskReminderInfo = res?.result;
    }

    updateAttachments() {
        this.attachments = arrayOrderBy(
            [...this.files, ...this.notes],
            ['creationTime'],
            ['asc']
        );
    }

    async addFiles(validFiles: any[]): Promise<boolean> {
        try {
            const res: any = await TaskService.getInstance().updateFiles(
                this.id,
                validFiles
            );
            const fileList = res?.result;
            if (!fileList || fileList?.length == 0) {
                return Promise.resolve(false);
            }

            this.files = [...this.files, ...fileList];
            this.attachmentCount = this.files.length;
            this.attachments = [
                ...this.attachments,
                ...fileList.map((o) => {
                    return {
                        ...o,
                        contentType: o.type,
                        isAudio: String(o.type).includes('audio'),
                    };
                }),
            ];

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async addNote(noteData: any): Promise<boolean> {
        try {
            const res: any = await TaskService.getInstance().updateNote(
                this.id,
                noteData
            );
            const newNote = res?.result;
            if (!newNote) return Promise.resolve(false);

            this.notes = [...this.notes, newNote];
            this.noteCount = this.notes.length;
            this.attachments = [
                ...this.attachments,
                {
                    ...newNote,
                    isNote: true,
                    fileUrl: newNote.imagePath,
                    contentType: 'image/jpeg',
                },
            ];
            return Promise.resolve(true);
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async updateNote(noteData: any): Promise<boolean> {
        try {
            const res: any = await TaskService.getInstance().updateNote(
                this.id,
                noteData
            );
            const newNote = res?.result;
            const noteId = noteData?.id;
            if (!newNote || !noteId) return Promise.resolve(false);

            const index = this.notes.findIndex((o) => o.id == noteId);
            if (index < 0) return Promise.resolve(false);
            const currentNote = this.notes[index];

            TaskService.getInstance().removeAwsFile(
                bucketTictop.AttachmentPhoto,
                currentNote?.imagePath
            );

            const newNoteShow = {
                ...newNote,
                isNote: true,
                type: 'image/jpeg',
            };

            this.notes[index] = newNoteShow;

            const aIndex = this.attachments.findIndex((o) => o.id == noteId);
            if (aIndex > -1) this.attachments[aIndex] = newNoteShow;

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async removeNote(note, isNeedConfirm): Promise<boolean> {
        if (!note?.id) return Promise.resolve(false);
        if (isNeedConfirm) {
            settingGlobalModal({
                type: 'confirm',
                title: '',
                content:
                    translate('COMMON_LABEL_QUESTION_REMOTE_NOTE') ||
                    'Do you want to remove this note?',
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
                closeable: true,
            });

            const confirmed = await ask();
            if (!confirmed) return Promise.resolve(false);
        }
        try {
            const res: any = await TaskService.getInstance().removeNote(
                this.id,
                note?.id
            );

            if (!res?.success) return Promise.resolve(false);

            if (note?.imagePath) {
                TaskService.getInstance().removeAwsFile(
                    bucketTictop.AttachmentPhoto,
                    note?.imagePath
                );
            }

            this.notes = this.notes.filter((o) => o.id !== note?.id);
            this.noteCount = this.notes.length;
            this.attachments = this.attachments.filter(
                (o) => o.id !== note?.id
            );

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async removeFile(file): Promise<boolean> {
        if (!file) return Promise.resolve(false);

        settingGlobalModal({
            type: 'confirm',
            title: '',
            content:
                translate('COMMON_LABEL_QUESTION_REMOTE_FILE') ||
                'Do you want to remove this file?',
            confirmLabel: translate('COMMON_LABEL_YES_ABORT'),
            confirmable: true,
            closeable: true,
        });

        const confirmed = await ask();
        if (!confirmed) return Promise.resolve(false);
        try {
            const res: any = await TaskService.getInstance().removeFile(
                this.id,
                file
            );

            if (!res?.success) return Promise.resolve(false);

            this.files = this.files.filter((o) => o.id !== file.id);
            this.attachmentCount = this.files.length;
            this.attachments = this.attachments.filter((o) => o.id !== file.id);

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    // getConfirmRepeatTaskBeforeDelete() {
    //   if (this.isRepeat && this.id == this.taskRepeat?.currentTaskId)
    //     return `<span class='text-orange-500 text-sm'>- ${translate(
    //       'TASK_LABEL_REPEAT_CONFIRM_BEFORE_DELETE'
    //     )}</span>`;
    //   return '';
    // }

    async delete(isDeleteRepeat?, deleteSubTaskIds?): Promise<boolean> {
        try {
            await TaskService.getInstance().delete(
                this.id,
                isDeleteRepeat,
                deleteSubTaskIds
            );

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async remove(): Promise<boolean> {
        settingGlobalModal({
            type: 'confirm',
            title: '',
            isHiddenIcon: true,
            contentComponent: ConfirmRemoveTask,
            contentComponentProps: {
                currentTask: this,
            },
        });

        const confirmed = await ask();
        if (!confirmed) return false;

        return Promise.resolve(true);
    }

    updateDataByFinishToday(isFinishItToday) {
        if (isFinishItToday) {
            this.taskSchedule.finishItToday = true;
            this.taskSchedule.doItToday = true;
            this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.OnDay;
            if (this.canTaskChangeDeadline()) {
                this.scheduleTime = getScheduleTimeAfterChangeDate(
                    this.scheduleTime,
                    Date.now(),
                    this.scheduleByHour,
                    this.scheduleTimezone
                );
            }
        } else {
            this.taskSchedule.finishItToday = false;
            this.taskSchedule.planType = PlanTypeEnum.ToDo;
            this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.Nothing;

            if (this.canTaskChangeDeadline()) {
                this.scheduleByHour = false;
                this.scheduleTime = '';
            }
        }
    }

    async createTaskRepeatInfo(data: ITaskReccurring) {
        try {
            const res = await TaskService.getInstance().createTaskRepeatInfo(
                this.id,
                data,
                formatTaskCode(this.code)
            );
            const result = res.result;
            this.isRepeat = result?.isRepeat;
            this.taskRepeat = result?.taskRepeat;
        } catch (error) {
            console.log('🚀 error', error);
        }
    }

    async updateTaskRepeatInfo(data: ITaskReccurring) {
        try {
            const res = await TaskService.getInstance().updateTaskRepeatInfo(
                this.id,
                data,
                formatTaskCode(this.code),
                !this.isRepeat
            );

            const result = res.result;
            this.isRepeat = result?.isRepeat;
            this.taskRepeat = result?.taskRepeat;
        } catch (error) {
            console.log('🚀 error', error);
        }
    }

    skipRepeatToCurrentTask() {
        return TaskService.getInstance().skipRepeatToCurrentTask(this.id);
    }

    async addCollaboratorsInTask(data: any[]) {
        const payload: IUpdateCollaboratorPayload = {
            taskId: this.id,
            collaboratorIds: data?.map((user) => user?.id),
        };
        await TaskService.getInstance().addCollaboratorsInTask(payload);
        this.collaborators = [
            ...this.collaborators,
            ...data?.map((o) => {
                return { ...o, memberUserId: o?.id || o?.userId };
            }),
        ];
    }

    async deleteCollaboratorsInTask(collaboratorIds: number[]) {
        const payload: IUpdateCollaboratorPayload = {
            taskId: this.id,
            collaboratorIds,
        };
        await TaskService.getInstance().deleteCollaboratorsInTask(payload);
        this.collaborators = this.collaborators?.filter(
            (user) => !collaboratorIds?.some((id) => id == user?.memberUserId)
        );
    }

    changePin(pin) {
        this.pinnedColor = pin?.code;
        const payload = {
            taskId: this.id,
            color: pin?.code,
        };
        return TaskService.getInstance().changePin(payload);
    }

    unpin() {
        this.pinnedColor = '';
        return TaskService.getInstance().unpin(this.id);
    }

    async getTaskWorkflow() {
        const res = await TaskService.getInstance().getTaskWorkFlow(this.id);
        this.taskWorkflow = new TaskWorkflowEntity(res?.result);
    }

    updateTaskFromWorkflowCurrentStep(currentStep: ITaskWorkflowStep) {
        if (currentStep?.groupId) {
            this.groupId = currentStep?.groupId;
            const currentGroup = Object.prototype.hasOwnProperty.call(
                groupStore().allGroupByIds,
                currentStep?.groupId
            )
                ? groupStore().allGroupByIds[currentStep?.groupId]
                : {};

            this.isGroupMember =
                currentGroup &&
                currentGroup?.members?.some(
                    (user) => user?.id == getCurrentUserId()
                );
        }

        if (currentStep?.assigneeId) this.assigneeId = currentStep?.assigneeId;

        if (currentStep?.scheduleTime) {
            this.scheduleTime = currentStep.scheduleTime;
        }
        this.important = !!currentStep?.important;
        this.priority = currentStep?.priority || 0;
        this.urgency = getUrgencyValue(this.priority, this.important);

        if (
            currentStep?.scheduleDayOption ||
            currentStep?.scheduleDayOption == 0
        ) {
            this.taskSchedule.scheduleOptionKey =
                currentStep?.scheduleDayOption;

            if (
                this.taskSchedule.scheduleOptionKey == ScheduleDayOptions.OnDay
            ) {
                this.finishItToday(true);
            }
        }
    }

    async synchronizeTaskToWorkflowCurrentStep() {
        if (!this.taskWorkflow?.id) return;

        try {
            const currentStepIndex = this.taskWorkflow.steps?.findIndex(
                (step) => step?.taskId == this.id
            );
            if (currentStepIndex == -1) return;

            // this.taskWorkflow.steps[currentStepIndex].domainId = this.domainId;
            // this.taskWorkflow.steps[currentStepIndex] = {
            //     ...this.taskWorkflow.steps[currentStepIndex],
            //     groupId: this.groupId || undefined,
            //     assigneeId: this.assigneeId || undefined,
            //     domainId: this.domainId || undefined,
            //     priority:
            //         this.urgency && this.urgency > 0
            //             ? convertUrgencyToPriority(this.urgency)
            //             : this.priority || 1,
            //     important:
            //         this.urgency && this.urgency > 0
            //             ? convertUrgencyToImportant(this.urgency)
            //             : !!this.important,
            //     scheduleDayOption: this.taskSchedule?.scheduleOptionKey,
            //     scheduleTime: this.taskSchedule?.scheduleDateValue || undefined,
            //     taskDescription: this.description,
            //     // todoLists: this.todoList || [],
            // };

            const currentTaskWorkflowStep = updateStepFromTask(
                {
                    ...this.taskWorkflow.steps[currentStepIndex],
                    taskId: this.id,
                    taskWorkflowId: this.workFlowTaskId,
                },
                {
                    ...this.taskWorkflow.steps[currentStepIndex],
                    taskId: this.id,
                    taskWorkflowId: this.workFlowTaskId,
                },
                new TaskTemplateEntity(this)
            );
            this.taskWorkflow.steps[currentStepIndex] = currentTaskWorkflowStep;

            currentTaskWorkflowStep.updateTaskWorkflowStep();

            await this.getTaskWorkflow();
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    }

    async checkBeforeMaskAsApproval() {
        if (this.isPrivate) {
            settingGlobalModal({
                type: 'confirm',
                title: translate(
                    'TASK_LABEL_CANNOT_SET_APPROVAL_FOR_PRIVATE_TASK_TITLE'
                ),
                content:
                    translate(
                        'TASK_LABEL_CANNOT_SET_APPROVAL_FOR_PRIVATE_TASK_CONTENT'
                    ) ||
                    'It is not possible to turned on approval for a private task',
                closeable: false,
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Got it',
            });

            await ask();
            return false;
        }
        return true;
    }

    async setTaskApproval(data: {
        approvalEnabled: boolean;
        approverIds: number[];
    }) {
        try {
            // const isValid = await this.checkBeforeMaskAsApproval();
            // if (!isValid) return;

            const res = await TaskService.getInstance().setTaskApproval(
                this.id,
                data
            );

            this.approvalEnabled = data?.approvalEnabled;
            this.approvalStatus = TaskApprovalStatus.NOT_REQUESTED;
            this.approvers = res?.result?.approvers;
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    }

    async cancelRequestTaskApproval() {
        try {
            await TaskService.getInstance().cancelRequestTaskApproval(this.id);

            this.approvalStatus = TaskApprovalStatus.NOT_REQUESTED;
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    }

    async checkNewResponsibleWithApprover(group, user) {
        console.log('🚀 Tictop ~ group, user:', group, user);
        if (!this.approvalEnabled) return true;
        const approver: any =
            this.approvers && this.approvers?.length > 0
                ? this.approvers[0]
                : {};
        if (!approver) return true;

        if (group?.id) {
            const approverIsAMemberOfGroup = group?.groupUsers?.some(
                (user) => user?.userId == approver?.userId
            );
            if (!approverIsAMemberOfGroup) {
                settingGlobalModal({
                    type: 'confirm',
                    title: translate(
                        'TASK_LABEL_CONFLICT_WITH_APPROVER_WHEN_CHANGE_GROUP_TITLE'
                    ),
                    content: translate(
                        'TASK_LABEL_CONFLICT_WITH_APPROVER_WHEN_CHANGE_GROUP_CONTENT',
                        {
                            groupName: group?.name || '',
                        }
                    ),
                    confirmable: true,
                    confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
                    closeable: false,
                });

                ask();
                return false;
            }
        } else if (user?.id) {
            const newUserDepartmentIds = user?.departmentIds;
            const approverDepartmentIds =
                userStore().allUserByIds[approver?.userId].departmentIds;

            const hasSameDepartment = newUserDepartmentIds?.some((dpId) =>
                approverDepartmentIds?.some((dpId2) => dpId2 == dpId)
            );

            if (!hasSameDepartment) {
                settingGlobalModal({
                    type: 'confirm',
                    title: translate(
                        'TASK_LABEL_CONFLICT_WITH_APPROVER_WHEN_CHANGE_USER_TITLE'
                    ),
                    content: translate(
                        'TASK_LABEL_CONFLICT_WITH_APPROVER_WHEN_CHANGE_USER_CONTENT'
                    ),
                    confirmable: true,
                    confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
                    closeable: false,
                });

                ask();
                return false;
            }
        }

        return true;
    }

    async checkValidNewApprover(approver) {
        if (!approver) return true;

        if (this.groupId) {
            const allGroupByIds = {
                ...groupStore().allGroupByIds,
                ...groupStore().myGroupByIds,
            };

            if (
                !Object.prototype.hasOwnProperty.call(
                    allGroupByIds,
                    this.groupId
                )
            ) {
                return true;
            }

            const currentGroup = allGroupByIds[this.groupId];
            const approverIsAMemberOfGroup = currentGroup?.groupUsers?.some(
                (user) => user?.userId == approver?.userId
            );
            if (!approverIsAMemberOfGroup) {
                settingGlobalModal({
                    type: 'confirm',
                    title: translate(
                        'TASK_LABEL_CONFLICT_WITH_APPROVER_WHEN_CHANGE_GROUP_TITLE'
                    ),
                    content: translate(
                        'TASK_LABEL_CONFLICT_WITH_APPROVER_WHEN_CHANGE_GROUP_CONTENT',
                        {
                            groupName: currentGroup?.name || '',
                        }
                    ),
                    confirmable: true,
                    confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
                    closeable: false,
                });

                ask();
                return false;
            }
        } else if (this.assigneeId) {
            if (myProfileStore().isAdmin || myProfileStore().isOwner) {
                return true;
            }

            const allUserByIds = userStore().allUserByIds;
            const assigneeDepartmentIds =
                allUserByIds[this.assigneeId].departmentIds;
            const approverDepartmentIds =
                allUserByIds[approver?.userId].departmentIds;

            const hasSameDepartment = assigneeDepartmentIds?.some((dpId) =>
                approverDepartmentIds?.some((dpId2) => dpId2 == dpId)
            );

            if (!hasSameDepartment) {
                settingGlobalModal({
                    type: 'confirm',
                    title: translate(
                        'TASK_LABEL_CONFLICT_ASSIGNEE_WHEN_CHANGE_APPROVER_TITLE'
                    ),
                    content: translate(
                        'TASK_LABEL_CONFLICT_ASSIGNEE_WHEN_CHANGE_APPROVER_CONTENT'
                    ),
                    confirmable: true,
                    confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
                    closeable: false,
                });

                ask();
                return false;
            }
        }

        return true;
    }

    async checkValidChangeResponsibleInPrivateTask(groupId, userId) {
        if (
            !this.isPrivate ||
            groupId ||
            !userId ||
            userId == myProfileStore().myProfile?.id ||
            this.creatorId == myProfileStore().myProfile?.id
        ) {
            return true;
        }

        settingGlobalModal({
            type: 'confirm',
            title: translate(
                'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_ASSIGNEE'
            ),
            content: translate('TASK_LABEL_CHANGE_ASSIGNEE_IN_PRIVATE_TASK'),
            footerButtons: [
                {
                    type: 'TEXT',
                    labelCode: 'COMMON_LABEL_CANCEL',
                    color: 'gray',
                    onClick: () => {
                        tell(false);
                    },
                },
                {
                    type: 'FILL',
                    labelCode:
                        'TASK_LABEL_CHANGE_ASSIGNEE_IN_PRIVATE_TASK_CONTINUE_AND_KEEP_PRIVATE',
                    color: 'current',
                    onClick: () => {
                        tell(true);
                    },
                },
                {
                    type: 'FILL',
                    labelCode:
                        'TASK_LABEL_CHANGE_ASSIGNEE_IN_PRIVATE_TASK_CONTINUE_AND_MAKE_PUBLIC',
                    color: 'current',
                    onClick: () => {
                        this.changePrivate(false);
                        tell(true);
                    },
                },
            ],
        });

        const result = await ask();

        return result;
    }

    async getSubTasks() {
        const res = await TaskService.getInstance().getSubTasks(this.id);
        this.subTasks = res?.result;
    }

    async removeAsSubTask(parentName) {
        settingGlobalModal({
            type: 'confirm',
            title: translate('TASK_NOTE_WHEN_USING_DEADLINE_LOCKING_FEATURE'),
            content: translate(
                'TASK_LABEL_DO_YOU_WANT_TO_DETACH_TASK_A_FROM_TASK_B',
                {
                    subTaskName: this.name,
                    parentTaskName:
                        parentName ||
                        this.parentName ||
                        translate('TASK_LABEL_PARENT_TASK'),
                }
            ),
            confirmable: true,
            confirmLabel:
                translate('GROUP_COMFIRM_BREAK_LINK_DEPARTMENT_BUTTON') ||
                'Confirm',
            closeable: true,
        });

        const result = await ask();

        if (!result) return;

        await TaskService.getInstance().removeAsSubTask(
            this.id,
            formatTaskCode(this.parentCode)
        );
        this.parentId = undefined;
        this.parentName = undefined;
    }

    async setParentTask(parentTask) {
        await TaskService.getInstance().setParentTask(this.id, parentTask?.id);
        this.parentId = parentTask?.id;
        this.parentName = parentTask?.name;
    }

    canTaskChangeDeadline(preventOpenWarningModal?) {
        if (!this.scheduleTimeLocked) return true;

        return this.canUserLockDeadline(preventOpenWarningModal);
    }

    canUserLockDeadline(preventOpenWarningModal?) {
        const allSystemConfigs = permissionStore().allSystemConfigs;

        if (
            allSystemConfigs[
                systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED
            ] !== '1'
        ) {
            settingGlobalModal({
                type: 'confirm',
                title: translate(
                    'TASK_NOTE_WHEN_USING_DEADLINE_LOCKING_FEATURE'
                ),
                content: translate(
                    'TASK_NOTE_WHEN_USING_DEADLINE_LOCKING_FEATURE_HAVE_NOT_ENABLE'
                ),
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
                closeable: false,
            });
            ask();

            return false;
        }

        const allAllowedPositions =
            allSystemConfigs[
                systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_BY
            ]?.split(';');
        if (
            allAllowedPositions.some((key) => {
                switch (key) {
                    case 'OWNER_ADMIN':
                        if (
                            myProfileStore().isAdmin ||
                            myProfileStore().isOwner
                        ) {
                            return true;
                        }
                        break;
                    case 'CREATOR':
                        if (myProfileStore().myProfile?.id == this.creatorId) {
                            return true;
                        }
                        break;
                    case 'ASSIGNEE':
                        if (myProfileStore().myProfile?.id == this.assigneeId) {
                            return true;
                        }
                        break;

                    default:
                        break;
                }

                return false;
            })
        ) {
            return true;
        } else {
            if (!preventOpenWarningModal) {
                settingGlobalModal({
                    type: 'confirm',
                    title: translate(
                        'TASK_NOTE_WHEN_USING_DEADLINE_LOCKING_FEATURE'
                    ),
                    content: translate(
                        this.scheduleTimeLocked
                            ? 'TASK_DEADLINE_LOCKING_FEATURE_YOU_HAVE_NOT_PERMISSION'
                            : 'TASK_DEADLINE_LOCKING_FEATURE_YOU_HAVE_NOT_PERMISSION_TO_LOCK',
                        {
                            allowedPeople: allAllowedPositions?.reduce(
                                (currentResult, currentKey) => {
                                    const textCode =
                                        currentKey == 'OWNER_ADMIN'
                                            ? 'COMMON_LABEL_ADMIN_OR_OWNER'
                                            : currentKey == 'CREATOR'
                                            ? 'TASK_TABLE_LABEL_CREATOR'
                                            : currentKey == 'ASSIGNEE'
                                            ? 'COMMON_LABEL_ASSIGNEE'
                                            : '';
                                    return (
                                        currentResult +
                                        `<li>- ${translate(textCode)}</li>`
                                    );
                                },
                                ''
                            ),
                            taskCode: formatTaskCode(this.code),
                        }
                    ),
                    confirmable: true,
                    confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
                    closeable: false,
                });

                ask();
            }
            return false;
        }
    }

    async toggleLockDeadline(value?) {
        if (!this.canUserLockDeadline()) return;

        if (!this.scheduleTime) {
            settingGlobalModal({
                type: 'confirm',
                title: translate(
                    'TASK_NOTE_WHEN_USING_DEADLINE_LOCKING_FEATURE'
                ),
                content: translate(
                    'TASK_DEADLINE_LOCKING_YOU_NEED_TO_SET_DEADLINE_FIRST'
                ),
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
                closeable: false,
            });
            ask();
            return;
        }

        // update task
        try {
            const res =
                await TaskService.getInstance().changeTaskScheduleTimeLocked(
                    this.id,
                    typeof value == 'boolean' ? value : !this.scheduleTimeLocked
                );
            if (!res?.success) return Promise.resolve(false);

            this.scheduleTimeLocked = !this.scheduleTimeLocked;
            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    async updateEvidenceSettings(data: {
        finishProofEnabled: boolean;
        finishProofTypes: string;
        finishProofSkipAllowed: boolean;
    }) {
        // update task
        try {
            const res = await TaskService.getInstance().updateEvidenceSettings(
                this.id,
                data
            );
            if (!res?.success) return Promise.resolve(false);

            this.finishProofEnabled = data.finishProofEnabled;
            this.finishProofTypes = data.finishProofTypes;
            this.finishProofSkipAllowed = data.finishProofSkipAllowed;
            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    async finishTaskInWorkflow(nextStepId: string | null) {
        // update task
        try {
            const res = await TaskService.getInstance().finishTaskInWorkflow(
                this.id,
                nextStepId
            );
            if (!res?.success) return Promise.resolve(false);

            this.taskLife = TaskLife.Finished;
            this.finisherId = myProfileStore().myProfile?.id;
            this.workflowNextStepTaskId = res?.result?.workflowNextStepTaskId;
            return Promise.resolve(res?.result?.workflowNextStepTaskId);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    async updateFileStatus(fileId: string, status: ETaskFileStatus) {
        // update task
        try {
            const res = await TaskService.getInstance().updateFileStatus({
                taskId: this.id,
                fileId,
                status,
            });
            if (!res?.success) return Promise.resolve(false);

            const index = this.files.findIndex((f) => f?.id == fileId);
            if (index > -1) this.files[index].status = status;

            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    async updateNoteStatus(fileId: string, status: ETaskFileStatus) {
        // update task
        try {
            const res = await TaskService.getInstance().updateNoteStatus({
                taskId: this.id,
                fileId,
                status,
            });
            if (!res?.success) return Promise.resolve(false);

            const index = this.notes.findIndex((f) => f?.id == fileId);
            if (index > -1) this.notes[index].status = status;

            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    async updateTaskPlaningTime(data: {
        planningStartTime: string;
        planningEndTime: string;
    }) {
        // update task
        try {
            const res = await TaskService.getInstance().updateTaskPlaningTime({
                ...data,
                taskId: this.id,
            });
            if (!res?.success) return Promise.resolve(false);
            this.planningStartTime = data?.planningStartTime;
            this.planningEndTime = data?.planningEndTime;

            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    updateProperties(newData) {
        Object.assign(
            this,
            prepareInitData({
                ...this,
                ...newData,
            })
        );
        super.updateProperties({
            ...this,
            ...newData,
        });
    }
}
// end TaskDetailClass

export class TaskListItem {
    name: string;
    code: string;
    urgency: number | null;
    planType: number;
    taskLife: number;
    taskSchedule: TaskSchedule;
    domainId: number | null;

    constructor(data: any) {
        this.name = data?.name || '';
        this.code = data?.code || '';
        this.urgency = getUrgencyValue(data?.priority, data?.important);
        this.planType = data?.planType || 0;
        this.taskLife = data?.taskLife || 0;
        this.domainId = data?.domainId || null;
        this.taskSchedule = data?.taskSchedule || new TaskSchedule({});
    }
}

export class TaskSchedule {
    doItToday: boolean;
    finishItToday: boolean;
    overdue: number | null;
    code: string;
    scheduleOptionKey: ScheduleDayOptions;
    scheduleDateValue: string;
    currentUserDate: Date | null;
    otherTime: number | null;
    otherTimes: [];
    planType: PlanTypeEnum;

    constructor(data: any) {
        this.doItToday = data?.doItToday || false;
        this.finishItToday = data?.finishItToday || false;
        this.overdue = data?.overdue;
        this.code = data?.code;
        this.scheduleOptionKey =
            data?.scheduleOptionKey || ScheduleDayOptions.Nothing;
        this.scheduleDateValue =
            data?.scheduleDateValue || ScheduleDayOptions.Nothing;
        this.currentUserDate = data?.currentUserDate;
        this.otherTime = data?.otherTime;
        this.otherTimes = data?.otherTimes || [];
        this.planType =
            data?.planType || data?.finishItToday
                ? PlanTypeEnum.ToFinish
                : data?.doItToday
                ? PlanTypeEnum.ToDo
                : PlanTypeEnum.Nothing;
    }
}

export class TaskCreateFormClass extends TaskCreatePayload {
    domainName: string;
    projectName: string;
    subprojectName: string;

    constructor(data: any) {
        super(data);
        this.domainName = data?.domainName || '';
        this.projectName = data?.projectName || '';
        this.subprojectName = data?.subprojectName || '';
    }
}

export const TaskStatus = {
    Finished: {
        key: 3,
        text: 'Finished',
        textCode: 'COMMON_LABEL_STATUS_FINISHED',
    },
};

export default class TaskNotificationPaserOriginal {
    id: number;
    groupId: number | null;
    name: string;
    description: string;
    taskLife: number;
    scheduleTime: string;
    assigneeId: number | null;
    creatorId: number | null;
    lastModifierId: number | null;
    domainId: number | null;
    projectId: number | null;
    subprojectId: number | null;
    estimationTime: number;
    finisherId: number | null;
    taskSchedule: TaskSchedule;
    planType: number;
    priority: number | null;
    taskType: number;
    important: boolean | null;
    isPrivate: boolean | null;
    files: any[];
    notes: any[];
    taggedMembers: any[];

    constructor(data: any) {
        this.id = data?.id;
        this.groupId = data?.groupId || null;
        this.name = data?.name || '';
        this.description = data?.description || '';
        this.scheduleTime = data?.scheduleTime || '';
        this.assigneeId = data?.assigneeId || null;
        this.creatorId = data?.creatorId || null;
        this.lastModifierId = data?.lastModifierId || null;
        this.domainId = data?.domainId || null;
        this.projectId = data?.projectId || null;
        this.subprojectId = data?.subprojectId || null;
        this.estimationTime = data?.estimationTime || 0;
        this.finisherId = data?.finisherId || null;
        this.taskSchedule = data?.taskSchedule
            ? {
                  ...data?.taskSchedule,
                  scheduleDateValue: this.scheduleTime,
              }
            : getDefaultTaskSchedule();
        this.taskLife = data?.taskLife
            ? data?.taskLife
            : this.taskSchedule.doItToday
            ? TaskLife.Todo
            : TaskLife.Created;
        this.planType = data?.planType || 0;
        this.priority = convertUrgencyToPriority(data?.urgency);
        this.important = convertUrgencyToImportant(data?.urgency);
        this.taskType = data?.taskType || 1;
        this.isPrivate = data?.isPrivate || false;
        this.files = data?.files || [];
        this.notes = data?.notes || [];
        this.taggedMembers = data?.taggedMembers || [];
    }
}

export class TaskWorkFlowModel {
    id: number;
    workFlowId: string;
    taskId: number;
    creatorId: number;
    isActive: boolean;
    createdTime: Date;
    finishedTime: Date;
    status: string;
    currentStepId: string;
    workFlow: IWorkflowEntity;

    constructor(data: any) {
        this.id = data?.id;
        this.workFlowId = data?.workFlowId;
        this.taskId = data?.taskId;
        this.creatorId = data?.creatorId;
        this.isActive = data?.isActive;
        this.createdTime = data?.createdTime;
        this.finishedTime = data?.finishedTime;
        this.status = data?.status;
        this.currentStepId = data?.currentStepId;
        this.workFlow = data?.workFlow;
    }
}

export class TaskWorkFlowInfoModel {
    id: string;
    originalId: string;
    organizationId: number;
    name: string;
    description: string;
    isActive: boolean;
    scope: string;
    groupId: number | undefined;
    groupName: string | undefined;
    groupAvatar: string | undefined;
    groupAvatarUrl: string | undefined;
    domainId: number | undefined;
    domainName: string | undefined;
    departmentId: number | undefined;
    departmentName: string | undefined;
    departmentAvatar: string | undefined;
    departmentAvatarUrl: string | undefined;
    totalStep: number;
    creatorId: number;
    createdTime: number;
    updaterId: number;
    updatedTime: number;
    applyChangeFromOrigin: number;

    constructor(data: any) {
        this.id = data?.id;
        this.originalId = data?.originalId;
        this.organizationId = data?.organizationId;
        this.name = data?.name;
        this.description = data?.description;
        this.isActive = data?.isActive;
        this.scope = data?.scope;
        this.groupId = data?.groupId;
        this.groupName = data?.groupName;
        this.groupAvatar = data?.groupAvatar;
        this.groupAvatarUrl = data?.groupAvatarUrl;
        this.domainId = data?.domainId;
        this.domainName = data?.domainName;
        this.departmentId = data?.departmentId;
        this.departmentName = data?.departmentName;
        this.departmentAvatar = data?.departmentAvatar;
        this.departmentAvatarUrl = data?.departmentAvatarUrl;
        this.totalStep = data?.totalStep;
        this.creatorId = data?.creatorId;
        this.createdTime = data?.createdTime;
        this.updaterId = data?.updaterId;
        this.updatedTime = data?.updatedTime;
        this.applyChangeFromOrigin = data?.applyChangeFromOrigin;
    }
}

export class TaskWorkFlowStepModel {
    workFlowStepId: string;
    stepOrder: number;
    mustAction: number;
    isActive: boolean;
    isCompleted: boolean;
    actionerId: number | undefined;
    actionTime: Date | undefined;
    name: string;
    stepDescription: string;
    type: string;
    assignees: TaskWorkFlowAssigneeModel[];
    isAddedItem: boolean;

    constructor(data: any) {
        this.workFlowStepId = data?.workFlowStepId;
        this.stepOrder = data?.stepOrder;
        this.mustAction = data?.mustAction;
        this.isActive = data?.isActive;
        this.isCompleted = data?.isCompleted;
        this.actionerId = data?.actionerId;
        this.actionTime = data?.actionTime;
        this.name = data?.name;
        this.stepDescription = data?.stepDescription;
        this.type = data?.type;
        this.assignees = data?.assignees || [];
        this.isAddedItem = data?.isAddedItem || false;
    }
}

export class TaskWorkFlowAssigneeModel {
    assigneeId: number;
    order: number;
    role: string;
    status: string;
    updatedTime: Date;

    constructor(data: any) {
        this.assigneeId = data?.assigneeId;
        this.order = data?.order;
        this.role = data?.role;
        this.status = data?.status;
        this.updatedTime = data?.updatedTime;
    }
}

export const checkValidSetTaskAsPrivate = (currentTask, currentUserId) => {
    if (currentTask?.groupId) {
        // show message
        settingGlobalModal({
            type: 'notification',
            title: translate('UNABLE_TO_SET_TASK_AS_PRIVATE'),
            content:
                translate('TASK_CANNOT_SET_PRIVATE_IN_GROUP') ||
                'You are on another call',
            confirmLabel: translate('COMMON_LABEL_CLOSE'),
            confirmable: true,
        });
        ask();
        return false;
    }
    if (currentTask?.workFlowTaskId) {
        // show message
        settingGlobalModal({
            type: 'notification',
            title: translate('UNABLE_TO_SET_TASK_AS_PRIVATE'),
            content:
                translate('TASK_CANNOT_SET_PRIVATE_IN_WORKFLOW') ||
                'You are on another call',
            confirmLabel: translate('COMMON_LABEL_CLOSE'),
            confirmable: true,
        });
        ask();
        return false;
    }
    if (
        currentTask?.assigneeId !== currentUserId &&
        currentTask?.creatorId !== currentUserId
    ) {
        // show message
        settingGlobalModal({
            type: 'notification',
            title: translate('UNABLE_TO_SET_TASK_AS_PRIVATE'),
            content:
                translate(
                    'YOU_CANNOT_SET_A_TASK_AS_PRIVATE_IF_YOU_ARE_NOT_THE_CREATOR_OR'
                ) || 'You are on another call',
            confirmLabel: translate('COMMON_LABEL_CLOSE'),
            confirmable: true,
        });
        ask();
        return false;
    }
    if (currentTask?.collaborators?.length > 0) {
        // show message
        settingGlobalModal({
            type: 'notification',
            title: translate('UNABLE_TO_SET_TASK_AS_PRIVATE'),
            content:
                translate(
                    'YOU_CANNOT_SET_A_TASK_AS_PRIVATE_IF_IT_INVOLVES_MULTIPLE_PEOPLE'
                ) || 'You are on another call',
            confirmLabel: translate('COMMON_LABEL_CLOSE'),
            confirmable: true,
        });
        ask();
        return false;
    }

    return true;
};
