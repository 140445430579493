<script setup lang="ts">
import { computed, ref } from 'vue';
import { chain, sumBy } from 'lodash';
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import UserById from '@/ui/components/user/UserById.vue';

const props = defineProps({
  reactions: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits(['onCancel', 'onReactionRemove']);

const currentId = getCurrentUserId();

const onReactionRemove = (reaction) => {
  activeGroup.value.reactions = activeGroup.value.reactions?.filter(
    (r: any) => r.id !== reaction.id
  );

  emits('onReactionRemove', reaction);
};

const reactionsGroup = computed(() => {
  let groups = chain(props?.reactions)
    .groupBy('content')
    .map((value, key) => ({
      content: key,
      reactions: value,
      totalReaction: sumBy(value, function (o: any) {
        return o.count;
      }),
    }))
    .value();

  return [
    {
      content: 'ALL',
      reactions: props?.reactions,
      totalReaction: props?.reactions
        ?.map((el: any) => el?.count)
        .reduce((a, b) => a + b, 0),
    },
  ].concat(groups);
});
const activeGroup = ref(reactionsGroup.value[0]);
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3 do-not-close-dropdown"
    style-body="p-1"
    :is-hidden-footer="true"
    @cancel="$emit('onCancel')"
  >
    <template #header>
      <div>{{ $t('NOTIFICATION_REACTIONS') || 'Message reactions' }}</div>
    </template>

    <template #body>
      <!--TABS-->
      <div
        class="
          flex flex-nowrap
          overflow-x-auto
          small-scrollbar
          px-3
          border-b
          whitespace-nowrap
          do-not-close-dropdown
        "
      >
        <button
          v-for="group in reactionsGroup"
          :key="group"
          class="
            px-2
            py-2
            hover:bg-gray-100
            flex-center
            space-x-2
            do-not-close-dropdown
          "
          :class="
            activeGroup?.content === group?.content
              ? 'text-current-500 font-semibold border-b-2 border-current-500'
              : 'text-gray-600 opacity-80'
          "
          @click="activeGroup = group"
        >
          <div v-if="group?.content === 'ALL'" class="do-not-close-dropdown">
            {{ $t('CHAT_REACTIONS_ALL') || 'All' }}
          </div>
          <div v-else class="w-5 h-5 flex-center">
            <span
              v-twemoji="{ size: 'w-5 h-5 do-not-close-dropdown' }"
              class="text-lg flex-center"
              >{{ group?.content }}</span
            >
          </div>
          <div>{{ group?.totalReaction }}</div>
        </button>
      </div>

      <!--ACTIVE TAB-->
      <div class="h-96 w-full overflow-auto small-scrollbar p-2">
        <div
          v-for="reaction of activeGroup?.reactions"
          :key="reaction"
          class="
            px-3
            py-3
            flex
            items-center
            justify-between
            space-x-2
            do-not-close-dropdown
          "
          :class="
            reaction?.createdBy === currentId
              ? 'cursor-pointer hover:bg-gray-100 rounded'
              : 'pointer-events-none'
          "
          @click="onReactionRemove(reaction)"
        >
          <UserById
            :user-id="reaction?.createdBy"
            avatar-class="w-8 h-8"
            class="font-medium"
          >
            <template v-if="reaction?.createdBy === currentId" #subName>
              <div class="text-sm text-gray-500 leading-4 text-xs">
                {{ $t('CHAT_REACTION_CLICK_TO_REMOVE') || 'Click to remove' }}
              </div>
            </template>
          </UserById>
          <div class="flex flex-center space-x-2">
            <div class="font-medium text-gray-700">
              {{ reaction?.count }}
            </div>
            <div v-twemoji="{ size: 'w-6 h-6' }" class="text-xl flex-center">
              {{ reaction?.content }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </SynModal>
</template>
