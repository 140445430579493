<template>
  <svg
    id="Group_466444"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 466444"
    viewBox="0 0 61.806 61.838"
  >
    <g id="Coding_Badge" data-name="Coding Badge" transform="translate(0 0)">
      <path
        id="Path_1661"
        data-name="Path 1661"
        d="M43.143,31H19.966a.966.966,0,0,0-.966.966V61.9a.966.966,0,0,0,1.2.966l11.357-2.9c12.139,3.032,11.415,2.9,11.589,2.9a.966.966,0,0,0,.966-.966V31.966A.966.966,0,0,0,43.143,31Z"
        transform="translate(-0.651 -1.059)"
        fill="#50d9d7"
      />
      <path
        id="Path_1662"
        data-name="Path 1662"
        d="M40.383,31V62.173L31.691,60,23,62.173V31Z"
        transform="translate(-0.789 -1.059)"
        fill="#00bebd"
      />
      <path
        id="Path_1663"
        data-name="Path 1663"
        d="M54.2,31.009a14.148,14.148,0,0,0-2.627,6.5,9.657,9.657,0,0,1-8.064,8.064,14.148,14.148,0,0,0-6.5,2.627,9.657,9.657,0,0,1-11.8,0,14.148,14.148,0,0,0-6.5-2.627,9.657,9.657,0,0,1-8.064-8.064,14.148,14.148,0,0,0-2.627-6.5,9.657,9.657,0,0,1,0-11.8,14.051,14.051,0,0,0,2.636-6.5A9.657,9.657,0,0,1,18.7,4.645a14.08,14.08,0,0,0,6.519-2.636,9.657,9.657,0,0,1,11.8,0,14.08,14.08,0,0,0,6.519,2.636,9.657,9.657,0,0,1,8.044,8.064,14.051,14.051,0,0,0,2.636,6.5,9.657,9.657,0,0,1-.019,11.8Z"
        transform="translate(-0.206 0.004)"
        fill="#e8e4e4"
      />
      <ellipse
        id="Ellipse_298"
        data-name="Ellipse 298"
        cx="15.451"
        cy="15.451"
        rx="15.451"
        ry="15.451"
        transform="translate(15.451 9.661)"
        fill="#f3f3f3"
      />
      <g id="Group_4646" data-name="Group 4646" transform="translate(0 0.015)">
        <path
          id="Path_1664"
          data-name="Path 1664"
          d="M26.074,29.938a.966.966,0,0,1-.686-.28l-3.863-3.863a.966.966,0,0,1,0-1.371l3.863-3.863a.97.97,0,0,1,1.371,1.371l-3.187,3.177,3.187,3.177a.966.966,0,0,1-.686,1.651Zm9.657,0a.966.966,0,0,1-.686-1.651l3.187-3.177-3.187-3.177a.97.97,0,0,1,1.371-1.371l3.863,3.863a.966.966,0,0,1,0,1.371l-3.863,3.863A.966.966,0,0,1,35.731,29.938Zm-5.794,1.931a.966.966,0,0,1-.966-1.12L30.9,19.16a.978.978,0,1,1,1.931.309L30.9,31.058A.966.966,0,0,1,29.937,31.869ZM4.829,13.52c-1.516,0-.782-2.849-4.491-6.026a.966.966,0,0,1,0-1.468A12.69,12.69,0,0,0,3.94.6.966.966,0,0,1,5.717.6a12.342,12.342,0,0,0,3.6,5.427.966.966,0,0,1,0,1.468,12.689,12.689,0,0,0-3.6,5.427A.966.966,0,0,1,4.829,13.52ZM56.977,51.183c-1.516,0-.782-2.9-4.491-6.026a.966.966,0,0,1,0-1.468,12.69,12.69,0,0,0,3.6-5.427.966.966,0,0,1,1.777,0,12.342,12.342,0,0,0,3.6,5.427.966.966,0,0,1,0,1.468,12.69,12.69,0,0,0-3.6,5.427.966.966,0,0,1-.888.6Z"
          transform="translate(0 -0.012)"
          fill="#8f8686"
        />
      </g>
    </g>
  </svg>
</template>
