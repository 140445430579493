<script setup lang="ts">
import { computed, ref, onUnmounted } from 'vue';
import dashboardStore from '@/store/dashboard';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import ActionById from './ActionById.vue';
import UserById from '@/ui/components/user/UserById.vue';
// import { useRoute, useRouter } from 'vue-router';
// import chatStore from '@/store/chat';
// import settingStore from '@/store/setting';
// import { genDirectConversationId } from '@/ui/plugins/firebases/firestore-database/constants';
// import { taskListRouteReplace } from '@/ui/router';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import { AdminType } from '@/ui/common/constants/constant';
import userStore from '@/store/user';

const props = withDefaults(
  defineProps<{
    userId: string | number;
    user?: any;
    isHiddenName?: boolean;
    avatarClass?: string;
    nameTextClass?: string;
    isShowActionUser?: boolean;
    isShowFirstName?: boolean;
    isShowUserNotification?: boolean;
    customClass?: string;
    isHaveOverlay?: boolean;
    userAvatar?: string;
    taskPageQuery?: {
      filterTab?: string;
    };
  }>(),
  {
    avatarClass: 'w-6 h-6',
    isShowUserNotification: false,
    user: undefined,
    customClass: '',
    isHaveOverlay: false,
    nameTextClass: '',
  }
);

defineEmits(['onActionClick']);

const _dashboardStore = dashboardStore();
// const route = useRoute();
// const router = useRouter();
// const _chatStore = chatStore();
// const _settingStore = settingStore();

// const chatEnabled = computed(() => _settingStore.chatEnabled);
const userWorkingStatus = computed(() => _dashboardStore.userWorkingStatus);
const allUserByIds = computed(() => userStore().allUserByIds);
const userInfo = computed(() => {
  return (
    props.user || (allUserByIds.value ? allUserByIds.value[props.userId] : {})
  );
});

const isOpenProfileUser = ref(false);

// const onMessageToUser = (user) => {
//   if (!user?.id) return;

//   const myProfile = myProfileStore().myProfile;

//   if (!myProfile?.id) return;

//   const conversationId = genDirectConversationId(myProfile.id, user.id);

//   if (route?.path?.includes('/messages')) {
//     router.push({ params: { conversationId } });
//   } else {
//     _chatStore.addBubbleChat({
//       id: conversationId,
//       name: user.name || `${user.lastName} ${user.firstName}`,
//       avatar: user.avatarThumbUrl || user.avatarUrl,
//     });
//   }
// };
// const chooseUser = (user) => {
//   router.replace(taskListRouteReplace({ userId: user.id }));
// };

onUnmounted(() => {});
</script>

<template>
  <div
    v-if="!isShowActionUser"
    v-vig-tooltip="{
      content:
        (userInfo?.name || `${userInfo?.lastName} ${userInfo?.firstName}`) +
        (userInfo?.id === getCurrentUserId()
          ? ` (${$t('COMMON_LABEL_ME')})`
          : ''),
    }"
    class="flex items-center space-x-2 font-sans"
  >
    <SynAvatarNotification
      v-if="isShowUserNotification && userInfo?.avatar"
      :src="userInfo?.avatar?.replace('original', 'mini')"
      :name="userInfo?.name"
      :status="userWorkingStatus[userInfo?.id]?.status || 'offline'"
      :custom-class="avatarClass"
    />
    <UserById
      v-else
      :user-id="userId"
      :user="user"
      is-hidden-name
      :avatar-class="avatarClass"
    />
    <div v-if="!isHiddenName" class="flex flex-col text-overflow-hidden-line">
      <slot name="description-name"></slot>
      <span v-if="!isShowFirstName" class="w-max truncate">
        {{ userInfo?.name }}
      </span>
      <span v-else class="w-max truncate font-medium">{{
        userInfo?.firstName || userInfo?.name
      }}</span>
      <slot name="subName"></slot>
    </div>
  </div>
  <template v-else>
    <VigDropdown :delay="[400, 0]" trigger="mouseenter" z-index="3000">
      <template #dropdown-toggle>
        <div
          :class="[
            'flex items-center space-x-2 font-sans justify-start relative',
            customClass,
          ]"
        >
          <div
            v-if="isHaveOverlay"
            class="w-full h-full absolute inset-0 z-10"
          ></div>
          <SynAvatarNotification
            v-if="isShowUserNotification && userInfo?.avatar"
            :src="userInfo?.avatar?.replace('original', 'mini')"
            :name="userInfo?.name"
            :status="userWorkingStatus[userInfo?.id]?.status || 'offline'"
            :custom-class="avatarClass"
          />
          <UserById
            v-else
            :user-id="userId"
            :user="user"
            is-hidden-name
            :avatar-class="avatarClass"
          />

          <div
            v-if="!isHiddenName"
            class="flex flex-col text-overflow-hidden-line"
          >
            <slot name="description-name"></slot>
            <span
              v-if="!isShowFirstName"
              class="w-max truncate"
              :class="nameTextClass"
            >
              {{ userInfo?.name }}
            </span>
            <span
              v-else
              class="w-max truncate font-medium"
              :class="nameTextClass"
              >{{ userInfo?.firstName || userInfo?.name }}</span
            >
            <slot name="subName"></slot>
          </div>
        </div>
      </template>

      <template #dropdown-menu>
        <div class="w-max bg-white rounded-md py-2 px-4">
          <div class="flex items-center space-x-4 font-sans py-1">
            <SynAvatarNotification
              v-if="userInfo?.avatar"
              :src="userInfo?.avatar?.replace('original', 'mini')"
              :name="userInfo?.name"
              :status="userWorkingStatus[userInfo?.id]?.status || 'offline'"
              custom-class="h-14 w-14 cursor-pointer"
              @click.stop="isOpenProfileUser = true"
            />
            <div class="flex flex-col space-y-2">
              <div class="flex space-x-2 items-center">
                <div class="flex-center space-x-2">
                  <span class="font-medium text-sm">
                    {{ userInfo?.name }}
                  </span>
                  <SynTag
                    v-if="userInfo?.adminType === AdminType.Visitor"
                    size="tiny"
                    custom-class="px-1 border border-current-100 bg-white text-gray-800"
                  >
                    <span class="italic">
                      {{ $t('COMMON_LABEL_VISITOR') || 'Visitor' }}
                    </span>
                  </SynTag>
                </div>
              </div>

              <div class="flex space-x-2">
                <ActionById
                  v-if="userInfo?.id || userInfo?.userId"
                  :contact-id="userInfo?.id || userInfo?.userId"
                  :types="['favourite', 'list-task', 'chat']"
                  :task-page-query="taskPageQuery"
                  @on-action-click="$emit('onActionClick', $event)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </VigDropdown>
  </template>
  <ModalProfileUser
    v-if="isOpenProfileUser"
    :profile-id="userInfo?.id"
    @cancel="isOpenProfileUser = false"
  />
</template>
