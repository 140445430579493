<script setup lang="ts">
import { computed, ref } from 'vue';
import { chain, map } from 'lodash';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import GroupAttachmentAction from '@/ui/modules/task/components/GroupAttachmentAction.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';

const props = defineProps<{
  task: any;
  taskFiles: any[];
  taskNotes: any[];
  readonly: boolean;
}>();

const isPreview = ref<boolean>();
const previewIndex = ref<number>();

const totalAttachments = computed(
  () => (props.task?.attachmentCount || 0) + (props.task?.noteCount || 0)
);

const attachments = computed(() => {
  const files = map(props?.taskFiles || [], (file) => ({
    ...file,
    contentType: file?.type,
    isMedia: file?.documentType === 'MEDIA',
  }));

  const notes = map(props?.taskNotes || [], (note) => ({
    ...note,
    isNote: true,
    fileUrl: note?.imagePath,
    contentType: 'image/jpeg',
  }));

  return chain(files).concat(notes).sortBy('creationTime').value();
});

const isHasJustCreatedFile = (file) => {
  const createTime = new Date(file?.creationTime).getTime();
  const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const overTime = Date.now() + timeOffset - createTime;
  return overTime < 15 * 60 * 1000;
};

const onAttachmentClick = (file) => {
  previewIndex.value = attachments.value?.findIndex(
    (att) => att?.id === file?.id
  );
  isPreview.value = true;
};
</script>

<template>
  <div class="flex items-center gap-3 mb-2">
    <div class="text-sm text-current-600 font-medium">
      {{ $t('TASK_DETAIL_LABEL_ATTACHMENT') || 'Attachments' }}
      <span v-if="totalAttachments">({{ totalAttachments }})</span>
    </div>
    <GroupAttachmentAction v-if="false" :has-permission="!readonly" />
  </div>

  <div
    v-if="attachments?.length"
    class="w-full items-center flex flex-wrap pt-3 pb-1"
  >
    <div
      v-for="(file, index) in attachments"
      :key="index"
      class="relative w-max h-16 mr-2 mb-2 hover-to-hidden__parent"
    >
      <UploadPreview
        class="m-0"
        :file="file"
        :readonly="true"
        :is-note="file?.isNote"
        is-hidden-info
        @on-select="onAttachmentClick(file)"
      />
      <div
        v-if="isHasJustCreatedFile(file)"
        class="
          new-sticker
          absolute
          top-3
          -left-0
          rounded
          w-max
          text-white
          px-1
          bg-current
          -rotate-45
          hover-to-hidden__children
        "
        style="font-size: 0.6rem"
      >
        {{ $t('COMMON_LABEL_NEW') }}
      </div>
    </div>
  </div>

  <ModalMultipleFileViewer
    v-if="isPreview"
    ref="modalMultipleFileViewerRef"
    :start-index="previewIndex"
    :files="attachments"
    :editable="false"
    :has-task-actions="false"
    :has-image-actions="!readonly"
    @on-close="isPreview = false"
  >
    <template #title>
      <span
        class="truncate"
        v-html="`${formatTaskCode(task?.code)} - ${task?.name}`"
      >
      </span>
    </template>
  </ModalMultipleFileViewer>
</template>
