// import { defineAsyncComponent } from 'vue';

export function declare(modules: any, vueApp: any) {
    Object.entries(modules).forEach(([path, module]) => {
        // Extract the component name from the file path
        const componentName = path.split('/').pop().replace('.vue', '');
        vueApp.component(componentName, module?.default || module);
    });

    // Object.entries(modules).forEach(([path, module]) => {
    //     const componentName = path.split('/').pop().replace('.vue', '');
    //     vueApp.component(
    //         componentName,
    //         defineAsyncComponent({
    //             loader: module,
    //         })
    //     );
    // });
}
