<script setup lang="ts">
import { computed } from 'vue';
// import {useRouter} from 'vue-router';
import dayjs from 'dayjs';
import { DayoffStatus } from '@/domain/enums/DayoffEnums';
// import {encryptUserId, encryptGroupId} from '@/ui/plugins/crypto/crypto';
import {
  DayoffTypeUI,
  DayoffStatusUI,
  // DayoffExceptionTypeUI,
  // DayoffActionTypeUI,
} from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import DayoffNotificationItemClass from '@/domain/entities/dayoff/DayoffNotificationItemClass';

import UserByAction from '@/ui/components/user/UserByAction.vue';
// import userStore from '@/store/user';
import { INotificationTask } from '@/application/types/notification.types';
import DayOffShortDetail from '@/ui/components/dayoff/detail/DayOffShortDetail.vue';
import { IDayOffDetail } from '@/application/types/calendar/day-off.types';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const props = withDefaults(
  defineProps<{
    propId?: string;
    notificationItem?: any;
    category?: string;
    isView?: boolean;
    isShowActionUser?: boolean;
    users?: any[];
  }>(),
  {
    category: 'all',
    isShowActionUser: true,
  }
);
defineEmits([
  'handleClickMarkRead',
  'handleDeleteNotification',
  'onUserActionClick',
]);

// const _userStore = userStore();
const dayoffNotificationItem = computed<INotificationTask>(
  () => props.notificationItem
);
const dayoffNotification = computed<DayoffNotificationItemClass>(
  () => new DayoffNotificationItemClass(dayoffNotificationItem.value?.mainData)
);
const dayOffDetail = computed<IDayOffDetail>(() => {
  const exceptionData: any = dayoffNotification.value?.exception;
  return {
    id: dayoffNotification.value?.dayoffId,
    organizationId: dayoffNotification.value?.organizationId,
    userId: dayoffNotification.value?.actionerId,
    startDate: dayoffNotification.value?.startDate,
    endDate: dayoffNotification.value?.endDate,
    startDateSession: dayoffNotification.value?.startDateSession,
    endDateSession: dayoffNotification.value?.endDateSession,
    requestDate: dayoffNotification.value?.requestDate,
    requestDay: dayoffNotification.value?.requestDay,
    usedDay: dayoffNotification.value?.requestDay,
    type: dayoffNotification.value?.requestType,
    status: dayoffNotification.value?.dayoffStatus,
    currentStatusRequest: dayoffNotification.value?.currentStatusRequest,
    requestReason: dayoffNotification.value?.requestReason,
    requestNote: dayoffNotification.value?.requestNote,
    rejectReason: dayoffNotification.value?.rejectReason,
    rejectNote: dayoffNotification.value?.rejectNote,
    exception: exceptionData
      ? {
          type: exceptionData?.type,
          daySession: exceptionData?.daySession || exceptionData?.day_session,
          startTime: exceptionData?.startTime || exceptionData?.start_time,
          endTime: exceptionData?.endTime || exceptionData?.end_time,
        }
      : undefined,
    createdDate: dayoffNotification.value?.requestDate,
    createdBy: dayoffNotification.value?.actionerId,
  };
});

const notifActionerUser = computed(() =>
  props.users?.find(
    (user: any) => user?.id === dayoffNotification.value?.actionerId
  )
);

const listStatusRequestShow = [
  DayoffStatus.ACCEPTED,
  DayoffStatus.CANCELLED,
  DayoffStatus.CLOSED,
  DayoffStatus.REJECTED,
];

// const avatarUserById = (avatarNotification, userId) => {
//   return _userStore.updateAvatarUserToAvatarCurrent(
//     avatarNotification,
//     userId,
//     'small'
//   );
// };

// const dayoffNotificationTitle = computed(() => 'A yeu cau nghi ngay mai');
// const initData = () => {
//   if (!props.notificationItem) return;
//   dayoffNotificationItem.value = new DayoffNotificationItemClass(
//     props.notificationItem
//   );
// };

// const router = useRouter();
// const getLinkUrl = (notification, type) => {
//   return type == 'group' && notification?.groupId
//       ? `/tasks/groups/${encryptGroupId(notification?.groupId)}`
//       : `/tasks/users/${encryptUserId(
//           notification?.executorId || notification?.userId
//       )}`;
// };

// const onGotoLink = (event, notification, type) => {
//   event.preventDefault();
//   router.push(getLinkUrl(notification, type));
// };

// const offsetTimezone = computed(() => {
//   if (timezone.value == '+7') return 7;
//   if (timezone.value == '+1') return 1;
//   return -5;
// });

const handleCreateTimeNotification = (createdDate) => {
  if (!createdDate) {
    return;
  }
  return dayjs(createdDate).fromNow();
};

// initData();
</script>

<template>
  <div
    :id="`noti-${dayoffNotification?.dayoffId}-${propId}`"
    :class="
      !dayoffNotificationItem?.status ? 'bg-current-50 bg-opacity-30' : ''
    "
    class="
      item-noti
      relative
      border-b border-gray-200
      flex
      items-center
      px-2
      py-1
      text-sm text-gray-700
      hover:bg-current-50 hover:bg-opacity-50
      cursor-pointer
      overflow-hidden
    "
  >
    <div class="relative flex items-center w-full">
      <!--      <SynAvatarStatus-->
      <!--        :name="dayoffNotificationItem.actionerName"-->
      <!--        :src="-->
      <!--          avatarUserById(-->
      <!--            dayoffNotificationItem.actionerAvatar,-->
      <!--            dayoffNotificationItem?.actionerId || dayoffNotificationItem?.userId-->
      <!--          )-->
      <!--        "-->
      <!--        :color-status="-->
      <!--          DayoffTypeUI[dayoffNotificationItem?.requestType || 'SCHEDULE'].color-->
      <!--        "-->
      <!--        :name-icon="-->
      <!--          DayoffTypeUI[dayoffNotificationItem?.requestType || 'SCHEDULE']-->
      <!--            .iconName-->
      <!--        "-->
      <!--      />-->
      <div class="relative">
        <UserByAction
          :avatar-class="'h-12 w-12'"
          :user-id="dayoffNotification?.actionerId"
          :user="notifActionerUser"
          :is-show-action-user="isShowActionUser"
          is-hidden-name
          @on-action-click="$emit('onUserActionClick', $event)"
        />
        <div
          class="
            absolute
            -bottom-1
            rounded-full
            flex-center
            -right-2
            border border-white
            w-7
            h-7
          "
          :class="
            DayoffTypeUI[dayoffNotification?.requestType || 'SCHEDULE'].color
          "
          :style="{
            backgroundColor:
              DayoffTypeUI[dayoffNotification?.requestType || 'SCHEDULE'].color,
          }"
        >
          <SynIconCustom
            class="fill-white text-white"
            custom-class="w-3.5 h-3.5"
            :name="
              DayoffTypeUI[dayoffNotification?.requestType || 'SCHEDULE']
                .iconName
            "
            is-active
          />
        </div>
      </div>
      <div class="w-full flex flex-col" style="padding-left: 1.25rem">
        <!-- P1: BODY NOTIFICATION -->
        <a :href="dayoffNotification.dayoffLink">
          <div
            :title="dayoffNotificationItem.title"
            class="flex items-center w-full space-x-2 pb-1"
          >
            <div
              class="text-overflow-hidden font-medium"
              :class="
                !dayoffNotificationItem?.status
                  ? 'text-black font-medium'
                  : 'text-gray-700'
              "
            >
              {{ dayoffNotificationItem.title }}
            </div>
          </div>
        </a>

        <DayOffShortDetail :day-off-detail="dayOffDetail">
          <template #subContent>
            <span
              v-if="dayoffNotificationItem?.groupedContent"
              :title="dayoffNotificationItem?.groupedContent"
              class="
                text-current-800 text-overflow-hidden-line
                align-text-bottom
              "
              style="font-size: 10.5px"
            >
              {{ dayoffNotificationItem?.groupedContent || '' }}
            </span>
          </template>
        </DayOffShortDetail>

        <!-- P3: GROUP NAME, EXECUTOR NAME -->
        <div class="flex items-center space-x-1 justify-between">
          <div class="flex flex-col">
            <div
              v-if="dayoffNotification?.actionerName"
              style="font-size: 11px"
              class="w-full flex items-start space-x-1"
            >
              <span>
                <span>{{ $t('TASK_DETAIL_LABEL_BY') + ' ' }}</span>
                <span class="text-gray-600">
                  {{ dayoffNotification.actionerName }}
                </span>
              </span>
            </div>
          </div>

          <div class="flex space-x-2 items-center text-overflow-hidden">
            <span class="text-gray-500" style="font-size: 12px">
              {{
                handleCreateTimeNotification(dayoffNotification?.requestDate)
              }}
            </span>
            <span
              v-if="!dayoffNotificationItem?.status"
              class="rounded-full w-2 h-2 bg-blue-500 mt-0.5"
            ></span>
          </div>
        </div>

        <!-- LAST STATE DAYOFF  -->
        <div
          v-if="
            dayoffNotification?.currentStatusRequest &&
            listStatusRequestShow?.includes(
              dayoffNotification?.currentStatusRequest
            )
          "
          class="min-w-max absolute right-0 top-1"
        >
          <div class="flex-center flex-row flex-nowrap -rotate-12">
            <SynStickerLabel
              :label="
                $t(
                  DayoffStatusUI[dayoffNotification?.currentStatusRequest]
                    ?.textCode
                ) || ''
              "
              :is-arrow="false"
              :custom-class="`border-2 border-${
                DayoffStatusUI[dayoffNotification?.currentStatusRequest]
                  ?.typeColor
              }-400 text-${
                DayoffStatusUI[dayoffNotification?.currentStatusRequest]
                  ?.typeColor
              }-400`"
            />
          </div>
        </div>

        <!-- BUTTON: ACTION SEEN, REMOVE -->
        <div
          v-if="!isView"
          class="
            item-noti__action
            absolute
            right-0
            top-1
            flex
            items-center
            space-x-2
          "
        >
          <div
            v-if="!dayoffNotificationItem?.status"
            :title="$t('COMMON_LABEL_SEEN')"
            class="
              border border-green-500
              bg-white bg-opacity-70
              flex-center
              w-6
              h-6
              rounded-full
              text-green-500
              fill-green-500
              hover:fill-white hover:text-white hover:bg-green-500
            "
            @click.stop="$emit('handleClickMarkRead', notificationItem?.id)"
          >
            <SynIcon name="check" size="small" />
          </div>

          <div
            :title="$t('NOTIFICATION_DELELTE_NOTIFICATION_SUB')"
            class="
              border border-red-500
              flex-center
              bg-white bg-opacity-70
              w-6
              h-6
              rounded-full
              text-red-500
              fill-red-500
              hover:fill-white hover:text-white hover:bg-red-500
            "
            @click.stop="
              $emit('handleDeleteNotification', notificationItem?.id)
            "
          >
            <SynIcon
              name="cancel"
              size="small"
              custom-class="w-2.5 h-2.5"
              is-active
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item-noti:hover .item-noti__action {
  /* display: block; */
  visibility: visible;
}

.item-noti__action {
  /* display: none; */
  visibility: hidden;
}

.item-noti:active {
  visibility: visible;
}

.item-noti:active .item-noti__action {
  /* display: block; */
  visibility: visible;
}
</style>
