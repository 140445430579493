<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    preserveAspectRatio="xMidYMid meet"
    style="transform: translate3d(0px, 0px, 0px); content-visibility: visible"
  >
    <defs>
      <clipPath id="__lottie_element_92">
        <rect width="500" height="500" x="0" y="0"></rect>
      </clipPath>
    </defs>
    <g clip-path="url(#__lottie_element_92)">
      <g
        transform="matrix(0.8482300043106079,0,0,0.8482300043106079,32.853118896484375,32.853118896484375)"
        opacity="1"
        style="display: block"
      >
        <g
          opacity="1"
          transform="matrix(1,0,0,1,295.7170104980469,216.03700256347656)"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            fill-opacity="0"
            stroke="rgb(74,176,177)"
            stroke-opacity="1"
            stroke-width="40"
            d=" M102.34200286865234,197.24000549316406 C102.34200286865234,197.24000549316406 -102.34200286865234,197.24000549316406 -102.34200286865234,197.24000549316406 C-130.94200134277344,197.24000549316406 -154.1269989013672,174.05499267578125 -154.1269989013672,145.4550018310547 C-154.1269989013672,145.4550018310547 -154.1269989013672,-145.45599365234375 -154.1269989013672,-145.45599365234375 C-154.1269989013672,-174.05599975585938 -130.94200134277344,-197.24000549316406 -102.34200286865234,-197.24000549316406 C-102.34200286865234,-197.24000549316406 102.34200286865234,-197.24000549316406 102.34200286865234,-197.24000549316406 C130.9429931640625,-197.24000549316406 154.1269989013672,-174.05599975585938 154.1269989013672,-145.45599365234375 C154.1269989013672,-145.45599365234375 154.1269989013672,145.4550018310547 154.1269989013672,145.4550018310547 C154.1269989013672,174.05499267578125 130.9429931640625,197.24000549316406 102.34200286865234,197.24000549316406z"
          ></path>
        </g>
      </g>
      <g
        transform="matrix(0.8482300043106079,0,0,0.8482300043106079,32.853118896484375,32.853118896484375)"
        opacity="1"
        style="display: block"
      >
        <g
          opacity="1"
          transform="matrix(1,0,0,1,215.63099670410156,296.1239929199219)"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            fill-opacity="0"
            stroke="rgb(74,176,177)"
            stroke-opacity="1"
            stroke-width="40"
            d=" M154.1269989013672,120.76599884033203 C154.1269989013672,120.76599884033203 154.1269989013672,145.4550018310547 154.1269989013672,145.4550018310547 C154.1269989013672,174.05499267578125 130.9409942626953,197.24099731445312 102.34200286865234,197.24099731445312 C102.34200286865234,197.24099731445312 -102.34200286865234,197.24099731445312 -102.34200286865234,197.24099731445312 C-130.94200134277344,197.24099731445312 -154.1269989013672,174.05499267578125 -154.1269989013672,145.4550018310547 C-154.1269989013672,145.4550018310547 -154.1269989013672,-145.45599365234375 -154.1269989013672,-145.45599365234375 C-154.1269989013672,-174.05599975585938 -130.94200134277344,-197.24099731445312 -102.34200286865234,-197.24099731445312 C-102.34200286865234,-197.24099731445312 -74.04100036621094,-197.24099731445312 -74.04100036621094,-197.24099731445312"
          ></path>
        </g>
      </g>
      <g
        transform="matrix(0.8482300043106079,0,0,0.8482300043106079,32.853118896484375,32.853118896484375)"
        opacity="1"
        style="display: block"
      >
        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            fill-opacity="0"
            stroke="rgb(74,176,177)"
            stroke-opacity="1"
            stroke-width="40"
            d=" M213.5659942626953,285.3739929199219 C213.5659942626953,285.3739929199219 304.37200927734375,285.3739929199219 304.37200927734375,285.3739929199219"
          ></path>
        </g>
      </g>
      <g
        transform="matrix(0.8482300043106079,0,0,0.8482300043106079,32.853118896484375,32.853118896484375)"
        opacity="1"
        style="display: block"
      >
        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            fill-opacity="0"
            stroke="rgb(74,176,177)"
            stroke-opacity="1"
            stroke-width="40"
            d=" M213.5659942626953,208.02999877929688 C213.5659942626953,208.02999877929688 378.3330078125,208.02999877929688 378.3330078125,208.02999877929688"
          ></path>
        </g>
      </g>
      <g
        transform="matrix(0.8482300043106079,0,0,0.8482300043106079,32.853118896484375,32.853118896484375)"
        opacity="1"
        style="display: block"
      >
        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            fill-opacity="0"
            stroke="rgb(74,176,177)"
            stroke-opacity="1"
            stroke-width="40"
            d=" M213.5659942626953,128.0189971923828 C213.5659942626953,128.0189971923828 378.3330078125,128.0189971923828 378.3330078125,128.0189971923828"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
