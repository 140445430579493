<template>
  <div class="flex-center flex-col">
    <div class="flex flex-col justify-center items-center space-y-2">
      <div
        class="relative h-32 w-32 flex-center rounded-full bg-opacity-20"
        :class="isRecording ? 'bg-red-200 animate-pulse' : 'bg-current-200 '"
      >
        <div
          class="
            relative
            inline-flex
            h-20
            w-20
            bg-white
            shadow-lg
            rounded-full
            flex-center
            cursor-pointer
          "
          :class="{ Rec: isRecording }"
          @click="onStart"
        >
          <SynIcon
            is-active
            custom-class="w-8 h-8"
            has-action
            :color="isRecording ? 'red' : 'current'"
            :name="isRecording ? 'stop' : 'microphone'"
          />
        </div>
      </div>
      <div class="flex space-x-2 pt-4">
        <syn-count-down-watch
          style="color: #ff6500"
          :start="isRecording"
          :total-miniseconds="litmitedTime * 1000"
          @on-stop="onStop"
        ></syn-count-down-watch>
      </div>
      <span class="text-sm">
        {{
          $t('TASK_CREATE_FORM_AUDIO_LABEL_LIMIT_RECORD') +
          ' ' +
          litmitedTime +
          's'
        }}
      </span>
      <span v-if="isLimited" class="text-sm text-red-400">
        {{
          $t('TASK_CREATE_FORM_AUDIO_LABEL_TOTAL_RECORD') +
          ' ' +
          litmitedTotalRecord +
          ' ' +
          $t('COMMON_LABEL_RECORDS')
        }}
      </span>
      <div class="flex flex-col-reverse justify-center items-center space-y-1">
        <syn-audio
          v-for="record in recordings"
          :key="record"
          class="h-16"
          :src="record.src"
          @on-close="onRemove(record)"
        />
      </div>
    </div>
    <div
      class="p-4 w-full flex justify-end items-center cursor-pointer space-x-2"
    >
      <syn-button
        :label="$t('COMMON_LABEL_CANCEL')"
        type-text
        color="gray"
        @click="onCancel"
      >
      </syn-button>
      <syn-button
        :disabled="isRecording || recordings.length == 0"
        :is-loading="isLoading"
        :label="$t('COMMON_LABEL_DONE')"
        @click="onDone"
      >
      </syn-button>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import vmsg from 'vmsg';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';

const recorder = new vmsg.Recorder({
  wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
});
const litmitedTime = 3 * 60;
const litmitedTotalRecord = 3;
export default {
  name: 'SynRecorder',
  components: {},
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['onDone', 'onCancel'],
  setup(props, { emit }) {
    const audioUrl = ref(null);
    const recordings = ref([]);
    const isLoading = ref(false);
    const isRecording = ref(false);
    const record = async () => {
      isLoading.value = true;

      if (isRecording.value) {
        const blob = await recorder.stopRecording();
        // Stoped Recording...
        isLoading.value = false;
        isRecording.value = false;
        recordings.value = [
          ...recordings.value,
          {
            blob: blob,
            src: URL.createObjectURL(blob),
            type: blob.type,
            size: blob.size,
            isRecorder: true,
          },
        ];
      } else {
        try {
          await recorder.initAudio();
          await recorder.initWorker();
          recorder.startRecording();
          // Started Recording...
          isLoading.value = false;
          isRecording.value = true;
        } catch (e) {
          console.error(e);

          openNotification({
            type: AlertType.error,
            title: translate('COMMON_LABEL_ERROR'),
            body: e,
          });
          isLoading.value = false;
        }
      }
    };
    onMounted(() => {});
    onUnmounted(() => {
      if (isRecording.value) {
        recorder.stopRecording();
      }
    });
    const onStart = () => {
      if (isLimited.value) {
        return;
      }
      record();
    };
    const onStop = () => {
      if (isRecording.value) {
        record();
      }
    };
    const onDone = async () => {
      if (isRecording.value) {
        await record();
      }
      emit('onDone', recordings.value);
    };
    const onCancel = () => {
      if (isRecording.value) {
        recorder.stopRecording();
        isRecording.value = false;
      }
      emit('onCancel');
    };
    const onRemove = (record) => {
      const index = recordings.value.findIndex((o) => o.src == record.src);
      if (index > -1) {
        recordings.value.splice(index, 1);
      }
    };
    const isLimited = computed(
      () => litmitedTotalRecord == recordings.value?.length
    );
    return {
      onStart,
      audioUrl,
      recordings,
      isRecording,
      onDone,
      onCancel,
      onRemove,
      litmitedTime,
      onStop,
      isLimited,
      litmitedTotalRecord,
    };
  },
};
</script>
<style scoped>
.Rec {
  animation-name: audio-pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes audio-pulse {
  0% {
    box-shadow: 0px 0px 10px 0px rgba(255, 19, 19, 0.3);
  }
  65% {
    box-shadow: 0px 0px 10px 13px rgba(255, 19, 19, 0.3);
  }
  90% {
    box-shadow: 0px 0px 10px 13px rgba(255, 19, 19, 0.3);
  }
}
</style>
