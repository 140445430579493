<script setup lang="ts">
import { computed } from 'vue';
import { TaskUrgency, getUrgencyValue } from '@/ui/hooks/taskHook';

const props = withDefaults(
  defineProps<{
    urgency?: number;
    priority?: number;
    important?: boolean;
    size?: 'mini' | 'tiny' | 'default';
    isHiddenLabel?: boolean;
    isHiddenIcon?: boolean;
  }>(),
  {
    size: 'default',
    urgency: 0,
    isHiddenLabel: false,
    isHiddenIcon: false,
  }
);
const sizeClass = computed(() => {
  if (props.size == 'mini') return 'h-5 w-5';
  else if (props.size == 'tiny') return 'h-4 w-4';
  return 'h-6 w-6';
});
const sizeIcon = computed(() => {
  if (props.size == 'mini') return 'w-2 h-2';
  else if (props.size == 'tiny') return 'h-2 w-2';
  return 'w-3 h-3';
});
const fontClass = computed(() => {
  if (props.size == 'mini') return 'text-xs';
  else if (props.size == 'tiny') return 'text-xs';
  return 'text-sm';
});

const urgencyValue = computed<any>(() => {
  if (props.urgency) return props.urgency;

  return getUrgencyValue(props.priority, props.important);
});
</script>
<template>
  <div class="flex-center" :class="!isHiddenLabel ? 'space-x-2' : ''">
    <div v-if="!isHiddenIcon" :class="[sizeClass]">
      <div
        v-if="urgencyValue == TaskUrgency.UrgentImportant.key"
        v-vig-tooltip="
          $t('TASK_CREATE_FORM_URGENT_IMPORTANT') || 'URGENT & IMPORTANT'
        "
        class="h-full w-full bg-red-300 flex-center rounded-full shadow"
      >
        <SynIcon class="fill-red-500" name="high" :custom-class="sizeIcon" />
      </div>
      <div
        v-if="urgencyValue == TaskUrgency.Urgent.key"
        v-vig-tooltip="$t('TASK_CREATE_FORM_URGENT') || 'URGENT'"
        class="h-full w-full bg-blue-200 flex-center rounded-full shadow"
      >
        <SynIcon class="fill-red-500" name="high" :custom-class="sizeIcon" />
      </div>
      <div
        v-if="urgencyValue == TaskUrgency.Important.key"
        v-vig-tooltip="$t('TASK_CREATE_FORM_IMPORTANT') || 'IMPORTANT'"
        class="h-full w-full flex-center rounded-full bg-blue-50 shadow"
      >
        <SynIcon class="fill-red-500" name="square" :custom-class="sizeIcon" />
      </div>
      <div
        v-if="urgencyValue == TaskUrgency.Cool.key"
        v-vig-tooltip="$t('TASK_CREATE_FORM_COOL') || 'COOL'"
        class="h-full w-full flex-center rounded-full bg-blue-200 shadow"
      >
        <!-- <SynIcon class="fill-blue-500" name="square" custom-class="w-3 h-3" /> -->
      </div>
    </div>
    <span
      v-if="!isHiddenLabel"
      :class="fontClass"
      class="flex-1 min-w-0 truncate"
    >
      {{
        urgency == TaskUrgency.UrgentImportant.key
          ? $t('TASK_CREATE_FORM_URGENT_IMPORTANT') || 'URGENT & IMPORTANT'
          : urgency == TaskUrgency.Urgent.key
          ? $t('TASK_CREATE_FORM_URGENT') || 'URGENT'
          : urgency == TaskUrgency.Important.key
          ? $t('TASK_CREATE_FORM_IMPORTANT') || 'IMPORTANT'
          : urgency == TaskUrgency.Cool.key
          ? $t('TASK_CREATE_FORM_COOL') || 'COOL'
          : ''
      }}
    </span>
  </div>
</template>
