<script setup lang="ts">
import { computed } from 'vue';
import PersonalSidebarItem from './PersonalSidebarItem.vue';
import settingStore from '@/store/setting';
import appStore from '@/store/app';

defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  currentActive: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
  onAction: {
    type: Function,
    required: false,
    default: () => {},
  },
  isCollapse: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['on-collapse']);
// const showResource = ref(false);

// show setting group
const _appStore = appStore();
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
const _settingStore = settingStore();
const dayoffEnabled = computed(() => _settingStore.dayoffEnabled);
const calendarEnabled = computed(() => _settingStore.calendarEnabled);

const getMainPageLinkUrl = () => {
  return `/calendars`;
};
</script>

<template>
  <div v-if="calendarEnabled || dayoffEnabled" class="w-full">
    <PersonalSidebarItem
      icon-name="calendars"
      :is-active="
        currentActive == 'calendar' || currentActive.includes('calendar')
      "
      :is-hidden-text="!isOpenMenuBar"
      :item-name="$t('CALENDAR_TITLE') || 'Calendar'"
      :link-to="getMainPageLinkUrl()"
    />
  </div>
</template>
