<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import groupStore from '@/store/group';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import userStore from '@/store/user';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

const props = defineProps({
  groupId: { type: Number, default: 0 },
  userId: { type: Number, default: 0 },
  isLoadingSave: { type: Boolean, default: false },
});

const emit = defineEmits(['onCancel', 'onSave']);

const _groupStore = groupStore();
const _userStore = userStore();
const isVisitor = computed(() => _userStore.isVisitor);
const allUser = computed(() =>
  _userStore.listShortInfosUser.map((e) => {
    e.favoriteId = e.userId;
    return {
      ...e,
    };
  })
);
const allAssignableUsers = computed(() =>
  allUser.value
    .filter((e) => !isVisitor.value || e.userId == getCurrentUserId())
    .map((e) => {
      e.favoriteId = e.userId;
      return {
        ...e,
      };
    })
);
const allGroup = computed(() =>
  _groupStore.allMyGroups
    .filter((e) => e.isMember)
    .map((o) => {
      o.id;
      o.name;
      o.groupUsers;
      return o;
    })
);
const allGroupByIds = computed(() => _groupStore.allGroupByIds);
onMounted(() => {
  groupList.value = cloneDeep(allGroup.value);
  const indexG = allGroup.value.findIndex((o) => o['id'] == props.groupId);
  if (indexG > -1) {
    assignedGroup.value = allGroupByIds.value[props.groupId];
  }
  const indexU = allUser.value.findIndex((o) => o['userId'] == props.userId);
  if (indexU > -1) {
    onChangeAssignee(allUser.value[indexU]);
  }
});
const assignedGroup = ref({} as any);
const assignedUser = ref({} as any);
const onCancelAssign = () => {
  emit('onCancel');
};
const errorMessage = ref('');
const onSaveAssign = () => {
  if (!assignedGroup.value?.id && !assignedUser.value?.userId) {
    errorMessage.value =
      translate('TASK_CREATE_FORM_MUST_BELONG_TO') ||
      'Task must belong to a group or a user';
    return;
  } else if (
    isVisitor.value &&
    !assignedGroup.value?.id &&
    assignedUser.value?.userId != getCurrentUserId()
  ) {
    errorMessage.value =
      translate('TASK_CREATE_FORM_VISITOR_CHANGE_ASSIGN_TO_USER') ||
      'You cannot assign task to other without a group';
    return;
  }
  emit('onSave', assignedGroup.value, assignedUser.value);
};

const onChangeGroupAssignee = (item) => {
  if (!item || !item.id) return;
  isHaveChanged.value = true;
  errorMessage.value = '';
  assignedGroup.value = item;
  const indexG = allGroup.value.findIndex((o) => o['id'] == item.id);
  if (indexG > -1) {
    assignedGroup.value = {
      ...allGroupByIds.value[item.id],
      ...item,
    };
  }
  // If current user is not in group, reset it
  if (
    !assignedGroup.value?.groupUsers?.some(
      (o) => o.userId == assignedUser.value.userId
    )
  ) {
    assignedUser.value = {};
  }
};
const onRemoveGroup = () => {
  isHaveChanged.value = true;
  assignedGroup.value = {};
};
const onRemoveUser = () => {
  isHaveChanged.value = true;
  assignedUser.value = {};
  groupList.value = cloneDeep(allGroup.value);
};
const groupList = ref([]);
const onChangeAssignee = async (item) => {
  assignedUser.value = item;
  errorMessage.value = '';
  // Update list group
  // isFetchingGroup.value = true;
  // const result = await _groupStore.getSameGroupOfUsers(item.userId);
  // groupList.value = result;
  // isFetchingGroup.value = false;
};

const isHaveChanged = ref(false);
</script>

<template>
  <syn-modal
    z-index="z-60"
    container-class="w-1/3"
    disable-click-outside
    :is-disable-button="isLoadingSave"
    :is-loading="isLoadingSave"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="
      isLoadingSave
        ? $t('COMMON_LABEL_TASK_IS_CLONING')
        : $t('COMMON_LABEL_CREATE')
    "
    @cancel="onCancelAssign"
    @confirm="onSaveAssign"
  >
    <template #header>
      <div class="flex items-center">
        <span class="text-base">
          {{ $t('COMMON_LABEL_CLONE') || 'Clone Task' }}
        </span>
      </div>
    </template>
    <template #body>
      <slot></slot>
      <div class="flex flex-col py-2">
        <div class="flex flex-wrap">
          <syn-tag
            custom-class="m-1 px-2"
            class="bg-white w-max max-w-full"
            style="padding-top: 0; padding-bottom: 0"
          >
            <div class="flex flex-col space-y-1">
              <div class="font-medium text-current-800 text-sm">
                {{ $t('COMMON_LABEL_ASSIGN_GROUP') }}
              </div>
              <!-- <syn-label-loading class="w-48" v-if="isFetchingGroup" /> -->
              <div class="flex-center bg-gray-100 px-1 rounded">
                <syn-select-assign
                  input-class="text-sm"
                  removeable
                  placeholder="Select group"
                  assign-group
                  type="group"
                  :data="
                    groupList?.map((x) => {
                      x.favoriteId = x.id;
                      return x;
                    }) ||
                    allGroup?.map((x) => {
                      x.favoriteId = x.id;
                      return x;
                    })
                  "
                  :name="assignedGroup.name"
                  :avatar="assignedGroup.avatarUrl?.replace('original', 'mini')"
                  @chosen="
                    (group) => [
                      onChangeGroupAssignee(group, false),
                      (isHaveChanged = true),
                    ]
                  "
                  @reset="onRemoveGroup()"
                />
              </div>
            </div>
          </syn-tag>

          <syn-tag
            custom-class="m-1 px-2"
            class="bg-white w-max max-w-full"
            style="padding-top: 0; padding-bottom: 0"
          >
            <div class="flex flex-col space-y-1">
              <div class="font-medium text-current-800 text-sm">
                {{ $t('TASK_LABEL_ASSIGNEE') || 'Assignee' }}
              </div>
              <div class="flex-center bg-gray-100 px-1 rounded">
                <syn-select-assign
                  input-class="text-sm"
                  :placeholder="$t('COMMON_LABEL_SELECT_USER')"
                  removeable
                  :data="
                    assignedGroup?.id
                      ? assignedGroup.groupUsers?.map((x) => {
                          x.favoriteId = x.userId;
                          x.id = x.userId;
                          x.name =
                            x?.name ||
                            x?.fullName ||
                            x?.lastName + ' ' + x?.firstName;
                          return x;
                        })
                      : allAssignableUsers
                  "
                  :current-id="assignedUser?.id"
                  :name="assignedUser.name || assignedUser?.fullName"
                  :avatar="
                    (assignedUser.avatar || assignedUser.avatarUrl)?.replace(
                      'original',
                      'mini'
                    )
                  "
                  @chosen="(user) => onChangeAssignee(user, true)"
                  @reset="onRemoveUser()"
                />
              </div>
            </div>
          </syn-tag>
        </div>
        <div class="px-2 text-sm text-red-500">
          {{ errorMessage }}
        </div>
      </div>
    </template>
  </syn-modal>
</template>
