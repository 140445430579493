import { hasPermission } from '@/ui/hooks/permission/permission-by-role';
export default {
    install: (app) => {
        app.config.globalProperties.$permission = (functionName) => {
            return hasPermission(functionName);
        };
        app.provide('permission', (functionName) => {
            return hasPermission(functionName);
        });
        app.directive('permission', {
            beforeMount(el, binding) {
                const { value } = binding;
                const permission = hasPermission(value);
                if (!permission) {
                    // el.parentNode && el.parentNode.removeChild(el);
                    el && el.remove();
                    // el.style.display = 'none';
                }
            },
        });
    },
};
