<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512.00019"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m256 511.996094c-141.484375 0-256-114.65625-256-255.996094 0-141.488281 114.496094-256 256-256 141.488281 0 255.996094 114.496094 255.996094 256 0 141.476562-114.667969 255.996094-255.996094 255.996094zm0 0"
        fill="#008e8e"
        data-original="#66a9df"
        style=""
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m256 0v511.996094c141.328125 0 255.996094-114.519532 255.996094-255.996094 0-141.5-114.507813-256-255.996094-256zm0 0"
        fill="#00b5b5"
        data-original="#4f84cf"
        style=""
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m256 316c-74.488281 0-145.511719 32.5625-197.417969 102.96875 103.363281 124.941406 294.6875 123.875 396.65625-2.230469-25.179687-25.046875-81.894531-100.738281-199.238281-100.738281zm0 0"
        fill="#d6f3fe"
        data-original="#d6f3fe"
        style=""
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m455.238281 416.738281c-48.140625 59.527344-120.371093 95.257813-199.238281 95.257813v-195.996094c117.347656 0 174.058594 75.699219 199.238281 100.738281zm0 0"
        fill="#bdecfc"
        data-original="#bdecfc"
        style=""
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m256 271c-49.628906 0-90-40.375-90-90v-30c0-49.625 40.371094-90 90-90 49.625 0 90 40.375 90 90v30c0 49.625-40.375 90-90 90zm0 0"
        fill="#d6f3fe"
        data-original="#d6f3fe"
        style=""
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m256 61v210c49.628906 0 90-40.371094 90-90v-30c0-49.628906-40.371094-90-90-90zm0 0"
        fill="#bdecfc"
        data-original="#bdecfc"
        style=""
        class=""
      />
    </g>
  </svg>
</template>
