import { encodeHtml } from '../hooks/commonFunction';

export default {
    install: (app) => {
        app.directive('encode-html', {
            mounted(el, binding) {
                if (!el) return;
                _bindingValueToElement(el, binding.value?.text);
            },
            updated(el, binding) {
                if (!el) return;
                _bindingValueToElement(el, binding.value?.text);
            },
        });
    },
};

const _bindingValueToElement = (el, originalText) => {
    el.innerHTML = encodeHtml(originalText);
};
