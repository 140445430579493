<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="16"
    viewBox="0 0 40 16"
  >
    <g id="Group_3305" data-name="Group 3305" transform="translate(5 0.533)">
      <g
        id="Group_3368"
        data-name="Group 3368"
        transform="translate(-5 -0.533)"
      >
        <circle
          id="Ellipse_202"
          data-name="Ellipse 202"
          cx="3.733"
          cy="3.733"
          r="3.733"
          transform="translate(3.199)"
        />
        <path
          id="Path_1067"
          data-name="Path 1067"
          d="M7.933,17A6.941,6.941,0,0,0,1,23.933a.533.533,0,0,0,.533.533h12.8a.533.533,0,0,0,.533-.533A6.941,6.941,0,0,0,7.933,17Z"
          transform="translate(-1 -8.467)"
        />
      </g>
    </g>
    <g id="Group_3306" data-name="Group 3306" transform="translate(26.133 0)">
      <circle
        id="Ellipse_202-2"
        data-name="Ellipse 202"
        cx="3.733"
        cy="3.733"
        r="3.733"
        transform="translate(3.2 0)"
      />
      <path
        id="Path_1067-2"
        data-name="Path 1067"
        d="M7.933,17a6.941,6.941,0,0,1,6.933,6.933.533.533,0,0,1-.533.533H1.533A.533.533,0,0,1,1,23.933,6.941,6.941,0,0,1,7.933,17Z"
        transform="translate(-1 -8.467)"
      />
    </g>
    <path
      id="Path_1084"
      data-name="Path 1084"
      d="M30,14H27V11a1,1,0,0,0-2,0v3H22a1,1,0,0,0,0,2h3v3a1,1,0,0,0,2,0V16h3a1,1,0,1,0,0-2Z"
      transform="translate(-6.334 -6.999)"
    />
  </svg>
</template>
