<script setup lang="ts">
import UpdateDomain from '@/ui/modules/task/detail/UpdateDomain.vue';
import { computed, ref } from 'vue';

const props = defineProps<{
  taskDetail: any;
  isUpdated: boolean;
  isUpdateDomainOnly?: boolean;
}>();

const emit = defineEmits(['cancel', 'save']);

const updatedDomain = ref({
  domainId: props.taskDetail?.domainId,
  domainName: props.taskDetail?.domainName,
  projectId: props.taskDetail?.projectId,
  projectName: props.taskDetail?.projectName,
  subprojectId: props.taskDetail?.subprojectId,
  subprojectName: props.taskDetail?.subprojectName,
  assigneeId: props.taskDetail?.assigneeId,
  groupId: props.taskDetail?.groupId,
} as any);
const isLoading = computed(() => props.isUpdated);

const onSaveDomain = (domainData) => {
  updatedDomain.value = domainData;
};
const onSaveChange = () => {
  emit('save', updatedDomain.value);
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-1/3"
    disable-click-outside
    is-hidden-close
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    @cancel="$emit('cancel')"
    @confirm="onSaveChange"
  >
    <template #header>
      <div class="font-bold text-current-800">
        {{ $t('TASK_PLEASE_SELECT_A_DOMAIN') || 'Please select a domain' }}
      </div>
    </template>
    <template #body>
      <UpdateDomain
        :task-detail="taskDetail"
        :is-update-domain-only="isUpdateDomainOnly"
        @save="onSaveDomain"
      />
    </template>
    <template #footer>
      <div
        class="
          p-4
          flex
          justify-end
          items-center
          hover:text-current
          cursor-pointer
          space-x-2
          vig-modal-footer
        "
      >
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="$emit('cancel')"
        />
        <SynButton
          :disabled="isLoading || !updatedDomain || !updatedDomain?.domainId"
          :label="$t('COMMON_LABEL_SAVE')"
          @click="onSaveChange"
        />
      </div>
    </template>
  </SynModal>
</template>
