<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';
import paymentStore from '@/store/payment';
import planStore from '@/store/plans';
import { IOrgCurrentPlan } from '@/application/types/plan.types';
import { diff } from '@/ui/hooks/hook-date';
import { EPlanAction } from '@/application/constants/plan.const';
import { EPlanId } from '@/application/constants/plan.const';

const _paymentStore = paymentStore();
const _planStore = planStore();

const isShowCurrentPlanInformation = computed<boolean>(
  () => _paymentStore.isShowCurrentPlanGlobalModal
);
const isCreatingPaymentToken = computed<boolean>(
  () => _paymentStore.isCreatingPaymentToken
);
const currentOrgPlan = computed<IOrgCurrentPlan>(
  () => _planStore.currentOrgPlan
);
const styleByPlanId = computed<{
  header: string;
  body: string;
  iconName: string;
}>(() => {
  if (currentOrgPlan.value?.planId == EPlanId.VIP)
    return {
      body: 'border-x border-b border-current-200',
      header: 'border-x border-t border-current-200 bg-current-400 text-white',
      iconName: 'PlanEfficient',
    };

  return {
    body: 'border-x border-b border-current-100',
    header: 'bg-current-350 text-white border-x border-t border-current-100',
    iconName: 'PlanEfficient',
  };
});

const onPurchase = async () => {
  const payload = _paymentStore.paymentPayload;
  await _paymentStore.createPaymentToken(
    payload || {
      planId: currentOrgPlan.value?.planId,
      purpose: EPlanAction.EXTEND,
      period: 3,
      totalUsers: currentOrgPlan.value?.members,
    }
  );

  onClose();
};

const onClose = () => {
  _paymentStore.isShowCurrentPlanGlobalModal = false;
};
</script>
<template>
  <SynModal
    v-if="isShowCurrentPlanInformation"
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    z-index="z-50"
    container-class="w-max"
    @cancel="isShowCurrentPlanInformation = false"
  >
    <template #body>
      <div class="flex-center flex-col space-y-8">
        <div class="flex-center w-full p-4" :class="styleByPlanId.header">
          <SynIcon :name="styleByPlanId?.iconName" custom-class="w-24 h-24" />
        </div>
        <div class="flex flex-col text-center space-y-4 px-8">
          <span
            class="text-lg font-semibold text-gray-600 uppercase"
            v-html="
              $t('PAYMENT_YOUR_CURRENT_PACKAGE_INFORMATION', {
                planId: currentOrgPlan?.planId,
              })
            "
          >
          </span>
          <span
            :class="
              diff(currentOrgPlan?.endDate, new Date(), 'day') > 0
                ? 'text-gray-500'
                : 'text-red-500'
            "
            v-html="
              diff(currentOrgPlan?.endDate, new Date(), 'day') > 0
                ? $t('PAYMENT_YOUR_CURRENT_PACKAGE_INFORMATION_SUB', {
                    expriredDate: dayjs(currentOrgPlan?.endDate).format(
                      'MMM DD, YYYY'
                    ),
                    remainDays: dayjs(currentOrgPlan?.endDate).fromNow(true),
                  })
                : $t('PLAN_EXPIRE_TITLE_CONTENT', {
                    planId: '',
                  })
            "
          >
          </span>
        </div>
        <div class="w-full flex items-center justify-end space-x-2">
          <SynButton type-text @click="onClose">
            <span class="text-gray-500">
              {{ $t('PAYMENT_RENEW_LATER') || 'Later' }}
            </span>
          </SynButton>
          <SynButton
            class="bg-current"
            :is-loading="isCreatingPaymentToken"
            @click="onPurchase"
          >
            <span>
              {{ $t('PAYMENT_EXTEND') || 'Renew' }}
            </span>
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
