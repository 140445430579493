<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 8.467 8.467"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g paint-order="fill markers stroke">
        <path
          d="M7.408 4.394a.8.8 0 0 1-.795.795h-2.39c-.146 0-.145-.529 0-.53h2.39a.26.26 0 0 0 .266-.265V4.07a.259.259 0 0 0-.266-.264h-2.38c-.146 0-.145-.528 0-.529h2.38c.435 0 .795.358.795.793zM6.879 1.91a.8.8 0 0 1-.795.795h-1.86c-.147 0-.146-.529 0-.53h1.86a.26.26 0 0 0 .266-.265v-.324a.259.259 0 0 0-.266-.264h-1.85c-.147 0-.146-.529 0-.53h1.85c.435 0 .795.359.795.794zM8.202 6.879a.8.8 0 0 1-.795.795H4.223c-.146-.001-.145-.53 0-.53h3.184a.26.26 0 0 0 .266-.265v-.325a.259.259 0 0 0-.266-.263H4.233c-.146 0-.145-.53 0-.53h3.174c.435 0 .795.358.795.793z"
          data-original="#000000"
          class=""
        ></path>
        <path
          d="M2.166 4.394a.8.8 0 0 0 .795.795h1.282c.146 0 .145-.529 0-.53H2.96a.26.26 0 0 1-.266-.265V4.07c0-.151.115-.264.266-.264h1.272c.146 0 .145-.528 0-.529H2.961a.798.798 0 0 0-.795.793zM.264 1.91a.8.8 0 0 0 .795.795h3.184c.146 0 .145-.529 0-.53H1.059a.26.26 0 0 1-.265-.265v-.324c0-.151.114-.264.265-.264h3.174c.146 0 .145-.529 0-.53H1.06a.798.798 0 0 0-.795.794zM.793 6.879a.8.8 0 0 0 .795.795h2.655c.146-.001.145-.53 0-.53H1.588a.26.26 0 0 1-.265-.265v-.325c0-.15.114-.263.265-.263h2.645c.146 0 .145-.53 0-.53H1.588a.798.798 0 0 0-.795.793z"
          data-original="#000000"
          class=""
        ></path>
        <path
          d="M4.499.529a.265.265 0 0 0-.264-.264.265.265 0 0 0-.266.264v7.408a.265.265 0 0 0 .266.265.265.265 0 0 0 .264-.265z"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
