<script setup lang="ts">
import { computed } from 'vue';
import AtomSwiperSlide from '@/ui/common/molecules/Slider/AtomSwiperSlide.vue';
// import VideoPlayerItem from '@/ui/components/contact-help/helps/VideoPlayerItem.vue';
import VigImage from '@/ui/common/atoms/VigImage/VigImage.vue';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const props = defineProps({
  sizePanel: {
    type: Object,
    default: () => {},
  },
  allVideo: {
    type: Object,
    default: () => {},
  },
  isSlide: {
    type: Boolean,
    default: false,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
});
defineEmits(['onShowMiniPlayer']);

const playlists = computed(() => props.allVideo);
const currentLanguage = getLocalStorage(StorageConstant.LANGUAGE);
</script>

<template>
  <div class="flex flex-col">
    <div class="relative hover-to-show__parent">
      <div v-if="!isSlide" :class="`grid grid-cols-${sizePanel.cols} gap-2`">
        <template v-for="video in playlists" :key="video">
          <div
            v-if="video[currentLanguage]?.url"
            class="
              rounded
              flex flex-col
              cursor-pointer
              p-1
              hover:bg-white hover:shadow-md hover:text-current-600
            "
            @click="$emit('onShowMiniPlayer', video)"
          >
            <div class="relative">
              <div
                class="absolute cursor-pointer z-40 cursor-pointer"
                style="transform: translate(-50%, -50%); top: 50%; left: 50%"
                @click="$emit('onShowMiniPlayer', video)"
              >
                <div
                  class="
                    w-10
                    h-10
                    flex-center
                    cursor-pointer
                    rounded-full
                    flex-center
                    hover:fill-current-300
                    fill-white
                    hover:bg-opacity-50 hover:bg-white
                  "
                >
                  <syn-icon name="play" />
                </div>
              </div>
              <div
                v-if="!video[currentLanguage]?.thumbnailUrl"
                class="
                  rounded-md
                  flex-center flex-col
                  p-3
                  bg-gradient-to-r
                  from-teal-200
                  to-yellow-100
                  shadow
                "
                :style="
                  sizePanel?.key === 'SMALL'
                    ? 'height: 6rem'
                    : sizePanel?.key === 'MEDIUM'
                    ? 'height: 6.5rem'
                    : 'height: 8.5rem'
                "
              >
                <SynIcon name="tictop-logo-label" custom-class="h-4" />
                <span
                  class="
                    text-center text-overflow-hidden text-xs
                    pt-1
                    font-bold
                    text-white
                  "
                  >{{ video[currentLanguage]?.name || 'Video Name' }}</span
                >
              </div>
              <VigImage
                v-else
                custom-class="rounded-md w-full h-auto shadow"
                :style="
                  sizePanel?.key === 'SMALL'
                    ? 'height: 6rem'
                    : sizePanel?.key === 'MEDIUM'
                    ? 'height: 6.5rem'
                    : 'height: 8.5rem'
                "
                :path="video[currentLanguage]?.thumbnailUrl"
                @click="$emit('onShowMiniPlayer', video)"
              />
            </div>
            <span
              v-vig-tooltip="video[currentLanguage].name"
              class="text-xs font-medium truncate px-2 py-1 text-center"
            >
              {{ video[currentLanguage]?.name || 'Video Name' }}
            </span>
          </div>
        </template>
      </div>

      <AtomSwiperSlide
        v-else
        :slides-per-view="sizePanel.slidesPerView || 2"
        :space-between="10"
        :autoplay="autoplay"
        :is-show-pagination="false"
      >
        <template v-for="video in playlists" :key="video">
          <div
            v-if="video[currentLanguage]?.url"
            class="swiper-slide rounded relative hover-to-show__parent h-full"
          >
            <div
              class="relative w-full"
              :style="
                sizePanel?.key === 'SMALL'
                  ? 'height: 7.5rem'
                  : sizePanel?.key === 'MEDIUM'
                  ? 'height: 8.5rem'
                  : 'height: 9.5rem'
              "
            >
              <div
                class="absolute cursor-pointer z-40 cursor-pointer"
                style="transform: translate(-50%, -50%); top: 50%; left: 50%"
                @click="$emit('onShowMiniPlayer', video)"
              >
                <div
                  class="
                    w-10
                    h-10
                    flex-center
                    cursor-pointer
                    rounded-full
                    flex-center
                    hover:fill-current-300
                    fill-white
                    hover:bg-opacity-50 hover:bg-white
                  "
                >
                  <syn-icon name="play" />
                </div>
              </div>
              <div
                v-if="!video[currentLanguage]?.thumbnailUrl"
                class="rounded-md flex-center flex-col relative w-full h-full"
              >
                <div
                  class="
                    rounded-md
                    relative
                    flex-center flex-col
                    shadow-md
                    p-3
                    bg-gradient-to-r
                    from-teal-200
                    to-yellow-100
                    w-full
                  "
                  :style="
                    sizePanel?.key === 'SMALL'
                      ? 'height: 7.5rem'
                      : sizePanel?.key === 'MEDIUM'
                      ? 'height: 8.5rem'
                      : 'height: 9.5rem'
                  "
                >
                  <SynIcon name="tictop-logo-label" custom-class="h-4" />
                  <span
                    class="
                      text-center text-overflow-hidden text-xs
                      pt-1
                      font-bold
                      text-white
                    "
                    >{{ video[currentLanguage]?.name || 'Video Name' }}</span
                  >
                </div>
              </div>
              <div v-else class="rounded-md flex-center flex-col relative">
                <VigImage
                  custom-class="rounded w-full shadow-md"
                  :style="
                    sizePanel?.key === 'SMALL'
                      ? 'height: 7.5rem'
                      : sizePanel?.key === 'MEDIUM'
                      ? 'height: 8.5rem'
                      : 'height: 9.5rem'
                  "
                  :path="video[currentLanguage]?.thumbnailUrl"
                  @click="$emit('onShowMiniPlayer', video)"
                />
              </div>
            </div>
            <div
              v-vig-tooltip="video[currentLanguage].name"
              class="text-center text-xs p-1 truncate text-gray-700"
            >
              {{ video[currentLanguage].name || 'Video Name' }}
            </div>
          </div>
          <!--          <VideoPlayerItem-->
          <!--              :title="video.name"-->
          <!--              :thumbnail="video.thumbnails"-->
          <!--              class="swiper-slide bg-current-800 rounded"-->
          <!--              :src-video="video.url"-->
          <!--              @on-pinned-video="$emit('onShowMiniPlayer', video)"-->
          <!--          />-->
        </template>
      </AtomSwiperSlide>
    </div>
  </div>
</template>
