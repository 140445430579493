<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import OrganizationsNotifList from '@/ui/modules/organization/modal-organizations-notification/orgs-list/OrganizationsNotifList.vue';
import VigTabs from '@/ui/common/atoms/VigTabs/VigTabs.vue';
import TabOrgMessages from '@/ui/modules/organization/modal-organizations-notification/orgs-tabs/tab-messages/TabOrgMessages.vue';
import TabOrgNotifications from '@/ui/modules/organization/modal-organizations-notification/orgs-tabs/tab-notifications/TabOrgNotifications.vue';
import { orgNotifGlobalStore } from '@/store/organization/org-notif-global';
import organizationStore from '@/store/organization';
import ChatOverviewSlide from '@/ui/modules/messaging/chat-introduce/ChatOverviewSlide.vue';

defineEmits(['onClose']);

const selectedOrganization = ref<any>();
const tabs = ref<any[]>([
  { id: 'ORG-MESSAGES', name: 'CHAT_MESSAGES' },
  { id: 'ORG-NOTIFICATIONS', name: 'NOTIFICATION_SETTING_LABEL' },
]);
const activeTab = ref<any>(tabs.value[0]);

const organizationByIds = computed(() => organizationStore().organizationByIds);

const notifCountByTab = computed<any>(() => {
  const orgData = organizationByIds.value
    ? organizationByIds.value[selectedOrganization.value?.id]
    : null;

  return {
    'ORG-MESSAGES': orgData?.unSeenConversationsCount,
    'ORG-NOTIFICATIONS': orgData?.notificationCount,
  };
});

watch(
  () => selectedOrganization.value?.id,
  (organizationId, oldOrganizationId) => {
    _clearData(oldOrganizationId);
    _initData(organizationId);
  }
);

onMounted(async () => {
  _initData(selectedOrganization.value?.id);
});

const _initData = (orgId) => {
  if (!orgId) return;

  const orgStore = orgNotifGlobalStore(orgId);

  orgStore.getOrgUsers();
  orgStore.getOrgDeactivatedUsers();
  orgStore.getAllGroupsByUser();
};

const _clearData = (orgId) => {
  if (!orgId) return;

  orgNotifGlobalStore(orgId).clearData();
};
</script>
<template>
  <SynModal
    z-index="z-50"
    disable-click-outside
    is-hidden-footer
    container-class="h-[90%] w-11/12 max-w-screen-2xl"
    container-style=""
    style-body="p-0"
    @cancel="$emit('onClose')"
  >
    <template #header> {{ $t('GLOBAL_MESSAGE_ACTIVITY') }}</template>
    <template #body>
      <div class="h-full flex-1 overflow-hidden flex">
        <!--LEFT PANEL-->
        <div class="max-h-full w-1/5 min-w-[12rem]">
          <!--ORGANIZATIONS LIST-->
          <OrganizationsNotifList
            v-model:selected-organization="selectedOrganization"
          />

          <!--NOTIFICATION SETTINGS-->
          <!--          <div
            v-if="false"
            role="button"
            class="flex items-center gap-3 p-3 hover:bg-gray-50"
          >
            <SynIcon name="Settings" class="fill-gray-700" />
            <span class="text-sm text-gray-700 font-semibold">
              {{ 'Notification settings' }}</span
            >
          </div>-->
        </div>

        <!--DETAIL PANEL-->
        <div
          v-if="selectedOrganization"
          class="flex-1 overflow-hidden flex flex-col"
        >
          <!--TABS-->
          <VigTabs
            v-model:active-tab="activeTab"
            :tabs="tabs"
            class="px-2 border-b"
          >
            <template #default="{ name, id }">
              <span>{{ $t(name) }}</span>
              <span
                v-if="notifCountByTab[id]"
                class="
                  absolute
                  top-1
                  -right-0.5
                  px-1
                  rounded-full
                  bg-red-500
                  text-white text-[10px]
                "
              >
                {{ notifCountByTab[id] }}</span
              >
            </template>
          </VigTabs>

          <!--TAB CONTENT-->
          <div class="flex-1 overflow-hidden">
            <TabOrgMessages
              v-if="activeTab?.id === 'ORG-MESSAGES'"
              :organization="selectedOrganization"
            />
            <TabOrgNotifications
              v-if="activeTab?.id === 'ORG-NOTIFICATIONS'"
              :organization="selectedOrganization"
            />
          </div>
        </div>

        <div v-else class="flex-1 overflow-hidden flex-center flex-col">
          <ChatOverviewSlide />
        </div>
      </div>
    </template>
  </SynModal>
</template>
