<template>
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 512.002 512.002"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path-->
  <!--        d="M256.001 77.017c-107.656 0-195.244 87.589-195.244 195.244 0 107.662 87.589 195.25 195.244 195.25 107.662 0 195.244-87.589 195.244-195.25 0-107.655-87.588-195.244-195.244-195.244zm0 355.109c-88.143 0-159.853-71.715-159.853-159.858s71.709-159.853 159.853-159.853 159.853 71.71 159.853 159.853c0 88.148-71.71 159.858-159.853 159.858z"-->
  <!--        opacity="1"-->
  <!--      ></path>-->
  <!--      <path-->
  <!--        d="M310.268 266.363H263.08v-68.424c0-9.774-7.922-17.696-17.696-17.696-9.774 0-17.696 7.922-17.696 17.696v86.12c0 9.774 7.922 17.696 17.696 17.696h64.885c9.774 0 17.696-7.922 17.696-17.696-.001-9.774-7.923-17.696-17.697-17.696zM155.766 398.911c-7.267-6.542-18.457-5.946-24.992 1.315l-53.088 58.986c-6.542 7.261-5.946 18.451 1.315 24.987a17.603 17.603 0 0 0 11.833 4.548c4.843 0 9.668-1.976 13.16-5.863l53.088-58.986c6.541-7.262 5.945-18.452-1.316-24.987zM434.322 459.218l-53.088-58.986c-6.524-7.267-17.719-7.857-24.987-1.315-7.267 6.536-7.851 17.725-1.315 24.987l53.088 58.986c3.486 3.881 8.311 5.857 13.154 5.857 4.212 0 8.447-1.498 11.833-4.542 7.267-6.536 7.851-17.726 1.315-24.987zM152.764 49.046c-35.162-34.43-91.841-34.377-126.342.13C9.256 66.335-.123 89.039.001 113.105c.13 23.777 9.556 46.039 26.55 62.685a17.65 17.65 0 0 0 12.381 5.049c4.53 0 9.06-1.734 12.511-5.179L152.899 74.204a17.71 17.71 0 0 0 5.179-12.605 17.686 17.686 0 0 0-5.314-12.553zM40.873 136.174c-3.545-7.143-5.439-15.047-5.48-23.258-.077-14.534 5.621-28.29 16.05-38.719 10.405-10.399 24.161-15.596 37.993-15.596a55.265 55.265 0 0 1 23.683 5.326l-72.246 72.247zM485.581 49.17c-34.507-34.501-91.187-34.56-126.348-.13a17.713 17.713 0 0 0-5.315 12.552 17.704 17.704 0 0 0 5.185 12.605l101.456 101.456a17.622 17.622 0 0 0 12.511 5.185 17.6 17.6 0 0 0 12.381-5.061c17-16.64 26.426-38.901 26.55-62.679.124-24.059-9.261-46.763-26.42-63.928zm-14.451 87.004-72.246-72.246c20.279-9.627 45.189-6.211 61.676 10.275 10.429 10.429 16.127 24.178 16.05 38.719-.042 8.205-1.935 16.115-5.48 23.252z"-->
  <!--        opacity="1"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->

  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 35 35"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m7.058 33.06 2.784-2.784a13.911 13.911 0 0 0 7.658 2.295 13.91 13.91 0 0 0 7.655-2.293l2.782 2.782a1.5 1.5 0 1 0 2.121-2.12l-2.573-2.574A13.948 13.948 0 0 0 31.5 18.57c0-7.72-6.28-14-14-14s-14 6.28-14 14c0 3.81 1.534 7.267 4.012 9.794l-2.575 2.575a1.5 1.5 0 1 0 2.121 2.121zM16 13.01c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v3.9l4.04 4.04c.58.59.58 1.54 0 2.12-.557.576-1.541.6-2.12 0l-4.48-4.48c-.28-.28-.44-.66-.44-1.06z"
        opacity="1"
      ></path>
      <path
        d="M31.77 12.52a6.558 6.558 0 0 0 1.73-4.43c0-3.62-2.95-6.58-6.58-6.58-1.95 0-3.73.84-4.94 2.23 4.42 1.33 8.01 4.58 9.79 8.78zM13.14 3.7a6.676 6.676 0 0 0-4.96-2.2c-3.68 0-6.68 3-6.68 6.68 0 1.66.63 3.23 1.69 4.43 1.79-4.28 5.44-7.59 9.95-8.91z"
        opacity="1"
      ></path>
    </g>
  </svg>
</template>
