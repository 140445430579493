<script setup lang="ts">
import { ref, watch } from 'vue';
import userStore from '@/store/user';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import ScopeTree from '@/ui/components/scope/ScopeTree.vue';

const props = defineProps<{
  userId?: number | string;
}>();
const _userStore = userStore();
const domains = ref([] as any[]);
const isLoading = ref(false);
const init = async (userId) => {
  isLoading.value = true;
  domains.value = await _userStore.getUserDomainListById(
    userId || getCurrentUserId()
  );
  isLoading.value = false;
};

watch(
  () => props.userId,
  (userId) => {
    console.log(
      '🚀 ~ file: FormScopeUser.vue ~ line 24 ~ props.userId',
      userId
    );
    init(userId);
  }
);

init(props.userId);
</script>
<template>
  <div class="flex bg-current-50 mx-2 py-3 pl-4 rounded-t-md justify-between">
    <p class="font-semibold text-current-500">
      {{ $t('USERMANAGEMENT_LABEL_DOMAIN') }}
    </p>
  </div>

  <div class="mx-2 p-1 text-sm bg-white overflow-y-auto small-scrollbar h-full">
    <div v-if="isLoading" class="flex-center space-x-3 mx-4 mt-8">
      <div class="w-8 p-2 bg-gray-200 animate-pulse rounded-lg"></div>
      <div class="w-full p-2 bg-gray-200 animate-pulse rounded-lg"></div>
    </div>
    <!-- add domain -->
    <div v-else>
      <div v-if="domains.find((el) => el.checked)">
        <scope-tree :domains="domains" is-disabled is-show-project>
        </scope-tree>
      </div>
      <div v-else>
        <syn-animation
          name="searchNotFound"
          stype="width: 100px; height: 100px"
          :label="$t('PROFILE_ALERT_NO_DOMAIN')"
        />
      </div>
    </div>
  </div>
</template>
