<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="32"
    viewBox="0 0 22 32"
  >
    <g id="tictop-mobile" transform="translate(-5)">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M26,22a1,1,0,0,0,1-1V5a5,5,0,0,0-5-5H10A5,5,0,0,0,5,5V27a5,5,0,0,0,5,5H22a5,5,0,0,0,5-5V25a1,1,0,0,0-2,0v2a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V5A3,3,0,0,1,9,2.18v.63A3.19,3.19,0,0,0,12.19,6h7.62A3.19,3.19,0,0,0,23,2.81V2.18A3,3,0,0,1,25,5V21A1,1,0,0,0,26,22ZM21,2.81A1.19,1.19,0,0,1,19.81,4H12.19A1.19,1.19,0,0,1,11,2.81V2H21Z"
        fill="#222"
      />
      <g id="prod_logo" transform="translate(10 10)">
        <path
          id="Path_1-2"
          data-name="Path 1"
          d="M254.658,252.774v4.808a4.808,4.808,0,0,1-4.808,4.808h0v-9.617a2.4,2.4,0,0,1,2.4-2.4h0A2.4,2.4,0,0,1,254.658,252.774Z"
          transform="translate(-249.85 -250.37)"
          fill="#008e8e"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M251.525,250.475a2.4,2.4,0,0,0-1.675,2.292v.313a2.4,2.4,0,0,0,2.384,2.1h2.424v-2.411a2.4,2.4,0,0,0-2.238-2.4h-.187A2.407,2.407,0,0,0,251.525,250.475Z"
          transform="translate(-249.85 -250.37)"
          fill="#f2cb05"
          opacity="0.63"
        />
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="2.003"
          cy="2.003"
          r="2.003"
          transform="translate(0.401 0.393)"
          fill="#005167"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M465.016,386.088h0a.533.533,0,0,1,.727-.195l.46.266a.532.532,0,0,1,.2.727h0a.532.532,0,0,1-.727.194l-.46-.266A.532.532,0,0,1,465.016,386.088Z"
          transform="translate(-456.326 -380.395)"
          fill="#8fcdcd"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M428.517,429.937h0a.532.532,0,0,1,.752,0l.376.376a.532.532,0,0,1,0,.753h0a.532.532,0,0,1-.752,0l-.376-.376A.532.532,0,0,1,428.517,429.937Z"
          transform="translate(-421.208 -422.592)"
          fill="#61b9b9"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M384.493,467.651h0a.532.532,0,0,1-.727-.194h0L383.5,467a.532.532,0,1,1,.922-.532l.266.461A.532.532,0,0,1,384.493,467.651Z"
          transform="translate(-378.076 -457.55)"
          fill="#008e8e"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M314,250.37a2.4,2.4,0,0,1,2.237,2.4v2.411h2.384a4.808,4.808,0,0,0,4.808-4.808Z"
          transform="translate(-311.43 -250.37)"
          fill="#f2cb05"
        />
      </g>
    </g>
  </svg>
</template>
