import { ETransactionMode } from '@/indexedDB';
import tictopIndexDB from '@/indexedDB';
import { cloneDeep } from '@/ui/hooks/commonFunction';

export default class IdbBase {
    currentObjectStoreName: string;
    constructor(objectStoreName) {
        this.currentObjectStoreName = objectStoreName;
    }
    async getAll(): Promise<any[]> {
        const { store } = await this._getTransactionByMode(
            ETransactionMode.readonly
        );
        const request = store.getAll();

        return new Promise((resolve) => {
            request.onsuccess = () => {
                resolve(request?.result);
            };

            request.onerror = () => {
                resolve([]);
            };
        });
    }

    async getDetailByKeyPath(keyPath) {
        const { store } = await this._getTransactionByMode(
            ETransactionMode.readonly
        );
        const request = store.get(`${keyPath}`);

        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                resolve(request?.result);
            };

            request.onerror = () => {
                reject(`can't find data by owner id: ${keyPath}`);
            };
        });
    }

    async put(data) {
        console.log('🚀 Tictop ~ data:', data);
        const { transaction, store } = await this._getTransactionByMode(
            ETransactionMode.readwrite
        );

        store.put(cloneDeep(data));

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve('success');
            };

            transaction.onerror = () => {
                reject('error');
            };
        });
    }
    async remove(keyPath) {
        const { transaction, store } = await this._getTransactionByMode(
            ETransactionMode.readwrite
        );

        store.delete(keyPath);

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve('success');
            };

            transaction.onerror = () => {
                reject('error');
            };
        });
    }

    async _getTransactionByMode(transactionMode: ETransactionMode) {
        const database = await tictopIndexDB.getInstance();
        const transaction = database.transaction(
            this.currentObjectStoreName,
            transactionMode
        );

        return {
            transaction: transaction,
            store: transaction.objectStore(this.currentObjectStoreName),
        };
    }
}
