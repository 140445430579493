<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
// import { getCurrentOrganizationId } from '@/ui/hooks/storageHook';
// import SwitchToOrg from '@/ui/modules/organization/modal-organizations-notification/switch-to-org/SwitchToOrg.vue';
import NotifTaskDetail from '@/ui/modules/task/detail/notif-task-detail/NotifTaskDetail.vue';
import {
  NotificationCategory,
  NotificationType,
} from '@/domain/enums/notification-enums';
import CommonService from '@/application/services/CommonService';
import NotificationService from '@/application/services/NotificationService';
import { orgNotifGlobalStore } from '@/store/organization/org-notif-global';
import NotificationDetail from '@/ui/modules/notification/notification-detail/NotificationDetail.vue';
import myProfileStore from '@/store/auth/my-profile';
import NotificationListHeader from '@/ui/modules/notification/notification-list/NotificationListHeader.vue';
import NotificationList from '@/ui/modules/notification/notification-list/NotificationList.vue';

const props = defineProps<{
  organization: any;
}>();

const _notifService = NotificationService.getInstance();

const pageSize = 15;
// const currentOrgId = getCurrentOrganizationId();

const myUserId = computed(() => myProfileStore().myProfile?.id);
const selectedCategory = ref<NotificationCategory>(NotificationCategory.ALL);
const selectedNotification = ref<any>();
const isLoading = ref<boolean>();
const canLoadMore = ref<boolean>();
const selectedTaskId = ref<number>();

const isPersonalModel = computed<boolean>(
  () => props.organization?.model === 'PERSONAL'
);

const notifications = computed(
  () => orgNotifGlobalStore(props.organization?.id).notifications
);

const latestNotification = computed(
  () => orgNotifGlobalStore(props.organization?.id).latestNotification
);

const unreadCategories = computed(
  () => orgNotifGlobalStore(props.organization?.id).notifUnreadCategories
);

const userSettings = computed(
  () => orgNotifGlobalStore(props.organization?.id).userSettings
);

const orgUsers = computed(
  () => orgNotifGlobalStore(props.organization?.id).allOrgUsers
);

const orgGroups = computed(
  () => orgNotifGlobalStore(props.organization?.id).allOrgGroups
);

const orgDomains = computed(
  () => orgNotifGlobalStore(props.organization?.id).orgDomains
);

const orgProjects = computed(
  () => orgNotifGlobalStore(props.organization?.id).orgProjects
);

watch(
  () => props.organization?.id,
  (orgId, oldOrgId) => {
    _clearData(oldOrgId);
    _initData(orgId);
  }
);

watch(
  () => notifications.value,
  (notifications) => {
    // Reload selected notification value
    if (selectedNotification.value) {
      selectedNotification.value = notifications?.find(
        (notif) => notif?.id === selectedNotification.value?.id
      );

      selectedTaskId.value =
        selectedNotification.value?.type === NotificationType.TASK
          ? selectedNotification.value?.mainDataId
          : 0;
    }
  },
  { deep: true }
);

onMounted(async () => {
  _initData(props.organization?.id);
});

const onSelectedCategoryChange = () => {
  _getNotifications(props.organization?.id);
};

const onShowUnreadChange = async (data) => {
  const organizationId = props.organization?.id;

  await CommonService.getInstance().updateOrgCurrentUserSetting(
    organizationId,
    {
      ...data,
      organizationId,
    }
  );

  if (organizationId !== props.organization?.id) return;

  orgNotifGlobalStore(organizationId).getOrgUserSettings();
  _getNotifications(organizationId);
};

const onReadAllClick = async (category) => {
  const organizationId = props.organization?.id;

  await _notifService.updateOrgUserNotifications(organizationId, {
    organizationId,
    category,
    selectAll: true,
    status: true,
  });

  if (organizationId !== props.organization?.id) return;

  _getNotifications(organizationId);
  orgNotifGlobalStore(organizationId).getNotifUnreadInfo();
};

const onNotifReadClick = async (notificationId) => {
  const organizationId = props.organization?.id;

  await _notifService.updateOrgUserNotifications(organizationId, {
    organizationId,
    notificationId,
    selectAll: false,
    status: true,
  });

  if (organizationId !== props.organization?.id) return;

  const orgNotifStore = orgNotifGlobalStore(organizationId);
  orgNotifStore.updateLocalNotification(notificationId, { status: true });
  orgNotifStore.getNotifUnreadInfo();
};

const onNotifUnreadClick = async (notificationId) => {
  const organizationId = props.organization?.id;

  await _notifService.updateOrgUserNotifications(organizationId, {
    organizationId,
    notificationId,
    selectAll: false,
    status: false,
  });

  if (organizationId !== props.organization?.id) return;

  const orgNotifStore = orgNotifGlobalStore(organizationId);
  orgNotifStore.updateLocalNotification(notificationId, { status: false });
  orgNotifStore.getNotifUnreadInfo();
};

const onNotifDeleteClick = async (notificationId) => {
  const organizationId = props.organization?.id;

  await _notifService.deleteOrgNotification(organizationId, [notificationId]);

  if (organizationId !== props.organization?.id) return;

  const orgNotifStore = orgNotifGlobalStore(organizationId);
  orgNotifStore.deleteLocalNotification(notificationId);
  orgNotifStore.getNotifUnreadInfo();
};

const onNotifClick = (notif) => {
  selectedNotification.value = notif;

  selectedTaskId.value =
    selectedNotification.value?.type === NotificationType.TASK
      ? selectedNotification.value?.mainDataId
      : 0;
};

// const onNotifTaskClick = (notif) => {
//   selectedNotification.value = notif;

//   selectedTaskId.value = notif?.mainData?.taskId;
// };

const _initData = (orgId) => {
  _getNotifications(orgId).then(() => {
    // Reset user notifications
    _notifService.resetUserTotalNotifications(orgId, myUserId.value);
  });

  const orgNotifStore = orgNotifGlobalStore(orgId);
  orgNotifStore.getNotifUnreadInfo();
  orgNotifStore.getOrgUserSettings();
};

const _clearData = (orgId) => {
  selectedNotification.value = null;
  selectedTaskId.value = 0;

  orgNotifGlobalStore(orgId).clearNotifData();
};

const isLoadingMore = ref<boolean>(false);
const _getNotifications = async (orgId, isLoadMore = false) => {
  if (isLoadMore && !canLoadMore.value) return;

  if (!isLoadMore) isLoading.value = true;

  isLoadingMore.value = true;
  try {
    const result = await orgNotifGlobalStore(orgId).getOrgUserNotifications(
      selectedCategory.value,
      pageSize,
      isLoadMore
    );

    canLoadMore.value = result?.canLoadMore;

    if (
      canLoadMore.value &&
      result?.notifications?.length > 0 &&
      notifications.value?.length < 10
    )
      loadMoreNotification();
  } catch (e) {
    console.log(e);
  }

  isLoading.value = false;
  isLoadingMore.value = false;
};

const loadMoreNotification = async () => {
  if (unreadCategories.value[selectedCategory.value] == 0) return;

  try {
    isLoadingMore.value = true;
    const organizationId = props.organization?.id;
    const result = await orgNotifGlobalStore(
      organizationId
    ).getOrgUserNotifications(selectedCategory.value, pageSize, true);

    canLoadMore.value = result?.canLoadMore;
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isLoadingMore.value = false;
  }
};
</script>

<template>
  <div class="h-full flex">
    <!--NOTIFICATIONS LIST-->
    <div class="h-full overflow-hidden flex flex-col" style="width: 27rem">
      <NotificationListHeader
        v-model:selectedCategory="selectedCategory"
        :unread-categories="unreadCategories"
        :user-settings="userSettings"
        @update:selected-category="onSelectedCategoryChange"
        @on-show-unread-change="onShowUnreadChange"
        @on-read-all-click="onReadAllClick"
      />

      <div class="flex-1 min-h-0 overflow-hidden flex flex-col">
        <NotificationList
          :all-notifications="notifications"
          :latest-notification="latestNotification"
          :is-loading="isLoading"
          :is-loading-more="isLoadingMore"
          :category="selectedCategory"
          is-hidden-action-user
          @on-load-more="loadMoreNotification"
          @on-click-notification="onNotifClick"
          @on-mark-read-notification="onNotifReadClick"
          @on-delete-notification="onNotifDeleteClick"
        />
      </div>
    </div>

    <!--NOTIFICATION DETAIL-->
    <div class="h-full flex-1 flex flex-col overflow-hidden border-l">
      <!--      <SwitchToOrg-->
      <!--        v-if="organization?.id !== currentOrgId"-->
      <!--        :organization="organization"-->
      <!--      />-->

      <div class="flex-1 overflow-hidden">
        <NotificationDetail
          v-if="selectedNotification && !selectedTaskId"
          :notification="selectedNotification"
          @on-mark-read-click="onNotifReadClick(selectedNotification?.id)"
          @on-mark-unread-click="onNotifUnreadClick(selectedNotification?.id)"
          @on-delete-click="onNotifDeleteClick(selectedNotification?.id)"
        />

        <NotifTaskDetail
          v-if="selectedTaskId"
          :organization-id="organization?.id"
          :task-id="selectedTaskId"
          :users="orgUsers"
          :groups="orgGroups"
          :domains="orgDomains"
          :projects="orgProjects"
          :is-org-personal="isPersonalModel"
        />
      </div>
    </div>
  </div>
</template>
