<script setup lang="ts">
import { PlanTypeEnum, TaskLife } from '@/domain/enums/taskEnum';
import { getBgClass } from '@/ui/hooks/taskHook';
import taskStore from '@/store/task';

defineProps<{
  task: any;
  taskPlan: PlanTypeEnum;
}>();

const getConfigName = (sitKey, typeKey) => {
  return taskStore().getConfigName(sitKey, typeKey);
};
</script>

<template>
  <span v-if="taskPlan == PlanTypeEnum.Nothing">
    {{ $t('COMMON_LABEL_NOTHING') }}
  </span>

  <SynTag
    v-else-if="
      taskPlan ||
      (task?.taskSchedule?.planType &&
        task?.taskSchedule?.planType !== PlanTypeEnum.Nothing &&
        task?.taskLife !== TaskLife.Finished)
    "
    size="small"
    :class="getBgClass(taskPlan || task?.taskSchedule?.planType, 'planType')"
  >
    {{ getConfigName(taskPlan || task?.taskSchedule?.planType, 'planType') }}
  </SynTag>
</template>
