<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import domainStore from '@/store/scope';
import DomainUpdateForm from '@/ui/modules/domains/domain/DomainUpdateForm.vue';
import ProjectDetailClass from '@/domain/entities/domains/ProjectDetailClass';
import EditButton from '@/ui/components/general/EditButton.vue';
import UpdateProjectMembers from '@/ui/modules/domains/component/UpdateProjectMembers.vue';
import ProjectDeleteModal from '@/ui/modules/domains/projects/ProjectDeleteModal.vue';
import { UpdateProjectInformationPayload } from '@/application/types/domain/DomainPayload';
import DomainDetailClass from '@/domain/entities/domains/DomainDetailClass';
import { arrayOrderBy, cloneDeep } from '@/ui/hooks/commonFunction';
import getUserInfo from '@/ui/helpers/user-helper';

const props = defineProps<{
  domainId: number;
  projectId: number;
  parentId: number;
}>();

const emit = defineEmits(['onDelete', 'onClose', 'onDeleteSuccess']);
const _domainStore = domainStore();
const domainByIds = computed(() => _domainStore.domainByIds);
const currrentDomainDetail = computed<DomainDetailClass>(() => {
  if (!props.domainId) return {};
  return domainByIds.value[props.domainId];
});
const anyProjectMembers = computed<any[]>(() => {
  if (!props.domainId) return [];
  return currrentDomainDetail.value?.members?.filter(
    (user) => user?.id && user.anyProjects
  );
});
const projectByIds = computed(() => _domainStore.projectByIds);
const projectDetail = computed<ProjectDetailClass>(() => {
  if (!projectByIds.value) return {};
  return projectByIds.value[props.projectId];
});

const parentMembers = computed<any[]>(() => {
  if (props.parentId) return projectByIds.value[props.parentId]?.members;

  return (domainByIds.value[props.domainId]?.members || []).map(
    (user) => user?.id
  );
});

const projectPayload = ref({
  id: props.projectId,
  parentId: props.parentId,
  domainId: props.domainId,
  name: '',
  description: '',
  members: [] as any[],
});

const initData = () => {
  projectPayload.value = {
    ...projectPayload.value,
    name: projectDetail.value?.name,
    description: projectDetail.value?.description,
    members: arrayOrderBy(
      [
        ...cloneDeep(anyProjectMembers.value),
        ...(cloneDeep(projectDetail.value?.members)
          ?.filter(
            (userId) =>
              userId &&
              !anyProjectMembers.value?.some((mem) => mem?.id == userId)
          )
          ?.map((id) => {
            return { id, anyProjects: false };
          }) || []),
      ].map((user) => {
        return {
          ...user,
          ...getUserInfo(user?.id),
        };
      }),
      ['anyProjects', (user) => user?.name?.split(' ')?.pop()],
      ['desc', 'asc']
    ),
  };
  console.log('🚀 Tictop ~ projectPayload.value:', projectPayload.value);

  updateSubDetailTabs();
};

watch(
  () => props.projectId,
  () => {
    initData();
  }
);
const projectDetailTabs = ref({
  INFORMATIONS: {
    key: 'INFORMATIONS',
    label: 'COMMON_LABEL_INFORMATION',
    isActive: true,
    isEditing: false,
  },
  MEMBERS: {
    key: 'MEMBERS',
    label: 'TASK_TABLE_LABEL_MEMBERS',
    isActive: false,
    isEditing: false,
    subContent: '',
  },
  SETTING: {
    key: 'SETTING',
    label: 'COMMON_LABEL_SETTINGS_GROUP',
    isActive: false,
  },
});

onMounted(() => {
  initData();
});
const changeTab = (key) => {
  for (const k in projectDetailTabs.value) {
    projectDetailTabs.value[k].isActive = false;
    projectDetailTabs.value[k].isEditing = false;

    if (k == key) projectDetailTabs.value[k].isActive = true;
  }
};

const isSavingInfo = ref(false);

const onSaveInfo = async () => {
  try {
    isSavingInfo.value = true;
    await _domainStore.updateProjectInformation(
      new UpdateProjectInformationPayload({
        id: props.projectId,
        name: projectPayload.value?.name,
        description: projectPayload.value?.description,
        domainId: props.domainId,
        parentId: props.parentId,
      })
    );
    isSavingInfo.value = false;
    onCancelInfo();
  } catch (error) {
    console.log('🚀 ~ onSubmitUpdate ~ error', error);
  }
};
const onSaveMembers = async () => {
  try {
    isSavingInfo.value = true;
    console.log('🚀 Tictop ~ projectPayload.value:', projectPayload.value);
    await _domainStore.updateProjectMembers(
      projectDetail.value,
      projectPayload.value?.members
    );
    isSavingInfo.value = false;
    onCancelInfo();
  } catch (error) {
    console.log('🚀 ~ onSubmitUpdate ~ error', error);
  }
};

const updateSubDetailTabs = () => {
  projectDetailTabs.value['MEMBERS'].subContent =
    projectDetail.value?.members?.length > 0
      ? `${projectDetail.value?.members?.length}`
      : '';
};
const onCancelInfo = () => {
  updateSubDetailTabs();
  projectDetailTabs.value['INFORMATIONS'].isEditing = false;
  projectDetailTabs.value['MEMBERS'].isEditing = false;
  initData();
};

const isShowRemoveModal = ref(false);
const onClickRemove = () => {
  isShowRemoveModal.value = true;
};

const onDeleteSuccess = () => {
  isShowRemoveModal.value = false;
  emit('onDeleteSuccess');
};
</script>
<template>
  <div class="flex-1 min-h-0 overflow-hidden flex flex-col">
    <div
      class="
        flex-1
        min-h-0
        overflow-hidden
        w-full
        flex flex-col
        space-y-2
        relative
      "
    >
      <SynTabs
        :option-status="projectDetailTabs"
        positon="left"
        class="overflow-hidden px-4 bg-white"
        @change-tab="changeTab"
      >
        <template #tabBody>
          <div class="py-2 flex-1 min-h-0 flex flex-col">
            <section
              v-if="projectDetailTabs['INFORMATIONS'].isActive"
              class="h-full space-y-2"
            >
              <div class="flex justify-end">
                <div class="h-8 flex items-center mx-4">
                  <EditButton
                    v-model="projectDetailTabs['INFORMATIONS'].isEditing"
                    :is-submitting="isSavingInfo"
                    :is-disable-submit="!projectPayload.name"
                    @cancel="onCancelInfo"
                    @save="onSaveInfo"
                  />
                </div>
              </div>
              <DomainUpdateForm
                v-model:name="projectPayload.name"
                v-model:description="projectPayload.description"
                :is-disabled="!projectDetailTabs['INFORMATIONS'].isEditing"
              />
            </section>

            <template v-if="projectDetailTabs['MEMBERS'].isActive">
              <template v-if="parentMembers?.length > 0">
                <div class="flex items-baseline justify-between">
                  <div
                    v-if="projectDetailTabs['MEMBERS'].isEditing"
                    class="text-sm italic pl-4"
                  >
                    {{
                      $t('DOMAIN_LABEL_ONLY_CHOOSE_USER_FROM_PARENT_MEMBERS')
                    }}
                  </div>
                  <div v-else class="text-center italic pl-4 text-sm">
                    <span v-if="projectPayload.members?.length == 0">
                      {{ $t('DOMAIN_LABEL_HAVE_NOT_ANY_MEMBERS') }}
                    </span>
                  </div>
                  <div class="h-8 flex items-center mx-4">
                    <EditButton
                      v-model="projectDetailTabs['MEMBERS'].isEditing"
                      :is-submitting="isSavingInfo"
                      @cancel="onCancelInfo"
                      @save="onSaveMembers"
                    />
                  </div>
                </div>
                <UpdateProjectMembers
                  v-model="projectPayload.members"
                  class="flex-1"
                  autofocus
                  :domain-id="domainId"
                  :parent-id="parentId"
                  :project-id="projectId"
                  :parent-members="parentMembers"
                  :is-editting="projectDetailTabs['MEMBERS'].isEditing"
                />
              </template>
              <div v-else class="italic pl-2 text-sm">
                {{ $t('DOMAIN_LABEL_NEED_TO_ADD_MORE_MEMBER_TO_PARENT_FISRT') }}
              </div>
            </template>
            <!-- SETTINGS -->
            <template v-if="projectDetailTabs['SETTING'].isActive">
              <span class="w-full text-center text-sm italic">
                <!-- SETTING DELETE -->
                <div
                  class="
                    px-4
                    py-3
                    flex
                    items-center
                    justify-end
                    bg-white
                    border-b
                    space-x-4
                  "
                >
                  <SynButton
                    class="text-sm"
                    color="red"
                    type-outline
                    @click="onClickRemove"
                  >
                    <div class="flex-center space-x-2">
                      <SynIcon
                        name="trash"
                        class="fill-red-500"
                        custom-class="w-4 h-4"
                      />
                      <span>
                        {{
                          $t('DOMAIN_LABEL_REMOVE_SUB_DOMAIN') ||
                          'Remove sub domain/project'
                        }}
                      </span>
                    </div>
                  </SynButton>
                </div>
              </span>
            </template>
          </div>
        </template>
      </SynTabs>
    </div>
  </div>
  <ProjectDeleteModal
    v-if="isShowRemoveModal"
    :domain-id="domainId"
    :project-id="projectId"
    :parent-id="parentId"
    @on-close="isShowRemoveModal = false"
    @on-delete-success="onDeleteSuccess"
  />
</template>
