<script setup lang="ts">
// *** IMPORTS ***
import { computed, onMounted, watch } from 'vue';
import { genGroupConversationId } from '@/ui/plugins/firebases/firestore-database/constants';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';
import chatStore from '@/store/chat';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import { translate } from '@/ui/plugins/i18n/myi18n';

// *** PROPS, EMITS ***
const props = defineProps<{
  currentTask: any;
  hasNewMessage?: boolean;
}>();

const emit = defineEmits([
  'onStartChat',
  'onCreateGroupChat',
  'update:hasNewMessage',
]);

// *** PRIVATE VARIABLES ***
const _chatStore = chatStore();

// *** COMPOSABLES ***
const { goToConversation } = useChatCommon();

// *** REFS ***

// *** COMPUTED ***
const groupChatConversation = computed(() => {
  if (!props.currentTask?.chatGroupId) return null;

  const converId = genGroupConversationId(props.currentTask?.chatGroupId);
  return _chatStore.conversationById[converId];
});

const hasNewMessage = computed(
  () => groupChatConversation.value && groupChatConversation.value.unSeen > 0
);

// *** WATCHES ***
watch(
  () => hasNewMessage.value,
  (val) => {
    emit('update:hasNewMessage', val);
  }
);

// *** HOOKS ***
onMounted(() => {
  emit('update:hasNewMessage', hasNewMessage.value);
});

// *** HANDLER FUNCTIONS ***
const onGroupChatAboutTaskClick = () => {
  // If existed and user is in group, open group chat
  if (props.currentTask?.chatGroupId && groupChatConversation.value) {
    goToConversation(groupChatConversation.value);

    emit('onStartChat');
  }
  // If existed but user is not member of group, warning
  else if (props.currentTask?.chatGroupId && !groupChatConversation.value) {
    settingGlobalModal({
      type: 'notification',
      title: translate('CHAT_GROUP_ABOUT_THIS_TASK_PREVENT_MSG'),
      content: translate('CHAT_GROUP_ABOUT_THIS_TASK_PREVENT_DESC'),
      confirmable: true,
    });
    ask();
  }
  // Open modal new group chat
  else {
    emit('onCreateGroupChat');
  }
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div
    v-permission-function="{
      functionCode: ALL_FUNCTIONS.TASK.GROUP_CHAT_ABOUT_TASK,
      needToShowPremiumIcon: true,
      onContinue: onGroupChatAboutTaskClick,
    }"
    @click="onGroupChatAboutTaskClick"
  >
    <slot />
  </div>
</template>

<style scoped></style>
