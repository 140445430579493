<script setup lang="ts">
import { ref } from 'vue';
import { useElementBounding } from '@vueuse/core';
import { ITaskNotificationHistory } from '@/application/types/notification.types';
import { translate } from '@/ui/plugins/i18n/myi18n';

defineProps<{
  history: ITaskNotificationHistory;
}>();

const genTextContent = (objectComment) => {
  if (objectComment?.task_comment) return objectComment?.task_comment;
  else if (
    objectComment?.task_comment_file_count > 0 &&
    objectComment?.task_comment_note_count > 0
  )
    return `${translate('TASK_ACTIVITY_LABEL_COMMENT')} ${translate(
      'ACTIVITY_HISTORY_TASK_WITH_NOTE_FILE',
      {
        totalFile: objectComment?.task_comment_file_count,
        totalNote: objectComment?.task_comment_note_count,
      }
    )}`;
  else if (
    objectComment?.task_comment_file_count > 0 &&
    objectComment?.task_comment_note_count <= 0
  )
    return `${translate('TASK_ACTIVITY_LABEL_COMMENT')} ${translate(
      'ACTIVITY_HISTORY_TASK_WITH_FILE',
      { total: objectComment?.task_comment_file_count }
    )}`;
  else if (
    objectComment?.task_comment_file_count <= 0 &&
    objectComment?.task_comment_note_count > 0
  )
    return `${translate('TASK_ACTIVITY_LABEL_COMMENT')} ${translate(
      'ACTIVITY_HISTORY_TASK_WITH_NOTE',
      { total: objectComment?.task_comment_note_count }
    )}`;
  return '';
};

const notificationTaskCommentRef = ref<any>(null);
const { width } = useElementBounding(notificationTaskCommentRef);
</script>

<template>
  <div class="w-full inline-flex lowercase">
    "
    <p
      ref="notificationTaskCommentRef"
      v-vig-tooltip="
        width >= 400
          ? history?.taskComment || genTextContent(history?.taskCommentObject)
          : ''
      "
      class="
        italic
        overflow-hidden
        text-overflow-hidden-line
        notification-task-item_comment
      "
      style="max-height: 1.5rem; word-break: break-all"
      v-html="
        history?.taskComment || genTextContent(history?.taskCommentObject)
      "
    ></p>
    "
  </div>
</template>
