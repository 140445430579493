<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import { useRoute } from 'vue-router';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';

const permission: any = inject('permission');
const route = useRoute();
const settings = [
  {
    path: 'shared-tasks',
    name: 'SharedTasksManagement',
    keyName: 'COMMON_LABEL_PUBLIC_TASKS',
    defaultName: 'Public',
    screenKey: ALL_SCREENS.COMMON.ADMIN.SETTING.ORGANIZATION,
    permissionList: [],
    children: [
      {
        path: 'shared-tasks',
        name: 'SharedTasksManagement',
        keyName: 'COMMON_LABEL_SHARED_TASKS',
        defaultName: 'Shared tasks',
        screenKey: ALL_SCREENS.COMMON.ADMIN.SETTING.ORGANIZATION,
      },
    ],
  },

  {
    path: 'workflow',
    name: 'TaskWorkflowManagement',
    keyName: 'TASK_ACTION_WORK_FLOW',
    defaultName: 'Workflow',
    permissionList: [
      {
        functionCode: 'ADVANCED_WORKFLOW_WORKFLOW_ALLOWED_BY_SYSTEM',
        functionValue: '1',
      },
      {
        functionCode: 'DRAFT_WORKFLOW_WORKFLOW_ALLOWED',
        functionValue: '1',
      },
    ],
    children: [
      {
        path: 'workflow',
        name: 'TaskWorkflowManagement',
        keyName: 'TASK_ACTION_WORK_FLOW',
        defaultName: 'Workflow',
      },
      // {
      //   path: 'workflow-steps',
      //   name: 'WorkflowStepManagement',
      //   keyName: 'WORKFLOW_LABEl_STEPS',
      //   defaultName: 'Workflow steps',
      // },
      // {
      //   path: 'workflow-step-types',
      //   name: 'WorkflowStepTypeManagement',
      //   keyName: 'WORKFLOW_LABEl_STEP_TYPES',
      //   defaultName: 'Workflow step types',
      // },
    ],
  },
];

const checkSystemConfig: Function | undefined = inject('checkSystemConfig');
const routerList = computed<any>(() => {
  return settings
    ?.filter((o) => permission(o.name))
    .filter((o) => {
      if (
        !o.permissionList ||
        o.permissionList?.length == 0 ||
        !checkSystemConfig
      )
        return true;

      return checkSystemConfig(o.permissionList);
    });
});

const currentRoute = computed(() => route.name);

const isActiveRouter = (name) => {
  return currentRoute.value == name;
};

const activeClass = ref('text-current-500 bg-current-50 bg-opacity-50');
const inactiveClass = ref(
  'transform hover:bg-gray-50 cursor-pointer hover:text-current'
);
const getMenuLinkUrl = (routePath) => {
  return `/tasks-management/${routePath}`;
};
</script>

<template>
  <div class="flex-center h-full w-full m-auto">
    <div class="w-full h-full flex flex-row divide-x rounded-lg relative">
      <div
        class="
          w-72
          bg-white
          max-h-full
          flex flex-col
          space-y-1
          overflow-y-auto overflow-x-hidden
          neumorphism-shadow
          p-1
        "
      >
        <template v-for="(item, index) in routerList" :key="index">
          <template v-if="item?.children?.length > 0">
            <div class="w-full flex pt-2 pb-1">
              <div class="w-2 border-b border-dashed"></div>
              <span class="text-xs font-semibold text-gray-600">
                {{ $t(item.keyName) || item.defaultName }}
              </span>
              <div class="flex-1 border-b border-dashed"></div>
            </div>
            <RouterLink
              v-for="(child, index2) in item?.children"
              :key="index2"
              class="
                font-semibold
                transition
                duration-200
                rounded-lg
                h-16
                text-sm
                flex
                items-center
                lg:h-auto
                w-full
                py-3
                lg:px-6
              "
              :class="[
                isActiveRouter(child.name) ? activeClass : inactiveClass,
              ]"
              :to="getMenuLinkUrl(child.path)"
            >
              {{ $t(child.keyName) || child.defaultName }}
            </RouterLink>
          </template>
          <RouterLink
            v-else
            class="
              font-semibold
              transition
              duration-200
              rounded-lg
              h-16
              text-sm
              flex
              items-center
              lg:h-auto
              w-full
              py-3
              lg:px-6
            "
            :class="[isActiveRouter(item.name) ? activeClass : inactiveClass]"
            :to="getMenuLinkUrl(item.path)"
          >
            {{ $t(item.keyName) || item.defaultName }}
          </RouterLink>
        </template>
      </div>

      <div
        class="
          flex flex-col
          rounded-md
          max-h-full
          w-full
          shadow
          bg-white
          m-3
          overflow-y-auto
          small-scrollbar
        "
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
