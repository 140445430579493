<script setup lang="ts">
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import { ref } from 'vue';
import dayjs from 'dayjs';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import appStore from '@/store/app';

const emit = defineEmits(['onFilterDate', 'isShowOrderStartWD']);

const isOpenDropdownOtherTime = ref(false);
const dropdownSpecificDate = ref(null as any);
const dateFilter = ref(null as any);
const dateConfig = ref({ type: 'string', mask: 'YYYY-MM-DD' });
const typeFilter = ref(1);
const isShowDateFilter = ref(false);
const dateFormat = ref('');
const input = ref();

const onCloseDropdownOtherTime = () => {
  isOpenDropdownOtherTime.value = false;
  // isAutoFocus.value = false;
  // onSave();
};

const onOpenDropdownOtherTime = () => {
  isOpenDropdownOtherTime.value = true;

  setTimeout(() => {
    input.value.focus();
    input.value.click();
  });
};

const onResetDate = () => {
  dateFilter.value = undefined;
};

const onSave = () => {
  const today = dayjs().startOf('day').valueOf();
  const yesterday = dayjs().startOf('day').subtract(1, 'day').valueOf();
  if (dayjs(dateFilter.value).startOf('day').valueOf() == today) {
    changeType(1);
  } else if (dayjs(dateFilter.value).startOf('day').valueOf() == yesterday) {
    changeType(2);
  } else {
    typeFilter.value = 3;
    isShowDateFilter.value = true;
    dateFormat.value = dayjs(dateFilter.value).format('DD/MM/YYYY');
    emit('onFilterDate', dayjs(dateFilter.value).format('YYYY-MM-DD'));
    emit('isShowOrderStartWD', false);
  }
  dropdownSpecificDate.value.onForceClose();
};

const changeType = (value) => {
  if (typeFilter.value == value) return;
  typeFilter.value = value;
  dateFilter.value = undefined;
  isShowDateFilter.value = false;
  const yesterday = dayjs
    .utc()
    .startOf('day')
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  switch (typeFilter.value) {
    case 1:
      emit('onFilterDate', null);
      emit('isShowOrderStartWD', true);

      break;
    case 2:
      emit('onFilterDate', yesterday);
      emit('isShowOrderStartWD', false);
      break;
    default:
      break;
  }
};
</script>

<template>
  <div class="flex items-center space-x-2">
    <div
      class="px-2 py-1 rounded-full border text-xs cursor-pointer"
      :class="
        typeFilter == 1
          ? 'bg-current-50 text-current-500 font-medium border-current'
          : 'hover:bg-gray-100'
      "
      @click="changeType(1)"
    >
      {{ $t('CALENDAR_TITLE_TODAY') }}
    </div>
    <div
      class="px-2 py-1 rounded-full border text-xs cursor-pointer"
      :class="
        typeFilter == 2
          ? 'bg-current-50 text-current-500 font-medium border-current'
          : 'hover:bg-gray-100'
      "
      @click="changeType(2)"
    >
      {{ $t('NOTIFICATION_YESTERDAY') }}
    </div>

    <VigDropdown
      ref="dropdownSpecificDate"
      @on-dropdown-open="onOpenDropdownOtherTime"
      @on-dropdown-close="onCloseDropdownOtherTime"
    >
      <template #dropdown-toggle>
        <div
          class="flex-center cursor-pointer border rounded-full"
          :class="[
            isOpenDropdownOtherTime
              ? 'border border-current ring-2 ring-current-100'
              : '',
            typeFilter == 3
              ? 'bg-current-50 border border-current'
              : 'hover:bg-gray-100',
            isShowDateFilter ? 'px-2 py-1 space-x-2' : 'h-6 w-6',
          ]"
        >
          <span
            v-if="isShowDateFilter"
            class="text-current-500 font-medium text-xs"
            >{{ dateFormat }}</span
          >
          <!--          <SynIcon-->
          <!--            name="dots-vertical"-->
          <!--            class="rotate-90"-->
          <!--            :custom-class="-->
          <!--              isOpenDropdownOtherTime-->
          <!--                ? 'fill-current w-4 h-4'-->
          <!--                : 'w-4 h-4 fill-gray-400'-->
          <!--            "-->
          <!--          />-->
          <SynIcon
            name="dots-vertical"
            :custom-class="
              isOpenDropdownOtherTime
                ? 'w-4 h-4 fill-current-500 text-current rotate-90'
                : 'w-4 h-4 fill-gray-500 text-gray-500 rotate-90'
            "
          />
        </div>
      </template>
      <template #dropdown-menu>
        <div class="p-3 rounded">
          <div class="flex flex-col">
            <div>
              <span class="text-xs">{{
                $t('TASK_CREATE_FORM_LABEL_SPECIFIC_DATE')
              }}</span>
            </div>
            <div class="flex items-center space-x-2">
              <v-date-picker
                v-model="dateFilter"
                :locale="appStore().language || 'en'"
                color="current"
                :model-config="dateConfig"
                :attributes="[
                  {
                    key: 'today',
                    dot: {
                      color:
                        dayjs(new Date()).format('MM-DD-YYYY') ===
                        dayjs(dateFilter).format('MM-DD-YYYY')
                          ? 'yellow'
                          : 'current',
                      class:
                        dayjs(new Date()).format('MM-DD-YYYY') ===
                        dayjs(dateFilter).format('MM-DD-YYYY')
                          ? 'mb-1 z-70'
                          : '',
                    },
                    dates: new Date(),
                  },
                ]"
                :popover="{ visibility: 'click' }"
                :max-date="new Date()"
                :first-day-of-week="2"
                position="fixed"
              >
                <template #default="{ inputValue, inputEvents }">
                  <div class="relative text-xs">
                    <input
                      ref="input"
                      type="text"
                      class="vig-input h-10"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                    <button
                      v-if="dateFilter"
                      class="
                        absolute
                        top-1/2
                        right-1
                        -mt-3
                        w-6
                        h-6
                        flex-center
                        rounded-full
                        hover:bg-gray-100
                      "
                      @click="onResetDate"
                    >
                      <SynIcon name="Close" />
                    </button>
                  </div>
                </template>
              </v-date-picker>
              <SynButton
                :disabled="!dateFilter"
                class="text-sm"
                @click="onSave"
              >
                {{ $t('COMMON_LABEL_SAVE') }}
              </SynButton>
            </div>
          </div>
        </div>
      </template>
    </VigDropdown>
  </div>
</template>
