<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 512.002 512.002"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <g>
            <path
              d="M202.669,42.668h-21.333c-5.896,0-10.667,4.771-10.667,10.667v405.333c0,5.896,4.771,10.667,10.667,10.667h21.333     c5.896,0,10.667-4.771,10.667-10.667V53.334C213.336,47.439,208.565,42.668,202.669,42.668z"
              class=""
            ></path>
            <path
              d="M141.325,239.345L34.658,154.012c-9.188-7.354-22.625-5.885-29.979,3.333c-7.365,9.198-5.875,22.625,3.333,29.979     l85.844,68.677L8.013,324.678c-9.208,7.354-10.698,20.781-3.333,29.979c4.208,5.271,10.406,8.01,16.667,8.01     c4.677,0,9.385-1.531,13.313-4.677l106.667-85.333c5.063-4.042,8.01-10.177,8.01-16.656S146.388,243.387,141.325,239.345z"
              class=""
            ></path>
            <path
              d="M501.336,298.668H266.669c-5.896,0-10.667,4.771-10.667,10.667v21.333c0,5.896,4.771,10.667,10.667,10.667h234.667     c5.896,0,10.667-4.771,10.667-10.667v-21.333C512.002,303.439,507.231,298.668,501.336,298.668z"
              class=""
            ></path>
            <path
              d="M501.336,170.668H266.669c-5.896,0-10.667,4.771-10.667,10.667v21.333c0,5.896,4.771,10.667,10.667,10.667h234.667     c5.896,0,10.667-4.771,10.667-10.667v-21.333C512.002,175.439,507.231,170.668,501.336,170.668z"
              class=""
            ></path>
            <path
              d="M501.336,42.668H266.669c-5.896,0-10.667,4.771-10.667,10.667v21.333c0,5.896,4.771,10.667,10.667,10.667h234.667     c5.896,0,10.667-4.771,10.667-10.667V53.334C512.002,47.439,507.231,42.668,501.336,42.668z"
              class=""
            ></path>
            <path
              d="M501.336,426.668H266.669c-5.896,0-10.667,4.771-10.667,10.667v21.333c0,5.896,4.771,10.667,10.667,10.667h234.667     c5.896,0,10.667-4.771,10.667-10.667v-21.333C512.002,431.439,507.231,426.668,501.336,426.668z"
              class=""
            ></path>
          </g>
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
