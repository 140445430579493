<script setup lang="ts">
import { computed } from 'vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskByStatusBoard from '@/ui/modules/task/board/TaskByStatusBoard.vue';
import TaskBoardByUrgency from '@/ui/modules/task/board/TaskBoardByUrgency.vue';
import { EFilterTaskBoard } from '@/application/constants/task.const';
import TaskBoardByDefault from '@/ui/modules/task/board/TaskBoardByDefault.vue';
import PinnedTasksBoard from '@/ui/modules/task/board/PinnedTasksBoard.vue';
import TaskByDeadlineBoard from '@/ui/modules/task/board/TaskByDeadlineBoard.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';

const props = withDefaults(
  defineProps<{
    currentContactId: any;
    type: 'USER' | 'GROUP' | 'DOMAIN' | 'IA';
    location: 'MY_TASKS' | 'USER_TASKS' | 'GROUP_TASKS' | 'DOMAIN_TASKS';
  }>(),
  {
    currentContactId: getCurrentUserId(),
    type: 'USER',
  }
);

const _taskTabViewStore = taskTabViewStore();
// const _taskSmartViewStore = taskSmartViewStore();
const boardCurrentTabId = computed(() => _taskTabViewStore.boardCurrentTabId);

const screenCode = computed<string>(() => {
  if (props.location == 'MY_TASKS') return ALL_SCREENS.LOGIN_USER.TASK.COLUMN;
  if (props.location == 'GROUP_TASKS') return ALL_SCREENS.GROUP.TASK.COLUMN;
  if (props.location == 'DOMAIN_TASKS') return ALL_SCREENS.DOMAIN.TASK.COLUMN;
  return ALL_SCREENS.USER.TASK.COLUMN;
});
</script>

<template>
  <div
    v-permission-screen="{
      screenCode: screenCode,
      isPage: true,
    }"
    class="flex-1 min-h-0 w-full h-full"
  >
    <template v-if="boardCurrentTabId === EFilterTaskBoard.PINNED">
      <PinnedTasksBoard
        class="px-1"
        :current-contact-id="currentContactId"
        :type="type"
      />
    </template>
    <template v-if="boardCurrentTabId === EFilterTaskBoard.BY_STATUS">
      <TaskByStatusBoard
        class="px-1"
        :current-contact-id="currentContactId"
        :type="type"
      />
    </template>
    <template v-else-if="boardCurrentTabId === EFilterTaskBoard.BY_URGENCY">
      <TaskBoardByUrgency
        class="px-1"
        :current-contact-id="currentContactId"
        :type="type"
      />
    </template>
    <template v-else-if="boardCurrentTabId === EFilterTaskBoard.BY_DEADLINE">
      <TaskByDeadlineBoard
        :current-contact-id="currentContactId"
        :type="type"
      />
    </template>
    <template v-else-if="boardCurrentTabId === EFilterTaskBoard.DEFAULT">
      <TaskBoardByDefault :current-contact-id="currentContactId" :type="type" />
    </template>
  </div>
</template>
