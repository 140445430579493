import BaseRepository from '@/base/repositories/BaseRepository';

export default class ChatApiRepository {
    private static instance: ChatApiRepository;

    constructor() {}

    public static getInstance(): ChatApiRepository {
        if (!ChatApiRepository.instance) {
            // Get from local storage
            ChatApiRepository.instance = new ChatApiRepository();
        }

        return ChatApiRepository.instance;
    }

    deleteUserConversation(converId, data): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Chat/DeleteUserConversation/${converId}`,
            method: 'put',
            data,
        });
    }

    getConversationMessages(converId, data): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Chat/GetConversationMessages/${converId}`,
            method: 'post',
            data,
        });
    }
}
