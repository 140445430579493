import { IUserService } from '@/domain/interfaces/services/IUserService';
import UserRepository from '@/application/repositories/UserRepository';
import { UserCreatePayload } from '@/domain/entities/PayloadClass/UserPayloadClass';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

export default class UserService implements IUserService {
    private static instance: UserService;
    _userRepository: UserRepository;

    constructor() {
        this._userRepository = UserRepository.getInstance();
    }

    public static getInstance(): UserService {
        if (!UserService.instance) {
            // Get from local storage
            UserService.instance = new UserService();
        }

        return UserService.instance;
    }

    async getDetail(userId: number): Promise<any> {
        return await this._userRepository.getDetail(userId);
    }

    async userShortInfosIncludeDepartment(): Promise<any> {
        return await this._userRepository.userShortInfosIncludeDepartment();
    }

    getDeactivatedUsers(): Promise<any> {
        return this._userRepository.getDeactivatedUsers();
    }

    getOrgDeactivatedUsers(orgId: number): Promise<any> {
        return this._userRepository.getOrgDeactivatedUsers(orgId);
    }

    getUsersGroupsForChat(orgId?: number | null): Promise<any> {
        return this._userRepository.getUsersGroupsForChat(orgId);
    }

    async getUserIdentity(): Promise<any> {
        return await this._userRepository.getUserIdentity();
    }

    async getShortInfosUser(): Promise<any> {
        return this._userRepository.getShortInfos();
    }

    async getOrgUsers(orgId: number): Promise<any> {
        return this._userRepository.getOrgUsers(orgId);
    }

    async saveProfile(data: UserCreatePayload, isDefaultAvatar): Promise<any> {
        return await this._userRepository.saveProfile(data, isDefaultAvatar);
    }

    async uploadAvatar(file: File): Promise<any> {
        return await this._userRepository.uploadAvatar(file);
    }

    async getDomainListById(userId: number): Promise<any> {
        return await this._userRepository.getDomainListById(userId);
    }

    async fetchAllProfile(lang): Promise<any> {
        const language = lang || getLocalStorage(StorageConstant.LANGUAGE);
        return await this._userRepository.fetchAllProfile(language);
    }

    async fetchAllPosition(language): Promise<any> {
        const lang = language || getLocalStorage(StorageConstant.LANGUAGE);
        return await this._userRepository.fetchAllPosition(lang);
    }

    async changePassword(data: any): Promise<any> {
        return await this._userRepository.changePassword(data);
    }

    async searchUser(
        searchString: string,
        status: boolean,
        role: number,
        departmentId: number,
        pageIndex: number,
        pageSize: number
    ): Promise<any> {
        const query = searchString
            ? `SearchString=${searchString}&Status=${status}&Role=${role}&DepartmentId=${departmentId}&PageIndex=${pageIndex}&PageSize=${pageSize}`
            : `Status=${status}&Role=${role}&DepartmentId=${departmentId}&PageIndex=${pageIndex}&PageSize=${pageSize}`;

        const res: any = await this._userRepository.searchUser(query);

        return {
            users: res?.result?.users,
            pagination: res?.result?.pagination,
        };
    }

    async getUserByPublicMemberId(memberId: string): Promise<any> {
        const res: any = await this._userRepository.getUserByPublicMemberId(
            memberId
        );
        return res.result;
    }

    async deleteUser(userId: number, status: boolean): Promise<any> {
        return await this._userRepository.deleteUser(userId, status);
    }

    async updateStatusUser(userId: number, status: boolean): Promise<any> {
        return await this._userRepository.updateStatusUser(userId, status);
    }

    async saveUserScopesDto(data: any): Promise<any> {
        return await this._userRepository.saveUserScopesDto(data);
    }

    async updatePositionValue(payload: any): Promise<any> {
        const language = getLocalStorage(StorageConstant.LANGUAGE);
        return await this._userRepository.updatePositionValue({
            ...payload,
            language: language ?? 'en',
        });
    }

    async setPassword(data: string): Promise<any> {
        return await this._userRepository.setPassword(data);
    }

    async sendVerificationUserEmail(): Promise<any> {
        return await this._userRepository.sendVerificationUserEmail();
    }

    async changeUserPhone(data: any): Promise<any> {
        return await this._userRepository.changeUserPhone(data);
    }

    async changeUserEmail(data: any): Promise<any> {
        return await this._userRepository.changeUserEmail(data);
    }

    async checkHasSendVerificationUserEmail(): Promise<any> {
        return await this._userRepository.checkHasSendVerificationUserEmail();
    }

    async uploadAvatarUser(
        userId: number,
        avatar: string,
        defaultAvatar: boolean
    ): Promise<any> {
        return await this._userRepository.uploadAvatarUser(
            userId,
            avatar,
            defaultAvatar
        );
    }

    async getUserActivities(data: {
        userId: number;
        activity: string;
        subActivity: string | undefined;
        platform: string | undefined;
        pageIndex: number;
        pageSize: number;
    }): Promise<any> {
        const query = `UserId=${data.userId}&Activity=${data?.activity}&SubActivity=${data.subActivity}&Platform=${data.platform}&PageIndex=${data.pageIndex}&PageSize=${data.pageSize}`;
        return await this._userRepository.getUserActivities(query);
    }

    async getAllUserActivitiesByDate(data: {
        date: Date | undefined;
        activity: string;
        subActivity: string | undefined;
    }): Promise<any> {
        const query = `Date=${data.date}&Activity=${data?.activity}&SubActivity=${data.subActivity}`;
        return await this._userRepository.getAllUserActivitiesByDate(query);
    }
}
