<template>
  <svg v-if="!isActive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g data-name="Layer 32">
      <path
        d="M16,31a5,5,0,0,1-5-5,1,1,0,0,1,2,0,3,3,0,0,0,.88,2.12,3.06,3.06,0,0,0,4.24,0,1,1,0,0,1,1.42,1.42A5,5,0,0,1,16,31Z"
      />
      <path d="M16,6a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0V5A1,1,0,0,1,16,6Z" />
      <rect
        height="36.77"
        transform="translate(-6.61 16.79) rotate(-47)"
        width="2"
        x="15"
        y="-2.38"
      />
      <path
        d="M26.73,27H5a1,1,0,0,1-.89-1.45,18.08,18.08,0,0,0,1.89-8V14a8,8,0,0,1,.06-1,1,1,0,1,1,2,.28A6,6,0,0,0,8,14v3.53A20.07,20.07,0,0,1,6.55,25H26.73a1,1,0,1,1,0,2Z"
      />
      <path
        d="M25.06,20a1,1,0,0,1-1-.92Q24,18.31,24,17.53V14A8,8,0,0,0,11.93,7.12,7.66,7.66,0,0,0,9.35,9.57a1,1,0,1,1-1.68-1.1A9.74,9.74,0,0,1,10.92,5.4,9.95,9.95,0,0,1,23.07,6.94,9.86,9.86,0,0,1,26,14v3.53c0,.46,0,.91.06,1.37A1,1,0,0,1,25.14,20Z"
      />
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="512px"
    height="512px"
    viewBox="0 0 512 512"
  >
    <path
      d="M448,464a15.92,15.92,0,0,1-11.31-4.69l-384-384A16,16,0,0,1,75.31,52.69l384,384A16,16,0,0,1,448,464Z"
    />
    <path
      d="M440.08,341.31c-1.66-2-3.29-4-4.89-5.93-22-26.61-35.31-42.67-35.31-118,0-39-9.33-71-27.72-95-13.56-17.73-31.89-31.18-56.05-41.12a3,3,0,0,1-.82-.67C306.6,51.49,282.82,32,256,32s-50.59,19.49-59.28,48.56a3.13,3.13,0,0,1-.81.65,157.88,157.88,0,0,0-21.88,11,8,8,0,0,0-1.49,12.49L434.32,366.44a8,8,0,0,0,13.6-6.63A35.39,35.39,0,0,0,440.08,341.31Z"
    />
    <path
      d="M112.14,217.35c0,75.36-13.29,91.42-35.31,118-1.6,1.93-3.23,3.89-4.89,5.93a35.16,35.16,0,0,0-4.65,37.62c6.17,13,19.32,21.07,34.33,21.07H312.8a8,8,0,0,0,5.66-13.66l-192-192a8,8,0,0,0-13.62,5Q112.14,208,112.14,217.35Z"
    />
    <path
      d="M256,480a80.06,80.06,0,0,0,70.44-42.13A4,4,0,0,0,322.9,432H189.12a4,4,0,0,0-3.55,5.87A80.06,80.06,0,0,0,256,480Z"
    />
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
