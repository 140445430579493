import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import NotificationService from '@/application/services/NotificationService';
import { NotificationKey } from '@/ui/common/constants/notifications';
import { getDiffDate } from '@/ui/plugins/filters';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import {
    covertSnakeCaseToCamelCase,
    arrayOrderBy,
} from '@/ui/hooks/commonFunction';
import { NotificationType } from '@/domain/enums/notification-enums';
import { INotification } from '@/application/types/notification.types';
import commonStore from '@/store/common/commonStore';
import { CurrentUserConfig } from '@/ui/common/constants/constant';

declare const sentNotifyToDesktopApp: any;

export default defineStore({
    id: 'notification',
    state: () => ({
        _notificationService: NotificationService.getInstance(),

        // allNotifications: {
        //     today: [] as any,
        //     yesterday: [] as any,
        //     other: [] as any,
        // },
        rawNotifications: [] as INotification[],
        allNotifications: [] as INotification[],
        latestNotification: {
            today: '',
            yesterday: '',
            older: '',
        } as {
            today: number | string;
            yesterday: number | string;
            older: number | string;
        },
        skipReload: false,
        totalNotification: 0,
        totalPage: 0,
        isLoading: false,
        userNotificationUnreadCount: '0',
        numberTotalNotificationUnread: 0,
        numberTotalNotificationMyTask: 0,
        notificationType: {
            all: {
                key: 'all',
                label: 'NOTIFICATION_LABEL_TYPE_ALL',
                totalUnread: 0,
                category: 'all',
                isOnlyUnread: false,
            },
            new: {
                key: 'new',
                label: 'NOTIFICATION_LABEL_TYPE_NEW',
                totalUnread: 0,
                category: 'new',
                isOnlyUnread: false,
            },
            updated: {
                key: 'updated',
                label: 'NOTIFICATION_LABEL_TYPE_UPDATED',
                totalUnread: 0,
                category: 'updated',
                isOnlyUnread: false,
            },
            finished: {
                key: 'finished',
                label: 'NOTIFICATION_LABEL_TYPE_FINISHED',
                totalUnread: 0,
                category: 'finished',
                isOnlyUnread: false,
            },
            other: {
                key: 'other',
                label: 'NOTIFICATION_LABEL_TYPE_OTHER',
                totalUnread: 0,
                category: 'other',
                isOnlyUnread: false,
            },
        },
        filters: {
            all: {
                actionBy: [],
            },
            new: {
                actionBy: [],
                groups: [],
                domains: [],
            },
            updated: {
                actionBy: [],
                groups: [],
                domains: [],
            },
            finished: {
                actionBy: [],
                groups: [],
                domains: [],
            },
            other: {
                actionBy: [],
                types: [],
            },
        },
        category: 'all',
    }),
    getters: {
        getSkip(state): boolean {
            return state.skipReload;
        },

        getNumberTotalNotificationUnread(state): number {
            return state.numberTotalNotificationUnread;
        },

        getNumberTotalNotificationMyTask(state): number {
            return state.numberTotalNotificationMyTask;
        },

        getFilterUserActionByNotificationByCategory(state): any {
            return state.filters[state.category].actionBy;
        },
    },
    actions: {
        setNumberTotalNotificationUnread(number) {
            this.numberTotalNotificationUnread = number;
        },

        setNumberTotalNotificationMyTask(number) {
            this.numberTotalNotificationMyTask = number;
        },

        setSkipReload(reload) {
            this.skipReload = reload;
        },

        setCategory(type) {
            this.category = type;
        },

        setValueOnlyUnread(category, value) {
            this.notificationType[category].isOnlyUnread = value;
        },

        setValueFilterActionBy(category, value) {
            if (!value) this.filters[category].actionBy = [];
            else this.filters[category].actionBy = [value];
        },

        setValueFilterGroup(category, value) {
            if (!value) this.filters[category].groups = [];
            else this.filters[category].groups = [value];
        },

        setValueFilterDomain(category, value) {
            if (!value) this.filters[category].domains = [];
            else this.filters[category].domains = [value];
        },

        setValueFilterTypes(category, value) {
            if (!value) this.filters[category].types = [];
            else this.filters[category].types = [value];
        },

        removeAllValueFilter(category) {
            this.filters[category].actionBy = [];
            this.filters[category].groups = [];
            this.filters[category].domains = [];
            this.filters[category].types = [];
        },

        findLatestNotification(notificationParse, defaultData) {
            const latestNotification = defaultData;
            const diffDay = getDiffDate(notificationParse.createdDate);
            switch (diffDay) {
                case 0:
                    if (
                        !latestNotification?.today ||
                        dayjs(latestNotification?.today).diff(
                            notificationParse.createdDate
                        ) < 0
                    )
                        latestNotification.today = notificationParse.id;
                    break;
                case 1:
                    if (
                        !latestNotification?.yesterday ||
                        dayjs(latestNotification?.yesterday).diff(
                            notificationParse.createdDate
                        ) < 0
                    )
                        latestNotification.yesterday = notificationParse.id;
                    break;

                default:
                    if (
                        !latestNotification?.older ||
                        dayjs(latestNotification?.older).diff(
                            notificationParse.createdDate
                        ) < 0
                    )
                        latestNotification.older = notificationParse.id;
                    break;
            }

            return latestNotification;
        },

        getNotificationConfigByUser(category) {
            let configKeyUpdate;
            const userConfig = commonStore().userConfig;
            switch (category) {
                case NotificationKey.All:
                    configKeyUpdate = userConfig.find(
                        (el) =>
                            el.key ===
                            CurrentUserConfig.OnlyUnreadAllNotification
                    );
                    break;
                case NotificationKey.Updated:
                    configKeyUpdate = userConfig.find(
                        (el) =>
                            el.key ===
                            CurrentUserConfig.OnlyUnreadUpdatedNotification
                    );
                    break;
                case NotificationKey.New:
                    configKeyUpdate = userConfig.find(
                        (el) =>
                            el.key ===
                            CurrentUserConfig.OnlyUnreadNewNotification
                    );
                    break;
                case NotificationKey.Finished:
                    configKeyUpdate = userConfig.find(
                        (el) =>
                            el.key ===
                            CurrentUserConfig.OnlyUnreadFinishedNotification
                    );
                    break;
                case NotificationKey.Other:
                    configKeyUpdate = userConfig.find(
                        (el) =>
                            el.key ===
                            CurrentUserConfig.OnlyUnreadOtherNotification
                    );
                    break;
                default:
                    break;
            }

            return configKeyUpdate;
        },

        prepareAllNotifications(
            currentNotifications,
            newNotifications,
            notificationConfigByUser
        ) {
            let allNotifications = [...currentNotifications];
            newNotifications.forEach((notification) => {
                const mainData = JSON.parse(notification.data);

                const notificationParse = {
                    ...notification,
                    ...this.parserNotification(notification, mainData),
                };

                this.latestNotification = this.findLatestNotification(
                    notificationParse,
                    this.latestNotification
                );

                switch (notificationParse?.type) {
                    case NotificationType.TASK:
                        {
                            if (
                                notificationConfigByUser?.value &&
                                notificationParse?.status
                            )
                                break;

                            const newHistory =
                                this.prepareTaskNotificationHistory(
                                    notificationParse
                                );

                            const taskId = parseInt(
                                notificationParse?.mainData?.taskId
                            );
                            const index = allNotifications?.findIndex(
                                (tn) => tn?.mainDataId == taskId
                            );
                            if (index > -1) {
                                const isExisted = allNotifications[
                                    index
                                ]?.histories?.some(
                                    (his) =>
                                        his?.notificationId ==
                                        newHistory?.notificationId
                                );

                                allNotifications[index] = {
                                    ...allNotifications[index],
                                    histories: [
                                        ...(allNotifications[index].histories ||
                                            []),
                                        ...(isExisted ? [] : [newHistory]),
                                    ],
                                    reactions: [
                                        ...allNotifications[index].reactions,
                                        ...notificationParse?.reactions,
                                    ],
                                };
                            } else {
                                allNotifications = [
                                    ...allNotifications,
                                    {
                                        ...notificationParse,
                                        mainDataId: taskId,
                                        histories: [newHistory],
                                    },
                                ];
                            }
                        }
                        break;

                    default:
                        allNotifications = [
                            ...allNotifications,
                            {
                                ...notificationParse,
                            },
                        ];
                        break;
                }
            });

            allNotifications = arrayOrderBy(
                allNotifications,
                ['createdDate'],
                ['desc']
            ) as INotification[];
            return allNotifications;
        },

        async getNotifications(
            maxResultCount,
            skipCount,
            category,
            type = 'LIST'
        ) {
            const actionby =
                this.filters[category].actionBy?.length > 0
                    ? this.filters[category].actionBy?.join('%2C')
                    : null;
            const groups =
                this.filters[category].groups?.length > 0
                    ? this.filters[category].groups?.join('%2C')
                    : null;
            const domains =
                this.filters[category].domains?.length > 0
                    ? this.filters[category].domains?.join('%2C')
                    : null;
            const types = this.filters[category].types?.join('%2C');
            try {
                this.isLoading = true;
                const res: any = await this._notificationService.getList(
                    maxResultCount,
                    skipCount,
                    category,
                    actionby,
                    groups,
                    domains,
                    types
                );

                const newNotifications = res['result']['items'];
                this.totalNotification = res['result']['totalCount'];
                this.totalPage =
                    Math.floor(this.totalNotification / maxResultCount) + 1;
                // if (newNotifications) this.getNotificationUnread();
                if (skipCount === 0 || type !== 'LIST') {
                    this.allNotifications = [];
                    this.rawNotifications = [];
                }

                this.rawNotifications = [
                    ...this.rawNotifications,
                    ...(newNotifications || []),
                ];

                if (!newNotifications || newNotifications?.length == 0)
                    return [];
                const notificationConfigByUser =
                    this.getNotificationConfigByUser(this.category);

                this.allNotifications = this.prepareAllNotifications(
                    this.allNotifications,
                    newNotifications,
                    notificationConfigByUser
                );

                this.isLoading = false;
                // console.log('allNotifi:', this.allNotifications);
                return newNotifications || [];
            } catch (error) {
                console.log('🚀 ~ ERR: ', error);
                this.isLoading = false;
                return [];
            }
        },

        // NUMBER IN BELL
        async getCountUnreadNotification() {
            const res: any = await this._notificationService.getCountUnread();
            const count = parseInt(res['result']);
            if (count >= 10) {
                this.userNotificationUnreadCount = '+9';
            } else {
                this.userNotificationUnreadCount = '' + count;
            }
            return this.userNotificationUnreadCount;
        },

        // NUMBER IN TAB NOTIFICATION
        async getNotificationUnread() {
            const res: any =
                await this._notificationService.getNotificationUnread();
            const result = res['result'];
            if (!result) return;
            result.forEach((el) => {
                this.notificationType[el.category].totalUnread =
                    el?.totalUnread || 0;
            });
        },

        async reactionOnNotification(notificationId, content, remove) {
            try {
                const data = {
                    content,
                    remove,
                };
                const res: any =
                    await this._notificationService.reactionOnNotification(
                        notificationId,
                        data
                    );
                if (res['success']) {
                    return res['result'];
                }
            } catch (error) {
                console.log(error);
                return;
            }
        },

        async updateUserNotifications(data) {
            if (!data) {
                return;
            }
            try {
                if (data.selectAll) {
                    data.actionBy =
                        this.filters[data.category].actionBy?.length > 0
                            ? this.filters[data.category].actionBy?.join('%2C')
                            : null;
                }
                const res: any =
                    await this._notificationService.updateUserNotifications(
                        data
                    );
                if (res['success']) {
                    this.getNotificationUnread();
                }
            } catch (error) {
                console.log(error);
            }
        },

        async deleteNotification(data: any[]) {
            await this._notificationService.deleteNotification(data);
        },

        sendNotificationToDesktopApp(message) {
            if (typeof sentNotifyToDesktopApp !== 'undefined') {
                sentNotifyToDesktopApp(message);
            }
        },

        async getNotificationById(id) {
            if (id) {
                const res: any =
                    await this._notificationService.getNotificationById(id);
                let notificationResult = res['result'];
                if (!notificationResult?.data) return;

                const notificationData = JSON.parse(notificationResult?.data);

                const mainData = this.parserNotification(
                    notificationResult,
                    notificationData
                );

                notificationResult = {
                    ...notificationResult,
                    ...mainData,
                };

                if (
                    notificationResult.keyNotification &&
                    notificationResult.keyNotification !== this.category &&
                    this.category !== NotificationKey.All
                )
                    return;

                if (
                    this.filters[this.category].actionBy[0] !==
                    notificationResult.executorId
                )
                    return;

                return res['result'] || {};
            }
        },

        parserNotification(notificationDataItems, mainDataRaw) {
            if (!mainDataRaw) return;

            const mainData = covertSnakeCaseToCamelCase(mainDataRaw);

            switch (notificationDataItems?.type) {
                case NotificationType.DEPARTMENT:
                    return this.handleParseNotificationDepartment(
                        notificationDataItems,
                        mainData
                    );
                case NotificationType.TASK: {
                    mainData.beforeUpdate = covertSnakeCaseToCamelCase(
                        mainData.beforeUpdate
                    );
                    mainData.afterUpdate = covertSnakeCaseToCamelCase(
                        mainData.afterUpdate
                    );

                    const camalCaseTaskWorkflow = covertSnakeCaseToCamelCase(
                        mainData.taskWorkflow
                    );

                    const camelCaseSteps =
                        camalCaseTaskWorkflow?.workflowSteps?.map((snakeCase) =>
                            covertSnakeCaseToCamelCase(snakeCase)
                        );

                    mainData.taskWorkflow = {
                        ...camalCaseTaskWorkflow,
                        workflowSteps: camelCaseSteps,
                    };
                    return {
                        ...notificationDataItems,
                        mainData,
                    };
                }

                // case NotificationType.GED:
                //   return this.handleParseNotificationGed(
                //     notificationDataItems,
                //     mainData
                //   );
                case NotificationType.DAYOFF:
                    return this.handleParseNotificationDayOff(
                        notificationDataItems,
                        mainDataRaw
                    );
                // case NotificationType.CALENDAR:
                //   return this.handleParseNotificationEvent(
                //     notificationDataItems,
                //     mainDataRaw
                //   );
                default:
                    return {
                        ...notificationDataItems,
                        mainData,
                    };
            }
        },

        handleParseNotificationDayOff(notificationDataItems, mainDataRaw) {
            const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
            const mainData = covertSnakeCaseToCamelCase(mainDataRaw);

            return {
                ...notificationDataItems,
                exception: mainDataRaw.dayoff_exception,
                groupedContent: !mainDataRaw
                    ? ''
                    : mainDataRaw[`grouped_content_${lang}`] ||
                      mainDataRaw?.grouped_content ||
                      '',
                title: notificationDataItems.title
                    ? notificationDataItems.title
                    : mainDataRaw.title || '',
                mainData,
            };
        },

        handleParseNotificationEvent(notificationDataItems, data) {
            let notificationResult = {} as any;
            const dataObject = covertSnakeCaseToCamelCase(data);
            const dataMetaEvent = data?.metadata
                ? covertSnakeCaseToCamelCase(data?.metadata)
                : null;
            notificationResult = {
                ...notificationResult,
                ...dataObject,
                ...notificationDataItems,
            };
            notificationResult.metadata = dataMetaEvent;
            return notificationResult;
        },

        handleParseNotificationDepartment(notificationDataItems, mainData) {
            return {
                ...mainData,
                ...notificationDataItems,
            };
        },

        handleReadAllNotificationLocal() {
            this.allNotifications?.forEach((el) => (el.status = true));
        },

        handleDeleteNotificationLocal(id) {
            this.allNotifications = this.allNotifications?.filter(
                (notification) => notification?.id !== id
            );
        },

        handleReadNotificationLocal(id) {
            const indexToday = this.allNotifications.findIndex(
                (x) => x.id === id
            );
            if (indexToday !== -1) {
                this.allNotifications[indexToday].status = true;
            }
        },

        handleReactionNotification(id, reactions) {
            const index = this.allNotifications.findIndex((x) => x.id === id);
            if (index != -1) {
                this.allNotifications[index].reactions = reactions || [];
                console.log(
                    this.allNotifications[index].reactions,
                    '----------------'
                );
            }
        },

        prepareTaskNotificationHistory(notificationParse) {
            return {
                notificationId: notificationParse?.id,
                actionType: notificationParse?.mainData?.actionType,
                createdDate: notificationParse?.createdDate,
                executorId: notificationParse?.executorId,
                executorFirstName: notificationParse.executorFirstName,
                executorLastName: notificationParse.executorLastName,
                executorFullName:
                    notificationParse.executorFullName ||
                    `${notificationParse.executorFirstName} ${notificationParse.executorLastName}`,
                executorAvatar: notificationParse.executorAvatar,
                executorAvatarUrl: notificationParse.executorAvatarUrl,
                executorAvatarThumbnail:
                    notificationParse.executorAvatarThumbnail,
                eventActionDate: notificationParse?.eventActionDate,
                modifiedDate: notificationParse?.modifiedDate,
                beforeUpdate: notificationParse?.mainData?.beforeUpdate,
                afterUpdate: notificationParse?.mainData?.afterUpdate,

                groupedContent: notificationParse?.groupedContent,
                title: notificationParse?.title,
                status: notificationParse?.status,
                taskComment: notificationParse?.mainData?.taskComment,
                taskCommentObject:
                    notificationParse?.mainData?.taskCommentObject,
            };
        },
    },
});
