import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import EmbedAuthServices from '@/ticket/services/auth-services';
import EmbedUserServices from '@/ticket/services/user-services';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

export default defineStore({
    id: storeId.ticketUser,
    state: () => ({
        _authService: EmbedAuthServices.getInstance(),
        _userService: EmbedUserServices.getInstance(),
        myInfo: {} as any,
        myTimeZone: '',
        listShortInfosUser: [],
        deactiveUsers: [],
        domains: [] as any[],
        projects: [] as any[],
    }),
    getters: {
        allUsers(state): any[] {
            return arrayOrderBy(
                [...state.listShortInfosUser, ...state.deactiveUsers],
                ['isMe', 'isFavorite', 'name'],
                ['desc', 'asc', 'asc']
            );
        },
    },
    actions: {
        async getUsers(): Promise<any> {
            const res = await this._userService.getShortInfosUser();
            this.listShortInfosUser = res?.result?.map((o) => {
                o.avatar = o.profilePictureUrl;
                o.name = `${o.lastName} ${o.firstName}`;
                o.userId = o.id;
                o.isMe = o.id == getCurrentUserId();
                return o;
            });
        },
        async getDeactivatedUsers(): Promise<any> {
            const res = await this._userService.getDeactivatedUsers();
            this.deactiveUsers = res?.result?.map((o) => {
                o.avatar = o.profilePictureUrl;
                o.name = `${o.lastName} ${o.firstName}`;
                o.userId = o.id;
                o.isDeactive = true;
                return o;
            });
        },
        async getScopeListByUserId(userId): Promise<any> {
            try {
                const res = await this._userService.getScopeListByUserId(
                    userId
                );
                this.domains = res.result;
                this.domains.forEach((domain) => {
                    this.projects = [
                        ...this.projects,
                        ...domain.projectScopeList,
                    ];
                });
                return Promise.resolve(res.result);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        getProjectById(projectId) {
            const index = this.projects.findIndex(
                (o) => o.projectId == projectId
            );
            return index > -1 ? this.projects[index].projectName : '';
        },
    },
});
