<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0,0,256,256"
  >
    <defs>
      <linearGradient
        id="color-1_pGHcje298xSl_gr1"
        x1="28"
        y1="14.966"
        x2="28"
        y2="6.45"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#42a3f2"></stop>
        <stop offset="1" stop-color="#42a4eb"></stop>
      </linearGradient>
      <linearGradient
        id="color-2_pGHcje298xSl_gr2"
        x1="28"
        y1="42"
        x2="28"
        y2="33.054"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#11408a"></stop>
        <stop offset="1" stop-color="#103f8f"></stop>
      </linearGradient>
      <linearGradient
        id="color-3_pGHcje298xSl_gr3"
        x1="28"
        y1="-15.46"
        x2="28"
        y2="-15.521"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#3079d6"></stop>
        <stop offset="1" stop-color="#297cd2"></stop>
      </linearGradient>
      <linearGradient
        id="color-4_pGHcje298xSl_gr4"
        x1="12"
        y1="28.53"
        x2="44"
        y2="28.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#1d59b3"></stop>
        <stop offset="1" stop-color="#195bbc"></stop>
      </linearGradient>
      <linearGradient
        id="color-5_pGHcje298xSl_gr5"
        x1="4.744"
        y1="14.744"
        x2="23.494"
        y2="33.493"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#256ac2"></stop>
        <stop offset="1" stop-color="#1247ad"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(-25.6,-25.6) scale(1.2,1.2)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.33333,5.33333)">
          <path
            d="M42,6h-28c-1.105,0 -2,0.895 -2,2v7.003h32v-7.003c0,-1.105 -0.895,-2 -2,-2z"
            fill="url(#color-1_pGHcje298xSl_gr1)"
          ></path>
          <path
            d="M12,33.054v6.946c0,1.105 0.895,2 2,2h28c1.105,0 2,-0.895 2,-2v-6.946z"
            fill="url(#color-2_pGHcje298xSl_gr2)"
          ></path>
          <path
            d="M12,15.003h32v9.002h-32z"
            fill="url(#color-3_pGHcje298xSl_gr3)"
          ></path>
          <path
            d="M12,24.005h32v9.05h-32z"
            fill="url(#color-4_pGHcje298xSl_gr4)"
          ></path>
          <path
            d="M22.319,13h-10.319v24h10.319c2.033,0 3.681,-1.648 3.681,-3.681v-16.638c0,-2.033 -1.648,-3.681 -3.681,-3.681z"
            fill="#000000"
            opacity="0.05"
          ></path>
          <path
            d="M22.213,36h-10.213v-22.667h10.213c1.724,0 3.121,1.397 3.121,3.121v16.425c-0.001,1.724 -1.398,3.121 -3.121,3.121z"
            fill="#000000"
            opacity="0.07"
          ></path>
          <path
            d="M22.106,35h-10.106v-21.333h10.106c1.414,0 2.56,1.146 2.56,2.56v16.213c0.001,1.414 -1.146,2.56 -2.56,2.56z"
            fill="#000000"
            opacity="0.09"
          ></path>
          <path
            d="M22,34h-16c-1.105,0 -2,-0.895 -2,-2v-16c0,-1.105 0.895,-2 2,-2h16c1.105,0 2,0.895 2,2v16c0,1.105 -0.895,2 -2,2z"
            fill="url(#color-5_pGHcje298xSl_gr5)"
          ></path>
          <path
            d="M18.403,19l-1.546,7.264l-1.713,-7.264h-2.187l-1.767,7.489l-1.593,-7.489h-1.956l2.344,10h2.352l1.713,-7.689l1.714,7.689h2.251l2.344,-10z"
            fill="#ffffff"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
