<script setup lang="ts">
import { ref } from 'vue';
import FileIcon from '@/ui/modules/ged/_commons/file-icon/FileIcon.vue';
import FileRename from '@/ui/modules/ged/_commons/file-rename/FileRename.vue';
import DriveDocumentActionsDropdown from '@/ui/modules/drive-document/drive-document-actions/DriveDocumentActionsDropdown.vue';

withDefaults(
  defineProps<{
    document: any;
    isSelected?: boolean;
  }>(),
  {}
);

defineEmits([
  'onClick',
  'onRenameConfirm',
  'onMoveToFolderClick',
  'onMoveToTrashClick',
]);

const isRenaming = ref<boolean>(false);
</script>

<template>
  <a
    :href="document?.link"
    class="relative text-center group p-1 rounded"
    :class="isSelected ? 'bg-current-50' : 'hover:bg-gray-100'"
    @click.prevent="$emit('onClick')"
  >
    <div
      :title="document?.link"
      class="h-28 p-1 flex items-end justify-center relative"
    >
      <FileIcon
        :file-type="document?.contentType"
        class-name="w-20 h-20 fill-gray-500 text-gray-500"
      />
    </div>

    <!--NAME-->
    <div
      v-if="!isRenaming"
      :title="document?.name"
      class="px-2 pt-1 pb-2 text-xs break-words overflow-hidden text-ellipsis"
      style="max-height: 5.5rem"
    >
      {{ document?.name }}
    </div>
    <div v-else @click.prevent.stop>
      <FileRename
        :file="document"
        input-class="text-sm leading-4"
        @on-cancel="isRenaming = false"
        @on-rename="
          isRenaming = false;
          $emit('onRenameConfirm', $event);
        "
      />
    </div>

    <!--ACTIONS-->
    <div
      class="absolute top-1 right-1 hidden group-hover:block"
      @click.prevent.stop
    >
      <DriveDocumentActionsDropdown
        :document="document"
        @on-rename-click="isRenaming = true"
        @on-move-to-folder-click="$emit('onMoveToFolderClick')"
        @on-move-to-trash-click="$emit('onMoveToTrashClick')"
      >
        <VigButton
          ghost
          color="gray"
          padding="p-0"
          rounded="rounded-full"
          class="bg-white hover:border-gray-200 w-8 h-8"
        >
          <SynIcon name="DotsVertical" />
        </VigButton>
      </DriveDocumentActionsDropdown>
    </div>
  </a>
</template>
