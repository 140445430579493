import { ref } from 'vue';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import UserService from '@/application/services/UserService';

export const typeAdmin = ref([]);
export const position = ref([]);
export const infoUserForm = ref({} as any);

export const fetchAllProfile = async () => {
    const language = getLocalStorage(StorageConstant.LANGUAGE);
    const res = await UserService.getInstance().fetchAllProfile(
        language ?? 'en'
    );
    typeAdmin.value = res?.result ?? [];
};

export const fetchAllPosition = async () => {
    const language = getLocalStorage(StorageConstant.LANGUAGE);
    const res = await UserService.getInstance().fetchAllPosition(
        language ?? 'en'
    );
    position.value = res?.result ?? [];
};

export const timeUtc = [
    {
        utc: '+7',
        label: 'USERMANAGEMENT_FORM_TIME_UTC_VIETNAM',
        nameIcon: 'flag-vietnam',
        text: 'Viet Nam (GMT +7)',
    },
    {
        utc: '+2',
        label: 'USERMANAGEMENT_FORM_TIME_UTC_FRANCE',
        nameIcon: 'flag-france',
        text: 'France (GMT +2)',
    },
    {
        utc: '-5',
        label: 'USERMANAGEMENT_FORM_TIME_UTC_UNITED_STATE',
        nameIcon: 'flag-united-state',
        text: 'Hoa Ky (GMT -5)',
    },
];
export const statusActive = [
    {
        status: false,
        label: 'USERMANAGEMENT_FORM_DEACTIVE',
        name: 'Deactive',
        key: 0,
    },
    {
        status: true,
        label: 'USERMANAGEMENT_FORM_ACTIVE',
        name: 'Active',
        key: 1,
    },
    {
        status: null,
        label: 'COMMON_LABEL_ALL',
        name: 'All',
        key: -1,
    },
];

export const setInfoUser = (key, info) => {
    infoUserForm.value.key = info;
};
