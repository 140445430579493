<script setup lang="ts">
import dayjs from 'dayjs';
import { VOUCHER_UI_BY_STATUS } from '@/application/constants/payment.const';
import {
  EPaymentVoucherResponseCode,
  IPaymentVoucherDetail,
} from '@/application/interfaces/payment/TransactionInterfaces';
import { computed } from 'vue';

const props = defineProps<{
  voucherDetail: IPaymentVoucherDetail;
  hiddenClose: boolean;
}>();
defineEmits(['close']);

const uiByStatus = computed<{
  bg: string;
  text: string;
  descriptionText: string;
  highlightText: string;
  discountText: string;
  expiredDateText: string;
}>(() => {
  let status = 'error';

  if (
    props.voucherDetail?.messageCode ===
    EPaymentVoucherResponseCode.VOUCHER_VALID
  )
    status = 'valid';
  if (
    props.voucherDetail?.messageCode ===
    EPaymentVoucherResponseCode.VOUCHER_EXPIRED
  )
    status = 'expired';
  return VOUCHER_UI_BY_STATUS[status];
});

const validToColor = computed<string>(() => {
  const remainDays = dayjs(props.voucherDetail?.validTo).diff(dayjs(), 'day');

  if (remainDays < 0) return '#ef4444';
  if (remainDays <= 3) return '#f97316';

  return uiByStatus.value?.expiredDateText;
});
</script>
<template>
  <div
    v-if="voucherDetail?.code"
    class="w-full flex-center relative rounded-md"
    :style="{
      background: uiByStatus?.bg,
      minHeight: '7.5rem',
      minWidth: '26.5rem',
    }"
  >
    <div
      class="
        absolute
        text-center
        -left-4
        transform
        -rotate-90
        font-semibold
        h-8
        w-20
      "
      :style="{
        color: uiByStatus?.text,
      }"
    >
      <span class="uppercase text-sm">
        {{ voucherDetail?.category || 'coupon' }}
      </span>
    </div>
    <div
      class="absolute top-0 left-0 ml-10 h-full border-l-2 border-dashed"
      :style="{
        borderColor: uiByStatus?.text,
      }"
    ></div>
    <div class="flex-1 h-full flex items-stretch justify-between pl-12">
      <div class="p-2 flex-1 flex flex-col justify-between space-y-2">
        <div class="flex-1 flex flex-col space-y-1">
          <span
            class="uppercase font-semibold"
            :style="{
              color: uiByStatus?.text,
              borderColor: uiByStatus?.text,
            }"
          >
            {{ voucherDetail?.name }}
          </span>
          <span
            class="italic text-sm"
            :style="{
              color: uiByStatus?.descriptionText,
            }"
          >
            {{ voucherDetail?.description }}
          </span>
        </div>
        <span
          class="text-xs italic"
          :style="{
            color: validToColor,
          }"
        >
          {{ $t('PAYMENT_EXPRIRED_DATE') }}:
          <SynLabelDateTime format="date" :datetime="voucherDetail.validTo" />
        </span>
      </div>
      <div class="h-full flex items-center justify-between flex-col w-28 pr-2">
        <!-- <span
          class="text-2xl font-bold pt-2"
          :style="{
            color: uiByStatus?.discountText,
            borderColor: uiByStatus?.discountText,
          }"
        >
          {{ voucherDetail.code }}
        </span> -->
        <span class="h-12"></span>
        <div class="flex-center flex-wrap py-2 pr-2">
          <span
            class="italic text-xs"
            :style="{
              color: '#707070',
            }"
          >
            {{ $t('COMMON_LABEL_MAX') }}
          </span>
          <span
            class="italic font-semibold text-xl"
            :style="{
              color: uiByStatus?.discountText,
              borderColor: uiByStatus?.discountText,
            }"
          >
            {{ $filters.currency(voucherDetail.valueInMoney, 'vi') }}
          </span>
        </div>
      </div>
    </div>
    <div class="absolute" style="right: -1.9rem; top: -0.4rem">
      <div class="flex-center relative">
        <div class="flex-center" style="height: 5.5rem">
          <SynIcon name="tag" custom-class="h-full" />
        </div>
        <span
          class="absolute text-white font-bold"
          style="top: 26%; left: 43%; font-size: 1rem; rotate: 46deg"
        >
          {{ voucherDetail.valueInPercent }}%
        </span>
      </div>
    </div>
    <div
      v-if="!hiddenClose"
      class="
        w-6
        h-6
        absolute
        -top-2
        -right-2
        flex-center
        cursor-pointer
        bg-gray-100
        rounded-full
        shadow
        hover:bg-gray-50
      "
      @click="$emit('close')"
    >
      <SynIcon name="close" custom-class="w-4 h-4 fill-gray-300" />
    </div>
  </div>
</template>
