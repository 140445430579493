<script setup lang="ts">
import { Recorder } from 'vmsg';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import SynAudio from '@/ui/common/atoms/SynRecorder/SynAudio.vue';

const recorder = new Recorder({
  wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
});
const { module = 'task', maxRecordMinute = 3 } =
  defineProps<{ module?: string; maxRecordMinute?: number }>();

const maxRecordSecond = maxRecordMinute * 60;
const maxRecordTotal = 1;

const emit = defineEmits(['onDone', 'onCancel']);
const recordings = ref([] as any[]);
const isLoading = ref(false);
const isRecording = ref(false);
const record = async () => {
  isLoading.value = true;

  if (isRecording.value) {
    const blob = await recorder.stopRecording();
    // Stoped Recording...
    isLoading.value = false;
    isRecording.value = false;
    recordings.value = [
      ...recordings.value,
      {
        blob: blob,
        src: URL.createObjectURL(blob),
        type: blob.type,
        size: blob.size,
        isRecorder: true,
        name: `tictop-${module}-recorder-${Date.now()}.mp3`,
      },
    ];
  } else {
    try {
      await recorder.initAudio();
      await recorder.initWorker();
      recorder.startRecording();
      //Started Recording...
      isLoading.value = false;
      isRecording.value = true;
    } catch (e) {
      console.error(e);

      openNotification({
        type: AlertType.error,
        title: translate('COMMON_LABEL_ERROR'),
        body: e,
      });
      isLoading.value = false;
    }
  }
};
onMounted(() => {});
onUnmounted(() => {
  if (isRecording.value) {
    recorder.stopRecording();
  }
});
const onStart = () => {
  if (isLimited.value) {
    return;
  }
  record();
};
const onStop = () => {
  if (isRecording.value) {
    record();
  }
};
const onDone = async () => {
  if (isRecording.value) {
    await record();
  }
  emit('onDone', recordings.value);
};
const onCancel = () => {
  if (isRecording.value) {
    recorder.stopRecording();
    isRecording.value = false;
  }
  emit('onCancel');
};
const onRemove = (record) => {
  const index = recordings.value.findIndex((o) => o.name == record.name);
  if (index > -1) {
    recordings.value.splice(index, 1);
  }
};
const isLimited = computed(() => maxRecordTotal == recordings.value?.length);
</script>

<template>
  <SynModal
    container-class="md:w-96"
    z-index="z-60"
    disable-click-outside
    disable-element-active
    @cancel="onCancel"
  >
    <template #header>
      <div class="flex items-center">
        <span class="px-2">{{ $t('COMMON_LABEL_AUDIO') }}</span>
      </div>
    </template>
    <template #body>
      <div class="flex flex-col justify-center items-center space-y-2">
        <div
          class="relative h-32 w-32 flex-center rounded-full bg-opacity-20"
          :class="isRecording ? 'bg-red-200 animate-pulse' : ''"
        >
          <div
            class="
              relative
              inline-flex
              h-20
              w-20
              bg-current
              rounded-full
              flex-center
            "
            :class="{ Rec: isRecording }"
          >
            <SynIcon
              is-active
              custom-class="w-8 h-8 fill-white"
              :name="'microphone'"
              :color="isRecording ? 'red' : 'current'"
            />
          </div>
        </div>
        <div v-if="!isLimited" class="flex flex-col items-center space-y-2">
          <div class="flex space-x-2 text-sm">
            <SynCountDownWatch
              :start="isRecording"
              :total-miniseconds="maxRecordSecond * 1000"
              @on-stop="onStop"
            />
          </div>

          <SynButton
            v-if="isRecording"
            color="white"
            class="border border-gray-100 shadow"
            @click="onStart"
          >
            <div class="flex-center space-x-2">
              <span class="h-4 w-4 rounded bg-red-500"></span>
              <span class="text-gray-800">
                {{ $t('TASK_LABEL_AUDIO_RECORD_STOP') }}
              </span>
            </div>
          </SynButton>
          <SynButton
            v-else
            color="white"
            class="border border-gray-100 shadow"
            @click="onStart"
          >
            <div class="flex-center space-x-2">
              <span class="h-4 w-4 rounded-full bg-red-500"></span>
              <span class="text-gray-800">
                {{ $t('TASK_LABEL_AUDIO_RECORD') }}
              </span>
            </div>
          </SynButton>
        </div>

        <span
          v-if="isLimited && maxRecordTotal > 1"
          class="text-sm text-red-400"
        >
          {{
            $t('TASK_CREATE_FORM_AUDIO_LABEL_TOTAL_RECORD') +
            ' ' +
            maxRecordTotal +
            ' ' +
            $t('COMMON_LABEL_RECORDS')
          }}
        </span>
        <div
          class="flex flex-col-reverse justify-center items-center space-y-1"
        >
          <syn-audio
            v-for="record in recordings"
            :key="record"
            class="h-12"
            :readonly="true"
            :src="record.src"
            @on-close="onRemove(record)"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div
        class="w-full p-4 flex items-center"
        :class="!isLimited ? 'justify-between' : 'justify-end'"
      >
        <div class="flex-1 flex items-center">
          <span v-if="!isLimited" class="text-xs italic">
            {{ $t('TASK_CREATE_FORM_AUDIO_LABEL_LIMIT_RECORD') }}
            <br />
            <span class="text-orange-500 text-sm">
              {{ maxRecordMinute + ' ' + $t('COMMON_LABEL_MINUTE') }}
            </span>
          </span>
        </div>
        <div class="flex-center space-x-2">
          <SynButton
            :label="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            @click="onCancel"
          />
          <SynButton
            :disabled="isRecording || recordings.length == 0"
            :is-loading="isLoading"
            :label="$t('COMMON_LABEL_DONE')"
            @click="onDone"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>

<style scoped>
.Rec {
  animation-name: audio-pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes audio-pulse {
  0% {
    box-shadow: 0px 0px 10px 0px rgba(255, 19, 19, 0.3);
  }
  65% {
    box-shadow: 0px 0px 10px 13px rgba(255, 19, 19, 0.3);
  }
  90% {
    box-shadow: 0px 0px 10px 13px rgba(255, 19, 19, 0.3);
  }
}
</style>
