import BaseRepository from '@/base/repositories/BaseRepository';

class TaskSharingRepository {
    getTaskSharedByTaskId(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskSharedByTaskId/${taskId}`,
            method: 'get',
        });
    }

    saveTaskShared(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SaveTaskShared`,
            method: 'post',
            data,
        });
    }

    removeTaskShared(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RemoveTaskShared`,
            method: 'post',
            data,
        });
    }

    getTaskSnapshots(taskId) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetTaskSnapshots/${taskId}`,
            method: 'get',
        });
    }

    saveTaskSnapshot(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/SaveTaskSnapshot`,
            method: 'post',
            data,
        });
    }

    removeTaskSnapshot(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/RemoveTaskSnapshot`,
            method: 'post',
            data,
        });
    }
}

export default new TaskSharingRepository();
