<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 171.09 136.25"
  >
    <g id="_48_Warning" data-name=" 48 Warning">
      <path
        class="cls-2"
        d="M159.93,122.04l-32.08-51.42c-2.52-4.08-7.87-5.34-11.95-2.83-1.14,.7-2.1,1.66-2.81,2.8l-32.13,51.51c-2.37,4.16-.92,9.47,3.24,11.84,1.27,.72,2.7,1.12,4.17,1.14h64.19c4.79-.08,8.62-4.03,8.54-8.82-.02-1.48-.43-2.94-1.17-4.22Z"
      />
      <path
        d="M117.85,108.19l-.76-11.4c-.15-2.29-.93-6,.82-7.87,1.33-1.43,4.49-1.68,5.45,.31,.81,2.34,.99,4.84,.52,7.27l-1.02,11.74c-.04,1.1-.29,2.19-.71,3.21-.51,1-1.73,1.39-2.73,.88-.37-.19-.67-.48-.86-.85-.4-1.06-.64-2.17-.7-3.3h0Zm2.64,15.67c-1.96-.01-3.55-1.61-3.54-3.58,.01-1.79,1.35-3.29,3.13-3.51,1.95-.24,3.72,1.15,3.96,3.1,.24,1.95-1.15,3.72-3.1,3.96-.15,.02-.3,.03-.45,.03Z"
      />
    </g>
    <path
      class="cls-1"
      d="M74.31,127.9c-4.54,.46-9.35,.72-14.38,.72-30.33,0-53-9.24-53-17.51v-19.84c9.62,8.72,31.81,13.34,53,13.34,9.49,0,19.17-.94,27.84-2.75l4.2-6.74c-8.77,2.35-19.77,3.87-32.05,3.87-30.33,0-53-9.24-53-17.51v-19.84c9.62,8.72,31.81,13.34,53,13.34s43.38-4.62,53-13.34v1.18c.21-.16,.42-.31,.64-.45,1.34-.83,2.81-1.28,4.29-1.38V25.24c0-15.02-29.84-23.13-57.93-23.13S2,10.21,2,25.24V111.12c0,15.02,29.84,23.13,57.93,23.13,5.76,0,11.59-.35,17.22-1.02-1.53-1.44-2.51-3.32-2.84-5.33ZM59.93,7.73c30.33,0,53,9.24,53,17.51s-22.66,17.51-53,17.51S6.93,33.5,6.93,25.24,29.59,7.73,59.93,7.73ZM6.93,35.03c9.62,8.72,31.81,13.34,53,13.34s43.38-4.62,53-13.34v16.81c0,8.27-22.66,17.51-53,17.51S6.93,60.11,6.93,51.84v-16.81Z"
    />
  </svg>
</template>
<style scoped>
.cls-1 {
  stroke: #231f20;
  stroke-miterlimit: 10;
  stroke-width: 4px;
}

.cls-2 {
  fill: #ffde17;
}
</style>
