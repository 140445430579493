import { computed } from 'vue';

export default function calculateImageLayoutComposables(options: {
    size: 'sm' | 'lg' | 'md';
}) {
    const containerDefault = {
        sm: {
            containerMaxWidth: 320,
            maxWidth: 220,
            minWidth: 100,
        },
        lg: { containerMaxWidth: 670, maxWidth: 500, minWidth: 170 },
        md: { containerMaxWidth: 480, maxWidth: 360, minWidth: 120 },
    };

    const containerDefaultBySize = computed(
        () => containerDefault[options.size]
    );

    const getPadding = (
        currentRowFiles: {
            fileId: string;
            width: number;
            height: number;
        }[]
    ) => {
        const totalWidthInCurrentRow = currentRowFiles?.reduce(
            (oldValue, currentItem) => {
                return oldValue + currentItem?.width;
            },
            0
        );
        const remainder =
            containerDefaultBySize.value?.containerMaxWidth -
            totalWidthInCurrentRow;

        return remainder / currentRowFiles.length;
    };

    const calculateImageLayout = (
        fileByIds: any, // object by file id
        idPrefix: string
    ) => {
        const allRows = [] as any;
        let currentRowFiles = [] as {
            fileId: string;
            width: number;
            height: number;
        }[];
        const totalFiles = Object.keys(fileByIds)?.length;

        Object.entries(fileByIds).forEach(([fileId, fileData]: any, index) => {
            const fileElementId = `${idPrefix}-${fileId}`;

            const fileElement = document.getElementById(fileElementId);
            const fileElementBounding = fileElement?.getBoundingClientRect();

            const currentElementWidth = Math.min(
                Math.max(
                    containerDefaultBySize.value?.minWidth,
                    fileElementBounding?.width || 0
                ),
                containerDefaultBySize.value?.maxWidth
            );

            // 0. handle file are not image -> push to new row
            if (!fileData.contentType?.startsWith('image/')) {
                // 0.1 Push current row to list
                if (currentRowFiles?.length > 0) {
                    const padding = getPadding(currentRowFiles);

                    allRows.push(
                        currentRowFiles.map((o) => {
                            return { ...o, width: o.width + padding };
                        })
                    );

                    currentRowFiles = [];
                }

                // 0.1 Push current file to list
                allRows.push([
                    {
                        fileId: fileId,
                        width: Math.max(
                            containerDefaultBySize.value?.minWidth,
                            fileElementBounding?.width || 0
                        ),
                        height: fileElementBounding?.height,
                    },
                ]);
                return;
            }

            const totalWidthInCurrentRow = currentRowFiles?.reduce(
                (oldValue, currentItem) => {
                    return oldValue + currentItem?.width;
                },
                0
            );

            const isNeedMerge2Image =
                currentRowFiles.length == 1 &&
                totalWidthInCurrentRow + currentElementWidth >=
                    containerDefaultBySize.value?.maxWidth;

            const isNeedPushToCurrentRow =
                totalWidthInCurrentRow + currentElementWidth <=
                    containerDefaultBySize.value?.containerMaxWidth ||
                isNeedMerge2Image;

            // 1. prepare current row
            if (isNeedPushToCurrentRow) {
                currentRowFiles.push({
                    fileId: fileId,
                    width: Math.min(
                        currentElementWidth,
                        containerDefaultBySize.value?.maxWidth
                    ),
                    height: fileElementBounding?.height || 0,
                });
            } else {
                // 2. push current row to allRows
                const padding = getPadding(currentRowFiles);

                allRows.push(
                    currentRowFiles.map((o) => {
                        return { ...o, width: o.width + padding };
                    })
                );

                currentRowFiles = [];

                // 3. prepare data for new row
                currentRowFiles = [
                    {
                        fileId: fileId,
                        width: Math.min(
                            currentElementWidth,
                            containerDefaultBySize.value?.maxWidth
                        ),
                        height: fileElementBounding?.height || 0,
                    },
                ];
            }

            // 4. handle last item
            if (index == totalFiles - 1 && currentRowFiles.length > 0) {
                const padding = getPadding(currentRowFiles);
                allRows.push(
                    currentRowFiles.map((o) => {
                        return { ...o, width: o.width + padding };
                    })
                );
            }
        });

        return allRows;
    };
    return {
        containerDefaultBySize,
        calculateImageLayout,
    };
}
