export class DomainAddPayload {
    name: string;
    description: string;
    members: DomainMembersPayload[];
    groupIds: number[];
    haveSupplement: boolean;
    supplement: DomainSupplementPayload | null;
    constructor(data) {
        this.name = data?.name;
        this.description = data?.description;
        this.members = data?.members;
        this.groupIds = data?.groupIds;
        this.haveSupplement = data?.haveSupplement || false;
        this.supplement = data?.haveSupplement ? data?.supplement : null;
    }
}
export class DomainAddOrUpdatePayload {
    id?: number;
    name: string;
    description: string;
    constructor(data) {
        this.id = data?.id;
        this.name = data?.name;
        this.description = data?.description;
    }
}
export class ProjectAddPayload {
    name: string;
    description: string;
    domainId: number;
    parentId?: number;
    members: {
        addMembers: any[];
        updateMembers: any[];
    };
    constructor(data) {
        this.name = data?.name;
        this.description = data?.description;
        this.domainId = data?.domainId;
        this.parentId = data?.parentId;
        this.members = data?.members;
    }
}
export class ProjectAddOrUpdatePayload {
    id?: number;
    name: string;
    description: string;
    domainId: number;
    parentId?: number;
    constructor(data) {
        this.id = data?.id;
        this.name = data?.name;
        this.description = data?.description;
        this.domainId = data?.domainId;
        this.parentId = data?.parentId;
    }
}
export class DomainAddMemberPayload {
    domainId: number;
    userIds: string;
    groupIds: string;
    constructor(data) {
        this.domainId = data?.domainId;
        this.userIds = data?.userIds;
        this.groupIds = data?.groupIds;
    }
}
export class UpdateMembersPayload {
    id: number;
    anyProjects: boolean;
    projectIds: number[];
    updated: boolean;
    constructor(data) {
        this.id = data?.id;
        this.anyProjects = data?.anyProjects;
        this.projectIds = data?.projectIds;
        this.updated = data?.updated;
    }
}
export class UpdateUserDomainRelativePayload {
    domainId: number;
    userId: number;
    anyProjects: boolean;
    projectIds: string;
    constructor(data) {
        this.domainId = data?.domainId;
        this.userId = data?.userId;
        this.anyProjects = data?.anyProjects;
        this.projectIds = data?.projectIds;
    }
}
export class ProjectAddMemberPayload {
    domainId: number;
    projectId: number;
    subProjectId?: number;
    userIds: number[];
    constructor(data) {
        this.domainId = data?.domainId;
        this.projectId = data?.projectId;
        this.subProjectId = data?.subProjectId;
        this.userIds = data?.userIds;
    }
}

export class UpdateDomainInformationPayload {
    id: number;
    name: string;
    description: string;
    constructor(data) {
        this.id = data?.id;
        this.name = data?.name;
        this.description = data?.description;
    }
}
export class UpdateProjectInformationPayload {
    id: number;
    name: string;
    description: string;
    domainId?: number;
    parentId?: number;
    constructor(data) {
        this.id = data?.id;
        this.name = data?.name;
        this.description = data?.description;
        this.domainId = data?.domainId;
        this.parentId = data?.parentId;
    }
}
export class UpdateProjectMembersPayload {
    id: number;
    members: any[];
    constructor(data) {
        this.id = data?.id;
        this.members = data?.members;
    }
}

export class DomainSupplementPayload {
    domainId: number | null;
    startDate: Date;
    endDate: Date;
    budgetMin: number;
    budgetMax: number;
    responsibility: number;
    constructor(data) {
        this.domainId = data?.domainId;
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.budgetMin = data?.budgetMin;
        this.budgetMax = data?.budgetMax;
        this.responsibility = data?.responsibility;
    }
}
export class DomainMembersPayload {
    id: number | null;
    anyProjects: boolean;

    constructor(data) {
        this.id = data?.id;
        this.anyProjects = data?.anyProjects;
    }
}
