<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 169.09 134.25"
  >
    <g id="_48_Warning" data-name=" 48 Warning">
      <path
        class="cls-1"
        d="M157.9,99.73l-27.77-44.51c-2.18-3.53-6.81-4.63-10.34-2.45-.99,.61-1.82,1.44-2.43,2.42l-27.81,44.59c-2.06,3.6-.8,8.19,2.81,10.25,1.1,.63,2.34,.97,3.61,.99h55.56c4.15-.07,7.46-3.48,7.4-7.63-.02-1.28-.37-2.54-1.01-3.65Z"
      />
      <path
        d="M121.47,87.74l-.66-9.87c-.13-1.99-.81-5.2,.71-6.81,1.15-1.24,3.89-1.45,4.72,.27,.7,2.02,.86,4.19,.45,6.29l-.88,10.16c-.04,.96-.25,1.9-.62,2.78-.44,.86-1.5,1.21-2.36,.76-.32-.16-.58-.42-.75-.73-.35-.91-.55-1.88-.6-2.85h0Zm2.29,13.56c-1.7,0-3.07-1.4-3.06-3.1,0-1.55,1.17-2.85,2.71-3.04,1.69-.2,3.22,1,3.43,2.69,.2,1.69-1,3.22-2.69,3.43-.13,.02-.26,.02-.39,.02Z"
      />
    </g>
    <g>
      <path
        d="M29.73,84.34c-2.26,0-4.09,1.83-4.09,4.09s1.83,4.09,4.09,4.09h24.52c2.26,0,4.09-1.83,4.09-4.09s-1.83-4.09-4.09-4.09H29.73Z"
      />
      <path
        d="M85.03,100.69H21.56c-1.08,0-2.13-.43-2.89-1.2-.77-.76-1.2-1.81-1.2-2.89V59.82H110.52l5.26-8.44c.68-1.09,1.6-2,2.69-2.68,1.64-1.01,3.49-1.38,5.26-1.18v-8.14c0-3.25-1.29-6.37-3.59-8.67-2.3-2.3-5.42-3.59-8.67-3.59H21.56c-3.25,0-6.37,1.29-8.67,3.59-2.3,2.3-3.59,5.42-3.59,8.67v57.22c0,3.25,1.29,6.37,3.59,8.67,2.3,2.3,5.42,3.59,8.67,3.59h63.51c-1.4-2.45-1.52-5.54-.04-8.17ZM17.47,39.39c0-1.08,.43-2.13,1.2-2.89,.76-.77,1.81-1.2,2.89-1.2H111.47c1.08,0,2.13,.43,2.89,1.2,.77,.76,1.2,1.81,1.2,2.89v12.26H17.47v-12.26Z"
      />
    </g>
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: #ffde17;
}
</style>
