<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';

import {
  CountryCode,
  parsePhoneNumberFromString,
  PhoneNumber,
} from 'libphonenumber-js';
import { getDefault, setCaretPosition } from './utils';
import {
  StorageConstant,
  getLocalStorage,
  setLocalStorage,
} from '@/ui/hooks/storageHook';

// const fallbackCountry = {
//   fr: { dialCode: '33', iso2: 'FR', name: 'France' },
//   vn: { dialCode: '84', iso2: 'VN', name: 'Vietnam' },
//   us: { dialCode: '1', iso2: 'US', name: 'United States' },
//   jp: { name: 'Japan (日本)', iso2: 'JP', dialCode: '81' },
//   kr: { name: 'South Korea (대한민국)', iso2: 'KR', dialCode: '82' },
// };
const fallbackCountryFromLanguage = {
  fr: 'fr',
  vi: 'vn',
  en: 'us',
};

// let examples = null;
// const getExamples = () => new Promise(
//   (resolve) => (
//     examples
//       ? resolve(examples)
//       : import('libphonenumber-js/examples.mobile.json')
//         .then((results) => {
//           examples = results;
//           resolve(results);
//         })
//   ),
// );

const props = withDefaults(
  defineProps<{
    value?: string;
    autofocus?: boolean;
    availableCountries?: any[];
    allCountries?: any[];
    autoFormat?: boolean;
    customValidate?: boolean | RegExp;
    defaultCountry?: string;
    disabled?: boolean;
    autoDefaultCountry?: boolean;
    dropdownOptions?: any;
    ignoredCountries?: any[];
    inputOptions?: any;
    invalidMsg?: string;
    mode?: 'INTERNATIONAL' | 'NATIONAL' | 'AUTO';
    onlyCountries?: any[];
    preferredCountries?: any[];
    validCharactersOnly?: boolean;
    styleClasses?: any;
    error?: boolean;
    inputClass?: string;
  }>(),
  {
    styleClasses: getDefault('styleClasses'),
    validCharactersOnly: getDefault('validCharactersOnly'),
    preferredCountries: getDefault('preferredCountries'),
    onlyCountries: getDefault('onlyCountries'),
    mode: 'AUTO',
    invalidMsg: getDefault('invalidMsg'),
    inputOptions: getDefault('inputOptions'),
    ignoredCountries: getDefault('ignoredCountries'),
    dropdownOptions: getDefault('dropdownOptions'),
    autoDefaultCountry: false,
    disabled: getDefault('disabled'),
    defaultCountry: getDefault('defaultCountry'),
    customValidate: getDefault('customValidate'),
    autoFormat: false,
    allCountries: getDefault('allCountries'),
    availableCountries: () => ['vn', 'fr', 'us', 'jp', 'kr', 'sg'],
  }
);

const emit = defineEmits([
  'input',
  'keydown',
  'country-changed',
  'validate',
  'open',
  'close',
  'validate',
  'blur',
  'focus',
  'enter',
  'space',
  'mouseleave',
  'change',
]);
const phone = ref('');
const activeCountryCode = ref('');
const selectedIndex = ref(0);
const phoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});

const findCountry = (iso = '') => {
  return filteredCountries.value.find(
    (country) => country.iso2?.toLowerCase() === iso.toLowerCase()
  );
};

const activeCountry = computed(() => findCountry(activeCountryCode.value));

// const parsedMode = computed<NumberFormat>(() => {
//   if (props.mode === 'AUTO') {
//     if (!phone.value || phone.value[0] !== '+') {
//       return 'NATIONAL';
//     }
//     return 'INTERNATIONAL';
//   }

//   if (!['INTERNATIONAL', 'NATIONAL'].includes(props.mode)) {
//     return 'INTERNATIONAL';
//   }
//   return props.mode;
// });

const filteredCountries = computed(() => {
  // List countries after filtered
  const location = getLocalStorage(StorageConstant.LOCATION);
  let allCountries = props.allCountries?.filter((country) =>
    props.availableCountries?.some((ct) => ct == country?.iso2?.toLowerCase())
  );
  if (location == 'EU')
    allCountries = props.allCountries.filter((o) => o.iso2 !== 'VN');
  if (props.onlyCountries.length) {
    return allCountries.filter(({ iso2 }) =>
      props.onlyCountries.some((c) => c.toUpperCase() === iso2)
    );
  }
  if (props.ignoredCountries.length) {
    return allCountries.filter(
      ({ iso2 }) =>
        !props.ignoredCountries.includes(iso2.toUpperCase()) &&
        !props.ignoredCountries.includes(iso2.toLowerCase())
    );
  }
  return allCountries;
});

const sortedCountries = computed(() => {
  // Sort the list countries: from preferred countries to all countries
  const preferredCountries = getCountries(props.preferredCountries).map(
    (country) => ({
      ...country,
      preferred: true,
    })
  );
  return [...preferredCountries, ...filteredCountries.value];
});

// const phoneObject = computed(() => {
//   let result;
//   if (this.phone?.[0] === '+') {
//     result = parsePhoneNumberFromString(this.phone) || {};
//   } else {
//     result =
//       parsePhoneNumberFromString(this.phone, this.activeCountryCode) || {};
//   }
//   console.log(
//     '🚀 ~ file: SynTelInput.vue ~ line 245 ~ phoneObject ~ result',
//     result
//   );
//   const { ...phoneObject } = result;
//   let valid = result.isValid?.();
//   let formatted = this.phone;
//   if (valid) {
//     formatted = result.format?.(this.parsedMode.toUpperCase());
//   }
//   if (
//     result.country &&
//     (this.ignoredCountries.length || this.onlyCountries.length)
//   ) {
//     if (!this.findCountry(result.country)) {
//       valid = false;
//       Object.assign(result, { country: null });
//     }
//   }
//   Object.assign(phoneObject, {
//     countryCode: result.country,
//     valid,
//     country: this.activeCountry,
//     formatted,
//   });
//   return phoneObject;
// })
watch(
  () => activeCountry.value,
  (value, oldValue) => {
    if (!value && oldValue?.iso2) {
      activeCountryCode.value = oldValue.iso2;
      return;
    }
    if (value?.iso2) {
      emit('country-changed', value);
    }
  }
);
watch(
  () => phoneObject.value?.isValid,
  () => {
    emit('validate', phoneObject.value);
  }
);

// AUTO CHANGE FORMAT
watch(
  () => phoneObject.value?.formattedNumber,
  async (value) => {
    if (!props.autoFormat || props.customValidate) {
      return;
    }
    emitInput(value);
    await nextTick();
    // In case `v-model` is not set, we need to update the `phone` to be new formatted value
    if (value && !props.value) {
      phone.value = value;
    }
  }
);
// watch(
//   () => props.value,
//   (value, oldValue) => {
//     if (!testCharacters()) {
//       // this.$nextTick(() => {
//       //   this.phone = oldValue;
//       // });
//       phone.value = oldValue;
//       onInput();
//     } else {
//       phone.value = value;
//     }
//   }
// );
watch(
  () => props.value,
  (value) => {
    if (!value) phone.value = '';
  }
);

onMounted(async () => {
  listenLocalStorage();

  if (props.value) {
    phone.value = props.value.trim();
  }
  cleanInvalidCharacters();
  await initializeCountry();

  if (
    !phone.value &&
    props.inputOptions?.showDialCode &&
    activeCountryCode.value
  ) {
    phone.value = `+${activeCountryCode.value}`;
  }
  emit('validate', phoneObject.value);

  changePhoneObject(phone.value);
});

const listenLocalStorage = () => {
  window.addEventListener('storage', (event) => {
    if (event.key !== StorageConstant.PHONE_COUNTRY_CODE) return;
    const countryCode = event.newValue;
    choose(countryCode ? countryCode : 'us');
  });
};
const initializeCountry = () => {
  return new Promise((resolve) => {
    /**
     * 1. If the phone included prefix (i.e. +12), try to get the country and set it
     */
    if (phone.value?.[0] === '+') {
      resolve(true);
      return;
    }
    /**
     * 2. Use default country if passed from parent
     */
    if (props.defaultCountry) {
      choose(props.defaultCountry);
      resolve(true);
      return;
    }

    const language = getLocalStorage(StorageConstant.LANGUAGE);
    const phoneCountryCode = getLocalStorage(
      StorageConstant.PHONE_COUNTRY_CODE
    );
    const fallbackCountryCode = (
      phoneCountryCode
        ? phoneCountryCode
        : fallbackCountryFromLanguage[language]
    ).toLowerCase();

    /**
     * 3. Check if fetching country based on user's IP is allowed, set it as the default country
     */
    choose(fallbackCountryCode);
    resolve(true);
  });
};
/**
 * Get the list of countries from the list of iso2 code
 */
const getCountries = (list: any[] = []) => {
  return list.map((countryCode) => findCountry(countryCode)).filter(Boolean);
};
const getItemClass = (index, iso2) => {
  const highlighted = selectedIndex.value === index;
  const lastPreferred = index === props.preferredCountries.length - 1;
  const preferred = props.preferredCountries.some(
    (c) => c.toUpperCase() === iso2
  );
  return {
    highlighted,
    'last-preferred': lastPreferred,
    preferred,
  };
};
const choose = (iso) => {
  const parsedCountry = findCountry(iso);
  if (!parsedCountry) {
    return;
  }

  setLocalStorage(StorageConstant.PHONE_COUNTRY_CODE, parsedCountry.iso2);

  // if (
  //   phone.value?.[0] === '+' &&
  //   parsedCountry.iso2 &&
  //   phoneObject.value.nationalNumber
  // ) {
  //   activeCountryCode.value = parsedCountry.iso2;
  //   // Attach the current phone number with the newly selected country
  //   phone.value =
  //     parsePhoneNumberFromString(
  //       phoneObject.value.nationalNumber?.toString(),
  //       parsedCountry.iso2
  //     )?.formatInternational() || '';
  //   changePhoneObject(phone.value);
  //   // return;
  // }
  // if (props.inputOptions?.showDialCode && parsedCountry) {
  //   // Reset phone if the showDialCode is set
  //   phone.value = `+${parsedCountry.dialCode}`;
  //   changePhoneObject(phone.value);
  //   // return;
  // }
  // update value, even if international mode is NOT used
  activeCountryCode.value = parsedCountry.iso2;
  changePhoneObject(phoneObject.value?.nationalNumber, true);
  emitInput(phone.value);
};
const cleanInvalidCharacters = () => {
  const currentPhone = phone.value;
  if (props.validCharactersOnly) {
    const results = phone.value.match(/[()\-+0-9\s]*/g);
    phone.value = results?.join('') || '';
  }
  if (props.customValidate && props.customValidate instanceof RegExp) {
    const results = phone.value?.match(props.customValidate);
    phone.value = results?.join('') || '';
  }
  if (currentPhone !== phone.value) {
    emitInput(phone.value);
  }
};
// const testCharacters = () => {
//   if (props.validCharactersOnly) {
//     const result = /^[()\-+0-9\s]*$/.test(phone.value);
//     if (!result) {
//       return false;
//     }
//   }
//   if (props.customValidate) {
//     return testCustomValidate();
//   }
//   return true;
// };
// const testCustomValidate = () => {
//   return props.customValidate instanceof RegExp
//     ? props.customValidate.test(phone.value)
//     : false;
// };

const inputRef = ref<any>(null);
const onInput = () => {
  changePhoneObject(phone.value);
  inputRef.value?.setCustomValidity(
    phoneObject.value?.isValid ? '' : props.invalidMsg
  );
  // Returns response.number to assign it to v-model (if being used)
  // Returns full response for cases @input is used
  // and parent wants to return the whole response.
  emitInput(phone.value);
};
const emitInput = (value) => {
  emit('input', value ? phoneObject.value : null, value, inputRef.value);
};
const onBlur = () => {
  emit('blur', phone.value);
};
const onKeydown = () => {
  emit('keydown', phone.value);
};
const onMouseLeave = () => {
  emit('mouseleave', phone.value);
};
const onFocus = () => {
  setCaretPosition(inputRef.value, phone.value?.length);
  emit('focus');
};
const onEnter = () => {
  // changePhoneObject(phone.value);
  console.log('🚀 Hyrin ~ onEnter ~ phone.value', phone.value);
  emit('enter', phone.value);
};
const onSpace = () => {
  emit('space');
};
// const focus = () => {
//   inputRef.value.focus();
// };

const atomPhoneInputRef = ref<any>(null);

const getFlagName = (countryCode) => {
  if (!countryCode) return '';
  const country = {
    VN: {
      flagName: 'flag-vietnam',
    },
    FR: {
      flagName: 'flag-france',
    },
    US: {
      flagName: 'flag-united-state',
    },
    JP: {
      flagName: 'flag-japan',
    },
    KR: {
      flagName: 'flag-korea',
    },
    SG: {
      flagName: 'flag-singapore',
    },
  };
  return country[countryCode]?.flagName || 'flag-vietnam';
};
const changePhoneObject = (originPhoneNumber, isChangeCountry?) => {
  if (!originPhoneNumber) {
    emit('change', {
      countryCallingCode: '',
      nationalNumber: '',
      number: '',
      isValid: false,
      formattedNumber: '',
    });

    return;
  }

  let parsedPhoneNumber: PhoneNumber | undefined;
  if (originPhoneNumber[0] === '+' && !isChangeCountry) {
    parsedPhoneNumber = parsePhoneNumberFromString(
      originPhoneNumber?.toString()
    );
  } else {
    parsedPhoneNumber = parsePhoneNumberFromString(
      originPhoneNumber?.toString(),
      activeCountryCode.value?.toUpperCase() as CountryCode
    );
  }
  let phoneObjectResult: {
    country?: CountryCode;
    countryCallingCode: string;
    nationalNumber: string;
    number: string;
    isValid: boolean;
    formattedNumber: string;
  } = {
    countryCallingCode: '',
    nationalNumber: '',
    number: originPhoneNumber?.toString(),
    isValid: false,
    formattedNumber: '',
  };

  if (parsedPhoneNumber) {
    let isValid = parsedPhoneNumber?.isValid() || false;
    let formattedNumber;

    // // TODO: verify case : format auto remove 0 in first
    // if (isValid) {
    //   phone.value = parsedPhoneNumber.format(parsedMode.value);
    // }
    if (
      parsedPhoneNumber.country &&
      (props.ignoredCountries.length || props.onlyCountries.length)
    ) {
      if (!findCountry(parsedPhoneNumber.country)) {
        isValid = false;
        Object.assign(parsedPhoneNumber, { country: null });
      }
    }
    phoneObjectResult = {
      country: parsedPhoneNumber.country,
      countryCallingCode: parsedPhoneNumber.countryCallingCode,
      nationalNumber: parsedPhoneNumber.nationalNumber,
      number: parsedPhoneNumber.number,
      isValid: !!isValid,
      formattedNumber,
    };
  }

  if (
    parsedPhoneNumber?.country &&
    parsedPhoneNumber?.country !== activeCountryCode.value
  )
    activeCountryCode.value = parsedPhoneNumber.country;

  if (isChangeCountry) phone.value = phoneObjectResult.number;

  phoneObject.value = {
    ...phoneObjectResult,
    isValid:
      phoneObjectResult.number?.length == 23
        ? true
        : phoneObjectResult?.isValid,
  };

  emit('change', phoneObject.value);
};

const isOpenChangeCountry = ref<boolean>(false);
</script>
<template>
  <div
    ref="atomPhoneInputRef"
    :class="[
      'vig-input vue-tel-input',
      styleClasses,
      disabled ? 'bg-gray-100' : 'bg-white',
      error ? 'vig-input-error' : 'vig-input',
    ]"
    class="h-10 rounded-md border text-sm relative"
  >
    <div class="h-full flex-center">
      <VigDropdown
        :arrow="false"
        placement="bottom-start"
        @on-dropdown-open="isOpenChangeCountry = disabled ? false : true"
        @on-dropdown-close="isOpenChangeCountry = false"
      >
        <template #dropdown-toggle>
          <div
            class="h-10 space-x-2 flex-center px-2 rounded-md"
            :class="{
              'bg-current-50': isOpenChangeCountry,
              'cursor-pointer hover:bg-current-50': !disabled,
            }"
          >
            <SynIcon
              v-if="dropdownOptions.showFlags"
              custom-class="w-5 h-5"
              :name="getFlagName(activeCountryCode)"
            />
            <span
              v-if="dropdownOptions.showDialCodeInSelection"
              class="vti__country-code"
            >
              +{{ activeCountry && activeCountry.dialCode }}
            </span>
            <SynIcon
              v-if="!disabled"
              :name="'sort-down'"
              custom-class="h-2 w-2 fill-gray-500"
              :class="!isOpenChangeCountry ? '-rotate-90' : ''"
            />
            <span v-else class="h-2 w-2"> </span>
          </div>
        </template>
        <template v-if="!disabled" #dropdown-menu>
          <li
            v-for="(pb, index) in sortedCountries"
            :key="pb.iso2 + (pb.preferred ? '-preferred' : '')"
            class="
              dropdown-item
              list-li
              flex
              items-center
              space-x-2
              py-2
              my-1
              rounded
            "
            :class="[getItemClass(index, pb.iso2)]"
            @click="choose(pb.iso2)"
            @mousemove="selectedIndex = index"
          >
            <div
              v-if="dropdownOptions.showFlags"
              :class="['vti__flag', pb.iso2.toLowerCase()]"
            />
            <span class="font-medium text-current-800">{{ pb.name }}</span>
            <span v-if="dropdownOptions.showDialCodeInList">
              (+{{ pb.dialCode }})
            </span>
          </li>
        </template>
      </VigDropdown>
    </div>

    <input
      :id="inputOptions.id"
      ref="inputRef"
      v-model="phone"
      v-cusFocus="autofocus"
      class="w-full text-sm rounded-lg border-0 outline-none"
      style="box-shadow: none"
      :class="[
        inputOptions.styleClasses,
        disabled ? 'bg-gray-100' : '',
        inputClass,
        'px-2',
      ]"
      :type="inputOptions.type"
      :autocomplete="inputOptions.autocomplete"
      :autofocus="inputOptions.autofocus"
      :disabled="disabled"
      :maxlength="inputOptions.maxlength"
      :name="inputOptions.name"
      :placeholder="$t('COMMON_LABEL_MOBILE_PHONE')"
      :readonly="inputOptions.readonly"
      :required="inputOptions.required"
      :tabindex="inputOptions.tabindex"
      @blur="onBlur"
      @focus="onFocus"
      @mouseleave="onMouseLeave"
      @input="onInput"
      @keyup.enter="onEnter"
      @keyup.space="onSpace"
      @keydown="onKeydown"
    />
    <div
      class="
        absolute
        inset-y-0
        right-0
        flex
        items-center
        h-full
        focus:outline-none focus:shadow-outline
      "
    >
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<style src="./sprite.css"></style>
