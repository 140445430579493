import { ref, getDatabase, update, onValue } from 'firebase/database';

class UserDynamicInfoRepository {
    resetUserTotalNotifications(orgId: number, userId: number) {
        return update(this._getRef(orgId, userId), {
            totalNotification: 0,
            totalMyTaskNotification: 0,
        });
    }

    subscribeCalendarSync(orgId: number, userId: number, cb: (val) => void) {
        const calendarSyncRef = ref(
            getDatabase(),
            `users_dynamic_info/organization_${orgId}/user_${userId}/calendarSynchronization`
        );

        return onValue(calendarSyncRef, (snapshot) => cb(snapshot?.val()));
    }

    private _getRef(orgId: number, userId: number) {
        return ref(
            getDatabase(),
            `users_dynamic_info/organization_${orgId}/user_${userId}`
        );
    }
}

export default new UserDynamicInfoRepository();
