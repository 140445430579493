import { ref } from 'vue';
import TaskService from '@/application/services/task/TaskService';
import { getUrgencyValue } from './../../../hooks/taskHook';
import { rankDomainData } from '@/ui/common/molecules/SynAvatar/ranking-constants';
import datetimeComposable from '@/ui/composables/common/datetime-composable';

export default function createdTasksComposable(departmentId?: number) {
    const isLoading = ref(false);
    const { formatDate } = datetimeComposable();
    const getAllDataForCreatedTaskPage = async () => {
        isLoading.value = true;
        await Promise.all([
            getCreatedTasks(),
            getCreatedTaskRankingByDomain(),
            getCreatedTaskOverview(),
        ]);
        isLoading.value = false;
    };

    const createdTasks = ref<any[]>([]);
    const createdTaskList = ref<any[]>([]);
    const createdTaskByCreator = ref<any[]>([]);
    const getCreatedTasks = async () => {
        const res: any = await TaskService.getInstance().getCreatedTasks(
            departmentId
        );

        if (!res?.result || res.result?.length == 0) return;

        createdTasks.value = res.result.map((o) => {
            return {
                ...o,
                urgency: getUrgencyValue(o?.priority, o?.important),
                creationTime:
                    new Date(o?.creationTime).getTime() -
                    new Date(o?.creationTime).getTimezoneOffset() * 60 * 1000,
            };
        });
        createdTaskList.value = createdTasks.value;

        createdTaskByCreator.value = createdTasks.value?.reduce(
            (currentResult, currentItem) => {
                const existedIndex = currentResult?.findIndex(
                    (u) => u?.id == currentItem?.creatorId
                );

                if (existedIndex >= 0)
                    currentResult[existedIndex].createdTasksTotal += 1;
                else
                    currentResult = [
                        ...currentResult,
                        {
                            id: currentItem?.creatorId,
                            creationTime: currentItem?.creationTime,
                            createdTasksTotal: 1,
                        },
                    ];
                return [...currentResult];
            },
            []
        );
    };

    const createdTaskRankingByDomain = ref<any[]>([]);
    const getCreatedTaskRankingByDomain = async () => {
        const res: any = await TaskService.getInstance().getCreatedTaskByDomain(
            departmentId
        );
        const dataList = res.result?.slice(0, 4);

        if (!dataList || dataList.length == 0) return;
        let otherData: any = {};
        if (res.result?.length > dataList.length) {
            const otherDataList = res.result?.slice(4, res.result?.length);
            otherData =
                otherDataList?.length > 1
                    ? otherDataList.reduce(
                          (old, current) => {
                              const numberOfTaskCreated =
                                  old?.data + current?.numberOfTaskCreated;
                              return {
                                  ...current,
                                  name: 'Other',
                                  data: numberOfTaskCreated,
                                  color: rankDomainData[5]?.color,
                              };
                          },
                          { data: 0 }
                      )
                    : {
                          ...otherDataList[0],
                          name: otherDataList[0]?.domainName,
                          data: otherDataList[0]?.numberOfTaskCreated,
                          color: rankDomainData[5]?.color,
                      };
        }

        createdTaskRankingByDomain.value = dataList?.map((o, index) => {
            return {
                ...o,
                name: o?.domainName,
                data: o?.numberOfTaskCreated,
                color: rankDomainData[index + 1]?.color,
            };
        });

        if (otherData?.data) createdTaskRankingByDomain.value.push(otherData);
    };

    const createdTaskLabels = ref<string[]>([]);
    const createdTaskDatasets = ref<any[]>([]);
    const todayInfo = ref<any>();
    const getCreatedTaskOverview = async () => {
        const res: any = await TaskService.getInstance().getCreatedTaskOverview(
            departmentId
        );

        const data = res?.result;
        if (!data || data.length == 0) return;

        createdTaskLabels.value = data.map((o) =>
            formatDate(o?.creationTime, 'day_month', {
                isIgnoreTimezone: true,
            })
        );
        createdTaskDatasets.value = [
            {
                label: 'CHART_LABEL_CREATED' || 'Created Task',
                data: data.map((o) => o.numberOfTaskCreated),
                backgroundColor: '#4BB0B2',
                hoverBackgroundColor: '#008d8d',
                minBarLength: 1,
                categoryPercentage: 0.5,
            },
        ];

        const createdToday = data.filter((o) => {
            const today = new Date();
            const checkDay = new Date(o.creationTime);
            return (
                checkDay.getFullYear() === today.getFullYear() &&
                checkDay.getMonth() === today.getMonth() &&
                checkDay.getDate() === today.getDate()
            );
        });
        todayInfo.value = createdToday?.length > 0 ? createdToday[0] : {};
    };

    getAllDataForCreatedTaskPage();

    return {
        createdTaskLabels,
        isLoading,
        getAllDataForCreatedTaskPage,
        createdTasks,
        createdTaskList,
        getCreatedTasks,
        createdTaskRankingByDomain,
        getCreatedTaskRankingByDomain,
        createdTaskDatasets,
        todayInfo,
        getCreatedTaskOverview,
        createdTaskByCreator,
    };
}
