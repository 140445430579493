export enum PaymentStatusEnum {
    InitTransaction = -1,
    SuccessfulTransaction = 11,
    TransactionIsNotCompleted = 22,
    ErrorTransaction = 33,
    HandlingTransactions = 44,
}
export enum EPaymentProvider {
    Vnpay = 'VN_PAY',
    Momo = 'MOMO',
    Zalopay = 'ZALO_PAY',
}

export const CPaymentProvider = {
    [EPaymentProvider.Vnpay]: {
        key: 'VN_PAY',
        name: 'VNPAY',
    },
    [EPaymentProvider.Momo]: {
        key: 'MOMO',
        name: 'MOMO',
    },
    [EPaymentProvider.Zalopay]: {
        key: 'ZALO_PAY',
        name: 'ZaloPay',
    },
};

export enum EPaymentPurpose {
    PURCHASE = 'PURCHASE',
    UPGRADE = 'UPGRADE',
    EXTEND = 'EXTEND',
    GET_MORE = 'GET_MORE',
    DOWNGRADE = 'DOWNGRADE',
}

export interface IPaymentPayload {
    planId: string;
    purpose: EPaymentPurpose | null;
    period: number | null;
    totalUsers: number | null;
}

export interface IPaymentVoucherDetail {
    code: string;
    valueInPercent: number;
    valueInMoney: number;
    currency: string;
    name: string;
    description: string;
    validFrom: string;
    validTo: string;
    isValid: boolean;
    messageCode: EPaymentVoucherResponseCode | null;
    message: string;
    excludedBonusDay: boolean;
    maxUserOffer: number;
    maxPaymentTermOffer: number; // month
}
export enum EPaymentVoucherResponseCode {
    VOUCHER_NOT_FOUND = 'VOUCHER_NOT_FOUND',
    VOUCHER_INVALID = 'VOUCHER_INVALID',
    VOUCHER_LIMIT_REACHED = 'VOUCHER_LIMIT_REACHED',
    VOUCHER_EXPIRED = 'VOUCHER_EXPIRED',
    VOUCHER_TOO_EARLY = 'VOUCHER_TOO_EARLY',
    VOUCHER_BELONG_TO_OTHER_PHONE_NUMBER = 'VOUCHER_BELONG_TO_OTHER_PHONE_NUMBER',
    VOUCHER_BELONG_TO_OTHER_EMAIL_ADDRESS = 'VOUCHER_BELONG_TO_OTHER_EMAIL_ADDRESS',
    VOUCHER_BELONG_TO_OTHER_ORGANIZATION = 'VOUCHER_BELONG_TO_OTHER_ORGANIZATION',
    VOUCHER_BELONG_TO_OTHER_USER = 'VOUCHER_BELONG_TO_OTHER_USER',
    VOUCHER_VALID = 'VOUCHER_VALID',
}

// Generated by https://quicktype.io

export interface IRegisterLicensePayload {
    licensePackageId: string;
    type: string;
    planId: string;
    name: string;
    phone: string;
    address: string;
    email: string;
    representativeName: string;
    position: string;
    taxCode: string;
    status: string;
}

// Generated by https://quicktype.io

export interface ILicensePackage {
    id: string;
    name: string;
    description: string;
    totalAmount: number;
    totalMember: number;
    index: number;
    rank: string;
    isActive: boolean;
    isDefault: boolean;
    currencyUnit: string;
    type: string;
}
