<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    width="121.805px"
    height="121.805px"
    viewBox="0 0 121.805 121.805"
    style="enable-background: new 0 0 121.805 121.805"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M7.308,85.264h107.188c4.037,0,7.309-3.271,7.309-7.31s-3.271-7.309-7.309-7.309H7.308C3.271,70.646,0,73.916,0,77.954    S3.271,85.264,7.308,85.264z"
        />
        <path
          d="M7.308,51.158h107.188c4.037,0,7.309-3.272,7.309-7.309c0-4.037-3.271-7.308-7.309-7.308H7.308    C3.271,36.541,0,39.812,0,43.849C0,47.886,3.271,51.158,7.308,51.158z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
