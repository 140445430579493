<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g fill-rule="evenodd" clip-rule="evenodd">
        <path
          d="M3 4.75a.25.25 0 0 0-.25.25v14c0 .138.112.25.25.25h5a.75.75 0 0 1 0 1.5H3A1.75 1.75 0 0 1 1.25 19V5c0-.967.783-1.75 1.75-1.75h18c.967 0 1.75.784 1.75 1.75v3a.75.75 0 0 1-1.5 0V5a.25.25 0 0 0-.25-.25z"
          opacity="1"
          class=""
        ></path>
        <path
          d="M13 12.75a.25.25 0 0 0-.25.25v6c0 .138.112.25.25.25h8a.25.25 0 0 0 .25-.25v-6a.25.25 0 0 0-.25-.25zm-1.75.25c0-.967.783-1.75 1.75-1.75h8c.967 0 1.75.783 1.75 1.75v6A1.75 1.75 0 0 1 21 20.75h-8A1.75 1.75 0 0 1 11.25 19z"
          opacity="1"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
