<script setup lang="ts">
import { ref } from 'vue';
import importStore from '@/store/import';
import ModalWarningFileTypeError from '@/ui/modules/task/import/ModalWarningFileTypeError.vue';
import ModalShowDataSuccessList from '@/ui/modules/task/import/ModalShowDataSuccessList.vue';
import ModalShowDataErrorList from '@/ui/modules/task/import/ModalShowDataErrorList.vue';
import { saveAs } from 'file-saver';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ModalWarningLimitData from '@/ui/modules/task/import/ModalWarningLimitData.vue';

const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onResetFilter'): void;
}>();

const _importStore = importStore();

const fileSelected = ref();
const isImporting = ref(false);
const taskEnteredList = ref([] as any);
const taskEnteredSuccessList = ref([]);
const taskEnteredErrorList = ref([]);
const isShowResult = ref(false);
const isError = ref(false);
const isErrorTypeOfFile = ref(false);
const isShowDataSuccessList = ref(false);
const isShowDataErrorList = ref(false);
const pathTemplateFile =
  'https://d1tvqetrcurhzb.cloudfront.net/common/import/TICTOP-template-import-danh-sach-cong-viec.xlsx';

const onImportData = async () => {
  if (!fileSelected.value) return;
  isImporting.value = true;
  const result = await _importStore.importTaskData(fileSelected.value);
  if (!result) return;
  if (
    result.filter((e) => e.message.includes('LIMIT_TASK_IMPORT_COUNT')).length >
    0
  ) {
    isError.value = true;
  } else {
    isShowResult.value = true;
    openNotification({
      body: translate('API_ACTION_MESSAGE_IMPORT_TASK_SUCCESS'),
    });
    taskEnteredList.value = result;
    taskEnteredSuccessList.value = result.filter((e) => e.dataValid);
    taskEnteredErrorList.value = result.filter((e) => !e.dataValid);
  }
  isImporting.value = false;
};

const getFile = (event: any) => {
  if (
    event.target.files[0].type !==
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    isErrorTypeOfFile.value = true;
    return;
  }

  fileSelected.value = event.target.files[0];
  isErrorTypeOfFile.value = false;
};

const onBack = () => {
  isShowResult.value = false;
  fileSelected.value = null;
};

const deletedFileSelected = () => {
  fileSelected.value = null;
};

const downloadTemplateFile = () => {
  saveAs(pathTemplateFile, 'Mẫu-file-công-việc.xlsx');
};

const onClose = () => {
  emit('onClose');
};
</script>
<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-class="w-1/4"
    disable-click-outside
    :container-style="'min-height: 400px; min-width: 500px; max-height : 600px'"
    @cancel="onClose"
  >
    <template #header>
      <div class="w-full flex items-center space-x-2">
        <span>
          {{ $t('TASK_LABEL_IMPORTING_FILE') || 'Import tasks' }}
        </span>
      </div>
    </template>
    <template #body>
      <div v-if="!isShowResult" class="w-full h-full flex flex-col p-4">
        <div
          class="h-40 w-full border border-current p-1 flex rounded-md bg-white"
        >
          <label
            for="inputCSV"
            class="
              group
              flex-1
              border-2 border-dashed border-gray-300
              rounded-md
              cursor-pointer
              bg-gray-50
              hover:bg-current-50
            "
          >
            <div class="flex flex-col items-center">
              <!--              <SynIcon name="csv" custom-class="h-12 w-12"> </SynIcon>-->
              <SynAnimation :name="'upload'" :stype="'width:25%;'" />
              <div class="-mt-2">
                <span
                  v-if="!fileSelected"
                  class="text-gray-500 group-hover:text-current-500"
                  >{{ $t('COMMON_LABEL_SELECT_A_FILE') }}</span
                >
                <span
                  v-else
                  class="text-gray-500 group-hover:text-current-500"
                  >{{ $t('COMMON_LABEL_SELECT_ANOTHER_FILE') }}</span
                >
              </div>
            </div>
          </label>
          <input
            id="inputCSV"
            type="file"
            accept="text"
            class="hidden"
            @change="getFile($event)"
          />
        </div>
        <!--        <div v-if="isErrorTypeOfFile" class="mt-3">-->
        <!--          <span class="text-orange-500 italic">{{-->
        <!--            $t('COMMON_LABEL_FILE_TYPE_ERROR')-->
        <!--          }}</span>-->
        <!--        </div>-->
        <div
          v-if="!fileSelected"
          class="flex items-center space-x-1 mt-3 text-sm"
        >
          <span class="text-gray-500">{{
            $t('COMMON_LABEL_DOWNLOAD_TEMPLATE_FILE')
          }}</span>
          <span
            class="underline text-current-500 cursor-pointer"
            @click="downloadTemplateFile"
            >{{ $t('COMMON_LABEL_HERE') }}</span
          >
        </div>
        <div v-if="fileSelected" class="mt-3">
          <div class="flex items-baseline space-x-2">
            <div class="flex items-baseline space-x-1">
              <span class="text-sm">{{
                $t('COMMON_LABEL_SELECTED_FILE')
              }}</span>
              <span class="text-current-500">{{ fileSelected.name }}</span>
            </div>
            <div>
              <div
                v-vig-tooltip="$t('NOTIFICATION_LABEL_TYPE_FILE_DELETE')"
                class="h-3.5 w-3.5 cursor-pointer"
                @click="deletedFileSelected"
              >
                <SynIcon name="close" custom-class="w-3.5 h-3.5 fill-red-500" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col min-h-0 px-4">
        <div class="flex justify-between items-start text-sm">
          <div class="flex flex-col space-y-1">
            <div class="flex items-center space-x-2">
              <span class="">{{ $t('LABEL_TASK_JUST_ENTERED') }}</span>
              <span
                >({{ $t('COMMON_LABEL_TOTAL') }}:
                {{ taskEnteredList.length }})</span
              >
            </div>
            <div class="flex items-center space-x-2">
              <span>{{ $t('GED_FILTER_FILES') }}</span>
              <span class="text-current-500">{{ fileSelected.name }}</span>
            </div>
          </div>
          <div
            class="
              cursor-pointer
              bg-gray-100
              text-gray-500
              hover:shadow
              rounded
              px-2
              py-1
            "
            style="width: fit-content"
            @click="onBack"
          >
            {{ $t('COMMON_LABEL_BACK') }}
          </div>
        </div>

        <div
          class="flex-1 min-h-0 mt-8 overflow-auto small-scrollbar flex-center"
        >
          <div class="flex items-center space-x-8">
            <div
              v-if="taskEnteredSuccessList.length > 0"
              class="flex flex-col space-y-2"
            >
              <div
                class="
                  flex flex-col
                  p-3
                  border border-green-500
                  rounded
                  h-32
                  w-32
                "
              >
                <div class="px-2 text-center">
                  <span class="text-green-500">{{
                    $t('COMMON_LABEL_SUCCESS')
                  }}</span>
                </div>
                <div class="flex-1 flex-center">
                  <span class="text-green-500 font-medium text-5xl">
                    {{ taskEnteredSuccessList.length }}
                  </span>
                </div>
              </div>
              <div
                class="
                  text-gray-500
                  italic
                  underline
                  cursor-pointer
                  hover:text-current-500
                  text-sm text-center
                "
                @click="isShowDataSuccessList = true"
              >
                {{ $t('PLAN_EXCEED_TITLE_SEE_DETAIL') }}
              </div>
            </div>

            <div
              v-if="taskEnteredErrorList.length > 0"
              class="flex flex-col space-y-2"
            >
              <div
                class="
                  flex flex-col
                  p-3
                  border border-orange-500
                  rounded
                  h-32
                  w-32
                "
              >
                <div class="px-2 text-center">
                  <span class="text-orange-500">{{
                    $t('COMMON_LABEL_ERROR')
                  }}</span>
                </div>
                <div class="flex-1 flex-center">
                  <span class="text-orange-500 font-medium text-5xl">
                    {{ taskEnteredErrorList.length }}
                  </span>
                </div>
              </div>
              <div
                class="
                  text-gray-500
                  italic
                  underline
                  cursor-pointer
                  hover:text-current-500
                  text-sm text-center
                "
                @click="isShowDataErrorList = true"
              >
                {{ $t('PLAN_EXCEED_TITLE_SEE_DETAIL') }}
              </div>
            </div>
          </div>

          <div class="flex items-center space-x-3"></div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          type-outline
          :label="
            isShowResult ? $t('COMMON_LABEL_CLOSE') : $t('COMMON_LABEL_CANCEL')
          "
          @click="onClose"
        />
        <SynButton
          v-if="!isShowResult"
          :is-loading="isImporting"
          :disabled="!fileSelected"
          :label="$t('COMMON_LABEL_UPLOAD')"
          @click="onImportData"
        />
      </div>
    </template>
  </SynModal>

  <ModalWarningFileTypeError
    v-if="isErrorTypeOfFile"
    @cancel="isErrorTypeOfFile = false"
  >
  </ModalWarningFileTypeError>

  <ModalShowDataSuccessList
    v-if="isShowDataSuccessList"
    :data-list="taskEnteredSuccessList"
    @on-close="isShowDataSuccessList = false"
  />
  <ModalShowDataErrorList
    v-if="isShowDataErrorList"
    :data-list="taskEnteredErrorList"
    @on-close="isShowDataErrorList = false"
  />

  <ModalWarningLimitData v-if="isError" @cancel="isError = false" />
</template>
