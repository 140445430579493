import { ref, computed } from 'vue';
import { isValidTaskCode } from '@/ui/hooks/taskHook';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import taskStore from '@/store/task';

export default function taskMultiTabLogic(
    errorCode,
    onSearchCallBack,
    onSaveEditChangeCallBack,
    onChangeDragCallBack,
    onSwitchTabCallBack
) {
    const _taskStore = taskStore();

    const currentCycle = computed(() => _taskStore.currentCycle);
    const codePrefix = computed(() =>
        currentCycle.value?.currentCycle
            ? `${
                  currentCycle.value?.currentCycle
              }-${currentCycle.value?.letter.trim()}`
            : ''
    );
    const isAddingTab = ref(false);
    const addTabName = ref('');
    const addTabRef = ref(null as any);
    const onSearch = async () => {
        if (!addTabName.value) {
            onCloseAddNew();
            return;
        }

        let taskCode = addTabName.value;
        if (taskCode?.startsWith('#'))
            taskCode = `${currentCycle.value?.currentCycle}-${taskCode?.replace(
                '#',
                ''
            )}`;

        if (!isValidTaskCode(taskCode)) {
            openNotification({
                type: AlertType.warning,
                body:
                    translate('TASK_LABEL_CODE_INVALID') || 'Task code invalid',
                duration: 2000,
            });
            return;
        }

        try {
            await onSearchCallBack(taskCode);

            onCloseAddNew();
        } catch (error: any) {
            const code = error?.error?.code;
            errorCode.value = code
                ? `COMMON_ERROR_BY_CODE_${code}`
                : 'COMMON_ERROR_BY_CODE_6403';
        }
    };

    const onCloseAddNew = () => {
        isAddingTab.value = false;
        addTabName.value = '';
        errorCode.value = '';
    };

    const onOpenAddNewTab = (isCreateNew) => {
        isAddingTab.value = true;
        addTabName.value = codePrefix.value;
        onCloseEditName();
        if (!isCreateNew)
            setTimeout(() => {
                addTabRef.value?.focus();
            });
    };

    const editingTabRef = ref(null as any);
    const editingTab = ref(null);
    const originEditingTabName = ref('');
    const editingTabName = ref('');
    const onOpenEditName = ({ id, tabCode }) => {
        editingTab.value = id;
        originEditingTabName.value = tabCode;
        editingTabName.value = tabCode;
        onCloseAddNew();
    };
    const onSaveChange = async (newTaskCode) => {
        if (!newTaskCode || newTaskCode === originEditingTabName.value) {
            onCloseEditName();
            return;
        }
        if (!isValidTaskCode(newTaskCode)) {
            openNotification({
                type: AlertType.warning,
                body:
                    translate('TASK_LABEL_CODE_INVALID') || 'Task code invalid',
                duration: 2000,
            });
            return;
        }
        try {
            await onSaveEditChangeCallBack(newTaskCode);
            onCloseEditName();
        } catch (error: any) {
            console.log(
                '🚀 ~ file: TaskDetailDrawer.vue ~ line 159 ~ onSaveChange ~ error',
                error
            );
            const code = error?.error?.code;
            errorCode.value = code
                ? `COMMON_ERROR_BY_CODE_${code}`
                : 'COMMON_ERROR_BY_CODE_6403';
        }
    };

    const onChangeDrag = (oldIndex, newIndex) => {
        onChangeDragCallBack(oldIndex, newIndex);
    };

    const onSwitchTab = (tabId, currentTabId) => {
        onCloseAddNew();
        onSwitchTabCallBack(tabId);
        if (currentTabId !== tabId) onCloseEditName();
    };
    const onCloseEditName = () => {
        editingTab.value = null;
        editingTabName.value = '';
        errorCode.value = '';
    };
    return {
        isAddingTab,
        addTabName,
        addTabRef,
        onSearch,
        onCloseAddNew,
        onOpenAddNewTab,
        editingTabRef,
        editingTab,
        originEditingTabName,
        editingTabName,
        onOpenEditName,
        onSaveChange,
        onChangeDrag,
        onSwitchTab,
        onCloseEditName,
    };
}
