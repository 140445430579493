<script setup lang="ts">
import { computed } from 'vue';
import groupStore from '@/store/group';

const props = withDefaults(
  defineProps<{
    groupId: number;
    group?: any;
    isHiddenName?: boolean;
    avatarClass?: string;
    isShowMembers?: boolean;
    isShowMyGroupSignal?: boolean;
    isShowPrivateGroupSignal?: boolean;
    groupName?: string;
    groupAvatar?: string;
  }>(),
  {
    isShowMembers: false,
    isShowMyGroupSignal: false,
    isShowPrivateGroupSignal: false,
    groupName: '',
    groupAvatar: '',
  }
);

const _groupStore = groupStore();

const allGroupByIds = computed(() => {
  return {
    ..._groupStore.allGroupByIds,
    ..._groupStore.myGroupByIds,
  };
});
const groupDetail = computed(() => {
  if (props.group) return props.group;

  if (
    !allGroupByIds.value ||
    !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
  )
    return {};
  return allGroupByIds.value[props.groupId];
});
</script>

<template>
  <div
    class="w-full flex items-center"
    :class="isShowMembers ? 'justify-between' : 'justify-start'"
  >
    <div
      v-vig-tooltip="groupDetail?.name || groupName"
      class="flex items-center space-x-2 font-sans"
    >
      <div
        class="relative flex-center cursor-pointer"
        :class="avatarClass || 'w-6 h-6'"
      >
        <SynAvatar
          :custom-class="avatarClass || 'w-6 h-6'"
          :src="groupDetail?.avatarUrl || groupAvatar"
          type="group"
          :name="groupDetail?.name || groupName"
        />
        <div
          v-if="groupDetail?.code == 'DELETED_GROUP'"
          class="
            bg-white
            flex-center
            rounded-full
            absolute
            right-0
            bottom-0
            w-2/3
            h-2/3
          "
        >
          <SynIcon
            class="fill-current"
            custom-class="w-2/3 h-2/3"
            name="cancel"
          />
        </div>
        <div
          v-if="
            isShowMyGroupSignal &&
            groupDetail?.isMember &&
            groupDetail?.code !== 'DELETED_GROUP'
          "
          class="
            bg-white
            flex-center
            rounded-full
            absolute
            -right-1
            bottom-0
            w-2/3
            h-2/3
          "
        >
          <SynIcon
            class="fill-current"
            custom-class="w-2/3 h-2/3"
            name="user-check"
          />
        </div>
      </div>
      <span
        v-if="!isHiddenName"
        class="text-overflow-hidden-line flex flex-col flex-wrap text-underline"
      >
        <slot name="description-name"></slot>
        <span class="text-overflow-hidden-line">{{
          groupDetail?.name || groupName || $t('COMMON_LABEL_GROUP_PRIVATE2')
        }}</span>
      </span>
      <span
        v-if="isShowPrivateGroupSignal && groupDetail?.isPrivate"
        class="bg-white flex-center rounded-full w-4 h-4"
      >
        <SynIcon
          class="fill-current"
          custom-class="w-2.5 h-2.5"
          name="private"
        />
      </span>
    </div>
    <div v-if="isShowMembers" class="flex-center">
      <SynListAvatar
        :users="groupDetail?.groupUsers"
        :custom-class="'w-6 h-6 text-xs'"
      />
    </div>
  </div>
</template>
