<script setup>
import { onMounted, ref, watch } from 'vue';
import TaskDetailSharing from '@/ui/modules/task/detail/task-detail-sharing/TaskDetailSharing.vue';
import UnAuthService from '@/application/services/un-authenticate/UnAuthenticateService';
import permissionStore from '@/store/permission';
import { useRoute, useRouter } from 'vue-router';

const _useRoute = useRoute();
const _useRouter = useRouter();
const _unAuthService = UnAuthService.getInstance();

const taskDetail = ref();

watch(
  () => _useRoute.params,
  (params) => {
    _getTaskDetailBySharedId(params?.sharedId);
  }
);

onMounted(() => {
  _getTaskDetailBySharedId(_useRoute.params?.sharedId);

  _processPermissions();
});

const _getTaskDetailBySharedId = async (sharedId) => {
  const sharingInfo = await _unAuthService.getTaskShared(sharedId);

  if (!sharingInfo) return _useRouter.push({ name: 'NotFound404' });

  taskDetail.value = sharingInfo?.activeTaskInfo?.taskInfo;
};

const _processPermissions = () => {
  permissionStore().updatePermissionsByCode([
    { functionCode: 'WEB.TASK.COMMENT', permission: 'ALLOW' },
  ]);
};
</script>

<template>
  <div class="max-w-screen-lg mx-auto py-4">
    <TaskDetailSharing
      v-if="taskDetail"
      :task-detail="taskDetail"
      :activities="['COMMENT', 'TODO_LIST']"
    />
  </div>
</template>
