<script setup lang="ts">
import { ref, computed } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';

const indexResult = ref(-1);

const props = withDefaults(
  defineProps<{
    originOptions: any[];
    keyLabel: string;
    labelValue?: string;
    keySearch?: string;
    placeholder?: string;
    toggleClass?: string;
    autofocus?: boolean;
  }>(),
  {
    placeholder: 'Select ...',
    keySearch: 'key',
    keyLabel: 'key',
    autofocus: false,
    toggleClass: '',
    labelValue: '',
  }
);
const emit = defineEmits<{
  (e: 'onChoose', data: any): void;
  (e: 'onClear'): void;
}>();
const vigDropdownRef = ref<any>(null);

const isInputting = ref(false);
const keyActive = ref('');
const searchText = ref('');
const inputRef = ref(null);
const onOpenDropdownSearch = () => {
  keyActive.value = '';
  searchText.value = '';
  indexResult.value = -1;
  isInputting.value = true;

  setTimeout(() => {
    inputRef.value?.focus();
  }, 50);
};
const onCloseDropdown = () => {
  keyActive.value = '';
  searchText.value = '';
  indexResult.value = -1;
  isInputting.value = false;
};

const filteredDataList = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);
  if (!searchTxt) return props.originOptions;

  return (props.originOptions || []).filter(
    (option) =>
      option[props.keySearch] &&
      ignoreUnicode(option[props.keySearch]).includes(searchTxt)
  );
});

const scrollToActiveItem = () => {
  keyActive.value = `vig-select-${indexResult.value}`;
  let element = document.getElementById(`vig-select-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
const onKeyDownSearch = () => {
  if (!filteredDataList.value || filteredDataList.value.length == 0) return;

  indexResult.value =
    filteredDataList.value.length == 1
      ? 0
      : indexResult.value == filteredDataList.value.length - 1
      ? 0
      : indexResult.value + 1;

  scrollToActiveItem();
};
const onKeyUpSearch = () => {
  if (!filteredDataList.value || filteredDataList.value.length == 0) return;

  indexResult.value =
    filteredDataList.value.length == 1
      ? 0
      : indexResult.value <= 0
      ? filteredDataList.value.length - 1
      : indexResult.value - 1;

  scrollToActiveItem();
};

const onChooseResult = () => {
  console.log(
    '🚀 Tictop ~ indexResult.value:',
    indexResult.value,
    filteredDataList.value[indexResult.value]
  );
  emit('onChoose', filteredDataList.value[indexResult.value]);
  vigDropdownRef.value?.onForceClose && vigDropdownRef.value?.onForceClose();
};

const onSelect = (data) => {
  emit('onChoose', data);
};

const onClickInput = () => {
  indexResult.value = -1;
  vigDropdownRef.value?.onForceOpen && vigDropdownRef.value?.onForceOpen();
};

const isOpen = ref<boolean>(false);
</script>

<template>
  <div class="flex w-full" style="min-width: 12rem">
    <VigDropdown
      ref="vigDropdownRef"
      v-model:is-open="isOpen"
      class="cursor-pointer w-full"
      :arrow="false"
      hide-on-click="outside"
      z-index="60"
      placement="bottom-start"
      @on-dropdown-open="onOpenDropdownSearch"
      @on-dropdown-close="onCloseDropdown"
    >
      <template #dropdown-toggle>
        <div
          class="
            w-full
            bg-white
            border
            rounded-md
            px-3
            flex
            items-center
            justify-between
            h-10
            overflow-hidden
          "
          :class="[toggleClass, isOpen ? 'border-current-500' : '']"
          style="min-width: 12rem"
        >
          <span
            v-if="!isInputting && labelValue"
            :class="labelValue ? '' : 'text-gray-500'"
          >
            {{ labelValue }}
          </span>
          <input
            v-else
            ref="inputRef"
            v-model="searchText"
            tabindex="0"
            class="h-8 rounded truncate border-0 focus:ring-0"
            :placeholder="placeholder"
            @focus="onClickInput"
            @keyup.down="onKeyDownSearch"
            @keyup.up="onKeyUpSearch"
            @keyup.enter="onChooseResult"
          />
          <SynIcon
            v-if="labelValue"
            name="close"
            custom-class="w-3 h-3 fill-gray-500 text-gray-500"
            @click="$emit('onClear')"
          />
          <SynIcon
            v-else
            name="chevron-right"
            :custom-class="
              isInputting
                ? 'w-2 h-2 fill-gray-500 -rotate-90'
                : 'w-2 h-2 fill-gray-500 rotate-90'
            "
          />
        </div>
      </template>
      <template #dropdown-menu>
        <div class="flex flex-col" style="max-height: 25rem; min-width: 14rem">
          <div
            class="flex-1 overflow-auto small-scrollbar"
            style="scroll-behavior: smooth"
          >
            <template v-if="filteredDataList?.length <= 0">
              <div class="py-8 flex-center" style="width: 14rem">
                <syn-animation
                  name="searchIcon"
                  :loop="false"
                  stype="width: 100px; height: 100px"
                  :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                />
              </div>
            </template>
            <template v-else>
              <div class="vig-select-label">
                <div
                  v-for="(result, index) in filteredDataList"
                  :id="`vig-select-${index}`"
                  :key="result"
                  :class="
                    keyActive === `vig-select-${index}` ? 'bg-current-50' : ''
                  "
                  class="
                    px-4
                    py-2
                    flex
                    items-center
                    space-x-2
                    cursor-pointer
                    hover:bg-gray-100
                    rounded
                    text-sm
                    vig-select
                    dropdown-item
                    do-not-close-dropdown
                  "
                  @click="onSelect(result)"
                >
                  <span class="vig-select-label">{{ result[keyLabel] }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </VigDropdown>
  </div>
</template>
<style scoped>
.highlight {
  background-color: yellow;
}
</style>
