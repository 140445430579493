<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 384 384"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M192,0C85.973,0,0,85.973,0,192s85.973,192,192,192c17.707,0,32-14.293,32-32c0-8.32-3.093-15.787-8.32-21.44
			c-5.013-5.653-8-13.013-8-21.227c0-17.707,14.293-32,32-32h37.653c58.88,0,106.667-47.787,106.667-106.667
			C384,76.373,298.027,0,192,0z M74.667,192c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S92.373,192,74.667,192z
			 M138.667,106.667c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S156.373,106.667,138.667,106.667z M245.333,106.667
			c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S263.04,106.667,245.333,106.667z M309.333,192
			c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S327.04,192,309.333,192z"
          ></path>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
