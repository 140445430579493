<template>
  <svg
    v-if="!isActive"
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 32 32"
    xml:space="preserve"
  >
    <rect x="2" y="26" width="28" height="2" />
    <path
      d="M25.4,9c0.8-0.8,0.8-2,0-2.8c0,0,0,0,0,0l-3.6-3.6c-0.8-0.8-2-0.8-2.8,0c0,0,0,0,0,0l-15,15V24h6.4L25.4,9z M20.4,4L24,7.6  l-3,3L17.4,7L20.4,4z M6,22v-3.6l10-10l3.6,3.6l-10,10H6z"
    />
    <rect
      id="_Transparent_Rectangle_"
      class="st0"
      style="fill: none"
      width="32"
      height="32"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M9.243 19H21v2H3v-4.243l9.9-9.9 4.242 4.244L9.242 19zm5.07-13.556l2.122-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
