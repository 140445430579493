export class AttachmentInTask {
    photo: AttachmentTaskItem;
    media: AttachmentTaskItem;
    file: AttachmentTaskItem;
    constructor(data: any) {
        this.photo = data?.photo || {};
        this.media = data?.media || {};
        this.file = data?.file || {};
    }
}

export class AttachmentTaskItem {
    maxFileSize: number;
    maxNumberOfFile: number;
    approveFileContentTypes: any[];
    constructor(data: any) {
        this.maxFileSize = data?.maxFileSize || 0;
        this.maxNumberOfFile = data?.maxNumberOfFile || 0;
        this.approveFileContentTypes = data?.approveFileContentTypes || [];
    }
}
