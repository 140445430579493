<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 512.086 512.086"
    style="enable-background: new 0 0 512.086 512.086"
    xml:space="preserve"
  >
    <path
      style="fill: #2196f3"
      d="M281.963,245.846l-247.68,247.68c-11.84-11.2-18.24-26.56-18.24-43.2V61.846  c0-16.96,6.72-32.32,18.88-43.84L281.963,245.846z"
    />
    <path
      style="fill: #ffc107"
      d="M496.043,256.086c0,22.4-12.16,42.24-32.32,53.44l-70.4,39.04l-87.36-80.64l-24-22.08l92.48-92.48  l89.28,49.28C483.883,213.846,496.043,233.686,496.043,256.086z"
    />
    <path
      style="fill: #4caf50"
      d="M281.963,245.846L34.923,18.006c3.2-3.2,7.36-6.08,11.52-8.64c20.16-12.16,44.48-12.48,65.28-0.96  l262.72,144.96L281.963,245.846z"
    />
    <path
      style="fill: #f44336"
      d="M393.323,348.566l-281.6,155.2c-9.92,5.76-21.12,8.32-32,8.32c-11.52,0-23.04-2.88-33.28-9.28  c-4.48-2.56-8.64-5.76-12.16-9.28l247.68-247.68l24,22.08L393.323,348.566z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
