<script setup lang="ts">
import { computed, provide, inject } from 'vue';
import { useRoute } from 'vue-router';
// import { MenuItem } from '@/ui/components/menu/menu-type';
import { menuListType } from '@/ui/pages/overview/dashboard-type';
import FilterGroupOverview from '@/ui/components/overview/FilterGroupOverview.vue';
import FilterUserOverview from '@/ui/components/overview/FilterUserOverview.vue';
import FilterDomainOverview from '@/ui/components/overview/FilterDomainOverview.vue';
// import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
// import { AdminType } from '@/ui/common/constants/constant';
import { dashboardMenuList, isOpenModalInvite } from '@/ui/app-global-state';
// const permission: any = inject('permission');
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import { checkPermissionByScreen } from '@/ui/hooks/permission/permission-by-function';
import { EScreenPermission } from '@/application/types/block-function.types';
import router from '@/ui/router';

const route = useRoute();

provide(
  'IS_HIDDEN_BLOCK_PAGE',
  checkPermissionByScreen(ALL_SCREENS.COMMON.OVERVIEW.ALL) ==
    EScreenPermission.LOW_VISION
);

const checkSystemConfig: Function | undefined = inject('checkSystemConfig');
const checkPersonalConfig: Function | undefined = inject('checkPersonalConfig');

const filteredDashboardMenuList = computed<any[]>(() => {
  let result = dashboardMenuList.value;

  result = result?.filter((func) => {
    if (
      !func.permissionBySettings ||
      func.permissionBySettings?.length == 0 ||
      !checkSystemConfig
    )
      return true;

    return checkSystemConfig(func.permissionBySettings);
  });
  result = result?.filter((func) => {
    if (
      !func.permissionByPersonalSettings ||
      func.permissionByPersonalSettings?.length == 0 ||
      !checkPersonalConfig
    )
      return true;

    return checkPersonalConfig(func.permissionByPersonalSettings);
  });
  return result;
});
const currentActive = computed(() => {
  return route.name ? route.name.toString().toLowerCase() : '';
});

// const filteredDashboardMenuList = computed(() => {
//   if (!permission('OverviewPage')) return [];

//   let menuResult = menuListDefault;
//   if (!domainManagement.value.showOnTab)
//     menuResult = menuResult?.filter((el) => el.name !== 'OverviewDomains');

//   if (hasRolePermission(AdminType.Owner) || hasRolePermission(AdminType.Admin))
//     menuResult = [...menuListForAdmin, ...menuResult, ...menuListForAdmin2];
//   else menuResult = [...menuResult, ...menuListForUser];

//   return menuResult?.filter((menu) =>
//     allRouteByPermission.value?.some((route) => route.name == menu.name)
//   );
// });

const onInviteUser = () => {
  isOpenModalInvite.value = true;
};

const currentMenuIndex = computed<any>(() => {
  return filteredDashboardMenuList.value?.findIndex(
    (o) => currentActive.value?.toLowerCase() === o?.name?.toLowerCase()
  );
});

const onBackRoute = () => {
  if (currentMenuIndex.value <= 0) return;

  const newRoute = filteredDashboardMenuList.value[currentMenuIndex.value - 1];
  router.push({
    name: newRoute?.name,
  });
};
const onNextRoute = () => {
  if (currentMenuIndex.value >= filteredDashboardMenuList.value?.length) return;

  const newRoute = filteredDashboardMenuList.value[currentMenuIndex.value + 1];
  router.push({
    name: newRoute?.name,
  });
};
</script>
<template>
  <div class="flex flex-col h-full w-full">
    <div
      class="
        flex
        items-center
        justify-between
        bg-white
        px-1
        text-gray-800 text-sm
        shadow
        space-x-2
        z-[1]
      "
    >
      <!-- EXTEND FULL SCREEN -->
      <div class="flex-1 flex items-center space-x-2 relative">
        <div></div>
        <div class="w-4/5 flex-center space-x-1 font-medium">
          <RouterLink
            v-for="(menu, index) in filteredDashboardMenuList"
            :key="index"
            v-system-config="menu?.permissionBySettings"
            v-personal-config="menu?.permissionByPersonalSettings"
            :to="{ name: menu.name }"
          >
            <div
              class="
                group
                font-medium
                px-2
                pt-4
                text-sm
                flex flex-col
                justify-between
              "
              :class="
                currentActive?.toLowerCase() === menu.name?.toLowerCase()
                  ? 'text-current-500 fill-current'
                  : 'fill-gray-500'
              "
            >
              <div class="px-2">
                <SynIcon
                  class="block xl:hidden group-hover:fill-current"
                  custom-class="w-5 h-5"
                  :name="menu?.icon"
                  :is-active="
                    currentActive?.toLowerCase() === menu.name?.toLowerCase()
                  "
                />
                <span class="hidden xl:block group-hover:text-current-500">
                  {{ $t(menu.titleKey) || menu.title }}</span
                >
              </div>

              <div
                class="border-b-2 mt-3.5 border-current-500 group-hover:w-full"
                style="transition: width 150ms ease-in-out"
                :class="
                  currentActive?.toLowerCase() === menu.name?.toLowerCase()
                    ? 'w-full'
                    : 'w-0'
                "
              ></div>
            </div>
          </RouterLink>
        </div>
      </div>
      <div class="absolute right-2">
        <FilterGroupOverview
          v-if="
            currentActive?.toLowerCase() === menuListType.Groups?.toLowerCase()
          "
        />
        <FilterUserOverview
          v-if="
            currentActive?.toLowerCase() === menuListType.Members?.toLowerCase()
          "
        />
        <FilterDomainOverview
          v-if="
            currentActive?.toLowerCase() === menuListType.Domains?.toLowerCase()
          "
          v-permission="'DomainOverview'"
        />

        <!-- ADD   -->
        <template
          v-if="
            currentActive?.toLowerCase() ===
              menuListType.Monitor?.toLowerCase() &&
            $permission('FunctionOrganizationInvitation')
          "
        >
          <SynButton
            v-vig-tooltip="{
              content: `
              <div class='rounded px-2 py-4 w-48 text-center bg-white text-current-500'>
                <span class='text-md'>${$t('SETUP_TEAM_INVITE_DES')}</span>
              </div>`,
              arrow: true,
              placement: 'bottom',
              delayArr: [0, 500],
              theme: 'light',
              animation: 'scale',
              interactive: true,
              showOnCreate: true,
              zIndex: 40,
              hideAfter: true,
            }"
            v-permission="'FunctionOrganizationInvitation'"
            custom-class="rounded text-xs py-0.5 h-10"
            color="current"
            @click="onInviteUser()"
          >
            <div class="flex-center space-x-2 py-0.5">
              <SynIcon name="AddMember" custom-class="fill-white w-6 h-6" />
              <span>{{ $t('USERMANAGEMENT_LABEL_INVITE_USER') }}</span>
            </div>
          </SynButton>
        </template>
      </div>
    </div>
    <div
      v-permission-screen="{
        screenCode: ALL_SCREENS.COMMON.OVERVIEW.ALL,
      }"
      class="flex-1 w-full h-full min-h-0 relative hover-to-show__parent"
    >
      <slot></slot>

      <template v-if="false">
        <div
          v-if="currentMenuIndex > 0"
          class="
            h-10
            w-10
            rounded-full
            bg-current-100
            opacity-50
            hover:opacity-100
            absolute
            left-1
            flex-center
            z-100
            top-1/3
            cursor-pointer
          "
          @click="onBackRoute"
        >
          <SynIcon name="ArrowLeft" custom-class="w-6 h-6" />
        </div>
        <div
          v-if="currentMenuIndex + 1 < filteredDashboardMenuList?.length"
          class="
            h-10
            w-10
            rounded-full
            bg-current-100
            opacity-50
            hover:opacity-100
            absolute
            right-1
            flex-center
            z-100
            top-1/3
            cursor-pointer
          "
          @click="onNextRoute"
        >
          <SynIcon name="ArrowRight" custom-class="w-6 h-6" />
        </div>
      </template>
    </div>
  </div>
</template>
