<script setup lang="ts">
import WorkflowStepTypeEntity from '@/domain/entities/workflow/WorkflowStepTypeEntity';
import WorkflowStepTypeTable from '@/ui/modules/workflow/workflow-step-type/WorkflowStepTypeTable.vue';
import { ref } from 'vue';
import WorkflowStepTypeDetailModal from '@/ui/modules/workflow/workflow-step-type/WorkflowStepTypeDetailModal.vue';
import { IStepTemplateEntity } from '@/domain/entities/workflow/StepTemplateEntity';

const searchText = ref<string>('');
const addNewStepPayload = ref<WorkflowStepTypeEntity>(
  new WorkflowStepTypeEntity({})
);
const isOpenAddNewModal = ref<boolean>(false);
const addNewStep = () => {
  addNewStepPayload.value = new WorkflowStepTypeEntity({});

  isOpenAddNewModal.value = true;
};

const previewStep = ref<IStepTemplateEntity | null>(null);
const onPreviewStep = (selectedStep) => {
  previewStep.value = selectedStep;
};

const onDuplicateStep = (step) => {
  addNewStepPayload.value = new WorkflowStepTypeEntity(step);
  isOpenAddNewModal.value = true;
};
const onReloadStepTable = () => {
  searchText.value = '';
};

const workflowStepTableRef = ref<any>(null);
const onCreateNewStepSuccess = (newStepType) => {
  searchText.value = '';

  workflowStepTableRef.value?.updateStepSuccess &&
    workflowStepTableRef.value?.updateStepSuccess(newStepType);

  isOpenAddNewModal.value = false;
};
</script>
<template>
  <div class="w-full h-full flex flex-col">
    <div class="w-full flex flex-col items-center justify-between p-2 gap-2">
      <div class="flex w-full justify-start">
        <span class="font-semibold text-current-500">
          {{ $t('WORKFLOW_LABEl_STEP_TYPES') }}
        </span>
      </div>
      <div class="w-full flex items-center justify-between">
        <VigSearchBox
          v-model="searchText"
          input-class="text-sm"
          style="width: 20rem"
          autofocus
          :placeholder="$t('COMMON_LABEL_SEARCH')"
        />
        <button
          class="w-8 h-8 rounded cursor-pointer flex-center bg-current-500"
          @click="addNewStep"
        >
          <SynIcon name="plus" custom-class="w-4 h-4 fill-white" />
        </button>
      </div>
    </div>
    <div class="flex-1 min-h-0 overflow-auto small-scrollbar">
      <WorkflowStepTypeTable
        ref="workflowStepTableRef"
        :action-list="['EDIT', 'REMOVE']"
        :search-text="searchText"
        @choose="onPreviewStep"
        @duplicate-step="onDuplicateStep"
        @reload="onReloadStepTable"
      />
    </div>
  </div>

  <WorkflowStepTypeDetailModal
    v-if="isOpenAddNewModal"
    :view-mode="'EDIT'"
    :step-type-detail="addNewStepPayload"
    @close="isOpenAddNewModal = false"
    @update-success="onCreateNewStepSuccess"
  />
</template>
