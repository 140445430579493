import { ref, watch } from 'vue';

export default function inputValueComp(validateFunc?) {
    const value = ref('');

    const errorMessage = ref('');
    const checkErrorMessage = () => {
        const result = validateFunc ? validateFunc(value.value) : '';
        return typeof result == 'string' ? result : '';
    };

    watch(
        () => value.value,
        () => {
            errorMessage.value = checkErrorMessage();
        }
    );
    return {
        value,
        errorMessage,
    };
}
