import { ITaskTodo } from '@/application/types/task/task.types';
import ShapeEntity from './ShapeEntity';

export default class ShapeTodoListEntity extends ShapeEntity {
    sourceData?: ITaskTodo;
    constructor(data) {
        super(data);
        this.sourceData =
            typeof data?.sourceData == 'string'
                ? JSON.parse(data?.sourceData)
                : data?.sourceData;
    }
}
