<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M587 4852 c-16 -17 -17 -175 -17 -2070 l0 -2052 -145 0 c-142 0 -147 -1 -170 -25 -30 -29 -31 -54 -4 -88 20 -26 22 -27 167 -29 l147 -3 3 -145 c3 -161 10 -180 67 -180 60 0 70 23 73 186 l3 144 2059 0 c1985 0 2059 1 2081 18 31 26 31 78 0 104 -22 17 -96 18 -2082 18 l-2059 0 -2 2049 c-3 1999 -3 2049 -22 2070 -24 26 -77 28 -99 3z"
      />
      <path
        d="M810 4130 l0 -400 740 0 c727 0 740 0 760 20 19 19 20 33 20 379 l0 360 -26 20 c-26 21 -33 21 -760 21 l-734 0 0 -400z"
      />
      <path
        d="M810 2810 l0 -400 1829 0 1828 0 27 21 26 20 0 359 c0 357 0 359 -22 380 -22 20 -24 20 -1855 20 l-1833 0 0 -400z"
      />
      <path
        d="M810 1495 l0 -395 1288 0 c708 0 1292 4 1298 8 31 20 34 54 34 387 0 333 -3 367 -34 387 -6 4 -590 8 -1298 8 l-1288 0 0 -395z"
      />
    </g>
  </svg>
</template>
