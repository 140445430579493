<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import organizationStore from '@/store/organization';
import { getCompanyOwnName } from '@/ui/hooks/commonFunction';
import OrganizationListModal from '@/ui/modules/organization/OrganizationListModal.vue';
import myProfileStore from '@/store/auth/my-profile';
import UserDocModel from '@/ui/plugins/firebases/firestore-database/models/UserDocModel';
import { onListenEventFirebase } from '@/ui/plugins/firebases/firebaseRealtimeDatabase';

const _organizationStore = organizationStore();
const myProfile = computed(() => myProfileStore().myProfile);

const activeOrganization = computed(
  () => _organizationStore.organizationInfo
) as any;
const isOpenNotActiveOrganization = ref(false);

const hasNotificationInNotActiveOrganization = computed(() => {
  return _organizationStore.organizationIds?.some((orgId) => {
    const orgDetail = _organizationStore.organizationByIds[orgId];
    return (
      orgId !== activeOrganization.value?.id &&
      orgDetail &&
      (orgDetail?.notificationCount > 0 ||
        orgDetail?.unSeenConversationsCount > 0)
    );
  });
});

let _unsubscribeFirebaseStores = [] as any[];
let _unsubscribeFirebaseDbs = [] as any[];

const subscribeRealtimeByOrganization = (organizationId) => {
  const userId = myProfile?.value?.id;
  if (!userId) return;

  const _unsub = new UserDocModel(userId).subscribleDataChangeByOrganizationId(
    {
      organizationId,
      userId,
    },
    (change) => {
      const _unSeenConversations = change?.unSeenConversations || {};
      const unSeenConverIds = Object.keys(_unSeenConversations).filter(
        (converId) => _unSeenConversations[converId]
      );
      _organizationStore.updateListOrganizationById(organizationId, {
        unSeenConversationsCount: unSeenConverIds.length || 0,
      });
    }
  );
  _unsubscribeFirebaseStores.push(_unsub);

  const totalNotificationPath = `users_dynamic_info/organization_${organizationId}/user_${userId}/totalNotification`;
  const _unscribleFb = onListenEventFirebase(
    totalNotificationPath,
    async (data) => {
      _organizationStore.updateListOrganizationById(organizationId, {
        notificationCount: data.val() || 0,
      });
    }
  );
  _unsubscribeFirebaseDbs.push(_unscribleFb);
};

const initData = async () => {
  await _organizationStore.getOrganizationConfigDefault();
  await handleGetDataOrganization();
};

const handleGetDataOrganization = async () => {
  const allOrganizations = await _organizationStore.getUserOrganizations();

  allOrganizations
    // ?.filter((org) => org?.id !== activeOrganization.value?.id)
    .forEach((org) => {
      const organizationId = org?.id;
      if (!organizationId) return;

      subscribeRealtimeByOrganization(organizationId);
    });
};

onMounted(() => {
  initData();
});

onUnmounted(() => {
  _unsubscribeFirebaseStores.forEach((unscribleFn) => {
    unscribleFn && unscribleFn();
  });
  _unsubscribeFirebaseDbs.forEach((unscribleFn) => {
    unscribleFn && unscribleFn();
  });
});

const isOpenOrganizationListModal = ref(false);

const onOpenOrganizationList = () => {
  isOpenOrganizationListModal.value = true;
  onReloadOrganization();
};

const onReloadOrganization = async () => {
  try {
    await handleGetDataOrganization();
  } catch (error) {
    console.log(
      '🚀 ~ file: OrganizationHeader.vue ~ line 112 ~ onReloadOrganization ~ error',
      error
    );
  }
};
</script>
<template>
  <div
    class="
      relative
      cursor-pointer
      hover:bg-current-50
      rounded-full
      py-1
      pr-2
      pl-1
    "
    @click="onOpenOrganizationList"
  >
    <SynAvatar
      has-name
      :class="isOpenNotActiveOrganization ? 'bg-current-50 ' : ''"
      :name="getCompanyOwnName(activeOrganization?.name)"
      :src="activeOrganization?.logoUrl"
      custom-class="w-8 h-8 ring ring-current ring-opacity-30 border border-current"
      type="group"
    >
      <template #name>
        <div
          class="
            font-medium
            text-gray-800
            overflow-hidden
            flex-nowrap
            text-ellipsis
            hidden
            xl:flex
          "
          style="white-space: nowrap; max-width: 10rem"
        >
          {{ getCompanyOwnName(activeOrganization?.name) }}
        </div>
      </template>
    </SynAvatar>
    <span
      v-if="hasNotificationInNotActiveOrganization"
      class="flex h-2.5 w-2.5 absolute -top-0 -right-0"
    >
      <span
        class="
          animate-ping
          absolute
          inline-flex
          h-full
          w-full
          rounded-full
          bg-red-400
          opacity-75
        "
      ></span>
      <span
        class="relative inline-flex rounded-full h-2.5 w-2.5 bg-red-500"
      ></span>
    </span>
  </div>

  <OrganizationListModal
    v-if="isOpenOrganizationListModal"
    @on-reload="onReloadOrganization"
    @on-cancel="isOpenOrganizationListModal = false"
  />
</template>
