import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';

import {
    EFilterTaskBoard,
    EFilterTaskDefault,
    ETaskViewType,
    LIST_FILTER_TASK_BOARD_DEFAULT,
    LIST_FILTER_TASK_DEFAULT,
    TabTask,
} from '@/application/constants/task.const';
import {
    ECustomFilterSourceType,
    ETaskListModule,
} from '@/application/types/task/task.types';
import taskListStore from '@/store/task/task-list-store';
import commonStore from '@/store/common/commonStore';
import { CurrentUserConfig } from '@/ui/common/constants/constant';

export default defineStore({
    id: storeId.taskTabView,
    state: () => ({
        _taskService: TaskService.getInstance(),
        customizeView: ETaskViewType.LIST as ETaskViewType,
        listTabs: Object.values(LIST_FILTER_TASK_DEFAULT) as TabTask[],
        listBoardTabs: LIST_FILTER_TASK_BOARD_DEFAULT as any[],
        listCustomTabs: [] as ICustomTaskFilter[],
        childrenTabActive: EFilterTaskDefault.DEFAULT as EFilterTaskDefault,
        defaultTab: EFilterTaskDefault.DEFAULT as EFilterTaskDefault,
        currentTabId: '',
        boardCurrentTabId: EFilterTaskBoard.DEFAULT,
        boardShowFinishedTask: false,
        boardShowFinishedRelatedTask: false,
        boardShowFinishedTaskByDeadline: false,
        boardShowFinishedPinnedTask: false,
    }),
    getters: {},
    actions: {
        setCustomizeViewActive(view?: ETaskViewType) {
            let result = ETaskViewType.LIST;

            const viewDefault =
                commonStore().userConfigByKeys[
                    CurrentUserConfig.ViewTaskListDefault
                ];
            if (
                viewDefault &&
                [
                    ETaskViewType.LIST,
                    ETaskViewType.BOARD,
                    ETaskViewType.GANTT,
                    ETaskViewType.CALENDAR,
                ].includes(viewDefault)
            )
                result = viewDefault;

            if (
                view &&
                [
                    ETaskViewType.LIST,
                    ETaskViewType.BOARD,
                    ETaskViewType.GANTT,
                    ETaskViewType.CALENDAR,
                ].includes(view)
            )
                result = view;

            this.customizeView = result;
        },

        setChildrenTabActive(value) {
            this.childrenTabActive = value;
        },

        changeTabActive(tabId) {
            taskListStore().setFilterDataOrigin();
            this.childrenTabActive = tabId;

            this.currentTabId = tabId;
        },
        updateDefaultTab(tabId) {
            this.defaultTab = tabId;
        },
        changeCustomTab(tabActive: ICustomTaskFilter) {
            taskListStore().setFilterDataOrigin();
            this.currentTabId = tabActive?.id;
        },
        changeCustomTabInBoard(tabId: EFilterTaskBoard) {
            this.boardCurrentTabId = tabId;
        },

        setCollapseBoard(tabId) {
            const index = this.listTabs?.findIndex((o) => o?.value === tabId);
            if (index === -1) return;
            this.listTabs[index].isCollapse = !this.listTabs[index].isCollapse;
        },
        async getUserCustomTaskFilters(
            sourceType: ETaskListModule | ECustomFilterSourceType,
            sourceId: number
        ) {
            const res = await this._taskService.getUserCustomTaskFilters(
                sourceType,
                sourceId
            );
            this.listCustomTabs = res?.result || [];
            return this.listCustomTabs;
        },
        async addUserCustomTaskFilter(
            sourceType: ETaskListModule | ECustomFilterSourceType,
            sourceId: number,
            data: ICustomTaskFilter
        ) {
            console.log('🚀 Tictop ~ sourceType:', {
                sourceType,
                sourceId,
                data,
            });
            this.listCustomTabs = [...this.listCustomTabs, data];
            const res = await this._taskService.addUserCustomTaskFilter(
                sourceType,
                sourceId,
                data
            );

            if (res?.result && this.listCustomTabs?.length > 0)
                this.listCustomTabs[this.listCustomTabs.length - 1] =
                    res?.result;
            if (this.currentTabId === data?.id) {
                this.currentTabId = res?.result?.id;
            }
        },
        async updateUserCustomTaskFilterFilter(
            sourceType: ETaskListModule,
            sourceId: number,
            data: ICustomTaskFilter
        ) {
            const index = this.listCustomTabs?.findIndex(
                (tab) => tab?.id === data?.id
            );
            this.listCustomTabs[index] = data;

            this._taskService.updateUserCustomTaskFilterFilter(
                sourceType,
                sourceId,
                data
            );
        },
        async deleteUserCustomTaskFilter(
            sourceType: ETaskListModule,
            sourceId: number,
            id: string
        ) {
            this.listCustomTabs = this.listCustomTabs?.filter(
                (tab) => tab?.id !== id
            );

            this._taskService.deleteUserCustomTaskFilter(
                sourceType,
                sourceId,
                id
            );
        },
        onChangeBoardShowFinishedTask(value: boolean) {
            this.boardShowFinishedTask = value;
        },
        onChangeBoardShowFinishedRelatedTask(value: boolean) {
            this.boardShowFinishedRelatedTask = value;
        },
        onShowFinishedTaskInDeadline(value: boolean) {
            this.boardShowFinishedTaskByDeadline = value;
        },
        onChangeBoardShowFinishedPinnedTask(value: boolean) {
            this.boardShowFinishedPinnedTask = value;
        },
    },
});
