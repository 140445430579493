<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
// import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import myProfileStore from '@/store/auth/my-profile';
import AtomDatePicker from '@/ui/common/atoms/date-pickers/AtomDatePicker.vue';

dayjs.extend(utc);
dayjs.extend(tz);

const props = defineProps<{
  time: any;
  timeFrom: any;
  isHiddenTime?: boolean;
}>();

const emit = defineEmits(['chooseTimeStart', 'chooseTimeEnd', 'change']);

const utcDate = computed(() => myProfileStore().getTimezone);
const timezone = computed(() => {
  let timeZone = myProfileStore().getTimezone;
  if (timeZone == '+7') return 'Asia/Bangkok';
  if (timeZone == '-5') {
    return 'America/New_York';
  } else {
    return 'Europe/Paris';
  }
});
// const locate = getLocalStorage(StorageConstant.LANGUAGE);
// const modelConfig = ref({ type: 'string', mask: 'YYYY-MM-DD' });
const masks = ref({ input: 'WWWW, DD MMM, YYYY' });

const filterdatetimeCurrent = computed(() => {
  let objDate = {
    hour: dayjs(new Date()).tz(timezone.value).get('hour'),
    minute: dayjs(new Date()).tz(timezone.value).get('minute'),
    day: dayjs(new Date()).tz(timezone.value).format('MM-DD-YYYY'),
  };
  return objDate;
});

const timeData = ref(props.time);

const filterDatetimeStart = computed(() => {
  let objDate = {
    hour: timeData.value
      ? dayjs(new Date(timeData.value)).tz(timezone.value).get('hour')
      : null,
    minute: timeData.value
      ? dayjs(new Date(timeData.value)).tz(timezone.value).get('minute')
      : null,
    text: timeData.value
      ? dayjs(new Date(timeData.value)).tz(timezone.value).format('hh:mm A')
      : null,
    day: timeData.value
      ? dayjs(new Date(timeData.value)).tz(timezone.value).format('MM-DD-YYYY')
      : null,
  };

  return objDate;
});

const filterDatetimeEnd = computed(() => {
  let objDate = {
    hour: props.timeFrom
      ? dayjs(new Date(props.timeFrom)).tz(timezone.value).get('hour')
      : null,
    minute: props.timeFrom
      ? dayjs(new Date(props.timeFrom)).tz(timezone.value).get('minute')
      : null,
    text: props.timeFrom
      ? dayjs(new Date(props.timeFrom)).tz(timezone.value).format('hh:mm A')
      : null,
    day: props.timeFrom
      ? dayjs(new Date(props.timeFrom)).tz(timezone.value).format('MM-DD-YYYY')
      : null,
  };
  return objDate;
});
const dataDateStart = ref<{
  hour: number | null;
  minute: number | null;
  text: string | null;
}>(filterDatetimeStart.value);
const dataDateEnd = ref<{
  hour: number | null;
  minute: number | null;
  text: string | null;
}>(filterDatetimeEnd.value);

const indexApart = computed<number | null>(() => {
  if (dataDateStart.value?.hour == null || dataDateEnd.value?.hour == null)
    return null;

  const startIndex = timeAroundDayArr.value?.findIndex(
    (o) =>
      o?.hour == dataDateStart.value?.hour &&
      o?.minute == dataDateStart.value?.minute &&
      o?.text == dataDateStart.value?.text
  );
  const endIndex = timeAroundDayArr.value?.findIndex(
    (o) =>
      o?.hour == dataDateEnd.value?.hour &&
      o?.minute == dataDateEnd.value?.minute &&
      o?.text == dataDateEnd.value?.text
  );

  if (startIndex == -1 || endIndex == -1) return null;

  return Math.max(endIndex - startIndex, 0);
});

const indexStartTimeEnd = ref(0);
const indexCurrentTimeStart = ref(0);
const indexCurrentTimeEnd = ref(2);
const isOpenTimeStart = ref(false);
const isOpenTimeEnd = ref(false);

const timeAroundDayArr = computed(() => {
  let arr: any = [];
  let index = 0;
  let current = new Date();
  current.setHours(0, 0, 0, 0);
  let times = dayjs(current);
  while (index < 96) {
    let valueTime = dayjs(times).add(15, 'minute');
    let minute = valueTime.get('hour') * 60 + valueTime.get('minute');
    arr.push({
      offset: minute,
      hour: valueTime.get('hour'),
      minute: valueTime.get('minute'),
      text: valueTime.format('hh:mm A'),
      timejs: valueTime,
    });
    times = dayjs(times).add(15, 'minute');
    index++;
  }

  return (arr as any) || ([] as any);
});

const handleCalculateDurationTime = (timeEnd) => {
  let daysjsStart = dataDateStart.value.hour * 60 + dataDateStart.value.minute;
  let daysjsEnd = timeEnd.hour * 60 + timeEnd.minute;

  let minutes = daysjsEnd - daysjsStart;
  let hour = minutes / 60;
  if (minutes < 0) return '';
  if (hour <= 1) {
    return (
      '(' + translate('CALENDAR_LABEL_TIME_MINUTES', { minutes: minutes }) + ')'
    );
  } else {
    return '(' + translate('CALENDAR_LABEL_TIME_HOUR', { hour: hour }) + ')';
  }
};

const formatDatetimeUTC = (datetime) => {
  const dateString =
    (
      datetime.day +
      ' ' +
      (datetime.hour || '00') +
      ':' +
      (datetime.minute || '00')
    ).replace(/-/g, '/') +
    ' ' +
    'GMT' +
    utcDate.value +
    '00';

  return new Date(dateString);
};

const setDatetimeData = (timeStart, timeDate) => {
  if (timeStart) {
    dataDateStart.value.hour = timeStart.hour;
    dataDateStart.value.minute = timeStart.minute;
    dataDateStart.value.text = timeStart.text;
  }

  if (timeDate) {
    dataDateEnd.value.hour = timeDate.hour;
    dataDateEnd.value.minute = timeDate.minute;
    dataDateEnd.value.text = timeDate.text;
  }
};

const handleTimeCurrent = () => {
  for (let i = 0; i < timeAroundDayArr.value.length; i++) {
    let offsetCurrent =
      filterdatetimeCurrent.value.hour * 60 +
      filterdatetimeCurrent.value.minute;
    if (
      i > 0 &&
      offsetCurrent <= timeAroundDayArr.value[i].offset &&
      offsetCurrent > timeAroundDayArr.value[i - 1].offset
    ) {
      setDatetimeData(timeAroundDayArr.value[i], timeAroundDayArr.value[i + 2]);

      indexCurrentTimeStart.value = i;
      indexStartTimeEnd.value = i;
    }
  }
};

onMounted(async () => {
  await nextTick();
  if (props.timeFrom) {
    // dataDateStart.value = { ...filterDatetimeStart.value };
    //dataDateEnd.value = { ...filterDatetimeEnd.value };

    for (let i = 0; i < timeAroundDayArr.value.length; i++) {
      if (
        timeAroundDayArr.value[i].hour === dataDateStart.value.hour &&
        dataDateStart.value.minute == timeAroundDayArr.value[i].minute
      ) {
        indexCurrentTimeStart.value = i;
      }

      if (
        timeAroundDayArr.value[i].hour === dataDateStart.value.hour &&
        dataDateEnd.value.minute == timeAroundDayArr.value[i].minute
      ) {
        indexStartTimeEnd.value = i;
      }
    }
  } else {
    dataDateEnd.value = { ...filterDatetimeStart.value };
    handleTimeCurrent();
  }

  emit('chooseTimeStart', formatDatetimeUTC(dataDateStart.value));
  emit('chooseTimeEnd', formatDatetimeUTC(dataDateEnd.value));
});

const onChooseTime = (_time, key, index) => {
  if (key == 'start') {
    console.log('🚀 Tictop ~ indexApart.value:', indexApart.value);
    const newEndIndex =
      indexApart.value == null
        ? null
        : Math.min(
            index + indexApart.value,
            timeAroundDayArr.value?.length - 1
          );

    if (newEndIndex == null) {
      setDatetimeData(_time, _time);
      indexCurrentTimeEnd.value = index;
    } else {
      setDatetimeData(_time, timeAroundDayArr.value[newEndIndex]);
      indexCurrentTimeEnd.value = newEndIndex;
    }

    indexCurrentTimeStart.value = index;
    indexStartTimeEnd.value = index;
  } else {
    indexCurrentTimeEnd.value = index;
    dataDateEnd.value = {
      hour: _time.hour,
      minute: _time.minute,
      text: _time.text,
      day: dataDateEnd.value.day,
    };
  }

  emit('change');
  emit('chooseTimeStart', formatDatetimeUTC(dataDateStart.value));
  emit('chooseTimeEnd', formatDatetimeUTC(dataDateEnd.value));
};

let timer: any = null;
// HANDLE SCROLL TIME
const onChangeTime = async (type) => {
  isOpenTimeStart.value = true;
  if (timer) clearTimeout(timer);
  timer = setTimeout(async () => {
    let elementStart = document.getElementById(
      `calendar-timerange-start-${indexCurrentTimeStart.value}`
    );
    let elementEnd = document.getElementById(
      `calendar-timerange-end-${indexCurrentTimeEnd.value}`
    );
    if (type == 'start') {
      elementStart && elementStart.scrollIntoView({ block: 'center' });
    } else {
      elementEnd && elementEnd.scrollIntoView({ block: 'center' });
    }
  }, 100);
};

const onChangeDate = (date) => {
  const newDate = dayjs(date).format('YYYY-MM-DD');
  dataDateStart.value.day = newDate;
  dataDateEnd.value.day = newDate;
  emit('change');
  emit('chooseTimeStart', formatDatetimeUTC(dataDateStart.value));
  emit('chooseTimeEnd', formatDatetimeUTC(dataDateEnd.value));
};
</script>

<template>
  <div class="flex items-center space-x-1">
    <div class="flex-1">
      <AtomDatePicker
        v-model="timeData"
        :masks="masks"
        custom-input-class="vig-input"
        @update:model-value="(value) => onChangeDate(value)"
      />
    </div>

    <template v-if="!isHiddenTime">
      <VigDropdown
        :arrow="false"
        placement="bottom"
        @on-dropdown-open="onChangeTime('start')"
        @on-dropdown-close="isOpenTimeStart = false"
      >
        <template #dropdown-toggle>
          <div
            class="
              w-20
              flex-center
              rounded
              border-transparent
              hover:bg-current-50
              bg-current-50 bg-opacity-60
              py-1
              px-1
              cursor-pointer
              flex-center
              focus:border-current
              focus:ring
              focus:border
              focus:ring-current
              focus:ring-opacity-30
            "
          >
            {{ dataDateStart?.text }}
          </div>
        </template>
        <template #dropdown-menu>
          <div
            v-perfect-scrollbar
            class="w-full h-max max-h-56 overflow-auto small-scrollbar"
          >
            <div
              v-for="(hour, index) in timeAroundDayArr"
              :id="`calendar-timerange-start-${index}`"
              :key="hour.offset"
              class="dropdown-item cursor-pointer hover:bg-gray-100 px-4 py-1"
              :class="indexCurrentTimeStart == index ? 'bg-gray-100' : ''"
              @click="onChooseTime(hour, 'start', index)"
            >
              {{ hour.text }}
            </div>
          </div>
        </template>
      </VigDropdown>

      <span>-</span>

      <VigDropdown
        :arrow="false"
        placement="bottom"
        @on-dropdown-open="onChangeTime('end')"
        @on-dropdown-close="isOpenTimeEnd = false"
      >
        <template #dropdown-toggle>
          <div
            class="
              w-20
              flex-center
              rounded
              border-transparent
              hover:bg-current-50
              bg-current-50 bg-opacity-60
              py-1
              px-1
              cursor-pointer
              flex-center
              focus:border-current
              focus:ring
              focus:border
              focus:ring-current
              focus:ring-opacity-30
            "
          >
            {{ dataDateEnd?.text }}
          </div>
        </template>
        <template #dropdown-menu>
          <div
            v-perfect-scrollbar
            class="h-max w-max max-h-56 overflow-auto small-scrollbar"
          >
            <div
              v-for="(hour, index) in timeAroundDayArr.slice(indexStartTimeEnd)"
              :id="`calendar-timerange-end-${index}`"
              :key="hour.offset"
              class="dropdown-item cursor-pointer hover:bg-gray-100 px-4 py-1"
              :class="indexCurrentTimeEnd == index ? 'bg-gray-100' : ''"
              @click="onChooseTime(hour, 'end', index)"
            >
              {{ hour.text }}
              <span class="text-sm italic">{{
                handleCalculateDurationTime(hour)
              }}</span>
            </div>
          </div>
        </template>
      </VigDropdown>
    </template>
  </div>
</template>
