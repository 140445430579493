<script setup lang="ts">
import { computed, ref, watch, toRaw, reactive } from 'vue';
import useSynTableCustom from '@/ui/common/molecules/SynTableCustom/use-syn-table-custom';
import StepTemplateDetailModal from '@/ui/modules/workflow/step-template/StepTemplateDetailModal.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import UserById from '@/ui/components/user/UserById.vue';
import StepTemplateEntity, {
  IStepTemplateEntity,
} from '@/domain/entities/workflow/StepTemplateEntity';
import workflowStepStore from '@/store/workflow/workflow-step.store';

const props = withDefaults(
  defineProps<{
    searchText: string;
    filterSteps?: IStepTemplateEntity[];
    departmentId?: number;
    domainId?: number;
    groupId?: number;
    actionList?: string[];
  }>(),
  {
    filterSteps: () => [],
    departmentId: undefined,
    domainId: undefined,
    groupId: undefined,
    actionList: () => ['DUPLICATE', 'EDIT', 'CHOOSE'],
  }
);
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'choose', step: any): void;
  (e: 'duplicateStep', step: any): void;
  (e: 'updateStep', step: any): void;
  (e: 'reload'): void;
}>();

const _workflowStepStore = workflowStepStore();

const isLoadingList = ref<boolean>(false);

const handleFilterData = (originData, newStep?) => {
  let filteredData = [...toRaw(originData)];

  if (newStep?.id) {
    const index = filteredData?.findIndex((o) => o?.id == newStep?.id);
    if (index > -1) filteredData[0].hasBeenJustCreated = true;
  }
  if (props.departmentId)
    filteredData = filteredData?.filter((step) => {
      return (
        !step?.departmentInfo?.id ||
        step?.departmentInfo?.id == props.departmentId
      );
    });
  if (props.domainId)
    filteredData = filteredData?.filter((step) => {
      return !step?.domainInfo?.id || step?.domainInfo?.id == props.domainId;
    });
  if (props.groupId)
    filteredData = filteredData?.filter((step) => {
      return !step?.groupInfo?.id || step?.groupInfo?.id == props.groupId;
    });

  if (props.filterSteps?.length > 0)
    filteredData = filteredData?.filter((step) => {
      return !props.filterSteps?.some((s) => s?.id == step?.id);
    });

  return filteredData;
};

const allWorkflowSteps = computed<IStepTemplateEntity[]>(() => {
  return _workflowStepStore.allWorkflowSteps;
});
const initComponent = async () => {
  isLoadingList.value = true;

  await _workflowStepStore.getAllStepTemplates();

  initComposable(handleFilterData(allWorkflowSteps.value));
  isLoadingList.value = false;
};

initComponent();

const onLoadMore = () => {};
const {
  initComposable,
  showingDataList,
  sortDataOrderByKey,
  genSortDataList,
  filterDataByKey,
  onFilterByKeyOnColumn,
  onResetFilterOnColumn,
  setFilterDataByKey,
  onFilterByKey,
  refreshComposable,
} = useSynTableCustom();

watch(
  () => props.searchText,
  () => {
    setFilterDataByKey('keySearch', props.searchText);
    onFilterByKey();
  }
);
const onClickSort = (key, name, orderFunction) => {
  genSortDataList({ key, name, orderFunction });
};

const reload = () => {
  initComponent();
  emit('reload');
};

const canLoadMore = computed<boolean>(() => {
  return false;
});

const hasJustCreate = (data) => {
  const createdTime = new Date(data?.createdTime).getTime();

  return Date.now() - createdTime < 3 * 60 * 60 * 1000;
};

const isOpenStepTemplateModal = ref<boolean>(false);
const currentStepDetail = ref<any>({});
const onClickDetail = (step, viewMode) => {
  viewStepMode.value = viewMode || 'VIEW';
  currentStepDetail.value = step;

  isOpenStepTemplateModal.value = true;
};

const refresh = (newStep?) => {
  refreshComposable(handleFilterData(allWorkflowSteps.value, newStep));
};

const viewStepMode = ref<'VIEW' | 'EDIT' | 'ADD'>('VIEW');

const highLightRow = reactive<{ rowId: string; highLightTime: number }>({
  rowId: '',
  highLightTime: 0,
});

const handleUpdateStepSuccess = (updatedStep, isNew?) => {
  highLightRow.rowId = updatedStep?.id;
  highLightRow.highLightTime = Date.now();

  emit('updateStep', updatedStep);
  isOpenStepTemplateModal.value = false;
  refresh(isNew ? updatedStep : undefined);
};
const handleRemoveStepSuccess = () => {
  currentStepDetail.value = {};

  isOpenStepTemplateModal.value = false;
  reload();
};
const actionDataListByKey = {
  DUPLICATE: {
    key: 'DUPLICATE',
    iconName: 'duplicate',
    color: '',
    handleClick: (step) => {
      onClickDetail(step, 'CLONE');
    },
  },
  EDIT: {
    key: 'EDIT',
    iconName: 'edit',
    color: '',
    handleClick: async (step) => {
      const editable = await new StepTemplateEntity(step).checkEditable();
      if (!editable) return;

      onClickDetail(step, 'EDIT');
    },
  },
  CHOOSE: {
    key: 'CHOOSE',
    iconName: 'ChevronDoubleRight',
    color: '',
    handleClick: (step) => {
      emit('choose', step);
    },
  },
  REMOVE: {
    key: 'REMOVE',
    iconName: 'trash',
    color: 'red',
    handleClick: async (step) => {
      const res = await new StepTemplateEntity(step).remove();

      if (!res) return;

      refresh();
    },
  },
};
const actionDataList = computed<any[]>(() => {
  return props.actionList?.map((key) => actionDataListByKey[key]);
});

defineExpose({
  refresh,
  initComponent,
  handleUpdateStepSuccess,
  onClickDetail,
});
</script>
<template>
  <div
    v-scroll-infinite="onLoadMore"
    class="h-full overflow-auto relative small-scrollbar"
  >
    <SynTableCustom class="rounded-none">
      <template #header>
        <SynTr class="rounded-none">
          <!--   NAME       -->
          <SynTh
            :label="$t('COMMON_LABEL_NAME')"
            is-sort
            :order-data="sortDataOrderByKey['name']"
            @on-sort="onClickSort('name', $t('TASK_TABLE_LABEL_TASKS'), 'name')"
          >
          </SynTh>
          <!--   Department       -->
          <!-- <SynTh
            :label="$t('COMMON_LABEL_DEPARTMENT')"
            is-sort
            :order-data="sortDataOrderByKey['DEPARTMENT']"
            @on-sort="
              onClickSort(
                'DEPARTMENT',
                $t('COMMON_MODULE_MANAGEMENT_DEPARTMENT'),
                (workflow) => {
                  return workflow?.departmentInfo?.name;
                }
              )
            "
          /> -->
          <!--   DOMAIN       -->
          <!-- <SynTh
            :label="$t('TASK_CREATE_FORM_DOMAIN')"
            position-text-header="justify-center"
            is-sort
            :order-data="sortDataOrderByKey['domainId']"
            @on-sort="
              onClickSort(
                'domainId',
                $t('TASK_TABLE_LABEL_PROJECT'),
                'domainId'
              )
            "
          >
            <template #custom>
              <ColumnFilter
                type="domain"
                :data-filters="filterDataByKey['domainId']"
                @on-ok="(data) => onFilterByKeyOnColumn('domainId', data)"
                @on-reset-filter="onResetFilterOnColumn('domainId')"
              />
            </template>
          </SynTh> -->
          <SynTh
            :label="$t('TASK_LABEL_ASSIGNEE')"
            is-sort
            is-center
            :order-data="sortDataOrderByKey['assigneeId']"
            style="max-width: 10rem"
            @on-sort="
              onClickSort('assigneeId', $t('TASK_LABEL_ASSIGNEE'), 'assigneeId')
            "
          >
            <template #custom>
              <ColumnFilter
                type="user"
                :data-filters="filterDataByKey['assigneeId']"
                @on-ok="(data) => onFilterByKeyOnColumn('assigneeId', data)"
                @on-reset-filter="onResetFilterOnColumn('assigneeId')"
              />
            </template>
          </SynTh>
          <SynTh
            :label="$t('COMMON_LABEL_TOTAL_USED_TIME') || 'Total used'"
            position-text-header="justify-center"
          />

          <!--   RELOAD       -->
          <SynTh is-center class="w-40">
            <div class="flex-1 flex items-center justify-end">
              <SynIcon
                class="
                  text-gray-600
                  fill-gray-500
                  cursor-pointer
                  hover:fill-current
                "
                name="reload"
                :title="$t('TASK_LIST_LABEL_RELOAD') || 'Reload'"
                @click="reload"
              />
            </div>
          </SynTh>
        </SynTr>
      </template>
      <template #body>
        <syn-table-tr-loading v-if="isLoadingList" class="h-full">
          <div v-for="item in 4" :key="item" class="table-cell p-2">
            <div class="p-2 w-full bg-gray-200 rounded-md"></div>
          </div>
        </syn-table-tr-loading>
        <template v-else-if="showingDataList?.length > 0">
          <SynTr
            v-for="step in showingDataList"
            :key="step?.id"
            :row-id="step?.id"
            :high-light-time="
              step?.id == highLightRow?.rowId ? highLightRow?.highLightTime : 0
            "
            class="
              hover:bg-gray-50
              relative
              hover-to-show__parent
              hover-to-hidden__parent
              border-t border-gray-50
            "
          >
            <SynTd class="max-w-md relative" style="padding: 0">
              <div
                class="absolute top-0 right-0 flex-center space-x-1"
                style="font-size: 0.6rem"
              >
                <span
                  v-if="hasJustCreate(step)"
                  class="bg-current text-white px-1.5 rounded-b-md z-10"
                  >New</span
                >
              </div>

              <div class="flex-center flex-col w-full">
                <div
                  class="
                    flex-1
                    px-2
                    py-1
                    overflow-hidden
                    w-full
                    h-full
                    text-overflow-hidden
                  "
                  @click.stop.prevent="onClickDetail(step, 'VIEW')"
                >
                  <div
                    class="flex flex-wrap flex-col space-y-1 break-words w-full"
                  >
                    <div class="w-full flex items-center justify-between">
                      <span
                        :title="step?.name"
                        class="
                          flex-1
                          py-1
                          hover:text-current-500
                          cursor-pointer
                        "
                      >
                        {{ step?.name }}
                      </span>
                      <div v-if="step.groupId" style="max-width: 20rem">
                        <GroupById
                          :group-id="step.groupId"
                          class="px-2 text-xs hover-to-show__parent"
                          avatar-class="w-4 h-4"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="w-full flex justify-end">
                  <div class="flex-center">
                    <template v-if="step?.departmentInfo?.id">
                      <SynAvatar
                        :src="
                          step?.departmentInfo?.avatarUrl?.replace(
                            'original',
                            'mini'
                          )
                        "
                        has-name
                        :name="step?.departmentInfo?.name"
                        custom-class="w-4 h-4 text-xs"
                      >
                        <template #name>
                          <span class="text-xs">
                            {{ step?.departmentInfo?.name }}
                          </span>
                        </template>
                      </SynAvatar>
                      <span>-</span>
                    </template>
                    <SynAvatar
                      v-if="step?.domainInfo?.id"
                      :src="
                        step?.domainInfo?.avatarUrl?.replace('original', 'mini')
                      "
                      has-name
                      :name="step?.domainInfo?.name"
                      :type="'domain'"
                      class="text-xs"
                      custom-class="w-4 h-4 text-xs"
                    >
                      <template #name>
                        <span class="text-xs">
                          {{ step?.domainInfo?.name }}
                        </span>
                      </template>
                    </SynAvatar>
                  </div>
                </div> -->
              </div>
            </SynTd>

            <SynTd>
              <div v-if="step?.assigneeId" class="flex-center">
                <UserById
                  is-hidden-name
                  avatar-class="w-7 h-7"
                  :user-id="step?.assigneeId"
                />
              </div>
            </SynTd>
            <SynTd>
              <div class="flex justify-center">
                <span>{{ step?.totalWorkflows || 0 }}</span>
              </div>
            </SynTd>
            <SynTd>
              <div class="flex-center space-x-1">
                <div
                  v-for="action in actionDataList"
                  :key="action?.key"
                  class="
                    w-8
                    h-8
                    rounded-md
                    cursor-pointer
                    flex-center
                    border border-gray-100
                    hover:shadow
                  "
                  @click="action?.handleClick(step)"
                >
                  <SynIcon
                    :name="action?.iconName"
                    :custom-class="
                      action?.color ? `h-4 w-4 fill-${action?.color}-500` : ''
                    "
                  />
                </div>
              </div>
            </SynTd>
          </SynTr>
          <slot name="footer"></slot>
        </template>
        <template v-else>
          <slot name="no-data"></slot>
        </template>
      </template>
    </SynTableCustom>
    <div v-if="canLoadMore" class="flex-center w-full p-2">
      <button
        class="px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300 text-sm"
        @click="onLoadMore"
      >
        {{ $t('GED_SHOW_MORE') || 'Show more' }}
      </button>
    </div>
  </div>

  <StepTemplateDetailModal
    v-if="isOpenStepTemplateModal"
    :view-mode="viewStepMode"
    :step-detail="currentStepDetail"
    @close="isOpenStepTemplateModal = false"
    @created-success="(data) => handleUpdateStepSuccess(data, true)"
    @update-success="handleUpdateStepSuccess"
    @remove-success="handleRemoveStepSuccess"
  />
</template>
