<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, toRefs, watch } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import chatStore from '@/store/chat';
import sendMessagesComposables from '@/ui/composables/chat/send-messages-composables';
import reactionMessagesComposables from '@/ui/composables/chat/reaction-messages-composables';
import attachmentMessageComposables from '@/ui/composables/chat/attachment-messages-composables';
import commonActionComposables from '@/ui/composables/chat/common-action-composables';
import useChatMessages from '@/ui/composables/chat/chat-messages-composable';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import customizesMessagesComposables from '@/ui/composables/chat/customizes-messages-composables';
import { windowFocusStatus } from '@/ui/app-global-state';
import useSeenBy from '@/ui/composables/chat/seen-by-composables';
import dayjs from 'dayjs';
import ModalFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalFileViewer.vue';
import ModalMessageReactions from '@/ui/modules/messaging/chat-panel/modal-message-reactions/ModalMessageReactions.vue';
import ModalMessageForward from '@/ui/modules/messaging/chat-panel/modal-message-forward/ModalMessageForward.vue';
import UploadDropZone from '@/ui/modules/ged/upload/upload-drop-zone/UploadDropZone.vue';
import ChatMutedNotification from '@/ui/modules/messaging/chat-panel/muted-notification/ChatMutedNotification.vue';
import MessageLoadMore from '@/ui/modules/messaging/chat-panel/message-load-more/MessageLoadMore.vue';
import MessageTyping from '@/ui/modules/messaging/chat-panel/message-typing/MessageTyping.vue';
import MessagesUnread from '@/ui/modules/messaging/chat-panel/messages-unread/MessagesUnread.vue';
import ChatScrollToBottom from '@/ui/modules/messaging/chat-panel/chat-scroll-to-bottom/ChatScrollToBottom.vue';
import ChatForm from '@/ui/modules/messaging/chat-panel/chat-form/ChatForm.vue';
import ModalMultipleFileViewerChat from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewerChat.vue';
import ChatMessage from '@/ui/modules/messaging/chat-panel/chat-message/ChatMessage.vue';
import { find, findLast, sortBy } from 'lodash';
import MessageDateStart from '@/ui/modules/messaging/chat-panel/message-date-start/MessageDateStart.vue';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import useChatMembers from '@/ui/composables/chat/chat-members-composables';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { useResizeObserver } from '@vueuse/core';
import MessagesSearch from '@/ui/modules/messaging/chat-panel/messages-search/MessagesSearch.vue';
import MessagesNoData from '@/ui/modules/messaging/chat-panel/messages-no-data/MessagesNoData.vue';
import MessagesNewLine from '@/ui/modules/messaging/chat-panel/messages-new-line/MessagesNewLine.vue';
import ChatPrevent from '@/ui/modules/messaging/chat-panel/chat-prevent/ChatPrevent.vue';
import TaskCardList from '@/ui/modules/task/board/task-card/TaskCardList.vue';

const props = withDefaults(
  defineProps<{
    conversationId: string;
    organizationId?: number;
    conversation?: ChatConversationModel;
    users?: any[];
    contact?: any;
    size?: 'sm' | 'lg';
    isPreview?: boolean;
    isLoading?: boolean;
    allowedActions?: ChatMessageAction[];
    ask?: any;
    importedTask?: any;
    isEmbeddedApp?: boolean;
    hasRightPanel?: boolean;
  }>(),
  {
    size: 'sm',
  }
);

const emits = defineEmits([
  'onMessageTaskClick',
  'onMessageDayoffClick',
  'onSetUserConversationSeenAll',
  'onMessageEventClick',
  'update:hasRightPanel',
]);

const { organizationId, conversationId, users, isEmbeddedApp } = toRefs(props);

const _useChatMessages = useChatMessages(organizationId, conversationId);
const _useChatMembers = useChatMembers(organizationId, conversationId, users);
const _useSeenBy = useSeenBy(organizationId, conversationId);
const _useMsgCustomize = customizesMessagesComposables(organizationId);
const _useMsgSending = sendMessagesComposables(organizationId);
const { onTyping } = commonActionComposables(organizationId);
const { reactionsViewMsgId, onReactionAdd, onReactionRemove } =
  reactionMessagesComposables(organizationId);

const _chatStore = chatStore();
const _myProfileStore = myProfileStore();

const formChatRef = ref();
const messagesListRef = ref();
const messagesListContentRef = ref();

const isFetchingFromCache = ref<boolean>();
const isLoadingMoreUp = ref<boolean>();
const isLoadingMoreDown = ref<boolean>();
const isFetchedFromServer = ref<boolean>();
const isOnBottomOfChatPage = ref<boolean>(false);
const isCheckingScrollBottom = ref<boolean>(false);
const isShowSearch = ref<boolean>(false);
const messageIdToShowNewLine = ref<string>();
const forwardMsgId = ref<string>();
const floatingDateHtml = ref<string>();
const floatingDate = ref<any>();

const {
  messageIds,
  messagesObj,
  startDateByMsgId,
  positionByMsgId,
  canLoadMoreUp,
  canLoadMoreDown,
  isLoadingMsgFromServerUp,
  isLoadingMsgFromServerDown,
  isGoingToMsg,
} = _useChatMessages;

const { members } = _useChatMembers;
const { seenByMemberId } = _useSeenBy;

const myProfile = computed(() => _myProfileStore.myProfile);
const lisMessageError = computed(() => _chatStore.messagesSendError);
const lisMessagePending = computed(() => _chatStore.messagesSendPending);
const totalUnSeen = computed(() => props.conversation?.unSeen);
const lastDeletedDate = computed(() => props.conversation?.lastDeletedDate);
const isDisabled = computed(
  () =>
    (props.conversation?.isDeactive ||
      props.conversation?.isDeleted ||
      props.conversation?.isDisallowed) &&
    !props.isLoading
);
const seenMembersByMsgId = computed<{ [msgId: string]: Array<any> }>(() => {
  return Object.entries(seenByMemberId.value || {}).reduce(
    (obj, [memberId, seenBy]) => {
      const seenMsgId = (messageIds.value || []).find(
        (msgId) =>
          msgId === seenBy?.lastSeenMessageId ||
          (messagesObj.value &&
            messagesObj.value[msgId]?.createdBy === memberId)
      );

      obj[seenMsgId] = sortBy(
        (obj[seenMsgId] || []).concat([
          {
            memberId,
            lastSeenMessageTime: seenBy?.lastSeenMessageTime,
          },
        ]),
        'lastSeenMessageTime'
      );

      return obj;
    },
    {}
  );
});
const isShowTaskListInChat = computed<boolean>(
  () =>
    _chatStore.hasVisibleTaskListConversationIds?.includes(
      props.conversationId
    ) && props.conversation?.contactId
);
const isShowRightPanel = computed(
  () => isShowSearch.value || isShowTaskListInChat.value
);

let seenOnScrollTimer;
let scrollToMsgTimerByMsgId = {};
let resizeTimer;

watch(
  () => [props.conversationId, props.organizationId],
  ([conversationId]) => {
    _resetChatData();
    _initChatPanel(conversationId);
  }
);

watch(
  () => activeAndFocusOnConversationId.value,
  () => _checkToSeenLastMessage(props.conversationId)
);

watch(
  () => windowFocusStatus.value,
  () => _checkToSeenLastMessage(props.conversationId)
);

// To fix asynchronous Firebase cloud function
watch(
  () => totalUnSeen.value,
  () => {
    if (isChatFocusing(props.conversationId) && isOnBottomOfChatPage.value) {
      _checkToSeenAllConversation(props.conversationId);
    }
  }
);

watch(
  () => seenByMemberId.value,
  () => _checkToScrollToBottom(props.conversationId, 'smooth')
);

watch(
  () => isShowTaskListInChat.value,
  (value) => {
    if (value) isShowSearch.value = false;
  }
);

watch(
  () => isShowRightPanel.value,
  () => {
    emits('update:hasRightPanel', isShowRightPanel.value);
  }
);

// Keep scroll in bottom when content resized (Eg: image loaded...)
useResizeObserver(messagesListContentRef, () => {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(
    () => _checkToScrollToBottom(props.conversationId),
    200
  );
});

onMounted(() => {
  _initChatPanel(props.conversationId);
});

onUnmounted(() => {
  _resetChatData();
});

const onMessagesListScroll = () => {
  _processIsOnBottom();
  _processFloatingDate();

  activeAndFocusOnConversationId.value = props.conversationId;

  if (isOnBottomOfChatPage.value && !isCheckingScrollBottom.value) {
    clearTimeout(seenOnScrollTimer);

    seenOnScrollTimer = setTimeout(() => {
      _processSeenLastMessage(props.conversationId);

      _checkToSeenAllConversation(props.conversationId);
    }, 300);
  }
};

const onMessagesListLoadMoreUp = () => {
  _loadMoreMessagesUp(props.conversationId);
};

const onMessagesListLoadMoreDown = () => {
  _loadMoreMessagesDown(props.conversationId);
};

const onScrollToBottomClick = () => {
  activeAndFocusOnConversationId.value = props.conversationId;

  _useChatMessages.scrollMessagesToBottom();
  _scrollToBottom();
};

const onUnReadMessageClick = () => {
  activeAndFocusOnConversationId.value = props.conversationId;

  _useChatMessages.scrollMessagesToBottom();
  _scrollToBottom();
};

const onChangeBackgroundMsg = async (messageId, color) => {
  const conversationId = props.conversationId;

  activeAndFocusOnConversationId.value = `${conversationId}`;

  await _useMsgCustomize.changeMessageBackgroundColor(
    conversationId,
    messagesObj.value[messageId],
    color
  );
};

const onSendMessage = async (message) => {
  const conversationId = props.conversationId;

  const newMsg = await _useMsgSending.onSendMessage(conversationId, message);

  if (conversationId !== props.conversationId) return;

  _useChatMessages.addNewSentMessageToList(conversationId, newMsg);

  messageIdToShowNewLine.value = '';

  _scrollToBottom();
};

const onSendingMessageChange = (message) => {
  activeAndFocusOnConversationId.value = props.conversationId;

  _chatStore.updateDraftMessage(props.conversationId, message);
};

const onHaveActionChangeForm = (isHaveAction) => {
  _chatStore.setIsFormChanged(isHaveAction);
};

const onMessageReplyClick = (replyMsgId) => {
  onGoToMessage(replyMsgId);
};

const onMessageDelete = (msgId) => {
  _useChatMessages.removeMessage(props.conversationId, msgId);
};

const onMessageReply = (msg) => {
  isShowSearch.value = false;

  _replyMessage(msg?.id, msg);
};

const onGoToMessage = async (msgId) => {
  if (_checkToScrollToMessage(msgId)) return;

  await _useChatMessages.goToMessage(
    props.conversationId,
    msgId,
    lastDeletedDate.value
  );

  setTimeout(() => _checkToScrollToMessage(msgId));
};

const onCloseTaskListInChat = (conversationId) => {
  _chatStore.toggleShowTaskListInChat(conversationId);
};

const isMyMessage = (messageId) => {
  return (
    messagesObj.value &&
    messagesObj.value[messageId]?.createdBy === myProfile.value?.id?.toString()
  );
};

const isChatFocusing = (conversationId) => {
  return (
    windowFocusStatus.value &&
    activeAndFocusOnConversationId.value === conversationId
  );
};

const _initChatPanel = (conversationId) => {
  _bindDraftMessage(conversationId);
  _initDataFromCache(conversationId);
  _initDataFromServer(conversationId);
};

const _initDataFromCache = async (conversationId) => {
  isFetchingFromCache.value = true;

  try {
    await Promise.all([
      _useChatMessages.initMessagesFromCache(
        conversationId,
        lastDeletedDate.value
      ),
      _useChatMembers.initMembersFromCache(conversationId),
      _useSeenBy.initSeenByFromCache(conversationId),
    ]);

    _scrollToBottom();
    _processNewMessageLine();
    _processFloatingDate();
  } catch (e) {
    console.log(e);
  }

  isFetchingFromCache.value = false;
};

const _initDataFromServer = async (conversationId) => {
  await Promise.all([
    _useChatMessages.initMessagesFromServer(
      conversationId,
      lastDeletedDate.value
    ),
    _useChatMembers.initMembersFromServer(conversationId),
    _useSeenBy.initSeenByFromServer(conversationId),
  ]);

  if (conversationId !== props.conversationId) return;

  _scrollToBottom();
  _processNewMessageLine();
  _processSeenLastMessage(conversationId);
  _checkToSeenAllConversation(conversationId);
  _processNotificationNegative(conversationId);
  _processIsOnBottom();
  _processFloatingDate();

  _useChatMessages.subscribeMessages(
    conversationId,
    lastDeletedDate.value,
    _processOnNewMessageAdded(conversationId)
  );
  _useChatMembers.subscribeMembers(conversationId);
  _useSeenBy.subscribeSeenBy(conversationId);

  isFetchedFromServer.value = true;
};

const _bindDraftMessage = (conversationId) => {
  if (conversationId === props.conversationId) {
    setMessage(_chatStore.draftMessages[conversationId]);
  }
};

const _checkToScrollToBottom = async (
  conversationId,
  behavior = 'auto',
  timeout = 0
) => {
  if (!isOnBottomOfChatPage.value) return;

  isCheckingScrollBottom.value = true;

  await _scrollToBottom(behavior, timeout);

  // Timer to wait scrolling is finished
  setTimeout(() => (isCheckingScrollBottom.value = false), 500);
};

const _scrollToBottom = (behavior = 'auto', timeout = 0) => {
  return new Promise<void>((resolve) => {
    // Smooth scroll
    if (behavior == 'smooth') {
      setTimeout(() => {
        if (messagesListRef.value) {
          messagesListRef.value.scrollTo({
            left: 0,
            top: messagesListRef.value.scrollHeight,
            behavior,
          });
        }
        resolve();
      }, timeout);
    }
    // Auto scroll
    else {
      requestAnimationFrame(() => {
        if (messagesListRef.value) {
          messagesListRef.value.scrollTop = messagesListRef.value.scrollHeight;
        }
        resolve();
      });
    }
  });
};

const _checkToScrollToMessage = (msgId) => {
  const msgElement = messagesListRef.value?.querySelector(
    `#msg-conversation-${props.conversationId}-${msgId}`
  );

  if (!msgElement) return false;

  messagesListRef.value.scrollTo({
    left: 0,
    top: msgElement.offsetTop - 100,
    behavior: 'auto',
  });
  msgElement
    .querySelector('.msg-content')
    ?.firstElementChild?.classList.add('msg-focus');

  clearTimeout(scrollToMsgTimerByMsgId[msgId]);

  scrollToMsgTimerByMsgId[msgId] = setTimeout(() => {
    msgElement
      ?.querySelector('.msg-content')
      ?.firstElementChild?.classList.remove('msg-focus');
  }, 2500);

  return true;
};

const _resetChatData = () => {
  _useChatMessages.resetMessages();
  _useChatMembers.resetMembers();
  _useSeenBy.resetSeenBy();

  messageIdToShowNewLine.value = '';
  isFetchedFromServer.value = false;
  floatingDateHtml.value = '';
  floatingDate.value = null;
  isShowSearch.value = false;
};

const _processNewMessageLine = () => {
  messageIdToShowNewLine.value = '';

  if (!messageIds.value || !messagesObj.value) return;

  const myMemberId = myProfile.value?.id + '';
  const isNotMyMessage = (msgId) =>
    messagesObj.value[msgId]?.createdBy !== myMemberId;

  if (!seenByMemberId.value || !seenByMemberId.value[myMemberId]) {
    return (messageIdToShowNewLine.value = findLast(
      messageIds.value,
      isNotMyMessage
    ));
  }

  const lastSeenMsgId =
    seenByMemberId.value[myMemberId]?.lastSeenMessageId || '';
  const lastSeenMsgIndex = messageIds.value.indexOf(lastSeenMsgId);

  messageIdToShowNewLine.value = findLast(
    messageIds.value,
    (msgId, index) => index < lastSeenMsgIndex && isNotMyMessage(msgId)
  );
};

const _processSeenLastMessage = (conversationId) => {
  if (!messageIds.value || !messagesObj.value) return;

  const myMemberId = myProfile.value?.id + '';
  const isNotMyMessage = (msgId) =>
    messagesObj.value[msgId]?.createdBy !== myMemberId;
  const lastMsgId = find(messageIds.value, isNotMyMessage);

  if (!lastMsgId) return;

  const lastMsg = messagesObj.value[lastMsgId];
  const lastSeenMessageTime = seenByMemberId.value
    ? seenByMemberId.value[myMemberId]?.lastSeenMessageTime
    : null;

  if (
    lastSeenMessageTime &&
    dayjs(lastSeenMessageTime).isAfter(lastMsg?.createdDate)
  ) {
    return;
  }

  _useSeenBy.setSeenMessage(conversationId, lastMsg.id);
};

const _processNotificationNegative = (conversationId) => {
  notificationSettingStore().notificationNegative({
    sourceId: conversationId,
    type: 'chat',
  });
};

const _processOnNewMessageAdded = (conversationId) => () => {
  _checkToSeenLastMessage(conversationId);
  _checkToScrollToBottom(conversationId, 'smooth', 100);

  if (!isChatFocusing(conversationId) || !isOnBottomOfChatPage.value) {
    _processNewMessageLine();
  }
};

const _checkToSeenLastMessage = (conversationId) => {
  if (!isChatFocusing(conversationId) || !isOnBottomOfChatPage.value) {
    return false;
  }

  _processSeenLastMessage(conversationId);
  _checkToSeenAllConversation(conversationId);
};

const _checkToSeenAllConversation = (conversationId) => {
  if (totalUnSeen.value) {
    emits('onSetUserConversationSeenAll', conversationId);
  }
};

const _loadMoreMessagesUp = (conversationId) => {
  if (isFetchingFromCache.value || isLoadingMoreUp.value) return;

  isLoadingMoreUp.value = true;

  _useChatMessages.loadMoreMessages(conversationId, lastDeletedDate.value);

  setTimeout(() => {
    isLoadingMoreUp.value = false;
  });
};

const _loadMoreMessagesDown = (conversationId) => {
  if (isFetchingFromCache.value || isLoadingMoreDown.value) return;

  isLoadingMoreDown.value = true;

  _useChatMessages.loadMoreMessagesDown(conversationId, lastDeletedDate.value);

  setTimeout(() => {
    isLoadingMoreDown.value = false;
  });
};

const _processIsOnBottom = () => {
  const scrollTop = messagesListRef.value?.scrollTop || 0;
  const scrollHeight = messagesListRef.value?.scrollHeight || 0;
  const clientHeight = messagesListRef.value?.clientHeight || 0;
  const extraValue = 30;

  isOnBottomOfChatPage.value =
    scrollHeight - scrollTop - clientHeight <= extraValue;
};

const _processFloatingDate = () => {
  let topMsgId;

  messageIds.value?.every((msgId: string) => {
    const msgEl = messagesListRef.value?.querySelector(
      `#msg-conversation-${props.conversationId}-${msgId}`
    );

    if (
      messagesListRef.value?.scrollTop >
      msgEl?.offsetTop + msgEl?.offsetHeight
    ) {
      return false;
    }

    topMsgId = msgId;
    return true;
  });

  floatingDate.value = messagesObj.value[topMsgId]?.createdDate;
};

const _replyMessage = (messageId, message) => {
  formChatRef.value?.setReplyTo(messageId, message);
};

const {
  previewFile,
  filesList,
  isLoadingFile,
  isLoading,
  indexCurrentFile,
  totalListFile,
  isDraging,

  onPaste,
  setChatAboutTask,
  setChatAboutDayoff,
  setChatAboutEvent,
  setNote,
  openNote,
  setStopRecording,
  onViewFile,
  onCloseViewFile,
  onViewNextFile,
  onMessageDownload,
  setPreviewLinkMessage,
  dragOver,
  onDropImage,
} = attachmentMessageComposables(organizationId, formChatRef, isEmbeddedApp);

const replyMessage = (message) => {
  _replyMessage(message?.id, message);
};

const setMessage = (message) => {
  formChatRef.value?.setMessage(message);
};

const emitClickEvent = (eventId) => {
  emits('onMessageEventClick', eventId);
};

const setSearchOpened = (opened) => {
  isShowSearch.value = opened;
};

defineExpose({
  setChatAboutTask,
  setChatAboutDayoff,
  setChatAboutEvent,
  setNote,
  openNote,
  setStopRecording,
  replyMessage,
  setMessage,
  setSearchOpened,
  onViewFile,
});
</script>

<template>
  <div class="h-full flex">
    <!--MAIN PANEL-->
    <div
      v-if="conversation"
      v-drop-zone="{ isDragging: dragOver, onDrop: onDropImage }"
      v-bind="$attrs"
      class="flex-1 overflow-hidden flex flex-col relative"
      @paste="onPaste"
    >
      <div
        class="flex-1 flex flex-col overflow-hidden relative"
        style="transition: 0.2s ease-out; contain: layout style size; bottom: 0"
      >
        <ChatMutedNotification :conversation-id="conversationId" />

        <!--MESSAGES LIST-->
        <div class="flex-1 overflow-hidden relative">
          <div
            v-if="messageIds?.length && !isFetchingFromCache"
            :id="`message-list-id_${conversationId}`"
            ref="messagesListRef"
            v-scroll-infinite-2="{
              onReachedTop: onMessagesListLoadMoreUp,
              onReachedBottom: onMessagesListLoadMoreDown,
            }"
            class="
              h-full
              relative
              overflow-x-hidden overflow-y-auto
              small-scrollbar
            "
            @scroll="onMessagesListScroll"
          >
            <div
              ref="messagesListContentRef"
              class="min-h-full flex flex-col-reverse"
            >
              <div v-if="isLoadingMsgFromServerDown" class="flex-center pb-5">
                <SynIcon name="Spinner" class="animate-spin fill-current-500" />
              </div>

              <MessageLoadMore
                v-if="canLoadMoreDown"
                is-load-more-down
                @on-load-more-click="onMessagesListLoadMoreDown"
              />

              <!--MESSAGES-->
              <template v-for="msgId in messageIds" :key="msgId">
                <ChatMessage
                  :message="messagesObj[msgId]"
                  :message-id="msgId"
                  :reply-to-msg="messagesObj[messagesObj[msgId]?.replyTo]"
                  :organization-id="organizationId"
                  :conversation-id="conversationId"
                  :conversation="conversation"
                  :members="members"
                  :contact="contact"
                  :is-my-message="isMyMessage(msgId)"
                  :size="size || ''"
                  :is-error-send="lisMessageError?.includes(msgId)"
                  :is-pending-send="lisMessagePending?.includes(msgId)"
                  :is-first-in-group="positionByMsgId[msgId]?.isFirst"
                  :is-last-in-group="positionByMsgId[msgId]?.isLast"
                  :seen-by-members="seenMembersByMsgId[msgId]"
                  :is-disabled="isDisabled"
                  :allowed-actions="allowedActions"
                  @on-message-delete="onMessageDelete(msgId)"
                  @on-message-forward="forwardMsgId = msgId"
                  @on-message-download="onMessageDownload"
                  @on-file-click="onViewFile(conversationId, $event)"
                  @on-reaction-add="
                    onReactionAdd(conversationId, msgId, $event)
                  "
                  @on-reactions-click="reactionsViewMsgId = msgId"
                  @on-reply-click="onMessageReply(messagesObj[msgId])"
                  @on-task-click="$emit('onMessageTaskClick', $event)"
                  @on-dayoff-click="$emit('onMessageDayoffClick', $event)"
                  @on-event-click="emitClickEvent"
                  @on-click-message-reply="onMessageReplyClick"
                  @on-close-preview-link="
                    setPreviewLinkMessage(
                      conversationId,
                      $event,
                      messagesObj[msgId],
                      myProfile?.id
                    )
                  "
                  @on-change-background-msg="
                    onChangeBackgroundMsg(msgId, $event)
                  "
                />

                <!--NEW MESSAGE LINE-->
                <MessagesNewLine v-if="messageIdToShowNewLine === msgId" />

                <!--MESSAGE START DATE-->
                <div
                  v-if="startDateByMsgId[msgId]"
                  class="flex-center p-2 mb-2 msg-start-date"
                >
                  <MessageDateStart :date="startDateByMsgId[msgId]" />
                </div>
              </template>

              <MessageLoadMore
                v-if="canLoadMoreUp"
                @on-load-more-click="onMessagesListLoadMoreUp"
              />

              <div v-if="isLoadingMsgFromServerUp" class="flex-center pt-5">
                <SynIcon name="Spinner" class="animate-spin fill-current-500" />
              </div>
            </div>
          </div>

          <!--NO MESSAGE-->
          <MessagesNoData v-if="!messageIds?.length && isFetchedFromServer" />

          <!--DATE FLOATING-->
          <div
            v-if="floatingDate"
            class="absolute top-1 left-0 right-0 flex-center z-10"
          >
            <div class="shadow rounded-full">
              <MessageDateStart :date="floatingDate" />
            </div>
          </div>
        </div>

        <MessageTyping
          v-if="isFetchedFromServer"
          class="absolute bottom-1 left-3"
          :organization-id="organizationId"
          :conversation-id="conversationId"
          :members="members"
        />

        <MessagesUnread
          v-if="
            totalUnSeen > 0 && !isOnBottomOfChatPage && !isCheckingScrollBottom
          "
          :total-un-seen="totalUnSeen"
          @on-messages-unread-click="onUnReadMessageClick"
        />

        <ChatScrollToBottom
          v-else-if="!isOnBottomOfChatPage && !isCheckingScrollBottom"
          @on-scroll-to-bottom-click="onScrollToBottomClick"
        />

        <UploadDropZone
          v-if="
            isDraging && !isDisabled && !conversation?.isGroupChatDisallowed
          "
          class="absolute w-full top-0 left-0 right-0 bottom-0 z-20"
        />

        <div
          v-if="isGoingToMsg"
          class="
            absolute
            top-0
            left-0
            right-0
            bottom-0
            flex-center
            bg-current-50 bg-opacity-30
          "
        >
          <SynIcon
            name="Spinner"
            custom-class="w-6 h-6"
            class="animate-spin fill-current-500"
          />
        </div>
      </div>

      <!--PREVENT CHAT-->
      <ChatPrevent
        v-if="
          (conversation?.isDeleted ||
            conversation?.isDeactive ||
            conversation?.isDisallowed ||
            conversation?.isGroupChatDisallowed) &&
          !isLoading
        "
        :conversation="conversation"
      />

      <!--CHAT INPUT-->
      <div v-else-if="!isDisabled && !isPreview" class="w-full min-h-[3.5rem]">
        <ChatForm
          ref="formChatRef"
          :size="size"
          :conversation="conversation"
          :members="members"
          :is-not-focus="!isChatFocusing(conversationId)"
          :task="importedTask"
          :is-embedded-app="isEmbeddedApp"
          @on-send-message="onSendMessage"
          @on-typing="onTyping(conversationId, $event)"
          @on-message-change="onSendingMessageChange"
          @on-have-action-change-form="onHaveActionChangeForm"
        />
      </div>
    </div>

    <!--RIGHT PANEL-->
    <div v-if="isShowRightPanel" class="h-full border-l" style="width: 25rem">
      <!--SEARCH MESSAGES-->
      <MessagesSearch
        v-if="isShowSearch"
        :organization-id="organizationId"
        :conversation-id="conversationId"
        :conversation="conversation"
        :members="members"
        @on-close="isShowSearch = false"
        @on-message-reply="onMessageReply"
        @on-message-view-file="onViewFile(conversationId, $event)"
        @on-message-task-click="$emit('onMessageTaskClick', $event)"
        @on-go-to-message="onGoToMessage"
      />

      <!--LIST TASKS-->
      <TaskCardList
        v-else-if="isShowTaskListInChat"
        :type="conversation?.isGroup ? 'GROUP' : 'USER'"
        :owner-id="conversation?.contactId"
        @on-close="onCloseTaskListInChat(conversationId)"
      />
    </div>
  </div>

  <ModalFileViewer
    v-if="
      (previewFile?.url_full && filesList?.length < 1 && !isLoading) ||
      (previewFile?.url_full && indexCurrentFile == null && !isLoading)
    "
    :file="previewFile"
    :path="previewFile?.url_full"
    :content-type="previewFile?.contentType"
    :name="previewFile?.name"
    @on-close="previewFile = null"
  />

  <ModalMultipleFileViewerChat
    v-if="
      previewFile?.url_full &&
      filesList?.length > 0 &&
      !isLoading &&
      indexCurrentFile !== null
    "
    :start-index="indexCurrentFile"
    :files="filesList"
    :is-loading="isLoadingFile"
    :editable="false"
    :is-not-back="true"
    @on-next="onViewNextFile(conversationId)"
    @on-close="onCloseViewFile"
  >
    <template #title>
      <div class="flex items-center">
        <span>Files</span>
        <div v-if="filesList?.length" class="px-2 text-gray-500">
          {{
            $t('GED_FILE_PER_TOTAL', {
              file: filesList?.length,
              total: totalListFile,
            }) || `${filesList?.length}/${totalListFile} file(s)`
          }}
        </div>
      </div>
    </template>
  </ModalMultipleFileViewerChat>

  <ModalMessageReactions
    v-if="
      reactionsViewMsgId &&
      Object.keys(messagesObj[reactionsViewMsgId]?.reactions || {}).length > 0
    "
    :reactions="messagesObj[reactionsViewMsgId]?.reactions"
    :members="members"
    :my-profile="myProfile"
    @on-cancel="reactionsViewMsgId = ''"
    @on-reaction-remove="onReactionRemove(conversationId, $event)"
  />

  <ModalMessageForward
    v-if="forwardMsgId"
    :message="messagesObj[forwardMsgId]"
    @on-close="forwardMsgId = ''"
  />
</template>

<style>
.animation-fade-in {
  animation: fadeIn 0.2s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
