<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 42 58"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#f8e9af"
          d="M33.04 53H40a2.006 2.006 0 0 0 2-2V8l-8-8H2a2.006 2.006 0 0 0-2 2v49a2.006 2.006 0 0 0 2 2z"
          data-original="#f8e9af"
        ></path>
        <g fill="#b08f46">
          <path
            d="M29 8H6a1 1 0 1 1 0-2h23a1 1 0 0 1 0 2zM36 14H6a1 1 0 0 1 0-2h30a1 1 0 0 1 0 2zM36 20H6a1 1 0 0 1 0-2h30a1 1 0 0 1 0 2z"
            fill="#b08f46"
            data-original="#b08f46"
          ></path>
        </g>
        <path
          fill="#f2d45b"
          d="M42 8h-6a2.006 2.006 0 0 1-2-2V0z"
          data-original="#f2d45b"
        ></path>
        <circle
          cx="21"
          cy="41"
          r="17"
          fill="#4fb96f"
          data-original="#4fb96f"
        ></circle>
        <path
          fill="#ffffff"
          d="M28 31H14a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM21.707 35.293a1 1 0 0 0-1.414 0l-3 3a1 1 0 0 0 1.414 1.414L20 38.414V53a1 1 0 0 0 2 0V38.414l1.293 1.293a1 1 0 0 0 1.414-1.414z"
          data-original="#ffffff"
        ></path>
      </g>
    </g>
  </svg>
</template>
