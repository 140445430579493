<script setup lang="ts">
import { computed, ref } from 'vue';
import overviewTeamStore from '@/store/dashboard/overview-team-store';
import { isOpenModalInvite } from '@/ui/app-global-state';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import organizationStore from '@/store/organization';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import router from '@/ui/router';
import SynSearch from '@/ui/common/atoms/SynInput/SynSearch.vue';
// import router from '@/ui/router';

const _overviewTeamStore = overviewTeamStore();
const _organizationStore = organizationStore();

const organizationByIds = computed(() => _organizationStore.organizationByIds);
const currentOrganization = computed(
  () => organizationByIds.value[getLocalStorage(StorageConstant.ACTIVE_ORG)]
);
const isCompanyTeam = computed(
  () =>
    currentOrganization?.value?.model == 'COMPANY' ||
    currentOrganization?.value?.model == 'TEAM'
);
const isHiddenTask = computed(() => _overviewTeamStore.isHiddenTask);
const lengthTeamUser = computed(
  () => _overviewTeamStore.overviewTeamUserIds?.length || 0
);

const searchText = ref(null);
searchText.value = _overviewTeamStore.keySearchTeam;

const searchInputMember = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath === '/dashboard/members'
  ) {
    searchInputMember.value?.showSearch();
    e.preventDefault();
  }
});

const resetKeyword = () => {
  searchText.value = null;
  _overviewTeamStore.setKeySearchTeam(null);
};

const onChangeText = (value) => {
  _overviewTeamStore.setKeySearchTeam(value);
};

const onToggleHiddenTask = () => {
  _overviewTeamStore.onToggleHiddenTask();
};

const onInviteUser = () => {
  isOpenModalInvite.value = true;
};
</script>

<template>
  <div class="flex items-center space-x-2 px-4">
    <!-- SEARCH   -->
    <SynSearch
      ref="searchInputMember"
      v-model="searchText"
      icon-prefix="search"
      is-show-short-cut-key
      :placeholder="$t('MEMBER_SEARCH')"
      :autofocus="true"
      width-max="17rem"
      @update:model-value="onChangeText"
    >
      <template v-if="searchText?.trim()?.length > 0" #suffix>
        <SynIcon
          has-action
          name="close2"
          custom-class="rounded-full w-3 h-3"
          @click="resetKeyword()"
        />
      </template>
    </SynSearch>

    <!-- HIDDEN TOGGLE   -->
    <div
      class="
        bg-white
        px-2
        py-2
        text-2xs
        cursor-pointer
        fill-gray-500
        text-gray-600
        border border-gray-100
        rounded-full
        hover:bg-gray-200 hover:border-gray-400
      "
      @click="onToggleHiddenTask"
    >
      <div class="flex-center space-x-2">
        <span v-if="isHiddenTask">
          {{ $t('OVERVIEW_ACTION_SHOW_TASK') || 'Show task' }}
        </span>
        <span v-else>
          {{ $t('OVERVIEW_ACTION_HIDDEN_TASK') || 'Hidden task' }}
        </span>
        <SynIcon
          name="eye"
          :is-active="!isHiddenTask"
          custom-class="w-3.5 h-3.5"
        />
      </div>
    </div>

    <!-- ADD   -->
    <template v-if="$permission('FunctionOrganizationInvitation')">
      <SynButton
        v-if="lengthTeamUser < 6 || isCompanyTeam"
        v-vig-tooltip="{
          content: `
              <div class='rounded px-2 py-4 w-48 text-center bg-white text-current-500'>
                <span class='text-md'>${$t('SETUP_TEAM_INVITE_DES')}</span>
              </div>`,
          arrow: true,
          placement: 'bottom',
          delayArr: [0, 500],
          theme: 'light',
          animation: 'scale',
          interactive: true,
          showOnCreate: true,
          zIndex: 40,
          hideAfter: true,
        }"
        v-permission="'FunctionOrganizationInvitation'"
        custom-class="rounded text-xs py-0.5 h-10"
        color="current"
        @click="onInviteUser()"
      >
        <div class="flex-center space-x-2 py-0.5">
          <SynIcon name="AddMember" custom-class="fill-white w-6 h-6" />
          <span>{{ $t('USERMANAGEMENT_LABEL_INVITE_USER') }}</span>
        </div>
      </SynButton>
      <div
        v-else
        v-vig-tooltip="$t('COMMON_LABEL_INVITE_YOUR_TEAM')"
        v-permission="'FunctionOrganizationInvitation'"
        class="
          flex-center
          p-2
          bg-current-400
          rounded
          cursor-pointer
          hover:bg-current-500 hover:ring hover:ring-current-100
        "
        @click="onInviteUser()"
      >
        <SynIcon custom-class="fill-white w-3.5 h-3.5" name="addUser" />
      </div>
    </template>

    <template v-if="$permission('FunctionOrganizationInvitation')">
      <router-link
        v-if="$permission('UserManagement')"
        :to="{ name: 'UserManagement' }"
      >
        <div
          class="
            flex-center
            p-2
            bg-white
            border border-gray-100
            rounded
            cursor-pointer
            hover:bg-gray-200
            hover:ring
            hover:ring-gray-100
            hover:border-gray-400
          "
        >
          <SynIcon
            v-vig-tooltip="$t('SIDEBAR_LABEL_MY_TEAM')"
            is-active
            custom-class="w-3.5 h-3.5 fill-gray-500 text-gray-500"
            name="settings"
          />
        </div>
      </router-link>
    </template>
  </div>
</template>
