<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { onChangeI18n } from '@/ui/plugins/i18n/myi18n';
import appStore from '@/store/app';
import {
  StorageConstant,
  setLocalStorage,
  getLocalStorage,
} from '@/ui/hooks/storageHook';
import organizationStore from '@/store/organization';
import { updateDeviceToken } from '@/ui/plugins/firebases/firebaseFcm';

defineProps({
  isHiddenLabel: {
    type: Boolean,
    default: false,
  },
});

const _organizationStore = organizationStore();
const currentLanguage = ref('');
const _appStore = appStore();
onMounted(() => {
  currentLanguage.value = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';
});

watch(
  () => getLocalStorage(StorageConstant.LANGUAGE),
  () => {
    onChangeLanguage(getLocalStorage(StorageConstant.LANGUAGE));
  }
);

const onChangeLanguage = (key) => {
  currentLanguage.value = key;
  setLocalStorage(StorageConstant.LANGUAGE, key);
  _appStore.setLanguage(key);
  onChangeI18n(key);

  updateDeviceToken(key);

  _organizationStore.getOrganizationConfigDefault();
};

const currentLanguageFlag = computed(() => {
  if (!currentLanguage.value) return '';
  switch (currentLanguage.value) {
    case 'fr':
      return 'flag-france';
    case 'en':
      return 'flag-united-state';
    default:
      return 'flag-vietnam';
  }
});
const locationByDomain = computed(() =>
  getLocalStorage(StorageConstant.LOCATION)
);
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <slot name="current" :icon="currentLanguageFlag">
        <SynIcon
          :name="currentLanguageFlag"
          has-action
          custom-class="w-6 h-6"
        />
      </slot>
    </template>
    <template #dropdown-menu>
      <div class="p-2 flex flex-col">
        <SynIcon
          name="flag-france"
          has-action
          custom-class="h-6 w-6"
          @click="onChangeLanguage('fr')"
        />
        <SynIcon
          v-if="locationByDomain !== 'EU'"
          name="flag-vietnam"
          has-action
          custom-class="h-6 w-6"
          @click="onChangeLanguage('vi')"
        />
        <SynIcon
          name="flag-united-state"
          has-action
          custom-class="h-6 w-6"
          @click="onChangeLanguage('en')"
        />
      </div>
    </template>
  </VigDropdown>
</template>
