<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import exportStore from '@/store/export';
// import FilterTaskButton from '@/ui/modules/task/filter/FilterTaskButton.vue';
import { TaskListTab } from '@/domain/enums/taskEnum';
import taskListStore from '@/store/task/task-list-store';

const props = defineProps<{
  currentTab: TaskListTab;
  moduleName: string;
  ownerName: 'Group' | 'Domain' | 'User';
  userId?: number;
  groupId?: number;
  domainId?: number;
}>();

const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onResetFilter'): void;
}>();

const _exportStore = exportStore();
const _taskListStore = taskListStore();

const customColumns = ref<any>([]);
const totalTasks = computed(() => _taskListStore.filteredTasks.length || 0);

const initData = () => {
  customColumns.value = _exportStore.customColumns;
};

const isExporting = ref(false);

const onDownloadTaskData = async () => {
  const csvFileName = `${props.moduleName}_${
    props?.ownerName?.split(' ').join('_') || ''
  }_Task_${dayjs(Date.now()).format('DDMMYYYY')}.csv`;
  await _exportStore.exportTaskData(csvFileName);
  emit('onClose');
};

const onClose = () => {
  emit('onClose');
};

const onUpdateCustomColumns = () => {
  _exportStore.customColumns = customColumns.value;
};

const onSelectAll = (value) => {
  Object.keys(customColumns.value)?.forEach((column) => {
    customColumns.value[column].export = value;
  });

  onUpdateCustomColumns();
};

const isAabledExport = computed(() => {
  return (
    Object.values(customColumns.value)?.filter((column: any) => column.export)
      ?.length > 0 && totalTasks.value > 0
  );
});
initData();
</script>
<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-class="md:w-2/5"
    disable-click-outside
    :container-style="'min-height: 600px; min-width: 800px'"
    @cancel="onClose"
  >
    <template #header>
      <div class="w-full flex items-center space-x-2">
        <span>
          {{
            $t('TASK_LABEL_EXPORTING_CSV_FILE') || 'Export tasks to CSV file'
          }}
        </span>

        <!-- <FilterTaskButton
            ref="filterTaskButtonRef"
            :user-id="userId"
            :group-id="groupId"
            :domain-id="domainId"
            :current-tab="currentTab"
            @on-reset-filter="$emit('onResetFilter')"
          /> -->
      </div>
    </template>
    <template #body>
      <div class="w-full px-4 flex flex-col space-y-2">
        <div class="flex items-center justify-between">
          <div class="text-gray-800">
            {{ $t('TASK_EXPORT_SELECT_COLUMNS') || 'Select columns' }}
          </div>
          <div class="flex space-x-2">
            <span
              class="text-sm cursor-pointer hover:text-current underline"
              @click="onSelectAll(true)"
            >
              {{ $t('COMMON_LABEL_SELECT_ALL') || 'Select all' }}
            </span>
            <span
              class="text-sm cursor-pointer hover:text-current underline"
              @click="onSelectAll(false)"
            >
              {{ $t('COMMON_LABEL_UN_SELECT_ALL') || 'Unselect all' }}
            </span>
          </div>
        </div>
        <div class="grid grid-rows-4 grid-flow-col bg-gray-100 p-4">
          <template v-for="(column, index) in customColumns" :key="index">
            <AtomCheckbox
              v-model="column.export"
              class="m-2"
              :label="column.displayName"
              @update:model-value="onUpdateCustomColumns"
            />
          </template>
        </div>
        <div class="flex-center pt-8">
          <span class="px-3 py-1 rounded text-lg text-white bg-current">
            {{ $t('COMMON_LABEL_TOTAL') || 'Total' }}: {{ totalTasks || 0 }}
          </span>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          type-outline
          :label="$t('COMMON_LABEL_CANCEL')"
          @click="onClose"
        />
        <SynButton
          :is-loading="isExporting"
          :disabled="!isAabledExport"
          :label="$t('TASK_LABEL_EXPORTING_CSV_FILE')"
          @click="onDownloadTaskData"
        />
      </div>
    </template>
  </SynModal>
</template>
