import BaseModel from './BaseModel';
import {
    collection,
    CollectionReference,
    doc,
    DocumentData,
    onSnapshot,
    getDoc,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { genGroupConversationId } from '@/ui/plugins/firebases/firestore-database/constants';

export default class ConversationCollectionModel extends BaseModel {
    collectionRef: CollectionReference<DocumentData>;

    constructor() {
        super();

        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        this.collectionRef = collection(
            doc(
                collection(firestoreDb, 'organizationConversations'),
                `${organizationId}`
            ),
            'conversations'
        );
    }

    add(conversationId, info, members, userId) {
        const batch = this.writeBatch(firestoreDb);

        // Set conversation-item info
        const setData: any = {
            name: info?.name || null,
            avatar: info?.avatar || null,
            isGroup: info?.isGroup || false,
            isSelf: info?.isSelf || null,
            createdBy: `${userId}`,
            createdDate: this.serverTimestamp(),
        };

        if (info?.chatAboutTaskId) {
            setData.chatAboutTaskId = info.chatAboutTaskId;
            setData.chatAboutTaskCode = info.chatAboutTaskCode;
        }

        batch.set(doc(this.collectionRef, `${conversationId}`), setData, {
            merge: true,
        });

        // Set conversation-item members
        (members || []).map((member) =>
            batch.set(
                doc(
                    collection(
                        doc(this.collectionRef, `${conversationId}`),
                        'members'
                    ),
                    `${member.id}`
                ),
                {
                    name: member.name || null,
                    avatar: member.avatar || null,
                    createdBy: `${userId}`,
                    createdDate: this.serverTimestamp(),
                }
            )
        );

        // Commit the batch
        return batch.commit();
    }

    async updateGroupChatInfo(groupId, info) {
        const conversationId = genGroupConversationId(groupId);

        const docs = await getDoc(doc(this.collectionRef, `${conversationId}`));

        if (docs.exists()) {
            return await this.setDoc(
                doc(this.collectionRef, `${conversationId}`),
                info,
                { merge: true }
            );
        }
    }

    subscribleDataChange(callback) {
        return onSnapshot(this.collectionRef, (snapshot) => {
            snapshot
                .docChanges()
                .reverse()
                .forEach((change) => {
                    callback && callback(change);
                });
        });
    }

    addMultipleMessage(contents, userId) {
        // Get a new write batch
        const batch = this.writeBatch(firestoreDb);

        contents.forEach((content) => {
            const newMsgId = content?.messageId;
            const conversationId = content?.conversationId;

            if (newMsgId && conversationId) {
                const newMsg = {
                    call: content?.call,
                    createdBy: `${userId}`,
                    createdDate: this.serverTimestamp(),
                };

                batch.set(
                    doc(
                        collection(
                            doc(this.collectionRef, conversationId),
                            'messages'
                        ),
                        `${newMsgId}`
                    ),
                    newMsg,
                    {
                        merge: true,
                    }
                );
            }
        });

        // Commit the batch
        return batch.commit();
    }
}
