<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { ResourceTab } from '@/domain/enums/resource-enums';
import driveDocumentPageStore from '@/store/driveDocument/drive-document-page';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';

defineProps<{
  activeTab: any;
}>();

const emits = defineEmits(['update:activeTab']);

const route = useRoute();
const _drivePageStore = driveDocumentPageStore();

const tabs = ref([
  { id: ResourceTab.Files, name: 'GED_TAB_FILES', icon: 'Files' },
  {
    id: ResourceTab.GoogleDocuments,
    name: 'GED_TAB_GOOGLE_DOCS',
    icon: 'GoogleDrive',
  },
]);

const activeTabId = ref();

const isShowSidebar = computed(() => {
  const systemConfigs = permissionStore().allSystemConfigs || {};
  return systemConfigs[systemConfig.RESOURCE_ALLOW_ADD_GOOGLE_FILES] === '1';
});

watch(
  () => route.query,
  (params) => _processActiveTab(params)
);

watch(
  () => route.fullPath,
  (path) => {
    _processDriveBrowserVisible(path);
  }
);

onBeforeMount(() => {
  _processDriveBrowserVisible(route.fullPath);
  _processActiveTab(route.query);
});

const _processActiveTab = (params) => {
  let actTab = tabs.value?.find((tab) => tab?.id === params?.resourceTab);

  // No active tab, set first tab active
  if (!actTab && tabs.value?.length) actTab = tabs.value[0];

  activeTabId.value = actTab?.id;

  emits('update:activeTab', actTab);
};

const _processDriveBrowserVisible = (routePath) => {
  const whitelists = ['/tasks/my-tasks', '/resources'];

  _drivePageStore.setPageIsVisible(
    routePath?.includes(`resourceTab=${ResourceTab.GoogleDocuments}`) &&
      whitelists.some((path) => routePath?.startsWith(path))
  );
};
</script>

<template>
  <!--    <div v-perfect-scrollbar class="h-full bg-white border-r">-->
  <!--      <router-link-->
  <!--        v-for="tab in tabs"-->
  <!--        :key="tab?.id"-->
  <!--        v-vig-tooltip="{ content: tab?.name, placement: 'right' }"-->
  <!--        :to="{path: route.path, query: { resourceTab: tab?.id }}"-->
  <!--        class="w-14 h-14 flex-center flex-col gap-2"-->
  <!--        :class="-->
  <!--          tab?.id === activeTabId-->
  <!--            ? 'bg-current-50 border-r-[3px] border-current fill-current-600 cursor-default'-->
  <!--            : 'hover:bg-gray-50 fill-gray-600'-->
  <!--        "-->
  <!--      >-->
  <!--        <SynIcon :name="tab?.icon" custom-class="w-6 h-6" />-->
  <!--      </router-link>-->
  <!--    </div>-->

  <div
    v-if="isShowSidebar"
    v-perfect-scrollbar
    class="h-full bg-gray-200 px-2 py-3"
  >
    <router-link
      v-for="tab in tabs"
      :key="tab?.id"
      v-vig-tooltip="{ content: $t(tab?.name), placement: 'right' }"
      class="w-12 h-12 flex-center flex-col gap-2 mb-3 rounded-lg"
      :class="
        tab?.id === activeTabId
          ? 'shadow bg-white border-r-[3px] border-current fill-current-600'
          : 'hover:bg-gray-50'
      "
      :to="{
        path: route.path,
        query: { tab: route.query.tab, resourceTab: tab?.id },
      }"
    >
      <SynIcon :name="tab?.icon" custom-class="w-6 h-6" is-active />
      <!--      <span class="text-xs font-semibold text-center">{{ tab?.name }}</span>-->
    </router-link>
  </div>
</template>

<style scoped></style>
