import { defineStore } from 'pinia';
import PaymentService from '@/application/services/payment/PaymentService';
import storeId from '@/base/store/storeId';
import { PaymentTransactionLog } from '@/application/interfaces/payment/TransactionInterfaces';
import { IPaymentConfig } from '@/application/types/payment-configs.types';
import {
    EPaymentPurpose,
    ILicensePackage,
    IPaymentPayload,
    IPaymentVoucherDetail,
    IRegisterLicensePayload,
} from '@/application/types/payment.types';
import organizationStore from '@/store/organization';
import { diff } from '@/ui/hooks/hook-date';
import plansStore from '@/store/plans';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';

type PaymentState = {
    paymentConfigs: IPaymentConfig;
    billingHistory: any;
    isLoadingBillingHistory: boolean;
    exchangeRatesBySymbol: any;
    detailTransaction: PaymentTransactionLog;
    isLoadingDetailTransaction: any;
    isShowCurrentPlanGlobalModal: boolean;
    isCreatingPaymentToken: boolean;
    paymentPayload: IPaymentPayload | null;
    availableVouchers: IPaymentVoucherDetail[] | null;
    allLicensePackages: ILicensePackage[] | null;
};

export default defineStore({
    id: storeId.PAYMENT_SETTING,
    state: () =>
        ({
            paymentConfigs: {},
            billingHistory: [] as any,
            isLoadingBillingHistory: false,
            exchangeRatesBySymbol: {},
            detailTransaction: {},
            isLoadingDetailTransaction: false,
            isShowCurrentPlanGlobalModal: false,
            isCreatingPaymentToken: false,
            paymentPayload: null,
            availableVouchers: null,
            allLicensePackages: null,
        } as PaymentState),
    getters: {},
    actions: {
        async checkAndMakeNewPayment(data: IPaymentPayload) {
            try {
                this.isCreatingPaymentToken = true;
                const currentOrgPlan = plansStore().currentOrgPlan;
                if (
                    data?.purpose &&
                    data?.purpose == EPaymentPurpose.EXTEND &&
                    diff(currentOrgPlan?.endDate, new Date(), 'day') > 0
                ) {
                    this.isShowCurrentPlanGlobalModal = true;
                    this.isCreatingPaymentToken = false;
                    return;
                }

                this.createPaymentToken(data);
            } catch (e) {
                console.log(e);
            } finally {
                this.isCreatingPaymentToken = false;
            }
        },
        async createPaymentToken(payload: IPaymentPayload) {
            try {
                this.isCreatingPaymentToken = true;

                const totalUsers = payload.totalUsers
                    ? payload.totalUsers
                    : getDefaultTotalUserByOrganizationSize();

                const res =
                    await PaymentService.getInstance().createPaymentToken({
                        planId: payload.planId,
                        purpose: payload.purpose || null,
                        period: payload.period || 12,
                        totalUsers,
                    });

                const paymentRequestToken = res.result;
                if (paymentRequestToken)
                    window.open(
                        `${
                            import.meta.env.VITE_BASE_PAYMENT_URL
                        }/payment/cart?token=${paymentRequestToken}&targetAppUrl=${
                            import.meta.env.VITE_DOMAIN_DEFAULT
                        }`,
                        '_blank'
                    );
                return;
            } catch (e) {
                console.log(e);
            } finally {
                this.isCreatingPaymentToken = false;
            }
        },

        async getPaymentConfigs() {
            try {
                const res =
                    await PaymentService.getInstance().getPaymentConfigs();

                this.paymentConfigs = res.result;
                this.exchangeRatesBySymbol =
                    this.paymentConfigs?.exchangeRates?.reduce(
                        (oldValue, currentValue) => {
                            return {
                                ...oldValue,
                                [currentValue.symbol]: currentValue,
                            };
                        },
                        {}
                    );
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },

        async getPaymentHistories() {
            try {
                this.isLoadingBillingHistory = true;
                const res =
                    await PaymentService.getInstance().getPaymentHistories();
                this.billingHistory = res['result'];
                this.isLoadingBillingHistory = false;
            } catch {
                this.billingHistory = [];
                this.isLoadingBillingHistory = false;
            }
        },

        async getTransactionDetail(id) {
            try {
                this.isLoadingDetailTransaction = true;
                const res =
                    await PaymentService.getInstance().getTransactionDetail(id);
                this.detailTransaction = res['result'];
                this.isLoadingDetailTransaction = false;
            } catch {
                this.isLoadingDetailTransaction = false;
            }
        },

        async getAvailableVouchers() {
            const res =
                await PaymentService.getInstance().getAvailableVouchers();
            this.availableVouchers = res?.result;
            return res?.result;
        },
        async checkLicense(licenseKey: string) {
            const res = await PaymentService.getInstance().checkLicense({
                licenseKey,
            });

            return res.result;
        },
        async verifyLicense(licenseKey: string) {
            const res = await PaymentService.getInstance().verifyLicense({
                licenseKey,
            });

            return res.result;
        },
        async applyLicense(data: { licenseKey: string; code: string }) {
            const res = await PaymentService.getInstance().applyLicense(data);

            return res.result;
        },

        async getAllLicensePackage() {
            const res =
                await UnAuthenticateService.getInstance().getAllLicensePackage();
            this.allLicensePackages = res?.result;
        },
        registerLicense(data: IRegisterLicensePayload) {
            return UnAuthenticateService.getInstance().registerLicense(data);
        },
    },
});

const getDefaultTotalUserByOrganizationSize = () => {
    let totalUsers = 10;
    switch (organizationStore().organizationInfo?.size) {
        case '2-5':
            totalUsers = 5;
            break;
        case '6-10':
            totalUsers = 10;
            break;
        case '11-20':
            totalUsers = 20;
            break;
        case '21-50':
            totalUsers = 50;
            break;

        default:
            totalUsers = 10;
            break;
    }

    return totalUsers;
};
