<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g data-name="new window, pop up">
        <path
          d="M29 15a1 1 0 0 0-1 1v9a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h9a1 1 0 0 0 0-2H7a5 5 0 0 0-5 5v18a5 5 0 0 0 5 5h18a5 5 0 0 0 5-5v-9a1 1 0 0 0-1-1z"
          opacity="1"
          class=""
        ></path>
        <path
          d="M22 4h4.59l-11.3 11.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L28 5.42V10a1 1 0 0 0 1 1 1 1 0 0 0 1-1V3a1 1 0 0 0-.29-.71A1 1 0 0 0 29 2h-7a1 1 0 0 0 0 2z"
          opacity="1"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
