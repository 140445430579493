<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
// import SetupTeam from '@/ui/modules/member/setup';
import InviteUserForm from '@/ui/modules/member/invite-user/InviteUserForm.vue';
import {
  StorageConstant,
  getLocalStorage,
  setLocalStorage,
} from '@/ui/hooks/storageHook';
import organizationStore from '@/store/organization';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { gotoDefaultPageByUserRole } from '@/ui/hooks/commonHook';
import { SettingProgressEnum } from '@/ui/common/constants/constant';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

const _organizationStore = organizationStore();
const router = useRouter();
onMounted(() => {
  const settingProgress =
    MyOrganizationSingleton.getInstance().getOrganizationSettingProgress();
  if (settingProgress == SettingProgressEnum.SelectInvitation) {
    router.push({
      name: 'Inviation',
    });
  }
  if (settingProgress == SettingProgressEnum.SettingFinished) {
    gotoDefaultPageByUserRole(router);
  }
});
const isSetupTeam = ref(false);
const createSuccess = ref(false);
const onCreateSuccess = () => {
  createSuccess.value = true;
  isSetupTeam.value = false;
};
const onCloseCongaturation = () => {
  setLocalStorage(StorageConstant.HAS_LOGIN, 'true');

  location.reload();
};
const stepSetup = ref({
  0: {
    key: 'info',
    label: 'COMMON_LABEL_SIGN_UP',
    isActive: true,
    isFinished: true,
  },
  1: {
    key: 'type',
    label: 'COMMON_LABEL_ORGANIZATION',
    isActive: true,
    isFinished: true,
  },
  2: {
    key: 'position',
    label: 'COMMON_LABEL_MEMBERS',
    isActive: true,
    isFinished: false,
  },
});

const onSkipInviteStep = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('SETUP_TEAM_CONFIRM_SKIP_INVITE'),
    confirmable: true,
    closeable: true,
  });
  const confirmed = await ask();
  if (confirmed) {
    await _organizationStore.skipAddMember();
    // Skip success
    // 1. Update setting progesss

    MyOrganizationSingleton.setOrganizationSettingProgress(
      SettingProgressEnum.SettingFinished
    );

    // 2. Open congraturation modal
    onCreateSuccess();
    // 3. Login
  }
};
const onInviteUsers = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('SETUP_TEAM_CONFIRM_INVITE', {
      totalMember: emailPhoneInvites.value?.length || 0,
    }),
    confirmable: true,
    closeable: true,
  });

  const confirmed = await ask();
  if (confirmed) {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);

    const payload = {
      organizationId,
      emailOrPhones: emailPhoneInvites.value,
    };
    await _organizationStore.inviteUser(payload);
    // Invite success
    // 1. Update setting progesss
    MyOrganizationSingleton.setOrganizationSettingProgress(
      SettingProgressEnum.SettingFinished
    );

    // 2. Open congraturation modal
    onCreateSuccess();
    // 3. Login
  }
};
const emailPhoneInvites = ref([] as any[]);
const updateListItem = (data) => {
  emailPhoneInvites.value = [...data];
};
</script>
<template>
  <!-- <setup-team
    v-if="isSetupTeam"
    @close="isSetupTeam = false"
    @onCreateSuccess="onCreateSuccess"
  ></setup-team> -->
  <div
    class="
      h-full
      w-full
      bg-white
      rounded
      pt-10
      mx-auto
      flex
      items-center
      flex-col
    "
  >
    <div class="flex-center flex-col">
      <syn-stepper :steps="Object.values(stepSetup)"></syn-stepper>
    </div>
    <div class="flex flex-1 items-start py-8">
      <div class="flex flex-col justify-center">
        <div class="w-96">
          <invite-user-form @update-list-item="updateListItem" />
        </div>
        <div class="pt-8 flex items-center space-x-2">
          <syn-button v-if="emailPhoneInvites?.length" @click="onInviteUsers">
            <span>{{ $t('COMMON_LABEL_INVITE_YOUR_TEAM') }}</span>
          </syn-button>
          <span
            class="text-sm cursor-pointer hover:text-current"
            @click="onSkipInviteStep"
          >
            {{ $t('COMMON_LABEL_INVITE_SKIP_THIS_STEP') || 'Skip this step' }}
          </span>
        </div>
      </div>

      <div>
        <syn-animation name="businessTeam" stype="width: 350px;" />
      </div>
    </div>
  </div>

  <syn-congraturation
    v-if="createSuccess"
    v-vig-tooltip="$t('COMMON_LABEL_CONGRATURATION')"
    :sub-title="$t('COMMON_LABEL_CREATE_TEAM_SUCCESS')"
    :title="$t('COMMON_LABEL_CONGRATURATION')"
    @on-close="onCloseCongaturation"
  >
  </syn-congraturation>
</template>
