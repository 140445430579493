<script setup lang="ts">
import { computed } from 'vue';
import appStore from '@/store/app';
import AtomSwiperSlide from '@/ui/common/molecules/Slider/AtomSwiperSlide.vue';

const linkDefault = 'https://d1tvqetrcurhzb.cloudfront.net/common/images/';

const _appStore = appStore();

const language = computed(() => _appStore.language);
const dataSlide = computed(() => {
  return [
    {
      title: 'MESSAGE_INTRO_1_TITLE',
      subTitle: 'MESSAGE_INTRO_1_SUB_TITLE',
      urlImage: linkDefault + 'messager-intro-page1.png',
    },
    {
      title: 'MESSAGE_INTRO_2_TITLE',
      subTitle: 'MESSAGE_INTRO_2_SUB_TITLE',
      urlImage:
        linkDefault +
        `messager-intro-page2${
          language?.value != 'en' ? '-' + language?.value : ''
        }.png`,
    },
    // {
    //   title: 'MESSAGE_INTRO_3_TITLE',
    //   subTitle: 'MESSAGE_INTRO_3_SUB_TITLE',
    //   urlImage: linkDefault + 'messager-intro-page3.png',
    // },
    {
      title: 'MESSAGE_INTRO_4_TITLE',
      subTitle: 'MESSAGE_INTRO_4_SUB_TITLE',
      urlImage: linkDefault + 'messager-intro-page3-1.png',
    },
    {
      title: 'MESSAGE_INTRO_5_TITLE',
      subTitle: 'MESSAGE_INTRO_5_SUB_TITLE',
      urlImage: linkDefault + 'messager-intro-page4.png',
    },
    {
      title: 'MESSAGE_INTRO_6_TITLE',
      subTitle: 'MESSAGE_INTRO_6_SUB_TITLE',
      urlImage: linkDefault + 'messager-intro-page5.png',
    },
  ];
});
</script>

<template>
  <AtomSwiperSlide :autoplay="true" :loop="true">
    <template v-for="item in dataSlide" :key="item">
      <div class="flex flex-col flex-center swiper-slide">
        <VigImage
          :path="item?.urlImage"
          custom-class="w-max"
          style="height: 28rem"
        />
        <div class="flex flex-col flex-center">
          <span class="text-current-700" style="font-size: 1.2rem">{{
            $t(item?.title)
          }}</span>
          <span class="text-gray-500 text-sm py-2">{{
            $t(item?.subTitle)
          }}</span>
        </div>
      </div>
    </template>
  </AtomSwiperSlide>
  <div class="flex-center space-x-2 pt-24 pb-12">
    <SynIcon
      name="private"
      custom-class="w-3.5 h-3.5 fill-gray-400 text-gray-400"
    />
    <span class="text-sm text-gray-400">{{
      $t('MESSAGE_END_TO_END_ENCRYPTED')
    }}</span>
  </div>
</template>
