import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import { ITaskStatisticOverview } from '@/application/types/task/task.types';
import dayjs from 'dayjs';

export default defineStore({
    id: storeId.DASHBOARD_PERFORMANCE,
    state: () => ({
        isLoading: true,
        _taskService: TaskService.getInstance(),
        statisticOverview: {} as ITaskStatisticOverview,
        statisticByUserIds: {} as any,
        statisticByDate: {} as any,
    }),
    getters: {},
    actions: {
        async getStatisticOverview() {
            const res: any = await this._taskService.getStatisticOverview();

            if (!res?.result || res.result?.length == 0) return;

            this.statisticByUserIds = res.result?.reduce(
                (currentObj, currentItem) => {
                    return {
                        ...currentObj,
                        [currentItem?.assigneeId]: currentItem,
                    };
                },
                {}
            );
            this.statisticOverview = Object.values(
                this.statisticByUserIds
            )?.reduce(
                (currentObj: any, currentItem: any) => {
                    return {
                        all: currentObj?.all + (currentItem?.all || 0),
                        finishedInTime:
                            currentObj?.finishedInTime +
                            (currentItem?.finishedInTime || 0),
                        finishedPastDue:
                            currentObj?.finishedPastDue +
                            (currentItem?.finishedPastDue || 0),
                        overdueDate:
                            currentObj?.overdueDate + currentItem?.overdueDate,
                        undefinedState:
                            currentObj?.undefinedState +
                            (currentItem?.undefinedState || 0),
                        inProcessState:
                            currentObj?.inProcessState +
                            (currentItem?.inProcessState || 0),
                        pendingState:
                            currentObj?.pendingState +
                            (currentItem?.pendingState || 0),
                        finishedState:
                            currentObj?.finishedState +
                            (currentItem?.finishedState || 0),
                        archivedState:
                            currentObj?.archivedState +
                            (currentItem?.archivedState || 0),
                        cancelledState:
                            currentObj?.cancelledState +
                            (currentItem?.cancelledState || 0),
                        duplicatedState:
                            currentObj?.duplicatedState +
                            (currentItem?.duplicatedState || 0),
                        todoState:
                            currentObj?.todoState +
                            (currentItem?.todoState || 0),
                        draftState:
                            currentObj?.draftState +
                            (currentItem?.draftState || 0),
                        urgentImportant:
                            currentObj?.urgentImportant +
                            (currentItem?.urgentImportant || 0),
                        urgent: currentObj?.urgent + (currentItem?.urgent || 0),
                        important:
                            currentObj?.important +
                            (currentItem?.important || 0),
                        cool: currentObj?.cool + (currentItem?.cool || 0),
                    };
                },
                {
                    all: 0,
                    finishedInTime: 0,
                    finishedPastDue: 0,
                    overdueDate: 0,
                    undefinedState: 0,
                    inProcessState: 0,
                    pendingState: 0,
                    finishedState: 0,
                    archivedState: 0,
                    cancelledState: 0,
                    duplicatedState: 0,
                    todoState: 0,
                    draftState: 0,
                    urgentImportant: 0,
                    urgent: 0,
                    important: 0,
                    cool: 0,
                }
            ) as ITaskStatisticOverview;
        },
        async getStatisticsByPlanToday() {
            const res: any = await this._taskService.getStatisticsByPlanToday();

            if (!res?.result || res.result?.length == 0) return;
            this.statisticByDate = res?.result?.reduce(
                (currentObj, currentItem) => {
                    const dayId = dayjs(
                        `${currentItem?.planningDate}Z`
                    )?.format('YYYYMMDD');
                    return {
                        ...currentObj,
                        [dayId]: currentItem,
                    };
                },
                {}
            );
        },
    },
});
