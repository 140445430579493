<script setup lang="ts">
import { computed } from 'vue';
import { TTaskActivityChart } from '@/store/dashboard/overview-monitor-store';
import LineChart from '@/ui/common/plugins/chart/LineChart.vue';
import dayjs from 'dayjs';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  taskActivityData: TTaskActivityChart[];
}>();

const taskActivityDatasets = computed(() => {
  return [
    {
      borderColor: '#FF8800',
      borderWidth: 1.5,
      data: props.taskActivityData?.map((data) => data.numberOfTaskCreated),
      fill: false,
      label: translate('CHART_LABEL_CREATED_TASK') || 'Created task',
      pointBackgroundColor: '#FF8800',
      pointRadius: 3,
      tension: 0.2,
      title: translate('CHART_LABEL_CREATED_TASK') || 'Created task',
    },
    {
      borderColor: '#4bb0b2',
      borderWidth: 1.5,
      data: props.taskActivityData?.map((data) => data.numberOfTaskFinished),
      fill: false,
      label: translate('CHART_LABEL_FINISHED_TASK') || 'Finished task',
      pointBackgroundColor: '#4bb0b2',
      pointRadius: 3,
      tension: 0.2,
      title: translate('CHART_LABEL_FINISHED_TASK') || 'Finished task',
    },
  ];
});
const taskActivityLabels = computed(() => {
  return props.taskActivityData?.map((data) => {
    if (new Date(data?.date).getDate() % 2 !== 0) return '';
    return dayjs(data?.date).format('DD MMM');
  });
});
</script>
<template>
  <div
    class="w-80 flex flex-col bg-white"
    style="box-shadow: 0px 2px 6px #9ca3af47; border-radius: 5px"
  >
    <div class="flex items-center justify-between pt-4 px-6 relative">
      <span class="text-gray-600 font-semibold text-sm text-center">
        {{ $t('MONITOR_STATISTIC_ACTIVITIES') }}
      </span>

      <div class="flex-center space-x-2 text-xs">
        <div class="flex-center space-x-1">
          <span class="w-4 h-2 bg-orange-500 rounded"></span>
          <span class="text-orange-500">
            {{ $t('CHART_LABEL_CREATED') || 'Created task' }}
          </span>
        </div>
        <div class="flex-center space-x-1">
          <span class="w-4 h-2 bg-current rounded"></span>
          <span class="text-current-500">
            {{ $t('CHART_LABEL_FINISHED') || 'Finished task' }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex-1 p-4 pb-2 relative">
      <LineChart
        :datasets="taskActivityDatasets"
        :labels-data="taskActivityLabels"
      />
    </div>
  </div>
</template>
