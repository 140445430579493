import { computed, ref } from 'vue';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { TaskActionType, TaskLife } from '@/domain/enums/taskEnum';
import {
    EFilterTaskDefault,
    TASK_PIN_BY_CODE,
} from '@/application/constants/task.const';
import taskStore from '@/store/task';
import {
    ETaskListModule,
    TaskApprovalStatus,
} from '@/application/types/task/task.types';
import taskListStore from '@/store/task/task-list-store';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import taskTabViewStore from '@/store/task/task-tab-view';
import { getDetailTaskUrl } from '@/ui/hooks/taskHook';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import taskDrawerStore from '@/store/task/drawer';
import getUserInfo from '@/ui/helpers/user-helper';
import chatStore from '@/store/chat';
import taskDetailWorkflowComposables from '@/ui/modules/task/workflow/task-detail-workflow.composables';

export default function commonActionsTaskComposables({
    currentTask,
    currentTaskId,
}) {
    const _taskStore = taskStore();
    const _taskListStore = taskListStore();

    const taskDetail = ref<TaskDetailClass>(new TaskDetailClass(currentTask));
    const isUpdatingTask = ref(false);
    const isUpdatingPartTask = ref({});
    const changeAssignPayload = ref({} as any);
    const isOpenUpdateDomainModal = ref(false);
    const taskForClone = ref({} as TaskDetailClass);
    const fixConflictDomain = ref({
        domain: false,
        project: false,
        subProject: false,
    } as any);
    const isOpenRequestConfirmModal = ref<boolean>(false);
    const viewModeProvideEvidence = ref<
        | 'FINISH_TASK_WITH_EVIDENCE'
        | 'REQUEST_APPROVAL_WITH_EVIDENCE'
        | 'APPROVE_OR_REJECT'
    >('FINISH_TASK_WITH_EVIDENCE');

    const canChangeDeadline = computed<boolean>(() => {
        return taskDetail.value?.canTaskChangeDeadline(true);
    });

    const onChangedTaskDetail = (changeMethod: Promise<any>, part) => {
        try {
            isUpdatingTask.value = true;
            isUpdatingPartTask.value[part] = true;
            changeMethod;
            _taskStore.updateAllTaskByIds(
                taskDetail.value.id,
                taskDetail.value
            );

            isUpdatingTask.value = false;
            isUpdatingPartTask.value[part] = false;
            return true;
        } catch (error) {
            _taskStore.updateAllTaskByIds(currentTaskId, currentTask);
            isUpdatingTask.value = false;
            isUpdatingPartTask.value[part] = false;
            return false;
        }
    };

    const needToProvideEvidenceWhenFinish = computed(() => {
        return !!taskDetail.value?.finishProofEnabled;
    });

    const workflowData = ref<any>({});
    const selectNextStepDropdownRef = ref<any>(null);

    const onNextStepInWorkflow = (nextStepId: string | null) => {
        selectNextStepDropdownRef.value?.onForceClose();
        onChangedTaskDetail(
            taskDetail.value.finishTaskInWorkflow(nextStepId),
            'state'
        );
    };
    const onChangeTaskState = async (state: TaskLife, comment?: any) => {
        if (
            state == TaskLife.Finished &&
            taskDetail.value?.id &&
            needToProvideEvidenceWhenFinish.value
        ) {
            viewModeProvideEvidence.value = currentTask.value?.approvalEnabled
                ? 'REQUEST_APPROVAL_WITH_EVIDENCE'
                : 'FINISH_TASK_WITH_EVIDENCE';
            isOpenRequestConfirmModal.value = true;
            return;
        }

        if (
            state == TaskLife.Finished &&
            taskDetail.value?.id &&
            taskDetail.value?.workFlowTaskId
        ) {
            // call api get task workflow
            const { currentTaskStep, stepByIds, initComponent } =
                taskDetailWorkflowComposables(taskDetail.value, {
                    doNotNeedInit: true,
                });
            // check next steps count
            // next steps count <= 1 => call api next step
            await initComponent(taskDetail.value);
            console.log(
                '🚀 Tictop ~ currentTaskStep.value:',
                currentTaskStep.value
            );
            if (
                !currentTaskStep.value?.nextSteps ||
                currentTaskStep.value?.nextSteps?.length == 0
            ) {
                onNextStepInWorkflow(null);
                return;
            }
            if (currentTaskStep.value?.nextSteps?.length == 1) {
                onNextStepInWorkflow(currentTaskStep.value?.nextSteps[0]?.id);

                return;
            }
            if (currentTaskStep.value?.nextSteps?.length > 1) {
                workflowData.value = {
                    currentTaskStep: currentTaskStep.value,
                    stepByIds: stepByIds.value,
                };

                selectNextStepDropdownRef.value?.onForceOpen();
                return;
            }
            // next steps count > 1 => open dropdown to choose next step

            return;
        }

        taskDetail.value.taskLife = state;
        onChangedTaskDetail(
            taskDetail.value.changeState(state, comment),
            'state'
        );
    };

    const onChangeUrgency = (urgency) => {
        onChangedTaskDetail(taskDetail.value.changeUrgency(urgency), 'urgency');
    };

    const onDelete = () => {
        onChangedTaskDetail(taskDetail.value.remove(), 'remove');
    };

    const onSaveDomain = (domainData) => {
        if (!domainData?.domainId) return;
        onChangedTaskDetail(
            taskDetail.value.changeDomain(domainData),
            'domain'
        );
    };

    const onSaveDeadline = (deadlineDate) => {
        onChangedTaskDetail(
            taskDetail.value.changeDeadline(deadlineDate),
            'deadline'
        );
        // scheduleRef.value?.onForceClose();
    };

    const onUpdateStartTime = (startTime) => {
        onChangedTaskDetail(
            taskDetail.value.changeStartEndTime({
                startTime,
                changeStartTimeOnly: true,
            }),
            'deadline'
        );
    };
    const changeDeadlineTime = (deadlineDate) => {
        onChangedTaskDetail(
            taskDetail.value.changeDeadlineTime(deadlineDate),
            'deadline'
        );
    };

    const onOpenUpdateDomainModal = () => {
        isOpenUpdateDomainModal.value = true;
    };

    const onCloseUpdateDomainModal = () => {
        isOpenUpdateDomainModal.value = false;
    };

    const onSaveDomainAfterChangeGroup = (domainData) => {
        const promiseAll = [
            taskDetail.value.changeDomain(domainData),
            taskDetail.value.changeAssignee(
                changeAssignPayload.value?.groupId,
                changeAssignPayload.value?.assigneeId
            ),
        ];
        if (
            !changeAssignPayload.value?.assigneeId &&
            taskDetail.value?.taskSchedule.doItToday
        ) {
            promiseAll.push(taskDetail.value.doItToday(false));
        }
        onChangedTaskDetail(Promise.all(promiseAll), 'domain');
        isOpenUpdateDomainModal.value = false;
    };

    const onChangeAssign = (group, user, { domain, project, subProject }) => {
        fixConflictDomain.value = { domain, project, subProject };

        changeAssignPayload.value = {
            groupId: group?.id,
            assigneeId: user?.userId,
        };
        if (domain || project || subProject) {
            onOpenUpdateDomainModal();
            return;
        }
        onChangeAssignee();
    };

    const onChangeAssignee = () => {
        const promiseAll: any[] = [
            taskDetail.value.changeAssignee(
                changeAssignPayload.value?.groupId,
                changeAssignPayload.value?.assigneeId
            ),
        ];
        if (
            !changeAssignPayload.value?.assigneeId &&
            taskDetail.value.taskSchedule.doItToday
        ) {
            promiseAll.push(taskDetail.value.doItToday(false));
        }
        onChangedTaskDetail(Promise.all(promiseAll), 'assignee');
    };

    const onFinish = () => {
        taskDetail.value.taskLife = TaskLife.Finished;
        onChangedTaskDetail(
            taskDetail.value.changeState(TaskLife.Finished),
            'state'
        );
    };

    const onCloneTask = () => {
        taskForClone.value = new TaskDetailClass(currentTask);
    };

    const onSaveCloneTask = async (group, assignee) => {
        taskForClone.value.groupId = group.id;
        taskForClone.value.assigneeId = assignee.userId;
        isUpdatingTask.value = true;
        await taskForClone.value.clone();
        isUpdatingTask.value = false;
        taskForClone.value = new TaskDetailClass({});
    };

    const onDoItToday = async (value) => {
        onChangedTaskDetail(taskDetail.value.doItToday(value), 'DoItToday');
    };

    const onFinishItToday = async (value) => {
        onChangedTaskDetail(
            taskDetail.value.finishItToday(value),
            'FinishItToday'
        );
    };

    const updateTaskListAfterPin = (action: TaskActionType) => {
        _taskListStore.getTaskSummaryStatistics(
            ETaskListModule.USER,
            getCurrentUserId()
        );
        const currentTabInTaskList = taskTabViewStore().currentTabId;

        if (currentTabInTaskList === EFilterTaskDefault.PINNED) {
            _taskListStore.updateListTaskById(taskDetail.value?.id, action);
        }
    };
    const onChangePin = async (pin) => {
        if (pin?.code) await taskDetail.value.changePin(pin);
        else await taskDetail.value.unpin();

        taskStore().updateAllTaskByIds(taskDetail.value.id, {
            pinnedColor: pin?.code || null,
        });

        updateTaskListAfterPin(
            pin?.code ? TaskActionType.MoveIn : TaskActionType.MoveOut
        );
    };
    const onTogglePin = async (value) => {
        if (value) taskDetail.value.changePin(TASK_PIN_BY_CODE.PIN_GRAY);
        else taskDetail.value.unpin();
    };

    const updateTaskDetail = (newTask) => {
        taskDetail.value = new TaskDetailClass(newTask);
    };

    const onToggleLockDeadline = (value?) => {
        taskDetail.value.toggleLockDeadline(value);
    };
    const onChangeApprovalStatus = (
        approvalStatus: TaskApprovalStatus,
        comment: any
    ) => {
        isOpenRequestConfirmModal.value = false;

        if (taskDetail.value?.workFlowTaskId) {
            // Finish with evidence
            taskDetail.value.taskLife = TaskLife.Finished;
            onChangedTaskDetail(
                taskDetail.value.finishTaskInWorkflow(comment?.nextStepId),
                'state'
            );
            return;
        }
        if (!taskDetail.value?.approvalEnabled) {
            // Finish with evidence
            taskDetail.value.taskLife = TaskLife.Finished;
            onChangedTaskDetail(
                taskDetail.value.changeState(TaskLife.Finished, comment),
                'state'
            );
            return;
        }

        taskDetail.value.changeApprovalStatus(
            approvalStatus,
            comment,
            undefined
        );
    };

    const onCopyLink = () => {
        const taskCode = taskDetail.value?.code;
        if (!taskCode) return;
        const url = getDetailTaskUrl(taskCode, true);
        const copied = copyToClipBroard(url);
        if (copied) {
            openNotification({
                body: translate('TASK_DETAIL_ACTION_COPIED_LINK'),
                duration: 2000,
            });
        }
    };

    const goToTaskParent = () => {
        if (!taskDetail.value?.parentId) return;

        taskDrawerStore().pushCurrentIds({
            id: taskDetail.value?.parentId,
            code: taskDetail.value?.parentCode || taskDetail.value?.code,
            name: taskDetail.value?.parentName || taskDetail.value?.name,
            tabType: 'DETAIL',
        });
    };

    const openTaskInDrawer = () => {
        taskDrawerStore().pushCurrentIds({
            id: taskDetail.value?.id?.toString(),
            code: taskDetail.value?.code,
            name: taskDetail.value?.name,
            tabType: 'DETAIL',
        });
    };

    const isNewChat = ref<boolean>(false);
    const onChatAboutTaskByUserId = (userId) => {
        if (!userId) return;

        const _chatStore = chatStore();

        const conversationId = _chatStore.checkExistedConversation(userId);

        isNewChat.value = false;

        const userInfo = getUserInfo(userId);

        const conversation = {
            id: conversationId,
            name: userInfo.name,
            avatar: userInfo.avatar,
        };

        _chatStore.setConversationActive(conversation);

        setTimeout(() => _chatStore.setChatAboutTask(taskDetail.value));
    };

    return {
        taskDetail,
        isUpdatingTask,
        isUpdatingPartTask,
        isOpenUpdateDomainModal,
        taskForClone,
        isOpenRequestConfirmModal,
        canChangeDeadline,
        isNewChat,
        workflowData,
        selectNextStepDropdownRef,
        viewModeProvideEvidence,
        onChangeAssign,
        onChangedTaskDetail,
        onChangeTaskState,
        onOpenUpdateDomainModal,
        onCloseUpdateDomainModal,
        onSaveDomainAfterChangeGroup,
        onChangeAssignee,
        onChangeUrgency,
        onSaveDomain,
        onSaveDeadline,
        onUpdateStartTime,
        onDelete,
        onFinish,
        onCloneTask,
        onSaveCloneTask,
        onDoItToday,
        onFinishItToday,
        onTogglePin,
        changeDeadlineTime,
        updateTaskDetail,
        onToggleLockDeadline,
        onChangeApprovalStatus,
        onChangePin,
        onCopyLink,
        goToTaskParent,
        openTaskInDrawer,
        onChatAboutTaskByUserId,
        onNextStepInWorkflow,
    };
}
