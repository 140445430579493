<template>
  <svg
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="path"
      d="M408.058 70.3487L250.713 69.6135C244.585 69.111 239.255 73.6678 238.81 79.7924C238.365 85.9162 242.972 91.2889 249.101 91.7908C249.672 91.8374 250.246 91.8407 250.817 91.7987L381.334 92.4872L71.3243 402.497C67 406.822 67.0329 413.866 71.3984 418.232C75.7639 422.597 82.8081 422.63 87.1324 418.305L397.142 108.295L397.751 238.733C397.306 244.857 401.913 250.23 408.042 250.732C414.171 251.234 419.5 246.677 419.945 240.553C419.986 239.982 419.984 239.408 419.937 238.837L419.202 81.4918C419.156 75.3744 414.177 70.3956 408.058 70.3487Z"
      fill-rule="nonzero"
    />
  </svg>
</template>
