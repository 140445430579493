import { computed, onMounted, ref } from 'vue';
import paymentStore from '@/store/payment';
import planStore from '@/store/plans';
import { PlanInfo } from '@/application/types/plan.types';
import {
    DiscountByPeriod,
    DiscountByUser,
    IPaymentConfig,
} from '@/application/types/payment-configs.types';
import {
    calculatePaymentPrice,
    getLastTotalAmountAfterApplyVoucher,
} from '@/ui/composables/plans/calculating-price-formula';

export default function paymentCalculatePriceComposables(
    planId,
    hasDefaultPeriod?
) {
    const _paymentStore = paymentStore();
    const _planStore = planStore();

    onMounted(() => {
        if (!hasDefaultPeriod)
            billingPeriodId.value = discountByPeriods.value?.at(-1)?.id || 1;
    });
    const allPlanByIds = computed(() => _planStore.allPlanByIds);

    const paymentConfigs = computed<IPaymentConfig>(
        () => _paymentStore.paymentConfigs
    );
    const discountByPeriods = computed(() =>
        paymentConfigs.value?.discountByPeriods
            ?.filter((period) => period?.planId == planId && period?.isActive)
            .slice(-3)
    );
    const discountByUsers = computed<DiscountByUser[]>(
        () =>
            paymentConfigs.value?.discountByUsers?.filter(
                (period) => period?.planId == planId
            ) || []
    );
    const currentPlan = computed<PlanInfo>(() => {
        if (!planId) return {};
        return allPlanByIds.value[planId];
    });
    const basePrice = computed(() => {
        if (!planId) return 0;
        return currentPlan.value?.basePrice;
    });

    const billingPeriodId = ref<number>(1);
    const billingPeriodInfo = computed<DiscountByPeriod>(() => {
        return (paymentConfigs.value?.discountByPeriods?.find(
            (o) => o.id == billingPeriodId.value
        ) || {}) as DiscountByPeriod;
    });

    // new

    const totalUsers = ref(5);

    const calculatedPricing = computed<{
        lastTotalAmount: number;
        priceActualForOneUserInOneMonth: number;
        lastTotalAmountNotDiscount: number;
        discountPercent: number;
    }>(() => {
        return calculatePaymentPrice(basePrice.value, discountByUsers.value, {
            period: billingPeriodInfo.value?.period,
            discount: billingPeriodInfo.value?.discount,
            totalUsers: totalUsers.value,
        });
    });

    return {
        totalUsers,
        billingPeriodId,
        billingPeriodInfo,
        calculatedPricing,
        discountByPeriods,
        getLastTotalAmountAfterApplyVoucher,
    };
}
