import BaseGoogleApi from '@/ui/plugins/google/base-google-api';

class GoogleCalendarApi extends BaseGoogleApi {
    public initCalendarClient() {
        this.setGapiConfig({
            apiKey: import.meta.env.VITE_GOOGLE_CALENDAR_API_KEY,
            discoveryDocs: [
                'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
                'https://people.googleapis.com/$discovery/rest',
            ],
        });

        this.setAuthConfig({
            client_id: import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID,
            scope: import.meta.env.VITE_GOOGLE_CALENDAR_SCOPES,
            ux_mode: 'popup',
            callback: '',
        });
    }
}

export default new GoogleCalendarApi();
