import myProfileStore from '@/store/auth/my-profile';
import ChatService from '@/application/services/ChatService';

export default function commonActionComposables(organizationId) {
    const onTyping = (converId, isTyping) => {
        return ChatService.setMemberIsTyping(
            organizationId.value,
            converId,
            myProfileStore().myProfile?.id,
            isTyping
        );
    };

    return {
        onTyping,
    };
}
