import { AxiosRequestConfig } from 'axios';
import BaseRequest from '@/base/api/base-request';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

export interface IResponseConfig {
    message: string;
}

export class BaseResponse {
    url?: string;
    success: boolean;
    result: any;
    error: any;
    targetUrl: string;
    unAuthorizedRequest: boolean;

    constructor(data, isBlob?) {
        this.url = data?.url;
        this.success = data?.success || false;
        this.result = isBlob ? data : data?.result;
        this.error = data?.error || false;
        this.targetUrl = data?.targetUrl;
        this.unAuthorizedRequest = data?.unAuthorizedRequest;
    }
}
class BaseRepository {
    constructor() {}

    async requestWithAuthorize(
        requestConfig: AxiosRequestConfig,
        responseConfig?: IResponseConfig
    ): Promise<BaseResponse> {
        try {
            const res = await BaseRequest(requestConfig);

            this.handleOpenNotificationAfterFetchApi(responseConfig);
            return Promise.resolve(
                new BaseResponse(res, requestConfig.responseType === 'blob')
            );
        } catch (error: any) {
            console.log('🚀 Tictop ~ error:', error);
            return Promise.reject(
                new BaseResponse({
                    url: requestConfig?.url,
                    error: error?.error || true,
                })
            );
        }
    }

    private handleOpenNotificationAfterFetchApi(
        responseConfig?: IResponseConfig
    ) {
        if (responseConfig?.message)
            openNotification({
                title: translate('COMMON_LABEL_SUCCESS'),
                body: responseConfig?.message,
            });
    }
}

export default new BaseRepository();
