import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import EmbedGroupServices from '@/ticket/services/group-services';
import { arrayOrderBy, cloneDeep } from '@/ui/hooks/commonFunction';
import { TicketSingleton } from '@/ticket/services/organization-service';

export default defineStore({
    id: storeId.ticketGroup,
    state: () => ({
        _groupService: EmbedGroupServices.getInstance(),
        myGroups: [] as any[],
    }),
    getters: {
        allMyGroups(state): any[] {
            return arrayOrderBy(state.myGroups || [], ['isFavorite'], ['asc']);
        },
    },
    actions: {
        async fetchMyGroups(): Promise<any> {
            try {
                const res = await this._groupService.getAllGroupByUser(
                    TicketSingleton.getInstance().userId
                );

                const groups = res?.result;

                if (!groups || groups.length == 0) return;

                this.myGroups = groups.map((e) => {
                    return {
                        ...e,
                        avatar: e.avatarThumnailUrl,
                        groupUsers: e.groupUsers.map((g) => {
                            return {
                                ...g,
                                name: g.lastName + ' ' + g.firstName,
                                avatar: g.avatarThumbnailUrl,
                            };
                        }),
                    };
                });
            } catch (error) {
                return Promise.reject(error);
            }
        },
        getGroupDomainByGroupId(groupId) {
            const index = this.myGroups?.findIndex(
                (o: any) => o.id === groupId
            );

            return index > -1
                ? cloneDeep(this.myGroups[index].groupDomains)
                : [];
        },
        getDomainDetailById(groupId, domainId) {
            const index = this.myGroups?.findIndex(
                (o: any) => o.id === groupId
            );

            const domains =
                index > -1 ? cloneDeep(this.myGroups[index].groupDomains) : [];

            if (domains.length == 0) return {};

            const indexD = domains?.findIndex(
                (o: any) => o.domainId === domainId
            );
            return {
                id: domains[indexD].domainId,
                name: domains[indexD].domainName,
            };
        },
    },
});
