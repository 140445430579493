<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import alertStore from '@/store/alert';
import { NotificationTypeEnum } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import appStore from '@/store/app';
import {
  setLocalStorage,
  getLocalStorage,
  StorageConstant,
  removeLocalStorage,
} from '@/ui/hooks/storageHook';
import { AuthenticateCaseEnum } from '@/application/entities/authenticate/AuthenticateEnum';
import myProfileStore from '@/store/auth/my-profile';
import settingStore from '@/store/setting';
import RouterInstanceClass from '@/ui/router/RouterInstanceClass';
import { getCompanyOwnName } from '@/ui/hooks/commonFunction';
import { computed } from 'vue';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

const props = withDefaults(
  defineProps<{
    notificationId: number | undefined;
    organizationId: number | string;
    organizationName: string;
    organizationAvatar: string;
    organizationType: string;
    organizationModel: string;
    autoRedirect?: boolean;
    actionType: string;
    duration: number;
    type?: NotificationTypeEnum;
  }>(),
  {
    duration: 5000,
    type: 'group',
  }
);

const _settingStore = settingStore();
const _appStore = appStore();
const _alertStore = alertStore();
const router = useRouter();

const onSwitchToNewOrg = async () => {
  const organizationId = props.organizationId;
  console.log('🚀 Tictop ~ organizationId:', organizationId);
  if (!organizationId) return;

  // switch org
  const authenSelectOrganization = new AuthenticateSelectOrganization(
    parseInt(organizationId.toString())
  );
  _appStore.setIsLoading(true);
  const res = await authenSelectOrganization.login();

  handleLoginSuccess(res);

  removeNotificationBar();
};

onBeforeMount(() => {
  if (props.autoRedirect) onSwitchToNewOrg();
});

const prepareDataBeforeNextRoute = async () => {
  await myProfileStore().fetchCurrentLoginUser();
  await _settingStore.getOrganizationSettings();
  RouterInstanceClass.getInstance().generateRoutes();
  RouterInstanceClass.getInstance().generateDynamicRoutes();
};

const handleLoginSuccess = async (result) => {
  if (!result) return;

  getLocalStorage(StorageConstant.HAVE_AVATAR) &&
    removeLocalStorage(StorageConstant.HAVE_AVATAR);

  switch (result.caseKey) {
    case AuthenticateCaseEnum.WaitingForActivation:
      {
        router.push({
          name: 'WaitingActivation',
        });
      }
      break;

    case AuthenticateCaseEnum.SettingTeam:
      {
        await prepareDataBeforeNextRoute();

        router.push({
          name: 'Inviation',
        });
      }
      break;

    default:
      // router.replace({
      //   name: 'Home',
      // });

      history.replaceState({}, '', '/');
      setLocalStorage(StorageConstant.HAS_LOGIN, 'true');

      window.location.reload();
      break;
  }
};

const removeNotificationBar = () => {
  _alertStore.removeDataAlerts({ id: props.notificationId });
};

const translatedModel = computed(() => {
  let result = '';
  switch (props.organizationModel) {
    case 'COMPANY':
      result = translate('COMMON_LABEL_COMPANY');
      break;
    case 'TEAM':
      result = translate('COMMON_LABEL_TEAM');
      break;
    case 'GROUP':
      result = translate('COMMON_LABEL_GROUP');
      break;
    case 'PERSONAL':
      result = translate('COMMON_LABEL_PERSONAL');
      break;

    default:
      break;
  }
  return result;
});

const isSwitchable = computed(() => {
  return (
    props.organizationId !==
    MyOrganizationSingleton.getInstance().activeOrganization
  );
});
</script>

<template>
  <SynModal
    v-if="!autoRedirect"
    container-class="w-max"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    @cancel="removeNotificationBar"
  >
    <template #body>
      <div class="flex-center flex-col space-y-4">
        <SynIcon name="Congratulation" custom-class="w-20 h-20" />
        <div class="flex flex-col text-center px-8">
          <span>
            {{ $t('SETUP_TEAM_TITLE') }}
          </span>
          <span>
            {{
              $t(
                actionType == 'ATTACH'
                  ? 'SETUP_TEAM_TITLE_SUB2'
                  : 'SETUP_TEAM_TITLE_SUB',
                { type: translatedModel }
              )
            }}
          </span>
          <span class="text-lg text-current-800 font-semibold">
            {{ getCompanyOwnName(organizationName) }}
          </span>
        </div>
        <div class="w-full flex items-center justify-end space-x-2">
          <SynButton
            :title="'Join an existed workspace'"
            type-outline
            class="shadow-md rounded-full border-3"
            @click="removeNotificationBar"
          >
            <span class="text-xs">
              {{
                $t(
                  isSwitchable
                    ? 'CREATE_NEW_ORGANIZATION_NOTIFICATION_STAY_HERE'
                    : 'COMMON_LABEL_CLOSE'
                ) || 'Stay here'
              }}
            </span>
          </SynButton>
          <SynButton
            v-if="isSwitchable"
            :title="'Create a new workspace'"
            class="shadow-md rounded-full bg-current"
            @click="onSwitchToNewOrg"
          >
            <span
              class="text-white text-xs text-overflow-hidden-line"
              style="max-width: 10rem"
            >
              {{ $t('API_ACTION_ORGANIZATION_NEW_SWITCH_TO') }}
              {{ getCompanyOwnName(organizationName) }}
            </span>
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
