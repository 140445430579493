export enum EUserWorkingStatus {
    ONLINE = 1,
    PAUSE = 0,
    OFFLINE = -1,
}

// Generated by https://quicktype.io

export interface IIpInformation {
    requestIp: string;
    timezone: string;
    country: string;
    countryCode: string;
    browser: string;
    region: string;
    latitude: number;
    longitude: number;
    zipcode: string;
}

export enum ECommonInitComponentStatus {
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}
export interface ICommonInitComponent {
    status?: ECommonInitComponentStatus;
    errorCode?: number;
    errorMessage?: string;
}

// Generated by https://quicktype.io

export interface ICurrentUserSetting {
    id: number;
    userId: number;
    organizationId: number;
    key: string;
    value: any;
}

export enum IPersonalSetting {
    SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN = 'SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN',
    SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW = 'SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW',
    SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS = 'SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS',
    SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS = 'SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS',
    SETTING_PERSONAL_SHOW_OVERVIEW_REPORT = 'SETTING_PERSONAL_SHOW_OVERVIEW_REPORT',
}
