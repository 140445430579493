import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';

export enum EFunctionList {
    EDIT_NAME = 'EDIT_NAME',
    EDIT_DESCRIPTION = 'EDIT_DESCRIPTION',
    TOGGLE_ACTIVE = 'TOGGLE_ACTIVE',
    EDIT_TYPE = 'EDIT_TYPE',
    EDIT_MUST_ACTION = 'EDIT_MUST_ACTION',
    EDIT_ASSIGNEE = 'EDIT_ASSIGNEE',
    REMOVE = 'REMOVE',
    ADD_STEP = 'ADD_STEP',
    TOGGLE_FINISH_STEP = 'TOGGLE_FINISH_STEP',
    SHOW_CURRENT_STEP = 'SHOW_CURRENT_STEP',
    EDIT_DOMAIN = 'EDIT_DOMAIN',
    EDIT_URGENCY = 'EDIT_URGENCY',
    EDIT_DEADLINE = 'EDIT_DEADLINE',
    ACTION_ON_ASSIGNEE = 'ACTION_ON_ASSIGNEE',
    EDIT_MULTIPLE = 'EDIT_MULTIPLE',
}

export const WORKFLOW_STEP_CONFIGS_BY_VIEW_MODE = {
    VIEW: {
        enableFunctions: [],
    },
    EDIT: {
        enableFunctions: [
            EFunctionList.EDIT_NAME,
            EFunctionList.EDIT_DESCRIPTION,
            EFunctionList.TOGGLE_ACTIVE,
            EFunctionList.EDIT_TYPE,
            EFunctionList.EDIT_MUST_ACTION,
            EFunctionList.EDIT_ASSIGNEE,
            EFunctionList.EDIT_DOMAIN,
            EFunctionList.EDIT_URGENCY,
            EFunctionList.EDIT_DEADLINE,
            EFunctionList.EDIT_MULTIPLE,
            EFunctionList.REMOVE,
            EFunctionList.ADD_STEP,
        ],
    },
    TASK_VIEW: {
        enableFunctions: [
            EFunctionList.TOGGLE_FINISH_STEP,
            EFunctionList.SHOW_CURRENT_STEP,
            EFunctionList.EDIT_MULTIPLE,
        ],
    },
    ATTACH_TO_TASK: {
        enableFunctions: [
            EFunctionList.EDIT_ASSIGNEE,
            EFunctionList.EDIT_DOMAIN,
            EFunctionList.EDIT_URGENCY,
            EFunctionList.EDIT_DEADLINE,
            EFunctionList.EDIT_MULTIPLE,
        ],
    },
    ATTACH_TO_WORKFLOW: {
        enableFunctions: [
            EFunctionList.EDIT_ASSIGNEE,
            EFunctionList.EDIT_DOMAIN,
            EFunctionList.EDIT_URGENCY,
            EFunctionList.EDIT_DEADLINE,
            EFunctionList.EDIT_MULTIPLE,
        ],
    },
};

export const WORKFLOW_STEP_TYPE = {
    CODING: { key: 'CODING', value: 'Coding' },
    TESTING: { key: 'TESTING', value: 'Testing' },
    REVIEW: { key: 'REVIEW', value: 'Review' },
};

export const genStyleByStepStatus = (status, isCurrentStep, isFocusingStep) => {
    const focusingHeader = isFocusingStep
        ? {
              borderWidth: '2px',
              borderColor: '#60a5fa',
          }
        : {};
    if (isCurrentStep)
        return {
            container: {
                borderColor: '#60a5fa',
            },
            header: {
                backgroundColor: '#bfdbfe',
                ...focusingHeader,
            },
        };
    if (status == EWorkflowTaskStepStatus.COMPLETED)
        return {
            container: {
                borderColor: '#bbf7d0',
            },
            header: {
                backgroundColor: '#bbf7d0',
                ...focusingHeader,
            },
        };
    if (status == EWorkflowTaskStepStatus.TODO)
        return {
            container: {
                borderColor: '#60a5fa',
            },
            header: {
                backgroundColor: '#bfdbfe',
                ...focusingHeader,
            },
        };
    if (status == EWorkflowTaskStepStatus.INPROGRESS)
        return {
            container: {
                borderColor: '#fbcfe8',
            },
            header: {
                backgroundColor: '#fbcfe8',
                ...focusingHeader,
            },
        };
    if (status == EWorkflowTaskStepStatus.WAITING)
        return {
            container: {
                borderColor: '#d1d5db',
            },
            header: {
                backgroundColor: '#f9fafb',
                ...focusingHeader,
            },
        };
    if (status == EWorkflowTaskStepStatus.CANCELED)
        return {
            container: {
                borderColor: '#d1d5db',
            },
            header: {
                backgroundColor: '#d1d5db',
                ...focusingHeader,
            },
        };
    return {
        container: {
            borderColor: '#d1d5db',
        },
        header: {
            backgroundColor: '#f9fafb',
            ...focusingHeader,
        },
    };
};
