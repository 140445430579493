<template>
  <svg
    v-if="isActive"
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M233.582,265.452h-30.2c-4.879,0-8.833,3.955-8.833,8.833c0,4.878,3.954,8.833,8.833,8.833h30.2
			c4.88,0,8.833-3.955,8.833-8.833C242.415,269.407,238.461,265.452,233.582,265.452z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M413.841,25.645H283.539V8.833c0-2.744-1.276-5.333-3.452-7.004c-2.178-1.672-5.007-2.239-7.658-1.53L95.883,47.378
			c-3.867,1.031-6.557,4.533-6.557,8.535v28.531c0,4.878,3.954,8.833,8.833,8.833s8.833-3.955,8.833-8.833V62.7l158.88-42.369
			v472.061l-158.88-31.776v-346.4c0-4.878-3.954-8.833-8.833-8.833c-4.879,0-8.833,3.955-8.833,8.833v353.642
			c0,4.211,2.971,7.836,7.101,8.661l176.546,35.31c0.575,0.115,1.154,0.172,1.732,0.172c2.025,0,4.007-0.696,5.6-2.002
			c2.046-1.678,3.233-4.185,3.233-6.831v-16.81h81.405c4.88,0,8.833-3.955,8.833-8.833c0-4.878-3.954-8.833-8.833-8.833h-81.405
			v-25.203h87.431c4.88,0,8.833-3.955,8.833-8.833V77.348c0-4.878-3.954-8.833-8.833-8.833h-87.431V43.311h121.468v425.377h-12.602
			c-4.88,0-8.833,3.955-8.833,8.833s3.954,8.833,8.833,8.833h21.435c4.88,0,8.833-3.955,8.833-8.833V34.478
			C422.674,29.6,418.719,25.645,413.841,25.645z M362.137,86.181V425.82h-78.597V86.181H362.137z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>

  <svg
    v-else
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M405.333,0H106.667c-17.643,0-32,14.357-32,32v469.333c0,5.888,4.779,10.667,10.667,10.667h341.333
			c5.888,0,10.667-4.779,10.667-10.667V32C437.333,14.357,422.976,0,405.333,0z M416,490.667H96V32
			c0-5.867,4.8-10.667,10.667-10.667h298.667C411.2,21.333,416,26.133,416,32V490.667z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M384,42.667H128c-5.888,0-10.667,4.779-10.667,10.667v448c0,5.888,4.779,10.667,10.667,10.667h256
			c5.888,0,10.667-4.779,10.667-10.667v-448C394.667,47.445,389.888,42.667,384,42.667z M373.333,490.667H138.667V64h234.667
			V490.667z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M341.333,256H320c-17.643,0-32,14.357-32,32c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667
			c0-5.867,4.8-10.667,10.667-10.667h21.333c5.888,0,10.667-4.779,10.667-10.667S347.221,256,341.333,256z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
