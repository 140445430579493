<script setup lang="ts">
import { computed, ref } from 'vue';
import { ATTACHMENT_STATUS_BY_KEY } from '@/application/constants/task.const';

const props = defineProps<{
  currentStatus?: string;
}>();

const emit = defineEmits(['onChangeStatus', 'toggleChangeStatus']);

const currentPin = computed<any>(() => {
  if (!props.currentStatus) return null;
  return ATTACHMENT_STATUS_BY_KEY[props.currentStatus];
});

const onChangeStatus = (pin) => {
  emit('onChangeStatus', pin?.key);
};

const togglePin = () => {
  emit('toggleChangeStatus');
};
</script>
<template>
  <AtomActionTooltip>
    <template #body>
      <button
        class="
          rounded-full
          flex-center
          hover:bg-current-100 hover:bg-opacity-100
          border
          bg-white
          w-6
          h-6
        "
        @click="togglePin"
      >
        <SynIcon
          v-if="currentPin"
          name="tick"
          is-active
          :custom-class="`h-4 w-4 fill-${currentPin?.iconColor}-500`"
        />
        <SynIcon v-else name="tick" custom-class="w-4 h-4 fill-gray-600" />
      </button>
    </template>

    <template #popup>
      <template
        v-for="pin in Object.values(ATTACHMENT_STATUS_BY_KEY)"
        :key="pin?.key"
      >
        <div
          class="
            w-8
            h-8
            flex-center
            cursor-pointer
            hover:bg-gray-100
            group
            rounded-full
          "
          :class="pin.key == currentPin?.key ? 'bg-gray-100' : ''"
          @click="onChangeStatus(pin)"
        >
          <SynIcon
            name="tick"
            is-active
            :custom-class="
              pin.key == currentPin?.key
                ? `w-8 h-8 fill-${pin?.iconColor}-500`
                : `h-5 w-5 group-hover:h-8 group-hover:w-8 fill-${pin?.iconColor}-500`
            "
          />
        </div>
      </template>
    </template>
  </AtomActionTooltip>
</template>
