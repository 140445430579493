export default {
    install: (app) => {
        app.directive('vig-focus', {
            mounted(el, binding) {
                el.clickOutsideEvent = function (event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        binding.value.isBlur(true);
                    } else {
                        binding.value.isFocus(true);
                    }
                };

                document.body.addEventListener('click', el.clickOutsideEvent);

                document.addEventListener('visibilitychange', function () {
                    if (document.visibilityState === 'visible') {
                        binding.value.isFocus(true);
                    } else {
                        binding.value.isBlur(true);
                    }
                });
                // window.addEventListener('blur', () => {
                //   console.log('onBlur binding', binding.value?.isBlur);
                //   binding.value?.isBlur;
                // });
            },

            unmounted(el) {
                document.body.removeEventListener(
                    'click',
                    el.clickOutsideEvent
                );
            },
        });
    },
};
