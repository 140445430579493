<script setup lang="ts">
import { ref } from 'vue';
import SearchTaskOwners from '@/ui/components/search/SearchTaskOwners.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskSearchingList from '@/ui/modules/task/table/TaskSearchingList.vue';
import TaskSimpleTable from '@/ui/modules/task/table/TaskSimpleTable.vue';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import TaskCreateNewModal from '@/ui/modules/task/create/TaskCreateNewModal.vue';

withDefaults(
  defineProps<{
    isSelectMultiple?: boolean;
    viewTaskMode?: 'CARD' | 'LIST';
    filterFunction?: Function;
  }>(),
  {
    viewTaskMode: 'CARD',
    filterFunction: undefined,
  }
);
const emit = defineEmits(['onClose', 'onConfirmSelect', 'onSelectOne']);

const currentTaskOwner = ref<{
  avatar: string;
  id: number;
  name: string;
  type: 'USER' | 'GROUP' | 'DOMAIN';
}>({
  avatar: '',
  id: getCurrentUserId(),
  name: '',
  type: 'USER',
});

const onChooseOwner = (owner) => {
  currentTaskOwner.value = owner;
};
const selectedTaskIds = ref<string[]>([]);
const selectedTasks = ref<any[]>([]);

const taskSearchingListRef = ref<any>(null);
const onClickDetail = (taskDetail) => {
  const isExisted = selectedTaskIds.value?.some((id) => id == taskDetail?.id);

  if (isExisted) {
    selectedTaskIds.value = selectedTaskIds.value?.filter(
      (id) => id !== taskDetail?.id
    );
    selectedTasks.value = selectedTasks.value?.filter(
      (t) => t?.id !== taskDetail?.id
    );
  } else {
    selectedTaskIds.value?.push(taskDetail?.id);
    selectedTasks.value?.push(taskDetail);
  }
};

const onConfirmSelect = (allTaskByIds) => {
  emit('onConfirmSelect', [...selectedTaskIds.value], allTaskByIds);

  selectedTaskIds.value = [];
};

const isOpenCreateTaskModal = ref<boolean>(false);
const onCreateSuccess = (localId, newTask) => {
  onClickDetail(newTask);
  isOpenCreateTaskModal.value = false;
};
</script>
<template>
  <div
    class="
      flex-1
      min-h-0
      flex flex-col
      h-full
      overflow-hidden
      items-stretch
      relative
      bg-white
    "
  >
    <div class="flex-1 min-h-0 flex divide-x gap-4">
      <div class="flex flex-col gap-2 w-1/2">
        <template v-if="currentTaskOwner?.id">
          <div>
            <span
              class="
                hover:text-current-500
                underline
                text-sm text-gray-500
                cursor-pointer
              "
              @click="
                onChooseOwner({ avatar: '', id: null, name: '', type: '' })
              "
            >
              {{ $t('TASK_LABEL_SELECT_ANOTHER_TASK_OWNER') }}
            </span>
          </div>
          <div>
            <!-- <SearchTaskOwners
              :selected-ids="[
                `${currentTaskOwner?.type}_${currentTaskOwner?.id}`,
              ]"
              :owner-type-list="['ITS_ME', 'USER', 'GROUP']"
              is-show-result-only
              @on-remove-owner="
                onChooseOwner({ avatar: '', id: null, name: '', type: '' })
              "
            /> -->
            <div
              class="
                p-2
                flex
                items-center
                space-x-2
                rounded
                text-sm
                bg-current-50
              "
            >
              <div class="flex items-center w-full justify-between space-x-2">
                <UserById
                  v-if="currentTaskOwner?.type == 'USER'"
                  :user-id="currentTaskOwner?.id"
                  is-show-department
                  class="flex-1"
                  avatar-class="w-8 h-8"
                />
                <GroupById
                  v-else
                  :group-id="currentTaskOwner?.id"
                  avatar-class="w-8 h-8"
                />
              </div>
            </div>
          </div>

          <div class="flex-1 min-h-0 h-full overflow-hidden">
            <TaskSearchingList
              ref="taskSearchingListRef"
              :key="currentTaskOwner?.id"
              is-readonly
              is-only-search
              :view-task-mode="viewTaskMode"
              :type="currentTaskOwner?.type || 'USER'"
              :owner-id="currentTaskOwner?.id"
              :selectable="isSelectMultiple"
              is-select-multiple
              :selected-task-ids="selectedTaskIds"
              :filter-function="filterFunction"
              is-need-filter-selected
              is-hidden-footer
              class="rounded-md"
              @on-click-detail="onClickDetail"
              @on-close="$emit('onClose')"
              @on-confirm-select="onConfirmSelect"
            />
          </div>
        </template>
        <template v-else>
          <div class="text-sm">
            {{ $t('TASK_LABEL_PLEASE_SELECT_A_TASK_OWNER') }}:
          </div>
          <div class="flex-1 min-h-0 h-full overflow-hidden">
            <SearchTaskOwners
              :selected-ids="[`USER_${currentTaskOwner?.id}`]"
              :owner-type-list="['ITS_ME', 'USER', 'GROUP']"
              is-hidden-action-item
              is-hidden-quickly-scroll
              is-hidden-selected-owners
              @on-choose-owner="onChooseOwner"
            />
          </div>
        </template>
      </div>
      <!-- SELECTED TASKS -->
      <div class="w-1/2 flex flex-col gap-2">
        <div class="w-full flex justify-end items-center">
          <AtomButton
            :label="$t('COMMON_LABEL_ADD_NEW')"
            @click="isOpenCreateTaskModal = true"
          />
        </div>
        <TaskSimpleTable
          :task-list="selectedTasks"
          is-hidden-header
          :column-list="['NAME', 'ASSIGNEE']"
          size="sm"
          multiple-selectable
          :selected-id-list="selectedTaskIds"
          @open-detail="onClickDetail"
        />
      </div>
    </div>
    <div
      class="
        p-4
        flex
        justify-end
        items-center
        hover:text-current
        cursor-pointer
        space-x-2
      "
    >
      <SynButton type-text color="gray" @click="$emit('onClose')">
        <span class="do-not-close-dropdown">
          {{ $t('COMMON_LABEL_CANCEL') }}</span
        >
      </SynButton>
      <SynButton
        class="do-not-close-dropdown"
        :disabled="!selectedTaskIds || selectedTaskIds?.length == 0"
        @click="onConfirmSelect"
      >
        <span>
          {{
            $t('TASK_LABEL_ADD_TOTAL_TASKS', {
              total: selectedTaskIds?.length || 0,
              suffix: selectedTaskIds?.length > 1 ? 's' : '',
            })
          }}
        </span>
      </SynButton>
    </div>
  </div>

  <TaskCreateNewModal
    v-if="isOpenCreateTaskModal"
    @close="isOpenCreateTaskModal = false"
    @on-create-success="onCreateSuccess"
  />
</template>
