<script setup lang="ts">
import { computed, ref } from 'vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';

const props = defineProps<{
  task: ITaskDetailClass;
}>();
const emit = defineEmits<{
  (e: 'onClose'): void;
  (
    e: 'onSave',
    data: {
      finishProofEnabled: boolean;
      finishProofTypes: string;
      finishProofSkipAllowed: boolean;
    }
  ): void;
}>();

const finishProofTypesDefault = computed<string>(() => {
  if (props.task?.finishProofTypes) return props.task?.finishProofTypes;

  if (
    !Object.prototype.hasOwnProperty.call(
      permissionStore().allSystemConfigs,
      systemConfig.ADVANCED_COMPLETI_REQUIRED_PROOF_TYPE
    )
  )
    return '';
  return permissionStore().allSystemConfigs[
    systemConfig.ADVANCED_COMPLETI_REQUIRED_PROOF_TYPE
  ];
});
const finishProofSkipAllowedDefault = computed<string>(() => {
  console.log(
    '🚀 Tictop ~ props.task?.finishProofSkipAllowed:',
    props.task?.finishProofSkipAllowed
  );
  if (typeof props.task?.finishProofSkipAllowed !== 'undefined')
    return props.task?.finishProofSkipAllowed;

  if (
    !Object.prototype.hasOwnProperty.call(
      permissionStore().allSystemConfigs,
      systemConfig.ADVANCED_COMPLETI_ALLOWED_TO_SKIP_COMPLETION_PRO
    )
  )
    return true;

  return permissionStore().allSystemConfigs[
    systemConfig.ADVANCED_COMPLETI_ALLOWED_TO_SKIP_COMPLETION_PRO
  ];
});
const proofTypeSelected = ref<{
  IMAGE: boolean;
  DOCUMENT: boolean;
  NOTE: boolean;
  COMMENT: boolean;
}>({
  IMAGE: !!finishProofTypesDefault.value?.includes('IMAGE') || true,
  DOCUMENT: !!finishProofTypesDefault.value?.includes('DOCUMENT'),
  NOTE: !!finishProofTypesDefault.value?.includes('NOTE'),
  COMMENT: !!finishProofTypesDefault.value?.includes('COMMENT'),
});
const finishProofSkipAllowed = ref<boolean>(
  typeof finishProofSkipAllowedDefault.value == 'undefined'
    ? true
    : !!finishProofSkipAllowedDefault.value
);

const onSave = () => {
  emit('onSave', {
    finishProofEnabled: true,
    finishProofTypes: Object.entries(proofTypeSelected.value)
      ?.map(([key, value]) => {
        return value ? key : '';
      })
      ?.filter((k) => k)
      ?.join(';'),
    finishProofSkipAllowed: finishProofSkipAllowed.value,
  });
};

const onProofTypeChange = (type) => {
  const selectedTypes = Object.values(proofTypeSelected.value).filter((t) => t);

  // Verify at least 1 type selected
  if (selectedTypes.length <= 0) {
    settingGlobalModal({
      type: 'notification',
      title: translate('DECIDE_PAYMENT_HAS_VOUCHER_CONFIRM_SELECT_TRIAL_TITLE'),
      content: translate('SETTING_TASK_FINISH_PROOF_TYPE_AT_LEAST_MSG'),
      confirmable: true,
    });

    ask();
    setTimeout(() => (proofTypeSelected.value[type] = true));
  }
};
</script>

<template>
  <SynModal
    container-class="w-11/12 max-w-lg"
    container-style=""
    style-body="px-4 pb-4"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    @cancel="$emit('onClose')"
    @confirm="onSave"
  >
    <template #header>
      <div class="flex gap-2 items-center">
        <span>
          {{
            $t('TASK_LABEL_ADJUST_EVIDENCE', {
              code: formatTaskCode(task?.code),
            })
          }}
        </span>
      </div>
    </template>

    <template #body>
      <div class="pl-8 pr-6 py-2 border-t border-dashed">
        <div class="font-medium text-gray-800">
          {{ $t('SETTING_TASK_FINISH_PROOF_TYPE_REQUIRED') }}
        </div>
        <div class="text-xs text-gray-600 mb-2">
          {{ $t('SETTING_TASK_FINISH_PROOF_TYPE_REQUIRED_DESC') }}
        </div>
        <VigCheckbox
          v-model="proofTypeSelected['IMAGE']"
          @on-change="onProofTypeChange('IMAGE')"
        >
          <span class="text-sm">
            {{ $t('COMMON_LABEL_IMAGE') }}
          </span>
        </VigCheckbox>
        <VigCheckbox
          v-model="proofTypeSelected['DOCUMENT']"
          @on-change="onProofTypeChange('DOCUMENT')"
        >
          <span class="text-sm">
            {{ $t('COMMON_LABEL_DOCUMENT') }}
          </span>
        </VigCheckbox>
        <VigCheckbox
          v-model="proofTypeSelected['NOTE']"
          @on-change="onProofTypeChange('NOTE')"
        >
          <span class="text-sm">
            {{ $t('COMMON_LABEL_NOTE_2') }}
          </span>
        </VigCheckbox>
        <VigCheckbox
          v-model="proofTypeSelected['COMMENT']"
          @on-change="onProofTypeChange('COMMENT')"
        >
          <span class="text-sm">
            {{ $t('COMMON_LABEL_COMMENT') }}
          </span>
        </VigCheckbox>
      </div>

      <div class="pl-2 border-t border-dashed">
        <MenuSettingRowItem
          :title-menu="$t('SETTING_TASK_FINISH_PROOF_SKIP_ALLOWED')"
          :sub-title="$t('SETTING_TASK_FINISH_PROOF_SKIP_ALLOWED_DESC')"
        >
          <template #action>
            <AtomSwitch v-model="finishProofSkipAllowed" />
          </template>
        </MenuSettingRowItem>
      </div>
    </template>
  </SynModal>
</template>
