<template>
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 423.49 388.44"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          d="m135.15,100.6c24.72-24.73,59.88-35.99,94.37-30.21,58.93,9.79,98.77,65.51,88.97,124.44-2,12.05-6.03,23.68-11.92,34.39-.23.62-.23,1.3,0,1.91l10.37,35.75c2.05,6.59-1.63,13.59-8.21,15.64-2.44.76-5.06.75-7.5-.02l-35.55-10.68c-.65-.23-1.36-.23-2.01,0-15.88,8.91-33.76,13.63-51.97,13.7-7.82-.02-15.61-.83-23.27-2.42-58.45-12.88-95.39-70.7-82.52-129.15,4.45-20.21,14.6-38.72,29.24-53.35Zm116.83,93.77c6.95,0,12.59-5.64,12.59-12.59s-5.64-12.59-12.59-12.59-12.59,5.64-12.59,12.59,5.64,12.59,12.59,12.59Zm-40.29,0c6.95,0,12.59-5.64,12.59-12.59s-5.64-12.59-12.59-12.59-12.59,5.64-12.59,12.59,5.64,12.59,12.59,12.59Zm-40.29,0c6.95,0,12.59-5.64,12.59-12.59s-5.64-12.59-12.59-12.59-12.59,5.64-12.59,12.59,5.64,12.59,12.59,12.59Z"
        />
        <path
          style="fill-rule: evenodd"
          d="m38.87,126.11C64.15,51.37,134.55,0,214.74,0s150.58,51.37,175.87,126.11h12.17c11.41,0,20.72,9.31,20.72,20.72v63.06c0,11.41-9.31,20.72-20.72,20.72h-7.91c-16.53,66.41-68.79,118.66-135.19,135.18-.67,12.62-11.19,22.66-23.91,22.66h-27.51c-13.18,0-23.94-10.76-23.94-23.94s10.76-23.94,23.94-23.94h27.51c9.19,0,17.35,5.25,21.36,13.15,60.77-15.27,108.84-62.65,125.04-123.11h-8.5c-11.41,0-20.72-9.31-20.72-20.72v-63.06c0-11.41,9.31-20.72,20.72-20.72h3.89C352.78,58.36,288.16,12.33,214.74,12.33S76.62,58.41,51.88,126.21c10.49,1.04,18.66,9.95,18.66,20.62v63.06c0,11.41-9.31,20.72-20.72,20.72h-29.1c-11.41,0-20.72-9.31-20.72-20.72v-63.06c0-11.41,9.31-20.72,20.72-20.72h18.15,0Z"
        />
      </g>
    </g>
  </svg>
</template>
