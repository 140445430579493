import { onMounted, watch } from 'vue';
import Favico from '@/ui/plugins/favico/favico';
import chatStore from '@/store/chat';
import notificationStore from '@/store/notification/notification.store';

export default function useAppFavicon() {
    const _favicon = Favico({ animation: 'popFade' });

    const _chatStore = chatStore();
    const _notificationStore = notificationStore();

    watch(
        () => [
            _chatStore.unSeenConversationsCount,
            _notificationStore.numberTotalNotificationUnread,
        ],
        () => _processFavicon()
    );

    onMounted(() => _processFavicon());

    const _processFavicon = () => {
        const totalNotif =
            (_chatStore.unSeenConversationsCount || 0) +
            (_notificationStore.numberTotalNotificationUnread || 0);

        if (totalNotif > 0) {
            _favicon.badge(totalNotif > 9 ? '9+' : totalNotif);
        } else {
            _favicon.reset();
        }
    };
}
