import {
    DayoffExceptionType,
    DayoffReason,
    DayoffStatus,
    DayoffType,
} from '@/domain/enums/DayoffEnums';

export enum CalendarSharingStatusEnum {
    NotSharing = 'NOT_SHARING',
    Sharing = 'SHARING',
}

export interface CalendarSharingModel {
    id?: string;
    organizationId?: number;
    userId?: number;
    calendarId?: number;
    status?: CalendarSharingStatusEnum;
    validFrom?: Date;
    validTo?: Date;
    sharingData?: CalendarSharingDataModel;
    createdBy?: number;
    createdDate?: Date;
    updatedBy?: number;
    updatedDate?: Date;
}

export interface CalendarSharingDataModel {
    dateFrom?: Date;
    dateTo?: Date;
    eventTypes?: number[];
    specificEventIds?: string[];
}

export interface CalendarSharingPublicModel {
    updatedDate?: Date;
    events?: CalendarSharingEventModel[];
}

export interface CalendarSharingEventModel {
    id?: string;
    title?: string;
    description?: string;
    type?: number;
    startDate?: Date;
    endDate?: Date;
    allDay?: boolean;
    meetingType?: number;
    workspace?: string;
    participants?: CalendarSharingUserModel[];
    attachments?: {
        files: any[];
        notes: any[];
    };
    repeatType?: number;
    repeatSpecificDaysOfWeek?: number[];
    repeatRRules?: string[];
    dayOff?: CalendarSharingDayOffModel;
    updatedDate?: Date;
}

export interface CalendarSharingDayOffModel {
    type?: DayoffType;
    exceptionType?: DayoffExceptionType;
    reason?: DayoffReason;
    status?: DayoffStatus;
    user?: CalendarSharingUserModel;
}

export interface CalendarSharingUserModel {
    name?: string;
    avatar?: string;
}
