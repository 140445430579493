<template>
  <svg
    viewBox="0 0 500.578 500.578"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <desc>Created with Pixso.</desc>
    <defs />
    <path
      id="path"
      d="M173.99 77.19C168.15 83.03 168.13 88.85 173.93 94.65C179.73 100.45 185.55 100.43 191.39 94.58L238.66 47.31L238.18 180.49C238.3 188.58 242.4 192.61 250.49 192.58C258.58 192.54 262.71 188.48 262.87 180.39L263.35 47.21L310.3 94.16C316.1 99.96 321.92 99.94 327.76 94.1C333.6 88.25 333.62 82.43 327.82 76.63L259.88 8.69C254.08 2.89 248.26 2.92 242.42 8.76L173.99 77.19ZM326.58 423.38C332.42 417.54 332.44 411.72 326.64 405.92C320.84 400.12 315.02 400.14 309.18 405.98L261.91 453.25L262.39 320.08C262.56 311.7 258.46 307.53 250.08 307.56C241.7 307.6 237.57 311.8 237.69 320.18L237.22 453.35L190.27 406.41C184.47 400.61 178.65 400.63 172.81 406.47C166.97 412.31 166.95 418.14 172.75 423.94L240.69 491.87C245.49 496.68 253.31 496.65 258.15 491.81L326.58 423.38Z"
      fill="#000000"
      fill-opacity="1.000000"
      fill-rule="nonzero"
    />
  </svg>
</template>
