<template>
  <div
    class="flex justify-center text-center"
    :class="size == 'small' ? 'text-xs' : ''"
  >
    <span class="w-5">{{ hours }}</span>
    <span class="w-2">{{ ' : ' }}</span>
    <span class="w-5">{{ minutes }}</span>
    <span class="w-2">{{ ' : ' }}</span>
    <span class="w-5">{{ seconds }}</span>
  </div>
</template>
<script>
import { computed } from 'vue';

export default {
  components: {},
  props: {
    dateTime: {
      type: [String, Number], // time stamp
      required: true,
      default: '',
    },
    isDistance: {
      type: Boolean, // time stamp
      default: false,
    },
    timezone: {
      type: String, // time stamp
      default: '0',
    },
    size: {
      type: String,
      default: 'normal',
    },
  },
  emits: ['onStop'],
  setup: (props) => {
    const hours = computed(() => {
      const timezone = props.timezone ? parseInt(props.timezone) : 0;
      const _hours = props.isDistance
        ? Math.floor(
            (props.dateTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
        : new Date(props.dateTime).getHours() + timezone;

      return ('0' + _hours).slice(-2);
    });
    const minutes = computed(() => {
      const _minutes = props.isDistance
        ? Math.floor((props.dateTime % (1000 * 60 * 60)) / (1000 * 60))
        : new Date(props.dateTime).getMinutes();
      return ('0' + _minutes).slice(-2);
    });
    const seconds = computed(() => {
      const _seconds = props.isDistance
        ? Math.floor((props.dateTime % (1000 * 60)) / 1000)
        : new Date(props.dateTime).getSeconds();

      return ('0' + _seconds).slice(-2);
    });
    return {
      hours,
      minutes,
      seconds,
    };
  },
};
</script>
