import { diffDate } from '@/ui/plugins/filters';
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import storeId from '@/base/store/storeId';
import {
    FilterQuery,
    ICustomFilterPayload,
} from '@/domain/entities/task/FilterTask';
import TaskService from '@/application/services/task/TaskService';
import {
    genSortData,
    getUrgencyValue,
    sortDefaultTaskList,
    sortTaskDefault,
    taskStates,
} from '@/ui/hooks/taskHook';
import { TaskActionType, TaskLife, TaskListTab } from '@/domain/enums/taskEnum';
import {
    arrayOrderBy,
    cloneDeep,
    stringNomalize,
    uniq,
} from '@/ui/hooks/commonFunction';
import remoteConfigStore from '@/store/remoteConfig';
// import { keySearch } from '@/ui/modules/task/table/task-list-state';
import { RemoteConfigKey } from '@/ui/plugins/firebases/firebase-remote-config';
import taskTabViewStore from '@/store/task/task-tab-view';
import {
    ECustomFilterSourceType,
    ETaskListModule,
    TaskApprovalStatus,
    UpdateMultipleTaskPayload,
} from '@/application/types/task/task.types';
import {
    EFilterTaskBoard,
    EFilterTaskDefault,
    LIST_FILTER_TASK_DEFAULT,
    TabTask,
    TaskStatusByTaskLife,
    getFilterPayloadByChildTab,
    getFilterPayloadByOwner,
} from '@/application/constants/task.const';
// import taskSmartView from '@/store/task/task-smart-view';
// import _ from 'lodash';
import taskStore from '@/store/task';
import userStore from '@/store/user';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { ITaskSummaryStatistics } from '@/domain/entities/task/TaskListClass';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { chain, groupBy, isEqual, orderBy } from 'lodash';

const mapKeyForFilter = {
    creatorId: 'id',
    assigneeId: 'id',
    priority: 'key',
    urgency: 'id',
    domainId: 'id',
    groupId: 'id',
    taskLife: 'id',
};
const enableSortMultiple = false;

export default defineStore({
    id: storeId.taskList,
    state: () => ({
        isLoading: true,
        isLoadingFilterTask: false,
        isLoadMore: false,
        totalTasks: 0,
        totalAssignTasks: 0,
        taskIds: [] as any[],
        taskAllList: [] as any[],
        relatedTaskAllList: [] as any[],
        filteredTasks: [] as any[],
        listSubProject: [] as any[],
        sortList: [] as any[],
        activeTab: TaskListTab.MyList as TaskListTab,
        ownerInfo: {
            userId: 0,
            groupId: 0,
        } as any,
        filterDataByKey: {} as any,
        filterPayload: new FilterQuery({
            isDefault: true,
        }),
        filterAssignedPayload: new FilterQuery({
            isDefault: true,
        }),
        filterRelatedPayload: new FilterQuery({
            isDefault: true,
        }),
        _taskService: TaskService.getInstance(),
        taskSummaryStatistics: {} as ITaskSummaryStatistics,
        assignTaskSummaryStatistics: {} as ITaskSummaryStatistics,
        approvalTaskSummaryStatistics: {} as {
            totalAll: number;
            totalApproval: number;
            totalApproved: number;
            totalRequesting: number;
        },
        selectedTaskIds: [] as number[],
        lastUpdatedTime: undefined,
        currentOwner: {
            id: getCurrentUserId(),
            type: ETaskListModule.USER,
        } as {
            id: number;
            type: ETaskListModule;
        },
        hasSubTasks: false as boolean,
        isGroupSubTasks: false as boolean,
        parentTaskByIds: {} as any,
    }),
    getters: {
        sortDataOrderByKey() {
            let result = {};
            const sortLength = this.sortList?.length;
            if (sortLength > 0) {
                const isShowIndex = sortLength > 1;
                result = this.sortList.reduce(
                    (previousValue, currentValue: any, index) => {
                        previousValue = {
                            ...previousValue,
                            ...{
                                [currentValue.key]: {
                                    sortIndex: isShowIndex ? index + 1 : null,
                                    orderBy: currentValue.orderBy,
                                },
                            },
                        };
                        return previousValue;
                    },
                    {}
                );
            }
            // console.log(' result-------------:', result);
            return result;
        },
    },
    actions: {
        initSortData() {
            this.sortList = [];
        },
        initFilterPayload(ownerId, sourceType: ETaskListModule) {
            this.filterPayload = getFilterPayloadByOwner(ownerId, sourceType);
        },
        initTaskStatistic(ownerId, sourceType: ETaskListModule) {
            sourceType = this.currentOwner?.type || ETaskListModule.USER;

            this.getTaskSummaryStatistics(sourceType, ownerId);

            if (
                this.currentOwner?.type == ETaskListModule.USER ||
                this.currentOwner?.type == ETaskListModule.I_ASSIGNED ||
                this.currentOwner?.type == ETaskListModule.WAITING_MY_APPROVAL
            ) {
                this.getAllAssigneeTaskStatisticByUser(ownerId);
                this.getAllApprovalTaskStatisticByUser(ownerId);
            }
        },
        initListData(
            ownerId,
            sourceType: ETaskListModule,
            options?: {
                customFilterSourceType?: ECustomFilterSourceType;
                isReload?: boolean;
            }
        ) {
            taskTabViewStore().getUserCustomTaskFilters(
                options?.customFilterSourceType || sourceType,
                ownerId
            );
            this.currentOwner = {
                id: ownerId,
                type: sourceType,
            };

            this.setDefaultInitData(this.activeTab);

            this.initSortData();
            this.initFilterPayload(ownerId, sourceType);
            this.initTaskStatistic(ownerId, sourceType);

            if (options?.isReload) {
                taskTabViewStore().changeTabActive(
                    taskTabViewStore().currentTabId
                );

                this.updateFilterPayload(
                    LIST_FILTER_TASK_DEFAULT[taskTabViewStore().currentTabId]
                );
            } else {
                taskTabViewStore().changeTabActive(EFilterTaskDefault.DEFAULT);
                if (
                    taskTabViewStore().defaultTab !== EFilterTaskDefault.DEFAULT
                ) {
                    taskTabViewStore().changeTabActive(
                        taskTabViewStore().defaultTab
                    );

                    this.updateFilterPayload(
                        LIST_FILTER_TASK_DEFAULT[taskTabViewStore().defaultTab]
                    );
                }
            }

            return this.fetchTasks(true);
        },
        onChangeParentTab(tab: TaskListTab) {
            this.resetTabView();
            this.onChangeTabActive(tab);

            if (
                tab !== TaskListTab?.MyList &&
                tab !== TaskListTab?.MyAssign &&
                tab !== TaskListTab?.WaitingApprovalTasks
            ) {
                return (this.isLoading = false);
            }

            let sourceType = this.currentOwner?.type || ETaskListModule.USER;

            if (tab == TaskListTab.MyAssign) {
                sourceType = ETaskListModule.I_ASSIGNED;
            }
            if (tab == TaskListTab.WaitingApprovalTasks) {
                sourceType = ETaskListModule.WAITING_MY_APPROVAL;
            }

            this.initFilterPayload(this.currentOwner?.id, sourceType);
            this.initTaskStatistic(this.currentOwner?.id, sourceType);

            return this.fetchTasks(true);
        },
        onChangeChildTab(childTab: TabTask) {
            const childTabId: EFilterTaskDefault = childTab?.id;
            taskTabViewStore().changeTabActive(childTabId);

            this.updateFilterPayload(childTab);

            this.fetchTasks(true);
        },
        updateFilterPayload(childTab, isDisableUpdatePayload?: boolean) {
            const newFilterPayload = getFilterPayloadByChildTab(
                this.filterPayload,
                childTab
            );

            if (!isDisableUpdatePayload) this.filterPayload = newFilterPayload;

            return newFilterPayload;
        },
        resetTabView() {
            taskTabViewStore().setCustomizeViewActive();
            taskTabViewStore().changeTabActive(EFilterTaskDefault.DEFAULT);
            taskTabViewStore().changeCustomTabInBoard(EFilterTaskBoard.DEFAULT);
        },
        setCustomFilterPayload(customFilterPayload: ICustomFilterPayload) {
            this.filterPayload = new FilterQuery({
                ...this.filterPayload,
                ...customFilterPayload,
            });
        },

        setIsLoadingFilter(value) {
            this.isLoadingFilterTask = value;
        },
        setFilterPayload(filters, isMerge?: boolean) {
            this.filterPayload = new FilterQuery(
                isMerge
                    ? {
                          ...this.filterPayload,
                          ...filters,
                      }
                    : { ...filters }
            );
        },
        resetFilterPayload() {
            this.filterPayload = new FilterQuery({
                isDefault: true,
            });
        },

        setFilterRelatedPayload(filters) {
            this.filterRelatedPayload = new FilterQuery({
                ...this.filterRelatedPayload,
                ...filters,
            });
        },
        async onResetMyTaskTab() {
            this.filterPayload = new FilterQuery({
                isDefault: true,
                listOwnerId: this.filterPayload.listOwnerId,
            });
            await this.fetchTasks(false);
        },
        async onResetMyAssignedTaskTab() {
            this.filterAssignedPayload = new FilterQuery({
                isDefault: true,
                assignorId: this.filterAssignedPayload.assignorId,
            });
            await this.fetchTasks(false);
        },
        async onResetMyRelatedTaskTab() {
            this.filterRelatedPayload = new FilterQuery({
                isDefault: true,
                assignorId: this.filterRelatedPayload.assignorId,
            });
            await this.getCollaboratorTask(false);
        },

        onGroupSubTasks(_allTaskList) {
            const subTaskByParentIds = groupBy(
                _allTaskList?.filter((t) => t?.parentId),
                'parentId'
            );

            const parentTaskByIds = chain(
                _allTaskList?.filter((t) => t?.parentId)
            )
                .map((t) => ({
                    id: t?.parentId,
                    code: t?.parentCode,
                    name: t?.parentName,
                    isCollapse: true,
                }))
                .uniqWith(isEqual)
                .map((t) => {
                    return {
                        ...t,
                        subTasks: subTaskByParentIds[t?.id],
                    };
                })
                .keyBy('id')
                .value();

            const notExistedParents = Object.values(parentTaskByIds)
                ?.filter(
                    (task: any) =>
                        !_allTaskList
                            ?.filter((t) => !t?.parentId)
                            ?.some((t) => t?.id == task?.id)
                )
                ?.map((t: any) => {
                    return {
                        ...t,
                        isNotBelongThis: true,
                    };
                });

            const allTaskList = [
                ..._allTaskList.map((task) => {
                    return {
                        ...task,
                        urgency: getUrgencyValue(
                            task?.priority,
                            task?.important
                        ),
                        scheduleTime: task?.scheduleTime
                            ? dayjs(task?.scheduleTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : task?.scheduleTime,
                        planningStartTime: task?.planningStartTime
                            ? dayjs(task?.planningStartTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : task?.planningStartTime,
                        planningEndTime: task?.planningEndTime
                            ? dayjs(task?.planningEndTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : task?.planningEndTime,
                        status: TaskStatusByTaskLife[task?.taskLife],
                        subTasks: subTaskByParentIds[task?.id],
                        isNotBelongThis: false,
                    };
                }),
                ...notExistedParents,
            ];

            return {
                parentTaskByIds,
                allTaskList,
                notExistedParents,
            };
        },
        async fetchTasks(isNeedLoading = true) {
            try {
                if (isNeedLoading) this.isLoading = true;

                let res = null as any;
                let currentFilterData = this.filterPayload;

                // TODO: CHECK MISS CONDITION: MUST VIEW IN COLUMN LAYOUT
                if (
                    taskTabViewStore().childrenTabActive ==
                    EFilterTaskDefault.MY_RELATED
                ) {
                    this.filterPayload.menuTab = 104;
                    const promiseList = [
                        this._taskService.getTaskWithFilter(this.filterPayload),
                    ];
                    if (taskTabViewStore().boardShowFinishedTask) {
                        promiseList.push(
                            this._taskService.getTaskWithFilter({
                                ...this.filterPayload,
                                isDefault: false,
                                status: '3',
                            })
                        );
                    }
                    const resAll: any[] = await Promise.all(promiseList);

                    const taskList = resAll.reduce(
                        (currentObject, currentItem) => {
                            return [
                                ...currentObject,
                                ...currentItem?.result?.filter(
                                    (task) =>
                                        !currentObject?.some(
                                            (t) => t?.id == task?.id
                                        )
                                ),
                            ];
                        },
                        []
                    );

                    res = {
                        result: taskList,
                    };
                } else {
                    if (
                        taskTabViewStore().childrenTabActive ==
                            EFilterTaskDefault.OVERDUE_DATE &&
                        taskTabViewStore().boardShowFinishedTask
                    ) {
                        currentFilterData = {
                            ...currentFilterData,
                            isIncludeFinished: true,
                        };
                    }
                    res = await this._taskService.getTaskWithFilter(
                        currentFilterData
                    );
                }

                if (currentFilterData?.isSubTask)
                    this.setFilterDataByKey('subTasks', false);

                if (!res || !res.result) return (this.isLoading = false);

                if (this.isGroupSubTasks) {
                    const dataResult = this.onGroupSubTasks(res?.result);

                    this.parentTaskByIds = dataResult?.parentTaskByIds;
                    this.taskAllList = dataResult?.allTaskList;

                    taskStore().mergeAllTaskByIds(
                        this.taskAllList?.reduce(
                            (currentResult, currentItem) => {
                                return {
                                    ...currentResult,
                                    [currentItem?.id]: {
                                        ...currentItem,
                                    },
                                };
                            },
                            {}
                        )
                    );
                } else {
                    this.taskAllList = res.result?.map((task) => {
                        if (task?.parentId) this.hasSubTasks = true;
                        return {
                            ...task,
                            urgency: getUrgencyValue(
                                task?.priority,
                                task?.important
                            ),
                            scheduleTime: task?.scheduleTime
                                ? dayjs(task?.scheduleTime)
                                      .utc(true)
                                      .format('YYYY-MM-DDTHH:mm:ssZ')
                                : task?.scheduleTime,
                            planningStartTime: task?.planningStartTime
                                ? dayjs(task?.planningStartTime)
                                      .utc(true)
                                      .format('YYYY-MM-DDTHH:mm:ssZ')
                                : task?.planningStartTime,
                            planningEndTime: task?.planningEndTime
                                ? dayjs(task?.planningEndTime)
                                      .utc(true)
                                      .format('YYYY-MM-DDTHH:mm:ssZ')
                                : task?.planningEndTime,
                            status: TaskStatusByTaskLife[task?.taskLife],
                        };
                    });
                    taskStore().mergeAllTaskByIds(
                        this.taskAllList?.reduce(
                            (currentResult, currentItem) => {
                                return {
                                    ...currentResult,
                                    [currentItem?.id]: {
                                        ...currentItem,
                                    },
                                };
                            },
                            {}
                        )
                    );
                }

                taskTabViewStore().updateDefaultTab(EFilterTaskDefault.DEFAULT);
                this.isLoading = false;
            } catch (e) {
                console.log('🚀 Tictop ~ e:', e);
                this.isLoading = false;
            }
        },
        async getTaskWithFilter(filters) {
            try {
                this.isLoadingFilterTask = true;
                const res = await this._taskService.getTaskWithFilter(filters);
                this.taskAllList = (res?.result || []).map((o) => {
                    const taskDetail = {
                        ...o,
                        groupId: o.groupId || 0,
                        urgency: getUrgencyValue(o?.priority, o?.important),
                        scheduleTime: o?.scheduleTime
                            ? dayjs(o?.scheduleTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : o?.scheduleTime,
                        planningStartTime: o?.planningStartTime
                            ? dayjs(o?.planningStartTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : o?.planningStartTime,
                        planningEndTime: o?.planningEndTime
                            ? dayjs(o?.planningEndTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : o?.planningEndTime,
                    };
                    return taskDetail;
                });

                taskStore().mergeAllTaskByIds(
                    this.taskAllList?.reduce((currentResult, currentItem) => {
                        return {
                            ...currentResult,
                            [currentItem?.id]: currentItem,
                        };
                    }, {})
                );
            } catch {
                this.isLoading = false;
                this.isLoadingFilterTask = false;
            } finally {
                this.isLoading = false;
                this.isLoadingFilterTask = false;
            }
        },
        async getCollaboratorTask(isNeedLoading = true) {
            try {
                if (isNeedLoading) this.isLoading = true;

                const res: any = await this._taskService.getCollaboratorTask(
                    this.filterRelatedPayload
                );

                if (!res || !res.result) return (this.isLoading = false);

                this.relatedTaskAllList = res.result.map((o) => {
                    const taskDetail = {
                        ...o,
                        groupId: o.groupId || 0,
                        urgency: getUrgencyValue(o?.priority, o?.important),
                        scheduleTime: o?.scheduleTime
                            ? dayjs(o?.scheduleTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : o?.scheduleTime,
                        planningStartTime: o?.planningStartTime
                            ? dayjs(o?.planningStartTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : o?.planningStartTime,
                        planningEndTime: o?.planningEndTime
                            ? dayjs(o?.planningEndTime)
                                  .utc(true)
                                  .format('YYYY-MM-DDTHH:mm:ssZ')
                            : o?.planningEndTime,
                    };
                    return taskDetail;
                });

                taskStore().mergeAllTaskByIds(
                    this.relatedTaskAllList?.reduce(
                        (currentResult, currentItem) => {
                            return {
                                ...currentResult,
                                [currentItem?.id]: currentItem,
                            };
                        },
                        {}
                    )
                );
            } finally {
                this.isLoading = false;
            }
        },

        async updateListTaskById(taskId, taskAction, ownerId?) {
            if (!taskId) return;
            // 1. Delete or Move out
            if (
                taskAction == TaskActionType.Delete ||
                taskAction == TaskActionType.MoveOut ||
                (this.activeTab == TaskListTab.MyRelated &&
                    taskAction == TaskActionType.RemoveCollaborator) ||
                (this.activeTab == TaskListTab.WaitingApprovalTasks &&
                    taskAction == TaskActionType.ApproverRemoved)
            ) {
                // 1.1 delete task id out list Id
                this.filteredTasks = this.filteredTasks.filter(
                    (t) => t.id !== taskId
                );
                this.taskIds = this.taskIds.filter((t) => t !== taskId);
                this.selectedTaskIds = this.selectedTaskIds.filter(
                    (t) => t !== taskId
                );
                this.taskAllList = this.taskAllList.filter(
                    (t) => t.id !== taskId
                );

                // taskSmartView().deleteTask(taskId);
                return;
            }
            // 2. Add or update
            const res = await this._taskService.getDetailTaskIncognitoById(
                taskId
            );
            const taskDetail = res?.result;

            const taskDetailUpdate: ITaskDetailClass = {
                ...taskDetail,
                urgency: getUrgencyValue(
                    taskDetail?.priority,
                    taskDetail?.important
                ),
                scheduleTime: taskDetail?.scheduleTime
                    ? dayjs(taskDetail?.scheduleTime)
                          .utc(true)
                          .format('YYYY-MM-DDTHH:mm:ssZ')
                    : taskDetail?.scheduleTime,
                planningStartTime: taskDetail?.planningStartTime
                    ? dayjs(taskDetail?.planningStartTime)
                          .utc(true)
                          .format('YYYY-MM-DDTHH:mm:ssZ')
                    : taskDetail?.planningStartTime,
                planningEndTime: taskDetail?.planningEndTime
                    ? dayjs(taskDetail?.planningEndTime)
                          .utc(true)
                          .format('YYYY-MM-DDTHH:mm:ssZ')
                    : taskDetail?.planningEndTime,
            };
            taskStore().updateAllTaskByIds(
                taskDetailUpdate.id,
                taskDetailUpdate
            );

            switch (this.currentOwner?.type) {
                case ETaskListModule.DOMAIN:
                case ETaskListModule.PROJECT:
                case ETaskListModule.SUB_PROJECT:
                    this.getTaskSummaryStatistics(
                        this.currentOwner?.type,
                        this.currentOwner?.id
                    );
                    break;

                default:
                    break;
            }

            switch (this.activeTab) {
                case TaskListTab.WaitingApprovalTasks:
                    {
                        // update approval tasks count
                        this.getAllApprovalTaskStatisticByUser(ownerId);

                        switch (taskTabViewStore().childrenTabActive) {
                            case EFilterTaskDefault.DEFAULT:
                                if (
                                    taskDetailUpdate?.approvalStatus ==
                                        TaskApprovalStatus.WAITING &&
                                    !this.taskAllList.some(
                                        (o) => o.id == taskId
                                    )
                                ) {
                                    this.filteredTasks.push(taskDetailUpdate);
                                } else {
                                    this.filteredTasks =
                                        this.filteredTasks?.filter(
                                            (task) => task?.id !== taskId
                                        );
                                    this.taskAllList = this.taskAllList?.filter(
                                        (task) => task?.id !== taskId
                                    );
                                }
                                break;
                            case EFilterTaskDefault.NEED_APPROVAL:
                                if (
                                    (taskDetailUpdate?.approvalStatus ==
                                        TaskApprovalStatus.NOT_REQUESTED ||
                                        taskDetailUpdate?.approvalStatus ==
                                            TaskApprovalStatus.REJECTED) &&
                                    !this.taskAllList.some(
                                        (o) => o.id == taskId
                                    )
                                ) {
                                    this.filteredTasks.push(taskDetailUpdate);
                                } else {
                                    this.filteredTasks =
                                        this.filteredTasks?.filter(
                                            (task) => task?.id !== taskId
                                        );
                                    this.taskAllList = this.taskAllList?.filter(
                                        (task) => task?.id !== taskId
                                    );
                                }
                                break;
                            case EFilterTaskDefault.APPROVED:
                                if (
                                    taskDetailUpdate?.approvalStatus ==
                                        TaskApprovalStatus.APPROVED &&
                                    !this.taskAllList.some(
                                        (o) => o.id == taskId
                                    )
                                ) {
                                    this.filteredTasks.push(taskDetailUpdate);
                                } else {
                                    this.filteredTasks =
                                        this.filteredTasks?.filter(
                                            (task) => task?.id !== taskId
                                        );
                                    this.taskAllList = this.taskAllList?.filter(
                                        (task) => task?.id !== taskId
                                    );
                                }
                                break;

                            default:
                                break;
                        }
                        this.onSortFilteredTasks();
                    }
                    break;

                default:
                    {
                        // Update task's list
                        switch (taskAction) {
                            case TaskActionType.AddNew:
                            case TaskActionType.MoveIn:
                                {
                                    // taskSmartView().addTask(taskDetailUpdate);

                                    const childTab =
                                        taskTabViewStore().childrenTabActive;

                                    if (
                                        childTab ===
                                            EFilterTaskDefault.FINISHED &&
                                        !taskDetailUpdate?.finisherId
                                    ) {
                                        break;
                                    }

                                    if (
                                        childTab ===
                                            EFilterTaskDefault.DO_TODAY &&
                                        !taskDetailUpdate?.isPlannedToFinish &&
                                        !taskDetailUpdate?.isPlannedToTreat
                                    ) {
                                        return;
                                    }

                                    if (
                                        childTab ===
                                            EFilterTaskDefault.OVERDUE_DATE &&
                                        taskDetailUpdate?.scheduleTime
                                    ) {
                                        const date1_fullyear = new Date(
                                            taskDetailUpdate?.scheduleTime
                                        ).getFullYear();
                                        const date1_date = new Date(
                                            taskDetailUpdate?.scheduleTime
                                        ).getDate();
                                        const date1_month = new Date(
                                            taskDetailUpdate?.scheduleTime
                                        ).getMonth();
                                        const date2_fullyear =
                                            new Date().getFullYear();
                                        const date2_date = new Date().getDate();
                                        const date2_month =
                                            new Date().getMonth();

                                        if (
                                            date1_fullyear > date2_fullyear &&
                                            date1_date > date2_date &&
                                            date1_month > date2_month
                                        ) {
                                            break;
                                        }
                                    }

                                    if (
                                        !this.taskAllList.some(
                                            (o) => o.id == taskId
                                        )
                                    ) {
                                        this.taskAllList.push(taskDetailUpdate);
                                    }

                                    if (
                                        this.activeTab !== TaskListTab.MyList ||
                                        this.filteredTasks.some(
                                            (o) => o.id == taskId
                                        )
                                    ) {
                                        return;
                                    }

                                    this.onFilterTaskOnColumn();

                                    this.onSortFilteredTasks();
                                }
                                break;
                            case TaskActionType.Assigned:
                                {
                                    if (
                                        this.activeTab !==
                                            TaskListTab.MyAssign ||
                                        this.filteredTasks.some(
                                            (o) => o.id == taskId
                                        )
                                    ) {
                                        return;
                                    }

                                    if (
                                        !this.taskAllList.some(
                                            (o) => o.id == taskId
                                        )
                                    ) {
                                        this.taskAllList.push(taskDetailUpdate);
                                    }

                                    this.filteredTasks.push(taskDetailUpdate);
                                    this.onSortFilteredTasks();
                                }
                                break;
                            case TaskActionType.AddCollaborator:
                                {
                                    // taskSmartView().addCollaboratorTask(taskDetailUpdate);

                                    if (
                                        !this.relatedTaskAllList.some(
                                            (o) => o.id == taskId
                                        )
                                    ) {
                                        this.relatedTaskAllList.push(
                                            taskDetailUpdate
                                        );
                                    }

                                    if (
                                        this.activeTab !==
                                            TaskListTab.MyRelated ||
                                        this.filteredTasks.some(
                                            (o) => o.id == taskId
                                        )
                                    ) {
                                        return;
                                    }

                                    this.filteredTasks.push(taskDetailUpdate);
                                    this.onSortFilteredTasks();
                                }
                                break;

                            default:
                                // taskSmartView().updateTask(taskDetailUpdate);

                                break;
                        }

                        switch (taskTabViewStore().childrenTabActive) {
                            case EFilterTaskDefault.DO_TODAY:
                                if (ownerId == taskDetailUpdate?.assigneeId) {
                                    if (
                                        taskDetailUpdate?.isPlannedToFinish ||
                                        taskDetailUpdate?.isPlannedToTreat
                                    ) {
                                        if (
                                            !this.filteredTasks?.some(
                                                (task) =>
                                                    task?.id ==
                                                    taskDetailUpdate?.id
                                            )
                                        )
                                            this.filteredTasks.push(
                                                taskDetailUpdate
                                            );

                                        if (
                                            !this.taskIds?.some(
                                                (taskId) =>
                                                    taskId ==
                                                    taskDetailUpdate?.id
                                            )
                                        )
                                            this.taskIds.push(
                                                taskDetailUpdate?.id
                                            );

                                        if (
                                            !this.selectedTaskIds?.some(
                                                (taskId) =>
                                                    taskId ==
                                                    taskDetailUpdate?.id
                                            )
                                        )
                                            this.selectedTaskIds.push(
                                                taskDetailUpdate?.id
                                            );

                                        if (
                                            !this.taskAllList?.some(
                                                (task) =>
                                                    task?.id ==
                                                    taskDetailUpdate?.id
                                            )
                                        )
                                            this.taskAllList.push(
                                                taskDetailUpdate
                                            );

                                        this.onSortFilteredTasks();
                                    }

                                    const finishTimeDiff = diffDate(
                                        taskDetailUpdate.finishedTime,
                                        new Date(),
                                        'day',
                                        false
                                    );
                                    if (
                                        !taskDetailUpdate?.isPlannedToFinish &&
                                        !taskDetailUpdate?.isPlannedToTreat &&
                                        this.filteredTasks?.some(
                                            (task) =>
                                                task?.id == taskDetailUpdate?.id
                                        ) &&
                                        finishTimeDiff !== 0
                                    ) {
                                        this.filteredTasks =
                                            this.filteredTasks.filter(
                                                (t) => t.id !== taskId
                                            );
                                        this.taskIds = this.taskIds.filter(
                                            (t) => t !== taskId
                                        );
                                        this.selectedTaskIds =
                                            this.selectedTaskIds.filter(
                                                (t) => t !== taskId
                                            );
                                        this.taskAllList =
                                            this.taskAllList.filter(
                                                (t) => t.id !== taskId
                                            );
                                    }
                                }
                                break;
                            case EFilterTaskDefault.OVERDUE_DATE:
                                if (
                                    taskDetailUpdate?.taskLife ==
                                    TaskLife.Finished
                                ) {
                                    // 1.1 delete task id out list Id
                                    this.filteredTasks =
                                        this.filteredTasks.filter(
                                            (t) => t.id !== taskId
                                        );
                                    this.taskIds = this.taskIds.filter(
                                        (t) => t !== taskId
                                    );
                                    this.selectedTaskIds =
                                        this.selectedTaskIds.filter(
                                            (t) => t !== taskId
                                        );
                                    this.taskAllList = this.taskAllList.filter(
                                        (t) => t.id !== taskId
                                    );
                                }
                                break;
                            case EFilterTaskDefault.NEED_APPROVAL:
                                break;
                            case EFilterTaskDefault.APPROVED:
                                break;

                            default:
                                break;
                        }
                    }
                    break;
            }

            return taskDetailUpdate;
        },

        updateOwnerInfo(ownerInfo) {
            this.ownerInfo = ownerInfo;
        },

        setListSubProjectId() {
            const listProjectId = this.filteredTasks
                .filter((e: any) => e.projectId || !e.projectId)
                .map((o: any) => ({
                    id: o.projectId,
                    subProjectId: o.subprojectId,
                    selected: false,
                }));
            let listSubProjectId = [] as any;

            listProjectId.forEach((el: any) => {
                const index = listSubProjectId?.findIndex(
                    (o: any) => o?.id === el.id
                );
                if (index !== -1) {
                    const i = listSubProjectId[index]?.subProjectId?.findIndex(
                        (e) => e.subProjectId == el?.subProjectId
                    );
                    if (i !== -1) {
                        return;
                    } else {
                        listSubProjectId[index]?.subProjectId?.push({
                            subProjectId: el?.subProjectId,
                            selectedId: false,
                        });
                    }
                } else {
                    listSubProjectId.push({
                        id: el?.id,
                        subProjectId: [
                            {
                                subProjectId: el?.subProjectId,
                                selectedId: false,
                            },
                        ],
                        selected: false,
                    });
                }
            });
            listSubProjectId = listSubProjectId.map((o) => {
                o.subProjectId = arrayOrderBy(
                    o.subProjectId,
                    'subProjectId',
                    'asc'
                );
                o.collapse = !(
                    o.subProjectId.length > 0 &&
                    o.subProjectId.some((e) => e.subProjectId != null)
                );
                return o;
            });
            return listSubProjectId;
        },

        setListProjectId() {
            const listDomainId = this.filteredTasks
                .filter((e: any) => e.domainId)
                .map((o: any) => ({
                    id: o.domainId,
                    projectId: o.projectId,
                    selected: false,
                }));
            let listProjectId = [] as any;

            listDomainId.forEach((el: any) => {
                const index = listProjectId?.findIndex(
                    (o: any) => o?.id === el.id
                );
                if (index !== -1) {
                    const i = listProjectId[index]?.projectId?.findIndex(
                        (e) => e.projectId == el?.projectId
                    );
                    if (i !== -1) {
                        return;
                    } else {
                        listProjectId[index]?.projectId?.push({
                            projectId: el?.projectId,
                            selectedId: false,
                        });
                    }
                } else {
                    listProjectId.push({
                        id: el?.id,
                        projectId: [
                            {
                                projectId: el?.projectId,
                                selectedId: false,
                            },
                        ],
                        selected: false,
                    });
                }
            });
            listProjectId = listProjectId.map((o) => {
                o.projectId = arrayOrderBy(o.projectId, 'projectId', 'asc');
                o.collapse = !(
                    o.projectId.length > 0 &&
                    o.projectId.some((e) => e.projectId != null)
                );
                return o;
            });

            return listProjectId;
        },

        setFilterDataByKey(key, filters) {
            switch (key) {
                case 'scheduleTime':
                case 'creationTime':
                case 'lastModificationTime':
                    this.filterDataByKey[key] = {
                        filterType: filters?.filterType,
                        start: new Date(filters?.start).getTime(),
                        end: new Date(filters?.end).getTime(),
                    };
                    break;
                case 'doToday':
                case 'finishToday':
                    this.filterDataByKey[key] = filters;
                    break;

                case 'domainId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);

                            const newListProject = o.projectId.map((e) => {
                                const isFilterSub = filters.some(
                                    (u) =>
                                        u.id == o.id &&
                                        u.projectId.some(
                                            (el) =>
                                                el.projectId == e.projectId &&
                                                el.selectedId
                                        )
                                );
                                e.selectedId = isFilterSub;
                                return e;
                            });
                            o.subProjectId = newListProject;
                            o.selected = isFilter;
                            return o;
                        });
                    }
                    break;
                case 'projectId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);

                            const newListSubProject = o.subProjectId.map(
                                (e) => {
                                    const isFilterSub = filters.some(
                                        (u) =>
                                            u.id == o.id &&
                                            u.subProjectId.some(
                                                (el) =>
                                                    el.subProjectId ==
                                                        e.subProjectId &&
                                                    el.selectedId
                                            )
                                    );
                                    e.selectedId = isFilterSub;
                                    return e;
                                }
                            );
                            o.subProjectId = newListSubProject;
                            o.selected = isFilter;

                            return o;
                        });
                    }
                    break;

                case 'keySearch':
                case 'subTasks':
                case 'subDomain':
                    {
                        this.filterDataByKey[key] = filters;
                    }
                    break;

                default:
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);
                            o.selected = isFilter;
                            return o;
                        });
                    }
                    break;
            }
        },

        setFilterDataOrigin() {
            this.filterDataByKey = {
                urgency: [
                    {
                        id: 4,
                        selected: false,
                    },
                    {
                        id: 3,
                        selected: false,
                    },
                    {
                        id: 2,
                        selected: false,
                    },
                    {
                        id: 1,
                        selected: false,
                    },
                ],
                assigneeId: [
                    ...arrayOrderBy(
                        uniq(
                            this.filteredTasks.map(
                                (task) =>
                                    parseInt(task?.assigneeId?.toString()) || 0
                            )
                        )
                            .filter((o: any) => {
                                return (
                                    !isNaN(o) &&
                                    Object.prototype.hasOwnProperty.call(
                                        userStore().allUserByIds,
                                        o
                                    )
                                );
                            })
                            .map((o) => {
                                return {
                                    id: o,
                                    selected: false,
                                };
                            }),
                        ['id'],
                        ['desc']
                    ),
                ],
                domainId: [
                    // ...arrayOrderBy(
                    //     uniq(
                    //         this.filteredTasks.map((task) => task.domainId)
                    //     ).map((o) => {
                    //         return {
                    //             id: o,
                    //             selected: false,
                    //         };
                    //     }),
                    //     ['id'],
                    //     ['desc']
                    // ),
                    ...arrayOrderBy(
                        [...this.setListProjectId()],
                        ['projectId.length', 'id'],
                        ['desc', 'asc']
                    ),
                ],
                projectId: [
                    ...arrayOrderBy(
                        [...this.setListSubProjectId()],
                        ['subProjectId.length', 'id'],
                        ['desc', 'asc']
                    ),
                ],
                groupId: [
                    ...arrayOrderBy(
                        uniq(
                            this.filteredTasks.map((task) => task.groupId || 0)
                        ).map((o) => {
                            return {
                                id: o,
                                selected: false,
                            };
                        }),
                        ['id'],
                        ['desc']
                    ),
                ],
                doToday: [
                    {
                        key: 'DO_TODAY',
                        doItToday: true,
                        labelCode: 'TASK_CREATE_FORM_DOING_TODAY',
                    },
                    {
                        key: 'DO_NOT_TODAY',
                        doItToday: false,
                        labelCode: 'TASK_CREATE_FORM_DO_NOT_TODAY',
                    },
                ],
                finishToday: [
                    {
                        key: 'FINISH_TODAY',
                        finishItToday: true,
                        labelCode: 'TASK_CREATE_FORM_FINISH_TODAY',
                    },
                    {
                        key: 'NOT_FINISH_TODAY',
                        finishItToday: false,
                        labelCode: 'TASK_CREATE_FORM_NOT_FINISH_TODAY',
                    },
                ],
                creatorId: uniq(
                    this.filteredTasks.map((task) => task.creatorId)
                )
                    .filter((o: any) => {
                        return (
                            o &&
                            Object.prototype.hasOwnProperty.call(
                                userStore().allUserByIds,
                                o
                            )
                        );
                    })
                    .map((o) => {
                        return {
                            id: o,
                            selected: false,
                        };
                    }),
                taskLife: [
                    ...arrayOrderBy(
                        uniq(
                            this.filteredTasks.map((task) => task.taskLife || 0)
                        ).map((o: any) => {
                            return {
                                id: o,
                                selected: false,
                                index: taskStates[o]?.index,
                            };
                        }),
                        ['index'],
                        ['asc']
                    ),
                ],
            };
        },
        resetFilterDataOrigin(key) {
            switch (key) {
                case 'scheduleTime':
                case 'creationTime':
                case 'lastModificationTime':
                    {
                        this.filterDataByKey[key] = {
                            start: null,
                            end: null,
                        };
                    }
                    break;
                case 'domainId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const listProject = o.projectId.map((u) => {
                                u.selectedId = false;
                                return u;
                            });
                            o.projectId = listProject;
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
                case 'projectId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const listSubProject = o.subProjectId.map((u) => {
                                u.selectedId = false;
                                return u;
                            });
                            o.subProjectId = listSubProject;
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
                default:
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
            }
        },

        onFilterTaskOnColumn(options?) {
            const filterData = cloneDeep(this.filterDataByKey);
            // console.log('File: task-list-store.ts - L: 943 - ', filterData);
            let filteredAllTaskList = cloneDeep(this.taskAllList) || [];

            if (filterData?.keySearch) filterData.subTasks = false;

            if (this.isGroupSubTasks) {
                if (filterData?.subDomain) {
                    filteredAllTaskList = [...filteredAllTaskList];
                } else if (this.filterPayload?.domainId) {
                    filteredAllTaskList = filteredAllTaskList
                        .filter((t) => !t?.projectId && !t?.subprojectId)
                        ?.map((t) => {
                            return {
                                ...t,
                                subTasks: t?.subTasks?.filter(
                                    (t) => !t?.projectId && !t?.subprojectId
                                ),
                            };
                        });
                } else if (this.filterPayload?.projectId) {
                    filteredAllTaskList = filteredAllTaskList
                        .filter((t) => !t?.subprojectId)
                        ?.map((t) => {
                            return {
                                ...t,
                                subTasks: t?.subTasks?.filter(
                                    (t) => !t?.subprojectId
                                ),
                            };
                        });
                }

                const dataResult = this.onGroupSubTasks(filteredAllTaskList);
                filteredAllTaskList = dataResult?.allTaskList;

                filteredAllTaskList.forEach((t) => {
                    taskStore().updateAllTaskByIds(t.id, t);
                });
            }

            if (filterData) {
                Object.entries(filterData).forEach(([key, value]: any) => {
                    switch (key) {
                        case 'scheduleTime':
                        case 'creationTime':
                        case 'lastModificationTime':
                            {
                                const filterType:
                                    | 'TODAY'
                                    | 'OTHER'
                                    | 'NOTHING'
                                    | null = value?.filterType;
                                if (!filterType) return;
                                if (filterType === 'NOTHING') {
                                    filteredAllTaskList = [
                                        ...(filteredAllTaskList?.filter((t) => {
                                            return !t[key];
                                        }) || []),
                                    ];
                                    return;
                                }
                                if (filterType === 'TODAY') {
                                    filteredAllTaskList = [
                                        ...(filteredAllTaskList?.filter((t) => {
                                            return dayjs(t[key]).isSame(
                                                Date.now(),
                                                'date'
                                            );
                                        }) || []),
                                    ];
                                    return;
                                }
                                if (!value?.start && !value?.end) return;

                                if (value?.start) {
                                    filteredAllTaskList = [
                                        ...(filteredAllTaskList?.filter((t) => {
                                            return (
                                                dayjs(t[key]).isSame(
                                                    value?.start,
                                                    'date'
                                                ) ||
                                                dayjs(t[key]).isAfter(
                                                    value?.start,
                                                    'date'
                                                )
                                            );
                                        }) || []),
                                    ];
                                }
                                if (value?.end) {
                                    filteredAllTaskList = [
                                        ...(filteredAllTaskList?.filter((t) => {
                                            return (
                                                dayjs(t[key]).isSame(
                                                    value?.end,
                                                    'date'
                                                ) ||
                                                dayjs(t[key]).isBefore(
                                                    value?.end,
                                                    'date'
                                                )
                                            );
                                        }) || []),
                                    ];
                                }
                            }
                            break;
                        case 'domainId':
                            {
                                const selectedFilter = (value as any).filter(
                                    (o) =>
                                        o.selected &&
                                        o.projectId.some((e) => e.selectedId)
                                );
                                console.log(
                                    'File: task-list-store.ts - L: 1023 - ',
                                    selectedFilter
                                );
                                if (selectedFilter.length == 0) return;

                                filteredAllTaskList = [
                                    ...(filteredAllTaskList?.filter((t) =>
                                        selectedFilter?.some(
                                            (f) =>
                                                f.id == t.domainId &&
                                                f.projectId.some(
                                                    (u) =>
                                                        u.projectId ==
                                                            t['projectId'] &&
                                                        u.selectedId
                                                )
                                        )
                                    ) || []),
                                ];
                            }
                            break;

                        case 'projectId':
                            {
                                const selectedFilter = (value as any).filter(
                                    (o) =>
                                        o.selected &&
                                        o.subProjectId.some((e) => e.selectedId)
                                );

                                if (
                                    !options?.disableShowAllWhenNotFound &&
                                    selectedFilter.length == 0
                                ) {
                                    return;
                                }

                                filteredAllTaskList = [
                                    ...(filteredAllTaskList?.filter((t) =>
                                        selectedFilter?.some(
                                            (f) =>
                                                f.id == t.projectId &&
                                                f.subProjectId.some(
                                                    (u) =>
                                                        u.subProjectId ==
                                                            t['subprojectId'] &&
                                                        u.selectedId
                                                )
                                        )
                                    ) || []),
                                ];
                            }
                            break;
                        case 'doToday':
                            {
                                const selectedFilter = (value as any).find(
                                    (o) => o.selected
                                );
                                if (!selectedFilter) return;

                                filteredAllTaskList = [
                                    ...(filteredAllTaskList?.filter(
                                        (t) =>
                                            t?.taskSchedule?.doItToday ==
                                            selectedFilter?.doItToday
                                    ) || []),
                                ];
                            }
                            break;
                        case 'finishToday':
                            {
                                const selectedFilter = (value as any).find(
                                    (o) => o.selected
                                );
                                if (!selectedFilter) return;

                                filteredAllTaskList = [
                                    ...(filteredAllTaskList?.filter(
                                        (t) =>
                                            t?.taskSchedule?.finishItToday ==
                                            selectedFilter?.finishItToday
                                    ) || []),
                                ];
                            }
                            break;
                        case 'keySearch':
                            {
                                const keySearch = value;
                                filteredAllTaskList = !keySearch
                                    ? [...filteredAllTaskList]
                                    : filteredAllTaskList.filter(
                                          (t) =>
                                              stringNomalize(
                                                  t.name?.toLowerCase()
                                              ).includes(
                                                  stringNomalize(
                                                      keySearch?.toLowerCase()
                                                  )
                                              ) ||
                                              stringNomalize(
                                                  t.code?.toLowerCase()
                                              ).includes(
                                                  stringNomalize(
                                                      keySearch?.toLowerCase()
                                                  )
                                              ) ||
                                              stringNomalize(
                                                  t.displayName?.toLowerCase()
                                              ).includes(
                                                  stringNomalize(
                                                      keySearch?.toLowerCase()
                                                  )
                                              )
                                      );
                            }
                            break;
                        case 'subTasks':
                            {
                                console.log(
                                    "🚀 Tictop ~ subTasks's value:",
                                    value
                                );

                                filteredAllTaskList = value
                                    ? filteredAllTaskList.filter(
                                          (t) => !t?.parentId
                                      )
                                    : [...filteredAllTaskList];
                            }
                            break;
                        case 'subDomain':
                            {
                                if (value) {
                                    filteredAllTaskList = [
                                        ...filteredAllTaskList,
                                    ];
                                } else if (this.filterPayload?.domainId) {
                                    filteredAllTaskList = filteredAllTaskList
                                        .filter(
                                            (t) =>
                                                !t?.projectId &&
                                                !t?.subprojectId
                                        )
                                        ?.map((t) => {
                                            return {
                                                ...t,
                                                subTasks: t?.subTasks?.filter(
                                                    (t) =>
                                                        !t?.projectId &&
                                                        !t?.subprojectId
                                                ),
                                            };
                                        });
                                } else if (this.filterPayload?.projectId) {
                                    filteredAllTaskList = filteredAllTaskList
                                        .filter((t) => !t?.subprojectId)
                                        ?.map((t) => {
                                            return {
                                                ...t,
                                                subTasks: t?.subTasks?.filter(
                                                    (t) => !t?.subprojectId
                                                ),
                                            };
                                        });
                                }

                                filteredAllTaskList.forEach((t) => {
                                    taskStore().updateAllTaskByIds(t.id, t);
                                });
                            }
                            break;

                        default:
                            {
                                const mapKey = mapKeyForFilter[key] || 'id';
                                const selectedFilter = (value as any).filter(
                                    (o) => o.selected
                                );
                                if (selectedFilter.length == 0) return;

                                filteredAllTaskList = [
                                    ...(filteredAllTaskList?.filter((t) =>
                                        selectedFilter?.some(
                                            (f) =>
                                                f[mapKey] == t[key] ||
                                                (!f[mapKey] && !t[key])
                                        )
                                    ) || []),
                                ];
                            }
                            break;
                    }
                });
            }

            this.filteredTasks = filteredAllTaskList.filter((o) => o.id);

            this.onSortFilteredTasks();
        },

        onSortFilteredTasks() {
            if (!this.filteredTasks || this.filteredTasks.length == 0) {
                this.taskIds = [];
            }

            if (this.sortList?.length > 0) {
                const iterate = [
                    ...this.sortList.map((o: any) => o.orderFunction),
                    ...sortDefaultTaskList.iterate,
                ];

                const order = [
                    ...this.sortList.map((o: any) => o.orderBy),
                    ...sortDefaultTaskList.order,
                ];

                this.filteredTasks = arrayOrderBy(
                    this.filteredTasks,
                    iterate,
                    order
                );
            } else {
                this.filteredTasks = sortTaskDefault(this.filteredTasks);
            }
            this.filteredTasks = orderBy(
                this.filteredTasks,
                'isNotBelongThis',
                'asc'
            );

            const pageSize = remoteConfigStore().getRemoteConfig(
                RemoteConfigKey.WebUserTaskPageSize
            );

            this.taskIds = [
                ...new Set(
                    this.filteredTasks.map((o) => parseInt(o.id?.toString()))
                ),
            ].slice(0, pageSize.asNumber() || 30);

            this.unCheckAll();
        },

        genSortDataList(sortObj) {
            this.sortList = genSortData(
                this.sortList,
                sortObj,
                enableSortMultiple
            );

            this.onSortFilteredTasks();
        },

        setDefault() {
            switch (this.activeTab) {
                case TaskListTab.MyList:
                    this.filteredTasks = cloneDeep(this.taskAllList);
                    break;
                case TaskListTab.MyAssign:
                    this.filteredTasks = cloneDeep(this.taskAllList);
                    break;
                case TaskListTab.MyRelated:
                    this.filteredTasks = cloneDeep(this.relatedTaskAllList);
                    break;

                default:
                    this.filteredTasks = cloneDeep(this.taskAllList);
                    break;
            }
            const pageSize = remoteConfigStore().getRemoteConfig(
                RemoteConfigKey.WebUserTaskPageSize
            );
            this.taskIds = this.filteredTasks
                .map((o) => o.id)
                .slice(0, pageSize.asNumber() || 30);

            this.setFilterDataOrigin();
            this.unCheckAll();
            this.initSortData();
        },
        onLoadMore() {
            this.isLoadMore = true;
            const currentLength = this.taskIds.length;
            if (currentLength < this.filteredTasks.length) {
                this.taskIds = this.filteredTasks
                    .map((o) => o.id)
                    .slice(0, currentLength + 10);
            }
            this.isLoadMore = false;
        },
        onSearchLocal(keySearch) {
            this.setFilterDataByKey('keySearch', keySearch);
            this.onFilterTaskOnColumn();
        },

        setDefaultInitData(tab: TaskListTab) {
            this.activeTab = tab;
            this.setFilterDataOrigin();
        },
        async onChangeTabAssign(tab: TaskListTab) {
            this.activeTab = tab;

            if (tab == TaskListTab.MyAssign) {
                this.filteredTasks = this.taskAllList;
                await this.onResetMyAssignedTaskTab();
            } else {
                this.filteredTasks = this.taskAllList;
                await this.onResetMyTaskTab();
            }
            switch (tab) {
                case TaskListTab.MyList:
                    this.filteredTasks = this.taskAllList;
                    await this.onResetMyTaskTab();
                    break;
                case TaskListTab.MyAssign:
                    this.filteredTasks = this.taskAllList;
                    await this.onResetMyAssignedTaskTab();
                    break;
                case TaskListTab.MyRelated:
                    this.filteredTasks = this.relatedTaskAllList;
                    await this.onResetMyRelatedTaskTab();
                    break;

                default:
                    this.filteredTasks = this.taskAllList;
                    await this.onResetMyTaskTab();
                    break;
            }

            console.log(
                '🚀 Tictop ~ onChangeTabAssign this.filteredTasks:',
                this.filteredTasks
            );
            this.setFilterDataOrigin();
            this.unCheckAll();
        },
        onResetSortAndFilter() {
            this.sortList = [];
        },
        onChangeTabActive(tab: TaskListTab) {
            this.activeTab = tab;

            this.onResetSortAndFilter();
        },
        async getTaskSummaryStatistics(
            sourceType: ETaskListModule,
            listOwnerId: number
        ) {
            const res = await this._taskService.getTaskSummaryStatistics(
                sourceType,
                listOwnerId
            );
            this.taskSummaryStatistics = res.result || {};
        },
        async getAllAssigneeTaskStatisticByUser(userId) {
            const res = await this._taskService.getTaskSummaryStatistics(
                ETaskListModule.I_ASSIGNED,
                userId
            );
            this.assignTaskSummaryStatistics = res['result'] || {};
        },
        async getAllApprovalTaskStatisticByUser(userId) {
            const res = await this._taskService.getTaskApprovalStatistic(
                userId
            );
            this.approvalTaskSummaryStatistics = res['result'] || {};
        },

        onChooseTask(taskId: number, isSelected: boolean) {
            if (isSelected) {
                this.selectedTaskIds = [
                    ...new Set(this.selectedTaskIds).add(taskId),
                ];
            } else {
                this.selectedTaskIds = this.selectedTaskIds?.filter(
                    (id) => id !== taskId
                );
            }
        },
        unCheckAll() {
            this.selectedTaskIds = [];
        },
        handleCheckAll() {
            this.selectedTaskIds = [...this.filteredTasks?.map((t) => t.id)];
        },

        async updateMultipleTasks(data: UpdateMultipleTaskPayload) {
            try {
                await this._taskService.updateMultipleTasks(data);
                this.unCheckAll();
            } catch (error) {
                console.log('🚀 Hyrin ~ updateMultipleTasks ~ error:', error);
            }
        },
    },
});
