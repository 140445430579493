import {
    getLocalStorage,
    setLocalStorage,
    StorageConstant,
} from './../../../hooks/storageHook';
import { computed, ref, watch } from 'vue';
import { AdminType } from '@/ui/common/constants/constant';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import userStore from '@/store/user';
import myProfileStore from '@/store/auth/my-profile';
import { translate } from '@/ui/plugins/i18n/myi18n';
import groupStore from '@/store/group';
import getUserInfo from '@/ui/helpers/user-helper';
import settingStore from '@/store/setting';

export default function changeAssignComposable(
    originalData,
    options?: { isFilterVisitor?: boolean; isWorkflow?: boolean }
) {
    const _userStore = userStore();
    const _settingStore = settingStore();

    const assignedUser = ref({} as any);
    const collaborators = ref<any[]>([]);
    const assignedGroup = ref({} as any);
    const originalFormData = ref({ ...originalData } as any);

    const assignableUsers = ref([] as any[]);
    const isVisitor = computed(() => _userStore.isVisitor);
    const myProfile = computed(() => myProfileStore().myProfile);

    const departmentManagementEnabled = computed(
        () => _settingStore._organizationSetting.departmentManagement.enabled
    );
    const myGroupByIds = computed(() => groupStore().myGroupByIds);

    const allAssignableUsers = computed(() =>
        _userStore.listShortInfosUser
            .filter(
                (user) =>
                    ((!isVisitor.value &&
                        user.adminType !== AdminType.Visitor) ||
                        user.userId == myProfile.value?.id) &&
                    (options?.isFilterVisitor
                        ? user?.adminType !== AdminType.Visitor
                        : true)
            )
            .map((e) => {
                e.favoriteId = e.userId;
                return {
                    ...e,
                };
            })
    );

    const errorMessage = computed(() => {
        if (!assignedGroup.value?.id && !assignedUser.value?.userId) {
            if (
                options?.isWorkflow &&
                (originalFormData.value?.groupId ||
                    originalFormData.value?.userId)
            )
                return '';

            return (
                translate('TASK_CREATE_FORM_MUST_BELONG_TO') ||
                'Task must belong to a group or a user'
            );
        }

        if (
            isVisitor.value &&
            !assignedGroup.value?.id &&
            assignedUser.value?.userId != myProfile.value?.id
        )
            return (
                translate('TASK_CREATE_FORM_VISITOR_CHANGE_ASSIGN_TO_USER') ||
                'You cannot assign task to other without a group'
            );

        return '';
    });

    const isSearchAll = ref(false);
    const isSearchOutsideGroup = ref(false);
    watch(
        () => originalData.value?.groupId,
        () => {
            originalFormData.value = originalData.value;
            updateAssignableUsers();
        }
    );

    const initData = (defaultData?) => {
        if (defaultData)
            originalFormData.value = {
                ...originalFormData.value,
                ...defaultData,
            };
        if (departmentManagementEnabled.value) {
            isSearchAll.value =
                getLocalStorage(StorageConstant.IS_SEARCH_ALL_ORG_MEMBERS) ==
                '1'
                    ? true
                    : false;
        }
        isSearchOutsideGroup.value =
            getLocalStorage(StorageConstant.IS_SEARCH_MEMBERS_OUTSIDE_GROUP) ==
            '1'
                ? true
                : false;
        const currentGroupId = originalFormData.value
            ? originalFormData.value.groupId
            : originalData.value?.groupId;

        assignedGroup.value = currentGroupId
            ? myGroupByIds.value[currentGroupId] || { isHidden: true }
            : {};
        const currentUserId = originalFormData.value
            ? originalFormData.value.userId
            : originalData.value?.userId;
        assignedUser.value = currentUserId ? getUserInfo(currentUserId) : {};

        updateAssignableUsers();
    };

    const updateAssignableUsers = () => {
        // const collaboratorByIds = originalData.value?.collaborators?.reduce(
        //     (oldObj, currentItem) => {
        //         return {
        //             ...oldObj,
        //             [currentItem?.id]: currentItem,
        //         };
        //     },
        //     {}
        // );
        // if (assignedGroup.value?.id) {
        //     assignableUsers.value = assignedGroup.value?.groupUsers
        //         ?.filter((u) => {
        //             return options?.isFilterVisitor
        //                 ? u?.adminType !== AdminType.Visitor
        //                 : true;
        //         })
        //         ?.map((user: any) => {
        //             return {
        //                 ...getUserInfo(user.userId),
        //                 ...(collaboratorByIds
        //                     ? collaboratorByIds[user?.userId]
        //                     : {}),
        //                 isSelected:
        //                     collaboratorByIds && collaboratorByIds[user?.userId]
        //                         ? true
        //                         : false,
        //             };
        //         });
        //     return;
        // }
        if (assignedGroup.value?.id) toggleSearchOutsideGroup(false);
        else
            toggleSearchAll(
                !departmentManagementEnabled.value ? true : isSearchAll.value
            );
    };

    const handleChangeGroup = (group) => {
        if (!group || !group.id) return;
        assignedGroup.value = group;
        updateAssignableUsers();

        // If current user is not in group, reset it
        if (
            group.groupUsers?.length > 0 &&
            !group.groupUsers?.some(
                (o) => o.userId == assignedUser.value.userId
            )
        )
            assignedUser.value = {};
    };
    const handleRemoveGroup = () => {
        assignedGroup.value = {};
        if (assignedUser.value?.adminType == AdminType.Visitor) {
            assignedUser.value = {};
        }
        assignableUsers.value = cloneDeep(allAssignableUsers.value);
    };
    const handleChangeUser = (user) => {
        assignedUser.value = user;
    };
    const handleRemoveUser = () => {
        assignedUser.value = {};
    };

    const toggleSearchAll = (value) => {
        // if (!departmentManagementEnabled.value) return;

        isSearchAll.value = value;
        setLocalStorage(
            StorageConstant.IS_SEARCH_ALL_ORG_MEMBERS,
            value ? '1' : '0'
        );

        const collaboratorByIds = originalData.value?.collaborators?.reduce(
            (oldObj, currentItem) => {
                return {
                    ...oldObj,
                    [currentItem?.id]: currentItem,
                };
            },
            {}
        );

        if (value) {
            assignableUsers.value = allAssignableUsers.value?.map((user) => {
                return {
                    ...user,
                    ...(collaboratorByIds
                        ? collaboratorByIds[user?.userId]
                        : {}),
                    isSelected:
                        collaboratorByIds && collaboratorByIds[user?.userId]
                            ? true
                            : false,
                };
            });
        } else {
            const myId = myProfileStore().myProfile?.id;
            const myDepartmentIds =
                _userStore.allUserByIds[myId]?.departmentIds || [];
            assignableUsers.value = allAssignableUsers.value
                ?.filter(
                    (user) =>
                        user?.departmentIds?.filter((dId) =>
                            myDepartmentIds.includes(dId)
                        )?.length > 0
                )
                ?.map((user) => {
                    return {
                        ...user,
                        ...(collaboratorByIds
                            ? collaboratorByIds[user?.userId]
                            : {}),
                        isSelected:
                            collaboratorByIds && collaboratorByIds[user?.userId]
                                ? true
                                : false,
                    };
                });
        }
    };
    const toggleSearchOutsideGroup = (isOutside) => {
        // if (!departmentManagementEnabled.value) return;

        isSearchOutsideGroup.value = isOutside;
        setLocalStorage(
            StorageConstant.IS_SEARCH_ALL_ORG_MEMBERS,
            isOutside ? '1' : '0'
        );

        const collaboratorByIds = originalData.value?.collaborators?.reduce(
            (oldObj, currentItem) => {
                return {
                    ...oldObj,
                    [currentItem?.id]: currentItem,
                };
            },
            {}
        );

        const groupUsers = assignedGroup.value?.groupUsers || [];
        const filteredUsers = isOutside
            ? allAssignableUsers.value?.filter((user) => {
                  return !groupUsers.some((group) => group?.userId == user?.id);
              })
            : assignedGroup.value?.groupUsers?.map((user) => {
                  return {
                      ...getUserInfo(user.userId),
                      ...(collaboratorByIds
                          ? collaboratorByIds[user?.userId]
                          : {}),
                      isSelected:
                          collaboratorByIds && collaboratorByIds[user?.userId]
                              ? true
                              : false,
                  };
              });

        assignableUsers.value = filteredUsers?.map((user) => {
            return {
                ...user,
                ...(collaboratorByIds ? collaboratorByIds[user?.userId] : {}),
                isSelected:
                    collaboratorByIds && collaboratorByIds[user?.userId]
                        ? true
                        : false,
            };
        });
    };

    initData();

    return {
        assignedGroup,
        assignedUser,
        errorMessage,
        assignableUsers,
        handleChangeGroup,
        handleRemoveGroup,
        handleChangeUser,
        handleRemoveUser,
        initData,
        isSearchAll,
        toggleSearchAll,
        collaborators,
        isSearchOutsideGroup,
        toggleSearchOutsideGroup,
    };
}
