<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { arrToObj } from '@/ui/helpers/utils';
import {
  TaskSharingPermission,
  TaskSharingStatus,
} from '@/domain/enums/taskEnum';
import { chain, first } from 'lodash';
import TaskSharingService from '@/application/services/task/TaskSharingService';
import TaskSharedInfo from '@/ui/modules/task/sharing/modal-task-sharing/TaskSharedInfo.vue';

const props = defineProps<{
  task: any;
}>();

const emit = defineEmits(['onClose']);

const sharingPermissions = TaskSharingService.sharingPermissions;

const isFetching = ref(true);
const isEditingMode = ref(false);
const isSaving = ref();

const taskSharingInfo = ref<any>();
const sharingPermissionGroup = ref(TaskSharingPermission.TASK_VIEW_ONLY);
const sharingPermissionById = ref<any>({});

onMounted(async () => {
  try {
    await _getTaskSharingInfo();
  } catch (e) {
    console.log(e);
  }

  isFetching.value = false;
});

const onSharingStatusChange = (updatedData) => {
  taskSharingInfo.value = {
    ...taskSharingInfo.value,
    ...updatedData,
  };
};

const onSharingModifyClick = () => {
  _processSharingInfo();

  isEditingMode.value = true;
};

const onCancelClick = () => {
  if (taskSharingInfo.value) {
    isEditingMode.value = false;
  } else {
    emit('onClose');
  }
};

const onSaveClick = async () => {
  isSaving.value = true;

  try {
    const permissions: string[] = [];

    const selectedGroup = chain(sharingPermissions)
      .filter((group) => sharingPermissionGroup.value === group?.id)
      .head()
      .value();

    if (selectedGroup) {
      permissions.push(selectedGroup.id);

      selectedGroup.children
        ?.filter((permission) => sharingPermissionById.value[permission?.id])
        ?.forEach((permission) => permissions.push(permission.id));
    }

    const saveData: any = {
      taskId: props.task?.id,
      status: TaskSharingStatus.Sharing,
      permissions,
    };

    if (taskSharingInfo.value?.sharedId) {
      saveData.sharedId = taskSharingInfo.value?.sharedId;
      saveData.status = taskSharingInfo.value?.status;
    }

    taskSharingInfo.value = await TaskSharingService.saveTaskShared(saveData);

    isEditingMode.value = false;
  } catch (e) {
    console.log(e);
  }

  isSaving.value = false;
};

const _getTaskSharingInfo = async () => {
  if (!props.task?.id) return;

  const sharedItems = await TaskSharingService.getTaskSharedByTaskId(
    props.task?.id
  );

  taskSharingInfo.value = first(sharedItems);

  _processSharingInfo();
};

const _processSharingInfo = () => {
  sharingPermissionById.value = arrToObj(
    taskSharingInfo.value?.permissions || [],
    null,
    () => true
  );

  if (sharingPermissionById.value[TaskSharingPermission.TASK_MODIFY_ALLOWED]) {
    sharingPermissionGroup.value = TaskSharingPermission.TASK_MODIFY_ALLOWED;
  } else if (
    sharingPermissionById.value[TaskSharingPermission.TASK_ADD_ALLOWED]
  ) {
    sharingPermissionGroup.value = TaskSharingPermission.TASK_ADD_ALLOWED;
  } else {
    sharingPermissionGroup.value = TaskSharingPermission.TASK_VIEW_ONLY;
  }
};
</script>

<template>
  <SynModal
    container-class="w-11/12 max-w-lg"
    container-style=""
    style-body="px-4 pb-4"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>
        {{ $t('TASK_SHARING_CODE_', { code: formatTaskCode(task?.code) }) }}
      </div>
    </template>

    <template #body>
      <div v-if="!isFetching">
        <TaskSharedInfo
          v-if="taskSharingInfo && !isEditingMode"
          :shared-info="taskSharingInfo"
          @on-modify-click="onSharingModifyClick"
          @on-status-change="onSharingStatusChange"
          @on-deleted="$emit('onClose')"
        />

        <div v-else>
          <div class="mb-3">{{ $t('TASK_SHARING_PERMISSIONS') }}</div>
          <div
            v-for="group of sharingPermissions"
            :key="group?.id"
            class="mb-4"
          >
            <div class="flex">
              <VigRadio
                v-model="sharingPermissionGroup"
                name="rd-share-permission"
                :value="group?.id"
                :disabled="group.disabled"
              >
                <span> {{ $t(group?.text) || group?.text }}</span>
              </VigRadio>
            </div>
            <div
              v-if="
                sharingPermissionGroup === group?.id && group?.children?.length
              "
              class="pl-5 mt-2"
            >
              <div
                v-for="permission in group?.children"
                :key="permission?.id"
                class="mb-1"
              >
                <VigCheckbox
                  v-model="sharingPermissionById[permission?.id]"
                  :label="$t(permission?.text)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <VigLoading v-else class="h-32" />
    </template>

    <template v-if="!isFetching" #footer>
      <div
        v-if="taskSharingInfo && !isEditingMode"
        class="px-4 py-3 flex items-center justify-end gap-3"
      >
        <VigButton
          v-if="1 !== 1"
          outline
          color="blue"
          padding="px-3 py-1"
          @click="onSharingModifyClick"
        >
          <SynIcon name="Edit" />
          <span class="text-sm">Modify</span>
        </VigButton>

        <VigButton light color="gray" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CLOSE') }}
        </VigButton>
      </div>
      <div v-else class="px-4 py-2 flex items-center justify-end gap-3">
        <VigButton
          light
          color="gray"
          label="COMMON_LABEL_CANCEL"
          @click="onCancelClick"
        />
        <VigButton
          :processing="isSaving"
          :label="isEditingMode ? 'COMMON_LABEL_SAVE' : 'TASK_SHARING'"
          @click="onSaveClick"
        />
      </div>
    </template>
  </SynModal>
</template>
