<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 48 48"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <circle
        cx="56"
        cy="260"
        r="5"
        transform="translate(-46 -242)"
        data-original="#000000"
      />
      <circle
        cx="56"
        cy="260"
        r="5"
        transform="translate(-18 -242)"
        data-original="#000000"
      />
      <circle
        cx="56"
        cy="260"
        r="5"
        transform="translate(-32 -246)"
        data-original="#000000"
      />
      <path
        d="M33 28v10a.997.997 0 0 1-1 1H16a.997.997 0 0 1-1-1V28a7 7 0 0 1 7-7h4a7 7 0 0 1 7 7zM13.441 25.211A5.007 5.007 0 0 0 12 25H8a5 5 0 0 0-5 5v8a1 1 0 0 0 1 1h9.172A2.986 2.986 0 0 1 13 38V28c0-.974.155-1.911.441-2.789zM34.828 39H44a1 1 0 0 0 1-1v-8a5 5 0 0 0-5-5h-4c-.501 0-.984.074-1.441.211.286.878.441 1.815.441 2.789v10c0 .344-.059.682-.172 1z"
        data-original="#000000"
      />
    </g>
  </svg>
</template>
