import ShapeEntity from './ShapeEntity';

export default class ShapeTaskEntity extends ShapeEntity {
    constructor(data) {
        super(data);
        this.sourceId =
            typeof data?.sourceId == 'string'
                ? parseInt(data?.sourceId)
                : data?.sourceId;
    }
}
