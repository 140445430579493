<script setup lang="ts">
import { computed } from 'vue';
import groupStore from '@/store/group';
import MyGroups from '@/ui/components/group/MyGroups.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const _groupStore = groupStore();
const allMyGroups = computed(() =>
  arrayOrderBy(
    _groupStore.allMyGroups.filter((e) => e.isMember) || [],
    ['name'],
    ['asc']
  )
);
</script>
<template>
  <div class="flex flex-1 flex-col h-full">
    <div
      class="
        flex
        bg-current-50
        py-2
        pl-4
        rounded-t-md
        items-center
        justify-between
        space-x-2
      "
    >
      <p class="font-semibold text-current-500 py-1">
        {{ $t('COMMON_MODULE_GROUP_MANAGEMENT') }}
      </p>
    </div>
    <div
      class="
        h-full
        border
        bg-white
        py-2
        px-2
        border-gray-100
        text-current-600
        overflow-y-auto
        small-scrollbar
        justify-between
      "
    >
      <MyGroups :groups="allMyGroups" />
    </div>
  </div>
</template>
