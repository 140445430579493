<script setup lang="ts">
import { ref, computed } from 'vue';
// import { v4 as uuidv4 } from 'uuid';
import organizationStore from '@/store/organization';
import myProfileStore from '@/store/auth/my-profile';
import {
  EOrganizationModel,
  IOrganizationInfoPayload,
} from '@/application/types/organization/organization.type';
import RegisterCompletePersonalDetail from '@/ui/modules/organization/setup/RegisterCompletePersonalDetail.vue';
import appStore from '@/store/app';

defineProps<{
  doNotNeedToCheckPersonal: boolean;
  typeKey: 'workspace_type_select' | 'workspace_type_select_register';
}>();
const emit = defineEmits(['onCancel', 'onFinish']);
const _organizationStore = organizationStore();

const isCreateFetching = ref(false);
const defaultNameByLang = {
  en: 'My workspace',
  fr: 'Espace de travail personnel',
  vi: 'Không gian cá nhân',
};

const infoPayload = ref<IOrganizationInfoPayload>({
  name: '',
  logo: '',
  model: EOrganizationModel.PERSONAL,
  type: '',
  size: '',
  address: '',
  addressDetail: '',
  phone: '',
  websiteUrl: '',
  taxCode: '',
  subType: '',
  personalType: undefined,
  knowAppFrom: undefined,
});
// const currentOrganizationInfo = computed<any>(
//   () => _organizationStore.organizationInfo
// );
// const isShowFullSize = ref<boolean>(false);

const appLanguage = computed(() => appStore().language);
const onSubmit = async () => {
  const currentLanguage = appLanguage.value || 'en';
  isCreateFetching.value = true;
  const data = {
    ...infoPayload.value,
    name: defaultNameByLang[currentLanguage],
    category: infoPayload.value?.subCategory
      ? infoPayload.value?.subCategory
      : infoPayload.value?.category,
    type: infoPayload.value?.type || 'OTHER',
  };

  _organizationStore.updateDecideTime(14);
  await _organizationStore.updateAndFinishSetupOrganization(data);
  myProfileStore().fetchCurrentLoginUser();
  isCreateFetching.value = false;
  emit('onFinish', data);
};
</script>

<template>
  <teleport to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
        z-50
      "
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div
        class="
          p-8
          mx-auto
          border-current
          flex
          items-center
          flex-col
          gap-8
          justify-between
          bg-white
          rounded-2xl
          relative
        "
        style="max-width: 50rem"
      >
        <div class="flex-1 flex flex-col space-y-6 items-center text-gray-800">
          <RegisterCompletePersonalDetail
            v-model:knowAppFrom="infoPayload.knowAppFrom"
            v-model:address="infoPayload.address"
            is-hidden-select-position
          />
        </div>

        <div class="w-full flex justify-between items-center space-x-2">
          <div class="flex items-center gap-4 relative"></div>
          <div class="flex-center gap-2">
            <AtomButton
              :label="$t('COMMON_LABEL_FINISH')"
              :is-loading="isCreateFetching"
              @click="onSubmit"
            />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
