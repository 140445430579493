<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M426,0H142.15c-3.979,0-7.794,1.581-10.606,4.394l-56.15,56.15C72.58,63.357,71,67.172,71,71.15V497  c0,8.284,6.716,15,15,15h340c8.284,0,15-6.716,15-15V15C441,6.716,434.284,0,426,0z"
        fill="#38bfc5"
        data-original="#ffe470"
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M426,0H256v512h170c8.284,0,15-6.716,15-15V15C441,6.716,434.284,0,426,0z"
        fill="#00b0b7"
        data-original="#ffd400"
        class=""
      />
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          style=""
          d="M366,440H146c-8.284,0-15-6.716-15-15s6.716-15,15-15h220c8.284,0,15,6.716,15,15   S374.284,440,366,440z"
          fill="#ecffff"
          data-original="#5a69e6"
          class=""
        />
        <path
          style=""
          d="M366,380H146c-8.284,0-15-6.716-15-15s6.716-15,15-15h220c8.284,0,15,6.716,15,15   S374.284,380,366,380z"
          fill="#ecffff"
          data-original="#5a69e6"
          class=""
        />
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          style=""
          d="M366,410H256v30h110c8.284,0,15-6.716,15-15S374.284,410,366,410z"
          fill="#fdf9ff"
          data-original="#3a4de2"
          class=""
        />
        <path
          style=""
          d="M366,350H256v30h110c8.284,0,15-6.716,15-15S374.284,350,366,350z"
          fill="#fdf9ff"
          data-original="#3a4de2"
          class=""
        />
        <path
          style=""
          d="M366,320h-80c-8.284,0-15-6.716-15-15s6.716-15,15-15h80c8.284,0,15,6.716,15,15   S374.284,320,366,320z"
          fill="#fdf9ff"
          data-original="#3a4de2"
          class=""
        />
      </g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M249.798,240.77c-26.124-0.871-47.458-14.368-47.458-28.301c0-7.401,6.531-18.287,14.804-18.287  c9.144,0,16.545,12.844,32.654,15.674V174.59c-20.028-7.62-43.539-16.98-43.539-44.846c0-27.647,20.463-40.927,43.539-44.193v-6.095  c0-3.048,3.483-5.878,8.272-5.878c4.137,0,8.272,2.83,8.272,5.878v5.442c13.497,0.436,38.968,3.918,38.968,18.939  c0,5.878-3.918,17.851-13.497,17.851c-7.184,0-11.32-6.966-25.471-8.055v31.784c19.811,7.402,42.887,17.634,42.887,47.023  c0,26.994-17.416,43.322-42.887,47.458v6.313c0,3.048-4.136,5.878-8.272,5.878c-4.789,0-8.272-2.83-8.272-5.878L249.798,240.77  L249.798,240.77z M251.975,140.193v-25.906c-9.796,1.959-13.933,6.966-13.933,12.191  C238.042,132.792,243.702,136.71,251.975,140.193z M264.166,180.467v29.171c7.401-1.741,13.279-5.877,13.279-13.715  C277.445,188.74,272.003,184.168,264.166,180.467z"
        fill="#ecffff"
        data-original="#5a69e6"
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M266.343,145.418v-31.784c14.15,1.088,18.287,8.055,25.471,8.055  c9.579,0,13.497-11.973,13.497-17.851c0-15.021-25.471-18.504-38.968-18.939v-5.442c0-3.048-4.136-5.878-8.272-5.878  c-0.803,0-1.566,0.086-2.286,0.233v178.046c0.72,0.147,1.482,0.233,2.286,0.233c4.137,0,8.272-2.83,8.272-5.878V239.9  c25.471-4.136,42.887-20.464,42.887-47.458C309.229,163.052,286.153,152.82,266.343,145.418z M264.166,209.639v-29.171  c7.837,3.701,13.279,8.272,13.279,15.457C277.445,203.761,271.567,207.897,264.166,209.639z"
        fill="#fdf9ff"
        data-original="#3a4de2"
        class=""
      />
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
  <svg
    v-else
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M366,350H146c-8.284,0-15,6.716-15,15s6.716,15,15,15h220c8.284,0,15-6.716,15-15S374.284,350,366,350z"
          />
          <path
            d="M366,410H146c-8.284,0-15,6.716-15,15s6.716,15,15,15h220c8.284,0,15-6.716,15-15S374.284,410,366,410z"
          />
          <path
            d="M271,305c0,8.284,6.716,15,15,15h80c8.284,0,15-6.716,15-15s-6.716-15-15-15h-80C277.716,290,271,296.716,271,305z"
          />
          <path
            d="M426,0H142.15c-3.979,0-7.794,1.581-10.606,4.394l-56.15,56.15C72.58,63.357,71,67.172,71,71.15V497
				c0,8.284,6.716,15,15,15h340c8.284,0,15-6.716,15-15V15C441,6.716,434.284,0,426,0z M411,482H101V77.364L148.363,30H411V482z"
          />
          <path
            d="M217.144,194.182c-8.272,0-14.804,10.885-14.804,18.287c0,13.933,21.334,27.43,47.458,28.301v5.442
				c0,3.048,3.483,5.878,8.272,5.878c4.137,0,8.272-2.83,8.272-5.878v-6.313c25.471-4.136,42.887-20.464,42.887-47.458
				c0-29.389-23.076-39.621-42.887-47.023v-31.784c14.15,1.088,18.287,8.055,25.471,8.055c9.579,0,13.497-11.973,13.497-17.851
				c0-15.021-25.471-18.504-38.968-18.939v-5.442c0-3.048-4.136-5.878-8.272-5.878c-4.789,0-8.272,2.83-8.272,5.878v6.095
				c-23.076,3.266-43.539,16.545-43.539,44.193c0,27.865,23.511,37.226,43.539,44.846v35.267
				C233.688,207.026,226.287,194.182,217.144,194.182z M264.166,180.467c7.837,3.701,13.279,8.272,13.279,15.457
				c0,7.837-5.878,11.974-13.279,13.715V180.467z M238.042,126.479c0-5.225,4.137-10.232,13.933-12.191v25.906
				C243.702,136.71,238.042,132.792,238.042,126.479z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
