<script setup lang="ts">
import { computed } from 'vue';
import { AdminType } from '@/ui/common/constants/constant';
import myProfileStore from '@/store/auth/my-profile';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import dashboardStore from '@/store/dashboard';
import userByIdComposable from '@/ui/components/user/user-by-id.composable';
// *** PROPS, EMITS ***
const props = withDefaults(
  defineProps<{
    userId: string | number;
    user?: any;
    userAvatar?: string;
    isHiddenAvatar?: boolean;
    isHiddenName?: boolean;
    isPositionTag?: boolean;
    avatarClass?: string;
    textClass?: string;
    shape?: string;
    placeholder?: string;
    isShowFirstName?: boolean;
    isShowDepartment?: boolean;
    isShowWorkingStatus?: boolean;
  }>(),
  {
    avatarClass: 'w-6 h-6',
    shape: '',
    placeholder: '',
    isShowDepartment: false,
    isShowWorkingStatus: false,
    user: undefined,
  }
);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***
const _dashboardStore = dashboardStore();

const { currentUserInfo, currentUserDepartmentId } = userByIdComposable(
  computed(() => {
    return {
      userId: props.userId,
      user: props.user,
    };
  })
);

// *** REFS ***

// *** COMPUTED ***

const myProfile = computed(() => myProfileStore().myProfile);

const userWorkingStatus = computed(() => _dashboardStore.userWorkingStatus);

const statusColor = computed(() => {
  let status = userWorkingStatus.value[props.userId]?.status || 'offline';

  if (status == 'offline') {
    return 'bg-gray-300';
  }
  if (status == 'absent') {
    return 'bg-orange-400';
  }
  return 'bg-green-400';
});

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div class="flex items-center space-x-2 font-sans">
    <span
      v-if="!isHiddenAvatar"
      class="relative flex-center"
      :class="avatarClass"
    >
      <SynAvatar
        :src="
          (currentUserInfo?.avatar || userAvatar)?.replace(
            'original',
            'mini'
          ) || ''
        "
        :custom-class="avatarClass"
        :name="currentUserInfo?.name || ''"
        :is-deactive="currentUserInfo?.isDeactive"
        :shape="shape ? shape : 'circle'"
      />
      <div
        v-if="isShowWorkingStatus && !currentUserInfo?.isDeactive"
        class="
          absolute
          bottom-0
          right-0
          w-1/4
          h-1/4
          my-1
          border border-white
          rounded-full
        "
        :class="statusColor"
      ></div>
    </span>
    <div
      v-if="!isHiddenName"
      class="flex-1 flex flex-col items-start text-underline"
      :class="textClass"
    >
      <slot name="descriptionName"></slot>
      <span
        class="flex-center space-x-2"
        :class="
          !currentUserInfo?.name && !currentUserInfo?.firstName
            ? 'text-gray-500 italic'
            : ''
        "
      >
        <span v-if="!isShowFirstName" class="text-overflow-hidden-line">
          {{ currentUserInfo?.name || $t('COMMON_LABEL_DELETED_USER') }}
        </span>
        <span v-else class="text-overflow-hidden-line">
          {{
            currentUserInfo?.firstName ||
            currentUserInfo?.name ||
            $t('COMMON_LABEL_DELETED_USER')
          }}
        </span>

        <template v-if="!isPositionTag">
          <SynTag
            v-if="currentUserInfo?.id == myProfile?.id"
            size="tiny"
            custom-class="px-1 border border-current-100 bg-white text-gray-800"
          >
            <span class="italic">
              {{ $t('COMMON_LABEL_ME') || 'Me' }}
            </span>
          </SynTag>
          <SynTag
            v-if="currentUserInfo?.adminType == AdminType.Visitor"
            size="tiny"
            custom-class="px-1 border border-current-100 bg-white text-gray-800"
          >
            <span class="italic">
              {{ $t('COMMON_LABEL_VISITOR') || 'Visitor' }}
            </span>
          </SynTag>
        </template>
      </span>
      <div
        v-if="isShowDepartment && currentUserDepartmentId"
        class="flex-center space-x-1"
      >
        <DepartmentById
          :department-id="currentUserDepartmentId"
          is-hidden-avatar
          class="text-xs text-gray-500"
        />
      </div>
      <slot name="subName"></slot>
    </div>
  </div>
</template>
