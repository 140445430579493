<template>
  <svg
    v-if="!isActive"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="calendar-Bold">
      <path
        d="m362.667 64a21.334 21.334 0 0 0 -42.667 0h-128a21.334 21.334 0 0 0 -42.667 0 106.792 106.792 0 0 0 -106.666 106.667v192a106.792 106.792 0 0 0 106.666 106.666h213.334a106.792 106.792 0 0 0 106.666-106.666v-192a106.792 106.792 0 0 0 -106.666-106.667zm-213.334 42.667v21.333a21.334 21.334 0 0 0 42.667 0v-21.333h128v21.333a21.334 21.334 0 0 0 42.667 0v-21.333a64.07 64.07 0 0 1 64 64h-341.334a64.07 64.07 0 0 1 64-64zm213.334 320h-213.334a64.071 64.071 0 0 1 -64-64v-149.334h341.334v149.334a64.071 64.071 0 0 1 -64 64zm0-149.334a21.334 21.334 0 1 1 -21.334-21.333 21.334 21.334 0 0 1 21.334 21.333zm-85.334 0a21.333 21.333 0 1 1 -21.333-21.333 21.333 21.333 0 0 1 21.333 21.333zm-85.333 0a21.334 21.334 0 1 1 -21.333-21.333 21.334 21.334 0 0 1 21.333 21.333zm170.667 85.334a21.334 21.334 0 1 1 -21.334-21.334 21.334 21.334 0 0 1 21.334 21.334zm-85.334 0a21.333 21.333 0 1 1 -21.333-21.334 21.333 21.333 0 0 1 21.333 21.334zm-85.333 0a21.334 21.334 0 1 1 -21.333-21.334 21.334 21.334 0 0 1 21.333 21.334z"
        data-name="calendar-Bold"
      />
    </g>
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <g id="calendar-Filled">
      <path
        id="calendar-Filled-2"
        data-name="calendar-Filled"
        d="M458.667,169.385c0-53.974-42.667-98.135-96-101.119V64A21.334,21.334,0,0,0,320,64v3.625H192V64a21.334,21.334,0,0,0-42.667,0v4.266c-53.333,2.984-96,47.145-96,101.119v40.323a6.215,6.215,0,0,0,.427,1.917,4.031,4.031,0,0,0-.427,1.708V362.667a96.145,96.145,0,0,0,96,96H362.667a96.145,96.145,0,0,0,96-96V213.333a4.031,4.031,0,0,0-.427-1.708,6.215,6.215,0,0,0,.427-1.917ZM170.667,384A21.334,21.334,0,1,1,192,362.667,21.4,21.4,0,0,1,170.667,384Zm0-85.333A21.334,21.334,0,1,1,192,277.333,21.4,21.4,0,0,1,170.667,298.667ZM256,384a21.334,21.334,0,1,1,21.333-21.333A21.4,21.4,0,0,1,256,384Zm0-85.333a21.334,21.334,0,1,1,21.333-21.334A21.4,21.4,0,0,1,256,298.667ZM341.333,384a21.334,21.334,0,1,1,21.334-21.333A21.4,21.4,0,0,1,341.333,384Zm0-85.333a21.334,21.334,0,1,1,21.334-21.334A21.4,21.4,0,0,1,341.333,298.667Zm74.667-96H96V169.385a59.006,59.006,0,0,1,54.186-58.666A21.02,21.02,0,0,0,170.667,128c10.453,0,18.773-7.677,20.692-17.708H320.641C322.56,120.323,330.88,128,341.333,128a21.02,21.02,0,0,0,20.481-17.281C392.107,113.281,416,138.667,416,169.385Z"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
