<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import ActionInBoard from '@/ui/modules/task/board/board-container/ActionInBoard.vue';
import TaskAttachmentDetail from '@/ui/modules/task/detail/TaskAttachmentDetail.vue';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';
import TaskBoardCardLoading from '@/ui/modules/task/board/task-card/TaskBoardCardLoading.vue';

import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import TaskFilterCustomTag from '@/ui/modules/task/components/tabs/TaskFilterCustomTag.vue';
import localConfigsStore from '@/store/local-configs';
import useTaskBoardCard from './useTaskBoardCard';
import { useElementVisibility } from '@vueuse/core';
import { FilterQuery } from '@/domain/entities/task/FilterTask';
import { ETaskListModule } from '@/application/types/task/task.types';

const props = withDefaults(
  defineProps<{
    cardInformation: ICustomTaskFilter;
    type: 'USER' | 'GROUP' | 'DOMAIN' | 'IA';
    sourceId: number;
  }>(),
  {
    type: 'USER',
  }
);

defineEmits(['onCollapseBoard', 'onOpenTaskDetail', 'onAddNewTask']);
const _localConfigsStore = localConfigsStore();

const sourceType = computed<ETaskListModule>(() => {
  if (props.type === 'GROUP') return ETaskListModule.GROUP;
  if (props.type === 'DOMAIN') return ETaskListModule.DOMAIN;
  if (props.type === 'IA') return ETaskListModule.I_ASSIGNED;
  return ETaskListModule.USER;
});

const filterPayload = computed<any>(() => {
  return new FilterQuery(props.cardInformation?.filterObject);
});

const {
  isReady,
  taskAllList,
  allTaskByIds,
  currentSortData,
  listTaskIdShowing,
  isLoading,
  initData,
  onLoadMore,
  onChangeIterate,
  onChangeOrder,
  onSearch,
} = useTaskBoardCard();

const taskBoardCardDefaultRef = ref<any>(null);
const targetIsVisible = useElementVisibility(taskBoardCardDefaultRef);
const initComponent = () => {
  if (targetIsVisible.value) initData(filterPayload.value);
};

watch(
  () => targetIsVisible.value,
  (value) => {
    if (value && !isReady.value && !isCollapse.value)
      initData(filterPayload.value);
  }
);

const localConfigs = computed<any>(() => _localConfigsStore.localConfigs);
const cardIdInCache = computed<any>(
  () => `BY_CUSTOM_${props.cardInformation?.id}`
);

const onCollapseBoard = () => {
  if (isCollapse.value && !isReady.value) initData(filterPayload.value);

  _localConfigsStore.setLocalConfigs('task', {
    [cardIdInCache.value]: {
      isCollapse: !isCollapse.value,
    },
  });
};

const isHasOpenAttachment = ref(null as any);
const currentTaskId = ref(0 as number);
const isOpenAttachmentDetail = ref(false);
const onOpenAttachmentDetail = (taskId) => {
  if (isHasOpenAttachment.value) isHasOpenAttachment.value = null;
  currentTaskId.value = taskId;
  isHasOpenAttachment.value = taskId;
  isOpenAttachmentDetail.value = true;
};

const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;
};

const isHovered = ref<boolean>(false);
const onHoverCard = () => {
  if (isHovered.value) return;

  onLoadMore();

  isHovered.value = true;
};

const isCollapse = computed(() => {
  return localConfigs.value['task']?.[cardIdInCache.value]?.isCollapse;
});
initComponent();
</script>

<template>
  <div
    v-bind="$attrs"
    class="flex flex-col h-full hover-to-show__parent task-board-card-custom"
    :style="isCollapse ? `width: 3rem` : `width: 25rem`"
    @mouseover="onHoverCard"
  >
    <div
      class="
        p-2
        rounded
        flex
        items-center
        justify-between
        hover:bg-current-50
        drag-to-scroll-target
      "
      :class="isCollapse ? ' flex-col-reverse ' : ''"
    >
      <TaskFilterCustomTag
        v-if="!isCollapse"
        is-active
        :total="taskAllList?.length"
        :color="cardInformation.color || '#008E8E'"
        :name="cardInformation?.name"
        :list-task-owner-id="sourceId"
        :source-type="sourceType"
        :tab-detail="cardInformation"
      />
      <ActionInBoard
        type="CUSTOM"
        hidden-class="task-board-card-custom__hover-to-show"
        :tab="cardInformation"
        :is-collapse="isCollapse"
        :current-sort-data="currentSortData"
        readonly
        @on-collapse-board="onCollapseBoard"
        @on-change-iterate="onChangeIterate"
        @on-change-order="onChangeOrder"
        @on-search="onSearch"
      />
    </div>

    <div
      ref="taskBoardCardDefaultRef"
      v-scroll-infinite="onLoadMore"
      class="bg-gray-50 rounded flex-1 overflow-y-auto small-scrollbar relative"
    >
      <TaskFilterCustomTag
        v-if="isCollapse"
        class="absolute min-w-max"
        style="
          transform-origin: left;
          transform: rotate(90deg) translateY(-50%);
          left: 25%;
        "
        is-active
        :total="taskAllList?.length"
        :color="cardInformation.color || '#008E8E'"
        :name="cardInformation?.name"
        :list-task-owner-id="sourceId"
        :source-type="sourceType"
        :tab-detail="cardInformation"
      />

      <template v-if="isLoading">
        <TaskBoardCardLoading class="m-2" />
      </template>
      <template v-else-if="!isCollapse">
        <TaskCardItem
          v-for="taskId in listTaskIdShowing"
          :key="taskId"
          class="m-2"
          :task-detail="allTaskByIds[taskId]"
          @on-show-files="onOpenAttachmentDetail(taskId)"
        />
      </template>
    </div>
  </div>

  <TaskAttachmentDetail
    v-if="isOpenAttachmentDetail"
    :task-id="currentTaskId"
    @on-close="onCloseAttachment"
  />
</template>

<style>
.task-board-card-custom__hover-to-show {
  display: none;
}
.task-board-card-custom:hover .task-board-card-custom__hover-to-show {
  display: flex;
}
</style>
