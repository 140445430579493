import BaseRepository from '@/base/repositories/BaseRepository';
import { CalendarsSystemEventsFilter } from '@/domain/entities/calendars/CalendarsOverviewFiltersClass';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { CalendarSharingModel } from '@/application/models/calendar/CalendarSharingModel';

export default class CalendarRepository {
    private static instance: CalendarRepository;

    constructor() {}

    public static getInstance(): CalendarRepository {
        if (!CalendarRepository.instance) {
            // Get from local storage
            CalendarRepository.instance = new CalendarRepository();
        }

        return CalendarRepository.instance;
    }

    getRangeEvents(data): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/GetRangeEvents`,
            method: 'post',
            data: data,
        });
    }

    addEvent(data): Promise<any> {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Calendar/AddEvent`,
                method: 'post',
                data: data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_CALENDAR_ADD_EVENT_SUCCESS'
                ),
            }
        );
    }

    getDetail(id): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/GetDetail?eventId=${id}`,
            method: 'get',
        });
    }

    updateEvent(data): Promise<any> {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Calendar/UpdateEvent`,
                method: 'post',
                data: data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_CALENDAR_UPDATE_EVENT_SUCCESS'
                ),
            }
        );
    }

    getSystemEvents(data: CalendarsSystemEventsFilter): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/GetSystemEvents?startDate=${data.fromDate}&endDate=${data.toDate}`,
            method: 'get',
            data: data,
        });
    }

    updateTimeEvent(data): Promise<any> {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Calendar/UpdateTimeEvent`,
                method: 'post',
                data: data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_CALENDAR_UPDATE_EVENT_SUCCESS'
                ),
            }
        );
    }

    getSystemEventDetail(id): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/GetSystemEventDetail?eventId=${id}`,
            method: 'get',
        });
    }

    snoozeReminder(id, time): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/RenewReminder?eventId=${id}&expiredAfter=${time}`,
            method: 'post',
        });
    }

    async deleteEvent(id): Promise<any> {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Calendar/DeleteEvent?eventId=${id}`,
                method: 'post',
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_CALENDAR_UPDATE_EVENT_SUCCESS'
                ),
            }
        );
    }

    stopReminder(id): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/StopReminder?eventId=${id}`,
            method: 'post',
        });
    }

    getUserCalendarSync(): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/GetUserCalendarSync`,
            method: 'get',
        });
    }

    signInUserCalendarSync(data: any): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/SignInUserCalendarSync`,
            method: 'put',
            data,
        });
    }

    signOutUserCalendarSync(): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/SignOutUserCalendarSync`,
            method: 'put',
        });
    }

    saveUserCalendarSync(data: any): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/SaveUserCalendarSync`,
            method: 'put',
            data,
        });
    }

    //<editor-fold desc="CALENDAR SHARING">

    getCalendarSharing(sharingId: string): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/GetCalendarSharing/${sharingId}`,
            method: 'get',
        });
    }

    getUserCalendarEventsBySharingFilter(data: any): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/GetUserCalendarEventsBySharingFilter`,
            method: 'post',
            data,
        });
    }

    getUserCalendarSharings(): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/GetUserCalendarSharings`,
            method: 'get',
        });
    }

    addUserCalendarSharing(data: CalendarSharingModel): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/AddUserCalendarSharing`,
            method: 'post',
            data,
        });
    }

    updateUserCalendarSharing(
        sharingId: string,
        data: CalendarSharingModel
    ): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/UpdateUserCalendarSharing/${sharingId}`,
            method: 'put',
            data,
        });
    }

    deleteUserCalendarSharing(sharingId: string): Promise<any> {
        return BaseRepository.requestWithAuthorize({
            url: `Calendar/DeleteUserCalendarSharing/${sharingId}`,
            method: 'delete',
        });
    }

    //</editor-fold>
}
