import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import { IWorkflowStepType } from '@/application/types/workflow/workflow.types';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import workflowStore from '@/store/workflow';

export default class WorkflowStepTypeEntity implements IWorkflowStepType {
    id: number;
    name: string;
    isSystem: boolean;
    index: number;
    usedStepTemplates: { id: string; name: string }[];
    constructor(data) {
        this.id = data?.id || 0;
        this.name = data?.name;
        this.isSystem = data?.isSystem;
        this.index = data?.index;
        this.usedStepTemplates = data?.usedStepTemplates;
    }

    async checkEditable() {
        if (this.isSystem) {
            settingGlobalModal({
                type: 'notification',
                title:
                    translate('WORKFLOW_LABEl_STEP_CAN_NOT_EDIT') ||
                    'Can not remove',
                content:
                    translate('WORKFLOW_LABEl_STEP_TYPE_IS_A_SYSTEM_TYPE', {
                        stepTypeName: this.name || '',
                    }) || '',
                closeable: true,
            });
            await ask();
            return false;
        }
        return true;
    }
    async saveUpdate() {
        const res =
            await WorkflowRepository.getInstance().addOrUpdateWorkFlowStepType(
                this
            );
        workflowStore().getAllStepType();

        return res;
    }

    async remove() {
        if (this.isSystem) {
            settingGlobalModal({
                type: 'notification',
                title:
                    translate('WORKFLOW_LABEl_STEP_CAN_NOT_REMOVE') ||
                    'Can not remove',
                content:
                    translate('WORKFLOW_LABEl_STEP_TYPE_IS_A_SYSTEM_TYPE', {
                        stepTypeName: this.name || '',
                    }) || '',
                closeable: true,
            });
            await ask();
            return Promise.resolve(false);
        }
        if (this.usedStepTemplates && this.usedStepTemplates?.length > 0) {
            settingGlobalModal({
                type: 'notification',
                title:
                    translate('WORKFLOW_LABEl_STEP_CAN_NOT_REMOVE') ||
                    'Can not remove',
                content:
                    translate('WORKFLOW_LABEl_STEP_TYPE_USE_IN_STEP', {
                        stepTypeName: this.name || '',
                        totalWorkflowStep: this.usedStepTemplates?.length || '',
                    }) || '',
                closeable: true,
            });
            await ask();
            return Promise.resolve(false);
        }

        settingGlobalModal({
            type: 'confirm',
            title: '',
            content:
                translate('WORKFLOW_LABEl_STEP_TYPE_CONFIRM_REMOVE', {
                    stepName: this.name || '',
                }) || 'Do you want to remove this note?',
            confirmable: true,
            confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
            closeable: true,
        });

        const confirmed = await ask();
        if (!confirmed) return Promise.resolve(false);
        return WorkflowRepository.getInstance().deleteWorkFlowStepType(this.id);
    }
}
