<script setup lang="ts">
import { computed } from 'vue';
import { colorByAlphabet, convertViToEn, cleanString } from './colorByAlphabet';
import $constants from '@/ui/plugins/constants';
import VigImage from '@/ui/common/atoms/VigImage/VigImage.vue';
import FavoriteButton from '@/ui/components/user/FavoriteButton.vue';

const props = withDefaults(
  defineProps<{
    src: string | null;
    name: string | null;
    subName: string | null;
    hasName: boolean;
    size: string;
    customClass: string;
    ring: string;
    type: 'user' | 'group';
    refId: string;
    favoriteType: 'member' | 'group';
    isDeactive: boolean;
  }>(),
  {
    size: $constants.IMAGE.SIZE.MINI,
    customClass: 'h-8 w-8',
    type: 'user',
    favoriteType: 'member',
    isDeactive: false,
  }
);

const textAva = computed(() => {
  if (!props?.name) return '';
  return cleanString(props.name)
    .split(' ')
    [cleanString(props.name).split(' ').length - 1].charAt(0);
});
</script>

<template>
  <div class="relative">
    <div
      class="
        flex-center
        absolute
        z-10
        rounded-full
        -right-1.5
        -bottom-1.5
        w-4
        h-4
      "
    >
      <FavoriteButton
        v-if="refId"
        shape-type="DEFAULT"
        custom-size="w-3 h-3"
        is-hidden-un-favorite
        :ref-id="refId"
        :type="favoriteType"
      />
    </div>

    <div class="relative flex items-center">
      <!--  AVATAR  -->
      <div class="relative shadow rounded-full" :class="[customClass, ring]">
        <VigImage
          v-if="src"
          class="w-full h-full object-cover overflow-hidden rounded-full"
          error-type="AVATAR"
          :path="src"
          :size="size"
        />
        <div
          v-else
          class="
            object-cover
            flex-center
            text-white
            shadow
            font-thin
            rounded-full
          "
          :class="`${customClass} ${ring}`"
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
        >
          <span v-if="textAva" class="uppercase text-base">{{ textAva }}</span>
          <SynIconCustom
            v-else
            :name="type === 'user' ? 'profile' : 'group'"
            is-active
            custom-class="w-3 h-3 fill-white text-white"
          />
        </div>
      </div>

      <div v-if="hasName && name" class="flex-1 flex flex-col items-start pl-2">
        <slot name="name">
          <div class="font-medium">{{ name }}</div>
        </slot>
        <slot name="subName"></slot>
      </div>

      <div
        v-if="isDeactive"
        class="
          absolute
          -bottom-1
          -right-1
          w-1/2
          h-1/2
          my-1
          border border-red-500
          bg-white
          rounded-full
          text-red-500
          flex-center
          font-medium
        "
      >
        <span class="pb-0.5">-</span>
      </div>
    </div>
  </div>
</template>
