<script setup lang="ts">
import { ref } from 'vue';
import WorkflowList from '@/ui/modules/workflow/WorkflowList.vue';
import ApplyWorkFlowModal from '@/ui/modules/task/workflow/ApplyWorkFlowModal.vue';
import TaskWorkflowEntity, {
  ITaskWorkflowEntity,
} from '@/domain/entities/task/TaskWorkflowEntity';
import workflowStore from '@/store/workflow';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';

defineProps<{
  formData: any;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onAttach', data: any): void;
}>();

const _workflowStore = workflowStore();
const searchText = ref<string>('');

const isShowCurrentStepAttachment = ref<boolean>(false);
const isOpenWorkFlow = ref<boolean>(false);

const attachedWorkflowInfo = ref<TaskWorkflowEntity>(
  new TaskWorkflowEntity({})
);
const originalWorkflowDetail = ref<ITaskWorkflowEntity | undefined>();
const onSelectExistedWorkflow = async (selectedWorkflow, viewMode) => {
  if (viewMode == 'CHOOSE') {
    originalWorkflowDetail.value = await _workflowStore.getWorkflowById(
      selectedWorkflow?.id
    );
    attachedWorkflowInfo.value = new TaskWorkflowEntity({
      ...selectedWorkflow,
      ...originalWorkflowDetail.value,
    });

    isOpenWorkFlow.value = true;
  }
};

const onAttach = (data) => {
  emit('onAttach', data);
  isOpenWorkFlow.value = false;
};

const allWorkflows = ref<any[]>([]);

const attachWorkflowDropdownRef = ref<any>(null);
</script>

<template>
  <div
    v-permission-function="{
      functionCode: ALL_FUNCTIONS.TASK.WORKFLOW,
      onContinue: () => {
        if (typeof attachWorkflowDropdownRef?.onForceOpen == 'function')
          attachWorkflowDropdownRef?.onForceOpen();
      },
    }"
    v-system-config="[
      {
        functionCode: 'ADVANCED_WORKFLOW_WORKFLOW_ALLOWED_BY_SYSTEM',
        functionValue: '1',
      },
      {
        functionCode: 'DRAFT_WORKFLOW_WORKFLOW_ALLOWED',
        functionValue: '1',
      },
    ]"
  >
    <VigDropdown
      ref="attachWorkflowDropdownRef"
      :arrow="false"
      placement="bottom-end"
      hide-on-click="toggle"
      @on-dropdown-open="isShowCurrentStepAttachment = true"
      @on-dropdown-close="isShowCurrentStepAttachment = false"
    >
      <template #dropdown-toggle>
        <div
          class="
            px-2
            py-1
            rounded-full
            border border-dashed
            text-gray-500 text-sm
            flex
            items-center
            justify-between
            gap-2
            cursor-pointer
            hover:bg-current-50
          "
          :class="isShowCurrentStepAttachment ? 'bg-current-50' : ''"
        >
          <span class="text-xs"> {{ $t('TASK_ACTION_WORK_FLOW') }} </span>
          <SynIcon
            v-if="isLoading"
            name="spinner"
            class="border-none animate-spin"
            custom-class="w-4 h-4 fill-current"
          />
          <SynIcon
            v-else
            name="SortDownThin"
            custom-class="h-2.5 w-2.5 fill-gray-500"
            :class="!isShowCurrentStepAttachment ? '' : 'rotate-90'"
          />
        </div>
      </template>
      <template #dropdown-menu>
        <div class="flex flex-col">
          <div v-if="allWorkflows?.length > 10" class="p-2">
            <VigSearchBox
              v-model="searchText"
              input-class="text-sm"
              style="width: 20rem"
              autofocus
              :placeholder="$t('COMMON_LABEL_SEARCH')"
            />
          </div>
          <div class="flex-1 min-h-0 flex flex-col">
            <WorkflowList
              view-type="LIST"
              :action-list="['PREVIEW']"
              filter-active-only
              @choose="onSelectExistedWorkflow"
              @on-ready="(data) => (allWorkflows = data)"
            />
          </div>
        </div>
      </template>
    </VigDropdown>
  </div>

  <ApplyWorkFlowModal
    v-if="isOpenWorkFlow"
    :task-workflow="attachedWorkflowInfo?.id ? attachedWorkflowInfo : undefined"
    :domain-id="formData?.domainId"
    :group-id="formData?.groupId"
    :task-detail="formData"
    @cancel="isOpenWorkFlow = false"
    @on-attach="onAttach"
    @on-save="onAttach"
  />
</template>
