import {
    getCurrentUserId,
    getCurrentOrganizationId,
} from '@/ui/hooks/storageHook';
import myProfileStore from '@/store/auth/my-profile';
import commonStore from '@/store/common/commonStore';

export default class TictopWebSocket {
    ws: WebSocket;
    message: ISocketMessagePayload;
    constructor(event?, subType?, metadata?) {
        this.message = this.initMessage();
        // this.ws = new WebSocket('ws://localhost:3408/api/socket');
        this.ws = new WebSocket(
            `${import.meta.env.VITE_TICTOP_WEB_SOCKET_SERVER}/api/socket/`
        );

        this.ws.onopen = () => {
            console.log('OPENED');
            if (event) {
                this.prepareMessageData(event, subType, metadata);
                this.ws.send(JSON.stringify(this.message));
            }
        };

        // ws.onmessage = (event) => {
        //     console.log(`WebSocket message received: ${event.data}`);
        // };

        this.ws.onclose = () => {
            console.log('CLOSED');
        };
    }

    initMessage() {
        const userProfile = myProfileStore().myProfile;
        const sessionId = commonStore().sessionId;
        return {
            session_id: sessionId,
            message: '',
            module: '',
            type: '',
            sub_type: '',
            event: '',
            phone: userProfile?.phone,
            email: userProfile?.email,
            username: userProfile?.username,
            user_id: getCurrentUserId(),
            organization_id: getCurrentOrganizationId(),
            metadata: {
                first_name: userProfile?.firstName,
                last_name: userProfile?.lastName,
                phone: userProfile?.phone,
                email: userProfile?.email,
            },
        };
    }

    send(event, subType?, metadata?) {
        if (this.ws?.readyState == 1) {
            this.prepareMessageData(event, subType, metadata);

            this.ws.send(JSON.stringify(this.message));
        }
    }

    prepareMessageData(event, subType, metadata?) {
        this.message = this.message || this.initMessage();
        this.message.event = event;
        this.message.type = metadata?.type || event;
        this.message.sub_type = subType;

        if (metadata) {
            metadata.password = undefined;
            metadata.verifyPassword = undefined;
        }

        if (metadata?.phone) {
            this.message.phone = metadata.phone;
            this.message.metadata.phone = metadata.phone;
        }
        if (metadata?.email) {
            this.message.email = metadata.email;
            this.message.metadata.email = metadata.email;
        }

        const userName = metadata?.username;
        if (userName) {
            this.message.username = userName;
            this.message.metadata.username = userName;
        }
        if (metadata?.firstName) {
            this.message.metadata.first_name = metadata.firstName;
        }
        if (metadata?.lastName) {
            this.message.metadata.last_name = metadata.lastName;
        }
        if (metadata?.planId) {
            this.message.metadata.plan_id = metadata.planId;
        }
        if (metadata?.period) {
            this.message.metadata.period = metadata.period;
        }
        if (metadata?.phone_code && typeof metadata.phone_code == 'string') {
            this.message.metadata.phone_code = metadata.phone_code;
        }
    }

    close() {
        if (this.ws) this.ws?.close();
    }
}

export interface ISocketMessagePayload {
    session_id: string;
    message: string;
    module: string;
    type: string;
    sub_type: string;
    event: string;
    phone: string;
    email: string;
    username: string;
    metadata: any;
    organization_id: number;
    user_id: number;
}

export const USER_REG_EVENT = {
    REGISTRATION_PAGE_INIT: 'USER_REGISTRATION_REGISTRATION_PAGE_INIT',
    FINISH_INPUT_PHONE_NUMBER: 'USER_REGISTRATION_FINISH_INPUT_PHONE_NUMBER',
    CLICK_REGISTER_SEND_PHONE_CODE_BTN:
        'USER_REGISTRATION_CLICK_REGISTER_SEND_PHONE_CODE_BTN',
    PAGE_ENTER_PHONE_CODE_INIT: 'USER_REGISTRATION_PAGE_ENTER_PHONE_CODE_INIT',
    CLICK_ENTER_PHONE_CODE: 'USER_REGISTRATION_CLICK_ENTER_PHONE_CODE',
    CLICK_RESEND_PHONE_CODE: 'USER_REGISTRATION_CLICK_RESEND_PHONE_CODE',
    PAGE_ENTER_EMAIL_ADDRESS_INIT:
        'USER_REGISTRATION_PAGE_ENTER_EMAIL_ADDRESS_INIT',
    ENTER_EMAIL_ADDRESS: 'USER_REGISTRATION_ENTER_EMAIL_ADDRESS',
    CLICK_COMPLETE_REGISTER: 'USER_REGISTRATION_CLICK_COMPLETE_REGISTER',
    PAGE_INPUT_ORGANIZATION_INFO_INIT:
        'USER_REGISTRATION_PAGE_INPUT_ORGANIZATION_INFO_INIT',
    INPUT_ORGANIZATION_INFO: 'USER_REGISTRATION_INPUT_ORGANIZATION_INFO',
    CLICK_FINISH_ORGANIZATION_SETTING:
        'USER_REGISTRATION_CLICK_FINISH_ORGANIZATION_SETTING',
    PAGE_SELECT_PAYMENT_PLAN_INIT:
        'USER_REGISTRATION_PAGE_SELECT_PAYMENT_PLAN_INIT',
    SELECT_PAYMENT_PLAN_TRIAL: 'USER_REGISTRATION_SELECT_PAYMENT_PLAN_TRIAL',
    SELECT_PAYMENT_PLAN_PAY_NOW:
        'USER_REGISTRATION_SELECT_PAYMENT_PLAN_PAY_NOW',
    SELECT_PAYMENT_PLAN_BACK_FROM_PAY_NOW:
        'USER_REGISTRATION_SELECT_PAYMENT_PLAN_BACK_FROM_PAY_NOW',
    CLICK_PAY_AND_GO_PAYMENT_PAGE:
        'USER_REGISTRATION_CLICK_PAY_AND_GO_PAYMENT_PAGE',
    CLICK_REGISTER_INPUT_PASSWORD: 'CLICK_REGISTER_INPUT_PASSWORD',
    CLICK_ENTER_PASSWORD: 'CLICK_ENTER_PASSWORD',
    CLICK_SELECT_ORGANIZATION_MODEL_COMPANY:
        'CLICK_SELECT_ORGANIZATION_MODEL_COMPANY',
    CLICK_SELECT_ORGANIZATION_MODEL_TEAM:
        'CLICK_SELECT_ORGANIZATION_MODEL_TEAM',
    CLICK_SELECT_ORGANIZATION_MODEL_PERSONAL:
        'CLICK_SELECT_ORGANIZATION_MODEL_PERSONAL',
    CLICK_SELECT_ORGANIZATION_MODEL_BACK:
        'CLICK_SELECT_ORGANIZATION_MODEL_BACK',
    CLICK_SELECT_ORGANIZATION_MODEL_CONTINUE:
        'CLICK_SELECT_ORGANIZATION_MODEL_CONTINUE',
    CLICK_SELECT_ORGANIZATION_SETTING_TYPE:
        'CLICK_SELECT_ORGANIZATION_SETTING_TYPE',
};

export enum USER_REG_SUB_TYPE {
    REGISTER_WITH_USERNAME = 'REGISTER_WITH_USERNAME',
    REGISTER_WITH_PHONE = 'REGISTER_WITH_PHONE',
}
