<template>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g clip-path="url(#clip3_1)">
      <path
        id="path"
        d="M91.13 303.78L162.61 303.78L162.61 329.73L91.13 329.73L91.13 303.78ZM91.13 151.88L162.61 151.88L162.61 177.83L91.13 177.83L91.13 151.88ZM91.13 227.83L162.61 227.83L162.61 253.78L91.13 253.78L91.13 227.83ZM212.64 303.78L284.13 303.78L284.13 329.73L212.64 329.73L212.64 303.78ZM91.13 379.72L162.61 379.72L162.61 405.67L91.13 405.67L91.13 379.72ZM212.64 379.72L284.13 379.72L284.13 405.67L212.64 405.67L212.64 379.72ZM212.64 151.88L284.13 151.88L284.13 177.83L212.64 177.83L212.64 151.88ZM212.64 227.83L284.13 227.83L284.13 253.78L212.64 253.78L212.64 227.83Z"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
      <path
        id="path"
        d="M360.07 0L150.22 0C67.38 0 0 67.4 0 150.21L0 512L511.96 512L511.96 167.08L360.07 167.08L360.07 0ZM334.16 486.05L25.91 486.05L25.91 150.21C25.91 81.67 81.68 25.94 150.22 25.94L334.15 25.94L334.16 486.05ZM394.91 243.02L451.21 243.02L451.21 268.97L394.91 268.97L394.91 243.02ZM394.91 318.97L451.21 318.97L451.21 344.91L394.91 344.91L394.91 318.97ZM394.91 394.91L451.21 394.91L451.21 420.86L394.91 420.86L394.91 394.91Z"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
      <circle
        id="circle"
        cx="362.000000"
        cy="362.000000"
        r="150.000000"
        fill="#FFFFFF"
        fill-opacity="1.000000"
      />
      <path
        id="path"
        d="M512 362C512 279.15 444.83 212 362 212C279.16 212 212 279.15 212 362C212 444.84 279.16 512 362 512C444.83 512 512 444.83 512 362ZM483.87 362C483.87 429.32 429.31 483.86 362 483.86C294.68 483.86 240.12 429.32 240.12 362C240.12 294.68 294.68 240.12 362 240.12C429.31 240.12 483.87 294.69 483.87 362ZM425.45 356.34L371.25 302.75C366.16 294.49 357.83 294.49 352.75 302.75L298.54 356.34C293.46 364.6 297.63 371.38 307.8 371.38L333.87 371.38L333.87 418.25C333.87 419.5 334.11 420.69 334.58 421.84C335.06 422.99 335.74 424 336.62 424.88C337.49 425.76 338.51 426.44 339.66 426.91C340.81 427.39 342 427.63 343.25 427.63L380.75 427.63C381.99 427.63 383.19 427.39 384.34 426.92C385.48 426.44 386.5 425.76 387.38 424.89C388.26 424.01 388.93 422.99 389.41 421.84C389.88 420.69 390.12 419.5 390.12 418.25L390.12 371.38L416.19 371.38C426.37 371.37 430.54 364.6 425.45 356.34Z"
        fill="#4793F3"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
