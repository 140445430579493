<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import WorkflowStepEntity, {
  IWorkflowStep,
} from '@/domain/entities/workflow/WorkflowStepEntity';
import TaskTemplate from '@/ui/modules/task/components/task-template/TaskTemplate.vue';
import WorkflowStepInformation from '@/ui/modules/workflow/workflow-step/WorkflowStepInformation.vue';
import workflowStepComposables from '@/ui/composables/workflow/workflow-step.composables';
import WorkflowStepNextSteps from '@/ui/modules/workflow/workflow-step/WorkflowStepNextSteps.vue';
import TaskSetApprovalModal from '@/ui/modules/task/approval/TaskSetApprovalModal.vue';
import { TaskApprovalStatus } from '@/application/types/task/task.types';

const props = withDefaults(
  defineProps<{
    viewMode?: 'VIEW' | 'EDIT' | 'ADD' | 'CLONE' | 'PREVIEW';
    stepDetail: IWorkflowStep;
    readonly?: boolean;
    actionList?: string[];
    isUpdateImmediately?: boolean;
    scope?: {
      departmentId?: number;
      groupId?: number;
      domainId?: number;
    };
    stepByIds?: any;
  }>(),
  {
    viewMode: 'VIEW',
    actionList: () => ['DUPLICATE', 'EDIT', 'REMOVE'],
  }
);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'update:stepDetail', newData: any): void;
  (e: 'updateSuccess', newData: any): void;
  (e: 'removeSuccess'): void;
  (e: 'onSave', newData): void;
}>();

const {
  workflowStepData,
  currentTaskTemplate,
  initData,
  updateWorkflowStepFromTaskTemplate,
} = workflowStepComposables({
  ...props.stepDetail,
});

const isLoading = ref<boolean>(true);

onMounted(() => {
  isLoading.value = false;
});

watch(
  () => props.stepDetail?.id,
  () => {
    if (workflowStepData.value?.id !== props.stepDetail?.id) {
      initData({
        ...props.stepDetail,
      });
    }
  }
);

const visibleStatus = reactive<{ information: boolean; taskTemplate: boolean }>(
  { information: true, taskTemplate: true }
);

const isUpdating = ref<boolean>(false);
const isDisableButton = computed<boolean>(() => {
  return !workflowStepData.value?.name || !workflowStepData.value?.code;
});
const viewMode = ref<'VIEW' | 'EDIT' | 'ADD' | 'CLONE' | 'PREVIEW'>(
  props.viewMode
);
const onSave = async () => {
  try {
    isUpdating.value = true;

    updateWorkflowStepFromTaskTemplate();

    let res: any = {};
    if (props.isUpdateImmediately && workflowStepData.value?.workflowId) {
      res = await workflowStepData.value.saveUpdate();
    }

    viewMode.value = 'VIEW';

    const updatedWorkflowStep = {
      ...workflowStepData.value,
      ...(res?.result || {}),
    };
    emit('update:stepDetail', updatedWorkflowStep);
    emit('onSave', updatedWorkflowStep);
    emit('updateSuccess', updatedWorkflowStep);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isUpdating.value = false;
  }
};
const onOpenEditMode = () => {
  viewMode.value = 'EDIT';
};

const actionDataListByKey = {
  DUPLICATE: {
    key: 'DUPLICATE',
    iconName: 'duplicate',
    color: '',
    handleClick: () => {
      workflowStepData.value = new WorkflowStepEntity({
        ...props.stepDetail,
      });
      viewMode.value = 'CLONE';
    },
  },
  EDIT: {
    key: 'EDIT',
    iconName: 'edit',
    color: '',
    handleClick: async (step) => {
      const editable = await new WorkflowStepEntity(step).checkEditable();
      if (!editable) return;

      onOpenEditMode();
    },
  },
  REMOVE: {
    key: 'REMOVE',
    iconName: 'trash',
    color: 'red',
    handleClick: async (step) => {
      const res = await new WorkflowStepEntity(step).remove();

      if (!res) return;

      emit('removeSuccess');
    },
  },
};
const onCancelEdit = () => {
  if (props.viewMode == 'VIEW') {
    workflowStepData.value = new WorkflowStepEntity({
      ...props?.stepDetail,
    });
    viewMode.value = 'VIEW';
    return;
  }

  emit('close');
};

const actionDataList = computed<any[]>(() => {
  return props.actionList?.map((key) => actionDataListByKey[key]);
});

const confirmLabel = computed<string>(() => {
  if (viewMode.value == 'PREVIEW') return 'COMMON_LABEL_APPLY';
  if (viewMode.value == 'EDIT') return 'COMMON_LABEL_SAVE';

  return 'COMMON_LABEL_CREATE';
});

const isOpenMoreAction = ref(false);

const onClickApproval = () => {
  if (!workflowStepData.value?.approvalEnabled) {
    // mask as approval
    onOpenApproval();
  } else {
    unMaskAsApproval();
  }
};
const unMaskAsApproval = () => {
  workflowStepData.value.approvalEnabled = false;
  workflowStepData.value.approvalStatus = undefined;
  workflowStepData.value.approvers = [];

  workflowStepData.value.updateFields = [
    'approvalEnabled',
    'approvalStatus',
    'approvers',
  ];
};

const isOpenApprovalModal = ref<boolean>(false);
const onOpenApproval = async () => {
  isOpenApprovalModal.value = true;
};

const onSetApprover = async (approverIds) => {
  isOpenApprovalModal.value = false;
  workflowStepData.value.approvalEnabled = true;
  workflowStepData.value.approvalStatus = TaskApprovalStatus.NOT_REQUESTED;
  workflowStepData.value.approvers = approverIds?.map((userId) => {
    return {
      userId,
    };
  });

  workflowStepData.value.updateFields = [
    'approvalEnabled',
    'approvalStatus',
    'approvers',
  ];
};
</script>
<template>
  <div
    class="
      flex flex-col
      justify-between
      flex-1
      min-h-0
      w-full
      text-xs
      overflow-hidden
    "
  >
    <div class="flex-1 min-h-0 w-full h-full px-2 flex flex-col space-y-3">
      <!-- HEADER -->
      <div
        class="
          w-full
          flex
          items-center
          justify-between
          space-x-2
          rounded
          cursor-pointer
          pt-4
          px-4
        "
      >
        <div class="flex-1 min-w-0 truncate">
          <!-- <span class="text-sm">
              {{ $t('COMMON_LABEL_INFORMATION') }}
            </span> -->
          <div
            class="text-base min-w-max font-semibold"
            v-html="
              $t(
                viewMode == 'ADD' || viewMode == 'CLONE'
                  ? 'WORKFLOW_LABEL_CREATE_NEW_WORKFLOW_STEP'
                  : 'WORKFLOW_LABEl_STEP_WITH_NAME',
                {
                  stepName: workflowStepData?.name || '',
                }
              )
            "
          ></div>
        </div>
        <!-- <SynIcon
          :name="'sort-down'"
          custom-class="h-2.5 w-2.5"
          :class="!visibleStatus.information ? '-rotate-90' : ''"
        /> -->
        <div
          v-if="viewMode == 'VIEW' && !readonly"
          class="flex-center space-x-1"
        >
          <div
            v-for="action in actionDataList"
            :key="action?.key"
            class="
              w-8
              h-8
              rounded-md
              cursor-pointer
              flex-center
              border border-gray-100
              hover:shadow
            "
            @click="action?.handleClick(workflowStepData)"
          >
            <SynIcon
              :name="action?.iconName"
              :custom-class="
                action?.color ? `h-4 w-4 fill-${action?.color}-500` : ''
              "
            />
          </div>
        </div>
        <div v-if="!readonly" class="flex-center space-x-1">
          <VigDropdown
            :arrow="false"
            placement="bottom-end"
            @click.stop.prevent="() => {}"
            @on-dropdown-open="isOpenMoreAction = true"
            @on-dropdown-close="isOpenMoreAction = false"
          >
            <template #dropdown-toggle>
              <div
                class="w-8 h-8 rounded-md p-2 hover:bg-gray-100 flex-center"
                :class="isOpenMoreAction ? 'bg-gray-100' : ''"
              >
                <SynIcon name="dots-vertical" custom-class="w-4 h-4" />
              </div>
            </template>
            <template #dropdown-menu>
              <div
                style="max-height: min(72vh, 600px)"
                class="overflow-auto small-scrollbar"
              >
                <div
                  class="
                    list-li
                    flex
                    text-gray-600
                    justify-start
                    items-center
                    space-x-2
                    text-sm
                    relative
                    dropdown-item
                  "
                  @click="onClickApproval"
                >
                  <SynIcon class="fill-gray-500" name="approval" />
                  <span v-if="!workflowStepData?.approvalEnabled">
                    {{ $t('WORKFLOW_LABEL_ENABLE_APPROVAL_FOR_THIS_TASK') }}
                  </span>
                  <span v-else>
                    {{ $t('WORKFLOW_LABEL_DISABLE_APPROVAL_FOR_THIS_TASK') }}
                  </span>
                </div>
              </div>
            </template>
          </VigDropdown>
        </div>
      </div>
      <!-- BODY -->
      <div
        v-if="!isLoading"
        class="
          flex-1
          min-h-0
          rounded
          w-full
          flex flex-col
          px-4
          py-1
          overflow-hidden
        "
      >
        <div class="flex-1 flex flex-col overflow-auto small-scrollbar">
          <WorkflowStepInformation
            v-if="visibleStatus.information"
            v-model:step-detail="workflowStepData"
            :scope="scope"
            view-mode="EDIT"
          />
          <!-- TASK REPEAT TEMPLATE -->
          <TaskTemplate
            v-if="visibleStatus.taskTemplate"
            ref="taskRepeatTemplateRef"
            v-model:task-template="currentTaskTemplate"
            :view-mode="viewMode == 'VIEW' ? 'VIEW' : 'EDIT'"
            is-do-not-need-add-user-info
            is-update-domain-only
            style="min-height: max-content"
            :element-list="[
              'RESPONSIBLE',
              'COLLABORATORS',
              'DOMAIN',
              'URGENCY',
              'DESCRIPTION',
              'DEADLINE',
              'ATTACHMENT',
              'CHECK_LIST',
            ]"
            is-hidden-start-time
          />
        </div>
        <div class="flex flex-col p-4 rounded">
          <span class="text-xs text-gray-500">
            {{ $t('WORKFLOW_LABEL_NEXT_STEP') }}
          </span>

          <WorkflowStepNextSteps
            :next-steps="workflowStepData?.nextSteps"
            :step-by-ids="stepByIds"
          />
        </div>
      </div>
    </div>

    <div
      v-if="viewMode !== 'VIEW'"
      class="w-full flex items-center justify-end"
    >
      <div class="flex-center space-x-2 p-4">
        <SynButton type-text color="gray" @click="onCancelEdit">
          <span> {{ $t('COMMON_LABEL_CANCEL') }}</span>
        </SynButton>
        <SynButton
          :is-loading="isUpdating"
          :disabled="isDisableButton"
          @click="onSave"
        >
          <span> {{ $t(confirmLabel) }}</span>
        </SynButton>
      </div>
    </div>
  </div>
  <TaskSetApprovalModal
    v-if="isOpenApprovalModal"
    :task="workflowStepData"
    @on-close="isOpenApprovalModal = false"
    @on-save="onSetApprover"
  />
</template>
<style>
.long-arrow-right {
  width: 100%;
  height: 21px;
  display: flex;
}
.long-arrow-right:before {
  content: '';
  background: currentColor;
  clip-path: polygon(
    0px 10px,
    calc(100% - 5px) 10px,
    calc(100% - 5px) 7px,
    100% 50%,
    calc(100% - 5px) 68%,
    calc(100% - 5px) calc(100% - 10px),
    0 calc(100% - 10px)
  );
  flex-grow: 1;
}
</style>
