<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  link: any;
  isMyMessage: boolean;
  size: string;
}>();

const emits = defineEmits(['onClosePreviewLink']);

onMounted(async () => {});

const getDomainLink = (links) => {
  if (!links) return;
  let domain = new URL(links);
  return domain.hostname.replace('www.', '');
};

const onClosePreviewLink = (link, e) => {
  e.preventDefault();
  emits('onClosePreviewLink', link);
};

const isImageError = ref(false);
const onChangeImageError = () => {
  isImageError.value = true;
};

const isOpenDetail = ref(false);

const onOpenActionLink = (e) => {
  e.preventDefault();
  isOpenDetail.value = true;
};

const onCopyLink = () => {
  if (!props.link?.link) return;
  copyToClipBroard(props.link?.link);

  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('GED_FILE_COPY_MSG_TITLE'),
    duration: 3000,
  });
};

const openInNewTab = () => {
  if (!props.link?.link) return;
  window.open(props.link?.link, '_blank');
};
</script>

<template>
  <a
    v-if="link?.previewData?.isPreview"
    :href="link?.link"
    target="_blank"
    class="
      flex
      align-center
      rounded
      relative
      max-w-xs
      hover-to-show__action
      bg-white bg-opacity-40
    "
    :class="[size === 'sm' ? 'h-14' : 'h-20']"
  >
    <div class="p-1.5 rounded" :class="size === 'sm' ? 'w-14' : 'w-20'">
      <img
        v-if="link?.previewData?.imageUrl && !isImageError"
        :src="link?.previewData?.imageUrl"
        :onError="onChangeImageError"
        class="object-cover w-full h-full rounded bg-white"
      />
      <div
        v-else
        class="bg-gray-50 bg-opacity-25 w-full h-full rounded flex-center"
      >
        <syn-icon
          name="image-thumb"
          :custom-class="size === 'sm' ? 'w-6 h-6' : 'w-10 h-10'"
        ></syn-icon>
      </div>
    </div>
    <div class="flex flex-col w-3/4 py-1 pr-2 pl-0.5">
      <div
        :title="link?.previewData?.title || link?.link"
        class="font-medium text-overflow-hidden-line text-black"
        :class="size === 'sm' ? 'text-xs' : 'text-sm'"
      >
        {{ link?.previewData?.title || link?.link }}
      </div>
      <div
        v-if="link?.previewData?.description"
        :title="link?.previewData?.description"
        class="text-gray-600 text-overflow-hidden-line"
        :class="size === 'sm' ? 'text-2xs' : 'text-xs'"
      >
        {{ link?.previewData?.description }}
      </div>
      <div class="flex space-x-1 items-center pt-1">
        <syn-icon
          name="link"
          custom-class="w-2.5 h-2.5 fill-gray-500"
        ></syn-icon>
        <span
          class="text-gray-700 text-2xs align-bottom text-overflow-hidden-line"
        >
          {{
            link?.previewData?.domain || getDomainLink(link?.previewData?.link)
          }}
        </span>
      </div>
    </div>
    <VigDropdown
      v-if="isMyMessage"
      @on-dropdown-open="isOpenDetail = true"
      @on-dropdown-close="isOpenDetail = false"
    >
      <template #dropdown-toggle>
        <div
          class="absolute flex-center right-0 p-1 m-1 rounded-full bg-white"
          :class="isOpenDetail ? '' : ' hover-to-show__action_children'"
          @click="($event) => onOpenActionLink($event)"
        >
          <SynIcon name="dots-vertical" custom-class="w-3 h-3 text-gray-800" />
        </div>
      </template>
      <template #dropdown-menu>
        <div class="flex flex-col py-1 mt-4">
          <div
            class="
              flex
              items-center
              space-x-2
              text-gray-500
              fill-gray-500
              text-xs
              py-1
              cursor-pointer
              hover:bg-gray-100
              px-2
            "
            @click="onCopyLink"
          >
            <div>
              <SynIcon name="copy" custom-class="w-4 h-4"></SynIcon>
            </div>
            <div>{{ $t('TASK_TABLE_LABEL_COPPY_LINK') }}</div>
          </div>
          <div
            class="
              flex
              items-center
              space-x-2
              text-gray-500
              fill-gray-500
              text-xs
              cursor-pointer
              hover:bg-gray-100
              py-1
              px-2
            "
            @click="openInNewTab"
          >
            <div>
              <SynIcon name="open-link" custom-class="w-4 h-4"></SynIcon>
            </div>
            <div>{{ $t('CHAT_OPENT_LINK') }}</div>
          </div>
          <div
            class="
              flex
              items-center
              border-t
              space-x-2
              text-red-500
              fill-red-500
              text-xs
              cursor-pointer
              hover:bg-gray-100
              py-1
              px-2
            "
            @click.stop="($event) => onClosePreviewLink(link, $event)"
          >
            <div>
              <SynIcon name="trash" custom-class="w-4 h-4"></SynIcon>
            </div>
            <div>{{ $t('CHAT_HIDE_PREVIEW_LINK') }}</div>
          </div>
        </div>
      </template>
    </VigDropdown>
  </a>
</template>

<style scoped>
.hover-to-show__action {
  position: relative;
}

.hover-to-show__action:hover .hover-to-show__action_children {
  display: flex;
}

.hover-to-show__action_children {
  display: none;
}
</style>
