<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="142.167"
    height="103.663"
    viewBox="0 0 142.167 103.663"
  >
    <g id="Group_4653" data-name="Group 4653" transform="translate(0 -3.25)">
      <path
        id="Path_1665"
        data-name="Path 1665"
        d="M142.167,17.253V44.206a2.962,2.962,0,1,1-5.924,0V17.253a8.092,8.092,0,0,0-8.08-8.08H14a8.092,8.092,0,0,0-8.08,8.08V54.406A8.092,8.092,0,0,0,14,62.486h57.08a2.962,2.962,0,1,1,0,5.924H14a14.021,14.021,0,0,1-14-14V17.253a14.021,14.021,0,0,1,14-14h114.16a14.021,14.021,0,0,1,14,14Zm-100.494,8.1a2.962,2.962,0,0,0-4.188,0l-6.279,6.285-6.285-6.285a2.962,2.962,0,1,0-4.188,4.188l6.285,6.285-6.285,6.285A2.962,2.962,0,1,0,24.921,46.3l6.285-6.285L37.485,46.3a2.962,2.962,0,1,0,4.188-4.188L35.394,35.83l6.279-6.285A2.962,2.962,0,0,0,41.673,25.357ZM83.4,44.206a2.962,2.962,0,0,0-2.962-2.962H61.724a2.962,2.962,0,0,0,0,5.924H80.443A2.962,2.962,0,0,0,83.4,44.206Zm58.762,30.128a32.58,32.58,0,1,1-32.58-32.58A32.58,32.58,0,0,1,142.167,74.333Zm-5.924,0a26.656,26.656,0,1,0-26.656,26.656A26.656,26.656,0,0,0,136.243,74.333ZM120.569,63.375a2.962,2.962,0,0,0-4.194,0l-6.788,6.771L102.8,63.375A2.966,2.966,0,1,0,98.6,67.569l6.794,6.788L98.6,81.146a2.966,2.966,0,0,0,4.194,4.194l6.788-6.818,6.788,6.771a2.966,2.966,0,1,0,4.194-4.194l-6.794-6.765,6.794-6.812A2.962,2.962,0,0,0,120.569,63.375Z"
        fill="#ef4444"
      />
    </g>
  </svg>
</template>
