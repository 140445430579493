<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
// import { v4 as uuidv4 } from 'uuid';
import SynInput from '@/ui/common/atoms/SynInput/SynInput.vue';
import SynCheckboxTag from '@/ui/common/molecules/SynCheckbox/SynCheckboxTag.vue';
import SynTag from '@/ui/common/atoms/SynTag.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import SelectMultiDomain from '@/ui/modules/task/filter/SelectMultiDomain.vue';
import SelectMultiGroup from '@/ui/modules/task/filter/SelectMultiGroup.vue';
import ListSelectMultiGroup from '@/ui/modules/task/filter/ListSelectMultiGroup.vue';
import SelectMultiUser from '@/ui/modules/task/filter/SelectMultiUser.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import {
  CustomFilters,
  ICustomTaskFilter,
} from '@/application/interfaces/tasks/task-interfaces';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import { taskStates, TaskUrgency } from '@/ui/hooks/taskHook';
import domainStore from '@/store/scope';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = withDefaults(
  defineProps<{
    sourceType: 'U' | 'G' | 'D';
    listTaskOwnerId?: number;
    currentTabDetail: ICustomTaskFilter;
    originMode?: 'VIEW' | 'EDIT' | 'REMOVE';
  }>(),
  {
    listTaskOwnerId: 0,
    originMode: 'VIEW',
  }
);

const emit = defineEmits([
  'onClose',
  'changeMode',
  'onDeleteSuccess',
  'onUpdateSuccess',
]);

const _taskTabViewStore = taskTabViewStore();
const _domainStore = domainStore();
const _userStore = userStore();
const _groupStore = groupStore();

const isOpenAddNewTab = ref(false);
const selectedGroupIds = ref(
  props.currentTabDetail?.filterObject?.groupIds
    ?.split(',')
    ?.filter((item) => item?.trim()) || []
);
const selectedDomainIds = ref(
  props.currentTabDetail?.filterObject?.domainIds
    ?.split(',')
    ?.filter((item) => item?.trim()) || []
);
const selectedUserIds = ref(
  props.currentTabDetail?.filterObject?.creatorIds
    ?.split(',')
    ?.filter((item) => item?.trim()) || []
);
const haveSupplement = ref(false);
const inputNameTabRef = ref(null as any);
const urgencyList = ref(cloneDeep(Object.values(TaskUrgency)) as any[]);

const {
  validationResult: validationTabName,
  onValidation: onValidationTabName,
  reset: onResetValidationTabName,
} = atomFormInputComposables([
  (value) => {
    return value?.trim() ? '' : 'TASK_CUSTOM_FILTER_NAME_IS_NOT_NULL';
  },
]);

const onBlurTabName = () => {
  onValidationTabName(currentTabPayload.value?.name);
};
const onUpdateTabName = () => {
  onResetValidationTabName();
};

const statusList = ref(
  cloneDeep(
    Object.values(taskStates).sort((a, b) => {
      return a.index - b.index;
    })
  )
);
const filterDateObj = ref({
  createdFromDate: null,
  createdToDate: null,
  updatedFromDate: null,
  updatedToDate: null,
});

onMounted(() => {
  console.log(
    '🚀 Hyrin ~ statusList.value.forEach ~ props.currentTabDetail?.filterObject?.status:',
    props.currentTabDetail?.filterObject?.status
  );
  statusList.value.forEach((st) => {
    st.isChecked =
      props.currentTabDetail?.filterObject?.status
        ?.split(',')
        ?.map((k) => parseInt(k))
        ?.includes(st?.key) ||
      props.currentTabDetail?.filterObject?.status == st?.key;
  });
  urgencyList.value.forEach((ul) => {
    ul.isChecked =
      props.currentTabDetail?.filterObject?.urgency
        ?.split(',')
        ?.map((k) => parseInt(k))
        ?.includes(ul?.key) ||
      props.currentTabDetail?.filterObject?.urgency == ul?.key;
  });

  currentMode.value = props.originMode;
});
const filterType = computed(() => {
  const urgency: any[] = urgencyList.value
    ?.filter((o) => o.isChecked)
    .map((o) => o.key);
  const status: any[] = statusList.value?.filter((o) => o.isChecked);
  return {
    STATUS: {
      isAny: status?.length <= 0,
      key: 'status',
    },
    URGENCY: {
      isAny: urgency?.length <= 0,
      key: 'urgency',
    },
    GROUP: {
      isAny: selectedGroupIds.value?.length <= 0,
      key: 'group',
    },
    DOMAIN: {
      isAny: selectedDomainIds.value?.length <= 0,
      key: 'domain',
    },
  };
});

const filterMoreNumber = computed(() => {
  let number = 0;
  if (selectedGroupIds?.value?.length) number++;
  if (filterDateObj?.value?.createdFromDate) number++;
  if (filterDateObj?.value?.createdToDate) number++;
  if (filterDateObj?.value?.updatedFromDate) number++;
  if (filterDateObj?.value?.updatedToDate) number++;
  return number;
});

const onChangeAnyCheckBox = (key) => {
  filterType.value[key].isAny = !filterType.value[key].isAny;
  if (!filterType.value[key].isAny) return;
  switch (key) {
    case filterType.value.STATUS.key:
      statusList.value = statusList.value.map((o) => {
        o.isChecked = false;
        return o;
      });
      break;
    case filterType.value.DOMAIN.key:
      selectedDomainIds.value = [];
      break;
    case filterType.value.GROUP.key:
      selectedGroupIds.value = [];
      break;
    case filterType.value.URGENCY.key:
      urgencyList.value = urgencyList.value.map((o) => {
        o.isChecked = false;
        return o;
      });
      break;
    default:
      break;
  }
};

const addTabsModalRef = ref<any>(null);

const isDisabledSave = computed<boolean>(() => {
  return (
    !currentTabPayload.value?.name ||
    (selectedGroupIds.value?.length == 0 &&
      selectedDomainIds.value?.length == 0 &&
      urgencyList.value?.filter((o) => o.isChecked)?.length == 0 &&
      selectedUserIds.value?.length == 0 &&
      statusList.value?.filter((o) => o.isChecked)?.length == 0)
  );
});

const currentTabPayload = ref<ICustomTaskFilter>(props.currentTabDetail);

const onUpdate = () => {
  if (!props.listTaskOwnerId) return;

  const selectedGroupIdString = selectedGroupIds.value.toString();
  const groupIds =
    props.sourceType === 'G'
      ? selectedGroupIdString
        ? `${selectedGroupIdString},${props.listTaskOwnerId}`
        : `${props.listTaskOwnerId}`
      : selectedGroupIdString;
  const selectedDomainIdString = selectedDomainIds.value.toString();
  const domainIds =
    props.sourceType === 'D'
      ? selectedDomainIdString
        ? `${selectedDomainIdString},${props.listTaskOwnerId}`
        : `${props.listTaskOwnerId}`
      : selectedDomainIdString;

  const filterPayload = {
    isDefault: false,
    listOwnerId: props.sourceType === 'U' ? props.listTaskOwnerId : null,
    urgency: urgencyList.value
      .filter((o) => o.isChecked)
      .map((o) => o.key)
      .toString(),
    groupIds,
    domainIds,
    creatorIds: selectedUserIds.value.toString(),
    status: statusList.value
      .filter((o) => o.isChecked)
      .map((o) => o.key)
      .toString(),
    assignorId: null,
    domainId: null,
    assigneeIds: '',
    createdFromDate: null,
    createdToDate: null,
    updatedFromDate: null,
    updatedToDate: null,
    keyword: '',
  } as CustomFilters;
  console.log('filterPayload', filterPayload);
  // onFilterMyTasks(filterPayload);

  _taskTabViewStore.updateUserCustomTaskFilterFilter(
    props.sourceType || 'U',
    props.listTaskOwnerId || 0,
    {
      id: currentTabPayload.value?.id,
      name: currentTabPayload.value?.name,
      index: 0,
      color: '',
      visible: true,
      filterObject: filterPayload,
    }
  );

  addTabsModalRef.value?.onForceClose && addTabsModalRef.value?.onForceClose();
  emit('onUpdateSuccess');
};

const onCancel = () => {
  isOpenAddNewTab.value = false;
  clearData();
  onChangeMode('VIEW');

  emit('onClose');
};

const clearData = () => {
  currentTabPayload.value = props.currentTabDetail;
  urgencyList.value = urgencyList.value.map((o) => {
    o.isChecked = false;
    return o;
  });
  statusList.value = statusList.value.map((o) => {
    o.isChecked = false;
    return o;
  });
  selectedGroupIds.value = [];
  selectedDomainIds.value = [];
  selectedUserIds.value = [];

  filterDateObj.value = {
    createdFromDate: null,
    createdToDate: null,
    updatedFromDate: null,
    updatedToDate: null,
  };
};

const currentMode = ref<'VIEW' | 'EDIT' | 'REMOVE'>('VIEW');
const allDomainByIds = computed(() => _domainStore.domainByIds);
const allUserByIds = computed(() => _userStore.allUserByIds);
const allGroupByIds = computed(() => _groupStore.myGroupByIds);

const onChangeMode = (mode: 'VIEW' | 'EDIT' | 'REMOVE') => {
  currentMode.value = mode;
  emit('changeMode', mode);
};

const onRemove = async () => {
  if (!props.listTaskOwnerId) return;

  onChangeMode('REMOVE');

  settingGlobalModal({
    type: 'notification',
    title: '',
    content:
      translate('TASK_CUSTOM_FILTER_CONFIRM_REMOVE') ||
      'Do you want to remove this tab',
    confirmLabel: translate('COMMON_LABEL_CONFIRM'),
    confirmable: true,
    closeable: true,
    cancelLabel: translate('COMMON_LABEL_CANCEL'),
  });
  const answer = await ask();
  if (answer) {
    _taskTabViewStore.deleteUserCustomTaskFilter(
      props.sourceType || 'U',
      props.listTaskOwnerId,
      props.currentTabDetail?.id
    );

    emit('onDeleteSuccess');
  }
};
</script>

<template>
  <div
    v-if="currentMode === 'VIEW' || currentMode === 'REMOVE'"
    class="flex flex-col rounded"
    style="width: 26rem; max-height: 40rem"
  >
    <!-- <span class="font-medium text-md text-gray-800 px-4 pt-4">{{
      $t('TASK_LIST_CREATE_NEW_TAB_LABEL')
    }}</span> -->

    <!-- NAME  -->
    <div class="flex flex-col space-y-1 pt-5 px-4 pb-2">
      <span class="text-xs tex-gray-300 font-medium">{{
        $t('TASK_LIST_CREATE_TAB_NAME_LABEL')
      }}</span>

      <span class="px-4 py-2 rounded border border-gray-100 bg-gray-50">
        {{ currentTabDetail?.name }}
      </span>
    </div>
    <!-- END NAME  -->

    <section
      class="
        flex flex-col
        space-y-1
        overflow-y-auto
        small-scrollbar
        px-4
        h-full
      "
    >
      <!--  STATUS    -->
      <div class="flex flex-col pt-2">
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('COMMON_LABEL_STATUS')
          }}</span>
          <div
            v-if="filterType.STATUS.isAny"
            class="text-xs flex-center space-x-2 cursor-pointer"
          >
            <SynIcon
              name="checkbox"
              :is-active="filterType.STATUS.isAny"
              class="fill-current"
              custom-class="w-3 h-3"
            />
            <span>{{ $t('COMMON_LABEL_ANY') }}</span>
          </div>
        </div>
        <div
          v-if="statusList?.length > 0 && !filterType.STATUS.isAny"
          class="flex flex-wrap pt-1"
        >
          <SynCheckboxTag
            v-for="item in statusList?.filter((st) => st.isChecked)"
            :key="item"
            :model-value="true"
            class="mr-1 mb-1"
          >
            <SynTag
              size="circle-small "
              :custom-class="'flex-center space-x-2 px-2 py-1 text-sm cursor-default'"
            >
              <span
                class="p-1 rounded-full"
                :class="`bg-${item?.colorKey}`"
              ></span>
              <span>{{ $t(item?.nameCode) }}</span>
            </SynTag>
          </SynCheckboxTag>
        </div>
      </div>
      <!--  END STATUS    -->

      <!--  URGENCY    -->
      <div class="flex flex-col space-y-1 pt-4">
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TASK_TABLE_LABEL_URGENCY')
          }}</span>
          <div
            v-if="filterType.URGENCY.isAny"
            class="text-xs flex-center space-x-2 cursor-pointer"
          >
            <SynIcon
              name="checkbox"
              :is-active="filterType.URGENCY.isAny"
              class="fill-current"
              custom-class="w-3 h-3"
            />
            <span>{{ $t('COMMON_LABEL_ANY') }}</span>
          </div>
        </div>

        <div
          v-if="urgencyList?.length > 0 && !filterType.URGENCY.isAny"
          class="flex flex-wrap pt-1"
        >
          <SynCheckboxTag
            v-for="item in urgencyList?.filter((ul) => ul?.isChecked)"
            :key="item"
            :model-value="true"
            class="mr-1 mb-1"
          >
            <SynTag
              size="circle-small"
              :custom-class="'flex-center space-x-2 px-2 py-1 text-sm cursor-default'"
            >
              <PriorityTask :urgency="item.key" size="mini" />
            </SynTag>
          </SynCheckboxTag>
        </div>
      </div>
      <!--  END URGENCY    -->

      <!--  DOMAIN  -->
      <div class="flex flex-col space-y-1 pt-4">
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TASK_FILTER_LABEL_SCOPE')
          }}</span>
          <div
            v-if="filterType.DOMAIN.isAny"
            class="text-xs flex-center space-x-2"
          >
            <SynIcon
              name="checkbox"
              :is-active="filterType.DOMAIN.isAny"
              class="fill-current"
              custom-class="w-3 h-3"
            />
            <span>{{ $t('COMMON_LABEL_ANY') }}</span>
          </div>
        </div>
        <SynCheckboxTag
          v-for="domainId in selectedDomainIds"
          :key="domainId"
          :model-value="!!domainId"
          class="mr-1 mb-1"
        >
          <div
            class="
              rounded-full
              text-xs
              px-2
              py-1
              flex-center
              w-max
              float-left
              cursor-default
            "
          >
            <span>{{ allDomainByIds[domainId]?.name }}</span>
          </div>
        </SynCheckboxTag>
      </div>
      <!--  END DOMAIN  -->

      <!--  CREATOR   -->
      <div
        v-if="selectedUserIds?.length > 0"
        class="flex flex-col space-y-1 pt-4"
      >
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TASK_TABLE_LABEL_CREATOR')
          }}</span>
        </div>
        <div
          v-for="userId of selectedUserIds"
          :key="userId"
          class="
            rounded-full
            bg-current-50
            text-gray-800 text-sm
            flex-center
            space-x-2
            w-max
            p-1
            m-0.5
            float-left
          "
        >
          <syn-avatar
            custom-class="w-5 h-5"
            :src="allUserByIds[userId]?.avatar?.replace('original', 'mini')"
            :name="allUserByIds[userId]?.name"
          />
          <span class="truncate" style="max-width: 8rem">
            {{ allUserByIds[userId]?.name }}
          </span>
        </div>
      </div>
      <!--  END CREATOR   -->

      <!--  GROUP  -->
      <div v-if="sourceType !== 'G'" class="flex flex-col space-y-1 pt-4">
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('COMMON_LABEL_GROUPS')
          }}</span>
          <div
            v-if="filterType.GROUP.isAny"
            class="text-xs flex-center space-x-2"
          >
            <SynIcon
              name="checkbox"
              :is-active="filterType.GROUP.isAny"
              class="fill-current"
              custom-class="w-3 h-3"
            />
            <span>{{ $t('COMMON_LABEL_ANY') }}</span>
          </div>
        </div>
        <SynCheckboxTag
          v-for="groupId in selectedGroupIds"
          :key="groupId"
          :model-value="true"
          class="p-1"
        >
          <div
            class="
              rounded-full
              text-xs
              p-1
              m-0.5
              flex-center
              space-x-2
              w-max
              float-left
              cursor-default
            "
          >
            <SynAvatar
              custom-class="w-5 h-5"
              is-hidden-name
              :src="allGroupByIds[groupId]?.avatar?.replace('original', 'mini')"
              :name="allGroupByIds[groupId]?.name"
            />
            <span class="truncate" style="max-width: 8rem">
              {{ allGroupByIds[groupId]?.name }}
            </span>
          </div>
        </SynCheckboxTag>
      </div>
      <!--  END GROUP  -->

      <!--  CREATE DATE  -->
      <div
        v-if="
          currentTabDetail?.filterObject?.createdFromDate ||
          currentTabDetail?.filterObject?.createdToDate
        "
        class="flex flex-col space-y-1 pt-4"
      >
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TICKET_TASK_FORM_LABEL_CREATE_DATE')
          }}</span>
        </div>
        <div class="flex space-x-2 pt-1">
          <div
            v-if="currentTabDetail?.filterObject?.createdFromDate"
            class="flex-1"
          >
            <span>
              {{ $t('CHECKIN_LABEL_FROM') || 'Date from' }}
            </span>
            <SynLabelDateTime
              format="date"
              :datetime="currentTabDetail?.filterObject?.createdFromDate"
            />
          </div>
          <div
            v-if="currentTabDetail?.filterObject?.createdToDate"
            class="flex-1"
          >
            <span>
              {{ $t('GED_FILTER_DATE_TO') || 'to' }}
            </span>
            <SynLabelDateTime
              format="date"
              :datetime="currentTabDetail?.filterObject?.createdToDate"
            />
          </div>
        </div>
      </div>
      <!--  CREATE DATE END  -->

      <!--  UPDATE DATE  -->
      <div
        v-if="
          currentTabDetail?.filterObject?.updatedFromDate ||
          currentTabDetail?.filterObject?.updatedToDate
        "
        class="flex flex-col pt-4 pb-4"
      >
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TICKET_TASK_FORM_LABEL_UPDATE_DATE')
          }}</span>
        </div>
        <div class="flex space-x-2 pt-1">
          <div
            v-if="currentTabDetail?.filterObject?.updatedFromDate"
            class="flex-1"
          >
            <span>
              {{ $t('CHECKIN_LABEL_FROM') || 'Date from' }}
            </span>
            <SynLabelDateTime
              format="date"
              :datetime="currentTabDetail?.filterObject?.updatedFromDate"
            />
          </div>
          <div
            v-if="currentTabDetail?.filterObject?.updatedToDate"
            class="flex-1"
          >
            <span>
              {{ $t('GED_FILTER_DATE_TO') || 'to' }}
            </span>
            <SynLabelDateTime
              format="date"
              :datetime="currentTabDetail?.filterObject?.updatedToDate"
            />
          </div>
        </div>
      </div>
      <!--  UPDATE DATE END  -->
    </section>

    <!--  ACTION      -->
    <section class="flex items-center space-x-2 justify-end py-4 px-4">
      <SynButton
        :label="$t('COMMON_LABEL_DELETE')"
        color="red"
        @click="onRemove"
      />
      <SynButton
        :label="$t('COMMON_LABEL_EDIT')"
        @click="onChangeMode('EDIT')"
      />
    </section>
  </div>
  <div
    v-else
    class="flex flex-col rounded relative"
    style="width: 26rem; max-height: 40rem"
  >
    <button
      :title="$t('COMMON_LABEL_CANCEL')"
      class="
        w-8
        h-8
        bg-gray-200
        rounded-full
        cursor-pointer
        hover:text-current-500 hover:bg-gray-100
        absolute
        top-0
        right-0
        flex-center
        vig-modal-close
        do-not-close-dropdown
        z-50
      "
      @click="onCancel"
    >
      <SynIcon name="close" custom-class="w-4 h-4 do-not-close-dropdown" />
    </button>
    <span class="font-medium text-md text-gray-800 px-4 pt-4">{{
      $t('TASK_LIST_CREATE_NEW_TAB_LABEL')
    }}</span>

    <!-- NAME  -->
    <div class="flex flex-col space-y-1 pt-5 px-4 pb-2">
      <span class="text-xs tex-gray-300 font-medium">{{
        $t('TASK_LIST_CREATE_TAB_NAME_LABEL')
      }}</span>

      <SynFormInput
        :is-valid="validationTabName.isValid"
        :error-message="validationTabName.message"
      >
        <SynInput
          ref="inputNameTabRef"
          v-model="currentTabPayload.name"
          :placeholder="$t('TASK_LIST_CREATE_TAB_NAME_LABEL')"
          class="font-medium pt-1"
          autofocus
          @blur="onBlurTabName"
          @update:model-value="onUpdateTabName"
        />
      </SynFormInput>
    </div>
    <!-- END NAME  -->

    <section class="flex flex-col overflow-y-auto small-scrollbar px-4 h-full">
      <!--  STATUS    -->
      <div class="flex flex-col space-y-1 pt-2">
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('COMMON_LABEL_STATUS')
          }}</span>
          <div
            class="text-xs flex-center space-x-2 cursor-pointer"
            @click="onChangeAnyCheckBox(filterType.STATUS.key)"
          >
            <SynIcon
              name="checkbox"
              :is-active="filterType.STATUS.isAny"
              class="fill-current"
              custom-class="w-3 h-3"
            />
            <span>{{ $t('COMMON_LABEL_ANY') }}</span>
          </div>
        </div>
        <div v-if="statusList?.length > 0" class="flex flex-wrap pt-1">
          <SynCheckboxTag
            v-for="item in statusList"
            :key="item"
            v-model="item.isChecked"
            class="mr-1 mb-1"
          >
            <SynTag
              size="circle-small"
              :custom-class="
                item.isChecked
                  ? 'flex-center space-x-2 px-2 py-1 text-sm border border-current-50 '
                  : 'flex-center space-x-2 px-2 py-1 text-sm border border-current-50  hover:bg-current-50 hover:text-current'
              "
            >
              <span
                class="p-1 rounded-full"
                :class="`bg-${item?.colorKey}`"
              ></span>
              <span>{{ $t(item?.nameCode) }}</span>
            </SynTag>
          </SynCheckboxTag>
        </div>
      </div>
      <!--  END STATUS    -->

      <!--  URGENCY    -->
      <div class="flex flex-col space-y-1 pt-4">
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TASK_TABLE_LABEL_URGENCY')
          }}</span>
          <div
            class="text-xs flex-center space-x-2 cursor-pointer"
            @click="onChangeAnyCheckBox(filterType.URGENCY.key)"
          >
            <SynIcon
              name="checkbox"
              :is-active="filterType.URGENCY.isAny"
              class="fill-current"
              custom-class="w-3 h-3"
            />
            <span>{{ $t('COMMON_LABEL_ANY') }}</span>
          </div>
        </div>

        <div v-if="urgencyList?.length > 0" class="flex flex-wrap pt-1">
          <SynCheckboxTag
            v-for="item in urgencyList"
            :key="item"
            v-model="item.isChecked"
            class="mr-1 mb-1"
          >
            <SynTag
              size="circle-small"
              :custom-class="
                item.isChecked
                  ? 'flex-center space-x-2 px-2 py-1 text-sm border border-current-50 '
                  : 'flex-center space-x-2 px-2 py-1 text-sm border border-current-50  hover:bg-current-50 hover:text-current'
              "
            >
              <PriorityTask :urgency="item.key" size="mini" />
            </SynTag>
          </SynCheckboxTag>
        </div>
      </div>
      <!--  END URGENCY    -->

      <!--  DOMAIN  -->
      <div class="flex flex-col space-y-1 pt-4">
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TASK_FILTER_LABEL_SCOPE')
          }}</span>
          <div
            class="text-xs flex-center space-x-2 cursor-pointer"
            @click="onChangeAnyCheckBox(filterType.DOMAIN.key)"
          >
            <SynIcon
              name="checkbox"
              :is-active="filterType.DOMAIN.isAny"
              class="fill-current"
              custom-class="w-3 h-3"
            />
            <span>{{ $t('COMMON_LABEL_ANY') }}</span>
          </div>
        </div>
        <SelectMultiDomain
          v-model="selectedDomainIds"
          class="mt-1"
          :list-task-owner-id="listTaskOwnerId"
        />
      </div>
      <!--  END DOMAIN  -->

      <!--  CREATOR   -->
      <div class="flex flex-col space-y-1 pt-4">
        <div class="flex items-center justify-between">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TASK_TABLE_LABEL_CREATOR')
          }}</span>
        </div>
        <SelectMultiUser v-model="selectedUserIds" />
      </div>
      <!--  END CREATOR   -->

      <div
        class="
          flex-center
          space-x-1
          text-xs text-gray-500
          cursor-pointer
          rounded
          px-2
          py-1
          my-3
          hover:bg-gray-50 hover:text-current-500 hover:fill-current-500
          fill-gray-500
        "
        :class="haveSupplement ? 'border-b border-gray-100' : ''"
        @click="haveSupplement = !haveSupplement"
      >
        <span>{{
          haveSupplement ? 'Hide Information' : 'More Information'
        }}</span>
        <template v-if="!haveSupplement">
          <span
            v-if="filterMoreNumber > 0"
            class="px-1 rounded-full bg-current-400 text-white"
            style="font-size: 0.6rem"
            >{{ filterMoreNumber }}</span
          >
        </template>
        <SynIcon
          name="arrow-right"
          :class="haveSupplement ? 'rotate-90' : ''"
        />
      </div>

      <template v-if="haveSupplement">
        <!--  GROUP  -->
        <div v-if="sourceType !== 'G'" class="flex flex-col pt-4">
          <div class="flex items-center justify-between">
            <span class="text-xs tex-gray-300 font-medium">{{
              $t('COMMON_LABEL_GROUPS')
            }}</span>
            <div
              class="text-xs flex-center space-x-2 cursor-pointer"
              @click="onChangeAnyCheckBox(filterType.GROUP.key)"
            >
              <SynIcon
                name="checkbox"
                :is-active="filterType.GROUP.isAny"
                class="fill-current"
                custom-class="w-3 h-3"
              />
              <span>{{ $t('COMMON_LABEL_ANY') }}</span>
            </div>
          </div>
          <SelectMultiGroup
            v-model="selectedGroupIds"
            class="mt-1"
            :list-task-owner-id="listTaskOwnerId"
            :max-item-show="6"
          />
          <ListSelectMultiGroup
            v-model="selectedGroupIds"
            class="mt-1"
            :list-task-owner-id="listTaskOwnerId"
            :min-item-show="6"
          />
        </div>
        <!--  END GROUP  -->

        <!--  CREATE DATE  -->
        <div class="flex flex-col pt-4">
          <div class="flex items-center justify-between">
            <span class="text-xs tex-gray-300 font-medium">{{
              $t('TICKET_TASK_FORM_LABEL_CREATE_DATE')
            }}</span>
          </div>
          <div class="flex space-x-2 pt-1">
            <div class="flex-1">
              <syn-date-input
                v-model="filterDateObj.createdFromDate"
                input-class="text-sm"
                :placeholder="$t('CHECKIN_LABEL_FROM') || 'Date from'"
              />
            </div>
            <div class="flex-1">
              <syn-date-input
                v-model="filterDateObj.createdToDate"
                input-class="text-sm"
                :placeholder="$t('GED_FILTER_DATE_TO') || 'to'"
              />
            </div>
          </div>
        </div>
        <!--  CREATE DATE END  -->

        <!--  UPDATE DATE  -->
        <div class="flex flex-col pt-4 pb-4">
          <div class="flex items-center justify-between">
            <span class="text-xs tex-gray-300 font-medium">{{
              $t('TICKET_TASK_FORM_LABEL_UPDATE_DATE')
            }}</span>
          </div>
          <div class="flex space-x-2 pt-1">
            <div class="flex-1">
              <syn-date-input
                v-model="filterDateObj.updatedFromDate"
                input-class="text-sm"
                :placeholder="$t('CHECKIN_LABEL_FROM') || 'Date from'"
              />
            </div>
            <div class="flex-1">
              <syn-date-input
                v-model="filterDateObj.updatedToDate"
                input-class="text-sm"
                :placeholder="$t('GED_FILTER_DATE_TO') || 'to'"
              />
            </div>
          </div>
        </div>
        <!--  UPDATE DATE END  -->
      </template>
    </section>

    <!--  ACTION      -->
    <section class="flex items-center space-x-2 justify-end py-4 px-4">
      <SynButton
        :label="$t('COMMON_LABEL_CANCEL')"
        type-text
        color="gray"
        @click="onCancel"
      />
      <SynButton
        :label="$t('COMMON_LABEL_SAVE')"
        :disabled="isDisabledSave"
        @click="onUpdate"
      />
    </section>
  </div>
</template>
