<template>
  <div class="flex flex-col justify-center items-center">
    <div v-if="slides && slides > 0" class="w-full mx-auto relative">
      <!-- Slides -->
      <template v-for="slide in slides" :key="slide">
        <div v-if="activeSlide === slide" class="">
          <slot></slot>
        </div>
      </template>
      <!-- Prev/Next Arrows -->
      <div class="absolute inset-0 flex items-center justify-start w-10">
        <div
          class="
            flex-center
            bg-gray-100
            text-gray-500
            hover:text-orange-500
            font-bold
            hover:shadow-lg
            rounded-full
            w-12
            h-12
            -ml-6
            cursor-pointer
          "
          @click="gotoBefore"
        >
          <SynIcon name="before" />
        </div>
      </div>
      <div
        class="absolute inset-y-0 right-0 flex items-center justify-end w-10"
      >
        <div
          class="
            flex-center
            bg-gray-100
            text-gray-500
            hover:text-orange-500
            font-bold
            hover:shadow
            rounded-full
            w-12
            h-12
            -mr-6
            cursor-pointer
          "
          @click="gotoNext"
        >
          <SynIcon name="next" />
        </div>
      </div>

      <!-- Buttons -->
      <div class="w-1/2 flex items-center justify-center px-4 mx-auto mt-2">
        <template v-for="slide in slides" :key="slide">
          <div
            class="
              flex-1 flex
              items-center
              justify-center
              m-2
              rounded-full
              overflow-hidden
              cursor-pointer
              transition-colors
              duration-200
              ease-out
              hover:bg-current-500 hover:shadow-lg
            "
            :class="{
              'bg-current text-white': activeSlide === slide,
              'bg-gray-200': activeSlide !== slide,
            }"
            @click="changeSide(slide)"
          >
            <small>{{ slide }}</small>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    slides: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  emits: ['changeSide'],
  setup: (props, { emit }) => {
    const activeSlide = ref(1);
    const gotoBefore = () => {
      activeSlide.value =
        activeSlide.value === 1 ? props.slides : activeSlide.value - 1;
      emit('changeSide', activeSlide.value);
    };
    const gotoNext = () => {
      activeSlide.value =
        activeSlide.value === props.slides ? 1 : activeSlide.value + 1;
      emit('changeSide', activeSlide.value);
    };
    const changeSide = (slide) => {
      activeSlide.value = slide;
      emit('changeSide', activeSlide.value);
    };
    return {
      activeSlide,
      gotoBefore,
      gotoNext,
      changeSide,
    };
  },
};
</script>
