<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0,0,256,256"
  >
    <defs>
      <linearGradient
        id="color-1_v0YYnU84T2c4_gr1"
        x1="-0.963"
        y1="2.951"
        x2="29.619"
        y2="33.532"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#55adfd"></stop>
        <stop offset="1" stop-color="#438ffd"></stop>
      </linearGradient>
      <linearGradient
        id="color-2_v0YYnU84T2c4_gr2"
        x1="36.523"
        y1="7.477"
        x2="31.001"
        y2="12.999"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#427fdb"></stop>
        <stop offset="1" stop-color="#0c52bb"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(-32,-32) scale(1.25,1.25)">
      <g
        fill="none"
        fill-rule="none"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.33333,5.33333)">
          <path
            d="M39.001,13.999v27c0,1.105 -0.896,2 -2,2h-26c-1.105,0 -2,-0.895 -2,-2v-34c0,-1.104 0.895,-2 2,-2h19l2,7z"
            fill="url(#color-1_v0YYnU84T2c4_gr1)"
            fill-rule="nonzero"
          ></path>
          <path
            d="M15.999,18.001v2.999h17.002v-2.999z"
            fill="#ffffff"
            fill-rule="evenodd"
          ></path>
          <path
            d="M16.001,24.001v2.999h17.002v-2.999z"
            fill="#ffffff"
            fill-rule="evenodd"
          ></path>
          <path
            d="M15.999,30.001v2.999h12.001v-2.999z"
            fill="#ffffff"
            fill-rule="evenodd"
          ></path>
          <path
            d="M30.001,13.999l0.001,-9l8.999,8.999z"
            fill="url(#color-2_v0YYnU84T2c4_gr2)"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
