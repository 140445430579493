<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512.002 512.002"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="m499.992 363.738v-182.848c0-23.958-19.422-43.381-43.381-43.381-23.959 0-43.381 19.422-43.381 43.381v44.885l-117.541-117.541c-14.686-14.686-38.496-14.686-53.181 0-14.686 14.686-14.686 38.495 0 53.181l-26.846-26.846c-14.686-14.686-38.496-14.686-53.181 0-14.686 14.686-14.686 38.496 0 53.181l15.543 15.543c-14.686-14.685-38.496-14.685-53.181 0-14.686 14.686-14.686 38.496 0 53.181l38.356 38.356c-14.686-14.686-38.496-14.686-53.181 0-14.686 14.686-14.686 38.496 0 53.181l118.403 118.403c60.782 60.782 159.329 60.782 220.111 0l15.254-15.254c24.14-24.139 36.209-55.778 36.209-87.417-.003 0-.003-.003-.003-.005z"
          fill="#f6ee86"
          data-original="#f6ee86"
        ></path>
        <g fill="#eed076">
          <path
            d="m168.719 300.354-5.523-5.523-13.673-8.726c-13.315-4.86-28.825-1.957-39.509 8.726-14.686 14.686-14.686 38.496 0 53.181 0 0 47.084 47.084 73.117 73.117-23.792-41.197-28.58-92.307-14.412-120.775z"
            fill="#eed076"
            data-original="#eed076"
          ></path>
          <path
            d="m124.84 256.475 20.593 20.593c-3.691-11.43-12.71-57.347 3.183-84.673-8.665.647-17.149 4.273-23.775 10.899-14.686 14.685-14.686 38.495-.001 53.181z"
            fill="#eed076"
            data-original="#eed076"
          ></path>
          <path
            d="m315.387 241.795c-1.919 0-3.839-.733-5.304-2.197l-72.881-72.88c-2.929-2.929-2.929-7.678 0-10.606 2.93-2.929 7.678-2.929 10.607 0l72.881 72.88c2.929 2.929 2.929 7.678 0 10.607-1.464 1.464-3.384 2.196-5.303 2.196z"
            fill="#eed076"
            data-original="#eed076"
          ></path>
          <path
            d="m263.061 295.833c-1.919 0-3.839-.733-5.304-2.197l-85.04-85.04c-2.929-2.929-2.929-7.678 0-10.606 2.93-2.93 7.678-2.928 10.607 0l85.04 85.039c2.929 2.929 2.929 7.678 0 10.607-1.464 1.464-3.383 2.197-5.303 2.197z"
            fill="#eed076"
            data-original="#eed076"
          ></path>
          <path
            d="m219.296 358.43c-1.919 0-3.839-.732-5.304-2.197l-56.1-56.099c-2.929-2.929-2.929-7.678 0-10.606 2.93-2.929 7.678-2.929 10.607 0l56.1 56.099c2.929 2.929 2.929 7.678 0 10.607-1.464 1.463-3.384 2.196-5.303 2.196z"
            fill="#eed076"
            data-original="#eed076"
          ></path>
        </g>
        <g fill="#4e5660">
          <path
            d="m361.53 110.564c-5.13-21.281-22.299-37.868-43.739-42.255-4.058-.831-6.674-4.793-5.844-8.851.83-4.059 4.795-6.674 8.852-5.844 27.114 5.549 48.826 26.523 55.314 53.436.971 4.026-1.507 8.078-5.533 9.048-4.01.967-8.075-1.49-9.05-5.534z"
            fill="#4e5660"
            data-original="#4e5660"
          ></path>
          <path
            d="m415.84 105.496c-11.005-45.653-47.836-81.234-93.83-90.646-4.058-.83-6.674-4.793-5.844-8.851.83-4.058 4.788-6.676 8.852-5.844 51.668 10.573 93.042 50.542 105.404 101.826.971 4.027-1.507 8.078-5.533 9.049-4.017.969-8.076-1.497-9.049-5.534z"
            fill="#4e5660"
            data-original="#4e5660"
          ></path>
        </g>
        <g fill="#4e5660">
          <path
            d="m119.058 436.749c-26.912-6.487-47.887-28.199-53.435-55.314-.83-4.058 1.786-8.021 5.845-8.851 4.053-.833 8.02 1.786 8.851 5.844 4.387 21.44 20.973 38.608 42.255 43.739 4.026.971 6.504 5.022 5.533 9.048-.97 4.025-5.02 6.506-9.049 5.534z"
            fill="#4e5660"
            data-original="#4e5660"
          ></path>
          <path
            d="m113.991 491.058c-51.284-12.362-91.254-53.736-101.827-105.405-.83-4.058 1.786-8.02 5.844-8.851 4.062-.834 8.021 1.786 8.852 5.844 9.412 45.994 44.993 82.825 90.647 93.83 4.026.971 6.504 5.022 5.533 9.048-.97 4.025-5.02 6.506-9.049 5.534z"
            fill="#4e5660"
            data-original="#4e5660"
          ></path>
        </g>
        <path
          d="m375.629 384.698c-2.9 0-5.659-1.69-6.886-4.52-15.847-36.561-12.919-78.647 7.832-112.579 2.161-3.534 6.777-4.648 10.312-2.486 3.533 2.161 4.647 6.777 2.485 10.311-18.206 29.772-20.773 66.703-6.865 98.789 1.646 3.8-.099 8.217-3.899 9.864-.972.421-1.984.621-2.979.621z"
          fill="#eed076"
          data-original="#eed076"
        ></path>
      </g>
    </g>
  </svg>
</template>
