import { computed, ref } from 'vue';
import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import TaskWorkflowEntity from '@/domain/entities/task/TaskWorkflowEntity';
import TaskService from '@/application/services/task/TaskService';

export default function taskDetailWorkflowComposables(taskDetail, options?) {
    const taskWorkflowData = ref<TaskWorkflowEntity | null>(null);
    const initComponent = async (_taskDetail) => {
        if (!_taskDetail) return;

        if (_taskDetail?.taskWorkflow) {
            taskWorkflowData.value = new TaskWorkflowEntity(
                _taskDetail.taskWorkflow
            );
        } else if (_taskDetail.id && _taskDetail.workFlowTaskId) {
            try {
                const res = await TaskService.getInstance().getTaskWorkFlow(
                    _taskDetail.id
                );

                taskWorkflowData.value = new TaskWorkflowEntity(res?.result);
            } catch (e) {
                console.log('🚀 Tictop ~ e:', e);
            }
        }
    };
    const currentTaskStep = computed<ITaskWorkflowStep | undefined>(() => {
        if (
            !taskWorkflowData.value ||
            !taskWorkflowData.value?.steps ||
            taskWorkflowData.value?.steps?.length == 0
        )
            return undefined;
        return (
            taskWorkflowData.value?.steps?.find(
                (o) => o.taskId == taskDetail?.id
            ) || taskWorkflowData.value?.steps[0]
        );
    });
    const currentStepInWorkflow = computed<ITaskWorkflowStep | undefined>(
        () => {
            if (
                !taskWorkflowData.value?.steps ||
                taskWorkflowData.value?.steps?.length == 0 ||
                !taskWorkflowData.value?.currentStepId
            )
                return undefined;
            return taskWorkflowData.value?.steps?.find(
                (o) => o.id == taskWorkflowData.value?.currentStepId
            );
        }
    );

    const stepByIds = computed<any>(() => {
        return taskWorkflowData.value?.steps?.reduce(
            (currentResult, currentItem) => {
                return {
                    ...currentResult,
                    [currentItem?.id]: currentItem,
                };
            },
            {}
        );
    });

    const getStepName = (stepId) => {
        if (
            !stepId ||
            !stepByIds.value ||
            !Object.prototype.hasOwnProperty.call(stepByIds.value, stepId)
        )
            return '';
        return `${stepByIds.value[stepId]?.code}. ${stepByIds.value[stepId]?.name}`;
    };
    if (!options?.doNotNeedInit) initComponent(taskDetail);
    return {
        currentTaskStep,
        taskWorkflowData,
        currentStepInWorkflow,
        stepByIds,
        initComponent,
        getStepName,
    };
}
