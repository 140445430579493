<script setup lang="ts">
import { computed, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import departmentStore from '@/store/department';
import { IDepartmentDetail } from '@/application/types/department/department.types';
import { orderBy } from 'lodash';

const props = defineProps<{
  modelValue: IDepartmentDetail | null;
  isShowNoDepartment?: boolean;
  ignoreVisibleSetting?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', data: IDepartmentDetail): void;
}>();

const _myProfileStore = myProfileStore();
const _departmentStore = departmentStore();

const allDepartmentByIds = computed<{ [key: number]: IDepartmentDetail }>(
  () => {
    return _departmentStore.allDepartmentByIds;
  }
);
const visibleForAllDepartmentIds = computed<number[]>(() => {
  if (props.ignoreVisibleSetting)
    return orderBy(
      Object.keys(_departmentStore.allDepartmentByIds).filter(
        (id) => parseInt(id) !== myDepartment.value?.id
      ),
      [
        (id) => {
          return allDepartmentByIds.value[id]?.departmentUsers?.length;
        },
      ],
      ['asc']
    );
  return orderBy(
    _departmentStore.visibleForAllDepartmentIds.filter(
      (id) => id !== myDepartment.value?.id
    ),
    [
      (id) => {
        return allDepartmentByIds.value[id]?.departmentUsers?.length;
      },
    ],
    ['asc']
  );
});

const myDepartment = computed<IDepartmentDetail>(() => {
  if (!_myProfileStore.myDepartment?.departmentId) return {};
  return allDepartmentByIds.value[_myProfileStore.myDepartment?.departmentId];
});
const isShowMore = ref<boolean>(false);

const departmentActiveInMore = computed<IDepartmentDetail | undefined>(() => {
  return props.modelValue;
});

const onChooseDepartmentId = (departmentId) => {
  emit('update:modelValue', allDepartmentByIds.value[departmentId]);
};
</script>
<template>
  <div class="w-full flex items-center justify-between p-2 space-x-2">
    <slot name="prefix"></slot>

    <div class="flex items-center gap-2">
      <VigDropdown
        :arrow="false"
        placement="bottom-end"
        dropdown-item-class="department-select__dropdown-item"
        @on-dropdown-open="isShowMore = true"
        @on-dropdown-close="isShowMore = false"
      >
        <template #dropdown-toggle>
          <div
            class="
              flex
              items-center
              justify-between
              cursor-pointer
              rounded-md
              py-0.5
              h-9
              pl-1
              pr-2
              gap-2
              hover:bg-current-50
              bg-white
              border border-gray-100
            "
            :class="[
              isShowMore
                ? 'ring-2 ring-current-100 ring-opacity-50 border-current-200 fill-current-400'
                : 'fill-gray-500',
            ]"
          >
            <DepartmentById
              v-if="departmentActiveInMore?.id"
              :department-id="departmentActiveInMore?.id"
              class="text-sm"
            />

            <span v-else class="text-sm pl-2 truncate">
              {{ $t('COMMON_LABEL_WHOLE_ORGANIZATION') }}
            </span>

            <SynIcon
              name="dots-vertical"
              :custom-class="
                departmentActiveInMore?.id || !modelValue?.id
                  ? 'w-4 h-4 rotate-90 fill-white'
                  : 'w-4 h-4 fill-gray-500 text-gray-500 rotate-90'
              "
            />
          </div>
        </template>
        <template #dropdown-menu>
          <div
            class="
              flex flex-col
              department-select__dropdown-item
              p-1
              overflow-y-auto
              small-scrollbar
            "
            style="min-width: 14rem; max-height: 32rem"
          >
            <div
              class="
                pl-2
                py-2
                space-x-2
                flex
                items-center
                hover:bg-current-50
                cursor-pointer
                department-select__dropdown-item
                text-sm
              "
              :class="!modelValue?.id ? 'bg-current-50 text-current-500' : ''"
              @click="onChooseDepartmentId(null)"
            >
              <span class="pl-8 text-sm underline">
                {{ $t('COMMON_LABEL_WHOLE_ORGANIZATION') }}
              </span>
            </div>
            <div
              class="
                pl-2
                py-2
                space-x-2
                flex
                items-center
                hover:bg-current-50
                cursor-pointer
                department-select__dropdown-item
                text-sm
              "
              :class="
                myDepartment?.id == modelValue?.id
                  ? 'bg-current-50 text-current-500'
                  : ''
              "
              @click="onChooseDepartmentId(myDepartment?.id)"
            >
              <DepartmentById
                :department-id="myDepartment?.id"
                class="text-sm"
                :is-name-center="false"
              />
            </div>
            <template
              v-for="departmentId in visibleForAllDepartmentIds"
              :key="departmentId"
            >
              <div
                class="
                  pl-2
                  py-2
                  space-x-2
                  flex
                  items-center
                  hover:bg-current-50
                  cursor-pointer
                  department-select__dropdown-item
                  text-sm
                "
                :class="
                  departmentId == modelValue?.id
                    ? 'bg-current-50 text-current-500'
                    : ''
                "
                @click="onChooseDepartmentId(departmentId)"
              >
                <DepartmentById
                  :department-id="departmentId"
                  class="text-sm"
                  :is-name-center="false"
                />
              </div>
            </template>
            <div
              v-if="isShowNoDepartment"
              class="
                pl-2
                py-2
                space-x-2
                flex
                items-center
                hover:bg-current-50
                cursor-pointer
                department-select__dropdown-item
                text-sm
              "
              :class="
                modelValue?.id == null ? 'bg-current-50 text-current-500' : ''
              "
              @click="onChooseDepartmentId({ id: null })"
            >
              <span class="pl-8 text-sm underline">
                {{ $t('DEPARTMENT_LABEL_USER_HAS_NOT_DEPARTMENT') }}
              </span>
            </div>
          </div>
        </template>
      </VigDropdown>
      <slot name="suffix"></slot>
    </div>
  </div>
</template>
