<script setup lang="ts">
import { ref, computed } from 'vue';
import groupStore from '@/store/group';
import myProfileStore from '@/store/auth/my-profile';
import GroupDetailEntity, {
  IGroupDetailEntity,
} from '@/domain/entities/group/GroupDetailEntity';
import ModalChangeAvatarGroup from '@/ui/modules/avatars/ModalChangeAvatarGroup.vue';

const props = defineProps<{
  groupId: number;
  viewModeDefault?: 'VIEW' | 'EDIT';
}>();
const emit = defineEmits<{
  (e: 'updateSuccess', data: IGroupDetailEntity): void;
}>();

const groupInformation = ref<GroupDetailEntity>(new GroupDetailEntity({}));
const groupInformationOriginal = ref<IGroupDetailEntity>();
// const defaultG = props.group as any;
const _groupStore = groupStore();
const allGroupByIds = computed(() => _groupStore.allGroupByIds);
const viewMode = ref<'VIEW' | 'EDIT'>('VIEW');
const isSaveName = ref(false);
const isCheckChange = ref(false);
const canEditGroupInfo = computed(() => {
  return (
    (!groupInformation.value?.isPrivate &&
      (myProfileStore().isAdmin || myProfileStore().isOwner)) ||
    groupInformation.value?.isAdminGroup
  );
});
const isUpdateSuccess = ref(false);
const fileObj = ref();

const init = async () => {
  if (
    allGroupByIds.value &&
    Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
  ) {
    groupInformation.value = new GroupDetailEntity(
      allGroupByIds.value[props.groupId]
    );
    groupInformationOriginal.value = allGroupByIds.value[props.groupId];
  } else {
    const result = await _groupStore.getDetailGroupById(props.groupId);
    const groupDetail = {
      ...result,
      avatarPath: result?.avatar,
      avatar: result?.avatarUrl,
    };

    groupInformation.value = new GroupDetailEntity(groupDetail);
    groupInformationOriginal.value = groupDetail;
  }
  isUpdateSuccess.value = false;
  if (props.viewModeDefault) viewMode.value = props.viewModeDefault;
};
init();

const onClickEdit = () => {
  if (!groupInformation.value.hasPermission()) return;

  viewMode.value = 'EDIT';
};

const onSaveInfo = async () => {
  if (!groupInformation.value) return;

  isCheckChange.value = false;
  isSaveName.value = true;

  const newGroupPayload = {
    ...groupInformation.value,
    name: groupInformation.value?.name,
    description: groupInformation.value?.description,
    isPrivate: groupInformation.value?.isPrivate,
    email: groupInformation.value?.email,
    avatar: groupInformation.value?.avatarPath,
    type: groupInformation.value?.type,
    memberInfos: groupInformation.value?.groupUsers,
    domainIds: groupInformation.value?.groupDomains.map((el) => el.domainId),
  };
  await _groupStore.createAndUpdate(newGroupPayload);
  isUpdateSuccess.value = true;

  init();
  isSaveName.value = false;
  viewMode.value = 'VIEW';
  emit('updateSuccess', groupInformation.value);
};

const onCancelInfo = async () => {
  isCheckChange.value = false;

  if (groupInformationOriginal.value)
    groupInformation.value = new GroupDetailEntity(
      groupInformationOriginal.value
    );

  viewMode.value = 'VIEW';
  isUpdateSuccess.value = false;
};

const getFile = (file) => {
  fileObj.value = {
    preview: URL.createObjectURL(file),
    file: file,
  };
};
const uploadAvatarSuccess = (uploadedData: any) => {
  if (!groupInformation.value) return;

  groupInformation.value.avatarPath = uploadedData?.avatar;
  groupInformation.value.avatar = uploadedData?.avatarUrl;

  emit('updateSuccess', groupInformation.value);
};

const isOpenModal = ref<boolean>(false);
</script>
<template>
  <div class="space-y-4 bg-white">
    <section class="flex-center">
      <div class="flex-center w-32 h-32 hover-to-show__parent cursor-pointer">
        <SynAvatar
          :src="fileObj?.preview ? fileObj?.preview : groupInformation?.avatar"
          :name="groupInformation?.name"
          is-hidden-name
          is-view-image
          size="small"
          custom-class="w-32 h-32"
        />

        <div
          v-if="
            (!groupInformation?.avatar || canEditGroupInfo) &&
            groupInformation?.code !== 'DELETED_GROUP'
          "
          class="
            absolute
            bottom-0
            right-0
            hover-to-show__children
            flex
            items-center
            justify-center
            rounded-full
            bg-current-50 bg-opacity-90
            cursor-pointer
            hover:bg-current-100
            w-1/3
            h-1/3
          "
          @click="isOpenModal = true"
        >
          <SynIcon
            name="camera"
            custom-class="w-1/2 h-1/2 fill-current font-bold "
          />
        </div>
      </div>
    </section>
    <div
      class="
        flex
        font-medium
        text-current-700
        justify-end
        cursor-pointer
        items-center
        px-2
        flex-wrap
        rounded-t-md
        py-2
      "
    >
      <div class="flex items-center mx-4">
        <div
          v-if="viewMode == 'VIEW'"
          v-vig-tooltip="$t('TASK_CREATE_GROUP_FORM_INFO_GROUP')"
          v-permission="'FunctionGroupInfoEdit'"
          class="flex-center space-x-1"
          @click="onClickEdit"
        >
          <SynIcon
            has-action
            name="edit"
            is-active
            class="text-current-500 fill-current"
          />
          <span class="font-base text-xs">{{ $t('COMMON_LABEL_EDIT') }}</span>
        </div>
        <div v-else class="text-xs flex space-x-2 mr-4">
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
            :disabled="isSaveName"
            :label="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            @click="onCancelInfo"
          />
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
            :label="$t('COMMON_LABEL_SAVE')"
            :is-loading="isSaveName"
            :disabled="!isCheckChange"
            @click="onSaveInfo"
          />
        </div>
      </div>
    </div>

    <!-- name -->
    <div class="text-center w-full">
      <div class="mx-8 flex flex-col">
        <span class="min-w-max text-current-800 w-32 text-left">
          {{ $t('TASK_CREATE_GROUP_FORM_GROUP_NAME') }}
          <span class="text-red-500">*</span>
        </span>
        <syn-input
          v-model="groupInformation.name"
          autofocus
          input-type="text"
          :is-disabled="viewMode !== 'EDIT'"
          :placeholder="$t('TASK_CREATE_GROUP_FORM_GROUP_NAME')"
          @update:model-value="
            (value) => {
              value ? (isCheckChange = true) : (isCheckChange = false);
            }
          "
        />
      </div>
    </div>
    <!-- description -->
    <div class="text-center w-full">
      <div class="flex mx-8 flex-col">
        <span class="min-w-max text-current-800 w-32 text-left">
          {{ $t('COMMON_LABEL_DESCRIPTION') }}
        </span>
        <SynInputArea
          v-model="groupInformation.description"
          input-type="text"
          :max-length="150"
          :is-disabled="viewMode !== 'EDIT'"
          :placeholder="$t('COMMON_LABEL_DESCRIPTION')"
          @update:model-value="isCheckChange = true"
        />
      </div>
    </div>
  </div>

  <ModalChangeAvatarGroup
    v-if="isOpenModal"
    :src="groupInformation?.avatar"
    :group-id="groupInformation?.id"
    is-delete-photo
    @cancel="isOpenModal = false"
    @get-file="getFile"
    @upload-avatar-success="uploadAvatarSuccess"
  />
</template>
