import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import {
    getCurrentUserId,
    setLocalStorage,
    StorageConstant,
} from '@/ui/hooks/storageHook';
import UserService from '@/application/services/UserService';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import myProfileStore from '@/store/auth/my-profile';
import overviewTeamStore from '@/store/dashboard/overview-team-store';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { UserCreatePayload } from '@/domain/entities/PayloadClass/UserPayloadClass';
import { AdminType } from '@/ui/common/constants/constant';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { IUserDetail } from '@/application/types/user/user.types';
import plansStore from '@/store/plans';
import dayjs from 'dayjs';

let timerUpdateAvatarUser;

type UserState = {
    _userService: UserService;
    userProfile: any;
    userIdentity: any;
    listAllUser: IUserDetail[];
    listShortInfosUser: any[];
    totalMember: number;
    totalAdmin: number;
    totalOwner: number;
    totalUser: number;
    total: any;
    totalVisitor: number;
    deactiveUsers: any[];
    pagination: any;
    fileName: string;
    domains: any[];
    typeAdmin: any[];
    position: any[];
    userUpdate: UserCreatePayload;
    idNewUser: number;
    userShortInfosIncludeDepartments: any[];
    filterObject: {
        keySearch: string;
        status: number | undefined;
        profile: AdminType | '';
        departmentId: string | number;
    };
    scrollTop: number;
    allUserDetailByIds: any;
    activities: {
        totalDocument: number;
        datas: any[];
    };
    activitiesByDate: {
        datas: any[];
        totalDocument: number;
        totalOnline: number;
        totalOffline: number;
    };
    queryActivity: {
        userId: number;
        activity: string;
        subActivity: string | undefined;
        platform: string | undefined;
        pageIndex: number;
        pageSize: number;
    };
    activityInput: {
        date: Date | undefined;
        activity: string;
        subActivity: string | undefined;
    };
    departmentIdList: any[];
};
export default defineStore({
    id: storeId.user,
    state: () =>
        ({
            _userService: UserService.getInstance(),
            userIdentity: {},
            userProfile: {},
            fileName: '',
            domains: [],
            listAllUser: [],
            listShortInfosUser: [],
            totalMember: 0,
            totalAdmin: 0,
            totalOwner: 0,
            totalUser: 0,
            totalVisitor: 0,
            total: {},
            deactiveUsers: [],
            pagination: {},
            typeAdmin: [],
            position: [],
            userUpdate: new UserCreatePayload({}),
            idNewUser: 0,
            userShortInfosIncludeDepartments: [],
            filterObject: {
                keySearch: '',
                status: undefined,
                profile: '',
                departmentId: '',
            },
            scrollTop: 0,
            allUserDetailByIds: {},
            activities: {
                totalDocument: 0,
                datas: [],
                dataByDates: [],
            },
            queryActivity: {
                userId: 0,
                activity: '',
                subActivity: '',
                platform: '',
                pageIndex: 0,
                pageSize: 0,
            },
            activityInput: {
                activity: '',
                subActivity: '',
                date: dayjs().toDate(),
            },
            activitiesByDate: {
                datas: [],
                totalDocument: 0,
                totalOnline: 0,
                totalOffline: 0,
            },
            departmentIdList: [],
        } as UserState),
    getters: {
        isVisitor(): boolean {
            return (
                MyOrganizationSingleton.getInstance().getUserRole() ==
                AdminType.Visitor
            );
        },
        isAdmin(): boolean {
            return (
                MyOrganizationSingleton.getInstance().getUserRole() ==
                AdminType.Admin
            );
        },
        isUser(): boolean {
            return (
                MyOrganizationSingleton.getInstance().getUserRole() ==
                AdminType.User
            );
        },

        getDefaultUsers(state): any[] {
            return arrayOrderBy(
                state.listShortInfosUser,
                ['isMe', 'isFavorite', 'name'],
                ['desc', 'asc', 'asc']
            );
        },
        allUsers(state): any[] {
            return arrayOrderBy(
                [...state.listShortInfosUser, ...state.deactiveUsers],
                ['isMe', 'isFavorite', 'name'],
                ['desc', 'asc', 'asc']
            );
        },
        allUserByIds(state): any {
            return [...state.listShortInfosUser, ...state.deactiveUsers].reduce(
                (oldObj, newObj) => {
                    return {
                        ...oldObj,
                        [newObj.id]: newObj,
                    };
                },
                {}
            );
        },
        allActiveUsers(state): any[] {
            return arrayOrderBy(
                [...state.listShortInfosUser],
                ['isMe', 'isFavorite', 'name'],
                ['desc', 'asc', 'asc']
            );
        },
        allActiveUserWithDepartments(state): any[] {
            return arrayOrderBy(
                [...state.userShortInfosIncludeDepartments],
                ['isMe', 'isFavorite', 'name'],
                ['desc', 'asc', 'asc']
            );
        },
        allUserStatistic(state): any {
            return {
                totalAdmin:
                    state.listAllUser?.filter(
                        (e) => e.active && e.adminType === AdminType.Admin
                    )?.length || 0,
                totalUser: state.listAllUser?.length || 0,
                totalOwner:
                    state.listAllUser?.filter(
                        (e) => e.active && e.adminType === AdminType.Owner
                    )?.length || 0,
            };
        },
    },
    actions: {
        async getDetailUser(userId) {
            const res = await this._userService.getDetail(userId);
            return res['result'];
        },
        async getUserShortInfosIncludeDepartment() {
            const res =
                await this._userService.userShortInfosIncludeDepartment();
            this.userShortInfosIncludeDepartments = res?.result?.map((o) => {
                o.avatar = o.profilePictureUrl;
                o.name = `${o.lastName} ${o.firstName}`;
                o.userId = o.id;
                o.isMe = o.id == getCurrentUserId();
                o.utcUser = o.utc || '+7';
                return o;
            });
        },
        getDetailByUserId(userId: number) {
            const index = this.allUsers?.findIndex((o) => o.id == userId);
            return index >= 0 ? this.allUsers[index] : {};
        },
        async isVisitorById(id) {
            const index = this.listShortInfosUser?.findIndex(
                (user) => user?.adminType == AdminType.Visitor && user?.id == id
            );
            return index !== -1;
        },

        async canEditUserAvatar(userId) {
            return myProfileStore()?.myProfile?.id == userId;
        },

        async getShortInfosUser(): Promise<any> {
            const res = await this._userService.getShortInfosUser();
            this.listShortInfosUser = res?.result?.map((o) => {
                o.avatar = o.profilePictureUrl;
                o.name = `${o.lastName} ${o.firstName}`;
                o.userId = o.id;
                o.isMe = o.id == getCurrentUserId();
                o.utcUser = o.utc || '+7';
                return o;
            });
        },

        async getDeactivatedUsers(): Promise<any> {
            const res = await this._userService.getDeactivatedUsers();
            this.deactiveUsers = res?.result?.map((o) => {
                o.avatar = o.profilePictureUrl;
                o.name = `${o.lastName} ${o.firstName}`;
                o.userId = o.id;
                o.isDeactive = true;
                return o;
            });
        },

        async getUserIdentity(): Promise<any> {
            const res = await this._userService.getUserIdentity();
            this.userIdentity = res?.result;
            return res?.result;
        },

        async saveProfile(user, isDefaultAvatar?) {
            const res = await this._userService.saveProfile(
                user,
                !!isDefaultAvatar
            );

            plansStore().getAllPlanCapacities();

            const updatedUserData = res?.result;
            setTimeout(() => {
                myProfileStore().updateInfoCurrentUser();
                this.getProfileUserCurrent();
            }, 0);
            this.idNewUser = updatedUserData.id;

            if (user?.id && this.listAllUser.length > 0) {
                const index = this.listAllUser.findIndex(
                    (el) => el?.id == user?.id
                );
                if (index > -1) {
                    this.listAllUser[index].firstName = user?.firstName;
                    this.listAllUser[index].lastName = user?.lastName;
                }
            }

            const index = this.listShortInfosUser?.findIndex(
                (u) => u?.id == user?.id
            );
            if (index > -1) {
                this.listShortInfosUser[index] = {
                    ...this.listShortInfosUser[index],
                    ...updatedUserData,
                    avatar: updatedUserData?.avatarUrl,
                };
            }

            return res;
        },

        async uploadAvatarAws(file) {
            const res = await this._userService.uploadAvatar(file);
            this.fileName = res?.result?.path;
            return res?.result;
        },

        async updateAvatarUser(userId, avatar, defaultAvatar) {
            const res = await this._userService.uploadAvatarUser(
                userId,
                avatar,
                defaultAvatar
            );

            if (timerUpdateAvatarUser) clearTimeout(timerUpdateAvatarUser);

            timerUpdateAvatarUser = setTimeout(() => {
                this.updateAvatarLocal(userId, res['result']);
            }, 500);
            return res;
        },

        async updateAvatarLocal(userId, listAvatar: any) {
            await myProfileStore().updateInfoCurrentUser();
            await overviewTeamStore().updateAvatarUserTeamLocal(
                userId,
                listAvatar
            );

            this.userIdentity &&
                this.updateLinkAvatar(this.userIdentity, userId, listAvatar);
            this.userProfile &&
                this.updateLinkAvatar(this.userProfile, userId, listAvatar);

            this.listAllUser?.length > 0 &&
                this.listAllUser.map((el) =>
                    this.updateLinkAvatar(el, userId, listAvatar)
                );

            this.listShortInfosUser?.length > 0 &&
                this.listShortInfosUser.map((el) =>
                    this.updateLinkAvatar(el, userId, listAvatar)
                );
        },

        updateLinkAvatar(objectUser, userId, listAvatar) {
            if (objectUser?.id === userId || objectUser?.userId === userId) {
                return {
                    ...objectUser,
                    avatar: listAvatar?.avatar || objectUser.avatar || '',
                    avatarThumbnailUrl:
                        listAvatar?.avatarThumbnailUrl ||
                        objectUser.avatarThumbnailUrl ||
                        '',
                    avatarThumbUrl:
                        listAvatar?.avatarThumbnailUrl ||
                        objectUser?.avatarThumbUrl ||
                        '',
                    avatarUrl:
                        listAvatar?.avatarUrl || objectUser.avatarUrl || '',
                };
            }

            return { ...objectUser };
        },

        updateAvatarUserToAvatarCurrent(linkAvatar, userId, size) {
            const avatarCompare = linkAvatar.replace('original', size);

            if (!userId) return avatarCompare;

            const allUser = this.getDefaultUsers?.reduce((acc, value) => {
                return { ...acc, [value.id]: value };
            }, {});

            const avatarCurrent = allUser[userId]?.profilePictureUrl?.replace(
                'original',
                size
            );

            if (avatarCurrent == avatarCompare || !avatarCurrent)
                return avatarCompare;
            return avatarCurrent;
        },

        resetFilterUser() {
            this.filterObject.status = undefined;
            this.filterObject.keySearch = '';
            this.filterObject.profile = '';
            this.filterObject.departmentId = '';
            this.scrollTop = 0;
        },

        clearUserDataCache() {
            this.allUserDetailByIds = {};
        },

        async getDomainListById(userId) {
            const domain: any[] = [];
            const resDomain = await this._userService.getDomainListById(userId);
            const userDomain = resDomain?.result?.length
                ? resDomain?.result
                : [];

            userDomain.forEach(async function (el) {
                let checkDomain = el.system;
                // const checkAnyProject = el.system || el.anyProjects;
                const projectScope: any[] = [];

                if (el.projectScopeList.length > 0) {
                    let checkProject = false;
                    const project = el.projectScopeList.filter(
                        (pr) => !pr.parentId
                    );
                    project.forEach((elpro) => {
                        if (elpro.userId !== null) {
                            checkProject = true;
                        } else {
                            checkProject = false;
                        }
                        let checkSubProject = false;
                        const sub = el.projectScopeList.filter(
                            (pr) => pr.parentId == elpro.projectId
                        );
                        const subProject: any[] = [];
                        sub.forEach((sub) => {
                            if (sub.userId !== null) {
                                checkSubProject = true;
                            } else {
                                checkSubProject = false;
                            }
                            subProject.push({
                                checked: checkSubProject,
                                projectId: sub.projectId,
                                projectName: sub.projectName,
                            });
                        });
                        projectScope.push({
                            checked: checkProject,
                            projectId: elpro.projectId,
                            projectName: elpro.projectName,
                            subProject: subProject,
                        });
                    });
                }
                if (el.userId) {
                    checkDomain = true;
                }
                domain.push({
                    system: el.system,
                    checked: checkDomain,
                    domainName: el.domainName,
                    domainId: el.domainId,
                    anyProjects: el.anyProjects,
                    projectScopeList: projectScope,
                });
            });
            this.domains = domain;
            return domain;
        },

        async getUserDomainListById(userId) {
            const domain: any[] = [];
            const resDomain = await this._userService.getDomainListById(userId);
            const userDomain = resDomain?.result?.length
                ? resDomain?.result
                : [];

            userDomain.forEach((el) => {
                let checkDomain = el.system;
                // const checkAnyProject = el.system || el.anyProjects;
                const projectScope: any[] = [];

                if (el.projectScopeList.length > 0) {
                    let checkProject = false;
                    const project = el.projectScopeList.filter(
                        (pr) => !pr.parentId
                    );
                    project.forEach((elpro) => {
                        if (elpro.userId !== null) {
                            checkProject = true;
                        } else {
                            checkProject = false;
                        }
                        let checkSubProject = false;
                        const sub = el.projectScopeList.filter(
                            (pr) => pr.parentId == elpro.projectId
                        );
                        const subProject: any[] = [];
                        sub.forEach((sub) => {
                            if (sub.userId !== null) {
                                checkSubProject = true;
                            } else {
                                checkSubProject = false;
                            }
                            subProject.push({
                                checked: checkSubProject,
                                projectId: sub.projectId,
                                projectName: sub.projectName,
                            });
                        });
                        projectScope.push({
                            checked: checkProject,
                            projectId: elpro.projectId,
                            projectName: elpro.projectName,
                            subProject: subProject,
                        });
                    });
                }
                if (el.userId) {
                    checkDomain = true;
                }
                domain.push({
                    system: el.system,
                    checked: checkDomain,
                    domainName: el.domainName,
                    domainId: el.domainId,
                    anyProjects: el.anyProjects,
                    projectScopeList: projectScope,
                });
            });
            return domain;
        },

        async fetchAllProfile() {
            const res = await this._userService.fetchAllProfile(null);
            this.typeAdmin = res?.result;
            return res?.result;
        },

        async fetchAllPosition() {
            const res = await this._userService.fetchAllPosition(null);
            this.position = res?.result;
            return res?.result;
        },

        async getProfileUserCurrent() {
            const res = await this._userService.getDetail(getCurrentUserId());
            this.userProfile = { ...res['result'] };
            return res['result'];
        },

        async changePassword(
            email: string,
            currentPassword: string,
            newPassword: string
        ) {
            const data = {
                email: email,
                currentPassword: currentPassword,
                newPassword: newPassword,
            };
            try {
                const res = await this._userService.changePassword(data);
                if (res.success == true) {
                    setLocalStorage(
                        StorageConstant.REFRESH_TOKEN,
                        res.refreshToken
                    );
                    return true;
                }
            } catch (e) {
                openNotification({
                    type: AlertType.error,
                    title: translate('COMMON_LABEL_ERROR'),
                    body: e,
                });
            }
            return false;
        },

        async searchUsers(
            searchString,
            status,
            role,
            departmentId,
            pageIndex,
            pageSize,
            key = 'reload'
        ) {
            const res = await this._userService.searchUser(
                searchString,
                status,
                role,
                departmentId,
                pageIndex,
                pageSize
            );
            if (key === 'reload') this.listAllUser = res.users;
            else this.listAllUser = [...this.listAllUser, ...res.users];
            this.pagination = res.pagination;
            this.totalMember = res.pagination?.totalRow;
            this.totalAdmin = res.pagination?.totalAdmin;
            this.totalOwner = res.pagination?.totalOwner;
            this.totalUser = res.pagination?.totalUser;
            this.totalVisitor = res.pagination?.totalVisitor;
            this.total = {
                totalMember: res.pagination?.totalRow,
                [AdminType.Admin]: res.pagination?.totalAdmin,
                [AdminType.User]: res.pagination?.totalUser,
                [AdminType.Visitor]: res.pagination?.totalVisitor,
                [AdminType.Owner]: res.pagination?.totalOwner,
            };
            return this.listAllUser;
        },

        async deleteUserId(userId, status) {
            await this._userService.deleteUser(userId, status);
        },

        async updateStatusUser(userId, status) {
            await this._userService.updateStatusUser(userId, status);
            plansStore().getAllPlanCapacities();
        },

        async updateModalUserPayload(user) {
            this.userUpdate.id = user.id ? user.id : 0;
            this.userUpdate.firstName = user.firstName;
            this.userUpdate.lastName = user.lastName;
            this.userUpdate.login = user.login;
            this.userUpdate.avatar = user.avatar;
            this.userUpdate.active = user.active;
            this.userUpdate.timeOt = user.timeOt;
            this.userUpdate.birthday = user.birthday;
            this.userUpdate.address = user.address;
            this.userUpdate.description = user.description;
            this.userUpdate.holiday = user.holiday;
            this.userUpdate.email = user.email;
            this.userUpdate.identityCard = user.identityCard;
            this.userUpdate.skyper = user.skyper;
            this.userUpdate.phone = user.phone;
            this.userUpdate.utc = user.utc;
            this.userUpdate.adminType = user.adminType;
            this.userUpdate.positionId = user.positionId;
            this.userUpdate.password = user.password;
            this.userUpdate.notificationInfos = user.notificationInfos;
        },

        async saveUserScopesDto(data) {
            await this._userService.saveUserScopesDto(data);
        },

        async updatePositionValue(payload) {
            await this._userService.updatePositionValue(payload);
        },

        async setPassword(data: string) {
            return await this._userService.setPassword(data);
        },

        async sendVerificationUserEmail() {
            return await this._userService.sendVerificationUserEmail();
        },

        async changeUserPhone(data: any): Promise<any> {
            return await this._userService.changeUserPhone(data);
        },

        async changeUserEmail(data: any): Promise<any> {
            return await this._userService.changeUserEmail(data);
        },

        async checkHasSendVerificationUserEmail() {
            return await this._userService.checkHasSendVerificationUserEmail();
        },

        updateFavoriteUserById(userId, value = true) {
            const index = this.listShortInfosUser?.findIndex(
                (user) => user.id == userId
            );
            if (index > -1) {
                this.listShortInfosUser[index].isFavorite = value;
            }
        },

        async getUserActivities() {
            const res = await this._userService.getUserActivities(
                this.queryActivity
            );
            this.activities.totalDocument = res.result?.totalCount;
            this.activities.datas = res.result?.items;
        },

        async getAllUserActivitiesByDate() {
            const res = await this._userService.getAllUserActivitiesByDate(
                this.activityInput
            );

            const departmentIds = res?.result?.map((o) => o.departmentId);

            this.departmentIdList = [
                ...new Set(departmentIds.filter((e) => e !== null)),
            ];

            this.activitiesByDate.datas = res?.result;
        },
    },
});
