<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 402.41 402.13"
  >
    <path
      d="M558.79,534.28q-1.71-5.13-21.7-15.13c-3.61-2.09-8.76-4.95-15.41-8.56s-12.71-6.95-18.13-10-10.52-6-15.28-8.84q-1.14-.87-7.13-5A81.14,81.14,0,0,0,471,480.6a18.81,18.81,0,0,0-8.14-2q-5.72,0-14.27,8.14a138.7,138.7,0,0,0-15.7,17.7,171.16,171.16,0,0,1-15.13,17.7q-8,8.15-13.13,8.14a19.55,19.55,0,0,1-6.43-1.42c-2.56-1-4.52-1.77-5.86-2.43s-3.6-2-6.84-4-5.05-3.1-5.43-3.29Q341,497.45,313,469.48t-49.68-67.1Q263,401.81,260,397c-2-3.24-3.33-5.52-4-6.86a60.3,60.3,0,0,1-2.43-5.85,19.29,19.29,0,0,1-1.42-6.42q0-5.15,8.14-13.14A172.39,172.39,0,0,1,278,349.56a140,140,0,0,0,17.7-15.69q8.13-8.58,8.13-14.28a18.67,18.67,0,0,0-2-8.14,80.88,80.88,0,0,0-6.14-10.13q-4.15-6-5-7.14-4.27-7.14-8.85-15.27t-10-18.13q-5.42-10-8.56-15.42-10-20-15.13-21.69a16,16,0,0,0-6-.86q-7.71,0-20.13,2.85a113.48,113.48,0,0,0-19.56,6q-14.28,6-30.26,34.83-14.56,26.82-14.56,53.1a109.71,109.71,0,0,0,1,15A121.39,121.39,0,0,0,162.24,351c1.71,6.09,3.09,10.62,4.13,13.56s3,8.23,5.86,15.85,4.56,12.27,5.13,14a258.42,258.42,0,0,0,23.7,50q22.55,36.54,61.52,75.52T338.1,581.4a259.31,259.31,0,0,0,50,23.7q2.58.84,14,5.14t15.84,5.85c2.95,1,7.48,2.43,13.56,4.14a123.56,123.56,0,0,0,16.42,3.58,111.21,111.21,0,0,0,15,1q26.26,0,53.1-14.57,28.83-16,34.83-30.27a112.29,112.29,0,0,0,6-19.55q2.87-12.42,2.86-20.13A16,16,0,0,0,558.79,534.28Z"
      transform="translate(-157.67 -222.68)"
    />
    <path
      d="M371.33,416.4l112.76,2.39A12.72,12.72,0,0,0,497,408.61a12.51,12.51,0,0,0-12-14.81L402,392,556,244.45A12.73,12.73,0,0,0,558,228.13a12.51,12.51,0,0,0-19-2L384.73,374l1.75-82.63c.13-6-4.59-12-10.55-12.93a12.51,12.51,0,0,0-14.44,12.09l-2.4,113.11A12.51,12.51,0,0,0,371.33,416.4Z"
      transform="translate(-157.67 -222.68)"
    />
  </svg>
</template>
