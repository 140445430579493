<script setup lang="ts">
import { computed } from 'vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  activity: any;
}>();

const afterUpdate = computed<any>(() => {
  console.log(
    '🚀 Tictop ~ props.activity?.afterUpdate:',
    props.activity?.afterUpdate
  );
  return covertSnakeCaseToCamelCase(props.activity?.afterUpdate);
});

// const isEnableCase = computed<any>(() => {
//   return (
//     !beforeUpdate.value?.approvalEnabled && afterUpdate.value?.approvalEnabled
//   );
// });
// const isChangeCase = computed<any>(() => {
//   return (
//     beforeUpdate.value?.approvalEnabled && afterUpdate.value?.approvalEnabled
//   );
// });
// const isCancelCase = computed<any>(() => {
//   return !afterUpdate.value?.approvalEnabled;
// });
</script>
<template>
  <div class="flex items-center gap-2">
    <span
      class="lowercase"
      :class="
        afterUpdate?.scheduleTimeLocked ? 'text-orange-500' : 'text-gray-600'
      "
    >
      {{
        $t(
          afterUpdate?.scheduleTimeLocked
            ? 'TASK_ACTIVITY_LABEL_LOCK_UPDATE_DEADLINE'
            : 'TASK_ACTIVITY_LABEL_UNLOCK_UPDATE_DEADLINE'
        )
      }}
    </span>
    <span class="font-medium lowercase">
      {{ $t('TASK_UPDATE_MULTIPLE_CHANGE_DEADLINE') }}
    </span>
  </div>
</template>
