import BaseRepository from '@/base/repositories/BaseRepository';
import {
    GenerateGroupPayload,
    GroupDeletePayload,
} from '@/domain/entities/PayloadClass/GroupPayloadClass';
import UserService from '@/application/services/UserService';
import DomainRepository from '@/application/repositories/DomainRepository';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { EGroupType, IGroupUser } from '../types/group/group.types';

export default class GroupRepository {
    private static instance: GroupRepository;
    _domainRepository: DomainRepository;

    constructor() {
        this._domainRepository = DomainRepository.getInstance();
    }

    public static getInstance(): GroupRepository {
        if (!GroupRepository.instance) {
            // Get from local storage
            GroupRepository.instance = new GroupRepository();
        }

        return GroupRepository.instance;
    }

    async getDetail(groupId: number): Promise<any> {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetGroupDetail?lang=${lang}&groupId=${groupId}`,
            method: 'get',
        });
    }

    getAllGroup() {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetAll?lang=${lang}`,
            method: 'get',
        });
    }
    getDeletedGroupByUser() {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetDeletedGroupByUser?lang=${lang}`,
            method: 'get',
        });
    }

    async getDomainListById(userId: number): Promise<any> {
        return await UserService.getInstance().getDomainListById(userId);
    }

    getAllDomainOfUser(): any {
        return this._domainRepository.getAllDomainUserList();
    }

    async uploadAvatar(file: File): Promise<any> {
        return await UserService.getInstance().uploadAvatar(file);
    }

    updateDomains(groupId: number, data: any[]): Promise<any> {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Group/UpdateGroupDomains?groupId=${groupId}`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_GROUP_CHANGE_DOMAIN_SUCCESS'
                ),
            }
        );
    }

    async uploadAvatarGroup(groupId: number, avatar: string): Promise<any> {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Group/ChangeAvatar`,
                method: 'post',
                data: {
                    avatar: avatar,
                    groupId: groupId,
                },
            },
            {
                message: translate('API_ACTION_MESSAGE_GROUP_UPDATE_SUCCESS'),
            }
        );
    }

    createAndUpdate(data: ICreateOrUpdateGroupPayload) {
        return BaseRepository.requestWithAuthorize({
            url: `Group/UpdateGroup`,
            method: 'post',
            data,
        });
    }

    getSameGroupOfUsers(userId) {
        return BaseRepository.requestWithAuthorize({
            url: `/Group/GetSameGroupOfUsers?userId=${userId}`,
            method: 'get',
        });
    }

    getAllGroupByUser(userId = 0) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetGroupByUser?lang=${lang}&userId=${userId}`,
            method: 'get',
        });
    }

    getOrgGroupsByUser(orgId, userId) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetGroupByUser?lang=${lang}&orgId=${orgId}&userId=${userId}`,
            method: 'get',
        });
    }

    getOrgAllGroupsByUser(orgId, userId) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetAllGroupsOfUser?lang=${lang}&orgId=${orgId}&userId=${userId}`,
            method: 'get',
        });
    }

    getGroupChatByUser(userId = 0) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetGroupChatByUser?lang=${lang}&userId=${userId}`,
            method: 'get',
        });
    }

    getOrgGroupsChatByUser(orgId, userId) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetGroupChatByUser?lang=${lang}&orgId=${orgId}&userId=${userId}`,
            method: 'get',
        });
    }

    getGroupListOverview() {
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetGroupListOverview`,
            method: 'get',
        });
    }

    getOverviewGroupInfos(orderBy) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Group/OverviewGroupInfos?lang=${lang}&orderBy=${orderBy}`,
            method: 'get',
        });
    }

    getOverviewGroupTasks() {
        return BaseRepository.requestWithAuthorize({
            url: `Group/OverviewGroupTasks`,
            method: 'get',
        });
    }

    showLessPublicGroup() {
        return BaseRepository.requestWithAuthorize({
            url: `Group/ShowLessPublicGroup`,
            method: 'post',
        });
    }

    getPublicGroupSetting() {
        return BaseRepository.requestWithAuthorize({
            url: `Group/GetPublicGroupSetting`,
            method: 'get',
        });
    }

    addUserToGroup(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Group/AddUserToGroup`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    data.active
                        ? 'API_ACTION_MESSAGE_GROUP_ADD_USER_SUCCESS'
                        : 'API_ACTION_MESSAGE_GROUP_UPDATE_USER_SUCCESS'
                ),
            }
        );
    }

    addOrUpdateGroupToUser(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Group/AddOrUpdateUserToGroups`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_USER_UPDATE_GROUP_SUCCESS'
                ),
            }
        );
    }

    changeGroupType(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Group/ChangeGroupType`,
            method: 'post',
            data,
        });
    }

    updateGroupSendMessageRestriction(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Group/UpdateGroupSendMessageRestriction`,
            method: 'put',
            data,
        });
    }

    deleteGroup(data: GroupDeletePayload) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Group/DeleteGroup`,
                method: 'post',
                data,
            },
            {
                message: translate('API_ACTION_MESSAGE_GROUP_DELETE_SUCCESS'),
            }
        );
    }

    breakLinkDefaultDepartmentGroup(groupId: any) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Group/BreakLinkDefaultDepartmentGroup`,
                method: 'post',
                params: { groupId },
            },
            {
                message: translate(
                    'GROUP_COMFIRM_BREAK_LINK_DEPARTMENT_UPDATE_SUCCESS'
                ),
            }
        );
    }

    addDefaultDepartmentGroup(data: GenerateGroupPayload) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Group/AddDefaultDepartmentGroup`,
                method: 'post',
                data,
            }
            // {
            //   message: translate(
            //       'GROUP_COMFIRM_BREAK_LINK_DEPARTMENT_UPDATE_SUCCESS'
            //   ),
            // }
        );
    }
}

// Generated by https://quicktype.io

export interface ICreateOrUpdateGroupPayload {
    id?: number;
    name: string;
    email?: string;
    isPrivate: boolean;
    description: string;
    avatar: string;
    memberInfos: IGroupUser[];
    domainIds: number[];
    type: EGroupType;
}
