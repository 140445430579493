<script setup lang="ts">
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import WorkflowStepCodeLabel from '@/ui/modules/workflow/workflow-step/WorkflowStepCodeLabel.vue';
import { computed } from 'vue';
const props = defineProps<{
  modelValue?: string;
  nextSteps: any[];
  stepByIds?: any;
  type?: 'DEFAULT' | 'MINI';
  viewMode?: string;
}>();

defineEmits<{
  (e: 'update:modelValue', id: string): void;
}>();

const nextStepList = computed<any[]>(() => {
  return arrayOrderBy(
    props.nextSteps?.map((step) => {
      return {
        ...step,
        ...props.stepByIds?.[step?.id],
      };
    }),
    [
      (data) => {
        return Number(data?.code) || data?.code;
      },
    ],
    ['asc']
  );
});
</script>
<template>
  <div v-if="type == 'MINI'" class="flex gap-2 p-2">
    <template v-for="step in nextStepList" :key="step?.id">
      <AtomTooltip :delay="[300, 0]">
        <template #trigger>
          <div class="p-2 flex-center gap-2 bg-current-50 rounded-md">
            <div class="w-4 relative flex-center">
              <div class="long-arrow-right text-gray-600"></div>
            </div>

            <WorkflowStepCodeLabel :model-value="step?.code" readonly />
          </div>
        </template>
        <template #body>
          <div class="w-96 p-2 flex-center gap-2 rounded-md">
            <div class="w-1/2 relative flex-center">
              <span class="max-w-full absolute text-xs -top-1.5 text-gray-600">
                {{ step?.condition?.name }}
              </span>

              <div class="long-arrow-right text-gray-600"></div>
            </div>
            <div class="w-1/2">
              <div
                class="
                  w-max
                  max-w-full
                  px-2
                  rounded-md
                  border border-gray-300
                  flex-center
                  gap-2
                "
                :class="[step?.code ? 'py-2' : 'py-1']"
              >
                <!-- <span
                  class="
                    h-5
                    w-max
                    text-xs
                    rounded-full
                    flex-center
                    bg-gray-500
                    text-white
                    shrink-0
                    px-1
                  "
                >
                  {{ stepByIds?.[step?.id]?.code || '' }}
                </span> -->
                <WorkflowStepCodeLabel :model-value="step?.code" readonly />
                <span class="truncate">
                  {{ step?.name || 'step name' }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </AtomTooltip>
    </template>
  </div>

  <div v-else class="flex flex-col py-4 rounded gap-2 min-w-[20rem]">
    <div
      v-for="step in nextStepList"
      :key="step?.id"
      class="w-full py-2 flex-center gap-2 rounded-md"
      :class="[
        viewMode == 'SELECT'
          ? 'cursor-pointer hover:border-gray-200 hover:bg-gray-50 border border-white'
          : '',
      ]"
      @click="$emit('update:modelValue', step?.id)"
    >
      <div class="w-1/2 relative flex-center">
        <span class="absolute text-xs -top-1.5 text-gray-600">
          {{ step?.condition?.name }}
        </span>

        <div class="long-arrow-right text-gray-600"></div>
      </div>
      <div class="w-1/2">
        <div
          class="
            w-max
            max-w-full
            px-2
            rounded-md
            border border-gray-300
            flex-center
            gap-1
          "
          :class="[
            modelValue == step?.id ? 'bg-current-50' : 'bg-gray-50',
            step?.code ? 'py-2' : 'py-1',
          ]"
        >
          <WorkflowStepCodeLabel :model-value="step?.code" readonly />
          <span class="truncate text-sm">
            {{ step?.name || 'step name' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.long-arrow-right {
  width: 100%;
  height: 21px;
  display: flex;
}
.long-arrow-right:before {
  content: '';
  background: currentColor;
  clip-path: polygon(
    0px 10px,
    calc(100% - 5px) 10px,
    calc(100% - 5px) 7px,
    100% 50%,
    calc(100% - 5px) 68%,
    calc(100% - 5px) calc(100% - 10px),
    0 calc(100% - 10px)
  );
  flex-grow: 1;
}
</style>
