<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay]);

const props = defineProps({
  slidesPerView: {
    default: 1,
    type: Number,
  },
  spaceBetween: {
    default: 0,
    type: Number,
  },
  isShowPagination: {
    default: true,
    type: Boolean,
  },
  isShowNavigation: {
    default: true,
    type: Boolean,
  },
  autoplay: {
    default: true,
    type: Boolean,
  },
  loop: {
    default: false,
    type: Boolean,
  },
});

const tictopSwiper = ref(null);
const swiper = ref(null as any);

watch(
  () => props.slidesPerView,
  () => {
    swiper.value.params.slidesPerView = props.slidesPerView;
    swiper.value.params.slidesPerGroup = props.slidesPerView;
    swiper.value.slideTo(0);
  }
);

onMounted(() => {
  initSlide();
});

const initSlide = async () => {
  if (tictopSwiper.value) {
    swiper.value = new Swiper(tictopSwiper.value, {
      slidesPerView: props.slidesPerView,
      slidesPerGroup: props.slidesPerView,
      spaceBetween: props.spaceBetween,
      loop: props.loop || false,
      autoplay: props.autoplay
        ? {
            delay: 5000,
            disableOnInteraction: false,
          }
        : false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    if (props.loop) {
      await swiper.value?.loopDestroy();
      swiper.value?.loopCreate();
    }
  }
};

const onNextSlide = () => {
  swiper.value.slideNext();
};

const onPrevSlide = () => {
  swiper.value.slidePrev();
};

defineExpose({
  onNextSlide,
  onPrevSlide,
});
</script>

<template>
  <div ref="tictopSwiper" class="w-full h-full swiper-container relative">
    <div class="swiper-wrapper cursor-pointer">
      <slot></slot>
    </div>
    <div
      v-if="isShowPagination"
      class="swiper-pagination absolute h-8 w-full flex-center"
      style="z-index: 999; width: 100%; bottom: 0"
    ></div>
    <template v-if="isShowNavigation">
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </template>
  </div>
</template>
<style scoped>
@import 'swiper/swiper.min.css';
@import 'swiper/components/pagination/pagination.min.css';
@import 'swiper/components/navigation/navigation.min.css';

.swiper-container:hover .swiper-button-next {
  visibility: visible;
  opacity: 1;
}

.swiper-container:hover .swiper-button-prev {
  visibility: visible;
  opacity: 1;
}

.swiper-pagination :deep(.swiper-pagination-bullet) {
  background-color: #bebebe !important;
}

.swiper-pagination :deep(.swiper-pagination-bullet-active) {
  background-color: #4bb0b2 !important;
}

.swiper-button-next,
.swiper-button-prev {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  background-color: white;
  background-color: rgba(255, 255, 255, 0.3);
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #008e8e !important;
  fill: #008e8e !important;
  stroke: #008e8e !important;
}

.swiper-button-prev::after {
  font-size: 12px;
}

.swiper-button-next::after {
  font-size: 12px;
}

.swiper-button-disabled {
  display: none;
}
</style>
