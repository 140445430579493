<script setup lang="ts">
import { ref, computed } from 'vue';
import groupStore from '@/store/group';
import DepartmentTable from '@/ui/modules/departments/DepartmentTable.vue';
import DepartmentCreateFormModal from '@/ui/modules/departments/DepartmentCreateFormModal.vue';
import DepartmentDetail from '@/ui/modules/departments/DepartmentDetail.vue';
import departmentStore from '@/store/department';
import userStore from '@/store/user';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';

const _groupStore = groupStore();
const _departmentStore = departmentStore();

const isLoadingTable = ref(false);
const listGroups = computed(() => _groupStore.allGroup);

const allDepartments = computed<any>(() => _departmentStore.allDepartments);

const groupTabs = ref<any>([]);
const viewMode = ref<'LIST' | 'DETAIL'>('LIST');
const currentGroupId = ref();
const departmentDetailIds = ref<any[]>([]);
const isShowAdd = ref(false);
const idCurrentGroup = ref(null);
const isUpdateAvt = ref(false);

const init = async () => {
  isLoadingTable.value = true;
  userStore().getUserShortInfosIncludeDepartment();
  isLoadingTable.value = false;
};
init();

const onReloadDeleteGroup = () => {
  if (departmentDetailIds.value?.length > 0) {
    const departmentId = departmentDetailIds.value[0];
    closeTab(departmentId);
    viewMode.value = 'LIST';
  }
};

// change status
const onChangeStatusGroup = async (value) => {
  var group = listGroups.value.find((el) => el.id == value.id);
  group.isPrivate = value.status;
  var dataPayload = {
    id: group.id,
    name: group.name,
    isPrivate: group.isPrivate,
    description: group.description,
    avatar: group.avatar,
    // userIds: group.groupUsers.map(el => el.userId),
    memberInfos: group.groupUsers,
    domainIds: group.groupDomains.map((el) => el.domainId),
  };
  await _groupStore.createAndUpdate(dataPayload);
};

// create
const onCreateGroup = () => {
  isShowAdd.value = true;
};

const onChooseGroup = async (derpartment: any) => {
  const departmentId = derpartment?.id;
  departmentDetailIds.value = [departmentId];

  const index = groupTabs.value.findIndex((o) => o.id == departmentId);
  if (index == -1) {
    if (groupTabs.value?.length == 5) groupTabs.value.shift();
    groupTabs.value = [...groupTabs.value, derpartment];
  }

  viewMode.value = 'DETAIL';
  currentGroupId.value = departmentId;
};

const changeTab = async (type, department?) => {
  viewMode.value = type?.toUpperCase();
  if (type == 'LIST') {
    idCurrentGroup.value = groupTabs.value[groupTabs.value?.length - 1]['id'];
  } else if (department) {
    const departmentId = department?.id;
    currentGroupId.value = departmentId;
    departmentDetailIds.value = [];
    departmentDetailIds.value.push(departmentId);
  }
};

const closeTab = (groupId) => {
  if (groupId == currentGroupId.value) {
    currentGroupId.value = null;
    viewMode.value = 'LIST';
  }
  groupTabs.value = groupTabs.value.filter((o) => o.id !== groupId);
};

const onCreateSuccess = (newDepartment) => {
  isShowAdd.value = false;
  onChooseGroup(newDepartment);
};
</script>
<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.ADMIN.DEPARTMENT,
      isPage: true,
    }"
    class="h-full w-full pt-2 flex flex-col"
  >
    <div
      v-if="allDepartments?.length > 0"
      class="flex-1 flex justify-between items-end"
    >
      <ul class="flex cursor-pointer text-sm space-x-0.5">
        <li
          class="py-2 px-4 font-semibold rounded-t-md items-center flex"
          :class="
            viewMode === 'LIST'
              ? 'bg-white text-current-500 border-t border-r border-current-100 z-10'
              : 'text-gray-500 bg-gray-100 '
          "
          :style="
            viewMode === 'LIST' ? 'margin-bottom: -1px' : 'margin-bottom: 0px'
          "
          @click="changeTab('LIST')"
        >
          {{ $t('COMMON_LABEL_ALL') }}
        </li>
        <li
          v-for="group in groupTabs"
          :key="group"
          class="py-2 px-2 rounded-t-lg"
          :class="
            viewMode == 'DETAIL' && currentGroupId == group?.id
              ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
              : 'text-gray-500 bg-gray-100 '
          "
          :style="
            viewMode == 'DETAIL' && currentGroupId == group?.id
              ? 'margin-bottom: -1px'
              : 'margin-bottom: 0px'
          "
          @click="changeTab('DETAIL', group)"
        >
          <div class="flex-center space-x-2">
            <SynAvatar
              class="cursor-pointer text-current-800"
              :src="
                isUpdateAvt
                  ? group?.avatarThumnailUrl?.replace('original', 'original')
                  : group?.avatarThumnailUrl?.replace('original', 'mini')
              "
              :name="group?.name"
              has-name
              custom-class="w-6 h-6"
            />
            <span
              class="
                w-6
                h-6
                rounded-full
                hover:bg-gray-50
                flex-center
                hover:fill-gray-500
              "
            >
              <SynIcon
                class="hover:text-current"
                name="close"
                @click.stop="closeTab(group?.id)"
              />
            </span>
          </div>
        </li>
      </ul>

      <!-- syn button -->
      <div class="text-sm flex-center space-x-2 w-max mb-1.5 pr-2">
        <SynButton
          :label="$t('DEPARTMENT_LABEL_CREATE_NEW') || 'Create'"
          name-icon="department"
          @click="onCreateGroup"
        />
      </div>
    </div>

    <div
      class="
        h-full
        shadow-md
        bg-white
        flex flex-col
        space-y-4
        overflow-y-hidden
        border-t border-current-100
      "
    >
      <DepartmentTable
        v-if="viewMode == 'LIST'"
        @show-detail="onChooseGroup"
        @change-status="onChangeStatusGroup"
        @confirm-deleted="onReloadDeleteGroup"
        @on-create-new="onCreateGroup"
      />
      <template v-if="viewMode == 'DETAIL'">
        <DepartmentDetail
          v-for="departmentId in departmentDetailIds"
          :key="departmentId"
          :department-id="departmentId"
          @is-update-avt="(value) => (isUpdateAvt = value)"
          @delete-success="onReloadDeleteGroup"
        />
      </template>
    </div>
  </div>

  <DepartmentCreateFormModal
    v-if="isShowAdd"
    @cancel="isShowAdd = false"
    @create-success="onCreateSuccess"
  />
</template>
