<template>
  <svg
    id="Group_1835"
    data-name="Group 1835"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.494 20"
  >
    <path
      id="Path_382"
      data-name="Path 382"
      d="M15.831,2H7.663C4.115,2,2,4.17,2,7.81v8.38C2,19.83,4.115,22,7.663,22h8.168c3.548,0,5.663-2.17,5.663-5.81V7.81c0-3.64-2.115-5.81-5.663-5.81ZM9.768,14.9,7.575,17.15a.714.714,0,0,1-.517.22.7.7,0,0,1-.517-.22l-.731-.75a.75.75,0,0,1,0-1.06.722.722,0,0,1,1.033,0l.214.22,1.676-1.72a.722.722,0,0,1,1.033,0A.769.769,0,0,1,9.768,14.9Zm0-7L7.575,10.15a.714.714,0,0,1-.517.22.7.7,0,0,1-.517-.22L5.811,9.4a.75.75,0,0,1,0-1.06.722.722,0,0,1,1.033,0l.214.22L8.735,6.84a.722.722,0,0,1,1.033,0,.769.769,0,0,1,0,1.06Zm7.4,8.72H12.049a.75.75,0,0,1,0-1.5h5.117a.75.75,0,0,1,0,1.5Zm0-7H12.049a.75.75,0,0,1,0-1.5h5.117a.75.75,0,0,1,0,1.5Z"
      transform="translate(-2 -2)"
    />
  </svg>
</template>
