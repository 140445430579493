import { defineStore } from 'pinia';
import TaskService from '@/application/services/task/TaskService';
import ExportService from '@/application/services/ExportService';
import storeId from '@/base/store/storeId';
import taskListStore from '@/store/task/task-list-store';
import ExportTaskPayloadClass from '@/domain/entities/export/ExportTaskPayloadClass';
import { keySearch } from '@/ui/modules/task/table/task-list-state';
import { saveAs } from 'file-saver';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { TaskListTab } from '@/domain/enums/taskEnum';
// import { translate } from '@/ui/plugins/i18n/myi18n';

export default defineStore({
    id: storeId.export,
    state: () => ({
        exporting: false,
        isLoadingDownload: false,
        isError: false,
        totalDownload: 0,
        _taskService: TaskService.getInstance(),
        _exportService: ExportService.getInstance(),
        customColumns: {
            taskId: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_TASK_ID') || 'Task ID',
                index: 1,
            },
            taskCode: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_TASK_CODE') || 'Task Code', // need to translate
                index: 2,
            },
            groupName: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_GROUP_NAME') || 'Group Name',
                index: 3,
            },
            taskName: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_TASK_NAME') || 'Task Name',
                index: 4,
            },
            description: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_DESCRIPTION') ||
                    'Description',
                index: 5,
            },
            domain: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_DOMAIN') || 'Domain',
                index: 6,
            },
            status: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_STATUS') || 'Status',
                index: 7,
            },
            urgency: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_URGENCY') || 'Urgency',
                index: 8,
            },
            planToday: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_PLAN_TODAY') || 'Plan today',
                index: 9,
            },
            deadline: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_DEADLINE') || 'Deadline',
                index: 10,
            },
            assigneeName: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_ASSIGNEE_NAME') ||
                    'Assignee Name',
                index: 11,
            },
            creatorName: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_CREATOR_NAME') ||
                    'Creator Name',
                index: 12,
            },
            creationDate: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_CREATION_DATE') ||
                    'Creation Date',
                index: 13,
            },
            updatedDate: {
                export: true,
                displayName:
                    translate('TASK_EXPORT_COLUMNS_UPDATED_DATE') ||
                    'Updated Date',
                index: 14,
            },
        },
    }),
    getters: {},
    actions: {
        async exportTaskData(csvFileName) {
            const payload = new ExportTaskPayloadClass({
                filters:
                    taskListStore().activeTab == TaskListTab.MyAssign
                        ? taskListStore().filterAssignedPayload
                        : taskListStore().filterPayload,
                filterDataByKey: taskListStore().filterDataByKey,
                sortDataOrderByKey: taskListStore().sortDataOrderByKey,
                keySearch: keySearch.value,
                fields: this.customColumns,
                tasks: taskListStore().filteredTasks,
            });

            try {
                this.exporting = true;
                const res = await this._exportService.exportTaskData(payload);
                this.exporting = false;
                if (!res?.result) return;
                saveAs(res?.result, csvFileName || 'users.csv');
            } catch (error) {
                this.exporting = false;
                console.log(error);
            }
        },

        async exportGanttChart(data, domainName) {
            try {
                this.totalDownload = 0;
                this.isLoadingDownload = true;

                let res;
                let stopLoop = false;
                let progress = 0;
                const updateProgress = () => {
                    this.totalDownload = progress;
                };

                const updateProgressLoop = async () => {
                    for (let i = 0; i <= 0.99 && !stopLoop; i += 0.01) {
                        await new Promise((resolve) => setTimeout(resolve, 30));
                        progress = i;
                        updateProgress();
                    }
                };

                await Promise.all([
                    updateProgressLoop(),
                    this._exportService
                        .exportGanttChart(data)
                        .then((result) => {
                            res = result;
                            stopLoop = true;
                        }),
                ]);

                if (res && res.result) {
                    progress = 1;
                    updateProgress();
                    const formatDomainName = domainName?.split(' ').join('_');
                    saveAs(
                        res?.result,
                        `TICTOP_${formatDomainName}_gantt_chart.xlsx`
                    );
                } else {
                    stopLoop = true;
                }

                this.isLoadingDownload = false;
            } catch (e) {
                console.log(e);
                this.isError = true;
                this.isLoadingDownload = false;
            }
        },
    },
});
