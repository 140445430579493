import { arrayOrderBy, stringNomalize } from '@/ui/hooks/commonFunction';
import { FilterQuery } from '@/domain/entities/task/FilterTask';
import { ref } from 'vue';
import {
    ITaskSoftLocal,
    TASK_SORT_BY,
    TaskStatusByTaskLife,
} from '@/application/constants/task.const';
import TaskService from '@/application/services/task/TaskService';
import dayjs from 'dayjs';
import { getUrgencyValue, sortDefaultTaskList } from '@/ui/hooks/taskHook';
import { ETaskListModule } from '@/application/types/task/task.types';

export default function useTaskBoardCard() {
    const MAX_ROW_FIRST_TIME = 10;

    const taskAllList = ref<any[]>([]);
    const allTaskByIds = ref<any>({});
    const sortedTaskIds = ref<any[]>([]);
    const listTaskIdShowing = ref<string[]>([]);
    const isLoading = ref<boolean>(true);
    const isReady = ref<boolean>(false);

    const fetchTasks = async (filterPayload, hiddenLoading?) => {
        try {
            if (!hiddenLoading) isLoading.value = true;

            const res = await TaskService.getInstance().getTaskWithFilter(
                filterPayload
            );

            if (!res || !res.result) return;

            if (filterPayload.isRepeatTask || filterPayload.isWorkFlowTask) {
                res.result = res.result.filter((e) => {
                    const isRepeatTask = filterPayload.isRepeatTask;
                    const isWorkFlowTask = filterPayload.isWorkFlowTask;

                    return (
                        (isRepeatTask &&
                            isWorkFlowTask &&
                            (e.isRepeat || e.workFlowTaskId)) ||
                        (isRepeatTask && !isWorkFlowTask && e.isRepeat) ||
                        (isWorkFlowTask && !isRepeatTask && e.workFlowTaskId)
                    );
                });
            }

            taskAllList.value = res.result?.map((task) => {
                return {
                    ...task,
                    urgency: getUrgencyValue(task?.priority, task?.important),
                    scheduleTime: task?.scheduleTime
                        ? dayjs(task?.scheduleTime)
                              .utc(true)
                              .format('YYYY-MM-DDTHH:mm:ssZ')
                        : task?.scheduleTime,
                    status: TaskStatusByTaskLife[task?.taskLife],
                };
            });

            allTaskByIds.value = taskAllList.value?.reduce(
                (currentResult, currentItem) => {
                    return {
                        ...currentResult,
                        [currentItem?.id]: {
                            ...currentItem,
                        },
                    };
                },
                {}
            );
        } catch (e) {
            console.log('🚀 Tictop ~ e:', e);
        } finally {
            isLoading.value = false;
        }
    };

    const initData = async (filterPayload: FilterQuery) => {
        await fetchTasks(filterPayload);

        onSortData();

        isReady.value = true;
    };
    const currentSortData = ref<ITaskSoftLocal>({
        ...TASK_SORT_BY.DEFAULT,
    } as ITaskSoftLocal);

    const onSortData = () => {
        const currentIterate =
            currentSortData.value?.key === 'DEFAULT'
                ? []
                : currentSortData.value?.orderData?.iterate;
        const currentOrder =
            currentSortData.value?.key === 'DEFAULT'
                ? []
                : currentSortData.value?.orderData?.order;

        sortedTaskIds.value = arrayOrderBy(
            taskAllList.value,
            [...currentIterate, ...sortDefaultTaskList.iterate],
            [...currentOrder, ...sortDefaultTaskList.order]
        )?.map((task: any) => task?.id);

        listTaskIdShowing.value =
            sortedTaskIds.value?.length > 0
                ? [...sortedTaskIds.value?.slice(0, MAX_ROW_FIRST_TIME)]
                : [];
    };

    const onLoadMore = () => {
        listTaskIdShowing.value =
            sortedTaskIds.value?.length > 0
                ? [
                      ...sortedTaskIds.value?.slice(
                          0,
                          listTaskIdShowing.value?.length + MAX_ROW_FIRST_TIME
                      ),
                  ]
                : [];
    };

    const onChangeIterate = (sortData) => {
        currentSortData.value = sortData;

        onSortData();
    };
    const onChangeOrder = (orderData) => {
        currentSortData.value.orderData.order = [orderData];

        onSortData();
    };

    const onSearch = (keySearch) => {
        if (!keySearch) {
            onSortData();
            return;
        }
        sortedTaskIds.value = taskAllList.value
            .filter(
                (t) =>
                    stringNomalize(t.name?.toLowerCase()).includes(
                        stringNomalize(keySearch?.toLowerCase())
                    ) ||
                    stringNomalize(t.code?.toLowerCase()).includes(
                        stringNomalize(keySearch?.toLowerCase())
                    ) ||
                    stringNomalize(t.displayName?.toLowerCase()).includes(
                        stringNomalize(keySearch?.toLowerCase())
                    )
            )
            ?.map((task) => task?.id);

        listTaskIdShowing.value =
            sortedTaskIds.value?.length > 0
                ? [...sortedTaskIds.value?.slice(0, MAX_ROW_FIRST_TIME)]
                : [];
    };

    return {
        isReady,
        taskAllList,
        allTaskByIds,
        currentSortData,
        listTaskIdShowing,
        isLoading,
        initData,
        onLoadMore,
        onChangeIterate,
        onChangeOrder,
        onSearch,
        onSortData,
    };
}

export const getFilterPayloadDefault = (
    sourceId,
    sourceType: ETaskListModule
) => {
    const newFilterPayload = new FilterQuery({
        listOwnerId: sourceId,
        assignorId: undefined,
        groupIds: undefined,
        domainId: undefined,
        isDefault: true,
        menuTab: undefined,
        status: '',
    });

    switch (sourceType) {
        case ETaskListModule.USER:
            break;
        case ETaskListModule.I_ASSIGNED:
            newFilterPayload.listOwnerId = null;
            newFilterPayload.assignorId = sourceId;

            break;
        case ETaskListModule.GROUP:
            newFilterPayload.listOwnerId = null;
            newFilterPayload.groupIds = `${sourceId}`;
            break;
        case ETaskListModule.DOMAIN:
            newFilterPayload.listOwnerId = null;
            newFilterPayload.domainId = sourceId;
            break;

        default:
            break;
    }
    return newFilterPayload;
};
