<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 48 48"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M14.3 16.2H11c-1.1 0-1.9-.9-1.9-1.9v-3.4C9 9.9 9.8 9 10.9 9h3.3c1.1 0 1.9.9 1.9 1.9v3.4c.1 1-.8 1.9-1.8 1.9zm-.1-5.3-3.3.1.1 3.3 3.2-.1zm.1 3.3c-.1 0-.1 0 0 0zM39 12.9c0-.6-.4-1-1-1H20c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.5 1-1zM14.3 27.6H11c-1.1 0-1.9-.9-1.9-1.9v-3.3c0-1.1.9-1.9 1.9-1.9h3.3c1.1 0 1.9.9 1.9 1.9v3.3c0 1-.9 1.9-1.9 1.9zm-.1-5.3-3.3.1.1 3.3 3.2-.1zm.1 3.3c-.1 0-.1 0 0 0zM39 24.3c0-.6-.4-1-1-1H20c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.5 1-1zM14.3 39H11c-1.2 0-2-.9-2-1.9v-3.4c0-1.1.9-1.9 1.9-1.9h3.3c1.1 0 1.9.9 1.9 1.9v3.4c.1 1-.8 1.9-1.8 1.9zm-.1-5.3-3.3.1.1 3.3 3.2-.1zm.1 3.3c-.1 0-.1 0 0 0zM39 35.7c0-.6-.4-1-1-1H20c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.5 1-1z"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>
