<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g id="Mini_Player" data-name="Mini Player">
        <path
          d="m19 4h-14a3.009 3.009 0 0 0 -3 3v10a3.009 3.009 0 0 0 3 3h14a3.009 3.009 0 0 0 3-3v-10a3.009 3.009 0 0 0 -3-3zm-2 13h-4a2.006 2.006 0 0 1 -2-2v-2a2.006 2.006 0 0 1 2-2h4a2.006 2.006 0 0 1 2 2v2a2.006 2.006 0 0 1 -2 2z"
        ></path>
      </g>
    </g>
  </svg>
</template>
