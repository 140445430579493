<template>
  <svg
    class="Icon SettingsIcon"
    viewBox="0 0 32 32"
    aria-hidden="true"
    focusable="false"
  >
    <path
      d="M25.999 3c-2.967 0-5.43 2.167-5.91 5H3a1 1 0 1 0 0 2h17.09c.478 2.833 2.942 5 5.91 5 3.309 0 6-2.691 6-6s-2.691-6-6-6Zm0 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4Zm-20 16c2.967 0 5.43-2.167 5.91-5h17.09a1 1 0 1 0 0-2h-17.09c-.478-2.833-2.942-5-5.91-5-3.309 0-6 2.691-6 6s2.691 6 6 6Zm0-10c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4Z"
    ></path>
  </svg>
</template>
