<script setup lang="ts">
import ModalViewLocationMap from '@/ui/components/map/ModalViewLocationMap.vue';
import { ref } from 'vue';

defineProps<{
  position?: {
    address?: string;
    region?: string;
    country?: string;
    latitude?: number;
    longitude?: number;
  };
}>();

const seeOnMapPosition = ref<any>();
</script>

<template>
  <div @click.stop="seeOnMapPosition = position">
    <slot />
  </div>

  <ModalViewLocationMap
    v-if="seeOnMapPosition"
    :position="seeOnMapPosition"
    @on-close="seeOnMapPosition = null"
  />
</template>

<style scoped></style>
