import { InstantMessagingType } from '@/ui/common/constants/constant';

export function getDefaultUserNotification() {
    return [
        {
            id: 0,
            memberId: 0,
            member: '',
            memberType: 1,
            instantMessagingType: InstantMessagingType.Slack,
            url: '',
            phone: '',
            isOn: false,
        },
        {
            id: 0,
            memberId: 0,
            member: '',
            memberType: 1,
            instantMessagingType: InstantMessagingType.Zalo,
            url: '',
            phone: '',
            isOn: false,
        },
    ];
}
