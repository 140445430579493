import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import GoogleCalendarApi from '@/ui/plugins/google/calendar-api';
import CalendarRepository from '@/application/repositories/CalendarRepository';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

type DriveDocumentState = {
    syncInfo: any;
    isSyncProcessing: boolean;
};

export default defineStore({
    id: storeId.CALENDAR_SYNC,
    state: () =>
        ({
            syncInfo: null,
            isSyncProcessing: false,
        } as DriveDocumentState),
    getters: {
        // syncStatus: (state) => {
        //     if (!state.googleUser) return CalendarSyncStatus.NotSync;
        //
        //     if (state.isSyncProcessing) return CalendarSyncStatus.InProcessing;
        //
        //     if (!state.syncSettings?.enabled) return CalendarSyncStatus.NotSync;
        //
        //     return state.lastSyncInfo?.status;
        // }
    },
    actions: {
        async initGoogleCalendar() {
            GoogleCalendarApi.initCalendarClient();
        },

        async getUserCalendarSync() {
            const res =
                await CalendarRepository.getInstance().getUserCalendarSync();

            return (this.syncInfo = res?.result);
        },

        async signInUserCalendarSync() {
            const auth = await GoogleCalendarApi.signIn();
            if (!auth?.code) return (this.syncInfo = null);

            const res =
                await CalendarRepository.getInstance().signInUserCalendarSync({
                    code: auth.code,
                });

            return (this.syncInfo = res?.result);
        },

        async signOutUserCalendarSync() {
            settingGlobalModal({
                type: 'confirm',
                title: translate('CALENDAR_SYNC_LOGOUT'),
                content: translate('CALENDAR_SYNC_LOGOUT_MSG'),
                confirmLabel: translate('COMMON_LABEL_CONFIRM'),
                confirmable: true,
                closeable: true,
            });

            const confirmed = await ask();
            if (!confirmed) return;

            const res =
                await CalendarRepository.getInstance().signOutUserCalendarSync();

            if (res?.result) GoogleCalendarApi.signOut();

            return (this.syncInfo = res?.result);
        },

        async saveUserCalendarSync(data: any) {
            const res =
                await CalendarRepository.getInstance().saveUserCalendarSync(
                    data
                );

            return (this.syncInfo = res?.result);
        },
    },
});
