<script setup lang="ts">
import { computed } from 'vue';
// import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
// import { encryptUserId, encryptGroupId } from '@/ui/plugins/crypto/crypto';
import { NotificationActionTypeGed } from '@/ui/common/constants/notifications';
import { currentTimeDevice } from '@/ui/hooks/calendarHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
// import userStore from '@/store/user';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import { INotificationTask } from '@/application/types/notification.types';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const props = withDefaults(
  defineProps<{
    propId?: string;
    notificationItem?: any;
    isView?: boolean;
    isShowActionUser?: boolean;
    users?: any;
  }>(),
  {
    isShowActionUser: true,
  }
);
const emit = defineEmits([
  'getNotificationLinkUrl',
  'handleClickMarkRead',
  'handleDeleteNotification',
  'onUserActionClick',
]);

const notification = computed<INotificationTask>(() => props.notificationItem);
const mainData = computed<any>(() => notification.value?.mainData);

const notifExecutorUser = computed(() =>
  props.users?.find((user: any) => user?.id === notification.value?.executorId)
);

// const router = useRouter();
// const _userStore = userStore();

// const avatarUserById = (avatarNotification, userId) => {
//   return _userStore.updateAvatarUserToAvatarCurrent(
//     avatarNotification,
//     userId,
//     'small'
//   );
// };

const getNotificationLinkUrl = (notification) => {
  if (notification?.sharedFolderUrl) {
    return notification?.sharedFolderUrl;
  }
  return `/`;
};

// const onGotoLinkResources = (event, notification, type) => {
//   event.preventDefault();
//   if (type === 'group') {
//     const linkResources = notification?.sharedFolderUrl.split('/')[1];
//     router.push('/' + linkResources);
//   } else {
//     router.push(`/resources?userId=${notification?.executorId}`);
//   }
// };

const handleCreateTimeNotification = (createdDate) => {
  if (!createdDate) {
    return;
  }
  let utcDate = currentTimeDevice().utcDate;

  const dayTimeZone = dayjs(createdDate).add(utcDate, 'hour');
  return dayjs(dayTimeZone).fromNow();
};

const handleClickMarkRead = (id) => {
  emit('handleClickMarkRead', id);
};
const handleDeleteNotification = (id) => {
  emit('handleDeleteNotification', id);
};

const statusTypeFileNotification = (itemType, typeFile) => {
  if (itemType.toUpperCase() == 'GED') {
    switch (typeFile) {
      case 'folder':
        return {
          nameIcon: 'folder',
          bgColor: 'bg-blue-500',
          textColor: 'text-blue-500',
          label: translate('GED_FOLDER'),
        };
      case 'link':
        return {
          nameIcon: 'link',
          bgColor: 'bg-blue-500',
          textColor: 'text-blue-500',
          label: translate('GED_LINK_URL'),
        };

      default:
        return {
          nameIcon: 'file',
          bgColor: 'bg-blue-500',
          textColor: 'text-blue-500',
          label: translate('GED_FILE_DOCUMENT'),
        };
    }
  } else {
    return {
      nameIcon: 'group',
      bgColor: 'bg-gray-500',
      textColor: 'text-gray-500',
    };
  }
};

const actionTypeFileNotification = (itemType, actionType) => {
  if (itemType.toUpperCase() == 'GED') {
    switch (actionType) {
      case NotificationActionTypeGed.NewGroupFolder.value:
      case NotificationActionTypeGed.NewGroupFile.value:
      case NotificationActionTypeGed.NewGroupLink.value:
      case NotificationActionTypeGed.NewFolder.value:
      case NotificationActionTypeGed.NewFile.value:
        return {
          bgColor: 'bg-current-500',
          textColor: 'text-current-500',
          label: translate('NOTIFICATION_LABEL_TYPE_FILE_ADD_NEW'),
        };
      case 'Updated':
        return {
          bgColor: 'bg-orange-500',
          textColor: 'text-orange-500',
          label: translate('NOTIFICATION_LABEL_TYPE_FILE_UPDATED'),
        };
      case 'Rename':
        return {
          bgColor: 'bg-orange-500',
          textColor: 'text-orange-500',
          label: translate('NOTIFICATION_LABEL_TYPE_FILE_RENAME'),
        };
      case 'Remove':
        return {
          bgColor: 'bg-orange-500',
          textColor: 'text-orange-500',
          label: translate('NOTIFICATION_LABEL_TYPE_FILE_REMOVE'),
        };
      case 'Deleted':
        return {
          bgColor: 'bg-red-500',
          textColor: 'text-red-500',
          label: translate('NOTIFICATION_LABEL_TYPE_FILE_DELETE'),
        };

      default:
        return {
          bgColor: 'bg-blue-500',
          textColor: 'text-blue-500',
          label: translate('NOTIFICATION_LABEL_TYPE_FILE_SHARE'),
        };
    }
  } else {
    return {
      nameIcon: 'group',
      bgColor: 'bg-gray-500',
      textColor: 'text-gray-500',
    };
  }
};
</script>

<template>
  <div
    :id="`noti-${notification?.id}-${propId}`"
    :class="!notification?.status ? 'bg-current-50 bg-opacity-30' : ''"
    class="
      item-noti
      relative
      border-b border-gray-200
      flex
      items-center
      px-2
      py-1
      text-sm text-gray-700
      hover:bg-current-50 hover:bg-opacity-50
      cursor-pointer
    "
  >
    <div class="relative flex items-center w-full">
      <!--      <syn-avatar-status-->
      <!--        :name="notification.executorFullName"-->
      <!--        :src="-->
      <!--          avatarUserById(-->
      <!--            notification?.executorAvatarUrl,-->
      <!--            notification?.executorId || notification?.userId-->
      <!--          )-->
      <!--        "-->
      <!--        :color-status="-->
      <!--          actionTypeFileNotification(-->
      <!--            notification?.type,-->
      <!--            notification?.actionType-->
      <!--          )?.bgColor-->
      <!--        "-->
      <!--        :name-icon="-->
      <!--          statusTypeFileNotification(-->
      <!--            notification?.type,-->
      <!--            notification?.shareType-->
      <!--          )?.nameIcon-->
      <!--        "-->
      <!--      />-->
      <div class="relative">
        <UserByAction
          :avatar-class="'h-12 w-12'"
          :user-id="notification?.executorId"
          :user="notifExecutorUser"
          :is-show-action-user="isShowActionUser"
          is-hidden-name
          @on-action-click="$emit('onUserActionClick', $event)"
        />
        <div
          class="
            absolute
            -bottom-1
            rounded-full
            flex-center
            -right-2
            border border-white
            w-7
            h-7
          "
          :class="
            actionTypeFileNotification(
              notification?.type,
              notification?.actionType
            )?.bgColor
          "
          :style="{
            backgroundColor: actionTypeFileNotification(
              notification?.type,
              notification?.actionType
            )?.bgColor,
          }"
        >
          <SynIconCustom
            class="fill-white text-white"
            :name="
              statusTypeFileNotification(
                notification?.type,
                mainData?.shareType
              )?.nameIcon
            "
            is-active
          />
        </div>
      </div>

      <div class="w-full flex flex-col pl-3">
        <!-- P1: BODY NOTIFICATION -->
        <div class="flex flex-col pb-1">
          <a :href="getNotificationLinkUrl(notification)" target="_blank">
            <div
              :title="
                notification?.body ? notification?.body : notification?.taskName
              "
              class="pl-2 text-overflow-hidden"
              :class="
                !notification?.status
                  ? 'text-black font-medium'
                  : 'text-gray-700'
              "
            >
              {{
                notification?.body ? notification?.body : notification?.taskName
              }}
            </div>
          </a>
        </div>

        <!-- P2: OPTION -->
        <div
          class="flex w-full justify-between items-end space-x-4"
          :class="!notification.status ? 'text-current-800' : 'text-gray-500'"
        >
          <div class="flex items-end space-x-3 text-xs pl-2">
            <span
              v-if="mainData?.sharedFolderName"
              :title="mainData?.sharedFolderName"
              class="font-bold truncate max-w-min w-32"
              >{{ mainData?.sharedFolderName }}</span
            >
            <div
              v-if="notification?.actionType"
              class="flex-center space-x-1 text-xs font-medium text-gray-800"
            >
              <span>{{
                actionTypeFileNotification(
                  notification?.type,
                  notification?.actionType
                )?.label
              }}</span>
            </div>

            <!-- <span
              v-if="notification?.groupedContent"
              :title="notification?.groupedContent"
              class="
                text-current-800 text-overflow-hidden-line
                align-text-bottom
              "
              style="font-size: 10.5px"
            >
              {{ notification?.groupedContent }}
            </span> -->
          </div>
        </div>

        <!-- P3: GROUP NAME, EXECUTOR NAME -->
        <div
          class="flex items-center space-x-1 justify-between"
          style="padding-top: 2.5px"
        >
          <div class="flex flex-col">
            <div
              style="font-size: 11px"
              class="pl-2 w-full flex items-start space-x-1"
            >
              <span
                v-if="notification?.groupName"
                :title="
                  mainData?.sharedFolderName
                    ? notification?.groupName +
                      ' /.../ ' +
                      mainData?.sharedFolderName
                    : notification?.groupName
                "
                class="text-gray-600"
              >
                {{ notification?.groupName }}
              </span>
              <span v-if="notification?.groupName">{{ '-' }}</span>
              <span>
                <span>{{ $t('TASK_DETAIL_LABEL_BY') + ' ' }}</span>
                <span class="text-gray-600">
                  {{
                    notification?.executorName || notification?.executorFullName
                  }}
                </span>
              </span>
            </div>
          </div>

          <div class="flex space-x-2 items-center">
            <span class="text-gray-500" style="font-size: 12px">
              {{
                handleCreateTimeNotification(
                  notification?.eventActionDate
                    ? notification?.eventActionDate
                    : notification?.createdDate
                )
              }}
            </span>
            <span
              v-if="!notification?.status"
              class="rounded-full w-2 h-2 bg-blue-500 mt-0.5"
            ></span>
          </div>
        </div>

        <!-- BUTTON: ACTION SEEN, REMOVE -->
        <div
          v-if="!isView"
          class="
            item-noti__action
            absolute
            right-0
            top-1
            flex
            items-center
            space-x-2
          "
        >
          <div
            v-if="!notification?.status"
            :title="$t('COMMON_LABEL_SEEN')"
            class="
              border border-green-500
              bg-white bg-opacity-70
              flex-center
              w-6
              h-6
              rounded-full
              text-green-500
              fill-green-500
              hover:fill-white hover:text-white hover:bg-green-500
            "
            @click.stop="handleClickMarkRead(notification?.id)"
          >
            <SynIcon name="check" size="small" />
          </div>

          <div
            :title="$t('NOTIFICATION_DELELTE_NOTIFICATION_SUB')"
            class="
              border border-red-500
              flex-center
              bg-white bg-opacity-70
              w-6
              h-6
              rounded-full
              text-red-500
              fill-red-500
              hover:fill-white hover:text-white hover:bg-red-500
            "
            @click.stop="handleDeleteNotification(notification?.id)"
          >
            <SynIcon
              name="cancel"
              size="small"
              custom-class="w-2.5 h-2.5"
              is-active
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item-noti:hover .item-noti__action {
  /* display: block; */
  visibility: visible;
}

.item-noti__action {
  /* display: none; */
  visibility: hidden;
}

.item-noti:active {
  visibility: visible;
}

.item-noti:active .item-noti__action {
  /* display: block; */
  visibility: visible;
}
</style>
