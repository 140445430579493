<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 144 200"
  >
    <path
      d="M20.21,0C9.05,0,0,9.05,0,20.21V179.79c0,11.16,9.05,20.21,20.21,20.21H59.79c11.16,0,20.21-9.05,20.21-20.21V0H20.21Zm43.79,169.65c0,7.93-6.42,14.35-14.35,14.35H30.35c-7.93,0-14.35-6.42-14.35-14.35V30.35c0-7.93,6.42-14.35,14.35-14.35h33.65V169.65Z"
    />
    <path
      d="M124,0h-60V130h60c11.05,0,20-8.95,20-20V20c0-11.05-8.95-20-20-20Zm4,100.55c0,7.43-6.02,13.45-13.45,13.45h-34.55V16h34.55c7.43,0,13.45,6.02,13.45,13.45V100.55Z"
    />
  </svg>
</template>
