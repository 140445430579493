import { GOOGLE_MAP_KEY } from '@/ui/plugins/keys.private';

// @ts-ignore
window.initPlaces = () => {};

export const initializeGoogleApis = () => {
    const key = GOOGLE_MAP_KEY;

    const googleMapScript = document.createElement('SCRIPT');
    googleMapScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initPlaces`
    );
    googleMapScript.setAttribute('defer', '');
    googleMapScript.setAttribute('async', '');

    document.head.appendChild(googleMapScript);
};

export const getPlaceFromLocation = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
        const latLng = { lat: latitude, lng: longitude };
        // @ts-ignore
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    // Found place
                    resolve(results[0]);
                } else {
                    // No results found
                    reject('No place found');
                }
            } else {
                // Error occurred
                reject('Error occurred');
            }
        });
    });
};

export const extractPlaceInfo = (
    place: any,
    infoType:
        | 'number'
        | 'street'
        | 'district'
        | 'city'
        | 'state'
        | 'country'
        | 'zip_code'
        | 'address'
) => {
    switch (infoType) {
        case 'number':
            return place?.address_components
                ?.filter((c) => c?.types?.some((t) => t === 'street_number'))
                ?.map((c) => c?.long_name)
                ?.find(() => true);
        case 'street':
            return place?.address_components
                ?.filter((c) => c?.types?.some((t) => t === 'route'))
                ?.map((c) => c?.long_name)
                ?.find(() => true);
        case 'district':
            return place?.address_components
                ?.filter((c) =>
                    c?.types?.some((t) => t === 'administrative_area_level_2')
                )
                ?.map((c) => c?.long_name)
                ?.find(() => true);
        case 'city':
            return place?.address_components
                ?.filter((c) => c?.types?.some((t) => t === 'locality'))
                ?.map((c) => c?.long_name)
                ?.find(() => true);
        case 'state':
            return place?.address_components
                ?.filter((c) =>
                    c?.types?.some((t) => t === 'administrative_area_level_1')
                )
                ?.map((c) => c?.long_name)
                ?.find(() => true);
        case 'country':
            return place?.address_components
                ?.filter((c) => c?.types?.some((t) => t === 'country'))
                ?.map((c) => c?.long_name)
                ?.find(() => true);
        case 'zip_code':
            return place?.address_components
                ?.filter((c) => c?.types?.some((t) => t === 'postal_code'))
                ?.map((c) => c?.short_name)
                ?.find(() => true);
        case 'address':
            return place?.formatted_address;
        default:
            return;
    }
};
