<script setup lang="ts">
import { computed, ref } from 'vue';
import { useMouseInElement } from '@vueuse/core';
import { useElementHover } from '@vueuse/core';
import DoughnutChart from '@/ui/common/plugins/chart/DoughnutChart.vue';

const props = defineProps<{
  overviewTask: {
    totalTaskOnDay: number;
    totalFinished: number;
    totalCreated: number;
  };
}>();

const membersStartedToday = computed(() => {
  return [
    {
      data: props.overviewTask?.totalFinished || 0,
      color: '#4bb0b2',
    },
    {
      data: Math.max(
        props.overviewTask?.totalTaskOnDay - props.overviewTask?.totalFinished,
        0
      ),
      color: '#e5e7eb',
    },
  ];
});
// end mock

const { x: mouseX, y: mouseY } = useMouseInElement(document.body);

const isOpenDetail = ref(false);
const dementionByMouse = ref<any>({});
let delayToShowDetail;
const detailModalRef = ref();
const isHoveredDetailModal = useElementHover(detailModalRef);
const onOpenDetail = () => {
  if (isOpenDetail.value) return;
  if (delayToShowDetail) clearTimeout(delayToShowDetail);

  delayToShowDetail = setTimeout(() => {
    isOpenDetail.value = true;
    dementionByMouse.value = {
      top: `${mouseY.value + 10}px`,
      left: `${mouseX.value + 10}px`,
    };
  }, 750);
};

const onMouseleaveCard = () => {
  if (delayToShowDetail) clearTimeout(delayToShowDetail);
  setTimeout(() => {
    if (isHoveredDetailModal.value) return;
    isOpenDetail.value = false;
  }, 500);
};
const onCloseDetailModal = () => {
  isOpenDetail.value = false;
};
</script>
<template>
  <div
    class="w-52 flex flex-col bg-white"
    style="box-shadow: 0px 2px 6px #9ca3af47; border-radius: 5px"
    @mouseover="onOpenDetail"
    @mouseleave="onMouseleaveCard"
  >
    <span class="text-gray-600 font-semibold text-sm text-center p-4">
      {{ $t('MONITOR_STATISTIC_FINISHED_TASKS') }}
    </span>
    <div class="flex-1 flex-center">
      <DoughnutChart
        :prop-data="membersStartedToday"
        :label-no-data="$t('COMMOM_LABEL_NOT_DATA_YET')"
        :is-show-description="false"
        is-show-progress
        :progress="25"
        cut-out="75%"
        custom-class="w-36 h-36"
      >
        <template #progress>
          <div class="absolute flex-center flex-col m-auto">
            <span class="font-medium text-current-400 text-2xl">
              {{ overviewTask.totalFinished }}/{{ overviewTask.totalTaskOnDay }}
            </span>
            <span class="text-gray-600 lowercase">
              {{ $t('TASK_TABLE_LABEL_TASKS') }}
            </span>
          </div>
        </template>
      </DoughnutChart>
    </div>
  </div>

  <AtomDialog v-if="isOpenDetail" :dimension="dementionByMouse">
    <template #body>
      <div
        ref="detailModalRef"
        class="w-full p-4 flex flex-col space-y-4"
        style="min-width: 16rem"
        @mouseleave="onCloseDetailModal"
      >
        <!-- HEADER -->
        <section class="flex justify-between">
          <span class="text-gray-800 font-semibold">
            {{ $t('MONITOR_STATISTIC_FINISHED_TASKS_MODAL_TITLE') }}
          </span>
        </section>

        <!-- body -->
        <section class="flex-1 w-full flex flex-col space-y-1">
          <div class="w-full flex items-center justify-between px-2">
            <div class="flex-center space-x-2">
              <span class="w-2 h-2 rounded-full bg-gray-200"></span>
              <span class="text-gray-600 font-semibold text-sm">
                {{ $t('MONITOR_STATISTIC_FINISHED_TASKS_DO_TODAY') }}
              </span>
            </div>
            <span class="text-current-800 font-semibold">
              {{ overviewTask.totalTaskOnDay }}
            </span>
          </div>
          <div class="w-full flex items-center justify-between px-2">
            <div class="flex-center space-x-2">
              <span class="w-2 h-2 rounded-full bg-current"></span>
              <span class="text-gray-600 font-semibold text-sm">
                {{ $t('MONITOR_STATISTIC_FINISHED_TASKS_FINISHED_TODAY') }}
              </span>
            </div>
            <span class="text-current-800 font-semibold">
              {{ overviewTask.totalFinished }}
            </span>
          </div>
        </section>
      </div>
    </template>
  </AtomDialog>
</template>
