<script setup lang="ts">
import { computed } from 'vue';
import { INotificationMainDataTaskUpdate } from '@/application/types/notification.types';

const props = defineProps<{
  beforeUpdate: INotificationMainDataTaskUpdate;
  afterUpdate: INotificationMainDataTaskUpdate;
}>();
const beforeValue = computed(() => {
  let result = props.beforeUpdate?.domainName;
  if (props.beforeUpdate?.projectId)
    result += ` - ${props.beforeUpdate?.projectName}`;
  if (props.beforeUpdate?.subprojectId)
    result += ` - ${props.beforeUpdate?.subProjectName}`;

  return result;
});
const afterValue = computed(() => {
  let result = props.afterUpdate?.domainName;
  if (props.afterUpdate?.projectId)
    result += ` - ${props.afterUpdate?.projectName}`;
  if (props.afterUpdate?.subprojectId)
    result += ` - ${props.afterUpdate?.subProjectName}`;

  return result;
});
</script>
<template>
  <div class="inline-flex space-x-1 text-overflow-hidden-line">
    <template v-if="beforeValue">
      <p>
        {{ $t('COMMON_LABEL_CHANGE_FROM') }}
      </p>
      <p
        class="text-overflow-hidden-line font-semibold italic"
        v-html="beforeValue"
      ></p>
    </template>

    <p>
      {{ $t('COMMON_LABEL_CHANGE_TO') }}
    </p>
    <p
      class="text-overflow-hidden-line font-semibold italic uppercase underline"
      v-html="afterValue"
    ></p>
  </div>
</template>
