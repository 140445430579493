import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DayoffService from '@/application/services/DayoffService';

export default defineStore({
    id: storeId.DAYOFF_DETAIL,
    state: () => ({
        _dayoffService: DayoffService.getInstance(),
    }),
    getters: {},
    actions: {
        getDetailById(id) {
            return this._dayoffService.getDetailById(id);
        },
    },
});
