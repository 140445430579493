<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { colorByAlphabet, convertViToEn, cleanString } from './colorByAlphabet';

const props = defineProps({
  src: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  subName: {
    type: String,
    default: null,
  },
  hasName: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'normal',
  },
  customClass: {
    type: String,
    default: '',
  },
  nameIcon: {
    type: String,
    default: '',
  },
  colorStatus: {
    type: String,
    default: 'bg-current-600',
  },
});
const textAva = computed(() => {
  if (!props?.name) return 'A';
  return cleanString(props.name)
    .split(' ')
    [cleanString(props.name).split(' ').length - 1].charAt(0);
});
const srcImg = ref(props.src);
const sizeAvatar = computed(() => {
  if (props.size == 'mini') return 'h-10 w-10';
  else if (props.size == 'tiny') return 'h-4 w-4';
  return 'h-12 w-12';
});
const sizeIcon = computed(() => {
  if (props.size == 'mini') return 'w-3 h-3';
  else if (props.size == 'tiny') return 'h-2 w-2';
  return 'w-3 h-3';
});
const sizeStatus = computed(() => {
  if (props.size == 'mini') return 'w-6 h-6';
  else if (props.size == 'tiny') return 'w-3 h-3';
  return 'w-7 h-7';
});
watch(
  () => props.src,
  () => {
    srcImg.value = props.src;
  }
);
</script>

<template>
  <div class="relative" :class="[sizeAvatar, customClass]">
    <div :class="[sizeAvatar, customClass]">
      <div
        v-if="hasName"
        class="flex items-center"
        :class="[sizeAvatar, customClass]"
      >
        <SynImg
          class="object-cover rounded-full shadow"
          :class="[sizeAvatar, customClass]"
          :src="srcImg?.replace('original', 'mini')"
        />

        <div class="hidden md:flex flex-col items-start pl-2">
          <div class="font-medium text-current-800">{{ name }}</div>
          <slot name="subName"></slot>
        </div>
      </div>
      <div v-else :class="[sizeAvatar, customClass]">
        <SynImg
          v-if="srcImg"
          class="object-cover rounded-full shadow"
          :class="[sizeAvatar, customClass]"
          :src="srcImg?.replace('original', 'mini')"
        />
        <div
          v-else
          :class="[sizeAvatar, customClass]"
          class="
            object-cover
            flex-center
            text-white
            shadow
            font-thin
            rounded-full
          "
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
        >
          <span class="uppercase">{{
            name ? name.split(' ')[name.split(' ').length - 1].charAt(0) : 'A'
          }}</span>
        </div>
      </div>
    </div>
    <div
      class="
        absolute
        -bottom-1
        rounded-full
        flex-center
        -right-2
        border border-white
      "
      :class="[colorStatus, sizeStatus]"
      :style="{
        backgroundColor: colorStatus,
      }"
    >
      <SynIconCustom
        class="fill-white text-white"
        :custom-class="sizeIcon"
        :name="nameIcon"
        is-active
      />
    </div>
  </div>
</template>
