<template>
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 512 512"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path d="M411 8.782V100h91.211z" opacity="1"></path>-->
  <!--      <path-->
  <!--        d="M396 130c-8.284 0-15-6.716-15-15V0H166c-24.813 0-45 20.187-45 45v167.689c4.942-.448 9.943-.689 15-.689 51.128 0 96.897 23.376 127.186 60H426c8.284 0 15 6.716 15 15s-6.716 15-15 15H282.948a163.749 163.749 0 0 1 17.363 60H426c8.284 0 15 6.716 15 15s-6.716 15-15 15H300.311c-4.486 49.539-30.954 92.826-69.553 120H466c24.813 0 45-20.187 45-45V130zm30 82H206c-8.284 0-15-6.716-15-15s6.716-15 15-15h220c8.284 0 15 6.716 15 15s-6.716 15-15 15z"-->
  <!--        opacity="1"-->
  <!--      ></path>-->
  <!--      <path-->
  <!--        d="M136 242C61.561 242 1 302.561 1 377s60.561 135 135 135 135-60.561 135-135-60.561-135-135-135zm40 150h-40c-8.284 0-15-6.716-15-15v-60c0-8.284 6.716-15 15-15s15 6.716 15 15v45h25c8.284 0 15 6.716 15 15s-6.716 15-15 15z"-->
  <!--        opacity="1"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->

  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <circle cx="9" cy="5" r="5" opacity="1" class=""></circle>
      <path
        d="M9.5 17.5c0-2.131.843-4.065 2.206-5.5H4.75A4.756 4.756 0 0 0 0 16.75v3.5c0 .414.336.75.75.75h9.567a7.941 7.941 0 0 1-.817-3.5z"
        opacity="1"
        class=""
      ></path>
      <path
        d="M17.5 11c-3.584 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5 6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm2.207 8.707a.997.997 0 0 1-1.414 0l-1.5-1.5a.996.996 0 0 1-.293-.707V15a1 1 0 1 1 2 0v2.086l1.207 1.207a.999.999 0 0 1 0 1.414z"
        opacity="1"
        class=""
      ></path>
    </g>
  </svg>
</template>
