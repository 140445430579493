import { ref } from 'vue';
import permissionStore from '@/store/permission';
import myProfileStore from '@/store/auth/my-profile';

export const isOpenUserBar = ref(false);
export const routeBeforeGotoSetting = ref('/');

export const onToggleSettingPage = (isOpen, router, route) => {
    const isSettingMenu = route.meta?.isSettingMenu;
    //
    isOpenUserBar.value = isOpen;
    if (isOpenUserBar.value) {
        if (!isSettingMenu) routeBeforeGotoSetting.value = route.fullPath;
        gotoDefaultSettingPageByUserRole(router);
    } else {
        router.push(routeBeforeGotoSetting.value);
    }
};

const gotoDefaultSettingPageByUserRole = (router) => {
    const model = permissionStore().organizationModel;

    if (
        model == 'PERSONAL' ||
        myProfileStore().isUser ||
        myProfileStore().isVisitor
    ) {
        router.push({ name: 'PersonalSetting' });
        return;
    }
    if (myProfileStore().isOwner || myProfileStore().isAdmin) {
        // window.open('/user-management', '_blank');
        router.push({ name: 'UserManagement' });
        return;
    }
};
