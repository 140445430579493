<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';

const props = withDefaults(
  defineProps<{
    currentId: string | number;
    placeholder: string;
    data: any[];
    inputClass: string;
    dropdownClass: string;
    objectContact: object;
    name: string | any;
    removeable: boolean;
    isEdit: boolean;
  }>(),
  {
    inputClass: 'py-2 px-3 rounded-md focus:outline-none focus:shadow-outline',
    dropdownClass:
      'absolute z-50 bg-white border border-gray-300 mt-1 mh-48 overflow-hidden overflow-y-auto rounded-md shadow-md',
  }
);
const emit = defineEmits(['chosen', 'reset', 'blur']);
const isInputting = ref(false);
const showOptions = ref(false);

const searchTerm = ref('');
const searchResults = computed(() => {
  const searchTxt = ignoreUnicode(searchTerm.value);

  if (!searchTxt) return props.data;

  return (props.data || []).filter(
    (item: any) => item?.name && ignoreUnicode(item?.name).includes(searchTxt)
  );
  // return props.data?.filter((item) => {
  //   return item.name.toLowerCase().includes(searchTerm.value.toLowerCase());
  // });
}) as any;

const vigDropdownRef = ref<any>(null);
watch(
  () => props.data?.length,
  () => {
    vigDropdownRef.value?.onForceClose();
    vigDropdownRef.value?.onForceOpen();
  }
);
const reset = () => {
  emit('reset');
  searchTerm.value = '';
  onOpenAssign();
};

const chooseItem = (item) => {
  emit('chosen', item);
  showOptions.value = false;
  isInputting.value = false;
  searchTerm.value = '';
};

const clickedOutside = () => {
  showOptions.value = false;
  // isInputting.value = false;
};

const inputRef = ref<any>(null);

const onOpenAssign = () => {
  if (!props.isEdit) return;
  indexResult.value = -1;
  showOptions.value = true;
  isInputting.value = true;

  inputRef.value?.focus();
};

const onClickInput = () => {
  indexResult.value = -1;
  showOptions.value = true;
};

const keyActive = ref('search-assign');
const indexResult = ref(-1);
const onKeyDownSearch = () => {
  indexResult.value++;
  if (indexResult.value == searchResults.value.length) {
    indexResult.value = 0;
  }
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
const onKeyUpSearch = () => {
  indexResult.value--;
  keyActive.value = `search-assign-${indexResult.value}`;
  if (indexResult.value < 0) {
    indexResult.value = searchResults.value.length - 1;
  }
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
const onChooseResult = () => {
  let item = searchResults.value[indexResult.value];
  emit('chosen', item);
  showOptions.value = false;
  isInputting.value = false;
  searchTerm.value = '';
};

const onBlur = () => {
  isInputting.value = false;
  emit('blur');
};
</script>

<template>
  <VigDropdown
    ref="vigDropdownRef"
    :arrow="false"
    hide-on-click="outside"
    :z-index="60"
    @on-dropdown-open="onOpenAssign"
    @on-dropdown-close="clickedOutside"
    @click.stop="onOpenAssign"
  >
    <template #dropdown-toggle>
      <div class="relative py-1" @click="showOptions = true">
        <input
          v-if="isInputting && isEdit"
          ref="inputRef"
          v-model="searchTerm"
          tabindex="0"
          class="w-full pl-3"
          :placeholder="placeholder"
          :class="inputClass"
          @focus="onClickInput"
          @blur="onBlur"
          @keyup.down="onKeyDownSearch"
          @keyup.up="onKeyUpSearch"
          @keyup.enter="onChooseResult"
        />
        <span
          v-else
          :title="name ? name : ''"
          class="
            w-full
            rounded
            focus:bg-white focus:outline focus:border-transparent
            flex
            items-center
            pl-3
            pr-8
            text-overflow-hidden-line text-black
          "
          :class="[
            inputClass,
            isEdit ? 'cursor-pointer bg-white border' : 'bg-gray-50',
          ]"
          @click="onOpenAssign()"
        >
          <span v-if="name" class="truncate text-overflow-hidden-line">{{
            name
          }}</span>
        </span>
        <div
          v-if="isEdit"
          class="
            flex-center
            h-6
            w-6
            cursor-pointer
            text-gray-400
            hover:bg-current-50 hover:text-gray-700
            absolute
            right-2
            top-3.5
            rounded-full
          "
          @click.stop="reset"
        >
          <SynIcon custom-class="h-5 w-5" name="close" />
        </div>
      </div>
    </template>
    <template v-if="showOptions && isEdit" #dropdown-menu>
      <div class="flex flex-col">
        <div style="min-width: 12rem">
          <slot name="header"></slot>
        </div>
        <ul
          class="z-100 w-max rounded-md small-scrollbar overflow-auto syn p-2"
          style="width: 12rem; max-height: 24rem"
        >
          <li
            v-for="(item, index) in searchResults"
            :id="`search-assign-${index}`"
            :key="item"
            :class="
              keyActive == `search-assign-${index}` ? 'bg-current-50' : ''
            "
            class="
              flex
              dropdown-item
              items-center
              space-x-2
              px-2
              py-1
              text-gray-700
              hover:bg-current-50
              cursor-pointer
              syn-select-assign-item
              rounded-md
            "
            @click.stop="chooseItem(item)"
          >
            <div class="py-2 flex flex-col items-start">
              <div
                :title="item?.name"
                class="
                  w-full
                  text-sm
                  syn-select-assign-item
                  flex
                  items-center
                  justify-between
                "
              >
                <span class="text-overflow-hidden-line syn-select-assign-item">
                  {{ item?.name }}
                </span>
              </div>
            </div>
          </li>
          <li v-if="!searchResults?.length" class="p-6 text-center">
            <SynAnimation
              name="searchIcon"
              :loop="false"
              stype="width: 100px; height: 100px"
              :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
            />
          </li>
        </ul>
      </div>
    </template>
  </VigDropdown>
</template>

<style scoped>
.mh-48 {
  max-height: 15rem;
}
</style>
