<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import principleStore from '@/store/principle';
import PrincipleService from '@/application/services/PrincipleService';
import TaskService from '@/application/services/task/TaskService';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import TaskSimpleTable from '@/ui/modules/task/table/TaskSimpleTable.vue';
import { TaskApprovalStatus } from '@/application/types/task/task.types';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const emit = defineEmits(['onStop', 'onCancel']);

const router = useRouter();
const _principleStore = principleStore();
const _taskService = TaskService.getInstance();

const isStoping = ref(false);
const loading = ref({ getStopWorkingDayTaskList: false });
// const isWaitingApprovalTasks = ref([] as any);
const stopWorkingDayTasks = ref<{
  finishTodays: any[];
  others: any[];
}>({
  finishTodays: [],
  others: [],
});
const finishTodayTasks = computed<any>(() => {
  return arrayOrderBy(
    [
      ...stopWorkingDayTasks.value?.finishTodays,
      ...stopWorkingDayTasks.value?.others?.filter(
        (t) =>
          t.approvalEnabled &&
          t?.approvalStatus !== TaskApprovalStatus.NOT_REQUESTED
      ),
    ].map((el) => {
      return {
        ...el,
        urgency: getUrgencyValue(el.priority, el.important),
      };
    }),
    [
      (t) => {
        return t.approvalEnabled
          ? t?.approvalStatus == TaskApprovalStatus.APPROVED
            ? 1
            : t?.approvalStatus == TaskApprovalStatus.REJECTED
            ? 2
            : t?.approvalStatus == TaskApprovalStatus.WAITING
            ? 3
            : 4
          : 5;
      },
    ],
    ['asc']
  );
});
const otherTasks = computed<any>(() => {
  return stopWorkingDayTasks.value?.others
    ?.filter((t) => {
      if (
        t.approvalEnabled &&
        t?.approvalStatus !== TaskApprovalStatus.NOT_REQUESTED
      )
        return false;

      return true;
    })
    .map((el) => {
      return {
        ...el,
        urgency: getUrgencyValue(el.priority, el.important),
      };
    });
});

const finishedTaskIds = ref<number[]>([]);

const planToFinishTasks = ref<any>();
onMounted(async () => {
  loading.value['getStopWorkingDayTaskList'] = true;

  const res = await Promise.all([
    _taskService.getTaskListPlannedToTreat(),
    _taskService.getStopWorkingDayTaskList(),
  ]);
  planToFinishTasks.value = res[0];

  const response = res[1];

  loading.value['getStopWorkingDayTaskList'] = false;

  const result = response['result'];

  stopWorkingDayTasks.value = {
    finishTodays: result?.finishedTaskListOnday,
    others: result?.otherFinishedTaskList,
  };
});

const onFinish = async () => {
  if (loading.value['stopWorkingDay']) return;

  loading.value['stopWorkingDay'] = true;
  _taskService.stopWorkingDay(finishedTaskIds.value);

  await PrincipleService.getInstance().startStopWorkingDay({ type: 0 });
  loading.value['stopWorkingDay'] = false;
  _principleStore.fetchUserWorkingStatus();

  router.push({
    name: 'OverviewFinishedTasks',
  });

  isStoping.value = false;
  emit('onCancel', false);
};

const onToggleSelectFinishedTask = (selectedTask) => {
  if (selectedTask?.approvalEnabled) return;

  const isExisted = finishedTaskIds.value?.includes(selectedTask?.id);
  if (isExisted)
    finishedTaskIds.value = finishedTaskIds.value?.filter(
      (id) => id !== selectedTask?.id
    );
  else finishedTaskIds.value.push(selectedTask?.id);
};
const updateTaskItem = (updatedTask) => {
  const index1 = stopWorkingDayTasks.value?.finishTodays?.findIndex(
    (task) => task?.id == updatedTask?.id
  );
  if (index1 > -1) stopWorkingDayTasks.value.finishTodays[index1] = updatedTask;

  const index2 = stopWorkingDayTasks.value?.others?.findIndex(
    (task) => task?.id == updatedTask?.id
  );
  if (index1 > -1) stopWorkingDayTasks.value.others[index2] = updatedTask;
};
</script>

<template>
  <SynModal
    container-class="md:w-2/3 md:h-4/5"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_CONTINUE')"
    :style-body="'py-0'"
    @confirm="isStoping = true"
    @cancel="$emit('onCancel')"
  >
    <template #header>
      <span>
        {{ $t('STOP_WORKING_LABEL_CHECK_TASK_TO_FINISH') }}
      </span>
    </template>

    <template #body>
      <div class="flex-1 min-h-0 flex w-full overflow-y-hidden px-2">
        <div
          class="
            h-full
            sticky
            top-0
            border-r border-current-50
            2xl:block
            hidden
          "
          style="width: 17rem !important"
        >
          <div
            class="
              absolute
              top-1/4
              px-4
              text-center text-xl
              flex
              font-mono
              text-current-400
            "
          >
            <span>{{ $t('STOP_WORKING_LABEL_GOODBYE') }}</span>
          </div>
          <div
            class="
              w-full
              h-full
              bg-current-50 bg-opacity-70
              mx-auto
              flex-center
            "
            style="max-height: 100%; width: 17rem"
          >
            <SynAnimation
              name="finishStopWD"
              stype="width: 200px; height: 200px;"
              class="absolute bottom-0"
            />
          </div>
        </div>

        <div
          class="
            h-full
            overflow-y-auto
            flex-auto
            small-scrollbar
            overflow-x-auto
            px-2
          "
        >
          <div class="font-semibold text-current-800 py-4">
            {{ $t('STOP_WORKING_LABEL_LIST_TASK_TO_FINISH_TODAY') }}
          </div>

          <div v-if="loading?.getStopWorkingDayTaskList">
            <SynAnimation name="my-tasks" />
          </div>
          <template v-else>
            <TaskSimpleTable
              is-hidden-column
              multiple-selectable
              :task-list="finishTodayTasks"
              :selected-id-list="finishedTaskIds"
              :column-list="['NAME', 'GROUP', 'URGENCY', 'DEADLINE', 'CREATOR']"
              is-disable-select-if-approval-task
              @open-detail="onToggleSelectFinishedTask"
              @choose="onToggleSelectFinishedTask"
              @update-task-item="updateTaskItem"
            />
            <template v-if="finishTodayTasks?.length == 0">
              <div class="flex-center flex-col">
                <SynAnimation
                  class="w-full flex-center"
                  name="congratulation"
                  stype="width: 100px; height: 100px;"
                />
                <div
                  class="text-gray-500 text-sm text-center"
                  style="width: 38rem"
                >
                  {{
                    $t(
                      planToFinishTasks?.length == 0
                        ? 'STOP_WORKING_HAS_NOT_PLAN_TO_FINISH_TASK'
                        : 'STOP_WORKING_FINISH_ALL_PLAN_TO_FINISH_TASK'
                    )
                  }}
                </div>
              </div>
            </template>
            <template v-if="otherTasks?.length > 0">
              <div class="font-semibold text-current-800 pb-4 pt-8">
                {{ $t('STOP_WORKING_LABEL_OTHER_TASKS') }}
              </div>

              <!-- other task -->
              <TaskSimpleTable
                multiple-selectable
                :task-list="otherTasks"
                :selected-id-list="finishedTaskIds"
                :column-list="[
                  'NAME',
                  'GROUP',
                  'URGENCY',
                  'DEADLINE',
                  'CREATOR',
                ]"
                is-disable-select-if-approval-task
                @open-detail="onToggleSelectFinishedTask"
                @choose="onToggleSelectFinishedTask"
                @update-task-item="updateTaskItem"
              />
            </template>
          </template>
        </div>
      </div>
    </template>
  </SynModal>

  <SynModal
    v-if="isStoping"
    container-class="w-1/4"
    is-hidden-header
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    @cancel="isStoping = false"
  >
    <template #body>
      <div class="flex-center flex-col h-full py-6">
        <div class="flex items-center justify-center flex-col">
          <syn-animation name="infomation" stype="width: 100px;" />
          <span
            class="text-xl pb-4 text-red-600 text-center font-medium uppercase"
            >{{ $t('COMMON_LABEL_QUESTION_STOP') }}</span
          >
          <span class="text-base text-center font-normal px-3">{{
            $t('COMMON_SUB_LABEL_QUESTION_STOP')
          }}</span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="p-4 flex justify-end items-center cursor-pointer space-x-2">
        <syn-button
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="$emit('onCancel')"
        >
        </syn-button>
        <syn-button
          class="uppercase"
          custom-class-icon="w-3 h-3"
          :label="$t('HEADER_LABEL_STOP')"
          :is-loading="loading['stopWorkingDay']"
          @click="onFinish"
        >
        </syn-button>
      </div>
    </template>
  </SynModal>
</template>
