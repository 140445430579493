<script setup lang="ts">
// import useFocusTrap from '../SynModal/useFocusTrap';

withDefaults(
  defineProps<{
    isLoading?: boolean;
    confirmText?: string;
    zIndex?: string;
    title?: string;
    cancelText?: string;
    previousText?: string;
    isHiddenHeader?: boolean;
    isHiddenFooter?: boolean;
    isHiddenClose?: boolean;
    disableClickOutside?: boolean;
    disableEscToClose?: boolean;
    headerClass?: string;
    styleBody?: string;
    containerClass?: string;
    containerStyle?: string;
    customBody?: string;
    disableAutoFocus?: boolean;
    disableElementActive?: boolean;
    isBackground?: boolean;
    backgroundStyle?: string;
    isDisableButton?: boolean;
    idButton?: boolean;
  }>(),
  {
    zIndex: 'z-40',
    headerClass: 'p-4',
    styleBody: 'p-4',
    containerClass: 'w-11/12 h-auto',
    containerStyle: `max-height: 90%; max-width: 90%;`,
    disableAutoFocus: false,
    isBackground: true,
    isDisableButton: false,
  }
);

defineEmits<{
  (e: 'cancel'): void;
  (e: 'previous'): void;
  (e: 'confirm'): void;
}>();

// const { trapRef } = useFocusTrap();
const elementId = `SYN_MODAL_NO_FOCUS_${Date.now()}`;
</script>

<template>
  <teleport to="body">
    <div
      :id="elementId"
      v-cusFocus="!disableAutoFocus"
      v-element-active="{ id: disableElementActive ? null : elementId }"
      class="
        fixed
        w-screen
        h-screen
        inset-0
        overflow-hidden
        flex-center
        vig-modal-background
      "
      :class="zIndex"
      :style="`${
        isBackground ? 'background: rgba(0, 0, 0, 0.7);' : ''
      } ${backgroundStyle}`"
      tabindex="-1"
      @click.self="!disableClickOutside && $emit('cancel')"
      @keydown.esc="!disableEscToClose && $emit('cancel')"
    >
      <slot name="background"></slot>
      <div
        class="relative shadow-lg bg-white mx-auto rounded-md flex flex-col"
        style="max-height: 90%"
        :class="containerClass"
        :style="containerStyle"
      >
        <div
          class="flex-1 h-full text-left flex flex-col overflow-y-auto"
          :class="customBody"
        >
          <!--Title-->
          <div
            v-if="!isHiddenHeader"
            class="text-lg font-medium text-gray-600 shadow vig-modal-header"
            :class="headerClass"
          >
            <slot name="header">
              <div class="flex items-center">
                <span class="text-base">
                  {{ title }}
                </span>
              </div>
            </slot>
          </div>
          <!--Body-->
          <div
            v-perfect-scrollbar
            class="
              flex-1
              min-h-0
              flex flex-col
              bg-white
              overflow-x-hidden overflow-y-auto
              vig-modal-body
              small-scrollbar
            "
            :class="[
              styleBody,
              !isHiddenFooter ? '' : 'rounded-b-md',
              !isHiddenHeader ? '' : 'rounded-t-md',
            ]"
          >
            <slot name="body"></slot>
          </div>
          <!--Footer-->
          <slot v-if="!isHiddenFooter" name="footer">
            <div
              class="
                p-4
                flex
                justify-end
                items-center
                hover:text-current
                cursor-pointer
                space-x-2
                vig-modal-footer
              "
            >
              <syn-button
                :label="cancelText"
                type-text
                color="gray"
                @click="$emit('cancel')"
              >
              </syn-button>
              <syn-button
                v-if="confirmText"
                :id="idButton ? idButton : ''"
                :is-loading="isLoading"
                :is-disabled="isDisableButton"
                :label="confirmText"
                @click="$emit('confirm')"
              >
              </syn-button>
            </div>
          </slot>
        </div>
        <slot name="close">
          <button
            v-if="!isHiddenClose"
            :title="$t('COMMON_LABEL_CANCEL')"
            class="
              w-8
              h-8
              bg-gray-200
              rounded-full
              cursor-pointer
              hover:text-current-500 hover:bg-gray-100
              absolute
              -top-3
              -right-3
              flex-center
              vig-modal-close
              z-10
            "
            @click="$emit('cancel')"
          >
            <SynIcon name="close" custom-class="w-4 h-4" />
          </button>
        </slot>
      </div>
    </div>
  </teleport>
</template>
