import dayjs from 'dayjs';

import { formatNumberToString } from '@/ui/hooks/commonFunction';
import { getDayOfWeekString } from '@/ui/hooks/calendarHook';

export default {
    install: (app) => {
        app.config.globalProperties.$filters = {
            currency(value, location) {
                let formatter;
                switch (location) {
                    case 'fr':
                        formatter = new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                        });
                        break;
                    case 'vi':
                        formatter = new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND',
                        });
                        break;
                    default:
                        formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        });
                        break;
                }
                return formatter.format(value);
            },
            dayjs(value, temp = 'DD/MM/YYYY', timezone = '0') {
                if (!value) return '';
                const zone = parseInt(timezone);
                const dateTimeWithZone = dayjs(value).add(zone, 'hour');
                return dayjs(dateTimeWithZone).format(temp);
            },
            dayjsUtc(value, temp = 'DD/MM/YYYY', timezone = 0) {
                if (!value) return '';
                const zone = parseInt(timezone.toString());
                const dateTimeWithZone = dayjs(value).add(zone, 'hour');
                return dayjs(dateTimeWithZone).utc().format(temp);
            },
            dayjsGetTime(value, temp = 'HH:mm') {
                if (!value) return '';
                return dayjs(value).format(temp);
            },
            dayjsByZone(value, timezone = '0', temp = 'HH:mm:ss') {
                const zone = parseInt(timezone);
                const currentUtcTime = dayjs.utc(value).add(zone, 'hour');
                return dayjs(currentUtcTime).utc(false).format(temp);
            },
            dayjsFormatFullTime(value, location) {
                if (!value) return;
                const temp = location === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
                return `${getDayOfWeekString(value)}, ${dayjs(value).format(
                    temp
                )}`;
            },
            durationTime(value, format = 'hh mm ss') {
                // value is miliseconds
                // Time calculations for days, hours, minutes and seconds
                const days = Math.floor(value / (1000 * 60 * 60 * 24));
                const hours = Math.floor(
                    (value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor(
                    (value % (1000 * 60 * 60)) / (1000 * 60)
                );
                const seconds = Math.floor((value % (1000 * 60)) / 1000);

                // Output the result in an element with id="demo"
                let result = '';
                switch (format) {
                    case 'HH MM SS':
                        if (days > 0) result += `${days}d`;
                        if (hours > 0) result += ` ${hours} hours`;
                        if (minutes > 0) result += ` ${minutes} minutes`;
                        if (seconds > 0) result += ` ${seconds} seconds`;
                        break;
                    case 'hh mm ss':
                        if (days > 0) result += `${days}d`;
                        if (hours > 0) result += ` ${hours}h`;
                        if (minutes > 0) result += ` ${minutes}m`;
                        if (seconds > 0) result += ` ${seconds}s`;
                        break;
                    case 'hh mm':
                        if (hours > 0) result += ` ${hours}h`;
                        if (minutes > 0) result += ` ${minutes}m`;
                        break;

                    default:
                        result = `${days > 0 ? days + 'd ' : ''}${(
                            '0' + hours
                        ).slice(-2)}:${('0' + minutes).slice(-2)}:${(
                            '0' + seconds
                        ).slice(-2)}`;
                        break;
                }
                return result || '< 1m';
            },
            duration(value, format) {
                let _format = 'mm:ss';
                if (format) {
                    _format = format;
                } else {
                    if (value >= 3.6e6) _format = 'HH:mm:ss';
                    if (value >= 8.64e7) _format = 'DD:HH:mm:ss';
                }
                return dayjs.duration(value).format(_format);
            },
            fromNow(value, withoutSuffix = true) {
                return dayjs(value).fromNow(withoutSuffix);
            },
            fileSize(number, fractionDigits = 1) {
                const kilobytes = 1024;
                const megabytes = Math.pow(kilobytes, 2);
                const gigabytes = Math.pow(kilobytes, 3);

                if (number >= gigabytes) {
                    return (number / gigabytes).toFixed(fractionDigits) + ' GB';
                }
                if (number >= megabytes && number < gigabytes) {
                    return (number / megabytes).toFixed(fractionDigits) + ' MB';
                }
                if (number >= kilobytes && number < megabytes) {
                    return (number / kilobytes).toFixed(fractionDigits) + ' KB';
                }
                return (number || 0) + ' bytes';
            },
            numberToString(number) {
                return formatNumberToString(number);
            },
        };
        app.provide('dayjs', (value, temp = 'DD/MM/YYYY', timezone = '+7') => {
            const zone = parseInt(timezone);
            const dateTimeWithZone = dayjs(value).add(zone, 'hour');
            return dayjs(dateTimeWithZone).format(temp);
        });
        app.provide('fromNow', (value, suffix = true) => {
            return dayjs(value).fromNow(suffix);
        });
        app.provide('dayjsByZone', (value, timezone = '0') => {
            const zone = parseInt(timezone);
            const currentUtcTime = dayjs.utc(value).add(zone, 'hour');
            return dayjs(currentUtcTime).utc(false);
        });
        app.provide('getCurrentTimeByZone', (diff, timezone = '+7') => {
            const zone = parseInt(timezone);
            const now = new Date().getTime();
            return dayjs(now - diff + zone * 60 * 60000);
        });
        app.provide('getServerTime', (diff) => {
            const now = new Date().getTime();
            return now + diff;
        });
        app.provide('getUtcTime', (diff) => {
            const currenTime = new Date();
            return currenTime.getTime() + diff;
        });
        app.provide('getHour', (value, timezone = '+7') => {
            const zone = parseInt(timezone);
            const currentUtcTime = dayjs(value).add(zone, 'hour');
            return dayjs(currentUtcTime).hour();
        });
        app.provide('onFollowOASuccess', () => {
            console.log('Thank you for followed our Offical Account');
            //  Not work - check if it needed
        });
    },
};

export const getDiffDate = (createdDate) => {
    const dStringDate = dayjs.utc(createdDate).local().format('MM/DD/YYYY');
    const todayString = dayjs().format('MM/DD/YYYY');
    const yesterdayString = dayjs(dayjs().add(-1, 'd'))
        .local()
        .format('MM/DD/YYYY');

    if (dStringDate === todayString) {
        return 0;
    } else if (dStringDate === yesterdayString) {
        return 1;
    } else {
        return 2;
    }
};

export const diffDate = (
    date1,
    date2,
    type = 'millisecond',
    isFloat = false
) => {
    const _date1 = dayjs(date1);
    const _date2 = dayjs(date2);
    return _date1.diff(_date2, type, isFloat);
};

export const getUserCurrentDateTime = () => {
    return dayjs();
};

export const formatDate = (value, temp = 'DD/MM/YYYY', timezone = '0') => {
    if (!value) return '';
    const zone = parseInt(timezone);
    const dateTimeWithZone = dayjs(value).add(zone, 'hour');
    return dayjs(dateTimeWithZone).format(temp);
};

export const isTomorrow = (date) => {
    if (!date) return false;

    return dayjs(date).isTomorrow();
};

export const isToday = (date) => {
    if (!date) return false;

    return dayjs(date).isToday();
};

export const isYesterday = (date) => {
    if (!date) return false;

    return dayjs(date).isYesterday();
};
