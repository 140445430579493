<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g>
        <rect
          x="45.322"
          y="118.221"
          style=""
          width="421.325"
          height="393.779"
          fill="#efefef"
          data-original="#efefef"
          class=""
        />
        <rect
          x="27.546"
          y="118.221"
          style=""
          width="456.909"
          height="112"
          fill="#efefef"
          data-original="#efefef"
          class=""
        />
      </g>
      <g>
        <polygon
          style=""
          points="263.101,118.221 367.99,40.888 323.543,0 255.99,112  "
          fill="#008d8d"
          data-original="#dd4e4e"
          class=""
        />
        <polygon
          style=""
          points="248.878,118.221 143.99,40.888 188.436,0 255.99,112  "
          fill="#008d8d"
          data-original="#dd4e4e"
          class=""
        />
      </g>
      <g>
        <polygon
          style=""
          points="263.101,118.221 413.322,107.556 394.655,40.888 260.434,107.556  "
          fill="#01ac9a"
          data-original="#fa7564"
          class=""
        />
        <polygon
          style=""
          points="248.878,118.221 98.657,107.556 117.325,40.888 251.546,107.556  "
          fill="#01ac9a"
          data-original="#fa7564"
          class=""
        />
      </g>
      <polygon
        style=""
        points="273.766,117.335 263.101,118.221 248.878,118.221 238.213,117.335 244.434,91.556   267.546,91.556 "
        fill="#008d8d"
        data-original="#dd4e4e"
        class=""
      />
      <rect
        x="239.099"
        y="118.221"
        style=""
        width="34.668"
        height="112"
        fill="#01ac9a"
        data-original="#fa7564"
        class=""
      />
      <rect
        x="239.099"
        y="230.221"
        style=""
        width="34.668"
        height="280.888"
        fill="#008d8d"
        data-original="#dd4e4e"
        class=""
      />
      <rect
        x="45.322"
        y="264.888"
        style=""
        width="421.325"
        height="34.668"
        fill="#01ac9a"
        data-original="#fa7564"
        class=""
      />
      <rect
        x="45.322"
        y="230.221"
        style=""
        width="421.325"
        height="13.332"
        fill="#dddddd"
        data-original="#dddddd"
        class=""
      />
      <rect
        x="239.099"
        y="230.221"
        style=""
        width="34.668"
        height="13.332"
        fill="#016565"
        data-original="#bf4444"
        class=""
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
