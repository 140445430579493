<script setup lang="ts">
import { computed } from 'vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import { getUrgencyValue } from '@/ui/hooks/taskHook';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});
const beforeActivity = computed(() => ({
  ...props.beforeUpdate,
  urgency: getUrgencyValue(
    props.beforeUpdate?.Priority,
    props.beforeUpdate?.Important
  ),
}));

const afterActivity = computed(() => ({
  ...props.afterUpdate,
  urgency: getUrgencyValue(
    props.afterUpdate?.Priority,
    props.afterUpdate?.Important
  ),
}));
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 items-center justify-between pr-2">
      <PriorityTask
        v-if="beforeActivity?.urgency !== null"
        size="mini"
        :urgency="beforeActivity?.urgency"
      />
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
      <span>→</span>
    </div>
    <div class="flex w-1/2 justify-start pl-4">
      <PriorityTask
        v-if="afterActivity?.urgency !== null"
        size="mini"
        :urgency="afterActivity?.urgency"
      />
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </div>
  </div>
</template>
