import { EResourceUsageStatus } from '@/domain/enums/PlanEnums';
import { EPlanId } from '../constants/plan.const';

export interface PlanInfo {
    id: string;
    index: number;
    code: string;
    lang: string;
    name: string;
    description: string;
    basePrice: number;
    currency: string;
    market: string;
    isActive: boolean;
    planFeatures: any[];
}

export enum EPlanState {
    INIT = 'INIT',
    FREE = 'FREE',
    PAID = 'PAID',
    TRIAL = 'TRIAL',
    OVERDUE_TRIAL = 'OVERDUE_TRIAL',
    OVERDUE_PAID = 'OVERDUE_PAID',
    OVERDUE = 'OVERDUE',
    BLOCK = 'BLOCK',
    EVALUATION = 'EVALUATION',
}

export enum ELicenseType {
    LIMITED = 'LIMITED',
    UNLIMITED = 'UNLIMITED',
    LIFETIME = 'LIFETIME',
    EVALUATION = 'EVALUATION',
}

export interface IOrgCurrentPlan {
    organizationId: number;
    license: ELicenseType;
    startDate: string;
    endDate: string;
    paymentMethod: null;
    billingCycle: null;
    period: null;
    periodUnit: null;
    nextBillingDate: null;
    planId: EPlanId;
    members: number;
    status: string;
    paymentStatus: string;
    lastInvoiceDate: null;
    lastInvoiceAmount: null;
    lastPayer: null;
    changedPlanDate: null;
    usageExceeded: boolean;
    payAtRegistration: boolean;
    stateCode: EPlanState;
    subStateCode:
        | 'DEFAULT'
        | 'INCOMING_EXPIRED'
        | 'INCOMING_EXPIRED_HIGHER'
        | 'INCOMING_EXPIRED_HIGHER_TODAY'
        | 'OVERDUE_LOWER'
        | 'EXPIRED';
    stateStartDate: string;
    stateEndDate: string;
    lastPayerName: null;
    lastPayerAvatar: null;
    lastPayerAvatarUrl: null;
    remainDay: number;
    isLifeTime: boolean;
    offer: {
        reason: string;
        endDate: string;
        remainDays: number;
        bonusDays: number;
    };
    firstDecidePaymentDate?: string;
    lastDateAllowedToUse?: string;
}

export enum EPlanCapacityKey {
    EXCEEDED_ONLY = 'ExceededOnly',
    TOTAL_TASK = 'TotalTask',
    TOTAL_STORAGE_BY_USER = 'TotalStorageByUser',
    TOTAL_STORAGE = 'TotalStorage',
    TOTAL_MEMBER = 'TotalMember',
    TOTAL_VALID_INVITE = 'TotalValidInvite',
    TOTAL_GROUP = 'TotalGroup',
    TOTAL_MESSAGE = 'TotalMessage',
    TOTAL_NOTE = 'TotalNote',
    TOTAL_CALL = 'TotalCall',
    TOTAL_VISITOR = 'TotalVisitor',
    MESSAGE_HISTORY = 'MessageHistory',
    FILE_TASK_HISTORY = 'FileTaskHistory',
}

export interface IPlanCapacityByKey {
    exceededOnly: IPlanCapacity;
    totalTask: IPlanCapacity;
    totalStorageByUser: IPlanCapacity;
    totalStorage: IPlanCapacity;
    totalMember: IPlanCapacity;
    totalValidInvite: IPlanCapacity;
    totalGroup: IPlanCapacity;
    totalMessage: IPlanCapacity;
    totalNote: IPlanCapacity;
    totalCall: IPlanCapacity;
    totalVisitor: IPlanCapacity;
    messageHistory: IPlanCapacity;
    fileTaskHistory: IPlanCapacity;
}

export interface IPlanCapacity {
    key: EPlanCapacityKey;
    limitation: number;
    status: EResourceUsageStatus;
    unit: 'QUANTITY' | 'DAY';
    usage: number;
}
