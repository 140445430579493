<template>
  <img :src="srcImg" :alt="alt" :onerror="onError" />
</template>
<script>
import { ref, watch } from 'vue';
export default {
  name: 'SynImg',
  components: {},
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
  },
  emits: ['onError'],
  setup(props, { emit }) {
    const isError = ref(false);
    const srcImg = ref(props.src);
    watch(
      () => props.src,
      () => {
        srcImg.value = props.src;
      }
    );
    const onError = () => {
      isError.value = true;
      emit('onError');
    };
    return {
      isError,
      srcImg,
      onError,
    };
  },
};
</script>
