import BaseRepository, {
    BaseResponse,
} from '@/base/repositories/BaseRepository';
import { EPlanCapacityKeys } from '@/domain/enums/PlanEnums';
import { IPlanRepository } from '@/domain/interfaces/repositories/IPlanRepository';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

export default class PlansRepository implements IPlanRepository {
    private static instance: PlansRepository;

    constructor() {}

    public static getInstance(): PlansRepository {
        if (!PlansRepository.instance) {
            PlansRepository.instance = new PlansRepository();
        }
        return PlansRepository.instance;
    }

    getCurrentOrgPlan(): any {
        return BaseRepository.requestWithAuthorize({
            url: `Plan/GetCurrentOrgPlan`,
            method: 'get',
        });
    }

    getAllPlansDetail(): any {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);
        return BaseRepository.requestWithAuthorize({
            url: `Plan/GetAllPlansDetail?lang=${lang}`,
            method: 'get',
        });
    }

    getOrgPlanCapacity(filters: EPlanCapacityKeys[]): Promise<BaseResponse> {
        return BaseRepository.requestWithAuthorize({
            url: `Plan/GetOrgPlanCapacityStatus`,
            method: 'post',
            data: {
                filters,
            },
        });
    }

    checkOrganizationUsageExceed(): Promise<BaseResponse> {
        return BaseRepository.requestWithAuthorize({
            url: `Plan/CheckOrganizationUsageExceedVer2`,
            method: 'get',
        });
    }

    getAllPlanComparativeCriteria(): Promise<BaseResponse> {
        return BaseRepository.requestWithAuthorize({
            url: `Plan/GetAllPlanComparativeCriteria`,
            method: 'get',
        });
    }
    getAllPlansCapacity(): Promise<BaseResponse> {
        return BaseRepository.requestWithAuthorize({
            url: `Plan/GetAllPlansCapacity`,
            method: 'get',
        });
    }
}
