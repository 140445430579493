<template>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g clip-path="url(#clip3_1)">
      <path
        id="path"
        d="M91.13 303.78L162.61 303.78L162.61 329.73L91.13 329.73L91.13 303.78ZM91.13 151.88L162.61 151.88L162.61 177.83L91.13 177.83L91.13 151.88ZM91.13 227.83L162.61 227.83L162.61 253.78L91.13 253.78L91.13 227.83ZM212.64 303.78L284.13 303.78L284.13 329.73L212.64 329.73L212.64 303.78ZM91.13 379.72L162.61 379.72L162.61 405.67L91.13 405.67L91.13 379.72ZM212.64 379.72L284.13 379.72L284.13 405.67L212.64 405.67L212.64 379.72ZM212.64 151.88L284.13 151.88L284.13 177.83L212.64 177.83L212.64 151.88ZM212.64 227.83L284.13 227.83L284.13 253.78L212.64 253.78L212.64 227.83Z"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
      <path
        id="path"
        d="M360.07 0L150.22 0C67.38 0 0 67.4 0 150.21L0 512L511.96 512L511.96 167.08L360.07 167.08L360.07 0ZM334.16 486.05L25.91 486.05L25.91 150.21C25.91 81.67 81.68 25.94 150.22 25.94L334.15 25.94L334.16 486.05ZM394.91 243.02L451.21 243.02L451.21 268.97L394.91 268.97L394.91 243.02ZM394.91 318.97L451.21 318.97L451.21 344.91L394.91 344.91L394.91 318.97ZM394.91 394.91L451.21 394.91L451.21 420.86L394.91 420.86L394.91 394.91Z"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
      <circle
        id="circle"
        cx="362.000000"
        cy="362.000000"
        r="150.000000"
        fill="#FFFFFF"
        fill-opacity="1.000000"
      />
      <path
        id="path"
        d="M362 497C436.55 497 497 436.56 497 362C497 287.44 436.56 227 362 227C287.44 227 227 287.44 227 362C227 436.55 287.44 497 362 497ZM362 512C444.84 512 512 444.84 512 362C512 279.15 444.84 212 362 212C279.15 212 212 279.15 212 362C212 444.84 279.15 512 362 512Z"
        fill-opacity="1.000000"
        fill-rule="evenodd"
        fill="#4793F3"
      />
      <path
        id="path"
        d="M422 362C422 362.99 421.8 363.95 421.42 364.87C421.04 365.78 420.5 366.6 419.8 367.3C419.09 368 418.28 368.54 417.37 368.92C416.45 369.31 415.49 369.5 414.5 369.5L309.5 369.5C308.5 369.5 307.54 369.31 306.63 368.92C305.71 368.54 304.9 368 304.19 367.3C303.49 366.6 302.95 365.78 302.57 364.87C302.19 363.95 302 362.99 302 362C302 361 302.19 360.04 302.57 359.13C302.95 358.21 303.49 357.4 304.19 356.69C304.9 355.99 305.71 355.45 306.63 355.07C307.54 354.69 308.5 354.5 309.5 354.5L414.49 354.5C415.49 354.5 416.45 354.69 417.36 355.07C418.28 355.45 419.09 355.99 419.8 356.69C420.5 357.4 421.04 358.21 421.42 359.13C421.8 360.04 422 361 422 362Z"
        fill-opacity="1.000000"
        fill="#4793F3"
        fill-rule="evenodd"
      />
      <path
        id="path"
        d="M362 422C361 422 360.04 421.8 359.13 421.42C358.21 421.04 357.4 420.5 356.69 419.8C355.99 419.09 355.45 418.28 355.07 417.37C354.69 416.45 354.5 415.49 354.5 414.5L354.5 309.5C354.5 308.5 354.69 307.54 355.07 306.62C355.45 305.71 355.99 304.9 356.69 304.19C357.4 303.49 358.21 302.95 359.13 302.57C360.04 302.19 361 302 362 302C362.99 302 363.95 302.19 364.87 302.57C365.78 302.95 366.6 303.49 367.3 304.19C368 304.9 368.54 305.71 368.92 306.62C369.3 307.54 369.5 308.5 369.5 309.5L369.5 414.49C369.5 415.49 369.3 416.45 368.92 417.36C368.54 418.28 368 419.09 367.3 419.8C366.6 420.5 365.78 421.04 364.87 421.42C363.95 421.8 362.99 422 362 422Z"
        fill-opacity="1.000000"
        fill="#4793F3"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>
