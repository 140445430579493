<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M29 23.345a.75.75 0 0 0-.75.75 2.761 2.761 0 0 1-2.759 2.759H4.51a2.763 2.763 0 0 1-2.76-2.759V10.58a2.763 2.763 0 0 1 2.76-2.759h2.084c.982 0 .979-1.499 0-1.5H4.51A4.265 4.265 0 0 0 .25 10.58v13.515a4.265 4.265 0 0 0 4.26 4.259h20.981a4.263 4.263 0 0 0 4.259-4.259.75.75 0 0 0-.75-.75zM25.491 6.321h-2.084a.914.914 0 0 1-.78-.442L20.95 3.085a2.962 2.962 0 0 0-2.527-1.431h-6.845c-1.056-.127-3.534 1.389-2.295 2.466a.75.75 0 0 0 1.053-.262 1.456 1.456 0 0 1 1.242-.7h6.845c.507.001.978.266 1.241.7l1.676 2.793c.792 1.486 2.77 1.144 4.151 1.17a2.763 2.763 0 0 1 2.759 2.759v9.158c-.002.971 1.499.987 1.5 0V10.58a4.264 4.264 0 0 0-4.259-4.259z"
        opacity="1"
        class=""
      ></path>
      <path
        d="M15 25.072a8.217 8.217 0 0 0 8.217-8.217C22.814 5.967 7.185 5.969 6.783 16.854A8.228 8.228 0 0 0 15 25.072zm0-14.934a6.717 6.717 0 0 1 6.717 6.717c-.307 8.892-13.128 8.889-13.434 0A6.724 6.724 0 0 1 15 10.138z"
        opacity="1"
        class=""
      ></path>
      <path
        d="M11.267 17.604a.75.75 0 0 0 .75-.751A2.987 2.987 0 0 1 15 13.871c.979-.003.982-1.496 0-1.5a4.489 4.489 0 0 0-4.483 4.483c0 .414.336.75.75.75zM26.3 10.804c-.026-1.53-2.302-1.53-2.328 0 .026 1.53 2.302 1.53 2.328 0z"
        opacity="1"
        class=""
      ></path>
    </g>
  </svg>
</template>
