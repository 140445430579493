import {
    ActivityInTask,
    NoteAction,
    SubActivityInTask,
} from '@/ui/modules/task/ui-enums/tasklog-enums';
import { translate } from '@/ui/plugins/i18n/myi18n';

export const titleActivityItem = (after, before) => {
    return {
        [ActivityInTask.CREATE]: {
            [SubActivityInTask.AddNew]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_CREATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_TASK_LABEL'
                ),
            },
            [SubActivityInTask.Clone]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_DUPLICATE_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_TASK_LABEL'
                ),
            },
            [SubActivityInTask.RepeatGenerate]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_REPEAT_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_TASK_LABEL'
                ),
            },
            [SubActivityInTask.Comment]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_CREATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_COMMENT_LABEL'
                ),
            },
        },
        [ActivityInTask.UPDATE]: {
            [SubActivityInTask.Reopen]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_REOPEN_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_TASK_LABEL'
                ),
            },
            [SubActivityInTask.Finished]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_FINISHED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_TASK_LABEL'
                ),
            },
            [SubActivityInTask.ChangeDeadline]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_SCHEDULE_LABEL'
                ),
            },
            [SubActivityInTask.ChangeDescription]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_DESCRIPTION_LABEL'
                ),
            },
            [SubActivityInTask.ChangePriority]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_PRIORITY_LABEL'
                ),
            },
            [SubActivityInTask.ChangeDomain]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_DOMAIN_LABEL'
                ),
            },
            [SubActivityInTask.ChangeStatus]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_STATUS_LABEL'
                ),
            },
            [SubActivityInTask.ChangeName]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_NAME_LABEL'
                ),
            },
            [SubActivityInTask.ChangePrivate]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_MODE_LABEL'
                ),
            },
            [SubActivityInTask.Planned]: {
                activity: translate('ACTIVITY_HISTORY_TASK_ACTION_SET_LABEL'),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_PLAN_LABEL'
                ),
            },
            [SubActivityInTask.ChangeAssignee]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed:
                    before?.AssigneeId !== after?.AssigneeId
                        ? translate(
                              'ACTIVITY_HISTORY_TASK_PART_CHANGED_ASSIGNEE_LABEL'
                          )
                        : before?.GroupId !== after?.GroupId
                        ? translate(
                              'ACTIVITY_HISTORY_TASK_PART_CHANGED_GROUP_ASSIGNEE_LABEL'
                          )
                        : translate(
                              'ACTIVITY_HISTORY_TASK_PART_CHANGED_ASSIGNEE_LABEL'
                          ),
            },
            [SubActivityInTask.ChangeAttachments]: {
                activity:
                    after?.AddedFiles && after?.AddedFiles?.length > 0
                        ? translate(
                              'ACTIVITY_HISTORY_TASK_ACTION_ADDED_LABEL',
                              {
                                  total: after?.AddedFiles?.length,
                              }
                          )
                        : after?.DeletedFiles && after?.DeletedFiles?.length > 0
                        ? translate(
                              'ACTIVITY_HISTORY_TASK_ACTION_DELETED_LABEL',
                              {
                                  total: after?.DeletedFiles?.length,
                              }
                          )
                        : translate(
                              'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                          ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_FILE_LABEL'
                ),
            },
            [SubActivityInTask.ChangeNotes]: {
                activity:
                    after?.NoteAction === NoteAction.Add
                        ? translate(
                              'ACTIVITY_HISTORY_TASK_ACTION_ADDED_LABEL',
                              {
                                  total: 1,
                              }
                          )
                        : after?.NoteAction === NoteAction.Delete
                        ? translate(
                              'ACTIVITY_HISTORY_TASK_ACTION_DELETED_LABEL',
                              {
                                  total: 1,
                              }
                          )
                        : translate(
                              'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                          ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_NOTE_LABEL'
                ),
            },
            [SubActivityInTask.EditComment]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL'
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_COMMENT_LABEL'
                ),
            },
            [SubActivityInTask.EditCommentAttachments]: {
                activity: parserTextFileNote(after),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_IN_COMMENT_LABEL'
                ),
            },
            [SubActivityInTask.PinnedComment]: {
                activity: after?.CommentPinned
                    ? translate('ACTIVITY_HISTORY_TASK_ACTION_PINNED_LABEL')
                    : translate('ACTIVITY_HISTORY_TASK_ACTION_UN_PINNED_LABEL'),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_COMMENT_LABEL'
                ),
            },
            [SubActivityInTask.AddCollaborator]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_ADD_COLLABORATOR'
                ),
                changed: translate('COMMON_LABEL_COLLABORATORS'),
            },
            [SubActivityInTask.RemoveCollaborator]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_REMOVE_COLLABORATOR'
                ),
                changed: translate('COMMON_LABEL_COLLABORATORS'),
            },
            [SubActivityInTask.JoinCollaborator]: {
                activity: translate('TASK_FOLLOW_JOIN'),
                changed: '',
            },
            [SubActivityInTask.LeaveCollaborator]: {
                activity: translate('TASK_FOLLOW_LEAVE'),
                changed: '',
            },
            [SubActivityInTask.ChangeTodoList]: {
                activity: translate('ACTIVITY_HISTORY_TASK_ACTION_SET_LABEL'),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_TODO_LIST'
                ),
            },
            [SubActivityInTask.WorkflowStepComplete]: {
                activity: translate('COMMON_LABEL_COMPLETE'),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_TASK_WORKFLOW_STEP'
                ),
            },
            [SubActivityInTask.WorkflowStepStart]: {
                activity: '',
                changed: translate(
                    'NOTIFICATION_CONTENT_BY_ACTION_TYPE_START_STEP'
                ),
            },
        },
        [ActivityInTask.DELETE]: {
            [SubActivityInTask.Delete]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_DELETED_LABEL',
                    {
                        total: 1,
                    }
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_TASK_LABEL'
                ),
            },
            [SubActivityInTask.DeleteComment]: {
                activity: translate(
                    'ACTIVITY_HISTORY_TASK_ACTION_DELETED_LABEL',
                    {
                        total: 1,
                    }
                ),
                changed: translate(
                    'ACTIVITY_HISTORY_TASK_PART_CHANGED_COMMENT_LABEL'
                ),
            },
        },
    };
};

const parserTextFileNote = (after) => {
    const l_AddFiles = after?.AddedFiles?.length;
    const l_AddedNotes = after?.AddedNotes?.length;
    const l_DeletedFiles = after?.DeletedFiles?.length;
    const l_DeletedNotes = after?.DeletedNotes?.length;
    const l_EditedNotes = after?.EditedNotes?.length;

    const textArr = [] as any[];

    if (l_AddFiles)
        textArr.push(
            translate('ACTIVITY_HISTORY_TASK_ACTION_ADDED_LABEL', {
                total: l_AddFiles,
            }) +
                ' ' +
                translate('ACTIVITY_HISTORY_TASK_PART_CHANGED_FILE_LABEL')
        );
    if (l_AddedNotes)
        textArr.push(
            translate('ACTIVITY_HISTORY_TASK_ACTION_ADDED_LABEL', {
                total: l_AddedNotes,
            }) +
                ' ' +
                translate('ACTIVITY_HISTORY_TASK_PART_CHANGED_NOTE_LABEL')
        );
    if (l_DeletedFiles)
        textArr.push(
            translate('ACTIVITY_HISTORY_TASK_ACTION_DELETED_LABEL', {
                total: l_DeletedFiles,
            }) +
                ' ' +
                translate('ACTIVITY_HISTORY_TASK_PART_CHANGED_FILE_LABEL')
        );
    if (l_DeletedNotes)
        textArr.push(
            translate('ACTIVITY_HISTORY_TASK_ACTION_DELETED_LABEL', {
                total: l_DeletedNotes,
            }) +
                ' ' +
                translate('ACTIVITY_HISTORY_TASK_PART_CHANGED_NOTE_LABEL')
        );
    if (l_EditedNotes)
        textArr.push(
            translate('ACTIVITY_HISTORY_TASK_ACTION_UPDATE_NOTE_LABEL', {
                total: l_EditedNotes,
            }) +
                ' ' +
                translate('ACTIVITY_HISTORY_TASK_PART_CHANGED_NOTE_LABEL')
        );

    let resultText = '';

    textArr.forEach((el, index) => {
        if (index === 0) resultText += el;
        else resultText += ', ' + el;
    });

    return resultText;
};
