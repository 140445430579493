import CryptoJS from 'crypto-js';

const kpsk = '9zwjdM0P1$H28Fe0';

const _decrypt = (value) => {
    const bytes = CryptoJS.AES.decrypt(value, kpsk);
    return bytes.toString(CryptoJS.enc.Utf8);
};

export const GOOGLE_MAP_KEY = _decrypt(import.meta.env.VITE_GOOGLEMAPS_KEY);

export const AWS_S3 = {
    region: _decrypt(import.meta.env.VITE_AWS_S3_RE),
    accessKeyId: _decrypt(import.meta.env.VITE_AWS_S3_AKI),
    secretAccessKey: _decrypt(import.meta.env.VITE_AWS_S3_SAK),
};

// export const _encrypt = (value) => {
//     return CryptoJS.AES.encrypt(value, kpsk).toString();
// };
