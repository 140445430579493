export const DOMAIN_STATUS = {
    ALL: {
        key: 'ALL',
        name: 'COMMON_LABEL_ALL',
    },
    VISIBLE: {
        key: 'VISIBLE',
        name: 'COMMON_LABLE_VISIBLE',
    },
    INVISIBLE: {
        key: 'INVISIBLE',
        name: 'COMMON_LABEL_ACTION_INVISIBLE',
    },
};
