<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 128 128"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="m60.164 93.327c-1.036 3.233-3.595 5.792-6.828 6.828 3.233 1.036 5.792 3.595 6.828 6.828 1.036-3.233 3.594-5.792 6.827-6.828-3.233-1.036-5.791-3.594-6.827-6.828z"
        ></path>
        <path
          d="m78.787 23.436c-2.336 12.999-12.611 23.275-25.608 25.612 12.997 2.336 23.272 12.613 25.608 25.611 2.336-12.999 12.611-23.275 25.608-25.611-12.997-2.337-23.272-12.613-25.608-25.612z"
        ></path>
        <path
          d="m35.713 58.887c-1.593 6.65-6.838 11.896-13.488 13.489 6.65 1.593 11.896 6.839 13.488 13.489 1.593-6.65 6.838-11.896 13.488-13.489-6.65-1.593-11.895-6.839-13.488-13.489z"
        ></path>
      </g>
    </g>
  </svg>
</template>
