<script setup lang="ts">
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import TaskLogActivity from '@/ui/modules/task/detail/TaskLogActivity.vue';
import detailTaskComposable from '@/ui/composables/task/detail-task';
import { computed } from 'vue';
import TaskTodoListAll from '@/ui/modules/task/components/check-list/TaskTodoListAll.vue';
import TaskComments from '@/ui/modules/task/detail/_commons/task-comments/TaskComments.vue';

const props = withDefaults(
  defineProps<{
    activities: string[];
    activeActivity: string;
    task: any;
    taskChecklist?: any;
    taskComments?: any[];
    organizationId?: number;
    users?: any[];
    readonly?: boolean;
    isSharing?: boolean;
  }>(),
  {
    activities: () => ['COMMENT', 'TODO_LIST', 'TASKLOG'],
    activeActivity: 'COMMENT',
  }
);

defineEmits(['update:activeActivity', 'onCommentSaved']);

const { TypeActivity, listActivityOption } = detailTaskComposable({
  taskId: props.task?.id,
});

const listActivities = computed(() =>
  listActivityOption.value?.filter((act) =>
    props.activities?.includes(act?.key)
  )
);

const countByActivity = computed(() => ({
  [TypeActivity.COMMENT]: props.task?.totalComment,
  [TypeActivity.TODO_LIST]: props.task?.taskTodoTotal,
}));
</script>

<template>
  <div class="">
    <div class="flex items-center space-x-2 py-2 bg-white sticky top-0 z-[1]">
      <SynTagField
        v-for="activity in listActivities"
        :key="activity?.key"
        v-permission-function="{
          functionCode: activity.functionCode,
          onContinue: () => {
            $emit('update:activeActivity', activity?.key);
          },
        }"
        :label="
          activity?.label +
          (countByActivity[activity?.key]
            ? ` (${countByActivity[activity?.key]})`
            : '')
        "
        :is-active="activity?.key === activeActivity"
        @click="$emit('update:activeActivity', activity?.key)"
      />
    </div>

    <div
      v-if="activeActivity === TypeActivity.COMMENT"
      v-permission-function="{ functionCode: ALL_FUNCTIONS.TASK.COMMENT }"
      class="w-full h-full flex flex-col justify-between relative"
    >
      <TaskComments
        :task-detail="task"
        :task-comments="taskComments"
        :readonly="readonly"
        :users="users"
        @on-save-success="$emit('onCommentSaved')"
      />
    </div>

    <div
      v-if="activeActivity === TypeActivity.TODO_LIST"
      v-permission-function="{ functionCode: ALL_FUNCTIONS.TASK.COMMENT }"
    >
      <TaskTodoListAll
        :task-id="task?.id"
        :task-check-list="taskChecklist"
        :readonly="readonly"
        :is-sharing="isSharing"
        :organization-id="organizationId"
      />
    </div>

    <div
      v-if="activeActivity === TypeActivity.TASKLOG"
      v-permission-function="{ functionCode: ALL_FUNCTIONS.TASK.HISTORY }"
    >
      <TaskLogActivity
        :task-id="task?.id"
        :organization-id="organizationId"
        :users="users"
      />
    </div>
  </div>
</template>
