<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512.001 512.001"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="m497.197 386.254-78.129-69.785a44.223 44.223 0 0 0-57.157-1.608 15372.06 15372.06 0 0 0-31.789 25.403c-9.649 7.729-22.607 8.411-33.013 1.734-28.762-18.454-52.056-36.737-71.212-55.894-19.158-19.158-37.441-42.452-55.893-71.212-6.677-10.406-5.996-23.364 1.735-33.014 10.233-12.774 18.7-23.379 25.402-31.789a44.225 44.225 0 0 0-1.607-57.156l-69.786-78.131C117.328 5.373 106.196.258 93.557.01c-12.629-.273-23.964 4.425-32.75 13.512L22.195 53.466v.001c-29.327 30.338-29.606 79.487-.808 142.132 24.624 53.563 69.037 113.916 125.06 169.94 56.024 56.026 116.379 100.443 169.945 125.07 31.034 14.268 58.753 21.398 82.518 21.398 24.209 0 44.313-7.402 59.624-22.202l39.943-38.612c9.09-8.785 13.762-20.109 13.513-32.748-.248-12.639-5.364-23.771-14.793-32.191zm-9.839 53.435-39.944 38.612c-31.832 30.769-85.186 15.77-124.338-2.229-51.888-23.854-110.598-67.128-165.315-121.847-54.716-54.716-97.984-113.425-121.836-165.31-17.998-39.149-32.993-92.499-2.226-124.328l38.612-39.943c5.695-5.893 12.733-8.768 20.93-8.637 8.193.161 15.114 3.342 20.573 9.454l69.786 78.131a28.263 28.263 0 0 1 1.027 36.527 15537.142 15537.142 0 0 1-25.375 31.756c-12.096 15.098-13.162 35.375-2.715 51.658 19.079 29.737 38.066 53.905 58.046 73.886 19.978 19.977 44.146 38.964 73.886 58.046 16.283 10.445 36.56 9.382 51.657-2.715a15244.841 15244.841 0 0 1 31.756-25.376 28.262 28.262 0 0 1 36.528 1.028l78.129 69.785c6.112 5.458 9.293 12.38 9.454 20.573.162 8.193-2.743 15.234-8.635 20.929zM74.103 185.007a8 8 0 0 1-3.503 10.757 7.957 7.957 0 0 1-3.621.872 8 8 0 0 1-7.137-4.375c-13.51-26.556-33.837-75.49-18.188-104.853a39.521 39.521 0 0 1 6.475-8.874 7.998 7.998 0 0 1 11.312-.19 8 8 0 0 1 .191 11.312 23.173 23.173 0 0 0-3.858 5.278c-13.052 24.49 10.729 75.134 18.329 90.073z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
