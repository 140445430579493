<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import FileViewer from './FileViewer.vue';
import VigSlider from '@/ui/common/molecules/Slider/VigSlider.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import ActionInFile from '@/ui/modules/ged/modal-file-viewer/ActionInFile.vue';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
import ModalSaveToResource from '@/ui/modules/ged/folder/modal-save-to-resource/ModalSaveToResource.vue';

const props = withDefaults(
  defineProps<{
    startIndex: number;
    files: any[];
    editable: boolean;
    isLoading: boolean;
    isNotBack: boolean;
  }>(),
  {
    startIndex: 0,
    editable: true,
  }
);

const emit = defineEmits([
  'onClose',
  'onSaveNote',
  'onRemove',
  'onSaveDescription',
  'onNext',
  'onBack',
  'onViewLastFile',
]);

onMounted(() => {
  filesPreview.value?.focus();
  onScollViewFile();
});

const currentIndex = ref(props.startIndex || 0);
watch(
  () => props.files.length,
  (newValue) => {
    if (newValue > 1 && newValue >= currentIndex.value)
      onScollViewFile('start');
  }
);

const saveToResource = ref(false);
const listFile = computed(() => props.files);
const isLoadingFile = computed(() => props.isLoading);
const totalFile = computed(() => listFile.value?.length);
const currentFile = computed(() => {
  const total = totalFile.value;
  if (total == 0) return {};
  if (total <= currentIndex.value) {
    return cloneDeep(props.files[total - 1]);
  }
  return props.files[currentIndex.value];
});

const onSelectItem = (item) => {
  const maxLength = listFile.value?.length;
  isEditMode.value = false;
  isEdittingDescription.value = false;
  currentIndex.value = item;
  if (currentIndex.value === maxLength - 1) {
    emit('onViewLastFile', currentIndex.value);
  }
};

const onNext = () => {
  const maxLength = listFile.value?.length;
  emit('onNext', currentIndex.value);
  if (isLoadingFile.value) return;

  if (currentIndex.value == maxLength - 1) {
    onSelectItem(0);
    onScollViewFile();
    return;
  }
  onSelectItem(currentIndex.value + 1);
  onScollViewFile();
};

const onBack = () => {
  const maxLength = listFile.value?.length;
  emit('onBack', currentIndex.value);
  if (props.isNotBack && currentIndex.value == 0) return;
  if (!props.isNotBack && currentIndex.value == 0) {
    onSelectItem(maxLength - 1);
    onScollViewFile();
    return;
  }
  onSelectItem(currentIndex.value - 1);
  onScollViewFile();
};

const filesPreview = ref(null as any);

const isEditMode = ref(false);

const isEdittingDescription = ref(false);
const descriptionValue = ref('');

const onOpenEditDescription = () => {
  isEdittingDescription.value = true;
  currentIndex.value = listFile.value?.length - 1;
  descriptionValue.value = currentFile.value?.data;
};

const currentFileUrl = computed(() =>
  currentFile.value?.type == 'text' || currentFile.value?.contentType == 'text'
    ? ''
    : currentFile.value?.fileUrl ||
      currentFile.value?.url_full ||
      currentFile.value?.imagePath ||
      currentFile.value?.src ||
      currentFile.value?.pathUrl ||
      (currentFile.value instanceof File
        ? URL.createObjectURL(currentFile.value)
        : '')
);

// eslint-disable-next-line no-undef
const onScollViewFile = (blockValue: ScrollLogicalPosition = 'center') => {
  let element = document.getElementById(
    `multiple_file_slider_${currentIndex.value}`
  ) as HTMLDivElement;
  element.scrollIntoView({ block: blockValue });
};

defineExpose({
  onOpenEditDescription,
  onSelectItem,
});
</script>

<template>
  <SynModalNoFocus
    is-hidden-header
    is-hidden-footer
    disable-click-outside
    z-index="z-50"
    container-class="w-11/12"
    :container-style="`max-height: 1920px;
          max-width: 1920px;
          min-height:500px;
          min-width:600px;
          width: 100%;
          height: 100%;
          `"
    :background-style="`padding: 20px;`"
    style-body="p-0 flex flex-col relative"
    tabindex="1"
    @cancel="$emit('onClose')"
  >
    <template #body>
      <div
        ref="filesPreview"
        tabindex="0"
        class="h-full flex outline-none flex-col"
        @keyup.left="onBack"
        @keyup.down="onBack"
        @keyup.up="onNext"
        @keyup.right="onNext"
      >
        <!-- View -->
        <clip-loader :loading="isLoading"></clip-loader>
        <div
          class="flex-1 flex flex-col w-full relative bg-gray-800 px-2 pb-2 h-0"
          style="padding-top: 4.75rem"
        >
          <template v-if="!isEditMode">
            <span
              class="
                absolute
                top-0
                left-0
                px-4
                py-4
                text-gray-800
                bg-white
                w-full
                flex
                justify-between
                font-semibold
              "
            >
              <slot name="title"></slot>
              <div class="flex items-center">
                <ActionInFile
                  :file="currentFile"
                  :name="currentFile?.name"
                  :path="currentFileUrl"
                  :content-type="currentFile?.contentType || currentFile?.type"
                  has-download
                  @close="$emit('onClose')"
                  @save-to-resource="saveToResource = true"
                />
                <template v-if="files.length > 1">
                  <div class="ml-6 flex items-center space-x-2">
                    <div class="flex items-center">
                      <div
                        class="
                          flex-center
                          bg-current-500 bg-opacity-70
                          hover:bg-opacity-100
                          text-white
                          font-bold
                          rounded-full
                          w-9
                          h-9
                          cursor-pointer
                        "
                        @click="onBack"
                      >
                        <SynIcon name="before" class="fill-white" />
                      </div>
                    </div>
                    <div class="flex items-center">
                      <div
                        id="column_view_flat_next"
                        class="
                          flex-center
                          bg-current-500 bg-opacity-70
                          hover:bg-opacity-100
                          text-white
                          font-bold
                          rounded-full
                          w-9
                          h-9
                          cursor-pointer
                        "
                        @click="onNext"
                      >
                        <SynIcon name="next" class="fill-white" />
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <div
                v-if="editable && currentFile?.type == 'text'"
                class="
                  flex-center
                  space-x-2
                  rounded-full
                  bg-current-50
                  hover:bg-current-100
                  cursor-pointer
                  text-current-800
                  px-2
                "
                @click.stop="onOpenEditDescription"
              >
                <SynIcon
                  name="edit"
                  is-active
                  class="fill-current"
                  :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                />
                <span>
                  {{ $t('COMMON_LABEL_EDIT') || 'Edit' }}
                </span>
              </div>
            </span>
          </template>

          <FileViewer
            :file="currentFile"
            :name="currentFile?.name"
            :path="currentFileUrl"
            :content-type="currentFile?.contentType || currentFile?.type"
            :is-loading="isLoadingFile"
            has-download
            @close="$emit('onClose')"
          />
        </div>

        <!-- List image footer -->
        <div class="bg-gray-500">
          <VigSlider>
            <UploadPreview
              v-for="(file, index) in listFile"
              :id="`multiple_file_slider_${index}`"
              :key="file"
              :file="file"
              :is-active="index == currentIndex"
              :is-note="file?.isNote"
              selectable
              readonly
              is-hidden-info
              has-opacity
              @on-select="onSelectItem(index)"
              @remove="$emit('onRemove', file)"
            />
            <div
              v-if="isLoadingFile"
              class="p-1 rounded h-full w-full flex-center"
            >
              <div class="w-28 h-20">
                <SynIcon
                  name="spinner"
                  class="fill-current border-none animate-spin"
                >
                </SynIcon>
              </div>
            </div>
            <slot name="footer-action"></slot>
          </VigSlider>
        </div>
      </div>
    </template>
  </SynModalNoFocus>

  <ModalSaveToResource
    v-if="saveToResource"
    :file="currentFile"
    @on-close="saveToResource = false"
  />
</template>
