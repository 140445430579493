<template>
  <div
    class="flex justify-between items-center cursor-pointer p-2 mx-2 border-b"
    @click.stop="isClose ? toggleShow : ''"
  >
    <div class="flex-1">
      <span class="flex-1 capitalize text-sm">
        {{ label }}
      </span>
    </div>

    <slot name="edit"></slot>

    <div class="h-4 w-4 flex-center">
      <SynIcon
        custom-class="h-2 w-2"
        :name="isShow ? 'sort-up' : 'sort-down'"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: '',
    },
    onAction: {
      type: Function,
      required: false,
      default: () => {
        return {};
      },
    },
    label: {
      type: String,
      default: '',
    },
    isClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-collapse'],
  setup(props, { emit }) {
    const isShow = ref(true);
    const toggleShow = () => {
      isShow.value = !isShow.value;
      emit('on-collapse', isShow.value);
    };
    return {
      isShow,
      toggleShow,
    };
  },
};
</script>
