<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.859"
    height="20.911"
    viewBox="0 0 11.859 20.911"
  >
    <path
      id="Icon_awesome-caret-right"
      data-name="Icon awesome-caret-right"
      d="M0,27.047V8.953a1.406,1.406,0,0,1,2.4-.994l9.047,9.047a1.406,1.406,0,0,1,0,1.989L2.4,28.041A1.406,1.406,0,0,1,0,27.047Z"
      transform="translate(0 -7.544)"
    />
  </svg>
</template>
