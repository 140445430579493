import {
    GroupCreatePayload,
    GroupDeletePayload,
} from '@/domain/entities/PayloadClass/GroupPayloadClass';
import EmbedGroupApi from '@/embed/api/group-api';
import EmbedUserApi from '@/embed/api/user-api';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export default class EmbedGroupServices {
    private static instance: EmbedGroupServices;
    groupApi: EmbedGroupApi;
    userApi: EmbedUserApi;
    constructor() {
        this.groupApi = EmbedGroupApi.getInstance();
        this.userApi = EmbedUserApi.getInstance();
    }

    public static getInstance(): EmbedGroupServices {
        if (!EmbedGroupServices.instance) {
            // Get from local storage
            EmbedGroupServices.instance = new EmbedGroupServices();
        }

        return EmbedGroupServices.instance;
    }

    getDetail(groupId: number): Promise<any> {
        return this.groupApi.fetchGroupDetail(groupId);
    }
    getSameGroupOfUsers(userId: number): Promise<any> {
        return this.groupApi.getSameGroupOfUsers(userId);
    }
    createAndUpdate(payload: GroupCreatePayload): Promise<any> {
        return this.groupApi.createAndUpdate(payload);
    }
    getAll(): any {
        return this.groupApi.getAllGroup();
    }
    addUserToGroup(data: []): Promise<any> {
        return this.groupApi.addUserToGroup(data);
    }
    getAllGroupByUser(): Promise<any> {
        const userId = MyOrganizationSingleton.getInstance().myUserId;
        return this.groupApi.getAllGroupByUser(userId);
    }
    getGroupListOverview(): Promise<any> {
        return this.groupApi.getGroupListOverview();
    }
    deleteGroup(payload: GroupDeletePayload): Promise<any> {
        return this.groupApi.deleteGroup(payload);
    }
    getDomainListById(userId: number): Promise<any> {
        return this.userApi.getDomainScopeList(userId);
    }

    uploadAvatar(file: File): Promise<any> {
        return this.userApi.uploadAvatarAws(file);
    }
}
