import BaseRepository from '@/base/repositories/BaseRepository';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { IExportDataPayload } from '../types/task/task-export-data.types';
// import { saveAs } from 'file-saver';
// import axios from 'axios';

export default class ExportRepository {
    private static instance: ExportRepository;

    constructor() {}

    public static getInstance(): ExportRepository {
        if (!ExportRepository.instance) {
            // Get from local storage
            ExportRepository.instance = new ExportRepository();
        }

        return ExportRepository.instance;
    }

    async exportTaskData(data: IExportDataPayload): Promise<any> {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);
        return BaseRepository.requestWithAuthorize({
            url: `Export/TaskData3?lang=${lang}`,
            method: 'post',
            data,
            responseType: 'blob',
        });
        //   axios.post(`${import.meta.env.VITE_TICTOP_SERVER}/api/web/Export/TaskData?lang=${lang}`, data, {responseType: 'blob'})
        //   .then(result => {
        //       saveAs(result.data, "users.csv");
        //     });
    }

    exportGanttChart(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/ExportGanttChart`,
            method: 'post',
            data,
            responseType: 'blob',
        });
    }
}
