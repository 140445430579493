<script setup lang="ts">
import { ref, computed } from 'vue';
import { getAllDomainUrl } from '@/ui/hooks/commonHook';
import { isValidTaskCode } from '@/ui/hooks/taskHook';
import dynamicCardPosition from '@/ui/composables/app/dynamic-card-position';
import { decryptUserId } from '@/ui/plugins/crypto/crypto';
import userStore from '@/store/user';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
// import TaskCard from '@/ui/modules/overview/TaskCard.vue';
import UserSmallCard from '@/ui/components/user/UserSmallCard.vue';
import taskDetailStore from '@/store/task/detail';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';

withDefaults(
  defineProps<{
    customClass?: string;
  }>(),
  {
    customClass: '',
  }
);

const taskCardSize = {
  width: 280,
  height: 100,
};
const _taskDetailStore = taskDetailStore();

const isLoadingTask = ref(false);
const currentTask = ref(null as any);
const cardUserId = ref(0 as number);
const cardUser = computed(
  () => userStore().allUserByIds[decryptUserId(cardUserId.value)]
);
const currentUserProfileId = ref(null);

const destroyCard = () => {
  currentTask.value = null;
  cardUserId.value = 0;
  if (timeOut) clearTimeout(timeOut);
};
const {
  updatePosition: updateTaskCardPosition,
  cardPosition: taskCardPosition,
  isShowCard: isShowTaskCard,
  cardElementRef: taskCardElementRef,
  isFocusOnCard: isFocusOnTaskCard,
} = dynamicCardPosition(350, destroyCard);

const fetchDataForTaskCard = async (href) => {
  try {
    isLoadingTask.value = true;
    const isUserCase = href.includes('/users/');
    if (isUserCase) {
      cardUserId.value = href.split('users/').pop();
      currentTask.value = null;
    } else {
      cardUserId.value = 0;
      const taskCode = href.split('/').pop();
      if (!isValidTaskCode(taskCode)) return;
      currentTask.value = await _taskDetailStore.getDetailByCode(taskCode);
    }
  } finally {
    isLoadingTask.value = false;
  }
};
let timeOut;
const onHoverTextMessage = async (event) => {
  if (isLoadingTask.value) return;

  if (timeOut) clearTimeout(timeOut);
  timeOut = setTimeout(() => {
    const href = event?.target?.href;
    const baseUrls = getAllDomainUrl();

    const isIncludeBaseUrl = Object.values(baseUrls).some((urlName) =>
      href?.includes(urlName)
    );
    if (!href || !isIncludeBaseUrl || !href?.includes('/tasks')) return;
    destroyCard();

    updateTaskCardPosition(event, taskCardSize, () => {
      fetchDataForTaskCard(href);
    });
  }, 800);
};

const onMouseLeaveTextMessage = () => {
  if (isFocusOnTaskCard.value) return;
  if (currentTask.value || cardUserId.value) {
    setTimeout(() => {
      if (isFocusOnTaskCard.value) return;

      destroyCard();
    }, 500);
  } else {
    destroyCard();
  }
};

defineExpose({
  onHoverTextMessage,
  onMouseLeaveTextMessage,
  destroyCard,
});
</script>
<template>
  <teleport to="body">
    <div
      v-if="isShowTaskCard && (cardUser || currentTask?.id)"
      ref="taskCardElementRef"
      class="absolute top-0 left-0 z-100 bg-white rounded shadow-md"
      :class="customClass"
      :style="[
        `top: ${taskCardPosition.y}px`,
        `left: ${taskCardPosition.x}px`,
        `width: ${taskCardSize.width}px`,
        `height: ${taskCardSize.height}px`,
      ]"
    >
      <div v-if="cardUser">
        <UserSmallCard
          :user="cardUser"
          @open-profile="(userId) => (currentUserProfileId = userId)"
        />
      </div>
      <TaskCardItem v-else :task-detail="currentTask" readonly />
    </div>
  </teleport>

  <ModalProfileUser
    v-if="currentUserProfileId"
    :profile-id="currentUserProfileId"
    @cancel="currentUserProfileId = null"
  />
</template>
