<template>
  <!-- Choose admin when create -->
  <div class="flex flex-col space-y-2 bg-white rounded-md shadow">
    <div class="flex items-center justify-between px-4 pt-4">
      <slot name="header"></slot>
    </div>

    <!-- VISIBLE DETAIL -->
    <div class="w-full flex items-start flex-col space-y-2 px-12 py-4 border-t">
      <slot name="body"></slot>
    </div>
  </div>
</template>
