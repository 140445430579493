import BaseModel from './BaseModel';
import {
    collection,
    doc,
    DocumentData,
    DocumentReference,
    onSnapshot,
    setDoc,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export default class UserDocModel extends BaseModel {
    docRef: DocumentReference<DocumentData>;

    constructor(userId: number) {
        super();

        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        this.docRef = doc(
            collection(
                doc(
                    collection(firestoreDb, 'organizationUsers'),
                    `${organizationId}`
                ),
                'users'
            ),
            `${userId}`
        );
    }

    subscribleDataChange(callback) {
        return onSnapshot(this.docRef, (snapshot) => {
            callback && callback(snapshot.data());
        });
    }

    subscribleDataChangeByOrganizationId(
        value: { organizationId: any; userId: any },
        callback
    ) {
        const userDoc = doc(
            collection(
                doc(
                    collection(firestoreDb, 'organizationUsers'),
                    `${value.organizationId}`
                ),
                'users'
            ),
            `${value.userId}`
        );
        return onSnapshot(userDoc, (snapshot) => {
            callback && callback(snapshot.data());
        });
    }

    setSeenAll() {
        return setDoc(
            this.docRef,
            { unSeenConversations: null },
            { merge: true }
        );
    }
}
