import { translate } from './../../ui/plugins/i18n/myi18n';
import BaseRepository from '@/base/repositories/BaseRepository';

export default class DepartmentRepository {
    private static instance: DepartmentRepository;

    constructor() {}

    public static getInstance(): DepartmentRepository {
        if (!DepartmentRepository.instance) {
            // Get from local storage
            DepartmentRepository.instance = new DepartmentRepository();
        }

        return DepartmentRepository.instance;
    }

    getAll() {
        return BaseRepository.requestWithAuthorize({
            url: `Department/GetAllDepartment`,
            method: 'get',
        });
    }
    shortInfoDepartment() {
        return BaseRepository.requestWithAuthorize({
            url: `Department/ShortInfoDepartments`,
            method: 'get',
        });
    }
    getDepartmentByUser() {
        return BaseRepository.requestWithAuthorize({
            url: `Department/GetDepartmentByUser`,
            method: 'get',
        });
    }
    createOrUpdate(data: {
        name: string;
        description: string;
        isPrivate: boolean;
        avatar: string;
        memberInfos: any;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/AddOrUpdateDepartment`,
            method: 'post',
            data,
        });
    }
    changeAvatar(data: { departmentId: number; avatar: string }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/ChangeAvatar`,
            method: 'post',
            data,
        });
    }
    changeNameAndDescription(data: {
        id: number;
        name: string;
        description: string;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/ChangeNameAndDescription`,
            method: 'post',
            data,
        });
    }
    addMembers(data: {
        departmentId: number;
        memberInfos: { userId: number; role: 1 | 2 }[];
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/AddUserToDepartment`,
            method: 'post',
            data,
        });
    }
    updateMembers(data: {
        departmentId: number;
        memberInfos: { userId: number; role: 1 | 2 }[];
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/UpdateUserToDepartment`,
            method: 'post',
            data,
        });
    }
    updateRoleUsers(data: {
        departmentId: number;
        memberInfos: { userId: number; role: 1 | 2 }[];
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/UpdateRoleUsersInDepartment`,
            method: 'post',
            data,
        });
    }
    updateUserDepartments(data: {
        userId: number;
        departments: { departmentId: number; role: 1 | 2 }[];
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/ManageUserToDepartment`,
            method: 'post',
            data,
        });
    }
    updateSettings(data: {
        departmentId: number;
        memberInfos: { userId: number; role: 1 | 2 }[];
    }) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Department/UpdateDepartmentSetting`,
                method: 'post',
                data,
            },
            {
                message: translate('DEPARTMENT_SETTING_UPDATE_SUCCESS'),
            }
        );
    }
    removeMembers(data: { departmentId: number; memberIds: number[] }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/RemoveUsersInDepartment`,
            method: 'post',
            data,
        });
    }
    deleteDepartment(data: {
        departmentId: number;
        isRemoveRelatedGroup: boolean;
        deleteOption?: number;
        deleteGroupOption?: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `Department/DeleteDepartment`,
            method: 'post',
            data,
        });
    }
}
