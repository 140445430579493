import { v4 as uuidv4 } from 'uuid';
import TaskService from '@/application/services/task/TaskService';
import {
    PlanTypeEnum,
    ScheduleDayOptions,
    TaskLife,
} from '@/domain/enums/taskEnum';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
    getScheduleTimeAfterChangeDate,
    getUrgencyValue,
} from '@/ui/hooks/taskHook';
import remoteConfigStore from '@/store/remoteConfig';
import { validateFile } from '@/ui/hooks/fileHook';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import TaskOriginalClass from './TaskOriginalClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import AttachmentClass from '@/application/services/attachment/AttachmentClass';
import NoteClass from '@/application/services/attachment/NoteClass';
import { ITaskTodo } from '@/application/types/task/task.types';
export class TaskTemplateEntity extends TaskOriginalClass {
    code: string;
    creationTime: Date;
    lastModificationTime: Date | null;
    finishedTime: Date | null;
    isGroupMember: boolean | null;
    attachmentCount: number;
    noteCount: number;
    errorCode: number | null;
    attachments: any[];
    urgency: number;
    taskTodoTotal: number;
    pinnedColor: string;
    taskTodos: ITaskTodo[] | null;
    updatedTodoList?: boolean;

    constructor(data: any) {
        super(data);

        this.code = data?.code || '';
        this.creationTime = data?.creationTime;
        this.lastModificationTime = data?.lastModificationTime;
        this.finishedTime = data?.finishedTime;
        this.isGroupMember = data?.isGroupMember;
        this.attachmentCount = data?.attachmentCount || 0;
        this.attachments = data?.attachments || [];
        this.urgency =
            typeof data?.priority == 'number' &&
            typeof data?.important == 'boolean'
                ? getUrgencyValue(data?.priority, data?.important)
                : data?.urgency;
        this.noteCount = data?.noteCount || 0;
        this.errorCode = data?.errorCode;
        this.pinnedColor = data?.pinnedColor;
        this.taskTodoTotal = data?.taskTodoTotal;
        this.taskTodos = data?.taskTodos || [];
        this.updatedTodoList = data?.updatedTodoList || false;

        this.updateAttachments();
    }

    async rename(newName): Promise<boolean> {
        this.name = newName;
        return Promise.resolve(true);
    }

    async changeUrgency(urgency): Promise<boolean> {
        try {
            this.urgency = urgency;
            this.priority = convertUrgencyToPriority(urgency);
            this.important = convertUrgencyToImportant(urgency);
            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeAssignee(groupId, assigneeId): Promise<boolean> {
        try {
            this.groupId = groupId;
            this.assigneeId = assigneeId;
            this.isPrivate = groupId > 0 ? false : this.isPrivate;
            this.isGroupMember = groupId > 0 ? true : this.isGroupMember;

            if (this.assigneeId)
                this.collaborators = this.collaborators?.filter(
                    (col) => col?.memberUserId !== this.assigneeId
                );

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }
    async doItToday(isDoItToday: boolean): Promise<boolean> {
        try {
            if (isDoItToday) {
                if (
                    this.taskLife !== TaskLife.Todo &&
                    this.taskLife !== TaskLife.InProcess
                )
                    this.changeState(TaskLife.Todo);
            }
            if (
                this.taskSchedule.scheduleOptionKey == ScheduleDayOptions.OnDay
            ) {
                this.taskSchedule.finishItToday = false;
                this.taskSchedule.scheduleOptionKey =
                    ScheduleDayOptions.Nothing;
            }

            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(false);
        }
    }

    async finishItToday(isFinishItToday: boolean): Promise<boolean> {
        try {
            this.updateDataByFinishToday(isFinishItToday);

            if (
                this.taskLife !== TaskLife.Todo &&
                this.taskLife !== TaskLife.InProcess
            ) {
                this.changeState(TaskLife.Todo);
            }

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeState(state: TaskLife): Promise<boolean> {
        const taskLifeBk = this.taskLife;
        const finisherIdBk = this.finisherId;

        try {
            this.taskLife = state;
            if (state == TaskLife.Finished) {
                this.finisherId = getCurrentUserId();
                this.finishedTime = new Date();
            }

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo

            this.taskLife = taskLifeBk;
            this.finisherId = finisherIdBk;
            return Promise.reject(false);
        }
    }

    async changeDescription(description: string): Promise<boolean> {
        try {
            this.description = description;

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeDomain(domainData: any): Promise<boolean> {
        try {
            this.domainId = domainData.domainId;
            this.projectId = domainData.projectId;
            this.subprojectId = domainData.subprojectId;

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changePrivate(isPrivate: any): Promise<boolean> {
        try {
            this.isPrivate = isPrivate;

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async changeDeadline(deadlineDate: {
        scheduleOptionKey: number;
        otherTimeKey: number;
        scheduleTime: string;
        overdue: number;
        startTime?: string;
        updateMode?: 'START_ONLY' | 'END_ONLY' | 'BOTH';
    }) {
        if (!deadlineDate) return Promise.resolve(false);

        switch (deadlineDate.updateMode) {
            case 'START_ONLY':
                this.startTime = deadlineDate?.startTime;
                break;
            case 'BOTH':
            case 'END_ONLY':
                {
                    this.startTime = deadlineDate?.startTime;
                    this.taskSchedule.scheduleOptionKey =
                        deadlineDate.scheduleOptionKey;
                    this.taskSchedule.otherTime = deadlineDate.otherTimeKey;
                    this.scheduleTime = getScheduleTimeAfterChangeDate(
                        this.scheduleTime,
                        deadlineDate.scheduleTime,
                        this.scheduleByHour,
                        this.scheduleTimezone
                    );
                    switch (deadlineDate.scheduleOptionKey) {
                        case ScheduleDayOptions.OnDay:
                            this.taskSchedule.finishItToday = true;
                            this.taskSchedule.doItToday = true;
                            this.taskLife = TaskLife.Todo;
                            break;
                        case ScheduleDayOptions.Nothing:
                            this.scheduleByHour = false;
                            break;

                        default:
                            break;
                    }
                }
                break;
            default:
                break;
        }
    }

    async getAttachmentData() {
        // if (this.attachmentCount > 0 || this.noteCount > 0) {
        //     const media = await TaskService.getInstance().getTaskMedia(this.id);
        //     this.files = media.files.map((o) => {
        //         return {
        //             ...o,
        //             path: o.fileUrl,
        //             contentType: o.type,
        //             isMedia: o.documentType == 'MEDIA',
        //         };
        //     });
        //     this.notes = media.notes.map((o) => {
        //         return {
        //             ...o,
        //             isNote: true,
        //             fileUrl: o.imagePath,
        //             contentType: 'image/jpeg',
        //         };
        //     });
        //     this.updateAttachments();
        // }
    }

    updateAttachments() {
        this.attachments = arrayOrderBy(
            [
                ...this.files,
                ...this.notes?.map((note) => {
                    return { ...note, isNote: true, type: 'image/jpeg' };
                }),
            ],
            ['creationTime'],
            ['asc']
        );
    }

    async addFiles(files: any[]): Promise<boolean> {
        try {
            const _remoteConfigStore = remoteConfigStore();
            const allowExtensionDefault =
                _remoteConfigStore.allowExtensionDefault;
            const webTaskUploadContentTypes =
                _remoteConfigStore.webTaskUploadContentTypes;
            const webTaskUploadMaxFilesize =
                _remoteConfigStore.webTaskUploadMaxFilesize;

            Array.from(files).forEach(async (file) => {
                const isValid = validateFile(
                    file,
                    webTaskUploadContentTypes || allowExtensionDefault,
                    webTaskUploadMaxFilesize || 25
                );

                if (isValid) {
                    const newFile = await new AttachmentClass(
                        ModuleSourceFileEnum.task,
                        file
                    );
                    const resFile = await newFile.upload();

                    const newFileResult = {
                        ...newFile,
                        ...resFile,
                        localId: uuidv4(),
                        actionType: 'ADD',
                    };
                    this.files.push(newFileResult);
                    this.attachmentCount = this.files.length;
                    this.attachments.push(newFileResult);
                }
            });

            return Promise.resolve(true);
        } catch (error) {
            console.log(
                '🚀 Hyrin ~ TaskReccuringTemplateClass ~ addFiles ~ error',
                error
            );
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async addNote(noteData: any): Promise<boolean> {
        try {
            const newNoteShow = await new NoteClass(ModuleSourceFileEnum.task, {
                base64: noteData?.base64,
                drawData: noteData?.drawData,
            }).upload();

            this.notes = [
                ...this.notes,
                {
                    ...newNoteShow,
                    base64: noteData?.base64,
                    actionType: 'ADD',
                },
            ];
            this.noteCount = this.notes.length;
            this.attachments = [
                ...this.attachments,
                {
                    ...newNoteShow,
                    isNote: true,
                    base64: noteData?.base64,
                    contentType: 'image/jpeg',
                    actionType: 'ADD',
                },
            ];
            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(false);
        }
    }

    async updateNote(noteData: any): Promise<boolean> {
        try {
            const noteId = noteData?.id;
            if (!noteData || !noteId) return Promise.resolve(false);

            const index = this.notes.findIndex((o) => o.id == noteId);
            if (index < 0) return Promise.resolve(false);
            const currentNote = this.notes[index];

            TaskService.getInstance().removeAwsFile(
                bucketTictop.AttachmentPhoto,
                currentNote?.imagePath
            );
            const newNoteShow = await new NoteClass(ModuleSourceFileEnum.task, {
                base64: noteData?.base64,
                drawData: noteData?.drawData,
            }).upload();

            this.notes[index] = {
                ...newNoteShow,
                id: noteId,
                isNote: true,
                base64: noteData?.base64,
                contentType: 'image/jpeg',
                actionType: 'EDIT',
            };

            const aIndex = this.attachments.findIndex((o) => o.id == noteId);
            if (aIndex > -1)
                this.attachments[aIndex] = {
                    ...newNoteShow,
                    id: noteId,
                    isNote: true,
                    base64: noteData?.base64,
                    contentType: 'image/jpeg',
                    actionType: 'EDIT',
                };

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async removeNote(note): Promise<boolean> {
        if (!note) return Promise.resolve(false);

        const noteId = note?.id;

        try {
            if (noteId) {
                const indexFile = this.notes.findIndex((f) => f?.id == noteId);
                if (indexFile !== -1)
                    this.notes[indexFile].actionType = 'REMOVE';

                this.attachments = this.attachments.filter(
                    (o) => o.id !== noteId
                );
            }

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    async removeFile(file): Promise<boolean> {
        if (!file) return Promise.resolve(false);
        try {
            if (file?.id) {
                const indexFile = this.files.findIndex((f) => f?.id == file.id);
                if (indexFile !== -1)
                    this.files[indexFile].actionType = 'REMOVE';

                this.attachments = this.attachments.filter(
                    (o) => o.id !== file.id
                );
            } else {
                this.files = this.files.filter(
                    (o) => o.localId !== file.localId
                );
                this.attachments = this.attachments.filter(
                    (o) => o.localId !== file.localId
                );
            }

            return Promise.resolve(true);
        } catch (error) {
            this.errorCode = 6100; // todo
            return Promise.reject(false);
        }
    }

    updateDataByFinishToday(isFinishItToday) {
        if (isFinishItToday) {
            this.taskSchedule.finishItToday = true;
            this.taskSchedule.doItToday = true;

            this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.OnDay;

            return Promise.resolve(true);
        }

        this.taskSchedule.finishItToday = false;

        this.taskSchedule.planType = PlanTypeEnum.ToDo;

        this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.Nothing;
    }

    addCollaboratorsInTask(payload) {
        this.collaborators = [...this.collaborators, ...payload];
    }

    deleteCollaboratorsInTask(userId) {
        this.collaborators = this.collaborators?.filter(
            (user) => user?.memberUserId !== userId
        );
    }
}
