import { defineStore } from 'pinia';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import storeId from '@/base/store/storeId';
import { UserCallingStatus } from '@/domain/enums/VideoCallEnum';

export default defineStore({
    id: storeId.UN_AUTH,
    state: () => ({
        _unAuthService: UnAuthenticateService.getInstance(),
        email: '',
        phone: '',
        resetToken: '',
        callingStatus: {} as UserCallingStatus | null,
    }),
    getters: {},
    actions: {
        async verifyToken() {
            const res = await this._unAuthService.verifyToken();
            return res?.result;
        },
    },
});
