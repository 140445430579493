<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0,0,256,256"
  >
    <defs>
      <linearGradient
        id="color-1_qrAVeBIrsjod_gr1"
        x1="24"
        y1="5"
        x2="24"
        y2="43"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#21ad64"></stop>
        <stop offset="1" stop-color="#088242"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(-32,-32) scale(1.25,1.25)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.33333,5.33333)">
          <path
            d="M39,16v25c0,1.105 -0.895,2 -2,2h-26c-1.105,0 -2,-0.895 -2,-2v-34c0,-1.105 0.895,-2 2,-2h17z"
            fill="url(#color-1_qrAVeBIrsjod_gr1)"
          ></path>
          <path d="M28,5v9c0,1.105 0.895,2 2,2h9z" fill="#61e3a7"></path>
          <path
            d="M39,16h-9c-0.473,0 -0.917,-0.168 -1.257,-0.444l10.257,11.444z"
            fill="#107c42"
          ></path>
          <path
            d="M32,23h-16c-0.553,0 -1,0.448 -1,1v12c0,0.552 0.447,1 1,1h16c0.553,0 1,-0.448 1,-1v-12c0,-0.552 -0.447,-1 -1,-1zM17,29h4v2h-4zM23,29h8v2h-8zM31,27h-8v-2h8zM21,25v2h-4v-2zM17,33h4v2h-4zM23,35v-2h8v2z"
            fill="#ffffff"
          ></path>
          <path
            d="M32,22.5c0.827,0 1.5,0.673 1.5,1.5v12c0,0.827 -0.673,1.5 -1.5,1.5h-16c-0.827,0 -1.5,-0.673 -1.5,-1.5v-12c0,-0.827 0.673,-1.5 1.5,-1.5h16M32,22h-16c-1.103,0 -2,0.897 -2,2v12c0,1.103 0.897,2 2,2h16c1.103,0 2,-0.897 2,-2v-12c0,-1.103 -0.897,-2 -2,-2z"
            fill="#000000"
            opacity="0.05"
          ></path>
          <path
            d="M32,23c0.553,0 1,0.448 1,1v12c0,0.552 -0.447,1 -1,1h-16c-0.553,0 -1,-0.448 -1,-1v-12c0,-0.552 0.447,-1 1,-1h16M32,22.5h-16c-0.827,0 -1.5,0.673 -1.5,1.5v12c0,0.827 0.673,1.5 1.5,1.5h16c0.827,0 1.5,-0.673 1.5,-1.5v-12c0,-0.827 -0.673,-1.5 -1.5,-1.5z"
            fill="#000000"
            opacity="0.07"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
