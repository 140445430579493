<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import userStore from '@/store/user';
import RatioColumnChart from '@/ui/common/plugins/chart/RatioColumnChart.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import atomDateRangPicker from '@/ui/common/atoms/date-pickers/atomDateRangPicker';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    size: number;
    orderBy: number;
  };
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    creatorId: number;
    fullName: string;
    avatar: string;
    createdTasks: number;
    lastCreationTime: string;
  }[]
>([]);

const chartData = computed<
  {
    total: number;
    id: number;
    name: string;
    avatar: string;
  }[]
>(() => {
  return resultData.value?.map((o) => {
    return {
      total: o.createdTasks,
      id: o.creatorId,
      name: o.fullName,
      avatar: o.avatar,
    };
  });
});

const { dataRangeString, updateCurrentChoice } = atomDateRangPicker();
const initComponent = async () => {
  isLoading.value = true;
  // const res = await WorkPerformanceRepository.getUserMostOverdueTask({
  //   ...props.filterData,
  // });

  const res = await WorkPerformanceRepository.getUserMostCreateTask({
    ...props.filterData,
  });
  resultData.value = res?.result
    ?.filter((o) => o.createdTasks > 0)
    ?.map((o) => {
      return {
        ...o,
        avatar: userStore().allUserByIds[o.creatorId]?.avatar,
      };
    });

  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.size == old.size &&
      data.orderBy == old.orderBy
    )
      return;

    initComponent();
  }
);

onMounted(() => {
  updateCurrentChoice({
    fromDate: props.filterData.dateFrom,
    toDate: props.filterData.dateTo,
  });
});

const genTooltipFunction = (item) => {
  return `<div class='p-2 w-max text-left bg-white flex flex-col space-y-2 rounded-xl'>
        <span class='font-semibold text-current'>
        ${item?.total + ' ' + translate('LABEL_TOTAL_TASK')?.toLowerCase()}
        </span>
        <span class='font-normal'>${dataRangeString.value}</span>
      </div>`;
};
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <RatioColumnChart
    v-else
    :chart-data="chartData"
    class="pt-4"
    :options="{
      backgroundColor: 'rgba(248, 113, 113, 0.2)',
      borderColor: 'rgba(248, 113, 113, 0.2)',
      hoverBackgroundColor: 'rgba(248, 113, 113, 0.5)',
      color: 'rgba(248, 113, 113, 1)',
    }"
    :gen-tooltip-function="genTooltipFunction"
  />
</template>
