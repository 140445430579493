<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { IWorkflowStep } from '@/domain/entities/workflow/WorkflowStepEntity';
import { IWorkflowEntity } from '@/domain/entities/workflow/WorkflowEntity';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import WorkflowStepItem from '@/ui/modules/workflow/workflow-step/WorkflowStepItem.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import BpmnDiagram from '@/ui/common/plugins/diagram/BpmnDiagram.vue';
import TaskWorkflowStepUpdateMultipleModal from '@/ui/modules/task/workflow/step/TaskWorkflowStepUpdateMultipleModal.vue';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';

const props = withDefaults(
  defineProps<{
    viewMode: 'VIEW' | 'EDIT' | 'TASK_VIEW' | 'ATTACH_TO_TASK';
    workflowInfo: IWorkflowEntity;
    workflowSteps: IWorkflowStep[];
    isUpdateRightAway?: boolean;
    drawData: string;
  }>(),
  {
    viewMode: 'VIEW',
  }
);

const emit = defineEmits<{
  (e: 'update:workflowSteps', data: IWorkflowStep[]): void;
  (e: 'update:drawData', data: string): void;
  (e: 'update:step', data: any): void;
  (e: 'updateAllStep', data: any): void;
}>();

const isSingleFlow = computed<boolean>(() => {
  return (
    permissionStore().allSystemConfigs[
      systemConfig.DRAFT_WORKFLOW_WORKFLOW_MULTI_BRANCHES_ALLOWE
    ] !== '1'
  );
});
const latestUpdatedTime = ref<number>(Date.now());

const updatePropData = () => {
  emit('update:workflowSteps', [...workflowStepsData.value]);

  latestUpdatedTime.value = Date.now();
};
onMounted(() => {
  // if (!props.workflowInfo?.id && workflowSteps.value?.length == 0)
  //   workflowSteps.value.push(generateNewStep(1));
});

const onDiagramReady = () => {
  props.workflowSteps.forEach((_workflowStep) => {
    if (
      bpmnDiagramRef.value?.handleChangeShape &&
      typeof bpmnDiagramRef.value?.handleChangeShape == 'function'
    )
      bpmnDiagramRef.value?.handleChangeShape({
        ..._workflowStep,
      });
  });

  bpmnDiagramRef.value?.handleClearUndoHistories();
};

const workflowStepsData = ref<IWorkflowStep[]>(
  props.workflowSteps?.length > 0
    ? (arrayOrderBy(
        [...props.workflowSteps],
        ['stepOrder'],
        ['asc']
      ) as IWorkflowStep[]) || []
    : []
);

const hasNotConnectionStepIds = computed<string[]>(() => {
  const nextStepIds: string[] = workflowStepsData.value?.reduce<string[]>(
    (currentArray, currentItem) => {
      return [
        ...currentArray,
        ...(currentItem.nextSteps?.map((s) => s?.id) || []),
      ];
    },
    []
  );

  return workflowStepsData.value
    ?.filter((s) => !s?.isStart)
    ?.map((s) => s?.id)
    ?.filter((stepId) => !nextStepIds?.includes(stepId));
});

const stepByIds = computed<any>(() => {
  return workflowStepsData.value?.reduce((currentResult, currentItem) => {
    return {
      ...currentResult,
      [currentItem?.id]: currentItem,
    };
  }, {});
});

const onShapeAdd = (shape) => {
  console.log('🚀 Tictop ~ shape:', shape);
  if (!shape) return;

  const removedShape = removedShapes.value.find(
    (s) => s?.id == shape?.id || s?.originalId == shape?.id
  );
  if (!removedShape?.id && shape?.isRedo) return;

  const isStart = workflowStepsData.value?.length == 0;
  const currentLength = workflowStepsData.value?.length;
  let newShape;
  if (removedShape?.id) {
    newShape = {
      ...removedShape,
    };

    removedShapes.value = removedShapes.value.filter(
      (s) => s?.id !== removedShape?.id
    );
  } else {
    newShape = {
      ...shape,
      priority: 1,
      important: false,
      code: `${currentLength + 1}`,
      isStart,
      stepOrder: currentLength,
    };
    bpmnDiagramRef.value?.handleChangeShape(newShape);
  }

  workflowStepsData.value.push(newShape);

  updatePropData();
};

const onShapeChanged = (shape) => {
  const index = workflowStepsData.value?.findIndex((o) => o?.id == shape?.id);
  if (index > -1)
    workflowStepsData.value[index] = {
      ...workflowStepsData.value[index],
      name: shape?.name,
      color: shape?.color,
    };

  updatePropData();
};

const removedShapes = ref<any[]>([]);
const onShapeRemoved = async (shape) => {
  const removeShape = workflowStepsData.value.find(
    (o) => o?.id == shape?.id || o?.originalId == shape?.id
  );

  if (removeShape?.id) {
    removedShapes.value = [...removedShapes.value, removeShape];
    workflowStepsData.value = workflowStepsData.value.filter(
      (o) => o?.id !== removeShape?.id
    );

    updateItemIndex();

    // check for undo
    settingGlobalModal({
      type: 'notification',
      title: '',
      content:
        translate('WORKFLOW_LABEl_STEP_CONFIRM_REMOVE', {
          stepName: `${removeShape?.code}. ${removeShape?.name || ''}`,
        }) || 'You are on another call',
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_DELETE'),
      closeable: true,
      options: { confirmButtonColor: 'red' },
    });
    const answer = await ask();
    if (!answer) {
      if (shape?.isUndo) bpmnDiagramRef.value?.handleRedo();
      else bpmnDiagramRef.value?.handleUndo();
    }
  }
};
const onConnectionChanged = (data: {
  connectionId: string;
  connectionName: string;
  startId: string;
  targetId: string;
}) => {
  const index = workflowStepsData.value?.findIndex(
    (o) => o?.id == data?.startId
  );
  if (index > -1) {
    const currentStep = workflowStepsData.value[index];
    let currentNextSteps = currentStep?.nextSteps
      ? [...currentStep?.nextSteps]
      : [];
    const targetStepId = data?.targetId;
    const nextStepIndex = currentNextSteps?.findIndex(
      (s) => s?.id == targetStepId || s?.condition?.id == data?.connectionId
    );

    const newCondition = {
      id: targetStepId,
      condition: {
        id: data?.connectionId,
        name: data?.connectionName,
        description: '',
        index: 0,
      },
    };
    if (nextStepIndex > -1) {
      if (
        currentNextSteps[nextStepIndex]?.id == data?.targetId &&
        currentNextSteps[nextStepIndex]?.condition?.id !== data?.connectionId
      ) {
        // remove new condition
        bpmnDiagramRef.value?.handleRemoveConnection({
          id: data?.connectionId,
        });
        return;
      }
      currentNextSteps[nextStepIndex] = newCondition;
    } else currentNextSteps = [...currentNextSteps, newCondition];

    workflowStepsData.value[index] = {
      ...workflowStepsData.value[index],
      nextSteps: [...currentNextSteps],
    };

    updatePropData();
  }
};

const onDrawDataChanged = (xmlString) => {
  emit('update:drawData', xmlString);
};
const onSelectionChanged = (selectedIds) => {
  isFocusingStepIds.value = selectedIds;
};
const onConnectionRemoved = (data) => {
  workflowStepsData.value.forEach((step) => {
    step.nextSteps = step?.nextSteps?.filter(
      (nextStep) => nextStep?.condition?.id !== data?.connectionId
    );
  });
};

const updateItemIndex = (indexByIds?) => {
  if (workflowStepsData.value?.length == 0) return;
  workflowStepsData.value.forEach((element, index) => {
    const stepIndex = indexByIds ? indexByIds[element?.id] : index;
    element.stepOrder = stepIndex;
    element.level = stepIndex;
    element.levelIndex = 0;
  });

  workflowStepsData.value =
    (arrayOrderBy(
      workflowStepsData.value,
      ['stepOrder'],
      ['asc']
    ) as IWorkflowStep[]) || [];

  workflowStepsData.value.forEach((element, index) => {
    const previousStepId =
      index > 0 ? workflowStepsData.value[index - 1].id : '';
    const nextStepId =
      index < workflowStepsData.value?.length
        ? workflowStepsData.value[index + 1]?.id
        : '';
    element.previousStepIds = previousStepId ? [`${previousStepId}`] : [];
    element.nextStepIds = nextStepId ? [`${nextStepId}`] : [];
  });
  updatePropData();
};
const onChangeDrag = (event) => {
  const childrenList = event?.target?.children;
  if (!childrenList || childrenList?.length == 0) return;

  let indexByIds = {};

  for (let index = 0; index < childrenList.length; index++) {
    const stepId = childrenList[index]?.id;
    indexByIds[stepId] = index;
  }

  updateItemIndex(indexByIds);
};

const onRemoveStepItem = (step) => {
  const removeSuccess = bpmnDiagramRef.value?.handleRemoveShape(step);
  if (!removeSuccess)
    workflowStepsData.value = workflowStepsData.value.filter(
      (o) => o?.id !== step?.id
    );
};

const onUpdateWorkflowStep = (workflowStep) => {
  if (!workflowStep?.id) return;

  const index = workflowStepsData.value?.findIndex(
    (s) => s?.id == workflowStep?.id
  );
  if (index == -1) return;

  workflowStepsData.value[index] = { ...workflowStep };

  bpmnDiagramRef.value?.handleChangeShape(workflowStep);

  emit('update:step', workflowStep);

  updatePropData();
};
const bpmnDiagramViewerRef = ref<any>(null);
const handleFocusShape = (shapeId) => {
  if (dataUiByViewMode.value?.diagramReadonly)
    bpmnDiagramViewerRef.value?.handleFocusShape(shapeId);
  else bpmnDiagramRef.value?.handleFocusShape(shapeId);
};
const handleSetStartPoint = (workflowStep) => {
  if (!workflowStep?.id) return;
  workflowStepsData.value.forEach((step, index) => {
    step.isStart = step?.id == workflowStep?.id;
    bpmnDiagramRef.value?.handleChangeShape({
      ...step,
      isInit: index == workflowStepsData.value?.length - 1,
    });
  });

  updatePropData();
};

const isOpeningStepIds = ref<string[]>([]);
const isFocusingStepIds = ref<string[]>([]);

const bpmnDiagramRef = ref<any>(null);
const canUndo = () => {
  return bpmnDiagramRef.value?.canUndo();
};

const isOpenUpdateMultipleSteps = ref<boolean>(false);

const onSaveAllSteps = (updatedWorkflowSteps) => {
  // isOpenUpdateMultipleSteps.value = false;

  if (!workflowStepsData.value) return;

  workflowStepsData.value = updatedWorkflowSteps;

  updatePropData();

  emit('updateAllStep', workflowStepsData.value);
};

const openUpdateMultipleStepsModal = () => {
  isOpenUpdateMultipleSteps.value = true;
};

const dataUiByViewMode = computed<{
  diagramReadonly: boolean;
  actionList: string[];
  sortable: boolean;
  isUpdateImmediately: boolean;
}>(() => {
  if (props.viewMode == 'ATTACH_TO_TASK')
    return {
      diagramReadonly: true,
      actionList: ['EDIT'],
      sortable: false,
      isUpdateImmediately: false,
    };
  if (props.viewMode == 'TASK_VIEW')
    return {
      diagramReadonly: true,
      actionList: ['EDIT', 'REDIRECT'],
      sortable: false,
      isUpdateImmediately: false,
    };
  if (props.viewMode == 'VIEW')
    return {
      diagramReadonly: true,
      actionList: ['EDIT'],
      sortable: false,
      isUpdateImmediately: true,
    };
  return {
    diagramReadonly: false,
    actionList: ['EDIT', 'REMOVE'],
    sortable: true,
    isUpdateImmediately: false,
  };
});

const toggleExpandCollapseAll = (isCollapseAll) => {
  isOpeningStepIds.value = isCollapseAll
    ? []
    : workflowStepsData.value?.map((s) => s?.id);
};

defineExpose({
  canUndo,
});
</script>

<template>
  <div class="flex-1 min-h-0 w-full h-full flex flex-col overflow-hidden">
    <div class="w-full h-full flex overflow-hidden gap-4">
      <div class="flex-1 h-full border rounded-md">
        <template v-if="dataUiByViewMode?.diagramReadonly">
          <BpmnDiagram
            :key="drawData"
            ref="bpmnDiagramViewerRef"
            readonly
            :draw-data="drawData"
            @selection-changed="onSelectionChanged"
          />
          <div class="invisible">
            <BpmnDiagram
              ref="bpmnDiagramRef"
              :draw-data="drawData"
              :is-single-flow="isSingleFlow"
              @shape-add="onShapeAdd"
              @shape-changed="onShapeChanged"
              @shape-removed="onShapeRemoved"
              @connection-changed="onConnectionChanged"
              @draw-data-changed="onDrawDataChanged"
              @selection-changed="onSelectionChanged"
              @connection-removed="onConnectionRemoved"
              @ready="onDiagramReady"
            />
          </div>
        </template>
        <template v-else>
          <BpmnDiagram
            ref="bpmnDiagramRef"
            :readonly="viewMode == 'ATTACH_TO_TASK' || viewMode == 'TASK_VIEW'"
            :draw-data="drawData"
            :is-single-flow="isSingleFlow"
            @shape-add="onShapeAdd"
            @shape-changed="onShapeChanged"
            @shape-removed="onShapeRemoved"
            @connection-changed="onConnectionChanged"
            @draw-data-changed="onDrawDataChanged"
            @selection-changed="onSelectionChanged"
            @ready="onDiagramReady"
            @connection-removed="onConnectionRemoved"
          />
        </template>
      </div>

      <div
        class="
          w-[28rem]
          h-full
          flex flex-col
          gap-2
          overflow-hidden
          rounded-md
          bg-white
        "
      >
        <div class="flex items-center justify-between p-0.5">
          <div>
            <AtomButton
              v-vig-tooltip="$t('COMMON_LABEL_VIEW_AND_ADJUST')"
              type="OUT_LINE"
              color="current"
              icon-name="Filter"
              class="text-sm"
              :disabled="workflowStepsData?.length == 0"
              :label="$t('WORKFLOW_LABEL_UPDATE_PROPERTY_FOR_STEP')"
              @click="openUpdateMultipleStepsModal"
            />
          </div>
          <div class="flex-center gap-1">
            <div
              class="
                w-8
                h-8
                rounded-md
                cursor-pointer
                flex-center
                border border-gray-100
                hover:shadow
              "
              @click.stop="toggleExpandCollapseAll(false)"
            >
              <SynIcon
                :name="'zoom-in2'"
                :custom-class="`h-4 w-4 fill-gray-500`"
              />
            </div>
            <div
              class="
                w-8
                h-8
                rounded-md
                cursor-pointer
                flex-center
                border border-gray-100
                hover:shadow
              "
              @click.stop="toggleExpandCollapseAll(true)"
            >
              <SynIcon
                :name="'zoom-out2'"
                :custom-class="`h-4 w-4 fill-gray-500`"
              />
            </div>
          </div>
        </div>
        <div class="flex-1 min-h-0 overflow-auto small-scrollbar">
          <template v-if="workflowStepsData?.length > 0">
            <SortAble
              :key="latestUpdatedTime"
              :disabled="!dataUiByViewMode?.sortable"
              :options="{
                onMove: true,
              }"
              @on-end-sort="onChangeDrag"
            >
              <template #list-item>
                <WorkflowStepItem
                  v-for="step in workflowStepsData"
                  :key="step?.id"
                  v-model:is-opening-step-ids="isOpeningStepIds"
                  v-model:is-focusing-step-ids="isFocusingStepIds"
                  class="w-full flex items-center justify-between"
                  :step-item="step"
                  :step-by-ids="stepByIds"
                  :view-mode="viewMode"
                  :action-list="dataUiByViewMode?.actionList"
                  :sortable="dataUiByViewMode?.sortable"
                  :is-update-immediately="dataUiByViewMode?.isUpdateImmediately"
                  :has-no-connection="
                    hasNotConnectionStepIds?.some((id) => id == step?.id)
                  "
                  @remove="onRemoveStepItem(step)"
                  @change="onUpdateWorkflowStep"
                  @set-start-point="handleSetStartPoint(step)"
                  @focus-shape="handleFocusShape"
                />
              </template>
            </SortAble>
          </template>
          <div v-else class="flex-center space-x-8 p-8">
            <span class="italic">
              {{ $t('WORKFLOW_LABEl_HAVE_NOT_STEP') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TaskWorkflowStepUpdateMultipleModal
    v-if="isOpenUpdateMultipleSteps"
    :workflow-steps="workflowStepsData"
    :is-not-null-update-data="
      viewMode == 'ATTACH_TO_TASK' || viewMode == 'TASK_VIEW'
    "
    @on-save="onSaveAllSteps"
    @close="isOpenUpdateMultipleSteps = false"
  />
</template>
