<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import StepTemplateEntity, {
  IStepTemplateEntity,
} from '@/domain/entities/workflow/StepTemplateEntity';
import TaskTemplate from '@/ui/modules/task/components/task-template/TaskTemplate.vue';
import { TaskTemplateEntity } from '@/domain/entities/task/TaskTemplateEntity';
import StepTemplateInformation from '@/ui/modules/workflow/step-template/StepTemplateInformation.vue';
import {
  convertUrgencyToImportant,
  convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import workflowStepStore from '@/store/workflow/workflow-step.store';

const props = withDefaults(
  defineProps<{
    viewMode?: 'VIEW' | 'EDIT' | 'ADD' | 'CLONE';
    stepDetail: IStepTemplateEntity;
    readonly?: boolean;
    actionList?: string[];
  }>(),
  {
    viewMode: 'VIEW',
    actionList: () => ['DUPLICATE', 'EDIT', 'REMOVE'],
  }
);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'updateSuccess', newData: any): void;
  (e: 'removeSuccess'): void;
}>();

const _workflowStepStore = workflowStepStore();
const workflowStepData = ref<StepTemplateEntity>(
  new StepTemplateEntity(props.stepDetail || {})
);
const isLoading = ref<boolean>(true);

const inCurrentTaskTemplate = () => {
  currentTaskTemplate.value = new TaskTemplateEntity({
    id: 'draft',
    groupId: workflowStepData.value?.groupId,
    assigneeId: workflowStepData.value?.assigneeId,
    domainId: workflowStepData.value?.domainId,
    important: workflowStepData.value?.important,
    priority: workflowStepData.value?.priority,
    description: workflowStepData.value?.taskDescription,
    startTime: workflowStepData.value?.startTime,
    scheduleByHour: workflowStepData.value?.scheduleByHour,
    scheduleTime: workflowStepData.value?.scheduleTime,
    scheduleTimezone: workflowStepData.value?.scheduleTimezone,
    taskSchedule: {
      scheduleOptionKey: workflowStepData.value?.scheduleDayOption,
      scheduleDateValue: workflowStepData.value?.scheduleTime || '',
    },
    files: workflowStepData.value?.files || [],
    notes: workflowStepData.value?.notes || [],
    taskTodos: workflowStepData.value?.todoLists || [],
    collaborators: workflowStepData.value?.collaborators
      ? workflowStepData.value?.collaborators?.map((u) => {
          return {
            memberUserId: u?.userId,
            memberRoleId: u?.roleId,
          };
        })
      : [],
  });
};
const getStepDetail = async () => {
  if (!props.stepDetail?.id) {
    isLoading.value = false;
    return;
  }

  isLoading.value = true;
  const res = await WorkflowRepository.getInstance().getWorkFlowStepTemplate(
    props.stepDetail?.id
  );
  workflowStepData.value = new StepTemplateEntity(
    {
      ...props.stepDetail,
      ...res?.result,
    } || {}
  );
  if (props.viewMode == 'CLONE') {
    workflowStepData.value.id = '';
    workflowStepData.value.name = _workflowStepStore.generateWorkflowName(
      workflowStepData.value.name
    );
  }

  inCurrentTaskTemplate();
  isLoading.value = false;
};

onMounted(() => {
  getStepDetail();
});

watch(
  () => props.stepDetail?.id,
  () => {
    if (workflowStepData.value?.id !== props.stepDetail?.id) {
      workflowStepData.value = new StepTemplateEntity(props.stepDetail || {});

      inCurrentTaskTemplate();
    }
  }
);

const visibleStatus = reactive<{ information: boolean; taskTemplate: boolean }>(
  { information: true, taskTemplate: true }
);

const currentTaskTemplate = ref<TaskTemplateEntity>(
  new TaskTemplateEntity({
    urgency: null,
    priority: null,
    important: null,
    taskSchedule: {
      doItToday: false,
      finishItToday: false,
      overdue: null,
      code: '',
      scheduleOptionKey: null,
      scheduleDateValue: '',
      currentUserDate: null,
      otherTime: null,
      otherTimes: [],
      planType: 0,
    },
  })
);

const isUpdating = ref<boolean>(false);
const isDisableButton = computed<boolean>(() => {
  return !workflowStepData.value?.name;
});
const viewMode = ref<'VIEW' | 'EDIT' | 'ADD' | 'CLONE'>(props.viewMode);
const onSave = async () => {
  try {
    isUpdating.value = true;

    workflowStepData.value.groupId = currentTaskTemplate.value?.groupId;
    workflowStepData.value.assigneeId = currentTaskTemplate.value?.assigneeId;
    workflowStepData.value.domainId = currentTaskTemplate.value?.domainId;
    workflowStepData.value.priority =
      currentTaskTemplate.value?.urgency &&
      currentTaskTemplate.value?.urgency > 0
        ? convertUrgencyToPriority(currentTaskTemplate.value?.urgency)
        : currentTaskTemplate.value?.priority || 1;
    workflowStepData.value.important =
      currentTaskTemplate.value?.urgency &&
      currentTaskTemplate.value?.urgency > 0
        ? convertUrgencyToImportant(currentTaskTemplate.value?.urgency)
        : !!currentTaskTemplate.value?.priority;

    workflowStepData.value.startTime =
      currentTaskTemplate.value?.startTime || undefined;
    workflowStepData.value.scheduleDayOption =
      currentTaskTemplate.value?.taskSchedule?.scheduleOptionKey;
    workflowStepData.value.scheduleByHour =
      currentTaskTemplate.value?.scheduleByHour || false;
    workflowStepData.value.scheduleTime =
      currentTaskTemplate.value?.scheduleTime || undefined;
    workflowStepData.value.scheduleTimezone =
      currentTaskTemplate.value?.scheduleTimezone || '';
    workflowStepData.value.taskDescription =
      currentTaskTemplate.value?.description;
    workflowStepData.value.files = [...currentTaskTemplate.value?.files];
    workflowStepData.value.notes = [...currentTaskTemplate.value?.notes];
    workflowStepData.value.todoLists =
      currentTaskTemplate.value?.taskTodos || [];

    workflowStepData.value.collaborators =
      currentTaskTemplate.value?.collaborators?.map((u) => {
        return {
          userId: u?.memberUserId,
          roleId: u?.memberRoleId,
        };
      });
    let res: any;
    if (viewMode.value == 'ADD' || viewMode.value == 'CLONE') {
      res = await workflowStepData.value.saveAddNew(viewMode.value == 'CLONE');
    } else res = await workflowStepData.value.saveUpdate();

    isUpdating.value = false;

    workflowStepData.value = new StepTemplateEntity(
      { ...workflowStepData.value, ...res?.result } || {}
    );
    inCurrentTaskTemplate();
    viewMode.value = 'VIEW';
    emit('updateSuccess', { ...workflowStepData.value, ...res?.result });
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isUpdating.value = false;
  }
};
const onOpenEditMode = () => {
  viewMode.value = 'EDIT';
};

const actionDataListByKey = {
  DUPLICATE: {
    key: 'DUPLICATE',
    iconName: 'duplicate',
    color: '',
    handleClick: () => {
      workflowStepData.value = new StepTemplateEntity({
        ...props.stepDetail,
      });
      viewMode.value = 'CLONE';
    },
  },
  EDIT: {
    key: 'EDIT',
    iconName: 'edit',
    color: '',
    handleClick: async (step) => {
      const editable = await new StepTemplateEntity(step).checkEditable();
      if (!editable) return;

      onOpenEditMode();
    },
  },
  REMOVE: {
    key: 'REMOVE',
    iconName: 'trash',
    color: 'red',
    handleClick: async (step) => {
      const res = await new StepTemplateEntity(step).remove();

      if (!res) return;

      emit('removeSuccess');
    },
  },
};
const onCancelEdit = () => {
  if (
    props.viewMode == 'ADD' ||
    props.viewMode == 'EDIT' ||
    props.viewMode == 'CLONE'
  ) {
    emit('close');
    return;
  }

  workflowStepData.value = new StepTemplateEntity({
    ...props?.stepDetail,
  });
  viewMode.value = 'VIEW';
};

const actionDataList = computed<any[]>(() => {
  return props.actionList?.map((key) => actionDataListByKey[key]);
});
</script>
<template>
  <div
    class="
      flex flex-col
      justify-between
      flex-1
      min-h-0
      w-full
      text-xs
      overflow-hidden
    "
  >
    <div class="flex-1 min-h-0 w-full h-full px-2 flex flex-col space-y-3">
      <!-- HEADER -->
      <div
        class="
          w-full
          flex
          items-center
          space-x-2
          rounded
          cursor-pointer
          pt-4
          px-4
        "
      >
        <div class="flex-1 min-w-0 truncate">
          <!-- <span class="text-sm">
              {{ $t('COMMON_LABEL_INFORMATION') }}
            </span> -->
          <div
            class="text-base min-w-max font-semibold"
            v-html="
              $t(
                viewMode == 'ADD' || viewMode == 'CLONE'
                  ? 'WORKFLOW_LABEL_CREATE_NEW_WORKFLOW_STEP'
                  : 'WORKFLOW_LABEl_STEP_WITH_NAME',
                {
                  stepName: workflowStepData?.name || '',
                }
              )
            "
          ></div>
        </div>
        <!-- <SynIcon
          :name="'sort-down'"
          custom-class="h-2.5 w-2.5"
          :class="!visibleStatus.information ? '-rotate-90' : ''"
        /> -->
        <div
          v-if="viewMode == 'VIEW' && !readonly"
          class="flex-center space-x-1"
        >
          <div
            v-for="action in actionDataList"
            :key="action?.key"
            class="
              w-8
              h-8
              rounded-md
              cursor-pointer
              flex-center
              border border-gray-100
              hover:shadow
            "
            @click="action?.handleClick(workflowStepData)"
          >
            <SynIcon
              :name="action?.iconName"
              :custom-class="
                action?.color ? `h-4 w-4 fill-${action?.color}-500` : ''
              "
            />
          </div>
        </div>
      </div>
      <!-- BODY -->
      <div
        v-if="!isLoading"
        class="
          flex-1
          min-h-0
          overflow-auto
          small-scrollbar
          rounded
          w-full
          flex flex-col
          px-4
          py-1
        "
      >
        <StepTemplateInformation
          v-if="visibleStatus.information"
          v-model:step-detail="workflowStepData"
          :view-mode="viewMode == 'VIEW' ? 'VIEW' : 'EDIT'"
        />
        <!-- TASK REPEAT TEMPLATE -->
        <TaskTemplate
          v-if="visibleStatus.taskTemplate"
          ref="taskRepeatTemplateRef"
          v-model:task-template="currentTaskTemplate"
          :view-mode="viewMode == 'VIEW' ? 'VIEW' : 'EDIT'"
          is-do-not-need-add-user-info
          is-update-domain-only
          style="min-height: max-content"
          :element-list="[
            'RESPONSIBLE',
            'COLLABORATORS',
            'DOMAIN',
            'URGENCY',
            'DESCRIPTION',
            'DEADLINE',
            'ATTACHMENT',
            'CHECK_LIST',
          ]"
          is-hidden-start-time
        />
      </div>
    </div>

    <div v-if="!readonly" class="w-full flex items-center justify-end">
      <div class="flex-center space-x-2 p-4">
        <template v-if="viewMode == 'EDIT'">
          <SynButton type-text color="gray" @click="onCancelEdit">
            <span> {{ $t('COMMON_LABEL_CANCEL') }}</span>
          </SynButton>
          <SynButton
            :is-loading="isUpdating"
            :disabled="isDisableButton"
            @click="onSave"
          >
            <span> {{ $t('COMMON_LABEL_SAVE') }}</span>
          </SynButton>
        </template>
        <template v-else-if="viewMode == 'ADD' || viewMode == 'CLONE'">
          <SynButton type-text color="gray" @click="onCancelEdit">
            <span> {{ $t('COMMON_LABEL_CANCEL') }}</span>
          </SynButton>
          <SynButton
            :is-loading="isUpdating"
            :disabled="isDisableButton"
            @click="onSave"
          >
            <span> {{ $t('COMMON_LABEL_CREATE') }}</span>
          </SynButton>
        </template>
      </div>
    </div>
  </div>
</template>
