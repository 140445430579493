<template>
  <svg
    id="a"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: #fff"
    viewBox="0 0 1263.26 350.3"
  >
    <path
      class="b"
      style="fill: #fff"
      d="M140.23,70.3V210.3c0,77.32-62.78,140-140.23,140H0V70.3C.03,31.61,31.43,.27,70.12,.3h0c38.69-.03,70.08,31.31,70.11,70h0Z"
    />
    <path
      class="h"
      style="isolation: isolate; fill: #f2cb05; opacity: 0.63"
      d="M48.86,3.4C19.78,12.61,.02,39.59,0,70.09v9.09c4.51,34.95,34.29,61.12,69.53,61.1h70.7V70.09c-.01-36.8-28.52-67.3-65.23-69.81h-5.47c-7.01,0-13.97,1.05-20.67,3.12Z"
    />
    <ellipse
      class="j"
      style="fill: #005167"
      cx="70.12"
      cy="70.08"
      rx="58.43"
      ry="58.33"
    />
    <path
      class="m"
      style="fill: #8fcdcd"
      d="M253.45,166.09h0c4.29-7.4,13.75-9.94,21.17-5.68l13.43,7.74c7.41,4.26,9.97,13.72,5.7,21.14v.02h-.01c-4.3,7.41-13.78,9.94-21.2,5.66l-13.43-7.74c-7.4-4.27-9.94-13.73-5.66-21.13h0Z"
    />
    <path
      class="i"
      style="fill: #d3ffff"
      d="M213.16,214.16h0c6.06-6.05,15.88-6.05,21.94,0l11,10.95c6.05,6.03,6.06,15.82,.04,21.86l-.04,.04h0c-6.06,6.05-15.88,6.05-21.94,0l-11-10.95c-6.13-5.94-6.44-15.57-.51-21.7,.12-.12,.23-.24,.51-.2Z"
    />
    <path
      class="b"
      style="fill: #fff"
      d="M187.16,294.41h0c-7.41,4.29-16.9,1.76-21.2-5.65h0l-7.76-13.42c-4.28-7.42-1.73-16.91,5.7-21.18s16.91-1.73,21.18,5.7l7.76,13.42c4.27,7.4,1.73,16.86-5.67,21.13h-.01Z"
    />
    <path
      class="l"
      style="fill: #f2cb05"
      d="M75,.3c36.7,2.51,65.21,33,65.23,69.79v70.19h69.54c77.44,0,140.23-62.68,140.23-140l-275,.02Z"
    />
    <polygon
      class="b"
      style="fill: #fff"
      points="539.14 50.16 517.64 66 517.64 233 560.64 233 560.64 66 539.14 50.16"
    />
    <polygon
      class="k"
      style="fill: #ffb62f"
      points="560.64 59.28 560.64 43.58 539.14 27.35 517.64 43.58 517.64 59.35 517.74 59.35 539.24 43.12 560.64 59.28"
    />
    <polygon
      class="l"
      style="fill: #f2cb05"
      points="560.64 36.93 560.64 21 539.14 4.54 517.64 21 517.64 37 517.74 37 539.24 20.54 560.64 36.93"
    />
    <path
      class="b"
      style="fill: #fff"
      d="M1012.83,232.16l-36.84-40.82c-17.76-5.95-29.84-22.45-30.16-41.18-.36-8.75,1.71-17.43,6-25.07,3.54-6.39,8.63-11.8,14.79-15.73,.26-.17,.54-.33,.8-.49l.49-.3c4.87-2.96,10.33-4.81,16-5.41,17.55-1.85,29.69,8.87,32,11,6.81,6.39,11.37,14.81,13,24l39.7,37.71c2.86-8.96,4.31-18.31,4.3-27.71,0-48.6-38-88-85-88s-85,39.4-85,88,38.06,88,85,88c6.13-.06,12.25-.73,18.25-2,2.83-.74,5.18-1.5,6.67-2Z"
    />
    <path
      class="b"
      style="fill: #fff"
      d="M1028.83,138.16c.7,3.29,1.03,6.64,1,10,0,3.71-.72,21.32-15,34-7.49,6.63-17.01,10.51-27,11-1.61,0-3.25-.13-3.25-.13-2.92-.25-5.8-.81-8.59-1.69l36.84,40.82c13.62-4.56,25.95-12.33,35.94-22.66,9-9.6,15.75-21.09,19.76-33.63l-39.7-37.71Z"
    />
    <path
      class="c"
      style="fill: #fff; opacity: 0.27; isolation: isolate"
      d="M1026.83,130.16c2.1,5.76,3.12,11.87,3,18-.08,4.14-.57,18-11,30-4.71,5.21-10.52,9.31-17,12-4.42,1.92-9.18,2.94-14,3-.28,0-1.61,0-3.25-.13-6.27-.66-12.32-2.66-17.75-5.87l38,47c32.27-6.87,57.63-31.84,65-64l-43-40Z"
    />
    <path
      class="d"
      style="fill: #fff; isolation: isolate"
      d="M1029.83,146.16c.17,4.03-.16,8.06-1,12-1.11,5.31-3.14,10.39-6,15-.81,1.28-11.47,17.53-31,20-3.32,.42-6.68,.42-10,0l34,38c12.96-4.93,24.6-12.8,34-23,7.25-7.9,13.01-17.05,17-27l-37-35Z"
    />
    <path
      class="l"
      style="fill: #f2cb05"
      d="M944.83,149c-.07,3.36,.27,6.72,1,10,1.01,4.24,2.7,8.3,5,12,3.56,5.93,8.33,11.04,14,15,2.2,1.55,4.55,2.89,7,4,6.23,2.94,13.18,3.98,20,3,6.8-.8,13.31-3.2,19-7,7.2-4.94,12.76-11.9,16-20,3.88-9.52,4.58-20.05,2-30-1.65-6.59-4.72-12.73-9-18-5.73-6.8-13.41-11.69-22-14-7.93-2.02-16.27-1.68-24,1-15.4,5.44-22.16,18.35-24,22-3.36,6.84-5.07,14.38-5,22Z"
    />
    <path
      class="b"
      style="fill: #fff"
      d="M391.25,0h42V67h65v42h-65v52c1.27,14.91,12.3,27.17,27,30h38v43l-41-1c-35.81-3.97-63.62-33.05-66-69v-55h-21c-.33-14-.67-28-1-42h22V0Z"
    />
    <path
      class="b"
      style="fill: #fff"
      d="M704.07,131l31-32c-2.19-3.26-27.11-39.08-70-39-30.9,.06-51.37,18.71-57,24-4.27,4-27,26.14-28,62-.22,7.46,0,32.66,18,56,3.39,4.4,26.78,33.67,67,34,43.1,.35,67.73-32.82,70-36l-31-31c-1.24,2.42-9.84,18.48-29,23-13.88,3.27-29.48-.42-40-11-11.28-11.35-11.83-25.71-12-30-.43-10.72,2.74-21.28,9-30,2-2.68,14.14-19.27,34-19,16.33,.23,31.55,11.78,38,29Z"
    />
    <path
      class="b"
      style="fill: #fff"
      d="M776.46,0h42V67h65v42h-65v52c1.28,14.91,12.3,27.16,27,30h38v43l-41-1c-35.81-3.97-63.62-33.05-66-69v-55h-21c-.33-14-.66-28-1-42h22V0Z"
    />
    <path
      class="b"
      style="fill: #fff"
      d="M1263.25,148c-.08-8.81-.36-39.73-25-64-4.49-4.42-26.23-24.82-60-24-33.56,.82-54.24,22-58,26l-17-20h-11v231h43v-75c12.6,9.02,27.52,14.23,43,15,37,1.57,60.64-24.22,64-28,20.91-23.56,21.09-51.21,21-61Zm-45.65,18c-3.23,8.11-8.8,15.07-16,20-5.69,3.8-12.2,6.2-19,7-3.32,.44-6.68,.44-10,0-3.45-.54-6.82-1.55-10-3-2.45-1.12-4.79-2.46-7-4-5.67-3.97-10.43-9.08-14-15-2.3-3.71-3.99-7.76-5-12-.72-3.28-1.06-6.64-1-10-.06-7.62,1.65-15.15,5-22,1.84-3.65,8.61-16.56,24-22,7.73-2.68,16.08-3.03,24-1,8.59,2.31,16.27,7.2,22,14,4.28,5.27,7.35,11.41,9,18,2.59,9.95,1.89,20.47-1.98,30h-.02Z"
    />
    <g
      class="g"
      style="isolation: isolate"
      transform="translate(387.49 331.87) scale(1.12 1)"
    >
      <text
        class="f"
        style="
          fill: #fff;

          isolation: isolate;
          font-family: Roboto-Light, Roboto;
          font-size: 68px;
        "
        transform="translate(387.49 331.87) scale(1.12 1)"
      >
        <tspan x="0" y="0">W</tspan>
      </text>
      <text
        class="e"
        style="fill: #fff; isolation: isolate; letter-spacing: 0.13em"
        transform="translate(464.67 331.87) scale(1.12 1)"
      >
        <tspan x="0" y="0">orking is fun!</tspan>
      </text>
    </g>
  </svg>
</template>
