<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import UserById from '@/ui/components/user/UserById.vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import DomainById from '@/ui/modules/domains/component/DomainById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import TaskStateLabel from '@/ui/modules/task/components/TaskStateLabel.vue';
import ProjectById from '@/ui/modules/domains/component/ProjectById.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import TaskPlaningLabel from '@/ui/modules/task/detail/_commons/task-planing/TaskPlaningLabel.vue';

const props = defineProps<{
  dataFilters?: any;
  type:
    | 'status'
    | 'date'
    | 'group'
    | 'domain'
    | 'project'
    | 'urgency'
    | 'taskPlan'
    | 'user'
    | 'radio';
  isNothing?: boolean;
}>();

const emit = defineEmits(['onOk', 'onCancel', 'onResetFilter']);

const creatorFilters = ref<any>([]);
const filterDate = ref<{
  start: Date | number | string | null;
  end: Date | number | string | null;
  filterType: 'TODAY' | 'OTHER' | 'NOTHING' | null;
}>({
  start: null,
  end: null,
  filterType: null,
});

const onSelectCreator = (item, type) => {
  const index = creatorFilters.value?.findIndex((o) => o.id == item.id);
  if (index > -1) {
    if (
      (type != 'domain' && type != 'project') ||
      (type === 'domain' && !item.projectId?.length) ||
      (type === 'project' && !item.subProjectId?.length)
    ) {
      creatorFilters.value[index].selected =
        !creatorFilters.value[index].selected;
    } else if (type == 'domain') {
      creatorFilters.value[index].selected =
        item.projectId?.filter((o) => o.selectedId)?.length <
        item.projectId?.length;
      creatorFilters.value[index].projectId = item.projectId?.map((e) => {
        e.selectedId = creatorFilters.value[index].selected;
        return e;
      });
    } else if (type == 'project') {
      creatorFilters.value[index].selected =
        item.subProjectId?.filter((o) => o.selectedId)?.length <
        item.subProjectId?.length;
    }
    creatorFilters.value[index].subProjectId = item.subProjectId?.map((e) => {
      e.selectedId = creatorFilters.value[index].selected;
      return e;
    });
  }
};

const onDomainClick = (domain) => {
  if (
    domain.projectId?.length > 0 &&
    domain.projectId?.some((e) => e.projectId !== null)
  ) {
    return collapseDomain(domain);
  }

  onSelectCreator(domain, 'domain');
};

const onSelectSubProject = (item, subProjectId) => {
  const index = item.subProjectId.findIndex(
    (o) => o.subProjectId == subProjectId
  );
  if (index > -1) {
    item.subProjectId[index].selectedId = !item.subProjectId[index].selectedId;
    item.selected = !item.selected;
    const listSubProject = item.subProjectId?.filter((e) => e.selectedId);
    if (listSubProject?.length > 0) {
      item.selected = true;
    } else {
      !item.selected;
    }
  }
};

const onSelectProject = (item, projectId) => {
  const index = item.projectId.findIndex((o) => o.projectId == projectId);
  if (index > -1) {
    item.projectId[index].selectedId = !item.projectId[index].selectedId;
    item.selected = !item.selected;
    const listProject = item.projectId?.filter((e) => e.selectedId);
    if (listProject?.length > 0) {
      item.selected = true;
    } else {
      !item.selected;
    }
  }
};

const onFilter = () => {
  let filterResult;
  let arrListProject;
  let arrListSubProject;

  switch (props.type) {
    case 'date':
      filterResult = filterDate.value;
      break;
    case 'domain':
      arrListProject = creatorFilters.value?.filter((o) =>
        o.projectId?.some((u) => u.selectedId)
      );
      if (arrListProject?.length > 0) {
        filterResult = arrListProject;
      } else {
        filterResult = creatorFilters.value?.filter((o) => o.selected);
      }
      break;

    case 'project':
      arrListSubProject = creatorFilters.value?.filter((o) =>
        o.subProjectId?.some((u) => u.selectedId)
      );
      if (arrListSubProject?.length > 0) {
        filterResult = arrListSubProject;
      } else {
        filterResult = creatorFilters.value?.filter((o) => o.selected);
      }
      break;

    case 'radio':
      filterResult = props.dataFilters?.map((data) => {
        return {
          ...data,
          selected: data?.key === radioData.value,
        };
      });

      break;
    default:
      filterResult = creatorFilters.value?.filter((o) => o.selected);
      break;
  }
  emit('onOk', filterResult);
};

const onClearFilterCreator = () => {
  switch (props.type) {
    case 'date':
      filterDate.value = { start: null, end: null, filterType: null };
      break;

    default:
      creatorFilters.value.forEach((item) => {
        item.selected = false;
      });
      break;
  }
  emit('onResetFilter');
};
// const originData = ref([]);
const isOpenFilter = ref(false);
const onOpenDropdown = () => {
  isOpenFilter.value = !isOpenFilter.value;
  creatorFilters.value = props.dataFilters ? cloneDeep(props.dataFilters) : [];
};
const onCancel = () => {
  // creatorFilters.value = cloneDeep(originData.value);
  // originData.value = [];
  creatorFilters.value = props.dataFilters ? cloneDeep(props.dataFilters) : [];
  emit('onCancel');
};
const totalFilterItem = computed(() => {
  if (props.type == 'date') return props.dataFilters?.filterType ? 1 : 0;
  if (props.type == 'radio') {
    return props.dataFilters?.some((o) => o?.selected) ? 1 : 0;
  }

  return creatorFilters.value?.filter((o) => o.selected)?.length;
});

// const filterByDeadlineOptions = [
//   { key: 'TODAY', labelCode: 'CALENDAR_TITLE_TODAY' },
//   { key: 'OTHER', labelCode: 'COMMON_LABEL_PERIOD' },
//   { key: 'NOTHING', labelCode: 'TASK_DETAIL_LABEL_NO_SCHEDULE' },
// ];

const filterByDateOptions = computed(() => {
  const filterbyDate = [
    { key: 'TODAY', labelCode: 'CALENDAR_TITLE_TODAY' },
    { key: 'OTHER', labelCode: 'COMMON_LABEL_PERIOD' },
  ];
  if (props.isNothing) {
    return [
      ...filterbyDate,
      { key: 'NOTHING', labelCode: 'TASK_DETAIL_LABEL_NO_SCHEDULE' },
    ];
  }
  return filterbyDate;
});

const radioData = ref<any>();

const collapseDomain = (domain) => {
  domain.collapse = !domain.collapse;
};

const collapseProject = (project) => {
  project.collapse = !project.collapse;
};

onMounted(() => {});
</script>

<template>
  <VigDropdown
    :arrow="false"
    :placement="'bottom-end'"
    class="pr-2"
    @on-dropdown-open="onOpenDropdown"
    @on-dropdown-close="onOpenDropdown"
  >
    <template #dropdown-toggle>
      <div
        v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER')"
        class="w-6 h-6 flex-center relative rounded"
        :class="isOpenFilter ? 'bg-current-100' : ''"
      >
        <SynIcon
          name="filter2"
          class="cursor-pointer"
          :custom-class="`w-3.5 h-3.5 ${
            totalFilterItem > 0 ? 'fill-current-400' : 'fill-gray-400'
          }`"
        />
        <span
          v-if="totalFilterItem"
          class="
            h-3
            px-1
            rounded-full
            text-white
            bg-current
            absolute
            -top-1.5
            flex-center
          "
          style="font-size: 0.6rem; width: 0.8rem; right: -0.175rem"
        >
          {{ totalFilterItem }}
        </span>
      </div>
    </template>
    <template #dropdown-menu>
      <div v-if="type == 'date'" class="z-100 py-2 px-4">
        <div
          v-if="dataFilters && dataFilters?.filterType"
          class="
            list-li
            flex-center
            space-x-2
            dropdown-item
            underline
            hover:bg-current-50
            text-current-600
          "
          @click="onClearFilterCreator()"
        >
          <span>{{ $t('COMMON_LABEL_CLEAR_FILTER') || 'Clear filter' }}</span>
        </div>
        <div class="flex-center">
          <SynRadioTag
            v-for="(item, index) in filterByDateOptions"
            :key="index"
            v-model="filterDate.filterType"
            :value="item.key"
            :label="$t(item.labelCode)"
            name="task-create-form_schedule"
            radio-name="task-create-form_priority"
            class="m-0.5"
          >
            <SynTag
              size="circle-small"
              custom-class="py-1 px-2 border border-current-100"
            >
              <span>{{ $t(item.labelCode) }}</span>
            </SynTag>
          </SynRadioTag>
        </div>
        <div
          v-if="filterDate.filterType === 'OTHER'"
          class="flex-center space-x-2 w-80"
        >
          <AtomDatePicker
            v-model="filterDate.start"
            :title="$t('COMMON_LABEL_FROM_DATE') || 'From date'"
            :config="{ type: 'number' }"
            :max-date="filterDate.end"
          />
          <AtomDatePicker
            v-model="filterDate.end"
            :title="$t('COMMON_LABEL_TO_DATE') || 'To date'"
            :config="{ type: 'number' }"
            :min-date="filterDate.start"
          />
        </div>
      </div>
      <div v-else-if="type == 'radio'" class="z-100 py-2 px-4">
        <div
          v-if="
            dataFilters && dataFilters?.filter((o) => o.selected)?.length > 0
          "
          class="
            list-li
            flex-center
            space-x-2
            dropdown-item
            underline
            hover:bg-current-50
            text-current-600
          "
          @click="onClearFilterCreator()"
        >
          <span>{{ $t('COMMON_LABEL_CLEAR_FILTER') || 'Clear filter' }}</span>
        </div>
        <div class="flex-center">
          <SynRadioTag
            v-for="(item, index) in dataFilters"
            :key="index"
            v-model="radioData"
            :value="item.key"
            :label="$t(item.labelCode)"
            name="task-create-form_schedule"
            radio-name="task-create-form_priority"
            class="m-0.5"
          >
            <SynTag
              size="circle-small"
              custom-class="py-1 px-2 border border-current-100"
            >
              <span>{{ $t(item.labelCode) }}</span>
            </SynTag>
          </SynRadioTag>
        </div>
      </div>
      <div
        v-else
        class="
          z-100
          list-ul
          py-1
          rounded
          cursor-pointer
          text-xs
          small-scrollbar
        "
        style="max-height: 30rem"
        :style="type == 'domain' || type == 'project' ? 'min-width: 15rem' : ''"
      >
        <div
          v-if="
            dataFilters && dataFilters?.filter((o) => o.selected)?.length > 0
          "
          class="
            list-li
            flex-center
            space-x-2
            dropdown-item
            underline
            hover:bg-current-50
            text-current-600
          "
          style="overflow-x: unset !important"
          @click="onClearFilterCreator()"
        >
          <span>{{ $t('COMMON_LABEL_CLEAR_FILTER') || 'Clear filter' }}</span>
        </div>
        <div
          v-for="item in creatorFilters"
          :key="item"
          class="flex flex-col list-li"
          style="overflow-x: unset !important"
          @click.stop="
            type !== 'domain' && type !== 'project'
              ? onSelectCreator(item, type)
              : ''
          "
        >
          <div class="flex items-center space-x-2">
            <!--            <SynIcon-->
            <!--              class="cursor-pointer fill-current text-current"-->
            <!--              name="checkbox"-->
            <!--              custom-class="h-4 w-4"-->
            <!--              :is-active="item.selected"-->
            <!--              @click.stop="onSelectCreator(item, type)"-->
            <!--            />-->
            <!--            <SynIcon-->
            <!--              v-if="item.isIndeterminate"-->
            <!--              name="minus-line"-->
            <!--              custom-class="w-2.5 fill-white"-->
            <!--              @click.stop="onSelectCreator(item, type)"-->
            <!--            />-->

            <div
              class="h-4 w-4 flex-center rounded border border-current-300"
              :class="item.selected ? 'bg-current-300' : 'bg-white'"
              @click.stop="onSelectCreator(item, type)"
            >
              <SynIcon
                v-if="
                  (item.selected && type != 'domain' && type != 'project') ||
                  (item.projectId?.filter((e) => e.selectedId)?.length ==
                    item.projectId?.length &&
                    type == 'domain') ||
                  (item.subProjectId?.filter((e) => e.selectedId)?.length ==
                    item.subProjectId?.length &&
                    type == 'project')
                "
                name="check"
                custom-class="w-3 text-white"
              />
              <SynIcon
                v-if="
                  (item.projectId?.filter((e) => e.selectedId)?.length <
                    item.projectId?.length &&
                    type == 'domain') ||
                  (item.subProjectId?.filter((e) => e.selectedId)?.length <
                    item.subProjectId?.length &&
                    type == 'project')
                "
                name="minus-line"
                custom-class="w-2.5 fill-white"
              />
            </div>

            <template v-if="type == 'user'">
              <UserById v-if="item.id" :user-id="item.id" />
              <div v-else class="flex-center space-x-2">
                <span class="h-6 w-6 rounded-full bg-current-50"> </span>
                <span>Empty</span>
              </div>
            </template>
            <div
              v-if="type == 'domain'"
              class="w-full flex-1 flex items-center"
              :class="
                item.projectId?.length > 0 &&
                item.projectId?.some((e) => e.projectId !== null)
                  ? 'justify-between'
                  : 'justify-start'
              "
            >
              <DomainById :domain-id="item.id" @click="onDomainClick(item)" />
              <div
                v-if="
                  item.projectId?.length > 0 &&
                  item.projectId?.some((e) => e.projectId !== null)
                "
                class="rounded w-5 h-5 hover:bg-gray-100 flex-center"
                @click.stop="collapseDomain(item)"
              >
                <SynIcon
                  name="arrow-right"
                  :class="item.collapse ? 'rotate-90' : ''"
                  custom-class="w-4 h-4 fill-gray-500"
                />
              </div>
            </div>
            <div
              v-if="type == 'project'"
              class="w-full flex-1 flex items-center"
              :class="
                item.subProjectId?.length > 0 &&
                item.subProjectId?.some((e) => e.subProjectId !== null)
                  ? 'justify-between'
                  : 'justify-start'
              "
            >
              <ProjectById :project-id="item.id" />
              <span v-if="!item.id">{{ $t('DOMAIN_LABEL_HAVE_NO_SUB') }}</span>
              <div
                v-if="
                  item.subProjectId?.length > 0 &&
                  item.subProjectId?.some((e) => e.subProjectId !== null)
                "
                class="rounded w-5 h-5 hover:bg-gray-100 flex-center"
                @click.stop="collapseProject(item)"
              >
                <SynIcon
                  name="arrow-right"
                  :class="item.collapse ? 'rotate-90' : ''"
                  custom-class="w-4 h-4 fill-gray-500"
                />
              </div>
            </div>
            <div
              v-if="type == 'group'"
              class="flex-1 flex justify-start pl-1 items-center"
            >
              <GroupById v-if="item.id" :group-id="item.id" />
              <div v-else class="flex-center space-x-2">
                <span class="h-6 w-6 rounded-full bg-current-50"> </span>
                <span>{{ $t('FILTER_LABEL_WITHOUT_GROUP') }}</span>
              </div>
            </div>
            <div
              v-if="type == 'status'"
              class="flex flex-col justify-start items-center"
            >
              <span v-if="item.id == 'null'">
                {{ $t('FILTER_LABEL_WITHOUT_STATUS') }}
              </span>

              <TaskStateLabel v-else :state-key="item.id" />
            </div>
            <PriorityTask
              v-if="type == 'urgency'"
              size="mini"
              :urgency="item.id"
            />
            <TaskPlaningLabel v-if="type == 'taskPlan'" :task-plan="item?.id" />
          </div>

          <div
            v-if="
              type == 'domain' &&
              item.projectId?.length > 0 &&
              item.projectId?.some((e) => e.projectId !== null) &&
              item.collapse
            "
          >
            <div
              v-for="i in item.projectId"
              :key="i.projectId"
              class="flex flex-col pl-4"
              @click.stop="onSelectProject(item, i.projectId)"
            >
              <div class="flex items-center space-x-2 justify-start mt-2">
                <div
                  v-if="item.projectId?.length >= 2 || i.projectId"
                  class="h-4 w-4 flex-center rounded border border-current-300"
                  :class="i.selectedId ? 'bg-current-300' : 'bg-white'"
                >
                  <SynIcon
                    v-if="i.selectedId"
                    name="check"
                    custom-class="w-3 text-white"
                  />
                </div>
                <!--                <SynIcon-->
                <!--                  v-if="item.projectId?.length >= 2 || i.projectId"-->
                <!--                  class="cursor-pointer h-3.5 w-3.5 fill-current text-current"-->
                <!--                  name="checkbox"-->
                <!--                  :is-active="i.selectedId"-->
                <!--                />-->
                <span
                  v-if="!i.projectId && item.projectId?.length >= 2"
                  class="text-xs"
                  >{{ $t('DOMAIN_LABEL_HAVE_NO_SUB') }}</span
                >
                <ProjectById class="text-xs" :project-id="i.projectId" />
              </div>
            </div>
          </div>

          <div
            v-if="
              type == 'project' &&
              item.subProjectId?.length > 0 &&
              item.subProjectId?.some((e) => e.subProjectId !== null) &&
              item.collapse
            "
          >
            <div
              v-for="i in item.subProjectId"
              :key="i.subProjectId"
              class="flex flex-col pl-4"
              @click.stop="onSelectSubProject(item, i.subProjectId)"
            >
              <div class="flex items-center space-x-2 justify-start mt-2">
                <div
                  v-if="item.subProjectId?.length >= 2 || i.subProjectId"
                  class="h-4 w-4 flex-center rounded border border-current-300"
                  :class="i.selectedId ? 'bg-current-300' : 'bg-white'"
                >
                  <SynIcon
                    v-if="i.selectedId"
                    name="check"
                    custom-class="w-3 text-white"
                  />
                </div>
                <span
                  v-if="!i.subProjectId && item.subProjectId?.length >= 2"
                  class="text-xs"
                  >{{ $t('DOMAIN_LABEL_HAVE_NO_SUB') }}</span
                >
                <ProjectById class="text-xs" :project-id="i.subProjectId" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          p-2
          space-x-2
          flex
          justify-end
          items-center
          cursor-pointer
          text-xs
        "
      >
        <SynButton
          class="dropdown-item"
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="onCancel"
        />
        <SynButton class="dropdown-item" :label="'OK'" @click="onFilter" />
      </div>
    </template>
  </VigDropdown>
</template>
