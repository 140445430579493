<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import {
  repeatDayInMonthByKeys,
  repeatDayInWeekByKeys,
  repeatTypeByKeys,
} from '@/ui/modules/task/constants/constant-task-repeats';
import {
  DayOfWeek,
  ETaskMonthlySubRepeatTypeModel,
  ETaskRepeatType,
  ITaskReccurring,
  ITaskRepeat,
} from '@/application/interfaces/tasks/task-interfaces';
// import { countWeedOfMonth } from '@/ui/hooks/hook-date';
import TaskRepeatTemplate from '@/ui/modules/task/detail/TaskRepeatTemplate.vue';
import { TaskReccuringTemplateClass } from '@/domain/entities/task/TaskReccuringTemplateClass';
import taskService from '@/application/services/task/TaskService';
import plansStore from '@/store/plans';
import { EPlanState, IOrgCurrentPlan } from '@/application/types/plan.types';
import orgStore from '@/store/organization';
import dayjs from 'dayjs';
import { EOrganizationModel } from '@/application/types/organization/organization.type';
import { getUiByPlanState } from '@/application/constants/plan.const';
import { cumsum } from 'd3';

const props = withDefaults(
  defineProps<{
    taskRepeat: ITaskRepeat;
    triggerForUpdate?: 'auto' | 'click';
    startDate?: any;
    originTemplate?: any;
    editMode?: 'CREATE' | 'EDIT';
    isUpdateTemplate?: boolean;
    errorMessageStartDate?: string;
    errorMessageEndDate?: string;
  }>(),
  {
    triggerForUpdate: 'auto',
    startDate: new Date(),
    originTemplate: {},
    editMode: 'EDIT',
    errorMessageStartDate: '',
    errorMessageEndDate: '',
  }
);

const emit = defineEmits<{
  (e: 'onSave', data: ITaskReccurring): void;
  (e: 'updateData', data);
}>();

const _planStore = plansStore();
const _orgStore = orgStore();

const currentTaskTemplate = ref<TaskReccuringTemplateClass>(
  new TaskReccuringTemplateClass({
    ...props.originTemplate,
    parentId: props.originTemplate?.parentId,
    parentCode: props.originTemplate?.parentCode,
    parentName: props.originTemplate?.parentName,
    subTasks: props.originTemplate?.subTasks?.map((sub) => {
      return {
        id: sub?.id,
      };
    }),
    subTasksDetail: props.originTemplate?.subTasks,
  })
);

const orgModel = computed(() => _orgStore.organizationInfo.model);

const currentOrgPlan = computed<IOrgCurrentPlan>(
  () => _planStore.currentOrgPlan
);

const isLoading = ref(false);
const uiByState = computed(() => {
  return getUiByPlanState(
    currentOrgPlan.value.planId,
    currentOrgPlan.value.stateCode
  );
});

const recurringOptions = computed<{
  endDateBy: string;
  messageCode: string;
  maxDays: number;
}>(() => {
  return uiByState.value?.recurringOptions;
});
const isDisableInfiniteRepeat = computed<boolean>(() => {
  return !currentOrgPlan.value?.isLifeTime;
});

const maxEndDate = computed(() => {
  if (recurringOptions.value?.endDateBy == 'DATE_NUMBER')
    dayjs().add(recurringOptions.value?.maxDays, 'd').toDate();

  return dayjs(currentOrgPlan.value?.endDate).toDate();
});

const repeatEndKey = ref(2);
onMounted(async () => {
  if (props.isUpdateTemplate) {
    isShowFullSetup.value = false;
    isShowFull.value = true;
  } else {
    isShowFullSetup.value = true;
    isShowFull.value = false;
  }
  taskRepeatData.value = props.taskRepeat?.templateId
    ? ({
        ...props.taskRepeat,
        startDate: dayjs
          .utc(props.taskRepeat.startDate)
          .startOf('day')
          .toDate(),
        endDate: dayjs.utc(props.taskRepeat.endDate).startOf('day').toDate(),
      } as ITaskRepeat)
    : {
        repeatType: ETaskRepeatType.None,
        subRepeatType: {
          weeklyOptions: [0],
          monthlyOptions: ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth,
          byMonth: [],
          byMonthday: [],
        },
        startDate:
          props.startDate && props.startDate !== '0001-01-01T00:00:00'
            ? dayjs.utc(props.startDate).add(1, 'day').startOf('day').toDate()
            : dayjs.utc().add(1, 'day').startOf('day').toDate(),

        endDate: undefined,
        triggerOn: null,
      };

  if (taskRepeatData.value?.endDate || isDisableInfiniteRepeat.value)
    repeatEndKey.value = 2;

  specificDay.value = {
    day:
      taskRepeatData.value?.subRepeatType?.byMonthday?.length > 0
        ? taskRepeatData.value?.subRepeatType?.byMonthday[0]
        : new Date().getDate(),
    month:
      taskRepeatData.value?.subRepeatType?.byMonth?.length > 0
        ? taskRepeatData.value?.subRepeatType?.byMonth[0]
        : new Date().getMonth() + 1,
  };

  if (props.taskRepeat?.templateId) {
    isLoading.value = true;
    const res = await taskService
      .getInstance()
      .getRepeatTemplateInfo(props.taskRepeat?.templateId);

    currentTaskTemplate.value = {
      ...res?.result,
    };
    if (taskRepeatData.value?.repeatType == ETaskRepeatType.None)
      currentTaskTemplate.value = {
        ...currentTaskTemplate.value,
        parentId: props.originTemplate?.parentId,
        parentCode: props.originTemplate?.parentCode,
        parentName: props.originTemplate?.parentName,
        subTasks: props.originTemplate?.subTasks?.map((sub) => {
          return {
            id: sub?.id,
          };
        }),
        subTasksDetail: props.originTemplate?.subTasks,
      };

    isLoading.value = false;
  }

  console.log('🚀 Tictop ~ props.editMode:', {
    editMode: props.editMode,
    data: currentTaskTemplate.value,
  });
  updateData();
});

const updateData = () => {
  emit('updateData', {
    ...taskRepeatData.value,
    repeatEndKey: repeatEndKey.value,
  });
};

const repeatTypeKeyList = [
  ETaskRepeatType.None,
  ETaskRepeatType.Daily,
  ETaskRepeatType.Weekly,
  ETaskRepeatType.Monthly,
  ETaskRepeatType.Yearly,
];

const taskRepeatData = ref<ITaskRepeat>({
  repeatType: ETaskRepeatType.None,
  subRepeatType: {
    weeklyOptions: [0],
    monthlyOptions: ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth,
    byMonth: [],
    byMonthday: [],
  },
  startDate: new Date(),
  endDate: undefined,
  triggerOn: null,
});

const weekdayStartDate = computed<DayOfWeek>(
  () => new Date().getDay() as DayOfWeek
);
// const weekOfMonthStartDate = computed(() => {
//   const date = new Date(taskRepeatData.value.startDate || new Date());

//   const dayInWeekOfFirstDate = new Date(
//     date.getFullYear(),
//     date.getMonth(),
//     1
//   ).getDay();
//   let adjustedDate = date.getDate() + dayInWeekOfFirstDate;

//   return Math.ceil(adjustedDate / 7);
// });

const repeatDayInMonthKeys = computed(() => {
  return [
    ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth,
    ETaskMonthlySubRepeatTypeModel.FirstDayOfTheMonth,
    ETaskMonthlySubRepeatTypeModel.LastDayOfTheMonth,
  ];
});

const onSelectRepeatType = (type: ETaskRepeatType) => {
  taskRepeatData.value.repeatType = type;

  if (!taskRepeatData.value.startDate)
    taskRepeatData.value.startDate =
      props.startDate == '0001-01-01T00:00:00' || !props.startDate
        ? dayjs.utc().add(1, 'day').startOf('day').toDate()
        : dayjs.utc(props.startDate).add(1, 'day').startOf('day').toDate();

  switch (type) {
    case ETaskRepeatType.Weekly:
      {
        taskRepeatData.value.subRepeatType = {
          weeklyOptions: [weekdayStartDate.value],
        };
      }

      break;
    case ETaskRepeatType.None:
      taskRepeatData.value.startDate = undefined;
      taskRepeatData.value.subRepeatType = {};

      break;
    case ETaskRepeatType.Monthly:
      {
        taskRepeatData.value.subRepeatType.byMonthday = [1];

        taskRepeatData.value.subRepeatType = {
          monthlyOptions: ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth,
          byMonthday:
            specificDay.value?.day > 0 ? [specificDay.value?.day] : [1],
        };
      }

      break;
    case ETaskRepeatType.Yearly:
      {
        taskRepeatData.value.subRepeatType = {
          byMonth:
            specificDay.value?.month > 0 ? [specificDay.value?.month] : [1],
          byMonthday:
            specificDay.value?.day > 0 ? [specificDay.value?.day] : [1],
        };
      }

      break;

    default:
      taskRepeatData.value.subRepeatType = {};

      break;
  }

  onSaveNow();
  updateData();
};

const taskRepeatTemplateRef = ref<any>(null);
const onSave = () => {
  const template =
    taskRepeatTemplateRef.value?.getTaskTemplate() || currentTaskTemplate.value;

  emit('onSave', {
    repeat: {
      ...taskRepeatData.value,
      startDate: dayjs
        .utc(taskRepeatData.value?.startDate)
        .startOf('day')
        .isBefore(dayjs.utc().add(1, 'day').startOf('day'))
        ? new Date(dayjs.utc().add(1, 'day').startOf('day').toDate())
        : new Date(
            dayjs.utc(taskRepeatData.value?.startDate).startOf('day').toDate()
          ),
      endDate: taskRepeatData.value?.endDate
        ? new Date(taskRepeatData.value?.endDate)
        : taskRepeatData.value?.endDate,
    },
    template,
  });
};

const onSaveNow = () => {
  updateData();
  if (props.triggerForUpdate !== 'auto') return;
  onSave();
};

const onToggleDayInWeek = (day: DayOfWeek) => {
  const existed =
    taskRepeatData.value?.subRepeatType?.weeklyOptions?.includes(day);
  if (existed)
    taskRepeatData.value.subRepeatType.weeklyOptions =
      taskRepeatData.value?.subRepeatType.weeklyOptions?.filter(
        (d) => d !== day
      );
  else
    taskRepeatData.value.subRepeatType.weeklyOptions = [
      ...(taskRepeatData.value.subRepeatType.weeklyOptions || []),
      day,
    ];

  onSaveNow();
};
// const onSelectDayInMonth = (value) => {
//   taskRepeatData.value.subRepeatType.monthlyOptions = value;

//   onSaveNow();
// };

const isShowFull = ref(false);
const isShowFullSetup = ref(true);

const getSuffixOfDay = (day) => {
  return day == 1 ? 'st' : day == 2 ? 'nd' : day == 3 ? 'rd' : 'th';
};

const specificDay = ref<any>({
  day: 1,
  month: 1,
});

const chooseSpecificDay = (day) => {
  specificDay.value.day = day;
  taskRepeatData.value.subRepeatType.byMonthday = [day];

  onSaveNow();
};
const chooseSpecificMonth = (month) => {
  if (!month) return;

  specificDay.value.month = month;
  taskRepeatData.value.subRepeatType.byMonth = [month];

  if (specificDay.value.day > DATA_BY_MONTH_NUMBER[month].totalDays) {
    chooseSpecificDay(DATA_BY_MONTH_NUMBER[month].totalDays);
    return;
  }
  onSaveNow();
};
const onChangeMonthlyOptions = (key) => {
  switch (taskRepeatData.value.subRepeatType.monthlyOptions) {
    case ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth:
      taskRepeatData.value.subRepeatType = {
        monthlyOptions: ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth,
        byMonthday: specificDay.value?.day > 0 ? [specificDay.value?.day] : [1],
      };
      break;

    default:
      taskRepeatData.value.subRepeatType = {
        monthlyOptions: key,
      };

      break;
  }

  onSaveNow();
};

const DATA_BY_MONTH_NUMBER = {
  1: {
    key: 1,
    nameCode: 'LABEL_JANUARY',
    totalDays: 31,
  },
  2: {
    key: 2,
    nameCode: 'LABEL_FEBRUARY',
    totalDays: 29,
  },
  3: {
    key: 3,
    nameCode: 'LABEL_MARCH',
    totalDays: 31,
  },
  4: {
    key: 4,
    nameCode: 'LABEL_APRIL',
    totalDays: 30,
  },
  5: {
    key: 5,
    nameCode: 'LABEL_MAY',
    totalDays: 31,
  },
  6: {
    key: 6,
    nameCode: 'LABEL_JUNE',
    totalDays: 30,
  },
  7: {
    key: 7,
    nameCode: 'LABEL_JULY',
    totalDays: 31,
  },
  8: {
    key: 8,
    nameCode: 'LABEL_AUGUST',
    totalDays: 31,
  },
  9: {
    key: 9,
    nameCode: 'LABEL_SEPTEMBER',
    totalDays: 30,
  },
  10: {
    key: 10,
    nameCode: 'LABEL_OCTOBER',
    totalDays: 31,
  },
  11: {
    key: 11,
    nameCode: 'LABEL_NOVEMBER',
    totalDays: 30,
  },
  12: {
    key: 12,
    nameCode: 'LABEL_DECEMBER',
    totalDays: 31,
  },
};

const latestUpdateStartDateTime = ref<number>(Date.now());
const onChangeStartDate = (newDate) => {
  if (!newDate) {
    latestUpdateStartDateTime.value = Date.now();
    return;
  }

  taskRepeatData.value.startDate = newDate;
};

defineExpose({
  onSave,
});
</script>
<template>
  <div
    class="flex-1 min-h-0 w-full p-4 text-xs overflow-y-auto small-scrollbar"
    style="max-width: 100%; min-width: 40rem"
  >
    <!-- <div
      class="
        w-full
        flex
        items-center
        justify-between
        space-x-4
        text-xs
        px-2
        py-1
      "
    >
      <span class="font-semibold w-1/3">Start date</span>
      <span>
        {{ $filters.dayjs(repeatStartDate, 'DD/MM/YYYY') }}
      </span>
    </div> -->

    <!-- REPEAT DETAIL -->
    <div class="w-full px-2 flex flex-col space-y-3">
      <div
        class="
          w-max
          flex
          items-center
          space-x-2
          rounded
          cursor-pointer
          text-gray-600
          fill-gray-400
        "
        @click="isShowFullSetup = !isShowFullSetup"
      >
        <span class="text-sm">
          {{ $t('TASK_LABEL_SETUP_REPEAT') }}
        </span>
        <SynIcon
          :name="'sort-down'"
          custom-class="h-2.5 w-2.5"
          :class="!isShowFullSetup ? '-rotate-90' : ''"
        />
      </div>
      <div
        v-if="isShowFullSetup"
        class="
          border border-gray-300 border-dashed
          rounded
          w-full
          p-2
          flex flex-col
          space-y-2
        "
      >
        <!-- REPEAT TYPE -->
        <div class="w-full flex flex-col space-y-2 text-xs px-2 py-1">
          <span class="text-gray-500">
            {{ $t('COMMON_LABEL_FREQUENCY') || 'Frequency' }}
          </span>
          <span class="pl-4">
            <div class="flex-1 flex flex-wrap">
              <SynRadioTag
                v-for="repeatKey in repeatTypeKeyList"
                :key="repeatKey"
                v-model="taskRepeatData.repeatType"
                :value="repeatKey"
                :label="
                  $t(repeatTypeByKeys[repeatKey]?.nameCode) ||
                  repeatTypeByKeys[repeatKey]?.name
                "
                name="task-create-form_schedule"
                radio-name="task-create-form_priority"
                class="mr-1"
                @change="onSelectRepeatType"
              >
                <SynTag
                  size="circle-small"
                  custom-class="py-1 px-2 border border-current-100"
                >
                  <span>
                    {{
                      $t(repeatTypeByKeys[repeatKey]?.nameCode) ||
                      repeatTypeByKeys[repeatKey]?.name
                    }}
                  </span>
                </SynTag>
              </SynRadioTag>
            </div>
          </span>
        </div>
        <!-- OPTIONS FOR WEEKLY -->
        <div
          v-if="taskRepeatData.repeatType == ETaskRepeatType.Weekly"
          class="w-full flex flex-col space-y-2 text-xs px-2 py-1"
        >
          <span class="text-gray-500">
            {{ $t('TASK_LABEL_REPEAT_ON_THERE_DAYS') || 'On there days' }}
          </span>
          <span
            class="flex items-center justify-between space-x-1 pl-4"
            style="max-width: 20rem"
          >
            <span
              v-for="(day, index) in Object.keys(repeatDayInWeekByKeys)"
              :key="index"
              class="flex-center flex-col"
            >
              <SynIcon
                name="checkbox"
                class="fill-current cursor-pointer flex-none"
                :is-active="
                  taskRepeatData?.subRepeatType?.weeklyOptions?.includes(
                    parseInt(day) as DayOfWeek
                  )
                "
                @click="onToggleDayInWeek(parseInt(day) as DayOfWeek)"
              />
              <span>
                {{ $t(repeatDayInWeekByKeys[day]?.shortNameCode) }}
              </span>
            </span>
          </span>
        </div>
        <!-- OPTIONS FOR MONTHLY -->
        <div
          v-if="taskRepeatData.repeatType == ETaskRepeatType.Monthly"
          class="w-full flex flex-col space-y-2 text-xs px-2 py-1"
        >
          <span class="text-gray-500">
            {{ $t('TASK_LABEL_REPEAT_ON_THERE_DAYS') || 'On there days' }}
          </span>
          <span class="flex flex-col gap-2 pl-4">
            <div class="flex-1 flex flex-wrap">
              <SynRadioTag
                v-for="repeatDayInMonth in repeatDayInMonthKeys"
                :key="repeatDayInMonth"
                v-model="taskRepeatData.subRepeatType.monthlyOptions"
                :value="repeatDayInMonth"
                :label="
                  $t(repeatDayInMonthByKeys[repeatDayInMonth]?.nameCode) ||
                  repeatDayInMonthByKeys[repeatDayInMonth]?.name
                "
                name="task-create-form_schedule"
                radio-name="task-create-form_priority"
                class="mr-1"
                @change="onChangeMonthlyOptions"
              >
                <SynTag
                  size="circle-small"
                  custom-class="py-1 px-2 border border-current-100"
                >
                  <span>{{
                    $t(repeatDayInMonthByKeys[repeatDayInMonth]?.nameCode, {
                      day:
                        $t(
                          repeatDayInMonthByKeys[repeatDayInMonth]?.nameCode
                        ) ||
                        repeatDayInMonthByKeys[repeatDayInMonth]?.name ||
                        '',
                      dayNumber: specificDay.day,
                      suffix: getSuffixOfDay(specificDay.day),
                    }) || repeatDayInMonthByKeys[repeatDayInMonth]?.name
                  }}</span>
                </SynTag>
              </SynRadioTag>
            </div>

            <div class="w-full flex">
              <VigDropdown
                v-if="
                  taskRepeatData.subRepeatType.monthlyOptions ==
                  ETaskMonthlySubRepeatTypeModel.SpecificDayEachMonth
                "
                hide-on-click="outside"
                :arrow="false"
                placement="bottom-start"
              >
                <template #dropdown-toggle>
                  <div class="w-[4rem]">
                    <SynTag
                      size="circle-small"
                      custom-class="w-max py-1 px-2 border border-current-100 cursor-pointer bg-current text-white"
                    >
                      <span>
                        {{
                          $t('TASK_LABEL_REPEAT_SPECIFIC_DAY_OF_MONTH', {
                            dayNumber: specificDay.day || 1,
                            suffix: getSuffixOfDay(specificDay.day),
                          })
                        }}
                      </span>
                    </SynTag>
                  </div>
                </template>
                <template #dropdown-menu>
                  <div
                    class="
                      w-[16rem]
                      flex
                      items-center
                      flex-wrap
                      gap-2
                      p-4
                      dropdown-item
                    "
                  >
                    <span
                      v-for="dayNumber in 31"
                      :key="dayNumber"
                      :class="[
                        'rounded-full flex-center cursor-pointer text-sm',
                        dayNumber == specificDay.day
                          ? 'h-6 w-6 bg-current-500 text-white hover:bg-current-300'
                          : 'h-6 w-6 bg-gray-200 hover:bg-gray-100',
                      ]"
                      @click="chooseSpecificDay(dayNumber)"
                    >
                      {{ dayNumber }}
                    </span>
                  </div>
                </template>
              </VigDropdown>
            </div>
          </span>
        </div>
        <!-- OPTIONS FOR YEARLY -->
        <div
          v-if="taskRepeatData.repeatType == ETaskRepeatType.Yearly"
          class="w-full flex flex-col space-y-2 text-xs px-2 py-1"
        >
          <div class="w-full flex pl-4">
            <VigDropdown
              hide-on-click="outside"
              :arrow="false"
              placement="bottom-start"
            >
              <template #dropdown-toggle>
                <SynTag
                  size="circle-small"
                  custom-class="w-max py-1 px-2 space-x-1 border border-current-100 cursor-pointer bg-current text-white"
                >
                  <span>
                    {{
                      $t(
                        specificDay.month > 0
                          ? DATA_BY_MONTH_NUMBER[specificDay.month].nameCode
                          : ''
                      )
                    }}
                  </span>
                  <span>-</span>
                  <span>
                    {{
                      $t('TASK_LABEL_REPEAT_SPECIFIC_DAY', {
                        dayNumber: specificDay.day || 1,
                        suffix: getSuffixOfDay(specificDay.day),
                      })
                    }}
                  </span>
                </SynTag>
              </template>
              <template #dropdown-menu>
                <div class="flex flex-col gap-2 w-[34rem] p-4">
                  <span class="text-xs font-semibold">
                    {{ $t('COMMON_TIMEKEEPING_LABEL_MONTH') }}
                  </span>
                  <div class="flex items-center flex-wrap gap-2">
                    <span
                      v-for="monthNumber in 12"
                      :key="monthNumber"
                      :class="[
                        'rounded-full flex-center cursor-pointer text-sm w-max px-2',
                        monthNumber == specificDay.month
                          ? 'h-6 bg-current-500 text-white hover:bg-current-300'
                          : 'h-6 bg-gray-200 hover:bg-gray-100',
                      ]"
                      @click="chooseSpecificMonth(monthNumber)"
                    >
                      {{
                        $t(DATA_BY_MONTH_NUMBER[monthNumber].nameCode) ||
                        monthNumber
                      }}
                    </span>
                  </div>
                  <template v-if="specificDay.month > 0">
                    <span class="text-xs font-semibold">
                      {{ $t('CALENDAR_TITLE_DAY') }}
                    </span>
                    <div class="flex items-center flex-wrap gap-2">
                      <span
                        v-for="dayNumber in DATA_BY_MONTH_NUMBER[
                          specificDay.month
                        ].totalDays"
                        :key="dayNumber"
                        :class="[
                          'rounded-full flex-center cursor-pointer text-sm',
                          dayNumber == specificDay.day
                            ? 'h-6 w-6 bg-current-500 text-white hover:bg-current-300'
                            : 'h-6 w-6 bg-gray-200 hover:bg-gray-100',
                        ]"
                        @click="chooseSpecificDay(dayNumber)"
                      >
                        {{ dayNumber }}
                      </span>
                    </div>
                  </template>
                </div>
              </template>
            </VigDropdown>
          </div>
        </div>

        <!-- START DATE -->
        <div
          v-if="
            taskRepeatData.repeatType &&
            taskRepeatData.repeatType !== ETaskRepeatType.None
          "
          class="w-full flex flex-col space-y-2 text-xs px-2 py-1"
        >
          <span class="text-gray-500">
            {{ $t('PAYMENT_START_DATE') }}
          </span>
          <div class="pl-4 flex flex-col">
            <span class="w-48">
              <AtomDatePicker
                :key="latestUpdateStartDateTime"
                :model-value="taskRepeatData.startDate"
                is-required
                :config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                :min-date="dayjs.utc().add(1, 'day').startOf('day').toDate()"
                :max-date="
                  taskRepeatData.endDate
                    ? new Date(taskRepeatData.endDate).setDate(
                        new Date(taskRepeatData.endDate).getDate() - 1
                      )
                    : null
                "
                @update:model-value="onChangeStartDate"
              />
            </span>
            <span
              v-if="errorMessageStartDate"
              class="mt-1 text-red-500 text-xs italic"
              >*{{ $t(errorMessageStartDate) }}</span
            >
          </div>
        </div>

        <!-- END DATE -->
        <div
          v-if="
            taskRepeatData.repeatType &&
            taskRepeatData.repeatType !== ETaskRepeatType.None
          "
          class="w-full flex flex-col space-y-2 text-xs px-2 py-1"
        >
          <span class="text-gray-500 w-1/3">
            {{ $t('COMMON_LABEL_ENDS') || 'Ends' }}
          </span>
          <div class="flex items-center pl-4 space-x-3">
            <SynRadio
              v-model="repeatEndKey"
              :value="1"
              :is-disabled="isDisableInfiniteRepeat"
              :label="$t('TASK_LABEL_REPEAT_NEVER') || 'Never'"
              class="text-xs w-24 justify-start text-gray-500"
              @change="
                taskRepeatData.endDate = undefined;
                updateData();
              "
            />
            <VigDropdown v-if="isDisableInfiniteRepeat" placement="top">
              <template #dropdown-toggle>
                <div class="flex-center">
                  <SynIcon
                    name="question"
                    custom-class="h-4 w-4 hover:fill-current cursor-pointer"
                  />
                </div>
              </template>
              <template #dropdown-menu>
                <div class="p-4 bg-gray-50 rounded-lg w-96 text-sm">
                  <span>{{
                    $t('COMMON_LABEL_HELP_COMPANY_TEAM_DISABLE_INFINITE_REPEAT')
                  }}</span>
                </div>
              </template>
            </VigDropdown>
          </div>
          <div class="flex items-center pl-4 space-x-3">
            <SynRadio
              v-model="repeatEndKey"
              :value="2"
              :label="$t('TASK_LABEL_REPEAT_ON') || 'On'"
              class="text-xs w-24 justify-start text-gray-500"
              @change="updateData()"
            />
            <VigDropdown placement="top">
              <template #dropdown-toggle>
                <div class="flex-center">
                  <SynIcon
                    name="question"
                    custom-class="h-4 w-4 hover:fill-current cursor-pointer"
                  />
                </div>
              </template>
              <template #dropdown-menu>
                <div class="p-4 bg-gray-50 rounded-lg w-96 text-sm">
                  <div class="flex justify-between items-center pb-3">
                    <span class="font-semibold text-current">
                      {{ $t('COMMON_LABEL_SETTING_REPEAT_TITLE') }}
                    </span>
                  </div>
                  <ul class="pl-4" style="list-style: circle">
                    <li v-if="isDisableInfiniteRepeat" class="py-2">
                      {{
                        $t(recurringOptions?.messageCode, {
                          planId: currentOrgPlan?.planId,
                          maxDays: recurringOptions?.maxDays,
                        })
                      }}
                    </li>
                    <li class="py-2">
                      {{ $t('COMMON_LABEL_HELP_COMPANY_TEAM_MAX_DATE_REPEAT') }}
                    </li>
                  </ul>
                </div>
              </template>
            </VigDropdown>
            <div class="flex flex-col">
              <span class="w-48">
                <AtomDatePicker
                  v-model="taskRepeatData.endDate"
                  :config="{ type: 'string' }"
                  :is-disabled="repeatEndKey !== 2"
                  :min-date="
                    taskRepeatData.startDate
                      ? new Date(taskRepeatData.startDate).setDate(
                          new Date(taskRepeatData.startDate).getDate() + 1
                        )
                      : null
                  "
                  :max-date="maxEndDate"
                  @update:model-value="onSaveNow"
                />
              </span>
              <span
                v-if="errorMessageEndDate"
                class="mt-1 text-red-500 text-xs italic"
                >*{{ $t(errorMessageEndDate) }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TASK REPEAT TEMPLATE -->
    <div
      v-if="
        editMode == 'EDIT' &&
        taskRepeatData.repeatType &&
        taskRepeatData.repeatType !== ETaskRepeatType.None
      "
      class="w-full px-2 py-4 flex flex-col space-y-3"
    >
      <div
        class="
          w-max
          flex
          items-center
          space-x-2
          rounded
          cursor-pointer
          text-gray-600
          fill-gray-400
        "
        @click="isShowFull = !isShowFull"
      >
        <span class="text-sm">
          {{ $t('TASK_LABEL_REPEAT_TEMPLATE') }}
        </span>
        <SynIcon
          :name="'sort-down'"
          custom-class="h-2.5 w-2.5"
          :class="!isShowFull ? '-rotate-90' : ''"
        />
      </div>
      <div
        v-if="isShowFull"
        class="
          border border-gray-300 border-dashed
          rounded
          w-full
          pb-4
          relative
        "
      >
        <SynPageLoading v-if="isLoading" />
        <TaskRepeatTemplate
          v-else
          ref="taskRepeatTemplateRef"
          :origin-template="currentTaskTemplate"
        />
      </div>
    </div>
  </div>
</template>
