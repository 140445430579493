<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { useResizeObserver } from '@vueuse/core';
import PerfectScrollbar from '@/ui/plugins/scrollbar/perfect-scrollbar';
import { ITaskTodoItemStatus } from '@/application/types/task/task.types';
import AtomChecklistInput from '@/ui/common/atoms/checklist/AtomChecklistInput.vue';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  modelValue: any[];
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: any[]): void;
  (e: 'remove-item', removedItem: any, isAbleRollback: boolean): void;
  (e: 'add', newItem: any): void;
  (e: 'blur-an-item'): void;
}>();

const TODO_LIST_ITEM_PREFIX = 'todo-list-item_';

const todoListPayload = ref<any[]>(
  props.modelValue || [
    {
      id: uuidv4(),
      title: '',
      shortDescription: '',
      description: '',
      status: ITaskTodoItemStatus.PENDING,
      index: 0,
    },
  ]
);

const initComponent = () => {
  todoListPayload.value = props.modelValue || [
    {
      id: uuidv4(),
      title: '',
      shortDescription: '',
      description: '',
      status: ITaskTodoItemStatus.PENDING,
      index: 0,
    },
  ];
};

const stopBubbleDragEvent = () => {
  const divEl = document.getElementById('atom-checklist');
  if (!divEl) return;
  divEl.addEventListener('dragenter', (event) => {
    event.stopPropagation();
  });
};

onMounted(() => {
  // setTimeout(() => {
  //   const firstItem =
  //     todoListPayload.value?.todoItems?.length > 0
  //       ? todoListPayload.value?.todoItems[0]
  //       : null;

  //   if (firstItem?.id)
  //     document
  //       .getElementById(`${TODO_LIST_ITEM_PREFIX}${firstItem?.id}`)
  //       ?.focus();
  // });

  stopBubbleDragEvent();
});

// const updateItemIndex = () => {
//   todoListPayload.value.forEach((element, index) => {
//     element.index = index;
//   });

//   updateTodoList();
// };

const updateItemIndex = (indexByIds?) => {
  if (todoListPayload.value?.length == 0) return;

  todoListPayload.value.forEach((element, index) => {
    const stepIndex = indexByIds ? indexByIds[element?.id] - 1 : index;
    element.index = stepIndex;
  });

  todoListPayload.value =
    arrayOrderBy(todoListPayload.value, ['index'], ['asc']) || [];

  updateTodoList();
};

const addNewItemInputRef = ref<any>(null);
const atomChecklistItemRefByIds = ref<any>({});

const setItemRef = (id, el) => {
  if (id && el) {
    atomChecklistItemRefByIds.value[id] = el;
  }
};
const addNewItem = (index, value, disableAutoFocus) => {
  if (index >= todoListPayload.value?.length - 1 && !value) {
    if (
      addNewItemInputRef.value &&
      typeof addNewItemInputRef.value?.focusToEnd == 'function'
    )
      addNewItemInputRef.value?.focusToEnd();

    return;
  }

  isFirstTime.value = true;

  const newItemId = uuidv4();

  const newItem = {
    id: newItemId,
    title: value || '',
    shortDescription: '',
    description: '',
    status: ITaskTodoItemStatus.PENDING,
    index: todoListPayload.value?.length,
  };
  todoListPayload.value.splice(index + 1, 0, newItem);

  setTimeout(() => {
    if (ps && typeof ps?.scrollToBottom == 'function') ps?.scrollToBottom();
    const newRef = atomChecklistItemRefByIds.value[newItemId];

    if (!newRef || disableAutoFocus) return;

    if (newRef && typeof newRef?.focusToStart == 'function')
      newRef.focusToStart();
  });

  updateItemIndex();
  // updateTodoList();

  emit('add', newItem);
};
const removeItem = (itemId, index) => {
  onFocusTo(Math.max(0, index - 1));

  const removedItem = todoListPayload.value?.find((o) => o.id == itemId);

  todoListPayload.value = todoListPayload.value?.filter(
    (item) => item?.id !== itemId
  );

  updateItemIndex();

  emit('remove-item', removedItem, true);
};

const onFocusTo = (index) => {
  if (index >= todoListPayload.value?.length) {
    if (
      addNewItemInputRef.value &&
      typeof addNewItemInputRef.value?.focusToEnd == 'function'
    )
      addNewItemInputRef.value.focusToEnd();

    return;
  }

  const newRef =
    atomChecklistItemRefByIds.value[todoListPayload.value[index]?.id];

  if (!newRef) return;
  if (newRef && typeof newRef?.focusToEnd == 'function') newRef.focusToEnd();
};

const onChangeStatus = (item) => {
  item.status =
    item.status === ITaskTodoItemStatus.FINISHED
      ? ITaskTodoItemStatus.PENDING
      : ITaskTodoItemStatus.FINISHED;

  updateTodoList();
};

const updateTodoList = () => {
  emit('update:modelValue', todoListPayload.value);
};

const updateChecklistItem = (item, value) => {
  const index = todoListPayload.value?.findIndex((o) => o.id == item?.id);
  if (index == -1) return;

  todoListPayload.value[index].title = value;
  updateTodoList();
};

const onChangeDrag = (event) => {
  const childrenList = event?.target?.children;
  if (!childrenList || childrenList?.length == 0) return;

  let indexByIds = {};

  for (let index = 0; index < childrenList.length; index++) {
    const stepId = childrenList[index]?.id;
    indexByIds[stepId] = index;
  }

  updateItemIndex(indexByIds);
};

const onAddNew = (index, value) => {
  console.log('🚀 Tictop ~ index, value:', index, value);
  if (index >= todoListPayload.value?.length - 1 && !value) {
    return;
  }

  addNewItem(index, value, true);

  newItemTitle.value = '';
};

const deleteCurrentItem = (index, oldValue) => {
  if (index <= 0) return;

  const previousItem = todoListPayload.value[index - 1];
  // const oldEndPosition = previousItem.title?.length || 0;

  // todoListPayload.value[index - 1].title += oldValue;

  const removedItem = todoListPayload.value[index];
  emit('remove-item', removedItem, false);

  todoListPayload.value.splice(index, 1);

  setTimeout(() => {
    const newRef = atomChecklistItemRefByIds.value[previousItem?.id];

    if (!newRef) return;

    if (newRef && typeof newRef?.focusToEnd == 'function')
      newRef.focusToEnd(oldValue);
  });

  updateItemIndex();
};
const deleteAfter = (index) => {
  if (index >= todoListPayload.value?.length) return;

  deleteCurrentItem(index + 1, todoListPayload.value[index + 1]?.title || '');
};

const onBack = (index) => {
  onFocusTo(index - 1);
};

const onNext = (index) => {
  onFocusTo(index + 1);
};

const newItemTitle = ref<string>('');

const onBlurAddNew = () => {
  if (!newItemTitle.value) return;
  addNewItem(todoListPayload.value?.length || 0, newItemTitle.value, true);
  newItemTitle.value = '';
};

const focusOnItem = (id) => {
  if (!id) return;
  setTimeout(() => {
    const newRef = atomChecklistItemRefByIds.value[id];

    if (!newRef) return;

    if (newRef && typeof newRef?.focusToEnd == 'function') newRef.focusToEnd();
  }, 100);
};

const onCopyTitle = (title) => {
  if (!title) return;
  const node = document.createElement('div');

  node.innerHTML = title;
  // htmlContent = "Some <span class="foo">sample</span> text."

  const textContent = node.textContent;
  // textContent = "Some sample text."
  copyToClipBroard(textContent);
  openNotification({
    body: translate('CHECK_LIST_TITLE_COPIED_MSG'),
    duration: 2000,
  });
};

const isStopInputInNew = ref<boolean>(false);
// let inputTimeout;
const isFirstTime = ref<boolean>(true);
const onChangeNewItem = () => {
  if (isFirstTime.value) {
    isFirstTime.value = false;
    isStopInputInNew.value = false;
    return;
  }
  isStopInputInNew.value = true;
};

const taskTodoListListenScrollRef = ref<any>();

let ps;
useResizeObserver(taskTodoListListenScrollRef, (entries) => {
  const entry = entries[0];
  // const { width, height } = entry.contentRect
  // text.value = `width: ${width}, height: ${height}`
  const hasVerticalScrollbar =
    entry?.target?.scrollHeight - 2 > entry?.target?.clientHeight;

  if (hasVerticalScrollbar) {
    if (ps) ps?.update();
    else
      ps = new PerfectScrollbar(taskTodoListListenScrollRef.value, {
        useBothWheelAxes: true,
        wheelPropagation: true,
      });
  } else if (ps && typeof ps?.destroy == 'function') ps?.destroy();
});

defineExpose({
  initComponent,
  focusOnItem,
});
</script>
<template>
  <section
    id="atom-checklist"
    class="flex-1 min-h-0 w-full h-full flex flex-col overflow-hidden"
  >
    <!-- ITEM -->
    <div
      ref="taskTodoListListenScrollRef"
      class="flex-1 min-h-0 overflow-hidden relative w-full flex flex-col"
    >
      <SortAble
        :disabled="readonly"
        :options="{
          onMove: true,
        }"
        @on-end-sort="onChangeDrag"
      >
        <template #list-item>
          <AtomChecklistInput
            v-for="(item, index) in todoListPayload"
            :id="item?.id"
            :key="item?.id"
            :ref="(el) => setItemRef(item?.id, el)"
            :title="item.title"
            :data-item="item"
            :textarea-id="`${TODO_LIST_ITEM_PREFIX}${item?.id}`"
            :readonly="readonly"
            can-copy
            @update:title="(value) => updateChecklistItem(item, value)"
            @on-change-status="onChangeStatus(item)"
            @add="(value) => addNewItem(index, value, false)"
            @remove="removeItem(item?.id, index)"
            @back="onBack(index)"
            @next="onNext(index)"
            @delete="(value) => deleteCurrentItem(index, value)"
            @delete-after="deleteAfter(index)"
            @copy="onCopyTitle(item.title)"
            @blur="$emit('blur-an-item')"
          />
        </template>
      </SortAble>
    </div>

    <!-- FOOTER -->
    <!-- <SynIcon name="plus" custom-class="w-3 h-3" /> -->
    <AtomChecklistInput
      v-if="!readonly"
      ref="addNewItemInputRef"
      v-model:title="newItemTitle"
      :textarea-id="`${TODO_LIST_ITEM_PREFIX}_NEW`"
      edit-title-only
      :placeholder="$t('TASK_TODO_LIST_NEW_ITEM')"
      :readonly="readonly"
      @add="onAddNew(todoListPayload?.length || 0, newItemTitle)"
      @delete="
        (value) => {
          deleteCurrentItem(todoListPayload?.length, value);
          newItemTitle = '';
        }
      "
      @back="onBack(todoListPayload?.length)"
      @blur="onBlurAddNew"
      @update:title="onChangeNewItem"
    >
      <template v-if="!newItemTitle || !isStopInputInNew" #prefix-icon>
        <div class="h-10 w-10 flex-center group">
          <SynIcon name="plus" custom-class="w-3 h-3" />
        </div>
      </template>
    </AtomChecklistInput>
    <AtomChecklistInput v-if="newItemTitle && isStopInputInNew">
      <template #prefix-icon>
        <div class="h-10 w-10 flex-center group">
          <SynIcon name="plus" custom-class="w-3 h-3" />
        </div>
      </template>
    </AtomChecklistInput>
  </section>
</template>
<style lang="scss">
@import '@/ui/plugins/ckeditor/css/content-styles.scss';
</style>
