<template>
  <svg
    v-if="!isActive"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 503.889 503.889"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m453.727 114.266h-108.576v-43.751c0-20.832-16.948-37.779-37.78-37.779h-110.854c-20.832 0-37.78 16.947-37.78 37.779v43.751h-108.575c-27.66 0-50.162 22.503-50.162 50.162v256.563c0 27.659 22.502 50.161 50.162 50.161h403.565c27.659 0 50.162-22.502 50.162-50.161v-256.563c0-27.659-22.503-50.162-50.162-50.162zm-262.99-43.751c0-3.187 2.593-5.779 5.78-5.779h110.854c3.187 0 5.78 2.593 5.78 5.779v43.751h-122.414zm-158.737 93.913c0-10.015 8.147-18.162 18.162-18.162h403.565c10.014 0 18.162 8.147 18.162 18.162v23.681c0 22.212-14.894 42.061-36.22 48.27l-167.345 48.723c-10.715 3.121-22.043 3.121-32.76 0l-167.344-48.724c-21.326-6.209-36.22-26.057-36.22-48.269zm421.727 274.725h-403.565c-10.014 0-18.162-8.147-18.162-18.161v-167.734c8.063 6.232 17.254 10.927 27.274 13.845 184.859 53.822 175.358 52.341 192.67 52.341 17.541 0 7.595 1.544 192.67-52.341 10.021-2.918 19.212-7.613 27.274-13.845v167.733c.001 10.014-8.147 18.162-18.161 18.162z"
      ></path>
    </g>
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 504.131 504.131"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m453.844 114.387h-108.576v-43.751c0-20.832-16.948-37.779-37.78-37.779h-110.854c-20.832 0-37.78 16.947-37.78 37.779v43.751h-108.576c-27.659 0-50.162 22.503-50.162 50.162 0 25.124-2.573 47.687 16.466 73.061 10.772 14.356 25.575 24.597 42.808 29.614l167.344 48.723c16.567 4.823 34.082 4.825 50.652 0l167.344-48.723c17.233-5.018 32.036-15.258 42.808-29.614 19.125-25.488 16.466-48.283 16.466-73.061.001-27.659-22.501-50.162-50.16-50.162zm-262.99-43.751c0-3.187 2.593-5.779 5.78-5.779h110.854c3.187 0 5.78 2.593 5.78 5.779v43.751h-122.414z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m453.677 297.948c-180.501 52.554-178.164 53.617-201.616 53.617-23.284 0-17.76-.086-201.615-53.616-19.385-5.645-36.553-16.049-50.329-30.392v153.556c0 27.659 22.502 50.161 50.162 50.161h403.565c27.659 0 50.162-22.502 50.162-50.161v-153.556c-13.777 14.342-30.945 24.747-50.329 30.391z"
      ></path>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
