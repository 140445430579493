export const initializeTwemoji = () => {
    const twemojiScript = document.createElement('SCRIPT');
    twemojiScript.setAttribute(
        'src',
        '/js/plugins/twemoji-14.0.2/twemoji.min.js'
    );
    twemojiScript.setAttribute('crossorigin', 'anonymous');

    document.body.appendChild(twemojiScript);
};
