<script setup lang="ts">
import { ref, watch } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import VerticalBarChart from '@/ui/common/plugins/chart/VerticalBarChart.vue';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    size: number;
  };
  isHiddenLegend?: boolean;
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    domainId: number;
    domainName: string;
    overdueTasks: number;
    unscheduledTasks: number;
  }[]
>([]);

const initComponent = async () => {
  isLoading.value = true;
  const res = await WorkPerformanceRepository.getDomainOverdueTaskStatistic({
    ...props.filterData,
  });
  resultData.value = res?.result;
  prepareDataForChart();
  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.size == old.size
    )
      return;

    initComponent();
  }
);

const datasets = ref<any>([]);
const labels = ref<string[]>([]);
const prepareDataForChart = () => {
  labels.value = resultData.value?.map(
    (data) => data?.domainName || 'Domain name'
  );

  datasets.value = [
    {
      label: 'COMMON_LABEL_OVERDUE',
      data: resultData.value?.map((data) => data?.overdueTasks),
      backgroundColor: '#fca5a5',
      borderColor: '#fca5a5',
      hoverBackgroundColor: '#f87171',
      minBarLength: 2,
    },
    {
      label: 'COMMON_LABEL_UNSCHEDULED',
      data: resultData.value?.map((data) => data?.unscheduledTasks),
      backgroundColor: '#9ca3af',
      borderColor: '#9ca3af',
      hoverBackgroundColor: '#6b7280',
      minBarLength: 2,
    },
  ];
};
</script>
<template>
  <AtomCardLoading v-if="isLoading" />

  <VerticalBarChart v-else :labels="labels" :datasets="datasets" />
</template>
