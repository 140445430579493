<script setup lang="ts">
import { computed, ref } from 'vue';
// import { GroupMemberRole } from '@/ui/common/constants/constant';
import groupStore from '@/store/group';
import FormUpdateGroupForMember from '@/ui/modules/member/detail/FormUpdateGroupForMember.vue';
import userStore from '@/store/user';

const props = defineProps<{
  userId: number;
  groupMembers: any[];
}>();

const emit = defineEmits(['onCancel', 'onUpdateSuccess']);
const _groupStore = groupStore();
const _userStore = userStore();

const allUserByIds = computed<any>(() => {
  return _userStore.allUserByIds;
});
const currentUser = computed<any>(() => {
  return allUserByIds.value[props.userId];
});

const isSave = ref(false);
const groupSelect = ref([]);
const isCheckChange = ref(false);

const onCancel = () => {
  isCheckChange.value = false;

  emit('onCancel');
};

const onEventChange = (value) => {
  isCheckChange.value = true;
  groupSelect.value = value;
};

const onSave = async () => {
  isCheckChange.value = false;
  isSave.value = true;
  const groups = groupSelect.value?.map((o: any) => ({
    role: o.role,
    groupId: o.id,
  }));

  const payload = {
    userId: props.userId,
    groups: groups,
  };
  const res = ref(null) as any;
  res.value = await _groupStore.addOrUpdateGroupToUser(payload);
  if (res.value?.error) {
    console.log(res.value?.error);
    onCancel();
  } else {
    emit('onUpdateSuccess', true);
    onCancel();
  }
  isSave.value = false;
};
</script>

<template>
  <div>
    <SynModal
      z-index="z-50"
      container-class="md:w-1/4"
      container-style="min-width: 44rem;max-height: 48rem"
      disable-click-outside
      @cancel="onCancel"
    >
      <template #header>
        <div class="w-full flex items-center text-base space-x-2">
          <span class="w-max">
            {{ $t('INTRODUCE_LABEL_SETTING_GROUP') + ':' }}
          </span>
          <span class="text-current">{{
            currentUser?.fullName ||
            `${currentUser?.firstName} ${currentUser.lastName}` ||
            ''
          }}</span>
        </div>
      </template>
      <template #body>
        <FormUpdateGroupForMember
          :user-id="userId"
          :group-members="groupMembers"
          @event-change="onEventChange"
        />
      </template>
      <template #footer>
        <div class="pr-4 flex py-4 justify-end space-x-2">
          <SynButton
            color="gray"
            type-text
            :label="$t('COMMON_LABEL_CANCEL')"
            :class="isSave ? 'cursor-not-allowed' : ''"
            @click="onCancel"
          />
          <SynButton
            :is-loading="isSave"
            :label="$t('COMMON_LABEL_SAVE')"
            :disabled="!isCheckChange"
            @click="isCheckChange ? onSave() : ''"
          />
        </div>
      </template>
    </SynModal>
  </div>
</template>
