<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import {
  IReportCard,
  EReportCardType,
} from '@/application/types/report/report.types';

const props = defineProps<{
  reportCard: IReportCard;
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
  };
  size?: string;
}>();
const resultData = ref<any>({
  totalTasks: 0,
  completedTasks: 0,
  incompleteTasks: 0,
  overdueTasks: 0,
});

const showData = computed<number>(() => {
  if (props.reportCard?.type == EReportCardType.TOTAL_TASKS)
    return resultData.value?.totalTasks;
  if (props.reportCard?.type == EReportCardType.COMPLETED_TASKS)
    return resultData.value?.completedTasks;
  if (props.reportCard?.type == EReportCardType.INCOMPLETE_TASKS)
    return resultData.value?.incompleteTasks;
  if (props.reportCard?.type == EReportCardType.OVERDUE_TASKS)
    return resultData.value?.overdueTasks;

  return 0;
});

const isLoading = ref<boolean>(true);
const initComponent = async () => {
  const res = await WorkPerformanceRepository.getStatisticByStatus(
    props.filterData
  );
  resultData.value = res?.result;
  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  () => {
    initComponent();
  }
);
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <div
    v-else
    class="flex-1 w-full h-full flex-center"
    :class="size == 'lg' ? 'pb-12' : ''"
  >
    <span
      :style="{
        fontSize: size == 'lg' ? '6rem' : '3rem',
      }"
    >
      {{ $filters.numberToString(showData || 0) }}
    </span>
  </div>
</template>
