<template>
  <div class="flex items-center">
    <SynIcon
      v-if="!isEdit"
      has-action
      name="edit"
      is-active
      @click="($event) => $emit('on-edit', $event)"
    />
    <div v-else class="text-xs flex space-x-2 mr-4">
      <syn-button
        v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
        type-text
        color="gray"
        :disabled="isLoadingSave"
        :label="$t('COMMON_LABEL_CANCEL')"
        @click="($event) => $emit('on-cancel', $event)"
      >
      </syn-button>
      <syn-button
        v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
        :is-loading="isLoadingSave"
        :disabled="isLoadingSave || ready"
        :label="$t('COMMON_LABEL_SAVE')"
        @click="onSave()"
      >
      </syn-button>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue';
export default {
  props: {
    isSave: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isReady: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-edit', 'on-cancel', 'on-save'],
  setup(props, { emit }) {
    const isLoadingSave = ref(false);
    const ready = ref(false);
    watch(
      () => props.isReady,
      () => {
        ready.value = props.isReady;
      }
    );
    const onEdit = () => {
      emit('on-edit');
    };
    const onCancel = () => {
      emit('on-cancel');
    };

    const onSave = () => {
      emit('on-save');
    };

    return {
      isLoadingSave,
      ready,
      onEdit,
      onCancel,
      onSave,
    };
  },
};
</script>
