<script setup lang="ts">
import { computed, ref } from 'vue';
import { isCreateGroup } from '@/ui/app-global-state';
import userStore from '@/store/user';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import router from '@/ui/router';
import SynSearch from '@/ui/common/atoms/SynInput/SynSearch.vue';

// const emits = defineEmits(['onChangeSearch', 'onAdd', 'onOrderBy'])

// const _systemStore = system();
const _userStore = userStore();
const _overviewGroupStore = overviewGroupStore();

const isVisitor = computed(() => _userStore.isVisitor);
const lengthGroups = computed(
  () => _overviewGroupStore.overviewGroupIds?.length || 0
);
const orderByKey = computed(() => _overviewGroupStore.orderByKey);
const isHiddenTask = computed(() => _overviewGroupStore.isHiddenTask);

const searchText = ref(null);
// const isHiddenTask = ref(false);

// isHiddenTask.value = _systemStore.getSystemSettingDashboardGroups() === 'true';
searchText.value = _overviewGroupStore.keySearchGroup;

const onToggleHiddenTask = () => {
  // isHiddenTask.value = !isHiddenTask.value;
  _overviewGroupStore.onToggleHiddenTask();
};

const resetKeyword = () => {
  searchText.value = null;
  _overviewGroupStore.setKeySearchGroup(null);
};

const searchInputGroup = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath === '/dashboard/groups'
  ) {
    searchInputGroup.value?.showSearch();
    e.preventDefault();
  }
});

const onChangeText = (value) => {
  _overviewGroupStore.setKeySearchGroup(value);
};

const onOrderBy = (key) => {
  _overviewGroupStore.onOrderBy(key);
};
</script>

<template>
  <div class="flex items-center space-x-2 px-4">
    <!-- SEARCH   -->
    <SynSearch
      ref="searchInputGroup"
      v-model="searchText"
      icon-prefix="search"
      is-show-short-cut-key
      :placeholder="$t('GROUP_SEARCH')"
      :autofocus="true"
      width-max="23rem"
      @update:model-value="onChangeText"
    >
      <template v-if="searchText?.trim()?.length > 0" #suffix>
        <SynIcon
          has-action
          name="close2"
          custom-class="rounded-full w-3 h-3"
          @click="resetKeyword()"
        />
      </template>
    </SynSearch>

    <!-- HIDDEN TOGGLE   -->
    <div
      class="
        bg-white
        px-2
        py-2
        text-xs
        cursor-pointer
        fill-gray-500
        text-gray-600
        border border-gray-200
        rounded-full
        hover:bg-gray-200 hover:border-gray-400
      "
      @click="onToggleHiddenTask"
    >
      <div class="flex-center space-x-2">
        <span v-if="isHiddenTask">
          {{ $t('OVERVIEW_ACTION_SHOW_TASK') || 'Show task' }}
        </span>
        <span v-else>
          {{ $t('OVERVIEW_ACTION_HIDDEN_TASK') || 'Hidden task' }}
        </span>
        <SynIcon
          name="eye"
          :is-active="!isHiddenTask"
          custom-class="w-3.5 h-3.5"
        />
      </div>
    </div>

    <!-- BUTTON ADD -->
    <template v-if="!isVisitor">
      <SynButton
        v-if="lengthGroups < 1"
        v-vig-tooltip="{
          content: `
              <div class='px-2 py-4 w-48 text-center bg-white text-current-500'>
                <span class='text-md'>${$t('VIDEO_TUTORIAL_SUB_06')}</span>
              </div>`,
          arrow: true,
          placement: 'bottom',
          delay: 0,
          theme: 'light',
          animation: 'scale',
          interactive: true,
          showOnCreate: true,
          hideAfter: true,
        }"
        v-permission="'FunctionGroupAdd'"
        name-icon="group-add"
        custom-class="rounded py-2 text-xs"
        color="current"
        :label="$t('TASK_CREATE_GROUP_FORM_CREATE_GROUP')"
        @click.stop="isCreateGroup = true"
      />
      <div
        v-else
        v-permission="'FunctionGroupAdd'"
        v-vig-tooltip="{
          content: `
              <div class='px-2 py-4 w-48 text-center bg-white text-current-500'>
                <span class='text-md'>${$t('VIDEO_TUTORIAL_SUB_06')}</span>
              </div>`,
          arrow: true,
          placement: 'bottom',
          delay: 0,
          theme: 'light',
          animation: 'scale',
          interactive: true,
          showOnCreate: false,
        }"
        class="
          flex-center
          p-2
          bg-current-400
          rounded
          cursor-pointer
          hover:bg-current-500 hover:ring hover:ring-current-100
        "
        @click.stop="isCreateGroup = true"
      >
        <SynIcon custom-class="fill-white w-3.5 h-3.5" name="group-add" />
      </div>
    </template>

    <!-- SETTING -->
    <template v-if="!isVisitor">
      <router-link
        v-if="$permission('GroupManagement')"
        :to="{ name: 'GroupManagement' }"
      >
        <div
          class="
            flex-center
            p-2
            bg-white
            border border-gray-200
            rounded
            cursor-pointer
            hover:bg-gray-200
            hover:ring
            hover:ring-gray-100
            hover:border-gray-400
          "
        >
          <SynIcon
            v-vig-tooltip="$t('COMMON_MODULE_GROUP_MANAGEMENT')"
            is-active
            custom-class="h-3.5 w-3.5 fill-gray-500 text-gray-500"
            name="settings"
          />
        </div>
      </router-link>
    </template>

    <!-- ORDER BY   -->
    <VigDropdown placement="bottom-end">
      <template #dropdown-toggle>
        <div
          class="
            flex-center
            p-2
            bg-white
            border border-gray-200
            rounded
            cursor-pointer
            hover:bg-gray-200
            hover:ring
            hover:ring-gray-100
            hover:border-gray-400
          "
        >
          <SynIcon
            name="dots-vertical"
            custom-class="h-3.5 w-3.5 fill-gray-500 text-gray-500"
          />
        </div>
      </template>
      <template #dropdown-menu>
        <span class="text-xs text-gray-600 px-2 py-4 mt-2">
          {{ $t('DASHBOARD_GROUP_SORT_BY') }}:
        </span>
        <SynDropdownMenu class="right-0 w-44 mt-2">
          <SynDropdownItem
            :class="
              orderByKey == 1
                ? 'text-current font-medium bg-current-50 fill-current'
                : 'text-gray-600 fill-gray-500'
            "
            @click="onOrderBy(1)"
          >
            <div class="flex items-center">
              <SynIcon name="SortDesc" custom-class="w-4 h-4" />
              <span class="pl-2">
                {{ $t('DASHBOARD_GROUP_SORT_BY_CURRENT') || 'Recent on top' }}
              </span>
            </div>
          </SynDropdownItem>
          <SynDropdownItem
            :class="
              orderByKey == 2
                ? 'text-current font-medium bg-current-50 fill-current'
                : 'text-gray-600 fill-gray-500'
            "
            @click="onOrderBy(2)"
          >
            <div class="flex items-center">
              <SynIcon name="SortByAlphabet" custom-class="w-4 h-4" />
              <span class="pl-2">
                {{ $t('DASHBOARD_GROUP_SORT_BY_ALPHABET') || 'Alphabetical' }}
              </span>
            </div>
          </SynDropdownItem>
        </SynDropdownMenu>
      </template>
    </VigDropdown>
  </div>
</template>
