import AwsS3Class from '@/ui/plugins/awss3/AwsS3Class';
import {
    getKindOfFileByType,
    KindOfFile,
    ModuleSourceFile,
    ModuleSourceFileEnum,
} from '@/ui/plugins/awss3/AwsS3Type';
import { NotePayloadClass } from '@/domain/entities/PayloadClass/TaskPayloadClass';
import { IUploadPreview } from '@/ui/components/file-viewer/update-preview-type';
import { awsS3Delete } from '@/ui/plugins/awss3/aws-s3-services';
import dayjs from 'dayjs';
export default class NoteClass {
    id: string;
    localId?: string;
    name: string;
    fileUrl: string;
    fileBlob: any;
    contentType: string;
    documentType: KindOfFile;
    drawData: any;
    creationTime: Date;
    path: string;
    isNote: boolean;
    base64: string;
    sourceFile: ModuleSourceFile;
    editStatus: 'ORIGINAL' | 'ADDED' | 'REMOVED';

    constructor(sourceFile: ModuleSourceFile, file: any) {
        this.id = file?.id || undefined;
        this.localId = file?.localId;
        this.name =
            file?.name || `note_${dayjs().format('DD-MMM-YYYY_hh-mmA')}`;
        this.fileUrl = file?.fileUrl;
        this.base64 = file?.base64;
        this.fileBlob = this.base64
            ? Buffer.from(
                  this.base64.replace(/^data:image\/\w+;base64,/, ''),
                  'base64'
              )
            : '';
        this.contentType = 'image/jpeg';
        this.drawData = file?.drawData;
        this.path = file?.path || file?.imagePath;
        this.creationTime = file?.creationTime || new Date();
        this.documentType = getKindOfFileByType(this.contentType);
        this.isNote = true;
        this.sourceFile = sourceFile;
        this.editStatus = file?.editStatus || 'ORIGINAL';
    }

    async upload(): Promise<any> {
        try {
            const uploadRes = await new AwsS3Class(this.sourceFile).upload(
                this.fileBlob,
                this.contentType,
                this.documentType,
                this.name,
                '.jpeg',
                {
                    isBase64: true,
                }
            );

            return Promise.resolve(
                new NotePayloadClass({
                    id: this.id,
                    localId: this.localId,
                    bucket: uploadRes.Bucket,
                    drawData: this.drawData,
                    path: uploadRes.Key,
                    type: this.contentType,
                    creationTime: this.creationTime,
                })
            );
        } catch (error) {
            Promise.reject(error);
        }
    }

    async clone(): Promise<any> {
        try {
            const uploadRes = await new AwsS3Class(this.sourceFile).clone(
                this.path,
                'PHOTO',
                '.jpeg'
            );

            return Promise.resolve(
                new NotePayloadClass({
                    id: this.id,
                    localId: this.localId,
                    bucket: uploadRes.Bucket,
                    drawData: this.drawData,
                    path: uploadRes.Key,
                    type: this.contentType,
                    creationTime: this.creationTime,
                    fileUrl: this.fileUrl?.replace(this.path, uploadRes.Key),
                })
            );
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
            Promise.reject(error);
        }
    }

    remove() {
        const kindOfFile = getKindOfFileByType(this.contentType);
        if (!this.path) return Promise.resolve(false);

        return new AwsS3Class(this.sourceFile).remove(this.path, kindOfFile);
    }
}

export interface IDrawNoteDetail extends IUploadPreview {
    localId?: string;
    type: string;
    base64: string;
    drawData: any;
    isNote: boolean;
    editStatus: 'ORIGINAL' | 'ADDED' | 'EDITED' | 'REMOVED';
    originalData?: any;

    bucket?: string;
    documentType?: KindOfFile;
    extension?: string;
    fileName?: string;
    module?: ModuleSourceFile;
    path?: string;
    objectUrl?: string;
    fileBlob?: any;
}

export const uploadNotesToAws = async (
    notes: IDrawNoteDetail[],
    options: { isClone: boolean }
) => {
    // update load & remove note
    const promiseNoteAll: Promise<any>[] = [];

    // 1. Remain notes
    const keepRemainNotes =
        notes
            ?.filter(
                (note) => !note?.editStatus || note?.editStatus == 'ORIGINAL'
            )
            ?.map((o) => o?.originalData || o) || [];
    if (options?.isClone && keepRemainNotes && keepRemainNotes?.length > 0) {
        Array.from(keepRemainNotes).forEach((note: any) => {
            promiseNoteAll.push(
                new NoteClass(ModuleSourceFileEnum.task, {
                    ...note,
                    id: '',
                }).clone()
            );
        });
    }
    // 2. Add new notes
    const addNotes = notes?.filter((note) => note?.editStatus == 'ADDED');
    if (addNotes && addNotes?.length > 0)
        Array.from([
            ...addNotes?.map((note) => {
                return {
                    ...note,
                    id: '',
                };
            }),
        ]).forEach((file) => {
            promiseNoteAll.push(
                new NoteClass(ModuleSourceFileEnum.task, {
                    ...file,
                    base64: file?.base64,
                    drawData: file?.drawData,
                }).upload()
            );
        });

    // 3. Edit notes
    const editNotes = notes?.filter((note) => note?.editStatus == 'EDITED');
    if (editNotes && editNotes?.length > 0)
        Array.from([...editNotes]).forEach((file) => {
            promiseNoteAll.push(
                new NoteClass(ModuleSourceFileEnum.task, {
                    ...file,
                    base64: file?.base64,
                    drawData: file?.drawData,
                }).upload()
            );
        });

    // 4. Removed notes
    const removeNotes = notes?.filter((note) => note?.editStatus == 'REMOVED');
    if (removeNotes && removeNotes?.length > 0)
        Array.from([...removeNotes]).forEach((file) => {
            promiseNoteAll.push(
                new NoteClass(ModuleSourceFileEnum.task, {
                    ...file,
                    base64: file?.base64,
                    drawData: file?.drawData,
                }).remove()
            );
        });

    const newUploadedNotes = await Promise.all(promiseNoteAll);

    if (options?.isClone) return [...newUploadedNotes?.filter((o) => o?.path)];
    else
        return [
            ...keepRemainNotes,
            ...newUploadedNotes?.filter((o) => o?.path),
        ];
};

export const removeAwsNotes = async (notes: NotePayloadClass[]) => {
    return Promise.all(
        Array.from(notes).map((note) => {
            return awsS3Delete({
                Bucket: note.bucket,
                Key: note.path,
            });
        })
    );
};
