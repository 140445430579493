<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import Sortable from 'sortablejs';
import TabTaskTag from '@/ui/modules/task/components/tabs/TabTaskTag.vue';
import ActionInBoard from '@/ui/modules/task/board/board-container/ActionInBoard.vue';
import TaskAttachmentDetail from '@/ui/modules/task/detail/TaskAttachmentDetail.vue';
import taskSmartViewStore from '@/store/task/task-smart-view';
import {
  CustomFilters,
  ICustomTaskFilter,
} from '@/application/interfaces/tasks/task-interfaces';
import TaskBoardCardLoading from '@/ui/modules/task/board/task-card/TaskBoardCardLoading.vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import localConfigsStore from '@/store/local-configs';
import { useElementVisibility } from '@vueuse/core';
import useTaskBoardCard, { getFilterPayloadDefault } from './useTaskBoardCard';
import { ETaskListModule } from '@/application/types/task/task.types';
import { ETaskListMenuTab } from '@/application/constants/task.const';
import taskListStore from '@/store/task/task-list-store';

const props = withDefaults(
  defineProps<{
    cardId: string;
    cardInformation: ICustomTaskFilter;
    type: 'USER' | 'GROUP' | 'DOMAIN' | 'IA';
    sourceId: number;
  }>(),
  {
    type: 'USER',
  }
);

defineEmits(['onCollapseBoard', 'onOpenTaskDetail', 'onAddNewTask']);
const _taskSmartViewStore = taskSmartViewStore();
const _localConfigsStore = localConfigsStore();
const _taskListStore = taskListStore();

const cardIdInCache = computed<any>(() => `BOARD_PINNED_${props.cardId}`);
const localConfigs = computed<any>(() => _localConfigsStore.localConfigs);
const sourceType = computed<ETaskListModule>(() => {
  if (props.type === 'GROUP') return ETaskListModule.GROUP;
  if (props.type === 'DOMAIN') return ETaskListModule.DOMAIN;
  if (props.type === 'IA') return ETaskListModule.I_ASSIGNED;
  return ETaskListModule.USER;
});
const filterObject = computed<CustomFilters>(() => {
  return props.cardInformation?.filterObject;
});

const taskIds = computed(() => {
  return taskAllList.value.filter((taskDetail) => {
    return filterObject.value?.pinnedColor == taskDetail?.pinnedColor;
  });
});

const onCollapseBoard = () => {
  if (isCollapse.value && !isReady.value) initData(filterPayload.value);
  _localConfigsStore.setLocalConfigs('task', {
    [cardIdInCache.value]: {
      isCollapse: !isCollapse.value,
    },
  });
  if (isCollapse.value) {
    setTimeout(() => {
      createNewDragInstance();
    });
  }
};
const isAddNewTask = ref(null as any);

const isHasOpenAttachment = ref(null as any);
const currentTaskId = ref(0 as number);
const isOpenAttachmentDetail = ref(false);
const onOpenAttachmentDetail = (taskId) => {
  if (isHasOpenAttachment.value) isHasOpenAttachment.value = null;
  currentTaskId.value = taskId;
  isHasOpenAttachment.value = taskId;
  isOpenAttachmentDetail.value = true;
};

const onAddNewTask = (cardId) => {
  console.log('cardId', cardId);
  isAddNewTask.value = cardId;
};

const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;
};

const isHovered = ref<boolean>(false);
const onHoverCard = () => {
  if (isHovered.value) return;

  onLoadMore();
  isHovered.value = true;
};

const isCollapse = computed(() => {
  return localConfigs.value['task']?.[cardIdInCache.value]?.isCollapse;
});

watch(
  () => isCollapse.value,
  () => {
    if (isCollapse.value) {
      setTimeout(() => {
        createNewDragInstance();
      });
    }
  }
);

const taskBoardCardRef = ref(null);

const createNewDragInstance = () => {
  if (!taskBoardCardRef.value) return;

  new Sortable(taskBoardCardRef.value, {
    group: 'task-board-card_pinned',
    animation: 150,
    ghostClass: 'blue-background-class',
    chosenClass: 'sortable-chosen-class',
    filter: '.filtered',
    preventOnFilter: true,
    dataIdAttr: 'Hyrin',
    // onStart: function (/**Event*/) {
    //   console.log('🚀 Hyrin ~ on start drag:', props.cardInformation);
    // },
    // onEnd: function (/**Event*/) {
    //   console.log('🚀 Hyrin ~ on end drag:', props.cardInformation);
    // },
    onAdd: function (/**Event*/ evt) {
      // same properties as onEnd
      _taskSmartViewStore.changePinTask(
        evt?.item?.id,
        props.cardInformation?.id
      );
    },
    onMove: function () {
      return 1;
    },
  });
};
onMounted(() => {
  createNewDragInstance();
});

const targetIsVisible = useElementVisibility(taskBoardCardRef);
const filterPayload = computed<any>(() => {
  const _filter = getFilterPayloadDefault(props.sourceId, sourceType.value);
  _filter.isDefault = false;

  const customFilter = _taskListStore.filterPayload;

  return {
    ...(customFilter?.isDefault ? _filter : customFilter),
    menuTab: ETaskListMenuTab.PINNED,
    pinColor: props.cardInformation?.id,
  };
});

const {
  isReady,
  taskAllList,
  allTaskByIds,
  currentSortData,
  listTaskIdShowing,
  isLoading,
  initData,
  onLoadMore,
  onChangeIterate,
  onChangeOrder,
  onSearch,
} = useTaskBoardCard();

const initComponent = () => {
  if (targetIsVisible.value) initData(filterPayload.value);
};

watch(
  () => targetIsVisible.value,
  (value) => {
    if (value && !isReady.value && !isCollapse.value)
      initData(filterPayload.value);
  }
);
initComponent();
</script>

<template>
  <div
    v-bind="$attrs"
    class="
      flex flex-col
      h-full
      shrink-0
      hover-to-show__parent
      task-board-card-status
    "
    :style="isCollapse ? `width: 3rem` : `width: 25rem`"
    @mouseover="onHoverCard"
  >
    <div
      class="
        p-2
        rounded
        flex
        items-center
        justify-between
        drag-to-scroll-target
        hover:bg-current-50
      "
      :class="isCollapse ? 'flex-col-reverse ' : 'pl-2'"
    >
      <TabTaskTag
        v-if="!isCollapse"
        is-active
        readonly
        :color="cardInformation?.color || ''"
        :name="cardInformation?.name"
        :icon-name="'pin'"
        :total="taskIds?.length"
      />
      <ActionInBoard
        type="STATUS"
        hidden-class="task-board-card-status__hover-to-show"
        :tab="cardInformation"
        :is-collapse="isCollapse"
        :current-sort-data="currentSortData"
        readonly
        @on-collapse-board="onCollapseBoard"
        @on-add-new-task="onAddNewTask(cardId)"
        @on-change-iterate="onChangeIterate"
        @on-change-order="onChangeOrder"
        @on-search="onSearch"
      />
    </div>

    <template v-if="isCollapse">
      <div
        class="
          bg-gray-50
          rounded
          flex-1
          overflow-y-auto
          small-scrollbar
          relative
        "
      >
        <TabTaskTag
          class="absolute min-w-max"
          style="
            transform-origin: left;
            transform: rotate(90deg) translateY(-50%);
            left: 25%;
          "
          is-active
          readonly
          :color="cardInformation?.color"
          :name="cardInformation?.name"
          :icon-name="'pin'"
          :total="taskIds?.length"
        />
      </div>
    </template>
    <template v-else>
      <div
        ref="taskBoardCardRef"
        v-scroll-infinite="onLoadMore"
        class="
          bg-gray-50
          rounded
          flex-1
          overflow-y-auto
          small-scrollbar
          relative
        "
      >
        <template v-if="isLoading">
          <TaskBoardCardLoading class="m-2" />
        </template>

        <template v-else>
          <TaskCardItem
            v-for="taskId in listTaskIdShowing"
            :id="taskId"
            :key="taskId"
            class="m-2"
            is-draggable
            :task-detail="allTaskByIds[taskId]"
            @on-show-files="onOpenAttachmentDetail(taskId)"
          />
        </template>
      </div>
    </template>
  </div>

  <TaskAttachmentDetail
    v-if="isOpenAttachmentDetail"
    :task-id="currentTaskId"
    @on-close="onCloseAttachment"
  />
</template>
<style>
.task-board-card-status__hover-to-show {
  display: none;
}
.task-board-card-status:hover .task-board-card-status__hover-to-show {
  display: flex;
}
</style>
