<script setup lang="ts">
import { computed, ref } from 'vue';
import inputValueComp from '@/ui/common/composables/form/input-value-comp';
import organizationStore from '@/store/organization';
import { EOrganizationModel } from '@/application/types/organization/organization.type';

const props = defineProps<{
  model: EOrganizationModel | undefined;
  name: string;
  address: string | undefined;
  addressDetail: string | undefined;
  type: string | undefined;
  domainName: string | undefined;
  websiteUrl: string | undefined;
  phone: string;
  organizationModel: string;
  subType: string;
  domain: string | undefined;
  isShowErrorCompanyModel?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:model', value: EOrganizationModel): void;
  (e: 'update:name', value: string): void;
  (e: 'update:address', value: string): void;
  (e: 'update:addressDetail', value: string): void;
  (e: 'update:type', value: string): void;
  (e: 'update:domainName', value: string): void;
  (e: 'update:websiteUrl', value: string): void;
  (e: 'update:phone', value: string): void;
  (e: 'update:subType', value: string): void;
}>();

const _organizationStore = organizationStore();

const onChangeOrganizationType = (item) => {
  emit('update:type', item?.code);
  emit('update:domainName', item?.name);
};
const { value: organizationName, errorMessage: nameError } = inputValueComp(
  (value) => {
    if (!value?.trim()) return 'COMMON_THIS_FIELD_REQUIRED';
    return '';
  }
);
organizationName.value = props.name || '';

const organizationTypes = computed(() => _organizationStore.organizationTypes);

const onAddressSelect = (value) => {
  if (value) {
    emit('update:addressDetail', JSON.stringify(value));
  }
};

const isChange = ref({
  name: false,
  phone: false,
  address: false,
  member: false,
  websiteUrl: false,
  taxCode: false,
});

const onClearDomain = () => {
  emit('update:type', '');
  emit('update:domainName', '');
};

const organizationModels = computed<any>(() => {
  return (
    [
      {
        code: EOrganizationModel.COMPANY,
        label: 'COMMON_LABEL_COMPANY',
      },
      {
        code: EOrganizationModel.TEAM,
        label: 'COMMON_LABEL_TEAM',
      },
      {
        code: EOrganizationModel.NON_PROFIT_ORGANIZATION,
        label: 'COMMON_LABEL_NON_PROFIT_ORGANIZATION',
      },
    ]?.filter((o) =>
      _organizationStore.organizationModels?.some((m) => m?.code == o?.code)
    ) || [
      {
        code: EOrganizationModel.COMPANY,
        label: 'COMMON_LABEL_COMPANY',
      },
    ]
  );
});
</script>

<template>
  <div class="flex-center flex-col space-y-4 px-8 w-full">
    <div class="w-full text-2xl text-center">
      <span> {{ $t('REGISTER_LABEL_YOUR_ORGANIZATION_IS') }}: </span>
    </div>
    <div class="w-full">
      <span> {{ $t('REGISTER_LABEL_MATCH_YOUR_USAGE_NEEDS') }} </span>
    </div>
    <div class="w-full text-center">
      <span>
        {{ $t('REGISTER_LABEL_CREATE_YOUR_WORKSPACE') }}
      </span>
    </div>
    <div
      class="mt-6 flex flex-col gap-6 overflow-hidden small-scrollbar w-full"
    >
      <div class="flex-center gap-2 relative">
        <SynRadio
          v-for="item in organizationModels"
          :key="item?.code"
          :model-value="model"
          class="bg-gray-50 hover:bg-gray-100 py-1 px-2 rounded-md"
          :value="item?.code"
          @update:model-value="$emit('update:model', item?.code)"
        >
          <span class="text-sm">
            {{ $t(item?.label) }}
          </span>
        </SynRadio>
        <span
          v-if="isShowErrorCompanyModel"
          class="text-red-500 text-xs absolute left-0 -bottom-4"
        >
          * {{ $t('REGISTER_LABEL_PLEASE_SELECT_YOUR_ORGANIZATION_TYPE') }}
        </span>
      </div>

      <div class="w-full flex flex-col gap-4">
        <!--  NAME -->
        <div class="flex flex-col gap-1">
          <div class="flex items-center text-sm pr-4 w-44 text-gray-600">
            <span>{{
              $t(
                model == EOrganizationModel.COMPANY
                  ? 'COMMON_LABEL_COMPANY'
                  : model == EOrganizationModel.TEAM
                  ? 'COMMON_LABEL_TEAM'
                  : 'COMMON_LABEL_NON_PROFIT_ORGANIZATION'
              )
            }}</span>
            <span class="text-red-500 pl-1">*</span>:
          </div>
          <SynFormInput :error-message="nameError">
            <SynInput
              ref="organizationNameRef"
              v-model="organizationName"
              custom-class="text-sm h-10"
              class="h-10"
              autofocus
              :placeholder="
                $t(
                  model == EOrganizationModel.COMPANY
                    ? 'ORGANIZATION_LABEL_NAME_COMPANY'
                    : model == EOrganizationModel.TEAM
                    ? 'ORGANIZATION_LABEL_NAME_TEAM'
                    : 'COMMON_LABEL_NON_PROFIT_ORGANIZATION_NAME'
                ) || ''
              "
              :error-message="nameError"
              @update:model-value="(value) => $emit('update:name', value)"
            />
          </SynFormInput>
        </div>

        <SynFormInput class="h-10">
          <VigAddressBox
            :model-value="address"
            :placeholder="$t('COMMON_LABEL_PLACEHOLDER_ENTER_ADDRESS')"
            class="vig-input text-sm h-10"
            @update:model-value="(value) => $emit('update:address', value)"
            @on-address-select="onAddressSelect"
          />
        </SynFormInput>
        <SynFormInput>
          <VigSelect
            :placeholder="$t('COMMON_LABEL_CHOOSE_DOMAIN')"
            key-search="name"
            key-label="name"
            toggle-class="py-1 text-sm "
            :label-value="domainName"
            :origin-options="organizationTypes"
            @on-choose="onChangeOrganizationType"
            @on-clear="onClearDomain"
          />
        </SynFormInput>
        <SynFormInput>
          <SynInput
            ref="organizationNameRef"
            :model-value="phone"
            custom-class="text-sm h-10"
            class="h-10"
            :placeholder="
              $t(
                model == EOrganizationModel.COMPANY
                  ? 'ORGANIZATION_LABEL_COMPANY_PHONE_NUMBER'
                  : 'ORGANIZATION_LABEL_TEAM_PHONE_NUMBER'
              ) || ''
            "
            @update:model-value="(value) => $emit('update:phone', value)"
            @change="isChange.phone = true"
          />
        </SynFormInput>
        <SynFormInput class="h-10">
          <SynInput
            :model-value="websiteUrl"
            class="h-10"
            custom-class="text-sm h-12"
            :placeholder="
              $t(
                model == EOrganizationModel.COMPANY
                  ? 'COMMON_LABEL_PLACEHOLDER_ENTER_WEBSITE'
                  : 'ORGANIZATION_LABEL_COMPANY_WEBSITE'
              )
            "
            @update:model-value="(value) => $emit('update:websiteUrl', value)"
          />
        </SynFormInput>
      </div>
    </div>
  </div>
</template>
