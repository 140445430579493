<script setup lang="ts">
import FilterTask from '@/ui/modules/task/filter/FilterTask.vue';
import { computed, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';

defineProps<{}>();

defineEmits(['onFilter', 'onReset']);

const myProfile = computed(() => myProfileStore().myProfile);

const filterCount = ref<number>();
const filterDefault = ref<any>();
</script>

<template>
  <VigDropdown ref="dropdown">
    <template #dropdown-toggle>
      <VigButton
        v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER') || 'Filter'"
        ghost
        rounded="rounded-full"
        color="gray"
        padding="p-2"
        class="relative"
      >
        <SynIcon
          name="Filter"
          :class="filterCount ? 'text-current-600' : 'text-gray-500'"
        />
        <div
          v-if="filterCount"
          class="
            absolute
            -top-1
            right-0
            bg-current-500
            text-white
            ring-1 ring-white
            text-xs
            font-semibold
            rounded-full
            w-4
            h-4
            flex-center
          "
        >
          {{ filterCount }}
        </div>
      </VigButton>
    </template>
    <template #dropdown-menu>
      <FilterTask
        :user-id="myProfile?.id"
        :filter-default="filterDefault"
        show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION"
        title="TASK_LABEL_ADVANCE_FILTERS"
        @on-close="$refs.dropdown?.onForceClose()"
        @on-reset="$emit('onReset')"
        @on-filter="$emit('onFilter', $event)"
        @update:filter-count="(value) => (filterCount = value)"
      />
    </template>
  </VigDropdown>
</template>
