<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m365.572 75.6-121.8 121.8 47.787 47.788a28.722 28.722 0 1 0 32.072 32.072l43.314 43.314 47.255-47.26a28.4 28.4 0 1 0 31.715-31.714l42.83-42.829z"
        fill="#f9b534"
        data-original="#f9b534"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m447.074 8-26.72 26.724-63.489 15.076-33.35 33.355-15.258 49.759 57.315-57.314 78.228 78.229 14.927-15.013c16.923-16.916 16.833-45.455 16.833-45.455l28.44-28.444v-56.917z"
        fill="#fcd3c4"
        data-original="#fcd3c4"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m366.259 75.6-31.248 96.9s18.889 8.776 30.32-2.8 29.6-52 29.6-52 33.263.771 48.27-21.717z"
        fill="#fcd3c4"
        data-original="#fcd3c4"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m443.2 95.973-6.918 2.306c-9.672 10.347-24.174 13.754-33.156 14.871l8.508 8.508c11.492-2.271 27.216-8.519 31.566-25.685z"
        fill="#ffc7b5"
        data-original="#ffc7b5"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m395.018 113.69-2.647-.055-1.09 2.415c-.178.4-18 39.9-28.8 50.833-3.143 3.185-7.4 4.8-12.665 4.8a32.411 32.411 0 0 1 -7.908-1.065l-.742 2.3s18.889 8.776 30.32-2.805 27.364-47.166 27.364-47.166a64.777 64.777 0 0 0 12.78-1.288l-8.508-8.508a66.016 66.016 0 0 1 -7.8.543z"
        fill="#f7a41c"
        data-original="#f7a41c"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m366.259 75.6-31.248 96.9s18.889 8.776 30.32-2.8 29.6-52 29.6-52 33.263.771 48.27-21.717z"
        fill="#fcd3c4"
        data-original="#fcd3c4"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m8 447.074 26.724-26.72 15.076-63.489 33.355-33.35 30.759-9.753 19.281 2.2-52.085 52.55 74.562 73.942-16.855 16.277c-16.917 16.919-45.456 16.829-45.456 16.829l-28.444 28.44h-56.917z"
        fill="#fcd3c4"
        data-original="#fcd3c4"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m226.149 298.536a28.131 28.131 0 0 1 16.909-8.014l-43.639-43.639-42.829 42.829a28.4 28.4 0 1 0 -31.714 31.714l-47.256 47.256 123.174 123.173 47.256-47.255a28.4 28.4 0 1 1 31.713-31.714l42.83-42.829-48.266-48.265a28.348 28.348 0 1 1 -48.178-23.255z"
        fill="#f7685f"
        data-original="#f7685f"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m98.361 436.2-2.388 7a51.012 51.012 0 0 0 25.16-31l-8.133-8.135c-1.247 9.013-4.763 22.816-14.639 32.135z"
        fill="#ffc7b5"
        data-original="#ffc7b5"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m123.408 398.529s36.1-16.175 47.683-27.606 2.8-30.32 2.8-30.32l-3.317 1.188c1.448 5.614 2.432 14.646-3.7 20.693-10.936 10.8-50.437 28.618-50.834 28.8l-2.421 1.088.061 2.653a65.008 65.008 0 0 1 -.686 9.043l8.129 8.129a50.6 50.6 0 0 0 2.285-13.668z"
        fill="#f0534a"
        data-original="#f0534a"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m172.5 335.011-94.88 33.967 18.353 74.222c22.488-15.007 21.717-48.27 21.717-48.27s40.423-18.167 52-29.6 2.81-30.319 2.81-30.319z"
        fill="#fcd3c4"
        data-original="#fcd3c4"
      />
    </g>
  </svg>
</template>
