<script setup lang="ts">
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';
import { AdminType } from '@/ui/common/constants/constant';
import { computed, onMounted, ref } from 'vue';
import departmentStore from '@/store/department';
import SelectDepartment from '@/ui/pages/usermanagement/detailUser/SelectDepartment.vue';
import { cloneDeep } from 'lodash';
import SynEnterList from '@/ui/pages/usermanagement/detailUser/SynEnterList.vue';
import groupStore from '@/store/group';
import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import domainStore from '@/store/scope';
import organizationStore from '@/store/organization';

const emit = defineEmits(['cancel', 'onSave']);

const props = defineProps<{
  userSelectedList: any;
  mode: 'list' | 'user';
}>();

const _departmentStore = departmentStore();
const _organizationStore = organizationStore();
const _groupStore = groupStore();
const _domainStore = domainStore();
const allDepartment = computed(() => _departmentStore.allDepartments);
const allDomains = computed(() => _domainStore.allDomains);
const myGroupList = computed(() =>
  _groupStore.allMyGroups.filter(
    (e) => (e.isAdminGroup && e.isPrivate) || !e.isPrivate
  )
);
const departmentList = ref([] as any);
const isLoading = ref(false);
const groupList = ref([] as any);
const domainList = ref([] as any);
const selectedGroups = ref([] as any);
const selecteddDomains = ref([] as any);
const currentDepartment = ref();
const isSelectGroupsMore = ref<boolean>(false);
const isSelectDomainsMore = ref<boolean>(false);
const synSelectGroupRef = ref<any>(null);
const synSelectDomainRef = ref<any>(null);

const onOpenEditMode = (mode) => {
  if (mode == 'group') {
    isSelectGroupsMore.value = true;
    setTimeout(() => {
      synSelectGroupRef.value?.onOpenDropdown();
    }, 100);
  } else {
    isSelectDomainsMore.value = true;
    setTimeout(() => {
      synSelectDomainRef.value?.onOpenDropdown();
    }, 100);
  }
};
const onCloseEditMode = () => {
  isSelectGroupsMore.value = false;
  isSelectDomainsMore.value = false;
};

const removeDepartment = () => {
  currentDepartment.value = {};
  departmentList.value = cloneDeep(allDepartment.value);
};

const chooseGroups = (value) => {
  selectedGroups.value = [...value];
};

const chooseDomains = (value) => {
  selecteddDomains.value = [...value];
};

const removeGroups = (value) => {
  selectedGroups.value = [...value];
};

const removeDomains = (value) => {
  selecteddDomains.value = [...value];
};

const removeItem = (value, type) => {
  if (type == 'group') {
    selectedGroups.value = selectedGroups.value.filter((e) => e.id !== value);
  } else
    selecteddDomains.value = selecteddDomains.value.filter(
      (e) => e.id !== value
    );
};

const onChangeDepartment = (value) => {
  currentDepartment.value = value;
};

onMounted(() => {
  departmentList.value = cloneDeep(allDepartment.value);
  groupList.value = cloneDeep(myGroupList.value);
  domainList.value = cloneDeep(allDomains.value);

  selectedGroups.value =
    props.mode == 'user'
      ? props.userSelectedList[0]?.groupIds.map((e) =>
          myGroupList.value.find((u) => u.id == e)
        )
      : [];
  selecteddDomains.value =
    props.mode == 'user'
      ? props.userSelectedList[0]?.domainIds.map((e) =>
          allDomains.value.find((u) => u.id == e)
        )
      : [];
  currentDepartment.value =
    props.mode == 'user'
      ? allDepartment.value.find(
          (e) => e.id === props.userSelectedList[0]?.departmentId
        )
      : null;
});

const roleList = [
  // {
  //   text: 'COMMON_LABEL_OWNER',
  //   key: AdminType.Owner,
  // },
  {
    text: 'USERMANAGEMENT_FORM_TYPE_ADMIN',
    key: AdminType.Admin,
  },
  {
    text: 'USERMANAGEMENT_FORM_TYPE_USER',
    key: AdminType.User,
  },
  {
    text: 'USERMANAGEMENT_FORM_TYPE_VISITOR',
    key: AdminType.Visitor,
  },
];

const roleDefault = ref(
  props.mode == 'user' ? props.userSelectedList[0]?.adminType : AdminType.User
);

const onSave = async () => {
  isLoading.value = true;
  const payload = {
    invitationIds:
      props.mode == 'user'
        ? [props.userSelectedList[0]?.id]
        : props.userSelectedList.map((o) => o.id),
    adminType: roleDefault.value,
    departmentId: currentDepartment.value?.id,
    groupIds: selectedGroups.value?.map((o) => o.id),
    domainIds: selecteddDomains.value?.map((o) => o.id),
  };
  await _organizationStore.updateInvitations(payload);
  isLoading.value = false;
  emit('onSave');
};
</script>

<template>
  <SynModal
    style-body="px-4 py-4"
    container-class="w-1/3"
    z-index="z-50"
    container-style="max-width: 1180px; min-height: 410px;"
    style-body-string="padding: 1rem 2.5rem"
    @cancel="$emit('cancel')"
    @click-to-body="onCloseEditMode"
  >
    <template #header>
      <div class="flex items-center">
        <span>{{ $t('COMMON_LABEL_EDIT') }}</span>
      </div>
    </template>
    <template #body>
      <div class="flex flex-col space-y-4">
        <div class="flex items-center space-x-2">
          <div class="w-40 text-gray-500">
            <span>{{ $t('USERMANAGEMENT_PENDING_HEADER_EMAILORPHONE') }}</span>
          </div>
          <div
            class="
              flex-1
              text-current-500
              overflow-auto
              max-h-40
              small-scrollbar
            "
          >
            <div class="flex flex-wrap items-center">
              <div v-for="(item, index) in userSelectedList" :key="item.id">
                <span>{{ item?.emailOrPhone }}</span>
                <span v-if="index !== userSelectedList.length - 1" class="mr-1"
                  >,</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center space-x-2">
          <div class="w-40 text-gray-500">
            <span>{{ $t('USERMANAGEMENT_PENDING_HEADER_ROLE') }}</span>
          </div>
          <SynSelect
            v-model="roleDefault"
            width-class="w-44 text-sm"
            custom-class="py-2"
            is-translate
            :options="roleList"
            key-field="key"
            text-field="text"
          />
        </div>
        <div class="flex items-center space-x-2">
          <div class="w-40 text-gray-500">
            <!--            <span>{{ $t('USERMANAGEMENT_PENDING_HEADER_EMAILORPHONE') }}</span>-->
            <span>{{ $t('COMMON_MODULE_MANAGEMENT_DEPARTMENT') }}</span>
          </div>
          <SelectDepartment
            :avatar="currentDepartment?.avatar"
            :name="currentDepartment?.name"
            :data="allDepartment"
            removeable
            placeholder="Chọn phòng ban"
            @chosen="onChangeDepartment"
            @reset="removeDepartment"
          />
        </div>
        <div class="flex items-center space-x-2">
          <div class="w-40 text-gray-500 mb-1">
            <span>{{ $t('COMMON_MODULE_GROUP_MANAGEMENT') }}</span>
          </div>
          <div
            class="
              flex-1
              overflow-auto
              small-scrollbar
              flex
              items-center
              space-x-2
            "
            style="min-height: 2.5rem; max-height: 20rem"
          >
            <template v-if="!isSelectGroupsMore">
              <div class="flex items-center flex-wrap">
                <div
                  v-for="item in selectedGroups"
                  :key="item?.id"
                  class="
                    px-2
                    p-1
                    flex
                    items-center
                    space-x-2
                    bg-current-50
                    rounded-full
                    mr-2
                    mb-1
                  "
                >
                  <SynAvatar
                    type="group"
                    :name="item?.name"
                    custom-class="w-5 h-5"
                    :src="
                      item?.avatar?.replace('original', 'mini') ||
                      item?.avatarUrl?.replace('original', 'mini')
                    "
                  />
                  <span class="text-sm">{{ item?.name }}</span>
                  <div
                    class="
                      rounded-full
                      p-1
                      hover:bg-gray-100
                      flex-center
                      cursor-pointer
                    "
                    @click="removeItem(item?.id, 'group')"
                  >
                    <SynIcon
                      name="close"
                      custom-class="w-4 h-4 fill-gray-500"
                    />
                  </div>
                </div>
                <button
                  v-vig-tooltip="{
                    content: 'Add groups',
                  }"
                  class="
                    text-xl
                    mb-1
                    font-medium
                    w-6
                    h-6
                    rounded-full
                    flex-center
                    cursor-pointer
                    outline-none
                    focus:outline-none
                    text-current-700
                    bg-current-50
                    hover:bg-current-100
                    z-10
                  "
                  @click.stop="onOpenEditMode('group')"
                >
                  <SynIcon
                    name="plus"
                    custom-class="w-3 h-3 fill-current-700"
                  />
                </button>
              </div>
            </template>
            <template v-else>
              <SynEnterList
                ref="synSelectGroupRef"
                type="group"
                :selected-list="selectedGroups"
                :data="groupList"
                :placeholder="$t('COMMON_LABEL_SELECT_GROUP')"
                @chosen="chooseGroups"
                @remove="removeGroups"
              />
            </template>
          </div>
        </div>
        <!--          DOMAIN-->
        <div class="flex items-center space-x-2">
          <div class="w-40 text-gray-500 mb-1">
            <span>{{ $t('TASK_EXPORT_COLUMNS_DOMAIN') }}</span>
          </div>
          <div
            class="
              flex-1
              overflow-auto
              small-scrollbar
              flex
              items-center
              space-x-2
            "
            style="min-height: 2.5rem; max-height: 20rem"
          >
            <template v-if="!isSelectDomainsMore">
              <div class="flex items-center flex-wrap">
                <div
                  v-for="item in selecteddDomains"
                  :key="item?.id"
                  class="
                    px-2
                    p-1
                    flex
                    items-center
                    space-x-2
                    bg-current-50
                    rounded-full
                    mr-2
                    mb-1
                  "
                >
                  <!--                  <SynAvatar-->
                  <!--                    type="group"-->
                  <!--                    :name="item?.name"-->
                  <!--                    custom-class="w-5 h-5"-->
                  <!--                    :src="-->
                  <!--                      item?.avatar?.replace('original', 'mini') ||-->
                  <!--                      item?.avatarUrl?.replace('original', 'mini')-->
                  <!--                    "-->
                  <!--                  />-->
                  <span class="text-sm">{{ item?.name }}</span>
                  <div
                    class="
                      rounded-full
                      p-1
                      hover:bg-gray-100
                      flex-center
                      cursor-pointer
                    "
                    @click="removeItem(item.id, 'domain')"
                  >
                    <SynIcon
                      name="close"
                      custom-class="w-4 h-4 fill-gray-500"
                    />
                  </div>
                </div>
                <button
                  v-vig-tooltip="{
                    content: 'Add domains',
                  }"
                  class="
                    text-xl
                    mb-1
                    font-medium
                    w-6
                    h-6
                    rounded-full
                    flex-center
                    cursor-pointer
                    outline-none
                    focus:outline-none
                    text-current-700
                    bg-current-50
                    hover:bg-current-100
                    z-10
                  "
                  @click.stop="onOpenEditMode('domain')"
                >
                  <SynIcon
                    name="plus"
                    custom-class="w-3 h-3 fill-current-700"
                  />
                </button>
              </div>
            </template>
            <template v-else>
              <SynEnterList
                ref="synSelectDomainRef"
                type="domain"
                :selected-list="selecteddDomains"
                :data="domainList"
                placeholder="Select a domain"
                @chosen="chooseDomains"
                @remove="removeDomains"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="pr-6 flex py-6 space-x-2 justify-end">
        <SynButton
          type-text
          color="gray"
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          @click="$emit('cancel')"
        />
        <SynButton
          :label="$t('COMMON_LABEL_SAVE')"
          :is-loading="isLoading"
          @click="onSave"
        />
      </div>
    </template>
  </SynModal>
</template>
