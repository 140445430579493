<script setup lang="ts">
import { ref, computed, onUnmounted, onMounted } from 'vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import UserById from '@/ui/components/user/UserById.vue';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
import { getConfigScheduleColor } from '@/ui/hooks/taskHook';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import taskStore from '@/store/task';
import { translate } from '@/ui/plugins/i18n/myi18n';
import GroupById from '@/ui/components/group/GroupById.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import domainStore from '@/store/scope';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
// import TaskState from '@/ui/modules/task/components/TaskState.vue';
import myProfileStore from '@/store/auth/my-profile';
import { TaskLife } from '@/domain/enums/taskEnum';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import UploadDropZone from '@/ui/modules/ged/upload/upload-drop-zone/UploadDropZone.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import UpdateDomainModal from '@/ui/modules/task/detail/UpdateDomainModal.vue';
import commentLogic from '@/ui/modules/task/general/comment-logic';
import AttachmentGroupButton from '@/ui/modules/task/components/AttachmentGroupButton.vue';
import updateAttachmentLogic from '@/ui/modules/task/detail/update-attachment-logic';
import AttachmentUploading from '@/ui/modules/task/components/AttachmentUploading.vue';
import customDocumentTitle from '@/ui/composables/app/document-title';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';
import UpdateDomainDropdown from '@/ui/modules/task/detail/UpdateDomainDropdown.vue';
import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';
import remoteConfigStore from '@/store/remoteConfig';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import chatStore from '@/store/chat';
import ModalDiscussChatTask from '@/ui/modules/messaging/modal-discuss-chat/ModalDiscussChatTask.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
// import TaskDetailFieldIcon from '@/ui/modules/task/components/TaskDetailFieldIcon.vue';
import { TaskReccuringTemplateClass } from '@/domain/entities/task/TaskReccuringTemplateClass';
import TaskStateStepper from '@/ui/modules/task/components/TaskStateStepper.vue';
import SetPinTask from '@/ui/modules/task/components/pin/SetPinTask.vue';
import GroupAttachmentAction from '@/ui/modules/task/components/GroupAttachmentAction.vue';
import TaskTodoListCreateNew from '@/ui/modules/task/components/check-list/TaskTodoListCreateNew.vue';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import { ScheduleDayOptions } from '@/domain/enums/taskEnum';
import settingStore from '@/store/setting';
import ManageCollaborator from '@/ui/modules/task/detail/assign/ManageCollaborator.vue';
import DeadlineInput from '@/ui/modules/task/components/deadline/DeadlineInput.vue';
import { IConfigType } from '@/application/types/task/task-configs.types';
import TaskDeadlineBlockingShortInfo from '@/ui/modules/task/deadline-blocking/TaskDeadlineBlockingShortInfo.vue';
import { EActionOnTask } from '@/application/types/task/task.types';
import ActionOnTask from '@/ui/modules/task/detail/action-on-task/ActionOnTask.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
// import {
//   isShowTaskDetailGlobal,
//   taskCodeGlobal,
// } from '@/ui/modules/task/task-global-state';
// import SubTaskList from '@/ui/modules/task/detail/sub-tasks/SubTaskList.vue';
import TaskMultipleAssignee from '@/ui/modules/task/detail/sub-tasks/TaskMultipleAssignee.vue';

const props = defineProps<{
  isPlaceModal?: boolean;
  originTemplate?: TaskReccuringTemplateClass;
}>();

const emit = defineEmits(['onClose']);

const taskTemplateFileInputId = 'task-template_attachment';
const _taskStore = taskStore();
const _domainStore = domainStore();
const currentTaskTemplate = ref<TaskReccuringTemplateClass>(
  new TaskReccuringTemplateClass({
    ...props.originTemplate,
    subTasksDetail: props.originTemplate?.subTasksDetail?.map((t) => {
      return {
        ...t,
        isSelecting: true,
      };
    }),
  }) || ({} as TaskReccuringTemplateClass)
);
const _remoteConfigStore = remoteConfigStore();
const _chatStore = chatStore();
const _settingStore = settingStore();

const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting?.taskManagement?.multipleAssigneeSetting
);
const webTaskUploadContentTypes = computed(
  () => _remoteConfigStore.webTaskUploadContentTypes
);
// Enable function
const myTimezone = computed(() => myProfileStore().getTimezone);
const totalAttachmentFiles = computed(
  () => currentTaskTemplate.value?.attachments?.length
);
const isLoadingInit = ref(false);
const isAttachmentLoading = ref(false);
const errorCode = ref('');
const { updateDocumentTitle, resetToLastestTitle } = customDocumentTitle();

const taskDescriptionRef = ref<any>(null);
const myCommentValid = ref(true);
const {
  descriptionInputRef,
  isInputComment,
  commentObjectCreate,
  onOpenComment,
  onSaveComment,
  onCancelComment,
  onFocus,
} = commentLogic(true, (value) => {
  taskDescriptionRef.value?.onInsertText(value);
});
const {
  isOpenNote,
  drawNoteData,
  uploadError,
  uploadingFiles,
  isPasted,
  onFetchCreateNote,
  onFetchUpdateNote,
  onOpenCreateNote,
  onUploadFiles,
  onChooseFiles,
  isOpenRecording,
  onCreateAudioDone,
} = updateAttachmentLogic(currentTaskTemplate, taskTemplateFileInputId);

onUnmounted(() => {
  resetToLastestTitle();
});

const isLoadingDataTask = ref({
  detail: false,
  files: false,
  comment: false,
});

const init = async () => {
  try {
    isLoadingInit.value = true;
    isLoadingDataTask.value.detail = true;
    errorCode.value = '';
    onCloseEditName();
    onCloseEditDescription();

    currentTaskTemplate.value = new TaskReccuringTemplateClass({
      ...props.originTemplate,
      subTasksDetail: props.originTemplate?.subTasksDetail?.map((t) => {
        return {
          ...t,
          isSelecting: true,
        };
      }),
    });
    isLoadingDataTask.value.detail = false;
    isLoadingInit.value = false;

    isLoadingDataTask.value.files = true;
    isAttachmentLoading.value = true;
    await currentTaskTemplate.value.getAttachmentData();
    isLoadingDataTask.value.files = false;
    if (_domainStore.allDomains.length == 0)
      _domainStore.fetchAllDomainProjects();
    handleChangeDocumentTitle();
  } catch (error: any) {
    const code = error?.error?.code;
    isLoadingDataTask.value.detail = false;
    isLoadingDataTask.value.files = false;
    errorCode.value = code
      ? `COMMON_ERROR_BY_CODE_${code}`
      : 'COMMON_ERROR_BY_CODE_6403';
  } finally {
    isLoadingDataTask.value.detail = false;
    isLoadingDataTask.value.files = false;
    isLoadingInit.value = false;
    isAttachmentLoading.value = false;
  }
};

const handleChangeDocumentTitle = () => {
  const domainDetail = _domainStore.getDomainDetailById(
    currentTaskTemplate.value?.domainId
  );

  let keyInfo = `${formatTaskCode(currentTaskTemplate.value?.code)} - ${
    domainDetail?.name
  }`;
  keyInfo = keyInfo.length > 20 ? `${keyInfo.substring(0, 16)}...` : keyInfo;

  let nameInfo = `[${keyInfo}] ${currentTaskTemplate.value.name}`;

  nameInfo =
    nameInfo.length > 55 ? `${nameInfo.substring(0, 56)}...` : nameInfo;

  updateDocumentTitle(`${nameInfo} | Task | TicTop`);
};

// Assign
const isOpenChangeAssign = ref(false);

const onOpenChangeAssign = () => {
  if (!hasPermission.value) return;
  isOpenChangeAssign.value = true;
};

const onCancelAssign = () => {
  if (isOpenUpdateDomainModal.value) return;
  isOpenChangeAssign.value = false;
};

const changeAssignPayload = ref({} as any);
const fixConflictDomain = ref({
  domain: false,
  project: false,
  subProject: false,
} as any);

const onChangeAssign = async (group, user, { domain, project, subProject }) => {
  if (!group?.id && !user?.userId) return;

  changeAssignPayload.value = {
    groupId: group?.id,
    assigneeId: user?.userId,
  };
  fixConflictDomain.value = { domain, project, subProject };
  if (domain || project || subProject) {
    onOpenUpdateDomainModal();
    return;
  }
  onChangeAssignee();
};

const onChangeAssignee = () => {
  const isOnlyChangeUser =
    changeAssignPayload.value?.groupId == currentTaskTemplate.value?.groupId &&
    changeAssignPayload.value?.assigneeId !==
      currentTaskTemplate.value?.assigneeId;
  const promiseAll: any[] = [
    currentTaskTemplate.value.changeAssignee(
      changeAssignPayload.value?.groupId,
      changeAssignPayload.value?.assigneeId
    ),
  ];
  if (
    !changeAssignPayload.value?.assigneeId &&
    currentTaskTemplate.value.taskSchedule.doItToday
  ) {
    promiseAll.push(currentTaskTemplate.value.doItToday(false));
  }
  onChangedTaskDetail(Promise.all(promiseAll));

  if (isOnlyChangeUser) onCancelAssign();
};

const allFiles = computed(() => currentTaskTemplate.value?.attachments);

const currentFileIndex = ref(0);
const isPreview = ref(false);

const allAttachments = computed(() => {
  return [
    ...currentTaskTemplate.value?.attachments,
    {
      type: 'text',
      data: currentTaskTemplate.value?.description,
    },
  ];
});

const onOpenFiles = (file) => {
  currentFileIndex.value = allFiles.value.findIndex((o) => o.id == file.id);
  isPreview.value = true;
};

// notes
const filePreview = ref({});
const isOpenMobileNote = ref(false);

const onRemoveNote = async (note) => {
  await currentTaskTemplate.value.removeNote(note);
  if (allFiles.value?.length == 0) isPreview.value = false;
};

const onRemoveAttachment = (file) => {
  if (file.isNote) {
    return onRemoveNote(file);
  } else {
    onRemoveFile(file);
  }
};

const onRemoveFile = (file) => {
  onChangedTaskDetail(currentTaskTemplate.value.removeFile(file));
};

const closePreview = () => {
  isOpenMobileNote.value = false;
};

const hasPermission = computed<boolean>(() => {
  return true;
});

const onDoItToday = async (value) => {
  onChangedTaskDetail(currentTaskTemplate.value.doItToday(value));
};

const onFinishItToday = async (value) => {
  onChangedTaskDetail(currentTaskTemplate.value.finishItToday(value));

  // onUpdateDeadline({
  //   otherTimeKey: null,
  //   scheduleOptionKey: value
  //     ? ScheduleDayOptions.OnDay
  //     : ScheduleDayOptions.Nothing,
  //   scheduleTime: null,
  // });

  // TODO
  // if (
  //   value &&
  //   currentTaskTemplate.value.taskRepeat?.repeatType !== ETaskRepeatType.None
  // )
  //   updateStartDateForRepeat(new Date());
};

const isUpdatingTask = ref(false);
const editingName = ref('');

const onChangedTaskDetail = async (changeMethod: Promise<any>) => {
  isUpdatingTask.value = true;
  await changeMethod;
  isUpdatingTask.value = false;

  return true;
};

const isEditingName = ref<boolean>(false);

const onRenameTask = async () => {
  isEditingName.value = false;

  if (!editingName.value) return;
  await onChangedTaskDetail(
    currentTaskTemplate.value.rename(editingName.value)
  );
};

const onOpenEditName = () => {
  editingName.value = currentTaskTemplate.value?.name;
  isEditingName.value = true;
  isInputComment.value = false;
  onCloseEditDescription();
  onCancelAssign();
};

const onCloseEditName = () => {
  isEditingName.value = false;
};

const onChangeUrgency = (urgency) => {
  onChangedTaskDetail(currentTaskTemplate.value.changeUrgency(urgency));
};

const isNewChat = ref();
const objectDiscussTaskFast = ref({
  contactId: null,
  type: '',
  conversationId: '',
  task: currentTaskTemplate.value,
} as any);

const onOpenSendFast = (obj) => {
  if (!props.isPlaceModal) return;
  _chatStore.setIdConversationActive(obj?.conversationId);
  objectDiscussTaskFast.value.contactId = obj?.contactId;
  objectDiscussTaskFast.value.type = obj?.type;
  objectDiscussTaskFast.value.conversationId = obj?.conversationId;
  objectDiscussTaskFast.value.task = currentTaskTemplate.value;
};

const onChatAboutTask = () => {
  isNewChat.value = false;
  emit('onClose');
};

// Description
const onChangeDescription = (newDes) => {
  let newDescription;
  onSaveComment((data) => (newDescription = data?.content));

  if (typeof newDes == 'string') newDescription = newDes;

  onChangedTaskDetail(
    currentTaskTemplate.value.changeDescription(newDescription)
  );
};

const onOpenEditDescription = () => {
  onOpenComment(currentTaskTemplate.value?.description);

  onCloseEditName();
  onCancelAssign();
};

const onCloseEditDescription = () => {
  onCancelComment();
};

onMounted(() => {
  init();
});

const isOpenUpdateDeadline = ref(false);
const scheduleRef = ref(null as any);

const onSaveDeadline = (deadlineDate) => {
  onChangedTaskDetail(currentTaskTemplate.value.changeDeadline(deadlineDate));
  scheduleRef.value?.onForceClose();
  // TODO
  // if (
  //   currentTaskTemplate.value.taskRepeat?.repeatType !== ETaskRepeatType.None &&
  //   deadlineDate?.scheduleTime
  // )
  //   updateStartDateForRepeat(new Date(deadlineDate?.scheduleTime));
};

const changeDeadlineTime = (data: { dateTime: string; timezone: string }) => {
  scheduleRef.value?.onForceClose();
  onChangedTaskDetail(
    currentTaskTemplate.value.changeDeadlineTime({
      scheduleTime: data?.dateTime,
      scheduleByHour: true,
      scheduleTimezone: data?.timezone,
    })
  );
};

const onRemoveScheduleTime = ({ dateTime }) => {
  onChangedTaskDetail(
    currentTaskTemplate.value.changeDeadlineTime({
      scheduleTime: dateTime,
      scheduleByHour: false,
      scheduleTimezone: '',
    })
  );
};

const onCancelDeadline = () => {
  isOpenUpdateDeadline.value = false;

  scheduleRef.value?.onForceClose();
};

const onOpenEditDomain = () => {
  onCancelAssign();
};

const onSaveDomain = (domainData) => {
  onChangedTaskDetail(currentTaskTemplate.value.changeDomain(domainData));
};

const isChangeState = ref({
  isLoading: false,
  state: currentTaskTemplate.value.taskLife,
});

const onChangeTaskState = async (state: TaskLife) => {
  isChangeState.value.isLoading = true;
  isChangeState.value.state = state;
  await onChangedTaskDetail(currentTaskTemplate.value.changeState(state));
  isChangeState.value.isLoading = false;
};

const onCheckPermission = async () => {
  if (hasPermission.value) return true;
  settingGlobalModal({
    type: 'notification',
    title: '',
    content: translate('COMMON_LABEL_YOU_HAVE_NO_PERMISSION'),
    confirmable: true,
  });
  await ask();
};

const onPasteFiles = async (event) => {
  const hasPermission = await onCheckPermission();
  if (!hasPermission) return;

  if (isPreview.value) {
    return;
  }
  var files = (event.clipboardData || event.originalEvent.clipboardData).files;
  // 1. if image file => open note

  // 2. If not image file => attachment
  if (!files || files?.length == 0) return;

  if (files?.length == 1 && files[0].type.includes('image')) {
    isOpenNote.value = true;
    isPasted.value = true;
    return;
  }

  await onUploadFiles(files);
};

const isDraging = ref(false);

const dragOver = (value) => {
  isDraging.value = value;
};

const onDropImage = async (e) => {
  isDraging.value = false;
  const hasPermission = await onCheckPermission();
  if (!hasPermission) return;
  const files = e.dataTransfer.files;
  await onUploadFiles(files);
};

const getConfigScheduleName = (data) => {
  return _taskStore.getConfigScheduleName(data);
};

const modalMultipleFileViewerRef = ref(null as any);

const onOpenCommentModal = () => {
  modalMultipleFileViewerRef.value?.onOpenEditDescription();
};

const isOpenUpdateDomainModal = ref(false);

const onOpenUpdateDomainModal = () => {
  isOpenUpdateDomainModal.value = true;
  onCancelAssign();
};

const onCloseUpdateDomainModal = () => {
  isOpenUpdateDomainModal.value = false;
  onCancelAssign();
};

const onSaveDomainAfterChangeGroup = async (domainData) => {
  const isOnlyChangeUser =
    changeAssignPayload.value?.groupId == currentTaskTemplate.value?.groupId &&
    changeAssignPayload.value?.assigneeId !==
      currentTaskTemplate.value?.assigneeId;

  const promiseAll = [
    currentTaskTemplate.value.changeDomain(domainData),
    currentTaskTemplate.value.changeAssignee(
      changeAssignPayload.value?.groupId,
      changeAssignPayload.value?.assigneeId
    ),
  ];

  if (
    !changeAssignPayload.value?.assigneeId &&
    currentTaskTemplate.value?.taskSchedule.doItToday
  ) {
    promiseAll.push(currentTaskTemplate.value.doItToday(false));
  }

  await onChangedTaskDetail(Promise.all(promiseAll));

  changeAssignPayload.value = {};
  isOpenUpdateDomainModal.value = false;

  if (isOnlyChangeUser) onCloseUpdateDomainModal();
};

const isHasJustCreatedFile = (file) => {
  const createTime = new Date(file?.creationTime).getTime();
  const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const overTime = Date.now() + timeOffset - createTime;
  return overTime < 15 * 60 * 1000;
};

const onSaveCreateNote = async (noteData) => {
  await onFetchCreateNote(noteData);
};

const onSaveUpdateNote = async (noteData) => {
  await onFetchUpdateNote(noteData);
};

const onSaveRecorder = async (recordings) => {
  await onCreateAudioDone(recordings);
};
// ACTIVITY

const taskDetailRef = ref(null as any);

document.getElementById('tagOptionActivity')?.addEventListener('scroll', () => {
  console.log('scroll');
});

const getTaskTemplate = () => {
  return {
    ...currentTaskTemplate.value,
  };
};

const onChangePin = async (pin) => {
  currentTaskTemplate.value.changePin(pin);
};
const onUnpin = async () => {
  currentTaskTemplate.value.unpin();
};

const taskTodoListAllRef = ref<any>();
const onAddTodoList = () => {
  console.log(
    '🚀 Tictop ~ taskTodoListAllRef.value:',
    taskTodoListAllRef.value
  );
  taskTodoListAllRef.value?.onClickNewTab();
};

const onSaveTodoList = (multipleTodoList) => {
  currentTaskTemplate.value.todoList = multipleTodoList;
};

const isEditCollaborator = ref<boolean>(false);

const onChangeCollaborator = async (collaborators) => {
  const payload = collaborators?.map((user) => {
    return {
      ...user,
      memberUserId: user?.id || user?.userId,
    };
  });
  currentTaskTemplate.value.addCollaboratorsInTask(payload);
};
const onRemoveCollaborator = async (userId) => {
  currentTaskTemplate.value.deleteCollaboratorsInTask(userId);
};

const manageCollaboratorRef = ref<any>(null);
const onCloseEditCollaborator = () => {
  manageCollaboratorRef.value?.onCloseEditMode();
};
const taskDataConfigScheduleDayOptions = computed<IConfigType[]>(() => {
  return (
    _taskStore.dataConfigs?.scheduleDayOptions
      ?.filter((o) => o?.active && o.key !== ScheduleDayOptions.OtherTime)
      ?.map((o) => {
        let customText = '';
        switch (o?.key) {
          case ScheduleDayOptions.OnDay:
            customText = translate(`COMMON_LABEL_DEADLINE_SAME_DAY`);
            break;
          case ScheduleDayOptions.D1:
          case ScheduleDayOptions.D2:
          case ScheduleDayOptions.D3:
            customText = translate(`COMMON_LABEL_DEADLINE_AFTER_DAY`, {
              totalDays: o?.key || '',
              suffix: o?.key > 1 ? 's' : '',
            });
            break;

          default:
            customText = o.text;
            break;
        }
        return {
          ...o,
          text: customText,
        };
      }) || []
  );
});
const currentSchedule = computed<IConfigType>(() => {
  return taskDataConfigScheduleDayOptions.value?.find(
    (o) => o.key == currentTaskTemplate.value?.taskSchedule?.scheduleOptionKey
  );
});
const canChangeDeadline = computed<boolean>(() => {
  return currentTaskTemplate.value?.canTaskChangeDeadline(true);
});

const onToggleLockDeadline = (value?) => {
  currentTaskTemplate.value.toggleLockDeadline(value);
};

const onSelectActionOnTask = (actionKey) => {
  switch (actionKey) {
    case EActionOnTask.LOCK_UPDATE_DEADLINE:
      onToggleLockDeadline();
      break;

    default:
      break;
  }
};

const doNotNeedCheckSubtaskPermission = ref<boolean>(false);

const ignoreCheckSubtaskPermission = () => {
  doNotNeedCheckSubtaskPermission.value = true;
};

const updateSubTasks = (newSubTasks) => {
  currentTaskTemplate.value.subTasks = newSubTasks
    ?.filter((t) => t?.isSelecting)
    ?.map((t) => t?.id);
};

defineExpose({
  getTaskTemplate,
});
</script>

<template>
  <!-- HAVE TASK   -->
  <div
    v-if="currentTaskTemplate?.id"
    v-drop-zone="{ isDragging: dragOver, onDrop: onDropImage }"
    class="
      w-full
      flex-1
      min-h-0
      flex flex-col
      text-sm
      bg-white
      relative
      rounded-t
    "
    style="width: 40rem"
    @paste="onPasteFiles"
    @click="onCloseEditCollaborator"
  >
    <div
      v-if="isLoadingDataTask?.detail"
      class="absolute w-full z-50 h-full flex-center bg-gray-50 bg-opacity-30"
    >
      <syn-icon
        custom-class="animate-spin w-4 h-4 fill-current"
        name="spinner"
      />
    </div>

    <UploadDropZone
      v-if="isDraging"
      class="absolute w-full top-0 left-0 right-0 bottom-0 z-20"
    />

    <section
      id="tagOptionActivity"
      ref="taskDetailRef"
      v-perfect-scrollbar
      class="
        flex-1
        min-h-0
        flex flex-col
        space-y-2.5
        relative
        overflow-y-auto
        small-scrollbar
      "
    >
      <div
        class="bg-orange-100 w-full text-orange-500 text-sm px-2 py-1 rounded-t"
        v-html="
          $t('TASK_LABEL_REPEAT_CHANGE_TEMPLATE_WARNING', {
            repeatContent: $t('TASK_LABEL_REPEAT_TEMPLATE'),
          })
        "
      ></div>
      <!-- HEADER ACTION -->
      <section
        class="
          w-full
          flex
          items-center
          justify-between
          py-2
          px-4
          border-b border-gray-100
        "
      >
        <div class="flex-center space-x-3 text-xs"></div>

        <!-- STEPPER STATE -->
        <section
          class="flex-1"
          :class="
            [TaskLife.Todo, TaskLife.InProcess, TaskLife.Finished]?.includes(
              currentTaskTemplate?.taskLife
            )
              ? 'flex-center'
              : 'ml-2'
          "
        >
          <TaskStateStepper
            :task-detail="currentTaskTemplate"
            :is-loading="isLoadingInit"
            :object-loading="isChangeState"
            :readonly="!hasPermission"
            @on-change-state="onChangeTaskState"
          />
        </section>

        <!-- OPTION -->
        <div class="flex items-center space-x-1">
          <template v-if="isLoadingInit">
            <div
              v-for="item in 3"
              :key="item"
              class="w-8 h-8 rounded bg-gray-100 animate-pulse"
            ></div>
          </template>
          <template v-else>
            <SetPinTask
              :current-pin-code="currentTaskTemplate?.pinnedColor"
              @on-change-pin="onChangePin"
              @on-unpin="onUnpin"
            />
            <ActionOnTask
              :current-task="currentTaskTemplate"
              :readonly="!hasPermission || !canChangeDeadline"
              is-hidden-remove
              is-hidden-tool
              :action-list="[EActionOnTask.LOCK_UPDATE_DEADLINE]"
              @select-action="onSelectActionOnTask"
            />
          </template>
        </div>
      </section>
      <section
        v-if="currentTaskTemplate?.parentId"
        class="w-full items-center px-4 inline-flex text-sm gap-2"
      >
        <p
          class="inline truncate italic text-current"
          v-html="
            $t('TASK_REPEAT_LABEL_REPEAT_IN_PARENT', {
              parentName: currentTaskTemplate?.parentName,
            })
          "
        ></p>
      </section>
      <!-- NAME TASK -->
      <section
        class="flex items-start space-x-2 justify-between text-xl px-2 pb-2"
      >
        <div
          v-if="isEditingName"
          class="flex-1 flex-center flex-col space-x-2 relative z-20"
        >
          <SynInputArea
            v-model="editingName"
            :disabled-enter-warning="
              $t('TASK_NAME_SHOULD_BE_WRITTEN_SEAMLESSLY') ||
              'The task name should be written seamlessly'
            "
            autofocus
            max-length="200"
          />
          <div
            class="w-full flex items-center justify-end space-x-1 text-xs px-2"
          >
            <SynButton
              :label="$t('COMMON_LABEL_CLOSE') || 'Close'"
              type-text
              color="gray"
              custom-class="px-2 py-1 rounded"
              @click.stop="onCloseEditName"
            />
            <SynButton
              :label="$t('COMMON_LABEL_SAVE') || 'Save'"
              custom-class="px-2 py-1 rounded"
              :disabled="!editingName"
              @click.stop="onRenameTask"
            />
          </div>
        </div>
        <div
          v-else
          class="
            flex-1 flex
            items-center
            space-x-2
            px-2
            py-2
            rounded
            cursor-text
            w-2/3
            hover:bg-gray-100
            hover-to-show__parent
            text-gray-800
          "
        >
          <span
            v-encode-html="{ text: currentTaskTemplate?.name }"
            class="break-words font-medium"
          >
          </span>
          <SynIcon
            v-if="hasPermission"
            name="edit"
            is-active
            class="
              hover-to-show__children
              fill-current
              cursor-pointer
              absolute
              hover:fill-current-800
              top-2
              right-1
            "
            :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
            @click.stop="onOpenEditName"
          />
        </div>
      </section>

      <!-- CREATOR +  ASSIGNMENT -->
      <section
        class="px-4 pt-2 pb-3 flex items-center gap-2 flex-wrap"
        :class="isOpenChangeAssign ? 'w-full' : ''"
      >
        <div class="flex items-center">
          <TaskDetailField :style="!isOpenChangeAssign ? '' : 'flex: 30%;'">
            <template #fieldName>
              <span>{{ $t('TASK_TABLE_LABEL_REPORTER') }}</span>
            </template>
            <template #body>
              <div class="flex text-xs h-full items-center space-x-1 py-2 px-2">
                <UserByAction
                  v-if="currentTaskTemplate.creatorId"
                  :user-id="currentTaskTemplate.creatorId"
                  is-show-action-user
                  is-show-first-name
                  avatar-class="w-6 h-6 text-xs font-medium"
                />
                <span class="text-2xs text-overflow-hidden-line font-medium">
                  (<SynLabelDateTime
                    format="date_time"
                    ignore-timezone
                    :datetime="currentTaskTemplate.creationTime"
                  />)
                </span>
              </div>
            </template>
          </TaskDetailField>
          <div
            class="flex-center"
            :class="isOpenChangeAssign ? 'flex-1' : 'w-16'"
          >
            <div
              class="
                flex-1 flex-center
                border-t border-current-50
                items-center
                relative
              "
            ></div>
          </div>
          <!-- ASSIGNEE -->
          <TaskDetailField
            :is-editable="hasPermission"
            :is-focus="isOpenChangeAssign"
            :style="!isOpenChangeAssign ? '' : 'flex: 70%;'"
          >
            <template #fieldName>
              <span>{{ $t('TASK_LABEL_ASSIGNEE') }}</span>
            </template>
            <template #body>
              <div
                v-click-outside="onCancelAssign"
                style="max-width: 26rem; min-width: 10rem"
              >
                <ChangeAssign
                  v-if="isOpenChangeAssign"
                  v-permission="'FunctionTaskAssign'"
                  class="text-xs"
                  is-update-instant
                  :group-id="currentTaskTemplate.groupId || 0"
                  :user-id="currentTaskTemplate.assigneeId || 0"
                  :domain-id="currentTaskTemplate.domainId || 0"
                  :project-id="currentTaskTemplate.projectId || 0"
                  :subproject-id="currentTaskTemplate.subprojectId || 0"
                  @on-cancel="onCancelAssign"
                  @on-save="onChangeAssign"
                  @focus="onCloseEditCollaborator"
                />
                <div
                  v-else
                  class="
                    flex-center
                    space-x-1
                    relative
                    rounded
                    text-xs
                    font-medium
                    px-2
                    py-2
                  "
                  :class="
                    hasPermission
                      ? 'hover:bg-current-50 hover:bg-opacity-60 cursor-pointer'
                      : ''
                  "
                  @click.stop="onOpenChangeAssign"
                >
                  <div
                    v-if="currentTaskTemplate?.groupId"
                    class=""
                    :style="
                      currentTaskTemplate?.assigneeId
                        ? 'flex: 50%'
                        : 'flex: 100%'
                    "
                  >
                    <GroupById
                      :group-id="currentTaskTemplate?.groupId"
                      avatar-class="w-6 h-6 text-xs"
                    />
                  </div>
                  <span
                    v-if="
                      currentTaskTemplate?.assigneeId &&
                      currentTaskTemplate?.groupId
                    "
                    class="px-2 text-gray-500"
                    >→</span
                  >
                  <div
                    v-if="currentTaskTemplate?.assigneeId"
                    class=""
                    :style="
                      currentTaskTemplate?.groupId ? 'flex: 50%' : 'flex: 100%'
                    "
                  >
                    <UserById
                      is-show-first-name
                      avatar-class="w-6 h-6 text-xs"
                      :user-id="currentTaskTemplate?.assigneeId"
                    />
                  </div>
                </div>
              </div>
            </template>
          </TaskDetailField>
        </div>

        <!-- COLLABORATOR -->
        <template
          v-if="
            multipleAssigneeSetting?.systemEnable &&
            multipleAssigneeSetting?.organizationEnable &&
            !currentTaskTemplate?.isPrivate
          "
        >
          <TaskDetailField>
            <template #fieldName>
              <span>{{ $t('COMMON_LABEL_COLLABORATORS') }}</span>
            </template>
            <template #body>
              <div class="px-2">
                <ManageCollaborator
                  ref="manageCollaboratorRef"
                  v-model:is-edit-mode="isEditCollaborator"
                  class="flex-1"
                  :group-id="currentTaskTemplate.groupId || 0"
                  :assignee-id="currentTaskTemplate.assigneeId || 0"
                  :collaborators="currentTaskTemplate.collaborators"
                  @on-add="onChangeCollaborator"
                  @on-remove="onRemoveCollaborator"
                />
              </div>
            </template>
          </TaskDetailField>
        </template>
      </section>

      <!-- DOMAIN  -->
      <div class="w-full px-4 flex items-center space-x-6">
        <span class="text-xs w-20 text-gray-500 flex items-center h-8">{{
          $t('DOMAIN_LABEL_TITLE')
        }}</span>
        <div class="flex items-center space-x-1">
          <div class="font-medium flex space-x-1 text-xs">
            <UpdateDomainDropdown
              v-if="hasPermission"
              :task-detail="currentTaskTemplate"
              @on-open="onOpenEditDomain"
              @on-save="onSaveDomain"
            />
            <DomainProjectById
              v-else
              :domain-id="currentTaskTemplate.domainId"
              :project-id="currentTaskTemplate.projectId"
              :sub-project-id="currentTaskTemplate.subprojectId"
            />
          </div>
        </div>
      </div>

      <!--  PLAN TODAY -->
      <div
        v-if="
          currentTaskTemplate.assigneeId &&
          currentTaskTemplate.taskLife !== TaskLife.Finished
        "
        class="w-full px-4 flex items-center space-x-6"
      >
        <span class="text-xs w-20 text-gray-500 flex items-center h-8">{{
          $t('TASK_FILTER_LABEL_PLANNED')
        }}</span>
        <div class="flex flex-col">
          <div class="flex items-center rounded-md px-1 space-x-4 py-1">
            <div class="flex items-center space-x-2">
              <AtomSwitch
                v-model="currentTaskTemplate.taskSchedule.doItToday"
                :disabled="!hasPermission"
                :is-loading="isUpdatingTask"
                @change="onDoItToday"
              >
                <span class="text-current-800 text-xs">
                  {{ $t('TASK_CREATE_FORM_DOING_TODAY') || 'Do today' }}
                </span>
              </AtomSwitch>
            </div>
            <div class="flex items-center space-x-2">
              <AtomSwitch
                v-model="currentTaskTemplate.taskSchedule.finishItToday"
                :disabled="!hasPermission"
                :is-loading="isUpdatingTask"
                @change="onFinishItToday"
              >
                <span class="text-current-800 text-xs">
                  {{ $t('TASK_CREATE_FORM_FINISH_TODAY') || 'Finish today' }}
                </span>
              </AtomSwitch>
            </div>
          </div>
        </div>
      </div>

      <!--  DEADLINE -->
      <div class="w-full px-4 flex items-center space-x-6">
        <div class="w-20 flex items-center gap-2">
          <span class="text-xs text-gray-500">{{
            $t('TASK_TABLE_LABEL_DEADLINE')
          }}</span>
          <TaskDeadlineBlockingShortInfo
            v-if="currentTaskTemplate?.scheduleTimeLocked"
            :readonly="!canChangeDeadline"
            @on-cancel-block-deadline="onToggleLockDeadline(false)"
          />
        </div>
        <div class="flex-center gap-2">
          <VigDropdown
            v-if="hasPermission"
            ref="scheduleRef"
            :arrow="false"
            :hide-on-click="'outside'"
            @on-dropdown-open="isOpenUpdateDeadline = true"
            @on-dropdown-close="isOpenUpdateDeadline = false"
          >
            <template #dropdown-toggle>
              <SynTag
                size="small"
                custom-class="py-1 px-2 hover:bg-current-50 rounded cursor-pointer text-green-500"
              >
                <span>{{ currentSchedule?.text }}</span>
              </SynTag>
            </template>
            <template #dropdown-menu>
              <div class="w-96 flex flex-col py-4 px-3">
                <span class="text-sm font-medium text-gray-700 pb-3">{{
                  $t('TASK_TABLE_LABEL_DEADLINE')
                }}</span>

                <DeadlineInput
                  v-if="isOpenUpdateDeadline"
                  :schedule-key="
                    currentTaskTemplate?.taskSchedule?.scheduleOptionKey
                  "
                  :other-time="currentTaskTemplate.taskSchedule.otherTime"
                  :schedule="currentTaskTemplate.scheduleTime"
                  :user-id="currentTaskTemplate.assigneeId"
                  :group-id="currentTaskTemplate.groupId"
                  is-hidden-exact-date
                  view-mode="EDIT"
                  :start-time="currentTaskTemplate.startTime"
                  is-hidden-start-time
                  @cancel="onCancelDeadline"
                  @save="onSaveDeadline"
                />
              </div>
            </template>
          </VigDropdown>
          <div
            v-else
            :class="[
              getConfigScheduleColor(currentTaskTemplate?.taskSchedule),
              hasPermission ? 'cursor-pointer' : '',
            ]"
            class="rounded text-xs px-1 py-0.5"
            style="min-width: 8rem"
          >
            {{
              currentTaskTemplate.assigneeId
                ? getConfigScheduleName(currentTaskTemplate)
                : $filters.dayjs(currentTaskTemplate.scheduleTime) ||
                  $t('TASK_DETAIL_LABEL_NO_SCHEDULE') ||
                  'No schedule'
            }}
          </div>
          <UpdateDeadlineTime
            v-if="
              currentTaskTemplate?.taskSchedule?.scheduleOptionKey !==
              ScheduleDayOptions.Nothing
            "
            :schedule-time="currentTaskTemplate?.scheduleTime"
            :schedule-by-hour="currentTaskTemplate?.scheduleByHour"
            :schedule-timezone="currentTaskTemplate?.scheduleTimezone"
            :my-timezone="myTimezone"
            @on-change="changeDeadlineTime"
            @on-remove-time="onRemoveScheduleTime"
          />
        </div>
      </div>

      <!-- URGENCY + STATE  -->
      <div class="w-full px-4 flex items-center space-x-6">
        <span class="text-xs w-20 text-gray-500 flex items-center h-8">{{
          $t('TASK_TABLE_LABEL_URGENCY') || 'Urgency'
        }}</span>
        <div class="flex items-center space-x-6">
          <div class="flex" style="width: 9.5rem">
            <vig-dropdown v-if="hasPermission" :arrow="false">
              <template #dropdown-toggle>
                <div
                  class="
                    flex
                    font-medium
                    items-center
                    space-x-2
                    hover:bg-current-50
                    cursor-pointer
                    p-1
                    rounded
                    hover-to-show__parent
                  "
                  style="min-width: 8rem"
                >
                  <PriorityTask
                    size="mini"
                    :urgency="currentTaskTemplate.urgency"
                  />
                </div>
              </template>
              <template #dropdown-menu>
                <div
                  class="
                    w-50
                    py-1
                    z-100
                    list-ul
                    rounded
                    cursor-pointer
                    dropdown-item
                  "
                >
                  <div
                    v-for="(item, index) in [4, 3, 2, 1]"
                    :key="index"
                    class="list-li flex text-left justify-start pr-2"
                    :class="
                      currentTaskTemplate.urgency == item ? 'bg-gray-100' : ''
                    "
                    @click="onChangeUrgency(item)"
                  >
                    <PriorityTask :urgency="item" size="mini" />
                  </div>
                </div>
              </template>
            </vig-dropdown>
            <PriorityTask
              v-else
              style="min-width: 8rem"
              :urgency="currentTaskTemplate.urgency"
              size="mini"
            />
          </div>
          <!-- <TaskState
            custom-class="py-1 space-x-2 text-xs"
            style="min-width: 8rem"
            :task-life="currentTaskTemplate?.taskLife"
            :readonly="!hasPermission"
            @change-state="onChangeTaskState"
          /> -->
        </div>
      </div>
      <!-- DESCRIPTION -->
      <div class="w-full px-4 flex items-start">
        <span class="text-xs w-28 text-gray-500 flex items-center h-8">
          {{ $t('COMMON_LABEL_DESCRIPTION') || 'Description' }}
        </span>
        <div
          class="flex-1 flex-center space-x-4"
          style="min-width: 0; max-width: 100%"
        >
          <div v-if="isInputComment" ref="descriptionInputRef" class="relative">
            <TaskDescriptionInput
              ref="taskDescriptionRef"
              v-model="commentObjectCreate.content"
              v-model:isValid="myCommentValid"
              :current-task="currentTaskTemplate"
              @on-focus="onFocus"
              @on-close="onCloseEditDescription"
              @on-save="onChangeDescription"
            />
          </div>
          <div
            v-else
            class="
              whitespace-pre-wrap
              rounded
              border border-transparent
              hover:border-gray-300
              inline-block
              w-full
              hover-to-show__parent
              leading-6
            "
            :class="[
              hasPermission && !currentTaskTemplate.description
                ? 'cursor-pointer'
                : 'cursor-text',
              currentTaskTemplate.description ? 'p-1 pl-1' : 'px-2 py-1',
            ]"
            :style="currentTaskTemplate.description ? 'padding-top: 12px' : ''"
            @click.stop="
              hasPermission &&
                !currentTaskTemplate.description &&
                onOpenEditDescription()
            "
          >
            <span
              class="task-detail_description break-words text-gray-500"
              v-html="
                currentTaskTemplate.description ||
                $t('COMMON_LABEL_PLACEHOLDER_ADD_DESCRIPTION') ||
                'Add description ...'
              "
            ></span>
            <SynIcon
              v-if="hasPermission"
              name="edit"
              is-active
              class="
                hover-to-show__children
                absolute
                top-1
                right-1
                fill-current
                cursor-pointer
                hover:fill-current-800
              "
              :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
              @click.stop="onOpenEditDescription"
            />
          </div>
        </div>
      </div>

      <!-- SUB TASK -->
      <div
        v-if="
          currentTaskTemplate?.subTasksDetail &&
          currentTaskTemplate?.subTasksDetail?.length > 0
        "
        v-permission-function="{
          functionCode: doNotNeedCheckSubtaskPermission
            ? ''
            : ALL_FUNCTIONS.TASK.SUB_TASK,
          onContinue: () => {
            ignoreCheckSubtaskPermission();
          },
        }"
        v-system-config="[
          {
            functionCode: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED',
            functionValue: '1',
          },
          {
            functionCode: 'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM',
            functionValue: '1',
          },
        ]"
        class="w-full px-4 flex flex-col"
      >
        <div class="flex items-center gap-2">
          <span class="text-xs text-gray-500 flex items-center h-8">
            {{ $t('COMMON_LABEL_SUB_TASK') || 'Sub tasks' }}
          </span>
          <AtomHelpDropdown
            title="TASK_REPEAT_LABEL_SUB_TASKS_TITLE"
            content="TASK_LABEL_SELECT_THE_SUB_TASK_TO_BE_DUPLICATED_CONTENT"
          />
        </div>
        <div
          class="flex-1 flex-center space-x-4"
          style="min-width: 0; max-width: 100%"
        >
          <!-- <SubTaskList
            :parent-task="{
              ...currentTaskTemplate,
              subTasks: currentTaskTemplate?.subTasksDetail?.map((t) => {
                return {
                  ...t,
                  taskLife: TaskLife.Finished,
                };
              }),
            }"
            :domain-id="currentTaskTemplate?.domainId"
            readonly
            is-select-mode
            @go-to-task="goToTask"
            @update:sub-tasks="updateSubTasks"
          /> -->
          <TaskMultipleAssignee
            v-model="currentTaskTemplate.subTasksDetail"
            :form-data="currentTaskTemplate"
            is-sub-tasks
            @update:model-value="updateSubTasks"
          />
        </div>
      </div>

      <!-- FILES -->
      <section class="px-4 pt-2">
        <div class="w-full flex items-center space-x-2">
          <span
            class="text-sm flex font-medium items-center"
            style="color: #128f90"
          >
            {{
              `${$t('TASK_DETAIL_LABEL_ATTACHMENT') || 'Attachments'} ${
                totalAttachmentFiles > 0 ? '(' + totalAttachmentFiles + ')' : ''
              }`
            }}
          </span>
          <div
            v-if="hasPermission && !totalAttachmentFiles"
            class="flex-center space-x-2"
          >
            <GroupAttachmentAction
              :has-permission="hasPermission"
              :input-file-id="taskTemplateFileInputId"
              @handle-click-note="onOpenCreateNote"
              @handle-click-audio="isOpenRecording = true"
              @on-add-todo-list="onAddTodoList"
            />
          </div>
        </div>
        <div
          v-if="isLoadingDataTask?.files"
          class="w-20 h-16 rounded-md flex-center bg-gray-200 bg-opacity-30"
        >
          <syn-icon
            custom-class="animate-spin w-4 h-4 fill-current"
            name="spinner"
          />
        </div>
        <div
          v-if="totalAttachmentFiles > 0 || uploadingFiles?.length > 0"
          class="w-full items-center flex flex-wrap pt-3 pb-1"
        >
          <div
            v-if="isAttachmentLoading"
            class="flex flex-wrap space-x-2 mr-2 mb-2"
          >
            <div
              v-for="item in totalAttachmentFiles"
              :key="item"
              class="w-20 h-16 animate-pulse bg-gray-100 rounded-md shadow-lg"
            ></div>
          </div>
          <div
            v-for="(file, index) in currentTaskTemplate?.attachments"
            :key="index"
            class="relative w-max h-16 mr-2 mb-2 hover-to-hidden__parent"
          >
            <UploadPreview
              class="m-0"
              :file="file"
              :readonly="!hasPermission"
              :is-note="file.isNote"
              is-hidden-info
              @on-select="onOpenFiles(file)"
              @remove="file.isNote ? onRemoveNote(file) : onRemoveFile(file)"
            />
            <div
              v-if="isHasJustCreatedFile(file)"
              class="
                new-sticker
                absolute
                top-3
                -left-0
                rounded
                w-max
                text-white
                px-1
                bg-current
                -rotate-45
                hover-to-hidden__children
              "
              style="font-size: 0.6rem"
            >
              {{ $t('COMMON_LABEL_NEW') }}
            </div>
          </div>
          <template v-if="uploadingFiles.length > 0">
            <AttachmentUploading
              :uploading-files="uploadingFiles"
              :upload-error="uploadError"
              class="mr-2 mb-2"
              @on-select-note="onOpenCreateNote"
            />
          </template>

          <AttachmentGroupButton
            v-if="hasPermission"
            :input-file-id="taskTemplateFileInputId"
            @handle-click-note="onOpenCreateNote"
            @handle-click-audio="isOpenRecording = true"
            @handle-click-comment="onOpenEditDescription"
            @on-add-todo-list="onAddTodoList"
          />
        </div>
      </section>

      <section class="px-4">
        <TaskTodoListCreateNew
          ref="taskTodoListAllRef"
          v-model:task-todos="currentTaskTemplate.todoList"
          hidden-footer
          @on-save="onSaveTodoList"
        />
      </section>
    </section>

    <!-- END -->
  </div>
  <!--  </template>-->

  <input
    v-if="hasPermission"
    :id="taskTemplateFileInputId"
    type="file"
    multiple
    class="hidden opacity-0"
    :accept="webTaskUploadContentTypes"
    @change="onChooseFiles"
  />

  <ModalMultipleFileViewer
    v-if="isOpenMobileNote"
    :file="filePreview"
    @on-close="closePreview"
  />

  <ModalMultipleFileViewer
    v-if="isPreview && allAttachments?.length > 0"
    ref="modalMultipleFileViewerRef"
    :start-index="currentFileIndex"
    :files="allAttachments"
    :editable="hasPermission"
    @on-close="isPreview = false"
    @on-save-note="onSaveUpdateNote"
    @on-save-description="onChangeDescription"
    @on-remove="onRemoveAttachment"
  >
    <template #title>
      <span>
        {{ formatTaskCode(currentTaskTemplate?.code) }} -
        {{ currentTaskTemplate?.name }}
      </span>
    </template>
    <template #footer-action>
      <template v-if="uploadingFiles.length > 0">
        <AttachmentUploading
          :uploading-files="uploadingFiles"
          :upload-error="uploadError"
        />
      </template>
      <AttachmentGroupButton
        v-if="hasPermission"
        class="flex-none bg-opacity-50"
        :input-file-id="taskTemplateFileInputId"
        @handle-click-note="onOpenCreateNote"
        @handle-click-audio="isOpenRecording = true"
        @handle-click-comment="onOpenCommentModal"
      />
    </template>
  </ModalMultipleFileViewer>

  <CreateNoteModal
    v-if="isOpenNote"
    :draw-note-data="drawNoteData"
    @on-close="isOpenNote = false"
    @on-save="onSaveCreateNote"
  />

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onSaveRecorder"
    @on-cancel="isOpenRecording = false"
  />

  <ModalNewChat
    v-if="isNewChat"
    :title="
      $t('COMMON_LABEL_DISCUSS_ABOUT_WITH') || 'Discuss about this task with'
    "
    :attached-task="currentTaskTemplate"
    :is-block-bubble-chat="isPlaceModal"
    @on-close="isNewChat = false"
    @on-conversation-click="onChatAboutTask"
    @open-send-fast="onOpenSendFast"
  />

  <ModalDiscussChatTask
    v-if="
      isPlaceModal &&
      objectDiscussTaskFast?.contactId &&
      objectDiscussTaskFast?.type
    "
    :user-id="
      objectDiscussTaskFast?.contactId && objectDiscussTaskFast?.type === 'USER'
        ? objectDiscussTaskFast?.contactId
        : null
    "
    :group-id="
      objectDiscussTaskFast?.contactId &&
      objectDiscussTaskFast?.type === 'GROUP'
        ? objectDiscussTaskFast?.contactId
        : null
    "
    :current-task="objectDiscussTaskFast?.task"
    :conversation-id="objectDiscussTaskFast?.conversationId"
    @on-close="
      objectDiscussTaskFast.contactId = null;
      objectDiscussTaskFast.type = '';
    "
  />

  <UpdateDomainModal
    v-if="isOpenUpdateDomainModal"
    :task-detail="{
      ...currentTaskTemplate,
      assigneeId: changeAssignPayload.assigneeId,
      groupId: changeAssignPayload.groupId,
      domainId: fixConflictDomain.domain ? null : currentTaskTemplate.domainId,
      projectId:
        fixConflictDomain.domain || fixConflictDomain.project
          ? null
          : currentTaskTemplate.projectId,
      subprojectId:
        fixConflictDomain.domain ||
        fixConflictDomain.project ||
        fixConflictDomain.subProject
          ? null
          : currentTaskTemplate.subprojectId,
    }"
    :is-updated="isUpdatingTask"
    @cancel="onCloseUpdateDomainModal"
    @save="onSaveDomainAfterChangeGroup"
  />
</template>

<style lang="scss">
.new-sticker {
  transform: perspective(10px) rotateX(1deg);
}

.task-detail_description {
  a {
    @apply text-current-500;
  }

  a:hover {
    @apply underline;
  }
}

#tagOptionActivity:not([data-scroll='0']) {
  background-color: transparent !important;
}
</style>
