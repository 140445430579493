<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';
import { ignoreUnicode } from '@/ui/plugins/utils';
import overviewDomainStore from '@/store/dashboard/overview-domains-store';
import { taskListRouteByType } from '@/ui/hooks/commonHook';
import { markBackgroundTextSearch } from '@/ui/hooks/commonFunction';
import ActionById from '@/ui/components/user/ActionById.vue';
import DomainDetailModal from '@/ui/modules/domains/domain/DomainDetailModal.vue';
import DomainCreateModal from '@/ui/modules/domains/domain/DomainCreateModal.vue';
import DomainDeleteModal from '@/ui/modules/domains/domain/DomainDeleteModal.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import SynDropdownItem from '@/ui/common/atoms/SynDropdown/SynDropdownItem.vue';
import SynListAvatar from '@/ui/common/molecules/SynAvatar/SynListAvatar.vue';
import myProfile from '@/store/auth/my-profile';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { ILatestTaskActivity } from '@/ui/plugins/firebases/realtime-database/types/organization-dynamic-model.types';
import settingStore from '@/store/setting';
import localConfigsStore from '@/store/local-configs';

defineEmits([
  'openTaskDetail',
  'openCreateTaskForUser',
  'onAdd',
  'onCreateTaskForGroup',
]);

const router = useRouter();
const _overviewDomainStore = overviewDomainStore();
const _myProfile = myProfile();
const _settingStore = settingStore();
const _localConfigsStore = localConfigsStore();

const overviewDomainFullDetail = computed(
  () => _overviewDomainStore.overviewDomainFullDetail
);
const isLoadingOverviewDomain = computed(
  () => _overviewDomainStore.isLoadingOverviewDomain
);
const isOwner = computed(() => _myProfile.isOwner);

const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const searchText = computed(() => _overviewDomainStore.keySearchDomain);
const isCreateDomain = computed(() => _overviewDomainStore.isCreateDomain);
const filteredDomains = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  const overviewDomainInfos: any[] = overviewDomainFullDetail.value || [];

  if (!searchTxt || overviewDomainInfos?.length < 1) return overviewDomainInfos;

  return overviewDomainInfos.reduce((arr, domain) => {
    const isMatchName = !!(
      domain.name && ignoreUnicode(domain.name).includes(searchTxt)
    );
    const isMatchUser = (domain.members || []).filter(
      (domainName) =>
        domainName.fullName &&
        ignoreUnicode(domainName.fullName).startsWith(searchTxt)
    );
    const isMatchGroup = (domain.groups || []).filter(
      (group) => group?.name && ignoreUnicode(group?.name).startsWith(searchTxt)
    );

    if (isMatchName || isMatchGroup?.length > 0 || isMatchUser?.length > 0) {
      domain.isMatchName = isMatchName;
      domain.textMatchName = isMatchName ? searchTxt : '';
      domain.arrayMatchUser = isMatchUser?.length > 0 ? isMatchUser : [];
      domain.arrayMatchGroup = isMatchGroup?.length > 0 ? isMatchGroup : [];
      arr.push(domain);
    }
    return arr;
  }, []);
});

const currentDomainDetailId = ref({
  id: null,
  tab: '',
} as any);
const isDeleteDomain = ref(null as any);
const domainListRef = ref(null as any);

let { unsubscribeCallbacks } = listenFirebaseRealtime();
onMounted(() => {
  initData();

  const LatestTaskActivityFirebaseModel = new OrganizationDynamicModel(
    `latestTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    LatestTaskActivityFirebaseModel.onChange((data: ILatestTaskActivity) => {
      if (Date.now() - data?.actionTime > 5 * 1000) return;

      _overviewDomainStore.updateDomainTasksRealtime(
        data?.action,
        data.taskDetail
      );
    })
  );
});

const initData = async () => {
  await _overviewDomainStore.getOverviewDomainFullDetail();
};

const chooseContact = (contactId, type: any = 'DOMAIN') => {
  if (!contactId) return;
  router.replace(taskListRouteByType(type, contactId));
};

const onGotoResource = () => {
  router.replace('/resources');
};

const onGotoManagement = () => {
  router.replace('/project-management');
};

const onEditDomain = (domainId, tab) => {
  currentDomainDetailId.value.id = domainId;
  currentDomainDetailId.value.tab = tab;
};

const onDeleteDomain = (domainId) => {
  isDeleteDomain.value = domainId;
};

let timeNewDomain;

const onCreateSuccessDomain = async (domain) => {
  isDeleteDomain.value = null;

  await _overviewDomainStore.getOverviewDomainFullDetail(false);
  _overviewDomainStore.setValueCreateDomain(false);

  if (!domain?.id) return;

  await nextTick();
  const elementNewDomain = document.getElementById(
    `listDomain-item-${domain?.id}`
  );

  domainListRef.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
  if (!elementNewDomain) return;

  elementNewDomain?.classList?.add('block-focus');

  if (timeNewDomain) clearTimeout(timeNewDomain);

  timeNewDomain = setTimeout(() => {
    elementNewDomain?.classList?.remove('block-focus');
  }, 2500);
};

const onOpenCreateModal = () => {
  _overviewDomainStore.setValueCreateDomain(true);
};

const onCloseCreateDomain = () => {
  _overviewDomainStore.setValueCreateDomain(false);
};

const onUpdateInformation = () => {
  _overviewDomainStore.getOverviewDomainFullDetail(false);
  currentDomainDetailId.value.id = null;
  currentDomainDetailId.value.tab = '';
};

const setDefault = () => {
  _localConfigsStore.setLocalConfigs('domain', {
    overviewDomainViewDefault: 'BOARD',
  });
};

const isViewDefault = computed<boolean>(() => {
  return (
    _localConfigsStore.localConfigs?.domain?.overviewDomainViewDefault ==
    'BOARD'
  );
});
</script>

<template>
  <section class="w-full h-full relative flex flex-col">
    <div class="relative flex-1 min-h-0">
      <span class="absolute top-2 right-14 italic">
        <AtomButton
          v-if="!isViewDefault"
          size="sm"
          type="TEXT"
          class="italic"
          :label="$t('COMMON_LABEL_SET_DEFAULT')"
          @click="setDefault"
        />
      </span>
      <template v-if="!isLoadingOverviewDomain">
        <template v-if="filteredDomains?.length <= 0 && searchText">
          <div class="py-16 h-full w-full text-center">
            <syn-animation
              name="searchIcon"
              :loop="false"
              stype="width: 200px; height: 200px;"
            />
            <span class="font-medium text-lg text-gray-600">{{
              $t('COMMON_LABEL_ALERT_NO_SEARCH')
            }}</span>
          </div>
        </template>
        <template v-else>
          <div
            ref="domainListRef"
            class="
              px-8
              pt-16
              w-full
              h-full
              grid
              auto-rows-max
              overflow-auto
              small-scrollbar
            "
            style="grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr))"
          >
            <template v-if="filteredDomains.length < 1">
              <div
                class="w-full h-full px-8 pb-16 relative hover-to-show__parent"
                style="height: 24rem"
              >
                <div
                  class="
                    w-full
                    h-full
                    p-3
                    rounded-3xl
                    bg-white
                    flex-center flex-col
                    cursor-pointer
                    hover:border hover:border-current
                  "
                  @click="onOpenCreateModal"
                >
                  <SynAnimation name="setupTeam" stype="width: 120px;" />
                  <span class="font-medium text-gray-700 pt-4">{{
                    $t('OVERVIEW_DOMAIN_ADD_NEW_PROJECT_TITLE')
                  }}</span>
                  <span
                    class="flex-center text-center text-sm text-gray-400 pt-2"
                    >{{ $t('OVERVIEW_DOMAIN_ADD_NEW_PROJECT_SUB_TITLE') }}</span
                  >
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="domain in filteredDomains"
                :key="domain.id"
                class="w-full h-full px-8 pb-16 relative hover-to-show__parent"
                :style="`height:24rem;`"
              >
                <div
                  v-if="domain?.responsibility"
                  v-vig-tooltip="
                    `${domain?.responsibilityFirstName} ${domain?.responsibilityLastName}`
                  "
                  class="absolute -top-6 left-12 cursor-pointer"
                  @click="chooseContact(domain?.responsibility, 'USER')"
                >
                  <SynAvatar
                    :src="domain?.responsibilityAvatarUrl"
                    :custom-class="`w-14 h-14 border border-current`"
                    :name="`${domain?.responsibilityFirstName} ${domain?.responsibilityLastName}`"
                  />
                </div>

                <div
                  :id="`listDomain-item-${domain.id}`"
                  class="
                    w-full
                    h-full
                    p-3
                    rounded-3xl
                    bg-white
                    flex flex-col
                    shadow
                  "
                >
                  <!--  ACTIONS -->
                  <div class="flex items-center justify-between">
                    <span></span>
                    <div class="flex-center space-x-2">
                      <ActionById
                        v-if="domain.id"
                        :contact-id="domain.id"
                        :types="['favourite']"
                        type-contact="domain"
                      />
                      <VigDropdown placement="bottom-end">
                        <template #dropdown-toggle>
                          <div
                            class="
                              w-7
                              h-7
                              flex-center
                              rounded-full
                              hover:bg-current-50
                              hover:fill-current
                              hover:text-current-500
                            "
                          >
                            <SynIcon name="dots-vertical" />
                          </div>
                        </template>
                        <template #dropdown-menu>
                          <SynDropdownMenu class="right-0 w-44">
                            <SynDropdownItem
                              :title="$t('LIST_TASK_VIEW_TASK')"
                              name-icon="note"
                              :label="$t('LIST_TASK_VIEW_TASK')"
                              @click="chooseContact(domain?.id)"
                            />
                            <SynDropdownItem
                              v-if="false"
                              :title="$t('SIDEBAR_LABEL_RESOURCES')"
                              name-icon="resources"
                              :label="$t('SIDEBAR_LABEL_RESOURCES')"
                              @click="onGotoResource(domain?.id)"
                            />

                            <SynDropdownItem
                              class="dropdown-item"
                              :title="
                                $t('SIDEBAR_LABEL_MANAGEMENT') || 'Management'
                              "
                              name-icon="settings"
                              :label="
                                $t('SIDEBAR_LABEL_MANAGEMENT') || 'Management'
                              "
                              @click="onGotoManagement(domain?.id)"
                            />

                            <SynDropdownItem
                              is-close
                              :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                              name-icon="edit"
                              :label="$t('COMMON_LABEL_EDIT') || 'Edit'"
                              @click="onEditDomain(domain?.id, 'INFORMATION')"
                            />

                            <SynDropdownItem
                              is-close
                              :title="$t('COMMON_LABEL_DELETE') || 'Delete'"
                              name-icon="trash"
                              color="red"
                              :label="$t('COMMON_LABEL_DELETE') || 'Delete'"
                              @click="onDeleteDomain(domain?.id)"
                            />
                          </SynDropdownMenu>
                        </template>
                      </VigDropdown>
                    </div>
                  </div>

                  <!--  INFORMATION  -->
                  <div class="flex flex-col px-3 h-full">
                    <!--   NAME DOMAIN   -->
                    <div
                      :title="domain?.name"
                      class="
                        font-medium
                        text-lg text-gray-700 text-overflow-hidden-line
                        cursor-pointer
                        hover:text-current-600
                      "
                      @click="chooseContact(domain?.id)"
                      v-html="
                        markBackgroundTextSearch(domain?.name, searchText)
                      "
                    ></div>
                    <!--   DESCRIPTION DOMAIN   -->
                    <div
                      :title="domain?.description"
                      class="text-gray-400 text-sm text-overflow-hidden-line"
                    >
                      {{ domain?.description || '' }}
                    </div>
                    <!--   MEMBER LIST   -->
                    <div v-if="isOwner && domain?.members" class="pt-1 pb-2">
                      <SynListAvatar
                        :users="domain?.members"
                        custom-class="w-6 h-6"
                        :max-length="5"
                      />
                    </div>
                  </div>

                  <!--  STATISTIC  -->
                  <div
                    class="
                      flex flex-col
                      mx-3
                      pb-2
                      pt-3
                      border-b border-gray-100
                    "
                  >
                    <!--   STATISTIC TASK   -->
                    <div class="flex items-center space-x-3 pb-4">
                      <div
                        :title="`${domain?.activeTasks} ${$t(
                          'HEADER_LABEL_WELCOME_ACTIVE_TASK'
                        )}`"
                        class="
                          flex-center
                          rounded-full
                          px-2
                          py-0.5
                          text-xs text-current-500
                          border border-current-100
                          bg-current-50 bg-opacity-30
                          text-overflow-hidden-line
                        "
                      >
                        <span class="font-bold text-base pr-1">{{
                          domain?.activeTasks
                        }}</span>
                        <span class="text-2xs">{{
                          $t('HEADER_LABEL_WELCOME_ACTIVE_TASK')
                        }}</span>
                      </div>
                      <div
                        :title="`${domain?.overdueTasks} ${$t(
                          'HEADER_LABEL_WELCOME_OVERDUE_TASK'
                        )}`"
                        class="
                          flex-center
                          rounded-full
                          px-2
                          py-0.5
                          text-red-500
                          border border-red-100
                          bg-red-50 bg-opacity-30
                          text-overflow-hidden-line
                        "
                      >
                        <span class="font-bold text-base pr-1">{{
                          domain?.overdueTasks
                        }}</span>
                        <span class="text-2xs">{{
                          $t('HEADER_LABEL_WELCOME_OVERDUE_TASK')
                        }}</span>
                      </div>
                    </div>
                    <!--   TIME/PROGRESS TASK   -->
                    <div class="flex items-center justify-between pb-2">
                      <div class="flex items-start space-x-1">
                        <SynIcon
                          class="pt-2"
                          name="TimeRemaining"
                          custom-class="w-6 h-6 fill-current"
                        />
                        <div class="flex flex-col">
                          <span class="text-gray-400 text-xs">{{
                            $t('SYSTEM_DEPLOYMENT_SAVE_FLOW_WORKING')
                          }}</span>
                          <span class="text-gray-700 text-sm font-medium">{{
                            domain?.endDate
                              ? dayjs(domain?.endDate).fromNow()
                              : $t('COMMON_LABEL_NOTHING')
                          }}</span>
                        </div>
                      </div>
                      <div class="flex items-start space-x-1">
                        <SynIcon
                          class="pt-2"
                          name="ProgressDomain"
                          custom-class="w-6 h-6 fill-current"
                        />
                        <div class="flex flex-col">
                          <span class="text-gray-400 text-xs">{{
                            $t('COMMOM_LABEL_PROGRESS')
                          }}</span>
                          <span
                            class="text-sm font-medium"
                            :class="{
                              'text-red-600': domain?.progress < 50,
                              'text-orange-500':
                                domain?.progress > 49 && domain?.progress < 70,
                              'text-current-500': domain?.progress > 69,
                            }"
                            >{{ `${domain?.progress}%` }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--  MEMBERS/ GROUPS/ DEPARTMENT   -->
                  <div class="flex items-center justify-between px-3 pb-3 pt-3">
                    <!--  DEPARTMENT   -->
                    <div
                      v-if="isOwner && departmentManagementEnabled"
                      class="flex flex-col"
                    >
                      <span class="text-xs font-medium text-gray-700 pb-2">{{
                        $t('COMMON_MODULE_MANAGEMENT_DEPARTMENT')
                      }}</span>
                      <SynListAvatar
                        :groups="domain?.departments"
                        type="GROUP"
                        custom-class="w-7 h-7"
                        :max-length="3"
                      />
                      <span
                        v-if="!domain?.departments?.length"
                        class="text-gray-400 text-sm"
                        >{{
                          $t('DEPARTMENT_LABEL_USER_HAS_NOT_DEPARTMENT')
                        }}</span
                      >
                    </div>
                    <!--  MEMBERS   -->
                    <div v-else class="flex flex-col">
                      <span class="text-xs font-medium text-gray-700 pb-2">{{
                        $t('COMMON_LABEL_MEMBERS')
                      }}</span>
                      <SynListAvatar
                        :users="domain?.members"
                        custom-class="w-7 h-7"
                        :max-length="3"
                      />
                      <div
                        v-if="domain?.members?.length < 1"
                        class="flex-center space-x-2 cursor-pointer"
                        @click="onEditDomain(domain?.id, 'MEMBERS')"
                      >
                        <div
                          class="
                            flex-center
                            w-7
                            h-7
                            border border-dashed border-current-100
                            rounded-full
                          "
                        >
                          <syn-icon
                            name="Plus"
                            custom-class="w-2.5 h-2.5 fill-current text-current-500"
                          />
                        </div>
                        <span class="text-xs text-gray-400">{{
                          $t('VALUEMANAGEMENT_LABEL_ADDNEW')
                        }}</span>
                      </div>
                    </div>
                    <!--  GROUPS   -->
                    <div class="flex flex-col">
                      <span class="text-xs font-medium text-gray-700 pb-2">{{
                        $t('DOMAIN_LABEL_GROUPS')
                      }}</span>
                      <SynListAvatar
                        :groups="domain?.groups"
                        type="GROUPS"
                        custom-class="w-7 h-7"
                        :max-length="3"
                      />
                      <div
                        v-if="domain?.groups?.length < 1"
                        class="flex-center space-x-2 cursor-pointer"
                        @click="onEditDomain(domain?.id, 'GROUPS')"
                      >
                        <div
                          class="
                            flex-center
                            w-7
                            h-7
                            border border-dashed border-current-100
                            rounded-full
                          "
                        >
                          <syn-icon
                            name="Plus"
                            custom-class="w-2.5 h-2.5 fill-current text-current-500"
                          />
                        </div>
                        <span class="text-xs text-gray-400">{{
                          $t('VALUEMANAGEMENT_LABEL_ADDNEW')
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <div
          class="
            px-8
            pt-16
            w-full
            h-full
            grid
            auto-rows-max
            overflow-auto
            small-scrollbar
          "
          style="grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr))"
        >
          <div
            v-for="item in 3"
            :key="item"
            class="w-full h-full px-8 pb-16 relative hover-to-show__parent"
            :style="`height:24rem;`"
          >
            <div
              class="
                w-full
                h-full
                p-3
                rounded-3xl
                bg-white
                flex flex-col
                animate-pulse
              "
            >
              <div class="flex items-center justify-between">
                <span></span>
                <div class="flex-center space-x-2">
                  <span
                    class="w-4 h-4 animate-pulse bg-gray-200 rounded-full"
                  ></span>
                  <span
                    class="w-4 h-4 animate-pulse bg-gray-200 rounded-full"
                  ></span>
                </div>
              </div>
              <div class="flex flex-col px-3 pt-4 space-y-2 h-full">
                <span
                  class="w-2/3 h-4 animate-pulse bg-gray-200 rounded-full"
                ></span>
                <span
                  class="w-3/4 h-2 animate-pulse bg-gray-200 rounded-full"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
  <DomainDetailModal
    v-if="currentDomainDetailId?.id"
    :domain-id="currentDomainDetailId?.id"
    :default-tab="currentDomainDetailId?.tab"
    @on-save-success="onUpdateInformation"
    @on-close="
      currentDomainDetailId.id = null;
      currentDomainDetailId.tab = '';
    "
    @on-delete-success="onUpdateInformation"
  />
  <DomainCreateModal
    v-if="isCreateDomain"
    @on-close="onCloseCreateDomain"
    @on-create-success="onCreateSuccessDomain"
  />

  <DomainDeleteModal
    v-if="isDeleteDomain"
    :domain-id="isDeleteDomain"
    @on-close="isDeleteDomain = null"
    @on-delete-success="onCreateSuccessDomain"
  />
  <slot name="suffix"></slot>
</template>
