<template>
  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    v-if="isActive"
    id="Layer_1"
    class="text-current-700"
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
  >
    <g>
      <path
        d=""
        style="
          fill: none;
          stroke: rgb(0, 0, 0);
          stroke-width: 32;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        "
      />
      <path
        d=""
        style="
          fill: none;
          stroke: rgb(0, 0, 0);
          stroke-width: 32;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        "
      />
      <path
        d="m236.94 237.109-230-96.842c-3.295-1.388-6.94 1.032-6.94 4.608v258.899c0 6.542 3.983 12.426 10.058 14.855l223.085 89.234c3.284 1.314 6.857-1.105 6.857-4.642v-261.504c0-2.012-1.206-3.827-3.06-4.608z"
      />
      <path
        d="m257.94 209.596 222.315-93.606c3.996-1.682 4.103-7.306.174-9.139l-217.663-101.576c-4.289-2.001-9.243-2.001-13.532 0l-217.663 101.576c-3.929 1.833-3.822 7.457.174 9.139l222.315 93.606c1.24.523 2.64.523 3.88 0z"
      />
      <path
        d="m272 241.717v261.504c0 3.537 3.573 5.956 6.857 4.642l223.085-89.234c6.074-2.43 10.058-8.313 10.058-14.855v-258.899c0-3.576-3.645-5.996-6.94-4.608l-230 96.842c-1.854.781-3.06 2.596-3.06 4.608z"
      />
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
  >
    <g>
      <path
        d="m511.9 126.269c-.598-5.507-4.02-10.381-9.135-12.768l-240-112c-4.289-2.001-9.243-2.001-13.532 0l-240 112c-5.114 2.387-8.536 7.26-9.135 12.768-.013.115-.098 1.647-.098 1.731v272c0 6.542 3.983 12.426 10.058 14.855l240 96c1.907.763 3.925 1.145 5.942 1.145s4.035-.381 5.942-1.145l240-96c6.075-2.429 10.058-8.313 10.058-14.855v-272c0-.084-.087-1.617-.1-1.731zm-255.9-92.613 199.744 93.214-76.914 30.766-203.503-86.332zm-119.69 55.856 200.346 84.993-80.656 32.262-199.744-79.897zm-104.31 62.12 208 83.2v237.535l-208-83.2zm240 320.736v-237.536l208-83.2v237.535z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
