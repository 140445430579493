import { computed, Ref, ref, UnwrapRef } from 'vue';

export interface IAttachment {
    files: any[];
    attachments: any[];
    addFiles(files: any[]): void;
    addNote(note: any): void;
    removeNote(note: any): void;
    removeFile(file: any): void;
}

export default function attachmentLogic(
    formData: Ref<UnwrapRef<IAttachment>>,
    dragCallBack
) {
    const isDraging = ref(false);

    const attachedFiles = computed(() => {
        return formData.value?.files?.filter(
            (attachment) => !attachment.contentType?.includes('audio')
        );
    });
    const attachedAudios = computed(() => {
        return formData.value?.files?.filter((attachment) =>
            attachment.contentType?.includes('audio')
        );
    });

    const onRemoveAttachments = (file) => {
        if (!formData.value?.files || !file) return;

        // formData.value.removeFile(file);
    };

    const onChooseFiles = (event) => {
        const fileList = event?.target?.files;
        if (fileList?.length <= 0) return false;

        onAddFiles(fileList);
        return true;
    };

    const onAddFiles = (fileList) => {
        formData.value.addFiles(fileList);
    };

    const onDropImage = (e) => {
        isDraging.value = false;
        const files = e.dataTransfer.files;
        formData.value.addFiles(files);

        // handle convert to base64
        // let reader = new FileReader();
        // reader.onload = f => {
        //   // f.target.result contains the base64 encoding of the image
        //   // let src = f.target.result;
        //   console.log(
        //     "🚀 ~ file: TaskGeneralForm.vue ~ line 654 ~ attachmentLogic ~ f.target.result",
        //     f.target.result
        //   );
        // };
        // reader.readAsDataURL(file);
    };
    const dragOver = (isDragging) => {
        isDraging.value = isDragging;
        dragCallBack();
    };
    const dragEnter = () => {};
    let timerDragLeave;
    const dragLeave = () => {
        if (timerDragLeave) clearTimeout(timerDragLeave);
        timerDragLeave = setTimeout(() => {
            isDraging.value = false;
        }, 100);
    };
    const genAudioUrl = (record) => {
        return URL.createObjectURL(record);
    };
    return {
        genAudioUrl,
        onChooseFiles,
        onDropImage,
        dragOver,
        dragEnter,
        dragLeave,
        attachedFiles,
        attachedAudios,
        onRemoveAttachments,
        isDraging,
        onAddFiles,
    };
}
