<script setup lang="ts">
import { ref } from 'vue';
import UpdateDomain from '@/ui/modules/task/detail/UpdateDomain.vue';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';

const props = withDefaults(
  defineProps<{
    taskDetail: any;
    readonly?: boolean;
    placement?: string;
    hideOnClick?: string;
    layout?: 'horizontal' | 'vertical';
    isUpdateDomainOnly?: boolean;
    isUpdateInstance?: boolean;
  }>(),
  {
    placement: 'bottom',
    hideOnClick: 'outside',
    layout: 'vertical',
  }
);
const emit = defineEmits(['onOpen', 'onSave']);

const domainRef = ref(null as any);

const onCancelDomain = () => {
  domainRef.value?.onForceClose();
};

const isEdit = ref(false);
const updateDomainRef = ref();

const onDropdownOpen = () => {
  if (props.readonly) return;
  isEdit.value = true;
  emit('onOpen');
  updateDomainRef.value?.initDomain();
};
const onDropdownClose = () => {
  if (props.readonly) return;
  isEdit.value = false;
};
</script>
<template>
  <VigDropdown
    ref="domainRef"
    :arrow="false"
    :placement="placement"
    :hide-on-click="hideOnClick"
    @on-dropdown-open="onDropdownOpen"
    @on-dropdown-close="onDropdownClose"
  >
    <template #dropdown-toggle>
      <div
        class="flex items-center hover-to-show__parent w-max cursor-pointer"
        style="min-width: 8rem; max-width: fit-content"
      >
        <slot>
          <DomainProjectById
            v-if="taskDetail.domainId"
            class="hover:bg-current-50"
            :class="isEdit ? 'bg-current-50' : ''"
            :domain-id="taskDetail.domainId"
            :project-id="taskDetail.projectId"
            :sub-project-id="taskDetail.subprojectId"
            :layout="layout"
          />
          <span v-else class="text-xs text-gray-500 italic font-normal">
            {{ $t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_DOMAIN') }}
          </span>
        </slot>

        <!-- <div class="flex-center h-8 w-8">
          <SynIcon
            :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
            name="edit"
            is-active
            has-action
            class="hover-to-show__children absolute bg-current-50 bg-opacity-80"
          />
        </div> -->
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="flex flex-col py-4 px-3 w-max"
        style="max-width: 32rem; min-width: 20rem"
      >
        <span class="text-sm font-medium text-gray-700 pb-3">{{
          $t('DOMAIN_LABEL_SELECT_DOMAIN')
        }}</span>
        <UpdateDomain
          v-if="isEdit"
          ref="updateDomainRef"
          :task-detail="taskDetail"
          :is-update-instance="isUpdateInstance"
          @cancel="onCancelDomain"
          @save="(data) => $emit('onSave', data)"
        />
      </div>
    </template>
  </VigDropdown>
</template>
