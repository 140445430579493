import { computed, ref } from 'vue';
import notificationStore from '@/store/notification/notification.store';
import {
    isShowTaskDetailGlobal,
    taskIdGlobal,
    taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import taskDrawerStore from '@/store/task/drawer';
import {
    dayoffIdGlobal,
    eventIdGlobal,
    gedIdGlobal,
    isShowDayoffDetail,
    isShowPageGedDetail,
} from '@/ui/app-global-state';
import DayoffNotificationItemClass from '@/domain/entities/dayoff/DayoffNotificationItemClass';
import { INotification } from '@/application/types/notification.types';
import { NotificationType } from '@/domain/enums/notification-enums';

export default function commonActionNotificationComposables(options?) {
    const _notificationStore = notificationStore();
    const _notificationSettingStore = notificationSettingStore();
    const _taskDrawerStore = taskDrawerStore();

    const category = computed(() => _notificationStore.category);

    const currentNotificationId = ref();
    const isOpenAlertReadAll = ref(false);
    const isLoadingMarkReadAll = ref(false);

    const handleUpdateUserNotifications = (notificationId) => {
        _notificationStore.updateUserNotifications({
            notificationId,
            selectAll: false,
            status: true,
            category: category.value,
        });
    };

    const onClickNotification = (
        notification: INotification,
        options?: {
            view: string;
        }
    ) => {
        switch (notification?.type) {
            case NotificationType.TASK:
                handleClickTaskNotification(notification, options?.view);
                break;

            case NotificationType.DAYOFF:
                handleClickDayoffNotification(notification);
                break;
            case NotificationType.GED:
                handleClickGedNotification(notification);
                break;
            case NotificationType.CALENDAR:
                eventIdGlobal.value = {
                    id: notification?.mainData?.eventId,
                    isShow: true,
                };
                onMarkReadNotification(notification);
                break;

            default:
                onMarkReadNotification(notification);
                break;
        }
    };

    const handleClickTaskNotification = (
        notification: INotification,
        view?
    ) => {
        if (!notification || !notification?.id) return;
        // mark read
        onMarkReadNotification(notification);

        // open task detail
        switch (view) {
            case 'MODAL':
                {
                    taskIdGlobal.value = notification?.mainData?.taskId;
                    taskCodeGlobal.value = notification?.mainData?.taskCode;
                    isShowTaskDetailGlobal.value = true;
                    _notificationSettingStore
                        .notificationNegative({
                            sourceId: notification?.mainData?.taskId,
                            type: 'task',
                        })
                        .then();
                }
                break;
            case 'PANEL':
                if (!notification?.mainData.taskId) return;
                currentNotificationId.value = notification.id;
                _taskDrawerStore.pushCurrentIds({
                    id: notification?.mainData?.taskId,
                    code: notification?.mainData?.taskCode,
                    name: notification?.mainData?.taskName,
                    tabType: 'DETAIL',
                });
                break;
            default:
                break;
        }

        // const taskCommentId =
        //     notification?.mainData?.taskCommentObject?.task_comment_id ||
        //     notification?.mainData?.taskCommentObject?.taskCommentId;

        // if (taskCommentId) {
        //     _taskDetailStore.setNavigatePoint('comment', taskCommentId);
        // }
    };

    // const handleClickGroupNotification = (notification, openNewTab = false) => {
    //     if (!notification || !notification.groupId) return;

    //     onMarkReadNotification(notification);

    //     if (openNewTab) {
    //         const linkTaskGroup = getGroupTaskListUrl(
    //             notification.groupId,
    //             true
    //         );
    //         window.open(linkTaskGroup, '_blank');
    //         return;
    //     }
    //     router.push(taskListRouteByType('GROUP', notification.groupId));
    //     return;
    // };

    const handleClickDayoffNotification = (notification) => {
        if (!notification) return;

        onMarkReadNotification(notification);

        const dayoffClass = new DayoffNotificationItemClass(
            notification?.mainData
        );
        if (!options?.isPage) isShowDayoffDetail.value = true;

        dayoffIdGlobal.value = dayoffClass.dayoffId;
    };

    const handleClickGedNotification = (notification: INotification) => {
        if (!notification || !notification?.id) return;
        onMarkReadNotification(notification);

        isShowPageGedDetail.value = true;
        gedIdGlobal.value = {
            id: notification.mainData?.groupId
                ? notification.mainData?.groupId
                : notification.executorId,
            isGroup: !!notification.mainData?.groupId,
            folderId: notification?.mainData?.sharedFolderId
                ? notification?.mainData?.sharedFolderId
                : '',
            notification: notification,
        };
    };

    const onReadAllNotification = async () => {
        isLoadingMarkReadAll.value = true;
        const data = {
            selectAll: true,
            status: true,
            category: category.value,
        };
        _notificationStore.handleReadAllNotificationLocal();
        try {
            await _notificationStore.updateUserNotifications(data);
        } catch (error) {
            console.log('🚀 ~ ERR: ', error);
        }
        isLoadingMarkReadAll.value = false;
        isOpenAlertReadAll.value = false;
        await _notificationStore.getNotifications(13, 0, category.value);
    };

    const onMarkReadNotification = async (notification: INotification) => {
        const notificationId = notification?.id;
        if (!notificationId) return;

        switch (notification?.type) {
            case NotificationType.TASK:
                {
                    if (
                        notification?.histories &&
                        notification?.histories?.length > 0
                    ) {
                        notification?.histories
                            ?.filter((h) => !h?.status)
                            .forEach((history) => {
                                handleUpdateUserNotifications(
                                    history?.notificationId
                                );
                            });
                    }

                    const index =
                        _notificationStore.allNotifications?.findIndex(
                            (n) => n?.id == notificationId
                        );
                    if (index > -1) {
                        _notificationStore.allNotifications[index].status =
                            true;

                        _notificationStore.allNotifications[
                            index
                        ].histories?.forEach((his) => {
                            his.status = true;
                        });
                    }
                }
                break;

            default:
                handleUpdateUserNotifications(notification?.id);
                break;
        }
    };

    const onDeleteNotification = async (notification: INotification) => {
        const notificationId = notification?.id;
        if (!notificationId) return;

        let removeNotificationIds: string[] = [notificationId];

        if (notification?.type == NotificationType.TASK) {
            const index = _notificationStore.allNotifications?.findIndex(
                (n) => n?.id == notificationId
            );
            if (index > -1)
                removeNotificationIds =
                    _notificationStore.allNotifications[index]?.histories?.map(
                        (his) => his?.notificationId || ''
                    ) || [];
        }

        await _notificationStore.deleteNotification(removeNotificationIds);
        _notificationStore.getNotificationUnread();

        // _notificationStore.handleDeleteNotificationLocal(notificationId);
    };

    const onReactionNotification = (id, value) => {
        _notificationStore.handleReactionNotification(id, value);
    };

    return {
        currentNotificationId,
        isOpenAlertReadAll,
        isLoadingMarkReadAll,
        onClickNotification,
        onReadAllNotification,
        onMarkReadNotification,
        onDeleteNotification,
        handleUpdateUserNotifications,
        onReactionNotification,
    };
}
