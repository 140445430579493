<script setup lang="ts">
import { ref, computed, onMounted, reactive } from 'vue';
import userStore from '@/store/user';
import { AdminType } from '@/ui/common/constants/constant';
import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import { statusActive } from '@/ui/common/constants/user';
import FilterProfile from '@/ui/pages/usermanagement/component/FilterProfile.vue';
import {
  getUserPathFirebase,
  setDataFirebase,
} from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import UserDeactiveQuestion from '@/ui/components/user/UserDeactiveQuestion.vue';
import settingStore from '@/store/setting';
import department from '@/store/department';
import FilterDepartment from '@/ui/pages/usermanagement/component/FilterDepartment.vue';
import MoveDepartmentModal from '@/ui/pages/usermanagement/department/MoveDepartmentModal.vue';
import { adminTypeStyle } from '@/ui/common/constants/user';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
import router from '@/ui/router';
import plansStore from '@/store/plans';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import ModalUserStartWorkingConditions from '@/ui/pages/usermanagement/modal-start-working-conditions/ModalUserStartWorkingConditions.vue';

const emit = defineEmits(['showDetail']);

const _userStore = userStore();
const _settingStore = settingStore();
const _departmentStore = department();
const _planStore = plansStore();

// const isOwner = computed(() => _myProfile.isOwner);
const totals = computed(() => _userStore.total);
const adminTypeStyleList = computed<
  {
    key: AdminType;
    labelKey: string;
    backgroundColor: string;
    code: string;
    order: number;
  }[]
>(
  () =>
    arrayOrderBy(Object.values(adminTypeStyle), 'order', 'asc') as {
      key: AdminType;
      labelKey: string;
      backgroundColor: string;
      code: string;
      order: number;
    }[]
);
const listAllUser = computed(() => _userStore.listAllUser);
// const usersDefault = ref();
const position = computed(() => _userStore.position);
const typeAdmin = computed(() => _userStore.typeAdmin);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const allUserShortSummary = computed(() => _userStore.allUserStatistic);
const departmentList = computed(() => _departmentStore.departmentList);

const pageIndex = ref(1);
const pageSize = ref(20);
const isLoadingUsers = ref(false);

const filterData = reactive<{
  keySearch: string;
  adminType: AdminType | '';
}>({
  keySearch: '',
  adminType: _userStore.filterObject.profile,
});

const keyDepartment = ref(_userStore.filterObject.departmentId);
const keySearch = ref(_userStore.filterObject.keySearch || '');
const showDeleteModal = ref(false);
const userId = ref();
const statusSelect = ref<number | undefined>(_userStore.filterObject.status);
const totalFilterItem = ref(false);
const isFilterProfile = ref(false);
const showInactiveUser = ref({
  show: false,
  idUser: null,
  nameUser: null,
  statusActive: null,
} as any);
const isSWConditionsOpen = ref<boolean>();
const userToSetSWConditions = ref<any>();

const scrollable = ref();
const scrollTopTable = computed(() => _userStore.scrollTop);

const filterUser = async (key = 'reload') => {
  await _userStore.searchUsers(
    keySearch.value,
    statusSelect.value,
    filterData.adminType,
    keyDepartment.value,
    pageIndex.value,
    pageSize.value,
    key
  );
};

const onSearch = async () => {
  isLoadingUsers.value = true;
  pageIndex.value = 1;
  await filterUser('reload');
  isLoadingUsers.value = false;
};

const init = async () => {
  isLoadingUsers.value = true;
  if (typeAdmin.value?.length < 1) await _userStore.fetchAllProfile();
  if (position.value?.length < 1) await _userStore.fetchAllPosition();

  pageIndex.value = 1;
  pageSize.value = 20;
  statusSelect.value = 1; //active

  await filterUser();
  await _departmentStore.shortInfoDepartment();
  isLoadingUsers.value = false;
};

onMounted(() => {
  init();
  scrollable.value.scrollTop = scrollTopTable.value;
});

const onReload = async () => {
  keySearch.value = '';
  pageIndex.value = 1;
  pageSize.value = 20;
  isLoadingUsers.value = true;
  await filterUser();
  isLoadingUsers.value = false;
  updateKeySearch();
};

const onChangeStatus = async (statusChoose) => {
  statusSelect.value = statusChoose;
  _userStore.filterObject.status = statusChoose;
  totalFilterItem.value = statusSelect.value !== undefined;
  pageIndex.value = 1;
  pageSize.value = 20;
  await filterUser('reload');
};

const onChangeViewProfile = async (key: AdminType | '') => {
  filterData.adminType = key;
  _userStore.filterObject.profile = key;
  pageIndex.value = 1;
  isFilterProfile.value = !!key;
  await filterUser('reload');
};

const onChangeDepartment = async (key) => {
  keyDepartment.value = key;
  _userStore.filterObject.departmentId = keyDepartment.value;
  pageIndex.value = 1;
  await filterUser('reload');
};
const onActivateUser = async (user) => {
  const isValidPlan = await _planStore.checkCurrentPaymentStageForUpdateUser({
    owner: user?.adminType == AdminType.Owner ? 1 : 0,
    admin: user?.adminType == AdminType.Admin ? 1 : 0,
    manager: user?.adminType == AdminType.Manager ? 1 : 0,
    user: user?.adminType == AdminType.User ? 1 : 0,
    visitor: user?.adminType == AdminType.Visitor ? 1 : 0,
  });
  if (!isValidPlan) return;

  await onUpdateUser(user?.id, 1);
};

const onDeactivateUser = async (user) => {
  // const isValidPlan = await _planStore.checkCurrentPaymentStageForUpdateUser({
  //   owner: user?.adminType == AdminType.Owner ? -1 : 0,
  //   admin: user?.adminType == AdminType.Admin ? -1 : 0,
  //   manager: user?.adminType == AdminType.Manager ? -1 : 0,
  //   user: user?.adminType == AdminType.User ? -1 : 0,
  //   visitor: user?.adminType == AdminType.Visitor ? -1 : 0,
  // });
  // if (!isValidPlan) return;

  showInactiveUser.value = {
    show: true,
    idUser: user.id,
    nameUser: user.lastName + ' ' + user.firstName,
    statusActive: user.active ? 0 : 1,
  };

  console.log(showInactiveUser.value);
};

const onUpdateUser = async (id, stt) => {
  // console.log('onUpdateUser', id, stt);
  await _userStore.updateStatusUser(id, stt);
  pageIndex.value = 1;
  await filterUser('reload');
  showInactiveUser.value = {};
  if (stt !== 0) return;

  let allPromises: any[] = [];
  listAllUser.value.forEach((u: any) => {
    const path = getUserPathFirebase(u.id, 'favorites');
    if (path) {
      let favoriteUserPath = `${path}/member_${id}`;
      allPromises.push(setDataFirebase(favoriteUserPath, null));
    }
  });

  await Promise.all(allPromises);
};

const onCancelInactive = async (id) => {
  showInactiveUser.value = {};
  const index = listAllUser.value.findIndex((el) => el.id === id);
  if (index === -1) return;
  listAllUser.value[index].active = true;
};

const showDetail = (user) => {
  emit('showDetail', user);
};

const onDeleteUser = async () => {
  showDeleteModal.value = false;
  await _userStore.deleteUserId(userId.value, 0);
  await filterUser();
};

const isLoadingSeeMore = ref(false);
const loadMoreUsers = async () => {
  if (listAllUser.value?.length >= totals.value['totalMember']) return;
  isLoadingSeeMore.value = true;
  pageIndex.value++;
  await filterUser('added');
  isLoadingSeeMore.value = false;
};

const inputSearchMembers = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath.includes('user-management')
  ) {
    inputSearchMembers.value?.focus();
    e.preventDefault();
  }
});

const updateDepartmentForUserId = ref(null);
const openMoveDepartmentModal = (userId) => {
  updateDepartmentForUserId.value = userId;
};

const onUpdateDepartmentSuccess = () => {
  updateDepartmentForUserId.value = null;
  pageIndex.value = 1;
  filterUser('reload');
};

const updateKeySearch = () => {
  _userStore.filterObject.keySearch = keySearch.value;
};

const scrollTable = () => {
  _userStore.scrollTop = scrollable.value.scrollTop;
};

const updateUser = (user) => {
  const index = listAllUser.value?.findIndex((u) => u?.id == user?.id);
  if (index > -1) {
    listAllUser.value[index] = {
      ...listAllUser.value[index],
      ...user,
    };
  }
};

defineExpose({
  updateUser,
});
</script>
<template>
  <div class="h-full bg-white flex flex-col space-y-1 p-2 overflow-y-hidden">
    <div class="flex w-full justify-between items-center py-2">
      <!-- search -->
      <div class="flex items-center w-80">
        <VigSearchBox
          ref="inputSearchMembers"
          v-model="keySearch"
          class="w-full"
          :placeholder="$t('USERMANAGEMENT_LABEL_SEARCH')"
          @enter="onSearch"
          @on-clear="onReload"
          @update:model-value="updateKeySearch"
        />
      </div>

      <div class="flex space-x-2">
        <div class="text-center flex space-x-5 h-9 items-center">
          <div
            v-for="item in statusActive"
            :key="item?.key"
            class="flex items-center text-end cursor-pointer"
            @click="onChangeStatus(item?.key)"
          >
            <input
              type="radio"
              name="default-radio"
              :checked="item?.key === statusSelect"
              class="
                w-4
                h-4
                text-current-500
                bg-gray-100
                border-gray-300
                cursor-pointer
              "
            />
            <span class="ml-2 text-sm text-current-500">
              {{ $t(item.label) }}
            </span>
          </div>
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex items-center space-x-1">
          <span
            class="
              text-xs
              flex-center
              space-x-1
              rounded
              py-1
              px-2
              border border-current-400
              text-current-500 text-end
            "
          >
            {{ `${$t('COMMON_LABEL_TOTAL')}: ${totals['totalMember'] || 0}` }}
          </span>
          <span>|</span>
          <template v-for="item in adminTypeStyleList" :key="item?.key">
            <span
              :class="`
              rounded
              flex-center
              space-x-1
              text-xs
              py-1
              px-2
              text-white text-end ${item?.backgroundColor}
            `"
            >
              {{ `${$t(item?.labelKey)}: ${totals[item?.key] || 0}` }}
            </span>
          </template>
        </div>
      </div>
    </div>

    <!-- table -->
    <div
      ref="scrollable"
      v-scroll-infinite="loadMoreUsers"
      class="flex-1 overflow-y-auto small-scrollbar"
      @scroll="scrollTable"
    >
      <syn-table-custom>
        <template #header>
          <syn-tr>
            <syn-th :label="$t('USERMANAGEMENT_TABLE_AVATAR')"></syn-th>
            <syn-th :label="$t('USERMANAGEMENT_TABLE_FULLNAME')"></syn-th>
            <!-- <syn-th :label="$t('USERMANAGEMENT_TABLE_DOB')"></syn-th> -->
            <syn-th :label="$t('USERMANAGEMENT_TABLE_STATUS')">
              <template #custom>
                <vig-dropdown>
                  <template #dropdown-toggle>
                    <div
                      v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER')"
                      class="w-6 h-6 flex-center relative"
                    >
                      <SynIcon
                        name="filter2"
                        class="cursor-pointer"
                        :custom-class="`w-3.5 h-3.5 ${
                          statusSelect !== undefined
                            ? 'fill-current'
                            : 'fill-gray-500'
                        } `"
                      />
                    </div>
                  </template>
                  <template #dropdown-menu>
                    <div v-for="status in statusActive" :key="status?.key">
                      <div
                        class="
                          pl-2
                          pr-8
                          py-2
                          hover:bg-gray-50
                          cursor-pointer
                          w-32
                          dropdown-item
                          text-sm
                        "
                        :class="
                          status?.key === statusSelect
                            ? 'bg-current-50 font-medium text-current-500'
                            : ''
                        "
                        @click="onChangeStatus(status?.key)"
                      >
                        <span>{{ $t(status?.label) }}</span>
                      </div>
                    </div>
                  </template>
                </vig-dropdown>
              </template>
            </syn-th>

            <syn-th :label="$t('USERMANAGEMENT_FORM_ADMIN_TYPE')">
              <template #custom>
                <FilterProfile
                  :admin-type="filterData.adminType"
                  @on-change-status="(value) => onChangeViewProfile(value)"
                />
              </template>
            </syn-th>

            <syn-th
              v-if="departmentManagementEnabled"
              :label="$t('COMMON_MODULE_MANAGEMENT_DEPARTMENT')"
              style="width: 25rem"
            >
              <template #custom>
                <FilterDepartment
                  :departments="departmentList"
                  :department-select="keyDepartment"
                  @on-change-department="(value) => onChangeDepartment(value)"
                />
              </template>
            </syn-th>

            <syn-th :label="$t('USERMANAGEMENT_FORM_PHONE')"></syn-th>
            <syn-th :label="$t('USERMANAGEMENT_TABLE_EMAIL')"></syn-th>
            <syn-th :label="$t('USERMANAGEMENT_TABLE_ADDRESS')"></syn-th>
            <!-- <syn-th :label="$t('USERMANAGEMENT_TABLE_POSITION')"></syn-th> -->

            <syn-th position-text-header="justify-center">
              <div class="flex-center w-full">
                <button
                  v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                  class="
                    flex-center
                    rounded-full
                    hover:fill-current
                    fill-gray-500
                    w-6
                    h-6
                  "
                  @click="onReload"
                >
                  <SynIcon name="reload" class="w-4 h-4" />
                </button>
              </div>
            </syn-th>
          </syn-tr>
        </template>

        <template #body>
          <syn-table-tr-loading v-if="isLoadingUsers">
            <div v-for="item in 8" :key="item" class="table-cell p-2 h-96">
              <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
            </div>
          </syn-table-tr-loading>

          <template
            v-for="user in listAllUser"
            v-else-if="listAllUser.length > 0"
            :key="user.id"
          >
            <syn-tr
              class="relative cursor-pointer hover:bg-gray-50"
              :id-task="user.id"
              :class="!user.active ? 'bg-gray-50 hover:bg-gray-100' : ''"
            >
              <SynTd class="w-12" @click="showDetail(user)">
                <div class="min-w-max flex items-center relative">
                  <SynAvatar
                    class="cursor-pointer text-current-800"
                    :src="user.avatarUrl?.replace('original', 'mini')"
                    :name="user.firstName"
                    custom-class="w-8 h-8"
                    :has-name="false"
                  />
                  <div
                    v-if="!user.active"
                    class="
                      w-8
                      h-8
                      rounded-full
                      bg-white bg-opacity-50
                      absolute
                      top-0
                      left-0
                    "
                  ></div>
                </div>
              </SynTd>
              <SynTd
                :class="!user.active ? 'text-gray-400' : ''"
                class="cursor-pointer font-semibold hover:text-current w-80"
                @click="showDetail(user)"
              >
                <span
                  v-vig-tooltip="user.lastName + ' ' + user.firstName"
                  class="min-w-full text-overflow-hidden-line"
                  >{{ user.lastName + ' ' + user.firstName }}
                </span>
              </SynTd>
              <SynTd
                class="flex-start items-center h-full w-48"
                @click="showDetail(user)"
              >
                <div
                  :class="
                    user.active
                      ? 'min-w-max flex text-green-500 border-none'
                      : 'min-w-max flex text-gray-500 border-none'
                  "
                >
                  {{
                    user.active
                      ? $t('USERMANAGEMENT_FORM_ACTIVE')
                      : $t('USERMANAGEMENT_FORM_DEACTIVE')
                  }}
                </div>
              </SynTd>
              <SynTd class="w-56" @click="showDetail(user)">
                <span
                  class="px-2 py-0.5 rounded-full text-xs flex-center w-max"
                  :class="`${!user.active ? '' : ''} ${
                    adminTypeStyle[user.adminType]?.backgroundColor
                  } text-white`"
                >
                  {{ $t(adminTypeStyle[user.adminType]?.labelKey) }}
                </span>
              </SynTd>
              <SynTd
                v-if="departmentManagementEnabled"
                class="hover-to-show__parent w-56"
                @click="openMoveDepartmentModal(user.id)"
              >
                <div class="flex items-center">
                  <div
                    v-if="user.departmentShortInfos?.length > 0"
                    class="flex items-center space-x-2"
                  >
                    <SynAvatar
                      class="cursor-pointer text-current-800"
                      :src="
                        user.departmentShortInfos[0]?.avatarUrl?.replace(
                          'original',
                          'mini'
                        )
                      "
                      :name="user.departmentShortInfos[0]?.name"
                      custom-class="w-5 h-5"
                    />
                    <span
                      v-vig-tooltip="user.departmentShortInfos[0]?.name"
                      class="overflow-hidden text-overflow-hidden-line"
                    >
                      {{ user.departmentShortInfos[0]?.name }}
                    </span>
                  </div>
                  <div v-else class="flex items-center space-x-2 w-max">
                    <div
                      class="
                        bg-gray-400
                        rounded-full
                        flex-center
                        overflow-hidden
                        h-5
                        w-5
                      "
                    >
                      <SynIcon
                        name="user"
                        is-active
                        custom-class="h-2.5 w-2.5 fill-gray-50"
                      />
                    </div>
                    <span class="text-sm text-gray-400">{{
                      $t('DEPARTMENT_LABEL_USER_HAS_NOT_DEPARTMENT')
                    }}</span>
                  </div>
                  <div class="flex-center w-8 h-8">
                    <SynIcon
                      :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                      name="edit"
                      is-active
                      custom-class="w-3 h-3 fill-current
                      hover-to-show__children
                      "
                      has-action
                    />
                  </div>
                </div>
              </SynTd>

              <SynTd class="w-52" @click="showDetail(user)">
                <AtomPhoneNumberLabel
                  v-if="user?.phone"
                  class="text-overflow-hidden-line"
                  :class="!user.active ? 'text-gray-400' : ''"
                  :phone-number="user?.phone?.toString()"
                />
              </SynTd>
              <SynTd
                class="w-64"
                :class="!user.active ? 'text-gray-400' : ''"
                @click="showDetail(user)"
              >
                <span class="min-w-full text-overflow-hidden-line">{{
                  user.email
                }}</span>
              </SynTd>
              <SynTd style="width: 25rem" @click="showDetail(user)">
                <span
                  v-if="user.address"
                  v-vig-tooltip="user.address"
                  :class="!user.active ? 'text-gray-400' : ''"
                  class="min-w-full text-overflow-hidden-line"
                >
                  {{ user.address }}
                </span>
              </SynTd>

              <SynTd class="">
                <three-dot-actions
                  type="action"
                  size="small"
                  class="flex-center w-16"
                >
                  <div
                    class="list-li flex justify-start items-center"
                    @click.stop="showDetail(user)"
                  >
                    <SynIcon custom-class="fill-gray-500 w-4 h-4" name="info" />
                    <span class="text-gray-600">
                      {{ $t('COMMON_LABEL_VIEW_DETAIL') }}
                    </span>
                  </div>
                  <div
                    class="list-li flex justify-start items-center"
                    @click.stop="
                      userToSetSWConditions = user;
                      isSWConditionsOpen = true;
                    "
                  >
                    <SynIcon
                      custom-class="fill-gray-500 w-4 h-4"
                      name="Settings"
                    />
                    <span class="text-gray-600">
                      {{ $t('SETTING_SW_CONDITION_SET') }}
                    </span>
                  </div>
                  <!-- Active/Deactivate User -->
                  <div
                    v-if="user.active"
                    class="
                      list-li
                      flex
                      justify-start
                      items-center
                      border-t
                      dropdown-item
                      text-red-600
                      fill-red-500
                    "
                    @click.stop="onDeactivateUser(user)"
                  >
                    <SynIcon
                      name="deactivate"
                      is-active
                      custom-class="fill-red-500 w-4 h-4"
                    />
                    <span class="text-red-600">
                      {{ $t('USERMANAGEMENT_LABEL_DEACTIVATE_USER') }}
                    </span>
                  </div>
                  <div
                    v-else
                    class="
                      list-li
                      flex
                      justify-start
                      items-center
                      border-t
                      dropdown-item
                      text-green-600
                      fill-green-500
                    "
                    @click.stop="onActivateUser(user)"
                  >
                    <SynIcon
                      name="check"
                      is-active
                      custom-class="fill-green-500 w-4 h-4"
                    />
                    <span class="text-green-600">
                      {{ $t('USERMANAGEMENT_LABEL_ACTIVATE_USER') }}
                    </span>
                  </div>
                </three-dot-actions>
              </SynTd>
            </syn-tr>
          </template>

          <template v-else>
            <syn-tr class="h-full" hover="hover:bg-current-50">
              <SynTd :colspan="10" style="height: calc(100vh - 20rem)">
                <syn-animation
                  name="searchIcon"
                  stype="width: 200px; height: 200px"
                  :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                />
              </SynTd>
            </syn-tr>
          </template>

          <syn-tr
            v-if="listAllUser.length < totals['totalMember'] && !isLoadingUsers"
          >
            <syn-td colspan="8">
              <div class="flex-center w-full">
                <div
                  class="
                    flex-center
                    rounded-full
                    cursor-pointer
                    px-3
                    py-2
                    border
                    bg-opacity-50 bg-white
                    text-sm
                    hover:border-current-400 hover:text-current-400
                  "
                  @click="loadMoreUsers"
                >
                  <SynIcon
                    v-if="isLoadingSeeMore"
                    name="Spinner"
                    class="w-4 h-4 fill-gray-500 animate-spin"
                  />
                  <span>{{
                    isLoadingSeeMore
                      ? 'Loading...'
                      : $t('NOTIFICATION_SEE_MORE')
                  }}</span>
                  <SynIcon
                    v-if="!isLoadingSeeMore"
                    name="ChevronDoubleDown"
                    class="w-4 h-4 text-gray-500"
                  />
                </div>
              </div>
            </syn-td>
          </syn-tr>
        </template>
      </syn-table-custom>
    </div>

    <!-- paginator -->
    <!-- <div class="sticky bottom-0 w-full bg-white border">
                      <syn-paginator
                        class="mb-2 pt-2 right-0"
                        :itemInPage="pageSize"
                        :pageIndex="pageIndex"
                        :pageSize="pageAmount"
                        @showPrevious="onShowPrevious"
                        @showNext="onShowNext"
                        @changeSize="onChangeItem"
                      ></syn-paginator>
                    </div> -->
  </div>

  <SynQuestionModal
    :visible="showDeleteModal"
    :show-confirm-btn="false"
    :show-delete-btn="true"
    :header-text="$t('USERMANAGEMENT_LABEL_DELETE_USER')"
    :content-text="$t('USERMANAGEMENT_LABEL_CONFIRM_DELETE_USER')"
    :delete-label="$t('USERMANAGEMENT_LABEL_DELETE_USER')"
    @on-delete="onDeleteUser"
    @cancel="showDeleteModal = false"
  />

  <UserDeactiveQuestion
    :visible="showInactiveUser?.show"
    :id-user="showInactiveUser?.idUser"
    :name-user="showInactiveUser?.nameUser"
    :all-user-short-summary="allUserShortSummary"
    :status-active="showInactiveUser?.statusActive"
    @on-cancel="onCancelInactive"
    @on-update-user="(data) => onUpdateUser(data.id, data.stt)"
  />

  <MoveDepartmentModal
    v-if="updateDepartmentForUserId"
    :user-id="updateDepartmentForUserId"
    @on-cancel="updateDepartmentForUserId = null"
    @on-update-success="onUpdateDepartmentSuccess"
  />

  <ModalUserStartWorkingConditions
    v-if="isSWConditionsOpen"
    :user="userToSetSWConditions"
    @on-close="isSWConditionsOpen = false"
  />
</template>

<style>
[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #fff !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  /* --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color); */
}
</style>
