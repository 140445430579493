<script setup lang="ts">
import { AdminType } from '@/ui/common/constants/constant';

import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';
import ActionById from '@/ui/components/user/ActionById.vue';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    type: 'MEMBER' | 'GROUP' | 'DOMAIN';
    totalTasks: number | string;
    totalMembertotalTasks: number | string;
    idContact: number | string;
    name: string;
    avatar: string;
    contactObject: any;
    isLoading: boolean;
  }>(),
  {
    type: 'MEMBER',
    totalTasks: 0,
    totalMember: 0,
  }
);

defineEmits(['onShowMember', 'onShowProfile']);

const typeContact = {
  MEMBER: 'MEMBER',
  GROUP: 'GROUP',
  DOMAIN: 'DOMAIN',
};

const isGroupDelete = computed(
  () => props.type === 'GROUP' && props?.contactObject?.code == 'DELETED_GROUP'
);
</script>

<template>
  <div class="flex items-center space-x-3">
    <div class="flex-center cursor-pointer" @click="$emit('onShowProfile')">
      <SynAvatar
        :src="avatar?.replace('original', 'mini')"
        :name="name"
        :type="type?.toLowerCase()"
      />
      <div
        v-if="isGroupDelete"
        class="bg-white w-5 h-5 flex-center absolute -right-3 -bottom-1"
      >
        <SynIcon
          class="fill-current"
          custom-class="w-2.5 h-2.5"
          name="cancel"
        />
      </div>
    </div>
    <div class="flex flex-col hidden md:block">
      <span v-if="isLoading" class="w-12 py-2 bg-gray-200 rounded-md"></span>
      <div class="flex items-center space-x-1">
        <span class="max-w-md w-max truncate font-medium text-gray-600">{{
          name
        }}</span>
        <SynIcon
          v-if="type === typeContact.GROUP && contactObject?.isPrivate"
          name="private"
          custom-class="w-3 h-3 fill-current-400"
        />
        <span
          v-if="
            type === typeContact.MEMBER &&
            contactObject?.adminType === AdminType.Visitor
          "
          class="
            italic
            text-gray-600
            border border-gray-100
            rounded-full
            px-1
            py-1
            text-sm
          "
        >
          {{ $t('COMMON_LABEL_VISITOR') }}
        </span>
      </div>
      <div
        class="flex items-center space-x-2 text-gray-500"
        style="line-height: 1rem"
      >
        <template v-if="type === typeContact.MEMBER">
          <div v-permission-model class="flex-center space-x-2">
            <small>{{ $t('COMMON_LABEL_MEMBER') }} </small>
            <span>|</span>
          </div>
          <span
            v-if="isLoading"
            class="w-12 py-2 bg-gray-200 rounded-md"
          ></span>
          <small v-else class="lowercase">
            {{ `${totalTasks} ${$t('TASK_TABLE_LABEL_TASKS') || 'tasks'}` }}
          </small>
        </template>

        <template v-if="type === typeContact.GROUP">
          <small>{{ $t('COMMON_LABEL_GROUP') }} </small>
          <span>|</span>
          <span
            v-if="isLoading"
            class="w-12 py-2 bg-gray-200 rounded-md"
          ></span>
          <small
            v-else
            class="lowercase hover:text-current-500 cursor-pointer"
            @click="$emit('onShowMember')"
          >
            {{
              `${contactObject?.groupUsers?.length || 0} ${
                $t('TASK_TABLE_LABEL_MEMBERS') || 'members'
              }`
            }}
          </small>
          <span>|</span>
          <span
            v-if="isLoading"
            class="w-12 py-2 bg-gray-200 rounded-md"
          ></span>
          <small v-else class="lowercase">
            {{ `${totalTasks} ${$t('TASK_TABLE_LABEL_TASKS') || 'tasks'}` }}
          </small>
        </template>

        <template v-if="type === typeContact.DOMAIN">
          <small>{{ $t('TASK_CREATE_FORM_DOMAIN') }} </small>
          <span>|</span>
          <span
            v-if="isLoading"
            class="w-12 py-2 bg-gray-200 rounded-md"
          ></span>
          <small
            v-else
            class="lowercase hover:text-current-500 cursor-pointer"
            @click="$emit('onShowMember')"
          >
            {{
              `${contactObject?.members?.length || 0} ${
                $t('TASK_TABLE_LABEL_MEMBERS') || 'members'
              }`
            }}
          </small>
          <span>|</span>
          <span
            v-if="isLoading"
            class="w-12 py-2 bg-gray-200 rounded-md"
          ></span>
          <small v-else class="lowercase">
            {{ `${totalTasks} ${$t('TASK_TABLE_LABEL_TASKS') || 'tasks'}` }}
          </small>
        </template>
      </div>
    </div>

    <div class="flex-center">
      <ActionById
        v-if="idContact"
        :contact-id="idContact"
        :type-contact="type?.toLowerCase()"
        :types="['favourite', 'chat']"
      />
    </div>
  </div>
</template>
