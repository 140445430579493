<script setup lang="ts">
// *** IMPORTS ***
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import appStore from '@/store/app';
import { loginAgain } from '@/ui/hooks/commonHook';

// *** PROPS, EMITS ***

// *** PRIVATE VARIABLES ***
const _myProfileStore = myProfileStore();

// *** COMPOSABLES ***

// *** REFS ***

// *** COMPUTED ***
const myProfile = computed<any>(() => _myProfileStore.myProfile);

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const handleClickLogout = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: translate('HEADER_LOGOUT_CONFIRM_TITLE'),
    content: translate('HEADER_LOGOUT_CONFIRM_SUB'),
    confirmable: true,
    closeable: true,
  });

  const confirm = await ask();

  if (confirm) {
    appStore().setIsLoading(true);
    loginAgain().then();
  }
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div v-if="myProfile?.id" class="flex-center gap-2">
    <router-link
      to="/tasks/my-tasks"
      class="
        relative
        cursor-pointer
        hover:bg-current-50
        rounded-full
        py-1
        pr-2
        pl-1
      "
    >
      <SynAvatar
        has-name
        :name="myProfile?.fullName"
        :src="myProfile?.avatarUrl"
        custom-class="w-8 h-8 ring ring-current ring-opacity-30 border border-current"
        type="group"
      >
        <template #name>
          <div
            class="
              font-medium
              text-gray-800
              overflow-hidden
              flex-nowrap
              text-ellipsis
              hidden
              xl:flex
            "
            style="white-space: nowrap; max-width: 10rem"
          >
            {{ myProfile?.fullName }}
          </div>
        </template>
      </SynAvatar>
    </router-link>
    <VigDropdown class="hover:text-current bg-white shadow-none">
      <template #dropdown-toggle>
        <SynIcon
          name="dots-vertical"
          has-action
          custom-class="h-4 w-4 hover:text-current text-current"
        />
      </template>
      <template #dropdown-menu>
        <SynDropdownMenu class="right-0 min-w-[11rem]">
          <div
            class="dropdown-item list-li"
            @click="handleClickLogout"
          >
            <div class="flex items-center">
              <SynIcon name="logout" class="fill-red-500"/>
              <span class="pl-2">
                {{ $t('COMMON_LABEL_LOGOUT') || 'Log out' }}
              </span>
            </div>
          </div>
        </SynDropdownMenu>
      </template>
    </VigDropdown>
  </div>

  <div v-else class="flex items-center gap-2">
    <router-link to="/register">
      <VigButton ghost label="COMMON_LABEL_SIGN_UP"></VigButton>
    </router-link>
    <router-link to="/">
      <VigButton ghost label="COMMON_MODULE_LOGIN"></VigButton>
    </router-link>
  </div>
</template>

<style scoped></style>
