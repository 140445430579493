import vmsg from 'vmsg';

export const initRecorder = async () => {
    const recorder = new vmsg.Recorder({
        wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
    });

    await recorder.initAudio();
    await recorder.initWorker();

    return recorder;
};
