<script setup lang="ts">
import { computed } from 'vue';
import { adminTypeStyle } from '@/ui/common/constants/user';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { AdminType } from '@/ui/common/constants/constant';

defineProps<{
  adminType?: AdminType | '';
}>();

const emits = defineEmits(['onChangeStatus']);
const adminTypeList = computed<any[]>(() =>
  arrayOrderBy(Object.values(adminTypeStyle), 'order', 'asc')
);
const onChangeStatus = (stt) => {
  emits('onChangeStatus', stt);
};
</script>

<template>
  <vig-dropdown>
    <template #dropdown-toggle>
      <div
        v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER')"
        class="w-6 h-6 flex-center relative"
      >
        <SynIcon
          name="filter2"
          class="cursor-pointer"
          :custom-class="`w-3.5 h-3.5 ${
            adminType ? 'fill-current' : 'fill-gray-500'
          } `"
        />
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="
          pl-2
          pr-8
          py-2
          hover:bg-gray-50
          cursor-pointer
          w-32
          dropdown-item
          text-sm
        "
        :class="!adminType ? 'bg-current-50 font-medium text-current-500' : ''"
        @click="onChangeStatus('')"
      >
        <span>{{ $t('COMMON_LABEL_ALL') }}</span>
      </div>

      <div
        v-for="item in adminTypeList"
        :key="item?.key"
        class="
          pl-2
          pr-8
          py-2
          hover:bg-gray-50
          cursor-pointer
          w-32
          dropdown-item
          text-sm
        "
        :class="
          adminType === item?.key
            ? 'bg-current-50 font-medium text-current-500'
            : ''
        "
        @click="onChangeStatus(item?.key)"
      >
        <span>{{ $t(item?.labelKey) }}</span>
      </div>
    </template>
  </vig-dropdown>
</template>
