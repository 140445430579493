<script setup lang="ts">
import { ref, watch, onMounted, computed } from 'vue';
import { initRecorder } from '@/ui/plugins/recorder/recorder';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import chatStore from '@/store/chat';
import ButtonSend from '@/ui/modules/messaging/chat-panel/chat-form/button-send/ButtonSend.vue';
import remoteConfigStore from '@/store/remoteConfig';
import { RemoteConfigKey } from '@/ui/plugins/firebases/firebase-remote-config';
import PostMessageService from '@/application/services/PostMessageService';
import { AppMessageTypeEnum } from '@/domain/enums/app-message-type.enum';

const props = defineProps<{
  isNotFocus?: boolean;
  isEmbeddedApp?: boolean;
}>();

const emit = defineEmits(['onRecordSubmit', 'isRecording']);

const _chatStore = chatStore();
const _remoteConfigStore = remoteConfigStore();

const isRecording = ref(false);
const isPausing = ref(false);
const isStopped = ref(false);
const recordedDuration = ref(0);
const recordedUrl = ref();
const recordDurationLimit = ref();

const isClearActionForm = computed(() => _chatStore.isClearActionForm);

let recorder, recorderTimer, recordedBlob, recordStartTime;

watch(
  () => isClearActionForm.value,
  (isClearActionForm) => {
    if (isClearActionForm) {
      onRecordCancel();
    }
  }
);

onMounted(() => {
  if (isClearActionForm.value) {
    onRecordCancel();
  }

  const limitDuration = _remoteConfigStore
    .getRemoteConfig(RemoteConfigKey.CommonChatAudioDurationLimit)
    .asNumber();
  recordDurationLimit.value = (limitDuration || 0) * 1000;
});

const onRecordStart = async () => {
  if (props.isEmbeddedApp) {
    return PostMessageService.getInstance().postMessageToParent({
      type: AppMessageTypeEnum.tictopRecordingClick,
    });
  }

  try {
    recorder = await initRecorder();
    recorder.startRecording();

    isRecording.value = true;
    isPausing.value = false;
    isStopped.value = false;
    recordedDuration.value = 0;
    recordStartTime = new Date().getTime();
    recordedBlob = null;

    emit('isRecording', isRecording.value);

    recorderTimer = setInterval(_processRecordedDuration, 100);
  } catch (e) {
    console.error(e);
    openNotification({
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body: e,
    });
  }
};

const onRecordPause = async () => {
  if (!recorder || !isRecording.value) return;

  await _processRecordedBlob();

  isPausing.value = true;

  _processRecordedDuration();

  clearInterval(recorderTimer);
};

const onRecordResume = async () => {
  if (!isRecording.value) return;

  recorder = await initRecorder();
  recorder.startRecording();

  isPausing.value = false;
  recordStartTime = new Date().getTime();

  recorderTimer = setInterval(_processRecordedDuration, 100);
};

const onRecordStop = async () => {
  if (!recorder || !isRecording.value) return;

  if (!isPausing.value) {
    await _processRecordedBlob();
  }

  recordedUrl.value = URL.createObjectURL(recordedBlob);
  isStopped.value = true;

  clearInterval(recorderTimer);
};

const onRecordCancel = async () => {
  if (!isRecording.value) return;

  if (recorder && !isPausing.value && !isStopped.value)
    await recorder.stopRecording();

  _processFinishRecording();
};

const onRecordSubmit = async () => {
  if (!recorder || !isRecording.value) return;

  if (!isPausing.value && !isStopped.value) {
    await _processRecordedBlob();
  }

  emit('onRecordSubmit', recordedBlob);

  _processFinishRecording();
};

const _processRecordedDuration = () => {
  const currentTime = new Date().getTime();

  recordedDuration.value += currentTime - recordStartTime;

  recordStartTime = currentTime;

  if (
    recordDurationLimit.value &&
    recordedDuration.value > recordDurationLimit.value + 100
  ) {
    onRecordStop();
  }
};

const _processRecordedBlob = async () => {
  const blob = await recorder.stopRecording();

  let blobParts: any[] = [];

  if (recordedBlob) blobParts.push(recordedBlob);

  blobParts.push(blob);

  recordedBlob = new Blob(blobParts, { type: 'audio/mpeg' });
};

const _processFinishRecording = () => {
  clearInterval(recorderTimer);

  isRecording.value = false;
  isPausing.value = false;
  recordedBlob = null;

  emit('isRecording', isRecording.value);
};

const cancelRecord = () => {
  onRecordCancel();
};

const setStopRecording = (blob) => {
  isRecording.value = true;
  isStopped.value = true;

  recordedUrl.value = URL.createObjectURL(blob);
};

defineExpose({
  cancelRecord,
  setStopRecording,
});
</script>

<template>
  <button
    v-if="!isRecording"
    v-vig-tooltip="$t('COMMON_LABEL_AUDIO')"
    type="button"
    class="w-8 h-8 rounded-full flex-center hover:bg-current-50"
    @click="onRecordStart"
  >
    <SynIcon
      name="Microphone"
      :class="isNotFocus ? 'fill-gray-500' : 'fill-blue-500'"
      is-active
      custom-class="w-5 h-5"
    />
  </button>

  <div v-else class="flex items-end justify-between space-x-3">
    <div
      v-vig-tooltip="$t('CHAT_RECORDING_CANCEL') || 'Cancel recording'"
      class="
        flex-center
        w-9
        h-9
        rounded-full
        cursor-pointer
        hover:bg-gray-100 hover:shadow
      "
      @click="onRecordCancel"
    >
      <SynIcon name="Close" is-active custom-class="w-6 h-6 text-gray-500" />
    </div>

    <div v-if="!isStopped" class="flex items-center space-x-2">
      <vig-button
        v-if="!isPausing"
        v-vig-tooltip="$t('CHAT_RECORDING_PAUSE') || 'Pause recording'"
        light
        icon="Pause"
        color="gray"
        rounded="rounded-full"
        @click="onRecordPause"
      >
      </vig-button>

      <vig-button
        v-else
        v-vig-tooltip="$t('CHAT_RECORDING_RESUME') || 'Resume recording'"
        light
        icon="Play"
        color="gray"
        rounded="rounded-full"
        @click="onRecordResume"
      >
      </vig-button>

      <vig-button
        v-vig-tooltip="$t('CHAT_RECORDING_STOP') || 'Stop recording'"
        light
        icon="StopVideo"
        color="red"
        rounded="rounded-full"
        :class="{ 'animate-pulse': !isPausing }"
        @click="onRecordStop"
      >
      </vig-button>

      <div class="text-lg">
        {{ $filters.duration(recordedDuration) }}
        <template v-if="recordDurationLimit">
          / {{ $filters.duration(recordDurationLimit) }}
        </template>
      </div>
    </div>

    <VigAudio v-else :path="recordedUrl"></VigAudio>

    <ButtonSend @on-send-click="onRecordSubmit" />
  </div>
</template>
