<script setup lang="ts">
import { computed, inject } from 'vue';
import { getSolidStraightArrowPath } from '@/ui/components/drawer/shapes/arrow/straight-arrow';
import { ArrowInfo } from '@/ui/components/drawer/shapes/arrow/arrow-types';
import { getArrowheadPathForType } from '@/ui/components/drawer/shapes/arrow/arrowheads';
import { STROKE_SIZES } from '@/ui/components/drawer/shapes/common/utils';
import PROVIDER_IDS, {
  TDesktopDrawer,
  TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
import ShapeTaskEntity from '@/ui/components/drawer/entities/ShapeTaskEntity';

const props = defineProps<{
  shapeEntity: ShapeTaskEntity;
}>();

const desktopDrawerShapeInjected = inject<TDesktopDrawerShape>(
  PROVIDER_IDS.DESKTOP_DRAWER_SHAPE
);
const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);

const arrowInfo = computed<ArrowInfo>(() => {
  return props.shapeEntity?.sourceData;
});

const strokeWidth = STROKE_SIZES['s'];

const path = computed<any>(() => {
  return arrowInfo.value.isStraight
    ? getSolidStraightArrowPath(arrowInfo.value)
    : '';
});
const arrowStart = computed<any>(() => {
  return (
    arrowInfo.value.start.arrowhead &&
    getArrowheadPathForType(arrowInfo.value, 'start', strokeWidth)
  );
});
const handStart = computed<any>(() => {
  return getArrowheadPathForType(
    {
      ...arrowInfo.value,
      start: {
        ...arrowInfo.value?.start,
        arrowhead: 'dot',
      },
    },
    'start',
    strokeWidth
  );
});
const arrowEnd = computed<any>(() => {
  return (
    arrowInfo.value.end.arrowhead &&
    getArrowheadPathForType(arrowInfo.value, 'end', strokeWidth)
  );
});

const onSelectArrow = (event) => {
  desktopDrawerShapeInjected?.onMouseDown(event);
};
const onClickArrowEnd = () => {
  desktopDrawerInjected?.updateArrowShape(props.shapeEntity?.id, 'end');
};
const onClickHandStart = () => {
  desktopDrawerInjected?.updateArrowShape(props.shapeEntity?.id, 'start');
};
</script>
<template>
  <svg class="tl-svg-container" style="min-width: 50px; min-height: 50px">
    <g
      class=""
      stroke="#1d1d1d"
      :strokeWidth="strokeWidth"
      strokeLinejoin="round"
      strokeLinecap="round"
      pointerEvents="none"
    >
      <g>
        <path :d="path" />
      </g>
      <path v-if="arrowStart" :d="arrowStart" />
      <path v-if="arrowEnd" :d="arrowEnd" stroke-width="2" />
    </g>
    <path :d="path" class="tl-hitarea-stroke" @mousedown="onSelectArrow" />
    <path
      v-if="handStart"
      class="tl-hitarea-stroke"
      style="cursor: grab"
      :d="handStart"
      stroke-width="2"
      @mousedown="onClickHandStart"
    />
    <path
      v-if="arrowEnd"
      class="tl-hitarea-stroke"
      style="cursor: grab"
      :d="arrowEnd"
      stroke-width="2"
      @mousedown="onClickArrowEnd"
    />
  </svg>
</template>
<style>
.tl-svg-container {
  overflow: visible;
}
.tl-svg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform-origin: top left;
}

.tl-hitarea-stroke {
  stroke: transparent;
  pointer-events: stroke;
  stroke-width: min(100px, calc(24px * var(--tl-scale)));
}
</style>
