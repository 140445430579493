<script setup lang="ts">
import { computed, ref, onMounted, Ref } from 'vue';
import { useRouter } from 'vue-router';
// import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import WelcomeModal from './WelcomeModal.vue';
// import GroupById from '@/ui/components/group/GroupById.vue';
// import UserById from '@/ui/components/user/UserById.vue';

import {
  // getConfigScheduleColor,
  isValidTaskCode,
  // formatTaskCode,
  getUrgencyValue,
} from '@/ui/hooks/taskHook';
import TaskService from '@/application/services/task/TaskService';
import PrincipleService from '@/application/services/PrincipleService';
import principleStore from '@/store/principle';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { TaskActionType } from '@/domain/enums/taskEnum';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import settingStore from '@/store/setting';
import startWorkingStore from '@/store/task/start-working-store';
import TaskDetailDrawerMini from '@/ui/modules/task/detail/TaskDetailDrawerMini.vue';
import taskDrawerStore from '@/store/task/drawer';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import TaskTimeBlockingSingle from '@/ui/pages/tasks/calendar/TaskTimeBlockingSingle.vue';
import dayjs from 'dayjs';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';
import {
  addOrUpdateReactiveList,
  removeItemOutOfReactiveList,
  arrayOrderBy,
  cloneDeep,
} from '@/ui/hooks/commonFunction';
import myProfileStore from '@/store/auth/my-profile';
import TaskSimpleTable from '@/ui/modules/task/table/TaskSimpleTable.vue';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import { taskListRouteByType } from '@/ui/hooks/commonHook';
import { ETaskListModule } from '@/application/types/task/task.types';

defineEmits<{
  (e: 'onCancel'): void;
  (e: 'onStart'): void;
}>();

const router = useRouter();
// const _taskStore = taskStore();
const _principleStore = principleStore();
const _settingStore = settingStore();
const _startWorkingStore = startWorkingStore();
const _taskDrawerStore = taskDrawerStore();
const _permissionStore = permissionStore();

const allSystemConfigs = computed(() => _permissionStore.allSystemConfigs);

const startWorkingHasTimeBlocking = computed(() => {
  return allSystemConfigs.value[
    systemConfig.START_WO_FUNCTION_START_WORKING_SCREENS
  ]?.includes('TIME_BLOCKING');
});

const originalDefaultTaskList = computed(() => {
  return _startWorkingStore.planToTreatTasks;
});
const doItTodayTasks = computed(() => {
  return originalDefaultTaskList.value?.filter((t) => t?.isPlannedToTreat);
});

const requiredUpdateTimeBlocking = computed(() => {
  return (
    allSystemConfigs.value[
      systemConfig.START_WO_FUNCTION_MANDATORY_TO_PLAN_TIME_FOR_EAC
    ] === '1'
  );
});
const startLocalTime = computed(() => _principleStore.startLocalTime);
const isLoadingFetchTotoTask = ref(false);
const _taskService = TaskService.getInstance();
const startWorkingManagement = computed(
  () => _settingStore._organizationSetting.startWorkingManagement
);
const myTimeZone = computed<any>(() => {
  return myProfileStore().timezone;
});
const taskDetailPanelRef = ref(null as any);
const isOpenTaskDrawer = ref(false);

const isDisableButtonSubmit = computed(() => {
  return (
    (startWorkingManagement.value.mustSelectTask &&
      currentSelectedIds.value?.length == 0 &&
      startWorkingStatus.value == 'SELECT_TASKS') ||
    (startWorkingStatus.value == 'PLANNING_TODAY' &&
      requiredUpdateTimeBlocking.value &&
      showingTaskList.value?.some(
        (o) => !o?.planningStartTime || !o?.planningEndTime
      ))
  );
});

const {
  currentStep: startWorkingStatus,
  stepperByKey: checkinStatusList,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    WELCOME: {
      key: 'WELCOME',
      next: 'SELECT_TASKS',
      previous: 'CANCEL',
      previousText: '',
      confirmText: 'COMMON_LABEL_CONTINUE',
      nextAction: () => {
        showingTaskList.value = cloneDeep(defaultTaskList.value);

        currentSelectedIds.value = defaultTaskList.value
          .filter((el: any) => el.isPlannedToTreat)
          ?.map((t) => t?.id);
      },
    },
    SELECT_TASKS: {
      key: 'SELECT_TASKS',
      previous: 'WELCOME',
      next: 'SELECT_TASKS_TO_FINISH',
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'COMMON_LABEL_CONTINUE',
      nextAction: () => {
        defaultTaskList.value = defaultTaskList.value?.map((t) => {
          const isSelected = currentSelectedIds.value?.includes(t?.id);
          return {
            ...t,
            isPlannedToTreat: isSelected,
            taskSchedule: {
              ...t?.taskSchedule,
              doItToday: isSelected,
            },
          };
        });

        showingTaskList.value = defaultTaskList.value.filter(
          (el: any) => el.isPlannedToTreat
        );

        currentSelectedIds.value = originalDefaultTaskList.value
          .filter((el: any) => el.isPlannedToFinish)
          ?.map((t) => t?.id);

        _startWorkingStore.setFilterDataOrigin(showingTaskList.value);
        _startWorkingStore.sortList = [];
      },
      previousAction: () => {
        _startWorkingStore.sortList = [];
      },
    },
    SELECT_TASKS_TO_FINISH: {
      key: 'SELECT_TASKS_TO_FINISH',
      previous: 'SELECT_TASKS',
      next: startWorkingHasTimeBlocking.value ? 'PLANNING_TODAY' : '',
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: startWorkingHasTimeBlocking.value
        ? 'COMMON_LABEL_CONTINUE'
        : 'LABEL_FINISH_CHOOSE_TASK',
      nextAction: () => {
        defaultTaskList.value = defaultTaskList.value?.map((t) => {
          const isSelected = currentSelectedIds.value?.includes(t?.id);
          return {
            ...t,
            isPlannedToFinish: isSelected,
            taskSchedule: {
              ...t?.taskSchedule,
              finishItToday: isSelected,
            },
          };
        });
        showingTaskList.value = showingTaskList.value?.map((t) => {
          const isSelected = currentSelectedIds.value?.includes(t?.id);
          return {
            ...t,
            isPlannedToFinish: isSelected,
            taskSchedule: {
              ...t?.taskSchedule,
              finishItToday: isSelected,
            },
          };
        });
        _startWorkingStore.sortList = [];

        updateEventSources();
        if (!startWorkingHasTimeBlocking.value) handleStartWD();
      },
      previousAction: () => {
        showingTaskList.value = cloneDeep(defaultTaskList.value);
        currentSelectedIds.value = defaultTaskList.value
          .filter((el: any) => el.isPlannedToTreat)
          ?.map((t) => t?.id);
        _startWorkingStore.setFilterDataOrigin(showingTaskList.value);
        _startWorkingStore.sortList = [];
      },
    },
    PLANNING_TODAY: {
      key: 'PLANNING_TODAY',
      previous: 'SELECT_TASKS_TO_FINISH',
      next: '',
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'LABEL_FINISH_CHOOSE_TASK',
      nextAction: () => {
        handleStartWD();
      },
      previousAction: () => {},
    },
  },
  'WELCOME'
);
let { unsubscribeCallbacks } = listenFirebaseRealtime();

const removeTaskOutOfEventList = (taskId) => {
  removeItemOutOfReactiveList(showingTaskList, taskId);
  if (startWorkingStatus.value == 'PLANNING_TODAY') removeEventByTask(taskId);
};
const addOrUpdateTaskList = (updatedTask, isDisableAdd?, isOverwriteAll?) => {
  const currentTaskInList = showingTaskList.value?.find(
    (t) => t?.id == updatedTask?.id
  );
  const _updatedTask = isOverwriteAll
    ? updatedTask
    : {
        ...updatedTask,
        ...(currentTaskInList
          ? {
              isPlannedToTreat: currentTaskInList?.isPlannedToTreat,
              isPlannedToFinish: currentTaskInList?.isPlannedToFinish,
              isUpdatePlanningTime: currentTaskInList?.isUpdatePlanningTime,
              planningStartTime: currentTaskInList?.planningStartTime,
              planningEndTime: currentTaskInList?.planningEndTime,
            }
          : {}),
      };

  addOrUpdateReactiveList(showingTaskList, _updatedTask, isDisableAdd);
  addOrUpdateReactiveList(defaultTaskList, _updatedTask);

  if (startWorkingStatus.value == 'PLANNING_TODAY' && !isDisableAdd)
    addOrUpdateEventByTask(_updatedTask);
};

const onPushTaskOutOfList = (taskId) => {
  removeTaskOutOfEventList(taskId);

  currentSelectedIds.value = currentSelectedIds.value?.filter(
    (id) => id !== taskId
  );
};

const onListenFirebase = () => {
  const LatestTaskActivityFirebaseModel = new UserDynamicModel(
    `user_${myProfileStore().myProfile?.id}/latestTaskActivity`
  );

  unsubscribeCallbacks.value.push(
    LatestTaskActivityFirebaseModel.onChange(async (data) => {
      const isUpdatedMyself =
        data?.executorId == myProfileStore().myProfile?.id;
      switch (data?.taskAction) {
        case TaskActionType.Delete:
        case TaskActionType.Finished:
        case TaskActionType.MoveOut:
          {
            _startWorkingStore.removeTask({
              id: data?.taskId,
            });
            removeItemOutOfReactiveList(defaultTaskList, data?.taskId);

            onPushTaskOutOfList(data?.taskId);
          }
          break;

        default:
          {
            const res =
              await TaskService.getInstance().getDetailTaskIncognitoById(
                data?.taskId
              );
            const task = res?.result;
            if (
              task?.assigneeId !== myProfileStore().myProfile?.id ||
              !task?.id
            )
              return;

            const updatedTask = {
              ...task,
              // isPlannedToTreat: task?.taskSchedule?.doItToday,
              // isPlannedToFinish: task?.taskSchedule?.finishItToday,
              urgency: getUrgencyValue(task.priority, task.important),
            };

            _startWorkingStore.updateTodoList(updatedTask);
            toggleSelectTaskIds(
              startWorkingStatus.value == 'SELECT_TASKS'
                ? updatedTask?.isPlannedToTreat ||
                    updatedTask?.isPlannedToFinish
                : updatedTask?.isPlannedToFinish,
              updatedTask?.id
            );
            switch (data?.taskAction) {
              case TaskActionType.AddNew:
              case TaskActionType.MoveIn:
                {
                  switch (startWorkingStatus.value) {
                    case 'SELECT_TASKS':
                      {
                        addOrUpdateTaskList(updatedTask);
                      }
                      break;
                    case 'SELECT_TASKS_TO_FINISH':
                    case 'PLANNING_TODAY':
                      {
                        if (
                          updatedTask?.isPlannedToTreat ||
                          updatedTask?.isPlannedToFinish
                        )
                          addOrUpdateTaskList(updatedTask);
                      }
                      break;

                    default:
                      break;
                  }
                }
                break;

              default:
                {
                  switch (startWorkingStatus.value) {
                    case 'SELECT_TASKS':
                      {
                        if (isUpdatedMyself) addOrUpdateTaskList(updatedTask);
                      }

                      break;
                    case 'SELECT_TASKS_TO_FINISH':
                    case 'PLANNING_TODAY':
                      {
                        if (
                          updatedTask?.isPlannedToTreat ||
                          updatedTask?.isPlannedToFinish
                        )
                          addOrUpdateTaskList(updatedTask, !isUpdatedMyself);
                        else if (isUpdatedMyself)
                          onPushTaskOutOfList(updatedTask?.id);
                      }

                      break;

                    default:
                      break;
                  }
                }
                break;
            }
          }
          break;
      }
    })
  );
};

const toggleSelectTaskIds = (isSelected, taskId) => {
  if (isSelected)
    currentSelectedIds.value = [
      ...new Set([...currentSelectedIds.value, taskId]),
    ];
  else
    currentSelectedIds.value = currentSelectedIds.value?.filter(
      (id) => id !== taskId
    );
};

onMounted(() => {
  onListenFirebase();

  fetchTodoTaskList();
});

const onClickSort = (key, name) => {
  const sortData = _startWorkingStore.genSortDataList({ key, name });

  if (!sortData?.iterate || !sortData?.order) {
    showingTaskList.value = cloneDeep(
      startWorkingStatus.value == 'SELECT_TASKS_TO_FINISH'
        ? doItTodayTasks.value
        : originalDefaultTaskList.value
    );
    return;
  }

  showingTaskList.value = arrayOrderBy(
    showingTaskList.value,
    sortData?.iterate,
    sortData?.order
  );
};

const onFilterByKeyOnColumn = (key, filters) => {
  _startWorkingStore.setFilterDataByKey(key, filters);
};

const onResetFilterOnColumn = (key) => {
  _startWorkingStore.resetFilterDataOrigin(key);
  showingTaskList.value = cloneDeep(
    startWorkingStatus.value == 'SELECT_TASKS_TO_FINISH'
      ? doItTodayTasks.value
      : originalDefaultTaskList.value
  );
};

// // search
// const typeStatus = computed(() => _startWorkingStore.typeStatus);

// const resetSearch = () => {
//   keySearch.value = '';
//   keySearchToFinish.value = '';
// };

// const handleFinishTask = (task, index) => {
//   _startWorkingStore.handleFinishTask(task, index);
//   //refresh list
// };

const onCreateTaskGlobal = () => {
  // createTaskGlobal.value = {
  //   groupId: 0,
  //   userId: getCurrentUserId(),
  //   name: '',
  //   avatar: '',
  //   files: [],
  // };
  // isOpenCreateTaskGlobal.value = true;
  // onCreateGlobalSuccess.value = _startWorkingStore.updateTodoListAfterCreate;
  taskDetailPanelRef?.value?.onClickNewTab(true);
};

const isLoading = ref(false);

const currentTabId = computed(() => _taskDrawerStore.currentTabId);
const currentTabInfo = computed(() => {
  if (!currentTabId.value) return {};
  return _taskDrawerStore.allTabByIds[currentTabId.value];
});
const handleStartWD = async () => {
  if (
    isOpenTaskDrawer.value &&
    currentTabInfo.value &&
    !isValidTaskCode(currentTabInfo.value?.tabCode)
  ) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('START_WORKING_IN_PROCESS_CREATE_TASK_WARNING', {
        taskCode: currentTabInfo.value?.tabCode || '',
      }),
      confirmable: true,
      confirmLabel: translate('LABEL_FINISH_CHOOSE_TASK') || 'Confirm',
      closeable: true,
    });

    const confirmed = await ask();
    if (!confirmed) return false;
  }
  isLoading.value = true;

  await _taskService.saveTaskListPlannedToday(
    showingTaskList.value.map((o: any) => {
      return {
        isSelected: o.isPlannedToFinish,
        taskId: o.id,
        planId: o.planId,
        isUpdatePlanningTime: true,
        planningStartTime: o?.planningStartTime,
        planningEndTime: o?.planningEndTime,
      };
    })
  );

  await onStartWorkingDay();
  // _dashboardStore.updateUserWorkingStatusList(
  //   getCurrentUserId(),
  //   UserWorkingStatusType.Online
  // );
  isLoading.value = false;
  // await _dashboardStore.getMemberOnday();
  _principleStore.changeCheckinStatus('STARTED');
  _principleStore.changeStarting(false);
  _principleStore.fetchUserWorkingStatus();

  goToMyTasks();
};

const goToMyTasks = () => {
  router.push(
    taskListRouteByType('USER', getCurrentUserId(), true, {
      filterTab: 'TODO',
    })
  );
};

const onStartWorkingDay = () => {
  const startWD = new Date(startLocalTime.value) || new Date();
  PrincipleService.getInstance().startStopWorkingDay({
    type: 1,
    time: startWD,
    location: _principleStore.startWorkingLocation,
  });
};

const filterDataByKey = computed(() => _startWorkingStore.filterDataByKey);
const showingTaskList = ref<any[]>([]);

const filteredShowingTaskList = computed(() => {
  if (!showingTaskList.value || showingTaskList.value?.length == 0) return [];

  let result = showingTaskList.value;
  if (filterDataByKey.value)
    result = _startWorkingStore.onFilterTaskOnColumn(result);

  return result;
});

const taskTimeBlockingSingleRef = ref<any>(null);
const updateEventSources = () => {
  if (
    taskTimeBlockingSingleRef.value &&
    typeof taskTimeBlockingSingleRef.value?.updateEventSources == 'function'
  )
    taskTimeBlockingSingleRef.value.updateEventSources();
};
const addOrUpdateEventByTask = (task) => {
  if (
    taskTimeBlockingSingleRef.value &&
    typeof taskTimeBlockingSingleRef.value?.addOrUpdateEventByTask == 'function'
  )
    taskTimeBlockingSingleRef.value.addOrUpdateEventByTask(task);
};

const removeEventByTask = (taskId) => {
  if (
    taskTimeBlockingSingleRef.value &&
    typeof taskTimeBlockingSingleRef.value?.removeEventByTask == 'function'
  )
    taskTimeBlockingSingleRef.value.removeEventByTask(taskId);
};

const defaultTaskList = ref<any[]>([]);
const fetchTodoTaskList = async () => {
  isLoadingFetchTotoTask.value = true;
  try {
    defaultTaskList.value = await _startWorkingStore.fetchListTask();
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  }
  isLoadingFetchTotoTask.value = false;
};

const reloadList = async () => {
  if (startWorkingStatus.value !== 'SELECT_TASKS') return;

  await fetchTodoTaskList();

  showingTaskList.value = cloneDeep(defaultTaskList.value);

  currentSelectedIds.value = [
    ...new Set([
      ...originalDefaultTaskList.value
        .filter((el: any) => el.isPlannedToTreat)
        ?.map((t) => t?.id),
    ]),
  ];
};

const sortDataOrderByKey = computed(
  () => _startWorkingStore.sortDataOrderByKey
);

const currentSelectedIds = ref<any[]>([]);
const handleSelectedTask = (selectedTask) => {
  taskDetailPanelRef.value?.onClosePanel();

  const isExisted = currentSelectedIds.value?.includes(selectedTask?.id);
  if (isExisted)
    currentSelectedIds.value = currentSelectedIds.value?.filter(
      (id) => id !== selectedTask?.id
    );
  else currentSelectedIds.value.push(selectedTask?.id);
};
// const handleSelectedTask = (task) => {
//   const indexTask = showingTaskList.value.findIndex((o) => o.id == task.id);
//   if (indexTask == -1) return;
//   showingTaskList.value[indexTask] = {
//     ...showingTaskList.value[indexTask],
//     isPlannedToTreat: !task.isPlannedToTreat,
//     taskSchedule: {
//       ...showingTaskList.value[indexTask]?.taskSchedule,
//       doItToday: !task.isPlannedToTreat,
//     },
//   };

//   _startWorkingStore.handleSelectedTask(showingTaskList.value[indexTask]);
// };

// const handleSelectedTaskToFinish = (task) => {
//   const indexTask = showingTaskList.value.findIndex((o) => o.id == task.id);
//   if (indexTask == -1) return;

//   showingTaskList.value[indexTask] = {
//     ...showingTaskList.value[indexTask],
//     isPlannedToFinish: !task.isPlannedToFinish,
//     taskSchedule: {
//       ...showingTaskList.value[indexTask]?.taskSchedule,
//       finishItToday: !task.isPlannedToFinish,
//     },
//   };
// };

const openTaskDetail = (task, event?) => {
  console.log('🚀 Tictop ~ event:', event);
  if (event && event?.stopPropagation) event?.stopPropagation();
  _taskDrawerStore.pushCurrentIds({ ...task, tabType: 'DETAIL' }, false);
  taskDetailPanelRef?.value?.onOpenPanel();
};

// const onClosePanel = () => {
//   console.log('click outside');
//   taskDetailPanelRef?.value?.onClosePanel();
// };

const changeStartEndTime = async (event) => {
  taskDetailPanelRef.value?.onClosePanel();
  const task = event?.extendedProps;
  const start = event?.start;
  const end = event?.end;

  const payloadData = event?.allDay
    ? {
        isUpdatePlanningTime: true,
        planningStartTime: null,
        planningEndTime: null,
      }
    : {
        isUpdatePlanningTime: true,
        planningStartTime: dayjs.utc(start).format('YYYY-MM-DDTHH:mm:ss'),
        planningEndTime: end
          ? dayjs.utc(end).format('YYYY-MM-DDTHH:mm:ss')
          : dayjs.utc(start).add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
      };

  const updatedTask = { ...task, ...payloadData };

  _startWorkingStore.updateTodoList(updatedTask);
  addOrUpdateTaskList(updatedTask, false, true);
};

const updateTaskItem = (updatedTask) => {
  const index1 = showingTaskList.value?.findIndex(
    (task) => task?.id == updatedTask?.id
  );
  if (index1 > -1) showingTaskList.value[index1] = updatedTask;
};
</script>

<template>
  <WelcomeModal
    v-if="startWorkingStatus == 'WELCOME'"
    :is-loading="isLoadingFetchTotoTask"
    @cancel="$emit('onCancel')"
    @on-next="onHandleGoToNextStep"
  />

  <SynModal
    v-else
    z-index="z-40"
    disable-click-outside
    disable-esc-to-close
    container-class="w-4/5"
    container-style="height: 90%;"
    style-body="overflow-hidden py-0"
    has-element-active
    @cancel="$emit('onCancel')"
  >
    <template #header>
      <div
        class="flex item-centers justify-between"
        @click="taskDetailPanelRef?.onClosePanel()"
      >
        <div class="h-8">
          <span v-if="startWorkingStatus == 'SELECT_TASKS'">
            {{ $t('START_WORKING_LABEL_SELECT_TASKS') }}
          </span>
          <div v-if="startWorkingStatus == 'PLANNING_TODAY'" class="flex gap-1">
            <span>
              {{ $t('COMMON_LABEL_PLAN_YOUR_DAY_TODAY') }}
            </span>
          </div>
          <span
            v-if="
              startWorkingStatus == 'SELECT_TASKS_TO_FINISH' ||
              startWorkingStatus == 'STARTED'
            "
          >
            {{ $t('START_WORKING_LABEL_SELECT_TASKS_TO_FINISH') }}
          </span>
        </div>
        <span
          v-if="startWorkingStatus == 'SELECT_TASKS'"
          class="h-8 w-8 flex-center rounded hover:bg-current-50 cursor-pointer"
          @click="reloadList"
        >
          <SynIcon name="reload2" />
        </span>
      </div>
    </template>
    <template #body>
      <div
        class="flex-1 min-h-0 flex w-full overflow-hidden"
        @click="taskDetailPanelRef?.onClosePanel()"
      >
        <!-- LEFT SIDE -->
        <div
          class="
            h-full
            w-56
            sticky
            top-0
            border-r border-current-50
            2xl:block
            hidden
          "
          style="min-height: 100%; width: 20%"
          @click="taskDetailPanelRef?.onClosePanel()"
        >
          <div
            class="
              absolute
              top-1/4
              text-xl
              px-4
              text-center text-md
              md:text-md
              font-mono
              text-current-400
            "
          >
            <span>
              {{
                startWorkingStatus == 'SELECT_TASKS'
                  ? $t('HEADER_LABEL_WELCOME_CHOOSE_TASK')
                  : startWorkingStatus == 'SELECT_TASKS_TO_FINISH'
                  ? $t('HEADER_LABEL_WELCOME_FINISH_TASK')
                  : $t(
                      'COMMON_LABEL_TIME_BLOCKS_EACH_TASK_INTO_THE_TIME_SLOT_YOU_WILL_DO_IT_TODAY'
                    )
              }}
            </span>
          </div>
          <div
            class="w-full h-full bg-current-50 bg-opacity-70 flex-center"
            style="max-height: 100%"
          >
            <!-- <img
              v-if="startWorkingStatus == 'SELECT_TASKS'"
              :src="rocket"
              style="width: 200px; height: 200px;"
              class="absolute bottom-0 right-1"
            />
            <img
              v-if="startWorkingStatus == 'SELECT_TASKS_TO_FINISH'"
              :src="finishTask"
              style="width: 200px; height: 200px;"
              class="absolute bottom-0 right-1"
            /> -->
            <SynAnimation
              v-if="startWorkingStatus == 'SELECT_TASKS'"
              name="rocket"
              stype="width: 200px; height: 200px;"
              class="absolute bottom-4"
            />
            <SynAnimation
              v-if="startWorkingStatus == 'SELECT_TASKS_TO_FINISH'"
              name="startWD3"
              stype="width: 200px; height: 200px;"
              class="absolute bottom-4"
            />
          </div>
        </div>

        <!-- RIGHT SIDE -->
        <div
          class="
            flex-1
            min-w-0
            h-full
            overflow-y-auto overflow-x-hidden
            small-scrollbar
          "
        >
          <!-- select_tasks_todo -->
          <!-- select_task_finish -->
          <div
            v-if="
              startWorkingStatus == 'SELECT_TASKS_TO_FINISH' &&
              showingTaskList.length < 1
            "
            class="px-4 py-8"
          >
            <SynAnimation
              name="startWD3"
              stype="width: 250px; height: 250px;"
            />
            <span class="text-xl px-4 text-current-700">
              {{ $t('START_WORKING_TITLE_FINISH_CHOOSE_TASK') }}
            </span>
          </div>

          <TaskSimpleTable
            v-else-if="
              startWorkingStatus == 'SELECT_TASKS' ||
              startWorkingStatus == 'SELECT_TASKS_TO_FINISH'
            "
            multiple-selectable
            is-filterable-in-column
            :task-list="filteredShowingTaskList"
            :selected-id-list="[...currentSelectedIds]"
            :column-list="[
              'URGENCY',
              'NAME',
              'GROUP',
              'DOMAIN',
              'DEADLINE',
              'CREATOR',
              'CREATED_TIME',
            ]"
            :sort-data-order-by-key="sortDataOrderByKey"
            :filter-data-by-key="filterDataByKey"
            is-start-working
            table-layout="table-fixed"
            @open-detail="(task, event) => openTaskDetail(task, event)"
            @choose="handleSelectedTask"
            @update-task-item="updateTaskItem"
            @on-click-sort="onClickSort"
            @on-filter-by-key-on-column="onFilterByKeyOnColumn"
            @on-reset-filter-on-column="onResetFilterOnColumn"
            @on-check-all="
              currentSelectedIds = filteredShowingTaskList?.map((t) => t?.id)
            "
            @on-un-check-all="currentSelectedIds = []"
          />

          <template v-else-if="startWorkingStatus == 'PLANNING_TODAY'">
            <TaskTimeBlockingSingle
              ref="taskTimeBlockingSingleRef"
              :source-type="ETaskListModule.USER"
              :events="showingTaskList"
              :time-zone="myTimeZone"
              @change-start-end-time="changeStartEndTime"
              @event-click="openTaskDetail"
            />
          </template>

          <TaskDetailDrawerMini
            ref="taskDetailPanelRef"
            @is-open-drawer-mini="(value) => (isOpenTaskDrawer = value)"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="p-4 flex w-full items-center justify-between"
        @click="taskDetailPanelRef?.onClosePanel()"
      >
        <div class="justify-start">
          <SynButton
            class="w-max"
            name-icon="plus"
            type-outline
            :label="$t('COMMON_LABEL_CREATE_TASK')"
            @click.stop="onCreateTaskGlobal"
          />
        </div>

        <div class="flex flex-col">
          <p class="font-medium text-center">
            <span class="text-gray-500">
              {{
                $t('HEADER_LABEL_WELCOME_START_TIME') || 'Today, you start at'
              }}:
            </span>

            <span class="text-current-500 px-1">
              <SynLabelDateTime format="time" :datetime="startLocalTime" />
            </span>
          </p>
          <!--          <marquee class="w-full" direction="right" scrollamount="12">-->
          <!--            <div class="bg-current w-1/5" style="height: 0.18rem"></div>-->
          <!--          </marquee>-->
          <div class="bar"></div>
        </div>

        <div
          class="flex items-center justify-end space-x-2"
          @click="taskDetailPanelRef?.onClosePanel()"
        >
          <SynButton
            type-outline
            :label="
              checkinStatusList[startWorkingStatus]?.previousText
                ? $t(checkinStatusList[startWorkingStatus]?.previousText)
                : ''
            "
            @click="onHandleGoToPreviousStep"
          />

          <SynButton
            v-if="checkinStatusList[startWorkingStatus]?.confirmText"
            class="w-max"
            :disabled="isDisableButtonSubmit"
            :is-loading="isLoading"
            :label="$t(checkinStatusList[startWorkingStatus]?.confirmText)"
            custom-class="pr-1"
            @click="onHandleGoToNextStep"
          >
            <SynIcon :custom-class="'w-6 h-6 fill-white'" name="arrow-right" />
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>

<style scoped>
.bar {
  width: 100%;
  height: 3px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transition: all linear;
}

.bar::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -30%;
  width: 20%;
  height: 100%;
  background-color: #34a5a5;
  animation: run 1.8s linear infinite;
  transition: all linear;
}

@keyframes run {
  to {
    left: 100%;
  }
}
</style>
