<script setup lang="ts">
import { computed, ref } from 'vue';
import { CalendarSourceId } from '@/domain/enums/CalendarEnum';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';

const props = defineProps<{
  event: any;
  hasTaskActions?: boolean;
  hasImageActions?: boolean;
  editable?: boolean;
}>();

const isPreview = ref(false);
const currentFileIndex = ref(0);

const attachments = computed(
  () => props.event?.attachments || props.event?.metadata?.attachments
);

const attachedFiles = computed(() => {
  if (!attachments.value?.files) return [];
  return attachments.value?.files
    ?.filter((attachment) => !String(attachment['type']).includes('audio'))
    .map((o) => {
      o.contentType = o.type;
      return o;
    });
});

const attachedAudios = computed(() => {
  if (!attachments.value?.files) return [];
  return attachments.value?.files
    ?.filter((attachment) => String(attachment['type']).includes('audio'))
    .map((o) => {
      o.contentType = o.type;
      return o;
    });
});

const genAudioUrl = (record) => {
  return URL.createObjectURL(record);
};

const onOpenFiles = (file, index) => {
  if (props.event?.sourceId === CalendarSourceId.googleCalendar) {
    return window.open(file?.fileUrl, '_blank');
  }

  currentFileIndex.value = index;
  isPreview.value = true;
};
</script>

<template>
  <div
    v-if="attachedAudios?.length > 0 || attachedFiles?.length > 0"
    class="flex items-start gap-4"
  >
    <div :title="$t('TASK_DETAIL_LABEL_ATTACHMENT')" class="flex-center py-4">
      <SynIcon name="Attach" class="fill-gray-500" />
    </div>
    <div
      class="
        flex
        items-center
        gap-1.5
        pt-3
        pb-1
        pr-2
        overflow-hidden
        small-scrollbar
      "
      style="max-height: 25vh"
    >
      <UploadPreview
        v-for="(file, index) in attachedFiles.filter((e) => !e.isDeleted)"
        :key="file"
        :file="file"
        readonly
        custom-class=""
        @on-select="onOpenFiles(file, index)"
      />
      <VigAudio
        v-for="record in attachedAudios?.filter((o) => !o?.noteAudioId)"
        :key="record"
        :path="record?.src || record?.fileUrl || genAudioUrl(record)"
        type="view"
      />
    </div>
  </div>

  <ModalMultipleFileViewer
    v-if="isPreview"
    :start-index="currentFileIndex"
    :files="attachedFiles"
    :has-task-actions="hasTaskActions"
    :has-image-actions="hasImageActions"
    :editable="editable"
    @on-close="isPreview = false"
  />
</template>
