<script setup lang="ts">
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import { onMounted, computed, ref } from 'vue';
import {
  ITaskSoftLocal,
  TASK_SORT_BY,
} from '@/application/constants/task.const';

const props = withDefaults(
  defineProps<{
    type?: 'STATUS' | 'URGENCY' | 'DEFAULT' | 'CUSTOM';
    tab?: any;
    readonly?: boolean;
    isCollapse?: boolean;
    isHiddenCollapseButton?: boolean;
    currentSortData?: ITaskSoftLocal;
    hiddenClass: string;
    isOnlySearch?: boolean;
  }>(),
  {
    type: 'DEFAULT',
    tab: {},
    readonly: false,
    isCollapse: false,
    currentSortData: undefined,
  }
);
const emit = defineEmits([
  'onCollapseBoard',
  'onEditTab',
  'onDeleteTab',
  'onAddNewTask',
  'onChangeIterate',
  'onChangeOrder',
  'onSearch',
]);

const isOpenAction = ref(false);
const taskSortByList = computed<any[]>(() => {
  if (props?.type === 'DEFAULT') return Object.values(TASK_SORT_BY);

  return Object.values(TASK_SORT_BY).filter((o) => o.key !== props.type);
});

const isHasSort = computed<boolean>(() => {
  return props.currentSortData?.key !== 'DEFAULT';
});
const isOpenMoreAction = computed<boolean>(() => {
  return isHasSort.value ? true : isOpenAction.value;
});

const isCurrentOrderBy = computed<'asc' | 'desc'>(() => {
  return props.currentSortData?.orderData?.order?.some((o) => o == 'asc')
    ? 'asc'
    : 'desc';
});

const keySearch = ref<string>('');

const isOpenSearch = ref<boolean>(!!props.isOnlySearch);

const onSearch = (event) => {
  emit('onSearch', event?.target?.value);
};
const onResetSearch = () => {
  keySearch.value = '';
  emit('onSearch', '');
};

const isCollapsed = computed<boolean>(() => {
  return props.tab?.isCollapse || props.isCollapse;
});

const searchInputTask = ref<any>(null);
onMounted(() => {});
</script>

<template>
  <div
    class="flex items-center space-x-1"
    :class="isOnlySearch ? 'w-full justify-end' : 'justify-center'"
  >
    <template v-if="!isOpenSearch && !isOnlySearch">
      <VigDropdown
        v-if="!isCollapsed"
        :arrow="false"
        placement="bottom-start"
        @on-dropdown-open="isOpenAction = true"
        @on-dropdown-close="isOpenAction = false"
      >
        <template #dropdown-toggle>
          <div
            class="
              px-2
              flex-center
              space-x-2
              h-6
              rounded
              cursor-pointer
              hover:bg-gray-100
            "
            :class="`${
              isOpenMoreAction
                ? 'fill-current-500 text-current-500 bg-current-50'
                : ' fill-gray-500 text-gray-500'
            }`"
          >
            <span class="text-sm">
              {{ $t(currentSortData?.nameCode) }}
            </span>
          </div>
        </template>
        <template #dropdown-menu>
          <section class="flex flex-col py-1 text-sm" style="min-width: 12rem">
            <div
              v-for="(item, index) in taskSortByList"
              :key="index"
              class="
                list-li
                flex
                items-center
                space-x-3
                fill-gray-500
                text-gray-800 text-sm
                dropdown-item
              "
              @click.stop="$emit('onChangeIterate', item)"
            >
              {{ $t(item?.nameCode) }}
            </div>
          </section>
        </template>
      </VigDropdown>
      <div
        v-if="isHasSort"
        class="
          px-2
          flex-center
          space-x-2
          h-6
          w-6
          rounded
          cursor-pointer
          hover:bg-gray-100
          text-lg
        "
        :class="`${
          isOpenMoreAction
            ? 'fill-current-500 text-current-500 bg-current-50'
            : 'hover-to-show__children fill-gray-500 text-gray-500'
        }`"
        @click.stop="
          $emit('onChangeOrder', isCurrentOrderBy == 'asc' ? 'desc' : 'asc')
        "
      >
        <span v-if="isCurrentOrderBy == 'asc'"> ↑ </span>
        <span v-else> ↓ </span>
      </div>
    </template>

    <div
      class="flex-center h-10"
      :class="[hiddenClass, isOnlySearch ? 'w-full ' : '']"
      style="min-width: 5rem"
    >
      <div
        class="items-center space-x-2"
        :class="[
          hiddenClass,
          isOnlySearch ? 'w-full justify-end' : 'justify-center',
        ]"
      >
        <SynSearch
          v-if="!isCollapsed"
          ref="searchInputTask"
          v-model="keySearch"
          :custom-class="[
            'fill-gray text-gray-500 hover:bg-gray-100',
            isOnlySearch ? 'w-full' : '',
          ]"
          icon-prefix="search"
          :placeholder="$t('COMMON_LABEL_SEARCH_PLACEHOLDER_TASK')"
          autofocus
          :width-max="isOnlySearch ? '' : '12rem'"
          :is-alway-show="isOnlySearch"
          @change="onSearch"
          @on-open="isOpenSearch = true"
          @on-close="isOpenSearch = false"
          @on-reset="onResetSearch"
        />
        <div
          v-if="!isHiddenCollapseButton"
          :class="[
            'flex-center w-6 h-6 rounded cursor-pointer hover:bg-gray-100',
          ]"
          @click="$emit('onCollapseBoard')"
        >
          <SynIcon
            :name="isCollapsed ? 'arrow-right' : 'arrow-left'"
            custom-class="w-5 h-5 fill-gray-500 text-gray-500"
          />
        </div>
      </div>
    </div>

    <VigDropdown
      v-if="!isCollapsed && !readonly"
      :arrow="false"
      @on-dropdown-open="isOpenAction = true"
      @on-dropdown-close="isOpenAction = false"
    >
      <template #dropdown-toggle>
        <div
          class="flex-center w-6 h-6 rounded cursor-pointer hover:bg-gray-100"
          :class="`${
            isOpenMoreAction
              ? 'fill-current-500 text-current-500 bg-current-50'
              : 'hover-to-show__children fill-gray-500 text-gray-500'
          }`"
        >
          <SynIcon
            name="dots-vertical"
            custom-class="w-4 h-4 fill-gray-500 text-gray-500"
          />
        </div>
      </template>
      <template #dropdown-menu>
        <section
          class="flex flex-col py-1 dropdown-item text-sm"
          style="min-width: 12rem"
        >
          <div
            class="
              list-li
              flex
              items-center
              space-x-3
              fill-gray-500
              text-gray-800 text-sm
            "
            @click.stop="$emit('onEditTab')"
          >
            <SynIcon
              name="edit"
              is-active
              custom-class="w-3.5 h-3.5 text-gray-500"
            />
            <span class="text-gray-600">
              {{ $t('COMMON_LABEL_EDIT') }}
            </span>
          </div>

          <div
            class="list-li-delete flex items-center space-x-3 text-sm"
            @click.stop="$emit('onDeleteTab')"
          >
            <SynIcon name="trash" is-active />
            <span class="">
              {{ $t('COMMON_LABEL_DELETE') }}
            </span>
          </div>
        </section>
      </template>
    </VigDropdown>

    <!-- <div
      v-if="!tab?.isCollapse|| isCollapse"
      :class="isOpenAction ? '' : 'hover-to-show__children'"
      class="flex-center w-6 h-6 rounded cursor-pointer hover:bg-gray-100"
      @click="$emit('onAddNewTask')"
    >
      <SynIcon name="plus" custom-class="w-3 h-3 fill-gray-500 text-gray-500" />
    </div> -->

    <slot name="suffix"></slot>
  </div>
</template>
