<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0,0,256,256"
  >
    <defs>
      <linearGradient
        id="color-1_ifP93G7BXUhU_gr1"
        x1="4.586"
        y1="14.586"
        x2="22.77"
        y2="32.77"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#ca4e2a"></stop>
        <stop offset="1" stop-color="#b63016"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(-25.6,-25.6) scale(1.2,1.2)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.33333,5.33333)">
          <path
            d="M8,24c0,9.941 8.059,18 18,18c9.941,0 18,-8.059 18,-18h-18z"
            fill="#d35230"
          ></path>
          <path
            d="M26,6v18h18c0,-9.941 -8.059,-18 -18,-18z"
            fill="#ff8f6b"
          ></path>
          <path d="M26,6c-9.941,0 -18,8.059 -18,18h18z" fill="#ed6c47"></path>
          <path
            d="M26,16.681c0,-2.033 -1.648,-3.681 -3.681,-3.681h-10.545c-2.357,3.044 -3.774,6.852 -3.774,11c0,5.116 2.145,9.723 5.571,13h8.747c2.034,0 3.682,-1.648 3.682,-3.681z"
            fill="#000000"
            opacity="0.05"
          ></path>
          <path
            d="M22.213,13.333h-10.688c-2.205,2.988 -3.525,6.669 -3.525,10.667c0,4.617 1.753,8.814 4.611,12h9.602c1.724,0 3.121,-1.397 3.121,-3.121v-16.425c-0.001,-1.723 -1.398,-3.121 -3.121,-3.121z"
            fill="#000000"
            opacity="0.07"
          ></path>
          <path
            d="M22.106,13.667h-10.83c-2.058,2.926 -3.276,6.484 -3.276,10.333c0,4.148 1.417,7.956 3.774,11h10.332c1.414,0 2.56,-1.146 2.56,-2.56v-16.213c0.001,-1.414 -1.146,-2.56 -2.56,-2.56z"
            fill="#000000"
            opacity="0.09"
          ></path>
          <path
            d="M22,34h-16c-1.105,0 -2,-0.895 -2,-2v-16c0,-1.105 0.895,-2 2,-2h16c1.105,0 2,0.895 2,2v16c0,1.105 -0.895,2 -2,2z"
            fill="url(#color-1_ifP93G7BXUhU_gr1)"
          ></path>
          <path
            d="M14.673,19.012h-4.673v10h2.024v-3.521h2.276c1.876,0 3.397,-1.521 3.397,-3.397v-0.058c0,-1.67 -1.354,-3.024 -3.024,-3.024zM15.57,22.358c0,0.859 -0.697,1.556 -1.556,1.556h-1.99v-3.325h1.99c0.859,0 1.556,0.697 1.556,1.556z"
            fill="#ffffff"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
