<script setup lang="ts">
import { computed } from 'vue';
import { DayoffType, DayoffExceptionType } from '@/domain/enums/DayoffEnums';
import DayoffStatusAtom from '@/ui/components/dayoff/atoms/DayoffStatusAtom.vue';
import DayoffTypeAtom from '@/ui/components/dayoff/atoms/DayoffTypeAtom.vue';
import DayoffDateSessionAtom from '@/ui/components/dayoff/atoms/DayoffDateSessionAtom.vue';
import DayoffExceptionTypeAtom from '@/ui/components/dayoff/atoms/DayoffExceptionTypeAtom.vue';

const props = defineProps({
  datas: {
    type: Array,
    default: () => [],
  },
  date: {
    type: [String, Number, Date],
    default: '',
  },
});

const currentDataDate = computed(() => props.datas);
const currentDate = computed(() => props.date);
</script>

<template>
  <div class="flex flex-col px-4 py-2">
    <div class="pb-2 text-sm">
      <span
        class="font-medium text-current-500"
        :class="currentDataDate?.length > 1 ? 'lowercase' : ''"
      >
        <span v-if="currentDataDate?.length > 1">{{
          currentDataDate?.length
        }}</span>
        {{ ' ' + $t('DAYOFF_REQUEST_TITLE') + ' - ' }}
      </span>
      <span>
        <SynLabelDateTime
          format="date"
          ignore-timezone
          :datetime="currentDate"
        />
      </span>
    </div>

    <template v-for="request in currentDataDate" :key="request">
      <div class="flex flex-col my-2">
        <!-- INFO -->
        <div class="flex space-x-1items-center justify-between">
          <div class="flex items-center space-x-1">
            <div class="flex flex-col">
              <div class="min-w-max">
                <DayoffTypeAtom
                  v-if="request?.type && request?.type !== DayoffType.EXCEPTION"
                  :type="request?.type"
                  size="small"
                  class="min-w-max w-max"
                />
                <div
                  v-if="
                    request?.type === DayoffType.EXCEPTION && request?.exception
                  "
                  class="flex space-x-1 items-center text-xs text-gray-500"
                >
                  <DayoffExceptionTypeAtom
                    is-active
                    class="text-xs min-w-max w-max"
                    :exception-type="request?.exception?.type"
                    size="small"
                  />
                </div>
              </div>
            </div>
            <DayoffStatusAtom
              v-if="request?.status"
              :status="request?.status"
              size="small"
            />
            <span
              v-if="request?.type !== 'EXCEPTION'"
              class="text-gray-500 text-xs"
            >
              (<DayoffDateSessionAtom
                is-text-only
                :date-session="request?.daySession"
              />)
            </span>
          </div>

          <div
            v-if="request?.dayAmount && request?.type !== DayoffType.EXCEPTION"
            class="text-xs text-gray-500"
          >
            {{ $t('DAYOFF_STATISTIC_USED_DAY', { total: request?.dayAmount }) }}
          </div>

          <div
            v-if="request?.type === DayoffType.EXCEPTION"
            class="min-w-max text-gray-500 text-2xs"
          >
            <span class="flex items-center space-x-1">
              <span>
                {{ $filters.dayjs(request?.exception?.startTime, ' hh:mm A') }}
              </span>
              <span
                v-if="
                  request?.exception?.type === DayoffExceptionType.BREAK_TIME
                "
              >
                - {{ $filters.dayjs(request.exception.endTime, 'hh:mm A') }}
              </span>
            </span>
          </div>
        </div>
        <!-- NOTE -->
        <div
          v-if="request?.requestNote"
          class="py-1 pl-2 flex text-xs text-gray-600"
        >
          <span style="min-width: fit-content" class="font-medium"
            >{{ $t('DAYOFF_DETAIL_LABEL_REASON') }}:</span
          >
          <span
            class="ml-1 text-overflow-hidden"
            :title="request?.requestNote"
            >{{ request?.requestNote }}</span
          >
        </div>
      </div>
    </template>
  </div>
</template>
