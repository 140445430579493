<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g>
        <path
          d="M10 4H6a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zM26 18h-4a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3v-4a3 3 0 0 0-3-3z"
          opacity="1"
          fill="none"
        ></path>
      </g>
      <g>
        <path
          d="m24.828 8-2.535-2.536a1 1 0 0 1 1.414-1.414l4.243 4.243a1 1 0 0 1 0 1.414l-4.243 4.243a1 1 0 1 1-1.414-1.414L24.828 10H17a1 1 0 1 1 0-2z"
          opacity="1"
          class=""
        ></path>
        <path
          fill-rule="evenodd"
          d="M6 4h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zM22 18h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1z"
          clip-rule="evenodd"
          opacity="1"
          class=""
        ></path>
        <path
          d="M16 23a1 1 0 0 0-1-1H7.172l2.535-2.535a1 1 0 0 0-1.414-1.415L4.05 22.293a1 1 0 0 0 0 1.414l4.243 4.243a1 1 0 1 0 1.414-1.415L7.172 24H15a1 1 0 0 0 1-1z"
          opacity="1"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
