<script setup lang="ts">
import { ITaskTodoItemStatus } from '@/application/types/task/task.types';
import { ref } from 'vue';

const CURRENT_COMPONENT_ID = 'todo-list-item';

defineProps<{
  title: string;
  dataItem?: any;
  textareaId: string;
  readonly?: boolean;
  editTitleOnly?: boolean;
  placeholder?: string;
  focusingPlaceholder?: string;
  canCopy?: boolean;
  maxTitleLength?: number;
  isSelectMode?: boolean;
  isDisabledEditTitle?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onChangeStatus', value: string): void;
  (e: 'update:title', value: string): void;
  (e: 'change', value: string): void;
  (e: 'remove'): void;
  (e: 'back'): void;
  (e: 'next'): void;
  (e: 'add', value: string): void;
  (e: 'delete', value: string): void;
  (e: 'deleteAfter'): void;
  (e: 'blur', value, isUpdated): void;
  (e: 'copy'): void;
  (e: 'onClickTitle'): void;
}>();

const onKeydown = (event) => {
  const inputElement = event?.target;
  if (!inputElement) return;
  const currentCursorPosition = inputElement?.selectionStart;
  const currentInputValueLength = inputElement?.value?.length;
  switch (event.keyCode) {
    case 13:
      {
        //method to prevent from default behaviour
        event.preventDefault();

        const substring = inputElement?.value?.substring(
          currentCursorPosition,
          currentInputValueLength
        );

        inputElement.value = inputElement?.value?.substring(
          0,
          currentCursorPosition
        );
        emit('update:title', inputElement.value);
        emit('add', substring);
      }
      break;
    case 8:
      {
        if (currentCursorPosition == 0) {
          emit('delete', inputElement?.value);
        }
      }
      break;
    case 46:
      {
        if (currentCursorPosition == currentInputValueLength)
          emit('deleteAfter');
      }
      break;
    case 38:
      {
        if (currentCursorPosition == 0) emit('back');
      }
      break;
    case 40:
      {
        if (currentCursorPosition == currentInputValueLength) emit('next');
      }
      break;
    default:
      break;
  }
};
const onKeyup = () => {
  // if ((e.keyCode == 8 || e.keyCode == 46) && listenDeleteKey.value) {
  //   emit('remove');
  // }
};
const isUpdateTitle = ref<boolean>(false);

const onUpdateModelValue = (event) => {
  isUpdateTitle.value = true;
  emit('update:title', event?.target?.value);
};

const isFocusing = ref<boolean>(false);
const onBlur = (event) => {
  emit('blur', event?.target?.value, !!isUpdateTitle.value);
  isUpdateTitle.value = false;
  isFocusing.value = false;
};

const onClickTitle = () => {
  emit('onClickTitle');
};
</script>
<template>
  <div
    :id="CURRENT_COMPONENT_ID"
    class="w-full flex-center h-auto relative"
    :class="{ 'todo-list-item-readonly': readonly }"
    style="min-height: 2.5rem"
  >
    <div class="flex-1 min-w-0 w-full flex items-start relative gap-2">
      <div class="flex-center rounded-l">
        <slot name="prefix-icon">
          <div v-if="!readonly" class="flex-center" style="width: 1rem">
            <span class="flex-center todo-list-item_hover-to-show">
              <SynIcon
                v-if="!editTitleOnly"
                name="dragable"
                class="fill-gray-500"
              />
              <div v-else class="h-4"></div>
            </span>
          </div>
          <div
            class="h-10 w-10 flex-center group"
            :class="{ 'pointer-events-none': readonly && !isSelectMode }"
            @click="$emit('onChangeStatus')"
          >
            <div
              class="h-4 w-4 flex-center rounded border ring-current-300"
              :class="{
                'bg-current-300':
                  dataItem?.status === ITaskTodoItemStatus.FINISHED &&
                  (!readonly || isSelectMode),
                'bg-gray-400':
                  dataItem?.status === ITaskTodoItemStatus.FINISHED &&
                  readonly &&
                  !isSelectMode,
                'cursor-pointer group-hover:ring-1 border-current-300':
                  !readonly || isSelectMode,
              }"
            >
              <SynIcon
                v-if="dataItem?.status === ITaskTodoItemStatus.FINISHED"
                name="check"
                class="text-white"
              />
            </div>
          </div>
        </slot>
      </div>
      <div class="flex-1 h-auto relative">
        <div
          class="w-full p-2"
          :style="`
          min-height: 2.5rem;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          overflow-wrap: break-word;
          resize: none;
          white-space: pre-wrap;
          height: auto;
          visibility:${isDisabledEditTitle ? 'visible' : 'hidden'} ;
          cursor:${isDisabledEditTitle ? 'pointer' : 'auto'} ;`"
          @click="onClickTitle"
        >
          {{ title }}
        </div>
        <textarea
          v-if="!isDisabledEditTitle"
          :id="textareaId"
          :value="title"
          :readonly="readonly"
          :placeholder="isFocusing ? focusingPlaceholder : placeholder"
          class="
            p-2
            absolute
            top-0
            left-0
            flex-1
            w-full
            h-full
            border-0
            hover:bg-gray-50
            focus:bg-gray-50 focus:outline-none focus:border-0 focus:ring-0
          "
          :class="{
            'cursor-pointer': !readonly,
          }"
          :style="`
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          overflow-wrap: break-word;
          resize: none;
          white-space: pre-wrap;
          overflow: hidden;`"
          :maxlength="maxTitleLength"
          @input="onUpdateModelValue"
          @change="$emit('change')"
          @keydown="onKeydown"
          @keyup="onKeyup"
          @blur="onBlur"
          @focus="isFocusing = true"
        />
      </div>
      <slot name="action">
        <div class="flex items-center todo-list-item_hover-to-show">
          <div
            v-if="canCopy"
            v-vig-tooltip="$t('COMMON_LABEL_COPY_TEXT') || 'Copy text'"
            class="
              flex-center
              h-9
              w-9
              cursor-pointer
              rounded-full
              hover:bg-gray-50
            "
            @click="$emit('copy')"
          >
            <SynIcon name="copy" :custom-class="'w-4 h-4 fill-gray-500'" />
          </div>
          <div
            v-if="!readonly && !editTitleOnly"
            class="
              flex-center
              h-9
              w-9
              cursor-pointer
              rounded-full
              hover:bg-gray-50
            "
            @click="$emit('remove')"
          >
            <SynIcon
              name="close-bold"
              :custom-class="'w-4 h-4 fill-gray-500'"
              size="small"
            />
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>
<style>
.todo-list-item_hover-to-show {
  visibility: hidden;
}

#todo-list-item:hover .todo-list-item_hover-to-show {
  visibility: unset;
}
</style>
