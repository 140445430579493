<script setup lang="ts">
import chatSupportingStore from '@/store/chat/chat-supporting';
import permissionStore from '@/store/permission';
import { computed } from 'vue';
import systemConfig from '@/application/constants/system-config.const';
import tictopConfig from '@/application/constants/tictop-config.const';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';

const emits = defineEmits(['onChatSupportClick']);

const _chatSupportingStore = chatSupportingStore();
const _permissionStore = permissionStore();

const canChatSupport = computed(
  () =>
    _permissionStore.tictopConfigs[
      tictopConfig.CHAT_CHAT_SUP_TICTOP_CHAT_SUPPORT_ALLOWED
    ] === '1' &&
    _permissionStore.allSystemConfigs &&
    _permissionStore.allSystemConfigs[
      systemConfig.CHAT_SYSTEM_C_CHAT_SUPPORT
    ] === '1' &&
    _permissionStore.allSystemConfigs[
      systemConfig.CHAT_SYSTEM_C_WEB_CHAT_ALLOWED
    ] === '1'
);

const { goToConversation } = useChatCommon();

const onChatSupportClick = async () => {
  const supportingConversation =
    await _chatSupportingStore.getSupportingUserConversation();

  goToConversation(supportingConversation);

  emits('onChatSupportClick');
};
</script>

<template>
  <button
    v-if="canChatSupport"
    v-vig-tooltip="$t('TICTOP_SUPPORT') || 'Tictop support'"
    type="button"
    @click="onChatSupportClick"
  >
    <slot></slot>
  </button>
</template>
