import BaseRepository from '@/base/repositories/BaseRepository';
import { IPrincipleRepository } from '@/domain/interfaces/repositories/IPrincipleRepository';
import { translate } from '@/ui/plugins/i18n/myi18n';
import dayjs from 'dayjs';
// import { ACTION } from '@/ui/hooks/apiHook';

export default class PrincipleRepository implements IPrincipleRepository {
    private static instance: PrincipleRepository;

    constructor() {}

    public static getInstance(): PrincipleRepository {
        if (!PrincipleRepository.instance) {
            // Get from local storage
            PrincipleRepository.instance = new PrincipleRepository();
        }

        return PrincipleRepository.instance;
    }
    getCurrentTime() {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/GetCurrentTime`,
            method: 'get',
        });
    }
    getDailyActions() {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/GetDailyActions`,
            method: 'get',
        });
    }
    getAllPrincipleByUserAndDay(
        date: undefined | string | null = null,
        departmentId?: number
    ) {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/getAllPrincipleByUserAndDay`,
            method: 'get',
            params: {
                date,
                dptId: departmentId,
            },
        });
    }

    getPrincipleTimeLimit() {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/GetPrincipleTimeLimit`,
            method: 'get',
        });
    }

    getPrincipleByUserId(userId, fromDate, toDate) {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/GetPrincipleByUserId?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`,
            method: 'get',
        });
    }

    getUserPrincipleStatisticByRange(userId, fromDate, toDate) {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/GetUserPrincipleStatisticByRange?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`,
            method: 'get',
        });
    }
    getUserWorkingStatus() {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/GetUserWorkingStatus`,
            method: 'get',
        });
    }
    startWorkingDay(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/StartWorkingDay`,
            method: 'post',
            data,
            // headers: {
            //   Action: ACTION.start,
            // },
        });
    }
    // type = 1 : start; type = 0 : stop
    startStopWorkingDay(data: { type: number; time?: any; location?: any }) {
        const messageCode =
            data.type == 0
                ? 'HEADER_LABEL_STOP_NOTIFICATION'
                : 'HEADER_LABEL_START_WD_NOTIFICATION';

        return BaseRepository.requestWithAuthorize(
            {
                url: `Principle/WorkingDay`,
                method: 'post',
                data,
            },
            {
                message: translate(messageCode, {
                    workingTime: `${dayjs(data?.time || new Date()).format(
                        'hh:mm A'
                    )}`,
                }),
            }
        );
    }
    // type = 1 : check in; type = 0 : check out
    checkInOutWorkingDay(data: { type: number }) {
        const messageCode =
            data.type == 0
                ? 'HEADER_LABEL_CHECKOUT_NOTIFICATION'
                : 'HEADER_LABEL_CHECKIN_NOTIFICATION';

        return BaseRepository.requestWithAuthorize(
            {
                url: `Principle/CheckInOut`,
                method: 'post',
                data,
            },
            {
                message: translate(messageCode, {
                    workingTime: `${dayjs(new Date()).format('hh:mm A')}`,
                }),
            }
        );
    }
    getWorkInfoByDateRange(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/GetWorkInfoByDateRange`,
            method: 'post',
            data,
        });
    }

    getExportTimekeepings(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/GetExportTimekeepings`,
            method: 'post',
            data,
        });
    }

    exportTimekeepings(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Principle/ExportTimekeepings`,
            method: 'post',
            data,
            responseType: 'blob',
        });
    }

    restartWk(data: { type: number }) {
        const messageCode = 'HEADER_LABEL_CHECKIN_NOTIFICATION';

        return BaseRepository.requestWithAuthorize(
            {
                url: `Principle/RestartWk`,
                method: 'post',
                data,
            },
            {
                message: translate(messageCode, {
                    workingTime: `${dayjs(new Date()).format('hh:mm A')}`,
                }),
            }
        );
    }
}
