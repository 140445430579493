<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          style=""
          d="M512,134.45v290.03c0,33.37-27.15,60.52-60.51,60.52H60.52C27.15,485,0,457.85,0,424.48V134.45   c0-33.37,27.15-60.52,60.52-60.52h390.97C484.85,73.93,512,101.08,512,134.45z"
          fill="#00b0b8"
          data-original="#ffe67b"
          class=""
        />
        <path
          style=""
          d="M512,134.45v290.03c0,33.37-27.15,60.52-60.51,60.52H255.77V73.93h195.72   C484.85,73.93,512,101.08,512,134.45z"
          fill="#00ccd6"
          data-original="#fcc454"
          class=""
        />
        <g>
          <path
            style=""
            d="M271,42v80.09c0,8.29-6.71,15-15,15c-0.08,0-0.15,0-0.23-0.01c-8.17-0.12-14.77-6.78-14.77-14.99    V42c0-8.2,6.6-14.87,14.77-14.99C255.85,27,255.92,27,256,27C264.29,27,271,33.72,271,42z"
            fill="#4af7ff"
            data-original="#537da0"
            class=""
          />
          <path
            style=""
            d="M82.263,27c-8.284,0-15,6.716-15,15v80.094c0,8.284,6.716,15,15,15s15-6.716,15-15V42    C97.263,33.716,90.547,27,82.263,27z"
            fill="#4af7ff"
            data-original="#537da0"
            class=""
          />
          <path
            style=""
            d="M169.133,27c-8.284,0-15,6.716-15,15v80.094c0,8.284,6.716,15,15,15s15-6.716,15-15V42    C184.133,33.716,177.417,27,169.133,27z"
            fill="#4af7ff"
            data-original="#537da0"
            class=""
          />
          <path
            style=""
            d="M344.318,27c-8.284,0-15,6.716-15,15v80.094c0,8.284,6.716,15,15,15s15-6.716,15-15V42    C359.318,33.716,352.602,27,344.318,27z"
            fill="#4af7ff"
            data-original="#3d5572"
            class=""
          />
          <path
            style=""
            d="M431.188,27c-8.284,0-15,6.716-15,15v80.094c0,8.284,6.716,15,15,15s15-6.716,15-15V42    C446.188,33.716,439.473,27,431.188,27z"
            fill="#4af7ff"
            data-original="#3d5572"
            class=""
          />
        </g>
        <g>
          <path
            style=""
            d="M308.18,206.42v65.32c0,10.79-8.78,19.57-19.58,19.57h-65.31c-10.79,0-19.58-8.78-19.58-19.57    v-65.32c0-10.79,8.79-19.57,19.58-19.57h65.31C299.4,186.85,308.18,195.63,308.18,206.42z"
            fill="#ffffff"
            data-original="#ffffff"
            class=""
          />
          <path
            style=""
            d="M152.152,186.849H86.839c-10.794,0-19.576,8.782-19.576,19.576v65.313    c0,10.794,8.782,19.577,19.576,19.577h65.313c10.794,0,19.576-8.782,19.576-19.577v-65.313    C171.728,195.63,162.947,186.849,152.152,186.849z"
            fill="#ffffff"
            data-original="#ffffff"
            class=""
          />
          <path
            style=""
            d="M426.612,186.849h-65.313c-10.794,0-19.576,8.782-19.576,19.576v65.313    c0,10.794,8.782,19.577,19.576,19.577h65.313c10.794,0,19.576-8.782,19.576-19.577v-65.313    C446.188,195.63,437.407,186.849,426.612,186.849z"
            fill="#4af7ff"
            data-original="#d9efff"
            class=""
          />
          <path
            style=""
            d="M308.18,345.61v65.31c0,10.8-8.78,19.58-19.58,19.58h-65.31c-10.79,0-19.58-8.78-19.58-19.58    v-65.31c0-10.79,8.79-19.57,19.58-19.57h65.31C299.4,326.04,308.18,334.82,308.18,345.61z"
            fill="#ffffff"
            data-original="#ffffff"
            class=""
          />
          <path
            style=""
            d="M152.152,326.036H86.839c-10.794,0-19.576,8.782-19.576,19.576v65.313    c0,10.794,8.782,19.577,19.576,19.577h65.313c10.794,0,19.576-8.782,19.576-19.577v-65.313    C171.728,334.817,162.947,326.036,152.152,326.036z"
            fill="#ffffff"
            data-original="#ffffff"
            class=""
          />
          <path
            style=""
            d="M441.795,330.429c-5.857-5.858-15.355-5.858-21.213,0l-26.626,26.626l-26.626-26.626    c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213l26.626,26.626l-26.626,26.626    c-5.858,5.858-5.858,15.355,0,21.213c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394l26.626-26.626l26.626,26.626    c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213l-26.626-26.626l26.626-26.626    C447.653,345.784,447.653,336.287,441.795,330.429z"
            fill="#ffffff"
            data-original="#ed5454"
            class=""
          />
        </g>
        <path
          style=""
          d="M256,137.09c-0.08,0-0.15,0-0.23-0.01V27.01C255.85,27,255.92,27,256,27c8.29,0,15,6.72,15,15v80.09   C271,130.38,264.29,137.09,256,137.09z"
          fill="#4af7ff"
          data-original="#3d5572"
          class=""
        />
        <path
          style=""
          d="M308.18,206.42v65.32c0,10.79-8.78,19.57-19.58,19.57h-32.83V186.85h32.83   C299.4,186.85,308.18,195.63,308.18,206.42z"
          fill="#4af7ff"
          data-original="#d9efff"
          class=""
        />
        <path
          style=""
          d="M308.18,345.61v65.31c0,10.8-8.78,19.58-19.58,19.58h-32.83V326.04h32.83   C299.4,326.04,308.18,334.82,308.18,345.61z"
          fill="#4af7ff"
          data-original="#d9efff"
          class=""
        />
      </g>
    </g>
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M451.484,73.934h-5.298V42c0-8.284-6.716-15-15-15s-15,6.716-15,15v31.934h-56.87V42c0-8.284-6.716-15-15-15   s-15,6.716-15,15v31.934H271V42c0-8.284-6.716-15-15-15s-15,6.716-15,15v31.934h-56.87V42c0-8.284-6.716-15-15-15s-15,6.716-15,15   v31.934h-56.87V42c0-8.284-6.716-15-15-15s-15,6.716-15,15v31.934h-6.744C27.147,73.934,0,101.082,0,134.451v290.033   C0,457.853,27.147,485,60.517,485h390.967C484.853,485,512,457.853,512,424.483V134.451C512,101.082,484.853,73.934,451.484,73.934   z M482,424.483C482,441.31,468.311,455,451.484,455H60.517C43.69,455,30,441.31,30,424.483V134.451   c0-16.827,13.69-30.517,30.517-30.517h6.744v18.16c0,8.284,6.716,15,15,15s15-6.716,15-15v-18.16h56.87v18.16   c0,8.284,6.716,15,15,15s15-6.716,15-15v-18.16H241v18.16c0,8.284,6.716,15,15,15s15-6.716,15-15v-18.16h58.316v18.16   c0,8.284,6.716,15,15,15s15-6.716,15-15v-18.16h56.87v18.16c0,8.284,6.716,15,15,15s15-6.716,15-15v-18.16h5.298   c16.827,0,30.516,13.69,30.516,30.517V424.483z"
          fill="#708698"
          data-original="#000000"
          style=""
          class=""
        />
        <path
          d="M288.601,172.249h-65.313c-10.794,0-19.576,8.782-19.576,19.576v65.313c0,10.794,8.782,19.576,19.576,19.576h65.313   c10.794,0,19.576-8.782,19.576-19.576v-65.313C308.177,181.031,299.396,172.249,288.601,172.249z M278.177,246.714h-44.465v-44.465   h44.465V246.714z"
          fill="#708698"
          data-original="#000000"
          style=""
          class=""
        />
        <path
          d="M152.15,172.249H86.837c-10.794,0-19.576,8.782-19.576,19.576v65.313c0,10.794,8.782,19.576,19.576,19.576h65.313   c10.794,0,19.576-8.782,19.576-19.576v-65.313C171.726,181.031,162.944,172.249,152.15,172.249z M141.726,246.714H97.261v-44.465   h44.465V246.714z"
          fill="#708698"
          data-original="#000000"
          style=""
          class=""
        />
        <path
          d="M426.61,172.249h-65.313c-10.794,0-19.576,8.782-19.576,19.576v65.313c0,10.794,8.782,19.576,19.576,19.576h65.313   c10.794,0,19.576-8.782,19.576-19.576v-65.313C446.186,181.031,437.404,172.249,426.61,172.249z M416.186,246.714h-44.465v-44.465   h44.465V246.714z"
          fill="#708698"
          data-original="#000000"
          style=""
          class=""
        />
        <path
          d="M288.601,311.436h-65.313c-10.794,0-19.576,8.782-19.576,19.576v65.313c0,10.794,8.782,19.576,19.576,19.576h65.313   c10.794,0,19.576-8.782,19.576-19.576v-65.313C308.177,320.218,299.396,311.436,288.601,311.436z M278.177,385.901h-44.465v-44.465   h44.465V385.901z"
          fill="#708698"
          data-original="#000000"
          style=""
          class=""
        />
        <path
          d="M152.15,311.436H86.837c-10.794,0-19.576,8.782-19.576,19.576v65.313c0,10.794,8.782,19.576,19.576,19.576h65.313   c10.794,0,19.576-8.782,19.576-19.576v-65.313C171.726,320.218,162.944,311.436,152.15,311.436z M141.726,385.901H97.261v-44.465   h44.465V385.901z"
          fill="#708698"
          data-original="#000000"
          style=""
          class=""
        />
        <path
          d="M441.792,315.83c-5.857-5.858-15.355-5.858-21.213,0l-26.626,26.626l-26.626-26.626c-5.857-5.858-15.355-5.858-21.213,0   c-5.858,5.858-5.858,15.355,0,21.213l26.626,26.626l-26.626,26.626c-5.858,5.858-5.858,15.355,0,21.213   c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394l26.626-26.626l26.626,26.626   c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213l-26.626-26.626l26.626-26.626   C447.65,331.185,447.65,321.688,441.792,315.83z"
          fill="#708698"
          data-original="#000000"
          style=""
          class=""
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
