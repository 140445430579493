import CryptoJS from 'crypto-js';
// import firebase from "firebase";
// import { RemoteConfigKey } from '@/ui/common/constants/constant'
import { isNumber } from '@/ui/hooks/commonFunction';
import { encodeBase64, decodeBase64 } from './custom-crypto';

export const encryptUserId = (rawUserId) => {
    // const text = rawUserId?.toString();
    // const _remoteCfgStore = remoteConfigStore();
    // const isEncode = _remoteCfgStore.getEncodeUserId();
    // const isId = isNumber(rawUserId);
    // if (!isEncode || !text || !isId) return rawUserId;

    // return encodeBase64(text);
    const text = rawUserId?.toString();
    const isId = isNumber(rawUserId);
    if (!text || !isId) return rawUserId;

    return encodeBase64(text);
    // return encrypt(text, _remoteCfgStore.getPublicEncryptKey());
};

export const decryptUserId = (ciphertext) => {
    // const remoteConfig = firebase.remoteConfig();

    // const aga = remoteConfig.getValue(RemoteConfigKey.EncodeUserId);

    // console.log("web_public_encrypt_key = ", aga.toString());

    // const _remoteCfgStore = remoteConfigStore();
    // const isEncode = _remoteCfgStore.getEncodeUserId();
    // const isId = isNumber(ciphertext);
    // if (!isEncode || !ciphertext || isId) return ciphertext;

    // return parseInt(decodeBase64(ciphertext));
    const isId = isNumber(ciphertext);
    if (!ciphertext || isId) return ciphertext;

    return parseInt(decodeBase64(ciphertext));
    // return parseInt(decrypt(ciphertext, _remoteCfgStore.getPublicEncryptKey()));
};

export const encryptGroupId = (rawGroupId) => {
    const text = rawGroupId?.toString();
    // const _remoteCfgStore = remoteConfigStore();
    // const isEncode = _remoteCfgStore.getEncodeGroupId();
    const isId = isNumber(rawGroupId);
    if (!text || !isId) return rawGroupId;

    return encodeBase64(text);
    // return encrypt(text, _remoteCfgStore.getPublicEncryptKey());
};

export const decryptGroupId = (ciphertext) => {
    // const _remoteCfgStore = remoteConfigStore();
    // const isEncode = _remoteCfgStore.getEncodeGroupId();
    const isId = isNumber(ciphertext);
    if (!ciphertext || isId) return ciphertext;

    return parseInt(decodeBase64(ciphertext));
    // return parseInt(decrypt(ciphertext, _remoteCfgStore.getPublicEncryptKey()));
};

const JsonFormatter = {
    stringify: function (cipherParams) {
        // // create json object with ciphertext
        // let jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
        // // optionally add iv or salt
        // if (cipherParams.iv) {
        //   jsonObj.iv = cipherParams.iv.toString();
        // }
        // if (cipherParams.salt) {
        //   jsonObj.s = cipherParams.salt.toString();
        // }
        // // stringify json object
        // return JSON.stringify(jsonObj);
        const ct = cipherParams.ciphertext.toString(CryptoJS.enc.Hex);
        const iv = cipherParams.iv.toString();
        const s = cipherParams?.salt.toString();
        return `${ct}-${iv}-${s}`;
    },
    parse: function (jsonStr) {
        // // parse json string
        // var jsonObj = JSON.parse(jsonStr);
        // // extract ciphertext from json object, and create cipher params object
        // var cipherParams = CryptoJS.lib.CipherParams.create({
        //   ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
        // });
        // // optionally extract iv or salt
        // if (jsonObj.iv) {
        //   cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
        // }
        // if (jsonObj.s) {
        //   cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
        // }
        // return cipherParams;
        const data = jsonStr.split('-');
        return CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Hex.parse(data[0]),
            iv: CryptoJS.enc.Hex.parse(data[1]),
            salt: CryptoJS.enc.Hex.parse(data[2]),
        });
    },
};
export const encrypt = (rawText, key) => {
    const text = rawText?.toString();
    if (!text) return rawText;

    // var ciphertext = CryptoJS.AES.encrypt(text, key).toString();
    var ciphertext = CryptoJS.AES.encrypt(text, key, {
        format: JsonFormatter,
    });

    return ciphertext.toString();
};

export const decrypt = (ciphertext, key) => {
    const textArr = ciphertext.split('-');
    if (!ciphertext || textArr.length !== 3) return ciphertext;
    // var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), key);
    // var originalText = bytes.toString(CryptoJS.enc.Utf8);

    const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
        format: JsonFormatter,
    });
    var originalText = decrypted.toString(CryptoJS.enc.Utf8);
    return originalText;
};
