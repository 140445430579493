<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import UploadPhoto from '@/ui/components/photo-upload/UploadPhoto.vue';
import ChoosePhotoDefault from '@/ui/components/photo-upload/ChoosePhotoDefault.vue';
import userStore from '@/store/user';
// import UserService from '@/application/services/UserService';
// import orgStore from '@/store/organization';
// import { UserCreatePayload } from '@/domain/entities/PayloadClass/UserPayloadClass';
// import { getCurrentUserId } from '@/ui/hooks/storageHook';
const props = defineProps({
  profileId: {
    type: [String, Number],
    default: null,
  },
});

const emit = defineEmits(['cancel']);

// const info = ref({} as any);
// const _orgStore = orgStore();
const _userStore = userStore();
const fileName = computed(() => _userStore.fileName);
const userIdentity = computed(() => _userStore.userIdentity);

const isChooseAvatar = ref(false);
const file = ref(null as any);
const isLoading = ref(false);
const isResetForm = ref(false);
// const avatarNew = ref('');

const typeSetAvatar = ref({
  UPLOAD_AVATAR: {
    isActive: true,
    labelCancel: 'COMMON_LABEL_SKIP',
    labelSave: 'COMMON_LABEL_SAVE',
  },
  CHOOSE_AVATAR: {
    isActive: false,
    labelCancel: 'COMMON_LABEL_CANCEL',
    labelSave: 'COMMON_LABEL_SAVE',
  },
});

onMounted(async () => {});

const onSkip = () => {
  typeSetAvatar.value['UPLOAD_AVATAR'].isActive = false;
  typeSetAvatar.value['CHOOSE_AVATAR'].isActive = true;
  isChooseAvatar.value = false;
};

const onChooseImage = (binary) => {
  file.value = binary;
  isChooseAvatar.value = true;
};

const onSave = async () => {
  isLoading.value = true;

  if (file.value) {
    try {
      await _userStore.uploadAvatarAws(file.value);
      // info.value.avatar = fileName.value;
      // infoUserUpdate.value.avatar = fileName.value;
    } catch (error) {
      isLoading.value = false;
      settingGlobalModal({
        type: 'confirm',
        title: '',
        content: translate('COMMON_ERROR_UPLOAD_AVATAR'),
        confirmable: true,
        closeable: true,
      });
      const anser = await ask();
      if (anser) {
        emit('cancel', false);
      }
      return;
    }
  }
  try {
    // await _userStore.saveProfile(infoUserUpdate.value);
    await _userStore.updateAvatarUser(props.profileId, fileName.value);
    isLoading.value = false;
    emit('cancel', false);
    return;
  } catch (error) {
    isLoading.value = false;
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('COMMON_ERROR_UPLOAD_AVATAR'),
      confirmable: true,
      closeable: true,
    });
    const anser = await ask();
    if (anser) {
      emit('cancel', false);
    }
    return;
  }
};

const onCancel = () => {
  if (typeSetAvatar.value['UPLOAD_AVATAR']?.isActive) {
    typeSetAvatar.value['UPLOAD_AVATAR'].isActive = false;
    typeSetAvatar.value['CHOOSE_AVATAR'].isActive = true;
    isChooseAvatar.value = false;
  } else {
    emit('cancel', false);
  }
};
</script>

<template>
  <syn-modal
    z-index="z-50"
    container-class="h-2/3"
    disable-click-outside
    :style-body="
      typeSetAvatar['UPLOAD_AVATAR']?.isActive
        ? 'p-6 overflow-y-hidden flex-center'
        : 'pl-2 overflow-y-hidden flex-center'
    "
    @cancel="onCancel"
  >
    <template #header>
      <div class="flex flex-col items-center" style="width: 400px">
        <p>{{ $t('PROFILE_MODAL_FIRST_LOGIN_TITLE') }}</p>
        <p class="text-xs font-normal">
          {{ $t('COMMON_SUB_LABEL_UPDATE_PROFILE') }}
        </p>
      </div>
    </template>
    <template #body>
      <UploadPhoto
        v-if="typeSetAvatar['UPLOAD_AVATAR']?.isActive"
        :default-images="[userIdentity?.avatarUrl]"
        :is-reset="isResetForm"
        @choose-image="onChooseImage"
      />
      <ChoosePhotoDefault
        v-if="typeSetAvatar['CHOOSE_AVATAR']?.isActive"
        @choose-image="onChooseImage"
      />
    </template>

    <template #footer>
      <div class="p-4 flex justify-end pt-2 items-center space-x-2">
        <syn-button
          v-if="typeSetAvatar['UPLOAD_AVATAR']?.isActive && !isChooseAvatar"
          type-outline
          :label="$t('COMMON_LABEL_SKIP')"
          @click="onSkip"
        ></syn-button>

        <syn-button
          v-if="
            (typeSetAvatar['UPLOAD_AVATAR']?.isActive && isChooseAvatar) ||
            typeSetAvatar['CHOOSE_AVATAR']?.isActive
          "
          type-text
          color="gray"
          :label="$t('COMMON_LABEL_CANCEL')"
          @click="onCancel"
        >
        </syn-button>

        <syn-button
          v-if="
            (typeSetAvatar['UPLOAD_AVATAR']?.isActive && isChooseAvatar) ||
            typeSetAvatar['CHOOSE_AVATAR']?.isActive
          "
          :disabled="!isChooseAvatar ? true : false"
          :is-loading="isLoading"
          :label="$t('COMMON_LABEL_SAVE')"
          @click="onSave"
        >
        </syn-button>
      </div>
    </template>
  </syn-modal>
</template>
