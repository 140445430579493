import supportStore from '@/store/support';
import { computed, ref } from 'vue';

export default function helpComposables() {
    const _supportStore = supportStore();

    const isShowHelpPanel = computed(() => _supportStore.isOpenPanelHelp);
    const loadings = computed(() => _supportStore.loadings);
    const allWhatNews = computed(() => _supportStore.allWhatNews);
    const videoSpecialList = computed(
        () => _supportStore.videoSpecialList?.files
    );
    const folders = ref([] as any);
    const foldersByIds = ref({} as any);
    const videoActive = computed(() => _supportStore.videoActive);
    const allFaqs = ref([] as any);
    const allSpecialFaq = ref([] as any);
    const allFaqsByIds = ref({} as any);
    const allSpecialFaqByIds = ref({} as any);
    const allTopics = computed(() => _supportStore.allTopics);
    const allTopicsByIds = ref({} as any);

    const initDataHome = async () => {
        await _supportStore.getPlaylistYoutubeSpecialFiles();
        await _supportStore.getAllWhatNew();
        await getAllSpecialFaqs();
        await getFilesVideo();
        await getAllFaqs();
    };

    const getFilesVideo = async () => {
        const res: any = await _supportStore.getFilesVideo();

        folders.value = res?.files?.map((el) => {
            return {
                ...el,
                isCollapse: false,
            };
        });

        folders.value?.forEach((o) => {
            foldersByIds.value[o?.id] = o;
        });
    };

    const getAllFaqs = async () => {
        await _supportStore.getAllFaqs();
        allFaqs.value = _supportStore.allFaqs;
        allFaqsByIds.value = _supportStore.allFaqsByIds;
        allTopicsByIds.value = _supportStore.allTopicsByIds;
    };

    const getAllSpecialFaqs = async () => {
        await _supportStore.getSpecialFaqs();
        allSpecialFaq.value = _supportStore?.allSpecialFaq;
        allSpecialFaqByIds.value = _supportStore?.allSpecialFaqByIds;
    };

    const onShowMiniPlayer = (folder, video) => {
        _supportStore.setVideoActive({
            folderId: folder || '',
            video: video || {},
        });
        _supportStore.setOpenPanelHelp(false);
    };

    const onExpandVideo = async (folderId, videoActive) => {
        console.log('videoActive', videoActive);
        _supportStore.setOpenPanelHelp(true);
        _supportStore.setVideoActive({});
        await getFilesVideo();
        foldersByIds.value[folderId].isCollapse = true;
    };

    const onClose = () => {
        _supportStore.setOpenPanelHelp(false);
        _supportStore.setVideoActive({});
    };

    const onClosePanel = () => {
        _supportStore.setOpenPanelHelp(false);
    };

    const onShowFilesByFolder = async (idFolder) => {
        foldersByIds.value[idFolder].isCollapse =
            !foldersByIds.value[idFolder]?.isCollapse;

        if (!foldersByIds.value[idFolder].isCollapse) return;

        foldersByIds.value[idFolder].isLoading = true;

        const listVideo = await _supportStore.getFilesVideo(idFolder);

        foldersByIds.value[idFolder].videos = listVideo?.files;
        foldersByIds.value[idFolder].isLoading = false;
    };

    const onShowAnswerByQuestion = (idQuestion, topicId = '') => {
        allFaqsByIds.value[idQuestion].isCollapse =
            !allFaqsByIds.value[idQuestion].isCollapse;
        if (topicId) {
            allTopicsByIds.value[topicId]?.qnas?.forEach((o) => {
                if (o?.id === idQuestion) o.isCollapse = !o.isCollapse;
            });
        }
    };

    const onShowAnswerByQuestionSpecial = (idQuestion) => {
        allSpecialFaqByIds.value[idQuestion].isCollapse =
            !allSpecialFaqByIds.value[idQuestion].isCollapse;
    };

    return {
        isShowHelpPanel,
        loadings,
        videoSpecialList,
        folders,
        foldersByIds,
        videoActive,
        allFaqs,
        allTopics,
        allTopicsByIds,
        allFaqsByIds,
        allSpecialFaqByIds,
        allWhatNews,
        initDataHome,
        onShowMiniPlayer,
        onExpandVideo,
        onClosePanel,
        onShowFilesByFolder,
        onShowAnswerByQuestion,
        onShowAnswerByQuestionSpecial,
        getAllFaqs,
        onClose,
    };
}
