<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import GroupDomainTree from '@/ui/modules/domains/component/GroupDomainTree.vue';
import groupStore from '@/store/group';
import GroupById from '@/ui/components/group/GroupById.vue';

const _groupStore = groupStore();
const allGroup = computed(() => {
  return _groupStore.allGroup;
});
const allGroupIds = computed(() => {
  return _groupStore.allGroup?.map((g) => {
    return g.id;
  });
});
onMounted(() => {
  console.log('allGroup.value', allGroup.value);
  onSelect(allGroup.value?.length > 0 ? allGroup.value[0]?.id : 0);
});

const currentGroupId = ref(0);
const onSelect = (groupId) => {
  currentGroupId.value = groupId;
};

onSelect(allGroup.value?.length > 0 ? allGroup.value[0]?.id : 0);
</script>
<template>
  <div class="h-full w-full flex-1 min-h-0 flex divide-x overflow-hidden">
    <div
      class="max-h-full min-h-0 overflow-y-auto small-scrollbar space-y-2"
      style="width: 25rem"
    >
      <div
        v-for="groupId in allGroupIds"
        :key="groupId"
        class="p-2 flex items-center space-x-2 cursor-pointer rounded text-sm"
        :class="
          currentGroupId === groupId
            ? 'bg-current-50 border-l-4 border-current'
            : 'hover:bg-gray-100'
        "
        @click="onSelect(groupId)"
      >
        <GroupById
          :group-id="groupId"
          is-show-members
          is-show-private-group-signal
          avatar-class="w-8 h-8"
        />
      </div>
    </div>
    <div class="flex-1 min-h-0 overflow-hidden">
      <GroupDomainTree v-if="currentGroupId" :group-id="currentGroupId" />
    </div>
  </div>
</template>

<style>
.outline-red {
  outline: 1px solid red;
}

.domain-min-height {
  min-height: 82px;
}
</style>
