<script setup lang="ts"></script>

<template>
  <div class="p-2 rounded shadow-sm bg-white relative">
    <div class="w-full flex flex-col space-y-4 animate-pulse">
      <div class="w-1/3 rounded h-2 bg-gray-200"></div>

      <span class="w-2/3 rounded h-2 bg-gray-200"> </span>

      <div class="w-1/3 rounded h-2 bg-gray-200"></div>
      <div class="w-full flex justify-end">
        <div class="w-1/4 rounded h-2 bg-gray-200"></div>
      </div>
    </div>
  </div>
</template>
