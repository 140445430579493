import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import ChatService from '@/application/services/ChatService';
import myProfileStore from '@/store/auth/my-profile';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { chain, values } from 'lodash';
import { formatDate } from '@/ui/plugins/filters';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import chatStore from '@/store/chat/index';
import organizationStore from '@/store/organization';

const EXTERNAL_ORG_ID = 100;

interface ChatExternal {
    externalOrgId: number;
    externalConversationsDraft: { [id: string]: ChatConversationModel };
}

export default defineStore({
    id: storeId.chatExternal,
    state: () =>
        ({
            externalOrgId: EXTERNAL_ORG_ID,
            externalConversationsDraft: {},
        } as ChatExternal),
    getters: {
        isExternalChat() {
            return (converId) =>
                chatStore().getConversationInfo(converId)?.externalChat;
        },
        getExternalUserId() {
            return (converId) => {
                const converInfo = chatStore().getConversationInfo(converId);
                const myUserId = myProfileStore().myProfile?.id;

                return Object.keys(converInfo?.members || {}).find(
                    (memberId) => memberId !== `${myUserId}`
                );
            };
        },
    },
    actions: {
        getExternalUserConversation(externalUser) {
            // Get conversation by external user
            const existedConver = Object.entries(chatStore().userConversations)
                .filter(
                    ([id, conver]: [string, any]) =>
                        id &&
                        conver?.externalChat &&
                        conver?.externalOrgId ===
                            externalUser?.organizationId &&
                        conver?.members &&
                        conver?.members[externalUser?.userId]
                )
                .map(([id, conver]: [string, any]) => ({ id, ...conver }))
                .find(() => true);

            // If has conversation, go to it
            if (existedConver) return existedConver;

            // If no conversation, create a draft
            const converId = ChatService.getNewConversationId(
                this.externalOrgId
            );
            const myProfile = myProfileStore().myProfile;
            const organizationInfo = organizationStore().organizationInfo;

            this.externalConversationsDraft[converId] = {
                id: converId,
                ..._prepareExternalMember(externalUser),
                members: {
                    [`${externalUser?.userId}`]:
                        _prepareExternalMember(externalUser),
                    [`${myProfile?.id}`]: _prepareExternalMember(
                        myProfile,
                        organizationInfo
                    ),
                },
            };

            return this.externalConversationsDraft[converId];
        },
        addMessage(converId, message) {
            const userId = myProfileStore().myProfile?.id;

            this._verifyToCreateConversation(this.externalOrgId, converId);

            _processMessageFilesName(
                chatStore().getConversationInfo(converId),
                message
            );

            return chatStore().addMessage(
                this.externalOrgId,
                converId,
                userId,
                message
            );
        },

        // USER CONVERSATION
        setSeenUserConversation(converId) {
            const userId = myProfileStore().myProfile?.id;
            const userOrgId =
                chatStore().getConversationInfo(converId)?.externalOrgId;

            return ChatService.setSeenUserConversation(
                userOrgId,
                userId,
                converId
            );
        },

        // PRIVATE METHODS
        async _verifyToCreateConversation(orgId, converId) {
            const draftConverInfo = this.externalConversationsDraft[converId];
            if (!draftConverInfo) return;

            const userId = myProfileStore().myProfile?.id;

            await ChatService.addConversation(
                orgId,
                converId,
                draftConverInfo,
                userId
            );

            // Add user conversation locally
            chatStore().addUserConversation(converId, {
                ...draftConverInfo,
                createdDate: new Date(),
            });

            // Remove conversation from Draft
            delete this.externalConversationsDraft[converId];
        },
    },
});

const _processMessageFilesName = (converInfo, message) => {
    if (!message?.files?.length) return;

    const pastedPhotoIdxs = message.files.reduce((arr, file, index) => {
        if (file?.isPasted && file?.name === 'image.jpeg') arr.push(index);
        return arr;
    }, []);

    const fileName = _genImageName(converInfo);

    if (pastedPhotoIdxs.length === 1) {
        const photoIdx = pastedPhotoIdxs[0];
        const msgFile = message.files[photoIdx];
        message.files[photoIdx] = new File([msgFile], fileName, {
            type: msgFile?.type,
        });
    } else {
        pastedPhotoIdxs.forEach((photoIdx, index) => {
            const msgFile = message.files[photoIdx];
            const msgFileName = fileName.replace(
                '.jpeg',
                ` (${index + 1}).jpeg`
            );
            message.files[photoIdx] = new File([msgFile], msgFileName, {
                type: msgFile?.type,
            });
        });
    }
};

const _genImageName = (converInfo) => {
    let chatName;

    if (converInfo?.isGroup) {
        chatName = ignoreUnicode(converInfo?.name, false);
    } else {
        const memberName = chain(values(converInfo?.members))
            .map((member) => member?.name?.split(' ').pop())
            .sortBy()
            .join('-');

        chatName = ignoreUnicode(memberName, false);
    }

    const time = formatDate(new Date(), 'YYYY-MM-DD hh.mm A');

    return `Photo-Chat ${chatName} ${time}.jpeg`;
};

const _prepareExternalMember = (externalUser, externalOrgInfo: any = null) => {
    return {
        name: externalUser?.fullName,
        avatar: externalUser?.avatarUrl,
        externalChat: true,
        externalOrgId: externalUser?.organizationId || externalOrgInfo?.id,
        externalOrgName:
            externalUser?.organizationName || externalOrgInfo?.name,
        externalOrgAvatar:
            externalUser?.organizationLogoUrl || externalOrgInfo?.logoUrl,
        externalOrgModel:
            externalUser?.organizationModel || externalOrgInfo?.model,
    };
};
