<template>
  <svg
    v-if="!isActive"
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 322 322"
    style="enable-background: new 0 0 322 322"
    xml:space="preserve"
  >
    <g transform="translate(0 -562.36)">
      <g>
        <g>
          <path
            d="M161.3,562.36C72.5,562.36,0,634.86,0,723.66c0,88.7,72.5,160.7,161.3,160.7c88.7,0,160.7-72,160.7-160.7
				C322,634.86,250,562.36,161.3,562.36z M161.3,856.46c-74.1,0-133.4-59.4-133.4-132.9c-0.1-74,59.3-133.4,133.4-133.4l0,0
				c73.5,0,132.9,59.4,132.9,133.4C294.2,797.06,234.8,856.46,161.3,856.46z"
          />
          <path
            d="M235.9,651.66L235.9,651.66c-4.2,0-8.4,2.1-11,5.3L135,760.36l-38.3-38.3c-13.1-13.1-33.1,6.8-20,20l48.9,48.9
				c5.8,5.8,15.2,5.3,20.5-0.5l100.3-115C254.2,666.36,247.9,651.66,235.9,651.66z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
  >
    <path
      d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M16.2,10.3l-4.8,4.8c-0.4,0.4-1,0.4-1.4,0l0,0l-2.2-2.2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l1.5,1.5l4.1-4.1c0.4-0.4,1-0.4,1.4,0C16.6,9.3,16.6,9.9,16.2,10.3z"
    />
    \
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
