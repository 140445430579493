<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import Gantt, { VIEW_MODE } from '@/ui/pages/tasks/gantt';
import dayjs from 'dayjs';
// import { TASK_LIST } from '@/ui/pages/tasks/gantt/mock-data';
import taskDrawerStore from '@/store/task/drawer';
import {
  formatTaskCode,
  getDetailTaskUrl,
  sortDefaultTaskList,
  TaskUrgency,
} from '@/ui/hooks/taskHook';
import { taskStates } from '@/ui/hooks/taskHook';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import taskListStore from '@/store/task/task-list-store';
import { ETaskListModule } from '@/application/types/task/task.types';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { getCurrentLanguage } from '@/ui/hooks/storageHook';
import GroupById from '@/ui/components/group/GroupById.vue';
import { keySearch } from '@/ui/modules/task/table/task-list-state';
import { arrayOrderBy, stringNomalize } from '@/ui/hooks/commonFunction';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import getUserInfo from '@/ui/helpers/user-helper';
import taskStore from '@/store/task';
import {
  GANTT_TABLE_COLUMN_LIST,
  ITaskSoftLocal,
  TASK_SORT_BY,
} from '@/application/constants/task.const';
import FilterTask from '@/ui/modules/task/filter/FilterTask.vue';
import localConfigsStore from '@/store/local-configs';
import ModalDownloadResult from '@/ui/modules/task/export/ModalDownloadResult.vue';
import GanttTableTh from '@/ui/pages/tasks/gantt/GanttTableTh.vue';

const props = defineProps<{
  ownerId: number | string;
  sourceType: ETaskListModule;
}>();
const DATE_FORMAT = 'YYYY-MM-DD';
const _taskDrawerStore = taskDrawerStore();
const _taskListStore = taskListStore();
const _localConfigsStore = localConfigsStore();
const isDownloadGanttchart = ref(false);
const ganttChartExportData = ref();

const localConfigs = computed<any>(() => _localConfigsStore.localConfigs);
const getDateRange = (s, e) => {
  if (!s && !e) return { start: null, end: null };
  const _start = s ? dayjs(s) : null;
  const _end = e ? dayjs(e) : null;

  const start =
    _start !== null
      ? _start.format(DATE_FORMAT)
      : _end !== null
      ? _end.format(DATE_FORMAT)
      : null;
  const end =
    _end !== null
      ? _end.format(DATE_FORMAT)
      : _start !== null
      ? _start.format(DATE_FORMAT)
      : null;

  return { start, end };
};

const allTaskByIds = computed<any>(() => {
  return taskStore().allTaskByIds;
});
const taskAllList = computed(() => {
  return _taskListStore.taskAllList
    ?.map((t) => {
      const _taskDetail = allTaskByIds.value[t?.id];

      return {
        ..._taskDetail,
        originStart: _taskDetail?.startTime
          ? dayjs(_taskDetail.startTime).format(DATE_FORMAT)
          : null,
        originEnd: _taskDetail?.scheduleTime
          ? dayjs(_taskDetail.scheduleTime).format(DATE_FORMAT)
          : null,
        ...getDateRange(_taskDetail?.startTime, _taskDetail?.scheduleTime),
      };
    })
    ?.filter((o) => o?.start || o?.end);
});

const filterDateObj = ref<{ startDate: string; endDate: string }>({
  startDate: dayjs().utc().set('day', -45).startOf('day').format(),
  endDate: dayjs().utc().set('day', 45).startOf('day').format(),
});

const currentSortData = ref<ITaskSoftLocal>({
  ...TASK_SORT_BY.DEFAULT,
} as ITaskSoftLocal);

const currentFilterPayload = ref<any>({});
const chartData = computed(() => {
  let resultList = taskAllList.value
    ?.filter((t) => {
      return (
        (t?.startTime &&
          dayjs(filterDateObj.value.startDate).diff(dayjs(t?.startTime)) <= 0 &&
          dayjs(filterDateObj.value.endDate).diff(dayjs(t?.startTime)) >= 0) ||
        (t?.scheduleTime &&
          dayjs(filterDateObj.value.startDate).diff(dayjs(t?.scheduleTime)) <=
            0 &&
          dayjs(filterDateObj.value.endDate).diff(dayjs(t?.scheduleTime)) >= 0)
      );
    })
    ?.map((task) => {
      return {
        ...task,
        progress: 20,
        dependencies: '', // example: '123, 34'
        custom_class: 'bar-milestone', // optional
      };
    });
  if (keySearch.value)
    resultList = resultList?.filter(
      (t) =>
        stringNomalize(t.name?.toLowerCase()).includes(
          stringNomalize(keySearch.value?.toLowerCase())
        ) ||
        stringNomalize(t.code?.toLowerCase()).includes(
          stringNomalize(keySearch.value?.toLowerCase())
        ) ||
        stringNomalize(t.displayName?.toLowerCase()).includes(
          stringNomalize(keySearch.value?.toLowerCase())
        )
    );
  if (currentFilterPayload.value?.assigneeIds) {
    resultList = resultList?.filter((t) =>
      currentFilterPayload.value?.assigneeIds
        ?.split(',')
        .some((aId) => aId == t.assigneeId)
    );
  }
  if (currentFilterPayload.value?.creatorIds) {
    resultList = resultList?.filter((t) =>
      currentFilterPayload.value?.creatorIds
        ?.split(',')
        .some((cId) => cId == t.creatorId)
    );
  }
  if (currentFilterPayload.value?.status) {
    resultList = resultList?.filter((t) =>
      currentFilterPayload.value?.status
        ?.split(',')
        .some((s) => s == t.taskLife)
    );
  }
  if (currentFilterPayload.value?.urgency) {
    resultList = resultList?.filter((t) =>
      currentFilterPayload.value?.urgency
        ?.split(',')
        .some((u) => u == t.urgency)
    );
  }
  if (currentFilterPayload.value?.createdFromDate) {
    resultList = resultList?.filter(
      (t) => dayjs(t.createdFromDate).diff(dayjs(t?.creationTime)) <= 0
    );
  }
  if (currentFilterPayload.value?.createdToDate) {
    resultList = resultList?.filter(
      (t) => dayjs(t.createdToDate).diff(dayjs(t?.creationTime)) >= 0
    );
  }
  if (currentFilterPayload.value?.updatedFromDate) {
    resultList = resultList?.filter(
      (t) => dayjs(t.updatedFromDate).diff(dayjs(t?.lastModificationTime)) <= 0
    );
  }
  if (currentFilterPayload.value?.updatedToDate) {
    resultList = resultList?.filter(
      (t) => dayjs(t.updatedToDate).diff(dayjs(t?.lastModificationTime)) >= 0
    );
  }

  const currentIterate =
    currentSortData.value?.key === 'DEFAULT'
      ? []
      : currentSortData.value?.orderData?.iterate || [];
  const currentOrder =
    currentSortData.value?.key === 'DEFAULT'
      ? []
      : currentSortData.value?.orderData?.order || [];
  resultList = arrayOrderBy(
    resultList,
    [...currentIterate, 'start', ...sortDefaultTaskList.iterate],
    [...currentOrder, 'asc', ...sortDefaultTaskList.order]
  );
  return resultList;
});

let timer;
watch([() => keySearch.value, () => chartData.value], () => {
  timer && clearTimeout(timer);
  // 3. start timeout
  timer = setTimeout(() => {
    if (chartData.value?.length > 0) {
      updateTaskInChart();
    }
  }, 250);
});

const isLoading = computed(() => _taskListStore.isLoading || false);
const ganttChartDetail = ref<Gantt | null>(null);

const isLoadingChart = ref<boolean>(true);

const VIEW_MODE_LIST = {
  [VIEW_MODE.DAY]: { key: VIEW_MODE.DAY, nameCode: 'GANTT_VIEW_BY_DAY' },
  [VIEW_MODE.WEEK]: { key: VIEW_MODE.WEEK, nameCode: 'GANTT_VIEW_BY_WEEK' },
  [VIEW_MODE.MONTH]: { key: VIEW_MODE.MONTH, nameCode: 'GANTT_VIEW_BY_MONTH' },
  // [VIEW_MODE.YEAR]: { key: VIEW_MODE.YEAR, nameCode: 'GANTT_VIEW_BY_YEAR' },
};

const initGanttChart = () => {
  console.log('🚀 Tictop ~ initGanttChart:', {
    ...filterDateObj.value,
    _s: dayjs(filterDateObj.value?.startDate).format(DATE_FORMAT),
  });
  ganttChartDetail.value = new Gantt('#tt-gantt', chartData.value, {
    header_height: 50,
    column_width: 30,
    step: 24,
    view_modes: Object.keys(VIEW_MODE_LIST),
    bar_height: 20,
    bar_corner_radius: 3,
    arrow_curve: 5,
    padding: 18,
    view_mode: VIEW_MODE.DAY,
    date_format: DATE_FORMAT,
    language: getCurrentLanguage(),
    custom_popup_html: null,
    has_process: false,
    gantt_start: dayjs(filterDateObj.value?.startDate).format(DATE_FORMAT),
    gantt_end: dayjs(filterDateObj.value?.endDate).format(DATE_FORMAT),
    on_click: function (task) {
      openTaskDetail(task);
    },
    on_date_change: function (task, start, end) {
      new TaskDetailClass(task).changeStartEndTime({
        startTime: dayjs(start).startOf('day').utc(true).format(),
        scheduleTime: dayjs(end).startOf('day').utc(true).format(),
        scheduleByHour: false,
      });
    },
    on_progress_change: function () {
      // params: task, progress
      // console.log(task, progress);
    },
    on_view_change: function (mode) {
      isLoadingChart.value = false;
      setTimeout(() => {
        const firstElementWithClass = document.getElementById(
          'gantt-chart-container'
        );

        if (firstElementWithClass)
          firstElementWithClass.scrollLeft = mode?.scroll_pos;
      }, 100);
    },
  });

  lastUpdateChartTime = Date.now();
};

const columnList = ref<any>([]);
const columnListToShow = computed<any>(() => {
  return columnList.value?.filter((col) => col?.visible);
});

onMounted(async () => {
  columnList.value = Object.values(
    localConfigs.value['task']?.ganttChartColumn || GANTT_TABLE_COLUMN_LIST
  ).map((o) => {
    return {
      ...o,
      ...GANTT_TABLE_COLUMN_LIST[o?.key],
    };
  });
  const ownerId = parseInt(props.ownerId?.toString());
  _taskListStore.currentOwner = {
    id: ownerId,
    type: props.sourceType,
  };
  _taskListStore.initFilterPayload(ownerId, props.sourceType);
  await _taskListStore.fetchTasks(false);

  if (!chartData.value || chartData.value?.length == 0) {
    isLoadingChart.value = false;
    return;
  }

  initGanttChart();
});

const openTaskDetail = (task) => {
  _taskDrawerStore.pushCurrentIds({
    id: task.id,
    name: task.name,
    code: task.code,
    tabType: 'DETAIL',
  });
};
let lastUpdateChartTime = Date.now();
const updateTaskInChart = () => {
  if (
    !chartData.value ||
    chartData.value?.length == 0 ||
    isLoading.value ||
    isLoadingChart.value ||
    Date.now() - lastUpdateChartTime < 500
  )
    return;

  if (!ganttChartDetail.value) initGanttChart();
  else {
    ganttChartDetail.value.chart_size.w = 0;
    ganttChartDetail.value?.update_options({
      gantt_start: dayjs(filterDateObj.value?.startDate)
        .utc()
        .format(DATE_FORMAT),
      gantt_end: dayjs(filterDateObj.value?.endDate).utc().format(DATE_FORMAT),
    });
    ganttChartDetail.value?.refresh(chartData.value);

    lastUpdateChartTime = Date.now();
  }
};

// let timeoutUpdateChart;
const onLoadMore = () => {
  // isLoadingChart.value = true;
  // _taskListStore.onLoadMore();
  // if (timeoutUpdateChart) clearTimeout(timeoutUpdateChart);
  // timeoutUpdateChart = setTimeout(() => {
  //   updateTaskInChart();
  // }, 100);
};

const exportExcelFile = async () => {
  const startDateFilter = dayjs(filterDateObj.value.startDate).toDate();
  const endDateFilter = dayjs(filterDateObj.value.endDate).toDate();

  const tasks = chartData.value.map((e) => ({
    taskName: `[${formatTaskCode(e?.code)}]  ${e?.name}`,
    assigneeName: getUserInfo(e?.assigneeId)?.name,
    taskLife: translate(taskStates[e?.taskLife].nameCode),
    priority: getTextTaskUrgency(e?.urgency),
    startTime: e?.startTime || e?.scheduleTime,
    endTime: e?.scheduleTime,
  }));

  const showColumns = columnListToShow.value.map((o) => o.key);

  ganttChartExportData.value = {
    startDate: startDateFilter,
    endDate: endDateFilter,
    tasks: tasks,
    columns: showColumns,
  };

  isDownloadGanttchart.value = true;
};

const getTextTaskUrgency = (key) => {
  const arrTaskUrgency = Object.values(TaskUrgency);
  const textCode = arrTaskUrgency?.find((e) => e.key === key)?.textCode || '';
  return translate(textCode);
};

const gotoToday = () => {
  if (!ganttChartDetail.value) return;
  const firstElementWithClass = document.getElementById(
    'gantt-chart-container'
  );

  if (firstElementWithClass)
    firstElementWithClass.scrollLeft = Math.max(
      0,
      ganttChartDetail.value?.today_position?.x - 300
    );
};

const durationStartToEnd = computed<string>(() => {
  const duration = dayjs(filterDateObj.value?.endDate).diff(
    dayjs(filterDateObj.value?.startDate),
    'day'
  );
  return dayjs.duration(duration, 'days').humanize();
});

const isShowSidebarList = ref<boolean>(true);
const taskSortByList = computed<any[]>(() => {
  return [
    'DEFAULT',
    'URGENCY',
    'STATUS',
    'NAME',
    'ASSIGNEE',
    'START_DATE',
    'SCHEDULE',
    'CREATED_DATE',
  ].map((key) => TASK_SORT_BY[key]);
});

const currentViewMode = ref<string>(VIEW_MODE.DAY);

const viewModeList = computed<any>(() => {
  return Object.values(VIEW_MODE_LIST)?.filter(
    (item) => item?.key !== currentViewMode.value
  );
});
const onChangeViewMode = (viewMode) => {
  if (!ganttChartDetail.value) return;
  currentViewMode.value = viewMode;
  ganttChartDetail.value.change_view_mode(viewMode);
};

const isOpenAction = ref(false);
const isHasSort = computed<boolean>(() => {
  return currentSortData.value?.key !== 'DEFAULT';
});
const isOpenMoreAction = computed<boolean>(() => {
  return isHasSort.value ? true : isOpenAction.value;
});

const isCurrentOrderBy = computed<'asc' | 'desc'>(() => {
  return currentSortData.value?.orderData?.order?.some((o) => o == 'asc')
    ? 'asc'
    : 'desc';
});

const onChangeOrder = (orderData) => {
  currentSortData.value.orderData.order = [orderData];
  updateTaskInChart();
};

const onChangeIterate = (sortData) => {
  if (currentSortData.value?.key == sortData?.key) {
    onChangeOrder(isCurrentOrderBy.value == 'asc' ? 'desc' : 'asc');
    return;
  }
  currentSortData.value = sortData;
  updateTaskInChart();
};

const filterDropdownRef = ref<any>(null);
const onCloseFilter = () => {
  if (filterDropdownRef.value?.onForceClose)
    filterDropdownRef.value?.onForceClose();
};

const onResetFilter = () => {
  currentFilterPayload.value = {};
  updateTaskInChart();
};
const onHandleFilter = (dataFilter) => {
  currentFilterPayload.value = dataFilter;

  updateTaskInChart();
};

const filterCount = ref<number>(0);

const onCloseUpdateColumnList = () => {
  _localConfigsStore.setLocalConfigs('task', {
    ganttChartColumn: columnList.value?.reduce((old, current) => {
      return {
        ...old,
        [current.key]: current,
      };
    }, {}),
  });
};
</script>

<template>
  <div class="flex-1 min-h-0 w-full h-full flex flex-col relative gap-2">
    <div
      v-if="isLoadingChart"
      class="w-full h-full z-100 absolute top-0 left-0 cursor-wait"
    ></div>
    <div class="flex flex-wrap gap-2 items-center justify-between px-4">
      <div class="flex-center gap-2">
        <VigDropdown
          ref="filterDropdownRef"
          :arrow="false"
          placement="bottom-start"
          class="hover:text-current bg-white shadow-none"
        >
          <template #dropdown-toggle>
            <div
              class="
                relative
                h-7
                flex-center
                border
                rounded
                hover:text-current-500
                pl-1
                gap-1
                pr-2
              "
              :class="`${
                filterCount
                  ? 'fill-current-500 text-current-500 bg-current-50'
                  : ' fill-gray-500 text-gray-500'
              }`"
            >
              <div class="w-4 h-4 flex-center">
                <SynIcon
                  name="filter3"
                  :custom-class="
                    filterCount
                      ? 'w-3 h-3 text-current-600'
                      : 'w-3 h-3 text-gray-500'
                  "
                />
              </div>
              <span class="text-xs">
                {{ $t('COMMON_LABEL_FILTERS') }}
              </span>
              <div
                v-if="filterCount"
                class="
                  absolute
                  -top-1
                  right-0
                  bg-current-500
                  text-white
                  ring-1 ring-white
                  text-xs
                  font-semibold
                  rounded-full
                  w-4
                  h-4
                  flex-center
                "
              >
                {{ filterCount }}
              </div>
            </div>
          </template>
          <template #dropdown-menu>
            <FilterTask
              title="COMMON_LABEL_FILTERS"
              :domain-id="ownerId"
              :filter-default="currentFilterPayload"
              :filter-by-field-list="[
                'TASK_LIFE',
                'URGENCY',
                'TASK_TYPE',
                'CREATE_DATE',
                'UPDATED_DATE',
                'GROUPS',
                'DOMAIN',
                'CREATOR',
                'ASSIGNEE',
              ]"
              @on-close="onCloseFilter"
              @on-reset="onResetFilter"
              @on-filter="onHandleFilter"
              @update:filter-count="(value) => (filterCount = value)"
            />
          </template>
        </VigDropdown>

        <VigDropdown
          :arrow="false"
          placement="bottom-start"
          @on-dropdown-open="isOpenAction = true"
          @on-dropdown-close="isOpenAction = false"
        >
          <template #dropdown-toggle>
            <div
              class="
                h-7
                flex-center
                border
                rounded
                cursor-pointer
                hover:text-current-500
                pl-1
                gap-1
                pr-2
              "
              :class="`${
                isOpenMoreAction
                  ? 'fill-current-500 text-current-500 bg-current-50'
                  : ' fill-gray-500 text-gray-500'
              }`"
            >
              <div class="w-4 h-4 flex-center">
                <SynIcon
                  name="sort2"
                  :custom-class="
                    isOpenMoreAction
                      ? 'w-3 h-3 text-current-600'
                      : 'w-3 h-3 text-gray-500'
                  "
                />
              </div>
              <span class="text-xs">
                {{ $t('COMMON_LABEL_SORT') }}
              </span>
              <span class="text-xs lowercase">
                {{ $t(currentSortData?.nameCode) }}
              </span>
            </div>
          </template>
          <template #dropdown-menu>
            <section
              class="flex flex-col py-1 text-sm"
              style="min-width: 12rem"
            >
              <div
                v-for="(item, index) in taskSortByList"
                :key="index"
                class="
                  list-li
                  flex
                  items-center
                  space-x-3
                  fill-gray-500
                  text-gray-800 text-sm
                  dropdown-item
                "
                @click.stop="onChangeIterate(item)"
              >
                {{ $t(item?.nameCode) }}
              </div>
            </section>
          </template>
        </VigDropdown>
        <div
          v-if="isHasSort"
          class="
            px-2
            flex-center
            space-x-2
            h-6
            w-6
            rounded
            cursor-pointer
            hover:bg-gray-100
            text-lg
          "
          :class="`${
            isOpenMoreAction
              ? 'fill-current-500 text-current-500 bg-current-50'
              : 'hover-to-show__children fill-gray-500 text-gray-500'
          }`"
          @click.stop="
            onChangeOrder(isCurrentOrderBy == 'asc' ? 'desc' : 'asc')
          "
        >
          <span v-if="isCurrentOrderBy == 'asc'"> ↑ </span>
          <span v-else> ↓ </span>
        </div>
      </div>
      <div class="flex items-center flex-wrap gap-2">
        <span
          class="
            h-7
            flex-center
            px-2
            text-xs
            border
            rounded
            cursor-pointer
            hover:text-current-500
          "
          @click="gotoToday"
        >
          <span>
            {{ $t('COMMON_LABEL_TODAY') }}
          </span>
        </span>

        <VigDropdown class="hover:text-current bg-white shadow-none">
          <template #dropdown-toggle>
            <div
              class="
                h-7
                flex-center
                border
                rounded
                cursor-pointer
                hover:text-current-500
              "
            >
              <span class="px-2 text-xs">
                {{ $t(VIEW_MODE_LIST[currentViewMode].nameCode) }}
              </span>
            </div>
          </template>
          <template #dropdown-menu>
            <SynDropdownMenu class="right-0 min-w-[11rem]">
              <div
                v-for="view in viewModeList"
                :key="view.key"
                class="dropdown-item list-li"
                @click="onChangeViewMode(view.key)"
              >
                <div class="flex items-center">
                  <span class="pl-2">
                    {{ $t(view.nameCode) }}
                  </span>
                </div>
              </div>
            </SynDropdownMenu>
          </template>
        </VigDropdown>
        <div class="flex-center">
          <div class="h-5 border-l-2"></div>
        </div>
        <div class="h-7 flex-center border rounded space-x-1 px-2">
          <span class="text-xs">{{ durationStartToEnd }}</span>
          <span class="text-xs">{{
            $t('TASK_LABEL_HISTORY_REPEAT_FROM')
          }}</span>
        </div>
        <AtomDatePicker
          v-model="filterDateObj.startDate"
          :placeholder="$t('COMMON_LABEL_FROM_DATE') || 'From date'"
          :config="{ type: 'number' }"
          :max-date="filterDateObj.endDate"
          custom-class="w-28"
          custom-input-class="h-7 text-xs"
          @update:model-value="updateTaskInChart"
        />
        <span>-</span>
        <AtomDatePicker
          v-model="filterDateObj.endDate"
          :placeholder="$t('COMMON_LABEL_TO_DATE') || 'To date'"
          :config="{ type: 'number' }"
          :min-date="filterDateObj.startDate"
          custom-class="w-28"
          custom-input-class="h-7 text-xs"
          @update:model-value="updateTaskInChart"
        />

        <span
          class="
            text-xs
            flex-center
            space-x-1
            rounded
            py-1
            px-2
            border border-current-400
            text-current-500 text-end
          "
        >
          {{ `${$t('COMMON_LABEL_TOTAL')}: ${chartData?.length || 0}` }}
        </span>

        <button
          v-vig-tooltip="
            $t('COMMON_LABEL_EXPORT_FILE_GANTT_CHART') || 'Export Excel file'
          "
          class="
            flex-center
            space-x-1
            rounded-md
            bg-gray-100
            hover:bg-gray-200
            px-2
            py-1.5
          "
          @click="exportExcelFile()"
        >
          <SynIcon name="export" class="w-4 h-4 fill-gray-500" />
          <span class="text-sm text-gray-500">{{
            $t('COMMON_LABEL_EXPORT_FILE_GANTT_CHART')
          }}</span>
        </button>
      </div>
    </div>
    <div
      id="gantt-chart-container"
      v-scroll-infinite="onLoadMore"
      class="
        ml-1
        flex-1
        relative
        w-full
        h-full
        min-h-0
        overflow-auto
        grid grid-cols-2
      "
      :style="{
        gridTemplateColumns: !isShowSidebarList
          ? '2rem auto'
          : `${16.5 + 5.5 * columnListToShow?.length}rem auto`,
      }"
    >
      <div
        v-if="!chartData || chartData?.length == 0"
        class="
          absolute
          top-0
          left-0
          w-full
          h-full
          flex
          items-center
          flex-col
          pt-24
        "
      >
        <VigImage
          :path="`https://d1tvqetrcurhzb.cloudfront.net/common/images/all-task-mylist.png`"
          :custom-class="`h-40 w-auto`"
        />
        <span
          class="text-current-700 pt-8"
          style="font-size: 1.15rem"
          v-html="$t('TASK_LIST_DONT_HAVE_ALL_TASK_TITLE')"
        ></span>
      </div>
      <div
        v-if="chartData?.length > 0"
        class="sticky left-0 shrink-0 h-full max-w-[50rem] border-t"
        style="z-index: 9"
      >
        <div
          class="w-full h-full flex flex-col bg-white"
          :style="{
            height: `${Math.max(
              (ganttChartDetail?.chart_size?.h || 0) - 150,
              0
            )}px`,
          }"
        >
          <!-- HEADER -->
          <div
            class="bg-white flex items-center gap-2"
            :style="`height: ${
              ganttChartDetail?.chart_size?.headerHeight
                ? ganttChartDetail?.chart_size?.headerHeight - 28.5
                : 31.5
            }px`"
          >
            <div
              class="w-8 h-8 flex-center hover:fill-current cursor-pointer"
              :style="isShowSidebarList ? {} : { transform: 'rotateY(180deg)' }"
              @click="isShowSidebarList = !isShowSidebarList"
            >
              <SynIcon name="MenuClose" custom-class="w-4 h-4 fill-gray-500" />
            </div>
            <div v-if="isShowSidebarList">
              <VigDropdown
                ref="filterDropdownRef"
                :arrow="false"
                placement="bottom-start"
                class="hover:text-current bg-white shadow-none"
                @on-dropdown-close="onCloseUpdateColumnList"
              >
                <template #dropdown-toggle>
                  <div
                    v-vig-tooltip="$t('TASK_GANTT_LABEL_MORE_COLUMN')"
                    class="
                      relative
                      h-7
                      w-7
                      flex-center
                      hover:border
                      rounded
                      hover:text-current-500
                      fill-gray-500
                      text-gray-500
                      cursor-pointer
                    "
                  >
                    <SynIcon
                      name="column2"
                      custom-class="w-4 h-4 text-gray-500"
                    />
                  </div>
                </template>
                <template #dropdown-menu>
                  <div
                    class="flex flex-col space-y-1"
                    :style="'z-index: 20; max-height: min(72vh, 650px)'"
                  >
                    <AtomCheckbox
                      v-for="column in columnList"
                      :key="column.key"
                      v-model="column.visible"
                      class="px-4 py-2 rounded hover:bg-current-50 hover:shadow"
                      :label="$t(column?.nameCode)"
                    />
                  </div>
                </template>
              </VigDropdown>
            </div>
          </div>
          <!-- BODY -->
          <div class="w-full flex-1 bg-white transition-all border-r">
            <table v-if="isShowSidebarList" class="w-full table-fixed">
              <tbody>
                <tr class="relative">
                  <GanttTableTh
                    style="width: 16rem"
                    :label-code="'TASK_TABLE_LABEL_TASKS'"
                    :order-key="'NAME'"
                    :current-sort-data="currentSortData"
                    :is-current-order-by="isCurrentOrderBy"
                    @click="onChangeIterate(TASK_SORT_BY['NAME'])"
                  />

                  <GanttTableTh
                    v-for="column in columnListToShow"
                    :key="column?.key"
                    :order-key="column?.orderKey"
                    :label-code="column?.nameCode"
                    :current-sort-data="currentSortData"
                    :is-current-order-by="isCurrentOrderBy"
                    @click="onChangeIterate(TASK_SORT_BY[column?.orderKey])"
                  />
                </tr>
                <tr
                  v-for="(task, index) in chartData"
                  :key="task.id"
                  :style="{
                    height: `${
                      ganttChartDetail?.chart_size?.rowHeight
                        ? ganttChartDetail?.chart_size?.rowHeight
                        : 38
                    }px`,
                    minHeight: 0,
                    backgroundColor: index % 2 == 0 ? '' : '#f9fafb',
                  }"
                  class="shadow-y-sm"
                >
                  <!-- <td
                    class="relative text-xs px-2"
                    :style="{
                      height: `${ganttChartDetail?.chart_size?.rowHeight}px`,
                      minHeight: 0,
                    }"
                  >
                    <UserByAction
                      v-if="task?.creatorId"
                      :user-id="task?.creatorId"
                      is-hidden-name
                      is-show-action-user
                    />
                  </td> -->
                  <td
                    class="
                      h-full
                      w-[10rem]
                      cursor-pointer
                      hover:text-current
                      items-center
                      pl-2
                      break-keep
                    "
                    @click.stop.prevent="openTaskDetail(task)"
                  >
                    <a
                      :title="task.name"
                      :href="getDetailTaskUrl(task.code, false)"
                      class="
                        w-full
                        hover:text-current-500
                        cursor-pointer
                        items-center
                      "
                    >
                      <p class="w-full inline-block truncate text-sm">
                        {{ formatTaskCode(task?.code) }}
                        <span v-if="task?.isPrivate">
                          <SynIcon
                            name="private"
                            class="fill-current"
                            custom-class="w-2.5 h-2.5"
                          />
                        </span>
                        -
                        <span
                          v-encode-html="{ text: task?.name }"
                          class="w-full truncate"
                        >
                        </span>
                      </p>
                    </a>
                  </td>
                  <template
                    v-for="column in columnListToShow"
                    :key="column?.key"
                  >
                    <template v-if="column?.key == 'ASSIGNEE'">
                      <td class="text-xs px-2 flex-center">
                        <UserByAction
                          v-if="task?.assigneeId"
                          :user-id="task?.assigneeId"
                          is-hidden-name
                          is-show-action-user
                        />
                        <div v-else class="flex-center">
                          <GroupById :group-id="task?.groupId" is-hidden-name />
                        </div>
                      </td>
                    </template>
                    <template v-if="column?.key == 'TASK_LIFE'">
                      <td class="text-xs px-2">
                        <div class="w-full flex-center">
                          <SynTag
                            v-if="task.taskLife"
                            class="rounded-full w-max px-2 text-xs"
                            size="small"
                            :class="[
                              taskStates[task?.taskLife]?.background,
                              taskStates[task?.taskLife]?.color,
                            ]"
                          >
                            {{ $t(taskStates[task?.taskLife]?.nameCode) }}
                          </SynTag>
                        </div>
                      </td>
                    </template>
                    <template v-if="column?.key == 'URGENCY'">
                      <td class="text-xs px-2">
                        <PriorityTask
                          :priority="task?.priority"
                          :important="task?.important"
                          size="mini"
                          is-hidden-label
                        />
                      </td>
                    </template>
                    <template v-if="column?.key == 'START_TIME'">
                      <td class="text-xs px-2 text-right">
                        <span>
                          <SynLabelDateTime
                            format="date"
                            :datetime="task.startTime || task.scheduleTime"
                          />
                        </span>
                      </td>
                    </template>
                    <template v-if="column?.key == 'SCHEDULE_TIME'">
                      <td class="text-xs px-2 text-right">
                        <span class="w-full">
                          <SynLabelDateTime
                            format="date"
                            :datetime="task.scheduleTime || task.startTime"
                          />
                        </span>
                      </td>
                    </template>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        v-show="chartData?.length > 0"
        class="w-full h-full relative overflow-x-auto overflow-y-hidden"
        :style="{
          width: `${ganttChartDetail?.chart_size?.w}px`,
        }"
      >
        <div
          v-if="isLoading || isLoadingChart"
          class="absolute top-0 left-0 flex flex-col pt-[60px] translate-x-20"
        >
          <div class="h-10 flex items-center">
            <div class="w-10 rounded py-2 bg-gray-100 animate-pulse"></div>
          </div>
          <div class="h-10 flex items-center">
            <div class="w-10 rounded py-2 bg-gray-100 animate-pulse"></div>
          </div>
          <div class="h-10 flex items-center translate-x-10">
            <div class="w-10 rounded py-2 bg-gray-100 animate-pulse"></div>
          </div>
          <div class="h-10 flex items-center translate-x-20">
            <div class="w-96 rounded py-2 bg-gray-100 animate-pulse"></div>
          </div>
          <div class="h-10 flex items-center translate-x-96">
            <div class="w-10 rounded py-2 bg-gray-100 animate-pulse"></div>
          </div>
        </div>
        <svg id="tt-gantt" style="height: 100%" />
      </div>
    </div>
  </div>

  <ModalDownloadResult
    v-if="isDownloadGanttchart"
    :owner-id="ownerId"
    :data="ganttChartExportData"
    @on-close="isDownloadGanttchart = false"
  />
</template>
