<script setup lang="ts">
import { ref, computed } from 'vue';
import DepartmentSelect from '@/ui/modules/departments/components/DepartmentSelect.vue';
import SearchFilterIcon from '@/ui/components/search/SearchFilterIcon.vue';
import { IDepartmentDetail } from '@/application/types/department/department.types';
import myProfileStore from '@/store/auth/my-profile';
import settingStore from '@/store/setting';
import userStore from '@/store/user';
import { ignoreUnicode } from '@/ui/plugins/utils';
import UserById from '@/ui/components/user/UserById.vue';
import ActionById from '@/ui/components/user/ActionById.vue';
import SearchSelectAll from '@/ui/components/search/SearchSelectAll.vue';
import { IGroupDetailEntity } from '@/domain/entities/group/GroupDetailEntity';
import GroupSelect from '@/ui/modules/group/components/GroupSelect.vue';

const props = withDefaults(
  defineProps<{
    isHoverToShowCheckbox?: boolean;
    isSelectMultiple?: boolean;
    selectedIds?: any[];
    isShowTitle?: boolean;
    allUsers?: any[];
    isFilterWhenSelected?: boolean;
    isHiddenActionItem?: boolean;
  }>(),
  {
    ownerTypeList: () => ['FAVORITE', 'USER', 'GROUP'],
    selectedIds: () => [],
  }
);
const emit = defineEmits(['onChoose', 'onChooseAll']);
const _myProfileStore = myProfileStore();
const _settingStore = settingStore();
const _userStore = userStore();

const myDepartment = computed(() => _myProfileStore.myDepartment);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const isVisitor = computed(() => _userStore.isVisitor);
const allActiveUsers = computed(() => {
  if (isVisitor.value) return [];

  let result = props.allUsers || _userStore.allActiveUsers || [];

  // if (filterType.value == 1) return result;

  if (
    filterType.value == 2 &&
    departmentManagementEnabled.value &&
    selectedDepartment.value?.id
  ) {
    result = result?.filter((user) =>
      selectedDepartment.value?.departmentUsers?.some(
        (member) => member?.userId == user?.id
      )
    );
  }
  if (filterType.value == 3 && selectedGroup.value?.id) {
    result = result?.filter((user) =>
      selectedGroup.value?.groupUsers?.some(
        (member) => member?.userId == user?.id
      )
    );
  }

  return result;
});

const isSelecting = computed<boolean>(() => {
  if (
    props.isHoverToShowCheckbox &&
    (!props.isSelectMultiple || props.selectedIds?.length == 0)
  )
    return false;

  return true;
});

const chooseUser = (contact) => {
  if (!contact?.id) return;

  emit('onChoose', { ...contact });
};

const onChangeSelectAll = (isSelected) => {
  emit('onChooseAll', isSelected, allActiveUsers.value);
};

const selectedDepartment = ref<IDepartmentDetail | null>(myDepartment.value);

const searchText = ref('');
const filteredUsers = computed(() => {
  let result = allActiveUsers.value || [];

  if (props.isFilterWhenSelected)
    result = result?.filter(
      (user) =>
        !props.selectedIds?.some((selectedId) => selectedId == `${user?.id}`)
    );

  const searchTxt = ignoreUnicode(searchText.value);
  if (searchTxt)
    return result.filter(
      (user) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
    );

  return result;
});
const currentIndex = ref(0);

const resultSearch = computed(() => {
  return filteredUsers.value.map((el) => {
    return {
      id: el?.id,
      type: 'USER',
      isGroup: false,
      avatar: el?.avatar,
      name: el?.name,
    };
  });
});

const isSearching = ref(false);
const onChangeTextSearch = () => {
  isSearching.value = true;
  currentIndex.value = 0;
  // if (searchText.value !== '#') return;
  // searchText.value = `#${codePrefix.value}`;
};

const onChooseResult = () => {
  if (resultSearch.value?.length < 1) return;

  const currentOwner = resultSearch.value[currentIndex.value];
  chooseUser(currentOwner);
  isSearching.value = false;
};

const filterType = ref<number>(1);
const selectedGroup = ref<IGroupDetailEntity | undefined>(undefined);
</script>

<template>
  <div>
    <div class="w-full py-2">
      <VigSearchBox
        v-model="searchText"
        v-model:current-index="currentIndex"
        :total-result="resultSearch?.length"
        input-class="text-sm"
        style="width: 100%"
        autofocus
        :placeholder="$t('COMMON_LABEL_SEARCH')"
        @update:model-value="onChangeTextSearch"
        @enter="onChooseResult"
      />
    </div>

    <div
      v-if="isShowTitle"
      class="
        px-4
        pt-4
        pb-1
        uppercase
        text-xs
        font-semibold
        tracking-widest
        text-gray-500
      "
    >
      {{ $t('CHAT_MEMBERS') || 'Members' }}
    </div>
    <DepartmentSelect
      v-if="departmentManagementEnabled && filterType == 2"
      v-model="selectedDepartment"
    >
      <template #prefix>
        <SearchSelectAll
          class="pl-1"
          :selected-ids="
            selectedIds?.filter((id) =>
              allActiveUsers?.some((u) => u?.id == id)
            )
          "
          :filtered-users="allActiveUsers"
          @on-choose-all="onChangeSelectAll"
        />
      </template>
      <template #suffix>
        <SearchFilterIcon v-model="filterType" />
      </template>
    </DepartmentSelect>
    <GroupSelect v-else-if="filterType == 3" v-model="selectedGroup">
      <template #prefix>
        <SearchSelectAll
          class="pl-1"
          :selected-ids="
            selectedIds?.filter((id) =>
              allActiveUsers?.some((u) => u?.id == id)
            )
          "
          :filtered-users="allActiveUsers"
          @on-choose-all="onChangeSelectAll"
        />
      </template>
      <template #suffix>
        <SearchFilterIcon v-model="filterType" />
      </template>
    </GroupSelect>
    <template v-else>
      <div class="flex items-center justify-between pl-3">
        <SearchSelectAll
          :selected-ids="selectedIds"
          :filtered-users="filteredUsers"
          @on-choose-all="onChangeSelectAll"
        />
        <SearchFilterIcon v-model="filterType" />
      </div>
    </template>

    <div
      v-if="filteredUsers?.length > 0"
      class="w-full h-full grid auto-rows-max gap-1 grid-cols-3"
      style="grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr))"
    >
      <div
        v-for="user in filteredUsers"
        :key="user"
        class="
          p-2
          flex
          items-center
          space-x-2
          hover:bg-gray-100
          rounded
          text-sm
          hover-to-show__parent
          cursor-pointer
        "
        @click="chooseUser(user)"
      >
        <div
          v-if="isSelectMultiple"
          class="group h-8 w-8 flex-center rounded-full cursor-pointer"
          :class="{
            'hover-to-show__children': !isSelecting,
          }"
          @click.stop="chooseUser(user)"
        >
          <div
            class="
              cursor-pointer
              h-4
              w-4
              flex-center
              rounded
              border border-current-300
              group-hover:border-2
            "
            :class="{
              'bg-current-300': selectedIds?.some(
                (id) => id == `member_${user?.id}`
              ),
              'bg-white': !selectedIds?.some(
                (id) => id == `member_${user?.id}`
              ),
            }"
          >
            <SynIcon
              v-if="selectedIds?.some((id) => id == `member_${user?.id}`)"
              name="check"
              class="text-white"
            />
          </div>
        </div>
        <div class="flex items-center w-full justify-between space-x-2">
          <UserById
            :user-id="user.id"
            is-show-department
            class="flex-1"
            avatar-class="w-8 h-8"
          />

          <div
            v-if="!isHiddenActionItem"
            class="text-end hover-to-show__children"
          >
            <ActionById
              :contact-id="user?.id"
              :types="['favourite', 'list-task', 'chat']"
              :type-contact="'member'"
            />
          </div>
        </div>
      </div>
    </div>
    <span v-else class="text-xs text-gray-500 italic px-4">
      {{ $t('COMMON_LABEL_NO_DATA') }}
    </span>
  </div>
</template>
