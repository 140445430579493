<script setup lang="ts">
import { onMounted, ref } from 'vue';
import authStore from '@/store/auth';
import { useRouter } from 'vue-router';
import { isValidEmail } from '@/ui/hooks/commonFunction';
import SettingLanguageSmall from '@/ui/components/setting/SettingLanguageSmall.vue';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import GetCode from './GetCode.vue';
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
// import VigInputEmail from '@/ui/common/atoms/VigInputEmail/VigInputEmail.vue';

const _authStore = authStore();
const router = useRouter();

const emailValue = ref('');
const emailError = ref('');
const phoneValue = ref('');

const inputErrorMessage = ref('');
const isFetchChecking = ref(false);
const isLoadingSubmitButton = ref(false);
const isSentEmail = ref(false);
const hasError = ref(false);
const msgError = ref('');
const isVerifyCode = ref(false);
const isOpenModalVerifyAccount = ref(false);
const emailLocal: any = router.currentRoute.value?.query?.email;
const phoneLocal: any = router.currentRoute.value?.query?.phone;
// const inputEmailRef = ref(null);

onMounted(() => {
  if (emailLocal) emailValue.value = emailLocal;
  if (phoneLocal) phoneValue.value = phoneLocal;
  // inputEmailRef.value?.onfocus();
});

const checkValidEmail = async (value) => {
  isFetchChecking.value = true;
  // 2.1 Check valid
  if (!isValidEmail(value)) {
    isFetchChecking.value = false;
    inputErrorMessage.value = 'COMMON_INVALID_EMAIL';
    return false;
  }
  // 2.3 Check existed in system
  const resCheckExist = await _authStore.checkEmail(value);
  if (!resCheckExist.existed) {
    isFetchChecking.value = false;
    inputErrorMessage.value = 'COMMON_LABEL_REQUIRE_EMAIL_NOT_EXIST';
    return;
  }

  if (!resCheckExist.isActivated) {
    isFetchChecking.value = false;
    inputErrorMessage.value = 'COMMON_LABEL_REQUIRE_EMAIL_NOT_VERIFIED';
    return;
  }

  inputErrorMessage.value = '';
  isFetchChecking.value = false;
};

const onSentCode = async () => {
  isLoadingSubmitButton.value = true;
  let res = null as any;
  try {
    res = await _authStore.forgotPassword(emailValue.value);
    if (!res?.success && res?.error) {
      inputErrorMessage.value = res?.error.message;
    }
    isSentEmail.value = true;
  } finally {
    isLoadingSubmitButton.value = false;
  }
};

const onVerifyCode = async (code) => {
  isVerifyCode.value = true;
  try {
    const result =
      await _authStore.submitVerificationEmailUserTokenForForgotPassword({
        email: emailValue.value,
        c: '',
        resetCode: code,
      });
    msgError.value = '';
    isVerifyCode.value = false;

    const token = result?.token;
    const email = result?.email;

    if (!token || !email) return;

    router.push(`/verify-pwd-token?token=${token}&email=${email}`);
  } catch (response: any) {
    isVerifyCode.value = false;
    hasError.value = true;
    msgError.value = ErrorNotificationLogin[response.error.message];
  }
};

const onSendCodeLogin = () => {
  router.push({
    name: 'Login',
    query: {
      type: emailLocal ? 'EMAIL' : 'PHONE',
      value_login: emailLocal ? emailLocal : phoneLocal,
    },
  });
};
</script>

<template>
  <div class="absolute w-full h-full bg-current-50">
    <setting-language-small class="flex-center absolute top-2 right-2" />

    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div
          class="w-full relative md:w-2/3 lg:w-1/3 px-4 bg-white"
          style="
            border-radius: 20px;
            padding: 3rem;
            box-shadow: 0 0 20px #b1dcde;
          "
        >
          <router-link :to="{ name: 'Login' }">
            <SynIcon
              name="back"
              class="fill-current text-current absolute top-0 left-0 m-4"
              has-action
            />
          </router-link>
          <div class="flex flex-col mb-4">
            <syn-logo />
          </div>

          <div
            class="
              flex flex-col
              content-center
              text-center
              justify-center
              w-full
              my-1
              font-roboto font-thin
              mb-8
            "
          >
            <span class="text-3xl w-full font-thin pb-2">
              {{ $t('FORGOT_PASSWORD_HEADER') }}
            </span>
            <span class="text-sm">
              {{ $t('FORGOT_PASSWORD_HEADER_SUB') }}
            </span>
          </div>

          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              w-full
              mb-6
              rounded-lg
              border-0
              lg:items-center
            "
          >
            <div v-if="!isSentEmail" class="flex-auto py-10 pt-0 w-full">
              <form>
                <div class="relative w-full mb-3 mt-3">
                  <SynFormInput
                    :is-checking="isFetchChecking"
                    :error-message="inputErrorMessage"
                  >
                    <SynInput
                      v-model="emailValue"
                      autofocus
                      class="w-full"
                      icon-prefix="email"
                      :placeholder="$t('SIGN_UP_LABEL_HEADER_EMAIL')"
                      :error-message="emailError"
                      :check-invalid="checkValidEmail"
                      @enter="onSentCode()"
                    />
                  </SynFormInput>
                </div>
              </form>
              <div class="text-center mt-4">
                <syn-button
                  class="w-full m-auto"
                  :label="$t('COMMON_LABEL_SEND_CODE')"
                  :disabled="
                    !emailValue || inputErrorMessage || isFetchChecking
                  "
                  :is-loading="isLoadingSubmitButton"
                  @click="onSentCode()"
                >
                </syn-button>
              </div>
              <div class="mt-2">
                <span
                  class="
                    text-sm
                    cursor-pointer
                    text-current-800
                    hover:text-current
                  "
                  @click="isOpenModalVerifyAccount = true"
                  >{{ $t('FORGOT_PASSWORD_CONNECT_WITH_OTP') }}</span
                >
              </div>
            </div>

            <div v-else>
              <GetCode
                type="forgotPWD"
                code-type="email"
                :email="emailValue"
                :error-code="msgError"
                :is-loading="isVerifyCode"
                :is-loading-resend="isLoadingSubmitButton"
                @on-verify="onVerifyCode"
                @resend-code="onSentCode()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <SynModal
    v-if="isOpenModalVerifyAccount"
    container-class="w-max h-auto"
    @cancel="isOpenModalVerifyAccount = false"
  >
    <template #header></template>
    <template #body>
      <div class="flex-center flex-col px-12" style="max-width: 36rem">
        <span class="font-medium text-2xl text-gray-700">{{
          $t('FORGOT_PASSWORD_CONNECT_WITH_OTP')
        }}</span>
        <span class="text-gray-500 text-center pt-3">{{
          $t('FORGOT_PASSWORD_CONNECT_WITH_OTP_SUB_TITLE')
        }}</span>
        <div
          class="
            flex-center
            p-4
            font-medium
            text-current-500
            bg-gray-50
            rounded
            my-6
            text-xl
          "
        >
          <span>{{ emailLocal ? emailLocal : phoneLocal }}</span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-end space-x-2 p-4">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="isOpenModalVerifyAccount = false"
        ></SynButton>
        <SynButton
          :label="$t('FORGOT_PASSWORD_CONNECT_BUTTON_LABEL')"
          @click="onSendCodeLogin"
        ></SynButton>
      </div>
    </template>
  </SynModal>
</template>
