import { ignoreUnicode } from '@/ui/plugins/utils';
import PrincipleService from '@/application/services/PrincipleService';
import { computed, ref } from 'vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import dayjs from 'dayjs';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import dashboardStore from '@/store/dashboard';
import {
    IPrinciple,
    IPrinciplePauseDetail,
    IWorkingTime,
} from '@/application/types/principle/working-time.types';

export default function workingTimeComposable(departmentId?: number) {
    const _dashboardStore = dashboardStore();
    const userWorkingTimes = ref<any>([]);
    const keySearch = ref('');
    const departmentSelectedList = ref([]);
    const userWorkingStatus = computed(() => _dashboardStore.userWorkingStatus);

    const isShowOrderTimeStartWD = computed(() => {
        return userWorkingTimes.value.some((e) => e.start);
    });
    const departmentList = ref<any>([]);
    const loading = ref(false);
    const timeLimit = ref();

    const initComponent = async (date: undefined | string | null = null) => {
        loading.value = true;
        const res =
            await PrincipleService.getInstance().getPrincipleTimeLimit();
        timeLimit.value = res?.result;

        const res2 =
            await PrincipleService.getInstance().getAllPrincipleByUserAndDay(
                date,
                departmentId
            );
        const workingTimeData: IWorkingTime = res2?.result;

        if (workingTimeData) {
            userWorkingTimes.value = _prepareWorkingTimeData(
                workingTimeData,
                timeLimit.value
            );

            const departmentIds = userWorkingTimes.value.map(
                (e) => e.departmentId
            );
            departmentList.value = [
                ...new Set(departmentIds.filter((e) => e !== null)),
            ];
        }

        loading.value = false;
    };

    const workingTimeByUsers = computed<IPrinciple[]>(() => {
        let result = userWorkingTimes.value;
        if (departmentSelectedList.value.length > 0)
            result = result.filter((e) =>
                departmentSelectedList.value.some((o) => o == e.departmentId)
            );
        if (keySearch.value)
            result = result.filter((user: any) =>
                stringNomalize(user.fullname?.toLowerCase()).includes(
                    stringNomalize(keySearch.value?.toLowerCase())
                )
            );

        result = result?.map((pri) => {
            return {
                ...pri,
                workingStatus: userWorkingStatus.value[pri?.userId]?.status,
            };
        });

        return arrayOrderBy(
            result,
            [
                (user) => {
                    if (user?.workingStatus == 'online') return 1;
                    if (user?.workingStatus == 'absent') return 2;
                    if (user?.workingStatus == 'offline') return 3;

                    return 4;
                },
                'start',
                'duration',
                'fullName',
            ],
            ['asc', 'asc', 'asc', 'asc']
        ) as IPrinciple[];
    });

    const _prepareWorkingTimeData = (
        workingTimeData: IWorkingTime,
        _timeLimit: number
    ): IPrinciple[] => {
        return workingTimeData?.principle?.map((pri, index) => {
            const totalTask = workingTimeData?.overviewTaskList.filter(
                (t) => t.assigneeId == pri.userId
            ).length;
            const countTaskOnDay = workingTimeData?.overviewTaskList.filter(
                (t) => t.assigneeId == pri.userId && t.isPlannedToTreat
            );
            const countTaskOnFinish = workingTimeData?.overviewTaskList.filter(
                (t) => t.assigneeId == pri.userId && t.isPlannedToFinish
            );

            const countTaskOnPending =
                totalTask - countTaskOnDay.length - countTaskOnFinish.length;

            const timeLimit = pri?.idDay
                ? dayjs
                      .utc(pri?.idDay)
                      .add(1, 'day')
                      .startOf('day')
                      .subtract(7, 'hour')
                      .add(parseFloat(_timeLimit?.toString()), 'hour')
                      .format('YYYY-MM-DDTHH:mm:ss.SSS')
                : undefined;

            const pauseList = arrayOrderBy(
                workingTimeData?.principlePauseDetail.filter(
                    (p) => p.principleId == pri.id
                ),
                'checkIn',
                'desc'
            ) as IPrinciplePauseDetail[];
            return {
                ...pri,
                timeLimit,
                pauseList,
                fullName: ignoreUnicode(pri.fullname),
                countTaskOnday: countTaskOnDay.length,
                countTaskOnFinish: countTaskOnFinish.length,
                countTaskOnPending,
                taskCodesOnday: countTaskOnDay.map((t) => t.code) || [],
                taskCodesOnFinish: countTaskOnFinish.map((t) => t.code) || [],
                startIndex: pri.start ? index + 1 : undefined,
            };
        });
    };

    initComponent(null);
    return {
        keySearch,
        userWorkingTimes,
        workingTimeByUsers,
        isShowOrderTimeStartWD,
        departmentList,
        loading,
        departmentSelectedList,
        initComponent,
    };
}
