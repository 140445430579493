<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';
import ModalChangeAvatarGroup from './ModalChangeAvatarGroup.vue';
import ModalChangeAvatarUser from './ModalChangeAvatarUser.vue';

const props = withDefaults(
  defineProps<{
    customClass?: string;
    size?: string;
    groupId?: string | number;
    userId?: string | number;
    avatar?: string;
    isEdit?: boolean;
    type?: string; //GROUP, USER (thêm type khi tạo mới, '' khi update)
  }>(),
  {
    customClass: 'absolute bottom-0 right-0 hover-to-show__children',
    size: 'w-full h-full',
  }
);

const emit = defineEmits(['updateAvatar', 'getFile']);

const modalType = computed<'GROUP' | 'USER'>(() => {
  if (
    (props.type && props.type == 'GROUP') ||
    (!props.type && !props.userId && !!props.groupId)
  )
    return 'GROUP';

  return 'USER';
});

const isEditImage = computed(() => props.isEdit);

const isOpenModal = ref(false);

const onCancel = (result: any) => {
  isOpenModal.value = result?.value;
  emit('updateAvatar', result?.avatars);
};

const getFile = (file) => {
  isOpenModal.value = false;

  emit('getFile', {
    preview: URL.createObjectURL(file),
    file: file,
  });
};

onMounted(() => {});
</script>

<template>
  <div
    class="
      flex
      items-center
      justify-center
      rounded-full
      bg-current-50 bg-opacity-90
      cursor-pointer
      hover:bg-current-100
    "
    :class="[customClass, size]"
    @click="isOpenModal = true"
  >
    <SynIcon name="camera" custom-class="w-1/2 h-1/2 fill-current font-bold " />
  </div>

  <ModalChangeAvatarUser
    v-if="isOpenModal && modalType == 'USER'"
    :src="avatar"
    :user-id="userId"
    is-delete-photo
    @cancel="onCancel"
  />

  <ModalChangeAvatarGroup
    v-if="isOpenModal && modalType == 'GROUP'"
    :src="avatar"
    :group-id="groupId"
    is-delete-photo
    :is-edit="isEditImage"
    @cancel="onCancel"
    @get-file="getFile"
  />
</template>
