<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 20 20"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g id="Layer_2" data-name="Layer 2">
        <path
          d="m16 1h-6a1 1 0 0 0 0 2h1.557l-5.25 14h-2.307a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-1.557l5.25-14h2.307a1 1 0 0 0 0-2z"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
