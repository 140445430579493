import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

import BaseRequest from '@/base/api/base-request-ticket';

export default class TicketTaskApi extends BaseRequest {
    private static instance: TicketTaskApi;
    constructor() {
        super();
    }

    public static getInstance(): TicketTaskApi {
        if (!TicketTaskApi.instance) {
            // Get from local storage
            TicketTaskApi.instance = new TicketTaskApi();
        }

        return TicketTaskApi.instance;
    }

    getHistories(data) {
        return this.request({
            url: `Task/GetFeedbackTasks`,
            method: 'post',
            data,
        });
    }

    getCurrentTaskCycle() {
        return this.request({
            url: `Task/GetCurrentTaskCycle`,
            method: 'get',
        });
    }

    reopen(data) {
        return this.request({
            url: `Task/Reopen`,
            method: 'post',
            data,
        });
    }
    renameTask(data) {
        return this.request({
            url: `Task/RenameTask`,
            method: 'post',
            data,
        });
    }
    updateNote(data) {
        return this.request({
            url: `Task/UpdateNote`,
            method: 'post',
            data,
        });
    }
    updateFiles(data) {
        return this.request({
            url: `Task/UpdateFiles`,
            method: 'post',
            data,
        });
    }
    changeDescription(data) {
        return this.request({
            url: `Task/ChangeDescription`,
            method: 'post',
            data,
        });
    }
    changeAssigneeTask(data) {
        return this.request({
            url: `Task/ChangeAssigneeTask`,
            method: 'post',
            data,
        });
    }
    createTicket(data) {
        return this.request({
            url: `Task/CreateTicket`,
            method: 'post',
            data,
        });
    }
    getTaskWithFilter(data) {
        return this.request({
            url: `Task/GetTasksWithFilters`,
            method: 'post',
            data,
        });
    }
    getDetailTaskByCode(taskCode) {
        return this.request({
            url: `Task/getDetailTaskByCode?code=${taskCode}`,
            method: 'get',
        });
    }
    getDetailTaskById(taskId) {
        return this.request({
            url: `Task/GetDetailTask?id=${taskId}`,
            method: 'get',
        });
    }
    getDetailTaskIncognitoById(taskId) {
        return this.request({
            url: `Task/GetDetailTaskIncognito?id=${taskId}`,
            method: 'get',
        });
    }
    getAttachmentNoteByTaskId(taskId) {
        return this.request({
            url: `Task/getAttachmentNoteByTaskId?id=${taskId}`,
            method: 'get',
        });
    }
    getDrawDataById(noteIdString) {
        return this.request({
            url: `Task/GetDrawDataById?id=${noteIdString}`,
            method: 'get',
        });
    }
    getDataConfig() {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);
        return this.request({
            url: `Task/GetDataConfig?lang=${lang}`,
            method: 'get',
        });
    }
    getSubProjectsWithParentIds(parentIds) {
        return this.request({
            url: `Project/GetSubProjectsWithParentIds?parentIds=${parentIds}`,
            method: 'get',
        });
    }

    changePriority(data) {
        return this.request({
            url: `Task/ChangePriority`,
            method: 'post',
            data,
        });
    }

    changeUrgency(data) {
        return this.request({
            url: `Task/ChangeUrgency`,
            method: 'post',
            data,
        });
    }

    changeDomain(data) {
        return this.request({
            url: `Task/ChangeDomain`,
            method: 'post',
            data,
        });
    }

    changePrivate(data) {
        return this.request({
            url: `Task/ChangePrivate`,
            method: 'post',
            data,
        });
    }

    changeEstimationTime(data) {
        return this.request({
            url: `Task/ChangeEstimationTime`,
            method: 'post',
            data,
        });
    }

    changeFinishedPercentage(data) {
        return this.request({
            url: `Task/ChangeFinishedPercentage`,
            method: 'post',
            data,
        });
    }

    changeType(data) {
        return this.request({
            url: `Task/ChangeType`,
            method: 'post',
            data,
        });
    }

    deleteTask(data) {
        return this.request({
            url: `Task/DeleteTask`,
            method: 'post',
            // action: ACTION.remove,
            data,
        });
    }
    finishTask(data) {
        return this.request({
            url: `Task/FinishTask`,
            method: 'post',
            // action: ACTION.finish,
            data,
        });
    }
    changeState(data) {
        return this.request({
            url: `Task/ChangeTaskState`,
            method: 'post',
            // action: ACTION.finish,
            data,
        });
    }
    setPlannedTask(data) {
        return this.request({
            url: `Task/SetPlannedTask`,
            method: 'post',
            data,
        });
    }
    changeScheduleTime(data) {
        return this.request({
            url: `Task/ChangeScheduleTime`,
            method: 'post',
            data,
        });
    }
    setAssignee(data) {
        return this.request({
            url: `Task/SetAssigneeTask`,
            method: 'post',
            data,
        });
    }
    getTaskListOverview() {
        return this.request({
            url: `TaskPlan/GetTaskListOverview`,
            method: 'get',
        });
    }
    getTaskListPlannedToTreat() {
        return this.request({
            url: `TaskPlan/GetTaskListPlannedToTreat`,
            method: 'get',
        });
    }
    saveTaskListPlannedToTreat(data) {
        return this.request({
            url: `TaskPlan/SaveTaskListPlannedToTreat`,
            method: 'post',
            // action: ACTION.update,
            data,
        });
    }
    getTaskListPlannedToFinish() {
        return this.request({
            url: `TaskPlan/GetTaskListPlannedToFinish`,
            method: 'get',
        });
    }
    updateTaskListPlannedToFinish(data) {
        return this.request({
            url: `TaskPlan/UpdateTaskListPlannedToFinish`,
            method: 'post',
            data,
        });
    }
    saveTaskListPlannedToday(data) {
        return this.request({
            url: `TaskPlan/SaveTaskListPlannedToday`,
            method: 'post',
            data,
        });
    }
    getStopWorkingDayTaskList() {
        return this.request({
            url: `TaskPlan/GetStopWorkingDayTaskList`,
            method: 'get',
        });
    }
    getTaskOverviewStatistic() {
        return this.request({
            url: `Task/GetTaskOverviewStatistic`,
            method: 'get',
        });
    }
    stopWorkingDay(data) {
        return this.request({
            url: `Task/StopWorkingDay`,
            method: 'post',
            data,
        });
    }

    updateTaskConfig(config) {
        return this.request({
            url: `Task/UpdateTaskConfig`,
            method: 'post',
            data: config,
        });
    }
    getUserTaskSchedule(assigneeId) {
        return this.request({
            url: `Task/GetUserTaskSchedule?assigneeId=${assigneeId}`,
            method: 'get',
        });
    }

    // Media
    uploadAws(files) {
        const formData = new FormData();
        formData.append('bucketType', '1');
        files.forEach((file) => {
            formData.append('files', file);
        });
        return this.request({
            url: `Media/UploadAws`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    // COMMENT
    getAllComments(orgId, taskId) {
        return this.request({
            url: 'Task/GetAllComments',
            method: 'get',
            params: { orgId, taskId },
        });
    }

    createComment(orgId, taskId, data) {
        return this.request({
            url: 'Task/CreateComment',
            method: 'post',
            data,
            params: { orgId, taskId },
        });
    }

    updateComment(commentId, data) {
        return this.request({
            url: `Task/UpdateComment?commentId=${commentId}`,
            method: 'post',
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    updateCommentAttachments(commentId, data) {
        return this.request({
            url: `Task/UpdateCommentAttachments?commentId=${commentId}`,
            method: 'post',
            data,
        });
    }

    pinnedComment(commentId) {
        return this.request({
            url: `Task/PinnedComment?commentId=${commentId}`,
            method: 'post',
        });
    }

    reactionComment(commentId, data) {
        return this.request({
            url: `Task/ReactionComment?commentId=${commentId}`,
            method: 'post',
            data,
        });
    }

    deleteComment(commentId) {
        return this.request({
            url: `Task/DeleteComment?commentId=${commentId}`,
            method: 'post',
        });
    }

    deleteAttachmentInComment(commentId) {
        return this.request({
            url: `Task/RemoveCommentAttachments?commentId=${commentId}`,
            method: 'post',
        });
    }
}
