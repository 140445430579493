<script setup lang="ts">
import { computed, ref } from 'vue';
import taskListStore from '@/store/task/task-list-store';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import FilterTask from '@/ui/modules/task/filter/FilterTask.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import {
  EFilterTaskBoard,
  ETaskViewType,
} from '@/application/constants/task.const';

const props = defineProps<{
  userId?: number;
  groupId?: number;
  domainId?: number;
  assignorId?: number;
  title?: string;
  showFilterAllCheckboxCode?: string;
  isShowFilterAllCheckBox?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onApplyFilter'): void;
  (e: 'onResetFilter'): void;
}>();

const _taskListStore = taskListStore();
const _taskTabViewStore = taskTabViewStore();

const isOwnerList = computed<boolean>(() => {
  return !!_taskListStore.filterPayload?.listOwnerId;
});
const filterDefault = computed<any>(() => {
  return _taskListStore.filterPayload;
});

const filterCount = ref<number>(0);
const isShowFilter = ref(false);

const onOpenFilter = () => {
  isShowFilter.value = true;
};

const onHandleFilter = (filterPayload) => {
  _taskListStore.setCustomFilterPayload({
    ...filterPayload,
    groupIds: props.groupId ? `${props.groupId}` : filterPayload?.groupIds,
    domainId: props.domainId ? props.domainId : undefined,
  });
  _taskListStore.fetchTasks(true);

  onClose();
};

const onResetClick = () => {
  emit('onResetFilter');

  onClose();
};

const filterTaskButtonRef = ref<any>(null);
const onClose = () => {
  if (filterTaskButtonRef.value?.onForceClose)
    filterTaskButtonRef.value?.onForceClose();
};

const viewCustomizeActive = computed<ETaskViewType>(
  () => _taskTabViewStore.customizeView
);
const boardCurrentTabId = computed(() => _taskTabViewStore.boardCurrentTabId);

const filterByFieldList = computed<string[]>(() => {
  let result = [
    'TASK_LIFE',
    'URGENCY',
    'TASK_TYPE',
    'CREATE_DATE',
    'UPDATED_DATE',
    'DEADLINE',
    'GROUPS',
    'DOMAIN',
    'CREATOR',
    'ASSIGNEE',
  ];

  if (props.userId) result = result?.filter((o) => o !== 'ASSIGNEE');
  if (props.assignorId) result = result?.filter((o) => o !== 'CREATOR');
  if (props.groupId) result = result?.filter((o) => o !== 'GROUPS');
  if (props.domainId) result = result?.filter((o) => o !== 'DOMAIN');

  if (viewCustomizeActive.value == ETaskViewType.BOARD) {
    if (boardCurrentTabId.value == EFilterTaskBoard.BY_URGENCY)
      result = result?.filter((o) => o !== 'URGENCY');
    if (boardCurrentTabId.value == EFilterTaskBoard.BY_STATUS)
      result = result?.filter((o) => o !== 'TASK_LIFE');
    if (boardCurrentTabId.value == EFilterTaskBoard.BY_DEADLINE)
      result = result?.filter((o) => o !== 'DEADLINE');
  }

  return result;
});
</script>

<template>
  <VigDropdown
    ref="filterTaskButtonRef"
    z-index="50"
    :arrow="false"
    hide-on-click="toggle"
    placement="bottom-start"
    @on-dropdown-open="onOpenFilter"
    @on-dropdown-close="isShowFilter = false"
  >
    <template #dropdown-toggle>
      <button
        v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER') || 'Filter'"
        class="w-8 h-8 flex-center rounded-full hover:bg-gray-100 relative"
        :class="isShowFilter ? 'bg-gray-100' : ''"
      >
        <SynIcon
          name="Filter"
          :custom-class="
            filterCount ? 'w-4 h-4 text-current-600' : 'w-4 h-4 text-gray-500'
          "
        />
        <div
          v-if="filterCount"
          class="
            absolute
            -top-1
            right-0
            bg-current-500
            text-white
            ring-1 ring-white
            text-xs
            font-semibold
            rounded-full
            w-4
            h-4
            flex-center
          "
        >
          {{ filterCount }}
        </div>
      </button>
    </template>
    <template #dropdown-menu>
      <div class="w-96">
        <FilterTask
          :title="title"
          :user-id="userId"
          :group-id="groupId"
          :domain-id="domainId"
          :is-owner-list="isOwnerList"
          :filter-default="filterDefault"
          :filter-by-field-list="filterByFieldList"
          :show-filter-all-checkbox-code="showFilterAllCheckboxCode"
          @on-close="onClose"
          @on-reset="onResetClick"
          @on-filter="onHandleFilter"
          @update:filter-count="(value) => (filterCount = value)"
        />
      </div>
    </template>
  </VigDropdown>
</template>
