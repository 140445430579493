// Embed page
import EmbedLogin from '@/embed/pages/EmbedLogin.vue';
import EmbedPage from '@/embed/pages/EmbedPage.vue';
import EmbedTasks from '@/embed/pages/EmbedTasks.vue';
import EmbedTasksNew from '@/embed/pages/EmbedTasksNew.vue';

// Ticket page
import TicketPage from '@/ticket/pages/TicketPage.vue';
import TicketTasks from '@/ticket/pages/TicketTasks.vue';
import TicketTasksNew from '@/ticket/pages/TicketTasksNew.vue';
import TicketTasksHistories from '@/ticket/pages/TicketTasksHistories.vue';
import TicketMyPage from '@/ticket/pages/TicketMyPage.vue';

//Home
import OrganizationHome from '../pages/home/OrganizationHome.vue';
import Home from '../pages/home/Home.vue';
import HomeApp from '../pages/home/HomeApp.vue';

// Other
import OrganizationInactive from '../pages/notfound/OrganizationInactive.vue';
import WaitingActivation from '../pages/notfound/WaitingActivation.vue';
import SuspiciousRequest from '../pages/notfound/SuspiciousRequest.vue';
import AccountDeactivated from '../pages/notfound/AccountDeactivated.vue';
import SystemDeployment from '../pages/systems/SystemDeployment.vue';
import SystemMaintenace from '../pages/systems/SystemMaintenace.vue';
import AdminSession from '@/ui/pages/auth/AdminSession.vue';
import ProfilePublic from '../pages/profile/ProfilePublic.vue';
import VerifyEmailToken from '../pages/auth/VerifyEmailToken.vue';
import VerifyEmailTokenForgotPassword from '../pages/auth/VerifyEmailTokenForgotPassword.vue';
import TaskSharingPage from '@/ui/pages/task-sharing/TaskSharingPage.vue';

// Starting
import Login from '../pages/auth/Login.vue';
import ForgotPassword from '../pages/auth/ForgotPassword.vue';
import RegisterPage from '../pages/auth/RegisterPage.vue';
import InviteUser from '../pages/organization/user-accept-invitation.vue';
import SystemNotificationPage from '@/ui/pages/home/notification/SystemNotificationPage.vue';
import BuyLicense from '@/ui/pages/home/payment/BuyLicense.vue';
import NoPermissionNotificationPage from '@/ui/pages/home/notification/NoPermissionNotificationPage.vue';
import NotFound from '../pages/notfound/404.vue';

export default [
    {
        path: '/embed-login',
        name: 'EmbedLogin',
        component: EmbedLogin,
    },
    {
        path: '/embed',
        name: 'EmbedPage',
        component: EmbedPage,
        meta: { layout: 'embed' },
        redirect: { name: 'EmbedTasksNew' },
        children: [
            {
                path: 'tasks',
                name: 'EmbedTasks',
                component: EmbedTasks,
                children: [
                    {
                        path: 'new',
                        name: 'EmbedTasksNew',
                        component: EmbedTasksNew,
                    },
                ],
            },
        ],
    },
    {
        path: '/ticket',
        name: 'TicketPage',
        component: TicketPage,
        meta: { layout: 'ticket' },
        redirect: { name: 'TicketTasksNew' },
        children: [
            {
                path: 'tasks',
                name: 'TicketTasks',
                component: TicketTasks,
                meta: { layout: 'ticket' },
                children: [
                    {
                        path: 'new',
                        name: 'TicketTasksNew',
                        meta: { layout: 'ticket' },
                        component: TicketTasksNew,
                    },
                    {
                        path: 'histories',
                        name: 'TicketTasksHistories',
                        meta: { layout: 'ticket' },
                        component: TicketTasksHistories,
                    },
                ],
            },
            {
                path: 'my-page',
                name: 'TicketMyPage',
                component: TicketMyPage,
                meta: { layout: 'ticket' },
            },
        ],
    },
    {
        path: '/home',
        name: 'OrganizationHome',
        component: OrganizationHome,
        meta: { layout: 'SetupTeam' },
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { layout: 'Firsttime' },
    },
    {
        path: '/home-app',
        name: 'HomeApp',
        component: HomeApp,
        meta: { layout: 'dashboard' },
    },
    {
        path: '/profile-public',
        name: 'ProfilePublic',
        component: ProfilePublic,
        meta: { layout: 'Profile' },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { layout: 'Login' },
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterPage,
        meta: { layout: 'Login' },
    },
    {
        path: '/users/invitation/accept',
        name: 'InviteUser',
        component: InviteUser,
        meta: { layout: 'Empty' },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: { layout: 'Empty' },
    },
    {
        path: '/verify-email',
        name: 'VerificationEmail',
        component: VerifyEmailToken,
        meta: { layout: 'Empty' },
    },
    {
        path: '/verify-pwd-token',
        name: 'VerificationPasswordToken',
        component: VerifyEmailTokenForgotPassword,
        meta: { layout: 'Empty' },
    },
    {
        path: '/organization-inactive',
        name: 'OrganizationInactive',
        component: OrganizationInactive,
        meta: { layout: 'Empty' },
    },
    {
        path: '/waiting-activation',
        name: 'WaitingActivation',
        component: WaitingActivation,
        meta: { layout: 'Empty' },
    },
    {
        path: '/suspicious-request',
        name: 'SuspiciousRequest',
        component: SuspiciousRequest,
        meta: { layout: 'Empty' },
    },
    {
        path: '/super-admin-session',
        name: 'AdminSession',
        component: AdminSession,
        meta: { layout: 'Empty' },
    },
    {
        path: '/account-deactivated',
        name: 'AccountDeactivated',
        component: AccountDeactivated,
        meta: { layout: 'Empty' },
    },
    {
        path: '/system-deployment',
        name: 'SystemDeployment',
        component: SystemDeployment,
        meta: { layout: 'Empty' },
    },
    {
        path: '/system-maintenace',
        name: 'SystemMaintenace',
        component: SystemMaintenace,
        meta: { layout: 'Empty' },
    },
    {
        path: '/system-notification',
        name: 'SystemNotificationPage',
        component: SystemNotificationPage,
        meta: { layout: 'dashboard' },
        redirect: { name: 'NoPermissionNotificationPage' },
        children: [
            {
                path: 'no-permission',
                name: 'NoPermissionNotificationPage',
                component: NoPermissionNotificationPage,
            },
        ],
    },
    {
        path: '/buy-license',
        name: 'BuyLicense',
        component: BuyLicense,
        meta: { layout: 'Empty' },
    },
    {
        path: '/shared/task/:sharedId',
        name: 'SharedTask',
        component: TaskSharingPage,
        meta: { layout: 'Shared' },
    },
    {
        path: '/not-found',
        name: 'NotFound404',
        component: NotFound,
        meta: { layout: 'Empty' },
    },
];
