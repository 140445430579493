<script setup lang="ts">
import { ref, computed } from 'vue';
import DepartmentAddMembers from '@/ui/modules/departments/components/DepartmentAddMembers.vue';
import ButtonAvatars from '@/ui/modules/avatars/ButtonAvatars.vue';
import departmentStore from '@/store/department';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import GroupService from '@/application/services/GroupService';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import DepartmentSettingForm from '@/ui/modules/departments/components/DepartmentSettingForm.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { GroupMemberRole } from '@/ui/common/constants/constant';

const emit = defineEmits(['cancel', 'createSuccess']);

const _departmentStore = departmentStore();

const CreateDepartmentStepConstant = {
  CANCEL: 'CANCEL',
  INFORMATION: 'INFORMATION',
  ADD_MEMBERS: 'ADD_MEMBERS',
  SETTINGS: 'SETTINGS',
  FINISH_CREATE: 'FINISH_CREATE',
};

const {
  currentStep,
  stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    [CreateDepartmentStepConstant.INFORMATION]: {
      key: CreateDepartmentStepConstant.INFORMATION,
      label: 'COMMON_LABEL_INFORMATION',
      next: CreateDepartmentStepConstant.ADD_MEMBERS,
      previous: CreateDepartmentStepConstant.CANCEL,
      isActive: true,
      previousText: 'COMMON_LABEL_CANCEL',
      confirmText: 'COMMON_LABEL_NEXT',
      isFinished: false,
      previousAction: () => {
        onCancel();
      },
    },
    [CreateDepartmentStepConstant.ADD_MEMBERS]: {
      key: CreateDepartmentStepConstant.ADD_MEMBERS,
      label: 'TASK_TABLE_LABEL_MEMBERS',
      next: CreateDepartmentStepConstant.SETTINGS,
      previous: CreateDepartmentStepConstant.INFORMATION,
      isActive: false,
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'COMMON_LABEL_NEXT',
      isFinished: false,
      previousAction: () => {},
      nextAction: () => {
        console.log(
          '🚀 Hyrin ~ dataPayload?.memberInfos:',
          dataPayload.value?.memberInfos
        );
      },
    },
    [CreateDepartmentStepConstant.SETTINGS]: {
      key: CreateDepartmentStepConstant.SETTINGS,
      label: 'COMMON_LABEL_VISIBILITY',
      next: CreateDepartmentStepConstant.FINISH_CREATE,
      previous: CreateDepartmentStepConstant.ADD_MEMBERS,
      isActive: false,
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'COMMON_LABEL_FINISH',
      isFinished: false,
      previousAction: () => {},
      nextAction: () => {
        onSave();
      },
    },
  },
  CreateDepartmentStepConstant.INFORMATION
);

const file = ref();
const isLoadFinishGroup = ref(false);
const dataPayload = ref<{
  name: string;
  description: string;
  isPrivate: boolean;
  avatar: string;
  memberInfos: any;
  departmentSetting: {
    visibleForAllMember: boolean;
    visibleStatusWorking: boolean;
    visibleTimeWorking: boolean;
    visiblePersonalData: boolean;
  };
}>({
  name: '',
  description: '',
  isPrivate: false,
  avatar: '',
  memberInfos: [],
  departmentSetting: {
    visibleForAllMember: true,
    visibleStatusWorking: true,
    visibleTimeWorking: false,
    visiblePersonalData: true,
  },
});

const {
  isTyping: isTypingName,
  validationResult: validationResultName,
  onValidation: onValidationName,
} = atomFormInputComposables(
  [
    (value) => {
      return value?.trim()
        ? ''
        : 'TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED';
    },
  ],
  0
);
// const isAddAvatar = ref(false);

// const onChooseAvatar = (value) => {
//   file.value = value;
//   isAddAvatar.value = !isAddAvatar.value;
// };

const onCancel = () => {
  file.value = '';
  emit('cancel');
};

const errorMessageCode = ref<string>('');

const onSave = async () => {
  try {
    isLoadFinishGroup.value = true;

    const adminList = dataPayload.value?.memberInfos?.filter(
      (o) => o.role === GroupMemberRole.Admin
    );
    if (dataPayload.value?.memberInfos?.length > 0 && adminList?.length == 0) {
      errorMessageCode.value = 'DEPARTMENT_LABEL_REQUIRE_TOTAL_ADMIN';
      isLoadFinishGroup.value = false;
      return;
    }
    if (file.value) {
      const avatarRes = await GroupService.getInstance().uploadAvatar(
        file.value
      );
      dataPayload.value.avatar = avatarRes?.result?.path;
    }

    const res = await _departmentStore.createNewDepartment(dataPayload.value);

    isLoadFinishGroup.value = false;

    emit('createSuccess', res?.result);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isLoadFinishGroup.value = false;
  }
};

const fileObj = ref();
const getFile = (files) => {
  fileObj.value = files;
  file.value = files.file;
};

const isDisabledSummit = computed(() => {
  if (currentStep.value === CreateDepartmentStepConstant.INFORMATION)
    return isTypingName.value || !validationResultName.value?.isValid;

  if (currentStep.value === CreateDepartmentStepConstant.ADD_MEMBERS)
    return false;

  const adminList = dataPayload.value?.memberInfos?.filter(
    (o) => o.role === GroupMemberRole.Admin
  );
  if (currentStep.value === CreateDepartmentStepConstant.SETTINGS)
    return dataPayload.value?.memberInfos?.length > 0 && adminList?.length == 0;

  return isLoadFinishGroup.value;
});

const updateSettings = (settings: {
  visibleForAllMember: boolean;
  visibleStatusWorking: boolean;
  visibleTimeWorking: boolean;
  visiblePersonalData: boolean;
}) => {
  dataPayload.value = {
    ...dataPayload.value,
    departmentSetting: settings,
  };
  console.log('🚀 Hyrin ~ dataPayload.value', dataPayload.value);
};

const onHandleNextStep = async () => {
  switch (currentStep.value) {
    case CreateDepartmentStepConstant.ADD_MEMBERS:
      {
        if (dataPayload.value?.memberInfos?.length <= 0) {
          settingGlobalModal({
            type: 'confirm',
            title: '',
            content: translate(
              'DEPARTMENT_LABEL_CREATE_WITHOUT_MEMBER_WARNING',
              {
                departmentName: dataPayload.value?.name || '',
              }
            ),
            confirmable: true,
            confirmLabel: translate('COMMON_LABEL_CONTINUE') || 'Continue',
            closeable: true,
          });

          const isConfirm = await ask();
          if (!isConfirm) return;
        }
        if (dataPayload.value?.memberInfos?.length == 1) {
          dataPayload.value.memberInfos[0].role = GroupMemberRole.Admin;
        }

        onHandleGoToNextStep();
      }
      break;

    default:
      onHandleGoToNextStep();

      break;
  }
};
</script>

<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-class="md:w-2/5 h-4/5"
    container-style="max-height: 90%; max-width: 90%;min-width: 48rem"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <div class="w-full pt-4 flex items-center">
        <!-- <div class="text-base min-w-max">
          {{ $t('TASK_CREATE_GROUP_FORM_CREATE_GROUP') }}
        </div> -->
        <SynStepper
          key-label="label"
          class=""
          :steps="Object.values(stepperByKey)"
        />
      </div>
    </template>
    <template #body>
      <!-- INFORMATION -->
      <div
        v-if="currentStep == CreateDepartmentStepConstant.INFORMATION"
        class="w-full flex flex-col items-center pt-4 justify-center"
      >
        <div class="flex-center w-32 h-32 hover-to-show__parent cursor-pointer">
          <SynAvatar
            :src="fileObj?.preview ? fileObj?.preview : ''"
            :name="dataPayload?.name"
            type="department"
            is-hidden-name
            is-view-image
            custom-class="w-32 h-32"
          />
          <ButtonAvatars
            size="w-1/3 h-1/3"
            is-edit
            type="GROUP"
            :avatar="fileObj?.preview ? fileObj?.preview : ''"
            @get-file="getFile"
          />
        </div>

        <div class="w-2/3 mt-6 flex flex-wrap space-y-4">
          <div class="flex flex-col w-full">
            <div class="w-full font-medium text-current-800">
              {{ $t('DEPARTMENT_CREATE_FORM_NAME') }}
            </div>
            <SynFormInput :error-message="validationResultName.message">
              <SynInput
                v-model="dataPayload.name"
                class="w-full"
                style-name="max-height: 500px;"
                autofocus
                :placeholder="$t('DEPARTMENT_CREATE_FORM_NAME_PLACEHOLDER')"
                :max-length="80"
                @update:model-value="onValidationName"
                @blur="onValidationName(dataPayload.name)"
                @enter="onHandleGoToNextStep"
              />
            </SynFormInput>
          </div>

          <div class="flex w-full justify-center space-x-4">
            <div class="w-full">
              <div class="w-32 font-medium text-current-800">
                {{ $t('COMMON_LABEL_DESCRIPTION') }}
              </div>
              <SynInputArea
                v-model="dataPayload.description"
                class="w-full"
                style-name="max-height: 500px;"
                :max-length="150"
                :placeholder="
                  $t('DEPARTMENT_CREATE_FORM_DESCRIPTION_PLACEHOLDER') ||
                  'Description'
                "
                @enter="onHandleGoToNextStep"
              />
            </div>
          </div>

          <!-- status -->
          <!-- <div class="flex w-full justify-center space-x-4">
            <div class="w-full flex flex-wrap space-x-4">
              <div
                class="
                  py-2
                  px-2
                  w-full
                  bg-gray-50
                  rounded-md
                  flex
                  justify-between
                  space-x-2
                "
              >
                <div class="flex items-center space-x-4">
                  <SynIcon name="private" class="fill-current" />
                  <div class="flex flex-wrap">
                    <label class="font-medium text-current-800">{{
                      $t('GROUP_CREATE_TITLE_STATUS_MAKE_PRIVATE')
                    }}</label>
                    <label class="text-xs w-full text-gray-500">{{
                      $t('GROUP_CREATE_SUB_STATUS_MAKE_PRIVATE')
                    }}</label>
                  </div>
                </div>
                <AtomSwitch v-model="dataPayload.isPrivate" />
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <!-- INVITE MEMBER -->
      <template v-if="currentStep == CreateDepartmentStepConstant.ADD_MEMBERS">
        <DepartmentAddMembers
          v-model:member-list="dataPayload.memberInfos"
          :department-name="dataPayload?.name"
          is-select-user-step
          :default-list="dataPayload.memberInfos"
        />
      </template>

      <!-- SETTINGS -->
      <template v-if="currentStep == CreateDepartmentStepConstant.SETTINGS">
        <div class="px-8">
          <DepartmentSettingForm
            v-model:members="dataPayload.memberInfos"
            :settings="dataPayload.departmentSetting"
            :department-name="dataPayload.name"
            @update-settings="updateSettings"
          />
        </div>
      </template>
    </template>

    <template #footer>
      <div class="w-full flex p-4 items-center justify-between">
        <div class="flex-center text-xs text-orange-500 space-x-2">
          <template
            v-if="currentStep == CreateDepartmentStepConstant.ADD_MEMBERS"
          >
            <p>
              {{ $t('DEPARTMENT_LABEL_CHANGE_MEMBER_WARNING') }}
            </p>
            <span>
              <AtomHelpDropdown
                title="DEPARTMENT_LABEL_CHANGE_MEMBER_WARNING_SHORT"
                content="DEPARTMENT_LABEL_CHANGE_MEMBER_WARNING_DETAIL"
              >
                <template #content>
                  <ul class="pl-4" style="list-style: circle">
                    <li
                      v-html="
                        $t('DEPARTMENT_LABEL_CHANGE_MEMBER_WARNING_DETAIL') ||
                        'Note: If you select a user who is a member of another Department, that user will become a member of the new Department and no longer be a memberof the old Department'
                      "
                    ></li>
                    <li
                      v-html="
                        $t('DEPARTMENT_LABEL_REQUIRE_TOTAL_ADMIN') ||
                        'Note: If you select a user who is a member of another Department, that user will become a member of the new Department and no longer be a memberof the old Department'
                      "
                    ></li>
                  </ul>
                </template>
              </AtomHelpDropdown>
            </span>
          </template>
          <template
            v-if="
              currentStep == CreateDepartmentStepConstant.SETTINGS &&
              isDisabledSummit
            "
          >
            <span class="pl-8">
              {{ $t('DEPARTMENT_LABEL_REQUIRE_TOTAL_ADMIN') }}
            </span>
          </template>
        </div>
        <div class="flex-1 flex items-center justify-between space-x-2">
          <div class="flex-center space-x-2">
            <template v-if="errorMessageCode">
              <SynIcon name="info" class="fill-red-500" />
              <span class="text-red-500 text-sm">
                {{ $t(errorMessageCode) }}
              </span>
            </template>
          </div>
          <div class="flex-center space-x-2">
            <SynButton
              v-if="stepperByKey[currentStep]?.previousText"
              type-outline
              :label="$t(stepperByKey[currentStep].previousText)"
              :disabled="isLoadFinishGroup"
              @click="onHandleGoToPreviousStep"
            />
            <SynButton
              v-if="currentStep == 'SETTINGS'"
              :is-loading="isLoadFinishGroup"
              :disabled="isDisabledSummit"
              :label="$t(stepperByKey[currentStep].confirmText)"
              @click="onSave"
            />
            <SynButton
              v-else-if="stepperByKey[currentStep]?.confirmText"
              :disabled="isDisabledSummit"
              :label="$t(stepperByKey[currentStep].confirmText)"
              @click="onHandleNextStep"
            />
          </div>
        </div>
      </div>
    </template>
  </SynModal>
</template>
