<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g data-name="Layer 2">
        <path
          d="M12 22.25A10.25 10.25 0 1 1 22.25 12 10.26 10.26 0 0 1 12 22.25Zm0-19A8.75 8.75 0 1 0 20.75 12 8.76 8.76 0 0 0 12 3.25Z"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
        <path
          d="M16 15.75a.7.7 0 0 1-.49-.19l-4-3.5a.71.71 0 0 1-.26-.56V6a.75.75 0 0 1 1.5 0v5.16l3.74 3.28a.75.75 0 0 1-.49 1.31ZM2.5 4.88a.76.76 0 0 1-.45-1.36l3-2.25A.75.75 0 0 1 6 2.48L3 4.73a.74.74 0 0 1-.5.15ZM21.5 4.88a.74.74 0 0 1-.45-.15l-3-2.25A.75.75 0 0 1 19 1.27l3 2.25a.76.76 0 0 1-.45 1.36ZM20 22.75a.75.75 0 0 1-.62-.33l-1.85-2.77a.75.75 0 1 1 1.24-.84l1.85 2.77a.75.75 0 0 1-.2 1 .74.74 0 0 1-.42.17ZM4 22.75a.74.74 0 0 1-.42-.13.75.75 0 0 1-.2-1l1.85-2.77a.75.75 0 1 1 1.24.84l-1.85 2.73a.75.75 0 0 1-.62.33Z"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
        <path
          d="M12 22.25A10.25 10.25 0 1 1 22.25 12 10.26 10.26 0 0 1 12 22.25Zm0-19A8.75 8.75 0 1 0 20.75 12 8.76 8.76 0 0 0 12 3.25Z"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
        <path
          d="M16 15.75a.7.7 0 0 1-.49-.19l-4-3.5a.71.71 0 0 1-.26-.56V6a.75.75 0 0 1 1.5 0v5.16l3.74 3.28a.75.75 0 0 1-.49 1.31ZM2.5 4.88a.76.76 0 0 1-.45-1.36l3-2.25A.75.75 0 0 1 6 2.48L3 4.73a.74.74 0 0 1-.5.15ZM21.5 4.88a.74.74 0 0 1-.45-.15l-3-2.25A.75.75 0 0 1 19 1.27l3 2.25a.76.76 0 0 1-.45 1.36ZM20 22.75a.75.75 0 0 1-.62-.33l-1.85-2.77a.75.75 0 1 1 1.24-.84l1.85 2.77a.75.75 0 0 1-.2 1 .74.74 0 0 1-.42.17ZM4 22.75a.74.74 0 0 1-.42-.13.75.75 0 0 1-.2-1l1.85-2.77a.75.75 0 1 1 1.24.84l-1.85 2.73a.75.75 0 0 1-.62.33Z"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
