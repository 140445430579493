<script setup lang="ts">
import { ref } from 'vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const props = defineProps<{
  modelValue: string[];
  placeholder?: string;
  disabled?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const inputValueRef = ref<any>();
const text = ref<string>();

const onInputPressEnter = () => {
  if (!text.value?.trim()) return;

  const newValues = (props.modelValue || []).concat([text.value?.trim()]);

  emit('update:modelValue', newValues);

  text.value = '';
};

const onRemoveItemClick = (index) => {
  if (props.disabled) return;

  const newValues = (props.modelValue || []).filter((_, i) => i != index);

  emit('update:modelValue', newValues);
};
</script>

<template>
  <div
    class="border rounded-md flex flex-wrap gap-2 p-2"
    @click="inputValueRef.focus()"
  >
    <div
      v-for="(item, index) in modelValue"
      :key="item"
      class="rounded-2xl px-2 py-1 bg-current-50 flex items-center gap-2"
    >
      <span class="text-sm" @click.stop>{{ item }}</span>
      <SynIcon
        name="Close"
        class="fill-gray-500"
        :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click="onRemoveItemClick(index)"
      />
    </div>

    <input
      ref="inputValueRef"
      v-model="text"
      :placeholder="placeholder"
      :disabled="disabled"
      type="text"
      class="px-2 py-0 border-0 focus:ring-0 flex-1 min-w-[5rem] text-sm"
      @keydown.enter="onInputPressEnter"
      @blur="onInputPressEnter"
    />
  </div>
</template>

<style scoped></style>
