import AWS, { AWSError } from 'aws-sdk';
import { AWS_S3 } from '@/ui/plugins/keys.private';
import $constants from '../constants';
import axios from 'axios';
import { PromiseResult } from 'aws-sdk/lib/request';
import {
    GetObjectOutput,
    PutObjectOutput,
    PutObjectRequest,
} from 'aws-sdk/clients/s3';
AWS.config.update(AWS_S3);
const _s3 = new AWS.S3();

//todo: need to refactor
export const getObjectFromAwsS3 = (
    params
): Promise<PromiseResult<GetObjectOutput, AWSError>> => {
    return _s3.getObject(params).promise();
};

export const getObjectDataByUrl = (
    url,
    responseType:
        | 'arraybuffer'
        | 'blob'
        | 'document'
        | 'json'
        | 'text'
        | 'stream'
) => {
    return axios.get(url, {
        responseType: responseType,
        headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
        },
    });
};

export const putObjectToAwsS3 = (
    payload: PutObjectRequest
): Promise<PromiseResult<PutObjectOutput, AWSError>> => {
    const params = {
        ACL: 'public-read',
        ...payload,
    };
    return _s3.putObject(params).promise();
};

export const uploadFile = (params) => {
    return _s3.upload(params).promise();
};

export const copyFile = (params) => {
    return _s3.copyObject(params).promise();
};

export const awsS3Delete = (payload) => {
    return _s3.deleteObject(payload).promise();
};

export const genFilePathByType = (
    organizationId,
    fileId,
    fileName,
    contentType
) => {
    if (contentType?.startsWith($constants.FILE.TYPE.IMAGE)) {
        return `${organizationId}/original/${fileId}/${fileName}`;
    }

    if (contentType?.startsWith($constants.FILE.TYPE.AUDIO)) {
        return `${organizationId}/audio/${fileId}/${fileName}`;
    }

    if (contentType?.startsWith($constants.FILE.TYPE.VIDEO)) {
        return `${organizationId}/video/${fileId}/${fileName}`;
    }

    return `${organizationId}/${fileId}/${fileName}`;
};
export const genKindOfFileByType = (
    contentType
): 'image' | 'default' | 'media' => {
    if (contentType?.startsWith($constants.FILE.TYPE.IMAGE)) return 'image';

    if (contentType?.startsWith($constants.FILE.TYPE.AUDIO)) return 'media';

    if (contentType?.startsWith($constants.FILE.TYPE.VIDEO)) return 'media';

    return 'default';
};
