<script setup lang="ts">
import { computed, onMounted, ref, toRef, watch } from 'vue';
import { NotificationCategory } from '@/domain/enums/notification-enums';
import NotifCategoryTabs from '@/ui/modules/notification/notifications-list/notif-category-tabs/NotifCategoryTabs.vue';
import { chain } from 'lodash';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { CurrentUserConfig } from '@/ui/common/constants/constant';

const props = defineProps<{
  selectedCategory?: NotificationCategory;
  unreadCategories?: any[];
  userSettings?: any[];
  notifTitle?: string;
}>();

const emits = defineEmits([
  'update:selectedCategory',
  'onShowUnreadChange',
  'onReadAllClick',
]);

const selectedCategoryRef = toRef(props, 'selectedCategory');
const isShowUnread = ref<boolean>();

const unreadByCategory = computed<any>(() =>
  chain(props.unreadCategories)
    .keyBy('category')
    .mapValues('totalUnread')
    .value()
);

watch(
  () => [props.userSettings, props.selectedCategory],
  () => _processIsShowUnread()
);

onMounted(() => {
  _processIsShowUnread();
});

const onShowOnlyUnreadChange = async () => {
  let key;
  switch (props.selectedCategory) {
    case NotificationCategory.ALL:
      key = CurrentUserConfig.OnlyUnreadAllNotification;
      break;
    case NotificationCategory.NEW:
      key = CurrentUserConfig.OnlyUnreadNewNotification;
      break;
    case NotificationCategory.UPDATED:
      key = CurrentUserConfig.OnlyUnreadUpdatedNotification;
      break;
    case NotificationCategory.FINISHED:
      key = CurrentUserConfig.OnlyUnreadFinishedNotification;
      break;
    case NotificationCategory.OTHER:
      key = CurrentUserConfig.OnlyUnreadOtherNotification;
      break;
  }

  emits('onShowUnreadChange', {
    key,
    value: isShowUnread.value,
  });
};

const onReadAllClick = async () => {
  // Case no unread notifications, alert
  if (!unreadByCategory.value[selectedCategoryRef.value]) {
    settingGlobalModal({
      type: 'warning',
      title: translate('NOTIFICATION_LABEL_ALERT_READ_ALL_WHEN_SHOW_UNREAD'),
      confirmable: true,
    });

    return await ask();
  }

  // Else, Ask
  settingGlobalModal({
    type: 'warning',
    title: translate('NOTIFICATION_LABEL_ALERT_READ_ALL'),
    closeable: true,
    confirmable: true,
    confirmLabel: translate('COMMON_LABEL_CONFIRM'),
  });

  const confirmed = await ask();

  if (confirmed) emits('onReadAllClick', selectedCategoryRef.value);
};

const _processIsShowUnread = () => {
  let settingKey;

  switch (props.selectedCategory) {
    case NotificationCategory.ALL:
      settingKey = CurrentUserConfig.OnlyUnreadAllNotification;
      break;
    case NotificationCategory.NEW:
      settingKey = CurrentUserConfig.OnlyUnreadNewNotification;
      break;
    case NotificationCategory.UPDATED:
      settingKey = CurrentUserConfig.OnlyUnreadUpdatedNotification;
      break;
    case NotificationCategory.FINISHED:
      settingKey = CurrentUserConfig.OnlyUnreadFinishedNotification;
      break;
    case NotificationCategory.OTHER:
      settingKey = CurrentUserConfig.OnlyUnreadOtherNotification;
      break;
  }

  isShowUnread.value = chain(props.userSettings)
    .filter({ key: settingKey })
    .map('value')
    .first()
    .value();
};
</script>

<template>
  <div class="flex flex-col">
    <!--NOTIF TYPES TABS-->
    <div class="flex gap-3 px-3 border-b">
      <div v-if="notifTitle">{{ $t(notifTitle) }}</div>
      <div class="flex-1" :class="{ 'flex-center': !!notifTitle }">
        <NotifCategoryTabs
          v-model:selectedCategory="selectedCategoryRef"
          :unread-by-category="unreadByCategory"
          @update:selected-category="$emit('update:selectedCategory', $event)"
        />
      </div>
    </div>

    <!-- ACTION IN TAB (SHOW UNREAD, MASK READ ALL, FILTER) -->
    <div class="flex items-center justify-between p-3">
      <AtomSwitch v-model="isShowUnread" @change="onShowOnlyUnreadChange">
        <span class="text-xs text-black">
          {{ $t('NOTIFICATION_ONLY_SHOW_UNREAD') || 'Only show unread' }}</span
        >
      </AtomSwitch>

      <div class="flex-center space-x-1">
        <div
          role="button"
          class="
            flex-center
            space-x-2
            h-7
            py-1
            px-2
            border border-gray-50
            rounded-full
            text-xs text-gray-600
            fill-gray-600
            hover:bg-current-50 hover:text-current-400 hover:fill-current-400
          "
          @click="onReadAllClick"
        >
          <SynIcon name="select-all-read" />
          <span>{{ $t('NOTIFICATION_MARK_READ_ALL') || 'I read it all' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
