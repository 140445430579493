<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { checkIsFileObject } from '@/ui/hooks/commonFunction';
import { getBlobData } from '@/ui/components/file-viewer/copyToClipboard';
import {
  // isOpenCreateTaskGlobal,
  // createTaskGlobal,
  attachmentToTaskGlobal,
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
} from '@/ui/modules/task/task-global-state';
import { getObjectDataByUrl } from '@/ui/plugins/awss3/aws-s3-services';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = withDefaults(
  defineProps<{
    path: string;
    data: any;
    dataType: 'FILE' | 'CHECKLIST';
  }>(),
  {
    dataType: 'FILE',
  }
);
const emit = defineEmits<{
  (e: 'openNewTask'): void;
  (e: 'openFindTask'): void;
}>();

onMounted(() => {});

const isGettingBlobData = ref<boolean>(false);

const getBlobDataOfFile = async () => {
  try {
    let blobData: Blob | false;
    const isFileObject = checkIsFileObject(props.data);
    if (isFileObject) {
      blobData = getBlobData(isFileObject);
    } else {
      let fileUrl =
        props.path ||
        props.data?.fileUrl ||
        props.data?.imagePath ||
        props.data?.url_full ||
        props.data?.pathUrl;

      if (!fileUrl?.startsWith('https://s3.ap-southeast-1.amazonaws.com'))
        fileUrl = `https://s3.ap-southeast-1.amazonaws.com/${props.data?.bucket}/${props.data?.path}`;

      if (!fileUrl) throw false;

      const imageDataFromS3 = await getObjectDataByUrl(fileUrl, 'blob');
      blobData = imageDataFromS3.data;
    }

    return blobData;
  } catch (error) {
    // console.log('🚀 ~ createNewTask ~ error', error);
    openNotification({
      type: AlertType.warning,
      body: translate('CHAT_CANNOT_CREATE_NEW_TASK_WITH_THIS_FILE'),
      duration: 2000,
    });
    return null;
  }
};
const createNewTask = async () => {
  switch (props.dataType) {
    case 'FILE':
      {
        if (isGettingBlobData.value) return;

        isGettingBlobData.value = true;
        const blobData = await getBlobDataOfFile();
        if (!blobData) return;

        isGettingBlobData.value = false;
        newTaskDefaultPayload.value = {
          assigneeId: getCurrentUserId(),
          files: [
            new File(
              [blobData],
              props.data?.name ||
                props.data?.fileName ||
                `tictop-chat-${Date.now()}.jpeg`,
              {
                type: blobData.type || 'image/jpeg',
              }
            ),
          ],
        };
        isOpenCreateTaskDrawer.value = true;
      }

      break;
    case 'CHECKLIST':
      {
        newTaskDefaultPayload.value = {
          assigneeId: getCurrentUserId(),
          checklist: [props.data],
        };
        isOpenCreateTaskDrawer.value = true;
      }
      break;

    default:
      break;
  }

  emit('openNewTask');
};

const onMoveFileToTask = async () => {
  switch (props.dataType) {
    case 'FILE':
      {
        const blobData = await getBlobDataOfFile();
        if (!blobData) return;

        attachmentToTaskGlobal.value = {
          isOpen: true,
          attachments: [
            new File(
              [blobData],
              props.data?.name ||
                props.data?.fileName ||
                `tictop-chat-${Date.now()}`,
              {
                type:
                  props.data?.type ||
                  props.data?.contentType ||
                  blobData.type ||
                  'image/jpeg',
              }
            ),
          ],
        };
      }

      break;
    case 'CHECKLIST':
      {
        attachmentToTaskGlobal.value = {
          isOpen: true,
          checklist: [props.data],
        };
      }

      break;

    default:
      break;
  }

  emit('openFindTask');
};
</script>

<template>
  <VigDropdown :z-index="50">
    <template #dropdown-toggle>
      <slot name="icon">
        <AtomButton
          type="BLANK"
          class="
            h-9
            w-9
            p-1
            border border-current-100
            flex-center
            rounded-full
            shadow-xl
            bg-white
            hover:bg-current-100
          "
          :is-loading="isGettingBlobData"
        >
          <SynIcon name="note" class="fill-current" />
        </AtomButton>
      </slot>
    </template>
    <template #dropdown-menu>
      <div
        class="py-1 z-100 list-ul rounded cursor-pointer dropdown-item"
        style="min-width: 11rem"
      >
        <div
          class="space-x-2 flex justify-start items-center text-sm list-li"
          @click="createNewTask"
        >
          <SynIcon is-active custom-class="fill-current  h-4 w-4" name="plus" />
          <span class="text-gray-600">
            {{ $t('CHAT_CREATE_NEW_TASK_WITH_THIS_FILE') }}
          </span>
        </div>
        <div
          class="space-x-2 flex justify-start items-center text-sm list-li"
          @click="onMoveFileToTask"
        >
          <SynIcon
            is-active
            custom-class="fill-blue-500 h-4 w-4"
            name="open-link"
          />
          <span class="text-gray-600">
            {{ $t('CHAT_MOVE_THIS_FILE_TO_A_TASK') }}
          </span>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
