import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import storeId from '@/base/store/storeId';
import { FilterQuery } from '@/domain/entities/task/FilterTask';
import TaskService from '@/application/services/task/TaskService';
import {
    genSortData,
    getUrgencyValue,
    sortDefaultTaskList,
    sortTaskDefault,
    taskStates,
} from '@/ui/hooks/taskHook';
import { TaskActionType, TaskListTab } from '@/domain/enums/taskEnum';
import {
    arrayOrderBy,
    cloneDeep,
    stringNomalize,
    uniq,
} from '@/ui/hooks/commonFunction';
import remoteConfigStore from '@/store/remoteConfig';
// import { keySearch } from '@/ui/modules/task/table/task-list-state';
import { RemoteConfigKey } from '@/ui/plugins/firebases/firebase-remote-config';
import taskTabView from '@/store/task/task-tab-view';
import { UpdateMultipleTaskPayload } from '@/application/types/task/task.types';
import { EFilterTaskDefault } from '@/application/constants/task.const';
// import taskSmartView from '@/store/task/task-smart-view';
// import _ from 'lodash';
import taskStore from '@/store/task';

const mapKeyForFilter = {
    creatorId: 'id',
    assigneeId: 'id',
    priority: 'key',
    urgency: 'id',
    domainId: 'id',
    groupId: 'id',
    taskLife: 'id',
};
type UserTaskSummaryStatistics = {
    allTasks: number;
    finishedTasks: number;
    overdueTasks: number;
    doTodayTasks: number;
    finishedTodayTasks: number;
    imAssignedTasks: number;
    collaboratorTask: number;
    otherTasks: number;
    pinnedTasks: number;
};
const enableSortMultiple = false;

export default defineStore({
    id: storeId.TASK_MANAGEMENT,
    state: () => ({
        isLoading: true,
        isLoadingFilterTask: false,
        isLoadMore: false,
        totalTasks: 0,
        totalAssignTasks: 0,
        taskIds: [] as any[],
        taskAllList: [] as any[],
        assignedTaskAllList: [] as any[],
        relatedTaskAllList: [] as any[],
        filteredTasks: [] as any[],
        listSubProject: [] as any[],
        sortList: [] as any[],
        activeTab: TaskListTab.MyList as TaskListTab,
        ownerInfo: {
            userId: 0,
            groupId: 0,
        } as any,
        filterDataByKey: {},
        filterPayload: new FilterQuery({
            isDefault: true,
        }),
        filterAssignedPayload: new FilterQuery({
            isDefault: true,
        }),
        filterRelatedPayload: new FilterQuery({
            isDefault: true,
        }),
        _taskService: TaskService.getInstance(),
        userTaskSummaryStatistics: {} as UserTaskSummaryStatistics,
        assignTaskSummaryStatistics: {} as UserTaskSummaryStatistics,
        approvalTaskSummaryStatistics: {} as UserTaskSummaryStatistics,
        selectedTaskIds: [] as number[],
        lastUpdatedTime: Date.now(),
    }),
    getters: {
        sortDataOrderByKey() {
            let result = {};
            const sortLength = this.sortList?.length;
            if (sortLength > 0) {
                const isShowIndex = sortLength > 1;
                result = this.sortList.reduce(
                    (previousValue, currentValue: any, index) => {
                        previousValue = {
                            ...previousValue,
                            ...{
                                [currentValue.key]: {
                                    sortIndex: isShowIndex ? index + 1 : null,
                                    orderBy: currentValue.orderBy,
                                },
                            },
                        };
                        return previousValue;
                    },
                    {}
                );
            }
            // console.log(' result-------------:', result);
            return result;
        },
    },
    actions: {
        setIsLoadingFilter(value) {
            this.isLoadingFilterTask = value;
        },
        setFilterPayload(filters) {
            this.filterPayload = new FilterQuery({
                ...this.filterPayload,
                ...filters,
            });
        },
        setFilterAssignedPayload(filters) {
            this.filterAssignedPayload = new FilterQuery({
                ...this.filterAssignedPayload,
                ...filters,
            });
        },
        setFilterRelatedPayload(filters) {
            this.filterRelatedPayload = new FilterQuery({
                ...this.filterRelatedPayload,
                ...filters,
            });
        },

        async getTaskWithFilter(filters) {
            try {
                this.isLoadingFilterTask = true;
                const res = await this._taskService.getSharedTasksWithFilters(
                    filters
                );
                this.taskAllList = (res?.result || []).map((o) => {
                    const taskDetail = {
                        ...o,
                        groupId: o.groupId || 0,
                        urgency: getUrgencyValue(o?.priority, o?.important),
                    };
                    return taskDetail;
                });

                taskStore().mergeAllTaskByIds(
                    this.taskAllList?.reduce((currentResult, currentItem) => {
                        return {
                            ...currentResult,
                            [currentItem?.id]: currentItem,
                        };
                    }, {})
                );
            } catch {
                this.isLoading = false;
                this.isLoadingFilterTask = false;
            } finally {
                this.isLoading = false;
                this.isLoadingFilterTask = false;
            }
        },

        async updateListTaskById(taskId, taskAction) {
            if (!taskId) return;
            // 1. Delete or Move out
            if (
                taskAction == TaskActionType.Delete ||
                taskAction == TaskActionType.MoveOut ||
                (this.activeTab == TaskListTab.MyRelated &&
                    taskAction == TaskActionType.RemoveCollaborator)
            ) {
                // 1.1 delete task id out list Id
                this.filteredTasks = this.filteredTasks.filter(
                    (t) => t.id !== taskId
                );
                this.taskIds = this.taskIds.filter((t) => t !== taskId);
                this.selectedTaskIds = this.selectedTaskIds.filter(
                    (t) => t !== taskId
                );
                this.taskAllList = this.taskAllList.filter(
                    (t) => t.id !== taskId
                );

                // taskSmartView().deleteTask(taskId);
                return;
            }
            // 2. Add or update
            const res = await this._taskService.getDetailTaskIncognitoById(
                taskId
            );
            const taskDetail = res['result'];

            const taskDetailUpdate = {
                ...taskDetail,
                urgency: getUrgencyValue(
                    taskDetail?.priority,
                    taskDetail?.important
                ),
            };
            taskStore().updateAllTaskByIds(
                taskDetailUpdate.id,
                taskDetailUpdate
            );

            // Update task's list
            switch (taskAction) {
                case TaskActionType.AddNew:
                case TaskActionType.MoveIn:
                    {
                        // taskSmartView().addTask(taskDetailUpdate);

                        const childTab = taskTabView().childrenTabActive;

                        if (
                            childTab === EFilterTaskDefault.FINISHED &&
                            !taskDetailUpdate?.finisherId
                        )
                            break;
                        if (
                            childTab === EFilterTaskDefault.DO_TODAY &&
                            !taskDetailUpdate?.isPlannedToFinish &&
                            !taskDetailUpdate?.isPlannedToTreat
                        )
                            return;
                        if (
                            childTab === EFilterTaskDefault.OVERDUE_DATE &&
                            taskDetailUpdate?.scheduleTime
                        ) {
                            const date1_fullyear = new Date(
                                taskDetailUpdate?.scheduleTime
                            ).getFullYear();
                            const date1_date = new Date(
                                taskDetailUpdate?.scheduleTime
                            ).getDate();
                            const date1_month = new Date(
                                taskDetailUpdate?.scheduleTime
                            ).getMonth();
                            const date2_fullyear = new Date().getFullYear();
                            const date2_date = new Date().getDate();
                            const date2_month = new Date().getMonth();

                            if (
                                date1_fullyear > date2_fullyear &&
                                date1_date > date2_date &&
                                date1_month > date2_month
                            )
                                break;
                        }

                        if (!this.taskAllList.some((o) => o.id == taskId))
                            this.taskAllList.push(taskDetailUpdate);

                        if (
                            this.activeTab !== TaskListTab.MyList ||
                            this.filteredTasks.some((o) => o.id == taskId)
                        )
                            return;

                        this.filteredTasks.push(taskDetailUpdate);
                        this.onSortFilteredTasks();
                    }
                    break;
                case TaskActionType.Assigned:
                    {
                        if (
                            !this.assignedTaskAllList.some(
                                (o) => o.id == taskId
                            )
                        )
                            this.assignedTaskAllList.push(taskDetailUpdate);

                        if (
                            this.activeTab !== TaskListTab.MyAssign ||
                            this.filteredTasks.some((o) => o.id == taskId)
                        )
                            return;

                        this.filteredTasks.push(taskDetailUpdate);
                        this.onSortFilteredTasks();
                    }
                    break;
                case TaskActionType.AddCollaborator:
                    {
                        // taskSmartView().addCollaboratorTask(taskDetailUpdate);

                        if (
                            !this.relatedTaskAllList.some((o) => o.id == taskId)
                        )
                            this.relatedTaskAllList.push(taskDetailUpdate);

                        if (
                            this.activeTab !== TaskListTab.MyRelated ||
                            this.filteredTasks.some((o) => o.id == taskId)
                        )
                            return;

                        this.filteredTasks.push(taskDetailUpdate);
                        this.onSortFilteredTasks();
                    }
                    break;

                default:
                    // taskSmartView().updateTask(taskDetailUpdate);

                    break;
            }
        },

        updateOwnerInfo(ownerInfo) {
            this.ownerInfo = ownerInfo;
        },

        setListProjectId() {
            const listProjectId = this.filteredTasks
                .filter((e: any) => e.projectId || !e.projectId)
                .map((o: any) => ({
                    id: o.projectId,
                    subProjectId: o.subprojectId,
                    selected: false,
                }));
            let listSubProjectId = [] as any;

            listProjectId.forEach((el: any) => {
                const index = listSubProjectId?.findIndex(
                    (o: any) => o?.id === el.id
                );
                if (index !== -1) {
                    const i = listSubProjectId[index]?.subProjectId?.findIndex(
                        (e) => e.subProjectId == el?.subProjectId
                    );
                    if (i !== -1) return;
                    else {
                        listSubProjectId[index]?.subProjectId?.push({
                            subProjectId: el?.subProjectId,
                            selectedId: false,
                        });
                    }
                } else {
                    listSubProjectId.push({
                        id: el?.id,
                        subProjectId: [
                            {
                                subProjectId: el?.subProjectId,
                                selectedId: false,
                            },
                        ],
                        selected: false,
                    });
                }
            });
            listSubProjectId = listSubProjectId.map((o) => {
                o.subProjectId = arrayOrderBy(
                    o.subProjectId,
                    'subProjectId',
                    'asc'
                );
                return o;
            });
            return listSubProjectId;
        },

        setFilterDataByKey(key, filters) {
            switch (key) {
                case 'scheduleTime':
                case 'creationTime':
                case 'lastModificationTime':
                    this.filterDataByKey[key] = filters;
                    break;
                case 'doToday':
                case 'finishToday':
                    this.filterDataByKey[key] = filters;
                    break;

                case 'projectId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);

                            const newListSubProject = o.subProjectId.map(
                                (e) => {
                                    const isFilterSub = filters.some(
                                        (u) =>
                                            u.id == o.id &&
                                            u.subProjectId.some(
                                                (el) =>
                                                    el.subProjectId ==
                                                        e.subProjectId &&
                                                    el.selectedId
                                            )
                                    );
                                    e.selectedId = isFilterSub;
                                    return e;
                                }
                            );
                            o.subProjectId = newListSubProject;
                            o.selected = isFilter;
                            return o;
                        });
                    }
                    break;

                case 'keySearch':
                    {
                        this.filterDataByKey[key] = filters;
                    }
                    break;

                default:
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);
                            o.selected = isFilter;
                            return o;
                        });
                    }
                    break;
            }
        },

        setFilterDataOrigin() {
            this.filterDataByKey = {
                urgency: [
                    {
                        id: 4,
                        selected: false,
                    },
                    {
                        id: 3,
                        selected: false,
                    },
                    {
                        id: 2,
                        selected: false,
                    },
                    {
                        id: 1,
                        selected: false,
                    },
                ],
                assigneeId: [
                    ...arrayOrderBy(
                        uniq(
                            this.filteredTasks.map(
                                (task) =>
                                    parseInt(task?.assigneeId?.toString()) || 0
                            )
                        )
                            .filter((o: any) => !isNaN(o))
                            .map((o) => {
                                return {
                                    id: o,
                                    selected: false,
                                };
                            }),
                        ['id'],
                        ['desc']
                    ),
                ],
                domainId: [
                    ...arrayOrderBy(
                        uniq(
                            this.filteredTasks.map((task) => task.domainId)
                        ).map((o) => {
                            return {
                                id: o,
                                selected: false,
                            };
                        }),
                        ['id'],
                        ['desc']
                    ),
                ],
                projectId: [
                    ...arrayOrderBy(
                        [...this.setListProjectId()],
                        ['subProjectId.length', 'id'],
                        ['desc', 'asc']
                    ),
                ],
                groupId: [
                    ...arrayOrderBy(
                        uniq(
                            this.filteredTasks.map((task) => task.groupId || 0)
                        ).map((o) => {
                            return {
                                id: o,
                                selected: false,
                            };
                        }),
                        ['id'],
                        ['desc']
                    ),
                ],
                doToday: [
                    {
                        key: 'DO_TODAY',
                        doItToday: true,
                        labelCode: 'TASK_CREATE_FORM_DOING_TODAY',
                    },
                    {
                        key: 'DO_NOT_TODAY',
                        doItToday: false,
                        labelCode: 'TASK_CREATE_FORM_DO_NOT_TODAY',
                    },
                ],
                finishToday: [
                    {
                        key: 'FINISH_TODAY',
                        finishItToday: true,
                        labelCode: 'TASK_CREATE_FORM_FINISH_TODAY',
                    },
                    {
                        key: 'NOT_FINISH_TODAY',
                        finishItToday: false,
                        labelCode: 'TASK_CREATE_FORM_NOT_FINISH_TODAY',
                    },
                ],
                creatorId: uniq(
                    this.filteredTasks.map((task) => task.creatorId)
                )
                    .filter((o) => o)
                    .map((o) => {
                        return {
                            id: o,
                            selected: false,
                        };
                    }),
                taskLife: [
                    ...arrayOrderBy(
                        uniq(
                            this.filteredTasks.map((task) => task.taskLife || 0)
                        ).map((o: any) => {
                            return {
                                id: o,
                                selected: false,
                                index: taskStates[o]?.index,
                            };
                        }),
                        ['index'],
                        ['asc']
                    ),
                ],
            };
        },
        resetFilterDataOrigin(key) {
            switch (key) {
                case 'scheduleTime':
                case 'creationTime':
                case 'lastModificationTime':
                    {
                        this.filterDataByKey[key] = {
                            start: null,
                            end: null,
                        };
                    }
                    break;
                case 'projectId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const listSubProject = o.subProjectId.map((u) => {
                                u.selectedId = false;
                                return u;
                            });
                            o.subProjectId = listSubProject;
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
                default:
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
            }
        },

        onFilterTaskOnColumn() {
            const filterData = cloneDeep(this.filterDataByKey);
            let filteredData =
                cloneDeep(
                    !this.activeTab || this.activeTab == TaskListTab.MyList
                        ? this.taskAllList
                        : this.assignedTaskAllList
                ) || [];

            if (filterData) {
                Object.entries(filterData).forEach(([key, value]: any) => {
                    switch (key) {
                        case 'scheduleTime':
                        case 'creationTime':
                        case 'lastModificationTime':
                            {
                                const filterType:
                                    | 'TODAY'
                                    | 'OTHER'
                                    | 'NOTHING'
                                    | null = value?.filterType;
                                if (!filterType) return;
                                if (filterType === 'NOTHING') {
                                    filteredData = [
                                        ...(filteredData?.filter((t) => {
                                            return !t[key];
                                        }) || []),
                                    ];
                                    return;
                                }
                                if (filterType === 'TODAY') {
                                    filteredData = [
                                        ...(filteredData?.filter((t) => {
                                            return dayjs(t[key]).isSame(
                                                Date.now(),
                                                'date'
                                            );
                                        }) || []),
                                    ];
                                    return;
                                }
                                if (!value?.start && !value?.end) return;

                                if (value?.start)
                                    filteredData = [
                                        ...(filteredData?.filter((t) => {
                                            return (
                                                dayjs(t[key]).isSame(
                                                    value?.start,
                                                    'date'
                                                ) ||
                                                dayjs(t[key]).isAfter(
                                                    value?.start,
                                                    'date'
                                                )
                                            );
                                        }) || []),
                                    ];
                                if (value?.end)
                                    filteredData = [
                                        ...(filteredData?.filter((t) => {
                                            return (
                                                dayjs(t[key]).isSame(
                                                    value?.end,
                                                    'date'
                                                ) ||
                                                dayjs(t[key]).isBefore(
                                                    value?.end,
                                                    'date'
                                                )
                                            );
                                        }) || []),
                                    ];
                            }
                            break;

                        case 'projectId':
                            {
                                const selectedFilter = (value as any).filter(
                                    (o) =>
                                        o.selected &&
                                        o.subProjectId.some((e) => e.selectedId)
                                );
                                if (selectedFilter.length == 0) return;

                                filteredData = [
                                    ...(filteredData?.filter((t) =>
                                        selectedFilter?.some(
                                            (f) =>
                                                f.id == t.projectId &&
                                                f.subProjectId.some(
                                                    (u) =>
                                                        u.subProjectId ==
                                                            t['subprojectId'] &&
                                                        u.selectedId
                                                )
                                        )
                                    ) || []),
                                ];
                            }
                            break;
                        case 'doToday':
                            {
                                const selectedFilter = (value as any).find(
                                    (o) => o.selected
                                );
                                if (!selectedFilter) return;

                                filteredData = [
                                    ...(filteredData?.filter(
                                        (t) =>
                                            t?.taskSchedule?.doItToday ==
                                            selectedFilter?.doItToday
                                    ) || []),
                                ];
                            }
                            break;
                        case 'finishToday':
                            {
                                const selectedFilter = (value as any).find(
                                    (o) => o.selected
                                );
                                if (!selectedFilter) return;

                                filteredData = [
                                    ...(filteredData?.filter(
                                        (t) =>
                                            t?.taskSchedule?.finishItToday ==
                                            selectedFilter?.finishItToday
                                    ) || []),
                                ];
                            }
                            break;
                        case 'keySearch':
                            {
                                const keySearch = value;
                                filteredData = !keySearch
                                    ? [...filteredData]
                                    : filteredData.filter(
                                          (t) =>
                                              stringNomalize(
                                                  t.name?.toLowerCase()
                                              ).includes(
                                                  stringNomalize(
                                                      keySearch?.toLowerCase()
                                                  )
                                              ) ||
                                              stringNomalize(
                                                  t.code?.toLowerCase()
                                              ).includes(
                                                  stringNomalize(
                                                      keySearch?.toLowerCase()
                                                  )
                                              ) ||
                                              stringNomalize(
                                                  t.displayName?.toLowerCase()
                                              ).includes(
                                                  stringNomalize(
                                                      keySearch?.toLowerCase()
                                                  )
                                              )
                                      );
                            }
                            break;

                        default:
                            {
                                const mapKey = mapKeyForFilter[key] || 'id';
                                const selectedFilter = (value as any).filter(
                                    (o) => o.selected
                                );
                                if (selectedFilter.length == 0) return;

                                filteredData = [
                                    ...(filteredData?.filter((t) =>
                                        selectedFilter?.some(
                                            (f) =>
                                                f[mapKey] == t[key] ||
                                                (!f[mapKey] && !t[key])
                                        )
                                    ) || []),
                                ];
                            }
                            break;
                    }
                });
            }

            this.filteredTasks = filteredData.filter((o) => o.id);

            this.onSortFilteredTasks();
        },

        onSortFilteredTasks() {
            if (!this.filteredTasks || this.filteredTasks.length == 0) {
                this.taskIds = [];
            }

            if (this.sortList?.length > 0) {
                const iterate = [
                    ...this.sortList.map((o: any) => o.orderFunction),
                    ...sortDefaultTaskList.iterate,
                ];

                const order = [
                    ...this.sortList.map((o: any) => o.orderBy),
                    ...sortDefaultTaskList.order,
                ];

                this.filteredTasks = arrayOrderBy(
                    this.filteredTasks,
                    iterate,
                    order
                );
            } else {
                this.filteredTasks = sortTaskDefault(this.filteredTasks);
            }

            const pageSize = remoteConfigStore().getRemoteConfig(
                RemoteConfigKey.WebUserTaskPageSize
            );

            this.taskIds = [
                ...new Set(
                    this.filteredTasks.map((o) => parseInt(o.id?.toString()))
                ),
            ].slice(0, pageSize.asNumber() || 30);

            this.unCheckAll();
        },

        genSortDataList(sortObj) {
            this.sortList = genSortData(
                this.sortList,
                sortObj,
                enableSortMultiple
            );

            this.onSortFilteredTasks();
        },

        setDefault() {
            this.filteredTasks = cloneDeep(this.taskAllList);
            const pageSize = remoteConfigStore().getRemoteConfig(
                RemoteConfigKey.WebUserTaskPageSize
            );
            this.taskIds = this.filteredTasks
                .map((o) => o.id)
                .slice(0, pageSize.asNumber() || 30);

            this.setFilterDataOrigin();
            this.unCheckAll();
        },
        onLoadMore() {
            this.isLoadMore = true;
            const currentLength = this.taskIds.length;
            if (currentLength < this.filteredTasks.length) {
                this.taskIds = this.filteredTasks
                    .map((o) => o.id)
                    .slice(0, currentLength + 10);
            }
            this.isLoadMore = false;
        },
        onSearchLocal(keySearch) {
            this.setFilterDataByKey('keySearch', keySearch);
            this.onFilterTaskOnColumn();
        },

        setDefaultInitData(tab: TaskListTab) {
            this.activeTab = tab;
            this.setFilterDataOrigin();
        },
        onResetSortAndFilter() {
            this.sortList = [];
        },
        onChangeTabActive(tab: TaskListTab) {
            this.activeTab = tab;

            this.onResetSortAndFilter();
        },
        async getAllAssigneeTaskStatisticByUser(userId) {
            const res =
                await this._taskService.getAllAssigneeTaskStatisticByUser(
                    userId
                );
            this.assignTaskSummaryStatistics = res['result'] || {};
        },
        async getAllApprovalTaskStatisticByUser(userId) {
            const res =
                await this._taskService.getAllAssigneeTaskStatisticByUser(
                    userId
                );
            this.approvalTaskSummaryStatistics = res['result'] || {};
        },

        onChooseTask(taskId: number, isSelected: boolean) {
            if (isSelected) {
                this.selectedTaskIds = [
                    ...new Set(this.selectedTaskIds).add(taskId),
                ];
            } else {
                this.selectedTaskIds = this.selectedTaskIds?.filter(
                    (id) => id !== taskId
                );
            }
        },
        unCheckAll() {
            this.selectedTaskIds = [];
        },
        handleCheckAll() {
            this.selectedTaskIds = [...this.filteredTasks?.map((t) => t.id)];
        },

        async updateMultipleTasks(data: UpdateMultipleTaskPayload) {
            try {
                await this._taskService.updateMultipleTasks(data);
                this.unCheckAll();
            } catch (error) {
                console.log('🚀 Hyrin ~ updateMultipleTasks ~ error:', error);
            }
        },
    },
});
