<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import chatStore from '@/store/chat';
import { useRoute, useRouter } from 'vue-router';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import CheckPermissionContainer from '@/ui/components/setting/permissions/CheckPermissionContainer.vue';
import {
  ChatExternalAcceptance,
  ChatMessageAction,
} from '@/domain/enums/chat-enums';
import {
  activeAndFocusOnConversationId,
  isForceActive,
} from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import ChatOverviewSlide from '@/ui/modules/messaging/chat-introduce/ChatOverviewSlide.vue';
import ChatPanel from '@/ui/modules/messaging/chat-panel/ChatPanel.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import ConversationsList from '@/ui/modules/messaging/conversations-list/ConversationsList.vue';
import permissionStore from '@/store/permission';
import {
  dayoffIdGlobal,
  eventIdGlobal,
  isShowDayoffDetail,
} from '@/ui/app-global-state';
import ChatInfoHeader from '@/ui/modules/messaging/chat-info-header/ChatInfoHeader.vue';
import chatExternalStore from '@/store/chat/chat-external';
import ModalChatPersonalWarning from '@/ui/modules/messaging/modal-chat-personal-warning/ModalChatPersonalWarning.vue';
import ChatExternalConfirmPanel from '@/ui/modules/messaging/chat-external/chat-external-confirm-panel/ChatExternalConfirmPanel.vue';
import userStore from '@/store/user';
import chatSupportingStore from '@/store/chat/chat-supporting';
import myProfileStore from '@/store/auth/my-profile';
import ConversationsListArchived from '@/ui/modules/messaging/conversations-list-archived/ConversationsListArchived.vue';

const router = useRouter();
const _useRoute = useRoute();
const _chatStore = chatStore();
const _chatExternalStore = chatExternalStore();
const _chatSupportingStore = chatSupportingStore();
const _myProfileStore = myProfileStore();

const currentOrgId = computed(() => _myProfileStore.myProfile?.organizationId);
const isPersonalModel = computed<boolean>(
  () => permissionStore().organizationModel === 'PERSONAL'
);
const contacts = computed(() => _chatStore.getContacts);
const allUsers = computed(() => _chatStore.allUsers);
const allUserByIds = computed(() => userStore().allUserByIds);
const userConversations = computed(() => _chatStore.userConversations);
const userConversationIds = computed(() => _chatStore.userConversationIds);
const conversationById = computed(() => _chatStore.conversationById);
const isLoadingConversations = computed(() => !_chatStore.usersChatInfo);

const conversationActive = computed(() => {
  const converId = _chatStore.conversationActive?.id;
  const converInfo =
    _chatStore.getConversationInfo(converId) || _chatStore.conversationActive;
  return converInfo
    ? {
        ..._chatStore.conversationActive,
        organizationId: converInfo?.externalChat
          ? _chatExternalStore.externalOrgId
          : converInfo?.supportingChat
          ? _chatSupportingStore.supportingOrgId
          : currentOrgId.value,
      }
    : null;
});

const activeConverInfo = computed(() =>
  _chatStore.getConversationInfo(conversationActive.value?.id)
);
const activeConverContact = computed<any>(() =>
  contacts.value ? contacts.value[conversationActive.value?.id] : null
);
const externalChatPending = computed(
  () =>
    activeConverInfo.value?.externalAcceptance ===
    ChatExternalAcceptance.PENDING
);
const lastSelectedConversation = computed(
  () => _chatStore.lastSelectedConversation
);
const chatMsgAllowedActions = computed(() =>
  [
    ChatMessageAction.REPLY,
    ChatMessageAction.FORWARD,
    ChatMessageAction.REACTION,
    ChatMessageAction.PIN,
    ChatMessageAction.COPY,
    ChatMessageAction.SET_BACKGROUND,
    ChatMessageAction.DELETE,
    ChatMessageAction.DOWNLOAD_FILE,
    ChatMessageAction.CREATE_TASK,
    ChatMessageAction.REMINDER,
  ].concat(
    activeConverInfo.value?.externalChat ||
      activeConverInfo.value?.supportingChat
      ? []
      : [ChatMessageAction.USER_ACTIONS]
  )
);

const isNewChat = ref();
const isShowArchivedConversations = ref();
const chatPanelRef = ref();
const currentIndex = ref(null) as any;
const isPersonalWarning = ref(false);
const isExternalMessagesPreview = ref<boolean>();

watch(
  () => _useRoute?.params,
  (params) => {
    _processConversationActive(params?.conversationId);
  }
);

watch(
  () => _chatStore.chatAboutTask,
  (currValue) => {
    chatPanelRef.value && chatPanelRef.value.setChatAboutTask(currValue);
  }
);

watch(
  () => _chatStore.chatAboutDayoff,
  (currValue) => {
    chatPanelRef.value && chatPanelRef.value.setChatAboutDayoff(currValue);
  }
);

watch(
  () => _chatStore.chatAboutEvent,
  (currValue) => {
    chatPanelRef.value && chatPanelRef.value.setChatAboutEvent(currValue);
  }
);

watch(
  () => conversationActive.value,
  () => {
    isExternalMessagesPreview.value = false;
  }
);

onMounted(() => {
  _chatStore.bubbleChatActive = null;

  if (_useRoute?.params?.conversationId) {
    _processConversationActive(_useRoute?.params?.conversationId);
  } else if (lastSelectedConversation.value) {
    onConversationClick(lastSelectedConversation.value?.id);
  }

  _verifyToAutoAddPersonalChat();
});

onUnmounted(() => {
  _chatStore.closeBubbleChat();
  // _chatStore.clearLastSelectedConversation();
  activeAndFocusOnConversationId.value = '';
  isForceActive.value = false;
});

const onNewChatClick = () => {
  if (isPersonalModel.value) {
    isPersonalWarning.value = true;
    return;
  }

  isNewChat.value = true;
};

const onConversationClick = async (conversationId) => {
  const discardAndContinue = await _chatStore.handleCheckFormHaveAction();
  if (!discardAndContinue) return;

  _processConversationActive(conversationId);

  _resetTabIndex();

  _chatStore.setIdConversationActive(conversationId);
  _chatStore.setLastSelectedConversation({
    id: conversationId,
    ...userConversations.value[conversationId],
  });

  router.push({ name: 'Messages', params: { conversationId } });
};

const onFocusOnConversation = (conversationId) => {
  activeAndFocusOnConversationId.value = `${conversationId}`;
};

const onOpenNoteClick = () => {
  chatPanelRef.value?.openNote();
};

const onMessageReply = (message) => {
  chatPanelRef.value?.replyMessage(message);
};

const onMessageViewFile = (file) => {
  chatPanelRef.value?.onViewFile(conversationActive.value?.id, file);
};

const onMessageTaskClick = (taskCode) => {
  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;
  // taskDrawerStore().pushCurrentIds({
  //   id: task?.id,
  //   code: task?.code,
  //   name: task?.name,
  //   tabType: 'DETAIL',
  // });
};

const onSupportingChatLeft = () => {
  router.push({ name: 'Messages' });
};

const onMessageDayoffClick = (dayoffId) => {
  dayoffIdGlobal.value = dayoffId;
  isShowDayoffDetail.value = true;
};

const onMessageEventClick = (eventId) => {
  eventIdGlobal.value.isShow = true;
  eventIdGlobal.value.id = eventId;
};

const onSetUserConversationSeenAll = (converId) => {
  return _chatStore.setSeenUserConversation(currentOrgId.value, converId);
};

const _processConversationActive = (conversationId) => {
  if (conversationId === conversationActive.value?.id) return;

  if (!conversationId) return _chatStore.setConversationActive(null);

  _addNewActiveConversation({
    id: conversationId,
    ...userConversations.value[conversationId],
  });
};

const _addNewActiveConversation = (newConversation) => {
  activeAndFocusOnConversationId.value = `${newConversation?.id}`;

  _chatStore.activeBubbleChat(newConversation);
  _chatStore.setConversationActive(newConversation);
};

const _resetTabIndex = () => {
  currentIndex.value = null;
};

const _verifyToAutoAddPersonalChat = () => {
  if (permissionStore().organizationModel === 'PERSONAL') {
    _chatStore.autoAddCurrentUserChat();
  }
};
</script>

<template>
  <CheckPermissionContainer :function-names="['chatEnabled']">
    <div
      v-permission-screen="{
        screenCode: ALL_SCREENS.COMMON.MESSAGE.PAGE.ALL,
      }"
      class="h-full w-full flex bg-white shadow relative"
    >
      <!--CONVERSATIONS LIST-->
      <div
        v-permission-screen="{
          screenCode: ALL_SCREENS.COMMON.MESSAGE.PAGE.LIST_CONVERSATION,
        }"
        class="h-full w-1/3 max-w-sm border-r flex flex-col"
        style="min-width: 15rem"
      >
        <template v-if="!isShowArchivedConversations">
          <!--HEADER-->
          <div class="px-3 py-2.5 flex items-center justify-between">
            <div class="text-2xl font-semibold">
              {{ $t('CHAT_LABEL') || 'Chat' }}
            </div>
            <div class="flex-center space-x-2">
              <VigDropdown>
                <template #dropdown-toggle>
                  <button
                    v-permission-model
                    class="
                      w-9
                      h-9
                      flex-center
                      bg-gray-100
                      rounded-full
                      hover:bg-gray-200
                    "
                  >
                    <SynIcon name="DotsVertical" />
                  </button>
                </template>
                <template #dropdown-menu>
                  <div class="text-sm">
                    <button
                      class="
                        px-4
                        py-2
                        w-full
                        hover:bg-gray-100
                        flex
                        items-center
                        space-x-4
                        dropdown-item
                      "
                      @click="isShowArchivedConversations = true"
                    >
                      <SynIcon name="Archive" class="text-gray-500" />
                      <span>{{
                        $t('CHAT_ARCHIVED_CONVERS') || 'Archived conversations'
                      }}</span>
                    </button>
                  </div>
                </template>
              </VigDropdown>
              <button
                class="
                  w-9
                  h-9
                  bg-gray-100
                  hover:bg-gray-200
                  rounded-full
                  flex-center
                "
                @click="onNewChatClick"
              >
                <SynIcon name="Plus" />
              </button>
            </div>
          </div>

          <!--BODY-->
          <div class="flex-1 overflow-hidden">
            <ConversationsList
              :organization-id="currentOrgId"
              :active-conversation="conversationActive"
              :members="allUserByIds"
              :conversation-by-id="conversationById"
              :conversation-ids="userConversationIds"
              :is-personal-org="isPersonalModel"
              :is-loading="isLoadingConversations"
              can-create-new-chat
              can-view-group-chat-task
              @on-conversation-click="onConversationClick"
              @on-new-chat-click="onNewChatClick"
            />
          </div>
        </template>

        <ConversationsListArchived
          v-else
          :active-conversation="conversationActive"
          @on-close="isShowArchivedConversations = false"
          @on-conversation-click="onConversationClick"
        />
      </div>

      <!--ACTIVE CHAT-->
      <div
        v-if="activeConverInfo"
        v-permission-screen="{
          screenCode: ALL_SCREENS.COMMON.MESSAGE.PAGE.CHAT_PANEL,
        }"
        class="flex-1 overflow-auto flex flex-col w-full h-full"
        @click="onFocusOnConversation(conversationActive?.id)"
      >
        <!--ACTIVE CHAT INFO-->
        <ChatInfoHeader
          v-if="!externalChatPending || isExternalMessagesPreview"
          :organization-id="conversationActive?.organizationId"
          :conversation-id="conversationActive?.id"
          :conversation="activeConverInfo"
          :contact="activeConverContact"
          :is-personal-model="isPersonalModel"
          @on-open-note-click="onOpenNoteClick"
          @on-message-reply="onMessageReply"
          @on-message-view-file="onMessageViewFile"
          @on-message-task-click="onMessageTaskClick"
          @on-supporting-chat-left="onSupportingChatLeft"
          @on-search-click="chatPanelRef?.setSearchOpened(true)"
        />

        <ChatExternalConfirmPanel
          v-if="externalChatPending"
          v-model:isPreviewMessages="isExternalMessagesPreview"
          :conversation="activeConverInfo"
        />

        <!--ACTIVE CHAT MESSAGES-->
        <div
          v-if="!externalChatPending || isExternalMessagesPreview"
          class="flex-1 h-full overflow-hidden"
        >
          <ChatPanel
            ref="chatPanelRef"
            size="lg"
            :organization-id="conversationActive?.organizationId"
            :conversation-id="conversationActive?.id"
            :conversation="activeConverInfo"
            :contact="activeConverContact"
            :users="allUsers"
            :is-preview="externalChatPending"
            :is-loading="isLoadingConversations"
            :allowed-actions="chatMsgAllowedActions"
            @on-message-task-click="onMessageTaskClick"
            @on-message-dayoff-click="onMessageDayoffClick"
            @on-message-event-click="(eventId) => onMessageEventClick(eventId)"
            @on-set-user-conversation-seen-all="onSetUserConversationSeenAll"
          />

          <!--CONVERSATION NOT FOUND-->
          <!--          <div v-else class="w-full h-full flex flex-col">
                      <div class="h-1/4"></div>
                      <div class="h-3/4">
                        <AtomAnimationPage
                          name="searchNotFound"
                          :title="'The conversation is not found'"
                          :label="
                            $t('CHAT_YOU_DONT_HAVE_PERMISSION_IN_THIS_CONVERSATION')
                          "
                        />
                      </div>
                    </div>-->
          <!-- TASK LIST -->
          <!--          <div-->
          <!--            v-if="isShowTaskListInChat && taskListOwnerId"-->
          <!--            class="border-l border-gray-300"-->
          <!--            style="width: 25rem"-->
          <!--          >-->
          <!--            <TaskCardList-->
          <!--              :type="conversationActiveType"-->
          <!--              :owner-id="taskListOwnerId"-->
          <!--              @on-close="onCloseTaskListInChat(conversationActive?.id)"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>

      <!--NO ACTIVE CHAT-->
      <div v-else class="flex-1 overflow-hidden flex-center flex-col">
        <ChatOverviewSlide />
      </div>
    </div>
  </CheckPermissionContainer>

  <ModalNewChat
    v-if="isNewChat"
    has-external-chat
    @on-close="isNewChat = false"
    @on-conversation-click="isNewChat = false"
  />

  <ModalChatPersonalWarning
    v-if="isPersonalWarning"
    @on-close="isPersonalWarning = false"
  />
</template>
