<script setup lang="ts">
import { ref, computed } from 'vue';
import taskDrawerStore from '@/store/task/drawer';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { allowedExtensionString } from '@/ui/modules/task/task-global-state';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import AttachmentGroupButton from '@/ui/modules/task/components/AttachmentGroupButton.vue';
import updateAttachmentLogic from '@/ui/modules/task/detail/update-attachment-logic';
import AttachmentUploading from '@/ui/modules/task/components/AttachmentUploading.vue';
import taskStore from '@/store/task';

const taskAttachmentDetailInputId = 'task-detail-modal_attachment';
const _taskStore = taskStore();
const _taskDrawerStore = taskDrawerStore();

const props = defineProps<{
  taskId: number;
  defaultViewFileId?: string;
}>();
const emit = defineEmits(['onClose']);
const currentTask = ref({} as TaskDetailClass);
const isLoading = ref(true);

const {
  isOpenNote,
  drawNoteData,
  uploadError,
  uploadingFiles,
  onOpenCreateNote,
  onFetchUpdateNote,
  onFetchCreateNote,
  onChooseFiles,
  isOpenRecording,
  onCreateAudioDone,
  isLoadingFile,
} = updateAttachmentLogic(currentTask, taskAttachmentDetailInputId);

const allAttachments = ref<any[]>([]);
const startIndex = ref<number>(0);

const init = async () => {
  try {
    if (!props.taskId) return;
    const taskDetail = await _taskDrawerStore.getDetailById(props.taskId);
    currentTask.value = new TaskDetailClass(taskDetail);
    await currentTask.value?.getAttachmentData();

    allAttachments.value = [
      ...currentTask.value?.attachments,
      ...(currentTask.value?.description
        ? [
            {
              id: `TASK_ATTACHMENT_DETAIL__DESCRIPTION`,
              type: 'text',
              data: currentTask.value?.description,
            },
          ]
        : []),
      ...(currentTask.value.taskTodoTotal > 0
        ? [
            {
              id: `TASK_ATTACHMENT_DETAIL__TODO_LIST`,
              type: 'todo_list',
              data: '',
            },
          ]
        : []),
      {
        id: `TASK_ATTACHMENT_DETAIL__COMMENT`,
        type: 'comment',
        data: '',
      },
    ];

    startIndex.value = props.defaultViewFileId
      ? allAttachments.value?.findIndex((f) => f?.id == props.defaultViewFileId)
      : 0;
  } catch (error) {
    console.log('🚀 ~ file: TaskDetail.vue ~ line 50 ~ init ~ error', error);
  } finally {
    isLoading.value = false;
  }
};

const onRemoveAttachment = (file) => {
  if (file.isNote) {
    return onRemoveNote(file);
  } else {
    onRemoveFile(file);
  }
};

const onRemoveNote = async (note) => {
  await currentTask.value.removeNote(note);

  const newNotes = currentTask.value?.notes.filter((o) => o.id !== note?.id);
  currentTask.value = {
    ...currentTask.value,
    notes: newNotes,
  };
  _taskStore.updateAllTaskByIds(currentTask.value?.id, {
    ...currentTask.value,
    noteCount: newNotes.length,
  });
  if (allAttachments.value?.length == 0) emit('onClose');
};

const onRemoveFile = async (file) => {
  if (!file) return;
  await currentTask.value.removeFile(file);

  const newFiles = currentTask.value?.files?.filter((o) => o.id !== file?.id);

  currentTask.value.files = newFiles;

  _taskStore.updateAllTaskByIds(currentTask.value?.id, {
    ...currentTask.value,
    attachmentCount: newFiles.length,
  });
  if (allAttachments.value?.length == 0) emit('onClose');
};

const isGroupMember = computed(() => currentTask.value?.isGroupMember);
const isGroupTask = computed(
  () => currentTask.value?.groupId && currentTask.value?.groupId > 0
);
const hasPermission = computed(() => {
  return isGroupTask.value ? isGroupMember.value : true;
});

const onChangeDescription = (newDescription) => {
  currentTask.value.changeDescription(newDescription);

  _taskStore.updateAllTaskByIds(currentTask.value?.id, {
    ...currentTask.value,
    description: newDescription,
  });
};

const modalMultipleFileViewerRef = ref(null as any);

const onOpenCommentModal = () => {
  modalMultipleFileViewerRef.value?.onOpenEditDescription();
};
const onAddTodoList = () => {
  modalMultipleFileViewerRef.value?.onAddTodoList();
};

const updateFileStatus = (isNote, fileId, status) => {
  if (isNote) currentTask.value.updateNoteStatus(fileId, status);
  else currentTask.value.updateFileStatus(fileId, status);
};
init();
</script>

<template>
  <SynLoading v-if="isLoading" />
  <ModalMultipleFileViewer
    v-else-if="allAttachments?.length > 0"
    ref="modalMultipleFileViewerRef"
    :start-index="startIndex"
    :files="allAttachments"
    :editable="hasPermission"
    :current-task="currentTask"
    :is-loading="isLoadingFile"
    is-show-mark-action
    @on-close="$emit('onClose')"
    @on-save-note="onFetchUpdateNote"
    @on-save-description="onChangeDescription"
    @on-remove="onRemoveAttachment"
    @on-update-file-status="updateFileStatus"
  >
    <template #title>
      <span>
        {{ formatTaskCode(currentTask?.code) }} -
        {{ currentTask?.name }}
      </span>
    </template>
    <template #footer-action>
      <template v-if="uploadingFiles.length > 0">
        <AttachmentUploading
          :uploading-files="uploadingFiles"
          :upload-error="uploadError"
        />
      </template>
      <AttachmentGroupButton
        v-if="hasPermission"
        class="flex-none bg-opacity-50"
        :input-file-id="taskAttachmentDetailInputId"
        @handle-click-note="onOpenCreateNote"
        @handle-click-audio="isOpenRecording = true"
        @handle-click-comment="onOpenCommentModal"
        @on-add-todo-list="onAddTodoList"
      />
    </template>
  </ModalMultipleFileViewer>
  <input
    v-if="hasPermission"
    :id="taskAttachmentDetailInputId"
    type="file"
    multiple
    class="hidden opacity-0"
    :accept="allowedExtensionString"
    @change="onChooseFiles"
  />
  <CreateNoteModal
    v-if="isOpenNote"
    :draw-note-data="drawNoteData"
    @on-close="isOpenNote = false"
    @on-save="onFetchCreateNote"
  />

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onCreateAudioDone"
    @on-cancel="isOpenRecording = false"
  />
</template>
