<script setup lang="ts">
import dayjs from 'dayjs';
// import { useScreens } from 'vue-screen-utils';
import { ref, computed, onMounted } from 'vue';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import {
  ScheduleDayOptions,
  ScheduleOtherTimeOptions,
} from '@/domain/enums/taskEnum';
import taskStore from '@/store/task';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import { IConfigType } from '@/application/types/task/task-configs.types';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ScheduleDatePicker from '@/ui/modules/task/detail/update-deadline/ScheduleDatePicker.vue';
// import DateInput from '@/ui/common/atoms/date-pickers/DateInput.vue';

const props = defineProps<{
  selectSpecificDate?: boolean;
  isHiddenExactDate?: boolean;
  scheduleKey?: number;
  otherTime?: number | null;
  schedule?: string;
  viewMode?: 'VIEW' | 'EDIT';
  startTime?: string;
  isHiddenStartTime?: boolean;
}>();

const emit = defineEmits([
  'cancel',
  'save',
  'isOpenChoose',
  'saveRealtime',
  'onChangeStartTime',
]);

const _taskStore = taskStore();

// const { mapCurrent } = useScreens({
//   xs: '0px',
//   sm: '640px',
//   md: '768px',
//   lg: '1024px',
// });
// const columns = mapCurrent({ lg: 2 }, 1);

const taskDataConfigScheduleDayOptions = computed<IConfigType[]>(() => {
  return (
    _taskStore.dataConfigs?.scheduleDayOptions
      ?.filter((o) => o?.active && o.key !== ScheduleDayOptions.OtherTime)
      ?.map((o) => {
        let customText = '';
        switch (o?.key) {
          case ScheduleDayOptions.OnDay:
            customText = translate(`COMMON_LABEL_DEADLINE_SAME_DAY`);
            break;
          case ScheduleDayOptions.D1:
          case ScheduleDayOptions.D2:
          case ScheduleDayOptions.D3:
            customText = translate(`COMMON_LABEL_DEADLINE_AFTER_DAY`, {
              totalDays: o?.key || '',
              suffix: o?.key > 1 ? 's' : '',
            });
            break;

          default:
            customText = o.text;
            break;
        }
        return {
          ...o,
          text: customText,
        };
      }) || []
  );
});

const currentSchedule = computed<IConfigType>(() => {
  return taskDataConfigScheduleDayOptions.value?.find(
    (o) => o.id == scheduleOptionKey.value
  );
});

const taskDataConfigOtherTimeOption = computed(
  () =>
    _taskStore.dataConfigs?.scheduleOtherTimeOptions?.filter(
      (o) => o?.active && o.key !== ScheduleOtherTimeOptions.SpecificDate
    ) || []
);
const userDayDeadlineInfo = computed(() => _taskStore.userDayDeadlineInfo);
const userOtherDay = computed(() => {
  const index = userDayDeadlineInfo.value.findIndex(
    (o) => o?.scheduleOptionKey == ScheduleDayOptions.OtherTime
  );
  if (index > -1) {
    return userDayDeadlineInfo.value[index].otherTimes;
  }
  return [];
});
const textDateChooseActive = computed(() => {
  if (scheduleOptionKey.value !== ScheduleDayOptions.OtherTime) return '';
  if (otherTimeKey.value === ScheduleOtherTimeOptions.SpecificDate) {
    return dayjs(scheduleTime.value)?.format('DD/MM/YYYY');
  }
  const index = taskDataConfigOtherTimeOption.value?.findIndex(
    (o) => o.key == otherTimeKey.value
  );
  if (index > -1) return taskDataConfigOtherTimeOption.value[index].text;
  return '';
});
const scheduleOptionKey = ref<ScheduleDayOptions | null>(null);
const otherTimeKey = ref(
  ScheduleOtherTimeOptions.SpecificDate as ScheduleOtherTimeOptions
);
const scheduleTime = ref('' as string);
const isOpenDropdownOtherTime = ref(false);
const isAutoFocus = ref(false);
// const isCurrentDayOffSelect = computed(
//   () =>
//     dayjs(new Date()).format('DD-MM-YYYY') ==
//     dayjs(scheduleTime.value).format('DD-MM-YYYY')
// );
const dateSpecificPickerRef = ref(null as any);
const dropdownSpecificDateRef = ref(null as any);

const initData = () => {
  scheduleOptionKey.value = props.scheduleKey;
  otherTimeKey.value = props.otherTime || ScheduleOtherTimeOptions.SpecificDate;
  scheduleTime.value = props.schedule || '';
  scheduleTimeInput.value = props.schedule || '';
};

onMounted(async () => {
  dateSpecificPickerRef.value?.hidePopover();
});

const getDateByOptions = () => {
  let result = '';
  switch (scheduleOptionKey.value) {
    case ScheduleDayOptions.OnDay:
      result = dayjs().format('YYYY-MM-DD');
      break;
    case ScheduleDayOptions.D1:
      result = dayjs().add(1, 'day').format('YYYY-MM-DD');
      break;
    case ScheduleDayOptions.D2:
      result = dayjs().add(2, 'day').format('YYYY-MM-DD');
      break;
    case ScheduleDayOptions.D3:
      result = dayjs().add(3, 'day').format('YYYY-MM-DD');
      break;
    case ScheduleDayOptions.Nothing:
      result = '';
      break;
    // case ScheduleDayOptions.OtherTime:
    // result = ''
    //   break;

    default:
      break;
  }

  return result;
};

const onChangeSchedule = () => {
  scheduleTime.value = getDateByOptions();
  onSave();
};

const getScheduleOtherTime = () => {
  let time;
  const key = otherTimeKey.value;
  const options = [...userOtherDay.value];

  const index = userOtherDay.value?.findIndex((o) => o.otherTime == key);
  if (index > -1) {
    time = options[index].scheduleDateValue;
  }
  time &&
    openNotification({
      type: AlertType.info,
      body: time,
      duration: 1000,
    });
  return time;
};

const onChangeOtherTime = () => {
  scheduleTime.value = getScheduleOtherTime();

  // onSave();
};

const onChooseDateSpecific = (date) => {
  if (!date) {
    scheduleOptionKey.value = ScheduleDayOptions.Nothing;
    onChangeSchedule();
    return;
  }
  let dateFormat = dayjs(date).format('YYYY-MM-DD');

  const dayInfoList = [
    ...userDayDeadlineInfo.value,
    // ...userOtherDay.value,
  ]?.filter((o) => o.scheduleDateValue);

  const findDateInData = dayInfoList?.find(
    (o) => o?.scheduleDateValue == dateFormat
  );

  scheduleTime.value = dateFormat;
  if (findDateInData) {
    scheduleOptionKey.value = Number.isInteger(
      findDateInData?.scheduleOptionKey
    )
      ? findDateInData?.scheduleOptionKey
      : ScheduleDayOptions.OtherTime;

    otherTimeKey.value =
      findDateInData?.otherTime || ScheduleOtherTimeOptions.SpecificDate;
  } else {
    scheduleOptionKey.value = ScheduleDayOptions.OtherTime;

    otherTimeKey.value = ScheduleOtherTimeOptions.SpecificDate;
  }

  onSave();
};

const onSave = () => {
  emit('save', {
    scheduleOptionKey: scheduleOptionKey.value,
    otherTimeKey: otherTimeKey.value,
    scheduleTime: scheduleTime.value,
    startTime: deadlineData.value?.startTime,
    updateMode: deadlineData?.value?.updateMode,
  });
  emit('isOpenChoose', false);
  dropdownSpecificDateRef.value?.onForceClose();
};

const onCloseDropdownOtherTime = () => {
  isOpenDropdownOtherTime.value = false;
  isAutoFocus.value = false;
  // onSave();
};

const onOpenDropdownOtherTime = () => {
  scheduleTimeInput.value = scheduleTime.value
    ? dayjs(scheduleTime.value).format('DD/MM/YYYY')
    : '';
  isOpenDropdownOtherTime.value = true;
  isAutoFocus.value = true;
  emit('isOpenChoose', true);
};

const scheduleTimeInput = ref<string>();

const onChangeStartTime = (startTime) => {
  emit('onChangeStartTime', startTime);
};

const deadlineData = ref<{
  startTime?: string;
  scheduleTime?: string;
  updateMode?: 'START_ONLY' | 'END_ONLY' | 'BOTH';
}>({
  updateMode: 'END_ONLY',
});
const onChangeRangeTime = (data: {
  startTime?: string;
  scheduleTime?: string;
  updateMode?: 'START_ONLY' | 'END_ONLY' | 'BOTH';
}) => {
  deadlineData.value = data;

  switch (data?.updateMode) {
    case undefined:
    case 'END_ONLY':
    case 'BOTH':
      onChooseDateSpecific(data?.scheduleTime);
      break;

    default:
      onSave();
      break;
  }
};

const onCloseDatePicker = () => {
  dropdownSpecificDateRef.value?.onForceClose();
};

initData();
</script>
<template>
  <div class="relative">
    <template v-if="viewMode == 'EDIT'">
      <div v-if="!selectSpecificDate" class="flex flex-col w-full">
        <div class="flex items-center w-full pb-1">
          <div class="flex-1 flex flex-wrap items-center">
            <SynRadioTag
              v-for="schedule in taskDataConfigScheduleDayOptions"
              :key="schedule.key"
              v-model="scheduleOptionKey"
              :value="schedule.key"
              :label="schedule.text"
              name="task-create-form_schedule"
              radio-name="task-create-form_priority"
              class="mr-1 mb-0.5 mt-0.5 relative"
              @change="onChangeSchedule"
            >
              <SynTag size="circle-small" custom-class="py-1 px-2">
                <span>{{ schedule.text }}</span>
              </SynTag>
            </SynRadioTag>

            <!-- OTHER TIME         -->
            <VigDropdown
              v-if="!isHiddenExactDate"
              ref="dropdownSpecificDateRef"
              placement="left"
              @on-dropdown-open="onOpenDropdownOtherTime"
              @on-dropdown-close="onCloseDropdownOtherTime"
            >
              <template #dropdown-toggle>
                <div
                  class="
                    flex-center
                    cursor-pointer
                    border border-current-50
                    rounded-full
                  "
                  :class="`${
                    scheduleTime &&
                    scheduleTime !== 'Invalid Date' &&
                    scheduleOptionKey === ScheduleDayOptions.OtherTime
                      ? 'bg-current fill-white text-white hover:bg-current-500 px-2 py-1'
                      : 'fill-gray-500 text-gray-500 hover:bg-current-50 p-1 h-6 w-6'
                  }`"
                >
                  <span
                    v-if="
                      scheduleTime &&
                      scheduleTime !== 'Invalid Date' &&
                      scheduleOptionKey === ScheduleDayOptions.OtherTime
                    "
                    class="text-xs font-medium pr-1"
                  >
                    {{ textDateChooseActive }}
                  </span>
                  <SynIcon
                    name="dots-vertical"
                    class="rotate-90"
                    custom-class="w-4 h-4"
                  />
                </div>
              </template>
              <template #dropdown-menu>
                <div
                  class="flex flex-col p-3 space-y-2 rounded"
                  style="min-width: 40rem"
                >
                  <ScheduleDatePicker
                    v-if="isOpenDropdownOtherTime"
                    :start-time="startTime"
                    :schedule-time="scheduleTime"
                    :is-hidden-start-time="isHiddenStartTime"
                    @on-change-start-time="onChangeStartTime"
                    @on-change-schedule-time="onChangeRangeTime"
                    @close="onCloseDatePicker"
                  >
                    <template #otherTime>
                      <div class="flex flex-wrap">
                        <template
                          v-for="schedule in taskDataConfigOtherTimeOption"
                          :key="schedule.key"
                        >
                          <SynRadioTag
                            v-model="otherTimeKey"
                            :value="schedule.key"
                            :label="schedule.text"
                            name="task-create-form_schedule-othertime"
                            radio-name="task-create-form_priority"
                            class="m-0.5"
                            @change="onChangeOtherTime"
                          >
                            <SynTag
                              size="circle-small"
                              custom-class="py-1 px-2"
                            >
                              <span>{{ schedule.text }}</span>
                            </SynTag>
                          </SynRadioTag>
                        </template>
                      </div>
                    </template>
                  </ScheduleDatePicker>
                </div>
                <!-- <div
                  v-if="isOpenDropdownOtherTime"
                  class="flex flex-col p-3 space-y-2 rounded"
                  style="min-width: 20rem"
                >
                  <div class="flex justify-between space-x-1 items-center">
                    <span class="font-medium text-sm pb-3">{{
                      $t('TASK_SELECT_SPECIFIC_DATE_LABEL')
                    }}</span>
                  </div>

                  <div class="flex flex-col">
                    <div class="flex items-center space-x-1 w-full">
                      <DateInput
                        v-model="scheduleTimeInput"
                        :autofocus="isAutoFocus"
                        @enter="onChooseDateSpecific(scheduleTimeInput)"
                        @on-clear="scheduleTimeInput = ''"
                      />

                      <SynButton
                        type-fill
                        color="current"
                        :label="$t('COMMON_LABEL_SAVE')"
                        :disabled="
                          scheduleTimeInput === '' ||
                          scheduleTimeInput === 'Invalid Date'
                        "
                        custom-class="rounded px-2 py-1 text-sm"
                        @click="onChooseDateSpecific(scheduleTimeInput)"
                      />
                    </div>
                  </div>

                  <div class="flex flex-wrap">
                    <template
                      v-for="schedule in taskDataConfigOtherTimeOption"
                      :key="schedule.key"
                    >
                      <SynRadioTag
                        v-model="otherTimeKey"
                        :value="schedule.key"
                        :label="schedule.text"
                        name="task-create-form_schedule-othertime"
                        radio-name="task-create-form_priority"
                        class="m-0.5"
                        @change="onChangeOtherTime"
                      >
                        <SynTag size="circle-small" custom-class="py-1 px-2">
                          <span>{{ schedule.text }}</span>
                        </SynTag>
                      </SynRadioTag>
                    </template>
                  </div>

                  <div class="w-full">
                    <v-date-picker
                      ref="dateSpecificPickerRef"
                      style="font-size: 13px !important"
                      class="
                        datepicker_view
                        border border-gray-100
                        text-sm
                        w-full
                        vig
                      "
                      :model-value="scheduleTime"
                      :columns="columns"
                      :config="{ type: 'string', mask: 'YYYY/MM/DD' }"
                      :popover="{ visibility: 'hidden' }"
                      color="teal"
                      :attributes="[
                        {
                          key: 'today',
                          dot: {
                            color: isCurrentDayOffSelect ? 'yellow' : 'teal',
                            class: isCurrentDayOffSelect ? 'mb-1 z-70' : '',
                          },
                          dates: new Date(),
                        },
                      ]"
                      :first-day-of-week="2"
                      @update:model-value="
                        (value) => onChooseDateSpecific(value)
                      "
                    />
                  </div>
                </div> -->
              </template>
            </VigDropdown>
          </div>
        </div>
      </div>
      <div v-else style="width: 20rem">
        <AtomDatePicker
          v-model="scheduleTime"
          :config="{ type: 'string', mask: 'YYYY/MM/DD' }"
          @update:model-value="onChooseDateSpecific(scheduleTime)"
        />
      </div>
    </template>
    <template v-else>
      <SynTag size="circle-small" custom-class="py-1 px-2">
        <span>{{ currentSchedule?.text }}</span>
      </SynTag>
    </template>
  </div>
</template>

<style lang="scss">
.datepicker_not_popover {
  .vc-popover-content-wrapper.is-interactive {
    visibility: hidden !important;
    display: none !important;
  }
}

.datepicker_view {
  .vc-title {
    font-size: 14px !important;
  }

  .vc-weeks .vc-weekday {
    font-size: 12px !important;
  }

  .vc-day {
    font-size: 12px !important;
  }
}
</style>
