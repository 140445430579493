<template>
  <syn-label></syn-label>
  <div class="grid grid-cols-3 gap-2 mt-2">
    <syn-input class="col-span-2"></syn-input>
    <syn-button></syn-button>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent } from 'vue';

export default defineComponent({
  components: {},
  props: {
    label: {
      type: String,
      required: true,
      default: 'Syn button',
    },
  },
  setup: () => {
    const count = ref(0);
    return { count };
  },
});
</script>
