<script setup lang="ts">
import { computed } from 'vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import { INotificationMainDataTaskUpdate } from '@/application/types/notification.types';
import { TASK_URGENCY } from '@/application/constants/task.const';

const props = defineProps<{
  beforeUpdate: INotificationMainDataTaskUpdate;
  afterUpdate: INotificationMainDataTaskUpdate;
}>();

const beforeUrgency = computed<1 | 2 | 3 | 4 | null>(() => {
  if (!props.beforeUpdate?.priority) return null;
  return getUrgencyValue(
    props.beforeUpdate?.priority,
    props.beforeUpdate?.important
  );
});
const afterUrgency = computed<1 | 2 | 3 | 4>(() => {
  return (
    getUrgencyValue(
      props.afterUpdate?.priority,
      props.afterUpdate?.important
    ) || 1
  );
});
</script>

<template>
  <div class="inline-flex space-x-1 text-overflow-hidden-line">
    <template v-if="beforeUrgency">
      <p>
        {{ $t('COMMON_LABEL_CHANGE_FROM') }}
      </p>
      <p class="text-overflow-hidden-line font-semibold italic">
        {{ $t(TASK_URGENCY[beforeUrgency]?.textCode) }}
      </p>
    </template>
    <p>
      {{ $t('COMMON_LABEL_CHANGE_TO') }}
    </p>
    <p
      class="text-overflow-hidden-line font-semibold italic uppercase underline"
    >
      <span class="flex-center space-x-1">
        <span>
          {{ $t(TASK_URGENCY[afterUrgency]?.textCode) }}
        </span>
        <PriorityTask :urgency="afterUrgency" size="tiny" is-hidden-label />
      </span>
    </p>
  </div>
</template>
