<script setup lang="ts">
import { computed, ref } from 'vue';
import NotificationBar from './NotificationBar.vue';
import NotificationBarTask from './NotificationBarTask.vue';
import NotificationBarWorkingStatus from './NotificationBarWorkingStatus.vue';
import NotificationBarGroup from '@/ui/components/notification/NotificationBarGroup.vue';
import NotificationOrganizationNew from '@/ui/components/notification/NotificationOrganizationNew.vue';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import alertStore from '@/store/alert';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import {
  NotificationPositionType,
  NotificationTypeEnum,
} from '@/ui/hooks/commonHook';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';

const _alertStore = alertStore();
const topCenterAlerts = computed(() =>
  _alertStore.dataAlerts.filter(
    (alert) => alert.position == NotificationPositionType.TopCenter
  )
);
const topCenterRightAlerts = computed(() =>
  _alertStore.dataAlerts.filter(
    (alert) =>
      alert.position == NotificationPositionType.TopCenterRight &&
      !alert?.notificationType
  )
);

const topCenterRightAlertsTask = computed(() => {
  return _alertStore.dataAlerts.filter(
    (alert) =>
      alert.position == NotificationPositionType.TopCenterRight &&
      alert?.notificationType &&
      alert?.notificationType == NotificationTypeEnum.task
  );
});

const topCenterRightAlertsWorkingStatus = computed(() => {
  return _alertStore.dataAlerts.filter(
    (alert) =>
      alert.position == NotificationPositionType.TopCenterRight &&
      alert?.notificationType &&
      alert?.notificationType == NotificationTypeEnum.working_status
  );
});
const topCenterRightAlertsGroup = computed(() => {
  return _alertStore.dataAlerts.filter(
    (alert) =>
      alert.position == NotificationPositionType.TopCenterRight &&
      alert?.notificationType &&
      (alert?.notificationType == NotificationTypeEnum.group_new ||
        alert?.notificationType == NotificationTypeEnum.group)
  );
});
const topCenterRightAlertsOrganizationNew = computed(() => {
  return _alertStore.dataAlerts.filter(
    (alert) =>
      alert.position == NotificationPositionType.TopCenterRight &&
      alert?.notificationType &&
      alert?.notificationType == NotificationTypeEnum.organization_new
  );
});

const rightBottomAlerts = computed(() =>
  _alertStore.dataAlerts.filter(
    (alert) =>
      alert.position == NotificationPositionType.RightBottom &&
      !alert?.notificationType
  )
);
const topRightAlerts = computed(() =>
  _alertStore.dataAlerts.filter(
    (alert) =>
      alert.position == NotificationPositionType.TopRight &&
      !alert?.notificationType
  )
);
const onOpenTaskDetail = (code) => {
  taskCodeGlobal.value = code;
  isShowTaskDetailGlobal.value = true;
};
const isNewChat = ref(false);

const currentTask = computed(() => {
  let notificationValue = topCenterRightAlertsTask.value[0]?.notificationValue;
  let currentTask = {
    ...notificationValue,
    name: notificationValue?.task_name,
    code: notificationValue?.task_code,
    taskId: notificationValue?.id || notificationValue?.task_id,
    id: notificationValue?.id || notificationValue?.task_id,
    urgency: getUrgencyValue(
      Number(notificationValue?.priority),
      /true/i.test(notificationValue?.important)
    ),
    taskSchedule: notificationValue?.taskSchedule,
  };
  return currentTask;
});

const onOpentAboutChat = () => {
  setTimeout(() => {
    isNewChat.value = true;
  });
};

const onNotificationClick = (notification, event) => {
  if (!notification?.onClick) return;

  notification?.onClick(notification, event);
};
</script>
<template>
  <div class="z-100 h-max fixed inset-x-0 top-4 flex-center flex-col">
    <div class="w-max">
      <NotificationBar
        v-for="notification in topCenterAlerts"
        :key="notification.id"
        v-vig-tooltip="notification.title"
        :notification="notification"
        :type="notification.type"
        :title="notification.title"
        :body="notification.body"
        :duration="notification.duration"
      />
    </div>
  </div>
  <div class="z-100 h-max w-80 fixed bottom-4 right-4">
    <NotificationBar
      v-for="notification in rightBottomAlerts"
      :key="notification.id"
      v-vig-tooltip="notification.title"
      :notification="notification"
      :type="notification.type"
      :title="notification.title"
      :body="notification.body"
      :duration="notification.duration"
      @click="onNotificationClick(notification, $event)"
    />
  </div>
  <div
    class="z-100 h-max w-96 fixed top-0 flex flex-col-reverse"
    style="right: 28rem"
  >
    <NotificationBar
      v-for="notification in topCenterRightAlerts"
      :key="notification.id"
      v-vig-tooltip="notification.title"
      :notification="notification"
      :type="notification.type"
      :title="notification.title"
      :body="notification.body"
      :duration="notification.duration"
      @click="onNotificationClick(notification, $event)"
    />

    <NotificationBarTask
      v-for="notification in topCenterRightAlertsTask"
      :key="notification.id"
      v-vig-tooltip="notification.title"
      :notification="notification"
      :notification-value="notification.notificationValue"
      :type="notification.type"
      :title="notification.title"
      :body="notification.body"
      :duration="notification.duration"
      @on-open-task-detail="onOpenTaskDetail"
      @open-about-chat="onOpentAboutChat"
    />

    <NotificationBarWorkingStatus
      v-for="notification in topCenterRightAlertsWorkingStatus"
      :key="notification.id"
      :notification="notification"
      :notification-value="notification.notificationValue"
      :type="notification.type"
      :title="notification.title"
      :body="notification.body"
      :duration="notification.duration"
      @on-open-task-detail="onOpenTaskDetail"
      @open-about-chat="onOpentAboutChat"
    />
    <NotificationBarGroup
      v-for="notification in topCenterRightAlertsGroup"
      :key="notification.id"
      :notification-id="notification.id"
      :group-id="notification?.metaData?.groupId"
      :group-name="notification?.metaData?.groupName"
      :group-avatar="notification?.metaData?.groupAvatar"
      :type="notification?.notificationType"
      :duration="notification?.duration"
    />
  </div>
  <div class="z-100 h-max w-80 fixed right-4 top-4 flex flex-col-reverse">
    <NotificationBar
      v-for="notification in topRightAlerts"
      :key="notification.id"
      v-vig-tooltip="notification.title"
      :notification="notification"
      :type="notification.type"
      :title="notification.title"
      :body="notification.body"
      :duration="notification.duration"
      @click="onNotificationClick(notification, $event)"
    />
  </div>
  <!-- <div
    class="z-60 h-max w-96 fixed top-0 flex flex-col-reverse"
    style="right: 28rem"
  >
    <NotificationBarTask
      v-for="notification in topCenterRightAlertsTask"
      :key="notification.id"
      v-vig-tooltip="notification.title"
      :notification="notification"
      :notification-value="notification.notificationValue"
      :type="notification.type"
      :body="notification.body"
      :duration="notification.duration"
      @on-open-task-detail="onOpenTaskDetail"
      @open-about-chat="onOpentAboutChat"
    />
  </div> -->

  <ModalNewChat
    v-if="isNewChat"
    :title="
      $t('COMMON_LABEL_DISCUSS_ABOUT_WITH') || 'Discuss about this task with'
    "
    :attached-task="currentTask"
    @on-close="isNewChat = false"
    @on-conversation-click="isNewChat = false"
  />

  <NotificationOrganizationNew
    v-for="notification in topCenterRightAlertsOrganizationNew"
    :key="notification.id"
    :notification-id="notification.id"
    :organization-id="notification?.metaData?.organizationId"
    :organization-name="notification?.metaData?.organizationName"
    :organization-avatar="notification?.metaData?.organizationAvatar"
    :organization-type="notification?.metaData?.organizationType"
    :organization-model="notification?.metaData?.organizationModel"
    :auto-redirect="notification?.metaData?.autoRedirect"
    :action-type="notification?.metaData?.actionType"
    :type="notification?.notificationType"
    :duration="notification?.duration"
  />
</template>
