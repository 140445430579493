<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue';
import initChatStore from '@/ui/components/layouts/logic/init-chat-store';
import chatStore from '@/store/chat';
import myProfileStore from '@/store/auth/my-profile';
import permissionStore from '@/store/permission';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import OrgDynamicInfoRepository from '@/application/repositories/org-dynamic-info/OrgDynamicInfoRepository';

const _myProfileStore = myProfileStore();
const _permissionStore = permissionStore();
const _chatStore = chatStore();
const _userStore = userStore();
const _groupStore = groupStore();

const { subscribeUserConversations, subscribeUserConversationInfo } =
  initChatStore();

let unsubGroups,
  unsubMembers,
  hadSubscGroups = false,
  hadSubscMembers = false;

onBeforeMount(async () => {
  await Promise.all([
    _myProfileStore.fetchCurrentLoginUser(),
    _permissionStore.getPermissionsByUser(),
    _chatStore.getUsersGroupsForChat(),
    _userStore.getShortInfosUser(),
    _groupStore.fetchMyGroups(),
    _groupStore.getGroupChatByUser(),
  ]);

  subscribeUserConversations();
  subscribeUserConversationInfo();

  _subscribeOrgDynamicData();
});

onUnmounted(() => {
  if (unsubGroups) unsubGroups();
  if (unsubMembers) unsubMembers();
});

const _subscribeOrgDynamicData = () => {
  const orgId = _myProfileStore.myProfile?.organizationId;
  if (!orgId) return;

  unsubGroups = OrgDynamicInfoRepository.subscribeGroupActivity(orgId, () => {
    if (hadSubscGroups) {
      _chatStore.getUsersGroupsForChat(null, true);
      _groupStore.fetchMyGroups();
      _groupStore.getGroupChatByUser();
    }

    hadSubscGroups = true;
  });

  unsubMembers = OrgDynamicInfoRepository.subscribeOrgInfoActivity(
    orgId,
    () => {
      if (hadSubscMembers) {
        _chatStore.getUsersGroupsForChat(null, true);
        _userStore.getShortInfosUser();
      }

      hadSubscMembers = true;
    }
  );
};
</script>

<template>
  <div class="w-screen h-screen bg-white">
    <router-view></router-view>
  </div>
</template>
