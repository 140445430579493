<script setup lang="ts">
import { ChatExternalAcceptance } from '@/domain/enums/chat-enums';
import { computed } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import $constants from '@/ui/plugins/constants';
import myProfileStore from '@/store/auth/my-profile';

const props = defineProps<{
  conversation: any;
  members?: any;
}>();

const myProfile = computed(() => myProfileStore().myProfile);

const specialLastMessage = computed(() => {
  if (
    props.conversation?.lastMessageObj?.removed ||
    props.conversation?.lastMessage === 'message_has_been_deleted'
  ) {
    return translate('CHAT_MSG_DELETED');
  }

  if (
    props.conversation?.lastMessageObj?.type ===
    $constants.MESSAGE.TYPE.MEMBER_JOINED_ORG
  ) {
    return `${props.conversation?.lastMessageObj?.member?.name} ${translate(
      'CHAT_MSG_USER_JOINED_ORG'
    )}`;
  }

  return null;
});

const lastMessageUser = computed(() => {
  const lastMessageBy = props.conversation?.lastMessageBy;

  if (!lastMessageBy) return null;

  if (lastMessageBy === `${myProfile.value?.id}`) {
    return translate('CHAT_USER_', {
      name: translate('COMMON_LABEL_ME') || 'Me',
    });
  }

  if (props.conversation?.isGroup) {
    const converMembers = props.members || props.conversation?.members;

    const member = converMembers ? converMembers[lastMessageBy] : null;

    if (member) {
      return translate('CHAT_USER_', {
        name: member?.firstName || member?.name?.split(' ').pop(),
      });
    }
  }

  return null;
});
</script>

<template>
  <span class="truncate">
    <span
      v-if="conversation?.externalAcceptance === ChatExternalAcceptance.PENDING"
      class="italic text-gray-400"
    >
      Wants to chat with you
    </span>

    <span v-else-if="specialLastMessage" class="italic text-gray-400">
      {{ specialLastMessage }}
    </span>

    <template v-else-if="conversation?.lastMessage">
      <span v-if="lastMessageUser" class="italic"> {{ lastMessageUser }}</span>
      <span v-twemoji="{ size: 'w-4 h-4' }" v-text="conversation?.lastMessage">
      </span>
    </template>
  </span>
</template>

<style scoped></style>
