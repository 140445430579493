<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import { openNotification, IBaseNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { NotificationSettingFunction } from '@/ui/common/constants/constant';
import { AlertType } from '@/ui/hooks/commonHook';
const props = withDefaults(
  defineProps<{
    scope?: string;
    refId?: number;
    folder?: any;
    name?: string;
    showModal?: boolean;
    position?: string;
  }>(),
  {
    refId: 0,
    position: 'list',
  }
);

const emit = defineEmits(['cancel', 'changeNotification']);
const actionTextKey = ref('NOTIFICATION_GED_BUTTON_UPDATE_SETTING');
const _notificationSettingStore = notificationSettingStore();

const showNotificationSetting = computed(() => props?.showModal);

const notificationSettingFunc = ref('');
const notificationSettingMsg = ref('');

const configured = ref(false);

const folderSettingEntity = ref({
  enable: false,
  folderId: props.folder?.id,
  events: {},
  applySubfolders: false,
  ignoreNotifyMe: false,
} as any);

const optionFunc = ref([
  {
    id: 'op4',
    key: 'NOTIFICATION_GED_EVENT_LABEL_OPTION_4',
    subDes: 'NOTIFICATION_GED_EVENT_SUB_OPTION_4',
    isSelected: folderSettingEntity.value?.events?.add || false,
    opt: NotificationSettingFunction.All,
    event: 'add',
  },
  {
    id: 'op3',
    key: 'NOTIFICATION_GED_EVENT_LABEL_OPTION_3',
    subDes: 'NOTIFICATION_GED_EVENT_SUB_OPTION_3',
    isSelected: folderSettingEntity.value?.events?.modify || false,
    opt: NotificationSettingFunction.All,
    event: 'modify',
  },
  {
    id: 'op2',
    key: 'NOTIFICATION_GED_EVENT_LABEL_OPTION_2',
    subDes: 'NOTIFICATION_GED_EVENT_SUB_OPTION_2',
    isSelected: folderSettingEntity.value?.events?.move || false,
    opt: NotificationSettingFunction.Task,
    event: 'move',
  },
  {
    id: 'op1',
    key: 'NOTIFICATION_GED_EVENT_LABEL_OPTION_1',
    subDes: 'NOTIFICATION_GED_EVENT_SUB_OPTION_1',
    isSelected: folderSettingEntity.value?.events?.delete || false,
    opt: NotificationSettingFunction.Messaging,
    event: 'delete',
  },
]);

const subDescriptionFunc = ref('NOTIFICATION_GED_EVENT_SUB_OPTION_4');

const changeOptionFunction = (key) => {
  optionFunc.value.forEach((op) => {
    if (op.id == key) {
      op.isSelected = !op.isSelected;
      subDescriptionFunc.value = op.subDes;
    }
  });

  if (
    !folderSettingEntity.value.events.add &&
    !folderSettingEntity.value.events.modify &&
    !folderSettingEntity.value.events.move &&
    !folderSettingEntity.value.events.delete
  ) {
    actionTextKey.value = 'NOTIFICATION_GED_BUTTON_TURN_OFF';
  } else {
    actionTextKey.value = 'NOTIFICATION_GED_BUTTON_UPDATE_SETTING';
  }
};

const isLoadingDelete = ref(false);

const onActionSettingNotification = async () => {
  isLoadingDelete.value = true;
  const settingNotificationResult =
    await _notificationSettingStore.setFolderSettingNotification(
      folderSettingEntity.value.folderId,
      folderSettingEntity.value.events,
      folderSettingEntity.value.applySubfolders,
      folderSettingEntity.value.ignoreNotifyMe
    );

  let notificationConfig: IBaseNotification = {
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('NOTIFICATION_GED_CHANGE_NOTIFICATION_SETTING_SUCCESS'),
  };
  if (settingNotificationResult) {
    emit('changeNotification', {
      type: 'SETTING',
      result: settingNotificationResult,
    });
  } else {
    notificationConfig = {
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body: translate('NOTIFICATION_GED_CHANGE_NOTIFICATION_SETTING_ERROR'),
    };
  }

  openNotification(notificationConfig);

  isLoadingDelete.value = false;
  onCancel();
  init();
};

const onCancel = () => {
  emit('cancel', false);
};

const init = async () => {
  configured.value = false;
  actionTextKey.value = 'NOTIFICATION_GED_BUTTON_UPDATE_SETTING';
};

const getNotificationSetting = async () => {
  const setting = await _notificationSettingStore.getFolderSettingNotification(
    props.folder?.id,
    props.folder?.folders.map((e) => e.id) || []
  );
  folderSettingEntity.value.enable = setting?.enable || false;
  folderSettingEntity.value.folderId = props.folder?.id;
  folderSettingEntity.value.events = setting?.events || {};
  folderSettingEntity.value.applySubfolders = setting.applySubfolders;
  folderSettingEntity.value.ignoreNotifyMe = setting.ignoreNotifyMe;
};

init();

watch(
  () => showNotificationSetting.value,
  () => {
    if (showNotificationSetting.value) getNotificationSetting();
  }
);
</script>
<template>
  <syn-question-modal
    z-index="z-50"
    class="z-50"
    :visible="showNotificationSetting"
    :show-confirm-btn="true"
    :show-delete-btn="false"
    :header-text="
      $t('NOTIFICATION_TITLE_HEADER_OFF_NOTIFICATION') + ' - ' + name
    "
    :confilm-label="$t(actionTextKey)"
    :is-loading="isLoadingDelete"
    @on-delete="
      (value) => {
        value ? onActionSettingNotification() : onCancel;
      }
    "
    @cancel="onCancel"
  >
    <template #content>
      <template v-if="configured">
        <div class="px-4 pt-4 text-sm italic text-current-700">
          <div
            class="
              bg-current-100
              border-l-4 border-current-500
              text-current-700
              p-4
            "
            role="alert"
          >
            <p class="font-bold">{{ $t(notificationSettingFunc) }}</p>
            <span>
              {{ notificationSettingMsg }}
            </span>
          </div>
        </div>

        <div></div>
      </template>
      <template v-else>
        <div class="px-4 pt-4 font-medium flex space-x-2 items-center">
          <SynIcon
            name="filter"
            custom-class="w-5 h-5 fill-current text-current-500"
          />
          <span>{{ $t('NOTIFICATION_SETTING_FOR_GED_EVENT_LABEL') }}</span>
        </div>
        <div class="flex space-y-3 py-4 ml-8 flex-wrap">
          <div
            v-for="opt in optionFunc"
            :key="opt.id"
            class="flex space-x-2 w-full items-center"
          >
            <input
              :id="'event_receive_' + opt.id"
              v-model="folderSettingEntity.events[`${opt.event}`]"
              type="checkbox"
              class="vig-checkbox"
              :disabled="!folderSettingEntity.enable"
              :class="
                folderSettingEntity.enable
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed'
              "
              @change="changeOptionFunction(opt.id)"
            />
            <label
              class="flex items-center space-x-2"
              :for="'event_receive_' + opt.id"
            >
              <span>{{ $t(opt.key) }}</span>
            </label>
          </div>
        </div>
        <div
          v-if="subDescriptionFunc"
          class="pb-6 ml-12 text-sm italic"
          :class="
            folderSettingEntity.enable ? 'text-current-700' : 'text-gray-500'
          "
        >
          <span class="font-bold">{{ $t('COMMON_LABEL_NOTE') + ': ' }}</span>
          <!-- <span>{{ $t(subDescriptionFunc) }}</span> -->
          <span>{{ $t('NOTIFICATION_GED_EVENT_SUB_OPTION') }}</span>
        </div>

        <div class="px-4 pt-4 font-medium flex space-x-2 items-center">
          <SynIcon
            name="settings"
            is-active
            custom-class="w-5 h-5 fill-current text-current-500"
          />
          <span>{{ $t('NOTIFICATION_GED_ADVANCED_SETTINGS') }}</span>
        </div>
        <div class="flex space-y-3 py-4 ml-8 flex-wrap">
          <div class="flex flex-col">
            <!-- <div class="px-4 pt-2 text-sm flex space-x-2 items-center">
              <AtomSwitch
                v-model="folderSettingEntity.applySubfolders"
                :disabled="!folderSettingEntity.enable"
              />
              <span>{{ $t('NOTIFICATION_GED_APPLY_FOR_ALL_SUB_FOLDER') }}</span>
            </div>
            <div class="px-4 pt-4 text-sm flex space-x-2 items-center">
              <AtomSwitch
                v-model="folderSettingEntity.ignoreNotifyMe"
                :disabled="!folderSettingEntity.enable"
              />
              <span>{{ $t('NOTIFICATION_GED_DO_NOT_NOTIFY_ME_NOTE') }}</span>
            </div> -->

            <div class="flex items-center space-x-2 w-full">
              <input
                :id="'applySubfolders'"
                v-model="folderSettingEntity.applySubfolders"
                type="checkbox"
                class="vig-checkbox"
                :disabled="!folderSettingEntity.enable"
                :class="
                  folderSettingEntity.enable
                    ? 'cursor-pointer'
                    : 'cursor-not-allowed'
                "
              />
              <label
                class="flex items-center space-x-2"
                :for="'applySubfolders'"
              >
                <span>{{
                  $t('NOTIFICATION_GED_APPLY_FOR_ALL_SUB_FOLDER')
                }}</span>
              </label>
            </div>
            <div class="flex items-center space-x-2 w-full">
              <input
                :id="'ignoreNotifyMe'"
                v-model="folderSettingEntity.ignoreNotifyMe"
                type="checkbox"
                class="vig-checkbox"
                :disabled="!folderSettingEntity.enable"
                :class="
                  folderSettingEntity.enable
                    ? 'cursor-pointer'
                    : 'cursor-not-allowed'
                "
              />
              <label
                class="flex items-center space-x-2"
                :for="'ignoreNotifyMe'"
              >
                <span>{{ $t('NOTIFICATION_GED_DO_NOT_NOTIFY_ME_NOTE') }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>
    </template>
  </syn-question-modal>
</template>
