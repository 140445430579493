<template>
  <div
    v-if="
      !currentStep ||
      currentStep == 'CONFIRM_SETUP' ||
      currentStep == 'CONFIRM_ATTACH'
    "
    class="
      h-full
      w-full
      bg-current-50 bg-opacity-40
      rounded
      pt-12
      mx-auto
      text-center
      flex
      items-center
      flex-col
    "
  >
    <div class="w-1/3 flex-center flex-col">
      <div>
        <syn-animation name="welcomeOrg" stype="width: 300px;"></syn-animation>
      </div>

      <span class="text-4xl text-current uppercase font-bold">
        {{ $t('COMMON_LABEL_WELCOME', { data: 'Fiine' }) }}
      </span>
      <span class="text-2xl pt-4 text-current font-semibol">
        Select function you want to use
      </span>
    </div>
  </div>
</template>
