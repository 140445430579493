import { ETaskListMenuTab } from '@/application/constants/task.const';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

export interface ICustomFilterPayload {
    isDefault: boolean;
    keyword?: string;
    urgency?: string;
    domainId: number | null;
    projectId: number | null;
    subProjectId: number | null;
    status?: string;
    groupIds?: string;
    domainIds?: string;
    creatorIds?: string;
    createdFromDate?: string;
    createdToDate?: string;
    updatedFromDate?: string;
    updatedToDate?: string;
    deadlineFromDate?: string;
    deadlineToDate?: string;
    isNoSchedule?: boolean;
    isRepeat?: boolean;
    isInWorkflow?: boolean;
    isApproval?: boolean;
    isSubTask?: boolean;
    assigneeIds?: string;
    pinColor?: string;
    approvalStatuses?: string;
}
export class FilterQuery implements ICustomFilterPayload {
    isDefault: boolean;
    listOwnerId: number | null;
    assignorId: number | null;
    approverId: number | null;
    groupId: number | null;
    domainId: number | null;
    projectId: number | null;
    subProjectId: number | null;
    menuTab: ETaskListMenuTab | null;
    // custom filter
    assigneeIds?: string;
    keyword?: string;
    urgency?: string;
    status?: string;
    groupIds?: string;
    domainIds?: string;
    creatorIds?: string;
    createdFromDate?: string;
    createdToDate?: string;
    updatedFromDate?: string;
    updatedToDate?: string;
    deadlineFromDate?: string;
    deadlineToDate?: string;
    isNoSchedule?: boolean;
    isRepeat?: boolean;
    isInWorkflow?: boolean;
    isApproval?: boolean;
    isSubTask?: boolean;
    pinColor?: string;
    approvalStatuses?: string;
    isIncludeFinished?: boolean;

    constructor(data: any) {
        this.isDefault = data.isDefault;
        this.listOwnerId = data.listOwnerId || null;
        this.assignorId = data.assignorId || null;
        this.approverId = data.approverId || null;
        this.groupId = data.groupId;
        this.domainId = data.domainId;
        this.projectId = data.projectId;
        this.subProjectId = data.subProjectId;
        this.status = data.status;
        this.urgency = data.urgency;
        this.groupIds = data.groupIds || null;
        this.domainIds = data.domainIds;
        this.creatorIds = data.creatorIds;
        this.assigneeIds = data.assigneeIds;
        this.menuTab = data.menuTab;
        this.createdFromDate = data.createdFromDate;
        this.createdToDate = data.createdToDate;
        this.updatedFromDate = data.updatedFromDate;
        this.updatedToDate = data.updatedToDate;
        this.keyword = data.keyword || '';
        this.deadlineFromDate = data.deadlineFromDate;
        this.deadlineToDate = data.deadlineToDate;
        this.isNoSchedule = data.isNoSchedule;
        this.isRepeat = data.isRepeat;
        this.isInWorkflow = data.isInWorkflow;
        this.isApproval = data.isApproval;
        this.isSubTask = data.isSubTask;
        this.pinColor = data.pinColor;
        this.approvalStatuses = data.approvalStatuses;
        this.isIncludeFinished = data.isIncludeFinished;
    }
}
export class FilterLocal extends FilterQuery {
    currentUserId: number | null;
    assignorId: number | null;
    domainIds: string;
    projectIds: string;
    subprojectIds: string;
    workSituation: string;
    isShowGroupTask: boolean;

    constructor(data: any) {
        super(data);
        this.currentUserId = data.currentUserId || getCurrentUserId();
        this.assignorId = data.assignorId || null;
        this.domainIds = data.domainIds || '';
        this.projectIds = data.projectIds || '';
        this.subprojectIds = data.subprojectIds || '';
        this.workSituation = data.workSituation || '';
        this.isShowGroupTask = data.isShowGroupTask || true;
    }
}

export class FilterByDomain {
    domainId: number;
    projectId?: number;
    subProjectId?: number;

    constructor(data: any) {
        this.domainId = data?.domainId;
        this.projectId = data?.projectId;
        this.subProjectId = data?.subProjectId;
    }
}
