import { computed } from 'vue';
import $constants from '@/ui/plugins/constants';
import settingStore from '@/store/setting';
import permissionStore from '@/store/permission';
import { EOrganizationModel } from '@/application/types/organization/organization.type';

export default function useResourceFilterCategories() {
    const chatEnabled = computed(() => settingStore().chatEnabled);
    const isOrgPersonal = computed(
        () =>
            permissionStore().organizationModel === EOrganizationModel.PERSONAL
    );

    const categories = computed(() => {
        const results = [
            {
                id: $constants.FILE.CATEGORY.STORAGE,
                name: 'GED_FILTER_STORAGE',
                title: 'GED_FILTER_STORAGE_TITLE',
            },
            {
                id: $constants.FILE.CATEGORY.TASK,
                name: 'GED_FILTER_TASK',
                title: 'GED_FILTER_TASK_TITLE',
            },
        ];

        if (chatEnabled.value && !isOrgPersonal.value) {
            results.push({
                id: $constants.FILE.CATEGORY.CHAT,
                name: 'GED_FILTER_CHAT',
                title: 'GED_FILTER_CHAT_TITLE',
            });
        }

        return results;
    });

    return {
        categories,
    };
}
