<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  AudioVideoCallStatusEnum,
  DurationList,
  ConferenceHosterType,
  ConferenceTypeEnum,
} from '@/domain/enums/VideoCallEnum';
import dashboardStore from '@/store/dashboard';
import myProfileStore from '@/store/auth/my-profile';
import { UserStatusEnum } from '@/domain/enums/UserStatusEnum';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { ConferenceClass } from '@/application/entities/VideoCallClass';
import settingStore from '@/store/setting';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import userStore from '@/store/user';

const props = withDefaults(
  defineProps<{
    receiverIds: number[];
    customClass?: string;
  }>(),
  {
    customClass: 'rounded-full fill-white',
  }
);

defineEmits(['onLeave']);

const _dashboardStore = dashboardStore();

const _settingStore = settingStore();

const myProfile = computed(() => myProfileStore().myProfile);
const hostUserId = computed(() => myProfile.value?.id);
const hostUserName = computed(() => myProfile.value?.fullName);
const hostUserAvatar = computed(() => myProfile.value?.avatarUrl);
const allUserByIds = computed(() => userStore().allUserByIds);
const allActiveUsers = computed(() => userStore().allActiveUsers);

const durationTime = ref(15);

const myUserId = computed(() => myProfileStore().myProfile?.id);
const groupMembersInfo = computed(() => {
  return props.receiverIds
    ?.filter(
      (userId) =>
        parseInt(`${userId}`) !== myUserId.value &&
        allActiveUsers.value?.some((user) => user?.id == userId)
    )
    ?.map((userId) => allUserByIds.value[userId]);
});

const receiverInfo = computed(() => {
  if (groupMembersInfo.value?.length == 1) return groupMembersInfo.value[0];
  return {};
});
const inOtherCall = computed<boolean>(() => {
  if (props.receiverIds?.length == 1)
    return dashboardStore()
      .getUserStatus(props.receiverIds[0])
      ?.includes(UserStatusEnum.OnCall);

  return false;
});
const myCallStatus = computed(() =>
  _dashboardStore.getUserStatus(getCurrentUserId())
);
const imOnOtherCall = computed(() =>
  myCallStatus.value?.includes(UserStatusEnum.OnCall)
);
const videoCallEnabled = computed(() => _settingStore.videoCallEnabled);
const isLoading = ref(false);

const onCallTo = async (type) => {
  if (props.receiverIds?.length > 15) {
    // show warning
    settingGlobalModal({
      type: 'notification',
      title: translate('CALL_CAN_NOT_MAKE_A_GROUP_CALL'),
      content: translate('CALL_CAN_NOT_MAKE_A_GROUP_CALL_DESCRIPTION'),
      confirmable: true,
      closeable: false,
    });
    ask();
    return;
  }
  callType.value = type;
  isLoading.value = true;

  try {
    const newConference = new ConferenceClass(
      type,
      {
        id: hostUserId.value,
        name: hostUserName.value,
        avatar: hostUserAvatar.value,
      } as ConferenceHosterType,
      AudioVideoCallStatusEnum.Started,
      durationTime.value,
      groupMembersInfo.value
    );

    await newConference.start();
    dropdownRef.value?.onForceClose();
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const callType = ref('');

const dropdownRef = ref(null as any);
</script>

<template>
  <vig-dropdown
    v-if="videoCallEnabled"
    ref="dropdownRef"
    placement="bottom-end"
    hide-on-click="toggle"
  >
    <template #dropdown-toggle>
      <button
        v-vig-tooltip="$t('CALL_LABEL_VIDEO_CALL')"
        class="h-8 w-8 flex-center rounded-full"
        :class="customClass"
      >
        <SynIcon name="video-audio" custom-class="w-5 h-5" is-active />
      </button>
    </template>
    <template #dropdown-menu>
      <div class="flex-center p-2">
        <div class="flex-center space-x-1">
          <SynInputNumber v-model="durationTime" :min="1" :max="60">
            <vig-dropdown
              :append-to-body="false"
              z-index="50"
              placement="top"
              @click.stop="() => {}"
            >
              <template #dropdown-toggle>
                <div class="flex-center space-x-1 cursor-pointer px-2">
                  <span class="h-8 flex-center">
                    {{ durationTime }}
                  </span>
                  <span>min</span>
                  <SynIcon
                    name="arrow-down"
                    class="fill-gray-500 text-gray-500"
                    custom-class="w-2 h-2"
                  />
                </div>
              </template>
              <template #dropdown-menu>
                <div
                  class="
                    list-ul
                    text-xs
                    w-full
                    rounded-md
                    overflow-x-hidden overflow-auto
                    small-scrollbar
                  "
                >
                  <div
                    v-for="(item, index) in DurationList"
                    :key="index"
                    class="
                      list-li
                      dropdown-item
                      px-4
                      py-2
                      text-xs text-black
                      hover:bg-gray-100 hover:text-current-700
                      cursor-pointer
                      break-words
                    "
                    @click.stop="durationTime = item"
                  >
                    <span>
                      {{ item }}
                    </span>
                    <span>min</span>
                  </div>
                </div>
              </template>
            </vig-dropdown>
          </SynInputNumber>
          <SynButton
            v-permission-function="{
              functionCode: ALL_FUNCTIONS.CHAT.AUDIO_CALL,
              onContinue: () => {
                onCallTo(ConferenceTypeEnum.Audio);
              },
            }"
            :title="
              inOtherCall
                ? $t('CALL_MEMBER_IN_OTHER_CALL', {
                    name: receiverInfo?.name || '',
                  })
                : $t('CALL_LABEL_AUDIO_CALL')
            "
            :disabled="isLoading || inOtherCall || imOnOtherCall"
            :is-loading="isLoading && callType == ConferenceTypeEnum.Audio"
            @click="onCallTo(ConferenceTypeEnum.Audio)"
          >
            <SynIcon name="phone" />
          </SynButton>

          <SynButton
            v-permission-function="{
              functionCode: ALL_FUNCTIONS.CHAT.AUDIO_CALL,
              onContinue: () => {
                onCallTo(ConferenceTypeEnum.Video);
              },
            }"
            :title="
              inOtherCall
                ? $t('CALL_MEMBER_IN_OTHER_CALL', {
                    name: receiverInfo?.name || '',
                  })
                : $t('CALL_LABEL_AUDIO_CALL')
            "
            :is-loading="isLoading && callType == ConferenceTypeEnum.Video"
            :disabled="isLoading || inOtherCall || imOnOtherCall"
            @click="onCallTo(ConferenceTypeEnum.Video)"
          >
            <SynIcon name="video" />
          </SynButton>
        </div>
      </div>
    </template>
  </vig-dropdown>
</template>

<style scoped lang="scss">
.video-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
}
</style>
