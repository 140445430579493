<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { keySearch } from '@/ui/modules/task/table/task-list-state';
import ListSelectMultiGroup from './ListSelectMultiGroup.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import SelectMultiDomain from '@/ui/modules/task/filter/SelectMultiDomain.vue';
import { taskStates, TaskUrgency } from '@/ui/hooks/taskHook';
import SearchTaskOwners from '@/ui/components/search/SearchTaskOwners.vue';
import { ICustomFilterPayload } from '@/domain/entities/task/FilterTask';

const props = withDefaults(
  defineProps<{
    filterKey:
      | 'TASK_LIFE'
      | 'URGENCY'
      | 'TASK_TYPE'
      | 'CREATE_DATE'
      | 'UPDATED_DATE'
      | 'DEADLINE'
      | 'GROUPS'
      | 'DOMAIN'
      | 'CREATOR'
      | 'ASSIGNEE';
    editingKey?: string;
    userId?: number;
    groupId?: number;
    domainId?: number;
    filterDefault?: any;
  }>(),
  {}
);
const emit = defineEmits<{
  (e: 'updateFilterData', key, data): void;
}>();

const urgencyList = ref<any[]>([]);
const statusList = ref<any[]>([]);
const selectedGroupIds = ref<string[]>([]);
const selectedDomainIds = ref<string[]>([]);
const selectedCreatorIds = ref<string[]>([]);
const selectedAssigneeIds = ref<string[]>([]);
const filterDateObj = ref<{
  createdFromDate?: Date;
  createdToDate?: Date;
  updatedFromDate?: Date;
  updatedToDate?: Date;
  deadlineFromDate?: Date;
  deadlineToDate?: Date;
}>({
  createdFromDate: undefined,
  createdToDate: undefined,
  updatedFromDate: undefined,
  updatedToDate: undefined,
  deadlineFromDate: undefined,
  deadlineToDate: undefined,
});
const filterDeadlineType = ref<'NOTHING' | 'PERIOD' | undefined>(undefined);

const taskTypeData = ref<{
  isRepeat: boolean;
  isInWorkflow: boolean;
  isApproval: boolean;
  isSubTask: boolean;
}>({
  isRepeat: false,
  isInWorkflow: false,
  isApproval: false,
  isSubTask: false,
});

const initComponent = () => {
  // prepare data for status
  const filterDataByStatus = props.filterDefault?.status?.split(',');
  statusList.value = Object.values(taskStates)
    .sort((a, b) => {
      return a.index - b.index;
    })
    .map((state) => {
      return {
        ...state,
        isChecked: filterDataByStatus?.some(
          (key) => key == state?.key?.toString()
        ),
      };
    });

  // prepare data for urgency
  const filterDataByUrgency = props.filterDefault?.urgency?.split(',');
  urgencyList.value = Object.values(TaskUrgency).map((urgency) => {
    return {
      ...urgency,
      isChecked: filterDataByUrgency?.some(
        (key) => key == urgency?.key?.toString()
      ),
    };
  });

  // prepare data filter by date
  filterDateObj.value = {
    createdFromDate: props.filterDefault?.createdFromDate,
    createdToDate: props.filterDefault?.createdToDate,
    updatedFromDate: props.filterDefault?.updatedFromDate,
    updatedToDate: props.filterDefault?.updatedToDate,
    deadlineFromDate: props.filterDefault?.deadlineFromDate,
    deadlineToDate: props.filterDefault?.deadlineToDate,
  };

  // prepare data filter by group
  selectedGroupIds.value = (
    props.filterDefault?.groupIds?.split(',') || []
  ).filter((o) => o);

  // prepare data filter by group
  selectedDomainIds.value = (
    props.filterDefault?.domainIds?.split(',') || []
  ).filter((o) => o);

  // prepare data filter by creator
  selectedCreatorIds.value = (
    props.filterDefault?.creatorIds?.split(',') || []
  ).filter((o) => o);

  // prepare data filter by assignee
  selectedAssigneeIds.value = (
    props.filterDefault?.assigneeIds?.split(',') || []
  ).filter((o) => o);

  // by schedule
  filterDeadlineType.value = props.filterDefault?.isNoSchedule
    ? 'NOTHING'
    : props.filterDefault?.deadlineFromDate ||
      props.filterDefault?.deadlineToDate
    ? 'PERIOD'
    : undefined;

  taskTypeData.value.isRepeat = !!props.filterDefault?.isRepeat;
  taskTypeData.value.isInWorkflow = !!props.filterDefault?.isInWorkflow;
  taskTypeData.value.isApproval = !!props.filterDefault?.isApproval;
  taskTypeData.value.isSubTask = !!props.filterDefault?.isSubTask;
};

onMounted(() => {
  initComponent();
});

const filterByDateOptions = computed(() => {
  return [
    { key: 'PERIOD', labelCode: 'COMMON_LABEL_PERIOD' },
    { key: 'NOTHING', labelCode: 'TASK_DETAIL_LABEL_NO_SCHEDULE' },
  ];
});

const onUpdateDeadlineType = (type) => {
  filterDeadlineType.value =
    filterDeadlineType.value == type ? undefined : type;
  updatePropData();
};

const onUpdateFilterTaskRepeat = () => {
  taskTypeData.value.isRepeat = !taskTypeData.value?.isRepeat;
  updatePropData();
};

const onUpdateFilterTaskWorkFlow = () => {
  taskTypeData.value.isInWorkflow = !taskTypeData.value.isInWorkflow;
  updatePropData();
};

const clearData = () => {
  keySearch.value = '';
  urgencyList.value = urgencyList.value.map((o) => {
    o.isChecked = false;
    return o;
  });
  statusList.value = statusList.value.map((o) => {
    o.isChecked = false;
    return o;
  });
  selectedGroupIds.value = [];
  selectedDomainIds.value = [];
  selectedCreatorIds.value = [];
  selectedAssigneeIds.value = [];

  filterDateObj.value = {
    createdFromDate: undefined,
    createdToDate: undefined,
    updatedFromDate: undefined,
    updatedToDate: undefined,
    deadlineFromDate: undefined,
    deadlineToDate: undefined,
  };

  taskTypeData.value = {
    isRepeat: false,
    isInWorkflow: false,
    isApproval: false,
    isSubTask: false,
  };

  updatePropData();
};

const addCreator = (dataItem: any) => {
  const isExisted = selectedCreatorIds.value?.some(
    (id) => id.toString() == dataItem?.id?.toString()
  );
  if (isExisted)
    selectedCreatorIds.value = selectedCreatorIds.value?.filter(
      (id) => id.toString() !== dataItem?.id?.toString()
    );
  else selectedCreatorIds.value.push(dataItem?.id);
  updatePropData();
};

const removeCreator = (data) => {
  selectedCreatorIds.value = selectedCreatorIds.value?.filter(
    (id) => id.toString() !== data?.id?.toString()
  );
  updatePropData();
};

const addAssignee = (dataItem: any) => {
  const isExisted = selectedAssigneeIds.value?.some(
    (id) => id.toString() == dataItem?.id?.toString()
  );
  if (isExisted)
    selectedAssigneeIds.value = selectedAssigneeIds.value?.filter(
      (id) => id.toString() !== dataItem?.id?.toString()
    );
  else selectedAssigneeIds.value.push(dataItem?.id);

  updatePropData();
};

const removeAssignee = (data) => {
  selectedAssigneeIds.value = selectedAssigneeIds.value?.filter(
    (id) => id.toString() !== data?.id?.toString()
  );
  updatePropData();
};

const onChange = (key) => {
  switch (key) {
    case 'TASK_LIFE':
      // filterTaskByKey.value.taskLife.isAny =
      //   statusList.value?.filter((o) => o.isChecked)?.length == 0;

      break;

    default:
      break;
  }

  updatePropData();
};
const onUpdateTaskType = () => {
  taskTypeData.value.isApproval = !taskTypeData.value?.isApproval;

  updatePropData();
};
const onUpdateIsSubTask = () => {
  taskTypeData.value.isSubTask = !taskTypeData.value?.isSubTask;

  updatePropData();
};

const updatePropData = () => {
  const customFilterPayload: ICustomFilterPayload = {
    ...filterDateObj.value,
    isDefault: false,
    keyword: keySearch.value,
    urgency: urgencyList.value
      .filter((o) => o.isChecked)
      .map((o) => o.key)
      .toString(),
    status: statusList.value
      .filter((o) => o.isChecked)
      .map((o) => o.key)
      .toString(),
    groupIds: selectedGroupIds.value.toString(),
    domainIds: selectedDomainIds.value.toString(),
    creatorIds: selectedCreatorIds.value.toString(),
    assigneeIds: selectedAssigneeIds.value.toString(),
    isNoSchedule: filterDeadlineType.value == 'NOTHING',
    deadlineFromDate:
      filterDeadlineType.value == 'PERIOD'
        ? filterDateObj.value?.deadlineFromDate
        : undefined,
    deadlineToDate:
      filterDeadlineType.value == 'PERIOD'
        ? filterDateObj.value?.deadlineToDate
        : undefined,
    isRepeat: taskTypeData.value?.isRepeat,
    isInWorkflow: taskTypeData.value.isInWorkflow,
    isApproval: taskTypeData.value.isApproval,
    isSubTask: taskTypeData.value.isSubTask,
  };

  emit('updateFilterData', props.filterKey, customFilterPayload);
};

defineExpose({
  clearData,
});
</script>
<template>
  <template v-if="filterKey == 'TASK_LIFE'">
    <div v-if="urgencyList?.length > 0" class="flex items-center flex-wrap">
      <SynCheckboxTag
        v-for="item in editingKey == 'TASK_LIFE'
          ? statusList
          : statusList?.filter((o) => o.isChecked)"
        :key="item"
        v-model="item.isChecked"
        :disabled="item?.disabled"
        class="p-1"
        @update:model-value="onChange('TASK_LIFE')"
      >
        <SynTag
          size="circle-small"
          :disabled="item?.disabled"
          :custom-class="[
            item?.disabled ? 'border-gray-100' : '',
            item.isChecked
              ? 'bg-current'
              : 'hover:bg-current-200 border-current-200',
            !item.isChecked && item?.disabled
              ? 'bg-gray-100 text-gray-400 '
              : '',
            'border px-2 py-1 text-sm',
          ]"
        >
          <div class="flex-center space-x-2">
            <!-- <span
              class="p-1 rounded-full"
              :class="`bg-${item?.colorKey} ${
                item?.disabled ? 'opacity-50' : ''
              }`"
            ></span> -->
            <span>
              {{ $t(item?.nameCode) }}
            </span>
            <SynIcon
              v-if="editingKey !== 'TASK_LIFE' && item.isChecked"
              name="CloseLine"
              custom-class="w-2.5 h-2.5 fill-white"
            />
          </div>
        </SynTag>
      </SynCheckboxTag>
    </div>
  </template>
  <template v-if="filterKey == 'URGENCY'">
    <div v-if="urgencyList?.length > 0" class="flex flex-wrap">
      <SynCheckboxTag
        v-for="item in editingKey == 'URGENCY'
          ? urgencyList
          : urgencyList?.filter((o) => o.isChecked)"
        :key="item"
        v-model="item.isChecked"
        class="p-1"
        @update:model-value="updatePropData"
      >
        <SynTag
          size="circle-small"
          :custom-class="[
            'p-1 border border-current-50 pr-2 flex-center gap-1',
            item?.disabled ? 'border-gray-100' : '',
            item.isChecked
              ? 'bg-current'
              : 'hover:bg-current-200 border-current-200',
          ]"
          rounded-full
        >
          <PriorityTask :urgency="item.key" size="mini" />
          <SynIcon
            v-if="editingKey !== 'URGENCY' && item.isChecked"
            name="CloseLine"
            custom-class="w-2.5 h-2.5 fill-white"
          />
        </SynTag>
      </SynCheckboxTag>
    </div>
  </template>
  <template v-if="filterKey == 'TASK_TYPE'">
    <!--TASK TYPE-->
    <div
      v-if="
        editingKey == 'TASK_TYPE' ||
        taskTypeData?.isRepeat ||
        taskTypeData?.isInWorkflow ||
        taskTypeData?.isApproval ||
        taskTypeData?.isSubTask
      "
      class="flex items-center space-x-1"
    >
      <SynCheckboxTag
        v-if="editingKey == 'TASK_TYPE' || taskTypeData?.isRepeat"
        :model-value="taskTypeData?.isRepeat"
        @update:model-value="onUpdateFilterTaskRepeat"
      >
        <SynTag
          size="circle-small"
          custom-class="flex-center gap-1 py-1 px-2 border border-current-100"
          :class="!taskTypeData?.isRepeat ? 'hover:bg-current-50' : ''"
        >
          <span>{{ $t('TASK_LABEL_TASK_TYPE_RECURRENCE') }}</span>
          <SynIcon
            v-if="editingKey !== 'TASK_TYPE' && taskTypeData?.isRepeat"
            name="CloseLine"
            custom-class="w-2.5 h-2.5 fill-white"
          />
        </SynTag>
      </SynCheckboxTag>
      <SynCheckboxTag
        v-if="editingKey == 'TASK_TYPE' || taskTypeData?.isInWorkflow"
        :model-value="taskTypeData?.isInWorkflow"
        @update:model-value="onUpdateFilterTaskWorkFlow"
      >
        <SynTag
          size="circle-small"
          custom-class="flex-center gap-1 py-1 px-2 border border-current-100"
          :class="!taskTypeData?.isInWorkflow ? 'hover:bg-current-50' : ''"
        >
          <span>{{ $t('TASK_ACTION_WORK_FLOW') }}</span>
          <SynIcon
            v-if="editingKey !== 'TASK_TYPE' && taskTypeData?.isInWorkflow"
            name="CloseLine"
            custom-class="w-2.5 h-2.5 fill-white"
          />
        </SynTag>
      </SynCheckboxTag>
      <SynCheckboxTag
        v-if="editingKey == 'TASK_TYPE' || taskTypeData?.isApproval"
        :model-value="taskTypeData?.isApproval"
        @update:model-value="onUpdateTaskType"
      >
        <SynTag
          size="circle-small"
          custom-class="flex-center gap-1 py-1 px-2 border border-current-100"
          :class="!taskTypeData?.isApproval ? 'hover:bg-current-50' : ''"
        >
          <span>{{ $t('COMMON_LABEL_APPROVAL') }}</span>
          <SynIcon
            v-if="editingKey !== 'TASK_TYPE' && taskTypeData?.isApproval"
            name="CloseLine"
            custom-class="w-2.5 h-2.5 fill-white"
          />
        </SynTag>
      </SynCheckboxTag>
      <SynCheckboxTag
        v-if="editingKey == 'TASK_TYPE' || taskTypeData?.isSubTask"
        :model-value="taskTypeData?.isSubTask"
        @update:model-value="onUpdateIsSubTask"
      >
        <SynTag
          size="circle-small"
          custom-class="flex-center gap-1 py-1 px-2 border border-current-100"
          :class="!taskTypeData?.isSubTask ? 'hover:bg-current-50' : ''"
        >
          <span>{{ $t('COMMON_LABEL_SUB_TASK') }}</span>
          <SynIcon
            v-if="editingKey !== 'TASK_TYPE' && taskTypeData?.isSubTask"
            name="CloseLine"
            custom-class="w-2.5 h-2.5 fill-white"
          />
        </SynTag>
      </SynCheckboxTag>
    </div>
  </template>

  <!-- CREATE DATE -->
  <template v-if="filterKey == 'CREATE_DATE'">
    <div
      v-if="
        editingKey == 'CREATE_DATE' ||
        filterDateObj?.createdFromDate ||
        filterDateObj?.createdToDate
      "
      class="flex space-x-2"
    >
      <AtomDatePicker
        v-model="filterDateObj.createdFromDate"
        :placeholder="$t('COMMON_LABEL_FROM_DATE') || 'From date'"
        :config="{ type: 'number' }"
        :max-date="filterDateObj.createdToDate"
        @update:model-value="updatePropData"
      />
      <AtomDatePicker
        v-model="filterDateObj.createdToDate"
        :placeholder="$t('COMMON_LABEL_TO_DATE') || 'To date'"
        :config="{ type: 'number' }"
        :min-date="filterDateObj.createdFromDate"
        @update:model-value="updatePropData"
      />
    </div>
  </template>

  <!-- UPDATED DATE -->
  <template v-if="filterKey == 'UPDATED_DATE'">
    <div
      v-if="
        editingKey == 'UPDATED_DATE' ||
        filterDateObj?.updatedFromDate ||
        filterDateObj?.updatedToDate
      "
      class="flex space-x-2"
    >
      <AtomDatePicker
        v-model="filterDateObj.updatedFromDate"
        :placeholder="$t('COMMON_LABEL_FROM_DATE') || 'From date'"
        :config="{ type: 'number' }"
        :max-date="filterDateObj.updatedToDate"
        @update:model-value="updatePropData"
      />
      <AtomDatePicker
        v-model="filterDateObj.updatedToDate"
        :placeholder="$t('COMMON_LABEL_TO_DATE') || 'To date'"
        :config="{ type: 'number' }"
        :min-date="filterDateObj.updatedFromDate"
        @update:model-value="updatePropData"
      />
    </div>
  </template>
  <!-- DEADLINE -->
  <template v-if="filterKey == 'DEADLINE'">
    <div
      v-if="editingKey == 'DEADLINE' || filterDeadlineType"
      class="flex flex-col gap-1"
    >
      <div class="flex items-center space-x-1">
        <SynRadioTag
          v-for="(item, index) in filterByDateOptions"
          :key="index"
          :model-value="filterDeadlineType"
          :value="item.key"
          :label="$t(item.labelCode)"
          name="task-create-form_schedule"
          radio-name="task-create-form_priority"
          @update:model-value="onUpdateDeadlineType"
        >
          <SynTag
            size="circle-small"
            custom-class="py-1 px-2 border border-current-100"
          >
            <span>{{ $t(item.labelCode) }}</span>
          </SynTag>
        </SynRadioTag>
      </div>
      <div v-if="filterDeadlineType == 'PERIOD'" class="flex space-x-2">
        <AtomDatePicker
          v-model="filterDateObj.deadlineFromDate"
          :placeholder="$t('COMMON_LABEL_FROM_DATE') || 'From date'"
          :config="{ type: 'number' }"
          :max-date="filterDateObj.deadlineToDate"
          @update:model-value="updatePropData"
        />
        <AtomDatePicker
          v-model="filterDateObj.deadlineToDate"
          :placeholder="$t('COMMON_LABEL_TO_DATE') || 'To date'"
          :config="{ type: 'number' }"
          :min-date="filterDateObj.deadlineFromDate"
          @update:model-value="updatePropData"
        />
      </div>
    </div>
  </template>

  <!-- GROUPS -->
  <template v-if="filterKey == 'GROUPS'">
    <ListSelectMultiGroup
      v-model="selectedGroupIds"
      :list-task-owner-id="userId"
      :domain-id="domainId"
      :is-show-result-only="editingKey !== 'GROUPS'"
      @update:model-value="updatePropData"
    />
  </template>

  <!-- DOMAIN -->
  <template v-if="filterKey == 'DOMAIN'">
    <SelectMultiDomain
      v-model="selectedDomainIds"
      :list-task-owner-id="userId"
      :group-id="groupId"
      :is-show-result-only="editingKey !== 'DOMAIN'"
      @update:model-value="updatePropData"
    />
  </template>

  <!-- CREATOR -->
  <template v-if="filterKey == 'CREATOR'">
    <SearchTaskOwners
      :owner-type-list="['USER']"
      is-hidden-action-item
      is-select-multiple
      do-not-close-dropdown
      is-filter-when-selected
      :is-show-result-only="editingKey !== 'CREATOR'"
      :selected-ids="selectedCreatorIds?.map((id) => `USER_${id}`)"
      @on-choose-owner="addCreator"
      @on-remove-owner="removeCreator"
    />
  </template>

  <!-- ASSIGNEE -->
  <template v-if="filterKey == 'ASSIGNEE'">
    <SearchTaskOwners
      :owner-type-list="['USER']"
      is-hidden-action-item
      is-select-multiple
      do-not-close-dropdown
      is-filter-when-selected
      :selected-ids="selectedAssigneeIds?.map((id) => `USER_${id}`)"
      :is-show-result-only="editingKey !== 'ASSIGNEE'"
      @on-choose-owner="addAssignee"
      @on-remove-owner="removeAssignee"
    />
  </template>
</template>
