<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m422.257812 0h-331.445312c-50.074219 0-90.8125 40.644531-90.8125 90.609375v338.863281c0 12.003906 7.28125 22.964844 18.558594 27.921875 3.984375 1.753907 8.207031 2.605469 12.394531 2.605469 7.503906 0 14.898437-2.738281 20.628906-7.949219 41.546875-37.589843 95.320313-58.292969 151.421875-58.292969h.015625l219.296875.015626c49.449219 0 89.683594-40.320313 89.683594-89.878907v-214.355469c0-49.371093-40.257812-89.539062-89.742188-89.539062zm-273.394531 261.519531c0 8.28125-6.714843 14.996094-15 14.996094-8.28125 0-15-6.714844-15-14.996094v-43.507812h-27.015625v43.507812c0 8.28125-6.71875 14.996094-15 14.996094-8.285156 0-15-6.714844-15-14.996094v-117.015625c0-8.285156 6.714844-15 15-15 8.28125 0 15 6.71875 15 15v43.507813h27.015625v-43.507813c0-8.285156 6.71875-15 15-15 8.285157 0 15 6.71875 15 15zm90.207031-73.507812c8.285157 0 15 6.714843 15 15 0 8.285156-6.714843 15-15 15h-42.015624v25.421875c0 1.703125 1.382812 3.085937 3.082031 3.085937h38.933593c8.285157 0 15 6.714844 15 14.996094 0 8.285156-6.714843 15-15 15h-38.933593c-18.242188 0-33.082031-14.839844-33.082031-33.082031v-80.847656c0-18.242188 14.839843-33.082032 33.082031-33.082032h38.933593c8.285157 0 15 6.714844 15 14.996094 0 8.285156-6.714843 15-15 15h-38.933593c-1.699219 0-3.082031 1.382812-3.082031 3.082031v25.425781h42.015624zm95.210938 88.503906h-27.132812c-19.238282 0-34.886719-15.648437-34.886719-34.882813v-97.128906c0-8.285156 6.714843-15 15-15 8.285156 0 15 6.714844 15 15v97.128906c0 2.691407 2.191406 4.886719 4.886719 4.886719h27.132812c8.28125 0 15 6.714844 15 14.996094 0 8.285156-6.71875 15-15 15zm71.695312-59.996094h-13.507812v45c0 8.28125-6.714844 14.996094-15 14.996094s-15-6.714844-15-14.996094v-110.441406c0-11.898437 9.679688-21.574219 21.574219-21.574219h21.933593c23.992188 0 43.511719 19.519532 43.511719 43.507813 0 23.992187-19.519531 43.507812-43.511719 43.507812zm0 0"
      ></path>
      <path
        d="m405.976562 159.503906h-13.507812v27.015625h13.507812c7.449219 0 13.511719-6.058593 13.511719-13.507812s-6.0625-13.507813-13.511719-13.507813zm0 0"
      ></path>
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m422.257812 0h-331.445312c-50.074219 0-90.8125 40.648438-90.8125 90.609375v338.878906c0 12.003907 7.28125 22.964844 18.558594 27.921875 3.984375 1.753906 8.207031 2.609375 12.394531 2.605469 7.503906 0 14.898437-2.734375 20.628906-7.949219 41.546875-37.59375 95.320313-58.292968 151.421875-58.292968h.015625l219.296875.015624c49.449219 0 89.683594-40.320312 89.683594-89.882812v-214.363281c0-49.375-40.257812-89.542969-89.742188-89.542969zm59.742188 303.90625c0 33.019531-26.773438 59.882812-59.683594 59.882812l-219.296875-.015624h-.015625c-63.550781 0-124.480468 23.457031-171.582031 66.078124-.144531.128907-.550781.203126-.789063.097657-.332031-.144531-.5625-.417969-.632812-.5625v-338.777344c0-33.417969 27.28125-60.609375 60.8125-60.609375h331.441406c32.941406 0 59.742188 26.710938 59.742188 59.542969v214.363281zm0 0"
      ></path>
      <path
        d="m200.136719 159.507812h38.933593c8.285157 0 15-6.714843 15-15 0-8.28125-6.714843-15-15-15h-38.933593c-18.242188 0-33.082031 14.84375-33.082031 33.085938v80.851562c0 18.242188 14.839843 33.082032 33.082031 33.082032h38.933593c8.285157 0 15-6.714844 15-15s-6.714843-15-15-15h-38.933593c-1.699219 0-3.082031-1.382813-3.082031-3.082032v-25.425781h42.015624c8.285157 0 15-6.714843 15-15 0-8.285156-6.714843-15-15-15h-42.015624v-25.425781c0-1.703125 1.382812-3.085938 3.082031-3.085938zm0 0"
      ></path>
      <path
        d="m334.28125 246.527344h-27.132812c-2.695313 0-4.886719-2.191406-4.886719-4.886719v-97.132813c0-8.28125-6.714844-15-15-15-8.285157 0-15 6.71875-15 15v97.132813c0 19.238281 15.648437 34.886719 34.886719 34.886719h27.132812c8.28125 0 15-6.714844 15-15s-6.71875-15-15-15zm0 0"
      ></path>
      <path
        d="m133.863281 129.507812c-8.28125 0-15 6.71875-15 15v43.511719h-27.015625v-43.511719c0-8.28125-6.71875-15-15-15-8.285156 0-15 6.71875-15 15v117.019532c0 8.285156 6.714844 15 15 15 8.28125 0 15-6.714844 15-15v-43.507813h27.015625v43.507813c0 8.285156 6.71875 15 15 15 8.285157 0 15-6.714844 15-15v-117.019532c0-8.28125-6.714843-15-15-15zm0 0"
      ></path>
      <path
        d="m405.976562 129.507812h-21.933593c-11.898438 0-21.574219 9.679688-21.574219 21.574219v110.445313c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-45h13.507812c23.992188 0 43.511719-19.515625 43.511719-43.507813 0-23.992187-19.519531-43.511719-43.511719-43.511719zm0 57.019532h-13.507812v-27.019532h13.507812c7.449219 0 13.511719 6.0625 13.511719 13.511719s-6.0625 13.507813-13.511719 13.507813zm0 0"
      ></path>
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
