<script setup lang="ts">
import { computed, ref } from 'vue';
import domainStore from '@/store/scope';

const props = withDefaults(
  defineProps<{
    userId: number | string;
    domainId?: number | string;
    parentId?: number | string | null;
    projectId?: number | string | null;
    isAnyProjects: boolean;
    domainName?: string;
    userProjectIds?: any[];
    isNeedGetProject: boolean;
  }>(),
  {}
);
const emit = defineEmits<{
  (e: 'update:isAnyProjects', value: boolean): void;
  (e: 'update:userProjects', data: any): void;
}>();
const _domainStore = domainStore();

const domainByIds = computed(() => _domainStore.domainByIds);
const projectByIds = computed(() => _domainStore.projectByIds);

const domainDetail = computed(() => {
  if (!props.domainId) return {};
  return domainByIds.value[props.domainId];
});
const currentProjectId = computed(() =>
  parseInt(props.projectId?.toString() || '')
);
const currentParentId = computed(() =>
  parseInt(props.parentId?.toString() || '')
);
const domainDetailProjects = ref<
  { id: number; checked: boolean; name: string; subProjects: any[] }[]
>([]);

const initData = async () => {
  let currentProjectByUser: any[] = props.userProjectIds || [];
  if (props.isNeedGetProject) {
    const userDomain: any[] = await _domainStore.getUserDomainById(
      props.userId
    );

    const currentDomainByUser = userDomain.find(
      (domain) => domain?.domainId == props.domainId
    );
    currentProjectByUser = currentDomainByUser?.projectScopeList?.map(
      (project) => project?.projectId
    );
  }
  domainDetailProjects.value = domainDetail.value?.projects?.map(
    (projectId) => {
      projectId = parseInt(projectId?.toString());
      return {
        id: projectId,
        checked:
          currentProjectByUser?.includes(projectId) ||
          projectId == currentProjectId.value ||
          projectId == currentParentId.value ||
          false,
        name: projectByIds.value[projectId]?.name,
        subProjects: projectByIds.value[projectId]?.subProjects?.map(
          (proId) => {
            proId = parseInt(proId?.toString());
            return {
              id: proId,
              checked:
                currentProjectByUser?.includes(proId) ||
                projectId == currentProjectId.value ||
                false,
              name: projectByIds.value[proId]?.name,
            };
          }
        ),
      };
    }
  );

  handleUpdateUserProjects();
};

const onUnselectAll = () => {
  domainDetailProjects.value.forEach((project) => {
    project.checked = false;
    project?.subProjects?.forEach((subProject) => {
      subProject.checked = false;
    });
  });
  handleUpdateUserProjects();
};
const onSelectAll = () => {
  domainDetailProjects.value.forEach((project) => {
    project.checked = true;
    project?.subProjects?.forEach((subProject) => {
      subProject.checked = true;
    });
  });

  handleUpdateUserProjects();
};

const handleUpdateUserProjects = () => {
  emit('update:userProjects', domainDetailProjects.value);
};

const onUpdateProject = (projectId, value) => {
  const index = domainDetailProjects.value?.findIndex(
    (project) => project?.id == projectId
  );
  if (index == -1) return;
  domainDetailProjects.value[index]?.subProjects?.forEach((sub) => {
    sub.checked = value;
  });

  handleUpdateUserProjects();
};

const onChangeAnyProjects = (value) => {
  emit('update:isAnyProjects', !!value);
  handleUpdateUserProjects();
};

initData();
</script>

<template>
  <div class="flex-1 flex flex-col space-y-2">
    <span class="font-semibold text-current">
      {{ domainDetail?.name || domainName }}
    </span>
    <div class="flex">
      <AtomSwitch
        :model-value="isAnyProjects"
        @update:model-value="onChangeAnyProjects"
      >
        <span class="text-sm font-normal">
          {{ $t('USERMANAGEMENT_LABEL_SELECT_ALL_PROJECT') }}
        </span>
      </AtomSwitch>
    </div>
    <div
      v-if="domainId && !isAnyProjects && domainDetailProjects?.length > 0"
      class="h-full flex-1 min-h-0 flex flex-col pt-4 space-y-2"
    >
      <div class="flex space-x-2">
        <span
          class="text-sm cursor-pointer hover:text-current underline"
          @click="onSelectAll"
        >
          {{ $t('COMMON_LABEL_SELECT_ALL') || 'Select all' }}
        </span>
        <span
          class="text-sm cursor-pointer hover:text-current underline"
          @click="onUnselectAll"
        >
          {{ $t('COMMON_LABEL_UN_SELECT_ALL') || 'Unselect all' }}
        </span>
      </div>
      <div class="flex-1 min-h-0 overflow-y-auto small-scrollbar">
        <template v-for="project in domainDetailProjects" :key="project.id">
          <AtomCheckbox
            v-model="project.checked"
            class="py-1"
            :label="project.name"
            @update:model-value="(value) => onUpdateProject(project?.id, value)"
          />
          <!-- sub project -->
          <template v-if="project.checked && project.subProjects?.length > 0">
            <div class="flex">
              <span class="h-8 w-8"> </span>

              <div class="flex flex-col">
                <template
                  v-for="subproject in project.subProjects"
                  :key="subproject?.id"
                >
                  <AtomCheckbox
                    v-model="subproject.checked"
                    class="py-1"
                    :label="subproject.name"
                    icon-class="fill-yellow-500"
                    @update:model-value="handleUpdateUserProjects"
                  />
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
