// Generated by https://quicktype.io

export type OrganizationModel = 'PERSONAL' | 'COMPANY' | 'TEAM' | 'FAMILY';
export enum EOrganizationModel {
    PERSONAL = 'PERSONAL',
    COMPANY = 'COMPANY',
    TEAM = 'TEAM',
    FAMILY = 'FAMILY',
    NON_PROFIT_ORGANIZATION = 'NON-PROFIT_ORGANIZATION',
}
export interface IOrganizationDetail {
    id: number;
    countryId: number;
    code: string;
    attachmentCode: string;
    name: string;
    email: null;
    phone: string;
    address: string;
    addressDetail: string;
    size: string;
    type: string;
    typeName: string;
    model: OrganizationModel;
    modelName: string;
    active: boolean;
    logo: string;
    taxCode: string;
    websiteUrl: string;
    registerdDate: string;
    createdDate: string;
    updatedDate: string;
    logoUrl: string;
    logoThumbnailUrl: string;
    registrationOn: string;
    sourceFrom: null;
    subType: string;
    joinedDate: string;
    members: Member[];
    decideTime?: number;
}

export interface Member {
    userId: number;
    organizationId: number;
    fullName: string;
    avatar: string;
    avatarUrl: string;
    utc: string;
}

// Generated by https://quicktype.io

export interface IOrganizationInfoPayload {
    name: string;
    logo: string;
    model: EOrganizationModel;
    type: string;
    size: string;
    address: string;
    addressDetail: string;
    phone: string;
    websiteUrl: string;
    taxCode: string;
    subType: string;

    // new
    jobTitle?: string;
    category?: string;
    subCategory?: string;
    knowAppFrom?: string;
    // use in local
    personalType?: number;
    domainName?: string;
}
