export enum ActivityInTask {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export enum SubActivityInTask {
    ChangeDeadline = 'ChangeDeadline',
    AddNew = 'AddNew',
    RepeatGenerate = 'RepeatGenerate',
    Clone = 'Clone',
    ChangeName = 'ChangeName',
    ChangeNotes = 'ChangeNotes',
    ChangeAttachments = 'ChangeAttachments',
    ChangeDescription = 'ChangeDescription',
    ChangePriority = 'ChangePriority',
    ChangeDomain = 'ChangeDomain',
    ChangePrivate = 'ChangePrivate',
    ChangeAssignee = 'ChangeAssignee',
    Reopen = 'Reopen',
    Finished = 'Finished',
    Planned = 'Planned',
    ChangeStatus = 'ChangeStatus',
    Delete = 'Delete',
    EditComment = 'EditComment',
    Comment = 'Comment',
    PinnedComment = 'PinnedComment',
    EditCommentAttachments = 'EditCommentAttachments',
    DeleteComment = 'DeleteComment',
    AddCollaborator = 'AddCollaborator',
    RemoveCollaborator = 'RemoveCollaborator',
    JoinCollaborator = 'JoinCollaborator',
    LeaveCollaborator = 'LeaveCollaborator',
    ChangeTodoList = 'ChangeTodoList',
    WorkflowStepComplete = 'WorkflowStepComplete',
    WorkflowStepStart = 'WorkflowStepStart',
    ApprovalChange = 'ApprovalChange',
    ApprovalRequest = 'ApprovalRequest',
    ApprovalRequestCancel = 'ApprovalRequestCancel',
    ApprovalRequestApprove = 'ApprovalRequestApprove',
    ApprovalRequestReject = 'ApprovalRequestReject',
    WorkflowAttachToTask = 'WorkflowAttachToTask',
    SubTaskAdded = 'SubTaskAdded',
    SubTaskRemoved = 'SubTaskRemoved',
    ParentTaskAdded = 'ParentTaskAdded',
    ParentTaskRemoved = 'ParentTaskRemoved',
    ScheduleTimeLockedChange = 'ScheduleTimeLockedChange',
}

export enum platformLogActivity {
    web = 'WEB',
    mobile = 'MOBILE',
}

export enum NoteAction {
    Add = 'Add',
    Update = 'Update',
    Delete = 'Delete',
}
