<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import {
  StartWorkingConditionModel,
  StartWorkingLimitIpModel,
} from '@/application/models/setting/StartWorkingConditionModel';
import VigButton from '@/ui/common/atoms/VigButton/VigButton.vue';
import LocationService from '@/application/services/LocationService';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import InputChips from '@/ui/components/input-chips/InputChips.vue';

const props = defineProps<{
  startWorkingCondition: StartWorkingConditionModel;
  disabled?: boolean;
}>();

const emits = defineEmits(['onSettingChange']);

const limitIp = ref<StartWorkingLimitIpModel>({});
const listIps = ref<string[]>([]);

watch(
  () => props.startWorkingCondition,
  () => _setValues()
);

onMounted(() => _setValues());

const onCurrentIpClick = async () => {
  try {
    const currentIp = await LocationService.getInstance().getCurrentIp();
    if (!currentIp) throw '';

    settingGlobalModal({
      type: 'confirm',
      title: translate('SETTING_SW_CONDITION_IP_CURRENT'),
      content: currentIp,
      confirmLabel: translate('COMMON_LABEL_USE'),
      confirmable: true,
      closeable: true,
    });
    const confirmed = await ask();

    if (confirmed) {
      // limitIp.value.ips =
      //   (limitIp.value.ips || '') +
      //   (limitIp.value.ips?.length ? ',' : '') +
      //   currentIp;

      // emits('onSettingChange', { limitIp: limitIp.value });
      listIps.value.push(currentIp);
      onListIpsChange();
    }
  } catch (e) {
    settingGlobalModal({
      type: 'warning',
      title: translate('SETTING_SW_CONDITION_IP_CURRENT_GET_ERROR'),
      confirmable: true,
    });
    await ask();
  }
};

const onListIpsChange = () => {
  limitIp.value.ips = listIps.value?.join(';');

  emits('onSettingChange', { limitIp: limitIp.value });
};

const _setValues = () => {
  limitIp.value = props.startWorkingCondition?.limitIp || {};
  listIps.value = (limitIp.value?.ips || '')
    .split(';')
    .map((ip) => ip?.trim())
    .filter((ip) => !!ip);
};
</script>

<template>
  <div v-if="startWorkingCondition?.enabled">
    <MenuSettingRowItem
      name-icon="Global"
      :title-menu="$t('SETTING_SW_CONDITION_IP_ENABLE')"
      :sub-title="$t('SETTING_SW_CONDITION_IP_ENABLE_DESC')"
      custom-class=""
    >
      <template #action>
        <AtomSwitch
          v-model="limitIp.enabled"
          :disabled="disabled"
          @change="$emit('onSettingChange', { limitIp })"
        />
      </template>
      <template v-if="limitIp.enabled" #subMenu>
        <div class="pl-8 pr-6 py-2.5">
          <div class="flex items-start gap-2">
            <div class="w-full max-w-[50rem]">
              <InputChips
                v-model="listIps"
                :disabled="disabled"
                :placeholder="$t('SETTING_SW_CONDITION_IP_ENTER')"
                @update:model-value="onListIpsChange"
              />
            </div>
            <VigButton
              light
              color="gray"
              class="text-sm whitespace-nowrap"
              :disabled="disabled"
              @click="onCurrentIpClick"
            >
              {{ $t('SETTING_SW_CONDITION_IP_CURRENT_GET') }}
            </VigButton>
          </div>
        </div>
      </template>
    </MenuSettingRowItem>
  </div>
</template>
