<script setup lang="ts">
import { computed } from 'vue';
import { cloneDeep } from 'lodash';
import userStore from '@/store/user';

const props = withDefaults(
  defineProps<{
    members: any[];
    isEditable: boolean;
    showUserNumber?: number;
  }>(),
  {
    showUserNumber: 5,
  }
);

defineEmits(['onOpenUpdateMembers']);

const showMembers = computed<any[]>(() => {
  return cloneDeep(
    props.members?.map((user) => {
      const userId = user.userId || user.id || 0;
      return {
        ...user,
        ...userStore().allUserByIds[userId],
      };
    })
  );
});
</script>

<template>
  <div class="hover-to-show__parent flex items-center space-x-1">
    <div class="flex cursor-pointer relative">
      <SynListAvatar
        :users="showMembers"
        :custom-class="'w-6 h-6 text-sm'"
        :max-length="showUserNumber"
      />
    </div>
    <div
      v-if="isEditable"
      class="w-6 h-6 hover:bg-gray-100 rounded-full flex-center cursor-pointer"
      @click.stop="$emit('onOpenUpdateMembers')"
    >
      <SynIcon
        class="hover-to-show__children"
        name="edit"
        is-active
        custom-class="w-4 h-4 fill-gray-500"
      />
    </div>
  </div>
</template>
