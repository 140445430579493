<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class="w-full h-full"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="m65.988 168.1h4.95v-58.375c0-60.599 49.125-109.725 109.725-109.725 53.421 0 97.905 38.185 107.698 88.745 13.589-13.439 32.267-21.743 52.889-21.743 41.547 0 75.227 33.68 75.227 75.227 0 9.09-1.612 17.804-4.567 25.871h34.101c36.444 0 65.988 29.544 65.988 65.988 0 36.444-29.544 65.988-65.988 65.988h-380.023c-36.444 0-65.988-29.543-65.988-65.988 0-36.444 29.544-65.988 65.988-65.988z"
            fill="#E5E7EB"
            data-original="#f9f2f2"
            style=""
            class=""
          />
          <path
            d="m446.012 168.1h-34.102c2.955-8.067 4.567-16.781 4.567-25.871 0-41.547-33.68-75.227-75.227-75.227-20.622 0-39.3 8.304-52.889 21.743-4.443-22.938-16.042-43.311-32.361-58.744v270.076h190.012c36.444 0 65.988-29.544 65.988-65.988 0-36.445-29.544-65.989-65.988-65.989z"
            fill="#E5E7EB"
            data-original="#e7e2ee"
            style=""
            class=""
          />
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  d="m224.295 181.938h136.8c8.3 0 15.029 6.729 15.029 15.029v300.004c0 8.3-6.729 15.029-15.029 15.029h-210.191c-8.3 0-15.029-6.729-15.029-15.029v-227.722l54.21-33.656z"
                  fill="#d1d1d1"
                  data-original="#a5e1bd"
                  style=""
                  class=""
                />
                <path
                  d="m361.096 181.938h-105.096v330.062h105.095c8.3 0 15.029-6.729 15.029-15.029v-300.004c.001-8.3-6.728-15.029-15.028-15.029z"
                  fill="#D1D5DB"
                  data-original="#71bda5"
                  style=""
                  class=""
                />
              </g>
              <path
                d="m135.875 269.249h73.42c8.284 0 15-6.716 15-15v-72.311z"
                fill="#ffffff"
                data-original="#518f8d"
                style=""
                class=""
              />
            </g>
            <g>
              <g>
                <path
                  d="m256 379.272h-37.479c-8.284 0-15 6.716-15 15s6.716 15 15 15h37.479l10-15z"
                  fill="#454545"
                  data-original="#3b5988"
                  style=""
                  class=""
                />
                <path
                  d="m296.322 379.272h-40.322v30h40.322c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                  fill="#171717"
                  data-original="#283c5b"
                  style=""
                  class=""
                />
              </g>
              <g>
                <path
                  d="m218.521 349.272h37.479l10-15-10-15h-37.479c-8.284 0-15 6.716-15 15 0 8.285 6.716 15 15 15z"
                  fill="#454545"
                  data-original="#3b5988"
                  style=""
                  class=""
                />
                <path
                  d="m296.322 319.272h-40.322v30h40.322c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                  fill="#171717"
                  data-original="#283c5b"
                  style=""
                  class=""
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  d="m381.871 512c-55.211 0-100.129-44.918-100.129-100.129s44.918-100.129 100.129-100.129 100.129 44.918 100.129 100.129-44.918 100.129-100.129 100.129z"
                  fill="#e7e7e7"
                  data-original="#e9d049"
                  style=""
                  class=""
                />
                <path
                  d="m381.872 311.742v200.258c55.21 0 100.128-44.918 100.128-100.129s-44.918-100.129-100.128-100.129z"
                  fill="#e7e7e7"
                  data-original="#e1b633"
                  style=""
                  class=""
                />
              </g>
              <g>
                <circle
                  cx="381.871"
                  cy="411.871"
                  fill="#D1D5DB"
                  r="70.129"
                  data-original="#e7e2ee"
                  style=""
                  class=""
                />
                <path
                  d="m381.872 341.742v140.258c38.731 0 70.128-31.398 70.128-70.129s-31.397-70.129-70.128-70.129z"
                  fill="#D1D5DB"
                  data-original="#d1ccde"
                  style=""
                  class=""
                />
              </g>
            </g>
            <g>
              <path
                d="m403.084 411.604 7.535-7.535c5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.355-5.857-21.213 0l-7.535 7.535-7.535-7.535c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l7.535 7.535-7.535 7.535c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.394 10.607 4.394s7.678-1.465 10.606-4.394l7.535-7.535 7.535 7.535c2.929 2.929 6.767 4.394 10.606 4.394s7.678-1.465 10.606-4.394c5.858-5.857 5.858-15.355 0-21.213z"
                fill="#313131"
                data-original="#d37b5f"
                style=""
                class=""
              />
              <path
                d="m403.084 411.604 7.535-7.535c5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.355-5.857-21.213 0l-7.535 7.535v42.427l7.535 7.535c2.929 2.929 6.767 4.394 10.606 4.394s7.678-1.465 10.606-4.394c5.858-5.857 5.858-15.355 0-21.213z"
                fill="#353535"
                data-original="#c5344e"
                style=""
                class=""
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
