<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 171.01 237.02"
  >
    <path
      style="fill: #008e8e"
      d="M485.49,370.28c-.08-8.81-.36-39.73-25-64-4.49-4.42-26.23-24.82-60-24-33.56.82-54.24,22-58,26l-17-20h-11v231h43v-75a80.87,80.87,0,0,0,43,15c37,1.57,60.64-24.22,64-28C485.4,407.72,485.58,380.07,485.49,370.28Zm-45.65,18a44,44,0,0,1-16,20,43.32,43.32,0,0,1-19,7,35.19,35.19,0,0,1-20-3,44.88,44.88,0,0,1-7-4,49.63,49.63,0,0,1-14-15,40.92,40.92,0,0,1-5-12,43.08,43.08,0,0,1-1-10,49.19,49.19,0,0,1,5-22c1.84-3.65,8.61-16.56,24-22a41.6,41.6,0,0,1,24-1,43.51,43.51,0,0,1,22,14,46.43,46.43,0,0,1,9,18A47.69,47.69,0,0,1,439.84,388.28Z"
      transform="translate(-314.49 -282.25)"
    />
  </svg>
</template>
