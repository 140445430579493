<template>
  <svg
    id="Objects"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 32 32"
    xml:space="preserve"
  >
    <g>
      <polygon
        class="st0"
        points="31.6,7.8 26.3,24 26.1,24.6 26.1,24.7 6.5,24.7 6.5,24.6 6.2,23.9 0.4,6.9 9.5,11.8 15.7,2.6 16.1,2    16.2,2.2 18.6,6 20.9,9.5 22.2,11.6 22.4,11.8  "
      />
      <path
        class="st1"
        d="M26.3,24l-0.2,0.7l0,0.1H6.5l0-0.1l-0.2-0.7c0.1-0.1,0.2-0.1,0.4-0.1h19.3C26,23.8,26.2,23.9,26.3,24z"
      />
      <polygon
        class="st2"
        points="18.6,6 16.2,14.1 16.2,14.3 15.8,15.4 15.3,17.3 15,18.2 14.7,19.2 14.3,20.6 13.3,23.8 13.1,24.6    13,24.7 9,24.7 9.1,24.6 9.3,23.8 15.7,2.6 16.1,2 16.2,2.2  "
      />
      <polygon
        class="st2"
        points="22.2,11.6 18.6,23.8 18.3,24.6 18.3,24.7 16.3,24.7 16.3,24.6 16.6,23.8 17.4,21 17.5,20.9 18.4,17.6    18.7,16.8 20.9,9.5  "
      />
      <path
        class="st3"
        d="M16.2,24.7V2.2L18.6,6l2.2,3.5l1.4,2.1l0.1,0.2l9.2-4L26.3,24l-0.2,0.7C26.5,24.8,16.2,24.7,16.2,24.7z"
      />
    </g>
    <g>
      <g>
        <path
          class="st4"
          d="M26.5,28.6c0,0.7-0.3,1.2-0.7,1.4c-0.1,0-0.1,0-0.2,0H7.2c-0.5,0-0.9-0.6-0.9-1.4c0-0.7,0.4-1.3,0.8-1.4    c0,0,0.1,0,0.1,0h18.4c0.1,0,0.2,0,0.2,0C26.2,27.4,26.5,27.9,26.5,28.6z"
        />
        <polygon
          class="st5"
          points="17.8,27.2 17.8,27.3 17,30 17,30 13,30 13.8,27.3 13.8,27.2   "
        />
        <polygon
          class="st5"
          points="23.2,27.2 23.1,27.3 22.3,30 22.3,30 20.3,30 20.3,30 21.1,27.3 21.2,27.2   "
        />
      </g>
      <path
        class="st3"
        d="M26.5,28.6c0,0.7-0.3,1.2-0.7,1.4c-0.7,0-2,0-3.5,0c-0.3,0-0.6,0-0.9,0c-0.4,0-0.7,0-1.1,0c-1.3,0-2.6,0-3.3,0   c-0.4,0-0.7,0-0.7,0v-2.8h9.3c0.1,0,0.2,0,0.2,0C26.2,27.4,26.5,27.9,26.5,28.6z"
      />
    </g>
  </svg>
</template>
