import { AdminType } from '@/ui/common/constants/constant';
import settingStore from '@/store/setting';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { permissionByRoleName } from '@/ui/hooks/permission/permission-constant';

export const isEnabled = (functionName) => {
    const _settingStore = settingStore();
    let keyCheck;
    if (functionName == 'Messages') {
        keyCheck = 'chatEnabled';
    } else if (functionName == 'Resources') {
        keyCheck = 'gedEnabled';
    }
    if (functionName == 'Video') {
        keyCheck = 'videoCallEnabled';
    }
    const orgSetting = _settingStore._organizationSetting;
    const enabled = orgSetting[keyCheck];
    if (enabled != true) return false;
    return true;
};
export const hasPermission = (functionName = '') => {
    if (hasRolePermission(AdminType.Owner)) {
        return true;
    }
    // function name has not defined
    if (
        !Object.prototype.hasOwnProperty.call(
            permissionByRoleName,
            functionName
        )
    )
        return hasRolePermission(AdminType.Admin);

    // function name has defined
    const role: AdminType =
        MyOrganizationSingleton.getInstance().getUserRole() || AdminType.User;
    return permissionByRoleName[functionName][role];
};
export const hasRolePermission = (userRole: AdminType) => {
    const role = MyOrganizationSingleton.getInstance().getUserRole();
    return role == userRole;
};

//  Field = chatEnabled, gedEnabled, videoCallEnabled
export const checkSettingAccessPermission = async (field) => {
    const _settingStore = settingStore();
    const result = await _settingStore.getOrganizationSettings();
    return result[field] || false;
};
