import { DayoffType } from '@/domain/enums/DayoffEnums';
import DateoffExceptionClass from './DateoffExceptionClass';

export default class DayoffPayloadClass {
    startDate: number;
    endDate: number;
    startDateSession: number;
    endDateSession: number;

    type: DayoffType; // SCHEDULE | EXCEPTION | WFH - keyword
    requestReason: string; // keyword
    requestNote: string;
    exception: DateoffExceptionClass;
    constructor(data) {
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.startDateSession = data?.startDateSession;
        this.endDateSession = data?.endDateSession;
        this.type = data?.type;
        this.requestReason = data?.requestReason;
        this.requestNote = data?.requestNote;
        this.exception = data?.exception;
    }
}
