<script setup lang="ts">
import { AdminType, GroupMemberRole } from '@/ui/common/constants/constant';
import { computed, onMounted, ref } from 'vue';
import UserById from '@/ui/components/user/UserById.vue';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import GroupDetailEntity, {
  IGroupDetailEntity,
} from '@/domain/entities/group/GroupDetailEntity';
import groupStore from '@/store/group';
import BreakLinkDepartmentGroupModal from '@/ui/modules/group/detail/BreakLinkDepartmentGroupModal.vue';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

const props = defineProps<{
  groupId: number;
}>();
const emit = defineEmits<{
  (e: 'updateMemberSuccess'): void;
}>();

const _groupStore = groupStore();

const currentUserRole = computed(() =>
  MyOrganizationSingleton.getInstance().getUserRole()
);
const viewingProfileUserId = ref<number>(0);
const chooseViewProfile = async (id) => {
  viewingProfileUserId.value = id;
};
const allGroupByIds = computed<IGroupDetailEntity>(() => {
  return _groupStore.allGroupByIds;
});
const groupDetail = computed<IGroupDetailEntity>(() => {
  if (
    !allGroupByIds.value ||
    !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
  )
    return {};
  return allGroupByIds.value[props.groupId];
});

const groupDetailDataRef = ref<GroupDetailEntity>(
  new GroupDetailEntity(groupDetail.value)
);
onMounted(() => {
  groupDetailDataRef.value = new GroupDetailEntity(groupDetail.value);
});

const isShowAlertCannotEditDepartmentGroup = ref(false);

const changeUserRole = async (user) => {
  await groupDetailDataRef.value.onChangeMemberRole(user);

  emit('updateMemberSuccess');
};

const removeMember = async (user) => {
  await groupDetailDataRef.value.onRemoveMember(user);

  emit('updateMemberSuccess');
};
</script>
<template>
  <div v-if="groupDetailDataRef.groupUsers?.length < 1">
    <syn-animation
      name="searchNotFound"
      stype="width: 100px; height: 100px"
      label="No members"
    />
  </div>
  <div v-else class="w-full flex flex-col h-full pb-4">
    <div
      v-for="user in groupDetailDataRef.groupUsers"
      :key="user?.userId"
      class="w-full py-2 hover:bg-gray-100 cursor-pointer"
    >
      <div class="w-full flex items-center justify-between pl-4 pr-2">
        <div
          v-vig-tooltip="user.lastName + ' ' + user.firstName"
          class="flex items-center space-x-2 font-sans"
          @click="chooseViewProfile(user.userId)"
        >
          <UserById
            :user-id="user.userId"
            class="flex-1"
            avatar-class="w-8 h-8"
          >
            <template #subName>
              <span
                v-if="user?.role == GroupMemberRole.Admin"
                class="italic text-xs text-gray-400"
              >
                {{ $t('COMMON_LABEL_ADMIN') }}
              </span>
            </template>
          </UserById>
        </div>
        <div
          v-if="currentUserRole != AdminType.Visitor"
          class="flex items-center"
        >
          <ThreeDotActions v-permission="'removeUserInGroup'" type="action">
            <div
              v-if="groupDetailDataRef.groupUsers?.length >= 1"
              @click="changeUserRole(user)"
            >
              <SynDropdownItem class="flex items-center space-x-2 list-li">
                <SynIcon
                  name="check-circle"
                  is-active
                  :class="
                    user.role == GroupMemberRole.Admin
                      ? 'text-gray-600 fill-gray-500'
                      : 'text-gray-600 fill-gray-500'
                  "
                />
                <span
                  v-if="user.role == GroupMemberRole.Admin"
                  class="text-gray-500"
                >
                  {{ $t('GROUP_UPDATE_MEMBER_UNSET_ADMIN') }}
                </span>
                <span v-else class="text-gray-500">
                  {{ $t('GROUP_UPDATE_MEMBER_SET_ADMIN') }}
                </span>
              </SynDropdownItem>
            </div>
            <div @click="removeMember(user)">
              <SynDropdownItem
                class="flex items-center space-x-2 list-li-delete"
              >
                <SynIcon
                  name="trash"
                  custom-class="w-4 h-4 fill-red-500"
                  is-active
                />
                <span class="text-red-500">
                  {{ $t('TASK_DETAIL_REMOVE') }}
                </span>
              </SynDropdownItem>
            </div>
          </ThreeDotActions>
        </div>
      </div>
    </div>
  </div>

  <ModalProfileUser
    v-if="viewingProfileUserId"
    :profile-id="viewingProfileUserId"
    @cancel="viewingProfileUserId = 0"
  />

  <BreakLinkDepartmentGroupModal
    v-if="isShowAlertCannotEditDepartmentGroup"
    :group="groupDetail"
    @cancel="isShowAlertCannotEditDepartmentGroup = false"
    @update-success="isShowAlertCannotEditDepartmentGroup = false"
  />
</template>
