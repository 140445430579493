<script setup lang="ts">
import { ref } from 'vue';
import { AuthenticateType } from '@/ui/common/constants/constant';
import { useRouter } from 'vue-router';
import PostMessageService from '@/application/services/PostMessageService';
import { AppMessageTypeEnum } from '@/domain/enums/app-message-type.enum';
import { AuthenticateUserPassword } from '@/application/entities/un-authenticate/LoginEntitiesClass';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';

const _useRouter = useRouter();

const phone = ref();
const password = ref();

const onLoginClick = async () => {
  let phoneFormatted = phone.value;
  if (phoneFormatted?.startsWith('0')) {
    phoneFormatted = `+84${phoneFormatted}`.replace('+840', '+84');
  }

  const authenUserPass = new AuthenticateUserPassword(
    phoneFormatted,
    password.value,
    AuthenticateType.EmailPhonePassword,
    phoneFormatted
  );

  const loginRes = await authenUserPass.login();

  if (loginRes?.organizations?.length) {
    const selectOrg = loginRes?.organizations[0];

    const authenSelectOrganization = new AuthenticateSelectOrganization(
      selectOrg?.id
    );

    await authenSelectOrganization.login();

    _useRouter.push({ name: 'EmbedChatsList' });

    PostMessageService.getInstance().postMessageToParent({
      type: AppMessageTypeEnum.tictopUserLogin,
    });
  }
};
</script>

<template>
  <div class="h-screen flex flex-col items-center justify-center">
    <SynIcon name="TictopLogo" custom-class="w-10 h-10 mb-3" />
    <div class="text-xl mb-4">Đăng nhập</div>
    <div class="mb-3">
      <input
        v-model="phone"
        type="tel"
        class="vig-input"
        placeholder="Số điện thoại"
      />
    </div>
    <div class="mb-4">
      <input
        v-model="password"
        type="password"
        class="vig-input"
        placeholder="Mật khẩu"
      />
    </div>
    <VigButton @click="onLoginClick">Đăng nhập</VigButton>
  </div>
</template>

<style scoped></style>
