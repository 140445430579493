import BaseModel from './BaseModel';
import {
    collection,
    CollectionReference,
    doc,
    DocumentData,
    onSnapshot,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export default class UserConversationCollectionModel extends BaseModel {
    collectionRef: CollectionReference<DocumentData>;
    constructor(userId: number) {
        super();

        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        this.collectionRef = collection(
            doc(
                collection(
                    doc(
                        collection(firestoreDb, 'organizationUsers'),
                        `${organizationId}`
                    ),
                    'users'
                ),
                `${userId}`
            ),
            'conversations'
        );
    }

    subscribleDataChange(callback) {
        return onSnapshot(this.collectionRef, (snapshot) => {
            callback && callback(snapshot.docChanges().reverse());
        });
    }
}
