<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  reactionsList: {
    type: Array,
    default: () => [],
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const totalReaction = computed(() => {
  return props?.reactionsList
    ?.map((el: any) => el?.count)
    ?.reduce((a, b) => a + b, 0);
});
</script>

<template>
  <div
    v-if="totalReaction > 0"
    class="bg-white rounded-full flex-center px-2 py-1 space-x-1 border"
    :class="{
      'cursor-pointer hover:bg-current-100 hover:bg-opacity-100': !readonly,
    }"
  >
    <span class="font-medium text-sm leading-none text-black">
      {{ totalReaction }}</span
    >
    <template v-for="group in reactionsList.slice(0, 3)" :key="group">
      <span class="w-4 h-4 flex-center">
        <span
          v-twemoji="{ size: 'w-4 h-4' }"
          class="emoji-icon leading-none"
          v-text="group?.content"
        ></span>
      </span>
    </template>
  </div>
</template>
