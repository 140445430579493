<script setup lang="ts">
import stepperComposables from '@/ui/composables/app/stepper-composables';
import groupStore from '@/store/group';
import { tell } from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';

const props = withDefaults(
  defineProps<{
    group: any;
  }>(),
  {
    group: {},
  }
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'updateSuccess'): void;
}>();

const _groupStore = groupStore();

const onBreakLinkDepartment = async () => {
  await _groupStore.breakLinkDefaultDepartmentGroup(props.group?.id);
  tell(false);
  emit('updateSuccess');
};

const {
  currentStep,
  stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    DEFAULT: {
      key: 'DEFAULT',
      label: '',
      next: 'BREAK_LINK',
      previous: '',
      isActive: true,
      previousText: 'COMMON_LABEL_CLOSE',
      confirmText: 'COMMON_LABEL_I_WANT_TO_MODIFY',
      onClose: () => {
        tell(false);
        emit('cancel');
      },
    },
    BREAK_LINK: {
      key: 'BREAK_LINK',
      label: 'GROUP_BREAK_LINK_DEPARTMENT_TITLE',
      next: '',
      previous: 'DEFAULT',
      isActive: false,
      previousText: 'COMMON_LABEL_CANCEL',
      confirmText: 'GROUP_COMFIRM_BREAK_LINK_DEPARTMENT_BUTTON',
      previousAction: () => {},
      nextAction: () => {
        onBreakLinkDepartment();
      },
    },
  },
  'DEFAULT'
);
</script>

<template>
  <div class="flex flex-col">
    <div class="px-6">
      <div v-if="currentStep == 'DEFAULT'" class="flex space-x-4 items-start">
        <div>
          <SynIcon name="info" custom-class="w-9 h-9 fill-current" />
        </div>
        <div class="" role="alert">
          <p class="font-medium text-xl pb-4 text-current">
            {{ $t('GROUP_LABEL_EDIT_SYSTEM_GROUP_TITLE') }}
          </p>
          <span
            class="text-gray-600"
            v-html="
              $t('GROUP_LABEL_REMOVE_DEPARTMENT_GROUP_CONTENT', {
                departmentName: group.name,
              })
            "
          ></span>
        </div>
      </div>
      <div
        v-if="currentStep == 'BREAK_LINK'"
        class="flex space-x-4 items-start"
      >
        <div>
          <SynIcon name="info" custom-class="w-9 h-9 fill-current" />
        </div>
        <div class="" role="alert">
          <p class="font-medium text-xl pb-4 text-current">
            {{ $t('GROUP_BREAK_LINK_DEPARTMENT_TITLE') }}
          </p>
          <span
            class="text-gray-600 space-y-2"
            v-html="
              $t('GROUP_BREAK_LINK_DEPARTMENT_CONTENT', {
                departmentName: group.name,
              })
            "
          ></span>
        </div>
      </div>
    </div>
    <div
      v-if="currentStep == 'BREAK_LINK'"
      class="text-center pt-5 pb-3 text-current-500"
    >
      {{
        $t('GROUP_COMFIRM_BREAK_LINK_DEPARTMENT', {
          groupName: group.name,
        })
      }}
    </div>
    <div class="pr-4 flex py-4 justify-end space-x-2">
      <SynButton
        v-if="stepperByKey[currentStep].previousText"
        type-outline
        :label="$t(stepperByKey[currentStep].previousText)"
        @click="onHandleGoToPreviousStep"
      />
      <SynButton
        v-if="stepperByKey[currentStep].confirmText"
        :label="$t(stepperByKey[currentStep].confirmText)"
        @click="onHandleGoToNextStep"
      />
    </div>
  </div>
</template>
