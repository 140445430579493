<template>
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M505.707,261.12c-64.96-61.76-152.96-99.627-249.707-99.627S71.253,199.36,6.293,261.12C2.347,265.067,0,270.4,0,276.267
			c0,5.867,2.347,11.2,6.293,15.147l52.8,52.8c3.84,3.947,9.173,6.293,15.04,6.293c5.867,0,11.093-2.347,14.933-6.08
			c16.96-15.68,36.053-29.013,56.853-39.467c7.04-3.52,11.947-10.773,11.947-19.2v-66.24C188.8,209.493,221.76,204.16,256,204.16
			s67.2,5.333,98.133,15.253v66.24c0,8.427,4.907,15.68,11.947,19.2c20.8,10.453,39.893,23.787,56.853,39.467
			c3.84,3.733,9.173,6.08,14.933,6.08c5.867,0,11.2-2.453,15.04-6.293l52.8-52.8c3.947-3.84,6.293-9.173,6.293-15.04
			C512,270.4,509.653,264.96,505.707,261.12z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
