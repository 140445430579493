import permissionStore from '@/store/permission';
import statisticStore from '@/store/common/statistic.store';
import chatStore from '@/store/chat';

export const EShowChatWidget = {
    DO_NOT_DISPLAY: 1,
    ALWAYS_DISPLAY: 2,
    BEGINNER_USER_ONLY: 3,
};
export const checkAutoShowChatWidget = () => {
    const allSystemConfigs = permissionStore().allSystemConfigs;
    const functionCode = 'CHAT_TICTOP_C_SHOW_MINI_CHAT_RULES';
    if (!allSystemConfigs) return false;

    if (!Object.prototype.hasOwnProperty.call(allSystemConfigs, functionCode))
        return false;

    const key = parseInt(allSystemConfigs[functionCode]);

    switch (key) {
        case EShowChatWidget.DO_NOT_DISPLAY:
            return false;
        case EShowChatWidget.ALWAYS_DISPLAY:
            return true;

        case EShowChatWidget.BEGINNER_USER_ONLY: {
            const myStatistics = statisticStore().myStatistics;
            const myConversationIds = chatStore().userConversationIds;
            const isBeginnerUser =
                myStatistics?.totalMessage < 50 &&
                myConversationIds?.length < 3;

            return isBeginnerUser;
        }

        default:
            break;
    }
};
export const checkShowChatWidget = () => {
    const allSystemConfigs = permissionStore().allSystemConfigs;
    const functionCode = 'CHAT_TICTOP_C_SHOW_MINI_CHAT_RULES';
    if (!allSystemConfigs) return;

    if (!Object.prototype.hasOwnProperty.call(allSystemConfigs, functionCode))
        return true;

    const key = parseInt(allSystemConfigs[functionCode]);

    switch (key) {
        case EShowChatWidget.DO_NOT_DISPLAY:
            return false;
        case EShowChatWidget.BEGINNER_USER_ONLY:
        case EShowChatWidget.ALWAYS_DISPLAY:
            return true;

        default:
            break;
    }
};
