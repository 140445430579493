<script setup lang="ts">
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
const srcAppStore = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/email-template/appstore.png`;
const srcGooglePlay = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/email-template/googleplay.png`;
const qrCode = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/images/qr-code-1.png`;

defineProps({
  isButtonDownload: {
    type: Boolean,
    default: true,
  },
  isTextDownload: {
    type: Boolean,
    default: true,
  },
  trigger: {
    type: String,
    default: 'mouseenter',
  },
  zIndex: {
    type: Number,
    default: 50,
  },
});
</script>

<template>
  <div class="flex items-center flex-col justify-center space-y-2">
    <vig-dropdown :trigger="trigger" :z-index="zIndex">
      <template #dropdown-toggle>
        <slot>
          <div class="flex items-center">
            <div>
              <SynIcon name="download-app" custom-class="w-6 h-6" />
            </div>
            <div
              v-if="isTextDownload"
              class="
                pl-2
                cursor-pointer
                truncate
                flex flex-1
                hover:text-current-400
              "
            >
              {{ $t('COMMON_LABEL_DOWNLOAD_APP') }}
            </div>
          </div>
        </slot>
      </template>
      <template #dropdown-menu>
        <div class="flex-center space-y-2 flex-col p-4">
          <span class="font-medium">{{
            $t('COMMON_LABEL_SCAN_CODE') + ' ↓'
          }}</span>
          <syn-img :src="qrCode" class="w-48" />
        </div>
      </template>
    </vig-dropdown>
    <div
      v-if="isButtonDownload"
      class="flex w-full space-x-4 justify-between items-center"
    >
      <a
        href="https://apps.apple.com/vn/app/tictop/id1582398478"
        target="”_blank”"
      >
        <syn-img :src="srcAppStore" class="w-48" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=vig.tictop.app"
        target="”_blank”"
      >
        <syn-img :src="srcGooglePlay" class="w-48" />
      </a>
    </div>
  </div>
</template>
