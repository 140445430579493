<template>
  <div class="flex-center flex-wrap w-full space-y-2 -pt-8">
    <div class="w-full">
      <syn-animation name="verifyMail" stype="width: 150px;" />
    </div>
    <label
      class="w-full text-center text-sm text-gray-900"
      v-html="
        $t('VERIFY_EMAIL_LABEL_SET_PASSWORD_ADMIN', {
          email: `<span class='font-semibold underline text-current-500'>${email}</span></br>`,
        })
      "
    ></label>
  </div>
  <div class="flex-center mt-4 space-x-3">
    <syn-form-input
      :error-message="!blockCodeLogin && !blockSendingCode && messageError"
      class="w-max"
    >
      <AtomOtpInput
        ref="otpInput"
        class="pb-2 mb-2"
        input-classes="otp-input"
        :num-inputs="6"
        :should-auto-focus="true"
        :is-input-num="true"
        input-type="number"
        :is-clear="isClear"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
      />
    </syn-form-input>
  </div>
  <div class="text-center mt-6">
    <div class="mt-3 text-sm flex-center space-x-3">
      <div v-if="!isResendCode">
        <label
          id="countdown"
          class="text-sm mt-2 text-current-500 w-full text-center"
        >
          {{ message }}
        </label>
      </div>
      <div v-else>
        <label v-if="blockCodeLogin" class="text-red-900">
          {{ $t('LOGIN_BY_CODE_BLOCKED') }}
        </label>
        <label v-else-if="blockSendingCode" class="text-red-900">
          {{ $t('SEND_PHONE_CODE_BLOCKED') }}
        </label>
        <label v-else class="mt-2 flex-center space-x-2">
          <span class="text-gray-800">{{
            $t('GET_CODE_LABEL_QUESTION_RESEND_CODE')
          }}</span>
          <span
            :class="isLoadResend ? 'cursor-not-allowed' : 'cursor-pointer'"
            class="font-medium text-current hover:text-current-700"
            @click="onResend"
            >{{ $t('LOGIN_LABEL_RESEND_CODE') }}
          </span>
        </label>
      </div>
      <!-- <label
        v-else
        @click="onResend"
        :class="isLoadResend ? 'cursor-not-allowed' : 'cursor-pointer'"
        class="mt-2 text-current hover:text-current-700"
        >{{ $t('LOGIN_LABEL_RESEND_CODE') }}
      </label> -->
      <SynIcon
        v-if="isLoadResend && isResendCode"
        name="spinner"
        class="animate-spin fill-current w-4 h-4 mt-2"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
export default {
  name: 'GetCodeSetPassword',
  components: {},
  props: {
    phone: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingResend: {
      type: Boolean,
      default: false,
    },
    errorCode: {
      type: String,
      default: '',
    },
    blockCodeLogin: {
      type: Boolean,
      default: false,
    },
    blockSendingCode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onVerify', 'resendCode', 'changeCode', 'codeReady'],
  setup(props, { emit }) {
    const isFullCode = ref(false);
    const codeOPT = ref('');
    const isLoad = ref(props.isLoading);
    const isLoadResend = ref(props.isLoadingResend);
    const isResendCode = ref(false);
    const time = ref(60);
    const message = ref('');
    const messageError = ref(props.errorCode);

    const valueWatch = () => {
      isLoad.value = props.isLoading;
      isLoadResend.value = props.isLoadingResend;
      messageError.value = props.errorCode;
    };
    watch(
      () => props.isLoading,
      () => {
        valueWatch();
      }
    );
    watch(
      () => props.isLoadingResend,
      () => {
        valueWatch();
      }
    );
    watch(
      () => props.errorCode,
      () => {
        valueWatch();
      }
    );
    watch(
      () => props.blockCodeLogin,
      () => {
        time.value = 0;
      }
    );

    const startCount = () => {
      time.value = 60;
      const countDownTime = setInterval(function () {
        if (time.value < 0 || props.blockCodeLogin || props.blockSendingCode) {
          clearInterval(countDownTime);
          isResendCode.value = true;
        } else {
          isResendCode.value = false;
          message.value = translate('COMMON_LABEL_COUNT_DOWN_TIME', {
            time: time.value,
          });
        }
        time.value -= 1;
      }, 1000);
    };
    startCount();

    const handleOnComplete = (value) => {
      codeOPT.value = value;
    };

    const onResend = () => {
      handleOnClear();
      emit('resendCode', true);
      startCount();
    };
    const handleOnChange = (value) => {
      isClear.value = false;
      codeOPT.value = value;
      if (codeOPT.value.length == 6) {
        isFullCode.value = true;
        emit('changeCode', codeOPT.value);
        emit('codeReady', true);
      } else {
        isFullCode.value = false;
        messageError.value = '';
        emit('codeReady', false);
      }
    };

    const isClear = ref(false);
    const handleOnClear = () => {
      isClear.value = true;
      isFullCode.value = false;
      codeOPT.value = '';
      messageError.value = '';
    };

    return {
      codeOPT,
      isFullCode,
      isLoad,
      isLoadResend,
      message,
      isResendCode,
      messageError,
      isClear,
      handleOnComplete,
      handleOnChange,
      onResend,
      handleOnClear,
    };
  },
};
</script>
