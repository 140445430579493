<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';
import groupStore from '@/store/group';
import changeAssignComposable from '@/ui/modules/task/composables/change-assign-composable';
import domainStore from '@/store/scope';
import settingStore from '@/store/setting';
import myProfileStore from '@/store/auth/my-profile';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import userStore from '@/store/user';
import { EGroupType } from '@/application/types/group/group.types';

const props = defineProps<{
  groupId?: number | null;
  userId?: number | null;
  domainId?: number;
  projectId?: number | null;
  subprojectId?: number | null;
  isUpdateInstant?: boolean;
  canRemoveAll?: boolean;
  customClass?: string;
  isHiddenWarning?: boolean;
  isWorkflow?: boolean;
}>();
const emit = defineEmits(['onCancel', 'onSave', 'focus']);
const _settingStore = settingStore();

const allGroup = computed(() =>
  groupStore()
    .myGroups?.filter((e) => e?.isMember && e.type == EGroupType.NORMAL)
    .map((o) => {
      return {
        ...o,
        avatar: o.avatarUrl,
        favoriteId: o.id,
      };
    })
);

const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);

const myDepartmentIds = computed(() => {
  const myId = myProfileStore().myProfile?.id;
  return myId ? userStore().allUserByIds[myId]?.departmentIds : [];
});

const fixConflictDomain = ref({
  domain: false,
  project: false,
  subProject: false,
});

const currentDomain = computed(() => {
  if (!props?.domainId) return {};
  return domainStore().domainByIds[props.domainId];
});
const currentProject = computed(() => {
  if (!props?.projectId) return {};
  return domainStore().projectByIds[props?.projectId];
});
const currentSubProject = computed(() => {
  if (!props?.subprojectId) return {};
  return domainStore().projectByIds[props?.subprojectId];
});

watch(
  () => props.userId,
  () => {
    formData.value.userId = props.userId as any;
    initData();
  }
);

onMounted(() => {
  if (departmentManagementEnabled.value && myDepartmentIds.value?.length == 0) {
    toggleSearchAll(true);
  }
});

const formData = ref({ groupId: 0, userId: 0 });

const {
  isSearchAll,
  assignedGroup,
  assignedUser,
  errorMessage,
  assignableUsers,
  handleChangeGroup,
  handleRemoveGroup,
  handleChangeUser,
  handleRemoveUser,
  initData: initUpdateAssignee,
  toggleSearchAll,
} = changeAssignComposable(formData, { isWorkflow: !!props.isWorkflow });

const onSaveAssign = () => {
  if (errorMessage.value && !props.canRemoveAll) return;
  emit(
    'onSave',
    assignedGroup.value,
    assignedUser.value,
    fixConflictDomain.value
  );
};

const onChangeGroup = (group) => {
  handleChangeGroup(group);
  _checkUpdateDomainAfterChangeGroup();
  if (props.isUpdateInstant) onSaveAssign();
};
const onRemoveGroup = () => {
  handleRemoveGroup();
  if (props.isUpdateInstant) onSaveAssign();
};
const onRemoveUser = () => {
  handleRemoveUser();
  if (props.isUpdateInstant) onSaveAssign();
};
const onChangeUser = (user) => {
  handleChangeUser(user);
  _checkUpdateDomainAfterChangeUser();
  if (props.isUpdateInstant) onSaveAssign();
};

// Private
const _checkUpdateDomainAfterChangeUser = () => {
  const assignedUserId = assignedUser.value?.id;

  const isNeedUpdateDomain =
    props.domainId &&
    (!currentDomain.value?.members ||
      !currentDomain.value?.members?.some(
        (user) => user?.id == assignedUserId
      ));

  const isNeedUpdateProject =
    props.projectId &&
    (!currentProject.value?.members ||
      !currentProject.value?.members?.some(
        (userId) => userId == assignedUserId
      ));
  const isNeedUpdateSubProject =
    props.subprojectId &&
    (!currentSubProject.value?.members ||
      !currentSubProject.value?.members?.some(
        (userId) => userId == assignedUserId
      ));

  fixConflictDomain.value = {
    domain: props.groupId ? false : !!isNeedUpdateDomain,
    project: !!isNeedUpdateProject,
    subProject: !!isNeedUpdateSubProject,
  };
};
const _checkUpdateDomainAfterChangeGroup = () => {
  const newGroupId = assignedGroup.value?.id;
  const isNeedUpdateDomain =
    newGroupId &&
    props.domainId &&
    (!currentDomain.value?.groups ||
      !currentDomain.value?.groups?.some((groupId) => groupId == newGroupId));
  fixConflictDomain.value = {
    domain: !!isNeedUpdateDomain,
    project: !!isNeedUpdateDomain,
    subProject: !!isNeedUpdateDomain,
  };
};
const initData = (defaultData?) => {
  const data = defaultData
    ? { groupId: defaultData?.groupId, userId: defaultData?.assigneeId }
    : props;
  formData.value = {
    groupId: data?.groupId,
    userId: data?.userId,
  };

  initUpdateAssignee(formData.value);
};

const isShowDepartment = computed(() => {
  return !assignedGroup.value?.id && departmentManagementEnabled.value;
});

initData();

defineExpose({
  initData,
});
</script>

<template>
  <SynFormInput
    :error-message="errorMessage && !isHiddenWarning ? errorMessage : ''"
  >
    <div
      class="w-full h-full flex items-center justify-between rounded relative"
      style="max-width: 24rem; height: 2.5rem"
    >
      <SynSelectAssign
        :function-code="ALL_FUNCTIONS.TASK.SET_GROUP"
        input-class="text-xs"
        :class="customClass"
        removeable
        :placeholder="$t('COMMON_LABEL_SELECT_GROUP')"
        assign-group
        type="group"
        :data="allGroup"
        :name="assignedGroup?.name"
        :avatar="assignedGroup?.avatarUrl?.replace('original', 'mini')"
        :object-contact="assignedGroup"
        :is-hidden-group="assignedGroup?.isHidden"
        @chosen="onChangeGroup"
        @reset="onRemoveGroup"
        @focus="$emit('focus')"
      />
      <SynDepartmentAssign
        v-if="isShowDepartment"
        input-class="text-xs"
        :class="customClass"
        removeable
        :is-show-department="isShowDepartment && isSearchAll"
        :placeholder="$t('COMMON_LABEL_SELECT_USER')"
        :current-id="assignedUser?.id"
        :data="assignableUsers"
        :name="assignedUser?.name"
        :avatar="assignedUser?.avatar?.replace('original', 'mini')"
        @chosen="onChangeUser"
        @reset="onRemoveUser"
        @focus="$emit('focus')"
      >
        <template #header>
          <div class="p-2 flex items-center">
            <SynRadioTag
              v-if="myDepartmentIds?.length > 0"
              v-model="isSearchAll"
              :value="false"
              name="task-create-form_schedule"
              radio-name="task-create-form_priority"
              class="flex-1 w-2/3 mr-1 mb-0.5 mt-0.5 relative"
              @change="toggleSearchAll"
            >
              <SynTag size="circle-small" custom-class="py-1 px-2">
                <DepartmentById
                  :department-id="myDepartmentIds[0]"
                  is-hidden-avatar
                />(D)
              </SynTag>
            </SynRadioTag>
            <SynRadioTag
              v-model="isSearchAll"
              :value="true"
              :label="'Department'"
              name="task-create-form_schedule"
              radio-name="task-create-form_priority"
              class="mr-1 mb-0.5 mt-0.5 relative"
              @change="toggleSearchAll"
            >
              <SynTag size="circle-small" custom-class="py-1 px-2">
                <span>
                  {{ $t('COMMON_LABEL_OTHER') }}
                </span>
              </SynTag>
            </SynRadioTag>
          </div>
        </template>
      </SynDepartmentAssign>
      <SynSelectAssign
        v-else
        input-class="text-xs"
        :class="customClass"
        removeable
        :is-show-department="isShowDepartment && isSearchAll"
        :placeholder="$t('COMMON_LABEL_SELECT_USER')"
        :current-id="assignedUser?.id"
        :data="assignableUsers"
        :name="assignedUser?.name"
        :avatar="assignedUser?.avatar?.replace('original', 'mini')"
        @chosen="onChangeUser"
        @reset="onRemoveUser"
        @focus="$emit('focus')"
      />
      <!-- <div
      v-if="errorMessage"
      class="
        absolute
        left-0
        h-8
        -bottom-8
        flex-center
        bg-gray-100
        z-10
        rounded-b-md
      "
      :class="errorMessage ? 'justify-between' : 'justify-end'"
    >
      <div v-if="errorMessage" class="px-2 text-xs text-red-500">
        {{ errorMessage }}
      </div>
    </div> -->
      <div
        v-if="!isUpdateInstant"
        class="
          absolute
          right-0
          h-8
          -bottom-8
          flex-center
          bg-gray-100
          z-10
          rounded-b-md
        "
      >
        <div class="flex-center p-1 rounded space-x-1">
          <SynIcon
            name="close"
            has-action
            color="red"
            :title="$t('COMMON_LABEL_CLOSE') || 'Close'"
            @click.stop="$emit('onCancel')"
          />
          <SynIcon
            name="check"
            has-action
            :title="$t('COMMON_LABEL_SAVE') || 'Save'"
            @click.stop="onSaveAssign"
          />
        </div>
      </div>
    </div>
  </SynFormInput>
</template>
