import BaseModel from './BaseModel';
import { ParentSource } from '../enums/FireaseDatabaseEnum';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { onChildAdded, orderByChild, query, startAt } from 'firebase/database';
import { ringingTime } from '@/domain/enums/VideoCallEnum';
import appStore from '@/store/app';

export default class UserDynamicVideoCallModel extends BaseModel {
    constructor(userId: string | number) {
        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        const sourcePath = `${ParentSource.user}/organization_${organizationId}/user_${userId}/videoCalls`;

        super(sourcePath);
    }

    subcribleHasNewCall(callback) {
        const currentTime = appStore().getCurrentTime();

        const q = query(
            this.dataRef,
            orderByChild('createdDate'),
            startAt(currentTime - ringingTime * 1000)
        );

        return onChildAdded(q, (snapshot) => {
            const videoCallObj = snapshot.val();

            callback && callback(videoCallObj);
        });
    }
}
