<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';
import FormChangePassword from './FormChangePassword.vue';
import FormSetupPassword from './FormSetupPassword.vue';
import InfoDif from './InfoDif.vue';
import ModalChangeLogin from './ModalChangeLogin.vue';
import userStore from '@/store/user';
import AlertVerifyEmail from '@/ui/components/auth/AlertVerifyEmail.vue';
import { UserCreatePayload } from '@/domain/entities/PayloadClass/UserPayloadClass';

const props = defineProps({
  userInfo: {
    type: Object,
    default: () => {},
  },
  edit: {
    type: Boolean,
    default: false,
  },
  textCancel: {
    type: String,
    default: 'COMMON_LABEL_CANCEL',
  },
  textFinish: {
    type: String,
    default: 'COMMON_LABEL_SAVE',
  },
});
const isLoading = ref(false);
const _userStore = userStore();
const userDefault = computed(() => _userStore.userProfile) as any;
const userIdentity = computed(() => _userStore.userIdentity) as any;
const user = ref({});
const newUser = ref();
const isLogin = ref(true);
const isEdit = ref(props.edit);
const isChangeEmail = ref(false);
const isChangePhone = ref(false);
const isVerifyEmail = ref(false);
const fileName = computed(() => _userStore.fileName);
const isSaveInfo = ref(false);
const isShowSetupPassword = ref(false);
const isShowChangePassword = ref(false);

watch(
  () => props.userInfo,
  () => {
    user.value = props.userInfo;
    // console.log('user.value', user.value);
  }
);

const init = async () => {
  isLoading.value = true;
  await _userStore.getProfileUserCurrent();
  await _userStore.getUserIdentity();
  user.value = userDefault.value;
  newUser.value = new UserCreatePayload(user.value);
  isVerifyEmail.value = userIdentity.value.isVerifiedEmail;
  isLoading.value = false;
};
init();

// avatar
const onSaveInfoUser = async (file, isDefaultAvatar) => {
  isSaveInfo.value = true;
  if (file) {
    try {
      await _userStore.uploadAvatarAws(file);
      newUser.value.avatar = fileName.value;
    } catch (error) {
      // console.log('error', error);
      isSaveInfo.value = false;
      return;
    }
  }

  try {
    const res = await _userStore.saveProfile(newUser.value, isDefaultAvatar);
    isSaveInfo.value = false;
    isEdit.value = false;
    if (!res.error) {
      init();
    }
  } catch (error) {
    isSaveInfo.value = false;
    isEdit.value = false;
    // console.log('error', error);
  } finally {
    isSaveInfo.value = false;
    isEdit.value = false;
  }
};

const onCancelChange = async (value, isComplete) => {
  isChangeEmail.value = value;
  isChangePhone.value = value;
  if (isComplete) {
    init();
  }
};

const onCancelVerifyEmail = () => {
  isVerifyEmail.value = !isVerifyEmail.value;
  init();
};
// password
const onSettingPWD = (hasPWD) => {
  if (hasPWD) {
    isShowChangePassword.value = true;
  } else {
    isShowSetupPassword.value = true;
  }
};

const onCancelChangePWD = async (data) => {
  isShowChangePassword.value = data;
};

const onCancelSetupPWD = async (data) => {
  isShowSetupPassword.value = data;
  init();
};
</script>
<template>
  <!-- HEADER -->
  <div
    class="
      flex
      py-3
      pl-4
      rounded-t-md
      items-center
      bg-current-50
      justify-between
    "
  >
    <p class="font-semibold text-current-500">
      {{ $t('USERMANAGEMENT_LABEL_INFOMATION') }}
    </p>
  </div>

  <!-- CONTENT BODY -->
  <div
    class="
      flex flex-col
      h-full
      flex-nowrap
      bg-white
      overflow-y-scroll
      small-scrollbar
    "
  >
    <SynTabCollapse
      class="font-medium mt-3"
      :label="$t('USERMANAGEMENT_LABEL_INFO_LOGIN')"
      @on-collapse="
        (value) => {
          isLogin = value;
        }
      "
    />
    <div v-if="isLogin" class="flex mt-4 space-x-6 mx-2">
      <!-- AVATAR -->
      <div>
        <div
          v-if="isLoading"
          class="h-32 w-32 bg-gray-200 rounded-full animate-pulse"
        ></div>
        <UploadAvatarIcon
          v-else
          :avatar-thumbnail-url="userDefault.avatarUrl"
          :name="userDefault.lastName + ' ' + userDefault.firstName"
          :is-loading="isSaveInfo"
          type-upload="USER"
          @upload-avatar-aws="onSaveInfoUser"
        />
      </div>
      <!-- INFO IDENTITY -->
      <div class="w-full pr-2">
        <!-- LOGIN -->
        <div class="my-2 flex-center space-x-2">
          <div
            class="flex items-center text-sm space-x-2 text-current-800 w-40"
          >
            <SynIcon name="user" class="fill-current" />
            <span>{{ $t('PROFILE_LABEL_LOGIN_ID') }}</span>
          </div>
          <div
            class="
              flex flex-wrap
              w-full
              font-medium
              text-sm
              border
              bg-gray-100
              rounded-md
              py-1
              px-2
              text-gray-500
            "
          >
            <span class="w-full">{{ userIdentity?.phone }}</span>
            <span>{{ userIdentity?.email }}</span>
          </div>
        </div>

        <!-- PHONE -->
        <div class="my-2 flex-center space-x-2">
          <div
            class="flex items-center text-sm space-x-2 text-current-800 w-40"
          >
            <SynIcon name="phone" class="fill-current" custom-class="w-3 h-3" />
            <span>{{ $t('USERMANAGEMENT_FORM_PHONE') }}</span>
            <div
              class="rounded-full flex-center p-0.25"
              :class="
                userIdentity.isVerifiedPhone ? 'bg-green-500' : 'bg-red-500'
              "
            >
              <SynIcon
                class="cursor-pointer fill-white text-white"
                :name="userIdentity.isVerifiedPhone ? 'check' : 'info'"
              />
            </div>
          </div>
          <div
            class="
              flex
              items-center
              justify-between
              w-full
              text-sm
              border
              bg-gray-100
              rounded-md
              py-1
              px-2
              text-gray-500
            "
          >
            <div class="flex-center space-x-2 font-medium">
              <span>{{
                userDefault?.phone || $t('USERMANAGEMENT_FORM_PHONE')
              }}</span>
            </div>
            <div class="w-8 h-8 rounded-full flex-center hover:bg-current-100">
              <SynIcon
                name="edit"
                is-active
                custom-class="w-6 h-6 p-1 fill-current cursor-pointer"
                @click="isChangePhone = !isChangePhone"
              />
            </div>
          </div>
        </div>

        <!-- EMAIL -->
        <div class="my-2 flex-center space-x-2">
          <div
            class="flex items-center text-sm space-x-2 text-current-800 w-40"
          >
            <SynIcon name="email" class="fill-current" custom-class="w-3 h-3" />
            <span>{{ $t('USERMANAGEMENT_TABLE_EMAIL') }}</span>
            <div
              class="rounded-full flex-center p-0.25"
              :class="
                userIdentity.isVerifiedEmail ? 'bg-green-500' : 'bg-red-500'
              "
            >
              <SynIcon
                class="cursor-pointer fill-white text-white"
                :name="userIdentity.isVerifiedEmail ? 'check' : 'info'"
              />
            </div>
          </div>
          <div
            class="
              flex
              items-center
              justify-between
              w-full
              text-sm
              border
              bg-gray-100
              rounded-md
              py-1
              px-2
              text-gray-500
            "
          >
            <div class="flex-center space-x-2 font-medium">
              <span>{{
                userDefault?.email || $t('COMMON_LABEL_ALERT_NOT_EMAIL')
              }}</span>
            </div>
            <div class="w-8 h-8 rounded-full flex-center hover:bg-current-100">
              <SynIcon
                :name="userDefault.email ? 'edit' : 'plus'"
                is-active
                custom-class="w-6 h-6 p-1 fill-current cursor-pointer"
                @click="isChangeEmail = !isChangeEmail"
              />
            </div>
          </div>
        </div>

        <!-- VERIFY EMAIL -->
        <div
          v-if="!userIdentity?.isVerifiedEmail && userIdentity?.email"
          class="flex-center"
        >
          <div class="flex items-center space-x-2 text-sm w-40 mr-1.5"></div>
          <AlertVerifyEmail size="small" @cancel="onCancelVerifyEmail()" />
        </div>

        <!-- PASSWORD -->
        <div class="my-2 flex-center space-x-2">
          <div
            class="flex items-center space-x-2 text-sm text-current-800 w-40"
          >
            <SynIcon name="lock" class="fill-current" />
            <span>{{ $t('LOGIN_FORM_PASSWORD') }}</span>
          </div>
          <SynButton
            class="w-full text-sm m-auto"
            type-outline
            :label="
              !userDefault.hasPassword
                ? $t('PROFILE_SET_UP_PASSWORD')
                : $t('USERMANAGEMENT_LABEL_CHANGE_PASSWORD')
            "
            @click="onSettingPWD(userDefault.hasPassword)"
          />
        </div>
      </div>
    </div>
    <InfoDif :is-loading="isLoading" :user-info="userDefault" />
  </div>

  <FormChangePassword
    v-if="isShowChangePassword"
    :email="userDefault.email"
    @cancel="(data) => onCancelChangePWD(data)"
  />

  <FormSetupPassword
    v-if="isShowSetupPassword"
    :email="userDefault.email"
    :admin-type="userDefault.adminType"
    :is-email-verify="userIdentity.isVerifiedEmail"
    @cancel="(data) => onCancelSetupPWD(data)"
  />

  <ModalChangeLogin
    v-if="isChangeEmail || isChangePhone"
    :login="isChangeEmail ? userDefault.email : userDefault.phone"
    :type="isChangeEmail ? 'EMAIL' : 'PHONE'"
    @cancel="(value) => onCancelChange(value, false)"
    @complete="(value) => onCancelChange(value, true)"
  />
</template>
