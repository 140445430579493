<template>
  <teleport to="body">
    <div
      class="
        z-100
        w-screen
        h-screen
        bg-gray-500 bg-opacity-50
        fixed
        top-0
        flex flex-col
        justify-center
        items-center
      "
      @click.self="$emit('closePreview')"
    >
      <div
        class="
          flex-1
          overflow-auto
          flex
          items-center
          w-full
          justify-center
          relative
        "
        @click.self="$emit('closePreview')"
      >
        <syn-img
          v-if="isImage"
          :src="src"
          class="max-h-9/10 m-auto ring-8 ring-gray-600"
        ></syn-img>
        <iframe
          v-else
          :src="src"
          class="h-3/4 md:h-full w-3/4 bg-white"
        ></iframe>
        <div
          class="
            h-10
            w-10
            rounded-full
            bg-gray-800
            hover:bg-gray-600
            text-white
            absolute
            top-2
            right-2
            cursor-pointer
            text-center
          "
          @click="$emit('closePreview')"
        >
          <SynIcon name="close" class="w-full h-full" />
        </div>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    isImage: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['closePreview'],
});
</script>
