<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import VirtualFrameCardUser from '@/ui/components/virtual-frames/VirtualFrameCardUser.vue';
import GridViewPendingUser from '@/ui/modules/overview/GridViewPendingUser.vue';
import WorkingTimeCard from '@/ui/modules/overview/WorkingTimeCard.vue';
import UserInfoCard from '@/ui/modules/overview/UserInfoCard.vue';
import { getUserStatus, taskListRouteByType } from '@/ui/hooks/commonHook';
import taskStore from '@/store/task';
import settingStore from '@/store/setting';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import FavoriteButton from '@/ui/components/user/FavoriteButton.vue';
import overviewTeamStore from '@/store/dashboard/overview-team-store';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import ActionById from '@/ui/components/user/ActionById.vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { isOpenModalInvite } from '@/ui/app-global-state';
import organizationStore from '@/store/organization';
import taskTabViewStore from '@/store/task/task-tab-view';
import { EFilterTaskDefault } from '@/application/constants/task.const';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { ILatestTaskActivity } from '@/ui/plugins/firebases/realtime-database/types/organization-dynamic-model.types';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  departmentId?: number;
  isHiddenTask?: boolean;
  isHiddenWorkingStatus?: boolean;
  isHiddenWorkingTime?: boolean;
  isHiddenPersonalData?: boolean;
  isShowPendingUsers?: boolean;
}>();
const router = useRouter();

const _taskStore = taskStore();
const _settingStore = settingStore();
const _overviewTeamStore = overviewTeamStore();
const _calendarsOverviewStore = calendarsOverviewStore();
const _organizationStore = organizationStore();
const _taskTabViewStore = taskTabViewStore();

const timeWorkingEnabled = computed(() => _settingStore.timeWorkingEnabled);
const overviewTeamUserIds: any = computed(
  () => _overviewTeamStore.overviewTeamUserIds
);
const overviewTeamInfo: any = computed(
  () => _overviewTeamStore.overviewTeamInfo
);
const overviewTeamTasks: any = computed(
  () => _overviewTeamStore.overviewTeamTasks
);
const listMemberOffWork: any = computed(
  () => _calendarsOverviewStore.listMembersOffWorkOnday
);
const isLoadingMemberTask = computed(
  () => _overviewTeamStore.isLoadingMemberTask
);
const searchText = computed(() => _overviewTeamStore.keySearchTeam);
const isFetchPending = computed(() => _overviewTeamStore.isFetchPending);
const isHiddenTaskView = computed(() => {
  if (props.isHiddenTask) return true;
  return _overviewTeamStore.isHiddenTask;
});
const filterMembers = computed(() => {
  let result = overviewTeamUserIds.value;

  const searchTxt = ignoreUnicode(searchText.value);
  if (searchTxt)
    result = (result.value || []).filter((userId) => {
      const memberName1 = ignoreUnicode(
        overviewTeamInfo.value[userId].userInfo.firstName +
          ' ' +
          overviewTeamInfo.value[userId].userInfo.lastName
      );
      const memberName2 = ignoreUnicode(
        overviewTeamInfo.value[userId].userInfo.lastName +
          ' ' +
          overviewTeamInfo.value[userId].userInfo.firstName
      );
      return memberName1.includes(searchTxt) || memberName2.includes(searchTxt);
    });

  return arrayOrderBy(
    result,
    [
      (u) => (u?.workInfo?.online ? 1 : 2),
      (u) => u?.workInfo?.startIndex,
      (u) => ignoreUnicode(u?.firstName),
    ],
    ['asc', 'asc', 'asc']
  );
});

const loadingMore = ref(null);
const isShowProfile = ref(false);
const currentUserId = ref(0);
const isColumnLayout = ref(false);
const totalListPending = computed(
  () => _organizationStore._pendingUsers?.length
);

let { unsubscribeCallbacks } = listenFirebaseRealtime();
onMounted(async () => {
  await _calendarsOverviewStore.getMembersOffWorkByDate();
  // pendingUsers.value = await _organizationStore.getPendingUsers();

  const LatestTaskActivityFirebaseModel = new OrganizationDynamicModel(
    `latestTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    LatestTaskActivityFirebaseModel.onChange((data: ILatestTaskActivity) => {
      _overviewTeamStore.updateUserTasksRealtime(
        data?.action,
        data?.taskDetail
      );
    })
  );
});

const getClassByWorkInfo = (overview) => {
  const status = getUserStatus(overview.workInfo);
  if (status == 'online') return 'bg-green-500';
  if (status == 'absent') return 'bg-orange-400';
  if (status == 'offline') return 'bg-gray-400';
  return '';
};

const onSeeMore = async (userId) => {
  if (!userId) return;
  if (overviewTeamTasks.value[userId].isShowMore) {
    _overviewTeamStore.overviewTeamTasks[userId].isShowMore = false;
  } else {
    loadingMore.value = userId;

    const res = await _taskStore.fetchTaskDefaultByUserId(userId);
    if (!res || !res.result) return;
    _overviewTeamStore.setOverviewTeamTaskMore(userId, res.result);
  }
  loadingMore.value = null;
};

const chooseUser = (userId) => {
  if (!userId) return;
  router.push(taskListRouteByType('USER', userId));
};

const chooseTabOverdueByUser = (userId) => {
  if (!userId) return;
  _taskTabViewStore.updateDefaultTab(EFilterTaskDefault.OVERDUE_DATE);
  router.push(taskListRouteByType('USER', userId));
};

const onMouserOver = (userId) => {
  _overviewTeamStore.setFullTaskForMember(userId);
};
</script>

<template>
  <section class="w-full h-full relative flex flex-col">
    <div class="relative flex-1 min-h-0 pt-2">
      <!-- SEARCH NOT FOUND     -->
      <div v-if="filterMembers.length < 1" class="py-8">
        <div v-if="searchText" class="py-16 h-full w-full text-center">
          <syn-animation
            name="searchIcon"
            :loop="false"
            stype="width: 200px; height: 200px;"
          />
          <span class="font-medium text-lg text-gray-600">{{
            $t('COMMON_LABEL_ALERT_NO_SEARCH')
          }}</span>
        </div>
        <div v-else class="py-16 h-full w-full flex-center">
          <VigImage
            :path="'https://d1tvqetrcurhzb.cloudfront.net/common/images/all-task-grouplist.png'"
            :custom-class="'h-42 w-96'"
          />
        </div>
      </div>

      <!--   HAVE MEMBER   -->
      <div
        class="
          pt-6
          px-6
          w-full
          h-full
          grid
          auto-rows-max
          overflow-auto
          small-scrollbar
        "
        :style="
          !departmentId && isHiddenTaskView
            ? 'grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr))'
            : 'grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr))'
        "
      >
        <div
          v-for="userId in filterMembers"
          :key="userId"
          class="w-full px-4 pb-8 relative group/card"
          :style="`height:${isColumnLayout ? '40rem' : '20rem'};`"
          @mouseover="onMouserOver(userId)"
        >
          <div
            v-if="timeWorkingEnabled && !isHiddenWorkingStatus"
            class="
              w-6
              h-6
              top-0
              left-1
              rounded-full
              absolute
              flex-center
              font-semibold
              text-white
              z-10
            "
            :class="getClassByWorkInfo(overviewTeamInfo[userId])"
          >
            {{ overviewTeamInfo[userId]?.workInfo?.startIndex }}
          </div>
          <div class="w-full h-full rounded-lg bg-white shadow flex divide-x">
            <div
              class="
                h-full
                flex flex-col
                justify-between
                items-center
                pb-2
                relative
                rounded-l-lg
              "
              :class="!departmentId && isHiddenTaskView ? 'w-full' : 'w-1/3'"
            >
              <div
                v-if="!isHiddenTask"
                class="
                  bg-white
                  flex-center
                  rounded-full
                  m-1
                  absolute
                  right-0
                  shadow-lg
                  top-0
                  w-7
                  h-7
                  hover-to-show__children
                  cursor-pointer
                  z-20
                "
              >
                <FavoriteButton :ref-id="userId" type="member" />
              </div>
              <div
                class="
                  w-full
                  flex-1 flex
                  items-center
                  flex-col
                  hover-to-show__parent
                  pt-6
                  p-2
                "
              >
                <UserInfoCard
                  :user-info="overviewTeamInfo[userId]?.userInfo"
                  :is-hidden-info-detail="isHiddenPersonalData"
                  :search-text="searchText"
                  @click-name="chooseUser(userId)"
                />
                <div
                  v-if="overviewTeamTasks[userId]?.extendData?.overdueTasks > 0"
                  class="flex-center"
                >
                  <span
                    class="
                      text-xs
                      w-max
                      rounded-full
                      px-2
                      py-1
                      bg-orange-100
                      cursor-pointer
                      text-orange-600
                    "
                    @click="chooseTabOverdueByUser(userId)"
                  >
                    {{
                      $t('TASK_LABEL_OVERDUE_TASK', {
                        total:
                          overviewTeamTasks[userId]?.extendData?.overdueTasks,
                        suffix:
                          overviewTeamTasks[userId]?.extendData?.overdueTasks >
                          1
                            ? 's'
                            : '',
                      })
                    }}
                  </span>
                </div>
                <WorkingTimeCard
                  v-if="timeWorkingEnabled && !isHiddenWorkingTime"
                  :work-info="overviewTeamInfo[userId]?.workInfo"
                  :user-info="overviewTeamInfo[userId]?.userInfo"
                  :work-off-info="
                    listMemberOffWork?.find((el) => el?.userId === userId)
                  "
                />
              </div>
              <div
                class="
                  items-center
                  justify-center
                  space-x-2
                  mb-2
                  hidden
                  group-hover/card:flex
                "
              >
                <ActionById
                  v-if="userId"
                  :contact-id="userId"
                  :types="
                    isHiddenTask
                      ? ['chat', 'create-task']
                      : ['list-task', 'chat', 'create-task']
                  "
                />
              </div>
            </div>
            <div
              v-if="departmentId || !isHiddenTaskView"
              class="flex-1 h-full overflow-y-auto small-scrollbar relative"
            >
              <div
                v-if="isLoadingMemberTask"
                class="
                  absolute
                  top-0
                  left-0
                  w-full
                  h-full
                  flex-center
                  space-y-2
                  pt-5
                "
              >
                <div class="flex-center space-x-2">
                  <div class="h-3 w-3 bg-current-100 rounded-full"></div>
                  <div class="h-3 w-3 bg-current-100 rounded-full"></div>
                  <div class="h-3 w-3 bg-current-100 rounded-full"></div>
                  <div class="h-3 w-3 bg-current-100 rounded-full"></div>
                </div>
              </div>
              <div v-else>
                <template
                  v-if="overviewTeamTasks[userId]?.overviewTaskList?.length > 0"
                >
                  <TaskCardItem
                    v-for="task in overviewTeamTasks[userId].overviewTaskList"
                    :key="task"
                    :task-detail="task"
                    class="border-b"
                    readonly
                  />
                </template>
                <div v-else class="bg-gray-50 py-4 rounded-lg m-2">
                  <div class="text-center w-full text-sm px-12 text-gray-500">
                    {{
                      $t('TASK_LABEL_ALERT_NO_TASK', {
                        member: overviewTeamInfo[userId]?.userInfo?.firstName,
                      })
                    }}
                  </div>
                </div>
                <div
                  v-if="overviewTeamTasks[userId]?.extendData?.hasShowMore"
                  class="
                    flex-center
                    text-gray-500 text-sm
                    py-1
                    space-x-2
                    hover:text-current-400 hover:bg-current-50
                    cursor-pointer
                    relative
                  "
                  style="border-bottom-width: 1px"
                  @click="onSeeMore(userId)"
                >
                  <SynIcon
                    :name="
                      overviewTeamTasks[userId]?.isShowMore
                        ? 'chevron-double-up'
                        : 'chevron-double-down'
                    "
                  />
                  <span class="font-semibold">
                    {{
                      overviewTeamTasks[userId]?.isShowMore
                        ? $t('TASK_CREATE_FORM_SHOW_LESS') || 'Show less'
                        : $t('TASK_CREATE_FORM_SHOW_MORE') || 'Show more'
                    }}
                  </span>
                  <syn-local-loading v-if="loadingMore == userId" />
                </div>
                <template v-if="overviewTeamTasks[userId]?.isShowMore">
                  <template
                    v-if="
                      overviewTeamTasks[userId]?.overviewTaskListSeemore
                        ?.length > 0
                    "
                  >
                    <TaskCardItem
                      v-for="task in overviewTeamTasks[userId]
                        ?.overviewTaskListSeemore"
                      :key="task"
                      class="border-b"
                      :task-detail="task"
                      readonly
                    />
                  </template>
                  <div
                    v-else
                    class="w-full flex flex-col justify-center items-center"
                  >
                    <div class="w-12 h-12">
                      <SynIcon name="nodata" />
                    </div>
                    <span> No data </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <template v-if="!searchText && isShowPendingUsers">
          <!-- Pending User -->
          <GridViewPendingUser
            v-if="
              overviewTeamUserIds.length < 5 &&
              $permission('FunctionOrganizationInvitation')
            "
            :user-number="overviewTeamUserIds.length"
            :is-column-layout="isColumnLayout"
            :is-fetch-invited="isFetchPending"
            :is-hidden-detail="isHiddenTaskView"
          />
          <!-- Virtual frames -->
          <VirtualFrameCardUser
            v-if="
              overviewTeamUserIds.length < 6 &&
              $permission('FunctionOrganizationInvitation')
            "
            v-permission="'FunctionOrganizationInvitation'"
            :is-column-layout="isColumnLayout"
            :is-show-notification="totalListPending > 4"
            :is-hidden-detail="isHiddenTaskView"
            @on-invite="isOpenModalInvite = true"
          />
        </template>
      </div>
    </div>
  </section>

  <ModalProfileUser
    v-if="isShowProfile"
    :profile-id="currentUserId"
    @cancel="isShowProfile = false"
  />
</template>
