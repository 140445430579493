import {
    createRouter,
    // createWebHashHistory,
    createWebHistory,
} from 'vue-router';
import publicRoutes from '@/ui/router/public-routes';
import notFoundRoutes from '@/ui/router/not-found-routes';
import dynamicRoutes from '@/ui/router/dynamic-routes';
import privateRoutes from '@/ui/router/private-routes';
import chatEmbedRoutes from '@/ui/router/chat-embed-routes';
import sharedCalendarRoutes from '@/ui/router/shared-routes';
import { hasPermission } from '@/ui/hooks/permission/permission-by-role';
import App from '@/ui/App.vue';
import AppEmbedChat from '@/ui/AppEmbedChat.vue';
import AppShared from '@/ui/AppShared.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: App,
            name: 'MainApp',
            children: publicRoutes,
        },
        {
            path: '/embed-chat',
            name: 'EmbedChatApp',
            component: AppEmbedChat,
            children: chatEmbedRoutes,
        },
        {
            path: '/shared',
            name: 'SharedApp',
            component: AppShared,
            children: sharedCalendarRoutes,
        },
    ],
});
export let fullRouteByPermission = [] as any[];

export const filterRoutes = () => {
    const routes = getRouterByPermission(privateRoutes);

    const mainAppRoute = router.options.routes.find(
        (route) => route.name === 'MainApp'
    );

    if (mainAppRoute?.children) {
        mainAppRoute.children = (mainAppRoute?.children || [])
            .concat(routes)
            .concat(notFoundRoutes);

        router.removeRoute('MainApp');
        router.addRoute(mainAppRoute);
    }

    fullRouteByPermission = [
        ...fullRouteByPermission,
        ...routes,
        ...notFoundRoutes,
    ];
};

export const filterRoutesAsync = async () => {
    const routes = getRouterByPermission(dynamicRoutes);

    const mainAppRoute = router.options.routes.find(
        (route) => route.name === 'MainApp'
    );

    if (mainAppRoute) {
        mainAppRoute.children = (mainAppRoute?.children || []).concat(routes);

        router.removeRoute('MainApp');
        router.addRoute(mainAppRoute);
    }

    fullRouteByPermission = [...fullRouteByPermission, ...routes];
};

const getRouterByPermission = (routes) => {
    const res = [] as any[];
    routes.forEach((route) => {
        const tmp = { ...route };
        const hasPer = hasPermission(tmp.name);

        if (hasPer) {
            if (tmp.children) {
                tmp.children = getRouterByPermission(tmp.children);
            }
            res.push(tmp);
        }
    });
    return res;
};
export default router;
