<script setup lang="ts">
import helpComposables from '@/ui/composables/help-contact/help-composables';
import TutorialOnYoutubeSlide from '@/ui/components/contact-help/helps/TutorialOnYoutubeSlide.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import ItemListCollapse from '@/ui/components/contact-help/helps/ItemListCollapse.vue';
import remoteConfigStore from '@/store/remoteConfig';
import { computed, nextTick } from 'vue';
import appStore from '@/store/app';

defineProps({
  sizeActive: {
    type: Object,
    default: () => {},
  },
});
defineEmits(['onChangeTab']);

const _remoteConfigStore = remoteConfigStore();
const _appStore = appStore();

const currentLanguage = computed(() => _appStore.language);
const webShowHelpTopVideo = computed(
  () => _remoteConfigStore.webShowHelpTopVideo
);
const webShowHelpFqa = computed(() => _remoteConfigStore.webShowHelpFqa);
const webShowHelpVideos = computed(() => _remoteConfigStore.webShowHelpVideos);
const webShowHelpWhatNew = computed(
  () => _remoteConfigStore.webShowHelpWhatNew
);

const {
  loadings,
  videoSpecialList,
  foldersByIds,
  // allFaqs,
  // allTopics,
  // allTopicsByIds,
  allWhatNews,
  allSpecialFaqByIds,
  initDataHome,
  onShowMiniPlayer,
  onShowFilesByFolder,
  onExpandVideo,
  onShowAnswerByQuestionSpecial,
} = helpComposables();

initDataHome();

const checkOpenFolder = async (folderId, videoActive) => {
  await nextTick();
  await onExpandVideo(folderId, videoActive);
};

defineExpose({
  checkOpenFolder,
});
</script>

<template>
  <template v-if="webShowHelpTopVideo">
    <template v-if="loadings.video_special">
      <div
        :class="`px-3 h-40 w-full bg-gray-100 rounded`"
        class="animate-pulse"
      ></div>
    </template>

    <TutorialOnYoutubeSlide
      v-else
      class="px-3"
      is-slide
      :size-panel="sizeActive"
      :autoplay="true"
      :all-video="videoSpecialList?.map((o) => o.resources)"
      @on-show-mini-player="(video) => onShowMiniPlayer('special', video)"
    />
  </template>

  <!-- WHAT NEW -->
  <template v-if="webShowHelpWhatNew">
    <div class="flex items-center font-medium px-3">
      <div
        class="flex items-center space-x-2 pt-4 font-medium text-current-400"
      >
        <syn-icon
          name="magic-wand"
          custom-class="w-4 h-4 fill-current-300"
        ></syn-icon>
        <span>{{ "What's new ? " }}</span>
      </div>
    </div>
    <div class="flex flex-col text-sm text-gray-600 px-3">
      <template v-for="item in allWhatNews" :key="item">
        <div
          v-if="item?.title[currentLanguage]"
          class="flex space-x-2 items-start px-3 py-2 justify-between"
        >
          <div class="flex space-x-2 items-start">
            <div
              class="
                text-white
                rounded-full
                bg-orange-400
                w-4
                h-4
                flex-center
                text-xs
                mt-1
              "
            >
              <span class="-mt-0.5">★</span>
            </div>
            <div class="flex-1 italic">{{ item?.title[currentLanguage] }}</div>
          </div>
          <div
            v-if="item?.links"
            v-vig-tooltip="$t('COMMON_LABEL_VIEW_VIDEO')"
            class="
              w-5
              h-5
              flex-center
              cursor-pointer
              fill-current-300
              hover:fill-current-500
            "
            @click="onShowMiniPlayer('What_new', item?.links?.explanation)"
          >
            <syn-icon name="video-player" custom-class="w-4 h-4"></syn-icon>
          </div>
        </div>
      </template>
    </div>
  </template>
  <!-- END WHAT NEW -->

  <!-- FQA -->
  <template v-if="webShowHelpFqa">
    <div class="flex items-center pt-6 font-medium justify-between px-3">
      <div class="flex items-center space-x-2 font-medium text-current-400">
        <syn-icon
          name="feature-question-answer"
          custom-class="w-4 h-4 fill-current-300"
        ></syn-icon>
        <span>{{ $t('HELP_VIDEO_FQA') }}</span>
      </div>
      <div
        class="
          w-max
          rounded
          p-1
          flex-center
          space-x-1
          hover:bg-gray-100
          cursor-pointer
          fill-gray-500
          text-gray-500
        "
        @click="$emit('onChangeTab', { key: 'FQA' })"
      >
        <span class="text-2xs">{{ $t('NOTIFICATION_SEE_MORE') }}</span>
        <syn-icon name="arrow-right" />
      </div>
    </div>
    <div class="flex flex-col pt-1">
      <template v-for="item in Object.values(allSpecialFaqByIds)" :key="item">
        <ItemListCollapse
          v-if="
            item?.question[currentLanguage] && item?.answer[currentLanguage]
          "
          :is-collapse="item?.isCollapse"
          icon-prefix="text-question"
          :name="item?.question[currentLanguage]"
          @on-collapse="onShowAnswerByQuestionSpecial(item?.id)"
        >
          <template #title>
            <div class="flex flex-col">
              <span>{{ item?.question[currentLanguage] }}</span>
            </div>
          </template>
          <template v-if="item?.isCollapse" #body>
            <div class="p-3 text-sm text-gray-600 bg-gray-100 flex flex-col">
              <span class="text-sm">
                <SynIcon
                  name="text-answer"
                  custom-class="w-5 h-5 fill-gray-500 mb-1"
                />
                <span
                  class="ck_editor_view"
                  v-html="`: ${item?.answer[currentLanguage]}`"
                ></span>
              </span>
              <!--              <span-->
              <!--                  class="-->
              <!--                  text-2xs-->
              <!--                  rounded-->
              <!--                  px-1-->
              <!--                  bg-current-100 bg-opacity-50-->
              <!--                  text-current-600-->
              <!--                  font-medium-->
              <!--                  w-max-->
              <!--                  mt-1-->
              <!--                "-->
              <!--              >{{ `# ${item?.topicName}` }}</span-->
              <!--              >-->
            </div>
          </template>
        </ItemListCollapse>
      </template>
    </div>
  </template>
  <!-- END FQA -->

  <!-- VIDEO -->
  <template v-if="webShowHelpVideos">
    <div class="flex items-center pt-6 font-medium justify-between px-3">
      <div class="flex items-center space-x-2 font-medium text-current-400">
        <syn-icon
          name="video-player"
          custom-class="w-4 h-4 fill-current-300"
        ></syn-icon>
        <span>{{ $t('HELP_VIDEO_LABEL') }}</span>
      </div>
      <!--    <div-->
      <!--      class="-->
      <!--        w-6-->
      <!--        h-6-->
      <!--        rounded-->
      <!--        flex-center-->
      <!--        hover:bg-gray-100-->
      <!--        cursor-pointer-->
      <!--        fill-gray-500-->
      <!--      "-->
      <!--      @click="$emit('onChangeTab', { key: 'VIDEO' })"-->
      <!--    >-->
      <!--      <syn-icon name="dots-vertical" class="rotate-90" />-->
      <!--    </div>-->
    </div>
    <div class="flex flex-col pt-1">
      <template v-for="folder in Object.values(foldersByIds)" :key="folder">
        <ItemListCollapse
          :is-collapse="folder?.isCollapse"
          :name="folder?.name"
          :icon-prefix="'video-tutorial'"
          @on-collapse="onShowFilesByFolder(folder?.id)"
        >
          <template
            v-if="folder?.isCollapse && folder?.videos?.length > 0"
            #body
          >
            <div
              v-if="folder?.isLoading"
              :class="`h-40 w-full bg-gray-100 rounded`"
              class="animate-pulse"
            ></div>
            <TutorialOnYoutubeSlide
              v-else
              class="p-3"
              :size-panel="sizeActive"
              :all-video="folder?.videos.map((o) => o.resources)"
              @on-show-mini-player="
                (video) => onShowMiniPlayer(folder?.id, video)
              "
            />
          </template>
        </ItemListCollapse>
      </template>
    </div>
  </template>
  <!-- END VIDEO -->
</template>
