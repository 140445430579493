export enum GroupType {
    NORMAL = 'NORMAL',
    DOMAIN = 'DOMAIN',
    CHAT = 'CHAT',
}

export enum GroupMemberRole {
    ADMIN = 1,
    MEMBER = 2,
}
