<script setup lang="ts">
import { computed } from 'vue';
import {
  colorByAlphabet,
  convertViToEn,
  cleanString,
} from '@/ui/common/molecules/SynAvatar/colorByAlphabet';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import dayjs from 'dayjs';
import { formatTaskCode, taskStates } from '@/ui/hooks/taskHook';
import { ETaskListModule } from '@/application/types/task/task.types';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { TaskUrgency, getUrgencyValue } from '@/ui/hooks/taskHook';

const props = withDefaults(
  defineProps<{
    data: any;
    sourceType: ETaskListModule;
    isTimeBlocking?: boolean;
    allDay?: boolean;
    timeZone?: string;
  }>(),
  {
    timeZone: 'Asia/Ho_Chi_Minh',
  }
);
const _groupStore = groupStore();

const textAva = computed(() => {
  // if (!props?.data?.userId) return 'A';
  // return '';
  return cleanString(assigneeInfo.value?.avatar)
    .split(' ')
    [cleanString(assigneeInfo.value?.avatar).split(' ').length - 1].charAt(0);
});
const allGroupByIds = computed(() => {
  return {
    ..._groupStore.allGroupByIds,
    ..._groupStore.myGroupByIds,
  };
});

const assigneeInfo = computed<any>(() => {
  if (props?.data?.assigneeId)
    return userStore().allUserByIds[props?.data?.assigneeId];

  return allGroupByIds.value[props?.data?.groupId];
});

const overdueDay = computed<number>(() => {
  return props?.data?.scheduleTime
    ? dayjs().diff(dayjs(props?.data?.scheduleTime), 'd')
    : 0;
});

const countdownContainerId = computed<string>(() => {
  return `countdown-container-id_${props.data?.id}`;
});
const remainTimeId = computed<string>(() => {
  return `countdown-id_remain-time_${props.data?.id}`;
});

const urgencyValue = computed<any>(() => {
  if (props.data?.urgency) return props.data?.urgency;

  return getUrgencyValue(props.data?.priority, props.data?.important);
});
</script>
<template>
  <div
    class="
      flex
      justify-between
      items-start
      space-x-1
      px-1.5
      rounded
      cursor-pointer
      w-full
      h-full
      text-xs
      shadow-sm
    "
    :class="[taskStates[data?.taskLife]?.background]"
    :style="{
      paddingTop: isTimeBlocking && !allDay ? '0.25rem' : '0.5rem',
      paddingBottom: isTimeBlocking && !allDay ? '0' : '0.5rem',
    }"
  >
    <div class="w-full flex-center space-x-2 truncate">
      <template v-if="sourceType !== ETaskListModule.USER">
        <div
          v-if="assigneeInfo?.avatar"
          class="rounded-full overflow-hidden w-5 h-5 shadow-sm flex-center"
        >
          <img
            class="w-5 h-5 object-cover rounded-full"
            :src="assigneeInfo?.avatar"
          />
        </div>
        <div
          v-else
          class="
            object-cover
            flex-center
            text-white
            shadow
            font-thin
            rounded-full
            w-5
            h-5
          "
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
        >
          <span v-if="textAva" class="uppercase" style="font-size: 0.75em">{{
            textAva
          }}</span>
        </div>
      </template>
      <div class="h-4 w-4">
        <div
          v-if="urgencyValue == TaskUrgency.UrgentImportant.key"
          v-vig-tooltip="
            translate('TASK_CREATE_FORM_URGENT_IMPORTANT') ||
            'URGENT & IMPORTANT'
          "
          class="h-full w-full bg-red-300 flex-center rounded-full"
        >
          <svg
            id="Capa_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 970.504 970.503"
            style="enable-background: new 0 0 970.504 970.503"
            xml:space="preserve"
            class="w-2 h-2 fill-red-500"
          >
            <g>
              <path
                d="M120.027,962.802c26.6,0,53.5-8.801,75.7-27l288.1-234.7l290.899,237c22.301,18.1,49.101,27,75.7,27
		c34.8,0,69.4-15.101,93.101-44.2c41.899-51.4,34.1-127-17.2-168.8l-366.7-298.8c-44.1-36-107.5-36-151.6,0l-363.8,296.5
		c-51.4,41.8-59.1,117.399-17.3,168.8C50.727,947.702,85.227,962.802,120.027,962.802z"
              />
              <path
                d="M120.027,541.902c26.6,0,53.5-8.8,75.7-27l288.1-234.7l290.899,237c22.301,18.101,49.101,27,75.7,27
		c34.8,0,69.4-15.1,93.101-44.2c41.899-51.399,34.1-127-17.2-168.8l-366.7-298.8c-44.1-36-107.5-36-151.6,0l-363.8,296.4
		c-51.4,41.9-59.1,117.5-17.3,168.9C50.727,526.802,85.227,541.902,120.027,541.902z"
              />
            </g>
          </svg>
        </div>
        <div
          v-if="urgencyValue == TaskUrgency.Urgent.key"
          v-vig-tooltip="translate('TASK_CREATE_FORM_URGENT') || 'URGENT'"
          class="h-full w-full bg-blue-200 flex-center rounded-full"
        >
          <svg
            id="Capa_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 970.504 970.503"
            style="enable-background: new 0 0 970.504 970.503"
            xml:space="preserve"
            class="w-2 h-2 fill-red-500"
          >
            <g>
              <path
                d="M120.027,962.802c26.6,0,53.5-8.801,75.7-27l288.1-234.7l290.899,237c22.301,18.1,49.101,27,75.7,27
		c34.8,0,69.4-15.101,93.101-44.2c41.899-51.4,34.1-127-17.2-168.8l-366.7-298.8c-44.1-36-107.5-36-151.6,0l-363.8,296.5
		c-51.4,41.8-59.1,117.399-17.3,168.8C50.727,947.702,85.227,962.802,120.027,962.802z"
              />
              <path
                d="M120.027,541.902c26.6,0,53.5-8.8,75.7-27l288.1-234.7l290.899,237c22.301,18.101,49.101,27,75.7,27
		c34.8,0,69.4-15.1,93.101-44.2c41.899-51.399,34.1-127-17.2-168.8l-366.7-298.8c-44.1-36-107.5-36-151.6,0l-363.8,296.4
		c-51.4,41.9-59.1,117.5-17.3,168.9C50.727,526.802,85.227,541.902,120.027,541.902z"
              />
            </g>
          </svg>
        </div>
        <div
          v-if="urgencyValue == TaskUrgency.Important.key"
          v-vig-tooltip="translate('TASK_CREATE_FORM_IMPORTANT') || 'IMPORTANT'"
          class="h-full w-full flex-center rounded-full bg-blue-50"
        >
          <svg
            id="Capa_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 438.536 438.536"
            style="enable-background: new 0 0 438.536 438.536"
            xml:space="preserve"
            class="fill-red-500 h-2 w-2"
          >
            <g>
              <path
                d="M414.41,24.123C398.333,8.042,378.963,0,356.315,0H82.228C59.58,0,40.21,8.042,24.126,24.123
		C8.045,40.207,0.003,59.576,0.003,82.225v274.084c0,22.647,8.042,42.018,24.123,58.102c16.084,16.084,35.454,24.126,58.102,24.126
		h274.084c22.648,0,42.018-8.042,58.095-24.126c16.084-16.084,24.126-35.454,24.126-58.102V82.225
		C438.532,59.576,430.49,40.204,414.41,24.123z"
              />
            </g>
          </svg>
        </div>
        <div
          v-if="urgencyValue == TaskUrgency.Cool.key"
          v-vig-tooltip="translate('TASK_CREATE_FORM_COOL') || 'COOL'"
          class="h-full w-full flex-center rounded-full bg-blue-200"
        >
          <!-- <SynIcon class="fill-blue-500" name="square" custom-class="w-2 h-2" /> -->
        </div>
      </div>

      <!-- <span class="text-gray-800"> {{ data?.userName }} Xin nghỉ </span> -->
      <div class="flex-1 flex items-center gap-2 truncate">
        <span
          :class="[
            overdueDay > 0
              ? 'text-red-500'
              : overdueDay == 0
              ? 'text-orange-500'
              : 'text-gray-500',
          ]"
        >
          {{ formatTaskCode(data?.code) }}
        </span>
        <span
          :title="data?.name"
          class="truncate"
          :style="{
            color: '#000',
          }"
        >
          {{ data?.name }}
        </span>
      </div>
      <div v-if="isTimeBlocking" class="flex-center gap-2">
        <div
          :id="countdownContainerId"
          class="flex-center gap-1 text-center text-current-500 hidden"
        >
          <div class="flex-center h-4 w-4 rounded-full bg-current-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              width="163.861px"
              height="163.861px"
              viewBox="0 0 163.861 163.861"
              style="enable-background: new 0 0 163.861 163.861"
              xml:space="preserve"
              class="w-2 h-2 fill-white text-white"
            >
              <g>
                <path
                  d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275   c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
                />
              </g>
            </svg>
          </div>
          <span :id="remainTimeId"> </span>
        </div>
        <span
          v-if="data?.taskSchedule?.finishItToday"
          class="text-xs text-orange-500"
        >
          {{ translate('TASK_CREATE_FORM_FINISH_TODAY') }}
        </span>

        <div class="flex-center gap-1 text-xs text-current-500">
          <span v-if="data?.planningStartTime" class="text-xs text-current-500">
            {{
              dayjs.utc(data?.planningStartTime).tz(timeZone).format('HH:mm')
            }}
          </span>
          <span
            v-if="data?.planningStartTime && data?.planningEndTime"
            class="text-xs text-current-500"
            >-</span
          >
          <span v-if="data?.planningEndTime" class="text-xs text-current-500">
            {{ dayjs.utc(data?.planningEndTime).tz(timeZone).format('HH:mm') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
