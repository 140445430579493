<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g id="Image" xmlns="http://www.w3.org/2000/svg">
        <rect
          fill="#e3f8fa"
          height="30"
          rx="4"
          width="28"
          x="2"
          y="1"
          data-original="#ddebfd"
          class=""
        ></rect>
        <path
          d="m23.5 14.458a1 1 0 0 0 -1.392.022l-4.312 4.313a1 1 0 0 0 0 1.414l10.223 10.226a3.985 3.985 0 0 0 1.981-3.433v-6.433z"
          fill="#0f9595"
          data-original="#4071f7"
          class=""
        ></path>
        <g fill="#5392f9">
          <circle
            cx="19"
            cy="11"
            r="3"
            fill="#01ac9a"
            data-original="#5392f9"
            class=""
          ></circle>
          <path
            d="m13.707 13.293a1.029 1.029 0 0 0 -1.414 0l-10.293 10.293v3.414a4 4 0 0 0 4 4h20a3.985 3.985 0 0 0 3.433-1.981z"
            fill="#01ac9a"
            data-original="#5392f9"
            class=""
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
