<script setup lang="ts">
import { computed } from 'vue';
import MessageTask from '@/ui/modules/messaging/chat-panel/chat-message/message-task/MessageTask.vue';
import userStore from '@/store/user';

defineProps({
  task: {
    type: Object,
    default: () => ({}),
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  organizationId: {
    type: Number,
    default: null,
  },
});

defineEmits(['onDelete', 'onTaskClick']);
const _userStore = userStore();
const members = computed(() =>
  (_userStore.listShortInfosUser || []).reduce((obj, user) => {
    obj[user?.id] = user;
    return obj;
  }, {})
);
</script>

<template>
  <div
    class="relative m-2 bg-white rounded"
    :class="readonly ? 'flex-1' : ' w-96'"
  >
    <MessageTask
      class="w-full"
      is-my-message
      :task="task"
      :members="members"
      custom-class-title="text-xs"
      @on-open-task="(code) => $emit('onTaskClick', code)"
    />

    <div
      v-if="!readonly"
      class="absolute -right-2 -top-2 rounded-full bg-white"
    >
      <button
        type="button"
        class="
          rounded-full
          bg-gray-700 bg-opacity-50
          text-white
          hover:bg-opacity-100
          w-5
          h-5
          flex-center
        "
        @click="$emit('onDelete')"
      >
        <SynIcon name="Close" custom-class="w-4 h-4 fill-white" />
      </button>
    </div>
  </div>
</template>
