import { defineStore } from 'pinia';
import SettingService from '@/application/services/SettingService';
import storeId from '@/base/store/storeId';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { EOrganizationSettings } from '@/application/enums/SettingEnums';
import {
    IOrganizationSettingPayload,
    OrganizationSetting,
} from '@/application/types/organization/org-setting.types';

type SettingState = {
    taskConfigs: any;
    _organizationSetting: OrganizationSetting;
    _isGotSetting: boolean;
    timezoneList: any[];
};

export default defineStore({
    id: storeId.setting,
    state: () =>
        ({
            taskConfigs: {},
            _organizationSetting: {
                chatEnabled: false,
                externalChatEnabled: false,
                gedEnabled: false,
                videoCallEnabled: false,
                calendarEnabled: false,
                dayoffEnabled: false,
                timeWorkingEnabled: false,
                showUserPhoneEnabled: false,

                timeWorking: {
                    enabled: false,
                    runMode: '',
                },
                domainManagement: {
                    enable: false,
                    requireWhenCreateTask: false,
                    showOnTab: false,
                    showOnMenu: false,
                },
                taskManagement: {
                    defaultPublicTask: false,
                    multipleAssigneeSetting: {
                        systemEnable: false,
                        organizationEnable: false,
                    },
                },
                startWorkingManagement: {
                    mustSelectTask: false,
                },
                departmentManagement: {
                    enabled: false,
                },
                memberManagement: {
                    visitorLimitation: 0,
                },
            },
            timezoneList: [],
            _isGotSetting: false,
        } as SettingState),
    getters: {
        chatEnabled: (state) => state._organizationSetting.chatEnabled,
        externalChatEnabled: (state) =>
            state._organizationSetting.externalChatEnabled,
        gedEnabled: (state) => state._organizationSetting.gedEnabled,
        showUserPhoneEnabled: (state) =>
            state._organizationSetting.showUserPhoneEnabled,
        videoCallEnabled: (state) =>
            state._organizationSetting.videoCallEnabled,
        dayoffEnabled: (state) => state._organizationSetting.dayoffEnabled,
        calendarEnabled: (state) => state._organizationSetting.calendarEnabled,
        timeWorkingEnabled: (state) =>
            state._organizationSetting.timeWorking.enabled,
        startWorkingManagement: (state) =>
            state._organizationSetting.startWorkingManagement,
        taskManagement: (state) => state._organizationSetting.taskManagement,
        domainManagement: (state) =>
            state._organizationSetting.domainManagement,
    },
    actions: {
        async getDataConfig() {
            const res = await SettingService.getInstance().getDataConfig();
            this.taskConfigs = res?.resull ?? {};
        },

        async updateTaskConfig(data) {
            await SettingService.getInstance().updateTaskConfig(data);
        },

        async getOrganizationSettings() {
            if (this._isGotSetting) return this._organizationSetting;
            const res =
                await SettingService.getInstance().getOrganizationSettings();

            this._organizationSetting = res?.settings ?? {};
            this._isGotSetting = true;
            return this._organizationSetting;
        },

        checkSettingAccessPermission(field) {
            return this._organizationSetting[field] || false;
        },

        async getAllTimezoneSettings() {
            const res =
                await SettingService.getInstance().getAllTimezoneSettings();
            this.timezoneList = res['result'] || [];
            return res;
        },

        async updateOrganizationSetting(key, data) {
            this._organizationSetting[key] = data;
            const organizationId = parseInt(
                getLocalStorage(StorageConstant.ACTIVE_ORG)
            );

            const payload: IOrganizationSettingPayload = {
                id: organizationId,
                settings: { ...this._organizationSetting },
            };
            payload.settings[key] = data;

            try {
                const res =
                    await SettingService.getInstance().updateOrganizationSetting(
                        payload
                    );
                // this._organizationSetting = res['result']?.settings ?? {};

                this._isGotSetting = true;

                openNotification({
                    type: AlertType.success,
                    title: translate('COMMON_LABEL_SUCCESS'),
                    body: translate('API_ACTION_SETTING_ORG_SUCCESS'),
                    duration: 3000,
                });

                return res?.settings;
            } catch (e) {
                openNotification({
                    type: AlertType.error,
                    title: translate('COMMON_LABEL_ERROR'),
                    body: translate('API_ACTION_SETTING_ORG_ERROR'),
                    duration: 3000,
                });
            }
        },
        async updateOrganizationSettingsByModule(data: {
            module: EOrganizationSettings;
            metadata: {
                enabled?: boolean;
                showOnMenu?: boolean;
                showOnTab?: boolean;
                mustSelectTask?: boolean;
                defaultPublicTask?: boolean;
                runMode?: string;
                showPhoneNumber?: boolean;
            };
        }) {
            try {
                const res =
                    await SettingService.getInstance().updateOrganizationSettingsByModule(
                        data
                    );

                this._organizationSetting.departmentManagement.enabled =
                    !!data?.metadata?.enabled;

                openNotification({
                    type: AlertType.success,
                    title: translate('COMMON_LABEL_SUCCESS'),
                    body: translate('API_ACTION_SETTING_ORG_SUCCESS'),
                    duration: 3000,
                });

                return res?.settings;
            } catch (e) {
                openNotification({
                    type: AlertType.error,
                    title: translate('COMMON_LABEL_ERROR'),
                    body: translate('API_ACTION_SETTING_ORG_ERROR'),
                    duration: 3000,
                });
            }
        },
    },
});
