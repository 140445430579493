export enum AppMessageTypeEnum {
    tictopBubbleChats = 'tictop-bubble-chats',
    tictopBubbleChatActive = 'tictop-bubble-chat-active',
    tictopChatOpen = 'tictop-chat-open',
    tictopChatUnseenCount = 'tictop-chat-unseen-count',
    tictopDocumentLoad = 'tictop-document-load',
    tictopDocumentClick = 'tictop-document-click',
    tictopUserLogin = 'tictop-user-login',
    tictopNoteClick = 'tictop-note-click',
    tictopRecordingClick = 'tictop-recording-click',
    tictopMessageFileClick = 'tictop-message-file-click',
    tictopViewChatFilesClick = 'tictop-view-chat-files-click',
    tictopGroupChatAddClick = 'tictop-group-chat-add-click',
    tictopGroupChatAddResponse = 'tictop-group-chat-add-response',
    tictopChatFilesGetResponse = 'tictop-chat-files-get-response',

    componentBubbleChatOpen = 'component-bubble-chat-open',
    componentBubbleChatRemove = 'component-bubble-chat-remove',
    componentBubbleChatRemoveAll = 'component-bubble-chat-remove-all',
    componentIconChatOpenState = 'component-icon-chat-open-state',
    componentNoteSave = 'component-note-save',
    componentRecordingStop = 'component-recording-stop',
    componentGroupChatAddRequest = 'component-group-chat-add-request',
    componentChatFilesGetRequest = 'component-chat-files-get-request',
}
