import myProfileStore from '@/store/auth/my-profile';
import ChatMessageRepository from '@/application/repositories/chat/ChatMessageRepository';

export default function customizesMessagesComposables(organizationId) {
    const changeMessageBackgroundColor = async (
        converId,
        message,
        bgColorCustomize
    ) => {
        const userId = myProfileStore().myProfile?.id;

        if (!converId || !userId || !message?.id) {
            return;
        }

        // Case remove background color
        if (!bgColorCustomize?.color) {
            await ChatMessageRepository.removeMessageBackgroundColor(
                organizationId.value,
                converId,
                message?.id,
                userId
            );

            // if (message?.customizes?.backgroundColor) {
            //   delete message.customizes.backgroundColor[userId];
            // }

            return message;
        }

        // Case set background color
        // message.customizes = message.customizes || {};
        // message.customizes.backgroundColor =
        //   message.customizes.backgroundColor || {};

        const newBgColor = {
            hex: bgColorCustomize?.color,
            isShowAll: `${bgColorCustomize?.isShowAll}`,
        };

        await ChatMessageRepository.addMessageBackgroundColor(
            organizationId.value,
            converId,
            message?.id,
            userId,
            newBgColor
        );

        return message;
    };

    return {
        changeMessageBackgroundColor,
    };
}
