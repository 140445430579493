<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import groupStore from '@/store/group';
import { ignoreUnicode } from '@/ui/plugins/utils';
import userStore from '@/store/user';
import favoriteStore from '@/store/favorite/favoriteStore';
import { stringNomalize, markTextSearch } from '@/ui/hooks/commonFunction';
import taskStore from '@/store/task';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import { isValidTaskCode } from '@/ui/hooks/taskHook';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import myProfileStore from '@/store/auth/my-profile';
import SynAvatarStatusActive from '@/ui/common/molecules/SynAvatar/SynAvatarStatusActive.vue';
import ActionById from '@/ui/components/user/ActionById.vue';
import UserById from '@/ui/components/user/UserById.vue';
import DepartmentByUserId from '@/ui/components/user/DepartmentByUserId.vue';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
import settingStore from '@/store/setting';
import DepartmentSelect from '@/ui/modules/departments/components/DepartmentSelect.vue';
import departmentStore from '@/store/department';
import { IDepartmentDetail } from '@/application/types/department/department.types';
import GroupById from '@/ui/components/group/GroupById.vue';

const props = withDefaults(
  defineProps<{
    isHiddenActionItem?: boolean;
    isSelectMultiple?: boolean;
    selectedIds?: any[];
    ownerTypeList?: ('FAVORITE' | 'USER' | 'GROUP' | 'ITS_ME')[];
    isFilterWhenSelected?: boolean;
    isHoverToShowCheckbox?: boolean;
    doNotCloseDropdown?: boolean;
    isHiddenSelectedOwners?: boolean;
    allUsers?: any[];
    isShowResultOnly?: boolean;
    isHiddenQuicklyScroll?: boolean;
    isMoveMeToTop?: boolean;
    isHiddenDepartmentSelecting?: boolean;
    ignoreFilterByDepartment?: boolean;
    hasSelectAllUser?: boolean;
  }>(),
  {
    ownerTypeList: () => ['FAVORITE', 'USER', 'GROUP'],
    selectedIds: () => [],
  }
);
const emit = defineEmits([
  'onOpenSearch',
  'onChooseOwner',
  'onRemoveOwner',
  'onUpdateOwnerList',
]);

const _groupStore = groupStore();
const _userStore = userStore();
const _favoriteStore = favoriteStore();
const _taskStore = taskStore();
const _myProfileStore = myProfileStore();
const _settingStore = settingStore();
const _departmentStore = departmentStore();

const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const myDepartment = computed(() => _myProfileStore.myDepartment);
const allDepartmentByIds = computed(() => _departmentStore.allDepartmentByIds);

const selectedDepartment = ref<IDepartmentDetail | null>(myDepartment.value);

const isSelecting = computed<boolean>(() => {
  if (
    props.isHoverToShowCheckbox &&
    (!props.isSelectMultiple || props.selectedIds?.length == 0)
  )
    return false;

  return true;
});
onMounted(() => {
  if (
    allDepartmentByIds.value &&
    myDepartment.value?.departmentId &&
    Object.prototype.hasOwnProperty.call(
      allDepartmentByIds.value,
      myDepartment.value?.departmentId
    )
  )
    selectedDepartment.value =
      allDepartmentByIds.value[myDepartment.value?.departmentId];

  let options = {
    root: document.querySelector('#intersection-root'),
    rootMargin: '0px',
    threshold: 0,
  };

  ['member', 'group'].forEach((item) => {
    let observer = new IntersectionObserver(
      item == 'group'
        ? onIntersectionGroupChanged
        : onIntersectionMemberChanged,
      options
    );
    const target = document.getElementById(`member-list-${item}`);
    target && observer.observe(target);
  });
});

const currentCycle = computed(() => _taskStore.currentCycle);
const myProfile = computed(() => myProfileStore().myProfile);
const isVisitor = computed(() => _userStore.isVisitor);
const allActiveUsers = computed(() => {
  if (props.allUsers) return props.allUsers;

  return isVisitor.value ? [] : _userStore.allActiveUsers;
});

const allMyGroups = computed(() => _groupStore.allMyGroups);
const filteredGroups = computed(() => {
  if (isSearchingTask.value) return [];
  let result = allMyGroups.value || [];

  if (props.isFilterWhenSelected)
    result = result?.filter(
      (user) =>
        !props.selectedIds?.some(
          (selectedId) => selectedId == `GROUP_${user?.id}`
        )
    );

  const searchTxt = ignoreUnicode(searchText.value);
  if (searchTxt)
    return result.filter(
      (group) => group?.name && ignoreUnicode(group?.name).includes(searchTxt)
    );

  return result;
});
const filteredUsers = computed(() => {
  if (isSearchingTask.value) return [];
  let result = allActiveUsers.value || [];

  if (props.isFilterWhenSelected)
    result = result?.filter(
      (user) =>
        !props.selectedIds?.some(
          (selectedId) => selectedId == `USER_${user?.id}`
        )
    );
  if (props.ownerTypeList?.includes('ITS_ME'))
    result = result?.filter((user) => user?.id !== myProfile.value?.id);

  const searchTxt = ignoreUnicode(searchText.value);
  if (searchTxt)
    return result.filter(
      (user) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
    );

  if (
    !props.ignoreFilterByDepartment &&
    departmentManagementEnabled.value &&
    selectedDepartment.value?.id
  ) {
    result = result?.filter((user) =>
      selectedDepartment.value?.departmentUsers?.some(
        (member) => member?.userId == user?.id
      )
    );
  }

  return result;
});
const isSearchingTask = computed(() => searchText.value?.includes('#'));

const allMyFavorites = computed(() => _favoriteStore.getMyFavorites);

const myFavorites = computed(() => {
  if (isSearchingTask.value) return [];
  let data = _favoriteStore.getMyFavorites;
  data = data?.filter((o) =>
    stringNomalize(o.name?.toLowerCase()).includes(
      stringNomalize(searchText.value?.toLowerCase())
    )
  );
  return data?.map((o) => {
    return {
      ...o,
      ownerType:
        o.favoriteType == 'member' ? 'USER' : o.favoriteType?.toUpperCase(),
    };
  });
});

const resultSearch = computed(() => {
  let data = [] as any;
  filteredUsers.value.forEach((el) => {
    data.push({
      id: el?.id,
      type: 'USER',
      isGroup: false,
      avatar: el?.avatar,
      name: el?.name,
    });
  });
  if (props.ownerTypeList?.includes('GROUP'))
    filteredGroups.value.forEach((el) => {
      data.push({
        id: el?.id,
        type: 'GROUP',
        isGroup: true,
        avatar: el?.avatar,
        name: el?.name,
      });
    });
  return data;
});

const currentTab = ref('favorite');
const activeClass = 'text-current-600 bg-current-50 border border-current-600';

const isSearching = ref(false);
const searchText = ref('');
const currentIndex = ref(0);
const isLoadingElement = ref(false);

const onIntersectionGroupChanged = (entries) => {
  if (entries.length > 0) {
    entries.forEach((entry) => {
      currentTab.value = entry.isIntersecting ? 'group' : 'member';
    });
  }
};
const onIntersectionMemberChanged = (entries) => {
  if (entries.length > 0) {
    entries.forEach((entry) => {
      currentTab.value = entry.isIntersecting ? 'member' : 'favorite';
    });
  }
};

const onScrollTo = (keyName) => {
  currentTab.value = keyName;
  const element = document.getElementById(
    `member-list-${keyName}`
  ) as HTMLDivElement;
  element.scrollIntoView({ block: 'start' });
};

const onOpenDropdownSearch = () => {
  emit('onOpenSearch', true);
  console.log('onOpenDropdownSearch');
  isSearching.value = true;
  isLoadingElement.value = true;
  searchText.value = '';
  currentIndex.value = 0;
};

const onChangeTextSearch = () => {
  isSearching.value = true;
  currentIndex.value = 0;
  // if (searchText.value !== '#') return;
  // searchText.value = `#${codePrefix.value}`;
};

const chooseUser = (contact, type) => {
  if (!contact?.id) return;
  // ownerType:  USER_..., GROUP_..., DOMAIN_...
  const ownerType = type?.toUpperCase();

  isSearching.value = false;

  emit('onChooseOwner', { ...contact, type: ownerType });
};
const removeOwner = (id, type) => {
  emit('onRemoveOwner', { id, type });
};

const onChooseResult = () => {
  if (resultSearch.value?.length < 1 && !isSearchingTask.value) return;

  if (isSearchingTask.value) {
    const taskCode = searchText.value?.replace('#', '');

    const fullCode = taskCode.includes('-')
      ? taskCode
      : `${currentCycle.value?.currentCycle}-${taskCode}`;

    if (!isValidTaskCode(fullCode)) {
      openNotification({
        type: AlertType.warning,
        body: translate('TASK_LABEL_CODE_INVALID') || 'Task code invalid',
        duration: 2000,
      });
      return;
    }
    taskCodeGlobal.value = fullCode;

    isShowTaskDetailGlobal.value = true;
    isSearching.value = false;
  } else {
    const currentOwner = resultSearch.value[currentIndex.value];
    emit('onChooseOwner', currentOwner);
    isSearching.value = false;
  }
};

const waitingForElementToDisplay = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      // console.log('mutations', mutations);
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

waitingForElementToDisplay('#vig-search-global').then(() => {
  console.log('Element is ready');
  isLoadingElement.value = false;
});
window.addEventListener('keydown', (e) => {
  if (e.ctrlKey && e.keyCode === 75) {
    // console.log('CTRL + K');
    onOpenDropdownSearch();
    e.preventDefault();
  }
});

const chooseDataFromSearch = (data) => {
  chooseUser(data, data?.type);
  isSearching.value = false;
};

const selectedUsers = computed<any>(() => {
  return props.selectedIds
    .filter((id) => id?.startsWith('USER'))
    .map((id) => id?.split('_').pop());
});
const selectedGroupIds = computed<any>(() => {
  return props.selectedIds
    .filter((id) => id?.startsWith('GROUP'))
    .map((id) => id?.split('_').pop());
});

const isShow = ref<boolean>(true);

const chooseAllUser = () => {
  emit('onUpdateOwnerList', filteredUsers.value);
};
</script>

<template>
  <div class="flex flex-col w-full h-full">
    <div v-if="selectedUsers?.length > 0 && !isHiddenSelectedOwners">
      <div
        v-for="userId of selectedUsers"
        :key="userId"
        class="
          rounded-full
          bg-current-50
          text-gray-800 text-sm
          flex-center
          space-x-2
          w-max
          p-1
          m-0.5
          float-left
        "
      >
        <UserById :user-id="userId" />
        <button
          class="
            p-0.5
            rounded-full
            bg-opacity-50
            hover:bg-current-400 hover:fill-white hover:text-white
            flex-center
          "
          @click.stop="removeOwner(userId, 'USER')"
        >
          <SynIcon name="CloseLine" custom-class="w-4 h-4" />
        </button>
      </div>
    </div>
    <div v-if="selectedGroupIds?.length > 0 && !isHiddenSelectedOwners">
      <div
        v-for="groupId of selectedGroupIds"
        :key="groupId"
        class="
          rounded-full
          bg-current-50
          text-gray-800 text-sm
          flex-center
          space-x-2
          w-max
          p-1
          m-0.5
          float-left
        "
      >
        <GroupById :group-id="groupId" />
        <button
          class="
            p-0.5
            rounded-full
            bg-opacity-50
            hover:bg-current-400 hover:fill-white hover:text-white
            flex-center
          "
          @click.stop="removeOwner(groupId, 'GROUP')"
        >
          <SynIcon name="CloseLine" custom-class="w-4 h-4" />
        </button>
      </div>
    </div>
    <template v-if="!isShowResultOnly">
      <div class="pt-3 pl-2 pr-4">
        <button
          v-if="!isShow && !searchText"
          ref="synSearchRef"
          class="
            flex-center
            relative
            text-gray-600
            focus-within:text-gray-800
            rounded-md
            h-9
            w-9
            border
            cursor-pointer
            hover:fill-current hover:border-current
          "
          @click="isShow = true"
        >
          <SynIcon name="search" :class="isShow ? 'fill-current' : ''" />
        </button>
        <VigSearchBox
          v-if="isShow || searchText"
          v-model="searchText"
          v-model:current-index="currentIndex"
          :total-result="resultSearch?.length"
          input-class="text-sm"
          style="width: 100%"
          autofocus
          :placeholder="$t('COMMON_LABEL_SEARCH_BY_USER_NAME_GROUP_NAME')"
          @update:model-value="onChangeTextSearch"
          @enter="onChooseResult"
        />
      </div>
      <div
        v-if="!isSearchingTask"
        class="
          flex-1
          min-h-0
          overflow-auto
          small-scrollbar
          flex flex-col
          w-full
        "
      >
        <div
          v-if="
            !searchText && ownerTypeList?.length > 1 && !isHiddenQuicklyScroll
          "
          class="flex w-full text-xs px-2 pt-4 pb-2 space-x-2"
        >
          <div
            v-if="
              allMyFavorites.length > 0 && ownerTypeList?.includes('FAVORITE')
            "
            :class="currentTab == 'favorite' ? activeClass : ''"
            class="
              py-1
              font-semibold
              cursor-pointer
              rounded-full
              px-3
              bg-current-50
              text-current-400
            "
            @click="onScrollTo('favorite')"
          >
            {{ $t('SIDEBAR_LABEL_MY_FAVORITES') || 'Favorites' }}
          </div>
          <div
            v-if="allActiveUsers.length > 0 && ownerTypeList?.includes('USER')"
            :class="currentTab == 'member' ? activeClass : ''"
            class="
              py-1
              font-semibold
              cursor-pointer
              rounded-full
              px-3
              bg-current-50
              text-current-400
            "
            @click="onScrollTo('member')"
          >
            {{ $t('CHAT_MEMBERS') || 'Members' }}
          </div>
          <div
            v-if="allMyGroups.length > 0 && ownerTypeList?.includes('GROUP')"
            :class="currentTab == 'group' ? activeClass : ''"
            class="
              py-1
              font-semibold
              cursor-pointer
              rounded-full
              px-3
              bg-current-50
              text-current-400
            "
            @click="onScrollTo('group')"
          >
            {{ $t('CHAT_GROUPS') || 'Groups' }}
          </div>
        </div>

        <div
          id="intersection-root"
          class="flex-1 overflow-auto small-scrollbar pb-3"
          style="scroll-behavior: smooth"
        >
          <template v-if="!searchText">
            <template
              v-if="
                myFavorites?.length <= 0 &&
                filteredUsers?.length <= 0 &&
                filteredGroups?.length <= 0
              "
            >
              <div class="py-16 flex-center flex-col">
                <SynAnimation
                  name="searchIcon"
                  :loop="false"
                  stype="width: 100px; height: 100px"
                />
                <span class="font-medium text-gray-600">{{
                  $t('COMMON_LABEL_ALERT_NO_SEARCH')
                }}</span>
              </div>
            </template>
            <template v-else>
              <div
                v-if="ownerTypeList?.includes('ITS_ME')"
                id="member-list-its-me"
              >
                <div
                  class="
                    px-4
                    pt-4
                    pb-1
                    uppercase
                    text-xs
                    font-semibold
                    tracking-widest
                    text-gray-500
                  "
                >
                  <!-- {{ $t('COMMON_LABEL_ME') || 'Favorites' }} -->
                </div>
                <div
                  class="w-full h-full grid auto-rows-max gap-2 p-2 grid-cols-3"
                  style="
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(12rem, 1fr)
                    );
                  "
                >
                  <div
                    class="
                      p-2
                      flex
                      items-center
                      space-x-2
                      hover:bg-current-100
                      rounded
                      text-sm
                      hover-to-show__parent
                      cursor-pointer
                    "
                    :class="[
                      doNotCloseDropdown ? '' : 'dropdown-item',
                      selectedIds?.some(
                        (selectedId) => selectedId == `USER_${myProfile?.id}`
                      )
                        ? 'bg-current-50'
                        : '',
                    ]"
                    @click="chooseUser(myProfile, 'USER')"
                  >
                    <div
                      v-if="isSelectMultiple"
                      class="
                        group
                        h-8
                        w-8
                        flex-center
                        rounded-full
                        cursor-pointer
                      "
                      :class="{
                        'hover-to-show__children': !isSelecting,
                      }"
                      @click.stop="chooseUser(myProfile, 'USER')"
                    >
                      <div
                        class="
                          cursor-pointer
                          h-4
                          w-4
                          flex-center
                          rounded
                          border border-current-300
                          group-hover:border-2
                        "
                        :class="{
                          'bg-current-300': selectedIds?.some(
                            (id) => id == `USER_${myProfile?.id}`
                          ),
                          'bg-white': !selectedIds?.some(
                            (id) => id == `USER_${myProfile?.id}`
                          ),
                        }"
                      >
                        <SynIcon
                          v-if="
                            selectedIds?.some(
                              (id) => id == `USER_${myProfile?.id}`
                            )
                          "
                          name="check"
                          class="text-white"
                        />
                      </div>
                    </div>
                    <div
                      class="flex items-center w-full justify-between space-x-2"
                    >
                      <UserById
                        :user-id="myProfile.id"
                        is-show-department
                        class="flex-1"
                        avatar-class="w-8 h-8"
                      />

                      <div
                        v-if="!isHiddenActionItem"
                        class="text-end hover-to-show__children"
                      >
                        <ActionById
                          :contact-id="myProfile?.id"
                          :types="['favourite', 'list-task', 'chat']"
                          :type-contact="'member'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  myFavorites?.length > 0 && ownerTypeList?.includes('FAVORITE')
                "
                id="member-list-favorite"
              >
                <div
                  v-if="ownerTypeList?.length > 1"
                  class="
                    px-4
                    pt-4
                    pb-1
                    uppercase
                    text-xs
                    font-semibold
                    tracking-widest
                    text-gray-500
                  "
                >
                  {{ $t('SIDEBAR_LABEL_MY_FAVORITES') || 'Favorites' }}
                </div>
                <div
                  class="w-full h-full grid auto-rows-max gap-2 p-2 grid-cols-3"
                  style="
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(12rem, 1fr)
                    );
                  "
                >
                  <div
                    v-for="favorite in myFavorites.filter((o) => o.id)"
                    :key="favorite"
                    class="
                      p-2
                      w-full
                      flex
                      items-center
                      hover:bg-current-100
                      rounded
                      text-sm
                      justify-between
                      hover-to-show__parent
                      cursor-pointer
                    "
                    @click="chooseUser(favorite, favorite?.ownerType)"
                  >
                    <UserById
                      v-if="favorite?.ownerType == 'USER'"
                      is-show-department
                      :user-id="favorite.id"
                      class="flex-1"
                      avatar-class="w-8 h-8"
                    />
                    <div v-else class="flex items-center space-x-2">
                      <SynAvatar
                        :src="
                          favorite.avatarUrl
                            ? favorite.avatarUrl?.replace('original', 'mini')
                            : ''
                        "
                        :type="favorite?.ownerType?.toLowerCase()"
                        :name="favorite.name"
                        custom-class="w-8 h-8"
                      />
                      <span class="cursor-pointer text-overflow-hidden-line">{{
                        favorite?.name
                      }}</span>
                      <span v-if="favorite?.refId === myProfile?.id">
                        ({{ $t('COMMON_LABEL_ME') || 'Me' }})
                      </span>
                    </div>
                    <div
                      v-if="!isHiddenActionItem"
                      class="text-end hover-to-show__children"
                    >
                      <ActionById
                        :contact-id="favorite?.id"
                        :types="['favourite', 'list-task', 'chat']"
                        :type-contact="favorite?.favoriteType"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="ownerTypeList?.includes('USER')"
                id="member-list-member"
              >
                <div
                  v-if="ownerTypeList?.length > 1"
                  class="
                    px-4
                    pt-4
                    pb-1
                    uppercase
                    text-xs
                    font-semibold
                    tracking-widest
                    text-gray-500
                  "
                >
                  {{ $t('CHAT_MEMBERS') || 'Members' }}
                </div>
                <DepartmentSelect
                  v-if="
                    departmentManagementEnabled && !isHiddenDepartmentSelecting
                  "
                  v-model="selectedDepartment"
                />

                <div
                  v-if="hasSelectAllUser && allActiveUsers?.length > 0"
                  class="
                    p-2
                    flex
                    items-center
                    space-x-2
                    hover:bg-current-100
                    rounded
                    text-sm
                    hover-to-show__parent
                    cursor-pointer
                  "
                  @click="chooseAllUser"
                >
                  <div
                    v-if="isSelectMultiple"
                    class="
                      group
                      h-8
                      w-8
                      flex-center
                      rounded-full
                      cursor-pointer
                    "
                    :class="{
                      'hover-to-show__children': !isSelecting,
                    }"
                    @click.stop="chooseAllUser"
                  >
                    <div
                      class="
                        cursor-pointer
                        h-4
                        w-4
                        flex-center
                        rounded
                        border border-current-300
                        group-hover:border-2
                      "
                    >
                      <SynIcon
                        v-if="
                          selectedIds?.filter((id) =>
                            filteredUsers?.some((u) => `USER_${u?.id}` == id)
                          )?.length >= filteredUsers?.length
                        "
                        name="check"
                        class="text-white"
                      />
                    </div>
                  </div>
                  <div
                    class="flex items-center w-full justify-between space-x-2"
                  >
                    {{ $t('COMMON_LABEL_SELECT_ALL') }}
                  </div>
                </div>
                <div
                  v-if="filteredUsers?.length > 0"
                  class="
                    w-full
                    h-full
                    grid
                    auto-rows-max
                    gap-1
                    py-2
                    grid-cols-3
                  "
                  style="
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(12rem, 1fr)
                    );
                  "
                >
                  <div
                    v-for="user in filteredUsers"
                    :key="user"
                    class="
                      p-2
                      flex
                      items-center
                      space-x-2
                      hover:bg-current-100
                      rounded
                      text-sm
                      hover-to-show__parent
                      cursor-pointer
                    "
                    :class="[
                      doNotCloseDropdown ? '' : 'dropdown-item',
                      selectedIds?.some(
                        (selectedId) => selectedId == `USER_${user?.id}`
                      )
                        ? 'bg-current-50'
                        : '',
                    ]"
                    @click="chooseUser(user, 'USER')"
                  >
                    <div
                      v-if="isSelectMultiple"
                      class="
                        group
                        h-8
                        w-8
                        flex-center
                        rounded-full
                        cursor-pointer
                      "
                      :class="{
                        'hover-to-show__children': !isSelecting,
                      }"
                      @click.stop="chooseUser(user, 'USER')"
                    >
                      <div
                        class="
                          cursor-pointer
                          h-4
                          w-4
                          flex-center
                          rounded
                          border border-current-300
                          group-hover:border-2
                        "
                        :class="{
                          'bg-current-300': selectedIds?.some(
                            (id) => id == `USER_${user?.id}`
                          ),
                          'bg-white': !selectedIds?.some(
                            (id) => id == `USER_${user?.id}`
                          ),
                        }"
                      >
                        <SynIcon
                          v-if="
                            selectedIds?.some((id) => id == `USER_${user?.id}`)
                          "
                          name="check"
                          class="text-white"
                        />
                      </div>
                    </div>
                    <div
                      class="flex items-center w-full justify-between space-x-2"
                    >
                      <UserById
                        :user-id="user.id"
                        is-show-department
                        class="flex-1"
                        avatar-class="w-8 h-8"
                      />

                      <div
                        v-if="!isHiddenActionItem"
                        class="text-end hover-to-show__children"
                      >
                        <ActionById
                          :contact-id="user?.id"
                          :types="['favourite', 'list-task', 'chat']"
                          :type-contact="'member'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span v-else class="text-xs text-gray-500 italic px-4">
                  {{ $t('COMMON_LABEL_NO_DATA') }}
                </span>
              </div>
              <div
                v-if="
                  filteredGroups?.length > 0 && ownerTypeList?.includes('GROUP')
                "
                id="member-list-group"
              >
                <div
                  v-if="ownerTypeList?.length > 1"
                  class="
                    px-4
                    pt-4
                    pb-2
                    uppercase
                    text-xs
                    font-semibold
                    tracking-widest
                    text-gray-500
                  "
                >
                  {{ $t('CHAT_GROUPS') || 'Groups' }}
                </div>
                <div
                  class="w-full h-full grid auto-rows-max gap-2 p-2 grid-cols-3"
                  style="
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(12rem, 1fr)
                    );
                  "
                >
                  <div
                    v-for="group in filteredGroups"
                    :key="group"
                    class="
                      p-2
                      flex
                      items-center
                      cursor-pointer
                      hover:bg-current-100
                      rounded
                      text-sm
                      justify-between
                      hover-to-show__parent
                    "
                    :class="[
                      doNotCloseDropdown ? '' : 'dropdown-item',
                      selectedIds?.some(
                        (selectedId) => selectedId == `group_${group?.id}`
                      )
                        ? 'bg-current-50'
                        : '',
                    ]"
                    @click="chooseUser(group, 'GROUP')"
                  >
                    <div class="flex items-center space-x-2">
                      <SynAvatar :src="group?.avatar" :name="group?.name" />
                      <span class="text-overflow-hidden-line">
                        {{ group?.name }}
                      </span>
                    </div>
                    <div
                      v-if="!isHiddenActionItem"
                      class="text-end hover-to-show__children"
                    >
                      <ActionById
                        :contact-id="group?.id"
                        :types="['favourite', 'list-task', 'chat']"
                        :type-contact="'group'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>

          <!-- STARTING SEARCH -->
          <template v-else>
            <template v-if="resultSearch?.length <= 0">
              <div class="py-16 flex-center flex-col">
                <SynAnimation
                  name="searchIcon"
                  :loop="false"
                  stype="width: 100px; height: 100px"
                />
                <span class="font-medium text-gray-600">{{
                  $t('COMMON_LABEL_ALERT_NO_SEARCH')
                }}</span>
              </div>
            </template>
            <template v-else>
              <div class="py-2">
                <span
                  v-for="(result, index) in resultSearch"
                  :id="`vig-search-box-item-id_${index.toString()}`"
                  :key="result"
                  class="
                    p-2
                    flex
                    items-center
                    space-x-2
                    cursor-pointer
                    hover:bg-current-50
                    rounded
                    text-sm
                    justify-between
                    hover-to-show__parent
                  "
                  :class="[
                    doNotCloseDropdown ? '' : 'dropdown-item',
                    index == currentIndex ? 'bg-gray-100' : '',
                  ]"
                  @click="chooseDataFromSearch(result)"
                >
                  <div class="flex space-x-2 items-center">
                    <div class="pr-3">
                      <SynIcon
                        name="search"
                        custom-class="w-3 h-3 text-gray-400 fill-gray-500"
                      />
                    </div>

                    <SynAvatar
                      v-if="result?.isGroup"
                      :src="result?.avatar"
                      :type="result?.isGroup ? 'group' : 'user'"
                      :name="result.name"
                      custom-class="w-8 h-8"
                    />
                    <span v-else class="w-8 h-8">
                      <SynAvatarStatusActive
                        :id="result?.id || result?.userId"
                        is-user
                        :name="result.name"
                        :avatar="result?.avatar"
                        is-hidden-name
                        custom-class="w-8 h-8"
                      />
                    </span>

                    <div class="flex flex-col items-start">
                      <div class="flex items center space-x-2">
                        <!-- <span>{{ result?.name }}</span> -->
                        <span
                          class="text-overflow-hidden-line"
                          v-html="markTextSearch(result?.name, searchText)"
                        ></span>
                        <span v-if="result?.id === myProfile?.id">
                          ({{ $t('COMMON_LABEL_ME') || 'Me' }})
                        </span>
                        <div>
                          <SynIcon
                            v-if="result?.isGroup"
                            name="group"
                            is-active
                            custom-class="w-3 h-3 fill-gray-500"
                          />
                        </div>
                      </div>
                      <div class="flex justify-start">
                        <DepartmentByUserId
                          v-if="result?.type == 'USER'"
                          :user-id="result?.id"
                          class="italic"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="text-end hover-to-show__children">
                    <ActionById
                      :contact-id="result?.id"
                      :types="['list-task', 'chat']"
                      :type-contact="result?.isGroup ? 'group' : 'member'"
                    />
                  </div>
                </span>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>

  <!-- <div
    v-else
    class="w-8 h-8 rounded-full flex-center hover:bg-current-50 cursor-pointer"
    @click="isSearching = true"
  >
    <SynIcon
      v-if="isLoadingElement"
      name="spinner"
      custom-class="w-5 h-5 fill-current-500"
      class="animate-pulse"
    ></SynIcon>
    <SynIcon v-else name="search" custom-class="w-5 h-5 fill-current-500" />
  </div> -->
</template>
