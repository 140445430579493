<script setup lang="ts">
import { inject } from 'vue';
import ShapeUserEntity from '@/ui/components/drawer/entities/ShapeUserEntity';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import PROVIDER_IDS, {
  TDesktopDrawer,
  TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
import ShapeActions from '@/ui/components/drawer/components/ShapeActions.vue';

const props = defineProps<{
  shapeEntity: ShapeUserEntity;
}>();

const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);
const desktopDrawerShapeInjected = inject<TDesktopDrawerShape>(
  PROVIDER_IDS.DESKTOP_DRAWER_SHAPE
);

const onMouseDown = (event) => {
  desktopDrawerShapeInjected?.onMouseDown(event);
};

const onClickRemove = () => {
  desktopDrawerInjected?.desktopDrawerInstance.value?.removeShapeById(
    props.shapeEntity?.id,
    () => {
      desktopDrawerInjected?.addMoreHistory();
    }
  );
};
</script>
<template>
  <div
    class="flex-center relative"
    :style="{
      width: `${shapeEntity?.width}px`,
      height: `${shapeEntity?.height}px`,
    }"
  >
    <div style="pointer-events: all" @mousedown.left="onMouseDown">
      <UserByAction
        class="justify-center"
        :user-id="shapeEntity.sourceData?.id || 0"
        is-show-first-name
        is-show-action-user
        is-have-overlay
        avatar-class="w-12 h-12"
        custom-class="flex-center flex-col space-y-1 pr-2"
      />
    </div>
    <ShapeActions
      :action-list="['REMOVE']"
      class="absolute top-0 right-0"
      @on-click-remove="onClickRemove"
    />
  </div>
</template>
