<script setup lang="ts">
import { computed, ref } from 'vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import TaskSettingEvidenceModal from '@/ui/modules/task/providing-evidence/TaskSettingEvidenceModal.vue';

const props = defineProps<{
  task: ITaskDetailClass;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'save',
    data: {
      finishProofEnabled: boolean;
      finishProofTypes: string;
      finishProofSkipAllowed: boolean;
    }
  ): void;
}>();
const evidenceList = computed<any[]>(() => {
  if (
    !props.task?.finishProofTypes ||
    props.task?.finishProofTypes?.length == 0
  )
    return [];

  return props.task?.finishProofTypes
    ?.split(';')
    ?.filter((key) => key)
    ?.map((key) => {
      return {
        key,
        textCode: `TASK_LABEL_PROVIDE_EVIDENCE_${key?.toUpperCase()}`,
      };
    });
});

const isOpenDropdown = ref<boolean>(false);
const isOpenApprovalModal = ref<boolean>(false);
const onOpenChangeEvidenceSettings = () => {
  isOpenApprovalModal.value = true;
};
const onCancelProvidingEvidence = () => {
  onSaveSettings({
    finishProofEnabled: false,
    finishProofTypes: '',
    finishProofSkipAllowed: false,
  });
};
const onSaveSettings = (data: {
  finishProofEnabled: boolean;
  finishProofTypes: string;
  finishProofSkipAllowed: boolean;
}) => {
  isOpenApprovalModal.value = false;
  emit('save', data);
};
</script>
<template>
  <AtomTooltip>
    <template #trigger>
      <div v-if="readonly" class="flex-center rounded h-[1.15rem] w-[1.15rem]">
        <SynIcon name="evidence" class="fill-orange-500" />
      </div>
      <VigDropdown
        v-else
        @on-dropdown-open="isOpenDropdown = true"
        @on-dropdown-close="isOpenDropdown = false"
      >
        <template #dropdown-toggle>
          <div class="flex-center gap-0.5 cursor-pointer hover-to-show__parent">
            <div class="flex-center rounded h-[1.15rem] w-[1.15rem]">
              <SynIcon name="evidence" class="fill-orange-500" />
            </div>
            <div class="w-4 h-4 flex-center">
              <div
                class="w-4 h-4 cursor-pointer rounded flex items-center"
                :class="isOpenDropdown ? '' : 'hover-to-show__children'"
              >
                <SynIcon
                  name="chevron-right"
                  :custom-class="
                    isOpenDropdown
                      ? 'w-2 h-2 fill-gray-500 -rotate-90'
                      : 'w-2 h-2 fill-gray-500 rotate-90'
                  "
                />
              </div>
            </div>
          </div>
        </template>
        <template #dropdown-menu>
          <div class="p-1">
            <div
              class="
                flex
                items-center
                space-x-2
                p-2
                hover:bg-current-50
                cursor-pointer
                dropdown-item
              "
              @click="onOpenChangeEvidenceSettings"
            >
              <span class="text-sm text-blue-500">
                {{ $t('TASK_LABEL_ADJUST_EVIDENCE') }}
              </span>
            </div>
            <div
              class="
                flex
                items-center
                space-x-2
                p-2
                hover:bg-current-50
                cursor-pointer
                dropdown-item
              "
              @click="onCancelProvidingEvidence"
            >
              <span class="text-sm text-red-500">
                {{ $t('TASK_LABEL_DO_NOT_NEED_TO_PROVIDE_EVIDENCE') }}
              </span>
            </div>
          </div>
        </template>
      </VigDropdown>
    </template>
    <template #body>
      <div class="flex flex-col p-4 gap-2 rounded-md">
        <span
          class="italic cursor-pointer text-sm text-gray-500 inline space-x-1"
        >
          <span class=""
            >{{
              $t(
                evidenceList?.length <= 1
                  ? 'TASK_LABEL_PROVIDE_EVIDENCE_DESCRIPTION_SHORT'
                  : 'TASK_LABEL_PROVIDE_EVIDENCE_DESCRIPTION'
              )
            }}:</span
          >
          <span class="text-current font-semibold uppercase">
            <template v-for="(evi, index) in evidenceList" :key="evi?.key">
              {{ $t(evi?.textCode) || evi?.textCode || 'Evidence'
              }}<template v-if="index < evidenceList?.length - 1"
                >,
              </template> </template
            >.
          </span>
        </span>
      </div>
    </template>
  </AtomTooltip>

  <TaskSettingEvidenceModal
    v-if="isOpenApprovalModal"
    :task="task"
    @on-close="isOpenApprovalModal = false"
    @on-save="onSaveSettings"
  />
</template>
