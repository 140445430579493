<script setup lang="ts">
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
const srcLogoIcon = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/logo.svg`;
const srcLogoLabel = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/logo_title.svg`;
defineProps<{
  errorCode: 6100 | 6101 | 6102 | 6103 | 6105 | 6106;
}>();
</script>
<template>
  <div class="flex justify-start flex-col h-full py-12 px-4">
    <div class="flex space-x-2 items-center">
      <syn-img :src="srcLogoIcon" class="h-8 cursor-pointer" />
      <syn-img :src="srcLogoLabel" class="h-8 cursor-pointer" />
    </div>
    <div class="pt-4">
      <div v-if="errorCode == 6100">
        <div class="font-thin py-1 text-current-600" style="font-size: 30px">
          {{ $t('ERROR_TITLE_INVITE_INVALID_INVITATION') }}
        </div>
        <div class="text-lg text-current-800">
          {{ $t('ERROR_SUB_INVITE_INVALID_INVITATION') }}
        </div>
      </div>
      <div v-else-if="errorCode == 6101">
        <div class="font-thin py-1 text-current-600" style="font-size: 30px">
          {{ $t('ERROR_TITLE_INVITE_EXPIRED') }}
        </div>
        <div class="text-lg text-current-800">
          {{ $t('ERROR_SUB_INVITE_EXPIRED') }}
        </div>
      </div>
      <div v-else-if="errorCode == 6102">
        <div class="font-thin py-1 text-current-600" style="font-size: 30px">
          {{ $t('ERROR_TITLE_INVITE_NO_LONGER_ACTIVE') }}
        </div>
        <div class="text-lg text-current-800">
          {{ $t('ERROR_SUB_INVITE_NO_LONGER_ACTIVE') }}
        </div>
      </div>
      <div v-else-if="errorCode == 6103">
        <div class="font-thin py-1 text-gray-600" style="font-size: 30px">
          {{ $t('ERROR_TITLE_INVITE_JOINED_INVITATION') }}
        </div>
        <div
          class="underline text-current-600 cursor-pointer"
          @click="$router.push({ name: 'Home' })"
        >
          {{ $t('COMMON_LABEL_GO_TO_HOME_PAGE') || 'Go to Home page' }}
        </div>
      </div>
      <div v-else-if="errorCode == 6105">
        <div class="font-thin py-1 text-current-600" style="font-size: 30px">
          {{ $t('ERROR_TITLE_INVITE_ACCOUNT_DELETED') }}
        </div>
        <div class="text-lg text-current-800">
          {{ $t('ERROR_SUB_TITLE_INVITE_ACCOUNT_DELETED') }}
        </div>
      </div>
      <div v-else-if="errorCode == 6106">
        <div class="font-thin py-1 text-current-600" style="font-size: 30px">
          {{ $t('LOGIN_PHONE_NOT_VERIFY') }}
        </div>
        <div class="text-lg text-current-800">
          {{ $t('SIGNUP_BY_CODE_BLOCKED') }}
        </div>
      </div>
    </div>
  </div>
</template>
