<script setup lang="ts">
import { computed } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  ActivityInTask,
  // NoteAction,
  platformLogActivity,
  SubActivityInTask,
} from '@/ui/modules/task/ui-enums/tasklog-enums';
import UserById from '@/ui/components/user/UserById.vue';
import ActivityChangeStatus from '@/ui/modules/task/components/activity-tasklog/ActivityChangeStatus.vue';
import ActivityChangePriority from '@/ui/modules/task/components/activity-tasklog/ActivityChangePriority.vue';
import ActivityChangeAssignee from '@/ui/modules/task/components/activity-tasklog/ActivityChangeAssignee.vue';
import ActivityChangeDomain from '@/ui/modules/task/components/activity-tasklog/ActivityChangeDomain.vue';
import ActivityChangeInformationText from '@/ui/modules/task/components/activity-tasklog/ActivityChangeInformationText.vue';
import ActivityChangeAttachments from '@/ui/modules/task/components/activity-tasklog/ActivityChangeAttachments.vue';
import ActivityChangeDeadline from '@/ui/modules/task/components/activity-tasklog/ActivityChangeDeadline.vue';
import ActivityChangeNote from '@/ui/modules/task/components/activity-tasklog/ActivityChangeNote.vue';
import ActivityAddNewAssignee from '@/ui/modules/task/components/activity-tasklog/ActivityAddNewAssignee.vue';
import ActivityChangePrivacy from '@/ui/modules/task/components/activity-tasklog/ActivityChangePrivacy.vue';
import ActivityChangeCollaborators from '@/ui/modules/task/components/activity-tasklog/ActivityChangeCollaborators.vue';
import { titleActivityItem } from '@/ui/modules/task/components/activity-tasklog/tasklog-parser';
import taskDrawerStore from '@/store/task/drawer';
import ActivityFinishWorkflowStep from '@/ui/modules/notification/task-activity/ActivityFinishWorkflowStep.vue';
import ActivityApprovalItemTitle from '@/ui/modules/task/components/activity-tasklog/ActivityApprovalItemTitle.vue';
import ActivityAttachWorkflowItemTitle from '@/ui/modules/task/components/activity-tasklog/ActivityAttachWorkflowItemTitle.vue';
import ActivityChangeTodoList from '@/ui/modules/task/components/activity-tasklog/ActivityChangeTodoList.vue';
import ActivityChangeApproval from '@/ui/modules/task/components/activity-tasklog/ActivityChangeApproval.vue';
import ActivitySubTask from '@/ui/modules/task/components/activity-tasklog/ActivitySubTask.vue';
import ActivityChangeDeadlineLocking from '@/ui/modules/task/components/activity-tasklog/ActivityChangeDeadlineLocking.vue';
import ActivityParentTaskTitle from '@/ui/modules/task/components/activity-tasklog/ActivityParentTaskTitle.vue';
import ActivitySubTaskTitle from '@/ui/modules/task/components/activity-tasklog/ActivitySubTaskTitle.vue';
import ActivitySubTaskCreateTitle from '@/ui/modules/task/components/activity-tasklog/ActivitySubTaskCreateTitle.vue';

const props = withDefaults(
  defineProps<{
    activity: any;
    position: 'top' | 'middle' | 'bottom';
    users: any[];
  }>(),
  {
    position: 'middle',
  }
);

const _taskDrawerStore = taskDrawerStore();
const activityTask = computed(() => {
  console.log('🚀 Tictop ~ props.activity:', props.activity);
  return props.activity;
});

const user = computed(() =>
  props.users?.find(
    (user: any) => user?.id === activityTask.value?.actioner?.userId
  )
);

const keyTextActivity = (item) => {
  let after = item?.afterUpdate;
  let before = item?.beforeUpdate;
  let activity = item?.activity;
  let subActivity = item?.subActivity;
  return (
    titleActivityItem(after, before)[activity][subActivity]?.activity ||
    translate('ACTIVITY_HISTORY_TASK_ACTION_UPDATED_LABEL')
  );
};

const keyTextPartIsChanged = (item) => {
  let after = item?.afterUpdate;
  let before = item?.beforeUpdate;
  let activity = item?.activity;
  let subActivity = item?.subActivity;
  return (
    titleActivityItem(after, before)[activity][subActivity]?.changed ||
    translate('ACTIVITY_HISTORY_TASK_PART_CHANGED_TASK_LABEL')
  );
};

const onOpenTask = (afterUpdate) => {
  _taskDrawerStore.pushCurrentIds({
    id: afterUpdate?.OriginTaskId,
    code: afterUpdate?.OriginTaskCode,
    name: afterUpdate?.OriginTaskCode,
    tabType: 'DETAIL',
  });
};
</script>

<template>
  <div class="flex items-start flex-col w-full space-y-1">
    <!-- HOUR   -->
    <div class="flex items-center space-x-2 py-1">
      <div class="flex-center w-6 h-6">
        <UserById
          :user-id="activityTask?.actioner?.userId"
          :user="user"
          is-hidden-name
          avatar-class="w-7 h-7 rounded-full"
        />
      </div>
      <span class="font-medium">{{ activityTask?.actioner?.fullName }}</span>
      <span class="text-xs text-gray-500">
        <SynLabelDateTime
          format="time"
          ignore-timezone
          :datetime="activityTask?.start"
        />
      </span>
    </div>
    <!-- LOG   -->
    <div
      class="flex flex-1 w-full flex-col bg-gray-50 px-3 pt-1.5 pb-2 rounded"
    >
      <div
        v-for="(item, index) in activityTask?.activities"
        :key="item"
        class="flex flex-col pt-2"
        :class="
          index === activityTask?.activities?.length - 1
            ? ''
            : ' border-b border-gray-100'
        "
      >
        <!--  TITLE      -->
        <div class="flex items-start justify-between">
          <div class="relative flex items-center flex-wrap space-x-1 text-sm">
            <ActivitySubTaskCreateTitle
              v-if="
                item?.subActivity === SubActivityInTask.AddNew &&
                item?.afterUpdate?.ParentTaskId
              "
              :activity="item"
            />
            <template v-else-if="item?.subActivity?.startsWith('Approval')">
              <ActivityApprovalItemTitle :activity="item" />
            </template>
            <template
              v-else-if="
                item?.subActivity == SubActivityInTask.WorkflowAttachToTask
              "
            >
              <ActivityAttachWorkflowItemTitle :activity="item" />
            </template>
            <template
              v-else-if="
                item?.subActivity === SubActivityInTask.ScheduleTimeLockedChange
              "
            >
              <ActivityChangeDeadlineLocking :activity="item" />
            </template>
            <ActivityParentTaskTitle
              v-else-if="
                item?.subActivity === SubActivityInTask.ParentTaskAdded ||
                item?.subActivity === SubActivityInTask.ParentTaskRemoved
              "
              :activity="item"
            />
            <ActivitySubTaskTitle
              v-else-if="
                item?.subActivity === SubActivityInTask.SubTaskAdded ||
                item?.subActivity === SubActivityInTask.SubTaskRemoved
              "
              :activity="item"
            />
            <template v-else>
              <span
                class="lowercase"
                :class="
                  item?.subActivity === SubActivityInTask.Finished ||
                  item?.subActivity === SubActivityInTask.WorkflowStepComplete
                    ? 'font-medium text-green-500'
                    : 'text-gray-600'
                "
              >
                {{ keyTextActivity(item) }}
              </span>
              <span class="font-medium lowercase">{{
                keyTextPartIsChanged(item)
              }}</span>

              <span v-if="item?.subActivity === SubActivityInTask.Planned">
                {{ $t('COMMOM_LABEL_TO') }}
              </span>
              <span
                v-if="
                  item?.subActivity === SubActivityInTask.RepeatGenerate ||
                  item?.subActivity === SubActivityInTask.Clone
                "
                class="flex space-x-2"
              >
                <span>
                  {{ $t('TASK_LABEL_HISTORY_REPEAT_FROM') || 'from task' }}
                </span>
                <span
                  class="text-current cursor-pointer"
                  @click="onOpenTask(item?.afterUpdate)"
                >
                  {{ item?.afterUpdate?.OriginTaskName }}
                </span>
              </span>
              <span
                v-if="item?.subActivity === SubActivityInTask.Planned"
                class="px-1 py-0.5 text-xs rounded"
                :class="{
                  'bg-red-100 text-red-800': item?.afterUpdate?.PlanType === 2,
                  'bg-blue-200 text-blue-800':
                    item?.afterUpdate?.PlanType === 1,
                  'bg-white text-current-600':
                    item?.afterUpdate?.PlanType === 0,
                }"
              >
                {{
                  item?.afterUpdate?.PlanType === 0
                    ? $t('COMMON_LABEL_NOTHING')
                    : item?.afterUpdate?.PlanType === 1
                    ? $t('TASK_CREATE_FORM_DOING_TODAY')
                    : $t('TASK_CREATE_FORM_FINISH_TODAY')
                }}
              </span>

              <!--  ADD NEW  -->
              <template v-if="item?.subActivity === SubActivityInTask.AddNew">
                <span
                  v-if="item?.afterUpdate?.IsPrivate"
                  :title="$t('COMMON_LABEL_PRIVATE')"
                  class="flex-center"
                >
                  <SynIcon
                    name="private"
                    class="fill-current"
                    custom-class="w-2 h-2 "
                  />
                </span>
                <span
                  v-if="
                    item?.afterUpdate?.AddedFiles?.length > 0 &&
                    item?.afterUpdate?.AddedNotes?.length < 1
                  "
                >
                  {{
                    $t('ACTIVITY_HISTORY_TASK_WITH_FILE', {
                      total: item?.afterUpdate?.AddedFiles?.length,
                    })
                  }}
                </span>
                <span
                  v-if="
                    item?.afterUpdate?.AddedNotes?.length > 0 &&
                    item?.afterUpdate?.AddedFiles?.length < 1
                  "
                >
                  {{
                    $t('ACTIVITY_HISTORY_TASK_WITH_NOTE', {
                      total: item?.afterUpdate?.AddedNotes?.length,
                    })
                  }}
                </span>
                <span
                  v-if="
                    item?.afterUpdate?.AddedNotes?.length > 0 &&
                    item?.afterUpdate?.AddedFiles?.length > 0
                  "
                >
                  {{
                    $t('ACTIVITY_HISTORY_TASK_WITH_NOTE_FILE', {
                      totalNote: item?.afterUpdate?.AddedNotes?.length,
                      totalFile: item?.afterUpdate?.AddedFiles?.length,
                    })
                  }}
                </span>
              </template>

              <span
                v-if="item?.platform == platformLogActivity.mobile"
                v-vig-tooltip="$t('COMMON_LABEL_TITLE_COMMENT_ON_MOBILE')"
                class="
                  absolute
                  right-0
                  top-0
                  rounded
                  px-1
                  fill-gray-500
                  font-medium
                  lowercase
                "
                style="font-size: 0.6rem"
              >
                <SynIcon name="mobile" custom-class="w-3 h-3" />
              </span>
            </template>
          </div>
          <span class="text-gray-600 text-2xs px-1 italic">
            <SynLabelDateTime
              format="time"
              ignore-timezone
              :datetime="item?.actionDate"
            />
          </span>
        </div>

        <!--  BODY CHANGE    -->
        <div class="pb-2.5 pt-1">
          <template v-if="item?.activity === ActivityInTask.CREATE">
            <template v-if="item?.subActivity === SubActivityInTask.AddNew">
              <ActivityAttachWorkflowItemTitle
                v-if="item?.afterUpdate?.WorkflowId"
                :activity="item"
              />

              <ActivityAddNewAssignee
                v-if="item?.afterUpdate?.AssigneeId"
                :before-update="item?.beforeUpdate"
                :after-update="item?.afterUpdate"
              />
            </template>
            <template v-if="item?.subActivity === SubActivityInTask.Comment">
              <span
                class="msg-text italic text-xs text-gray-600"
                style="word-break: break-word"
                v-html="item?.afterUpdate?.CommentContent"
              ></span>
            </template>

            <div
              v-if="
                item?.afterUpdate?.AddedFiles?.length > 0 ||
                item?.afterUpdate?.AddedNotes?.length > 0
              "
              class="pt-2"
            >
              <ActivityChangeAttachments
                :before-update="item?.beforeUpdate"
                :after-update="item?.afterUpdate"
              />
            </div>
          </template>

          <template
            v-if="
              [
                SubActivityInTask.PinnedComment,
                SubActivityInTask.DeleteComment,
              ]?.includes(item?.subActivity)
            "
          >
            <span
              class="msg-text italic text-xs text-gray-600"
              :class="[
                item?.subActivity === SubActivityInTask.DeleteComment
                  ? 'line-through'
                  : '',
              ]"
              style="word-break: break-word"
              v-html="item?.afterUpdate?.CommentContent"
            ></span>
          </template>

          <ActivityFinishWorkflowStep
            v-if="item?.subActivity === SubActivityInTask.WorkflowStepComplete"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />
          <ActivityFinishWorkflowStep
            v-if="item?.subActivity === SubActivityInTask.WorkflowStepStart"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
            is-hidden-before
          />
          <ActivityChangeStatus
            v-if="item?.subActivity === SubActivityInTask.ChangeStatus"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />

          <ActivityChangePriority
            v-if="item?.subActivity === SubActivityInTask.ChangePriority"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />

          <ActivityChangeAssignee
            v-if="item?.subActivity === SubActivityInTask.ChangeAssignee"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />

          <ActivityChangeDomain
            v-if="item?.subActivity === SubActivityInTask.ChangeDomain"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />

          <ActivityChangeInformationText
            v-if="
              item?.subActivity === SubActivityInTask.ChangeDescription ||
              item?.subActivity === SubActivityInTask.ChangeName ||
              item?.subActivity === SubActivityInTask.EditComment
            "
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />

          <ActivityChangeAttachments
            v-if="
              item?.subActivity === SubActivityInTask.ChangeAttachments ||
              item?.subActivity === SubActivityInTask.EditCommentAttachments
            "
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />

          <ActivityChangeDeadline
            v-if="item?.subActivity === SubActivityInTask.ChangeDeadline"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />

          <ActivityChangeNote
            v-if="item?.subActivity === SubActivityInTask.ChangeNotes"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />

          <ActivityChangePrivacy
            v-if="item?.subActivity === SubActivityInTask.ChangePrivate"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />
          <ActivityChangeCollaborators
            v-if="
              item?.subActivity === SubActivityInTask.AddCollaborator ||
              item?.subActivity === SubActivityInTask.RemoveCollaborator
            "
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />
          <ActivityChangeTodoList
            v-if="item?.subActivity === SubActivityInTask.ChangeTodoList"
            :before-update="item?.beforeUpdate"
            :after-update="item?.afterUpdate"
          />
          <ActivityChangeApproval
            v-if="item?.subActivity === SubActivityInTask.ApprovalChange"
            :activity="item"
          />
          <ActivitySubTask
            v-if="
              item?.subActivity === SubActivityInTask.SubTaskAdded ||
              item?.subActivity === SubActivityInTask.SubTaskRemoved ||
              item?.subActivity === SubActivityInTask.ParentTaskAdded ||
              item?.subActivity === SubActivityInTask.ParentTaskRemoved
            "
            :activity="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>
