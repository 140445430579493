<script setup lang="ts">
import { computed, onMounted } from 'vue';
import orgStore from '@/store/organization';
import settingStore from '@/store/setting';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { EOrganizationSettings } from '@/application/enums/SettingEnums';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import permissionStore from '@/store/permission';
import DeadlineLockingSetting from '@/ui/modules/settings/organizations/deadline-locking/DeadlineLockingSetting.vue';
import MenuSettingItemReportMenu from '@/ui/modules/settings/menu-setting-items/MenuSettingItemReportMenu.vue';
import TaskFinishProofSettings from '@/ui/modules/settings/organizations/task-finish-proof/TaskFinishProofSettings.vue';
import TaskCreateListAssigneesSettings from '@/ui/modules/settings/organizations/task-create-list-assignees/TaskCreateListAssigneesSettings.vue';
import StartWorkdaySettings from '@/ui/modules/settings/organizations/start-workday/StartWorkdaySettings.vue';

const _orgStore = orgStore();
const _settingStore = settingStore();
const _permissionStore = permissionStore();
const idOrg = getLocalStorage(StorageConstant.ACTIVE_ORG);
const organizationTypes = computed(() => _orgStore.organizationTypes);
const orgInfo = computed(() => _orgStore.organizationInfo);
const timeWorkingEnabled = computed(() => _settingStore.timeWorkingEnabled);
const startWorkingManagement = computed(
  () => _settingStore._organizationSetting.startWorkingManagement
);
const allSystemConfigs = computed(() => _permissionStore.allSystemConfigs);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const taskManagement = computed(
  () => _settingStore._organizationSetting.taskManagement
);
const domainManagement = computed(
  () => _settingStore._organizationSetting.domainManagement
);
const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting.taskManagement.multipleAssigneeSetting
);
const viewTaskByGanttSetting = computed(() => {
  return {
    systemEnable:
      allSystemConfigs.value['ADVANCED_GANTT_CH_GANTT_CHART_ALLOWED_SYSTEM'] ==
      '1',
    organizationEnable:
      allSystemConfigs.value['ADVANCED_GANTT_CH_GANTT_CHART_ALLOWED'] == '1',
  };
});
const setDeadlineTimeSetting = computed(() => {
  return {
    systemEnable:
      allSystemConfigs.value[
        'ADVANCED_TIME_ADV_DEADLINE_TIME_ALLOWED_SYSTEM'
      ] == '1',
    organizationEnable:
      allSystemConfigs.value['ADVANCED_TIME_ADV_DEADLINE_TIME_ALLOWED'] == '1',
  };
});

onMounted(async () => {
  await _orgStore.getOrganizationConfigDefault();
  await _orgStore.getOrganizationInfo(idOrg);
});

const onChangeDomainManagement = (key) => {
  const keySetting = 'domainManagement';
  const payload = {
    showOnTab: domainManagement.value.showOnTab,
    showOnMenu: domainManagement.value.showOnMenu,
  };

  switch (key) {
    case 'TAB':
      payload.showOnTab = !domainManagement.value.showOnTab;
      break;
    case 'MENU':
      payload.showOnMenu = !domainManagement.value.showOnMenu;
      break;
    default:
      break;
  }
  _settingStore.updateOrganizationSetting(keySetting, payload);
};

const openConfirmSettingPrivateTask = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: !taskManagement.value?.defaultPublicTask
      ? translate('SETTING_ORGANIZATION_CONFIRM_PUBLIC_TASK')
      : translate('SETTING_ORGANIZATION_CONFIRM_PRIVATE_TASK'),
    confirmable: true,
    closeable: true,
  });

  const confirm = await ask();
  if (confirm) onChangeSettingPrivateTask();
};

const onChangeSettingPrivateTask = () => {
  const keySetting = 'taskManagement';
  const data = {
    defaultPublicTask: !taskManagement.value?.defaultPublicTask,
  };
  _settingStore.updateOrganizationSetting(keySetting, data);
};

const onChangeSettingMustSelectTask = () => {
  const keySetting = 'startWorkingManagement';
  const data = {
    mustSelectTask: !startWorkingManagement.value.mustSelectTask,
  };
  _settingStore.updateOrganizationSetting(keySetting, data);
};
const onToggleDepartmentManagement = async (enabled: boolean) => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: enabled
      ? translate('SETTING_ORGANIZATION_CONFIRM_TURN_ON_DEPARTMENT_MANAGEMENT')
      : translate(
          'SETTING_ORGANIZATION_CONFIRM_TURN_OFF_DEPARTMENT_MANAGEMENT'
        ),
    confirmable: true,
    closeable: true,
  });

  const confirm = await ask();
  if (!confirm) return;

  _settingStore.updateOrganizationSettingsByModule({
    module: EOrganizationSettings.DEPARTMENT,
    metadata: {
      enabled,
    },
  });
};

const onChangeMultipleAssignee = async (enabled: boolean) => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: enabled
      ? translate('SETTING_ORGANIZATION_MULTIPLE_ASSIGNEE_ENABLE_CONFIRM_ON')
      : translate('SETTING_ORGANIZATION_MULTIPLE_ASSIGNEE_ENABLE_CONFIRM_OFF'),
    confirmable: true,
    closeable: true,
  });

  const confirm = await ask();
  if (!confirm) return;

  const keySetting = 'taskManagement';
  _settingStore.updateOrganizationSetting(keySetting, {
    defaultPublicTask: taskManagement.value?.defaultPublicTask,
    multipleAssigneeSetting: {
      systemEnable: multipleAssigneeSetting.value?.systemEnable,
      organizationEnable: enabled,
    },
  });
};
const onChangeViewTaskByGantt = async () => {
  _permissionStore.setPermissions({
    key: 'ADVANCED_GANTT_CH_GANTT_CHART_ALLOWED',
    value: viewTaskByGanttSetting.value?.organizationEnable ? '0' : '1',
  });
};
const onChangeSetDeadlineTime = async () => {
  _permissionStore.setPermissions({
    key: 'ADVANCED_TIME_ADV_DEADLINE_TIME_ALLOWED',
    value: setDeadlineTimeSetting.value?.organizationEnable ? '0' : '1',
  });
};

const onSettingChange = (setting) => {
  _permissionStore.setPermissions(setting);
};
</script>
<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.ADMIN.SETTING.ORGANIZATION,
      isPage: true,
    }"
    v-perfect-scrollbar
    class="w-full h-full bg-white"
  >
    <!--  My Profile    -->
    <div class="px-6 pt-6 pb-4 flex items-center space-x-2">
      <SynAvatar
        :src="orgInfo?.logoThumbnailUrl"
        :name="orgInfo?.name"
        custom-class="w-12 h-12"
      />
      <div class="flex flex-col">
        <span class="text-gray-700 font-medium">{{ orgInfo?.name }}</span>
        <span class="text-xs text-gray-500">{{
          organizationTypes.find((el) => el.code == orgInfo?.type)?.name ||
          $t('ORGANIZATION_LABEL_TYPE')
        }}</span>
      </div>
    </div>

    <MenuSettingRowItem
      name-icon="organization"
      :title-menu="$t('USERMANAGEMENT_LABEL_INFOMATION')"
      :sub-title="$t('VALUEMANAGEMENT_LABEL_SUB_INFO_ORG')"
      is-sub-menu
      route-name="InformationOrgSetting"
    />

    <MenuSettingRowItem
      name-icon="HideProject"
      :title-menu="$t('SETTING_ORGANIZATION_HIDE_DOMAIN_MENU_TITLE')"
      :sub-title="$t('SETTING_ORGANIZATION_HIDE_DOMAIN_MENU_SUB_TITLE')"
    >
      <template #action>
        <div class="flex items-center space-x-6">
          <div class="flex items-center space-x-2">
            <span class="text-xs text-gray-600 w-full">
              {{ $t('COMMON_LABEL_SHOW_ON_MENU') }}
            </span>
            <AtomSwitch
              :model-value="domainManagement.showOnMenu"
              @change="onChangeDomainManagement('MENU')"
            />
          </div>
          <div class="flex items-center space-x-2">
            <span class="text-xs text-gray-600 w-full">
              {{ $t('COMMON_LABEL_SHOW_ON_TAB') }}
            </span>
            <AtomSwitch
              :model-value="domainManagement.showOnTab"
              @change="onChangeDomainManagement('TAB')"
            />
          </div>
        </div>
      </template>
    </MenuSettingRowItem>

    <MenuSettingItemReportMenu />
    <MenuSettingRowItem
      name-icon="Department"
      :title-menu="$t('SETTING_ORGANIZATION_DEPARTMENT_MANAGEMENT')"
      :sub-title="$t('SETTING_ORGANIZATION_DEPARTMENT_MANAGEMENT_SUB')"
    >
      <template #action>
        <div class="flex-center space-x-2">
          <AtomSwitch
            :model-value="departmentManagementEnabled"
            @change="onToggleDepartmentManagement"
          />
        </div>
      </template>
    </MenuSettingRowItem>

    <div class="bg-gray-100 font-medium text-gray-800 px-6 pb-2 pt-4 uppercase">
      {{ $t('COMMON_LABEL_START_THE_WORKDAY') }}
    </div>
    <MenuSettingRowItem
      v-if="timeWorkingEnabled"
      name-icon="ClockStart"
      :title-menu="$t('SETTING_ORGANIZATION_SELECT_TASK_WHEN_START_WD_TITLE')"
      :sub-title="
        $t('SETTING_ORGANIZATION_SELECT_TASK_WHEN_START_WD__SUB_TITLE')
      "
    >
      <template #action>
        <AtomSwitch
          :model-value="startWorkingManagement.mustSelectTask"
          @change="onChangeSettingMustSelectTask"
        />
      </template>
    </MenuSettingRowItem>
    <StartWorkdaySettings
      :settings="allSystemConfigs"
      @on-setting-change="onSettingChange"
    />

    <!-- TASK SETTINGS -->
    <div class="bg-gray-100 font-medium text-gray-800 px-6 pb-2 pt-4 uppercase">
      {{ $t('SETTING_ORGANIZATION_TASK') }}
    </div>

    <!--PRIVATE TASK-->
    <MenuSettingRowItem
      name-icon="PrivateTask"
      :title-menu="$t('SETTING_ORGANIZATION_PUBLIC_TASK_TITLE')"
      :sub-title="$t('SETTING_ORGANIZATION_PUBLIC_TASK_SUB_TITLE')"
    >
      <template #action>
        <div class="flex-center space-x-2">
          <!--          <span class="text-xs text-gray-600 w-full">{{-->
          <!--              $t('COMMON_LABEL_PRIVATE')-->
          <!--            }}</span>-->
          <AtomSwitch
            :model-value="taskManagement?.defaultPublicTask"
            @change="openConfirmSettingPrivateTask"
          />
        </div>
      </template>
    </MenuSettingRowItem>

    <!--MULTI ASSIGNEE-->
    <MenuSettingRowItem
      name-icon="MultipleAssign"
      :title-menu="$t('SETTING_ORGANIZATION_MULTIPLE_ASSIGNEE_ENABLE_TITLE')"
      :sub-title="$t('SETTING_ORGANIZATION_MULTIPLE_ASSIGNEE_ENABLE_TITLE_SUB')"
    >
      <template #action>
        <AtomSwitch
          :disabled="!multipleAssigneeSetting?.systemEnable"
          :model-value="multipleAssigneeSetting?.organizationEnable"
          @change="onChangeMultipleAssignee"
        />
      </template>
    </MenuSettingRowItem>

    <!-- VIEW TASK BY GANTT -->
    <MenuSettingRowItem
      name-icon="ViewModeGantt"
      :title-menu="
        $t('SETTING_ORGANIZATION_VIEW_TASK_BY_GANTT_CHART_ENABLE_TITLE')
      "
      :sub-title="
        $t('SETTING_ORGANIZATION_VIEW_TASK_BY_GANTT_CHART_ENABLE_SUB_TITLE')
      "
    >
      <template #action>
        <AtomSwitch
          :disabled="!viewTaskByGanttSetting?.systemEnable"
          :model-value="viewTaskByGanttSetting?.organizationEnable"
          @change="onChangeViewTaskByGantt"
        />
      </template>
    </MenuSettingRowItem>

    <!-- Set deadline time -->
    <MenuSettingRowItem
      name-icon="TimePicker"
      :title-menu="
        $t('SETTING_ORGANIZATION_ADVANCED_TIME_ADV_DEADLINE_TIME_ALLOWED')
      "
      :sub-title="
        $t(
          'SETTING_ORGANIZATION_ADVANCED_TIME_ADV_DEADLINE_TIME_ALLOWED_CONTENT'
        )
      "
    >
      <template #action>
        <AtomSwitch
          :disabled="!setDeadlineTimeSetting?.systemEnable"
          :model-value="setDeadlineTimeSetting?.organizationEnable"
          @change="onChangeSetDeadlineTime"
        />
      </template>
    </MenuSettingRowItem>

    <!-- Locking deadline -->
    <div
      v-system-config="[
        {
          functionCode:
            'ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED_SYSTEM',
          functionValue: '1',
        },
      ]"
    >
      <DeadlineLockingSetting />
    </div>

    <TaskFinishProofSettings
      :settings="allSystemConfigs"
      @on-setting-change="onSettingChange"
    />

    <TaskCreateListAssigneesSettings
      :settings="allSystemConfigs"
      @on-setting-change="onSettingChange"
    />

    <div class="mb-4"></div>
  </div>
</template>
