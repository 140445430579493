export enum DayoffType {
    SCHEDULE = 'SCHEDULE',
    EXCEPTION = 'EXCEPTION',
    WFH = 'WFH',
}
export enum DayoffStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    MARK_UNUSED = 'MARK_UNUSED',
    CLOSED = 'CLOSED',
}
export enum DayoffActionType {
    SUBMIT = 'SUBMIT',
    ACCEPT = 'ACCEPT',
    REJECT = 'REJECT',
    CANCEL = 'CANCEL',
    MARK_UNUSED = 'MARK_UNUSED',
    AGREE = 'AGREE',
    DISAGREE = 'DISAGREE',
    RE_OPEN = 'RE_OPEN',
    CLOSE = 'CLOSE',
    MODIFY = 'MODIFY',
}
export enum DayoffReason {
    TRAVELING = 'TRAVELING',
    SICK = 'SICK',
    // URGENT_WORK = 'URGENT_WORK',
    // VISIT_HOMETOWN = 'VISIT_HOMETOWN',
    FAMILY = 'FAMILY',
    PERSONAL = 'PERSONAL',
    OTHERS = 'OTHERS',
}
export enum DayoffExceptionReason {
    TRAFFIC_JAM = 'TRAFFIC_JAM',
    SICK = 'SICK',
    URGENT_WORK = 'URGENT_WORK',
    OTHERS = 'OTHERS',
}
export enum DayoffDateSession {
    MORNING = 1,
    AFTERNOON = 2,
    ALL_DAY = 3,
}
export enum DayoffExceptionType {
    START_LATE = 'START_LATE',
    STOP_EARLY = 'STOP_EARLY',
    BREAK_TIME = 'BREAK_TIME',
}
export enum DayoffDateoffStatus {
    USED = 'USED',
    NOT_USED = 'NOT_USED',
}
export enum DayoffRole {
    USER = 'USER',
    ADMIN = 'ADMIN',
}
