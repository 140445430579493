<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import dashboardStore from '@/store/dashboard';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { UserStatusEnum } from '@/domain/enums/UserStatusEnum';
import conferenceStore from '@/store/conference';
import authStore from '@/store/auth';
import UserById from '@/ui/components/user/UserById.vue';
import {
  onListenEventFirebase,
  getOrganizationPathFirebase,
} from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import appStore from '@/store/app';

const _appStore = appStore();
const _dashboardStore = dashboardStore();
const _conferenceStore = conferenceStore();
const _authStore = authStore();

const myCurrentCallData = ref({} as any);
const callingDuration = ref<number>();
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
const myCallingStatus = computed(() => _authStore.callingStatus);
const myCurrentCallId = computed(() => myCallingStatus.value?.callId);
const myCurrentJoinToken = computed(() => myCallingStatus.value?.joinToken);

const myCurrentCallDataParticipants = computed(
  () => myCurrentCallData.value?.participants
);

const onCallParticipantIds = computed(() => {
  if (!myCurrentCallDataParticipants.value) return [];
  return Object.keys(myCurrentCallDataParticipants.value)?.filter(
    (userId) =>
      myCurrentCallDataParticipants.value[userId]?.status ===
      UserStatusEnum.OnCall
  );
});
const numberParticipants = computed(
  () => Object.keys(myCurrentCallData.value?.participants || {})?.length || 0
);

const init = () => {
  _conferenceStore.getDetailById(myCurrentCallId.value, (data) => {
    myCurrentCallData.value = data;
  });
};

let _callingDurationTimer;

watch(
  () => myCurrentCallId.value,
  async () => {
    if (myCurrentCallId.value) {
      init();
    }
  }
);

watch(
  () => myCurrentCallData.value?.startedTime,
  () => _processCallingDuration()
);

onMounted(() => {
  init();
  onListenDataFromFirebase();
  _processCallingDuration();
});

onUnmounted(() => {
  clearInterval(_callingDurationTimer);
  offListenDataFromFirebase();
});

let unscribleFbVideoCall;
const onListenDataFromFirebase = () => {
  const path = getOrganizationPathFirebase(
    `audioVideoCall/${myCurrentCallId.value}`
  );
  unscribleFbVideoCall = onListenEventFirebase(path, async (data) => {
    myCurrentCallData.value = data.val() ? data.val() : myCurrentCallData.value;
  });
};

const offListenDataFromFirebase = () => {
  unscribleFbVideoCall && unscribleFbVideoCall();
};

const forceEndCall = () => {
  _conferenceStore.forceEndCall(myCurrentJoinToken.value);
  _dashboardStore.setUserStatus(getCurrentUserId(), UserStatusEnum.Online);
};

const _processCallingDuration = () => {
  clearInterval(_callingDurationTimer);

  _callingDurationTimer = setInterval(() => {
    const callStartTime = myCurrentCallData.value?.startedTime;
    const currentTime = _appStore.getCurrentTime();

    callingDuration.value = callStartTime ? currentTime - callStartTime : 0;
  }, 100);
};
</script>

<template>
  <div
    v-if="myCurrentCallId"
    class="flex-center flex-col space-y-2 bg-orange-50 py-2 mb-2"
  >
    <span
      :class="isOpenMenuBar ? 'visible' : 'hidden'"
      style="transition: visibility 0.2s"
      class="font-semibold truncate text-orange-800 animate-pulse text-xs"
    >
      {{
        callingDuration
          ? $t('CALL_LABEL_YOU_ARE_CALLING')
          : $t('VIDEO_CALL_LABEL_WAITING_JOIN_CALL')
      }}
    </span>

    <div
      v-if="callingDuration && isOpenMenuBar"
      class="text-current-400 text-3xl"
    >
      {{ $filters.duration(callingDuration) }}
    </div>

    <div v-if="isOpenMenuBar" class="flex items-center space-x-2">
      <template v-if="onCallParticipantIds && onCallParticipantIds?.length > 0">
        <div class="flex-center p-0.5">
          <UserById
            v-for="item in numberParticipants > 6
              ? onCallParticipantIds.slice(0, 6)
              : onCallParticipantIds"
            :key="item"
            avatar-class="h-8 w-8 -mr-1 border border-white rounded-full"
            is-hidden-name
            :user-id="item"
          />
          <VigDropdown
            v-if="numberParticipants > 6"
            trigger="mouseenter"
            class="flex z-10"
          >
            <template #dropdown-toggle>
              <div
                v-if="numberParticipants > 6"
                class="
                  flex-center
                  -mr-1
                  cursor-pointer
                  h-6
                  w-6
                  rounded-full
                  bg-current-400
                  text-xs text-white
                  font-medium
                "
              >
                {{ '+' + (numberParticipants - 6) }}
              </div>
            </template>
            <template #dropdown-menu>
              <div class="px-2 py-1 text-sm">
                <UserById
                  v-for="item in Object.keys(
                    myCurrentCallDataParticipants
                  )?.slice(6)"
                  :key="item"
                  class="py-1"
                  avatar-class="w-5 h-5"
                  :user-id="item"
                />
              </div>
            </template>
          </VigDropdown>
        </div>
      </template>
    </div>

    <!-- ACTION -->
    <div class="flex-center w-full space-x-1">
      <!-- mute -->
      <!-- <div
        class="
          flex-center
          cursor-not-allowed
          rounded-full
          border border-gray-500
          w-7
          h-7
          bg-gray-50
        "
      >
        <SynIcon
          name="microphone"
          custom-class="w-3 h-3 fill-gray-500 text-gray-500"
        />
      </div> -->

      <!-- end call -->
      <div
        v-vig-tooltip="$t('COMMON_LABEL_LEAVE') || 'End call'"
        class="
          flex-center
          cursor-pointer
          rounded-full
          w-10
          h-10
          bg-red-500
          hover:bg-red-400 hover:shadow-lg
        "
        @click="forceEndCall"
      >
        <SynIcon
          name="phoneEnd"
          class="fill-white text-white"
          custom-class="w-3 h-3 "
        />
      </div>

      <!-- open -->
      <!-- <div
        class="
          flex-center
          cursor-not-allowed
          rounded-full
          border border-gray-500
          w-7
          h-7
          bg-gray-50
        "
      >
        <SynIcon
          name="open-link"
          custom-class="w-3 h-3 fill-gray-500 text-gray-500"
        />
      </div> -->
    </div>
  </div>
</template>
