import BaseRepository from '@/base/repositories/BaseRepository';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

export default class NotificationRepository {
    private static instance: NotificationRepository;

    constructor() {}

    public static getInstance(): NotificationRepository {
        if (!NotificationRepository.instance) {
            // Get from local storage
            NotificationRepository.instance = new NotificationRepository();
        }

        return NotificationRepository.instance;
    }

    getDashboardOverview() {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/Overview`,
            method: 'get',
        });
    }

    createDeviceTokens(data) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);
        return BaseRepository.requestWithAuthorize({
            url: `Notification/RegisterDevice?lang=${lang}`,
            method: 'post',
            data,
        });
    }

    deleteDeviceTokens(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/RemoveDevice`,
            method: 'post',
            data,
        });
    }

    getList(
        maxResultCount,
        skipCount,
        category,
        actionby,
        groups,
        domains,
        types
    ) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);

        const params = [] as any;
        if (lang) params.push(`lang=${lang}`);
        if (category) params.push(`Category=${category}`);
        if (actionby) params.push(`ActionBy=${actionby}`);
        if (groups) params.push(`Groups=${groups}`);
        if (domains) params.push(`Domains=${domains}`);
        if (types) params.push(`Types=${types}`);
        if (maxResultCount) params.push(`MaxResultCount=${maxResultCount}`);
        params.push(`SkipCount=${skipCount}`);

        const queriesStr = params.length ? `?${params.join('&')}` : '';

        const path = `Notification/ListNotificationByUser${queriesStr}`;

        return BaseRepository.requestWithAuthorize({
            url: path,
            method: 'get',
        });
    }

    getOrgUserNotifications(orgId, category, pageIndex, pageSize) {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);

        const params: any = { lang };
        if (orgId) params.orgId = orgId;
        if (category) params.category = category;
        if (pageSize) params.maxResultCount = pageSize;
        params.skipCount = pageIndex || 0;

        return BaseRepository.requestWithAuthorize({
            method: 'get',
            url: `Notification/ListNotificationByUser`,
            params,
        });
    }

    getCountUnread() {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/CountUnreadNotificationsByUser`,
            method: 'get',
        });
    }

    getNotificationUnread() {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/GetNotificationUnread`,
            method: 'get',
        });
    }

    getOrgUserNotificationUnread(orgId) {
        const params: any = {};
        if (orgId) params.orgId = orgId;

        return BaseRepository.requestWithAuthorize({
            method: 'get',
            url: `Notification/GetNotificationUnread`,
            params,
        });
    }

    reactionOnNotification(notificationId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/ReactionOnNotification?notificationId=${notificationId}`,
            method: 'post',
            data,
        });
    }

    updateUserNotifications(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/UpdateNotification`,
            method: 'post',
            data,
        });
    }

    updateOrgUserNotifications(orgId, data) {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/UpdateNotification`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    getNotificationById(id) {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/GetNotificationById?notificationId=${id}`,
            method: 'get',
        });
    }

    deleteNotification(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Notification/DeleteNotification`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_NOTIFICATION_DELETE_USER_SUCCESS'
                ),
            }
        );
    }

    deleteOrgNotification(orgId, data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Notification/DeleteNotification`,
                method: 'post',
                data,
                params: { orgId },
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_NOTIFICATION_DELETE_USER_SUCCESS'
                ),
            }
        );
    }

    sendChatSupportForOrganization() {
        return BaseRepository.requestWithAuthorize({
            url: `Notification/SendChatSupportForOrganization`,
            method: 'post',
        });
    }
}
