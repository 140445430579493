export enum ConversationValidCode {
    VALID = 'VALID',
    UNDEFINED = 'UNDEFINED',
    HAS_NOT_PERMISSION = 'HAS_NOT_PERMISSION',
    OPPONENT_DEACTIVE = 'OPPONENT_DEACTIVE',
    OPPONENT_DELETED = 'OPPONENT_DELETED',
}

export enum ChatPanelStatus {
    CLOSED = 'CLOSED',
    ACTIVE = 'ACTIVE',
    HIDDEN = 'HIDDEN',
}

export enum ChatMessageAction {
    REPLY = 'REPLY',
    FORWARD = 'FORWARD',
    PIN = 'PIN',
    COPY = 'COPY',
    REACTION = 'REACTION',
    SET_BACKGROUND = 'SET_BACKGROUND',
    DOWNLOAD_FILE = 'DOWNLOAD_FILE',
    CREATE_TASK = 'CREATE_TASK',
    DELETE = 'DELETE',
    USER_ACTIONS = 'USER_ACTIONS',
    REMINDER = 'REMINDER',
    GO_TO_MESSAGE = 'GO_TO_MESSAGE',
}

export enum ChatExternalAcceptance {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    BLOCKED = 'BLOCKED',
}

export enum ChatSupporterStatus {
    ACTIVE = 1,
}
