import { onMounted, onUnmounted } from 'vue';

export default function focusOnConversationComposables(
    targetRef,
    handleClickOutSide,
    isNeedToCheck
) {
    const appElm = document.getElementById('app') as HTMLElement;
    onMounted(() => {
        appElm.addEventListener('mousedown', onClickOutSide);
        window.onblur = () => onClickOutSide(null);
    });

    onUnmounted(() => {
        appElm.removeEventListener('mousedown', onClickOutSide);
        window.onblur = null;
    });

    const onClickOutSide = (event) => {
        if (!isNeedToCheck?.value) return;
        if (event && targetRef.value?.contains(event.target)) return;

        handleClickOutSide && handleClickOutSide();
    };
}
