import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import { FilterQuery } from '@/domain/entities/task/FilterTask';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import taskStore from '@/store/task';

export default defineStore({
    id: storeId.TASK_PINNED,
    state: () => ({
        _taskService: TaskService.getInstance(),
        allPinnedTaskByIds: {} as any,
        allPinnedTaskIds: [] as any[],
        currentFilterByPinCode: 'ALL' as
            | 'ALL'
            | 'UN_PIN'
            | 'PIN_GRAY'
            | 'PIN_RED'
            | 'PIN_GREEN'
            | 'PIN_BLUE'
            | 'PIN_YELLOW'
            | 'PIN_PINK',
    }),
    getters: {},
    actions: {
        async getAllPinnedTasks() {
            const payload = new FilterQuery({
                isDefault: true,
                listOwnerId: getCurrentUserId(),
                groupIds: null,
            });

            const res = await TaskService.getInstance().getPinnedTasks(payload);

            const result = res?.result?.map((task) => {
                return {
                    ...task,
                    urgency: getUrgencyValue(task?.priority, task?.important),
                };
            });

            this.allPinnedTaskIds = result?.map((task) => task?.id);

            taskStore().mergeAllTaskByIds(
                result?.reduce((currentResult, currentItem) => {
                    return {
                        ...currentResult,
                        [currentItem?.id]: currentItem,
                    };
                }, {})
            );
        },
    },
});
