<script setup lang="ts">
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import { computed } from 'vue';
import chatStore from '@/store/chat';
import permissionStore from '@/store/permission';
import ConversationLastMessage from '@/ui/modules/messaging/conversation-item/ConversationLastMessage.vue';
import userStore from '@/store/user';

defineProps<{
  conversation: ChatConversationModel;
  isActive: boolean;
}>();

defineEmits(['onBubbleChatClick', 'onBubbleChatRemove']);

const bubbleChatActive = computed(() => chatStore().bubbleChatActive);
const allUserByIds = computed(() => userStore().allUserByIds);

const isPersonalModel = computed<boolean>(
  () => permissionStore().organizationModel === 'PERSONAL'
);
</script>
<template>
  <VigDropdown :arrow="false" placement="left" trigger="mouseenter">
    <template #dropdown-toggle>
      <div
        class="
          rounded-full
          border
          shadow-xl
          bg-white
          cursor-pointer
          relative
          group
          bubble-chat
          fade-in
        "
      >
        <SynAvatarStatusActive
          :id="conversation?.contactId"
          :avatar="
            (conversation?.avatarUrl || conversation?.avatar)?.replace(
              'original',
              'mini'
            )
          "
          :name="conversation?.name"
          :is-user="!conversation?.isGroup"
          :is-group="conversation?.isGroup"
          :custom-class="
            isActive
              ? 'h-14 w-14'
              : bubbleChatActive
              ? 'h-10 w-10'
              : 'h-12 w-12'
          "
          is-hidden-name
          :is-deactive="conversation?.isDeactive"
          :is-deleted="conversation?.isDeleted"
          :is-disallowed="conversation?.isDisallowed"
          :is-external="conversation?.externalChat && !isPersonalModel"
          :is-supporting="conversation?.supportingChat"
          :short-name="conversation?.shortName"
          @click="$emit('onBubbleChatClick')"
        />
        <div
          v-if="conversation?.unSeen > 0"
          class="absolute -left-2 -top-1 rounded-full bg-white p-0.5"
        >
          <div class="rounded-full bg-red-600 text-white text-xs px-1">
            {{ conversation?.unSeen }}
          </div>
        </div>
        <button
          class="
            absolute
            -right-2
            -top-2
            rounded-full
            shadow
            bg-white
            hover:bg-gray-100
            w-5
            h-5
            flex
            justify-center
            items-center
            invisible
            group-hover:visible
          "
          @click.stop="$emit('onBubbleChatRemove')"
        >
          <SynIcon name="Close" />
        </button>
        <div
          v-if="isActive"
          class="
            absolute
            -bottom-3
            left-1/2
            -ml-1
            w-2
            h-2
            rounded-full
            bg-current-500
          "
        ></div>
      </div>
    </template>
    <template #dropdown-menu>
      <div
        v-if="!bubbleChatActive"
        class="px-2 py-2 rounded-md max-w-xs flex flex-col items-start bg-white"
      >
        <span class="font-medium text-sm">
          {{ conversation?.name }}
        </span>
        <ConversationLastMessage
          :conversation="conversation"
          :members="allUserByIds"
          class="flex-1 max-w-[16rem] text-sm text-gray-500"
        />
      </div>
    </template>
  </VigDropdown>
</template>

<style scoped>
@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.bubble-chat.fade-in {
  animation: scaleIn 200ms ease-in-out;
  transform-origin: center center;
}
</style>
