<script setup lang="ts">
import { computed } from 'vue';
import {
  DayoffStatus,
  DayoffActionType,
  DayoffRole,
} from '@/domain/enums/DayoffEnums';
import {
  DayoffStatusUI,
  DayoffActionTypeUI,
} from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import { AdminType, GroupMemberRole } from '@/ui/common/constants/constant';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

import myProfileStore from '@/store/auth/my-profile';
import settingStore from '@/store/setting';
const props = defineProps<{
  requestStatus: DayoffStatus;
  startDate: number;
  requesterId: number;
}>();

defineEmits(['onAction']);

const _settingStore = settingStore();
const _myProfileStore = myProfileStore();

const myDepartment = computed(() => _myProfileStore.myDepartment);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const isAdmin = computed(() => {
  return (
    hasRolePermission(AdminType.Admin) ||
    hasRolePermission(AdminType.Owner) ||
    (departmentManagementEnabled.value &&
      myDepartment.value?.role == GroupMemberRole.Admin)
  );
});

const listActionByStatus = computed(() => {
  if (!props.requestStatus) return [];

  return DayoffStatusUI[props.requestStatus]?.actionTypeList?.filter((o) => {
    const isShowMarkUnused =
      (props.startDate <= Date.now()
        ? o !== DayoffActionType.CANCEL
        : o !== DayoffActionType.MARK_UNUSED && o !== DayoffActionType.CLOSE) ||
      props.requestStatus == DayoffStatus.PENDING;
    const isRequester =
      props.requesterId == getCurrentUserId() &&
      DayoffActionTypeUI[o]?.role == DayoffRole.USER;
    const isAdminRole =
      DayoffActionTypeUI[o]?.role == DayoffRole.ADMIN &&
      isAdmin.value &&
      props.requesterId !== getCurrentUserId();

    return isShowMarkUnused && (isRequester || isAdminRole);
  });
});
</script>

<template>
  <div class="flex-center space-x-2">
    <SynButton
      v-for="action in listActionByStatus"
      :key="action"
      class="border"
      :style="{
        backgroundColor: DayoffActionTypeUI[action]?.color,
        color: '#fff',
        borderColor: DayoffActionTypeUI[action]?.color,
      }"
      @click="$emit('onAction', action)"
    >
      <span>
        {{
          $t(DayoffActionTypeUI[action]?.textCode) ||
          DayoffActionTypeUI[action]?.textDefault
        }}
      </span>
    </SynButton>
  </div>
</template>
