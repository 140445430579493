<script setup lang="ts">
import { computed } from 'vue';
import localConfigsStore from '@/store/local-configs';

const props = withDefaults(
  defineProps<{
    cardId: string;
    prefixId:
      | 'BOARD_BY_DEFAULT_'
      | 'BY_CUSTOM_'
      | 'BOARD_BY_STATUS_'
      | 'BOARD_BY_URGENCY_'
      | 'BOARD_PINNED_'
      | 'BOARD_BY_DEADLINE_';
  }>(),
  {
    type: 'USER',
    prefixId: 'BOARD_BY_DEFAULT_',
  }
);
const _localConfigsStore = localConfigsStore();

const cardIdInCache = computed<any>(() => `${props.prefixId}${props.cardId}`);
const localConfigs = computed<any>(() => _localConfigsStore.localConfigs);
const isCollapse = computed(() => {
  return localConfigs.value['task']?.[cardIdInCache.value]?.isCollapse;
});

const onCollapseBoard = () => {
  let data: any = {
    [cardIdInCache.value]: {
      isCollapse: !isCollapse.value,
    },
  };

  if (props.prefixId == 'BOARD_BY_DEADLINE_')
    data.latestCollapseDeadlineCardAt = Date.now();
  _localConfigsStore.setLocalConfigs('task', data);

  // _taskTabViewStore.setCollapseBoard(tabId);
};
</script>
<template>
  <div>
    <div
      v-if="isCollapse"
      class="
        h-8
        w-4
        rounded
        flex-center
        border
        bg-gray-50
        cursor-pointer
        switch-task-board-item
      "
      @click="onCollapseBoard"
    >
      <span class="switch-task-board-item__hover-to-hidden">
        <SynIcon name="close" custom-class="w-3 h-3" />
      </span>
      <span class="switch-task-board-item__hover-to-show">
        <SynIcon name="eye" custom-class="w-3 h-3" />
      </span>
    </div>

    <div
      v-else
      class="
        h-8
        w-5
        rounded
        flex-center
        border border-current
        bg-current-50
        cursor-pointer
        switch-task-board-item
      "
      @click="onCollapseBoard"
    >
      <span class="switch-task-board-item__hover-to-show">
        <SynIcon name="eye" is-active custom-class="w-3 h-3" />
      </span>
    </div>
  </div>
</template>
<style>
.switch-task-board-item__hover-to-show {
  display: none;
}
.switch-task-board-item:hover .switch-task-board-item__hover-to-show {
  display: flex;
}
.switch-task-board-item__hover-to-hidden {
  display: flex;
}
.switch-task-board-item:hover .switch-task-board-item__hover-to-hidden {
  display: none;
}
</style>
