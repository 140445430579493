<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M49.5 38h-35c-.6 0-1 .4-1 1s.4 1 1 1h35c.6 0 1-.4 1-1s-.4-1-1-1zM49.5 50h-35c-.6 0-1 .4-1 1s.4 1 1 1h35c.6 0 1-.4 1-1s-.4-1-1-1z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M56.5 6h-25V3c0-.6-.4-1-1-1h-16c-.6 0-1 .4-1 1v3h-6c-.6 0-1 .4-1 1v54c0 .6.4 1 1 1h49c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1zm-41 1V4h14v25l-6.4-4.8c-.4-.3-.8-.3-1.2 0L15.5 29zm40 53h-47V8h5v23c0 .4.2.7.6.9.3.2.7.1 1-.1l7.4-5.6 7.4 5.5c.1.1.2.1.3.1H30.5c.1 0 .3 0 .4-.1.3-.2.6-.5.6-.9V8h24z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M49.5 44h-35c-.6 0-1 .4-1 1s.4 1 1 1h35c.6 0 1-.4 1-1s-.4-1-1-1z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
