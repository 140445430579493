<script setup lang="ts">
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    value: number;
  }>(),
  {
    value: 0,
  }
);

const valuePercent = computed(() => props.value);
const progressRef = ref();
</script>

<template>
  <section class="relative flex flex-col">
    <div class="h-1 flex bg-gray-200 rounded-full overflow-hidden">
      <div
        ref="progressRef"
        class="h-full bg-current transition-all"
        :style="{ width: valuePercent + '%' }"
      ></div>
    </div>
    <div
      class="
        absolute
        transition-all
        duration-300
        right-0
        text-xs
        py-2
        text-current-500
      "
    >
      {{ valuePercent + '%' }}
    </div>
  </section>
</template>
