<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.037"
    height="29.038"
    viewBox="0 0 29.037 29.038"
  >
    <g
      id="Group_3854"
      data-name="Group 3854"
      transform="translate(-13.616 -78.783)"
    >
      <path
        id="Path_1468"
        data-name="Path 1468"
        d="M16.837,35.989l3.019.1c.049,0,.1,0,.146,0a4.514,4.514,0,0,0,3.214-1.332l18.6-18.6a2.871,2.871,0,0,0,0-4.06L37.6,7.892a2.871,2.871,0,0,0-4.06,0l-18.6,18.6a4.561,4.561,0,0,0-1.329,3.361l.1,3.019A3.234,3.234,0,0,0,16.837,35.989ZM34.765,9.115a1.142,1.142,0,0,1,1.616,0l4.209,4.21a1.142,1.142,0,0,1,0,1.615L38.328,17.2,32.5,11.376ZM16.17,27.71,31.281,12.6l5.825,5.825L21.994,33.535a2.8,2.8,0,0,1-2.082.824l-3.019-.1a1.5,1.5,0,0,1-1.449-1.449l-.1-3.019a2.824,2.824,0,0,1,.824-2.082Z"
        transform="translate(0 71.732)"
      />
      <path
        id="Path_1469"
        data-name="Path 1469"
        d="M125.774,95.218l-1.022,3.092a.719.719,0,1,1-1.363-.457l3.265-9.611a1.24,1.24,0,0,1,2.348,0l3.278,9.611a.718.718,0,0,1-.68.951h-.054a.718.718,0,0,1-.68-.487l-1.052-3.1h-4.039Zm3.738-1.15L128.4,90.786c-.254-.744-.423-1.421-.592-2.081h-.034c-.169.677-.355,1.37-.575,2.064l-1.116,3.3Z"
        transform="translate(-109.734 -8.617)"
      />
    </g>
  </svg>
</template>
