<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { TaskTemplateEntity } from '@/domain/entities/task/TaskTemplateEntity';
import TaskTemplate from '@/ui/modules/task/components/task-template/TaskTemplate.vue';
import domainStore from '@/store/scope';
import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import TaskSetApproval from '@/ui/modules/task/approval/TaskSetApproval.vue';
import { TaskApprovalStatus } from '@/application/types/task/task.types';
import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';
import {
  TYPE_LIST_BY_IDS,
  EUpdateType,
} from '@/ui/modules/task/workflow/step/task-workflow-step-update-single.const';
import WorkflowStepCodeLabel from '@/ui/modules/workflow/workflow-step/WorkflowStepCodeLabel.vue';

const props = defineProps<{
  workflowSteps: ITaskWorkflowStep[];
  formType: EUpdateType;
  currentType: EUpdateType;
  viewMode: 'EDIT' | 'VIEW';
  selectedIds: string[];
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'onSave', updatedStepList: any[]): void;
  (e: 'update:selectedIds', updatedStepList: any[]): void;
}>();
const _domainStore = domainStore();

const selectableWorkflowSteps = computed<any>(() => {
  const result = props.workflowSteps?.map((step) => {
    return {
      ...step,
      status: step?.status || EWorkflowTaskStepStatus.WAITING,
    };
  });
  return result?.filter(
    (step) => step?.status == EWorkflowTaskStepStatus.WAITING
  );
});

const taskRepeatTemplateRef = ref<any>(null);

const allDomainByIds = computed<any>(() => _domainStore.domainByIds);

const currentTaskTemplate = ref<TaskTemplateEntity>(
  new TaskTemplateEntity({
    id: 'draft',
  })
);

watch(
  [
    () => currentTaskTemplate.value?.groupId,
    () => currentTaskTemplate.value?.assigneeId,
    () => currentTaskTemplate.value?.domainId,
  ],
  () => {
    emit(
      'update:selectedIds',
      filteredWorkflowSteps.value
        ?.filter(
          (step) => props.selectedIds?.includes(step?.id) && !step?.disabled
        )
        ?.map((s) => s?.id)
    );
  }
);

const toggleSelect = (step) => {
  if (!step?.id || step?.disabled) return;

  const existed = props.selectedIds?.includes(step?.id);
  emit(
    'update:selectedIds',
    existed
      ? props.selectedIds?.filter((id) => id !== step?.id)
      : [...new Set([...(props.selectedIds || []), step?.id])]
  );
};

const onCheckAll = () => {
  emit(
    'update:selectedIds',
    filteredWorkflowSteps.value
      ?.filter((u) => !u?.disabled)
      ?.map((step) => step?.id)
  );
};
const onUnCheckAll = () => {
  emit('update:selectedIds', []);
};

const currentSelectAllState = computed<any>(() => {
  if (props.selectedIds.length >= selectableWorkflowSteps.value?.length)
    return {
      key: 'SELECT_ALL',
      class: 'bg-current-300',
      icon: {
        name: 'check',
        class: 'text-white',
      },
      onClick: () => {
        onUnCheckAll();
      },
    };
  if (
    props.selectedIds.length > 0 &&
    props.selectedIds.length < selectableWorkflowSteps.value?.length
  )
    return {
      key: 'SELECT_ALL',
      class: 'bg-current-300',
      icon: {
        name: 'minus-line',
        class: 'w-2.5 fill-white',
      },
      onClick: () => {
        onCheckAll();
      },
    };
  return {
    key: 'SELECT_NONE',
    class: '',
    icon: {
      name: '',
      class: '',
    },
    onClick: () => {
      onCheckAll();
    },
  };
});

const filteredWorkflowSteps = computed<any>(() => {
  const result = props.workflowSteps?.map((step) => {
    const currentStatus = step?.status || EWorkflowTaskStepStatus.WAITING;
    let disabled = currentStatus !== EWorkflowTaskStepStatus.WAITING;
    let disabledReasonCodes = [] as string[];
    if (!disabled) {
      switch (props.currentType) {
        case EUpdateType.RESPONSIBLE:
          {
            if (
              step?.domainId &&
              (currentTaskTemplate.value?.groupId ||
                currentTaskTemplate.value?.assigneeId)
            ) {
              const currentDomain = allDomainByIds.value[step?.domainId];

              disabled = !(currentTaskTemplate.value?.groupId
                ? currentDomain?.groups?.some(
                    (groupId) => groupId == currentTaskTemplate.value?.groupId
                  )
                : currentTaskTemplate.value?.assigneeId
                ? currentDomain?.members?.some(
                    (user) => user?.id == currentTaskTemplate.value?.assigneeId
                  )
                : true);
              disabledReasonCodes = disabled
                ? ['WORKFLOW_UPDATE_MULTIPLE_CONFLICT_DOMAIN']
                : [];
            }
          }
          break;
        case EUpdateType.DOMAIN:
          {
            if (
              currentTaskTemplate.value?.domainId &&
              (step?.groupId || step?.assigneeId)
            ) {
              const currentDomain =
                allDomainByIds.value[currentTaskTemplate.value?.domainId];

              disabled = !(step?.groupId
                ? currentDomain?.groups?.some(
                    (groupId) => groupId == step?.groupId
                  )
                : step?.assigneeId
                ? currentDomain?.members?.some(
                    (user) => user?.id == step?.assigneeId
                  )
                : true);
              disabledReasonCodes = disabled
                ? ['WORKFLOW_UPDATE_MULTIPLE_CONFLICT_DOMAIN']
                : [];
            }
          }
          break;

        default:
          break;
      }
    }
    return {
      ...step,
      status: currentStatus,
      disabled,
      disabledReasonCodes,
    };
  });
  if (props.viewMode == 'VIEW')
    return result?.filter(
      (step) => step?.id && props.selectedIds?.includes(step?.id)
    );
  return result;
});

const isHasValueByType = computed<boolean>(() => {
  if (props.formType == EUpdateType.RESPONSIBLE)
    return (
      currentTaskTemplate.value?.groupId ||
      currentTaskTemplate.value?.assigneeId
    );
  if (props.formType == EUpdateType.DOMAIN)
    return currentTaskTemplate.value?.domainId;
  if (props.formType == EUpdateType.COLLABORATORS)
    return currentTaskTemplate.value?.collaborators?.length > 0;
  if (props.formType == EUpdateType.URGENCY)
    return currentTaskTemplate.value?.urgency;
  if (props.formType == EUpdateType.DEADLINE) return true;
  if (props.formType == EUpdateType.APPROVAL) return true;

  return false;
});

const getData = () => {
  return {
    hasValue: !!isHasValueByType.value,
    formType: props.formType,
    taskTemplate: currentTaskTemplate.value,
  };
};

const onReset = () => {};
const onSetApprover = async (approver) => {
  currentTaskTemplate.value.approvalEnabled = true;
  currentTaskTemplate.value.approvalStatus = TaskApprovalStatus.NOT_REQUESTED;
  currentTaskTemplate.value.approvers = [
    {
      userId: approver?.id,
    },
  ];
};
defineExpose({
  getData,
});
</script>
<template>
  <div
    v-if="
      viewMode !== 'VIEW' ||
      (viewMode == 'VIEW' && isHasValueByType && selectedIds?.length > 0)
    "
    class="flex flex-col flex-1 min-h-0 w-full p-4 gap-4"
  >
    <div class="flex-1 min-h-0 flex w-full gap-4 pt-2 relative">
      <div class="flex-center absolute top-0 right-0">
        <AtomButton
          class="
            h-8
            w-8
            flex-center
            rounded-full
            hover:bg-gray-200
            cursor-pointer
          "
          color="white"
          @click="onReset"
        >
          <div class="h-4 w-4 flex-center rounded border">
            <SynIcon name="close" />
          </div>
        </AtomButton>
      </div>
      <!-- SET VALUE -->
      <section class="flex flex-col" style="width: 32rem">
        <!-- SET VALUE BY TYPE -->
        <div>
          <template v-if="formType == EUpdateType.APPROVAL">
            <TaskSetApproval
              :task="currentTaskTemplate"
              @update:approver="onSetApprover"
            />
          </template>
          <TaskTemplate
            v-else
            ref="taskRepeatTemplateRef"
            v-model:task-template="currentTaskTemplate"
            :view-mode="viewMode == 'EDIT' ? 'ADD' : 'VIEW'"
            is-do-not-need-add-user-info
            is-hidden-exact-date
            is-update-domain-only
            is-hidden-title
            is-hidden-start-time
            :element-list="TYPE_LIST_BY_IDS[formType]?.elementList"
          />
        </div>
      </section>

      <!-- CHOOSE STEP -->
      <section
        class="flex-1 min-h-0 overflow-auto flex flex-col small-scrollbar"
        style="min-width: 20rem"
      >
        <div
          class="w-full sticky top-0 z-10 bg-white flex items-center space-x-2"
        >
          <AtomButton
            class="
              h-8
              w-8
              flex-center
              rounded-full
              hover:bg-gray-200
              cursor-pointer
            "
            color="white"
            :disabled="viewMode == 'VIEW'"
            @click="() => currentSelectAllState?.onClick()"
          >
            <div
              class="h-4 w-4 flex-center rounded border border-current-300"
              :class="currentSelectAllState?.class"
            >
              <SynIcon
                v-if="currentSelectAllState?.icon?.name"
                :name="currentSelectAllState?.icon?.name"
                :custom-class="currentSelectAllState?.icon?.class"
              />
            </div>
          </AtomButton>

          <span class="text-gray-800 font-semibold">
            {{
              $t('GED_NUM_SELECTED', {
                number: selectedIds?.length,
              })
            }}
          </span>
        </div>
        <div
          v-for="step in filteredWorkflowSteps"
          :key="step?.id"
          class="flex items-center hover:bg-gray-50 cursor-not"
          :class="step?.disabled ? 'cursor-not-allowed' : 'cursor-pointers'"
          @click="toggleSelect(step)"
        >
          <div class="h-8 w-8 flex-center group shrink-0">
            <div
              class="h-4 w-4 flex-center rounded border ring-current-300"
              :class="{
                'bg-current-300': selectedIds?.includes(step.id),
                'cursor-pointer group-hover:ring-1 border-current-300':
                  !step?.disabled,
              }"
            >
              <SynIcon
                v-if="selectedIds?.includes(step.id)"
                name="check"
                class="text-white"
              />
            </div>
          </div>
          <div
            class="w-full flex items-center space-x-2 relative"
            :class="{
              'text-gray-500': step?.disabled,
            }"
          >
            <WorkflowStepCodeLabel :model-value="step?.code" readonly />

            <span>
              {{ step?.name }}
            </span>

            <span
              v-if="step?.disabledReasonCodes?.length > 0"
              class="absolute right-2 flex-center"
            >
              <AtomTooltip>
                <template #trigger>
                  <SynIcon name="info" custom-class="w-4 h-4 fill-orange-500" />
                </template>
                <template #body>
                  <div
                    class="
                      p-4
                      w-[32rem]
                      text-left
                      bg-white
                      flex flex-col
                      space-y-2
                      rounded
                    "
                  >
                    <div class="flex gap-2">
                      <span class="font-semibold text-orange-500">
                        {{
                          $t(
                            'WORKFLOW_TASK_STEP_CONFLICT_RESPONSIBLE_WITH_DOMAIN'
                          )
                        }}
                      </span>
                    </div>
                    <ul class="list-disc px-4 space-y-2">
                      <li
                        v-for="(item, index) in step?.disabledReasonCodes"
                        :key="index"
                        class="text-sm italic"
                      >
                        {{ $t(item) }}
                      </li>
                    </ul>
                  </div>
                </template>
              </AtomTooltip>
            </span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
