<script setup lang="ts">
import { computed } from 'vue';
import userStore from '@/store/user';
import UserById from '@/ui/components/user/UserById.vue';

const props = withDefaults(
  defineProps<{
    haveSupplement: boolean;
    startDate: Date;
    endDate: Date;
    budgetMin: number;
    budgetMax: number;
    responsibility: number;
    responsibilityName?: string;
    responsibilityAvatar?: string;
    readonly?: boolean;
    isHiddenCollapse?: boolean;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: 'update:haveSupplement', haveSupplement: boolean): void;
  (e: 'update:startDate', startDate: Date): void;
  (e: 'update:endDate', endDate: Date): void;
  (e: 'update:budgetMin', budgetMin: number): void;
  (e: 'update:budgetMax', budgetMax: number): void;
  (e: 'update:responsibility', responsibility: number): void;
}>();

const _userStore = userStore();

const allActiveUsers = computed(() => {
  return _userStore.allActiveUsers;
});
const allUserByIds = computed(() => _userStore.allUserByIds);

const personInCharge = computed(() => {
  if (!props.responsibility) return {};
  return allUserByIds.value[props.responsibility];
});
const onClickShowInfo = () => {
  // const currentState = props.haveSupplement
  emit('update:haveSupplement', !props.haveSupplement);
  emit('update:budgetMin', 0);
  emit('update:budgetMax', 5000);

  // updateBudgetRange();
};
</script>
<template>
  <!-- More information -->
  <div class="w-full flex flex-col space-y-2" style="max-width: 500px">
    <div class="flex">
      <span
        v-if="!isHiddenCollapse"
        class="
          py-2
          text-gray-600 text-sm
          bg-gray-100
          rounded
          w-max
          space-x-2
          px-3
          cursor-pointer
          flex-center
        "
        @mousedown="onClickShowInfo"
      >
        <span class="w-2 h-2 flex-center">
          <SynIcon
            name="sort-down"
            custom-class="w-2 h-2 fill-gray-500 text-gray-500"
            :class="!haveSupplement ? '-rotate-90' : ''"
          />
        </span>
        <span>
          {{
            !haveSupplement
              ? $t('DOMAIN_LABEL_SHOW_MORE_INFORMATION') || 'More information'
              : $t('DOMAIN_LABEL_MORE_INFORMATION')
          }}
        </span>
      </span>
    </div>
    <template v-if="haveSupplement">
      <div class="flex items-center space-x-4 pb-2 w-full">
        <div class="flex flex-col w-full">
          <span class="py-1 text-current-600 font-semibold text-sm">
            {{ $t('DOMAIN_LABEL_START_DATE') || 'Start date' }}
          </span>
          <AtomDatePicker
            :is-disabled="readonly"
            :model-value="startDate"
            @update:model-value="(value) => $emit('update:startDate', value)"
          />
        </div>
        <div class="flex flex-col w-full">
          <span class="py-1 text-current-600 font-semibold text-sm">
            {{ $t('DOMAIN_LABEL_END_DATE') || 'End date' }}
          </span>
          <AtomDatePicker
            :is-disabled="readonly"
            :model-value="endDate"
            @update:model-value="(value) => $emit('update:endDate', value)"
          />
        </div>
      </div>

      <!-- BUDGET -->
      <div class="flex flex-col space-y-2 pb-2">
        <span class="py-1 text-current-600 font-semibold text-sm pb-2">
          {{ $t('DOMAIN_LABEL_BUDGET') || 'Budget' }} ($)
        </span>
        <AtomRangeSlider
          :disabled="readonly"
          :from-value="budgetMin"
          :to-value="budgetMax"
          @update:from-value="(value) => $emit('update:budgetMin', value)"
          @update:to-value="(value) => $emit('update:budgetMax', value)"
        />
      </div>
      <!-- RESPONSIBLE -->
      <div class="flex flex-col space-y-2 pb-2">
        <span class="text-current-600 font-semibold text-sm">
          {{ $t('DOMAIN_LABEL_RESPONSIBLE') || 'Responsible' }}
        </span>
        <div class="bg-gray-50 relative rounded-md w-1/2">
          <UserById v-if="readonly" :user-id="personInCharge?.id" />
          <SynSelectAssign
            v-else
            input-class="text-xs"
            type="user"
            removeable
            :placeholder="$t('COMMON_LABEL_SELECT_USER')"
            :data="allActiveUsers"
            :current-id="personInCharge?.id"
            :name="personInCharge?.name || personInCharge?.fullName"
            :avatar="personInCharge?.avatar"
            @chosen="(user) => $emit('update:responsibility', user.id)"
            @reset="$emit('update:responsibility', null)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style>
.outline-red {
  outline: 1px solid red;
}

.domain-min-height {
  min-height: 82px;
}
</style>
