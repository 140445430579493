<script setup lang="ts">
import { computed, ref } from 'vue';
import { TaskLife } from '@/domain/enums/taskEnum';
import { getDetailTaskUrl, taskStates } from '@/ui/hooks/taskHook';
import chatStore from '@/store/chat';
import getUserInfo from '@/ui/helpers/user-helper';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import ColumnFilter from './ColumnFilter.vue';
import TaskListTableCell from '@/ui/modules/task/table/TaskListTableCell.vue';
import UserById from '@/ui/components/user/UserById.vue';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { openNotification } from '@/ui/hooks/commonHook';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';
import SynTableCustom from '@/ui/common/molecules/SynTableCustom/SynTableCustom.vue';
import SynTr from '@/ui/common/molecules/SynTableCustom/SynTr.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';

const props = defineProps<{
  taskIds: any[];
  allTaskByIds: any;
  type: 'member' | 'group' | 'domain' | 'my_assign' | 'management';
  selectedTaskIds?: any[];
  hiddenColumnList?: string[];
  isFilterInTable?: boolean;
  isFilterableInColumn?: boolean;
  taskColumnByCode?: any;
  sortDataOrderByKey?: any;
  filterDataByKey?: any;
  isRelatedTab?: boolean;
  isPinnedTaskTab?: boolean;
  isLoadingFilterTask?: boolean;
  isFocusedOnTaskId?: any;
  isHightLightOpenTask?: boolean;
  isLoadingMoreActionByTaskId?: any;
  keySearch?: string;
  isLoadMore?: boolean;
  canLoadMore?: boolean;
  isBlockHiddenColumn?: boolean;
  isSharedList?: boolean;
}>();

defineEmits<{
  (e: 'reload'): void;
  (e: 'onLoadMore'): void;
  (e: 'onClickSort', key, name, orderFunction): void;
  (e: 'onClickHiddenColumn', key: string): void;
  (e: 'onFilterByKeyOnColumn', key, filters): void;
  (e: 'onResetFilterOnColumn'): void;
  (e: 'onOpenTaskDetail'): void;
}>();

const _chatStore = chatStore();

const isGroup = computed(() => props.type == 'group');
const isDomain = computed(() => props.type == 'domain');
const isMyAssign = computed(() => props.type == 'my_assign');

const isNewChat = ref(false);
const currentTaskId = ref(0 as number);

const { goToConversation } = useChatCommon();

const onShareToChat = (taskId) => {
  currentTaskId.value = taskId;
  isNewChat.value = true;
};

const onChatAboutTask = () => {
  isNewChat.value = false;
};

const onChatAboutTaskByUserId = (userId) => {
  if (!userId) return;

  const conversationId = _chatStore.checkExistedConversation(userId);

  isNewChat.value = false;

  const userInfo = getUserInfo(userId);

  const conversation = {
    id: conversationId,
    name: userInfo.name,
    avatar: userInfo.avatarUrl || userInfo.avatar,
    contactId: userId,
  };

  goToConversation(conversation);

  setTimeout(() =>
    _chatStore.setChatAboutTask(props.allTaskByIds[currentTaskId.value])
  );
};

const onCopyLink = (task) => {
  if (!task?.code) return;
  const url = getDetailTaskUrl(task.code, true);
  const copied = copyToClipBroard(url);
  if (copied) {
    openNotification({
      body: translate('COMMON_LABEL_COPIED'),
      duration: 2000,
    });
  }
};
</script>

<template>
  <div
    v-perfect-scrollbar
    v-scroll-infinite="
      () => {
        $emit('onLoadMore');
      }
    "
    class="h-full overflow-hidden relative"
  >
    <SynTableCustom class="rounded-none">
      <template #header>
        <SynTr class="rounded-none">
          <SynTh style="width: 3rem">
            <div class="w-8 h-8">
              <slot v-if="isFilterInTable" name="custom-filter"></slot>
            </div>
          </SynTh>
          <!--   STATUS       -->
          <SynTh
            v-if="taskColumnByCode['STATUS']?.visible"
            :label="$t('TASK_EXPORT_COLUMNS_STATUS')"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['taskLife']"
            position-text-header="justify-start"
            style="width: 0; min-width: 5rem"
            @on-sort="
              $emit(
                'onClickSort',
                'taskLife',
                $t('TASK_TABLE_LABEL_TASKS'),
                (t) => {
                  return t.taskLife == TaskLife.InProcess
                    ? 1
                    : t.taskLife == TaskLife.Todo
                    ? 2
                    : t.taskLife == TaskLife.Created
                    ? 3
                    : t.taskLife == TaskLife.Finished
                    ? 4
                    : taskStates[t.taskLife]?.index + 4;
                }
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'taskLife')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                type="status"
                :data-filters="filterDataByKey['taskLife']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'taskLife', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'taskLife')"
              />
            </template>
          </SynTh>
          <!--   TASKS       -->
          <SynTh
            :label="$t('TASK_TABLE_LABEL_TASKS')"
            is-sort
            :order-data="sortDataOrderByKey['name']"
            style="min-width: 24rem"
            @on-sort="
              $emit('onClickSort', 'name', $t('TASK_TABLE_LABEL_TASKS'), 'name')
            "
          >
            <template v-if="isFilterableInColumn && !isGroup" #custom>
              <ColumnFilter
                type="group"
                :data-filters="filterDataByKey['groupId']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'groupId', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'groupId')"
              />
            </template>
          </SynTh>
          <!--   DOMAIN       -->
          <SynTh
            v-if="
              !hiddenColumnList?.some((c) => c == 'DOMAIN') &&
              taskColumnByCode['DOMAIN']?.visible
            "
            :label="$t('TASK_CREATE_FORM_DOMAIN')"
            position-text-header="justify-center"
            class="w-28"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['domainId']"
            @on-sort="
              $emit(
                'onClickSort',
                'domainId',
                $t('TASK_TABLE_LABEL_PROJECT'),
                'domainId'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'DOMAIN')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                v-if="isDomain"
                type="project"
                :data-filters="filterDataByKey['projectId']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'projectId', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'projectId')"
              />
              <ColumnFilter
                v-else
                type="domain"
                :data-filters="filterDataByKey['domainId']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'domainId', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'domainId')"
              />
            </template>
          </SynTh>
          <!--   DOMAIN       -->
          <SynTh
            v-if="isSharedList"
            :label="$t('COMMON_LABEL_SHARED_BY')"
            position-text-header="justify-center"
            class="w-28"
          />
          <!--   URGENCY       -->
          <SynTh
            v-if="taskColumnByCode['URGENCY']?.visible"
            :label="$t('TASK_TABLE_LABEL_URGENCY')"
            position-text-header="justify-center"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['urgency']"
            @on-sort="
              $emit(
                'onClickSort',
                'urgency',
                $t('TASK_TABLE_LABEL_URGENCY'),
                'urgency'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'URGENCY')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                type="urgency"
                :data-filters="filterDataByKey['urgency']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'urgency', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'urgency')"
              />
            </template>
          </SynTh>

          <!-- DO TODAY -->
          <SynTh
            v-if="taskColumnByCode['DO_TODAY']?.visible"
            :label="$t('TASK_CREATE_FORM_DOING_TODAY')"
            position-text-header="justify-center"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['isPlannedToTreat']"
            @on-sort="
              $emit(
                'onClickSort',
                'isPlannedToTreat',
                $t('TASK_TABLE_LABEL_URGENCY'),
                'isPlannedToTreat'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'DO_TODAY')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                type="radio"
                :data-filters="filterDataByKey['doToday']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'doToday', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'doToday')"
              />
            </template>
          </SynTh>
          <!-- FINISH TODAY -->
          <SynTh
            v-if="taskColumnByCode['FINISH_TODAY']?.visible"
            :label="$t('TASK_CREATE_FORM_FINISH_TODAY')"
            position-text-header="justify-center"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['isPlannedToFinish']"
            @on-sort="
              $emit(
                'onClickSort',
                'isPlannedToFinish',
                $t('TASK_TABLE_LABEL_URGENCY'),
                'isPlannedToFinish'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'FINISH_TODAY')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                type="radio"
                :data-filters="filterDataByKey['finishToday']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'finishToday', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'finishToday')"
              />
            </template>
          </SynTh>
          <!--   DEADLINE       -->
          <SynTh
            v-if="taskColumnByCode['DEADLINE']?.visible"
            :label="$t('TASK_TABLE_LABEL_DEADLINE')"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['scheduleTime']"
            position-text-header="justify-start"
            @on-sort="
              $emit(
                'onClickSort',
                'scheduleTime',
                $t('TASK_TABLE_LABEL_DEADLINE'),
                'scheduleTime'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'DEADLINE')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                type="date"
                is-nothing
                :data-filters="filterDataByKey['scheduleTime']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'scheduleTime', data)
                "
                @on-reset-filter="
                  $emit('onResetFilterOnColumn', 'scheduleTime')
                "
              />
            </template>
          </SynTh>
          <!--   DEADLINE       -->
          <SynTh
            v-if="taskColumnByCode['OVERDUE']?.visible"
            :label="$t('COMMON_LABEL_OVERDUE')"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['overdue']"
            position-text-header="justify-start"
            @on-sort="
              $emit(
                'onClickSort',
                'overdue',
                $t('COMMON_LABEL_OVERDUE'),
                (t) => {
                  return t?.taskSchedule?.overdue;
                }
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'OVERDUE')"
          />
          <!--   ASSIGNEE       -->
          <SynTh
            v-if="
              isGroup ||
              isMyAssign ||
              isDomain ||
              isRelatedTab ||
              isPinnedTaskTab
            "
            :label="$t('TASK_LABEL_ASSIGNEE')"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['assigneeId']"
            position-text-header="justify-center"
            @on-sort="
              $emit(
                'onClickSort',
                'assigneeId',
                $t('TASK_LABEL_ASSIGNEE'),
                'assigneeId'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'ASSIGNEE')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                type="user"
                :data-filters="filterDataByKey['assigneeId']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'assigneeId', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'assigneeId')"
              />
            </template>
          </SynTh>
          <!--   REPORTER       -->
          <SynTh
            v-if="
              !hiddenColumnList?.some((c) => c == 'REPORTER') &&
              (isGroup || !isMyAssign) &&
              taskColumnByCode['REPORTER']?.visible
            "
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :label="$t('TASK_TABLE_LABEL_REPORTER')"
            :order-data="sortDataOrderByKey['creatorId']"
            position-text-header="justify-center"
            @on-sort="
              $emit(
                'onClickSort',
                'creatorId',
                $t('TASK_TABLE_LABEL_REPORTER'),
                'creatorId'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'REPORTER')"
          >
            <template #custom>
              <ColumnFilter
                v-if="isFilterableInColumn"
                type="user"
                :data-filters="filterDataByKey['creatorId']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'creatorId', data)
                "
                @on-reset-filter="$emit('onResetFilterOnColumn', 'creatorId')"
              />
            </template>
          </SynTh>
          <!--   CREATED_DATE       -->
          <SynTh
            v-if="taskColumnByCode['CREATED_TIME']?.visible"
            :label="$t('TASK_TABLE_LABEL_CREATED_DATE')"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['creationTime']"
            position-text-header="justify-end"
            @on-sort="
              $emit(
                'onClickSort',
                'creationTime',
                $t('TASK_TABLE_LABEL_CREATED_DATE'),
                'creationTime'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'CREATED_TIME')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                type="date"
                :data-filters="filterDataByKey['creationTime']"
                @on-ok="
                  (data) => $emit('onFilterByKeyOnColumn', 'creationTime', data)
                "
                @on-reset-filter="
                  $emit('onResetFilterOnColumn', 'creationTime')
                "
              />
            </template>
          </SynTh>
          <!--   UPDATED_DATE       -->
          <SynTh
            v-if="taskColumnByCode['UPDATED_TIME']?.visible"
            :label="$t('TASK_TABLE_LABEL_UPDATED_DATE')"
            is-sort
            :is-hidden-able="!isBlockHiddenColumn"
            :order-data="sortDataOrderByKey['lastModificationTime']"
            position-text-header="justify-end"
            @on-sort="
              $emit(
                'onClickSort',
                'lastModificationTime',
                $t('TASK_TABLE_LABEL_UPDATED_DATE'),
                'lastModificationTime'
              )
            "
            @on-hidden="$emit('onClickHiddenColumn', 'UPDATED_TIME')"
          >
            <template v-if="isFilterableInColumn" #custom>
              <ColumnFilter
                type="date"
                :data-filters="filterDataByKey['lastModificationTime']"
                @on-ok="
                  (data) =>
                    $emit('onFilterByKeyOnColumn', 'lastModificationTime', data)
                "
                @on-reset-filter="
                  $emit('onResetFilterOnColumn', 'lastModificationTime')
                "
              />
            </template>
          </SynTh>
          <!--   RELOAD       -->
          <SynTh>
            <div class="pl-3">
              <div v-if="!isFilterInTable" class="w-full flex-center">
                <SynIcon
                  class="
                    text-gray-600
                    fill-gray-500
                    cursor-pointer
                    hover:fill-current
                  "
                  name="reload"
                  :title="$t('TASK_LIST_LABEL_RELOAD') || 'Reload'"
                  @click="$emit('reload')"
                />
              </div>
            </div>
          </SynTh>
        </SynTr>
      </template>
      <template #body>
        <syn-table-tr-loading v-if="isLoadingFilterTask" class="h-full">
          <div
            v-for="item in isGroup ? 10 : 9"
            :key="item"
            class="table-cell p-2"
          >
            <div class="p-2 w-full bg-gray-200 rounded-md"></div>
          </div>
        </syn-table-tr-loading>
        <template v-else-if="taskIds?.length > 0">
          <SynTr
            v-for="taskId in taskIds"
            :key="taskId"
            :row-id="taskId"
            :custom-class="isFocusedOnTaskId === taskId ? 'font-semibold' : ''"
            class="
              hover:bg-gray-50
              relative
              task-table-item_row
              hover-to-show__parent
              hover-to-hidden__parent
            "
            :is-high-light="isFocusedOnTaskId == taskId || isHightLightOpenTask"
          >
            <TaskListTableCell
              v-if="allTaskByIds[taskId]"
              :task="allTaskByIds[taskId]"
              :type="type"
              :is-loading-more-action-by-task-id="isLoadingMoreActionByTaskId"
              :is-selecting="selectedTaskIds?.length > 0"
              :is-shared-list="isSharedList"
              @on-open-task-detail="
                $emit('onOpenTaskDetail', allTaskByIds[taskId])
              "
              @share-to-chat="onShareToChat(taskId)"
              @copy-link="onCopyLink(allTaskByIds[taskId])"
              @go-to-task="(task) => $emit('onOpenTaskDetail', task)"
            />
          </SynTr>
          <slot name="footer"></slot>
        </template>
        <template v-else>
          <slot name="no-data"></slot>
        </template>
      </template>
    </SynTableCustom>
    <div v-if="!isLoadMore && canLoadMore" class="flex-center w-full p-2">
      <button
        class="px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300 text-sm"
        @click="$emit('onLoadMore')"
      >
        {{ $t('GED_SHOW_MORE') || 'Show more' }}
      </button>
    </div>
  </div>

  <ModalNewChat
    v-if="isNewChat"
    :title="
      $t('COMMON_LABEL_DISCUSS_ABOUT_WITH') || 'Discuss about this task with'
    "
    :attached-task="allTaskByIds[currentTaskId]"
    @on-close="isNewChat = false"
    @on-conversation-click="onChatAboutTask"
  >
    <template #discuss>
      <div class="flex-center flex-col list-ul">
        <div
          v-if="allTaskByIds[currentTaskId]?.assigneeId"
          class="flex-center list-li space-x-2"
          @click="
            onChatAboutTaskByUserId(allTaskByIds[currentTaskId]?.assigneeId)
          "
        >
          <span>
            {{
              $t('COMMON_LABEL_DISCUSS_ABOUT_WITH') ||
              'Discuss about this task with'
            }}
          </span>
          <UserById :user-id="allTaskByIds[currentTaskId]?.assigneeId" />
        </div>
        <div
          v-if="
            allTaskByIds[currentTaskId]?.creatorId !==
            allTaskByIds[currentTaskId]?.assigneeId
          "
          class="flex-center list-li space-x-2"
          @click="
            onChatAboutTaskByUserId(allTaskByIds[currentTaskId]?.creatorId)
          "
        >
          <span>
            {{
              $t('COMMON_LABEL_DISCUSS_ABOUT_WITH') ||
              'Discuss about this task with'
            }}
          </span>
          <UserById :user-id="allTaskByIds[currentTaskId]?.creatorId" />
        </div>
      </div>
    </template>
  </ModalNewChat>
</template>
<style>
#task-list-scrollbar-container {
  position: relative;
}
</style>
