<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    disabled?: boolean;
    isLoading?: boolean;
    colorSwitch?: string;
    label?: string;
    size?: 'small' | 'medium' | 'large';
  }>(),
  {
    colorSwitch: 'bg-current',
    isLoading: false,
    disabled: false,
    label: '',
    size: 'medium', //small, medium, large
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'change', value: boolean): void;
  (e: 'click'): void;
}>();

const onClick = () => {
  if (props.disabled) return;
  const newValue = !props.modelValue;
  emit('update:modelValue', newValue);
  emit('change', newValue);
  emit('click');
};

const classByState = {
  active: {
    enabled: 'bg-current hover:bg-current-500 cursor-pointer',
    disabled: 'bg-current cursor-not-allowed',
  },
  deactive: {
    enabled: 'bg-gray-400 hover:bg-gray-500 cursor-pointer',
    disabled: 'bg-gray-300 cursor-not-allowed',
  },
};

const className = computed(() => {
  if (props.modelValue)
    return classByState.active[
      props.disabled || props.isLoading ? 'disabled' : 'enabled'
    ];
  return classByState.deactive[
    props.disabled || props.isLoading ? 'disabled' : 'enabled'
  ];
});

const sizeSwitch = computed(() => {
  switch (props.size) {
    case 'medium':
      return {
        switch: 'w-9 h-5',
        control: 'w-4 h-4',
        translate: 'translate-x-4',
        label: '',
      };
    case 'small':
      return {
        switch: 'w-7 h-4',
        control: 'w-3 h-3',
        translate: 'translate-x-3',
        label: 'text-xs',
      };
    case 'large':
      return {
        switch: 'w-16 h-8',
        control: 'w-7 h-7',
        translate: 'translate-x-8',
        label: '',
      };
    default:
      return {
        switch: 'w-9 h-5',
        control: 'w-4 h-4',
        translate: 'translate-x-4',
        label: '',
      };
  }
});
</script>

<template>
  <div class="flex-center space-x-2 min-w-max">
    <div class="flex justify-between items-center" @click.stop="onClick">
      <div
        class="flex items-center rounded-full duration-300 ease-in-out"
        :class="`${className} ${sizeSwitch.switch}`"
      >
        <div
          class="rounded-full m-0.5 transform duration-300 ease-in-out"
          style="box-shadow: rgba(0, 129, 119, 0.35) 0px 0px 6px"
          :class="`${modelValue ? sizeSwitch.translate : ''} ${
            !disabled ? 'bg-white ring-current' : ''
          } ${disabled ? 'bg-gray-200 ring-gray-300' : ''} ${
            sizeSwitch.control
          }`"
        ></div>
      </div>
    </div>
    <slot>
      <span v-if="label" :class="sizeSwitch?.label">
        {{ label }}
      </span>
    </slot>
  </div>
</template>
