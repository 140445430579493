<script setup lang="ts">
import { computed, ref } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import $constants from '@/ui/plugins/constants';
import driveDocumentStore from '@/store/driveDocument';
import { useFormValidation } from '@/ui/common/composables/validations/form-validation.composable';
import { DriveProvider } from '@/ui/common/constants/drive';
import FileStorageRepository from '@/application/repositories/FileStorageRepository';
import AddFileLocation from '@/ui/modules/ged/_commons/add-file-location/AddFileLocation.vue';

const props = defineProps<{
  documentType: string;
  location: any;
}>();

const emits = defineEmits(['onClose', 'onAdded']);

const _driveDocumentStore = driveDocumentStore();

const document = ref<any>({ name: '' });
const isSaving = ref<boolean>(false);

const { isValidLinkName } = useFormValidation();

const googleUser = computed(() => _driveDocumentStore.googleUser);

const onFormSubmit = async () => {
  isSaving.value = true;

  try {
    // Create file in Google Drive
    const file = await _driveDocumentStore.createGoogleDocument(
      document.value.name,
      props.documentType,
      props.location?.groupId || props.location?.domainId
    );

    // Create file in Tictop Resources
    if (file) {
      const newDoc = {
        ...document.value,
        link: file.path,
        contentType: props.documentType,
        folderId: props.location?.folderId,
        groupId: props.location?.groupId,
        domainId: props.location?.domainId,
        userId: props.location?.userId,
        metadata: {
          driveProvider: DriveProvider.Google,
          driveFileId: file.id,
          driveUserEmail: googleUser.value?.email,
          driveUserName: googleUser.value?.displayName,
          driveUserAvatar: googleUser.value?.avatar,
        },
      };

      try {
        const res: any =
          await FileStorageRepository.getInstance().addDriveDocument(newDoc);

        if (res?.result) emits('onAdded', res?.result);
      } catch (e) {
        console.log(e);
      }
    }
  } catch (e) {
    console.log(e);
  }

  isSaving.value = false;
};
</script>

<template>
  <syn-modal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    style-body="py-4 px-2 flex flex-col"
    :disable-click-outside="true"
    :disable-esc-to-close="true"
    disable-auto-focus
    @cancel="$emit('onClose')"
  >
    <template #header>
      <span v-if="documentType === $constants.FILE.TYPE.GOOGLE_DOC">
        {{ $t('GED_GOOGLE_ADD_DOCUMENT') }}
      </span>
      <span v-else-if="documentType === $constants.FILE.TYPE.GOOGLE_SHEET">
        {{ $t('GED_GOOGLE_ADD_SPREADSHEET') }}
      </span>
      <span
        v-else-if="documentType === $constants.FILE.TYPE.GOOGLE_PRESENTATION"
      >
        {{ $t('GED_GOOGLE_ADD_PRESENTATION') }}
      </span>
    </template>

    <template #body>
      <Form id="form-drive-add" class="px-3" @submit="onFormSubmit">
        <div class="mb-3 flex">
          <div class="w-20 text-gray-500">{{ $t('GED_FOLDER_IN') }}</div>
          <AddFileLocation
            :location="location"
            :is-drive-document="true"
            class="font-semibold"
          />
        </div>

        <div class="flex items-center">
          <label for="form-drive-add-name" class="block w-20 text-gray-500">
            {{ $t('COMMON_LABEL_NAME') }}</label
          >
          <div class="flex-1">
            <Field
              id="form-drive-add-name"
              v-model="document.name"
              v-cusFocus="true"
              type="text"
              class="vig-input"
              name="fieldName"
              :rules="isValidLinkName"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              :validate-on-model-update="false"
            />
            <ErrorMessage
              name="fieldName"
              class="block mt-1 text-sm text-red-600"
            />
          </div>
        </div>
      </Form>
    </template>

    <template #footer>
      <div class="px-3 py-2 flex justify-end space-x-2">
        <vig-button ghost color="gray" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') }}
        </vig-button>
        <vig-button type="submit" form="form-drive-add" :processing="isSaving">
          <span>{{ $t('COMMON_LABEL_SAVE') }}</span>
        </vig-button>
      </div>
    </template>
  </syn-modal>
</template>
