<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    titleText: string;
    subTitleText: string;
    containerClass?: string;
    containerColor?: string;
    nameIcon?: string;
    isHtmlText?: boolean;
    confirmText?: string;
  }>(),
  {
    containerClass: 'w-1/4',
    containerColor: 'orange',
    nameIcon: 'info',
  }
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

const icon = computed(() => props.nameIcon);
// const colorModal = computed(
//   () =>
//     `bg-${props.containerColor}-50 border-${props.containerColor}-500 text-${props.containerColor}-700`
// );
const colorIcon = computed(
  () => `text-${props.containerColor}-700 fill-${props.containerColor}-500`
);
const onCancel = () => {
  emit('cancel');
};
</script>

<template>
  <SynModal
    z-index="z-60"
    style-body="p-0"
    container-style="max-height: 90%; max-width: 45rem; min-width: 32rem;"
    is-hidden-header
    @cancel="onCancel"
  >
    <template #body>
      <div class="py-10 px-6">
        <div class="flex space-x-4 items-start">
          <div>
            <SynIcon :name="icon" :class="colorIcon" custom-class="w-9 h-9" />
          </div>
          <div class="" role="alert">
            <p class="font-medium text-xl pb-4 text-current">
              {{ titleText }}
            </p>
            <span class="text-gray-600 space-y-2" v-html="subTitleText"></span>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          type-outline
          :label="$t('COMMON_LABEL_CLOSE')"
          @click="$emit('cancel')"
        />
        <SynButton
          v-if="confirmText"
          :label="confirmText"
          @click="$emit('confirm')"
        />
      </div>
    </template>
  </SynModal>
</template>
