<script setup lang="ts">
// *** IMPORTS ***
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ChatApiRepository from '@/application/repositories/chat/ChatApiRepository';
import { computed, ref } from 'vue';
import chatStore from '@/store/chat';
import { useRoute, useRouter } from 'vue-router';
import myProfileStore from '@/store/auth/my-profile';
import { GroupMemberRole } from '@/ui/common/constants/constant';

// *** PROPS, EMITS ***
const props = defineProps<{
  conversation: ChatConversationModel;
}>();

const emit = defineEmits(['onClose']);

// *** PRIVATE VARIABLES ***
const _route = useRoute();
const _router = useRouter();
const _chatStore = chatStore();
const _myProfileStore = myProfileStore();

// *** COMPOSABLES ***

// *** REFS ***
const isDeleteForAllMembers = ref<boolean>();
const isDeleting = ref<boolean>();

// *** COMPUTED ***
const isAdminGroup = computed(() => {
  if (!props.conversation?.id) return false;

  const converInfo: any = _chatStore.getConversationInfo(props.conversation.id);
  if (!converInfo?.isGroup) return false;

  const myUserId = _myProfileStore.myProfile?.id;
  const myMember = converInfo?.groupUsers?.find((u) => u?.userId === myUserId);
  return myMember?.role === GroupMemberRole.Admin;
});

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onConfirmClick = async () => {
  if (!props.conversation.id) return;

  isDeleting.value = true;

  try {
    const converId = props.conversation.id;

    const res = await ChatApiRepository.getInstance().deleteUserConversation(
      converId,
      {
        isDeleteForAllMembers:
          isAdminGroup.value && isDeleteForAllMembers.value,
      }
    );

    if (res?.result) {
      _removeConversation(converId);

      emit('onClose');
    }
  } catch (e) {
    console.log(e);
  }

  isDeleting.value = false;
};

// *** PRIVATE FUNCTIONS ***
const _removeConversation = (converId) => {
  if (!converId) return;

  _chatStore.removeBubbleChat({ id: converId });

  if (
    _route?.path?.includes('/messages') &&
    _chatStore.conversationActive?.id === converId
  ) {
    _router.push({
      name: 'Messages',
    });
  }
};

// *** EXPOSES ***
</script>

<template>
  <SynModal
    is-hidden-header
    container-class="w-[30rem]"
    @cancel="$emit('onClose')"
  >
    <template #body>
      <div class="flex space-x-3 pt-3">
        <SynIcon name="info" custom-class="w-12 h-12" class="fill-orange-500" />
        <div>
          <div class="text-xl mt-2">{{ $t('CHAT_DELETE_CONFIRM_MSG') }}</div>
          <div v-if="isAdminGroup" class="mt-4">
            <VigCheckbox
              v-model="isDeleteForAllMembers"
              align="start"
              checkbox-class="mt-0.5"
              label="CHAT_DELETE_FOR_ALL_MEMBERS_MSG"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end space-x-2 p-3">
        <VigButton
          ghost
          color="gray"
          label="COMMON_LABEL_CANCEL"
          @click="$emit('onClose')"
        ></VigButton>
        <VigButton :processing="isDeleting" @click="onConfirmClick">
          <span>{{ $t('COMMON_LABEL_CONFIRM') }}</span>
        </VigButton>
      </div>
    </template>
  </SynModal>
</template>

<style scoped></style>
