<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0,0,256,256"
  >
    <g transform="translate(-12.8,-12.8) scale(1.1,1.1)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="translate(-2.66667,0) scale(5.33333,5.33333)">
          <rect
            x="-32"
            y="12"
            transform="rotate(-90)"
            width="16"
            height="16"
            fill="#ffffff"
          ></rect>
          <path d="M3,17v14l5,1l5,-1v-14l-5,-1z" fill="#1e88e5"></path>
          <path
            d="M37,24v14c0,1.657 -1.343,3 -3,3h-21l-1,-5l1,-5h14v-7l5,-1z"
            fill="#4caf50"
          ></path>
          <path
            d="M37,10v14h-10v-7h-14l-1,-5l1,-5h21c1.657,0 3,1.343 3,3z"
            fill="#fbc02d"
          ></path>
          <path
            d="M13,31v10h-7c-1.657,0 -3,-1.343 -3,-3v-7z"
            fill="#1565c0"
          ></path>
          <path d="M13,7v10h-10z" fill="#e53935"></path>
          <path d="M38,24l-1,8.45l-10,-8.45l10,-8.45z" fill="#2e7d32"></path>
          <path
            d="M46,10.11v27.78c0,0.84 -0.98,1.31 -1.63,0.78l-7.37,-6.22v-16.9l7.37,-6.22c0.65,-0.53 1.63,-0.06 1.63,0.78z"
            fill="#4caf50"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
