import { computed, onMounted } from 'vue';
import { useElementBounding, useElementSize } from '@vueuse/core';

export default function dynamicPosition(target, element) {
    const { height: bodyHeight } = useElementSize(document.body);
    const padding = 5;
    const {
        height: targetHeight,
        left: targetLeft,
        top: targetTop,
    } = useElementBounding(target);
    const { height: elementHeight } = useElementBounding(element);
    onMounted(() => {});
    const dynamicPosition = computed(() => {
        const totalHeight = targetHeight.value + elementHeight.value;

        const onTopTargetY = targetTop.value - elementHeight.value - padding;
        const onBottomTargetY = targetTop.value + targetHeight.value + padding;

        const isOverScreen = totalHeight + targetTop.value > bodyHeight.value;

        const fitHeight = isOverScreen ? onTopTargetY : onBottomTargetY;
        return {
            x: targetLeft.value,
            y: fitHeight,
        };
    });
    return {
        dynamicPosition,
    };
}
