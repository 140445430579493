import { EOrganizationSettings } from './../enums/SettingEnums';
import BaseRequest from '@/base/api/base-request';
import { AxiosInstance } from 'axios';
import { IOrganizationSettingPayload } from '../types/organization/org-setting.types';

export default class SettingRepository {
    private static instance: SettingRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): SettingRepository {
        if (!SettingRepository.instance) {
            // Get from local storage
            SettingRepository.instance = new SettingRepository();
        }

        return SettingRepository.instance;
    }

    getOrganizationSettings(): Promise<any> {
        return this.request({
            url: `Setting/GetOrganizationSettings`,
            method: 'get',
        });
    }

    getAllTimezoneSettings(): Promise<any> {
        return this.request({
            url: `Setting/GetAllTimezoneSettings?countryCode=${''}`,
            method: 'get',
        });
    }

    updateOrganizationSetting(data: IOrganizationSettingPayload): Promise<any> {
        return this.request({
            url: `Setting/UpdateOrganizationSetting`,
            method: 'post',
            data,
        });
    }
    updateOrganizationDepartmentSettings(enabled: boolean): Promise<any> {
        return this.request({
            method: 'post',
            params: { enabled },
            url: `Setting/UpdateOrganizationDepartmentSettings`,
        });
    }
    updateOrganizationSettingsByModule(data: {
        module: EOrganizationSettings;
        metadata: {
            enabled?: boolean;
            showOnMenu?: boolean;
            showOnTab?: boolean;
            mustSelectTask?: boolean;
            defaultPublicTask?: boolean;
            runMode?: string;
            showPhoneNumber?: boolean;
        };
    }): Promise<any> {
        return this.request({
            method: 'post',
            url: `Setting/UpdateOrganizationSettingsByModule`,
            data,
        });
    }
    getSystemConfig(): Promise<any> {
        return this.request({
            method: 'get',
            url: `Setting/GetSystemConfig`,
        });
    }
    setSystemConfig(data): Promise<any> {
        return this.request({
            method: 'put',
            url: `Setting/UpdateOrganizationSetting2`,
            data,
        });
    }
    getTictopConfig(): Promise<any> {
        return this.request({
            method: 'get',
            url: `Setting/GetTictopConfigs`,
        });
    }
}
