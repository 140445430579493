<script setup lang="ts">
// *** IMPORTS ***
import { computed, onBeforeMount, ref } from 'vue';
import StartWorkingSettingsForm from '@/ui/modules/settings/start-working/start-working-settings-form/StartWorkingSettingsForm.vue';
import { StartWorkingConditionModel } from '@/application/models/setting/StartWorkingConditionModel';
import systemConfig from '@/application/constants/system-config.const';
import permissionStore from '@/store/permission';

// *** PROPS, EMITS ***
const props = defineProps<{
  setting: StartWorkingConditionModel;
}>();

const emit = defineEmits(['update:setting']);

// *** PRIVATE VARIABLES ***
const _permissionStore = permissionStore();
let _specificSWCondition: StartWorkingConditionModel | null = null;

// *** COMPOSABLES ***

// *** REFS ***
const orgSWCondition = ref<StartWorkingConditionModel>({});

// *** COMPUTED ***
const isApplySpecific = computed(() => !!props.setting);

// *** WATCHES ***

// *** HOOKS ***
onBeforeMount(() => {
  const systemConfigs = _permissionStore.allSystemConfigs || {};

  orgSWCondition.value =
    JSON.parse(
      systemConfigs[systemConfig.START_WO_START_WORKING_BY_CONDITIONS] || null
    ) || {};
});

// *** HANDLER FUNCTIONS ***
const onApplyOrgSettingsClick = () => {
  _specificSWCondition = _processSpecificSWCondition();

  emit('update:setting', null);
};

const onApplySpecificSettingsClick = () => {
  _specificSWCondition = _processSpecificSWCondition();

  emit('update:setting', _specificSWCondition);
};

const onSettingChange = (setting: StartWorkingConditionModel) => {
  if (!isApplySpecific.value) return;

  _specificSWCondition = _processSpecificSWCondition();
  _specificSWCondition = {
    ...(_specificSWCondition || {}),
    ...(setting || {}),
  };

  emit('update:setting', _specificSWCondition);
};

// *** PRIVATE FUNCTIONS ***
const _processSpecificSWCondition = () => {
  return (
    _specificSWCondition ||
    props.setting ||
    JSON.parse(JSON.stringify(orgSWCondition.value || {}))
  );
};

// *** EXPOSES ***
</script>

<template>
  <div class="h-full flex flex-col">
    <div class="flex gap-3 mb-3">
      <VigButton
        outline
        rounded="rounded-full"
        padding="px-2 py-1"
        :color="isApplySpecific ? 'gray' : 'current'"
        @click="onApplyOrgSettingsClick"
      >
        <SynIcon v-if="!isApplySpecific" name="CircleCheckbox" is-active />
        <div v-else class="w-4 h-4 rounded-full border"></div>
        <span :class="{ 'font-normal': isApplySpecific }">{{
          $t('SETTING_SW_CONDITION_APPLY_ORG_COND')
        }}</span>
      </VigButton>
      <VigButton
        outline
        rounded="rounded-full"
        padding="px-2 py-1"
        :color="!isApplySpecific ? 'gray' : 'current'"
        @click="onApplySpecificSettingsClick"
      >
        <SynIcon v-if="isApplySpecific" name="CircleCheckbox" is-active />
        <div v-else class="w-4 h-4 rounded-full border"></div>
        <span :class="{ 'font-normal': !isApplySpecific }">{{
          $t('SETTING_SW_CONDITION_APPLY_SPECIFIC_COND')
        }}</span>
      </VigButton>
    </div>
    <div v-perfect-scrollbar class="flex-1">
      <StartWorkingSettingsForm
        :start-working-condition="setting || orgSWCondition"
        :disabled="!isApplySpecific"
        :class="{ 'opacity-70': !isApplySpecific }"
        @on-setting-change="onSettingChange"
      />
    </div>
  </div>
</template>

<style scoped></style>
