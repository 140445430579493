<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getPlaceFromLocation } from '@/ui/plugins/google/place';
import { StartWorkingPositionModel } from '@/application/models/setting/StartWorkingConditionModel';
import LocationService from '@/application/services/LocationService';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  position?: StartWorkingPositionModel;
  readonly?: boolean;
}>();

const emits = defineEmits(['onClose', 'onConfirm']);

const positionEditing = ref<StartWorkingPositionModel>({});
const errorPositionMsg = ref<string>();
const errorRadiusMsg = ref<string>();
const mapRef = ref();

let map: any, marker: any, circle: any;

onMounted(() => {
  positionEditing.value = { ...(props.position || {}) };

  _initMap();

  if (props.position) _locatePositionOnMap();
});

const onAddressSelect = (address) => {
  positionEditing.value.latitude = address?.latitude;
  positionEditing.value.longitude = address?.longitude;

  _locatePositionOnMap();
};

const onRadiusChange = () => {
  _locatePositionOnMap();
};

const onUseCurrentLocationClick = async () => {
  try {
    const currentCoords: any =
      await LocationService.getInstance().getCurrentLocation();
    if (!currentCoords) throw '';

    await _setPositionCoords(currentCoords.latitude, currentCoords.longitude);

    _locatePositionOnMap();
  } catch (e) {
    if (e === 'LOCATION_PERMISSION_DENIED') {
      return (errorPositionMsg.value = translate(
        'PERMISSION_LOCATION_REQUIRED_MSG'
      ));
    }
    return (errorPositionMsg.value = translate(
      'PERMISSION_LOCATION_UNDEFINED_MSG'
    ));
  }
};

const onConfirmClick = () => {
  errorPositionMsg.value = '';
  errorRadiusMsg.value = '';

  if (!positionEditing.value.name) {
    errorPositionMsg.value = translate(
      'SETTING_SW_CONDITION_POSITION_REQUIRED_MSG'
    );
  } else if (
    !positionEditing.value.latitude ||
    !positionEditing.value.longitude
  ) {
    errorPositionMsg.value = translate(
      'SETTING_SW_CONDITION_POSITION_INVALID_MSG'
    );
  }

  if (positionEditing.value.radius === 0) {
    errorRadiusMsg.value = translate(
      'SETTING_SW_CONDITION_POSITION_RADIUS_INVALID_MSG'
    );
  } else if (!positionEditing.value.radius) {
    errorRadiusMsg.value = translate(
      'SETTING_SW_CONDITION_POSITION_RADIUS_REQUIRED_MSG'
    );
  }

  if (
    !positionEditing.value.name ||
    !positionEditing.value.latitude ||
    !positionEditing.value.longitude ||
    !positionEditing.value.radius
  ) {
    return;
  }

  emits('onConfirm', positionEditing.value);
};

const _initMap = () => {
  const defaultCenter = { lat: 21.0285, lng: 105.8542 };

  map = new window.google.maps.Map(mapRef.value, {
    center: defaultCenter,
    zoom: 15,
  });

  _createMarkerOnMap(defaultCenter);
};

const _setPositionCoords = async (latitude, longitude) => {
  positionEditing.value.latitude = latitude;
  positionEditing.value.longitude = longitude;

  const res = await getPlaceFromLocation(
    positionEditing.value.latitude,
    positionEditing.value.longitude
  );

  positionEditing.value.name = res?.formatted_address;
};

const _locatePositionOnMap = () => {
  marker?.setMap(null);
  circle?.setMap(null);

  if (!positionEditing.value?.latitude || !map) return;

  const coords = {
    lat: positionEditing.value.latitude,
    lng: positionEditing.value.longitude,
  };

  _createMarkerOnMap(coords);
  _drawRadiusOnMap(coords);

  map.setCenter(coords);
  map.setZoom(_getZoomByRadius(positionEditing.value.radius));
};

const _createMarkerOnMap = (position) => {
  marker?.setMap(null);

  if (!map) return;

  marker = new window.google.maps.Marker({
    position,
    map: map,
    draggable: !props.readonly,
  });

  marker.addListener('dragend', async () => {
    const coords = {
      lat: marker.getPosition().lat(),
      lng: marker.getPosition().lng(),
    };

    await _setPositionCoords(coords.lat, coords.lng);

    _drawRadiusOnMap(coords);
  });
};

const _drawRadiusOnMap = (center) => {
  circle?.setMap(null);

  if (!positionEditing.value?.latitude || !map) return;

  if (positionEditing.value.radius) {
    circle = new window.google.maps.Circle({
      strokeColor: '#4bb0b2',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#4bb0b2',
      fillOpacity: 0.35,
      map: map,
      center,
      radius: positionEditing.value.radius,
    });
  }
};

const _getZoomByRadius = (radius) => {
  if (!radius) return 15;

  if (radius <= 30) return 20;
  if (radius <= 50) return 19;
  if (radius <= 100) return 18;
  if (radius <= 250) return 17;
  if (radius <= 500) return 16;
  if (radius <= 1000) return 15;
  if (radius <= 2000) return 14;

  return 15;
};
</script>

<template>
  <SynModal
    :is-hidden-footer="readonly"
    container-class="w-11/12 md:w-[45rem]"
    style-body="px-4 pb-4"
    @cancel="$emit('onClose')"
  >
    <template #header
      >{{
        $t(
          readonly
            ? 'SETTING_SW_CONDITION_POSITION_VIEW_MAP'
            : position?.id
            ? 'SETTING_SW_CONDITION_POSITION_EDIT'
            : 'SETTING_SW_CONDITION_POSITION_ADD'
        )
      }}
    </template>
    <template #body>
      <!--POSITION-->
      <div v-if="!readonly || positionEditing.name" class="flex gap-3 mb-3">
        <div class="w-36 mt-2">
          {{ $t('SETTING_SW_CONDITION_POSITION_ALLOW') }}
        </div>
        <div class="flex-1">
          <div class="flex items-center gap-2">
            <div class="flex-1">
              <div v-if="readonly" class="mt-2 font-semibold">
                {{ positionEditing.name }}
              </div>
              <VigAddressBox
                v-else
                v-model="positionEditing.name"
                :placeholder="$t('SETTING_SW_CONDITION_POSITION_ENTER')"
                @on-address-select="onAddressSelect"
              />
            </div>
            <VigButton
              v-if="!readonly"
              v-vig-tooltip="$t('SETTING_SW_CONDITION_POSITION_USE_CURRENT')"
              light
              color="gray"
              padding="p-2"
              @click="onUseCurrentLocationClick"
            >
              <SynIcon name="Crosshair" custom-class="w-5 h-5" />
            </VigButton>
          </div>
          <div v-if="errorPositionMsg" class="text-red-500 text-sm mt-1">
            {{ errorPositionMsg }}
          </div>
        </div>
      </div>

      <!--RADIUS-->
      <div v-if="!readonly || positionEditing.radius" class="flex gap-3 mb-5">
        <div class="w-36 mt-2">
          {{ $t('SETTING_SW_CONDITION_POSITION_RADIUS') }}
        </div>
        <div class="flex-1">
          <div v-if="readonly" class="mt-2 font-semibold">
            {{ positionEditing.radius }}m
          </div>
          <div v-else class="flex items-center gap-2">
            <div class="w-24">
              <input
                v-model="positionEditing.radius"
                type="number"
                class="vig-input text-right px-4"
                step="50"
                min="0"
                @change="onRadiusChange"
              />
            </div>
            <div>m</div>
          </div>
          <div v-if="errorRadiusMsg" class="text-red-500 text-sm mt-1">
            {{ errorRadiusMsg }}
          </div>
        </div>
      </div>

      <!--MAPS-->
      <div
        ref="mapRef"
        class="h-[30rem] border rounded bg-gray-200 relative"
      ></div>

      <div v-if="!readonly" class="text-gray-500 text-sm mt-2">
        {{ $t('SETTING_SW_CONDITION_POSITION_DRAG_MSG') }}
      </div>
    </template>
    <template v-if="!readonly" #footer>
      <div class="p-4 flex justify-end items-center space-x-2">
        <VigButton
          ghost
          color="gray"
          :label="$t('COMMON_LABEL_CANCEL')"
          @click="$emit('onClose')"
        />
        <VigButton
          :label="$t('COMMON_LABEL_CONFIRM')"
          @click="onConfirmClick"
        />
      </div>
    </template>
  </SynModal>
</template>
