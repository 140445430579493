<script setup lang="ts">
import TaskCreateNew from '@/ui/modules/task/general/TaskCreateNew.vue';
const emit = defineEmits(['close', 'onCreateSuccess']);

const onClose = () => {
  emit('close');
};
</script>
<template>
  <SynModalNoFocus
    style-body="p-0 overflow-hidden"
    container-class="w-2/5"
    z-index="z-50"
    container-style="width: 650px; max-width: 1200px; height: 80%;"
    is-hidden-header
    is-hidden-footer
    disable-click-outside
    @cancel="onClose"
  >
    <template #body>
      <div class="flex-1 min-h-0 overflow-hidden p-4">
        <TaskCreateNew
          ref="taskCreateNewRef"
          is-create-modal
          @on-cancel="onClose"
          @on-create-success="
            (localId, newTask) => $emit('onCreateSuccess', localId, newTask)
          "
        />
      </div>
    </template>
  </SynModalNoFocus>
</template>
