<script setup lang="ts">
import { ref } from 'vue';
import {
  EPlanId,
  getUiByPlanSubState,
} from '@/application/constants/plan.const';

defineProps<{
  organizations: any[];
}>();

const emit = defineEmits(['onChoose']);

const selectedOrgId = ref(0);

const onSelectOrganization = async (org) => {
  selectedOrgId.value = org.id;
  emit('onChoose', org);
};
</script>

<template>
  <div
    class="
      flex-1
      overflow-auto
      small-scrollbar
      flex flex-col
      space-y-2
      w-full
      p-2
    "
    style="max-height: 20rem; min-height: fit-content; height: max-content"
  >
    <div
      v-for="(org, index) in organizations"
      :key="index"
      class="
        rounded-md
        relative
        grid grid-cols-9
        hover:bg-current-50 hover:bg-opacity-100
        bg-current-50 bg-opacity-40
        cursor-pointer
        p-2
        w-full
        space-x-3
      "
      :class="
        selectedOrgId === org.id
          ? 'border border-current ring ring-current ring-opacity-30 hover:bg-current-50 hover:text-gray-500 text-current-400'
          : 'text-gray-500'
      "
      @click="onSelectOrganization(org)"
    >
      <div class="flex-center col-span-1">
        <SynAvatar
          custom-class="w-10 h-10"
          :has-name="false"
          :src="org?.logoUrl"
          :name="org.name"
        />
      </div>
      <div class="flex-1 flex-col space-y-0.5 col-span-8">
        <div class="flex items-center justify-between w-full">
          <span class="font-medium truncate">
            {{ org.name }}
          </span>
          <AtomPremiumLabel
            v-if="org?.planId && org?.planId !== EPlanId.PERSONAL"
            :label="org?.planId"
          />
        </div>
        <div v-if="org?.typeName" class="text-xs text-gray-400">
          {{ org?.typeName }}
        </div>
        <div
          class="text-xs italic truncate"
          :class="`${getUiByPlanSubState(org)?.style?.colorText}`"
        >
          {{
            $t(getUiByPlanSubState(org)?.message?.shortContent, {
              remainDays: org?.planRemainDays,
              planId: org?.planId,
            })
          }}
        </div>
      </div>
      <div
        v-if="selectedOrgId === org.id"
        class="
          w-5
          h-5
          bg-current-400
          rounded-full
          flex-center
          absolute
          -right-2
          -top-2
        "
      >
        <SynIcon name="check" custom-class="fill-white w-3 h-3 text-white" />
      </div>
    </div>
  </div>
</template>
