<script setup lang="ts">
import { ref, computed } from 'vue';
import { CalendarType } from '@/ui/common/constants/calendar';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import { DayoffStatus } from '@/domain/enums/DayoffEnums';
import DayoffStatusAtom from '@/ui/components/dayoff/atoms/DayoffStatusAtom.vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import settingStore from '@/store/setting';

defineEmits(['update', 'updateSource']);

const _calendarsOverviewStore = calendarsOverviewStore();
const _settingStore = settingStore();

const dayoffEnabled = computed(() => _settingStore.dayoffEnabled);
const calendarEnabled = computed(() => _settingStore.calendarEnabled);

const isOpenFilter = ref(false);
const isFilter = ref(false);

const typeEventDefault = [
  {
    key: CalendarType.Reminder,
    type: 'REMINDER',
    label: 'CALENDAR_FILTER_TITLE_TYPE_REMINDER',
    isActive: true,
    colorKey: 'green',
    idType: CalendarType.Reminder,
  },
  {
    key: CalendarType.Meeting,
    type: 'MEETING',
    label: 'CALENDAR_FILTER_TITLE_TYPE_VIDEO_CALL',
    isActive: true,
    colorKey: 'purple',
    idType: CalendarType.Meeting,
  },
];

const dayoffStatusDefault = [
  {
    key: DayoffStatus.PENDING,
    isChecked: true,
  },
  {
    key: DayoffStatus.ACCEPTED,
    isChecked: true,
  },
  {
    key: DayoffStatus.REJECTED,
    isChecked: true,
  },
  {
    key: DayoffStatus.CANCELLED,
    isChecked: true,
  },
  {
    key: DayoffStatus.MARK_UNUSED,
    isChecked: true,
  },
  {
    key: DayoffStatus.CLOSED,
    isChecked: true,
  },
];
const typeSystemDefault = {
  type: 'SYSTEM',
  label: 'CALENDAR_TYPE_HOLIDAY',
  isActive: true,
  colorKey: 'cyan',
  key: true,
};
const dayoffStatusFilter = ref(cloneDeep(dayoffStatusDefault));
const typeEvent = ref(cloneDeep(typeEventDefault));
const typeSystem = ref(cloneDeep(typeSystemDefault));

const onHandleFilter = () => {
  isFilter.value = true;
  _calendarsOverviewStore.rerenderCalendar();
};

const onReset = () => {
  isFilter.value = false;
  dayoffStatusFilter.value = cloneDeep(dayoffStatusDefault);
  typeEvent.value = cloneDeep(typeEventDefault);
  typeSystem.value = cloneDeep(typeSystemDefault);
  _calendarsOverviewStore.onChangeFilterData({
    status: dayoffStatusDefault.map((o) => o.key),
    types: typeEventDefault.map((o) => o.key),
  });
  _calendarsOverviewStore.rerenderCalendar();
};

const onCancel = () => {
  isOpenFilter.value = false;
  if (isFilter.value) return;
  if (totalFilter.value < totalDefault.value) {
    dayoffStatusFilter.value = cloneDeep(dayoffStatusDefault);
    typeEvent.value = cloneDeep(typeEventDefault);
    typeSystem.value = cloneDeep(typeSystemDefault);
    return;
  }
  onReset();
};

const onChangeEventType = () => {
  _calendarsOverviewStore.onChangeFilterData({
    types: typeEvent.value.filter((o) => o.isActive).map((o) => o.key),
  });
};

const onChangeSystemEventType = () => {
  _calendarsOverviewStore.onChangeFilterData({
    isShowSystemEvents: typeSystem.value?.isActive,
  });
};

const onChangeDayoffStatus = () => {
  _calendarsOverviewStore.onChangeFilterData({
    status: dayoffStatusFilter.value
      ?.filter((o) => o.isChecked)
      .map((o) => o.key),
  });
};

const totalFilter = computed(
  () =>
    [
      ...typeEvent.value?.filter((o) => o.isActive),
      ...[typeSystem.value].filter((o) => o.isActive),
      ...dayoffStatusFilter.value.filter((o) => o.isChecked),
    ].length
);
const totalDefault = computed(
  () =>
    [...typeEvent.value, ...[typeSystem.value], ...dayoffStatusFilter.value]
      .length
);
</script>
<template>
  <vig-dropdown
    @on-dropdown-open="isOpenFilter = true"
    @on-dropdown-close="onCancel()"
  >
    <template #dropdown-toggle>
      <div
        v-vig-tooltip="$t('COMMON_LABEL_FILTERS')"
        class="
          border
          rounded
          w-8
          h-8
          flex-center
          hover:bg-gray-200 hover:ring hover:ring-gray-100 hover:border-gray-400
          cursor-pointer
          relative
        "
        :class="
          isOpenFilter
            ? 'bg-current-50 border-current-500 ring ring-current-50 fill-current text-current-500'
            : 'fill-gray-500 text-gray-500 border-gray-200'
        "
      >
        <SynIcon name="filter" />
        <div
          v-if="totalFilter < totalDefault"
          class="
            w-4
            h-4
            bg-current
            rounded-full
            flex-center
            text-2xs
            absolute
            -top-1.5
            -right-1.5
            font-medium
            text-white
          "
        >
          {{ totalFilter }}
        </div>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="p-1 border-b flex items-center justify-between w-96">
        <span class="w-8"></span>
        <span class="text-current-600 font-semibold">{{
          $t('TASK_FILTER_LABEL_FILTER')
        }}</span>
        <button
          class="
            w-8
            h-8
            flex-center
            rounded-full
            hover:bg-gray-200
            dropdown-item
          "
        >
          <SynIcon name="Close" custom-class="w-5 h-5" />
        </button>
      </div>
      <!-- Body -->
      <div class="flex flex-col space-y-4 p-4 max-w-md w-96">
        <div
          v-if="dayoffEnabled"
          v-permission-model
          class="flex flex-col space-y-2"
        >
          <!-- TYPE EVENT -->
          <div class="font-medium text-sm">
            {{ $t('COMMON_MODULE_DAYOFF') }}
          </div>

          <div class="flex items-center flex-wrap">
            <SynCheckboxTag
              v-for="dayoff in dayoffStatusFilter"
              :key="dayoff.key"
              v-model="dayoff.isChecked"
              custom-active-class=""
              class="mr-1 mt-1"
              @update:model-value="onChangeDayoffStatus"
            >
              <DayoffStatusAtom
                :status="dayoff.key"
                :is-active="dayoff.isChecked"
                size="middle"
              />
            </SynCheckboxTag>
          </div>
        </div>
        <div v-if="calendarEnabled" class="flex flex-col space-y-2">
          <!-- TYPE EVENT -->
          <div class="font-medium text-sm">
            {{ $t('CALENDAR_FILTER_TITLE_TYPE') }}
          </div>

          <div class="flex items-center">
            <SynCheckboxTag
              v-for="item in typeEvent"
              :key="item"
              v-model="item.isActive"
              class="mr-1"
              :custom-active-class="`bg-${item.colorKey}-50`"
              @update:model-value="onChangeEventType"
            >
              <syn-tag
                size="circle-small"
                custom-class="px-2 py-1 text-sm border"
              >
                <div class="flex-center space-x-2">
                  <span
                    class="p-1 rounded-full"
                    :class="`bg-${item?.colorKey}-500`"
                  ></span>
                  <span :class="`text-${item?.colorKey}-500`">
                    {{ $t(item?.label) }}
                  </span>
                </div>
              </syn-tag>
            </SynCheckboxTag>
            <SynCheckboxTag
              v-model="typeSystem.isActive"
              class="mr-1"
              custom-active-class="bg-cyan-100"
              @update:model-value="onChangeSystemEventType"
            >
              <syn-tag
                size="circle-small"
                custom-class="px-2 py-1 text-sm border"
              >
                <div class="flex-center space-x-2">
                  <span
                    class="p-1 rounded-full"
                    :class="`bg-${typeSystem?.colorKey}-500`"
                  ></span>
                  <span :class="`text-${typeSystem?.colorKey}-500`">
                    {{ $t(typeSystem?.label) }}
                  </span>
                </div>
              </syn-tag>
            </SynCheckboxTag>
          </div>
        </div>
      </div>

      <div
        class="px-3 py-2 border-t flex space-x-1 justify-end text-right w-96"
      >
        <SynButton
          class="text-sm dropdown-item px-3 py-1.5"
          type-outline
          :label="$t('COMMON_LABEL_RESET')"
          @click="onReset"
        />
        <SynButton
          class="text-sm dropdown-item px-3 py-1.5"
          :label="$t('CALL_LABEL_APPLY')"
          @click="onHandleFilter"
        />
      </div>
    </template>
  </vig-dropdown>
</template>
