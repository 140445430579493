// Generated by https://quicktype.io

export type TWorkflowActionType =
    | 'PREVIEW'
    | 'DUPLICATE'
    | 'EDIT'
    | 'CHOOSE'
    | 'ACTIVE'
    | 'REMOVE';
export interface IWorkflowStepType {
    id: number;
    name: string;
    isSystem: boolean;
    index: number;
    usedStepTemplates: { id: string; name: string }[];
}
export interface IWorkflowStepTypeLanguage {
    id: number;
    language: string;
    name: string;
}

export enum EAssigneeStatus {
    COMPLETED = 'COMPLETED',
    ASSIGNED = 'ASSIGNED',
}
export enum EWorkflowTaskStepStatus {
    TODO = 'TODO',
    INPROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    WAITING = 'WAITING',
    CANCELED = 'CANCELED',
}
export enum EWorkflowTaskStatus {
    INPROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    WAITING = 'WAITING',
    CANCELED = 'CANCELED',
}
export interface IAssignee {
    groupId?: number;
    assigneeId?: number;
    order: number;
    role: string;
    status: EAssigneeStatus;
    updatedTime?: Date | string;
}

// Generated by https://quicktype.io

export interface IWorkflowShortInfo {
    workflows: {
        id: string;
        code: string;
        name: string;
    }[];
    workflowSteps: {
        id: string;
        name: string;
    }[];
}
