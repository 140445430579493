export enum CalendarEventReminderStatusEnum {
    New = 'new',
    Renew = 're_new',
    Acknowledge = 'acknowledge',
}

export enum CalendarType {
    Task = 10,
    Meeting = 20,
    Reminder = 30,
    DayOff = 99,
}

export enum CalendarSourceType {
    Task = 1,
    Event = 2,
}

export enum CalendarSourceId {
    googleCalendar = 'GOOGLE_CALENDAR',
}

export enum CalendarMeetingType {
    PhysicalMeeting = 1,
    OnlineMeeting = 2,
}

export const CalendarTypeByKey = {
    10: {
        key: 'task',
        text: 'Task',
    },
    20: {
        key: 'meeting',
        text: 'Meeting',
    },
    30: {
        key: 'reminder',
        text: 'Reminder',
    },
};

export enum CalendarSyncStatus {
    NotSync = 'NOT_SYNC',
    InProcessing = 'IN_PROCESSING',
    Completed = 'COMPLETED',
    Failure = 'FAILURE',
}

export enum CalendarSyncDirection {
    ExternalToInternal = 'EXTERNAL_TO_INTERNAL',
    InternalToExternal = 'INTERNAL_TO_EXTERNAL',
    BothDirections = 'BOTH_DIRECTIONS',
}

export enum CalendarSyncTrigger {
    Manually = 'MANUALLY',
    Schedule = 'SCHEDULE',
}

export enum CalendarSyncProvider {
    Google = 'GOOGLE',
}
