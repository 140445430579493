<script setup lang="ts">
import { computed } from 'vue';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { typeDeployments } from '@/ui/common/constants/system';

const languge = getLocalStorage(StorageConstant.LANGUAGE);

const props = defineProps({
  description: {
    default: () => [],
    type: Array,
  },
  severity: {
    default: 1,
    type: Number,
  },
  type: {
    default: 1,
    type: Number,
  },
});
defineEmits(['cancel']);

const listDescription = computed(() => {
  if (!props?.description) return [];
  if (
    props?.description[languge?.toLowerCase()] &&
    props?.description[languge?.toLowerCase()]?.length > 0
  )
    return props?.description[languge?.toLowerCase()];
  else if (props?.description['en'] && props?.description['en']?.length > 0) {
    return props?.description['en'];
  } else if (props?.description['vi'] && props?.description['vi']?.length > 0) {
    return props?.description['vi'];
  }
  return props?.description['fr'];
});
</script>
<template>
  <syn-modal
    is-hidden-header
    is-hidden-footer
    container-class="w-max h-max p-8"
    :container-style="'max-height: 40rem; max-width: 40rem;'"
    @cancel="$emit('cancel', false)"
  >
    <template #body>
      <syn-animation
        :name="
          type == typeDeployments.MAINTENANCE
            ? 'maintainsystems'
            : 'contractionsystem'
        "
        stype="width: 220px; height: 220px;"
      ></syn-animation>
      <div
        class="
          font-medium
          pb-2
          pt-2
          text-current-500
          flex
          items-center
          space-x-2
        "
      >
        <!-- <span :class="colorDot" class="w-2 h-2 rounded-full p-1"></span> -->
        <span class="uppercase">{{
          $t('SYSTEM_DEPLOYMENT_VIEW_INFO_TITLE')
        }}</span>
      </div>
      <ul class="list-disc p-2 pl-4 italic text-gray-500">
        <template v-for="item in listDescription" :key="item">
          <li v-if="item" v-html="item"></li>
        </template>
      </ul>
    </template>
  </syn-modal>
</template>
