import {
    collection,
    deleteDoc,
    doc,
    endBefore,
    getDoc,
    getDocsFromCache,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    startAfter,
    updateDoc,
    writeBatch,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { getDocsFromServer, QuerySnapshot } from '@firebase/firestore';
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import {
    ChatConversationMemberModel,
    ChatConversationModel,
} from '@/application/models/chat/ChatConversationModel';
import { ChatExternalAcceptance } from '@/domain/enums/chat-enums';

class ChatConversationRepository {
    getConversation(orgId: number, converId: string) {
        return getDoc(this._getDocRef(orgId, converId));
    }

    getNewConversationId(orgId: number | string) {
        const newDoc = doc(
            collection(
                firestoreDb,
                'organizationConversations',
                `${orgId}`,
                'conversations'
            )
        );

        return newDoc?.id;
    }

    addConversation(
        orgId: number,
        converId: string,
        converInfo: ChatConversationModel,
        userId: number
    ) {
        const batch = writeBatch(firestoreDb);
        const docRef = this._getDocRef(orgId, converId);

        // Set conversation-item info
        const setConverInfo: any = {
            id: converId || null,
            name: converInfo?.name || null,
            avatar: converInfo?.avatar || null,
            createdBy: `${userId}`,
            createdDate: serverTimestamp(),
        };

        if (converInfo?.isGroup) setConverInfo.isGroup = true;
        if (converInfo?.isSelf) setConverInfo.isSelf = true;
        if (converInfo?.externalChat) setConverInfo.externalChat = true;
        if (converInfo?.supportingChat) {
            setConverInfo.supportingChat = true;
            setConverInfo.supportingMemberId =
                converInfo?.supportingMemberId || null;
            setConverInfo.supportingOrgId = converInfo?.supportingOrgId || null;
            setConverInfo.supportingOrgName =
                converInfo?.supportingOrgName || null;
            setConverInfo.supportingOrgAvatar =
                converInfo?.supportingOrgAvatar || null;
            setConverInfo.supportingOrgModel =
                converInfo?.supportingOrgModel || null;
            setConverInfo.supporterName = converInfo?.supporterName || null;
            setConverInfo.supporterAvatar = converInfo?.supporterAvatar || null;
        }

        batch.set(docRef, setConverInfo, { merge: true });

        // Set conversation-item members
        Object.entries(converInfo?.members || {}).map(([memberId, member]) => {
            const setMemberInfo: any = {
                id: memberId || null,
                name: member?.name || null,
                avatar: member?.avatar || null,
                createdBy: `${userId}`,
                createdDate: serverTimestamp(),
            };

            if (converInfo?.externalChat) {
                setMemberInfo.externalChat = true;
                setMemberInfo.externalOrgId = member?.externalOrgId || null;
                setMemberInfo.externalOrgName = member?.externalOrgName || null;
                setMemberInfo.externalOrgAvatar =
                    member?.externalOrgAvatar || null;
                setMemberInfo.externalOrgModel =
                    member?.externalOrgModel || null;
                setMemberInfo.externalAcceptance =
                    `${memberId}` === `${userId}`
                        ? ChatExternalAcceptance.ACCEPTED
                        : ChatExternalAcceptance.ACCEPTED; // TODO: PENDING
            }

            if (converInfo?.supportingChat) {
                setMemberInfo.supportingChat = true;
                setMemberInfo.supportingMemberId =
                    member?.supportingMemberId || null;
                setMemberInfo.supportingOrgId = member?.supportingOrgId || null;
                setMemberInfo.supportingOrgName =
                    member?.supportingOrgName || null;
                setMemberInfo.supportingOrgAvatar =
                    member?.supportingOrgAvatar || null;
                setMemberInfo.supportingOrgModel =
                    member?.supportingOrgModel || null;
                setMemberInfo.isSupporter = member?.isSupporter || false;
            }

            batch.set(doc(docRef, 'members', `${memberId}`), setMemberInfo);
        });

        // Commit the batch
        return batch.commit();
    }

    //<editor-fold desc="MEMBERS">

    getMembersFromLocal(orgId: number, converId: string) {
        const collectionRef = collection(
            this._getDocRef(orgId, converId),
            'members'
        );

        return getDocsFromCache(query(collectionRef));
    }

    getMembersFromServer(orgId: number, converId: string) {
        const collectionRef = collection(
            this._getDocRef(orgId, converId),
            'members'
        );

        return getDocsFromServer(query(collectionRef));
    }

    subscribeMembers(
        orgId: number,
        converId: string,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        const collectionRef = collection(
            this._getDocRef(orgId, converId),
            'members'
        );

        return onSnapshot(collectionRef, onNext);
    }

    async leaveConversation(
        orgId: number | string,
        converId: string,
        userId: number
    ) {
        const docRef = doc(
            this._getDocRef(orgId, converId),
            'members',
            `${userId}`
        );

        const updMember = {
            removed: true,
            removedBy: `${userId}`,
            removedDate: serverTimestamp(),
        };

        await updateDoc(docRef, updMember);

        return updMember;
    }

    //</editor-fold>

    //<editor-fold desc="SEEN BY MEMBERS">

    getSeenByMembersFromLocal(orgId: number, converId: string) {
        const collectionRef = collection(
            this._getDocRef(orgId, converId),
            'seenByMembers'
        );

        return getDocsFromCache(query(collectionRef));
    }

    getSeenByMembersFromServer(orgId: number, converId: string) {
        const collectionRef = collection(
            this._getDocRef(orgId, converId),
            'seenByMembers'
        );

        return getDocsFromServer(query(collectionRef));
    }

    subscribeSeenByMembers(
        orgId: number,
        converId: string,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        const collectionRef = collection(
            this._getDocRef(orgId, converId),
            'seenByMembers'
        );

        return onSnapshot(collectionRef, onNext);
    }

    updateSeenByMember(
        orgId: number,
        converId: string,
        userId: number,
        messageId: string
    ) {
        const docRef = doc(
            this._getDocRef(orgId, converId),
            'seenByMembers',
            `${userId}`
        );

        return setDoc(docRef, {
            lastSeenMessageId: messageId,
            lastSeenMessageTime: serverTimestamp(),
        });
    }

    //</editor-fold>

    //<editor-fold desc="TYPING MEMBERS">

    subscribeTypingMembers(
        orgId: number,
        converId: string,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        const collectionRef = collection(
            this._getDocRef(orgId, converId),
            'typingMembers'
        );

        return onSnapshot(collectionRef, onNext);
    }

    setMemberIsTyping(
        orgId: number,
        converId: string,
        userId: number,
        isTyping: boolean
    ) {
        const docRef = doc(
            this._getDocRef(orgId, converId),
            'typingMembers',
            `${userId}`
        );

        return setDoc(docRef, {
            isTyping: isTyping,
            updatedDate: serverTimestamp(),
        });
    }

    //</editor-fold>

    //<editor-fold desc="PINNED MESSAGES">

    subscribePinnedMessages(
        orgId: number,
        converId: string,
        startAfterVal: any,
        endBeforeVal: any,
        pageSize: number | null,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        const query = this._preparePinnedMessagesQuery(
            orgId,
            converId,
            startAfterVal,
            endBeforeVal,
            pageSize
        );

        return onSnapshot(query, onNext);
    }

    addPinnedMessage(
        orgId: number,
        converId: string,
        messageId: string,
        message: ChatMessageModel,
        userId: number
    ) {
        const docRef = doc(
            this._getDocRef(orgId, converId),
            'pinnedMessages',
            messageId
        );

        return setDoc(docRef, {
            ...message,
            pinned: true,
            pinnedBy: `${userId}`,
            pinnedDate: serverTimestamp(),
        });
    }

    removePinnedMessage(orgId: number, converId: string, messageId: string) {
        const docRef = doc(
            this._getDocRef(orgId, converId),
            'pinnedMessages',
            messageId
        );

        return deleteDoc(docRef);
    }

    private _preparePinnedMessagesQuery(
        orgId: number,
        converId: string,
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number | null,
        orderByField: string = 'pinnedDate'
    ) {
        const collectionRef = collection(
            this._getDocRef(orgId, converId),
            'pinnedMessages'
        );

        let q = query(collectionRef, orderBy(orderByField, 'desc'));

        if (startAfterVal) {
            q = query(q, startAfter(startAfterVal));
        }

        if (endBeforeVal) {
            q = query(q, endBefore(endBeforeVal));
        }

        if (pageSize) {
            q = query(q, limit(pageSize));
        }

        return q;
    }

    //</editor-fold>

    //<editor-fold desc="DELETED MESSAGES">

    async removeMessage(
        orgId: number,
        converId: string,
        messageId: string,
        userId: number
    ) {
        const docRef = doc(
            this._getDocRef(orgId, converId),
            'deletedMessages',
            messageId
        );

        const delMsg = {
            removed: true,
            removedBy: `${userId}`,
            removedDate: serverTimestamp(),
            updatedBy: `${userId}`,
            updatedDate: serverTimestamp(),
        };

        await setDoc(docRef, delMsg);

        return delMsg;
    }

    //</editor-fold>

    //<editor-fold desc="SUPPORTING">

    async getOrgSupportingInfo(orgId: string) {
        const docRef = doc(
            firestoreDb,
            'organizationConversations',
            `${orgId}`
        );

        return getDoc(docRef);
    }

    async addConversationSupporters(
        orgId: string,
        converId: string,
        supporters: ChatConversationMemberModel[],
        userId: number
    ) {
        const batch = writeBatch(firestoreDb);
        const docRef = this._getDocRef(orgId, converId);

        // Set conversation-item members
        supporters?.map((supporter) => {
            const setMemberInfo: any = {
                id: supporter?.id || null,
                name: supporter?.name || null,
                avatar: supporter?.avatar || null,
                createdBy: `${userId}`,
                createdDate: serverTimestamp(),
                isSupporter: true,
                supportingChat: true,
                supportingOrgId: supporter?.supportingOrgId || null,
                supportingOrgName: supporter?.supportingOrgName || null,
                supportingOrgAvatar: supporter?.supportingOrgAvatar || null,
                supportingOrgModel: supporter?.supportingOrgModel || null,
            };

            batch.set(
                doc(docRef, 'members', `${supporter?.id}`),
                setMemberInfo
            );
        });

        // Commit the batch
        return batch.commit();
    }

    //</editor-fold>

    private _getDocRef(orgId: number | string, converId: string) {
        return doc(
            firestoreDb,
            'organizationConversations',
            `${orgId}`,
            'conversations',
            `${converId}`
        );
    }
}

export default new ChatConversationRepository();
