<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 328.75 328.75"
    style="enable-background: new 0 0 328.75 328.75"
    xml:space="preserve"
  >
    <g>
      <circle cx="164.375" cy="60" r="60" />
      <circle cx="244.375" cy="308.75" r="20" />
      <circle cx="84.375" cy="308.75" r="20" />
      <circle cx="164.375" cy="308.75" r="20" />
      <path
        d="M164.375,150c-60.061,0-108.75,48.689-108.75,108.75h217.5C273.125,198.689,224.436,150,164.375,150z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 393.75 393.75"
    style="enable-background: new 0 0 393.75 393.75"
    xml:space="preserve"
  >
    <g>
      <circle cx="276.875" cy="373.75" r="20" />
      <circle cx="116.875" cy="373.75" r="20" />
      <circle cx="196.875" cy="373.75" r="20" />
      <path
        d="M196.875,150c41.355,0,75-33.645,75-75s-33.645-75-75-75s-75,33.645-75,75S155.52,150,196.875,150z M196.875,30   c24.813,0,45,20.187,45,45s-20.187,45-45,45s-45-20.187-45-45S172.062,30,196.875,30z"
      />
      <path
        d="M196.875,180c-68.236,0-123.75,55.514-123.75,123.75v15h247.5v-15C320.625,235.514,265.111,180,196.875,180z    M104.324,288.75c7.201-44.588,45.962-78.75,92.551-78.75s85.35,34.162,92.551,78.75H104.324z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
