import { createApp, h } from 'vue';
import TaskCalendarEventItem from '@/ui/pages/tasks/calendar/TaskCalendarEventItem.vue';
import PlanedTaskEventItem from '@/ui/pages/tasks/calendar/PlanedTaskEventItem.vue';
import { TaskCalendarViewClass } from '@/domain/entities/full-calendar';
import { ETaskListModule } from '@/application/types/task/task.types';

export function renderTaskEventComponent(
    data: TaskCalendarViewClass,
    sourceType: ETaskListModule,
    options?: any
) {
    const appItem = createApp({
        render() {
            return h(
                TaskCalendarEventItem,
                {
                    data,
                    sourceType,
                    isTimeBlocking: options?.isTimeBlocking,
                    timeZone: options?.timeZone,
                    allDay: options?.allDay,
                },
                () => []
            );
        },
    });
    const tmpEl = document.createElement('div');
    const mountedApp = appItem.mount(tmpEl);
    const el = document.createElement('div');
    el.innerHTML = mountedApp.$el.outerHTML;
    el.style.height = '100%';
    return el;
}
export function renderPlanedTaskComponent(
    data: TaskCalendarViewClass,
    sourceType: ETaskListModule,
    options?: any
) {
    const appItem = createApp({
        render() {
            return h(PlanedTaskEventItem, {
                data,
                sourceType,
                ...options?.isTimeBlocking,
            });
        },
    });
    const tmpEl = document.createElement('div');
    const mountedApp = appItem.mount(tmpEl);
    const el = document.createElement('div');
    el.innerHTML = mountedApp.$el.outerHTML;
    el.style.height = '100%';
    return el;
}
