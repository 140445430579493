<script setup lang="ts">
import dayjs from 'dayjs';
import { onMounted, ref, computed } from 'vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const props = defineProps({
  filter: {
    type: Object,
    default: null,
  },
  isFilterType: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(['onApplyFilter', 'onClose']);

const fileTypes = ref([
  { value: 'FOLDER', text: 'GED_FOLDER' },
  { value: 'IMAGE', text: 'GED_FILE_PHOTO' },
  { value: 'AUDIO', text: 'GED_FILE_AUDIO' },
  { value: 'VIDEO', text: 'GED_FILE_VIDEO' },
  { value: 'PDF', text: 'GED_FILE_PDF' },
  { value: 'DOC', text: 'GED_FILE_DOCUMENT' },
  { value: 'EXCEL', text: 'GED_FILE_SPREADSHEETS' },
  { value: 'PPTX', text: 'GED_FILE_PRESENTATION' },
  { value: 'LINK', text: 'GED_FILE_LINK' },
]);

const filterObj = ref({ dateFrom: null, dateTo: null, fileTypes: {} });
const filterCount = computed(() => {
  const { dateFrom, dateTo, fileTypes } = props.filter || {};
  return Object.values({ dateFrom, dateTo, fileTypes })
    .reduce((arr, val) => arr.concat(val), [])
    .filter((val) => val).length;
});

onMounted(() => {
  filterObj.value = {
    dateFrom: props.filter?.dateFrom
      ? dayjs(props.filter?.dateFrom).format('YYYY-MM-DD')
      : null,
    dateTo: props.filter?.dateTo
      ? dayjs(props.filter?.dateTo).format('YYYY-MM-DD')
      : null,
    fileTypes: (props.filter?.fileTypes || []).reduce((obj, type) => {
      obj[type] = true;
      return obj;
    }, {}),
  };
});
const onFileTypeClick = (type) => {
  if (!filterObj.value?.fileTypes) return;

  filterObj.value.fileTypes[type?.value] =
    !filterObj.value.fileTypes[type?.value];

  onApplyClick();
};

const onApplyClick = () => {
  emits('onApplyFilter', {
    dateFrom: filterObj.value?.dateFrom
      ? dayjs(filterObj.value?.dateFrom).startOf('day').format()
      : null,
    dateTo: filterObj.value?.dateTo
      ? dayjs(filterObj.value?.dateTo).endOf('day').format()
      : null,
    fileTypes: fileTypes.value
      ?.filter((type) => filterObj.value?.fileTypes[type?.value])
      .map((type) => type.value),
  });
};

const onResetClick = () => {
  filterObj.value = { dateFrom: null, dateTo: null, fileTypes: {} };

  emits('onApplyFilter', {
    dateFrom: null,
    dateTo: null,
    fileTypes: [],
  });
};

const onClose = () => {
  onResetClick();
  emits('onClose');
};
</script>

<template>
  <div class="flex items-center flex-wrap pt-2 pb-1 px-3 relative">
    <div class="flex items-center space-x-2 pr-3 pb-1" style="width: 18rem">
      <AtomDatePicker
        v-model="filterObj.dateFrom"
        :placeholder="$t('GED_FILTER_DATE_FROM')"
        :max-date="filterObj.dateTo"
        @update:model-value="onApplyClick"
      />
      <AtomDatePicker
        v-model="filterObj.dateTo"
        :placeholder="$t('GED_FILTER_DATE_TO')"
        :min-date="filterObj.dateFrom"
        @update:model-value="onApplyClick"
      />
    </div>

    <div class="flex items-center space-x-1 flex-wrap">
      <button
        v-for="type of fileTypes"
        :key="type"
        class="
          px-3
          py-1.5
          rounded-full
          border
          text-sm
          mb-1
          hover:bg-gray-200 hover:text-black
        "
        :class="
          filterObj?.fileTypes[type?.value]
            ? 'border-gray-500 bg-gray-500 text-white'
            : 'bg-white'
        "
        @click="onFileTypeClick(type)"
      >
        {{ $t(type?.text) || type?.text }}
      </button>
    </div>

    <div v-if="filterCount" class="flex-center mx-2 mb-1">
      <button
        class="
          px-3
          py-1
          rounded-full
          bg-white
          border border-current-200
          hover:bg-current-50
          text-current-500 text-sm
          mr-2
          dropdown-item
        "
        @click="onResetClick"
      >
        {{ $t('COMMON_LABEL_RESET') || 'Reset' }}
      </button>
    </div>

    <button
      class="
        absolute
        right-2
        top-3
        w-8
        h-8
        flex-center
        rounded-full
        hover:bg-gray-200
        text-current-500 text-sm
        mr-2
        dropdown-item
      "
      @click="onClose"
    >
      <syn-icon
        name="close"
        custom-class="w-5 h-5 fill-gray-500 text-gray-500"
      />
    </button>
  </div>
</template>
