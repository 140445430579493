<template>
  <svg
    v-if="!isActive"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="m9 14h-2v2h2z"></path>
        <path d="m9 18h-2v2h2z"></path>
        <path d="m13 14h-2v2h2z"></path>
        <path d="m13 18h-2v2h2z"></path>
        <path d="m17 14h-2v2h2z"></path>
        <path
          d="m6 7c0-.55228.44772-1 1-1h2c.55228 0 1 .44772 1 1 0 .55229-.44772 1-1 1h-2c-.55228 0-1-.44771-1-1z"
        ></path>
        <path
          d="m19 6c-.5523 0-1 .44772-1 1 0 .55229.4477 1 1 1h2c.5523 0 1-.44771 1-1 0-.55228-.4477-1-1-1z"
        ></path>
        <path
          clip-rule="evenodd"
          d="m2 6c0-1.65685 1.34315-3 3-3h18c1.6569 0 3 1.34315 3 3v9.6736c2.3649 1.1235 4 3.534 4 6.3264 0 3.866-3.134 7-7 7-2.7924 0-5.2029-1.6351-6.3264-4h-11.6736c-1.65685 0-3-1.3431-3-3zm25.9 15c-.3977-1.9591-1.9409-3.5023-3.9-3.9v3.9zm-5.9-3.9v4.9c0 .2652.1054.5196.2929.7071s.4419.2929.7071.2929h4.9c-.4633 2.2822-2.481 4-4.9 4-2.7614 0-5-2.2386-5-5 0-2.419 1.7178-4.4367 4-4.9zm1-2.1c.3395 0 .6734.0242 1 .0709v-4.0709h-20v11c0 .5523.44772 1 1 1h11.0709c-.0467-.3266-.0709-.6605-.0709-1 0-3.866 3.134-7 7-7zm-18-10c-.55228 0-1 .44772-1 1v3h20v-3c0-.55229-.4477-1-1-1z"
          fill-rule="evenodd"
        ></path>
      </g>
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="m5 3c-1.65685 0-3 1.34315-3 3v16c0 1.6569 1.34314 3 3 3h10.5815c-.2553-.6307-.4325-1.3014-.5196-2h-10.0619c-.55228 0-1-.4477-1-1v-11h20v3.0619c.6986.0871 1.3693.2643 2 .5196v-8.5815c0-1.65685-1.3431-3-3-3zm2 3c-.55228 0-1 .44772-1 1s.44772 1 1 1h2c.55228 0 1-.44772 1-1s-.44772-1-1-1zm11 1c0-.55228.4477-1 1-1h2c.5523 0 1 .44772 1 1s-.4477 1-1 1h-2c-.5523 0-1-.44772-1-1z"
          fill-rule="evenodd"
        ></path>
        <path d="m7 14h2v2h-2z"></path>
        <path d="m7 18h2v2h-2z"></path>
        <path d="m13 14h-2v2h2z"></path>
        <path d="m11 18h2v2h-2z"></path>
        <path d="m17 14h-2v2h2z"></path>
        <path
          clip-rule="evenodd"
          d="m23 15c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7 3.134-7 7-7zm1 6v-3.9c1.9591.3977 3.5023 1.9409 3.9 3.9z"
          fill-rule="evenodd"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
