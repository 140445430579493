<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import {
  StartWorkingConditionModel,
  StartWorkingLimitPositionModel,
  StartWorkingPositionModel,
} from '@/application/models/setting/StartWorkingConditionModel';
import ModalStartWorkingSettingLocation from '@/ui/modules/settings/start-working/start-working-settings-form/ModalStartWorkingSettingLocation.vue';
import { v4 as uuidv4 } from 'uuid';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';

const props = defineProps<{
  startWorkingCondition: StartWorkingConditionModel;
  disabled?: boolean;
}>();

const emits = defineEmits(['onSettingChange']);

const limitPosition = ref<StartWorkingLimitPositionModel>({});
const isSelectPosition = ref<boolean>();
const isViewPosition = ref<boolean>();
const editingPosition = ref<StartWorkingPositionModel | null>();

watch(
  () => props.startWorkingCondition,
  () => _setValues()
);

onMounted(() => _setValues());

const _setValues = () => {
  limitPosition.value = props.startWorkingCondition?.limitPosition || {};
};

const onPositionConfirm = (position: StartWorkingPositionModel) => {
  // Edit position
  if (editingPosition.value) {
    const idx = (limitPosition.value.positions || []).findIndex(
      (p) => p?.id === position?.id
    );
    if (idx > -1) {
      limitPosition.value.positions![idx] = position;
    }
  }
  // Add position
  else {
    limitPosition.value.positions = (
      limitPosition.value.positions || []
    ).concat({
      ...position,
      id: uuidv4(),
    });
  }

  // Update settings
  emits('onSettingChange', { limitPosition: limitPosition.value });

  // Close modal
  editingPosition.value = null;
  isViewPosition.value = false;
  isSelectPosition.value = false;
};

const onPositionDelete = async (position: StartWorkingPositionModel) => {
  if (!position) return;

  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('SETTING_SW_CONDITION_POSITION_DELETE_CONFIRM'),
    confirmLabel: translate('COMMON_LABEL_CONFIRM'),
    confirmable: true,
    closeable: true,
  });
  const confirmed = await ask();

  if (confirmed) {
    limitPosition.value.positions = limitPosition.value.positions?.filter(
      (p) => p?.id !== position.id
    );

    emits('onSettingChange', { limitPosition: limitPosition.value });
  }
};
</script>

<template>
  <div v-if="startWorkingCondition?.enabled">
    <div class="px-6 pb-2 pt-2 bg-gray-100 mt-4">
      <div class="text-lg text-gray-700">
        {{ $t('SETTING_SW_CONDITION_POSITION') }}
      </div>
      <div class="text-xs text-gray-600">
        {{ $t('SETTING_SW_CONDITION_POSITION_DESC') }}
      </div>
    </div>
    <MenuSettingRowItem
      name-icon="Location"
      :title-menu="$t('SETTING_SW_CONDITION_POSITION_ENABLE')"
      :sub-title="$t('SETTING_SW_CONDITION_POSITION_ENABLE_DESC')"
    >
      <template #action>
        <AtomSwitch
          v-model="limitPosition.enabled"
          :disabled="disabled"
          @change="$emit('onSettingChange', { limitPosition })"
        />
      </template>
      <template v-if="limitPosition?.enabled" #subMenu>
        <div class="pl-8 py-2.5">
          <table class="w-full table-auto mb-1">
            <thead>
              <tr class="border-b text-gray-500 text-sm">
                <td class="px-2 py-1 w-[35rem]">
                  {{ $t('SETTING_SW_CONDITION_POSITION_ALLOW') }}
                </td>
                <td class="px-2 py-1 w-[10rem] text-center">
                  {{ $t('SETTING_SW_CONDITION_POSITION_RADIUS') }}
                </td>
                <td class="px-2 py-1 w-[10rem] text-center"></td>
                <td class="px-2 py-1"></td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="position in limitPosition.positions"
                :key="position"
                class="text-sm"
              >
                <td class="px-2 py-2">
                  {{ position?.name }}
                </td>
                <td class="px-2 py-2 text-center">{{ position?.radius }}m</td>
                <td class="px-2 py-2">
                  <div class="flex-center flex-nowrap gap-2">
                    <VigButton
                      v-vig-tooltip="
                        $t('SETTING_SW_CONDITION_POSITION_VIEW_MAP')
                      "
                      ghost
                      color="gray"
                      icon="MapPin"
                      @click="
                        editingPosition = position;
                        isViewPosition = true;
                        isSelectPosition = true;
                      "
                    />
                    <VigButton
                      v-vig-tooltip="$t('COMMON_LABEL_EDIT')"
                      ghost
                      color="gray"
                      icon="Edit"
                      :disabled="disabled"
                      @click="
                        editingPosition = position;
                        isViewPosition = false;
                        isSelectPosition = true;
                      "
                    />
                    <VigButton
                      v-vig-tooltip="$t('COMMON_LABEL_DELETE')"
                      ghost
                      color="gray"
                      icon="Trash"
                      :disabled="disabled"
                      @click="onPositionDelete(position)"
                    />
                  </div>
                </td>
                <td class="px-2 py-2"></td>
              </tr>
            </tbody>
          </table>
          <VigButton
            ghost
            class="text-sm"
            padding="px-2 py-1"
            :disabled="disabled"
            @click="
              editingPosition = null;
              isViewPosition = false;
              isSelectPosition = true;
            "
          >
            <SynIcon name="Add" />
            <span>{{ $t('SETTING_SW_CONDITION_POSITION_ADD') }}</span>
          </VigButton>
        </div>
      </template>
    </MenuSettingRowItem>
  </div>

  <ModalStartWorkingSettingLocation
    v-if="isSelectPosition"
    :position="editingPosition"
    :readonly="isViewPosition"
    @on-close="
      editingPosition = null;
      isViewPosition = false;
      isSelectPosition = false;
    "
    @on-confirm="onPositionConfirm"
  />
</template>
