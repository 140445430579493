import { ref } from 'vue';
import WorkflowEntity from '@/domain/entities/workflow/WorkflowEntity';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import workflowStore from '@/store/workflow';

export default function workflowDetailComposables(workflowInfo) {
    const _workflowStore = workflowStore();
    const workflowDetail = ref<WorkflowEntity>(new WorkflowEntity({}));
    const isLoading = ref<boolean>(true);
    const initComponent = async () => {
        if (!workflowInfo?.id) {
            isLoading.value = false;
            return;
        }

        isLoading.value = true;
        const data = await _workflowStore.getWorkflowById(workflowInfo?.id);

        workflowDetail.value = new WorkflowEntity(data);

        isLoading.value = false;
    };

    const isUpdating = ref<boolean>(false);
    const isOpenConfirmModal = ref<boolean>(false);
    const onClickSave = async (isApplyAllTask, callback) => {
        isUpdating.value = true;

        await workflowDetail.value.updateWorkflowSteps(isApplyAllTask);

        isOpenConfirmModal.value = false;
        isUpdating.value = false;

        if (callback)
            callback(workflowDetail.value.steps, workflowDetail.value.drawData);
    };

    const onPreCheckToSave = async (callback) => {
        if (
            workflowDetail.value?.steps?.filter((s) => s?.isStart)?.length == 0
        ) {
            settingGlobalModal({
                type: 'notification',
                title: translate('COMMON_LABEL_NOTE'),
                content:
                    translate('WORKFLOW_LABEL_NEED_TO_HAVE_A_START_POINT') ||
                    'Workflow needs to have a start point',
                confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                confirmable: true,
            });
            ask();
            return;
        }

        if (workflowInfo?.totalTaskUsed && workflowInfo?.totalTaskUsed > 0) {
            isOpenConfirmModal.value = true;
            return;
        }

        onClickSave(false, callback);
    };

    initComponent();
    return {
        workflowDetail,
        isLoading,
        isUpdating,
        isOpenConfirmModal,
        onClickSave,
        onPreCheckToSave,
        initComponent,
    };
}
