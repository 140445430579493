<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import {
  adminTypeStyle,
  // timeUtc,
  statusOptions,
} from '@/ui/common/constants/user';
import {
  arrayOrderBy,
  cloneDeep,
  isValidEmail,
} from '@/ui/hooks/commonFunction';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import userStore from '@/store/user';
import settingStore from '@/store/setting';
// import authStore from '@/store/auth';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { useField } from 'vee-validate';
import UserDeactiveQuestion from '@/ui/components/user/UserDeactiveQuestion.vue';
import { AdminType } from '@/ui/common/constants/constant';
// import {hasRolePermission} from '@/ui/hooks/permission/permission-by-role';
import myProfile from '@/store/auth/my-profile';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';

const props = defineProps<{
  info?: any;
  isEdit?: boolean;
  keyNotShow?: any[];
  keyDisable?: any[];
  hiddenDetail?: boolean;
}>();

const emit = defineEmits(['update:info', 'verifyEmail']);
const _userStore = userStore();
const _settingStore = settingStore();
const _myProfile = myProfile();

const isOwner = computed(() => _myProfile.isOwner);
const isAdmin = computed(() => _myProfile.isAdmin);
const adminTypeList = computed(() =>
  arrayOrderBy(Object.values(adminTypeStyle), 'order', 'asc')
);
const position = computed(() => _userStore.position);
const allUserShortSummary = computed(() => _userStore.allUserStatistic);

const currentLanguage = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';
const information = ref({} as any);
const isVerifyEmail = ref(true);
const isVerifyPhone = ref(true);
const showDeactiveQuestion = ref(false);
const isReady = ref(false);

const timeUtc = computed(() =>
  _settingStore.timezoneList.map((tz) => {
    return {
      ...tz,
      countryNameLg: tz.countryName[currentLanguage],
      nameIcon:
        tz.countryCode === 'VN'
          ? 'flag-vietnam'
          : tz.countryCode === 'FR'
          ? 'flag-france'
          : 'flag-united-state',
    };
  })
);

onMounted(async () => {
  if (adminTypeList.value.length < 1) await _userStore.fetchAllProfile();
  if (position.value.length < 1) await _userStore.fetchAllPosition();
});

const { value: firstName, errorMessage: firstNameError } = useField(
  'data.firstName',
  function (value) {
    if (!value) return translate('COMMON_THIS_FIELD_REQUIRED');
    return true;
  }
);
const { value: lastName, errorMessage: lastNameError } = useField(
  'data.lastName',
  function (value) {
    if (!value) return translate('COMMON_THIS_FIELD_REQUIRED');
    return true;
  }
);
const { value: phone, errorMessage: phoneError } = useField('data.phone');
const { value: email, errorMessage: emailError } = useField('data.email');
const { value: address, errorMessage: addressError } = useField('data.address');
const { value: addressDetail } = useField('data.addressDetail') as any;

const updateValue = () => {
  email.value = information.value.email ? information.value.email : '';
  firstName.value = information.value.firstName
    ? information.value.firstName
    : '';
  lastName.value = information.value.lastName ? information.value.lastName : '';
  phone.value = information.value.phone ? information.value.phone : '';
  address.value = information.value.address ? information.value.address : '';
};
const initData = () => {
  information.value = cloneDeep(props.info);
  updateValue();
};

watch(
  () => props.info?.id,
  () => initData()
);

initData();

const onAddressSelect = (value) => {
  if (value) {
    addressDetail.value = JSON.stringify(value);
  }
  information.value.address = address.value || addressDetail.value?.fullAddress;
  emit('update:info', {
    info: information.value,
    isReady: isReady.value,
  });
};

const changeInfo = async (changedField?: string) => {
  if (changedField === 'status') {
    if (props.info.active && !information.value.active) {
      if (isAdmin.value && information?.value?.adminType == AdminType.Owner) {
        settingGlobalModal({
          type: 'notification',
          title: translate('USER_MANAGEMENT_UNABLE_TO_PERFORM_ACTION_TITLE'),
          content: translate('USER_MANAGEMENT_CANNOT_DEACTIVE_OWNER'),
          confirmLabel: translate('COMMON_LABEL_CLOSE'),
          closeable: true,
        });
        await ask();
        information.value.active = true;
        return;
      }

      showDeactiveQuestion.value = true;
    }
  }
  information.value.email = email.value || '';
  information.value.firstName = firstName.value || '';
  information.value.lastName = lastName.value || '';
  information.value.phone = phone.value || '';
  information.value.address =
    address.value || addressDetail.value?.fullAddress || null;
  if (
    information.value.phone &&
    information.value.email &&
    isValidEmail(information.value.email) &&
    information.value.lastName &&
    information.value.firstName &&
    information.value.utc
  ) {
    isReady.value = true;
  }

  emit('update:info', {
    info: information.value,
    isReady: isReady.value,
  });
};

const onChangePhone = (phoneObj, phoneN) => {
  if (phoneObj?.number) {
    information.value.phone = phoneObj.number;
    emit('update:info', {
      info: information.value,
      isReady: isReady.value,
    });
  } else {
    information.value.phone = phoneN;
    emit('update:info', {
      info: information.value,
      isReady: isReady.value,
    });
  }
};

const onCancelChangeStatus = () => {
  showDeactiveQuestion.value = false;

  information.value.active = true;
};

const onConfirmChangeStatus = () => {
  showDeactiveQuestion.value = false;
};
</script>

<template>
  <!-- login -->
  <div
    v-if="
      'login' in info && keyNotShow?.find((el) => el == 'login') === undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span>{{ $t('USERMANAGEMENT_LABEL_USERNAME') + '*' }}</span>
    </div>
    <div class="w-full">
      <SynInput
        v-if="isEdit"
        v-model="information.login"
        :is-disabled="!!keyDisable?.find((el) => el == 'login')"
        :placeholder="info.login || $t('USERMANAGEMENT_LABEL_USERNAME')"
        :error-message="
          information.firstName == ''
            ? $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED')
            : ''
        "
        @change="changeInfo"
      />
      <div
        v-else
        class="px-4 py-1 border-b w-full"
        :class="info.login ? 'font-medium' : 'text-gray-500 font-normal'"
      >
        {{ info.login || $t('USERMANAGEMENT_LABEL_USERNAME') }}
      </div>
    </div>
  </div>

  <!-- first name -->
  <div
    v-if="
      'firstName' in info &&
      keyNotShow?.find((el) => el == 'firstName') == undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span
        >{{ $t('USERMANAGEMENT_TABLE_FIRST_NAME')
        }}<span class="text-red-500">*</span></span
      >
    </div>
    <div class="w-full">
      <SynFormInput v-if="isEdit" :error-message="firstNameError">
        <SynInput
          v-model="firstName"
          :is-disabled="!!keyDisable?.find((el) => el == 'firstName')"
          :placeholder="firstName || $t('USERMANAGEMENT_TABLE_FIRST_NAME')"
          :error-message="firstNameError"
          @change="changeInfo"
        />
      </SynFormInput>
      <div
        v-else
        class="px-4 py-1 border-b w-full"
        :class="firstName ? 'font-medium' : 'text-gray-500 font-normal'"
      >
        {{ firstName || $t('USERMANAGEMENT_TABLE_FIRST_NAME') }}
      </div>
    </div>
  </div>

  <!-- last name -->
  <div
    v-if="
      'lastName' in info &&
      keyNotShow?.find((el) => el == 'lastName') == undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span
        >{{ $t('USERMANAGEMENT_TABLE_LAST_NAME')
        }}<span class="text-red-500">*</span></span
      >
    </div>
    <div class="w-full">
      <SynFormInput v-if="isEdit" :error-message="lastNameError">
        <SynInput
          v-model="lastName"
          :is-disabled="!!keyDisable?.find((el) => el == 'lastName')"
          :placeholder="lastName || $t('USERMANAGEMENT_TABLE_LAST_NAME')"
          :error-message="lastNameError"
          @change="changeInfo"
        />
      </SynFormInput>
      <div
        v-else
        class="px-4 py-1 border-b w-full"
        :class="lastName ? 'font-medium' : 'text-gray-500 font-normal'"
      >
        {{ lastName || $t('USERMANAGEMENT_TABLE_LAST_NAME') }}
      </div>
    </div>
  </div>

  <!-- email -->
  <div
    v-if="
      'email' in info && keyNotShow?.find((el) => el == 'email') == undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span
        >{{ $t('USERMANAGEMENT_TABLE_EMAIL')
        }}<span class="text-red-500">*</span></span
      >
    </div>
    <SynFormInput v-if="isEdit" :error-message="emailError">
      <SynInput
        v-model="email"
        input-type="email"
        :error-message="emailError"
        :is-disabled="!!keyDisable?.find((el) => el == 'email')"
        :placeholder="email || $t('USERMANAGEMENT_TABLE_EMAIL')"
        @change="changeInfo"
      />
    </SynFormInput>
    <div
      v-else
      :title="isVerifyEmail ? '' : $t('LOGIN_EMAIL_NOT_VERIFY')"
      class="flex justify-between px-4 py-1 border-b w-full"
      :class="email ? 'font-medium' : 'text-gray-500 font-normal'"
    >
      <span v-if="hiddenDetail" class="text-gray-500"> ******** </span>
      <span v-else>
        {{ email || $t('USERMANAGEMENT_TABLE_EMAIL') }}
      </span>
    </div>
  </div>

  <!-- phone -->
  <div
    v-if="
      'phone' in info && keyNotShow?.find((el) => el == 'phone') == undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span
        >{{ $t('USERMANAGEMENT_FORM_PHONE')
        }}<span class="text-red-500">*</span></span
      >
    </div>
    <SynFormInput v-if="isEdit" :error-message="phoneError">
      <SynTelInput
        class="w-full"
        :value="phone"
        :error="phoneError"
        :disabled="!!keyDisable?.find((el) => el == 'phone')"
        :auto-default-country="false"
        @keydown="(value) => (phone = value)"
        @input="onChangePhone"
      />
    </SynFormInput>
    <div
      v-else
      :title="isVerifyPhone ? '' : $t('LOGIN_PHONE_NOT_VERIFY')"
      class="flex justify-between px-4 py-1 border-b w-full"
      :class="phone ? 'font-medium' : 'text-gray-500 font-normal'"
    >
      <span v-if="hiddenDetail" class="text-gray-500"> ******** </span>
      <span v-else>{{ phone || $t('USERMANAGEMENT_FORM_PHONE') }}</span>
      <!-- <SynIcon
        class="cursor-pointer"
        :name="isVerifyPhone ? 'check' : 'info'"
        :class="isVerifyPhone ? 'text-green-500' : 'fill-red-500 text-red-500'"
      /> -->
    </div>
  </div>

  <!-- ADMIN TYPE -->
  <div
    v-if="
      'adminType' in info &&
      keyNotShow?.find((el) => el == 'adminType') == undefined
    "
    v-permission-model
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span>{{ $t('USERMANAGEMENT_FORM_ADMIN_TYPE') }}</span>
    </div>
    <SynSelect
      v-if="isEdit"
      v-model="information.adminType"
      class="w-full"
      key-field="key"
      text-field="labelKey"
      is-translate
      :is-disable="
        !!keyDisable?.find((el) => el == 'adminType') ||
        (information.adminType === AdminType.Owner && isAdmin)
      "
      width-class="w-full"
      key-item-not-show="key"
      :value-item-not-show="isOwner ? [] : [AdminType.Owner]"
      :placeholder="
        $t(adminTypeList.find((el) => el.key == info.adminType)?.labelKey) ||
        $t('USERMANAGEMENT_FORM_ADMIN_TYPE')
      "
      :options="adminTypeList"
      @update:model-value="information.adminType"
      @change="changeInfo"
    />
    <div v-else class="font-medium px-4 py-1 border-b w-full">
      {{
        $t(adminTypeList.find((el) => el.key == info.adminType)?.labelKey) ||
        $t('USERMANAGEMENT_FORM_ADMIN_TYPE')
      }}
    </div>
  </div>

  <!-- position -->
  <div
    v-if="
      'positionId' in info &&
      keyNotShow?.find((el) => el == 'positionId') == undefined
    "
    v-permission-model
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span>{{ $t('USERMANAGEMENT_TABLE_POSITION') }}</span>
    </div>
    <SynSelect
      v-if="isEdit"
      v-model="information.positionId"
      class="w-full"
      key-field="id"
      text-field="name"
      :is-disable="!!keyDisable?.find((el) => el === 'position')"
      width-class="w-full"
      key-item-not-show="active"
      :value-item-not-show="[false]"
      :placeholder="
        position.find((el) => el.id == info.positionId)
          ? position.find((el) => el.id == info.positionId).name
          : $t('USERMANAGEMENT_TABLE_POSITION')
      "
      :options="position"
      @update:model-value="information.positionId"
      @change="changeInfo"
    >
    </SynSelect>
    <div v-else class="font-medium px-4 py-1 border-b w-full">
      {{
        position.find((el) => el.id == info.positionId)
          ? position.find((el) => el.id == info.positionId).name
          : $t('USERMANAGEMENT_TABLE_POSITION')
      }}
    </div>
  </div>

  <!-- active -->
  <div
    v-if="
      'active' in info && keyNotShow?.find((el) => el == 'active') == undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span>{{ $t('USERMANAGEMENT_TABLE_STATUS') }}</span>
    </div>
    <SynSelect
      v-if="isEdit"
      v-model="information.active"
      class="w-full"
      key-field="status"
      text-field="viewLabel"
      key-item-not-show="status"
      :is-disable="!!keyDisable?.find((el) => el == 'active')"
      :is-translate="true"
      width-class="w-full"
      :placeholder="
        $t(
          statusOptions.find((el) => el.status == info.active)?.label ||
            'USERMANAGEMENT_TABLE_POSITION'
        )
      "
      :value-item-not-show="[null]"
      :options="statusOptions"
      @update:model-value="information.active"
      @change="changeInfo('status')"
    />
    <div
      v-else
      class="px-4 py-1 border-b w-full"
      :class="
        statusOptions.find((el) => el.status == info.active)
          ? 'font-medium'
          : 'text-gray-500 font-normal'
      "
    >
      {{
        $t(
          statusOptions.find((el) => el.status == info.active)?.label ||
            'USERMANAGEMENT_TABLE_POSITION'
        )
      }}
    </div>
  </div>

  <!-- dob -->
  <div
    v-if="
      'birthday' in info &&
      keyNotShow?.find((el) => el == 'birthday') == undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span>{{ $t('USERMANAGEMENT_TABLE_DOB') }}</span>
    </div>
    <div class="w-full">
      <SynDate
        v-if="isEdit"
        v-model="information.birthday"
        min-width="100%"
        :is-disabled="!!keyDisable?.find((el) => el == 'birthday')"
        @change="
          (value) => {
            information.birthday = value;
            changeInfo;
          }
        "
      />
      <div
        v-else
        class="px-4 py-1 border-b w-full"
        :class="info.birthday ? 'font-medium' : 'text-gray-500 font-normal'"
      >
        {{ info.birthday || $t('USERMANAGEMENT_TABLE_DOB') }}
      </div>
    </div>
  </div>

  <!-- address -->
  <div
    v-if="
      'address' in info &&
      keyNotShow?.find((el) => el == 'address') == undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span>{{ $t('USERMANAGEMENT_TABLE_ADDRESS') }}</span>
    </div>
    <SynFormInput v-if="isEdit" :error-message="addressError">
      <vig-address-box
        v-model="address"
        :placeholder="address || $t('USERMANAGEMENT_TABLE_ADDRESS')"
        class="vig-input"
        :error-message="addressError"
        @change="changeInfo"
        @on-address-select="onAddressSelect"
      />
    </SynFormInput>
    <!-- <SynInput-area
      v-if="isEdit"
      v-model="information.address"
      class="py-2"
      :is-disabled="keyDisable.find((el) => el == 'address')"
      :placeholder="info.address || $t('USERMANAGEMENT_TABLE_ADDRESS')"
      @change="value => changeInfo(value)"
    /-area> -->
    <div
      v-else
      class="px-4 py-1 border-b w-full"
      :class="info.address ? 'font-medium' : 'text-gray-500 font-normal'"
    >
      {{ info.address || $t('USERMANAGEMENT_TABLE_ADDRESS') }}
    </div>
  </div>

  <!-- Identity Card -->
  <div
    v-if="
      'identityCard' in info &&
      keyNotShow?.find((el) => el == 'identityCard') == undefined
    "
    v-permission-model
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span>{{ $t('USER_FORM_IDENTITY_CARD') }}</span>
    </div>
    <SynInput
      v-if="isEdit"
      v-model="information.identityCard"
      :is-disabled="!!keyDisable?.find((el) => el == 'identityCard')"
      :placeholder="info.identityCard || $t('USER_FORM_IDENTITY_CARD')"
      @change="changeInfo"
    />
    <div
      v-else
      class="px-4 py-1 border-b w-full"
      :class="info.identityCard ? 'font-medium' : 'text-gray-500 font-normal'"
    >
      {{ info.identityCard || $t('USER_FORM_IDENTITY_CARD') }}
    </div>
  </div>

  <!-- skype -->
  <div
    v-if="
      'skyper' in info && keyNotShow?.find((el) => el == 'skyper') === undefined
    "
    class="my-2 flex"
  >
    <div class="flex items-center text-sm pr-4 text-current-800 w-44">
      <span>{{ $t('USERMANAGEMENT_FORM_SKYPE') }}</span>
    </div>
    <SynInput
      v-if="isEdit"
      v-model="information.skyper"
      :placeholder="info.skyper || $t('USERMANAGEMENT_FORM_SKYPE')"
      :is-disabled="!!keyDisable?.find((el) => el == 'skyper')"
      @change="changeInfo"
    />
    <div
      v-else
      class="px-4 py-1 border-b w-full"
      :class="info.skyper ? 'font-medium' : 'text-gray-500 font-normal'"
    >
      {{ info.skyper || $t('USERMANAGEMENT_FORM_SKYPE') }}
    </div>
  </div>

  <!-- time utc -->
  <div
    v-if="'utc' in info && keyNotShow?.find((el) => el == 'utc') == undefined"
    class="my-2 flex"
  >
    <div class="flex items-center pr-4 text-sm text-current-800 w-44">
      <span
        >{{ $t('USERMANAGEMENT_FORM_TIME_UTC')
        }}<span class="text-red-500">*</span></span
      >
    </div>

    <SynSelect
      v-if="isEdit"
      v-model="information.utc"
      class="w-full"
      key-field="utc"
      text-field="countryNameLg"
      name-icon="nameIcon"
      width-class="w-full"
      is-select-flags
      :error-message="
        information.utc == ''
          ? $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED')
          : ''
      "
      :is-icon-beside-label="true"
      :is-disable="keyDisable?.find((el) => el == 'utc')"
      :placeholder="
        info.utc
          ? timeUtc.find((element) => element.utc == info.utc)?.countryNameLg
          : $t('USERMANAGEMENT_FORM_TIME_UTC')
      "
      :options="timeUtc"
      @update:model-value="
        (value) => {
          information.utc = value;
          changeInfo;
        }
      "
      @change="changeInfo"
    >
    </SynSelect>
    <div
      v-else
      class="flex items-center px-4 py-1 border-b w-full"
      :class="info.utc ? 'font-medium' : 'text-gray-500 font-normal'"
    >
      <SynIcon
        class="mr-2"
        :name="
          info.utc
            ? timeUtc.find((element) => element.utc == info.utc)?.nameIcon
            : ''
        "
      />
      <span>{{
        info.utc
          ? timeUtc.find((element) => element.utc == info.utc)?.countryNameLg
          : $t('USERMANAGEMENT_FORM_TIME_UTC')
      }}</span>
    </div>
  </div>

  <UserDeactiveQuestion
    :visible="showDeactiveQuestion"
    :id-user="information?.id"
    :name-user="information?.lastName + ' ' + information?.firstName"
    :all-user-short-summary="allUserShortSummary"
    :status-active="information.active ? 0 : 1"
    @on-cancel="onCancelChangeStatus"
    @on-update-user="onConfirmChangeStatus"
  />
</template>
