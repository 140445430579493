<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M366,0H146c-20.678,0-37.5,16.822-37.5,37.5v437c0,20.678,16.822,37.5,37.5,37.5h220c20.678,0,37.5-16.822,37.5-37.5v-437
			C403.5,16.822,386.678,0,366,0z M388.5,407H176c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h212.5v52.5
			c0,12.407-10.093,22.5-22.5,22.5H146c-12.407,0-22.5-10.093-22.5-22.5V422H146c4.142,0,7.5-3.358,7.5-7.5
			c0-4.142-3.358-7.5-7.5-7.5h-22.5V75h265V407z M388.5,60h-265V37.5c0-12.407,10.093-22.5,22.5-22.5h220
			c12.407,0,22.5,10.093,22.5,22.5V60z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M286,30h-30c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h30c4.142,0,7.5-3.358,7.5-7.5
			C293.5,33.358,290.142,30,286,30z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,437c-12.407,0-22.5,10.093-22.5,22.5c0,12.407,10.093,22.5,22.5,22.5s22.5-10.093,22.5-22.5
			C278.5,447.093,268.407,437,256,437z M256,467c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5
			S260.136,467,256,467z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <circle cx="226" cy="37.5" r="7.5"></circle>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
