<script setup lang="ts">
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import FileViewer from './FileViewer.vue';
import VigSlider from '@/ui/common/molecules/Slider/VigSlider.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import ActionInFile from '@/ui/modules/ged/modal-file-viewer/ActionInFile.vue';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import ModalSaveToResource from '@/ui/modules/ged/folder/modal-save-to-resource/ModalSaveToResource.vue';
import DriveDocumentActions from '@/ui/modules/drive-document/drive-document-actions/DriveDocumentActions.vue';
import { isGoogleFile } from '@/ui/hooks/fileHook';

// import { saveAs } from 'file-saver';
// import axios from 'axios';

const props = withDefaults(
  defineProps<{
    moduleSource?: ModuleSourceFileEnum;
    startIndex: number;
    files: any[];
    editable: boolean;
    isLoading: boolean;
    isFileInTask: boolean;
    contentType: string;
  }>(),
  {
    editable: true,
  }
);

const emit = defineEmits([
  'onClose',
  'onSaveNote',
  'onRemove',
  'onSaveDescription',
  'onNext',
  'onBack',
  'onViewLastFile',
  'onInfoClick',
]);

onMounted(() => {
  filesPreview.value?.focus();
  onScollViewFile();
});

const currentIndex = ref(props.startIndex || 0);
watch(
  () => props.files.length,
  (newValue) => {
    if (newValue > 1 && newValue >= currentIndex.value)
      onScollViewFile('start');
  }
);

const saveToResource = ref(false);
const listFile = computed(() => props.files);
const isLoadingFile = computed(() => props.isLoading);
const totalFile = computed(() => listFile.value?.length);
const currentFile = computed(() => {
  const total = totalFile.value;
  if (total == 0) return {};
  if (total <= currentIndex.value) {
    return cloneDeep(props.files[total - 1]);
  }
  return props.files[currentIndex.value];
});

const onSelectItem = (item) => {
  const maxLength = listFile.value?.length;
  isEditMode.value = false;
  isEdittingDescription.value = false;
  currentIndex.value = item;
  if (currentIndex.value === maxLength - 1) {
    emit('onViewLastFile', currentIndex.value);
  }
};

const onNext = () => {
  const maxLength = listFile.value?.length;
  emit('onNext', currentIndex.value);
  if (isLoadingFile.value) return;

  if (currentIndex.value == maxLength - 1) {
    onSelectItem(0);
    onScollViewFile();
    return;
  }
  onSelectItem(currentIndex.value + 1);
  onScollViewFile();
};

const onBack = () => {
  const maxLength = listFile.value?.length;
  emit('onBack', currentIndex.value);
  if (currentIndex.value == 0) {
    onSelectItem(maxLength - 1);
    onScollViewFile();
    return;
  }
  onSelectItem(currentIndex.value - 1);
  onScollViewFile();
};

const filesPreview = ref(null as any);

const isEditMode = ref(false);

const isEdittingDescription = ref(false);
const descriptionValue = ref('');

const onOpenEditDescription = () => {
  isEdittingDescription.value = true;
  currentIndex.value = listFile.value?.length - 1;
  descriptionValue.value = currentFile.value?.data;
};

// const onDownloadClick = async () => {
//   if (!currentFile.value?.pathUrl) return;

//   try {
//     const res = await axios.get(currentFile.value.pathUrl, {
//       responseType: 'blob',
//     });
//     saveAs(res.data, currentFile.value.name);
//   } catch (e) {
//     console.log(e);
//   }
// };

// eslint-disable-next-line no-undef
const onScollViewFile = async (blockValue: any = 'center') => {
  await nextTick();
  let element = document.getElementById(
    `multiple_file_slider_${currentIndex.value}`
  ) as HTMLDivElement;
  element.scrollIntoView({ block: blockValue });
};

defineExpose({
  onOpenEditDescription,
  onSelectItem,
});
</script>

<template>
  <SynModalNoFocus
    is-hidden-footer
    disable-click-outside
    z-index="z-50"
    container-class="w-11/12"
    :container-style="`max-height: 1920px;
          max-width: 1920px;
          min-height:500px;
          min-width:600px;
          width: 100%;
          height: 100%;
          `"
    :background-style="`padding: 20px;`"
    style-body="p-0 flex flex-col relative"
    tabindex="1"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="flex items-center justify-between space-x-3">
        <div class="truncate text-lg">
          <span v-if="!isFileInTask">{{
            currentFile?.name || currentFile?.Name
          }}</span>
          <span v-if="isFileInTask">{{
            currentFile?.fileName || currentFile?.Name
          }}</span>

          <!--          <div class="ml-2 flex items-center">-->
          <!--            -->
          <!--            &lt;!&ndash; <vig-button-->
          <!--              ghost-->
          <!--              color="gray"-->
          <!--              rounded="rounded-full"-->
          <!--              padding="p-1"-->
          <!--              @click="onDownloadClick"-->
          <!--            >-->
          <!--              <SynIcon name="Download" custom-class="w-6 h-6" class="fill-gray-500" />-->
          <!--            </vig-button> &ndash;&gt;-->
          <!--          </div>-->
        </div>

        <div>
          <div
            v-if="currentFile?.metadata?.taskId"
            class="truncate text-base font-normal"
          >
            <span class="text-current font-semibold mr-2">
              {{ currentFile?.metadata?.taskCode?.split('-').pop() }}
            </span>
            <span>{{ currentFile?.metadata?.taskName }}</span>
          </div>
          <div
            v-if="currentFile?.creationTime"
            class="text-sm truncate font-normal flex items-center"
          >
            <!-- {{
              $filters.dayjs(currentFile?.creationTime, 'MMM DD, YYYY, hh:mm A')
            }} -->
            <SynLabelDateTime
              format="date_time"
              :datetime="currentFile?.creationTime"
            />
            <vig-button
              ghost
              color="blue"
              rounded="rounded-full"
              padding="p-1"
              @click="$emit('onInfoClick')"
            >
              <SynIcon
                name="Info"
                custom-class="w-5 h-5"
                class="fill-blue-500"
              />
            </vig-button>
          </div>
        </div>
        <div class="flex items-center">
          <DriveDocumentActions
            v-if="isGoogleFile(currentFile?.contentType)"
            :document="currentFile"
            @update:is-fullscreen="$refs.fileViewer?.setFullscreen($event)"
          />
          <ActionInFile
            v-if="!isFileInTask"
            :file="currentFile"
            :name="currentFile?.name || currentFile?.Name"
            :path="currentFile?.pathUrl || currentFile?.Path"
            :content-type="
              currentFile?.contentType ||
              currentFile?.type ||
              currentFile?.ContentType
            "
            :module-source="ModuleSourceFileEnum.task"
            :has-download="!isGoogleFile(currentFile?.contentType)"
            :has-task-actions="!isGoogleFile(currentFile?.contentType)"
            @close="$emit('onClose')"
            @save-to-resource="saveToResource = true"
          />
          <ActionInFile
            v-if="isFileInTask"
            :file="currentFile"
            :name="currentFile?.fileName || currentFile?.Name"
            :path="
              currentFile?.fileUrl ||
              currentFile?.imagePath ||
              currentFile?.Path
            "
            :content-type="
              currentFile?.contentType ||
              currentFile?.type ||
              contentType ||
              currentFile?.ContentType
            "
            :has-download="!isGoogleFile(currentFile?.contentType)"
            :has-task-actions="!isGoogleFile(currentFile?.contentType)"
            @close="$emit('onClose')"
          />
          <template v-if="files.length > 1">
            <div class="ml-6 flex items-center space-x-2">
              <div class="flex items-center">
                <div
                  class="
                    flex-center
                    bg-current-500 bg-opacity-70
                    hover:bg-opacity-100
                    text-white
                    font-bold
                    rounded-full
                    w-9
                    h-9
                    cursor-pointer
                  "
                  @click="onBack"
                >
                  <SynIcon name="before" class="fill-white" />
                </div>
              </div>
              <div class="flex items-center">
                <div
                  id="column_view_flat_next"
                  class="
                    flex-center
                    bg-current-500 bg-opacity-70
                    hover:bg-opacity-100
                    text-white
                    font-bold
                    rounded-full
                    w-9
                    h-9
                    cursor-pointer
                  "
                  @click="onNext"
                >
                  <SynIcon name="next" class="fill-white" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template #body>
      <div
        ref="filesPreview"
        tabindex="0"
        class="h-full flex outline-none flex-col"
        @keyup.left="onBack"
        @keyup.down="onBack"
        @keyup.up="onNext"
        @keyup.right="onNext"
      >
        <!-- View -->
        <div class="flex-1 flex flex-col w-full relative bg-gray-800 p-2 h-0">
          <FileViewer
            v-if="!isFileInTask"
            ref="fileViewer"
            :file="currentFile"
            :name="currentFile?.name || currentFile?.Name"
            :path="
              currentFile?.pathUrl || currentFile?.Path || currentFile?.link
            "
            :content-type="
              currentFile?.contentType ||
              currentFile?.type ||
              currentFile?.ContentType
            "
            has-download
            @close="$emit('onClose')"
          />
          <FileViewer
            v-if="isFileInTask"
            ref="fileViewer"
            :file="currentFile"
            :name="currentFile?.fileName || currentFile?.Name"
            :path="
              currentFile?.fileUrl
                ? currentFile.fileUrl
                : currentFile?.imagePath || currentFile?.Path
            "
            :content-type="
              currentFile?.contentType ||
              currentFile?.type ||
              contentType ||
              currentFile?.ContentType
            "
            has-download
            @close="$emit('onClose')"
          />

          <!-- <FileViewer
            :file="currentFile"
            :name="currentFile?.name"
            :path="currentFileUrl"
            :content-type="currentFile?.contentType || currentFile?.type"
            has-download
            @close="$emit('onClose')"
          /> -->
        </div>

        <!-- List image footer -->
        <div class="bg-gray-500">
          <VigSlider>
            <UploadPreview
              v-for="(file, index) in listFile"
              :id="`multiple_file_slider_${index}`"
              :key="file"
              :file="file"
              :is-active="index == currentIndex"
              :is-note="file?.isNote"
              selectable
              readonly
              is-hidden-info
              has-opacity
              @on-select="onSelectItem(index)"
              @remove="$emit('onRemove', file)"
            />
            <div
              v-if="isLoadingFile"
              class="p-1 rounded h-full w-full flex-center"
            >
              <div class="w-28 h-20">
                <SynIcon
                  name="spinner"
                  class="fill-current border-none animate-spin"
                >
                </SynIcon>
              </div>
            </div>
            <slot name="footer-action"></slot>
          </VigSlider>
        </div>
      </div>
    </template>
  </SynModalNoFocus>

  <ModalSaveToResource
    v-if="saveToResource"
    :file="currentFile"
    @on-close="saveToResource = false"
  />
</template>
