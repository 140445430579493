<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import TaskDetail from '@/ui/modules/task/detail/TaskDetail.vue';
const route = useRoute();

const taskCode = computed(() => route.params?.taskCode);
</script>

<template>
  <div class="w-full h-full flex flex-col bg-gray-100 p-4 rounded-lg">
    <task-detail :task-code="taskCode" />
  </div>
</template>
