<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import FileIcon from '@/ui/modules/ged/_commons/file-icon/FileIcon.vue';
import $constants from '@/ui/plugins/constants';
import chatStore from '@/store/chat';
import calculateImageLayoutComposables from '@/ui/composables/chat/calculate-image-layout-composables';
import { downloadMessageFiles } from '@/ui/plugins/firebases/firestore-database/constants';
// import {useRoute} from 'vue-router';

const props = withDefaults(
  defineProps<{
    file: any;
    size?: 'sm' | 'lg' | 'md';
    lengthFiles?: number;
    imgSize?: string;
    isHiddenAction?: boolean;
    isCheckError?: boolean;
  }>(),
  {
    size: 'sm',
    imgSize: $constants.IMAGE.SIZE.LARGE,
    lengthFiles: 0,
    isCheckError: false,
  }
);

const contentTypeImage = [
  'image/bmp',
  'image/cis-cod',
  'image/gif',
  'image/ief',
  'image/jpeg',
  'image/pipeg',
  'image/svg+xml',
  'image/tiff',
  'image/x-cmu-raster',
  'image/x-cmx',
  'image/x-icon',
  'image/x-portable-anymap',
  'image/x-portable-bitmap',
  'image/png',
  'image/webp',
  'image/apng',
  'image/avif',
  'image/jpg',
  'image/heif',
  'image/heic',
];

defineEmits(['onFileClick']);
// const route = useRoute();
const { containerDefaultBySize } = calculateImageLayoutComposables({
  size: props.size,
});
// const isPageMessage = computed(() => route?.path?.includes('/messages'));
const isImageFile = computed(
  () =>
    props.file?.contentType?.startsWith($constants.FILE.TYPE.IMAGE) &&
    contentTypeImage.includes(props.file?.contentType)
);
const isAudioFile = computed(() =>
  props.file?.contentType?.startsWith($constants.FILE.TYPE.AUDIO)
);
const isVideoFile = computed(() =>
  props.file?.contentType?.startsWith($constants.FILE.TYPE.VIDEO)
);

const _chatStore = chatStore();

const uploadingFiles = computed(() => _chatStore.uploadingFiles);

const isOpenDetail = ref(false);
// const isErrorImage = ref(false);

const onOpenActionLink = (e) => {
  e.preventDefault();
  isOpenDetail.value = true;
};

onMounted(() => {
  // console.log(props.file);
});

const onMessageFileDownloadClick = () => {
  if (!props.file) return;

  downloadMessageFiles([props.file]);
};
</script>

<template>
  <div
    class="h-full relative hover-to-show__action rounded-xl"
    style="min-width: 8rem"
    :style="`max-width: ${containerDefaultBySize.containerMaxWidth}px;`"
  >
    <!--IMAGE-->
    <article
      v-if="isImageFile && file.url_full"
      tabindex="0"
      class="h-full rounded border overflow-hidden message-file-image"
    >
      <VigImage
        v-if="file.url_full"
        :path="file.url_full"
        :size="imgSize"
        custom-class="object-cover object-center h-full w-full"
        style="min-height: 2rem"
        :is-check-error="isCheckError"
        class="border-red-400"
        :class="size === 'sm' ? 'max-h-48' : 'max-h-48'"
        @click="$emit('onFileClick')"
      />
      <!--      <div-->
      <!--        v-if="isErrorImage"-->
      <!--        class="-->
      <!--          h-full-->
      <!--          w-full-->
      <!--          flex-center flex-col-->
      <!--          space-y-2-->
      <!--          absolute-->
      <!--          inset-0-->
      <!--          bg-gray-100-->
      <!--          rounded-->
      <!--        "-->
      <!--        style="min-height: 2rem"-->
      <!--        :class="size === 'sm' ? 'max-h-48' : 'max-h-48'"-->
      <!--      >-->
      <!--        <syn-icon-->
      <!--          name="image-not-viewer"-->
      <!--          :custom-class="size === 'sm' ? 'h-6 w-6' : 'h-10 w-10'"-->
      <!--        />-->
      <!--      </div>-->
    </article>

    <!--AUDIO-->
    <div v-else-if="isAudioFile">
      <VigAudio v-if="file?.url_full" :path="file?.url_full"></VigAudio>
    </div>

    <!--VIDEO-->
    <div
      v-else-if="isVideoFile"
      class="flex-center bg-gray-300 rounded border overflow-hidden"
    >
      <VigVideo
        v-if="file?.url_full"
        class="w-full"
        :style="[
          size === 'sm'
            ? 'min-height: 6rem; max-height: 18rem; max-width: 18rem; min-width: 8rem;'
            : 'max-height: 24rem; max-width: 24rem;',
        ]"
        :path="file?.url_full"
        :click-to-play="false"
        prevent-fullscreen
        @click="$emit('onFileClick')"
      />
    </div>

    <!--OTHER FILE-->
    <div
      v-else
      class="
        overflow-hidden
        rounded
        border
        relative
        bg-gray-50
        message-file-image
        text-gray-800
      "
      :style="`min-height: 2rem; width: 100%; max-width: ${containerDefaultBySize.containerMaxWidth}px`"
    >
      <div
        v-if="file?.url_full"
        class="w-full flex items-center"
        :title="file?.name"
        @click="$emit('onFileClick')"
      >
        <div
          class="flex-center pt-3 pb-3 pl-3 pr-1"
          :class="size === 'sm' ? 'h-16' : 'h-20'"
        >
          <FileIcon
            :file-type="file?.contentType"
            :class-name="size === 'sm' ? 'h-6 w-6' : 'h-10 w-10'"
          />
        </div>
        <div
          class="flex-1 break-all"
          :class="size === 'sm' ? ' text-2xs px-2 py-1 ' : ' text-sm px-2 py-3'"
        >
          <span class="text-overflow-hidden font-medium">{{ file?.name }}</span>
        </div>
      </div>
      <div class="absolute right-0 bottom-0 text-2xs text-gray-500 p-0.5">
        {{ file?.size ? $filters.fileSize(file.size) : '' }}
      </div>
    </div>

    <!--UPLOADING-->
    <div
      v-if="
        uploadingFiles[file?.id]?.uploadStatus ===
        $constants.UPLOAD.STATUS.UPLOADING
      "
      class="
        absolute
        right-0
        top-0
        bottom-0
        flex
        items-center
        justify-center
        bg-black bg-opacity-50
        w-full
        h-full
        min-h-48 min-w-48
        rounded-md
      "
    >
      <SynIcon name="Spinner" class="animate-spin fill-white" />
    </div>
    <div
      v-if="
        uploadingFiles[file?.id]?.uploadStatus ===
        $constants.UPLOAD.STATUS.UPLOAD_FAIL
      "
      class="
        absolute
        right-0
        top-0
        bottom-0
        flex
        items-center
        justify-center
        bg-red-100 bg-opacity-50
        border border-red-500
        w-full
        h-full
        min-h-48 min-w-48
        rounded-md
      "
    >
      <SynIcon
        class="absolute top-2 right-2 fill-red-500 text-red-500"
        name="warning"
        custom-clas="w-2.5 h-2.5 fill-red-500 text-red-500"
      />
    </div>
    <span
      v-if="
        uploadingFiles[file?.id]?.uploadStatus ===
        $constants.UPLOAD.STATUS.UPLOAD_FAIL
      "
      class="text-red-600 px-1"
      style="font-size: 10px"
      >{{ $t('MESSAGE_FILE_ERROR_CONTENT') }}</span
    >
    <template v-if="!isAudioFile || !isHiddenAction">
      <VigDropdown
        class="absolute right-0 top-0"
        @on-dropdown-open="isOpenDetail = true"
        @on-dropdown-close="isOpenDetail = false"
      >
        <template #dropdown-toggle>
          <div
            class="
              flex-center
              m-1
              p-1
              rounded-full
              bg-white
              border border-current-100
              hover:bg-current-50 hover:border-current-100
            "
            :class="isOpenDetail ? '' : 'hover-to-show__action_children'"
            @click.self="($event) => onOpenActionLink($event)"
          >
            <SynIcon
              name="dots-vertical"
              custom-class="w-3 h-3 fill-gray-500 text-gray-500"
            />
          </div>
        </template>
        <template #dropdown-menu>
          <div class="flex flex-col py-1">
            <div
              class="
                flex
                items-center
                space-x-2
                text-gray-500
                fill-gray-500
                text-xs
                py-1
                cursor-pointer
                hover:bg-gray-100
                px-2
              "
              @click.stop="onMessageFileDownloadClick"
            >
              <div>
                <SynIcon name="Download" custom-class="w-4 h-4"></SynIcon>
              </div>
              <div>
                {{ $t('CHAT_DOWNLOAD_FILE') || 'Save to your computer' }}
              </div>
            </div>
            <!-- <div
              class="
                flex
                items-center
                border-t
                space-x-2
                text-red-500
                fill-red-500
                text-xs
                cursor-pointer
                hover:bg-gray-100
                py-1
                px-2
              "
              @click.stop="($event) => onClosePreviewLink(link, $event)"
            >
              <div><SynIcon name="trash" custom-class="w-4 h-4"></SynIcon></div>
              <div>{{ $t('CHAT_HIDE_PREVIEW_LINK') }}</div>
            </div> -->
          </div>
        </template>
      </VigDropdown>
    </template>
  </div>
</template>

<style scoped>
.hover-to-show__action {
  position: relative;
}

.hover-to-show__action:hover .hover-to-show__action_children {
  display: flex;
}

.hover-to-show__action_children {
  display: none;
}

.message-file-image::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  border-radius: 8px;
}

.message-file-image:hover::before {
  @apply bg-gray-300 opacity-30 shadow;
  border-radius: 0;
}
</style>
