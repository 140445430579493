<script setup lang="ts">
import { reactive, ref, inject } from 'vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import TaskAttachmentDetail from '@/ui/modules/task/detail/TaskAttachmentDetail.vue';
import taskDrawerStore from '@/store/task/drawer';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import ShapeTaskEntity from '@/ui/components/drawer/entities/ShapeTaskEntity';
import PROVIDER_IDS, {
  TDesktopDrawer,
  // TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
import ShapeActions from '@/ui/components/drawer/components/ShapeActions.vue';

const props = defineProps<{
  shapeEntity: ShapeTaskEntity;
}>();
const _taskDrawerStore = taskDrawerStore();

const currentTaskDetail = ref<any>({});
const loadingData = reactive({
  init: false,
});
const errorCode = ref('');
const initComponent = async () => {
  loadingData.init = true;

  const taskDetail = props.shapeEntity?.sourceId?.toString()
    ? await _taskDrawerStore.getDetailById(
        parseInt(props.shapeEntity?.sourceId?.toString())
      )
    : {};

  if (taskDetail?.errorCode) {
    errorCode.value = taskDetail?.errorCode
      ? `COMMON_ERROR_BY_CODE_${taskDetail?.errorCode}`
      : 'COMMON_ERROR_BY_CODE_6403';

    loadingData.init = false;

    return;
  }
  currentTaskDetail.value = new TaskDetailClass(taskDetail);
};

const isOpenAttachmentDetail = ref(false);
const onOpenAttachmentDetail = () => {
  isOpenAttachmentDetail.value = true;
};
const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;
};

// const desktopDrawerShapeInjected = inject<TDesktopDrawerShape>(
//   PROVIDER_IDS.DESKTOP_DRAWER_SHAPE
// );

// const onMouseDown = (event) => {
//   desktopDrawerShapeInjected?.onMouseDown(event);
// };

const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);
const onClickDetail = () => {
  _taskDrawerStore.pushCurrentIds({
    id: props.shapeEntity?.sourceId?.toString() || '',
    code: props.shapeEntity?.sourceData?.code,
    name: props.shapeEntity?.sourceData?.name,
    tabType: 'DETAIL',
  });
};
const onClickRemove = () => {
  desktopDrawerInjected?.desktopDrawerInstance.value?.removeShapeById(
    props.shapeEntity?.id,
    () => {
      desktopDrawerInjected?.addMoreHistory();
    }
  );
};

initComponent();
</script>
<template>
  <div
    class="flex-center rounded-md"
    :style="{
      width: `${shapeEntity?.width}px`,
      height: `${shapeEntity?.height}px`,
    }"
  >
    <div
      class="w-full h-full bg-white relative rounded-md"
      style="pointer-events: all"
    >
      <TaskCardItem
        :key="currentTaskDetail?.id"
        class="m-0"
        is-hidden-show-more
        is-readonly
        :task-detail="currentTaskDetail"
        @on-show-files="onOpenAttachmentDetail()"
      >
        <template #moreActions>
          <ShapeActions
            @on-click-detail="onClickDetail"
            @on-click-remove="onClickRemove"
          />
        </template>
      </TaskCardItem>
    </div>
  </div>

  <TaskAttachmentDetail
    v-if="isOpenAttachmentDetail && shapeEntity?.sourceId"
    :task-id="shapeEntity?.sourceId"
    @on-close="onCloseAttachment"
  />
</template>
