export enum EWorkingStatus {
    HAVE_NOT_STARTED = 'HAVE_NOT_STARTED',
    WORKING = 'WORKING',
    PAUSE = 'PAUSE',
    STOPPED = 'STOPPED',
}
export enum WORKING_ACTION {
    START = 'START',
    CHECK_IN = 'CHECK_IN',
    PAUSE = 'PAUSE',
    STOP = 'STOP',
}

export const EWorkingStatusUI = {
    [EWorkingStatus.HAVE_NOT_STARTED]: {
        titleCode: 'HEADER_LABEL_START_WD',
        action: WORKING_ACTION.START,
    },
    [EWorkingStatus.WORKING]: {
        titleCode: 'HEADER_LABEL_START_WD',
        action: WORKING_ACTION.PAUSE,
    },
    [EWorkingStatus.PAUSE]: {
        titleCode: 'HEADER_LABEL_START_WD',
        action: WORKING_ACTION.CHECK_IN,
    },
    [EWorkingStatus.STOPPED]: {
        titleCode: 'HEADER_LABEL_START_WD',
        action: WORKING_ACTION.STOP,
    },
};
