import tippy from 'tippy.js';

const _appendTippyTooltip = (el, content, bindingValue) => {
    const allowHTML = true;
    const appendTo = () => document.body;
    const placement = bindingValue?.placement || 'top';
    const arrow = bindingValue?.arrow || false;
    const theme = bindingValue?.theme || 'translucent';
    const showOnCreate = bindingValue?.showOnCreate || false;
    const interactive = bindingValue?.interactive || false;
    const animation = bindingValue?.animation || 'scale';
    const zIndex = bindingValue?.zIndex || 9999;
    const delay =
        bindingValue?.delay || bindingValue?.delay === 0
            ? bindingValue?.delay
            : 500;
    const delayArr = bindingValue?.delay || [];
    const skidding =
        bindingValue?.skidding || bindingValue?.skidding === 0
            ? bindingValue?.skidding
            : 0;
    const distance =
        bindingValue?.distance || bindingValue?.distance === 0
            ? bindingValue?.distance
            : 5;
    const hideAfter = bindingValue?.hideAfter || false;

    tippy(el, {
        appendTo,
        allowHTML,
        content,
        showOnCreate,
        arrow,
        theme,
        interactive,
        animation,
        placement,
        delay: delayArr?.length ? delayArr : [delay, null],
        offset: [skidding, distance],
        zIndex,
        onShow(instance) {
            if (hideAfter) {
                setTimeout(() => {
                    instance.hide();
                }, 5000);
            }
        },
    });
};

export default {
    install: (app) => {
        app.directive('vig-tooltip', {
            mounted(el, binding) {
                if (!binding.value) return;

                const content = binding.value?.content || binding.value || '';
                if (!content) {
                    return;
                }

                _appendTippyTooltip(el, content, binding.value);
            },
            updated(el, binding) {
                if (!binding.value) return;

                const content = binding.value?.content || binding.value || '';

                const showOnCreate = binding.value?.showOnCreate || false;
                if (showOnCreate) return;
                el._tippy && el._tippy.destroy();
                if (!content || binding.value?.isDestroyed) {
                    return;
                }
                _appendTippyTooltip(el, content, binding.value);
            },
            // updated(el) {
            //   if (!el) return;
            //   el.tippy?.update();
            // },
            unmounted(el) {
                el._tippy && el._tippy.destroy();
            },
        });
    },
};
