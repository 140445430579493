<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 679 678"
  >
    <circle style="fill: #008e8e; opacity: 0.2" cx="339" cy="40" r="40" />
    <circle style="fill: #008e8e; opacity: 0.2" cx="551" cy="128" r="40" />
    <circle style="fill: #008e8e; opacity: 0.2" cx="639" cy="337" r="40" />
    <circle style="fill: #008e8e; opacity: 0.5" cx="551" cy="551" r="40" />
    <circle style="fill: #008e8e; opacity: 0.7" cx="341" cy="638" r="40" />
    <circle style="fill: #008e8e; opacity: 0.7" cx="128" cy="551" r="40" />
    <circle style="fill: #008e8e" cx="40" cy="339" r="40" />
    <circle style="fill: #008e8e; opacity: 0.2" cx="128" cy="127" r="40" />
  </svg>
</template>
