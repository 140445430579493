<script setup lang="ts">
import { IWorkflowEntity } from '@/domain/entities/workflow/WorkflowEntity';
import UpdateWorkflowSteps from '@/ui/modules/workflow/workflow-detail/UpdateWorkflowSteps.vue';
import ConfirmUpdateWorkflowModal from '@/ui/modules/workflow/components/ConfirmUpdateWorkflowModal.vue';
import { IWorkflowStep } from '@/domain/entities/workflow/WorkflowStepEntity';

import workflowDetailComposables from '@/ui/modules/workflow/workflow-detail/workflow-detail.composables';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    workflowInfo: IWorkflowEntity;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'updateSuccess', newSteps: IWorkflowStep[], drawData: string): void;
}>();

const {
  workflowDetail,
  isLoading,
  isUpdating,
  onPreCheckToSave,
  isOpenConfirmModal,
  onClickSave,
} = workflowDetailComposables(props.workflowInfo);

const updateWorkflowStepsRef = ref<any>(null);
const onCloseModal = async () => {
  if (
    updateWorkflowStepsRef.value &&
    typeof updateWorkflowStepsRef.value?.canUndo &&
    updateWorkflowStepsRef.value?.canUndo()
  ) {
    settingGlobalModal({
      type: 'confirm',
      title: translate('DECIDE_PAYMENT_HAS_VOUCHER_CONFIRM_SELECT_TRIAL_TITLE'),
      content: translate('WORKFLOW_LABEL_UPDATE_CONFIRM_TO_CLOSE'),
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
      closeable: true,
    });

    const confirmed = await ask();

    if (confirmed) emit('onClose');
  } else emit('onClose');
};
</script>

<template>
  <SynModal
    z-index="z-50"
    is-hidden-footer
    disable-click-outside
    disable-esc-to-close
    disable-auto-focus
    disable-element-active
    style-body="p-0"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :container-style="`max-height: 1920px;
          max-width: 1920px;
          min-height:500px;
          min-width:600px;
          width: 100%;
          height: 100%;
          `"
    :background-style="`padding: 20px;`"
    @cancel="onCloseModal"
  >
    <template #header>
      <div class="flex justify-between">
        <span
          class="font-semibold"
          v-html="
            $t('WORKFLOW_LABEL_UPDATE_STEP_FOR_WORKFLOW', {
              workflowName: workflowDetail?.name || '',
            })
          "
        >
        </span>
      </div>
    </template>
    <template #body>
      <div
        class="relative"
        style="
          max-height: 1920px;
          max-width: 1920px;
          min-height: 500px;
          min-width: 600px;
          width: 100%;
          height: 100%;
        "
      >
        <div v-if="!isLoading" class="w-full h-full px-4 pb-4">
          <div class="w-full h-full flex flex-col">
            <UpdateWorkflowSteps
              ref="updateWorkflowStepsRef"
              v-model:workflow-steps="workflowDetail.steps"
              v-model:draw-data="workflowDetail.drawData"
              :workflow-info="workflowDetail"
              view-mode="EDIT"
            />
            <div v-if="workflowInfo?.id" class="flex justify-end pt-4">
              <div class="flex-center space-x-2">
                <AtomButton
                  v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
                  type="TEXT"
                  color="gray"
                  size="lg"
                  :label="$t('COMMON_LABEL_CANCEL')"
                  @click="$emit('onClose')"
                />
                <AtomButton
                  v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
                  :label="$t('COMMON_LABEL_SAVE')"
                  size="lg"
                  :is-loading="isUpdating"
                  @click="
                    onPreCheckToSave(
                      $emit(
                        'updateSuccess',
                        workflowDetail?.steps,
                        workflowDetail?.drawData
                      )
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SynModal>
  <ConfirmUpdateWorkflowModal
    v-if="isOpenConfirmModal"
    :total-task-in-use="workflowInfo?.totalTaskUsed || 0"
    :is-loading="isUpdating"
    view-mode="ADD"
    @cancel="isOpenConfirmModal = false"
    @confirm-no="
      onClickSave(
        false,
        $emit('updateSuccess', workflowDetail?.steps, workflowDetail?.drawData)
      )
    "
    @confirm-yes="
      onClickSave(
        true,
        $emit('updateSuccess', workflowDetail?.steps, workflowDetail?.drawData)
      )
    "
  />
</template>
