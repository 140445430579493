<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.507 28.034">
    <path
      id="Icon_awesome-angle-double-up"
      data-name="Icon awesome-angle-double-up"
      d="M12.445,17.979l9.563,9.563a1.681,1.681,0,0,1,0,2.384l-1.589,1.589a1.681,1.681,0,0,1-2.384,0L11.25,24.743,4.472,31.521a1.681,1.681,0,0,1-2.384,0l-1.6-1.589a1.681,1.681,0,0,1,0-2.384l9.563-9.562A1.683,1.683,0,0,1,12.445,17.979Zm-2.391-13.5L.492,14.041a1.681,1.681,0,0,0,0,2.384l1.589,1.589a1.681,1.681,0,0,0,2.384,0l6.778-6.778,6.778,6.778a1.681,1.681,0,0,0,2.384,0l1.589-1.589a1.681,1.681,0,0,0,0-2.384L12.431,4.479a1.672,1.672,0,0,0-2.377,0Z"
      transform="translate(0.004 -3.983)"
    />
  </svg>
</template>
