<script setup lang="ts">
import { StartWorkingConditionModel } from '@/application/models/setting/StartWorkingConditionModel';
import StartWorkingSettingsCommon from '@/ui/modules/settings/start-working/start-working-settings-form/StartWorkingSettingsCommon.vue';
import StartWorkingSettingsPosition from '@/ui/modules/settings/start-working/start-working-settings-form/StartWorkingSettingsPosition.vue';
import StartWorkingSettingsIP from '@/ui/modules/settings/start-working/start-working-settings-form/StartWorkingSettingsIP.vue';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import { onMounted, ref, watch } from 'vue';

const props = defineProps<{
  startWorkingCondition: StartWorkingConditionModel;
  disabled?: boolean;
  settings?: any;
}>();

defineEmits(['onSettingChange']);

const conditionEnabled = ref<boolean>();

watch(
  () => props.startWorkingCondition,
  () => _setValues()
);

onMounted(() => _setValues());

const _setValues = () => {
  conditionEnabled.value = props.startWorkingCondition?.enabled;
};
</script>

<template>
  <div>
    <!--ENABLE CONDITIONS-->
    <MenuSettingRowItem
      name-icon="Play"
      :icon-is-active="false"
      :title-menu="$t('SETTING_SW_CONDITION_ENABLE')"
      :sub-title="$t('SETTING_SW_CONDITION_ENABLE_DESC')"
      custom-class=""
      :class="{ 'opacity-70': disabled }"
    >
      <template #action>
        <AtomSwitch
          v-model="conditionEnabled"
          :disabled="disabled"
          @change="$emit('onSettingChange', { enabled: conditionEnabled })"
        />
      </template>
    </MenuSettingRowItem>

    <div class="">
      <StartWorkingSettingsCommon
        :start-working-condition="startWorkingCondition"
        :disabled="disabled"
        :settings="settings"
        @on-setting-change="$emit('onSettingChange', $event)"
      />
    </div>

    <div class="">
      <StartWorkingSettingsPosition
        :start-working-condition="startWorkingCondition"
        :disabled="disabled"
        @on-setting-change="$emit('onSettingChange', $event)"
      />
    </div>

    <div class="">
      <StartWorkingSettingsIP
        :start-working-condition="startWorkingCondition"
        :disabled="disabled"
        @on-setting-change="$emit('onSettingChange', $event)"
      />
    </div>
  </div>
</template>
