<template>
  <svg
    v-if="!isActive"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 49.94 49.94"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M48.856 22.731a3.56 3.56 0 0 0 .906-3.671 3.56 3.56 0 0 0-2.892-2.438l-12.092-1.757a1.58 1.58 0 0 1-1.19-.865L28.182 3.043a3.56 3.56 0 0 0-3.212-1.996 3.56 3.56 0 0 0-3.211 1.996L16.352 14c-.23.467-.676.79-1.191.865L3.069 16.623A3.557 3.557 0 0 0 .178 19.06a3.56 3.56 0 0 0 .906 3.671l8.749 8.528c.373.364.544.888.456 1.4L8.224 44.702a3.559 3.559 0 0 0 1.424 3.502 3.552 3.552 0 0 0 3.772.273l10.814-5.686a1.583 1.583 0 0 1 1.472 0l10.815 5.686a3.568 3.568 0 0 0 3.772-.273 3.559 3.559 0 0 0 1.424-3.502L39.651 32.66a1.582 1.582 0 0 1 .456-1.4l8.749-8.529zM37.681 32.998l2.065 12.042a1.553 1.553 0 0 1-.629 1.547 1.558 1.558 0 0 1-1.665.121l-10.815-5.687a3.588 3.588 0 0 0-3.334.001L12.49 46.708a1.56 1.56 0 0 1-1.666-.121 1.554 1.554 0 0 1-.629-1.547l2.065-12.042a3.581 3.581 0 0 0-1.03-3.17l-8.75-8.529a1.55 1.55 0 0 1-.4-1.621c.19-.586.667-.988 1.276-1.077l12.091-1.757a3.576 3.576 0 0 0 2.697-1.959l5.407-10.957a1.551 1.551 0 0 1 1.418-.881c.616 0 1.146.329 1.419.881l5.407 10.957a3.575 3.575 0 0 0 2.696 1.959l12.092 1.757a1.552 1.552 0 0 1 1.276 1.077c.19.585.041 1.191-.4 1.621l-8.749 8.528a3.58 3.58 0 0 0-1.029 3.171z"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 49.94 49.94"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M48.856 22.73a3.56 3.56 0 0 0 .906-3.671 3.56 3.56 0 0 0-2.892-2.438l-12.092-1.757a1.58 1.58 0 0 1-1.19-.865L28.182 3.043a3.56 3.56 0 0 0-3.212-1.996 3.56 3.56 0 0 0-3.211 1.996L16.352 14c-.23.467-.676.79-1.191.865L3.069 16.622A3.56 3.56 0 0 0 .177 19.06a3.56 3.56 0 0 0 .906 3.671l8.749 8.528c.373.364.544.888.456 1.4L8.224 44.701a3.506 3.506 0 0 0 .781 2.904c1.066 1.267 2.927 1.653 4.415.871l10.814-5.686a1.619 1.619 0 0 1 1.472 0l10.815 5.686a3.544 3.544 0 0 0 1.666.417c1.057 0 2.059-.47 2.748-1.288a3.505 3.505 0 0 0 .781-2.904l-2.065-12.042a1.582 1.582 0 0 1 .456-1.4l8.749-8.529z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
