import { getDetailTaskUrl, getTaskUrgencyText } from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import groupStore from '@/store/group';
import {
    DayoffDateSessionUI,
    DayoffStatusUI,
    DayoffTypeUI,
} from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import dayjs from 'dayjs';
import { genGroupConversationId } from '@/ui/plugins/firebases/firestore-database/constants';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { sortBy, uniq } from 'lodash';
import { formatDate } from '@/ui/plugins/filters';
import { CalendarMeetingType } from '@/ui/common/constants/calendar';

export const _prepareMsgTask = (newMessage, members) => {
    const _groupStore = groupStore();
    const msgText = newMessage?.text;
    const msgTask = newMessage?.task as any;
    const groupInfo = (_groupStore.allMyGroups || []).find(
        (group) => group?.id === msgTask?.groupId
    );

    const taskLink = getDetailTaskUrl(msgTask?.code, true);
    const taskCode = msgTask?.code?.split('-').pop();
    let taskCreator = '';
    if (msgTask?.creatorId && members[msgTask?.creatorId]) {
        taskCreator = translate('TASK_DETAIL_BY_MEM', {
            member: members[msgTask?.creatorId].name,
        });
    }
    let taskAssignee = '';
    if (msgTask?.assigneeId && members[msgTask?.assigneeId]) {
        taskAssignee = translate('TASK_DETAIL_TO_MEM', {
            member: members[msgTask?.assigneeId].name,
        });
    }
    let taskGroup = '';
    if (groupInfo) {
        taskGroup = translate('TASK_DETAIL_IN_GROUP', {
            group: groupInfo.name,
        });
    }

    let taskMsg = '<p><i>';
    taskMsg += `<a target="_blank" rel="noopener noreferrer" href="${taskLink}">#${taskCode}</a>`;
    taskMsg += `<br><span style="font-size: 1rem; font-weight: 400;">${msgTask?.name}</span>`;
    taskMsg += `<br><span style="font-size: 0.8rem;">${translate(
        getTaskUrgencyText(msgTask) || ''
    )}</span>`;
    taskMsg += `<br><span style="font-size: 0.8rem;">${taskCreator}</span>`;
    if (taskAssignee) {
        taskMsg += `<span style="font-size: 0.8rem;"> - ${taskAssignee}</span>`;
    }
    if (taskGroup) {
        taskMsg += `<span style="font-size: 0.8rem;"><br>${taskGroup}</span>`;
    }
    if (msgText) {
        taskMsg += `<br>--------------------------`;
    }
    taskMsg += `</i></p>`;
    const allMsg =
        taskMsg +
        '<b class="font-medium" style="font-weight: 400;">' +
        newMessage?.text +
        '</b>';

    return {
        task: taskMsg,
        all: allMsg,
    };
};

export const _prepareMsgDayOff = (message, members) => {
    const msgDayoff = message?.dayoff;
    const msgText = message?.text;
    let dayoffCreator = '';
    if (msgDayoff?.userId && members[msgDayoff?.userId]) {
        dayoffCreator = translate('TASK_DETAIL_BY_MEM', {
            member: members[msgDayoff?.userId].name,
        });
    }
    const typeDayoff =
        translate(DayoffTypeUI[msgDayoff?.type]?.textCode) ||
        DayoffTypeUI[msgDayoff?.type]?.textDefault;
    const statusDayoff =
        translate(DayoffStatusUI[msgDayoff?.status]?.textCode) ||
        DayoffStatusUI[msgDayoff?.status]?.textDefault;

    const startDate = dayjs(msgDayoff?.startDate).format('MMM DD, YYYY');
    const endDate = dayjs(msgDayoff?.endDate).format('MMM DD, YYYY');
    const usedDay = msgDayoff?.usedDay;

    const dayoffSession =
        translate(
            DayoffDateSessionUI[msgDayoff?.dateOffs[0]?.daySession]?.textCode
        ) ||
        DayoffDateSessionUI[msgDayoff?.dateOffs[0]?.daySession]?.textDefault;

    let dayoffMsg = '<p><i>';
    dayoffMsg += `<span style="font-size: 1rem; font-weight: 400;">${translate(
        'DAYOFF_REQUEST_TITLE'
    )}</span>`;
    dayoffMsg += `<br><span style="font-size: 0.8rem;">${dayoffCreator}</span>`;
    dayoffMsg += `<br><span style="font-size: 0.8rem;">${typeDayoff} - ${statusDayoff}</span>`;
    dayoffMsg += `<br><span style="font-size: 0.8rem;">${startDate}</span>`;
    if (startDate !== endDate) {
        dayoffMsg += `- <span style="font-size: 0.8rem;">${endDate} </span>`;
    }
    if (msgDayoff?.dateOffs?.length > 1) {
        dayoffMsg += `<span style="font-size: 0.8rem;">(${usedDay} d)</span>`;
    } else if (msgDayoff?.dateOffs?.length > 0) {
        dayoffMsg += `<span style="font-size: 0.8rem;">${dayoffSession}</span>`;
    }

    if (msgDayoff?.requestNote) {
        dayoffMsg += `<br><span style="font-size: 0.8rem;">${
            translate('COMMON_LABEL_NOTE') + ': ' + msgDayoff?.requestNote
        }</span>`;
    }

    if (msgText) {
        dayoffMsg += `<br>--------------------------`;
        dayoffMsg += `<br><span">${msgDayoff?.requestNote}</span>`;
    }

    dayoffMsg += `</i></p>`;
    const allMsg =
        dayoffMsg +
        '<b class="font-medium" style="font-weight: 400;">' +
        message?.text +
        '</b>';
    return {
        dayoff: dayoffMsg,
        all: allMsg,
    };
};

export const _prepareMsgEvent = (message) => {
    const msgEventData = message?.eventData;
    const msgText = message?.text;

    const date = dayjs(msgEventData?.startDate).format('MMM DD, YYYY');
    const startTime = dayjs(msgEventData?.startDate).format('hh:mm a');
    const endTime = dayjs(msgEventData?.endDate).format('hh:mm a');

    let eventMsg = '<p><i>';
    eventMsg +=
        msgEventData.metadata?.meetingType === CalendarMeetingType.OnlineMeeting
            ? `<span style="font-size: 1rem; font-weight: 400;">${translate(
                  'CALENDAR_LABEL_ONLINE_MEETING'
              )}</span>`
            : `<span style="font-size: 1rem; font-weight: 400;">${translate(
                  'CALENDAR_FILTER_TITLE_TYPE_EVENT'
              )}</span>`;
    eventMsg += `<br><span style="font-size: 0.8rem;">${msgEventData?.title}</span>`;
    eventMsg += `<br><span style="font-size: 0.8rem;">${date}</span>`;
    eventMsg += `<span style="font-size: 0.8rem; margin-left: 5px">(${startTime} - ${endTime})</span>`;

    const allMsg =
        eventMsg +
        '<b class="font-medium" style="font-weight: 400;">' +
        msgText +
        '</b>';

    return {
        eventData: eventMsg,
        all: allMsg,
    };
};

export const _genFileName = (type, ext, conversationId, members, isGroup) => {
    let chatName;
    const _groupStore = groupStore();
    if (isGroup) {
        const allMyGroups = _groupStore.allMyGroups;
        const groupInfo = (allMyGroups || []).find(
            (group) => genGroupConversationId(group?.id) === conversationId
        );

        chatName = ignoreUnicode(groupInfo?.name, false);
    } else {
        let memberIds = conversationId?.split('-');
        memberIds = uniq(memberIds);

        let memberName = memberIds.map((memberId) =>
            members[memberId]?.name?.split(' ').pop()
        );
        memberName = sortBy(memberName);
        chatName = ignoreUnicode(memberName.join('-'), false);
    }

    const time = formatDate(new Date(), 'YYYY-MM-DD hh.mm A');

    return `${type}-Chat ${chatName} ${time}.${ext}`;
};

export const _processMsgTextBlankLine = (text) => {
    let newText = text?.replace('<br>&nbsp;</p>', '</p>');

    while (newText?.endsWith('<br></p>')) {
        newText = newText?.replace('<br></p>', '</p>');
    }

    while (newText?.startsWith('<p><br>')) {
        newText = newText?.replace('<p><br>', '<p>');
    }

    return newText;
};
