<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { ImageDefault } from '@/ui/components/photo-upload/imageDefault.js';

const props = defineProps<{
  isColumnLayout: boolean;
  isShowNotification: boolean;
  isHiddenDetail: boolean;
}>();

defineEmits(['onInvite']);

const isColumn = computed(() => {
  return props.isColumnLayout;
});
let intervalFunction;
const keyStatus = ['online', 'absent', 'offline'];
const status = ref('online');

const keyAvatar = ['BOYS', 'GIRLS', 'ANIMALS'];
const avatarUser = ref(ImageDefault('USER')['BOYS'].objects[0]);
const isActiveFavious = ref(false);

onMounted(() => {
  clearInterval(intervalFunction);
  runRamdom();
});

const getClassByWorkInfo = (status) => {
  if (status == 'online') return 'bg-green-500';
  if (status == 'absent') return 'bg-orange-400';
  if (status == 'offline') return 'bg-gray-400';
  return '';
};

const ramdomUser = () => {
  let numberRamdom = Math.floor(Math.random() * 5) + 1;
  let numberKeyRamdom = Math.floor(Math.random() * 3);

  isActiveFavious.value = !isActiveFavious.value;
  status.value = keyStatus[numberKeyRamdom];
  avatarUser.value =
    ImageDefault('USER')[keyAvatar[numberKeyRamdom]].objects[numberRamdom];
};

const runRamdom = () => {
  intervalFunction = setInterval(() => {
    ramdomUser();
  }, 2000);
};
</script>

<template>
  <div
    class="w-full h-full px-4 pb-8 relative hover-to-show__parent"
    :style="`height:${isColumn ? '40rem' : '20rem'};`"
  >
    <div
      class="
        w-6
        h-6
        top-1
        left-1
        rounded-full
        absolute
        flex-center
        font-semibold
        text-white
      "
      :class="getClassByWorkInfo(status) || 'bg-green-500'"
    ></div>
    <div
      class="
        w-full
        h-full
        rounded-xl
        border-dashed border-2 border-current-200
        flex
        divide-x-2 divide-dashed divide-current-200
      "
    >
      <div
        class="h-full flex flex-col justify-between items-center pb-2 relative"
        :class="isHiddenDetail ? 'w-full' : 'w-1/3'"
      >
        <div
          class="
            bg-white
            flex-center
            rounded-full
            absolute
            right-0
            top-0
            m-1
            w-6
            h-6
          "
        >
          <SynIcon
            name="heart"
            custom-class="w-4 h-4 fill-current text-current"
            :is-active="isActiveFavious || false"
          />
        </div>
        <div
          class="
            flex-1 flex
            items-center
            flex-col
            hover-to-show__parent
            cursor-pointer
            pt-6
          "
        >
          <div class="relative w-16 h-16">
            <syn-avatar
              class="cursor-pointer"
              custom-class="w-16 h-16"
              :src="avatarUser || ImageDefault('USER')['BOYS'].objects[0]"
            ></syn-avatar>
          </div>
          <SynButton
            v-if="isHiddenDetail"
            class="
              bg-orange-500
              text-white
              fill-white
              text-xs
              border-none
              hover:bg-orange-600
              mt-8
            "
            custom-class="text-xs rounded"
            name-icon="plus"
            type-fill
            :label="$t('USERMANAGEMENT_LABEL_INVITE_USER')"
            @click="$emit('onInvite')"
          />

          <div v-if="isHiddenDetail" class="flex-1 h-full p-2">
            <div class="rounded-lg h-full flex-center flex-col space-y-4">
              <div
                v-if="isShowNotification"
                class="text-center text-sm px-2 pt-4"
              >
                <span class="text-sm">
                  {{ $t('VIRTUALFRAME_TITLE_DIREC_USER_MANAGEMENT') }}
                </span>
                <br />
                <router-link :to="{ name: 'UserManagement' }">
                  <span
                    class="
                      cursor-pointer
                      text-current-700
                      font-medium
                      hover:text-current-400
                    "
                  >
                    {{ $t('COMMON_MODULE_USER_MANAGEMENT') }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isHiddenDetail" class="flex-1 h-full p-2">
        <div class="rounded-lg h-full flex-center flex-col space-y-4">
          <span class="px-2 text-center text-current-600">{{
            $t('SETUP_TEAM_INVITE_DES')
          }}</span>
          <SynButton
            class="
              bg-orange-500
              text-white
              fill-white
              border-none
              hover:bg-orange-600
            "
            name-icon="plus"
            type-fill
            :label="$t('USERMANAGEMENT_LABEL_INVITE_USER')"
            @click="$emit('onInvite')"
          />
          <div v-if="isShowNotification" class="text-center text-sm px-2 pt-4">
            <span>
              {{ $t('VIRTUALFRAME_TITLE_DIREC_USER_MANAGEMENT') }}
            </span>
            <br />
            <router-link :to="{ name: 'UserManagement' }">
              <span
                class="
                  cursor-pointer
                  text-current-700
                  font-medium
                  hover:text-current-400
                "
              >
                {{ $t('COMMON_MODULE_USER_MANAGEMENT') }}
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
