<template>
  <div class="relative">
    <div
      v-click-outside="
        () => {
          clickOutsideClose ? (dropdownOpen = false) : (dropdownOpen = true);
          $emit('openDropdown', false);
        }
      "
      @click.stop="onOpenDropdown"
      @mouseover="behavior == 'hover' && onMouseOver(true)"
      @mouseleave="behavior == 'hover' && onMouseOver(false)"
    >
      <slot></slot>
    </div>
    <div
      v-show="dropdownOpen"
      :id="getId"
      class="absolute -top-2 left-auto bottom-auto -right-1 rounded-md"
      :style="`will-change: transform;transform: translate3d(0px, ${positionTop}px, 0px);z-index: ${zIndex};`"
      @click.stop="dropdownOpen = clickFocus"
    >
      <div class="w-full relative h-5">
        <svg
          class="transform rotate-180 h-5 w-5 absolute right-0"
          style="bottom: -2.5px"
          :class="positionFocus"
          viewBox="0 0 255 255"
          xml:space="preserve"
        >
          <polygon
            style="box-shadow: 0px 0px 10px #f2f2f2"
            stroke="gray"
            class="fill-white stroke-2"
            points="0,0 127.5,127.5 255,0"
          />
        </svg>
      </div>

      <div class="rounded-md shadow-xl bg-white border-2 border-gray">
        <slot name="dropdown-list" class="rounded-md"></slot>
      </div>
    </div>
  </div>
  <!-- <teleport to="body" v-if="behavior == 'click'">
    <div
      v-if="dropdownOpen"
      @click="dropdownOpen = false"
      class="fixed inset-0 h-full w-full"
      :class="zIndex"
    ></div>
  </teleport> -->
</template>
<script>
import { ref } from 'vue';
export default {
  props: {
    behavior: {
      type: String,
      default: 'click',
    },
    zIndex: {
      type: Number,
      default: 1000,
    },
    clickOutsideClose: {
      type: Boolean,
      default: true,
    },
    clickFocus: {
      type: Boolean,
      default: false,
    },
    positionFocus: {
      type: String,
      default: 'right-4',
    },
  },
  emits: ['openDropdown'],
  setup(props, { emit }) {
    const dropdownOpen = ref(false);
    const onMouseOver = (hover) => {
      dropdownOpen.value = hover;
      emit('openDropdown', hover);
    };
    const positionTop = ref(0);
    const getId = ref(null);
    const onOpenDropdown = () => {
      getId.value = Date.now();
      dropdownOpen.value = !dropdownOpen.value;
      emit('openDropdown', dropdownOpen.value);
      // const positionOffset = event?.toElement?.offsetTop;
      // const dropdownMenu = document.getElementById(getId.value);
      positionTop.value = 30;
    };
    return {
      dropdownOpen,
      onMouseOver,
      onOpenDropdown,
      positionTop,
      getId,
    };
  },
};
</script>
