import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import EmbedGroupServices from '@/embed/services/group-services';
import { arrayOrderBy, cloneDeep } from '@/ui/hooks/commonFunction';

export default defineStore({
    id: storeId.embedGroup,
    state: () => ({
        _groupService: EmbedGroupServices.getInstance(),
        myGroups: [] as any[],
    }),
    getters: {
        allMyGroups(): any[] {
            return arrayOrderBy(this.myGroups || [], ['isFavorite'], ['asc']);
        },
    },
    actions: {
        async fetchMyGroups(): Promise<any> {
            try {
                const res = await this._groupService.getAllGroupByUser();

                const groups = res?.result;

                if (!groups || groups.length == 0) return;

                this.myGroups = groups.map((e) => {
                    return {
                        ...e,
                        avatar: e.avatarThumnailUrl,
                        groupUsers: e.groupUsers.map((g) => {
                            return {
                                ...g,
                                name: g.lastName + ' ' + g.firstName,
                                avatar: g.avatarThumbnailUrl,
                            };
                        }),
                    };
                });
            } catch (error) {
                return Promise.reject(error);
            }
        },
        getGroupDomainByGroupId(groupId) {
            const index = this.myGroups?.findIndex(
                (o: any) => o.id === groupId
            );

            return index > -1
                ? cloneDeep(this.myGroups[index].groupDomains)
                : [];
        },
    },
});
