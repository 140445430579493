import chatStore from '@/store/chat';
import myProfileStore from '@/store/auth/my-profile';
import chatExternalStore from '@/store/chat/chat-external';
import chatSupportingStore from '@/store/chat/chat-supporting';

export default function sendMessagesComposables(organizationId) {
    const isVerifyingCreateConversations: { [id: string]: boolean } = {};
    const existedConversations: { [id: string]: boolean } = {};

    const _chatStore = chatStore();

    const onSendMessage = (converId, message) => {
        const userId = myProfileStore().myProfile?.id;

        // External chat
        if (_chatStore.isExternalChat(converId)) {
            return chatExternalStore().addMessage(converId, message);
        }

        // External chat
        if (_chatStore.isSupportingChat(converId)) {
            return chatSupportingStore().addMessage(converId, message);
        }

        if (!organizationId.value || !converId || !userId) return;

        _verifyToCreateConversation(organizationId.value, converId);

        _chatStore.processMessageFilesName(converId, message);

        return _chatStore.addMessage(
            organizationId.value,
            converId,
            userId,
            message
        );
    };

    const _verifyToCreateConversation = async (orgId, converId) => {
        if (
            existedConversations[converId] ||
            isVerifyingCreateConversations[converId]
        ) {
            return;
        }

        isVerifyingCreateConversations[converId] = true;

        try {
            existedConversations[converId] =
                await _chatStore.checkConversationCreated(orgId, converId);
        } catch (e) {
            console.log(e);
        }

        isVerifyingCreateConversations[converId] = false;
    };

    return {
        onSendMessage,
    };
}
