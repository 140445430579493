import { defineStore } from 'pinia';
import PlansService from '@/application/services/plans/PlansService';
import storeId from '@/base/store/storeId';
import paymentStore from '@/store/payment';
import { IPlanDetail } from '@/application/interfaces/PaymentInterfaces';
import {
    EPlanCapacityKeys,
    ExceededPlanAction,
    ExceededPlanNotify,
} from '@/domain/enums/PlanEnums';
import {
    ELicenseType,
    EPlanState,
    IOrgCurrentPlan,
    IPlanCapacityByKey,
} from '@/application/types/plan.types';
import permissionStore from '@/store/permission';
import { mapKeysToCamelCase } from '@/ui/hooks/commonFunction';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { getUiByPlanState } from '@/application/constants/plan.const';
import myProfileStore from '@/store/auth/my-profile';
import { AdminType } from '@/ui/common/constants/constant';
import userStore from '@/store/user';
import { getStateCodeByRemainDays } from '@/ui/helpers/payment/payment-ui';
import { EPlanId } from '@/application/constants/plan.const';
import OrganizationPlan from '@/domain/entities/payment/OrganizationPlan';
import organizationStore from '@/store/organization';
import { EPaymentPurpose } from '@/application/types/payment.types';

export const LIFETIME_ORGANIZATION_IDS = [7490];

export default defineStore({
    id: storeId.PLANS_SETTING,
    state: () => ({
        _plansService: PlansService.getInstance() as PlansService,
        currentOrgPlan: {} as IOrgCurrentPlan,
        allPlansDetail: [] as any[],
        allPlanByIds: {} as any,
        isLoadingCurrentPlan: false as boolean,
        isLoadingAllPlans: false as boolean,
        organizationUsageExceed: false as boolean,
        orgLifeTime: 7490 as number,
        exceededYourPlanLimitation: false as boolean,
        planStatusInfo: {
            showNotification: false,
            notificationType: null,
            action: null,
            plannedEndDate: null,
            nextBillingDate: null,
        } as {
            showNotification: boolean;
            notificationType: ExceededPlanNotify | null;
            action: ExceededPlanAction | null;
            plannedEndDate?: number | null;
            nextBillingDate?: number | null;
        },
        planCapacityByKeys: {} as IPlanCapacityByKey,
        allPlanComparativeCriteria: [] as any[],
        capacityByPlanIds: {
            fileTaskHistory: 0,
            messageHistory: 0,
            totalCall: 0,
            totalGroup: 0,
            totalMember: 0,
            totalMessage: 0,
            totalNote: 0,
            totalStorage: 0,
            totalStorageByUser: 0,
            totalTask: 0,
            totalVisitor: 0,
        } as {
            fileTaskHistory: number;
            messageHistory: number;
            totalCall: number;
            totalGroup: number;
            totalMember: number;
            totalMessage: number;
            totalNote: number;
            totalStorage: number;
            totalStorageByUser: number;
            totalTask: number;
            totalVisitor: number;
        },
    }),
    getters: {
        isPersonalModel(state): boolean {
            return state?.currentOrgPlan?.planId == EPlanId.PERSONAL;
        },
    },
    actions: {
        initData() {
            const _paymentStore = paymentStore();
            const promise = [
                this.getCurrentOrgPlans(),
                this.getAllPlansDetail(),
                _paymentStore.getPaymentConfigs(),
            ];

            if (permissionStore().organizationModel !== 'PERSONAL')
                promise.push(this.checkOrganizationUsageExceed());

            return Promise.all(promise);
        },
        async getCurrentOrgPlans(isHiddenLoading?) {
            try {
                if (!isHiddenLoading) this.isLoadingCurrentPlan = true;

                const res = await this._plansService.getCurrentOrgPlan();
                const result: IOrgCurrentPlan = res.result;
                this.currentOrgPlan = {
                    ...result,
                    subStateCode: getStateCodeByRemainDays(
                        result?.remainDay,
                        result?.lastDateAllowedToUse
                    ),
                    isLifeTime:
                        LIFETIME_ORGANIZATION_IDS?.some(
                            (orgId) => orgId == result?.organizationId
                        ) || result?.license === ELicenseType.LIFETIME,
                };

                this.isLoadingCurrentPlan = false;
                return res['result'];
            } catch (e) {
                this.isLoadingCurrentPlan = false;
            }
        },
        async checkOrganizationUsageExceed() {
            try {
                const res =
                    await this._plansService.checkOrganizationUsageExceed();
                const result = res?.result;
                if (!result) return;
                this.organizationUsageExceed = result?.isExceeded;
                this.exceededYourPlanLimitation =
                    result?.exceededYourPlanLimitation;
                this.planStatusInfo = {
                    showNotification: result?.showNotification,
                    notificationType: result?.notificationType,
                    action: result?.action,
                    plannedEndDate: result?.plannedEndDate,
                    nextBillingDate: result?.nextBillingDate,
                };
            } catch (e) {
                console.log('🚀 Hyrin ~ getOrgPlanCapacities ~ e', e);
            }
        },
        async getAllPlanCapacities() {
            try {
                const res = await this._plansService.getOrgPlanCapacity([
                    EPlanCapacityKeys.ALL,
                ]);
                const result: any[] = res.result || [];
                this.planCapacityByKeys = mapKeysToCamelCase(
                    result?.reduce((oldValue, currentValue) => {
                        return {
                            ...oldValue,
                            [currentValue?.key]: currentValue,
                        };
                    }, {})
                ) as IPlanCapacityByKey;
            } catch (e) {
                console.log('🚀 Hyrin ~ getOrgPlanCapacities ~ e', e);
                return [];
            }
        },
        async getOrgPlanCapacities(filters?: EPlanCapacityKeys[]) {
            /* List keys:
                          ALL
                          EXCEEDED_ONLY
                          TotalTask
                          TotalStorageByUser
                          TotalStorage
                          TotalMember
                          TotalGroup
                          TotalMessage
                          TotalNote
                          MessageHistory
                          FileAndTaskHistory
                          TotalCall
                        */
            try {
                const res = await this._plansService.getOrgPlanCapacity(
                    filters || [EPlanCapacityKeys.ALL]
                );
                return res.result;
            } catch (e) {
                console.log('🚀 Hyrin ~ getOrgPlanCapacities ~ e', e);
                return [];
            }
        },
        async getAllPlansDetail() {
            try {
                this.isLoadingAllPlans = true;
                const res = await this._plansService.getAllPlansDetail();
                const capacityRes =
                    await this._plansService.getAllPlansCapacity();

                this.capacityByPlanIds = capacityRes?.result.reduce(
                    (oldValue, currentValue: any) => {
                        return {
                            ...oldValue,
                            [currentValue.planId]: {
                                ...currentValue?.planCapacity,
                            },
                        };
                    },
                    {}
                );
                this.allPlanByIds = res?.result.reduce(
                    (oldValue, currentValue: IPlanDetail) => {
                        return {
                            ...oldValue,
                            [currentValue.id]: {
                                ...currentValue,
                                level:
                                    currentValue?.id == EPlanId.FREE ||
                                    currentValue?.id == EPlanId.BASIC
                                        ? 1
                                        : currentValue?.id == 'PRO'
                                        ? 2
                                        : 3,
                                capacities:
                                    this.capacityByPlanIds[currentValue.id],
                            },
                        };
                    },
                    {}
                );
                this.isLoadingAllPlans = false;
                return res['result'];
            } catch (e) {
                this.isLoadingAllPlans = false;
                console.log(e);
            }
        },
        async getAllPlanComparativeCriteria() {
            let res: any = [];
            try {
                res = await this._plansService.getAllPlanComparativeCriteria();
                this.allPlanComparativeCriteria =
                    res['result'].map((o) => {
                        return {
                            ...o,
                            translations: o.translations?.reduce(
                                (a, v) => ({ ...a, [v.lang]: v }),
                                {}
                            ),
                            criteria: o?.criteria?.map((e) => {
                                return {
                                    ...e,
                                    criteriaTranslations:
                                        e.criteriaTranslations?.reduce(
                                            (a, v) => ({
                                                ...a,
                                                [v.lang]: v,
                                            }),
                                            {}
                                        ),
                                };
                            }),
                        };
                    }) || [];
            } catch (e) {
                this.allPlanComparativeCriteria = [];
            }
        },

        checkPremiumMemberCapacity: async function (
            additional: {
                owner: number;
                admin: number;
                manager: number;
                user: number;
                visitor: number;
            },
            isPaid
        ): Promise<boolean> {
            // TRIAL : open all
            // if (this.currentOrgPlan?.stateC0de === EPlanState.TRIAL)
            //     return true;

            const currentLoggedUser = myProfileStore().myProfile;
            if (additional.owner < 0) {
                if (
                    parseInt(currentLoggedUser?.adminType?.toString()) !==
                    AdminType.Owner
                ) {
                    settingGlobalModal({
                        type: 'notification',
                        title: '',
                        content: translate(
                            'USER_YOU_DO_NOT_HAVE_AUTHORITY_TO_UPDATE_THIS_USER'
                        ),
                        closeable: true,
                        cancelLabel: translate('COMMON_LABEL_CLOSE'),
                        confirmable: false,
                    });

                    await ask();
                    return false;
                }

                const totalOwner = userStore().listAllUser?.filter(
                    (user) =>
                        parseInt(user?.adminType?.toString()) ==
                            AdminType.Owner && user?.active
                )?.length;

                if (totalOwner + additional.owner < 1) {
                    settingGlobalModal({
                        type: 'notification',
                        title: '',
                        content: translate(
                            'USER_MANAGEMENT_CANNOT_DEACTIVE_OWNER_BECAUSE_ORGANIZATION_MUST_HAS_AT_LEST_ONE_OWNER'
                        ),
                        confirmLabel: translate('COMMON_LABEL_CLOSE'),
                        closeable: true,
                    });
                    await ask();
                    return false;
                }
            }

            const totalPremiumMembers =
                this.planCapacityByKeys?.totalMember?.limitation;
            const totalPremiumMembersUsage =
                this.planCapacityByKeys?.totalMember?.usage;

            // 1. Exceed to total premium members
            if (this.currentOrgPlan?.stateCode !== EPlanState.TRIAL) {
                const additionalPremiumMember =
                    additional?.owner +
                    additional?.admin +
                    additional?.manager +
                    additional?.user;

                const totalExceedMembers =
                    totalPremiumMembersUsage +
                    (additionalPremiumMember || 0) -
                    totalPremiumMembers;

                if (totalExceedMembers > 0) {
                    settingGlobalModal({
                        type: 'confirm',
                        title: '',
                        content: translate(
                            'PLAN_EXCEED_INVITE_TOTAL_MEMBER_FOR_PLAN',
                            {
                                planId: this.currentOrgPlan?.planId,
                                note: '',
                            }
                        ),
                        confirmable: !!isPaid,
                        confirmLabel:
                            translate('PAYMENT_BUY_MORE_USER') ||
                            'Buy more users',
                        closeable: true,
                    });

                    const confirmed = await ask();
                    if (confirmed) {
                        // go to buy more page
                        const planId = this.currentOrgPlan?.planId;
                        if (!planId) return false;

                        await paymentStore().createPaymentToken({
                            planId,
                            purpose: EPaymentPurpose.GET_MORE,
                            period: null,
                            totalUsers: null,
                        });
                    }

                    return false;
                }
            }

            // 2. Exceed total visitor
            const { totalExceedVisitorMembers, limitVisitors } =
                this.checkLimitVisitor(additional.visitor);

            if (totalExceedVisitorMembers > 0) {
                await this.showModalLimitVisitor(limitVisitors);
                return false;
            }

            return true;
        },

        async checkCurrentPaymentStageForUpdateUser(additional?: {
            owner: number;
            admin: number;
            manager: number;
            user: number;
            visitor: number;
        }): Promise<boolean> {
            const totalPremiumMembersUsage =
                this.planCapacityByKeys?.totalMember?.usage;
            const uiByPlanState = getUiByPlanState(
                this.currentOrgPlan?.planId,
                this.currentOrgPlan?.stateCode
            );

            switch (this.currentOrgPlan?.stateCode) {
                case EPlanState.TRIAL:
                case EPlanState.INIT: {
                    return this.checkPremiumMemberCapacity(
                        additional || {
                            owner: 0,
                            admin: 0,
                            manager: 0,
                            user: 0,
                            visitor: 0,
                        },
                        false
                    );
                }
                case EPlanState.OVERDUE_TRIAL: {
                    if ((additional?.visitor || 0) > 0) {
                        const { totalExceedVisitorMembers, limitVisitors } =
                            this.checkLimitVisitor(additional?.visitor || 0);

                        if (
                            totalExceedVisitorMembers <= 0 &&
                            (additional?.owner !== 0 ||
                                additional?.admin !== 0 ||
                                additional?.manager !== 0 ||
                                additional?.user !== 0)
                        ) {
                            return true;
                        }

                        if (
                            totalExceedVisitorMembers > 0 &&
                            (additional?.owner !== 0 ||
                                additional?.admin !== 0 ||
                                additional?.manager !== 0 ||
                                additional?.user !== 0)
                        ) {
                            await this.showModalLimitVisitor(limitVisitors);
                            return false;
                        }
                    }

                    settingGlobalModal({
                        type: 'notification',
                        title: '',
                        content: translate(
                            uiByPlanState.OVERDUE_TRIAL.DEFAULT.message.title,
                            {
                                planId: this.currentOrgPlan?.planId,
                            }
                        ),
                        closeable: true,
                        cancelLabel: translate('COMMON_LABEL_CLOSE'),
                        confirmable: true,
                        confirmLabel: translate('PAYMENT_LABEL_PURCHASE'),
                    });

                    const isConfirm = await ask();
                    if (isConfirm) {
                        // await paymentStore().checkAndMakeNewPayment({
                        //     planId: this.currentOrgPlan?.planId,
                        //     purpose: EPaymentPurpose.PURCHASE,
                        //     period: 6,
                        //     totalUsers: totalPremiumMembersUsage,
                        // });
                        paymentStore().isCreatingPaymentToken = true;

                        await new OrganizationPlan(
                            this.currentOrgPlan,
                            organizationStore().organizationInfo,
                            paymentStore().paymentConfigs,
                            this.planCapacityByKeys,
                            this.capacityByPlanIds
                        ).createPayment({
                            planId: this.currentOrgPlan?.planId,
                            purpose: EPaymentPurpose.PURCHASE,
                            period: 6,
                            totalUsers: totalPremiumMembersUsage,
                        });

                        paymentStore().isCreatingPaymentToken = false;
                    }

                    return false;
                }

                case EPlanState.OVERDUE_PAID: {
                    if ((additional?.visitor || 0) > 0) {
                        const { totalExceedVisitorMembers, limitVisitors } =
                            this.checkLimitVisitor(additional?.visitor || 0);

                        if (
                            totalExceedVisitorMembers <= 0 &&
                            (additional?.owner !== 0 ||
                                additional?.admin !== 0 ||
                                additional?.manager !== 0 ||
                                additional?.user !== 0)
                        ) {
                            return true;
                        }

                        if (
                            totalExceedVisitorMembers > 0 &&
                            (additional?.owner !== 0 ||
                                additional?.admin !== 0 ||
                                additional?.manager !== 0 ||
                                additional?.user !== 0)
                        ) {
                            await this.showModalLimitVisitor(limitVisitors);
                            return false;
                        }
                    }

                    settingGlobalModal({
                        type: 'notification',
                        title: '',
                        content: translate(
                            uiByPlanState.OVERDUE_PAID.DEFAULT.message.title,
                            {
                                planId: this.currentOrgPlan?.planId,
                            }
                        ),
                        closeable: true,
                        cancelLabel: translate('COMMON_LABEL_CLOSE'),
                        confirmable: true,
                        confirmLabel: translate('PAYMENT_LABEL_PURCHASE'),
                    });

                    const isConfirm = await ask();
                    if (isConfirm) {
                        paymentStore().isCreatingPaymentToken = true;

                        await new OrganizationPlan(
                            this.currentOrgPlan,
                            organizationStore().organizationInfo,
                            paymentStore().paymentConfigs,
                            this.planCapacityByKeys,
                            this.capacityByPlanIds
                        ).createPayment({
                            planId: this.currentOrgPlan?.planId,
                            purpose: EPaymentPurpose.PURCHASE,
                            period: 6,
                            totalUsers: totalPremiumMembersUsage,
                        });

                        paymentStore().isCreatingPaymentToken = false;
                    }

                    return false;
                }

                case EPlanState.PAID:
                case EPlanState.EVALUATION:
                    return this.checkPremiumMemberCapacity(
                        additional || {
                            owner: 0,
                            admin: 0,
                            manager: 0,
                            user: 0,
                            visitor: 0,
                        },
                        this.currentOrgPlan?.stateCode == EPlanState.PAID
                    );
                default:
                    return true;
            }
        },

        checkLimitVisitor(visitor: number) {
            const totalVisitors = this.planCapacityByKeys?.totalVisitor?.usage;
            const limitVisitors =
                this.planCapacityByKeys?.totalVisitor?.limitation;

            const totalExceedVisitorMembers =
                totalVisitors + visitor - limitVisitors;

            return { totalExceedVisitorMembers, limitVisitors };
        },

        async showModalLimitVisitor(limitVisitors: number) {
            settingGlobalModal({
                type: 'confirm',
                title: '',
                content: translate(
                    'PLAN_EXCEED_INVITE_TOTAL_VISITOR_FOR_PLAN',
                    {
                        member: limitVisitors,
                    }
                ),
                confirmable: false,
                closeable: true,
                cancelLabel: translate('COMMON_LABEL_CLOSE'),
            });
            await ask();
        },
    },
});
