<script setup lang="ts">
import { computed, ref } from 'vue';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import { translate } from '@/ui/plugins/i18n/myi18n';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import { isValidEmail, isValidPhoneNumber } from '@/ui/hooks/commonFunction';
import { CountryCode } from 'libphonenumber-js';
import EnterLicenseCode from '@/ui/modules/settings/payments/license/EnterLicenseCode.vue';
import paymentStore from '@/store/payment';
import {
  ILicensePackage,
  IRegisterLicensePayload,
} from '@/application/types/payment.types';
import registerStore from '@/store/auth/register';

const _paymentStore = paymentStore();
const _registerStore = registerStore();

const organizationModels = [
  {
    id: 1,
    code: 'COMPANY',
    index: 1,
    active: true,
    name: translate('COMMON_LABEL_COMPANY'),
    description: null,
  },
  {
    id: 4,
    code: 'PERSONAL',
    index: 4,
    active: true,
    name: translate('COMMON_LABEL_PERSONAL'),
    description: null,
  },
];

const packageList = computed<ILicensePackage[] | null>(
  () => _paymentStore.allLicensePackages
);
const initComponent = () => {
  _paymentStore.getAllLicensePackage();
};

const {
  currentStep,
  stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    CHOOSE_PACKAGE: {
      key: 'CHOOSE_PACKAGE',
      previous: '',
      previousAction: () => {},
      next: 'ENTER_INFORMATION',
      nextAction: () => {},
      label: '',
      buttonBack: '',
      buttonNext: '',
      title: translate('BUY_LICENSE_TITLE_STEP_1'),
      subTitle: translate('BUY_LICENSE_SUB_TITLE_STEP_1'),
    },
    ENTER_INFORMATION: {
      key: 'ENTER_INFORMATION',
      previous: 'CHOOSE_PACKAGE',
      previousAction: () => {},
      next: 'ENTER_OTP',
      nextAction: () => {},
      label: '',
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_NEXT',
      title: translate('BUY_LICENSE_TITLE_STEP_1'),
      subTitle: translate('BUY_LICENSE_SUB_TITLE_STEP_2'),
    },
    ENTER_OTP: {
      key: 'ENTER_OTP',
      previous: 'ENTER_INFORMATION',
      previousAction: () => {},
      next: 'PREVIEW_CONTRACT',
      nextAction: () => {
        onRegisterLicense();
      },
      label: '',
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_NEXT',
      title: translate('BUY_LICENSE_TITLE_STEP_1'),
      subTitle: translate('BUY_LICENSE_SUB_TITLE_STEP_3'),
    },
    PREVIEW_CONTRACT: {
      key: 'PREVIEW_CONTRACT',
      previous: 'ENTER_OTP',
      previousAction: () => {},
      next: 'CHOOSE_TYPE',
      nextAction: () => {},
      label: '',
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'PAYMENT_LABEL_PURCHASE',
      title: translate('BUY_LICENSE_TITLE_STEP_1'),
      subTitle: translate('BUY_LICENSE_SUB_TITLE_STEP_4'),
    },
  },
  'CHOOSE_PACKAGE'
);

const isDisabledSubmitButton = computed<boolean>(() => {
  const isDisabledStep2 =
    currentStep.value === 'ENTER_INFORMATION' &&
    ((dataPayload.value?.type == 'COMPANY' &&
      (!dataPayload.value?.name ||
        !dataPayload.value?.representativeName ||
        !dataPayload.value?.position ||
        !dataPayload.value?.address ||
        !dataPayload.value?.phone ||
        !dataPayload.value?.email ||
        !validationResultPhone.value?.isValid ||
        !validationResultEmail.value?.isValid)) ||
      (dataPayload.value?.type == 'PERSONAL' &&
        (!dataPayload.value?.name ||
          !dataPayload.value?.phone ||
          !dataPayload.value?.email ||
          !validationResultPhone.value?.isValid ||
          !validationResultEmail.value?.isValid)));

  const isDisableLastStep =
    currentStep.value === 'PREVIEW_CONTRACT' &&
    !isSelectedAcceptProvision.value;
  return (
    isTypingPhone.value ||
    isTypingEmail.value ||
    isDisableLastStep ||
    isDisabledStep2
  );
});

const registerInfo = ref<any>({});

const onRegisterLicense = async () => {
  try {
    const res = await _paymentStore.registerLicense(dataPayload.value);

    console.log('🚀 Hyrin ~ onRegisterLicense ~ res:', res);
    registerInfo.value = res?.result;
  } catch (error) {
    console.log('🚀 Hyrin ~ onRegisterLicense ~ error:', error);
  }
};

const sendVerificationCode = async () => {
  try {
    await _registerStore.sendVerificationCodeByPhoneNumber({
      phoneNumber: dataPayload.value?.phone,
      type: 'license_register',
      locale: 'vi',
    });

    onHandleGoToNextStep();
  } catch (error) {
    console.log('🚀 Hyrin ~ sendVerificationCode ~ error:', error);
  }
};

const verifyCode = ref<string>('');
const onVerifyPhoneCode = async () => {
  try {
    verifyErrorMessageCode.value = '';
    const data = {
      phoneNumber: dataPayload.value?.phone,
      code: verifyCode.value,
      sessionInfo: null,
      type: 'license_register',
    };
    const res = await _registerStore.verifyUserPhoneNumber(data);
    if (res?.success) onHandleGoToNextStep();
    else verifyErrorMessageCode.value = 'TOKEN_INFO_NOT_MATCH';
  } catch (error: any) {
    console.log('🚀 Hyrin ~ onVerify ~ error:', error);
    verifyErrorMessageCode.value = 'TOKEN_INFO_NOT_MATCH';
  }
};

const onClickBack = () => {
  onHandleGoToPreviousStep();
};
const onClickNext = async () => {
  switch (currentStep.value) {
    case 'ENTER_INFORMATION':
      sendVerificationCode();
      break;
    case 'ENTER_OTP':
      onVerifyPhoneCode();
      break;
    case 'PREVIEW_CONTRACT':
      window.location.href = `${
        import.meta.env.VITE_BASE_PAYMENT_URL
      }/buy-license/${registerInfo.value?.id}`;
      break;

    default:
      onHandleGoToNextStep();
      break;
  }
};

const onChoosePackage = (pac) => {
  console.log('🚀 Hyrin ~ onChoosePackage ~ pac:', pac);
  dataPayload.value.licensePackageId = pac?.id;
  onHandleGoToNextStep();
};

const dataPayload = ref<IRegisterLicensePayload>({
  licensePackageId: 'COMPANY',
  type: 'COMPANY',
  planId: '',
  name: '',
  phone: '',
  address: '',
  email: '',
  representativeName: '',
  position: '',
  taxCode: '',
  status: '',
});

const onChangeOrgModel = (item) => {
  dataPayload.value.type = item?.code;
};

const resultPhoneObject = ref<{
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}>({
  countryCallingCode: '',
  nationalNumber: '',
  number: '',
  isValid: true,
  formattedNumber: '',
});
const {
  isTyping: isTypingPhone,
  isChecking: isCheckingPhone,
  validationResult: validationResultPhone,
  onValidation: onValidationPhone,
  reset: onResetValidationPhone,
} = atomFormInputComposables([
  () => {
    return !resultPhoneObject.value?.isValid && dataPayload.value?.phone
      ? 'COMMON_INVALID_PHONE_NUMBER'
      : '';
  },
  (value) => {
    const isValid = !dataPayload.value?.phone || isValidPhoneNumber(value);
    return isValid ? '' : 'COMMON_INVALID_PHONE_NUMBER';
  },
]);

const onBlurPhone = () => {
  if (!dataPayload.value?.phone || validationResultPhone.value?.isValid) return;

  onValidationPhone(dataPayload.value?.phone);
};

const onChangePhone = (phoneObj: {
  country?: CountryCode;
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  isValid: boolean;
  formattedNumber: string;
}) => {
  onResetValidationPhone();
  resultPhoneObject.value = phoneObj;
  dataPayload.value.phone = phoneObj?.number || '';

  if (phoneObj?.number?.length == 12) onValidationPhone(phoneObj.number);
};

// Email
const {
  isTyping: isTypingEmail,
  isChecking: isCheckingEmail,
  validationResult: validationResultEmail,
  onValidation: onValidationEmail,
  reset: onResetValidationEmail,
} = atomFormInputComposables([
  (value) => {
    return isValidEmail(value) ? '' : 'COMMON_INVALID_EMAIL';
  },
]);

const onChangeEmail = (value) => {
  if (!value) return;
  onResetValidationEmail();
  if (!isValidEmail(value)) return;

  onValidationEmail(value);
};

const onBlurEmail = () => {
  if (!dataPayload.value?.email || validationResultEmail.value?.isValid) return;
  onValidationEmail(dataPayload.value?.email);
};

const onAddressSelect = (value) => {
  console.log('🚀 Hyrin ~ onAddressSelect ~ value:', value);
  // dataPayload.value.address = JSON.stringify(value);
};

const verifyErrorMessageCode = ref<string>('');

const onChangeOptCode = (code) => {
  verifyCode.value = code;
  verifyErrorMessageCode.value = '';
};

const onNextToPreview = () => {};

const isSelectedAcceptProvision = ref<boolean>(false);

initComponent();
</script>
<template>
  <div
    class="
      h-screen
      w-full
      flex-center
      justify-around
      bg-current-50 bg-opacity-50
    "
  >
    <div
      class="flex-center flex-col space-y-8"
      :class="{ 'h-full': currentStep == 'PREVIEW_CONTRACT' }"
      style="width: 64rem; min-height: 40rem"
    >
      <div class="flex-center flex-col space-y-4">
        <div class="text-2xl text-gray-800 font-semibold pt-8">
          {{ stepperByKey[currentStep].title }}
        </div>
        <div class="text-gray-500 text-center">
          {{ stepperByKey[currentStep].subTitle }}
        </div>
      </div>
      <div
        class="flex-1 flex-center flex-col py-8 w-full min-h-0 overflow-hidden"
      >
        <template v-if="currentStep == 'CHOOSE_PACKAGE'">
          <div
            class="flex-1 w-full pt-16"
            style="
              display: grid;
              grid-auto-rows: max-content;
              grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
              gap: 1rem;
            "
          >
            <div
              v-for="(pac, index) in packageList"
              :key="index"
              class="
                w-full
                relative
                border border-gray-300
                hover:bg-current-50
                cursor-pointer
                hover:shadow
                rounded-lg
                flex-center flex-col
              "
              style="width: 189px; height: 260px"
              @click="onChoosePackage(pac)"
            >
              <div class="w-full h-1/3 flex-center">
                <div class="absolute -top-8">
                  <SynIcon
                    :name="
                      index + 1 == 4 || index + 1 > 5
                        ? 'license'
                        : `license${index + 1}`
                    "
                    custom-class="w-16 h-16"
                  />
                </div>
              </div>
              <div class="w-full h-1/3 px-5 text-center">
                <div style="font-size: 14px">
                  Quyền sử dụng chọn đời dành cho:
                </div>
                <br />
                <div
                  class="text-center"
                  style="font-size: 25px; color: #606060"
                >
                  {{ pac?.totalMember }} người
                </div>
              </div>
              <div class="w-full h-1/3 flex-center">
                <span
                  class="
                    w-full
                    px-2
                    py-1
                    bg-current
                    text-white text-center text-lg
                  "
                >
                  {{ $filters.currency(pac?.totalAmount, 'vi') }}
                </span>
              </div>
            </div>
          </div>
        </template>

        <template v-if="currentStep == 'ENTER_INFORMATION'">
          <div class="w-full flex-1 flex-center flex-col space-y-4">
            <div class="flex-center space-x-2">
              <template v-for="item in organizationModels" :key="item">
                <div
                  class="
                    flex-center
                    rounded-full
                    px-3
                    py-1
                    cursor-pointer
                    text-sm
                  "
                  :class="
                    dataPayload?.type === item.code
                      ? 'text-white bg-current-500  '
                      : 'border border-gray-300'
                  "
                  style="min-width: 4rem"
                  @click="onChangeOrgModel(item)"
                >
                  {{ item.name }}
                </div>
              </template>
            </div>

            <div
              class="flex flex-col flex-1 w-full space-y-4"
              style="max-width: 40rem"
            >
              <template v-if="dataPayload?.type == 'COMPANY'">
                <!--  NAME -->
                <div class="w-full flex flex-col space-y-1">
                  <div
                    class="flex items-center text-sm pr-4 w-44 text-gray-600"
                  >
                    <span> Tên doanh nghiệp </span>
                    <span class="text-red-500 pl-1">*</span>
                  </div>

                  <SynFormInput>
                    <SynInput
                      ref="organizationNameRef"
                      v-model="dataPayload.name"
                      custom-class="text-sm"
                      autofocus
                      :placeholder="$t('ORG_SETUP_PLACEHOLDER_NAME_COMPANY')"
                    />
                  </SynFormInput>
                </div>
                <!-- DEPUTY -->
                <div class="flex-center space-x-4">
                  <!--  Deputy Name -->
                  <div class="w-3/5 flex flex-col space-y-1">
                    <div
                      class="flex items-center text-sm pr-4 w-44 text-gray-600"
                    >
                      <span> Người đại diện </span>
                      <span class="text-red-500 pl-1">*</span>
                    </div>

                    <SynFormInput>
                      <SynInput
                        v-model="dataPayload.representativeName"
                        custom-class="text-sm"
                        :placeholder="'Nhập tên người đại diện'"
                      />
                    </SynFormInput>
                  </div>
                  <!--  Deputy Name -->
                  <div class="w-2/5 flex flex-col space-y-1">
                    <div
                      class="flex items-center text-sm pr-4 w-44 text-gray-600"
                    >
                      <span> Chức vụ </span>
                      <span class="text-red-500 pl-1">*</span>
                    </div>

                    <SynFormInput>
                      <SynInput
                        v-model="dataPayload.position"
                        custom-class="text-sm"
                        :placeholder="'Nhập chức vụ người đại diện'"
                      />
                    </SynFormInput>
                  </div>
                </div>
                <!--  ADDRESS -->
                <div class="flex flex-col space-y-1">
                  <div
                    class="flex items-center text-sm pr-4 w-44 text-gray-600"
                  >
                    <span>{{ $t('USERMANAGEMENT_TABLE_ADDRESS') }}</span>
                    <span class="text-red-500 pl-1">*</span>
                  </div>
                  <SynFormInput>
                    <VigAddressBox
                      v-model="dataPayload.address"
                      :placeholder="
                        $t('COMMON_LABEL_PLACEHOLDER_ENTER_ADDRESS')
                      "
                      class="vig-input text-sm"
                      @on-address-select="onAddressSelect"
                    />
                  </SynFormInput>
                </div>

                <!--  TAX_CODE -->
                <div class="flex flex-col space-y-1">
                  <span class="text-gray-600 text-sm">{{
                    $t('USERMANAGEMENT_TABLE_TAXCODE')
                  }}</span>

                  <div class="w-full">
                    <SynInput
                      v-model="dataPayload.taxCode"
                      custom-class="text-sm"
                      :placeholder="
                        $t('COMMON_LABEL_PLACEHOLDER_ENTER_TAXCODE')
                      "
                    />
                  </div>
                </div>
                <!-- Phone & Email -->
                <div class="flex-center space-x-4">
                  <!--  PHONE        -->
                  <div class="w-1/2 my-2 flex flex-col space-y-1">
                    <div
                      class="flex items-center text-sm pr-4 w-44 text-gray-600"
                    >
                      <span>{{ $t('USERMANAGEMENT_FORM_PHONE') }}</span>
                      <span class="text-red-500 pl-1">*</span>
                    </div>
                    <SynFormInput
                      :is-checking="isCheckingPhone"
                      :is-valid="
                        dataPayload?.phone !== null &&
                        dataPayload?.phone !== '' &&
                        validationResultPhone.isValid
                      "
                      :error-message="validationResultPhone.message"
                    >
                      <AtomPhoneInput
                        :value="dataPayload?.phone"
                        input-class="text-sm"
                        :input-options="{
                          placeholder: $t('COMMON_LABEL_COMPANY_MOBILE_PHONE'),
                        }"
                        :available-countries="['vn']"
                        :default-country="'vn'"
                        :auto-format="false"
                        :auto-default-country="false"
                        @change="onChangePhone"
                        @blur="onBlurPhone"
                      />
                    </SynFormInput>
                  </div>
                  <!--  EMAIL        -->
                  <div class="w-1/2 my-2 flex flex-col space-y-1">
                    <div
                      class="flex items-center text-sm pr-4 w-44 text-gray-600"
                    >
                      <span>Email</span>
                      <span class="text-red-500 pl-1">*</span>
                    </div>
                    <SynFormInput
                      :is-checking="isCheckingEmail"
                      :is-valid="validationResultEmail.isValid"
                      :error-message="validationResultEmail.message"
                    >
                      <div
                        class="
                          relative
                          text-gray-600
                          focus-within:text-gray-800
                          w-full
                          rounded-md
                          py-0.4
                        "
                      >
                        <VigInputEmail
                          v-model="dataPayload.email"
                          class="
                            bg-white
                            w-full
                            h-10
                            focus:outline-none
                            pr-6
                            pl-12
                            text-sm
                            rounded-md
                            vig-input
                            placeholder-gray-500 placeholder-opacity-75
                          "
                          :placeholder="$t('LOGIN_FORM_EMAIL')"
                          @update:model-value="onChangeEmail"
                          @blur="onBlurEmail"
                        />
                        <div
                          class="
                            absolute
                            inset-y-0
                            left-4
                            flex
                            items-center
                            h-full
                            focus:outline-none focus:shadow-outline
                          "
                        >
                          <SynIcon
                            name="Email"
                            custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                          />
                        </div>
                        <div
                          class="
                            absolute
                            inset-y-0
                            left-4
                            flex
                            items-center
                            h-full
                            focus:outline-none focus:shadow-outline
                          "
                        >
                          <SynIcon
                            name="Email"
                            custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                          />
                        </div>
                      </div>
                    </SynFormInput>
                  </div>
                </div>
              </template>
              <template v-else>
                <!--  NAME -->
                <div class="w-full flex flex-col space-y-1">
                  <div
                    class="flex items-center text-sm pr-4 w-44 text-gray-600"
                  >
                    <span> Họ tên </span>
                    <span class="text-red-500 pl-1">*</span>
                  </div>

                  <SynFormInput>
                    <SynInput
                      ref="organizationNameRef"
                      v-model="dataPayload.name"
                      custom-class="text-sm"
                      autofocus
                      :placeholder="'Nhập tên của bạn'"
                    />
                  </SynFormInput>
                </div>
                <!--  ADDRESS -->
                <div class="flex flex-col space-y-1">
                  <div
                    class="flex items-center text-sm pr-4 w-44 text-gray-600"
                  >
                    <span>{{ $t('USERMANAGEMENT_TABLE_ADDRESS') }}</span>
                  </div>
                  <SynFormInput>
                    <VigAddressBox
                      v-model="dataPayload.address"
                      :placeholder="
                        $t('COMMON_LABEL_PLACEHOLDER_ENTER_ADDRESS')
                      "
                      class="vig-input text-sm"
                      @on-address-select="onAddressSelect"
                    />
                  </SynFormInput>
                </div>
                <!-- Phone & Email -->
                <div class="flex-center space-x-4">
                  <!--  PHONE        -->
                  <div class="w-1/2 my-2 flex flex-col space-y-1">
                    <div
                      class="flex items-center text-sm pr-4 w-44 text-gray-600"
                    >
                      <span>{{ $t('USERMANAGEMENT_FORM_PHONE') }}</span>
                      <span class="text-red-500 pl-1">*</span>
                    </div>
                    <SynFormInput
                      :is-checking="isCheckingPhone"
                      :is-valid="
                        dataPayload?.phone !== null &&
                        dataPayload?.phone !== '' &&
                        validationResultPhone.isValid
                      "
                      :error-message="validationResultPhone.message"
                    >
                      <AtomPhoneInput
                        :value="dataPayload?.phone"
                        input-class="text-sm"
                        :input-options="{
                          placeholder: 'Nhập số điện thoại của bạn',
                        }"
                        :available-countries="['vn']"
                        :default-country="'vn'"
                        :auto-format="false"
                        :auto-default-country="false"
                        @change="onChangePhone"
                        @blur="onBlurPhone"
                      />
                    </SynFormInput>
                  </div>
                  <!--  EMAIL        -->
                  <div class="w-1/2 my-2 flex flex-col space-y-1">
                    <div
                      class="flex items-center text-sm pr-4 w-44 text-gray-600"
                    >
                      <span>Email</span>
                      <span class="text-red-500 pl-1">*</span>
                    </div>
                    <SynFormInput
                      :is-checking="isCheckingEmail"
                      :is-valid="validationResultEmail.isValid"
                      :error-message="validationResultEmail.message"
                    >
                      <div
                        class="
                          relative
                          text-gray-600
                          focus-within:text-gray-800
                          w-full
                          rounded-md
                          py-0.4
                        "
                      >
                        <VigInputEmail
                          v-model="dataPayload.email"
                          class="
                            bg-white
                            w-full
                            h-10
                            focus:outline-none
                            pr-6
                            pl-12
                            text-sm
                            rounded-md
                            vig-input
                            placeholder-gray-500 placeholder-opacity-75
                          "
                          :placeholder="'Nhập email của bạn'"
                          @update:model-value="onChangeEmail"
                          @blur="onBlurEmail"
                        />
                        <div
                          class="
                            absolute
                            inset-y-0
                            left-4
                            flex
                            items-center
                            h-full
                            focus:outline-none focus:shadow-outline
                          "
                        >
                          <SynIcon
                            name="Email"
                            custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                          />
                        </div>
                        <div
                          class="
                            absolute
                            inset-y-0
                            left-4
                            flex
                            items-center
                            h-full
                            focus:outline-none focus:shadow-outline
                          "
                        >
                          <SynIcon
                            name="Email"
                            custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                          />
                        </div>
                      </div>
                    </SynFormInput>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-if="currentStep == 'ENTER_OTP'">
          <EnterLicenseCode
            :phone-number="dataPayload?.phone"
            :error-code="verifyErrorMessageCode"
            @change-code="onChangeOptCode"
            @resend-code="onNextToPreview"
          />
        </template>
        <template v-if="currentStep == 'PREVIEW_CONTRACT'">
          <div
            class="
              w-full
              flex-1 flex-center flex-col
              space-y-4
              overflow-y-hidden
            "
          >
            <iframe
              v-if="registerInfo?.contractFileHtml"
              :src="`data:text/html;base64,${registerInfo?.contractFileHtml}`"
              frameborder="0"
              class="flex-1 w-full h-full bg-white small-scrollbar"
            ></iframe>
            <div class="flex items-center space-x-2">
              <div
                class="
                  cursor-pointer
                  h-4
                  w-4
                  flex-center
                  rounded
                  border border-current-300
                  group-hover:border-2
                "
                :class="{
                  'bg-current-300': isSelectedAcceptProvision,
                }"
                @click="isSelectedAcceptProvision = !isSelectedAcceptProvision"
              >
                <SynIcon
                  v-if="isSelectedAcceptProvision"
                  name="check"
                  class="text-white"
                />
              </div>
              <span>Tôi đồng ý với điều khoản và quy định trong hợp đồng</span>
            </div>
            <div>
              <span>
                Sau khi thanh toán thành công thì chúng tôi sẽ gửi cho bạn cả
                hợp đồng cả mã số licence (quyền sử dụng) của bạn
              </span>
            </div>
          </div>
        </template>

        <div
          v-if="currentStep !== 'CHOOSE_PACKAGE'"
          class="
            w-full
            flex
            justify-between
            items-center
            hover:text-current
            cursor-pointer
            mt-8
          "
          :style="
            currentStep == 'PREVIEW_CONTRACT'
              ? 'width: 100%'
              : 'max-width: 40rem'
          "
        >
          <div class="flex-center space-x-2 w-max"></div>

          <div class="flex-center space-x-2 w-max">
            <SynButton
              v-if="stepperByKey[currentStep]?.['buttonBack']"
              :label="$t(stepperByKey[currentStep]?.['buttonBack'])"
              type-text
              color="gray"
              @click="onClickBack"
            />

            <SynButton
              :disabled="isDisabledSubmitButton"
              :label="$t(stepperByKey[currentStep]?.['buttonNext'])"
              @click="onClickNext"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
