import {
    DayoffDateSession,
    DayoffExceptionType,
} from '@/domain/enums/DayoffEnums';

export default class DateoffExceptionClass {
    type: DayoffExceptionType; // 1 - START_LATE | 2 - STOP_EARLY | 3 - BREAK_TIME
    daySession: DayoffDateSession; // 1 - MORNING | 2 - AFTER_NOON | 3 - ALL_DAY
    startTime: number;
    endTime: number;
    constructor(data) {
        this.type = data?.type || DayoffExceptionType.START_LATE;
        this.daySession = data?.daySession || DayoffDateSession.ALL_DAY;
        this.startTime = data?.startTime || Date.now();

        if (this.type === DayoffExceptionType.BREAK_TIME) {
            this.endTime = data?.endTime || Date.now();
        } else {
            this.endTime = this.startTime;
        }
    }
}
