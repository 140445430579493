<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import commonStore from '@/store/common/commonStore';
import { CurrentUserConfig } from '@/ui/common/constants/constant';
import { ETaskViewType } from '@/application/constants/task.const';
import myProfileStore from '@/store/auth/my-profile';

const _commonStore = commonStore();

const currentLanguage = ref('');
onMounted(() => {
  currentLanguage.value = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';
});
const userConfigByKeys = computed<any>(() => {
  return _commonStore.userConfigByKeys;
});

const currentViewTaskListDefault = computed<string>(() => {
  if (
    !userConfigByKeys.value ||
    !Object.prototype.hasOwnProperty.call(
      userConfigByKeys.value,
      CurrentUserConfig.ViewTaskListDefault
    )
  )
    return ETaskViewType.LIST;

  return (
    userConfigByKeys.value[CurrentUserConfig.ViewTaskListDefault] ||
    ETaskViewType.LIST
  );
});

const isShowSetting = ref(false);

const listCustomize = {
  LIST: {
    key: 'LIST',
    nameIcon: 'view-list',
    label: 'TASK_LIST_VIEW_LIST_LABEL',
  },
  BOARD: {
    key: 'BOARD',
    nameIcon: 'view-board',
    label: 'TASK_LIST_VIEW_BOARD_LABEL',
  },
  CALENDAR: {
    key: 'CALENDAR',
    nameIcon: 'view-calendar',
    label: 'TASK_LIST_VIEW_CALENDAR_LABEL',
  },
};

const uploadingKey = ref<string>('');
const onChangeViewTaskMode = async (viewModeKey) => {
  if (!viewModeKey || uploadingKey.value) return;

  try {
    uploadingKey.value = viewModeKey;
    await commonStore().updateCurrentUserSetting({
      userId: myProfileStore().myProfile?.id,
      organizationId: myProfileStore().myProfile?.organizationId,
      key: CurrentUserConfig.ViewTaskListDefault,
      value: viewModeKey,
    });
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    uploadingKey.value = '';
  }
};
</script>

<template>
  <SynDropdownItem
    class="flex justify-between items-center"
    @click.stop="isShowSetting = !isShowSetting"
  >
    <div class="flex items-center space-x-2 pl-2">
      <span>
        {{ $t(listCustomize[currentViewTaskListDefault]?.label) }}
      </span>
    </div>

    <SynIcon
      :name="'sort-down'"
      custom-class="h-2 w-2 fill-gray-500"
      :class="!isShowSetting ? '-rotate-90' : ''"
      @click.stop="isShowSetting = !isShowSetting"
    />
  </SynDropdownItem>

  <!-- Other -->
  <SynDropdownItem
    v-if="isShowSetting && currentViewTaskListDefault != 'LIST'"
    class="bg-gray-100"
    @click="onChangeViewTaskMode('LIST')"
  >
    <div class="flex items-center">
      <div class="flex items-center">
        <span class="pl-2 text-sm">{{
          $t('TASK_LIST_VIEW_LIST_LABEL') || 'French'
        }}</span>
      </div>
    </div>
  </SynDropdownItem>

  <SynDropdownItem
    v-if="isShowSetting && currentViewTaskListDefault != 'BOARD'"
    class="bg-gray-100"
    @click="onChangeViewTaskMode('BOARD')"
  >
    <div class="flex items-center">
      <div class="flex items-center">
        <span class="pl-2 text-sm">
          {{ $t('TASK_LIST_VIEW_BOARD_LABEL') }}
        </span>
      </div>
    </div>
  </SynDropdownItem>
  <SynDropdownItem
    v-if="isShowSetting && currentViewTaskListDefault != 'CALENDAR'"
    class="bg-gray-100"
    @click="onChangeViewTaskMode('CALENDAR')"
  >
    <div class="flex items-center">
      <div class="flex items-center">
        <span class="pl-2 text-sm">
          {{ $t('TASK_LIST_VIEW_CALENDAR_LABEL') }}
        </span>
      </div>
    </div>
  </SynDropdownItem>
</template>
