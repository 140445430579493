<script setup lang="ts">
import { ref, computed, onMounted, inject } from 'vue';
import DomainGridViewCard from '@/ui/modules/overview/domain/DomainGridViewCard.vue';
import OverviewDomainByList from '@/ui/modules/overview/domain/OverviewDomainByList.vue';
// import { getCurrentUserId } from '@/ui/hooks/storageHook';
// import TaskCreateLocal from '@/ui/modules/task/create/TaskCreateLocal.vue';
import groupStore from '@/store/group';
import CreateGroupTask from '@/ui/modules/group/create/CreateGroupTask.vue';
import overviewDomainStore from '@/store/dashboard/overview-domains-store';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import myProfile from '@/store/auth/my-profile';
import localConfigsStore from '@/store/local-configs';

const _groupStore = groupStore();
const _overviewDomainStore = overviewDomainStore();
const _localConfigsStore = localConfigsStore();
const isHiddenBlockPage: any = inject('IS_HIDDEN_BLOCK_PAGE');

const isOwnerAdmin = computed<boolean>(() => {
  return myProfile().isAdmin || myProfile().isOwner;
});
const isVisitor = computed<boolean>(() => {
  return myProfile().isVisitor;
});

const initComponent = async () => {
  if (isOwnerAdmin.value) {
    if (!_localConfigsStore?.localConfigs?.domain) {
      _overviewDomainStore.isLoadingDomainTask = true;
      await _localConfigsStore.loadAllLocalConfigs();
      _overviewDomainStore.isLoadingDomainTask = false;
    }

    _overviewDomainStore.viewMode =
      _localConfigsStore?.localConfigs?.domain?.overviewDomainViewDefault ||
      'BOARD';

    if (!_localConfigsStore?.localConfigs?.domain?.overviewDomainViewDefault) {
      _localConfigsStore.setLocalConfigs('domain', {
        overviewDomainViewDefault: 'BOARD',
      });
    }
  }
};

const isLoading = computed(() => _overviewDomainStore.isLoadingDomainTask);

onMounted(() => {
  // _overviewDomainStore.getOverviewData();
  // _groupStore.getAllGroup();
});
// const currentUserId = computed(() => getCurrentUserId());
const groupDetail = ref({});
const taskCreateLocalRef = ref(null as any);
const onCreateTaskForGroup = async (groupId) => {
  const data = await _groupStore.getDetailGroupById(groupId);
  groupDetail.value = data;
  // isOpenCreateTaskGlobal.value = true;
  taskCreateLocalRef.value?.onOpenInterface();
};

// show Add group
const isAddGroup = ref(false);

const onAddGroup = () => {
  isAddGroup.value = true;
};

const onCreateGroupSuccess = () => {
  isAddGroup.value = false;
};

const viewMode = computed<any>(() => {
  return _overviewDomainStore.viewMode;
});

initComponent();
</script>

<template>
  <div
    v-permission-screen="
      isHiddenBlockPage
        ? false
        : {
            screenCode: ALL_SCREENS.DOMAIN.OVERVIEW.OVERVIEW,
            isPage: true,
          }
    "
    class="flex flex-col h-full w-full relative"
  >
    <SynPageLoading v-if="isLoading" />
    <div v-else class="h-full w-full">
      <template v-if="isOwnerAdmin">
        <DomainGridViewCard
          v-if="viewMode == 'BOARD'"
          @on-create-task-for-group="onCreateTaskForGroup"
          @on-add="onAddGroup"
        />
        <OverviewDomainByList
          v-else
          @on-create-task-for-group="onCreateTaskForGroup"
          @on-add="onAddGroup"
        />
      </template>
      <template v-else-if="!isVisitor">
        <OverviewDomainByList
          @on-create-task-for-group="onCreateTaskForGroup"
          @on-add="onAddGroup"
        />
      </template>
    </div>

    <!--    <TaskCreateLocal-->
    <!--      ref="taskCreateLocalRef"-->
    <!--      :is-group="groupDetail && groupDetail.id"-->
    <!--      :assignee-id="currentUserId"-->
    <!--      :group-id="groupDetail?.id"-->
    <!--      :group-domains="groupDetail?.groupDomains"-->
    <!--    />-->
  </div>

  <CreateGroupTask
    v-if="isAddGroup"
    @cancel="isAddGroup = false"
    @create-success="onCreateGroupSuccess"
  />
</template>
