<template>
  <component
    :is="name"
    class="inline-block"
    :class="customClass ? customClass : `w-4 h-4`"
  >
  </component>
</template>
<script>
export default {
  name: 'SynIcon',
  components: {},
  props: {
    name: {
      type: String,
      required: true,
      default: 'coffee',
    },

    customClass: {
      type: String,
      default: '',
    },
  },
  setup: () => {
    return {};
  },
};
</script>
