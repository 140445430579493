<script setup lang="ts">
import { ref, watch } from 'vue';
import TaskDetail from '@/ui/modules/task/detail/TaskDetail.vue';
import TabContainer from '@/ui/common/molecules/SynTab/TabContainer.vue';
import taskMultiTabLogic from '@/ui/modules/task/detail/task-multi-tab-logic';
import taskDetailStore from '@/store/task/detail';
import { computed } from '@vue/reactivity';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';

const props = defineProps<{
  taskId?: number;
  taskCode?: string;
}>();

const emit = defineEmits(['close']);

const _taskDetailStore = taskDetailStore();
const currentTaskId = ref(props.taskId);
const currentTaskCode = ref(props.taskCode);
const errorCode = ref('');
const currentTasks = ref([] as any[]);

const currentTabs = computed(() => {
  return currentTasks.value?.length > 0
    ? currentTasks.value?.map((task) => {
        return {
          id: task?.code,
          name: task?.code?.split('-').pop() || 'New tab',
          taskId: task?.id,
          taskCode: task?.code,
          taskName: task?.taskName,
          tabCode: task?.code,
        };
      })
    : [];
});
const {
  isAddingTab,
  addTabName,
  addTabRef,
  onSearch,
  onCloseAddNew,
  onOpenAddNewTab,
  editingTabRef,
  editingTab,
  editingTabName,
  onOpenEditName,
  onSaveChange,
  onChangeDrag,
  onSwitchTab,
  onCloseEditName,
} = taskMultiTabLogic(
  errorCode,
  async (taskCode) => {
    const taskDetail = await _taskDetailStore.getDetailByCode(taskCode);
    pushCurrentIds({ ...taskDetail, tabType: 'DETAIL' });
  },
  async (taskCode) => {
    const taskDetail = await _taskDetailStore.getDetailByCode(taskCode);
    replaceCurrentIds(taskDetail);
  },
  (oldIndex, newIndex) => {
    const oldItem = currentTasks.value[oldIndex];
    currentTasks.value?.splice(oldIndex, 1);
    currentTasks.value?.splice(newIndex, 0, oldItem);
  },
  (tab) => {
    if (currentTaskCode.value == tab.taskCode) return;

    currentTaskCode.value = tab.taskCode;
  }
);

const onChangeTabName = () => {
  errorCode.value = '';
};

const initData = () => {
  currentTasks.value.push({
    id: props.taskId,
    code: props.taskCode,
  });
};

const onCloseTab = (tab) => {
  if (currentTasks.value?.length == 1) onOpenAddNewTab(true);

  currentTasks.value = currentTasks.value?.filter(
    (t) => t?.id !== tab?.taskId && t?.code !== tab?.taskCode
  );

  if (
    currentTaskId.value == tab?.taskId ||
    (currentTaskCode.value == tab?.taskCode && currentTasks.value?.length > 0)
  ) {
    currentTaskId.value = currentTasks.value[0]?.id;
    currentTaskCode.value = currentTasks.value[0]?.code;
  }
};

const replaceCurrentIds = ({ id, code }) => {
  if (!id) return;

  const newIndex = currentTasks.value.findIndex(
    (o) => o.code == code || o?.id == id
  );
  if (newIndex > -1) {
    currentTaskCode.value = code;
  } else {
    const oldCurrentTaskCode = currentTaskCode.value;
    const index = currentTasks.value.findIndex(
      (o) => o.code == oldCurrentTaskCode
    );
    if (index > -1) {
      const taskObj = {
        id: parseInt(id),
        code,
      };
      currentTasks.value[index] = taskObj;
      currentTaskCode.value = code;
    }
  }
};

const pushCurrentIds = ({ id, code, name }) => {
  if (!id) return;
  currentTaskId.value = id;
  currentTaskCode.value = code;

  const currentTaskCodes =
    currentTasks.value && currentTasks.value.length > 0
      ? new Set(cloneDeep(currentTasks.value?.map((o) => o.code)))
      : new Set();

  const taskObj = {
    id,
    code,
    taskName: name,
  };

  if (!currentTaskCodes.has(taskObj.code)) {
    currentTasks.value = [taskObj, ...currentTasks.value];
  }

  if (currentTasks.value.length > 8) currentTasks.value?.pop();
};

watch(
  () => props.taskCode,
  () => {
    currentTaskCode.value = props.taskCode;
    initData();
  }
);
watch(
  () => props.taskId,
  () => {
    currentTaskId.value = props.taskId;
    initData();
  }
);

const isOpenChatModal = ref<boolean>(false);
const onClose = () => {
  _taskDetailStore.$reset();
  emit('close');
};

const goToTask = (task) => {
  pushCurrentIds(task);
};

initData();
</script>

<template>
  <SynModalNoFocus
    style-body="p-0 overflow-hidden"
    container-class="w-2/5"
    z-index="z-50"
    container-style="width: 650px; max-width: 1200px; height: 80%;"
    is-hidden-header
    is-hidden-footer
    disable-click-outside
    :background-style="isOpenChatModal ? 'opacity: 0' : ''"
    @cancel="onClose"
  >
    <template #body>
      <TabContainer body-class="overflow-hidden h-full" class="bg-gray-200">
        <template #header>
          <div class="flex-center">
            <div
              v-if="isAddingTab"
              class="relative flex-center hover-to-show__parent"
            >
              <input
                ref="addTabRef"
                v-model="addTabName"
                v-cusFocus="true"
                placeholder="#code"
                class="
                  h-full
                  w-20
                  outline-none
                  p-1
                  ring-1
                  focus:ring-current
                  rounded
                "
                @keyup.enter="onSearch"
                @blur="onCloseAddNew"
                @input="onChangeTabName"
              />
              <div
                v-if="addTabName"
                class="
                  hover-to-show__children
                  absolute
                  right-1
                  w-4
                  h-4
                  rounded-full
                  bg-white
                  flex-center
                  hover:text-black hover:bg-gray-300
                "
                @click.stop="
                  addTabName = '';
                  addTabRef?.focus();
                "
              >
                <SynIcon name="close" custom-class="w-3 h-3 " />
              </div>
            </div>
            <div
              v-else
              class="h-8 w-8 flex-center hover:bg-current-50 cursor-pointer"
              @click.stop="onOpenAddNewTab"
            >
              <SynIcon
                class="fill-current"
                custom-class="h-3 w-3 "
                name="plus"
              />
            </div>
            <ul class="flex cursor-pointer text-sm">
              <SortAble
                :options="{
                  preventOnFilter: false,
                  onMove: 1,
                }"
                class="flex"
                @on-change-drag="onChangeDrag"
              >
                <template #list-item>
                  <li
                    v-for="tab in currentTabs"
                    :key="tab.id"
                    v-vig-tooltip="tab.taskName"
                    :class="
                      tab.taskCode == currentTaskCode && !isAddingTab
                        ? 'bg-white font-semibold text-current'
                        : 'text-current-900 bg-gray-200 hover:bg-gray-100'
                    "
                    class="hover-to-show__parent flex-center"
                    @click="onSwitchTab(tab)"
                    @auxclick="onCloseTab(tab)"
                  >
                    <div
                      v-if="editingTab == tab.taskCode"
                      class="h-full relative flex-center hover-to-show__parent"
                    >
                      <input
                        ref="editingTabRef"
                        v-model="editingTabName"
                        v-cusFocus="true"
                        placeholder="#code"
                        class="
                          w-20
                          outline-none
                          p-1
                          ring-1
                          focus:ring-current
                          rounded
                        "
                        @keyup.enter="onSaveChange(editingTabName)"
                        @blur="onCloseEditName"
                        @input="onChangeTabName"
                      />
                      <div
                        v-if="editingTabName"
                        class="
                          hover-to-show__children
                          absolute
                          right-1
                          w-4
                          h-4
                          rounded-full
                          bg-white
                          flex-center
                          hover:text-black hover:bg-gray-300
                        "
                        @click.stop="
                          editingTabName = '';
                          editingTabRef?.focus();
                        "
                      >
                        <SynIcon name="close" custom-class="w-3 h-3" />
                      </div>
                    </div>
                    <div
                      v-else
                      class="flex items-center justify-between relative py-1.5"
                      style="min-width: 4rem"
                    >
                      <span class="px-2" @dblclick="onOpenEditName(tab)">
                        {{ tab.name }}
                      </span>
                      <div
                        class="
                          w-4
                          h-4
                          rounded-full
                          bg-white
                          flex-center
                          hover:text-black hover:bg-gray-300
                          cursor-pointer
                        "
                        :class="
                          tab.taskCode == currentTaskCode && !isAddingTab
                            ? 'mr-1'
                            : 'hover-to-show__children absolute right-1'
                        "
                        @click.stop="onCloseTab(tab)"
                      >
                        <SynIcon name="close" custom-class="w-3 h-3" />
                      </div>
                    </div>
                  </li>
                </template>
              </SortAble>
            </ul>
          </div>
        </template>
        <template #actions>
          <div></div>
        </template>
        <template #body>
          <div
            v-if="
              (currentTaskId || currentTaskCode) && !isAddingTab && !errorCode
            "
            class="px-0 flex-1 min-h-0 flex flex-col"
          >
            <TaskDetail
              is-modal
              is-place-modal
              :task-id="currentTaskId"
              :task-code="currentTaskCode"
              @on-close="$emit('close')"
              @close-modal="$emit('close')"
              @on-toggle-chat-modal="(value) => (isOpenChatModal = value)"
              @go-to-task="goToTask"
            />
          </div>

          <div v-else class="flex-center">
            <div
              v-if="errorCode"
              class="py-6 px-4 bg-opacity-40 rounded-xl flex flex-col"
            >
              <syn-animation
                name="searchNotFound"
                stype="width: 150px; height:150px"
              />
              <div class="flex flex-col space-y-4 pt-8 text-center">
                <span
                  class="font-semibold text-2xl text-current-500"
                  v-html="
                    $t(errorCode, {
                      code: formatTaskCode(addTabName || editingTabName) || '',
                    }) ||
                    $t('TASK_LABEL_CAN_NOT_FIND_OUT_TASK') ||
                    'Something wrong'
                  "
                >
                </span>
                <span class="text-gray-500">
                  {{
                    $t('COMMON_LABEL_PAGE_NOT_FOUND_BODY', { name: 'task' }) ||
                    'We are sorry.'
                  }}
                </span>
              </div>
            </div>
            <syn-animation v-else name="my-tasks" />
          </div>
        </template>
      </TabContainer>
    </template>
  </SynModalNoFocus>
</template>
<style scoped>
.task-comment-input {
  .ck-toolbar__items {
    bottom: 7rem !important;
  }
}
</style>
