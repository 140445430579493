<script setup lang="ts">
import { ITaskTodo } from '@/application/types/task/task.types';
import ShapeTodoListEntity from '@/ui/components/drawer/entities/ShapeTodoListEntity';
import { inject, ref } from 'vue';
import TaskTodoListDetail from '@/ui/modules/task/components/check-list/TaskTodoListDetail.vue';
import PROVIDER_IDS, {
  TDesktopDrawer,
  TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
import ShapeActions from '@/ui/components/drawer/components/ShapeActions.vue';

const props = defineProps<{
  shapeEntity: ShapeTodoListEntity;
  readonly?: boolean;
}>();
console.log('🚀 Tictop ~ props:', props.shapeEntity);
const currentTodoList = ref<ITaskTodo | null>(
  props.shapeEntity?.sourceData || {
    id: '',
    title: '',
    description: '',
    todoItems: [],
  }
);
const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);
const desktopDrawerShapeInjected = inject<TDesktopDrawerShape>(
  PROVIDER_IDS.DESKTOP_DRAWER_SHAPE
);

const taskTodoListDetailRef = ref<any>();

// const onMouseDown = (event) => {
//   desktopDrawerShapeInjected?.onMouseDown(event);
// };
const onSelectResize = (event) => {
  desktopDrawerShapeInjected?.onMouseDown(event, 'RESIZE');
};

const onClickRemove = () => {
  desktopDrawerInjected?.desktopDrawerInstance.value?.removeShapeById(
    props.shapeEntity?.id,
    () => {
      desktopDrawerInjected?.addMoreHistory();
    }
  );
};
</script>
<template>
  <div
    class="flex-center rounded-md"
    :style="{
      width: `${shapeEntity?.width}px`,
      height: `${shapeEntity?.height}px`,
      pointerEvents: 'all',
    }"
  >
    <div
      class="w-full h-full bg-white relative rounded-md"
      style="pointer-events: all"
    >
      <TaskTodoListDetail
        v-if="currentTodoList"
        ref="taskTodoListDetailRef"
        :readonly="readonly"
        :todo-list="currentTodoList"
      />

      <ShapeActions
        class="absolute top-0 right-0"
        :shape-type="shapeEntity?.type"
        :source-data="shapeEntity?.sourceData"
        :action-list="['ATTACH_TO_TASK', 'REMOVE']"
        @on-click-remove="onClickRemove"
      />
      <span
        class="absolute bottom-0 right-0 pointer-events-auto p-1"
        style="cursor: n-resize"
        @mousedown.stop.prevent="onSelectResize"
      >
        <SynIcon name="TextareaResize" custom-class="h-3 w-3" />
      </span>
    </div>
  </div>
</template>
