<script setup lang="ts">
import { onMounted, ref, watch, computed } from 'vue';
import {
  colorByAlphabet,
  convertViToEn,
  // cleanString,
} from '@/ui/common/molecules/SynAvatar/colorByAlphabet';
import ModalPreviewPhoto from '@/ui/components/photo-upload/ModalPreviewPhoto.vue';

const props = withDefaults(
  defineProps<{
    type?: string;
    file?: File;
    avatarThumbnailUrl?: string;
    srcPreview?: string;
    size?: string;
    label?: string;
    name?: string;
    isLoading?: boolean;
    isDeletePhoto?: boolean;
    typeUpload?: string;
    zIndex?: string;
    isUpdateSuccess?: boolean;
    isChoosePhoto?: boolean;
    avatarReadonly?: boolean;
    rounded?: string;
    textStyle?: string;
    isShowButton?: boolean;
  }>(),
  {
    size: 'medium',
    zIndex: 'z-100',
    typeUpload: 'USER',
    label: 'Upload Avatar',
    rounded: 'full',
    textStyle: 'font-size: 1.1em',
  }
);

const emit = defineEmits([
  'uploadAvatarAws',
  'cancelUpload',
  'uploadAvatar',
  'deleteAvatar',
]);

const avatarThumb = ref('');
const srcImgPreview = ref(props.srcPreview);
const isLoadingUpload = computed(() => props.isLoading);
const fileCurrent = ref(props.file);

watch(
  () => props.avatarThumbnailUrl,
  () => {
    avatarThumb.value = props.avatarThumbnailUrl?.replace('original', 'mini');
  }
);

watch(
  () => props.isUpdateSuccess,
  () => {
    isOpenModalView.value = false;
  }
);

watch(
  () => props.file,
  () => {
    fileCurrent.value = props.file;
    srcImgPreview.value = URL.createObjectURL(fileCurrent.value);
  }
);

const textAva = computed(() => {
  if (!props?.name) return 'A';
  const index = props.name.split(' ').length - 1;
  return props.name.split(' ')[index].charAt(0);
});

onMounted(() => {
  if (props.file) {
    srcImgPreview.value = URL.createObjectURL(fileCurrent.value);
  }
  if (props.avatarThumbnailUrl) {
    avatarThumb.value = props.avatarThumbnailUrl?.replace('original', 'mini');
  }
});

// const sizeCamera = ref();
const sizeClass = computed(() => {
  if (props.size == 'medium') {
    return 'w-32 h-32';
  }
  if (props.size == 'small') {
    return 'w-16 h-16';
  }
  return 'w-80 w-80';
});

const isOpenModalView = ref(false);
const openModalView = () => {
  isOpenModalView.value = true;
};

const onError = () => {
  avatarThumb.value = props.avatarThumbnailUrl?.replace('mini', 'original');
};

const onComfirm = (file, isDefaultAvatar) => {
  emit('uploadAvatarAws', file, isDefaultAvatar);
  emit('uploadAvatar', file, isDefaultAvatar);
};

const onDeleteImage = () => {
  emit('deleteAvatar', null);
};

const viewImage = ref('');

const openDocument = (doc) => {
  viewImage.value = doc;
};
const closePreview = () => {
  viewImage.value = '';
};
</script>
<template>
  <div
    v-bind="$attrs"
    class="text-center w-full hover-to-show__parent cursor-pointer"
  >
    <div class="relative flex justify-center">
      <div class="border rounded-full bg-current-50" :class="sizeClass"></div>
      <div class="absolute top-0 flex justify-center" :class="sizeClass">
        <img
          v-if="srcImgPreview || avatarThumb"
          class="object-cover shadow"
          :class="[sizeClass, `rounded-${rounded}`]"
          :src="srcImgPreview || avatarThumb"
          :onError="onError"
          @click.stop="openDocument(srcImgPreview || avatarThumb)"
        />
        <div v-else class="w-full h-full flex-center">
          <SynIcon
            v-if="!name"
            :name="type === 'user' ? 'user' : 'groups'"
            class="w-2/3 h-2/3 pt-8 fill-current-700"
          />
          <div
            v-else
            class="
              w-full
              h-full
              object-cover
              text-white
              shadow
              font-thin
              flex-center
            "
            :class="[sizeClass, `rounded-${rounded}`]"
            :style="`background: ${
              colorByAlphabet[convertViToEn(textAva, true)?.toUpperCase()] ||
              '#4bb0b2'
            }`"
          >
            <span v-if="name" class="uppercase" :style="textStyle">
              {{ textAva }}
            </span>
            <SynIcon
              v-else
              :name="type === 'user' ? 'user' : 'groups'"
              class="fill-white text-white"
            />
          </div>
        </div>

        <div
          v-if="!avatarReadonly"
          v-permission="
            type == 'group'
              ? 'FunctionUploadAvatarGroup'
              : 'FunctionUploadAvatarUser'
          "
          class="
            absolute
            bottom-0
            flex
            items-center
            justify-center
            right-0
            w-1/3
            h-1/3
            rounded-full
            bg-current-50 bg-opacity-90
            cursor-pointer
            hover:bg-current-100
          "
          :class="isShowButton ? '' : 'hover-to-show__children'"
          @click="openModalView"
        >
          <SynIcon
            name="camera"
            class="w-1/2 h-1/2 fill-current font-bold hover:bg-current-100"
          />
        </div>
      </div>
    </div>
  </div>

  <ModalPreviewPhoto
    v-if="isOpenModalView"
    :src="srcImgPreview || avatarThumb"
    :is-loading="isLoadingUpload"
    :type-upload="typeUpload"
    :z-index="zIndex"
    :is-hidden-set-default="typeUpload == 'GROUP'"
    @cancel="(value) => (isOpenModalView = value)"
    @confirm="onComfirm"
    @delete-image="onDeleteImage"
  />

  <SynPreviewDoc
    v-if="viewImage"
    :src="viewImage"
    is-image
    @close-preview="closePreview"
  />
</template>
