<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="612px"
    height="612px"
    viewBox="0 0 612 612"
    style="enable-background: new 0 0 612 612"
    xml:space="preserve"
  >
    <g>
      <g id="_x31_0_5_">
        <g>
          <path
            d="M267.75,497.25H140.97l130.031-130.031l-27.043-27.042l-129.82,129.82l0.612-125.747
				c0-10.557-8.568-19.125-19.125-19.125S76.5,333.693,76.5,344.25v172.125c0,5.661,2.333,10.231,6.043,13.368
				c3.461,3.538,8.281,5.757,13.617,5.757H267.75c10.557,0,19.125-8.568,19.125-19.125S278.307,497.25,267.75,497.25z M535.5,0h-459
				C34.253,0,0,34.253,0,76.5v459C0,577.747,34.253,612,76.5,612h459c42.247,0,76.5-34.253,76.5-76.5v-459
				C612,34.253,577.747,0,535.5,0z M573.75,535.5c0,21.114-17.117,38.25-38.25,38.25h-459c-21.133,0-38.25-17.117-38.25-38.25v-459
				c0-21.114,17.117-38.25,38.25-38.25h459c21.133,0,38.25,17.117,38.25,38.25V535.5z M515.84,76.5H344.25
				c-10.557,0-19.125,8.568-19.125,19.125s8.568,19.125,19.125,19.125h126.779l-130.03,130.031l27.042,27.043l129.821-129.821
				L497.25,267.75c0,10.557,8.568,19.125,19.125,19.125s19.125-8.568,19.125-19.125V95.625c0-5.661-2.333-10.232-6.044-13.388
				C525.995,78.699,521.176,76.5,515.84,76.5z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
