import ShapeEntity from './ShapeEntity';

export default class ShapeNoteEntity extends ShapeEntity {
    sourceData?: IDrawNote;
    constructor(data) {
        super(data);
        this.sourceData =
            typeof data?.sourceData == 'string'
                ? JSON.parse(data?.sourceData)
                : data?.sourceData;
    }
}

// Generated by https://quicktype.io

export interface IDrawNote {
    base64: string;
    drawData: DrawData;
    audios: any[];
}

export interface DrawData {
    version: string;
    objects: any[];
    background: string;
}
