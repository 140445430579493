<script setup lang="ts">
import { ref } from 'vue';

const durationTime = ref(0);
const durationTimeMinus = ref(0);
const exceptionSessionTime = ref(1);
</script>

<template>
  <div class="w-max p-1 bg-white rounded flex items-center space-x-2">
    <SynInputNumber v-model="durationTime" :min="1" :max="12">
      <VigDropdown z-index="50" placement="top" @click.stop="() => {}">
        <template #dropdown-toggle>
          <div
            class="
              h-8
              flex-center
              space-x-1
              cursor-pointer
              px-2
              bg-white
              border-t border-b
            "
          >
            <span class="flex-center">
              {{ durationTime }}
            </span>
            <SynIcon name="chevron-double-down" />
          </div>
        </template>
        <template #dropdown-menu>
          <div class="h-96 overflow-x-hidden overflow-auto small-scrollbar">
            <div class="list-ul text-xs w-full">
              <div
                v-for="(item, index) in 12"
                :key="index"
                class="
                  list-li
                  dropdown-item
                  px-4
                  py-2
                  text-xs text-black
                  hover:bg-gray-100 hover:text-current-700
                  cursor-pointer
                  break-words
                "
                @click.stop="durationTime = item"
              >
                <span>
                  {{ item }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </VigDropdown>
    </SynInputNumber>
    <span>:</span>
    <SynInputNumber v-model="durationTimeMinus" :min="5" :max="55" :step="5">
      <VigDropdown z-index="50" placement="top" @click.stop="() => {}">
        <template #dropdown-toggle>
          <div
            class="
              h-8
              flex-center
              space-x-1
              cursor-pointer
              px-2
              bg-white
              border-t border-b
            "
          >
            <span class="flex-center">
              {{ durationTimeMinus }}
            </span>
            <SynIcon name="chevron-double-down" />
          </div>
        </template>
        <template #dropdown-menu>
          <div class="h-96 overflow-x-hidden overflow-auto small-scrollbar">
            <div class="list-ul text-xs w-full">
              <div
                v-for="(item, index) in 11"
                :key="index"
                class="
                  list-li
                  dropdown-item
                  px-4
                  py-2
                  text-xs text-black
                  hover:bg-gray-100 hover:text-current-700
                  cursor-pointer
                  break-words
                "
                @click.stop="durationTimeMinus = item * 5"
              >
                <span>
                  {{ item * 5 }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </VigDropdown>
    </SynInputNumber>
    <AtomLabelSwitchSimple v-model="exceptionSessionTime" :left-key="1">
      <template #left>AM</template>
      <template #right>PM</template>
    </AtomLabelSwitchSimple>
  </div>
</template>
