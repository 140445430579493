<script setup lang="ts">
// *** IMPORTS ***
import { computed, onMounted, ref } from 'vue';
import {
  CalendarSharingDataModel,
  CalendarSharingEventModel,
  CalendarSharingModel,
  CalendarSharingStatusEnum,
} from '@/application/models/calendar/CalendarSharingModel';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import { CalendarType } from '@/domain/enums/CalendarEnum';
import CalendarService from '@/application/services/CalendarService';
import CalendarSharingStatus from '@/ui/modules/calendar/calendar-sharing/calendar-sharing-status/CalendarSharingStatus.vue';
import { CalendarId } from '@/ui/common/constants/calendar';
import CalendarEventType from '@/ui/modules/calendar/detail-view-event/CalendarEventType.vue';
import CalendarEventDate from '@/ui/modules/calendar/detail-view-event/CalendarEventDate.vue';
import { arrToObj } from '@/ui/helpers/utils';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { getDayOffTitle } from '@/ui/components/calendars/event-item/dayoff-render-component';
import SynLoading from '@/ui/common/molecules/SynLoading/SynLoading.vue';
import VigLoading from '@/ui/common/atoms/VigLoading/VigLoading.vue';

// *** PROPS, EMITS ***
const props = defineProps<{
  sharing: CalendarSharingModel | null;
}>();

const emit = defineEmits(['onClose', 'onSaved']);

// *** PRIVATE VARIABLES ***
const _eventTypes = [
  { value: CalendarType.DayOff, text: 'COMMON_MODULE_DAYOFF' },
  { value: CalendarType.Meeting, text: 'COMMON_LABEL_EVENT' },
  { value: CalendarType.Reminder, text: 'CALENDAR_FILTER_TITLE_TYPE_REMINDER' },
];

// *** COMPOSABLES ***

// *** REFS ***
const sharingInfo = ref<CalendarSharingModel>({
  status: CalendarSharingStatusEnum.Sharing,
});
const sharingData = ref<CalendarSharingDataModel>({});
const events = ref<CalendarSharingEventModel[]>();
const isSharingSpecific = ref<boolean>();
const isSelectedAllEvents = ref<boolean>();
const isLoadingEvents = ref<boolean>();
const selectedEventById = ref<{ [eventId: string]: boolean }>({});

// *** COMPUTED ***
const selectedEvents = computed(() =>
  events.value?.filter((e) => selectedEventById.value[e?.id])
);

// *** WATCHES ***

// *** HOOKS ***
onMounted(() => {
  if (props.sharing) {
    sharingInfo.value = cloneDeep(props.sharing);
    sharingData.value = cloneDeep(props.sharing.sharingData || {});
    isSharingSpecific.value = !!sharingData.value.specificEventIds;
    if (isSharingSpecific.value) {
      selectedEventById.value = arrToObj(
        sharingData.value.specificEventIds,
        null,
        () => true
      );
    }
  }

  _getUserCalendarEventsBySharingFilter();
});

// *** HANDLER FUNCTIONS ***
const onEventDateChange = () => {
  _getUserCalendarEventsBySharingFilter();
};

const onEventTypeClick = (type) => {
  if (!type?.value) return;

  if (sharingData.value.eventTypes?.includes(type.value)) {
    sharingData.value.eventTypes = sharingData.value.eventTypes?.filter(
      (t) => t !== type.value
    );
  } else {
    sharingData.value.eventTypes = (sharingData.value.eventTypes || []).concat([
      type.value,
    ]);
  }

  _getUserCalendarEventsBySharingFilter();
};

const onEventSpecificToggle = () => {
  if (!isSharingSpecific.value) {
    isSelectedAllEvents.value = true;
    onEventSelectedAllChange();
  }
};

const onEventSelectedChange = () => {
  _processIsSelectedAllEvents();
};

const onEventSelectedAllChange = () => {
  if (isSelectedAllEvents.value) {
    selectedEventById.value = arrToObj(events.value, 'id', () => true);
  } else {
    selectedEventById.value = {};
  }
};

const onSaveSharingClick = async (buttonSave) => {
  // If no filtered events, show confirm saving
  // if (!selectedEvents.value?.length) {
  //   settingGlobalModal({
  //     type: 'warning',
  //     title: translate('CALENDAR_SHARING_DELETE_MSG'),
  //     content: '',
  //     confirmable: true,
  //     closeable: true,
  //   });
  //   const confirmed = await ask();
  //   if (!confirmed) return;
  // }

  _processSaveSharing(buttonSave).then();
};

// *** PRIVATE FUNCTIONS ***
const _getUserCalendarEventsBySharingFilter = async () => {
  isLoadingEvents.value = true;

  try {
    const filter = {
      calendarId: CalendarId.Event,
      startDate: sharingData.value?.dateFrom,
      endDate: sharingData.value?.dateTo,
      types: sharingData.value?.eventTypes,
    };

    const res =
      await CalendarService.getInstance().getUserCalendarEventsBySharingFilter(
        filter
      );

    events.value = res?.result;

    _processSelectedEvents();
    _processIsSelectedAllEvents();
  } catch (e) {
    console.log(e);
  }

  isLoadingEvents.value = false;
};

const _processSelectedEvents = () => {
  if (!isSharingSpecific.value) {
    selectedEventById.value = arrToObj(events.value, 'id', () => true);
  }
};

const _processIsSelectedAllEvents = () => {
  isSelectedAllEvents.value =
    events.value?.length &&
    events.value?.every((e) => selectedEventById.value[e?.id]);
};

const _processSaveSharing = async (buttonSave) => {
  buttonSave?.setProcessing(true);

  try {
    sharingData.value.specificEventIds = isSharingSpecific.value
      ? selectedEvents.value?.map((e) => e?.id)
      : null;

    const saveSharing: CalendarSharingModel = {
      ...sharingInfo.value,
      sharingData: sharingData.value,
    };

    let res;

    // Update sharing
    if (props.sharing?.id) {
      res = await CalendarService.getInstance().updateUserCalendarSharing(
        props.sharing?.id,
        saveSharing
      );
    }
    // Create sharing
    else {
      saveSharing.calendarId = CalendarId.Event;

      res = await CalendarService.getInstance().addUserCalendarSharing(
        saveSharing
      );
    }

    if (res?.result) emit('onSaved', res?.result);
  } catch (e) {
    console.log(e);
  }

  buttonSave?.setProcessing(false);
};

// *** EXPOSES ***
</script>

<template>
  <SynModal
    container-class="w-11/12 md:w-[60rem]"
    style-body="px-4"
    :disable-click-outside="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      {{ $t(sharing?.id ? 'CALENDAR_SHARING_UPDATE' : 'CALENDAR_SHARING_ADD') }}
    </template>
    <template #body>
      <div class="flex-1 overflow-hidden flex gap-6">
        <!--EVENT SETTINGS-->
        <div class="w-80">
          <div class="text-lg text-current-500 mb-3">
            {{ $t('CALENDAR_SHARING_SETTING_EVENT') }}
          </div>

          <!--FILTER DATE FROM-->
          <div class="flex items-center justify-between gap-3 mb-2">
            <div>{{ $t('COMMON_LABEL_FROM_DATE') }}</div>
            <div class="w-36">
              <AtomDatePicker
                v-model="sharingData.dateFrom"
                @update:model-value="onEventDateChange"
              />
            </div>
          </div>

          <!--FILTER DATE TO-->
          <div class="flex items-center justify-between gap-3 mb-2">
            <div>{{ $t('COMMON_LABEL_TO_DATE') }}</div>
            <div class="w-36">
              <AtomDatePicker
                v-model="sharingData.dateTo"
                @update:model-value="onEventDateChange"
              />
            </div>
          </div>

          <!--FILTER TYPES-->
          <div class="flex items-start justify-between gap-3 mb-2">
            <div>{{ $t('TASK_TABLE_LABEL_TYPE') }}</div>
            <div class="flex flex-wrap justify-end gap-2">
              <SynCheckboxLabel
                v-for="type in _eventTypes"
                :key="type?.value"
                :is-check="sharingData.eventTypes?.includes(type?.value)"
                :text="$t(type?.text)"
                @click="onEventTypeClick(type)"
              />
            </div>
          </div>

          <!--SHARING SETTINGS-->
          <div class="mt-4">
            <div class="text-lg text-current-500 mb-3">
              {{ $t('CALENDAR_SHARING_SETTING') }}
            </div>
            <div class="w-80">
              <div class="flex items-center justify-between gap-3 mb-3">
                <div>{{ $t('COMMON_LABEL_STATUS') }}</div>
                <div class="flex items-center gap-2">
                  <CalendarSharingStatus :sharing="sharingInfo" />
                  <AtomSwitch
                    :model-value="
                      sharingInfo.status === CalendarSharingStatusEnum.Sharing
                    "
                    @update:model-value="
                      sharingInfo.status = $event
                        ? CalendarSharingStatusEnum.Sharing
                        : CalendarSharingStatusEnum.NotSharing
                    "
                  />
                </div>
              </div>
              <div class="flex items-center justify-between gap-3 mb-2">
                <div>{{ $t('DOMAIN_LABEL_START_DATE') }}</div>
                <div class="w-36">
                  <AtomDatePicker v-model="sharingInfo.validFrom" />
                </div>
              </div>
              <div class="flex items-center justify-between gap-3 mb-2">
                <div>{{ $t('DOMAIN_LABEL_END_DATE') }}</div>
                <div class="w-36">
                  <AtomDatePicker v-model="sharingInfo.validTo" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--EVENTS LIST-->
        <div class="flex-1 overflow-hidden relative">
          <div
            v-if="events?.length"
            class="max-h-[25rem] relative overflow-auto small-scrollbar"
          >
            <table
              class="w-full table-auto border-separate"
              style="border-spacing: 0 0.25rem"
            >
              <thead class="sticky top-0 bg-white z-[1]">
                <tr>
                  <td class="w-0">
                    <label class="block px-2 py-1">
                      <VigCheckbox
                        v-model="isSelectedAllEvents"
                        :disabled="!isSharingSpecific"
                        @on-change="onEventSelectedAllChange"
                      />
                    </label>
                  </td>
                  <td class="px-2 py-1" colspan="5">
                    <div class="flex items-center justify-between gap-3">
                      <div class="font-semibold">
                        {{
                          $t('CALENDAR_SHARING_NUMBER_EVENTS', {
                            number:
                              selectedEvents?.length +
                              (selectedEvents?.length !== events?.length
                                ? `/${events?.length}`
                                : ''),
                          })
                        }}
                      </div>
                      <AtomSwitch
                        v-model="isSharingSpecific"
                        :label="$t('CALENDAR_SHARING_SPECIFIC_EVENTS_SELECT')"
                        @change="onEventSpecificToggle"
                      />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="event in events"
                  :key="event?.id"
                  class="bg-gray-50 text-sm"
                >
                  <td class="w-0">
                    <label class="block px-2 py-1">
                      <VigCheckbox
                        v-model="selectedEventById[event?.id]"
                        :disabled="!isSharingSpecific"
                        @on-change="onEventSelectedChange"
                      />
                    </label>
                  </td>
                  <td class="px-2 py-1 w-0">
                    <CalendarEventType
                      :event="event"
                      hide-label
                      icon-size="w-4 h-4"
                    />
                  </td>
                  <td class="px-2 py-1 w-0 text-nowrap">
                    <CalendarEventDate :event="event" />
                  </td>
                  <td class="px-2 py-1">
                    {{
                      event?.type === CalendarType.DayOff
                        ? getDayOffTitle(
                            event?.dayOff?.type,
                            event?.dayOff?.exceptionType
                          )
                        : event?.title
                    }}
                  </td>
                  <td class="px-2 py-1"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="italic">
            {{ $t('CALENDAR_SHARING_NO_EVENTS') }}
          </div>
          <div
            v-if="isLoadingEvents"
            class="
              absolute
              top-0
              left-0
              right-0
              bottom-0
              bg-white bg-opacity-50
              z-[1]
            "
          >
            <VigLoading class="h-full" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="px-4 py-2 flex items-center justify-end gap-2">
        <VigButton
          ghost
          color="gray"
          label="COMMON_LABEL_CANCEL"
          @click="$emit('onClose')"
        />
        <VigButton
          ref="buttonSave"
          label="COMMON_LABEL_SAVE"
          @click="onSaveSharingClick($refs.buttonSave)"
        />
      </div>
    </template>
  </SynModal>
</template>

<style scoped></style>
