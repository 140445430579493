import TaskService from '@/application/services/task/TaskService';
import { IUserDay } from '@/application/types/task/task.types';
import { computed, ref } from 'vue';

export default function useUpdateDeadline() {
    const userDayInfo = ref<IUserDay[]>([]);
    const useDayByScheduleOptionKey = computed<any>(() => {
        if (!userDayInfo.value || userDayInfo.value?.length == 0) return {};
        return userDayInfo.value.reduce((currentResult, currentItem) => {
            return {
                ...currentResult,
                [currentItem?.scheduleOptionKey]: currentItem,
            };
        }, {});
    });
    const getUserDay = async (userId) => {
        const res = await TaskService.getInstance().getUserTaskSchedule(userId);
        userDayInfo.value = res?.result;
    };

    return {
        userDayInfo,
        useDayByScheduleOptionKey,
        getUserDay,
    };
}
