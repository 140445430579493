<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { COLOR_LIST } from '@/application/constants/report/report.const';

const props = defineProps<{
  chartData: {
    total: number;
    id: number;
    name: string;
    avatar: string;
  }[];
  options?: any;
  genTooltipFunction?: any;
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    total: number;
    id: number;
    name: string;
    avatar: string;
    color: string;
    percent: number;
  }[]
>([]);

const initComponent = async () => {
  isLoading.value = true;

  const dataList = props.chartData?.filter((o) => o.total > 0);
  const colorByIds: any = [
    ...(arrayOrderBy(dataList, ['total'], ['desc']) || []),
  ]?.reduce((currentObj: any, currentItem: any, currentIndex: number) => {
    return {
      ...currentObj,
      [currentItem?.id]: COLOR_LIST[currentIndex],
    };
  }, {});

  const maxTotalValue = Math.max(...dataList?.map((o) => o?.total));

  resultData.value = dataList?.map((o) => {
    return {
      ...o,
      color: colorByIds[o?.id],
      percent:
        o.total && maxTotalValue
          ? Math.floor((o.total / maxTotalValue) * 100)
          : 0,
    };
  });

  isLoading.value = false;
};

watch(
  () => props.chartData,
  () => {
    // initComponent();
  }
);

const hasData = computed<boolean>(() => {
  return resultData.value?.length > 0;
});

initComponent();
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <div
    v-else
    class="
      flex-1
      w-full
      h-full
      flex-center flex-col
      overflow-x-auto overflow-y-hidden
      small-scrollbar
    "
  >
    <div class="flex-1 w-full h-full relative flex-center flex-col">
      <div class="flex-1 w-full h-full flex justify-around items-stretch">
        <div
          v-for="(item, index) in resultData"
          :key="index"
          class="flex-center flex-col gap-4"
        >
          <div class="flex-1">
            <AtomProcess
              :percent="item.percent"
              :color="item.color || '#b1dcde'"
              class="w-3"
              type="PORTRAIT"
              :tooltip-content="
                genTooltipFunction && typeof genTooltipFunction == 'function'
                  ? genTooltipFunction(item)
                  : ''
              "
            />
          </div>
          <div class="w-10 h-10 flex-center">
            <UserByAction
              v-if="item.id"
              :user-id="item?.id"
              is-show-action-user
              avatar-class="w-9 h-9"
              is-hidden-name
            />
          </div>
        </div>
      </div>
      <div v-if="!hasData" class="w-full h-full flex-center">
        <VigImage
          path="https://d1tvqetrcurhzb.cloudfront.net/web/charts/lollipop-chart.png"
          object-fit="contain"
        />
      </div>
    </div>
  </div>
</template>
