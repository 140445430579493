<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 176">
    <path
      class="cls-1"
      style="fill: #008e8e"
      d="M1062,433.16l-36.84-40.82A45.31,45.31,0,0,1,1004,377.16a44.92,44.92,0,0,1-9-26,47.23,47.23,0,0,1,5.95-25.07,43.83,43.83,0,0,1,14.79-15.73c.26-.17.54-.33.8-.49l.49-.3a38.62,38.62,0,0,1,16-5.41c17.55-1.85,29.69,8.87,32,11a43.17,43.17,0,0,1,13,24l39.7,37.71a90.68,90.68,0,0,0,4.3-27.71c0-48.6-38-88-85-88s-85,39.4-85,88,38.06,88,85,88a93.07,93.07,0,0,0,18.25-2C1058.16,434.42,1060.51,433.66,1062,433.16Z"
      transform="translate(-951.96 -261.16)"
    />
    <path
      class="cls-1"
      style="fill: #008e8e"
      d="M1078,339.16a45.32,45.32,0,0,1,1,10c0,3.71-.72,21.32-15,34a44,44,0,0,1-27,11c-1.61,0-3.25-.13-3.25-.13a40.22,40.22,0,0,1-8.59-1.69L1062,433.16a89.56,89.56,0,0,0,35.94-22.66,88.5,88.5,0,0,0,19.76-33.63Z"
      transform="translate(-951.96 -261.16)"
    />
    <path
      class="cls-2"
      style="fill: #005c5c; opacity: 0.27"
      d="M1076,331.16a49.59,49.59,0,0,1,3,18c-.08,4.14-.57,18-11,30a47.36,47.36,0,0,1-17,12,36.54,36.54,0,0,1-14,3c-.28,0-1.61,0-3.25-.13a43.86,43.86,0,0,1-17.75-5.87l38,47a84.8,84.8,0,0,0,65-64Z"
      transform="translate(-951.96 -261.16)"
    />
    <path
      class="cls-3"
      style="fill: #005c5c; opacity: 0.18"
      d="M1079,347.16a47.49,47.49,0,0,1-1,12,46.5,46.5,0,0,1-6,15c-.81,1.28-11.47,17.53-31,20a40,40,0,0,1-10,0l34,38a89.52,89.52,0,0,0,34-23,88.6,88.6,0,0,0,17-27Z"
      transform="translate(-951.96 -261.16)"
    />
    <path
      class="cls-4"
      style="fill: #f2cb05"
      d="M994,350a42.62,42.62,0,0,0,1,10,40.61,40.61,0,0,0,5,12,49.24,49.24,0,0,0,14,15,44.09,44.09,0,0,0,7,4,35.19,35.19,0,0,0,20,3,43.43,43.43,0,0,0,19-7,44,44,0,0,0,16-20,47.76,47.76,0,0,0,2-30,46.45,46.45,0,0,0-9-18,43.55,43.55,0,0,0-22-14,41.71,41.71,0,0,0-24,1c-15.4,5.44-22.16,18.35-24,22A49.06,49.06,0,0,0,994,350Z"
      transform="translate(-951.96 -261.16)"
    />
  </svg>
</template>
