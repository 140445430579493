import { defineStore } from 'pinia';
import CatalogService from '@/application/services/CatalogService';
import storeId from '@/base/store/storeId';

import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { SettingProgressEnum } from '@/ui/common/constants/constant';
import DefaultValue from '@/store/organization/default-value';
import { IOrganizationDetail } from '@/application/types/organization/organization.type';
import permissionStore from '@/store/permission';
import plansStore from '@/store/plans';
import { IOrganizationCategory } from '@/application/types/common/register.types';
import { orderBy } from 'lodash';

const mapTimezoneByOffset = {
    '+7': 'Asia/Ho_Chi_Minh',
    '+1': 'Europe/Paris',
    '+2': 'Europe/Paris',
    '-5': 'America/New_York',
};

export default defineStore({
    id: storeId.organization,
    state: () => ({
        _catalogService: CatalogService.getInstance(),
        isLoadingOrg: false,
        attachmentCode: '',
        organizationModels: DefaultValue.organizationModels,
        organizationTypes: DefaultValue.organizationTypes,
        organizationConfigs: DefaultValue.organizationConfigs,
        organizationCategories:
            DefaultValue.organizationCategories as IOrganizationCategory[],
        jobTitles: DefaultValue.jobTitles,
        draftedData: [],
        fileName: '',
        organizationInfo: {} as IOrganizationDetail,
        _pendingUsers: [] as any[],
        isInstallMobileApp: false,
        organizationIds: [] as any[],
        organizationByIds: {} as any,
        allTimezones: [] as string[],
    }),
    getters: {
        draftedDataLength(state): number {
            return state.draftedData?.length;
        },

        pendingUsers(state): any[] {
            return state._pendingUsers ?? [];
        },
    },
    actions: {
        async getOrganizationConfigDefault() {
            const res =
                await this._catalogService.getOrganizationConfigDefault();
            const data = res['result'];
            if (data) {
                if (data['organizationConfigs'])
                    this.organizationConfigs = data['organizationConfigs'];
                if (data['organizationModels'])
                    this.organizationModels = data['organizationModels'];
                if (data['organizationTypes'])
                    this.organizationTypes = data['organizationTypes'];

                if (data['jobTitles']) this.jobTitles = data['jobTitles'];

                const organizationCategories = data?.organizationCategories;
                if (organizationCategories) {
                    this.organizationCategories = orderBy(
                        organizationCategories?.filter(
                            (category) => !category?.parentId
                        ),
                        'index',
                        'asc'
                    ).map((category) => {
                        return {
                            ...category,
                            children: organizationCategories?.filter(
                                (item) => item?.parentId == category?.id
                            ),
                        };
                    });
                }
            }
        },

        async getCheckCode(code) {
            const res = await this._catalogService.getCheckCode(code);
            return res['result'];
        },

        async getCheckEmailPhone(value) {
            const res = await this._catalogService.getCheckEmailPhone(value);
            return res['result'];
        },

        async checkEmailPhoneInOrganization(value) {
            const res =
                await this._catalogService.checkEmailPhoneInOrganization(value);
            return res['result'];
        },

        saveOrganizationSetting(payload) {
            return this._catalogService.saveOrganizationSetting(payload);
        },

        registerNewOrganization(payload, autoRedirect?: boolean) {
            return this._catalogService.registerNewOrganization(
                payload,
                autoRedirect
            );
        },

        saveTeam(payload) {
            return this._catalogService.saveTeam(payload);
        },

        saveTeamDraft(payload) {
            return this._catalogService.saveTeamDraft(payload);
        },

        async getDraftTeam() {
            const res = await this._catalogService.getDraftTeam();
            this.draftedData = res['result'];
            return res['result'];
        },

        getUserProfile() {
            return this._catalogService.getUserProfile();
        },

        async getOrganizationInfo(id?: string) {
            const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
            const orgId = id || organizationId;
            const res = await this._catalogService.getOrganizationInfo(orgId);
            this.organizationInfo = res?.result;

            this.allTimezones = this.organizationInfo?.members?.reduce(
                (currentList: string[], currentMember) => {
                    if (!currentMember?.utc) return currentList;
                    const currentTimezone =
                        mapTimezoneByOffset[currentMember?.utc];
                    const existed = currentList?.includes(currentTimezone);
                    if (existed) return currentList;

                    return [...currentList, currentTimezone];
                },
                []
            );

            permissionStore().getPermissionsByModel(
                this.organizationInfo?.model
            );
            // permissionStore().getPermissionsByModel('PERSONAL');
            return res?.result;
        },

        async updateOrganization(data: any) {
            const res = await this._catalogService.updateOrganization(data);
            if (res?.result) {
                this.organizationInfo = res.result;
            }
            return res?.result;
        },

        async uploadAvatarAws(file): Promise<any> {
            const res = await this._catalogService.uploadAvatarAws(file);
            this.fileName = res?.result.path;
            return res?.result.path;
        },

        skipAddMember(): Promise<any> {
            return this._catalogService.skipAddMember();
        },

        inviteUser(data): Promise<any> {
            return this._catalogService.inviteUser(data);
        },

        getInvitedUser(params): Promise<any> {
            return this._catalogService.getInvitedUser(params);
        },

        acceptedInvitation(data): Promise<any> {
            return this._catalogService.acceptedInvitation(data, {}, '');
        },

        attachOrganizationByCode(data): Promise<any> {
            return this._catalogService.attachOrganizationByCode(data);
        },

        checkOrganizationAttachCode(data): Promise<any> {
            return this._catalogService.checkOrganizationAttachCode(data);
        },

        async getPendingUsers(): Promise<any[]> {
            const res = await this._catalogService.getPendingUsers();
            const convertToLocalDate = (utcDate) => {
                if (!utcDate) return utcDate;
                if (!utcDate.includes('Z')) {
                    utcDate = utcDate + 'Z';
                }
                return new Date(utcDate);
            };
            this._pendingUsers = (res['result'] || [])?.sort(
                (a, b) =>
                    convertToLocalDate(b?.invitedTime) -
                    convertToLocalDate(a?.invitedTime)
            );
            return this._pendingUsers;
        },

        async updateInvitations(data): Promise<any> {
            const res = await this._catalogService.updateInvitations(data);
            plansStore().getAllPlanCapacities();
            return res['result'];
        },

        async getOrganizationAttachmentCode(): Promise<any[]> {
            const res =
                await this._catalogService.getOrganizationAttachmentCode();
            this.attachmentCode = res['result'] || '';
            return res['result'] || '';
        },

        async generateOrganizationAttachmentCode(): Promise<any[]> {
            const res =
                await this._catalogService.generateOrganizationAttachmentCode();
            this.attachmentCode = res['result'] || '';
            return res['result'] || '';
        },

        async resendInvitation(data): Promise<any> {
            await this._catalogService.resendInvitation(data);
            // this.getPendingUsers();
            plansStore().getAllPlanCapacities();
        },

        async cancelInvitation(data): Promise<any> {
            await this._catalogService.cancelInvitation(data);
            plansStore().getAllPlanCapacities();
        },

        getMyPendingInvitation(): Promise<any> {
            return this._catalogService.getMyPendingInvitation();
        },

        async checkUserInstallApp(): Promise<any> {
            const res = await this._catalogService.checkUserInstallApp();
            this.isInstallMobileApp = res['result']?.isInstallApp || false;
            return res['result'];
        },

        updateCurrentOrganizationInfo() {
            const currentOrg =
                this.organizationByIds[this.organizationInfo?.id];
            if (!currentOrg?.id) return;
            this.organizationInfo = {
                ...this.organizationInfo,
                members: currentOrg?.members,
                joinedDate: currentOrg?.joinedDate,
            };
        },

        async getUserOrganizations(): Promise<any> {
            this.isLoadingOrg = true;

            try {
                const res = await this._catalogService.getUserOrganizations();

                const allOrganizations = res?.result;

                const newSet = new Set(allOrganizations.map((org) => org.id));
                this.organizationIds = [...newSet];
                this.organizationByIds = allOrganizations.reduce(
                    (old, current) => {
                        return {
                            ...old,
                            [current?.id]: current,
                        };
                    },
                    {}
                );
                this.updateCurrentOrganizationInfo();

                this.isLoadingOrg = false;
                return res?.result;
            } catch (e) {
                this.isLoadingOrg = false;
            }
        },

        onFinishWithPersonalMode(): Promise<any> {
            return this._catalogService.onFinishWithPersonalMode();
        },

        updateDecideTime(payload: number): Promise<any> {
            return this._catalogService.updateDecideTime(payload);
        },
        async updateAndFinishSetupOrganization(
            payload,
            isRegister?
        ): Promise<any> {
            const res =
                await this._catalogService.updateAndFinishSetupOrganization({
                    ...this.organizationInfo,
                    ...payload,
                });
            if (!isRegister) {
                this.getOrganizationInfo();

                MyOrganizationSingleton.setOrganizationSettingProgress(
                    SettingProgressEnum.SettingFinished
                );
            }

            return res;
        },

        updateListOrganizationById(organizationId, data) {
            this.organizationByIds = {
                ...this.organizationByIds,
                [organizationId]: {
                    ...this.organizationByIds[organizationId],
                    ...data,
                },
            };
        },
    },
});
