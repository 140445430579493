<script setup lang="ts">
import { onMounted, ref } from 'vue';

// *** PROPS, EMITS ***
const props = defineProps<{
  position?: {
    latitude?: number;
    longitude?: number;
  };
}>();

// *** PRIVATE VARIABLES ***
let _map, _marker;

// *** COMPOSABLES ***

// *** REFS ***
const mapRef = ref();

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***
onMounted(() => {
  _initMap();
  _locatePositionOnMap(props.position);
});

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***
const _initMap = () => {
  const defaultCenter = { lat: 21.0285, lng: 105.8542 };

  _map = new window.google.maps.Map(mapRef.value, {
    center: defaultCenter,
    zoom: 15,
  });

  _createMarkerOnMap(defaultCenter);
};

const _createMarkerOnMap = (position) => {
  _marker?.setMap(null);

  if (!_map) return;

  _marker = new window.google.maps.Marker({
    position,
    map: _map,
  });
};

const _locatePositionOnMap = (position) => {
  _marker?.setMap(null);

  if (!position?.latitude || !_map) return;

  const coords = {
    lat: position.latitude,
    lng: position.longitude,
  };

  _createMarkerOnMap(coords);

  _map.setCenter(coords);
};

// *** EXPOSES ***
</script>

<template>
  <div ref="mapRef" class="border rounded bg-gray-200"></div>
</template>

<style scoped></style>
