<script setup lang="ts">
import {
  computed,
  onMounted,
  watch,
  // computed
} from 'vue';
import Chart from 'chart.js/auto';
import { useDocumentVisibility } from '@vueuse/core';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { roundToNearestMultipleOfFive } from '@/ui/hooks/commonFunction';
// import router from '@/ui/router';
// import appStore from '@/store/app';

const props = withDefaults(
  defineProps<{
    customClass?: string;
    labels?: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      hoverBackgroundColor: string;
      minBarLength: number;
    }[];
    options?: any;
    isHiddenNoteY?: boolean;
  }>(),
  {
    customClass: '',
    labels: () => [],
    options: () => {},
  }
);

let verticalBarChart: Chart;

const visibility = useDocumentVisibility();
watch(
  () => visibility.value,
  () => {
    if (verticalBarChart && typeof verticalBarChart?.update == 'function')
      verticalBarChart?.update();
  }
);
// const _appStore = appStore();
// const language = computed(() => _appStore.language);

// const customYScales = (value, index) => {
// if (router.currentRoute.value.fullPath?.includes('dashboard/monitor')) {

// }
// };

const canvas_id = `tictop-common-vertical-bar-chart_${Math.random() * 100}`;
const chartOptions = computed(() => {
  const maxValue = Math.max(
    ...props.datasets?.reduce((currentResult, data) => {
      return [...currentResult, ...(data?.data || [])];
    }, [])
  );

  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        border: {
          display: false,
        },
        grid: {
          color: function () {
            return '#e5e7eb';
          },
          drawBorder: false,
        },

        min: 0,
        suggestedMax: roundToNearestMultipleOfFive(maxValue + maxValue * 0.1, {
          min: maxValue + 1,
          isRoundUp: true,
        }),
        grace: '5%',
        ticks: {
          callback: (tickValue) => {
            if (Number.isInteger(tickValue)) return tickValue;
          },
        },
      },
      x: {
        offset: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        // displayColors: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: '#6b7280',
        bodyColor: '#6b7280',
        borderWidth: 1,
        borderColor: '#e5e7eb',
        callbacks: {
          label: (tooltipItems) => {
            if (tooltipItems?.dataset?.key == 'totalTasks')
              return (
                tooltipItems?.formattedValue +
                ' ' +
                translate('LABEL_TOTAL_TASK')?.toLowerCase()
              );

            return (
              tooltipItems?.formattedValue +
              ' ' +
              (
                translate(tooltipItems?.dataset?.label) ||
                tooltipItems?.dataset?.label
              )?.toLowerCase()
            );
          },
        },
      },
    },
  };
});
const initData = () => {
  const labels = [
    'COMMON_LABEL_MONDAY_VERTICAL_CHART',
    'COMMON_LABEL_TUESDAY_VERTICAL_CHART',
    'COMMON_LABEL_WEDNESDAY_VERTICAL_CHART',
    'COMMON_LABEL_THURSDAY_VERTICAL_CHART',
    'COMMON_LABEL_FRIDAY_VERTICAL_CHART',
    'COMMON_LABEL_SATURDAY_VERTICAL_CHART',
  ];
  const labelLanguages = labels.map((e) => translate(e));
  const data = {
    labels: props.labels || labelLanguages,
    datasets: props.datasets,
  };
  const config = {
    type: 'bar',
    data: data,
    options: {
      ...chartOptions.value,
      ...props.options,
    },
  };

  const ctx = document.getElementById(canvas_id) as HTMLCanvasElement;

  if (!ctx) return;

  verticalBarChart = new Chart(ctx, config);
};

onMounted(() => {
  initData();
});

watch(
  () => props.datasets,
  () => {
    verticalBarChart.data.datasets = props.datasets;
    // verticalLineChart.data.datasets
    verticalBarChart?.update();
  },
  { deep: true }
);

const hasData = computed<boolean>(() => {
  return props.datasets?.some((dataset) => {
    return dataset?.data?.length > 0;
  });
});
</script>
<template>
  <div class="flex-1 w-full h-full flex-center flex-col gap-4 p-4">
    <div class="flex-1 w-full h-full flex-center relative">
      <div v-if="!isHiddenNoteY" class="absolute -left-4 w-0 flex-center">
        <span>
          <p class="w-max break-keep -rotate-90 text-gray-500 text-xs">
            {{ $t('COMMON_LABEL_TASK_COUNT') }}
          </p>
        </span>
      </div>

      <div class="w-full h-full flex-center space-x-4">
        <div class="w-full h-full relative" :class="customClass">
          <canvas
            v-show="hasData"
            :id="canvas_id"
            class="w-full h-full border-0"
          ></canvas>

          <div v-if="!hasData" class="w-full h-full flex-center">
            <VigImage
              path="https://d1tvqetrcurhzb.cloudfront.net/web/charts/column-chart.png"
              object-fit="contain"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white flex-center gap-2">
      <div v-for="(item, index) in datasets" :key="index" class="flex-center">
        <div class="flex-center gap-1">
          <span
            class="w-4 border-2"
            :style="{
              borderColor: item?.backgroundColor,
            }"
          ></span>
          <span class="text-xs text-gray-500">
            {{ $t(item?.label) || item?.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
