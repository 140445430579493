import { getDatabase, onValue, ref } from 'firebase/database';

class OrgDynamicInfoRepository {
    subscribeGroupActivity(orgId: number, cb: (val) => void) {
        return onValue(this._getRef(orgId, 'latestGroupActivity'), (snapshot) =>
            cb(snapshot?.val())
        );
    }

    subscribeOrgInfoActivity(orgId: number, cb: (val) => void) {
        return onValue(
            this._getRef(orgId, 'latestInformationChanges'),
            (snapshot) => cb(snapshot?.val())
        );
    }

    private _getRef(orgId: number, path: string) {
        return ref(
            getDatabase(),
            `organizations_dynamic_info/organization_${orgId}/${path}`
        );
    }
}

export default new OrgDynamicInfoRepository();
