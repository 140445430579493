import BaseRepository from '@/base/repositories/BaseRepository';

class WhiteboardRepository {
    constructor() {}

    getAllByUser() {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/GetAllByUser`,
            method: 'get',
        });
    }
    getWhiteboardDetail(whiteboardId) {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/GetWhiteboardDetail`,
            method: 'get',
            params: {
                id: whiteboardId,
            },
        });
    }
    createWhiteboard(data: ICreateWhiteboardInput) {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/CreateWhiteboard`,
            method: 'post',
            data,
        });
    }
    updateWhiteboard(data: ICreateWhiteboardInput) {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/UpdateWhiteboard`,
            method: 'put',
            data,
        });
    }
    updateWhiteboardTitle(id, title) {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/UpdateWhiteboardTitle`,
            method: 'put',
            data: {
                id,
                title,
            },
        });
    }
    deleteWhiteboard(whiteboardId) {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/DeleteWhiteboard`,
            method: 'delete',
            params: {
                whiteboardId,
            },
        });
    }
    addDeletedShapeToBin(data: ICreateWhiteboardShapeInput) {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/AddDeletedShapeToBin`,
            method: 'post',
            data,
        });
    }
    getAllRecycleShapeByUser() {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/GetAllRecycleShapeByUser`,
            method: 'get',
        });
    }
    deleteRecycleShape(recycleShapeId) {
        return BaseRepository.requestWithAuthorize({
            url: `Whiteboard/DeleteRecycleShape`,
            method: 'delete',
            params: {
                recycleShapeId,
            },
        });
    }
}

export default new WhiteboardRepository();

export interface ICreateWhiteboardInput {
    id?: string;
    title: string;
    maxIndex: number;
    shapeList: ICreateWhiteboardShapeInput[];
}

export interface ICreateWhiteboardShapeInput {
    id: string;
    type: string;
    translateX: number;
    translateY: number;
    zIndex: number;
    width: number;
    height: number;
    sourceId?: string;
    sourceData?: string;
}
