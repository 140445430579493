<template>
  <vig-dropdown>
    <template #dropdown-toggle>
      <div class="w-8 h-8 flex-center">
        <SynIcon has-action color="gray" name="dots-vertical" />
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="py-1 z-100 list-ul rounded cursor-pointer dropdown-item"
        style="min-width: 11rem"
      >
        <slot />
      </div>
    </template>
  </vig-dropdown>
</template>
