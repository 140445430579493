<script setup lang="ts">
import { computed, ref } from 'vue';
import { ETaskListModule } from '@/application/types/task/task.types';
import UnscheduleTasksDrawer from '@/ui/pages/tasks/calendar/UnscheduleTasksDrawer.vue';
import { isOpenDrawer } from '@/ui/pages/tasks/calendar/unschedule-tasks-drawer-state';

import TaskTimeBlockingSingle from '@/ui/pages/tasks/calendar/TaskTimeBlockingSingle.vue';
// import taskTabViewStore from '@/store/task/task-tab-view';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
  onCreateGlobalSuccess,
} from '@/ui/modules/task/task-global-state';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import principleStore from '@/store/principle';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import taskDrawerStore from '@/store/task/drawer';
import myProfileStore from '@/store/auth/my-profile';

import planTaskCalendarByOwnerComposables from '@/ui/pages/tasks/calendar/plan-task-calendar-by-owner.composables';

const props = defineProps<{
  ownerId: number | string;
  sourceType: ETaskListModule;
  location: 'MY_TASKS' | 'USER_TASKS' | 'GROUP_TASKS' | 'DOMAIN_TASKS';
}>();

const myTimeZone = computed<any>(() => {
  return myProfileStore().timezone;
});
const timeBlockingScreenCode = computed<string>(() => {
  if (props.location == 'MY_TASKS')
    return ALL_SCREENS.LOGIN_USER.TASK.TIME_BLOCKING;
  if (props.location == 'GROUP_TASKS')
    return ALL_SCREENS.GROUP.TASK.TIME_BLOCKING;
  if (props.location == 'DOMAIN_TASKS')
    return ALL_SCREENS.DOMAIN.TASK.TIME_BLOCKING;
  return ALL_SCREENS.USER.TASK.COLUMN;
});

const FULL_CALENDAR_VIEW_MODE = {
  DAY_GRID_MONTH: 'dayGridMonth',
  DAY_GRID_WEEK: 'dayGridWeek',
  DAY_GRID_DAY: 'timeGridDay',
  LIST_WEEK: 'listWeek',
};
const VIEW_MODE_LIST = {
  [FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY]: {
    key: FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY,
    nameCode: 'TASK_LABEL_TODAY_S_PLAN',
  },
  [FULL_CALENDAR_VIEW_MODE.DAY_GRID_MONTH]: {
    key: FULL_CALENDAR_VIEW_MODE.DAY_GRID_MONTH,
    nameCode: 'GANTT_VIEW_BY_MONTH',
  },
  [FULL_CALENDAR_VIEW_MODE.DAY_GRID_WEEK]: {
    key: FULL_CALENDAR_VIEW_MODE.DAY_GRID_WEEK,
    nameCode: 'GANTT_VIEW_BY_WEEK',
  },

  // [FULL_CALENDAR_VIEW_MODE.LIST_WEEK]: {
  //   key: FULL_CALENDAR_VIEW_MODE.LIST_WEEK,
  //   nameCode: 'CALENDAR_TITLE_LIST',
  // },
};

const {
  currentViewMode,
  isTimeBlockingMode,
  planTodayTasks,
  taskTimeBlockingSingleRef,
  isLoadingChart,
  unscheduleTasks,
  onChangeViewMode,
  getDefaultTaskList,
  changeStartEndTime,
} = planTaskCalendarByOwnerComposables({
  sourceType: props.sourceType,
  listOwnerId: props.ownerId,
  isHasDefaultList: true,
});

const viewModeList = computed<any>(() => {
  return Object.values(VIEW_MODE_LIST)?.filter(
    (item) => item?.key !== currentViewMode.value
  );
});

const onPrevious = () => {
  if (
    taskTimeBlockingSingleRef.value &&
    typeof taskTimeBlockingSingleRef.value?.onPrevious == 'function'
  )
    taskTimeBlockingSingleRef.value.onPrevious();
};
const onNext = () => {
  if (
    taskTimeBlockingSingleRef.value &&
    typeof taskTimeBlockingSingleRef.value?.onNext == 'function'
  )
    taskTimeBlockingSingleRef.value.onNext();
};
const gotoToday = () => {
  if (
    taskTimeBlockingSingleRef.value &&
    typeof taskTimeBlockingSingleRef.value?.gotoToday == 'function'
  )
    taskTimeBlockingSingleRef.value.gotoToday();
};

const isShowWeekends = ref<any>(true);

const toggleShowWeekends = (key) => {
  if (
    taskTimeBlockingSingleRef.value &&
    typeof taskTimeBlockingSingleRef.value?.toggleShowWeekends == 'function'
  )
    taskTimeBlockingSingleRef.value.toggleShowWeekends(key);
};
const gotoFirstData = (key) => {
  if (
    taskTimeBlockingSingleRef.value &&
    typeof taskTimeBlockingSingleRef.value?.gotoFirstData == 'function'
  )
    taskTimeBlockingSingleRef.value.gotoFirstData(key);
};

const isCollapse = ref<boolean>(false);

const toggleTaskListDrawer = () => {
  isOpenDrawer.value = !isOpenDrawer.value;
  if (isOpenDrawer.value && isTimeBlockingMode.value) getDefaultTaskList();
};

const viewTitle = ref<string>('');

const generateNewTaskPayload = (scheduleTime) => {
  if (props.sourceType == ETaskListModule.DOMAIN)
    return {
      domainId: parseInt(props.ownerId?.toString()) || 1,
      scheduleTime,
    };

  if (props.sourceType == ETaskListModule.GROUP)
    return {
      groupId: parseInt(props.ownerId?.toString()) || 1,
      scheduleTime,
    };

  return {
    assigneeId: parseInt(props.ownerId?.toString()) || getCurrentUserId(),
    scheduleTime,
  };
};
const onClickAddButton = (dateKey) => {
  newTaskDefaultPayload.value = generateNewTaskPayload(dateKey);

  onCreateGlobalSuccess.value = (data) => {
    if (data?.domainId !== props.ownerId) return;
  };

  isOpenCreateTaskDrawer.value = true;
};

const isStarting = computed(() => principleStore().isStarting);

const openTaskDetail = (task) => {
  taskDrawerStore().pushCurrentIds({
    id: task.id,
    name: task.name,
    code: task.code,
    tabType: 'DETAIL',
  });
};
</script>

<template>
  <div
    v-if="!isStarting"
    class="
      flex-1
      min-h-0
      w-full
      h-full
      flex flex-col
      relative
      gap-2
      task-calendar-view
    "
    @click="isOpenDrawer = false"
  >
    <div
      v-if="isLoadingChart"
      class="w-full h-full z-100 absolute top-0 left-0 cursor-wait"
    ></div>
    <div class="flex items-center justify-between px-4">
      <div class="flex-center gap-2">
        <template v-if="!isTimeBlockingMode">
          <span
            class="
              h-8
              w-8
              hover:bg-current-50
              cursor-pointer
              rounded-full
              flex-center
            "
            @click="onPrevious"
          >
            <SynIcon name="previous" custom-class="h-3 w-3" />
          </span>
          <span
            class="
              h-8
              w-8
              hover:bg-current-50
              cursor-pointer
              rounded-full
              flex-center
            "
            @click="onNext"
          >
            <SynIcon name="next" custom-class="h-3 w-3" />
          </span>
        </template>
        <span class="capitalize font-semibold text-current-600">
          {{ viewTitle }}
        </span>
      </div>
      <div class="flex-center space-x-2">
        <span
          v-if="!isTimeBlockingMode"
          class="
            h-7
            flex-center
            px-2
            text-xs
            border
            rounded
            cursor-pointer
            hover:text-current-500
          "
          @click="gotoToday"
        >
          <span>
            {{ $t('COMMON_LABEL_TODAY') }}
          </span>
        </span>

        <VigDropdown
          v-model:is-open="isCollapse"
          placement="bottom-start"
          :arrow="false"
          class="hover:text-current bg-white shadow-none"
        >
          <template #dropdown-toggle>
            <div
              class="
                h-7
                flex-center
                gap-2
                border
                rounded
                cursor-pointer
                hover:text-current-500
                px-2
              "
            >
              <span class="flex-1 text-xs">
                {{ $t(VIEW_MODE_LIST[currentViewMode].nameCode) }}
              </span>
              <SynIcon
                name="ChevronDown2"
                custom-class="h-2.5 w-2.5"
                :class="isCollapse ? 'rotate-180' : ''"
              />
            </div>
          </template>
          <template #dropdown-menu>
            <SynDropdownMenu class="right-0 min-w-[11rem]">
              <div
                v-for="view in viewModeList"
                :key="view.key"
                class="dropdown-item list-li"
                @click="onChangeViewMode(view.key)"
              >
                <div class="flex items-center">
                  <span class="pl-2">
                    {{ $t(view.nameCode) }}
                  </span>
                </div>
              </div>
              <div v-if="!isTimeBlockingMode" class="dropdown-item list-li">
                <div class="flex items-center justify-between w-full">
                  <span class="pl-2">
                    {{ $t('TASK_LABEL_SHOW_WEEKENDS') }}
                  </span>
                  <AtomSwitch
                    v-model="isShowWeekends"
                    size="small"
                    @change="toggleShowWeekends"
                  />
                </div>
              </div>
            </SynDropdownMenu>
          </template>
        </VigDropdown>
        <div class="flex-center">
          <div class="h-5 border-l-2"></div>
        </div>

        <span
          class="
            text-xs
            flex-center
            space-x-1
            rounded
            py-1
            px-2
            border
            text-end
            cursor-pointer
            hover:text-current-500
          "
          @click="gotoFirstData"
        >
          {{ `${$t('COMMON_LABEL_TOTAL')} : ${planTodayTasks?.length || 0}` }}
        </span>
        <div
          class="
            h-7
            flex-center
            border
            rounded
            cursor-pointer
            hover:text-current-500
          "
          @click.stop="toggleTaskListDrawer"
        >
          <span class="px-2 text-xs">
            {{
              $t(
                isTimeBlockingMode
                  ? 'COMMON_LABEL_TASK_LIST'
                  : 'COMMON_LABEL_UNSCHEDULED'
              )
            }}
            <span v-if="unscheduleTasks?.length > 0">
              : {{ unscheduleTasks?.length }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="!planTodayTasks"
      class="
        absolute
        top-0
        left-0
        w-full
        h-full
        flex
        items-center
        flex-col
        pt-24
      "
    >
      <VigImage
        :path="`https://d1tvqetrcurhzb.cloudfront.net/common/images/all-task-mylist.png`"
        :custom-class="`h-40 w-auto`"
      />
      <span
        class="text-current-700 pt-8"
        style="font-size: 1.15rem"
        v-html="$t('TASK_LIST_DONT_HAVE_ALL_TASK_TITLE')"
      ></span>
    </div>

    <div
      v-permission-screen="{
        screenCode: isTimeBlockingMode ? timeBlockingScreenCode : null,
        isListenUpdated: true,
      }"
      class="flex-1 min-h-0 h-full overflow-auto"
    >
      <TaskTimeBlockingSingle
        ref="taskTimeBlockingSingleRef"
        v-model:is-loading="isLoadingChart"
        v-model:current-view-mode="currentViewMode"
        v-model:view-title="viewTitle"
        :source-type="sourceType"
        :events="planTodayTasks"
        is-disable-init
        :time-zone="myTimeZone"
        @on-click-add-button="onClickAddButton"
        @change-start-end-time="changeStartEndTime"
        @event-click="openTaskDetail"
      />
    </div>

    <UnscheduleTasksDrawer
      :unschedule-tasks="unscheduleTasks"
      :title-key="
        isTimeBlockingMode
          ? 'TASK_LABEL_YOUR_TASK_LIST'
          : 'COMMON_LABEL_UNSCHEDULED_TASKS'
      "
    />
  </div>
</template>
<style lang="scss">
.date-td {
  @apply relative;
}
.my-custom-button {
  display: none;
  @apply absolute bottom-1 right-1 h-6 w-6 justify-center
    items-center rounded-full bg-current text-white z-10 pb-0.5;
}

.date-td:hover .my-custom-button {
  display: flex;
}

.fc-timegrid-col-events {
  width: 99%;
}
.fc .fc-timegrid-slot-minor {
  border-top: 0;
}
/* Customize the now indicator line */
.fc .fc-timegrid-now-indicator-line {
  border-color: #008e8e; /* Change the color of the line */
}
.fc .fc-timegrid-now-indicator-arrow {
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #008e8e;
  border-right-color: #008e8e;
  // border-width: 8px 0 8px 6px;
}

.fc-timeGridDay-view {
  .my-custom-button {
    display: none;
  }

  .date-td:hover .my-custom-button {
    display: none;
  }

  th[role='columnheader'] {
    border-bottom: 1px solid var(--fc-border-color, #ddd) !important;
  }
}

.fc-scroller::-webkit-scrollbar,
.fc-daygrid-day-events::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.fc-scroller::-webkit-scrollbar-thumb,
.fc-daygrid-day-events::-webkit-scrollbar-thumb {
  background: transparent;
}

.fc-scroller::-webkit-scrollbar-track,
.fc-daygrid-day-events::-webkit-scrollbar-track {
  background: transparent;
}

.fc-scroller:hover::-webkit-scrollbar-thumb,
.fc-daygrid-day-events:hover::-webkit-scrollbar-thumb {
  background: #888;
}

.fc-scroller:hover::-webkit-scrollbar-track,
.fc-daygrid-day-events:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.fc-scroller,
.fc-daygrid-day-events {
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.fc-scroller:hover,
.fc-daygrid-day-events:hover {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.fc .fc-timegrid-now-indicator-line {
  z-index: 2;
}
</style>
