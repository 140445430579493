<script setup lang="ts">
import { computed } from 'vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import TaskBoardCardDefault from '@/ui/modules/task/board/board-container/TaskBoardCardDefault.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';
import TaskBoardCardCustom from '@/ui/modules/task/board/board-container/TaskBoardCardCustom.vue';
import { EFilterTaskDefault } from '@/application/constants/task.const';
import { TaskListTab } from '@/domain/enums/taskEnum';
import taskListStore from '@/store/task/task-list-store';

const props = withDefaults(
  defineProps<{
    currentContactId: any;
    type: 'USER' | 'GROUP' | 'DOMAIN';
  }>(),
  {
    isShowAll: true,
    currentContactId: getCurrentUserId(),
    type: 'USER',
  }
);

const _taskTabViewStore = taskTabViewStore();
const _taskListStore = taskListStore();
const sourceType = computed<'U' | 'G' | 'D'>(() => {
  if (props.type == 'DOMAIN') return 'D';
  if (props.type == 'GROUP') return 'G';
  return 'U';
});
const activeTab = computed(() => _taskListStore.activeTab);
const listTabs = computed(() =>
  _taskTabViewStore.listTabs.filter((o) => {
    const isMyRelated =
      o?.id == EFilterTaskDefault.MY_RELATED &&
      (activeTab.value === TaskListTab.MyAssign || sourceType.value !== 'U');

    const isDefault = props?.isShowAll ? o : o?.isDefault;

    return !isMyRelated && isDefault;
  })
);
const listCustomTabs = computed<ICustomTaskFilter[]>(
  () => _taskTabViewStore.listCustomTabs
);
</script>

<template>
  <div
    id="task-board-by-default-container"
    v-drag-to-scroll="{ targetClass: 'drag-to-scroll-target' }"
    class="h-full flex"
  >
    <template v-for="card in listTabs" :key="card?.id">
      <TaskBoardCardDefault
        class="swiper-slide text-xs mx-1.5"
        :type="type"
        :card-id="card?.id"
        :tab-id="card?.value"
        :card-information="card"
        :source-id="currentContactId"
      />
    </template>
    <template v-if="activeTab === TaskListTab.MyList">
      <template v-for="card in listCustomTabs" :key="card?.id">
        <TaskBoardCardCustom
          class="swiper-slide text-xs mx-1.5"
          :type="type"
          :card-information="card"
          :source-id="currentContactId"
        />
      </template>
    </template>
  </div>
</template>
