import { defineStore } from 'pinia';
import TaskService from '@/application/services/task/TaskService';
import storeId from '@/base/store/storeId';
import { IWorkflowEntity } from '@/domain/entities/workflow/WorkflowEntity';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import { IWorkflowStepType } from '@/application/types/workflow/workflow.types';
import { stringNomalize } from '@/ui/hooks/commonFunction';

export default defineStore({
    id: storeId.WORKFLOW,
    state: () => ({
        _taskService: TaskService.getInstance(),
        allStepType: [] as IWorkflowStepType[],
        allWorkflowList: [] as IWorkflowEntity[],
        allWorkflowShortInfos: [] as { id: string; name: string }[],
        allWorkflowStepShortInfos: [] as {
            id: string;
            name: string;
            code: string;
        }[],
    }),
    getters: {
        allStepTypeByIds(state): any {
            return state?.allStepType?.reduce((currentResult, currentItem) => {
                return {
                    ...currentResult,
                    [currentItem?.id]: currentItem,
                };
            }, {});
        },
        allWorkflowShortInfoByIds(state): any {
            return state?.allWorkflowShortInfos?.reduce(
                (currentResult, currentItem) => {
                    return {
                        ...currentResult,
                        [currentItem?.id]: currentItem,
                    };
                },
                {}
            );
        },
        allWorkflowStepShortInfoByIds(state): any {
            return state?.allWorkflowStepShortInfos?.reduce(
                (currentResult, currentItem) => {
                    return {
                        ...currentResult,
                        [currentItem?.id]: currentItem,
                    };
                },
                {}
            );
        },
        allWorkflowByIds(state): any {
            return state?.allWorkflowList?.reduce(
                (currentResult, currentItem) => {
                    return {
                        ...currentResult,
                        [currentItem?.id]: currentItem,
                    };
                },
                {}
            );
        },
        allWorkflowByNames(state): any {
            return state?.allWorkflowList?.reduce(
                (currentResult, currentItem) => {
                    const name = stringNomalize(currentItem?.name)
                        ?.toUpperCase()
                        ?.replace(/\(\d+\)$/, '')
                        ?.trim()
                        .replace(/ +/g, '_');

                    if (!currentResult[name]) {
                        currentResult[name] = {
                            total: 1,
                            departmentId: currentItem?.departmentId,
                            domainId: currentItem?.domainId,
                            groupId: currentItem?.groupId,
                        };
                    } else {
                        currentResult[name] = {
                            ...currentResult[name],
                            total: currentResult[name]?.total + 1,
                        };
                    }
                    return {
                        ...currentResult,
                    };
                },
                {}
            );
        },
    },
    actions: {
        async getWorkflowsStepsShortInfo() {
            try {
                const res =
                    await WorkflowRepository.getInstance().getWorkflowsStepsShortInfo();
                this.allWorkflowShortInfos = res?.result?.workflows;
                this.allWorkflowStepShortInfos = res?.result?.workflowSteps;
                return res;
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },
        async createNewWorkflow(workflow: IWorkflowEntity) {
            try {
                const res =
                    await WorkflowRepository.getInstance().createWorkFlow(
                        workflow
                    );
                if (res.result?.id) this.updateAllWorkflowList(res.result);
                return res;
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },
        async getAllStepType() {
            const res = await WorkflowRepository.getInstance().getAllStepType();
            this.allStepType = res?.result;
        },
        async getWorkflows() {
            const res = await WorkflowRepository.getInstance().getWorkflows();
            this.allWorkflowList = res?.result;

            return res?.result;
        },
        async getWorkflowById(id: string) {
            const res = await WorkflowRepository.getInstance().getWorkflowById(
                id
            );

            return res?.result;
        },

        generateWorkflowName(originalName) {
            const convertedName = stringNomalize(originalName)
                ?.toUpperCase()
                ?.replace(/\(\d+\)$/, '')
                ?.trim()
                .replace(/ +/g, '_');

            const totalUsedName = Object.prototype.hasOwnProperty.call(
                this.allWorkflowByNames,
                convertedName
            )
                ? this.allWorkflowByNames[convertedName]?.total
                : 0;

            return totalUsedName > 0
                ? `${originalName?.replace(/\(\d+\)$/, '')?.trim()} (${
                      totalUsedName + 1
                  })`
                : originalName;
        },
        checkNewWorkflowExisted(newWorkflow: {
            name: string;
            departmentId?: number;
            domainId?: number;
            groupId?: number;
        }) {
            const convertedName = stringNomalize(newWorkflow?.name)
                ?.toUpperCase()
                ?.replace(/ +/g, '_')
                ?.trim();
            if (
                !Object.prototype.hasOwnProperty.call(
                    this.allWorkflowByNames,
                    convertedName
                )
            )
                return false;

            const { departmentId, domainId, groupId } =
                this.allWorkflowByNames[convertedName];

            return (
                newWorkflow.departmentId == departmentId &&
                newWorkflow.domainId == domainId &&
                newWorkflow.groupId == groupId
            );
        },

        async deleteWorkflow(workflowId: string): Promise<boolean> {
            try {
                const res =
                    await WorkflowRepository.getInstance().deleteWorkflow(
                        workflowId
                    );
                if (!res) return false;

                this.allWorkflowList = this.allWorkflowList?.filter(
                    (o) => o.id !== workflowId
                );

                return true;
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
                return false;
            }
        },
        updateAllWorkflowList(newData: IWorkflowEntity) {
            const index = this.allWorkflowList?.findIndex(
                (o) => o.id == newData?.id
            );
            if (index > -1)
                this.allWorkflowList[index] = {
                    ...this.allWorkflowList[index],
                    ...newData,
                };
            else this.allWorkflowList = [newData, ...this.allWorkflowList];
        },
    },
});
