import { ref, watch } from 'vue';
import { useElementHover } from '@vueuse/core';
import { getDynamicPosition } from '@/ui/hooks/commonFunction';

export default function dynamicCardPosition(timeoutValue, resetCallBack) {
    const cardPosition = ref({ x: 0, y: 0 });
    const cardElementRef = ref();
    const isFocusOnCard = useElementHover(cardElementRef);

    watch(
        () => cardElementRef.value,
        () => {
            cardElementRef.value &&
                cardElementRef.value.addEventListener(
                    'mouseleave',
                    onMouseLeaveCard
                );
        }
    );

    const isShowCard = ref(false);

    const onMouseLeaveCard = () => {
        isShowCard.value = false;

        resetCallBack && resetCallBack();
    };
    const onMouseLeaveTarget = () => {
        setTimeout(() => {
            if (isFocusOnCard.value) return;
            onMouseLeaveCard();
        }, timeoutValue);
    };
    const updatePosition = (event, cardTaskSize, callback) => {
        setTimeout(() => {
            callback && callback();
            const targetPosition = event?.target?.getBoundingClientRect();

            cardPosition.value = getDynamicPosition({
                x: targetPosition.left,
                y: targetPosition.top,
                width: cardTaskSize.width,
                height: cardTaskSize.height,
                padding: 10,
            });

            isShowCard.value = true;
        }, timeoutValue);
    };
    return {
        updatePosition,
        cardPosition,
        isShowCard,
        cardElementRef,
        isFocusOnCard,
        onMouseLeaveCard,
        onMouseLeaveTarget,
    };
}
