<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, ref } from 'vue';

const props = defineProps<{
  hasTime: boolean;
  datetime: any;
  timezone: string;
  options?: {
    timezoneList: any[];
  };
}>();

const emit = defineEmits<{
  (
    e: 'onChange',
    data: {
      dateTime: any;
      timezone: string;
    }
  ): void;
  (e: 'removeTime'): void;
}>();

const currentDateTime = computed<any>(() => {
  const myTimezone = dayjs.tz.guess();

  return props.datetime
    ? dayjs(props.datetime).tz(props.timezone || myTimezone)
    : '';
});
// const currentDate = computed<any>(() => {
//   return dayjs(currentDateTime.value).format('YYYY/MM/DD');
// });

const _getHour = (h, p) => {
  if (h == 12 || h == 0) return p == 'AM' ? 0 : 12;
  return p == 'AM' ? parseInt(h) : parseInt(h) + 12;
};

const chooseTime = (data: {
  hour: string;
  minute: string;
  period: 'AM' | 'PM';
  timezone: string;
}) => {
  const _currentDate = dayjs(currentDateTime.value);

  const newDateTime = _currentDate
    .hour(_getHour(parseInt(data?.hour), data?.period))
    .minute(parseInt(data?.minute))
    .tz(data.timezone, true);

  emit('onChange', {
    dateTime: newDateTime.format(),
    timezone: data?.timezone,
  });
};

const currentTime = computed<{
  hour: number;
  minute: number;
  period: 'AM' | 'PM';
}>(() => {
  const _currentDateTime = dayjs(currentDateTime.value);

  return {
    hour: _currentDateTime.get('h'),
    minute: _currentDateTime.get('m'),
    period: _currentDateTime.format('A'),
  };
});

const vigDropdownRef = ref<any>(null);
const openTimeInput = () => {
  if (
    vigDropdownRef.value &&
    typeof vigDropdownRef.value?.onForceOpen == 'function'
  )
    vigDropdownRef.value?.onForceOpen();
};

const isOpenDropdown = ref<boolean>(false);

defineExpose({
  openTimeInput,
});
</script>
<template>
  <VigDropdown
    ref="vigDropdownRef"
    hide-on-click="outside"
    :arrow="false"
    @on-dropdown-open="isOpenDropdown = true"
    @on-dropdown-close="isOpenDropdown = false"
  >
    <template #dropdown-toggle>
      <div
        class="
          w-max
          rounded-md
          h-full
          flex
          items-center
          cursor-pointer
          hover:bg-current-50
          hover-to-show__parent
          px-2
          border border-current-50
        "
      >
        <template v-if="hasTime">
          <div class="flex-1 min-w-0 truncate pr-2 text-xs">
            <SynLabelDateTime2
              v-if="currentDateTime"
              format="time"
              :datetime="currentDateTime"
              :timezone="timezone"
            />
          </div>
          <div
            class="
              w-4
              h-4
              absolute
              right-0
              hover-to-show__children
              bg-white
              rounded-full
              flex-center
            "
            @click.stop="$emit('removeTime')"
          >
            <SynIcon name="close" custom-class="w-3 h-3" />
          </div>
        </template>

        <div v-else class="w-max flex-center gap-2">
          <div class="flex-center">
            <SynIcon name="TimePicker" custom-class="w-3 h-3 fill-gray-500" />
          </div>
          <span class="text-xs">{{ $t('COMMON_LABEL_ADD_TIME') }}</span>
        </div>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="p-4 w-[18rem] min-h-[20rem]">
        <!-- <AtomInputTime
              :default-data="formData?.startDate"
              @on-change="chooseTime"
            /> -->
        <SynTimePicker
          v-if="isOpenDropdown"
          :current-time="currentTime"
          :timezone="timezone"
          :options="{
            isShowTimezoneInput: true,
            isShowInput: true,
            isShowClock: true,
            isShowClockWhenFocusOnInput: true,
            timezone: timezone,
            timezoneList: options?.timezoneList,
          }"
          @on-change="chooseTime"
        />
      </div>
    </template>
  </VigDropdown>
</template>
