<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import remoteConfigStore from '@/store/remoteConfig';
import calendarStore from '@/store/calendar';
import myProfileStore from '@/store/auth/my-profile';
import { isImage, verifyFiles } from '@/ui/hooks/fileHook';
import noteLogic from '@/ui/modules/calendar/general/note-logic';
import TimeRange from '@/ui/components/calendars/event-attribute/TimeRange.vue';
import SelectPeopleInvolved from '@/ui/components/calendars/event-attribute/SelectPeopleInvolved.vue';
import NoticeOption from '@/ui/components/calendars/event-attribute/NoticeOption.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';

import {
  CalendarId,
  CalendarMeetingType,
  CalendarNotificationType,
  CalendarReminderRepeatType,
  CalendarSourceId,
  CalendarSourceType,
  CalendarType,
} from '@/ui/common/constants/calendar';
import { allowedExtensionString } from '@/ui/modules/task/task-global-state';
import EventRepeatField from '@/ui/modules/calendar/event/event-repeat-field/EventRepeatField.vue';
import dayjs from 'dayjs';
import { translate } from '@/ui/plugins/i18n/myi18n';
import EventAllDayField from '@/ui/modules/calendar/event/event-allday-field/EventAllDayField.vue';
import { getDayInUtc } from '@/ui/hooks/calendarHook';
import { CalendarDetail } from '@/domain/entities/PayloadClass/CalendarPayloadClass';
import userStore from '@/store/user';

const props = defineProps<{
  isEdit?: boolean;
  isClone?: boolean;
  defaultData?: any;
}>();

const emit = defineEmits([
  'cancel',
  'onUpdate',
  'isChangeContent',
  'onCreateSuccess',
]);

const timezone = computed(() => myProfileStore().getTimezone);

onMounted(() => {
  isChangeContent.value = false;

  setTimeout(() => {
    inputSearchRef.value?.focus();
  });
});

const _remoteConfigStore = remoteConfigStore();
const _calendarStore = calendarStore();
const inputSearchRef = ref(null as any);

const onlineMeeting = ref(false);

const isLoadingSave = ref(false);
const isChangeContent = ref(false);
const isChangeTitle = ref(false);

const calendarDescriptionRef = ref<any>(null);

const formData = ref(
  new CalendarDetail({
    calendarId: CalendarId.Event,
    sourceId: CalendarSourceId.Meeting,
    sourceType: CalendarSourceType.Event,
    type: CalendarType.Meeting,
    id: props.defaultData?.id || '',
    title: props.defaultData?.title || '',
    description: props.defaultData?.description || '',
    startDate: props.defaultData?.startDate || Date.now(),
    endDate: props.defaultData?.endDate || Date.now(),
    assigneeId: 0,
    createdBy: props.defaultData?.createdBy || 0,
    updatedBy: props.defaultData?.updatedBy || 0,
    allDay: props.defaultData?.allDay,
    metadata: {
      ...props.defaultData?.metadata,
      files: props.defaultData?.metadata?.attachments?.files,
      notes: props.defaultData?.metadata?.attachments?.notes,
    },
  })
);

const selectedWeekdays = ref(
  props.defaultData?.metadata?.repeatSpecificDaysOfWeek || [
    (props.defaultData?.startDate
      ? dayjs(props.defaultData?.startDate)
      : dayjs()
    ).day(),
  ]
);

const selectedUsers = computed<any[]>(() => {
  return formData.value?.participantIds?.map((id) => {
    return userStore().allUserByIds[id];
  });
});

const isMessageError = computed(
  () => isChangeContent.value && !formData.value.title?.trim()
);
const repeatErrorMsg = computed(() => {
  if (
    formData.value.repeatType ===
      CalendarReminderRepeatType.CustomSpecificDayOfWeek &&
    !selectedWeekdays.value?.length
  ) {
    return translate('CALENDAR_REPEAT_CUSTOM_DAY_WEEK_REQUIRED_MSG');
  }
  return null;
});

const isDisabled = computed(() => {
  return !!(
    (!formData.value.title?.trim() && !props.isEdit) ||
    (isMessageError.value && props.isEdit) ||
    (!formData.value.title?.trim() && props.isEdit) ||
    !descriptionValid.value ||
    repeatErrorMsg.value
  );
});

const initData = () => {
  if (props.isEdit || props.isClone) {
    isChangeContent.value = false;

    onlineMeeting.value =
      formData.value.meetingType !== CalendarMeetingType.PhysicalMeeting;
  }

  if (props.defaultData?.files?.length > 0) {
    formData.value.files = [...props.defaultData?.files];
  }
};

initData();

const onChooseMember = (members) => {
  isChangeContent.value = true;
  onCheckMessageError();
  formData.value.participantIds = [];
  members.forEach((el) => {
    formData.value.participantIds.push(el?.id);
  });
};

const onChangeTitle = (event) => {
  isChangeContent.value = true;
  onCheckMessageError();
  isChangeTitle.value = true;
  formData.value.title = event.target.value;
};

const onChangeTime = (time, type) => {
  switch (type) {
    case 'start':
      formData.value.startDate = time;
      break;
    case 'end':
      formData.value.endDate = time;
      break;
    default:
      break;
  }

  handleCalculateDurationTime();
};

const onChangeTypeMeeting = (isonline) => {
  isChangeContent.value = true;
  onCheckMessageError();
  if (isonline) {
    handleCalculateDurationTime();
  }
};

const handleCalculateDurationTime = () => {
  let daysjsStart = new Date(formData.value.startDate).getTime();

  let daysjsEnd = new Date(formData.value.endDate).getTime();

  let minutes = (daysjsEnd - daysjsStart) / 1000 / 60;

  if (minutes < 0) formData.value.meetingDuration = 0;

  if (minutes >= 0 && minutes <= 45) {
    formData.value.meetingDuration = minutes > 0 ? minutes : 15;
  }

  if (minutes > 45) formData.value.meetingDuration = 45;
};

// FILE
const currentFileIndex = ref(0);
const isPreview = ref(false);
const attachedFiles = computed(() => {
  if (!formData.value || !formData.value?.files) return [];

  return formData.value?.files.filter(
    (attachment) => !attachment.type?.includes('audio') && !attachment.isDeleted
  );
});
const attachedAudios = computed(() => {
  if (!formData.value || !formData.value?.files) return [];

  return formData.value?.files.filter(
    (attachment) => attachment.type?.includes('audio') && !attachment.isDeleted
  );
});

const onHandleUploadFile = async () => {
  const files = formData.value?.files;
  const arrayFileCurrent = files?.filter((el) => el?.fileUrl) || [];
  const arrayFile = files?.filter((el) => !el?.fileUrl && !el?.isDeleted) || [];
  const notes = formData.value?.notes || [];

  const fileUploads = [...arrayFile, ...notes];
  if (!fileUploads || fileUploads?.length == 0) return;

  try {
    const res = await _calendarStore.uploadFileInEvent(fileUploads);

    formData.value.files = arrayFileCurrent.concat(
      res?.filter((r) => !r?.isUploadedFail)
    );
    formData.value.notes = [];
  } catch (e) {
    console.log('e', e);
    // formData.value.files = [];
  }
};

const onHandleDeleteFile = async () => {
  console.log(
    '---onHandleDeleteFile formData.value?.files',
    formData.value?.files
  );
  let fileRemove = formData.value?.files?.filter((el) => el?.isDeleted);
  console.log('---fileRemove', fileRemove);
  if (!fileRemove || fileRemove?.length == 0) return;
  try {
    await _calendarStore.removeFileInEvent(fileRemove);
    formData.value.files = formData.value.files?.filter((el) => !el?.isDeleted);
  } catch (e) {
    console.log('e', e);
    // formData.value.files = [];
  }
};

const onHandleCloneFile = async () => {
  const files = formData.value?.files;
  const arrayFileClone =
    files?.filter((el) => el?.fileUrl && !el?.isDeleted) || [];
  if (!arrayFileClone || arrayFileClone?.length == 0) return;

  try {
    const res = await _calendarStore.cloneFileInEvent(arrayFileClone);

    formData.value.files = formData.value.files?.filter(
      (el) => !el?.fileUrl || el?.isDeleted
    );
    formData.value.files = formData.value.files?.concat(res);
  } catch (e) {
    console.log('e', e);
  }
};

const onChangedFiles = async (files) => {
  isChangeContent.value = true;
  onCheckMessageError();

  verifyFiles(
    files,
    _remoteConfigStore.webChatUploadContentTypes,
    _remoteConfigStore.webTaskUploadMaxFilesize,
    (file) => {
      formData.value.files = [...formData.value?.files, file];
    }
  );
};

const onChooseFiles = (event) => {
  const files = event.target?.files;
  onChangedFiles(files);
};

const onRemoveAttachments = (file) => {
  let index = -1;
  isChangeContent.value = true;
  onCheckMessageError();
  if (file?.id) {
    index = formData.value?.files.findIndex((o) => o?.id == file.id);
    if (index > -1) {
      formData.value.files[index].isDeleted = true;
    }
  } else {
    index = formData.value?.files.findIndex(
      (o) => o.name == file.name && o.lastModified == file.lastModified
    );
    formData.value?.files.splice(index, 1);
  }
};

const onOpenFiles = (index) => {
  currentFileIndex.value = index;
  isPreview.value = true;
};

// AUDIO
const isOpenRecording = ref(false);
const onCreateAudio = () => {
  isOpenRecording.value = true;
};

const onCreateAudioDone = (recordings) => {
  onChangedFiles(recordings);
  isOpenRecording.value = false;
};

const onCreateAudioCancel = () => {
  isOpenRecording.value = false;
};

const genAudioUrl = (record) => {
  return URL.createObjectURL(record);
};

const onPasteFiles = async (event) => {
  var files = (event.clipboardData || event.originalEvent.clipboardData).files;
  if (!files || files?.length == 0) return;
  await onChangedFiles(files);
};

// ACTION
const onSave = async () => {
  if (!formData.value.title?.trim() || repeatErrorMsg.value) {
    isLoadingSave.value = false;
    return;
  }

  isLoadingSave.value = true;

  formData.value.type = CalendarType.Meeting;
  formData.value.sourceType = CalendarSourceType.Event;
  formData.value.sourceId = CalendarSourceId.Meeting;
  formData.value.calendarId = CalendarId.Event;

  onlineMeeting.value
    ? (formData.value.meetingType = CalendarMeetingType.OnlineMeeting)
    : (formData.value.meetingType = CalendarMeetingType.PhysicalMeeting);

  formData.value.repeatSpecificDaysOfWeek =
    formData.value.repeatType ===
    CalendarReminderRepeatType.CustomSpecificDayOfWeek
      ? selectedWeekdays.value
      : [];

  if (formData.value.allDay) {
    formData.value.startDate = getDayInUtc(formData.value.startDate);
    formData.value.endDate = getDayInUtc(formData.value.endDate);
  }

  if (props.isEdit) {
    await onHandleUploadFile();
    await onHandleDeleteFile();
    await _calendarStore.updateEvent(formData.value);
    isLoadingSave.value = false;

    emit('onUpdate', true);

    return;
  }

  if (props.isClone) await onHandleCloneFile();

  await onHandleUploadFile();
  await onHandleDeleteFile();
  const res = await _calendarStore.addEvent(formData.value);
  isLoadingSave.value = false;
  emit('onCreateSuccess', res?.result);
};

const onCheckMessageError = () => {
  if (isChangeContent.value && !formData.value.title?.trim()) {
    emit('isChangeContent', true);
    return;
  }
  emit('isChangeContent', false);
};

// const onAddressSelect = (value) => {
//   if (value) {
//     addressDetail.value = JSON.stringify(value);
//     newUser.value.address = addressDetail.value?.fullAddress
//       ? addressDetail.value?.fullAddress
//       : newUser.value.address;
//   }
// };

const descriptionValid = ref<boolean>(true);

//NOTE
const {
  isCreateNote,
  currentNote,
  isLoadingDrawData,
  onSaveNote,
  onCreateNote,
  onCancelNote,
  onRemoveNote,
  onEditNote,
} = noteLogic(formData as any);
</script>

<template>
  <div class="h-full flex flex-col gap-3">
    <!--EVENT TITLE-->
    <div class="py-2 pl-16 pr-6 flex flex-col">
      <input
        ref="inputSearchRef"
        class="
          bg-white
          w-full
          h-full
          focus:outline-none
          pt-4
          pb-2
          border-b-2
          focus:border-b-2 focus:border-current
          placeholder-gray-500 placeholder-opacity-75
          text-xl
        "
        :class="
          isMessageError
            ? 'border-red-400 focus:border-b-2 focus:border-red-400'
            : 'border-gray-200 focus:border-b-2 focus:border-current'
        "
        :placeholder="$t('CALENDAR_LABEL_INPUTVALUE_TITLE')"
        :value="formData.title"
        required
        autofocus
        @input="(value) => onChangeTitle(value)"
        @blur="(isChangeContent = true), onCheckMessageError"
      />
      <small v-if="isMessageError" class="text-red-400">{{
        $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED')
      }}</small>
    </div>

    <div
      class="flex-1 overflow-auto small-scrollbar px-6 py-1"
      @paste="onPasteFiles"
    >
      <!-- TIMES -->
      <div class="flex items-center space-x-2 mb-3">
        <div class="w-8 h-8 flex items-center">
          <SynIcon name="calendars" custom-class="w-5 h-5 fill-gray-500" />
        </div>
        <div class="flex-1 flex items-center space-x-2">
          <TimeRange
            :time="formData?.startDate"
            :time-from="isEdit || isClone ? formData?.endDate : ''"
            :is-hidden-time="formData.allDay"
            class="flex-1"
            @choose-time-start="(time) => onChangeTime(time, 'start')"
            @choose-time-end="(time) => onChangeTime(time, 'end')"
            @change="(isChangeContent = true), onCheckMessageError()"
          />
        </div>
        <SynExplain
          :label-question="
            $t('CALENDAR_QUESTION_LABEL_TIMEZONE', { utc: timezone })
          "
          :label-answer="$t('CALENDAR_ANSWER_LABEL_TIMEZONE')"
        />
      </div>

      <!--REPEAT & ALL DAY-->
      <div class="flex items-start space-x-2 mb-3">
        <div class="w-8 h-8 flex items-center">
          <SynIcon name="reload" class="fill-gray-500" />
        </div>
        <div class="flex-1">
          <EventRepeatField
            v-model="formData.repeatType"
            v-model:days-of-week="selectedWeekdays"
            :start-date="formData.startDate"
          />
          <small v-if="repeatErrorMsg" class="text-red-400">{{
            repeatErrorMsg
          }}</small>
        </div>
        <EventAllDayField v-model="formData.allDay" />
      </div>

      <!-- HAS INPUT TITLE FIRST -->
      <div class="flex-1 flex-col">
        <!-- TYPE MEETING (ONL/WORKSPACE) -->
        <div class="flex items-start space-x-2 mb-2">
          <div class="w-8 h-8 flex items-center">
            <SynIcon
              name="Meeting"
              custom-class="w-5 h-5 fill-gray-500"
              is-active
            />
          </div>
          <div v-permission-model class="flex-1">
            <div
              class="
                flex-center
                space-x-2
                px-2
                py-1
                cursor-pointer
                w-max
                bg-current-50 bg-opacity-60
                rounded
              "
              :class="
                onlineMeeting
                  ? `border-current-600 border bg-current-50 text-current-600 `
                  : ''
              "
            >
              <input
                id="id_onl_Meeting"
                v-model="onlineMeeting"
                type="checkbox"
                class="vig-checkbox"
                @change="onChangeTypeMeeting(onlineMeeting)"
              />
              <label for="id_onl_Meeting" class="cursor-pointer">
                {{ $t('CALENDAR_LABEL_ONLINE_MEETING') }}
              </label>
            </div>
          </div>
        </div>

        <!-- LOCATION -->
        <div v-if="!onlineMeeting" class="flex items-start space-x-2 mb-3">
          <div class="w-8 h-8 flex items-center">
            <SynIcon name="location" custom-class="w-5 h-5 fill-gray-500" />
          </div>
          <div class="flex-1">
            <!-- <div class="py-1 font-medium text-sm" style="color: #128f90">
              {{ $t('CALENDAR_LABEL_WORK_SPACE') }}
            </div> -->

            <!--        <syn-input-->
            <!--          v-model="formData.workspace"-->
            <!--          maxlength="400"-->
            <!--          class="p-0"-->
            <!--          :placeholder="$t('CALENDAR_LABEL_WORK_SPACE')"-->
            <!--          @input="(isChangeContent = true), onCheckMessageError()"-->
            <!--        />-->

            <VigAddressBox
              v-model="formData.workspace"
              :placeholder="$t('CALENDAR_LABEL_WORK_SPACE')"
              class="vig-input"
            />
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="flex items-start space-x-2 mb-3">
          <div class="w-8 h-8 flex items-center">
            <SynIcon
              name="align-left"
              custom-class="w-5 h-5 fill-gray-500"
              is-active
            />
          </div>
          <div class="flex-1">
            <!-- <div class="py-1 font-medium text-sm" style="color: #128f90">
              {{ $t('COMMON_LABEL_DESCRIPTION') }}
            </div> -->
            <TaskDescriptionInput
              ref="calendarDescriptionRef"
              v-model="formData.description"
              v-model:isValid="descriptionValid"
              :current-task="formData"
              :placeholder="$t('COMMON_LABEL_DESCRIPTION')"
              :max-length="400"
              :is-action="false"
            />
            <!--          <SynInputArea-->
            <!--              v-model="formData.description"-->
            <!--              :placeholder="$t('COMMON_LABEL_DESCRIPTION')"-->
            <!--              max-length="400"-->
            <!--              class="p-0"-->
            <!--              @change="(isChangeContent = true), onCheckMessageError()"-->
            <!--          />-->
          </div>
        </div>

        <!-- FILE/NOTE/ATT -->
        <div class="flex items-start space-x-2 mb-3">
          <div class="w-8 h-8 flex items-center">
            <SynIcon
              name="attach"
              custom-class="w-4 h-4 fill-gray-500"
              is-active
            />
          </div>
          <div class="flex-1 overflow-hidden">
            <div class="flex items-center space-x-2 pt-1">
              <div
                class="text-sm flex font-medium items-center space-x-1"
                style="color: #128f90"
              >
                <span>{{
                  $t('TASK_DETAIL_LABEL_ATTACHMENT') || 'Attachments'
                }}</span>
              </div>
              <div class="flex-center space-x-1">
                <div class="w-4 h-4">
                  <label
                    for="event-detail_attachment"
                    class="
                      w-full
                      h-full
                      flex-center
                      cursor-pointer
                      hover:text-current hover:fill-current
                    "
                    :title="
                      $t('TASK_CREATE_FORM_LABEL_ADD_ATTACHMENT') ||
                      'Add attachment'
                    "
                  >
                    <SynIcon name="attach" has-action />
                  </label>
                  <input
                    id="event-detail_attachment"
                    type="file"
                    multiple
                    class="hidden opacity-0"
                    :accept="allowedExtensionString"
                    @change="onChooseFiles"
                  />
                </div>
                <SynIcon
                  name="noter"
                  has-action
                  is-active
                  custom-class="fill-orange-500 h-4 w-4"
                  :title="$t('TASK_CREATE_FORM_LABEL_ADD_NOTE') || 'Add note'"
                  @click="onCreateNote"
                />
                <SynIcon
                  :title="$t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'"
                  is-active
                  name="microphone"
                  color="blue"
                  has-action
                  @click="onCreateAudio"
                />
              </div>
            </div>
            <div
              v-if="
                formData.notes?.length > 0 ||
                attachedFiles?.length > 0 ||
                attachedAudios?.length > 0
              "
              class="
                overflow-auto
                small-scrollbar
                flex
                items-center
                space-x-1
                mt-2
              "
              style="max-height: 25vh"
            >
              <div
                v-if="formData.notes?.length > 0 || attachedFiles?.length > 0"
                class="flex items-center"
              >
                <template v-if="attachedFiles?.length > 0">
                  <UploadPreview
                    v-for="(file, index) in attachedFiles"
                    :key="file"
                    :file="file"
                    @on-select="onOpenFiles(index)"
                    @remove="onRemoveAttachments(file)"
                  />
                </template>
                <template v-if="formData.notes?.length > 0">
                  <UploadPreview
                    v-for="(file, index) in formData.notes?.filter(
                      (o) => !o?.isDeleted
                    )"
                    :key="index"
                    :file="file"
                    is-note
                    @on-select="onEditNote(file)"
                    @remove="onRemoveNote(file)"
                  />
                </template>
              </div>
              <div
                v-if="attachedAudios?.length > 0"
                class="flex items-center space-x-1"
              >
                <VigAudio
                  v-for="record in attachedAudios.filter(
                    (o) => !o?.noteAudioId
                  )"
                  :key="record"
                  :path="record.src || record.fileUrl || genAudioUrl(record)"
                  type="view"
                  :readonly="false"
                  @remove="onRemoveAttachments(record)"
                />

                <!--          <syn-audio-->
                <!--              v-for="record in attachedAudios.filter((o) => !o?.noteAudioId)"-->
                <!--              :key="record"-->
                <!--              class="mr-4"-->
                <!--              :src="record.src || record.fileUrl || genAudioUrl(record)"-->
                <!--              @on-close="onRemoveAttachments(record)"-->
                <!--          />-->
              </div>
            </div>
          </div>
        </div>

        <!-- PEOPLE  -->
        <div class="flex items-start space-x-2 mb-3">
          <div class="w-8 h-8 flex items-center">
            <SynIcon name="group" custom-class="w-5 h-5 fill-gray-500" />
          </div>
          <div class="flex-1">
            <SelectPeopleInvolved
              :default-users="selectedUsers"
              @on-choose-member="(member) => onChooseMember(member)"
            />
          </div>
        </div>

        <!-- NOTICE -->
        <div class="flex items-start space-x-2">
          <div class="w-8 h-8 flex items-center">
            <SynIcon name="bell" custom-class="w-4 h-4 fill-gray-500" />
          </div>
          <div class="flex-1">
            <NoticeOption
              :notice-choose="
                formData?.notificationType ||
                CalendarNotificationType.NoticeAfter15Minutes
              "
              :placement="'top-start'"
              @on-change-notice="
                (value) => (
                  (formData.notificationType = value),
                  (isChangeContent = true),
                  onCheckMessageError()
                )
              "
            />
          </div>
        </div>
      </div>
    </div>

    <!-- BTN: ACTION -->
    <div class="px-6 flex justify-end items-center space-x-2">
      <SynButton
        type-text
        color="gray"
        :label="$t('COMMON_LABEL_CANCEL')"
        @click="$emit('cancel', false)"
      />
      <SynButton
        :disabled="isDisabled"
        :is-loading="isLoadingSave"
        :label="$t('COMMON_LABEL_SAVE')"
        @click="onSave"
      />
    </div>

    <ModalMultipleFileViewer
      v-if="isPreview"
      :start-index="currentFileIndex"
      :files="attachedFiles"
      @on-remove="onRemoveAttachments"
      @on-close="isPreview = false"
    />

    <RecorderModal
      v-if="isOpenRecording"
      module="calendar"
      @on-done="onCreateAudioDone"
      @on-cancel="onCreateAudioCancel"
    />

    <CreateNoteModal
      v-if="isCreateNote"
      :draw-note-data="{
        ...currentNote,
        isLoading: isLoadingDrawData,
        images: formData.files?.filter((o) => isImage(o)) || [],
        originFile: '',
        readonly: false,
      }"
      @on-close="onCancelNote"
      @on-save="
        (data) =>
          onSaveNote({
            ...currentNote,
            ...data,
          })
      "
    />
  </div>
</template>
