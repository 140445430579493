<script setup lang="ts">
import dayjs from 'dayjs';
import { onMounted, ref } from 'vue';
import atomDateRangPicker from '@/ui/common/atoms/date-pickers/atomDateRangPicker';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

const props = defineProps<{
  modelValue: {
    start: string;
    end: string;
  };
  timezone?: string;
  maxDate?: string;
}>();

const emit = defineEmits<{
  (
    e: 'onChange',
    data: {
      dateTime: any;
      timezone: string;
    }
  ): void;
  (e: 'removeTime'): void;
  (e: 'update:modelValue', data: { start: string; end: string }): void;
}>();

const vigDropdownRef = ref<any>(null);
const openTimeInput = () => {
  if (
    vigDropdownRef.value &&
    typeof vigDropdownRef.value?.onForceOpen == 'function'
  )
    vigDropdownRef.value?.onForceOpen();
};

const isOpenDropdown = ref<boolean>(false);

const filterDateObj = ref<{ start: string; end: string }>({
  start: props.modelValue?.start,
  end: props.modelValue?.end,
});

const {
  currentChoice,
  suggestRangeList,
  dataRangeString,
  updateCurrentChoice,
} = atomDateRangPicker();

const updateModelValue = () => {
  if (
    !dayjs(filterDateObj.value?.start).isValid() ||
    !dayjs(filterDateObj.value?.end).isValid()
  )
    return;

  updateCurrentChoice({
    fromDate: filterDateObj.value?.start,
    toDate: filterDateObj.value?.end,
  });
  emit('update:modelValue', filterDateObj.value);
};

const onChooseSuggestRange = (data) => {
  if (currentChoice.value == data?.key) return;

  currentChoice.value = data?.key;
  const rangeDate = data?.getData();

  if (!rangeDate?.start || !rangeDate?.end) return;

  filterDateObj.value = rangeDate;

  updateModelValue();
};

onMounted(() => {
  updateCurrentChoice({
    fromDate: props.modelValue?.start,
    toDate: props.modelValue?.end,
  });
});

defineExpose({
  openTimeInput,
});
</script>
<template>
  <VigDropdown
    ref="vigDropdownRef"
    hide-on-click="outside"
    :arrow="false"
    @on-dropdown-open="isOpenDropdown = true"
    @on-dropdown-close="isOpenDropdown = false"
  >
    <template #dropdown-toggle>
      <div
        class="
          w-max
          h-9
          text-sm
          px-2
          flex-center
          border border-gray-100
          rounded-md
          bg-white
          cursor-pointer
          hover:bg-current-50
        "
      >
        <span>
          {{ dataRangeString }}
        </span>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="p-4 w-[18rem]">
        <div class="flex flex-col gap-0.5">
          <div
            v-for="item in suggestRangeList"
            :key="item?.key"
            class="px-2 py-2 rounded hover:bg-current-50 cursor-pointer"
            :class="currentChoice == item?.key ? 'bg-current-50' : ''"
            @click="onChooseSuggestRange(item)"
          >
            <span class="text-sm">
              {{ $t(item?.titleCode) || item?.title }}
            </span>
          </div>
          <div class="w-full py-0.5 flex-center">
            <div class="w-full border-t"></div>
          </div>

          <div
            class="px-2 py-2 mb-2 rounded hover:bg-current-50 cursor-pointer"
            :class="currentChoice == 'CUSTOM' ? 'bg-current-50' : ''"
            @click="currentChoice = 'CUSTOM'"
          >
            <span class="text-sm">
              {{ $t('SUGGEST_DATE_RANGE_LABEL_CUSTOM') }}
            </span>
          </div>
          <AtomDatePicker
            v-if="currentChoice == 'CUSTOM'"
            v-model="filterDateObj"
            is-range
            timezone="Asia/Bangkok"
            :max-date="maxDate"
            @update:model-value="updateModelValue"
          />
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
