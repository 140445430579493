<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g>
        <path
          d="M14.586 10.343a1 1 0 1 1-1.414-1.414l3.535-3.536a7 7 0 0 1 9.9 9.9l-3.536 3.535a1 1 0 1 1-1.414-1.414l3.535-3.535a5 5 0 0 0-7.07-7.071zM6.808 6.808a1 1 0 0 1 1.414 0l16.97 16.97a1 1 0 0 1-1.414 1.414L6.808 8.222a1 1 0 0 1 0-1.414zM10.343 13.172a1 1 0 0 1 0 1.414L6.808 18.12a5 5 0 0 0 7.07 7.071l3.536-3.535a1 1 0 1 1 1.414 1.414l-3.535 3.536a7 7 0 1 1-9.9-9.9l3.536-3.535a1 1 0 0 1 1.414 0z"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
