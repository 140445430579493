<script setup lang="ts">
import { computed, ref } from 'vue';
import domainStore from '@/store/scope';

const props = defineProps<{
  domainId: any;
}>();

const emit = defineEmits<{
  (e: 'onDeleteSuccess'): void;
}>();

const _domainStore = domainStore();

const domainByIds = computed(() => _domainStore.domainByIds);
const domainDetail = computed(() => domainByIds.value[props.domainId]);
const isLoading = ref(false);
const initData = async () => {
  try {
    isLoading.value = true;
    const res = await _domainStore.getTasksByDomain({
      domainId: props.domainId,
      projectId: null,
      subProjectId: null,
    });
    tasksByDomain.value = res?.result || [];
  } catch (error) {
    console.log(
      '🚀 ~ file: DomainDeleteForm.vue ~ line 26 ~ initData ~ error',
      error
    );
  } finally {
    isLoading.value = false;
  }
};

const tasksByDomain = ref([] as any[]);

const isDisableSubmitReject = computed(() => {
  return inputConfirmDelete.value !== 'delete';
});

const updateTaskPayload = ref({});

const isDeleting = ref(false);
const onSubmitReject = async () => {
  try {
    isDeleting.value = true;
    await _domainStore.rejectDomain({
      domainId: props.domainId,
      relatedTasks: [...Object.values(updateTaskPayload.value)],
    });
    isDeleting.value = false;

    emit('onDeleteSuccess');
  } catch (error) {
    console.log('🚀 ~ onSubmitReject ~ error', error);
  }
};

const inputConfirmDelete = ref('');

initData();
</script>
<template>
  <div
    v-if="domainDetail?.system"
    class="flex-1 overflow-hidden flex flex-col p-8"
  >
    <div class="flex-center p-6">
      <div class="flex-center h-16 w-16 ring ring-orange-300 rounded-full">
        <SynIcon
          name="warning-circle"
          custom-class="w-16 h-16 fill-orange-500"
        />
      </div>
    </div>
    <div class="flex-center">
      <span class="font-semibold">
        {{
          $t('DOMAIN_LABEL_REMOVE_SYSTEM_DOMAIN_TITLE') ||
          'Please do not delete this domain'
        }}
      </span>
    </div>
    <div class="text-center">
      <span
        class="text-sm text-gray-600"
        v-html="
          $t('DOMAIN_LABEL_REMOVE_SYSTEM_DOMAIN_CONTENT', {
            domainName: domainDetail.name,
          })
        "
      >
      </span>
    </div>
  </div>
  <div
    v-else-if="tasksByDomain?.length > 0 || domainDetail?.groups?.length > 0"
    class="flex-1 overflow-hidden flex flex-col p-8"
  >
    <div class="flex-center p-6">
      <div class="flex-center h-16 w-16 ring ring-orange-300 rounded-full">
        <SynIcon
          name="warning-circle"
          custom-class="w-16 h-16 fill-orange-500"
        />
      </div>
    </div>
    <div class="flex-center">
      <span class="font-semibold">
        {{ $t('DOMAIN_LABEL_REMOVE_DOMAIN') || 'Remove domain/project' }}
      </span>
    </div>
    <div class="text-center">
      <span class="text-sm text-gray-600">
        {{
          $t('DOMAIN_LABEL_CANNOT_DELETE') ||
          'You can not delete this domain because there are active tasks, groups, and members related to the domain.'
        }}
      </span>
    </div>
    <div class="flex-center space-x-2 p-8">
      <template v-if="domainDetail?.groups?.length > 0">
        <div class="w-28 h-28 rounded-md flex-center flex-col border">
          <span class="text-4xl font-bold text-current">
            {{ domainDetail?.groups?.length }}
          </span>
          <span>
            {{ $t('DOMAIN_LABEL_GROUPS') || 'Groups' }}
          </span>
        </div>
      </template>
      <template v-if="tasksByDomain?.length > 0">
        <div class="w-28 h-28 rounded-md flex-center flex-col border">
          <span class="text-4xl font-bold text-current">
            {{ tasksByDomain?.length }}
          </span>
          <span>
            {{ $t('TASK_TABLE_LABEL_TASKS') || 'Tasks' }}
          </span>
        </div>
      </template>
    </div>
  </div>
  <div v-else class="flex-1 overflow-hidden flex flex-col justify-between p-8">
    <div class="flex-center flex-col">
      <span class="font-semibold">
        {{ $t('DOMAIN_LABEL_REMOVE_DOMAIN') || 'Remove domain/project' }}
      </span>
      <span class="text-xl font-semibold text-current">
        {{ domainDetail?.name }}
      </span>
    </div>
    <div class="pt-8">
      <span
        class="pt-2 text-sm text-gray-600"
        v-html="
          $t('DOMAIN_LABEL_TYPE_FOR_DELETE') ||
          'To confirm deletion, type delete in the text input field.'
        "
      >
      </span>
      <SynInput
        v-model="inputConfirmDelete"
        autofocus
        placeholder="delete"
        @enter="onSubmitReject"
      />
    </div>
    <div class="flex justify-end pt-2 items-center space-x-2">
      <SynButton
        :color="'red'"
        :label="$t('COMMON_LABEL_DELETE')"
        :disabled="isDisableSubmitReject"
        :is-loading="isDeleting"
        @click="onSubmitReject"
      />
    </div>
  </div>
</template>

<style>
.outline-red {
  outline: 1px solid red;
}

.domain-min-height {
  min-height: 82px;
}
</style>
