<script setup lang="ts">
import { computed } from 'vue';
import {
  visible,
  tell,
  confirmModalMetadata,
  isContinueFn,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';

const confirmLabelByType = computed(
  () => confirmModalMetadata.value?.confirmLabel
);
const cancelLabelByType = computed(
  () => confirmModalMetadata.value?.cancelLabel
);
const classByType = computed(() => {
  if (confirmModalMetadata.value?.type == 'warning') return 'text-orange-500';
  return '';
});

const iconName = computed<string>(() => {
  return confirmModalMetadata.value?.iconName || 'info';
});
const iconColor = computed<string>(() => {
  return confirmModalMetadata.value?.iconColor || 'orange';
});
const options = computed<
  | {
      confirmButtonColor: string;
    }
  | undefined
>(() => {
  return confirmModalMetadata.value?.options || 'orange';
});
const footerButtons = computed<
  | {
      type: 'TEXT' | 'FILL';
      labelCode: string;
      color?: string;
      iconName?: string;
      onClick: Function;
    }[]
  | undefined
>(() => {
  return confirmModalMetadata.value?.footerButtons;
});
</script>

<template>
  <SynModal
    v-if="visible"
    disable-click-outside
    disable-esc-to-close
    container-class="w-max"
    container-style="min-width: 28rem"
    is-hidden-header
    disable-element-active
    :is-hidden-close="!confirmModalMetadata?.closeable"
    :is-hidden-footer="
      !confirmLabelByType &&
      !cancelLabelByType &&
      (!footerButtons || footerButtons?.length == 0)
    "
    z-index="z-100"
    @cancel="tell(false)"
  >
    <template #body>
      <div class="flex-center flex-col h-full" style="max-width: 32rem">
        <div class="w-full flex flex-col">
          <div
            v-if="
              !confirmModalMetadata?.isHiddenIcon ||
              confirmModalMetadata?.title ||
              confirmModalMetadata?.content
            "
            class="py-4 px-6 flex space-x-4 items-start"
          >
            <div v-if="!confirmModalMetadata?.isHiddenIcon">
              <SynIcon
                :name="iconName"
                :custom-class="`w-9 h-9 fill-${iconColor}-500`"
              />
            </div>
            <div class="" role="alert">
              <p
                v-if="confirmModalMetadata?.title"
                class="font-medium text-xl pb-4 pt-1"
                :class="`text-${iconColor}-500`"
              >
                {{ confirmModalMetadata?.title }}
              </p>
              <span
                v-if="confirmModalMetadata?.content"
                class="text-gray-600 space-y-2"
                :class="classByType"
                v-html="confirmModalMetadata?.content"
              ></span>
            </div>
          </div>

          <div v-if="confirmModalMetadata?.contentComponent" class="w-full">
            <component
              :is="confirmModalMetadata?.contentComponent"
              v-bind="confirmModalMetadata?.contentComponentProps"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        v-if="
          confirmLabelByType ||
          cancelLabelByType ||
          (footerButtons && footerButtons?.length > 0)
        "
        class="
          w-full
          p-4
          flex
          items-center
          hover:text-current
          cursor-pointer
          space-x-2
          vig-modal-footer
          do-not-close-dropdown
        "
        :class="
          confirmModalMetadata?.hasConfigs ? 'justify-between' : 'justify-end'
        "
      >
        <template v-if="confirmModalMetadata?.hasConfigs">
          <AtomCheckbox
            v-model="confirmModalMetadata.disableNextTime"
            :label="$t('TASK_LABEL_DO_NOT_SHOW_THIS_NOTIFICATION_AGAIN')"
          />
        </template>
        <div class="flex-center gap-2">
          <template v-if="footerButtons && footerButtons?.length > 0">
            <AtomButton
              v-for="(button, index) in footerButtons"
              :key="index"
              :type="button?.type"
              :color="button?.color"
              :label="$t(button?.labelCode) || button?.labelCode"
              :icon-name="button?.iconName"
              size="lg"
              custom-class="do-not-close-dropdown"
              custom-class-label="font-normal"
              @click="
                () => {
                  if (button && typeof button.onClick == 'function')
                    button.onClick({
                      disableNextTime: confirmModalMetadata?.disableNextTime,
                    });
                }
              "
            />
          </template>
          <template v-else>
            <SynButton
              v-if="confirmModalMetadata?.closeable"
              type-text
              color="gray"
              @click.stop="
                tell(false);
                confirmModalMetadata?.isContinueBtn
                  ? (isContinueFn = true)
                  : (isContinueFn = false);
              "
            >
              <span class="do-not-close-dropdown">
                {{ cancelLabelByType }}</span
              >
            </SynButton>
            <SynButton
              v-if="confirmLabelByType"
              class="do-not-close-dropdown"
              :color="options?.confirmButtonColor || 'current'"
              @click.stop="
                tell(true, {
                  disableNextTime: confirmModalMetadata?.disableNextTime,
                })
              "
            >
              <span class="do-not-close-dropdown">
                {{ confirmLabelByType }}</span
              >
            </SynButton>
          </template>
        </div>
      </div>
    </template>
  </SynModal>
</template>
