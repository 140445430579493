export const CUSTOM_COLUMN_CONFIG_BY_CODE = {
    SET_FINISH: { nameCode: 'TASK_DETAIL_FINISH' },
    STATUS: { nameCode: 'TASK_EXPORT_COLUMNS_STATUS' },
    DOMAIN: { nameCode: 'TASK_CREATE_FORM_DOMAIN' },
    URGENCY: { nameCode: 'TASK_TABLE_LABEL_URGENCY' },
    DO_TODAY: { nameCode: 'TASK_CREATE_FORM_DOING_TODAY' },
    FINISH_TODAY: { nameCode: 'TASK_CREATE_FORM_FINISH_TODAY' },
    DEADLINE: { nameCode: 'TASK_TABLE_LABEL_DEADLINE' },
    OVERDUE: { nameCode: 'COMMON_LABEL_OVERDUE' },
    REPORTER: { nameCode: 'TASK_TABLE_LABEL_REPORTER' },
    CREATED_TIME: { nameCode: 'TASK_TABLE_LABEL_CREATED_DATE' },
    UPDATED_TIME: { nameCode: 'TASK_TABLE_LABEL_UPDATED_DATE' },
};
