import { IPersonalSetting } from '@/application/types/common/common.types';
import { AdminType } from '@/ui/common/constants/constant';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import systemConfig from '@/application/constants/system-config.const';

export const dashboardMenus = [
    {
        name: 'OverviewMonitorPage',
        titleKey: 'COMMON_LABEL_OVERVIEW',
        title: 'Overview',
        icon: 'home',
        permissionByRole: [AdminType.Admin, AdminType.Owner],
        index: 1,
        screenKey: ALL_SCREENS.COMMON.OVERVIEW.OVERVIEW,
    },

    {
        name: 'PerformanceOverview',
        titleKey: 'COMMON_LABEL_PERFORMANCE',
        title: 'Performance',
        icon: 'timer',
        permissionByRole: [AdminType.Admin, AdminType.Owner],
        index: 2,
        screenKey: ALL_SCREENS.COMMON.OVERVIEW.OVERVIEW,
    },
    {
        name: 'OverviewMembers',
        titleKey: 'SIDEBAR_LABEL_MY_TEAM',
        title: 'Team',
        icon: 'user',
        permissionByRole: [AdminType.Admin, AdminType.Owner, AdminType.User],
        index: 3,
        screenKey: ALL_SCREENS.USER.OVERVIEW.OVERVIEW,
    },
    {
        name: 'OverviewGroups',
        titleKey: 'SIDEBAR_LABEL_MY_GROUPS',
        title: 'My Groups',
        icon: 'groups',
        permissionByRole: [
            AdminType.Admin,
            AdminType.Owner,
            AdminType.User,
            AdminType.Visitor,
        ],
        index: 3,
        screenKey: ALL_SCREENS.GROUP.OVERVIEW.OVERVIEW,
    },
    {
        name: 'OverviewDomains',
        titleKey: 'COMMON_MODULE_PROJECT_MANAGEMENT',
        title: 'My Domains',
        icon: 'project',
        index: 5,
        permissionByRole: [AdminType.Admin, AdminType.Owner, AdminType.User],
        screenKey: ALL_SCREENS.DOMAIN.OVERVIEW.OVERVIEW,
        permissionByPersonalSettings: [
            {
                functionCode:
                    IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_DOMAIN,
                functionValue: true,
            },
        ],
    },
    {
        name: 'OverviewFinishedTasks',
        titleKey: 'OVERVIEW_FINISHED_TASKS',
        title: 'Finished tasks',
        icon: 'CheckCircle',
        permissionByRole: [AdminType.User],
        index: 6,
        screenKey: ALL_SCREENS.COMMON.OVERVIEW.FINISHED_TASK,
        permissionByPersonalSettings: [
            {
                functionCode:
                    IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_FINISHED_TASKS,
                functionValue: true,
            },
        ],
    },
    {
        name: 'OverviewCreatedTasks',
        titleKey: 'OVERVIEW_CREATED_TASKS',
        title: 'Created tasks',
        icon: 'note',
        permissionByRole: [AdminType.User],
        index: 7,
        screenKey: ALL_SCREENS.COMMON.OVERVIEW.NEW_TASK,
        permissionByPersonalSettings: [
            {
                functionCode:
                    IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_NEW_TASKS,
                functionValue: true,
            },
        ],
    },
    {
        name: 'OverviewWorkflows',
        titleKey: 'OVERVIEW_WORKFLOWS',
        title: 'Workflows',
        icon: 'Flow',
        permissionByRole: [AdminType.Admin, AdminType.Owner, AdminType.User],
        permissionBySettings: [
            {
                functionCode: 'ADVANCED_WORKFLOW_WORKFLOW_ALLOWED_BY_SYSTEM',
                functionValue: '1',
            },
            {
                functionCode: 'DRAFT_WORKFLOW_WORKFLOW_ALLOWED',
                functionValue: '1',
            },
            {
                functionCode: 'DRAFT_WORKFLOW_WORKFLOW_VIEW_BOARD_ALLOWED',
                functionValue: '1',
            },
        ],
        permissionByPersonalSettings: [
            {
                functionCode:
                    IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_WORKFLOW,
                functionValue: true,
            },
        ],
        index: 7,
        screenKey: ALL_SCREENS.COMMON.OVERVIEW.WORKFLOWS,
    },
    {
        name: 'PerformanceOverview',
        titleKey: 'COMMON_LABEL_PERFORMANCE',
        title: 'Performance',
        icon: 'timer',
        index: 8,
        screenKey: ALL_SCREENS.COMMON.OVERVIEW.PERFORMANCE,
        permissionByRole: [AdminType.Admin, AdminType.Owner, AdminType.User],
        permissionBySettings: [
            {
                functionCode: systemConfig.SHOW_HIDE_THE_TASK_PERFORMANCE_MENU,
                functionValue: '1',
            },
        ],
        permissionByPersonalSettings: [
            {
                functionCode:
                    IPersonalSetting.SETTING_PERSONAL_SHOW_OVERVIEW_REPORT,
                functionValue: true,
            },
        ],
    },
];

export const menuListType = {
    Monitor: 'OverviewMonitorPage',
    Members: 'OverviewMembers',
    Groups: 'OverviewGroups',
    Domains: 'OverviewDomains',
    FinishedTasks: 'OverviewFinishedTasks',
    CreatedTasks: 'OverviewCreatedTasks',
};
