<template>
  <svg
    v-if="!isActive"
    height="560pt"
    viewBox="-78 -18 560 560.00187"
    width="560pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m260.722656 3.878906-.128906-.125c-2.355469-2.429687-5.605469-3.7890622-8.996094-3.74999975-.417968-.01953125-.839844.01953125-1.25.12890575h-191.953125c-34.503906.007813-62.46875 27.976563-62.484375 62.484376v399.898437c.015625 34.503906 27.980469 62.472656 62.484375 62.484375h278.308594c34.503906-.011719 62.472656-27.980469 62.484375-62.484375v-315.175781c-.023438-3.308594-1.320312-6.484375-3.625-8.867188zm3.371094 38.742188 92.726562 92.476562h-55.234374c-20.679688-.0625-37.433594-16.8125-37.492188-37.488281zm72.734375 457.261718h-278.433594c-20.679687-.058593-37.425781-16.8125-37.492187-37.492187v-399.902344c.066406-20.675781 16.8125-37.429687 37.492187-37.488281h180.707031v72.609375c.011719 34.5 27.980469 62.46875 62.484376 62.484375h72.609374v302.296875c.039063 20.671875-16.695312 37.464844-37.367187 37.492187zm0 0"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g id="Layer_10" xmlns="http://www.w3.org/2000/svg" data-name="Layer 10">
        <path
          d="m22 8v-5.37l5.46 6.37h-4.46a1 1 0 0 1 -1-1zm1 3a3 3 0 0 1 -3-3v-6h-13a3 3 0 0 0 -3 3v22a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-16z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
