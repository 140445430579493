<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import UserById from '@/ui/components/user/UserById.vue';
import { AdminType } from '@/ui/common/constants/constant';
import myProfileStore from '@/store/auth/my-profile';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import userStore from '@/store/user';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const props = withDefaults(
  defineProps<{
    currentId: string | number;
    type: 'user' | 'group';
    placeholder: string;
    data: any[];
    inputClass: string;
    dropdownClass: string;
    objectContact: object;
    name: string;
    avatar: string;
    assignGroup: boolean;
    isShowDepartment: boolean;
    isNotCloseDropdownParent?: boolean;
    collaborators: any[];
  }>(),
  {
    type: 'user',
    inputClass:
      'border border-gray-300 py-2 px-3 rounded-md focus:outline-none focus:shadow-outline',
    dropdownClass:
      'absolute z-50 bg-white border border-gray-300 mt-1 mh-48 overflow-hidden overflow-y-auto rounded-md shadow-md',
  }
);
const emit = defineEmits(['chosen', 'reset', 'blur', 'remove']);

const _myProfileStore = myProfileStore();

const currentUser = computed(() => _myProfileStore.myProfile);
const myDepartment = computed(() => _myProfileStore.myDepartment);
const isInputting = ref(false);

const allUserByIds = computed(() => userStore().allUserByIds);

const selectedUsers = ref<any>([]);

onMounted(() => {
  selectedUsers.value = props.collaborators || [];
});

const searchTerm = ref('');
const searchResults = computed(() => {
  const searchTxt = ignoreUnicode(searchTerm.value);
  let result: any[] = [];
  if (!searchTxt)
    result = props.data.filter(
      (item: any) => !selectedUsers.value?.some((user) => user?.id == item?.id)
    );
  else
    result = (props.data || []).filter(
      (item: any) =>
        item?.name &&
        ignoreUnicode(item?.name).includes(searchTxt) &&
        !selectedUsers.value?.some((user) => user?.id == item?.id)
    );
  // return props.data?.filter((item) => {
  //   return item.name.toLowerCase().includes(searchTerm.value.toLowerCase());
  // });

  return result?.length
    ? arrayOrderBy(
        result,
        [
          (user) => {
            return user?.departmentIds?.length > 0
              ? user?.departmentIds[0] == myDepartment.value?.departmentId
                ? 0
                : user?.departmentIds[0]
              : null;
          },
          'name',
        ],
        ['asc', 'asc']
      )
    : [];
}) as any;

const vigDropdownRef = ref<any>(null);
watch(
  () => props.data?.length,
  () => {
    onOpenDropdown();
  }
);

const chooseItem = (item) => {
  selectedUsers.value.push(item);
  emit('chosen', item);
  isInputting.value = false;
  searchTerm.value = '';
  onOpenDropdown();
};

const clickedOutside = () => {
  isInputting.value = false;
};

const inputRef = ref<any>(null);

const onOpenAssign = () => {
  indexResult.value = -1;
  isInputting.value = true;

  setTimeout(() => {
    inputRef.value?.focus();
  });
};

const onClickInput = () => {
  indexResult.value = -1;
};

const keyActive = ref('search-assign');
const indexResult = ref(-1);
const onKeyDownSearch = () => {
  indexResult.value++;
  if (indexResult.value == searchResults.value.length) {
    indexResult.value = 0;
  }
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
const onKeyUpSearch = () => {
  indexResult.value--;
  keyActive.value = `search-assign-${indexResult.value}`;
  if (indexResult.value < 0) {
    indexResult.value = searchResults.value.length - 1;
  }
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
const onChooseResult = () => {
  if (indexResult.value < 0 || searchResults.value?.length == 0) return;

  let item = searchResults.value[indexResult.value];
  selectedUsers.value.push(item);
  emit('chosen', item);
  isInputting.value = false;
  searchTerm.value = '';
  indexResult.value = 0;
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  if (element && element?.scrollIntoView)
    element?.scrollIntoView({ block: 'center' });
};

const onBlur = () => {
  isInputting.value = false;
  emit('blur');
};

const onOpenDropdown = () => {
  vigDropdownRef.value?.onForceClose();
  vigDropdownRef.value?.onForceOpen();
};

const onRemoveSelectedUser = (user) => {
  selectedUsers.value = selectedUsers.value?.filter((u) => u?.id !== user?.id);
  emit('remove', user);
};

defineExpose({
  onOpenDropdown,
});
</script>

<template>
  <div
    class="
      relative
      flex flex-wrap
      items-center
      py-1
      px-2
      rounded
      focus:bg-white focus:outline-none focus:border-current
    "
    :class="{
      'do-not-close-dropdown': isNotCloseDropdownParent,
    }"
  >
    <div
      v-for="contact of selectedUsers"
      :key="contact?.id"
      class="
        rounded-full
        shadow
        bg-current-100
        text-gray-800 text-sm
        p-1
        m-0.5
        flex-center
        space-x-2
        w-max
        float-left
      "
    >
      <SynAvatar
        custom-class="w-5 h-5"
        :is-not-close-dropdown-parent="isNotCloseDropdownParent"
        :src="allUserByIds[contact?.id]?.avatar?.replace('original', 'small')"
        :name="allUserByIds[contact?.id]?.name"
      />
      <span v-if="allUserByIds[contact?.id]?.name">{{
        allUserByIds[contact?.id]?.name
      }}</span>
      <span v-else>{{ $t('COMMON_LABEL_DELETED_USER') }}</span>
      <button
        class="p-0.5 rounded-full hover:bg-current-200 flex-center text-xs"
        :class="{
          'do-not-close-dropdown': isNotCloseDropdownParent,
        }"
        @click.stop="onRemoveSelectedUser(allUserByIds[contact?.id])"
      >
        <SynIcon name="Close" custom-class="w-4 h-4" />
      </button>
    </div>
    <VigDropdown
      ref="vigDropdownRef"
      :arrow="false"
      :z-index="60"
      trigger="click"
      @on-dropdown-open="onOpenAssign"
      @on-dropdown-close="clickedOutside"
      @click.stop="onOpenAssign"
    >
      <template #dropdown-toggle>
        <input
          ref="inputRef"
          v-model="searchTerm"
          tabindex="0"
          class="flex-1 h-8 w-44 border-0 focus:ring-0"
          :class="{
            'do-not-close-dropdown': isNotCloseDropdownParent,
          }"
          :placeholder="placeholder"
          @focus="onClickInput"
          @blur="onBlur"
          @keyup.down="onKeyDownSearch"
          @keyup.up="onKeyUpSearch"
          @keyup.enter="onChooseResult"
        />
      </template>
      <template #dropdown-menu>
        <div class="flex flex-col">
          <div style="width: 12rem">
            <slot name="header"></slot>
          </div>
          <ul
            class="z-100 w-max rounded-md small-scrollbar overflow-auto syn p-2"
            style="width: 12rem; max-height: 24rem"
          >
            <li
              v-for="(item, index) in searchResults"
              :id="`search-assign-${index}`"
              :key="item"
              class="
                flex
                dropdown-item
                items-center
                space-x-2
                px-2
                py-1
                text-gray-700
                hover:bg-current-50
                cursor-pointer
                syn-select-assign-item
                rounded-md
              "
              :class="[
                isNotCloseDropdownParent ? 'do-not-close-dropdown' : '',
                keyActive == `search-assign-${index}`
                  ? 'bg-current-50 border border-current'
                  : '',
              ]"
              @click.stop.prevent="chooseItem(item)"
            >
              <span class="min-w-max relative">
                <UserById
                  v-if="type == 'user'"
                  :user-id="item?.id"
                  is-hidden-name
                  is-show-department
                  class="syn-select-assign-item"
                  :avatar-class="`h-8 w-8 ${
                    isNotCloseDropdownParent ? 'do-not-close-dropdown' : ''
                  }`"
                />

                <SynAvatarFavorite
                  v-else
                  :src="
                    item?.avatar?.replace('original', 'mini') ||
                    item?.avatarUrl?.replace('original', 'mini')
                  "
                  :custom-class="`w-6 h-6 syn-select-assign-item ${
                    isNotCloseDropdownParent ? 'do-not-close-dropdown' : ''
                  }`"
                  :name="item?.name || name"
                  :favorite-type="'group'"
                  :is-deactive="item?.isDeactive"
                />
                <div
                  v-if="type == 'group' && item?.isPrivate"
                  :title="
                    item?.isPrivate
                      ? $t('COMMON_LABEL_GROUP_PRIVATE')
                      : $t('COMMON_LABEL_GROUP_PUBLIC')
                  "
                  class="
                    flex-center
                    bg-white
                    absolute
                    -bottom-1
                    -right-1
                    p-0.5
                    rounded-full
                  "
                >
                  <SynIcon
                    class="fill-current"
                    custom-class="w-2 h-2"
                    :name="item?.isPrivate ? 'private' : 'global'"
                  />
                </div>
              </span>
              <div class="py-2 flex flex-col items-start">
                <div
                  :title="item?.name"
                  class="
                    w-full
                    text-sm
                    syn-select-assign-item
                    flex
                    items-center
                    justify-between
                  "
                >
                  <span
                    class="text-overflow-hidden-line syn-select-assign-item"
                  >
                    {{
                      item?.name ||
                      item?.fullName ||
                      item?.lastName + ' ' + item?.firstName
                    }}
                  </span>
                  <span
                    v-if="item.id == currentUser.id"
                    class="text-xs italic px-0.5"
                  >
                    {{ $t('COMMON_LABEL_ME') }}
                  </span>
                  <span
                    v-else-if="item.adminType == AdminType.Visitor"
                    class="text-xs italic"
                  >
                    {{ $t('COMMON_LABEL_VISITOR') }}
                  </span>
                </div>
                <div v-if="isShowDepartment && item?.departmentIds?.length > 0">
                  <DepartmentById
                    :department-id="item?.departmentIds[0]"
                    is-hidden-avatar
                    class="text-xs text-gray-500 italic"
                  />
                </div>
              </div>
            </li>
            <li v-if="!searchResults?.length" class="p-6 text-center">
              <SynAnimation
                name="searchIcon"
                :loop="false"
                stype="width: 100px; height: 100px"
                :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
              />
            </li>
          </ul>
        </div>
      </template>
    </VigDropdown>
  </div>
</template>

<style scoped>
.mh-48 {
  max-height: 15rem;
}
</style>
