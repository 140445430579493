<script setup lang="ts">
import { ref, watch, computed } from 'vue';

const props = defineProps({
  startedTime: {
    type: Number, // time stamp
    default: 0,
  },
  type: {
    type: String, // duration,
    required: true,
    default: 'current',
  },
  start: {
    type: Boolean,
    required: true,
    default: false,
  },
  isHiddenSecond: {
    type: Boolean,
    default: false,
  },
  isHiddenHour: {
    type: Boolean,
    default: false,
  },
  formatAmPm: {
    type: Boolean,
    default: false,
  },
  diffMiliseconds: {
    type: Number,
    default: 0,
  },
  timezone: {
    type: String,
    default: '+7',
  },
});
const emit = defineEmits(['onStop']);
// Set the date we're counting down to
const days = ref('');
const hours = ref('00');
const minutes = ref('00');
const seconds = ref('00');
// Update the count down every 1 second
// const zone = computed(() => parseInt(props.timezone) * 60 * 60 * 1000); // convert zone to miliseconds
let intervalFunction;
const distance = ref(0);
const runCountDown = () => {
  const countDuration = Date.now();
  intervalFunction = setInterval(function () {
    // Get today's date and time
    const now = Date.now();
    // Find the distance between now and the count down date
    if (props.type === 'current') {
      distance.value = now + props.diffMiliseconds;
      hours.value = ('0' + new Date(distance.value).getHours()).slice(-2);
      minutes.value = ('0' + new Date(distance.value).getMinutes()).slice(-2);
      seconds.value = ('0' + new Date(distance.value).getSeconds()).slice(-2);
    } else {
      distance.value = now - countDuration + props.startedTime;
      // Time calculations for days, hours, minutes and seconds
      const _days = Math.floor(distance.value / (1000 * 60 * 60 * 24));
      const _hours = Math.floor(
        (distance.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const _minutes = Math.floor(
        (distance.value % (1000 * 60 * 60)) / (1000 * 60)
      );
      const _seconds = Math.floor((distance.value % (1000 * 60)) / 1000);
      days.value = _days > 0 ? _days + 'd ' : '';
      hours.value = ('0' + _hours).slice(-2);
      minutes.value = ('0' + _minutes).slice(-2);
      seconds.value = ('0' + _seconds).slice(-2);
    }

    // Output the result in an element with id="demo"
    // cdValue.value = `${days > 0 ? days + "d " : ""}${("0" + hours).slice(
    //   -2
    // )}:${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`;
    // cdValue.value = minutes + "m " + seconds + "s";

    // If the count down is over, write some text
    // if (distance < 0) {
    //   clearInterval(intervalFunction);
    // }
  }, 1000);
};
if (props.start) {
  runCountDown();
}
watch(
  () => props.start,
  () => {
    if (props.start) {
      runCountDown();
    } else {
      clearInterval(intervalFunction);
      emit('onStop', distance.value);
    }
  }
);
const hoursShow = computed(() =>
  props.formatAmPm && hours.value && hours.value > 12
    ? `0${parseInt(hours.value) - 12}`.slice(-2)
    : hours.value
);
const suffixString = computed(() =>
  props.formatAmPm ? (hours.value > 12 ? 'PM' : 'AM') : ''
);
</script>

<template>
  <div class="flex-center">
    <span v-if="days" class="w-max">{{ days }}</span>
    <span
      v-if="!isHiddenHour || (isHiddenHour && hoursShow !== '00')"
      class="w-max"
      >{{ hoursShow }}</span
    >
    <span
      v-if="!isHiddenHour || (isHiddenHour && hoursShow !== '00')"
      class="w-max"
      >{{ ' : ' }}</span
    >
    <span class="w-max">{{ minutes }}</span>
    <template v-if="!isHiddenSecond">
      <span class="w-max">{{ ' : ' }}</span>
      <span class="w-max">{{ seconds }}</span>
    </template>
    <span v-if="suffixString">{{ suffixString }}</span>
  </div>
</template>
