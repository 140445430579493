<script setup lang="ts">
import { computed, ref } from 'vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import {
  TaskApprovalStatus,
  ITaskApprover,
} from '@/application/types/task/task.types';
import myProfileStore from '@/store/auth/my-profile';
import UserById from '@/ui/components/user/UserById.vue';
import TaskApprovalIconByStatus from '@/ui/modules/task/approval/TaskApprovalIconByStatus.vue';
import TaskApprovalModal from '@/ui/modules/task/approval/TaskApprovalModal.vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';

const props = withDefaults(
  defineProps<{
    taskDetail?: ITaskDetailClass;
    approvalEnabled?: boolean;
    approvalStatus?: TaskApprovalStatus;
    approvers?: ITaskApprover[];
    approverId?: number;
    readonly?: boolean;
    isShowSticker?: boolean;
    isRequestOnly?: boolean;
    isTaskDetailPage?: boolean;
  }>(),
  {}
);

const emit = defineEmits([
  'onEdit',
  'onRemove',
  'onCancelApprovalRequest',
  'requestApproval',
]);

const myProfile = computed<any>(() => {
  return myProfileStore().myProfile;
});

const isApprover = computed<any>(() => {
  return props.approvers?.some((user) => user?.userId == myProfile.value?.id);
});
const isOpenDropdown = ref<boolean>(false);

const onOpenChangeApproverModal = () => {
  emit('onEdit');
};

const onCancelApproval = () => {
  emit('onRemove');
};
const onCancelApprovalRequest = () => {
  emit('onCancelApprovalRequest');
};

const approver = computed<any>(() => {
  return props.approvers && props.approvers?.length > 0
    ? props.approvers[0]
    : {};
});

const isOpenRequestWithEvidenceModal = ref<boolean>(false);

const onChangeApprovalStatus = (comment) => {
  isOpenRequestWithEvidenceModal.value = false;
  emit('requestApproval', comment);
};

const onClickRequestApproval = () => {
  if (props.taskDetail?.finishProofEnabled) {
    isOpenRequestWithEvidenceModal.value = true;
    return;
  }

  emit('requestApproval', {
    isCreateComment: true,
    isSkipProvidingEvidence: false,
    content: '',
    attachments: {
      files: [] as any[],
      notes: [] as any[],
    },
    mentionUserIds: [],
    mentionGroup: false,
  });
};
</script>

<template>
  <div v-if="approvalEnabled" class="flex-center space-x-1 pin-task-current">
    <AtomTooltip
      v-if="approvalStatus == TaskApprovalStatus.NOT_REQUESTED && isRequestOnly"
    >
      <template #trigger>
        <VigDropdown
          v-if="!readonly"
          @on-dropdown-open="isOpenDropdown = true"
          @on-dropdown-close="isOpenDropdown = false"
        >
          <template #dropdown-toggle>
            <div
              class="flex-center gap-0.5 cursor-pointer hover-to-show__parent"
            >
              <div
                class="
                  flex-center
                  rounded
                  h-[1.15rem]
                  w-[1.15rem]
                  border border-gray-150
                "
                :class="isOpenDropdown ? 'bg-gray-100' : ''"
              >
                <SynIcon name="approval" class="fill-gray-500" />
              </div>

              <div class="w-4 h-4 flex-center">
                <div
                  class="w-4 h-4 cursor-pointer rounded flex items-center"
                  :class="isOpenDropdown ? '' : 'hover-to-show__children'"
                >
                  <SynIcon
                    name="chevron-right"
                    :custom-class="
                      isOpenDropdown
                        ? 'w-2 h-2 fill-gray-500 -rotate-90'
                        : 'w-2 h-2 fill-gray-500 rotate-90'
                    "
                  />
                </div>
              </div>
            </div>
          </template>
          <template #dropdown-menu>
            <div class="p-1">
              <div
                class="
                  flex
                  items-center
                  space-x-2
                  p-2
                  hover:bg-current-50
                  cursor-pointer
                  dropdown-item
                "
                @click="onClickRequestApproval"
              >
                <span class="text-sm text-current-500">
                  {{ $t('COMMON_LABEL_APPROVAL_REQUEST') }}
                </span>
              </div>
            </div>
          </template>
        </VigDropdown>
      </template>
      <template #body>
        <div class="flex flex-col p-4 gap-2 rounded-md">
          <span class="text-sm">
            {{
              $t(
                approverId
                  ? 'TASK_LABEL_APPROVED_BY'
                  : 'TASK_LABEL_THIS_TASK_WAS_APPROVED'
              )
            }}
          </span>
          <UserById
            v-if="approverId"
            :user-id="approverId"
            class="text-sm"
            avatar-class="w-5 h-5"
          />
        </div>
      </template>
    </AtomTooltip>
    <AtomTooltip v-else-if="approvalStatus == TaskApprovalStatus.APPROVED">
      <template #trigger>
        <slot>
          <TaskApprovalIconByStatus
            :approval-status="approvalStatus"
            :is-show-sticker="isShowSticker"
          />
        </slot>
      </template>
      <template #body>
        <div class="flex flex-col p-4 gap-2 rounded-md">
          <span class="text-sm">
            {{
              $t(
                approverId
                  ? 'TASK_LABEL_APPROVED_BY'
                  : 'TASK_LABEL_THIS_TASK_WAS_APPROVED'
              )
            }}
          </span>
          <UserById
            v-if="approverId"
            :user-id="approverId"
            class="text-sm"
            avatar-class="w-5 h-5"
          />
        </div>
      </template>
    </AtomTooltip>

    <AtomTooltip
      v-else-if="
        !isTaskDetailPage ||
        (isTaskDetailPage &&
          !(approvalStatus == TaskApprovalStatus.WAITING && isApprover))
      "
    >
      <template #trigger>
        <slot>
          <VigDropdown
            v-if="!readonly"
            @on-dropdown-open="isOpenDropdown = true"
            @on-dropdown-close="isOpenDropdown = false"
          >
            <template #dropdown-toggle>
              <div
                class="flex-center gap-0.5 cursor-pointer hover-to-show__parent"
              >
                <TaskApprovalIconByStatus
                  :approval-status="approvalStatus"
                  :is-show-sticker="isShowSticker"
                />
                <div
                  class="w-4 h-4 cursor-pointer rounded flex items-center"
                  :class="isOpenDropdown ? '' : 'hover-to-show__children'"
                >
                  <SynIcon
                    name="chevron-right"
                    :custom-class="
                      isOpenDropdown
                        ? 'w-2 h-2 fill-gray-500 -rotate-90'
                        : 'w-2 h-2 fill-gray-500 rotate-90'
                    "
                  />
                </div>
              </div>
            </template>
            <template #dropdown-menu>
              <template v-if="approvalStatus == TaskApprovalStatus.WAITING">
                <div class="p-1">
                  <div
                    class="
                      flex
                      items-center
                      space-x-2
                      p-2
                      hover:bg-current-50
                      cursor-pointer
                      dropdown-item
                    "
                    @click="onCancelApprovalRequest"
                  >
                    <span class="text-sm text-red-500">
                      {{ $t('TASK_LABEL_CANCEL_APPROVAL_REQUEST') }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-1">
                  <div
                    class="
                      flex
                      items-center
                      space-x-2
                      p-2
                      hover:bg-current-50
                      cursor-pointer
                      dropdown-item
                    "
                    @click="onOpenChangeApproverModal"
                  >
                    <span class="text-sm text-blue-500">
                      {{ $t('TASK_LABEL_CHANGE_APPROVER') }}
                    </span>
                  </div>
                  <div
                    class="
                      flex
                      items-center
                      space-x-2
                      p-2
                      hover:bg-current-50
                      cursor-pointer
                      dropdown-item
                    "
                    @click="onCancelApproval"
                  >
                    <span class="text-sm text-red-500">
                      {{ $t('TASK_LABEL_DO_NOT_NEED_APPROVAL') }}
                    </span>
                  </div>
                </div>
              </template>
            </template>
          </VigDropdown>
          <div v-else class="flex-center">
            <TaskApprovalIconByStatus
              :approval-status="approvalStatus"
              :is-show-sticker="isShowSticker"
            />
          </div>
        </slot>
      </template>
      <template #body>
        <div class="flex flex-col p-4 gap-2">
          <div class="flex flex-col flex-wrap gap-2 text-sm">
            <span
              v-if="approver?.userId"
              v-html="
                $t(
                  approvalStatus == TaskApprovalStatus.WAITING
                    ? 'TASK_LABEL_WAITING_FOR_APPROVAL_BY'
                    : 'TASK_LABEL_NEED_TO_APPROVAL_BY'
                )
              "
            >
            </span>
            <span v-else>
              {{
                $t(
                  approvalStatus == TaskApprovalStatus.WAITING
                    ? 'TASK_LABEL_THIS_TASK_IS_WAITING_APPROVING'
                    : 'TASK_LABEL_WAITING_APPROVAL_TASKS_2'
                )
              }}
            </span>
            <UserById v-if="approver?.userId" :user-id="approver?.userId" />
          </div>
        </div>
      </template>
    </AtomTooltip>
  </div>

  <TaskApprovalModal
    v-if="isOpenRequestWithEvidenceModal"
    :task="taskDetail"
    :approval-status="TaskApprovalStatus.WAITING"
    view-mode="REQUEST_APPROVAL_WITH_EVIDENCE"
    @on-close="isOpenRequestWithEvidenceModal = false"
    @confirm="onChangeApprovalStatus"
  />
</template>
