<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 128 128"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="64.015"
        cy="89.962"
        r="2.5"
        data-original="#000000"
        class=""
      ></circle>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="38.038"
        cy="63.985"
        r="2.5"
        data-original="#000000"
        class=""
      ></circle>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="89.991"
        cy="63.985"
        r="2.5"
        data-original="#000000"
        class=""
      ></circle>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m62.247 36.241a2.5 2.5 0 1 0 3.536 0 2.5 2.5 0 0 0 -3.536 0z"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m97.272 37.132 1.942-1.943a4.1 4.1 0 0 0 4.67-6.592l-4.358-4.358a4.1 4.1 0 0 0 -6.592 4.67l-1.928 1.927a42.623 42.623 0 0 0 -20.308-9.1v-2.463a6.6 6.6 0 0 0 -.172-13.19h-13.052a6.6 6.6 0 0 0 -.172 13.19v2.463a42.623 42.623 0 0 0 -20.308 9.1l-1.928-1.927a4.1 4.1 0 0 0 -6.592-4.67l-4.358 4.361a4.1 4.1 0 0 0 4.67 6.592l1.942 1.943a42.792 42.792 0 1 0 66.544 0zm-10.572 47.45-4.436-4.436a1.5 1.5 0 0 0 -2.121 2.121l4.439 4.433a30.6 30.6 0 0 1 -41.164 0l4.436-4.436a1.5 1.5 0 0 0 -2.121-2.121l-4.433 4.439a30.6 30.6 0 0 1 0-41.164l4.436 4.437a1.5 1.5 0 0 0 2.122-2.122l-4.44-4.433a30.6 30.6 0 0 1 41.164 0l-4.437 4.436a1.5 1.5 0 1 0 2.122 2.122l4.433-4.44a30.6 30.6 0 0 1 0 41.164z"
        data-original="#000000"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m66.519 59.352v-9.932a2.519 2.519 0 0 0 -5.038 0v9.932a5.3 5.3 0 0 0 -2.793 4.648 5.219 5.219 0 0 0 .247 1.516l-9.269 9.269a2.519 2.519 0 0 0 3.563 3.563l9.276-9.276a5.243 5.243 0 0 0 1.495.241 5.294 5.294 0 0 0 2.519-9.961z"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
