<script setup lang="ts">
import { computed, onMounted, watch } from 'vue';
import Chart, { ChartConfiguration } from 'chart.js/auto';

const pieChartId = `tictop-common-pie-chart-${Date.now()}`;
const props = withDefaults(
  defineProps<{
    customClass: string;
    propData: any[];
    isHiddenTitle: boolean;
  }>(),
  {
    customClass: 'w-40',
  }
);
let pieChart: Chart;

const datasets = computed(() => {
  return [
    {
      data: props.propData.map((o) => o.data),
      backgroundColor: props.propData.map((o) => o.color),
      hoverOffset: 4,
    },
  ];
});

const initData = () => {
  if (!props.propData || props.propData?.length == 0) return;

  const data = {
    datasets: datasets.value,
  };
  const config: ChartConfiguration = {
    type: 'pie',
    data: data,
  };

  const ctx = document.getElementById(pieChartId) as HTMLCanvasElement;

  if (!ctx) return;

  pieChart = new Chart(ctx, config);
};

onMounted(() => {
  initData();
});

watch(
  () => props.propData,
  () => {
    pieChart.data.datasets = datasets.value;
    pieChart?.update();
  },
  { deep: true }
);
</script>
<template>
  <div
    class="w-full h-full flex"
    :class="[isHiddenTitle ? 'justify-center' : 'justify-between']"
  >
    <div v-if="!isHiddenTitle" class="flex flex-col items-start space-y-2 pt-4">
      <div
        v-for="item in propData"
        :key="item"
        class="flex items-center space-x-2 w-full"
      >
        <span
          class="w-4 h-4 rounded-full border shrink-0 flex-none"
          :style="`background-color : ${item?.color}`"
        ></span>
        <span
          :title="item?.name"
          class="text-sm font-semibold text-overflow-hidden-line"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
    <div class="relative" :class="customClass">
      <canvas :id="pieChartId" class="w-full h-full"></canvas>
    </div>
  </div>
</template>
