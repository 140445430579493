<script setup lang="ts">
import { computed } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskBoardCardUrgency from '@/ui/modules/task/board/board-container/TaskBoardCardUrgency.vue';
import { TaskUrgency } from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

withDefaults(
  defineProps<{
    currentContactId: any;
    type: 'USER' | 'GROUP' | 'DOMAIN' | 'IA';
  }>(),
  {
    currentContactId: getCurrentUserId(),
    type: 'USER',
  }
);
const cardList = computed<any[]>(() => {
  return Object.values(TaskUrgency)?.map((urgency) => {
    return {
      ...urgency,
      id: urgency?.key,
      nameKey: urgency?.textCode,
      name: translate(urgency?.textCode),
      filterObject: urgency?.data,
    };
  });
});
</script>

<template>
  <div
    v-drag-to-scroll="{ targetClass: 'drag-to-scroll-target' }"
    class="h-full flex"
  >
    <template v-for="urgency in cardList" :key="urgency?.key">
      <TaskBoardCardUrgency
        class="text-xs mx-1.5"
        :type="type"
        :card-id="urgency?.key"
        :card-information="urgency"
        :source-id="currentContactId"
      />
    </template>
  </div>
</template>
