<script setup lang="ts">
import { computed, ref } from 'vue';
import driveDocumentPageStore from '@/store/driveDocument/drive-document-page';
import GoogleDocViewer from '@/ui/modules/ged/_commons/viewer-google-document/GoogleDocViewer.vue';
import DriveDocumentTabs from '@/ui/modules/drive-document/drive-document-tabs/DriveDocumentTabs.vue';
import PanelCanFullscreen from '@/ui/components/fullscreen/PanelCanFullscreen.vue';
import ToolsSidebar from '@/ui/modules/tools/tools-sidebar/ToolsSidebar.vue';
import DriveDocumentsList from '@/ui/modules/drive-document/drive-documents-list/DriveDocumentsList.vue';

const _drivePageStore = driveDocumentPageStore();

const isFullscreen = ref<boolean>(false);

const tabs = computed(() => {
  return _drivePageStore.pageTabs;
});
const activeTab = computed(() => _drivePageStore.pageActiveTab);
const isPageVisible = computed(() => _drivePageStore.pageIsVisible);
</script>

<template>
  <div class="flex bg-gray-100">
    <ToolsSidebar v-if="isPageVisible" is-show-button-extend />

    <div class="flex-1 overflow-hidden">
      <PanelCanFullscreen
        :is-fullscreen="isFullscreen"
        :is-show-fullscreen-header="false"
      >
        <template #content>
          <div class="h-full flex flex-col bg-gray-100">
            <!--TABS-->
            <DriveDocumentTabs v-model:is-fullscreen="isFullscreen" />

            <!--TABS CONTENT-->
            <div class="flex-1 overflow-hidden bg-white relative">
              <div
                v-for="tab in tabs"
                :key="tab?.id"
                class="absolute top-1 left-0 right-0 bottom-0"
                :class="{ invisible: tab?.id !== activeTab?.id }"
              >
                <GoogleDocViewer
                  v-if="!tab?.isNewTab"
                  :path="tab?.link"
                  :owner-info="tab"
                />

                <DriveDocumentsList v-else />
              </div>
            </div>
          </div>
        </template>
      </PanelCanFullscreen>
    </div>
  </div>
</template>
