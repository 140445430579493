<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.929"
    height="18.073"
    viewBox="0 0 14.929 18.073"
  >
    <g
      id="Group_3056"
      data-name="Group 3056"
      transform="translate(19.714 -11.5)"
    >
      <g
        id="Group_3051"
        data-name="Group 3051"
        transform="translate(-19.714 11.5)"
      >
        <g id="Group_3011" data-name="Group 3011">
          <g id="Group_3010" data-name="Group 3010">
            <path
              id="Path_1005"
              data-name="Path 1005"
              d="M114.961,200.691a.691.691,0,0,0-.691-.691H107.15a.691.691,0,1,0,0,1.382h7.119A.691.691,0,0,0,114.961,200.691Z"
              transform="translate(-103.698 -193.088)"
            />
            <path
              id="Path_1006"
              data-name="Path 1006"
              d="M107.15,280a.691.691,0,1,0,0,1.382h4.324a.691.691,0,0,0,0-1.382Z"
              transform="translate(-103.698 -270.323)"
            />
            <path
              id="Path_1007"
              data-name="Path 1007"
              d="M31.647,16.312H29.335a1.384,1.384,0,0,1-1.382-1.382V2.765a1.384,1.384,0,0,1,1.382-1.382h8.5a1.384,1.384,0,0,1,1.382,1.382V7.016a.691.691,0,1,0,1.382,0V2.765A2.768,2.768,0,0,0,37.833,0h-8.5A2.768,2.768,0,0,0,26.57,2.765V14.93a2.768,2.768,0,0,0,2.765,2.765h2.312a.691.691,0,0,0,0-1.382Z"
              transform="translate(-26.57)"
            />
            <path
              id="Path_1009"
              data-name="Path 1009"
              d="M114.27,120H107.15a.691.691,0,1,0,0,1.382h7.119a.691.691,0,1,0,0-1.382Z"
              transform="translate(-103.698 -115.853)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3052"
        data-name="Group 3052"
        transform="translate(-40.785 21.309)"
      >
        <path
          id="Path_1013"
          data-name="Path 1013"
          d="M35.509,4.14V2.811A2.941,2.941,0,0,0,34.8.789a2.427,2.427,0,0,0-3.582,0,2.948,2.948,0,0,0-.724,2.022V4.14A.7.7,0,0,0,30,4.792V7.585a.69.69,0,0,0,.692.679h4.642A.669.669,0,0,0,36,7.585V4.792A.658.658,0,0,0,35.509,4.14ZM32.984,6.692a.5.5,0,1,1,.5-.5A.5.5,0,0,1,32.984,6.692Zm1.582-2.579H31.434v-1.3a1.59,1.59,0,1,1,3.132,0Z"
        />
      </g>
    </g>
  </svg>
</template>
