<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m58 6h-8.5v-2a2 2 0 0 0 -4 0v2h-27v-2a2 2 0 0 0 -4 0v2h-8.5a6 6 0 0 0 -6 6v44a6 6 0 0 0 6 6h52a6 6 0 0 0 6-6v-44a6 6 0 0 0 -6-6zm-52 4h8.5v2a2 2 0 0 0 4 0v-2h27v2a2 2 0 0 0 4 0v-2h8.5a2 2 0 0 1 2 2v5h-56v-5a2 2 0 0 1 2-2zm52 48h-52a2 2 0 0 1 -2-2v-35h56v35a2 2 0 0 1 -2 2z"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m45 27.41a2 2 0 0 0 -2.8.38l-14.61 19.12-5.88-6.28a2 2 0 1 0 -2.92 2.74l7.5 8a2 2 0 0 0 1.46.63h.1a2 2 0 0 0 1.49-.79l16-21a2 2 0 0 0 -.34-2.8z"
        class=""
      ></path>
    </g>
  </svg>
</template>
