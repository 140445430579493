<script setup lang="ts">
import { ref } from 'vue';
import StartConference from '@/ui/modules/video-call/components/StartConference.vue';

defineProps<{
  title?: string;
  attachedTask?: any;
  attachedDayoff?: any;
  attachedEvent?: any;
  isBlockBubbleChat?: boolean;
  hasExternalChat?: boolean;
}>();
const emit = defineEmits<{
  (e: 'onClose'): void;
}>();
const currentTab = ref('histories');
const onClose = () => {
  emit('onClose');
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-style="width: 29rem; max-width: 1200px;height: 80vh"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    :is-hidden-footer="true"
    @cancel="onClose"
  >
    <template #header>
      <div class="flex justify-between">
        <span class="text-base">
          {{ title || $t('INTRODUCE_LABEL_CALL_VIDEO') || 'New chat' }}
        </span>
      </div>
    </template>

    <template #body>
      <StartConference
        :current-tab="currentTab"
        :is-show-close="false"
        @on-close="onClose"
        @update-current-tab="(value) => (currentTab = value)"
      />
    </template>
  </SynModal>
</template>
