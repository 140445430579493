import { setLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { activeState } from '@/ticket/pages/layouts/ticket-layout-state';

enum EventIdEnum {
    OPEN_NOTE = 'OPEN_NOTE',
    CLOSE_NOTE = 'CLOSE_NOTE',
    COLLAPSE_NOTE = 'COLLAPSE_NOTE',
    COLLAPSE = 'COLLAPSE',
    NOTIFICATION = 'NOTIFICATION',
    CLOSE_DRAG_ELEMENT = 'CLOSE_DRAG_ELEMENT',
    SENT_ME_TOKEN = 'SENT_ME_TOKEN',
}

const closeDragElement = () => {
    _postMessageToIframe(EventIdEnum.CLOSE_DRAG_ELEMENT);
};
const sentMeToken = () => {
    _postMessageToIframe(EventIdEnum.SENT_ME_TOKEN);
};
const openNote = () => {
    _postMessageToIframe(EventIdEnum.OPEN_NOTE);
};
const closeNote = () => {
    _postMessageToIframe(EventIdEnum.CLOSE_NOTE);
};
const collapseNote = () => {
    _postMessageToIframe(EventIdEnum.COLLAPSE_NOTE);
};
const collapse = () => {
    _postMessageToIframe(EventIdEnum.COLLAPSE);
};
const pushNotification = (count) => {
    _postMessageToIframe(EventIdEnum.NOTIFICATION, count);
};

const _postMessageToIframe = (eventId: EventIdEnum, data?: any) => {
    if (!window?.parent) return;

    window.parent.postMessage(
        {
            event_id: eventId,
            data: data,
        },
        '*'
    );
};

const onListenGetToken = (callback) => {
    window.addEventListener(
        'message',
        (event) => {
            if (!event?.data) return;

            const data = event.data;
            if (!data.isFromTicketPlugin) return;

            switch (data.key) {
                case 'init':
                    {
                        setLocalStorage(
                            StorageConstant.TICKET_TOKEN,
                            data.token
                        );

                        setLocalStorage(
                            StorageConstant.TICKET_EXTERNAL_ROOT_ID,
                            data.externalUser?.rootId || ''
                        );
                        setLocalStorage(
                            StorageConstant.TICKET_EXTERNAL_UID,
                            data.externalUser?.id || ''
                        );
                        setLocalStorage(
                            StorageConstant.TICKET_EXTERNAL_FIRST_NAME,
                            data.externalUser?.firstName || ''
                        );
                        setLocalStorage(
                            StorageConstant.TICKET_EXTERNAL_LAST_NAME,
                            data.externalUser?.lastName || ''
                        );
                        setLocalStorage(
                            StorageConstant.TICKET_EXTERNAL_EMAIL,
                            data.externalUser?.email || ''
                        );

                        callback();
                    }
                    break;
                case 'on-change-state':
                    {
                        activeState.value = data.state || null;
                    }
                    break;

                default:
                    break;
            }
        },
        false
    );
};
export default {
    sentMeToken,
    onListenGetToken,
    openNote,
    closeNote,
    collapseNote,
    collapse,
    closeDragElement,
    pushNotification,
};
