import { ITaskRepeat } from '@/application/interfaces/tasks/task-interfaces';
import { getDefaultTaskSchedule } from '@/application/parsers/TaskParser';
import {
    ITaskApprover,
    TaskApprovalStatus,
    TaskCollaborator,
} from '@/application/types/task/task.types';
import { TaskSchedule } from '@/domain/entities/TaskType';
import {
    PlanTypeEnum,
    ScheduleDayOptions,
    TaskLife,
} from '@/domain/enums/taskEnum';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';
import dayjs from 'dayjs';
import myProfileStore from '@/store/auth/my-profile';
import localConfigsStore from '@/store/local-configs';
import {
    ask,
    settingGlobalModal,
    tell,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

export interface ITaskOriginalClass {
    id: number;
    parentId?: number;
    parentCode?: string;
    parentName?: string;
    groupId?: number;
    name: string;
    description: string;
    taskLife: number;
    scheduleTime: string | null;
    approvalEnabled?: boolean;
    approverId?: number;
    approvers?: ITaskApprover[];
    assigneeId?: number;
    creatorId: number | null;
    lastModifierId: number | null;
    domainId: number;
    projectId?: number;
    subprojectId?: number;
    estimationTime: number;
    finisherId: number | null;
    taskSchedule: TaskSchedule;
    planType: number;
    priority: number | null;
    taskType: number;
    important: boolean | null;
    isPrivate: boolean | null;
    files: any[];
    notes: any[];
    taggedMembers: any[];
    taskRepeat: ITaskRepeat;
    isRepeat: boolean;
    taskSnapshotTotal: number;
    collaborators: TaskCollaborator[];
    scheduleByHour?: boolean;
    scheduleTimezone?: string;
    startTime?: string;
    scheduleTimeLocked?: boolean;
    isClone?: boolean;
}

export default class TaskOriginalClass implements ITaskOriginalClass {
    id: number;
    parentId?: number;
    parentCode?: string;
    parentName?: string;
    groupId?: number;
    name: string;
    description: string;
    taskLife: number;
    assigneeId?: number;
    creatorId: number;
    lastModifierId: number | null;
    domainId: number;
    projectId?: number;
    subprojectId?: number;
    estimationTime: number;
    finisherId: number | null;
    taskSchedule: TaskSchedule;
    planType: number;
    priority: number | null;
    taskType: number;
    important: boolean | null;
    isPrivate: boolean | null;
    files: any[];
    notes: any[];
    taggedMembers: any[];
    taskRepeat: ITaskRepeat;
    isRepeat: boolean;
    taskSnapshotTotal: number;
    collaborators: TaskCollaborator[];
    // scheduleTime
    startTime?: string;
    scheduleByHour?: boolean;
    scheduleTime: string | null;
    scheduleTimezone?: string;
    // approval

    // Approval
    approvalEnabled?: boolean;
    approvalStatus?: TaskApprovalStatus;
    approverApprovalDate?: any;
    approverApprovalStatus?: number;
    approverId?: number;
    approvers?: ITaskApprover[];
    approverIds?: number[];
    scheduleTimeLocked?: boolean;
    isClone?: boolean;

    constructor(_data: any) {
        const data = prepareInitData(_data);
        this.id = data?.id;
        this.parentId = data?.parentId;
        this.parentCode = data?.parentCode;
        this.parentName = data?.parentName;
        this.groupId = data?.groupId;
        this.name = data?.name;
        this.description = data?.description;
        this.taskLife = data?.taskLife;
        this.assigneeId = data?.assigneeId;
        this.creatorId = data?.creatorId;
        this.lastModifierId = data?.lastModifierId;
        this.domainId = data?.domainId;
        this.projectId = data?.projectId;
        this.subprojectId = data?.subprojectId;
        this.estimationTime = data?.estimationTime;
        this.finisherId = data?.finisherId;
        this.taskSchedule = data?.taskSchedule;
        this.planType = data?.planType;
        this.priority = data?.priority;
        this.taskType = data?.taskType;
        this.important = data?.important;
        this.isPrivate = data?.isPrivate;
        this.files = data?.files;
        this.notes = data?.notes;
        this.taggedMembers = data?.taggedMembers;
        this.taskRepeat = data?.taskRepeat;
        this.isRepeat = data?.isRepeat;
        this.taskSnapshotTotal = data?.taskSnapshotTotal;
        this.collaborators = data?.collaborators;
        this.startTime = data?.startTime;
        this.scheduleByHour = data?.scheduleByHour;
        this.scheduleTime = data?.scheduleTime;
        this.scheduleTimezone = data?.scheduleTimezone;
        this.approvalEnabled = data?.approvalEnabled;
        this.approvalStatus = data?.approvalStatus;
        this.approverApprovalDate = data?.approverApprovalDate;
        this.approverApprovalStatus = data?.approverApprovalStatus;
        this.approverId = data?.approverId;
        this.approvers = data?.approvers;
        this.approverIds = data?.approverIds;
        this.scheduleTimeLocked = data?.scheduleTimeLocked;
        this.isClone = data?.isClone;
    }
    changeState(state: TaskLife) {
        this.taskLife = state;
        if (state !== TaskLife.Finished) return;
        this.finisherId = getCurrentUserId();
    }

    doItToday(isDoItToday: boolean) {
        if (isDoItToday) {
            if (
                this.taskLife == TaskLife.Todo ||
                this.taskLife == TaskLife.InProcess
            )
                return;

            this.taskLife = TaskLife.Todo;
            return;
        }

        this.taskSchedule.finishItToday = false;

        if (this.taskSchedule.scheduleOptionKey !== ScheduleDayOptions.OnDay)
            return;
        this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.Nothing;
        this.scheduleTime = '';
    }

    finishItToday(isFinishItToday: boolean) {
        if (isFinishItToday) this.doItToday(true);

        this.updateDataByFinishToday(isFinishItToday);
    }

    changePrivate(isPrivate: any) {
        this.isPrivate = isPrivate;
    }

    changeDomain(domainData: any) {
        this.domainId = domainData.domainId;
        this.projectId = domainData.projectId;
        this.subprojectId = domainData.subprojectId;
    }

    updateDataByFinishToday(isFinishItToday) {
        if (isFinishItToday) {
            this.taskSchedule.finishItToday = true;
            this.taskSchedule.doItToday = true;
            this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.OnDay;
            return;
        }

        this.taskSchedule.finishItToday = false;
        this.taskSchedule.planType = PlanTypeEnum.ToDo;
        this.taskSchedule.scheduleOptionKey = ScheduleDayOptions.Nothing;
        this.scheduleTime = '';
    }

    changeAssignee(groupId, userId) {
        this.groupId = groupId;
        this.assigneeId = userId;
        this.isPrivate = groupId > 0 ? false : this.isPrivate;

        this.collaborators = this.collaborators?.filter(
            (user) => user?.memberUserId !== userId
        );
    }

    updateProperties(newData) {
        Object.assign(this, prepareInitData(newData));
    }

    setParentTask(parentTask) {
        this.isClone = false;
        this.parentId = parentTask?.id;
        this.parentCode = parentTask?.code;
        this.parentName = parentTask?.name;
    }
    removeAsSubTask(parentName) {
        console.log(parentName);
        this.parentId = undefined;
        this.parentCode = undefined;
        this.parentName = undefined;
    }

    async confirmBeforeChangePrivate(isPrivate) {
        const _localConfigsStore = localConfigsStore();

        const options = {
            title: '',
            content: '',
            taskDetailConfig: {},
        };

        switch (isPrivate) {
            case true:
                if (
                    _localConfigsStore.localConfigs['task']?.['taskDetail']
                        ?.disableConfirmBeforeSetPrivate
                )
                    return true;
                else {
                    options.title = 'TASK_DETAIL_LABEL_MAKE_PRIVATE';
                    options.content = 'TASK_LABEL_WARNING_WHEN_SET_PRIVATE';
                    options.taskDetailConfig = {
                        disableConfirmBeforeSetPrivate: true,
                    };
                }
                break;
            case false:
                if (
                    _localConfigsStore.localConfigs['task']?.['taskDetail']
                        ?.disableConfirmBeforeSetPublic
                )
                    return true;
                else {
                    options.title = 'TASK_DETAIL_LABEL_MAKE_PUBLIC';
                    options.content = 'TASK_LABEL_WARNING_WHEN_SET_PUBLIC';
                    options.taskDetailConfig = {
                        disableConfirmBeforeSetPublic: true,
                    };
                }
                break;

            default:
                return true;
        }

        settingGlobalModal({
            type: 'confirm',
            title: translate(options?.title),
            content: translate(options?.content),
            footerButtons: [
                {
                    type: 'TEXT',
                    labelCode: 'COMMON_LABEL_CANCEL',
                    color: 'gray',
                    onClick: () => {
                        tell(false);
                    },
                },
                {
                    type: 'FILL',
                    labelCode: 'COMMON_LABEL_GOT_IT',
                    color: 'current',
                    onClick: (options) => {
                        tell(true, options);
                    },
                },
            ],
            hasConfigs: true,
        });

        const result = await ask();
        if (
            typeof result === 'object' &&
            'confirm' in result &&
            !result?.confirm
        )
            return false;

        if (
            typeof result === 'object' &&
            'configs' in result &&
            result?.configs?.disableNextTime
        )
            _localConfigsStore.setLocalConfigs('task', {
                taskDetail: {
                    ..._localConfigsStore.localConfigs['task']?.['taskDetail'],
                    ...options?.taskDetailConfig,
                },
            });

        return true;
    }
}

const prepareInitData = (data) => {
    const scheduleTime = data?.scheduleTime
        ? dayjs.utc(data?.scheduleTime).format('YYYY-MM-DDTHH:mm:ssZ')
        : data?.scheduleTime;

    const taskSchedule = data?.taskSchedule
        ? {
              ...data?.taskSchedule,
              scheduleDateValue: scheduleTime,
          }
        : getDefaultTaskSchedule();
    return {
        ...data,
        id: data?.id,
        parentId: data?.parentId,
        parentCode: data?.parentCode,
        parentName: data?.parentName,
        groupId: data?.groupId || null,
        name: data?.name || '',
        description: data?.description || '',
        startTime: data?.startTime
            ? dayjs.utc(data?.startTime).format('YYYY-MM-DDTHH:mm:ssZ')
            : data?.startTime,
        scheduleByHour: data?.scheduleByHour || false,
        scheduleTime,

        scheduleTimezone: data?.scheduleTimezone || myProfileStore().timezone,
        assigneeId: data?.assigneeId || null,
        creatorId: data?.creatorId || null,
        lastModifierId: data?.lastModifierId || null,
        domainId: data?.domainId || null,
        projectId: data?.projectId || null,
        subprojectId: data?.subprojectId || null,
        estimationTime: data?.estimationTime || 0,
        finisherId: data?.finisherId || null,
        taskSchedule,
        taskLife: data?.taskLife
            ? data?.taskLife
            : taskSchedule.doItToday
            ? TaskLife.Todo
            : TaskLife.Created,
        planType: data?.planType || 0,
        priority:
            data?.urgency && data?.urgency > 0
                ? convertUrgencyToPriority(data?.urgency)
                : data?.priority,
        important:
            data?.urgency && data?.urgency > 0
                ? convertUrgencyToImportant(data?.urgency)
                : data?.important,
        taskType: data?.taskType || 1,
        isPrivate: data?.isPrivate || false,
        files:
            data?.files?.map((f) => {
                return {
                    ...f,
                    localId: f?.id,
                };
            }) || [],
        notes:
            data?.notes?.map((n) => {
                return {
                    ...n,
                    localId: n?.id,
                };
            }) || [],

        taggedMembers: data?.taggedMembers || [],
        taskRepeat: data?.taskRepeat,
        isRepeat: data?.isRepeat || false,
        taskSnapshotTotal: data?.taskSnapshotTotal || 0,
        collaborators:
            data?.collaborators?.map((c) => {
                return {
                    ...c,
                    id: c?.memberUserId,
                };
            }) || [],

        approvalEnabled: data?.approvalEnabled,
        approvalStatus: data?.approvalStatus,
        approverApprovalDate: data?.approverApprovalDate,
        approverApprovalStatus: data?.approverApprovalStatus,
        approverId: data?.approverId,
        approvers: data?.approvers,
        approverIds: data?.approverIds,
        scheduleTimeLocked: data?.scheduleTimeLocked,
    };
};
