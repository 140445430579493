<script setup lang="ts">
import dayjs from 'dayjs';
import {
  ITaskNotificationHistory,
  ENotificationTaskActionType,
} from '@/application/types/notification.types';
import {
  NOTIFICATION_TASK_UI,
  ENotificationViewType,
} from '@/application/constants/notification.const';
import NotificationTaskChangeDiff from '@/ui/modules/notification/NotificationTaskChangeDiff.vue';
import { currentTimeDevice } from '@/ui/hooks/calendarHook';
import NotificationTaskComment from '@/ui/modules/notification/task/NotificationTaskComment.vue';
import NotificationTaskTitleApproval from '@/ui/modules/notification/task/NotificationTaskTitleApproval.vue';

defineProps<{ history: ITaskNotificationHistory }>();

const handleCreateTimeNotification = (createdDate) => {
  if (!createdDate) {
    return;
  }
  let utcDate = currentTimeDevice().utcDate;

  const dayTimeZone = dayjs(createdDate).add(utcDate, 'hour');
  return dayjs(dayTimeZone).fromNow();
};
</script>
<template>
  <div
    class="
      flex-center
      w-full
      pt-1
      pb-2
      pl-1
      border-t border-gray-100
      bg-opacity-30
    "
    :class="[
      history?.status ? '' : 'bg-current-50 shadow-sm',

      history?.actionType == ENotificationTaskActionType.Comment
        ? 'hover:bg-current-50'
        : '',
    ]"
  >
    <!-- ICON -->
    <div
      class="rounded-full flex-center border border-white w-7 h-7 shrink-0"
      :style="{
        backgroundColor:
          NOTIFICATION_TASK_UI[history?.actionType]?.icon?.bgColor,
        fill: NOTIFICATION_TASK_UI[history?.actionType]?.icon?.fillColor,
        color: NOTIFICATION_TASK_UI[history?.actionType]?.icon?.fillColor,
      }"
    >
      <SynIcon
        class="fill-white text-white"
        custom-class="w-3.5 h-3.5"
        :name="NOTIFICATION_TASK_UI[history?.actionType]?.icon?.name"
        is-active
      />
    </div>
    <!-- ACTION BODY -->
    <div class="flex-1 min-w-0 flex-center flex-col relative">
      <div class="w-full flex-1 flex items-baseline px-2">
        <!--  ACTION      -->
        <div class="flex-1 flex space-x-1 items-baseline text-sm">
          <span class="shrink-0 truncate" :title="history?.executorFullName">
            <span class="text-gray-950 truncate text-sm font-semibold">
              {{ history?.executorFirstName || history?.executorFullName }}
            </span>
          </span>

          <div
            :title="history?.groupedContent || history?.title"
            class="flex-1 my-0.5 inline-flex lowercase"
          >
            <template
              v-if="
                history?.actionType ==
                ENotificationTaskActionType.ApprovalChange
              "
            >
              <NotificationTaskTitleApproval :history="history" />
            </template>
            <span
              v-else
              class="text-gray-950 text-overflow-hidden-line"
              v-html="
                $t(NOTIFICATION_TASK_UI[history?.actionType]?.actionLabelKey)
              "
            ></span>
            <template
              v-if="
                NOTIFICATION_TASK_UI[history?.actionType]?.viewType ===
                ENotificationViewType.COMMENT
              "
            >
              :
            </template>
          </div>
          <!-- ACTION TIME -->
          <div class="flex space-x-2 items-baseline justify-end shrink-0">
            <span class="text-gray-500 text-xs">
              {{
                handleCreateTimeNotification(
                  history?.eventActionDate
                    ? history?.eventActionDate
                    : history?.createdDate
                )
              }}
            </span>
            <span
              :class="
                history?.status
                  ? 'w-2 h-2 '
                  : 'rounded-full w-2 h-2 bg-blue-500 mt-0.5'
              "
            ></span>
          </div>
        </div>
      </div>
      <!-- SUB DESCRIPTION -->

      <div class="w-full px-2 text-current-800 text-overflow-hidden-line">
        <template
          v-if="
            NOTIFICATION_TASK_UI[history?.actionType]?.viewType ===
            ENotificationViewType.COMMENT
          "
        >
          <NotificationTaskComment :history="history" />
        </template>
        <template
          v-else-if="
            NOTIFICATION_TASK_UI[history?.actionType]?.viewType ===
            ENotificationViewType.DIFF
          "
        >
          <NotificationTaskChangeDiff
            :action-type="history?.actionType"
            :before-update="history?.beforeUpdate"
            :after-update="history?.afterUpdate"
          />
        </template>
      </div>
    </div>
  </div>
</template>
