<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useElementVisibility } from '@vueuse/core';
import TabTaskTag from '@/ui/modules/task/components/tabs/TabTaskTag.vue';
import ActionInBoard from '@/ui/modules/task/board/board-container/ActionInBoard.vue';
// import taskTabViewStore from "@/store/task/task-tab-view";
// import TaskBoardCardCompact from '@/ui/modules/task/board/task-card/TaskBoardCardCompact.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import TaskAttachmentDetail from '@/ui/modules/task/detail/TaskAttachmentDetail.vue';
import TaskBoardCardLoading from '@/ui/modules/task/board/task-card/TaskBoardCardLoading.vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import {
  EFilterTaskDefault,
  ETaskListMenuTab,
  TabTask,
} from '@/application/constants/task.const';
import localConfigsStore from '@/store/local-configs';
import domainStore from '@/store/scope';
import groupStore from '@/store/group';
import userStore from '@/store/user';
import useTaskBoardCard, {
  getFilterPayloadDefault,
} from '@/ui/modules/task/board/board-container/useTaskBoardCard';
import { ETaskListModule } from '@/application/types/task/task.types';
import taskListStore from '@/store/task/task-list-store';

const props = withDefaults(
  defineProps<{
    cardId: EFilterTaskDefault;
    tabId: string;
    cardInformation: TabTask;
    type: 'USER' | 'GROUP' | 'DOMAIN' | 'IA';
    sourceId: number;
  }>(),
  {
    type: 'USER',
  }
);

defineEmits(['onCollapseBoard', 'onOpenTaskDetail', 'onAddNewTask']);

// const _taskTabViewStore = taskTabViewStore();

const _taskTabViewStore = taskTabViewStore();
const _localConfigsStore = localConfigsStore();
const _taskListStore = taskListStore();

const sourceType = computed<ETaskListModule>(() => {
  if (props.type === 'GROUP') return ETaskListModule.GROUP;
  if (props.type === 'DOMAIN') return ETaskListModule.DOMAIN;
  if (props.type === 'IA') return ETaskListModule.I_ASSIGNED;
  return ETaskListModule.USER;
});
const listTaskOwner = computed<any>(() => {
  if (props.type == 'DOMAIN') {
    const domainByIds = domainStore().domainByIds;
    return domainByIds[props.sourceId];
  }
  if (props.type == 'GROUP') {
    const allGroupByIds = {
      ...groupStore().allGroupByIds,
      ...groupStore().myGroupByIds,
    };
    return allGroupByIds[props.sourceId];
  }
  return userStore().allUserByIds[props.sourceId];
});

const boardShowFinishedTask = computed(
  () => _taskTabViewStore.boardShowFinishedTask
);
const boardShowFinishedRelatedTask = computed(
  () => _taskTabViewStore.boardShowFinishedRelatedTask
);
const boardShowFinishedPinnedTask = computed(
  () => _taskTabViewStore.boardShowFinishedPinnedTask
);
const cardIdInCache = computed<any>(() => `BOARD_BY_DEFAULT_${props.cardId}`);
const localConfigs = computed<any>(() => _localConfigsStore.localConfigs);

const isCollapse = computed(() => {
  return localConfigs.value['task']?.[cardIdInCache.value]?.isCollapse;
});

const taskBoardCardRef = ref<any>(null);
const targetIsVisible = useElementVisibility(taskBoardCardRef);
const filterPayload = computed<any>(() => {
  const _filter = getFilterPayloadDefault(props.sourceId, sourceType.value);
  _filter.menuTab = props.cardInformation?.menuTab;
  _filter.status =
    boardShowFinishedTask.value ||
    (_filter.menuTab !== ETaskListMenuTab.MY_RELATED &&
      _filter.menuTab !== ETaskListMenuTab.PINNED)
      ? undefined
      : `0,7,1,2,8,6,4,5`;

  const customFilter = _taskListStore.filterPayload;

  return {
    ...(customFilter?.isDefault ? _filter : customFilter),
    menuTab: props.cardInformation?.menuTab,
  };
});

const {
  isReady,
  taskAllList,
  allTaskByIds,
  currentSortData,
  listTaskIdShowing,
  isLoading,
  initData,
  onLoadMore,
  onChangeIterate,
  onChangeOrder,
  onSearch,
} = useTaskBoardCard();

const initComponent = () => {
  if (targetIsVisible.value) initData(filterPayload.value);
};

watch(
  () => targetIsVisible.value,
  (value) => {
    if (value && !isReady.value && !isCollapse.value)
      initData(filterPayload.value);
  }
);

const onCollapseBoard = () => {
  if (isCollapse.value && !isReady.value) initData(filterPayload.value);

  _localConfigsStore.setLocalConfigs('task', {
    [cardIdInCache.value]: {
      isCollapse: !isCollapse.value,
    },
  });

  // _taskTabViewStore.setCollapseBoard(tabId);
};

const isHasOpenAttachment = ref(null as any);
const currentTaskId = ref(0 as number);
const isOpenAttachmentDetail = ref(false);
const onOpenAttachmentDetail = (taskId) => {
  if (isHasOpenAttachment.value) isHasOpenAttachment.value = null;
  currentTaskId.value = taskId;
  isHasOpenAttachment.value = taskId;
  isOpenAttachmentDetail.value = true;
};

const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;
};

const isHovered = ref<boolean>(false);
const onHoverCard = () => {
  if (isHovered.value) return;
  onLoadMore();

  isHovered.value = true;
};
const onChangeBoardShowFinishedRelatedTask = async (value) => {
  _taskTabViewStore.onChangeBoardShowFinishedRelatedTask(value);

  initData(filterPayload.value);
};
const onChangeBoardShowFinishedPinnedTask = (value) => {
  _taskTabViewStore.onChangeBoardShowFinishedPinnedTask(value);

  initData(filterPayload.value);
};

initComponent();
</script>

<template>
  <div
    v-bind="$attrs"
    class="flex flex-col h-full hover-to-show__parent task-board-card-default"
    :style="isCollapse ? `width: 3rem` : `width: 25rem`"
    @mouseover="onHoverCard"
  >
    <div
      class="
        p-2
        rounded
        flex
        items-center
        justify-between
        hover:bg-current-50
        drag-to-scroll-target
      "
      :class="isCollapse ? 'flex-col-reverse ' : 'pl-2'"
    >
      <TabTaskTag
        v-if="!isCollapse"
        is-active
        :color="cardInformation?.color"
        :name="
          $t(cardInformation?.labelKey, {
            username: listTaskOwner?.isMe
              ? $t('COMMON_LABEL_ME')
              : listTaskOwner?.firstName,
          })
        "
        :total="taskAllList?.length"
        readonly
      />
      <ActionInBoard
        :tab="cardInformation"
        :is-collapse="isCollapse"
        :current-sort-data="currentSortData"
        hidden-class="task-board-card-default__hover-to-show"
        readonly
        @on-collapse-board="onCollapseBoard"
        @on-change-iterate="onChangeIterate"
        @on-change-order="onChangeOrder"
        @on-search="onSearch"
      />
    </div>

    <div
      ref="taskBoardCardRef"
      v-scroll-infinite="onLoadMore"
      class="bg-gray-50 rounded flex-1 overflow-y-auto small-scrollbar relative"
    >
      <div
        v-if="isCollapse"
        class="absolute w-full"
        style="
          transform-origin: left;
          transform: rotate(90deg) translateY(-50%);
          left: 25%;
        "
      >
        <TabTaskTag
          class="w-max"
          readonly
          is-active
          :color="cardInformation?.color"
          :name="
            $t(cardInformation?.labelKey, {
              username: listTaskOwner?.isMe
                ? $t('COMMON_LABEL_ME')
                : listTaskOwner?.firstName,
            })
          "
          :total="taskAllList?.length"
        />
      </div>
      <template v-else>
        <div v-if="cardId === EFilterTaskDefault.MY_RELATED" class="pt-4 pb-2">
          <AtomSwitch
            :model-value="boardShowFinishedRelatedTask"
            :label="$t('TASK_LIST_SHOW_FINISHED_TASKS')"
            @update:model-value="onChangeBoardShowFinishedRelatedTask"
          />
        </div>
        <div v-if="cardId === EFilterTaskDefault.PINNED" class="pt-4 pb-2">
          <AtomSwitch
            :model-value="boardShowFinishedPinnedTask"
            :label="$t('TASK_LIST_SHOW_FINISHED_TASKS')"
            @update:model-value="onChangeBoardShowFinishedPinnedTask"
          />
        </div>
        <TaskBoardCardLoading v-if="isLoading" class="m-2" />

        <template v-else>
          <TaskCardItem
            v-for="taskId in listTaskIdShowing"
            :key="taskId"
            class="m-2"
            :task-detail="allTaskByIds[taskId]"
            @on-show-files="onOpenAttachmentDetail(taskId)"
          />
        </template>
      </template>
    </div>
  </div>

  <TaskAttachmentDetail
    v-if="isOpenAttachmentDetail"
    :task-id="currentTaskId"
    @on-close="onCloseAttachment"
  />
</template>
<style>
.task-board-card-default__hover-to-show {
  display: none;
}
.task-board-card-default:hover .task-board-card-default__hover-to-show {
  display: flex;
}
</style>
