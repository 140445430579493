<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="m410.113 114.753h-52.782a20 20 0 0 0 0 40h52.782a61.957 61.957 0 0 1 61.887 61.886v141.824a61.958 61.958 0 0 1 -61.887 61.887h-308.226a61.958 61.958 0 0 1 -61.887-61.887v-141.824a61.957 61.957 0 0 1 61.887-61.886h114.667l-30.833 30.832a20 20 0 0 0 28.285 28.285l64.974-64.97a20 20 0 0 0 0-28.284l-63.1-63.1a20 20 0 0 0 -28.285 28.284l28.961 28.961h-114.669a102 102 0 0 0 -101.887 101.878v141.824a102 102 0 0 0 101.887 101.887h308.226a102 102 0 0 0 101.887-101.887v-141.824a102 102 0 0 0 -101.887-101.886z"
        class=""
      ></path>
    </g>
  </svg>
</template>
