<script setup lang="ts">
import { computed, ref } from 'vue';
import overviewDomainsStore from '@/store/dashboard/overview-domains-store';
import router from '@/ui/router';
import SynSearch from '@/ui/common/atoms/SynInput/SynSearch.vue';
import myProfile from '@/store/auth/my-profile';

const _overviewDomainStore = overviewDomainsStore();

const isOwnerAdmin = computed<boolean>(() => {
  return myProfile().isAdmin || myProfile().isOwner;
});

const searchText = ref(null);
searchText.value = _overviewDomainStore.keySearchDomain;

const resetKeyword = () => {
  searchText.value = null;
  _overviewDomainStore.setKeySearchGroup(null);
};

const searchInputDomain = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath === '/dashboard/domains'
  ) {
    searchInputDomain.value?.showSearch();
    e.preventDefault();
  }
});

const onChangeText = (value) => {
  _overviewDomainStore.setKeySearchGroup(value);
};

const onCreateDomain = () => {
  _overviewDomainStore.setValueCreateDomain(true);
};

const viewMode = computed<any>(() => {
  return _overviewDomainStore.viewMode;
});
const onViewModeToggle = () => {
  _overviewDomainStore.setOverviewDomainMode(
    viewMode.value === 'BOARD' ? 'LIST' : 'BOARD'
  );
};
</script>

<template>
  <div class="flex items-center space-x-2 px-4">
    <!-- SEARCH   -->
    <SynSearch
      ref="searchInputDomain"
      v-model="searchText"
      icon-prefix="search"
      is-show-short-cut-key
      :placeholder="$t('GROUP_SEARCH')"
      :autofocus="true"
      width-max="23rem"
      @update:model-value="onChangeText"
    >
      <template v-if="searchText?.trim()?.length > 0" #suffix>
        <SynIcon
          has-action
          name="close2"
          custom-class="rounded-full w-3 h-3"
          @click="resetKeyword()"
        />
      </template>
    </SynSearch>
    <VigButton
      v-if="isOwnerAdmin"
      v-vig-tooltip="
        $t(
          viewMode === 'BOARD'
            ? 'TASK_LIST_VIEW_LIST_LABEL'
            : 'TASK_LIST_VIEW_BOARD_LABEL'
        )
      "
      ghost
      rounded="rounded-full"
      color="gray"
      :icon="viewMode === 'BOARD' ? 'List' : 'ViewBoard'"
      @click="onViewModeToggle"
    />

    <!-- ADD DOMAIN -->
    <div
      class="
        flex-center
        p-2
        bg-current-400
        rounded
        cursor-pointer
        hover:bg-current-500 hover:ring hover:ring-current-100
      "
      @click.stop="onCreateDomain"
    >
      <SynIcon custom-class="fill-white w-3.5 h-3.5" name="plus" />
    </div>
  </div>
</template>
