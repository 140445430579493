import {
    ScheduleDayOptions,
    ScheduleOtherTimeOptions,
} from '@/domain/enums/taskEnum';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
    TaskUrgency,
    getUrgencyValue,
} from '@/ui/hooks/taskHook';
import TaskOriginalClass, { ITaskOriginalClass } from './TaskOriginalClass';
import { NotificationTypeEnum, openNotification } from '@/ui/hooks/commonHook';
import TaskService from '@/application/services/task/TaskService';
import { IUpdateDeadlineForm } from '../TaskType';
import remoteConfigStore from '@/store/remoteConfig';
import { verifyFiles } from '@/ui/hooks/fileHook';
import AttachmentClass from '@/application/services/attachment/AttachmentClass';
import NoteClass from '@/application/services/attachment/NoteClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import { translate } from '@/ui/plugins/i18n/myi18n';
import groupStore from '@/store/group';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import myProfileStore from '@/store/auth/my-profile';
import { ITaskTodo } from '@/application/types/task/task.types';
import { TaskFromChat } from '@/domain/entities/task/TaskFromChat';
import ChatService from '@/application/services/ChatService';
import dayjs from 'dayjs';
import { ITaskWorkflowEntity } from './TaskWorkflowEntity';
// import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';
import TaskWorkflowStepEntity from './TaskWorkflowStepEntity';

export interface ITaskCreatePayload extends ITaskOriginalClass {
    localId?: number;
    urgency: number; // 1 | 2 | 3 | 4
    domainName: string;
    projectName: string;
    subprojectName: string;
    attachments: any[];
    files: AttachmentClass[];
    notes: NoteClass[];
    filesPayload: AttachmentClass[];
    notesPayload: NoteClass[];
    isPrivate: boolean;
    taskTodos: ITaskTodo[];
    taskFromChat: TaskFromChat | null;

    // workflow
    taskWorkflow?: ITaskWorkflowEntity;
    workflowId?: string;
}

export default class TaskCreateUpdatePayload
    extends TaskOriginalClass
    implements ITaskCreatePayload
{
    localId?: number;
    urgency: number; // 1 | 2 | 3 | 4

    domainName: string;
    projectName: string;
    subprojectName: string;
    attachments: any[];

    files: AttachmentClass[];
    notes: NoteClass[];
    filesPayload: AttachmentClass[];
    notesPayload: NoteClass[];
    isPrivate: boolean;

    taskTodos: ITaskTodo[];
    taskWorkflow?: ITaskWorkflowEntity;
    workflowId?: string;
    subTasks?: any;
    subTaskIds?: any;
    isClone?: boolean;

    taskFromChat: TaskFromChat | null;
    multiAssignees?: {
        assigneeId: number;
        domainId: number;
        projectId?: number;
        subprojectId?: number;
    }[];
    static userDayInfo: any;
    static userOtherDay: any;
    _taskService: TaskService;

    constructor(data) {
        super(data);
        this.name = data?.name || '';
        this.description = data?.description || '';
        this.urgency = data?.urgency || 1;
        this.attachments = data?.attachments || [];
        this.domainName = data?.domainName;
        this.projectName = data?.projectName;
        this.subprojectName = data?.subprojectName;
        this.isPrivate = data?.isPrivate;
        this._taskService = TaskService.getInstance();

        this.files = data?.files || [];
        this.notes = data?.notes || [];
        this.filesPayload = [];
        this.notesPayload = [];
        this.taskTodos = data?.taskTodos || [];
        this.taskWorkflow = data?.taskWorkflow;
        this.workflowId = data?.workFlowTaskId || '';
        this.taskFromChat = data?.taskFromChat;
        this.subTasks = data?.subTasks;
        this.subTaskIds = data?.subTaskIds;
        this.multiAssignees = data?.multiAssignees;
        this.isClone = data?.isClone;
    }

    async create(isShowNotification = true) {
        try {
            const filesPayload = await this.uploadAttachments();

            if (
                this.files?.length > 0 &&
                (!filesPayload ||
                    filesPayload?.filter((file) => file)?.length == 0)
            ) {
                throw 'UPLOAD_FILE_FAILED';
            }

            await this.uploadNotes();
            // 1. Add files which has not ID

            if (this.taskWorkflow?.id && this.taskWorkflow?.steps?.length > 0) {
                let index = 0;
                for await (const step of this.taskWorkflow.steps) {
                    const _step = new TaskWorkflowStepEntity(step);

                    await _step.saveAttachment(true);

                    if (
                        !_step.updateFields ||
                        !_step.updateFields?.includes('files')
                    )
                        _step.updateFields = [
                            ...(_step.updateFields || []),
                            'files',
                        ];
                    if (
                        !_step.updateFields ||
                        !_step.updateFields?.includes('notes')
                    )
                        _step.updateFields = [
                            ...(_step.updateFields || []),
                            'notes',
                        ];
                    if (
                        !_step.updateFields ||
                        !_step.updateFields?.includes('todoLists')
                    )
                        _step.updateFields = [
                            ...(_step.updateFields || []),
                            'todoLists',
                        ];

                    this.taskWorkflow.steps[index] = _step;
                    index++;
                }
            }

            const res = await this._taskService.create(
                new TaskCreateUpdatePayload({
                    ...this,
                    files: filesPayload,
                    notes: this.notesPayload,
                    taskWorkflow: this.taskWorkflow
                        ? {
                              workflowId: this.taskWorkflow?.id,
                              steps: this.taskWorkflow?.steps,
                          }
                        : undefined,
                })
            );

            const newTask = {
                ...res?.result,
                // creationTime: new Date()
            };

            const myProfile = myProfileStore().myProfile;

            if (newTask?.id) {
                const taskCode = newTask.code;
                let groupName = '';
                if (this.groupId) {
                    const groupDetail: any = groupStore().getDetailGroupById(
                        this.groupId
                    );
                    groupName = groupDetail?.name;
                }

                if (isShowNotification) {
                    openNotification({
                        metaData: {
                            hasAction: true,
                            isOpenTaskDetail: true,
                            taskCode,
                        },
                        notificationValue: {
                            ...newTask,
                            actionType: 'AddNew',
                            type: 'task',
                            task_name: this.name,
                            task_code: newTask?.code,
                            isPlannedToTreat: this.taskSchedule.doItToday,
                            isPlannedToFinish: this.taskSchedule.finishItToday,
                            group_name: groupName,
                            executor_name: myProfile?.firstName,
                            icon: myProfile?.avatarUrl,
                        },
                        notificationType: NotificationTypeEnum.task,
                    });
                }
            }

            if (newTask?.id && this.taskFromChat?.messageId) {
                try {
                    ChatService.addTaskCreatedFromMessage(
                        this.taskFromChat?.orgId,
                        this.taskFromChat?.conversationId,
                        this.taskFromChat?.messageId,
                        newTask,
                        myProfile?.id
                    );
                } catch (e) {
                    console.log('addTaskCreatedFromMessage FAIL', e);
                }
            }

            return Promise.resolve(newTask);
        } catch (error) {
            settingGlobalModal({
                type: 'notification',
                title: '',
                content:
                    error == 'UPLOAD_FILE_FAILED'
                        ? translate('TASK_CREATE_UPLOAD_FILE_FAILED')
                        : translate('COMMON_ERROR_MESSAGE_CREATE_TASK') ||
                          'Đã xảy ra lỗi khi tạo task, vui lòng thử lại.',
                confirmable: true,
            });
            await ask();

            await Promise.all([
                ...this.filesPayload
                    .filter((file) => file.path)
                    .map((file) =>
                        new AttachmentClass(
                            ModuleSourceFileEnum.task,
                            file
                        ).remove()
                    ),
                ...this.notesPayload
                    .filter((file) => file.path)
                    .map((file) =>
                        new NoteClass(ModuleSourceFileEnum.task, file).remove()
                    ),
            ]);
            this.filesPayload = [];
            this.notesPayload = [];
            // this.files = this.filesPayload;
            // this.notes = this.notesPayload;

            // remove file which uploaded to aws

            return Promise.reject(false);
        }
    }

    changePriority(isHigh: boolean) {
        this.changeUrgency(
            isHigh ? TaskUrgency.UrgentImportant.key : TaskUrgency.Cool.key
        );
    }

    changeUrgency(urgency: number) {
        // 1 | 2 | 3 | 4
        this.priority = convertUrgencyToPriority(urgency);
        this.important = convertUrgencyToImportant(urgency);
        this.urgency = urgency;
    }

    changeDeadline(deadlineDate: IUpdateDeadlineForm) {
        if (!deadlineDate) return;
        if (
            this.taskSchedule.finishItToday ||
            deadlineDate.scheduleOptionKey == ScheduleDayOptions.OnDay
        ) {
            this.finishItToday(
                deadlineDate.scheduleOptionKey == ScheduleDayOptions.OnDay
            );
        }

        this.taskSchedule.scheduleOptionKey = deadlineDate.scheduleOptionKey;
        this.taskSchedule.otherTime = deadlineDate.otherTimeKey;
        this.scheduleTime = deadlineDate.scheduleTime;
    }

    async changeStartEndTime(data: {
        startTime: string;
        scheduleTime?: string;
        scheduleByHour?: boolean;
        scheduleTimezone?: string;
        isFinishToday?: boolean;
        changeStartTimeOnly?: boolean;
    }): Promise<boolean> {
        try {
            this.startTime = data.startTime;
            this.scheduleTime = data.scheduleTime || '';
            this.scheduleByHour = data.scheduleByHour;
            this.scheduleTimezone = data.scheduleTimezone;

            const res: any = await TaskService.getInstance().changeStartEndTime(
                {
                    taskId: this.id,
                    ...data,
                    changeStartTimeOnly: !!data?.changeStartTimeOnly,
                }
            );

            if (!res?.success) return Promise.resolve(false);

            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(false);
        }
    }

    async addFiles(files: any[]) {
        const _remoteConfigStore = remoteConfigStore();

        verifyFiles(
            files,
            _remoteConfigStore.webTaskUploadContentTypes,
            _remoteConfigStore.webTaskUploadMaxFilesize,
            (file) => {
                const newFile = new AttachmentClass(
                    ModuleSourceFileEnum.task,
                    file
                );

                this.files.push(newFile);
                this.attachments.push(newFile);
            }
        );
    }

    addNote(noteData: any) {
        if (noteData?.name) {
            const noteIndex = this.notes.findIndex(
                (o) => o.name == noteData.name
            );
            console.log(
                '🚀 Hyrin ~ TaskCreateUpdatePayload ~ addNote ~ noteIndex:',
                noteIndex
            );
            if (noteIndex > -1) {
                this.notes[noteIndex].fileUrl = noteData?.base64;
                this.notes[noteIndex].base64 = noteData?.base64;
                this.notes[noteIndex].fileBlob = Buffer.from(
                    noteData?.base64.replace(/^data:image\/\w+;base64,/, ''),
                    'base64'
                );
                this.notes[noteIndex].drawData = noteData?.drawData;
            }

            const attachmentIndex = this.attachments.findIndex(
                (o) => o?.name == noteData?.name
            );
            if (attachmentIndex > -1) {
                this.attachments[attachmentIndex].fileUrl = noteData?.base64;
                this.attachments[attachmentIndex].base64 = noteData?.base64;
                this.attachments[noteIndex].fileBlob = Buffer.from(
                    noteData?.base64.replace(/^data:image\/\w+;base64,/, ''),
                    'base64'
                );
                this.attachments[attachmentIndex].drawData = noteData?.drawData;
            }
        } else {
            const newNote = new NoteClass(ModuleSourceFileEnum.task, {
                base64: noteData?.base64,
                type: 'image/jpeg',
                size: noteData?.base64?.length * (3 / 4) - 2,
                drawData: noteData?.drawData,
            });
            console.log(
                '🚀 Hyrin ~ TaskCreateUpdatePayload ~ addNote ~ newNote',
                {
                    input: {
                        base64: noteData?.base64,
                        type: 'image/jpeg',
                        size: noteData?.base64?.length * (3 / 4) - 2,
                        drawData: noteData?.drawData,
                    },
                    output: newNote,
                }
            );
            this.notes.push(newNote);

            this.attachments.push({
                ...newNote,
                isNote: true,
            });
        }
    }

    removeFile(file: any) {
        if (!file) return;
        this.files = this.files.filter((o) => o.localId !== file.localId);
        this.attachments = this.attachments.filter(
            (o) => o.localId !== file.localId
        );
    }

    removeNote(note: any) {
        if (!note) return;
        this.notes = this.notes.filter((o) => o.name !== note?.name);
        this.attachments = this.attachments.filter(
            (o) => o.name !== note?.name
        );
    }

    async getUserDayInfo() {
        const userId = this.assigneeId;
        if (userId) {
            const res: any =
                await TaskService.getInstance().getUserTaskSchedule(userId);

            TaskCreateUpdatePayload.userDayInfo = res.result;

            if (TaskCreateUpdatePayload.userDayInfo?.length > 0) {
                const index = TaskCreateUpdatePayload.userDayInfo.findIndex(
                    (o) => o.scheduleOptionKey == ScheduleDayOptions.OtherTime
                );

                if (index > -1) {
                    TaskCreateUpdatePayload.userOtherDay =
                        TaskCreateUpdatePayload.userDayInfo[index].otherTimes;
                }
            }
        }
    }

    addCollaboratorsInTask(payload) {
        this.collaborators = [...this.collaborators, ...payload];
    }

    deleteCollaboratorsInTask(userId) {
        this.collaborators = this.collaborators?.filter(
            (user) => user?.memberUserId !== userId
        );
    }

    private uploadAttachments() {
        if (!this.files || this.files.length == 0) return;
        return Promise.all(
            this.files.map((file) =>
                new AttachmentClass(ModuleSourceFileEnum.task, file).upload()
            )
        );
    }

    private async uploadNotes() {
        if (!this.notes || this.notes.length == 0) return;
        this.notesPayload = await Promise.all(
            this.notes.map((file) => {
                console.log(
                    '🚀 Hyrin ~ TaskCreateUpdatePayload ~ uploadNotes ~ file',
                    file
                );
                return new NoteClass(ModuleSourceFileEnum.task, file).upload();
            })
        );
    }
    getDateByOptions(scheduleOptionKey) {
        let result = '';
        switch (scheduleOptionKey) {
            case ScheduleDayOptions.OnDay:
                result = dayjs().format('YYYY/MM/DD');
                break;
            case ScheduleDayOptions.D1:
                result = dayjs().add(1, 'day').format('YYYY/MM/DD');
                break;
            case ScheduleDayOptions.D2:
                result = dayjs().add(2, 'day').format('YYYY/MM/DD');
                break;
            case ScheduleDayOptions.D3:
                result = dayjs().add(3, 'day').format('YYYY/MM/DD');
                break;
            case ScheduleDayOptions.Nothing:
                result = '';
                break;
            // case ScheduleDayOptions.OtherTime:
            // result = ''
            //   break;

            default:
                break;
        }

        return result;
    }
    onAttachWorkflow(workflow: ITaskWorkflowEntity) {
        this.workflowId = workflow?.id;
        this.taskWorkflow = workflow;

        if (!this.taskWorkflow?.steps || this.taskWorkflow?.steps?.length == 0)
            return {
                needToUpdateDomain: false,
            };

        // this.taskWorkflow.steps[0].status = EWorkflowTaskStepStatus.INPROGRESS;

        const currentStep = this.taskWorkflow?.steps[0];

        if (!currentStep?.groupId && !currentStep?.assigneeId)
            return {
                needToUpdateDomain: false,
            };

        if (currentStep.domainId) {
            this.domainId = currentStep.domainId;
            this.projectId = undefined;
            this.subprojectId = undefined;
        }

        this.groupId = currentStep.groupId;
        this.assigneeId = currentStep.assigneeId;
        this.important = !!currentStep?.important;
        this.priority = currentStep?.priority || 0;
        this.urgency = getUrgencyValue(this.priority, this.important);
        this.description = currentStep.taskDescription || '';

        const _scheduleTime = currentStep?.scheduleTime
            ? dayjs(currentStep?.scheduleTime).format('YYYY-MM-DD')
            : '';
        this.scheduleTime = _scheduleTime;

        this.changeDeadline({
            scheduleOptionKey:
                currentStep?.scheduleDayOption || ScheduleDayOptions.Nothing,
            otherTimeKey: _scheduleTime
                ? ScheduleOtherTimeOptions.SpecificDate
                : null,
            scheduleTime:
                _scheduleTime ||
                this.getDateByOptions(currentStep?.scheduleDayOption || 0),
        });

        this.files = currentStep?.files;
        this.notes = currentStep?.notes;
        this.attachments = [...this.files, ...this.notes];
        this.taskTodos = currentStep?.todoLists || [];

        return { needToUpdateDomain: !!currentStep.domainId };
    }
    onDetachWorkflow() {
        this.workflowId = undefined;
        this.taskWorkflow = undefined;
    }

    synchronizeTaskToWorkflowCurrentStep() {
        if (!this.taskWorkflow) return;

        if (this.taskWorkflow.steps && this.taskWorkflow.steps?.length > 0)
            this.taskWorkflow.steps[0] = {
                ...this.taskWorkflow.steps[0],
                groupId: this.groupId,
                assigneeId: this.assigneeId,
                domainId: this.domainId,
                priority:
                    this.urgency && this.urgency > 0
                        ? convertUrgencyToPriority(this.urgency)
                        : this.priority || 1,
                important:
                    this.urgency && this.urgency > 0
                        ? convertUrgencyToImportant(this.urgency)
                        : !!this.important,
                scheduleDayOption: this.taskSchedule?.scheduleOptionKey,
                scheduleTime: this.scheduleTime || undefined,
                taskDescription: this.description,
            };
    }
}

export interface IAttachmentItem {
    objectUrl: string;
    fileBlob: File | Blob | null;
    base64: string;
    name: string;
    size: number;
    type: string;
    creationTime: Date | number | string;
    localId: string;
}
