<script setup lang="ts">
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import appStore from '@/store/app';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';

const props = defineProps<{
  organization: any;
}>();

const onSwitchOrgClick = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: `<span>
    <span class="">${translate(
      'COMMON_LABEL_SWITCH_ORGANIZATION_CONFIRM'
    )}</span>
    <br />
    <span class="text-current font-semibold">${props.organization?.name}</span>
    </span>`,
    confirmable: true,
    closeable: true,
    confirmLabel: translate('COMMON_LABEL_SWITCH_ORGANIZATION'),
  });
  const confirmed = await ask();
  if (confirmed) {
    // switch org
    appStore().setIsLoading(true);

    const authenSelectOrganization = new AuthenticateSelectOrganization(
      props.organization?.id
    );
    const res = await authenSelectOrganization.login(true);

    _processLoginSuccess(res);
  }
};

const _processLoginSuccess = async (result) => {
  if (!result) return;

  getLocalStorage(StorageConstant.HAVE_AVATAR) &&
    removeLocalStorage(StorageConstant.HAVE_AVATAR);

  setLocalStorage(StorageConstant.HAS_LOGIN, 'true');
};
</script>

<template>
  <!--  <div class="px-3 py-2 flex items-center gap-3 bg-orange-50">-->
  <!--    <SynIcon name="Switch" />-->
  <!--    <div class="flex-1 text-sm text-gray-700">-->
  <!--      Switch to organization-->
  <!--      <span class="text-current-500 font-semibold">{{-->
  <!--        organization?.name-->
  <!--      }}</span>-->
  <!--      to view all message-->
  <!--    </div>-->
  <!--    <VigButton padding="px-3 py-1.5" class="text-sm" @click="onSwitchOrgClick"-->
  <!--      >Switch</VigButton-->
  <!--    >-->
  <!--  </div>-->

  <button
    type="button"
    class="
      px-1.5
      py-0.5
      text-xs text-current-700
      font-semibold
      whitespace-nowrap
      bg-current-100
      hover:bg-current-200
      rounded-lg
    "
    @click="onSwitchOrgClick"
  >
    <span class="mr-1">{{ $t('COMMON_LABEL_SWITCH') }}</span>
    <SynIcon name="Switch" custom-class="w-3 h-3 fill-current-700" />
  </button>
</template>
