<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { fullRouteByPermission } from '@/ui/router';
import { ROUTER_GROUP } from '@/application/constants/system/router.const';
import { isOpenCreateTaskDrawer } from '@/ui/modules/task/task-global-state';
import CreateGroupTask from '@/ui/modules/group/create/CreateGroupTask.vue';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import CreateNewCallModal from '@/ui/modules/video-call/components/CreateNewCallModal.vue';
import DayoffCreateNewModal from '@/ui/components/dayoff/DayoffCreateNewModal.vue';
import EventCreateModal from '@/ui/modules/calendar/event/EventCreateModal.vue';
import ReminderCreateModal from '@/ui/modules/calendar/reminder/ReminderCreateModal.vue';
import FavoriteButton from '@/ui/components/user/FavoriteButton.vue';
import favoriteStore from '@/store/favorite/favoriteStore';
import orderBy from 'lodash/orderBy';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import {
  getUserPathFirebase,
  updateDataFirebase,
} from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import myProfileStore from '@/store/auth/my-profile';

const router = useRouter();
const checkSystemConfig: Function | undefined = inject('checkSystemConfig');
const currentTab = ref('histories');
const isOpenDropdown = ref(false);
const dropdownRef = ref(null as any);

const favoriteMenus = computed<any[]>(() => {
  return favoriteStore().favoriteMenus;
});

const onDropdownOpen = async () => {
  isOpenDropdown.value = true;
  currentTab.value = 'histories';
};
const onCloseButtonCall = () => {
  currentTab.value = '';
  isOpenDropdown.value = false;
};

const allRouteByPermission = computed(() => {
  const allChildrenRoute = fullRouteByPermission
    .filter((route) => route.children?.length > 0)
    .reduce((ollRoute, currentRoute) => {
      return [...ollRoute, ...currentRoute.children];
    }, []);

  return [...fullRouteByPermission, ...allChildrenRoute]
    .filter((route) => route?.meta?.isShowInQuickMenu)
    .filter((route) => {
      if (
        !route?.meta?.permissionBySettings ||
        route?.meta?.permissionBySettings?.length == 0 ||
        !checkSystemConfig
      )
        return true;

      return checkSystemConfig(route?.meta?.permissionBySettings);
    })
    .reduce((currentResult, currentItem) => {
      const groupId = currentItem?.meta?.group?.id
        ? currentItem?.meta?.group?.id
        : 'OTHERS';

      let key = groupId ? groupId : 'OTHERS';

      let favoriteKey = '';

      const favoriteItem = favoriteMenus.value?.find(
        (menu) => menu?.id == currentItem?.name
      );
      if (favoriteItem) favoriteKey = 'FAVORITES';

      const isExisted = currentResult[key]?.routes?.some(
        (o) => o?.name == currentItem?.name
      );
      const isFavoriteExisted = favoriteKey
        ? currentResult[favoriteKey]?.routes?.some(
            (o) => o?.name == currentItem?.name
          )
        : false;

      return {
        ...currentResult,
        [key]: {
          ...currentItem?.meta?.group,
          routes: orderBy(
            [
              ...(currentResult[key]?.routes || []),
              ...(isExisted ? [] : [currentItem]),
            ],
            [(data) => data?.meta?.group?.index],
            ['asc']
          ),
        },
        ...(favoriteKey
          ? {
              [favoriteKey]: {
                ...currentItem?.meta?.group,
                routes: orderBy(
                  [
                    ...(currentResult[favoriteKey]?.routes || []),
                    ...(isFavoriteExisted
                      ? []
                      : [{ ...currentItem, index: favoriteItem?.index }]),
                  ],
                  ['index'],
                  ['asc']
                ),
              },
            }
          : {}),
      };
    }, {});
});

const allGroupKeys = computed<any>(() => {
  return orderBy(
    Object.keys(allRouteByPermission.value)?.map((key) => {
      return {
        ...allRouteByPermission.value[key],
        ...ROUTER_GROUP[key],
      };
    }),
    ['index'],
    ['asc']
  );
});

const isCreateGroup = ref<boolean>(false);
const isNewChat = ref<boolean>(false);
const isOpenMakeCallModal = ref<boolean>(false);
const allCreateList = computed(() => {
  return [
    {
      id: 'TASK',
      titleKey: 'COMMON_MODULE_TASK',
      icon: 'note',
      onClick: () => {
        isOpenCreateTaskDrawer.value = true;
        dropdownRef.value?.onForceClose();
      },
    },
    {
      id: 'GROUP',
      titleKey: 'COMMON_LABEL_GROUP',
      icon: 'group',
      onClick: () => {
        isCreateGroup.value = true;
        dropdownRef.value?.onForceClose();
      },
    },
    {
      id: 'MESSAGE',
      titleKey: 'CHAT_MESSAGE',
      icon: 'message',
      onClick: () => {
        isNewChat.value = true;
        dropdownRef.value?.onForceClose();
      },
    },
    {
      id: 'CALL',
      titleKey: 'CHAT_VIDEO_CALL',
      icon: 'video-audio',
      onClick: () => {
        isOpenMakeCallModal.value = true;
        dropdownRef.value?.onForceClose();
      },
    },
  ];
});

const openModalStatus = reactive<{
  dayOff: boolean;
  event: boolean;
  reminder: boolean;
}>({
  dayOff: false,
  event: false,
  reminder: false,
});
const allPersonalCreateList = computed(() => {
  return [
    {
      id: 'DAY_OFF',
      titleKey: 'COMMON_MODULE_DAYOFF',
      icon: 'Dayoff',
      onClick: () => {
        openModalStatus.dayOff = true;
        dropdownRef.value?.onForceClose();
      },
    },
    {
      id: 'EVENT',
      titleKey: 'CALENDAR_FILTER_TITLE_TYPE_EVENT',
      icon: 'EventIcon',
      onClick: () => {
        openModalStatus.event = true;
        dropdownRef.value?.onForceClose();
      },
    },
    {
      id: 'REMINDER',
      titleKey: 'CALENDAR_FILTER_TITLE_TYPE_REMINDER',
      icon: 'Reminder',
      onClick: () => {
        openModalStatus.reminder = true;
        dropdownRef.value?.onForceClose();
      },
    },
  ];
});

const onCreateCalendarSuccess = () => {
  openModalStatus.dayOff = false;
  openModalStatus.event = false;
  openModalStatus.reminder = false;
};

const preventEvent = (event) => {
  event?.preventDefault();
};

let timer;
const onChangeDrag = (event) => {
  const childrenList = event?.target?.children;
  if (!childrenList || childrenList?.length == 0) return;

  let indexByIds = {};

  for (let index = 0; index < childrenList.length; index++) {
    const stepId = childrenList[index]?.id;
    indexByIds[stepId] = index;
  }

  clearTimeout(timer);
  timer = setTimeout(async () => {
    let objectAdd = {};
    favoriteMenus.value.forEach((el) => {
      objectAdd[`${el.type}_${el.refId}`] = {
        refId: el.refId,
        type: el.type,
        index: indexByIds[el.refId],
      };
    });
    await updateDataFirebase(
      getUserPathFirebase(myProfileStore().myProfile?.id, 'favorites'),
      objectAdd
    );
  }, 1000);
};

const onClickLink = () => {
  if (dropdownRef.value && typeof dropdownRef.value?.onForceClose == 'function')
    dropdownRef.value?.onForceClose();
};
const goToWhiteboardPage = () => {
  const routeData = router.resolve({ name: 'FpWhiteboardList' });
  console.log('🚀 Tictop ~ routeData:', routeData);
  // window.open(routeData.href, '_blank');
  const width = 680 * 2;
  const height = 680;
  const iMyWidth = window.screen.width / 2 - (width / 2 + 10);
  const iMyHeight = window.screen.height / 2 - (height / 2 + 50);
  const newCallWindow = window.open(
    routeData.href,
    'fp-whiteboard-list',
    `width=${width},height=${height},left=${iMyWidth},top=${iMyHeight},directories=0,titlebar=0,location=0,toolbar=0,menubar=0,scrollbars=0,resizable=0,status=0`
  );

  newCallWindow?.focus();
};
</script>

<template>
  <VigDropdown
    ref="dropdownRef"
    placement="bottom"
    hide-on-click="outside"
    @on-dropdown-open="onDropdownOpen"
    @on-dropdown-close="onCloseButtonCall"
  >
    <template #dropdown-toggle>
      <button
        :title="$t('COMMON_LABEL_MENU')"
        class="h-10 w-10 flex-center hover:fill-current rounded-full relative"
      >
        <SynIcon
          name="menu"
          class="fill-current-500"
          custom-class="w-5 h-5 fill-current"
          :is-active="isOpenDropdown"
          has-action
        />
      </button>
    </template>
    <template #dropdown-menu>
      <div
        class="flex flex-col bg-gray-50"
        :style="'z-index: 20; width: 36rem; min-height: 450px; max-height: min(80vh, 800px);'"
      >
        <div class="px-4">
          <div
            class="
              text-md
              items-center
              justify-between
              space-x-2
              py-2
              flex
              font-medium
            "
          >
            <span>
              {{ $t('COMMON_LABEL_MENU') || 'Menu' }}
            </span>
            <div
              class="
                flex-center
                rounded-full
                hover:bg-gray-100
                h-8
                w-8
                cursor-pointer
              "
              @click="dropdownRef?.onForceClose()"
            >
              <SynIcon
                name="close"
                class="fill-gray-500"
                custom-class="w-4 h-4 text-gray-500"
                color="gray"
              />
            </div>
          </div>
        </div>
        <div
          v-if="isOpenDropdown"
          class="flex flex-col flex-1 min-h-0 overflow-auto"
        >
          <div class="flex px-4 pb-4 gap-4 items-start">
            <div class="w-2/3 rounded-md shadow bg-white">
              <div class="flex flex-col divide-y p-2 gap-2">
                <div v-for="group in allGroupKeys" :key="group?.id">
                  <div class="font-semibold py-1">
                    {{ $t(group.nameCode) }}
                  </div>
                  <template
                    v-if="group && group?.routes && group?.routes?.length > 0"
                  >
                    <SortAble
                      :disabled="group?.id !== 'FAVORITES'"
                      @on-end-sort="onChangeDrag"
                    >
                      <template #list-item>
                        <div
                          v-for="route in group?.routes"
                          :id="route?.name"
                          :key="route?.name"
                          class="
                            flex
                            relative
                            hover:bg-current-50
                            hover-to-show__parent
                            hover-to-hidden__parent
                          "
                        >
                          <RouterLink
                            :to="{
                              name: route?.name,
                            }"
                            class="flex-1 flex items-start gap-2 p-2 rounded-md"
                            @click="onClickLink"
                          >
                            <template v-if="group?.id == 'FAVORITES'">
                              <div
                                class="
                                  flex-center
                                  w-6
                                  h-6
                                  hover-to-hidden__children
                                "
                              >
                                <SynIcon :name="route?.meta?.icon" />
                              </div>
                              <div
                                class="
                                  flex-center
                                  w-6
                                  h-6
                                  hover-to-show__children
                                "
                              >
                                <SynIcon name="dragable" />
                              </div>
                            </template>

                            <div v-else class="flex flex-center w-6 h-6">
                              <SynIcon :name="route?.meta?.icon" />
                            </div>
                            <div class="flex-1 flex flex-col">
                              <span class="text-sm font-semibold">
                                {{ $t(route?.meta?.titleKey) }}
                              </span>
                              <span class="text-sm text-gray-600">
                                {{
                                  $t(route?.meta?.descriptionKey) ||
                                  route?.meta?.title
                                }}
                              </span>
                            </div>
                          </RouterLink>
                          <div
                            class="absolute z-100 right-1 top-1 gap-1 flex"
                            :class="
                              favoriteMenus?.some(
                                (menu) => menu?.id == route?.name
                              ) && group?.id !== 'FAVORITES'
                                ? ''
                                : 'hover-to-show__children'
                            "
                            @click.stop.prevent="preventEvent"
                          >
                            <div
                              v-if="route?.name == 'WhiteboardPage'"
                              class="
                                flex flex-center
                                rounded-full
                                w-7
                                h-7
                                bg-white
                                border border-current-50
                                cursor-pointer
                                hover:bg-current-50 hover:border-current-100
                              "
                              @click="goToWhiteboardPage"
                            >
                              <SynIcon name="Redirect" />
                            </div>
                            <FavoriteButton
                              :ref-id="route?.name"
                              :type="'menu'"
                              icon-name="Star"
                              update-immediately
                              class="hover:bg-current-100"
                            />
                          </div>
                        </div>
                      </template>
                    </SortAble>
                  </template>
                </div>
              </div>
            </div>
            <div class="w-1/3 sticky top-0 h-max rounded-md shadow bg-white">
              <div class="flex flex-col divide-y p-2 gap-2">
                <div class="flex flex-col">
                  <div class="font-semibold py-1">
                    {{ $t('COMMON_LABEL_CREATE') }}
                  </div>
                  <div
                    v-if="allCreateList && allCreateList?.length > 0"
                    class="flex flex-col"
                  >
                    <div
                      v-for="item in allCreateList"
                      :key="item?.id"
                      class="
                        flex
                        items-center
                        gap-4
                        p-2
                        hover:bg-current-50
                        cursor-pointer
                        rounded-md
                      "
                      @click="item?.onClick()"
                    >
                      <SynIcon :name="item?.icon" />
                      <span class="text-sm font-semibold">
                        {{ $t(item?.titleKey) }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="font-semibold py-1">
                    {{ $t('COMMON_LABEL_PERSONAL') }}
                  </div>
                  <div
                    v-if="
                      allPersonalCreateList && allPersonalCreateList?.length > 0
                    "
                    class="flex flex-col"
                  >
                    <div
                      v-for="item in allPersonalCreateList"
                      :key="item?.id"
                      class="
                        flex
                        items-center
                        gap-4
                        p-2
                        hover:bg-current-50
                        cursor-pointer
                        rounded-md
                      "
                      @click="item?.onClick()"
                    >
                      <SynIcon :name="item?.icon" />
                      <span class="text-sm font-semibold">
                        {{ $t(item?.titleKey) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </VigDropdown>

  <CreateGroupTask v-if="isCreateGroup" @cancel="isCreateGroup = false" />
  <ModalNewChat
    v-if="isNewChat"
    @on-close="isNewChat = false"
    @on-conversation-click="isNewChat = false"
  />

  <CreateNewCallModal
    v-if="isOpenMakeCallModal"
    @on-close="isOpenMakeCallModal = false"
  />

  <DayoffCreateNewModal
    v-if="openModalStatus.dayOff"
    @cancel="openModalStatus.dayOff = false"
    @on-create-success="onCreateCalendarSuccess"
  />

  <EventCreateModal
    v-if="openModalStatus.event"
    @cancel="openModalStatus.event = false"
    @on-create-success="onCreateCalendarSuccess"
  />
  <ReminderCreateModal
    v-if="openModalStatus.reminder"
    @cancel="openModalStatus.reminder = false"
    @on-create-success="onCreateCalendarSuccess"
  />
</template>
