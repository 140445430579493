<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 510.611 510.611"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="m509.761 289.339-38.759-219.818c-5.268-29.866-33.855-49.881-63.716-44.614l-332.432 58.618c-29.867 5.266-49.881 33.848-44.615 63.714l2.848 16.154c-19.451 8.485-33.087 27.892-33.087 50.434v217.718c0 30.327 24.673 55 55 55h330c30.327 0 55-24.673 55-55v-74.058l25.146-4.434c14.468-2.551 27.076-10.583 35.503-22.618 8.427-12.034 11.663-26.63 9.112-41.096zm-33.686 23.889c-3.83 5.47-9.562 9.121-16.138 10.281l-19.937 3.515v-17.098-68.05-28.05c0-30.327-24.673-55-55-55h-322.254l-2.962-16.797c-1.16-6.576.312-13.21 4.142-18.681 3.83-5.47 9.562-9.121 16.138-10.281l332.432-58.617c1.463-.258 2.917-.382 4.354-.382 11.896 0 22.473 8.548 24.608 20.661l38.759 219.818c1.159 6.577-.312 13.211-4.142 18.681zm-91.075 143.317h-330c-13.785 0-25-11.215-25-25v-106.619h380v106.618c0 13.786-11.215 25.001-25 25.001zm-330-267.718h330c13.785 0 25 11.215 25 25v13.05h-380v-13.05c0-13.785 11.215-25 25-25zm355 68.05v38.05h-380v-38.05z"
        class=""
      ></path>
      <path
        d="m144.352 396.305h-68.05c-8.284 0-15 6.716-15 15s6.716 15 15 15h68.05c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
        class=""
      ></path>
    </g>
  </svg>
</template>
