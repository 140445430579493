import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import GroupService from '@/application/services/GroupService';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import system from '@/store/system';
import { TaskActionType } from '@/domain/enums/taskEnum';
import TaskService from '@/application/services/task/TaskService';
import { getUrgencyValue } from '@/ui/hooks/taskHook';

export default defineStore({
    id: storeId.dashboardGroup,
    state: () => ({
        overviewGroupInfo: [] as any[],
        overviewGroupIds: [] as any[],
        overviewGroupFullTasks: {} as any,
        overviewGroupTasks: {} as any,
        overviewGroupNotification: {} as any,
        _groupService: GroupService.getInstance(),
        isLoading: true,
        isLoadingGroupTask: true,
        orderByKey: 1,
        keySearchGroup: null,
        isHiddenTask: system().getSystemSettingDashboardGroups() === 'true',
    }),
    getters: {},
    actions: {
        setKeySearchGroup(keySearch) {
            this.keySearchGroup = keySearch;
        },

        onToggleHiddenTask() {
            this.isHiddenTask = !this.isHiddenTask;
            system().setSystemSettingDashboardGroups(
                this.isHiddenTask.toString()
            );
        },

        onOrderBy(key) {
            if (key === this.orderByKey) return;
            this.orderByKey = key;
            this.getOverviewGroup(key);
        },

        async getOverviewGroupInfo(orderBy, dontLoading = false) {
            if (!dontLoading) this.isLoading = true;

            const res: any = await this._groupService.getOverviewGroupInfos(
                orderBy
            );

            const result = res?.result;

            if (!result || result.length == 0) {
                this.isLoading = false;
                return;
            }

            this.overviewGroupInfo = result.reduce((old, current) => {
                const groupId = current?.id;
                return {
                    ...old,
                    [groupId]: current,
                };
            }, {});

            this.overviewGroupIds = result.map((o) => {
                return o.id;
            });

            this.updateNotificationInListGroup();
            this.isLoading = false;
        },

        async updateAvatarGroupTeamLocal(groupId, avatars) {
            const info =
                this.overviewGroupInfo[
                    typeof groupId == 'string' ? parseInt(groupId) : groupId
                ];
            this.overviewGroupInfo[
                typeof groupId == 'string' ? parseInt(groupId) : groupId
            ] = {
                ...this.overviewGroupInfo[
                    typeof groupId == 'string' ? parseInt(groupId) : groupId
                ],
                avatarThumbnailUrl:
                    avatars?.avatarThumbnailUrl ||
                    info?.avatarThumbnailUrl ||
                    '',
                avatarThumbUrl:
                    avatars?.avatarThumbnailUrl || info?.avatarThumbUrl || '',
                avatarUrl: avatars?.avatarUrl || info?.avatarUrl || '',
            };
        },

        updateNotificationInListGroup() {
            return notificationSettingStore().getUserNotificationSettingInGroups();
        },

        async getOverviewGroupTask() {
            try {
                this.isLoadingGroupTask = true;
                const res: any =
                    await this._groupService.getOverviewGroupTasks();
                const dataTasks = res?.result;

                this.overviewGroupIds.forEach((groupId) => {
                    if (!dataTasks[groupId]) return;
                    this.overviewGroupTasks[groupId] = {
                        ...dataTasks[groupId],
                        groupTasks: dataTasks[groupId].groupTasks.slice(0, 4),
                    };
                });

                this.overviewGroupFullTasks = dataTasks;
            } catch (error) {
                console.log(
                    '🚀 ~ file: overview-group-store.ts ~ line 66 ~ getOverviewGroupTask ~ error',
                    error
                );
            } finally {
                this.isLoadingGroupTask = false;
            }
        },

        async getOverviewGroup(orderBy) {
            await this.getOverviewGroupInfo(orderBy, false);
            this.getOverviewGroupTask();
        },

        setOverviewTeamTaskMore(groupId, tasks) {
            const currentData = this.overviewGroupTasks[groupId].groupTasks;
            this.overviewGroupTasks[groupId].taskListSeemore = tasks?.filter(
                (o) => !currentData || !currentData.some((p) => p.id == o.id)
            );

            this.overviewGroupTasks[groupId].isShowMore = true;
        },

        setCloseSeeMoreTask(groupId) {
            this.overviewGroupTasks[groupId].isShowMore = false;
        },

        setFullTaskForGroup(groupId) {
            if (
                !this.overviewGroupFullTasks[groupId] ||
                this.overviewGroupFullTasks[groupId].updated
            )
                return;

            this.overviewGroupTasks[groupId].groupTasks =
                this.overviewGroupFullTasks[groupId].groupTasks;
            this.overviewGroupFullTasks[groupId].updated = true;
        },

        updateNotificationAll(listNotificationGroup) {
            this.overviewGroupNotification = listNotificationGroup.reduce(
                (old, current) => {
                    const groupId = current?.refId;
                    return {
                        ...old,
                        [groupId]: current,
                    };
                },
                {}
            );
        },

        updateNotificationByGroupId(groupNotification, groupId) {
            if (!groupId) return;

            this.overviewGroupNotification = {
                ...this.overviewGroupNotification,
                [groupId]: groupNotification,
            };
        },

        updateOverviewGroupInfo(newGroupInfo) {
            const groupId = newGroupInfo?.id;
            if (!groupId) return;

            this.overviewGroupInfo[groupId] = {
                ...this.overviewGroupInfo[groupId],
                ...newGroupInfo,
            };
        },

        removeGroupById(groupId) {
            if (!groupId) return;

            this.overviewGroupIds = this.overviewGroupIds.filter(
                (id) => id !== groupId
            );
        },

        async updateUserTasksRealtime(
            taskAction: TaskActionType,
            taskDetail: {
                id: number;
                assigneeId?: number;
                groupId?: number;
            }
        ) {
            if (!taskDetail) return;

            const { groupId, id: taskId } = taskDetail;

            if (
                !groupId ||
                !Object.prototype.hasOwnProperty.call(
                    this.overviewGroupTasks,
                    groupId
                )
            )
                return;

            switch (taskAction) {
                case TaskActionType.Delete:
                case TaskActionType.MoveOut:
                    this.overviewGroupTasks[groupId].groupTasks =
                        this.overviewGroupTasks[groupId]?.groupTasks?.filter(
                            (t) => t?.id !== taskId
                        ) || [];
                    break;

                default:
                    {
                        // 2. Add or update
                        const res =
                            await TaskService.getInstance().getDetailTaskIncognitoById(
                                taskId
                            );
                        const taskDetail = res['result'];

                        const updatedTask = {
                            ...taskDetail,
                            urgency: getUrgencyValue(
                                taskDetail?.priority,
                                taskDetail?.important
                            ),
                        };

                        const existedTaskIndex = this.overviewGroupTasks[
                            groupId
                        ]?.groupTasks?.findIndex((t) => t?.id == taskId);
                        if (existedTaskIndex > -1) {
                            this.overviewGroupTasks[groupId].groupTasks[
                                existedTaskIndex
                            ] = updatedTask;
                        } else {
                            this.overviewGroupTasks[groupId].groupTasks = [
                                updatedTask,
                                ...this.overviewGroupTasks[groupId]?.groupTasks,
                            ];
                        }
                    }
                    break;
            }
        },
    },
});
