<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import calendarStore from '@/store/calendar';
import { formatDate } from '@/ui/hooks/calendarHook';

const props = defineProps({
  event: {
    default: () => {},
    type: Object,
  },
  customClass: {
    default: 'p-4',
    type: String,
  },
});

watch(
  () => props.event,
  async () => {
    isLoading.value = true;
    await _calendarStore.getSystemEventDetail(eventId.value);
    isLoading.value = false;
  }
);

defineEmits(['cancel']);

const _calendarStore = calendarStore();
const eventId = computed(() => props.event?.id);
const eventDetail = computed(() => _calendarStore.calendarDetailSymtem);
const isLoading = ref(false);

onMounted(async () => {
  isLoading.value = true;
  await _calendarStore.getSystemEventDetail(eventId.value);
  isLoading.value = false;
});
</script>
<template>
  <div
    v-if="isLoading"
    class="flex items-center space-y-2 pt-4 pb-8 px-4 flex-col w-96"
  >
    <div
      class="w-full rounded-md bg-gray-200 animate-pulse mb-2"
      style="height: 245px"
    ></div>
    <div class="flex items-center space-x-2 w-full">
      <div class="rounded-full bg-gray-200 animate-pulse w-8 h-4"></div>
      <div class="rounded-full bg-gray-200 animate-pulse w-full h-4"></div>
    </div>
    <div class="flex space-x-2 w-full">
      <div class="rounded-full bg-gray-200 animate-pulse w-8 h-4"></div>
      <div class="rounded-md bg-gray-200 animate-pulse w-full h-10"></div>
    </div>
    <div class="flex items-center space-x-2 w-full">
      <div class="rounded-full bg-gray-200 animate-pulse w-8 h-4"></div>
      <div class="rounded-full bg-gray-200 animate-pulse w-full h-4"></div>
    </div>
  </div>

  <div v-else class="flex flex-col px-2 w-96">
    <!--    <div-->
    <!--      class="w-96 bg-red-500 rounded-md mt-2"-->
    <!--      :style="{-->
    <!--        'background-image': `url(${eventDetail?.path})`,-->
    <!--        'background-size': 'cover',-->
    <!--        height: '15rem',-->
    <!--      }"-->
    <!--    ></div>-->
    <div class="mt-2 rounded overflow-hidden" style="height: 246px">
      <img :src="eventDetail?.path" class="w-full h-full object-cover" alt="" />
    </div>

    <!-- TTTLE -->
    <div class="flex items-center space-x-2 px-6 pt-4">
      <div class="w-6 h-6 flex items-center mt-2">
        <div class="w-3 h-3 rounded-full bg-red-500"></div>
      </div>
      <div class="text-current-800" style="font-size: 22px">
        {{ eventDetail?.title }}
      </div>
    </div>

    <!-- TIMER -->
    <div class="flex items-center pl-14 text-sm pb-3 space-x-2">
      <span>{{ formatDate(eventDetail?.startDate).date }}</span>
      <span v-if="eventDetail?.startDate != eventDetail?.endDate">-</span>
      <span v-if="eventDetail?.startDate != eventDetail?.endDate">
        {{ formatDate(eventDetail?.endDate).date }}
      </span>
    </div>

    <!-- DESCRIPTION -->
    <template v-if="eventDetail?.description">
      <div class="flex items-start space-x-2 px-6 pb-1">
        <div
          :title="$t('COMMON_LABEL_WRITE')"
          class="w-6 h-6 flex items-start mt-1"
        >
          <SynIcon
            name="align-left"
            custom-class="w-4 h-4 fill-gray-500 text-gray-400"
            is-active
          />
        </div>
        <div class="pl-1 text-sm">
          {{ eventDetail?.description || '' }}
        </div>
      </div>
    </template>

    <!-- TYPE -->
    <div class="flex items-start space-x-2 pb-1 px-6">
      <div
        :title="$t('COMMON_LABEL_WRITE')"
        class="w-6 h-6 flex items-start mt-1"
      >
        <SynIcon
          name="calendars"
          custom-class="w-4 h-4 fill-gray-500 text-gray-400"
          is-active
        />
      </div>
      <div class="text-sm">{{ $t('CALENDAR_TYPE_HOLIDAY_DES') }}</div>
    </div>
  </div>
</template>
