<script setup lang="ts">
import { onMounted, onUnmounted, computed } from 'vue';
import alertStore from '@/store/alert';
import taskStore from '@/store/task';
import { AlertType } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import { getBgClass } from '@/ui/hooks/taskHook';
import { formatTaskCode } from '@/ui/hooks/taskHook';

const _taskStore = taskStore();
const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
  notificationValue: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    default: 'info',
  },
  title: {
    type: String,
    default: '',
  },
  body: {
    type: String,
    default: '',
  },
  duration: {
    type: Number,
    default: 5000,
  },
  avatar: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['onOpenTaskDetail', 'openAboutChat']);

const _alertStore = alertStore();
const notificationTypeClass = computed(() => {
  if (props.type == AlertType.error) {
    return 'bg-red-400 text-white';
  }
  if (props.type == AlertType.info) {
    return 'bg-gray-300';
  }
  if (props.type == AlertType.warning) {
    return 'bg-orange-200 text-current-800';
  }
  return `bg-current-400 text-white`;
});

const notificationTypeLable = computed(() => {
  let type = '';
  switch (props.notificationValue?.actionType) {
    case 'AddNew':
      type = translate('NOTIFICATION_LABEL_TYPE_NEW');
      break;
    case 'Finished':
      type = translate('NOTIFICATION_LABEL_TYPE_FINISHED');
      break;
    case 'Cloned':
      type = translate('NOTIFICATION_LABEL_TYPE_CLONE');
      break;
    default:
      type = translate('NOTIFICATION_LABEL_TYPE_UPDATED');
      break;
  }
  return type;
});

const metaData = computed(() => props.notification?.metaData);

const hasAction = computed(() => metaData.value?.hasAction);
const statusTypeNotification = (itemType, actionType) => {
  if (itemType.toUpperCase() == 'TASK') {
    switch (actionType) {
      case 'AddNew':
        return {
          nameIcon: 'news',
          bgColor: 'bg-current-500',
          textColor: 'text-current-500',
          label: translate('NOTIFICATION_LABEL_TYPE_NEW'),
        };
      case 'Finished':
        return {
          nameIcon: 'check',
          bgColor: 'bg-green-500',
          textColor: 'text-green-500',
          label: translate('NOTIFICATION_LABEL_TYPE_FINISHED'),
        };
      case 'Cloned':
        return {
          nameIcon: 'news',
          bgColor: 'bg-current-500',
          textColor: 'text-current-500',
          label: translate('NOTIFICATION_LABEL_TYPE_CLONE'),
        };

      default:
        return {
          nameIcon: 'edit',
          bgColor: 'bg-orange-500',
          textColor: 'text-orange-500',
          label: translate('NOTIFICATION_LABEL_TYPE_UPDATED'),
        };
    }
  } else {
    return {
      nameIcon: 'group',
      bgColor: 'bg-gray-500',
      textColor: 'text-gray-500',
    };
  }
};

let timeoutFn;
const timeout = () => {
  timeoutFn = setTimeout(
    () => _alertStore.removeDataAlerts(props.notification),
    props.duration || 5000
  );
};
onMounted(() => {
  timeout();
});
onUnmounted(() => {
  clearTimeout(timeoutFn);
});

const onClose = (notification) => {
  _alertStore.removeDataAlerts(notification);
};
const onMouseover = () => {
  clearTimeout(timeoutFn);
};
const onMouseleave = () => {
  timeout();
};
const onClick = () => {
  // _alertStore.handleClickAlertItem(props.notification);

  if (
    metaData.value?.hasAction &&
    metaData.value?.isOpenTaskDetail &&
    metaData.value?.taskCode
  ) {
    emit('onOpenTaskDetail', metaData.value?.taskCode);
  }
};

const getTaskConfigName = (sitKey, typeKey) => {
  return _taskStore.getConfigName(sitKey, typeKey);
};
</script>

<template>
  <div
    class="
      relative
      flex
      items-center
      p-2
      mt-2
      text-sm
      rounded
      bg-opacity-80
      cursor-pointer
      text-white
      w-full
    "
    :class="`${notificationTypeClass} ${hasAction ? 'cursor-pointer' : ''}`"
    @mouseover="onMouseover"
    @mouseleave="onMouseleave"
    @click="onClick"
  >
    <div class="relative flex items-center w-full">
      <syn-avatar-status
        :name="notificationValue.executor_name"
        size="mini"
        :src="notificationValue?.icon?.replace('original', 'small')"
        :color-status="
          statusTypeNotification(
            notificationValue?.type,
            notificationValue?.actionType
          )?.bgColor
        "
        :name-icon="
          statusTypeNotification(
            notificationValue?.type,
            notificationValue?.actionType
          )?.nameIcon
        "
      />
      <div class="w-full flex flex-col pl-3">
        <!-- P1: BODY NOTIFICATION -->
        <div class="flex flex-col pb-2">
          <div
            :title="
              notificationValue?.task_name
                ? notificationValue?.task_name
                : notificationValue?.body
            "
            class="pl-2 text-overflow-hidden"
          >
            <span v-if="notificationTypeLable" class="font-medium">{{
              '[' + notificationTypeLable + ']'
            }}</span>
            {{
              notificationValue?.task_name
                ? notificationValue?.task_name
                : notificationValue?.body
            }}
          </div>
          <span
            v-if="
              notificationValue[`grouped_content_${lang}`] ||
              notificationValue?.grouped_content
            "
            :title="
              notificationValue[`grouped_content_${lang}`] ||
              notificationValue?.grouped_content
            "
            class="pl-2 text-white align-text-bottom"
            style="font-size: 10.5px"
          >
            {{
              notificationValue[`grouped_content_${lang}`] ||
              notificationValue?.grouped_content
            }}
          </span>
        </div>

        <!-- P2: TASK CODE, PRIORITY -->
        <div class="flex w-full justify-between items-end space-x-4">
          <div class="flex items-end space-x-3 text-xs pl-2">
            <span class="font-bold">{{
              formatTaskCode(notificationValue?.task_code)
            }}</span>

            <div class="flex-center space-x-1 font-bold min-w-max">
              <div
                v-if="/true/i.test(notificationValue?.isPlannedToTreat)"
                style="font-size: 10px"
                class="flex-center px-2 py-0.25 rounded-full"
                :class="getBgClass(1, 'planType')"
              >
                {{ getTaskConfigName(1, 'planType') }}
              </div>
              <div
                v-if="/true/i.test(notificationValue?.isPlannedToFinish)"
                class="flex-center px-2 py-0.25 rounded-full"
                style="font-size: 10px"
                :class="getBgClass(2, 'planType')"
              >
                {{ getTaskConfigName(2, 'planType') }}
              </div>
              <PriorityTask
                v-if="/true/i.test(notificationValue?.important) !== null"
                :urgency="
                  getUrgencyValue(
                    Number(notificationValue?.priority),
                    /true/i.test(notificationValue?.important)
                  )
                "
                size="tiny"
                is-hidden-label
              />
            </div>
          </div>
        </div>

        <!-- P3: GROUP NAME, EXECUTOR NAME -->
        <div
          class="flex items-center space-x-1 justify-between"
          style="padding-top: 2.5px"
        >
          <div class="flex flex-col">
            <div
              style="font-size: 11px"
              class="pl-2 w-full flex items-start space-x-1"
            >
              <span v-if="notificationValue?.group_name" class="text-white">
                {{ notificationValue?.group_name }}
              </span>
              <span v-if="notificationValue?.group_name">{{ '-' }}</span>
              <span>
                <span>{{ $t('TASK_DETAIL_LABEL_BY') + ' ' }}</span>
                <span class="text-white">
                  {{ notificationValue?.executor_name }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          p-2
          rounded-full
          bg-current-50 bg-opacity-50
          hover:bg-current-100
          flex-center
          h-full
        "
        @click.stop="$emit('openAboutChat')"
      >
        <SynIcon name="Message" custom-class="w-4 h-4 fill-white text-white" />
      </div>
    </div>

    <div
      class="
        cursor-pointer
        w-6
        h-6
        rounded-full
        hover:bg-white hover:bg-opacity-20
        flex-center
        absolute
        top-1
        right-1
        do-not-close-task-detail-drawer
      "
      @click.stop="onClose(notification)"
    >
      <SynIcon name="close" custom-class="w-4 h-4 fill-white" />
    </div>
  </div>
</template>
