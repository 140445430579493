import CatalogRepository from '@/application/repositories/CatalogRepository';
import UnauthRepository from '../repositories/UnauthRepository';
import { NotificationTypeEnum, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

export default class CatalogService {
    private static instance: CatalogService;
    _catalogRepository: CatalogRepository;
    _unauthRepository: UnauthRepository;

    constructor() {
        this._catalogRepository = CatalogRepository.getInstance();
        this._unauthRepository = UnauthRepository.getInstance();
    }

    public static getInstance(): CatalogService {
        if (!CatalogService.instance) {
            // Get from local storage
            CatalogService.instance = new CatalogService();
        }

        return CatalogService.instance;
    }
    async saveOrganizationSetting(payload) {
        const res = await this._catalogRepository.saveOrganizationSetting({
            organizationInfo: payload,
            positions: [],
            taskTypes: [],
            domains: [],
            decideTime: payload?.decideTime,
        });
        return res.result;
    }
    async registerNewOrganization(
        payload,
        autoRedirect?: boolean
    ): Promise<any> {
        const res = await this._catalogRepository.registerNewOrganization({
            organizationInfo: {
                address: '',
                addressDetail: '',
                logo: '',
                model: '',
                name: '',
                size: '',
                type: '',
                subType: '',
                ...payload?.organizationInfo,
            },
            positions: payload?.positions || [],
            taskTypes: payload?.taskTypes || [],
            domains: payload?.domains || [],
        });
        const newOrg = res.result;
        openNotification({
            metaData: {
                organizationId: newOrg?.id,
                organizationName: newOrg?.name,
                organizationAvatar: newOrg?.logoUrl,
                organizationType: newOrg?.type,
                organizationModel: newOrg?.model,
                autoRedirect,
            },
            notificationType: NotificationTypeEnum.organization_new,
        });
        return newOrg;
    }
    getUserProfile(): Promise<any> {
        return this._catalogRepository.getUserProfile();
    }
    getOrganizationConfigDefault(): Promise<any> {
        return this._catalogRepository.getOrganizationConfigDefault();
    }
    getCheckCode(data): Promise<any> {
        return this._catalogRepository.getCheckCode(data);
    }
    getCheckEmailPhone(data): Promise<any> {
        return this._catalogRepository.getCheckEmailPhone(data);
    }
    checkEmailPhoneInOrganization(data): Promise<any> {
        return this._catalogRepository.checkEmailPhoneInOrganization(data);
    }
    saveTeam(data): Promise<any> {
        return this._catalogRepository.saveTeam(data);
    }
    saveTeamDraft(data): Promise<any> {
        return this._catalogRepository.saveTeamDraft(data);
    }
    getDraftTeam(): Promise<any> {
        return this._catalogRepository.getDraftTeam();
    }
    getOrganizationInfo(id: string): Promise<any> {
        return this._catalogRepository.getOrganizationInfo(id);
    }
    updateOrganization(data: any): Promise<any> {
        return this._catalogRepository.updateOrganization(data);
    }

    uploadAvatarAws(file): Promise<any> {
        return this._catalogRepository.uploadAvatarAws(file);
    }
    skipAddMember(): Promise<any> {
        return this._catalogRepository.skipAddMember();
    }
    inviteUser(data): Promise<any> {
        return this._catalogRepository.inviteUser(data);
    }
    getInvitedUser(params): Promise<any> {
        return this._unauthRepository.getInvitedUser(params);
    }
    async acceptedInvitation(data, organization, location): Promise<any> {
        const res = await this._catalogRepository.acceptedInvitation(data);

        if (location == 'ORGANIZATION_HEADER')
            openNotification({
                metaData: {
                    organizationId: organization?.id,
                    organizationName: organization?.name,
                    organizationAvatar: organization?.logoUrl,
                    organizationType: organization?.type,
                    organizationModel: organization?.model,
                    actionType: 'ATTACH',
                },
                notificationType: NotificationTypeEnum.organization_new,
            });
        else
            openNotification({
                title: translate('COMMON_LABEL_SUCCESS'),
                body: '',
            });

        return res;
    }
    rejectedInvitation(data): Promise<any> {
        return this._catalogRepository.rejectedInvitation(data);
    }
    async attachOrganizationByCode(
        data,
        options?: {
            isHiddenNotification?: boolean;
        }
    ): Promise<any> {
        try {
            const res = await this._catalogRepository.attachOrganizationByCode(
                data
            );

            const newOrg = res?.result?.attachedOrganization || res?.result;
            if (newOrg?.id && !options?.isHiddenNotification)
                openNotification({
                    metaData: {
                        organizationId: newOrg?.id,
                        organizationName: newOrg?.name,
                        organizationAvatar: newOrg?.logoUrl,
                        organizationType: newOrg?.type,
                        organizationModel: newOrg?.model,
                        actionType: 'ATTACH',
                    },
                    notificationType: NotificationTypeEnum.organization_new,
                });
            return newOrg;
        } catch (error: any) {
            console.log('🚀 Tictop ~ error:', error);
            return {
                ...error?.error,
                code: error?.error?.code,
                message: error?.error?.message,
            };
        }
    }
    checkOrganizationAttachCode(data): Promise<any> {
        return this._catalogRepository.checkOrganizationAttachCode(data);
    }
    getPendingUsers(): Promise<any> {
        return this._catalogRepository.getPendingUsers();
    }

    updateInvitations(data): Promise<any> {
        return this._catalogRepository.updateInvitations(data);
    }

    getOrganizationAttachmentCode(): Promise<any> {
        return this._catalogRepository.getOrganizationAttachmentCode();
    }
    generateOrganizationAttachmentCode(): Promise<any> {
        return this._catalogRepository.generateOrganizationAttachmentCode();
    }
    resendInvitation(data): Promise<any> {
        return this._catalogRepository.resendInvitation(data);
    }
    cancelInvitation(data): Promise<any> {
        return this._catalogRepository.cancelInvitation(data);
    }
    getMyPendingInvitation(): Promise<any> {
        return this._catalogRepository.getMyPendingInvitation();
    }
    checkUserInstallApp(): Promise<any> {
        return this._catalogRepository.checkUserInstallApp();
    }
    getUserOrganizations(): Promise<any> {
        return this._catalogRepository.getUserOrganizations();
    }
    onFinishWithPersonalMode(): Promise<any> {
        return this._catalogRepository.onFinishWithPersonalMode();
    }
    updateDecideTime(payload: number): Promise<any> {
        return this._catalogRepository.updateP(payload);
    }
    updateAndFinishSetupOrganization(payload): Promise<any> {
        return this._catalogRepository.updateAndFinishSetupOrganization(
            payload
        );
    }
    getAllMyPendingInvitation(): Promise<any> {
        return this._catalogRepository.getAllMyPendingInvitation();
    }
    usingTrialPackage(planId: string) {
        return this._catalogRepository.usingTrialPackage(planId);
    }
    payImmediately(planId: string) {
        return this._catalogRepository.payImmediately(planId);
    }
    getPaymentBonus() {
        return this._catalogRepository.getPaymentBonus();
    }
    searchAnyUsersByPhoneAndEmail(phone: string, email?: string) {
        return this._catalogRepository.searchAnyUsersByPhoneAndEmail(
            phone,
            email
        );
    }
}
