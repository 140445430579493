<script setup lang="ts">
import { ref, computed } from 'vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import taskStore from '@/ticket/store/task-store';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import TaskState from '@/ui/modules/task/components/TaskState.vue';
import myProfileStore from '@/store/auth/my-profile';
import userStore from '@/ticket/store/user-store';
import ticketPlugin from '@/ticket/services/plugin-services';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import TicketComment from '@/ticket/modules/task/TicketComment.vue';
import TicketTaskCommentBottom from '@/ticket/modules/task/TicketTaskCommentBottom.vue';

const _userStore = userStore();
const props = defineProps<{
  taskId: number;
  taskCode: string;
}>();

defineEmits(['onClose']);

const _taskStore = taskStore();
const currentTask = ref({} as any);
const isLoadingInit = ref(false);
const init = async () => {
  isLoadingInit.value = true;
  if (!props.taskId && !props.taskCode) return;

  currentTask.value = await _taskStore.getDetailByCode(props.taskCode);

  isLoadingInit.value = false;
};

const currentFileIndex = ref(0);
const isPreview = ref(false);

const allFiles = computed(() => {
  // filter(file => !file.noteAudioId)
  const files = currentTask.value.files?.map((o) => {
    o.path = o.fileUrl;
    o.contentType = o.type;
    return o;
  });
  const notes = currentTask.value.notes?.map((o) => {
    o.fileUrl = o.imagePath;
    o.contentType = 'image/jpeg';
    o.isNote = true;
    return o;
  });
  return [...files, ...notes];
});
const myTimezone = computed(() => myProfileStore().getTimezone);

const onOpenFiles = (file) => {
  currentFileIndex.value = allFiles.value.findIndex((o) => o.id == file.id);
  isPreview.value = true;

  ticketPlugin.openNote();
};
const onCloseFile = () => {
  isPreview.value = false;

  ticketPlugin.closeNote();
};

const fileShowList = computed(() => {
  if (!currentTask.value?.files) return [];
  return currentTask.value.files
    ?.filter((attachment) => !String(attachment['type']).includes('audio'))
    .map((o) => {
      o.path = o.fileUrl;
      o.contentType = o.type;
      return o;
    });
});

const audios = computed(() => {
  if (!currentTask.value?.files) return [];
  return currentTask.value.files
    ?.filter((attachment) => String(attachment['type']).includes('audio'))
    .map((o) => {
      o.path = o.fileUrl;
      o.contentType = o.type;
      return o;
    });
});

init();

const getProjectName = (projectId) => {
  return _userStore.getProjectById(projectId);
};

const onSaveCommentSuccess = (isNeedUpdateAttachments) => {
  if (isNeedUpdateAttachments) currentTask.value?.getAttachmentData();
  // onResetCacheStateComment();
};
</script>

<template>
  <div v-if="isLoadingInit" class="w-full h-full p-4">
    <syn-task-loading />
  </div>
  <template v-else>
    <div
      v-if="currentTask?.errorCode"
      id="task-detail-page"
      class="flex-center flex-1 min-h-0 w-full bg-white"
      style="max-width: 50rem"
    >
      <div class="py-6 px-4 bg-opacity-40 rounded-xl flex flex-col">
        <syn-animation
          name="searchNotFound"
          stype="width: 200px; height:200px"
        />
        <div class="flex flex-col space-y-4 pt-8 text-center">
          <span
            class="font-semibold text-2xl"
            v-html="
              $t(`COMMON_ERROR_BY_CODE_${currentTask?.errorCode}`, {
                code: formatTaskCode(currentTask?.code) || '',
              }) ||
              $t(`TASK_LABEL_CAN_NOT_FIND_OUT_TASK`) ||
              'Something wrong'
            "
          >
          </span>
          <span class="text-xl">
            {{
              $t('COMMON_LABEL_PAGE_NOT_FOUND_BODY', { name: 'task' }) ||
              'We are sorry.'
            }}
          </span>
          <SynButton
            :label="$t('COMMON_LABEL_REFRESH')"
            type-text
            @click.stop="init"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="currentTask?.id"
      class="w-full flex-1 min-h-0 flex flex-col text-sm bg-white relative"
      style="max-width: 50rem"
    >
      <div class="flex items-start space-x-2 justify-between text-xl p-4">
        <div
          class="flex-1 items-start space-x-2 py-2 rounded inline-block w-2/3"
        >
          <span
            v-encode-html="{ text: currentTask?.name }"
            class="text-current-800 break-words"
          >
          </span>
        </div>
        <div class="flex items-center space-x-1">
          <span
            class="
              font-semibold
              text-base text-gray-600
              px-2
              bg-gray-50
              rounded
            "
          >
            {{ formatTaskCode(currentTask.code) }}
          </span>

          <section>
            <div
              v-if="currentTask.priority === 0"
              class="flex items-center space-x-2 text-xs"
            >
              <div
                class="h-5 w-5 bg-red-300 flex-center rounded-full"
                :title="'High'"
              >
                <SynIcon
                  class="fill-red-500"
                  name="high"
                  custom-class="h-3 w-3"
                />
              </div>
            </div>
            <div v-else class="flex items-center space-x-2 text-xs">
              <div
                class="h-5 w-5 flex-center rounded-full bg-blue-200"
                :title="'Normal'"
              ></div>
            </div>
          </section>
          <!-- <SynIcon
            v-vig-tooltip="$t('TASK_DETAIL_COPY_LINK') || 'Copy link'"
            has-action
            name="link"
            custom-class="w-5 h-5 fill-none"
            @click="onCopyLink"
          /> -->
        </div>
      </div>

      <div
        class="
          flex-1
          min-h-0
          overflow-y-auto
          small-scrollbar
          p-4
          flex flex-col
          space-y-4
        "
      >
        <div class="flex flex-col text-sm">
          <div class="flex space-x-2">
            <span class="text-gray-800">
              {{ $t('GROUP_LABEL_CREATE_DATE') || 'Created date' }} :
            </span>
            <span :title="'Created date'">
              {{
                $filters.dayjs(
                  currentTask.creationTime,
                  'MMM DD, YYYY, hh:mm A',
                  myTimezone || 0
                )
              }}
            </span>
          </div>
          <div
            v-if="currentTask.lastModificationTime !== currentTask.creationTime"
            class="flex space-x-2"
          >
            <span class="text-gray-800">
              {{ $t('TASK_TABLE_LABEL_UPDATED_DATE') || 'Updated date' }} :
            </span>
            <span :title="'Created date'">
              {{
                $filters.dayjs(
                  currentTask.lastModificationTime,
                  'MMM DD, YYYY, hh:mm A',
                  myTimezone || 0
                )
              }}
            </span>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div
            class="
              flex-center
              space-x-2
              border border-current-100
              p-2
              rounded
              relative
            "
          >
            {{ getProjectName(currentTask.projectId) }}
          </div>
          <TaskState
            v-if="currentTask.taskLife !== 0"
            :task-life="currentTask?.taskLife"
            readonly
          />
        </div>

        <!-- Desciption -->
        <div v-if="currentTask.description" class="w-full flex flex-col">
          <div
            class="
              whitespace-pre-wrap
              text-gray-600
              py-2
              rounded
              inline-block
              w-full
            "
          >
            <span
              class="break-words"
              v-html="
                currentTask.description ||
                $t('COMMON_LABEL_PLACEHOLDER_ADD_COMMENT') ||
                'Add a comment ...'
              "
            ></span>
          </div>
        </div>
        <!-- Files -->
        <div class="w-full items-center flex flex-wrap">
          <upload-preview
            v-for="(file, index) in fileShowList"
            :key="index"
            :file="file"
            readonly
            is-hidden-info
            @on-select="onOpenFiles(file)"
          />
          <upload-preview
            v-for="(file, index) in currentTask.notes"
            :key="index"
            :file="file"
            readonly
            is-note
            @on-select="onOpenFiles(file)"
          />
        </div>
        <div class="w-full items-center flex flex-wrap space-x-2">
          <syn-audio
            v-for="(file, index) in audios?.filter((o) => !o?.noteAudioId)"
            :key="index"
            :file="file"
            :src="file.fileUrl"
            readonly
            class="h-10 w-64"
          />
        </div>
        <div class="w-full flex flex-col justify-between relative">
          <TicketComment
            :task-id="currentTask?.id"
            :current-task="currentTask"
            @on-save-success="onSaveCommentSuccess"
          />
        </div>
      </div>

      <!-- COMMENT BOTTOM   -->
      <section
        class="
          w-full
          flex
          items-start
          justify-between
          pb-3
          pt-4
          px-3
          border-t border-gray-100
        "
        style="max-height: 25rem"
      >
        <TicketTaskCommentBottom
          :task-id="taskId"
          :current-task="currentTask"
          @on-save-success="onSaveCommentSuccess"
        />
        <!--      <div class="pl-2 pt-2 flex-center">-->
        <!--        <GroupAttachmentAction-->
        <!--          :has-permission="hasPermission"-->
        <!--          :input-file-id="taskDetailFileInputId"-->
        <!--          @handle-click-note="onOpenCreateNote"-->
        <!--          @handle-click-audio="isOpenRecording = true"-->
        <!--        />-->
        <!--      </div>-->
      </section>

      <!-- END -->
    </div>
  </template>

  <ModalMultipleFileViewer
    v-if="isPreview && allFiles?.length > 0"
    :start-index="currentFileIndex"
    :files="allFiles"
    :editable="false"
    @on-close="onCloseFile"
  >
    <template #title>
      <span>
        {{ formatTaskCode(currentTask?.code) }} -
        {{ currentTask?.name }}
      </span>
    </template>
  </ModalMultipleFileViewer>
</template>
