<script setup lang="ts">
import { computed } from 'vue';
import overviewMonitorStore from '@/store/dashboard/overview-monitor-store';

const _overviewMonitorStore = overviewMonitorStore();

const scoreTodayCapacity = computed(() => _overviewMonitorStore.analyze);
</script>
<template>
  <div
    class="w-60 flex flex-col bg-white"
    style="box-shadow: 0px 2px 6px #9ca3af47; border-radius: 5px"
  >
    <span class="text-gray-600 font-semibold text-sm text-center p-4">
      {{ $t('MONITOR_STATISTIC_SCORE_TODAY') }}
    </span>
    <div class="flex-1 flex flex-col justify-between py-4">
      <div class="px-8 pt-2">
        <AtomScore :percent="scoreTodayCapacity.score" />
      </div>
      <div class="flex-center flex-col">
        <span class="text-3xl font-semibold text-current-400">
          {{ scoreTodayCapacity.score }}%
        </span>
        <span class="uppercase text-gray-400 font-semibold">
          {{ scoreTodayCapacity.label }}
        </span>
      </div>
    </div>
  </div>
</template>
