import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import getUserInfo from '@/ui/helpers/user-helper';
import { rankDomainData } from '@/ui/common/molecules/SynAvatar/ranking-constants';
import { translate } from '@/ui/plugins/i18n/myi18n';

export default defineStore({
    id: storeId.dashboardFinishedTask,
    state: () => ({
        isLoading: true,
        _taskService: TaskService.getInstance(),
        finishedTasks: [] as any,
        filterFinishedTasks: [] as any,
        finishedTaskRankingByMember: {} as any,
        finishedTaskRankingByDomain: [] as any,
        finishedTaskOverview: [] as any,
        maxFinishedTask: 0,
        todayInfo: {} as any,
        userId: [] as any,
        groupId: [] as any,
        domainId: [] as any,
    }),
    getters: {},
    actions: {
        async getFinishedTasks() {
            const res: any = await this._taskService.getFinishedTasks();

            if (!res?.result || res.result?.length == 0) return;
            const arrayUser = res.result.map((o) => ({
                id: o.assigneeId,
            }));
            this.userId = arrayUser.filter(
                (a, i) => arrayUser.findIndex((s) => a.id === s.id) === i
            );

            const arrayGroup = res.result.map((o) => ({
                id: o.groupId,
            }));
            this.groupId = arrayGroup.filter(
                (a, i) => arrayGroup.findIndex((s) => a.id === s.id) === i
            );

            const arrayDomain = res.result.map((o) => ({
                id: o.domainId,
            }));
            this.domainId = arrayDomain.filter(
                (a, i) => arrayDomain.findIndex((s) => a.id === s.id) === i
            );

            this.finishedTasks = res.result.map((o) => {
                return {
                    ...o,
                    urgency: getUrgencyValue(o?.priority, o?.important),
                    finishedTime:
                        new Date(o?.finishedTime).getTime() -
                        new Date(o?.finishedTime).getTimezoneOffset() *
                            60 *
                            1000,
                };
            });
            this.filterFinishedTasks = this.finishedTasks;
        },
        async getFinishedTaskRankingByMember() {
            const res: any =
                await this._taskService.getFinishedTaskRankingByMember();
            const dataList = res.result?.slice(0, 3);

            if (!dataList || dataList.length == 0) return;

            this.finishedTaskRankingByMember = dataList.reduce(
                (old, current, index) => {
                    return {
                        ...old,
                        [index + 1]: {
                            ...current,
                            ...getUserInfo(current?.assigneeId),
                        },
                    };
                },
                {}
            );
        },
        async getFinishedTaskRankingByDomain() {
            const res: any =
                await this._taskService.getFinishedTaskRankingByDomain();
            const dataList = res.result?.slice(0, 4);

            if (!dataList || dataList.length == 0) return;
            let otherData: any = {};
            if (res.result?.length > dataList.length) {
                const otherDataList = res.result?.slice(4, res.result?.length);
                otherData =
                    otherDataList?.length > 1
                        ? otherDataList.reduce(
                              (old, current) => {
                                  const numberOfFinishedTask =
                                      old?.data + current?.numberOfTaskFinished;
                                  return {
                                      ...current,
                                      name: 'Other',
                                      data: numberOfFinishedTask,
                                      color: rankDomainData[5]?.color,
                                  };
                              },
                              { data: 0 }
                          )
                        : {
                              ...otherDataList[0],
                              name: otherDataList[0]?.domainName,
                              data: otherDataList[0]?.numberOfTaskFinished,
                              color: rankDomainData[5]?.color,
                          };
            }

            this.finishedTaskRankingByDomain = dataList?.map((o, index) => {
                return {
                    ...o,
                    name: o?.domainName,
                    data: o?.numberOfTaskFinished,
                    color: rankDomainData[index + 1]?.color,
                };
            });

            if (otherData?.data)
                this.finishedTaskRankingByDomain.push(otherData);
        },
        async getFinishedTaskOverview() {
            const res: any = await this._taskService.getFinishedTaskOverview();

            const data = res?.result;
            if (!data || data.length == 0) return;
            const toFinishList = data.map((o) => o.numberOfTaskToFinish);
            const finishedList = data.map((o) => o.numberOfTaskFinished);
            this.maxFinishedTask = Math.max(
                ...[...toFinishList, ...finishedList]
            );

            this.finishedTaskOverview = [
                {
                    label:
                        translate('OVERVIEW_FINISHED_TASKS_FINISHED_TODAY') ||
                        'Finished today',
                    data: toFinishList,
                    backgroundColor: '#FF8800',
                    hoverBackgroundColor: '#666',
                    minBarLength: 2,
                },
                {
                    label:
                        translate('COMMON_LABEL_STATUS_FINISHED') || 'Finished',
                    data: finishedList,
                    backgroundColor: '#4BB0B2',
                    hoverBackgroundColor: '#008d8d',
                    minBarLength: 2,
                },
            ];

            const finishedToday = data.filter((o) => {
                const today = new Date();
                const checkDay = new Date(o.date);
                return (
                    checkDay.getFullYear() === today.getFullYear() &&
                    checkDay.getMonth() === today.getMonth() &&
                    checkDay.getDate() === today.getDate()
                );
            });
            this.todayInfo = finishedToday?.length > 0 ? finishedToday[0] : {};
        },

        async getAllDataForFinishedTaskPage() {
            this.isLoading = true;
            await Promise.all([
                this.getFinishedTasks(),
                this.getFinishedTaskRankingByMember(),
                this.getFinishedTaskRankingByDomain(),
                this.getFinishedTaskOverview(),
            ]);
            this.isLoading = false;
        },

        updateFilterFinishedTasks(arr) {
            this.filterFinishedTasks = arr;
        },
    },
});
