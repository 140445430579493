import { createApp, h } from 'vue';
import chatItemMention from '@/ui/modules/messaging/chat-panel/chat-form/chat-input/chat-item-mention/chatItemMention.vue';

export function renderItemListMentionChat(user) {
    const appIcon = createApp({
        render() {
            return h(chatItemMention, { user: user }, [h(chatItemMention, {})]);
        },
    });

    let tmpEl = document.createElement('div');
    const mountedApp = appIcon.mount(tmpEl);
    let el = document.createElement('div');
    el.innerHTML = mountedApp.$el.outerHTML;
    return el;
}
