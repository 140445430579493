<script setup lang="ts">
import { computed } from 'vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import taskDrawerStore from '@/store/task/drawer';

const props = defineProps<{
  activity: any;
}>();

const afterUpdate = computed<any>(() => {
  return covertSnakeCaseToCamelCase(props.activity?.afterUpdate);
});

const onOpenParentTask = () => {
  taskDrawerStore().pushCurrentIds({
    id: afterUpdate.value?.parentTaskId,
    code: afterUpdate.value?.parentTaskCode,
    name: afterUpdate.value?.parentTaskName,
    tabType: 'DETAIL',
  });
};
</script>
<template>
  <span>
    <span v-html="$t('ACTIVITY_HISTORY_SUB_TASK_ACTION_CREATED_FROM')"> </span
    >&nbsp;
    <span
      class="text-gray-500 underline cursor-pointer"
      @click="onOpenParentTask"
    >
      {{ formatTaskCode(afterUpdate?.parentTaskCode) }}
      -
      {{ afterUpdate?.parentTaskName }}
    </span>
  </span>
</template>
