<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  ICommonInitComponent,
  ECommonInitComponentStatus,
} from '@/application/types/common/common.types';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  initComponent?: Function;
}>();

const commonInitComponentData = ref<ICommonInitComponent>({
  status: ECommonInitComponentStatus.LOADING,
  errorCode: undefined,
  errorMessage: undefined,
});

const errorMessage = computed<string>(() => {
  if (
    commonInitComponentData.value?.status !== ECommonInitComponentStatus.ERROR
  )
    return '';

  if (
    commonInitComponentData.value?.errorCode &&
    translate(
      `BASE_REQUEST_ERROR_BY_CODE_${commonInitComponentData.value?.errorCode}`
    )
  )
    return translate(
      `BASE_REQUEST_ERROR_BY_CODE_${commonInitComponentData.value?.errorCode}`
    );
  if (commonInitComponentData.value?.errorMessage)
    return commonInitComponentData.value?.errorMessage;
  return translate('COMMON_LABEL_ALERT_NO_SEARCH');
});

const onInitComponent = async () => {
  if (!props.initComponent || typeof props.initComponent !== 'function') {
    commonInitComponentData.value.status = ECommonInitComponentStatus.SUCCESS;
    return;
  }
  try {
    await props.initComponent();
    commonInitComponentData.value.status = ECommonInitComponentStatus.SUCCESS;
  } catch (error: any) {
    const errorData = error?.error || error;

    commonInitComponentData.value = {
      status: ECommonInitComponentStatus.ERROR,
      errorCode: errorData?.code,
      errorMessage: errorData?.message,
    };
  }
};

onInitComponent();
</script>
<template>
  <slot
    v-if="commonInitComponentData.status == ECommonInitComponentStatus.LOADING"
    name="loading"
  >
    <section class="flex animate-pulse justify-center flex-wrap space-y-6">
      <div
        class="relative w-full flex justify-center h-32 rounded-t-md"
        :style="{
          'background-color': `#ffff`,
          'background-size': 'cover',
        }"
      >
        <div
          class="
            absolute
            top-10
            z-10
            w-32
            h-32
            bg-gray-200
            border-4 border-white
            rounded-full
          "
        ></div>
      </div>
      <div class="w-3/4 space-y-6 flex flex-wrap">
        <div
          class="font-medium w-full mt-8 px-6 py-2 rounded-lg bg-gray-200"
        ></div>
        <div class="w-full h-32 px-6 py-2 rounded-lg bg-gray-200"></div>
      </div>
    </section>
  </slot>
  <div
    v-else-if="
      commonInitComponentData.status == ECommonInitComponentStatus.ERROR
    "
    class="w-full h-full"
  >
    <SynAnimation
      name="something-wrong"
      stype="width: 50%; height:50%; min-width: 200px; min-height: 200px;"
      :label="errorMessage"
    />
  </div>
  <slot v-else />
</template>
