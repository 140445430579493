<script setup lang="ts">
defineProps({
  modelValue: {
    type: String || Number || Boolean,
    required: true,
    default: false,
  },
  leftKey: {
    type: String || Number || Boolean,
    default: '1',
  },
  rightKey: {
    type: String || Number || Boolean,
    default: '2',
  },
});

const emit = defineEmits(['update:modelValue', 'change']);

const onChange = (value) => {
  emit('update:modelValue', value);
  emit('change', value);
};
</script>

<template>
  <div class="p-1 rounded bg-gray-300 flex-center">
    <div
      class="px-1 flex-center font-semibold rounded cursor-pointer"
      :class="[
        modelValue == leftKey ? 'bg-current text-white' : 'text-gray-800',
      ]"
      @click="onChange(leftKey)"
    >
      <slot name="left"></slot>
    </div>
    <div
      class="px-1 flex-center font-semibold rounded cursor-pointer"
      :class="[
        modelValue == rightKey ? 'bg-current text-white' : 'text-gray-800',
      ]"
      @click="onChange(rightKey)"
    >
      <slot name="right"></slot>
    </div>
  </div>
</template>
