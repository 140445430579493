import { ref } from 'vue';
export const isShowTaskDetailGlobal = ref(false);
export const taskCodeGlobal = ref(null);
export const isOpenTaskDrawer = ref(false);
export const allowedExtensionString =
    '.txt,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.gif,.bmp,.mp3,.zip,.rar';

// Task detail
export const isEdittingComment = ref(false);
export const isEdittingName = ref(false);
