<template>
  <div
    role="button"
    class="relative text-center group p-1 rounded"
    :class="isSelected ? 'bg-current-50' : 'hover:bg-gray-100'"
    @click="$emit('onFileClick')"
  >
    <a :href="fileUrl" @click.prevent>
      <FilePreview
        :file="file"
        class="h-28 p-1 flex items-end justify-center"
      />
    </a>
    <div v-if="file?.link && showLink" :title="file?.link">
      <a
        :href="fileUrl"
        class="text-sm text-current-500 text-center text-overflow-hidden-line"
        @click.prevent
      >
        {{ file?.link }}</a
      >
    </div>
    <a
      v-if="!isRenaming"
      :href="fileUrl"
      :title="file?.name"
      class="
        px-2
        pt-1
        pb-2
        text-xs
        break-words
        overflow-hidden
        text-ellipsis
        block
      "
      style="max-height: 5.5rem"
      @click.prevent
    >
      {{ file?.name }}
    </a>
    <div v-else @click.stop>
      <FileRename
        :file="file"
        input-class="text-sm leading-4"
        @on-cancel="$emit('onRenameCancel')"
        @on-rename="$emit('onRenameConfirm', $event)"
      />
    </div>

    <div
      v-if="showCheckbox"
      class="absolute top-0 left-0 group-hover:flex w-9 h-9 flex-center"
      :class="isSelectionMode ? 'flex' : 'hidden'"
      @click.stop="$emit('onCheckboxClick')"
    >
      <input
        type="checkbox"
        class="vig-checkbox w-5 h-5"
        :checked="isSelected"
      />
    </div>

    <div
      v-if="showActionsButton"
      class="absolute top-0 right-0 hidden group-hover:block"
      @click.stop
    >
      <FileActions
        :file="file"
        @on-view-info-click="$emit('onViewInfoClick')"
        @on-rename-click="$emit('onRenameClick')"
        @on-move-to-click="$emit('onMoveToClick')"
        @on-move-to-trash-click="$emit('onMoveToTrashClick')"
        @on-delete-permanently-click="$emit('onDeletePermanentlyClick')"
        @on-restore-click="$emit('onRestoreClick')"
        @on-copy-link-click="$emit('onCopyLinkClick')"
        @on-notification-setting-click="$emit('onNotificationSettingClick')"
        @on-download-click="$emit('onDownloadClick')"
        @on-save-to-resource="$emit('onSaveToResourceClick')"
      />
    </div>

    <div
      v-if="showGoToButton"
      class="absolute top-0 right-0 p-1 hidden group-hover:block"
      @click.stop="$emit('onGoToClick')"
    >
      <vig-button
        outline
        color="gray"
        padding="p-0"
        rounded="rounded-full"
        class="bg-white w-9 h-9"
      >
        <SynIcon name="Back" class="transform rotate-180" />
      </vig-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue';
import FileRename from '../../_commons/file-rename/FileRename.vue';
import FileActions from '@/ui/modules/ged/file-actions/FileActions.vue';
import useFileItem from '@/ui/composables/resource/file-item-composables';
import FilePreview from '@/ui/modules/ged/_commons/file-preview/FilePreview.vue';

export default defineComponent({
  components: { FilePreview, FileActions, FileRename },
  props: {
    file: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isSelectionMode: {
      type: Boolean,
      default: false,
    },
    isRenaming: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    showActionsButton: {
      type: Boolean,
      default: true,
    },
    showGoToButton: {
      type: Boolean,
      default: false,
    },
    showLockFile: {
      type: Boolean,
      default: true,
    },
    showLink: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'onFileClick',
    'onCheckboxClick',
    'onViewInfoClick',
    'onRenameClick',
    'onRenameConfirm',
    'onRenameCancel',
    'onMoveToClick',
    'onCopyLinkClick',
    'onMoveToTrashClick',
    'onDeletePermanentlyClick',
    'onRestoreClick',
    'onGoToClick',
    'onNotificationSettingClick',
    'onDownloadClick',
    'onSaveToResourceClick',
  ],
  setup(props) {
    const { file } = toRefs(props);
    const { fileUrl } = useFileItem(file);

    return {
      fileUrl,
    };
  },
});
</script>
