import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import $constants from '@/ui/plugins/constants';

export const genBucketNameByModule = (
    module: ModuleSourceFile
): BucketObject => {
    if (module == 'CALENDAR_ATTACHMENT')
        return {
            PHOTO: bucketTictop.CalendarAttachmentPhoto,
            MEDIA: bucketTictop.CalendarAttachmentMedia,
            FILE: bucketTictop.CalendarAttachmentFile,
        };
    if (module == 'TASK_ATTACHMENT')
        return {
            PHOTO: bucketTictop.AttachmentPhoto,
            MEDIA: bucketTictop.AttachmentMedia,
            FILE: bucketTictop.AttachmentFile,
        };
    if (module == 'messaging')
        return {
            PHOTO: bucketTictop.MessagingPhoto,
            MEDIA: bucketTictop.MessagingMedia,
            FILE: bucketTictop.MessagingFile,
        };

    return {
        PHOTO: bucketTictop.StoragePhoto,
        MEDIA: bucketTictop.StorageMedia,
        FILE: bucketTictop.StorageFile,
    };
};

type BucketName = string;
export interface BucketObject {
    PHOTO: BucketName;
    MEDIA: BucketName;
    FILE: BucketName;
}

export type ModuleSourceFile =
    | 'CALENDAR_ATTACHMENT'
    | 'messaging'
    | 'source'
    | 'TASK_ATTACHMENT'
    | 'user';

export const getKindOfFileByType = (contentType): KindOfFile => {
    if (contentType?.startsWith($constants.FILE.TYPE.IMAGE)) return 'PHOTO';

    if (contentType?.startsWith($constants.FILE.TYPE.AUDIO)) return 'MEDIA';

    if (contentType?.startsWith($constants.FILE.TYPE.VIDEO)) return 'MEDIA';

    return 'FILE';
};

export type KindOfFile = 'PHOTO' | 'MEDIA' | 'FILE';

export enum ModuleSourceFileEnum {
    calendar = 'CALENDAR_ATTACHMENT',
    messaging = 'messaging',
    source = 'source',
    task = 'TASK_ATTACHMENT',
    user = 'user',
}

export const getModuleSource = (file) => {
    if (file?.sourceFrom == 'CHAT') return ModuleSourceFileEnum.messaging;

    return ModuleSourceFileEnum.task;
};
