import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DomainService from '@/application/services/DomainService';
import domainStore from '@/store/scope';
import dayjs from 'dayjs';
import {
    ScheduleDayOptions,
    ScheduleOtherTimeOptions,
    TaskActionType,
} from '@/domain/enums/taskEnum';
import TaskService from '@/application/services/task/TaskService';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';

export default defineStore({
    id: storeId.OVERVIEW_DOMAINS,
    state: () => ({
        _domainService: DomainService.getInstance(),
        domainTasksByIds: {} as any,
        overviewDomainIds: [] as any[],
        overviewDomainFullDetail: [] as any,
        isHiddenTask: false,
        isLoadingDomainTask: false,
        keySearchDomain: null,
        overviewDomainFullTasks: {},
        isLoadingOverviewDomain: false,
        isCreateDomain: false,
        viewMode: 'BOARD',
    }),
    getters: {},
    actions: {
        setKeySearchGroup(keySearch) {
            this.keySearchDomain = keySearch;
        },

        setValueCreateDomain(isCreate) {
            this.isCreateDomain = isCreate;
        },
        setOverviewDomainMode(mode: 'BOARD' | 'LIST') {
            this.viewMode = mode;
        },

        async getOverviewData() {
            this.overviewDomainIds = domainStore().domains?.map(
                (domain: any) => domain?.id
            );
            this.getOverviewDomainTask();
        },

        async getOverviewDomainTask() {
            try {
                this.isLoadingDomainTask = true;
                const res: any =
                    await this._domainService.getOverviewDomainTasks();
                const dataTasks = res?.result;
                this.overviewDomainIds.forEach((domainId) => {
                    if (!dataTasks[domainId]) return;
                    this.domainTasksByIds[domainId] = {
                        ...dataTasks[domainId],
                        domainTasks: dataTasks[domainId].domainTasks?.slice(
                            0,
                            4
                        ),
                    };
                });

                this.overviewDomainFullTasks = dataTasks;
            } catch (error) {
                // console.log(
                //   '🚀 ~ file: overview-group-store.ts ~ line 66 ~ getOverviewGroupTask ~ error',
                //   error
                // );
            } finally {
                this.isLoadingDomainTask = false;
            }
        },

        setOverviewTeamTaskMore(domainId, tasks) {
            const currentData = this.domainTasksByIds[domainId].domainTasks;
            this.domainTasksByIds[domainId].taskListSeemore = tasks?.filter(
                (o) => !currentData || !currentData.some((p) => p.id == o.id)
            );

            this.domainTasksByIds[domainId].isShowMore = true;
        },

        setFullTaskForGroup(domainId) {
            if (
                !this.overviewDomainFullTasks[domainId] ||
                this.overviewDomainFullTasks[domainId].updated
            )
                return;

            this.domainTasksByIds[domainId].domainTasks =
                this.overviewDomainFullTasks[domainId].domainTasks;
            this.overviewDomainFullTasks[domainId].updated = true;
        },

        async getOverviewDomainFullDetail(isLoading = true) {
            if (isLoading) this.isLoadingOverviewDomain = true;
            try {
                const res =
                    await DomainService.getInstance().getOverviewDomainFullDetail();
                this.overviewDomainFullDetail = res['result']?.map(
                    (el: any) => {
                        return {
                            ...el,
                            startDate: el?.startDate
                                ? dayjs(el?.startDate).startOf('day')
                                : null,
                            endDate: el?.endDate
                                ? dayjs(el?.endDate).endOf('day')
                                : null,
                        };
                    }
                );
                this.isLoadingOverviewDomain = false;
                return res['result'];
            } catch (e) {
                this.overviewDomainFullDetail = [];
                this.isLoadingOverviewDomain = false;
                // console.log('e', e);
            }
        },
        async getCountTasksByDomain(isLoading = true, isIncludeSub) {
            if (isLoading) this.isLoadingOverviewDomain = true;
            try {
                const res =
                    await DomainService.getInstance().getCountTasksByDomain(
                        isIncludeSub
                    );

                if (res?.result?.length > 0) {
                    res?.result.forEach((item) => {
                        if (item?.domainId)
                            domainStore().updateStoreDataByDomainId(
                                item?.domainId,
                                { countActiveTasks: item?.countActiveTasks }
                            );
                        else if (item?.projectId || item?.subprojectId)
                            domainStore().updateStoreDataByProjectId(
                                item?.projectId || item?.subprojectId,
                                { countActiveTasks: item?.countActiveTasks }
                            );
                    });
                }
                return res['result'];
            } catch (e) {
                this.overviewDomainFullDetail = [];
                this.isLoadingOverviewDomain = false;
                // console.log('e', e);
            }
        },
        async updateDomainTasksRealtime(
            taskAction: TaskActionType,
            task: {
                id: number;
                assigneeId?: number;
                groupId?: number;
            }
        ) {
            if (!task) return;

            const { id: taskId } = task;
            // 2. Add or update
            const res =
                await TaskService.getInstance().getDetailTaskIncognitoById(
                    taskId
                );
            const taskDetail = res['result'];

            const updatedTask: ITaskDetailClass = {
                ...taskDetail,
                urgency: getUrgencyValue(
                    taskDetail?.priority,
                    taskDetail?.important
                ),
            };

            if (!updatedTask?.domainId) return;

            const currentDomainIndex = this.overviewDomainFullDetail?.findIndex(
                (d) => d?.id == updatedTask?.domainId
            );
            if (currentDomainIndex == -1) return;

            const isOverdueTask =
                updatedTask?.taskSchedule?.scheduleOptionKey ==
                    ScheduleDayOptions.OtherTime &&
                updatedTask?.taskSchedule.otherTime ==
                    ScheduleOtherTimeOptions.SpecificDate &&
                updatedTask?.taskSchedule.overdue &&
                updatedTask?.taskSchedule?.overdue > 0;

            switch (taskAction) {
                case TaskActionType.Delete:
                case TaskActionType.MoveOut:
                    this.overviewDomainFullDetail[
                        currentDomainIndex
                    ].activeTasks = Math.max(
                        this.overviewDomainFullDetail[currentDomainIndex]
                            .activeTasks - 1,
                        0
                    );
                    if (isOverdueTask)
                        this.overviewDomainFullDetail[
                            currentDomainIndex
                        ].overdueTasks = Math.max(
                            this.overviewDomainFullDetail[currentDomainIndex]
                                .overdueTasks - 1,
                            0
                        );

                    break;
                case TaskActionType.AddNew:
                case TaskActionType.MoveIn:
                    {
                        this.overviewDomainFullDetail[
                            currentDomainIndex
                        ].activeTasks += 1;
                        if (isOverdueTask)
                            this.overviewDomainFullDetail[
                                currentDomainIndex
                            ].overdueTasks += 1;
                    }
                    break;

                default:
                    break;
            }
        },
    },
});
