<script setup lang="ts">
import { computed, ref } from 'vue';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';
import UpdateCustomFilterTabModal from '@/ui/modules/task/tabs-list-task/create-new-tabs/UpdateCustomFilterTabModal.vue';

const props = withDefaults(
  defineProps<{
    isActive: boolean;
    color: string;
    labelKey?: string;
    name: string;
    total?: number;
    readonly?: boolean;
    listTaskOwnerId?: number;
    sourceType?: 'U' | 'G' | 'D';
    tabDetail: ICustomTaskFilter;
    uiType?: 'LI_ITEM' | 'TAG';
  }>(),
  {
    labelKey: '',
    sourceType: 'U',
    total: 0,
    listTaskOwnerId: 0,
    uiType: 'TAG',
  }
);
const emits = defineEmits([
  'onSelectTab',
  'onDeleteTab',
  'onChangeName',
  'onDeleteSuccess',
  'onUpdateSuccess',
]);

const mainColor = computed<string>(() => {
  return props.color || '#008E8E';
});

const currentTabDetail = ref<ICustomTaskFilter>({
  id: '',
  name: '',
  index: 0,
  color: '',
  visible: true,
  filterObject: {
    isDefault: false,
    listOwnerId: null,
    assignorId: null,
    urgency: null,
    keyword: null,
    groupIds: null,
    domainId: null,
    domainIds: null,
    creatorIds: null,
    assigneeIds: null,
    createdFromDate: null,
    createdToDate: null,
    updatedFromDate: null,
    updatedToDate: null,
    status: null,
  },
});

const currentMode = ref<'EDIT' | 'VIEW' | 'REMOVE'>('VIEW');

const onChangeMode = (mode: 'EDIT' | 'VIEW' | 'REMOVE') => {
  currentMode.value = mode;
};

const onClose = () => {
  currentMode.value = 'VIEW';
};
const onUpdateSuccess = () => {
  emits('onUpdateSuccess');
};
const onOpenTagDetail = () => {
  currentTabDetail.value = props.tabDetail;
};
</script>

<template>
  <div
    v-bind="$attrs"
    class="container-tabs relative cursor-pointer flex items-center space-x-1"
    :style="
      isActive ? `background-color: ${mainColor}20; color: ${mainColor}` : ``
    "
    :class="[
      isActive ? 'font-medium' : '',
      total && total > 0 ? 'pr-1 pl-2 py-1.5' : 'px-2 py-1.5',
      uiType == 'LI_ITEM'
        ? 'rounded w-full'
        : 'rounded-full text-gray-600 bg-gray-100 hover:bg-gray-200',
    ]"
    @click="$emit('onSelectTab')"
  >
    <slot name="prefix" />
    <span
      class="truncate"
      :class="!readonly ? '' : ''"
      :style="!readonly ? `border-color: ${mainColor}` : ''"
      style="max-width: 8rem"
    >
      {{ name }}
    </span>

    <span
      v-if="total && total > 0"
      class="px-1 rounded-full text-xs"
      style="font-size: 10.5px"
      :style="
        isActive
          ? `background-color: ${mainColor || '#008E8E'}; color: white;`
          : ''
      "
      :class="isActive ? '' : 'text-white bg-gray-400'"
    >
      {{ total }}
    </span>
    <div
      v-if="!readonly"
      class="
        absolute
        right-0
        w-6
        h-6
        flex-center
        group
        cursor-pointer
        rounded-full
        bg-white
      "
      :class="{
        tabs_close: currentMode == 'VIEW',
      }"
      @click.stop="[onChangeMode('EDIT'), onOpenTagDetail()]"
    >
      <SynIcon name="edit" custom-class="w-3 h-3 fill-blue-500 " />
    </div>
  </div>

  <UpdateCustomFilterTabModal
    v-if="currentMode == 'EDIT'"
    :current-tab-detail="currentTabDetail"
    :list-task-owner-id="listTaskOwnerId"
    :source-type="sourceType || 'U'"
    @change-mode="onChangeMode"
    @on-close="onClose"
    @on-delete-success="$emit('onDeleteSuccess')"
    @on-update-success="onUpdateSuccess"
  />
</template>

<style scoped>
.container-tabs {
}

.tabs_close {
  display: none;
  /* visibility: hidden;*/
}

.container-tabs:hover .tabs_close {
  display: flex;
  /* visibility: hidden;*/
}
</style>
