<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 96 96"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M19.667 56.213h12.286v4.46H19.667zM19.667 30.106h12.286v4.46H19.667zM19.667 43.159h12.286v4.46H19.667zM40.552 56.213h12.286v4.46H40.552zM19.667 69.266h12.286v4.46H19.667zM40.552 69.266h12.286v4.46H40.552zM40.552 30.106h12.286v4.46H40.552zM40.552 43.159h12.286v4.46H40.552z"
        data-original="#000000"
        class=""
      />
      <path
        d="M65.891 4H29.823C15.585 4 4.003 15.585 4.003 29.818V92h87.994V32.717H65.891zm-4.454 83.54H8.457V29.818c0-11.78 9.586-21.358 21.366-21.358h31.613v79.08zm10.442-41.77h9.676v4.46h-9.676zm0 13.053h9.676v4.46h-9.676zm0 13.053h9.676v4.46h-9.676z"
        data-original="#000000"
        class=""
      />
    </g>
  </svg>
</template>
