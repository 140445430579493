<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import SelectOrganizationModel from '@/ui/modules/organization/setup/SelectOrganizationModel.vue';
import organizationStore from '@/store/organization';
import myProfileStore from '@/store/auth/my-profile';

defineProps<{
  isHiddenPersonalModel?: boolean;
  selectedModel?: any;
  isUpgradeCurrentOrg?: boolean;
}>();
const emit = defineEmits(['onCancel', 'onFinish']);
const router = useRouter();

const _organizationStore = organizationStore();

const setupOrgRef = ref(null as any);
const onFinishSetup = (newOrg) => {
  emit('onFinish', newOrg);
};
const isCreateFetching = ref(false);
const onSubmit = async ({
  data,
  isUpgradeCurrentOrganization,
}: {
  mode: number;
  data: any;
}) => {
  isCreateFetching.value = true;

  if (isUpgradeCurrentOrganization) {
    await _organizationStore.updateAndFinishSetupOrganization(data);
    myProfileStore().fetchCurrentLoginUser();

    router.push('/');
  } else {
    const newOrg = await _organizationStore.registerNewOrganization({
      organizationInfo: data,
      positions: [],
      taskTypes: [],
      domains: [],
    });
    isCreateFetching.value = false;
    onFinishSetup(newOrg);
  }
};
</script>

<template>
  <teleport to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
        z-50
      "
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div class="rounded-md relative">
        <SelectOrganizationModel
          ref="setupOrgRef"
          :is-hidden-personal-model="isHiddenPersonalModel"
          :is-submitting="isCreateFetching"
          :selected-model="selectedModel"
          :is-upgrade-current-org="isUpgradeCurrentOrg"
          @on-cancel="$emit('onCancel')"
          @on-submit="onSubmit"
        />
        <button
          :title="$t('COMMON_LABEL_CANCEL')"
          class="
            w-8
            h-8
            bg-gray-200
            rounded-full
            cursor-pointer
            hover:text-current-500 hover:bg-gray-100
            absolute
            -top-3
            -right-3
            flex-center
            vig-modal-close
            do-not-close-dropdown
            z-50
          "
          @click="$emit('onCancel')"
        >
          <SynIcon name="close" custom-class="w-4 h-4 do-not-close-dropdown" />
        </button>
      </div>
    </div>
  </teleport>
</template>
