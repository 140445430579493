<script setup lang="ts">
import { computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { onToggleSettingPage } from '@/ui/hooks/useSidebar';
import PersonalSidebarItem from '@/ui/components/layouts/sidebar/PersonalSidebarItem.vue';
import settingStore from '@/store/setting';
import appStore from '@/store/app';
import HelpSupportIcon from '@/ui/modules/contact-help/helps/HelpSupportIcon.vue';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import remoteConfigStore from '@/store/remoteConfig';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import { EScreenPermission } from '@/application/types/block-function.types';
import systemConfig from '@/application/constants/system-config.const';
import permissionStore from '@/store/permission';

const permission: any = inject('permission');
const route = useRoute();
const router = useRouter();
const _settingStore = settingStore();
const _appStore = appStore();
const _remoteConfigStore = remoteConfigStore();
const _permissionStore = permissionStore();

const location = getLocalStorage(StorageConstant.LOCATION);
const webShowHelpFunction = computed(
  () => _remoteConfigStore.webShowHelpFunction
);
const isShowHelpButton = computed(
  () =>
    webShowHelpFunction.value &&
    location !== 'EU' &&
    _permissionStore.allSystemConfigs[
      systemConfig.DRAFT_SYSTEM_HELP_BUTTON_ALLOWED
    ] === '1'
);

const currentRoute = computed(() => route.name);
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
const isActive = (name, path) => {
  if (route.path?.includes(path)) return true;
  return currentRoute.value == name;
};
// todo: should get from router.js
const timeWorkingEnabled = computed(() => _settingStore.timeWorkingEnabled);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const permissionScreen: Function | undefined = inject('permissionScreen');
const routerList = computed(() => {
  let result = [
    {
      path: 'user-management',
      name: 'UserManagement',
      meta: {
        titleKey: 'COMMON_MODULE_USER_MANAGEMENT',
        title: 'UserManagement',
        layout: 'dashboard',
        iconName: 'user-management',
      },
      screenKey: ALL_SCREENS.COMMON.ADMIN.MEMBER,
    },
    {
      path: 'project-management',
      name: 'ProjectManagement',
      meta: {
        titleKey: 'COMMON_MODULE_PROJECT_MANAGEMENT',
        title: 'ProjectManagement',
        layout: 'dashboard',
        iconName: 'project',
      },
      screenKey: ALL_SCREENS.COMMON.ADMIN.DOMAIN,
    },
    {
      path: 'group-management',
      name: 'GroupManagement',
      meta: {
        titleKey: 'COMMON_MODULE_GROUP_MANAGEMENT',
        title: 'GroupManagement',
        layout: 'dashboard',
        iconName: 'groups',
      },
      screenKey: ALL_SCREENS.COMMON.ADMIN.GROUP,
    },
    // {
    //   path: 'dayoff',
    //   name: 'Dayoff',
    //   meta: {
    //     titleKey: 'COMMON_MODULE_DAYOFF',
    //     title: 'Dayoff',
    //     layout: 'dashboard',
    //     iconName: 'dayoff',
    //   },
    // },
    {
      path: 'tasks-management',
      name: 'TasksManagement',
      meta: {
        titleKey: 'COMMON_MODULE_TASK',
        title: 'Task management',
        layout: 'dashboard',
        iconName: 'note',
      },
      screenKey: ALL_SCREENS.COMMON.ADMIN.MEMBER,
    },
    {
      path: 'settings',
      name: 'Settings',
      meta: {
        titleKey: 'COMMON_LABEL_SETTINGS',
        title: 'Settings',
        layout: 'dashboard',
        iconName: 'settings',
      },
    },
  ];
  if (timeWorkingEnabled.value) {
    result.splice(-1, 0, {
      path: 'working-time',
      name: 'WorkingTime',
      meta: {
        titleKey: 'COMMON_MODULE_DASHBOARD',
        title: 'Dashboard',
        layout: 'dashboard',
        iconName: 'dashboard',
      },
      screenKey: ALL_SCREENS.COMMON.ADMIN.WORKING_TIME,
    });
  }
  if (departmentManagementEnabled.value) {
    result.splice(1, 0, {
      path: 'departments-management',
      name: 'DepartmentsManagement',
      meta: {
        titleKey: 'COMMON_MODULE_MANAGEMENT_DEPARTMENT',
        title: 'Team/Department',
        layout: 'dashboard',
        iconName: 'department',
      },
      screenKey: ALL_SCREENS.COMMON.ADMIN.DEPARTMENT,
    });
  }

  return result
    ?.filter((o) => permission(o.name))
    ?.filter((o) => {
      if (!o.screenKey) return true;
      if (!permissionScreen) return false;
      return (
        permissionScreen(o.screenKey) !== EScreenPermission.HIDDEN &&
        permissionScreen(o.screenKey) !== EScreenPermission.NOT_SET
      );
    });
});
const handleClickNav = (item) => {
  item.isShowChild = !item.isShowChild;
  if (item.children && item.children.length > 0) {
    return;
  }
  router.push({ name: item.name });
};
const getMenuLinkUrl = (routePath) => {
  return `/${routePath}`;
};
</script>

<template>
  <div
    class="
      flex flex-col
      h-full
      w-full
      transition
      duration-300
      transform
      bg-current-800
      rounded-br-2xl
    "
  >
    <div
      class="
        m-2
        rounded-md
        border border-yellow-400 border-opacity-50
        text-yellow-400
        flex
        items-center
        space-x-4
        cursor-pointer
        hover:bg-yellow-400 hover:bg-opacity-10
        fill-yellow-500
      "
      :class="isOpenMenuBar ? 'pl-4 py-3 pr-2' : 'px-2 py-2 justify-center'"
      @click="onToggleSettingPage(false, $router, $route)"
    >
      <SynIcon name="return" custom-class="h-6 w-6" />
      <span v-if="isOpenMenuBar" class="text-sm font-medium">
        {{ $t('COMMON_MODULE_OVERVIEW') || 'Home' }}
      </span>
    </div>

    <nav class="flex-1 h-full w-full">
      <div
        class="
          h-full
          flex flex-col
          justify-between
          overflow-y-auto
          small-scrollbar
          relative
        "
      >
        <div class="flex-1 min-h-0 w-full relative">
          <div class="max-h-full w-full flex flex-col">
            <div
              v-for="item in routerList"
              :key="item.name"
              class="py-0.5 px-2"
            >
              <PersonalSidebarItem
                v-vig-tooltip="$t(item.meta?.titleKey) || item.meta?.title"
                :icon-name="item.meta.iconName"
                :is-active="isActive(item.name, item.path)"
                :item-name="$t(item.meta?.titleKey) || item.meta?.title"
                :link-to="getMenuLinkUrl(item.path)"
                :is-hidden-text="!isOpenMenuBar"
                class="text-white text-opacity-50 font-normal"
                active-class="text-white text-opacity-100 fill-yellow-500 bg-current-50 bg-opacity-20"
                inactive-class="hover:bg-current-50 hover:bg-opacity-20 hover:text-white fill-yellow-500"
                bg-icon="bg-current-50 bg-opacity-10"
                @click="handleClickNav(item)"
              />
            </div>
          </div>
        </div>

        <div v-if="isShowHelpButton" class="flex-center py-4">
          <HelpSupportIcon
            :is-label="isOpenMenuBar"
            class-active="text-white bg-current-50 bg-opacity-20"
            :custom-class="`text-white text-opacity-50 font-medium hover:bg-current-50 fill-yellow-500 hover:bg-opacity-20 hover:text-white ${
              isOpenMenuBar ? 'mx-3 px-2 py-3 ' : 'p-1'
            }`"
          />
        </div>
      </div>
    </nav>
  </div>
</template>
