<script setup lang="ts">
import { ref } from 'vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import TaskSetApproval from '@/ui/modules/task/approval/TaskSetApproval.vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';

defineProps<{
  task: ITaskDetailClass;
}>();
const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onSave', approverIds: number[]): void;
}>();
const approver = ref({} as any);

const onSave = () => {
  emit('onSave', [approver.value?.id]);
};
</script>

<template>
  <SynModal
    container-class="w-11/12 max-w-lg"
    container-style=""
    style-body="px-4 pb-4"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    :is-disable-button="!approver?.id"
    @cancel="$emit('onClose')"
    @confirm="onSave"
  >
    <template #header>
      <div class="flex gap-2 items-center">
        <span>
          {{
            $t('TASK_LABEL_SELECT_APPROVER', {
              code: formatTaskCode(task?.code),
            })
          }}
        </span>
      </div>
    </template>

    <template #body>
      <TaskSetApproval
        :task="task"
        @update:approver="(data) => (approver = data)"
      />
    </template>
  </SynModal>
</template>
