<template>
  <svg
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <rect id="Frame 1" />
    <g>
      <g>
        <path
          id="path"
          d="M256 0C222.052 0 189.397 6.49561 158.033 19.4868C126.669 32.4781 98.9853 50.976 74.9807 74.9807C50.976 98.9854 32.4781 126.669 19.4868 158.033C6.49561 189.397 0 222.052 0 256C0 289.948 6.49561 322.603 19.4868 353.967C32.4781 385.331 50.976 413.015 74.9807 437.019C98.9853 461.024 126.669 479.522 158.033 492.513C189.397 505.504 222.052 512 256 512C289.948 512 322.603 505.504 353.967 492.513C385.331 479.522 413.015 461.024 437.019 437.019C461.024 413.015 479.522 385.331 492.513 353.967C505.504 322.603 512 289.948 512 256C512 222.052 505.504 189.397 492.513 158.033C479.522 126.669 461.024 98.9854 437.019 74.9807C413.015 50.976 385.331 32.4781 353.967 19.4869C322.603 6.49561 289.948 0 256 0ZM256 477.867C226.579 477.867 198.277 472.237 171.095 460.978C143.914 449.719 119.921 433.687 99.1166 412.883C78.3125 392.079 62.2809 368.086 51.0219 340.905C39.7629 313.723 34.1333 285.421 34.1333 256C34.1333 226.579 39.7629 198.277 51.0219 171.095C62.2809 143.914 78.3125 119.921 99.1166 99.1166C119.921 78.3126 143.914 62.2811 171.095 51.022C198.277 39.7629 226.579 34.1334 256 34.1334C285.421 34.1334 313.723 39.7629 340.905 51.022C368.086 62.281 392.079 78.3126 412.883 99.1166C433.687 119.921 449.719 143.914 460.978 171.095C472.237 198.277 477.867 226.579 477.867 256C477.867 285.421 472.237 313.723 460.978 340.905C449.719 368.087 433.688 392.079 412.883 412.883C392.079 433.687 368.087 449.719 340.905 460.978C313.723 472.237 285.421 477.867 256 477.867Z"
          fill-rule="nonzero"
        />
        <path
          id="path"
          d="M388.32 168.96C380.8 161.485 373.28 161.485 365.76 168.96L217.12 317.6C215.147 319.456 213.173 319.456 211.2 317.6L146.24 252.64C138.72 245.165 131.2 245.165 123.68 252.64C116.205 260.16 116.205 267.68 123.68 275.2L211.2 362.72C213.173 364.576 215.147 364.576 217.12 362.72L388.32 192C395.795 184.48 395.795 176.48 388.32 168.96Z"
          fill-rule="nonzero"
        />
      </g>
    </g>
  </svg>
</template>
