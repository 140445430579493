<script setup lang="ts">
import DayoffStatusAtom from '@/ui/components/dayoff/atoms/DayoffStatusAtom.vue';
import DayoffDateSessionAtom from '@/ui/components/dayoff/atoms/DayoffDateSessionAtom.vue';
import {
  DayoffType,
  DayoffExceptionType,
  DayoffStatus,
} from '@/domain/enums/DayoffEnums';
import DayoffExceptionTypeAtom from '@/ui/components/dayoff/atoms/DayoffExceptionTypeAtom.vue';
import { IDayOffDetail } from '@/application/types/calendar/day-off.types';

defineProps<{
  dayOffDetail: IDayOffDetail;
  isHiddenRequestNote?: boolean;
}>();

const listStatusRequestShow = [
  DayoffStatus.ACCEPTED,
  DayoffStatus.CANCELLED,
  DayoffStatus.CLOSED,
  DayoffStatus.REJECTED,
];
</script>
<template>
  <div class="flex items-center space-x-2 text-xs text-gray-800">
    <!-- TIME -->
    <span class="min-w-max">
      <SynLabelDateTime
        format="date"
        ignore-timezone
        :datetime="dayOffDetail.startDate"
      />
    </span>
    <span
      v-if="dayOffDetail.startDate !== dayOffDetail.endDate"
      class="min-w-max"
    >
      -
      <SynLabelDateTime
        format="date"
        ignore-timezone
        :datetime="dayOffDetail.endDate"
      />
    </span>
    <span
      v-if="dayOffDetail?.requestDay > 1"
      class="text-current text-xs min-w-max"
    >
      ({{ dayOffDetail?.requestDay }}d)
    </span>
    <span
      v-else-if="dayOffDetail?.requestDay > 0"
      class="text-current text-xs min-w-max"
    >
      <span
        v-if="dayOffDetail.exception?.type === DayoffExceptionType.BREAK_TIME"
        class="min-w-max flex-center space-x-1"
      >
        (<SynLabelDateTime
          format="time"
          ignore-timezone
          :datetime="dayOffDetail.exception?.startTime"
        />
        -
        <SynLabelDateTime
          format="time"
          ignore-timezone
          :datetime="dayOffDetail.exception?.endTime"
        />)
      </span>
      <span
        v-else-if="
          dayOffDetail.exception?.type === DayoffExceptionType.START_LATE ||
          dayOffDetail.exception?.type === DayoffExceptionType.STOP_EARLY
        "
        class="min-w-max flex-center space-x-1"
      >
        (<SynLabelDateTime
          format="time"
          ignore-timezone
          :datetime="dayOffDetail.exception?.startTime"
        />
        )
      </span>
      <span v-else>
        (<DayoffDateSessionAtom
          class="text-current text-xs"
          is-text-only
          :date-session="dayOffDetail?.startDateSession"
          :end-date-session="dayOffDetail?.endDateSession"
        />)
      </span>
    </span>

    <!-- EXCEPTION -->
    <span
      v-if="
        dayOffDetail.type === DayoffType.EXCEPTION && dayOffDetail.exception
      "
      class="text-xs min-w-max"
    >
      <DayoffExceptionTypeAtom
        :exception-type="dayOffDetail.exception?.type"
        size="small"
      />
    </span>
    <span>
      <DayoffStatusAtom
        v-if="
          dayOffDetail.status &&
          !(
            dayOffDetail?.currentStatusRequest &&
            listStatusRequestShow?.includes(dayOffDetail?.currentStatusRequest)
          )
        "
        :status="dayOffDetail.status"
        size="small"
      />
    </span>
    <slot name="subContent"></slot>
  </div>

  <div v-if="!isHiddenRequestNote && dayOffDetail.requestNote">
    <span class="text-xs italic text-overflow-hidden">
      {{ dayOffDetail.requestNote }}
    </span>
  </div>
</template>
