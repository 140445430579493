<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    actionList: string[];
    commandActive: any[];
  }>(),
  {
    actionList: () => [
      'bold',
      'italic',
      'strikethrough',
      'underline',
      'bulletedList',
      'numberedList',
    ],
  }
);

const emits = defineEmits<{
  (e: 'execute', commandName): void;
  (e: 'executeLink', link): void;
}>();

const actionByKeys = {
  bold: {
    key: 'bold',
    iconName: 'bold',
  },
  italic: {
    key: 'italic',
    iconName: 'italic',
  },
  strikethrough: {
    key: 'strikethrough',
    iconName: 'strikethrough',
  },
  underline: {
    key: 'underline',
    iconName: 'underline',
  },
  bulletedList: {
    key: 'bulletedList',
    iconName: 'BulletedList',
  },
  numberedList: {
    key: 'numberedList',
    iconName: 'NumberedList',
  },
};

const actionDataList = computed(() => {
  return props.actionList
    ?.filter((key) => Object.keys(actionByKeys).includes(key))
    ?.map((action) => {
      return actionByKeys[action];
    });
});

const listActive = computed(() => props.commandActive);

const onClickAction = (key) => {
  emits('execute', key);
};
</script>
<template>
  <section class="flex-center space-x-2">
    <div
      v-for="(action, index) in actionDataList"
      :key="index"
      class="h-6 w-6 flex-center rounded cursor-pointer hover:bg-current-50"
      :class="
        listActive?.includes(action?.key)
          ? 'fill-current text-current-500 bg-current-50'
          : 'fill-gray-500 text-gray-500'
      "
      @click="onClickAction(action.key)"
    >
      <SynIcon :name="action.iconName" custom-class="h-3.5 w-3.5" />
    </div>
    <slot name="moreAction"></slot>
  </section>
</template>
