<script setup lang="ts">
import { ref, watch } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import VerticalBarChart from '@/ui/common/plugins/chart/VerticalBarChart.vue';
import { DATASETS_BY_REPORT_TYPE } from '@/application/constants/report/report.const';
import { EReportCardType } from '@/application/types/report/report.types';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    size: number;
  };
  isHiddenLegend?: boolean;
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    domainId: number;
    domainName: string;
    totalTasks: number;
    completedTasks: number;
  }[]
>([]);

const initComponent = async () => {
  isLoading.value = true;
  const res = await WorkPerformanceRepository.getDomainTaskStatistic({
    ...props.filterData,
  });
  resultData.value = res?.result;
  prepareDataForChart();
  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.size == old.size
    )
      return;

    initComponent();
  }
);

const datasets = ref<any>([]);
const labels = ref<string[]>([]);
const prepareDataForChart = () => {
  labels.value = resultData.value?.map(
    (data) => data?.domainName || 'Domain name'
  );

  datasets.value = DATASETS_BY_REPORT_TYPE[EReportCardType.DOMAIN_TASKS].map(
    (o) => {
      return {
        ...o,
        data: resultData.value?.map((data) => data[o.key]),
      };
    }
  );
};
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <VerticalBarChart v-else :labels="labels" :datasets="datasets" />
</template>
