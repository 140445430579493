<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g data-name="Layer 2">
        <path
          d="M256 512A256 256 0 0 1 75 75a256 256 0 0 1 362 362 254.33 254.33 0 0 1-181 75zm0-482C131.38 30 30 131.38 30 256s101.38 226 226 226 226-101.38 226-226S380.62 30 256 30z"
          opacity="1"
          class=""
        ></path>
        <path
          d="M345.26 360.26a14.93 14.93 0 0 1-10.6-4.39L156.13 177.34a15 15 0 0 1 21.21-21.21l178.53 178.53a15 15 0 0 1-10.61 25.6z"
          opacity="1"
          class=""
        ></path>
        <path
          d="M166.74 360.26a15 15 0 0 1-10.61-25.6l178.53-178.53a15 15 0 0 1 21.21 21.21L177.34 355.87a14.93 14.93 0 0 1-10.6 4.39z"
          opacity="1"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
