<script setup lang="ts">
import { ref, watch } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import userStore from '@/store/user';
import UserByAction from '@/ui/components/user/UserByAction.vue';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    size: number;
  };
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    assigneeId: number;
    fullName: string;
    avatar: string;
    completedTasks: number;
    totalTasks: number;
    totalDuration: number;
  }[]
>([]);

const initComponent = async () => {
  isLoading.value = true;
  const res = await WorkPerformanceRepository.getUserMostValuable({
    ...props.filterData,
  });
  resultData.value = res?.result?.map((o) => {
    return {
      ...o,
      avatar: userStore().allUserByIds[o.assigneeId].avatar,
    };
  });
  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.size == old.size
    )
      return;
    initComponent();
  }
);
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <div
    v-else
    class="
      flex-1
      min-h-0
      overflow-auto
      small-scrollbar
      w-full
      h-full
      flex
      justify-center
      items-start
    "
  >
    <SynTableCustom v-if="resultData?.length > 0">
      <template #header>
        <SynTr>
          <SynTh class="w-16"></SynTh>
          <SynTh is-center class="text-center">
            {{ $t('REPORT_LABEL_COMPLETED_TASKS') }}
          </SynTh>
          <SynTh is-center class="text-center">
            {{ $t('OVERVIEW_LABEL_WORKING_TIME_FULL') }}
          </SynTh>
          <SynTh is-center class="text-center">
            {{ $t('COMMON_LABEL_PERFORMANCE') }}
          </SynTh>
        </SynTr>
      </template>
      <template #body>
        <SynTr
          v-for="(task, index) in resultData"
          :key="task.assigneeId"
          :class="[index % 2 == 0 ? 'bg-gray-50' : '']"
        >
          <SynTd class="relative text-xs py-2">
            <UserByAction
              v-if="task.assigneeId"
              :user-id="task?.assigneeId"
              is-show-action-user
              avatar-class="w-7 h-7"
            />
          </SynTd>

          <SynTd class="text-xs text-center">
            <span> {{ task.completedTasks }}/{{ task.totalTasks }} </span>
          </SynTd>
          <SynTd class="text-xs text-center">
            {{
              task.totalDuration
                ? $filters.duration(
                    task.totalDuration * 60 * 60 * 1000,
                    'HH[h] mm[m]'
                  )
                : '0h 0m'
            }}
          </SynTd>

          <SynTd class="text-xs text-center">
            <div class="px-4 flex items-center gap-2">
              <div class="flex-1">
                <AtomProcess
                  :percent="
                    task.completedTasks && task.totalTasks
                      ? Math.floor(
                          (task.completedTasks / task.totalTasks) * 100
                        )
                      : 0
                  "
                  is-show-label
                  color="#b1dcde"
                  class="bg-white ring-1 h-4 ring-current"
                />
              </div>
            </div>
          </SynTd>
        </SynTr>
      </template>
    </SynTableCustom>
    <div v-else class="w-full h-full flex-center flex-col space-y-6">
      <div class="w-full grid grid-cols-4 gap-2">
        <div class="col-span-2 bg-gray-100 rounded-md h-4"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-4"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-4"></div>
        <!-- body -->
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
      </div>
    </div>
  </div>
</template>
