<script setup lang="ts">
import ModalDownloadFiles from '@/ui/modules/ged/download/ModalDownloadFiles.vue';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import { ref } from 'vue';
import chatStore from '@/store/chat';
import { canDownload, isFolder } from '@/ui/helpers/file-utils';
import { getDomainUrl } from '@/ui/hooks/commonHook';

defineProps<{
  files: any[];
}>();

const emits = defineEmits(['onComplete']);

const _chatStore = chatStore();

const isSelectChat = ref<boolean>();
const chatFilesToDownload = ref<any[]>();

let messageToChat: any;

const onFilesSendToChat = (files) => {
  messageToChat = null;

  const msgTexts: string[] = [];

  const filesNotDownload = files?.filter(
    (file) => !canDownload(file?.contentType)
  );

  filesNotDownload?.forEach((file) => {
    if (isFolder(file?.contentType)) {
      const folderLink = `${getDomainUrl()}/resources?folder=${file?.id}`;

      msgTexts.push(
        `<a target="_blank" rel="noopener noreferrer" href="${folderLink}">${
          file?.name || 'Folder'
        }</a>`
      );
    } else if (file?.link) {
      msgTexts.push(
        `<a target="_blank" rel="noopener noreferrer" href="${file.link}">${
          file?.name || 'Link'
        }</a>`
      );
    }
  });

  const msgText = msgTexts.join(', ');
  if (msgText) {
    messageToChat = {
      text: `<p>${msgText}</p>`,
    };
  }

  // Process message files
  chatFilesToDownload.value = files?.filter((file) =>
    canDownload(file?.contentType)
  );

  // If no file and only text, set chat message
  if (!chatFilesToDownload.value?.length) _setChatMessage();
};

const onChatFilesDownloaded = (files) => {
  if (files?.length) {
    messageToChat = messageToChat || {};
    messageToChat.files = files;
  }

  _setChatMessage();
};

const _setChatMessage = () => {
  if (!messageToChat) return;

  setTimeout(() => _chatStore.setChatAboutResources(messageToChat));

  emits('onComplete');
};
</script>

<template>
  <div @click.stop="isSelectChat = true">
    <slot />

    <ModalNewChat
      v-if="isSelectChat"
      :title="'Send to chat'"
      @on-close="isSelectChat = false"
      @on-conversation-click="
        isSelectChat = false;
        onFilesSendToChat(files);
      "
    />

    <ModalDownloadFiles
      v-if="chatFilesToDownload?.length"
      :files="chatFilesToDownload"
      @on-close="
        chatFilesToDownload = [];
        onChatFilesDownloaded($event);
      "
    />
  </div>
</template>

<style scoped></style>
