import TaskCreatePayload from '@/domain/entities/task/TaskPayloadClass';

export class TicketPayloadClass extends TaskCreatePayload {
    externalRootUID: string;
    externalUID: string;
    externalFirstName: string;
    externalLastName: string;
    externalEmail: string;

    constructor(data: any) {
        super(data);

        this.externalRootUID = data?.externalRootUID;
        this.externalUID = data?.externalUID;
        this.externalFirstName = data?.externalFirstName;
        this.externalLastName = data?.externalLastName;
        this.externalEmail = data?.externalEmail;
    }
}
