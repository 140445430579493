<script setup>
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  folder: {
    type: Object,
    default: null,
  },
  element: {
    type: HTMLElement,
    default: null,
  },
  isDriveDocument: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onFolderClick']);

const _route = useRoute();
const _onWindowKeydown = (e) => {
  const resourceElement = document.getElementById('resource-page');

  if (
    e.keyCode === 8 &&
    (e.target === resourceElement || e.target === document.body) &&
    _route.query.folder
  ) {
    onFolderClick(folders.value?.slice(-1).pop());
    e.preventDefault();
  }
};

const folders = computed(() => props.folder?.folders);

onMounted(() => {
  window.addEventListener('keydown', _onWindowKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', _onWindowKeydown);
});

const onFolderClick = (folder) => {
  if (!folder) {
    return emit('onFolderClick', null);
  }

  const folderIdx = folders.value?.findIndex((fd) => fd?.id === folder.id);

  if (folderIdx < 0) return;

  emit('onFolderClick', {
    ...folder,
    folders: folders.value?.slice(0, folderIdx),
  });
};

const onBackClick = () => {
  if (props.isDriveDocument && folders.value?.length === 1) {
    return onFolderClick(null);
  }

  onFolderClick(folders.value?.slice(-1).pop());
};
</script>

<template>
  <div class="flex items-center">
    <SynButton type-text color="gray" @click="onBackClick">
      <div class="flex-center space-x-2 text-sm">
        <SynIcon name="Back" custom-class="w-3 h-3" />
        <span class="text-overflow-hidden-line">{{
          $t('COMMON_LABEL_BACK')
        }}</span>
      </div>
    </SynButton>

    <SynButton type-text color="gray" @click="onFolderClick(null)">
      <div class="flex-center space-x-2 text-sm">
        <span v-if="folder?.storageName">{{ folder?.storageName }}</span>
        <SynIcon v-else name="Home" />
      </div>
    </SynButton>

    <template v-for="(parentFolder, index) in folders" :key="parentFolder?.id">
      <template v-if="!(isDriveDocument && index === 0)">
        <SynIcon name="ChevronRight" custom-class="w-2.5 h-2.5 fill-gray-500" />

        <SynButton type-text color="gray" @click="onFolderClick(parentFolder)">
          <span class="text-overflow-hidden-line text-sm">{{
            parentFolder?.name
          }}</span>
        </SynButton>
      </template>
    </template>

    <SynIcon name="ChevronRight" custom-class="w-3 h-3 fill-gray-500" />

    <div
      class="
        px-2
        py-1
        text-sm
        font-semibold
        text-overflow-hidden-line text-current
      "
    >
      {{ folder?.name }}
    </div>
  </div>
</template>
