<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import VigProgress from '@/ui/common/atoms/VigProgress/VigProgress.vue';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import exportStore from '@/store/export';
import domainStore from '@/store/scope';

const props = defineProps<{
  data: any;
  ownerId: any;
}>();

const emits = defineEmits(['onClose']);

const _exportStore = exportStore();
const _domainStore = domainStore();
const totalDownloadFile = computed(() => _exportStore.totalDownload);
// const isLoading = computed(() => _exportStore.isLoadingDownload);
const isError = computed(() => _exportStore.isError);

const downloadedPercent = ref(Math.round(totalDownloadFile.value * 100));

watch(
  () => totalDownloadFile.value,
  () => {
    downloadedPercent.value = Math.round(totalDownloadFile.value * 100);
  }
);

watch(
  () => downloadedPercent.value,
  (downloadedPercent) => {
    if (downloadedPercent === 100) {
      setTimeout(() => {
        openNotification({
          type: AlertType.success,
          body: translate('COMMON_DOWNLOAD_FILE_SUCCESS'),
        });
        emits('onClose');
      }, 1300);
    }
  }
);

watch(
  () => isError.value,
  () => {
    setTimeout(() => {
      openNotification({
        type: AlertType.error,
        body: translate('COMMON_DOWNLOAD_FILE_ERROR'),
      });
      emits('onClose');
    }, 500);
  }
);

onMounted(() => {
  const domainDetail = _domainStore.getDomainDetailById(props.ownerId);
  _exportStore.exportGanttChart(props.data, domainDetail?.name);
});

onUnmounted(() => {
  _exportStore.totalDownload = 0;
  _exportStore.isError = false;
});
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    :is-hidden-header="true"
    :is-hidden-footer="true"
    :is-hidden-close="true"
  >
    <template #body>
      <div class="h-max p-10 flex-center flex-col">
        <div class="flex flex-col items-center w-full">
          <!--            <SynAnimation-->
          <!--              v-if="isLoading"-->
          <!--              name="loadingdot"-->
          <!--              stype="width: 150px; height: 150px;"-->
          <!--            />-->
          <SynAnimation
            v-if="downloadedPercent == 100"
            name="success"
            stype="width: 200px; height: 200px;"
          />

          <SynAnimation
            v-else
            name="download"
            stype="width: 200px; height: 200px;"
          />

          <div class="flex-center mb-6 text-lg">
            <span v-if="downloadedPercent != 100" class="text-gray-700">
              {{ $t('COMMON_DOWNLOADING_FILE') || 'Uploading' }}
            </span>
            <span v-else class="text-current-500">{{
              $t('COMMON_DOWNLOAD_FILE_SUCCESS') || 'Downloaded'
            }}</span>
          </div>
        </div>

        <VigProgress :value="downloadedPercent" class="mb-5 w-full" />
      </div>
    </template>
  </SynModal>
</template>
