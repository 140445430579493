<script setup lang="ts">
withDefaults(
  defineProps<{
    tagList: any[];
    tagActive: string;
    dataByKey: any;
  }>(),
  {
    tagList: () => [{ key: 'Tag1', label: 'Tag1' }],
    tagActive: 'Tag1',
  }
);
const emits = defineEmits(['onSelectOption']);

const onChooseTag = (key) => {
  emits('onSelectOption', key);
};
</script>

<template>
  <div class="flex items-center space-x-2">
    <template v-for="item in tagList" :key="item">
      <SynTagField
        v-permission-function="{
          functionCode: item.functionCode,
          onContinue: () => {
            onChooseTag(item?.key);
          },
        }"
        :label="item?.label"
        :is-active="item?.key === tagActive"
        @click="onChooseTag(item?.key)"
      >
        <template v-if="dataByKey[item?.key]?.totalItem" #suffix>
          ({{ dataByKey[item?.key]?.totalItem }})
        </template>
      </SynTagField>
    </template>
  </div>
</template>
