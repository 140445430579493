<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="m8.45329 9.37052c-1.4063-.64644-2.38466-2.0837-2.38466-3.75295 0-2.27407 1.81577-4.11757 4.05567-4.11757 1.2566 0 2.3798.58026 3.1237 1.49126-2.7071.37124-4.84733 2.77431-4.84733 5.61195 0 .25982.01794.516.05262.76731z"
          class=""
        ></path>
        <path
          d="m4.9779 18.9997h-1.95101c-.60834 0-1.1153-.5147-1.01391-1.1324.54959-3.6268 3.33339-6.4975 6.8563-7.0183.42345.9727 1.11068 1.8086 1.96552 2.41-2.66571.9387-4.84628 3.0361-5.8569 5.7407z"
          class=""
        ></path>
        <path
          d="m13.9772 4.48564c2.2306 0 4.0556 1.8529 4.0556 4.11757 0 2.26469-1.825 4.11759-4.0556 4.11759s-4.05566-1.8529-4.05566-4.11759c0-2.26467 1.82506-4.11757 4.05566-4.11757z"
          class=""
        ></path>
        <path
          d="m13.9771 14.2649c4.0557 0 7.503 3.0881 8.0099 7.1028.1014.6176-.4055 1.1323-1.0139 1.1323h-13.99194c-.60834 0-1.1153-.5147-1.01391-1.1323.60835-4.0147 3.95424-7.1028 8.00985-7.1028z"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
