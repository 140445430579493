<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="36"
    viewBox="0 0 45 36"
  >
    <path
      id="Icon_awesome-user-plus"
      data-name="Icon awesome-user-plus"
      d="M43.875,14.625h-4.5v-4.5A1.128,1.128,0,0,0,38.25,9H36a1.128,1.128,0,0,0-1.125,1.125v4.5h-4.5A1.128,1.128,0,0,0,29.25,15.75V18a1.128,1.128,0,0,0,1.125,1.125h4.5v4.5A1.128,1.128,0,0,0,36,24.75h2.25a1.128,1.128,0,0,0,1.125-1.125v-4.5h4.5A1.128,1.128,0,0,0,45,18V15.75A1.128,1.128,0,0,0,43.875,14.625ZM15.75,18a9,9,0,1,0-9-9A9,9,0,0,0,15.75,18Zm6.3,2.25H20.876a12.24,12.24,0,0,1-10.252,0H9.45A9.452,9.452,0,0,0,0,29.7v2.925A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V29.7A9.452,9.452,0,0,0,22.05,20.25Z"
    />
  </svg>
</template>
