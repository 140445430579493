<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g fill-rule="evenodd">
        <path
          d="m48 64h-32a16.0007 16.0007 0 0 1 -16-16v-32a16.0007 16.0007 0 0 1 16-16h32a16 16 0 0 1 16 16v32a16 16 0 0 1 -16 16"
          fill="#ff0000"
          data-original="#ff0000"
        ></path>
        <path
          d="m30 18h18a9.0006 9.0006 0 0 0 .92-17.954c-.306-.017-.609-.046-.92-.046h-32a16.0007 16.0007 0 0 0 -16 16v32a30.0007 30.0007 0 0 1 30-30"
          fill="#ff5a51"
          data-original="#ff5a51"
        ></path>
        <path
          d="m48 32a16 16 0 1 0 16 16v-32a16 16 0 0 1 -16 16"
          fill="#d5002c"
          data-original="#d5002c"
        ></path>
        <path
          d="m25.048 17.876a1.9994 1.9994 0 0 0 -3.048 1.703v24.842a1.9994 1.9994 0 0 0 3.048 1.703c5.099-3.137 15.279-9.402 20.184-12.421a1.9992 1.9992 0 0 0 0-3.406c-4.905-3.019-15.085-9.284-20.184-12.421z"
          fill="#ffffff"
          data-original="#ffffff"
        ></path>
      </g>
    </g>
  </svg>
</template>
