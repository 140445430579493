<script setup lang="ts">
import { computed, ref } from 'vue';
import NewChat from '@/ui/modules/messaging/chat-icon/NewChat.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ModalNewGroupChat from '@/ui/modules/messaging/modal-new-group-chat/ModalNewGroupChat.vue';
import ModalChatExternalSearch from '@/ui/modules/messaging/chat-external/modal-chat-external-search/ModalChatExternalSearch.vue';
import settingStore from '@/store/setting';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';
import myProfileStore from '@/store/auth/my-profile';
import { AdminType } from '@/ui/common/constants/constant';

const props = defineProps<{
  title?: string;
  attachedTask?: any;
  attachedDayoff?: any;
  attachedEvent?: any;
  isBlockBubbleChat?: boolean;
  hasExternalChat?: boolean;
}>();

const emit = defineEmits(['onClose', 'onConversationClick', 'openSendFast']);

const isCreateGroupChat = ref<boolean>();
const isCreateExternalChat = ref<boolean>();

const currentTask = computed(() => props.attachedTask);
const externalChatEnabled = computed(() => {
  const systemConfigs = permissionStore().allSystemConfigs || {};
  const myProfile = myProfileStore().myProfile;

  return (
    settingStore().externalChatEnabled &&
    systemConfigs[systemConfig.CHAT_TICTOP_C_EXTERNAL_CHAT_ALLOWED] === '1' &&
    myProfile?.adminType !== AdminType.Visitor
  );
});

const onOpenNewChatSuccess = () => {
  emit('onConversationClick');
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    container-style="min-width: 40rem; max-width: 1200px;height: 80vh"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    :is-hidden-footer="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="flex justify-between">
        {{ title || translate('CHAT_NEW') || 'New chat' }}

        <div v-permission-model class="flex gap-2">
          <VigButton
            rounded="rounded-full"
            class="text-sm"
            @click="isCreateGroupChat = true"
          >
            <SynIcon name="GroupAdd" />
            <span>{{ $t('CHAT_GROUP_NEW') }}</span>
          </VigButton>
          <VigButton
            v-if="hasExternalChat && externalChatEnabled"
            color="yellow"
            rounded="rounded-full"
            class="text-sm"
            @click="isCreateExternalChat = true"
          >
            <SynIcon name="External" />
            <span>{{ 'External chat' }}</span>
          </VigButton>
        </div>
      </div>
    </template>

    <template #body>
      <NewChat
        is-chat-page
        :is-block-bubble-chat="isBlockBubbleChat"
        :attached-task="currentTask"
        :attached-dayoff="attachedDayoff"
        :attached-event="attachedEvent"
        @open-send-fast="(value) => $emit('openSendFast', value)"
        @success="onOpenNewChatSuccess"
        @close="$emit('onClose')"
      />
    </template>
  </SynModal>

  <ModalNewGroupChat
    v-if="isCreateGroupChat"
    @on-close="isCreateGroupChat = false"
    @on-chat-click="[onOpenNewChatSuccess(), (isCreateGroupChat = false)]"
    @on-created="[onOpenNewChatSuccess(), (isCreateGroupChat = false)]"
  />

  <ModalChatExternalSearch
    v-if="isCreateExternalChat"
    @on-close="isCreateExternalChat = false"
    @on-external-chat-click="
      isCreateExternalChat = false;
      $emit('onClose');
    "
  />
</template>
