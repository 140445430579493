<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import WorkflowsListInOverview from '@/ui/modules/workflow/workflows-list-in-overview/WorkflowsListInOverview.vue';
import WorkflowTasks from '@/ui/modules/workflow-task/workflow-tasks/WorkflowTasks.vue';

const route = useRoute();

const selectedWorkflowId = ref();

watch(
  () => route.query,
  (query) => {
    selectedWorkflowId.value = query['workflowId'];
  }
);

onBeforeMount(() => {
  selectedWorkflowId.value = route.query['workflowId'];
});
</script>

<template>
  <div class="h-full">
    <WorkflowsListInOverview v-if="!selectedWorkflowId" />

    <WorkflowTasks v-else :workflow-id="selectedWorkflowId" />
  </div>
</template>

<style scoped></style>
