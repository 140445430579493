import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';
import {
    append as svgAppend,
    attr as svgAttr,
    create as svgCreate,
    // remove as svgRemove,
    innerSVG,
} from 'tiny-svg';
import { getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';
import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';
import { translate } from '@/ui/plugins/i18n/myi18n';

const HIGH_PRIORITY = 1500,
    TASK_BORDER_RADIUS = 2;

export default class CustomRenderer extends BaseRenderer {
    constructor(eventBus, bpmnRenderer) {
        super(eventBus, HIGH_PRIORITY);

        this.bpmnRenderer = bpmnRenderer;
    }

    canRender(element) {
        // only render tasks and events (ignore labels)
        return (
            isAny(element, ['bpmn:Task', 'bpmn:Event']) && !element.labelTarget
        );
    }

    drawShape(parentNode, element) {
        const shape = this.bpmnRenderer.drawShape(parentNode, element);

        if (is(element, 'bpmn:Task')) {
            const metaData = element?.di?.$attrs;

            // if (metaData?.isStart == true || metaData?.isStart == 'true') {
            //     svgAttr(shape, {
            //         strokeWidth: 5,
            //         rx: '100%',
            //         ry: '100%',
            //     });
            // }
            if (metaData?.status) {
                svgAttr(shape, {
                    ...getStrokeByStatus(metaData?.status),
                });
            }

            if (metaData?.code) drawText(parentNode, metaData?.code);
            if (metaData?.isStart == true || metaData?.isStart == 'true')
                drawText(
                    parentNode,
                    translate('WORKFLOW_LABEL_START_POINT') || 'Start',
                    {
                        rx: 8,
                        ry: 8,
                        color: '#000',
                        background: '#e0f1f2',
                        transform: 'translate(-18,62) rotate(-90)',
                    }
                );

            // svgAttr(rect2, {
            //     transform: 'translate(2, 2)',
            // });

            return shape;
        }

        return shape;
    }

    getShapePath(shape) {
        if (is(shape, 'bpmn:Task')) {
            return getRoundRectPath(shape, TASK_BORDER_RADIUS);
        }

        return this.bpmnRenderer.getShapePath(shape);
    }
}

CustomRenderer.$inject = ['eventBus', 'bpmnRenderer'];

// function drawRect(parentNode, width, height, borderRadius, strokeColor) {
//     const rect = svgCreate('rect');

//     svgAttr(rect, {
//         width: width,
//         height: height,
//         rx: borderRadius,
//         ry: borderRadius,
//         stroke: strokeColor || '#000',
//         strokeWidth: 2,
//         fill: '#fff',
//     });
//     svgAppend(parentNode, rect);

//     return rect;
// }

// function drawStartShape(oldShape) {
//     console.log('🚀 Tictop ~ oldShape:', oldShape.getBBox());
//     const rect = svgCreate('rect');

//     svgAttr(rect, {
//         width: 60,
//         height: 60,
//         rx: 2,
//         ry: 2,
//         stroke: '#64748b' || '#000',
//         strokeWidth: 2,
//         fill: '#64748b',
//     });

//     return rect;
// }
function drawText(parentNode, textString, options) {
    const rect = svgCreate('rect');

    svgAttr(rect, {
        height: 15,
        rx: options?.rx || 2,
        ry: options?.ry || 2,
        stroke: options?.background || '#64748b',
        strokeWidth: 2,
        fill: options?.background || '#64748b',
        transform: options?.transform || 'translate(4, -8)',
    });
    svgAppend(parentNode, rect);

    // add text
    const textElm = svgCreate('text');

    svgAttr(textElm, {
        x: 6,
        y: 12,
        strokeWidth: 0,
        fill: options?.color || '#fff',
        fontSize: 12,
        transform: options?.transform || 'translate(4, -8)',
    });

    const codeString = textString || '';

    innerSVG(textElm, codeString);
    svgAppend(parentNode, textElm);

    setTimeout(() => {
        svgAttr(rect, {
            width: textElm?.getBBox()?.width + 12 || 20,
        });
    }, 100);

    return rect;
}
function getStrokeByStatus(status) {
    if (status == EWorkflowTaskStepStatus.COMPLETED)
        return {
            strokeWidth: 4,
            stroke: '#4ade80',
        };
    if (status == EWorkflowTaskStepStatus.TODO)
        return {
            strokeWidth: 4,
            stroke: '#60a5fa',
        };
    if (status == EWorkflowTaskStepStatus.INPROGRESS)
        return {
            stroke: '#f472b6',
        };
    if (status == EWorkflowTaskStepStatus.WAITING)
        return {
            strokeWidth: 2,
            stroke: '#9ca3af',
        };
    if (status == EWorkflowTaskStepStatus.CANCELED)
        return {
            stroke: '#e5e7eb',
        };

    return {
        stroke: '#1f2937',
    };
}

// function prependTo(newNode, parentNode, siblingNode) {
//     parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
// }
