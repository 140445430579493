<template>
  <svg
    viewBox="0 0 509 502.002"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs />
    <path
      id="path"
      d="M354.181 35.1257L448.152 35.1257L340.735 141.12L340.74 141.12C333.783 147.981 333.783 159.139 340.74 166C344.166 169.379 347.431 171.002 352 171.002C356.569 171.002 362.493 169.379 365.92 166L365.915 166.002L473.384 60.0106L473.384 152.689C473.384 162.418 481.38 170.303 491.14 170.303C501.004 170.303 509 162.418 509 152.689L509 17.614C509 7.88538 501.004 0 491.14 0L354.181 0C344.317 0 336.322 7.88538 336.322 17.614C336.426 27.2403 344.317 35.1257 354.181 35.1257Z"
      fill-rule="nonzero"
    />
    <path
      id="path"
      d="M106.846 502.002L402.05 502.002C461.028 502.002 508.896 454.69 508.896 396.625L508.896 259.502C508.896 249.773 500.901 241.99 491.036 241.99C481.172 241.99 473.281 249.875 473.281 259.502L473.281 396.522C473.281 435.232 441.299 466.774 402.05 466.774L106.846 466.774C67.5967 466.774 35.6155 435.232 35.6155 396.522L35.6155 105.481C35.6155 66.7713 67.5967 35.2299 106.846 35.2299L244.636 35.2299C254.5 35.2299 262.391 27.3445 262.391 17.6158C262.391 7.88721 254.396 0.00183105 244.636 0.00183105L106.846 0.00183105C47.868 0.00183105 1.52588e-05 47.314 1.52588e-05 105.379L1.52588e-05 396.522C1.52588e-05 454.69 47.9719 502.002 106.846 502.002Z"
      fill-rule="nonzero"
    />
    <path
      id="path"
      d="M339.5 171.002L185.5 171.002C180.315 171.002 172 179.061 172 189.002C172 198.943 180.315 207.002 185.5 207.002L339.5 207.002C344.685 207.002 354 198.943 354 189.002C354 179.061 344.685 171.002 339.5 171.002Z"
      fill-rule="nonzero"
    />
    <ellipse
      id="circle"
      cx="130.000046"
      cy="189.001953"
      rx="18.000000"
      ry="17.999998"
    />
    <path
      id="path"
      d="M339.5 263.002L185.5 263.002C180.315 263.002 172 271.061 172 281.002C172 290.943 180.315 299.002 185.5 299.002L339.5 299.002C344.685 299.002 354 290.943 354 281.002C354 271.061 344.685 263.002 339.5 263.002Z"
      fill-rule="nonzero"
    />
    <ellipse
      id="circle"
      cx="130.000015"
      cy="281.001984"
      rx="18.000000"
      ry="17.999998"
    />
    <path
      id="path"
      d="M339.5 361.002L185.5 361.002C180.315 361.002 172 369.061 172 379.002C172 388.943 180.315 397.002 185.5 397.002L339.5 397.002C344.685 397.002 354 388.943 354 379.002C354 369.061 344.685 361.002 339.5 361.002Z"
      fill-rule="nonzero"
    />
    <ellipse
      id="circle"
      cx="130.000015"
      cy="379.001984"
      rx="18.000000"
      ry="17.999998"
    />
  </svg>
</template>
