<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import DomainCreateForm from '@/ui/modules/domains/domain/DomainCreateForm.vue';
import DomainCreateSupplementForm from '@/ui/modules/domains/domain/DomainCreateSupplementForm.vue';
import UpdateDomainMembers from '@/ui/modules/domains/component/UpdateDomainMembers.vue';
import domainStore from '@/store/scope';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import UpdateProjectMembers from '@/ui/modules/domains/component/UpdateProjectMembers.vue';
import {
  DomainAddPayload,
  DomainMembersPayload,
  DomainSupplementPayload,
} from '@/application/types/domain/DomainPayload';
import DomainDetailClass from '@/domain/entities/domains/DomainDetailClass';
import { cloneDeep } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  domainId?: number | null;
  parentId?: number | null;
}>();
const emit = defineEmits<{
  (e: 'onDelete'): void;
  (e: 'onClose'): void;
  (e: 'onCreateSuccess', data: any): void;
}>();

const _domainStore = domainStore();

const onClose = () => {
  emit('onClose');
};

const domainPayload = ref({
  name: '',
  description: '',
  members: [] as DomainMembersPayload[] | number[],
  domainId: props.domainId,
  domainName: '',
  parentId: props.parentId,
  parentName: '',
  haveSupplement: false,
  supplement: {} as DomainSupplementPayload,
});

const domainByIds = computed(() => _domainStore.domainByIds);
const projectByIds = computed(() => _domainStore.projectByIds);
const currentDomainDetail = computed<DomainDetailClass>(() => {
  if (!domainPayload.value?.domainId) return {};
  return domainByIds.value[domainPayload.value?.domainId];
});
const currentParentDetail = computed<any>(() => {
  if (!domainPayload.value?.parentId) return {};
  return projectByIds.value[domainPayload.value?.parentId];
});
const anyProjectMembers = computed<any[]>(() => {
  if (!domainPayload.value?.domainId) return [];
  return currentDomainDetail.value.members?.filter(
    (user) => user?.id && user.anyProjects
  );
});

const initData = () => {
  domainPayload.value = {
    ...domainPayload.value,
    members: [...cloneDeep(anyProjectMembers.value)] || [],
  };
};
const onResetMember = () => {
  domainPayload.value = {
    ...domainPayload.value,
    members: [...cloneDeep(anyProjectMembers.value)] || [],
  };
};

onMounted(() => {
  initData();
});

const {
  currentStep,
  stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    INFOMATIONS: {
      key: 'INFOMATIONS',
      label: 'COMMON_LABEL_INFORMATION',
      next: 'MEMBERS',
      previous: '',
      isActive: true,
      previousText: 'COMMON_LABEL_CANCEL',
      confirmText: 'COMMON_LABEL_NEXT',
      onClose: () => {
        onClose();
      },
    },
    MEMBERS: {
      key: 'MEMBERS',
      label: 'DASHBOARD_TABLE_LABEL_MEMBER',
      next: '',
      previous: 'INFOMATIONS',
      isActive: false,
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'TASK_DETAIL_FINISH',
      previousAction: () => {
        onResetMember();
      },
      nextAction: () => {
        onSubmitCreate();
      },
    },
  },
  'INFOMATIONS'
);

const isLoadFinishCreate = ref(false);
const isReadyNameDomain = ref(false);
const newDomainName = ref('');
const isDisabledSummit = computed(
  () => !domainPayload.value?.name || isReadyNameDomain.value == false
);

const onSubmitCreate = async () => {
  if (!domainPayload.value?.name) return;
  isLoadFinishCreate.value = true;
  let promiseCreate;
  if (domainPayload.value?.domainId) {
    // Create new project
    promiseCreate = () =>
      _domainStore.createProject({
        name: domainPayload.value.name,
        description: domainPayload.value.description,
        domainId: domainPayload.value.domainId,
        parentId: domainPayload.value.parentId,
        members: [
          ...domainPayload.value.members,
          ...updatedMembersOfDomain.value,
        ],
      });
  } else {
    // Create new domain
    promiseCreate = () =>
      _domainStore.createDomain(new DomainAddPayload(domainPayload.value));
  }
  const result = await promiseCreate();
  emit('onCreateSuccess', result?.id);
  isLoadFinishCreate.value = false;
};
const updatedMembersOfDomain = ref<any>([]);
const updateDomainProjectMembers = (data) => {
  const index = updatedMembersOfDomain.value?.findIndex(
    (user) => user?.id == data?.id
  );
  if (index == -1)
    updatedMembersOfDomain.value = [...updatedMembersOfDomain.value, data];
  else updatedMembersOfDomain.value[index] = data;
};
const isProject = ref(false);
const updateIsProject = (value) => {
  isProject.value = value;
  if (!value) {
    domainPayload.value.domainId = null;
    domainPayload.value.parentId = null;
    domainPayload.value.domainName = '';
    domainPayload.value.parentName = '';
  }
};

const parentMembers = computed<any[]>(() => {
  if (props.parentId) return projectByIds.value[props.parentId]?.members;
  if (
    !props.domainId ||
    !Object.prototype.hasOwnProperty.call(domainByIds.value, props.domainId)
  )
    return [];

  return (domainByIds.value[props.domainId]?.members || []).map(
    (user) => user?.id
  );
});
</script>
<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-class="md:w-2/5 h-4/5"
    disable-click-outside
    :container-style="'min-height: 600px; min-width: 800px'"
    @cancel="onClose"
  >
    <template #header>
      <div class="w-full flex items-center space-x-1">
        <template v-if="domainPayload.domainId || currentDomainDetail?.name">
          <span>
            {{
              $t('DOMAIN_LABEL_CREATE_NEW_SUB_FOR') ||
              'Create new domain/project'
            }}
          </span>

          <div class="flex-center space-x-2 rounded text-current">
            <span v-if="currentDomainDetail?.name">
              {{ currentDomainDetail?.name }}
            </span>
            <span v-else>{{ newDomainName }}</span>

            <template v-if="domainPayload.domainId">
              <span>></span>
              <span v-if="currentParentDetail?.name">
                {{ currentParentDetail?.name }}
              </span>
              <span v-else>
                {{ newDomainName }}
              </span>
            </template>
          </div>
        </template>
        <template v-else>
          <span>
            {{ $t('DOMAIN_LABEL_CREATE_NEW') || 'Create new domain/project' }}
          </span>
        </template>
      </div>
    </template>
    <template #body>
      <!-- INFO GROUP -->
      <div
        v-if="currentStep == 'INFOMATIONS'"
        class="w-full p-4 pt-8 flex-center flex-col space-y-2"
      >
        <DomainCreateForm
          v-model:isProject="isProject"
          v-model:name="domainPayload.name"
          v-model:description="domainPayload.description"
          v-model:domainId="domainPayload.domainId"
          v-model:domainName="domainPayload.domainName"
          v-model:parentId="domainPayload.parentId"
          v-model:parentName="domainPayload.parentName"
          @update:domain-id="initData"
          @update:is-project="updateIsProject"
          @is-ready-name-domain="(value) => (isReadyNameDomain = value)"
          @name-input="(value) => (newDomainName = value)"
        />
        <DomainCreateSupplementForm
          v-if="!domainPayload.domainId"
          v-model:haveSupplement="domainPayload.haveSupplement"
          v-model:startDate="domainPayload.supplement.startDate"
          v-model:endDate="domainPayload.supplement.endDate"
          v-model:budgetMin="domainPayload.supplement.budgetMin"
          v-model:budgetMax="domainPayload.supplement.budgetMax"
          v-model:responsibility="domainPayload.supplement.responsibility"
        />
      </div>

      <!-- INVITE MEMBER -->
      <template v-if="currentStep == 'MEMBERS'">
        <div class="px-4 flex flex-col w-full h-full">
          <UpdateProjectMembers
            v-if="domainPayload.domainId"
            v-model="domainPayload.members"
            class="flex-1"
            autofocus
            :domain-id="domainPayload.domainId"
            :parent-id="domainPayload.parentId"
            :parent-members="parentMembers"
            is-editting
            @update:updated-members="updateDomainProjectMembers"
          />
          <UpdateDomainMembers
            v-else
            v-model="domainPayload.members"
            is-editting
            :domain-name="domainPayload.name"
          />
        </div>
      </template>
    </template>

    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          v-if="stepperByKey[currentStep].previousText"
          type-outline
          :label="$t(stepperByKey[currentStep].previousText)"
          :disabled="isLoadFinishCreate"
          @click="onHandleGoToPreviousStep"
        />
        <SynButton
          v-if="stepperByKey[currentStep].confirmText"
          :is-loading="isLoadFinishCreate"
          :disabled="isDisabledSummit"
          :label="$t(stepperByKey[currentStep].confirmText)"
          @click="onHandleGoToNextStep"
        />
      </div>
    </template>
  </SynModal>
</template>
