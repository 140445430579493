import BaseRequest from '@/base/api/base-request';
import { AxiosInstance } from 'axios';
import { IFileStorageRepository } from '@/domain/interfaces/repositories/IFileStorageRepository';
import {
    uploadFile,
    genFilePathByType,
} from '@/ui/plugins/awss3/aws-s3-services';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import $constants from '@/ui/plugins/constants';

export default class FileStorageRepository implements IFileStorageRepository {
    private static instance: FileStorageRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }
    public static getInstance(): FileStorageRepository {
        if (!FileStorageRepository.instance) {
            // Get from local storage
            FileStorageRepository.instance = new FileStorageRepository();
        }

        return FileStorageRepository.instance;
    }

    getFiles(params) {
        return this.request({
            method: 'get',
            url: `FileStorage/GetFiles`,
            params,
        });
    }

    uploadFiles(data) {
        return this.request({
            method: 'post',
            url: `FileStorage/UploadFiles`,
            data,
        });
    }

    deleteFiles(data) {
        return this.request({
            method: 'put',
            url: `FileStorage/DeleteFiles`,
            data,
        });
    }

    moveFilesToTrash(data) {
        return this.request({
            method: 'put',
            url: `FileStorage/MoveFilesToTrash`,
            data,
        });
    }

    restoreFilesFromTrash(data) {
        return this.request({
            method: 'put',
            url: `FileStorage/RestoreFilesFromTrash`,
            data,
        });
    }

    deleteAllFilesFromTrash() {
        return this.request({
            method: 'put',
            url: `FileStorage/DeleteAllFilesFromTrash`,
        });
    }

    moveFilesToFolder(data) {
        return this.request({
            method: 'put',
            url: `FileStorage/MoveFilesToFolder`,
            data,
        });
    }

    saveFileToMyResource(data) {
        return this.request({
            method: 'post',
            url: `FileStorage/SaveFileToMyResource`,
            data,
        });
    }

    renameFile(data) {
        return this.request({
            method: 'put',
            url: `FileStorage/renameFile`,
            data,
        });
    }

    addFolder(data) {
        return this.request({
            method: 'post',
            url: `FileStorage/AddFolder`,
            data,
        });
    }

    getFolder(folderId) {
        return this.request({
            method: 'get',
            url: `FileStorage/GetFolder/${folderId}`,
        });
    }

    addLink(data) {
        return this.request({
            method: 'post',
            url: `FileStorage/addLink`,
            data,
        });
    }

    getDriveDocuments(params) {
        return this.request({
            method: 'get',
            url: `FileStorage/GetDriveDocuments`,
            params,
        });
    }

    addDriveDocument(data) {
        return this.request({
            method: 'post',
            url: `FileStorage/AddDriveDocument`,
            data,
        });
    }

    async uploadFileToAws(
        organizationId: number,
        fileId: string,
        file: any
    ): Promise<any> {
        const path = genFilePathByType(
            organizationId,
            fileId,
            file?.name,
            file?.type
        );

        const s3Payload = {
            Bucket: this._getFileBucketByType(file?.type),
            Key: path,
            Body: file.url
                ? Buffer.from(
                      file.url.replace(/^data:image\/\w+;base64,/, ''),
                      'base64'
                  )
                : file,
            ContentType: file?.type,
            ACL: 'public-read',
        };

        const { Location } = await uploadFile(s3Payload);

        return {
            path: Location?.slice(Location?.indexOf(`${organizationId}/`)),
        };
    }

    private _getFileBucketByType(contentType) {
        if (contentType?.startsWith($constants.FILE.TYPE.IMAGE)) {
            return bucketTictop.StoragePhoto;
        }

        if (
            contentType?.startsWith($constants.FILE.TYPE.AUDIO) ||
            contentType?.startsWith($constants.FILE.TYPE.VIDEO)
        ) {
            return bucketTictop.StorageMedia;
        }

        return bucketTictop.StorageFile;
    }
}
