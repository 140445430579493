import BaseRepository from '@/base/repositories/BaseRepository';

export default class PermissionRepository {
    private static instance: PermissionRepository;

    constructor() {}

    public static getInstance(): PermissionRepository {
        if (!PermissionRepository.instance) {
            PermissionRepository.instance = new PermissionRepository();
        }
        return PermissionRepository.instance;
    }

    getPermissionsByUser(): any {
        return BaseRepository.requestWithAuthorize({
            url: `Permission/GetPermissionsByUser`,
            method: 'get',
        });
    }
}
