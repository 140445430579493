<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { onMounted, ref, computed } from 'vue';
import TabContainer from '@/ui/common/molecules/SynTab/TabContainer.vue';
import {
  ITaskTodo,
  ITaskTodoItemStatus,
} from '@/application/types/task/task.types';
import TaskTodoListCreate from '@/ui/modules/task/components/check-list/TaskTodoListCreate.vue';
import TaskTodoListDetail from '@/ui/modules/task/components/check-list/TaskTodoListDetail.vue';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import taskStore from '@/store/task';
import { ITaskConfigs } from '@/application/types/task/task-configs.types';

const CURRENT_COMPONENT_ID = 'task-todo-list-create-new';
const props = defineProps<{
  taskId?: number;
  taskTodos?: ITaskTodo[];
  hiddenFooter?: boolean;
  readonly?: boolean;
}>();
const emit = defineEmits(['onSave', 'update:taskTodos', 'onAddNew']);

const _taskStore = taskStore();

const taskConfigs = computed<ITaskConfigs>(() => {
  return _taskStore.dataConfigs;
});
const maxTodoList = computed<number>(() => {
  return taskConfigs.value?.checkList?.maxTotalItems || 5;
});

const multipleTodoList = ref<ITaskTodo[]>([]);

const recentlyTodoList = computed<ITaskTodo[]>(() =>
  multipleTodoList.value?.slice(0, 5)
);

const moreTodoList = computed<ITaskTodo[]>(() =>
  multipleTodoList.value?.slice(5, multipleTodoList.value?.length || 0)
);

const currentTodoList = ref<ITaskTodo | null>();

const currentInMoreList = computed<boolean>(() => {
  return moreTodoList.value?.some(
    (data) => data?.id == currentTodoList.value?.id
  );
});

const taskTodoListCreateNewRef = ref<any>();

const initComponent = async (data?) => {
  const defaultData = data?.taskTodos || props.taskTodos;

  multipleTodoList.value = defaultData || [];
  currentTodoList.value = defaultData?.length > 0 ? defaultData[0] : null;
};

const taskTodoListDetailRef = ref<any>();
const onSwitchTab = (data) => {
  currentTodoList.value = data;
};
const onCloseTab = async (data) => {
  if (props.readonly) return;

  removeTodoListParent(data);
};

const isAddNew = ref<boolean>(false);

const taskTodoListCreateRef = ref<any>();
const onClickNewTab = () => {
  console.log(
    '🚀 Tictop ~ multipleTodoList.value?.length == maxTodoList.value:',
    multipleTodoList.value?.length == maxTodoList.value
  );
  if (multipleTodoList.value?.length == maxTodoList.value) return;

  isAddNew.value = true;

  const lang = getLocalStorage(StorageConstant.LANGUAGE);
  const namePrefix =
    lang == 'en'
      ? 'Checklist'
      : lang == 'fr'
      ? 'Liste de contrôle'
      : 'Việc cần làm';

  const newTodoItemId = uuidv4();

  currentTodoList.value = {
    id: uuidv4(),
    title: `${namePrefix} ${multipleTodoList.value?.length + 1}`,
    description: '',
    isDraft: true,
    todoItems: [
      {
        id: newTodoItemId,
        title: '',
        shortDescription: '',
        description: '',
        status: ITaskTodoItemStatus.PENDING,
        index: 0,
      },
    ],
  };

  multipleTodoList.value = [currentTodoList.value, ...multipleTodoList.value];

  emit('update:taskTodos', multipleTodoList.value);
};

const removeTodoListParent = (todoList) => {
  multipleTodoList.value = multipleTodoList.value?.filter(
    (o) => o.id !== todoList?.id
  );

  if (currentTodoList.value?.id == todoList?.id)
    currentTodoList.value =
      multipleTodoList.value?.length > 0 ? multipleTodoList.value[0] : null;

  emit('update:taskTodos', multipleTodoList.value);
};

const onCancelCreate = () => {
  removeTodoListParent(currentTodoList.value);
};

const onSave = () => {
  if (!currentTodoList.value) return;
  currentTodoList.value.isDraft = false;

  emit('onSave', multipleTodoList.value);
};

onMounted(() => {
  initComponent();
});

const isShowHiddenTab = ref<boolean>(false);

const onUpdateTodoList = () => {
  onSave();
};

defineExpose({
  initComponent,
  onClickNewTab,
});
</script>
<template>
  <div
    :id="CURRENT_COMPONENT_ID"
    class="w-full h-full rounded-md relative"
    :class="multipleTodoList?.length > 0 ? 'border border-gray-300' : ''"
  >
    <span
      v-if="multipleTodoList?.length == 1 && !readonly"
      class="
        absolute
        -top-2
        -right-2
        z-10
        w-6
        h-6
        rounded-full
        flex-center
        hover:text-black hover:bg-gray-300
        bg-gray-100
        cursor-pointer
        task-todo-list-create-new_hover-to-show
      "
      @click.stop="onCloseTab(currentTodoList)"
    >
      <SynIcon name="trash" custom-class="w-4 h-4 fill-red-500" />
    </span>
    <TabContainer class="rounded-md">
      <template #actions> <span></span></template>
      <template v-if="multipleTodoList?.length > 1" #header>
        <div
          class="w-full flex-1 flex list-tab-container bg-gray-50"
          style="height: 2rem"
        >
          <!--          <div-->
          <!--            v-if="multipleTodoList?.length < maxTodoList"-->
          <!--            class="h-8 w-8 flex-center hover:bg-current-50 cursor-pointer"-->
          <!--            @click.stop="onClickNewTab"-->
          <!--          >-->
          <!--            <SynIcon class="fill-current" custom-class="h-3 w-3" name="plus" />-->
          <!--          </div>-->
          <li
            v-for="data in recentlyTodoList"
            :key="data?.id"
            v-vig-tooltip="data?.title || ''"
            :class="[
              data?.id == currentTodoList?.id
                ? 'bg-white font-semibold text-current'
                : 'text-current-900 bg-gray-200 hover:bg-gray-100',
            ]"
            class="
              min-w-0
              hover-to-show__parent
              flex-center
              shadow-sm
              cursor-pointer
              relative
            "
            @click="onSwitchTab(data)"
            @auxclick="onCloseTab(data)"
          >
            <span class="px-2 truncate" style="min-width: 3rem">
              {{ data?.title }}
            </span>

            <span
              v-if="data?.isDraft"
              class="absolute top-0 right-0 w-2 h-2 rounded-full bg-orange-500"
            >
            </span>
            <span
              v-if="!readonly"
              class="
                w-4
                h-4
                rounded-full
                bg-white
                flex-center
                hover:text-black hover:bg-gray-300
              "
              :class="[
                data?.id == currentTodoList?.id
                  ? 'mr-1'
                  : 'hover-to-show__children absolute right-1',
              ]"
              @click.stop="onCloseTab(data)"
            >
              <SynIcon name="close" custom-class="w-3 h-3" />
            </span>
          </li>

          <VigDropdown
            v-if="moreTodoList?.length > 0"
            ref="hiddenTabDropdownRef"
            class="flex-1"
            :arrow="false"
            :z-index="60"
            @on-dropdown-open="isShowHiddenTab = true"
            @on-dropdown-close="isShowHiddenTab = false"
          >
            <template #dropdown-toggle>
              <div
                :title="`${moreTodoList?.length} Other`"
                class="
                  w-full
                  cursor-pointer
                  flex-center
                  space-x-2
                  shadow-sm
                  text-gray-700
                  hover:bg-gray-100
                  relative
                  px-2
                "
                :class="currentInMoreList ? 'bg-white' : 'bg-gray-200'"
                style="min-width: 6rem; height: 2rem"
              >
                <span v-if="currentInMoreList">
                  {{ currentTodoList?.title }}
                </span>
                <span v-else>
                  {{ $t('NOTIFICATION_LABEL_TYPE_OTHER') }} ({{
                    moreTodoList?.length
                  }})
                </span>
                <SynIcon
                  :name="'sort-down'"
                  custom-class="h-2.5 w-2.5 fill-gray-500"
                  :class="!isShowHiddenTab ? '-rotate-90' : ''"
                />

                <span
                  v-if="currentInMoreList && currentTodoList?.isDraft"
                  class="
                    absolute
                    top-0
                    right-0
                    w-2
                    h-2
                    rounded-full
                    bg-orange-500
                  "
                >
                </span>
              </div>
            </template>
            <template #dropdown-menu>
              <div
                class="
                  flex flex-col
                  rounded-md
                  space-y-1
                  overflow-auto
                  small-scrollbar
                "
                style="max-height: 620px; width: 8rem"
              >
                <div
                  v-for="tab in moreTodoList"
                  :key="tab?.id"
                  :title="tab?.title"
                  class="
                    flex
                    items-center
                    px-4
                    py-2
                    hover:bg-gray-100
                    rounded
                    relative
                    cursor-pointer
                    hover-to-show__parent
                    dropdown-item
                  "
                  @click="onSwitchTab(tab)"
                >
                  <span class="text-sm text-gray-400 truncate">
                    {{ tab?.title }}
                  </span>

                  <span
                    v-if="!readonly"
                    class="
                      hover-to-show__children
                      absolute
                      right-1
                      flex-center
                      h-5
                      w-5
                      hover:bg-gray-200
                      rounded-full
                      cursor-pointer
                    "
                    @click.stop="onCloseTab(tab?.id)"
                  >
                    <SynIcon name="close" />
                  </span>

                  <span
                    v-if="tab?.isDraft"
                    class="
                      h-2
                      w-2
                      absolute
                      top-1
                      left-1
                      rounded-full
                      bg-orange-500
                    "
                  >
                  </span>
                </div>
              </div>
            </template>
          </VigDropdown>
        </div>
      </template>

      <template #body>
        <div
          ref="taskTodoListCreateNewRef"
          class="flex-1 min-h-0 flex flex-col relative"
          style="max-height: 100%"
        >
          <template v-if="currentTodoList?.id">
            <TaskTodoListCreate
              v-if="currentTodoList?.isDraft || !taskId"
              ref="taskTodoListCreateRef"
              v-model:todo-list="currentTodoList"
              :task-id="taskId"
              :hidden-footer="hiddenFooter"
              :show-add-new-button="multipleTodoList?.length < maxTodoList"
              :readonly="readonly"
              @on-cancel="onCancelCreate"
              @on-save="onSave"
              @on-add-new="onClickNewTab"
              @update:todo-list="onUpdateTodoList"
            />
            <TaskTodoListDetail
              v-else
              ref="taskTodoListDetailRef"
              :show-add-new-button="multipleTodoList?.length < maxTodoList"
              :task-id="taskId"
              :todo-list="currentTodoList"
              :readonly="readonly"
              @on-add-new="onClickNewTab"
            />
          </template>
        </div>
      </template>
    </TabContainer>
  </div>
</template>

<style>
.task-todo-list-create-new_hover-to-show {
  visibility: hidden;
}
#task-todo-list-create-new:hover .task-todo-list-create-new_hover-to-show {
  visibility: unset;
}
</style>
