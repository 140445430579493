<script setup lang="ts">
import DrawerWidget from '@/ui/components/DrawerWidget/DrawerWidget.vue';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
import { ref } from 'vue';

withDefaults(
  defineProps<{
    isLoading?: boolean;
    drawNoteData: {
      isLoading: boolean;
      drawData: any;
      images: any[];
      originFile: string;
      readonly: boolean;
    };
    pastedImage?: any;
    isHiddenClose?: boolean;
  }>(),
  {
    isLoading: false,
    pastedImage: undefined,
    isHiddenClose: false,
    drawNoteData: () => {
      return {
        isLoading: false,
        drawData: {},
        images: [],
        originFile: '',
        readonly: false,
      };
    },
  }
);

const emit = defineEmits(['onClose', 'onSave', 'onCollapse']);

const onSaveNote = (data) => {
  emit('onSave', data);
};
const drawerWidgetRef = ref<any>(null);
const onCloseModal = () => {
  drawerWidgetRef.value?.onCancel && drawerWidgetRef.value?.onCancel();
};
</script>

<template>
  <SynModalNoFocus
    is-hidden-footer
    is-hidden-header
    :is-hidden-close="isHiddenClose"
    disable-click-outside
    disable-esc-to-close
    disable-auto-focus
    disable-element-active
    z-index="z-60"
    style-body="p-0"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :container-style="`max-height: 1920px;
          max-width: 1920px;
          min-height:500px;
          min-width:600px;
          width: 100%;
          height: 100%;
          `"
    :background-style="`padding: 20px;`"
    @cancel="onCloseModal"
  >
    <template #body>
      <div
        class="relative"
        style="
          max-height: 1920px;
          max-width: 1920px;
          min-height: 500px;
          min-width: 600px;
          width: 100%;
          height: 100%;
        "
        z-index="-1"
      >
        <DrawerWidget
          ref="drawerWidgetRef"
          :readonly="drawNoteData?.readonly"
          :files="drawNoteData?.images"
          :draw-data="drawNoteData?.drawData"
          :origin-file="drawNoteData?.originFile"
          :is-loading="isLoading"
          :is-hidden-close="isHiddenClose"
          :pasted-image="pastedImage"
          @cancel="$emit('onClose')"
          @on-collapse="$emit('onCollapse')"
          @on-save="onSaveNote"
        />
      </div>
    </template>
  </SynModalNoFocus>
</template>
