import { EObjectStore, ETransactionMode } from '@/indexedDB';
import IdbBase from '../base/idb-base';
import { cloneDeep } from '@/ui/hooks/commonFunction';

const CURRENT_OBJECT_STORE_NAME = EObjectStore.myConfigs;

export default class MyConfigsIdbStore extends IdbBase {
    private static instance: MyConfigsIdbStore;
    constructor() {
        super(CURRENT_OBJECT_STORE_NAME);
    }

    public static getInstance(): MyConfigsIdbStore {
        if (!MyConfigsIdbStore.instance) {
            // Get from local storage
            MyConfigsIdbStore.instance = new MyConfigsIdbStore();
        }

        return MyConfigsIdbStore.instance;
    }

    async saveConfig(code, data: any) {
        const payload = { ...cloneDeep(data), code };

        const { transaction, store } = await this._getTransactionByMode(
            ETransactionMode.readwrite
        );

        store.put(payload);

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve('save local config completed!');
            };

            transaction.onerror = () => {
                reject("can't save local completed!");
            };
        });
    }
}
