<script setup lang="ts">
import { ref } from 'vue';
import VoucherCard from '@/ui/modules/voucher/VoucherCard.vue';
import unAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import { EPaymentVoucherResponseCode } from '@/application/interfaces/payment/TransactionInterfaces';
const props = defineProps<{
  voucherCode: string;
  planId: string;
  needToUpdate: boolean;
}>();
defineEmits<{
  (e: 'apply', value: string): void;
}>();

const voucherDetail = ref<any>({});

const initComponent = async () => {
  if (props.voucherCode) {
    const res = await unAuthenticateService
      .getInstance()
      .getVoucherCodeDetail(props.voucherCode, props.needToUpdate);

    const result = res?.result;
    voucherDetail.value = {
      ...result,
      messageCode: EPaymentVoucherResponseCode.VOUCHER_VALID,
    };
  }
};

initComponent();
</script>
<template>
  <div class="flex flex-col bg-white rounded-xl" style="width: 32rem">
    <!-- HEADER -->
    <div
      class="
        flex-center
        p-4
        flex-col
        bg-gradient-to-r
        from-current-500
        to-green-400
        relative
        rounded-t-xl
      "
    >
      <SynIcon
        name="present"
        custom-class="absolute -top-24 w-32 h-32 fill-white z-60"
      />
      <div class="text-white font-semibold mt-8 text-2xl">
        {{ $t('VOUCHER_LABEL_THANK') || 'Thank you for Being Our Customer' }}
      </div>
    </div>
    <!-- BODY -->
    <div class="flex-center pt-8 px-8 flex-col text-current-800">
      <!-- Letter -->
      <div
        class="py-4 flex flex-col text-current-500 text-center"
        style="font-weight: 450"
      >
        <!-- <div class="w-full pb-4">
          {{ $t('HEADER_LABEL_WELCOME_HELLO') || 'Hi' }},
        </div> -->
        <div
          class="text-sm"
          v-html="
            $t('VOUCHER_LABEL_LETTER_01_V2', {
              planId,
            })
          "
        ></div>
        <!-- <div>
          {{
            $t('VOUCHER_LABEL_LETTER_02_V2') ||
            'Tictop Team is very grateful for your trust and efforts to bring the best experience to our customers!'
          }}
        </div> -->
      </div>

      <!-- Voucher code -->
      <div
        class="
          w-full
          p-4
          flex-center
          rounded-md
          bg-gradient-to-r
          to-white
          from-yellow-50
        "
      >
        <VoucherCard hidden-close :voucher-detail="voucherDetail" />
      </div>
    </div>

    <!-- FOOTER -->
    <div class="flex space-x-2 justify-end p-8">
      <!-- <SynButton
        type-text
        class="bg-gray-100"
        color="gray"
        :label="$t('PAYMENT_RENEW_LATER') || 'Skip'"
        @click="$emit('skip')"
      /> -->
      <SynButton
        :label="$t('VOUCHER_LABEL_APPLY_NOW') || 'Apply now'"
        @click="$emit('apply', voucherCode)"
      />
    </div>
  </div>
</template>
