/* eslint-disable no-unused-vars */
export enum DomainStatus {
    Created = 1,

    Verified = 2,
}

/* eslint-disable no-unused-vars */
export enum DomainActionType {
    AddNew = 1,

    Update = 2,

    MoveIn = 3,

    MoveOut = 4,

    Remove = 5,
}

export enum DomainTab {
    Tasks = 'TASKS',
    Resources = 'RESOURCES',
}
