<script setup lang="ts">
import { ColumnExports } from '@/ui/pages/overview/working-time/principle-const';
import { onMounted, ref } from 'vue';

defineEmits(['onClose', 'onSave']);
defineProps<{
  isLoading: boolean;
}>();

const columnList = ColumnExports;
const columnSelected = ref([] as any);

onMounted(() => {
  columnList.forEach((el) => {
    if (el.value !== '') {
      columnSelected.value.push(el.value);
    }
  });
  console.log('File: ModalSelectColumns.vue - L: 17 - ', columnSelected.value);
});

const onSelectColumns = (col) => {
  if (col.value == '') return;
  if (columnSelected.value.some((e) => e == col.value)) {
    columnSelected.value = columnSelected.value.filter((o) => o !== col.value);
  } else {
    columnSelected.value.push(col.value);
  }
};
</script>

<template>
  <SynModal
    is-hidden-header
    disable-click-outside
    z-index="z-60"
    container-class="w-1/5"
    style-body="p-3 flex flex-col"
    @cancel="$emit('onClose')"
  >
    <template #body>
      <div class="px-3 pt-5 flex flex-col space-y-2">
        <span class="text-gray-500">{{
          $t('TASK_EXPORT_SELECT_COLUMNS')
        }}</span>
        <div class="grid grid-rows-2 grid-flow-col gap-2">
          <div
            v-for="item in columnList"
            :key="item.value"
            class="flex items-center space-x-2 px-1"
          >
            <SynIcon
              name="checkbox"
              :class="
                item.value == ''
                  ? 'cursor-not-allowed fill-current-100'
                  : 'cursor-pointer fill-current'
              "
              :is-active="
                columnSelected.some((e) => e == item.value) || item.value == ''
              "
              custom-class="w-4 h-4"
              @click="onSelectColumns(item)"
            />
            <span
              :class="
                columnSelected.some((e) => e == item.value) || item.value == ''
                  ? 'text-current-500'
                  : 'text-gray-500'
              "
              >{{ $t(item.text) }}</span
            >
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          type-outline
          :label="$t('COMMON_LABEL_CANCEL')"
          @click="$emit('onClose')"
        />
        <SynButton
          :label="$t('COMMON_LABEL_EXPORT_FILE')"
          :is-loading="isLoading"
          @click="$emit('onSave', columnSelected)"
        />
      </div>
    </template>
  </SynModal>
</template>
