<script setup lang="ts">
import { onMounted, ref } from 'vue';
import DepartmentRemoveModal from '@/ui/modules/departments/components/DepartmentRemoveModal.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import DepartmentGenerateGroupModal from '@/ui/modules/departments/components/DepartmentGenerateGroupModal.vue';
import UserById from '@/ui/components/user/UserById.vue';
import { GroupMemberRole } from '@/ui/common/constants/constant';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';

const props = defineProps<{
  settings: any;
  departmentId?: any;
  departmentName?: any;
  relatedGroup?: any;
  members?: any[];
}>();

const emit = defineEmits<{
  (e: 'updateSettings', data: any): void;
  (e: 'update:members', data: any): void;
  (e: 'deleteSuccess', data: any): void;
}>();

onMounted(() => {
  console.log('🚀 Hyrin ~ members:', props.settings);
});
const settingPayload = ref<{
  visibleForAllMember: boolean;
  visibleStatusWorking: boolean;
  visibleTimeWorking: boolean;
  visiblePersonalData: boolean;
}>({
  visibleForAllMember: !!props.settings?.visibleForAllMember,
  visibleStatusWorking: !!props.settings?.visibleStatusWorking,
  visibleTimeWorking: !!props.settings?.visibleTimeWorking,
  visiblePersonalData: !!props.settings?.visiblePersonalData,
});

const showDeleteGroup = ref(false);
const showGenerateGroup = ref(false);

const showModalRemoveGroup = () => {
  showDeleteGroup.value = true;
};

const showModalGenerateGroup = () => {
  showGenerateGroup.value = true;
};

const onToggleVisible = (value: boolean) => {
  console.log('🚀 Hyrin ~ onToggleVisible ~ value', value);

  settingPayload.value = {
    visibleForAllMember: !!value,
    visibleStatusWorking: !!value,
    visibleTimeWorking: false,
    visiblePersonalData: !!value,
  };
  emit('updateSettings', settingPayload.value);
};

const onChangeVisibleDetail = () => {
  emit('updateSettings', settingPayload.value);
};

const onChangeMemberRole = (user, role) => {
  const newMembers = [...(props.members || [])];

  const newRole = role ? GroupMemberRole.Admin : GroupMemberRole.User;

  const index = newMembers?.findIndex((o) => o.userId == user?.id);
  if (index >= 0) newMembers[index].role = newRole;
  emit('update:members', newMembers);
};
</script>

<template>
  <div class="flex flex-col p-2 space-y-8">
    <!--    RELATED GROUP-->
    <div v-if="departmentId" class="px-4 py-3 bg-white border-b space-x-4">
      <div class="flex items-start space-x-3">
        <div class="flex items-center">
          <SynIcon
            name="groups"
            is-active
            class="fill-current"
            custom-class="w-5 h-5"
          />
        </div>
        <div class="flex flex-col flex-1 space-y-1">
          <span>{{ $t('DEPARTMENT_LABEL_RELATED_GROUP') }}</span>
          <div>
            <GroupById
              v-if="relatedGroup"
              avatar-class="w-8 h-8"
              :group-id="relatedGroup.id"
              :group-name="relatedGroup.name"
              :group-avatar="relatedGroup.avatarUrl"
            />
            <div v-else class="flex flex-col w-full py-2">
              <SynButton
                class="text-sm"
                custom-class-icon="h-3 w-3"
                name-icon="plus"
                :label="$t('DEPARTMENT_LABEL_GENERATE_GROUP')"
                @click="showModalGenerateGroup()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SETTING PRIVATE -->
    <MoleculeSettingCard>
      <template #header>
        <div class="flex items-center justify-between">
          <div class="flex items-start space-x-3">
            <div class="flex-center mt-1">
              <SynIcon name="Eye" class="fill-current" custom-class="w-5 h-5" />
            </div>
            <div class="flex flex-wrap">
              <span>{{ $t('DEPARTMENT_SETTING_VISIBLE') }}</span>
              <span class="text-xs text-gray-600 w-full"
                >{{ $t('DEPARTMENT_SETTING_VISIBLE_SUB') }}
              </span>
            </div>
          </div>
          <AtomSwitch
            v-model="settingPayload.visibleForAllMember"
            @change="onToggleVisible"
          />
        </div>
      </template>
      <template #body>
        <!-- VISIBLE DETAIL -->
        <div
          v-if="settingPayload.visibleForAllMember"
          class="w-full flex items-start flex-col space-y-2"
        >
          <!-- WORKING STATUS -->
          <div class="w-full flex items-center justify-between">
            <div class="flex-center space-x-2">
              <span class="text-sm italic">
                {{ $t('DEPARTMENT_SETTING_VISIBLE_DETAIL_WORKING_STATUS') }}
              </span>
              <AtomHelpDropdown
                title="DEPARTMENT_SETTING_VISIBLE_DETAIL_WORKING_STATUS"
                content="DEPARTMENT_SETTING_VISIBLE_DETAIL_WORKING_STATUS_DESCRIPTION"
              />
            </div>
            <AtomSwitch
              v-model="settingPayload.visibleStatusWorking"
              size="small"
              @change="onChangeVisibleDetail"
            />
          </div>
          <!-- WORKING TIME  -->
          <div class="w-full flex items-center justify-between">
            <div class="flex-center space-x-2">
              <span class="text-sm italic">
                {{ $t('DEPARTMENT_SETTING_VISIBLE_DETAIL_WORKING_TIME') }}
              </span>
              <AtomHelpDropdown
                title="DEPARTMENT_SETTING_VISIBLE_DETAIL_WORKING_TIME"
                content="DEPARTMENT_SETTING_VISIBLE_DETAIL_WORKING_TIME_DESCRIPTION"
              />
            </div>

            <AtomSwitch
              v-model="settingPayload.visibleTimeWorking"
              size="small"
              @change="onChangeVisibleDetail"
            />
          </div>
          <!-- PERSONAL DATA -->
          <div class="w-full flex items-center justify-between">
            <div class="flex-center space-x-2">
              <span class="text-sm italic">
                {{ $t('DEPARTMENT_SETTING_VISIBLE_DETAIL_PERSONAL_DATA') }}
              </span>
              <AtomHelpDropdown
                title="DEPARTMENT_SETTING_VISIBLE_DETAIL_PERSONAL_DATA"
                content="DEPARTMENT_SETTING_VISIBLE_DETAIL_PERSONAL_DATA_DESCRIPTION"
              />
            </div>

            <AtomSwitch
              v-model="settingPayload.visiblePersonalData"
              size="small"
              @change="onChangeVisibleDetail"
            />
          </div>
        </div>
      </template>
    </MoleculeSettingCard>
    <!-- Choose admin when create -->
    <MoleculeSettingCard v-if="!departmentId && members && members?.length > 0">
      <template #header>
        <div class="flex items-start space-x-3">
          <div class="flex-center mt-1">
            <SynIcon
              name="Groups"
              class="fill-current"
              custom-class="w-5 h-5 "
            />
          </div>
          <div class="flex flex-wrap">
            <span
              v-html="
                $t('DEPARTMENT_SETTING_MEMBERS_TITLE', {
                  departmentName: departmentName,
                })
              "
            >
            </span>
            <span class="text-xs text-gray-600 w-full">
              {{ $t('DEPARTMENT_SETTING_MEMBERS_TITLE_SUB') }}
            </span>
          </div>
        </div>
      </template>
      <template #body>
        <div
          v-for="user in members"
          :key="user?.id"
          class="w-full flex items-center justify-between"
        >
          <div
            class="
              w-full
              flex
              justify-between
              items-center
              p-2
              custom-display
              bg-current-50 bg-opacity-50
              rounded-md
            "
          >
            <UserById
              :user-id="user.userId"
              class="flex-1 text-sm"
              avatar-class="w-8 h-8"
            >
              <template #subName>
                <div
                  v-if="user.departmentInfos?.length > 0"
                  class="flex-center space-x-1"
                >
                  <DepartmentById
                    :department-id="user.departmentInfos[0]?.id"
                    is-hidden-avatar
                    class="text-xs text-gray-500"
                  />
                  <span
                    v-if="
                      user.departmentInfos[0]?.role == GroupMemberRole.Admin
                    "
                    class="text-xs text-gray-500"
                  >
                    ({{ $t('COMMON_LABEL_ADMIN') }})
                  </span>
                </div>
              </template>
            </UserById>

            <AtomSwitch
              :model-value="user?.role == GroupMemberRole.Admin"
              size="small"
              @change="onChangeMemberRole(user, $event)"
            />
          </div>
        </div>
      </template>
    </MoleculeSettingCard>
    <!-- SETTING DELETE -->
    <div
      v-if="departmentId"
      class="
        px-4
        py-3
        flex
        items-center
        justify-between
        bg-white
        border-b
        space-x-4
      "
    >
      <div class="flex items-start space-x-3">
        <div class="flex-center mt-1">
          <SynIcon name="trash" class="fill-current" custom-class="w-5 h-5" />
        </div>
        <div class="flex flex-wrap">
          <span>{{ $t('DEPARTMENT_LABEL_REMOVE') }}</span>
          <span class="text-xs text-gray-600 w-full">
            {{ $t('DEPARTMENT_LABEL_REMOVE_SUB') }}
          </span>
        </div>
      </div>
      <div>
        <SynButton
          class="text-sm"
          color="red"
          :label="$t('DEPARTMENT_LABEL_REMOVE')"
          type-outline
          @click="showModalRemoveGroup()"
        />
      </div>
    </div>
  </div>

  <DepartmentRemoveModal
    v-if="showDeleteGroup"
    :department-id="departmentId"
    :department-name="departmentName"
    @cancel="showDeleteGroup = false"
    @deleted-success="$emit('deleteSuccess')"
  />

  <DepartmentGenerateGroupModal
    v-if="showGenerateGroup"
    :department-id="departmentId"
    @cancel="showGenerateGroup = false"
    @submit-generate-group="showGenerateGroup = false"
  />
</template>
