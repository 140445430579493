<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { downloadFileWithProgress } from '@/ui/plugins/axios/download';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import JSZip from 'jszip';
import { getFileNameFromPath } from '@/ui/hooks/fileHook';

const props = defineProps<{
  files: any[];
  isCreateZip?: boolean;
  zipFileName?: string;
}>();

const emit = defineEmits(['onFinished', 'onCancel']);

const downloadProcesses: any[] = [];
let zipStream: any;
let isCancelled = false;

const downloadedByFile = ref<{ [fileId: string]: number }>({});
const zipProcessing = ref<boolean>(false);
const zippedPercent = ref<number>(0);

const downloadedPercent = computed<number>(() => {
  const total = (props.files?.length || 0) * 100;
  const completed = props.files?.reduce(
    (total, file) =>
      (total += downloadedByFile.value[file?.id || file?.fileId] || 0),
    0
  );

  return Math.round(((completed || 0) / (total || 1)) * 100);
});

onMounted(async () => {
  if (!props.files?.length) return;

  try {
    let results = await Promise.all(
      props.files.map((file) => _downloadFile(file))
    );

    results = results?.filter((file) => file);

    if (isCancelled || !results?.length) return emit('onFinished');

    if (props.isCreateZip) {
      zipProcessing.value = true;

      const zipFile = await _zipFiles(results);

      emit('onFinished', [zipFile]);
    } else {
      emit('onFinished', results);
    }
  } catch (e) {
    console.log(e);
    settingGlobalModal({
      type: 'notification',
      title: '',
      content: translate('COMMON_DOWNLOAD_FILE_ERROR'),
      confirmable: true,
    });
    await ask();

    emit('onFinished');
  }

  zipProcessing.value = false;
});

const onCancelClick = () => {
  _cancelDownload();

  emit('onCancel');
};

const _downloadFile = (file) => {
  return new Promise((resolve, reject) => {
    const fileId = file?.id || file?.fileId;

    const fileUrl =
      file?.fileUrl || file?.pathUrl || file?.url_full || file?.imagePath;

    if (!fileUrl) throw 'Cannot get file url';

    const fileName =
      file?.name || file?.fileName || getFileNameFromPath(fileUrl) || `file`;

    const process = downloadFileWithProgress(fileUrl, (blob, error) => {
      if (error) {
        _cancelDownload();
        return reject(error);
      }

      resolve(blob ? new File([blob], fileName, { type: blob?.type }) : null);
    });

    watch(
      () => process.value,
      (p) => (downloadedByFile.value[fileId] = p?.completedPercent)
    );

    downloadProcesses.push(process.value);
  });
};

const _cancelDownload = () => {
  isCancelled = true;

  downloadProcesses.forEach((process) => {
    process?.cancelToken?.cancel();
  });

  if (zipStream) zipStream.pause();
};

const _zipFiles = async (files: any[]) => {
  return new Promise((resolve, reject) => {
    const zip = new JSZip();

    let totalSize = 0;
    for (let file of files) {
      totalSize += file?.size || 0;

      const fileName = _genFileNameIfDuplicate(file.name, zip);
      zip.file(fileName, file);
    }

    zipStream = zip.generateInternalStream({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 1 },
      streamFiles: true,
    });

    const parts: any[] = [];
    let zippedSize = 0;

    zipStream.on('data', (chunk) => {
      parts.push(chunk);

      zippedSize += chunk?.length || 0;
      zippedPercent.value = totalSize
        ? Math.round((zippedSize / totalSize) * 100)
        : 0;
    });

    zipStream.on('end', () => {
      zippedPercent.value = 100;

      const zipBlob = new Blob(parts, { type: 'application/zip' });

      resolve(
        new File(
          [zipBlob],
          `${props.zipFileName}.zip` || `files-${new Date().getTime()}.zip`
        )
      );
    });

    zipStream.on('error', (error) => {
      zipStream.pause();

      reject(error);
    });

    zipStream.resume();
  });
};

const _genFileNameIfDuplicate = (
  fileName: string,
  zip: JSZip,
  originFileName: string = '',
  index: number = 0
) => {
  if (zip?.files && zip?.files[fileName]) {
    let texts = (originFileName || fileName)?.split('.');
    if (texts?.length > 1) texts[texts?.length - 2] += `(${++index})`;
    if (texts?.length === 1) texts[texts?.length - 1] += `(${++index})`;
    if (!texts?.length) return fileName;

    const newFileName = texts?.join('.');
    return _genFileNameIfDuplicate(
      newFileName,
      zip,
      originFileName || fileName,
      index
    );
  }

  return fileName;
};
</script>

<template>
  <div class="flex items-center gap-4">
    <div v-if="zipProcessing" class="flex-center">
      <SynIcon
        name="Processing"
        custom-class="w-6 h-6 text-current animate-spin"
      />
    </div>
    <div v-else class="flex-center">
      <SynIcon
        name="Download"
        custom-class="w-6 h-6 fill-current animate-bounce"
      />
    </div>

    <div class="flex-1">
      <div class="flex items-end justify-between mb-1">
        <span
          v-html="
            $t(
              zipProcessing
                ? 'COMMON_COMPRESSING_NUM_FILES'
                : 'COMMON_DOWNLOADING_NUM_FILES',
              { number: files?.length || 0 }
            )
          "
        ></span>
        <span class="text-current-600"
          >{{ zipProcessing ? zippedPercent : downloadedPercent }}%</span
        >
      </div>
      <div class="h-2 rounded-full bg-gray-100 overflow-hidden shadow">
        <div
          class="h-full rounded-full bg-current"
          :style="{
            width: (zipProcessing ? zippedPercent : downloadedPercent) + '%',
          }"
        ></div>
      </div>
    </div>

    <div class="flex-center">
      <VigButton
        light
        color="gray"
        rounded="rounded-full"
        padding="px-4 py-1.5"
        @click="onCancelClick"
      >
        <span class="font-normal">{{ $t('COMMON_LABEL_CANCEL') }}</span>
      </VigButton>
    </div>
  </div>
</template>

<style scoped></style>
