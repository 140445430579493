<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';

const props = defineProps({
  link: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['onClosePreviewLink']);

const objectLink = computed(() => props.link);

onMounted(async () => {
  // console.log('link', props.link);
});

const getDomainLink = (links) => {
  if (!links) return;
  let domain = new URL(links);
  return domain.hostname.replace('www.', '');
};

const onClosePreviewLink = (link, e) => {
  e.preventDefault();
  emit('onClosePreviewLink', link);
};

const isImageError = ref(false);
const onChangeImageError = () => {
  isImageError.value = true;
};
</script>

<template>
  <template v-if="!objectLink?.isLoading">
    <a
      v-if="objectLink?.previewData?.isPreview"
      :href="objectLink?.link"
      target="_blank"
      class="
        border
        bg-white
        flex
        align-center
        rounded
        h-16
        my-0.5
        relative
        min-w-xxs
        max-w-xs
        w-64
      "
    >
      <div class="w-16 h-full p-1 rounded">
        <img
          v-if="objectLink?.previewData?.imageUrl && !isImageError"
          :src="objectLink?.previewData?.imageUrl"
          :onError="onChangeImageError"
          class="object-cover w-full h-full rounded"
        />
        <div
          v-else
          class="bg-gray-200 bg-opacity-60 w-full h-full rounded flex-center"
        >
          <syn-icon name="image-thumb" custom-class="w-8 h-8"></syn-icon>
        </div>
      </div>
      <div class="flex flex-col w-3/4 py-1 pr-2 pl-0.5">
        <div
          :title="
            objectLink?.previewData?.title || objectLink?.previewData?.link
          "
          class="font-medium truncate text-sm"
        >
          {{ objectLink?.previewData?.title || objectLink?.link }}
        </div>
        <div
          v-if="objectLink?.previewData?.description"
          :title="objectLink?.previewData?.description"
          class="text-gray-600 text-xs truncate"
        >
          {{ objectLink?.previewData?.description }}
        </div>
        <div class="flex space-x-1 items-center pt-1">
          <syn-icon
            name="link"
            custom-class="w-2.5 h-2.5 fill-gray-500"
          ></syn-icon>
          <span class="text-gray-500 text-2xs align-bottom truncate">
            {{
              objectLink?.previewData?.domain ||
              getDomainLink(objectLink?.previewData?.link)
            }}
          </span>
        </div>
      </div>
      <div
        :title="$t('CHAT_HIDE_PREVIEW_LINK')"
        class="
          absolute
          flex-center
          -right-2
          -top-2
          p-1
          m-1
          rounded-full
          bg-gray-400
          fill-white
          text-white
          cursor-pointer
        "
        @click.stop="($event) => onClosePreviewLink(objectLink?.link, $event)"
      >
        <SynIcon name="Close" custom-class="w-3 h-3" />
      </div>
    </a>
  </template>

  <template v-else>
    <div
      class="
        border
        bg-white
        flex
        align-center
        rounded
        h-16
        my-0.5
        min-w-xxs
        max-w-xs
        w-64
        relative
      "
    >
      <div class="w-16 p-1 rounded">
        <div class="bg-gray-200 w-full h-full rounded flex-center">
          <syn-icon
            name="spinner"
            custom-class="w-4 h-4 fill-gray-500 text-gray-500 animate-spin"
          ></syn-icon>
        </div>
      </div>

      <div class="flex flex-col w-3/4 space-y-1 py-1 pr-2 pl-0.5">
        <div class="text-gray-600 text-xs text-overflow-hidden">
          {{ objectLink?.link }}
        </div>
        <div class="flex space-x-1 items-center pt-1">
          <syn-icon
            name="link"
            custom-class="w-2.5 h-2.5 fill-gray-500"
          ></syn-icon>
          <span class="text-gray-500 text-2xs align-bottom truncate">
            {{ link?.domain || getDomainLink(link?.link) }}
          </span>
        </div>
      </div>
      <div
        :title="$t('CHAT_HIDE_PREVIEW_LINK')"
        class="
          absolute
          flex-center
          -right-2
          -top-2
          p-1
          m-1
          rounded-full
          bg-gray-400
          fill-white
          text-white
          cursor-pointer
        "
        @click.stop="($event) => onClosePreviewLink(objectLink?.link, $event)"
      >
        <SynIcon name="Close" custom-class="w-3 h-3" />
      </div>
    </div>
  </template>
</template>
