<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import chatStore from '@/store/chat';
import myProfileStore from '@/store/auth/my-profile';
import { chain, orderBy } from 'lodash';
import ConversationItem from '../conversation-item/ConversationItem.vue';
import favoriteStore from '@/store/favorite/favoriteStore';
import { genGroupConversationId } from '@/ui/plugins/firebases/firestore-database/constants';
import getGroupInfo from '@/ui/helpers/group-helper';
import userStore from '@/store/user';
import dashboardStore from '@/store/dashboard';
import { ignoreUnicode } from '@/ui/plugins/utils';
import groupStore from '@/store/group';
import { AdminType } from '@/ui/common/constants/constant';
import getUserInfo from '@/ui/helpers/user-helper';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import { markTextSearch } from '@/ui/hooks/commonFunction';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
import permissionStore from '@/store/permission';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';
import GroupChatAboutTask from '@/ui/modules/task/detail/task-chat-about/GroupChatAboutTask.vue';
import systemConfig from '@/application/constants/system-config.const';
import ModalNewGroupChat from '@/ui/modules/messaging/modal-new-group-chat/ModalNewGroupChat.vue';

const props = defineProps<{
  isChatPage?: boolean;
  attachedTask?: any;
  attachedDayoff?: any;
  attachedEvent?: any;
  isShowClose?: boolean;
  isBlockBubbleChat?: boolean;
  isFocusInputSearch?: boolean;
  isOpenDropdown?: boolean;
  isShowViewAll?: boolean;
}>();

const emit = defineEmits([
  'success',
  'close',
  'openSendFast',
  'focusInput',
  'onChatAboutTask',
]);

const _chatStore = chatStore();
const _favoriteStore = favoriteStore();
const _userStore = userStore();
const _dashboardStore = dashboardStore();
const _groupStore = groupStore();
const _permissionStore = permissionStore();

const isHovered = ref(false);
const isCreateGroupChat = ref(false);
const groupChatHasNewMessage = ref();
const myProfile = computed(() => myProfileStore().myProfile);
const members = computed(() => _userStore.allUserByIds);
const conversationById = computed(() => _chatStore.conversationById);
const userConversationIds = computed(() => {
  if (props.isChatPage) return [];
  return orderBy(
    _chatStore.userConversationIds?.filter(
      (conversId) =>
        !conversationById.value[conversId]?.deleted &&
        !conversationById.value[conversId]?.closed &&
        conversationById.value[conversId]?.name
    ),
    [
      (conversId) =>
        conversationById.value[conversId]?.lastMessageTime ||
        conversationById.value[conversId]?.createdDate ||
        0,
    ],
    ['desc']
  );
});
const pinnedConversationIds = computed(() => {
  if (props.isChatPage) return [];
  return orderBy(
    userConversationIds.value?.filter(
      (conversId) => conversationById.value[conversId]?.pinned
    ),
    [(conversId) => conversationById.value[conversId]?.pinnedIndex],
    ['asc']
  );
});

const { goToConversation } = useChatCommon();

onMounted(() => {
  currentTab.value = menuList.value?.length ? menuList.value[0]?.key : null;
  focusInputSearch();
  // console.log('new Chat userConversationIds', userConversationIds.value);
});

const onOpenConversation = async (conversation) => {
  if (!conversation || !conversation?.id) return;
  // if (props.isBlockBubbleChat) {
  //   emit('openSendFast', {
  //     conversationId: conversation?.id,
  //     type: 'GROUP',
  //     contactId: groupId
  //   });
  //   return;
  // }

  const discardAndContinue = await _chatStore.handleCheckFormHaveAction();
  if (!discardAndContinue) return;

  goToConversation(conversation);

  // if (!isHasChatWidget.value) {
  //   activeAndFocusOnConversationId.value = `${conversation?.id}`;
  //
  //   _chatStore.activeBubbleChat(conversation);
  //   _chatStore.setConversationActive(conversation);
  //   router.push({
  //     name: 'Messages',
  //     params: { conversationId: conversation.id }
  //   });
  // }
  // else if (route?.path?.includes('/messages')) {
  //   activeAndFocusOnConversationId.value = `${conversation?.id}`;
  //
  //   _chatStore.activeBubbleChat(conversation);
  //   _chatStore.setConversationActive(conversation);
  //   router.replace({ params: { conversationId: conversation.id } });
  // }
  // else {
  //   _chatStore.addBubbleChat({
  //     id: conversation.id,
  //     name: conversation.name,
  //     avatar: conversation.avatar
  //   });
  //
  //   isForceActive.value = true;
  // }

  if (props.attachedTask?.id) {
    setTimeout(() => _chatStore.setChatAboutTask(props.attachedTask));
  }
  if (props.attachedDayoff?.id) {
    setTimeout(() => _chatStore.setChatAboutDayoff(props.attachedDayoff));
  }
  if (props.attachedEvent?.id) {
    setTimeout(() => _chatStore.setChatAboutEvent(props.attachedEvent));
  }

  emit('success');
};

const onContactClick = async (contact, isGroup) => {
  if (!contact || !myProfile?.value?.id) return;

  const verifyContact = {
    ...contact,
    isGroup,
    id: isGroup ? contact.id : contact.userId || contact.id,
  };

  const conversation = _chatStore.verifyExistedConversationOfContact(
    verifyContact,
    isGroup
  );

  if (props.isBlockBubbleChat) {
    emit('openSendFast', {
      conversationId: conversation?.id,
      type: isGroup ? 'GROUP' : 'USER',
      contactId: contact.userId || contact.id || myProfile.value.id,
    });
    return;
  }

  await onOpenConversation(conversation);

  // emit('success');
};

const onChooseResult = () => {
  const contact = resultSearch.value[currentIndex.value];
  if (!contact || !contact?.id) return;

  onContactClick(contact, contact?.isGroup);
};

const conferenceMenuList = {
  PINNED: {
    key: 'pinned',
    label: 'Pinned',
    labelKey: 'COMMON_LABEL_PIN',
  },
  HISTORY: {
    key: 'histories',
    label: 'History',
    labelKey: 'COMMON_LABEL_RECENTLY',
  },
  FAVORITES: {
    key: 'favorites',
    label: 'Favorites',
    labelKey: 'SIDEBAR_LABEL_MY_FAVORITES',
  },
  MEMBERS: {
    key: 'members',
    label: 'Members',
    labelKey: 'TASK_TABLE_LABEL_MEMBERS',
  },
  GROUPS: {
    key: 'groups',
    label: 'Groups',
    labelKey: 'COMMON_LABEL_GROUPS',
  },
};

const searchText = ref(null) as any;
const isSearching = ref(false);
const totalContact = computed(
  () => filteredUsers.value?.length + allMyGroups.value?.length
);

const currentIndex = ref(0);
const resultSearch = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);
  if (!searchTxt) return [...filteredUsers.value, ...allMyGroups.value];
  return [
    ...(filteredUsers.value || []).filter(
      (user: any) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
    ),
    ...(allMyGroups.value || [])
      .filter(
        (group) =>
          group?.isMember &&
          group?.name &&
          ignoreUnicode(group?.name).includes(searchTxt)
      )
      .map((o) => {
        return {
          ...o,
          isGroup: true,
        };
      }),
  ];
}) as any;

const onBlurSearchBox = () => {
  if (!searchText.value) isSearching.value = false;
};

// Favorites
const myFilteredFavorites = computed(() => {
  return _favoriteStore.getMyFavorites
    ?.filter((favorite) => {
      return favorite?.type !== 'domain' && favorite?.id;
    })
    .reduce((currentResult, currentItem) => {
      const isExisted = currentResult?.some((o) => o?.id == currentItem?.id);
      return isExisted ? currentResult : [...currentResult, currentItem];
    }, []);
}) as any;

const onFavoriteClick = (favorite) => {
  if (favorite?.favoriteType == 'member') {
    onContactClick(favorite, false);
  } else {
    onContactClick(favorite, true);
  }
  // if (!props.isBlockBubbleChat) emit('success');
};

// MEMBER
const filteredUsers = computed(() => {
  const allUsers = _userStore.getDefaultUsers.map((user) => {
    return {
      ...user,
      callStatus: _dashboardStore.getUserStatus(user.id),
    };
  });
  if (!hasRolePermission(AdminType.Visitor)) return allUsers;
  if (
    hasRolePermission(AdminType.Visitor) &&
    props.attachedTask?.id &&
    props.attachedTask?.groupId &&
    Object.prototype.hasOwnProperty.call(
      allGroupByIds.value,
      props.attachedTask?.groupId
    )
  ) {
    return allGroupByIds.value[props.attachedTask?.groupId].groupUsers || [];
  }
  return allUsers;
});
const filteredCompactUsers = computed(() => {
  return isHovered.value
    ? filteredUsers.value
    : filteredUsers.value?.slice(0, 10);
});

// Group
const allMyGroups = computed(() =>
  chain(_groupStore.allMyGroupsIncludeChat)
    .unionBy('id')
    .filter((o) => o?.isMember)
    .value()
);
const allMyGroupsCompact = computed(() =>
  isHovered.value ? allMyGroups.value : allMyGroups.value?.slice(0, 10)
);
const allGroupByIds = computed(() => _groupStore.allGroupByIds);

const currentTab = ref();
const menuList = computed(() => {
  if (permissionStore().organizationModel == 'PERSONAL') return [];
  let data = [] as any;
  if (pinnedConversationIds.value?.length && !props.isChatPage) {
    data = [...data, conferenceMenuList?.PINNED];
  }
  if (userConversationIds.value?.length && !props.isChatPage) {
    data = [...data, conferenceMenuList?.HISTORY];
  }
  if (myFilteredFavorites.value?.length) {
    data = [...data, conferenceMenuList?.FAVORITES];
  }
  if (filteredUsers.value?.length) {
    data = [...data, conferenceMenuList?.MEMBERS];
  }
  if (allMyGroups.value?.length) data = [...data, conferenceMenuList?.GROUPS];
  return data;
});

const onChatAboutTaskByUserId = (userId) => {
  if (!userId) return;

  const conversationId = _chatStore.checkExistedConversation(userId);

  const userInfo = getUserInfo(userId);
  if (!userInfo?.name) return;

  if (props.isBlockBubbleChat) {
    emit('openSendFast', {
      conversationId: conversationId,
      type: 'USER',
      contactId: userId,
    });
    return;
  }

  onOpenConversation({
    id: conversationId,
    name: userInfo.name,
    avatar: userInfo.avatarUrl || userInfo.avatar,
    contactId: userId,
  });
};
const onChatAboutTaskWithGroup = async (groupId) => {
  if (!groupId) return;

  const conversationId = genGroupConversationId(groupId);

  const groupInfo = await _groupStore.getDetailGroupById(groupId);

  if (props.isBlockBubbleChat) {
    emit('openSendFast', {
      conversationId: conversationId,
      type: 'GROUP',
      contactId: groupId,
    });
    return;
  }

  onOpenConversation({
    id: conversationId,
    name: groupInfo.name,
    avatar: groupInfo.avatarUrl || groupInfo.avatar,
    contactId: groupId,
  });
};

const isShowDayoffRequester = computed(() => {
  return (
    props.attachedDayoff?.userId &&
    props.attachedDayoff?.userId !== myProfile.value?.id
  );
});
const isShowGroupChatAboutTask = computed(
  () =>
    !!props.attachedTask &&
    _permissionStore.allSystemConfigs &&
    _permissionStore.allSystemConfigs[
      systemConfig.ADVANCED_CHAT_GROUP_CHAT_ABOUT_TASK_ALLOWED
    ] === '1'
);
const isShowAssigneeUser = computed(() => {
  return (
    props.attachedTask?.assigneeId &&
    props.attachedTask?.assigneeId !== myProfile.value?.id
  );
});
const isShowCreator = computed(() => {
  return (
    props.attachedTask?.creatorId !== props.attachedTask?.assigneeId &&
    props.attachedTask?.creatorId !== myProfile.value?.id
  );
});

const focusInputSearch = () => {
  if (props.isFocusInputSearch) inputSearchChat.value?.focus();
};

document.addEventListener('keydown', (e) => {
  if (e.ctrlKey && e.keyCode === 81) inputSearchChat.value?.focus();
});

const inputSearchChat = ref();
</script>
<template>
  <div
    v-if="
      isShowGroupChatAboutTask ||
      isShowAssigneeUser ||
      isShowCreator ||
      attachedTask?.groupId ||
      isShowDayoffRequester
    "
    ref="myHoverableElement"
    class="flex flex-wrap items-center gap-2 px-2 mb-2"
  >
    <GroupChatAboutTask
      v-if="isShowGroupChatAboutTask"
      v-model:has-new-message="groupChatHasNewMessage"
      :current-task="attachedTask"
      @on-start-chat="$emit('success')"
      @on-create-group-chat="isCreateGroupChat = true"
    >
      <SynTag
        class="
          bg-current-50
          hover:bg-current-100 hover:shadow
          cursor-pointer
          space-x-2
          rounded-full
          text-sm
          w-max
        "
      >
        <div class="relative flex-center">
          <SynIcon name="Groups" class="fill-gray-500" />
          <div
            v-if="groupChatHasNewMessage"
            class="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full"
          ></div>
        </div>
        <span class="pr-5">{{ $t('CHAT_GROUP_ABOUT_THIS_TASK') }}</span>
      </SynTag>
    </GroupChatAboutTask>
    <SynTag
      v-if="isShowAssigneeUser"
      class="
        bg-current-50
        hover:bg-current-100 hover:shadow
        cursor-pointer
        space-x-2
        rounded-full
        text-sm
        w-max
      "
      @click="onChatAboutTaskByUserId(attachedTask?.assigneeId)"
    >
      <span class="text-gray-600 text-xs">
        {{ $t('TASK_LABEL_ASSIGNEE') || 'Responsible' }}
      </span>
      <UserById :user-id="attachedTask?.assigneeId" class="font-medium" />
    </SynTag>
    <SynTag
      v-if="
        attachedTask?.groupId && getGroupInfo(attachedTask?.groupId)?.isMember
      "
      class="
        bg-current-50
        hover:bg-current-100 hover:shadow
        cursor-pointer
        space-x-2
        rounded-full
        text-sm
        w-max
      "
      @click="onChatAboutTaskWithGroup(attachedTask?.groupId)"
    >
      <span class="text-gray-600 text-xs">
        {{ $t('COMMON_LABEL_GROUP') || 'Group' }}
      </span>
      <GroupById :group-id="attachedTask?.groupId" class="font-medium" />
    </SynTag>
    <SynTag
      v-if="isShowCreator"
      class="
        bg-current-50
        hover:bg-current-100 hover:shadow
        cursor-pointer
        space-x-2
        rounded-full
        text-sm
        w-max
      "
      @click="onChatAboutTaskByUserId(attachedTask?.creatorId)"
    >
      <span class="text-gray-600 text-xs">
        {{ $t('TASK_TABLE_LABEL_CREATOR') || 'Creator' }}
      </span>
      <UserById :user-id="attachedTask?.creatorId" class="font-medium" />
    </SynTag>
    <SynTag
      v-if="isShowDayoffRequester"
      class="
        bg-current-50
        hover:bg-current-100 hover:shadow
        cursor-pointer
        space-x-2
        rounded-full
        text-sm
        w-1/2
      "
      @click="onChatAboutTaskByUserId(attachedDayoff?.userId)"
    >
      <span>
        {{ $t('DAYOFF_REQUEST_REQUESTER') || 'Requester' }}
      </span>
      <UserById :user-id="attachedDayoff?.userId" />
    </SynTag>
  </div>

  <SynTabScroll v-model="currentTab" :menu-list="menuList">
    <template v-if="totalContact > 12" #prefix-header>
      <div
        v-permission-model
        class="py-3 px-4 flex-center space-x-4"
        :class="
          searchText ? 'bg-gradient-to-r from-current-300 to-teal-400' : ''
        "
      >
        <VigSearchBox
          ref="inputSearchChat"
          v-model="searchText"
          v-model:current-index="currentIndex"
          :total-result="resultSearch?.length"
          class="w-full"
          input-class="text-sm"
          autofocus
          @blur="onBlurSearchBox"
          @enter="onChooseResult"
        />
        <div v-if="isShowClose" class="dropdown-item">
          <SynIcon
            name="close"
            custom-class="w-4 h-4 fill-gray-500 text-gray-500"
            color="gray"
            has-action
            @click="$emit('close')"
          />
        </div>
      </div>
    </template>
    <template #prefix>
      <div
        v-permission-model
        class="flex-center space-x-1 justify-end absolute right-0 py-3 mt-2"
        :class="isSearching ? 'w-full' : 'px-4'"
      >
        <div
          v-if="isSearching && totalContact < 13"
          class="bg-white px-3 py-3 flex-1 w-full"
          :class="
            searchText ? 'bg-gradient-to-r from-current-300 to-teal-400' : ''
          "
        >
          <VigSearchBox
            v-model="searchText"
            v-model:current-index="currentIndex"
            :total-result="resultSearch?.length"
            class="w-full"
            input-class="text-sm"
            autofocus
            @blur="onBlurSearchBox"
            @enter="onChooseResult"
          />
        </div>
        <SynIcon
          v-if="!isSearching && totalContact < 13"
          name="search"
          has-action
          @click="isSearching = true"
        />
        <!-- <router-link v-if="!isChatPage" :to="{ name: 'Messages' }">
          <SynIcon
            has-action
            name="OpenLink"
            custom-class="h-5 w-5 fill-current dropdown-item"
          />
        </router-link> -->
      </div>
    </template>
    <template #body>
      <div
        class="flex-1 overflow-auto small-scrollbar pb-3"
        style="scroll-behavior: smooth"
        @mouseover="isHovered = true"
      >
        <template
          v-if="
            (userConversationIds?.length <= 0 &&
              myFilteredFavorites?.length <= 0 &&
              filteredUsers?.length <= 0 &&
              allMyGroups?.length <= 0) ||
            (searchText && resultSearch?.length == 0)
          "
        >
          <div class="py-16">
            <syn-animation
              name="searchIcon"
              :loop="false"
              stype="width: 100px; height: 100px"
              :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
            />
          </div>
        </template>
        <!-- SEARCHING -->
        <div v-else-if="searchText && resultSearch?.length > 0" class="pt-2">
          <div
            v-for="(result, index) in resultSearch"
            :id="`vig-search-box-item-id_${index.toString()}`"
            :key="result"
            :class="index == currentIndex ? 'bg-gray-100' : ''"
            class="
              dropdown-item
              py-2
              px-6
              flex
              items-center
              justify-between
              space-x-2
              cursor-pointer
              hover:bg-current-50
              text-sm
            "
            @click="onContactClick(result, result?.isGroup)"
          >
            <div class="flex-center space-x-2">
              <div class="pr-3">
                <SynIcon
                  name="search"
                  custom-class="w-3 h-3 text-gray-400 fill-gray-500"
                />
              </div>

              <SynAvatar
                :src="result?.avatar"
                :type="result?.isGroup ? 'group' : 'user'"
                :name="result.name"
                custom-class="w-8 h-8"
              />
              <!-- <span>{{ result?.name }}</span> -->
              <span
                class="text-overflow-hidden-line"
                v-html="markTextSearch(result?.name, searchText)"
              ></span>
              <span v-if="result?.id === myProfile?.id">
                ({{ $t('COMMON_LABEL_ME') || 'Me' }})
              </span>
            </div>
            <SynIcon
              v-if="result?.isGroup"
              name="group"
              is-active
              custom-class="w-4 h-4 fill-gray-500"
            />
          </div>
        </div>

        <!-- DEFAULT LIST -->
        <template v-else>
          <!-- PINNED -->
          <div
            v-if="!isChatPage"
            :id="`syn-tab-scroll-item-${conferenceMenuList?.PINNED?.key}`"
            class="flex-1 overflow-auto small-scrollbar p-1"
          >
            <div
              v-if="pinnedConversationIds?.length > 0"
              class="
                px-4
                pt-6
                pb-1
                uppercase
                text-xs
                font-semibold
                tracking-widest
                text-gray-500
              "
            >
              {{ $t(conferenceMenuList?.PINNED?.labelKey) || 'Recently' }}
            </div>
            <div
              v-for="conversId in pinnedConversationIds"
              :key="conversId"
              class="dropdown-item"
              @click="onOpenConversation(conversationById[conversId])"
            >
              <ConversationItem
                :conversation="conversationById[conversId]"
                :members="members"
              />
            </div>
          </div>

          <!-- RECENTLY -->
          <div
            v-if="!isChatPage"
            :id="`syn-tab-scroll-item-${conferenceMenuList?.HISTORY?.key}`"
            class="flex-1 overflow-auto small-scrollbar p-1"
          >
            <div
              v-if="userConversationIds?.length > 0"
              class="
                px-4
                pt-6
                pb-1
                uppercase
                text-xs
                font-semibold
                tracking-widest
                text-gray-500
              "
            >
              {{ $t(conferenceMenuList?.HISTORY?.labelKey) || 'Recently' }}
            </div>
            <div
              v-for="conversId in userConversationIds"
              :key="conversId"
              class="dropdown-item"
              @click="onOpenConversation(conversationById[conversId])"
            >
              <ConversationItem
                :conversation="conversationById[conversId]"
                :members="members"
              />
            </div>
          </div>

          <!-- FAVORITE -->
          <div
            v-if="myFilteredFavorites?.length > 0"
            :id="`syn-tab-scroll-item-${conferenceMenuList?.FAVORITES?.key}`"
            class="dropdown-item"
          >
            <div
              class="
                px-4
                pt-6
                pb-1
                uppercase
                text-xs
                font-semibold
                tracking-widest
                text-gray-500
              "
            >
              {{ $t(conferenceMenuList?.FAVORITES?.labelKey) || 'Favorites' }}
            </div>
            <div
              v-for="(contact, index) in myFilteredFavorites"
              :key="index"
              class="
                px-4
                py-2
                flex
                items-center
                justify-start
                space-x-4
                cursor-pointer
                hover:bg-gray-100
                hover-to-show__parent
              "
              @click="onFavoriteClick(contact)"
            >
              <div class="flex-center space-x-2">
                <SynAvatarStatusActive
                  :id="contact?.id"
                  :avatar="contact?.avatar"
                  :name="contact?.name"
                  custom-class="h-11 w-11"
                  :is-user="contact?.favoriteType === 'member'"
                  :is-group="contact?.favoriteType === 'group'"
                  :is-visitor="contact?.adminType == AdminType.Visitor"
                />
              </div>
            </div>
          </div>
          <!-- USER -->
          <div
            v-if="filteredUsers?.length > 0"
            :id="`syn-tab-scroll-item-${conferenceMenuList?.MEMBERS?.key}`"
          >
            <div
              class="
                px-4
                pt-6
                pb-1
                uppercase
                text-xs
                font-semibold
                tracking-widest
                text-gray-500
              "
            >
              {{ $t(conferenceMenuList?.MEMBERS?.labelKey) || 'Members' }}
            </div>
            <div
              v-for="(user, index) in filteredCompactUsers"
              :key="index"
              class="
                px-4
                py-2
                flex
                items-center
                justify-start
                space-x-4
                cursor-pointer
                hover:bg-gray-100
                relative
                dropdown-item
              "
              @click="onContactClick(user, false)"
            >
              <SynAvatarStatusActive
                :id="user?.id || user?.userId"
                :avatar="user?.avatar"
                custom-class="h-11 w-11"
                :name="user?.name"
                :is-visitor="user?.adminType == AdminType.Visitor"
                is-user
              />
            </div>
          </div>

          <!-- GROUP -->
          <div
            v-if="allMyGroups?.length"
            :id="`syn-tab-scroll-item-${conferenceMenuList?.GROUPS?.key}`"
            v-permission-model
          >
            <div
              class="
                px-4
                pt-6
                pb-1
                uppercase
                text-xs
                font-semibold
                tracking-widest
                text-gray-500
              "
            >
              {{ $t(conferenceMenuList?.GROUPS?.labelKey) || 'Groups' }}
            </div>
            <div
              v-for="group in allMyGroupsCompact"
              :key="group"
              class="
                px-4
                py-2
                flex
                items-center
                space-x-4
                cursor-pointer
                hover:bg-gray-100
                hover-to-show__parent
                relative
                dropdown-item
              "
              @click="onContactClick(group, true)"
            >
              <SynAvatarStatusActive
                :id="group?.id"
                :avatar="group?.avatar"
                :name="group?.name"
                custom-class="h-11 w-11"
                is-group
              />
            </div>
          </div>
        </template>
      </div>
    </template>
  </SynTabScroll>

  <router-link v-if="isShowViewAll" :to="{ name: 'Messages' }">
    <div
      class="
        py-2
        cursor-pointer
        dropdown-item
        hover:text-current-800 hover:bg-current-50 hover:bg-opacity-70
        border-t
        bg-white
        rounded-b-md
        flex-center
        font-medium
        text-current-500 text-sm
      "
      @click="$emit('close')"
    >
      <span>{{ $t('MESSAGE_VIEW_ALL_MESSAGE') }}</span>
    </div>
  </router-link>

  <ModalNewGroupChat
    v-if="isCreateGroupChat"
    :chat-about-task="attachedTask"
    modal-title="CHAT_GROUP_ABOUT_THIS_TASK"
    @on-close="isCreateGroupChat = false"
    @on-created="
      isCreateGroupChat = false;
      emit('success');
    "
  />
</template>
