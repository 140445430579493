<template>
  <svg
    v-if="!isActive"
    xmlns="http://www.w3.org/2000/svg"
    width="40.462"
    height="51.538"
    viewBox="0 0 40.462 51.538"
  >
    <g transform="translate(-3.71 -1.686)">
      <g transform="translate(4.46 2.474)">
        <path
          d="M16.647,19.846h12.04A1.459,1.459,0,0,0,29.7,17.24L15.239,3.037a1.459,1.459,0,0,0-2.606,1.016v11.91a3.883,3.883,0,0,0,4.013,3.883Z"
          transform="translate(8.659 -2.474)"
          fill="none"
          stroke="#485050"
          stroke-width="1.5"
        />
        <path
          d="M43,17.65c.72,0,.263,5.6.263,6.324V42.008A10.424,10.424,0,0,1,32.84,52.432H14.884A10.424,10.424,0,0,1,4.46,42.008V12.924A10.424,10.424,0,0,1,14.884,2.5H27.552ZM17.907,42.894h2.4a1.955,1.955,0,1,0,0-3.909h-2.4V36.613a1.955,1.955,0,0,0-3.909,0v2.372H11.627a1.955,1.955,0,1,0,0,3.909H14v2.372a1.955,1.955,0,0,0,3.909,0Z"
          transform="translate(-4.46 -2.432)"
          fill="none"
          stroke="#485050"
          stroke-width="1.5"
          fill-rule="evenodd"
        />
      </g>
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="m14.1701 9.13998h4.62c.2468.02035.4777-.12387.5677-.35463s.0177-.49322-.1777-.64537l-5.55-5.45c-.1522-.19544-.4146-.26773-.6454-.17773-.2307.08999-.375.32089-.3546.56773v4.57c-.0002.40399.1636.79076.4539 1.07167.2904.28091.6823.43189 1.0861.41833z"
        ></path>
        <path
          clip-rule="evenodd"
          d="m14.07 10.24h4.78c.2761 0 .5.2239.5.5v6.92c0 2.2091-1.7909 4-4 4h-6.89004c-2.20914 0-4-1.7909-4-4v-11.16c0-2.20914 1.79086-4 4-4h2.67004c.2739.00532.4946.22609.5.5v4.78c-.0027.64978.2529 1.27401.7105 1.73535s1.0797.72205 1.7295.72465zm-4.45004 7.76h.92004c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75h-.92004v-.91c0-.4142-.33579-.75-.75-.75s-.75.3358-.75.75v.91h-.91c-.41421 0-.75.3358-.75.75s.33579.75.75.75h.91v.91c0 .4142.33579.75.75.75s.75-.3358.75-.75z"
          fill-rule="evenodd"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
