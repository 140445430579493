<template>
  <div
    class="
      h-full
      flex-center flex-col
      text-center
      space-y-3
      bg-current-50 bg-opacity-90
      border-dashed border-4 border-current
    "
  >
    <SynIcon name="Upload" custom-class="w-16 h-16 fill-current" />
    <div class="text-2xl text-current-700">
      {{ $t('GED_UPLOAD_DROP_HERE') }}
    </div>
  </div>
</template>
