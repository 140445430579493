export const NotificationType = {
    All: 'all',
    New: 'new',
    Updated: 'updated',
    Finished: 'finished',
    Other: 'other',
};

export const NotificationKey = {
    All: 'all',
    New: 'new',
    Updated: 'updated',
    Finished: 'finished',
    Other: 'other',
};

export const NotificationActionTypeTask = {
    AddNew: 'AddNew',
    Finished: 'Finished',
    Delete: 'Delete',
    Comment: 'Comment',
    MentionComment: 'MentionComment',
};

export const NotificationTypeValue = {
    All: {
        key: 'all',
        label: 'NOTIFICATION_LABEL_TYPE_ALL',
    },
    New: {
        key: 'new',
        label: 'NOTIFICATION_LABEL_TYPE_NEW',
    },
    Updated: {
        key: 'updated',
        label: 'NOTIFICATION_LABEL_TYPE_UPDATED',
    },
    Finished: {
        key: 'finished',
        label: 'NOTIFICATION_LABEL_TYPE_FINISHED',
    },
    Other: {
        key: 'other',
        label: 'NOTIFICATION_LABEL_TYPE_OTHER',
    },
};

export const NotificationActionTypeGed = {
    FileShared: {
        key: 1,
        value: 'FileShared',
    },
    LinkShared: {
        key: 2,
        value: 'LinkShared',
    },
    GroupFileShared: {
        key: 3,
        value: 'GroupFileShared',
    },
    GroupLinkShared: {
        key: 4,
        value: 'GroupLinkShared',
    },
    NewFolder: {
        key: 5,
        value: 'NewFolder',
    },
    NewFile: {
        key: 6,
        value: 'NewFile',
    },
    NewGroupFolder: {
        key: 7,
        value: 'NewGroupFolder',
    },
    NewGroupFile: {
        key: 8,
        value: 'NewGroupFile',
    },
    NewGroupLink: {
        key: 9,
        value: 'NewGroupLink',
    },
};
