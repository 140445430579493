<script setup lang="ts">
// *** IMPORTS ***
import { CalendarSharingModel } from '@/application/models/calendar/CalendarSharingModel';
import { computed } from 'vue';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

// *** PROPS, EMITS ***
const props = defineProps<{
  sharing: CalendarSharingModel;
}>();

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***
const sharingLink = computed(() =>
  props.sharing?.id
    ? `${window.location.origin}/shared/calendar/${props.sharing?.id}`
    : ''
);

// *** REFS ***

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onCopyLinkClick = () => {
  if (!sharingLink.value) return;

  const copied = copyToClipBroard(sharingLink.value);

  if (copied) {
    openNotification({
      body: translate('TASK_SHARING_URL_COPIED_MSG'),
      duration: 2000,
    });
  }
};

const onOpenLinkClick = () => {
  if (!sharingLink.value) return;

  window.open(sharingLink.value, 'SharedCalendarTab');
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div class="flex items-center gap-1 bg-gray-50 rounded p-1">
    <div class="flex-1 text-sm truncate pl-2" :title="sharingLink">
      {{ sharingLink }}
    </div>
    <VigButton
      v-vig-tooltip="$t('TASK_DETAIL_COPY_LINK')"
      ghost
      color="gray"
      icon="Duplicate"
      padding="p-1"
      @click="onCopyLinkClick"
    />
    <VigButton
      v-vig-tooltip="$t('CHAT_OPENT_LINK')"
      ghost
      color="gray"
      icon="External"
      padding="p-1"
      @click="onOpenLinkClick"
    />
  </div>
</template>

<style scoped></style>
