import Resources from '../pages/resources/Resources.vue';
import Messages from '../pages/messages/Messages.vue';
import { ROUTER_GROUP } from '@/application/constants/system/router.const';

export default [
    {
        path: '/resources',
        name: 'Resources',
        component: Resources,
        meta: {
            layout: 'dashboard',
            isOverview: true,
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.FEATURE,
                index: 4,
            },
            titleKey: 'SIDEBAR_LABEL_RESOURCES',
            descriptionKey:
                'QUICK_MENU_DESCRIPTION_RESOURCES_FILES_DOCUMENTS_LINKS',
            title: 'Resources',
            icon: 'resources',
        },
    },
    {
        path: '/messages/:conversationId?',
        name: 'Messages',
        component: Messages,
        meta: {
            layout: 'dashboard',
            isOverview: true,
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.FEATURE,
                index: 2,
            },
            titleKey: 'CHAT_AND_CALL_LABEL',
            descriptionKey: 'QUICK_MENU_DESCRIPTION_CHAT_AND_AUDIO_VIDEO_CALL',
            title: 'Messages',
            icon: 'contact',
        },
    },
];
