import { EObjectStore } from '@/indexedDB';
import IdbBase from '../base/idb-base';
import { _prepareDesktopData } from '@/indexedDB/desktop/desktop-schema';

const CURRENT_OBJECT_STORE_NAME = EObjectStore.desktops;

export default class DesktopIndexedDBSingleton extends IdbBase {
    private static instance: DesktopIndexedDBSingleton;
    constructor() {
        super(CURRENT_OBJECT_STORE_NAME);
    }

    public static getInstance(): DesktopIndexedDBSingleton {
        if (!DesktopIndexedDBSingleton.instance) {
            // Get from local storage
            DesktopIndexedDBSingleton.instance =
                new DesktopIndexedDBSingleton();
        }

        return DesktopIndexedDBSingleton.instance;
    }

    toRawData(desktopId, data) {
        return _prepareDesktopData(desktopId, data);
    }
}
