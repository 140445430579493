import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import { ChatSeenByMemberModel } from '@/application/models/chat/ChatConversationModel';
import { MESSAGES_PAGE_SIZE } from '@/ui/modules/messaging/chat-panel/chat-panel-config';
import { keys, orderBy } from 'lodash';

interface ConversationCache {
    id?: string;
    messageIds?: Array<string>;
    messagesObj?: { [msgId: string]: ChatMessageModel };
    seenByMemberObj?: { [memberId: string]: ChatSeenByMemberModel };
}

export default defineStore({
    id: storeId.CHAT_PANEL,
    state: () => ({
        cacheByConversationId: {} as {
            [conversationId: string]: ConversationCache;
        },
    }),
    getters: {},
    actions: {
        getMessagesFromCache(
            conversationId: string,
            pageSize?: number,
            fromIndex?: number
        ): any {
            if (
                !this.cacheByConversationId ||
                !this.cacheByConversationId[conversationId]
            ) {
                return {};
            }
            console.log(
                'getMessagesFromCache',
                this.cacheByConversationId[conversationId]
            );

            pageSize = pageSize || MESSAGES_PAGE_SIZE;
            fromIndex = fromIndex || 0;

            const { messageIds, messagesObj } =
                this.cacheByConversationId[conversationId];

            return {
                messageIds: (messageIds || []).slice(
                    fromIndex,
                    fromIndex + pageSize
                ),
                messagesObj: messagesObj || {},
                totalMessages: (messageIds || []).length,
            };
        },
        updateMessagesInCache(
            conversationId: string,
            messagesObj: { [msgId: string]: ChatMessageModel }
        ): void {
            this.cacheByConversationId = this.cacheByConversationId || {};
            this.cacheByConversationId[conversationId] =
                this.cacheByConversationId[conversationId] || {};

            let msgObj =
                this.cacheByConversationId[conversationId].messagesObj || {};
            msgObj = { ...msgObj, ...messagesObj };

            this.cacheByConversationId[conversationId].messagesObj = msgObj;
            this.cacheByConversationId[conversationId].messageIds = orderBy(
                keys(msgObj),
                (msgId) => msgObj[msgId]?.createdDate,
                'desc'
            );
        },

        getMembersSeenByInCache(conversationId: string): {
            [memberId: string]: ChatSeenByMemberModel;
        } {
            if (
                !this.cacheByConversationId ||
                !this.cacheByConversationId[conversationId]
            ) {
                return {};
            }

            return (
                this.cacheByConversationId[conversationId].seenByMemberObj || {}
            );
        },
        updateMembersSeenByInCache(
            conversationId: string,
            seenByMemberObj: { [memberId: string]: ChatSeenByMemberModel }
        ): void {
            this.cacheByConversationId = this.cacheByConversationId || {};
            this.cacheByConversationId[conversationId] =
                this.cacheByConversationId[conversationId] || {};

            this.cacheByConversationId[conversationId].seenByMemberObj =
                seenByMemberObj;
        },
    },
});
