import { getCurrentUserId } from '@/ui/hooks/storageHook';

export const colorMyMsgDefault = {
    all: `background-color: #cee8e9;`,
    bgColor: `background-color: #cee8e9;`,
    color: `color: #000;`,
};
export const colorOtherMsgDefault = {
    all: `background-color: #edeff1;`,
    bgColor: `background-color: #edeff1;`,
    color: `color: #000;`,
};

export const listColorSetup = [
    '#baea9e',
    '#22c55e',
    '#4cb273',
    '#20cef5',
    '#0183ff',
    '#7746ff',
    '#ffc400',
    '#ff7e2a',
    '#fb3c4c',
];

function hexToRGB(h) {
    let r = 0 as any,
        g = 0 as any,
        b = 0 as any;

    // 3 digits
    if (h.length == 4) {
        r = '0x' + h[1] + h[1];
        g = '0x' + h[2] + h[2];
        b = '0x' + h[3] + h[3];

        // 6 digits
    } else if (h.length == 7) {
        r = '0x' + h[1] + h[2];
        g = '0x' + h[3] + h[4];
        b = '0x' + h[5] + h[6];
    }

    return {
        R: r,
        G: g,
        B: b,
    };
}

export function calculateStyleBrightness(colorHex) {
    const color = hexToRGB(colorHex);
    const brightness = Math.sqrt(
        color.R * color.R * 0.241 +
            color.G * color.G * 0.691 +
            color.B * color.B * 0.068
    );
    const textColor = brightness < 130 ? '#fff' : '#000';

    return {
        hexColor: `${colorHex}`,
        all: `background-color: ${colorHex}; color: ${textColor} !important;`,
        bgColor: `background-color: ${colorHex};`,
        color: `color: ${textColor} !important;`,
    };
}

export function styleCustomizeMessage(message, isMyMessage, allowBackground) {
    if (!allowBackground) return colorOtherMsgDefault;

    const createdBy = message?.createdBy;
    const myId = getCurrentUserId();

    const colorByCreator =
        message?.customizes?.backgroundColor[createdBy]?.hex || '';
    const isShowAllByCreator =
        message?.customizes?.backgroundColor[createdBy]?.isShowAll == 'true';
    const colorByMe = message?.customizes?.backgroundColor[myId]?.hex || '';

    if (isMyMessage) {
        if (!colorByMe) return colorMyMsgDefault;
        return calculateStyleBrightness(colorByMe);
    }
    if (!colorByMe && colorByCreator && isShowAllByCreator)
        return calculateStyleBrightness(colorByCreator);

    if (colorByMe && colorByCreator && !isShowAllByCreator)
        return calculateStyleBrightness(colorByMe);
    if (colorByMe && !colorByCreator)
        return calculateStyleBrightness(colorByMe);

    if (colorByMe && colorByCreator && isShowAllByCreator) {
        return {
            ...calculateStyleBrightness(colorByMe),
            border: colorByCreator,
        };
    }

    return colorOtherMsgDefault;
}
