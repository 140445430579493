<template>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <desc>Created with Pixso.</desc>
    <defs />
    <path
      id="path"
      d="M249.86 6.42C260.2 6.68 265.5 11.98 265.76 22.32L269.88 189.86L315.45 144.29C322.59 137.15 329.92 137.33 337.42 144.83C344.92 152.34 345.1 159.66 337.96 166.8L266.54 238.22C259.39 245.37 252.07 245.19 244.57 237.68L169.54 162.66C162.04 155.16 161.86 147.83 169 140.69C176.15 133.55 183.47 133.73 190.97 141.23L238.84 189.1L234.72 21.56C234.47 11.22 239.52 6.17 249.86 6.42ZM196.55 368.23C189.41 375.37 182.09 375.19 174.59 367.69C167.08 360.18 166.9 352.86 174.05 345.72L245.47 274.29C252.61 267.15 259.93 267.33 267.44 274.84L342.46 349.86C349.96 357.36 350.14 364.69 343 371.83C335.86 378.97 328.54 378.79 321.03 371.29L273.17 323.42L277.29 490.96C277.18 500.94 272.13 505.81 262.13 505.56C252.14 505.32 246.84 500.19 246.24 490.19L242.12 322.66L196.55 368.23Z"
      fill="#000000"
      fill-opacity="1.000000"
      fill-rule="evenodd"
    />
  </svg>
</template>
