<script setup lang="ts">
// *** IMPORTS ***
import { computed } from 'vue';
import appStore from '@/store/app';
import { useRouter } from 'vue-router';
import plansStore from '@/store/plans';
import { IOrgCurrentPlan } from '@/application/types/plan.types';
import { EPlanId } from '@/application/constants/plan.const';

// *** PROPS, EMITS ***

// *** PRIVATE VARIABLES ***
const _router = useRouter();
const _appStore = appStore();
const _planStore = plansStore();

// *** COMPOSABLES ***

// *** REFS ***

// *** COMPUTED ***
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
const isOpenUserBar = computed(
  () => _router.currentRoute.value.meta.isSettingMenu
);

const currentOrganizationPlan = computed<IOrgCurrentPlan>(
  () => _planStore.currentOrgPlan
);
const isOrgPremium = computed(
  () =>
    currentOrganizationPlan.value?.planId == EPlanId.PRO ||
    currentOrganizationPlan.value?.planId == EPlanId.VIP
);

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div
    class="flex items-center px-3"
    :class="!isOpenUserBar ? '' : 'rounded-tr-2xl'"
  >
    <div class="relative flex content-center justify-center items-center">
      <SynIcon name="tictop-logo" custom-class="h-9" />
      <div
        v-if="isOrgPremium"
        class="
          flex-center
          rounded-full
          w-5
          h-5
          absolute
          -right-3
          bottom-0
          logo-label
        "
        :class="!isOpenMenuBar ? 'opacity-100' : 'opacity-0'"
      >
        <SynIcon name="crown" custom-class="fill-white text-white w-4 h-4" />
      </div>
      <!-- <SynIcon name="tictop-logo-premium" custom-class="h-7" /> -->
    </div>
    <div
      class="flex flex-col w-max truncate pl-2 gap-1 logo-label"
      :class="[
        !isOrgPremium ? '' : 'flex-1',
        !isOpenMenuBar ? 'opacity-0' : 'opacity-100',
      ]"
    >
      <div class="w-full flex items-center space-x-2">
        <SynIcon
          v-if="isOrgPremium"
          name="tictop-logo-label-premium"
          custom-class="h-5"
        />
        <SynIcon v-else name="tictop-logo-label" custom-class="h-5" />
        <AtomPremiumLabel
          v-if="isOrgPremium"
          :label="currentOrganizationPlan.value?.planId"
        />
      </div>
      <div class="flex">
        <SynIcon
          v-if="isOpenUserBar"
          name="TictopSloganWhite"
          custom-class="h-2"
        />
        <SynIcon v-else name="TictopSlogan" custom-class="h-2" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
