<template>
  <svg
    v-if="isActive"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <clipPath id="clip2_1">
        <rect
          id="Frame 1"
          width="512.000000"
          height="512.000000"
          fill="white"
        />
      </clipPath>
    </defs>
    <rect id="Frame 1" width="512.000000" height="512.000000" fill="#FFFFFF" />
    <g clip-path="url(#clip2_1)">
      <path
        id="path"
        d="M448.5 21L63.5 21C58.3964 21.0059 53.4875 22.0016 48.7735 23.987C44.0595 25.9724 39.8981 28.7968 36.2893 32.4603C32.6805 36.1238 29.8982 40.3483 27.9424 45.1337C25.9866 49.9192 25.0058 54.9024 25 60.0833L25 450.917C25.0058 456.097 25.9866 461.081 27.9424 465.866C29.8982 470.652 32.6805 474.876 36.2893 478.54C39.8981 482.203 44.0595 485.027 48.7735 487.013C53.4875 488.998 58.3964 489.994 63.5 490L325.027 490C335.67 490.029 344.745 486.214 352.25 478.552L475.723 353.208C483.27 345.589 487.029 336.377 487 325.573L487 60.0833C486.994 54.9024 486.013 49.9192 484.057 45.1337C482.102 40.3483 479.319 36.1238 475.711 32.4603C472.102 28.7968 467.94 25.9724 463.226 23.987C458.512 22.0016 453.603 21.0059 448.5 21ZM333 442.823L333 333.647L440.546 333.647L333 442.823Z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 25 25"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M21.5 2H4a1.5 1.5 0 0 0-1.5 1.5v17.75A1.68 1.68 0 0 0 4.08 23H17.5a.47.47 0 0 0 .35-.15l5-5a.47.47 0 0 0 .15-.35v-14A1.5 1.5 0 0 0 21.5 2zM18 21.29V18.5a.5.5 0 0 1 .5-.5h2.79zm.5-4.29a1.5 1.5 0 0 0-1.5 1.5V22H4.08a.69.69 0 0 1-.58-.75V3.5A.5.5 0 0 1 4 3h17.5a.5.5 0 0 1 .5.5V17z"
        data-name="Layer 30"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
