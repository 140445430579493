export default class PostMessageService {
    private static instance: PostMessageService;

    public static getInstance(): PostMessageService {
        if (!PostMessageService.instance) {
            // Get from local storage
            PostMessageService.instance = new PostMessageService();
        }

        return PostMessageService.instance;
    }

    postMessageToParent = (message) => {
        window.parent.postMessage(
            JSON.parse(JSON.stringify(message || null)),
            '*'
        );
    };
}
