<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g>
        <path
          d="M250 184c44.112 0 80-35.888 80-80s-35.888-80-80-80-80 35.888-80 80 35.888 80 80 80zm0-144c35.29 0 64 28.71 64 64s-28.71 64-64 64-64-28.71-64-64 28.71-64 64-64zM464 472h-60.737c.421-1.193.765-2.426.997-3.705l.72-3.96 29.057-29.057c10.506-10.507 14.314-25.851 9.939-40.04l-16.616-54.002 3.081-16.944a23.964 23.964 0 0 0-4.842-19.248 23.98 23.98 0 0 0-11.829-8.007l-10.745-34.919c-5.576-18.113-16.583-33.641-31.833-44.901C355.943 205.953 337.864 200 318.91 200H181.09c-18.954 0-37.033 5.953-52.283 17.215-15.25 11.261-26.257 26.788-31.833 44.902L86.23 297.033a23.991 23.991 0 0 0-11.829 8.01 23.965 23.965 0 0 0-4.842 19.248l3.08 16.945-16.613 53.997a39.884 39.884 0 0 0 9.938 40.045l29.054 29.054.721 3.956a23.984 23.984 0 0 0 1 3.713H48a8 8 0 0 0 0 16h416A8 8 0 0 0 464 472zm-35.314-72.053a23.919 23.919 0 0 1-5.963 24.016l-12.714 12.714 11.135-61.243zM181.09 216h137.82c31.809 0 59.467 20.424 68.823 50.822L396.712 296H103.289l8.978-29.177C121.623 236.424 149.281 216 181.09 216zM71.316 399.942l7.54-24.505 11.132 61.236-12.711-12.711a23.92 23.92 0 0 1-5.961-24.02zM119.35 472a7.992 7.992 0 0 1-7.869-6.576L88.712 340.19l-.012-.066-3.4-18.702a7.863 7.863 0 0 1 1.609-6.403c1.466-1.837 3.558-2.905 5.867-3.006.131-.005.262-.013.394-.013h313.66c.132 0 .263.008.371.012 2.332.102 4.424 1.17 5.89 3.007a7.864 7.864 0 0 1 1.609 6.403l-.001.006-3.397 18.688c-.007.031-.011.063-.017.094l-21.577 118.693-.004.021-.014.079-1.173 6.428a7.988 7.988 0 0 1-7.867 6.568h-261.3z"
          opacity="1"
          data-original="#231f20"
          class=""
        ></path>
        <path
          d="M250 360c-22.056 0-40 14.355-40 32s17.944 32 40 32 40-14.355 40-32-17.944-32-40-32zm0 48c-13.01 0-24-7.327-24-16s10.99-16 24-16 24 7.327 24 16-10.99 16-24 16z"
          opacity="1"
          data-original="#231f20"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
