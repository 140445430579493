<script setup lang="ts">
import { computed } from 'vue';
import { formatDate } from '@/ui/hooks/calendarHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps({
  file: {
    type: Object,
    default: () => {},
  },
  src: {
    type: String,
    default: '',
  },
  isAutoPlay: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});
defineEmits(['onClose', 'click']);

const timeUpdate = computed(
  () =>
    translate('TASK_TABLE_LABEL_CREATED_DATE') +
    ': ' +
    formatDate(props.file?.creationTime).date +
    ' ' +
    formatDate(props.file?.creationTime).time
);
</script>
<template>
  <div class="flex items-center">
    <div
      v-vig-tooltip="timeUpdate"
      class="flex hover-to-show__parent items-center relative"
    >
      <audio
        class="h-9 w-64"
        controls
        :autoplay="isAutoPlay"
        :src="src"
        @click="$emit('click')"
      />
      <div
        v-if="!readonly"
        class="
          absolute
          -right-2
          w-6
          h-6
          hover-to-show__children
          rounded-full
          bg-red-100
          flex-center
        "
        @click="$emit('onClose')"
      >
        <SynIcon
          name="close"
          custom-class="w-3 h-3"
          class="text-red-500 fill-red-500"
        />
      </div>
    </div>
    <div
      v-if="readonly"
      class="
        ml-2
        hover:bg-red-100
        flex-center
        rounded-md
        cursor-pointer
        border border-red-400
        h-8
        w-8
      "
      @click="$emit('onClose')"
    >
      <SynIcon
        name="trash"
        custom-class="w-4 h-4 fill-red-500"
        class="text-red-500"
      />
    </div>
  </div>
</template>
