<script setup lang="ts">
import { ref, computed } from 'vue';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import ModalNotificationSetting from '@/ui/components/notification/ModalNotificationSetting.vue';
import { NotificationSettingFunction } from '@/ui/common/constants/constant';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import BreakLinkDepartmentGroupModal from '@/ui/modules/group/detail/BreakLinkDepartmentGroupModal.vue';
import ModalDeleteGroup from '@/ui/components/group/ModalDeleteGroup.vue';
import MakePrivateConfirm from '@/ui/components/group/MakePrivateConfirm.vue';
import { GroupType } from '@/application/enums/GroupEnums';
import ModalSetDomain from '@/ui/components/group/ModalSetDomain.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import myProfileStore from '@/store/auth/my-profile';
import { IGroupDetailEntity } from '@/domain/entities/group/GroupDetailEntity';
import { EGroupType } from '@/application/types/group/group.types';
import GroupNotificationStatus from '@/ui/modules/group/detail/GroupNotificationStatus.vue';
import SettingChatRestriction from '@/ui/modules/group/detail/setting/SettingChatRestriction.vue';

const props = withDefaults(
  defineProps<{
    groupId: number;
  }>(),
  {}
);

const emit = defineEmits([
  'showDeleteModal',
  'showModalSettingGroup',
  'onDelete',
  'deletedSuccess',
  'onClickRemove',
  'onChangePrivate',
  'changedPrivateSuccess',
]);
const _groupStore = groupStore();

const allGroupByIds = computed<{ number: IGroupDetailEntity }>(() => {
  return _groupStore.allGroupByIds;
});
const groupDetail = computed<IGroupDetailEntity>(() => {
  if (
    !allGroupByIds.value ||
    !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
  )
    return {};
  return allGroupByIds.value[props.groupId];
});

const notificationSetting = computed(
  () => _notificationSettingStore?.notificationSetting
);
const _notificationSettingStore = notificationSettingStore();
const isShowNotificationSetting = ref(false);
const configured = ref(true);
const isTaskCreation = ref<boolean>();
const isTaskCreationConfirm = ref<boolean>();
const isLoadingWhenChangePrivate = ref(false);

const canUpdateMemberGroup = computed(() => {
  {
    // admin system has permission like admin group in public group
    return (
      (!groupDetail.value?.isPrivate &&
        (myProfileStore().isAdmin || myProfileStore().isOwner)) ||
      groupDetail.value?.isAdminGroup
    );
  }
});

const init = async () => {
  isTaskCreation.value = groupDetail.value?.type !== EGroupType.CHAT;

  await _notificationSettingStore.getSettingNotification(
    groupDetail.value?.id,
    'GROUP'
  );

  if (notificationSetting.value && notificationSetting.value.id) {
    configured.value = false;

    _groupStore.setValueSettingGroup(
      notificationSetting.value,
      groupDetail.value?.id
    );
    overviewGroupStore().updateNotificationByGroupId(
      notificationSetting.value,
      groupDetail.value?.id
    );
  } else {
    configured.value = true;
  }
};

init();

const showModalSettingGroup = async () => {
  if (notificationSetting.value && notificationSetting.value.id) {
    const enableResult =
      await _notificationSettingStore.enableSettingNotification(
        notificationSetting.value.id
      );
    if (enableResult) {
      _groupStore.setValueSettingGroup({}, props.groupId);
      overviewGroupStore().updateNotificationByGroupId({}, props.groupId);
      configured.value = true;
      openNotification({
        title: translate('COMMON_LABEL_SUCCESS'),
        body:
          translate('NOTIFICATION_SETTING_ENABLE_SUCCESS_LABEL', {
            group: groupDetail.value?.name,
          }) || 'Turn on the notification of the group again',
      });
      await init();
    } else {
      openNotification({
        type: AlertType.error,
        title: translate('COMMON_LABEL_ERROR'),
        body:
          translate('NOTIFICATION_SETTING_ERROR_LABEL') ||
          'Notification settings failed',
      });
    }
  } else {
    isShowNotificationSetting.value = true;
  }
};

const onChangeNotification = async () => {
  await init();
  isShowNotificationSetting.value = false;
};

const showAlertSystemGroup = ref(false);
const isShowAlertCannotEditDepartmentGroup = ref(false);
const alertAdminSystemNotHasPermission = ref(false);
const isSystemGroup = computed<boolean>(() => {
  return (
    groupDetail.value?.code == 'GENERAL_GROUP' ||
    groupDetail.value?.code == 'DELETED_GROUP'
  );
});
const onCheckPermissionBeforeUpdate = (): boolean => {
  if (isSystemGroup.value) {
    showAlertSystemGroup.value = true;
    return false;
  }
  if (groupDetail.value?.code?.includes('DEPARTMENT')) {
    isShowAlertCannotEditDepartmentGroup.value = true;
    return false;
  }
  if (!canUpdateMemberGroup.value) {
    alertAdminSystemNotHasPermission.value = true;
    return false;
  }

  return true;
};

const onMakePrivateFromPopup = async () => {
  groupDetail.value.isPrivate = true;
  await onSaveInfoGroup();
  openMakePrivatePopup.value = false;
};

const onSaveInfoGroup = async () => {
  if (isLoadingWhenChangePrivate.value) return;
  try {
    isLoadingWhenChangePrivate.value = true;
    await _groupStore.createAndUpdate({
      id: groupDetail.value.id,
      name: groupDetail.value.name,
      description: groupDetail.value.description,
      isPrivate: groupDetail.value.isPrivate,
      email: groupDetail.value.email,
      avatar: groupDetail.value.avatarPath,
      type: groupDetail.value.type,
      // userIds: groupDetail.value.groupUsers.map(el => el.userId),
      memberInfos: groupDetail.value.groupUsers,
      domainIds: groupDetail.value.groupDomains.map((el) => el.domainId),
    });
    isLoadingWhenChangePrivate.value = false;
    await _groupStore.getDetailGroupById(groupDetail.value?.id);
    emit('changedPrivateSuccess', groupDetail.value.isPrivate);
    openNotification({
      type: AlertType.success,
      title: translate('COMMON_LABEL_SUCCESS'),
      body: groupDetail.value.isPrivate
        ? translate('NOTIFICATION_SETTING_TURN_ON_PRIVATE_GROUP', {
            groupName: groupDetail.value.name,
          })
        : translate('NOTIFICATION_SETTING_TURN_OFF_PRIVATE_GROUP', {
            groupName: groupDetail.value.name,
          }),
    });
  } catch (err) {
    console.log('File: FormSetting.vue - L: 251 - ', err);
    groupDetail.value.isPrivate = !groupDetail.value.isPrivate;
    isLoadingWhenChangePrivate.value = false;
    openNotification({
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body: translate('NOTIFICATION_SETTING_PRIVATE_GROUP_ERROR'),
    });
  }
};

const openMakePrivatePopup = ref(false);
const onAskMakePrivate = () => {
  if (!onCheckPermissionBeforeUpdate()) return;
  //  Only ask when set group to private
  if (groupDetail.value?.isAdminGroup || groupDetail.value.isPrivate) {
    if (isLoadingWhenChangePrivate.value) return;
    groupDetail.value.isPrivate = !groupDetail.value.isPrivate;
    onSaveInfoGroup();
    return;
  }

  openMakePrivatePopup.value = true;
};

const showDeleteGroup = ref(false);
const showModalRemoveGroup = () => {
  if (!onCheckPermissionBeforeUpdate()) return;

  showDeleteGroup.value = true;
};

const onSetGroupTaskCreation = async () => {
  if (!onCheckPermissionBeforeUpdate()) return;

  if (!isTaskCreation.value) {
    isTaskCreationConfirm.value = true;
  } else {
    if (groupDetail.value?.taskCount) {
      settingGlobalModal({
        type: 'notification',
        title: translate('COMON_NOT_ALLOW_PERFORM_ACTION'),
        content: translate('GROUP_PREVENT_TASK_OFF'),
        confirmable: true,
      });
      await ask();
    } else {
      settingGlobalModal({
        type: 'confirm',
        title: translate('GROUP_TASK_OFF_CONFIRM'),
        content: translate('GROUP_TASK_OFF_CONFIRM_MSG'),
        confirmable: true,
        closeable: true,
      });
      const confirmed = await ask();

      if (confirmed) {
        const res = await _groupStore.changeGroupType({
          id: props.groupId,
          type: GroupType.CHAT,
          domainIds: groupDetail.value?.groupDomains?.map(
            (domain) => domain?.domainId
          ),
        });

        if (res?.success) isTaskCreation.value = false;
      }
    }
  }
};

const onConfirmSetDomain = async (selectedDomains) => {
  await _groupStore.changeGroupType({
    id: props.groupId,
    type: GroupType.NORMAL,
    domainIds: selectedDomains?.map((el) => el.id),
  });

  onSetGroupTaskCreationConfirmed();
};

const onSetGroupTaskCreationConfirmed = async () => {
  isTaskCreation.value = true;
  isTaskCreationConfirm.value = false;
};
</script>

<template>
  <div class="flex flex-col">
    <!-- SETTING  NOTIFICATION-->
    <div class="px-4 py-3 flex justify-between bg-white border-b space-x-4">
      <div class="flex items-start space-x-4">
        <div class="w-4 h-4">
          <SynIcon name="bell" class="fill-current" custom-class="w-4 h-4" />
        </div>
        <div class="flex flex-wrap">
          <span class="text-base">{{ $t('NOTIFICATION_SETTING_LABEL') }}</span>
          <span class="text-xs text-gray-600 w-full">
            <GroupNotificationStatus
              :notification-setting="notificationSetting"
              class="text-xs"
            />
          </span>
        </div>
      </div>
      <div class="flex-center space-x-2">
        <div class="flex-center space-x-2">
          <SynIcon
            v-if="
              notificationSetting?.function ==
              NotificationSettingFunction.Messaging
            "
            v-vig-tooltip="{
              content: `
              <div class='p-2 w-48 text-center text-xs bg-white text-current-500'>
                <span class='text-md'>${$t(
                  'NOTIFICATION_SETTING_ALERT_TURN_OFF_MESSAGE'
                )}</span>
              </div>`,
              arrow: true,
              placement: 'bottom',
              delay: 0,
              theme: 'light',
              animation: 'scale',
              interactive: true,
            }"
            name="message"
            class="fill-gray-500 text-gray-500"
            is-active
            custom-class="w-4 h-4 fill-gray-500"
          />

          <SynIcon
            v-if="
              notificationSetting?.function == NotificationSettingFunction.Task
            "
            v-vig-tooltip="{
              content: `
              <div class='p-2 w-48 text-center text-xs bg-white text-current-500'>
                <span class='text-md'>${$t(
                  'NOTIFICATION_SETTING_ALERT_TURN_OFF_TASK'
                )}</span>
              </div>`,
              arrow: true,
              placement: 'bottom',
              delay: 0,
              theme: 'light',
              animation: 'scale',
              interactive: true,
            }"
            name="note"
            is-active
            class="fill-gray-500 text-gray-500"
            custom-class="w-4 h-4 fill-gray-500"
          />
        </div>
        <AtomSwitch :model-value="configured" @click="showModalSettingGroup" />
      </div>
    </div>

    <div class="px-4 py-3 bg-white border-b space-y-3">
      <!-- TASK CREATION -->
      <div class="flex items-center justify-between space-x-4">
        <div class="flex items-start space-x-4">
          <div class="w-4 h-4 shrink-0">
            <SynIcon name="Note" class="fill-current" custom-class="w-4 h-4" />
          </div>
          <div class="flex flex-col">
            <span class="text-base">{{ $t('GROUP_CREATE_TASKS_TITLE') }}</span>
            <span
              v-if="isTaskCreation && groupDetail?.taskCount"
              class="text-xs text-gray-600"
            >
              {{
                $t(
                  groupDetail?.taskCount <= 1
                    ? 'GROUP_HAS_NUM_TASK'
                    : 'GROUP_HAS_NUMS_TASKS',
                  { number: groupDetail?.taskCount }
                )
              }}</span
            >
          </div>
        </div>
        <AtomSwitch
          :model-value="isTaskCreation"
          @change="onSetGroupTaskCreation"
        />
      </div>

      <!-- SETTING PRIVATE -->
      <div
        v-if="groupDetail?.type !== EGroupType.CHAT || isTaskCreation"
        class="flex items-center justify-between space-x-4"
      >
        <div class="flex items-start space-x-4">
          <div class="w-4 h-4">
            <SynIcon
              name="private"
              class="fill-current"
              custom-class="w-4 h-4"
            />
          </div>
          <div class="flex flex-wrap">
            <span class="text-sm">{{ $t('GROUP_TASKS_PRIVATE') }}</span>
            <span class="text-xs text-gray-600 w-full">
              {{ $t('GROUP_CREATE_SUB_STATUS_MAKE_PRIVATE') }}
            </span>
          </div>
        </div>
        <AtomSwitch
          :model-value="groupDetail.isPrivate"
          @change="onAskMakePrivate"
        />
      </div>
    </div>

    <SettingChatRestriction
      class="px-4 py-3 bg-white border-b space-y-3"
      :group="groupDetail"
    />

    <!-- SETTING DELETE -->
    <div
      v-if="canUpdateMemberGroup"
      class="
        px-4
        py-3
        flex
        items-center
        justify-between
        bg-white
        border-b
        space-x-4
      "
    >
      <div class="flex items-start space-x-4">
        <div class="w-4 h-4 shrink-0">
          <SynIcon name="trash" class="fill-current" custom-class="w-4 h-4" />
        </div>
        <div class="flex flex-wrap">
          <span class="text-base">{{ $t('GROUP_TITLE_DELETE_GROUP') }}</span>
          <span class="text-xs text-gray-600 w-full">{{
            $t('GROUP_SUB_DELETE_GROUP')
          }}</span>
        </div>
      </div>
      <div>
        <SynButton
          class="text-sm"
          color="red"
          :label="$t('GROUP_LABEL_DELETE')"
          type-outline
          @click="showModalRemoveGroup"
        />
      </div>
    </div>
  </div>

  <ModalNotificationSetting
    v-if="isShowNotificationSetting"
    :ref-id="groupId"
    scope="GROUP"
    :name="groupDetail.name"
    @change-notification="onChangeNotification"
    @cancel="isShowNotificationSetting = false"
  />

  <AlertModal
    v-if="showAlertSystemGroup"
    :title-text="$t('GROUP_LABEL_EDIT_SYSTEM_GROUP_TITLE')"
    :sub-title-text="
      $t('GROUP_LABEL_REMOVE_SYSTEM_GROUP_CONTENT', {
        groupName: groupDetail.name,
      })
    "
    :is-html-text="true"
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="showAlertSystemGroup = false"
  />
  <BreakLinkDepartmentGroupModal
    v-if="isShowAlertCannotEditDepartmentGroup"
    :group="groupDetail"
    @cancel="isShowAlertCannotEditDepartmentGroup = false"
    @update-success="isShowAlertCannotEditDepartmentGroup = false"
  />

  <ModalDeleteGroup
    v-if="showDeleteGroup"
    :show-modal="showDeleteGroup"
    :group="groupDetail"
    :group-id="groupId"
    :group-name="groupDetail.name"
    :group-code="groupDetail.code"
    @cancel="showDeleteGroup = false"
    @deleted-success="$emit('deletedSuccess')"
  />

  <MakePrivateConfirm
    v-if="openMakePrivatePopup"
    :group="groupDetail"
    :is-loading="isLoadingWhenChangePrivate"
    :confirm-text="'confirm'"
    @on-close="openMakePrivatePopup = false"
    @on-confirm="onMakePrivateFromPopup"
  />
  <AlertModal
    v-if="alertAdminSystemNotHasPermission"
    :title-text="$t('COMON_NOT_ALLOW_PERFORM_ACTION')"
    :sub-title-text="$t('GROUP_UPDATE_ALLOW_ADMIN_GROUP_ONLY')"
    container-class="w-1/3"
    :container-color="'current'"
    @cancel="alertAdminSystemNotHasPermission = false"
  />

  <ModalSetDomain
    v-if="isTaskCreationConfirm"
    :group-id="groupId"
    @on-close="isTaskCreationConfirm = false"
    @on-confirmed="onConfirmSetDomain"
  />
</template>
