import { ref, computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import limitationComposable from '@/ui/composables/limitation/limitation-composable';

import TaskService from '@/application/services/task/TaskService';
import {
    IAttachmentFileDetail,
    removeAwsFiles,
    uploadFilesToAws,
} from '@/application/services/attachment/AttachmentClass';
import {
    IDrawNoteDetail,
    removeAwsNotes,
    uploadNotesToAws,
} from '@/application/services/attachment/NoteClass';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';

export default function attachmentFilesComposables(
    module: 'CHAT' | 'TASK' | 'STORAGE',
    options: {
        defaultFiles?: any[];
        defaultNotes?: any[];
        isUploadRightAway?: boolean;
        onChange?: Function;
    }
) {
    const _limitationComposable = limitationComposable(module);

    // const getFileUrl = (file) => {
    //     if (file instanceof File) return URL.createObjectURL(file);
    //     if (file?.fileBlob instanceof Blob) {
    //         return URL.createObjectURL(file?.fileBlob);
    //     }
    //     if (file?.blob instanceof Blob) {
    //         return URL.createObjectURL(file?.blob);
    //     }
    //     if (file?.base64) return file?.base64;
    //     if (file?.fileUrl) return file?.fileUrl;
    //     if (file?.url_full) return file?.url_full;
    //     if (file?.pathUrl) return file?.pathUrl;
    //     if (file?.Path) return file?.Path;

    //     return file?.imagePath || file?.src;
    // };
    const attachmentFiles = ref<any[]>(
        options?.defaultFiles && options?.defaultFiles?.length > 0
            ? [...options?.defaultFiles]
            : []
    );
    const onChooseFiles = (event) => {
        if (!event?.target) return;

        const files = event?.target?.files;
        onAddFiles(files);

        event.target.value = '';
    };

    const onChange = () => {
        if (options?.onChange && typeof options?.onChange == 'function')
            options?.onChange({
                attachmentFiles: attachmentFiles.value,
                noteList: noteList.value,
                uploadingFiles: uploadingFiles.value,
            });
    };

    const attachmentContainerRef = ref<any>(null);

    const onAddFiles = async (files: FileList) => {
        const validFiles = _limitationComposable.verifySelectedFiles(
            attachmentFiles.value,
            Array.from(files)
        );
        if (!validFiles || validFiles?.length == 0) return;

        const newAttachments = Array.from(validFiles)?.map((file) => {
            return _prepareNewFile(file);
        });

        attachmentFiles.value = [...attachmentFiles.value, ...newAttachments];

        setTimeout(() => {
            if (attachmentContainerRef.value)
                attachmentContainerRef.value.scrollLeft =
                    attachmentContainerRef.value?.scrollWidth || 0;
        }, 200);
        onChange();

        if (options?.isUploadRightAway) _uploadFiles(newAttachments);
    };

    const uploadingFiles = ref<any[]>([]);

    // AUDIO
    // audio playback
    const isOpenRecording = ref(false);
    const onCreateAudioDone = (
        recordings: {
            blob: Blob;
            isRecorder: true;
            name: string;
            size: number;
            src: string;
            type: string;
        }[]
    ) => {
        const newFiles = recordings.map((record) => {
            const newId = uuidv4();
            return {
                localId: newId,
                name: `${newId}.mp3`,
                type: 'audio/mpeg',
                fileUrl: record.src,
                creationTime: new Date(),
                blob: record.blob,
                size: record.size,
                editStatus: 'ADDED',
            } as IAttachmentFileDetail;
        });
        attachmentFiles.value = [...attachmentFiles.value, ...newFiles];

        setTimeout(() => {
            if (attachmentContainerRef.value)
                attachmentContainerRef.value.scrollLeft =
                    attachmentContainerRef.value?.scrollWidth || 0;
        }, 200);

        if (options?.isUploadRightAway) _uploadFiles(newFiles);

        isOpenRecording.value = false;
        onChange();
    };

    const onRemoveFile = (file) => {
        console.log('🚀 Tictop ~ file:', file);
        if (!attachmentFiles.value?.length) return;

        if (options?.isUploadRightAway) {
            if (!file?.localId) return;
            removeAwsFiles([file]);
            attachmentFiles.value = attachmentFiles.value?.filter(
                (f) => f?.localId !== file?.localId
            );
        } else {
            if (!file?.localId && !file?.id) return;

            const fileIndex = attachmentFiles.value.findIndex(
                (o) =>
                    (file?.localId && o.localId == file.localId) ||
                    (file?.id && o.id == file.id)
            );
            if (fileIndex > -1) {
                attachmentFiles.value[fileIndex].editStatus = 'REMOVED';
            }
        }

        onChange();
    };

    // Start note
    const isOpenNote = ref(false);
    const noteList = ref<any[]>(
        options?.defaultNotes && options?.defaultNotes?.length > 0
            ? [
                  ...options?.defaultNotes?.map((note) => {
                      return {
                          ...note,
                          isNote: true,
                          drawData:
                              typeof note?.drawData == 'string'
                                  ? JSON.parse(note?.drawData)
                                  : note?.drawData,
                      };
                  }),
              ]
            : []
    );
    const drawNoteData = ref({
        id: '',
        isLoading: false,
        drawData: {},
        images:
            // currentTask.value.files.filter((o) => o.type?.startsWith('image')) ||
            [],
        originFile: '',
        readonly: false,
    } as any);

    const onCancelNote = () => {
        isOpenNote.value = false;
        drawNoteData.value = {
            isLoading: false,
            drawData: {},
            images:
                // currentTask.value.files.filter((o) => o.type?.startsWith('image')) ||
                [],
            originFile: '',
            readonly: false,
        };
    };

    // const handlePasteAndSave = (noteData) => {
    //   const drawObjects = noteData?.drawData?.objects;

    //   const hasOnlyOneImage =
    //     drawObjects?.length == 1 && drawObjects[0]?.type == 'image';
    //   return hasOnlyOneImage
    //     ? {
    //         name: '',
    //         fileUrl: drawObjects[0].src,
    //         size: noteData?.base64?.length * (3 / 4) - 2,
    //         type: 'image/jpeg',
    //         isBase64: true,
    //       }
    //     : {};
    // };

    const isPastedImage = (objects) => {
        return (
            objects.length == 1 &&
            objects[0].type.includes('image') &&
            isPasted.value &&
            originalFiles.value?.length == 1
        );
    };

    const isPasted = ref(false);

    const updateNoteById = (noteId, noteData) => {
        const noteIndex = noteList.value.findIndex((o) => o.localId == noteId);
        if (noteIndex > -1) {
            noteList.value[noteIndex].fileUrl = noteData?.base64;
            noteList.value[noteIndex].base64 = noteData?.base64;
            noteList.value[noteIndex].drawData = noteData?.drawData;
            if (noteList.value[noteIndex].editStatus === 'ORIGINAL')
                noteList.value[noteIndex].editStatus = 'EDITED';
        }

        onChange();
        if (options.isUploadRightAway) {
            removeAwsNotes([noteList.value[noteIndex]]);
            _uploadNotes([
                {
                    ...noteList.value[noteIndex],
                    editStatus: 'ADDED',
                },
            ]);
        }
    };
    const addNewNote = (noteData) => {
        const newNote = _prepareNewNote(noteData);
        noteList.value = [...noteList.value, newNote];

        setTimeout(() => {
            if (attachmentContainerRef.value)
                attachmentContainerRef.value.scrollLeft =
                    attachmentContainerRef.value?.scrollWidth || 0;
        }, 200);
        onChange();
        if (options?.isUploadRightAway) _uploadNotes([newNote]);
    };
    const onSaveNote = (noteData) => {
        try {
            const currentNoteId = drawNoteData.value?.localId;
            if (currentNoteId) {
                updateNoteById(currentNoteId, noteData);
            } else if (isPastedImage(noteData?.drawData?.objects)) {
                const newFile = _prepareNewFile(originalFiles.value[0]);

                attachmentFiles.value = [...attachmentFiles.value, newFile];

                setTimeout(() => {
                    if (attachmentContainerRef.value)
                        attachmentContainerRef.value.scrollLeft =
                            attachmentContainerRef.value?.scrollWidth || 0;
                }, 200);
                _uploadFiles([newFile]);
            } else addNewNote(noteData);

            isPasted.value = false;
            onChange();
        } catch (error) {
            console.log('🚀 ~ onCreateNote ~ error', error);
        } finally {
            onCancelNote();
        }
    };

    const onFetchUpdateNote = async (noteData) => {
        console.log(noteData);
        // await currentTask.value.updateNote(noteData);

        onCancelNote();
    };

    const getDrawDataFromServer = async (noteId) => {
        const resDraw = await TaskService.getInstance().getDrawDataById(noteId);

        return JSON.parse(resDraw?.result?.drawData);
    };

    const originalFiles = ref<any>('');
    const onOpenCreateNote = async (defaultData?, pasted?, defaultFiles?) => {
        isPasted.value = pasted;
        originalFiles.value = defaultFiles;
        let drawData = defaultData?.drawData;

        if (defaultData?.id && !drawData) {
            drawData = await getDrawDataFromServer(defaultData?.id);
        }

        drawNoteData.value = {
            id: defaultData?.id || '',
            localId: defaultData?.localId || '',
            name: defaultData?.name || '',
            isLoading: false,
            drawData: drawData,
            images:
                // currentTask.value.files.filter((o) => o.type?.startsWith('image')) ||
                [],
            originFile: '',
            readonly: false,
        };
        isOpenNote.value = true;
    };

    const onRemoveNote = async (note) => {
        if (!noteList.value?.length) return;
        if (options?.isUploadRightAway) {
            if (!note?.localId) return;
            removeAwsNotes([note]);
            noteList.value = noteList.value?.filter(
                (f) => f?.localId !== note?.localId
            );
        } else {
            const noteIndex = noteList.value.findIndex(
                (o) => o.localId == note.localId
            );
            if (noteIndex > -1) {
                noteList.value[noteIndex].editStatus = 'REMOVED';
            }
        }
        onChange();
    };

    const onEditNote = (data) => {
        drawNoteData.value = data;
        isOpenNote.value = true;
    };

    // End note

    const pastedImage = ref<any>();
    const onReset = () => {
        attachmentFiles.value =
            options?.defaultFiles && options?.defaultFiles?.length > 0
                ? [...options?.defaultFiles]
                : [];

        noteList.value =
            options.defaultNotes && options.defaultNotes?.length > 0
                ? options.defaultNotes?.map((note) => {
                      return {
                          id: note?.id || uuidv4(),
                          name: note?.name || `Note-Task ${Date.now()}`,
                          fileUrl:
                              note?.fileUrl || note?.base64 || note?.imagePath,
                          base64: note?.base64,
                          path: note?.path || note?.imagePath,
                          type: 'image/jpeg',
                          drawData: note?.drawData,
                          isNote: true,
                          creationTime: note?.creationTime || new Date(),
                          editStatus: note?.editStatus || 'ORIGINAL',
                          originalData: note?.originalData || note,
                      };
                  })
                : [];
        onChange();
    };

    const onPasteFiles = async (event) => {
        // console.log('taskcommentInput');
        const files = (event.clipboardData || event.originalEvent.clipboardData)
            .files;

        if (!files || files?.length == 0) return;

        // 1. if image file => open note
        if (files?.length == 1 && files[0].type.includes('image')) {
            onOpenCreateNote(null, true, files);
            pastedImage.value = files[0];
            return;
        }

        // 2. If not image file => attachment

        onAddFiles(files);
    };

    const allAttachments = computed<any[]>(() => {
        return arrayOrderBy(
            [
                ...attachmentFiles.value?.filter(
                    (file) => file?.editStatus !== 'REMOVED'
                ),
                ...noteList.value?.filter(
                    (note) => note?.editStatus !== 'REMOVED'
                ),
            ],
            [(data) => dayjs(data?.creationTime)],
            [' desc']
        );
    });

    // PRIVATE
    const _prepareNewFile = (file) => {
        const newId = uuidv4();
        return {
            localId: newId,
            name: file?.name,
            type: file?.type,
            fileUrl: URL.createObjectURL(file),
            creationTime: new Date(),
            blob: file,
            size: file.size,
            editStatus: 'ADDED',
        } as IAttachmentFileDetail;
    };
    const _prepareNewNote = (noteData) => {
        const newId = uuidv4();
        return {
            id: newId,
            localId: newId,
            name: `Note-Task ${Date.now()}`,
            fileUrl: noteData?.base64,
            base64: noteData?.base64,
            type: 'image/jpeg',
            drawData: noteData?.drawData,
            isNote: true,
            creationTime: new Date(),
            editStatus: 'ADDED',
        } as IDrawNoteDetail;
    };
    const _uploadFiles = async (newAttachments: IAttachmentFileDetail[]) => {
        if (!options?.isUploadRightAway) return;

        uploadingFiles.value = [...uploadingFiles.value, ...newAttachments];

        try {
            // upload to AWS S3
            const uploadedFiles = await uploadFilesToAws(
                ModuleSourceFileEnum.task,
                newAttachments,
                {
                    isClone: false,
                }
            );

            uploadedFiles.forEach((file) => {
                const fileIndex = attachmentFiles.value?.findIndex(
                    (f) => f?.localId == file?.localId
                );
                if (fileIndex > -1) {
                    attachmentFiles.value[fileIndex] = {
                        ...attachmentFiles.value[fileIndex],
                        ...file,
                        id: undefined,
                    };
                }
            });

            uploadingFiles.value = uploadingFiles.value.filter(
                (file) => !newAttachments.some((f) => f?.id == file?.id)
            );
            onChange();
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
            uploadingFiles.value = [];
        }
    };
    const _uploadNotes = async (newNotes) => {
        uploadingFiles.value = [...uploadingFiles.value, ...newNotes];
        try {
            // upload to AWS S3
            const uploadedNotes = await uploadNotesToAws(newNotes, {
                isClone: false,
            });

            uploadedNotes.forEach((note) => {
                const fileIndex = noteList.value?.findIndex(
                    (f) => f?.localId == note?.localId
                );
                if (fileIndex > -1) {
                    noteList.value[fileIndex] = {
                        ...noteList.value[fileIndex],
                        ...note,
                        id: undefined,
                    };
                }
            });

            uploadingFiles.value = uploadingFiles.value.filter(
                (file) => !newNotes.some((f) => f?.id == file?.id)
            );

            onChange();
        } catch (error) {
            console.log('🚀 Tictop ~ error:', error);
        }
    };

    const onCloseNote = () => {
        // console.log('onCloseNote');
        isOpenNote.value = false;
        isPasted.value = false;
    };

    const reUpload = (fileLocalId) => {
        const reUploadFile = attachmentFiles.value?.find(
            (file) => file?.localId == fileLocalId
        );
        if (reUploadFile?.localId) _uploadFiles([reUploadFile]);
    };

    const initComposables = (_options: {
        files?: any[];
        notes?: any[];
        isUploadRightAway?: boolean;
    }) => {
        attachmentFiles.value =
            _options?.files && _options?.files?.length > 0
                ? [..._options?.files]
                : [];

        noteList.value =
            _options?.notes && _options?.notes?.length > 0
                ? [
                      ..._options?.notes?.map((note) => {
                          return {
                              ...note,
                              isNote: true,
                              drawData:
                                  typeof note?.drawData == 'string'
                                      ? JSON.parse(note?.drawData)
                                      : note?.drawData,
                          };
                      }),
                  ]
                : [];
    };
    return {
        attachmentContainerRef,
        allAttachments,
        attachmentFiles,
        uploadingFiles,
        onChooseFiles,
        onAddFiles,
        // AUDIO
        isOpenRecording,
        onCreateAudioDone,
        onRemoveFile,
        onReset,
        // Note
        isOpenNote,
        noteList,
        drawNoteData,
        pastedImage,
        onSaveNote,
        onCancelNote,
        onFetchUpdateNote,
        onOpenCreateNote,
        onRemoveNote,
        onEditNote,
        onPasteFiles,
        onCloseNote,
        reUpload,
        initComposables,
    };
}
