<script setup lang="ts">
import { DayoffType } from '@/domain/enums/DayoffEnums';
import { computed } from 'vue';
import { DayoffTypeUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';

const props = defineProps<{
  type: DayoffType;
  size?: 'small' | null;
  isActive?: boolean;
  isHiddenName?: boolean;
}>();

const classNameBySize = computed(() => {
  if (props.size == 'small') return 'h-4 px-2 text-2xs';
  return 'px-4 py-1.5';
});
const iconClassNameBySize = computed(() => {
  if (props.size == 'small') return 'w-2 h-2';
  return '';
});
</script>

<template>
  <!-- <div
    class="rounded flex-center space-x-2"
    :class="[classNameBySize]"
    :style="{
      backgroundColor: DayoffTypeUI[type]?.color,
    }"
  >
    <span class="text-white">{{
      $t(DayoffTypeUI[type]?.textCode) || DayoffTypeUI[type]?.textDefault
    }}</span>
  </div> -->

  <div
    class="flex-center space-x-2 rounded-full border bg-opacity-50"
    :title="$t(DayoffTypeUI[type]?.textCode) || DayoffTypeUI[type]?.textDefault"
    :class="[classNameBySize]"
    :style="
      isActive
        ? {
            backgroundColor: DayoffTypeUI[type]?.color,
            borderColor: DayoffTypeUI[type]?.color,
          }
        : {
            borderColor: DayoffTypeUI[type]?.color,
          }
    "
  >
    <SynIcon
      :name="DayoffTypeUI[type]?.iconName"
      :custom-class="iconClassNameBySize"
      :style="
        isActive
          ? {
              fill: '#fff',
            }
          : {
              fill: DayoffTypeUI[type]?.color,
            }
      "
    />
    <span
      v-if="!isHiddenName"
      class="min-w-max"
      :style="
        isActive
          ? {
              color: '#fff',
            }
          : {
              color: DayoffTypeUI[type]?.color,
            }
      "
    >
      {{ $t(DayoffTypeUI[type]?.textCode) || DayoffTypeUI[type]?.textDefault }}
    </span>
  </div>
</template>
