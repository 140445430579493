<script setup lang="ts">
import { ref, computed } from 'vue';
import authStore from '@/store/auth';
import StartConference from '@/ui/modules/video-call/components/StartConference.vue';
import conferenceStore from '@/store/conference';
import CallingStatus from '@/ui/modules/video-call/components/CallingStatus.vue';

const _conferenceStore = conferenceStore();
const _authStore = authStore();
const currentTab = ref('histories');
const isOpenCall = ref(false);
const dropdownRef = ref(null as any);
const myCallingStatus = computed(() => _authStore.callingStatus);
const myCurrentCallId = computed(() => myCallingStatus.value?.callId);

const onDropdownOpen = async () => {
  isOpenCall.value = true;
  await _conferenceStore.getHistories();
  currentTab.value = 'histories';
};
const onCloseButtonCall = () => {
  currentTab.value = '';
  isOpenCall.value = false;
};
</script>

<template>
  <vig-dropdown
    ref="dropdownRef"
    placement="bottom"
    hide-on-click="toggle"
    @on-dropdown-open="onDropdownOpen"
    @on-dropdown-close="onCloseButtonCall"
  >
    <template #dropdown-toggle>
      <button
        :title="isOpenCall ? '' : $t('INTRODUCE_LABEL_CALL_VIDEO')"
        class="h-8 w-8 flex-center hover:fill-current rounded-full relative"
      >
        <SynIcon
          name="video-audio"
          class="fill-current-500"
          custom-class="w-6 h-6 fill-current"
          :is-active="isOpenCall"
          has-action
        />
      </button>
    </template>
    <template #dropdown-menu>
      <div
        class="flex flex-col"
        :style="'z-index: 20; width: 29rem; min-height: 450px; max-height: min(80vh, 800px);'"
      >
        <div class="px-4 pt-2 bg-white">
          <div
            class="
              text-md
              items-center
              justify-between
              space-x-2
              py-2
              flex
              font-medium
            "
          >
            <span>{{
              $t('COMMOM_LABEL_CALL_VIDEO_AUDIO') || 'Video/Audio Call'
            }}</span>
            <div class="dropdown-item" @click="dropdownRef?.onForceClose()">
              <SynIcon
                name="close"
                class="fill-gray-500"
                custom-class="w-4 h-4 text-gray-500"
                color="gray"
                has-action
              />
            </div>
          </div>
        </div>
        <CallingStatus v-if="isOpenCall && myCurrentCallId" />
        <div
          v-if="isOpenCall"
          class="flex flex-col flex-1 min-h-0 overflow-hidden"
        >
          <StartConference
            :current-tab="currentTab"
            :is-show-close="false"
            @on-close="dropdownRef?.onForceClose()"
            @update-current-tab="(value) => (currentTab = value)"
          />
        </div>
      </div>
    </template>
  </vig-dropdown>
</template>
