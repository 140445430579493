<script setup>
import { ref, toRefs } from 'vue';
import ModalFolderAdd from '../../folder/modal-folder-add/ModalFolderAdd.vue';
import ModalGedLinkAdd from '../../link/modal-link-add/ModalGedLinkAdd.vue';
import ModalGedNoteAdd from '../../note/modal-note-add/ModalGedNoteAdd.vue';
import ModalGedAudioAdd from '../../audio/modal-audio-add/ModalGedAudioAdd.vue';
import useResourcePrevent from '@/ui/composables/resource/resource-prevent-composables';

const props = defineProps({
  location: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits([
  'onFolderAdded',
  'onUploadFilesSelected',
  'onUploadClick',
]);

const { location } = toRefs(props);
const isAddFolder = ref();
const isAddLink = ref();
const isAddNote = ref();
const isAddAudio = ref();
const showPreventAddMsg = ref();

const { preventAddResourcesMsg } = useResourcePrevent(location);

const onFilesSelected = (event) => {
  emit('onUploadFilesSelected', Array.from(event.target.files));

  event.target.value = null;
};
</script>

<template>
  <vig-dropdown :disabled="!!preventAddResourcesMsg">
    <template #dropdown-toggle>
      <vig-button
        v-vig-tooltip="$t('GED_RESOURCE_ADD')"
        color="current"
        padding="p-1"
        rounded="rounded"
        class="w-8 h-8"
        @click="showPreventAddMsg = preventAddResourcesMsg"
      >
        <SynIcon name="Plus" custom-class="w-4 h-4 fill-white" />
      </vig-button>
    </template>
    <template #dropdown-menu>
      <div class="text-sm">
        <label
          role="button"
          class="
            px-4
            py-2
            flex
            items-center
            gap-3
            font-semibold
            text-gray-600
            border border-transparent
            hover:bg-gray-100
            dropdown-item
          "
          @click="$emit('onUploadClick', $event)"
        >
          <SynIcon name="FileUpload" class="fill-current-500" />
          <span>{{ $t('COMMON_LABEL_UPLOAD_FILE') }}</span>
          <input
            type="file"
            class="hidden"
            multiple
            @change="onFilesSelected"
          />
        </label>
        <vig-button
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start space-x-3 dropdown-item"
          @click="isAddFolder = true"
        >
          <SynIcon name="Folder" class="text-yellow-500" />
          <span>{{ $t('GED_FOLDER_NEW') }}</span>
        </vig-button>
        <vig-button
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start space-x-3 dropdown-item"
          @click="isAddLink = true"
        >
          <SynIcon
            name="Link"
            custom-class="w-3.5 h-3.5"
            class="text-blue-500"
          />
          <span>{{ $t('GED_LINK_NEW') }}</span>
        </vig-button>
        <vig-button
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start space-x-3 dropdown-item"
          @click="isAddNote = true"
        >
          <SynIcon name="noter" is-active class="fill-orange-500" />
          <span>{{ $t('GED_NOTE_NEW') }}</span>
        </vig-button>
        <vig-button
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start space-x-3 dropdown-item"
          @click="isAddAudio = true"
        >
          <SynIcon name="Microphone" is-active class="fill-blue-500" />
          <span>{{ $t('GED_AUDIO_NEW') }}</span>
        </vig-button>
      </div>
    </template>
  </vig-dropdown>

  <ModalFolderAdd
    v-if="isAddFolder"
    :location="location"
    @on-close="isAddFolder = false"
    @on-folder-added="
      isAddFolder = false;
      $emit('onFolderAdded', $event);
    "
  />

  <ModalGedLinkAdd
    v-if="isAddLink"
    :location="location"
    @on-close="isAddLink = false"
  />

  <ModalGedNoteAdd
    v-if="isAddNote"
    :location="location"
    @on-close="isAddNote = false"
  />

  <ModalGedAudioAdd
    v-if="isAddAudio"
    :location="location"
    @on-close="isAddAudio = false"
  />

  <syn-question-modal
    v-if="showPreventAddMsg"
    class="z-50"
    :visible="true"
    :header-text="$t('COMMON_LABEL_ALERT')"
    :content-text="showPreventAddMsg"
    :show-cancel-btn="false"
    :show-confirm-btn="true"
    :show-delete-btn="false"
    :confilm-label="$t('COMMON_LABEL_GOT_IT')"
    @on-confirm="showPreventAddMsg = null"
    @cancel="showPreventAddMsg = null"
  />
</template>
