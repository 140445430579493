export default class SynStepperItem {
    key: string;
    label: string;
    title: string;
    isActive: boolean;
    isFinished: boolean;
    slotProps: any;
    constructor(data) {
        this.key = data?.key;
        this.label = data?.label;
        this.title = data?.title;
        this.isActive = data?.isActive;
        this.isFinished = data?.isFinished;
        this.slotProps = data?.slotProps;
    }
}
