<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AlertVerifyEmail from '@/ui/components/auth/AlertVerifyEmail.vue';
import SetupTeamSideBar from '@/ui/components/layouts/sidebar/SetupTeamSideBar.vue';
import myProfileStore from '@/store/auth/my-profile';
import organizationStore from '@/store/organization';
// import SetupTeam from '@/ui/modules/member/setup';
import SettingLanguage from '../setting/SettingLanguage.vue';
import userStore from '@/store/user';
import appStore from '@/store/app';
import settingStore from '@/store/setting';
import { loginAgain } from '@/ui/hooks/commonHook';
import { SettingProgressEnum } from '@/ui/common/constants/constant';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import taskStore from '@/store/task';
import RouterInstanceClass from '@/ui/router/RouterInstanceClass';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const _myProfileStore = myProfileStore();
const _appStore = appStore();
const _userStore = userStore();
const _settingStore = settingStore();
const _organizationStore = organizationStore();
const _taskStore = taskStore();
const srcLogoLabel = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/logo_brand.svg`;
const userProfile = computed(() => myProfileStore().myProfile);
const router = useRouter();
const isProfile = computed(() => router.currentRoute.value.meta.isProfile);

const isFetching = ref(false);

const initDashboardData = async () => {
  try {
    _appStore.setIsLoading(true);

    await myProfileStore().fetchCurrentCatalogUser();
    await _myProfileStore.fetchCurrentLoginUser();
    await _settingStore.getOrganizationSettings();
    await RouterInstanceClass.getInstance().generateRoutes();

    var promise = [_userStore.getShortInfosUser(), _taskStore.getDataConfig()];

    Promise.all(promise).then(() => {
      _appStore.setIsLoading(false);
    });
  } catch {
    // _appStore.setIsLoading(false);
  }
};
initDashboardData();
const handleLogout = () => {
  loginAgain();
};
const openSetupProfile = async () => {
  router.push({
    name: 'SetupProfile',
  });
};

onMounted(async () => {
  const settingProgress =
    MyOrganizationSingleton.getInstance().getOrganizationSettingProgress();

  if (settingProgress == SettingProgressEnum.SelectInvitation) {
    await _organizationStore.getDraftTeam();
  }
});
</script>

<template>
  <div class="flex flex-1 flex-col h-screen font-roboto">
    <header
      class="
        h-18
        flex flex-wrap
        justify-between
        items-center
        py-3
        px-6
        bg-white
        border-b-2
        shadow-xl
      "
    >
      <div class="flex items-center justify-center min-w-min space-x-4">
        <syn-img :src="srcLogoLabel" class="h-7 cursor-pointer" />
      </div>
      <div class="flex-center">
        <div>
          <syn-avatar
            class="cursor-pointer text-current-800 pr-2"
            custom-class="w-8 h-8"
            :src="userProfile?.avatarUrl?.replace('original', 'mini')"
            :name="`${userProfile?.lastName ? userProfile?.lastName : ''} ${
              userProfile?.firstName ? userProfile?.firstName : ''
            }`"
            :ring="
              !userProfile?.isVerifiedPhone || !userProfile?.isVerifiedEmail
                ? 'ring-red-600 ring-2'
                : 'ring-white'
            "
            has-name
          ></syn-avatar>
        </div>

        <vig-dropdown>
          <template #dropdown-toggle>
            <SynIcon
              name="dots-vertical"
              has-action
              custom-class="h-5 w-5 hover:text-current"
            />
          </template>
          <template #dropdown-menu>
            <syn-dropdown-menu class="right-0 w-44">
              <syn-dropdown-item @click="openSetupProfile">
                <div class="flex items-center">
                  <SynIcon name="profile" />
                  <span class="pl-2">
                    {{ $t('COMMON_LABEL_PROFILE') || 'Profile' }}
                  </span>
                </div>
              </syn-dropdown-item>
              <setting-language is-setting-organization />
              <syn-dropdown-item class="text-red-500" @click="handleLogout()">
                <div class="flex items-center">
                  <SynIcon name="logout" />
                  <span class="pl-2">{{
                    $t('COMMON_LABEL_LOGOUT') || 'Log out'
                  }}</span>
                </div>
              </syn-dropdown-item>
            </syn-dropdown-menu>
          </template>
        </vig-dropdown>
      </div>
    </header>

    <div class="flex flex-1 overflow-hidden relative">
      <div class="border-r">
        <SetupTeamSideBar v-if="!isFetching" />
      </div>

      <main
        class="relative w-screen overflow-x-hidden overflow-y-hidden bg-gray-50"
      >
        <div class="relative h-full flex rounded-md flex-col">
          <alert-verify-email v-if="!isProfile" class="m-2" />
          <slot />
        </div>
      </main>
    </div>
  </div>
  <!-- <setup-team
    v-if="isSetupTeam"
    @close="isSetupTeam = false"
    @onCreateSuccess="onCreateSuccess"
  ></setup-team>
  <syn-congraturation v-if="createSuccess" @on-close="onCloseCongaturation">
    <span>
      Create your team success!
    </span>
  </syn-congraturation> -->
</template>
