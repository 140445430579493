<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import groupStore from '@/store/group';
import FormSetting from '@/ui/modules/group/detail/setting/FormSetting.vue';
import GroupUpdateMemberModal from '@/ui/modules/group/detail/GroupUpdateMemberModal.vue';
import { EGroupType } from '@/application/types/group/group.types';
import GroupDetailEntity, {
  IGroupDetailEntity,
} from '@/domain/entities/group/GroupDetailEntity';
import GroupDetailInformation from '@/ui/modules/group/detail/GroupDetailInformation.vue';
import EditableCard from '@/ui/components/common/EditableCard.vue';
import ModalSetDomain from '@/ui/components/group/ModalSetDomain.vue';
import GroupMemberList from '@/ui/modules/group/components/GroupMemberList.vue';
import { cloneDeep } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  groupId: number;
}>();

const emit = defineEmits<{
  (e: 'updateValue'): void;
  (e: 'deletedSuccess'): void;
  (e: 'updateInformationSuccess', data: any): void;
}>();

const _groupStore = groupStore();

const allGroupByIds = computed<IGroupDetailEntity>(() => {
  return _groupStore.allGroupByIds;
});
const groupDetail = computed<IGroupDetailEntity>(() => {
  if (
    !allGroupByIds.value ||
    !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
  )
    return {};
  return allGroupByIds.value[props.groupId];
});

const latestUpdatedTime = ref<number>(Date.now());
watch(
  () => groupDetail.value,
  () => {
    if (
      !allGroupByIds.value ||
      !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
    )
      return;

    groupDetailDataRef.value = new GroupDetailEntity(
      cloneDeep(groupDetail.value)
    );

    latestUpdatedTime.value = Date.now();
  },
  {
    deep: true,
  }
);

const groupDetailDataRef = ref<GroupDetailEntity>(new GroupDetailEntity({}));
const isLoading = ref<boolean>(true);
const initComponent = async () => {
  if (!props.groupId) return;

  const currentGroup = await _groupStore.getDetailGroupById(props.groupId);
  groupDetailDataRef.value = new GroupDetailEntity(currentGroup);

  isLoading.value = false;
};

const currentUser = computed(() => myProfileStore().myProfile);

const currentRemoveUserId = ref<number>(0);
const isLeftUser = ref(false);

const statusGroup = ref({
  INFO: {
    key: 'INFO',
    isEdit: false,
    isSave: false,
    label: 'TASK_CREATE_GROUP_FORM_INFO_GROUP',
  },
  MEMBER: {
    key: 'MEMBER',
    isEdit: false,
    isSave: false,
    label: 'GROUP_LABEL_MEMBER',
  },
  SCOPE: {
    key: 'SCOPE',
    isEdit: false,
    isSave: false,
    label: 'USERMANAGEMENT_LABEL_DOMAIN',
  },
  SETTING: {
    key: 'SETTING',
    isEdit: false,
    isSave: false,
    label: 'HEADER_LABEL_SETTINGS',
  },
});

const onEdit = (key) => {
  if (!groupDetailDataRef.value.hasPermission()) return;

  statusGroup.value[key].isEdit = true;
};

const onCancelInfo = (key) => {
  statusGroup.value[key].isEdit = false;
};

const latestUpdateTime = ref<number>(Date.now());
const onDeleteUser = async () => {
  isLeftUser.value = true;
  groupDetailDataRef.value.groupUsers =
    groupDetailDataRef.value.groupUsers.filter(
      (e) => e.userId != currentRemoveUserId.value
    );
  const payload = {
    groupId: groupDetailDataRef.value.id,
    active: false,
    memberInfos: groupDetailDataRef.value.groupUsers,
  };
  await _groupStore.addUserToGroup(payload);

  isLeftUser.value = false;
  currentRemoveUserId.value = 0;

  latestUpdateTime.value = Date.now();
};

const onUpdateMemberSuccess = (newGroupUsers) => {
  statusGroup.value['MEMBER'].isEdit = false;
  groupDetailDataRef.value.groupUsers = newGroupUsers;
};

// profile

// domain
const onUpdateScope = async (selectedDomains) => {
  statusGroup.value['SCOPE'].isSave = true;
  const newDomainIds = selectedDomains?.map((d) => d?.id);
  await _groupStore.createAndUpdate({
    id: groupDetailDataRef.value.id,
    name: groupDetailDataRef.value.name,
    description: groupDetailDataRef.value.description,
    isPrivate: groupDetailDataRef.value.isPrivate,
    email: groupDetailDataRef.value.email,
    avatar: groupDetailDataRef.value.avatarPath,
    type: groupDetailDataRef.value.type,
    memberInfos: groupDetailDataRef.value.groupUsers,
    domainIds: newDomainIds,
  });

  groupDetailDataRef.value.groupDomains = selectedDomains?.map((d) => {
    return {
      ...d,
      domainId: d?.id,
      domainName: d?.name,
    };
  });
  statusGroup.value['SCOPE'].isSave = false;
  statusGroup.value['SCOPE'].isEdit = false;
};

const updateInformationSuccess = (data: IGroupDetailEntity) => {
  groupDetailDataRef.value = new GroupDetailEntity(data);
  emit('updateInformationSuccess', data);
};

const changePrivateSuccess = (isPrivate) => {
  groupDetailDataRef.value.changePrivateSuccess(isPrivate);

  latestUpdateTime.value = Date.now();
};

const updateMemberSuccess = () => {
  latestUpdateTime.value = Date.now();
};

initComponent();
</script>
<template>
  <div v-if="!isLoading" class="flex h-full space-x-2 p-2">
    <!-- NAME/AVT GROUP- -->
    <section class="h-full w-1/4">
      <EditableCard :title="$t('COMMON_LABEL_INFORMATION')">
        <template #body>
          <div class="flex flex-1 flex-col h-full">
            <GroupDetailInformation
              v-if="groupDetailDataRef?.id"
              :key="latestUpdatedTime"
              :group-id="groupDetailDataRef?.id"
              @update-success="updateInformationSuccess"
            />
          </div>
        </template>
      </EditableCard>
    </section>

    <!-- MEMBER -->
    <section class="h-full w-1/4">
      <EditableCard :title="$t('DASHBOARD_TABLE_LABEL_MEMBER')">
        <template #header>
          <div class="flex items-center justify-between">
            <span class="text-current-600 font-semibold">
              {{
                $t('GROUP_LABEL_MEMBER') +
                ' (' +
                groupDetailDataRef.groupUsers?.length +
                ')'
              }}
            </span>
            <div
              class="
                hover:bg-current-100
                p-2
                rounded-full
                flex
                items-center
                cursor-pointer
                justify-center
              "
              @click.stop="onEdit('MEMBER')"
            >
              <SynIcon
                name="edit"
                is-active
                class="text-current-500 fill-current"
              />
            </div>
          </div>
        </template>
        <template #body>
          <GroupMemberList
            v-if="!statusGroup['MEMBER'].isEdit"
            :key="latestUpdateTime"
            :group-id="groupDetailDataRef?.id"
            @update-member-success="updateMemberSuccess"
          />
        </template>
      </EditableCard>
    </section>

    <!-- DOMAIN -->
    <section class="h-full w-1/4">
      <EditableCard>
        <template #header>
          <div class="flex items-center justify-between">
            <span class="text-current-600 font-semibold">
              {{
                $t('USERMANAGEMENT_LABEL_DOMAIN') +
                ' (' +
                groupDetailDataRef.groupDomains.length +
                ')'
              }}
            </span>
            <div
              class="
                hover:bg-current-100
                p-2
                rounded-full
                flex
                items-center
                cursor-pointer
                justify-center
              "
              @click.stop="onEdit('SCOPE')"
            >
              <SynIcon
                name="edit"
                is-active
                class="text-current-500 fill-current"
              />
            </div>
          </div>
        </template>
        <template #body>
          <div class="bg-white text-sm h-full overflow-y-auto small-scrollbar">
            <div v-if="groupDetailDataRef.groupDomains.length < 1">
              <syn-animation
                name="searchNotFound"
                stype="width: 100px; height: 100px"
                label="No scope"
              />
            </div>
            <div class="flex flex-col h-full pb-4">
              <div
                v-for="domain in groupDetailDataRef.groupDomains"
                :key="domain?.domainId"
                class="py-2 flex items-center space-x-3 px-4"
              >
                <SynIcon
                  name="checkbox"
                  class="fill-current"
                  :is-active="true"
                />
                <label class="text-sm">{{ domain.domainName }}</label>
              </div>
            </div>
          </div>
        </template>
      </EditableCard>
    </section>

    <!-- SETTING -->
    <section class="h-full w-1/4">
      <EditableCard>
        <template #header>
          <div class="flex items-center justify-between">
            <span class="text-current-600 font-semibold">
              {{ $t('HEADER_LABEL_SETTINGS') }}
            </span>
          </div>
        </template>
        <template #body>
          <FormSetting
            :group-id="groupDetailDataRef?.id"
            @deleted-success="$emit('deletedSuccess')"
            @changed-private-success="changePrivateSuccess"
          />
        </template>
      </EditableCard>
    </section>
  </div>

  <!-- add user -->
  <GroupUpdateMemberModal
    v-if="statusGroup['MEMBER'].isEdit"
    :group-detail="groupDetailDataRef"
    @on-cancel="onCancelInfo('MEMBER')"
    @on-update-success="onUpdateMemberSuccess"
  />

  <!-- add scope -->
  <ModalSetDomain
    v-if="
      statusGroup['SCOPE'].isEdit &&
      groupDetailDataRef.type == 'NORMAL' &&
      groupDetail?.id
    "
    :group-id="groupDetail?.id"
    @on-close="onCancelInfo('SCOPE')"
    @on-confirmed="onUpdateScope"
  />

  <!-- leave group -->
  <SynQuestionModal
    class="z-50"
    :visible="currentRemoveUserId"
    :show-confirm-btn="false"
    :show-delete-btn="true"
    :header-text="$t('GROUP_LABEL_LEFT_USER')"
    :content-text="
      currentRemoveUserId !== currentUser.id
        ? $t('GROUP_LABEL_CONFIRM_LEFT_USER')
        : $t('GROUP_LABEL_CONFIRM_LEFT_GROUP')
    "
    :delete-label="$t('COMMON_LABEL_DELETE')"
    :is-loading="isLeftUser"
    @on-delete="onDeleteUser"
    @cancel="currentRemoveUserId = 0"
  />
  <!-- popup alert not change 1 domain -->
  <SynQuestionModal
    class="z-50"
    :visible="
      statusGroup['SCOPE'].isEdit &&
      groupDetailDataRef.type == EGroupType.DOMAIN
    "
    :show-confirm-btn="false"
    :show-delete-btn="false"
    :header-text="$t('COMMON_LABEL_CONFIRM')"
    :content-text="$t('GROUP_LABEL_ALERT_NOT_CHANGE_DOMAIN')"
    @on-delete="
      (value) => {
        statusGroup['SCOPE'].isEdit = value;
        statusGroup['MEMBER'].isEdit = value;
      }
    "
  />
</template>
