<template>
  <nav :class="navClass">
    <button
      v-for="item in items"
      :key="item.key"
      :class="isActive == item.key ? 'tab-active' : 'tab-default'"
      class="flex-auto"
      @click="changeTab(item.key)"
    >
      {{ item.name }}
    </button>
  </nav>
</template>
<script lang="ts">
import { defineComponent, ref, computed, toRefs } from 'vue';

export default defineComponent({
  name: 'SynTab',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    tabType: {
      type: String,
      default: 'grid',
    },
  },
  emits: ['changeTab'],
  setup: (props, { emit }) => {
    const { items, tabType } = toRefs(props);
    let isActive = ref('email');
    const changeTab = (key) => {
      isActive.value = key;
      emit('changeTab', key);
    };

    const navClass = computed(() => {
      return tabType.value == 'grid'
        ? `grid grid-cols-${items.value.length}`
        : 'flex flex-row  justify-center';
    });
    return { isActive, changeTab, navClass };
  },
});
</script>
<style lang="postcss" scoped>
.tab-active {
  @apply py-2 block hover:text-current-500 focus:outline-none text-current-500 border-b-4 font-medium border-current-500;
}
.tab-default {
  @apply text-gray-500 font-normal py-2 block hover:text-current-500 focus:outline-none border-b-2;
}
</style>
