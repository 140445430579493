<script setup lang="ts">
import { onMounted, ref } from 'vue';
import TaskSharingService from '@/application/services/task/TaskSharingService';
import TaskDetailSharing from '@/ui/modules/task/detail/task-detail-sharing/TaskDetailSharing.vue';
import UnAuthService from '@/application/services/un-authenticate/UnAuthenticateService';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const props = defineProps<{
  snapshot: any;
}>();

defineEmits(['onClose']);

const _unAuthService = UnAuthService.getInstance();

const isFetching = ref(true);
const taskDetail = ref();

onMounted(async () => {
  try {
    await _getSnapshotTaskDetail();
  } catch (e) {
    console.log(e);
  }

  isFetching.value = false;
});

const onCopySnapshotLinkClick = () => {
  TaskSharingService.copySharedTaskLink(props.snapshot?.sharedId);
};

const onDeleteSnapshotClick = () => {
  TaskSharingService.deleteTaskSnapshot(props.snapshot);
};

const _getSnapshotTaskDetail = async () => {
  if (!props.snapshot?.sharedId) return;

  const sharingInfo = await _unAuthService.getTaskShared(
    props.snapshot?.sharedId
  );

  taskDetail.value = sharingInfo?.activeTaskInfo?.taskInfo;
};
</script>

<template>
  <SynModal
    container-class="w-11/12 max-w-3xl"
    container-style=""
    style-body="px-4"
    @cancel="$emit('onClose')"
  >
    <template #header> {{ snapshot?.name }}</template>

    <template #body>
      <div v-if="!isFetching" class="relative">
        <TaskDetailSharing
          v-if="taskDetail"
          :task-detail="taskDetail"
          :activities="['COMMENT', 'TODO_LIST']"
        />
      </div>

      <VigLoading v-else class="h-56" />
    </template>

    <template #footer>
      <div class="px-4 py-2 flex gap-2">
        <VigButton outline color="black" @click="onCopySnapshotLinkClick">
          <SynIcon name="Duplicate" />
          <span>{{ $t('TASK_DETAIL_COPY_LINK') }}</span>
        </VigButton>
        <VigButton outline color="red" @click="onDeleteSnapshotClick">
          <SynIcon name="Trash" />
          <span>{{ $t('COMMON_LABEL_DELETE') }}</span>
        </VigButton>
        <VigButton
          light
          color="gray"
          label="COMMON_LABEL_CLOSE"
          class="ml-auto"
          @click="$emit('onClose')"
        />
      </div>
    </template>
  </SynModal>
</template>
