<script setup lang="ts">
import { computed } from 'vue';
import { taskStates } from '@/ui/hooks/taskHook';

const taskStateList = computed(() => {
  if (!taskStates) return [];

  const stateList = Object.values(taskStates);

  return stateList.sort((a, b) => {
    return a.index - b.index;
  });
});
</script>
<template>
  <div class="w-max p-4 text-left bg-white flex flex-col space-y-4 rounded">
    <span class="text-current">
      {{ $t('TASK_EXPLAIN_STATE_QUESTION') || 'What is the state?' }}
    </span>
    <div class="flex-start-center flex-col space-y-2">
      <div
        v-for="state in taskStateList"
        :key="state"
        class="flex items-center space-x-2"
      >
        <span
          class="w-2 h-2 rounded-full"
          :class="`bg-${state.colorKey}`"
        ></span>
        <span class="w-32" :class="state.color">{{ $t(state.nameCode) }}</span>
        :
        <span class="flex-1 text-sm">
          {{ $t(state.descriptionCode) }}
        </span>
      </div>
    </div>
  </div>
</template>
