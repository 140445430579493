<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
// import { PlanListNew } from '@/ui/modules/settings/payments/compare-plan/plansList';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIcon.vue';
import paymentStore from '@/store/plans';
import appStore from '@/store/app';

const _appStore = appStore();
const _paymentStore = paymentStore();

const functionPlans = computed(() => {
  return _paymentStore.allPlanComparativeCriteria;
});
const lang = computed(() => _appStore.language || 'en');
const pricePackages = computed(() => _paymentStore.allPlansDetail);

const isLoading = ref(false);

const groupCriteriaOpenByIds = ref<any>({});

onMounted(async () => {
  isLoading.value = true;
  await _paymentStore.getAllPlanComparativeCriteria();
  // console.log('functionPlans', functionPlans.value);
  groupCriteriaOpenByIds.value = functionPlans.value?.reduce(
    (currentObj, currentItem) => {
      return {
        ...currentObj,
        [currentItem?.criteriaGroupId]: true,
      };
    },
    {}
  );

  isLoading.value = false;
});
</script>

<template>
  <template v-if="isLoading">
    <div class="w-full flex items-center space-x-3">
      <div
        v-for="item in 3"
        :key="item"
        class="rounded-md bg-gray-100 animate-pulse h-32 w-42"
      ></div>
    </div>
  </template>
  <table class="table-container table-fixed border-collapse">
    <thead class="sticky top-2 bg-white z-20">
      <tr class="bg-white">
        <th class="w-1/4"></th>
        <template v-for="item in pricePackages" :key="item">
          <th
            class="
              w-1/4
              text-center
              p-4
              text-lg
              lg:text-2xl
              font-bold
              border-white
              relative
            "
          >
            <div class="uppercase text-current-500">{{ item?.name }}</div>
            <div class="font-normal text-base h-8">
              <span class="text-xl text-gray-600">
                {{ item?.descriptionWebCommercial }}</span
              >
            </div>

            <div
              v-if="!item?.isActive"
              class="absolute"
              style="right: -1.4rem; top: -0.6rem"
            >
              <div class="flex-center relative">
                <SynIcon name="tag" custom-class="h-28" />
                <span
                  class="absolute text-white text-center font-bold rotate-45"
                  style="top: 24%; left: 24%; font-size: 0.7rem"
                >
                  COMING SOON
                </span>
              </div>
            </div>
          </th>
        </template>
      </tr>
    </thead>

    <tbody>
      <template v-for="func in functionPlans" :key="func">
        <tr>
          <td class="p-2"></td>
          <td class="p-2"></td>
          <td class="p-2"></td>
          <td class="p-2"></td>
        </tr>
        <!-- TITLE FUNC   -->
        <tr
          class="cursor-pointer"
          @click="
            groupCriteriaOpenByIds[func?.criteriaGroupId] =
              !groupCriteriaOpenByIds[func?.criteriaGroupId]
          "
        >
          <td class="p-4 bg-gray-700 flex justify-between rounded-l-xl">
            <span class="text-white text-xl font-semibold">
              {{ func?.translations[`${lang}`]?.name }}
            </span>
          </td>
          <td class="p-4 bg-gray-700"></td>
          <td class="p-4 bg-gray-700"></td>
          <td class="p-4 bg-gray-700 rounded-r-xl">
            <div class="flex justify-end">
              <SynIcon
                name="ChevronDown2"
                custom-class="h-5 w-5 fill-white"
                :class="
                  !groupCriteriaOpenByIds[func?.criteriaGroupId]
                    ? '-rotate-90'
                    : ''
                "
              />
            </div>
          </td>
        </tr>
        <!--        <td class="border-l-8 border-white bg-gray-50 bg-opacity-50"></td>-->
        <!-- CONTENT FUNC   -->
        <template v-if="groupCriteriaOpenByIds[func?.criteriaGroupId]">
          <tr
            v-for="subFunc in func.criteria"
            :key="subFunc"
            class="border-b border-gray-100 border-dashed"
          >
            <td class="function_label pl-2 pr-4 py-3.5 text-lg text-gray-700">
              <span v-vig-tooltip="subFunc.criteriaTranslations[lang]?.name">
                {{ subFunc.criteriaTranslations[lang]?.name }}
              </span>
            </td>

            <template
              v-for="measurement in subFunc.measurementValues"
              :key="measurement"
            >
              <td class="px-2 py-3.5 text-center text-sm">
                <SynIcon
                  v-if="measurement?.measurementValue === 'true'"
                  name="check"
                  custom-class="w-6 h-6 "
                />
                <span
                  v-else-if="
                    !measurement?.measurementValue ||
                    measurement?.measurementValue == 'false'
                  "
                ></span>
                <span v-else class="text-gray-600 px-3 text-base">
                  {{ measurement?.measurementValue }}
                </span>
              </td>
            </template>
          </tr>
        </template>
      </template>

      <!--  ACTION  -->
      <tr>
        <td></td>
        <!--      <td class="py-8 px-8 text-center border-white bg-gray-50 bg-opacity-50">-->
        <!--        <div class="flex flex-col items-start space-y-3">-->
        <!--          <slot :key="'FREE'" name="action-button">-->
        <!--            &lt;!&ndash;              <SynButton&ndash;&gt;-->
        <!--            &lt;!&ndash;                :label="$t('PLAN_BUTTON_GET_STARTED')"&ndash;&gt;-->
        <!--            &lt;!&ndash;                type-fill&ndash;&gt;-->
        <!--            &lt;!&ndash;                color="current"&ndash;&gt;-->
        <!--            &lt;!&ndash;                custom-class="py-3 px-2 w-full"&ndash;&gt;-->
        <!--            &lt;!&ndash;              />&ndash;&gt;-->
        <!--          </slot>-->
        <!--        </div>-->
        <!--      </td>-->
        <template v-for="item in pricePackages" :key="item?.id">
          <td
            class="
              pt-8
              text-center
              border-l-8 border-white
              bg-gray-50 bg-opacity-50
            "
          >
            <div class="flex flex-col items-start space-y-3">
              <slot
                :key="item?.id"
                name="action-button"
                :is-active="item?.isActive"
              >
                <SynButton
                  :label="$t('PLAN_BUTTON_GET_STARTED')"
                  type-fill
                  :disabled="item?.isActive == false"
                  color="current"
                  custom-class="py-3 px-2 w-full"
                />
              </slot>
            </div>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<style scoped></style>
