<script setup lang="ts">
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import TaskWorkflowStepDetail from '@/ui/modules/task/workflow/step/TaskWorkflowStepDetail.vue';
defineProps<{
  viewMode?: 'TASK_VIEW' | 'ATTACH_TO_TASK';
  stepDetail: ITaskWorkflowStep;
  taskDetail?: ITaskDetailClass;
  readonly?: boolean;
  taskWorkflowId?: string;
  isUpdateOnlyAttachment?: boolean;
}>();
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'updateSuccess', newData: any, workflowStepData: any): void;
}>();

const updateSuccess = (newData, workflowStepData) => {
  emit('updateSuccess', newData, workflowStepData);
  emit('close');
};
</script>
<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-class="w-max"
    :container-style="'max-height: 95%;min-height: 24rem; width: 44rem'"
    disable-click-outside
    is-hidden-footer
    is-hidden-header
    @cancel="$emit('close')"
  >
    <template #body>
      <TaskWorkflowStepDetail
        :step-detail="stepDetail"
        :view-mode="viewMode"
        :task-detail="taskDetail"
        :task-workflow-id="taskWorkflowId"
        :readonly="readonly"
        :is-update-only-attachment="isUpdateOnlyAttachment"
        @close="$emit('close')"
        @update-success="updateSuccess"
      />
    </template>
    <template #footer> </template>
  </SynModal>
</template>
