import TaskCreateUpdatePayload from '@/domain/entities/task/TaskCreateUpdatePayload';
import tictopIndexDB, { EObjectStore, ETransactionMode } from '@/indexedDB';
import { _prepareTaskIndexDBSchema } from './task-schema';

const objectStoreName = EObjectStore.tasks;
export default class CreateTaskFormSingleton {
    private static instance: CreateTaskFormSingleton;
    constructor() {}

    public static getInstance(): CreateTaskFormSingleton {
        if (!CreateTaskFormSingleton.instance) {
            // Get from local storage
            CreateTaskFormSingleton.instance = new CreateTaskFormSingleton();
        }

        return CreateTaskFormSingleton.instance;
    }

    async getStore() {
        const database = await tictopIndexDB.getInstance();

        return new Promise((resolve, reject) => {
            const transaction = database.transaction(
                objectStoreName,
                ETransactionMode.readonly
            );
            const store = transaction.objectStore(objectStoreName);

            const taskList = [] as any[];

            store.openCursor().onsuccess = (event: any) => {
                const cursor: any = event?.target?.result;
                if (cursor) {
                    taskList.push(cursor?.value);
                    cursor.continue();
                }
            };

            transaction.oncomplete = () => {
                resolve(taskList);
            };

            transaction.onerror = (event) => {
                reject(event);
            };
        });
    }
    async getAll(): Promise<any[]> {
        const { store } = await this._getTransactionByMode(
            ETransactionMode.readonly
        );
        const request = store.getAll();

        return new Promise((resolve) => {
            request.onsuccess = () => {
                resolve(request?.result);
            };

            request.onerror = () => {
                resolve([]);
            };
        });
    }
    async getDetailByOwnerId(ownerId) {
        const { store } = await this._getTransactionByMode(
            ETransactionMode.readonly
        );
        const request = store.get(`${ownerId}`);

        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                resolve(request?.result);
            };

            request.onerror = () => {
                reject(`can't find data by owner id: ${ownerId}`);
            };
        });
    }

    async saveTask(ownerId, taskForm: TaskCreateUpdatePayload) {
        const task = _prepareTaskIndexDBSchema(ownerId, taskForm);

        const { transaction, store } = await this._getTransactionByMode(
            ETransactionMode.readwrite
        );

        store.put(task);

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve('save task completed!');
            };

            transaction.onerror = () => {
                reject("can't save task completed!");
            };
        });
    }
    async removeTask(ownerId) {
        const { transaction, store } = await this._getTransactionByMode(
            ETransactionMode.readwrite
        );

        store.delete(ownerId);

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve('save task completed!');
            };

            transaction.onerror = () => {
                reject("can't save task completed!");
            };
        });
    }
    async clearAll() {
        const { transaction, store } = await this._getTransactionByMode(
            ETransactionMode.readwrite
        );

        store.clear();

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve('clear completed!');
            };

            transaction.onerror = () => {
                reject('clear not completed!');
            };
        });
    }

    private async _getTransactionByMode(transactionMode: ETransactionMode) {
        const database = await tictopIndexDB.getInstance();
        const transaction = database.transaction(
            objectStoreName,
            transactionMode
        );

        return {
            transaction: transaction,
            store: transaction.objectStore(objectStoreName),
        };
    }
}
