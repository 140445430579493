import groupStore from '@/store/group';

export default function getGroupInfo(groupId) {
    // const _groupStore = groupStore();
    // const allGroupByIds = {
    //     ..._groupStore.allGroupByIds,
    //     ..._groupStore.myGroupByIds,
    // };
    // if (
    //     !allGroupByIds.value ||
    //     !Object.prototype.hasOwnProperty.call(allGroupByIds.value, groupId)
    // )
    //     return {};
    return groupStore().allGroupByIds[groupId] || {};
}
