<script setup lang="ts">
import { ref, computed } from 'vue';
import Message from '@/ui/components/icons/Message.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import DotsVertical from '@/ui/components/icons/DotsVertical.vue';
import { EActionOnTask } from '@/application/types/task/task.types';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { TASK_ACTION_LIST_DEFAULT } from '@/application/constants/task.const';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ClockHistory from '@/ui/components/icons/ClockHistory.vue';

const props = withDefaults(
  defineProps<{
    currentTask: ITaskDetailClass;
    readonly?: boolean;
    actionList?: string[];
  }>(),
  {
    viewMode: 'DEFAULT',
    actionList: () => [EActionOnTask.DISCUSS, EActionOnTask.REMINDER_TASK],
  }
);

const emit = defineEmits<{
  (e: 'delete'): void;
  (e: 'attachWorkflow', data: any): void;
  (e: 'update:loading', value: boolean): void;
  (e: 'openApprovalModal'): void;
  (e: 'unMaskAsApproval'): void;
  (e: 'cancelApprovalRequest'): void;
  (e: 'selectAction', key: EActionOnTask): void;
  (e: 'removeAsSubTask'): void;
  (e: 'setParentTask', task: any): void;
}>();

const isOpenMoreAction = ref<boolean>(false);

const defaultActionOnTaskList = computed<any[]>(() => {
  let _actionList = [...TASK_ACTION_LIST_DEFAULT];

  if (props.actionList && props.actionList?.length > 0)
    _actionList = _actionList?.filter((action) =>
      props.actionList?.some(
        (k) =>
          k == action?.key &&
          (!action?.needCheckPermissionOnTask ||
            (action?.needCheckPermissionOnTask && !props.readonly)) &&
          (!props.readonly || (props.readonly && action?.isIgnoreReadonly))
      )
    );

  return arrayOrderBy(_actionList, ['index'], ['asc']) || [];
});

const onSelectAction = (actionKey) => {
  emit('selectAction', actionKey);
};
</script>
<template>
  <VigDropdown
    v-model:is-open="isOpenMoreAction"
    :arrow="false"
    hide-on-click="toggle"
    placement="bottom-end"
    @click.stop="() => {}"
  >
    <template #dropdown-toggle>
      <div
        class="
          w-8
          h-8
          rounded-md
          cursor-pointer
          p-2
          hover:bg-gray-100
          flex-center
        "
        :class="isOpenMoreAction ? 'bg-gray-100' : ''"
      >
        <DotsVertical
          name="dots-vertical"
          class="w-4 h-4 fill-gray-500 text-gray-500"
        />
      </div>
    </template>
    <template #dropdown-menu>
      <div
        ref="actionOnTaskBodyRef"
        style="max-height: min(72vh, 600px)"
        class="overflow-auto small-scrollbar"
      >
        <div
          class="py-1 z-100 list-ul rounded cursor-pointer dropdown-item"
          style="min-width: 13rem"
        >
          <template
            v-for="action in defaultActionOnTaskList"
            :key="action?.key"
          >
            <div
              v-permission-function="{
                functionCode: action?.functionCode,
                needToShowPremiumIcon: true,
                onContinue: () => {
                  $emit('selectAction', action?.key);
                },
              }"
              class="
                list-li
                flex
                text-gray-600
                justify-start
                items-center
                space-x-2
                text-sm
                relative
              "
              @click="onSelectAction(action?.key)"
            >
              <Message
                v-if="action?.iconName == 'Message'"
                class="h-4 w-4 fill-gray-500"
              />
              <ClockHistory
                v-else-if="action?.iconName == 'ClockHistory'"
                class="h-4 w-4 fill-gray-500"
              />
              <span v-if="action?.key == EActionOnTask.PIN">
                {{
                  translate(
                    currentTask?.pinnedColor &&
                      currentTask?.pinnedColor !== 'UN_PIN'
                      ? 'CHAT_UNPIN'
                      : 'CHAT_PIN'
                  )
                }}
              </span>
              <span v-else-if="action?.key == EActionOnTask.FINISH">
                {{
                  translate(
                    currentTask?.approvalEnabled
                      ? 'COMMON_LABEL_APPROVAL_REQUEST'
                      : action?.labelKey
                  )
                }}
              </span>
              <span
                v-else-if="action?.key == EActionOnTask.LOCK_UPDATE_DEADLINE"
              >
                {{
                  translate(
                    currentTask?.scheduleTimeLocked
                      ? 'TASK_LABEL_UNLOCK_UPDATE_DEADLINE'
                      : 'TASK_LABEL_LOCK_UPDATE_DEADLINE'
                  )
                }}
              </span>
              <span v-else>
                {{ translate(action?.labelKey) }}
              </span>

              <span
                v-if="action?.key == 'SHARE' && currentTask?.sharedId"
                class="absolute right-1 w-2.5 h-2.5 rounded-full bg-green-500"
              >
              </span>
              <span
                v-if="
                  action?.key == 'SNAPSHOT' &&
                  props.currentTask?.taskSnapshotTotal > 0
                "
                class="absolute right-1 w-2.5 h-2.5 rounded-full bg-green-500"
              >
              </span>
            </div>
          </template>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
