<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M12.75 20a.75.75 0 0 1-.75.75 11.843 11.843 0 0 1-3.394-.48 7.225 7.225 0 0 1-4.749 1.48c-.144 0-.286 0-.423-.007a1.228 1.228 0 0 1-.749-2.162 4.124 4.124 0 0 0 1.455-2.155A8.3 8.3 0 0 1 2.25 12c0-5.151 4.01-8.75 9.75-8.75 5.272 0 9.165 3.081 9.686 7.667A8.878 8.878 0 0 1 21.75 12a.75.75 0 0 1-1.5 0 7.425 7.425 0 0 0-.05-.9c-.435-3.8-3.728-6.35-8.2-6.35-4.857 0-8.25 2.982-8.25 7.25a6.787 6.787 0 0 0 1.75 4.7.749.749 0 0 1 .19.625 5.391 5.391 0 0 1-1.507 2.914 5.326 5.326 0 0 0 3.68-1.329.749.749 0 0 1 .792-.2 10.208 10.208 0 0 0 3.345.543.75.75 0 0 1 .75.747zM21 16.75h-1.75V15a.75.75 0 0 0-1.5 0v1.75H16a.75.75 0 0 0 0 1.5h1.75V20a.75.75 0 0 0 1.5 0v-1.75H21a.75.75 0 0 0 0-1.5zM8 9.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5zm5 4H8a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5z"
      ></path>
    </g>
  </svg>
</template>
