<script setup lang="ts">
import { INotificationMainDataTaskUpdate } from '@/application/types/notification.types';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import { computed } from 'vue';
const props = defineProps<{
  beforeUpdate: any;
  afterUpdate: any;
  isHiddenBefore?: boolean;
  isHiddenAfter?: boolean;
}>();

const beforeData = computed<INotificationMainDataTaskUpdate>(() => {
  return covertSnakeCaseToCamelCase(props.beforeUpdate);
});
const afterData = computed<INotificationMainDataTaskUpdate>(() => {
  return covertSnakeCaseToCamelCase(props.afterUpdate);
});
</script>
<template>
  <div class="inline-flex space-x-1 w-full">
    <!-- BEFORE -->
    <template v-if="beforeData?.workflowStepId && !isHiddenBefore">
      <span
        class="items-center inline-flex text-green-500 relative"
        :style="{
          maxWidth:
            afterData?.workflowStepId && !isHiddenAfter ? '45%' : '100%',
        }"
      >
        <!-- ICON -->

        <p :title="beforeData?.workflowStepName" class="truncate text-sm">
          {{ beforeData?.workflowStepName }}
        </p>
      </span>
    </template>
    <template v-if="!isHiddenAfter">
      <p v-if="afterData?.workflowStepId && !isHiddenBefore">&#8594;</p>

      <!-- AFTER -->
      <template v-if="afterData?.workflowStepId">
        <span
          class="items-center inline-flex text-blue-500 relative"
          :style="{
            maxWidth:
              beforeData?.workflowStepId && !isHiddenBefore ? '45%' : '100%',
          }"
        >
          <p :title="afterData?.workflowStepName" class="truncate text-sm">
            {{ afterData?.workflowStepName }}
          </p>
        </span>
      </template>
    </template>
  </div>
</template>
