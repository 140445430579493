import { ComputedRef, computed } from 'vue';
import userStore from '@/store/user';

export default function userByIdComposable(
    originData: ComputedRef<{
        userId: number | string;
        user: any;
    }>
) {
    // *** PROPS, EMITS ***

    // *** PRIVATE VARIABLES ***

    // *** COMPOSABLES ***

    // *** REFS ***

    // *** COMPUTED ***
    const currentUserInfo = computed(() => {
        return (
            originData.value?.user ||
            userStore().allUserByIds[originData.value?.userId]
        );
    });

    const currentUserDepartmentId = computed(() => {
        if (
            !currentUserInfo.value ||
            !currentUserInfo.value?.departmentIds ||
            currentUserInfo.value?.departmentIds?.length == 0
        )
            return null;
        return currentUserInfo.value?.departmentIds[0];
    });
    // *** WATCHES ***

    // *** HOOKS ***

    // *** HANDLER FUNCTIONS ***

    // *** PRIVATE FUNCTIONS ***

    // *** EXPOSES ***

    return {
        currentUserInfo,
        currentUserDepartmentId,
    };
}
