import { translate } from '@/ui/plugins/i18n/myi18n';
import { useTitle } from '@vueuse/core';
let lastestDocumentTitle = '';
export default function customDocumentTitle() {
    const changeByRouter = (
        routeName?: string,
        pageOwer?: string,
        routeTitle?: string
    ) => {
        if (!routeName) return;
        let title;

        switch (routeName) {
            case RouteName.UserTasks.key:
            case RouteName.GroupTasks.key:
            case RouteName.DomainTasks.key:
                if (pageOwer) title = `[${pageOwer}]`;
                if (routeTitle) title = `[${pageOwer}] ${routeTitle}`;

                break;

            default:
                title = `${
                    translate(RouteName[routeName]?.translateKey) ||
                    RouteName[routeName]?.name ||
                    routeName
                }`;

                if (pageOwer) title = `${title} | ${pageOwer}`;

                break;
        }
        const newTitle = title ? `${title} | TicTop` : 'TicTop';
        updateDocumentTitle(newTitle);
        lastestDocumentTitle = newTitle;
    };

    const updateDocumentTitle = (newTitle) => {
        useTitle(newTitle);
    };

    const resetToLastestTitle = () => {
        updateDocumentTitle(lastestDocumentTitle);
    };

    const RouteName = {
        OverviewMonitorPage: {
            key: 'OverviewMonitorPage',
            name: 'OverviewMonitorPage',
            translateKey: 'COMMON_LABEL_OVERVIEW',
        },
        OverviewMembers: {
            key: 'OverviewMembers',
            name: 'Members',
            translateKey: 'DOCUMENT_TITLE_TEAM',
        },
        OverviewGroups: {
            key: 'OverviewGroups',
            name: 'Groups',
            translateKey: 'DOCUMENT_TITLE_GROUP',
        },
        OverviewDomains: {
            key: 'OverviewDomains',
            name: 'Domains',
            translateKey: 'DOMAIN_LABEL_TITLE',
        },
        OverviewFinishedTasks: {
            key: 'OverviewFinishedTasks',
            name: 'Domains',
            translateKey: 'OVERVIEW_FINISHED_TASKS',
        },
        OverviewCreatedTasks: {
            key: 'OverviewCreatedTasks',
            name: 'Domains',
            translateKey: 'CHART_LABEL_CREATED_TASK',
        },
        Messages: {
            key: 'Messages',
            translateKey: 'DOCUMENT_TITLE_MESSAGE',
        },
        Resources: {
            key: 'Resources',
            translateKey: 'DOCUMENT_TITLE_RESOURCE',
        },
        Calendars: {
            key: 'Calendars',
            translateKey: 'DOCUMENT_TITLE_CALENDAR',
        },
        MyTasks: {
            key: 'MyTasks',
            name: 'My tasks',
            translateKey: 'TASK_TABLE_LABEL_MY_TASKS',
        },
        UserTasks: {
            key: 'UserTasks',
            name: "User's tasks",
            translateKey: 'TASK_TABLE_LABEL_TASKS_OF_SOMEONE',
        },
        GroupTasks: {
            key: 'GroupTasks',
            name: "Goups's tasks",
            translateKey: 'TASK_TABLE_LABEL_TASKS_OF_SOMEONE',
        },
        DomainTasks: {
            key: 'DomainTasks',
            name: "Domain's tasks",
            translateKey: 'TASK_TABLE_LABEL_TASKS_OF_SOMEONE',
        },
        UserManagement: {
            key: 'UserManagement',
            translateKey: 'DOCUMENT_TITLE_USER_MGT',
        },
        ProjectManagement: {
            key: 'ProjectManagement',
            translateKey: 'DOCUMENT_TITLE_PROJECT_MGT',
        },
        GroupManagement: {
            key: 'GroupManagement',
            translateKey: 'DOCUMENT_TITLE_GROUP_MGT',
        },
        OrganizationSetting: {
            key: 'OrganizationSetting',
            translateKey: 'DOCUMENT_TITLE_ORG_SETTING',
        },
        PersonalSetting: {
            key: 'PersonalSetting',
            translateKey: 'DOCUMENT_TITLE_PERSONAL_SETTING',
        },
        WorkingTime: {
            key: 'WorkingTime',
            translateKey: 'DOCUMENT_TITLE_WORKING_TIME',
        },
        Profile: {
            key: 'Profile',
            translateKey: 'COMMON_LABEL_MY_PROFILE',
        },
        PlansSetting: {
            key: 'PlansSetting',
            translateKey: 'PAYMENT_MANAGE_PLAN',
        },
        DepartmentsManagement: {
            key: 'DepartmentsManagement',
            translateKey: 'DOCUMENT_TITLE_DEPARTMENTS_MANAGEMENT',
        },
        SystemDeployment: {
            key: 'SystemDeployment',
            translateKey: 'COMMON_LABEL_DEPLOYING',
        },
        InformationOrgSetting: {
            key: 'InformationOrgSetting',
            translateKey: 'TITLE_ORGANIZATION_INFORMATION',
        },
        OverviewWorkflows: {
            key: 'OverviewWorkflows',
            translateKey: 'OVERVIEW_WORKFLOWS',
        },
        SharedTasksManagement: {
            key: 'SharedTasksManagement',
            translateKey: 'TITLE_SHARED_TASKS_MANAGEMENT',
        },
        TaskWorkflowManagement: {
            key: 'TaskWorkflowManagement',
            translateKey: 'TITLE_TASK_WORKFLOW_MANAGEMENT',
        },
        WorkflowStepManagement: {
            key: 'WorkflowStepManagement',
            translateKey: 'TITLE_WORKFLOW_STEP_MANAGEMENT',
        },
        WorkflowStepTypeManagement: {
            key: 'WorkflowStepTypeManagement',
            translateKey: 'TITLE_WORKFLOW_STEP_TYPE_MANAGEMENT',
        },
        WhiteboardList: {
            key: 'WhiteboardList',
            translateKey: 'COMMON_LABEL_MY_DESKTOP',
        },
        WhiteboardDetail: {
            key: 'WhiteboardDetail',
            translateKey: 'COMMON_LABEL_MY_DESKTOP',
        },
        StartWorkingSetting: {
            key: 'StartWorkingSetting',
            translateKey: 'SETTING_SW_CONDITION_TITLE',
        },
        PerformanceOverview: {
            key: 'PerformanceOverview',
            translateKey: 'COMMON_LABEL_PERFORMANCE',
        },
    };

    return {
        changeByRouter,
        updateDocumentTitle,
        resetToLastestTitle,
    };
}
