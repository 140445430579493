import { UserCreatePayload } from '@/domain/entities/PayloadClass/UserPayloadClass';
import EmbedUserApi from '@/embed/api/user-api';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

export default class EmbedUserServices {
    private static instance: EmbedUserServices;
    userApi: EmbedUserApi;

    constructor() {
        this.userApi = EmbedUserApi.getInstance();
    }

    public static getInstance(): EmbedUserServices {
        if (!EmbedUserServices.instance) {
            // Get from local storage
            EmbedUserServices.instance = new EmbedUserServices();
        }

        return EmbedUserServices.instance;
    }

    getDetail(userId: number): Promise<any> {
        return this.userApi.getUserInfo(userId);
    }
    getDeactivatedUsers(): Promise<any> {
        return this.userApi.getDeactivatedUsers();
    }
    getUserIdentity(): Promise<any> {
        return this.userApi.getUserIdentity();
    }
    getShortInfosUser(): Promise<any> {
        return this.userApi.getShortInfos();
    }
    saveProfile(data: UserCreatePayload): Promise<any> {
        return this.userApi.saveProfileUser(data);
    }

    uploadAvatar(file: File): Promise<any> {
        return this.userApi.uploadAvatarAws(file);
    }

    getDomainListById(userId: number): Promise<any> {
        return this.userApi.getDomainScopeList(userId);
    }

    fetchAllProfile(): Promise<any> {
        const language = getLocalStorage(StorageConstant.LANGUAGE);
        return this.userApi.getAllProfile(language ?? 'en');
    }

    fetchAllPosition(): Promise<any> {
        const language = getLocalStorage(StorageConstant.LANGUAGE);
        return this.userApi.getAllPosition(language ?? 'en');
    }

    changePassword(data: any): Promise<any> {
        return this.userApi.changePassword(data);
    }

    async searchUser(
        searchString: string,
        status: boolean,
        pageIndex: number,
        pageSize: number
    ): Promise<any> {
        const res: any = await this.userApi.searchUsers(
            searchString,
            status,
            pageIndex,
            pageSize
        );
        return {
            users: res?.result.users,
            pagination: res?.result.pagination,
        };
    }

    deleteUserId(userId: number, status: boolean): Promise<any> {
        return this.userApi.updateStatusUser(userId, status);
    }

    updateStatusUser(userId: number, status: boolean): Promise<any> {
        return this.userApi.updateStatusUser(userId, status);
    }

    saveUserScopesDto(data: any): Promise<any> {
        return this.userApi.saveUserScopesDto(data);
    }

    updatePositionValue(payload: any): Promise<any> {
        const language = getLocalStorage(StorageConstant.LANGUAGE);
        return this.userApi.updatePositionValue({
            ...payload,
            language: language ?? 'en',
        });
    }

    setPassword(data: string): Promise<any> {
        return this.userApi.setPassword(data);
    }

    sendVerificationUserEmail(): Promise<any> {
        return this.userApi.sendVerificationUserEmail();
    }

    changeUserPhone(data: any): Promise<any> {
        return this.userApi.changeUserPhone(data);
    }

    changeUserEmail(data: any): Promise<any> {
        return this.userApi.changeUserEmail(data);
    }

    checkHasSendVerificationUserEmail(): Promise<any> {
        return this.userApi.checkHasSendVerificationUserEmail();
    }

    getScopeListByUserId(userId): Promise<any> {
        return this.userApi.getDomainScopeListByUserId(userId);
    }
}
