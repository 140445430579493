<script setup lang="ts">
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import {
  AdminType,
  UserActivitiesType,
  UserSubActivitiesType,
} from '@/ui/common/constants/constant';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import userStore from '@/store/user';
import SynTableTrLoading from '@/ui/common/molecules/SynTableCustom/SynTableTrLoading.vue';
import SynTd from '@/ui/common/molecules/SynTableCustom/SynTd.vue';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import dayjs from 'dayjs';
import UserActivitiesDetailModal from '@/ui/pages/usermanagement/user-detail/UserActivitiesDetailModal.vue';
import appStore from '@/store/app';
import myProfileStore from '@/store/auth/my-profile';
import FilterDepartmentMultiple from '@/ui/modules/dashboard/monitor/FilterDepartmentMultiple.vue';
import FilterPlatformUserActivities from '@/ui/pages/overview/working-time/FilterPlatformUserActivities.vue';
import settingStore from '@/store/setting';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import VigButton from '@/ui/common/atoms/VigButton/VigButton.vue';
import ButtonViewLocationMap from '@/ui/components/map/ButtonViewLocationMap.vue';

const props = defineProps<{
  keySearch: string;
  dateFilter: any;
}>();

const emits = defineEmits(['emitUserActivities']);

const _userStore = userStore();
const _appStore = appStore();
const _settingStore = settingStore();

const lang = computed(() => _appStore.language);
const currentUser = computed(() => myProfileStore().myProfile);
const loadingData = reactive<{
  init: boolean;
}>({ init: true });

const isShowDetail = ref<boolean>(false);
const currentUserId = ref<number>(0);
const currentUserName = ref<string>('');
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const departmentFilterList = computed(() => _userStore.departmentIdList);
const filterDepartmentList = ref([] as any);
const platformSelect = ref('');

const onFilerDepartment = (value) => {
  filterDepartmentList.value = value;
  emitActivitiesList();
};

const queryActivity = computed(() => _userStore.activityInput);
const activitiesDatas = computed(() => {
  if (
    !props.keySearch &&
    filterDepartmentList.value.length <= 0 &&
    platformSelect.value == ''
  ) {
    return _userStore.activitiesByDate.datas;
  }

  let newArr = _userStore.activitiesByDate.datas || [];

  if (filterDepartmentList.value.length > 0) {
    newArr = newArr.filter((u) =>
      filterDepartmentList.value.some((e) => e == u?.departmentId)
    );
  }

  if (platformSelect.value != '') {
    newArr = newArr.filter(
      (u) => u.lastActivity?.platform == platformSelect.value
    );
  }

  if (props.keySearch) {
    const keyTxt = stringNomalize(props.keySearch.toLowerCase());
    newArr = newArr.filter((u) =>
      stringNomalize(u.fullName.toLowerCase()).includes(keyTxt)
    );
  }

  return newArr;
});

const initComponent = async () => {
  try {
    loadingData.init = true;
    queryActivity.value.date = dayjs(props.dateFilter).format(
      'YYYY-MM-DD'
    ) as any;
    queryActivity.value.activity = UserActivitiesType.StartWorking;
    queryActivity.value.subActivity = UserSubActivitiesType.Start;
    await _userStore.getAllUserActivitiesByDate();
    emitActivitiesList();
  } catch (e) {
    console.log(e);
  } finally {
    loadingData.init = false;
  }
};

const onShowDetail = (userId: number, fullName: string) => {
  isShowDetail.value = true;
  currentUserId.value = userId;
  currentUserName.value = fullName;
};

const isRoleOwner = computed(() => hasRolePermission(AdminType.Owner));

watch(
  () => props.dateFilter,
  () => initComponent()
);

watch(
  () => props.keySearch,
  () => emitActivitiesList()
);

const emitActivitiesList = () => {
  emits('emitUserActivities', activitiesDatas.value);
};

onMounted(() => {
  initComponent();
});
</script>

<template>
  <syn-table-custom class="rounded" style="width: 100%">
    <template #header>
      <SynTr>
        <SynTh :label="$t('COMMON_LABEL_MEMBERS')"></SynTh>
        <SynTh
          v-if="departmentManagementEnabled"
          :label="$t('COMMON_MODULE_MANAGEMENT_DEPARTMENT')"
        >
          <template #custom>
            <FilterDepartmentMultiple
              v-if="isRoleOwner"
              :department-list="departmentFilterList"
              @on-filter="onFilerDepartment"
            />
          </template>
        </SynTh>
        <SynTh :label="$t('USERMANAGEMENT_LABEL_ACTIVITY')"></SynTh>
        <SynTh :label="$t('CALENDAR_LABEL_TIME')"></SynTh>
        <SynTh :label="$t('USERMANAGEMENT_LABEL_PLATFORM')">
          <template #custom>
            <FilterPlatformUserActivities
              @on-choose-platform="
                (value) => [(platformSelect = value), emitActivitiesList()]
              "
            />
          </template>
        </SynTh>
        <SynTh :label="$t('COMMOM_LABEL_LOCATION')"></SynTh>
        <SynTh :label="$t('USERMANAGEMENT_LABEL_IP_ADDRESS')"></SynTh>
      </SynTr>
    </template>
    <template #body>
      <SynTableTrLoading v-if="loadingData.init">
        <div v-for="item in 7" :key="item" class="table-cell p-2">
          <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
        </div>
      </SynTableTrLoading>
      <template
        v-for="(item, index) in activitiesDatas"
        v-else-if="activitiesDatas.length > 0"
        :key="index"
      >
        <SynTr
          class="cursor-pointer hover:bg-gray-50 group/user-activity"
          @click="onShowDetail(item.id, item.fullName)"
        >
          <SynTd>
            <div class="flex items-center space-x-1.5">
              <SynAvatar
                :src="item?.avatarUrl"
                :name="item?.fullName"
                custom-class="h-8 w-8"
              />
              <span
                class="text-sm text-overflow-hidden-line"
                :title="item?.fullName"
              >
                {{ item?.fullName }}
              </span>
              <span v-if="item.id == currentUser.id" class="italic text-sm">
                {{ '(' + $t('COMMON_LABEL_ME') + ')' }}
              </span>
              <span
                v-if="item?.adminType == AdminType.Visitor"
                class="italic text-sm"
              >
                {{ '(' + $t('COMMON_LABEL_VISITOR') + ')' }}
              </span>
            </div>
          </SynTd>
          <SynTd v-if="departmentManagementEnabled">
            <div
              v-if="item.departmentShortInfos?.length > 0"
              class="flex items-center space-x-2"
            >
              <SynAvatar
                class="cursor-pointer text-current-800"
                :src="
                  item.departmentShortInfos[0]?.avatarUrl?.replace(
                    'original',
                    'mini'
                  )
                "
                :name="item.departmentShortInfos[0]?.name"
                custom-class="w-8 h-8"
              />
              <span
                v-vig-tooltip="item.departmentShortInfos[0]?.name"
                class="overflow-hidden text-overflow-hidden-line"
              >
                {{ item.departmentShortInfos[0]?.name }}
              </span>
            </div>
            <div v-else class="text-gray-400 italic">
              {{ $t('DEPARTMENT_LABEL_USER_HAS_NOT_DEPARTMENT') }}
            </div>
          </SynTd>
          <SynTd style="">
            <div
              v-if="
                item.lastActivity?.activity ==
                  UserActivitiesType.StartWorking &&
                item.lastActivity?.subActivity == UserSubActivitiesType.Start
              "
              style="width: 120px"
            >
              <span
                class="
                  px-2
                  py-1
                  bg-white
                  text-current-500
                  font-medium
                  text-center
                  rounded
                  text-xs
                  border border-current
                "
                >{{ $t('HEADER_LABEL_START_WD') }}</span
              >
            </div>
            <div v-else style="width: 70px" class="flex-center">-</div>
          </SynTd>
          <SynTd>
            <div
              v-if="item.lastActivity"
              class="flex-center"
              style="width: 70px"
            >
              {{
                $filters.dayjsGetTime(
                  item.lastActivity?.activityDate,
                  lang == 'en' ? 'hh:mm a' : 'HH:mm'
                )
              }}
            </div>
            <div v-else style="width: 50px" class="flex-center">-</div>
          </SynTd>
          <SynTd>
            <div
              v-if="item.lastActivity"
              class="
                px-2
                py-1
                rounded
                flex-center
                text-white text-xs
                font-medium
              "
              :class="
                item.lastActivity?.platform == 'WEB'
                  ? 'bg-current'
                  : 'bg-green-400'
              "
              style="width: 70px"
            >
              {{ item.lastActivity?.platform }}
            </div>
            <div v-else style="width: 70px" class="flex-center">-</div>
          </SynTd>
          <SynTd>
            <div
              v-if="item?.lastActivity?.location?.address"
              class="flex items-center space-x-1"
            >
              <div
                class="text-overflow-hidden-line"
                :title="item?.lastActivity?.location?.address"
              >
                {{ item?.lastActivity?.location?.address }}
              </div>
              <div class="w-5 invisible group-hover/user-activity:visible">
                <ButtonViewLocationMap :position="item?.lastActivity?.location">
                  <VigButton
                    v-vig-tooltip="$t('SETTING_SW_CONDITION_POSITION_VIEW_MAP')"
                    light
                    color="gray"
                    padding="p-1"
                    icon="MapPin"
                  />
                </ButtonViewLocationMap>
              </div>
            </div>
            <div
              v-else-if="
                item?.lastActivity?.region && item?.lastActivity?.region !== '-'
              "
              class="flex items-center space-x-1"
            >
              <div class="text-overflow-hidden-line">
                {{ item?.lastActivity?.region }} ({{
                  item?.lastActivity?.country
                }})
              </div>
              <div class="w-5 invisible group-hover/user-activity:visible">
                <ButtonViewLocationMap :position="item?.lastActivity?.location">
                  <VigButton
                    v-vig-tooltip="$t('SETTING_SW_CONDITION_POSITION_VIEW_MAP')"
                    light
                    color="gray"
                    padding="p-1"
                    icon="MapPin"
                  />
                </ButtonViewLocationMap>
              </div>
            </div>
            <div v-else style="width: 70px" class="flex-center">-</div>
          </SynTd>
          <SynTd>
            <div v-if="item.lastActivity">
              {{ item?.lastActivity?.ipAddress }}
            </div>
            <div v-else style="width: 70px" class="flex-center">-</div>
          </SynTd>
        </SynTr>
      </template>

      <template v-else>
        <syn-tr class="h-full" hover="hover:bg-current-50">
          <SynTd :colspan="10" style="height: calc(100vh - 20rem)">
            <syn-animation
              name="searchIcon"
              stype="width: 200px; height: 200px"
              :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
            />
          </SynTd>
        </syn-tr>
      </template>
    </template>
  </syn-table-custom>

  <UserActivitiesDetailModal
    v-if="isShowDetail"
    :user-id="currentUserId"
    :user-name="currentUserName"
    @cancel="isShowDetail = false"
  />
</template>
