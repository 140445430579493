<script setup lang="ts">
import { computed, onMounted, ref, onUnmounted, inject } from 'vue';
import dayjs from 'dayjs';
import WorkingTime from '@/ui/modules/dashboard/monitor/WorkingTime.vue';
import FinishedTasks from '@/ui/modules/dashboard/monitoring-department/FinishedTasks.vue';
import CreatedTasks from '@/ui/modules/dashboard/monitoring-department/CreatedTasks.vue';
import RemainingWorkforceCard from '@/ui/modules/dashboard/monitor/RemainingWorkforceCard.vue';
import MembersStatedWorkingCard from '@/ui/modules/dashboard/monitor/MembersStatedWorkingCard.vue';
import FinishedTasksCard from '@/ui/modules/dashboard/monitor/FinishedTasksCard.vue';
import overviewMonitoringDepartmentStore, {
  TDepartmentOverview,
} from '@/store/dashboard/overview-monitoring-department-store';
import ScoreTodayCard from '@/ui/modules/dashboard/monitoring-department/ScoreTodayCard.vue';
import TaskActivitiesCard from '@/ui/modules/dashboard/monitor/TaskActivitiesCard.vue';
import DayoffStatusTodayCard from '@/ui/modules/dashboard/monitor/DayoffStatusTodayCard.vue';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import departmentStore from '@/store/department';
import OverviewMembers from '@/ui/pages/overview/OverviewMembers.vue';
import DepartmentCreateFormModal from '@/ui/modules/departments/DepartmentCreateFormModal.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const props = withDefaults(
  defineProps<{
    departmentId?: number;
    totalTasks?: number;
    mode?: 'PAGE' | 'MODAL';
  }>(),
  {
    mode: 'MODAL',
  }
);

const _overviewMonitorStore = overviewMonitoringDepartmentStore();
const _departmentStore = departmentStore();
const isHiddenBlockPage: any = inject('IS_HIDDEN_BLOCK_PAGE');

const isLoading = computed(() => _overviewMonitorStore.isLoadingWorkingStatus);
const timeCapacity = computed(() => {
  return _overviewMonitorStore.timeCapacity;
});
const finishedTaskCapacity = computed(() => _overviewMonitorStore.task);
const workingMember = computed(() => _overviewMonitorStore.workingMember);
const taskActivityData = computed(
  () => _overviewMonitorStore.taskActivityChart
);
const dayoffStatusByDate = computed(
  () => _overviewMonitorStore.dayoffStatusByDate
);

const allDepartments = computed(() => {
  return _departmentStore.allDepartments;
});
const departmentInfo = computed(() => {
  return _departmentStore.allDepartmentByIds[props.departmentId];
});

enum EOverviewDetailTabs {
  DEPARTMENTS = 'DEPARTMENTS',
  MEMBERS = 'MEMBERS',
  FINISHED_TASKS = 'FINISHED_TASKS',
  NEW_TASKS = 'NEW_TASKS',
}

const currentTab = ref<EOverviewDetailTabs | null>(EOverviewDetailTabs.MEMBERS);
const overviewDetailTabs = ref<any>({
  [EOverviewDetailTabs.MEMBERS]: {
    key: EOverviewDetailTabs.MEMBERS,
    label: 'TASK_TABLE_LABEL_MEMBERS',
    isActive: true,
    isEditing: false,
    subContent: '',
  },
  [EOverviewDetailTabs.FINISHED_TASKS]: {
    key: EOverviewDetailTabs.FINISHED_TASKS,
    label: 'OVERVIEW_FINISHED_TASKS',
    isActive: false,
    isEditing: false,
    subContent: '',
  },
  [EOverviewDetailTabs.NEW_TASKS]: {
    key: EOverviewDetailTabs.NEW_TASKS,
    label: 'OVERVIEW_CREATED_TASKS',
    isActive: false,
  },
});

enum EOverviewMoniteringDepartmentTabs {
  OVERVIEW = 'OVERVIEW',
  TEAM = 'TEAM',
}

const currentOverviewTab = ref<EOverviewMoniteringDepartmentTabs | null>(
  EOverviewMoniteringDepartmentTabs.OVERVIEW
);

const departmentOverviewData = ref<TDepartmentOverview>({});
const overviewTabs = ref<any>({
  [EOverviewMoniteringDepartmentTabs.OVERVIEW]: {
    key: EOverviewMoniteringDepartmentTabs.OVERVIEW,
    label: 'COMMON_LABEL_OVERVIEW',
    isActive: true,
    isEditing: false,
    subContent: '',
  },
  [EOverviewMoniteringDepartmentTabs.TEAM]: {
    key: EOverviewMoniteringDepartmentTabs.TEAM,
    label: 'SIDEBAR_LABEL_MY_TEAM',
    isActive: false,
    isEditing: false,
    subContent: '',
  },
});
let recalculateInterval;
onMounted(() => {
  recalculateInterval = setInterval(function () {
    if (props.departmentId) {
      _overviewMonitorStore.getWorkingStatus(props.departmentId);
    }
  }, 1 * 60 * 1000);
});
onUnmounted(() => {
  clearInterval(recalculateInterval);
});

const initComponent = async () => {
  _overviewMonitorStore.getAllMonitorData(props.departmentId);
  const res = await _overviewMonitorStore.getDepartmentsOverviewById(
    props.departmentId
  );
  departmentOverviewData.value = res.result as TDepartmentOverview;
};

initComponent();
const changeTab = (key) => {
  currentTab.value = key;
  for (const k in overviewDetailTabs.value) {
    overviewDetailTabs.value[k].isActive = false;
    overviewDetailTabs.value[k].isEditing = false;

    if (k == key) overviewDetailTabs.value[k].isActive = true;
  }
};
const changeOverviewTab = (key) => {
  currentOverviewTab.value = key;
  for (const k in overviewTabs.value) {
    overviewTabs.value[k].isActive = false;
    overviewTabs.value[k].isEditing = false;

    if (k == key) overviewTabs.value[k].isActive = true;
  }
};

const onChangeDayInDayoffCard = (newDate) => {
  _overviewMonitorStore.getDayoffStatusByDate(
    dayjs(newDate).format('YYYY-MM-DD'),
    props.departmentId
  );
};

const noDepartmentImage = computed<string>(
  () => `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/images/department.jpg`
);

const isShowAddDepartment = ref<boolean>(false);
</script>
<template>
  <template v-if="departmentId">
    <SynPageLoading v-if="isLoading" />
    <div v-else class="w-full h-full flex flex-col space-y-2 bg-gray-50 pt-6">
      <!-- HEADER BLOCK -->
      <template v-if="mode == 'MODAL'">
        <SynTabs
          :option-status="overviewTabs"
          positon="left"
          class="overflow-hidden pl-4 bg-gray-50"
          is-header
          custom-header-class="flex-1"
          @change-tab="changeOverviewTab"
        >
          <template #header>
            <div class="flex items-center space-x-4 px-4">
              <div>
                <DepartmentById
                  :department-id="departmentId"
                  class="text-lg font-semibold text-gray-600"
                  is-hidden-avatar
                />
              </div>
              <SynListAvatar
                :users="departmentInfo?.departmentUsers"
                :custom-class="'w-10 h-10 text-sm'"
                :max-length="5"
              />
              <span class="h-1/2 border-l border-gray-400"></span>
              <div class="flex items-baseline space-x-1">
                <span class="text-current text-3xl font-semibold">
                  {{ departmentInfo?.departmentUsers?.length || 0 }}
                </span>
                <span class="text-gray-600 text-sm">
                  {{ $t('SIDEBAR_LABEL_MY_TEAM') || 'Members' }}
                </span>
              </div>
              <span class="h-1/2 border-l border-gray-400"></span>
              <div class="flex items-baseline space-x-1">
                <span
                  v-vig-tooltip="{
                    content: `${
                      totalTasks ||
                      departmentOverviewData?.activeTasks +
                        departmentOverviewData?.finishedTasks
                    } ${$t('TASK_TABLE_LABEL_TASKS') || 'Tasks'}`,
                  }"
                  class="text-current text-3xl font-semibold"
                >
                  {{
                    $filters.numberToString(
                      totalTasks ||
                        departmentOverviewData?.activeTasks +
                          departmentOverviewData?.finishedTasks
                    )
                  }}
                </span>
                <span class="text-gray-600 font- text-sm">
                  {{ $t('TASK_TABLE_LABEL_TASKS') || 'Tasks' }}
                </span>
              </div>
            </div>
          </template>
          <template #tabBody>
            <div class="py-2 flex-1 min-h-0 flex flex-col relative">
              <!-- MEMBERS -->
              <template
                v-if="
                  currentOverviewTab ==
                  EOverviewMoniteringDepartmentTabs.OVERVIEW
                "
              >
                <div class="overflow-auto small-scrollbar">
                  <!-- TOP BLOCK -->
                  <div
                    class="w-full min-w-0 flex overflow-x-auto small-scrollbar"
                  >
                    <div
                      v-permission-screen="
                        isHiddenBlockPage
                          ? false
                          : {
                              screenCode: ALL_SCREENS.COMMON.OVERVIEW.CHART,
                            }
                      "
                      class="flex-1 flex space-x-4 p-4"
                    >
                      <RemainingWorkforceCard :time-capacity="timeCapacity" />
                      <MembersStatedWorkingCard
                        :time-capacity="timeCapacity"
                        :working-member="workingMember"
                      />
                      <FinishedTasksCard
                        :overview-task="finishedTaskCapacity"
                      />
                      <TaskActivitiesCard
                        :task-activity-data="taskActivityData"
                      />
                      <DayoffStatusTodayCard
                        :dayoff-status-by-date="dayoffStatusByDate"
                        @change-day="onChangeDayInDayoffCard"
                      />
                      <ScoreTodayCard />
                    </div>
                  </div>

                  <!-- BOTTOM BLOCK -->
                  <div>
                    <SynTabs
                      :option-status="overviewDetailTabs"
                      positon="left"
                      class="overflow-hidden pl-4"
                      @change-tab="changeTab"
                    >
                      <template #tabBody>
                        <div class="py-2 flex-1 min-h-0 flex flex-col relative">
                          <!-- MEMBERS -->
                          <template
                            v-if="currentTab == EOverviewDetailTabs.MEMBERS"
                          >
                            <WorkingTime
                              :department-id="departmentId"
                              :current-tab="currentTab"
                            />
                          </template>
                          <!-- FINISHED_TASKS -->
                          <template
                            v-if="
                              currentTab == EOverviewDetailTabs.FINISHED_TASKS
                            "
                          >
                            <FinishedTasks :department-id="departmentId" />
                          </template>
                          <!-- NEW_TASKS -->
                          <template
                            v-if="currentTab == EOverviewDetailTabs.NEW_TASKS"
                          >
                            <CreatedTasks :department-id="departmentId" />
                          </template>
                        </div>
                      </template>
                    </SynTabs>
                  </div>
                </div>
              </template>
              <!-- FINISHED_TASKS -->
              <template
                v-if="
                  currentOverviewTab == EOverviewMoniteringDepartmentTabs.TEAM
                "
              >
                <OverviewMembers :department-id="departmentId" />
              </template>
            </div>
          </template>
        </SynTabs>
      </template>
      <template v-else>
        <div class="flex items-center space-x-4 px-4">
          <div>
            <DepartmentById
              :department-id="departmentId"
              class="text-lg font-semibold text-gray-600"
              is-hidden-avatar
            />
          </div>
          <SynListAvatar
            :users="departmentInfo?.departmentUsers"
            :custom-class="'w-10 h-10 text-sm'"
            :max-length="5"
          />
          <span class="h-1/2 border-l border-gray-400"></span>
          <div class="flex items-baseline space-x-1">
            <span class="text-current text-3xl font-semibold">
              {{ departmentInfo?.departmentUsers?.length || 0 }}
            </span>
            <span class="text-gray-600 text-sm">
              {{ $t('SIDEBAR_LABEL_MY_TEAM') || 'Members' }}
            </span>
          </div>
          <span class="h-1/2 border-l border-gray-400"></span>
          <div class="flex items-baseline space-x-1">
            <span
              v-vig-tooltip="{
                content: `${
                  totalTasks ||
                  departmentOverviewData?.activeTasks +
                    departmentOverviewData?.finishedTasks
                } ${$t('TASK_TABLE_LABEL_TASKS') || 'Tasks'}`,
              }"
              class="text-current text-3xl font-semibold"
            >
              {{
                $filters.numberToString(
                  totalTasks ||
                    departmentOverviewData?.activeTasks +
                      departmentOverviewData?.finishedTasks
                )
              }}
            </span>
            <span class="text-gray-600 font- text-sm">
              {{ $t('TASK_TABLE_LABEL_TASKS') || 'Tasks' }}
            </span>
          </div>
        </div>
        <div class="overflow-auto small-scrollbar">
          <!-- TOP BLOCK -->
          <div
            v-perfect-scrollbar
            class="w-full min-w-0 flex overflow-x-auto small-scrollbar"
          >
            <div
              v-permission-screen="
                isHiddenBlockPage
                  ? false
                  : {
                      screenCode: ALL_SCREENS.COMMON.OVERVIEW.CHART,
                    }
              "
              class="flex-1 flex space-x-4 p-4"
            >
              <RemainingWorkforceCard :time-capacity="timeCapacity" />
              <MembersStatedWorkingCard
                :time-capacity="timeCapacity"
                :working-member="workingMember"
              />
              <FinishedTasksCard :overview-task="finishedTaskCapacity" />
              <TaskActivitiesCard :task-activity-data="taskActivityData" />
              <DayoffStatusTodayCard
                :dayoff-status-by-date="dayoffStatusByDate"
                @change-day="onChangeDayInDayoffCard"
              />
              <ScoreTodayCard />
            </div>
          </div>

          <!-- BOTTOM BLOCK -->
          <div>
            <SynTabs
              :option-status="overviewDetailTabs"
              positon="left"
              class="overflow-hidden"
              @change-tab="changeTab"
            >
              <template #tabBody>
                <div class="py-2 flex-1 min-h-0 flex flex-col relative">
                  <!-- MEMBERS -->
                  <template v-if="currentTab == EOverviewDetailTabs.MEMBERS">
                    <WorkingTime
                      :department-id="departmentId"
                      :current-tab="currentTab"
                    />
                  </template>
                  <!-- FINISHED_TASKS -->
                  <template
                    v-if="currentTab == EOverviewDetailTabs.FINISHED_TASKS"
                  >
                    <FinishedTasks :department-id="departmentId" />
                  </template>
                  <!-- NEW_TASKS -->
                  <template v-if="currentTab == EOverviewDetailTabs.NEW_TASKS">
                    <CreatedTasks :department-id="departmentId" />
                  </template>
                </div>
              </template>
            </SynTabs>
          </div>
        </div>
      </template>
    </div>
  </template>
  <div v-else class="flex-center flex-col pt-8 w-full h-full bg-white">
    <template v-if="allDepartments?.length > 0">
      <div class="w-64">
        <VigImage :path="noDepartmentImage" />
      </div>
      <div class="font-semibold pt-4">
        {{ $t('DEPARTMENT_LABEL_NO_DEPARTMENT') }}
      </div>
      <div class="text-gray-500">
        {{ $t('DEPARTMENT_LABEL_NO_DEPARTMENT_SUB') }}
      </div>
      <div class="pt-4">
        <RouterLink :to="{ name: 'DepartmentsManagement' }">
          <SynButton
            :label="
              $t('COMMON_LABEL_GO_TO_DEPARTMENT_MANAGEMENT_PAGE') || 'Create'
            "
            name-icon="department"
          />
        </RouterLink>
      </div>
    </template>
    <template v-else>
      <div class="w-64">
        <VigImage :path="noDepartmentImage" />
      </div>
      <div class="font-semibold pt-4">
        {{ $t('DEPARTMENT_LABEL_NO_DATA') }}
      </div>
      <div class="text-gray-500">
        {{ $t('DEPARTMENT_LABEL_NO_DATA_SUB') }}
      </div>
      <div class="pt-4">
        <SynButton
          :label="$t('DEPARTMENT_LABEL_CREATE_NEW') || 'Create'"
          name-icon="department"
          @click="isShowAddDepartment = true"
        />
      </div>
    </template>
  </div>

  <DepartmentCreateFormModal
    v-if="isShowAddDepartment"
    @cancel="isShowAddDepartment = false"
  />
</template>
