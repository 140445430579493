<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="96.000000pt"
    height="96.000000pt"
    viewBox="0 0 96.000000 96.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M115 840 l-29 -30 119 -120 c113 -113 119 -121 102 -137 -16 -16 -18
-15 -47 12 l-30 29 -33 -32 -32 -32 75 -75 75 -75 -98 -98 c-83 -83 -97 -102
-97 -130 0 -29 3 -32 32 -32 28 0 47 14 130 97 l98 98 75 -75 75 -75 32 32 32
33 -27 28 c-25 26 -26 29 -10 45 17 16 24 11 135 -100 l118 -118 32 33 33 32
-360 360 c-198 198 -362 360 -366 360 -3 0 -18 -14 -34 -30z"
      />
      <path
        d="M555 840 c-27 -28 -27 -31 -12 -48 9 -10 17 -21 17 -24 0 -3 -24 -26
-54 -50 l-54 -45 112 -112 113 -112 43 55 c23 31 45 56 48 56 3 0 14 -8 24
-17 18 -16 20 -15 51 15 l32 32 -140 140 c-77 77 -142 140 -145 140 -4 0 -19
-13 -35 -30z"
      />
    </g>
  </svg>
</template>
