import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DashboardService from '@/application/services/DashboardService';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import { AdminType } from '@/ui/common/constants/constant';
import system from '@/store/system';
import dashboardStore from '@/store/dashboard';
import { TaskActionType } from '@/domain/enums/taskEnum';
import TaskService from '@/application/services/task/TaskService';
import { getUrgencyValue } from '@/ui/hooks/taskHook';

export default defineStore({
    id: storeId.dashboardTeam,
    state: () => ({
        overviewTeamInfo: [] as any[],
        overviewTeamUserIds: [] as any[],
        overviewTeamFullTasks: {} as any,
        overviewTeamTasks: {} as any,
        _dashboardService: DashboardService.getInstance(),
        isLoading: true,
        isLoadingMemberTask: true,
        keySearchTeam: null,
        isHiddenTask: system().getSystemSettingDashboardTeams() === 'true',
        isFetchPending: false,
    }),
    getters: {},
    actions: {
        setFetchPendingKey(isFetch) {
            this.isFetchPending = isFetch;
        },

        setKeySearchTeam(keySearch) {
            this.keySearchTeam = keySearch;
        },

        onToggleHiddenTask() {
            this.isHiddenTask = !this.isHiddenTask;
            system().setSystemSettingDashboardTeams(
                this.isHiddenTask.toString()
            );
        },

        async getOverviewTeamInfo(
            departmentId?: number,
            dontLoading?: boolean
        ) {
            if (!dontLoading) this.isLoading = true;

            const res: any = await this._dashboardService.getDashboardOverview(
                departmentId
            );

            const dataList = res?.result?.filter(
                (e) =>
                    hasRolePermission(AdminType.Owner) ||
                    hasRolePermission(AdminType.Admin) ||
                    (hasRolePermission(AdminType.Visitor) &&
                        e.userInfo?.adminType == AdminType.Visitor) ||
                    (!hasRolePermission(AdminType.Visitor) &&
                        e.userInfo?.adminType != AdminType.Visitor)
            );

            console.log('🚀 Tictop ~ dataList:', dataList);
            this.overviewTeamInfo = dataList.reduce((old, current) => {
                const userId = current.userInfo?.id || 0;

                if (
                    current?.workInfo?.startWD &&
                    current?.workInfo?.online &&
                    current.workInfo?.stopWD
                ) {
                    dashboardStore().updateUserWorkingStatusList(
                        userId,
                        'offline'
                    );
                } else if (
                    current?.workInfo?.startWD &&
                    !current?.workInfo?.online &&
                    !current.workInfo?.stopWD
                ) {
                    dashboardStore().updateUserWorkingStatusList(
                        userId,
                        'absent'
                    );
                } else if (
                    current?.workInfo?.startWD &&
                    current?.workInfo?.online &&
                    !current.workInfo?.stopWD
                ) {
                    dashboardStore().updateUserWorkingStatusList(
                        userId,
                        'online'
                    );
                } else
                    dashboardStore().updateUserWorkingStatusList(
                        userId,
                        'offline'
                    );
                return {
                    ...old,
                    [userId]: current,
                };
            }, {});

            this.overviewTeamUserIds = dataList.map((o) => {
                return o.userInfo?.id;
            });
            this.isLoading = false;
        },

        async updateAvatarUserTeamLocal(userId, avatars) {
            if (!avatars?.avatarThumbnailUrl && !avatars?.avatarUrl) return;

            const _userId =
                typeof userId == 'string' ? parseInt(userId) : userId;
            const userDetail = this.overviewTeamInfo[_userId] || {};
            this.overviewTeamInfo = {
                ...this.overviewTeamInfo,
                [_userId]: {
                    ...userDetail,
                    userInfo: {
                        ...userDetail?.userInfo,
                        avatarThumbnailUrl:
                            avatars?.avatarThumbnailUrl ||
                            userDetail?.avatarThumbnailUrl ||
                            '',
                        avatarThumbUrl:
                            avatars?.avatarThumbnailUrl ||
                            userDetail?.avatarThumbUrl ||
                            '',
                        avatarUrl:
                            avatars?.avatarUrl || userDetail?.avatarUrl || '',
                    },
                },
            };
        },

        async getOverviewTeamTasks(departmentId?: number) {
            try {
                this.isLoadingMemberTask = true;
                const res: any =
                    await this._dashboardService.getOverviewTeamTasks(
                        departmentId
                    );
                const dataTasks = res?.result;

                this.overviewTeamUserIds.forEach((userId) => {
                    if (!dataTasks[userId]) return;
                    this.overviewTeamTasks[userId] = {
                        ...dataTasks[userId],
                        overviewTaskList: dataTasks[
                            userId
                        ].overviewTaskList.slice(0, 4),
                    };
                });

                this.overviewTeamFullTasks = dataTasks;
            } catch (error) {
                console.log(
                    '🚀 ~ file: overview-team-store.ts ~ line 50 ~ getOverviewTeamTasks ~ error',
                    error
                );
            } finally {
                this.isLoadingMemberTask = false;
            }
        },
        async getOverviewTeam(departmentId?: number) {
            await this.getOverviewTeamInfo(departmentId);
            this.getOverviewTeamTasks(departmentId);
        },
        setOverviewTeamTaskMore(userId, tasks) {
            const currentData = this.overviewTeamTasks[userId].overviewTaskList;
            this.overviewTeamTasks[userId].overviewTaskListSeemore =
                tasks?.filter(
                    (o) =>
                        !currentData || !currentData.some((p) => p.id == o.id)
                );

            this.overviewTeamTasks[userId].isShowMore = true;
        },
        setFullTaskForMember(userId) {
            if (
                !this.overviewTeamFullTasks[userId] ||
                this.overviewTeamFullTasks[userId].updated
            )
                return;

            this.overviewTeamTasks[userId] = this.overviewTeamFullTasks[userId];
            this.overviewTeamFullTasks[userId].updated = true;
        },
        async updateUserTasksRealtime(
            taskAction: TaskActionType,
            taskDetail: {
                id: number;
                assigneeId?: number;
                groupId?: number;
            }
        ) {
            if (!taskDetail) return;

            const { assigneeId: userId, id: taskId } = taskDetail;

            if (
                !userId ||
                !Object.prototype.hasOwnProperty.call(
                    this.overviewTeamTasks,
                    userId
                )
            )
                return;

            switch (taskAction) {
                case TaskActionType.Delete:
                case TaskActionType.MoveOut:
                    this.overviewTeamTasks[userId].overviewTaskList =
                        this.overviewTeamTasks[
                            userId
                        ]?.overviewTaskList?.filter((t) => t?.id !== taskId) ||
                        [];
                    break;

                default:
                    {
                        // 2. Add or update
                        const res =
                            await TaskService.getInstance().getDetailTaskIncognitoById(
                                taskId
                            );
                        const taskDetail = res['result'];

                        const updatedTask = {
                            ...taskDetail,
                            urgency: getUrgencyValue(
                                taskDetail?.priority,
                                taskDetail?.important
                            ),
                        };

                        const existedTaskIndex = this.overviewTeamTasks[
                            userId
                        ]?.overviewTaskList?.findIndex((t) => t?.id == taskId);
                        if (existedTaskIndex > -1) {
                            this.overviewTeamTasks[userId].overviewTaskList[
                                existedTaskIndex
                            ] = updatedTask;
                        } else {
                            this.overviewTeamTasks[userId].overviewTaskList = [
                                updatedTask,
                                ...this.overviewTeamTasks[userId]
                                    ?.overviewTaskList,
                            ];
                        }
                    }
                    break;
            }
        },
    },
});
