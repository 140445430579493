<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import dayjs from 'dayjs';
import Sortable from 'sortablejs';
import TabTaskTag from '@/ui/modules/task/components/tabs/TabTaskTag.vue';
import ActionInBoard from '@/ui/modules/task/board/board-container/ActionInBoard.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskAttachmentDetail from '@/ui/modules/task/detail/TaskAttachmentDetail.vue';
import TaskBoardCardLoading from '@/ui/modules/task/board/task-card/TaskBoardCardLoading.vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import {
  ETaskByDeadline,
  _getLastWeekPeriod,
  _getNextWeekPeriod,
} from '@/application/constants/task.const';
import localConfigsStore from '@/store/local-configs';
import taskStore from '@/store/task';
import {
  ScheduleDayOptions,
  ScheduleOtherTimeOptions,
} from '@/domain/enums/taskEnum';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import groupStore from '@/store/group';
import { useElementVisibility } from '@vueuse/core';
import useTaskBoardCard, { getFilterPayloadDefault } from './useTaskBoardCard';
import { ETaskListModule } from '@/application/types/task/task.types';
import myProfileStore from '@/store/auth/my-profile';
import taskListStore from '@/store/task/task-list-store';

const props = withDefaults(
  defineProps<{
    cardId: string;
    cardInformation: {
      id: string;
      name: string;
      index: number;
      color: string;
      visible: boolean;
      filterFunc: Function;
      scheduleTime: string;
      filterObject: {
        isNoSchedule?: boolean;
        deadlineFromDate?: string;
        deadlineToDate?: string;
      };
    };
    type: 'USER' | 'GROUP' | 'DOMAIN' | 'IA';
    sourceId: number;
  }>(),
  {
    type: 'USER',
  }
);

defineEmits(['onCollapseBoard', 'onOpenTaskDetail', 'onAddNewTask']);

const _taskTabViewStore = taskTabViewStore();
const _taskStore = taskStore();

const _localConfigsStore = localConfigsStore();
const _taskListStore = taskListStore();

const cardIdInCache = computed<any>(() => `BOARD_BY_DEADLINE_${props.cardId}`);
const localConfigs = computed<any>(() => _localConfigsStore.localConfigs);
const sourceType = computed<ETaskListModule>(() => {
  if (props.type === 'GROUP') return ETaskListModule.GROUP;
  if (props.type === 'DOMAIN') return ETaskListModule.DOMAIN;
  if (props.type === 'IA') return ETaskListModule.I_ASSIGNED;
  return ETaskListModule.USER;
});
const boardShowFinishedTaskByDeadline = computed(
  () => _taskTabViewStore.boardShowFinishedTaskByDeadline
);

onMounted(() => {});

watch(
  () => boardShowFinishedTaskByDeadline.value,
  async () => {
    if (!isCollapse.value) initData(filterPayload.value);
  }
);

const onCollapseBoard = () => {
  if (isCollapse.value && !isReady.value) initData(filterPayload.value);

  _localConfigsStore.setLocalConfigs('task', {
    [cardIdInCache.value]: {
      isCollapse: !isCollapse.value,
    },
    latestCollapseDeadlineCardAt: Date.now(),
  });
};
const isAddNewTask = ref(null as any);

const isHasOpenAttachment = ref(null as any);
const currentTaskId = ref(0 as number);
const isOpenAttachmentDetail = ref(false);
const onOpenAttachmentDetail = (taskId) => {
  if (isHasOpenAttachment.value) isHasOpenAttachment.value = null;
  currentTaskId.value = taskId;
  isHasOpenAttachment.value = taskId;
  isOpenAttachmentDetail.value = true;
};

const onAddNewTask = (cardId) => {
  console.log('cardId', cardId);
  isAddNewTask.value = cardId;
};

const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;
};

const isHovered = ref<boolean>(false);
const onHoverCard = () => {
  if (isHovered.value) return;

  onLoadMore();
  isHovered.value = true;
};
const isCollapse = computed(() => {
  return localConfigs.value['task']?.[cardIdInCache.value]?.isCollapse;
});

watch(
  () => isCollapse.value,
  () => {
    if (isCollapse.value) {
      setTimeout(() => {
        createNewDragInstance();
      });
    }
  }
);

const taskBoardCardRef = ref(null);

const userDayDeadlineInfo = computed(() => _taskStore.userDayDeadlineInfo);

const getTaskScheduleObject = (dateFormat) => {
  if (!dateFormat)
    return {
      scheduleTime: null,
      scheduleOptionKey: ScheduleDayOptions.Nothing,
      otherTimeKey: ScheduleOtherTimeOptions.SpecificDate,
    };

  const dayInfoList = [
    ...userDayDeadlineInfo.value,
    // ...userOtherDay.value,
  ]?.filter((o) => o.scheduleDateValue);

  const findDateInData = dayInfoList?.find(
    (o) => o?.scheduleDateValue == dateFormat
  );

  let scheduleOptionKey, otherTimeKey;
  if (findDateInData) {
    scheduleOptionKey = Number.isInteger(findDateInData?.scheduleOptionKey)
      ? findDateInData?.scheduleOptionKey
      : ScheduleDayOptions.OtherTime;

    otherTimeKey =
      findDateInData?.otherTime || ScheduleOtherTimeOptions.SpecificDate;
  } else {
    scheduleOptionKey = ScheduleDayOptions.OtherTime;

    otherTimeKey = ScheduleOtherTimeOptions.SpecificDate;
  }

  return {
    scheduleTime: dateFormat,
    scheduleOptionKey,
    otherTimeKey,
  };
};

const isGroupMember = computed<boolean>(() => {
  if (sourceType.value !== 'G') return false;
  return groupStore().checkUserIsGroupMember(
    props.sourceId,
    getCurrentUserId()
  );
});

const hasPermission = computed<boolean>(() => {
  return sourceType.value == 'G' ? isGroupMember.value : true;
});

const createNewDragInstance = () => {
  if (!taskBoardCardRef.value || !hasPermission.value) return;

  new Sortable(taskBoardCardRef.value, {
    group: 'task-board-card_deadline',
    animation: 150,
    ghostClass: 'blue-background-class',
    chosenClass: 'sortable-chosen-class',
    filter: '.filtered',
    preventOnFilter: true,
    dataIdAttr: 'Hyrin',
    handle: '.task-card-item_handle',
    // onStart: function (/**Event*/) {
    //   console.log('🚀 Hyrin ~ on start drag:', props.cardInformation);
    // },
    // onEnd: function (/**Event*/) {
    //   console.log('🚀 Hyrin ~ on end drag:', props.cardInformation);
    // },
    onAdd: async function (/**Event*/ evt) {
      if (!evt?.item?.id) return;

      const taskDetail = allTaskByIds.value[evt?.item?.id];

      const payload = getTaskScheduleObject(
        props.cardInformation?.scheduleTime
      );
      console.log('🚀 Tictop ~ payload:', payload);
      const newTaskDetail = new TaskDetailClass(taskDetail);

      if (newTaskDetail?.assigneeId)
        await taskStore().getUserTaskSchedule(newTaskDetail?.assigneeId);

      await newTaskDetail.changeDeadline(payload);
      taskStore().updateAllTaskByIds(taskDetail?.id, newTaskDetail);
    },
    onMove: function () {
      return 1;
    },
  });
};
onMounted(() => {
  createNewDragInstance();

  // Set default collapse
  if (
    !localConfigs.value['task']?.latestCollapseDeadlineCardAt &&
    (props.cardId == ETaskByDeadline.NO_SCHEDULE ||
      props.cardId == ETaskByDeadline.OVER_A_WEEK_AGO ||
      props.cardId == ETaskByDeadline.LAST_WEEK ||
      props.cardId == ETaskByDeadline.MORE_THAN_A_WEEK)
  )
    _localConfigsStore.setLocalConfigs('task', {
      [cardIdInCache.value]: {
        isCollapse: true,
      },
    });
});

const targetIsVisible = useElementVisibility(taskBoardCardRef);

const filterObject = computed<any>(() => {
  const filterByKeys = {
    [ETaskByDeadline.NO_SCHEDULE]: {
      isNoSchedule: true,
    },
    [ETaskByDeadline.OVER_A_WEEK_AGO]: {
      isNoSchedule: false,
      deadlineToDate: dayjs(_getLastWeekPeriod().startDate)
        .add(-1, 'day')
        .utc(),
    },
    [ETaskByDeadline.LAST_WEEK]: {
      isNoSchedule: false,
      deadlineFromDate: dayjs(_getLastWeekPeriod().startDate).utc(),
      deadlineToDate: dayjs(_getLastWeekPeriod().endDate).utc(),
    },
    [ETaskByDeadline.YESTERDAY]: {
      isNoSchedule: false,
      deadlineFromDate: dayjs.utc(myProfileStore().myToday).add(-1, 'd'),
      deadlineToDate: dayjs.utc(myProfileStore().myToday).add(-1, 'd'),
    },
    [ETaskByDeadline.TODAY]: {
      isNoSchedule: false,
      deadlineFromDate: dayjs.utc(myProfileStore().myToday),
      deadlineToDate: dayjs.utc(myProfileStore().myToday),
    },
    [ETaskByDeadline.TOMORROW]: {
      isNoSchedule: false,
      deadlineFromDate: dayjs.utc(myProfileStore().myToday).add(1, 'd'),
      deadlineToDate: dayjs.utc(myProfileStore().myToday).add(1, 'd'),
    },
    [ETaskByDeadline.NEXT_WEEK]: {
      isNoSchedule: false,
      deadlineFromDate: dayjs(_getNextWeekPeriod().startDate).utc(),
      deadlineToDate: dayjs(_getNextWeekPeriod().endDate).utc(),
    },
    [ETaskByDeadline.MORE_THAN_A_WEEK]: {
      isNoSchedule: false,
      deadlineFromDate: dayjs(_getNextWeekPeriod().endDate).utc(),
    },
  };
  return filterByKeys[props.cardInformation?.id];
});

const filterPayload = computed<any>(() => {
  const _filter = getFilterPayloadDefault(props.sourceId, sourceType.value);
  _filter.isDefault = false;
  _filter.status = boardShowFinishedTaskByDeadline.value
    ? undefined
    : `0,7,1,2,8,6,4,5`;

  const customFilter = _taskListStore.filterPayload;

  return {
    ...(customFilter?.isDefault ? _filter : customFilter),
    isNoSchedule: filterObject.value?.isNoSchedule,
    deadlineFromDate: filterObject.value?.deadlineFromDate
      ? filterObject.value?.deadlineFromDate
      : undefined,
    deadlineToDate: filterObject.value?.deadlineToDate
      ? filterObject.value?.deadlineToDate
      : undefined,
  };
});

const {
  isReady,
  taskAllList,
  allTaskByIds,
  currentSortData,
  listTaskIdShowing,
  isLoading,
  initData,
  onLoadMore,
  onChangeIterate,
  onChangeOrder,
  onSearch,
} = useTaskBoardCard();

const initComponent = () => {
  if (targetIsVisible.value) initData(filterPayload.value);
};

watch(
  () => targetIsVisible.value,
  (value) => {
    if (value && !isReady.value && !isCollapse.value)
      initData(filterPayload.value);
  }
);
initComponent();
</script>

<template>
  <div
    v-bind="$attrs"
    class="
      flex flex-col
      h-full
      shrink-0
      hover-to-show__parent
      task-board-card-deadline
    "
    :style="isCollapse ? `width: 3rem` : `width: 25rem`"
    @mouseover="onHoverCard"
  >
    <div
      class="
        p-2
        rounded
        flex
        items-center
        justify-between
        drag-to-scroll-target
        hover:bg-current-50
      "
      :class="isCollapse ? 'flex-col-reverse ' : 'pl-2'"
    >
      <TabTaskTag
        v-if="!isCollapse"
        is-active
        readonly
        :color="cardInformation?.color || ''"
        :name="cardInformation?.name"
        :total="taskAllList?.length"
      />
      <ActionInBoard
        type="STATUS"
        hidden-class="task-board-card-deadline__hover-to-show"
        :tab="cardInformation"
        :is-collapse="isCollapse"
        :current-sort-data="currentSortData"
        readonly
        @on-collapse-board="onCollapseBoard"
        @on-add-new-task="onAddNewTask(cardId)"
        @on-change-iterate="onChangeIterate"
        @on-change-order="onChangeOrder"
        @on-search="onSearch"
      />
    </div>

    <template v-if="isCollapse">
      <div
        class="
          bg-gray-50
          rounded
          flex-1
          overflow-y-auto
          small-scrollbar
          relative
        "
      >
        <TabTaskTag
          class="absolute min-w-max"
          style="
            transform-origin: left;
            transform: rotate(90deg) translateY(-50%);
            left: 25%;
          "
          readonly
          is-active
          :color="cardInformation?.color"
          :name="cardInformation?.name"
          :total="taskAllList?.length"
        />
      </div>
    </template>
    <template v-else>
      <div
        ref="taskBoardCardRef"
        v-scroll-infinite="onLoadMore"
        class="
          bg-gray-50
          rounded
          flex-1
          overflow-y-auto
          small-scrollbar
          relative
        "
      >
        <template v-if="isLoading">
          <TaskBoardCardLoading class="m-2" />
        </template>

        <template v-else>
          <TaskCardItem
            v-for="taskId in listTaskIdShowing"
            :id="taskId"
            :key="taskId"
            class="m-2"
            :is-draggable="hasPermission"
            :task-detail="allTaskByIds[taskId]"
            is-change-deadline
            @on-show-files="onOpenAttachmentDetail(taskId)"
          />
        </template>
      </div>
    </template>
  </div>

  <TaskAttachmentDetail
    v-if="isOpenAttachmentDetail"
    :task-id="currentTaskId"
    @on-close="onCloseAttachment"
  />
</template>
<style>
.task-board-card-deadline__hover-to-show {
  display: none;
}
.task-board-card-deadline:hover .task-board-card-deadline__hover-to-show {
  display: flex;
}
</style>
