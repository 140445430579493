<script setup lang="ts">
import { computed } from 'vue';
import departmentStore from '@/store/department';
import DepartmentHasNotAdminWarning from '@/ui/modules/departments/components/DepartmentHasNotAdminWarning.vue';

const props = withDefaults(
  defineProps<{
    departmentId: string | number;
    isHiddenName?: boolean;
    isHiddenAvatar?: boolean;
    avatarClass?: string;
    shape?: string;
    placeholder?: string;
    isNameCenter?: boolean;
  }>(),
  {
    avatarClass: 'w-6 h-6',
    shape: '',
    placeholder: '',
    isNameCenter: true,
  }
);
const _departmentStore = departmentStore();

const departmentInfo = computed(() => {
  return _departmentStore.allDepartmentByIds[props.departmentId];
});
</script>

<template>
  <div
    v-vig-tooltip="departmentInfo?.name"
    class="w-full flex items-center space-x-2 font-sans"
  >
    <span
      v-if="!isHiddenAvatar"
      class="relative flex-center"
      :class="avatarClass"
    >
      <SynAvatar
        :src="departmentInfo?.avatarUrl?.replace('original', 'mini')"
        :custom-class="avatarClass"
        :name="departmentInfo?.name"
        :is-deactive="!departmentInfo?.isActive"
        :shape="shape ? shape : 'circle'"
      />
      <!--<span class="w-full h-full absolute bg-gray-100 opacity-50"></span>-->
    </span>
    <div v-if="!isHiddenName" class="w-full flex flex-col">
      <slot name="descriptionName"></slot>
      <span
        class="w-full relative space-x-2 flex items-center"
        :class="isNameCenter ? 'justify-center' : ''"
      >
        <span class="text-overflow-hidden-line">
          {{ departmentInfo?.name }}
        </span>

        <DepartmentHasNotAdminWarning v-if="departmentInfo?.isHasNotAnyAdmin" />
      </span>
      <slot name="subName"></slot>
    </div>
  </div>
</template>
