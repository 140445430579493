<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        clip-rule="evenodd"
        d="m14.4685 6.41438c.3234.25875.3759.73072.1171 1.05417l-3.6252 4.53145 3.6252 4.5315c.2588.3235.2063.7954-.1171 1.0542-.3235.2587-.7954.2063-1.0542-.1172l-3.99998-5c-.21913-.2739-.21913-.6631 0-.937l3.99998-4.99999c.2588-.32345.7307-.37589 1.0542-.11713z"
        fill-rule="evenodd"
      ></path>
    </g>
  </svg>
</template>
