<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g
      id="Icon_feather-grid"
      data-name="Icon feather-grid"
      transform="translate(-3 -3)"
    >
      <path id="Path_1" data-name="Path 1" d="M4.5,4.5H15V15H4.5Z" />
      <path id="Path_2" data-name="Path 2" d="M21,4.5H31.5V15H21Z" />
      <path id="Path_3" data-name="Path 3" d="M21,21H31.5V31.5H21Z" />
      <path id="Path_4" data-name="Path 4" d="M4.5,21H15V31.5H4.5Z" />
    </g>
  </svg>
</template>
