import { Ref, ref, UnwrapRef } from 'vue';
import ticketPlugin from '@/ticket/services/plugin-services';
import { CalendarDetail } from '@/domain/entities/PayloadClass/CalendarPayloadClass';

export default function noteLogic(formData: Ref<UnwrapRef<CalendarDetail>>) {
    // Note
    const currentNote = ref({} as any);

    const isCreateNote = ref(false);
    const isLoadingDrawData = ref(false);
    const isPasted = ref(false);

    const isParstedImage = (objects) => {
        return (
            objects.length == 1 &&
            objects[0].type.includes('image') &&
            isPasted.value
        );
    };
    const onSaveNote = async (noteData) => {
        const base64 = noteData?.base64;
        const drawData = noteData?.drawData;
        const audios = noteData?.audios;
        const objects = drawData.objects;
        // 1. If have only image save as attachments
        if (isParstedImage(objects)) {
            await formData.value.addFiles([
                {
                    name: `event-tictop-note-${Date.now()}.jpeg`,
                    fileUrl: objects[0].src,
                    base64: base64,
                    size: base64.length * (3 / 4) - 2,
                    type: 'image/jpeg',
                    isBase64: true,
                },
            ]);
        } else {
            formData.value.addNote(noteData);
            if (audios?.length > 0) {
                await formData.value.addFiles(audios);
            }
        }

        onCancelNote();
    };
    const onCancelNote = () => {
        isCreateNote.value = false;
        isLoadingDrawData.value = true;
        isPasted.value = false;

        ticketPlugin.closeNote();
    };

    const onCreateNote = (pasted = false) => {
        currentNote.value = {};
        isCreateNote.value = true;
        isLoadingDrawData.value = false;
        isPasted.value = pasted;

        ticketPlugin.openNote();
    };

    const onEditNote = (file) => {
        currentNote.value = file;
        isCreateNote.value = true;
    };
    const onRemoveNote = (file) => {
        formData.value.removeNote(file);
    };

    return {
        isCreateNote,
        currentNote,
        isLoadingDrawData,
        isPasted,
        onSaveNote,
        onCreateNote,
        onCancelNote,
        onRemoveNote,
        onEditNote,
    };
}
