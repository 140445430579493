<script setup lang="ts">
import { ref } from 'vue';
import taskLogActivityComposable from '@/ui/composables/task/history-task';
import ItemActivity from '@/ui/modules/task/components/activity-tasklog/ItemActivity.vue';

const props = defineProps({
  taskId: {
    type: [String, Number],
    default: 0,
  },
  organizationId: {
    type: Number,
    default: 0,
  },
  users: {
    type: Array,
    default: () => [],
  },
});

const {
  detailTaskHistory,
  isLoadingDetail,
  orderByTask,
  onChangeOrderBy,
  onCollapse,
  initData,
} = taskLogActivityComposable({
  taskId: props.taskId,
  orgId: props.organizationId,
});

const refDetailTask = ref();
const heightDetailTask = ref(0);

const onCollapseDate = (date) => {
  onCollapse(date);
};

const onChangeOrderByTaskLog = () => {
  if (!refDetailTask.value) return;

  heightDetailTask.value = refDetailTask.value?.getBoundingClientRect()?.height;

  onChangeOrderBy();
};

defineExpose({
  initData,
});
</script>

<template>
  <div class="flex flex-col">
    <!-- FILTER   -->
    <div
      v-if="Object.keys(detailTaskHistory)?.length > 0"
      class="w-full flex items-center justify-end"
    >
      <div
        class="
          w-max
          items-center
          space-x-1
          text-xs
          px-2
          py-1
          cursor-pointer
          rounded
          bg-gray-100
          hover:bg-gray-200
        "
        @click="onChangeOrderByTaskLog"
      >
        <span class="font-medium">{{
          orderByTask === 'desc'
            ? $t('COMMOM_LABEL_NEWEST_FIRST')
            : $t('COMMOM_LABEL_OLDEST_FIRST')
        }}</span>
        <SynIcon
          v-if="orderByTask === 'desc'"
          name="SortDesc"
          class="fill-gray-500"
          custom-class="w-3.5 h-3.5 "
        />
        <SynIcon
          v-else
          name="SortAsc"
          class="fill-gray-500"
          custom-class="w-3.5 h-3.5"
        />
      </div>
    </div>

    <!-- ITEM   -->
    <template v-if="!isLoadingDetail">
      <div
        v-if="Object.keys(detailTaskHistory)?.length > 0"
        ref="refDetailTask"
      >
        <div
          v-for="(arrayDate, date, index) in detailTaskHistory"
          :key="date"
          class="relative pl-3 py-4"
          :class="
            arrayDate?.isCollapse &&
            Object.values(detailTaskHistory)?.length - 1 === index
              ? ''
              : 'border-l border-current-50 '
          "
          style="min-height: 3rem"
        >
          <div
            class="
              absolute
              -top-2
              -left-1
              flex
              items-center
              cursor-pointer
              space-x-2
            "
            @click="onCollapseDate(date)"
          >
            <span class="w-2 h-2 rounded-full bg-current"></span>
            <span class="font-medium text-gray-500 cursor-pointer text-xs">
              <SynLabelDateTime format="date" :datetime="date" />
            </span>
            <syn-icon
              name="arrow-right"
              custom-class="fill-current w-3.5 h-3.5"
              :class="arrayDate?.isCollapse ? '' : 'rotate-90'"
            />
          </div>

          <template v-if="!arrayDate?.isCollapse">
            <div
              v-for="item in arrayDate?.histories"
              :key="item"
              class="mb-1 mt-5 pl-3"
            >
              <ItemActivity :activity="item" :users="users" />
            </div>
          </template>
        </div>
      </div>
      <template v-else>
        <div
          class="
            rounded
            bg-gray-50 bg-opacity-50
            py-4
            my-3
            space-y-2
            flex-center flex-col
            text-xs text-gray-600
          "
        >
          <SynAnimation
            name="historyTaskLog"
            stype="width: 80px; height: 80px;"
          />
          <span>{{ $t('ACTIVITY_HISTORY_NOT_LOG') }}</span>
        </div>
      </template>
    </template>

    <template v-else>
      <div :style="`height: ${heightDetailTask}px`">
        <div class="flex flex-col space-y-2 w-full py-2">
          <div class="w-1/4 bg-gray-200 h-3 rounded-full animate-pulse"></div>
          <div class="w-2/3 bg-gray-200 h-3 rounded-full animate-pulse"></div>
        </div>
      </div>
    </template>
  </div>
</template>
