<template>
  <div class="w-full h-full flex flex-col space-y-4 animate-pulse">
    <div class="flex justify-between">
      <div class="h-6 w-1/2 bg-gray-100 rounded-full"></div>
      <div class="h-6 w-1/4 bg-gray-100 rounded-full"></div>
    </div>
    <div class="h-24 w-full bg-gray-100 rounded-md"></div>
    <div class="h-40 w-full bg-gray-100 rounded-md"></div>
  </div>
</template>
