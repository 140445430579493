<script setup lang="ts">
import { computed } from 'vue';
import chatSupportingStore from '@/store/chat/chat-supporting';
import chatStore from '@/store/chat';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import ConversationDelete from '@/ui/modules/messaging/chat-action/conversation-delete/ConversationDelete.vue';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';

const props = defineProps<{
  conversationId: string;
  conversation: ChatConversationModel;
  canGoToChatScreen: boolean;
  canAddSupporters: boolean;
}>();

const emit = defineEmits([
  'onShowFilesClick',
  'onShowPinnedMessagesClick',
  'onGoToChatScreenClick',
  'onOpenNoteClick',
  'onGoToChatScreenClick',
  'onAddSupportersClick',
  'onSupportingChatLeft',
]);

const _chatSupportingStore = chatSupportingStore();

const isDraftSupportingConversation = computed(
  () => _chatSupportingStore.supportingConversationsDraft[props.conversationId]
);

const onSupporterLeaveClick = async () => {
  const result = await _chatSupportingStore.leaveConversationSupporting(
    props.conversationId
  );

  if (result) {
    activeAndFocusOnConversationId.value = '';
    chatStore().setConversationActive(null);

    emit('onSupportingChatLeft');
  }
};
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <slot></slot>
    </template>
    <template #dropdown-menu>
      <div class="text-sm">
        <VigButton
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onShowFilesClick')"
        >
          <SynIcon name="Files" />
          <span class="font-normal">{{ $t('CHAT_MINI_SHOW_FILES') }}</span>
        </VigButton>
        <VigButton
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onShowPinnedMessagesClick')"
        >
          <SynIcon name="Pin" />
          <span class="font-normal">{{
            $t('CHAT_MINI_SHOW_PINNED_MESSAGES')
          }}</span>
        </VigButton>
        <VigButton
          v-if="canGoToChatScreen"
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onGoToChatScreenClick')"
        >
          <SynIcon name="Message" />
          <span class="font-normal">{{
            $t('CHAT_MINI_GO_TO_CHAT_SCREEN')
          }}</span>
        </VigButton>
        <VigButton
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onOpenNoteClick')"
        >
          <SynIcon name="Noter" is-active class="fill-orange-500" />
          <span class="font-normal">{{
            $t('CHAT_MINI_SHOW_DESIGN_SCREEN')
          }}</span>
        </VigButton>
        <VigButton
          v-if="canAddSupporters"
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="$emit('onAddSupportersClick')"
        >
          <SynIcon name="CustomerSupport" />
          <span class="font-normal">{{ $t('CHAT_SUPPORTERS_ADD') }}</span>
        </VigButton>
        <VigButton
          v-if="canAddSupporters && !isDraftSupportingConversation"
          ghost
          color="gray"
          class="w-full space-x-4 justify-start dropdown-item"
          @click="onSupporterLeaveClick"
        >
          <SynIcon name="Logout" />
          <span class="font-normal">{{ $t('CHAT_LEAVE') }}</span>
        </VigButton>
        <ConversationDelete
          v-if="conversation?.supportingChat"
          :conversation="conversation"
        >
          <VigButton
            ghost
            color="red"
            class="w-full space-x-4 justify-start dropdown-item"
          >
            <SynIcon name="Trash" />
            <span class="font-normal">{{ $t('CHAT_DELETE') }}</span>
          </VigButton>
        </ConversationDelete>
      </div>
    </template>
  </VigDropdown>
</template>
