<script setup lang="ts">
import { ref } from 'vue';
import ModalFilesUpload from '@/ui/modules/ged/upload/modal-files-upload/ModalFilesUpload.vue';
import ModalUploadResult from '@/ui/modules/ged/upload/modal-upload-result/ModalUploadResult.vue';
import $constants from '@/ui/plugins/constants';
import { translate } from '@/ui/plugins/i18n/myi18n';
import myProfileStore from '@/store/auth/my-profile';
import groupStore from '@/store/group';
import userStore from '@/store/user';

const props = defineProps<{
  fileFilters?: any;
  selectedFolder?: any;
}>();

defineEmits(['onUploaded']);
const selectedFiles = ref();
const showPreventUploadMsg = ref();
const uploadingInfo = ref();

const uploadFiles = (files) => {
  const _groupStore = groupStore();
  const _userStore = userStore();

  const myProfile = myProfileStore().myProfile;
  const allMyGroups = _groupStore.allMyGroups;
  const allUsers = _userStore.listShortInfosUser;

  if (props.fileFilters?.category === $constants.FILE.CATEGORY.TASK) {
    return (showPreventUploadMsg.value = translate(
      'GED_UPLOAD_PREVENT_CATEGORY_MSG',
      {
        category: translate('GED_FILTER_TASK'),
      }
    ));
  }

  if (props.fileFilters?.category === $constants.FILE.CATEGORY.CHAT) {
    return (showPreventUploadMsg.value = translate(
      'GED_UPLOAD_PREVENT_CATEGORY_MSG',
      {
        category: translate('GED_FILTER_CHAT'),
      }
    ));
  }

  if (
    props.fileFilters?.userId &&
    props.fileFilters?.userId !== myProfile?.id
  ) {
    const userInfo = allUsers?.find(
      (user) => user?.id === props.fileFilters?.userId
    );

    return (showPreventUploadMsg.value = translate(
      'GED_UPLOAD_PREVENT_USER_MSG',
      { user: userInfo?.name }
    ));
  }
  if (props.fileFilters?.groupId) {
    const groupInfo = allMyGroups?.find(
      (group) => group?.id === props.fileFilters?.groupId
    );

    if (!groupInfo?.isMember) {
      return (showPreventUploadMsg.value = translate(
        'GED_UPLOAD_PREVENT_GROUP_MSG',
        { group: groupInfo?.name }
      ));
    }
  }

  if (!files?.length) return;

  selectedFiles.value = files;
};

defineExpose({
  uploadFiles,
});
</script>

<template>
  <ModalFilesUpload
    v-if="selectedFiles?.length"
    :files="selectedFiles"
    :file-filters="fileFilters"
    :selected-folder="selectedFolder"
    @on-close="selectedFiles = null"
    @on-upload="
      selectedFiles = null;
      uploadingInfo = $event;
    "
  />

  <ModalUploadResult
    v-if="uploadingInfo"
    :files="uploadingInfo.files"
    :location="uploadingInfo.location"
    @on-close="
      uploadingInfo = null;
      $emit('onUploaded');
    "
  />

  <SynQuestionModal
    v-if="showPreventUploadMsg"
    class="z-50"
    :visible="true"
    :header-text="$t('COMMON_LABEL_ALERT')"
    :content-text="showPreventUploadMsg"
    :show-cancel-btn="false"
    :show-confirm-btn="true"
    :show-delete-btn="false"
    @on-confirm="showPreventUploadMsg = null"
    @cancel="showPreventUploadMsg = null"
  />
</template>
