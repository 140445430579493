export const GOOGLE_ANALYTICS_ELEMENT_ID = {
    REGISTER: {
        register_by_phone_submit_form_btn: 'register_by_phone_submit_form_btn',
        register_by_phone_submit_verify_code_btn:
            'register_by_phone_submit_verify_code_btn',
        register_by_phone_submit_email_btn:
            'register_by_phone_submit_email_btn',
        register_by_username_submit_form_btn:
            'register_by_username_submit_form_btn',
        register_by_username_submit_password_btn:
            'register_by_username_submit_password_btn',
        register_by_username_submit_email_btn:
            'register_by_username_submit_email_btn',
    },
    SELECT_ORG_MODEL: {
        setting_org_select_company: 'setting_org_select_company',
        setting_org_select_company_description_back:
            'setting_org_select_company_description_back',
        setting_org_select_company_description_continue:
            'setting_org_select_company_description_continue',
        setting_org_select_company_information_back:
            'setting_org_select_company_information_back',
        setting_org_select_company_information_continue:
            'setting_org_select_company_information_continue',
        setting_org_select_company_type_back:
            'setting_org_select_company_type_back',
        setting_org_select_company_type_continue:
            'setting_org_select_company_type_continue',
        setting_org_select_company_sub_information_back:
            'setting_org_select_company_sub_information_back',
        setting_org_select_company_sub_information_complete:
            'setting_org_select_company_sub_information_complete',
        setting_org_select_team: 'setting_org_select_team',
        setting_org_select_team_description_back:
            'setting_org_select_team_description_back',
        setting_org_select_team_description_continue:
            'setting_org_select_team_description_continue',
        setting_org_select_team_information_back:
            'setting_org_select_team_information_back',
        setting_org_select_team_information_continue:
            'setting_org_select_team_information_continue',
        setting_org_select_team_type_back: 'setting_org_select_team_type_back',
        setting_org_select_team_type_continue:
            'setting_org_select_team_type_continue',
        setting_org_select_team_sub_information_back:
            'setting_org_select_team_sub_information_back',
        setting_org_select_team_sub_information_complete:
            'setting_org_select_team_sub_information_complete',
        setting_org_select_personal: 'setting_org_select_personal',
        setting_org_select_personal_2nd_select_organization:
            'setting_org_select_personal_2nd_select_organization',
        setting_org_select_personal_2nd_select_team:
            'setting_org_select_personal_2nd_select_team',
        setting_org_select_personal_2nd_back:
            'setting_org_select_personal_2nd_back',
        setting_org_select_personal_3rd_back:
            'setting_org_select_personal_3rd_back',
        setting_org_select_personal_3rd_continue:
            'setting_org_select_personal_3rd_continue',
        setting_org_select_personal_3rd_complete:
            'setting_org_select_personal_3rd_complete',
    },
};

export enum REGISTER_STEP_KEYS {
    EXPLAIN = 'EXPLAIN',
    ENTER_MAIN_INFO = 'ENTER_MAIN_INFO',
    CHOOSE_TYPE = 'CHOOSE_TYPE',
    ENTER_SUB_INFO = 'ENTER_SUB_INFO',
}
export const dataByOrganizationModel = {
    COMPANY: {
        nameKey: 'COMMON_LABEL_COMPANY',
        subTitle: 'SELECT_ORGANIZATION_MODEL_SUB_TITLE_COMPANY',
        background:
            'transparent linear-gradient(180deg, #00ABAB 0%, #4793F3 100%) 0% 0% no-repeat padding-box',
        explainList: [
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_1',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_2',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_3',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_4',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_5',
        ],

        sizeDefault: '11-20',
        defaultNameByLang: {
            en: 'My Company',
            fr: 'Mon entreprise',
            vi: 'Công ty của tôi',
        },

        backButtonIdByCurrentStep: {
            [REGISTER_STEP_KEYS.EXPLAIN]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_company_description_back,
            [REGISTER_STEP_KEYS.ENTER_MAIN_INFO]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_company_information_back,
            [REGISTER_STEP_KEYS.CHOOSE_TYPE]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_company_type_back,
            [REGISTER_STEP_KEYS.ENTER_SUB_INFO]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_company_sub_information_back,
        },
        nextButtonIdByCurrentStep: {
            [REGISTER_STEP_KEYS.EXPLAIN]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_company_description_continue,
            [REGISTER_STEP_KEYS.ENTER_MAIN_INFO]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_company_information_continue,
            [REGISTER_STEP_KEYS.CHOOSE_TYPE]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_company_type_continue,
            [REGISTER_STEP_KEYS.ENTER_SUB_INFO]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_company_sub_information_complete,
        },
        placeholder: 'ORG_SETUP_PLACEHOLDER_NAME_COMPANY',
    },
    TEAM: {
        nameKey: 'COMMON_LABEL_TEAM',
        subTitle: 'SELECT_ORGANIZATION_MODEL_SUB_TITLE_TEAM',
        background:
            'transparent linear-gradient(180deg, #4BB0B2 0%, #18FCD4 100%) 0% 0% no-repeat padding-box',
        explainList: [
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_1',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_2',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_3',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_4',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_5',
        ],

        sizeDefault: '6-10',
        defaultNameByLang: {
            en: 'My Company',
            fr: 'Mon entreprise',
            vi: 'Công ty của tôi',
        },
        backButtonIdByCurrentStep: {
            [REGISTER_STEP_KEYS.EXPLAIN]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_team_description_back,
            [REGISTER_STEP_KEYS.ENTER_MAIN_INFO]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_team_information_back,
            [REGISTER_STEP_KEYS.CHOOSE_TYPE]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_team_type_back,
            [REGISTER_STEP_KEYS.ENTER_SUB_INFO]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_team_sub_information_back,
        },
        nextButtonIdByCurrentStep: {
            [REGISTER_STEP_KEYS.EXPLAIN]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_team_description_continue,
            [REGISTER_STEP_KEYS.ENTER_MAIN_INFO]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_team_information_continue,
            [REGISTER_STEP_KEYS.CHOOSE_TYPE]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_team_type_continue,
            [REGISTER_STEP_KEYS.ENTER_SUB_INFO]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_team_sub_information_complete,
        },
        placeholder: 'ORG_SETUP_PLACEHOLDER_NAME_TEAM',
    },
    PERSONAL: {
        nameKey: 'COMMON_LABEL_PERSONAL',
        subTitle: 'SELECT_ORGANIZATION_MODEL_SUB_TITLE_PERSONAL',
        background:
            'transparent linear-gradient(180deg, #10B99B 0%, #FFE692 100%) 0% 0% no-repeat padding-box',
        explainList: [
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_PERSONAL_1',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_PERSONAL_2',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_PERSONAL_3',
        ],
        sizeDefault: '1',
        defaultNameByLang: {
            en: 'My workspace',
            fr: 'Espace de travail personnel',
            vi: 'Không gian cá nhân',
        },
        backButtonIdByCurrentStep: {
            [REGISTER_STEP_KEYS.EXPLAIN]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_personal_3rd_back,
            [REGISTER_STEP_KEYS.ENTER_MAIN_INFO]: '',
            [REGISTER_STEP_KEYS.CHOOSE_TYPE]: '',
            [REGISTER_STEP_KEYS.ENTER_SUB_INFO]: '',
        },
        nextButtonIdByCurrentStep: {
            [REGISTER_STEP_KEYS.EXPLAIN]:
                GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_personal_3rd_continue,
            [REGISTER_STEP_KEYS.ENTER_MAIN_INFO]: '',
            [REGISTER_STEP_KEYS.CHOOSE_TYPE]: '',
            [REGISTER_STEP_KEYS.ENTER_SUB_INFO]: '',
        },
        placeholder: 'ORG_SETUP_PLACEHOLDER_NAME_PERSONAL',
    },
};

export const STYLE_BY_ORGANIZATION_MODEL = {
    COMPANY: {
        nameKey: 'COMMON_LABEL_COMPANY',
        subTitle: 'SELECT_ORGANIZATION_MODEL_SUB_TITLE_COMPANY',
        background:
            'transparent linear-gradient(180deg, #00ABAB 0%, #4793F3 100%) 0% 0% no-repeat padding-box',
        explainList: [
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_1',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_2',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_3',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_4',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_COMPANY_5',
        ],
    },
    TEAM: {
        nameKey: 'COMMON_LABEL_TEAM',
        subTitle: 'SELECT_ORGANIZATION_MODEL_SUB_TITLE_TEAM',
        background:
            'transparent linear-gradient(180deg, #4BB0B2 0%, #18FCD4 100%) 0% 0% no-repeat padding-box',
        explainList: [
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_1',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_2',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_3',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_4',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_TEAM_5',
        ],
    },
    PERSONAL: {
        nameKey: 'COMMON_LABEL_PERSONAL',
        subTitle: 'SELECT_ORGANIZATION_MODEL_SUB_TITLE_PERSONAL',
        background:
            'transparent linear-gradient(180deg, #10B99B 0%, #FFE692 100%) 0% 0% no-repeat padding-box',
        explainList: [
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_PERSONAL_1',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_PERSONAL_2',
            'SELECT_ORGANIZATION_MODEL_EXPLAIN_PERSONAL_3',
        ],
    },
};
