import { Ref, ref, UnwrapRef } from 'vue';
import { DomainAddNewType } from '../ui-enums/domain-enums';
import domainStore from '@/store/scope';
import groupStore from '@/store/group';
import { DomainAddPayload } from '@/application/types/domain/DomainPayload';

export default function newDomainComps(formData: Ref<UnwrapRef<any>>) {
    const _domainStore = domainStore();
    const addNewDomainParams = ref({
        addNewDomainType: null as DomainAddNewType | null,
        addNewProjectType: null as DomainAddNewType | null,
        addNewSubProjectType: null as DomainAddNewType | null,
    });
    const createNewDomain = async () => {
        const newDomain: any = await _domainStore.createDomain(
            new DomainAddPayload({
                name: formData.value.domainName,
                groupIds: formData.value.groupId?.toString(),
            })
        );

        formData.value.domainId = newDomain?.id;
        if (formData.value?.groupId) groupStore().fetchMyGroups();
    };
    const addDomainMember = () => {
        _domainStore.addMembers(
            {
                domainId: formData.value.domainId,
                userIds: formData.value.assigneeId?.toString(),
                groupIds: formData.value.groupId?.toString(),
            },
            false
        );
    };
    const addNewProject = async () => {
        const newProject: any = await _domainStore.createProject({
            domainId: formData.value.domainId,
            name: formData.value.projectName,
            memberIds: formData.value.assigneeId?.toString(),
        });

        formData.value.projectId = newProject?.id;
    };
    const addProjectMember = () => {
        _domainStore.addMembers(
            {
                domainId: formData.value.domainId,
                projectId: formData.value.projectId,
                userIds: formData.value.assigneeId?.toString(),
            },
            false
        );
    };
    const addNewSubProject = async () => {
        const newProject: any = await _domainStore.createProject({
            domainId: formData.value.domainId,
            parentId: formData.value.projectId,
            name: formData.value.subprojectName,
            memberIds: formData.value.assigneeId?.toString(),
        });

        formData.value.projectId = newProject?.id;
    };
    const addSubProjectMember = () => {
        _domainStore.addMembers(
            {
                domainId: formData.value.domainId,
                projectId: formData.value.projectId,
                subProjectId: formData.value.subprojectId,
                userIds: formData.value.assigneeId?.toString(),
            },
            false
        );
    };

    const updateDomainBefore = async () => {
        try {
            if (
                addNewDomainParams.value.addNewDomainType ===
                DomainAddNewType.ADD_NEW
            )
                await createNewDomain();

            if (
                addNewDomainParams.value.addNewDomainType ===
                DomainAddNewType.ADD_MEMBER_ONLY
            )
                addDomainMember();

            if (
                addNewDomainParams.value.addNewProjectType ===
                DomainAddNewType.ADD_NEW
            )
                addNewProject();

            if (
                addNewDomainParams.value.addNewProjectType ===
                DomainAddNewType.ADD_MEMBER_ONLY
            )
                addProjectMember();

            if (
                addNewDomainParams.value.addNewSubProjectType ===
                DomainAddNewType.ADD_NEW
            )
                addNewSubProject();

            if (
                addNewDomainParams.value.addNewSubProjectType ===
                DomainAddNewType.ADD_MEMBER_ONLY
            )
                addSubProjectMember();

            _domainStore.fetchAllDomainProjects();
        } catch (error) {
            console.log('🚀 ~ updateDomainBefore ~ error', error);
        }
    };

    return {
        addNewDomainParams,
        updateDomainBefore,
    };
}
