import { computed, inject } from 'vue';
import { dashboardMenuList } from '@/ui/app-global-state';
import { dashboardMenus } from '@/ui/pages/overview/dashboard-type';
import { fullRouteByPermission } from '@/ui/router';
import settingStore from '@/store/setting';
import myProfileStore from '@/store/auth/my-profile';
import { EScreenPermission } from '@/application/types/block-function.types';

export default function () {
    const _settingStore = settingStore();
    const _myProfileStore = myProfileStore();
    const permissionScreen: Function | undefined = inject('permissionScreen');
    const domainManagement = computed(
        () => _settingStore._organizationSetting.domainManagement
    );

    const allRouteByPermission = computed(() => {
        const allChildrenRoute = fullRouteByPermission
            .filter((route) => route.children?.length > 0)
            .reduce((ollRoute, currentRoute) => {
                return [...ollRoute, ...currentRoute.children];
            }, []);
        return [...fullRouteByPermission, ...allChildrenRoute];
    });
    const initMenus = () => {
        let menuResult: any[] = [];
        for (const menu of dashboardMenus.sort((a, b) => a.index - b.index)) {
            const permissionByState = permissionScreen
                ? permissionScreen(menu?.screenKey)
                : false;
            if (
                menu?.permissionByRole?.includes(
                    _myProfileStore.myProfile?.adminType
                ) &&
                permissionByState !== EScreenPermission.HIDDEN &&
                permissionByState !== EScreenPermission.NOT_SET &&
                permissionByState !== null
            )
                menuResult.push(menu);
        }

        if (!domainManagement.value.showOnTab)
            menuResult = menuResult?.filter(
                (el) => el.name !== 'OverviewDomains'
            );

        dashboardMenuList.value = menuResult?.filter((menu) =>
            allRouteByPermission.value?.some((route) => route.name == menu.name)
        );
    };
    return {
        initMenus,
    };
}
