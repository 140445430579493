<script setup lang="ts">
import { ref, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    iconName?: string;
    isLoading?: boolean;
    customClass?: string;
    color?: string;
    label?: string;
    customClassIcon?: string;
    customClassLabel?: string;
    type?: 'OUT_LINE' | 'FILL' | 'TEXT' | 'BLANK';
    size?: 'sm' | 'md' | 'lg' | 'xl';
  }>(),
  {
    disabled: false,
    isLoading: false,
    customClass: 'rounded',
    color: 'current',
    customClassIcon: 'h-4 w-4',
    customClassLabel: '',
    iconName: '',
    label: '',
    type: 'FILL',
    size: 'lg',
  }
);

defineEmits(['click']);

const colorBtn = ref('');

const classByType = computed<string>(() => {
  if (props.type == 'FILL')
    return `bg-${props.color}-500 hover:bg-${props.color}-400 text-white fill-white hover:shadow-lg`;

  if (props.type == 'OUT_LINE')
    return `bg-transparent hover:bg-${props.color}-50 text-${props.color}-500 fill-${props.color}-500 border border-${props.color}-500`;

  if (props.type == 'TEXT')
    return `bg-transparent text-${props.color}-500 hover:bg-${props.color}-50 fill-${props.color}-500 font-normal`;
  return '';
});
const classBySize = computed<string>(() => {
  if (props.size == 'sm') return 'h-6 text-sm';

  if (props.size == 'md') return 'h-8';

  if (props.size == 'lg') return 'h-9 font-semibold text-base';
  if (props.size == 'xl') return 'h-12 text-lg font-semibold';
  return '';
});
</script>

<template>
  <button
    class="
      focus:outline-none
      flex-center
      relative
      flex-center
      space-x-1
      rounded
    "
    :class="[classByType, classBySize, customClass]"
    :style="
      disabled && !isLoading
        ? 'cursor: not-allowed; opacity: 0.5'
        : disabled && isLoading
        ? 'cursor: wait; opacity: 0.5'
        : 'cursor: pointer;'
    "
    :disabled="disabled || isLoading"
    @click="($event) => $emit('click', $event)"
  >
    <slot>
      <div class="flex-center space-x-2 px-4">
        <SynIcon
          v-if="isLoading"
          name="spinner"
          class="fill-white border-none animate-spin"
          custom-class="w-4 h-4"
        />
        <SynIcon
          v-else-if="iconName"
          class="border-none mr-1"
          :custom-class="customClassIcon"
          :color="props.type == 'FILL' ? 'white' : colorBtn"
          :name="iconName"
        />

        <span
          v-if="label"
          :class="['do-not-close-dropdown', customClassLabel]"
          v-html="$t(label) || label"
        ></span>
      </div>
    </slot>
  </button>
</template>
