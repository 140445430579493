<script setup lang="ts">
import UserById from '@/ui/components/user/UserById.vue';
import TicketTaskCommentInput from '@/ticket/modules/task/TicketTaskCommentInput.vue';
import commentTaskComposable from '@/ticket/compossables/comment-task';
import { computed, watch } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import taskCommentStore from '@/ticket/store/ticket-comment';

const props = defineProps({
  taskId: {
    type: [String, Number],
    default: 0,
  },
  currentTask: {
    type: Object,
    default: () => {},
  },
  organizationId: {
    type: Number,
    default: 0,
  },
  users: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits<{
  (e: 'onSaveSuccess', isNeedUpdateAttachments: boolean): void;
  (e: 'update:modelValue', value: any): void;
}>();

const {
  commentTaskInputRef,
  commentObjectCreate,
  isLoading,
  resetData,
  onUpdateContentValue,
  onCreateComment,
  updateCacheStateByTaskId,
} = commentTaskComposable();

const _taskCommentStore = taskCommentStore();

const currentId = getCurrentUserId();
const currentUser = computed(() =>
  props.users?.find((user: any) => user?.id === currentId)
);

const listUserRelated = computed(() => [
  props?.currentTask?.creatorId,
  props?.currentTask?.assigneeId,
]);

watch(
  () => props.currentTask,
  () => {
    const currentCommentCacheState =
      _taskCommentStore.commentCacheByTaskIds[props.currentTask?.id];

    if (
      currentCommentCacheState?.content ||
      currentCommentCacheState?.attachmentFiles?.length > 0 ||
      currentCommentCacheState?.noteList?.length > 0
    ) {
      updateCacheStateByTaskId(currentCommentCacheState);
    } else {
      resetData();
      commentTaskInputRef.value?.resetForm();
    }

    commentTaskInputRef.value?.onCloseTools();
  }
);

const onSendComment = async (attachments) => {
  await onCreateComment(
    props.organizationId,
    props.taskId || props?.currentTask?.id,
    attachments
  );

  emits(
    'onSaveSuccess',
    attachments?.addedFiles?.length > 0 || attachments?.addedNotes?.length > 0
  );
};

const onOpenInputComment = () => {
  // _taskDetailStore.setNavigatePoint('other', 'commentTaskInputRefId');
};

const onCloseInputComment = () => {
  resetData();
  commentTaskInputRef.value?.resetForm();
};
</script>

<template>
  <div class="flex w-full h-full items-start">
    <!--  CREATOR  -->
    <div class="w-8 h-8 pt-0.5 mr-3">
      <UserById
        :user-id="currentId"
        :user="currentUser"
        is-hidden-name
        avatar-class="w-8 h-8"
      />
    </div>
    <TicketTaskCommentInput
      ref="commentTaskInputRef"
      v-model="commentObjectCreate.content"
      v-model:mentionUserIds="commentObjectCreate.mentionUserIds"
      v-model:mentionGroup="commentObjectCreate.mentionGroup"
      v-model:attachments="commentObjectCreate.attachments"
      :task-id="currentTask?.id"
      :notes="commentObjectCreate?.attachments?.notes"
      :files="commentObjectCreate?.attachments?.files"
      edit-mode="CREATE"
      :group-id="currentTask?.groupId"
      :task-private="currentTask?.isPrivate"
      :users-related="listUserRelated"
      :is-loading-send="isLoading?.send"
      @update:model-value="onUpdateContentValue"
      @on-save="onSendComment"
      @on-open="onOpenInputComment"
      @on-close="onCloseInputComment"
      @on-focus="onOpenInputComment"
    />
  </div>
</template>
