import dayjs, { QUnitType } from 'dayjs';

export const getToDayUTC = () => {
    return Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
    );
};
export const diff = (d1, d2, unit: QUnitType) => {
    const date1 = dayjs(d1);
    const date2 = dayjs(d2);

    return date1.diff(date2, unit);
};

export const countWeedOfMonth = (date) => {
    // month_number is in the range 1..12

    const year = new Date(date).getFullYear();
    const month_number = new Date(date).getMonth();

    const firstOfMonth = new Date(year, month_number - 1, 1);
    const lastOfMonth = new Date(year, month_number, 0);

    const used = firstOfMonth.getDay() + lastOfMonth.getDate();

    return Math.ceil(used / 7);
};
