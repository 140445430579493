import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import PermissionService from '@/application/services/PermissionService';
import SettingService from '@/application/services/SettingService';
import {
    EFunctionPermission,
    IPermissionByFunction,
    IPermissionByScreen,
} from '@/application/types/block-function.types';
import { OrganizationModel } from '@/application/types/organization/organization.type';
import systemConfig from '@/application/constants/system-config.const';
import { StartWorkingConditionModel } from '@/application/models/setting/StartWorkingConditionModel';
import LocationService from '@/application/services/LocationService';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import allFunctions from '@/ui/hooks/permission/all-functions.json';
import principleStore from '@/store/principle';
import {
    extractPlaceInfo,
    getPlaceFromLocation,
} from '@/ui/plugins/google/place';
import commonStore from '@/store/common/commonStore';
import { CurrentUserConfig } from '@/ui/common/constants/constant';
// import RouterInstanceClass from '@/ui/router/RouterInstanceClass';

export default defineStore({
    id: storeId.PERMISSION,
    state: () =>
        ({
            screenPermissionsByCode: {},
            tictopConfigs: {},
        } as {
            screenPermissionsByCode: any;
            screenPermissions: IPermissionByScreen[];
            functionPermissionsByCode: any;
            functionPermissions: IPermissionByFunction[];
            organizationModel: OrganizationModel;
            allSystemConfigs: any;
            tictopConfigs: any;
        }),
    getters: {},
    actions: {
        getPermissionsByUser() {
            return Promise.all([
                this.getAllSystemConfigs(),
                this.getTictopConfigs(),
                this.getFunctionAndScreenPermissions(),
            ]);
        },
        async getFunctionAndScreenPermissions() {
            try {
                const res =
                    await PermissionService.getInstance().getPermissionsByUser();

                this.screenPermissions = res?.result?.pageAndScreens;
                this.screenPermissionsByCode = this.screenPermissions?.reduce(
                    (oldValue, currentValue) => {
                        return {
                            ...oldValue,
                            [currentValue?.pageAndScreenCode?.trim()]:
                                currentValue,
                        };
                    },
                    {}
                );
                this.functionPermissions = res?.result?.functions;
                this.functionPermissionsByCode =
                    this.functionPermissions?.reduce(
                        (oldValue, currentValue) => {
                            return {
                                ...oldValue,
                                [currentValue?.functionCode?.trim()]:
                                    currentValue,
                            };
                        },
                        {}
                    );
            } catch (error) {
                console.log(error);
            }
        },
        async getPermissionsByModel(model: OrganizationModel) {
            try {
                this.organizationModel = model;
            } catch (error) {
                console.log(error);
            }
        },
        async getAllSystemConfigs() {
            try {
                const res =
                    await SettingService.getInstance().getSystemConfig();
                this.allSystemConfigs = res?.result;

                // RouterInstanceClass.getInstance().updateRouterByKey(
                //     'WhiteboardPage',
                //     'REMOVE'
                // );
                // RouterInstanceClass.getInstance().updateRouterByKey(
                //     'WhiteboardDetail',
                //     'REMOVE'
                // );
            } catch (error) {
                console.log(error);
            }
        },
        async getTictopConfigs() {
            try {
                const res =
                    await SettingService.getInstance().getTictopConfig();
                this.tictopConfigs = res?.result || {};
            } catch (error) {
                console.log(error);
            }
        },
        updatePermissionsByCode(permissions) {
            this.functionPermissionsByCode =
                this.functionPermissionsByCode || {};

            permissions?.forEach((permission) => {
                this.functionPermissionsByCode[permission?.functionCode] =
                    permission;
            });
        },
        async setPermissions(data: { key: string; value: string }) {
            await SettingService.getInstance().setSystemConfig(data);
            this.allSystemConfigs[data?.key] = data?.value;
        },

        async checkStartWorkingConditions() {
            try {
                // Nếu ko áp dụng function thì cho phép SW, ko thì check tiếp settings
                const isAllowedFunction = this._checkSWFunctionPermission();
                if (!isAllowedFunction) return true;

                // Check xem có setting riêng cho user ko, nếu ko thì dùng setting chung của org
                const userSettings = commonStore().userConfigByKeys || {};
                const startWorkingCondition: StartWorkingConditionModel =
                    userSettings[
                        CurrentUserConfig.StartWorkingConditionsSpecific
                    ] ||
                    JSON.parse(
                        this.allSystemConfigs[
                            systemConfig.START_WO_START_WORKING_BY_CONDITIONS
                        ] || null
                    ) ||
                    {};

                // Nếu setting chung ko bật, thì cho phép SW
                if (!startWorkingCondition.enabled) return true;

                // Nếu KHÔNG cho phép SW trên web, thì KHÔNG cho phép SW
                if (!startWorkingCondition.allowWeb) throw 'WEB_NOT_ALLOWED';

                // Nếu KHÔNG hợp lệ điều kiện về IP, thì check thêm điều kiện về Position
                const isValidIp = await this._checkSWSettingIp(
                    startWorkingCondition
                );
                if (isValidIp === true) return true;

                // Nếu KHÔNG hợp lệ điều kiện về Position, thì KHÔNG cho phép SW
                const isValidLocation = await this._checkSWSettingLocation(
                    startWorkingCondition
                );
                if (isValidLocation === true) return true;

                // Nếu không cài đặt cả Location và IP thì cho phép SW
                if (isValidLocation === false && isValidIp === false) {
                    return true;
                }

                // Còn lại thì KHÔNG cho phép SW
                throw isValidLocation || isValidIp;
            } catch (e) {
                console.log(e);
                let msg;

                switch (e) {
                    case 'LOCATION_PERMISSION_DENIED':
                        msg = 'PERMISSION_LOCATION_REQUIRED_MSG';
                        break;
                    case 'OUT_OF_RADIUS':
                        msg = 'START_WORKING_POSITION_INVALID_MSG';
                        break;
                    case 'NO_POSITION':
                        msg = 'PERMISSION_LOCATION_UNDEFINED_MSG';
                        break;
                    case 'WEB_NOT_ALLOWED':
                        msg = 'START_WORKING_WEB_NOT_ALLOW_MSG';
                        break;
                    case 'IP_NOT_ALLOWED':
                        msg = 'START_WORKING_IP_INVALID_MSG';
                        break;
                    default:
                        msg = 'COMMON_DOWNLOAD_FILE_ERROR';
                        break;
                }

                setTimeout(async () => {
                    settingGlobalModal({
                        type: 'notification',
                        title: '',
                        content: translate(msg),
                        confirmable: true,
                    });
                    await ask();
                });

                return false;
            }
        },

        async checkReStartWorkingConditions() {
            try {
                // Nếu ko áp dụng function thì cho phép SW, ko thì check tiếp settings
                const isAllowedFunction = this._checkSWFunctionPermission();
                if (!isAllowedFunction) return true;

                // Check xem có setting riêng cho user ko, nếu ko thì dùng setting chung của org
                const userSettings = commonStore().userConfigByKeys || {};
                const startWorkingCondition: StartWorkingConditionModel =
                    userSettings[
                        CurrentUserConfig.StartWorkingConditionsSpecific
                    ] ||
                    JSON.parse(
                        this.allSystemConfigs[
                            systemConfig.START_WO_START_WORKING_BY_CONDITIONS
                        ] || null
                    ) ||
                    {};

                // Nếu setting chung ko bật, thì cho phép SW
                if (!startWorkingCondition.enabled) return true;

                if (!startWorkingCondition.allowRestart) {
                    throw 'RESTART_NOT_ALLOWED';
                }

                return true;
            } catch (e) {
                let msg;

                switch (e) {
                    case 'RESTART_NOT_ALLOWED':
                        msg = 'START_WORKING_RESTART_NOT_ALLOW_MSG';
                        break;
                    default:
                        msg = 'COMMON_DOWNLOAD_FILE_ERROR';
                        break;
                }

                setTimeout(async () => {
                    settingGlobalModal({
                        type: 'notification',
                        title: '',
                        content: translate(msg),
                        confirmable: true,
                    });
                    await ask();
                });

                return false;
            }
        },

        _checkSWFunctionPermission() {
            if (!this.functionPermissionsByCode) return false;

            const functionCode = allFunctions.WK_TIME.START_WD_CONDITIONS;
            const functionInfo = this.functionPermissionsByCode[functionCode];

            return functionInfo?.permission === EFunctionPermission.ALLOW;
        },

        async _checkSWSettingLocation(
            startWorkingCondition: StartWorkingConditionModel
        ) {
            // Nếu setting Position ko bật, thì tiếp tục check IP
            if (!startWorkingCondition.limitPosition?.enabled) return false;

            // Nếu ko có positions nào, thì tiếp tục check IP
            const allowPositions =
                startWorkingCondition.limitPosition.positions;
            if (!allowPositions?.length) return false;

            // Get current location
            const currentCoords: any =
                await LocationService.getInstance().getCurrentLocation();
            if (!currentCoords) return 'NO_POSITION';

            // Store current location
            this._storeStartWorkingLocation(
                currentCoords?.latitude,
                currentCoords?.longitude
            );

            // Nếu ko nằm trong phạm vi của posiions nào, thì KHÔNG cho phép SW
            const validLocation = allowPositions.some((allowPosition) => {
                const locationDistance =
                    LocationService.getInstance().calculateDistance(
                        allowPosition?.latitude,
                        allowPosition?.longitude,
                        currentCoords?.latitude,
                        currentCoords?.longitude
                    );

                return (
                    allowPosition?.radius &&
                    locationDistance <= allowPosition.radius
                );
            });
            if (!validLocation) return 'OUT_OF_RADIUS';

            // Còn lại thì cho phép SW
            return true;
        },

        async _checkSWSettingIp(
            startWorkingCondition: StartWorkingConditionModel
        ) {
            // Nếu setting IP ko bật, thì cho phép SW
            if (!startWorkingCondition.limitIp?.enabled) return false;

            // Nếu ko có IP nào, thì cho phép SW
            const allowIps = startWorkingCondition.limitIp.ips;
            if (!allowIps?.length) return false;

            // Get current IP
            const currentIp: any =
                await LocationService.getInstance().getCurrentIp();
            if (!currentIp) return 'NO_IP';

            // Nếu ko nằm trong các IP cho phép, thì KHÔNG cho phép SW
            if (!allowIps?.includes(currentIp)) return 'IP_NOT_ALLOWED';

            // Còn lại thì cho phép SW
            return true;
        },

        _storeStartWorkingLocation(latitude: number, longitude: number) {
            getPlaceFromLocation(latitude, longitude)
                .then((res) => {
                    const location = {
                        latitude,
                        longitude,
                        country: extractPlaceInfo(res, 'country'),
                        region:
                            extractPlaceInfo(res, 'state') ||
                            extractPlaceInfo(res, 'city'),
                        zipcode: extractPlaceInfo(res, 'zip_code'),
                        address: extractPlaceInfo(res, 'address'),
                    };

                    principleStore().setStartWorkingLocation(location);
                })
                .catch((e) => console.log('setStartWorkingLocation ERROR', e));
        },
    },
});
