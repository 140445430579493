<script setup lang="ts">
import { EPlanId, getUiByPlanState } from '@/application/constants/plan.const';
import { computed } from 'vue';
import planStore from '@/store/plans';

const props = defineProps<{
  currentPlanId: EPlanId | string;
  organizationPlan?: any;
  currentPlanActive?: boolean;
  voucherDetail?: any;
  paymentConfigs?: any;
  isOverVoucherLimit?: boolean;
  isLoading?: boolean;
  hasUseTrial?: boolean;
}>();

defineEmits<{
  (e: 'handleToUseBasic'): void;
  (e: 'handlePayImmediately'): void;
  (e: 'onGotoWebContact'): void;
  (e: 'onCreatePaymentToken'): void;
  (e: 'onUpgrade'): void;
  (e: 'onDowngrade'): void;
  (e: 'onChosePlan'): void;
  (e: 'useTrial'): void;
}>();

const _planStore = planStore();
const allPlanByIds = computed<any>(() => _planStore.allPlanByIds);

const uiByPlanState = computed(() => {
  return getUiByPlanState(
    props.organizationPlan?.planId,
    props.organizationPlan?.stateCode
  );
});
const uiByPlanSubState = computed(() => {
  const subState = props.organizationPlan.subStateCode || 'DEFAULT';
  if (
    !uiByPlanState.value ||
    !Object.prototype.hasOwnProperty.call(uiByPlanState.value, subState)
  )
    return null;

  return uiByPlanState.value[subState];
});

const actionByState = computed(() => {
  return uiByPlanState.value?.action;
});

const bonusDays = computed<number>(() => {
  return props.organizationPlan?.getBonusDays();
});
</script>
<template>
  <template v-if="bonusDays > 0">
    <div class="w-full flex flex-col space-y-2">
      <button
        v-if="currentPlanId == EPlanId.FREE || currentPlanId == EPlanId.BASIC"
        class="h-11 w-full flex-center space-x-2 py-2 rounded-xl bg-current"
        :class="{
          'bg-current': currentPlanActive == true,
          'bg-current-200 cursor-not-allowed': currentPlanActive == false,
        }"
        @click="$emit('handleToUseBasic')"
      >
        <div class="flex-center space-x-2">
          <span
            class="text-white font-normal uppercase"
            :style="{
              fontSize: '.875rem',
            }"
          >
            {{ $t('PAYMENT_LABEL_I_WANT_TO_USE_THE_FREE_VERSION') }}
          </span>
        </div>
      </button>
      <button
        v-else
        :disabled="currentPlanActive == false"
        class="h-11 w-full flex-center space-x-2 py-2 rounded-xl bg-current"
        :class="{
          'bg-current': currentPlanActive == true,
          'bg-current-200 cursor-not-allowed': currentPlanActive == false,
        }"
        @click="$emit('handlePayImmediately')"
      >
        <div class="flex-center space-x-2">
          <SynIcon name="crown" class="fill-white" />
          <span
            class="text-white font-normal uppercase"
            :style="{
              fontSize: '.875rem',
            }"
          >
            {{
              $t(
                voucherDetail?.excludedBonusDay
                  ? isOverVoucherLimit
                    ? 'DECIDE_PAYMENT_PAY_BUTTON'
                    : 'DECIDE_PAYMENT_PAY_WITH_VOUCHER_BUTTON'
                  : 'DECIDE_PAYMENT_PAY_BUTTON',

                {
                  bonusDays,
                }
              )
            }}
          </span>
        </div>
      </button>

      <div
        v-if="currentPlanId == EPlanId.PRO && hasUseTrial"
        class="
          w-full
          text-center text-sm
          absolute
          left-0
          bottom-2
          hover:underline
          cursor-pointer
        "
        @click="$emit('useTrial')"
        v-html="
          $t('REGISTER_LABEL_OR_ENJOY_X_DAY_FREE', {
            totalDays: paymentConfigs?.paymentBonus?.defaultTrial,
          })
        "
      ></div>
    </div>
  </template>
  <template v-else-if="organizationPlan?.planId">
    <div class="w-full flex-center flex-col">
      <SynButton
        v-if="uiByPlanSubState.action === 'CONTACT'"
        :label="$t('SETTING_PLAN_PAYMENT_CONTACT_SUPPORT_TITLE')"
        custom-class="py-3 px-2 w-full rounded-xl"
        custom-label-class="font-normal"
        @click="$emit('onGotoWebContact')"
      />
      <SynButton
        v-else-if="
          currentPlanId == EPlanId.FREE || currentPlanId == EPlanId.BASIC
        "
        :label="$t('PAYMENT_LABEL_I_WANT_TO_USE_THE_FREE_VERSION')"
        :is-loading="isLoading"
        custom-class="py-3 px-2 w-full rounded-xl font-normal"
        custom-label-class="font-normal"
        @click="$emit('handleToUseBasic')"
      />
      <SynButton
        v-else-if="organizationPlan?.planId == currentPlanId"
        :label="
          $t(actionByState?.actionOnCurrentPlanLabelCode, {
            planId: currentPlanId,
          })
        "
        :is-loading="isLoading"
        :disabled="organizationPlan?.isLifeTime"
        custom-class="py-3 px-2 w-full rounded-xl"
        custom-label-class="font-normal"
        @click="$emit('onCreatePaymentToken')"
      />
      <SynButton
        v-else-if="
          allPlanByIds[organizationPlan?.planId]?.level <
          allPlanByIds[currentPlanId]?.level
        "
        :label="
          $t(actionByState?.actionOnHigherPlanLabelCode, {
            planId: currentPlanId,
          })
        "
        custom-class="py-3 px-2 w-full rounded-xl"
        custom-label-class="font-normal"
        @click="$emit('onUpgrade')"
      />
      <SynButton
        v-else-if="
          allPlanByIds[organizationPlan?.planId]?.level >
          allPlanByIds[currentPlanId]?.level
        "
        :label="
          $t(actionByState?.actionOnLowerPlanLabelCode, {
            planId: currentPlanId,
          })
        "
        custom-class="py-3 px-2 w-full rounded-xl"
        custom-label-class="font-normal"
        @click="$emit('onDowngrade')"
      />
      <SynButton
        v-else
        :label="$t('SETTING_PLAN_MANAGE_PLAN_UPGRADE_BTN_LABEL')"
        custom-class="py-3 px-2 w-full rounded-xl"
        custom-label-class="font-normal"
        disabled
      />
    </div>
  </template>
  <SynButton
    v-else
    :label="$t('PLAN_BUTTON_GET_STARTED')"
    type-fill
    color="current"
    custom-class="py-3 px-2 w-full"
    custom-label-class="font-normal"
    @click="$emit('onChosePlan')"
  />
</template>
