import { ICommonService } from '@/domain/interfaces/services/ICommonService';
import CommonRepository from '@/application/repositories/CommonRepository';

export default class CommonService implements ICommonService {
    private static instance: CommonService;
    _commonRepository: CommonRepository;

    constructor() {
        this._commonRepository = CommonRepository.getInstance();
    }

    public static getInstance(): CommonService {
        if (!CommonService.instance) {
            // Get from local storage
            CommonService.instance = new CommonService();
        }

        return CommonService.instance;
    }

    getCurrentUserSetting() {
        return this._commonRepository.getCurrentUserSetting();
    }

    getOrgCurrentUserSetting(orgId) {
        return this._commonRepository.getOrgCurrentUserSetting(orgId);
    }

    getUserSettingByKey(userId: number, key: string) {
        return this._commonRepository.getUserSettingByKey(userId, key);
    }

    updateCurrentUserSetting(data: any) {
        return this._commonRepository.updateCurrentUserSetting(data);
    }

    updateOrgCurrentUserSetting(orgId, data: any) {
        return this._commonRepository.updateOrgCurrentUserSetting(orgId, data);
    }

    getSystemLanguages() {
        return this._commonRepository.getSystemLanguages();
    }

    getPreviewLinkInfo(link: string) {
        return this._commonRepository.getPreviewLinkInfo(link);
    }

    getMyStatistics() {
        return this._commonRepository.getMyStatistics();
    }

    setCurrentUserSetting(data) {
        return this._commonRepository.setCurrentUserSetting(data);
    }

    setMultiUsersSetting(data) {
        return this._commonRepository.setMultiUsersSetting(data);
    }
}
