import BaseRequestEmbed from '@/base/api/base-request-embed';

export default class EmbedUserApi extends BaseRequestEmbed {
    private static instance: EmbedUserApi;
    constructor() {
        super();
    }

    public static getInstance(): EmbedUserApi {
        if (!EmbedUserApi.instance) {
            // Get from local storage
            EmbedUserApi.instance = new EmbedUserApi();
        }

        return EmbedUserApi.instance;
    }

    getShortInfos() {
        return this.request({
            url: `User/ShortInfos`,
            method: 'get',
        });
    }

    getDeactivatedUsers() {
        return this.request({
            url: `User/GetDeactivatedUsers`,
            method: 'get',
        });
    }

    getUserInfo(userId) {
        return this.request({
            url: `User/GetUserInfo?userId=${userId}`,
            method: 'get',
        });
    }

    getUserIdentity() {
        return this.request({
            url: `Catalog/GetUserProfile`,
            method: 'get',
        });
    }

    searchUsers(searchString, status, pageIndex, pageSize) {
        if (searchString !== '') {
            return this.request({
                url: `User/SearchUsers?SearchString=${searchString}&Status=${status}&PageIndex=${pageIndex}&PageSize=${pageSize}`,
                method: 'get',
            });
        } else {
            return this.request({
                url: `User/SearchUsers?Status=${status}&PageIndex=${pageIndex}&PageSize=${pageSize}`,
                method: 'get',
            });
        }
    }

    getDomainScopeList(userId) {
        return this.request({
            url: `Domain/GetDomainScopeList?userId=${userId}`,
            method: 'get',
        });
    }

    uploadAvatarAws(file) {
        const formData = new FormData();
        formData.append('fileData', file);
        return this.request({
            url: `Media/UploadAvatarAws`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    saveProfileUser(user) {
        return this.request({
            url: `User/SaveProfileUser`,
            method: 'post',
            data: user,
        });
    }

    deleteUser(userId, status) {
        return this.request({
            url: `User/DeleteUser?userId=${userId}&status=${status}`,
            method: 'post',
        });
    }

    saveUserScopesDto(data) {
        return this.request({
            url: `Domain/SaveUserScopesDto`,
            method: 'post',
            data: data,
        });
    }

    getAllPosition(lang = '') {
        return this.request({
            url: `User/GetAllPosition?lang=${lang}`,
            method: 'get',
        });
    }

    updatePositionValue(payload) {
        return this.request({
            url: `User/UpdatePositionValue`,
            method: 'post',
            data: payload,
        });
    }

    getAllProfile(lang = '') {
        return this.request({
            url: `User/GetAllProfile?lang=${lang}`,
            method: 'get',
        });
    }

    changePassword(payload) {
        return this.request({
            url: `Catalog/UpdatePassword`,
            method: 'post',
            data: payload,
        });
    }

    updateStatusUser(userId, status) {
        return this.request({
            url: `User/UpdateStatusUser?userId=${userId}&status=${status}`,
            method: 'post',
        });
    }

    setPassword(pass) {
        return this.request({
            url: `User/SetPassword`,
            method: 'post',
            data: JSON.stringify(pass),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    sendVerificationUserEmail() {
        return this.request({
            url: `User/SendVerificationUserEmail`,
            method: 'post',
        });
    }

    changeUserPhone(data) {
        return this.request({
            url: `User/ChangeUserPhone`,
            method: 'post',
            data: data,
        });
    }

    changeUserEmail(data) {
        return this.request({
            url: `User/ChangeUserEmail`,
            method: 'post',
            data: data,
        });
    }

    checkHasSendVerificationUserEmail() {
        return this.request({
            url: `User/CheckHasSendVerificationUserEmail`,
            method: 'post',
        });
    }

    getDomainScopeListByUserId(userId) {
        return this.request({
            url: `Domain/GetDomainScopeListByUserId?userId=${userId}`,
            method: 'get',
        });
    }
}
