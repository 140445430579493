<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 230.639 230.639"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M184.777 125.36a7.5 7.5 0 0 0-15 0c0 30.028-24.43 54.458-54.458 54.458s-54.458-24.43-54.458-54.458a7.5 7.5 0 0 0-15 0c0 35.765 27.174 65.294 61.958 69.047v21.232H89.35a7.5 7.5 0 0 0 0 15h51.939a7.5 7.5 0 0 0 0-15H122.82v-21.232c34.783-3.753 61.957-33.282 61.957-69.047z"
        style=""
        data-original="#1d1d1b"
        class=""
      ></path>
      <path
        d="M115.32 171.112c23.408 0 42.451-19.043 42.451-42.451v-86.21C157.771 19.043 138.728 0 115.32 0S72.869 19.043 72.869 42.451v86.21c0 23.408 19.043 42.451 42.451 42.451zM87.869 42.451C87.869 27.314 100.183 15 115.32 15s27.451 12.314 27.451 27.451v86.21c0 15.137-12.314 27.451-27.451 27.451s-27.451-12.314-27.451-27.451v-86.21z"
        style=""
        data-original="#1d1d1b"
        class=""
      ></path>
    </g>
  </svg>
</template>
