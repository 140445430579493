<script setup lang="ts">
import { computed, onMounted } from 'vue';
import whiteboardStore from '@/store/whiteboard';
import { useRoute } from 'vue-router';
import userStore from '@/store/user';
import departmentStore from '@/store/department';
import groupStore from '@/store/group';
import taskStore from '@/store/task';
import myProfileStore from '@/store/auth/my-profile';

// const whiteboardLink = computed<string>(() => {
//   const currentWhiteboardId = whiteboardStore().currentWhiteboardId;
//   if (currentWhiteboardId) return `/whiteboards/detail/${currentWhiteboardId}`;
//   return `/whiteboards`;
// });
const route = useRoute();
const _userStore = userStore();
const _departmentStore = departmentStore();
const _groupStore = groupStore();
const _taskStore = taskStore();

const isFullPage = computed<boolean>(() => {
  return route.name?.toString()?.startsWith('Fp');
});

const initComponent = async () => {
  if (isFullPage.value) {
    const promise = [
      _userStore.getShortInfosUser(),
      _departmentStore.getAll(),
      _userStore.getDeactivatedUsers(),
      _groupStore.fetchMyGroups(),
      _groupStore.getAllDeletedGroup(),
      _groupStore.getGroupChatByUser(),
      _taskStore.getCurrentTaskCycle(),
      // _groupStore.updateNotificationInListGroup(),
      // _appStore.getCurrentServerTime(),
      _taskStore.getDataConfig(),
      // _planStore.initData(),
      // _organizationStore.checkUserInstallApp(),
      // _settingStore.getAllTimezoneSettings(),
      // _taskCustomConfigStore.getUserCustomColumnTask(),
      // _statisticStore.getMyStatistics(),
      // _userStore.getUserIdentity(),
      // _taskStore.getCurrentTaskCycleForChat(),
      // _workflowStore.getAllStepType(),
      // _workflowStore.getWorkflowsStepsShortInfo(),
      // _commonStore.getCurrentUserSetting(true),
      myProfileStore().getUserTaskSchedule(),
      myProfileStore().getMyDomains(),
    ];
    await Promise.all(promise);
  }
};
onMounted(() => {
  whiteboardStore().getAllWhiteboardByUser();
});

initComponent();
</script>
<template>
  <div v-if="isFullPage" class="w-screen h-screen">
    <router-view></router-view>
  </div>
  <router-view v-else></router-view>
</template>
