import { getDatabase, ref, onValue, set, update } from 'firebase/database';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

export const onListenEventFirebase = (
    path, // `user-notifications/${companyId}/${userId}/ntfDate`
    onListen
) => {
    const db = getDatabase();
    const notificationRef = ref(db, path);

    return onValue(notificationRef, onListen);
};

export const setDataFirebase = (
    path, // `user-notifications/${companyId}/${userId}/ntfDate`
    value // 'value'
) => {
    const db = getDatabase();
    set(ref(db, path), value);
};
export const updateDataFirebase = (path, updateObj) => {
    const db = getDatabase();

    update(ref(db, path), updateObj);
};
export const updateRD = (updateObj) => {
    const db = getDatabase();

    update(ref(db), updateObj);
};

export const updateMultiplePaths = (updates) => {
    const db = getDatabase();

    update(ref(db), updates);
};

export const getUserPathFirebase = (userId, key) => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    if (!organizationId || !userId) return '';

    const companyId = `organization_${organizationId}`;
    const userPath = `user_${userId}`;
    return `users_dynamic_info/${companyId}/${userPath}/${key}`;
};
export const getGroupPathFirebase = (groupId, key) => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    if (!organizationId || !groupId) return '';

    const companyId = `organization_${organizationId}`;
    const groupPath = `group_${groupId}`;
    return `groups_dynamic_info/${companyId}/${groupPath}/${key}`;
};
export const getOrganizationPathFirebase = (key) => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    if (!organizationId) return '';

    const companyId = `organization_${organizationId}`;
    return `organizations_dynamic_info/${companyId}/${key}`;
};

export const getOrganizationPathSystemInfo = (key) => {
    return `systems_info/${key}`;
};

export const refByPath = (path) => {
    const db = getDatabase();
    return ref(db, path);
};
