<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import StepTemplateEntity, {
  IStepTemplateEntity,
} from '@/domain/entities/workflow/StepTemplateEntity';
import departmentStore from '@/store/department';
// import groupStore from '@/store/group';
import SelectDependent from '@/ui/modules/workflow/SelectDependent.vue';
// import SelectMultipleUsers from '@/ui/components/user/SelectMultipleUsers.vue';
// import userStore from '@/store/user';
// import workflowStore from '@/store/workflow';
// import {
//   EAssigneeStatus,
//   IAssignee,
// } from '@/application/types/workflow/workflow.types';
// import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
// import GroupById from '@/ui/components/group/GroupById.vue';
// import UserById from '@/ui/components/user/UserById.vue';
// import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
// import DeadlineInput from '@/ui/modules/task/components/deadline/DeadlineInput.vue';
// import { ScheduleDayOptions } from '@/domain/enums/taskEnum';

const props = withDefaults(
  defineProps<{
    viewMode: 'VIEW' | 'EDIT';
    stepDetail: IStepTemplateEntity;
    readonly?: boolean;
  }>(),
  {
    viewMode: 'VIEW',
  }
);

const emit = defineEmits([
  'update:stepDetail',
  'onChangeStatus',
  'update:title',
  'change',
  'remove',
  'enter',
]);
const _departmentStore = departmentStore();
// const _groupStore = groupStore();
// const _workflowStore = workflowStore();

// const allStepTypeByIds = computed<any>(() => {
//   return _workflowStore.allStepTypeByIds;
// });
const allDepartment = computed(() => _departmentStore.allDepartments);
const allDepartmentByIds = computed(() => _departmentStore.allDepartmentByIds);
// const domains = computed(() => _groupStore.domains);
// const allActiveUsers = computed(() => userStore().allActiveUsers);

const workflowStepData = ref<StepTemplateEntity>(
  new StepTemplateEntity(props.stepDetail || {})
);

// const assigneeInfo = computed<IAssignee | null>(() => {
//   return workflowStepData.value?.assignees?.length > 0
//     ? workflowStepData.value?.assignees[0]
//     : null;
// });

// const assigneeList = computed<any[]>(() => {
//   return workflowStepData.value?.assignees
//     ?.filter(
//       (o) =>
//         o.assigneeId &&
//         Object.prototype.hasOwnProperty.call(
//           userStore().allUserByIds,
//           o?.assigneeId
//         )
//     )
//     ?.map((o: any) => {
//       return {
//         ...o,
//         ...userStore().allUserByIds[o?.assigneeId],
//         id: o?.assigneeId,
//       };
//     });
// });

watch(
  () => props.stepDetail?.id,
  () => {
    if (workflowStepData.value?.id !== props.stepDetail?.id)
      workflowStepData.value = new StepTemplateEntity(props.stepDetail || {});
  }
);
const updatePropData = () => {
  emit(
    'update:stepDetail',
    new StepTemplateEntity(workflowStepData.value || {})
  );
};

const onChangeSelectedInfo = (type, data) => {
  switch (type) {
    case 'department':
      workflowStepData.value.departmentId = data?.id;

      break;
    case 'domain':
      workflowStepData.value.domainId = data?.domainId;

      break;
    case 'group':
      workflowStepData.value.groupId = data?.id;

      break;

    default:
      break;
  }

  updatePropData();
};

const removeSelectedInfo = (type) => {
  switch (type) {
    case 'department':
      workflowStepData.value.departmentId = undefined;

      break;
    case 'domain':
      workflowStepData.value.domainId = undefined;

      break;
    case 'group':
      workflowStepData.value.groupId = undefined;

      break;
    default:
      break;
  }
  updatePropData();
};

// const onUpdateType = (newType) => {
//   workflowStepData.value.typeId = newType?.id;
// };
</script>
<template>
  <div class="w-full h-full flex flex-col space-y-4">
    <div class="flex-1 flex flex-col space-y-4">
      <template v-if="viewMode == 'EDIT'">
        <!-- Name -->
        <section class="flex w-full items-start justify-center px-4">
          <!-- <SynFormInput
              :title="$t('WORKFLOW_LABEl_STEP_NAME')"
              :error-message="
                workflowStepData.name?.trim() === ''
                  ? 'TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED'
                  : ''
              "
            >
              
            </SynFormInput> -->
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_LABEL_NAME') || 'Name' }}
          </span>
          <div class="flex-1">
            <SynInput
              v-model="workflowStepData.name"
              autofocus
              :placeholder="$t('WORKFLOW_STEP_LABEL_NAME_PLACEHOLDER')"
              :max-length="80"
              @update:model-value="updatePropData"
            />
          </div>
        </section>

        <!-- description -->
        <!-- <section class="w-full px-4 flex items-start">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_LABEL_DESCRIPTION') || 'Description' }}
          </span>

          <div class="flex-1">
            <SynInputArea
              v-model="workflowStepData.description"
              class="w-full"
              style-name="max-height: 500px;"
              :max-length="400"
              :placeholder="
                $t('WORKFLOW_LABEl_DESCRIPTION_PLACEHOLDER') || 'Description'
              "
              @update:model-value="updatePropData"
            />
          </div>
        </section> -->

        <!-- STEP TYPE -->
        <!-- <section class="w-full px-4 flex items-start">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_LABEL_TYPE') || 'Type' }}
          </span>

          <div class="flex-1">
            <template v-if="Object.keys(allStepTypeByIds)?.length > 5">
              <div class="w-max">
                <VigSelect
                  :placeholder="$t('WORKFLOW_LABEL_CHOOSE_WORKFLOW_STEP_TYPE')"
                  key-search="name"
                  key-label="name"
                  class="mr-0.5 mb-0.5"
                  :origin-options="Object.values(allStepTypeByIds)"
                  :label-value="
                    workflowStepData?.typeId
                      ? allStepTypeByIds[workflowStepData.typeId]?.name
                      : ''
                  "
                  @on-choose="onUpdateType"
                />
              </div>
            </template>
            <template v-else>
              <SynRadioTag
                v-for="typeId in Object.keys(allStepTypeByIds)"
                :key="typeId"
                v-model="workflowStepData.typeId"
                :value="parseInt(typeId)"
                radio-name="task-create-form_priority"
                style="margin: 0.9px"
                @change="updatePropData"
              >
                <SynTag
                  size="circle-small"
                  custom-class="px-2 py-1 border border-current-100"
                >
                  <span>{{ allStepTypeByIds[typeId]?.name }}</span>
                </SynTag>
              </SynRadioTag>
            </template>
          </div>
        </section> -->

        <!-- department -->
        <section v-if="false" class="w-full px-4 flex items-start">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_MODULE_MANAGEMENT_DEPARTMENT') || 'Type' }}
          </span>

          <div class="flex-1">
            <SelectDependent
              :avatar="
                workflowStepData?.departmentId
                  ? allDepartmentByIds[workflowStepData?.departmentId].avatarUrl
                  : ''
              "
              :name="
                workflowStepData?.departmentId
                  ? allDepartmentByIds[workflowStepData?.departmentId].name
                  : ''
              "
              :data="allDepartment"
              removable
              name-field="name"
              avatar-field="avatarUrl"
              has-avatar
              placeholder="Chọn phòng ban"
              type="department"
              @chosen="(value) => onChangeSelectedInfo('department', value)"
              @reset="removeSelectedInfo('department')"
            />
          </div>
        </section>
      </template>
      <template v-if="viewMode == 'VIEW'">
        <!-- Name -->

        <!-- <section class="flex w-full items-start px-4">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_LABEL_NAME') || 'Name' }}
          </span>
          <span class="flex-1 min-w-0 truncate w-max text-xl">
            {{ workflowStepData?.name }}
          </span>
        </section> -->

        <!-- description -->
        <!-- <section class="w-full px-4 flex items-start">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_LABEL_DESCRIPTION') || 'Description' }}
          </span>

          <span
            class="flex-1 min-w-0 truncate"
            style="overflow-wrap: break-word; white-space: pre-wrap"
          >
            {{ workflowStepData.description }}
          </span>
        </section> -->

        <!-- STEP TYPE -->
        <!-- <section class="w-full px-4 flex items-start">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_LABEL_TYPE') || 'Type' }}
          </span>

          <div class="flex-1">
            <div class="w-max">
              <SynTag
                v-if="workflowStepData.typeId"
                size="circle-small"
                custom-class="px-2 py-1 border border-current-100"
              >
                <span>
                  {{ allStepTypeByIds[workflowStepData.typeId]?.name }}
                </span>
              </SynTag>
            </div>
          </div>
        </section> -->

        <!-- department -->
        <section v-if="false" class="w-full px-4 flex items-start">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('COMMON_MODULE_MANAGEMENT_DEPARTMENT') || 'Type' }}
          </span>

          <div class="flex-1 flex items-center h-8">
            <div
              v-if="workflowStepData?.departmentId"
              class="relative flex items-center py-1 h-10 rounded"
            >
              <div class="w-6 h-6 absolute left-2">
                <div class="relative flex items-center">
                  <SynAvatar
                    :src="
                      workflowStepData?.departmentId
                        ? allDepartmentByIds[
                            workflowStepData?.departmentId
                          ].avatarUrl?.replace('original', 'mini')
                        : ''
                    "
                    :name="
                      workflowStepData?.departmentId
                        ? allDepartmentByIds[workflowStepData?.departmentId]
                            .name
                        : ''
                    "
                    :type="'department'"
                    custom-class="w-6 h-6"
                  />
                </div>
              </div>

              <div
                :title="
                  workflowStepData?.departmentId
                    ? allDepartmentByIds[workflowStepData?.departmentId].name
                    : ''
                "
                class="
                  text-sm text-gray-500
                  h-full
                  w-full
                  focus:bg-white focus:outline-none focus:border-transparent
                  flex
                  items-center
                  pl-10
                  pr-8
                  text-overflow-hidden-line
                "
              >
                <span
                  v-if="workflowStepData?.departmentId"
                  class="truncate text-overflow-hidden-line"
                >
                  {{
                    allDepartmentByIds[workflowStepData?.departmentId].name ||
                    ''
                  }}
                </span>
              </div>
            </div>
          </div>
        </section>

        <!-- Total used -->
        <!-- <section class="w-full px-4 flex items-start">
          <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('WORKFLOW_LABEl_TOTAL_WORKFLOW_IN_USE') || 'Type' }}
          </span>

          <div
            v-if="
              workflowStepData?.usedWorkFlows &&
              workflowStepData?.usedWorkFlows?.length > 0
            "
            class="flex items-center h-8"
          >
            <span
              v-for="(workflow, index) in workflowStepData?.usedWorkFlows"
              :key="workflow?.id"
              class="text-xs text-current-600"
              :title="workflow?.name"
            >
              {{ workflow?.code }}

              <template
                v-if="index < workflowStepData?.usedWorkFlows?.length - 1"
              >
                ,
              </template>
            </span>
          </div>
          <span v-else>
            {{ workflowStepData?.totalWorkflows || 0 }}
          </span>
        </section> -->
      </template>
    </div>
  </div>
</template>
