<script setup lang="ts">
import { computed, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

import WorkflowInformation from '@/ui/modules/workflow/WorkflowInformation.vue';
import UpdateWorkflowSteps from '@/ui/modules/workflow/workflow-detail/UpdateWorkflowSteps.vue';
import useStepper from '@/ui/composables/app/stepper-composables';
import WorkflowEntity, {
  IWorkflowEntity,
} from '@/domain/entities/workflow/WorkflowEntity';
import workflowStore from '@/store/workflow';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
// import { IWorkflowStep } from '@/domain/entities/workflow/WorkflowStepEntity';
// import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const props = withDefaults(
  defineProps<{
    defaultData?: IWorkflowEntity;
    viewMode?: 'ADD' | 'CLONE';
  }>(),
  {
    viewMode: 'ADD',
  }
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'createSuccess', data: any): void;
}>();
const onCloseModal = async () => {
  if (workflowInfo.value?.steps?.length > 0) {
    settingGlobalModal({
      type: 'confirm',
      title: translate('DECIDE_PAYMENT_HAS_VOUCHER_CONFIRM_SELECT_TRIAL_TITLE'),
      content: translate('WORKFLOW_LABEL_CREATE_COMFIRM_TO_CLOSE'),
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_GOT_IT') || 'Confirm',
      closeable: true,
    });

    const confirmed = await ask();

    if (confirmed) emit('cancel');
  } else emit('cancel');
};

const isLoading = ref<boolean>(true);
const _workflowStore = workflowStore();
const initComponent = async () => {
  if (props.viewMode == 'ADD' || !props.defaultData?.id) {
    isLoading.value = false;
    return;
  }
  isLoading.value = true;
  const workflowDetail = await _workflowStore.getWorkflowById(
    props.defaultData?.id
  );

  workflowInfo.value = new WorkflowEntity({
    ...props.defaultData,
    ...workflowDetail,
    name: props.defaultData?.name, // case duplicate: name has suffix
  });

  // workflowSteps.value = workflowInfo.value?.steps;

  if (props.viewMode == 'CLONE') {
    workflowInfo.value.id = '';
    const newIdByOldIds = {};
    workflowInfo.value.steps = workflowDetail?.steps?.map((step) => {
      const newStepId = uuidv4();
      newIdByOldIds[step?.id] = newStepId;

      workflowInfo.value.drawData = workflowInfo.value.drawData?.replaceAll(
        step?.id,
        newStepId
      );
      return {
        ...step,
        id: newStepId,
      };
    });

    workflowInfo.value.steps?.forEach((step) => {
      step.nextSteps?.forEach((nextStep) => {
        nextStep.id =
          nextStep?.id &&
          Object.prototype.hasOwnProperty.call(newIdByOldIds, nextStep?.id)
            ? newIdByOldIds[nextStep?.id]
            : '';
      });
    });
  }
  isLoading.value = false;
};

initComponent();

const errorMessageCode = ref<string>('');

const isLoadFinishCreate = ref<boolean>(false);

const CREATE_WORKFLOW_STEPS = {
  INPUT_FORM: 'INPUT_FORM',
  INPUT_STEP: 'INPUT_STEP',
};
const {
  currentStep,
  stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = useStepper(
  {
    [CREATE_WORKFLOW_STEPS.INPUT_FORM]: {
      key: CREATE_WORKFLOW_STEPS.INPUT_FORM,
      next: CREATE_WORKFLOW_STEPS.INPUT_STEP,
      label: 'COMMON_LABEL_INFORMATION',
      previous: '',
      isActive: true,
      isFinished: false,
      previousText: 'COMMON_LABEL_CANCEL',
      confirmText: 'COMMON_LABEL_NEXT',
      nextAction: () => {},
      previousAction: () => {
        onCloseModal();
      },
    },
    [CREATE_WORKFLOW_STEPS.INPUT_STEP]: {
      key: CREATE_WORKFLOW_STEPS.INPUT_STEP,
      previous: CREATE_WORKFLOW_STEPS.INPUT_FORM,
      next: '',
      label: 'WORKFLOW_LABEL_STEP_SETTING',
      isActive: false,
      isFinished: false,
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'WORKFLOW_LABEL_CREATE_NEW',
      nextAction: () => {
        onSave();
      },
      previousAction: async () => {
        const xmlDiagram = await updateWorkflowStepsRef.value?.exportDiagram();
        console.log('🚀 Tictop ~ xmlDiagram:', xmlDiagram);
        workflowInfo.value.drawData = xmlDiagram;
      },
    },
  },
  CREATE_WORKFLOW_STEPS.INPUT_FORM
);

const hasNotConnectionStepIds = computed<string[]>(() => {
  const nextStepIds: string[] = workflowInfo.value?.steps?.reduce<string[]>(
    (currentArray, currentItem) => {
      return [
        ...currentArray,
        ...(currentItem.nextSteps?.map((s) => s?.id) || []),
      ];
    },
    []
  );

  return workflowInfo.value?.steps
    ?.filter((s) => !s?.isStart)
    ?.map((s) => s?.id)
    ?.filter((stepId) => !nextStepIds?.includes(stepId));
});

const isDisabledSummit = computed<boolean>(() => {
  return (
    (currentStep.value == CREATE_WORKFLOW_STEPS.INPUT_FORM &&
      !workflowInfo.value?.name) ||
    (currentStep.value == CREATE_WORKFLOW_STEPS.INPUT_STEP &&
      (!workflowInfo.value?.steps ||
        workflowInfo.value?.steps?.length == 0 ||
        workflowInfo.value?.steps?.some((s) => !s?.name) ||
        hasNotConnectionStepIds.value?.length > 0))
  );
});

const workflowInfo = ref<WorkflowEntity>(
  new WorkflowEntity({
    ...(props.defaultData || {}),
    id: '',
  })
);

// const workflowSteps = ref<IWorkflowStep[]>(
//   props.defaultData && props.defaultData?.steps?.length > 0
//     ? (arrayOrderBy(
//         [...props.defaultData?.steps],
//         ['stepOrder'],
//         ['asc']
//       ) as IWorkflowStep[]) || []
//     : []
// );

const updateWorkflowStepsRef = ref<any>(null);
const onSave = async () => {
  try {
    isLoadFinishCreate.value = true;

    const result = await new WorkflowEntity({
      ...workflowInfo.value,
    }).create(props.viewMode == 'CLONE');

    if (!result) return;

    emit('createSuccess', result);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isLoadFinishCreate.value = false;
  }
};

const onClickNext = async () => {
  console.log('🚀 Tictop ~ onClickNext:');
  if (currentStep.value == CREATE_WORKFLOW_STEPS.INPUT_FORM) {
    const isValid = await new WorkflowEntity(workflowInfo.value).checkName();
    if (!isValid) return;
  }

  onHandleGoToNextStep();
};
</script>

<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    :container-class="[
      currentStep == CREATE_WORKFLOW_STEPS.INPUT_FORM
        ? 'md:w-2/5'
        : 'w-full h-full',
    ]"
    container-style="min-height: 40rem,max-height: 95%; max-width: 95%;min-width: 48rem"
    disable-click-outside
    @cancel="onCloseModal"
  >
    <template #header>
      <div class="w-full flex flex-col items-center">
        <div class="w-full flex gap-1 items-baseline">
          <span class="text-base min-w-max">
            {{ $t('WORKFLOW_LABEl_ADD_NEW') }}
          </span>
          <span
            v-if="
              workflowInfo?.name &&
              currentStep == CREATE_WORKFLOW_STEPS.INPUT_STEP
            "
            class="text-current"
          >
            {{ workflowInfo?.name }}
          </span>
        </div>
        <div class="pt-2">
          <SynStepper
            key-label="label"
            class=""
            :steps="Object.values(stepperByKey)"
          />
        </div>
      </div>
    </template>
    <template v-if="!isLoading" #body>
      <template v-if="currentStep == CREATE_WORKFLOW_STEPS.INPUT_FORM">
        <div class="px-8">
          <WorkflowInformation
            v-model:workflow-info="workflowInfo"
            :view-mode="'EDIT'"
          />
        </div>
      </template>
      <template v-if="currentStep == CREATE_WORKFLOW_STEPS.INPUT_STEP">
        <div class="px-4 w-full h-full overflow-auto">
          <UpdateWorkflowSteps
            ref="updateWorkflowStepsRef"
            v-model:workflow-steps="workflowInfo.steps"
            v-model:draw-data="workflowInfo.drawData"
            :workflow-info="workflowInfo"
            is-update-right-away
            view-mode="EDIT"
          />
        </div>
      </template>
    </template>
    <template #footer>
      <div class="w-full flex items-center justify-between p-4">
        <div class="flex-center space-x-2">
          <div v-if="errorMessageCode" class="flex-center space-x-2">
            <SynIcon name="info" class="fill-red-500" />
            <span class="text-red-500 text-sm">
              {{ $t(errorMessageCode) }}
            </span>
          </div>
        </div>
        <div class="flex-center space-x-2">
          <SynButton
            v-if="stepperByKey[currentStep].previousText"
            type-outline
            :label="$t(stepperByKey[currentStep].previousText)"
            :disabled="isLoadFinishCreate"
            @click="onHandleGoToPreviousStep"
          />
          <AtomTooltip
            v-if="
              isDisabledSummit &&
              currentStep == CREATE_WORKFLOW_STEPS.INPUT_STEP
            "
          >
            <template #trigger>
              <SynButton
                v-if="stepperByKey[currentStep].confirmText"
                :is-loading="isLoadFinishCreate"
                :disabled="isDisabledSummit"
                :label="$t(stepperByKey[currentStep].confirmText)"
                @click="onClickNext"
              />
            </template>
            <template #body>
              <div class="p-4 flex flex-col text-sm">
                <span
                  >{{ $t('WORKFLOW_LABEL_A_WORKFLOW_NEED_TO_HAVE_1') }}:</span
                >
                <ul class="list-disc px-4 space-y-2">
                  <li
                    v-if="
                      !workflowInfo?.steps || workflowInfo?.steps?.length == 0
                    "
                    class="text-sm italic"
                  >
                    {{ $t('WORKFLOW_LABEL_A_WORKFLOW_NEED_TO_HAVE_2') }}
                  </li>
                  <li
                    v-if="workflowInfo?.steps?.some((s) => !s?.name)"
                    class="text-sm italic"
                  >
                    {{ $t('WORKFLOW_LABEL_A_WORKFLOW_NEED_TO_HAVE_3') }}
                  </li>
                  <li
                    v-if="hasNotConnectionStepIds?.length > 0"
                    class="text-sm italic"
                  >
                    {{ $t('WORKFLOW_LABEL_A_WORKFLOW_NEED_TO_HAVE_4') }}
                  </li>
                </ul>
              </div>
            </template>
          </AtomTooltip>
          <SynButton
            v-else-if="stepperByKey[currentStep].confirmText"
            :is-loading="isLoadFinishCreate"
            :disabled="isDisabledSummit"
            :label="$t(stepperByKey[currentStep].confirmText)"
            @click="onClickNext"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
