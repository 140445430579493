import {
    getRemoteConfigValueByKey,
    RemoteConfigKey,
} from '@/ui/plugins/firebases/firebase-remote-config';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export const openVideoCallPage = (
    joinToken,
    participants: { name: string; avatar: string }[]
) => {
    if (!joinToken) return;

    // @ts-ignore
    if (typeof openDesktopAppWindow !== 'undefined') {
        // @ts-ignore
        openDesktopAppWindow(joinToken);
        return;
    }

    const location = getLocalStorage(StorageConstant.LOCATION);
    const host =
        location == 'eu' || location == 'EU'
            ? import.meta.env.VITE_VIDEO_CONFERENCE_DOMAIN_EU
            : import.meta.env.VITE_VIDEO_CONFERENCE_DOMAIN_DEFAULT;

    const participantString = participants.reduce(
        (oldValue: string, currentObject: { name: string; avatar: string }) => {
            return oldValue.concat(
                `${oldValue ? '__' : ''}${currentObject.name}_${
                    currentObject.avatar
                }`
            );
        },
        ''
    );
    const currentOrganizationId =
        MyOrganizationSingleton.getInstance().activeOrganization;
    const url = `${host}/conferences/${joinToken}?participants=${participantString}&organizationId=${currentOrganizationId}`;
    // onConfirmCallVideo(url, 'xtf', 900, 500);
    // const title = 'xtf';
    const width = 1100;
    const height = 680;
    const iMyWidth = window.screen.width / 2 - (width / 2 + 10);
    const iMyHeight = window.screen.height / 2 - (height / 2 + 50);

    const openMode = getRemoteConfigValueByKey(
        RemoteConfigKey.WebVideoCallOpenMode
    ).asNumber();

    const newCallWindow = window.open(
        url,
        'tictop-video-call-1123',
        openMode == 2
            ? `width=${width},height=${height},left=${iMyWidth},top=${iMyHeight},directories=0,titlebar=0,location=0,toolbar=0,menubar=0,scrollbars=0,resizable=0,status=0`
            : ''
    );

    newCallWindow?.focus();
};
