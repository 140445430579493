<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import TaskWorkflowStepEntity, {
  ITaskWorkflowStep,
} from '@/domain/entities/task/TaskWorkflowStepEntity';
import TaskTemplate from '@/ui/modules/task/components/task-template/TaskTemplate.vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import taskWorkflowStepComposables from '@/ui/composables/task/workflow/task-workflow-step.composables';

const props = withDefaults(
  defineProps<{
    viewMode?: 'TASK_VIEW' | 'ATTACH_TO_TASK';
    stepDetail: ITaskWorkflowStep;
    taskDetail?: ITaskDetailClass;
    readonly?: boolean;
    actionList?: string[];
    taskWorkflowId: string;
    isUpdateOnlyAttachment?: boolean;
  }>(),
  {
    viewMode: 'TASK_VIEW',
    actionList: () => ['DUPLICATE', 'EDIT', 'REMOVE'],
  }
);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'updateSuccess', newData: any, workflowStepData: any): void;
}>();

const {
  workflowStepData,
  currentTaskTemplate,
  updateWorkflowStepFromTaskTemplate,
  initData,
} = taskWorkflowStepComposables(
  { ...props.stepDetail, taskId: props.taskDetail?.id },
  { ...props.stepDetail, taskId: props.taskDetail?.id },
  props.taskDetail,
  false
);

onMounted(() => {});

watch(
  () => props.stepDetail?.id,
  () => {
    if (workflowStepData.value?.id !== props.stepDetail?.id)
      initData(props.stepDetail, props.taskDetail);
  }
);

const isUpdating = ref<boolean>(false);
const isDisableButton = computed<boolean>(() => {
  return !workflowStepData.value?.name;
});
const onSave = async () => {
  try {
    isUpdating.value = true;

    updateWorkflowStepFromTaskTemplate();

    if (props.viewMode == 'TASK_VIEW')
      await workflowStepData.value.updateTaskWorkflowStep();

    isUpdating.value = false;

    emit('updateSuccess', currentTaskTemplate.value, workflowStepData.value);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  }
};

const onCancelEdit = () => {
  emit('close');

  workflowStepData.value = new TaskWorkflowStepEntity({
    ...props?.stepDetail,
    taskWorkflowId: props.taskWorkflowId,
  });
};
</script>
<template>
  <div
    class="flex flex-col justify-between flex-1 min-h-0 w-full px-4 text-xs"
    style="max-width: 44rem"
  >
    <div class="flex-1 min-h-0 overflow-auto small-scrollbar">
      <!-- REPEAT DETAIL -->
      <div class="w-full px-2 flex flex-col space-y-3">
        <div
          class="w-full flex items-center space-x-2 rounded cursor-pointer pt-4"
        >
          <div class="flex-1 min-w-0 truncate">
            <!-- <span class="text-sm">
              {{ $t('COMMON_LABEL_INFORMATION') }}
            </span> -->
            <div class="text-base min-w-max font-semibold flex gap-2">
              <span
                v-html="
                  $t('WORKFLOW_LABEl_STEP_WITH_NAME', {
                    stepName: stepDetail?.name || '',
                  })
                "
              >
              </span>
            </div>
          </div>
          <!-- <SynIcon
          :name="'sort-down'"
          custom-class="h-2.5 w-2.5"
          :class="!visibleStatus.information ? '-rotate-90' : ''"
        /> -->
        </div>
        <div class="rounded w-full flex flex-col space-y-2">
          <!-- TASK REPEAT TEMPLATE -->
          <TaskTemplate
            ref="taskRepeatTemplateRef"
            v-model:task-template="currentTaskTemplate"
            :view-mode="readonly ? 'VIEW' : 'EDIT'"
            is-do-not-need-add-user-info
            is-update-domain-only
            is-workflow
            is-hidden-start-time
            :element-list="
              isUpdateOnlyAttachment
                ? ['DESCRIPTION', 'ATTACHMENT', 'CHECK_LIST']
                : [
                    'RESPONSIBLE',
                    'COLLABORATORS',
                    'DOMAIN',
                    'URGENCY',
                    'DESCRIPTION',
                    'DEADLINE',
                    'ATTACHMENT',
                    'CHECK_LIST',
                  ]
            "
          />
        </div>
      </div>
    </div>

    <div v-if="!readonly" class="w-full flex items-center justify-end">
      <div class="flex-center space-x-2 p-4">
        <AtomButton
          label="COMMON_LABEL_CANCEL"
          type="TEXT"
          size="lg"
          @click="onCancelEdit"
        />

        <AtomButton
          label="COMMON_LABEL_SAVE"
          size="lg"
          :disabled="isDisableButton"
          :is-loading="isUpdating"
          @click="onSave"
        />
      </div>
    </div>
  </div>
</template>
