<script setup lang="ts">
// *** IMPORTS ***

// *** PROPS, EMITS ***

const props = defineProps<{
  viewOptions: any;
}>();

const emit = defineEmits(['update:viewOptions']);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onOptionChange = (key, value) => {
  const options = props.viewOptions || {};
  options[key] = value;

  emit('update:viewOptions', options);
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <VigButton light color="gray" icon="DotsVertical" />
    </template>
    <template #dropdown-menu>
      <div class="w-72 p-3">
        <div class="mb-3 font-semibold text-gray-500">{{$t('CALENDAR_EVENT_DISPLAY_SETTING')}}</div>
        <div class="flex items-start justify-between gap-2 mb-2">
          <div>{{ $t('CALENDAR_SHOW_DESCRIPTION')}}</div>
          <VigSwitch
            :model-value="viewOptions?.isShowDescription"
            @update:model-value="onOptionChange('isShowDescription', $event)"
          />
        </div>
<!--        <div class="flex items-start justify-between gap-2">-->
<!--          <div>Hiển thị icon</div>-->
<!--          <VigSwitch-->
<!--            :model-value="viewOptions?.isShowIcon"-->
<!--            @update:model-value="onOptionChange('isShowIcon', $event)"-->
<!--          />-->
<!--        </div>-->
      </div>
    </template>
  </VigDropdown>
</template>

<style scoped></style>
