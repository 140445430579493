import TicketTaskApi from '@/ticket/api/task-api';
import TicketDomainApi from '@/ticket/api/domain-api';
import { FilterQuery } from '@/domain/entities/task/FilterTask';
import {
    removeAwsFile,
    uploadTaskAttachmentNotes,
    uploadTaskAttachments,
    uploadTaskSingleNote,
} from '@/ui/modules/task/helper/task-upload-helper';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { awsS3Delete } from '@/ui/plugins/awss3/aws-s3-services';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { TaskMedia } from '@/domain/entities/task/TaskResponseClass';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';
import { TicketPayloadClass } from '../domain/TicketPayload';
import AttachmentClass from '@/application/services/attachment/AttachmentClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import NoteClass from '@/application/services/attachment/NoteClass';

export default class EmbedTaskServices {
    private static instance: EmbedTaskServices;
    taskApi: TicketTaskApi;
    domainApi: TicketDomainApi;

    constructor() {
        this.taskApi = TicketTaskApi.getInstance();
        this.domainApi = TicketDomainApi.getInstance();
    }

    public static getInstance(): EmbedTaskServices {
        if (!EmbedTaskServices.instance) {
            // Get from local storage
            EmbedTaskServices.instance = new EmbedTaskServices();
        }

        return EmbedTaskServices.instance;
    }

    async create(payloadForm: TicketPayloadClass): Promise<any> {
        let payloadUploadedToAws: any[] = [];

        // const attachmentServiceClass = new AttachmentServiceClass(
        //   'TASK_ATTACHMENT'
        // );

        try {
            // 1. Add files which has not ID
            if (payloadForm.files?.length > 0) {
                // const uploadFiles = await attachmentServiceClass.uploads(
                //   payloadForm.files
                // );
                const uploadFiles = await uploadTaskAttachments(
                    payloadForm.files
                );

                payloadUploadedToAws = [
                    ...payloadUploadedToAws,
                    ...uploadFiles,
                ];
                payloadForm.files = cloneDeep(uploadFiles);
            }
            // 2. Upload Note capture to aws
            payloadForm.notes = payloadForm.notes.filter(
                (o) => !o.id || (o.id && (o.isDeleted || o.isUpdated))
            );
            if (payloadForm.notes?.length > 0) {
                const uploadNotes = await uploadTaskAttachmentNotes(
                    payloadForm.notes
                );
                payloadUploadedToAws = [
                    ...payloadUploadedToAws,
                    ...uploadNotes,
                ];
                payloadForm.notes = cloneDeep(uploadNotes);
            }

            const res: any = await this.taskApi.createTicket({
                ...payloadForm,
            });

            const newTask = res?.result;

            return Promise.resolve(newTask);
        } catch (error) {
            payloadUploadedToAws.forEach((item) => {
                awsS3Delete({
                    Bucket: item.bucket,
                    Key: item.path,
                });
            });

            console.log('🚀 TaskState', error);
            // remove file which uploaded to aws

            settingGlobalModal({
                type: 'notification',
                title: '',
                content:
                    translate('COMMON_ERROR_MESSAGE_CREATE_TASK') ||
                    'Đã xảy ra lỗi khi tạo task, vui lòng thử lại.',
                confirmable: true,
            });
            await ask();

            return Promise.reject(false);
        }
    }

    getHistories(data): Promise<any> {
        return this.taskApi.getHistories(data);
    }

    finish(taskId: number): Promise<any> {
        return this.taskApi.finishTask(taskId);
    }
    changeState(taskId: number, state: number): Promise<any> {
        return this.taskApi.changeState({ taskId, state });
    }
    async setPlanned(taskId: number, planType: number): Promise<any> {
        return await this.taskApi.setPlannedTask({
            taskId,
            planType,
        });
    }
    async setAssignee(
        taskId: number,
        assigneeId: number | null,
        assignToMe: boolean
    ): Promise<any> {
        return await this.taskApi.setAssignee({
            taskId,
            assigneeId,
            assignToMe,
        });
    }
    async delete(taskId: number): Promise<any> {
        return await this.taskApi.deleteTask(taskId);
    }
    getTaskWithFilter(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId
        )
            return Promise.resolve([]);

        return this.taskApi.getTaskWithFilter(filterData);
    }
    async getTaskMedia(taskId: number): Promise<TaskMedia> {
        const res = await this.taskApi.getAttachmentNoteByTaskId(taskId);
        return res['result'];
    }
    async getDetailByCode(taskCode: string): Promise<any> {
        const res = await this.taskApi.getDetailTaskByCode(taskCode);
        return res['result'];
    }
    async getDetailByID(taskId: number): Promise<any> {
        const res = await this.taskApi.getDetailTaskById(taskId);
        return res['result'];
    }

    getDetailTaskIncognitoById(taskId: number): Promise<any> {
        return this.taskApi.getDetailTaskIncognitoById(taskId);
    }

    reopenTask(taskId: number): Promise<any> {
        return this.taskApi.reopen(taskId);
    }
    renameTask(data: any): Promise<any> {
        return this.taskApi.renameTask(data);
    }
    removeAwsFile(bucket, filePath): any {
        let key = filePath;
        if (filePath.includes(bucket)) {
            key = filePath.split(bucket + '/').pop();
            key = key
                ?.replace('xlarge', 'original')
                ?.replace('small', 'original')
                ?.replace('medium', 'original')
                ?.replace('mini', 'original');
        }
        removeAwsFile(bucketTictop.AttachmentPhoto, key);
    }

    getCurrentTaskCycle(): Promise<any> {
        return this.taskApi.getCurrentTaskCycle();
    }
    changeScheduleTime(data: any): Promise<any> {
        return this.taskApi.changeScheduleTime(data);
    }
    async updateFiles(taskId, files) {
        //1. upload to aws
        const uploadFiles = await uploadTaskAttachments(files);
        //2. update db
        return this.taskApi.updateFiles({
            id: taskId,
            files: uploadFiles,
        });
    }
    removeFile(taskId, file) {
        return this.taskApi.updateFiles({
            id: taskId,
            files: [
                {
                    ...file,
                    isDeleted: true,
                },
            ],
        });
    }
    async updateNote(taskId, noteData) {
        // const { base64, drawData, audios } = noteData;
        const { base64, drawData, id } = noteData;
        // 1. update image, audios
        const payloadNote = await uploadTaskSingleNote({
            id,
            fileUrl: base64,
            type: 'image/jpeg',
            isBase64: true,
            isDeleted: false,
        });
        // TODO
        // uploadMultipleFiles(audios);
        // 2. save note data to database
        return this.taskApi.updateNote({
            id: taskId,
            note: {
                ...payloadNote,
                drawData: JSON.stringify(drawData),
                id,
            },
        });
    }
    removeNote(taskId, noteId) {
        return this.taskApi.updateNote({
            id: taskId,
            note: {
                id: noteId,
                isDeleted: true,
            },
        });
    }
    changeDescription(data: any): Promise<any> {
        return this.taskApi.changeDescription(data);
    }
    changeAssigneeTask(data: any): Promise<any> {
        return this.taskApi.changeAssigneeTask(data);
    }
    changeUrgency(taskId: number, urgency: number): Promise<any> {
        const payload = {
            id: taskId,
            priority: convertUrgencyToPriority(urgency),
            important: convertUrgencyToImportant(urgency),
        };
        return this.taskApi.changeUrgency(payload);
    }

    changeDomain(payload): Promise<any> {
        return this.taskApi.changeDomain(payload);
    }

    changePrivate(payload): Promise<any> {
        return this.taskApi.changePrivate(payload);
    }

    async getTaskListOverview(): Promise<any> {
        const res = await this.taskApi.getTaskListOverview();
        return res['result'];
    }
    async getTaskListPlannedToTreat(): Promise<any> {
        const res = await this.taskApi.getTaskListPlannedToTreat();
        return res['result'];
    }
    async getTaskListPlannedToFinish(): Promise<any> {
        const res = await this.taskApi.getTaskListPlannedToFinish();
        return res['result'];
    }
    getDataConfig(): Promise<any> {
        return this.taskApi.getDataConfig();
    }

    getAllComments(orgId, taskId) {
        return this.taskApi.getAllComments(orgId, taskId);
    }

    async createComment(
        orgId,
        taskId,
        content,
        attachmentFiles,
        notes,
        mentionUserIds,
        mentionGroup
    ) {
        //1. upload to aws
        // const uploadFiles = await uploadTaskAttachments(files);
        const promiseAll: Promise<any>[] = [];
        const promiseNoteAll: Promise<any>[] = [];
        Array.from(attachmentFiles).forEach((file) => {
            promiseAll.push(
                new AttachmentClass(ModuleSourceFileEnum.task, file).upload()
            );
        });
        Array.from(
            notes?.map((note) => {
                return { ...note, id: '00000000-0000-0000-0000-000000000000' };
            })
        ).forEach((file) => {
            // console.log('🚀 Hyrin ~ TaskServiceSingleton ~ ).forEach ~ file', file);
            promiseNoteAll.push(
                new NoteClass(ModuleSourceFileEnum.task, file).upload()
            );
        });
        const uploadFiles = await Promise.all(promiseAll);
        const uploadNotes = await Promise.all(promiseNoteAll);

        //2. update db
        return this.taskApi.createComment(orgId, taskId, {
            taskId,
            content: content,
            attachments: {
                files: uploadFiles || [],
                notes: uploadNotes || [],
            },
            mentionUserIds: mentionUserIds || [],
            mentionGroup: mentionGroup,
        });
    }

    updateComment(commentId, content, mentionUserIds, mentionGroup) {
        const dateRequest = {
            commentId: commentId,
            content: content,
            mentionUserIds: mentionUserIds || [],
            mentionGroup: mentionGroup,
        };
        return this.taskApi.updateComment(commentId, dateRequest);
    }

    async updateCommentAttachments(
        commentId,
        attachments: {
            //files
            addedFiles: any[];
            removedFiles: any[];
            //notes
            addedNotes: any[];
            editedNotes: any[];
            removedNotes: any[];
        }
    ) {
        console.log(
            '🚀 Hyrin ~ TaskServiceSingleton ~ attachments',
            attachments
        );
        const promiseAll: Promise<any>[] = [];
        const promiseNoteAll: Promise<any>[] = [];
        Array.from(attachments.addedFiles).forEach((file) => {
            promiseAll.push(
                new AttachmentClass(ModuleSourceFileEnum.task, file).upload()
            );
        });
        Array.from([
            ...attachments.addedNotes?.map((note) => {
                return {
                    ...note,
                    id: '00000000-0000-0000-0000-000000000000',
                };
            }),
            ...attachments.editedNotes,
        ]).forEach((file) => {
            console.log(
                '🚀 Hyrin ~ TaskServiceSingleton ~ ]).forEach ~ file',
                file
            );
            promiseNoteAll.push(
                new NoteClass(ModuleSourceFileEnum.task, {
                    ...file,
                    base64: file?.base64,
                    drawData: file?.drawData,
                }).upload()
            );
        });
        const newUploadedFiles = await Promise.all(promiseAll);
        const newUploadedNotes = await Promise.all(promiseNoteAll);

        return this.taskApi.updateCommentAttachments(commentId, {
            files: [
                ...newUploadedFiles,
                ...attachments.removedFiles?.map((file) => {
                    return { ...file, isDeleted: true };
                }),
            ],
            notes: [
                ...newUploadedNotes,
                ...attachments.removedNotes?.map((note) => {
                    return {
                        ...note,
                        drawData: JSON.stringify(note.drawData),
                        isDeleted: true,
                    };
                }),
            ],
        });
    }

    pinnedComment(commentId) {
        return this.taskApi.pinnedComment(commentId);
    }

    reactionComment(commentId, data) {
        return this.taskApi.reactionComment(commentId, data);
    }

    deleteComment(commentId) {
        return this.taskApi.deleteComment(commentId);
    }

    deleteAttachmentInComment(commentId) {
        return this.taskApi.deleteAttachmentInComment(commentId);
    }

    getDrawDataById(noteIdString) {
        return this.taskApi.getDrawDataById(noteIdString);
    }
}
