<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 791.454 791.454"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g id="Vrstva_x0020_1_28_">
          <path
            clip-rule="evenodd"
            d="m202.8 0h264.71l224.217 233.922v454.459c0 56.891-46.182 103.073-103.073 103.073h-385.854c-56.891 0-103.073-46.182-103.073-103.073v-585.308c0-56.891 46.182-103.073 103.073-103.073z"
            fill="#ff7878"
            fill-rule="evenodd"
            data-original="#fa0000"
            class=""
          ></path>
          <g fill="#fff">
            <path
              clip-rule="evenodd"
              d="m467.176 0v231.914h224.552z"
              fill-rule="evenodd"
              opacity=".302"
              fill="#ffffff"
              data-original="#ffffff"
            ></path>
            <path
              d="m532.767 423.671c-2.677-2.008-6.693-2.008-9.705-.669l-66.596 33.465v-24.095c0-17.067-13.386-30.453-30.453-30.453h-141.893c-16.733 0-30.453 13.386-30.453 30.453v121.479c0 17.067 13.721 30.453 30.453 30.453h141.893c17.067 0 30.453-13.386 30.453-30.453v-24.095l66.596 33.465c5.02 2.343 11.044.335 13.721-4.685.669-1.339 1.004-3.012 1.004-4.685v-121.479c0-3.682-2.008-7.028-5.02-8.701zm-177.7 110.1c-22.422 0-40.493-18.406-40.493-40.828s18.071-40.493 40.493-40.493 40.493 18.071 40.493 40.493-18.071 40.828-40.493 40.828z"
              fill="#ffffff"
              data-original="#ffffff"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
