<script setup lang="ts">
import { computed } from 'vue';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});
const beforeActivity = computed(() => props.beforeUpdate);
const afterActivity = computed(() => props.afterUpdate);
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 items-center justify-between pr-2">
      <div
        v-if="beforeActivity?.AssigneeId || beforeActivity?.GroupId"
        class="flex flex-col space-y-1 text-xs"
      >
        <UserById
          v-if="
            beforeActivity?.AssigneeId &&
            beforeActivity?.AssigneeId !== afterActivity?.AssigneeId
          "
          :user-id="beforeActivity?.AssigneeId"
          avatar-class="w-5 h-5"
          class="font-medium"
        />
        <span
          v-if="!beforeActivity?.AssigneeId"
          class="text-xs text-gray-500"
          >{{ $t('COMMOM_LABEL_NONE') }}</span
        >
        <GroupById
          v-if="
            beforeActivity?.GroupId &&
            beforeActivity?.GroupId !== afterActivity?.GroupId
          "
          :group-id="beforeActivity?.GroupId"
          :group-name="beforeActivity?.GroupName"
          :group-avatar="beforeActivity?.GroupAvatar"
          avatar-class="w-5 h-5"
          class="font-medium"
        >
          <template #description-name>
            <span class="text-gray-500" style="font-size: 0.6rem">{{
              $t('COMMON_LABEL_ASSIGN_GROUP')
            }}</span>
          </template>
        </GroupById>
        <span v-if="!beforeActivity?.GroupId" class="text-xs text-gray-500">{{
          `${$t('COMMON_LABEL_GROUP')}: ${$t('COMMOM_LABEL_NONE')}`
        }}</span>
      </div>
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
      <span>→</span>
    </div>

    <div class="flex w-1/2 justify-start pl-4">
      <div
        v-if="afterActivity?.AssigneeId || afterActivity?.GroupId"
        class="flex flex-col space-y-1 text-xs"
      >
        <UserById
          v-if="
            afterActivity?.AssigneeId &&
            beforeActivity?.AssigneeId !== afterActivity?.AssigneeId
          "
          :user-id="afterActivity?.AssigneeId"
          avatar-class="w-5 h-5"
          class="font-medium"
        />
        <div
          v-else-if="
            afterActivity?.GroupId &&
            beforeActivity?.GroupId !== afterActivity?.GroupId
          "
          class="flex items-center space-x-2 space-y-1 text-xs"
        >
          <GroupById
            v-if="afterActivity?.GroupId"
            :group-id="afterActivity?.GroupId"
            :group-name="afterActivity?.GroupName"
            :group-avatar="afterActivity?.GroupAvatar"
            avatar-class="w-5 h-5"
            class="font-medium"
          >
            <template #description-name>
              <span class="text-gray-500" style="font-size: 0.6rem">{{
                $t('COMMON_LABEL_ASSIGN_GROUP')
              }}</span>
            </template>
          </GroupById>
        </div>
        <span
          v-else-if="!afterActivity?.GroupId"
          class="text-xs text-gray-500"
          >{{ `${$t('COMMON_LABEL_GROUP')}: ${$t('COMMOM_LABEL_NONE')}` }}</span
        >
      </div>
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </div>
  </div>
</template>
