import { ref } from 'vue';
import { chain, findIndex } from 'lodash';

export default function useWorkflowTasksPaging(
    tasksByStep: any,
    pageSize: number
) {
    const tasksByStepPaging = ref<{ [stepId: string]: any[] }>({});
    const remainingTasksCountByStep = ref<{ [stepId: string]: number }>({});

    const onLoadMoreStepTasks = (stepId: string, size: number = 0) => {
        if (remainingTasksCountByStep.value[stepId] <= 0) return;

        remainingTasksCountByStep.value[stepId] = 0;

        const currTasksCount = tasksByStepPaging.value[stepId]?.length || 0;

        tasksByStepPaging.value[stepId] = [
            ...tasksByStepPaging.value[stepId],
            ...tasksByStep.value[stepId]?.slice(
                currTasksCount,
                currTasksCount + (size || pageSize)
            ),
        ];

        _processRemainingTasksCount(stepId);
    };

    const updateStepTasks = (stepId: string) => {
        const bottomTask = chain(tasksByStepPaging.value[stepId])
            .filter((task) =>
                tasksByStep.value[stepId]?.some((t) => t?.id === task?.id)
            )
            .last()
            .value();

        let bottomTaskNewIndex = findIndex(
            tasksByStep.value[stepId],
            (task: any) => task?.id === bottomTask?.id
        );

        bottomTaskNewIndex = Math.max(bottomTaskNewIndex, pageSize - 1);

        // Update tasks in current range (0->bottom task)
        tasksByStepPaging.value[stepId] = tasksByStep.value[stepId]?.slice(
            0,
            bottomTaskNewIndex + 1
        );

        _processRemainingTasksCount(stepId);
    };

    const _processRemainingTasksCount = (stepId: string) => {
        remainingTasksCountByStep.value[stepId] =
            (tasksByStep.value[stepId]?.length || 0) -
            (tasksByStepPaging.value[stepId]?.length || 0);
    };

    return {
        tasksByStepPaging,
        remainingTasksCountByStep,
        onLoadMoreStepTasks,
        updateStepTasks,
    };
}
