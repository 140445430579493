<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useIntersectionObserver } from '@vueuse/core';
import groupStore from '@/store/group';
import { ignoreUnicode } from '@/ui/plugins/utils';
import userStore from '@/store/user';
import favoriteStore from '@/store/favorite/favoriteStore';
import { stringNomalize, markTextSearch } from '@/ui/hooks/commonFunction';
import { FavoriteType } from '@/ui/common/constants/constant';
import taskStore from '@/store/task';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import { isValidTaskCode } from '@/ui/hooks/taskHook';
import {
  openNotification,
  AlertType,
  taskListRouteByType,
} from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import myProfileStore from '@/store/auth/my-profile';
import SynAvatarStatusActive from '@/ui/common/molecules/SynAvatar/SynAvatarStatusActive.vue';
import ActionById from '@/ui/components/user/ActionById.vue';
import UserById from '@/ui/components/user/UserById.vue';
import DepartmentByUserId from '@/ui/components/user/DepartmentByUserId.vue';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
// import taskTabViewStore from '@/store/task/task-tab-view';
// import { EFilterTaskDefault } from '@/application/constants/task.const';

const _groupStore = groupStore();
const _userStore = userStore();
const _favoriteStore = favoriteStore();
const _taskStore = taskStore();
const router = useRouter();

const emit = defineEmits(['onOpenSearch']);

onMounted(() => {
  let options = {
    root: document.querySelector('#intersection-root'),
    rootMargin: '0px',
    threshold: 0,
  };

  ['member', 'group'].forEach((item) => {
    let observer = new IntersectionObserver(
      item == 'group'
        ? onIntersectionGroupChanged
        : onIntersectionMemberChanged,
      options
    );
    const target = document.getElementById(`item-list-by-${item}`);
    target && observer.observe(target);
  });
});

const currentCycle = computed(() => _taskStore.currentCycle);
const myProfile = computed(() => myProfileStore().myProfile);
const isVisitor = computed(() => _userStore.isVisitor);
const allUsers = computed(() =>
  isVisitor.value ? [] : _userStore.getDefaultUsers
);
const allMyGroups = computed(() => _groupStore.allMyGroups);
const allMyDomains = computed(() => myProfileStore().myDomains);
const filteredGroups = computed(() => {
  if (isSearchingTask.value) return [];
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return allMyGroups.value;

  return (allMyGroups.value || []).filter(
    (group) => group?.name && ignoreUnicode(group?.name).includes(searchTxt)
  );
});
const filteredDomains = computed(() => {
  if (isSearchingTask.value) return [];
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return allMyDomains.value;

  return (allMyDomains.value || []).filter(
    (domain) => domain?.name && ignoreUnicode(domain?.name).includes(searchTxt)
  );
});
const filteredUsers = computed(() => {
  if (isSearchingTask.value) return [];
  const searchTxt = ignoreUnicode(searchText.value);
  if (!searchTxt) return allUsers.value;

  return (allUsers.value || []).filter(
    (user) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
  );
});
const isSearchingTask = computed(() => searchText.value?.includes('#'));

const allMyFavorites = computed(() => _favoriteStore.getMyFavorites);

const myFavorites = computed(() => {
  if (isSearchingTask.value) return [];
  let data = _favoriteStore.getMyFavorites;
  data = data?.filter((o) =>
    stringNomalize(o.name?.toLowerCase()).includes(
      stringNomalize(searchText.value?.toLowerCase())
    )
  );
  return data;
});

const resultSearch = computed(() => {
  let data = [] as any;
  filteredUsers.value.forEach((el) => {
    data.push({
      id: el?.id,
      type: 'USER',
      isGroup: false,
      avatar: el?.avatar,
      name: el?.name,
    });
  });
  filteredGroups.value.forEach((el) => {
    data.push({
      id: el?.id,
      type: 'GROUP',
      isGroup: true,
      avatar: el?.avatar,
      name: el?.name,
    });
  });
  filteredDomains.value.forEach((el) => {
    data.push({
      id: el?.id,
      type: 'DOMAIN',
      name: el?.name,
    });
  });
  return data;
});

const currentTab = ref('favorite');
const activeClass = 'text-current-600 bg-current-50 border border-current-600';

const isSearching = ref(false);
const searchText = ref('');
const currentIndex = ref(0);
const isLoadingElement = ref(false);

const onIntersectionGroupChanged = (entries) => {
  if (entries.length > 0) {
    entries.forEach((entry) => {
      currentTab.value = entry.isIntersecting ? 'group' : 'member';
    });
  }
};
const onIntersectionMemberChanged = (entries) => {
  if (entries.length > 0) {
    entries.forEach((entry) => {
      currentTab.value = entry.isIntersecting ? 'member' : 'favorite';
    });
  }
};

const getMenuLinkUrl = (contact) => {
  return taskListRouteByType(contact?.type?.toUpperCase(), contact?.id, true, {
    filterTab: 'TODO',
  });
};

const onScrollTo = (keyName) => {
  currentTab.value = keyName;
  const element = document.getElementById(
    `item-list-by-${keyName}`
  ) as HTMLDivElement;
  if (element) element.scrollIntoView({ block: 'start' });
};

const onOpenDropdownSearch = () => {
  emit('onOpenSearch', true);
  console.log('onOpenDropdownSearch');
  isSearching.value = true;
  isLoadingElement.value = true;
  searchText.value = '';
  currentIndex.value = 0;
  setTimeout(() => {
    if (currentTab.value) onScrollTo(currentTab.value);
  });
};

const onChangeTextSearch = () => {
  isSearching.value = true;
  currentIndex.value = 0;
  // if (searchText.value !== '#') return;
  // searchText.value = `#${codePrefix.value}`;
};

const chooseTaskOwner = (contact) => {
  console.log('🚀 Tictop ~ contact:', contact);
  if (!contact?.id) return;
  isSearching.value = false;
  router.push(getMenuLinkUrl(contact));
};

const onChooseResult = () => {
  if (resultSearch.value?.length < 1 && !isSearchingTask.value) return;

  if (isSearchingTask.value) {
    const taskCode = searchText.value?.replace('#', '');

    const fullCode = taskCode.includes('-')
      ? taskCode
      : `${currentCycle.value?.currentCycle}-${taskCode}`;

    if (!isValidTaskCode(fullCode)) {
      openNotification({
        type: AlertType.warning,
        body: translate('TASK_LABEL_CODE_INVALID') || 'Task code invalid',
        duration: 2000,
      });
      return;
    }
    taskCodeGlobal.value = fullCode;

    isShowTaskDetailGlobal.value = true;
    isSearching.value = false;
  } else {
    chooseTaskOwner(resultSearch.value[currentIndex.value]);

    isSearching.value = false;
  }
};

const onCloseDropdownSearch = () => {
  isSearching.value = false;
  isLoadingElement.value = false;
  emit('onOpenSearch', false);
};

const waitingForElementToDisplay = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      // console.log('mutations', mutations);
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

waitingForElementToDisplay('#vig-search-global').then(() => {
  console.log('Element is ready');
  isLoadingElement.value = false;
});
window.addEventListener('keydown', (e) => {
  if (e.ctrlKey && e.keyCode === 75) {
    // console.log('CTRL + K');
    onOpenDropdownSearch();
    e.preventDefault();
  }
});

const favoriteElmRef = ref(null);

useIntersectionObserver(favoriteElmRef, ([{ isIntersecting }]) => {
  console.log('🚀 Tictop ~ isIntersecting:', isIntersecting);
});
</script>

<template>
  <VigDropdown
    v-if="isSearching"
    id="vig-search-global"
    class="cursor-pointer"
    placement="bottom-start"
    auto-open
    trigger="focus"
    :arrow="false"
    hide-on-click="outside"
    @on-dropdown-open="onOpenDropdownSearch"
    @on-dropdown-close="onCloseDropdownSearch"
  >
    <template #dropdown-toggle>
      <VigSearchBox
        v-model="searchText"
        v-model:current-index="currentIndex"
        :total-result="resultSearch?.length"
        is-show-short-cut-key
        key-code="K"
        input-class="text-sm"
        style="width: 20rem"
        autofocus
        :placeholder="$t('COMMON_LABEL_SEARCH_TASK')"
        @update:model-value="onChangeTextSearch"
        @enter="onChooseResult"
      />
    </template>
    <template #dropdown-menu>
      <div
        v-if="!isSearchingTask"
        class="flex flex-col"
        style="max-height: 30rem"
        :style="searchText ? 'width: 20rem' : 'width: 50rem'"
      >
        <div
          v-if="!searchText"
          class="flex w-full text-xs px-2 pt-4 pb-2 space-x-2"
        >
          <div
            v-if="allMyFavorites.length > 0"
            :class="currentTab == 'favorite' ? activeClass : ''"
            class="
              py-1
              font-semibold
              cursor-pointer
              rounded-full
              px-3
              bg-current-50
              text-current-400
            "
            @click="onScrollTo('favorite')"
          >
            {{ $t('SIDEBAR_LABEL_MY_FAVORITES') || 'Favorites' }}
          </div>
          <div
            v-if="allUsers.length > 0"
            :class="currentTab == 'member' ? activeClass : ''"
            class="
              py-1
              font-semibold
              cursor-pointer
              rounded-full
              px-3
              bg-current-50
              text-current-400
            "
            @click="onScrollTo('member')"
          >
            {{ $t('CHAT_MEMBERS') || 'Members' }}
          </div>
          <div
            v-if="allMyGroups.length > 0"
            :class="currentTab == 'group' ? activeClass : ''"
            class="
              py-1
              font-semibold
              cursor-pointer
              rounded-full
              px-3
              bg-current-50
              text-current-400
            "
            @click="onScrollTo('group')"
          >
            {{ $t('CHAT_GROUPS') || 'Groups' }}
          </div>
          <div
            v-if="allMyDomains.length > 0"
            :class="currentTab == 'domain' ? activeClass : ''"
            class="
              py-1
              font-semibold
              cursor-pointer
              rounded-full
              px-3
              bg-current-50
              text-current-400
            "
            @click="onScrollTo('domain')"
          >
            {{ $t('DOMAIN_LABEL_TITLE') || 'Groups' }}
          </div>
        </div>
        <template v-if="!searchText">
          <div
            id="intersection-root"
            class="flex-1 overflow-auto small-scrollbar pb-3"
            style="scroll-behavior: smooth"
          >
            <template
              v-if="
                myFavorites?.length <= 0 &&
                filteredUsers?.length <= 0 &&
                filteredGroups?.length <= 0 &&
                filteredDomains?.length <= 0
              "
            >
              <div class="py-16 flex-center flex-col">
                <SynAnimation
                  name="searchIcon"
                  :loop="false"
                  stype="width: 100px; height: 100px"
                />
                <span class="font-medium text-gray-600">{{
                  $t('COMMON_LABEL_ALERT_NO_SEARCH')
                }}</span>
              </div>
            </template>
            <template v-else>
              <div id="item-list-by-favorite">
                <div
                  v-if="myFavorites?.length > 0"
                  ref="favoriteElmRef"
                  class="
                    px-4
                    pt-7
                    pb-1
                    uppercase
                    text-xs
                    font-semibold
                    tracking-widest
                    text-gray-500
                  "
                >
                  {{ $t('SIDEBAR_LABEL_MY_FAVORITES') || 'Favorites' }}
                </div>
                <div
                  v-if="myFavorites?.length > 0"
                  class="w-full h-full grid auto-rows-max gap-2 p-2 grid-cols-3"
                  style="
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(12rem, 1fr)
                    );
                  "
                >
                  <div
                    v-for="favorite in myFavorites.filter((o) => o.id)"
                    :key="favorite"
                    class="
                      p-2
                      w-full
                      flex
                      items-center
                      hover:bg-gray-100
                      rounded
                      text-sm
                      justify-between
                      hover-to-show__parent
                      cursor-pointer
                    "
                    @click="
                      chooseTaskOwner({
                        ...favorite,
                        type: favorite?.favoriteType,
                      })
                    "
                  >
                    <UserById
                      v-if="favorite?.favoriteType == 'member'"
                      is-show-department
                      :user-id="favorite.id"
                      class="flex-1"
                      avatar-class="w-8 h-8"
                      is-show-working-status
                    />
                    <div v-else class="flex items-center space-x-2">
                      <SynAvatar
                        :src="
                          favorite.avatarUrl
                            ? favorite.avatarUrl?.replace('original', 'mini')
                            : ''
                        "
                        :type="
                          favorite?.favoriteType === FavoriteType.Group
                            ? 'group'
                            : favorite?.favoriteType === FavoriteType.Domain
                            ? 'domain'
                            : 'user'
                        "
                        :name="favorite.name"
                        custom-class="w-8 h-8"
                      />
                      <span class="cursor-pointer text-overflow-hidden-line">{{
                        favorite?.name
                      }}</span>
                      <span v-if="favorite?.refId === myProfile?.id">
                        ({{ $t('COMMON_LABEL_ME') || 'Me' }})
                      </span>
                    </div>
                    <div class="text-end hover-to-show__children">
                      <ActionById
                        v-if="favorite?.id"
                        :contact-id="favorite?.id"
                        :types="['favourite', 'chat']"
                        :type-contact="favorite?.favoriteType"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="item-list-by-member">
                <div
                  v-if="filteredUsers?.length > 0"
                  class="
                    px-4
                    pt-7
                    pb-1
                    uppercase
                    text-xs
                    font-semibold
                    tracking-widest
                    text-gray-500
                  "
                >
                  {{ $t('CHAT_MEMBERS') || 'Members' }}
                </div>
                <div
                  v-if="filteredUsers?.length > 0"
                  class="w-full h-full grid auto-rows-max gap-2 p-2 grid-cols-3"
                  style="
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(12rem, 1fr)
                    );
                  "
                >
                  <div
                    v-for="user in filteredUsers"
                    :key="user"
                    class="
                      dropdown-item
                      p-2
                      flex
                      items-center
                      space-x-2
                      hover:bg-gray-100
                      rounded
                      text-sm
                      hover-to-show__parent
                      cursor-pointer
                    "
                    @click="
                      chooseTaskOwner({
                        ...user,
                        type: 'MEMBER',
                      })
                    "
                  >
                    <div
                      class="flex items-center w-full justify-between space-x-2"
                    >
                      <UserById
                        :user-id="user.id"
                        is-show-department
                        class="flex-1"
                        avatar-class="w-8 h-8"
                        is-show-working-status
                      />

                      <div class="text-end hover-to-show__children">
                        <ActionById
                          v-if="user?.id"
                          :contact-id="user?.id"
                          :types="['favourite', 'chat']"
                          :type-contact="'member'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="item-list-by-group">
                <div
                  v-if="filteredGroups?.length > 0"
                  class="
                    px-4
                    pt-7
                    pb-2
                    uppercase
                    text-xs
                    font-semibold
                    tracking-widest
                    text-gray-500
                  "
                >
                  {{ $t('CHAT_GROUPS') || 'Groups' }}
                </div>
                <div
                  v-if="filteredGroups?.length > 0"
                  class="w-full h-full grid auto-rows-max gap-2 p-2 grid-cols-3"
                  style="
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(12rem, 1fr)
                    );
                  "
                >
                  <div
                    v-for="group in filteredGroups"
                    :key="group"
                    class="
                      dropdown-item
                      p-2
                      flex
                      items-center
                      cursor-pointer
                      hover:bg-gray-100
                      rounded
                      text-sm
                      justify-between
                      hover-to-show__parent
                    "
                    @click="
                      chooseTaskOwner({
                        ...group,
                        type: 'GROUP',
                      })
                    "
                  >
                    <div class="flex items-center space-x-2">
                      <SynAvatar :src="group?.avatar" :name="group?.name" />
                      <span class="text-overflow-hidden-line">
                        {{ group?.name }}
                      </span>
                    </div>
                    <div class="text-end hover-to-show__children">
                      <ActionById
                        v-if="group?.id"
                        :contact-id="group?.id"
                        :types="['favourite', 'chat']"
                        :type-contact="'group'"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="item-list-by-domain">
                <div
                  v-if="filteredDomains?.length > 0"
                  class="
                    px-4
                    pt-7
                    pb-2
                    uppercase
                    text-xs
                    font-semibold
                    tracking-widest
                    text-gray-500
                  "
                >
                  {{ $t('DOMAIN_LABEL_TITLE') || 'Domains' }}
                </div>
                <div
                  v-if="filteredDomains?.length > 0"
                  class="w-full h-full grid auto-rows-max gap-2 p-2 grid-cols-3"
                  style="
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(12rem, 1fr)
                    );
                  "
                >
                  <div
                    v-for="domain in filteredDomains"
                    :key="domain?.id"
                    class="
                      dropdown-item
                      p-2
                      flex
                      items-center
                      cursor-pointer
                      hover:bg-gray-100
                      rounded
                      text-sm
                      justify-between
                      hover-to-show__parent
                    "
                    @click="
                      chooseTaskOwner({
                        ...domain,
                        type: 'DOMAIN',
                      })
                    "
                  >
                    <div class="flex items-center space-x-2">
                      <SynAvatar
                        type="domain"
                        :name="domain.name"
                        custom-class="w-8 h-8"
                      />
                      <span class="text-overflow-hidden-line">
                        {{ domain?.name }}
                      </span>
                    </div>
                    <div class="text-end hover-to-show__children">
                      <ActionById
                        v-if="domain?.id"
                        :contact-id="domain?.id"
                        :types="['favourite']"
                        :type-contact="'domain'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
        <!-- STARTING SEARCH -->
        <div v-else class="flex-1 overflow-auto small-scrollbar pb-3">
          <template v-if="resultSearch?.length <= 0">
            <div class="py-16 flex-center flex-col">
              <SynAnimation
                name="searchIcon"
                :loop="false"
                stype="width: 100px; height: 100px"
              />
              <span class="font-medium text-gray-600">{{
                $t('COMMON_LABEL_ALERT_NO_SEARCH')
              }}</span>
            </div>
          </template>
          <template v-else>
            <div class="py-2">
              <RouterLink
                v-for="(result, index) in resultSearch"
                :id="`vig-search-box-item-id_${index.toString()}`"
                :key="result"
                :class="index == currentIndex ? 'bg-gray-100' : ''"
                :to="getMenuLinkUrl(result)"
                class="
                  dropdown-item
                  p-2
                  flex
                  items-center
                  space-x-2
                  cursor-pointer
                  hover:bg-current-50
                  rounded
                  text-sm
                  justify-between
                  hover-to-show__parent
                "
                @click="isSearching = false"
              >
                <div class="flex space-x-2 items-center">
                  <div class="pr-3">
                    <SynIcon
                      name="search"
                      custom-class="w-3 h-3 text-gray-400 fill-gray-500"
                    />
                  </div>

                  <SynAvatar
                    v-if="result?.type == 'GROUP' || result?.type == 'DOMAIN'"
                    :src="result?.avatar"
                    :type="result?.type?.toLowerCase()"
                    :name="result.name"
                    custom-class="w-8 h-8"
                  />
                  <span v-else class="w-8 h-8">
                    <SynAvatarStatusActive
                      :id="result?.id || result?.userId"
                      is-user
                      :name="result.name"
                      :avatar="result?.avatar"
                      is-hidden-name
                      custom-class="w-8 h-8"
                    />
                  </span>

                  <div class="flex flex-col items-start">
                    <div class="flex items center space-x-2">
                      <!-- <span>{{ result?.name }}</span> -->
                      <span
                        class="text-overflow-hidden-line"
                        v-html="markTextSearch(result?.name, searchText)"
                      ></span>
                      <span v-if="result?.id === myProfile?.id">
                        ({{ $t('COMMON_LABEL_ME') || 'Me' }})
                      </span>
                      <div>
                        <SynIcon
                          v-if="result?.isGroup"
                          name="group"
                          is-active
                          custom-class="w-3 h-3 fill-gray-500"
                        />
                      </div>
                    </div>
                    <div class="flex justify-start">
                      <DepartmentByUserId
                        v-if="result?.type == 'USER'"
                        :user-id="result?.id"
                        class="italic"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-end hover-to-show__children">
                  <ActionById
                    v-if="result?.id && result?.type !== 'DOMAIN'"
                    :contact-id="result?.id"
                    :types="['chat']"
                    :type-contact="result?.isGroup ? 'group' : 'member'"
                  />
                </div>
              </RouterLink>
            </div>
          </template>
        </div>
      </div>
    </template>
  </VigDropdown>
  <div
    v-else
    class="w-8 h-8 rounded-full flex-center hover:bg-current-50 cursor-pointer"
    @click="isSearching = true"
  >
    <SynIcon
      v-if="isLoadingElement"
      name="spinner"
      custom-class="w-5 h-5 fill-current-500"
      class="animate-pulse"
    ></SynIcon>
    <SynIcon v-else name="search" custom-class="w-5 h-5 fill-current-500" />
  </div>
</template>
