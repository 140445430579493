<script setup lang="ts">
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';

const props = defineProps<{
  conversation: ChatConversationModel;
  isPreviewMessages: boolean;
}>();

defineEmits(['update:isPreviewMessages']);

const myUserId = computed(() => myProfileStore().myProfile?.id);

const externalMember = computed(() =>
  Object.entries(props.conversation?.members || {})
    .filter(([memberId]) => memberId !== `${myUserId.value}`)
    .map(([memberId, member]) => ({ ...member, id: memberId }))
    .find(() => true)
);

// const onBlockClick = () => {
//
// };
//
// const onAcceptClick = () => {
//
// };
</script>

<template>
  <!--IS PREVIEW MODE-->
  <div
    v-if="isPreviewMessages"
    class="
      flex
      items-center
      justify-between
      gap-3
      py-2
      px-4
      border-b
      bg-gray-50
    "
  >
    <div class="flex items-ce nter gap-2">
      <VigButton
        ghost
        color="gray"
        icon="back"
        @click="$emit('update:isPreviewMessages', false)"
      />
      Previewing messages
    </div>
    <div class="flex items-center gap-2 text-sm">
      <VigButton color="red" padding="px-3 py-1">
        <SynIcon name="close" />
        <span>Block</span>
      </VigButton>
      <VigButton padding="px-3 py-1">
        <SynIcon name="check" />
        <span>Accept</span>
      </VigButton>
    </div>
  </div>

  <!--NOT PREVIEW-->
  <div
    v-if="!isPreviewMessages"
    class="flex-1 overflow-hidden flex-center flex-col px-4 bg-yellow-50"
  >
    <SynAvatar
      custom-class="w-16 h-16"
      :src="externalMember?.avatar"
      :name="externalMember?.name"
    />
    <div class="text-lg text-center mt-4">
      <span class="font-semibold"> {{ externalMember?.name }}</span>
      <span class="font-semibold">
        ({{ externalMember?.externalOrgName }})</span
      >
      <span> wants to chat with you</span>
    </div>
    <div class="mt-8 flex-center gap-3 text-lg">
      <VigButton color="red">
        <SynIcon name="close" />
        <span>Block</span>
      </VigButton>
      <VigButton>
        <SynIcon name="check" />
        <span>Accept</span>
      </VigButton>
    </div>
    <div class="mt-4 text-center">
      <a
        href="javascript:;"
        class="text-current-400 hover:text-current-700"
        @click="$emit('update:isPreviewMessages', true)"
      >
        Preview messages</a
      >
    </div>
  </div>
</template>
