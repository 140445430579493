<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { CalendarReminderRepeatType } from '@/ui/common/constants/calendar';

import dayjs from 'dayjs';
import SynSelect from '@/ui/common/atoms/SynSelect/SynSelect.vue';

const props = defineProps({
  date: {
    type: String,
    default: '',
  },
  repeatNoticeChoose: {
    type: Number,
    default: 1,
  },
  widthClass: {
    type: String,
    default: 'w-full',
  },
});
const emit = defineEmits(['changeRepeatNotice']);
const dateNotice = ref(props.date);
const noticed = computed(() => props.repeatNoticeChoose);

watch(
  () => props.date,
  () => {
    dateNotice.value = props.date;
    formatLabel(props.date);
  }
);

const formatLabel = (date) => {
  noticeOption.value = [
    {
      label: translate('CALENDAR_REMINDER_NO_REPEAT'),
      time: CalendarReminderRepeatType.DoNotRepeat,
    },
    {
      label: translate('CALENDAR_REMINDER_REPEAT_DAILY'),
      time: CalendarReminderRepeatType.EveryDay,
    },
    {
      label: translate('CALENDAR_REMINDER_REPEAT_EVERYDAY'),
      time: CalendarReminderRepeatType.EveryWeekDays,
    },
    {
      label: translate('CALENDAR_REMINDER_REPEAT_DAILY_WEEK', {
        day: dayjs(date).format('dddd'),
      }),
      time: CalendarReminderRepeatType.EverySpecificDayOfWeek,
    },
    // {
    //   label: translate('CALENDAR_REMINDER_REPEAT_CUSTOM_DAY_WEEK'),
    //   time: CalendarReminderRepeatType.CustomSpecificDayOfWeek,
    // },
  ];
};

onMounted(() => {
  formatLabel(props.date);
});

const noticeOption = ref([]);

const noticeChoose = ref(noticed.value);

const onChangeOption = (value) => {
  emit('changeRepeatNotice', value);
};
</script>
<template>
  <SynSelect
    v-model="noticeChoose"
    :options="noticeOption"
    :append-to-body="true"
    key-field="time"
    text-field="label"
    :width-class="widthClass"
    @change="onChangeOption"
  />
</template>
