<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M456.832 0H55.167C24.748 0 0 24.747 0 55.166v337.4c0 30.419 24.748 55.167 55.167 55.167H185.52l59.872 59.873a15 15 0 0 0 21.214 0l59.873-59.873h130.354c30.42 0 55.168-24.748 55.168-55.167v-337.4C512 24.747 487.252 0 456.832 0zM482 392.566c0 13.877-11.29 25.167-25.168 25.167H320.266c-3.979 0-7.794 1.58-10.606 4.394l-53.661 53.66-53.659-53.66a15 15 0 0 0-10.607-4.394H55.167C41.29 417.733 30 406.443 30 392.566v-337.4C30 41.289 41.29 30 55.167 30h401.666C470.71 30 482 41.289 482 55.166z"
        opacity="1"
      ></path>
      <path
        d="m384.858 172.545-79.737-11.59-35.67-72.262a15 15 0 0 0-26.901.001l-35.667 72.261-79.743 11.59a15 15 0 0 0-8.313 25.585l57.706 56.247-13.62 79.421a15.002 15.002 0 0 0 21.764 15.813L256 312.114l71.33 37.497a14.99 14.99 0 0 0 6.978 1.723h.062c8.266-.021 14.96-6.729 14.96-15 0-1.152-.13-2.274-.375-3.352l-13.489-78.605 57.705-56.246a14.997 14.997 0 0 0 3.796-15.376 14.997 14.997 0 0 0-12.109-10.21zm-75.98 65.853a14.999 14.999 0 0 0-4.314 13.278l9.822 57.236-51.406-27.022a15.002 15.002 0 0 0-13.96 0l-51.401 27.023 9.816-57.238a15 15 0 0 0-4.314-13.276l-41.588-40.537 57.469-8.353a15 15 0 0 0 11.293-8.205l25.706-52.081 25.709 52.082a14.999 14.999 0 0 0 11.293 8.204l57.464 8.353z"
        opacity="1"
      ></path>
    </g>
  </svg>
</template>
