<script setup lang="ts">
import { onMounted, ref } from 'vue';
import {
  EBaseNotificationBarKey,
  notificationBarConfigByKey,
} from './const/base-notification-bar.const';

const props = defineProps<{
  caseId: EBaseNotificationBarKey;
  muteAt?: number;
  forceOpenAt?: number;
  isForceOpen?: boolean;
  isOnSideBar?: boolean;
}>();

const emit = defineEmits(['close']);

const isOpen = ref<boolean>(false);

let localStorageKey = '';
onMounted(() => {
  if (props.isForceOpen) {
    isOpen.value = true;
    return;
  }

  localStorageKey = `OPEN_${props.caseId?.toUpperCase()}_AGAIN_SCHEDULE_AT`;
  const isForceOpen = props.forceOpenAt && Date.now() - props.forceOpenAt >= 0;

  const openAgainScheduleAt =
    parseInt(localStorage.getItem(localStorageKey) || '') || 0;

  const isOpenAgain = Date.now() - openAgainScheduleAt >= 0;

  isOpen.value = isForceOpen || isOpenAgain;
});

const onClose = () => {
  // default 48h
  localStorage.setItem(
    localStorageKey,
    (
      Date.now() +
      (notificationBarConfigByKey[props.caseId]?.periodOfMuteTime ||
        48 * 60 * 60 * 1000)
    ).toString()
  );

  isOpen.value = false;

  emit('close');
};
</script>
<template>
  <div
    v-if="isOpen"
    class="z-10 flex-center relative w-full"
    :class="isOnSideBar ? '' : 'h-9'"
  >
    <slot></slot>

    <div
      class="absolute z-10 flex-center cursor-pointer"
      :class="
        isOnSideBar
          ? 'bg-gray-50 hover:bg-gray-100 rounded-full h-5 w-5 -right-1.5 -top-2'
          : 'h-9 w-9 right-0'
      "
      @click="onClose"
    >
      <SynIcon
        name="close-bold"
        :custom-class="'w-4 h-4 fill-gray-500'"
        size="small"
      />
    </div>
  </div>
</template>
