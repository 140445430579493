<script setup lang="ts">
import { ref, watch, computed, onMounted } from 'vue';
import { useField } from 'vee-validate';
import userStore from '@/store/user';
import settingStore from '@/store/setting';
import { adminTypeStyle } from '@/ui/common/constants/user';
import { UserCreatePayload } from '@/domain/entities/PayloadClass/UserPayloadClass';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { validateFormatDatetime } from '@/ui/hooks/commonFunction';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import AlertModal from '@/ui/components/modals/AlertModal.vue';

const props = defineProps({
  isLoading: {
    default: false,
    type: Boolean,
  },
  userInfo: {
    type: Object,
    default: () => {},
  },
});
const _userStore = userStore();
const _settingStore = settingStore();
const isLoad = ref(false);
const isInfo = ref(true);
const isReady = ref(false);
const isEdit = ref(false);
const isSaveInfo = ref(false);
const messageError = ref('');
const isShowPopupWarning = ref(false);
const currentLanguage = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';
const userDefault = computed(() => _userStore.userProfile);
const newUser = ref(new UserCreatePayload(userDefault.value));
// const adminTypeList = [
//   {
//     key: 1,
//     text: 'Admin',
//     textCode: 'COMMON_LABEL_ADMIN',
//   },
//   {
//     key: 3,
//     text: 'User',
//     textCode: 'USERMANAGEMENT_FORM_TYPE_USER',
//   },
//   {
//     key: 2,
//     text: 'Visitor',
//     textCode: 'COMMON_LABEL_VISITOR',
//   },
// ];
const adminTypeList = computed(() => Object.values(adminTypeStyle));
const position = computed(() => _userStore.position);
const timeUtc = computed(() =>
  _settingStore.timezoneList.map((tz) => {
    return {
      ...tz,
      countryNameLg: tz.countryName[currentLanguage],
      nameIcon:
        tz.countryCode === 'VN'
          ? 'flag-vietnam'
          : tz.countryCode === 'FR'
          ? 'flag-france'
          : 'flag-united-state',
    };
  })
);

const { value: addressDetail } = useField('data.addressDetail') as any;

onMounted(async () => {
  if (adminTypeList.value.length < 1) await _userStore.fetchAllProfile();
  if (position.value.length < 1) await _userStore.fetchAllPosition();
});

watch(
  () => props.isLoading,
  () => {
    isLoad.value = props.isLoading;
  }
);

const onCollapse = () => {
  if (isInfo.value) {
    isEdit.value = false;
  }
  isInfo.value = !isInfo.value;
};

const init = async () => {
  isLoad.value = true;
  await _userStore.getProfileUserCurrent();
  newUser.value = new UserCreatePayload(userDefault.value);
  isLoad.value = false;
};

init();

const onAddressSelect = (value) => {
  if (value) {
    addressDetail.value = JSON.stringify(value);
    newUser.value.address = addressDetail.value?.fullAddress
      ? addressDetail.value?.fullAddress
      : newUser.value.address;
  }
  isReady.value = true;
};

const onSaveInfoUser = async () => {
  isSaveInfo.value = true;
  if (newUser.value.firstName && newUser.value.lastName) {
    if (
      newUser.value.birthday &&
      !validateFormatDatetime(newUser.value.birthday)
    ) {
      isShowPopupWarning.value = true;
      isInfo.value = true;
      isEdit.value = true;
      messageError.value = translate('WARNING_ALERT_INVALID_FORMAT_DATETIME');
    } else {
      if (
        (newUser.value.birthday && newUser.value.birthday.indexOf('.')) ||
        (newUser.value.birthday && newUser.value.birthday.indexOf('-'))
      ) {
        newUser.value.birthday = newUser.value.birthday.replaceAll('.', '/');
        newUser.value.birthday = newUser.value.birthday.replaceAll('-', '/');
      }
      const res = await _userStore.saveProfile({
        ...newUser.value,
      });
      isSaveInfo.value = false;
      isEdit.value = false;
      if (!res.error) {
        await init();
      }
    }
  } else {
    isSaveInfo.value = false;
  }
};

const onCancel = (e) => {
  e?.stopPropagation();
  init();
  isEdit.value = false;
};

const onEdit = (e) => {
  e?.stopPropagation();
  isReady.value = false;
  isEdit.value = true;
  isInfo.value = true;
};
</script>

<template>
  <syn-tab-collapse
    class="font-medium mt-3"
    :label="$t('USERMANAGEMENT_LABEL_INFO_DIF')"
    :is-close="false"
    @on-collapse="onCollapse"
  >
    <template #edit>
      <syn-button-edit
        :is-save="isSaveInfo"
        :is-edit="isEdit"
        :is-ready="!isReady"
        @on-edit="onEdit"
        @on-cancel="onCancel"
        @on-save="onSaveInfoUser()"
      />
    </template>
  </syn-tab-collapse>
  <div v-if="isInfo" class="px-4">
    <div v-if="isLoad" class="flex-center space-x-3 mt-4">
      <div class="w-16 p-2 bg-gray-200 animate-pulse rounded-lg"></div>
      <div class="w-full p-2 bg-gray-200 animate-pulse rounded-lg"></div>
    </div>
    <div v-else>
      <!-- first name -->
      <div class="my-2 flex">
        <div class="flex items-center pr-4 w-44">
          <span
            >{{ $t('USERMANAGEMENT_TABLE_FIRST_NAME') }}
            <span class="text-red-500">*</span></span
          >
        </div>
        <div class="w-full">
          <syn-form-input
            v-if="isEdit"
            :is-valid="true"
            :error-message="
              !newUser.firstName?.trim() ? $t('COMMON_THIS_FIELD_REQUIRED') : ''
            "
          >
            <syn-input
              v-model="newUser.firstName"
              :placeholder="
                newUser.firstName || $t('USERMANAGEMENT_TABLE_FIRST_NAME')
              "
              :error-message="
                !newUser.firstName?.trim()
                  ? $t('COMMON_THIS_FIELD_REQUIRED')
                  : ''
              "
              @update:model-value="
                newUser.firstName?.trim() ? (isReady = true) : (isReady = false)
              "
            ></syn-input>
          </syn-form-input>
          <div
            v-else
            class="px-4 py-2 border-b w-full"
            :class="
              userDefault.firstName?.trim()
                ? 'font-medium'
                : 'text-gray-500 font-normal'
            "
          >
            {{
              userDefault.firstName?.trim() ||
              $t('USERMANAGEMENT_TABLE_FIRST_NAME')
            }}
          </div>
        </div>
      </div>
      <!-- last name -->
      <div class="my-2 flex">
        <div class="flex items-center pr-4 w-44">
          <span
            >{{ $t('USERMANAGEMENT_TABLE_LAST_NAME')
            }}<span class="text-red-500">*</span></span
          >
        </div>
        <div class="w-full">
          <syn-form-input
            v-if="isEdit"
            :is-valid="true"
            :error-message="
              !newUser.lastName?.trim() ? $t('COMMON_THIS_FIELD_REQUIRED') : ''
            "
          >
            <syn-input
              v-model="newUser.lastName"
              :placeholder="
                newUser.lastName || $t('USERMANAGEMENT_TABLE_LAST_NAME')
              "
              :error-message="
                !newUser.lastName?.trim()
                  ? $t('COMMON_THIS_FIELD_REQUIRED')
                  : ''
              "
              @update:model-value="
                newUser.lastName?.trim() ? (isReady = true) : (isReady = false)
              "
            ></syn-input>
          </syn-form-input>
          <div
            v-else
            class="text-gray-800 px-4 py-2 font-medium border-b w-full"
            :class="
              userDefault.lastName?.trim()
                ? 'font-medium'
                : 'text-gray-500 font-normal'
            "
          >
            {{
              userDefault.lastName?.trim() ||
              $t('USERMANAGEMENT_TABLE_LAST_NAME')
            }}
          </div>
        </div>
      </div>
      <!-- Admin Type -->
      <div v-permission-model class="my-2 flex">
        <div class="flex items-center pr-4 w-44">
          <span
            >{{ $t('USERMANAGEMENT_FORM_ADMIN_TYPE')
            }}<span class="text-red-500">*</span></span
          >
        </div>
        <div class="w-full">
          <syn-select
            v-if="isEdit"
            v-model="newUser.adminType"
            class="w-full"
            key-field="key"
            text-field="labelKey"
            is-translate
            :is-disable="true"
            key-item-not-show="code"
            :value-item-not-show="['MANAGER']"
            width-class="w-full"
            :placeholder="
              adminTypeList?.find((el) => el.key === newUser.adminType)
                ? $t(
                    adminTypeList?.find((el) => el.key === newUser.adminType)
                      ?.labelKey
                  )
                : $t('USERMANAGEMENT_FORM_ADMIN_TYPE')
            "
            :options="adminTypeList"
            @update:model-value="newUser.adminType"
            @change="isReady = true"
          ></syn-select>
          <div v-else class="px-4 py-2 border-b w-full font-medium">
            {{
              adminTypeList?.find((el) => el.key === userDefault.adminType)
                ? $t(
                    adminTypeList?.find(
                      (el) => el.key === userDefault.adminType
                    )?.labelKey
                  )
                : $t('USERMANAGEMENT_FORM_ADMIN_TYPE')
            }}
          </div>
        </div>
      </div>
      <!-- position -->
      <!-- <div class="my-2 flex">
        <div class="flex items-center text-sm pr-4 text-current-800 w-44">
          <span>{{ $t('USERMANAGEMENT_TABLE_POSITION') }}</span>
        </div>
        <syn-select
          v-if="isEdit"
          class="w-full"
          key-field="id"
          text-field="name"
          v-model="user.positionId"
          :is-disable="user.adminType !== AdminType.Admin"
          width-class="w-full text-sm"
          :placeholder="
            position?.find(el => el.id == user.positionId)
              ? position?.find(el => el.id == user.positionId)?.name
              : $t('USERMANAGEMENT_TABLE_POSITION')
          "
          :options="position"
          @update:model-value="user.positionId"
          @change="isReady = true"
        >
        </syn-select>
        <div v-else class="text-gray-800 font-normal px-4 py-1 border-b w-full">
          {{
            position?.find(el => el.id == user.positionId)
              ? position?.find(el => el.id == user.positionId)?.name
              : $t('USERMANAGEMENT_TABLE_POSITION')
          }}
        </div>
      </div> -->
      <!-- dob -->
      <div class="my-2 flex">
        <div class="flex items-center pr-4 w-44">
          <span>{{ $t('USERMANAGEMENT_TABLE_DOB') }}</span>
        </div>
        <div class="w-full">
          <syn-date
            v-if="isEdit"
            min-width="100%"
            :model-value="newUser.birthday"
            @change="
              (value) => {
                newUser.birthday = value;
                isReady = true;
              }
            "
          ></syn-date>
          <div
            v-else
            class="text-gray-800 px-4 py-2 font-medium border-b w-full"
            :class="
              userDefault.birthday ? 'font-medium' : 'text-gray-500 font-normal'
            "
          >
            {{ userDefault.birthday || $t('USERMANAGEMENT_TABLE_DOB') }}
          </div>
        </div>
      </div>
      <!-- address -->
      <div class="mt-2 flex">
        <div class="flex items-center pr-4 w-44">
          <span>{{ $t('USERMANAGEMENT_TABLE_ADDRESS') }}</span>
        </div>
        <syn-form-input v-if="isEdit">
          <vig-address-box
            v-model="newUser.address"
            :placeholder="address || $t('USERMANAGEMENT_TABLE_ADDRESS')"
            class="vig-input"
            @change="isReady = true"
            @on-address-select="onAddressSelect"
          />
        </syn-form-input>

        <div
          v-else
          class="text-gray-800 px-4 py-2 font-medium border-b w-full"
          :class="
            userDefault.address ? 'font-medium' : 'text-gray-500 font-normal'
          "
        >
          {{ userDefault.address || $t('USERMANAGEMENT_TABLE_ADDRESS') }}
        </div>
      </div>
      <!-- IDENTITY_CARD -->
      <div v-permission-model class="my-2 flex">
        <div class="flex items-center pr-4 w-44">
          <span>{{ $t('USER_FORM_IDENTITY_CARD') }}</span>
        </div>
        <syn-input
          v-if="isEdit"
          v-model="newUser.identityCard"
          :placeholder="newUser.identityCard || $t('USER_FORM_IDENTITY_CARD')"
          @update:model-value="isReady = true"
        ></syn-input>
        <div
          v-else
          class="text-gray-800 px-4 py-2 font-medium border-b w-full"
          :class="
            userDefault.identityCard
              ? 'font-medium'
              : 'text-gray-500 font-normal'
          "
        >
          {{ userDefault.identityCard || $t('USER_FORM_IDENTITY_CARD') }}
        </div>
      </div>
      <!-- Skyper -->
      <!-- <div class="my-2 flex">
        <div class="flex items-center text-sm pr-4 text-current-800 w-44">
          <span>{{ $t('USERMANAGEMENT_FORM_SKYPE') }}</span>
        </div>
        <syn-input
          v-if="isEdit"
          v-model="user.skyper"
          @update:model-value="isReady = true"
          :placeholder="user.skyper || $t('USERMANAGEMENT_FORM_SKYPE')"
        ></syn-input>
        <div v-else class="text-gray-800 font-normal px-4 py-1 border-b w-full">
          {{ user.skyper || $t('USERMANAGEMENT_FORM_SKYPE') }}
        </div>
      </div> -->
      <!-- time utc -->
      <div class="my-2 flex">
        <div class="flex items-center pr-4 w-44">
          <span
            >{{ $t('USERMANAGEMENT_FORM_TIME_UTC')
            }}<span class="text-red-500">*</span></span
          >
        </div>
        <syn-select
          v-if="isEdit"
          v-model="newUser.utc"
          class="w-full"
          key-field="utc"
          text-field="countryNameLg"
          name-icon="nameIcon"
          width-class="w-full"
          :is-icon-beside-label="true"
          :is-select-flags="true"
          :placeholder="
            newUser.utc
              ? timeUtc.find((element) => element.utc === newUser.utc)
                  ?.countryNameLg
              : $t('USERMANAGEMENT_FORM_TIME_UTC')
          "
          :options="timeUtc"
          @update:model-value="
            (value) => {
              newUser.utc = value;
              isReady = true;
            }
          "
          @change="isReady = true"
        >
        </syn-select>
        <div
          v-else
          class="flex items-center px-4 py-2 font-medium border-b w-full"
        >
          <SynIcon
            class="mr-2"
            :name="
              userDefault?.utc
                ? timeUtc?.find((element) => element.utc === userDefault.utc)
                    ?.nameIcon
                : ''
            "
          />
          <span>{{
            userDefault?.utc
              ? timeUtc?.find((element) => element.utc === userDefault.utc)
                  ?.countryNameLg
              : $t('USERMANAGEMENT_FORM_TIME_UTC')
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <alert-modal
    v-if="isShowPopupWarning"
    :title-text="$t('COMMON_LABEL_BE_WARNED')"
    :sub-title-text="messageError"
    container-class="w-1/4"
    name-icon="warning"
    :container-color="'orange'"
    @cancel="(value) => (isShowPopupWarning = value)"
  />
</template>
