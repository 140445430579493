import { checkPermissionByFunction } from '@/ui/hooks/permission/permission-by-function';
import {
    settingGlobalModal,
    ask,
    tell,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { EFunctionPermission } from '@/application/types/block-function.types';
import plansStore from '@/store/plans';
import { getUiByPlanState } from '@/application/constants/plan.const';
// import router from '../router';
import myProfileStore from '@/store/auth/my-profile';
import { AdminType } from '../common/constants/constant';

export default {
    install: (app) => {
        app.config.globalProperties.$permissionFunction = (functionName) => {
            return checkPermissionByFunction(functionName);
        };
        app.provide('permissionFunction', (functionName) => {
            return checkPermissionByFunction(functionName);
        });
        app.directive('permission-function', {
            mounted(element, binding) {
                if (!element) return;

                const functionCode = binding.value?.functionCode;
                const neverHidden = binding.value?.neverHidden;
                const needToShowPremiumIcon =
                    binding.value?.needToShowPremiumIcon;
                if (!functionCode) return;

                const permission = checkPermissionByFunction(functionCode);

                if (neverHidden && permission !== EFunctionPermission.ALLOW) {
                    element.style.position = 'relative';
                    const div = document.createElement('div');
                    div.style.position = 'absolute';
                    div.style.cursor = 'pointer';
                    div.style.width = '100%';
                    div.style.height = '100%';
                    div.style.zIndex = '999';
                    div.style.top = '0';
                    div.style.left = '0';

                    element.appendChild(div);

                    if (needToShowPremiumIcon) addPremiumIcon(element);
                    div.addEventListener('click', async () => {
                        // event.preventDefault();
                        // event.stopPropagation();

                        const uiByPlanState = _getUiByPlanState();
                        const currentOrgPlan = plansStore().currentOrgPlan;

                        const message = translate(
                            uiByPlanState?.message?.lockFunction
                                ?.warningMessage ||
                                uiByPlanState?.message?.title ||
                                'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                            {
                                planId: currentOrgPlan?.planId,
                            }
                        );
                        settingGlobalModal({
                            type: 'notification',
                            title: translate('COMMON_LABEL_ADVANCE_FEATURE'),
                            content: message,
                            closeable: true,
                            confirmLabel: translate('PAYMENT_LABEL_PURCHASE'),
                            confirmable: true,
                            iconName: 'info',
                            footerButtons: [
                                {
                                    type: 'TEXT',
                                    labelCode:
                                        'PLAN_NOTIFICATION_MESSAGE_M2_LOCK_FUNCTION_BLOCK_CANCEL_BUTTON',
                                    color: 'gray',
                                    onClick: () => {
                                        if (
                                            binding.value?.onContinue &&
                                            typeof binding.value?.onContinue ==
                                                'function'
                                        )
                                            binding.value?.onContinue();

                                        tell(false);
                                    },
                                },
                                {
                                    type: 'FILL',
                                    labelCode: 'PAYMENT_LABEL_PURCHASE',
                                    color: 'current',
                                    iconName: 'payment',
                                    onClick: () => {
                                        const myProfile =
                                            myProfileStore().myProfile;
                                        let paymentRouteName =
                                            '/system-notification/no-permission';
                                        if (
                                            myProfile?.adminType ==
                                                AdminType.Admin ||
                                            myProfile?.adminType ==
                                                AdminType.Owner
                                        )
                                            paymentRouteName =
                                                '/settings/plans';
                                        // router.push({ name: paymentRouteName });
                                        window.open(paymentRouteName);
                                        tell(true);
                                    },
                                },
                            ],
                        });
                        ask();
                    });
                } else {
                    switch (permission) {
                        case EFunctionPermission.WARNING:
                            {
                                element.style.position = 'relative';
                                const div = document.createElement('div');
                                div.style.position = 'absolute';
                                div.style.cursor = 'pointer';
                                div.style.width = '100%';
                                div.style.height = '100%';
                                div.style.zIndex = '999';
                                div.style.top = '0';
                                div.style.left = '0';

                                element.appendChild(div);

                                if (needToShowPremiumIcon)
                                    addPremiumIcon(element);

                                div.addEventListener('click', async (event) => {
                                    event.stopPropagation();
                                    event.preventDefault();

                                    const uiByPlanState = _getUiByPlanState();
                                    const currentOrgPlan =
                                        plansStore().currentOrgPlan;

                                    const message = translate(
                                        uiByPlanState?.message?.lockFunction
                                            ?.warningMessage ||
                                            uiByPlanState?.message?.title ||
                                            'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                                        {
                                            planId: currentOrgPlan?.planId,
                                        }
                                    );
                                    settingGlobalModal({
                                        type: 'notification',
                                        title: translate(
                                            'COMMON_LABEL_ADVANCE_FEATURE'
                                        ),
                                        content: message,
                                        confirmable: true,
                                        iconName: 'info',
                                        footerButtons: [
                                            {
                                                type: 'TEXT',
                                                labelCode:
                                                    'PLAN_NOTIFICATION_MESSAGE_M2_LOCK_FUNCTION_BLOCK_CANCEL_BUTTON',
                                                color: 'gray',
                                                onClick: () => {
                                                    if (
                                                        binding.value
                                                            ?.onContinue &&
                                                        typeof binding.value
                                                            ?.onContinue ==
                                                            'function'
                                                    ) {
                                                        binding.value?.onContinue();
                                                    }
                                                    tell(false);
                                                },
                                            },
                                            {
                                                type: 'FILL',
                                                labelCode:
                                                    'PAYMENT_LABEL_PURCHASE',
                                                color: 'current',
                                                iconName: 'payment',
                                                onClick: () => {
                                                    const myProfile =
                                                        myProfileStore()
                                                            .myProfile;
                                                    let paymentRouteName =
                                                        '/system-notification/no-permission';
                                                    if (
                                                        myProfile?.adminType ==
                                                            AdminType.Admin ||
                                                        myProfile?.adminType ==
                                                            AdminType.Owner
                                                    )
                                                        paymentRouteName =
                                                            '/settings/plans';
                                                    // router.push({ name: paymentRouteName });
                                                    window.open(
                                                        paymentRouteName
                                                    );
                                                    tell(true);
                                                },
                                            },
                                        ],
                                    });
                                    ask();
                                });
                            }
                            break;
                        case EFunctionPermission.BLOCK:
                            {
                                // const newElement = element.cloneNode(true);
                                const newElement = element?.cloneNode(true);

                                const newParentElement =
                                    document.createElement('div');

                                newElement.inert = true;

                                newParentElement.style.position = 'relative';
                                newParentElement.appendChild(newElement);

                                const para = document.createElement('div');
                                para.style.position = 'absolute';
                                para.style.cursor = 'pointer';
                                para.style.zIndex = '9999';
                                para.style.top = '0px';
                                para.style.left = '0px';
                                para.style.width = '100%';
                                para.style.height = '100%';
                                para.style.cursor = 'pointer';
                                const handleAction = async (event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    // const uiByPlanState =
                                    //     _getUiByPlanState();
                                    // const currentOrgPlan =
                                    //     plansStore().currentOrgPlan;

                                    // const message = translate(
                                    //     uiByPlanState?.message?.lockFunction
                                    //         ?.blockMessage ||
                                    //         uiByPlanState?.message?.title ||
                                    //         'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                                    //     {
                                    //         planId: currentOrgPlan?.planId,
                                    //     }
                                    // );
                                    settingGlobalModal({
                                        type: 'notification',
                                        title: translate(
                                            'COMMON_LABEL_ADVANCE_FEATURE'
                                        ),
                                        content: translate(
                                            'COMMON_LABEL_BLOCK_FUNCTION_DESCRIPTION'
                                        ),
                                        iconName: 'lock',
                                        footerButtons: [
                                            {
                                                type: 'TEXT',
                                                labelCode: 'COMMON_LABEL_CLOSE',
                                                color: 'gray',
                                                onClick: () => {
                                                    tell(false);
                                                },
                                            },
                                            {
                                                type: 'FILL',
                                                labelCode:
                                                    'COMMON_LABEL_PLAN_MANAGE',
                                                color: 'current',
                                                iconName: 'payment',
                                                onClick: () => {
                                                    const myProfile =
                                                        myProfileStore()
                                                            .myProfile;
                                                    let paymentRouteName =
                                                        '/system-notification/no-permission';
                                                    if (
                                                        myProfile?.adminType ==
                                                            AdminType.Admin ||
                                                        myProfile?.adminType ==
                                                            AdminType.Owner
                                                    )
                                                        paymentRouteName =
                                                            '/settings/plans';

                                                    window.open(
                                                        paymentRouteName
                                                    );
                                                    tell(true);
                                                },
                                            },
                                        ],
                                    });
                                    ask();
                                };
                                para.addEventListener('click', handleAction);
                                para.addEventListener('focus', handleAction);
                                newParentElement.appendChild(para);

                                if (needToShowPremiumIcon)
                                    addPremiumIcon(newElement);

                                element?.parentNode?.replaceChild(
                                    newParentElement,
                                    element
                                );
                            }
                            break;

                        default:
                            break;
                    }
                }
            },
        });
    },
};

const _getUiByPlanState = () => {
    const currentOrgPlan = plansStore().currentOrgPlan;

    const subState = currentOrgPlan.subStateCode || 'DEFAULT';
    return getUiByPlanState(currentOrgPlan.planId, currentOrgPlan.stateCode)?.[
        subState
    ];
};

const premiumIconString = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g fill="#ffb743"><path d="M2.837 20.977 1.012 9.115c-.135-.876.863-1.474 1.572-.942l5.686 4.264a1.359 1.359 0 0 0 1.945-.333l4.734-7.1c.5-.75 1.602-.75 2.102 0l4.734 7.1a1.359 1.359 0 0 0 1.945.333l5.686-4.264c.71-.532 1.707.066 1.572.942l-1.825 11.862zM27.79 27.559H4.21a1.373 1.373 0 0 1-1.373-1.373v-3.015h26.326v3.015c0 .758-.615 1.373-1.373 1.373z" fill="#ffb743" opacity="1" data-original="#ffb743" class=""></path></g></g></svg>`;

const addPremiumIcon = (element) => {
    const premiumIcon = document.createElement('div');
    premiumIcon.style.position = 'absolute';
    premiumIcon.style.right = '10px';
    premiumIcon.style.width = '0.85rem';
    premiumIcon.style.height = '0.85rem';
    premiumIcon.style.background = 'white';
    premiumIcon.innerHTML = premiumIconString;

    element.style.display = 'flex';
    element.style.alignItems = 'center';
    element.appendChild(premiumIcon);
};
