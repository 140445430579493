<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import {
  StorageConstant,
  setLocalStorage,
  getLocalStorage,
  getCurrentUserId,
  getCurrentOrganizationId,
} from '@/ui/hooks/storageHook';
import appStore from '@/store/app';
import taskStore from '@/store/task';
import commonStore from '@/store/common/commonStore';
import { onChangeI18n, translate } from '@/ui/plugins/i18n/myi18n';
import { openNotification } from '@/ui/hooks/commonHook';
import { fetchGroupPersonalSetting } from './settingState';
import { updateDeviceToken } from '@/ui/plugins/firebases/firebaseFcm';
import { CurrentUserConfig } from '@/ui/common/constants/constant';
import myProfileStore from '@/store/auth/my-profile';
import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import { ETaskViewType } from '@/application/constants/task.const';
import { PERSONAL_SETTING_BY_KEYS } from '@/application/constants/system/common-settings.const';
import permissionStore from '@/store/permission';

const _appStore = appStore();
const _taskStore = taskStore();
const _commonStore = commonStore();

const userConfigByKeys = computed<any>(() => {
  return _commonStore.userConfigByKeys;
});
const currentViewTaskListDefault = computed<string>(() => {
  if (
    !userConfigByKeys.value ||
    !Object.prototype.hasOwnProperty.call(
      userConfigByKeys.value,
      CurrentUserConfig.ViewTaskListDefault
    )
  )
    return ETaskViewType.LIST;

  return (
    userConfigByKeys.value[CurrentUserConfig.ViewTaskListDefault] ||
    ETaskViewType.LIST
  );
});
const languageList = [
  {
    key: 'fr',
    keyName: 'SETTING_LANGUAGE_LABEL_SEARCH',
    defaultName: 'French',
    flagName: 'FlagFrance',
  },
  {
    key: 'vi',
    keyName: 'SETTING_LANGUAGE_LABEL_VIETNAMESE',
    defaultName: 'Vietnamese',
    flagName: 'FlagVietnam',
  },
  {
    key: 'en',
    keyName: 'SETTING_LANGUAGE_LABEL_ENGLISH',
    defaultName: 'English',
    flagName: 'FlagUnitedState',
  },
];
// const adminPermission = inject('permission');

// const languages = computed(
//   () => store.getters['common/getSystemLanguages']
// );

const currentUser = computed(() => myProfileStore().myProfile);

const locationByDomain = computed(() =>
  getLocalStorage(StorageConstant.LOCATION)
);
const isVisitor = computed(() => myProfileStore().isVisitor);
const isAdmin = computed(
  () => myProfileStore().isAdmin || myProfileStore().isOwner
);

const userWorkingStatus = computed<boolean>(
  () => userConfigByKeys.value[CurrentUserConfig.NotifyWorkingStatus] || false
);
const filterPublicGroup = computed<boolean>(() => {
  return !!userConfigByKeys.value[CurrentUserConfig.FILTER_PUBLIC_GROUP];
});

const currentLanguage = ref('');
onMounted(() => {
  currentLanguage.value = getLocalStorage(StorageConstant.LANGUAGE);
  onInitData();
});
const onInitData = () => {
  // if (adminPermission()) {
  //   fetchGroupPersonalSetting();
  // }
  fetchGroupPersonalSetting();
};
const onChangeLanguage = (item) => {
  const currentLang = item.key;
  currentLanguage.value = currentLang;
  setLocalStorage(StorageConstant.LANGUAGE, currentLang);
  _appStore.setLanguage(currentLang);
  onChangeI18n(currentLang);
  updateDeviceToken(currentLang);

  // confirmBody.value = `Loading page to apply new language?`;
  // const confirmed = await ask();
  // if (confirmed) {
  //   location.reload();
  // }
  // 2. update data config by lang
  _taskStore.getDataConfig();
  onInitData();
  // 3. push notification to
  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('COMMON_LABEL_CHANGED_LANGUAGE'),
  });
};
const onChangeGroupPersonalSetting = async () => {
  await _commonStore.updateCurrentUserSetting({
    id: 0,
    userId: getCurrentUserId(),
    organizationId: getCurrentOrganizationId(),
    key: CurrentUserConfig.FILTER_PUBLIC_GROUP,
    value: !filterPublicGroup.value,
  });
};

const onChangeNotifyWorkingStatus = async () => {
  await _commonStore.updateCurrentUserSetting({
    id: 0,
    userId: getCurrentUserId(),
    organizationId: getCurrentOrganizationId(),
    key: CurrentUserConfig.NotifyWorkingStatus,
    value: !userWorkingStatus.value,
  });
};

const listCustomize = {
  LIST: {
    key: 'LIST',
    nameIcon: 'view-list',
    label: 'TASK_LIST_VIEW_LIST_LABEL',
  },
  BOARD: {
    key: 'BOARD',
    nameIcon: 'view-board',
    label: 'TASK_LIST_VIEW_BOARD_LABEL',
  },
  CALENDAR: {
    key: 'CALENDAR',
    nameIcon: 'view-calendar',
    label: 'TASK_LIST_VIEW_CALENDAR_LABEL',
  },
};

const uploadingKey = ref<string>('');
const onChangeViewTaskMode = async (viewModeObj) => {
  if (!viewModeObj?.key || uploadingKey.value) return;

  try {
    uploadingKey.value = viewModeObj?.key;
    await commonStore().updateCurrentUserSetting({
      userId: myProfileStore().myProfile?.id,
      organizationId: myProfileStore().myProfile?.organizationId,
      key: CurrentUserConfig.ViewTaskListDefault,
      value: viewModeObj?.key,
    });
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    uploadingKey.value = '';
  }
};

const personalSettingList = computed<any>(() => {
  return Object.values(PERSONAL_SETTING_BY_KEYS);
});

const onChangePersonalSetting = (key, _value) => {
  commonStore().setCurrentUserSetting({
    key,
    value: _value ? '1' : '0',
  });
};
const isPersonalModel = computed<any>(() => {
  return permissionStore().organizationModel == 'PERSONAL';
});
</script>

<template>
  <div class="w-full h-full flex flex-col bg-white">
    <!--  My Profile    -->
    <div class="px-6 pt-6 pb-4 flex items-center space-x-2">
      <SynAvatar
        :src="currentUser?.avatarUrl.replace('original', 'small')"
        custom-class="w-12 h-12"
      />
      <div class="flex flex-col">
        <span class="text-gray-700 font-medium">{{
          currentUser?.fullName
        }}</span>
        <span class="text-xs text-gray-500">{{
          currentUser?.email || currentUser?.phone
        }}</span>
      </div>
      <a
        class="
          flex-center
          cursor-pointer
          border
          rounded
          border-current-500
          p-1
          text-current-500 text-2xs
          hover:bg-current-50
          hover:ring
          hover:ring-current
          hover:ring-opacity-30
        "
        href="/profile"
      >
        View profile
      </a>
    </div>

    <!-- LANGUAGE     -->
    <MenuSettingRowItem
      name-icon="language"
      :title-menu="$t('SETTING_LABEL_SET_LANGUAGE')"
      :sub-title="$t('SETTING_LABEL_SET_LANGUAGE_DES')"
    >
      <template #action>
        <div class="flex space-x-2">
          <div
            v-for="(item, index) in languageList.filter((el) =>
              locationByDomain !== 'EU' ? el : el?.key !== 'vi'
            )"
            :key="index"
            class="w-max"
          >
            <SynCheckboxLabel
              :is-check="item.key == currentLanguage"
              :text="$t(item.keyName) || item.name"
              @click="onChangeLanguage(item)"
            >
              <template #label>
                <div class="flex-center space-x-2">
                  <SynIcon :name="item.flagName" />
                  <span>{{ $t(item.keyName) || item.name }}</span>
                </div>
              </template>
            </SynCheckboxLabel>
          </div>
        </div>
      </template>
    </MenuSettingRowItem>

    <!--  Show private group    -->
    <MenuSettingRowItem
      v-if="!isVisitor && !isPersonalModel"
      name-icon="lock"
      :title-menu="$t('SETTING_PERSONAL_HIDE_PUBLIC_GROUP_TITLE')"
      :sub-title="$t('SETTING_PERSONAL_HIDE_PUBLIC_GROUP_SUBTITLE')"
    >
      <template #action>
        <AtomSwitch
          :model-value="filterPublicGroup"
          @change="onChangeGroupPersonalSetting"
        />
      </template>
    </MenuSettingRowItem>

    <!--  Show Notification WD    -->
    <MenuSettingRowItem
      v-if="isAdmin && !isPersonalModel"
      name-icon="bell"
      :title-menu="$t('SETTING_PERSONAL_NOTIFY_WORKING_STATUS_TITLE')"
      :sub-title="$t('SETTING_PERSONAL_NOTIFY_WORKING_STATUS_SUBTITLE')"
    >
      <template #action>
        <AtomSwitch
          :model-value="userWorkingStatus"
          @change="onChangeNotifyWorkingStatus"
        />
      </template>
    </MenuSettingRowItem>
    <!--  Show task list default    -->
    <MenuSettingRowItem
      name-icon="eye-open"
      :title-menu="$t('COMMON_LABEL_TASK_LIST_VIEW_MODE')"
      :sub-title="$t('COMMON_LABEL_TASK_LIST_VIEW_MODE_DESCRIPTION')"
    >
      <template #action>
        <div class="flex-center gap-2">
          <div
            v-for="item in listCustomize"
            :key="item?.key"
            class="flex-center space-x-2"
          >
            <SynCheckboxLabel
              class="ml-1 text-xs"
              :is-check="item.key == currentViewTaskListDefault"
              :text="$t(item?.label)"
              :is-loading="item.key == uploadingKey"
              @click="onChangeViewTaskMode(item)"
            />
          </div>
        </div>
      </template>
    </MenuSettingRowItem>

    <!--  Show private group    -->
    <template v-if="!isPersonalModel">
      <MenuSettingRowItem
        v-for="item in personalSettingList"
        :key="item?.key"
        :name-icon="item?.iconName"
        :title-menu="$t(item?.titleCode)"
        :sub-title="$t(item?.subTitleCode)"
      >
        <template #action>
          <AtomSwitch
            :model-value="userConfigByKeys[item?.key]"
            @change="(value) => onChangePersonalSetting(item?.key, value)"
          />
        </template>
      </MenuSettingRowItem>
    </template>
  </div>
</template>
