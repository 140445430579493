<script setup lang="ts">
import SynModal from '@/ui/common/molecules/SynModal/SynModal.vue';
// import DatePicker from 'vue-datepicker-next';
// import 'vue-datepicker-next/index.css';
import { computed, onMounted, ref } from 'vue';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
// import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';
import dayjs from 'dayjs';
import principleStore from '@/store/principle';
import ModalSelectUserToExport from '@/ui/pages/overview/working-time/ModalSelectUserToExport.vue';
import { saveAs } from 'file-saver';
import {
  ExportOptions,
  SignType,
} from '@/ui/pages/overview/working-time/principle-const';
import ModalSelectColumns from '@/ui/pages/overview/working-time/ModalSelectColumns.vue';
import SynListAvatar from '@/ui/common/molecules/SynAvatar/SynListAvatar.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';

const emit = defineEmits(['onClose']);

const props = defineProps<{
  overviewList: [];
}>();

const _principleStore = principleStore();

const selectedMonth = ref<string>(dayjs().format('MM-YYYY'));
const userList = ref(JSON.parse(JSON.stringify(props.overviewList)) as any);
const isLoading = ref(false);
const isLoadingWhenExport = ref(false);
const timeKeepingList = computed(() => _principleStore.timeKeepingList);
const numberDateOfMonth = computed(
  () => timeKeepingList.value[0]?.timekeepings.length
);
const isEditSelectUser = ref(false);
const isSelectColumns = ref(false);
const exportOption = ref(ExportOptions.Day);

const weekendList = computed(() => {
  const month = parseInt(selectedMonth.value.split('-')[0]);
  const year = parseInt(selectedMonth.value.split('-')[1]);
  const startDate = dayjs(`${year}-${month}-01`); // Ngày đầu tiên của tháng
  const endDate = startDate.endOf('month'); // Ngày cuối cùng của tháng

  const weekendDays = [];
  let currentDate = startDate;

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    if (currentDate.day() === 6 || currentDate.day() === 0) {
      weekendDays.push(currentDate.toISOString());
    }
    currentDate = currentDate.add(1, 'day');
  }

  return weekendDays.map((e) => dayjs(e)?.date());
});

onMounted(() => {
  init();
});

const init = async () => {
  const payload = {
    month: selectedMonth.value.split('-').reverse().join('-'),
    userIds: userList.value.map((e: any) => e.userId),
    exportOption: exportOption.value,
  };
  isLoading.value = true;
  await _principleStore.getExportTimekeepings(payload);
  isLoading.value = false;
};

const onChangeDate = () => {
  if (!selectedMonth.value) return;
  init();
};

const onUpdateSelectUser = (value) => {
  userList.value = value;
  init();
};

const onExportFile = async (data) => {
  if (!selectedMonth.value) return;
  isLoadingWhenExport.value = true;
  try {
    const payload = {
      month: selectedMonth.value.split('-').reverse().join('-'),
      userIds: userList.value.map((e: any) => e.userId),
      columns: data,
      exportOption: exportOption.value,
    };
    const res = await _principleStore.exportTimekeepings(payload);
    saveAs(res?.result, 'Bảng-chấm-công.xlsx');
    isLoadingWhenExport.value = false;
    emit('onClose');
  } catch (err) {
    console.log('File: ModalExportWorkingTimeFile.vue - L: 97 - ', err);
    isLoadingWhenExport.value = false;
  }
};

const selectOptions = (option) => {
  if (exportOption.value == option) return;
  exportOption.value = option;
  init();
};
</script>

<template>
  <SynModal
    disable-click-outside
    z-index="z-40"
    container-style="height: 95vh; max-height:unset; width: 98vw;"
    background-body="bg-gray-50"
    header-class="z-50 p-4"
    style-body="pt-4 flex flex-col relative"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="flex-1 flex items-center justify-between">
        <div class="">
          <span class="text-xl text-gray-500 font-medium uppercase">{{
            $t('COMMON_LABEL_TIMESHEETS')
          }}</span>
          <!--          <span v-if="date" class="text-gray-500 italic text-xs">{{-->
          <!--            $t('COMMON_LABEL_MONTH_YEAR', {-->
          <!--              month: dayjs(date).month() + 1,-->
          <!--              year: dayjs(date).year(),-->
          <!--            })-->
          <!--          }}</span>-->
        </div>
        <div class="flex items-center space-x-2 text-gray-500">
          <span>{{ $t('COMMON_TIMEKEEPING_LABEL_MONTH') }}</span>
          <div class="" style="width: 50%">
            <AtomMonthPicker
              v-model="selectedMonth"
              format="MM-YYYY"
              placeholder="MM-YYYY"
              @update:model-value="onChangeDate"
            />
          </div>
        </div>

        <!--        <div class="flex flex-col space-y-2">-->
        <div class="flex items-center space-x-5">
          <div class="flex items-center space-x-2">
            <input
              type="radio"
              name="default-radio"
              :checked="exportOption == ExportOptions.Day"
              class="
                w-6
                h-6
                text-current-500
                bg-gray-100
                border-gray-300
                cursor-pointer
              "
              @click="selectOptions(ExportOptions.Day)"
            />
            <label
              class="text-base"
              :class="
                exportOption == ExportOptions.Day
                  ? 'text-current-500'
                  : 'text-gray-500'
              "
              >{{
                $t('COMMON_LABEL_TIMEKEEPING_BY_DAY' || 'Timekeeping by day')
              }}</label
            >
          </div>
          <div class="flex items-center space-x-2">
            <input
              type="radio"
              name="default-radio"
              :checked="exportOption == ExportOptions.Hour"
              class="
                w-6
                h-6
                text-current-500
                bg-gray-100
                border-gray-300
                cursor-pointer
              "
              @click="selectOptions(ExportOptions.Hour)"
            />
            <label
              class="text-base"
              :class="
                exportOption == ExportOptions.Hour
                  ? 'text-current-500'
                  : 'text-gray-500'
              "
              >{{
                $t('COMMON_LABEL_TIMEKEEPING_BY_HOUR' || 'Timekeeping by hour')
              }}</label
            >
          </div>
        </div>
        <!--        </div>-->
        <div class="flex flex-col min-h-0 border rounded">
          <div
            class="flex-1 min-h-0 p-2 rounded bg-white space-y-2 flex flex-col"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <SynListAvatar :users="userList" :max-length="5" />
              </div>
              <div class="flex-center pl-4">
                <div
                  class="
                    w-6
                    h-6
                    cursor-pointer
                    rounded-full
                    hover:bg-current-100
                    flex-center
                  "
                  @click="isEditSelectUser = true"
                >
                  <SynIcon
                    name="edit"
                    is-active
                    custom-class="w-4 h-4 fill-current text-current-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex-1 flex flex-col min-h-0 px-4 pb-4">
        <div
          class="
            flex-1
            overflow-auto
            small-scrollbar
            bg-white
            border
            shadow-sm
            rounded
          "
        >
          <template v-if="!selectedMonth">
            <div class="w-full flex-center mt-5">
              <span class="text-gray-500 italic">{{
                $t('COMMON_LABEL_SELECT_TIME')
              }}</span>
            </div>
          </template>
          <template v-else-if="isLoading">
            <div class="w-full flex-center mt-5">
              <SynIcon
                name="spinner"
                class="fill-current border-none animate-spin"
                custom-class="w-6 h-6"
              />
            </div>
          </template>
          <template v-else>
            <syn-table-custom class="rounded" style="width: 100%">
              <template #header>
                <thead class="sticky top-0" style="z-index: 1">
                  <SynTr :is-show-border="false">
                    <SynTh
                      rowspan="2"
                      :label="'TT'"
                      is-center
                      style="width: 40px"
                    ></SynTh>
                    <SynTh
                      rowspan="2"
                      :label="$t('COMMON_LABEL_MEMBERS')"
                      class="border-l"
                      is-center
                    ></SynTh>
                    <SynTh
                      :colspan="numberDateOfMonth"
                      is-center
                      :label="$t('COMMON_LABEL_DAY_OF_MONTH')"
                      class="border-l border-b border-gray-150"
                    ></SynTh>
                    <SynTh
                      rowspan="2"
                      :label="$t('COMMON_LABEL_TOTAL')"
                      is-wrap
                      class="border-l border-gray-150"
                      is-center
                    ></SynTh>
                    <SynTh
                      rowspan="2"
                      :label="$t('COMMON_MODULE_DAYOFF')"
                      class="border-l border-gray-150"
                      is-center
                      is-wrap
                    ></SynTh>
                    <SynTh
                      rowspan="2"
                      :label="$t('DAYOFF_TYPE_WFH')"
                      class="border-l border-gray-150"
                      is-center
                      is-wrap
                    ></SynTh>
                    <SynTh
                      rowspan="2"
                      :label="$t('DAYOFF_TYPE_EXCEPTION')"
                      class="border-l border-gray-150"
                      is-center
                      is-wrap
                    ></SynTh>
                  </SynTr>
                  <SynTr>
                    <template
                      v-for="(item, index) in numberDateOfMonth"
                      :key="index"
                    >
                      <SynTh
                        :label="String(index + 1)"
                        is-center
                        class="border-l border-gray-150"
                        :style="
                          weekendList.some((e) => e == index + 1)
                            ? 'background: #F9FAFB'
                            : ''
                        "
                        style="width: 35px"
                      ></SynTh>
                    </template>
                    <!--                      <SynTh-->
                    <!--                        :label="$t('COMMON_LABEL_UNPAID_DAYOFFS')"-->
                    <!--                        is-wrap-->
                    <!--                        class="border-l border-gray-150"-->
                    <!--                        style="width: 100px"-->
                    <!--                        is-center-->
                    <!--                      ></SynTh>-->
                    <!--                      <SynTh-->
                    <!--                        :label="$t('COMMON_LABEL_HOLIDAYS')"-->
                    <!--                        is-wrap-->
                    <!--                        class="border-l border-gray-150"-->
                    <!--                        is-center-->
                    <!--                      ></SynTh>-->
                    <!--                      <SynTh-->
                    <!--                        :label="$t('COMMON_LABEL_PAID_DAYOFFS')"-->
                    <!--                        is-wrap-->
                    <!--                        class="border-l border-gray-150"-->
                    <!--                        is-center-->
                    <!--                      ></SynTh>-->
                  </SynTr>
                </thead>
              </template>
              <template #body>
                <template v-for="(item, index) in timeKeepingList" :key="index">
                  <SynTr
                    :is-show-border="false"
                    :custom-class="
                      index === timeKeepingList.length - 1
                        ? ''
                        : 'border-b border-gray-150'
                    "
                  >
                    <SynTd>
                      <div class="text-xs 3xl:text-sm flex-center">
                        {{ index + 1 }}
                      </div>
                    </SynTd>
                    <SynTd class="text-xs 3xl:text-sm border-l border-gray-150">
                      <div v-vig-tooltip="item.name" class="w-max">
                        {{ item.name }}
                      </div>
                    </SynTd>
                    <template
                      v-for="(time, index) in item.timekeepings"
                      :key="time"
                    >
                      <SynTd
                        class="3xl:text-sm text-xs border-l border-gray-150"
                        :style="
                          weekendList.some((e) => e == index + 1)
                            ? 'background: #F9FAFB'
                            : ''
                        "
                      >
                        <div class="flex-center">
                          <span v-if="time == 'T7' || time == 'CN'"></span>
                          <span
                            v-else
                            :class="time == 'N' ? 'text-gray-400' : ''"
                          >
                            {{ time }}
                          </span>
                        </div>
                      </SynTd>
                    </template>
                    <SynTd
                      class="
                        3xl:text-sm
                        text-xs
                        border-l border-gray-150
                        text-center
                      "
                    >
                      <div>{{ item?.totalWorkings }}</div>
                    </SynTd>
                    <SynTd
                      class="
                        3xl:text-sm
                        text-xs
                        border-l border-gray-150
                        text-center
                      "
                    >
                      <div>{{ item?.totalDayoffs }}</div>
                    </SynTd>
                    <SynTd
                      class="
                        3xl:text-sm
                        text-xs
                        border-l border-gray-150
                        text-center
                      "
                    >
                      <div>{{ item?.totalWFHs }}</div>
                    </SynTd>
                    <SynTd
                      class="
                        3xl:text-sm
                        text-xs
                        border-l border-gray-150
                        text-center
                      "
                    >
                      <div>{{ item?.totalExceptions }}</div>
                    </SynTd>
                  </SynTr>
                </template>
              </template>
            </syn-table-custom>
          </template>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-between p-4">
        <VigDropdown
          trigger="mouseenter"
          arrow
          z-index="60"
          hide-on-click="outside"
        >
          <template #dropdown-toggle>
            <div
              class="
                flex
                items-center
                space-x-2
                p-2
                hover:bg-current-50
                rounded
                group
              "
            >
              <span class="text-gray-500 group-hover:text-current-500">{{
                $t('COMMON_LABEL_NOTES')
              }}</span>
              <SynIcon
                name="question"
                has-action
                custom-class="h-5 w-5 fill-gray-500 group-hover:fill-current-500"
              />
            </div>
          </template>
          <template #dropdown-menu>
            <div
              class="
                p-4
                text-left text-sm
                bg-white
                flex flex-col
                space-y-2
                rounded
              "
              style="min-width: 20rem"
            >
              <div class="border-b pb-2">
                <span class="text-gray-500 text-sm">{{
                  $t('COMMON_LABEL_NOTES')
                }}</span>
              </div>
              <div class="flex flex-col space-y-1.5 text-sm pt-1.5">
                <div
                  v-for="sign in SignType"
                  :key="sign.value"
                  class="flex items-center"
                >
                  <div class="flex-1">{{ $t(sign.text) }}</div>
                  <div class="w-1/5">{{ sign.value }}</div>
                </div>
              </div>
            </div>
          </template>
        </VigDropdown>
        <div class="flex space-x-2">
          <SynButton
            :label="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            @click="$emit('onClose')"
          ></SynButton>
          <SynButton
            :label="$t('COMMON_LABEL_EXPORT_FILE')"
            :disabled="!selectedMonth || isLoading"
            @click="isSelectColumns = true"
          ></SynButton>
        </div>
      </div>
    </template>
  </SynModal>

  <ModalSelectUserToExport
    v-if="isEditSelectUser"
    :user-selected-list="userList"
    @on-update-success="onUpdateSelectUser"
    @on-cancel="isEditSelectUser = false"
  />

  <ModalSelectColumns
    v-if="isSelectColumns"
    :is-loading="isLoadingWhenExport"
    @on-save="onExportFile"
    @on-close="isSelectColumns = false"
  />
</template>

<style lang="scss">
.mx-input {
  border-color: #d3d8e2 !important;
  border-width: 1px !important;
  border-radius: 0.25rem !important;
  color: #6b7280 !important;
  //@apply rounded border border-gray-300 py-2;
}
</style>
