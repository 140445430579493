import { IPrincipleService } from '@/domain/interfaces/services/IPrincipleService';
import PrincipleRepository from '@/application/repositories/PrincipleRepository';

export default class PrincipleService implements IPrincipleService {
    private static instance: PrincipleService;
    _principleRepository: PrincipleRepository;

    constructor() {
        this._principleRepository = PrincipleRepository.getInstance();
    }

    public static getInstance(): PrincipleService {
        if (!PrincipleService.instance) {
            // Get from local storage
            PrincipleService.instance = new PrincipleService();
        }

        return PrincipleService.instance;
    }

    getCurrentTime(): Promise<any> {
        return this._principleRepository.getCurrentTime();
    }

    async getDailyActions(): Promise<any> {
        const res = await this._principleRepository.getDailyActions();
        return res['result'];
    }

    async getUserWorkingStatus(): Promise<any> {
        const res = await this._principleRepository.getUserWorkingStatus();
        return res['result'];
    }

    getPrincipleTimeLimit() {
        return this._principleRepository.getPrincipleTimeLimit();
    }

    getAllPrincipleByUserAndDay(
        date?: string | null,
        departmentId?: number
    ): Promise<any> {
        return this._principleRepository.getAllPrincipleByUserAndDay(
            date,
            departmentId
        );
    }

    getPrincipleByUserId(userId, fromDate, toDate): Promise<any> {
        return this._principleRepository.getPrincipleByUserId(
            userId,
            fromDate,
            toDate
        );
    }

    getUserPrincipleStatisticByRange(userId, fromDate, toDate): Promise<any> {
        return this._principleRepository.getUserPrincipleStatisticByRange(
            userId,
            fromDate,
            toDate
        );
    }

    startStopWorkingDay(data: {
        type: number;
        time?: any;
        location?: any;
    }): Promise<any> {
        return this._principleRepository.startStopWorkingDay(data);
    }

    checkInOutWorkingDay(data: { type: number }): Promise<any> {
        return this._principleRepository.checkInOutWorkingDay(data);
    }

    restartWk(data: { type: number }): Promise<any> {
        return this._principleRepository.restartWk(data);
    }

    getWorkInfoByDateRange(date): Promise<any> {
        return this._principleRepository.getWorkInfoByDateRange(date);
    }

    getExportTimekeepings(data): Promise<any> {
        return this._principleRepository.getExportTimekeepings(data);
    }

    exportTimekeepings(data): Promise<any> {
        return this._principleRepository.exportTimekeepings(data);
    }
}
