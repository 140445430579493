export default {
    CHAT_CHAT_SUP_TICTOP_CHAT_SUPPORT_ALLOWED:
        'CHAT_CHAT_SUP_TICTOP_CHAT_SUPPORT_ALLOWED',
    CHAT_CHAT_SUP_EXTERNAL_LINK_ALLOWED: 'CHAT_CHAT_SUP_EXTERNAL_LINK_ALLOWED',
    SYSTEM_SUBSCRIP_WEB_ENTRY: 'SYSTEM_SUBSCRIP_WEB_ENTRY',
    SYSTEM_SUBSCRIP_WEB_ENTRY_ALLOW_PERSONAL_FOR_P:
        'SYSTEM_SUBSCRIP_WEB_ENTRY_ALLOW_PERSONAL_FOR_P',
    SYSTEM_SUBSCRIP_WEB_WORKSPACE_ALLOW_PERSONAL:
        'SYSTEM_SUBSCRIP_WEB_WORKSPACE_ALLOW_PERSONAL',
};
