<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m19 18v-16a1 1 0 0 0 -1-1h-16a1 1 0 0 0 -1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1zm-2-1h-14v-14h14z"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m22 5h-2v2h1v14h-14v-1h-2v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-16a1 1 0 0 0 -1-1z"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m9 14.429h2v-3.429h3.429v-2h-3.429v-3.429h-2v3.429h-3.429v2h3.429z"
        class=""
      ></path>
    </g>
  </svg>
</template>
