<script setup lang="ts">
import { computed, ref } from 'vue';
import userStore from '@/store/user';
import { ignoreUnicode } from '@/ui/plugins/utils';
import UpdateMemberDomainSingleModal from '@/ui/modules/domains/component/UpdateMemberDomainSingleModal.vue';
import DomainMemberLabel from '@/ui/modules/domains/component/DomainMemberLabel.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import UserById from '@/ui/components/user/UserById.vue';

const props = withDefaults(
  defineProps<{
    modelValue: any[];
    domainId?: number | string | null;
    parentId?: number | string | null;
    projectId?: number | string | null;
    isEditting: boolean;
    autofocus?: boolean;
    domainName?: string;
    parentMembers?: any[];
  }>(),
  {
    domainName: '',
  }
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void;
  (e: 'update:updatedMembers', value: any): void;
}>();

const _userStore = userStore();

const isVisitor = computed(() => _userStore.isVisitor);

const searchText = ref('');

const filteredUsers = computed(() => {
  if (isVisitor.value) return [];

  let result = (props.parentMembers || []).filter(
    (userId) =>
      !props.modelValue?.some((user) => user?.id == userId && !user?.deleted)
  );
  if (result?.length == 0) return [];

  const searchTxt = ignoreUnicode(searchText.value);
  if (searchTxt)
    return result.filter(
      (user) =>
        user?.name &&
        ignoreUnicode(user?.name).includes(searchTxt) &&
        !props.modelValue?.some((u) => u.id == user?.id && !u.deleted)
    );

  return result?.filter(
    (user) => !props.modelValue?.some((u) => u.id == user?.id && !u.deleted)
  );
});

const onSelectUser = (userId) => {
  let currentSelectedUsers = props.modelValue;
  const index = currentSelectedUsers?.findIndex((user) => user?.id == userId);

  if (index > -1) {
    currentSelectedUsers[index].deleted = false;
  } else {
    currentSelectedUsers = [
      ...currentSelectedUsers,
      {
        id: userId,
        anyProjects: false,
      },
    ];
  }
  emit(
    'update:modelValue',
    arrayOrderBy(
      currentSelectedUsers,
      ['anyProjects', (u) => u?.name?.split(' ')?.pop()],

      ['desc', 'asc']
    )
  );

  setTimeout(() => {
    if (filteredUsers.value?.length == 0) searchText.value = '';
  });
};
const editDomainByUserId = ref(0);
const isAnyProjects = ref(true);
const currentUserProjectIds = ref<any[]>([]);
const onEditAnyProject = (userId) => {
  editDomainByUserId.value = userId;

  const index = props.modelValue?.findIndex((user) => user.id == userId);
  if (index == -1) return;
  isAnyProjects.value = !!props.modelValue[index]?.anyProjects;
  currentUserProjectIds.value = props.modelValue[index]?.projectIds;
};

const onSaveAnyProjects = (data: {
  anyProjects: boolean;
  projectIds: number[];
}) => {
  const index = props.modelValue?.findIndex(
    (user) => user.id == editDomainByUserId.value
  );
  if (index == -1) {
    editDomainByUserId.value = 0;
    return;
  }

  const isDelete =
    !data.anyProjects &&
    !data.projectIds?.some((projectId) => projectId == props.projectId);

  const newModelValue = props.modelValue;
  newModelValue[index].anyProjects = data.anyProjects;
  newModelValue[index].projectIds = data.projectIds;
  newModelValue[index].updated = true;
  newModelValue[index].deleted = isDelete;

  emit(
    'update:modelValue',
    arrayOrderBy(
      newModelValue,
      ['anyProjects', (user) => user?.name?.split(' ')?.pop()],
      ['desc', 'asc']
    )
  );

  editDomainByUserId.value = 0;
};
const onRemoveMember = (userId) => {
  const index = props.modelValue?.findIndex((user) => user.id == userId);
  if (index == -1) return;

  const newModelValue = props.modelValue;

  newModelValue[index].deleted = true;
  emit(
    'update:modelValue',
    arrayOrderBy(
      newModelValue,
      ['anyProjects', (user) => user?.name?.split(' ')?.pop()],
      ['desc', 'asc']
    )
  );
};

const currentIndex = ref(null);

const onChooseResult = () => {
  if (currentIndex.value == null) return;

  const userId = filteredUsers.value[currentIndex.value];

  if (!userId) return;

  onSelectUser(userId);
};
</script>

<template>
  <div
    class="flex flex-col flex-1 min-h-0 overflow-hidden"
    :class="isEditting ? 'grid grid-cols-2 ' : ''"
  >
    <div v-if="isEditting" class="flex-1 min-h-0 flex flex-col p-4">
      <VigSearchBox
        v-model="searchText"
        v-model:current-index="currentIndex"
        class="p-1"
        :total-result="filteredUsers?.length"
        :autofocus="autofocus"
        @enter="onChooseResult"
      />
      <div class="flex-1 overflow-auto small-scrollbar">
        <div v-if="filteredUsers?.length">
          <div
            v-for="(userId, index) in filteredUsers"
            :key="userId"
            class="p-2 flex items-center space-x-3 cursor-pointer"
            :class="index == currentIndex ? 'bg-gray-100' : ''"
            @click="onSelectUser(userId)"
          >
            <SynIcon
              name="checkbox"
              class="fill-current cursor-pointer flex-none"
              :is-active="modelValue?.some((u) => u.id == userId && !u.deleted)"
            />
            <UserById :user-id="userId" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-1 min-h-0 overflow-auto small-scrollbar">
      <div v-if="isEditting" class="p-4 flex space-x-2">
        <span class="">
          {{
            $t('GED_NUM_SELECTED', {
              number: modelValue?.filter((e) => !e.deleted)?.length || 0,
            })
          }}
        </span>
        <!--        <span class="font-semibold"> ({{ modelValue?.length }}) </span>-->
      </div>
      <div class="flex flex-col space-y-2 flex-1 min-h-0 px-4">
        <div
          v-for="user of modelValue?.filter((mem) => !mem.deleted)"
          :key="user?.id"
          class="w-full flex items-center space-x-2 justify-between"
        >
          <DomainMemberLabel
            :user-id="user?.id"
            :is-edit="isEditting"
            :any-projects="user?.anyProjects"
            :removeable="!user?.anyProjects"
            :editable="user?.anyProjects"
            @on-edit="onEditAnyProject(user?.id)"
            @on-remove="onRemoveMember(user?.id)"
          />
        </div>
      </div>
    </div>
  </div>

  <UpdateMemberDomainSingleModal
    v-if="editDomainByUserId"
    v-model:isAnyProjects="isAnyProjects"
    :user-id="editDomainByUserId"
    :domain-id="domainId || 0"
    :parent-id="parentId"
    :project-id="projectId"
    :domain-name="domainName"
    :user-project-ids="currentUserProjectIds"
    @on-close="editDomainByUserId = 0"
    @on-submit="onSaveAnyProjects"
  />
</template>
