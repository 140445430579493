import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import EmbedTaskServices from '@/ticket/services/task-services';
import { TaskMedia } from '@/domain/entities/task/TaskResponseClass';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import { TicketPayloadClass } from '../domain/TicketPayload';

export default defineStore({
    id: storeId.ticketTask,
    state: () => ({
        _taskService: EmbedTaskServices.getInstance(),
        myGroups: [] as any[],
        dataConfig: {} as any,
        currentCycle: '',
    }),
    getters: {},
    actions: {
        async fetchDataConfig(): Promise<any> {
            try {
                const res = await this._taskService.getDataConfig();
                // this.myGroups = res['result'];
                this.dataConfig = res.result;
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async getCurrentTaskCycle() {
            const res = await this._taskService.getCurrentTaskCycle();
            this.currentCycle = res?.result;
        },

        create(newTask: TicketPayloadClass) {
            const payload = new TicketPayloadClass(newTask);
            return this._taskService.create(payload);
        },

        getHistories(data) {
            return this._taskService.getHistories(data);
        },

        getAttachAndNoteByTaskId(taskId: number): Promise<TaskMedia> {
            return this._taskService.getTaskMedia(taskId);
        },

        async getDetailByCode(code) {
            const taskDetail: any = await this._taskService.getDetailByCode(
                code
            );
            if (taskDetail.noteCount > 0 || taskDetail.attachmentCount > 0) {
                const media = await this.getAttachAndNoteByTaskId(
                    taskDetail?.id
                );
                taskDetail.files = cloneDeep(media.files);
                taskDetail.notes = cloneDeep(media.notes);
            }

            return taskDetail;
        },
    },
});
