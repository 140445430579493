import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import {
    putObjectToAwsS3,
    awsS3Delete,
} from '@/ui/plugins/awss3/aws-s3-services';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import { v4 as uuidv4 } from 'uuid';
import { getFileType, getFileExtension } from '@/ui/hooks/fileHook';
import { FILE } from '@/ui/plugins/constants';
import { AttachmentServiceClass } from '@/application/services/attachment/attachment-service';
export const uploadTaskAttachments = async (files: any[]): Promise<any[]> => {
    const payloadUploadedToAws: any[] = []; // Handle rollback when create task error
    const promisesAllList: any[] = [];
    Array.from(files).forEach(async (file) => {
        if (file.isDeleted || file.id) return;
        const fileName = stringNomalize(file?.name, false);

        const { s3Payload, extension, documentType } =
            getAwsPayloadToUpload(file);
        // Push to list data
        promisesAllList.push(putObjectToAwsS3(s3Payload));
        payloadUploadedToAws.push({
            bucket: bucketTictop.AttachmentFile,
            fileName,
            path: s3Payload.Key,
            size: file.size,
            type: file.type || file.mimeType || getFileType(file),
            extension,
            documentType,
            module: 'TASK_ATTACHMENT',
        });
    });

    // 4. Run upload to aws s3
    await Promise.all(promisesAllList);
    return payloadUploadedToAws;
};

const getAwsPayloadToUpload = (file) => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    const fileType = file.type;
    const fileName = stringNomalize(file?.name, false);
    let extension = '';
    let documentType = '';
    let fileData = file;

    const isAudio = fileType.startsWith(FILE.TYPE.AUDIO);
    const isPhoto = fileType.startsWith(FILE.TYPE.IMAGE);
    let s3Payload: any = {};
    let filePath = '';
    // 1. Audio file
    if (isAudio) {
        // Task khong co file name se dc tao tren server: Audio-Task [Task Code] [Datetime].ext
        if (file.isRecorder) {
            fileData = new File([file.blob], fileName.toString(), {
                type: file.type,
            });
        }
        documentType = 'MEDIA';
        extension = `.${
            getFileExtension(fileName, fileType)?.toLowerCase() || 'mp3'
        }`;

        filePath = `${organizationId}/audio/${uuidv4()}`;

        s3Payload = {
            Bucket: bucketTictop.AttachmentMedia,
            Key: filePath,
            Body: fileData,
            ContentType: file.type || file.mimeType || getFileType(file),
        };
    }

    // 2. Image file
    else if (isPhoto) {
        // Task khong co file name se dc tao tren server: Photo-Task [Task Code] [Datetime].ext
        const isBase64 = file.isBase64;
        if (isBase64) {
            fileData = Buffer.from(
                file?.fileUrl?.replace(/^data:image\/\w+;base64,/, ''),
                'base64'
            );
        }
        documentType = 'PHOTO';
        extension = `.${
            getFileExtension(fileName, fileType)?.toLowerCase() || 'jpeg'
        }`;
        filePath = `${organizationId}/original/${uuidv4()}`;
        s3Payload = {
            Bucket: bucketTictop.AttachmentPhoto,
            Key: filePath,
            Body: fileData,
            ContentType: file.type || file.mimeType || getFileType(file),
            ContentEncoding: isBase64 ? 'base64' : '',
        };
    }

    // 3. Other file
    else {
        documentType = 'FILE';
        extension = `.${
            getFileExtension(fileName, fileType)?.toLowerCase() || 'doc'
        }`;
        filePath = `${organizationId}/${uuidv4()}`;
        s3Payload = {
            Bucket: bucketTictop.AttachmentFile,
            Key: filePath,
            Body: fileData,
            ContentType: file.type || file.mimeType || getFileType(file),
        };
    }
    return { s3Payload, extension, documentType };
};

export const uploadTaskAttachmentNotes = (notes): any[] => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    const payloadUploadedToAws: any[] = [...notes]; // Handle rollback when create task error
    const promisesAllList: any[] = [];
    payloadUploadedToAws.forEach((note, index, thisArray) => {
        //1. Upload
        if (!note.id || note.isUpdated) {
            const awsId = uuidv4() || `Note_${Date.now()}`;
            const filePath = `${organizationId}/original/${awsId}.jpeg`;
            const s3Payload = getPayloadUploadTaskNote(note, filePath);
            promisesAllList.push(putObjectToAwsS3(s3Payload));
            thisArray[index] = {
                ...note,
                bucket: bucketTictop.AttachmentFile,
                id: note.id,
                drawData: JSON.stringify(note.drawData),
                imagePath: filePath,
                path: filePath,
            };
        }
        //2. Remove note
        if (note.isDeleted || note.isUpdated) {
            awsS3Delete({
                Bucket: bucketTictop.AttachmentPhoto,
                Key: note?.imagePath,
            });
        }
    });

    // 4. Run upload to aws s3
    Promise.all(promisesAllList);
    return payloadUploadedToAws;
};

export const uploadTaskSingleNote = async (note): Promise<any> => {
    const newAwsS3Class = new AttachmentServiceClass('TASK_ATTACHMENT');

    const res = await newAwsS3Class.upload(note, note.type);
    return {
        ...note,
        bucket: bucketTictop.AttachmentFile,
        id: note.id,
        drawData: JSON.stringify(note.drawData),
        imagePath: res.path,
        path: res.path,
    };
};

export const getPayloadUploadTaskNote = (note, filePath): any => {
    const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
    const bodyPayload = Buffer.from(
        note?.fileUrl?.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
    );
    const s3Payload = {
        Bucket: bucketTictop.AttachmentPhoto,
        Key: filePath || `${organizationId}/original/${uuidv4()}.jpeg`,
        Body: bodyPayload,
        ContentType: note.type || note.mimeType || getFileType(note),
        ContentEncoding: note.isBase64 ? 'base64' : '',
    };
    return s3Payload;
};

export const removeAwsFile = (bucket, key): any => {
    return awsS3Delete({
        Bucket: bucket,
        Key: key,
    });
};
