<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
// import { stringNomalize } from '@/ui/hooks/commonFunction';
import groupStore from '@/store/group';
import _ from 'lodash';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import { GroupMemberRole } from '@/ui/common/constants/constant';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import GroupDetailEntity from '@/domain/entities/group/GroupDetailEntity';

const props = defineProps<{
  userId: number;
  groupMembers: any[];
}>();

const emit = defineEmits(['eventChange']);

const _groupStore = groupStore();
const allMyGroups = computed(() => _groupStore.allMyGroups);
const isLoading = ref(false);
const keySearch = ref('' as any);
const showAlertRemainOneAdmin = ref(false);

const groupDefault = computed(() => {
  const arrDefault = allMyGroups.value.filter(
    (group) =>
      (!groupSelect.value.some((el) => el.id === group.id) &&
        !group.isPrivate) ||
      (group.isPrivate &&
        group.isAdminGroup &&
        !groupSelect.value.some((el) => el.id === group.id))
  );

  let arrOnSearch: any;

  if (keySearch.value == '') {
    arrOnSearch = arrDefault;
  } else {
    arrOnSearch = arrDefault.filter((e) =>
      stringNomalize(e.name?.toLowerCase()).includes(
        stringNomalize(keySearch.value?.toLowerCase())
      )
    );
  }
  return arrOnSearch.map((o) => ({
    id: o.id,
    avatar: o.avatar,
    avatarThumnailUrl: o.avatarThumnailUrl,
    avatarUrl: o.avatarUrl,
    name: o.name,
    role: 2,
    isPrivate: o.isPrivate,
    groupUsers: o.groupUsers,
  }));
});

const groupSelect = ref(
  props.groupMembers.map((o) => ({
    ...o,
    id: o.id,
    avatar: o.avatar,
    avatarThumnailUrl: o.avatarThumnailUrl,
    avatarUrl: o.avatarUrl,
    name: o.name,
    role: o.isAdminGroup ? 1 : 2,
    isPrivate: o.isPrivate,
    groupUsers: o.groupUsers,
    code: o.code,
  }))
);

const resetSearch = () => {
  keySearch.value = '';
};

// const checkPermissionRemoveGroup = (groupId) => {
//   const arr = allMyGroups.value.filter(
//     (group) => !group.isPrivate || (group.isPrivate && group.isAdminGroup)
//   );
//   return arr?.some((e) => e.id == groupId);
// };

// const checkGeneralGroup = (group) => {
//   return group?.code == 'GENERAL_GROUP';
// };

// const checkGroupIsCreatedByDepartment = (group) => {
//   return group?.code?.includes('DEPARTMENT');
// };

const checkSetRole = (groupId) => {
  const groupUsers = groupSelect.value.find((e) => e.id == groupId)?.groupUsers;
  return (showAlertRemainOneAdmin.value =
    groupUsers.filter((el) => el.role == GroupMemberRole.Admin).length <= 1);
};

const checkRemove = (group) => {
  if (group.role == GroupMemberRole.Admin) {
    const groupUsers = groupSelect.value.find(
      (e) => e.id == group.id
    )?.groupUsers;
    return (showAlertRemainOneAdmin.value =
      groupUsers.filter((el) => el.role == GroupMemberRole.Admin).length <= 1);
  } else return false;
};

const checkGroup = (group) => {
  group.groupUsers.push({
    userId: props.userId,
    groupId: group.id,
    role: 2,
  });
  groupSelect.value.unshift(group);
  if (groupDefault.value.length < 1) resetSearch();
  emit('eventChange', groupSelect.value);
};

const removeGroup = (group) => {
  if (
    !Object.prototype.hasOwnProperty.call(_groupStore.allGroupByIds, group?.id)
  )
    return;

  if (
    !new GroupDetailEntity(_groupStore.allGroupByIds[group?.id]).hasPermission()
  )
    return;

  if (checkRemove(group)) return;
  _.remove(group.groupUsers, (e: any) => e.userId == props.userId);
  _.remove(groupSelect.value, (e: any) => e.id == group.id);
  emit('eventChange', groupSelect.value);
};

const setAdminGroup = (groupId) => {
  groupSelect.value.forEach((el) => {
    if (el.id == groupId) {
      el.role = GroupMemberRole.Admin;
      el.groupUsers.find((el) => el.userId == props.userId).role =
        GroupMemberRole.Admin;
    }
  });
  emit('eventChange', groupSelect.value);
};

const setUserGroup = (groupId) => {
  if (checkSetRole(groupId)) return;
  groupSelect.value.forEach((el) => {
    if (el.id == groupId) {
      el.role = GroupMemberRole.User;
      el.groupUsers.find((el) => el.userId == props.userId).role =
        GroupMemberRole.User;
    }
  });
  emit('eventChange', groupSelect.value);
};

onMounted(() => {});
</script>
<template>
  <div class="pt-3 pl-3 grid grid-cols-2 gap-3">
    <div class="w-full py-2">
      <VigSearchBox v-model="keySearch" autofocus @on-clear="resetSearch" />
    </div>
    <div class="py-3 px-4">
      <span class="font-medium">
        {{
          groupSelect.length > 0
            ? $t('GED_NUM_SELECTED', {
                number: groupSelect.length || 0,
              })
            : $t('COMMON_LABEL_SELECT_GROUP_NULL')
        }}
      </span>
    </div>
  </div>

  <div class="pl-2 flex-1 overflow-hidden grid gap-3 grid-cols-2">
    <div class="h-full small-scrollbar overflow-auto px-2">
      <template v-if="groupDefault.length < 1">
        <SynAnimation
          name="searchIcon"
          stype="width: 100px; height: 100px"
          :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
        />
      </template>
      <template v-else>
        <div
          v-for="group in groupDefault"
          :key="group"
          class="py-2 hover:bg-gray-100 cursor-pointer"
          @click="checkGroup(group)"
        >
          <div class="flex items-center space-x-2 px-1 font-sans text-sm">
            <SynIcon
              name="checkbox"
              class="fill-current"
              has-action
              :is-active="group?.isSelected"
              custom-class="w-3.5 h-3.5"
            />
            <SynAvatarStatusActive
              :id="group?.id"
              :avatar="group?.avatar?.replace('original', 'mini')"
              :name="group?.name"
              :is-user="false"
              custom-class="w-6 h-6"
            >
              <template #avatar-suffix>
                <div
                  v-if="group.isPrivate"
                  class="
                    absolute
                    bottom-0
                    bg-white
                    rounded-full
                    flex-center
                    w-3
                    h-3
                    -right-1
                  "
                >
                  <SynIcon
                    class="fill-current"
                    custom-class="w-2 h-2"
                    name="private"
                  />
                </div>
              </template>
            </SynAvatarStatusActive>

            <!--            <span class="text-overflow-hidden-line">-->
            <!--              {{ group.name }}-->
            <!--            </span>-->
            <!--            <span v-if="user.userId == currentUser.id" class="italic text-xs">-->
            <!--              {{ '(' + $t('COMMON_LABEL_ME') + ')' }}-->
            <!--            </span>-->
            <!--            <span v-if="user.adminType == 2" class="italic text-xs">-->
            <!--              {{ '(' + $t('COMMON_LABEL_VISITOR') + ')' }}-->
            <!--            </span>-->
          </div>
        </div>
      </template>
    </div>

    <div class="h-full small-scrollbar overflow-auto pr-2">
      <template v-if="groupSelect.length < 1">
        <div v-if="isLoading" class="flex-center space-x-3 mt-3">
          <div class="w-8 p-2 bg-gray-200 rounded-lg animate-pulse"></div>
          <div class="w-full bg-gray-200 p-2 rounded-lg animate-pulse"></div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="group in groupSelect"
          :key="group"
          class="
            py-2
            cursor-pointer
            my-1
            p-0.5
            custom-display
            bg-gray-50
            rounded-md
            relative
          "
        >
          <div class="flex justify-between items-center px-2">
            <div
              class="
                flex-1 flex
                items-center
                justify-between
                w-1/2
                space-x-2
                font-sans
                text-sm
              "
            >
              <div class="flex w-max items-center space-x-2 font-sans text-sm">
                <SynAvatarStatusActive
                  :id="group?.id"
                  :avatar="group?.avatar?.replace('original', 'mini')"
                  :name="group?.name"
                  :is-user="false"
                  custom-class="w-6 h-6"
                >
                  <template #avatar-suffix>
                    <div
                      v-if="group.isPrivate"
                      class="
                        absolute
                        bottom-0
                        bg-white
                        rounded-full
                        flex-center
                        w-3
                        h-3
                        -right-1
                      "
                    >
                      <SynIcon
                        class="fill-current"
                        custom-class="w-2 h-2"
                        name="private"
                      />
                    </div>
                  </template>
                </SynAvatarStatusActive>
                <!--                <span-->
                <!--                  v-vig-tooltip="group.name"-->
                <!--                  class="text-overflow-hidden-line flex-1"-->
                <!--                >-->
                <!--                  {{ group.name }}-->
                <!--                </span>-->
                <!--                <span-->
                <!--                  v-if="user.adminType === 2"-->
                <!--                  class="rounded-md text-2xs py-0.5 px-1 text-gray-500"-->
                <!--                >-->
                <!--                  {{ `(${$t('COMMON_LABEL_VISITOR')})` }}-->
                <!--                </span>-->
                <template v-if="group.role === GroupMemberRole.Admin">
                  <span
                    v-if="group.role === GroupMemberRole.Admin"
                    class="rounded-md text-2xs py-0.5 px-1 text-gray-500"
                  >
                    {{ `(${$t('COMMON_LABEL_ADMIN')})` }}
                  </span>
                </template>
              </div>
            </div>

            <!--  TYPE -->
            <div
              class="
                h-full
                flex-center
                action-text
                absolute
                right-2
                bg-gray-50
                px-2
              "
            >
              <small
                v-if="group.role === GroupMemberRole.Admin"
                class="
                  text-red-500
                  hover:text-red-700 hover:font-medium
                  italic
                  pr-2
                "
                @click="setUserGroup(group.id)"
              >
                <span>{{ $t('GROUP_UPDATE_MEMBER_UNSET_ADMIN') }}</span>
              </small>
              <small
                v-else
                class="
                  text-green-500
                  hover:text-green-700 hover:font-medium
                  italic
                  pr-2
                "
                @click="setAdminGroup(group.id)"
              >
                <span>{{ $t('GROUP_UPDATE_MEMBER_SET_ADMIN') }}</span>
              </small>
              <SynIcon
                v-vig-tooltip="$t('COMMON_LABEL_DELETE')"
                has-action
                name="cancel"
                color="gray"
                custom-class="fill-gray-500 w-2 h-2"
                @click="removeGroup(group)"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <AlertModal
    v-if="showAlertRemainOneAdmin"
    :title-text="$t('COMON_NOT_ALLOW_PERFORM_ACTION')"
    :sub-title-text="$t('GROUP_UPDATE_ADMIN_NOTALLOW')"
    container-class="w-1/4"
    name-icon="warning"
    :container-color="'current'"
    @cancel="showAlertRemainOneAdmin = false"
  />
</template>

<style scoped>
.item-domain:hover .item-cancel {
  display: block;
}

.item-cancel {
  display: none;
}

.custom-display:hover .action-text {
  display: flex;
}

.action-text {
  display: none;
}
</style>
