import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import storeId from '@/base/store/storeId';
import DomainService from '@/application/services/DomainService';
import { translate } from '@/ui/plugins/i18n/myi18n';
import getUserInfo from '@/ui/helpers/user-helper';

export default defineStore({
    id: storeId.DOMAIN_MANAGEMENT,
    state: () => ({
        _domainService: DomainService.getInstance(),
        domainTodayStatistic: {} as any,
        domainTaskStatisticChart: [] as any,
        labelDomainTaskStatisticChart: [] as any,
        domainTaskOverview: {} as any,
        domainTaskMemberActive: [] as any,
        isLoadingData: false,
        domainFullDetail: {} as any,
        timeDomainActivedData: {} as any,
        domainTaskOverviewStatisticData: {} as any,
    }),
    getters: {},
    actions: {
        async getDomainTodayStatistic(domainId) {
            try {
                const res = await this._domainService.getDomainTodayStatistic(
                    domainId
                );
                this.domainTodayStatistic = {
                    ...res?.result,
                    dataChart: [
                        {
                            name: translate(
                                'DOMAIN_MANAGEMENT_TASK_TODO_IN_TODAY_LABEL'
                            ),
                            label: translate(
                                'DOMAIN_MANAGEMENT_TASK_TODO_IN_TODAY_LABEL'
                            ),
                            data: res?.result?.doToday,
                            color: '#fb923c',
                        },
                        {
                            name: translate(
                                'DOMAIN_MANAGEMENT_TASK_FINISHED_IN_TODAY_LABEL'
                            ),
                            label: translate(
                                'DOMAIN_MANAGEMENT_TASK_FINISHED_IN_TODAY_LABEL'
                            ),
                            data: res?.result?.finishedToday,
                            color: '#4bb0b2',
                        },
                    ],
                };
                return res['result'];
            } catch (e) {
                console.log(e);
            }
        },

        async getDomainTaskCreateFinishStatistic(domainId, fromDate, toDate) {
            try {
                const res =
                    await this._domainService.getDomainTaskCreateFinishStatistic(
                        domainId,
                        fromDate,
                        toDate
                    );
                const data = res?.result;
                if (!data || data.length == 0) return;
                const createTaskList = data.map((o) => o.numberOfTaskCreated);
                const finishTaskList = data.map((o) => o.numberOfTaskFinished);
                this.labelDomainTaskStatisticChart = data.map((o) =>
                    dayjs(o.date).format('DD MMM')
                );

                this.domainTaskStatisticChart = [
                    {
                        label: translate('CHART_LABEL_CREATED_TASK'),
                        title: translate('CHART_LABEL_CREATED_TASK'),
                        data: createTaskList,
                        borderColor: '#FF8800',
                        fill: false,
                        tension: 0.2,
                        pointRadius: 3,
                        borderWidth: 1.5,
                        pointBackgroundColor: '#FF8800',
                    },
                    {
                        label: translate('COMMON_LABEL_STATUS_FINISHED'),
                        title: translate('COMMON_LABEL_STATUS_FINISHED'),
                        data: finishTaskList,
                        borderColor: '#4BB0B2',
                        fill: false,
                        tension: 0.2,
                        pointRadius: 3,
                        borderWidth: 1.5,
                        pointBackgroundColor: '#4BB0B2',
                    },
                ];

                return this.domainTaskStatisticChart;
            } catch (e) {
                console.log(e);
            }
        },

        async getAllDomainTaskOverviews(domainId) {
            try {
                const res = await this._domainService.getAllDomainTaskOverviews(
                    domainId
                );

                for (const [key, value] of Object.entries(res['result'])) {
                    switch (key) {
                        case 'activeTasks':
                            this.domainTaskOverview[key] = {
                                key: 'ACTIVE_TASK',
                                data: value,
                                label: 'HEADER_LABEL_WELCOME_ACTIVE_TASK',
                                color: 'current',
                                bgColor: 'current',
                                icon: 'ListTask',
                            };
                            break;
                        case 'finishedTasks':
                            this.domainTaskOverview[key] = {
                                key: 'FINISHED_TASK',
                                data: value,
                                label: 'TASK_STATE_DESCRIPTION_FINISHED',
                                color: 'green-400',
                                bgColor: 'green',
                                icon: 'CircleCheckbox',
                            };
                            break;
                        case 'overdueTasks':
                            this.domainTaskOverview[key] = {
                                key: 'OVERDUE_TASK',
                                data: value,
                                label: 'HEADER_LABEL_WELCOME_OVERDUE_TASK',
                                color: 'red-400',
                                bgColor: 'red',
                                icon: 'OverdueTask',
                            };
                            break;
                        default:
                            break;
                    }
                }

                return res['result'];
            } catch (e) {
                console.log(e);
            }
        },

        async getDomainTasksMemberActive(domainId) {
            try {
                const res =
                    await this._domainService.getDomainTasksMemberActive(
                        domainId
                    );
                const dataList = res?.result?.slice(0, 3) || [];
                if (!dataList || dataList.length == 0) {
                    this.domainTaskMemberActive = [];
                    return;
                }
                this.domainTaskMemberActive = dataList.map((el: any) => {
                    return {
                        ...el,
                        ...getUserInfo(el?.id),
                    };
                });
                return dataList;
            } catch (e) {
                console.log(e);
            }
        },

        async getDomainFullDetail(domainId) {
            try {
                const res = await this._domainService.getDomainFullDetail(
                    domainId
                );

                // const endD = res?.result?.endDate ? res?.result?.endDate : dayjs(new Date()).add(2, 'week');
                // const startD = res?.result?.startDate ? res?.result?.startDate : dayjs(new Date()).subtract(1, 'week');

                this.domainFullDetail = {
                    ...res?.result,
                    endDate: res?.result?.endDate,
                    startDate: res?.result?.startDate,
                };

                this.domainTaskOverviewStatisticData = {
                    activeTasks: this.domainFullDetail?.activeTasks || 0,
                    finishedTasks: this.domainFullDetail?.finishedTasks || 0,
                    overdueTasks: this.domainFullDetail?.overdueTasks || 0,
                    progress: this.domainFullDetail?.progress || 0,
                    dataChart: [
                        {
                            name: 'Remaining',
                            label: translate(''),
                            data: this.domainFullDetail?.activeTasks,
                            color: '#b8c3e0',
                        },
                        {
                            name: 'Used',
                            label: translate(''),
                            data: this.domainFullDetail?.finishedTasks,
                            color: '#008e8e',
                        },
                    ],
                };

                this.timeDomainActivedData = this.calcTimeRemainingChart(
                    res?.result?.startDate,
                    res?.result?.endDate
                );
            } catch (e) {
                console.log(e);
            }
        },

        async getAllDataDomainManagement(domainId) {
            this.isLoadingData = true;

            const endDate = dayjs(new Date())
                .add(1, 'day')
                .format('YYYY-MM-DD');
            const startDate = dayjs(new Date())
                .subtract(2, 'week')
                .format('YYYY-MM-DD') as any;

            await Promise.all([
                this.getDomainTodayStatistic(domainId),
                this.getDomainTaskCreateFinishStatistic(
                    domainId,
                    startDate,
                    endDate
                ),
                this.getAllDomainTaskOverviews(domainId),
                this.getDomainTasksMemberActive(domainId),
                this.getDomainFullDetail(domainId),
            ]);
            this.isLoadingData = false;
        },

        calcTimeRemainingChart(start, end) {
            let startDate = start;
            let endDate = end;

            startDate = dayjs(startDate).startOf('day');
            endDate = dayjs(endDate).endOf('day');

            let state = 'RUNNING';
            const timeRemainStartDomain =
                new Date(startDate).getTime() - new Date().getTime();
            let remaining = new Date(endDate).getTime() - new Date().getTime();
            const totalTime =
                new Date(endDate).getTime() - new Date(startDate).getTime();
            const used = totalTime - remaining;

            if (timeRemainStartDomain > 0) {
                state = 'WAITING';
                remaining =
                    new Date(endDate).getTime() - new Date(startDate).getTime();
            } else if (timeRemainStartDomain < 1 && remaining > 0) {
                state = 'RUNNING';
            } else {
                state = 'FINISHED';
            }

            return {
                state: state,
                totalMiniSecondRemain: endDate ? remaining : 0,
                progress: endDate ? Math.floor((used / totalTime) * 100) : 0,
                dataChart: [
                    {
                        name: 'Remaining',
                        data: endDate ? remaining / 3600 : 0,
                        color: '#b8c3e0',
                    },
                    {
                        name: 'Used',
                        data: endDate ? used / 3600 : 0,
                        color: '#008e8e',
                    },
                ],
            };
        },
    },
});
