<script setup lang="ts">
import {
  IReportCard,
  EReportCardType,
} from '@/application/types/report/report.types';
import ReportCardNumber from '@/ui/modules/dashboard/performance/cards/ReportCardNumber.vue';
import ReportCardTaskStatus from '@/ui/modules/dashboard/performance/cards/ReportCardTaskStatus.vue';
import ReportCardTaskPerformance from '@/ui/modules/dashboard/performance/cards/ReportCardTaskPerformance.vue';
import ReportCardDomainTasks from '@/ui/modules/dashboard/performance/cards/ReportCardDomainTasks.vue';
import ReportCardDomainOverdueTasks from '@/ui/modules/dashboard/performance/cards/ReportCardDomainOverdueTasks.vue';
import ReportCardExcellenceUsers from '@/ui/modules/dashboard/performance/cards/ReportCardExcellenceUsers.vue';
import ReportCardActiveUsers from '@/ui/modules/dashboard/performance/cards/ReportCardActiveUsers.vue';
import ReportCardMostOverdue from '@/ui/modules/dashboard/performance/cards/ReportCardMostOverdue.vue';
import ReportCardMostTasks from '@/ui/modules/dashboard/performance/cards/ReportCardMostTasks.vue';
import ReportCardCreatingMost from '@/ui/modules/dashboard/performance/cards/ReportCardCreatingMost.vue';
import ReportCardAssignedMost from '@/ui/modules/dashboard/performance/cards/ReportCardAssignedMost.vue';

defineProps<{
  reportCard: IReportCard;
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
  };
  settings: {
    viewType: any;
    size: any;
    orderBy: any;
  };
}>();
defineEmits<{
  (e: 'close'): void;
}>();

const totalTaskCardList = [
  EReportCardType.COMPLETED_TASKS,
  EReportCardType.INCOMPLETE_TASKS,
  EReportCardType.OVERDUE_TASKS,
  EReportCardType.TOTAL_TASKS,
];
</script>
<template>
  <SynModal
    style-body="p-0 overflow-y-hidden"
    container-class="w-2/5"
    z-index="z-50"
    :container-style="reportCard.configs?.modal?.bodyStyle"
    is-hidden-footer
    :title="$t(reportCard?.titleCode) || reportCard?.title"
    @cancel="$emit('close')"
  >
    <template #header>
      <div class="flex items-center justify-between">
        <span class="text-base">
          {{ $t(reportCard?.titleCode) || reportCard?.title }}
        </span>
        <slot name="filters" />
      </div>
    </template>
    <template #body>
      <div
        class="w-full h-full flex-1 min-h-0 flex flex-col justify-center"
        :class="
          reportCard?.type == EReportCardType.ACTIVE_USERS
            ? 'items-start'
            : 'items-center'
        "
      >
        <div
          class="
            w-full
            h-full
            flex flex-col
            justify-center
            flex-1
            min-h-0
            px-8
            pb-8
            overflow-x-auto
            small-scrollbar
          "
          :class="
            reportCard?.type == EReportCardType.ACTIVE_USERS
              ? 'items-start'
              : 'items-center'
          "
        >
          <template v-if="totalTaskCardList.includes(reportCard?.type)">
            <ReportCardNumber
              :report-card="{
                ...reportCard,
                height: '32rem',
              }"
              :filter-data="filterData"
              size="lg"
            />
          </template>
          <template v-else-if="reportCard?.type == EReportCardType.TASK_STATUS">
            <ReportCardTaskStatus :filter-data="filterData" size="lg" />
          </template>
          <template
            v-else-if="reportCard?.type == EReportCardType.TASK_PERFORMANCE"
          >
            <ReportCardTaskPerformance
              :filter-data="{
                ...filterData,
                viewType: settings.viewType,
              }"
            />
          </template>
          <template
            v-else-if="reportCard?.type == EReportCardType.DOMAIN_TASKS"
          >
            <ReportCardDomainTasks
              :filter-data="{
                ...filterData,
                size: settings.size,
              }"
            />
          </template>
          <template
            v-else-if="reportCard?.type == EReportCardType.DOMAIN_OVERDUE_TASKS"
          >
            <ReportCardDomainOverdueTasks
              :filter-data="{
                ...filterData,
                size: settings.size,
              }"
            />
          </template>
          <template
            v-else-if="reportCard?.type == EReportCardType.EXCELLENCE_USERS"
          >
            <ReportCardExcellenceUsers
              :filter-data="{
                ...filterData,
                size: settings.size,
              }"
            />
          </template>
          <template
            v-else-if="reportCard?.type == EReportCardType.ACTIVE_USERS"
          >
            <ReportCardActiveUsers
              :filter-data="{
                ...filterData,
                size: settings.size,
              }"
            />
          </template>
          <template
            v-else-if="reportCard?.type == EReportCardType.MOST_OVERDUE"
          >
            <ReportCardMostOverdue
              :filter-data="{
                ...filterData,
                size: settings.size,
              }"
            />
          </template>
          <template v-else-if="reportCard?.type == EReportCardType.MOST_TASKS">
            <ReportCardMostTasks
              :filter-data="{
                ...filterData,
                size: settings.size,
              }"
            />
          </template>
          <template
            v-else-if="reportCard?.type == EReportCardType.CREATING_MOST"
          >
            <ReportCardCreatingMost
              :filter-data="{
                ...filterData,
                size: settings.size,
                orderBy: settings.orderBy,
              }"
            />
          </template>
          <template
            v-else-if="reportCard?.type == EReportCardType.ASSIGNED_MOST"
          >
            <ReportCardAssignedMost
              :filter-data="{
                ...filterData,
                size: settings.size,
                orderBy: settings.orderBy,
              }"
            />
          </template>
          <template v-else>
            {{ reportCard?.type }}
          </template>
        </div>
      </div>
    </template>
  </SynModal>
</template>
