<template>
  <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 500.804 266.64 C 498.677 270.84 495.473 272.99 490.902 273.428 L 490.846 273.434 L 490.79 273.446 C 486.36 274.433 482.882 273.343 479.655 270.046 L 423.893 219.719 C 420.571 217.016 419.246 213.708 419.24 209.606 C 418.861 205.53 420.027 202.13 423.117 199.05 C 425.782 195.765 428.794 194.511 432.65 194.542 L 432.711 194.542 L 432.77 194.535 C 436.567 194.094 439.494 195.085 442.444 198.074 L 469.058 222.097 L 468.598 219.11 L 466.753 207.158 L 466.243 203.863 C 456.948 144.641 423.775 93.979 378.402 61.132 C 332.701 28.582 273.153 12.829 212.462 22.142 C 151.888 31.953 99.981 65.247 66.251 110.284 C 32.89 155.498 16.51 213.883 25.394 273.13 C 34.69 332.351 67.862 383.013 113.238 415.864 C 158.939 448.413 218.487 464.166 279.176 454.852 C 355.649 442.398 418.027 392.536 448.382 328.21 C 450.004 324.21 452.709 321.99 456.471 320.838 C 460.003 319.288 463.158 319.331 466.721 321.307 L 466.78 321.341 L 466.843 321.364 C 470.729 322.815 472.829 325.51 474.062 329.404 C 475.64 333.174 475.573 336.716 473.528 340.622 C 439.272 414.551 370.279 469.993 283.215 483.339 C 214.537 494.433 149.027 477.046 97.162 439.521 C 44.965 402.295 8.01 345.923 -2.229 277.796 C -12.887 209.694 5.482 144.5 44.089 92.886 C 82.323 41.098 139.631 4.286 208.424 -6.32 C 277.102 -17.416 342.611 -0.028 394.479 37.499 C 446.672 74.727 483.624 131.091 493.862 199.226 L 496.679 218.058 L 506.408 201.389 L 514.963 186.737 C 516.935 182.914 519.702 180.997 523.528 180.206 L 523.584 180.193 L 523.638 180.175 C 527.254 178.95 530.369 179.345 533.846 181.62 C 537.63 183.535 539.565 186.423 540.417 190.401 C 541.643 194.308 541.259 197.813 538.872 201.514 L 500.804 266.64 Z"
      style=""
      transform="matrix(0.874203, -0.485561, 0.485561, 0.874203, -82.10484, 160.104014)"
    />
    <g
      transform="matrix(2.55671, 0, 0, 2.78324, -29.799307, -58.916435)"
      style=""
    >
      <path
        d="M 131.833 135.154 L 131.833 151.867 L 152.662 130.972 L 136.002 130.972 C 133.698 130.972 131.833 132.842 131.833 135.154 Z"
        style=""
      />
      <path
        d="M 65.483 76.176 L 65.475 73.056 C 65.283 72.026 65.501 71.716 66.343 71.114 L 66.493 70.996 L 66.564 70.911 L 66.612 70.853 C 67.137 70.092 67.46 70.039 68.646 70.183 L 149.598 70.177 C 150.694 69.998 151.023 70.199 151.651 70.996 L 151.773 71.136 L 151.863 71.204 L 151.913 71.242 C 152.602 71.695 152.655 71.801 152.626 72.716 L 152.626 76.176 L 65.483 76.176"
        style=""
      />
      <path
        d="M 153.83 87.153 L 65.046 87.153 L 65.046 144.77 C 65.008 146.748 65.692 148.3 66.984 149.517 C 68.191 150.808 69.87 151.574 71.864 151.317 L 126.035 151.317 L 126.235 136.034 C 125.92 134.445 127.646 131.498 129.512 129.402 C 131.791 126.842 134.369 127.288 134.963 126.559 L 153.391 126.557 L 153.83 87.153 Z M 92.789 121.136 L 92.789 145.568 L 70.495 145.568 L 70.495 121.136 L 92.789 121.136 Z M 92.649 91.832 L 92.789 116.106 L 70.495 116.106 L 70.495 92.393 L 92.789 92.033 L 92.633 92.393 L 92.649 91.832 Z M 120.559 121.136 L 120.559 145.568 L 98.265 145.568 L 98.265 121.136 L 120.559 121.136 Z M 120.919 92.234 L 120.559 116.106 L 98.265 116.106 L 98.265 92.393 L 120.559 92.249 L 120.911 92.393 L 120.919 92.234 Z M 148.603 92.033 L 148.329 116.106 L 126.035 116.106 L 126.035 92.393 L 148.329 92.033 L 148.603 92.393 L 148.603 92.033 Z"
        style="stroke-miterlimit: 1; stroke-linecap: round"
      />
    </g>
  </svg>
</template>
