<script setup lang="ts">
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import { computed, ref } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';

const props = defineProps<{
  currentId: string | number;
  placeholder: string;
  data: any[];
  inputClass: string;
  dropdownClass: string;
  objectContact: object;
  name: string;
  avatar: string;
  removeable: boolean;
}>();

const emit = defineEmits(['chosen', 'reset', 'blur']);

const isInputting = ref(false);
const showOptions = ref(false);
const searchTerm = ref('');
const inputRef = ref<any>(null);
const indexResult = ref(-1);
const keyActive = ref('search-assign');

const searchResults = computed(() => {
  const searchTxt = ignoreUnicode(searchTerm.value);
  if (!searchTxt) return props.data;
  return (props.data || []).filter(
    (item: any) => item?.name && ignoreUnicode(item?.name).includes(searchTxt)
  );
}) as any;
const onOpenAssign = () => {
  inputRef.value?.focus();
  indexResult.value = -1;
  showOptions.value = true;
  isInputting.value = true;
};

const reset = () => {
  emit('reset');
  searchTerm.value = '';
  inputRef.value?.click();
  onOpenAssign();
};

const chooseItem = (item) => {
  emit('chosen', item);
  showOptions.value = false;
  isInputting.value = false;
  searchTerm.value = '';
  // $refs.input.focus();
};

const clickedOutside = () => {
  showOptions.value = false;
  isInputting.value = false;
};

const onClickInput = () => {
  indexResult.value = -1;
  showOptions.value = true;
};

const onChooseResult = () => {
  let item = searchResults.value[indexResult.value];
  emit('chosen', item);
  showOptions.value = false;
  isInputting.value = false;
  searchTerm.value = '';
};

const onBlur = () => {
  isInputting.value = false;
  emit('blur');
};

const onKeyDownSearch = () => {
  indexResult.value++;
  if (indexResult.value == searchResults.value.length) {
    indexResult.value = 0;
  }
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
const onKeyUpSearch = () => {
  indexResult.value--;
  keyActive.value = `search-assign-${indexResult.value}`;
  if (indexResult.value < 0) {
    indexResult.value = searchResults.value.length - 1;
  }
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
</script>

<template>
  <VigDropdown
    :arrow="false"
    hide-on-click="outside"
    :z-index="60"
    @on-dropdown-open="onOpenAssign"
    @on-dropdown-close="clickedOutside"
    @click.stop="onOpenAssign"
  >
    <template #dropdown-toggle>
      <div
        class="
          relative
          flex
          items-center
          py-1
          h-10
          border
          rounded
          w-44
          cursor-pointer
          hover:bg-gray-100
        "
        @click="showOptions = true"
      >
        <div class="w-6 h-6 absolute left-2">
          <div class="relative flex items-center">
            <SynAvatar
              :src="avatar"
              :name="name"
              :type="'department'"
              custom-class="w-6 h-6"
            />
          </div>
        </div>
        <input
          v-if="isInputting"
          ref="inputRef"
          v-model="searchTerm"
          tabindex="0"
          class="pl-10 text-sm h-full w-full border-0 focus:ring-0"
          :placeholder="placeholder"
          @focus="onClickInput"
          @blur="onBlur"
          @keyup.down="onKeyDownSearch"
          @keyup.up="onKeyUpSearch"
          @keyup.enter="onChooseResult"
        />
        <div
          v-else
          :title="name ? name : ''"
          class="
            text-sm text-gray-500
            h-full
            w-full
            focus:bg-white focus:outline-none focus:border-transparent
            flex
            items-center
            pl-10
            pr-8
            text-overflow-hidden-line
          "
          :class="inputClass"
          @click="onOpenAssign()"
        >
          <span v-if="name" class="truncate text-overflow-hidden-line">{{
            name
          }}</span>
          <span v-else>
            {{ $t('COMMON_LABEL_DEPARTMENT') }}
          </span>
        </div>
        <div
          v-if="name && removeable"
          class="
            flex-center
            h-4
            w-4
            cursor-pointer
            hover:bg-current-50
            absolute
            right-2
            rounded-full
          "
          @click.stop="reset"
        >
          <SynIcon custom-class="h-3 w-3" name="close" />
        </div>
      </div>
    </template>
    <template v-if="showOptions" #dropdown-menu>
      <div class="flex flex-col">
        <slot name="header"></slot>
        <ul
          class="
            h-max
            max-h-96
            z-100
            w-48
            bg-gray-50
            rounded-md
            divide-y divide-gray-100
            small-scrollbar
            overflow-auto
            syn
          "
        >
          <li
            v-for="(item, index) in searchResults"
            :id="`search-assign-${index}`"
            :key="item"
            :class="
              keyActive == `search-assign-${index}` ? 'bg-current-50' : ''
            "
            class="
              flex
              dropdown-item
              items-center
              space-x-2
              px-2
              py-0.5
              text-gray-700
              hover:bg-current-50
              cursor-pointer
              syn-select-assign-item
            "
            @click.stop="chooseItem(item)"
          >
            <span class="min-w-max relative">
              <SynAvatar
                :src="
                  item?.avatar?.replace('original', 'mini') ||
                  item?.avatarUrl?.replace('original', 'mini')
                "
                custom-class="w-8 h-8 syn-select-assign-item"
                :name="item?.name || name"
              />
            </span>
            <div
              :title="item?.name"
              class="
                w-full
                py-2
                text-sm
                syn-select-assign-item
                flex
                items-center
                justify-between
              "
            >
              <span class="text-overflow-hidden-line syn-select-assign-item">
                {{
                  item?.name ||
                  item?.fullName ||
                  item?.lastName + ' ' + item?.firstName
                }}
              </span>
            </div>
          </li>
          <li v-if="!searchResults?.length" class="p-6 text-center">
            <SynAnimation
              name="searchIcon"
              :loop="false"
              stype="width: 100px; height: 100px"
              :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
            />
          </li>
        </ul>
      </div>
    </template>
  </VigDropdown>
</template>

<style scoped>
.mh-48 {
  max-height: 15rem;
}
</style>
