<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import dayjs from 'dayjs';
import { useMouseInElement } from '@vueuse/core';
import {
  IReportCard,
  EReportCardType,
} from '@/application/types/report/report.types';
import ReportCardNumber from '@/ui/modules/dashboard/performance/cards/ReportCardNumber.vue';
import ReportCardTaskStatus from '@/ui/modules/dashboard/performance/cards/ReportCardTaskStatus.vue';
import ReportCardTaskPerformance from '@/ui/modules/dashboard/performance/cards/ReportCardTaskPerformance.vue';
import ReportCardDomainTasks from '@/ui/modules/dashboard/performance/cards/ReportCardDomainTasks.vue';
import ReportCardDomainOverdueTasks from '@/ui/modules/dashboard/performance/cards/ReportCardDomainOverdueTasks.vue';
import ReportCardExcellenceUsers from '@/ui/modules/dashboard/performance/cards/ReportCardExcellenceUsers.vue';
import ReportCardMostOverdue from '@/ui/modules/dashboard/performance/cards/ReportCardMostOverdue.vue';
import ReportCardActiveUsers from '@/ui/modules/dashboard/performance/cards/ReportCardActiveUsers.vue';
import ReportCardModal from '@/ui/modules/dashboard/performance/cards/ReportCardModal.vue';
import ReportCardMostTasks from '@/ui/modules/dashboard/performance/cards/ReportCardMostTasks.vue';
import ReportCardCreatingMost from '@/ui/modules/dashboard/performance/cards/ReportCardCreatingMost.vue';
import ReportCardAssignedMost from '@/ui/modules/dashboard/performance/cards/ReportCardAssignedMost.vue';

const props = defineProps<{
  reportCard: IReportCard;
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
  };
}>();

const cardRef = ref<any>(null);
const isHoverSetting = ref<boolean>(false);
const { isOutside } = useMouseInElement(cardRef);

const totalTaskCardList = [
  EReportCardType.COMPLETED_TASKS,
  EReportCardType.INCOMPLETE_TASKS,
  EReportCardType.OVERDUE_TASKS,
  EReportCardType.TOTAL_TASKS,
];

const FULL_CALENDAR_VIEW_MODE = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEAR: 4,
};
const currentViewMode = ref<1 | 2 | 3 | 4>(1);

const initComponent = () => {
  const rangeOfDate = dayjs(props.filterData?.dateTo).diff(
    dayjs(props.filterData?.dateFrom),
    'd'
  );
  if (rangeOfDate < 14) currentViewMode.value = 1;
  if (14 <= rangeOfDate && rangeOfDate < 90) currentViewMode.value = 2;
  if (rangeOfDate >= 90) currentViewMode.value = 3;
};

initComponent();

watch(
  () => props.filterData,
  () => {
    initComponent();
  }
);
const VIEW_MODE_LIST = {
  [FULL_CALENDAR_VIEW_MODE.DAY]: {
    key: FULL_CALENDAR_VIEW_MODE.DAY,
    nameCode: 'CALENDAR_TITLE_DAY',
  },
  [FULL_CALENDAR_VIEW_MODE.WEEK]: {
    key: FULL_CALENDAR_VIEW_MODE.WEEK,
    nameCode: 'CALENDAR_TITLE_WEEK',
  },
  [FULL_CALENDAR_VIEW_MODE.MONTH]: {
    key: FULL_CALENDAR_VIEW_MODE.MONTH,
    nameCode: 'CALENDAR_TITLE_MONTH',
  },

  // [FULL_CALENDAR_VIEW_MODE.LIST_WEEK]: {
  //   key: FULL_CALENDAR_VIEW_MODE.LIST_WEEK,
  //   nameCode: 'CALENDAR_TITLE_LIST',
  // },
};

const viewModeList = computed<any>(() => {
  return Object.values(VIEW_MODE_LIST);
});

const SELECT_TOP_LIST = props.reportCard?.filters?.size
  ? props.reportCard?.filters?.size?.options
  : [5, 10, 20];
const currentSize = ref<number>(
  props.reportCard?.filters?.size ? props.reportCard?.filters?.size?.default : 5
);
const currentOrderBy = ref<number>(1);
const isOpenDetailModal = ref<boolean>(false);

const lastTimeUpdated = ref<number>(Date.now());
const ACTION_ON_CARD_LIST = [
  {
    key: 'EXPAND',
    title: 'COMMON_LABEL_VIEW_LARGER',
    iconName: 'zoom',
    onClick: () => {
      isOpenDetailModal.value = true;
    },
  },
  {
    key: 'RELOAD',
    title: 'TASK_LIST_LABEL_RELOAD',
    iconName: 'reload2',
    onClick: () => {
      lastTimeUpdated.value = Date.now();
    },
  },
];

const filterDropdownRef = ref<any>(null);
const onMouseLeaveCard = () => {
  setTimeout(() => {
    if (isHoverSetting.value) return;

    if (filterDropdownRef.value?.onForceClose)
      filterDropdownRef.value?.onForceClose();
  });
};
</script>
<template>
  <div
    class="p-4"
    :style="{
      width: `${reportCard.width || '50%'}`,
      height: `${reportCard.height || '10rem'}`,
      minWidth: `${reportCard.height || '28rem'}`,
    }"
  >
    <div
      ref="cardRef"
      class="
        w-full
        h-full
        p-4
        bg-white
        rounded-md
        shadow
        flex-center flex-col
        hover:ring-1
        ring-gray-300
      "
      @mouseleave="onMouseLeaveCard"
    >
      <div
        class="w-full flex items-center relative"
        :class="
          totalTaskCardList.includes(reportCard?.type)
            ? 'justify-center'
            : 'justify-between'
        "
      >
        <span>{{ $t(reportCard?.titleCode) || reportCard?.title }}</span>
        <div
          v-show="!isOutside || isHoverSetting"
          class="absolute z-10 right-0 flex-center gap-1 bg-white"
        >
          <button
            v-for="item in ACTION_ON_CARD_LIST"
            :key="item?.key"
            v-vig-tooltip="$t(item?.title) || item?.title"
            class="flex-center h-8 w-8 rounded-md hover:bg-gray-100"
            @click="item?.onClick"
          >
            <SynIcon :name="item?.iconName" class="fill-gray-500" />
          </button>

          <VigDropdown
            v-if="
              reportCard?.type == EReportCardType.TASK_PERFORMANCE ||
              props.reportCard?.filters?.size ||
              reportCard?.orders?.length > 0
            "
            ref="filterDropdownRef"
            placement="bottom-end"
            :arrow="false"
            class="hover:text-current bg-white shadow-none"
          >
            <template #dropdown-toggle>
              <button
                class="flex-center h-8 w-8 rounded-md hover:bg-gray-100"
                @click="() => {}"
              >
                <SynIcon name="edit" class="fill-gray-500" />
              </button>
            </template>
            <template #dropdown-menu>
              <div
                ref="settingRef"
                class="p-4"
                @mouseover="isHoverSetting = true"
                @mouseleave="isHoverSetting = false"
              >
                <template
                  v-if="reportCard?.type == EReportCardType.TASK_PERFORMANCE"
                >
                  <div>
                    <span class="text-sm">
                      {{ $t('COMMON_LABEL_VIEW_BY') || 'View by' }}
                    </span>
                  </div>
                  <div class="flex flex-wrap items-center">
                    <SynRadioTag
                      v-for="view in viewModeList"
                      :key="view.key"
                      v-model="currentViewMode"
                      :value="view.key"
                      :label="$t(view.nameCode)"
                      class="mr-1 mb-0.5 mt-0.5 relative"
                    >
                      <SynTag size="circle-small" custom-class="py-1 px-2">
                        <span>{{ $t(view.nameCode) }}</span>
                      </SynTag>
                    </SynRadioTag>
                  </div>
                </template>
                <template v-else-if="reportCard?.filters?.size">
                  <div>
                    <span class="text-sm">
                      {{
                        $t(reportCard?.filters?.size?.title, {
                          size: '',
                          object: $t(
                            reportCard?.filters?.size?.objectKey
                          )?.toLowerCase(),
                        })
                      }}
                    </span>
                  </div>
                  <div class="flex flex-wrap items-center py-2">
                    <SynRadioTag
                      v-for="size in SELECT_TOP_LIST"
                      :key="size"
                      v-model="currentSize"
                      :value="size"
                      :label="size"
                      class="mr-1 mb-0.5 mt-0.5 relative"
                    >
                      <SynTag size="circle-small" custom-class="py-1 px-2">
                        <span>
                          {{
                            $t('COMMON_LABEL_SELECT_TOP_SIZE', {
                              size,
                              object: $t(
                                reportCard?.filters?.size?.subObjectKey ||
                                  reportCard?.filters?.size?.objectKey
                              )?.toLowerCase(),
                            })
                          }}
                        </span>
                      </SynTag>
                    </SynRadioTag>
                  </div>
                </template>
                <template v-if="reportCard?.orders?.length > 0">
                  <div>
                    <span class="text-sm">
                      {{ $t('REPORT_LABEL_ORDER_BY') }}
                    </span>
                  </div>
                  <div class="flex flex-wrap items-center py-2">
                    <SynRadioTag
                      v-for="item in reportCard?.orders"
                      :key="item"
                      v-model="currentOrderBy"
                      :value="item.key"
                      :label="item.key"
                      class="mr-1 mb-0.5 mt-0.5 relative"
                    >
                      <SynTag size="circle-small" custom-class="py-1 px-2">
                        <span>
                          {{ $t(item.titleCode) }}
                        </span>
                      </SynTag>
                    </SynRadioTag>
                  </div>
                </template>
              </div>
            </template>
          </VigDropdown>
        </div>
      </div>
      <div
        :key="lastTimeUpdated"
        class="w-full flex-center flex-1 min-h-0 max-w-[48rem]"
      >
        <template v-if="totalTaskCardList.includes(reportCard?.type)">
          <ReportCardNumber
            :report-card="reportCard"
            :filter-data="filterData"
          />
        </template>
        <template v-else-if="reportCard?.type == EReportCardType.TASK_STATUS">
          <ReportCardTaskStatus :filter-data="filterData" />
        </template>
        <template
          v-else-if="reportCard?.type == EReportCardType.TASK_PERFORMANCE"
        >
          <ReportCardTaskPerformance
            :filter-data="{
              ...filterData,
              viewType: currentViewMode,
            }"
          />
        </template>
        <template v-else-if="reportCard?.type == EReportCardType.DOMAIN_TASKS">
          <ReportCardDomainTasks
            :filter-data="{
              ...filterData,
              size: currentSize,
            }"
          />
        </template>
        <template
          v-else-if="reportCard?.type == EReportCardType.DOMAIN_OVERDUE_TASKS"
        >
          <ReportCardDomainOverdueTasks
            :filter-data="{
              ...filterData,
              size: currentSize,
            }"
          />
        </template>
        <template
          v-else-if="reportCard?.type == EReportCardType.EXCELLENCE_USERS"
        >
          <ReportCardExcellenceUsers
            :filter-data="{
              ...filterData,
              size: currentSize,
            }"
          />
        </template>
        <template v-else-if="reportCard?.type == EReportCardType.ACTIVE_USERS">
          <div class="pt-6 h-full w-full flex-1 min-h-0 overflow-hidden">
            <ReportCardActiveUsers
              :filter-data="{
                ...filterData,
                size: currentSize,
              }"
            />
          </div>
        </template>
        <template v-else-if="reportCard?.type == EReportCardType.MOST_OVERDUE">
          <ReportCardMostOverdue
            :filter-data="{
              ...filterData,
              size: currentSize,
            }"
          />
        </template>
        <template v-else-if="reportCard?.type == EReportCardType.MOST_TASKS">
          <div class="pt-6 h-full w-full flex-1 min-h-0 overflow-hidden">
            <ReportCardMostTasks
              :filter-data="{
                ...filterData,
                size: currentSize,
              }"
            />
          </div>
        </template>
        <template v-else-if="reportCard?.type == EReportCardType.CREATING_MOST">
          <ReportCardCreatingMost
            :filter-data="{
              ...filterData,
              size: currentSize,
              orderBy: currentOrderBy,
            }"
          />
        </template>
        <template v-else-if="reportCard?.type == EReportCardType.ASSIGNED_MOST">
          <ReportCardAssignedMost
            :filter-data="{
              ...filterData,
              size: currentSize,
              orderBy: currentOrderBy,
            }"
          />
        </template>
        <template v-else>
          {{ reportCard?.type }}
        </template>
      </div>
      <div class="flex-center">
        <!-- Filter -->
      </div>
    </div>
  </div>
  <ReportCardModal
    v-if="isOpenDetailModal"
    :key="lastTimeUpdated"
    :settings="{
      viewType: currentViewMode,
      size: currentSize,
      orderBy: currentOrderBy,
    }"
    :report-card="reportCard"
    :filter-data="filterData"
    @close="isOpenDetailModal = false"
  >
    <template #filters>
      <div class="flex-center gap-1 bg-white">
        <button
          v-for="item in ACTION_ON_CARD_LIST.filter((o) => o.key !== 'EXPAND')"
          :key="item?.key"
          v-vig-tooltip="$t(item?.title) || item?.title"
          class="flex-center h-8 w-8 rounded-md hover:bg-gray-100"
          @click="item?.onClick"
        >
          <SynIcon :name="item?.iconName" class="fill-gray-500" />
        </button>

        <VigDropdown
          v-if="
            reportCard?.type == EReportCardType.TASK_PERFORMANCE ||
            props.reportCard?.filters?.size ||
            reportCard?.orders?.length > 0
          "
          ref="filterDropdownRef"
          placement="bottom-end"
          :arrow="false"
          class="hover:text-current bg-white shadow-none"
        >
          <template #dropdown-toggle>
            <button
              class="flex-center h-8 w-8 rounded-md hover:bg-gray-100"
              @click="() => {}"
            >
              <SynIcon name="edit" class="fill-gray-500" />
            </button>
          </template>
          <template #dropdown-menu>
            <div
              ref="settingRef"
              class="p-4"
              @mouseover="isHoverSetting = true"
              @mouseleave="isHoverSetting = false"
            >
              <template
                v-if="reportCard?.type == EReportCardType.TASK_PERFORMANCE"
              >
                <div>
                  <span class="text-sm">
                    {{ $t('COMMON_LABEL_VIEW_BY') || 'View by' }}
                  </span>
                </div>
                <div class="flex flex-wrap items-center">
                  <SynRadioTag
                    v-for="view in viewModeList"
                    :key="view.key"
                    v-model="currentViewMode"
                    :value="view.key"
                    :label="$t(view.nameCode)"
                    class="mr-1 mb-0.5 mt-0.5 relative"
                  >
                    <SynTag size="circle-small" custom-class="py-1 px-2">
                      <span>{{ $t(view.nameCode) }}</span>
                    </SynTag>
                  </SynRadioTag>
                </div>
              </template>
              <template v-else-if="reportCard?.filters?.size">
                <div>
                  <span class="text-sm">
                    {{
                      $t(reportCard?.filters?.size?.title, {
                        size: '',
                        object: $t(
                          reportCard?.filters?.size?.objectKey
                        )?.toLowerCase(),
                      })
                    }}
                  </span>
                </div>
                <div class="flex flex-wrap items-center py-2">
                  <SynRadioTag
                    v-for="size in SELECT_TOP_LIST"
                    :key="size"
                    v-model="currentSize"
                    :value="size"
                    :label="size"
                    class="mr-1 mb-0.5 mt-0.5 relative"
                  >
                    <SynTag size="circle-small" custom-class="py-1 px-2">
                      <span>
                        {{
                          $t('COMMON_LABEL_SELECT_TOP_SIZE', {
                            size,
                            object: $t(
                              reportCard?.filters?.size?.subObjectKey ||
                                reportCard?.filters?.size?.objectKey
                            )?.toLowerCase(),
                          })
                        }}
                      </span>
                    </SynTag>
                  </SynRadioTag>
                </div>
              </template>

              <template v-if="reportCard?.orders?.length > 0">
                <div>
                  <span class="text-sm">
                    {{ $t('REPORT_LABEL_ORDER_BY') }}
                  </span>
                </div>
                <div class="flex flex-wrap items-center py-2">
                  <SynRadioTag
                    v-for="item in reportCard?.orders"
                    :key="item"
                    v-model="currentOrderBy"
                    :value="item.key"
                    :label="item.key"
                    class="mr-1 mb-0.5 mt-0.5 relative"
                  >
                    <SynTag size="circle-small" custom-class="py-1 px-2">
                      <span>
                        {{ $t(item.titleCode) }}
                      </span>
                    </SynTag>
                  </SynRadioTag>
                </div>
              </template>
            </div>
          </template>
        </VigDropdown>
      </div>
    </template>
  </ReportCardModal>
</template>
