import { defineStore } from 'pinia';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

export default defineStore({
    id: 'favorite',
    state: () => ({
        favorites: [] as any[],
        favoriteMenus: [] as any[],
    }),
    getters: {
        getMyFavorites(state): any[] {
            return arrayOrderBy(state.favorites, ['index'], ['asc']);
        },
    },
    actions: {
        async setNewUserFavorites(myFavorites) {
            this.favorites = myFavorites;
        },
        async addDataFavorite(favorite, moreData) {
            if (!favorite || !favorite.type || !favorite.refId) {
                return;
            }

            const newData = {
                ...favorite,
                ...moreData,
                id: favorite?.refId,
                isMe: favorite.refId == getCurrentUserId(),
                avatar: moreData?.avatarUrl,
                type: favorite?.type,
            };
            const isExisted = this.favorites?.some((o) => o?.id == newData?.id);
            if (isExisted) return;

            this.favorites = [...this.favorites, newData];
        },
        async addFavoriteMenu(favorite) {
            if (!favorite || !favorite.type || !favorite.refId) {
                return;
            }

            const newData = {
                ...favorite,
                id: favorite?.refId,
            };
            const isExisted = this.favorites?.some((o) => o?.id == newData?.id);
            if (isExisted) return;

            this.favoriteMenus = [...this.favoriteMenus, newData];
        },
        async removeDataFavorite(favorite) {
            if (!favorite || !favorite.type || !favorite.refId) {
                return;
            }

            this.favorites = this.favorites?.filter(
                (fa) =>
                    !(
                        fa?.type?.toLowerCase() ==
                            favorite.type?.toLowerCase() &&
                        fa?.id == favorite.refId
                    )
            );
            this.favoriteMenus = this.favoriteMenus?.filter(
                (fa) =>
                    !(
                        fa?.type?.toLowerCase() ==
                            favorite.type?.toLowerCase() &&
                        fa?.id == favorite.refId
                    )
            );
        },
    },
});
