<template>
  <tr
    class="cursor-pointer border-b border-gray-50 group rounded"
    :class="isSelected ? 'bg-current-50 border-white' : 'hover:bg-gray-100'"
    @click="$emit('onFileClick')"
  >
    <td class="py-1 px-2 w-14">
      <a :href="fileUrl" @click.prevent>
        <FilePreview
          :file="file"
          size="sm"
          class="h-9 w-14 flex-center relative"
        />
      </a>
    </td>
    <td class="py-1 px-2" style="width: 300px">
      <a v-if="!isRenaming" :href="fileUrl" @click.prevent>
        <div class="truncate text-sm" :title="file?.name">{{ file?.name }}</div>
        <div
          :title="file?.link"
          class="text-overflow-hidden-line text-xs text-current-500"
        >
          <span>{{ file?.link }}</span>
        </div>
      </a>
      <div v-else @click.stop>
        <FileRename
          :file="file"
          input-class="text-sm leading-4"
          @on-cancel="$emit('onRenameCancel')"
          @on-rename="$emit('onRenameConfirm', $event)"
        />
      </div>
    </td>
    <td v-if="file?.category === 'TASK'" class="py-1 text-sm">
      <div
        class="p-1 truncate rounded cursor-pointer hover:bg-current-50"
        @click.stop="$emit('onTaskClick', file?.metadata?.taskCode)"
      >
        <span class="text-current font-semibold mr-2">
          {{ file?.metadata?.taskCode?.split('-').pop() }}
        </span>
        <span :title="file?.metadata?.taskName">{{
          file?.metadata?.taskName
        }}</span>
      </div>
    </td>
    <td
      v-if="filter.category !== 'STORAGE'"
      class="py-1 px-2 truncate text-gray-500 text-sm w-56"
    >
      <div class="flex items-center space-x-2">
        <SynAvatar
          :src="
            fileCreator?.avatarUrl ||
            fileCreator?.avatar ||
            fileCreator?.avatarThumbnailUrl
          "
          :name="fileCreator?.name"
          :type="'user'"
          custom-class="w-6 h-6"
        />
        <span>
          {{ fileCreator?.name }}
        </span>
      </div>
    </td>
    <td
      v-if="!filter?.onlyDeletedFiles"
      class="py-1 px-2 truncate text-gray-500 text-sm w-56"
    >
      <SynLabelDateTime
        format="date_time"
        ignore-timezone
        :datetime="file?.creationTime"
      />
    </td>
    <td
      v-if="filter?.onlyDeletedFiles"
      class="py-1 px-2 truncate text-gray-500 text-sm w-56"
    >
      <SynLabelDateTime
        v-if="file?.deletedDate"
        format="date_time"
        ignore-timezone
        :datetime="file?.deletedDate"
      />
    </td>

    <td
      :class="
        file?.contentType !== $constants.FILE.TYPE.LINK
          ? 'py-1 px-2 truncate text-gray-500 text-sm text-right w-20'
          : 'w-0'
      "
    >
      <span v-if="file?.contentType !== $constants.FILE.TYPE.LINK">
        {{ file.size ? $filters.fileSize(file.size) : '' }}
      </span>
      <span v-else></span>
    </td>
    <td class="relative" :class="showCheckbox ? 'py-1 px-2 w-14' : 'w-0'">
      <div
        v-if="showCheckbox"
        class="w-9 h-9 flex-center group-hover:opacity-100"
        :class="isSelectionMode || isSelected ? 'opacity-100' : 'opacity-0'"
        @click.stop="$emit('onCheckboxClick')"
      >
        <input
          type="checkbox"
          class="vig-checkbox w-5 h-5 align-text-bottom"
          :checked="isSelected"
        />
      </div>

      <div
        class="absolute top-1/2 -left-9 hidden group-hover:block"
        style="margin-top: -1.125rem"
        @click.stop
      >
        <FileActions
          :file="file"
          @on-view-info-click="$emit('onViewInfoClick')"
          @on-rename-click="$emit('onRenameClick')"
          @on-move-to-click="$emit('onMoveToClick')"
          @on-move-to-trash-click="$emit('onMoveToTrashClick')"
          @on-delete-permanently-click="$emit('onDeletePermanentlyClick')"
          @on-restore-click="$emit('onRestoreClick')"
          @on-copy-link-click="$emit('onCopyLinkClick')"
          @on-notification-setting-click="$emit('onNotificationSettingClick')"
          @on-download-click="$emit('onDownloadClick')"
          @on-save-to-resource="$emit('onSaveToResourceClick')"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { defineComponent, toRefs } from 'vue';
import FileActions from '@/ui/modules/ged/file-actions/FileActions.vue';
import FileRename from '../../_commons/file-rename/FileRename.vue';
import useFileItem from '@/ui/composables/resource/file-item-composables';
import FilePreview from '@/ui/modules/ged/_commons/file-preview/FilePreview.vue';

export default defineComponent({
  components: { FilePreview, FileRename, FileActions },
  props: {
    file: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isSelectionMode: {
      type: Boolean,
      default: false,
    },
    isRenaming: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'onFileClick',
    'onCheckboxClick',
    'onTaskClick',
    'onViewInfoClick',
    'onRenameClick',
    'onRenameConfirm',
    'onRenameCancel',
    'onMoveToClick',
    'onCopyLinkClick',
    'onNotificationSettingClick',
    'onMoveToTrashClick',
    'onDeletePermanentlyClick',
    'onRestoreClick',
    'onDownloadClick',
    'onSaveToResourceClick',
  ],
  setup(props) {
    const { file } = toRefs(props);
    const { fileUrl, fileCreator } = useFileItem(file);

    return {
      fileUrl,
      fileCreator,
    };
  },
});
</script>
