import VideoCallRepository from '@/application/repositories/VideoCallRepository';

export default class VideoCallService {
    private static instance: VideoCallService;

    videoCallApi: VideoCallRepository;

    constructor() {
        this.videoCallApi = VideoCallRepository.getInstance();
    }

    public static getInstance(): VideoCallService {
        if (!VideoCallService.instance) {
            // Get from local storage
            VideoCallService.instance = new VideoCallService();
        }

        return VideoCallService.instance;
    }

    conferenceSignature(data) {
        return this.videoCallApi.conferenceSignature(data);
    }

    setVideoCallSession(data) {
        return this.videoCallApi.setVideoCallSession(data);
    }

    setVideoCallParticipants(data) {
        return this.videoCallApi.setVideoCallParticipants(data);
    }

    // AWS chime
    createConference(data) {
        return this.videoCallApi.createConference(data);
    }

    getCallHistories() {
        return this.videoCallApi.getCallHistories();
    }

    forceEndCall(data) {
        return this.videoCallApi.forceEndCall(data);
    }

    actionOnCall(data) {
        return this.videoCallApi.actionOnCall(data);
    }

    answer(data) {
        return this.videoCallApi.answer(data);
    }
}
