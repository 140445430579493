<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <circle
        cx="256"
        cy="256"
        r="256"
        fill="#d80027"
        opacity="1"
        data-original="#d80027"
        class=""
      ></circle>
      <path
        fill="#ffda44"
        d="m256 133.565 27.628 85.029h89.405l-72.331 52.55 27.628 85.03L256 303.623l-72.33 52.551 27.628-85.03-72.33-52.55h89.404z"
        opacity="1"
        data-original="#ffda44"
        class=""
      ></path>
    </g>
  </svg>
</template>
