<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 394.68 512"
  >
    <path
      d="M403.68,39.4H355V20A20,20,0,0,0,335,0H177.66a20,20,0,0,0-20,20V39.4H109a50.06,50.06,0,0,0-50,50V462a50.06,50.06,0,0,0,50,50H403.68a50.06,50.06,0,0,0,50-50V89.4A50.06,50.06,0,0,0,403.68,39.4ZM315,40V78.8H197.66V40Zm98.68,422a10,10,0,0,1-10,10H109a10,10,0,0,1-10-10V89.4a10,10,0,0,1,10-10h48.66V98.8a20,20,0,0,0,20,20H335a20,20,0,0,0,20-20V79.4h48.68a10,10,0,0,1,10,10Z"
      transform="translate(-59)"
    />
    <path
      d="M256,203.4c-10.88,0-19.12,5.68-19.12,14.85v84.13c0,9.18,8.24,14.84,19.12,14.84,10.61,0,19.11-5.9,19.11-14.84V218.25C275.12,209.29,266.62,203.4,256,203.4Zm0,132.77a19,19,0,1,0,18.95,19A19,19,0,0,0,256,336.17Z"
      transform="translate(-59)"
    />
  </svg>
</template>
