<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    data-name="Layer 1"
    viewBox="0 0 227.77 417.48"
  >
    <!-- <defs>
            <style>
                .cls-1{stroke:#a0a0a0;stroke-miterlimit:10;fill:url(#linear-gradient);}
                .cls-2{fill:#fff;}
                .cls-3{fill:url(#linear-gradient-2);}
                .cls-4{fill:#008e8e;}
                .cls-5,.cls-9{fill:#f2cb05;}
                .cls-5{isolation:isolate;opacity:0.63;}
                .cls-6{fill:#005167;}
                .cls-7{fill:#8fcdcd;}
                .cls-8{fill:#61b9b9;}
            </style>
        </defs> -->
    <path
      class="cls-1"
      style="stroke: #a0a0a0; stroke-miterlimit: 10; fill: #000"
      d="M487.15,198.27h-2.78v-15.6A31.86,31.86,0,0,0,452.32,151H297.89a31.86,31.86,0,0,0-32.06,31.67l0,15.6h-3.73a.85.85,0,0,0-.85.84v32.67a.85.85,0,0,0,.85.84h3.71v4.48h-3.7a.85.85,0,0,0-.85.84v32.67a.85.85,0,0,0,.85.84h3.68l-.21,264.36a31.87,31.87,0,0,0,32.07,31.67H452.05a31.87,31.87,0,0,0,32.06-31.67l.23-303.19h2.81a.85.85,0,0,0,.85-.84V199.11A.85.85,0,0,0,487.15,198.27Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-2"
      style="fill: #fff"
      d="M301.47,555H446.91a26.43,26.43,0,0,0,26.5-26.36l.27-342.31A26.42,26.42,0,0,0,447.19,160H301.74a26.43,26.43,0,0,0-26.5,26.36L275,528.65A26.43,26.43,0,0,0,301.47,555Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-3"
      style="fill: #000"
      d="M375.51,155H303.75v4.83h12.64a8.51,8.51,0,0,1,8.56,8.46,8.51,8.51,0,0,0,8.56,8.46h83.18a8.52,8.52,0,0,0,8.57-8.46,8.51,8.51,0,0,1,8.56-8.46h12.64V155Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-2"
      style="fill: #fff"
      d="M387.68,167.72H349.07a2.32,2.32,0,0,1-2.34-2.31h0a2.32,2.32,0,0,1,2.34-2.31h38.61a2.32,2.32,0,0,1,2.34,2.31h0A2.33,2.33,0,0,1,387.68,167.72Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-2"
      style="fill: #fff"
      d="M394.66,167.72h0a2.33,2.33,0,0,1-2.34-2.31h0a2.32,2.32,0,0,1,2.34-2.31h0a2.32,2.32,0,0,1,2.34,2.31h0A2.33,2.33,0,0,1,394.66,167.72Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-4"
      style="fill: #008e8e"
      d="M361.89,312.31V362.2c0,27.56-23.44,49.9-52.34,49.9h0V312.31c0-13.78,11.71-24.94,26.17-24.94h0c14.45,0,26.17,11.16,26.17,24.94Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-5"
      style="isolation: isolate; opacity: 0.63; fill: #f2cb05"
      d="M327.78,288.46c-10.86,3.29-18.24,12.91-18.23,23.78v3.24c1.67,12.45,12.78,21.78,25.95,21.78h26.39v-25c0-13.11-10.65-24-24.36-24.88h-2A27.16,27.16,0,0,0,327.78,288.46Z"
      transform="translate(-260.73 -150.5)"
    />
    <ellipse
      class="cls-6"
      style="fill: #005167"
      cx="75.05"
      cy="161.73"
      rx="21.81"
      ry="20.79"
    />
    <path
      class="cls-7"
      style="fill: #8fcdcd"
      d="M404.2,346.44h0a6,6,0,0,1,7.91-2l5,2.76a5.38,5.38,0,0,1,2.12,7.54h0a5.94,5.94,0,0,1-7.91,2l-5-2.76A5.38,5.38,0,0,1,404.2,346.44Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-8"
      style="fill: #61b9b9"
      d="M389.16,363.58h0a6,6,0,0,1,8.19,0l4.09,3.9a5.33,5.33,0,0,1,0,7.8h0a6,6,0,0,1-8.18,0h0l-4.1-3.9A5.33,5.33,0,0,1,389.16,363.58Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-4"
      style="fill: #008e8e"
      d="M379.46,392.18h0a6,6,0,0,1-7.92-2h0l-2.9-4.78a5.39,5.39,0,0,1,2.21-7.52,6,6,0,0,1,7.83,2l2.89,4.78A5.39,5.39,0,0,1,379.46,392.18Z"
      transform="translate(-260.73 -150.5)"
    />
    <path
      class="cls-9"
      style="fill: #f2cb05"
      d="M337.54,287.37c13.71.91,24.34,11.77,24.35,24.88v25h25.95c28.91,0,52.34-22.34,52.34-49.89Z"
      transform="translate(-260.73 -150.5)"
    />
  </svg>
</template>
