<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M469.333,64H42.667C19.146,64,0,83.135,0,106.667v256c0,23.531,19.146,42.667,42.667,42.667H224v21.333h-64			c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h192c5.896,0,10.667-4.771,10.667-10.667			c0-5.896-4.771-10.667-10.667-10.667h-64v-21.333h181.333c23.521,0,42.667-19.135,42.667-42.667v-256			C512,83.135,492.854,64,469.333,64z M266.667,426.667h-21.333v-21.333h21.333V426.667z M490.667,362.667			c0,11.76-9.563,21.333-21.333,21.333H42.667c-11.771,0-21.333-9.573-21.333-21.333v-256c0-11.76,9.563-21.333,21.333-21.333			h426.667c11.771,0,21.333,9.573,21.333,21.333V362.667z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<style scoped></style>
