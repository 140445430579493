<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import { ImageDefault } from '@/ui/components/photo-upload/imageDefault.js';
import { openNotification } from '@/ui/hooks/commonHook';
import { AdminType } from '@/ui/common/constants/constant';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { ResponseStatusCode } from '@/ui/common/constants/constant';
import organizationStore from '@/store/organization';
import userStore from '@/store/user';

const props = withDefaults(
  defineProps<{
    isColumnLayout: boolean;
    isFetchInvited: boolean;
    isHiddenDetail: boolean;
    userNumber: number;
  }>(),
  {
    userNumber: 1,
  }
);

defineEmits(['onInvite', 'totalPending']);

const _organizationStore = organizationStore();
const _userStore = userStore();

const pendingUsers = computed<any[]>(() => {
  return _organizationStore._pendingUsers?.slice(-(5 - props.userNumber)) || [];
});

const initComponent = () => {};

const loadingBtn = ref({ resend: false, cancel: false } as any);
const errorOnResend = ref(false);
const isColumn = computed(() => {
  return props.isColumnLayout;
});

watch(
  () => props.isFetchInvited,
  () => {
    initComponent();
  }
);

onMounted(() => {
  _userStore.fetchAllProfile();
  initComponent();
});

const convertToLocalDate = (utcDate) => {
  if (!utcDate) return utcDate;
  if (!utcDate.includes('Z')) {
    utcDate = utcDate + 'Z';
  }
  return new Date(utcDate);
};

const showExpiredLabel = (date) => {
  if (!date) return true;
  return new Date() > convertToLocalDate(date);
};

const actionSuccess = (action, address) => {
  const contentMessage =
    action == 'resend'
      ? translate('USERMANAGEMENT_PENDING_ACTION_RESENDSUCCESS', {
          address: address,
        })
      : translate('USERMANAGEMENT_PENDING_ACTION_CANCELSUCCESS', {
          address: address,
        });
  openNotification({
    body: contentMessage,
    duration: 3000,
  });
};

const onResendInvitation = async (inviteId, emailOrPhone) => {
  try {
    loadingBtn.value.resend = true;
    loadingBtn.value.id = inviteId;

    await _organizationStore.resendInvitation([inviteId]);
    await _organizationStore.getPendingUsers();

    loadingBtn.value.resend = false;
    loadingBtn.value.id = 0;

    actionSuccess('resend', emailOrPhone);
  } catch (error: any) {
    errorOnResend.value =
      error?.error?.code == ResponseStatusCode.InviteJoinedByOtherMethods ||
      error?.error?.code == ResponseStatusCode.InviteUserDeleted;
  }
};

const onCancelInvitation = async (inviteId, emailOrPhone) => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: translate('USERMANAGEMENT_PENDING_CONFIRM_CANCELED'),

    confirmable: true,
    closeable: true,
  });
  const confirmed = await ask();
  if (confirmed) {
    loadingBtn.value.cancel = true;
    loadingBtn.value.id = inviteId;

    await _organizationStore.cancelInvitation([inviteId]);

    loadingBtn.value.cancel = false;
    loadingBtn.value.id = 0;

    actionSuccess('cancel', emailOrPhone);

    await initComponent();
  }
};
</script>

<template>
  <div
    v-for="(user, index) in pendingUsers"
    :key="user"
    class="w-full h-full px-4 pb-8 relative hover-to-show__parent"
    :style="`height:${isColumn ? '40rem' : '20rem'};`"
  >
    <div
      class="
        w-full
        h-full
        rounded-xl
        border-dashed border-2 border-current-200
        flex
        divide-x-2 divide-dashed divide-current-200
      "
    >
      <div
        class="
          h-full
          flex flex-col
          justify-between
          items-center
          pb-2
          relative
          rounded-l-lg
        "
        :class="isHiddenDetail ? 'w-full' : 'w-1/3'"
      >
        <div
          class="
            bg-white
            flex-center
            rounded-full
            absolute
            right-0
            top-0
            m-1
            w-6
            h-6
          "
        >
          <SynIcon
            name="heart"
            custom-class="w-4 h-4 fill-current text-current"
            :is-active="false"
          />
        </div>
        <div
          class="
            flex-1 flex
            items-center
            flex-col
            hover-to-show__parent
            cursor-pointer
            pt-6
          "
        >
          <div v-vig-tooltip="user?.emailOrPhone" class="w-16 h-16">
            <syn-avatar
              class="cursor-pointer"
              custom-class="w-16 h-16"
              :src="ImageDefault('USER')['ANIMALS'].objects[index]"
            ></syn-avatar>
          </div>
          <div
            v-if="isHiddenDetail"
            class="
              mt-6
              w-full
              flex flex-col
              justify-center
              items-center
              space-y-2
            "
          >
            <span
              class="px-2 rounded-full text-xs flex-center w-max"
              :class="
                user.adminType == AdminType.Admin
                  ? 'bg-orange-400 text-white'
                  : user.adminType == AdminType.User
                  ? 'bg-current text-white'
                  : 'bg-gray-400 text-white'
              "
            >
              {{
                user.adminType == AdminType.Admin
                  ? 'Admin'
                  : user.adminType == AdminType.User
                  ? 'User'
                  : 'Visitor'
              }}
            </span>
            <span
              class="
                text-sm
                flex flex-wrap
                px-4
                font-medium
                text-current-500 text-center
              "
              style="word-break: break-word"
              >{{ user?.emailOrPhone }}</span
            >
          </div>
        </div>
        <div class="flex-center space-x-2 mb-2">
          <div
            v-vig-tooltip="$t('USERMANAGEMENT_PENDING_ACTION_RESEND')"
            class="
              flex-center
              cursor-pointer
              fill-green-500
              p-2
              bg-white
              shadow-lg
              rounded-full
              hover:bg-green-50
              w-8
              h-8
            "
            @click="onResendInvitation(user?.id, user?.emailOrPhone)"
          >
            <SynIconCustom
              :name="
                loadingBtn.resend && loadingBtn.id == user.id
                  ? 'spinner'
                  : 'plane-paper'
              "
              :class="[
                loadingBtn.resend && loadingBtn.id == user.id
                  ? 'animate-spin'
                  : '',
              ]"
              class="w-3 h-3"
            />
          </div>
          <div
            v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
            class="
              flex-center
              cursor-pointer
              fill-red-500
              p-2
              bg-white
              shadow-lg
              rounded-full
              hover:bg-red-50
              w-8
              h-8
            "
            @click="onCancelInvitation(user?.id, user?.emailOrPhone)"
          >
            <SynIconCustom
              :name="
                loadingBtn.cancel && loadingBtn.id == user.id
                  ? 'spinner'
                  : 'cancel'
              "
              :class="[
                loadingBtn.cancel && loadingBtn.id == user.id
                  ? 'animate-spin'
                  : '',
              ]"
              class="w-3 h-3"
            />
          </div>
        </div>
      </div>

      <div v-if="!isHiddenDetail" class="flex-1 h-full p-2 flex flex-col">
        <div class="text-sm flex justify-center flex-col space-y-1">
          <div
            class="
              font-semibold
              py-4
              bg-current-50
              text-current-700 text-sm
              break-all
              text-center
              rounded-md
              px-3
            "
          >
            <span>{{ user?.emailOrPhone }}</span>
          </div>
          <div class="flex space-x-2 pt-4">
            <span class="font-medium text-current-500">{{
              $t('USERMANAGEMENT_PENDING_HEADER_ROLE') + ' :'
            }}</span>
            <span
              class="px-2 rounded-full text-xs flex-center"
              :class="
                user.adminType == AdminType.Admin
                  ? 'bg-orange-400 text-white'
                  : user.adminType == AdminType.User
                  ? 'bg-current text-white'
                  : 'bg-gray-400 text-white'
              "
            >
              {{
                user.adminType == AdminType.Admin
                  ? 'Admin'
                  : user.adminType == AdminType.User
                  ? 'User'
                  : 'Visitor'
              }}
            </span>
          </div>
          <div class="flex space-x-2">
            <span class="font-medium text-current-500">{{
              $t('USERMANAGEMENT_PENDING_HEADER_INVITE_DATE') + ' :'
            }}</span>
            <span>
              <SynLabelDateTime format="date" :datetime="user?.invitedTime" />
            </span>
            <span>
              <SynLabelDateTime format="time" :datetime="user?.invitedTime" />
            </span>
          </div>
          <div class="flex space-x-2">
            <span class="font-medium text-current-500">{{
              $t('USERMANAGEMENT_PENDING_HEADER_EXPIREDATE') + ' :'
            }}</span>
            <span
              :class="
                showExpiredLabel(user?.tokenExpirationDate)
                  ? 'text-red-600'
                  : ''
              "
            >
              <SynLabelDateTime
                format="date_time"
                :datetime="user?.tokenExpirationDate"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
