<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g id="_22_Right" xmlns="http://www.w3.org/2000/svg" data-name="22 Right">
        <path
          d="m28.8608 16.874-20.25 11.25a.9973.9973 0 0 1 -.4858.126 1.0149 1.0149 0 0 1 -.98-1.1963l2.21-11.0537-2.2105-11.0537a1.0088 1.0088 0 0 1 1.4663-1.07l20.25 11.25a1.0092 1.0092 0 0 1 0 1.7477zm-24.2553 10.5723 2.25-11.25a1.0023 1.0023 0 0 0 0-.3926l-2.25-11.25a1.0005 1.0005 0 0 0 -1.9609.3926l2.2104 11.0537-2.21 11.0537a1.0107 1.0107 0 0 0 .981 1.1963 1 1 0 0 0 .9795-.8037z"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
