<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 381 275"
  >
    <path
      d="M466.43,279H280.56C256.05,279,236,302,236,330.2V502.8C236,531,256.05,554,280.56,554H466.44C491,554,511,531,511,502.8V330.21C511,301.53,490.94,279,466.43,279ZM451.55,484.9a8.49,8.49,0,0,1-6,14.49,8.38,8.38,0,0,1-6-2.49l-68-68-68,68a8.51,8.51,0,0,1-6,2.49,8.38,8.38,0,0,1-6-2.49,8.47,8.47,0,0,1,0-12l68-68-68-68a8.49,8.49,0,0,1,12-12l68,68,68-68a8.49,8.49,0,0,1,12,12l-68,68Z"
      transform="translate(-236 -279)"
    />
    <path
      d="M589.74,307.89a16.39,16.39,0,0,0-6.27,3.58L526,357.38V475.72l57.84,45.92c10.69,8.67,23.95,3.57,30.21-11.23a40.8,40.8,0,0,0,3-15.3V337.48C617,318.61,604.11,303.3,589.74,307.89Z"
      transform="translate(-236 -279)"
    />
  </svg>
</template>
