<template>
  <SynModalNoFocus
    is-hidden-footer
    z-index="z-50"
    container-class="w-11/12 2xl:w-9/12"
    container-style="height: 95vh; max-height:unset; max-width:1200px"
    style-body="p-0 flex flex-col relative"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div class="flex items-center justify-between space-x-3">
        <div class="truncate text-lg">{{ file?.name }}</div>
        <div
          v-if="file?.metadata?.taskId"
          class="truncate text-base font-normal"
        >
          <span class="text-current font-semibold mr-2">
            {{ file?.metadata?.taskCode?.split('-').pop() }}
          </span>
          <span>{{ file?.metadata?.taskName }}</span>
        </div>
        <div class="flex-center space-x-2 pr-3">
          <div class="truncate text-base font-normal">
            <!-- {{ $filters.dayjs(file?.creationTime, 'MMM DD, YYYY, hh:mm A') }} -->
            <SynLabelDateTime
              format="date_time"
              :datetime="file?.creationTime"
            />
          </div>
          <vig-button
            ghost
            color="blue"
            rounded="rounded-full"
            padding="p-1"
            @click="$emit('onInfoClick')"
          >
            <SynIcon name="Info" custom-class="w-6 h-6" class="fill-blue-500" />
          </vig-button>
          <vig-button
            v-if="!isGoogleFile(file?.contentType)"
            ghost
            color="gray"
            rounded="rounded-full"
            padding="p-1"
            @click="onDownloadClick"
          >
            <SynIcon
              name="Download"
              custom-class="w-6 h-6"
              class="fill-gray-500"
            />
          </vig-button>
        </div>
      </div>
    </template>
    <template #body>
      <FileViewer
        :file="file"
        :name="file?.name"
        :path="file?.pathUrl || file?.link"
        :content-type="file?.contentType"
        :has-download="false"
        :is-hidden-name="true"
        @close="$emit('onClose')"
      />
    </template>
  </SynModalNoFocus>
</template>

<script>
import FileViewer from './FileViewer.vue';
import { saveAs } from 'file-saver';
import axios from 'axios';
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
import { isGoogleFile } from '@/ui/hooks/fileHook';

export default {
  components: { SynModalNoFocus, FileViewer },
  props: {
    file: {
      type: Object,
      default: null,
      required: true,
    },
  },
  emits: ['onClose', 'onInfoClick'],
  setup(props) {
    const onDownloadClick = async () => {
      if (!props.file?.pathUrl) return;

      try {
        const res = await axios.get(props.file.pathUrl, {
          responseType: 'blob',
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        });
        saveAs(res.data, props.file.name);
      } catch (e) {
        console.log(e);
      }
    };

    return {
      onDownloadClick,
    };
  },
  methods: { isGoogleFile },
};
</script>
