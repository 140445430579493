import EmbedAuthApi from '@/embed/api/authen-api';

export default class EmbedAuthServices {
    private static instance: EmbedAuthServices;
    authenApi: EmbedAuthApi;

    constructor() {
        this.authenApi = EmbedAuthApi.getInstance();
    }

    public static getInstance(): EmbedAuthServices {
        if (!EmbedAuthServices.instance) {
            // Get from local storage
            EmbedAuthServices.instance = new EmbedAuthServices();
        }

        return EmbedAuthServices.instance;
    }

    selectOrganization(data) {
        return this.authenApi.selectOrganization(data);
    }

    loginWithPassword(data) {
        return this.authenApi.loginWithPassword(data);
    }

    getCurrentLoginInformations() {
        return this.authenApi.getCurrentLoginInformations();
    }
    forgotPassword(data) {
        return this.authenApi.forgotPassword(data);
    }
    validateResetPasswordCode(data) {
        return this.authenApi.validateResetPasswordCode(data);
    }
    resetPassword(data) {
        return this.authenApi.resetPassword(data);
    }

    checkPhone(phoneNumber) {
        return this.authenApi.checkPhone(phoneNumber);
    }
    checkEmail(email) {
        return this.authenApi.checkEmail(email);
    }

    checkUserHasPasswordByEmail(data) {
        return this.authenApi.checkUserHasPasswordByEmail(data);
    }

    checkUserHasPasswordByPhone(data) {
        return this.authenApi.checkUserHasPasswordByPhone(data);
    }

    saveUserProfile(data) {
        return this.authenApi.saveUserProfile(data);
    }

    verifyUserPhoneNumber(data) {
        return this.authenApi.verifyUserPhoneNumber(data);
    }

    verifyUserEmail(data) {
        return this.authenApi.verifyUserEmail(data);
    }

    sendVerificationCodeByPhoneNumber(data) {
        return this.authenApi.sendVerificationCodeByPhoneNumber(data);
    }

    sendVerificationCodeByEmail(data) {
        return this.authenApi.sendVerificationCodeByEmail(data);
    }

    reSendVerificationCodeByPhoneNumber(data) {
        return this.authenApi.reSendVerificationCodeByPhoneNumber(data);
    }

    reSendVerificationCodeByEmail(data) {
        return this.authenApi.reSendVerificationCodeByEmail(data);
    }

    loginByPhoneCode(data) {
        return this.authenApi.loginByPhoneCode(data);
    }

    loginByEmailCode(data) {
        return this.authenApi.loginByEmailCode(data);
    }
    submitVerificationUserEmailToken(data) {
        return this.authenApi.submitVerificationUserEmailToken(data);
    }
    submitVerificationUserEmailTokenForForgotPassword(data) {
        return this.authenApi.submitVerificationUserEmailTokenForForgotPassword(
            data
        );
    }
    refresh(data) {
        return this.authenApi.refresh(data);
    }
    logout(data) {
        return this.authenApi.logout(data);
    }
    getUserOrganizationName(userId) {
        return this.authenApi.getUserOrganizationName(userId);
    }
}
