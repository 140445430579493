<script setup lang="ts">
import interact from 'interactjs';
import { onMounted, ref } from 'vue';

onMounted(() => {
  initInteract(myDraggable.value);
});

const myDraggable = ref();
const initInteract = (selector) => {
  interact(selector).draggable({
    inertia: true,
    max: Infinity,
    maxPerElement: Infinity,
    allowFrom: '.drag-handle',
    modifiers: [
      interact.modifiers.restrictRect({
        // endOnly: true,
        restriction: 'parent',
      }),
    ],
    // autoScroll: true,
    onmove(event) {
      const target = event.target,
        // keep the dragged position in the data-x/data-y attributes
        x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
        y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
      // translate the element
      target.style.webkitTransform = target.style.transform =
        'translate(' + x + 'px, ' + y + 'px)';
      // update the posiion attributes
      target?.setAttribute('data-x', x);
      target?.setAttribute('data-y', y);
    },
  });
};

const unSet = () => {
  interact(myDraggable.value).unset();
};

const enableDragging = (enable) => {
  interact(myDraggable.value).draggable(enable);
};

defineExpose({
  unSet,
  enableDragging,
});
</script>

<template>
  <div ref="myDraggable" class="z-20 flex absolute">
    <slot name="move"></slot>
  </div>
</template>
