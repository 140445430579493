<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import userStore from '@/store/user';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

const props = defineProps<{
  modelValue: any[];
}>();
const emit = defineEmits([
  'onClose',
  'onConversationClick',
  'update:modelValue',
]);

onMounted(async () => {
  if (
    !_userStore.listShortInfosUser ||
    _userStore.listShortInfosUser.length == 0
  ) {
    await _userStore.getShortInfosUser;
  }

  const userList = cloneDeep(
    _userStore.listShortInfosUser.filter((o) => o.id !== getCurrentUserId())
  );
  allUsers.value = userList?.map((o) => {
    return {
      ...o,
      isSelected: props.modelValue?.some((x) => x == o.id),
    };
  });
});

const _userStore = userStore();
const allUsers = ref<any[]>([]);

const selectedUsers = computed(() => {
  return (allUsers.value || []).filter((user) => user?.isSelected);
});

const onContactClick = (contact) => {
  if (!contact) return;

  const index = allUsers.value.indexOf(contact);
  if (index > -1) {
    allUsers.value[index].isSelected = !allUsers.value[index].isSelected;
  }
  emit(
    'update:modelValue',
    selectedUsers.value?.map((o) => o.id)
  );
};
const onRemoveSelectedUser = (contact) => {
  if (!contact) return;

  const index = allUsers.value.indexOf(contact);
  if (index > -1) {
    allUsers.value[index].isSelected = false;
  }

  emit(
    'update:modelValue',
    selectedUsers.value?.map((o) => o.id)
  );
};

const searchText = ref(null);
const filteredUsers = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return allUsers.value;

  return (allUsers.value || []).filter(
    (user) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
  );
});
</script>

<template>
  <div class="flex flex-col overflow-y-auto small-scrollbar w-full">
    <div v-if="selectedUsers?.length > 0">
      <div
        v-for="contact of selectedUsers"
        :key="contact"
        class="
          rounded-full
          bg-current-50
          text-gray-800 text-sm
          flex-center
          space-x-2
          w-max
          p-1
          m-0.5
          float-left
        "
      >
        <syn-avatar
          custom-class="w-5 h-5"
          :src="contact?.avatar?.replace('original', 'mini')"
          :name="contact?.name"
        />
        <span class="truncate" style="max-width: 8rem">{{
          contact?.name
        }}</span>
        <button
          class="
            p-0.5
            rounded-full
            bg-opacity-50
            hover:bg-current-400 hover:fill-white hover:text-white
            flex-center
          "
          @click.stop="onRemoveSelectedUser(contact)"
        >
          <SynIcon name="Close" custom-class="w-4 h-4" />
        </button>
      </div>
    </div>
    <div class="py-2">
      <VigSearchBox v-model="searchText" input-class="text-sm" />
    </div>
    <div
      class="px-2 flex-1 overflow-auto small-scrollbar pb-3"
      style="max-height: 200px"
    >
      <div v-if="filteredUsers?.length">
        <div
          v-for="(user, index) in filteredUsers"
          :key="index"
          class="
            p-2
            flex
            items-center
            justify-between
            cursor-pointer
            hover:bg-gray-100
          "
          @click="onContactClick(user, false)"
        >
          <div class="flex-center space-x-2">
            <syn-avatar
              :src="user?.avatar"
              :name="user?.name"
              custom-class="w-6 h-6"
            ></syn-avatar>
            <span class="text-sm truncate" style="max-width: 16rem">{{
              user?.name
            }}</span>
            <span v-if="user?.userId === getCurrentUserId()">
              ({{ $t('COMMON_LABEL_ME') || 'Me' }})
            </span>
          </div>
          <SynIcon
            name="checkbox"
            :is-active="user.isSelected"
            class="fill-current"
            custom-class="w-4 h-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>
