<script setup lang="ts">
import { computed } from 'vue';
import TaskProject from '@/ui/modules/task/detail/_commons/task-project/TaskProject.vue';

const props = defineProps<{
  task: any;
  domains: any[];
  projects: any[];
  readonly?: boolean;
}>();

defineEmits([]);

const taskDomain = computed(() =>
  props.domains?.find((domain) => domain?.id === props.task?.domainId)
);

const taskProject = computed(() =>
  props.projects?.find((project) => project?.id === props.task?.projectId)
);

const taskSubProject = computed(() =>
  props.projects?.find((project) => project?.id === props.task?.subprojectId)
);
</script>

<template>
  <div
    class="px-1 py-1 rounded w-max flex items-start justify-center flex-col"
    :class="{ 'hover:bg-current-50 cursor-pointer': !readonly }"
  >
    <div class="text-xs">
      <span
        v-vig-tooltip="taskDomain?.name || task?.domainName"
        class="text-overflow-hidden-line"
      >
        {{ taskDomain?.name || task?.domainName }}</span
      >
      <span v-if="taskDomain?.invisible" class="italic">
        ({{ $t('COMMON_LABEL_INVISIBLE') }})</span
      >
    </div>

    <div
      v-if="taskProject"
      class="flex-center space-x-2 text-xs text-gray-400"
      style="max-width: 8rem"
    >
      <TaskProject :task-project="taskProject" />
      <template v-if="taskSubProject">
        <span>-</span>
        <TaskProject :task-project="taskSubProject" />
      </template>
    </div>
  </div>
</template>
