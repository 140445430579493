<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});
const beforeActivity = computed(() => props.beforeUpdate);
const afterActivity = computed(() => props.afterUpdate);
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 items-center justify-between pr-2">
      <div
        v-if="beforeActivity?.DomainName"
        class="
          flex
          items-center
          bg-current-50
          text-xs
          space-x-1
          px-2
          py-1
          rounded
        "
      >
        <span v-if="beforeActivity?.DomainName?.trim() == 'General'">
          {{ $t('COMMON_LABEL_GENERAL_DOMAIN') }}
        </span>
        <span v-else>{{ beforeActivity?.DomainName?.trim() }}</span>
        <span v-if="beforeActivity?.ProjectId">{{
          ' - ' + beforeActivity?.ProjectName?.trim()
        }}</span>
        <span v-if="beforeActivity?.SubprojectId">{{
          ' - ' + beforeActivity?.SubProjectName?.trim()
        }}</span>
      </div>
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
      <span>→</span>
    </div>

    <div class="flex w-1/2 justify-start pl-4">
      <div
        v-if="afterActivity?.DomainName"
        class="
          flex
          items-center
          bg-current-50
          text-xs
          space-x-1
          px-2
          py-1
          rounded
        "
      >
        <span v-if="afterActivity?.DomainName?.trim() == 'General'">
          {{ $t('COMMON_LABEL_GENERAL_DOMAIN') }}
        </span>
        <span v-else>{{ afterActivity?.DomainName?.trim() }}</span>
        <span v-if="afterActivity?.ProjectId">{{
          ' - ' + afterActivity?.ProjectName?.trim()
        }}</span>
        <span v-if="afterActivity?.SubprojectId">{{
          ' - ' + afterActivity?.SubProjectName?.trim()
        }}</span>
      </div>
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </div>
  </div>
</template>
