export default {
    install: (app) => {
        app.directive('add-tab-index', {
            beforeMount() {
                // console.log('beforeMount value', {
                //     'binding.value': binding.value,
                //     'el': el
                // });
            },
            updated(el, binding) {
                const elementIdList = binding.value?.elementIdList
                    ? binding.value?.elementIdList
                    : [];

                for (const [index, elementId] of elementIdList.entries()) {
                    const element = document.getElementById(elementId);

                    if (!element) return;

                    element.tabIndex = index + 1;
                }
            },
        });
    },
};
