<script setup>
import { computed } from 'vue';

const emit = defineEmits(['change']);
const props = defineProps({
  options: {
    default: () => [],
    type: Array,
  },
  keyText: {
    default: 'label',
    type: String,
  },
});

const listOptions = computed(() => {
  let data = props?.options;
  data?.map((el, index) => {
    if (index !== 0) {
      el.isActive = false;
    } else el.isActive = true;
  });
  return data;
});

let timerOut = null;

const onClickOption = (opt, index, value) => {
  if (value) {
    listOptions.value[index].isDeactive = value || false;
    listOptions.value.map((el, ind) => {
      if (ind !== index) {
        el.isActive = !value;
      } else el.isActive = value;
    });
  }

  clearTimeout(timerOut);
  timerOut = setTimeout(async () => {
    // return 1 array
    emit(
      'change',
      listOptions.value?.filter((el) => el?.isActive)
    );
  }, 500);
};
</script>

<template>
  <div class="flex items-center space-x-2">
    <div
      v-for="(opt, index) in listOptions"
      :key="opt"
      class="py-1 px-3 rounded cursor-pointer text-sm flex-center space-x-2"
      :class="
        opt?.isActive
          ? `border border-${opt.colorKey}-500 text-${opt.colorKey}-500 bg-${opt.colorKey}-50 hover:bg-${opt.colorKey}-100`
          : `bg-gray-100 text-gray-500 hover:bg-gray-200`
      "
      @click="onClickOption(opt, index, !opt?.isActive)"
    >
      <span
        class="w-1 h-1 p-1 rounded-full"
        :class="`bg-${opt?.colorKey}-400`"
      ></span>
      <span>
        {{ $t(opt[keyText]) }}
      </span>
    </div>
  </div>
</template>
