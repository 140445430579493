<script setup lang="ts">
// import { IStepTemplateEntity } from '@/domain/entities/workflow/StepTemplateEntity';
import { computed, ref } from 'vue';
import WorkflowStepItemUpdate from '@/ui/modules/workflow/workflow-step/WorkflowStepItemUpdate.vue';
import WorkflowStepDetailModal from '@/ui/modules/workflow/workflow-step/WorkflowStepDetailModal.vue';
import WorkflowStepNextSteps from '@/ui/modules/workflow/workflow-step/WorkflowStepNextSteps.vue';
import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import {
  taskIdGlobal,
  isShowTaskDetailGlobal,
} from '@/ui/modules/task/task-global-state';
import WorkflowStepCodeLabel from '@/ui/modules/workflow/workflow-step/WorkflowStepCodeLabel.vue';
import WorkflowStepNameUpdate from '@/ui/modules/workflow/workflow-step/WorkflowStepNameUpdate.vue';
import { genStyleByStepStatus } from '@/application/constants/task/workflow.const';
import TaskApprovalShortInfo from '@/ui/modules/task/approval/TaskApprovalShortInfo.vue';
import { TaskApprovalStatus } from '@/application/types/task/task.types';
import TaskSetApprovalModal from '@/ui/modules/task/approval/TaskSetApprovalModal.vue';

// const CURRENT_COMPONENT_ID = 'workflow-step-item';

const props = withDefaults(
  defineProps<{
    workflowId?: string;
    stepItem: ITaskWorkflowStep;
    readonly?: boolean;
    sortable?: boolean;
    actionList?: string[]; // DUPLICATE | EDIT | REMOVE
    isOpeningStepIds: string[];
    isFocusingStepIds: string[];
    stepByIds?: any;
    viewMode: 'VIEW' | 'EDIT' | 'TASK_VIEW' | 'ATTACH_TO_TASK';
    isUpdateImmediately?: boolean;
    hasNoConnection?: boolean;
  }>(),
  {
    actionList: () => ['EDIT'],
  }
);

const emit = defineEmits<{
  (e: 'change', data: any): void;
  (e: 'duplicateStep', data: any): void;
  (e: 'update:isOpeningStepIds', data: any): void;
  (e: 'update:isFocusingStepIds', data: any): void;
  (e: 'setStartPoint'): void;
  (e: 'remove'): void;
  (e: 'focusShape', shapeId: string): void;
}>();

const actionDataListByKey = {
  DUPLICATE: {
    key: 'DUPLICATE',
    iconName: 'duplicate',
    labelCode: 'COMMON_LABEL_DUPLICATE',
    color: '',
    handleClick: (step) => {
      emit('duplicateStep', step);
    },
  },
  EDIT: {
    key: 'EDIT',
    iconName: 'edit',
    labelCode: 'COMMON_LABEL_EDIT',
    color: '',
    handleClick: async () => {
      onFocus();
      onOpenEditWorkflowStep();
    },
  },
  REDIRECT: {
    key: 'REDIRECT',
    iconName: 'redirect',
    labelCode: 'COMMON_LABEL_REDIRECT',
    color: '',
    handleClick: async () => {
      taskIdGlobal.value = props.stepItem?.taskId;
      isShowTaskDetailGlobal.value = true;
    },
  },
  REMOVE: {
    key: 'REMOVE',
    iconName: 'trash',
    labelCode: 'COMMON_LABEL_REMOVE',
    color: '',
    handleClick: async () => {
      emit('remove');
    },
  },
};
const actionDataList = computed<any[]>(() => {
  return props.actionList
    ?.filter((key) => {
      if (key == 'REDIRECT') return props.stepItem?.taskId;
      return true;
    })
    ?.map((key) => actionDataListByKey[key]);
});

const onToggleCollapse = (value) => {
  emit('update:isOpeningStepIds', [
    ...new Set(
      value
        ? [...props.isOpeningStepIds, props.stepItem?.id]
        : props.isOpeningStepIds?.filter((id) => id !== props.stepItem?.id)
    ),
  ]);
  emit('focusShape', props.stepItem?.id);
};

const onFocus = () => {
  if (isFocusing.value) onToggleCollapse(true);
  emit('focusShape', props.stepItem?.id);
};

const isOpening = computed<boolean>(() => {
  if (!props.isOpeningStepIds || !props.stepItem?.id) return false;
  return props.isOpeningStepIds.includes(props.stepItem?.id);
});
const isFocusing = computed<boolean>(() => {
  if (!props.isFocusingStepIds || !props.stepItem?.id) return false;
  return props.isFocusingStepIds.includes(props.stepItem?.id);
});

const isOpenEditModal = ref<boolean>(false);
const onOpenEditWorkflowStep = () => {
  onFocus();
  isOpenEditModal.value = true;
};

const onUpdateWorkflowStep = (workflowStep) => {
  emit('change', workflowStep);
  isOpenEditModal.value = false;
};

const styleByStepStatus = computed<{ container: any; header: any }>(() => {
  return genStyleByStepStatus(props.stepItem?.status);
});

const isDisabled = computed<boolean>(() => {
  return (
    (!props.stepItem.assigneeId && !props.stepItem.groupId) ||
    !props.stepItem.domainId
  );
});

const isOpenApprovalModal = ref<boolean>(false);

const unMaskAsApproval = () => {
  onUpdateWorkflowStep({
    ...props.stepItem,
    approvalEnabled: false,
    approvalStatus: undefined,
    approvers: [],
    updateFields: [
      ...(props.stepItem?.updateFields || []),
      'approvalEnabled',
      'approvalStatus',
      'approvers',
    ],
  });
};

const onSetApprover = async (approverIds) => {
  isOpenApprovalModal.value = false;

  onUpdateWorkflowStep({
    ...props.stepItem,
    approvalEnabled: true,
    approvalStatus: TaskApprovalStatus.NOT_REQUESTED,
    approvers: approverIds?.map((userId) => {
      return {
        userId,
      };
    }),
    updateFields: [
      ...(props.stepItem?.updateFields || []),
      'approvalEnabled',
      'approvalStatus',
      'approvers',
    ],
  });
};
</script>
<template>
  <div :id="stepItem?.id" class="p-0.5" @click="onFocus">
    <div
      class="
        w-full
        flex-center flex-col
        h-auto
        relative
        hover-to-show__parent
        bg-white
        rounded-md
      "
      :class="[isFocusing ? 'border-2' : 'border']"
      style="min-height: 2.5rem"
      :style="{
        borderColor: isFocusing
          ? '#60a5fa'
          : styleByStepStatus.container?.borderColor,
      }"
    >
      <div
        class="w-full flex-1 flex-center relative rounded-md"
        :class="!readonly && sortable ? '' : 'pr-2'"
        :style="styleByStepStatus.header"
      >
        <div class="absolute z-10 left-0 flex-center rounded-l">
          <div
            v-if="!readonly && sortable"
            class="flex-center"
            style="width: 1rem"
          >
            <span class="flex-center workflow-step-item_hover-to-show">
              <SynIcon name="dragable" class="fill-gray-500" />
            </span>
          </div>
        </div>
        <div class="pl-6 flex flex-1 min-w-0 items-center gap-2">
          <WorkflowStepCodeLabel
            :model-value="stepItem?.code"
            :readonly="viewMode == 'VIEW'"
            @update:model-value="
              (code) =>
                onUpdateWorkflowStep({
                  ...stepItem,
                  code,
                })
            "
          />

          <div
            v-if="viewMode == 'VIEW'"
            :title="stepItem.name"
            class="truncate text-gray-800"
          >
            {{ stepItem.name }}
          </div>
          <WorkflowStepNameUpdate
            v-else
            :model-value="stepItem?.name"
            @update:model-value="
              (name) =>
                onUpdateWorkflowStep({
                  ...stepItem,
                  name,
                })
            "
          />
        </div>
        <div
          class="flex-center w-max gap-0.5"
          :style="styleByStepStatus.header"
        >
          <span
            v-if="stepItem?.isStart"
            class="px-2 py-1 rounded-full text-xs bg-current-50 mr-1"
          >
            {{ $t('WORKFLOW_LABEL_START_POINT') }}
          </span>

          <span
            v-else-if="viewMode == 'EDIT'"
            class="
              px-2
              py-1
              rounded-full
              hover-to-show__children
              text-xs
              hover:bg-current-100
              cursor-pointer
              underline
              mr-1
            "
            @click="$emit('setStartPoint')"
          >
            {{ $t('WORKFLOW_LABEL_SET_START_POINT') }}
          </span>
          <TaskApprovalShortInfo
            v-if="stepItem?.approvalEnabled"
            :approval-enabled="stepItem?.approvalEnabled"
            :approval-status="stepItem?.approvalStatus"
            :approvers="stepItem?.approvers"
            :approver-id="stepItem?.approverId"
            is-show-sticker
            is-task-detail-page
            :readonly="viewMode == 'VIEW'"
            @on-edit="isOpenApprovalModal = true"
            @on-remove="unMaskAsApproval"
          />
          <div
            v-if="isDisabled && !isOpening && viewMode !== 'VIEW'"
            class="w-8 h-8 rounded-md flex-center cursor-default"
            @click.stop="onToggleCollapse(!isOpening)"
          >
            <SynIcon name="Info" :custom-class="`h-4 w-4 fill-red-500`" />
          </div>
          <div
            v-if="hasNoConnection"
            v-vig-tooltip="$t('WORKFLOW_LABEL_HAS_NOT_CONNECTION')"
            class="w-8 h-8 rounded-md flex-center cursor-default"
          >
            <SynIcon name="Warning" :custom-class="`h-4 w-4 fill-red-500`" />
          </div>
          <template v-for="action in actionDataList" :key="action?.key">
            <div
              v-if="
                viewMode !== 'TASK_VIEW' ||
                (viewMode == 'TASK_VIEW' && action?.key == 'EDIT'
                  ? !stepItem?.taskId
                  : true)
              "
              v-vig-tooltip="$t(action?.labelCode)"
              class="w-8 h-8 rounded-md cursor-pointer flex-center hover:shadow"
              @click.stop="action?.handleClick(stepItem)"
            >
              <SynIcon
                :name="action?.iconName"
                :custom-class="
                  action?.color ? `h-4 w-4 fill-${action?.color}-500` : ''
                "
              />
            </div>
          </template>

          <div
            class="flex-center h-9 w-9 transition ease-in cursor-pointer"
            :class="!isOpening ? '-rotate-90' : ''"
            @click.stop="onToggleCollapse(!isOpening)"
          >
            <SynIcon
              :name="'sort-down'"
              custom-class="h-2.5 w-2.5 fill-gray-500"
            />
          </div>
        </div>
      </div>
      <template v-if="isOpening">
        <div class="w-full px-2 pb-2">
          <WorkflowStepItemUpdate
            :workflow-id="workflowId"
            :view-mode="viewMode"
            :workflow-step="stepItem"
            :action-list="actionList"
            @update-success="onUpdateWorkflowStep"
            @update:workflow-step="(data) => $emit('change', data)"
            @on-open-update-step-modal="onOpenEditWorkflowStep"
          />
        </div>
        <div
          v-if="stepItem?.nextSteps && stepItem?.nextSteps?.length > 0"
          class="w-full"
        >
          <WorkflowStepNextSteps
            :next-steps="stepItem?.nextSteps"
            :step-by-ids="stepByIds"
            type="MINI"
          />
        </div>
      </template>
    </div>
  </div>

  <WorkflowStepDetailModal
    v-if="isOpenEditModal"
    :step-detail="stepItem"
    view-mode="EDIT"
    :step-by-ids="stepByIds"
    :is-update-immediately="isUpdateImmediately"
    @close="isOpenEditModal = false"
    @on-save="onUpdateWorkflowStep"
  />

  <TaskSetApprovalModal
    v-if="isOpenApprovalModal"
    :task="stepItem"
    @on-close="isOpenApprovalModal = false"
    @on-save="onSetApprover"
  />
</template>
<style scoped>
.workflow-step-item_hover-to-show {
  visibility: unset;
}

#workflow-step-item:hover .workflow-step-item_hover-to-show {
  visibility: unset;
}
</style>
