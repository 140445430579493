<script setup lang="ts">
// import { computed } from 'vue';
import { saveAs } from 'file-saver';
import FileIcon from '../_commons/file-icon/FileIcon.vue';
import ImageViewer from '../_commons/viewer-image/ImageViewer.vue';
// import DemoImgViewer from '../_commons/viewer-image/DemoImgViewer.vue';
import axios from 'axios';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { isGoogleFile, isOfficeFile } from '@/ui/hooks/fileHook';
import PanelCanFullscreen from '@/ui/components/fullscreen/PanelCanFullscreen.vue';
import DriveDocumentActions from '@/ui/modules/drive-document/drive-document-actions/DriveDocumentActions.vue';
import { ref } from 'vue';
import GoogleDocViewer from '@/ui/modules/ged/_commons/viewer-google-document/GoogleDocViewer.vue';
// import $constants from '@/ui/plugins/constants';
// import { checkIsFileObject } from '@/ui/hooks/commonFunction';
// import {
//   copyImageToClipBroard,
//   getBlobData,
// } from '@/ui/components/file-viewer/copyToClipboard';
// import { getObjectDataByUrl } from '@/ui/plugins/awss3/aws-s3-services';
// import { AlertType, openNotification } from '@/ui/hooks/commonHook';
// import { translate } from '@/ui/plugins/i18n/myi18n';
// import {
//   isOpenCreateTaskGlobal,
//   createTaskGlobal,
//   isAddAttachmentToTask,
//   moveAttachments,
// } from '@/ui/modules/task/task-global-state';
// import { getCurrentUserId } from '@/ui/hooks/storageHook';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = withDefaults(
  defineProps<{
    file?: any;
    name?: string;
    path?: string;
    contentType?: string;
    hasDownload?: boolean;
    isHiddenName?: boolean;
    editable?: boolean;
    removeable?: boolean;
    isLoading?: boolean;
  }>(),
  {
    hasDownload: true,
  }
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emit = defineEmits(['onDownLoad']);

const isFullscreen = ref<boolean>(false);

const onDownloadClick = async () => {
  if (!props.path) return;

  try {
    const res = await axios.get(props.path, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    saveAs(res.data, props.name || '');
  } catch (e) {
    console.log(e);
  }
};

const setFullscreen = (fullscreen) => {
  isFullscreen.value = fullscreen;
};

defineExpose({
  setFullscreen,
});
</script>

<template>
  <div class="relative w-full h-full flex-center">
    <!--IMAGE FILE-->
    <div v-if="isLoading">
      <SynIcon
        name="spinner"
        class="animate-spin fill-white"
        custom-class="h-8 w-8"
      />
    </div>
    <ImageViewer
      v-else-if="path && contentType?.startsWith($constants.FILE.TYPE.IMAGE)"
      class="h-full w-full"
      :name="name"
      :path="path"
      :size="$constants.IMAGE.SIZE.ORIGINAL"
      :is-hidden-name="isHiddenName"
    />

    <!--    <DemoImgViewer-->
    <!--      v-if="path && contentType?.startsWith($constants.FILE.TYPE.IMAGE)"-->
    <!--      class="h-full w-full"-->
    <!--    />-->
    <!--    <div-->
    <!--      v-if="path && contentType?.startsWith($constants.FILE.TYPE.IMAGE)"-->
    <!--      class="h-full w-full"-->
    <!--    >-->
    <!--      <img :src="path" alt="" />-->
    <!--    </div>-->
    <!--    <DemoImgViewer-->
    <!--      v-if="path && contentType?.startsWith($constants.FILE.TYPE.IMAGE)"-->
    <!--    ></DemoImgViewer>-->

    <!--PDF FILE-->
    <div
      v-else-if="path && contentType === $constants.FILE.TYPE.PDF"
      class="h-full w-full bg-gray-800"
    >
      <iframe
        v-if="path && contentType === $constants.FILE.TYPE.PDF"
        class="h-full w-full rounded"
        :src="path"
      ></iframe>
    </div>

    <!--GOOGLE DOC-->
    <PanelCanFullscreen
      v-else-if="path && isGoogleFile(contentType)"
      v-model:is-fullscreen="isFullscreen"
      class="rounded overflow-hidden"
    >
      <template #actions>
        <DriveDocumentActions
          v-model:is-fullscreen="isFullscreen"
          :document="file"
        />
      </template>
      <template #content>
        <GoogleDocViewer :path="path" />
      </template>
    </PanelCanFullscreen>

    <!--OFFICE FILE-->
    <div
      v-else-if="path && !path.startsWith('blob:') && isOfficeFile(contentType)"
      class="h-full w-full bg-gray-800"
    >
      <iframe
        class="h-full w-full rounded"
        :src="
          'https://view.officeapps.live.com/op/embed.aspx?src=' +
          encodeURIComponent(path)
        "
      ></iframe>
    </div>

    <!--TXT FILE-->
    <div
      v-else-if="path && contentType === $constants.FILE.TYPE.TEXT"
      class="h-full w-full bg-gray-800"
    >
      <iframe
        class="h-full w-full bg-white rounded"
        :src="
          'https://docs.google.com/gview?url=' +
          encodeURIComponent(path) +
          '&embedded=true'
        "
      ></iframe>
    </div>

    <!--AUDIO FILE-->

    <div
      v-else-if="path && contentType?.startsWith($constants.FILE.TYPE.AUDIO)"
      class="h-full flex-center bg-gray-800"
    >
      <audio :src="path" controls />
    </div>

    <!--VIDEO FILE-->
    <div
      v-else-if="path && contentType?.startsWith($constants.FILE.TYPE.VIDEO)"
      class="flex-center bg-gray-800"
      style="height: inherit"
    >
      <VigVideo v-if="isLoading === false" :path="path" class="w-full h-full" />
    </div>

    <!--LINK-->
    <div
      v-else-if="contentType?.includes('.link')"
      class="h-full flex-center bg-gray-800"
    >
      <div class="flex flex-col items-center space-y-4">
        <FileIcon
          :file-type="contentType"
          class-name="w-56 h-56 fill-gray-500"
        />
        <span class="text-white text-2xl">{{ name }}</span>
      </div>
    </div>

    <!--OTHER FILE-->
    <div v-else class="h-full flex-center flex-col space-y-4">
      <div class="flex flex-col items-center space-y-1">
        <FileIcon
          :file-type="contentType"
          class-name="w-56 h-56 fill-gray-500"
        />
        <span class="text-white">{{ name }}</span>
      </div>
      <div class="font-roboto text-xl text-white">
        {{ $t('COMMON_LABEL_NOT_SUPPORT_SHOW_FILE') }}
      </div>
      <syn-button
        :label="$t('COMMON_LABEL_DOWNLOAD')"
        name-icon="download"
        @click="onDownloadClick"
      ></syn-button>
    </div>

    <!--    <div class="flex-center absolute right-5 top-5 space-x-2">-->
    <!--      <button-->
    <!--        v-if="editable"-->
    <!--        class="-->
    <!--          h-8-->
    <!--          w-8-->
    <!--          flex-center-->
    <!--          rounded-full-->
    <!--          shadow-lg-->
    <!--          bg-white-->
    <!--          hover:bg-current-50-->
    <!--        "-->
    <!--        @click="$emit('edit')"-->
    <!--      >-->
    <!--        <SynIcon name="Edit" custom-class="w-4 h-4 fill-blue-500" is-active />-->
    <!--      </button>-->
    <!--      <button-->
    <!--        v-if="hasDownload"-->
    <!--        class="-->
    <!--          h-8-->
    <!--          w-8-->
    <!--          flex-center-->
    <!--          rounded-full-->
    <!--          shadow-lg-->
    <!--          bg-white-->
    <!--          hover:bg-current-50-->
    <!--        "-->
    <!--        @click="onDownloadClick"-->
    <!--      >-->
    <!--        <SynIcon name="Download" class="fill-current" />-->
    <!--      </button>-->

    <!--      <VigDropdown :z-index="50">-->
    <!--        <template #dropdown-toggle>-->
    <!--          <button-->
    <!--            class="-->
    <!--              p-1-->
    <!--              flex-center-->
    <!--              rounded-full-->
    <!--              shadow-lg-->
    <!--              bg-white-->
    <!--              hover:bg-current-50-->
    <!--            "-->
    <!--          >-->
    <!--            <SynIcon has-action name="note" />-->
    <!--          </button>-->
    <!--        </template>-->
    <!--        <template #dropdown-menu>-->
    <!--          <div-->
    <!--            class="py-1 z-100 list-ul rounded cursor-pointer dropdown-item"-->
    <!--            style="min-width: 11rem"-->
    <!--          >-->
    <!--            <div-->
    <!--              class="space-x-2 flex justify-start items-center text-sm list-li"-->
    <!--              @click="createNewTask"-->
    <!--            >-->
    <!--              <SynIcon-->
    <!--                is-active-->
    <!--                custom-class="fill-current  h-4 w-4"-->
    <!--                name="plus"-->
    <!--              />-->
    <!--              <span class="text-gray-600">-->
    <!--                {{ $t('CHAT_CREATE_NEW_TASK_WITH_THIS_FILE') }}-->
    <!--              </span>-->
    <!--            </div>-->
    <!--            <div-->
    <!--              class="space-x-2 flex justify-start items-center text-sm list-li"-->
    <!--              @click="onMoveFileToTask"-->
    <!--            >-->
    <!--              <SynIcon-->
    <!--                is-active-->
    <!--                custom-class="fill-blue-500 h-4 w-4"-->
    <!--                name="open-link"-->
    <!--              />-->
    <!--              <span class="text-gray-600">-->
    <!--                {{ $t('CHAT_MOVE_THIS_FILE_TO_A_TASK') }}-->
    <!--              </span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </VigDropdown>-->
    <!--      <vig-dropdown v-if="removeable || isImage" :z-index="50">-->
    <!--        <template #dropdown-toggle>-->
    <!--          <button-->
    <!--            class="-->
    <!--              p-1-->
    <!--              flex-center-->
    <!--              rounded-full-->
    <!--              shadow-lg-->
    <!--              bg-white-->
    <!--              hover:bg-current-50-->
    <!--            "-->
    <!--          >-->
    <!--            <SynIcon has-action name="dots-vertical" />-->
    <!--          </button>-->
    <!--        </template>-->
    <!--        <template #dropdown-menu>-->
    <!--          <div-->
    <!--            class="py-1 z-100 list-ul rounded cursor-pointer dropdown-item"-->
    <!--            style="min-width: 11rem"-->
    <!--          >-->
    <!--            <div-->
    <!--              v-if="removeable"-->
    <!--              class="space-x-2 flex justify-start items-center text-sm list-li"-->
    <!--              @click="$emit('remove')"-->
    <!--            >-->
    <!--              <SynIcon is-active custom-class="fill-red-500 w-4 h-4" name="trash" />-->
    <!--              <span class="text-gray-600">-->
    <!--                {{ $t('COMMON_LABEL_DELETE') }}-->
    <!--              </span>-->
    <!--            </div>-->
    <!--            <div-->
    <!--              v-if="isImage"-->
    <!--              class="space-x-2 flex justify-start items-center text-sm list-li"-->
    <!--              @click="onCopyImage"-->
    <!--            >-->
    <!--              <SynIcon is-active custom-class="fill-blue-500 w-5 h-5" name="copy" />-->
    <!--              <span class="text-gray-600">-->
    <!--                {{ $t('COMMON_LABEL_COPY_IMAGE') }}-->
    <!--              </span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </vig-dropdown>-->
    <!--    </div>-->
  </div>
</template>
