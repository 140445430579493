<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { clearLocalStorage } from '@/ui/hooks/storageHook';
import userStore from '@/store/user';
import authStore from '@/store/auth';
import FormRenewPassword from './FormRenewPassword.vue';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import { validatePassword } from '@/ui/hooks/commonFunction';

const props = defineProps({
  email: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['cancel']);
const router = useRouter();
const _userStore = userStore();
const _authStore = authStore();

const payloadData = ref<{
  currentPassword: string;
  newPassword: string;
  verifyPassword: string;
}>({
  currentPassword: '',
  newPassword: '',
  verifyPassword: '',
});

const {
  isTyping: isTypingNewPassword,
  validationResult: validationResultNewPassword,
  onValidation: onValidationNewPassword,
  reset: onResetValidationNewPassword,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
    (value) => {
      return validatePassword(value) ? '' : 'COMMON_VALID_PASSWORD_WARNING';
    },
  ],
  0
);
const {
  isTyping: isTypingVerifyPassword,
  validationResult: validationResultVerifyPassword,
  onValidation: onValidationVerifyPassword,
} = atomFormInputComposables(
  [
    (value) => {
      return value.trim() ? '' : 'COMMON_THIS_FIELD_REQUIRED';
    },
    (value) => {
      return value !== payloadData.value?.newPassword
        ? 'CHANGE_PASSWORD_CONFIRM_PASS_NOT_MATCH'
        : '';
    },
  ],
  0
);

const showPWDCurrent = ref(false);
const showPWDNew = ref(false);
const isSavePass = ref(false);
const isResetPwd = ref(false);
const isLoadingSubmitButton = ref(false);
const changePassword = async () => {
  isSavePass.value = true;
  const status = await _userStore.changePassword(
    props.email,
    payloadData.value?.currentPassword,
    payloadData.value?.newPassword
  );
  if (status) {
    clearLocalStorage({ isSwitchingOrg: false });
    isSavePass.value = false;
    router.push('/login');
  } else {
    isSavePass.value = false;
    payloadData.value = {
      currentPassword: '',
      newPassword: '',
      verifyPassword: '',
    };
  }
};

// const onSetupPassword = async () => {
//   const pass = setupPassword.value;
//   isSavePass.value = true;
//   try {
//     await _userStore.setPassword(pass);
//     isSavePass.value = false;
//     emit('cancel', {
//       value: false,
//       type: 'setup',
//     });
//   } catch (error) {
//     isSavePass.value = false;
//   }
// };

const onCancel = () => {
  emit('cancel', false);
  isResetPwd.value = false;
};

const onResetPwd = async () => {
  isResetPwd.value = true;
  isLoadingSubmitButton.value = true;
  try {
    await _authStore.forgotPassword(props.email);
  } finally {
    isLoadingSubmitButton.value = false;
  }
};

const isDisabledButton = computed<boolean>(() => {
  return (
    !payloadData.value?.currentPassword ||
    !payloadData.value?.newPassword ||
    !payloadData.value?.verifyPassword ||
    payloadData.value?.verifyPassword !== payloadData.value?.newPassword ||
    isTypingNewPassword.value ||
    !validationResultNewPassword.value?.isValid ||
    isTypingVerifyPassword.value ||
    !validationResultVerifyPassword.value?.isValid
  );
});
</script>

<template>
  <syn-modal
    v-if="!isResetPwd"
    z-index="z-50"
    container-class="md:w-1/3 h-max"
    style-body="py-8 px-2"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <span class="px-2">
        {{ $t('USERMANAGEMENT_LABEL_CHANGE_PASSWORD') }}
      </span>
    </template>

    <template #body>
      <div class="flex items-center space-y-2 flex-wrap h-full">
        <div class="flex flex-wrap px-4 space-y-4">
          <div class="flex flex-col w-full">
            <div
              class="flex items-center pr-4 text-sm text-gray-600 font-medium"
            >
              <span>
                {{ $t('USERMANAGEMENT_LABEL_CURRENT_PASSWORD') }}
                <span class="text-red-600">*</span>
              </span>
            </div>
            <div class="w-full">
              <SynFormInput>
                <SynInput
                  v-model="payloadData.currentPassword"
                  autofocus
                  :placeholder="$t('USERMANAGEMENT_LABEL_CURRENT_PASSWORD')"
                  :icon-right="showPWDCurrent ? 'eye' : 'eye-close'"
                  :input-type="showPWDCurrent ? 'text' : 'password'"
                  @on-action="
                    (value) => {
                      showPWDCurrent = !showPWDCurrent;
                    }
                  "
                />
              </SynFormInput>
            </div>
            <div class="flex w-full justify-end">
              <small
                class="text-current cursor-pointer hover:text-current-700"
                @click="onResetPwd"
              >
                {{ $t('USERPROFILE_LABEL_RESET_YOUR_PASSWORD') }}
              </small>
            </div>
          </div>

          <div class="flex flex-col w-full">
            <div
              class="flex items-center pr-4 text-sm text-gray-600 font-medium"
            >
              <span
                >{{ $t('USERMANAGEMENT_LABEL_NEW_PASSWORD') }}
                <span class="text-red-600">*</span>
              </span>
            </div>
            <div class="w-full">
              <SynFormInput
                is-html
                :error-message="validationResultNewPassword.message"
              >
                <SynInput
                  v-model="payloadData.newPassword"
                  :placeholder="$t('USERMANAGEMENT_LABEL_NEW_PASSWORD')"
                  :icon-right="showPWDNew ? 'eye' : 'eye-close'"
                  :input-type="showPWDNew ? 'text' : 'password'"
                  @on-action="
                    (value) => {
                      showPWDNew = !showPWDNew;
                    }
                  "
                  @update:model-value="onResetValidationNewPassword"
                  @blur="onValidationNewPassword(payloadData.newPassword)"
                />
              </SynFormInput>
            </div>
          </div>
          <div class="flex flex-col w-full">
            <div
              class="flex items-center pr-4 text-sm text-gray-600 font-medium"
            >
              <span
                >{{ $t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM') }}
                <span class="text-red-600">*</span>
              </span>
            </div>
            <div class="w-full">
              <SynFormInput
                :error-message="validationResultVerifyPassword.message"
              >
                <SynInput
                  v-model="payloadData.verifyPassword"
                  input-type="password"
                  :placeholder="$t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM')"
                  @blur="onValidationVerifyPassword(payloadData.verifyPassword)"
                />
              </SynFormInput>
            </div>
          </div>
        </div>
        <div v-if="!email" class="text-sm text-red-500 pl-6 mt-4">
          {{ '(*) ' + $t('PROFILE_ALERT_EMAIL_REQUIRED') }}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="pr-6 flex py-6 space-x-2 justify-end">
        <SynButton
          type-text
          color="gray"
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          @click="onCancel"
        />
        <SynButton
          :label="$t('COMMON_LABEL_SAVE')"
          :is-loading="isSavePass"
          :disabled="isDisabledButton"
          @click="changePassword"
        />
      </div>
    </template>
  </syn-modal>

  <FormRenewPassword v-if="isResetPwd" :email="email" @cancel="onCancel" />
</template>
