<template>
  <div
    class="table-cell h-full px-4 py-2 text-left break-words align-middle"
    style="width: fit-content"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {},
  setup() {
    return {};
  },
};
</script>
