<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { onChangeI18n } from '@/ui/plugins/i18n/myi18n';
// import { openNotification } from '@/ui/hooks/commonHook';
import {
  StorageConstant,
  setLocalStorage,
  getLocalStorage,
} from '@/ui/hooks/storageHook';
import organizationStore from '@/store/organization';
import domainStore from '@/store/scope';
import userStore from '@/store/user';
import notificationStore from '@/store/notification/notification.store';
import taskStore from '@/store/task';
import appStore from '@/store/app';
import { updateDeviceToken } from '@/ui/plugins/firebases/firebaseFcm';
const props = defineProps({
  isSettingOrganization: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const _appStore = appStore();
const _organizationStore = organizationStore();
const _domainStore = domainStore();
const _userStore = userStore();
const _notificationStore = notificationStore();
const _taskStore = taskStore();
const currentLanguage = ref('');
onMounted(() => {
  currentLanguage.value = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';
});

watch(
  () => getLocalStorage(StorageConstant.LANGUAGE),
  () => {
    onChangeLanguage(getLocalStorage(StorageConstant.LANGUAGE) || 'vi');
  }
);
const onChangeLanguage = async (key) => {
  currentLanguage.value = key;
  setLocalStorage(StorageConstant.LANGUAGE, key);
  _appStore.setLanguage(key);
  onChangeI18n(key);

  updateDeviceToken(key);

  // 2. update data config by lang
  if (props.isSettingOrganization) {
    await _organizationStore.getOrganizationConfigDefault();
  } else {
    _taskStore.getDataConfig();
    _notificationStore.getNotifications(13, 0, 'all');
  }
  if (
    route.name == 'ProjectManagement' ||
    route.name == 'SetupProjectManagement'
  ) {
    _domainStore.fetchAllDomainProjects();
  } else if (route.name == 'UserManagement') {
    _userStore.fetchAllProfile();
  }

  // 3. push notification to
  // openNotification(
  //   'success',
  //   translate('COMMON_LABEL_SUCCESS'),
  //   translate('COMMON_LABEL_CHANGED_LANGUAGE')
  // );
  isShowSettingLanguage.value = false;
};
const isShowSettingLanguage = ref(false);
const currentLanguageFlag = computed(() => {
  if (!currentLanguage.value) return '';
  switch (currentLanguage.value) {
    case 'fr':
      return 'flag-france';
    case 'en':
      return 'flag-united-state';
    default:
      return 'flag-vietnam';
  }
});

const locationByDomain = computed(() =>
  getLocalStorage(StorageConstant.LOCATION)
);
</script>

<template>
  <SynDropdownItem
    class="flex justify-between items-center"
    @click.stop="isShowSettingLanguage = !isShowSettingLanguage"
  >
    <div class="flex items-center space-x-2">
      <SynIcon v-if="currentLanguageFlag" :name="currentLanguageFlag" />
      <span class="">{{
        currentLanguage == 'fr'
          ? $t('SETTING_LANGUAGE_LABEL_SEARCH')
          : currentLanguage == 'en'
          ? $t('SETTING_LANGUAGE_LABEL_ENGLISH')
          : $t('SETTING_LANGUAGE_LABEL_VIETNAMESE')
      }}</span>
    </div>

    <SynIcon
      :name="'sort-down'"
      custom-class="h-2 w-2 fill-gray-500"
      :class="!isShowSettingLanguage ? '-rotate-90' : ''"
      @click.stop="isShowSettingLanguage = !isShowSettingLanguage"
    />
  </SynDropdownItem>

  <SynDropdownItem
    v-if="isShowSettingLanguage && currentLanguage != 'fr'"
    class="bg-gray-100"
    @click="onChangeLanguage('fr')"
  >
    <div class="flex items-center pl-4">
      <div class="flex items-center">
        <SynIcon name="flag-france" />
        <span class="pl-2 text-sm">{{
          $t('SETTING_LANGUAGE_LABEL_SEARCH') || 'French'
        }}</span>
      </div>
    </div>
  </SynDropdownItem>
  <SynDropdownItem
    v-if="
      isShowSettingLanguage &&
      currentLanguage != 'vi' &&
      locationByDomain !== 'EU'
    "
    class="bg-gray-100"
    @click="onChangeLanguage('vi')"
  >
    <div class="flex items-center pl-4">
      <div class="flex items-center">
        <SynIcon name="flag-vietnam" />
        <span class="pl-2 text-sm">{{
          $t('SETTING_LANGUAGE_LABEL_VIETNAMESE') || 'Vietnamese'
        }}</span>
      </div>
    </div>
  </SynDropdownItem>
  <SynDropdownItem
    v-if="isShowSettingLanguage && currentLanguage != 'en'"
    class="bg-gray-100"
    @click="onChangeLanguage('en')"
  >
    <div class="flex items-center pl-4">
      <div class="flex items-center">
        <SynIcon name="flag-united-state" />
        <span class="pl-2 text-sm">{{
          $t('SETTING_LANGUAGE_LABEL_ENGLISH') || 'English'
        }}</span>
      </div>
    </div>
  </SynDropdownItem>
</template>
