import { ref } from 'vue';
import { chain, toArray } from 'lodash';
import { formatDate } from '@/ui/plugins/filters';
import { diff } from '@/ui/hooks/hook-date';
import dayjs from 'dayjs';
import {
    MESSAGES_GROUP_MINUTE,
    MESSAGES_PAGE_SIZE,
} from '@/ui/modules/messaging/chat-panel/chat-panel-config';
import { Unsubscribe } from '@firebase/firestore';
import myProfileStore from '@/store/auth/my-profile';
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import { prepareMessage } from '@/ui/plugins/firebases/firestore-database/constants';
import ChatService from '@/application/services/ChatService';

export default function useChatMessages(organizationId, conversationId): any {
    let _unsubscribeAddedMessages: Unsubscribe;
    let _unsubscribeUpdatedMessages: Unsubscribe;
    let _messageIdsInLocal: string[] = [];
    let _messageIdsFromServer: string[] = [];
    let _lastMsgDocFromCache: any;
    let _lastMsgDocFromServerTop: any;
    let _lastMsgDocFromServerBottom: any;
    let _lastSnapshotFromServerUp: number | null;
    let _lastSnapshotFromServerDown: number | null;

    const messageIds = ref<Array<string>>([]);
    const messagesObj = ref<{ [msgId: string]: ChatMessageModel }>({});
    const startDateByMsgId = ref<{ [msgId: string]: Date }>({});
    const positionByMsgId = ref<{
        [msgId: string]: { isFirst?: boolean; isLast?: boolean };
    }>({});
    const canLoadMoreUp = ref<boolean>();
    const canLoadMoreDown = ref<boolean>();
    const isLoadingMsgFromServerUp = ref<boolean>();
    const isLoadingMsgFromServerDown = ref<boolean>();
    const isGoingToMsg = ref<boolean>();

    const resetMessages = () => {
        if (_unsubscribeAddedMessages) _unsubscribeAddedMessages();
        if (_unsubscribeUpdatedMessages) _unsubscribeUpdatedMessages();

        _messageIdsInLocal = [];
        _messageIdsFromServer = [];
        _lastMsgDocFromCache = null;
        _lastMsgDocFromServerTop = null;
        _lastMsgDocFromServerBottom = null;
        _lastSnapshotFromServerUp = null;
        _lastSnapshotFromServerDown = null;

        canLoadMoreUp.value = false;
        canLoadMoreDown.value = false;
        isLoadingMsgFromServerUp.value = false;
        isLoadingMsgFromServerDown.value = false;
        isGoingToMsg.value = false;
    };

    const initMessagesFromCache = async (converId, lastDeletedDate) => {
        const snapshots = await ChatService.getMessagesFromLocal(
            organizationId.value,
            converId,
            null,
            lastDeletedDate,
            MESSAGES_PAGE_SIZE
        );

        if (converId !== conversationId.value) return;

        const msgObj = {};
        const msgIds: any = [];

        snapshots?.forEach((doc) => {
            msgObj[doc.id] = prepareMessage(doc.id, doc.data());
            msgIds.push(doc.id);

            _lastMsgDocFromCache = doc;
        });

        messagesObj.value = msgObj;
        _messageIdsInLocal = _sortMessageIds(msgIds);
        _processMessageDatesAndPositions();
        messageIds.value = _messageIdsInLocal.slice(0, MESSAGES_PAGE_SIZE);
        _processCanLoadMore();

        _initMoreMessagesFromCache(converId, lastDeletedDate).then().catch();
    };

    const initMessagesFromServer = async (converId, lastDeletedDate) => {
        const snapshots = await ChatService.getMessagesFromServer(
            organizationId.value,
            converId,
            null,
            lastDeletedDate,
            MESSAGES_PAGE_SIZE
        );

        if (converId !== conversationId.value) return;

        snapshots?.forEach((doc) => {
            _pushMessageIntoLists(doc.id, doc.data());

            _lastMsgDocFromServerTop = doc;
        });

        _messageIdsInLocal = _sortMessageIds(_messageIdsInLocal);
        _processMessageDatesAndPositions();
        messageIds.value = _messageIdsInLocal.slice(0, MESSAGES_PAGE_SIZE);
        _processCanLoadMore();

        _lastSnapshotFromServerUp = snapshots?.size;
    };

    const subscribeMessages = (
        converId,
        lastDeletedDate,
        onNewMsg: () => void
    ) => {
        if (_unsubscribeAddedMessages) _unsubscribeAddedMessages();
        if (_unsubscribeUpdatedMessages) _unsubscribeUpdatedMessages();

        _unsubscribeAddedMessages = ChatService.subscribeAddedMessages(
            organizationId.value,
            converId,
            null,
            lastDeletedDate,
            1,
            (snapshot) => {
                if (converId !== conversationId.value) return;

                let newMsgId: string = '';

                snapshot.docChanges().forEach((change) => {
                    const msgId = change?.doc?.id;
                    const msgData = prepareMessage(msgId, change?.doc?.data());
                    if (
                        lastDeletedDate &&
                        msgData.createdDate < lastDeletedDate.toDate()
                    ) {
                        return;
                    }

                    switch (change?.type) {
                        case 'added': {
                            messagesObj.value[msgId] = msgData;

                            if (!_messageIdsInLocal.includes(msgId)) {
                                _messageIdsInLocal.push(msgId);
                                newMsgId = msgId;
                            }
                            break;
                        }
                    }
                });

                _messageIdsInLocal = _sortMessageIds(_messageIdsInLocal);
                _processMessageDatesAndPositions();

                if (newMsgId) {
                    messageIds.value = _messageIdsInLocal.slice(
                        0,
                        messageIds.value?.length + 1
                    );

                    if (onNewMsg) onNewMsg();
                }

                _processCanLoadMore();
            }
        );

        _unsubscribeUpdatedMessages = ChatService.subscribeUpdatedMessages(
            organizationId.value,
            converId,
            null,
            lastDeletedDate,
            1,
            (snapshot) => {
                if (converId !== conversationId.value) return;

                snapshot.docChanges().forEach((change) => {
                    const msgId = change?.doc?.id;
                    const msgData = prepareMessage(msgId, change?.doc?.data());
                    if (
                        lastDeletedDate &&
                        msgData.createdDate < lastDeletedDate.toDate()
                    ) {
                        return;
                    }

                    switch (change?.type) {
                        case 'added':
                        case 'modified': {
                            messagesObj.value[msgId] = msgData;

                            if (_messageIdsInLocal.includes(msgId)) {
                                _messageIdsInLocal =
                                    _sortMessageIds(_messageIdsInLocal);
                                _processMessageDatesAndPositions();
                                _processCanLoadMore();
                            }
                            break;
                        }
                    }
                });
            }
        );
    };

    const loadMoreMessages = (converId, lastDeletedDate) => {
        if (canLoadMoreUp.value && messageIds.value?.length) {
            const msgCount = messageIds.value.length;
            const msgIdBottom = messageIds.value[0];
            const msgIdBottomIndex = _messageIdsInLocal?.indexOf(msgIdBottom);

            if (msgIdBottomIndex > -1) {
                messageIds.value = _messageIdsInLocal.slice(
                    msgIdBottomIndex,
                    msgIdBottomIndex + msgCount + MESSAGES_PAGE_SIZE
                );
            }
            // messageIds.value = _messageIdsInLocal.slice(
            //     0,
            //     messageIds.value?.length + MESSAGES_PAGE_SIZE
            // );

            _processCanLoadMore();
        }

        // Load more if current messages list has <=40 remaining items in top
        // if (
        //     messageIds.value?.length >=
        //     _messageIdsFromServer?.length - MESSAGES_PAGE_SIZE * 2
        // ) {
        const msgIdTop = messageIds.value?.length
            ? messageIds.value[messageIds.value.length - 1]
            : null;
        if (
            _messageIdsFromServer?.length &&
            _messageIdsFromServer?.indexOf(msgIdTop) >=
                _messageIdsFromServer.length - MESSAGES_PAGE_SIZE * 2
        ) {
            _loadMoreMessagesFromServerUp(converId, lastDeletedDate).then();
        }
    };

    const loadMoreMessagesDown = (converId, lastDeletedDate) => {
        if (
            canLoadMoreDown.value &&
            messageIds.value?.length &&
            _messageIdsInLocal
        ) {
            const msgCount = messageIds.value.length;
            const msgIdBottom = messageIds.value[0];
            const msgIdBottomIndex = _messageIdsInLocal.indexOf(msgIdBottom);

            if (msgIdBottomIndex > -1) {
                messageIds.value = _messageIdsInLocal.slice(
                    Math.max(msgIdBottomIndex - MESSAGES_PAGE_SIZE, 0),
                    msgIdBottomIndex + msgCount
                );
            }

            _processCanLoadMore();
        }

        // Load more if current messages list has <=40 remaining items in bottom
        const msgIdBottom = messageIds.value?.length
            ? messageIds.value[0]
            : null;
        if (
            _messageIdsFromServer?.length &&
            _messageIdsFromServer?.indexOf(msgIdBottom) <=
                MESSAGES_PAGE_SIZE * 2
        ) {
            _loadMoreMessagesFromServerDown(converId, lastDeletedDate).then();
        }
    };

    const goToMessage = async (
        converId: string,
        msgId: string,
        lastDeletedDate: any
    ) => {
        isGoingToMsg.value = true;

        try {
            // Get message doc
            const docSnap = await ChatService.getMessage(
                organizationId.value,
                converId,
                msgId
            );
            if (converId !== conversationId.value || !docSnap?.exists()) {
                return (isGoingToMsg.value = false);
            }

            // If message deleted, return
            const msgData = prepareMessage(msgId, docSnap?.data());
            if (
                lastDeletedDate &&
                msgData.createdDate < lastDeletedDate.toDate()
            ) {
                return (isGoingToMsg.value = false);
            }

            // Get more messages before
            const snapshotsBefore = await ChatService.getMessagesFromServer(
                organizationId.value,
                converId,
                docSnap,
                lastDeletedDate,
                MESSAGES_PAGE_SIZE
            );
            if (converId !== conversationId.value) {
                return (isGoingToMsg.value = false);
            }

            // Get more messages after
            const snapshotsAfter = await ChatService.getMessagesFromServer(
                organizationId.value,
                converId,
                docSnap,
                null,
                MESSAGES_PAGE_SIZE,
                'createdDate',
                'asc'
            );
            if (converId !== conversationId.value) {
                return (isGoingToMsg.value = false);
            }

            // Push messages in to list
            const msgIds = [];

            _pushMessageIntoLists(msgId, docSnap.data());
            msgIds.push(msgId);

            snapshotsBefore?.forEach((doc) => {
                _pushMessageIntoLists(doc.id, doc.data());
                _lastMsgDocFromServerTop = doc;
                msgIds.push(doc.id);
            });

            snapshotsAfter?.forEach((doc) => {
                _pushMessageIntoLists(doc.id, doc.data());
                _lastMsgDocFromServerBottom = doc;
                msgIds.push(doc.id);
            });

            // Replace messages in current list
            _messageIdsInLocal = _sortMessageIds(_messageIdsInLocal);
            _processMessageDatesAndPositions();
            messageIds.value = _sortMessageIds(msgIds);
            _processCanLoadMore();

            _lastSnapshotFromServerUp = snapshotsBefore?.size;
            _lastSnapshotFromServerDown = snapshotsAfter?.size;
        } catch (e) {
            console.log(e);
        }

        isGoingToMsg.value = false;
    };

    const addNewSentMessageToList = (converId, message) => {
        if (!message) return;

        // Process message date
        message.createdDate = new Date();

        messagesObj.value[message?.id] = prepareMessage(message?.id, message);

        _messageIdsInLocal.push(message?.id);
        _messageIdsInLocal = _sortMessageIds(_messageIdsInLocal);

        _processMessageDatesAndPositions();

        scrollMessagesToBottom();
    };

    const updateMessageInList = (converId, message) => {
        if (!message) return;

        messagesObj.value[message?.id] = prepareMessage(message?.id, message);
    };

    const removeMessage = async (converId, msgId) => {
        const userId = myProfileStore().myProfile?.id;

        if (!converId || !userId) return;

        const updMsg = await ChatService.removeMessage(
            organizationId.value,
            converId,
            msgId,
            userId
        );

        // Delete message locally
        messagesObj.value[msgId] = { ...messagesObj.value[msgId], ...updMsg };
    };

    const scrollMessagesToBottom = () => {
        messageIds.value = _messageIdsInLocal.slice(0, MESSAGES_PAGE_SIZE);

        _processCanLoadMore();
    };

    const _initMoreMessagesFromCache = async (converId, lastDeletedDate) => {
        try {
            const snapshots = await ChatService.getMessagesFromLocal(
                organizationId.value,
                converId,
                _lastMsgDocFromCache,
                lastDeletedDate,
                1000 - MESSAGES_PAGE_SIZE
            );

            if (converId !== conversationId.value) return;

            snapshots?.forEach((doc) => {
                messagesObj.value[doc.id] = prepareMessage(doc.id, doc.data());
                if (!_messageIdsInLocal?.includes(doc.id)) {
                    _messageIdsInLocal.push(doc.id);
                }
            });

            _messageIdsInLocal = _sortMessageIds(_messageIdsInLocal);
            _processMessageDatesAndPositions();
            _processCanLoadMore();
        } catch (e) {
            console.log(e);
        }
    };

    const _loadMoreMessagesFromServerUp = async (converId, lastDeletedDate) => {
        if (isLoadingMsgFromServerUp.value || !_lastSnapshotFromServerUp) {
            return;
        }

        isLoadingMsgFromServerUp.value = true;

        try {
            const snapshots = await ChatService.getMessagesFromServer(
                organizationId.value,
                converId,
                _lastMsgDocFromServerTop,
                lastDeletedDate,
                MESSAGES_PAGE_SIZE * 5
            );

            if (converId !== conversationId.value) {
                return (isLoadingMsgFromServerUp.value = false);
            }

            snapshots?.forEach((doc) => {
                _pushMessageIntoLists(doc.id, doc.data());

                _lastMsgDocFromServerTop = doc;
            });

            _messageIdsInLocal = _sortMessageIds(_messageIdsInLocal);
            _processMessageDatesAndPositions();
            _processCanLoadMore();

            _lastSnapshotFromServerUp = snapshots?.size;
        } catch (e) {
            console.log(e);
        }

        isLoadingMsgFromServerUp.value = false;

        if (_lastSnapshotFromServerUp) {
            loadMoreMessages(converId, lastDeletedDate);
        }
    };

    const _loadMoreMessagesFromServerDown = async (
        converId,
        lastDeletedDate
    ) => {
        if (
            isLoadingMsgFromServerDown.value ||
            !_lastSnapshotFromServerDown ||
            !_lastMsgDocFromServerBottom
        ) {
            return;
        }

        isLoadingMsgFromServerDown.value = true;

        try {
            const snapshots = await ChatService.getMessagesFromServer(
                organizationId.value,
                converId,
                _lastMsgDocFromServerBottom,
                null,
                MESSAGES_PAGE_SIZE * 5,
                'createdDate',
                'asc'
            );

            if (converId !== conversationId.value) {
                isLoadingMsgFromServerDown.value = false;
            }

            snapshots?.forEach((doc) => {
                _pushMessageIntoLists(doc.id, doc.data());

                _lastMsgDocFromServerBottom = doc;
            });

            _messageIdsInLocal = _sortMessageIds(_messageIdsInLocal);
            _processMessageDatesAndPositions();
            _processCanLoadMore();

            _lastSnapshotFromServerDown = snapshots?.size;
        } catch (e) {
            console.log(e);
        }

        isLoadingMsgFromServerDown.value = false;

        if (_lastSnapshotFromServerDown) {
            loadMoreMessagesDown(converId, lastDeletedDate);
        }
    };

    const _pushMessageIntoLists = (msgId, msg) => {
        messagesObj.value[msgId] = prepareMessage(msgId, msg);

        if (!_messageIdsInLocal?.includes(msgId)) {
            _messageIdsInLocal.push(msgId);
        }
        if (!_messageIdsFromServer?.includes(msgId)) {
            _messageIdsFromServer.push(msgId);
        }
    };

    const _sortMessageIds = (msgIds) => {
        return chain(msgIds)
            .uniq()
            .orderBy((msgId) => messagesObj.value[msgId]?.createdDate, 'desc')
            .value();
    };

    const _processMessageDatesAndPositions = () => {
        const startDates = {};
        const msgPositions = {};

        let prevMsgId, prevMsgTime, prevMsgUser;

        _messageIdsInLocal.forEach((msgId, currIdx) => {
            const currMessage = messagesObj.value[msgId];

            const currMsgUser = currMessage?.createdBy;
            const currMsgTime = currMessage?.createdDate;
            const currMsgFiles = Object.keys(currMessage?.files || {});
            const currMsgReplyTo = currMessage?.replyTo || '';
            const currMsgPinned = currMessage?.pinned;
            const currMsgCreatedTask = currMessage?.createdTasks?.length;

            // Process date
            const formattedDate = formatDate(currMsgTime, 'YYYY-MM-DD');

            if (
                !startDates[formattedDate] ||
                diff(
                    currMsgTime,
                    startDates[formattedDate]?.createdDate,
                    'milliseconds'
                ) < 0
            ) {
                startDates[formattedDate] = {
                    id: msgId,
                    createdDate: currMsgTime,
                };
            }

            // Process position
            msgPositions[msgId] = {};

            if (
                !prevMsgTime ||
                !prevMsgUser ||
                dayjs(prevMsgTime).diff(currMsgTime, 'minute') >
                    MESSAGES_GROUP_MINUTE ||
                currMsgUser !== prevMsgUser ||
                msgPositions[prevMsgId].isFirst ||
                currMsgFiles?.length ||
                currMsgReplyTo ||
                currMsgPinned ||
                currMsgCreatedTask
            ) {
                msgPositions[msgId].isLast = true;

                if (currIdx > 0 && !msgPositions[prevMsgId].isFirst) {
                    msgPositions[prevMsgId] = {
                        ...msgPositions[prevMsgId],
                        isFirst: true,
                    };
                }
            }

            if (
                currIdx === _messageIdsInLocal?.length - 1 ||
                currMsgFiles?.length ||
                currMessage?.task ||
                currMessage?.dayoff ||
                currMsgReplyTo ||
                currMsgPinned
            ) {
                msgPositions[msgId].isFirst = true;
            }

            prevMsgId = msgId;

            // Update last currMessage time & user
            prevMsgTime = currMsgTime;
            prevMsgUser = currMsgUser;
        });

        positionByMsgId.value = msgPositions;

        startDateByMsgId.value = chain(toArray(startDates))
            .keyBy('id')
            .mapValues('createdDate')
            .value();
    };

    const _processCanLoadMore = () => {
        canLoadMoreUp.value =
            messageIds.value?.length &&
            _messageIdsInLocal?.length &&
            messageIds.value[messageIds.value.length - 1] !=
                _messageIdsInLocal[_messageIdsInLocal.length - 1];

        canLoadMoreDown.value =
            messageIds.value?.length &&
            _messageIdsInLocal?.length &&
            messageIds.value[0] != _messageIdsInLocal[0];

        // canLoadMoreUp.value =
        //     messageIds.value?.length < _messageIdsInLocal?.length;
    };

    return {
        messageIds,
        messagesObj,
        startDateByMsgId,
        positionByMsgId,
        canLoadMoreUp,
        canLoadMoreDown,
        isLoadingMsgFromServerUp,
        isLoadingMsgFromServerDown,
        isGoingToMsg,

        resetMessages,
        initMessagesFromCache,
        initMessagesFromServer,
        subscribeMessages,
        loadMoreMessages,
        loadMoreMessagesDown,
        goToMessage,
        addNewSentMessageToList,
        updateMessageInList,
        removeMessage,
        scrollMessagesToBottom,
    };
}
