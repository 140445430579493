<script setup lang="ts">
import { computed } from 'vue';
import { AdminType } from '@/ui/common/constants/constant';
import myProfileStore from '@/store/auth/my-profile';
import chatStore from '@/store/chat';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ConversationLastMessage from '@/ui/modules/messaging/conversation-item/ConversationLastMessage.vue';

defineProps<{
  conversationId?: string;
  conversation: ChatConversationModel;
  members: any;
  selected?: boolean;
  readonly?: boolean;
  isFocusing?: boolean;
  isLoading?: boolean;
}>();

const myProfile = computed(() => myProfileStore().myProfile);
const mutedConversations = computed(() => chatStore().mutedConversations);
</script>

<template>
  <div
    class="p-2 flex"
    v-bind="$attrs"
    :class="{
      'cursor-pointer hover:bg-gray-100': !readonly,
      'bg-gray-50': conversation?.pinned && !isFocusing && !selected,
      'bg-gray-100': isFocusing,
      'bg-current-50': selected && !isFocusing,
      'opacity-70':
        conversation?.isDeleted ||
        conversation?.isDeactive ||
        conversation?.isDisallowed ||
        isLoading,
    }"
  >
    <SynAvatarStatusActive
      :id="conversation?.contactId"
      class="w-full"
      :avatar="conversation?.avatarUrl || conversation?.avatar"
      :name="conversation?.name"
      :is-group="conversation?.isGroup && !conversation?.supportingChat"
      :is-user="!conversation?.isGroup && !conversation?.externalChat"
      :is-deactive="conversation?.isDeactive && !isLoading"
      :is-deleted="conversation?.isDeleted && !isLoading"
      :short-name="conversation?.shortName"
      custom-class="h-12 w-12 "
      is-hidden-name
    >
      <template #contact-name>
        <div
          class="ml-2 flex-1 overflow-hidden flex flex-col justify-center"
          :class="conversation?.unSeen > 0 ? 'font-semibold' : ''"
        >
          <!--NAME-->
          <div class="flex items-center justify-between gap-1">
            <div
              class="flex items-center text-overflow-hidden-line space-x-2 pl-1"
            >
              <span v-if="conversation?.name" class="truncate">
                {{ conversation?.name }}
              </span>
              <!--              <span v-else class="italic">Tictop User</span>-->
              <SynTag
                v-if="
                  !conversation?.isGroup &&
                  conversation?.contactId === myProfile?.id
                "
                size="tiny"
                custom-class="px-1 border border-current-100 bg-white text-gray-800"
              >
                <span class="italic">
                  {{ $t('COMMON_LABEL_ME') || 'Me' }}
                </span>
              </SynTag>
              <SynTag
                v-else-if="conversation?.adminType === AdminType.Visitor"
                size="tiny"
                custom-class="px-1 border border-current-100 bg-white text-gray-800"
              >
                <span class="italic">
                  {{ $t('COMMON_LABEL_VISITOR') || 'Visitor' }}
                </span>
              </SynTag>
              <SynTag
                v-if="conversation?.isDeleted && !isLoading"
                size="tiny"
                custom-class="px-2 bg-gray-100 text-gray-800"
              >
                <span class="italic">
                  {{ $t('COMMON_LABEL_DELETED') }}
                </span>
              </SynTag>
              <SynTag
                v-else-if="conversation?.isDeactive && !isLoading"
                size="tiny"
                custom-class="px-2 bg-gray-100 text-gray-800"
              >
                <span class="italic">
                  {{ $t('USERMANAGEMENT_FORM_DEACTIVE') }}
                </span>
              </SynTag>
              <SynTag
                v-else-if="conversation?.isDisallowed && !isLoading"
                size="tiny"
                custom-class="px-2 bg-gray-100 text-gray-800"
              >
                <span class="italic">
                  {{ $t('CHAT_NOT_ALLOWED') }}
                </span>
              </SynTag>
              <div
                v-if="conversation?.externalChat"
                class="flex items-center gap-1 truncate"
              >
                <SynAvatar
                  custom-class="w-5 h-5"
                  :src="conversation?.externalOrgAvatar"
                  :name="conversation?.externalOrgName"
                />
                <div
                  class="text-xs text-gray-600 truncate"
                  :title="conversation?.externalOrgName"
                >
                  {{ conversation?.externalOrgName }}
                </div>
              </div>
              <div
                v-if="
                  conversation?.supportingChat &&
                  conversation?.supportingOrgName
                "
                class="flex items-center gap-1 truncate"
              >
                <SynAvatar
                  custom-class="w-5 h-5"
                  :src="conversation?.supportingOrgAvatar"
                  :name="conversation?.supportingOrgName"
                />
                <div
                  class="text-xs text-gray-600 truncate"
                  :title="conversation?.supportingOrgName"
                >
                  {{ conversation?.supportingOrgName }}
                </div>
              </div>
            </div>
            <div class="flex-center gap-1">
              <SynIcon
                v-if="mutedConversations[conversationId]?.offTime > Date.now()"
                name="notification-off"
                class="fill-gray-400"
                custom-class="w-3.5 h-3.5"
              />
              <SynIcon
                v-if="conversation?.pinned"
                name="Pin"
                class="fill-gray-400"
                is-active
              />
              <div
                v-if="conversation?.supportingChat"
                class="flex-center rounded-full bg-green-200 p-0.5"
              >
                <SynIcon name="CustomerSupport" class="fill-green-600" />
              </div>
              <div
                v-if="conversation?.externalChat"
                class="flex-center rounded-full bg-yellow-300 p-1"
              >
                <SynIcon
                  name="External"
                  custom-class="w-3 h-3 fill-yellow-900"
                />
              </div>
              <div
                v-if="conversation?.chatAboutTaskId"
                class="flex-center rounded-full bg-gray-200 p-1"
              >
                <SynIcon name="Note" custom-class="w-3 h-3 fill-gray-600" />
              </div>
            </div>
          </div>

          <!--LAST MSG-->
          <div
            class="text-sm flex flex-nowrap"
            :class="conversation?.unSeen > 0 ? '' : 'text-gray-500'"
          >
            <ConversationLastMessage
              :conversation="conversation"
              :members="members"
              class="flex-1 pl-1"
            />

            <AtomLabel
              v-if="conversation?.lastMessageTime"
              class="ml-1"
              :datetime="conversation?.lastMessageTime?.toDate()"
            />
          </div>
        </div>
      </template>
    </SynAvatarStatusActive>
  </div>
</template>
