<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g id="Group_3584" data-name="Group 3584" transform="translate(-1 -1)">
      <g id="LIne">
        <path
          id="Path_1171"
          data-name="Path 1171"
          d="M19,3.1V2a1,1,0,0,0-2,0V3H13V2a1,1,0,0,0-2,0V3H7V2A1,1,0,0,0,5,2V3.1A5,5,0,0,0,1,8V18a5,5,0,0,0,5,5H18a5,5,0,0,0,5-5V8a5,5,0,0,0-4-4.9ZM21,18a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V8A3,3,0,0,1,5,5.18V6A1,1,0,0,0,7,6V5h4V6a1,1,0,0,0,2,0V5h4V6a1,1,0,0,0,2,0V5.18A3,3,0,0,1,21,8Zm-4.24-7.66a1,1,0,0,1,0,1.42l-4.81,4.73a1,1,0,0,1-1.41,0L8.25,14.25a1,1,0,1,1,1.41-1.42l1.58,1.55,4.12-4A1,1,0,0,1,16.76,10.34Z"
        />
      </g>
    </g>
  </svg>
</template>
