export enum EOrganizationSettings {
    CALENDAR = 'CALENDAR',
    CHAT = 'CHAT',
    DAYOFF = 'DAYOFF',
    DEPARTMENT = 'DEPARTMENT',
    DOMAIN = 'DOMAIN',
    GED = 'GED',
    START_WORKING = 'START_WORKING',
    TASK = 'TASK',
    TIME_WORKING = 'TIME_WORKING',
    USER = 'USER',
    VIDEO_CALL = 'VIDEO_CALL',
}
