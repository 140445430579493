export enum EResourceUsageStatus {
    Exceed = 1,
    ReachLimitation = 2,
    Allowable = 3,
}
export enum EUsageItemUnit {
    Quantity = 'QUANTITY',
    Day = 'DAY',
}
export enum EPlanCapacityKeys {
    ALL = 'ALL',
    EXCEEDED_ONLY = 'EXCEEDED_ONLY',
    TotalTask = 'TotalTask',
    TotalStorageByUser = 'TotalStorageByUser',
    TotalStorage = 'TotalStorage',
    TotalMember = 'TotalMember',
    TotalValidInvite = 'TotalValidInvite',
    TotalGroup = 'TotalGroup',
    TotalMessage = 'TotalMessage',
    TotalNote = 'TotalNote',
    MessageHistory = 'MessageHistory',
    FileAndTaskHistory = 'FileAndTaskHistory',
    TotalCall = 'TotalCall',
}

export enum ENextBillingStatus {
    AboutToExpire = 1,
    Expired,
}
export enum ExceededPlanAction {
    Upgrade = 'UPGRADE',
    Extend = 'EXTEND',
    BuyMore = 'BUY_MORE',
    ContactSale = 'CONTACT_SALE',
}
export enum ExceededPlanNotify {
    PaymentOverdue = 'PAYMENT_OVERDUE', // A
    ExceededPlanLimitation = 'EXCEEDED_PLAN_LIMITATION', // B
    ExceededPurchasedResourceLimitation = 'EXCEEDED_PURCHASED_RESOURCE_LIMITATION', // C
    UpcomingPaymentDueDate = 'UPCOMING_PAYMENT_DUE_DATE', // D
}
