<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="612px"
    height="612px"
    viewBox="0 0 612 612"
    style="enable-background: new 0 0 612 612"
    xml:space="preserve"
  >
    <g>
      <g id="_x31__12_">
        <g>
          <path
            d="M267.214,325.125H95.625c-10.557,0-19.125,8.568-19.125,19.125s8.568,19.125,19.125,19.125h126.761L92.374,493.406
				l27.043,27.042l129.82-129.82l-0.612,125.747c0,10.557,8.568,19.125,19.125,19.125s19.125-8.568,19.125-19.125V344.25
				c0-5.661-2.353-10.231-6.043-13.388C277.37,327.344,272.55,325.125,267.214,325.125z M516.375,248.625H389.596l130.03-130.031
				l-27.042-27.043L362.763,221.372l0.612-125.747c0-10.557-8.568-19.125-19.125-19.125s-19.125,8.568-19.125,19.125V267.75
				c0,5.661,2.333,10.232,6.044,13.388c3.461,3.538,8.28,5.737,13.616,5.737h171.59c10.557,0,19.125-8.568,19.125-19.125
				S526.932,248.625,516.375,248.625z M535.5,0h-459C34.253,0,0,34.253,0,76.5v459C0,577.747,34.253,612,76.5,612h459
				c42.247,0,76.5-34.253,76.5-76.5v-459C612,34.253,577.747,0,535.5,0z M573.75,535.5c0,21.114-17.117,38.25-38.25,38.25h-459
				c-21.133,0-38.25-17.136-38.25-38.25v-459c0-21.114,17.117-38.25,38.25-38.25h459c21.133,0,38.25,17.117,38.25,38.25V535.5z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
