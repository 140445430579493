/* eslint-disable no-unused-vars */
export enum ResponseCode {
    LoginFailed = 4001,
    LoginBlocked = 4002,
    OrganizationInactive = 4003,
    NotFinishSetup = 4004, //  For mobile only
    SendPhoneCodeBlocked = 4005,
    CaptchaCheckFailed = 4006,
    InvalidPhoneNumber = 4007,
    InvalidSessionInfo = 4008,
    SendVerificationFailed = 4009,
    CodeInvalid = 4010,
    WaitingActivationOrganization = 4011,

    SuspiciousRequest = 5000,

    //  show small message that show task was deleted
    TaskDeleted = 6000,
    TaskNotExist = 6403,

    //  Invite code
    InviteTokenInvalid = 6100,

    InviteTokenExpired = 6101,
    InviteTokenCanceled = 6102,
    InviteUserAttached = 6103,
    InviteJoinedByOtherMethods = 6104,
    InviteUserDeleted = 6105,

    AttachmentCodeInvalid = 7100,
    AlreadyAMemberOfThisOrganization = 7200,
    NotSupportThisPhoneNumber = 7300,
}

export const ErrorResponseConstant = {
    6000: {
        textCode: 'COMMON_ERROR_BY_CODE_6000',
        textMessage: '',
    },
    6403: {
        textCode: 'COMMON_ERROR_BY_CODE_6403',
        textMessage: '',
    },
    7100: {
        textCode: 'COMMON_ERROR_BY_CODE_7100',
        textMessage: 'Sorry! Your attachment code is incorrect.',
    },
    7200: {
        textCode: 'COMMON_ERROR_BY_CODE_7200',
        textMessage: 'You are already a member of this organization',
    },
};

export class ErrorResponseClass {
    errorCode: string;
    data: IErrorResponse;
    constructor(errorCode) {
        this.errorCode = errorCode;
        this.data = ErrorResponseConstant[errorCode];
    }
}

export interface IErrorResponse {
    textCode: string;
    textMessage: string;
}
