import BaseRepository from '@/base/repositories/BaseRepository';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import { IHelpRepository } from '@/domain/interfaces/repositories/IHelpRepository';
import { CategoryFolderHelp } from '@/domain/enums/HelpEnums';

export default class HelpRepository implements IHelpRepository {
    private static instance: HelpRepository;

    constructor() {}

    public static getInstance(): HelpRepository {
        if (!HelpRepository.instance) {
            HelpRepository.instance = new HelpRepository();
        }
        return HelpRepository.instance;
    }

    getPlaylistYoutubeSpecialFiles(): any {
        return BaseRepository.requestWithAuthorize({
            url: `Help/GetPlaylistYoutubeSpecialFiles`,
            method: 'get',
        });
    }

    getFilesVideo(folderId): any {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);
        const category = CategoryFolderHelp.HelpVideo;
        const params = [] as any;

        if (lang) params.push(`lang=${lang}`);
        if (category) params.push(`category=${category}`);
        if (folderId) params.push(`folderId=${folderId}`);

        const queriesStr = params.length ? `?${params.join('&')}` : '';

        return BaseRepository.requestWithAuthorize({
            url: `Help/GetFiles${queriesStr}`,
            method: 'get',
        });
    }

    getAllFaqs(): any {
        return BaseRepository.requestWithAuthorize({
            url: `Faq/GetAllFaqs`,
            method: 'get',
        });
    }

    getAllTopics(): any {
        return BaseRepository.requestWithAuthorize({
            url: `Faq/GetAllTopics`,
            method: 'get',
        });
    }

    getByTopic(topicId): any {
        return BaseRepository.requestWithAuthorize({
            url: `Faq/GetByTopic?topicId=${topicId}`,
            method: 'get',
        });
    }

    getQnAByTopic(topicId): any {
        return BaseRepository.requestWithAuthorize({
            url: `Faq/GetQnAByTopic?topicId=${topicId}`,
            method: 'get',
        });
    }

    getSpecialFaqs(pageSize): any {
        return BaseRepository.requestWithAuthorize({
            url: `Faq/GetSpecialFaqs?pageSize=${pageSize}`,
            method: 'get',
        });
    }

    getAllWhatNew(pageSize): any {
        return BaseRepository.requestWithAuthorize({
            url: `WhatsNew/GetAll?pageSize=${pageSize}`,
            method: 'get',
        });
    }
}
