<script setup lang="ts">
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { isShowTaskDetailGlobal } from '@/ui/modules/task/task-global-state';
import {
  isShowDayoffDetail,
  isShowPageGedDetail,
  eventIdGlobal,
} from '@/ui/app-global-state';
import SynDeleteModal from '@/ui/common/molecules/SynModal/SynDeleteModal.vue';
import FilterNotification from '@/ui/modules/notification/FilterNotification.vue';
import realtimefbNotificationComposables from '@/ui/composables/notification/realtimefb-notification-composables';
import notificationListComposables from '@/ui/composables/notification/notification-list.composables';
import commonActionNotificationComposables from '@/ui/composables/notification/common-action-notification-composables';
import NotificationList from '@/ui/modules/notification/notification-list/NotificationList.vue';
import GroupDetailModal from '@/ui/modules/group/detail/GroupDetailModal.vue';

dayjs.extend(relativeTime);

const {
  initSubscriberNotificationCount,
  updateDataFirebaseWhenClose,
  unscribleListenFB,
} = realtimefbNotificationComposables();

const {
  rawNotifications,
  allNotifications,
  latestNotification,
  totalNotification,
  isConditionReadAll,
  isLoading,
  skipCount,
  defaultMaxResultCount,
  isLoadingMore,
  category,
  notificationType,
  notificationCount,
  notificationCountMyTask,
  initData,
  onChangeCategory,
  onFilterUserIdNotification,
  onFilterGroupIdNotification,
  onFilterDomainIdNotification,
  onFilterTypesNotification,
  loadMoreNotification,
  onShowOnlyUnread,
  getNotificationsData,
  removeFilter,
} = notificationListComposables();

const {
  isOpenAlertReadAll,
  isLoadingMarkReadAll,
  onClickNotification,
  onReadAllNotification,
  onMarkReadNotification,
  onDeleteNotification,
  onReactionNotification,
} = commonActionNotificationComposables();

const isOpenNotification = ref(false);
const notifDropdownRef = ref();
const isOpenFilterNotification = ref(false);

onMounted(async () => {
  await initSubscriberNotificationCount();
  await initData();
});

onUnmounted(() => {
  unscribleListenFB();
});

const openNotificationList = async () => {
  isOpenNotification.value = true;
  defaultMaxResultCount.value = 13;
  skipCount.value = 0;

  await getNotificationsData();

  if (notificationCount.value > 0 || notificationCountMyTask.value > 0)
    updateDataFirebaseWhenClose();
};

const closeNotificationList = async () => {
  isOpenNotification.value = false;
  defaultMaxResultCount.value = 13;
  skipCount.value = 0;
  removeFilter();
};

const elementId = 'NOTIFICATION_BELL';

const hasMore10UnreadNotification = computed<boolean>(() => {
  return notificationType.value?.all?.totalUnread > 10;
});

const onCloseDropdown = (actionType) => {
  if (['chat', 'list-task'].includes(actionType)) {
    notifDropdownRef.value?.onForceClose();
  }
};

const openingDetailGroupId = ref<number | undefined>(undefined);
const openingDetailGroupName = ref<string>('');
const onOpenGroupDetailModal = (group) => {
  openingDetailGroupId.value = group?.groupId;
  openingDetailGroupName.value = group?.groupName;
};
</script>

<template>
  <VigDropdown
    :id="elementId"
    ref="notifDropdownRef"
    v-element-active="{
      id: elementId,
    }"
    hide-on-click="toggle"
    :is-show-dropdown="
      isShowTaskDetailGlobal ||
      isShowDayoffDetail ||
      isShowPageGedDetail ||
      isOpenFilterNotification ||
      eventIdGlobal?.isShow ||
      openingDetailGroupId
    "
    z-index="40"
    @on-dropdown-open="openNotificationList"
    @on-dropdown-close="closeNotificationList"
  >
    <template #dropdown-toggle>
      <button
        class="h-8 w-8 flex-center hover:fill-current rounded-full relative"
        :title="$t('NOTIFICATION_ALERT_UN_READ', { total: notificationCount })"
      >
        <SynIcon
          custom-class="w-5 h-5 fill-current text-current-500"
          class=""
          has-action
          name="bell"
          size="medium"
          :is-active="isOpenNotification"
        />

        <div
          v-if="notificationCount"
          class="
            absolute
            -right-2
            -top-1
            rounded-full
            bg-white
            p-0.5
            flex-center
          "
        >
          <div
            class="
              rounded-full
              bg-red-500
              text-white text-center
              w-5
              h-5
              flex
              justify-center
              items-center
            "
            style="font-size: 11px"
          >
            {{ notificationCount > 9 ? '+9' : notificationCount }}
          </div>
        </div>
      </button>
    </template>

    <template #dropdown-menu>
      <div
        class="flex flex-col rounded-md p-0 h-max"
        :style="'z-index: 20; width: 29rem; min-height: 450px;max-height: min(80vh, 800px);'"
      >
        <div class="px-4 pt-2 bg-white">
          <div
            class="
              text-md
              items-center
              justify-between
              space-x-2
              py-2
              flex
              font-medium
            "
          >
            <span>{{ $t('COMMON_NOTIFICATION_TITLE') || 'Notification' }}</span>
            <div class="dropdown-item" @click="closeNotificationList">
              <SynIcon
                name="close"
                custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                color="gray"
                has-action
              />
            </div>
          </div>

          <!-- TYPE NOTIFICATION -->
          <div class="flex space-x-2 item-center justify-between pt-1 border-b">
            <div class="flex item-center space-x-1">
              <div
                v-for="item in notificationType"
                :key="item.key"
                class="
                  cursor-pointer
                  flex-center
                  text-xs
                  pt-0.5
                  pb-2
                  px-1.5
                  hover:text-current-500 hover:font-medium
                  space-x-2
                "
                :title="$t(item?.label)"
                :class="
                  category == item.key
                    ? 'border-b-2 border-current text-current-500 font-medium'
                    : 'rounded text-gray-500'
                "
                @click.stop="onChangeCategory(item)"
              >
                <span class="text-xs text-overflow-hidden-line">{{
                  $t(item?.label)
                }}</span>
                <span
                  v-if="item?.totalUnread !== 0"
                  class="px-1 text-2xs rounded"
                  :class="
                    category == item.key
                      ? 'bg-current-500 text-white'
                      : 'bg-gray-100 text-gray-500'
                  "
                >
                  {{ item?.totalUnread }}
                </span>
              </div>
            </div>
          </div>

          <!-- ACTION IN TAB (SHOW UNREAD, MASK READ ALL) -->
          <div class="flex items-center justify-between py-3">
            <AtomSwitch
              :model-value="notificationType[category].isOnlyUnread"
              @change="onShowOnlyUnread"
            >
              <span class="text-xs text-black">
                {{ $t('NOTIFICATION_ONLY_SHOW_UNREAD') || 'Only show unread' }}
              </span>
            </AtomSwitch>

            <div class="flex-center space-x-1">
              <div
                class="
                  flex-center
                  space-x-2
                  w-max
                  h-7
                  py-1
                  px-2
                  text-xs
                  border border-gray-50
                  fill-gray-600
                  text-gray-600
                  rounded-full
                  cursor-pointer
                  hover:bg-current-50
                  hover:text-current-400
                  hover:fill-current-400
                "
                @click="isOpenAlertReadAll = true"
              >
                <SynIcon
                  name="select-all-read"
                  custom-class="w-4 h-4"
                ></SynIcon>
                <span>{{
                  $t('NOTIFICATION_MARK_READ_ALL') || 'I read it all'
                }}</span>
              </div>
              <FilterNotification
                v-if="isOpenNotification"
                :category="category"
                :actions-hide="['mark_all_as_reads']"
                @is-open-filter="(value) => (isOpenFilterNotification = value)"
                @on-mark-all-as-read="(value) => (isOpenAlertReadAll = value)"
                @on-filter-user="(userId) => onFilterUserIdNotification(userId)"
                @on-select-type="(type) => onFilterTypesNotification(type)"
                @on-filter-group="
                  (groupId) => onFilterGroupIdNotification(groupId)
                "
                @on-filter-domain="
                  (domainId) => onFilterDomainIdNotification(domainId)
                "
              />
            </div>
          </div>
        </div>
        <!-- NOTIFICATION LIST -->
        <div
          v-if="isOpenNotification"
          class="flex-1 min-h-0 overflow-hidden flex flex-col"
        >
          <NotificationList
            :all-notifications="allNotifications"
            :latest-notification="latestNotification"
            :can-load-more="rawNotifications?.length < totalNotification"
            :is-loading="isLoading"
            :is-loading-more="isLoadingMore"
            :is-condition-read-all="isConditionReadAll"
            :category="category"
            @on-load-more="loadMoreNotification"
            @on-click-notification="onClickNotification"
            @on-mark-read-notification="onMarkReadNotification"
            @on-delete-notification="onDeleteNotification"
            @on-reaction-notification="
              (notification, reaction) =>
                onReactionNotification(notification.id, reaction)
            "
            @on-close-dropdown="onCloseDropdown"
            @on-open-group-detail-modal="onOpenGroupDetailModal"
          />
        </div>

        <router-link :to="{ name: 'Notifications' }">
          <div
            class="
              py-2
              cursor-pointer
              dropdown-item
              hover:bg-opacity-70
              border-t
              bg-white
              rounded-b-md
              flex-center
              font-medium
              text-sm
            "
            :class="
              hasMore10UnreadNotification
                ? 'text-orange-500  hover:bg-secondary-50 '
                : 'text-current-500 hover:bg-current-50 hover:text-current-800'
            "
            @click="closeNotificationList"
          >
            <span
              class=""
              :class="hasMore10UnreadNotification ? 'animate-pulse' : ''"
            >
              {{ $t('NOTIFICATION_VIEW_ALL_NOTIFICATION') }}
            </span>
          </div>
        </router-link>
      </div>
    </template>
  </VigDropdown>

  <SynDeleteModal
    v-if="isOpenAlertReadAll"
    :title="
      isConditionReadAll
        ? $t('NOTIFICATION_LABEL_ALERT_READ_ALL_WHEN_SHOW_UNREAD')
        : $t('NOTIFICATION_LABEL_ALERT_READ_ALL')
    "
    :label-btn-save="$t('COMMON_LABEL_CONFIRM')"
    :is-show-btn-save="!isConditionReadAll"
    :label-btn-cancel="isConditionReadAll ? $t('COMMON_LABEL_CLOSE') : ''"
    :is-loading="isLoadingMarkReadAll"
    @cancel="isOpenAlertReadAll = false"
    @on-save="onReadAllNotification"
  />

  <GroupDetailModal
    v-if="openingDetailGroupId"
    :group-id="openingDetailGroupId"
    :group-name="openingDetailGroupName"
    position="members"
    @cancel="openingDetailGroupId = undefined"
  />
</template>
