<template>
  <svg
    class="Icon DueDateWithRecurrence-repeatIcon RepeatIcon"
    viewBox="0 0 32 32"
    aria-labelledby="titlelui_848"
    role="img"
    focusable="false"
  >
    <title id="titlelui_848">Repeats</title>
    <path
      d="M25.05,6.05H7.05c-2.76,0-5,2.24-5,5v10c0,2.76,2.24,5,5,5h9.59l-3.29,3.29c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l5-5c.09-.09,.17-.2,.22-.33,.1-.24,.1-.52,0-.76-.05-.12-.12-.23-.22-.33l-5-5c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l3.29,3.29H7.05c-1.65,0-3-1.35-3-3V11.05c0-1.65,1.35-3,3-3H25.05c1.65,0,3,1.35,3,3v10c0,1.65-1.35,3-3,3h-1c-.55,0-1,.45-1,1s.45,1,1,1h1c2.76,0,5-2.24,5-5V11.05c0-2.76-2.24-5-5-5Z"
    ></path>
  </svg>
</template>
