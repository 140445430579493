import BaseModel from './BaseModel';
import { ParentSource } from '../enums/FireaseDatabaseEnum';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
export default class DomainDynamicModel extends BaseModel {
    constructor(source: string) {
        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        const sourcePath = `${ParentSource.domain}/organization_${organizationId}/${source}`;
        super(sourcePath);
    }
}
