<script setup lang="ts">
import dayjs from 'dayjs';
import { ref, watchEffect } from 'vue';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { DatePicker } from 'v-calendar';

defineProps<{
  modelValue: Date | null;
  minDate?: Date;
  minWidth?: string;
}>();

const emit = defineEmits(['change']);
const calendarRef = ref(null);
const showDatepicker = ref(false);
const randomId = Math.random();
const optionStype = ref('');
const language = getLocalStorage(StorageConstant.LANGUAGE);
const attrs = [
  {
    key: 'today',
    dot: true,
    dates: new Date(),
  },
];

watchEffect(() => {
  if (calendarRef.value) {
    // calendarRef.value.focusDate(props.modelValue);
  }
});

const onDayClick = (value) => {
  showDatepicker.value = false;
  var dateSelect = dayjs(value.id, 'DD/MM/YYYY');
  emit('change', dateSelect);
};
</script>
<template>
  <vig-dropdown
    placement="bottom-start"
    :arrow="false"
    @on-dropdown-open="showDatepicker = true"
    @on-dropdown-close="showDatepicker = false"
  >
    <template #dropdown-toggle>
      <div
        :id="`syn-date${randomId}`"
        class="flex relative item-center justify-between w-full"
      >
        <input
          type="text"
          class="
            w-full
            pl-4
            pr-10
            py-2
            border-gray-300
            focus:border-current
            focus:ring-0
            focus:ring-current
            focus:ring-opacity-30
            leading-none
            rounded-md
            focus
            shadow-sm
            focus:outline-none focus:shadow-outline
          "
          placeholder="DD/MM/YYYY"
          :value="
            !isNaN($filters.dayjs(modelValue, 'DD/MM/YYYY'))
              ? $filters.dayjs(modelValue, 'DD/MM/YYYY')
              : modelValue
          "
          @keydown="showDatepicker = false"
          @input="$emit('change', $event.target.value)"
        />
        <div
          class="absolute right-3 top-2 cursor-pointer"
          @click="showDatepicker = true"
        >
          <SynIcon name="dayoff" :is-active="showDatepicker" />
        </div>
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="h-full border-none top-0 z-100"
        @click.self="showDatepicker = false"
      >
        <date-picker
          ref="calendarRef"
          :style="optionStype"
          :attributes="attrs"
          :locale="language"
          :value="modelValue"
          color="teal"
          @dayclick="onDayClick"
        />
      </div>
    </template>
  </vig-dropdown>
  <!-- <div
    :id="`syn-date${randomId}`"
    class="flex relative item-center justify-between w-full"
  >
    <input
      type="text"
      class="w-full pl-4 pr-10 py-2 text-sm border-gray-200 leading-none rounded-md focus:ring-current focus shadow-sm focus:outline-none focus:shadow-outline text-gray-600"
      placeholder="DD/MM/YYYY"
      :value="
        !isNaN($filters.dayjs(modelValue, 'DD/MM/YYYY'))
          ? $filters.dayjs(modelValue, 'DD/MM/YYYY')
          : modelValue
      "
      @keydown="showDatepicker = false"
      @input="$emit('change', $event.target.value)"
    />
    <div
      class="absolute right-3 top-2 cursor-pointer"
      @click="onShowDatepicker"
    >
      <SynIcon name="dayoff" :is-active="showDatepicker" />
    </div>
  </div>
  <teleport v-if="showDatepicker" to="body">
    <div class="h-full top-0 z-100" @click.self="showDatepicker = false">
      <date-picker
        ref="calendarRef"
        :style="optionStype"
        :locale="language"
        :value="modelValue"
        color="teal"
        @dayclick="onDayClick"
      />
    </div>
  </teleport> -->
</template>
