<script setup lang="ts">
import { computed, reactive, ref, onMounted, toRaw, watch } from 'vue';
import TaskService from '@/application/services/task/TaskService';
import TaskWorkflowEntity, {
  ITaskWorkflowEntity,
} from '@/domain/entities/task/TaskWorkflowEntity';
import TaskWorkflowModal from '@/ui/modules/task/workflow/TaskWorkflowModal.vue';
// import workflowStore from '@/store/workflow';
import {
  ITaskDetailClass,
  TaskDetailClass,
} from '@/domain/entities/task/TaskPresentClass';
import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';
import { TaskLife } from '@/domain/enums/taskEnum';
import ApplyWorkFlowModal from '@/ui/modules/task/workflow/ApplyWorkFlowModal.vue';
import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import WorkflowArrow from '@/ui/modules/workflow/WorkflowArrow.vue';
import taskDetailStore from '@/store/task/detail';
import { ATTACHMENTS_ICON_LIST } from '@/application/constants/task.const';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import TaskTodoListAll from '@/ui/modules/task/components/check-list/TaskTodoListAll.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import TaskWorkflowStepDetailModal from '@/ui/modules/task/workflow/step/TaskWorkflowStepDetailModal.vue';

const props = defineProps<{
  viewMode?: 'TASK_VIEW' | 'ATTACH_TO_TASK';
  taskId?: number;
  taskDetail: ITaskDetailClass;
  defaultTaskWorkflow?: ITaskWorkflowEntity;
  isShowIconOnly?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onAttach', data): void;
  (e: 'detach'): void;
  (e: 'detachSuccess'): void;
  (e: 'updateTask', data: any): void;
  (e: 'chooseStep', step: any): void;
  (e: 'updateCurrentStep', step: any, workflowStepData: any): void;
}>();

const _taskDetailStore = taskDetailStore();
const RECENTLY_MAX_LENGTH = 5;
const INIT_TIME = Date.now();
// const _workflowStore = workflowStore();

// const allStepTypeByIds = computed<any>(() => {
//   return _workflowStore.allStepTypeByIds;
// });
const taskWorkflowData = ref<TaskWorkflowEntity>(new TaskWorkflowEntity({}));

const workFlowSteps = computed<ITaskWorkflowStep[]>(() => {
  return (
    taskWorkflowData.value?.steps?.map((step, index) => {
      return {
        ...step,
        index,
      };
    }) || []
  );
});

const currentStepIndex = computed<number>(() => {
  if (!currentStep.value) return 0;
  return taskWorkflowData.value?.steps?.findIndex(
    (o) => o.id == currentStep.value?.id
  );
});
const recentlyStepStartIndex = computed<number>(() => {
  return Math.max(
    0,
    Math.min(workFlowSteps.value?.length - 5, currentStepIndex.value - 2)
  );
});
const recentlyStepEndIndex = computed<number>(() => {
  return Math.min(
    workFlowSteps.value?.length,
    recentlyStepStartIndex.value + RECENTLY_MAX_LENGTH
  );
});
const recentlyStepList = computed<ITaskWorkflowStep[]>(() => {
  if (
    currentStepIndex.value < 0 ||
    currentStepIndex.value >= workFlowSteps.value?.length ||
    workFlowSteps.value?.length == 0 ||
    typeof currentStepIndex.value !== 'number' ||
    recentlyStepEndIndex.value == 0
  ) {
    return [];
  }

  return workFlowSteps.value?.slice(
    recentlyStepStartIndex.value,
    recentlyStepEndIndex.value
  );
});
const previousRemainList = computed<ITaskWorkflowStep[]>(() => {
  if (
    recentlyStepStartIndex.value == 0 ||
    typeof currentStepIndex.value !== 'number'
  ) {
    return [];
  }

  return workFlowSteps.value?.slice(0, recentlyStepStartIndex.value + 1);
});
const afterRemainList = computed<ITaskWorkflowStep[]>(() => {
  if (
    recentlyStepEndIndex.value == workFlowSteps.value?.length ||
    typeof currentStepIndex.value !== 'number'
  ) {
    return [];
  }

  return workFlowSteps.value?.slice(
    recentlyStepEndIndex.value - 1,
    workFlowSteps.value?.length
  );
});

const currentStep = computed<ITaskWorkflowStep | undefined>(() => {
  if (
    !taskWorkflowData.value?.steps ||
    taskWorkflowData.value?.steps?.length == 0
  )
    return undefined;
  return (
    taskWorkflowData.value?.steps?.find((o) => o.taskId == props.taskId) ||
    taskWorkflowData.value?.steps[0]
  );
});
const currentStepInWorkflow = computed<ITaskWorkflowStep | undefined>(() => {
  if (
    !taskWorkflowData.value?.steps ||
    taskWorkflowData.value?.steps?.length == 0 ||
    !taskWorkflowData.value?.currentStepId
  )
    return undefined;
  return taskWorkflowData.value?.steps?.find(
    (o) => o.id == taskWorkflowData.value?.currentStepId
  );
});
const previousStep = computed<ITaskWorkflowStep | null>(() => {
  if (!currentStepIndex.value || currentStepIndex.value <= 0) return null;

  return taskWorkflowData.value?.steps[currentStepIndex.value - 1];
});
const totalByKeys = computed<any>(() => {
  return {
    DESCRIPTION: currentStep.value?.taskDescription ? 1 : 0,
    FILE: currentStep.value?.totalFiles || 0,
    NOTE: currentStep.value?.totalNotes || 0,
    CHECKLIST: currentStep.value?.totalTodoLists || 0,
    WORKFLOW: 0,
    COMMENT: 0,
  };
});

const currentStepHasAttachment = computed<boolean>(() => {
  return (
    totalByKeys.value?.DESCRIPTION > 0 ||
    totalByKeys.value?.FILE > 0 ||
    totalByKeys.value?.NOTE > 0 ||
    totalByKeys.value?.CHECKLIST > 0
  );
});
const currentIconList = computed<any[]>(() => {
  const iconList = ATTACHMENTS_ICON_LIST.map((icon) => {
    return {
      ...icon,
      total: totalByKeys.value[icon?.key] || 0,
    };
  });
  return iconList?.filter((icon) => icon?.total > 0);
});
const currentStepAttachments = computed<any[]>(() => {
  return arrayOrderBy(
    [
      ...(currentStep.value?.files || []),
      ...(currentStep.value?.notes?.map((n) => {
        return {
          ...n,
          isNote: true,
          type: 'image/jpeg',
        };
      }) || []),
    ],
    ['creationTime'],
    ['asc']
  );
});

const loadingByKey = reactive<{
  myPart: boolean;
  rollback: boolean;
  finishCurrentStep: boolean;
  detach: boolean;
}>({
  myPart: false,
  rollback: false,
  finishCurrentStep: false,
  detach: false,
});

const loadingData = reactive<{ init: boolean }>({ init: false });
const initComponent = async () => {
  if (props.taskId) {
    try {
      loadingData.init = true;
      const res = await TaskService.getInstance().getTaskWorkFlow(
        props?.taskId
      );

      taskWorkflowData.value = new TaskWorkflowEntity(res?.result);
    } finally {
      loadingData.init = false;
    }
  } else if (props.defaultTaskWorkflow) {
    taskWorkflowData.value = new TaskWorkflowEntity(props.defaultTaskWorkflow);
  }
};

const updatedTaskWorkflowId = computed<any>(() => {
  return _taskDetailStore.updatedTaskWorkflowId;
});
watch(
  () => updatedTaskWorkflowId.value,
  (newValue) => {
    if (!props.taskDetail?.workFlowTaskId) return;
    if (newValue.split(':').pop() !== props.taskDetail?.workFlowTaskId) return;

    initComponent();
  }
);

const detailCardRef: any = ref(null);
const detailCardPosition = ref<{ translateX: number }>({
  translateX: 0,
});
const initDropdownCurrentStep = () => {
  if (!currentStep.value?.id) return;

  const containerElement = document.getElementById('workflow-step-container');
  const currentStepElement = document.getElementById(
    `${currentStep.value?.id}_${INIT_TIME}`
  );
  const containerBounding = containerElement?.getBoundingClientRect();
  if (!currentStepElement || !containerBounding) return;

  const currentStepLeft = currentStepElement?.offsetLeft + 25;
  const detailCardElement = detailCardRef.value?.getBoundingClientRect();
  const centerPoint = detailCardElement?.width / 2;

  detailCardPosition.value.translateX = Math.min(
    Math.max(0, currentStepLeft - centerPoint),
    containerBounding?.width - detailCardElement?.width
  );
};
onMounted(async () => {
  if (props.isShowIconOnly) return;

  await initComponent();
  setTimeout(() => {
    initDropdownCurrentStep();
  }, 100);
});

const isOpenDetail = ref<boolean>(false);
const isOpenWorkFlow = ref<boolean>(false);

const onOpenDetailModal = async () => {
  if (props.isShowIconOnly) {
    await initComponent();
  }

  if (props.taskDetail?.id) isOpenDetail.value = true;
  else isOpenWorkFlow.value = true;
};

const onRollback = async () => {
  if (!previousStep.value?.id) return;

  loadingByKey.rollback = true;

  let promiseAll: any[] = [];
  if (props.taskDetail?.taskLife == TaskLife.Finished) {
    const taskDetailEntity = new TaskDetailClass(props.taskDetail);
    // const confirm = await taskDetailEntity.checkChangeStatusWhenHasWorkflow(
    //   TaskLife.InProcess
    // );

    // if (!confirm) return;

    promiseAll.push(taskDetailEntity.changeState(TaskLife.InProcess));
  }

  promiseAll.push(
    taskWorkflowData.value.reopenTaskWorkflowStep(previousStep.value?.id)
  );
  await Promise.all(promiseAll);
  loadingByKey.rollback = false;

  initComponent();

  setTimeout(() => {
    emit('updateTask', taskWorkflowData.value);
  });
};
const onFinishCurrentStep = async () => {
  loadingByKey.finishCurrentStep = true;

  await taskWorkflowData.value.finishCurrentStep();
  loadingByKey.finishCurrentStep = false;

  await initComponent();
  setTimeout(() => {
    emit('updateTask', taskWorkflowData.value);
  });
};
const onDetachWorkflow = async () => {
  if (props.taskId) {
    loadingByKey.detach = true;

    await taskWorkflowData.value.detach();
    emit('detachSuccess');

    loadingByKey.detach = false;
  } else {
    emit('detach');
  }
};

const isOpenMoreAction = ref<boolean>(false);
const isNeedUpdateTask = ref<boolean>(false);

const onCloseDetailModal = async () => {
  isOpenDetail.value = false;
  if (isNeedUpdateTask.value)
    setTimeout(() => {
      emit('updateTask', taskWorkflowData.value);
    });
  // if (!props.isShowIconOnly) await initComponent();
};

const updateTaskWorkflow = (newData) => {
  taskWorkflowData.value = new TaskWorkflowEntity(newData);
  isOpenWorkFlow.value = false;

  emit('onAttach', taskWorkflowData.value);
};

const isShowCurrentStepAttachment = ref<boolean>(false);

const currentFileIndex = ref(0);
const isPreview = ref(false);
const onOpenFiles = (file) => {
  currentFileIndex.value = currentStepAttachments.value.findIndex(
    (o) => o.id == file.id
  );
  isPreview.value = true;
};

const toggleShowAttachment = () => {
  if (!currentStepHasAttachment.value) return;

  isShowCurrentStepAttachment.value = !isShowCurrentStepAttachment.value;
};

const onChooseStep = async (step) => {
  if (!props.taskId) {
    onOpenDetailModal();
    return;
  }
  if (!step?.taskId) {
    settingGlobalModal({
      type: 'confirm',
      title: translate('TASK_WORKFLOW_STEP_DO_NOT_IMPLEMENTED_TITLE'),
      content: translate('TASK_WORKFLOW_STEP_DO_NOT_IMPLEMENTED'),
      closeable: false,
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_GOT_IT'),
    });
    await ask();

    return;
  }

  if (step?.taskId == props.taskId) return;
  emit('chooseStep', step);
};

const isOpenUpdateTaskWorkflowStepDetailModal = ref<boolean>(false);
const onOpenUpdateStepModal = () => {
  isOpenUpdateTaskWorkflowStepDetailModal.value = true;
};

const updateSuccess = (data, workflowStepData) => {
  isOpenUpdateTaskWorkflowStepDetailModal.value = false;
  if (props.viewMode == 'TASK_VIEW') initComponent();

  emit('updateCurrentStep', data, workflowStepData);
};

const goToCurrentStep = () => {
  onChooseStep(currentStepInWorkflow.value);
};

defineExpose({
  initComponent,
  onOpenDetailModal,
  goToCurrentStep,
});
</script>
<template>
  <div
    v-if="isShowIconOnly"
    class="flex-center hover:bg-current-50 rounded px-0.5"
    @click.stop="onOpenDetailModal"
  >
    <span class="flex-center h-5 w-5">
      <SynIcon custom-class="w-3.5 h-3.5 fill-current" name="flow" />
    </span>
    <!-- <span class="text-xs text-current-500">
      {{ currentStepIndex + 1 }} /
      {{ taskWorkflowData?.steps?.length }}
    </span> -->
  </div>
  <div
    v-else
    class="flex-1 w-full flex-center relative pb-4"
    style="max-width: 100%"
  >
    <!-- body -->
    <section class="w-full flex flex-col relative space-y-1">
      <div class="flex items-center justify-between">
        <!-- information -->
        <div class="flex-1 min-w-0 flex items-center space-x-1">
          <span class="text-xs text-gray-500">
            {{ $t('TASK_ACTION_WORK_FLOW') }}:
          </span>
          <div
            class="
              flex-center
              gap-2
              cursor-pointer
              hover:text-current-500 hover:fill-current-500
              hover-to-show__parent
            "
            @click="onOpenDetailModal"
          >
            <span class="text-sm font-semibold truncate text-current-500">
              {{ taskWorkflowData?.name }}
            </span>
            <SynIcon
              name="edit"
              is-active
              class="
                hover-to-show__children
                fill-current
                cursor-pointer
                absolute
                hover:fill-current-800
                -right-5
              "
              :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
            />
          </div>
        </div>
        <!-- action -->
        <div class="flex-center space-x-2">
          <template v-if="taskId && false">
            <AtomButton
              v-if="previousStep?.id"
              type="OUT_LINE"
              color="gray"
              :is-loading="loadingByKey.myPart"
              @click="onRollback"
            >
              <div class="flex-center space-x-2 px-4">
                <SynIcon
                  name="set-finished"
                  custom-class="w-4 h-4 fill-gray-500"
                />
                <span class="text-gray-500">
                  {{ $t('COMMON_LABEL_ROLLBACK') }}
                </span>
              </div>
            </AtomButton>
            <AtomButton
              v-if="currentStep?.status !== EWorkflowTaskStepStatus.COMPLETED"
              type="OUT_LINE"
              color="green"
              :is-loading="loadingByKey.myPart"
              @click="onFinishCurrentStep"
            >
              <div class="flex-center space-x-2 px-4">
                <SynIcon
                  name="set-finished"
                  custom-class="w-4 h-4 fill-green-500"
                />
                <span class="text-green-500">
                  {{
                    $t('TASK_WORKFLOW_BAR_FINISH_AND_NEXT') || 'Finish & next'
                  }}
                </span>
              </div>
            </AtomButton>
          </template>
          <template v-if="taskId">
            <button
              v-if="currentStep?.id !== taskWorkflowData?.currentStepId"
              class="
                focus:outline-none
                flex-center
                relative
                flex-center
                space-x-1
                h-6
                text-sm
                bg-transparent
                hover:bg-gray-50 hover:bg-opacity-30
                text-blue-500
                fill-blue-500
                border border-blue-500 border-dashed
                rounded-full
              "
              :is-loading="loadingByKey.myPart"
              @click="onChooseStep(currentStepInWorkflow)"
            >
              <div class="flex-center space-x-2 px-2">
                <SynIcon name="goto" custom-class="w-4 h-4 fill-blue-500" />
                <span class="text-blue-500 text-xs">
                  {{
                    $t('TASK_WORKFLOW_GO_TO_CURRENT_STEP') ||
                    'Go to current step'
                  }}
                </span>
              </div>
            </button>
          </template>
          <VigDropdown
            v-if="!taskId"
            :arrow="false"
            placement="bottom-end"
            @on-dropdown-open="isOpenMoreAction = true"
            @on-dropdown-close="isOpenMoreAction = false"
          >
            <template #dropdown-toggle>
              <button
                class="w-8 h-8 rounded-md shadow hover:bg-gray-100 flex-center"
                :class="isOpenMoreAction ? 'bg-gray-100' : ''"
              >
                <SynIcon name="dots-vertical" custom-class="w-4 h-4" />
              </button>
            </template>
            <template #dropdown-menu>
              <div
                class="py-1 z-100 list-ul rounded cursor-pointer dropdown-item"
                style="min-width: 11rem"
              >
                <template v-if="taskId">
                  <!-- onRollback -->
                  <div
                    v-if="
                      previousStep?.id ||
                      currentStep?.status == EWorkflowTaskStepStatus.COMPLETED
                    "
                    class="
                      list-li
                      flex
                      text-gray-600
                      justify-start
                      items-center
                      space-x-2
                      text-sm
                      relative
                    "
                    @click="onRollback"
                  >
                    <SynIcon name="back" />
                    <div class="flex-center space-x-2">
                      <span>
                        {{
                          $t('WORKFLOW_LABEL_BACK_TO_STEP') || 'Detach Workflow'
                        }}
                      </span>
                      <span class="text-current-500">
                        {{
                          currentStep?.status ==
                          EWorkflowTaskStepStatus.COMPLETED
                            ? currentStep?.name
                            : previousStep?.name
                        }}
                      </span>
                    </div>
                  </div>
                  <!-- Finish & next -->
                  <div
                    v-if="
                      currentStep?.status !== EWorkflowTaskStepStatus.COMPLETED
                    "
                    class="
                      list-li
                      flex
                      text-gray-600
                      justify-start
                      items-center
                      space-x-2
                      text-sm
                      relative
                    "
                    @click="onFinishCurrentStep"
                  >
                    <SynIcon
                      name="set-finished"
                      custom-class="w-4 h-4 fill-green-500"
                    />
                    <div class="flex-center space-x-2">
                      <span>
                        {{
                          $t('WORKFLOW_LABEL_FINISH_STEP') || 'Detach Workflow'
                        }}
                      </span>
                      <span class="text-current-500">
                        {{ currentStep?.name }}
                      </span>
                    </div>
                  </div>
                </template>
                <div
                  class="
                    list-li
                    flex
                    text-gray-600
                    justify-start
                    items-center
                    space-x-2
                    text-sm
                    relative
                  "
                  :class="taskId ? 'border-t' : ''"
                  @click="onOpenDetailModal"
                >
                  <SynIcon name="edit" custom-class="w-4 h-4 fill-blue-500" />
                  <span>
                    {{
                      $t('WORKFLOW_LABEL_EDIT_WORKFLOW') || 'Detach Workflow'
                    }}
                  </span>
                </div>
                <div
                  if="!taskId"
                  class="
                    list-li
                    flex
                    text-gray-600
                    justify-start
                    items-center
                    space-x-2
                    text-sm
                    relative
                  "
                  @click="onDetachWorkflow"
                >
                  <SynIcon
                    v-if="loadingByKey.detach"
                    name="spinner"
                    class="fill-current-500 border-none animate-spin"
                    custom-class="w-4 h-4"
                  />
                  <SynIcon
                    v-else
                    name="trash"
                    custom-class="w-4 h-4 fill-red-500"
                  />
                  <span>
                    {{
                      $t('TASK_WORKFLOW_BAR_DETACH_WORKFLOW') ||
                      'Detach Workflow'
                    }}
                  </span>
                </div>
              </div>
            </template>
          </VigDropdown>
        </div>
      </div>
      <!-- process -->
      <div class="w-full flex items-center space-x-2">
        <div
          v-if="workFlowSteps?.length > 0"
          id="workflow-step-container"
          class="flex flex-col flex-1 min-w-0 relative"
          :style="isShowCurrentStepAttachment ? '' : 'padding-bottom: 40px'"
        >
          <div class="w-full flex-center">
            <div
              class="flex items-center justify-around gap-2"
              :class="recentlyStepList?.length == 2 ? 'w-2/3' : 'w-full'"
            >
              <template
                v-for="(step, index) in recentlyStepList"
                :key="step?.id"
              >
                <VigDropdown
                  v-if="index == 0 && previousRemainList?.length > 0"
                  :arrow="false"
                  placement="bottom-start"
                >
                  <template #dropdown-toggle>
                    <div
                      class="
                        flex-center
                        relative
                        h-8
                        w-8
                        rounded-full
                        bg-green-500
                        text-white
                        cursor-pointer
                      "
                    >
                      +{{ previousRemainList?.length }}
                    </div>
                  </template>
                  <template #dropdown-menu>
                    <div
                      class="
                        py-1
                        z-100
                        list-ul
                        rounded
                        cursor-pointer
                        dropdown-item
                      "
                      style="min-width: 11rem"
                    >
                      <div
                        v-for="step in previousRemainList"
                        :key="step?.id"
                        class="
                          list-li
                          flex
                          text-gray-600
                          justify-start
                          items-center
                          space-x-2
                          text-sm
                          relative
                        "
                        @click="onChooseStep(step)"
                      >
                        <span> {{ step?.stepOrder + 1 }}. </span>
                        <span>
                          {{ step?.name }}
                        </span>
                      </div>
                    </div>
                  </template>
                </VigDropdown>
                <VigDropdown
                  v-else-if="
                    index == recentlyStepList?.length - 1 &&
                    afterRemainList?.length > 0
                  "
                  :arrow="false"
                  placement="bottom-end"
                >
                  <template #dropdown-toggle>
                    <div
                      class="
                        flex-center
                        relative
                        h-8
                        w-8
                        rounded-full
                        bg-gray-100
                        text-gray-800
                        cursor-pointer
                      "
                    >
                      +{{ afterRemainList?.length }}
                    </div>
                  </template>
                  <template #dropdown-menu>
                    <div
                      class="
                        py-1
                        z-100
                        list-ul
                        rounded
                        cursor-pointer
                        dropdown-item
                      "
                      style="min-width: 11rem"
                    >
                      <div
                        v-for="step in afterRemainList"
                        :key="step?.id"
                        class="
                          list-li
                          flex
                          text-gray-600
                          justify-start
                          items-center
                          space-x-2
                          text-sm
                          relative
                        "
                        @click="onChooseStep(step)"
                      >
                        <span> {{ step?.stepOrder + 1 }}. </span>
                        <span>
                          {{ step?.name }}
                        </span>
                      </div>
                    </div>
                  </template>
                </VigDropdown>

                <div
                  v-else
                  :id="`${step?.id}_${INIT_TIME}`"
                  v-vig-tooltip="step?.name"
                  class="flex-center relative cursor-pointer"
                  :data-is-active="currentStep?.id == step?.id"
                  :class="[
                    step?.status == EWorkflowTaskStepStatus.COMPLETED
                      ? 'bg-green-500 text-white'
                      : step?.status == EWorkflowTaskStepStatus.INPROGRESS
                      ? 'bg-pink-500 text-white'
                      : step?.status == EWorkflowTaskStepStatus.TODO
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-gray-800',
                    currentStep?.id == step?.id
                      ? 'font-semibold text-xl h-11 w-11 rounded-full'
                      : 'h-8 w-8 rounded-full',
                  ]"
                  @click="onChooseStep(step)"
                >
                  {{ step?.stepOrder + 1 }}

                  <span
                    v-if="currentStep?.id == step?.id"
                    class="absolute bottom-0 translate-y-4"
                  >
                    <SynIcon
                      :name="'sort-up'"
                      custom-class="h-2.5 w-2.5 fill-current"
                    />
                  </span>
                </div>

                <WorkflowArrow
                  v-if="index < recentlyStepList?.length - 1"
                  arrow-type="LTR"
                />
              </template>
            </div>
          </div>
          <div
            v-if="currentStep?.id"
            ref="detailCardRef"
            class="
              px-4
              py-2
              border
              rounded-md
              border-current-500 border-dashed
              flex flex-col
              gap-4
            "
            :style="{
              transform: isShowCurrentStepAttachment
                ? `translate(0px, 1px)`
                : `translate(${detailCardPosition.translateX}px, 61px)`,
            }"
            :class="isShowCurrentStepAttachment ? 'w-full' : 'w-max absolute'"
          >
            <div
              class="flex-center justify-between gap-2 cursor-pointer"
              @click="toggleShowAttachment"
            >
              <div class="flex-center gap-2">
                <span class="text-current-500">
                  {{ currentStepIndex + 1 }}. {{ currentStep?.name }}
                </span>
                <div v-if="!isShowCurrentStepAttachment" class="flex gap-1">
                  <template v-for="icon in currentIconList" :key="icon?.key">
                    <span
                      v-vig-tooltip="$t(icon?.titleKey)"
                      class="text-xs p-0.5 flex-center space-x-0.5 rounded"
                      :style="{
                        fill: icon?.color,
                        color: icon?.color,
                      }"
                      @click.stop="icon?.onClick && icon?.onClick()"
                    >
                      <SynIcon
                        :is-active="icon?.isActive"
                        :name="icon?.iconName"
                        custom-class="w-3.5 h-3.5"
                      />
                      <template v-if="icon?.total > 1">
                        <span>
                          {{ icon?.total }}
                        </span>
                      </template>
                    </span>
                  </template>
                </div>
              </div>
              <div class="flex-center gap-2">
                <AtomButton
                  color="white"
                  class="w-8 h-8"
                  @click.stop="onOpenUpdateStepModal"
                >
                  <SynIcon name="edit" custom-class="w-4 h-4 fill-gray-500" />
                </AtomButton>
                <SynIcon
                  v-if="currentStepHasAttachment"
                  :name="'sort-down'"
                  custom-class="h-2.5 w-2.5 fill-current"
                  :class="!isShowCurrentStepAttachment ? '-rotate-90' : ''"
                />
              </div>
            </div>
            <div v-if="isShowCurrentStepAttachment" class="flex flex-col gap-2">
              <!-- DESCRIPTION -->

              <section v-if="currentStep.taskDescription">
                <div class="w-full flex items-center space-x-2">
                  <span class="text-sm flex items-center">
                    {{ $t('COMMON_LABEL_DESCRIPTION') || 'Description' }}
                  </span>
                </div>

                <div class="w-full items-center flex flex-wrap pb-1">
                  <span
                    class="
                      task-detail_description
                      break-words
                      text-gray-500
                      italic
                    "
                    v-html="
                      currentStep.taskDescription ||
                      $t('COMMON_LABEL_PLACEHOLDER_ADD_DESCRIPTION') ||
                      'Add description ...'
                    "
                  ></span>
                </div>
              </section>

              <!-- FILES -->
              <section v-if="currentStepAttachments?.length > 0">
                <div class="w-full flex items-center space-x-2">
                  <span class="text-sm flex items-center">
                    {{
                      `${$t('TASK_DETAIL_LABEL_ATTACHMENT') || 'Attachments'} ${
                        totalByKeys?.FILE + totalByKeys?.NOTE > 0
                          ? '(' + (totalByKeys?.FILE + totalByKeys?.NOTE) + ')'
                          : ''
                      }`
                    }}
                  </span>
                </div>

                <div
                  v-if="currentStepAttachments?.length > 0"
                  class="w-full items-center flex flex-wrap pb-1"
                >
                  <div
                    v-for="(file, index) in currentStepAttachments"
                    :key="index"
                    class="
                      relative
                      w-max
                      h-16
                      mr-2
                      mb-2
                      hover-to-hidden__parent
                    "
                  >
                    <UploadPreview
                      class="m-0"
                      :file="file"
                      readonly
                      :is-note="file.isNote"
                      is-hidden-info
                      @on-select="onOpenFiles(file)"
                    />
                  </div>
                </div>
              </section>

              <!-- CHECK LIST -->
              <section v-if="totalByKeys?.CHECKLIST > 0">
                <div class="w-full flex items-center space-x-2 pb-1">
                  <span class="text-sm flex items-center">
                    {{
                      `${$t('COMMON_LABEL_CHECKLIST') || 'Attachments'} ${
                        totalByKeys?.CHECKLIST > 0
                          ? '(' + totalByKeys?.CHECKLIST + ')'
                          : ''
                      }`
                    }}
                  </span>
                </div>
                <section style="max-height: 28rem">
                  <TaskTodoListAll
                    ref="taskTodoListAllRef"
                    :task-id="currentStep?.taskId"
                    :todo-list="currentStep?.todoLists"
                    readonly
                  />
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <TaskWorkflowModal
    v-if="isOpenDetail"
    :task-workflow="taskWorkflowData"
    :task-detail="taskDetail"
    :is-loading="loadingData.init"
    @update:workflow-steps="isNeedUpdateTask = true"
    @cancel="onCloseDetailModal"
  />

  <ApplyWorkFlowModal
    v-if="isOpenWorkFlow"
    :task-workflow="toRaw(taskWorkflowData)"
    :domain-id="taskDetail?.domainId"
    :group-id="taskDetail?.groupId"
    :task-detail="taskDetail"
    @cancel="isOpenWorkFlow = false"
    @on-attach="updateTaskWorkflow"
  />

  <ModalMultipleFileViewer
    v-if="isPreview && currentStepAttachments?.length > 0"
    ref="modalMultipleFileViewerRef"
    :start-index="currentFileIndex"
    :files="currentStepAttachments"
    :editable="false"
    :current-task="currentStep"
    @on-close="isPreview = false"
  >
    <template #title>
      <span v-html="`${currentStepIndex + 1}. ${currentStep?.name}`"> </span>
    </template>
  </ModalMultipleFileViewer>

  <TaskWorkflowStepDetailModal
    v-if="isOpenUpdateTaskWorkflowStepDetailModal"
    :step-detail="{
      ...currentStep,
      taskId: taskDetail?.id || 0,
    }"
    :view-mode="viewMode"
    :task-detail="taskDetail"
    :task-workflow-id="taskDetail?.taskWorkflowId"
    :readonly="readonly"
    is-update-only-attachment
    @close="isOpenUpdateTaskWorkflowStepDetailModal = false"
    @update-success="updateSuccess"
  />
</template>
