<script setup lang="ts">
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import myProfile from '@/store/auth/my-profile';
import { computed } from 'vue';

const emit = defineEmits(['onUpdateUser', 'onCancelInactive', 'onCancel']);

defineProps<{
  visible?: boolean;
  idUser?: number;
  nameUser?: string;
  statusActive?: number;
  allUserShortSummary?: any;
}>();

const _myProfile = myProfile();

const isOwner = computed(() => _myProfile.isOwner);
// const isAdmin = computed(() => _myProfile.isAdmin);

const onupdateUser = async (id, stt) => {
  // console.log('modal onupdateUser', stt);
  emit('onUpdateUser', { id, stt });
};

const onCancelInactive = async (id) => {
  emit('onCancelInactive', id);
};
</script>

<template>
  <SynQuestionModal
    :visible="visible"
    :show-confirm-btn="false"
    :show-delete-btn="true"
    :show-header="false"
    :header-text="$t('USERMANAGEMENT_ALERT_INACTIVE_USER')"
    :content-text="`
        <div class='flex flex-col px-2'>
          ${
            getCurrentUserId() !== idUser
              ? `<span class='font-medium'>${$t(
                  'USERMANAGEMENT_FORM_DEACTIVE_OTHER',
                  {
                    username: nameUser,
                  }
                )}</span>`
              : `<span class='font-medium'>${$t(
                  'USERMANAGEMENT_FORM_DEACTIVE_MYSELF'
                )}</span>`
          }

          <span class='text-base text-gray-700 pt-6 pb-2'>${$t(
            getCurrentUserId() === idUser
              ? isOwner &&
                allUserShortSummary.totalOwner === 1 &&
                allUserShortSummary.totalUser > 1
                ? `USERMANAGEMENT_FORM_CANNOT_DEACTIVE_MYSELF_TITLE`
                : `USERMANAGEMENT_FORM_DEACTIVE_MYSELF_TITLE`
              : 'USERMANAGEMENT_FORM_DEACTIVE_OTHER_TITLE'
          )}</span>

          <span class='text-sm ps-4 text-gray-500'>${$t(
            getCurrentUserId() === idUser
              ? isOwner &&
                allUserShortSummary.totalOwner === 1 &&
                allUserShortSummary.totalUser > 1
                ? `USERMANAGEMENT_FORM_CANNOT_DEACTIVE_MYSELF_BODY`
                : `USERMANAGEMENT_FORM_DEACTIVE_MYSELF_BODY`
              : 'USERMANAGEMENT_FORM_DEACTIVE_OTHER_BODY'
          )}</span>
        </div>
      `"
    :delete-label="$t('USERMANAGEMENT_LABEL_DEACTIVATE_USER')"
    :disabled-btn="
      isOwner &&
      getCurrentUserId() == idUser &&
      allUserShortSummary.totalOwner == 1
    "
    @cancel="$emit('onCancel')"
    @on-delete="
      (value) =>
        value ? onupdateUser(idUser, statusActive) : onCancelInactive(idUser)
    "
  />
</template>
