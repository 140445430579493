<template>
  <syn-modal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    style-body="p-1"
    :is-hidden-footer="true"
    @cancel="$emit('onCancel')"
  >
    <template #header>
      <div>{{ $t('CHAT_REACTIONS') || 'Message reactions' }}</div>
    </template>

    <template #body>
      <!--TABS-->
      <div
        class="
          flex flex-nowrap
          overflow-x-auto
          small-scrollbar
          px-3
          border-b
          whitespace-nowrap
        "
      >
        <button
          v-for="group in reactionsGroup"
          :key="group?.content"
          class="px-2 py-2 hover:bg-gray-100 flex-center space-x-2"
          :class="
            activeGroup?.content === group?.content
              ? 'text-current-500 font-semibold border-b-2 border-current-500'
              : 'text-gray-600 opacity-80'
          "
          @click="activeGroup = group"
        >
          <div v-if="group?.content === 'ALL'">
            {{ $t('CHAT_REACTIONS_ALL') || 'All' }}
          </div>
          <div v-else class="w-5 h-5 flex-center">
            <span v-twemoji="{ size: 'w-5 h-5' }" class="text-lg flex-center">{{
              group?.content
            }}</span>
          </div>
          <div>{{ group?.totalReactions }}</div>
        </button>
      </div>

      <!--ACTIVE TAB-->
      <div class="h-96 overflow-auto small-scrollbar p-2">
        <div
          v-for="reaction of activeGroup?.reactions"
          :key="reaction?.createdBy"
          class="px-3 py-3 flex items-center space-x-2"
          :class="
            reaction?.createdBy === myProfile?.id?.toString()
              ? 'cursor-pointer hover:bg-gray-100 rounded'
              : 'pointer-events-none'
          "
          @click="$emit('onReactionRemove', reaction?.reactionIds)"
        >
          <syn-avatar
            custom-class="h-8 w-8"
            :src="
              members[reaction?.createdBy]?.avatar?.replace('original', 'mini')
            "
            :name="members[reaction?.createdBy]?.name"
          ></syn-avatar>
          <div class="flex-1">
            <div class="font-semibold text-sm">
              {{ members[reaction?.createdBy]?.name }}
            </div>
            <div
              v-if="reaction?.createdBy === myProfile?.id?.toString()"
              class="text-sm text-gray-500 leading-4 text-xs"
            >
              {{ $t('CHAT_REACTION_CLICK_TO_REMOVE') || 'Click to remove' }}
            </div>
          </div>
          <div class="flex flex-center space-x-2">
            <div class="font-medium text-gray-700">
              {{ reaction?.reactionIds?.length }}
            </div>
            <div
              v-for="content in reaction?.contents"
              :key="content"
              v-twemoji="{ size: 'w-6 h-6' }"
              class="text-xl flex-center"
              v-text="content"
            ></div>
          </div>
        </div>
      </div>
    </template>
  </syn-modal>
</template>

<script>
import { computed, onMounted, watch, ref } from 'vue';
import { chain, map, orderBy } from 'lodash';

export default {
  components: {},
  props: {
    reactions: {
      type: Object,
      default: null,
    },
    members: {
      type: Object,
      default: () => ({}),
    },
    myProfile: {
      type: Object,
      default: null,
    },
  },
  emits: ['onCancel', 'onReactionRemove'],
  setup(props) {
    const activeGroup = ref();

    const reactionsList = computed(() =>
      orderBy(
        Object.values(props.reactions || {}),
        ['createdDate', 'id'],
        ['desc', 'asc']
      )
    );

    const reactionsGroup = computed(() => {
      const reactionsOfGroup = (reactions) =>
        chain(reactions)
          .groupBy('createdBy')
          .map((memberReactions, createdBy) => ({
            createdBy,
            contents: chain(memberReactions)
              .map('content')
              .uniq()
              .slice(0, 3)
              .value(),
            reactionIds: map(memberReactions, 'id'),
          }))
          .orderBy(({ reactionIds }) => reactionIds?.length, 'desc')
          .value();

      const groupAll = {
        content: 'ALL',
        reactions: reactionsOfGroup(reactionsList.value),
        totalReactions: reactionsList.value?.length,
      };

      const groups = chain(reactionsList.value)
        .groupBy('content')
        .map((reactions, content) => ({
          content,
          reactions: reactionsOfGroup(reactions),
          totalReactions: reactions?.length,
        }))
        .orderBy('totalReactions', 'desc')
        .value();

      return [groupAll].concat(groups);
    });

    watch(
      () => reactionsGroup.value,
      () => {
        const reActiveGroup = reactionsGroup.value?.find(
          (group) => group?.content === activeGroup.value?.content
        );

        activeGroup.value = reActiveGroup || (reactionsGroup.value || [])[0];
      }
    );

    onMounted(() => {
      activeGroup.value = (reactionsGroup.value || [])[0];
    });

    return {
      reactionsGroup,
      activeGroup,
    };
  },
};
</script>
