<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  StorageConstant,
  getLocalStorage,
  clearLocalStorage,
} from '@/ui/hooks/storageHook';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import { AuthenticateCaseEnum } from '@/application/entities/authenticate/AuthenticateEnum';
import settingStore from '@/store/setting';
import RouterInstanceClass from '@/ui/router/RouterInstanceClass';
import myProfileStore from '@/store/auth/my-profile';

const _myProfileStore = myProfileStore();
const _settingStore = settingStore();
const router = useRouter();
const route = useRoute();
const parserUserSessionInfo = () => {
  const queryData: any = route.query;

  const organizationId = queryData.organizationId;
  const token = queryData.token;

  MyOrganizationSingleton.setInstance([], organizationId, token, '', 0, null);

  onChangeOrganization(organizationId);
};
onMounted(async () => {
  const currentToken = getLocalStorage(StorageConstant.TOKEN);
  if (currentToken) {
    clearLocalStorage({ isSwitchingOrg: false });

    const userName = route.query?.userName;

    settingGlobalModal({
      type: 'confirm',
      title: '',
      content:
        translate('COMMON_LABEL_QUESTION_CHECKOUT_USER', {
          userName: userName,
        }) ||
        `You are in another user's session on this browser. Do you want to switch to user ${userName}?`,
      confirmable: true,
      closeable: true,
    });

    await ask();
  }

  parserUserSessionInfo();
});

const prepareDataBeforeNextRoute = async () => {
  await _myProfileStore.fetchCurrentLoginUser();
  await _settingStore.getOrganizationSettings();
  RouterInstanceClass.getInstance().generateRoutes();
  RouterInstanceClass.getInstance().generateDynamicRoutes();
};

const handleLoginSuccess = async (result) => {
  if (!result) return;

  switch (result.caseKey) {
    case AuthenticateCaseEnum.WaitingForActivation:
      {
        router.push({
          name: 'WaitingActivation',
        });
      }
      break;

    case AuthenticateCaseEnum.SettingTeam:
      {
        await prepareDataBeforeNextRoute();

        router.push({
          name: 'Inviation',
        });
      }
      break;

    default:
      // router.replace({
      //   name: 'Home',
      // });

      history.replaceState({}, '', '/');

      window.location.reload();
      break;
  }
};

const onChangeOrganization = async (organizationId) => {
  if (!organizationId) return;
  // switch org
  const authenSelectOrganization = new AuthenticateSelectOrganization(
    organizationId
  );
  const res = await authenSelectOrganization.login();
  handleLoginSuccess(res);
};
</script>

<template>
  <SynLoading />
</template>
