<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isShowChildren: boolean;
    isActive?: boolean;
    parentActiveClass?: string;
    isHaveChildren?: boolean;
  }>(),
  {
    isShowChildren: true,
    isActive: false,
    parentActiveClass: 'bg-gray-200',
    isHaveChildren: true,
  }
);
const emit = defineEmits<{
  (e: 'update:isShowChildren', value: boolean): void;
  (e: 'onClickParent', value: boolean): void;
  (e: 'onClickToggleIcon'): void;
}>();

const onToggleChildren = () => {
  emit('onClickParent', props.isShowChildren);
  if (!props.isHaveChildren) return;
  emit('update:isShowChildren', !props.isShowChildren);
};
</script>
<template>
  <section class="w-full flex flex-col space-y-2">
    <div
      class="
        w-full
        h-10
        flex
        justify-start
        items-center
        cursor-pointer
        px-1
        space-x-1
        rounded
      "
      :class="isActive ? parentActiveClass : 'hover:bg-gray-100'"
      @click.stop="onToggleChildren"
    >
      <span
        class="w-5 h-5 flex-center"
        @click.stop="$emit('onClickToggleIcon')"
      >
        <SynIcon
          v-if="isHaveChildren"
          name="sort-down"
          custom-class="w-2 h-2 fill-current"
          :class="!isShowChildren ? '-rotate-90' : ''"
        />
      </span>
      <slot name="parent"></slot>
    </div>
    <div
      v-show="isHaveChildren && isShowChildren"
      class="w-full flex justify-start"
    >
      <span class="w-4"></span>
      <slot name="children"></slot>
    </div>
  </section>
</template>
