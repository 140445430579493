<script setup lang="ts">
import { computed, ref } from 'vue';
import taskListStore from '@/store/task/task-list-store';
import UpdateDeadline from '@/ui/modules/task/detail/UpdateDeadline.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import {
  convertUrgencyToImportant,
  convertUrgencyToPriority,
  taskStates,
} from '@/ui/hooks/taskHook';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { TaskActionType, TaskLife } from '@/domain/enums/taskEnum';
import { ExtraData } from '@/application/types/task/task.types';
import TaskCommentMultiple from '@/ui/modules/task/table/TaskCommentMultiple.vue';
import ChangeAssigneeForMultipleTask from '@/ui/modules/task/detail/update-multiple-task/ChangeAssigneeForMultipleTask.vue';
import ConfirmRemoveTask from '@/ui/modules/task/detail/action-on-task/ConfirmRemoveTask.vue';
import taskStore from '@/store/task';

const _taskStore = taskStore();
const _taskListStore = taskListStore();

const selectedTaskIds = computed(() => _taskListStore.selectedTaskIds);
const filteredTasks = computed(() => _taskListStore.filteredTasks);
const allTaskByIds = computed(() => _taskStore.allTaskByIds);

const taskStateList = computed(() => {
  if (!taskStates) return [];
  const stateList = Object.values(taskStates);
  return stateList.sort((a, b) => {
    return a.index - b.index;
  });
});

const onUnCheckAll = () => {
  _taskListStore.unCheckAll();
};
const onCheckAll = () => {
  _taskListStore.handleCheckAll();
};

const onSetFinishAll = async () => {
  settingGlobalModal({
    type: 'confirm',
    title:
      translate('TASK_UPDATE_MULTIPLE_FINISH_TITLE') ||
      'Do you want to complete the selected tasks?',
    content:
      translate('TASK_UPDATE_MULTIPLE_FINISH_CONTENT') ||
      'Mark all selected tasks as "Finished"',
    confirmable: true,
    confirmLabel:
      translate('TASK_UPDATE_MULTIPLE_FINISH_CONFIRM_LABEL') ||
      'Yes, Finish all',
    closeable: true,
    cancelLabel:
      translate('TASK_UPDATE_MULTIPLE_FINISH_CANCEL_LABEL') || 'No, thanks',
    iconName: 'finished',
    iconColor: 'green',
  });
  const isConfirm = await ask();
  if (isConfirm) {
    updateMultipleTasks(TaskActionType.Finished, {
      taskLife: TaskLife.Finished,
    });
  }
};
const onRemoveAll = async () => {
  // settingGlobalModal({
  //   type: 'confirm',
  //   title:
  //     translate('TASK_UPDATE_MULTIPLE_DELETE_TITLE') ||
  //     'Do you want to delete the selected tasks?',
  //   content:
  //     translate('TASK_UPDATE_MULTIPLE_DELETE_CONTENT') ||
  //     'Delete all selected tasks',
  //   confirmable: true,
  //   confirmLabel:
  //     translate('TASK_UPDATE_MULTIPLE_DELETE_CONFIRM_LABEL') ||
  //     'Yes, Delete all',
  //   closeable: true,
  //   cancelLabel:
  //     translate('TASK_UPDATE_MULTIPLE_DELETE_CANCEL_LABEL') || 'No, thanks',
  //   iconName: 'trash',
  //   iconColor: 'red',
  // });
  // const isConfirm = await ask();
  const selectedTasks = selectedTaskIds.value?.map((id) => {
    return allTaskByIds.value[id];
  });
  const hasParentTask = selectedTasks?.some((t) => t.totalSubTask > 0);
  const hasRepeatTask = selectedTasks?.some((t) => t.isRepeat);

  settingGlobalModal({
    type: 'confirm',
    title: '',
    isHiddenIcon: true,
    contentComponent: ConfirmRemoveTask,
    contentComponentProps: {
      isUpdateMultiple: true,
      updateMultiplePayload: {
        taskIds: selectedTaskIds.value,
        actionType: TaskActionType.Delete,
        extraData: {},
        hasParentTask,
        hasRepeatTask,
      },
    },
    iconName: 'trash',
    iconColor: 'red',
  });

  ask();
};

const onChangeUrgency = (urgency) => {
  const priority = convertUrgencyToPriority(urgency);
  const important = convertUrgencyToImportant(urgency);

  updateMultipleTasks(TaskActionType.ChangePriority, {
    priority,
    important,
  });
};

const updateMultipleTasks = (
  actionType: TaskActionType,
  extraData: ExtraData
) => {
  _taskListStore.updateMultipleTasks({
    taskIds: selectedTaskIds.value,
    actionType,
    extraData,
  });
};

const taskSchedule = ref<any>({
  doItToday: false,
});

const onCreateComment = (messageData: {
  content: string;
  attachments: {
    files: any[];
    notes: any[];
  };
  mentionUserIds: any[];
  mentionGroup: any;
}) => {
  updateMultipleTasks(TaskActionType.Comment, {
    commentInput: messageData,
  });
};

const isOpenComment = ref<boolean>(false);
const isOpenChangeAssignee = ref<boolean>(false);

const onUpdateAssignee = (data: {
  groupId?: number;
  assigneeId?: number;
  collaboratorIds?: number[];
  domainId: number;
  projectId?: number;
  subprojectId?: number;
}) => {
  updateMultipleTasks(TaskActionType.ChangeAssignee, {
    groupId: data?.groupId,
    assigneeId: data?.assigneeId,
    collaboratorIds: data?.collaboratorIds,
    domainId: data?.domainId,
    projectId: data?.projectId,
    subprojectId: data?.subprojectId,
  });
};

const changeAssigneeDropdownRef = ref<any>(null);
</script>

<template>
  <div
    class="
      w-full
      py-2
      flex
      items-center
      justify-between
      border-t border-gray-100
      pr-2
    "
  >
    <div class="w-max flex-center space-x-4">
      <div
        v-if="selectedTaskIds.length == filteredTasks.length"
        class="
          h-8
          w-8
          flex-center
          rounded-full
          hover:bg-gray-200
          cursor-pointer
          ml-1.5
        "
        @click="onUnCheckAll"
      >
        <div
          class="
            h-4
            w-4
            flex-center
            rounded
            border border-current-300
            bg-current-300
          "
        >
          <SynIcon name="check" class="text-white" />
        </div>
      </div>
      <div
        v-else
        class="
          h-8
          w-8
          flex-center
          rounded-full
          hover:bg-gray-200
          cursor-pointer
          ml-1.5
        "
        @click="onCheckAll"
      >
        <div
          class="
            h-4
            w-4
            flex-center
            rounded
            border border-current-300
            bg-current-300
          "
        >
          <SynIcon name="minus-line" custom-class="w-2.5 fill-white" />
        </div>
      </div>

      <span class="text-gray-800 font-semibold">
        {{
          $t('GED_NUM_SELECTED', {
            number: selectedTaskIds?.length,
          })
        }}
      </span>
      <div class="flex-center space-x-2">
        <!-- PLAN -->

        <VigDropdown>
          <template #dropdown-toggle>
            <div
              v-vig-tooltip="{
                content: $t('TASK_UPDATE_MULTIPLE_CHANGE_PLAN') || 'Planned',
              }"
              class="
                h-8
                w-8
                flex-center
                rounded-full
                hover:bg-gray-200
                cursor-pointer
              "
            >
              <SynIcon name="plan" custom-class="w-4 h-4 fill-gray-600" />
            </div>
          </template>
          <template #dropdown-menu>
            <div class="rounded-md p-4 flex flex-col space-y-4">
              <div class="flex flex-col space-y-2">
                <span class="font-semibold text-gray-700">
                  {{ $t('TASK_FILTER_LABEL_PLANNED') || 'Planned' }}
                </span>
                <span class="text-sm text-gray-500">
                  {{
                    $t('TASK_UPDATE_MULTIPLE_PLAN_DESCRIPTION') ||
                    'Change planned all selected tasks'
                  }}
                </span>
              </div>
              <div class="flex-center py-2">
                <div class="w-full flex items-center justify-around">
                  <AtomSwitch
                    v-model="taskSchedule.doItToday"
                    @change="updateMultipleTasks(TaskActionType.ChangeTodo, {})"
                  >
                    <span class="text-current-800 text-sm">
                      {{ $t('TASK_CREATE_FORM_DOING_TODAY') || 'Do it today' }}
                    </span>
                  </AtomSwitch>

                  <AtomSwitch
                    v-model="taskSchedule.finishItToday"
                    @change="
                      updateMultipleTasks(TaskActionType.ChangeFinish, {})
                    "
                  >
                    <span class="text-current-800 text-sm">
                      {{
                        $t('TASK_CREATE_FORM_FINISH_TODAY') || 'Finished today'
                      }}
                    </span>
                  </AtomSwitch>
                </div>
              </div>
            </div>
          </template>
        </VigDropdown>

        <!-- DEADLINE -->
        <VigDropdown>
          <template #dropdown-toggle>
            <div
              v-vig-tooltip="{
                content:
                  $t('TASK_UPDATE_MULTIPLE_CHANGE_DEADLINE') || 'Deadline',
              }"
              class="
                h-8
                w-8
                flex-center
                rounded-full
                hover:bg-gray-200
                cursor-pointer
              "
            >
              <SynIcon name="calendars" custom-class="w-5 h-5 fill-gray-600" />
            </div>
          </template>
          <template #dropdown-menu>
            <div class="rounded-md p-4 flex flex-col space-y-4">
              <div class="flex flex-col space-y-2">
                <span class="font-semibold text-gray-700">
                  {{ $t('TASK_EXPORT_COLUMNS_DEADLINE') || 'Deadline' }}
                </span>
                <span class="text-sm text-gray-500">
                  {{
                    $t('TASK_UPDATE_MULTIPLE_DEADLINE_DESCRIPTION') ||
                    'Change deadline all selected tasks'
                  }}
                </span>
              </div>
              <div class="">
                <UpdateDeadline
                  @save="
                    (data) =>
                      updateMultipleTasks(TaskActionType.ChangeDeadline, {
                        scheduleTime: data?.scheduleTime,
                      })
                  "
                />
              </div>
            </div>
          </template>
        </VigDropdown>

        <!-- URGENCY -->
        <VigDropdown>
          <template #dropdown-toggle>
            <div
              v-vig-tooltip="{
                content: $t('TASK_UPDATE_MULTIPLE_CHANGE_URGENCY') || 'Urgency',
              }"
              class="
                h-8
                w-8
                flex-center
                rounded-full
                hover:bg-gray-200
                cursor-pointer
              "
            >
              <SynIcon name="urgency" custom-class="w-4 h-4 fill-gray-600" />
            </div>
          </template>
          <template #dropdown-menu>
            <div class="rounded-md p-4 flex flex-col space-y-4">
              <div class="flex flex-col space-y-2">
                <span class="font-semibold text-gray-700">
                  {{ $t('TASK_TABLE_LABEL_URGENCY') || 'Urgency' }}
                </span>
                <span class="text-sm text-gray-500">
                  {{
                    $t('TASK_UPDATE_MULTIPLE_URGENCY_DESCRIPTION') ||
                    'Change urgency for all selected tasks'
                  }}
                </span>
              </div>
              <div
                class="
                  w-50
                  py-1
                  z-100
                  list-ul
                  rounded
                  cursor-pointer
                  dropdown-item
                "
              >
                <div
                  v-for="(item, index) in [4, 3, 2, 1]"
                  :key="index"
                  class="list-li flex text-left justify-start pr-2"
                  @click="onChangeUrgency(item)"
                >
                  <PriorityTask :urgency="item" size="mini" />
                </div>
              </div>
            </div>
          </template>
        </VigDropdown>

        <!-- STATE -->
        <VigDropdown>
          <template #dropdown-toggle>
            <div
              v-vig-tooltip="{
                content: $t('TASK_UPDATE_MULTIPLE_CHANGE_STATE') || 'Status',
              }"
              class="
                h-8
                w-8
                flex-center
                rounded-full
                hover:bg-gray-200
                cursor-pointer
              "
            >
              <SynIcon name="state" custom-class="w-4 h-4 fill-gray-600" />
            </div>
          </template>
          <template #dropdown-menu>
            <div class="rounded-md p-4 flex flex-col space-y-4">
              <div class="flex flex-col space-y-2">
                <span class="font-semibold text-gray-700">
                  {{ $t('TASK_STATE_LIST_TITLE') || 'Status' }}
                </span>
                <span class="text-sm text-gray-500">
                  {{
                    $t('TASK_UPDATE_MULTIPLE_STATE_DESCRIPTION') ||
                    'Change status for all selected tasks'
                  }}
                </span>
              </div>
              <div
                class="
                  w-full
                  py-1
                  z-100
                  list-ul
                  rounded
                  cursor-pointer
                  dropdown-item
                "
              >
                <template v-for="status in taskStateList" :key="status?.key">
                  <div
                    class="
                      w-full
                      rounded
                      p-2
                      cursor-pointer
                      dropdown-item
                      text-sm
                      font-medium
                      truncate
                      hover:bg-current-50
                    "
                    @click="
                      updateMultipleTasks(TaskActionType.ChangeStatus, {
                        taskLife: status?.key,
                      })
                    "
                  >
                    <div
                      class="
                        flex
                        items-center
                        justify-between
                        space-x-2
                        text-xs
                      "
                    >
                      <span
                        :class="status?.color"
                        :title="$t(status?.nameCode)"
                      >
                        {{ $t(status?.nameCode) }}
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </VigDropdown>

        <!-- SET FINISH -->
        <div
          v-vig-tooltip="{
            content: $t('TASK_UPDATE_MULTIPLE_SET_FINISHED') || 'Status',
          }"
          class="
            h-8
            w-8
            flex-center
            rounded-full
            hover:bg-gray-200
            cursor-pointer
          "
          @click="onSetFinishAll"
        >
          <SynIcon name="finished" custom-class="w-4 h-4 fill-gray-600" />
        </div>

        <!-- COMMENT -->
        <VigDropdown
          @on-dropdown-open="isOpenComment = true"
          @on-dropdown-close="isOpenComment = false"
        >
          <template #dropdown-toggle>
            <div
              v-vig-tooltip="{
                content: $t('TASK_ACTIVITY_LABEL_COMMENT') || 'Comment',
              }"
              class="
                h-8
                w-8
                flex-center
                rounded-full
                hover:bg-gray-200
                cursor-pointer
              "
            >
              <SynIcon name="message" custom-class="w-4 h-4 fill-gray-600" />
            </div>
          </template>
          <template #dropdown-menu>
            <div class="rounded-md p-4 flex flex-col space-y-4">
              <div class="flex flex-col space-y-2">
                <span class="font-semibold text-gray-700">
                  {{ $t('TASK_ACTIVITY_LABEL_COMMENT') || 'Comment' }}
                </span>
                <span class="text-sm text-gray-500">
                  {{
                    $t('TASK_UPDATE_MULTIPLE_COMMENT') ||
                    'Change status for all selected tasks'
                  }}
                </span>
              </div>
              <div
                class="
                  w-full
                  py-1
                  z-100
                  list-ul
                  rounded
                  cursor-pointer
                  dropdown-item
                "
              >
                <TaskCommentMultiple
                  v-if="isOpenComment"
                  ref="taskCommentBottomRef"
                  @on-create-comment="onCreateComment"
                />
              </div>
            </div>
          </template>
        </VigDropdown>
        <!-- CHANGE ASSIGNEE -->
        <VigDropdown
          ref="changeAssigneeDropdownRef"
          :hide-on-click="'toggle'"
          :is-show-dropdown="isOpenChangeAssignee"
          @on-dropdown-open="isOpenChangeAssignee = true"
          @on-dropdown-close="isOpenChangeAssignee = false"
        >
          <template #dropdown-toggle>
            <div
              v-vig-tooltip="{
                content: $t('TASK_LABEL_ASSIGNEE') || 'Responsible',
              }"
              class="
                h-8
                w-8
                flex-center
                rounded-full
                hover:bg-gray-200
                cursor-pointer
              "
            >
              <SynIcon
                name="group"
                custom-class="w-5 h-5 fill-gray-600 absolute"
              />
            </div>
          </template>
          <template #dropdown-menu>
            <div class="rounded-md w-max">
              <ChangeAssigneeForMultipleTask
                @on-save="onUpdateAssignee"
                @cancel="
                  changeAssigneeDropdownRef?.onForceClose &&
                    changeAssigneeDropdownRef?.onForceClose()
                "
              />
            </div>
          </template>
        </VigDropdown>
        <!-- SET REMOVE -->
        <div
          v-vig-tooltip="{
            content: $t('TASK_UPDATE_MULTIPLE_DELETE') || 'Status',
          }"
          class="
            h-8
            w-8
            flex-center
            rounded-full
            hover:bg-gray-200
            cursor-pointer
          "
          @click="onRemoveAll"
        >
          <SynIcon name="trash" custom-class="w-4 h-4 fill-red-500" />
        </div>

        <button
          v-vig-tooltip="{
            content: $t('COMMON_LABEL_CANCEL') || 'Close',
          }"
          class="
            w-max
            px-4
            h-8
            rounded-full
            cursor-pointer
            hover:text-current-500 hover:bg-gray-100
            flex-center
            space-x-1
          "
          @click="onUnCheckAll"
        >
          <SynIcon name="close" custom-class="w-4 h-4 do-not-close-dropdown" />
          <span class="text-sm">
            {{ $t('COMMON_LABEL_CANCEL') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
