<script setup lang="ts">
import { computed, ref } from 'vue';
import { getConfigScheduleColor } from '@/ui/hooks/taskHook';
import { ETaskRepeatType } from '@/application/interfaces/tasks/task-interfaces';
import { repeatTypeByKeys } from '@/ui/modules/task/constants/constant-task-repeats';
import taskStore from '@/store/task';
import UpdateDeadline from '@/ui/modules/task/detail/UpdateDeadline.vue';

const props = defineProps<{
  task: any;
  readonly: boolean;
}>();

const emits = defineEmits(['onDeadlineChange', 'onChangeDeadlineTime']);

const dropdownRef = ref();

const taskScheduleName = computed(() =>
  taskStore().getConfigScheduleName(props.task)
);

const onDeadlineChange = (deadline) => {
  emits('onDeadlineChange', deadline);

  dropdownRef.value?.onForceClose();
};
const changeDeadlineTime = (newValue) => {
  emits('onChangeDeadlineTime', newValue);
};
</script>

<template>
  <div class="flex-center flex-wrap gap-x-4 gap-y-2">
    <VigDropdown ref="dropdownRef" :arrow="false">
      <template #dropdown-toggle>
        <div
          class="text-sm px-1 py-0.5 rounded"
          :class="[
            getConfigScheduleColor(task?.taskSchedule),
            !readonly ? 'cursor-pointer hover:bg-current-50' : '',
          ]"
        >
          <span v-if="task?.assigneeId && taskScheduleName">
            {{ taskScheduleName }}</span
          >
          <SynLabelDateTime
            v-else-if="task?.scheduleTime"
            :datetime="task?.scheduleTime"
            format="date"
          />
          <span v-else>
            {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}</span
          >
        </div>
      </template>
      <template v-if="!readonly" #dropdown-menu>
        <div class="w-96 flex flex-col py-4 px-3 w-max">
          <span class="text-sm font-medium text-gray-700 pb-3">
            {{ $t('TASK_TABLE_LABEL_DEADLINE') }}</span
          >
          <UpdateDeadline
            :schedule-key="task?.taskSchedule?.scheduleOptionKey"
            :other-time="task?.taskSchedule?.otherTime"
            :schedule="task?.scheduleTime"
            :schedule-timezone="task.scheduleTimezone"
            :user-id="task?.assigneeId"
            :group-id="task?.groupId"
            @save="onDeadlineChange"
          />
        </div>
      </template>
    </VigDropdown>

    <UpdateDeadlineTime
      :schedule-time="task?.scheduleTime"
      @on-change="changeDeadlineTime"
    />

    <!-- RECURRING TASK -->
    <div
      v-if="
        !readonly &&
        task?.taskRepeat &&
        task?.taskRepeat?.repeatType !== ETaskRepeatType.None
      "
      class="flex-center space-x-2"
    >
      <div
        class="
          flex-center
          px-2
          rounded
          cursor-pointer
          hover:bg-current-50
          text-current-400
          fill-current-400
        "
      >
        <div class="flex-center h-8 w-8 rounded">
          <SynIcon name="recurrence" class="h-5 w-5" />
        </div>
        <span class="text-xs">
          {{
            $t(repeatTypeByKeys[task?.taskRepeat?.repeatType]?.nameCode) ||
            repeatTypeByKeys[task?.taskRepeat?.repeatType]?.name
          }}
        </span>
      </div>
      <span class="text-sm text-gray-400">|</span>
      <span class="text-gray-500 underline text-xs px-2 py-1 cursor-pointer">
        {{ $t('TASK_LABEL_REPEAT_CHANGE_TEMPLATE_TITLE') }}
      </span>
    </div>
  </div>
</template>
