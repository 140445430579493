<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { IDesktopDrawerShape } from '../schema';
const CONTAINER_SIZE = {
  w: 210,
  h: 140,
};

const props = defineProps<{
  shapeList: IDesktopDrawerShape[];
  translateX: number;
  translateY: number;
  scale: number;
  isShowCollapse?: boolean;
}>();
defineEmits<{
  (e: 'collapse'): void;
}>();

const svgSize = ref<{
  w: number;
  h: number;
  ratio: number;
}>({
  w: CONTAINER_SIZE.w,
  h: CONTAINER_SIZE.h,
  ratio: 1,
});

const addBackground = () => {
  const parent = document.getElementById('whiteboard-minimap-fad955019756');

  if (!parent) return;

  const elem = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

  elem.setAttribute('width', '100%');
  elem.setAttribute('height', '100%');
  elem.setAttribute('rx', `10`);
  elem.setAttribute('ry', `10`);
  elem.setAttribute('fill', `#1f2937`);

  parent.appendChild(elem);
};
const addShapeShadow = ({ x, y, width, height }) => {
  const parent = document.getElementById('whiteboard-minimap-fad955019756');

  if (!parent) return;

  const elem = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  elem.setAttribute('x', x);
  elem.setAttribute('y', y);
  elem.setAttribute('width', width);
  elem.setAttribute('height', height);
  elem.setAttribute('rx', `2`);
  elem.setAttribute('ry', `2`);
  elem.setAttribute('fill', `#cbd5e1`);

  parent.appendChild(elem);
};
const addViewportShadow = ({ x, y, width, height }) => {
  const parent = document.getElementById('whiteboard-minimap-fad955019756');

  if (!parent) return;

  const elem = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  elem.setAttribute('x', x);
  elem.setAttribute('y', y);
  elem.setAttribute('width', width);
  elem.setAttribute('height', height);
  elem.setAttribute('rx', `15`);
  elem.setAttribute('ry', `15`);
  elem.setAttribute('fill', `#cbd5e1`);
  elem.style.fillOpacity = '0.25';
  elem.style.zIndex = '100';
  elem.style.filter = 'drop-shadow(rgba(0, 0, 0, 1) 0px 0px 0px)';

  parent.appendChild(elem);
};
const initData = () => {
  const containerBackground = document.getElementById(
    'desktop-drawer-background'
  );
  const rect = containerBackground?.getBoundingClientRect();
  if (!rect) return;
  const containerBackgroundWidth = rect.width / props.scale;
  const containerBackgroundHeight = rect.height / props.scale;

  let minPosition = {
    x: 0,
    y: 0,
  };
  let maxPosition = {
    x: 0,
    y: 0,
  };
  const parent = document.getElementById('whiteboard-minimap-fad955019756');
  if (!parent || props.shapeList?.length == 0) return;

  parent.innerHTML = '';

  addBackground();

  props.shapeList
    ?.filter((x) => x)
    .forEach((shape, index) => {
      const _x = shape?.translateX;
      const _y = shape?.translateY;
      if (_x < minPosition.x || index == 0) minPosition.x = _x;
      if (_y < minPosition.y || index == 0) minPosition.y = _y;

      if (_x + shape.width > maxPosition.x || index == 0)
        maxPosition.x = _x + shape.width;
      if (_y + shape.height > maxPosition.y || index == 0)
        maxPosition.y = _y + shape.height;
    });

  if (
    Math.abs(maxPosition.x + props.translateX) <= containerBackgroundWidth &&
    Math.abs(maxPosition.y + props.translateY) <= containerBackgroundHeight &&
    minPosition.x + props.translateX >= 0 &&
    minPosition.y + props.translateY >= 0
  ) {
    const containerWidth = containerBackgroundWidth;
    const containerHeight = containerBackgroundHeight;
    const ratio = Math.min(
      CONTAINER_SIZE.w / containerWidth,
      CONTAINER_SIZE.h / containerHeight
    );
    svgSize.value = {
      w: containerWidth,
      h: containerHeight,
      ratio,
    };

    props.shapeList
      ?.filter((x) => x)
      .forEach((shape) => {
        addShapeShadow({
          x: `${shape.translateX + props.translateX}`,
          y: `${shape.translateY + props.translateY}`,
          width: `${shape.width}`,
          height: `${shape.height}`,
        });
      });

    addViewportShadow({
      x: `${0}`,
      y: `${0}`,
      width: `${containerBackgroundWidth}`,
      height: `${containerBackgroundHeight}`,
    });
  } else {
    const padding = {
      x: Math.min(minPosition.x + props.translateX, 0),
      y: Math.min(minPosition.y + props.translateY, 0),
    };
    const containerWidth = Math.max(
      maxPosition.x - minPosition.x,
      containerBackgroundWidth - (minPosition.x + props.translateX),
      maxPosition.x + props.translateX
    );
    const containerHeight = Math.max(
      maxPosition.y - minPosition.y,
      containerBackgroundHeight - (minPosition.y + props.translateY),
      maxPosition.y + props.translateY
    );

    const ratio = Math.min(
      CONTAINER_SIZE.w / containerWidth,
      CONTAINER_SIZE.h / containerHeight
    );
    svgSize.value = {
      w: containerWidth,
      h: containerHeight,
      ratio,
    };

    props.shapeList
      ?.filter((x) => x)
      .forEach((shape) => {
        addShapeShadow({
          x: `${shape.translateX + props.translateX - padding.x}`,
          y: `${shape.translateY + props.translateY - padding.y}`,
          width: `${shape.width}`,
          height: `${shape.height}`,
        });
      });

    addViewportShadow({
      x: `${0 - padding.x}`,
      y: `${0 - padding.y}`,
      width: `${containerBackgroundWidth}`,
      height: `${containerBackgroundHeight}`,
    });
  }
};

onMounted(() => {
  initData();
});

defineExpose({
  initData,
});
</script>

<template>
  <div
    class="relative h-full hover-to-show__parent"
    :style="{
      width: `${svgSize.w * svgSize.ratio}px`,
    }"
  >
    <div
      class="absolute bottom-0 left-0"
      :style="{
        transform: `scale(${svgSize.ratio})`,
        transformOrigin: 'bottom left',
      }"
    >
      <svg
        id="whiteboard-minimap-fad955019756"
        version="1.1"
        :width="svgSize.w"
        :height="svgSize.h"
        xmlns="http://www.w3.org/2000/svg"
      ></svg>
    </div>
    <span
      v-if="isShowCollapse"
      class="
        flex-center
        h-6
        w-6
        rounded
        cursor-pointer
        hover:bg-current-50
        bg-gray-100
        absolute
        right-0
        z-10
        hover-to-show__children
      "
      :style="{
        top: `${-svgSize.h * svgSize.ratio}px`,
      }"
      @click="$emit('collapse')"
    >
      <SynIcon name="minus" />
    </span>
  </div>
</template>
