import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import EmbedAuthServices from '@/embed/services/auth-services';
import EmbedUserServices from '@/embed/services/user-services';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { AdminType } from '@/ui/common/constants/constant';

export default defineStore({
    id: storeId.embedUser,
    state: () => ({
        _authService: EmbedAuthServices.getInstance(),
        _userService: EmbedUserServices.getInstance(),
        myInfo: {} as any,
        myTimeZone: '',
        listShortInfosUser: [],
        deactiveUsers: [],
    }),
    getters: {
        isVisitor(state): boolean {
            return state.myInfo?.adminType == AdminType.Visitor;
        },
        isAdmin(state): boolean {
            return state.myInfo?.adminType == AdminType.Admin;
        },
        isUser(state): boolean {
            return state.myInfo?.adminType == AdminType.User;
        },
        allUsers(state): any[] {
            return arrayOrderBy(
                [...state.listShortInfosUser, ...state.deactiveUsers],
                ['isMe', 'isFavorite', 'name'],
                ['desc', 'asc', 'asc']
            );
        },
    },
    actions: {
        async fetchCurrentLoginUser(): Promise<any> {
            try {
                const res: any =
                    await this._authService.getCurrentLoginInformations();

                const userInfo = res?.result;
                MyOrganizationSingleton.setUserRole(userInfo?.adminType);
                MyOrganizationSingleton.setUserId(userInfo?.id);

                MyOrganizationSingleton.setOrganizationSettingProgress(
                    userInfo?.currentSettingProgress
                );
                this.myInfo = userInfo;
                this.myTimeZone = userInfo?.utc;

                return Promise.resolve(true);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async getUsers(): Promise<any> {
            const res = await this._userService.getShortInfosUser();
            this.listShortInfosUser = res?.result?.map((o) => {
                o.avatar = o.profilePictureUrl;
                o.name = `${o.lastName} ${o.firstName}`;
                o.userId = o.id;
                o.isMe = o.id == getCurrentUserId();
                return o;
            });
        },
        async getDeactivatedUsers(): Promise<any> {
            const res = await this._userService.getDeactivatedUsers();
            this.deactiveUsers = res?.result?.map((o) => {
                o.avatar = o.profilePictureUrl;
                o.name = `${o.lastName} ${o.firstName}`;
                o.userId = o.id;
                o.isDeactive = true;
                return o;
            });
        },
        async getScopeListByUserId(userId): Promise<any> {
            try {
                const res = await this._userService.getScopeListByUserId(
                    userId
                );

                return Promise.resolve(res.result);
            } catch (error) {
                return Promise.reject(error);
            }
        },
    },
});
