import { Ref, ref, UnwrapRef } from 'vue';
import ticketPlugin from '@/ticket/services/plugin-services';

export interface IDrawNote {
    addFiles(files: any[]): void;
    addNote(note: any): void;
    removeNote(note: any): void;
}
export default function noteLogic(formData: Ref<UnwrapRef<IDrawNote>>) {
    // Note
    const currentNote = ref({} as any);

    const isCreateNote = ref(false);
    const isLoadingDrawData = ref(false);
    const isPasted = ref(false);

    const isParstedImage = (objects) => {
        return (
            objects.length == 1 &&
            objects[0].type.includes('image') &&
            isPasted.value &&
            originalFiles.value?.length == 1
        );
    };
    const onSaveNote = async (noteData) => {
        const drawData = noteData?.drawData;
        const audios = noteData?.audios;
        const objects = drawData.objects;
        // 1. If have only image save as attachments
        if (isParstedImage(objects)) {
            formData.value.addFiles(originalFiles.value);
        } else {
            formData.value.addNote(noteData);
            if (audios?.length > 0) {
                formData.value.addFiles(audios);
            }
        }

        onCancelNote();
    };
    const onCancelNote = () => {
        isCreateNote.value = false;
        isLoadingDrawData.value = true;
        isPasted.value = false;

        ticketPlugin.closeNote();
    };

    const originalFiles = ref<any>('');

    const onCreateNote = (pasted = false, defaultFiles?) => {
        originalFiles.value = defaultFiles;
        currentNote.value = {};
        isCreateNote.value = true;
        isLoadingDrawData.value = false;
        isPasted.value = pasted;

        ticketPlugin.openNote();
    };

    const onEditNote = (file) => {
        currentNote.value = file;
        isCreateNote.value = true;
    };
    const onRemoveNote = (file) => {
        formData.value.removeNote(file);
    };

    return {
        isCreateNote,
        currentNote,
        isLoadingDrawData,
        isPasted,
        onSaveNote,
        onCreateNote,
        onCancelNote,
        onRemoveNote,
        onEditNote,
    };
}
