import dayjs from 'dayjs';
import { getTaskFilterOptions } from '@/application/parsers/CalendarParser';
import {
    CalendarId,
    CalendarSourceType,
    CalendarType,
} from '@/ui/common/constants/calendar';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { DayoffStatus } from '@/domain/enums/DayoffEnums';

export class CalendarsDayoffFilter {
    fromDate: number;
    toDate: number;
    userIds: number[];
    status: DayoffStatus[];
    constructor(data: any) {
        this.fromDate = data?.fromDate
            ? data?.fromDate
            : getDefaultStartDate().getTime();
        this.toDate = data?.toDate
            ? data?.toDate
            : getDefaultEndDate().getTime();
        this.userIds = data?.userIds;
        this.status = data?.status || [
            DayoffStatus.PENDING,
            DayoffStatus.ACCEPTED,
            DayoffStatus.CANCELLED,
            DayoffStatus.CLOSED,
            DayoffStatus.MARK_UNUSED,
            DayoffStatus.REJECTED,
        ];
    }
}
export class CalendarsEventFilter {
    assigneeId: number;
    calendarId: number;
    sourceTypes: any[];
    types: any[];
    startDate: Date;
    endDate: Date;
    taskFilterOptions: {
        urgencies: any[];
        myTask: number;
        imAssignTask: number;
    };
    constructor(data: any) {
        this.assigneeId = data.assigneeId || getCurrentUserId();
        this.calendarId = data.calendarId || CalendarId.Event;
        this.sourceTypes = data.sourceTypes || [CalendarSourceType.Event];
        this.startDate = data.fromDate
            ? new Date(data.fromDate)
            : getDefaultStartDate();
        this.endDate = data.toDate
            ? new Date(data.toDate)
            : getDefaultEndDate();
        this.types = data.types || [
            CalendarType.Reminder,
            CalendarType.Meeting,
        ];
        this.taskFilterOptions =
            data.taskFilterOptions || getTaskFilterOptions();
    }
}
export class CalendarsSystemEventsFilter {
    fromDate: string;
    toDate: string;
    constructor(data: any) {
        this.fromDate = data?.fromDate
            ? dayjs(data?.fromDate).format('YYYY-MM-DD')
            : dayjs(getDefaultStartDate()).format('YYYY-MM-DD');
        this.toDate = data?.toDate
            ? dayjs(data?.toDate).format('YYYY-MM-DD')
            : dayjs(getDefaultEndDate()).format('YYYY-MM-DD');
    }
}

export const getDefaultStartDate = () => {
    const dateNew = new Date(dayjs().startOf('month').toString());
    const dayinWeek = dateNew.getDay();
    const resultDate = dayjs(dateNew).subtract(dayinWeek - 1, 'day') as any;

    return new Date(resultDate);
};

export const getDefaultEndDate = () => {
    const dateEnd = new Date(dayjs().endOf('month').toString());

    const totalDate = dayjs(getDefaultStartDate()).diff(
        dayjs().endOf('month'),
        'day'
    );

    let diffDateEnd = 0;

    if (Math.abs(totalDate) < 42) {
        diffDateEnd = 42 - Math.abs(totalDate);
    }

    const resultDate = dayjs(dateEnd).add(diffDateEnd, 'day') as any;

    return new Date(resultDate);
};
