import { onMessage, getToken, deleteToken } from 'firebase/messaging';

import { VITE_FIREBASE_PARAM } from './firebaseConfig';
import NotificationService from '@/application/services/NotificationService';
import { getBrowserInfo } from '@/ui/plugins/utils';
import { openNotification, NotificationTypeEnum } from '@/ui/hooks/commonHook';
import {
    StorageConstant,
    getLocalStorage,
    setLocalStorage,
} from '@/ui/hooks/storageHook';
import { getMessagingApp } from './init-app';

export const onListenMessage = async () => {
    const messagingApp = await getMessagingApp();
    if (!messagingApp) return;

    onMessage(messagingApp, async (payload) => {
        const data = payload?.data;
        const notificationType = data?.type?.toLowerCase();
        const subType = data?.sub_type?.toLowerCase();

        switch (notificationType) {
            case NotificationTypeEnum.chat:
                break;
            case NotificationTypeEnum.task:
                {
                    if (subType == 'taskreminder') return;
                    const taskLink = data?.task_link;
                    const taskCode = taskLink ? taskLink.split('/').pop() : '';

                    openNotification({
                        metaData: {
                            hasAction: true,
                            isOpenTaskDetail: true,
                            taskCode,
                        },
                        notificationValue: data,
                        notificationType: NotificationTypeEnum.task,
                    });
                }
                break;

            case NotificationTypeEnum.working_status:
                {
                    openNotification({
                        notificationValue: data,
                        notificationType: NotificationTypeEnum.working_status,
                        title: data?.title,
                        body: data?.body,
                    });
                }
                break;
            case NotificationTypeEnum.call:
            case NotificationTypeEnum.calendar:
            case NotificationTypeEnum.dayoff:
                {
                    showNotificationViaServiceWorker(notificationType, data);
                }
                break;
            case NotificationTypeEnum.group:
                {
                    showNotificationViaServiceWorker(notificationType, data);

                    openNotification({
                        metaData: {
                            groupId: data?.groupId,
                            groupName: data?.groupName,
                            groupAvatar: data?.groupAvatar,
                        },
                        notificationType: NotificationTypeEnum.group,
                    });
                }
                break;

            default:
                {
                    openNotification({
                        title: data?.title || '',
                        body: data?.body,
                    });
                }
                break;
        }
    });
};

export const updateDeviceToken = async (lang = null) => {
    try {
        const messagingApp = await getMessagingApp();
        if (!messagingApp) return;

        const jwtToken = getLocalStorage(StorageConstant.TOKEN);
        const userId = getLocalStorage(StorageConstant.CURRENT_USER_ID);
        if (!jwtToken || !userId) return;

        const registration = await navigator.serviceWorker.register(
            '/firebase-messaging-sw.js'
        );

        const deviceToken = await getToken(messagingApp, {
            vapidKey: VITE_FIREBASE_PARAM.vapidkey,
            serviceWorkerRegistration: registration,
        });
        if (!deviceToken) return;

        setLocalStorage(StorageConstant.DEVICE_TOKEN, deviceToken);

        updateDeviceTokenOnServer(deviceToken, lang);
    } catch (error) {
        console.error('An error occurred while retrieving token. ', error);
    }
};

export const unregisterFirebaseFcm = async () => {
    try {
        const messagingApp = await getMessagingApp();
        if (!messagingApp) return;

        const unregister = await deleteToken(messagingApp);
        if (unregister) {
            const deviceToken = getLocalStorage(StorageConstant.DEVICE_TOKEN);
            if (deviceToken)
                NotificationService.getInstance().deleteDeviceTokens(
                    deviceToken
                );
        }
    } catch (error) {
        console.error('An error occurred while retrieving token. ', error);
    }
};

export const updateDeviceTokenOnServer = (token, lang) => {
    if (!token) return Promise.resolve();

    lang = lang != null ? lang : getLocalStorage(StorageConstant.LANGUAGE);
    const browser = getBrowserInfo();
    const saveToken = {
        deviceToken: token,
        deviceName: browser.deviceName,
        deviceType: 'WEB',
        deviceLanguage: lang,
        deviceInfo: browser.deviceInfo,
        deviceGmt: (new Date().getTimezoneOffset() / -60)?.toString(),
        status: 1,
    };

    return NotificationService.getInstance().createDeviceTokens(saveToken);
};

const showNotificationViaServiceWorker = async (
    notificationType,
    notifictionData
) => {
    const registration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js'
    );
    let tagId = '';
    switch (notificationType) {
        case NotificationTypeEnum.call:
            tagId = notifictionData?.meeting_id || '';
            break;
        case NotificationTypeEnum.calendar:
            tagId = notifictionData?.event_id || '';
            break;
        case NotificationTypeEnum.dayoff:
            tagId = notifictionData?.dayoff_id || '';
            break;

        default:
            break;
    }

    registration.showNotification(notifictionData?.title || '', {
        body: notifictionData?.body,
        icon: notifictionData?.icon,
        tag: tagId,
        data: notifictionData,
        renotify: true,
        requireInteraction: false,
    });
};
