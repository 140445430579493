<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import userStore from '@/store/user';
import CustomLollipopChart from '@/ui/common/plugins/chart/CustomLollipopChart.vue';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    size: number;
  };
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    assigneeId: number;
    fullName: string;
    avatar: string;
    totalTasks: number;
    completedTasks: number;
  }[]
>([]);

const chartData = computed<
  {
    total: number;
    id: number;
    name: string;
    avatar: string;
  }[]
>(() => {
  return resultData.value?.map((o) => {
    return {
      total: o.completedTasks,
      id: o.assigneeId,
      name: o.fullName,
      avatar: o.avatar,
    };
  });
});

const initComponent = async () => {
  isLoading.value = true;
  const res = await WorkPerformanceRepository.getUserBestPerformance({
    ...props.filterData,
  });
  resultData.value = res?.result
    ?.filter((o) => o.completedTasks > 0)
    ?.map((o) => {
      return {
        ...o,
        avatar: userStore().allUserByIds[o.assigneeId].avatar,
      };
    });

  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.size == old.size
    )
      return;

    initComponent();
  }
);
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <CustomLollipopChart v-else :chart-data="chartData" class="pt-4" />
</template>
