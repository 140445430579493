<script setup lang="ts">
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import appStore from '@/store/app';
import { computed, nextTick } from 'vue';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';

const _appStore = appStore();
const _calendarsOverviewStore = calendarsOverviewStore();
const isOpenMenu = computed(() => _appStore?.isOpenMenuBar);

const onSetOpenMenu = async () => {
  _appStore.setOpenMenuBar();
  await nextTick();
  _calendarsOverviewStore.updateSizeCalendar();
};
</script>

<template>
  <div
    :title="
      isOpenMenu
        ? $t('COMMON_LABEL_CLICK_TO_COLLAPSE')
        : $t('COMMON_LABEL_CLICK_TO_EXPAND')
    "
    class="
      flex-center
      z-50
      bg-white
      rounded
      cursor-pointer
      border
      fill-gray-500
      text-gray-500
      hover:fill-current hover:text-current-500 hover:border-current
      px-1
      py-2
    "
    @click="onSetOpenMenu"
  >
    <SynIcon
      :name="isOpenMenu ? 'chevron-left' : 'chevron-right'"
      custom-class="w-2.5 h-2.5"
    />
  </div>
</template>
