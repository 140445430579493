<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M16 1a15 15 0 1 0 15 15A15 15 0 0 0 16 1zm0 2a12.91 12.91 0 0 1 8.47 3.16L6.09 24.39A13 13 0 0 1 16 3zm0 26a12.93 12.93 0 0 1-8.5-3.19L25.88 7.57A13 13 0 0 1 16 29z"
        data-name="Layer 2"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
