<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ignoreUnicode } from '@/ui/plugins/utils';
import WhiteboardCreateModal from '@/ui/modules/whiteboard/create/WhiteboardCreateModal.vue';
import whiteboardStore from '@/store/whiteboard';
import WhiteboardEditTitleModal from '@/ui/modules/whiteboard/create/WhiteboardEditTitleModal.vue';
import { IUserWhiteboard } from '@/application/types/whiteboard';

const router = useRouter();
const route = useRoute();

const props = defineProps<{
  isNotWhiteboardPage?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSelect', whiteboard): void;
}>();

const _whiteboardStore = whiteboardStore();

const allWhiteboards = computed<IUserWhiteboard[]>(() => {
  return _whiteboardStore.allWhiteboards;
});
const isFullPage = computed<boolean>(() => {
  console.log('🚀 Tictop ~ route.name:', route.name);
  return route.name?.toString()?.startsWith('Fp');
});
onMounted(() => {
  const currentWhiteboardId = whiteboardStore().currentWhiteboardId;

  if (
    !props.isNotWhiteboardPage &&
    currentWhiteboardId &&
    route.query?.redirect !== '2'
  ) {
    router.push({
      name: isFullPage.value ? 'FpWhiteboardDetail' : 'WhiteboardDetail',

      params: {
        id: currentWhiteboardId,
      },
    });
    return;
  }
});

const isLoading = ref<boolean>(false);
const initData = async () => {
  isLoading.value = true;
  await _whiteboardStore.getAllWhiteboardByUser();

  isLoading.value = false;
};

const searchText = ref<string>('');
const filterWhiteboards = computed<any>(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  if (searchTxt)
    return allWhiteboards.value?.filter((whiteboard) =>
      ignoreUnicode(whiteboard?.title).includes(searchTxt)
    );

  return allWhiteboards.value;
});

const resetKeyword = () => {
  searchText.value = '';
};

const isOpenCreateWhiteboardModal = ref<boolean>(false);

const onCreateSuccess = (newWhiteboard) => {
  if (!newWhiteboard?.id) return;
  isOpenCreateWhiteboardModal.value = false;
  console.log('🚀 Tictop ~ isFullPage.value:', isFullPage.value);

  if (!props.isNotWhiteboardPage)
    router.push({
      name: isFullPage.value ? 'FpWhiteboardDetail' : 'WhiteboardDetail',
      params: { id: newWhiteboard?.id },
    });
};

const isEditingTitle = ref<boolean>(false);
const currentUpdateWhiteboard = ref<
  | {
      id: string;
      title: string;
    }
  | undefined
>();
const onOpenEditTitle = (id, title) => {
  currentUpdateWhiteboard.value = {
    id,
    title,
  };
  isEditingTitle.value = true;
};
const onRemoveWhiteboard = (whiteboardId, whiteboardTitle) => {
  _whiteboardStore.deleteWhiteboard(whiteboardId, whiteboardTitle);
};

const onUpdateWhiteboardTitleSuccess = () => {
  currentUpdateWhiteboard.value = undefined;
  isEditingTitle.value = false;
};

const onSelectWhiteboard = (whiteboard) => {
  router.push({
    name: isFullPage.value ? 'FpWhiteboardDetail' : 'WhiteboardDetail',
    params: { id: whiteboard?.id },
  });
  emit('onSelect', whiteboard);
};

initData();
</script>
<template>
  <SynLoading v-if="isLoading" />
  <div
    v-else
    v-system-config="{
      functionCode: 'DRAFT_WHITEBOA_WHITEBOARD_FUNCTIONS_ALLOWED',
      functionValue: '1',
    }"
    class="flex flex-col h-full w-full"
  >
    <div
      class="
        flex
        items-center
        justify-between
        bg-white
        px-4
        py-2
        text-gray-800 text-sm
        shadow
      "
    >
      <div>
        <span v-if="isNotWhiteboardPage" class="text-lg font-semibold">{{
          $t('WHITEBOARD_LABEL_WHITEBOARD_LIST')
        }}</span>
      </div>
      <div class="flex items-center gap-2">
        <SynSearch
          ref="searchInputGroup"
          v-model="searchText"
          icon-prefix="search"
          :placeholder="$t('WHITEBOARD_LABEL_NAME')"
          :autofocus="true"
          width-max="16rem"
        >
          <template v-if="searchText?.trim()?.length > 0" #suffix>
            <SynIcon
              has-action
              name="close2"
              custom-class="rounded-full w-3 h-3"
              @click="resetKeyword()"
            />
          </template>
        </SynSearch>

        <SynButton
          name-icon="desktop"
          custom-class="rounded py-2 text-xs"
          color="current"
          :label="$t('WHITEBOARD_CREATE_NEW')"
          @click.stop="isOpenCreateWhiteboardModal = true"
        />
        <div class="cursor-pointer flex-center" @click.stop="initData">
          <SynIcon name="reload" />
        </div>
      </div>
    </div>
    <div class="flex-1 w-full h-full min-h-0">
      <section class="w-full h-full relative flex flex-col">
        <div class="relative flex-1 min-h-0 pt-2">
          <!-- SEARCH NOT FOUND     -->
          <div
            v-if="!filterWhiteboards || filterWhiteboards?.length < 1"
            class="py-8"
          >
            <div v-if="searchText" class="py-16 h-full w-full text-center">
              <syn-animation
                name="searchIcon"
                :loop="false"
                stype="width: 200px; height: 200px;"
              />
              <span class="font-medium text-lg text-gray-600">{{
                $t('COMMON_LABEL_ALERT_NO_SEARCH')
              }}</span>
            </div>
            <div v-else class="pt-12 h-full w-full flex-center flex-col gap-4">
              <VigImage
                :path="'https://d1tvqetrcurhzb.cloudfront.net/common/images/all-task-grouplist.png'"
                :custom-class="'h-42 w-96'"
              />
              <div class="w-full flex-center flex-col gap-2">
                <div class="flex items-center justify-between gap-2">
                  <div class="flex-1 min-w-0 truncate text-xl text-gray-800">
                    {{ $t('WHITEBOARD_LABEL_SUGGEST_CREATE_NEW') }}
                  </div>
                </div>
                <SynButton
                  name-icon="desktop"
                  custom-class="rounded py-2 text-xs"
                  color="current"
                  :label="$t('WHITEBOARD_CREATE_NEW')"
                  @click.stop="isOpenCreateWhiteboardModal = true"
                />
              </div>
            </div>
          </div>

          <!--   HAVE MEMBER   -->
          <div
            v-else
            class="
              pt-6
              px-6
              w-full
              h-full
              grid
              auto-rows-max
              overflow-auto
              small-scrollbar
            "
            :style="'grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr))'"
          >
            <div
              v-for="(whiteboard, index) in filterWhiteboards"
              :key="whiteboard?.id"
              class="w-full px-4 pb-8 relative hover-to-show__parent"
            >
              <span
                class="
                  w-full
                  h-full
                  rounded-lg
                  hover:bg-current-50
                  flex
                  items-center
                  justify-between
                  gap-2
                  p-4
                  border
                  bg-gray-50
                  shadow-md
                "
                @click="onSelectWhiteboard(whiteboard)"
              >
                <div class="flex-1 min-w-0 flex items-center gap-2">
                  <SynIcon name="desktop" />
                  <div class="flex-1 min-w-0 truncate">
                    {{ whiteboard?.title || `Whiteboard ${index + 1}` }}
                  </div>
                </div>
                <VigDropdown
                  placement="bottom-end"
                  @click.stop.prevent="() => {}"
                >
                  <template #dropdown-toggle>
                    <div
                      class="
                        w-7
                        h-7
                        flex-center
                        rounded-full
                        hover:bg-current-100
                        hover:fill-current
                        hover:text-current-500
                      "
                    >
                      <SynIcon name="dots-vertical" />
                    </div>
                  </template>
                  <template #dropdown-menu>
                    <SynDropdownMenu class="right-0 w-44">
                      <SynDropdownItem
                        is-close
                        @click="
                          onOpenEditTitle(whiteboard?.id, whiteboard?.title)
                        "
                      >
                        <div class="flex items-center">
                          <div class="w-4 h-4 flex-center">
                            <SynIcon
                              name="edit"
                              is-active
                              custom-class="w-4 h-4 fill-gray-500 text-gray-600"
                            />
                          </div>
                          <span class="pl-2 text-gray-600 truncate"
                            >{{ $t('GED_FILE_RENAME') }}
                          </span>
                        </div>
                      </SynDropdownItem>
                      <SynDropdownItem
                        is-close
                        @click="
                          onRemoveWhiteboard(whiteboard?.id, whiteboard?.title)
                        "
                      >
                        <div class="flex items-center">
                          <div class="w-4 h-4 flex-center">
                            <SynIcon
                              name="trash"
                              is-active
                              custom-class="w-3.5 h-3.5 fill-red-500"
                            />
                          </div>

                          <span class="pl-2 text-red-500 truncate"
                            >{{ $t('TASK_DETAIL_REMOVE') }}
                          </span>
                        </div>
                      </SynDropdownItem>
                    </SynDropdownMenu>
                  </template>
                </VigDropdown>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <WhiteboardCreateModal
    v-if="isOpenCreateWhiteboardModal"
    @cancel="isOpenCreateWhiteboardModal = false"
    @create-success="onCreateSuccess"
  />

  <WhiteboardEditTitleModal
    v-if="isEditingTitle && currentUpdateWhiteboard"
    :whiteboard-id="currentUpdateWhiteboard?.id"
    :whiteboard-title="currentUpdateWhiteboard?.title"
    @cancel="isEditingTitle = false"
    @update-title-success="onUpdateWhiteboardTitleSuccess"
  />
</template>
