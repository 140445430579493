<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import settingStore from '@/store/setting';
import userStore from '@/store/user';
import myProfileStore from '@/store/auth/my-profile';
import {
  ITaskDetailClass,
  TaskDetailClass,
} from '@/domain/entities/task/TaskPresentClass';
import changeAssignComposable from '@/ui/modules/task/composables/change-assign-composable';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';

const props = defineProps<{
  task: ITaskDetailClass;
}>();

const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onSave', approverIds: number[]): void;
  (e: 'update:approver', approver: any): void;
}>();
const _settingStore = settingStore();

const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const myDepartmentIds = computed(() => {
  const myId = myProfileStore().myProfile?.id;
  return myId ? userStore().allUserByIds[myId]?.departmentIds : [];
});

const formData = ref<any>({
  groupId: props.task?.groupId,
  userId: props.task?.approverId,
});

const {
  isSearchAll,
  assignedGroup,
  assignedUser,
  assignableUsers,
  handleChangeUser,
  handleRemoveUser,
  initData: initUpdateAssignee,
  toggleSearchAll,
} = changeAssignComposable(formData.value, {
  isFilterVisitor: true,
});

const filteredAssignableUsers = computed<any>(() => {
  if (props.task?.isPrivate) {
    let userIds = [props.task?.creatorId];
    if (
      props.task?.assigneeId &&
      props.task?.assigneeId !== props.task?.creatorId
    )
      userIds.push(props.task?.assigneeId);

    return userIds?.map((id) => userStore().allUserByIds[id]);
  }
  return assignableUsers.value;
});

const initData = (defaultData?) => {
  let data = defaultData
    ? { groupId: defaultData?.groupId, userId: defaultData?.assigneeId }
    : {
        groupId: props.task?.groupId,
        userId: props.task?.approverId,
      };
  if (props.task?.approvalEnabled) {
    data.userId =
      props.task?.approvers && props.task?.approvers?.length > 0
        ? props.task?.approvers[0]?.userId
        : 0;
  }

  formData.value = {
    ...data,
  };

  initUpdateAssignee(formData.value);
};
const isShowDepartment = computed(() => {
  return !assignedGroup.value?.id && departmentManagementEnabled.value;
});

const onRemoveUser = () => {
  handleRemoveUser();

  emit('update:approver', {});
};
const onChangeUser = async (user) => {
  const isValid = await new TaskDetailClass(props.task).checkValidNewApprover(
    user
  );
  if (!isValid) return;

  handleChangeUser(user);

  emit('update:approver', assignedUser.value);
};

onMounted(() => {
  if (departmentManagementEnabled.value && myDepartmentIds.value?.length == 0) {
    toggleSearchAll(true);
  }
});
initData();

defineExpose({
  initData,
});
</script>

<template>
  <div class="py-2">
    <SynFormInput>
      <div class="flex items-center gap-2">
        <TaskDetailField>
          <template v-if="assignedUser?.id" #fieldName>
            <span>{{ $t('COMMON_LABEL_APPROVER') }}</span>
          </template>
          <template #body>
            <div
              class="
                w-full
                h-full
                flex
                items-center
                justify-between
                rounded
                relative
                px-0.5
              "
              style="max-width: 24rem; height: 2.5rem"
            >
              <SynDepartmentAssign
                v-if="isShowDepartment"
                input-class="text-xs"
                removeable
                :is-show-department="isShowDepartment && isSearchAll"
                :placeholder="$t('COMMON_LABEL_APPROVER')"
                :current-id="assignedUser?.id"
                :data="filteredAssignableUsers"
                :name="assignedUser?.name"
                :avatar="assignedUser?.avatar?.replace('original', 'mini')"
                @chosen="onChangeUser"
                @reset="onRemoveUser"
              >
                <template #header>
                  <div class="p-2 flex items-center">
                    <SynRadioTag
                      v-if="myDepartmentIds?.length > 0"
                      v-model="isSearchAll"
                      :value="false"
                      name="task-create-form_schedule"
                      radio-name="task-create-form_priority"
                      class="flex-1 w-2/3 mr-1 mb-0.5 mt-0.5 relative"
                      @change="toggleSearchAll"
                    >
                      <SynTag size="circle-small" custom-class="py-1 px-2">
                        <DepartmentById
                          :department-id="myDepartmentIds[0]"
                          is-hidden-avatar
                        />(D)
                      </SynTag>
                    </SynRadioTag>
                    <SynRadioTag
                      v-model="isSearchAll"
                      :value="true"
                      :label="'Department'"
                      name="task-create-form_schedule"
                      radio-name="task-create-form_priority"
                      class="mr-1 mb-0.5 mt-0.5 relative"
                      @change="toggleSearchAll"
                    >
                      <SynTag size="circle-small" custom-class="py-1 px-2">
                        <span>
                          {{ $t('COMMON_LABEL_OTHER') }}
                        </span>
                      </SynTag>
                    </SynRadioTag>
                  </div>
                </template>
              </SynDepartmentAssign>
              <SynSelectAssign
                v-else
                input-class="text-xs"
                removeable
                :is-show-department="isShowDepartment"
                :placeholder="$t('COMMON_LABEL_APPROVER')"
                :current-id="assignedUser?.id"
                :data="filteredAssignableUsers"
                :name="assignedUser?.name"
                :avatar="assignedUser?.avatar?.replace('original', 'mini')"
                @chosen="onChangeUser"
                @reset="onRemoveUser"
              />
            </div>
          </template>
        </TaskDetailField>

        <AtomHelpDropdown title="TASK_LABEL_WHO_CAN_APPROVE_TASK">
          <template #content>
            <ul class="pl-4 space-y-4" style="list-style: circle">
              <li
                v-html="
                  $t('TASK_LABEL_WHO_CAN_APPROVE_TASK_CONTENT_1') ||
                  'Note: If you select a user who is a member of another Department, that user will become a member of the new Department and no longer be a memberof the old Department'
                "
              ></li>
              <li
                v-html="
                  $t('TASK_LABEL_WHO_CAN_APPROVE_TASK_CONTENT_2') ||
                  'Note: If you select a user who is a member of another Department, that user will become a member of the new Department and no longer be a memberof the old Department'
                "
              ></li>
            </ul>
          </template>
        </AtomHelpDropdown>
      </div>
    </SynFormInput>
  </div>
</template>
