export const EVENT_BUS_KEYS = {
    DIAGRAM_DESTROY: 'diagram.destroy',
    RENDER_SHAPE: 'render.shape',
    RENDER_CONNECTION: 'render.connection',
    RENDER_GET_SHAPE_PATH: 'render.getShapePath',
    RENDER_GET_CONNECTION_PATH: 'render.getConnectionPath',
    DIAGRAM_INIT: 'diagram.init',
    SHAPE_ADDED: 'shape.added',
    CONNECTION_ADDED: 'connection.added',
    SHAPE_REMOVED: 'shape.removed',
    CONNECTION_REMOVED: 'connection.removed',
    ELEMENTS_CHANGED: 'elements.changed',
    ROOT_SET: 'root.set',
    DIAGRAM_CLEAR: 'diagram.clear',
    CANVAS_VIEWBOX_CHANGING: 'canvas.viewbox.changing',
    CANVAS_VIEWBOX_CHANGED: 'canvas.viewbox.changed',
    SHAPE_REMOVE: 'shape.remove',
    CONNECTION_REMOVE: 'connection.remove',
    ELEMENT_CHANGED: 'element.changed',
    ELEMENT_MARKER_UPDATE: 'element.marker.update',
    SHAPE_CHANGED: 'shape.changed',
    CONNECTION_CHANGED: 'connection.changed',
    COMMAND_STACK_EXECUTED: 'commandStack.executed',
    COMMAND_STACK_REVERT: 'commandStack.revert',
    COMMAND_STACK_SHAPE_TOGGLE_COLLAPSE_EXECUTED:
        'commandStack.shape.toggleCollapse.executed',
    COMMAND_STACK_SHAPE_TOGGLE_COLLAPSE_REVERTED:
        'commandStack.shape.toggleCollapse.reverted',
    COMMAND_STACK_SHAPE_CREATE_EXECUTED: 'commandStack.shape.create.executed',
    COMMAND_STACK_SHAPE_MOVE_EXECUTED: 'commandStack.shape.move.executed',
    COMMAND_STACK_SHAPE_DELETE_EXECUTED: 'commandStack.shape.delete.executed',
    COMMAND_STACK_SHAPE_CREATE_REVERTED: 'commandStack.shape.create.reverted',
    COMMAND_STACK_SHAPE_MOVE_REVERTED: 'commandStack.shape.move.reverted',
    COMMAND_STACK_SHAPE_DELETE_REVERTED: 'commandStack.shape.delete.reverted',
    IMPORT_RENDER_COMPLETE: 'import.render.complete',
    IMPORT_RENDER_START: 'import.render.start',
    OUTLINE_GET_PROVIDERS: 'outline.getProviders',
    CANVAS_DESTROY: 'canvas.destroy',
    CANVAS_INIT: 'canvas.init',
    INTERACTION_EVENTS_CREATE_HIT: 'interactionEvents.createHit',
    INTERACTION_EVENTS_UPDATE_HIT: 'interactionEvents.updateHit',
    ELEMENT_HOVER: 'element.hover',
    ELEMENT_OUT: 'element.out',
    SELECTION_CHANGED: 'selection.changed',
    CREATE_END: 'create.end',
    CONNECT_END: 'connect.end',
    SHAPE_MOVE_END: 'shape.move.end',
    ELEMENT_CLICK: 'element.click',
    ATTACH: 'attach',
    DETACH: 'detach',
    EDITOR_ACTIONS_INIT: 'editorActions.init',
    KEYBOARD_KEYDOWN: 'keyboard.keydown',
    ELEMENT_MOUSEDOWN: 'element.mousedown',
    COMMAND_STACK_CONNECTION_START_CAN_EXECUTE:
        'commandStack.connection.start.canExecute',
    COMMAND_STACK_CONNECTION_CREATE_CAN_EXECUTE:
        'commandStack.connection.create.canExecute',
    COMMAND_STACK_CONNECTION_RECONNECT_CAN_EXECUTE:
        'commandStack.connection.reconnect.canExecute',
    COMMAND_STACK_CONNECTION_UPDATE_WAYPOINTS_CAN_EXECUTE:
        'commandStack.connection.updateWaypoints.canExecute',
    COMMAND_STACK_SHAPE_RESIZE_CAN_EXECUTE:
        'commandStack.shape.resize.canExecute',
    COMMAND_STACK_ELEMENTS_CREATE_CAN_EXECUTE:
        'commandStack.elements.create.canExecute',
    COMMAND_STACK_ELEMENTS_MOVE_CAN_EXECUTE:
        'commandStack.elements.move.canExecute',
    COMMAND_STACK_SHAPE_CREATE_CAN_EXECUTE:
        'commandStack.shape.create.canExecute',
    COMMAND_STACK_SHAPE_ATTACH_CAN_EXECUTE:
        'commandStack.shape.attach.canExecute',
    COMMAND_STACK_ELEMENT_COPY_CAN_EXECUTE:
        'commandStack.element.copy.canExecute',
    CONTEXT_PAD_GET_PROVIDERS: 'contextPad.getProviders',
    POPUP_MENU_GET_PROVIDERS_ALIGN_ELEMENTS:
        'popupMenu.getProviders.align-elements',
    COMMAND_STACK_ELEMENTS_ALIGN_CAN_EXECUTE:
        'commandStack.elements.align.canExecute',
    AUTO_PLACE_END: 'autoPlace.end',
    AUTO_PLACE: 'autoPlace',
    DRAG_START: 'drag.start',
    DRAG_MOVE: 'drag.move',
    DRAG_CLEANUP: 'drag.cleanup',
    COMMAND_STACK_SHAPE_CREATE_POST_EXECUTED:
        'commandStack.shape.create.postExecuted',
    COMMAND_STACK_ELEMENTS_MOVE_POST_EXECUTED:
        'commandStack.elements.move.postExecuted',
    COMMAND_STACK_SHAPE_TOGGLE_COLLAPSE_POST_EXECUTED:
        'commandStack.shape.toggleCollapse.postExecuted',
    COMMAND_STACK_SHAPE_RESIZE_POST_EXECUTED:
        'commandStack.shape.resize.postExecuted',
    COMMAND_STACK_ELEMENT_AUTO_RESIZE_CAN_EXECUTE:
        'commandStack.element.autoResize.canExecute',
    BENDPOINT_MOVE_HOVER: 'bendpoint.move.hover',
    BENDPOINT_MOVE_OUT: 'bendpoint.move.out',
    BENDPOINT_MOVE_CLEANUP: 'bendpoint.move.cleanup',
    BENDPOINT_MOVE_END: 'bendpoint.move.end',
    CONNECTION_SEGMENT_MOVE_START: 'connectionSegment.move.start',
    CONNECTION_SEGMENT_MOVE_MOVE: 'connectionSegment.move.move',
    CONNECTION_SEGMENT_MOVE_HOVER: 'connectionSegment.move.hover',
    CONNECTION_SEGMENT_MOVE_OUT: 'connectionSegment.move.out',
    CONNECTION_SEGMENT_MOVE_CLEANUP: 'connectionSegment.move.cleanup',
    CONNECTION_SEGMENT_MOVE_CANCEL: 'connectionSegment.move.cancel',
    CONNECTION_SEGMENT_MOVE_END: 'connectionSegment.move.end',
    ELEMENT_MOUSEMOVE: 'element.mousemove',
    ELEMENT_UPDATE_ID: 'element.updateId',
    CONNECT_HOVER: 'connect.hover',
    CONNECT_MOVE: 'connect.move',
    BENDPOINT_MOVE_MOVE: 'bendpoint.move.move',
    BENDPOINT_MOVE_START: 'bendpoint.move.start',
    BENDPOINT_MOVE_CANCEL: 'bendpoint.move.cancel',
    CONNECT_OUT: 'connect.out',
    CONNECT_CLEANUP: 'connect.cleanup',
    COMMAND_STACK_CONNECTION_CREATE_POST_EXECUTED:
        'commandStack.connection.create.postExecuted',
    COMMAND_STACK_CONNECTION_LAYOUT_POST_EXECUTED:
        'commandStack.connection.layout.postExecuted',
    COMMAND_STACK_CONNECTION_UPDATE_WAYPOINTS_POST_EXECUTED:
        'commandStack.connection.updateWaypoints.postExecuted',
    COMMAND_STACK_LABEL_CREATE_POST_EXECUTED:
        'commandStack.label.create.postExecuted',
    COMMAND_STACK_ELEMENTS_CREATE_POST_EXECUTED:
        'commandStack.elements.create.postExecuted',
    COMMAND_STACK_SHAPE_APPEND_PRE_EXECUTE:
        'commandStack.shape.append.preExecute',
    COMMAND_STACK_SHAPE_MOVE_POST_EXECUTE:
        'commandStack.shape.move.postExecute',
    MODDLE_COPY_CAN_COPY_PROPERTIES: 'moddleCopy.canCopyProperties',
    MODDLE_COPY_CAN_COPY_PROPERTY: 'moddleCopy.canCopyProperty',
    MODDLE_COPY_CAN_SET_COPIED_PROPERTY: 'moddleCopy.canSetCopiedProperty',
    COMMAND_STACK_ELEMENTS_MOVE_PRE_EXECUTE:
        'commandStack.elements.move.preExecute',
    COMMAND_STACK_CONNECTION_CREATE_POST_EXECUTE:
        'commandStack.connection.create.postExecute',
    COMMAND_STACK_CONNECTION_RECONNECT_POST_EXECUTE:
        'commandStack.connection.reconnect.postExecute',
    COMMAND_STACK_SHAPE_REPLACE_PRE_EXECUTE:
        'commandStack.shape.replace.preExecute',
    COMMAND_STACK_SHAPE_REPLACE_POST_EXECUTED:
        'commandStack.shape.replace.postExecuted',
    COMMAND_STACK_CONNECTION_CREATE_PRE_EXECUTE:
        'commandStack.connection.create.preExecute',
    COMMAND_STACK_CONNECTION_DELETE_POST_EXECUTED:
        'commandStack.connection.delete.postExecuted',
    COMMAND_STACK_CONNECTION_RECONNECT_POST_EXECUTED:
        'commandStack.connection.reconnect.postExecuted',
    COMMAND_STACK_ELEMENT_UPDATE_PROPERTIES_POST_EXECUTED:
        'commandStack.element.updateProperties.postExecuted',
    COMMAND_STACK_SHAPE_CREATE_PRE_EXECUTE:
        'commandStack.shape.create.preExecute',
    CREATE_START: 'create.start',
    SHAPE_MOVE_START: 'shape.move.start',
    COMMAND_STACK_ELEMENTS_CREATE_PRE_EXECUTE:
        'commandStack.elements.create.preExecute',
    COMMAND_STACK_SHAPE_CREATE_EXECUTE: 'commandStack.shape.create.execute',
    COMMAND_STACK_SHAPE_CREATE_REVERT: 'commandStack.shape.create.revert',
    COMMAND_STACK_SHAPE_CREATE_POST_EXECUTE:
        'commandStack.shape.create.postExecute',
    COMMAND_STACK_CONNECTION_CREATE_EXECUTED:
        'commandStack.connection.create.executed',
    COMMAND_STACK_CONNECTION_DELETE_EXECUTED:
        'commandStack.connection.delete.executed',
    COMMAND_STACK_CONNECTION_MOVE_EXECUTED:
        'commandStack.connection.move.executed',
    COMMAND_STACK_CONNECTION_RECONNECT_EXECUTED:
        'commandStack.connection.reconnect.executed',
    COMMAND_STACK_CONNECTION_CREATE_REVERTED:
        'commandStack.connection.create.reverted',
    COMMAND_STACK_CONNECTION_DELETE_REVERTED:
        'commandStack.connection.delete.reverted',
    COMMAND_STACK_CONNECTION_MOVE_REVERTED:
        'commandStack.connection.move.reverted',
    COMMAND_STACK_CONNECTION_RECONNECT_REVERTED:
        'commandStack.connection.reconnect.reverted',
    COMMAND_STACK_CONNECTION_LAYOUT_EXECUTED:
        'commandStack.connection.layout.executed',
    COMMAND_STACK_CONNECTION_LAYOUT_REVERTED:
        'commandStack.connection.layout.reverted',
    COMMAND_STACK_CANVAS_UPDATE_ROOT_EXECUTED:
        'commandStack.canvas.updateRoot.executed',
    COMMAND_STACK_CANVAS_UPDATE_ROOT_REVERTED:
        'commandStack.canvas.updateRoot.reverted',
    COMMAND_STACK_SHAPE_RESIZE_EXECUTED: 'commandStack.shape.resize.executed',
    COMMAND_STACK_SHAPE_RESIZE_REVERTED: 'commandStack.shape.resize.reverted',
    COMMAND_STACK_CONNECTION_UPDATE_WAYPOINTS_EXECUTED:
        'commandStack.connection.updateWaypoints.executed',
    COMMAND_STACK_CONNECTION_UPDATE_WAYPOINTS_REVERTED:
        'commandStack.connection.updateWaypoints.reverted',
    COMMAND_STACK_ELEMENT_UPDATE_ATTACHMENT_EXECUTED:
        'commandStack.element.updateAttachment.executed',
    COMMAND_STACK_ELEMENT_UPDATE_ATTACHMENT_REVERTED:
        'commandStack.element.updateAttachment.reverted',
    COMMAND_STACK_ELEMENT_UPDATE_LABEL_EXECUTED:
        'commandStack.element.updateLabel.executed',
    COMMAND_STACK_ELEMENT_UPDATE_LABEL_REVERTED:
        'commandStack.element.updateLabel.reverted',
    COMMAND_STACK_SHAPE_DELETE_POST_EXECUTE:
        'commandStack.shape.delete.postExecute',
    COMMAND_STACK_CANVAS_UPDATE_ROOT_POST_EXECUTE:
        'commandStack.canvas.updateRoot.postExecute',
    SPACE_TOOL_SELECTION_INIT: 'spaceTool.selection.init',
    SPACE_TOOL_SELECTION_ENDED: 'spaceTool.selection.ended',
    SPACE_TOOL_SELECTION_CANCELED: 'spaceTool.selection.canceled',
    SPACE_TOOL_ENDED: 'spaceTool.ended',
    SPACE_TOOL_CANCELED: 'spaceTool.canceled',
    SPACE_TOOL_SELECTION_END: 'spaceTool.selection.end',
    SPACE_TOOL_MOVE: 'spaceTool.move',
    SPACE_TOOL_END: 'spaceTool.end',
    COMMAND_STACK_SHAPE_DELETE_POST_EXECUTED:
        'commandStack.shape.delete.postExecuted',
    COMMAND_STACK_CONNECTION_CREATE_PRE_EXECUTED:
        'commandStack.connection.create.preExecuted',
    COMMAND_STACK_SHAPE_REPLACE_PRE_EXECUTED:
        'commandStack.shape.replace.preExecuted',
    CREATE_HOVER: 'create.hover',
    CREATE_MOVE: 'create.move',
    CREATE_OUT: 'create.out',
    SHAPE_MOVE_HOVER: 'shape.move.hover',
    SHAPE_MOVE_MOVE: 'shape.move.move',
    SHAPE_MOVE_OUT: 'shape.move.out',
    GLOBAL_CONNECT_HOVER: 'global-connect.hover',
    GLOBAL_CONNECT_OUT: 'global-connect.out',
    GLOBAL_CONNECT_END: 'global-connect.end',
    GLOBAL_CONNECT_CLEANUP: 'global-connect.cleanup',
    CONNECT_START: 'connect.start',
    COMMAND_STACK_LABEL_CREATE_EXECUTE: 'commandStack.label.create.execute',
    COMMAND_STACK_LABEL_CREATE_REVERT: 'commandStack.label.create.revert',
    COMMAND_STACK_SHAPE_DELETE_EXECUTE: 'commandStack.shape.delete.execute',
    COPY_PASTE_COPY_ELEMENT: 'copyPaste.copyElement',
    COPY_PASTE_PASTE_ELEMENT: 'copyPaste.pasteElement',
    BPMN_ELEMENT_ADDED: 'bpmnElement.added',
    COMMAND_STACK_ELEMENT_UPDATE_PROPERTIES_POST_EXECUTE:
        'commandStack.element.updateProperties.postExecute',
    COMMAND_STACK_ELEMENT_UPDATE_MODDLE_PROPERTIES_POST_EXECUTE:
        'commandStack.element.updateModdleProperties.postExecute',
    COMMAND_STACK_CONNECTION_LAYOUT_POST_EXECUTE:
        'commandStack.connection.layout.postExecute',
    COMMAND_STACK_CONNECTION_UPDATE_WAYPOINTS_POST_EXECUTE:
        'commandStack.connection.updateWaypoints.postExecute',
    COMMAND_STACK_SHAPE_REPLACE_POST_EXECUTE:
        'commandStack.shape.replace.postExecute',
    COMMAND_STACK_SHAPE_RESIZE_POST_EXECUTE:
        'commandStack.shape.resize.postExecute',
    COMMAND_STACK_CONNECTION_MOVE_POST_EXECUTE:
        'commandStack.connection.move.postExecute',
    COMMAND_STACK_SHAPE_DELETE_PRE_EXECUTE:
        'commandStack.shape.delete.preExecute',
    COMMAND_STACK_SHAPE_RESIZE_PRE_EXECUTE:
        'commandStack.shape.resize.preExecute',
    COMMAND_STACK_CONNECTION_RECONNECT_PRE_EXECUTE:
        'commandStack.connection.reconnect.preExecute',
    RESIZE_START: 'resize.start',
    RESIZE_END: 'resize.end',
    COMMAND_STACK_ELEMENT_UPDATE_PROPERTIES_EXECUTED:
        'commandStack.element.updateProperties.executed',
    COMMAND_STACK_ELEMENT_UPDATE_MODDLE_PROPERTIES_EXECUTED:
        'commandStack.element.updateModdleProperties.executed',
    COMMAND_STACK_ELEMENT_UPDATE_PROPERTIES_REVERTED:
        'commandStack.element.updateProperties.reverted',
    COMMAND_STACK_ELEMENT_UPDATE_MODDLE_PROPERTIES_REVERTED:
        'commandStack.element.updateModdleProperties.reverted',
    SPACE_TOOL_GET_MIN_DIMENSIONS: 'spaceTool.getMinDimensions',
    COMMAND_STACK_SHAPE_DELETE_PRE_EXECUTED:
        'commandStack.shape.delete.preExecuted',
    COPY_PASTE_CREATE_TREE: 'copyPaste.createTree',
    COMMAND_STACK_CONNECTION_DELETE_PRE_EXECUTE:
        'commandStack.connection.delete.preExecute',
    COMMAND_STACK_CANVAS_UPDATE_ROOT_PRE_EXECUTE:
        'commandStack.canvas.updateRoot.preExecute',
    COMMAND_STACK_SPACE_TOOL_PRE_EXECUTE: 'commandStack.spaceTool.preExecute',
    COMMAND_STACK_LANE_ADD_PRE_EXECUTE: 'commandStack.lane.add.preExecute',
    COMMAND_STACK_LANE_RESIZE_PRE_EXECUTE:
        'commandStack.lane.resize.preExecute',
    COMMAND_STACK_LANE_SPLIT_PRE_EXECUTE: 'commandStack.lane.split.preExecute',
    COMMAND_STACK_ELEMENTS_DELETE_PRE_EXECUTE:
        'commandStack.elements.delete.preExecute',
    COMMAND_STACK_SHAPE_MOVE_PRE_EXECUTE: 'commandStack.shape.move.preExecute',
    COMMAND_STACK_SPACE_TOOL_POST_EXECUTED:
        'commandStack.spaceTool.postExecuted',
    COMMAND_STACK_LANE_ADD_POST_EXECUTED: 'commandStack.lane.add.postExecuted',
    COMMAND_STACK_LANE_RESIZE_POST_EXECUTED:
        'commandStack.lane.resize.postExecuted',
    COMMAND_STACK_LANE_SPLIT_POST_EXECUTED:
        'commandStack.lane.split.postExecuted',
    COMMAND_STACK_ELEMENTS_DELETE_POST_EXECUTED:
        'commandStack.elements.delete.postExecuted',
    COMMAND_STACK_SHAPE_MOVE_POST_EXECUTED:
        'commandStack.shape.move.postExecuted',
    SAVE_XML_START: 'saveXML.start',
    COMMAND_STACK_CONNECTION_MOVE_PRE_EXECUTE:
        'commandStack.connection.move.preExecute',
    CREATE_CLEANUP: 'create.cleanup',
    CREATE_INIT: 'create.init',
    COPY_PASTE_PASTE_ELEMENTS: 'copyPaste.pasteElements',
    REPLACE_END: 'replace.end',
    SHAPE_MOVE_CLEANUP: 'shape.move.cleanup',
    COMMAND_STACK_ELEMENTS_MOVE_PRE_EXECUTED:
        'commandStack.elements.move.preExecuted',
    COMMAND_STACK_SHAPE_DELETE_REVERT: 'commandStack.shape.delete.revert',
    SPACE_TOOL_SELECTION_START: 'spaceTool.selection.start',
    SPACE_TOOL_SELECTION_MOVE: 'spaceTool.selection.move',
    SPACE_TOOL_SELECTION_CLEANUP: 'spaceTool.selection.cleanup',
    SPACE_TOOL_CLEANUP: 'spaceTool.cleanup',
    POPUP_MENU_GET_PROVIDERS_BPMN_REPLACE:
        'popupMenu.getProviders.bpmn-replace',
    CONTEXT_PAD_CLOSE: 'contextPad.close',
    COMMAND_STACK_ELEMENTS_DISTRIBUTE_CAN_EXECUTE:
        'commandStack.elements.distribute.canExecute',
    RESIZE_MOVE: 'resize.move',
    SHAPE_MOVE_INIT: 'shape.move.init',
    RESIZE_CLEANUP: 'resize.cleanup',
    ELEMENT_DBLCLICK: 'element.dblclick',
    AUTO_PLACE_START: 'autoPlace.start',
    DRAG_INIT: 'drag.init',
    POPUP_MENU_OPEN: 'popupMenu.open',
    COMMAND_STACK_CHANGED: 'commandStack.changed',
    DIRECT_EDITING_ACTIVATE: 'directEditing.activate',
    DIRECT_EDITING_RESIZE: 'directEditing.resize',
    DIRECT_EDITING_COMPLETE: 'directEditing.complete',
    DIRECT_EDITING_CANCEL: 'directEditing.cancel',
    SHAPE_MOVE_REJECTED: 'shape.move.rejected',
    CREATE_REJECTED: 'create.rejected',
    TOOL_MANAGER_UPDATE: 'tool-manager.update',
    I18N_CHANGED: 'i18n.changed',
    LASSO_SELECTION_INIT: 'lasso.selection.init',
    LASSO_SELECTION_ENDED: 'lasso.selection.ended',
    LASSO_SELECTION_CANCELED: 'lasso.selection.canceled',
    LASSO_ENDED: 'lasso.ended',
    LASSO_CANCELED: 'lasso.canceled',
    LASSO_SELECTION_END: 'lasso.selection.end',
    LASSO_END: 'lasso.end',
    LASSO_START: 'lasso.start',
    LASSO_MOVE: 'lasso.move',
    LASSO_CLEANUP: 'lasso.cleanup',
    HAND_INIT: 'hand.init',
    HAND_ENDED: 'hand.ended',
    HAND_CANCELED: 'hand.canceled',
    HAND_MOVE_ENDED: 'hand.move.ended',
    HAND_MOVE_CANCELED: 'hand.move.canceled',
    KEYBOARD_KEYUP: 'keyboard.keyup',
    HAND_END: 'hand.end',
    HAND_MOVE_MOVE: 'hand.move.move',
    HAND_MOVE_END: 'hand.move.end',
    GLOBAL_CONNECT_INIT: 'global-connect.init',
    GLOBAL_CONNECT_ENDED: 'global-connect.ended',
    GLOBAL_CONNECT_CANCELED: 'global-connect.canceled',
    GLOBAL_CONNECT_DRAG_ENDED: 'global-connect.drag.ended',
    GLOBAL_CONNECT_DRAG_CANCELED: 'global-connect.drag.canceled',
    PALETTE_GET_PROVIDERS: 'palette.getProviders',
    CANVAS_RESIZED: 'canvas.resized',
    IMPORT_PARSE_COMPLETE: 'import.parse.complete',
    CREATE_CANCEL: 'create.cancel',
    CONTEXT_PAD_OPEN: 'contextPad.open',
};

// import MockData from '@/ui/common/plugins/diagram/mock-data.bpmn';
const camelize = (str) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
};
const translateByKeys = {
    searchInDiagram: {
        vi: 'Tìm kiếm trong biểu đồ',
        en: 'Search in diagram',
        fr: 'Search in diagram',
    },
    createStartEvent: {
        vi: 'Tạo công việc bắt đầu',
        en: 'Create start task',
        fr: 'Create start task',
    },
    appendEndEvent: {
        vi: 'Nối tới công việc kết thúc',
        en: 'Append end task',
        fr: 'Append end task',
    },
    appendGateway: {
        vi: 'Nối tới công việc ',
        en: 'Append gateway',
        fr: 'Append gateway',
    },
    appendTask: {
        vi: 'Nối tới công việc',
        en: 'Append task',
        fr: 'Append task',
    },
    messageStartEvent: {
        vi: 'Message start event',
        en: 'Message start event',
        fr: 'Message start event',
    },
    appendIntermediateBoundaryEvent: {
        vi: 'Append intermediate/boundary event',
        en: 'Append intermediate/boundary event',
        fr: 'Append intermediate/boundary event',
    },
    intermediateThrowEvent: {
        vi: 'Intermediate throw event',
        en: 'Intermediate throw event',
        fr: 'Intermediate throw event',
    },
    endEvent: {
        vi: 'End event',
        en: 'End event',
        fr: 'End event',
    },
    timerStartEvent: {
        vi: 'Timer start event',
        en: 'Timer start event',
        fr: 'Timer start event',
    },
    signalStartEvent: {
        vi: 'Signal start event',
        en: 'Signal start event',
        fr: 'Signal start event',
    },
    changeElement: {
        vi: 'Change element',
        en: 'Change element',
        fr: 'Change element',
    },
    delete: {
        vi: 'Xóa',
        en: 'Delete',
        fr: 'Delete',
    },
    createTask: {
        vi: 'Tạo công việc bắt đầu',
        en: 'Create task',
        fr: 'Create task',
    },
    createEndEvent: {
        vi: 'Create end event',
        en: 'Create end event',
        fr: 'Create end event',
    },
    createGateway: {
        vi: 'Create gateway',
        en: 'Create gateway',
        fr: 'Create gateway',
    },
};

const customTranslateFn = (template) => {
    const key = camelize(template);

    if (!Object.prototype.hasOwnProperty.call(translateByKeys, key))
        return template;

    return translateByKeys[key]['vi'];
};
export const CUSTOM_TRANSLATE = {
    translate: ['value', customTranslateFn],
};

// export const ORIGINAL_DATA_XML = `<?xml version="1.0" encoding="UTF-8"?>
// <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" id="_RdgBELNaEeSYkoSLDs6j-w" targetNamespace="http://activiti.org/bpmn">
//   <bpmn2:process id="Process_1">
//     <bpmn2:task id="Task_1" name="Examine Situation" qa:suitable="0.7">
//       <bpmn2:outgoing>SequenceFlow_1</bpmn2:outgoing>
//     </bpmn2:task>
//     <bpmn2:sequenceFlow id="SequenceFlow_1" name="" sourceRef="Task_1" targetRef="ExclusiveGateway_1"/>
//     <bpmn2:exclusiveGateway id="ExclusiveGateway_1" name="Things OK?">
//       <bpmn2:incoming>SequenceFlow_1</bpmn2:incoming>
//       <bpmn2:outgoing>SequenceFlow_2</bpmn2:outgoing>
//       <bpmn2:outgoing>SequenceFlow_5</bpmn2:outgoing>
//     </bpmn2:exclusiveGateway>
//     <bpmn2:sequenceFlow id="SequenceFlow_2" name="" sourceRef="ExclusiveGateway_1" targetRef="EndEvent_1"/>
//     <bpmn2:sequenceFlow id="SequenceFlow_5" name="" sourceRef="ExclusiveGateway_1" targetRef="EndEvent_2"/>
//     <bpmn2:endEvent id="EndEvent_1" name="Notification Sent">
//       <bpmn2:incoming>SequenceFlow_2</bpmn2:incoming>
//       <bpmn2:messageEventDefinition id="MessageEventDefinition_1"/>
//     </bpmn2:endEvent>
//     <bpmn2:endEvent id="EndEvent_2" name="Error Propagated">
//       <bpmn2:incoming>SequenceFlow_5</bpmn2:incoming>
//       <bpmn2:errorEventDefinition id="ErrorEventDefinition_1"/>
//     </bpmn2:endEvent>
//   </bpmn2:process>
//   <bpmndi:BPMNDiagram id="BPMNDiagram_1">
//     <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
//       <bpmndi:BPMNShape id="_BPMNShape_Task_2" bpmnElement="Task_1">
//         <dc:Bounds height="80.0" width="100.0" x="96.0" y="196.0"/>
//       </bpmndi:BPMNShape>
//       <bpmndi:BPMNShape id="_BPMNShape_EndEvent_2" bpmnElement="EndEvent_1">
//         <dc:Bounds height="36.0" width="36.0" x="396.0" y="300.0"/>
//         <bpmndi:BPMNLabel>
//           <dc:Bounds height="0.0" width="0.0" x="414.0" y="341.0"/>
//         </bpmndi:BPMNLabel>
//       </bpmndi:BPMNShape>
//       <bpmndi:BPMNShape id="_BPMNShape_ExclusiveGateway_2" bpmnElement="ExclusiveGateway_1" isMarkerVisible="true">
//         <dc:Bounds height="50.0" width="50.0" x="276.0" y="210.0"/>
//         <bpmndi:BPMNLabel>
//           <dc:Bounds height="21.0" width="74.0" x="333.0" y="226.0"/>
//         </bpmndi:BPMNLabel>
//       </bpmndi:BPMNShape>
//       <bpmndi:BPMNEdge id="BPMNEdge_SequenceFlow_1" bpmnElement="SequenceFlow_1" sourceElement="_BPMNShape_Task_2" targetElement="_BPMNShape_ExclusiveGateway_2">
//         <di:waypoint xsi:type="dc:Point" x="196.0" y="236.0"/>
//         <di:waypoint xsi:type="dc:Point" x="276.0" y="235.0"/>
//         <bpmndi:BPMNLabel>
//           <dc:Bounds height="6.0" width="6.0" x="214.0" y="236.0"/>
//         </bpmndi:BPMNLabel>
//       </bpmndi:BPMNEdge>
//       <bpmndi:BPMNEdge id="BPMNEdge_SequenceFlow_2" bpmnElement="SequenceFlow_2" sourceElement="_BPMNShape_ExclusiveGateway_2" targetElement="_BPMNShape_EndEvent_2">
//         <di:waypoint xsi:type="dc:Point" x="301.0" y="260.0"/>
//         <di:waypoint xsi:type="dc:Point" x="301.0" y="318.0"/>
//         <di:waypoint xsi:type="dc:Point" x="396.0" y="318.0"/>
//         <bpmndi:BPMNLabel>
//           <dc:Bounds height="6.0" width="6.0" x="298.0" y="301.0"/>
//         </bpmndi:BPMNLabel>
//       </bpmndi:BPMNEdge>
//       <bpmndi:BPMNShape id="_BPMNShape_EndEvent_3" bpmnElement="EndEvent_2">
//         <dc:Bounds height="36.0" width="36.0" x="396.0" y="132.0"/>
//         <bpmndi:BPMNLabel>
//           <dc:Bounds height="0.0" width="0.0" x="414.0" y="173.0"/>
//         </bpmndi:BPMNLabel>
//       </bpmndi:BPMNShape>
//       <bpmndi:BPMNEdge id="BPMNEdge_SequenceFlow_5" bpmnElement="SequenceFlow_5" sourceElement="_BPMNShape_ExclusiveGateway_2" targetElement="_BPMNShape_EndEvent_3">
//         <di:waypoint xsi:type="dc:Point" x="301.0" y="210.0"/>
//         <di:waypoint xsi:type="dc:Point" x="301.0" y="150.0"/>
//         <di:waypoint xsi:type="dc:Point" x="396.0" y="150.0"/>
//         <bpmndi:BPMNLabel>
//           <dc:Bounds height="6.0" width="6.0" x="333.0" y="150.0"/>
//         </bpmndi:BPMNLabel>
//       </bpmndi:BPMNEdge>
//     </bpmndi:BPMNPlane>
//   </bpmndi:BPMNDiagram>
// </bpmn2:definitions>`;
export const ORIGINAL_DATA_XML = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions 
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
    xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" 
    xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" 
    xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" 
    xmlns:di="http://www.omg.org/spec/DD/20100524/DI" 
    xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL 
    BPMN20.xsd" 
    targetNamespace="http://bpmn.io/schema/bpmn"
>
  <bpmn2:process id="Process_1" isExecutable="false">
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">

    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>`;
