<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 34 34"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m27.5 1h-21c-3.04 0-5.5 2.47-5.5 5.5v21c0 3.03 2.46 5.5 5.5 5.5h21c3.03 0 5.5-2.47 5.5-5.5v-21c0-3.03-2.47-5.5-5.5-5.5zm-14.5 3h1.5c.82 0 1.5.67 1.5 1.5s-.68 1.5-1.5 1.5h-1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5zm8.58 12.19c.26.19.42.49.42.81 0 .33-.16.63-.42.82l-7 5c-.18.12-.38.18-.58.18-.16 0-.32-.03-.46-.11-.33-.17-.54-.51-.54-.89v-10c0-.37.21-.71.54-.88s.73-.15 1.04.07zm-13.58 13.81h-1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.5c.82 0 1.5.67 1.5 1.5s-.68 1.5-1.5 1.5zm0-23h-1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.5c.82 0 1.5.67 1.5 1.5s-.68 1.5-1.5 1.5zm6.5 23h-1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.5c.82 0 1.5.67 1.5 1.5s-.68 1.5-1.5 1.5zm6.5 0h-1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.5c.82 0 1.5.67 1.5 1.5s-.68 1.5-1.5 1.5zm0-23h-1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.5c.82 0 1.5.67 1.5 1.5s-.68 1.5-1.5 1.5zm6.5 23h-1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.5c.82 0 1.5.67 1.5 1.5s-.68 1.5-1.5 1.5zm0-23h-1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.5c.82 0 1.5.67 1.5 1.5s-.68 1.5-1.5 1.5z"
      ></path>
    </g>
  </svg>
</template>
