<script setup lang="ts">
import { onMounted, onUnmounted, computed } from 'vue';
import alertStore from '@/store/alert';
import { AlertType } from '@/ui/hooks/commonHook';

const props = withDefaults(
  defineProps<{
    notification: any;
    type: string;
    title?: string;
    body?: string;
    duration: number;
  }>(),
  {
    type: 'info',
    title: '',
    body: '',
    duration: 5000,
  }
);

const _alertStore = alertStore();
const notificationTypeClass = computed(() => {
  if (props.type == AlertType.error) {
    return 'bg-red-400 text-white';
  }
  if (props.type == AlertType.info) {
    return 'bg-gray-300';
  }
  if (props.type == AlertType.warning) {
    return 'bg-orange-200 text-current-800';
  }
  return `bg-current-400 text-white`;
});

const metaData = computed(() => props.notification?.metaData);

const hasAction = computed(() => metaData.value?.hasAction);

let timeoutFn;
const timeout = () => {
  timeoutFn = setTimeout(
    () => _alertStore.removeDataAlerts(props.notification),
    props.duration || 5000
  );
};
onMounted(() => {
  timeout();
});
onUnmounted(() => {
  clearTimeout(timeoutFn);
});

const onClose = (notification) => {
  _alertStore.removeDataAlerts(notification);
};
const onMouseover = () => {
  clearTimeout(timeoutFn);
};
const onMouseleave = () => {
  timeout();
};
</script>

<template>
  <div
    class="
      w-full
      relative
      p-2
      rounded
      flex
      items-center
      justify-between
      transition-all
      bg-opacity-80
      mt-4
    "
    :class="`${notificationTypeClass} ${hasAction ? 'cursor-pointer' : ''}`"
    @mouseover="onMouseover"
    @mouseleave="onMouseleave"
  >
    <!-- <div class="transform-gpu absolute wave -left-6 -top-3">
      <SynIcon name="tictop-logo" custom-class="w-8 h-8" />
    </div> -->
    <div class="flex items-center">
      <div class="w-8 h-8 shrink-0 flex-center bg-white rounded-full">
        <SynIcon name="tictop-logo" custom-class="w-7 h-7 rounded-full" />
      </div>
      <div class="flex flex-col px-2 flex-nowrap">
        <div class="font-bold text-left">
          {{ title }}
        </div>
        <div
          class="text-ellipsis text-sm"
          style="white-space: pre-wrap"
          v-html="body"
        ></div>
      </div>
    </div>
    <div
      class="
        cursor-pointer
        w-6
        h-6
        rounded-full
        hover:bg-white hover:bg-opacity-20
        flex-center
        absolute
        top-1
        right-1
        do-not-close-task-detail-drawer
      "
      @click.stop="onClose(notification)"
    >
      <SynIcon name="close" custom-class="w-4 h-4 fill-white" />
    </div>
    <!-- <audio class="hidden" autoplay>
      <source
        src="https://s3.ap-southeast-1.amazonaws.com/tictop.source-default/web/audio/coockoo.wav"
        type="audio/ogg"
      />
    </audio> -->
  </div>
</template>
<style>
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
</style>
