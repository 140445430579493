import { useRoute, useRouter } from 'vue-router';
import { checkShowChatWidget } from '@/ui/hooks/system-config/chat.system-config';
import chatStore from '@/store/chat';

export default function useChatCommon() {
    const _route = useRoute();
    const _router = useRouter();
    const _chatStore = chatStore();

    const goToChatFromContact = (contact, isGroup, isActiveBubble = true) => {
        if (!contact) return;

        contact.name =
            contact.name || `${contact.lastName} ${contact.firstName}`;
        contact.avatar =
            contact.avatarThumbUrl || contact.avatarUrl || contact.avatar;

        const conversation = _chatStore.verifyExistedConversationOfContact(
            contact,
            isGroup
        );

        if (!checkShowChatWidget() || _route?.path?.includes('/messages')) {
            return _router.push({
                name: 'Messages',
                params: {
                    conversationId: conversation?.id,
                },
            });
        }

        _chatStore.addBubbleChat(conversation, isActiveBubble);
    };

    const goToConversation = (conversation, isActiveBubble = true) => {
        if (!conversation) return;

        const conver = _chatStore.verifyExistedConversation(conversation);

        if (!checkShowChatWidget() || _route?.path?.includes('/messages')) {
            return _router.push({
                name: 'Messages',
                params: {
                    conversationId: conver?.id,
                },
            });
        }

        _chatStore.addBubbleChat(conver, isActiveBubble);
    };

    return {
        goToChatFromContact,
        goToConversation,
    };
}
