<script setup lang="ts">
import {
  CalendarNotificationType,
  CalendarNotificationTypeValue,
} from '../../../common/constants/calendar';
import { computed } from 'vue';
import appStore from '@/store/app';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const props = defineProps<{
  event: any;
}>();

const notifTime = computed(() => {
  const notifMinutes = props.event?.metadata?.notificationMinutes;
  if (!notifMinutes) return null;

  const lang = appStore().language;

  return dayjs.duration(notifMinutes, 'minutes').locale(lang).humanize();
});
</script>

<template>
  <div v-if="event.metadata?.notificationType" class="flex items-center gap-4">
    <div :title="$t('COMMON_LABEL_ALERT')" class="flex-center">
      <SynIcon name="bell" class="fill-gray-500" />
    </div>

    <div
      v-if="
        event.metadata?.notificationType === CalendarNotificationType.Custom
      "
      class="text-sm"
    >
      {{ $t('CALENDAR_NOTIFY_BEFORE', { time: notifTime }) }}
    </div>

    <div v-else class="text-sm">
      {{
        $t(
          CalendarNotificationTypeValue[event.metadata?.notificationType]
            ?.label,
          {
            time: CalendarNotificationTypeValue[
              event.metadata?.notificationType
            ]?.time,
          }
        )
      }}
    </div>
  </div>
</template>
