<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g id="Layer_35" xmlns="http://www.w3.org/2000/svg" data-name="Layer 35">
        <path
          d="m32 3.4a28.59 28.59 0 0 0 -28.6 28.6 28.59 28.59 0 0 0 28.6 28.6 28.59 28.59 0 0 0 28.6-28.6 28.59 28.59 0 0 0 -28.6-28.6zm0 49.2a4.32 4.32 0 1 1 4.31-4.31 4.32 4.32 0 0 1 -4.31 4.31zm5.23-35.6-1.63 22a.6.6 0 0 1 -.6.56h-6a.6.6 0 0 1 -.6-.56l-1.63-22a5.24 5.24 0 1 1 10.46 0z"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
