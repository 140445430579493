import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DayoffService from '@/application/services/DayoffService';
import {
    DayoffCalendarItemClass,
    DayoffDetailItemClass,
} from '@/domain/entities/dayoff/DayoffCalendarItemClass';
import { Calendar } from '@fullcalendar/core';
import getUserInfo from '@/ui/helpers/user-helper';
import { DayoffStatus } from '@/domain/enums/DayoffEnums';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import rrulePlugin from '@fullcalendar/rrule';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { renderDayoffItemEvent } from '@/ui/components/calendars/event-item/dayoff-render-component';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { fullCalendarInstance } from '@/store/dayoff/dayoff-state';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import calendarStore from '@/store/calendar';
import { CalendarsType } from '@/domain/enums/CalendarsEnums';
import { renderItemEvent } from '@/ui/components/calendars/event-item/render-component';
import { EventDetailItemClass } from '@/domain/entities/calendars/CalendarsClass';
import { CalendarSource } from '@/ui/common/constants/calendar';
import {
    CalendarsDayoffFilter,
    getDefaultEndDate,
    getDefaultStartDate,
} from '@/domain/entities/calendars/CalendarsOverviewFiltersClass';
import appStore from '@/store/app';
import myProfileStore from '@/store/auth/my-profile';
import dayjs from 'dayjs';
import settingStore from '@/store/setting';
import getDynamicPositionDayoff from '@/ui/composables/app/dynamic-position-dayoff';
import permissionStore from '@/store/permission';
import viLocale from '@fullcalendar/core/locales/vi';
import frLocale from '@fullcalendar/core/locales/fr';

let timerUpdateSize: any;
export default defineStore({
    id: storeId.CALENDARS_OVERVIEW,
    state: () => ({
        _dayoffService: DayoffService.getInstance(),
        calendarItemObjectById: {},
        pendingList: [] as any[],
        myActiveList: [] as any[],
        currentDayoffDetailId: '',
        currentEventId: '',
        elementEvent: HTMLElement,
        positionEvent: {
            x: 0,
            y: 0,
        },
        isOpenView: {
            type: 1,
            subType: 10,
        },
        isPositionEventDetail: false,
        eventDetail: {},
        calendarDetail: HTMLElement,
        calendarShowByType: {
            EVENTS: {
                key: 'EVENTS',
                label: 'CALENDAR_FILTER_TITLE_TYPE_EVENT',
                labelDefault: 'Events',
                isActive: true,
                subContent: '',
                isShow: true,
            },
            DAYOFF: {
                key: 'DAYOFF',
                label: 'COMMON_MODULE_DAYOFF',
                labelDefault: 'Day off',
                isActive: false,
                subContent: '',
                isShow: true,
            },
        },
        positionDayoff: {
            x: 0,
            y: 0,
        },
        isShowRightContent: true,
        isOpenCreateForm: false,
        defaultStartDate: new Date(),
        viewTitle: '',
        filtersData: {
            fromDate: 0,
            toDate: 0,
            isShowSystemEvents: true,
        } as any,
        viewCalendar: 'dayGridMonth',
        viewOptions: {} as any,
        listMembersOffWorkOnday: [] as any,
        loading: false,
    }),
    getters: {},
    actions: {
        initDayoffCalendar(tictopCalendar) {
            const _appStore = appStore();

            const openDetail = this.onOpenDetail;
            const setElement = (element, position) => {
                this.elementEvent = element;
                this.positionEvent = {
                    x: position.x,
                    y: position.y,
                };
            };
            const navLinkDayClick = this.navLinkClickDay;
            const onOpenCreateForm = this.onOpenCreateForm;
            const handlechangeDateEvent = this.handlechangeDateEvent;
            // const updateCurrentDate = this.updateCurrentDate;
            const container = tictopCalendar
                ? tictopCalendar
                : document.getElementById('dayoff-calendar-id');
            if (
                fullCalendarInstance.value &&
                typeof fullCalendarInstance.value.destroy == 'function'
            ) {
                fullCalendarInstance.value.destroy();
            }

            fullCalendarInstance.value = new Calendar(container, {
                themeSystem: 'standard',
                timeZone: this.caculateTimezone(),
                // locale: _appStore.language,
                locale:
                    _appStore.language == 'vi'
                        ? viLocale
                        : _appStore.language == 'fr'
                        ? frLocale
                        : 'en',
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin,
                    momentPlugin,
                    rrulePlugin,
                    momentTimezonePlugin,
                ],
                editable: true,
                firstDay: 1,
                navLinks: true,
                initialView: 'dayGridMonth',
                dayMaxEventRows: 3,
                aspectRatio: 1,
                lazyFetching: true,
                nowIndicator: true,
                footerToolbar: false,
                fixedWeekCount: false,
                droppable: true,
                headerToolbar: {
                    left: '',
                    center: '',
                    right: '',
                },
                visibleRange: {
                    start: this.filtersData.fromDate
                        ? dayjs(this.filtersData.fromDate).format('YYYY-MM-DD')
                        : dayjs(getDefaultStartDate()).format('YYYY-MM-DD'),
                    end: this.filtersData.toDate
                        ? dayjs(this.filtersData.toDate).format('YYYY-MM-DD')
                        : dayjs(getDefaultEndDate()).format('YYYY-MM-DD'),
                },
                customButtons: {
                    todayButton: {
                        text: translate('CALENDAR_TITLE_TODAY'),
                        click: () => {
                            fullCalendarInstance.value.today();
                        },
                    },
                    ForwardButton: {
                        text: '>',
                        click: () => {
                            fullCalendarInstance.value.next();
                        },
                    },
                    BackwardButton: {
                        text: '<',
                        click: () => {
                            fullCalendarInstance.value.prev();
                        },
                    },
                },
                views: {
                    dayGridMonth: {
                        titleFormat: 'MMMM, YYYY',
                    },
                    dayGridWeek: {
                        titleFormat: 'DD MMMM, YYYY',
                        dayMaxEventRows: 10,
                    },
                    dayGridDay: {
                        titleFormat: 'DD MMMM, YYYY',
                        dayMaxEventRows: 10,
                    },
                    timeGridWeek: {
                        titleFormat: 'DD MMMM, YYYY',
                    },
                    timeGridDay: {
                        titleFormat: 'DD MMMM, YYYY',
                    },
                    listWeek: {
                        titleFormat: 'DD MMMM, YYYY',
                    },
                },
                buttonText: {
                    todayButton: translate('CALENDAR_TITLE_TODAY') || 'Today',
                    month: translate('CALENDAR_TITLE_MONTH') || 'Month',
                    week: translate('CALENDAR_TITLE_WEEK') || 'Week',
                    day: translate('CALENDAR_TITLE_DAY') || 'Day',
                    list: translate('CALENDAR_TITLE_LIST') || 'List',
                },
                displayEventTime: true,
                eventTimeFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short',
                },
                datesSet: (event) => {
                    this.updateCurrentDate(event);
                    this.updateViewTitle();
                },
                eventSources: [],
                eventContent: (arg) => {
                    const extendedProps = arg.event.extendedProps;
                    if (extendedProps.calendarType == CalendarsType.DAYOFF) {
                        const itemEvent = renderDayoffItemEvent(
                            new DayoffDetailItemClass(extendedProps)
                        );
                        itemEvent.style.width = '100%';

                        return { domNodes: [itemEvent] };
                    }
                    if (extendedProps.calendarType == CalendarsType.EVENT) {
                        const itemEvent = renderItemEvent(
                            new EventDetailItemClass(extendedProps),
                            this.viewOptions
                        );
                        itemEvent.id = 'event_item';
                        itemEvent.style.width = '100%';

                        return { domNodes: [itemEvent] };
                    }
                    return null;
                },
                dateClick: function (info) {
                    onOpenCreateForm(new Date(info.dateStr));
                },
                eventDrop: function (info) {
                    handlechangeDateEvent(info?.event);
                },
                navLinkDayClick: function (date, jsEvent) {
                    navLinkDayClick(date, jsEvent);
                },
                eventResize: function () {},
                eventClick: function (info) {
                    setElement(info.el, info.el.getBoundingClientRect());
                    openDetail(info.event.extendedProps, info.event);
                    // @ts-ignore
                    if (this.elementEvent) {
                        // @ts-ignore
                        this.elementEvent.removeAttribute('style');
                    }
                    // @ts-ignore
                    this.elementEvent = info.el;
                    info.el.setAttribute(
                        'style',
                        'border: 1px solid red !important'
                    );
                },
                moreLinkClick: (event) => {
                    setTimeout(() => {
                        const popoverElements =
                            document.getElementsByClassName('fc-more-popover');
                        if (popoverElements?.length == 0) return;

                        const calendar: any = event?.view?.calendar;
                        const calendarBounding =
                            calendar?.el?.getBoundingClientRect &&
                            typeof calendar?.el?.getBoundingClientRect ==
                                'function'
                                ? calendar?.el?.getBoundingClientRect()
                                : null;
                        for (const element of popoverElements) {
                            const elementBounding =
                                element.getBoundingClientRect();

                            const overY =
                                document.documentElement.clientHeight -
                                (elementBounding?.top +
                                    elementBounding?.height);
                            if (overY < 0) {
                                (element as HTMLElement).style.top = `${
                                    elementBounding?.top +
                                    overY -
                                    (calendarBounding?.top || 0)
                                }px`;
                            }
                        }
                    }, 50);
                    return;
                },
            });
            fullCalendarInstance.value.render();

            this.updateViewTitle();
            // fullCalendarInstance.value.setOption('timeZone', 'Asia/Bangkok');
        },

        caculateTimezone() {
            const utcDate = myProfileStore().getTimezone;
            if (utcDate == '+7') return 'Asia/Bangkok';
            if (utcDate == '-5') return 'America/New_York';
            if (utcDate == '+2') return 'Europe/Paris';
            return 'Africa/Abidjan';
        },

        onOpenCreateForm(startDate) {
            this.isOpenCreateForm = true;
            this.defaultStartDate = startDate || new Date();
        },

        onCloseCreateForm() {
            this.isOpenCreateForm = false;
        },

        updateCurrentDate(date) {
            this.filtersData = {
                ...this.filtersData,
                fromDate: date?.start?.getTime(),
                toDate: date?.end?.getTime(),
            };
            this.rerenderCalendar();
        },

        onChangeFilterData(data) {
            this.filtersData = {
                ...this.filtersData,
                ...data,
            };
        },

        updateItemOfList(dayoff) {
            const requester = getUserInfo(dayoff?.userId);
            const updatedItem = new DayoffCalendarItemClass({
                id: dayoff.id,
                title: dayoff?.name || '',
                start: dayoff.startDate,
                end: dayoff.endDate,
                extendedProps: {
                    ...dayoff,
                    userName: requester?.name,
                    userAvatar: requester?.avatar,
                },
            });
            this.calendarItemObjectById = {
                ...this.calendarItemObjectById,
                [updatedItem.id]: updatedItem,
            };
            this.rerenderCalendar('Dayoff');
        },

        updateMyRequests(dayoff) {
            const index = this.myActiveList.findIndex(
                (o) => o.id == dayoff?.id
            );
            if (index > -1) {
                this.myActiveList[index] = dayoff;
            }
        },

        async rerenderCalendar(type: string = 'All') {
            const _eventStore = calendarStore();
            const promiseAll = [] as any[];

            if (
                settingStore().dayoffEnabled &&
                permissionStore().organizationModel !== 'PERSONAL' &&
                !myProfileStore().isVisitor
            ) {
                if (type === 'All' || type === 'Dayoff') {
                    promiseAll.push(this.getListDayoffs());
                }
            }
            if (settingStore().calendarEnabled) {
                if (type === 'All' || type === 'Event') {
                    promiseAll.push(
                        _eventStore.getRangeEvents(this.filtersData)
                    );
                }
            }
            if (this.filtersData?.isShowSystemEvents) {
                if (type === 'All' || type === 'System') {
                    promiseAll.push(
                        _eventStore.getSystemEvents(this.filtersData)
                    );
                }
            } else {
                _eventStore.calendarsSystem = [];
            }

            try {
                await Promise.all(promiseAll);
                this.handleUpdateSourceDataInCalendar();
            } catch (error) {
                console.log(error);
            }
        },

        onChangeViewCalender(viewCalendar) {
            const today = dayjs().toDate();
            this.viewCalendar = viewCalendar;
            if (viewCalendar === 'dayGridDay') {
                fullCalendarInstance.value.changeView(viewCalendar, today);
            } else {
                fullCalendarInstance.value.changeView(
                    viewCalendar || 'dayGridMonth'
                );
            }

            this.updateViewTitle();
        },

        async onOpenDetail(extendedProps: any, event) {
            if (!extendedProps) return;
            await this.handlePositionPopup();
            if (this.currentDayoffDetailId) this.currentDayoffDetailId = '';
            if (this.isOpenView.type) this.isOpenView.type = 0;
            if (this.isOpenView.subType) this.isOpenView.subType = 0;
            switch (extendedProps?.calendarType) {
                case CalendarsType.EVENT:
                    this.eventDetail = event;
                    this.currentEventId = extendedProps?.id;
                    if (extendedProps?.system) {
                        this.isOpenView.type = CalendarSource.System;
                        this.isOpenView.subType = 100;
                    } else {
                        this.isOpenView.type = CalendarSource.Work;
                        this.isOpenView.subType = extendedProps.type;
                    }
                    break;
                case CalendarsType.DAYOFF:
                    this.currentDayoffDetailId = extendedProps?.id;
                    break;
                default:
                    break;
            }
        },

        handlePositionPopup() {
            if (
                this.positionDayoff.x ||
                this.positionDayoff.y ||
                this.loading
            ) {
                this.positionDayoff = {
                    x: 0,
                    y: 0,
                };
                this.isPositionEventDetail = false;
            }
            setTimeout(() => {
                const calendarDetail =
                    document.getElementById('dayoffDetailId');

                const positionEventClick = getDynamicPositionDayoff(
                    this.positionEvent.x,
                    this.positionEvent.y,
                    calendarDetail,
                    this.elementEvent
                );
                this.positionDayoff = {
                    x: positionEventClick.x,
                    y: positionEventClick.y,
                };
                this.isPositionEventDetail = true;
            }, 400);
        },

        onCloseDetail() {
            this.currentDayoffDetailId = '';
        },

        async getListDayoffs() {
            const data = await this._dayoffService.getListDayoffs(
                new CalendarsDayoffFilter(this.filtersData)
            );
            const dayoffList = data?.result;
            if (
                Object.keys(this.calendarItemObjectById).length >=
                dayoffList?.length
            ) {
                this.calendarItemObjectById = {};
            }
            if (dayoffList?.length > 0) {
                dayoffList.forEach((dayoff) => {
                    const requester = getUserInfo(dayoff?.userId);
                    this.calendarItemObjectById = {
                        ...this.calendarItemObjectById,
                        [dayoff.id]: new DayoffCalendarItemClass({
                            id: dayoff.id,
                            title: requester?.name || '',
                            start: dayjs(dayoff.startDate).format('YYYY-MM-DD'),
                            end: dayjs(dayoff.endDate)
                                .add(1, 'day')
                                .format('YYYY-MM-DD'),
                            allDay: true,
                            extendedProps: {
                                ...dayoff,
                                userName: requester?.name,
                                userAvatar: requester?.avatar,
                            },
                        }),
                    };
                });
            }
        },

        async getListPending() {
            const res = await this._dayoffService.getListByStatus({
                status: [DayoffStatus.PENDING, DayoffStatus.MARK_UNUSED],
                year: null,
            });

            this.pendingList = arrayOrderBy(
                res?.result || [],
                ['createdDate'],
                ['asc']
            );
        },

        async getMyActiveRequest() {
            const res = await this._dayoffService.getMyActiveRequest(null);
            this.myActiveList = arrayOrderBy(
                res?.result || [],
                ['createdDate'],
                ['asc']
            );
        },

        removeItemInPendingList(id) {
            this.pendingList =
                this.pendingList?.length > 0
                    ? this.pendingList.filter((o) => o.id !== id)
                    : [];
        },

        toggleShowRightContent() {
            this.isShowRightContent = !this.isShowRightContent;
        },

        changeCalendarShowByType(item) {
            if (item.key == 'DAYOFF' && item.isShow) {
                this.isShowRightContent = false;
            }
            this.calendarShowByType[item.key].isShow = !item.isShow;
            setTimeout(() => {
                // this.initDayoffCalendar(null);
                this.onChangeViewDateCalendar();
            });
        },

        updateViewTitle() {
            this.viewTitle = fullCalendarInstance.value?.currentData?.viewTitle;
        },

        navLinkClickDay(date, jsEvent) {
            console.log(':', jsEvent);

            this.viewCalendar = 'dayGridDay';
            fullCalendarInstance.value.changeView('dayGridDay', date);
        },

        async handlechangeDateEvent(event) {
            const _eventStore = calendarStore();
            try {
                await _eventStore.updateTimeEvent({
                    eventId: event?.id,
                    startDate: event?.start,
                    endDate: event?.end || event?.start,
                });
                await _eventStore.getRangeEvents(this.filtersData);
                this.handleUpdateSourceDataInCalendar();
            } catch (e) {
                console.log('e');
            }
        },

        handleUpdateSourceDataInCalendar() {
            const _eventStore = calendarStore();
            const dataSources = [] as any[];
            if (this.calendarShowByType.EVENTS.isShow) {
                dataSources.push(_eventStore.calendars);
                dataSources.push(_eventStore.calendarsSystem);
            }
            if (this.calendarShowByType.DAYOFF.isShow) {
                dataSources.push(Object.values(this.calendarItemObjectById));
            }

            fullCalendarInstance.value.removeAllEventSources();
            dataSources.forEach((data) => {
                fullCalendarInstance.value.addEventSource(data);
            });
        },

        onChangeViewDateCalendar() {
            fullCalendarInstance.value.changeView(
                this.viewCalendar || 'dayGridMonth',
                {
                    start: this.filtersData.fromDate
                        ? dayjs(this.filtersData.fromDate).format('YYYY-MM-DD')
                        : dayjs(getDefaultStartDate()).format('YYYY-MM-DD'),
                    end: this.filtersData.toDate
                        ? dayjs(this.filtersData.toDate).format('YYYY-MM-DD')
                        : dayjs(getDefaultEndDate()).format('YYYY-MM-DD'),
                }
            );
        },

        async getMembersOffWorkByDate() {
            const date = new Date().getTime();
            const data = await this._dayoffService.getMembersOffWorkByDate(
                date
            );

            const result = data?.result || [];

            this.listMembersOffWorkOnday = [...result].map((el) => {
                const dateStart = new Date(el.startDate).getDate();
                const currentDate = new Date().getDate();

                const sessionKey =
                    dateStart === currentDate
                        ? el.startDateSession
                        : el.endDateSession;

                return {
                    ...el,
                    session: sessionKey,
                };
            });
        },

        updateSizeCalendar() {
            clearTimeout(timerUpdateSize);
            timerUpdateSize = setTimeout(() => {
                if (
                    fullCalendarInstance.value &&
                    typeof fullCalendarInstance.value.updateSize == 'function'
                ) {
                    fullCalendarInstance.value.updateSize();
                }
            }, 400);
        },

        renderCalendar() {
            fullCalendarInstance.value.render();
        },

        async getRangeEvents() {
            const _eventStore = calendarStore();

            await _eventStore.getRangeEvents(this.filtersData);

            this.handleUpdateSourceDataInCalendar();
        },
    },
});
