import { ref } from 'vue';
import { chain } from 'lodash';
import { taskStates } from '@/ui/hooks/taskHook';
import { PlanTypeEnum } from '@/domain/enums/taskEnum';

export default function useWorkflowTasksFilter() {
    const sortByKey = ref<{ [key: string]: any }>({});
    const filterByKey = ref<{ [key: string]: any }>({});

    const initFilterData = (tasks) => {
        return (filterByKey.value = {
            assigneeId: _initFilterDataAssigneeId(tasks),
            groupId: _initFilterDataGroupId(tasks),
            domainId: _initFilterDataDomainId(tasks),
            taskLife: _initFilterDataTaskLife(tasks),
            taskPlan: _initFilterDataTaskPlan(),
            urgency: _initFilterDataUrgency(),
        });
    };

    const updateFilterColumn = (column, data) => {
        switch (column) {
            case 'assigneeId':
            case 'groupId':
            case 'domainId':
            case 'urgency':
            case 'taskLife':
            case 'taskPlan':
                filterByKey.value[column]?.forEach(
                    (item) =>
                        (item.selected = data?.some(
                            (i) => i?.id === item?.id && i?.selected
                        ))
                );
                break;
            case 'deadline':
            case 'creationTime':
                filterByKey.value[column] = data;
                break;
        }

        return filterByKey.value;
    };

    const resetFilterColumn = (column) => {
        switch (column) {
            case 'assigneeId':
            case 'groupId':
            case 'domainId':
            case 'urgency':
            case 'taskLife':
            case 'taskPlan':
                filterByKey.value[column]?.forEach(
                    (item) => (item.selected = false)
                );
                break;
            case 'deadline':
            case 'creationTime':
                filterByKey.value[column] = null;
                break;
        }

        return filterByKey.value;
    };

    const updateSortColumn = (column) => {
        switch (sortByKey.value[column]?.orderBy) {
            case 'desc':
                return (sortByKey.value = { [column]: { orderBy: 'asc' } });
            case 'asc':
                return (sortByKey.value = {});
            default:
                return (sortByKey.value = { [column]: { orderBy: 'desc' } });
        }
    };

    const _initFilterDataAssigneeId = (tasks) => {
        return chain(tasks)
            .filter((t) => t.assigneeId)
            .uniqBy((t) => t.assigneeId)
            .map((t) => ({
                id: t?.assigneeId,
                selected: false,
            }))
            .orderBy(['id'], ['desc'])
            .value();
    };

    const _initFilterDataGroupId = (tasks) => {
        return chain(tasks)
            .filter((t) => t.groupId)
            .uniqBy((t) => t.groupId)
            .map((t) => ({
                id: t?.groupId,
                selected: false,
            }))
            .orderBy(['id'], ['desc'])
            .value();
    };

    const _initFilterDataDomainId = (tasks) => {
        return chain(tasks)
            .filter((t) => t.domainId)
            .uniqBy((t) => t.domainId)
            .map((t) => {
                // const projectIds = chain(tasks)
                //     .filter(t => t.projectId)
                //     .uniqBy(t => t.projectId)
                //     .map(t => ({
                //         projectId: t.projectId,
                //         selectedId: false
                //     }))
                //     .value();

                return {
                    id: t.domainId,
                    selected: false,
                    // projectId: projectIds,
                    // collapse: !projectIds?.length
                };
            })
            .value();
    };

    const _initFilterDataUrgency = () => {
        return [
            {
                id: 4,
                selected: false,
            },
            {
                id: 3,
                selected: false,
            },
            {
                id: 2,
                selected: false,
            },
            {
                id: 1,
                selected: false,
            },
        ];
    };

    const _initFilterDataTaskPlan = () => {
        return [
            {
                id: PlanTypeEnum.Nothing,
                selected: false,
            },
            {
                id: PlanTypeEnum.ToDo,
                selected: false,
            },
            {
                id: PlanTypeEnum.ToFinish,
                selected: false,
            },
        ];
    };

    const _initFilterDataTaskLife = (tasks) => {
        return chain(tasks)
            .filter((t) => t.taskLife)
            .uniqBy((t) => t.taskLife)
            .map((t) => ({
                id: t.taskLife,
                selected: false,
                index: taskStates[t.taskLife]?.index,
            }))
            .value();
    };

    return {
        sortByKey,
        filterByKey,
        initFilterData,
        updateFilterColumn,
        resetFilterColumn,
        updateSortColumn,
    };
}
