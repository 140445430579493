<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import { SubActivityInTask } from '../../ui-enums/tasklog-enums';

const props = defineProps<{
  activity: any;
}>();

const subActivity = computed<SubActivityInTask>(() => {
  return props.activity?.subActivity;
});
const beforeUpdate = computed<any>(() => {
  // console.log('🚀 Tictop ~ props.activity:', props.activity);
  return covertSnakeCaseToCamelCase(props.activity?.beforeUpdate);
});
const afterUpdate = computed<any>(() => {
  return covertSnakeCaseToCamelCase(props.activity?.afterUpdate);
});

const isEnableCase = computed<any>(() => {
  return (
    !beforeUpdate.value?.approvalEnabled && afterUpdate.value?.approvalEnabled
  );
});
const isChangeCase = computed<any>(() => {
  return (
    beforeUpdate.value?.approvalEnabled && afterUpdate.value?.approvalEnabled
  );
});
const isCancelCase = computed<any>(() => {
  return !afterUpdate.value?.approvalEnabled;
});

onMounted(() => {
  // console.log('🚀 Tictop ~ props:', props.activity);
});
</script>
<template>
  <span class="lowercase">
    <template v-if="subActivity == SubActivityInTask.ApprovalChange">
      <span
        v-if="isEnableCase"
        v-html="$t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_ADD_APPROVER')"
      >
      </span>
      <span
        v-else-if="isChangeCase"
        v-html="$t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_CHANGED')"
      >
      </span>
      <span
        v-else-if="isCancelCase"
        v-html="$t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_UN_MASK_AS_APPROVAL')"
      >
      </span>
    </template>
    <template v-else-if="subActivity == SubActivityInTask.ApprovalRequest">
      <span v-html="$t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST')">
      </span>
    </template>
    <template
      v-else-if="subActivity == SubActivityInTask.ApprovalRequestCancel"
    >
      <span
        v-html="
          $t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST_CANCEL')
        "
      >
      </span>
    </template>
    <template
      v-else-if="subActivity == SubActivityInTask.ApprovalRequestApprove"
    >
      <span
        v-html="
          $t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST_APPROVE')
        "
      >
      </span>
    </template>
    <template
      v-else-if="subActivity == SubActivityInTask.ApprovalRequestReject"
    >
      <span
        v-html="
          $t('NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST_REJECT')
        "
      >
      </span>
    </template>
  </span>
</template>
