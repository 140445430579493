import ZaloRepository from '@/application/repositories/ZaloRepository';
export default class ZaloService {
    private static instance: ZaloService;
    _zaloRepository: ZaloRepository;
    constructor() {
        this._zaloRepository = ZaloRepository.getInstance();
    }

    public static getInstance(): ZaloService {
        if (!ZaloService.instance) {
            // Get from local storage
            ZaloService.instance = new ZaloService();
        }

        return ZaloService.instance;
    }

    saveOaAccessToken(callbackQuery) {
        return this._zaloRepository.saveOaAccessToken(callbackQuery);
    }

    getOAInfo() {
        return this._zaloRepository.getOAInfo();
    }

    getUserFollowedInfo(userId) {
        return this._zaloRepository.getUserFollowedInfo(userId);
    }
}
