<script setup lang="ts">
import { computed, inject } from 'vue';
import ShapeMediaEntity from '@/ui/components/drawer/entities/ShapeMediaEntity';
import PROVIDER_IDS, {
  TDesktopDrawer,
  // TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
import ShapeActions from '@/ui/components/drawer/components/ShapeActions.vue';

const props = defineProps<{
  shapeEntity: ShapeMediaEntity;
  isDragging?: boolean;
}>();

const linkInfo = computed<any>(() => {
  return props.shapeEntity?.sourceData;
});

// const desktopDrawerShapeInjected = inject<TDesktopDrawerShape>(
//   PROVIDER_IDS.DESKTOP_DRAWER_SHAPE
// );
const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);

// const onMouseDown = (event) => {
//   desktopDrawerShapeInjected?.onMouseDown(event);
// };

const onDblClick = () => {
  if (!props?.shapeEntity?.sourceData?.url) return;
  window.open(props?.shapeEntity?.sourceData?.url, '_blank');
};

const onClickDetail = () => {};

const onClickRemove = () => {
  desktopDrawerInjected?.desktopDrawerInstance.value?.removeShapeById(
    props.shapeEntity?.id,
    () => {
      desktopDrawerInjected?.addMoreHistory();
    }
  );
};
</script>
<template>
  <div
    class="flex-center rounded-md"
    :style="{
      width: `${shapeEntity?.width}px`,
      height: `${shapeEntity?.height}px`,
    }"
  >
    <div
      class="bg-white flex-center w-full relative rounded-md"
      :style="{ pointerEvents: 'all' }"
      @dblclick="onDblClick"
    >
      <AtomLinkInformation
        v-if="linkInfo?.url"
        :link-url="linkInfo?.url"
        :link-info="linkInfo"
        disable-remove-button
      />
      <ShapeActions
        class="absolute top-0 right-0 z-100"
        :action-list="['REMOVE']"
        @on-click-detail="onClickDetail"
        @on-click-remove="onClickRemove"
      />
    </div>
  </div>
</template>
