<script setup lang="ts">
import { computed, ref } from 'vue';
import fileStorageStore from '@/store/fileStorage';
import groupStore from '@/store/group';
import ButtonChangeFileLocation from '../../_commons/button-change-file-location/ButtonChangeFileLocation.vue';
import VigRecorder from '../../../../common/atoms/SynRecorder/VigRecorder.vue';
import { formatDate } from '@/ui/plugins/filters';
import { ignoreUnicode } from '@/ui/plugins/utils';
import myProfileStore from '@/store/auth/my-profile';
import AddFileLocation from '@/ui/modules/ged/_commons/add-file-location/AddFileLocation.vue';

const props = defineProps({
  location: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['onClose']);

const _fileStorageStore = fileStorageStore();
const _groupStore = groupStore();

const myProfile = computed(() => myProfileStore().myProfile);
// const allMyGroups = computed(() =>
//   _groupStore.allMyGroups?.filter((group) => group?.isMember)
// );

const audioLocation = ref(props.location?.groupId);

// const audioLocationName = computed(() => {
//   if (!audioLocation?.value) return translate('GED_MY_STORAGE');
//
//   const groupInfo = allMyGroups.value?.find(
//     (group) => group?.id === audioLocation.value
//   );
//   return groupInfo?.name;
// });

const audioLocationCanChange = computed(
  () =>
    false && // Add by domain resources
    !props.location?.groupId &&
    !props.location?.category &&
    props.location?.userId !== myProfile?.value.id &&
    !props.location?.folderId
);

const onRecordDone = async (audioFiles) => {
  if (!audioFiles?.length || !myProfile.value.organizationId) return;

  try {
    const newAudioFiles = audioFiles.map(({ blob }) => blob);

    newAudioFiles.forEach((audioFile) => {
      audioFile.name = _genFileName('Audio', 'mp3');
    });

    const location = {
      folderId: props.location?.folderId,
      // groupId: audioLocation.value,
      groupId: props.location?.groupId,
      domainId: props.location?.domainId,
    };

    const uploadedFiles = await _fileStorageStore.uploadFiles(
      myProfile.value.organizationId,
      newAudioFiles,
      location
    );

    _fileStorageStore.setRenamingFile(
      uploadedFiles?.length ? uploadedFiles[0] : null
    );

    _fileStorageStore.getFiles(props.location);

    emit('onClose');
  } catch (e) {
    console.log(e);
  }
};

const _genFileName = (type, ext) => {
  let noteName;

  const allMyGroups = _groupStore.allMyGroups;
  const groupInfo = (allMyGroups || []).find(
    (group) => group?.id === audioLocation.value
  );

  if (groupInfo) {
    noteName = ignoreUnicode(groupInfo?.name, false);
  } else {
    noteName = ignoreUnicode(
      myProfile.value?.fullName?.split(' ').pop(),
      false
    );
  }

  const time = formatDate(new Date(), 'YYYY-MM-DD hh.mm A');

  return `${type}-Storage ${noteName} ${time}.${ext}`;
};
</script>

<template>
  <syn-modal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    style-body="p-0 flex flex-col"
    :is-hidden-footer="true"
    :disable-click-outside="true"
    :disable-esc-to-close="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>{{ $t('GED_AUDIO_NEW') }}</div>
    </template>

    <template #body>
      <div class="p-3 mb-3 bg-gray-100 flex space-x-2">
        <div class="w-max text-gray-500">{{ $t('GED_FOLDER_IN') }}</div>
        <AddFileLocation :location="location" class="font-semibold" />
        <button-change-file-location
          v-if="audioLocationCanChange"
          class="ml-3"
          :group-id="audioLocation"
          @on-location-click="audioLocation = $event"
        />
      </div>

      <VigRecorder @on-cancel="$emit('onClose')" @on-done="onRecordDone" />
    </template>
  </syn-modal>
</template>
