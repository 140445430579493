<script setup lang="ts">
import { encryptUserId } from '@/ui/plugins/crypto/crypto';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconCustom.vue';

defineProps({
  userList: {
    type: Array,
    default: () => [],
  },
});

const getMenuLinkUrl = (id) => {
  return `/tasks/users/${encryptUserId(id)}`;
};
</script>

<template>
  <div :class="`grid w-full h-full grid-cols-${userList?.length} gap-3`">
    <template v-if="userList?.length > 0">
      <RouterLink
        v-for="(rank, index) in userList"
        :key="rank"
        class="
          h-full
          flex flex-col
          items-center
          px-6
          py-4
          rounded-md
          border border-transparent
          hover:border-current-400
        "
        :class="
          index === 0
            ? 'bg-current-50'
            : index === 1
            ? 'bg-current-50 bg-opacity-50'
            : 'bg-current-50 bg-opacity-20'
        "
        :to="getMenuLinkUrl(rank?.id)"
      >
        <div class="flex flex-col items-center justify-center">
          <SynIcon
            name="StarGold"
            :custom-class="
              index === 0
                ? 'fill-orange-500 w-10 h-10'
                : index === 2
                ? 'fill-gray-300 w-10 h-10'
                : 'fill-current w-10 h-10'
            "
          />
          <div
            class="p-0.5 border-2 rounded-full"
            :class="
              index === 0
                ? 'border-orange-500'
                : index === 2
                ? 'border-gray-300'
                : 'border-current-400'
            "
          >
            <SynAvatar
              :src="rank?.avatar || rank?.avatarUrl"
              :name="rank?.name"
              custom-class="w-16 h-16"
            />
          </div>
        </div>

        <div class="w-full flex flex-col space-y-1 items-center -mt-3 z-30">
          <span
            class="
              flex
              justify-center
              items-center
              space-x-1
              p-2
              h-7
              w-max
              rounded-full
              border-2 border-white
            "
            :class="{
              'bg-orange-500 text-white': index === 0,
              'bg-gray-300 text-gray-600': index === 2,
              'bg-current-400 text-white': index === 1,
            }"
          >
            <span class="">{{ rank?.finishedTasks }}</span>
          </span>
        </div>

        <span class="text-current-600 text-center font-semibold">{{
          rank?.firstName
        }}</span>
        <span class="font-thin text-xs text-gray-500">
          {{ index === 0 ? 'Expert' : index === 1 ? 'Pro' : 'Star' }}
        </span>
      </RouterLink>
    </template>
    <template v-else>
      <div
        class="
          rounded
          col-span-3
          w-full
          h-full
          bg-gray-50
          flex-center flex-col
          text-gray-600
        "
      >
        <syn-icon
          name="groups"
          is-active
          custom-class="w-6 h-6 fill-gray-500"
        ></syn-icon>
        <span class="text-center px-4">{{
          $t('DOMAIN_MANAGEMENT_NO_ACTIVE_MEMBER_LABEL')
        }}</span>
      </div>
    </template>
  </div>
</template>
