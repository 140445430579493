import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import remoteConfigStore from '@/ticket/store/remote-config-store';
import { validateFile } from '@/ui/hooks/fileHook';
import { IUploadPreview } from '@/ui/components/file-viewer/update-preview-type';

export interface IAttachmentFileDetail extends IUploadPreview {
    blob: File | Blob;
    size: number;
    editStatus: 'ORIGINAL' | 'ADDED' | 'REMOVED';
}

export default function attachmentFilesComposables(defaultFiles: any[]) {
    const _remoteConfigStore = remoteConfigStore();
    const attachmentFiles = ref<IAttachmentFileDetail[]>(
        defaultFiles?.length > 0
            ? defaultFiles.map((file) => {
                  return {
                      id: file?.id || uuidv4(),
                      name: file?.name,
                      type: file?.type || file?.contentType,
                      fileUrl: file?.fileUrl
                          ? file?.fileUrl
                          : file?.imagePath
                          ? file?.imagePath
                          : file instanceof Blob
                          ? URL.createObjectURL(file)
                          : null,
                      creationTime: file?.creationTime || new Date(),
                      blob: file,
                      size: file?.size,
                      editStatus: 'ORIGINAL',
                  };
              })
            : []
    );
    const onChooseFiles = (event) => {
        onAddFiles(event?.target?.files);
    };
    const onAddFiles = (files: FileList) => {
        const allowExtensionDefault = _remoteConfigStore.allowExtensionDefault;
        const webTaskUploadContentTypes =
            _remoteConfigStore.webTaskUploadContentTypes;
        const webTaskUploadMaxFilesize =
            _remoteConfigStore.webTaskUploadMaxFilesize;

        Array.from(files).forEach((file) => {
            const isValid = validateFile(
                file,
                webTaskUploadContentTypes || allowExtensionDefault,
                webTaskUploadMaxFilesize || 25
            );
            if (isValid) {
                attachmentFiles.value.push({
                    id: uuidv4(),
                    name: file?.name,
                    type: file?.type,
                    fileUrl: URL.createObjectURL(file),
                    creationTime: new Date(),
                    blob: file,
                    size: file.size,
                    editStatus: 'ADDED',
                });
            }
        });
    };

    const pushImageFromNote = (image) => {
        attachmentFiles.value.push(image);
    };

    // AUDIO
    // audio playback
    const isOpenRecording = ref(false);
    const onCreateAudioDone = (
        recordings: {
            blob: Blob;
            isRecorder: true;
            name: string;
            size: number;
            src: string;
            type: string;
        }[]
    ) => {
        const newFiles = recordings.map((record) => {
            return {
                id: uuidv4(),
                blob: record.blob,
                fileUrl: record.src,
                type: 'audio/mpeg',
                creationTime: new Date(),
                size: record.size,
                editStatus: 'ADDED',
            } as IAttachmentFileDetail;
        });

        attachmentFiles.value = [...attachmentFiles.value, ...newFiles];

        isOpenRecording.value = false;
    };

    const onRemoveFile = (file) => {
        if (!attachmentFiles.value?.length || !file?.id) return;

        const fileIndex = attachmentFiles.value.findIndex(
            (o) => o.id == file.id
        );
        if (fileIndex > -1) {
            attachmentFiles.value[fileIndex].editStatus = 'REMOVED';
        }
    };
    return {
        attachmentFiles,
        onChooseFiles,
        onAddFiles,
        // AUDIO
        isOpenRecording,
        onCreateAudioDone,
        onRemoveFile,
        pushImageFromNote,
    };
}
