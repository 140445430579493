<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g id="Layer_2" data-name="Layer 2">
        <path
          d="m26.44 30h-20.88a4.57 4.57 0 0 1 -4.56-4.55v-14.45h30v14.45a4.57 4.57 0 0 1 -4.56 4.55zm-23.44-17v12.45a2.57 2.57 0 0 0 2.56 2.55h20.88a2.57 2.57 0 0 0 2.56-2.55v-12.45z"
        ></path>
        <path
          d="m31 13h-30v-4.42a4.57 4.57 0 0 1 4.56-4.58h20.88a4.57 4.57 0 0 1 4.56 4.58zm-28-2h26v-2.42a2.57 2.57 0 0 0 -2.56-2.58h-20.88a2.57 2.57 0 0 0 -2.56 2.58z"
        ></path>
        <rect height="6.06" rx="1" width="2" x="6.94" y="1.98"></rect>
        <rect height="6.06" rx="1" width="2" x="23.06" y="1.98"></rect>
        <rect height="6.06" rx="1" width="2" x="15" y="1.98"></rect>
        <circle cx="16" cy="21.08" r="1"></circle>
        <circle cx="11" cy="21.08" r="1"></circle>
        <circle cx="21" cy="21.08" r="1"></circle>
      </g>
    </g>
  </svg>
</template>
