import { translate } from '@/ui/plugins/i18n/myi18n';
import { ref } from 'vue';

export const visible = ref(false);

export const isContinueFn = ref(false);

type SynConfirmModalType = 'confirm' | 'notification' | 'warning';

export const type = ref('confirm' as SynConfirmModalType);
export const title = ref('');
export const content = ref('');
export const cancelLabel = ref('');
export const confirmLabel = ref('');

export interface ISynConfirmModalMetadata {
    type: SynConfirmModalType;
    title: string;
    content?: any;
    contentComponent?: any;
    contentComponentProps?: any;
    cancelLabel?: string;
    confirmLabel?: string;
    closeable?: boolean;
    confirmable?: boolean;
    iconName?: string;
    iconColor?: string;
    isHiddenIcon?: boolean;
    isContinueBtn?: boolean;
    options?: {
        confirmButtonColor: string;
    };
    footerButtons?: {
        type: 'TEXT' | 'FILL';
        labelCode: string;
        color?: string;
        iconName?: string;
        hasLoading?: boolean;
        onClick: Function;
    }[];
    hasConfigs?: boolean;
}
export class SynConfirmModalMetadata implements ISynConfirmModalMetadata {
    type: SynConfirmModalType;
    title: string;
    content?: any;
    contentComponent?: any;
    contentComponentProps?: any;
    cancelLabel?: string;
    confirmLabel?: string;
    closeable?: boolean;
    confirmable?: boolean;
    iconName?: string;
    iconColor?: string;
    isHiddenIcon?: boolean;
    isContinueBtn?: boolean;
    options?: {
        confirmButtonColor: string;
    };
    footerButtons?: {
        type: 'TEXT' | 'FILL';
        labelCode: string;
        color?: string;
        onClick: Function;
    }[];

    hasConfigs?: boolean;
    disableNextTime?: boolean;

    constructor(data) {
        this.type = data?.type;
        this.title = data?.title;
        this.content = data?.content;
        this.contentComponent = data?.contentComponent;
        this.contentComponentProps = data?.contentComponentProps;
        this.cancelLabel = data?.closeable
            ? data?.cancelLabel || translate('COMMON_LABEL_CANCEL')
            : '';
        this.confirmLabel = data?.confirmable
            ? data?.confirmLabel || translate('COMMON_LABEL_GOT_IT')
            : '';
        this.closeable = data?.closeable;
        this.confirmable = data?.confirmable;
        this.iconName = data?.iconName;
        this.iconColor = data?.iconColor;
        this.isHiddenIcon = data?.isHiddenIcon;
        this.options = data?.options;
        this.isContinueBtn = data?.isContinueBtn;
        this.footerButtons = data?.footerButtons;
        this.hasConfigs = data?.hasConfigs;
        this.disableNextTime = data?.disableNextTime;
    }
}
export const confirmModalMetadata = ref<SynConfirmModalMetadata>({
    type: 'notification',
    title: '',
    content: '',
});

export const settingGlobalModal = (data: ISynConfirmModalMetadata) => {
    confirmModalMetadata.value = new SynConfirmModalMetadata(data);
};

let resolveFn = (_value: boolean, _options?: any) => {};

export const ask = (): Promise<any> => {
    visible.value = true;
    isContinueFn.value = false;
    resolveFn && resolveFn(false);

    return new Promise((resolve) => {
        resolveFn = resolve;
    });
};

export const tell = (
    value,
    configs?: {
        disableNextTime?: boolean;
    }
) => {
    visible.value = false;
    const result = confirmModalMetadata.value?.hasConfigs
        ? {
              confirm: value,
              configs,
          }
        : value;
    resolveFn && resolveFn(result);
};
