<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { TaskLife, TaskSharingPermission } from '@/domain/enums/taskEnum';
import TaskCode from '@/ui/modules/task/detail/_commons/task-code/TaskCode.vue';
import TaskStateStepper from '@/ui/modules/task/components/TaskStateStepper.vue';
import TaskName from '@/ui/modules/task/detail/_commons/task-name/TaskName.vue';
import TaskDeadline from '@/ui/modules/task/detail/_commons/task-deadline/TaskDeadline.vue';
import TaskPlaning from '@/ui/modules/task/detail/_commons/task-planing/TaskPlaning.vue';
import TaskDomain from '@/ui/modules/task/detail/_commons/task-domain/TaskDomain.vue';
import TaskUrgency from '@/ui/modules/task/detail/_commons/task-urgency/TaskUrgency.vue';
import TaskDescription from '@/ui/modules/task/detail/_commons/task-description/TaskDescription.vue';
import TaskAssignee from '@/ui/modules/task/detail/_commons/task-assignee/TaskAssignee.vue';
import TaskAttachments from '@/ui/modules/task/detail/_commons/task-attachments/TaskAttachments.vue';
import TaskCreator from '@/ui/modules/task/detail/_commons/task-creator/TaskCreator.vue';
import TaskActivities from '@/ui/modules/task/detail/_commons/task-activities/TaskActivities.vue';
import TaskCommentBottom from '@/ui/modules/task/detail/TaskCommentBottom.vue';

const props = defineProps<{
  taskDetail: any;
  activities?: string[];
  permissions?: string[];
}>();

defineEmits([
  'onStateChange',
  'onPrivateChange',
  'onNameChange',
  'onAssigneeChange',
  'onDomainChange',
  'onPlanningChange',
  'onDeadlineChange',
  'onUrgencyChange',
  'onDescriptionChange',
  'onAttachmentsChange',
]);

const preventEdit = true;

const activeActivity = ref<string>('COMMENT');

const taskUsers = computed(() => {
  const userById = {};

  if (props.taskDetail?.creatorId) {
    userById[props.taskDetail?.creatorId] = {
      id: props.taskDetail?.creatorId,
      name: props.taskDetail?.creatorFullName,
      avatar: props.taskDetail?.creatorAvatarUrl,
    };
  }

  if (props.taskDetail?.assigneeId && !userById[props.taskDetail?.assigneeId]) {
    userById[props.taskDetail?.assigneeId] = {
      id: props.taskDetail?.assigneeId,
      name: props.taskDetail?.assigneeFullName,
      avatar: props.taskDetail?.assigneeAvatarUrl,
    };
  }

  props.taskDetail?.comments?.forEach((cmt) => {
    if (cmt?.createdBy && !userById[cmt?.createdBy]) {
      userById[cmt?.createdBy] = {
        id: cmt?.createdBy,
        name: cmt?.createdByName,
        avatar: cmt?.createdByAvatarUrl,
      };
    }
  });

  return Object.values(userById);
});
const taskGroups = computed(() => {
  const groups: any[] = [];

  if (props.taskDetail?.assigneeId) {
    groups.push({
      id: props.taskDetail?.groupId,
      name: props.taskDetail?.groupName,
      avatarUrl: props.taskDetail?.groupAvatarUrl,
    });
  }

  return groups;
});

const isActiveTask = computed(() =>
  [TaskLife.Todo, TaskLife.InProcess, TaskLife.Finished]?.includes(
    props.taskDetail?.taskLife
  )
);

const canAddDescription = computed(
  () =>
    !props.taskDetail?.description &&
    props.permissions?.includes(TaskSharingPermission.TASK_ADD_DESCRIPTION)
);
const canModifyDescription = computed(
  () =>
    props.taskDetail?.description &&
    props.permissions?.includes(TaskSharingPermission.TASK_MODIFY_DESCRIPTION)
);

watch(
  () => props.taskDetail?.id,
  () => {
    activeActivity.value = 'COMMENT';
  }
);
</script>

<template>
  <div>
    <!--HEADER-->
    <div class="flex items-center justify-between gap-3 px-4 py-2">
      <!--TASK CODE-->
      <TaskCode :task="taskDetail" />

      <!--TASK STATUS STEPS-->
      <div class="flex-1" :class="{ 'flex-center': isActiveTask }">
        <TaskStateStepper
          :task-detail="taskDetail"
          :readonly="
            !permissions?.includes(TaskSharingPermission.TASK_MODIFY_STATUS) &&
            preventEdit
          "
          @on-change-state="$emit('onStateChange', $event)"
        />
      </div>

      <!--TASK COMMON ACTIONS-->
      <div></div>
    </div>

    <!--BODY-->
    <div class="px-4 relative">
      <!--CREATOR + TASK NAME-->
      <div class="py-2 flex items-center gap-2">
        <TaskCreator :task="taskDetail" :users="taskUsers" />

        <div class="flex-1">
          <TaskName
            :task="taskDetail"
            :readonly="
              !permissions?.includes(TaskSharingPermission.TASK_MODIFY_TITLE) &&
              preventEdit
            "
            @on-name-change="$emit('onNameChange', $event)"
          />
        </div>
      </div>

      <!--ASSIGNEE-->
      <div class="flex items-center space-x-6 mt-4">
        <div class="w-20 text-xs text-gray-500">
          {{ $t('TASK_LABEL_ASSIGNEE') }}
        </div>
        <TaskAssignee
          :task="taskDetail"
          :users="taskUsers"
          :groups="taskGroups"
          readonly
          @on-assignee-change="$emit('onAssigneeChange', $event)"
        />
      </div>

      <!--DOMAIN-->
      <div class="flex items-center space-x-6 mt-4">
        <div class="w-20 text-xs text-gray-500">
          {{ $t('DOMAIN_LABEL_TITLE') }}
        </div>
        <TaskDomain
          :task="taskDetail"
          readonly
          class="font-medium"
          @on-domain-change="$emit('onDomainChange', $event)"
        />
      </div>

      <!--  URGENCY + STATE -->
      <div class="flex items-center space-x-6 mt-4">
        <div class="w-20 text-xs text-gray-500">
          {{ $t('TASK_TABLE_LABEL_URGENCY') }}
        </div>
        <TaskUrgency
          :task="taskDetail"
          :readonly="
            !permissions?.includes(TaskSharingPermission.TASK_MODIFY_URGENCY) &&
            preventEdit
          "
          @on-urgency-change="$emit('onUrgencyChange', $event)"
        />
      </div>

      <!--PLAN TODAY-->
      <div
        v-if="
          taskDetail?.assigneeId && taskDetail?.taskLife !== TaskLife.Finished
        "
        class="flex items-center space-x-6 mt-4"
      >
        <div class="w-20 text-xs text-gray-500">
          {{ $t('TASK_FILTER_LABEL_PLANNED') }}
        </div>
        <TaskPlaning
          :task="taskDetail"
          readonly
          @on-planning-change="$emit('onPlanningChange', $event)"
          @on-state-change="$emit('onStateChange', $event)"
        />
      </div>

      <!--  DEADLINE -->
      <div class="flex items-center space-x-6 mt-4">
        <div class="w-20 text-xs text-gray-500">
          {{ $t('TASK_TABLE_LABEL_DEADLINE') }}
        </div>
        <TaskDeadline
          :task="taskDetail"
          :readonly="
            !permissions?.includes(
              TaskSharingPermission.TASK_MODIFY_DEADLINE
            ) && preventEdit
          "
          @on-deadline-change="$emit('onDeadlineChange', $event)"
        />
      </div>

      <!--  DESCRIPTION -->
      <div class="flex items-start space-x-6 mt-4">
        <div class="w-20 text-xs text-gray-500 pt-1.5">
          {{ $t('COMMON_LABEL_DESCRIPTION') }}
        </div>
        <div class="flex-1">
          <TaskDescription
            :task="taskDetail"
            :readonly="
              !(canAddDescription || canModifyDescription) && preventEdit
            "
            @on-description-change="$emit('onDescriptionChange', $event)"
          />
        </div>
      </div>

      <!-- FILES -->
      <div class="mt-6">
        <TaskAttachments
          :task="taskDetail"
          :task-files="taskDetail?.attachment?.files"
          :task-notes="taskDetail?.attachment?.notes"
          :readonly="
            !permissions?.includes(TaskSharingPermission.TASK_ADD_FILE) &&
            preventEdit
          "
        />
      </div>

      <!--COMMENTS + LOGS-->
      <TaskActivities
        v-model:activeActivity="activeActivity"
        :activities="activities"
        :task="taskDetail"
        :task-checklist="taskDetail?.checklist"
        :task-comments="taskDetail?.comments || []"
        :users="taskUsers"
        is-sharing
        readonly
        class="mt-4 text-sm"
      />
    </div>

    <!--FOOTER COMMENT-->
    <div
      v-if="!preventEdit"
      class="max-h-80 px-4 py-3 sticky bottom-0 bg-white"
    >
      <TaskCommentBottom
        :task-id="taskDetail?.id"
        :current-task="taskDetail"
        :users="taskUsers"
      />
    </div>
  </div>
</template>
