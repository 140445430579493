import { computed, onMounted, ref, watch } from 'vue';
import dayjs from 'dayjs';
import {
    EFilterTaskDefault,
    getFilterPayloadByChildTab,
    getFilterPayloadByOwner,
    LIST_FILTER_TASK_DEFAULT,
} from '@/application/constants/task.const';
import TaskService from '@/application/services/task/TaskService';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import { TaskActionType } from '@/domain/enums/taskEnum';
import taskListRealtime from '@/ui/modules/task/composables/task-list-realtime';
import { ETaskListModule } from '@/application/types/task/task.types';
import {
    addOrUpdateReactiveList,
    removeItemOutOfReactiveList,
} from '@/ui/hooks/commonFunction';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import taskStore from '@/store/task';
import { useElementVisibility } from '@vueuse/core';
import groupStore from '@/store/group';

const FULL_CALENDAR_VIEW_MODE = {
    DAY_GRID_MONTH: 'dayGridMonth',
    DAY_GRID_WEEK: 'dayGridWeek',
    DAY_GRID_DAY: 'timeGridDay',
    LIST_WEEK: 'listWeek',
};
export default function planTaskCalendarByOwner(props: {
    sourceType: ETaskListModule;
    listOwnerId: number;
}) {
    const filterPayloadByOwner = getFilterPayloadByOwner(
        props.listOwnerId,
        props.sourceType
    );
    const planTodayTasks = ref<any[]>([]);
    const taskTimeBlockingSingleRef = ref<any>(null);
    const isLoadingChart = ref<boolean>(true);
    const defaultTaskList = ref<any[]>([]);
    const currentViewMode = ref<string>(FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY);
    const isTimeBlockingMode = computed<boolean>(() => {
        return currentViewMode.value == FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY;
    });

    const unscheduleTasks = computed(() => {
        if (isTimeBlockingMode.value)
            return defaultTaskList.value?.filter(
                (o) =>
                    !o?.taskSchedule?.doItToday &&
                    !o?.taskSchedule?.finishItToday
            );

        return defaultTaskList.value?.filter((o) => !o?.scheduleTime);
    });

    let { unsubscribeCallbacks } = listenFirebaseRealtime();
    const { getDynamicModel } = taskListRealtime();
    const getFirebaseKey = () => {
        if (props.sourceType == ETaskListModule.DOMAIN) return 'domain';

        if (props.sourceType == ETaskListModule.GROUP) return 'group';

        return 'user';
    };

    const addTaskIntoEventList = (updatedTask, addConditionFn?) => {
        addOrUpdateReactiveList(planTodayTasks, updatedTask, addConditionFn);
        addOrUpdateEventByTask(updatedTask);
    };
    const removeTaskOutOfEventList = (taskId) => {
        removeItemOutOfReactiveList(planTodayTasks, taskId);
        removeEventByTask(taskId);
    };
    const onPushTaskOutOfList = (taskId) => {
        removeItemOutOfReactiveList(defaultTaskList, taskId);

        removeTaskOutOfEventList(taskId);
    };
    const onPushTaskIntoList = (task) => {
        addOrUpdateReactiveList(defaultTaskList, task);

        addTaskIntoEventList(task, (data) => {
            return !(isTimeBlockingMode.value
                ? data?.isPlannedToTreat
                : data?.scheduleTime);
        });
    };
    const onUpdateTaskInList = (updatedTask) => {
        console.log('🚀 Tictop ~ updatedTask:', updatedTask);
        const indexDf = defaultTaskList.value?.findIndex(
            (t) => t?.id == updatedTask?.id
        );
        if (indexDf > -1) {
            defaultTaskList.value[indexDf] = {
                ...defaultTaskList.value[indexDf],
                ...updatedTask,
            };
        }

        if (isTimeBlockingMode.value) {
            if (!updatedTask?.isPlannedToTreat) {
                removeTaskOutOfEventList(updatedTask?.id);
            } else if (
                updatedTask?.isPlannedToTreat ||
                updatedTask?.isPlannedToFinish
            ) {
                addTaskIntoEventList(updatedTask);
            }
        } else {
            if (!updatedTask?.scheduleTime) {
                removeTaskOutOfEventList(updatedTask?.id);
            } else {
                addTaskIntoEventList(updatedTask);
            }
        }
    };
    const onListenFirebase = () => {
        const LatestTaskActivityFirebaseModel = getDynamicModel(
            getFirebaseKey(),
            props.listOwnerId,
            'latestTaskActivity'
        );

        unsubscribeCallbacks.value.push(
            LatestTaskActivityFirebaseModel.onChange(async (data) => {
                if (!data?.taskId) return;

                switch (data?.taskAction) {
                    case TaskActionType.Delete:
                    case TaskActionType.Finished:
                    case TaskActionType.MoveOut:
                        onPushTaskOutOfList(data?.taskId);
                        break;

                    default:
                        {
                            const res =
                                await TaskService.getInstance().getDetailTaskIncognitoById(
                                    data?.taskId
                                );
                            const task = res?.result;
                            if (
                                task?.assigneeId !== props.listOwnerId ||
                                !task?.id
                            )
                                return;

                            const updatedTask = {
                                ...task,
                                isPlannedToTreat: task?.taskSchedule?.doItToday,
                                isPlannedToFinish:
                                    task?.taskSchedule?.finishItToday,
                                urgency: getUrgencyValue(
                                    task.priority,
                                    task.important
                                ),
                            };

                            switch (data?.taskAction) {
                                case TaskActionType.AddNew:
                                case TaskActionType.MoveIn:
                                    {
                                        onPushTaskIntoList(updatedTask);
                                    }
                                    break;

                                default:
                                    {
                                        onUpdateTaskInList(updatedTask);
                                    }
                                    break;
                            }
                        }
                        break;
                }
            })
        );
    };

    const isReady = ref<boolean>(false);
    const initComponent = async () => {
        onListenFirebase();
        await getTasks();
        isReady.value = true;
        if (!planTodayTasks.value) {
            isLoadingChart.value = false;
            return;
        }
        initCalendar();
    };
    onMounted(() => {
        if (!containerElmRef.value || targetIsVisible.value) initComponent();
    });

    const getDefaultTaskList = async () => {
        const res = await TaskService.getInstance().getTaskWithFilter(
            getFilterPayloadByChildTab(
                filterPayloadByOwner,
                LIST_FILTER_TASK_DEFAULT[EFilterTaskDefault.DEFAULT]
            )
        );
        defaultTaskList.value = res?.result;
    };
    const getTasks = async () => {
        if (isTimeBlockingMode.value) {
            if (props.isHasDefaultList) getDefaultTaskList();

            const res = await TaskService.getInstance().getTaskWithFilter(
                getFilterPayloadByChildTab(
                    filterPayloadByOwner,
                    LIST_FILTER_TASK_DEFAULT[EFilterTaskDefault.DO_TODAY]
                )
            );

            planTodayTasks.value = res?.result?.map((t) => {
                return {
                    ...t,
                    urgency: getUrgencyValue(t?.priority, t?.important),
                };
            });
        } else {
            const res = await TaskService.getInstance().getTaskWithFilter(
                getFilterPayloadByChildTab(
                    filterPayloadByOwner,
                    LIST_FILTER_TASK_DEFAULT[EFilterTaskDefault.DEFAULT]
                )
            );
            defaultTaskList.value = res?.result?.map((t) => {
                return {
                    ...t,
                    urgency: getUrgencyValue(t?.priority, t?.important),
                };
            });

            planTodayTasks.value = defaultTaskList.value.filter(
                (t) => t?.scheduleTime
            );
        }

        setTimeout(() => {
            updateEventSources();
        });
    };

    const updateEventSources = () => {
        if (
            taskTimeBlockingSingleRef.value &&
            typeof taskTimeBlockingSingleRef.value?.updateEventSources ==
                'function'
        )
            taskTimeBlockingSingleRef.value.updateEventSources();
    };

    const removeEventByTask = (taskId) => {
        if (
            taskTimeBlockingSingleRef.value &&
            typeof taskTimeBlockingSingleRef.value?.removeEventByTask ==
                'function'
        )
            taskTimeBlockingSingleRef.value.removeEventByTask(taskId);
    };
    const addOrUpdateEventByTask = (task) => {
        if (
            taskTimeBlockingSingleRef.value &&
            typeof taskTimeBlockingSingleRef.value?.addOrUpdateEventByTask ==
                'function'
        )
            taskTimeBlockingSingleRef.value.addOrUpdateEventByTask(task);
    };
    const initCalendar = () => {
        console.log(
            '🚀 Tictop ~ taskTimeBlockingSingleRef.value:',
            taskTimeBlockingSingleRef.value
        );
        if (
            taskTimeBlockingSingleRef.value &&
            typeof taskTimeBlockingSingleRef.value?.initCalendar == 'function'
        )
            taskTimeBlockingSingleRef.value.initCalendar();
    };

    const onChangeViewMode = async (viewMode) => {
        currentViewMode.value = viewMode;

        // ganttChartDetail.value.change_view_mode(viewMode);

        planTodayTasks.value = [];
        await getTasks();

        updateEventSources();

        if (
            taskTimeBlockingSingleRef.value &&
            typeof taskTimeBlockingSingleRef.value?.onChangeViewMode ==
                'function'
        )
            taskTimeBlockingSingleRef.value.onChangeViewMode(viewMode);
    };
    const allGroupByIds = computed(() => {
        return {
            ...groupStore().allGroupByIds,
            ...groupStore().myGroupByIds,
        };
    });

    const changeStartEndTime = async (event) => {
        const task = event?.extendedProps;
        const start = event?.start;
        const end = event?.end;

        const updatedTask = new TaskDetailClass(task);

        if (isTimeBlockingMode.value) {
            switch (props.sourceType) {
                // case ETaskListModule.DOMAIN:
                //     {
                //         if (updatedTask?.domainId !== props.listOwnerId)
                //             updatedTask.changeDomain({
                //                 domainId: props.listOwnerId,
                //                 assigneeId: updatedTask?.assigneeId,
                //                 groupId: updatedTask?.groupId,
                //                 taskId: updatedTask?.id,
                //             });
                //     }
                //     break;
                // case ETaskListModule.GROUP:
                //     {
                //         if (updatedTask?.groupId !== props.listOwnerId)
                //             updatedTask.changeAssignee(
                //                 props.listOwnerId,
                //                 updatedTask?.assigneeId
                //             );
                //     }
                //     break;
                case ETaskListModule.USER:
                    {
                        if (updatedTask?.assigneeId !== props.listOwnerId) {
                            let groupId = updatedTask?.groupId;
                            if (groupId) {
                                const groupInfo = allGroupByIds.value[groupId];

                                groupId = groupInfo?.groupUsers?.some(
                                    (u) => u?.userId == props.listOwnerId
                                )
                                    ? groupId
                                    : undefined;
                            }

                            updatedTask.changeAssignee(
                                groupId ? groupId : undefined,
                                props.listOwnerId
                            );
                        }
                    }
                    break;

                default:
                    break;
            }

            if (event?.allDay) {
                await updatedTask.doItToday(
                    true,
                    {
                        isUpdatePlanningTime: true,
                        planningStartTime: null,
                        planningEndTime: null,
                    },
                    updatedTask?.taskSchedule?.finishItToday
                );
            } else
                await updatedTask.doItToday(
                    true,
                    {
                        isUpdatePlanningTime: true,
                        planningStartTime: dayjs.utc(start).toISOString(),
                        planningEndTime: end
                            ? dayjs.utc(end).toISOString()
                            : dayjs.utc(start).add(1, 'hour').toISOString(),
                    },
                    updatedTask?.taskSchedule?.finishItToday
                );

            defaultTaskList.value = defaultTaskList.value?.filter(
                (t) => t?.id !== updatedTask.id
            );
        } else {
            const scheduleTime = dayjs(end || start)
                .startOf('day')
                .utc(true)
                .format();
            await updatedTask.changeStartEndTime({
                startTime: dayjs(start).startOf('day').utc(true).format(),
                scheduleTime,
                scheduleByHour: false,
                isFinishToday: dayjs(scheduleTime).isToday(),
            });
        }

        taskStore().updateAllTaskByIds(updatedTask.id, updatedTask);
    };

    const containerElmRef = ref<any>(null);
    const targetIsVisible = useElementVisibility(containerElmRef);
    watch(
        () => targetIsVisible.value,
        (value) => {
            if (value && !isReady.value) initComponent();
        }
    );
    return {
        currentViewMode,
        isTimeBlockingMode,
        planTodayTasks,
        taskTimeBlockingSingleRef,
        isLoadingChart,
        unscheduleTasks,
        defaultTaskList,
        containerElmRef,
        onChangeViewMode,
        getDefaultTaskList,
        changeStartEndTime,
    };
}
