<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import WorkflowRepository from '@/application/repositories/WorkflowRepository';
import useSynTableCustom from '@/ui/common/molecules/SynTableCustom/use-syn-table-custom';
import WorkflowStepTypeDetailModal from '@/ui/modules/workflow/workflow-step-type/WorkflowStepTypeDetailModal.vue';
import { IWorkflowStepType } from '@/application/types/workflow/workflow.types';
import WorkflowStepTypeEntity from '@/domain/entities/workflow/WorkflowStepTypeEntity';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const props = withDefaults(
  defineProps<{
    searchText: string;
    actionList?: string[];
  }>(),
  {
    filterSteps: () => [],
    departmentId: undefined,
    domainId: undefined,
    groupId: undefined,
    actionList: () => ['DUPLICATE', 'EDIT', 'CHOOSE'],
  }
);
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'choose', step: any): void;
  (e: 'duplicateStep', step: any): void;
  (e: 'reload'): void;
}>();

const isLoadingList = ref<boolean>(false);
const allWorkflowStepTypes = ref<IWorkflowStepType[]>([]);

const initComponent = async () => {
  isLoadingList.value = true;
  const res = await WorkflowRepository.getInstance().getAllStepType();
  allWorkflowStepTypes.value = arrayOrderBy(
    res?.result,
    ['isSystem'],
    'desc'
  ) as IWorkflowStepType[];

  initComposable(allWorkflowStepTypes.value);
  isLoadingList.value = false;
};

initComponent();

const onLoadMore = () => {};
const {
  initComposable,
  showingDataList,
  sortDataOrderByKey,
  genSortDataList,
  setFilterDataByKey,
  onFilterByKey,
  refreshComposable,
} = useSynTableCustom();

watch(
  () => props.searchText,
  () => {
    setFilterDataByKey('keySearch', props.searchText);
    onFilterByKey();
  }
);
const onClickSort = (key, name, orderFunction) => {
  genSortDataList({ key, name, orderFunction });
};

const reload = () => {
  initComponent();
  emit('reload');
};

const canLoadMore = computed<boolean>(() => {
  return false;
});

const hasJustCreate = (data) => {
  const createdTime = new Date(data?.createdTime).getTime();

  return Date.now() - createdTime < 3 * 60 * 60 * 1000;
};

const currentStepDetail = ref<any>({});
const onClickDetail = (step, viewMode) => {
  viewStepMode.value = viewMode || 'VIEW';
  currentStepDetail.value = step;
};

const refresh = () => {
  refreshComposable(allWorkflowStepTypes.value);
};

const viewStepMode = ref<'VIEW' | 'EDIT'>('VIEW');

const updateStepSuccess = (updatedStep) => {
  const updatedIndex = allWorkflowStepTypes.value?.findIndex(
    (step) => step?.id == updatedStep?.id
  );

  if (updatedIndex > -1) allWorkflowStepTypes.value[updatedIndex] = updatedStep;
  else {
    allWorkflowStepTypes.value = [updatedStep, ...allWorkflowStepTypes.value];
  }

  refresh();
  currentStepDetail.value = null;
};
const actionDataListByKey = {
  DUPLICATE: {
    key: 'DUPLICATE',
    iconName: 'duplicate',
    color: '',
    handleClick: (step) => {
      emit('duplicateStep', step);
    },
  },
  EDIT: {
    key: 'EDIT',
    iconName: 'edit',
    color: '',
    handleClick: async (step) => {
      const editable = await new WorkflowStepTypeEntity(step).checkEditable();
      if (!editable) return;

      onClickDetail(step, 'EDIT');
    },
  },
  CHOOSE: {
    key: 'CHOOSE',
    iconName: 'ChevronDoubleRight',
    color: '',
    handleClick: (step) => {
      emit('choose', step);
    },
  },
  REMOVE: {
    key: 'REMOVE',
    iconName: 'trash',
    color: 'red',
    handleClick: async (step) => {
      const res = await new WorkflowStepTypeEntity(step).remove();

      if (!res) return;

      allWorkflowStepTypes.value = allWorkflowStepTypes.value?.filter(
        (s) => s?.id !== step?.id
      );
      refresh();
    },
  },
};
const actionDataList = computed<any[]>(() => {
  return props.actionList?.map((key) => actionDataListByKey[key]);
});

defineExpose({
  refresh,
  initComponent,
  updateStepSuccess,
});
</script>
<template>
  <div
    v-scroll-infinite="onLoadMore"
    class="h-full overflow-auto relative small-scrollbar"
  >
    <SynTableCustom class="rounded-none">
      <template #header>
        <SynTr class="rounded-none">
          <!--   NAME       -->
          <SynTh
            :label="$t('COMMON_LABEL_NAME')"
            is-sort
            style="width: 0; min-width: 10rem"
            :order-data="sortDataOrderByKey['name']"
            @on-sort="onClickSort('name', $t('TASK_TABLE_LABEL_TASKS'), 'name')"
          />

          <SynTh
            :label="$t('COMMON_LABEL_TOTAL_USED_TIME') || 'Total used'"
            position-text-header="justify-center"
          />
          <SynTh
            :label="$t('TASK_TABLE_LABEL_TYPE') || 'Total used'"
            position-text-header="justify-center"
          />

          <!--   RELOAD       -->
          <SynTh is-center class="w-40">
            <div class="flex-1 flex items-center justify-end">
              <SynIcon
                class="
                  text-gray-600
                  fill-gray-500
                  cursor-pointer
                  hover:fill-current
                "
                name="reload"
                :title="$t('TASK_LIST_LABEL_RELOAD') || 'Reload'"
                @click="reload"
              />
            </div>
          </SynTh>
        </SynTr>
      </template>
      <template #body>
        <syn-table-tr-loading v-if="isLoadingList" class="h-full">
          <div v-for="item in 3" :key="item" class="table-cell p-2">
            <div class="p-2 w-full bg-gray-200 rounded-md"></div>
          </div>
        </syn-table-tr-loading>
        <template v-else-if="showingDataList?.length > 0">
          <SynTr
            v-for="step in showingDataList"
            :key="step?.id"
            class="
              hover:bg-gray-50
              relative
              hover-to-show__parent
              hover-to-hidden__parent
            "
          >
            <SynTd class="max-w-md relative" style="padding: 0">
              <div
                class="absolute top-0 right-0 flex-center space-x-1"
                style="font-size: 0.6rem"
              >
                <span
                  v-if="hasJustCreate(step)"
                  class="bg-current text-white px-1.5 rounded-b-md z-10"
                  >New</span
                >
              </div>

              <div class="flex-center w-full">
                <div
                  class="
                    flex-1
                    px-2
                    py-1
                    overflow-hidden
                    w-full
                    h-full
                    text-overflow-hidden
                  "
                  @click.stop.prevent="onClickDetail(step, 'VIEW')"
                >
                  <div
                    class="flex flex-wrap flex-col space-y-1 break-words w-full"
                  >
                    <span
                      :title="step?.name"
                      class="
                        w-full
                        py-1
                        hover:text-current-500
                        cursor-pointer
                        font-semibold
                      "
                    >
                      {{ step?.name }}
                    </span>
                  </div>
                </div>
              </div>
            </SynTd>

            <SynTd>
              <div class="flex justify-center">
                <span>{{ step?.usedStepTemplates?.length || 0 }}</span>
              </div>
            </SynTd>
            <SynTd>
              <div class="flex justify-center">
                <SynTag
                  v-if="step?.isSystem"
                  size="circle-small"
                  custom-class="px-2 py-1 border border-current-100 bg-current text-white"
                >
                  <span>{{ $t('COMMON_LABEL_SYSTEM') }}</span>
                </SynTag>
                <SynTag
                  v-else
                  size="circle-small"
                  custom-class="px-2 py-1 border border-current-100"
                >
                  <span>{{ $t('LABEL_CUSTOM_DATE') }}</span>
                </SynTag>
              </div>
            </SynTd>
            <SynTd>
              <div class="flex-center space-x-1">
                <div
                  v-for="action in actionDataList"
                  :key="action?.key"
                  class="
                    w-8
                    h-8
                    rounded-md
                    cursor-pointer
                    flex-center
                    border border-gray-100
                    hover:shadow
                  "
                  @click="action?.handleClick(step)"
                >
                  <SynIcon
                    :name="action?.iconName"
                    :custom-class="
                      action?.color ? `h-4 w-4 fill-${action?.color}-500` : ''
                    "
                  />
                </div>
              </div>
            </SynTd>
          </SynTr>
          <slot name="footer"></slot>
        </template>
        <template v-else>
          <slot name="no-data"></slot>
        </template>
      </template>
    </SynTableCustom>
    <div v-if="canLoadMore" class="flex-center w-full p-2">
      <button
        class="px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300 text-sm"
        @click="onLoadMore"
      >
        {{ $t('GED_SHOW_MORE') || 'Show more' }}
      </button>
    </div>
  </div>

  <WorkflowStepTypeDetailModal
    v-if="currentStepDetail?.id"
    :view-mode="viewStepMode"
    :step-type-detail="currentStepDetail"
    @close="currentStepDetail = {}"
    @update-success="updateStepSuccess"
  />
</template>
