import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import {
    isOpenTaskDrawer,
    lastAutoCreateTime,
} from '@/ui/modules/task/task-global-state';
import { TaskStatus } from '@/domain/entities/TaskType';
// import chatStore from '@/store/chat';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import TaskService from '@/application/services/task/TaskService';

interface ITabDetail {
    id: string | number;
    code: string;
    name: string;
    tabType: 'NEW' | 'DETAIL';
    autoCreate?: boolean;
}

export const MAX_TOTAL_ITEM_IN_DRAWER = 15;

export default defineStore({
    id: storeId.taskDrawer,
    state: () => ({
        isOpenDrawer: false,
        currentTabId: 0 as number | string,
        allTabIds: [] as string[],
        allTabByIds: {} as any,
        _taskService: TaskService.getInstance(),
        lastUpdateTaskReminderTime: {} as any,
    }),
    getters: {
        currentTabs(state) {
            return state.allTabByIds &&
                Object.values(state.allTabByIds).length > 0
                ? Object.values(state.allTabByIds)?.map((task: any) => {
                      return {
                          id: task.id,
                          tabCode: task?.tabCode?.split('-').pop() || 'New tab',
                          taskCode: task?.code,
                          taskName: task?.taskName,
                          tabType: task?.tabType,
                          lastTimeAccess: task?.lastTimeAccess,
                      };
                  })
                : [];
        },
    },
    actions: {
        async getDetailById(taskId: number) {
            let taskDetail;
            if (!taskDetail) {
                try {
                    taskDetail = await this._taskService.getDetailByID(taskId);
                } catch (error) {
                    const errorObj = error as any;
                    taskDetail = {
                        id: taskId,
                        errorCode:
                            errorObj?.error?.code > 0
                                ? errorObj?.error?.code
                                : TaskStatus.Unavailable,
                    };
                }
            }
            return taskDetail;
        },
        async getOriginDataFromLocalStorage() {
            const currentTaskDrawer =
                await MyOrganizationSingleton.getInstance().getCurrentTaskDrawer();

            this.currentTabId = currentTaskDrawer?.currentTabId;
            this.allTabByIds = currentTaskDrawer?.allTabByIds;
            this.allTabIds = currentTaskDrawer?.allTabIds?.length
                ? [...currentTaskDrawer?.allTabIds]
                : [];
        },
        pushCurrentIds(
            { id, code, name, tabType, autoCreate }: ITabDetail,
            isOpenGlobalPanel = true
        ) {
            const tabId = `${id}`;
            if (!tabId) return;
            this.currentTabId = tabId;

            // const isExistedInTabList = this.allTabByIds
            //   ? Object.prototype.hasOwnProperty.call(this.allTabByIds, id)
            //   : false;

            let tabCode = code;
            if (tabType == 'NEW') {
                const currentNewTabs = this.allTabByIds
                    ? Object.values(this.allTabByIds)
                          ?.filter((tab: any) => tab.tabType == 'NEW')
                          ?.map((tab: any) =>
                              parseInt(tab.tabCode?.split(' ')?.pop())
                          )
                    : [];
                tabCode = genTabCode(currentNewTabs);
            }

            this.allTabByIds = {
                ...this.allTabByIds,
                [id]: {
                    id: tabId,
                    tabCode: tabCode,
                    taskName: name,
                    name: name,
                    tabType: tabType,
                    lastTimeAccess: Date.now(),
                },
            };
            // if (new Set(this.allTabIds).has(tabId) && tabId)
            //   this.allTabIds = this.allTabIds?.filter((id) => id !== tabId);

            // this.allTabIds.unshift(tabId);
            if (new Set(this.allTabIds).has(tabId)) {
                const index = this.allTabIds?.findIndex((id) => id == tabId);
                if (index >= MAX_TOTAL_ITEM_IN_DRAWER - 1) {
                    this.allTabIds = this.allTabIds?.filter(
                        (id) => id !== tabId
                    );
                    this.allTabIds.unshift(tabId);
                }
            } else if (tabId) {
                // if (this.allTabIds?.length >= MAX_TOTAL_ITEM_IN_DRAWER) {
                //   const overTotalItem =
                //     this.allTabIds?.length - MAX_TOTAL_ITEM_IN_DRAWER + 1;

                //   const detailTabIds = this.allTabIds?.filter(
                //     (tabId) => this.allTabByIds[tabId]?.tabType === 'DETAIL'
                //   );

                //   const removeTabIds: string[] =
                //     detailTabIds?.length > overTotalItem
                //       ? detailTabIds?.slice(
                //           detailTabIds?.length - overTotalItem,
                //           detailTabIds?.length
                //         )
                //       : detailTabIds;

                //   if (removeTabIds?.length > 0) {
                //     this.allTabIds = this.allTabIds?.filter(
                //       (_id) => !removeTabIds?.some((id) => id == _id)
                //     );

                //     for (const removeTabId of removeTabIds) {
                //       delete this.allTabByIds[removeTabId];
                //     }
                //   }
                // }
                this.allTabIds.unshift(tabId);
            }

            // chatStore().closeBubbleChat();

            if (isOpenGlobalPanel) isOpenTaskDrawer.value = true;
            if (autoCreate) lastAutoCreateTime.value = Date.now();

            // update local storage
            this.onUpdateCache();
        },
        onSortTabs(oIndex, nIndex) {
            const oldIndex = oIndex - 1;
            const newIndex = nIndex - 1;
            const oldItem = this.allTabIds[oldIndex];
            if (!oldItem) return;

            this.allTabIds?.splice(oldIndex, 1);
            this.allTabIds?.splice(newIndex, 0, oldItem);
        },
        updateCurrentTabId(tabId) {
            this.currentTabId = tabId;

            this.onUpdateCache();
        },
        updateDataByTabId(tabId, taskDetail) {
            this.allTabByIds = {
                ...this.allTabByIds,
                [tabId]: {
                    ...this.allTabByIds[tabId],
                    taskName: taskDetail.name,
                    tabCode: taskDetail.code,
                    lastTimeAccess: Date.now(),
                },
            };
            this.onUpdateCache();
        },
        updateLastTimeAccessByTabId(tabId) {
            this.allTabByIds = {
                ...this.allTabByIds,
                [tabId]: {
                    ...this.allTabByIds[tabId],
                    lastTimeAccess: Date.now(),
                },
            };
            this.onUpdateCache();
        },

        replaceCurrentIds(tabId, taskDetail) {
            const taskId = `${taskDetail?.id}`;
            if (!tabId || !taskId) return;

            const index = this.allTabIds?.findIndex((id) => id == tabId);
            if (!new Set(this.allTabIds).has(taskId))
                this.allTabIds[index] = taskId;

            this.allTabByIds = {
                ...this.allTabByIds,
                [taskId]: {
                    ...taskDetail,
                    taskName: taskDetail.name,
                    tabCode: taskDetail.code,
                    tabType: 'DETAIL',
                    lastTimeAccess: Date.now(),
                },
            };
            if (tabId == this.currentTabId) this.currentTabId = taskId;

            delete this.allTabByIds[tabId];

            // update local storage
            this.onUpdateCache();
        },
        onCloseTab(tabId) {
            if (!tabId) return;

            const index = this.allTabIds.findIndex(
                (id) => `${id}` == `${tabId}`
            );

            delete this.allTabByIds[tabId];
            this.allTabIds = this.allTabIds?.filter(
                (id) => `${id}` !== `${tabId}`
            );

            if (tabId == this.currentTabId) {
                const length = this.allTabIds.length;

                this.currentTabId =
                    index < length
                        ? this.allTabIds[index]
                        : this.allTabIds[length - 1];
            }
            this.onUpdateCache();
        },
        async closeAllTab() {
            this.allTabByIds = {};
            this.allTabIds = [];
            this.currentTabId = 0;

            MyOrganizationSingleton.setOrganizationTaskDrawer({
                currentTabId: 0,
                allTabByIds: [],
                allTabIds: [],
            });
        },
        // updateTaskById(taskId) {
        //   if(!taskId) return;
        //   const taskId = tab.id;
        //   console.log('🚀 ~ file: drawer.ts ~ line 41 ~ onCloseTab ~ tab', tab);
        //   this.currentTasks = this.currentTasks.filter(o => o !== tab.id);
        //   if (taskId == this.currentTaskId)
        //     this.currentTaskId = this.currentTasks.shift();
        // },
        onUpdateCache() {
            MyOrganizationSingleton.setOrganizationTaskDrawer({
                currentTabId: parseInt(this.currentTabId?.toString()),
                allTabByIds: this.allTabByIds,
                allTabIds: this.allTabIds,
            });
        },
    },
});

const genTabCode = (currentIndexList: number[]) => {
    let index = 0;

    do {
        index += 1;
    } while (currentIndexList.includes(index));

    return `New ${index}`;
};
