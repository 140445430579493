<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class="h-5 w-5 fill-white"
  >
    <g>
      <path
        d="M496 319.996c-8.832 0-16 7.168-16 16v112H32v-192h176c8.832 0 16-7.168 16-16s-7.168-16-16-16H32v-64h176c8.832 0 16-7.168 16-16s-7.168-16-16-16H32c-17.664 0-32 14.336-32 32v288c0 17.664 14.336 32 32 32h448c17.664 0 32-14.336 32-32v-112c0-8.832-7.168-16-16-16z"
        class=""
      ></path>
      <path
        d="M144 319.996H80c-8.832 0-16 7.168-16 16s7.168 16 16 16h64c8.832 0 16-7.168 16-16s-7.168-16-16-16zM502.304 81.276l-112-48a16.337 16.337 0 0 0-12.64 0l-112 48A16.039 16.039 0 0 0 256 95.996v64c0 88.032 32.544 139.488 120.032 189.888 2.464 1.408 5.216 2.112 7.968 2.112s5.504-.704 7.968-2.112C479.456 299.612 512 248.156 512 159.996v-64c0-6.4-3.808-12.192-9.696-14.72zM480 159.996c0 73.888-24.448 114.56-96 157.44-71.552-42.976-96-83.648-96-157.44v-53.44l96-41.152 96 41.152v53.44z"
        class=""
      ></path>
      <path
        d="M442.016 131.484c-6.88-5.44-16.928-4.384-22.496 2.496l-50.304 62.912-19.904-29.76c-4.96-7.36-14.912-9.312-22.176-4.448-7.328 4.896-9.344 14.848-4.448 22.176l32 48c2.848 4.256 7.52 6.88 12.64 7.136H368c4.832 0 9.44-2.176 12.512-6.016l64-80c5.504-6.912 4.416-16.96-2.496-22.496z"
        class=""
      ></path>
    </g>
  </svg>
</template>
