<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        fill-rule="evenodd"
        d="M188.122 210.734c-6.266-6.246-6.266-16.379 0-22.621 6.242-6.246 16.375-6.246 22.617 0 6.25 6.242 6.25 16.375 0 22.621-6.242 6.262-16.375 6.262-22.617 0zm135.756-22.621c37.483 37.484 37.483 98.27 0 135.762-37.483 37.5-98.272 37.5-135.756 0-18.906-18.891-28.359-43.125-28.117-67.883h.086c0-4.09 1.57-8.18 4.695-11.305 6.242-6.262 16.375-6.262 22.625 0 3.203 3.207 4.695 7.453 4.609 11.676-.047 16.465 6.18 32.34 18.718 44.887 25 25 65.523 25 90.515 0 24.984-25 24.984-65.52 0-90.516-12.555-12.543-28.452-18.754-44.898-18.703-4.227.102-8.469-1.406-11.672-4.633-6.25-6.242-6.25-16.375 0-22.621a15.934 15.934 0 0 1 11.312-4.684v-.102c24.759-.132 48.992 9.232 67.883 28.122zM496 271.992c-6.414 0-11.883-3.805-14.445-9.227-44.374-76.113-131.17-134.777-225.559-134.777-94.702 0-177.192 51.57-221.465 128.004 44.273 76.445 126.764 128 221.465 128 43.273 0 84-10.789 119.686-29.781l.07.133c2.406-1.453 5.211-2.359 8.25-2.359 8.82 0 16 7.188 16 16.008 0 7.023-4.562 12.93-10.844 15.086C349.361 403.984 304.12 416 255.996 416 142.982 416 47.155 350.734 0 255.992 47.155 161.25 142.982 96 255.996 96c108.678 0 203.06 59.09 252.067 147.977 1.086 1.969 2.039 3.887 2.766 6.059a15.786 15.786 0 0 1 1.172 5.957c-.001 8.835-7.181 15.999-16.001 15.999zM431.985 320c8.844 0 16.008 7.156 16.008 16 0 8.828-7.164 15.984-16.008 15.984-8.82 0-16-7.156-16-15.984 0-8.844 7.18-16 16-16z"
        clip-rule="evenodd"
        opacity="1"
        class=""
      ></path>
    </g>
  </svg>
</template>
