<script setup lang="ts">
import { ref } from 'vue';
import WorkflowList from '@/ui/modules/workflow/WorkflowList.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import WorkflowDetail from '@/ui/modules/workflow/workflow-detail/WorkflowDetail.vue';
import { IWorkflowEntity } from '@/domain/entities/workflow/WorkflowEntity';
import WorkflowAddNewModal from '@/ui/modules/workflow/workflow-detail/WorkflowAddNewModal.vue';
import { statusActive } from '@/ui/common/constants/user';

const currentTabId = ref<string>('ALL');
const tabList = ref<
  {
    id: string | number;
    name: string;
    nameCode?: string;
    isDefault?: boolean;
  }[]
>([
  {
    id: 'ALL',
    name: 'All workflow',
    nameCode: 'TASK_LABEL_ALL_WORKFLOWS',
    isDefault: true,
  },
]);

const changeTab = (tab) => {
  const workflowId = tab?.id;
  if (!workflowId) return;

  currentTabId.value = workflowId;
  currentWorkflow.value = Object.prototype.hasOwnProperty.call(
    addWorkflowByIds.value,
    workflowId
  )
    ? addWorkflowByIds.value[workflowId]
    : {};
};

const updateViewModel = (workflowId, viewMode) => {
  if (!workflowId) return;

  viewModeDetailByIds.value[workflowId] = viewMode || 'VIEW';
};

const selectedWorkflow = (workflow, viewMode?: 'VIEW' | 'EDIT_STEP') => {
  updateViewModel(workflow?.id, viewMode);
  currentWorkflow.value = workflow;
  const existedTab = tabList.value?.find((tab) => tab?.id == workflow?.id);
  if (existedTab?.id) {
    changeTab(existedTab);
  } else {
    tabList.value.push(workflow);
    currentTabId.value = workflow?.id;
  }
};

const newWorkflowDefault = ref<IWorkflowEntity | undefined>(undefined);

const closeTab = (tab) => {
  if (currentTabId.value == tab?.id) currentTabId.value = 'ALL';
  tabList.value = tabList.value?.filter((t) => t?.id !== tab?.id);
};

const addWorkflowByIds = ref<any>({});
const onReadyWorkflowList = (data: IWorkflowEntity[]) => {
  addWorkflowByIds.value = data?.reduce((currentResult, currentObj) => {
    return {
      ...currentResult,
      [currentObj?.id]: currentObj,
    };
  }, {});
};

const currentWorkflow = ref<IWorkflowEntity | null>(null);

const isOpenNewWorkflow = ref<boolean>(false);
const addMode = ref<'ADD' | 'CLONE'>('ADD');
const onAddNewWorkflow = (mode: 'ADD' | 'CLONE') => {
  addMode.value = mode;
  isOpenNewWorkflow.value = true;
};

const workflowListRef = ref<any>(null);

const viewModeDetailByIds = ref<any>({});
const onCreateSuccess = (newWorkflow) => {
  if (workflowListRef.value?.initComponent)
    workflowListRef.value?.initComponent();
  isOpenNewWorkflow.value = false;

  selectedWorkflow(newWorkflow);
};

const onRemoveSuccess = () => {
  closeTab({ id: currentTabId.value });

  onUpdateSuccess(null);
};
const onAddSuccess = (newData) => {
  selectedWorkflow(newData);

  if (workflowListRef.value?.initComponent)
    workflowListRef.value?.initComponent();
};
const onUpdateSuccess = (newData) => {
  currentWorkflow.value = newData;
  if (newData?.id) {
    addWorkflowByIds.value[newData?.id] = newData;

    const index = tabList.value?.findIndex((tab) => tab?.id == newData?.id);
    if (index > -1) tabList.value[index] = newData;
  }

  if (workflowListRef.value?.initComponent)
    workflowListRef.value?.initComponent();
};

const filterObject = ref<{
  departmentId?: number;
  domainId?: number;
  groupId?: number;
  searchText?: string;
  isActive: boolean | null;
}>({
  searchText: '',
  isActive: null,
});

const onRemoveWorkflowSuccess = (removedWorkflow) => {
  tabList.value = tabList.value?.filter(
    (tab) => tab?.id !== removedWorkflow?.id
  );
};

const onChangeStatus = async (status) => {
  filterObject.value.isActive = status;
};
</script>
<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.ADMIN.MEMBER,
      isPage: true,
    }"
    class="h-full w-full pt-3 flex flex-col overflow-hidden"
  >
    <div class="flex justify-between relative">
      <AtomTabHeader
        :current-tab-id="currentTabId"
        :tab-list="tabList"
        @change-tab="changeTab"
        @close-tab="closeTab"
      />
      <div
        v-if="currentTabId !== 'ALL'"
        class="absolute right-2 -top-1 flex-center space-x-2"
      >
        <button
          v-vig-tooltip="$t('WORKFLOW_LABEl_ADD_NEW') || 'Invite'"
          class="w-8 h-8 rounded cursor-pointer flex-center bg-current-500"
          @click="onAddNewWorkflow('ADD')"
        >
          <SynIcon name="plus" custom-class="w-4 h-4 fill-white" />
        </button>
      </div>
    </div>
    <div
      class="
        flex-1
        min-h-0
        bg-white
        flex flex-col
        space-y-2
        pt-2
        border-t border-current-100
      "
    >
      <template v-if="currentTabId == 'ALL'">
        <div class="px-2 w-full flex items-center justify-between">
          <VigSearchBox
            v-model="filterObject.searchText"
            input-class="text-sm"
            style="width: 20rem"
            autofocus
            :placeholder="$t('COMMON_LABEL_SEARCH')"
          />
          <div class="flex space-x-2">
            <div class="text-center flex space-x-5 h-9 items-center">
              <div
                v-for="item in statusActive"
                :key="item?.key"
                class="flex items-center text-end cursor-pointer"
                @click="onChangeStatus(item?.status)"
              >
                <input
                  type="radio"
                  name="default-radio"
                  :checked="item?.status == filterObject?.isActive"
                  class="
                    w-4
                    h-4
                    text-current-500
                    bg-gray-100
                    border-gray-300
                    cursor-pointer
                  "
                />
                <span class="ml-2 text-sm text-current-500">
                  {{ $t(item.label) }}
                </span>
              </div>
            </div>
          </div>
          <button
            v-vig-tooltip="$t('WORKFLOW_LABEl_ADD_NEW') || 'Invite'"
            class="w-8 h-8 rounded cursor-pointer flex-center bg-current-500"
            @click="onAddNewWorkflow('ADD')"
          >
            <SynIcon name="plus" custom-class="w-4 h-4 fill-white" />
          </button>
        </div>
        <WorkflowList
          ref="workflowListRef"
          is-custom-open-detail
          :action-list="['DUPLICATE', 'PREVIEW', 'EDIT', 'ACTIVE', 'REMOVE']"
          :filter-object="filterObject"
          @choose="selectedWorkflow"
          @on-ready="onReadyWorkflowList"
          @remove-success="onRemoveWorkflowSuccess"
        />
      </template>
      <template v-else>
        <div class="w-full h-full px-4 pb-2">
          <WorkflowDetail
            v-if="currentWorkflow?.id"
            :key="currentWorkflow?.id"
            :workflow-id="currentTabId"
            :workflow-info="currentWorkflow"
            :view-mode="viewModeDetailByIds[currentWorkflow?.id]"
            @add-success="onAddSuccess"
            @update-success="onUpdateSuccess"
            @remove-success="onRemoveSuccess"
            @update-view-model="
              (value) => updateViewModel(currentWorkflow?.id, value)
            "
          />
        </div>
      </template>
    </div>
  </div>

  <WorkflowAddNewModal
    v-if="isOpenNewWorkflow"
    :view-mode="addMode"
    :default-data="newWorkflowDefault"
    @cancel="isOpenNewWorkflow = false"
    @create-success="onCreateSuccess"
  />
</template>
