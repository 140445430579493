<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="1024"
    height="1024"
    viewBox="0 0 1024 1024"
  >
    <title></title>
    <g id="icomoon-ignore"></g>
    <path
      d="M0 113.778h113.778v-113.778c-62.578 0-113.778 51.2-113.778 113.778zM0 568.889h113.778v-113.778h-113.778v113.778zM227.556 1024h113.778v-113.778h-113.778v113.778zM0 341.333h113.778v-113.778h-113.778v113.778zM568.889 0h-113.778v113.778h113.778v-113.778zM910.222 0v113.778h113.778c0-62.578-51.2-113.778-113.778-113.778zM113.778 1024v-113.778h-113.778c0 62.578 51.2 113.778 113.778 113.778zM0 796.444h113.778v-113.778h-113.778v113.778zM341.333 0h-113.778v113.778h113.778v-113.778zM455.111 1024h113.778v-113.778h-113.778v113.778zM910.222 568.889h113.778v-113.778h-113.778v113.778zM910.222 1024c62.578 0 113.778-51.2 113.778-113.778h-113.778v113.778zM910.222 341.333h113.778v-113.778h-113.778v113.778zM910.222 796.444h113.778v-113.778h-113.778v113.778zM682.667 1024h113.778v-113.778h-113.778v113.778zM682.667 113.778h113.778v-113.778h-113.778v113.778zM227.556 796.444h568.889v-568.889h-568.889v568.889zM341.333 341.333h341.333v341.333h-341.333v-341.333z"
    ></path>
  </svg>
</template>
