<script setup lang="ts">
import { ref } from 'vue';
import CalendarSyncSettingsModal from '@/ui/modules/calendar/calendar-sync/CalendarSyncSettingsModal.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import CalendarSharingButton from '@/ui/modules/calendar/calendar-sharing/CalendarSharingButton.vue';

const props = defineProps<{
  viewOptions: any;
}>();

const emit = defineEmits(['update:viewOptions']);

const isOpenSyncConfigModal = ref<boolean>();
const syncSettingsEdit = ref<any>();

const onSyncConfigClick = () => {
  isOpenSyncConfigModal.value = true;
};

const onOptionChange = (key, value) => {
  const options = props.viewOptions || {};
  options[key] = value;

  emit('update:viewOptions', options);
};
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <VigButton ghost color="gray" icon="DotsVertical" />
    </template>
    <template #dropdown-menu>
      <div class="p-3 border-b">
        <div class="flex items-start justify-between gap-2">
          <div class="text-sm">{{ $t('CALENDAR_SHOW_DESCRIPTION') }}</div>
          <VigSwitch
            :model-value="viewOptions?.isShowDescription"
            @update:model-value="onOptionChange('isShowDescription', $event)"
          />
        </div>
      </div>
      <VigButton
        v-permission-function="{
          functionCode: ALL_FUNCTIONS.CALENDAR.SYNC,
          needToShowPremiumIcon: true,
          onContinue: () => {
            onSyncConfigClick();
          },
        }"
        ghost
        color="gray"
        class="w-full justify-start dropdown-item"
        @click="onSyncConfigClick"
      >
        <SynIcon name="GoogleCalendar" class="mr-2" />
        <span class="text-sm font-normal pr-3">{{
          $t('CALENDAR_SYNC_LABEL')
        }}</span>
      </VigButton>
      <CalendarSharingButton button-class="w-full justify-start dropdown-item">
        <SynIcon name="Share" class="mr-2" />
        <span class="text-sm font-normal pr-3">{{
          $t('CALENDAR_SHARING')
        }}</span>
      </CalendarSharingButton>
    </template>
  </VigDropdown>

  <CalendarSyncSettingsModal
    v-if="isOpenSyncConfigModal"
    :sync-settings="syncSettingsEdit"
    @on-close="isOpenSyncConfigModal = false"
  />
</template>

<style scoped></style>
