<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

const props = defineProps<{
  path: string;
}>();

const isLoadingIframe = ref<boolean>();

watch(
  () => props.path,
  (path) => {
    if (path) isLoadingIframe.value = true;
  }
);

onMounted(() => {
  if (props.path) isLoadingIframe.value = true;
});
</script>

<template>
  <div class="h-full relative">
    <iframe
      class="h-full w-full"
      :src="path"
      @load="isLoadingIframe = false"
    ></iframe>
    <VigLoading
      v-if="isLoadingIframe"
      class="h-full w-full absolute top-0 left-0 bg-white bg-opacity-50"
    />
  </div>
</template>
