<script setup lang="ts">
// *** IMPORTS ***
import { onBeforeMount, ref } from 'vue';
import { StartWorkingConditionModel } from '@/application/models/setting/StartWorkingConditionModel';
import SWSettingsForMembers from '@/ui/modules/settings/start-working/modal-settings-for-members/SWSettingsForMembers.vue';
import { CurrentUserConfig } from '@/ui/common/constants/constant';
import commonStore from '@/store/common/commonStore';
import CommonService from '@/application/services/CommonService';

// *** PROPS, EMITS ***
const props = defineProps<{
  user: any;
}>();

const emit = defineEmits(['onClose']);

// *** PRIVATE VARIABLES ***
const _commonStore = commonStore();

// *** COMPOSABLES ***

// *** REFS ***
const sWCondition = ref<StartWorkingConditionModel | null>();
const isApplying = ref<boolean>();

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***
onBeforeMount(async () => {
  if (!props.user?.id) return;

  const res: any = await CommonService.getInstance().getUserSettingByKey(
    props.user?.id,
    CurrentUserConfig.StartWorkingConditionsSpecific
  );

  sWCondition.value = res?.result?.value
    ? JSON.parse(res?.result?.value)
    : null;
});

// *** HANDLER FUNCTIONS ***
const onApplyClick = async () => {
  if (!props.user?.id) return;

  isApplying.value = true;

  try {
    const data = {
      userIds: [props.user?.id],
      key: CurrentUserConfig.StartWorkingConditionsSpecific,
      value: sWCondition.value ? JSON.stringify(sWCondition.value) : null,
    };

    await _commonStore.setMultiUsersSetting(data);

    emit('onClose');
  } catch (e) {
    console.log(e);
  }

  isApplying.value = true;
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <SynModal
    container-class="h-[90%] w-11/12 xl:w-[50rem]"
    style-body="px-4"
    disable-esc-to-close
    disable-click-outside
    @cancel="$emit('onClose')"
  >
    <template #header>
      <span
        v-html="
          $t('SETTING_SW_CONDITION_SET_TO_MEMBER', { member: user?.firstName })
        "
      ></span>
    </template>
    <template #body>
      <SWSettingsForMembers v-model:setting="sWCondition" />
    </template>
    <template #footer>
      <div class="flex items-center justify-end px-4 py-3 gap-2">
        <VigButton
          light
          color="gray"
          :label="$t('COMMON_LABEL_CANCEL')"
          @click="$emit('onClose')"
        />
        <VigButton
          :label="$t('COMMON_LABEL_APPLY')"
          :processing="isApplying"
          @click="onApplyClick"
        />
      </div>
    </template>
  </SynModal>
</template>

<style scoped></style>
