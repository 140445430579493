<script setup lang="ts">
import { IOrgCurrentPlan } from '@/application/types/plan.types';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { computed, onMounted, ref } from 'vue';
import plansStore from '@/store/plans';
import {
  getUiByPlanState,
  // UI_BY_PLAN_ID,
} from '@/application/constants/plan.const';
import { AdminType } from '@/ui/common/constants/constant';
import myProfileStore from '@/store/auth/my-profile';
import userStore from '@/store/user';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
import { isShowPlanAndPricingOverviewModal } from '@/ui/app-global-state';

defineProps<{
  isPage: boolean;
}>();

const canPayment = computed<any>(
  () => myProfileStore().isAdmin || myProfileStore().isOwner
);
const _planStore = plansStore();
const currentOrgPlan = computed<IOrgCurrentPlan>(
  () => _planStore.currentOrgPlan
);
const uiByPlanState = computed(() => {
  return getUiByPlanState(
    currentOrgPlan.value.planId,
    currentOrgPlan.value.stateCode
  )?.BLOCK_SCREEN;
});

const allActiveUsers = computed(() => userStore().allActiveUsers);
const defaultOwnerInfo = ref<any>({});

onMounted(() => {
  for (const user of allActiveUsers.value) {
    if (user?.adminType === AdminType.Owner) defaultOwnerInfo.value = user;
  }
});
</script>
<template>
  <div
    class="
      block-function
      absolute
      top-0
      left-0
      p-4
      w-full
      h-full
      flex-center
      m-0
    "
    :style="`background: rgba(41, 47, 76, 0.7);
      z-index: 20;
      margin: 0px;
      filter: unset !important;`"
  >
    <div class="flex-center flex-col space-y-4 rounded-md p-8">
      <div
        v-if="isPage"
        class="w-1/4 rounded-full object-cover"
        style="min-width: 12rem"
      >
        <img
          :src="`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/payment/block-screen.png`"
        />
      </div>
      <div v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="50.801"
          height="70.296"
          viewBox="0 0 50.801 70.296"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              y1="0.5"
              x2="1.001"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#ffde00" />
              <stop offset="1" stop-color="#fd5900" />
            </linearGradient>
          </defs>
          <g
            id="Group_4317"
            data-name="Group 4317"
            transform="translate(-1062.699 -161.204)"
          >
            <g
              id="Group_4314"
              data-name="Group 4314"
              transform="translate(1060.85 176.908)"
            >
              <path
                id="Path_1582"
                data-name="Path 1582"
                d="M41.629,3.791h-.86V2.715a1.615,1.615,0,1,0-3.231,0V3.791H16.963V2.715a1.615,1.615,0,0,0-3.231,0V3.791h-.854A11.039,11.039,0,0,0,1.849,14.819V43.564A11.039,11.039,0,0,0,12.877,54.592H41.622A11.039,11.039,0,0,0,52.65,43.564V14.812A11.036,11.036,0,0,0,41.629,3.791ZM12.877,7.022h.854V8.1a1.615,1.615,0,0,0,3.231,0V7.022H37.535V8.1a1.615,1.615,0,1,0,3.231,0V7.022h.86a7.8,7.8,0,0,1,7.788,7.79V18.6H5.078V14.819a7.808,7.808,0,0,1,7.8-7.8ZM41.622,51.359H12.877a7.808,7.808,0,0,1-7.8-7.8V21.827H49.416V43.562A7.8,7.8,0,0,1,41.622,51.359Z"
                fill="#cee8e9"
              />
              <g
                id="Group_4313"
                data-name="Group 4313"
                transform="translate(25.634 26.605)"
              >
                <path
                  id="Path_1583"
                  data-name="Path 1583"
                  d="M16.715,15.309A1.615,1.615,0,0,0,15.1,16.924v9.33a1.615,1.615,0,1,0,3.231,0v-9.33A1.615,1.615,0,0,0,16.715,15.309Zm0,15.462A1.615,1.615,0,0,0,15.1,32.386v1.285a1.615,1.615,0,1,0,3.231,0V32.386A1.615,1.615,0,0,0,16.715,30.771Z"
                  transform="translate(-15.1 -15.309)"
                  fill="#cee8e9"
                />
              </g>
            </g>
            <g
              id="Group_4315"
              data-name="Group 4315"
              transform="translate(1074.734 150.734)"
            >
              <path
                id="Path_1584"
                data-name="Path 1584"
                d="M24.186,15.633l-2.523,9.711a1.315,1.315,0,0,1-1.271.983H6.339a1.315,1.315,0,0,1-1.271-.983L2.546,15.633a1.313,1.313,0,0,1,1.742-1.558l4.18,1.609a.212.212,0,0,0,.243-.066l3.62-4.64a1.307,1.307,0,0,1,2.07,0l3.62,4.64a.212.212,0,0,0,.243.066l4.18-1.609a1.322,1.322,0,0,1,1.742,1.558Z"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
        </svg>
      </div>
      <!-- CONTENT -->
      <section class="flex-center flex-col">
        <!-- <template v-if="uiByPlanState?.message?.hasPopup">
        <div
          class="text-white text-center italic text-sm"
          style="max-width: 45rem"
        >
          {{
            uiByPlanState?.message?.popupBody
              ? translate(uiByPlanState?.message?.popupBody, {
                  planId: currentOrgPlan?.planId,
                })
              : ''
          }}
        </div>
      </template>
      <template v-else>
        <div
          class="text-white text-center italic text-sm"
          style="max-width: 45rem"
        >
          {{
            uiByPlanState?.message?.title
              ? translate(uiByPlanState?.message?.title, {
                  planId: currentOrgPlan?.planId,
                  remainDays: currentOrgPlan?.remainDay,
                })
              : ''
          }}
        </div>
      </template> -->
        <div
          class="text-white text-center italic text-sm"
          style="max-width: 45rem"
          v-html="
            uiByPlanState?.message?.longMessage
              ? translate(uiByPlanState?.message?.longMessage, {
                  planId: currentOrgPlan?.planId,
                })
              : translate('BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG')
          "
        ></div>
      </section>
      <!-- ACTION -->
      <section class="flex-center pt-4">
        <button
          v-if="canPayment"
          class="
            px-4
            py-2
            bg-current-500
            text-white
            rounded-md
            flex-center
            space-x-2
            w-max
          "
          @click="isShowPlanAndPricingOverviewModal = true"
        >
          <svg
            id="Objects"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 32 32"
            style="enable-background: new 0 0 32 32"
            xml:space="preserve"
            class="h-4 w-4 fill-white"
          >
            <g>
              <polygon
                class="st0"
                points="31.6,7.8 26.3,24 26.1,24.6 26.1,24.7 6.5,24.7 6.5,24.6 6.2,23.9 0.4,6.9 9.5,11.8 15.7,2.6 16.1,2    16.2,2.2 18.6,6 20.9,9.5 22.2,11.6 22.4,11.8  "
              />
              <path
                class="st1"
                d="M26.3,24l-0.2,0.7l0,0.1H6.5l0-0.1l-0.2-0.7c0.1-0.1,0.2-0.1,0.4-0.1h19.3C26,23.8,26.2,23.9,26.3,24z"
              />
              <polygon
                class="st2"
                points="18.6,6 16.2,14.1 16.2,14.3 15.8,15.4 15.3,17.3 15,18.2 14.7,19.2 14.3,20.6 13.3,23.8 13.1,24.6    13,24.7 9,24.7 9.1,24.6 9.3,23.8 15.7,2.6 16.1,2 16.2,2.2  "
              />
              <polygon
                class="st2"
                points="22.2,11.6 18.6,23.8 18.3,24.6 18.3,24.7 16.3,24.7 16.3,24.6 16.6,23.8 17.4,21 17.5,20.9 18.4,17.6    18.7,16.8 20.9,9.5  "
              />
              <path
                class="st3"
                d="M16.2,24.7V2.2L18.6,6l2.2,3.5l1.4,2.1l0.1,0.2l9.2-4L26.3,24l-0.2,0.7C26.5,24.8,16.2,24.7,16.2,24.7z"
              />
            </g>
            <g>
              <g>
                <path
                  class="st4"
                  d="M26.5,28.6c0,0.7-0.3,1.2-0.7,1.4c-0.1,0-0.1,0-0.2,0H7.2c-0.5,0-0.9-0.6-0.9-1.4c0-0.7,0.4-1.3,0.8-1.4    c0,0,0.1,0,0.1,0h18.4c0.1,0,0.2,0,0.2,0C26.2,27.4,26.5,27.9,26.5,28.6z"
                />
                <polygon
                  class="st5"
                  points="17.8,27.2 17.8,27.3 17,30 17,30 13,30 13.8,27.3 13.8,27.2   "
                />
                <polygon
                  class="st5"
                  points="23.2,27.2 23.1,27.3 22.3,30 22.3,30 20.3,30 20.3,30 21.1,27.3 21.2,27.2   "
                />
              </g>
              <path
                class="st3"
                d="M26.5,28.6c0,0.7-0.3,1.2-0.7,1.4c-0.7,0-2,0-3.5,0c-0.3,0-0.6,0-0.9,0c-0.4,0-0.7,0-1.1,0c-1.3,0-2.6,0-3.3,0   c-0.4,0-0.7,0-0.7,0v-2.8h9.3c0.1,0,0.2,0,0.2,0C26.2,27.4,26.5,27.9,26.5,28.6z"
              />
            </g>
          </svg>
          <span>
            {{ translate('PAYMENT_LABEL_SELECT_PLAN') }}
          </span>
        </button>
        <span
          v-else
          class="text-white"
          v-html="
            translate('PLAN_NOTIFICATION_MESSAGE_NO_PERMISSION_PAYMENT', {
              ownerName: defaultOwnerInfo?.name || '',
              adminName: '',
            })
          "
        >
        </span>
      </section>
    </div>
  </div>
</template>
