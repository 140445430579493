<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    fill-rule="evenodd"
    class=""
  >
    <g>
      <path
        d="M7.97 12.53a.749.749 0 1 1 1.06-1.06l1.803 1.803 4.137-4.137a.75.75 0 0 1 1.06 1.061l-4.666 4.667a.75.75 0 0 1-1.061 0z"
        opacity="1"
      ></path>
      <path
        d="M10.732 1.775a1.793 1.793 0 0 1 2.536 0l1.445 1.445c.066.066.16.097.253.082l2.018-.319a1.794 1.794 0 0 1 2.052 1.49l.319 2.018a.295.295 0 0 0 .157.216l1.82.927a1.795 1.795 0 0 1 .784 2.412l-.927 1.821a.29.29 0 0 0 0 .266l.927 1.821a1.792 1.792 0 0 1-.784 2.412l-1.82.927a.295.295 0 0 0-.157.216l-.319 2.018a1.794 1.794 0 0 1-2.052 1.49l-2.018-.319a.292.292 0 0 0-.253.082l-1.445 1.445a1.793 1.793 0 0 1-2.536 0L9.287 20.78a.292.292 0 0 0-.253-.082l-2.018.319a1.794 1.794 0 0 1-2.052-1.49l-.319-2.018a.295.295 0 0 0-.157-.216l-1.82-.927a1.795 1.795 0 0 1-.784-2.412l.927-1.821a.29.29 0 0 0 0-.266l-.927-1.821a1.792 1.792 0 0 1 .784-2.412l1.82-.927a.295.295 0 0 0 .157-.216l.319-2.018a1.794 1.794 0 0 1 2.052-1.49l2.018.319a.292.292 0 0 0 .253-.082zm1.061 1.061-1.445 1.445a1.793 1.793 0 0 1-1.549.503l-2.018-.32a.292.292 0 0 0-.335.244l-.32 2.018a1.79 1.79 0 0 1-.957 1.317l-1.821.928a.293.293 0 0 0-.128.394l.928 1.821a1.79 1.79 0 0 1 0 1.628l-.928 1.821a.293.293 0 0 0 .128.394l1.821.928c.512.26.867.75.957 1.317l.32 2.018a.292.292 0 0 0 .335.244l2.018-.32a1.793 1.793 0 0 1 1.549.503l1.445 1.445a.292.292 0 0 0 .414 0l1.445-1.445a1.793 1.793 0 0 1 1.549-.503l2.018.32a.292.292 0 0 0 .335-.244l.32-2.018a1.79 1.79 0 0 1 .957-1.317l1.821-.928a.293.293 0 0 0 .128-.394l-.928-1.821a1.79 1.79 0 0 1 0-1.628l.928-1.821a.293.293 0 0 0-.128-.394l-1.821-.928a1.79 1.79 0 0 1-.957-1.317l-.32-2.018a.292.292 0 0 0-.335-.244l-2.018.32a1.793 1.793 0 0 1-1.549-.503l-1.445-1.445a.292.292 0 0 0-.414 0z"
        opacity="1"
      ></path>
    </g>
  </svg>
</template>
