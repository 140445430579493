<template>
  <svg
    id="email"
    height="512"
    viewBox="0 0 32 32"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m18.35 12a6.11 6.11 0 0 0 1.27 2.18l-5.12 3.57-8.3-5.75h11.8zm6.65 14h-21v-13.09l9.93 6.87a1 1 0 0 0 1.14 0l6.21-4.29a5.92 5.92 0 0 0 2.75.68 6.25 6.25 0 0 0 1-.09z"
    />
    <path
      d="m24 6.19a4 4 0 1 0 4 4 4 4 0 0 0 -4-4zm1 5.81a1 1 0 0 1 -2 0v-4a1 1 0 0 1 2 0z"
    />
  </svg>
</template>
