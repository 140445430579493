<script setup lang="ts">
import DomainById from '@/ui/modules/domains/component/DomainById.vue';
import ProjectById from '@/ui/modules/domains/component/ProjectById.vue';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    domainId: number | string;
    projectId?: number | string | null;
    subProjectId?: number | string | null;
    layout?: 'horizontal' | 'vertical';
  }>(),
  {
    domainId: 0,
    projectId: 0,
    subProjectId: 0,
    layout: 'vertical',
  }
);

const isHorizontal = computed<boolean>(() => {
  return props.layout == 'horizontal';
});
</script>

<template>
  <div
    class="px-2 py-1 rounded w-max flex border"
    :class="{
      'flex-col items-start': !isHorizontal,
      'items-center justify-start space-x-2 ': isHorizontal,
      'border-current-100': domainId,
      'border-red-200': !domainId,
    }"
  >
    <div class="w-full text-xs">
      <DomainById :domain-id="domainId" />
    </div>
    <div
      v-if="projectId"
      class="flex-center space-x-2 text-xs text-gray-400"
      :style="isHorizontal ? '' : 'max-width: 8rem;'"
    >
      <span v-if="isHorizontal">/</span>
      <ProjectById :project-id="projectId" />
      <template v-if="subProjectId">
        <span>/</span>
        <ProjectById :project-id="subProjectId" />
      </template>
    </div>
  </div>
</template>
