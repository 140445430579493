<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'normal', // mini, small, large
  },
});

const customClass = computed(() => {
  switch (props?.size) {
    case 'mini':
      return 'text-2xs px-1 py-0.5';
    case 'small':
      return 'text-xs px-1 py-0.5';
    case 'normal':
      return 'text-sm px-2 py-1';
    case 'large':
      return 'text-xl px-3 py-2';
    default:
      return 'text-sm px-2 py-1';
  }
});
</script>

<template>
  <div
    class="flex-center px-2 py-1 rounded-full cursor-pointer"
    :class="[
      isActive
        ? 'bg-current-50 hover:bg-current-100 text-current-400'
        : 'bg-gray-100 hover:bg-gray-200 text-gray-500',
      customClass,
    ]"
  >
    {{ label }}
    <slot name="suffix"></slot>
  </div>
</template>
