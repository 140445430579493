import { AxiosInstance } from 'axios';
import BaseRequest from '@/base/api/base-request';

export default class ZaloRepository {
    private static instance: ZaloRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): ZaloRepository {
        if (!ZaloRepository.instance) {
            // Get from local storage
            ZaloRepository.instance = new ZaloRepository();
        }

        return ZaloRepository.instance;
    }

    saveOaAccessToken(callbackQuery) {
        return this.request({
            url: `Zalo/SaveOaAccessToken/${callbackQuery}`,
            method: 'post',
        });
    }

    getOAInfo() {
        return this.request({
            url: `Zalo/GetOAInfo`,
            method: 'get',
        });
    }

    getUserFollowedInfo(userId) {
        return this.request({
            url: `Zalo/GetUserFollowedInfo?userId=${userId}`,
            method: 'get',
        });
    }
}
