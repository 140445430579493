<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import GoogleUserInfo from '@/ui/components/google/GoogleUserInfo.vue';
import driveDocumentStore from '@/store/driveDocument';
import $constants from '@/ui/plugins/constants';
import ModalDriveDocumentAddFromLink from '@/ui/modules/drive-document/drive-document-add/ModalDriveDocumentAddFromLink.vue';
import ModalDriveDocumentAdd from '@/ui/modules/drive-document/drive-document-add/ModalDriveDocumentAdd.vue';
import { GoogleService } from '@/domain/enums/google.enum';
import ModalFolderAdd from '@/ui/modules/ged/folder/modal-folder-add/ModalFolderAdd.vue';

defineProps<{
  location: any;
}>();

defineEmits(['onAdded', 'onFolderAdded']);

const _driveDocumentStore = driveDocumentStore();

const googleDocTypes = ref<any[]>([
  {
    value: $constants.FILE.TYPE.GOOGLE_DOC,
    icon: 'GoogleDocument',
    text: 'GED_GOOGLE_ADD_DOCUMENT',
  },
  {
    value: $constants.FILE.TYPE.GOOGLE_SHEET,
    icon: 'GoogleSpreadsheet',
    text: 'GED_GOOGLE_ADD_SPREADSHEET',
  },
  {
    value: $constants.FILE.TYPE.GOOGLE_PRESENTATION,
    icon: 'GooglePresentation',
    text: 'GED_GOOGLE_ADD_PRESENTATION',
  },
]);
const addingDocType = ref<any>();
const isAddNewDoc = ref<boolean>(false);
const isAddFromLink = ref<boolean>(false);
const isAddFolder = ref<boolean>(false);

const googleUser = computed(() => _driveDocumentStore.googleUser);

onMounted(() => {
  _driveDocumentStore.initGoogleDrive();
});

const onAddDocumentClick = async (docType) => {
  if (!googleUser.value) await _driveDocumentStore.signInGoogleDriveAccount();

  addingDocType.value = docType;
  isAddNewDoc.value = true;
};
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <slot />
    </template>
    <template #dropdown-menu>
      <div class="min-w-[16rem]">
        <div class="border-b">
          <GoogleUserInfo
            v-if="googleUser"
            class="px-4 py-2"
            action-class="flex-col items-start"
            has-actions
            :user="googleUser"
            :service="GoogleService.DRIVE"
          />
          <VigButton
            v-for="type in googleDocTypes"
            :key="type?.value"
            ghost
            color="gray"
            rounded="rounded-0"
            class="w-full justify-start text-sm dropdown-item"
            @click="onAddDocumentClick(type?.value)"
          >
            <SynIcon :name="type?.icon" class="mr-2" />
            <span class="text-sm">{{ $t(type?.text) }}</span>
          </VigButton>

          <!--ADD FROM LINK-->
          <VigButton
            ghost
            color="gray"
            rounded="rounded-0"
            class="w-full justify-start text-sm dropdown-item"
            @click="isAddFromLink = true"
          >
            <SynIcon name="Link" class="mr-2" />
            <span>{{ $t('GED_GOOGLE_ADD_FROM_LINK') }}</span>
          </VigButton>
        </div>

        <!--ADD FOLDER-->
        <VigButton
          ghost
          color="gray"
          rounded="rounded-0"
          class="w-full justify-start text-sm dropdown-item"
          @click="isAddFolder = true"
        >
          <SynIcon name="Folder" class="mr-2" />
          <span>{{ $t('GED_FOLDER_NEW') }}</span>
        </VigButton>
      </div>
    </template>
  </VigDropdown>

  <ModalDriveDocumentAdd
    v-if="isAddNewDoc"
    :document-type="addingDocType"
    :location="location"
    @on-close="isAddNewDoc = false"
    @on-added="
      isAddNewDoc = false;
      $emit('onAdded', $event);
    "
  />

  <ModalDriveDocumentAddFromLink
    v-if="isAddFromLink"
    :location="location"
    @on-close="isAddFromLink = false"
    @on-added="
      isAddFromLink = false;
      $emit('onAdded', $event);
    "
  />

  <ModalFolderAdd
    v-if="isAddFolder"
    :location="location"
    :is-drive-folder="true"
    @on-close="isAddFolder = false"
    @on-folder-added="
      isAddFolder = false;
      $emit('onFolderAdded', $event);
    "
  />
</template>
