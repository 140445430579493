<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import { GroupMemberRole } from '@/ui/common/constants/constant';
import userStore from '@/store/user';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import UserById from '@/ui/components/user/UserById.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  departmentId?: number;
  departmentName: string;
  memberList: any[];
  defaultList: any[];
  isSelectUserStep?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:memberList', data: any[]): any;
}>();

const _userStore = userStore();

onMounted(() => {
  memberListPayload.value = memberListPayload.value?.map((user) => {
    const member = props.defaultList?.find((u) => u?.userId == user?.id);
    return {
      ...user,
      isSelected: member ? true : false,
      role: member ? member.role : GroupMemberRole.User,
    };
  });
});

const allActiveUsers = computed(() => _userStore.allActiveUserWithDepartments);

const currentUser = computed(() => myProfileStore().myProfile);

const isLoading = ref(false);
const isCheckChange = ref(false);

const memberListPayload = ref<any[]>([]);

// member

const sortMemberFunction = (ob1, ob2) => {
  if (ob1.role > ob2.role) {
    return 1;
  } else if (ob1.role < ob2.role) {
    return -1;
  }

  if (ob1.userId == currentUser.value.id) {
    return 1;
  } else if (ob2.userId == currentUser.value.id) {
    return 1;
  }

  return ob1.name.localeCompare(ob2.name);
};
const selectedUsers = computed(() => {
  return memberListPayload.value
    .filter((el) => el.isSelected)
    ?.sort(sortMemberFunction);
});
const selectedAdminUsers = computed(() => {
  return memberListPayload.value.filter(
    (el) => el.isSelected && el.role == GroupMemberRole.Admin
  );
});
const unSelectedUsers = computed(() => {
  const unSelectList = memberListPayload.value.filter((el) => !el.isSelected);
  if (keySearch.value)
    return unSelectList.filter((o) => {
      const name = o.lastName + ' ' + o.firstName;
      return stringNomalize(name?.toLowerCase()).includes(
        stringNomalize(keySearch.value?.toLowerCase())
      );
    });

  return unSelectList;
});

const initComponent = async () => {
  isLoading.value = true;

  getDefaultMemberList();

  isLoading.value = false;
};

const isEdit = ref(false);

const getDefaultMemberList = () => {
  memberListPayload.value = allActiveUsers.value?.map((el) => {
    el.name = el.lastName + ' ' + el.firstName;

    const matchMember = props.memberList.find((g) => g.userId == el.userId);

    el.isSelected = matchMember ? matchMember.isSelected : false;
    el.role = matchMember ? matchMember.role : GroupMemberRole.User;
    return el;
  });
  emit('update:memberList', selectedUsers.value);
};

const onSelectMember = async (user) => {
  const id = user?.userId;
  isCheckChange.value = true;

  const departmentInfo = user?.departmentInfos[0];
  if (
    departmentInfo &&
    departmentInfo?.id !== props.departmentId &&
    departmentInfo?.role == GroupMemberRole.Admin
  ) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('DEPARTMENT_ADD_DEPARTMENT_ADMIN_CONFIRM', {
        departmentName: departmentInfo?.name || '',
        currentDepartmentName: props.departmentName || '',
      }),
      confirmable: true,
      closeable: true,
      cancelLabel: translate('DEPARTMENT_ADD_DEPARTMENT_ADMIN_BUTTON_CANCEL'),
      confirmLabel: translate('DEPARTMENT_ADD_DEPARTMENT_ADMIN_BUTTON_CONFIRM'),
    });
    const confirmed = await ask();
    if (!confirmed) return;
  }

  memberListPayload.value.find((el) => el.userId == id).isSelected = true;

  emit('update:memberList', selectedUsers.value);
};

const removeMember = (id) => {
  const removedUserIndex = memberListPayload.value.findIndex(
    (el) => el.userId == id
  );
  if (removedUserIndex < 0) return;
  var removedUser = memberListPayload.value[removedUserIndex];
  isCheckChange.value = true;
  isEdit.value = true;
  removedUser.isSelected = false;
  removedUser.role = GroupMemberRole.User;
  memberListPayload.value[removedUserIndex] = { ...removedUser };

  emit('update:memberList', selectedUsers.value);
};

// search member
const keySearch = ref('');

const updateMemberRole = (id, newRole) => {
  console.log('🚀 Hyrin ~ updateMemberRole ~ id', id);

  const index = memberListPayload.value?.findIndex((o) => o.userId == id);
  if (index >= 0) memberListPayload.value[index].role = newRole;
  emit('update:memberList', selectedUsers.value);
};

initComponent();
</script>
<template>
  <div class="pt-3 pl-3 grid grid-cols-2 gap-3">
    <div class="w-full py-2">
      <VigSearchBox v-model="keySearch" autofocus />
    </div>
    <div class="py-3 px-4 flex items-center space-x-2">
      <span class="font-medium">
        {{
          selectedUsers.length > 0
            ? $t('GED_NUM_SELECTED', {
                number: selectedUsers.length || 0,
              })
            : $t('COMMON_LABEL_SELECT_MEMBER_NULL')
        }}
      </span>
      <VigDropdown
        v-if="!selectedAdminUsers || selectedAdminUsers?.length == 0"
        class="cursor-pointer"
        z-index="60"
        :arrow="false"
        hide-on-click="outside"
      >
        <template #dropdown-toggle>
          <div class="flex-center">
            <SynIcon name="info" class="fill-red-500" />
          </div>
        </template>
        <template #dropdown-menu>
          <div class="p-4 bg-gray-50 rounded-lg">
            <span class="text-red-500 text-sm">
              {{ $t('DEPARTMENT_LABEL_REQUIRE_TOTAL_ADMIN') }}
            </span>
          </div>
        </template>
      </VigDropdown>
    </div>
  </div>

  <div class="pl-2 flex-1 overflow-hidden grid grid-cols-2 gap-3">
    <div class="h-full small-scrollbar overflow-auto px-2">
      <template v-if="unSelectedUsers.length < 1">
        <SynAnimation
          name="searchIcon"
          stype="width: 100px; height: 100px"
          :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
        />
      </template>
      <template v-else>
        <div
          v-for="user in unSelectedUsers"
          :key="user"
          v-vig-tooltip="user.lastName + ' ' + user.firstName"
          class="
            py-2
            pr-2
            cursor-pointer
            flex
            items-center
            justify-between
            space-x-2
            px-1
            text-gray-700
            hover:bg-current-50
            rounded-md
            text-sm
          "
          @click="onSelectMember(user)"
        >
          <SynIcon
            name="checkbox"
            class="fill-current"
            has-action
            :is-active="user?.isSelected"
            custom-class="w-3.5 h-3.5"
          />
          <UserById
            :user-id="user.userId"
            class="flex-1"
            avatar-class="w-8 h-8"
          >
            <template #subName>
              <div
                v-if="
                  user.departmentInfos?.length > 0 &&
                  user.departmentInfos[0]?.id !== departmentId
                "
                class="flex-center space-x-1"
              >
                <DepartmentById
                  :department-id="user.departmentInfos[0]?.id"
                  is-hidden-avatar
                  class="text-xs text-gray-500"
                />
                <span
                  v-if="user.departmentInfos[0]?.role == GroupMemberRole.Admin"
                  class="text-xs text-gray-500"
                >
                  ({{ $t('COMMON_LABEL_ADMIN') }})
                </span>
              </div>
            </template>
          </UserById>
        </div>
      </template>
    </div>

    <div class="h-full small-scrollbar overflow-auto pr-2 relative">
      <template v-if="selectedUsers.length < 1">
        <div v-if="isLoading" class="flex-center space-x-3 mt-3">
          <div class="w-8 p-2 bg-gray-200 rounded-lg animate-pulse"></div>
          <div class="w-full bg-gray-200 p-2 rounded-lg animate-pulse"></div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="user in selectedUsers"
          :key="user"
          class="
            py-2
            my-1
            p-0.5
            custom-display
            bg-current-50 bg-opacity-50
            rounded-md
          "
        >
          <div class="flex justify-between items-center px-2">
            <UserById
              :user-id="user.userId"
              class="flex-1 text-sm"
              avatar-class="w-8 h-8"
            >
              <template #subName>
                <div
                  v-if="user.departmentInfos?.length > 0"
                  class="flex-center space-x-1"
                >
                  <DepartmentById
                    :department-id="user.departmentInfos[0]?.id"
                    is-hidden-avatar
                    class="text-xs text-gray-500"
                  />
                  <span
                    v-if="
                      user.departmentInfos[0]?.role == GroupMemberRole.Admin
                    "
                    class="text-xs text-gray-500"
                  >
                    ({{ $t('COMMON_LABEL_ADMIN') }})
                  </span>
                </div>
              </template>
            </UserById>

            <!--  TYPE          -->
            <div class="flex items-center space-x-2">
              <div class="flex">
                <span
                  v-if="user.role === GroupMemberRole.Admin"
                  class="
                    rounded-md
                    text-2xs
                    py-0.5
                    px-1
                    text-gray-500
                    admin-text
                  "
                >
                  {{ `(${$t('COMMON_LABEL_ADMIN')})` }}
                </span>
                <small
                  v-if="user.role === GroupMemberRole.Admin"
                  class="
                    text-red-500
                    hover:text-red-700 hover:font-medium
                    italic
                    pr-2
                    cursor-pointer
                  "
                  @click="updateMemberRole(user.userId, GroupMemberRole.User)"
                >
                  <span class="action-text">
                    {{ $t('GROUP_UPDATE_MEMBER_UNSET_ADMIN') }}
                  </span>
                </small>
                <small
                  v-else
                  class="
                    text-green-500
                    hover:text-green-700 hover:font-medium
                    italic
                    action-text
                    pr-2
                    cursor-pointer
                  "
                  @click="updateMemberRole(user.userId, GroupMemberRole.Admin)"
                >
                  <span>{{ $t('GROUP_UPDATE_MEMBER_SET_ADMIN') }}</span>
                </small>
              </div>
              <SynIcon
                v-vig-tooltip="$t('COMMON_LABEL_DELETE')"
                has-action
                name="cancel"
                color="gray"
                custom-class="fill-gray-500 w-2 h-2"
                @click="removeMember(user.userId)"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.item-domain:hover .item-cancel {
  display: block;
}

.item-cancel {
  display: none;
}

.action-text {
  display: none;
}

.custom-display:hover .action-text {
  display: block;
}

.admin-text {
  display: block;
}
.custom-display:hover .admin-text {
  display: none;
}
</style>
