export interface IFullCalendarEvent {
    id: string;
    title: string;
    start: string;
    end: string;
    allDay: boolean;
    editable: boolean;
    extendedProps: any;
}

export default class FullCalendarEventClass implements IFullCalendarEvent {
    id: string;
    title: string;
    start: string;
    end: string;
    allDay: boolean;
    editable: boolean;
    extendedProps: any;

    constructor(data: any) {
        this.id = data?.id;
        this.title = data?.title || '';
        this.start = data?.start || '';
        this.end = data?.end;
        this.allDay = data?.allDay;
        this.editable = !!data?.editable;
        this.extendedProps = data?.extendedProps;
    }
}
