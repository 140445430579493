<script setup lang="ts">
import { ref } from 'vue';
import { useElementHover } from '@vueuse/core';
import { getDynamicPosition } from '@/ui/hooks/commonFunction';

const emit = defineEmits(['onChangeStatus', 'toggleChangeStatus']);

const cardPosition = ref({ x: 0, y: 0 });
const cardTaskSize = {
  width: 240,
  height: 45,
};
const isShowTooltip = ref(false);
let hoverTimer;
const buttonReactionCommentTooltipRef = ref();
const isFocusOnTooltip = useElementHover(buttonReactionCommentTooltipRef);

const onHoverReactionButton = (event) => {
  hoverTimer && clearTimeout(hoverTimer);
  hoverTimer = setTimeout(() => {
    const targetPosition = event?.target?.getBoundingClientRect();

    if (!targetPosition || !targetPosition.width || !targetPosition.height)
      return;
    cardPosition.value = getDynamicPosition({
      x: targetPosition.left,
      y: targetPosition.top,
      width: cardTaskSize.width,
      height: cardTaskSize.height,
      padding: 10,
    });

    isShowTooltip.value = true;
  }, 250);
};
let leaveTimer;

const onMouseLeaveReactionButton = () => {
  hoverTimer && clearTimeout(hoverTimer);
  leaveTimer && clearTimeout(leaveTimer);
  leaveTimer = setTimeout(() => {
    if (isFocusOnTooltip.value) return;
    isShowTooltip.value = false;
  }, 500);
};

const isOpenVigEmojiPicker = ref(false);
const onMouseLeaveTooltip = () => {
  setTimeout(() => {
    if (isOpenVigEmojiPicker.value || isFocusOnTooltip.value) return;
    isShowTooltip.value = false;
  }, 250);
};
</script>
<template>
  <div
    @mouseover="onHoverReactionButton"
    @mouseleave="onMouseLeaveReactionButton"
  >
    <slot name="body"></slot>
  </div>

  <teleport to="body">
    <div
      v-if="isShowTooltip"
      ref="buttonReactionCommentTooltipRef"
      class="
        absolute
        top-0
        left-0
        z-100
        rounded-full
        shadow
        p-1
        bg-white
        flex-center
      "
      :style="[
        `top: ${cardPosition.y}px`,
        `left: ${cardPosition.x}px`,
        'height: 45px',
        'width: 240px',
      ]"
      @mouseleave="onMouseLeaveTooltip"
    >
      <slot name="popup"></slot>
    </div>
  </teleport>
</template>
