const FR = {
    dayNames: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
    ],
    monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ],
    tokens: {
        SKIP: /^[ \r\n\t]+|^\.$/,
        number: /^[1-9][0-9]*/,
        numberAsText: /^(one|two|three)/i,
        chaque: /^every/i,
        'jour(s)': /^days?/i,
        'jour(s) de la semaine': /^weekdays?/i,
        'semaine(s)': /^weeks?/i,
        'heure(s)': /^hours?/i,
        'minute(s)': /^minutes?/i,
        mois: /^months?/i,
        'année(s)': /^years?/i,
        sur: /^(on|in)/i,
        à: /^(at)/i,
        le: /^the/i,
        premier: /^first/i,
        deuxième: /^second/i,
        troisième: /^third/i,
        nth: /^([1-9][0-9]*)(\.|th|nd|rd|st)/i,
        dernier: /^last/i,
        pour: /^for/i,
        fois: /^times?/i,
        "jusqu'à": /^(un)?til/i,
        lundi: /^mo(n(day)?)?/i,
        mardi: /^tu(e(s(day)?)?)?/i,
        mercredi: /^we(d(n(esday)?)?)?/i,
        jeudi: /^th(u(r(sday)?)?)?/i,
        vendredi: /^fr(i(day)?)?/i,
        samedi: /^sa(t(urday)?)?/i,
        dimanche: /^su(n(day)?)?/i,
        janvier: /^jan(uary)?/i,
        février: /^feb(ruary)?/i,
        mars: /^mar(ch)?/i,
        avril: /^apr(il)?/i,
        mai: /^may/i,
        juin: /^june?/i,
        juillet: /^july?/i,
        août: /^aug(ust)?/i,
        septembre: /^sep(t(ember)?)?/i,
        octobre: /^oct(ober)?/i,
        novembre: /^nov(ember)?/i,
        décembre: /^dec(ember)?/i,
        comma: /^(,\s*|(and|or)\s*)+/i,
    },
};
export default FR;
