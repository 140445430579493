<script setup lang="ts">
import { computed } from 'vue';
import { PackagePrice, iconPlans } from '@/ui/common/constants/plans';
import paymentStore from '@/store/payment';
import { IPaymentConfig } from '@/application/types/payment-configs.types';

withDefaults(
  defineProps<{
    currentPlanId: 'PRO' | 'VIP';
    remainDays: number;
  }>(),
  {
    currentPlanId: 'PRO',
    remainDays: 30,
  }
);

defineEmits<{
  (e: 'cancel'): void;
}>();
const _paymentStore = paymentStore();

const paymentConfigs = computed<IPaymentConfig>(
  () => _paymentStore.paymentConfigs
);

const getCustomClass = (planId) => {
  switch (planId) {
    case PackagePrice.PRO.key:
      return {
        customClassBody: 'border-current-100',
        customClassHeader: 'bg-current-300 text-white border-current-100',
      };
    case PackagePrice.VIP.key:
      return {
        customClassBody: 'border-current-200',
        customClassHeader: 'border-current-200 bg-current-400 text-white',
      };
    default:
      break;
  }
};
</script>
<template>
  <SynModal
    container-class="w-max"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    @cancel="$emit('cancel')"
  >
    <template #background>
      <AtomCongraturationBackground />
    </template>
    <template #body>
      <div class="flex-center flex-col space-y-8" style="max-width: 40rem">
        <div
          class="flex-center w-full p-4"
          :class="getCustomClass(currentPlanId)?.customClassHeader"
        >
          <SynIcon :name="iconPlans[currentPlanId]" custom-class="w-24 h-24" />
        </div>
        <div class="flex flex-col text-center space-y-4 px-8">
          <span class="text-lg font-semibold text-gray-600 uppercase">
            {{
              $t('PAYMENT_LABEL_SUCCESSFUL_TRIAL_REGISTRATION') ||
              'Thanks for upgrading!'
            }}
          </span>

          <span
            class="text-gray-500"
            v-html="
              $t('PAYMENT_THANK_FOR_CHOOSE_TRIAL_BODY', {
                planId: currentPlanId,
                remainDays: paymentConfigs?.paymentBonus?.defaultTrial || 15,
              })
            "
          >
          </span>
        </div>
        <div class="w-full flex items-center justify-end space-x-2">
          <SynButton
            :title="'Join an existed workspace'"
            class="bg-current"
            @click="$emit('cancel')"
          >
            <span>
              {{ $t('COMMON_LABEL_GOT_IT') || 'Got it' }}
            </span>
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
