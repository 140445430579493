<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        clip-rule="evenodd"
        d="m9.53151 6.41438c-.32345.25875-.37589.73072-.11713 1.05417l3.62522 4.53145-3.62522 4.5315c-.25876.3235-.20632.7954.11713 1.0542.32344.2587.79539.2063 1.05419-.1172l4-5c.2191-.2739.2191-.6631 0-.937l-4-4.99999c-.2588-.32345-.73075-.37589-1.05419-.11713z"
        fill-rule="evenodd"
      ></path>
    </g>
  </svg>
</template>
