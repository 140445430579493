<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g id="Layer_2" xmlns="http://www.w3.org/2000/svg" data-name="Layer 2">
        <path
          d="m6.707 5.293a1 1 0 0 0 -1.414 0l-3 3a1 1 0 1 0 1.414 1.414l1.293-1.293v17.586a1 1 0 0 0 2 0v-17.586l1.293 1.293a1 1 0 0 0 1.414-1.414z"
        ></path>
        <path d="m14 20h12a1 1 0 0 0 0-2h-12a1 1 0 0 0 0 2z"></path>
        <path d="m29 24h-15a1 1 0 0 0 0 2h15a1 1 0 0 0 0-2z"></path>
        <path d="m14 14h9a1 1 0 0 0 0-2h-9a1 1 0 0 0 0 2z"></path>
        <path d="m14 8h5a1 1 0 0 0 0-2h-5a1 1 0 0 0 0 2z"></path>
      </g>
    </g>
  </svg>
</template>
