<script setup lang="ts">
import { onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: number;
    step?: number;
    errorMessage?: string;
    customClass?: string;
    unit?: string;
    min?: number;
    max?: number;
    disabled?: boolean;
  }>(),
  {
    modelValue: 0,
    step: 1,
    min: 0,
    max: undefined,
  }
);
const emit = defineEmits(['update:modelValue', 'enter', 'focus']);

const synInputNumberRef = ref<any>(null);
onMounted(() => {
  synInputNumberRef.value?.focus();
});

const decrement = () => {
  emit(
    'update:modelValue',
    props.modelValue - props.step < props.min
      ? props.modelValue
      : props.modelValue - props.step
  );
};
const increment = () => {
  emit(
    'update:modelValue',
    props.max && +props.modelValue + props.step > props.max
      ? +props.modelValue
      : +props.modelValue + props.step
  );
};
</script>
<template>
  <div
    class="w-full custom-number-input rounded flex-center"
    v-bind="$attrs"
    :class="customClass ? customClass : 'border'"
  >
    <div class="flex-center h-full w-full py-0.5 px-2 relative">
      <button
        class="
          text-xl
          flex-center
          w-7
          h-7
          rounded
          font-medium
          rounded-l
          cursor-pointer
          outline-none
          focus:outline-none
        "
        :class="[
          disabled
            ? 'bg-gray-200 text-gray-500'
            : 'text-current-700 bg-current-50 hover:bg-current-100',
        ]"
        :disabled="disabled"
        @click="decrement"
      >
        <SynIcon name="minus" class="fill-current-700" />
      </button>

      <slot>
        <input
          ref="synInputNumberRef"
          type="number"
          class="
            rounded-md
            p-0
            py-2
            mx-1
            text-center
            flex-1
            text-sm
            hover:text-black
            focus:bg-gray-100 focus:font-semibold focus:text-current-600
            placeholder-gray-500 placeholder-opacity-75
            md:text-base
            cursor-default
            items-center
            text-gray-700
            border-none
            focus:ring-0
            bg-transparent
          "
          style="min-width: 1rem"
          name="custom-input-number"
          :value="modelValue"
          :step="step"
          :min="min"
          :max="max"
          @input="$emit('update:modelValue', $event.target.value)"
          @keyup.enter.prevent="$emit('enter')"
          @focus="$emit('focus')"
        />
      </slot>
      <button
        class="
          text-xl
          font-medium
          w-7
          h-7
          rounded
          flex-center
          rounded-r
          cursor-pointer
          outline-none
          focus:outline-none
        "
        :class="[
          disabled
            ? 'bg-gray-200 text-gray-500'
            : 'text-current-700 bg-current-50 hover:bg-current-100',
        ]"
        style="line-height: 0"
        :disabled="disabled"
        @click="increment"
      >
        <SynIcon name="plus" custom-class="w-3 h-3 fill-current-700" />
      </button>
    </div>
  </div>

  <span
    v-if="errorMessage"
    class="
      flex
      items-center
      font-medium
      tracking-wide
      text-red-500 text-xs
      mt-1
      ml-1
    "
  >
    {{ errorMessage }}
  </span>
</template>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
