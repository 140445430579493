<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineProps<{
  modelValue: string;
  placeholder?: string;
}>();
const emit = defineEmits(['update:modelValue', 'onAddressSelect']);

const inputAddressRef = ref();

onMounted(() => {
  _initPlaceAutocomplete();
});

const _initPlaceAutocomplete = () => {
  try {
    const placeAutoComplete = new window.google.maps.places.Autocomplete(
      inputAddressRef.value,
      {
        types: ['address'],
      }
    );

    // Set initial restrict to the greater list of countries.
    placeAutoComplete.setComponentRestrictions({ country: ['vn', 'fr'] });
    placeAutoComplete.setFields([
      'address_components',
      'geometry',
      'formatted_address',
    ]);

    placeAutoComplete.addListener('place_changed', () => {
      const place = placeAutoComplete.getPlace();

      if (place.geometry === undefined || place.geometry === null) {
        return emit('onAddressSelect', null);
      }

      let address = {
        fullAddress: null,
        number: null,
        street: null,
        city: null,
        country: null,
        zipCode: null,
        latitude: 0,
        longitude: 0,
      };

      address.fullAddress = place.formatted_address;

      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];

        switch (addressType) {
          case 'street_number':
            address.number = place.address_components[i]['long_name'];
            break;
          case 'route':
            address.street = place.address_components[i]['long_name'];
            break;
          case 'locality':
            address.city = place.address_components[i]['long_name'];
            break;
          // case 'administrative_area_level_1':
          //   this.fournisseur.address.state = place.address_components[i]['long_name'];
          //   break;
          case 'country':
            address.country = place.address_components[i]['short_name'];
            break;
          case 'postal_code':
            address.zipCode = place.address_components[i]['short_name'];
            break;
        }
      }

      address.latitude = place.geometry.location.lat();
      address.longitude = place.geometry.location.lng();

      console.log(
        '🚀 Hyrin ~ placeAutoComplete.addListener ~ inputAddressRef.value',
        inputAddressRef.value
      );
      emit('update:modelValue', inputAddressRef.value?.value);
      return emit('onAddressSelect', address);
    });
  } catch (e) {
    console.log(e);
  }
};
</script>
<template>
  <input
    ref="inputAddressRef"
    :value="modelValue"
    class="
      vig-input
      w-full
      h-full
      px-4
      rounded
      placeholder-gray-500 placeholder-opacity-75
    "
    maxlength="150"
    type="text"
    :placeholder="placeholder"
    @input="(event) => $emit('update:modelValue', event?.target?.value)"
  />
</template>
