import dayjs from 'dayjs';
import { EventDetailItemClass } from './../calendars/CalendarsClass';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { verifyFiles } from '@/ui/hooks/fileHook';
import { CalendarsType } from '@/domain/enums/CalendarsEnums';
import NoteClass from '@/application/services/attachment/NoteClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import AttachmentClass from '@/application/services/attachment/AttachmentClass';
import remoteConfigStore from '@/store/remoteConfig';
import {
    getMetaData,
    getRRuleItemCalendar,
    getTaskFilterOptions,
} from '@/application/parsers/CalendarParser';
import {
    CalendarId,
    CalendarNotificationType,
    CalendarReminderRepeatType,
    CalendarSourceType,
} from '@/ui/common/constants/calendar';
import { formatDate } from '@/ui/plugins/filters';
import { CalendarSourceId, CalendarType } from '@/domain/enums/CalendarEnum';

export class CalendarFilter {
    assigneeId: number;
    calendarId: number;
    sourceTypes: any[];
    types: any[];
    startDate: string;
    endDate: string;
    taskFilterOptions: {
        urgencies: any[];
        myTask: number;
        imAssignTask: number;
    };

    constructor(data: any) {
        this.assigneeId = data.assigneeId || getCurrentUserId();
        this.calendarId = data.calendarId || CalendarId.Event;
        this.sourceTypes = data.sourceTypes || [CalendarSourceType.Event];
        this.startDate = data.startDate || this.getDefaultStartDate();
        this.endDate = data.endDate || this.getDefaultEndDate();
        this.types = data.types || [];
        this.taskFilterOptions =
            data.taskFilterOptions || getTaskFilterOptions();
    }

    private getDefaultStartDate() {
        const dateNew = new Date(dayjs().startOf('month').toString());
        const dayinWeek = dateNew.getDay();
        const resultDate = dayjs(dateNew).subtract(dayinWeek - 1, 'day') as any;
        return new Date(resultDate);
    }

    private getDefaultEndDate() {
        const dateNew = new Date(dayjs().endOf('month').toString());
        const dayinWeek = dateNew.getDay();
        const resultDate = dayjs(dateNew).subtract(dayinWeek - 1, 'day') as any;
        return new Date(resultDate);
    }
}

export class CalendarCreatePayload {
    calendarId: number;
    sourceId: string;
    sourceType: number;
    title: string;
    description: string;
    type: number;
    status: number;
    startDate: string;
    endDate: string;
    startTime: object;
    endTime: object;

    metadata: {
        participantIds: any[];
        workspace: string;
        meetingDuration: number;
        notificationType: number;
        allDay: boolean;
        repeatType: number;
        meetingType: number;
        attachments: object;
    };

    constructor(data: any) {
        this.calendarId = data.calendarId || 0;
        this.sourceId = data.sourceId || '';
        this.sourceType = data.sourceType || 0;
        this.title = data.title || '';
        this.description = data.description || '';
        this.type = data.type || 1;
        this.status = data.status || 1;
        this.startDate = data.startDate || '';
        this.endDate = data.endDate || '';
        this.startTime = data.startTime || {};
        this.endTime = data.endTime || {};
        this.metadata = data?.metadata || getMetaData(data);
    }
}

export class CalendarUpdatePayload {
    id: string;
    calendarId: number;
    sourceId: string;
    sourceType: number;
    title: string;
    description: string;
    type: number;
    status: number;
    startDate: string;
    endDate: string;
    startTime: object;
    endTime: object;

    metadata: {
        allDay: boolean;
        participantIds: any[];
        workspace: string;
        meetingDuration: number;
        notificationType: number;
        repeatType: number;
        repeatSpecificDaysOfWeek: any[];
        repeatRRules?: string[];
        meetingType: number;
        attachments: object;
    };

    constructor(data: any) {
        this.id = data.id || '000-000-000';
        this.calendarId = data.calendarId || 0;
        this.sourceId = data.sourceId || '';
        this.sourceType = data.sourceType || 0;
        this.title = data.title || '';
        this.description = data.description || '';
        this.type = data.type || 1;
        this.status = data.status || 1;
        this.startDate = data.startDate || '';
        this.endDate = data.endDate || '';
        this.startTime = data.startTime || {};
        this.endTime = data.endTime || {};
        this.metadata = getMetaData(data);
    }
}

export class CalendarItem {
    id: string;
    title: string;
    start: string;
    end: string;
    allDay?: boolean;
    editable: boolean;
    type: 'EVENT' | 'DAYOFF';
    extendedProps: EventDetailItemClass;
    rrule: string;

    constructor(data: any) {
        this.id = data?.id;
        this.title = data?.title;
        this.start = data.startDate;
        this.end = data?.endDate;
        this.allDay = data?.metadata?.allDay;

        // Fix tạm cho reminder
        if (data.type === CalendarType.Reminder) {
            this.end = this.allDay
                ? dayjs(this.start).add(1, 'day').toISOString()
                : dayjs(this.start).toISOString();
        }

        this.editable =
            !(
                data.repeatType &&
                data.repeatType !== CalendarReminderRepeatType.DoNotRepeat
            ) && data?.sourceId !== CalendarSourceId.googleCalendar;

        this.type = data?.type;
        this.extendedProps = new EventDetailItemClass(
            data.extendedProps || data
        );
        this.rrule =
            data.rrule ||
            getRRuleItemCalendar(
                data.startDate,
                data.repeatType,
                data.metadata.repeatSpecificDaysOfWeek,
                data.metadata.repeatRRules
            );
    }
}

export class CalendarItemSystem {
    id: string;
    title: string;
    start: string;
    end: string;
    allDay: boolean;
    editable: boolean;
    extendedProps: {
        path: string;
        allDay: boolean;
        type: number;
        calendarType: string;
        system: boolean;
        titleEvent: string;
    };

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title || '';
        this.start = data.startDate || '';
        this.end = dayjs(data.endDate).add(1, 'day').toISOString();
        this.allDay = true;
        this.editable = false;
        this.extendedProps = {
            path: data.path,
            allDay: true,
            type: data.type || 1,
            system: data.system,
            calendarType: data.calendarType || CalendarsType.EVENT,
            titleEvent: data.title || '',
        };
    }
}

// from create to detaile
export interface ICalendarDetail {
    id: string;
    calendarId: number;
    sourceId: string;
    sourceType: number;
    title: string;
    description: string;
    type: number;
    status: number;
    startDate: Date;
    endDate: Date;
    startTime: object;
    endTime: object;
    participantIds: any[];
    workspace: string;
    meetingDuration: number;
    notificationType: number;
    allDay: boolean;
    repeatSpecificDaysOfWeek: any[];
    repeatType: number;
    meetingType: number;
    files: any[];
    notes: any[];
}

export class CalendarDetail {
    id: string;
    calendarId: number;
    sourceId: string;
    sourceType: number;
    title: string;
    description: string;
    type: number;
    status: number;
    startDate: Date;
    endDate: Date;
    startTime: object;
    endTime: object;
    participantIds: any[];
    workspace: string;
    meetingDuration: number;
    notificationType: number;
    allDay: boolean;
    repeatSpecificDaysOfWeek: any[];
    repeatType: number;
    meetingType: number;
    files: any[];
    notes: any[];

    constructor(data: any) {
        this.id = data.id || '000-000-000';
        this.calendarId = data.calendarId || 0;
        this.sourceId = data.sourceId || '';
        this.sourceType = data.sourceType || 0;
        this.title = data.title || '';
        this.description = data.description || '';
        this.type = data.type || 1;
        this.status = data.status || 1;
        this.startDate = data.startDate || '';
        this.endDate = data.endDate || '';
        this.startTime = data.startTime || {};
        this.endTime = data.endTime || {};
        this.workspace = data?.metadata?.workspace || '';
        this.participantIds = data?.metadata?.participantIds || [];
        this.meetingDuration = data?.metadata?.meetingDuration || 15;
        this.notificationType =
            data?.metadata?.notificationType ||
            CalendarNotificationType.NoticeAfter15Minutes;
        this.allDay = data?.metadata?.allDay || false;
        this.repeatSpecificDaysOfWeek =
            data?.metadata?.repeatSpecificDaysOfWeek ||
            data?.metadata?.RepeatSpecificDaysOfWeek ||
            [];
        this.repeatType =
            data?.metadata?.repeatType ||
            CalendarReminderRepeatType.DoNotRepeat;
        this.meetingType = data?.metadata?.meetingType || 1;
        this.files = data?.metadata?.files || [];
        this.notes = data?.metadata?.notes || [];
    }

    addNote(noteData: any) {
        if (noteData?.name) {
            const noteIndex = this.notes.findIndex(
                (o) => o.name == noteData.name
            );

            if (noteIndex > -1) {
                this.notes[noteIndex].fileUrl = noteData?.base64;
                this.notes[noteIndex].fileBlob = Buffer.from(
                    noteData?.base64.replace(/^data:image\/\w+;base64,/, ''),
                    'base64'
                );
                this.notes[noteIndex].drawData = noteData?.drawData;
            }
        } else {
            const time = formatDate(new Date(), 'YYYY-MM-DD hh.mm A');
            const newNote = new NoteClass(ModuleSourceFileEnum.calendar, {
                name: `Note-Event ${time}.jpeg`,
                base64: noteData?.base64,
                type: 'image/jpeg',
                size: noteData?.base64?.length * (3 / 4) - 2,
                drawData: noteData?.drawData,
            });

            this.notes.push({
                ...newNote,
                isNote: true,
            });
        }
    }

    async addFiles(files: any[]) {
        const _remoteConfigStore = remoteConfigStore();

        verifyFiles(
            files,
            _remoteConfigStore.webTaskUploadContentTypes,
            _remoteConfigStore.webTaskUploadMaxFilesize,
            (file) => {
                const newFile = new AttachmentClass(
                    ModuleSourceFileEnum.calendar,
                    file
                );
                this.files.push(newFile);
            }
        );
    }

    removeFile(file: any) {
        if (!file) return;
        this.files = this.files.filter((o) => o.localId !== file.localId);
    }

    removeNote(note: any) {
        if (!note) return;
        this.notes = this.notes.filter((o) => o.name !== note?.name);
    }
}
