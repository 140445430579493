<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

defineProps({
  drawerName: {
    type: String,
    default: 'Drawer',
  },
});

const emit = defineEmits(['onOpenDrawer']);

const appElm = document.getElementById('app') as HTMLElement;
onMounted(() => {
  appElm.addEventListener('click', onClickOutSideTaskDrawer);
});

onUnmounted(() => {
  appElm.removeEventListener('click', onClickOutSideTaskDrawer);
});
const isOpenVigDrawer = ref(false);

const onClickOutSideTaskDrawer = (event) => {
  const taskDetailElm = document.getElementById('task-detail-page');
  if (
    vigDrawerRef.value?.contains(event.target) ||
    !isOpenVigDrawer.value ||
    taskDetailElm?.contains(event.target)
  )
    return;

  isOpenVigDrawer.value = false;
};

const vigDrawerRef = ref(null as any);

const open = () => {
  isOpenVigDrawer.value = true;
};

const close = () => {
  isOpenVigDrawer.value = false;
};

const onOpenDrawer = () => {
  isOpenVigDrawer.value = !isOpenVigDrawer.value;
  emit('onOpenDrawer', isOpenVigDrawer.value);
};

defineExpose({
  open,
  close,
});
</script>

<template>
  <div
    ref="vigDrawerRef"
    class="
      absolute
      top-0
      right-0
      flex
      h-full
      z-30
      transition
      duration-300
      transform
      bg-gray-200
      shadow
      border-l
    "
    :class="[
      isOpenVigDrawer ? 'translate-x-0 ease-out' : 'translate-x-full ease-in',
    ]"
    style="width: 35%; max-width: 100%; min-width: 39rem"
  >
    <slot></slot>

    <div
      :title="drawerName"
      class="
        absolute
        -left-8
        w-8
        top-28
        bg-secondary
        shadow-xl
        flex-center
        cursor-pointer
        rounded-l-lg
      "
      style="height: 7.5rem"
      @click="onOpenDrawer"
    >
      <div
        class="
          absolute
          transform
          -rotate-90
          font-semibold
          flex
          items-center
          text-current-800
        "
      >
        <span> {{ drawerName }} </span>
      </div>
      <SynIcon
        custom-class="absolute top-4 w-2 h-2 fill-current transform"
        :class="isOpenVigDrawer ? '' : 'rotate-180'"
        name="play"
      />
    </div>
  </div>
</template>
