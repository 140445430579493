<script setup lang="ts">
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: string;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const codeInputValue = ref<string>(props.modelValue);

const inputRef = ref<any>(null);
const dropdownRef = ref<any>(null);

const listenOpenDropdown = (isOpen) => {
  if (isOpen) {
    setTimeout(() => {
      inputRef.value.focus();
    });
  } else {
    emit('update:modelValue', codeInputValue.value);
  }
};

const onForceClose = () => {
  dropdownRef.value?.onForceClose();
};
</script>
<template>
  <VigDropdown
    ref="dropdownRef"
    placement="top"
    @click.stop="() => {}"
    @update:is-open="listenOpenDropdown"
  >
    <template #dropdown-toggle>
      <span class="truncate text-gray-800 cursor-pointer">
        {{ modelValue }}
      </span>
    </template>
    <template #dropdown-menu>
      <div class="p-4">
        <SynInput
          ref="inputRef"
          v-model="codeInputValue"
          autofocus
          :placeholder="$t('WORKFLOW_LABEl_STEP_NAME')"
          @enter="onForceClose"
        />
      </div>
    </template>
  </VigDropdown>
</template>
