import EmbedChatLogin from '@/embed/pages/chat/EmbedChatLogin.vue';
import EmbedChatLayout from '@/embed/pages/layouts/EmbedChatLayout.vue';
import EmbedChatsList from '@/embed/pages/chat/EmbedChatsList.vue';
import EmbedChatWidget from '@/embed/pages/chat/EmbedChatWidget.vue';

export default [
    {
        path: 'login',
        name: 'EmbedChatLogin',
        component: EmbedChatLogin,
    },
    {
        path: '',
        component: EmbedChatLayout,
        children: [
            {
                path: 'list',
                name: 'EmbedChatsList',
                component: EmbedChatsList,
                meta: { layout: 'embed-chat' },
            },
            {
                path: 'widget',
                name: 'EmbedChatWidget',
                component: EmbedChatWidget,
                meta: { layout: 'embed-chat' },
            },
            {
                path: '',
                redirect: { name: 'EmbedChatsList' },
            },
        ],
    },
];
