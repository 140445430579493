<script setup lang="ts">
import { PaymentTransactionLog } from '@/application/interfaces/payment/TransactionInterfaces';
import { computed } from 'vue';

const props = defineProps<{
  transactionDetail: PaymentTransactionLog;
}>();

const licenseCode = computed<string>(() => {
  const codeList = props.transactionDetail?.licenseInfo?.licenseKey?.split('-');
  const encodeCodeList = codeList?.map((o, index) => {
    return index == 0 || index == codeList?.length ? o : '****';
  });
  return encodeCodeList?.join('-') || '';
});
</script>
<template>
  <div class="flex flex-col space-y-2 p-3 border border-gray-100 rounded">
    <div class="flex items-center space-x-2 pb-2">
      <span class="text-gray-700 font-medium text-sm">
        {{ $t('BILLING_MODAL_INFORMATION_LICENSE_TITLE') }}
      </span>
      <SynIcon name="license" />
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">
        {{ $t('BILLING_INFORMATION_LICENSE_KEY') }}
      </span>
      <span>
        {{ licenseCode || '' }}
      </span>
    </div>
    <div
      class="
        flex
        justify-between
        items-center
        text-sm
        mx-2
        py-1
        border-b border-gray-50
      "
    >
      <span class="text-gray-400">
        {{ $t('SETTING_PLAN_PAYMENT_TOTAL_MEMBER_LABEL') }}
      </span>

      <span>
        {{ transactionDetail?.licenseInfo?.totalMember || '' }}
      </span>
    </div>

    <!-- Price -->
    <div class="flex justify-between items-center text-sm mx-2 py-1">
      <span class="text-gray-400 font-medium">{{
        $t('BILLING_MODAL_AMOUNT_TITLE')
      }}</span>
      <div class="flex flex-col items-end">
        <span class="font-medium text-xl text-current-500">
          {{
            $filters.currency(transactionDetail?.licenseInfo?.totalAmount, 'vi')
          }}
        </span>
      </div>
    </div>
  </div>
</template>
