const _initEmoji = (el, binding) => {
    if (typeof twemoji !== 'undefined') {
        // eslint-disable-next-line no-undef
        twemoji.parse(el, {
            folder: 'png',
            ext: '.png',
            base: 'https://d1tvqetrcurhzb.cloudfront.net/common/twemoji/',
        });
    }

    let { size } = binding.value || {};
    size = size || 'w-5 h-5';

    const emojiElements = el.querySelectorAll('.emoji');
    emojiElements.forEach((emojiEl) =>
        emojiEl.classList.add(...size.split(' '))
    );
};

export default {
    install: (app) => {
        app.directive('twemoji', {
            mounted(el, binding) {
                // _initEmoji(el, binding);
                setTimeout(() => _initEmoji(el, binding));
            },
            updated(el, binding) {
                _initEmoji(el, binding);
            },
        });
    },
};
