import {
    getDatabase,
    ref,
    onValue,
    DatabaseReference,
    onChildAdded,
    onChildChanged,
    onChildRemoved,
    Unsubscribe,
    update,
    set,
} from 'firebase/database';
export default class BaseModel {
    dataRef: DatabaseReference;
    constructor(sourcePath: string) {
        const db = getDatabase();
        this.dataRef = ref(db, sourcePath);
    }

    getOnce(callBack): Unsubscribe {
        return onValue(
            this.dataRef,
            (snapshot) => {
                callBack(snapshot.val());
            },
            {
                onlyOnce: true,
            }
        );
    }

    onChange(callback: (_data: any) => void): Unsubscribe {
        return onValue(this.dataRef, (snapshot) => {
            callback(snapshot.val());
        });
    }

    onChildAdded(callback: (_data: any, _key: any) => void): Unsubscribe {
        return onChildAdded(this.dataRef, (data) => {
            const dataValue = data.val();
            callback(dataValue, data.key);
        });
    }

    onChildChanged(callback: (_data: any, _key: any) => void): Unsubscribe {
        return onChildChanged(this.dataRef, (data) => {
            const dataValue = data.val();
            callback(dataValue, data.key);
        });
    }

    onChildRemoved(callback: (_data: any, _key: any) => void): Unsubscribe {
        return onChildRemoved(this.dataRef, (data) => {
            const dataValue = data.val();
            callback(dataValue, data.key);
        });
    }

    updateData(updates) {
        return update(this.dataRef, updates);
    }
    setData(updates) {
        return set(this.dataRef, updates);
    }
}
