<template>
  <svg
    v-if="!isActive"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M352.459,220c0-11.046-8.954-20-20-20h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206     C343.505,240,352.459,231.046,352.459,220z"
          />
          <path
            d="M126.459,280c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20H251.57c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20     H126.459z"
          />
          <path
            d="M173.459,472H106.57c-22.056,0-40-17.944-40-40V80c0-22.056,17.944-40,40-40h245.889c22.056,0,40,17.944,40,40v123     c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80c0-44.112-35.888-80-80-80H106.57c-44.112,0-80,35.888-80,80v352     c0,44.112,35.888,80,80,80h66.889c11.046,0,20-8.954,20-20C193.459,480.954,184.505,472,173.459,472z"
          />
          <path
            d="M467.884,289.572c-23.394-23.394-61.458-23.395-84.837-0.016l-109.803,109.56c-2.332,2.327-4.052,5.193-5.01,8.345     l-23.913,78.725c-2.12,6.98-0.273,14.559,4.821,19.78c3.816,3.911,9,6.034,14.317,6.034c1.779,0,3.575-0.238,5.338-0.727     l80.725-22.361c3.322-0.92,6.35-2.683,8.79-5.119l109.573-109.367C491.279,351.032,491.279,312.968,467.884,289.572z      M333.776,451.768l-40.612,11.25l11.885-39.129l74.089-73.925l28.29,28.29L333.776,451.768z M439.615,346.13l-3.875,3.867     l-28.285-28.285l3.862-3.854c7.798-7.798,20.486-7.798,28.284,0C447.399,325.656,447.399,338.344,439.615,346.13z"
          />
          <path
            d="M332.459,120h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206c11.046,0,20-8.954,20-20S343.505,120,332.459,120z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <svg
    v-else
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 458.86 512"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          class="cls-1"
          d="m151.67,507.29l33.56-110.48c1.34-4.42,3.76-8.45,7.03-11.71l154.09-153.75c16.41-16.41,37.98-24.61,59.54-24.6v-126.18c0-44.5-36.07-80.57-80.57-80.57H80.57C36.07,0,0,36.07,0,80.57v350.85c0,44.5,36.07,80.57,80.57,80.57h70.11c.2-1.58.51-3.16.98-4.71ZM99.89,120h206c11.05,0,20,8.95,20,20s-8.95,20-20,20H99.89c-11.05,0-20-8.95-20-20s8.95-20,20-20Zm0,80h206c11.05,0,20,8.95,20,20s-8.95,20-20,20H99.89c-11.05,0-20-8.95-20-20s8.95-20,20-20Zm-20,100c0-11.05,8.95-20,20-20h125.11c11.05,0,20,8.95,20,20s-8.95,20-20,20h-125.11c-11.05,0-20-8.95-20-20Z"
        />
        <path
          class="cls-1"
          d="m441.31,289.57c-23.39-23.39-61.46-23.4-84.84-.02l-109.8,109.56c-2.33,2.33-4.05,5.19-5.01,8.34l-23.91,78.72c-2.12,6.98-.27,14.56,4.82,19.78,3.82,3.91,9,6.03,14.32,6.03,1.78,0,3.58-.24,5.34-.73l80.72-22.36c3.32-.92,6.35-2.68,8.79-5.12l109.57-109.37c23.39-23.39,23.39-61.46,0-84.85Zm-60.42,88.72l-28.28-28.28,28.28-28.28,28.28,28.28-28.28,28.28Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
