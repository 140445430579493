<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue';
// import PieChart from '@/ui/common/plugins/chart/PieChart.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import { getBgClass, getDetailTaskUrl } from '@/ui/hooks/taskHook';
import taskStore from '@/store/task';
import taskDrawerStore from '@/store/task/drawer';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import UserByAction from '@/ui/components/user/UserByAction.vue';
// import TaskCreateLocal from '@/ui/modules/task/create/TaskCreateLocal.vue';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
// import SynAvatarRanking from "@/ui/common/molecules/SynAvatar/SynAvatarRanking.vue";
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import finishedTasksComposable from '@/ui/modules/dashboard/monitoring-department/finished-tasks-composable';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import FilterTasks from '@/ui/modules/dashboard/FilterTasks.vue';
import CustomLollipopChart from '@/ui/common/plugins/chart/CustomLollipopChart.vue';
import VerticalBarChart from '@/ui/common/plugins/chart/VerticalBarChart.vue';
import domainStore from '@/store/scope';
import { orderBy } from 'lodash';

const props = defineProps<{
  departmentId?: number;
}>();
const _taskStore = taskStore();
const _taskDrawerStore = taskDrawerStore();

const {
  isLoading,
  todayInfo,
  finishedTasks,
  finishedTaskRankingByMember,
  finishedTaskRankingByDomain,
  // maxFinishedTask,
  finishedTaskDatasets,
  finishedTaskLabels,
  // getAllDataForFinishedTaskPage,
  getFinishedTasks,
  getFinishedTaskRankingByMember,
  getFinishedTaskRankingByDomain,
  getFinishedTaskOverview,
} = finishedTasksComposable(props.departmentId);

const finishedByUserChartData = computed<
  {
    total: number;
    id: number;
    name: string;
    avatar: string;
  }[]
>(() => {
  return orderBy(
    Object.values(finishedTaskRankingByMember.value),
    ['numberOfTaskFinished'],
    ['desc']
  )
    ?.slice(0, 3)
    ?.map((o: any) => {
      return {
        total: o.numberOfTaskFinished,
        id: o.id,
        name: o.name,
        avatar: o.avatar,
      };
    });
});

const initComponent = () => {};

let fbUserDynamicModel: UserDynamicModel;
let unscribleLatestFinishedTaskActivity;
let fbOrganizationDynamicModel: OrganizationDynamicModel;
let unscribleOrgLatestFinishedTaskActivity;

onMounted(() => {
  onListenUserDashboard();
  onListenOrganizationDashboard();
});

onUnmounted(() => {
  unscribleLatestFinishedTaskActivity && unscribleLatestFinishedTaskActivity();
  unscribleOrgLatestFinishedTaskActivity &&
    unscribleOrgLatestFinishedTaskActivity();
});

let lastUpdateTime: number;
const onListenUserDashboard = () => {
  const myUserId = getCurrentUserId();
  if (!myUserId) return;

  if (unscribleLatestFinishedTaskActivity)
    unscribleLatestFinishedTaskActivity();
  fbUserDynamicModel = new UserDynamicModel(
    `user_${myUserId}/dashboard/latestFinishedTaskActivity`
  );

  unscribleLatestFinishedTaskActivity = fbUserDynamicModel.onChange(() => {
    if (!lastUpdateTime) return (lastUpdateTime = Date.now());

    if (Date.now() - lastUpdateTime < 500) return;

    lastUpdateTime = Date.now();

    getFinishedTasks();
  });
};

let lastOrgUpdateTime: number;
const onListenOrganizationDashboard = () => {
  if (unscribleOrgLatestFinishedTaskActivity)
    unscribleOrgLatestFinishedTaskActivity();
  fbOrganizationDynamicModel = new OrganizationDynamicModel(
    `dashboard/latestFinishedTaskActivity`
  );

  unscribleOrgLatestFinishedTaskActivity = fbOrganizationDynamicModel.onChange(
    () => {
      if (!lastOrgUpdateTime) return (lastOrgUpdateTime = Date.now());

      if (Date.now() - lastOrgUpdateTime < 500) return;

      lastOrgUpdateTime = Date.now();

      Promise.all([
        getFinishedTaskRankingByMember(),
        getFinishedTaskRankingByDomain(),
        getFinishedTaskOverview(),
      ]);
    }
  );
};

initComponent();

const getConfigName = (sitKey, typeKey) => {
  return _taskStore.getConfigName(sitKey, typeKey);
};

const openTaskDetail = (task) => {
  _taskDrawerStore.pushCurrentIds({
    id: task.taskId,
    name: task.name,
    code: task.code,
    tabType: 'DETAIL',
  });
};

// const assigneeId = ref(getCurrentUserId());
const currentTabId = computed(() => _taskDrawerStore.currentTabId);

const domainByIds = computed(() => domainStore().domainByIds);
const byDomainLabels = computed<string[]>(() => {
  const dataList = orderBy(
    finishedTaskRankingByDomain.value,
    ['numberOfTaskFinished'],
    ['desc']
  )?.slice(0, 3);
  return dataList?.map((o) => o?.name || domainByIds.value[o.domainId]?.name);
});
const byDomainDatasets = computed<any[]>(() => {
  const dataList = orderBy(
    finishedTaskRankingByDomain.value,
    ['numberOfTaskFinished'],
    ['desc']
  )?.slice(0, 5);
  return [
    {
      key: 'completedTasks',
      label: 'COMMON_LABEL_STATUS_FINISHED',
      data: dataList?.map((o) => o?.numberOfTaskFinished || 0),
      backgroundColor: '#4bb0b2',
      borderColor: '#4bb0b2',
      hoverBackgroundColor: '#008e8e',
      minBarLength: 2,
    },
  ];
});
</script>

<template>
  <SynLoading v-if="isLoading" />
  <div
    v-else
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.OVERVIEW.FINISHED_TASK,
    }"
    class="w-full h-full flex flex-col lg:flex-row relative overflow-hidden"
  >
    <div class="h-full w-full lg:w-1/2 p-2">
      <div
        class="w-full h-full flex flex-col gap-4 bg-white shadow rounded-lg p-4"
      >
        <div
          class="flex items-center justify-between space-x-2 font-semibold px-4"
        >
          <span class="text-gray-600 font-semibold text-sm">
            {{ $t('TASK_STATE_DESCRIPTION_FINISHED') || 'Finished Task Today' }}
          </span>
          <div
            v-if="finishedTasks?.length || todayInfo?.numberOfTaskFinished"
            class="flex-center"
          >
            <div
              class="
                rounded-full
                w-max
                px-2
                py-1
                text-xs text-green-700
                bg-green-100
              "
            >
              <div class="flex-center space-x-2">
                <span>
                  {{ $t('OVERVIEW_FINISHED_TASKS_YOU_SEE') || 'You see' }}
                  ({{ finishedTasks?.length || 0 }})
                </span>

                <span v-if="todayInfo?.numberOfTaskFinished">
                  / {{ $t('COMMON_LABEL_TOTAL') || 'Total' }} ({{
                    todayInfo.numberOfTaskFinished
                  }})
                </span>
              </div>
            </div>
            <FilterTasks
              v-if="finishedTasks?.length > 0"
              v-model:task-list="finishedTasks"
              is-finish-task
            />
          </div>
        </div>
        <div
          class="w-full h-full flex flex-col overflow-auto pt-2 small-scrollbar"
        >
          <SynTableCustom v-if="finishedTasks?.length > 0">
            <template #body>
              <SynTr
                v-for="(task, index) in finishedTasks"
                :key="task.id"
                :class="[
                  index % 2 == 0 ? 'bg-gray-50' : '',
                  currentTabId == task?.taskId && isOpenTaskDrawer
                    ? 'font-semibold'
                    : '',
                ]"
              >
                <SynTd class="relative text-xs py-2">
                  <span
                    v-if="currentTabId == task?.taskId && isOpenTaskDrawer"
                    class="absolute left-0 top-0 h-full border-2 border-current"
                  >
                  </span>
                  <UserByAction
                    v-if="task.assigneeId || task.finisherId"
                    :user-id="
                      task?.assigneeId ? task.assigneeId : task.finisherId
                    "
                    is-hidden-name
                    is-show-action-user
                    avatar-class="w-7 h-7"
                  />
                </SynTd>
                <SynTd
                  class="w-1/2 cursor-pointer hover:text-current"
                  @click.stop.prevent="openTaskDetail(task)"
                >
                  <a
                    :title="task.name"
                    :href="getDetailTaskUrl(task.code, false)"
                    class="w-full py-1 hover:text-current-500 cursor-pointer"
                  >
                    <div class="px-4 py-1 w-full h-full">
                      <!--                    <div-->
                      <!--                      class="-->
                      <!--                        flex flex-nowrap flex-col-->
                      <!--                        space-y-1-->
                      <!--                        break-words-->
                      <!--                        w-full-->
                      <!--                      "-->
                      <!--                    >-->
                      <!--                    <div class="flex space-x-1 w-full break-words">-->
                      <!--                      <div class="flex space-x-1 items-center">-->
                      <!--                        <span class="text-overflow-hidden-line">-->
                      <!--                          {{ formatTaskCode(task?.code) }}-->
                      <!--                        </span>-->
                      <!--                        <div v-if="task?.isPrivate">-->
                      <!--                          <SynIcon-->
                      <!--                            name="private"-->
                      <!--                            class="fill-current"-->
                      <!--                            custom-class="w-2.5 h-2.5"-->
                      <!--                          />-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                      <!--                      <span class="mx-1">-</span>-->
                      <!--                      <span class="">{{ task?.name }}</span>-->
                      <!--                    </div>-->

                      <span class="overflow-hidden">
                        {{ formatTaskCode(task?.code) }}
                        <span v-if="task?.isPrivate">
                          <SynIcon
                            name="private"
                            class="fill-current"
                            custom-class="w-2.5 h-2.5"
                          />
                        </span>
                        - <span v-encode-html="{ text: task?.name }"> </span>
                      </span>

                      <div v-if="task.groupId" class="flex w-full justify-end">
                        <div class="flex items-center text-gray-500">
                          <GroupById
                            :group-id="task.groupId"
                            class="px-2 text-xs"
                            avatar-class="w-3 h-3"
                          />
                        </div>
                      </div>
                      <!--                    </div>-->
                    </div>
                  </a>
                </SynTd>
                <SynTd class="text-xs">
                  <SynTag
                    class="rounded-full w-max px-2 text-xs"
                    size="small"
                    :class="getBgClass(3, 'taskLife')"
                  >
                    {{ getConfigName(3, 'taskLife') }}
                  </SynTag>
                </SynTd>
                <SynTd class="text-xs">
                  <PriorityTask
                    class="cursor-pointer"
                    :urgency="task?.urgency"
                    size="mini"
                    is-hidden-label
                  />
                </SynTd>

                <SynTd class="text-right text-gray-500">
                  <span v-if="task?.finishedTime" class="text-xs text-gray-500">
                    {{ $filters.fromNow(task?.finishedTime) }}
                    {{ $t('COMMON_LABEL_AGO') || 'ago' }}
                  </span>
                </SynTd>
              </SynTr>
            </template>
          </SynTableCustom>
          <div v-else class="flex-center flex-col h-1/3 space-y-6">
            <SynIcon
              name="finished-flag"
              custom-class="h-16 w-16 fill-current"
              color="current"
            />
            <div>
              <span class="text-gray-500 text-xl">
                {{ $t('OVERVIEW_FINISHED_TASKS_HAVE_NO_TASK') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        flex-1
        lg:min-h-max
        h-full
        w-full
        lg:w-1/2
        flex flex-col
        lg:flex-auto
      "
    >
      <div class="flex flex-wrap">
        <div class="w-full 2xl:w-1/2 h-[15rem] p-2">
          <div
            class="
              w-full
              h-full
              flex flex-col
              gap-2
              p-4
              bg-white
              rounded-lg
              shadow
              min-w-[15rem]
            "
          >
            <div class="text-gray-600 font-semibold text-sm">
              {{
                $t('OVERVIEW_FINISHED_TASKS_ACTIVE_MEMBER') || 'Active members'
              }}
            </div>
            <div
              v-if="
                finishedTaskRankingByMember &&
                Object.keys(finishedTaskRankingByMember).length > 0
              "
              class="flex-1 min-h-0 w-full flex-center"
            >
              <CustomLollipopChart :chart-data="finishedByUserChartData" />
            </div>

            <div v-else class="flex-center text-gray-500 h-1/2">
              {{ $t('OVERVIEW_FINISHED_TASKS_HAVE_NO_ACTIVE_MEMBER') }}
            </div>
          </div>
        </div>
        <div class="w-full 2xl:w-1/2 h-[15rem] p-2">
          <div
            class="
              w-full
              h-full
              flex flex-col
              gap-2
              p-4
              bg-white
              rounded-lg
              shadow
              min-w-[15rem]
            "
          >
            <div class="text-gray-600 font-semibold text-sm">
              {{ $t('COMMON_LABEL_DOMAIN_PROJECT') || 'Domain' }}
            </div>
            <div
              v-if="finishedTaskRankingByDomain?.length > 0"
              class="flex-1 min-h-0 w-full relative"
            >
              <VerticalBarChart
                :labels="byDomainLabels"
                :datasets="byDomainDatasets"
                is-hidden-note-y
              />
            </div>
            <div v-else class="flex-center text-gray-500 h-1/2">
              {{ $t('OVERVIEW_FINISHED_TASKS_HAVE_NO_INFORMATION') }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full flex-1 p-2 h-[15rem] max-h-[30rem] 2xl:h-auto 2xl:flex-1"
      >
        <div
          class="
            w-full
            h-full
            flex flex-col
            gap-2
            p-4
            bg-white
            rounded-lg
            shadow
            min-w-[18rem]
          "
        >
          <div class="text-gray-600 font-semibold text-sm">
            {{ $t('PERFORMANCE_LABEL_STATISTIC_IN_WEEK') || 'Domain' }}
          </div>
          <div class="flex-1 min-h-0 w-full relative">
            <VerticalBarChart
              :datasets="finishedTaskDatasets"
              :labels="finishedTaskLabels"
              is-hidden-note-y
            />
          </div>
          <!-- <div v-else class="flex-center text-gray-500 h-1/2">
            {{ $t('OVERVIEW_FINISHED_TASKS_HAVE_NO_INFORMATION') }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
