import BaseModel from './BaseModel';
import { ParentSource } from '../enums/FireaseDatabaseEnum';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export default class OrganizationDynamicModel extends BaseModel {
    constructor(source: string) {
        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        const sourcePath = `${ParentSource.organization}/organization_${organizationId}/${source}`;
        super(sourcePath);
    }
}
