<script setup lang="ts">
import { computed } from 'vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';

const props = defineProps<{
  task: any;
  users?: any[];
}>();

const taskCreator = computed(() =>
  props.users?.find((user) => user?.id === props.task?.creatorId)
);
</script>

<template>
  <UserByAction :user="taskCreator" is-hidden-name avatar-class="w-10 h-10" />
</template>
