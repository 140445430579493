<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import groupStore from '@/store/group';
import { ignoreUnicode } from '@/ui/plugins/utils';
import userStore from '@/store/user';
import myProfileStore from '@/store/auth/my-profile';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import {
  AudioVideoCallStatusEnum,
  ConferenceTypeEnum,
  DurationList,
  ConferenceHosterType,
} from '@/domain/enums/VideoCallEnum';
import { UserStatusEnum } from '@/domain/enums/UserStatusEnum';
import dashboardStore from '@/store/dashboard';

import { cloneDeep } from '@/ui/hooks/commonFunction';
import { ConferenceClass } from '@/application/entities/VideoCallClass';
import conferenceStore from '@/store/conference';
import favoriteStore from '@/store/favorite/favoriteStore';
import $constants from '@/ui/plugins/constants';
import UserById from '@/ui/components/user/UserById.vue';
import { AdminType } from '@/ui/common/constants/constant';
import { markTextSearch } from '@/ui/hooks/commonFunction';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import SingleCallButton from '@/ui/modules/video-call/components/SingleCallButton.vue';
import { arrToObj } from '@/ui/helpers/utils';
import chatStore from '@/store/chat';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const IconNameByTypeConstant = {
  video: {
    ringing: 'VideoIn',
    inprogess: 'VideoIn',
    missed: 'VideoMiss',
    hangup: 'VideoIn',
  },
  audio: {
    ringing: 'CallIn',
    inprogess: 'CallIn',
    missed: 'CallMiss',
    hangup: 'CallIn',
  },
};

const MessageByCallStatus = {
  ringing: {
    title: 'Calling...',
  },
  inprogress: {
    title: 'Inprogress...',
  },
  missed: {
    title: 'Missed Call',
  },
  failed_to_start: {
    title: 'Call Ended',
  },
  hangup: {
    title: 'Call Ended',
  },
};

defineProps({
  currentTab: {
    type: String,
    default: '',
  },
  isShowClose: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits([
  'onClose',
  'onConversationClick',
  'updateCurrentTab',
]);

const _dashboardStore = dashboardStore();
const _groupStore = groupStore();
const _userStore = userStore();
const _conferenceStore = conferenceStore();
const _favoriteStore = favoriteStore();
const _chatStore = chatStore();

const myCallStatus = computed(() =>
  _dashboardStore.getUserStatus(getCurrentUserId())
);

const isDisabledCall = computed(() =>
  myCallStatus.value?.includes(UserStatusEnum.OnCall)
);

const myProfile = computed(() => myProfileStore().myProfile);
const hostUserId = computed(() => myProfile.value?.id);
const hostUserName = computed(() => myProfile.value?.fullName);
const hostUserAvatar = computed(() => myProfile.value?.avatarUrl);
const allMyGroups = computed(() =>
  _groupStore.myGroups?.filter((o) => o.isMember)
);
const restrictedGroupChatById = computed(() =>
  arrToObj(
    _chatStore.usersChatInfo?.filter(
      (chat) => chat?.isGroup && !chat?.isGroupChatAllowed
    ),
    'id',
    () => true
  )
);

const totalContact = computed(
  () => allUsers.value?.length + allMyGroups.value?.length
);
const allUsers = ref([] as any[]);
const myFavorites = ref([] as any[]);

onMounted(async () => {
  if (
    !_userStore.listShortInfosUser ||
    _userStore.listShortInfosUser.length == 0
  ) {
    await _userStore.getShortInfosUser;
  }
  allUsers.value = cloneDeep(
    _userStore.listShortInfosUser
      .filter((o) => o.id !== getCurrentUserId())
      .map((user) => {
        return {
          ...user,
          callStatus: _dashboardStore.getUserStatus(user.id),
        };
      })
  );
  myFavorites.value = cloneDeep(_favoriteStore.getMyFavorites);
});

const searchText = ref('' as string);

const filteredGroups = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return allMyGroups.value?.filter((el) => el?.isMember);

  return (allMyGroups.value || []).filter(
    (group) => group?.name && ignoreUnicode(group?.name).includes(searchTxt)
  );
});

const filteredUsers = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return allUsers.value;

  return (allUsers.value || []).filter(
    (user) => user['name'] && ignoreUnicode(user['name']).includes(searchTxt)
  );
});
const selectedUsers = computed(() => {
  let result: any[] = (allUsers.value || []).filter(
    (user: any) => user?.isSelected
  );
  if (selectedGroup.value?.id) {
    result = [
      ...result,
      {
        ...selectedGroup.value,
        isGroup: true,
      },
    ];
  }
  return result;
});

const onChooseMember = (contact) => {
  if (!contact || !hostUserId.value) return;
  clearSelectedGroup();
  clearSelectedGroupFavorite();
  const index = allUsers.value.findIndex((o: any) => o.id == contact.id);
  if (index > -1) {
    allUsers.value[index].isSelected = !allUsers.value[index].isSelected;
  }
  // my favorites
  const indexF = myFavorites.value.findIndex((o) => o.id == contact.id);
  if (indexF > -1) {
    myFavorites.value[indexF].isSelected =
      !myFavorites.value[indexF].isSelected;
  }
};

const selectedGroup = ref({} as any);

const onChooseGroup = (contact) => {
  if (!contact || !hostUserId.value) return;
  clearSelectedMember();
  clearSelectedFavorite();

  const newValue = contact.isSelected ? false : true;

  if (newValue) {
    allMyGroups.value
      ?.filter((group) => group.isSelected)
      .forEach((o) => {
        o.isSelected = false;
      });
  }
  // my favorites
  const indexF = myFavorites.value.findIndex((o) => o.id == contact.id);

  if (indexF > -1) {
    myFavorites.value[indexF].isSelected = newValue;
  }

  const indexG = allMyGroups.value.findIndex((o) => o.id == contact.id);
  if (indexG > -1) {
    allMyGroups.value[indexG].isSelected = newValue;
  }

  selectedGroup.value = newValue ? { ...contact } : null;
};

const onRemoveSelectedUser = (contact) => {
  if (!contact) return;
  if (contact?.isGroup) {
    selectedGroup.value = null;
  } else {
    const index = allUsers.value.indexOf(contact);
    if (index > -1) {
      allUsers.value[index].isSelected = false;
    }
  }
};

const durationTime = ref(15);

const isLoading = ref(false);

const clearSelectedMember = () => {
  allUsers.value = allUsers.value.map((o) => {
    return {
      ...o,
      isSelected: false,
    };
  });
};

const clearSelectedGroupFavorite = () => {
  myFavorites.value = myFavorites.value.map((o) => {
    return {
      ...o,
      isSelected: o.favoriteType == 'group' ? false : o.isSelected,
    };
  });
};

const clearSelectedFavorite = () => {
  myFavorites.value = myFavorites.value.map((o) => {
    return {
      ...o,
      isSelected: false,
    };
  });
};
const clearSelectedGroup = () => {
  selectedGroup.value = null;
};
const callType = ref(ConferenceTypeEnum.Audio);

const onCallByTypeAndReceivers = async (receivers) => {
  if (!receivers || receivers.length <= 0) return;
  isLoading.value = true;

  try {
    const freeReceivers = receivers.filter((user) => {
      const userCallStatus = _dashboardStore.getUserStatus(user.userId);
      return (
        !userCallStatus || !userCallStatus?.includes(UserStatusEnum.OnCall)
      );
    });

    const newConference = new ConferenceClass(
      callType.value,
      {
        id: hostUserId.value,
        name: hostUserName.value,
        avatar: hostUserAvatar.value,
      } as ConferenceHosterType,
      AudioVideoCallStatusEnum.Started,
      durationTime.value,
      freeReceivers
    );

    await newConference.start();
  } catch (error) {
    console.log(error);
  }

  isLoading.value = false;
  emit('onClose');
};

const conferenceMenuList = {
  HISTORY: {
    key: 'histories',
    label: 'History',
    labelKey: 'COMMON_LABEL_RECENTLY',
  },
  FAVORITES: {
    key: 'favorites',
    label: 'Favorites',
    labelKey: 'SIDEBAR_LABEL_MY_FAVORITES',
  },
  MEMBERS: {
    key: 'members',
    label: 'Members',
    labelKey: 'TASK_TABLE_LABEL_MEMBERS',
  },
  GROUPS: {
    key: 'groups',
    label: 'Groups',
    labelKey: 'COMMON_LABEL_GROUPS',
  },
};
const isSearching = ref(false);

const currentIndex = ref(0);
const resultSearch = computed(() => {
  return [
    ...filteredUsers.value,
    ...filteredGroups.value.map((o) => {
      return {
        ...o,
        isGroup: true,
      };
    }),
  ];
}) as any;

const vigSearchBoxRef = ref(null as any);
const onChooseResult = () => {
  onContactClick(resultSearch.value[currentIndex.value]);

  vigSearchBoxRef.value?.focus();
};
const onContactClick = (contact) => {
  if (
    !contact ||
    !contact?.id ||
    isDisabledCall.value ||
    contact?.callStatus?.includes(UserStatusEnum.OnCall)
  ) {
    return;
  }

  if (contact?.isGroup) {
    onChooseGroup(contact);
  } else {
    onChooseMember(contact);
  }
};

const onBlurSearchBox = () => {
  if (!searchText.value) isSearching.value = false;
};

// HISTORY
const myCallHistories = computed(() => _conferenceStore.histories);
const historiesObjects = computed(() => _conferenceStore.historiesObjects);

// Favorites

const myFilteredFavorites = computed(() => {
  let data = myFavorites.value;
  data = data?.filter(
    (o) => o?.type == 'member' || (o?.type !== 'member' && o?.isMember)
  );
  return data;
});

const onFavoriteClick = (favorite) => {
  if (favorite?.favoriteType == 'member') {
    onChooseMember(favorite);
  } else {
    onChooseGroup(favorite);
  }
};

const onCallFromFooter = (call_type) => {
  callType.value = call_type;
  let receivers;
  if (selectedGroup.value?.id) {
    receivers = selectedGroup.value?.groupUsers
      .filter((u) => u.userId !== hostUserId.value)
      .map((user) => {
        return {
          ...user,
          id: user.userId,
        };
      });
  } else {
    receivers = selectedUsers.value;
  }
  onCallByTypeAndReceivers(receivers);
};

const iconNameByType = (callType, messageCallType) => {
  return IconNameByTypeConstant[callType]
    ? IconNameByTypeConstant[callType][messageCallType]
    : 'VideoIn';
};

const messageTitleByStatus = (messageCallType) => {
  return MessageByCallStatus[messageCallType]
    ? MessageByCallStatus[messageCallType]['title']
    : 'Call';
};

const menuList = computed(() => {
  let data: any[] = [];
  if (myCallHistories.value?.length) {
    data = [...data, conferenceMenuList?.HISTORY];
  }
  if (myFilteredFavorites.value?.length) {
    data = [...data, conferenceMenuList?.FAVORITES];
  }
  if (filteredUsers.value?.length) {
    data = [...data, conferenceMenuList?.MEMBERS];
  }
  if (filteredGroups.value?.length) {
    data = [...data, conferenceMenuList?.GROUPS];
  }

  return data;
});
const isShowDetail = ref('');
const onOpenHistoryInfo = (callInfo) => {
  const historyId = callInfo?.id;
  if (!callInfo || !historyId) return;
  // _conferenceStore.histories[index].isShowDetail = !callInfo?.isShowDetail;
  isShowDetail.value = isShowDetail.value == historyId ? '' : historyId;
};
</script>

<template>
  <div
    class="flex-1 min-h-0 h-full flex flex-col rounded-md"
    style="width: 29rem"
  >
    <SynTabScroll
      :model-value="currentTab"
      :menu-list="menuList"
      @update:model-value="(value) => $emit('updateCurrentTab', value)"
    >
      <template v-if="totalContact > 12" #prefix-header>
        <div
          class="py-3 px-4 flex-center space-x-4"
          :class="
            searchText ? 'bg-gradient-to-r from-current-300 to-teal-400' : ''
          "
        >
          <VigSearchBox
            ref="vigSearchBoxRef"
            v-model="searchText"
            v-model:current-index="currentIndex"
            input-class="text-sm"
            :total-result="resultSearch?.length"
            class="w-full"
            autofocus
            @blur="onBlurSearchBox"
            @enter="onChooseResult"
          />
          <div v-if="isShowClose" class="dropdown-item">
            <SynIcon
              name="close"
              class="fill-gray-500 text-gray-500"
              custom-class="w-4 h-4"
              color="gray"
              has-action
              @click="$emit('onClose')"
            />
          </div>
        </div>
      </template>
      <template v-else #prefix>
        <div
          class="flex-center space-x-1 justify-end absolute right-0 py-3 mt-2"
          :class="isSearching ? 'w-full' : 'px-4'"
        >
          <div
            v-if="isSearching"
            class="bg-white px-3 py-3 flex-1 w-full"
            :class="
              searchText ? 'bg-gradient-to-r from-current-300 to-teal-400' : ''
            "
          >
            <VigSearchBox
              ref="vigSearchBoxRef"
              v-model="searchText"
              v-model:current-index="currentIndex"
              :total-result="resultSearch?.length"
              autofocus
              input-class="text-sm"
              class="w-full"
              @blur="onBlurSearchBox"
              @enter="onChooseResult"
            />
          </div>
          <SynIcon
            v-else
            name="search"
            has-action
            @click="isSearching = true"
          />
        </div>
      </template>
      <template #body>
        <div class="flex-1 pb-3">
          <template
            v-if="
              (myFilteredFavorites?.length <= 0 &&
                filteredUsers?.length <= 0 &&
                filteredGroups?.length <= 0) ||
              (searchText && !resultSearch?.length)
            "
          >
            <div class="py-16">
              <syn-animation
                name="searchIcon"
                :loop="false"
                stype="width: 100px; height: 100px"
                :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
              />
            </div>
          </template>
          <div v-else-if="searchText && resultSearch?.length" class="pt-2">
            <div
              v-for="(result, index) in resultSearch"
              :id="`vig-search-box-item-id_${index.toString()}`"
              :key="result"
              class="
                dropdown-item
                py-2
                px-6
                flex
                items-center
                justify-between
                space-x-2
                cursor-pointer
                hover:bg-current-50
                text-sm
              "
              :class="[
                result?.callStatus?.includes(UserStatusEnum.OnCall) ||
                isDisabledCall
                  ? 'cursor-not-allowed'
                  : '',
                index == currentIndex ? 'bg-gray-100' : '',
              ]"
              @click="onContactClick(result)"
            >
              <div class="flex-center space-x-2">
                <div class="pr-3">
                  <SynIcon
                    name="search"
                    class="fill-gray-500 text-gray-500"
                    custom-class="w-3 h-3"
                  />
                </div>

                <SynAvatar
                  :src="result?.avatar"
                  :type="result?.isGroup ? 'group' : 'user'"
                  :name="result.name"
                  custom-class="w-8 h-8"
                />
                <!-- <span>{{ result?.name }}</span> -->
                <span
                  class="text-overflow-hidden-line"
                  v-html="markTextSearch(result?.name, searchText)"
                ></span>
                <span v-if="result?.id === myProfile?.id">
                  ({{ $t('COMMON_LABEL_ME') || 'Me' }})
                </span>
              </div>
              <SynIcon
                v-if="result?.isGroup"
                name="group"
                is-active
                class="fill-gray-500"
                custom-class="w-4 h-4"
              />
            </div>
          </div>

          <template v-else>
            <!-- Histories -->
            <div
              v-if="myCallHistories?.length > 0 && !searchText"
              :id="`syn-tab-scroll-item-${conferenceMenuList?.HISTORY?.key}`"
              class="flex-1"
            >
              <div
                class="
                  px-4
                  pt-4
                  pb-1
                  uppercase
                  text-xs
                  font-semibold
                  tracking-widest
                  text-gray-500
                "
              >
                {{ $t(conferenceMenuList?.HISTORY?.labelKey) }}
              </div>
              <div
                v-for="(call, index) in myCallHistories"
                :key="call?.joinToken"
                class="
                  hover-to-show__parent
                  relative
                  flex flex-col
                  cursor-pointer
                  hover:bg-gray-50
                "
                @click="onOpenHistoryInfo(call, index)"
              >
                <div
                  :class="
                    isShowDetail === call.id
                      ? 'border-l-4 border-current shadow-md'
                      : ''
                  "
                >
                  <div class="p-2 flex">
                    <div class="h-10 w-10 bg-gray-100 flex-center rounded-full">
                      <SynIcon
                        v-if="call?.isHost"
                        :name="
                          call?.callType === $constants.CALL.TYPE.VIDEO
                            ? 'VideoOut'
                            : 'CallOut'
                        "
                        class="fill-gray-500"
                        custom-class="w-4 h-4"
                      />
                      <SynIcon
                        v-else
                        :name="iconNameByType(call?.callType, call?.status)"
                        class="fill-gray-500"
                        custom-class="w-4 h-4"
                      />
                    </div>
                    <div
                      class="
                        ml-2
                        flex-1
                        overflow-hidden
                        flex flex-col
                        justify-center
                      "
                    >
                      <div class="w-full flex items-center justify-between">
                        <div
                          class="
                            truncate
                            flex
                            items-center
                            space-x-4
                            font-medium
                            text-sm
                          "
                        >
                          <span> {{ messageTitleByStatus(call?.status) }}</span>
                        </div>
                        <div class="flex-center gap-2">
                          <div class="flex-center space-x-2">
                            <span class="ml-1 text-gray-500 text-xs">
                              {{
                                $filters.fromNow(
                                  call?.endDate || call?.createdDate
                                )
                              }}
                              {{ $t('COMMON_LABEL_AGO') || 'ago' }}
                            </span>
                            <!-- <span>
                        {{ $filters.dayjs(call?.createdDate, 'hh:mm A') }}
                      </span> -->
                          </div>
                          <div
                            class="
                              ml-2
                              h-5
                              w-5
                              flex-center
                              cursor-pointer
                              rounded
                              hover:bg-current-50 hover:fill-current
                            "
                            :class="
                              isShowDetail === call.id
                                ? 'bg-current-50 fill-current'
                                : 'fill-gray-500'
                            "
                          >
                            <SynIcon
                              custom-class="w-2.5 h-2.5"
                              :name="
                                isShowDetail === call.id
                                  ? 'arrow-up'
                                  : 'arrow-down'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-sm flex items-center flex-nowrap">
                        <div class="flex-1 flex flex-wrap">
                          <UserById
                            v-for="userId in call?.participantIds"
                            :key="userId"
                            :user-id="userId"
                            class="text-xs w-5 h-5 m-0.5"
                            avatar-class="w-5 h-5"
                            is-hidden-name
                          />
                        </div>
                        <!-- CALLBACK BUTTON -->

                        <div @click.stop="() => {}">
                          <SingleCallButton
                            :receiver-ids="call?.participantIds"
                            custom-class="hover:bg-current-50 fill-current"
                          />
                        </div>
                        <!-- <span
                          class="
                            flex-center
                            space-x-1
                            absolute
                            right-2
                            top-1
                            hover-to-show__children
                          "
                        >
                          <SynIcon
                            name="phone"
                            has-action
                            class="fill-green-500"
                            :class="{
                              'cursor-not-allowed': isLoading || isDisabledCall,
                            }"
                            @click.stop="
                              !isLoading &&
                                onCallBack(
                                  call?.participantIds,
                                  ConferenceTypeEnum.Audio
                                )
                            "
                          />
                          <SynIcon
                            name="video"
                            has-action
                            class="fill-green-500"
                            :class="{
                              'cursor-not-allowed': isLoading || isDisabledCall,
                            }"
                            @click.stop="
                              !isLoading &&
                                onCallBack(
                                  call?.participantIds,
                                  ConferenceTypeEnum.Video
                                )
                            "
                          />
                        </span> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="isShowDetail === call.id"
                  class="bg-gray-50 bg-opacity-60"
                >
                  <div
                    v-for="(callDetail, indexD) in historiesObjects[call.id]"
                    :key="indexD"
                    class="py-2 pr-2 pl-4 flex border-b border-gray-100"
                  >
                    <div class="h-8 w-8 bg-current-50 flex-center rounded-full">
                      <SynIcon
                        v-if="callDetail?.isHost"
                        :name="
                          callDetail?.callType === $constants.CALL.TYPE.VIDEO
                            ? 'VideoOut'
                            : 'CallOut'
                        "
                        class="fill-current-500"
                        custom-class="w-4 h-4"
                      />
                      <SynIcon
                        v-else
                        :name="
                          iconNameByType(
                            callDetail?.callType,
                            callDetail?.status
                          )
                        "
                        class="fill-current-500"
                        custom-class="w-4 h-4"
                      />
                    </div>
                    <div
                      class="
                        ml-2
                        flex-1
                        overflow-hidden
                        flex
                        items-center
                        justify-between
                        text-sm
                      "
                    >
                      <div class="truncate">
                        {{ messageTitleByStatus(callDetail?.status) }}
                      </div>
                      <div class="text-sm flex flex-nowrap">
                        <div class="flex-center space-x-2 text-xs">
                          <span class="ml-1 text-gray-500">
                            {{
                              $filters.fromNow(
                                callDetail?.endDate || callDetail?.createdDate
                              )
                            }}
                            {{ $t('COMMON_LABEL_AGO') || 'ago' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
              v-if="!myCallHistories?.length"
              class="h-56 flex-center flex-col text-center"
            >
              <SynAnimation name="noMessage" stype="width: 200px" />
              <div class="">{{ $t('CHAT_NO_CONVERSATION_MSG') }}</div>
            </div> -->
            </div>

            <!-- FAVORITE -->
            <div
              v-if="myFilteredFavorites?.length > 0"
              :id="`syn-tab-scroll-item-${conferenceMenuList?.FAVORITES?.key}`"
            >
              <div
                class="
                  px-4
                  pt-6
                  pb-1
                  uppercase
                  text-xs
                  font-semibold
                  tracking-widest
                  text-gray-500
                "
              >
                {{ $t('SIDEBAR_LABEL_MY_FAVORITES') || 'Favorites' }}
              </div>

              <div
                v-for="(contact, index) in myFilteredFavorites"
                :key="index"
                class="
                  px-4
                  py-2
                  flex
                  items-center
                  justify-start
                  space-x-4
                  cursor-pointer
                  hover:bg-gray-100
                  hover-to-show__parent
                  relative
                "
                :class="
                  contact?.callStatus?.includes(UserStatusEnum.OnCall) ||
                  isDisabledCall
                    ? 'cursor-not-allowed'
                    : ''
                "
                @click="!isDisabledCall && onFavoriteClick(contact)"
              >
                <SynIcon
                  v-if="contact?.callStatus?.includes(UserStatusEnum.OnCall)"
                  name="phone"
                  class="fill-current"
                />
                <SynIcon
                  v-else-if="!isDisabledCall"
                  name="checkbox"
                  :is-active="contact.isSelected"
                  class="fill-current"
                />

                <div class="flex-center space-x-2">
                  <SynAvatarStatusActive
                    :id="contact?.id"
                    :avatar="contact?.avatar"
                    custom-class="h-11 w-11"
                    :name="contact?.name"
                    :is-user="contact?.favoriteType === 'member'"
                    :is-visitor="contact?.adminType == AdminType.Visitor"
                  />
                  <!-- <syn-avatar :src="contact?.avatar" :name="contact?.name" />
                <span>{{ contact?.name }}</span>
                <span v-if="contact?.userId === hostUserId">
                  ({{ $t('COMMON_LABEL_ME') || 'Me' }})
                </span> -->
                </div>

                <span
                  v-if="!isDisabledCall"
                  class="
                    flex-center
                    space-x-1
                    absolute
                    right-2
                    hover-to-show__children
                  "
                  @click.stop="() => {}"
                >
                  <SingleCallButton
                    :receiver-ids="
                      contact?.type == 'member'
                        ? [contact.id]
                        : contact?.groupUsers?.map((o) => o?.userId)
                    "
                    custom-class="hover:bg-current-50 fill-current"
                  />
                </span>
              </div>
            </div>

            <!-- MEMBER -->
            <div
              v-if="allUsers?.length > 0"
              :id="`syn-tab-scroll-item-${conferenceMenuList?.MEMBERS?.key}`"
            >
              <div
                class="
                  px-4
                  pt-6
                  pb-1
                  uppercase
                  text-xs
                  font-semibold
                  tracking-widest
                  text-gray-500
                "
              >
                {{ $t(conferenceMenuList?.MEMBERS?.key) || 'Members' }}
              </div>
              <div
                v-for="(user, index) in allUsers"
                :key="index"
                class="
                  px-4
                  py-2
                  flex
                  items-center
                  justify-start
                  space-x-4
                  cursor-pointer
                  hover:bg-gray-100
                  hover-to-show__parent
                  relative
                "
                :class="
                  user?.callStatus?.includes(UserStatusEnum.OnCall) ||
                  isDisabledCall
                    ? 'cursor-not-allowed'
                    : ''
                "
                @click="
                  !user?.callStatus?.includes(UserStatusEnum.OnCall) &&
                    !isDisabledCall &&
                    onChooseMember(user)
                "
              >
                <template v-if="!isDisabledCall">
                  <SynIcon
                    v-if="user?.callStatus?.includes(UserStatusEnum.OnCall)"
                    name="phone"
                    class="fill-current"
                  />
                  <SynIcon
                    v-else
                    name="checkbox"
                    :is-active="user.isSelected"
                    class="fill-current"
                  />
                </template>
                <div class="flex-center space-x-2">
                  <SynAvatarStatusActive
                    :id="user?.id"
                    :avatar="user?.avatar"
                    :name="user?.name"
                    custom-class="h-11 w-11"
                    is-user
                    :is-visitor="user?.adminType == AdminType.Visitor"
                  />
                  <!-- <syn-avatar :src="user?.avatar" :name="user?.name"></syn-avatar>
                <span>{{ user?.name }}</span>
                <span v-if="user?.userId === hostUserId">
                  ({{ $t('COMMON_LABEL_ME') || 'Me' }})
                </span> -->
                </div>
                <span
                  v-if="!isDisabledCall"
                  class="
                    flex-center
                    space-x-1
                    absolute
                    right-2
                    hover-to-show__children
                  "
                  @click.stop="() => {}"
                >
                  <SingleCallButton
                    :receiver-ids="[user.id]"
                    custom-class="hover:bg-current-50 fill-current"
                  />
                </span>
              </div>
            </div>

            <!-- GROUP -->
            <div
              v-if="allMyGroups?.length"
              :id="`syn-tab-scroll-item-${conferenceMenuList?.GROUPS?.key}`"
            >
              <div
                class="
                  px-4
                  pt-6
                  pb-1
                  uppercase
                  text-xs
                  font-semibold
                  tracking-widest
                  text-gray-500
                "
              >
                {{ $t(conferenceMenuList?.GROUPS?.key) || 'Groups' }}
              </div>
              <div
                v-for="group in allMyGroups"
                :key="group"
                class="
                  px-4
                  py-2
                  flex
                  items-center
                  space-x-4
                  cursor-pointer
                  hover:bg-gray-100
                  hover-to-show__parent
                  relative
                "
                :class="
                  isDisabledCall || restrictedGroupChatById[group?.id]
                    ? 'cursor-not-allowed'
                    : ''
                "
                @click="
                  !isDisabledCall &&
                    !restrictedGroupChatById[group?.id] &&
                    onChooseGroup(group)
                "
              >
                <template v-if="!isDisabledCall">
                  <SynIcon
                    name="checkbox"
                    :is-active="group?.id == selectedGroup?.id"
                    class="fill-current"
                    :class="{
                      'bg-gray-100 fill-gray-300':
                        restrictedGroupChatById[group?.id],
                    }"
                  />
                </template>
                <div class="flex-1 flex items-center space-x-2">
                  <syn-avatar
                    :src="group?.avatar?.replace('original', 'small')"
                    :name="group?.name"
                    custom-class="h-11 w-11"
                  />
                  <div>
                    <div>
                      <span class="mr-2">{{ group?.name }} </span>
                      <SynIcon
                        name="group"
                        custom-class="w-3 h-3 fill-current"
                        is-active
                      />
                    </div>
                    <div
                      v-if="restrictedGroupChatById[group?.id]"
                      class="text-xs"
                    >
                      <SynIcon name="NotAllow" custom-class="w-3 h-3" />
                      {{ $t('CHAT_GROUP_NOT_ALLOWED_MSG') }}
                    </div>
                  </div>
                </div>
                <span
                  v-if="!isDisabledCall && !restrictedGroupChatById[group?.id]"
                  class="
                    flex-center
                    space-x-1
                    absolute
                    right-2
                    hover-to-show__children
                  "
                  @click.stop="() => {}"
                >
                  <SingleCallButton
                    :receiver-ids="group?.groupUsers?.map((o) => o?.userId)"
                    custom-class="hover:bg-current-50 fill-current"
                  />
                </span>
              </div>
            </div>
          </template>
        </div>
      </template>
    </SynTabScroll>
    <div
      v-if="selectedUsers?.length > 0 || selectedGroup?.id"
      class="p-4 relative hover-to-show__parent flex items-center flex-wrap"
      style="max-height: 50%"
    >
      <span class="float-left pr-1">To: </span>
      <div
        v-for="contact of selectedUsers"
        :key="contact"
        class="
          rounded-full
          shadow
          bg-current-100
          text-gray-800 text-sm
          p-1
          m-0.5
          flex-center
          space-x-2
          w-max
          float-left
        "
      >
        <syn-avatar
          custom-class="w-5 h-5"
          :src="contact?.avatar?.replace('original', 'small')"
          :name="contact?.name"
        />
        <span>{{ contact?.name }}</span>
        <button
          class="p-0.5 rounded-full hover:bg-current-200 flex-center"
          @click.stop="onRemoveSelectedUser(contact)"
        >
          <SynIcon name="Close" custom-class="w-4 h-4" />
        </button>
      </div>
      <span
        v-if="!isLoading"
        class="
          px-2
          rounded
          absolute
          bg-red-500
          text-white
          font-semibold
          bottom-2
          right-2
          cursor-pointer
          hover-to-show__children
        "
        @click="
          clearSelectedMember();
          clearSelectedFavorite();
          clearSelectedGroup();
        "
      >
        Clear
      </span>
    </div>
    <div class="flex items-center justify-end space-x-2 p-2 border-t border-b">
      <div class="flex-center space-x-1">
        <SynInputNumber v-model="durationTime" :min="1" :max="60">
          <vig-dropdown
            :append-to-body="false"
            z-index="50"
            placement="top"
            @click.stop="() => {}"
          >
            <template #dropdown-toggle>
              <div class="flex-center space-x-1 cursor-pointer px-2">
                <span class="h-8 flex-center">
                  {{ durationTime }}
                </span>
                <span>min</span>
                <SynIcon name="chevron-double-down" />
              </div>
            </template>
            <template #dropdown-menu>
              <div
                class="
                  list-ul
                  text-xs
                  w-full
                  overflow-x-hidden overflow-auto
                  small-scrollbar
                "
              >
                <div
                  v-for="(item, index) in DurationList"
                  :key="index"
                  class="
                    list-li
                    dropdown-item
                    px-4
                    py-2
                    text-xs text-black
                    hover:bg-gray-100 hover:text-current-700
                    cursor-pointer
                    break-words
                  "
                  @click.stop="durationTime = item"
                >
                  <span>
                    {{ item }}
                  </span>
                  <span>min</span>
                </div>
              </div>
            </template>
          </vig-dropdown>
        </SynInputNumber>
        <syn-button
          v-permission-function="{
            functionCode: ALL_FUNCTIONS.CHAT.AUDIO_CALL,
            onContinue: () => {
              onCallFromFooter(ConferenceTypeEnum.Audio);
            },
          }"
          :disabled="!selectedUsers || selectedUsers.length < 1 || isLoading"
          :is-loading="isLoading && callType == ConferenceTypeEnum.Audio"
          @click="onCallFromFooter(ConferenceTypeEnum.Audio)"
        >
          <SynIcon name="phone" />
        </syn-button>
        <syn-button
          v-permission-function="{
            functionCode: ALL_FUNCTIONS.CHAT.VIDEO_CALL,
            onContinue: () => {
              onCallFromFooter(ConferenceTypeEnum.Video);
            },
          }"
          :is-loading="isLoading && callType == ConferenceTypeEnum.Video"
          :disabled="!selectedUsers || selectedUsers.length < 1 || isLoading"
          @click="onCallFromFooter(ConferenceTypeEnum.Video)"
        >
          <SynIcon name="video" />
        </syn-button>
      </div>
    </div>
  </div>
</template>
