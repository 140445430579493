<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { sortBy } from 'lodash';
import MessageFile from '@/ui/modules/messaging/chat-panel/chat-message/message-file/MessageFile.vue';
import calculateImageLayoutComposables from '@/ui/composables/chat/calculate-image-layout-composables';
import { styleCustomizeMessage } from '@/ui/modules/messaging/chat-panel/chat-message/button-customize/calculate-color-customizes';
import GroupReaction from '@/ui/modules/messaging/chat-panel/chat-message/group-message/GroupReaction.vue';
import ButtonReactions from '@/ui/modules/messaging/chat-panel/chat-message/button-reactions/ButtonReactions.vue';
import { ChatMessageAction } from '@/domain/enums/chat-enums';

const props = withDefaults(
  defineProps<{
    isExpandViewFile: boolean;
    message: any;
    size: 'sm' | 'lg';
    isMyMessage: boolean;
    isFirstInGroup: boolean;
    isLastInGroup: boolean;
    members: any;
    allowedActions?: ChatMessageAction[];
    isShowName?: boolean;
  }>(),
  {
    size: 'sm',
  }
);

defineEmits<{
  (e: 'onReactionsClick', value: any): void;
  (e: 'onReactionAdd', value: any): void;
  (e: 'onFileClick', value: any): void;
}>();

const hasReactions = computed(
  () => !!Object.keys(props.message?.reactions || {})?.length
);

const createdDate = computed(() => {
  if (!props.message?.createdDate) return new Date();

  return new Date(props.message.createdDate).getTime();
});

const styleMsg = computed(() =>
  styleCustomizeMessage(
    props.message,
    props.isMyMessage,
    props.allowedActions?.includes(ChatMessageAction.SET_BACKGROUND)
  )
);

const fileList = computed(() => {
  const msgFiles = Object.entries(props.message?.files || {}).map(
    ([id, file]: [string, any]) => ({ ...file, id })
  );
  return sortBy(msgFiles, ['index']);
});

const fileByIds = computed(() => {
  return fileList.value?.reduce((oldValue, currentValue) => {
    return {
      ...oldValue,
      [currentValue.id]: currentValue,
    };
  }, {});
});

const { containerDefaultBySize, calculateImageLayout } =
  calculateImageLayoutComposables({ size: props.size });
const groupMessageFilesContainerRef = ref();
const fileByRows = ref<any>([]);
const isCalculatedLayout = ref(false);
const idPrefix = 'group-message-file';
onMounted(() => {
  setTimeout(() => {
    const totalFiles = Object.keys(fileByIds.value)?.length;
    if (totalFiles <= 1) return;
    fileByRows.value = calculateImageLayout(fileByIds.value, idPrefix);
    isCalculatedLayout.value = true;
  }, 100);
});
</script>

<template>
  <template v-if="fileList?.length && isExpandViewFile">
    <div v-if="isShowName">
      <span class="text-gray-500 text-xs">
        {{ members[message?.createdBy]?.name }}
      </span>
    </div>
    <div class="relativeov">
      <div
        v-if="fileList?.length > 0"
        ref="groupMessageFilesContainerRef"
        class="flex flex-col rounded"
        :style="`border: 2px solid ${styleMsg?.hexColor}`"
        :class="isMyMessage ? 'justify-end ' : 'justify-start '"
      >
        <div v-if="!isCalculatedLayout">
          <div
            v-for="fileId in Object.keys(fileByIds)"
            :id="`${idPrefix}-${fileId}`"
            :key="fileId"
            class="w-max cursor-pointer"
            :style="`max-width: ${containerDefaultBySize.containerMaxWidth}px;`"
          >
            <MessageFile
              :file="fileByIds[fileId]"
              :size="size"
              :length-files="fileList?.length"
              @on-file-click="$emit('onFileClick', fileByIds[fileId])"
            />
          </div>
        </div>
        <template v-else>
          <div
            v-for="(row, index) in fileByRows"
            :key="index"
            class="flex msg-content w-full"
            :class="isMyMessage ? 'justify-end' : 'justify-start'"
          >
            <div
              v-for="itemInRow in row"
              :key="itemInRow.fileId"
              class="cursor-pointer"
              :style="`width: ${itemInRow.width}px; max-width: ${containerDefaultBySize.containerMaxWidth}px; margin: 0.1rem;`"
            >
              <MessageFile
                :file="fileByIds[itemInRow.fileId]"
                :size="size"
                :length-files="fileList?.length"
                @on-file-click="
                  $emit('onFileClick', fileByIds[itemInRow.fileId])
                "
              />
            </div>
          </div>
        </template>
      </div>

      <!-- BUTTON REACTION -->
      <template v-if="!message?.text || message?.task || message?.dayoff">
        <div
          class="text-2xs text-gray-500"
          :class="isMyMessage ? 'text-end' : 'text-start'"
        >
          <SynLabelDateTime format="time" :datetime="createdDate" />
        </div>

        <div
          v-if="allowedActions?.includes(ChatMessageAction.REACTION)"
          class="absolute bottom-1 z-10"
          :class="isMyMessage ? 'left-1' : 'right-1'"
        >
          <GroupReaction
            v-if="hasReactions"
            :reactions="message?.reactions"
            :is-my-message="isMyMessage"
            :is-in-group="false"
            :members="members"
            @on-reaction-add="$emit('onReactionAdd', $event)"
            @on-reactions-click="$emit('onReactionsClick', $event)"
          />

          <ButtonReactions
            v-else
            custom-class="bg-white border w-6 h-6 hover-to-show__children"
            @on-emoji-click="$emit('onReactionAdd', $event)"
          />
        </div>
      </template>
    </div>
  </template>
</template>
