<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import groupStore from '@/store/group';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import EditButton from '@/ui/components/general/EditButton.vue';
import domainStore from '@/store/scope';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import { AdminType } from '@/ui/common/constants/constant';
import GroupById from '@/ui/components/group/GroupById.vue';

const props = defineProps<{
  groupId: number | string;
}>();

const _domainStore = domainStore();
const _groupStore = groupStore();

const allDomains = computed(() => _domainStore.domains);
const groupDetail = computed(() => _groupStore.allGroupByIds[props.groupId]);
const groupDomains = computed(() => {
  return groupDetail.value?.groupDomains;
});

const isPermissionForEditGroup = computed(() => {
  const currentUserRole = MyOrganizationSingleton.getInstance().getUserRole();
  return (
    (!groupDetail.value?.isPrivate && currentUserRole == AdminType.Admin) ||
    groupDetail.value?.isAdminGroup
  );
});

const isEditting = ref(false);
const isSaving = ref(false);
const groupDomainsPayload = ref<any>([]);

const selectedDomainIds = computed(() => {
  return groupDomainsPayload.value
    ?.filter((data) => data?.checked)
    ?.map((data) => data?.id);
});
const initData = () => {
  groupDomainsPayload.value = cloneDeep(
    allDomains.value?.map((data) => {
      return {
        ...data,
        checked:
          groupDomains.value?.some((gd) => gd?.domainId == data?.id) || false,
      };
    })
  );
};

watch(
  () => props.groupId,
  () => {
    initData();
  }
);
const onCancel = () => {
  isEditting.value = false;
  initData();
};
initData();

const onSave = async () => {
  try {
    isSaving.value = true;

    await _groupStore.updateDomains(props.groupId, selectedDomainIds.value);

    isEditting.value = false;
  } catch (error) {
    console.log(
      '🚀 ~ file: GroupDomainTree.vue ~ line 50 ~ onSave ~ error',
      error
    );
  } finally {
    isSaving.value = false;
  }
};
</script>
<template>
  <div class="w-full h-full flex-1 min-h-0 flex flex-col overflow-hidden">
    <div class="flex py-3 px-3 border-b border-current justify-between">
      <div class="flex-center space-x-2">
        <p class="font-semibold text-current-500">
          {{ $t('USERMANAGEMENT_LABEL_DOMAIN') }}
        </p>
        <div class="flex-center p-1 rounded-full bg-current-50">
          <GroupById
            :group-id="groupId"
            avatar-class="w-5 h-5"
            class="text-sm"
          />
        </div>
      </div>
      <div class="flex-center">
        <span class="text-xs rounded-md py-1 px-2 border border-current-400">{{
          $t('COMMON_LABEL_TOTAL') + ': ' + groupDomains.length
        }}</span>
      </div>
    </div>

    <div
      class="
        flex-1
        min-h-0
        flex flex-col
        mx-2
        p-1
        text-sm
        bg-white
        overflow-hidden
        h-full
      "
    >
      <!-- add domain -->

      <template v-if="groupDomainsPayload?.length > 0">
        <div v-if="isPermissionForEditGroup" class="flex justify-end">
          <div class="h-8 flex items-center mx-4">
            <EditButton
              v-model="isEditting"
              :is-submitting="isSaving"
              :is-disable-submit="selectedDomainIds?.length == 0"
              @cancel="onCancel"
              @save="onSave"
            />
          </div>
        </div>
        <div class="flex-1 min-h-0 overflow-auto small-scrollbar">
          <!-- Edit -->
          <template v-if="isEditting">
            <template v-for="domain in groupDomainsPayload" :key="domain">
              <!-- domain -->
              <AtomCheckbox
                v-model="domain.checked"
                class="py-1"
                :label="domain.name"
                icon-class="fill-current"
              />
            </template>
          </template>
          <!-- Detail  -->
          <template v-else>
            <template v-for="domain in groupDomainsPayload" :key="domain">
              <!-- domain -->
              <AtomCheckbox
                v-if="domain.checked"
                class="py-1"
                :model-value="domain.checked"
                :label="domain.name"
                icon-class="fill-current"
              />
            </template>
          </template>
        </div>
      </template>
      <div v-else>
        <syn-animation
          name="searchNotFound"
          stype="width: 100px; height: 100px"
          :label="$t('PROFILE_ALERT_NO_DOMAIN')"
        />
      </div>
    </div>
  </div>
</template>
