<script setup lang="ts">
import { computed } from 'vue';
import { listColorSetup } from '@/ui/modules/messaging/chat-panel/chat-message/button-customize/calculate-color-customizes';

const props = withDefaults(
  defineProps<{
    colorCurrent: any;
  }>(),
  {
    colorCurrent: '',
  }
);
const emit = defineEmits(['onChangeBackgroundMsg']);

const isCurrentColor = computed(() =>
  props?.colorCurrent && listColorSetup.includes(props.colorCurrent)
    ? props.colorCurrent
    : ''
);

// const listColor = computed(() =>
//     orderBy(listColorSetup, [(color) => color === props.colorCurrent], ['desc'])
// );
const listColor = computed(() => listColorSetup);

const onSelectItem = (item) => {
  emit('onChangeBackgroundMsg', item);
};

// const sliderColor = ref(null as any);

// const onNextSlide = () => {
//   sliderColor.value.onNextSlide();
// };
//
// const onPrevSlide = () => {
//   sliderColor.value.onPrevSlide();
// };
</script>

<template>
  <div
    class="px-3 pb-3 rounded-full grid grid-cols-9 gap-0.5"
    style="max-width: 20rem"
  >
    <div v-for="item in listColor" :key="item" class="flex-center">
      <div
        class="
          rounded-full
          flex-center
          py-1
          w-8
          h-8
          hover:bg-current-50
          relative
        "
        @click="onSelectItem(item)"
      >
        <span
          class="w-6 h-6 rounded-full border"
          :class="
            item === isCurrentColor
              ? 'ring ring-current ring-opacity-30 border-current-400'
              : 'border-white'
          "
          :style="`background-color: ${item}`"
        ></span>
        <span
          v-if="item === isCurrentColor"
          class="
            flex-center
            w-3.5
            h-3.5
            rounded-full
            bg-red-500
            hover:bg-red-600
            absolute
            top-0
            right-0
          "
          @click.stop="onSelectItem(item)"
        >
          <SynIcon
            name="close"
            custom-class="fill-white text-white w-2.5 h-2.5"
          />
        </span>
      </div>
    </div>

    <!--    <div-->
    <!--      class="flex-center hover:bg-gray-100 w-5 h-5 rounded cursor-pointer px-1"-->
    <!--      @click="onPrevSlide"-->
    <!--    >-->
    <!--      <SynIcon name="chevron-left" custom-class="w-3 h-3 fill-gray-500" />-->
    <!--    </div>-->
    <!--    <AtomSwiperSlide-->
    <!--      ref="sliderColor"-->
    <!--      :slides-per-view="5"-->
    <!--      :is-show-pagination="false"-->
    <!--      :autoplay="false"-->
    <!--      :is-show-navigation="false"-->
    <!--    >-->
    <!--      <div-->
    <!--        v-for="item in listColor"-->
    <!--        :key="item"-->
    <!--        class="-->
    <!--          swiper-slide-->
    <!--          rounded-full-->
    <!--          flex-center-->
    <!--          py-1.5-->
    <!--          w-8-->
    <!--          h-8-->
    <!--          hover:bg-current-50-->
    <!--          relative-->
    <!--        "-->
    <!--        @click="onSelectItem(item)"-->
    <!--      >-->
    <!--        <span-->
    <!--          class="w-6 h-6 rounded-full border"-->
    <!--          :class="-->
    <!--            item === isCurrentColor-->
    <!--              ? 'ring ring-current ring-opacity-30 border-current-400'-->
    <!--              : 'border-white'-->
    <!--          "-->
    <!--          :style="`background-color: ${item}`"-->
    <!--        ></span>-->
    <!--        <div-->
    <!--          v-if="item === isCurrentColor"-->
    <!--          class="-->
    <!--            flex-center-->
    <!--            w-3.5-->
    <!--            h-3.5-->
    <!--            rounded-full-->
    <!--            bg-red-500-->
    <!--            hover:bg-red-600-->
    <!--            absolute-->
    <!--            top-0-->
    <!--            right-0-->
    <!--          "-->
    <!--          @click.stop="onSelectItem(item)"-->
    <!--        >-->
    <!--          <SynIcon-->
    <!--            name="close"-->
    <!--            custom-class="fill-white text-white w-2.5 h-2.5"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </AtomSwiperSlide>-->
    <!--    <div-->
    <!--      class="flex-center hover:bg-gray-100 w-5 h-5 rounded cursor-pointer px-1"-->
    <!--      @click="onNextSlide"-->
    <!--    >-->
    <!--      <SynIcon name="chevron-right" custom-class="w-3 h-3 fill-gray-500" />-->
    <!--    </div>-->
  </div>
</template>
