<script setup lang="ts">
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import { computed, onBeforeMount, ref } from 'vue';
import permissionStore from '@/store/permission';
import { StartWorkingConditionModel } from '@/application/models/setting/StartWorkingConditionModel';
import systemConfig from '@/application/constants/system-config.const';
import StartWorkingSettingsForm from '@/ui/modules/settings/start-working/start-working-settings-form/StartWorkingSettingsForm.vue';
import ModalSWSettingsForMembers from '@/ui/modules/settings/start-working/modal-settings-for-members/ModalSWSettingsForMembers.vue';

const _permissionStore = permissionStore();

const startWorkingCondition = ref<StartWorkingConditionModel>({});
const isSettingsForMembers = ref<boolean>();

const settings = computed(() => _permissionStore.allSystemConfigs);

onBeforeMount(() => {
  const systemConfigs = _permissionStore.allSystemConfigs || {};

  startWorkingCondition.value =
    JSON.parse(
      systemConfigs[systemConfig.START_WO_START_WORKING_BY_CONDITIONS] || null
    ) || {};
});

const onSettingChange = (setting) => {
  startWorkingCondition.value = {
    ...startWorkingCondition.value,
    ...(setting || {}),
  };

  _permissionStore.setPermissions({
    key: systemConfig.START_WO_START_WORKING_BY_CONDITIONS,
    value: JSON.stringify(startWorkingCondition.value),
  });
};
</script>

<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.ADMIN.SETTING.START_WORKING,
      isPage: true,
    }"
    class="w-full h-full flex flex-col bg-white"
  >
    <!--TITLE-->
    <div class="flex items-center gap-4 p-6">
      <div class="flex-1">
        <div class="text-gray-700 font-medium">
          {{ $t('SETTING_SW_CONDITION_TITLE') }}
        </div>
        <div class="text-xs text-gray-500">
          {{ $t('SETTING_SW_CONDITION_DESC') }}
        </div>
      </div>
      <VigButton
        v-if="false"
        outline
        icon="Groups"
        class="text-sm"
        :label="$t('SETTING_SW_CONDITION_SPECIFIC')"
        @click="isSettingsForMembers = true"
      />
    </div>

    <StartWorkingSettingsForm
      :start-working-condition="startWorkingCondition"
      :settings="settings"
      @on-setting-change="onSettingChange"
    />
  </div>

  <ModalSWSettingsForMembers
    v-if="isSettingsForMembers"
    @on-close="isSettingsForMembers = false"
  />
</template>
