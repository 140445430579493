<script setup lang="ts">
// *** IMPORTS ***

// *** PROPS, EMITS ***
import {
  breakpointsSematic,
  useBreakpoints,
  useWindowSize,
} from '@vueuse/core';
import { computed } from 'vue';

const props = defineProps<{
  calendarView: string;
}>();

defineEmits(['update:calendarView']);

// *** PRIVATE VARIABLES ***
const _calendarViews = [
  { value: 'dayGridMonth', text: 'CALENDAR_TITLE_MONTH' },
  { value: 'timeGridWeek', text: 'CALENDAR_TITLE_WEEK' },
  { value: 'timeGridDay', text: 'CALENDAR_TITLE_DAY' },
  { value: 'listWeek', text: 'CALENDAR_TITLE_LIST' },
];

// *** COMPOSABLES ***
const { width: windowWidth } = useWindowSize();

// *** REFS ***

// *** COMPUTED ***
const isMobileSize = computed(
  () => windowWidth.value < breakpointsSematic.mobileL
);

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div v-if="!isMobileSize" class="flex-center gap-1">
    <VigButton
      v-for="view in _calendarViews"
      :key="view?.value"
      :label="$t(view?.text)"
      light
      :color="view?.value === calendarView ? 'current' : 'gray'"
      class="text-sm"
      padding="px-2 py-1.5"
      @click="$emit('update:calendarView', view?.value)"
    />
  </div>

  <select
    v-else
    class="
      rounded-md
      text-sm text-gray-600
      font-semibold
      bg-gray-100
      border-0
      focus:ring-0
      pl-2
      pr-7
      py-1.5
    "
    :value="calendarView"
    @change="$emit('update:calendarView', $event?.target?.value)"
  >
    <option
      v-for="view in _calendarViews"
      :key="view?.value"
      :value="view?.value"
    >
      {{ $t(view?.text) }}
    </option>
  </select>
</template>

<style scoped></style>
