<script setup lang="ts">
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  task: any;
}>();

const onCodeClick = () => {
  const taskCode = formatTaskCode(props.task?.code);
  if (!taskCode) return;

  const copied = copyToClipBroard(`#${taskCode}`);

  if (copied) {
    openNotification({
      body: translate('TASK_DETAIL_ACTION_COPIED_CODE'),
      duration: 2000,
    });
  }
};
</script>

<template>
  <span
    role="button"
    class="px-2 py-1.5 rounded font-medium text-xs text-gray-600 bg-gray-100"
    @click="onCodeClick"
  >
    {{ `Id: ${formatTaskCode(task?.code)}` }}
  </span>
</template>
