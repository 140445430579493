<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    optionStatus?: any;
    styleTab?: string;
    isActive?: string;
    keyText?: string;
    keyStatus?: string;
    positon?: string;
    isHeader?: boolean;
    customHeaderClass?: string;
  }>(),
  {
    position: 'left',
    keyStatus: 'key',
    keyText: 'label',
    isActive: 'isActive',
  }
);
const emit = defineEmits(['changeTab']);
const changeTab = (section) => {
  if (section?.disabled) return;
  emit('changeTab', section[props.keyStatus]);
};
</script>

<template>
  <div class="flex-1 min-h-0 h-full w-full flex flex-col">
    <div
      class="text-sm flex items-center space-x-0.5 w-full relative"
      :class="
        positon == 'center'
          ? 'justify-center items-center'
          : positon == 'left'
          ? 'justify-start items-center'
          : positon == 'end'
          ? 'justify-end items-center'
          : 'justify-between items-center'
      "
      :style="styleTab"
    >
      <slot v-if="isHeader" name="header"></slot>
      <div class="flex-center" :class="[customHeaderClass]">
        <div
          v-for="section in optionStatus"
          :key="section"
          :class="[
            section[isActive]
              ? ' text-current-500 font-medium '
              : ' text-gray-500',
            section?.disabled
              ? 'bg-gray-100 opacity-70 cursor-not-allowed'
              : 'cursor-pointer',
          ]"
          class="group flex flex-col py-2 px-3 w-max relative"
          @click="changeTab(section)"
        >
          <div class="flex items-center px-2">
            <span
              class="text-overflow-hidden-line"
              :class="[
                section.subContent ? 'pr-1' : '',
                section?.disabled ? '' : 'group-hover:text-current-500',
              ]"
            >
              {{ $t(section[keyText]) || section?.labelDefault }}
            </span>
            <span
              v-if="section.subContent"
              class="group-hover:text-current-400"
            >
              ({{ section.subContent || '' }})
            </span>
          </div>

          <span
            class="border-b-2 mt-3 border-current-500"
            style="transition: width 150ms ease-in-out"
            :class="[
              section[isActive] ? 'w-full' : 'w-0',
              section?.disabled ? '' : 'group-hover:w-full',
            ]"
          ></span>
        </div>
      </div>
      <slot name="prefix"></slot>
    </div>
    <slot name="tabBody"></slot>
  </div>
</template>
