<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import groupStore from '@/store/group';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import BreakLinkDepartmentGroupModal from '@/ui/modules/group/detail/BreakLinkDepartmentGroupModal.vue';
import myProfileStore from '@/store/auth/my-profile';
import GroupDetailEntity, {
  IGroupDetailEntity,
} from '@/domain/entities/group/GroupDetailEntity';
import { IGroupUser } from '@/application/types/group/group.types';
import GroupUpdateMemberList from '@/ui/modules/group/components/GroupUpdateMemberList.vue';
import GroupMemberList from '@/ui/modules/group/components/GroupMemberList.vue';

const props = defineProps<{
  groupId: number;
  isSaveSuccess?: boolean;
}>();
defineEmits(['saveUser', 'systemGroupAlert']);

const _groupStore = groupStore();

const allGroupByIds = computed<{ number: IGroupDetailEntity }>(() => {
  return _groupStore.allGroupByIds;
});
const groupDetail = computed<IGroupDetailEntity>(() => {
  if (
    !allGroupByIds.value ||
    !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
  )
    return {};
  return allGroupByIds.value[props.groupId];
});

const groupUsers = computed<IGroupUser[]>(() => {
  return groupDetail.value?.groupUsers;
});

const groupDetailDataRef = ref<GroupDetailEntity>(
  new GroupDetailEntity(groupDetail.value)
);
onMounted(() => {
  groupDetailDataRef.value = new GroupDetailEntity(groupDetail.value);
});

// const currentUser = computed(() => myProfileStore().myProfile);
const canEditGroupInfo = computed(() => {
  return (
    (!groupDetail.value?.isPrivate &&
      (myProfileStore().isAdmin || myProfileStore().isOwner)) ||
    groupDetail.value?.isAdminGroup
  );
});
// const isLoading = ref(false);
const showAlertRemainOneAdmin = ref(false);

const init = async () => {
  // isLoading.value = true;
  // await _groupStore.getAllDomainOfUser();
  // isLoading.value = false;
};
init();

const showAlertSystemGroup = ref(false);
const isShowAlertCannotEditDepartmentGroup = ref(false);

const onClickEdit = () => {
  if (!groupDetailDataRef.value.hasPermission()) return;

  isOpenAddUser.value = true;
};

const isOpenAddUser = ref(false);
const isSaveUser = ref(false);
const isSaving = ref<boolean>(false);
const onSaveChangeMembers = async () => {
  try {
    isSaving.value = true;
    const updateSuccess = await groupDetailDataRef.value.updateMembers(
      groupDetailDataRef.value.groupUsers
    );

    if (!updateSuccess) return;
    isOpenAddUser.value = false;
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isSaving.value = false;
  }
};

const onCancelUser = async () => {
  await init();
  isOpenAddUser.value = false;
};

const updateMemberSuccess = () => {
  groupDetailDataRef.value = new GroupDetailEntity(groupDetail.value);
};
</script>
<template>
  <div class="flex items-center justify-between mx-4 bg-white">
    <div class="text-sm text-current-600 rounded-t-xl font-medium">
      {{ $t('COMMON_LABEL_ASSIGN_GROUP') }}
      <span v-if="groupUsers?.length > 0">
        {{ ' (' + groupUsers?.length + ')' }}
      </span>
    </div>
    <div v-if="groupDetail?.code !== 'DELETED_GROUP'">
      <div
        v-if="!isOpenAddUser && canEditGroupInfo"
        class="
          flex-center
          space-x-1
          font-medium
          text-current-700
          cursor-pointer
        "
        @click="onClickEdit"
      >
        <SynIcon
          has-action
          name="edit"
          is-active
          class="text-current-500 fill-current"
        />
        <span class="font-base text-xs">{{ $t('COMMON_LABEL_EDIT') }}</span>
      </div>
      <div v-if="isOpenAddUser" class="text-xs flex space-x-2">
        <SynButton
          v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          :disabled="isSaveUser"
          @click="onCancelUser"
        />
        <SynButton
          v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
          :label="$t('COMMON_LABEL_SAVE')"
          :is-loading="isSaving"
          :disabled="groupDetailDataRef?.groupUsers?.length == 0"
          @click="onSaveChangeMembers"
        />
      </div>
    </div>
  </div>

  <GroupUpdateMemberList
    v-if="isOpenAddUser"
    v-model="groupDetailDataRef.groupUsers"
    mode="edit"
  />
  <div v-else class="flex-1 min-h-0 overflow-auto small-scrollbar w-1/2">
    <GroupMemberList
      :group-id="groupId"
      @update-member-success="updateMemberSuccess"
    />
  </div>
  <AlertModal
    v-if="showAlertRemainOneAdmin"
    :title-text="$t('COMON_NOT_ALLOW_PERFORM_ACTION')"
    :sub-title-text="$t('GROUP_UPDATE_ADMIN_NOTALLOW')"
    container-class="w-1/4"
    name-icon="warning"
    :container-color="'current'"
    @cancel="showAlertRemainOneAdmin = false"
  />

  <AlertModal
    v-if="showAlertSystemGroup"
    :title-text="$t('GROUP_LABEL_EDIT_SYSTEM_GROUP_TITLE')"
    :sub-title-text="
      $t('GROUP_LABEL_REMOVE_SYSTEM_GROUP_CONTENT', {
        groupName: groupDetail?.name,
      })
    "
    :is-html-text="true"
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="showAlertSystemGroup = false"
  />
  <BreakLinkDepartmentGroupModal
    v-if="isShowAlertCannotEditDepartmentGroup"
    :group="groupDetail"
    @cancel="isShowAlertCannotEditDepartmentGroup = false"
    @update-success="isShowAlertCannotEditDepartmentGroup = false"
  />
</template>

<style scoped>
.item-domain:hover .item-cancel {
  display: block;
}
.item-cancel {
  display: none;
}
</style>
