<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import departmentStore from '@/store/department';
import userStore from '@/store/user';
import { GroupMemberRole } from '@/ui/common/constants/constant';

const props = defineProps<{
  userId: number | string;
}>();
const emit = defineEmits(['onCancel', 'onUpdateSuccess']);

const _departmentStore = departmentStore();

const currentUserInfo = computed(() => {
  return userStore().allActiveUserWithDepartments.find(
    (user) => user.id == props.userId
  );
});
const userDepartment = computed(() => {
  if (
    !currentUserInfo.value ||
    !currentUserInfo.value?.departmentInfos ||
    currentUserInfo.value?.departmentInfos?.length == 0
  )
    return null;

  return currentUserInfo.value?.departmentInfos[0];
});

const allDepartmentByIds = computed(() => departmentStore().allDepartmentByIds);
const onCancel = () => {
  emit('onCancel');
};

const isDisabledSaveButton = computed(() => {
  if (userDepartment.value?.id) {
    return false;
  } else {
    return selectedDepartmentIds.value?.length == 0;
  }
});

onMounted(() => {
  if (userDepartment.value?.id)
    selectedDepartmentIds.value = [`${userDepartment.value?.id}`];

  memberRole.value = userDepartment.value?.role;
});

const onSaveMoveDepartment = async () => {
  await _departmentStore.updateUserDepartments({
    userId: parseInt(props.userId?.toString()),
    departments: selectedDepartmentIds.value.map((departmentId) => {
      return {
        departmentId: parseInt(departmentId),
        role: memberRole.value,
      };
    }),
  });

  emit('onUpdateSuccess');
};

const selectedDepartmentIds = ref<any[]>([]);
const onChooseDepartment = (departmentId) => {
  selectedDepartmentIds.value = selectedDepartmentIds.value?.includes(
    departmentId
  )
    ? []
    : [departmentId];

  const currentDepartment = allDepartmentByIds.value[departmentId];

  memberRole.value =
    currentDepartment?.departmentUsers?.length == 0
      ? GroupMemberRole.Admin
      : GroupMemberRole.User;
};
const memberRole = ref<GroupMemberRole>(GroupMemberRole.User);

const onChangeRole = () => {
  memberRole.value =
    memberRole.value == GroupMemberRole.User
      ? GroupMemberRole.Admin
      : GroupMemberRole.User;
};
</script>
<template>
  <SynModal
    z-index="z-50"
    container-class="md:w-1/4"
    container-style="min-width: 32rem"
    style-body="p-0 flex flex-row flex-wrap"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <div>
        {{ $t('USER_LABEL_CHANGE_DEPARTMENT_TITLE') }}
      </div>
    </template>
    <template #body>
      <!-- invite members -->
      <div
        class="flex-1 flex items-center flex-col space-y-2 overflow-hidden px-4"
      >
        <template v-if="userDepartment?.id">
          <div class="w-full flex items-center space-x-2 py-2">
            <span class="flex space-x-1 flex-wrap">
              <span
                v-if="userDepartment?.role == 1"
                v-html="
                  $t('USER_LABEL_CHANGE_DEPARTMENT_ADMIN_DETAIL', {
                    userName: currentUserInfo?.name,
                  })
                "
              >
              </span>
              <span
                v-else
                v-html="
                  $t('USER_LABEL_CHANGE_DEPARTMENT_MEMBER_DETAIL', {
                    userName: currentUserInfo?.name,
                  })
                "
              >
              </span>
              <span class="text-current">
                <DepartmentById
                  is-hidden-avatar
                  :department-id="userDepartment?.id"
                />
              </span>
            </span>
          </div>
        </template>
        <template v-else>
          <div class="w-full flex items-center space-x-2 text-lg pb-2">
            <span
              v-html="
                $t('USER_LABEL_CHANGE_DEPARTMENT_NO_DEPARTMENT', {
                  userName: currentUserInfo?.name,
                })
              "
            >
            </span>
          </div>
        </template>
        <div class="w-2/3 pb-2 border-b border-gray-100"></div>
        <div class="w-full flex space-x-1 text-sm">
          <span>
            {{ $t('USER_LABEL_CHANGE_DEPARTMENT_SELECT_TITLE') }}
          </span>
          <AtomHelpDropdown title="USER_LABEL_CHANGE_DEPARTMENT_WARNING_TITLE">
            <template #content>
              <ul class="pl-4" style="list-style: circle">
                <li
                  v-html="
                    $t('USER_LABEL_CHANGE_DEPARTMENT_WARNING_CONTENT_00') ||
                    'Note: If you select a user who is a member of another Department, that user will become a member of the new Department and no longer be a memberof the old Department'
                  "
                ></li>
                <li
                  v-html="
                    $t('USER_LABEL_CHANGE_DEPARTMENT_WARNING_CONTENT_01') ||
                    'Note: If you select a user who is a member of another Department, that user will become a member of the new Department and no longer be a memberof the old Department'
                  "
                ></li>
              </ul>
            </template>
          </AtomHelpDropdown>
        </div>
        <div class="w-full h-full small-scrollbar overflow-auto">
          <div
            v-for="departmentId in Object.keys(allDepartmentByIds)"
            :key="departmentId"
            class="
              list-li
              rounded-mdhover:bg-gray-100
              flex
              items-center
              justify-between
            "
            :class="
              selectedDepartmentIds?.includes(departmentId)
                ? 'bg-current-50'
                : ''
            "
            @click="onChooseDepartment(departmentId)"
          >
            <div
              class="
                w-full
                flex
                items-center
                justify-between
                space-x-2
                cursor-pointer
              "
            >
              <div
                class="w-full px-2 flex items-center justify-between space-x-2"
              >
                <div class="flex-1 flex items-center justify-start space-x-2">
                  <SynIcon
                    :is-active="selectedDepartmentIds?.includes(departmentId)"
                    custom-class="w-4 h-4"
                    class="fill-current"
                    name="checkbox"
                  />
                  <span>
                    <DepartmentById :department-id="departmentId" />
                  </span>
                  <span
                    v-if="
                      selectedDepartmentIds?.includes(departmentId) &&
                      memberRole === GroupMemberRole.Admin
                    "
                    class="
                      rounded-md
                      text-2xs
                      py-0.5
                      px-1
                      text-gray-500
                      admin-text
                    "
                  >
                    {{ `(${$t('COMMON_LABEL_ADMIN')})` }}
                  </span>
                </div>
                <!--  TYPE          -->

                <small
                  v-if="selectedDepartmentIds?.includes(departmentId)"
                  class="hover:font-medium italic pr-2 cursor-pointer"
                  :class="
                    memberRole === GroupMemberRole.Admin
                      ? 'text-red-500 hover:text-red-700'
                      : 'text-green-500 hover:text-green-700'
                  "
                  @click.stop="onChangeRole"
                >
                  <span class="action-text">
                    {{
                      $t(
                        memberRole === GroupMemberRole.Admin
                          ? 'GROUP_UPDATE_MEMBER_UNSET_ADMIN'
                          : 'GROUP_UPDATE_MEMBER_SET_ADMIN'
                      )
                    }}
                  </span>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-between p-4 space-x-2">
        <div class="flex-center text-xs text-orange-500 space-x-2">
          <span
            v-if="userDepartment?.id && selectedDepartmentIds?.length == 0"
            v-html="
              $t('USER_LABEL_CHANGE_DEPARTMENT_WARNING_NO_DEPARTMENT', {
                userName: currentUserInfo?.name,
              })
            "
          >
          </span>
        </div>
        <div class="flex justify-end space-x-2">
          <SynButton
            color="gray"
            type-text
            :label="$t('COMMON_LABEL_CANCEL')"
            @click="onCancel"
          />
          <SynButton
            :label="$t('COMMON_LABEL_SAVE')"
            :disabled="isDisabledSaveButton"
            @click="onSaveMoveDepartment"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
