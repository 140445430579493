<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    size?: string;
    disabled?: boolean;
    isLoading?: boolean;
    customClass?: string | string[];
    position?: string;
  }>(),
  {
    customClass: 'p-2',
    position: 'flex-center',
  }
);

const classBySize = computed(() => {
  if (props.size == 'tiny') return 'p-0.5 rounded-full tiny-size';
  if (props.size == 'small') return 'py-1 pr-1 pl-1 rounded text-xs h-6 ';
  if (props.size == 'circle-small') return 'rounded-full text-xs ';
  if (props.size == 'middle') return 'py-1 pr-1 pl-1 rounded-full text-sm';
  if (props.size == 'overview') return 'py-1 px-2 rounded-lg text-sm';
  return 'rounded ';
});
</script>
<template>
  <div
    class="relative whitespace-nowrap"
    :class="[
      classBySize,
      disabled ? 'cursor-not-allowed' : '',
      customClass,
      position,
    ]"
  >
    <slot></slot>
    <syn-local-loading v-if="isLoading" />
  </div>
</template>
<style scoped>
.tiny-size {
  font-size: 0.6rem;
  line-height: 0.75rem;
  height: 1rem;
  width: fit-content;
}
</style>
