<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.5"
    height="33"
    viewBox="0 0 16.5 33"
  >
    <path
      id="Icon_material-attach-file"
      data-name="Icon material-attach-file"
      d="M24.75,9V26.25a6,6,0,0,1-12,0V7.5a3.75,3.75,0,0,1,7.5,0V23.25a1.5,1.5,0,0,1-3,0V9H15V23.25a3.75,3.75,0,0,0,7.5,0V7.5a6,6,0,0,0-12,0V26.25a8.25,8.25,0,0,0,16.5,0V9Z"
      transform="translate(-10.5 -1.5)"
    />
  </svg>
</template>
