<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import {
  ITaskTodo,
  ITaskTodoList,
  ITaskTodoItemStatus,
} from '@/application/types/task/task.types';
import taskDetailStore from '@/store/task/detail';
// import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  taskId?: number;
  todoList?: ITaskTodo;
  hiddenFooter?: boolean;
  organizationId?: number;
  showAddNewButton?: boolean;
  readonly?: boolean;
}>();
const emit = defineEmits([
  'onCancel',
  'onCreateSuccess',
  'onSave',
  'update:todoList',
  'onAddNew',
]);

const _taskDetailStore = taskDetailStore();

const todoListPayload = ref<ITaskTodo>({
  id: uuidv4(),
  title: '',
  description: '',
  todoItems: [
    {
      id: uuidv4(),
      title: '',
      shortDescription: '',
      description: '',
      status: ITaskTodoItemStatus.PENDING,
      index: 0,
    },
  ],
});

const initComponent = () => {
  todoListPayload.value = props.todoList;
};

const stopBubbleDragEvent = () => {
  const divEl = document.getElementById('task-todo-list-detail');
  if (!divEl) return;

  divEl.addEventListener('dragenter', (event) => {
    event.stopPropagation();
  });
};

onMounted(() => {
  setTimeout(() => {
    const firstItem =
      todoListPayload.value?.todoItems?.length > 0
        ? todoListPayload.value?.todoItems[0]
        : null;

    if (
      todoListPayload.value?.todoItems?.length == 1 &&
      firstItem?.id &&
      !firstItem?.title
    )
      focusOnItem(firstItem?.id);
  });

  stopBubbleDragEvent();
});

const atomChecklistRef = ref<any>(null);
watch(
  () => props.todoList?.id,
  () => {
    initComponent();

    setTimeout(() => {
      if (atomChecklistRef.value && atomChecklistRef.value?.initComponent)
        atomChecklistRef.value?.initComponent();
    });
  }
);
const onClose = () => {
  emit('onCancel');
};

const isLoading = ref<boolean>(false);
const onSave = async () => {
  if (props.taskId) {
    isLoading.value = true;
    const newPayload: ITaskTodoList = {
      taskId: props.taskId,
      taskTodos: [todoListPayload.value],
    };

    await _taskDetailStore.createOrUpdateTaskCheckList(
      newPayload,
      props.organizationId
    );
    emit('onCreateSuccess', todoListPayload.value);
  } else {
    emit('onSave', todoListPayload.value);
  }
};

const updateTodoList = () => {
  emit('update:todoList', todoListPayload.value);
};

onUnmounted(() => {});

const focusOnItem = (id) => {
  if (
    atomChecklistRef.value &&
    typeof atomChecklistRef.value?.focusOnItem == 'function'
  )
    atomChecklistRef.value?.focusOnItem(id);
};

initComponent();

defineExpose({
  focusOnItem,
});
</script>
<template>
  <section
    id="task-todo-list-detail"
    class="
      flex-1
      min-h-0
      w-full
      h-full
      flex flex-col
      gap-1
      border
      rounded-md
      border-gray-300
      relative
    "
  >
    <span
      class="
        absolute
        -top-2
        -right-2
        z-10
        w-6
        h-6
        rounded-full
        flex-center
        hover:text-black hover:bg-gray-300
        bg-gray-100
        cursor-pointer
        task-todo-list-all_hover-to-show
      "
      @click.stop="$emit('onCancel')"
    >
      <SynIcon name="trash" custom-class="w-4 h-4 fill-red-500" />
    </span>
    <div class="flex-center space-x-12">
      <div class="flex-1">
        <div v-if="readonly" class="px-4 py-2 w-full bg-gray-50 rounded">
          {{ todoListPayload.title }}
        </div>
        <SynInput
          v-else
          v-model="todoListPayload.title"
          :placeholder="$t('COMMON_LABEL_TITLE')"
          :style="`border: 0px; background: #e0f1f2 `"
        />
      </div>
      <SynButton
        v-if="showAddNewButton && !readonly"
        :label="$t('TASK_DETAIL_ADD_CHECKLIST')"
        name-icon="plus"
        @click="$emit('onAddNew')"
      />
    </div>

    <div
      id="task-todo-list-detail_checklist"
      class="flex-1 min-h-0 flex flex-col overflow-hidden relative"
    >
      <!-- ITEM -->
      <AtomChecklist
        ref="atomChecklistRef"
        v-model="todoListPayload.todoItems"
        :readonly="readonly"
        @update:model-value="updateTodoList"
      />
    </div>
    <div
      v-if="!hiddenFooter"
      class="flex items-center justify-end gap-2 text-xs p-4"
    >
      <SynButton
        :label="$t('COMMON_LABEL_CANCEL') || 'Close'"
        type-text
        color="gray"
        @click.stop="onClose"
      />

      <SynButton
        :is-loading="isLoading"
        :label="$t('COMMON_LABEL_SAVE') || 'Save'"
        @mousedown.stop="onSave"
      />
    </div>
  </section>
</template>
