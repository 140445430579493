<template>
  <span class="text-xl font-semibold">
    {{ label }}
  </span>
</template>
<script lang="ts">
import { ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'SynLabel',
  components: {},
  props: {
    label: {
      type: String,
      required: true,
      default: 'Syn label',
    },
  },
  setup: () => {
    const count = ref(0);
    return { count };
  },
});
</script>
