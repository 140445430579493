import WhiteboardRepository from '@/application/repositories/WhiteboardRepository';
class WhiteboardService {
    constructor() {}

    getAllByUser() {
        return WhiteboardRepository.getAllByUser();
    }
    getWhiteboardDetail(whiteboardId) {
        return WhiteboardRepository.getWhiteboardDetail(whiteboardId);
    }
    createWhiteboard(payload) {
        const data = {
            ...payload,
            title: payload?.title || 'New Whiteboard',
            maxIndex: payload?.maxIndex,
            shapeList: payload?.shapeList?.map((shape) => {
                return {
                    ...shape,
                    sourceId: shape?.sourceId?.toString(),
                    sourceData: JSON.stringify(shape?.sourceData),
                };
            }),
        };
        return WhiteboardRepository.createWhiteboard(data);
    }
    updateWhiteboard(payload) {
        if (!payload?.id) return;

        const data = {
            id: payload?.id,
            title: payload?.title || 'New Whiteboard',
            translateX: payload?.translateX || 0,
            translateY: payload?.translateY || 0,
            maxIndex: payload?.maxIndex,
            lastModificationTime: payload?.lastModificationTime,
            shapeList: payload?.shapeList
                ?.filter((o) => o?.id)
                .map((shape) => {
                    return {
                        ...shape,
                        sourceId: shape?.sourceId?.toString(),
                        sourceData: JSON.stringify(shape?.sourceData),
                    };
                }),
        };
        return WhiteboardRepository.updateWhiteboard(data);
    }
    updateWhiteboardTitle(whiteboardId, newTitle) {
        return WhiteboardRepository.updateWhiteboardTitle(
            whiteboardId,
            newTitle
        );
    }
    deleteWhiteboard(whiteboardId) {
        return WhiteboardRepository.deleteWhiteboard(whiteboardId);
    }
    addDeletedShapeToBin(data) {
        return WhiteboardRepository.addDeletedShapeToBin(data);
    }
    getAllRecycleShapeByUser() {
        return WhiteboardRepository.getAllRecycleShapeByUser();
    }
    deleteRecycleShape(recycleShapeId) {
        return WhiteboardRepository.deleteRecycleShape(recycleShapeId);
    }
}

export default new WhiteboardService();
