<script setup lang="ts">
import { computed, onMounted, watch, ref } from 'vue';
import $constants from '@/ui/plugins/constants';
import { translate } from '@/ui/plugins/i18n/myi18n';
import MessageFile from '@/ui/modules/messaging/chat-panel/chat-message/message-file/MessageFile.vue';
import MessageTask from '@/ui/modules/messaging/chat-panel/chat-message/message-task/MessageTask.vue';
import DayoffListItem from '@/ui/components/dayoff/atoms/DayoffListItem.vue';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import MessageDocModel from '@/ui/plugins/firebases/firestore-database/models/MessageDocModel';
import { prepareMessage } from '@/ui/plugins/firebases/firestore-database/constants';
import EventListItem from '@/ui/components/calendars/atoms/EventListItem.vue';

const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);

const props = defineProps({
  replyToMsg: {
    type: Object,
    default: null,
  },
  conversationId: {
    type: String,
    default: '',
  },
  replyToMsgId: {
    type: String,
    default: '',
  },
  messages: {
    type: Object,
    default: () => ({}),
  },
  members: {
    type: Object,
    default: () => ({}),
  },
  isMyMessage: Boolean,
});

defineEmits(['onClickMessageReply']);

const replyToMsgObj = ref();

// let timer;

onMounted(() => {
  if (!props.replyToMsg) {
    _getConversationMessagesId(props.conversationId, props.replyToMsgId);
  } else {
    replyToMsgObj.value = props.replyToMsg;
  }
});

watch(
  () => props.replyToMsg,
  () => {
    // console.log('replyToMsgObj', replyToMsgObj.value);
  }
);

const replyToUser = computed(
  () => props.members[replyToMsgObj.value?.createdBy]
);

const replyMsgFile = computed(() => {
  const msgFiles = Object.values(replyToMsgObj.value?.files || {});
  if (
    msgFiles.length &&
    msgFiles.every((file: any) =>
      file?.contentType?.startsWith($constants.FILE.TYPE.IMAGE)
    )
  ) {
    if (msgFiles?.length === 1) {
      return msgFiles[0];
    }

    if (msgFiles?.length > 1) {
      return (
        translate('CHAT_REPLYING_NUM_PHOTOS', {
          number: msgFiles?.length,
        }) || `${msgFiles?.length} photos`
      );
    }

    return translate('CHAT_REPLYING_PHOTO') || 'Photo';
  }

  if (msgFiles?.length > 1) {
    return (
      translate('CHAT_REPLYING_NUM_FILES', { number: msgFiles?.length }) ||
      `${msgFiles?.length} files`
    );
  }

  if (msgFiles?.length) {
    return translate('CHAT_REPLYING_FILE') || 'File';
  }

  return null;
});

const _getConversationMessagesId = (conversationId, messageId) => {
  if (!organizationId || !messageId || !conversationId) return;

  new MessageDocModel(conversationId, messageId).subscibleDetailChanged(
    (data) => {
      replyToMsgObj.value = prepareMessage(messageId, data);
    }
  );
};
</script>
<template>
  <div
    class="flex flex-col"
    style="margin-bottom: -10px"
    :class="isMyMessage ? 'flex-row-reverse' : 'flex-row'"
    :style="isMyMessage ? 'align-items: self-end' : ''"
  >
    <div class="text-gray-400 text-xs mb-1 flex">
      <span v-if="replyToMsgObj?.removed">
        {{
          $t('CHAT_MSG_REPLIED_TO_REMOVED') || 'Reply to removed message'
        }}</span
      >
      <span v-else>
        <SynIcon name="Reply" custom-class="w-3 h-3 fill-gray-500" />
        {{
          $t('CHAT_MSG_REPLIED_TO_USER', {
            user: replyToUser?.name || '',
          })
        }}</span
      >
    </div>

    <div
      class="flex cursor-pointer max-w-sm w-full"
      :class="isMyMessage ? 'flex-row-reverse' : 'flex-row'"
      @click.stop="$emit('onClickMessageReply', replyToMsgId)"
    >
      <!-- REMOVED -->
      <div
        v-if="replyToMsgObj?.removed"
        class="
          px-4
          pt-2
          pb-5
          rounded-2xl
          bg-gray-50
          text-gray-400 text-sm
          hover:bg-gray-100
        "
      >
        {{ $t('CHAT_MSG_DELETED') || 'Message has been deleted' }}
      </div>

      <div
        v-else-if="
          (replyToMsgObj?.text &&
            !replyToMsgObj?.task &&
            !replyToMsgObj?.dayoff &&
            !replyToMsgObj?.eventData &&
            !replyToMsgObj?.call) ||
          (!replyMsgFile?.contentType &&
            !replyToMsgObj?.task &&
            !replyToMsgObj?.dayoff &&
            !replyToMsgObj?.eventData &&
            !replyToMsgObj?.call)
        "
        class="px-4 pt-2 pb-5 rounded-2xl bg-gray-50 hover:bg-gray-100 max-w-xl"
        :class="isMyMessage ? 'ml-28' : 'mr-28'"
      >
        <div
          v-twemoji="{ size: 'w-5 h-5' }"
          class="text-gray-400 text-sm leading-5 line-clamp-2"
          style="word-break: break-word"
          v-html="replyToMsgObj?.text || replyMsgFile || '&nbsp;'"
        ></div>
      </div>

      <div
        v-else-if="
          (replyToMsgObj?.comment && replyToMsgObj?.task) ||
          (replyToMsgObj?.comment && replyToMsgObj?.dayoff) ||
          (replyToMsgObj?.comment && replyToMsgObj?.eventData)
        "
        class="px-4 pt-2 pb-5 rounded-2xl bg-gray-50 hover:bg-gray-100 max-w-xl"
        :class="isMyMessage ? 'ml-28' : 'mr-28'"
      >
        <div
          v-twemoji="{ size: 'w-5 h-5' }"
          class="text-gray-400 text-sm leading-5 line-clamp-2"
          style="word-break: break-word"
          v-html="replyToMsgObj?.comment || replyMsgFile"
        ></div>
      </div>

      <MessageTask
        v-else-if="replyToMsgObj?.task && !replyToMsgObj?.comment"
        :task="replyToMsgObj?.task"
        is-my-message
        :is-comment="!!replyToMsgObj?.comment"
        :members="members"
        is-readonly
        @click.stop="$emit('onClickMessageReply', replyToMsgId)"
      />

      <div
        v-else-if="replyToMsgObj?.dayoff && !replyToMsgObj?.comment"
        class="
          my-0.5
          rounded
          bg-gray-50
          text-gray-500
          border border-gray-300
          cursor-pointer
          hover:bg-gray-100
          max-w-md
          xl:min-w-xs
        "
      >
        <DayoffListItem
          :dayoff-data="replyToMsgObj?.dayoff"
          is-show-title
          is-hidden-name
        />
      </div>

      <div
        v-else-if="replyToMsgObj?.eventData && !replyToMsgObj?.comment"
        class="
          my-0.5
          rounded
          bg-gray-50
          text-gray-500
          border border-gray-300
          cursor-pointer
          max-w-md
          xl:min-w-xs
        "
      >
        <EventListItem :event-data="replyToMsgObj?.eventData" />
      </div>

      <MessageFile
        v-else-if="replyMsgFile"
        :file="replyMsgFile"
        :img-size="$constants.IMAGE.SIZE.MEDIUM"
        size="sm"
        class="max-w-xs"
        :is-hidden-action="true"
      />
    </div>
  </div>
</template>
