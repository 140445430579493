import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';

dayjs.extend(utc);
dayjs.extend(tz);

const utcDate = computed(() => myProfileStore().getTimezone);
const timezone = computed(() => {
    const timeZone = myProfileStore().getTimezone;
    if (timeZone == '+7') return 'Asia/Bangkok';
    if (timeZone == '-5') return 'America/New_York';
    else return 'Europe/Paris';
});

export const covertToDateObj = (date) => {
    return {
        hour: date
            ? dayjs(new Date(date)).tz(timezone.value).get('hour')
            : null,
        minute: date
            ? dayjs(new Date(date)).tz(timezone.value).get('minute')
            : null,
        text: date
            ? dayjs(new Date(date)).tz(timezone.value).format('hh:mm A')
            : null,
        day: date
            ? dayjs(new Date(date)).tz(timezone.value).format('MM-DD-YYYY')
            : null,
    };
};

export const formatDatetimeUTC = (datetimeObj) => {
    return new Date(
        datetimeObj.day +
            ' ' +
            datetimeObj.hour +
            ':' +
            datetimeObj.minute +
            ' GMT' +
            utcDate.value +
            '00'
    );
};

export const formatDateWithTimeUTC = (date) => {
    const year_date = dayjs(new Date(date)).tz(timezone.value).format('YYYY');
    const month_date = dayjs(new Date(date)).tz(timezone.value).format('MM');
    const day_date = dayjs(new Date(date)).tz(timezone.value).format('DD');
    return new Date(
        Date.UTC(
            parseInt(year_date),
            parseInt(month_date) - 1,
            parseInt(day_date),
            0,
            0,
            0
        )
    );
};
