<script setup lang="ts">
import { computed, markRaw, ref, watch } from 'vue';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import DeletePlugin from '@ckeditor/ckeditor5-typing/src/delete';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import AutoLinkPlugin from '@ckeditor/ckeditor5-link/src/autolink';
import MentionPlugin from '@ckeditor/ckeditor5-mention/src/mention';
import ShiftEnterPlugin from '@ckeditor/ckeditor5-enter/src/shiftenter';
import AutoformatPlugin from '@/ui/plugins/ckeditor/autoformat/autoformat';
import PasteLinkPlugin from '@/ui/plugins/ckeditor/paste-link/paste-link';
import CustomLinkTaskTargetPlugin from '@/ui/plugins/ckeditor/autoformat/customlinktask';
import CustomLinkMentionPlugin from '@/ui/plugins/ckeditor/mentions/customlinkmention';
import { renderItemListMentionChat } from '@/ui/modules/messaging/chat-panel/chat-form/chat-input/chat-item-mention/RenderComponentChat.js';
import taskStore from '@/store/task';
import chatStore from '@/store/chat';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { getDomainUrl } from '@/ui/hooks/commonHook';
import { isForceActive } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';

const props = defineProps<{
  conversation?: any;
  modelValue?: string;
  mentionUserIds?: any[];
  placeholder?: string;
}>();

const emit = defineEmits([
  'update:modelValue',
  'update:mentionUserIds',
  'onGetLinkFromMsg',
  'onEnter',
  'onKeydown',
  'focus',
]);

const _taskStore = taskStore();
const _chatStore = chatStore();
let ckEditorInstance;

const currentCycle = computed<{
  currentCycle: number;
  letter: string;
  code: number;
}>(() => _taskStore.currentTaskCycle);

const listMentionUser = computed(() =>
  _chatStore.getConversationMentionsList(props.conversation)
);

const editorRef = ref();
const editor = ref(ClassicEditor);

const editorConfig = computed(() => ({
  toolbar: [],
  startupFocus: true,
  plugins: [
    Essentials,
    ParagraphPlugin,
    DeletePlugin,
    AutoformatPlugin,
    LinkPlugin,
    AutoLinkPlugin,
    MentionPlugin,
    ShiftEnterPlugin,
    PasteLinkPlugin,
    CustomLinkTaskTargetPlugin,
    CustomLinkMentionPlugin,
  ],
  placeholder: props.placeholder || translate('CHAT_MESSAGE_ADD'),
  link: _prepareEditorLinkConfig(),
  mention: {
    feeds: [
      {
        marker: '@',
        feed: getFeedItems,
        itemRenderer: customItemRenderer,
      },
    ],
  },
}));

watch(
  () => currentCycle.value,
  () => {
    if (ckEditorInstance?.config) {
      ckEditorInstance?.config?.set('link', _prepareEditorLinkConfig());
    }
  }
);

const getFeedItems = (queryText) => {
  const querySearch = ignoreUnicode(queryText);
  if (!queryText) return listMentionUser.value.slice(0, 12);
  return listMentionUser.value.filter(
    (el) =>
      ignoreUnicode(el.name)?.includes(querySearch) ||
      ignoreUnicode(el.id)?.includes(querySearch)
  );
};

const customItemRenderer = (item) => {
  return renderItemListMentionChat(item);
};

const handleGetLinkFromMsg = (message) => {
  if (!message || !message.includes('<a') || !message.includes('</a>')) return;

  let elementMessage = document.createElement('html');
  elementMessage.innerHTML = message;

  let mentionArr = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        link?.getAttribute('data-mention') &&
        link?.getAttribute('userid') &&
        link?.getAttribute('data-type') == 'mention'
    )
    .map((elLink) => elLink?.getAttribute('userid'));

  let linkArr = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        !link?.getAttribute('href').includes('tictop.app') &&
        !link?.getAttribute('href').includes('app.tictop.eu') &&
        !link?.getAttribute('href').includes('fiine.pro') &&
        !link?.getAttribute('data-mention') &&
        !link?.getAttribute('userid') &&
        !link?.getAttribute('data-type')
    )
    .map((elLink, index) => {
      let domainUrl = new URL(elLink.getAttribute('href'));
      let domain = domainUrl.hostname.replace('www.', '');

      return {
        title: domain,
        link: elLink.getAttribute('href'),
        index: index,
        previewData: {
          isPreview: false,
          description: '',
          title: domain,
          imageUrl: '',
          domain: domain,
        },
      };
    });

  const linkObjects = linkArr.filter(
    (a, i) => linkArr.findIndex((s) => a.link === s.link) === i
  );

  const mentionUserIds = mentionArr.filter(
    (a, i) => mentionArr.findIndex((s) => a === s) === i
  );

  emit('onGetLinkFromMsg', linkObjects);
  emit('update:mentionUserIds', mentionUserIds);
};

const onChange = (messageText) => {
  emit('update:modelValue', messageText);
  handleGetLinkFromMsg(messageText);
};

const onEditorReady = (editor) => {
  ckEditorInstance = editor;
  editorRef.value = markRaw(editor);

  editor.keystrokes.set('Enter', (data, stop) => {
    stop(); // Works like data.preventDefault() + evt.stop()
    emit('update:modelValue', editor.getData());
    emit('onEnter');
    setTimeout(() => editor.setData(''));
  });

  ckEditorInstance.model.change((writer) => {
    writer.setSelection(editor.model.document.getRoot(), 'end');
  });

  _focusInput();

  editor.editing.view.document.on('keydown', (evt) => {
    emit('onKeydown', evt);
  });
};

const focusToEnd = (newValue) => {
  if (!editorRef.value || !ckEditorInstance) return;
  ckEditorInstance.setData(newValue);

  _focusInput();

  const model = ckEditorInstance.editing.model;
  const document = model.document;
  model.change((writer) => {
    writer.setSelection(writer.createPositionAt(document.getRoot(), 'end'));
  });
};

const insertEmoji = (emoji) => {
  if (!editorRef.value) return;
  editorRef.value?.model.change((writer) => {
    writer.insertText(
      emoji,
      editorRef.value?.model.document.selection.getFirstPosition()
    );
  });

  _focusInput();
};

const onBlur = () => {
  isForceActive.value = false;
};

const onFocus = () => {
  emit('focus');
};

const _focusInput = () => {
  setTimeout(() => {
    ckEditorInstance?.editing?.view?.focus &&
      ckEditorInstance.editing.view.focus();
  });
};

const _prepareEditorLinkConfig = () => {
  return {
    defaultTaskLink: `${getDomainUrl()}/tasks/`,
    currentCycle: currentCycle.value,
    defaultProtocol: 'https://',
    addTargetToExternalLinks: true,
    decorators: {
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  };
};

defineExpose({
  focusToEnd,
  insertEmoji,
});
</script>
<template>
  <Ckeditor
    :model-value="modelValue"
    :editor="editor"
    :config="editorConfig"
    @ready="onEditorReady"
    @update:model-value="onChange"
    @blur="onBlur"
    @focus="onFocus"
  />
</template>
<style scoped></style>
