<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <circle cx="8" cy="4" r="2" class=""></circle>
      <circle cx="8" cy="12" r="2" class=""></circle>
      <circle cx="8" cy="20" r="2" class=""></circle>
      <circle cx="16" cy="4" r="2" class=""></circle>
      <circle cx="16" cy="12" r="2" class=""></circle>
      <circle cx="16" cy="20" r="2" class=""></circle>
    </g>
  </svg>
</template>
