import DepartmentRepository from '@/application/repositories/DepartmentRepository';

export default class DepartmentService {
    private static instance: DepartmentService;

    _departmentRepository: DepartmentRepository;

    constructor() {
        this._departmentRepository = DepartmentRepository.getInstance();
    }

    public static getInstance(): DepartmentService {
        if (!DepartmentService.instance) {
            // Get from local storage
            DepartmentService.instance = new DepartmentService();
        }
        return DepartmentService.instance;
    }
    getAll(): Promise<any> {
        return this._departmentRepository.getAll();
    }
    shortInfoDepartment(): Promise<any> {
        return this._departmentRepository.shortInfoDepartment();
    }
    getDepartmentByUser(): Promise<any> {
        return this._departmentRepository.getDepartmentByUser();
    }
    createOrUpdate(data: any): Promise<any> {
        return this._departmentRepository.createOrUpdate(data);
    }
    changeAvatar(data: any): Promise<any> {
        return this._departmentRepository.changeAvatar(data);
    }
    changeNameAndDescription(data: any): Promise<any> {
        return this._departmentRepository.changeNameAndDescription(data);
    }
    addMembers(data: any): Promise<any> {
        return this._departmentRepository.addMembers(data);
    }
    updateMembers(data: any): Promise<any> {
        return this._departmentRepository.updateMembers(data);
    }
    updateRoleUsers(data: any): Promise<any> {
        return this._departmentRepository.updateRoleUsers(data);
    }
    updateUserDepartments(data: any): Promise<any> {
        return this._departmentRepository.updateUserDepartments(data);
    }
    updateSettings(data: any): Promise<any> {
        return this._departmentRepository.updateSettings(data);
    }
    removeMembers(data: any): Promise<any> {
        return this._departmentRepository.removeMembers(data);
    }
    deleteDepartment(data: any): Promise<any> {
        return this._departmentRepository.deleteDepartment(data);
    }
}
