<script setup lang="ts">
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ChatExternalActions from '@/ui/modules/messaging/chat-external/chat-external-actions/ChatExternalActions.vue';
import ModalChatSupporters from '@/ui/modules/messaging/chat-support/modal-chat-supporters/ModalChatSupporters.vue';
import { computed, ref } from 'vue';
import chatStore from '@/store/chat';
import chatSupportingStore from '@/store/chat/chat-supporting';

const props = defineProps<{
  conversationId: string;
  conversation: ChatConversationModel;
}>();

defineEmits([
  'onShowFilesClick',
  'onShowPinnedMessagesClick',
  'onOpenNoteClick',
  'onSupportingChatLeft',
  'onSearchClick',
]);

const isShowSupporters = ref();

const isMeSupporter = computed(() =>
  chatStore().isMeSupporter(props.conversation)
);

const userClickableUrl = computed(() =>
  chatSupportingStore().prepareSupportingUserExternalLink(
    props.conversation?.supportingMemberId
  )
);

const onSupportingUserClick = () => {
  if (!userClickableUrl.value) return;

  window.open(userClickableUrl.value, 'SupportingExternalInfo');
};
</script>

<template>
  <div
    class="
      flex
      items-center
      justify-between
      gap-3
      border-b border-green-500
      bg-green-50
      shadow
      px-3
      py-2
    "
  >
    <!--INFO-->
    <div
      class="flex items-center gap-2 text-lg"
      :class="{ 'cursor-pointer': userClickableUrl }"
      @click="onSupportingUserClick"
    >
      <SynAvatar
        custom-class="w-10 h-10"
        :src="conversation?.avatar"
        :name="conversation?.name"
        :short-name="conversation?.shortName"
      />
      <div>
        <div class="text-lg font-semibold">{{ conversation?.name }}</div>
        <div
          v-if="conversation?.supportingOrgName"
          class="flex items-center gap-2 mt-0.5"
        >
          <SynAvatar
            custom-class="w-5 h-5"
            :src="conversation?.supportingOrgAvatar"
            :name="conversation?.supportingOrgName"
          />
          <div class="text-sm text-gray-600">
            {{ conversation?.supportingOrgName }}
          </div>
        </div>
      </div>
      <div
        class="
          flex-center
          bg-green-200
          rounded-full
          shadow
          overflow-hidden
          p-0.5
        "
      >
        <SynIcon custom-class="w-5 h-5 fill-green-600" name="CustomerSupport" />
      </div>
    </div>

    <!--ACTIONS-->
    <div class="flex items-center gap-1">
      <VigButton
        v-if="isMeSupporter"
        v-vig-tooltip="{
          content: $t('CHAT_SUPPORTERS_ADD'),
          placement: 'bottom',
        }"
        ghost
        color="gray"
        rounded="rounded-full"
        icon="CustomerSupport"
        @click="isShowSupporters = true"
      />
      <div class="flex items-center gap-1">
        <VigButton
          v-vig-tooltip="{
            content: $t('CHAT_SEARCH_IN_CONVER'),
            placement: 'bottom',
          }"
          ghost
          color="gray"
          rounded="rounded-full"
          icon="Search"
          @click="$emit('onSearchClick')"
        />
        <VigButton
          v-vig-tooltip="{
            content: $t('CHAT_MINI_SHOW_FILES'),
            placement: 'bottom',
          }"
          ghost
          color="gray"
          rounded="rounded-full"
          icon="Files"
          @click="$emit('onShowFilesClick')"
        />
        <VigButton
          v-vig-tooltip="{
            content: $t('CHAT_MINI_SHOW_PINNED_MESSAGES'),
            placement: 'bottom',
          }"
          ghost
          color="gray"
          rounded="rounded-full"
          icon="Pin"
          @click="$emit('onShowPinnedMessagesClick')"
        />
        <ChatExternalActions
          :conversation-id="conversationId"
          :conversation="conversation"
          :can-add-supporters="isMeSupporter"
          @on-show-files-click="$emit('onShowFilesClick')"
          @on-show-pinned-messages-click="$emit('onShowPinnedMessagesClick')"
          @on-open-note-click="$emit('onOpenNoteClick')"
          @on-supporting-chat-left="$emit('onSupportingChatLeft')"
          @on-add-supporters-click="isShowSupporters = true"
        >
          <VigButton
            ghost
            color="gray"
            rounded="rounded-full"
            icon="DotsVertical"
          />
        </ChatExternalActions>
      </div>
    </div>
  </div>

  <ModalChatSupporters
    v-if="isShowSupporters"
    :conversation-id="conversationId"
    @on-close="isShowSupporters = false"
  />
</template>
