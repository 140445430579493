<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch, computed } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import TictopWebSocket, {
  USER_REG_EVENT,
  USER_REG_SUB_TYPE,
} from '@/ui/plugins/websocket/ws-instance';
import { useRoute } from 'vue-router';
import { GOOGLE_ANALYTICS_ELEMENT_ID } from '@/application/constants/register.const';

const props = withDefaults(
  defineProps<{
    type: string;
    codeType: string;
    phone?: string;
    email?: string;
    isLoading?: boolean;
    isLoadingResend?: boolean;
    isGetCodeOnly?: boolean;
    hasPassword?: boolean;
    errorCode?: string;
    blockCodeLogin?: boolean;
    blockSendingCode?: boolean;
  }>(),
  {
    codeType: 'phone',
    type: 'login',
  }
);

const emit = defineEmits<{
  (e: 'changeLogin', value: any): void;
  (e: 'onVerify', value: any): void;
  (e: 'resendCode', value: any): void;
  (e: 'changeCode', value: any): void;
  (e: 'codeReady', value: any): void;
}>();
let ws: TictopWebSocket;
const isFullCode = ref(false);
const codeOPT = ref('');
const isLoad = ref(props.isLoading);
const isLoadResend = ref(props.isLoadingResend);
const waitingResendCodeSecond = ref(60);
const message = ref('');
const messageError = ref(props.errorCode);
const route = useRoute();
const isRegisterPage = computed(() => route.name == 'Register');

onMounted(() => {
  if (isRegisterPage.value) {
    ws = new TictopWebSocket(
      USER_REG_EVENT.PAGE_ENTER_PHONE_CODE_INIT,
      USER_REG_SUB_TYPE.REGISTER_WITH_PHONE,
      {
        phone: props.phone,
      }
    );
  } else {
    ws = new TictopWebSocket();
  }
  startCount();
});
onUnmounted(async () => {
  ws?.close();
});
const valueWatch = () => {
  isLoad.value = props.isLoading;
  isLoadResend.value = props.isLoadingResend;
  messageError.value = props.errorCode;
};
watch(
  () => props.isLoading,
  () => {
    valueWatch();
  }
);
watch(
  () => props.isLoadingResend,
  () => {
    valueWatch();
  }
);
watch(
  () => props.errorCode,
  () => {
    valueWatch();
  }
);
watch(
  () => props.blockCodeLogin,
  () => {
    waitingResendCodeSecond.value = 0;
  }
);

const startCount = () => {
  waitingResendCodeSecond.value = 60;
  const countDownTime = setInterval(function () {
    if (
      waitingResendCodeSecond.value < 0 ||
      props.blockCodeLogin ||
      props.blockSendingCode
    ) {
      clearInterval(countDownTime);
    } else {
      message.value = translate('COMMON_LABEL_COUNT_DOWN_TIME', {
        time: waitingResendCodeSecond.value,
      });
    }

    waitingResendCodeSecond.value -= 1;
  }, 1000);
};

const onVerifyCode = () => {
  emit('onVerify', codeOPT.value);
};
const handleOnComplete = (value) => {
  codeOPT.value = value;
};

const onResend = () => {
  handleOnClear();
  emit('resendCode', true);
  startCount();
};
const handleOnChange = (value) => {
  isClear.value = false;
  codeOPT.value = value;
  if (codeOPT.value.length == 6) {
    isFullCode.value = true;
    emit('changeCode', codeOPT.value);
    emit('codeReady', true);
  } else {
    isFullCode.value = false;
    messageError.value = '';
    emit('codeReady', false);
  }
};

const isClear = ref(false);
const handleOnClear = () => {
  isClear.value = true;
  isFullCode.value = false;
  codeOPT.value = '';
  messageError.value = '';
};
</script>

<template>
  <div class="flex-center flex-wrap w-full space-y-2 -pt-8">
    <div class="w-full">
      <syn-animation
        :name="codeType?.toLowerCase() === 'phone' ? 'phoneCode' : 'verifyMail'"
        stype="width: 150px;"
      />
    </div>
    <label
      v-if="codeType?.toLowerCase() === 'phone'"
      class="w-full text-center text-sm text-gray-900"
      v-html="
        $t('LOGIN_LABEL_SUB_HEADER_VERIFY_CODE', {
          phone: `<span class='font-semibold underline text-current-500'>${phone}</span></br>`,
        })
      "
    ></label>
    <label
      v-else
      class="w-full text-center text-sm text-gray-900"
      v-html="
        $t('LOGIN_LABEL_SUB_HEADER_VERIFY_CODE_EMAIL', {
          email: `<span class='font-semibold underline text-current-500'>${email}</span></br>`,
        })
      "
    ></label>
  </div>
  <div class="flex-center mt-4 space-x-3 w-full">
    <SynFormInput
      :error-message="!blockCodeLogin && !blockSendingCode && messageError"
      class="w-max"
    >
      <AtomOtpInput
        ref="otpInput"
        class="pb-2 mb-2"
        input-classes="otp-input"
        :num-inputs="6"
        should-auto-focus
        :is-input-num="true"
        input-type="number"
        :is-clear="isClear"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
      />
    </SynFormInput>
  </div>
  <div v-if="type === 'login' && hasPassword" class="py-2 flex justify-center">
    <label
      class="cursor-pointer text-current hover:text-current-700 text-sm"
      @click="$emit('changeLogin', codeType)"
      >{{ $t('COMMON_LABEL_LOGIN_WITH_PASS') }}</label
    >
  </div>
  <div class="text-center mt-6 w-full">
    <SynButton
      v-if="!isGetCodeOnly"
      :id="
        type == 'signup'
          ? GOOGLE_ANALYTICS_ELEMENT_ID.REGISTER
              .register_by_phone_submit_verify_code_btn
          : ''
      "
      class="w-full m-auto"
      :label="$t('COMMON_LABEL_CONFIRM')"
      :is-loading="isLoad"
      :disabled="blockCodeLogin || !isFullCode"
      @click="onVerifyCode"
    />
    <div class="mt-3 text-sm flex-center space-x-3">
      <div v-if="waitingResendCodeSecond > 0">
        <label
          id="countdown"
          class="text-sm mt-2 text-current-500 w-full text-center"
        >
          {{ message }}
        </label>
      </div>
      <div v-else>
        <label v-if="blockCodeLogin" class="text-red-900">
          {{ $t('LOGIN_BY_CODE_BLOCKED') }}
        </label>
        <label v-else-if="blockSendingCode" class="text-red-900">
          {{ $t('SEND_PHONE_CODE_BLOCKED') }}
        </label>
        <label v-else class="mt-2 float-left space-x-1">
          <span class="text-gray-800">
            {{ $t('GET_CODE_LABEL_QUESTION_RESEND_CODE') }}
          </span>
          <span
            :class="isLoadResend ? 'cursor-not-allowed' : 'cursor-pointer'"
            class="font-medium text-current hover:text-current-700"
            @click="onResend"
          >
            {{ $t('LOGIN_LABEL_RESEND_CODE') }}
          </span>
        </label>
      </div>
      <!-- <label
        v-else
        @click="onResend"
        :class="isLoadResend ? 'cursor-not-allowed' : 'cursor-pointer'"
        class="mt-2 text-current hover:text-current-700"
        >{{ $t('LOGIN_LABEL_RESEND_CODE') }}
      </label> -->
      <SynIcon
        v-if="isLoadResend && waitingResendCodeSecond > 0"
        name="spinner"
        class="animate-spin fill-current w-4 h-4 mt-2"
      />
    </div>
  </div>
</template>
