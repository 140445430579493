import BaseUnauthorizeRequest from '@/base/api/base-unauthorize-request';
import { LoginInput } from '@/domain/entities/PayloadClass/AuthPayloadClass';
import { AxiosInstance } from 'axios';
import { IRegisterLicensePayload } from '../types/payment.types';

export default class UnauthRepository {
    private static instance: UnauthRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseUnauthorizeRequest;
    }
    public static getInstance(): UnauthRepository {
        if (!UnauthRepository.instance) {
            // Get from local storage
            UnauthRepository.instance = new UnauthRepository();
        }

        return UnauthRepository.instance;
    }

    checkPhone(phone) {
        return this.request({
            url: `Catalog/CheckPhone?phone=${encodeURIComponent(phone)}`,
            method: 'get',
        });
    }
    checkUsername(username) {
        return this.request({
            url: `Catalog/Username?username=${username}`,
            method: 'get',
        });
    }
    checkEmail(email) {
        return this.request({
            url: `Catalog/CheckEmail?email=${email}`,
            method: 'get',
        });
    }

    checkUserHasPasswordByEmail(data: string): Promise<any> {
        return this.request({
            url: `Catalog/CheckUserHasPasswordByEmail?email=${encodeURIComponent(
                data
            )}`,
            method: 'get',
            data,
        });
    }
    checkUserHasPasswordByPhone(data: string): Promise<any> {
        return this.request({
            url: `Catalog/CheckUserHasPasswordByPhone?phone=${encodeURIComponent(
                data
            )}`,
            method: 'get',
            data,
        });
    }
    sendVerificationCodeByPhoneNumber(data) {
        return this.request({
            url: 'Verification/SendVerificationCodeByPhoneNumber',
            method: 'post',
            data,
        });
    }
    reSendVerificationCodeByPhoneNumber(data) {
        return this.request({
            url: 'Verification/ReSendVerificationCodeByPhoneNumber',
            method: 'post',
            data,
        });
    }
    sendVerificationCodeByEmail(data: any): Promise<any> {
        return this.request({
            url: 'Verification/SendVerificationCodeByEmail',
            method: 'post',
            data,
        });
    }
    reSendVerificationCodeByEmail(data: any): Promise<any> {
        return this.request({
            url: 'Verification/ReSendVerificationCodeByEmail',
            method: 'post',
            data,
        });
    }
    verifyUserPhoneNumber(data) {
        return this.request({
            url: 'Verification/VerifyUserPhoneNumber',
            method: 'post',
            data,
        });
    }

    getInvitedUser(params) {
        return this.request({
            url: `Catalog/GetInvitedUser`,
            method: 'get',
            params,
        });
    }

    saveUserProfile(
        data: any,
        refId: string,
        voucherCode: string,
        advertisementInfo: any
    ): Promise<any> {
        return this.request({
            url: 'Catalog/SaveUserProfile',
            params: { d_id: refId, v_code: voucherCode, ...advertisementInfo },
            method: 'post',
            data,
        });
    }
    registerWithUsername(
        data: any,
        refId: string,
        voucherCode: string,
        advertisementInfo: any
    ): Promise<any> {
        return this.request({
            url: 'Catalog/RegisterUsername',
            params: { d_id: refId, v_code: voucherCode, ...advertisementInfo },
            method: 'post',
            data,
        });
    }
    // Login
    verifyToken(data: { jwtToken: string }): Promise<any> {
        return this.request({
            url: 'VerifyToken',
            method: 'post',
            data,
        });
    }
    loginWithPassword(payload: LoginInput): Promise<any> {
        return this.request({
            url: `authenticate`,
            method: 'post',
            data: payload,
        });
    }
    loginByPhoneCode(data: any): Promise<any> {
        return this.request({
            url: 'Verification/LoginByPhoneCode',
            method: 'post',
            data,
        });
    }
    loginByEmailCode(data: any): Promise<any> {
        return this.request({
            url: 'Verification/LoginByEmailCode',
            method: 'post',
            data,
        });
    }
    getIpInfo(): Promise<any> {
        return this.request({
            url: 'Setting/GetIpInfo',
            method: 'get',
        });
    }
    checkVoucherCode(voucherCode: string): Promise<any> {
        return this.request({
            url: 'Voucher/Check',
            params: {
                voucher: voucherCode,
            },
            method: 'get',
        });
    }
    getVoucherCodeDetail(
        voucherCode: string,
        needToUpdate: boolean
    ): Promise<any> {
        return this.request({
            url: `Voucher/Detail/${voucherCode}`,
            method: 'get',
            params: {
                type: needToUpdate ? 1 : 0,
            },
        });
    }

    getAllLicensePackage(): any {
        return this.request({
            url: `LicenseRegistration/GetAllLicensePackage`,
            method: 'get',
        });
    }
    registerLicense(data: IRegisterLicensePayload): any {
        return this.request({
            url: `LicenseRegistration/Register`,
            method: 'post',
            data,
        });
    }

    getTaskShared(sharedId: string) {
        return this.request({
            url: `Task/GetTaskShared/${sharedId}`,
            method: 'get',
        });
    }
}
