<script setup lang="ts">
// *** IMPORTS ***
import { computed, watch } from 'vue';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';

// *** PROPS, EMITS ***
const props = defineProps<{
  activeTab: any;
  userConversations: { [converId: string]: ChatConversationModel };
  userUnseenConversations: { [converId: string]: boolean };
  userConversationIdsOriginal: string[];
}>();

const emit = defineEmits(['update:activeTab']);

// *** PRIVATE VARIABLES ***
const TABS = [
  { id: 'CHAT-ALL', text: 'CHAT_ALL' },
  { id: 'CHAT-INTERNAL', text: 'CHAT_MESSAGES' },
  { id: 'CHAT-ABOUT-TASK', text: 'CHAT_ABOUT_TASK' },
  { id: 'CHAT-EXTERNAL', text: 'CHAT_EXTERNAL' },
  { id: 'CHAT-SUPPORTER', text: 'CHAT_SUPPORT' },
];

// *** COMPOSABLES ***

// *** REFS ***

// *** COMPUTED ***
const isExternalChat = (conversId) =>
  props.userConversations
    ? props.userConversations[conversId]?.externalChat
    : false;
const isSupporterChat = (conversId) =>
  props.userConversations &&
  props.userConversations[conversId]?.supportingChat &&
  props.userConversations[conversId]?.supportingOrgId;
const isChatAboutTask = (conversId) =>
  props.userConversations &&
  props.userConversations[conversId]?.chatAboutTaskId;
const isChatInternal = (conversId) =>
  !isExternalChat(conversId) &&
  !isSupporterChat(conversId) &&
  !isChatAboutTask(conversId);

const countByTab = computed<any>(() => ({
  'CHAT-ALL': props.userConversationIdsOriginal?.length,
  'CHAT-INTERNAL':
    props.userConversationIdsOriginal?.filter(isChatInternal).length,
  'CHAT-ABOUT-TASK':
    props.userConversationIdsOriginal?.filter(isChatAboutTask).length,
  'CHAT-EXTERNAL':
    props.userConversationIdsOriginal?.filter(isExternalChat).length,
  'CHAT-SUPPORTER':
    props.userConversationIdsOriginal?.filter(isSupporterChat).length,
}));
const unSeenByTab = computed<any>(() => {
  const unseenConverIds = props.userConversationIdsOriginal?.filter(
    (conversId) =>
      props.userUnseenConversations && props.userUnseenConversations[conversId]
  );

  return {
    'CHAT-ALL': unseenConverIds?.length,
    'CHAT-INTERNAL': unseenConverIds?.filter(isChatInternal).length,
    'CHAT-ABOUT-TASK': unseenConverIds?.filter(isChatAboutTask).length,
    'CHAT-EXTERNAL': unseenConverIds?.filter(isExternalChat).length,
    'CHAT-SUPPORTER': unseenConverIds?.filter(isSupporterChat).length,
  };
});

const hasExternalChat = computed(() => countByTab.value['CHAT-EXTERNAL']);
const hasSupporterChat = computed(() => countByTab.value['CHAT-SUPPORTER']);
const hasChatAboutTask = computed(() => countByTab.value['CHAT-ABOUT-TASK']);

// *** WATCHES ***
watch(
  () => hasExternalChat.value,
  () => {
    if (!hasExternalChat.value) _selectTab('CHAT-ALL');
  }
);

watch(
  () => hasSupporterChat.value,
  () => {
    if (!hasSupporterChat.value) _selectTab('CHAT-ALL');
  }
);

watch(
  () => hasChatAboutTask.value,
  () => {
    if (!hasChatAboutTask.value) _selectTab('CHAT-ALL');
  }
);

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onTabClick = (tabId) => {
  _selectTab(tabId);
};

// *** PRIVATE FUNCTIONS ***
const _selectTab = (tabId) => {
  emit('update:activeTab', tabId);
};

// *** EXPOSES ***
</script>

<template>
  <div
    v-if="hasExternalChat || hasSupporterChat || hasChatAboutTask"
    class="flex flex-wrap gap-x-2 gap-y-1 px-3 mb-2"
  >
    <template v-for="tab in TABS" :key="tab?.id">
      <div
        v-if="countByTab[tab?.id]"
        role="button"
        class="
          pl-2
          py-1.5
          pr-1
          text-xs
          rounded-full
          flex-center
          gap-1.5
          relative
        "
        :class="
          tab?.id === activeTab
            ? 'text-current-600 bg-current-50 font-medium'
            : 'text-gray-600 bg-gray-100 hover:bg-gray-200'
        "
        @click="onTabClick(tab?.id)"
      >
        {{ $t(tab?.text) }}
        <span
          v-if="countByTab[tab?.id]"
          class="text-[0.7rem] text-white leading-4 px-1 rounded-full"
          :class="tab?.id === activeTab ? 'bg-current-500' : 'bg-gray-400'"
        >
          {{ countByTab[tab?.id] }}</span
        >
        <span
          v-if="unSeenByTab[tab?.id]"
          class="
            absolute
            -right-1
            -top-0.5
            w-2.5
            h-2.5
            rounded-full
            bg-red-500
            shadow
          "
        ></span>
      </div>
    </template>
  </div>
</template>

<style scoped></style>
