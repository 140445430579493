<template>
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 234 234"
    style="enable-background: new 0 0 234 234"
    xml:space="preserve"
  >
    <path
      d="M234,174.5h-31v-143H59v-31H29v31H0v30h29v143h144v29h30v-29h31V174.5z M151.121,61.5L59,153.287V61.5H151.121z   M80.546,174.5L173,81.713V174.5H80.546z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
