<template>
  <div
    ref="filesListRef"
    v-scroll-infinite="onLoadMore"
    class="h-full overflow-auto small-scrollbar px-3 pb-10"
  >
    <div
      v-if="!isViewByList"
      class="grid auto-rows-max gap-3"
      style="grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr))"
    >
      <FileCard
        v-for="file in files"
        :key="file"
        :file="file"
        :class="[filesNew?.includes(file?.id + '') ? 'colorNewFile' : '']"
        :is-selected="filesSelected[file?.id]"
        :is-selection-mode="isSelectionMode"
        :is-renaming="fileRenaming?.id === file?.id"
        @on-file-click="onFileClick(file)"
        @on-checkbox-click="onCheckboxClick(file)"
        @on-view-info-click="$emit('onFileInfoClick', file)"
        @on-rename-click="onFileRenameClick(file)"
        @on-rename-confirm="onFileRenameConfirm(file, $event)"
        @on-rename-cancel="onFileRenameCancel"
        @on-move-to-click="$emit('onMoveToClick', file)"
        @on-move-to-trash-click="$emit('onMoveToTrashClick', file)"
        @on-restore-click="$emit('onRestoreClick', file)"
        @on-delete-permanently-click="$emit('onDeletePermanentlyClick', file)"
        @on-copy-link-click="onFileCopyLinkClick(file)"
        @on-notification-setting-click="
          $emit('onNotificationSettingClick', file)
        "
        @on-download-click="onFileDownloadClick(file)"
        @on-save-to-resource-click="onSaveToResourceClick(file)"
      />
    </div>

    <table v-if="isViewByList && files?.length > 0" class="w-full table-fixed">
      <thead>
        <SynTr>
          <SynTh class="w-16"></SynTh>
          <SynTh
            is-sort
            :label="$t('GED_LINK_NAME')"
            :order-by="filter?.sortBy === 'name' ? filter?.orderBy : null"
            @on-sort="onSortFiles('name', filter?.orderBy)"
          ></SynTh>
          <SynTh
            v-if="filter.category === 'TASK'"
            is-sort
            :order-by="filter?.sortBy === 'task_code' ? filter?.orderBy : null"
            @on-sort="onSortFiles('task_code', filter?.orderBy)"
            >{{ $t('TASK_EXPORT_COLUMNS_TASK_NAME') }}
          </SynTh>
          <SynTh
            v-if="filter.category !== 'STORAGE'"
            is-sort
            :label="$t('GED_CREATOR_NAME')"
            class="w-44"
          ></SynTh>
          <SynTh
            v-if="!filter?.onlyDeletedFiles"
            is-sort
            :label="$t('TASK_EXPORT_COLUMNS_CREATION_DATE')"
            class="w-44"
            :order-by="
              filter?.sortBy === 'creation_time' ? filter?.orderBy : null
            "
            @on-sort="onSortFiles('creation_time', filter?.orderBy)"
          ></SynTh>
          <SynTh
            v-if="filter?.onlyDeletedFiles"
            is-sort
            :label="$t('COMMON_LABEL_DELETED_DATE')"
            class="w-44"
            :order-by="
              filter?.sortBy === 'deleted_date' ? filter?.orderBy : null
            "
            @on-sort="onSortFiles('deleted_date', filter?.orderBy)"
          ></SynTh>
          <SynTh
            is-sort
            is-right
            :order-by="filter?.sortBy === 'size' ? filter?.orderBy : null"
            :label="$t('COMMON_LABEL_SIZE')"
            class="w-28"
            @on-sort="onSortFiles('size', filter?.orderBy)"
          ></SynTh>
          <SynTh class="w-12"></SynTh>
        </SynTr>
      </thead>
      <tbody>
        <FileRow
          v-for="file in files"
          :key="file"
          :file="file"
          :class="[filesNew?.includes(file?.id + '') ? 'colorNewFile' : '']"
          :is-selected="filesSelected[file?.id]"
          :is-selection-mode="isSelectionMode"
          :is-renaming="fileRenaming?.id === file?.id"
          :filter="filter"
          @on-file-click="onFileClick(file)"
          @on-checkbox-click="onCheckboxClick(file)"
          @on-view-info-click="$emit('onFileInfoClick', file)"
          @on-rename-click="onFileRenameClick(file)"
          @on-rename-confirm="onFileRenameConfirm(file, $event)"
          @on-rename-cancel="onFileRenameCancel"
          @on-move-to-click="$emit('onMoveToClick', file)"
          @on-move-to-trash-click="$emit('onMoveToTrashClick', file)"
          @on-restore-click="$emit('onRestoreClick', file)"
          @on-delete-permanently-click="$emit('onDeletePermanentlyClick', file)"
          @on-copy-link-click="onFileCopyLinkClick(file)"
          @on-notification-setting-click="
            $emit('onNotificationSettingClick', file)
          "
          @on-download-click="onFileDownloadClick(file)"
          @on-task-click="taskShowDetail = $event"
          @on-save-to-resource-click="onSaveToResourceClick(file)"
        />
      </tbody>
    </table>

    <div v-if="isLoading" class="px-3 py-2 flex-center pt-10">
      <SynIcon name="Spinner" class="w-5 h-5 fill-gray-500 animate-spin" />
    </div>

    <div
      v-if="!isLoading && !files?.length && !filter?.keyword"
      class="px-3 pb-2 pt-8 flex-center flex-col"
    >
      <syn-animation name="emptyBox" stype="width: 100px; height: 100px" />
      <span class="text-gray-600 pt-3">{{
        $t('GED_NO_FILE_MSG') || 'There is no file'
      }}</span>
    </div>

    <div
      v-if="!isLoading && !files?.length && filter?.keyword"
      class="px-3 pb-2 pt-8 flex-center flex-col"
    >
      <syn-animation name="searchIcon" stype="width: 120px; height: 120px" />
      <span class="text-gray-600 pt-3">{{
        $t('GED_NOT_FOUND_FILE_MSG') || 'No files found'
      }}</span>
    </div>

    <div v-if="!isLoading && canShowMore" class="px-3 py-2 mt-2 flex-center">
      <button
        class="px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300"
        @click="onShowMoreClick"
      >
        {{ $t('GED_SHOW_MORE') || 'Show more' }}
      </button>
    </div>
  </div>

  <ModalMultipleFileViewerInfo
    v-if="fileShowViewer && indexCurrentFile !== null"
    :start-index="indexCurrentFile"
    :files="filesHasView"
    :is-loading="isLoadingFile"
    :editable="false"
    @on-next="onViewNextFile"
    @on-close="fileShowViewer = null"
    @on-view-last-file="onViewNextFile"
    @on-info-click="
      $emit('onFileInfoClick', fileShowViewer);
      fileShowViewer = null;
    "
  >
  </ModalMultipleFileViewerInfo>

  <TaskDetailModal
    v-if="taskShowDetail"
    :task-code="taskShowDetail"
    @close="taskShowDetail = null"
  />

  <AlertModal
    v-if="alertMessage"
    :title-text="alertMessage.title"
    :sub-title-text="alertMessage.content"
    name-icon="warning"
    container-class="w-5/6 md:w-4/6 lg:w-3/6 xl:w-2/6"
    @cancel="alertMessage = null"
  />

  <ModalSaveToResource
    v-if="saveToResource"
    :file="saveSelectedFile"
    @on-close="onFinishSaveToResourceEvent()"
  />
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import FileCard from './file-card/FileCard.vue';
import FileRow from './file-row/FileRow.vue';
import fileStorageStore from '@/store/fileStorage';
import TaskDetailModal from '@/ui/modules/task/detail/TaskDetailModal.vue';
import $constants from '@/ui/plugins/constants';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import axios from 'axios';
import { saveAs } from 'file-saver';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ModalMultipleFileViewerInfo from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewerInfo.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import ModalSaveToResource from '@/ui/modules/ged/folder/modal-save-to-resource/ModalSaveToResource.vue';
import { openNotification } from '@/ui/hooks/commonHook';

export default defineComponent({
  components: {
    SynTh,
    // ModalFileViewerWithInfo,
    FileRow,
    FileCard,
    TaskDetailModal,
    AlertModal,
    ModalMultipleFileViewerInfo,
    ModalSaveToResource,
  },
  props: {
    filter: {
      type: Object,
      default: null,
    },
    isViewByList: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onFileInfoClick',
    'onFilesSelected',
    'onSelectionMode',
    'onFolderClick',
    'onMoveToClick',
    'onNotificationSettingClick',
    'onMoveToTrashClick',
    'onDeletePermanentlyClick',
    'onRestoreClick',
    'onSortChange',
    'onLoadMore',
  ],
  setup(props, { emit }) {
    const _fileStorageStore = fileStorageStore();

    const files = computed(() => _fileStorageStore.files);
    const filesNew = computed(() => _fileStorageStore.listFileAddNew);

    const fileRenaming = computed(() =>
      files.value?.find(
        (file) => file?.id === _fileStorageStore.renamingFile?.id
      )
    );
    const isLoading = computed(() => _fileStorageStore.isFetchingFiles);
    const canShowMore = computed(
      () => _fileStorageStore.files?.length < _fileStorageStore.totalFiles
    );
    const filesHasView = computed(() =>
      _fileStorageStore.files.filter(
        (file) =>
          file?.contentType !== $constants.FILE.TYPE.FOLDER &&
          file?.contentType !== $constants.FILE.TYPE.LINK
      )
    );

    const fileCardList = ref();

    const filesListRef = ref();
    const filesSelected = ref({});
    const fileShowViewer = ref();
    const isSelectionMode = ref(false);
    const taskShowDetail = ref();
    const alertMessage = ref();
    const indexCurrentFile = ref();
    const saveToResource = ref(false);
    const saveSelectedFile = ref({});

    const onFileClick = (file) => {
      if (isSelectionMode.value) {
        filesSelected.value[file?.id] = !filesSelected.value[file?.id];
        _emitSelectedFiles();
      } else if (file?.contentType === $constants.FILE.TYPE.FOLDER) {
        emit('onFolderClick', file);
      } else if (file?.contentType === $constants.FILE.TYPE.LINK) {
        window.open(file?.link, '_blank').focus();
      } else {
        fileShowViewer.value = file;
        indexCurrentFile.value = filesHasView.value.findIndex(
          (f) => f?.id === file?.id
        );
        if (indexCurrentFile.value === -1) indexCurrentFile.value = null;
      }
    };

    const onCheckboxClick = (file) => {
      if (!isSelectionMode.value) {
        isSelectionMode.value = true;
        emit('onSelectionMode', true);
      }

      filesSelected.value[file?.id] = !filesSelected.value[file?.id];
      _emitSelectedFiles();
    };

    const onShowMoreClick = () => {
      emit('onLoadMore');
    };

    const onLoadMore = () => {
      if (canShowMore.value && !isLoading.value) {
        emit('onLoadMore');
      }
    };

    const onFileRenameClick = (file) => {
      _fileStorageStore.setRenamingFile(file);
    };

    const onFileRenameConfirm = async (file, fileName) => {
      await _fileStorageStore.renameFile({
        id: file?.id,
        contentType: file?.contentType,
        name: fileName,
      });

      _fileStorageStore.setRenamingFile(null);
    };

    const onFileRenameCancel = () => {
      _fileStorageStore.setRenamingFile(null);
    };

    const onFileCopyLinkClick = (file) => {
      if (file?.contentType === $constants.FILE.TYPE.FOLDER) {
        const host = window.location.origin;
        copyToClipBroard(`${host}/resources?folder=${file?.id}`);
        openNotification({
          title: translate('COMMON_LABEL_SUCCESS'),
          body: translate('GED_LABEL_COPY_URL_SUCCESS'),
          duration: 3000,
        });
      } else {
        copyToClipBroard(file?.link || file?.pathUrl);
        alertMessage.value = {
          title: translate('GED_FILE_COPY_MSG_TITLE'),
          content: translate('GED_FILE_COPY_MSG_CONTENT'),
        };
      }
    };

    const onFileDownloadClick = (file) => {
      return axios.get(file?.pathUrl, { responseType: 'blob' }).then((res) => {
        saveAs(res.data, file?.name);
      });
    };

    const onSaveToResourceClick = (file) => {
      saveToResource.value = true;
      saveSelectedFile.value = file;
    };

    const onFinishSaveToResourceEvent = () => {
      saveToResource.value = false;
      saveSelectedFile.value = {};
    };

    const onSortFiles = (sortName, order) => {
      if (props.filter?.sortBy !== sortName) {
        _processOrderBy(sortName, null);
      } else {
        _processOrderBy(sortName, order);
      }
    };

    const _emitSelectedFiles = () => {
      const selectedFiles = files.value?.filter(
        (file) => filesSelected.value[file?.id]
      );

      emit('onFilesSelected', selectedFiles);

      if (!selectedFiles?.length) {
        isSelectionMode.value = false;
        emit('onSelectionMode', false);
      }
    };

    const _processOrderBy = (sortName, order) => {
      let sortBy, orderBy;
      switch (order) {
        case null:
          sortBy = sortName;
          orderBy = 'asc';
          break;
        case 'asc':
          sortBy = sortName;
          orderBy = 'desc';
          break;
        case 'desc':
          sortBy = null;
          orderBy = null;
          break;
        default:
          break;
      }

      emit('onSortChange', { sortBy, orderBy });
    };

    return {
      files,
      fileCardList,
      fileRenaming,
      isLoading,
      canShowMore,
      filesListRef,
      filesSelected,
      fileShowViewer,
      isSelectionMode,
      taskShowDetail,
      alertMessage,
      indexCurrentFile,
      filesHasView,
      filesNew,
      saveToResource,
      saveSelectedFile,
      onLoadMore,
      onFileClick,
      onCheckboxClick,
      onShowMoreClick,
      onFileRenameClick,
      onFileRenameConfirm,
      onFileRenameCancel,
      onFileCopyLinkClick,
      onFileDownloadClick,
      onSaveToResourceClick,
      onFinishSaveToResourceEvent,
      onSortFiles,
    };
  },
  methods: {
    setSelectionMode(value) {
      this.isSelectionMode = value;
      this.$emit('onSelectionMode', value);

      if (!this.isSelectionMode) {
        this.filesSelected = {};
        this.$emit('onFilesSelected', []);
      }
    },
    setSelectionAll(isSelectAll) {
      this.filesSelected = (this.files || []).reduce((obj, file) => {
        obj[file?.id] = isSelectAll;
        return obj;
      }, {});

      this.$emit('onFilesSelected', this.files);
    },
    scrollTop() {
      this.filesListRef && this.filesListRef.scrollTo(0, 0);
    },
    clickFile(file) {
      this.onFileClick(file);
    },
  },
});
</script>

<style scoped>
@keyframes change {
  0% {
    background-color: white;
    border: 1px solid white;
  }
  35% {
    background-color: #e0f1f2;
    border: 1px solid #c1edf0;
  }
  70% {
    background-color: #f8ffff;
    border: 1px solid #adebf0;
  }
  100% {
    background-color: white;
    border: 1px solid white;
  }
  /* from {color: black;}
    to {color: #00ff7b;} */
}

.colorNewFile {
  animation-name: change;
  animation-duration: 2.5s;
}
</style>
