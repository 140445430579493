<script setup lang="ts">
// *** IMPORTS ***
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import { computed, ref } from 'vue';
import settingStore from '@/store/setting';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import getGroupInfo from '@/ui/helpers/group-helper';
import {
  genDirectConversationId,
  genGroupConversationId,
} from '@/ui/plugins/firebases/firestore-database/constants';
import getUserInfo from '@/ui/helpers/user-helper';
import { isForceActive } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import chatStore from '@/store/chat';
import groupStore from '@/store/group';
import myProfileStore from '@/store/auth/my-profile';
import ModalNewChat from '@/ui/modules/messaging/modal-new-chat/ModalNewChat.vue';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';
import permissionStore from '@/store/permission';
import { EOrganizationModel } from '@/application/types/organization/organization.type';
import ModalDiscussChatTask from '@/ui/modules/messaging/modal-discuss-chat/ModalDiscussChatTask.vue';
import systemConfig from '@/application/constants/system-config.const';
import GroupChatAboutTask from '@/ui/modules/task/detail/task-chat-about/GroupChatAboutTask.vue';
import ModalNewGroupChat from '@/ui/modules/messaging/modal-new-group-chat/ModalNewGroupChat.vue';

// *** PROPS, EMITS ***
const props = defineProps<{
  currentTask: any;
  isPlaceModal?: boolean;
}>();

const emit = defineEmits(['onStartChat']);

// *** PRIVATE VARIABLES ***
const _permissionStore = permissionStore();
const _settingStore = settingStore();
const _chatStore = chatStore();
const _groupStore = groupStore();

// *** COMPOSABLES ***
const { goToConversation } = useChatCommon();

// *** REFS ***
const isNewChat = ref<boolean>();
const isOpenDropdownChat = ref<boolean>();
const isCreateGroupChat = ref<boolean>(false);
const discussAboutTaskDropdownRef = ref<any>();
const objectDiscussTaskFast = ref({
  contactId: null,
  type: '',
  conversationId: '',
  task: props.currentTask,
} as any);
const groupChatHasNewMessage = ref<boolean>();

// *** COMPUTED ***
const myProfile = computed(() => myProfileStore().myProfile);
const isPersonalModel = computed<boolean>(
  () => _permissionStore.organizationModel === EOrganizationModel.PERSONAL
);
const chatEnabled = computed(() => _settingStore.chatEnabled);
const isShowGroupChatAboutTask = computed(
  () =>
    _permissionStore.allSystemConfigs &&
    _permissionStore.allSystemConfigs[
      systemConfig.ADVANCED_CHAT_GROUP_CHAT_ABOUT_TASK_ALLOWED
    ] === '1'
);
const isShowCreatorChat = computed(
  () =>
    props.currentTask?.creatorId !== props.currentTask?.assigneeId &&
    props.currentTask?.creatorId !== getCurrentUserId()
);
const isShowGroupChat = computed(
  () =>
    props.currentTask?.groupId &&
    getGroupInfo(props.currentTask?.groupId)?.isMember
);
const isShowAssigneeUser = computed(
  () =>
    props.currentTask?.assigneeId &&
    props.currentTask?.assigneeId !== getCurrentUserId()
);
// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onOpenChatAboutTaskModal = () => {
  if (isPersonalModel.value) {
    const conversation = {
      id: genDirectConversationId(myProfile.value.id, myProfile.value.id) || '',
      name: myProfile.value?.name,
      avatar: myProfile.value?.avatarUrl || myProfile.value?.avatar,
      isGroup: false,
      contactId: myProfile.value.id,
    };

    _onOpenConversation(conversation);
    emit('onStartChat');
    return;
  }

  isNewChat.value = true;
};

const onChatAboutTaskByUserId = (userId) => {
  isOpenDropdownChat.value = false;
  if (!userId) return;
  const conversationId = _chatStore.checkExistedConversation(userId);

  const userInfo = getUserInfo(userId);
  if (!userInfo?.name) return;
  if (props.isPlaceModal) {
    _chatStore.setIdConversationActive(conversationId);
    objectDiscussTaskFast.value.contactId = userId;
    objectDiscussTaskFast.value.type = 'USER';
    objectDiscussTaskFast.value.conversationId = conversationId;
    objectDiscussTaskFast.value.task = props.currentTask;
    return;
  }

  _onOpenConversation({
    id: conversationId,
    name: userInfo.name,
    avatar: userInfo.avatarUrl || userInfo.avatar,
    contactId: userId,
  });

  onChatAboutTask();
};

const onChatAboutTaskWithGroup = async (groupId) => {
  isOpenDropdownChat.value = false;
  if (!groupId) return;

  const conversationId = genGroupConversationId(groupId);
  const groupInfo = await _groupStore.getDetailGroupById(groupId);

  if (props.isPlaceModal) {
    await _chatStore.setIdConversationActive(conversationId);
    objectDiscussTaskFast.value.contactId = groupId;
    objectDiscussTaskFast.value.type = 'GROUP';
    objectDiscussTaskFast.value.conversationId = conversationId;
    objectDiscussTaskFast.value.task = props.currentTask;
    return;
  }

  _onOpenConversation({
    id: conversationId,
    name: groupInfo.name,
    avatar: groupInfo.avatarUrl || groupInfo.avatar,
    contactId: groupId,
  });

  onChatAboutTask();
};

const onChatAboutTask = () => {
  isNewChat.value = false;
  emit('onStartChat');
};

const onOpenSendFast = (obj) => {
  if (!props.isPlaceModal) return;
  _chatStore.setIdConversationActive(obj?.conversationId);
  objectDiscussTaskFast.value.contactId = obj?.contactId;
  objectDiscussTaskFast.value.type = obj?.type;
  objectDiscussTaskFast.value.conversationId = obj?.conversationId;
  objectDiscussTaskFast.value.task = props.currentTask;
};

// *** PRIVATE FUNCTIONS ***
const _onOpenConversation = (conversation) => {
  isOpenDropdownChat.value = false;
  isForceActive.value = true;

  goToConversation(conversation);

  if (props.currentTask?.id) {
    setTimeout(() => _chatStore.setChatAboutTask(props.currentTask));
  }
};

// *** EXPOSES ***
defineExpose({
  onOpenChatAboutTaskModal,
});
</script>

<template>
  <VigDropdown
    v-if="chatEnabled"
    ref="discussAboutTaskDropdownRef"
    :arrow="false"
    placement="bottom-end"
    @on-dropdown-open="
      !isShowGroupChatAboutTask &&
      !isShowCreatorChat &&
      !isShowGroupChat &&
      !isShowAssigneeUser
        ? onOpenChatAboutTaskModal()
        : (isOpenDropdownChat = true)
    "
    @on-dropdown-close="isOpenDropdownChat = false"
  >
    <template #dropdown-toggle>
      <div
        v-permission-function="{
          functionCode: ALL_FUNCTIONS.TASK.DISCUSS,
          onContinue: () => {
            if (
              discussAboutTaskDropdownRef &&
              typeof discussAboutTaskDropdownRef?.onForceOpen == 'function'
            )
              discussAboutTaskDropdownRef.onForceOpen();
          },
        }"
        v-permission-model
        class="
          flex-center
          rounded-md
          w-8
          h-8
          hover:bg-gray-100
          cursor-pointer
          relative
        "
        :class="isOpenDropdownChat ? 'bg-gray-100' : ''"
      >
        <SynIcon name="Message" class="fill-gray-500" />
        <div
          v-if="groupChatHasNewMessage"
          class="absolute top-0.5 right-0.5 w-2.5 h-2.5 bg-red-500 rounded-full"
        ></div>
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="list-ul py-1 rounded min-w-[10rem]"
        :class="{ 'd-none': !isOpenDropdownChat }"
      >
        <GroupChatAboutTask
          v-if="isShowGroupChatAboutTask"
          v-model:has-new-message="groupChatHasNewMessage"
          :current-task="currentTask"
          @on-start-chat="emit('onStartChat')"
          @on-create-group-chat="isCreateGroupChat = true"
        >
          <div class="list-li flex items-center space-x-2">
            <div class="w-5 h-5 flex-center relative">
              <SynIcon name="Groups" class="fill-gray-500" />
              <div
                v-if="groupChatHasNewMessage"
                class="
                  absolute
                  -top-0.5
                  -right-0.5
                  w-2
                  h-2
                  bg-red-500
                  rounded-full
                "
              ></div>
            </div>
            <span class="pr-7">
              {{ $t('CHAT_GROUP_ABOUT_THIS_TASK') }}
            </span>
          </div>
        </GroupChatAboutTask>
        <div
          v-if="isShowCreatorChat"
          class="list-li dropdown-item"
          @click="onChatAboutTaskByUserId(currentTask?.creatorId)"
        >
          <UserById
            :user-id="currentTask?.creatorId"
            avatar-class="w-5 h-5"
            class="font-medium"
          >
            <template #descriptionName>
              <span class="text-gray-400 text-2xs font-normal">
                {{ $t('TASK_TABLE_LABEL_CREATOR') || 'Creator' }}
              </span>
            </template>
          </UserById>
        </div>
        <div
          v-if="isShowGroupChat"
          class="list-li dropdown-item"
          @click="onChatAboutTaskWithGroup(currentTask?.groupId)"
        >
          <GroupById
            :group-id="currentTask?.groupId"
            avatar-class="w-5 h-5"
            class="font-medium"
          >
            <template #description-name>
              <span class="text-gray-400 text-2xs font-normal">
                {{ $t('COMMON_LABEL_GROUP') || 'Group' }}
              </span>
            </template>
          </GroupById>
        </div>
        <div
          v-if="isShowAssigneeUser"
          class="list-li dropdown-item"
          @click="onChatAboutTaskByUserId(currentTask?.assigneeId)"
        >
          <UserById
            :user-id="currentTask?.assigneeId"
            avatar-class="w-5 h-5"
            class="font-medium"
          >
            <template #descriptionName>
              <span class="text-gray-400 text-2xs font-normal">
                {{ $t('TASK_LABEL_ASSIGNEE') || 'Responsible' }}
              </span>
            </template>
          </UserById>
        </div>
        <div
          class="list-li border-t border-gray-100 flex items-center space-x-2"
          @click="onOpenChatAboutTaskModal"
        >
          <div class="w-5 flex-center">
            <syn-icon name="message" class="fill-gray-500" />
          </div>
          <span>{{ $t('NOTIFICATION_SEE_MORE') }}</span>
        </div>
      </div>
    </template>
  </VigDropdown>

  <ModalNewChat
    v-if="isNewChat"
    :title="
      $t('COMMON_LABEL_DISCUSS_ABOUT_WITH') || 'Discuss about this task with'
    "
    :attached-task="currentTask"
    :is-block-bubble-chat="isPlaceModal"
    @on-close="isNewChat = false"
    @on-conversation-click="onChatAboutTask"
    @open-send-fast="onOpenSendFast"
  />

  <ModalDiscussChatTask
    v-if="
      isPlaceModal &&
      objectDiscussTaskFast?.contactId &&
      objectDiscussTaskFast?.type
    "
    :user-id="
      objectDiscussTaskFast?.contactId && objectDiscussTaskFast?.type === 'USER'
        ? objectDiscussTaskFast?.contactId
        : null
    "
    :group-id="
      objectDiscussTaskFast?.contactId &&
      objectDiscussTaskFast?.type === 'GROUP'
        ? objectDiscussTaskFast?.contactId
        : null
    "
    :current-task="objectDiscussTaskFast?.task"
    :conversation-id="objectDiscussTaskFast?.conversationId"
    @on-close="
      objectDiscussTaskFast.contactId = null;
      objectDiscussTaskFast.type = '';
    "
  />

  <ModalNewGroupChat
    v-if="isCreateGroupChat"
    :chat-about-task="currentTask"
    modal-title="CHAT_GROUP_ABOUT_THIS_TASK"
    @on-close="isCreateGroupChat = false"
    @on-created="
      isCreateGroupChat = false;
      emit('onStartChat');
    "
  />
</template>

<style scoped></style>
