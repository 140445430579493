import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import DayoffService from '@/application/services/DayoffService';
// import { DayoffCalendarItemClass } from '@/domain/entities/dayoff/DayoffCalendarItemClass';
import DayoffPayloadClass from '@/domain/entities/dayoff/DayoffPayloadClass';
import myProfileStore from '@/store/auth/my-profile';
import DayoffModifyPayloadClass from '@/domain/entities/dayoff/DayoffModifyPayloadClass';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

export default defineStore({
    id: storeId.DAYOFF_CREATE,
    state: () => ({
        _dayoffService: DayoffService.getInstance(),
        isOpenCreateForm: false,
        startDateDefault: Date.now(),
    }),
    getters: {},
    actions: {
        createNewDayoff(data) {
            const payload = new DayoffPayloadClass(data);
            return this._dayoffService.createRequest(payload);
        },
        modifyDayoff(data) {
            if (!data?.id) return;
            const payload = new DayoffModifyPayloadClass(data);
            return this._dayoffService.modifyRequest(payload);
        },
        async remove(id) {
            if (!id) return;
            settingGlobalModal({
                type: 'confirm',
                title: '',
                content: translate(
                    'PROJECTMANAGEMENT_LABEL_CONFIRMDELETECONTENT'
                ),
                closeable: true,
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_AGREE'),
            });

            const confirmed = await ask();
            if (!confirmed) return false;
            return this._dayoffService.remove(id);
        },
        onOpenCreateForm(startDate) {
            const timezone = parseInt(myProfileStore().getTimezone);
            const defaultDate = startDate
                ? new Date(
                      new Date(startDate).getTime() + timezone * 60 * 60 * 1000
                  )
                : new Date();

            defaultDate.setHours(12);
            this.startDateDefault = defaultDate.getTime();
            this.isOpenCreateForm = true;
        },
        onCloseCreateForm() {
            this.isOpenCreateForm = false;
        },
        async calculateTotalDay(data: {
            startDate: number;
            endDate: number;
            startDateSession: number;
            endDateSession: number;
        }) {
            const res = await this._dayoffService.genDateOffRange(data);
            const dayOffs: any[] = res?.result || [];

            return {
                total: dayOffs.reduce((old, current) => {
                    return old + current?.dayAmount;
                }, 0),
                dayOffs,
            };
        },
    },
});
