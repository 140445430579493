<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M54 30H14.101l15.278-14.552a2 2 0 1 0-2.759-2.897L9.172 29.171A3.978 3.978 0 0 0 8 32c0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552a1.999 1.999 0 0 0 1.38-3.448L14.038 34H54a2 2 0 0 0 0-4z"
        class=""
      ></path>
    </g>
  </svg>
</template>
