<template>
  <nav class="flex flex-row justify-center divide-x">
    <button
      v-for="item in items"
      :key="item.key"
      :class="
        currentItem && currentItem.key == item.key
          ? 'tab-active'
          : 'tab-default'
      "
      class="flex-auto rounded"
      @click="changeTab(item)"
    >
      {{ item.name.fr ? item.name.fr : item.name }}
    </button>
  </nav>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'SynTabz',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currentTab: {
      type: String,
      default: '',
    },
  },
  emits: ['changeTab'],
  setup: (props, { emit }) => {
    let isActive = ref('');
    const currentItem = computed(() => props.currentTab);
    const changeTab = (item) => {
      emit('changeTab', item);
    };
    return { isActive, changeTab, currentItem };
  },
});
</script>
<style lang="postcss" scoped>
.tab-active {
  @apply py-2 block hover:text-current-500 focus:outline-none text-current-500 border-t-4 font-medium border-current-500;
}
.tab-default {
  @apply text-gray-500 font-normal py-2 block hover:text-current-500 focus:outline-none border-b-2;
}
</style>
