<script setup lang="ts">
// *** IMPORTS ***
import { onBeforeMount, ref, watch } from 'vue';
import { GroupChatRestriction } from '@/ui/common/constants/constant';
import ListMember from '@/ui/modules/domains/component/ListMember.vue';
import ModalChatRestrictionMembers from '@/ui/modules/group/detail/setting/ModalChatRestrictionMembers.vue';
import GroupRepository from '@/application/repositories/GroupRepository';
import { GroupMemberRole } from '@/application/enums/GroupEnums';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import myProfileStore from '@/store/auth/my-profile';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';

// *** PROPS, EMITS ***
const props = defineProps<{
  group: any;
}>();

// *** PRIVATE VARIABLES ***
const _myProfileStore = myProfileStore();

// *** COMPOSABLES ***

// *** REFS ***
const isRestricted = ref<boolean>();
const selectedOption = ref<string>();
const restrictionOptions = ref<any[]>([
  {
    value: GroupChatRestriction.AllowAdminOnly,
    text: translate('GROUP_SETTING_CHAT_RESTRICTION_ONLY_ADMIN'),
  },
  {
    value: GroupChatRestriction.AllowSpecificMembers,
    text: translate('GROUP_SETTING_CHAT_RESTRICTION_SPECIFIC_MEMBERS'),
  },
]);
const allowedMembers = ref<any[]>();
const isOpenModalSelectMembers = ref<boolean>();

// *** COMPUTED ***

// *** WATCHES ***
watch(
  () => props.group,
  (group) => _initSettingValues(group)
);

// *** HOOKS ***
onBeforeMount(() => {
  _initSettingValues(props.group);
});

// *** HANDLER FUNCTIONS ***
const onRestrictedChange = (value) => {
  if (!_checkHasPermission()) return;

  isRestricted.value = value;

  if (isRestricted.value) {
    selectedOption.value = GroupChatRestriction.AllowAdminOnly;
  }

  _saveRestriction();
};

const onSelectedOptionChange = (option) => {
  if (selectedOption.value === option) return;

  if (!_checkHasPermission()) return;

  if (option === GroupChatRestriction.AllowSpecificMembers) {
    isOpenModalSelectMembers.value = true;
    return;
  }

  selectedOption.value = option;
  _saveRestriction();
};

const onAllowedMembersClick = () => {
  if (!_checkHasPermission()) return;

  isOpenModalSelectMembers.value = true;
};

const onAllowedMembersSaved = (group) => {
  const updatedGroup = { ...(props.group || {}) };

  updatedGroup.sendMessageRestriction = group?.sendMessageRestriction;
  updatedGroup.groupUsers?.forEach((user) => {
    user.sendMessageAllowed = group?.groupUsers?.find(
      (u) => u?.id === user?.id
    )?.sendMessageAllowed;
  });

  _initSettingValues(updatedGroup);

  isOpenModalSelectMembers.value = false;

  _alertUpdateSuccess();
};

// *** PRIVATE FUNCTIONS ***
const _checkHasPermission = () => {
  const myUserId = _myProfileStore.myProfile?.id;
  const myMember = props.group?.groupUsers?.find((u) => u?.userId === myUserId);

  if (myMember?.role !== GroupMemberRole.ADMIN) {
    settingGlobalModal({
      type: 'notification',
      title: translate('COMON_NOT_ALLOW_PERFORM_ACTION'),
      content: translate('GROUP_ONLY_ADMIN_HAVE_PERMISSION'),
      confirmable: true,
    });
    ask();
    return false;
  }

  return true;
};

const _initSettingValues = (group) => {
  isRestricted.value =
    group &&
    (group.sendMessageRestriction === GroupChatRestriction.AllowAdminOnly ||
      group.sendMessageRestriction ===
        GroupChatRestriction.AllowSpecificMembers);

  selectedOption.value = group?.sendMessageRestriction;

  allowedMembers.value = group?.groupUsers?.filter(
    (u) => u?.sendMessageAllowed || u?.role === GroupMemberRole.ADMIN
  );
};

const _saveRestriction = async () => {
  if (!props.group?.id) return;

  const data = {
    groupId: props.group?.id,
    sendMessageRestriction: isRestricted.value
      ? selectedOption.value
      : GroupChatRestriction.NoRestriction,
    allowedUserIds: allowedMembers.value?.map((m) => m?.userId),
  };

  const res =
    await GroupRepository.getInstance().updateGroupSendMessageRestriction(data);

  _alertUpdateSuccess();

  return res?.result;
};

const _alertUpdateSuccess = () => {
  openNotification({
    type: AlertType.success,
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('GROUP_COMFIRM_BREAK_LINK_DEPARTMENT_UPDATE_SUCCESS'),
  });
};

// *** EXPOSES ***
</script>

<template>
  <div>
    <!-- CHAT RESTRICTION -->
    <div class="flex items-center justify-between space-x-4">
      <div class="flex items-start space-x-4">
        <div class="w-4 h-4 shrink-0">
          <SynIcon name="ChatRestrict" class="fill-current" />
        </div>
        <div class="flex flex-col">
          <span class="text-base">{{
            $t('GROUP_SETTING_CHAT_RESTRICTION_TITLE')
          }}</span>
          <span class="text-xs text-gray-600">
            {{ $t('GROUP_SETTING_CHAT_RESTRICTION_DESC') }}</span
          >
        </div>
      </div>
      <AtomSwitch :model-value="isRestricted" @change="onRestrictedChange" />
    </div>

    <!-- RESTRICTION OPTIONS -->
    <div v-if="isRestricted" class="flex items-start space-x-4">
      <div class="w-4 h-4"></div>
      <div class="flex flex-col gap-1">
        <div v-for="option in restrictionOptions" :key="option?.value">
          <VigRadio
            :model-value="selectedOption"
            name="rd-chat-restrict-option"
            :value="option?.value"
            @click.prevent="onSelectedOptionChange(option?.value)"
          >
            <span class="text-sm"> {{ option?.text }}</span>
          </VigRadio>
          <div
            v-if="
              option?.value === GroupChatRestriction.AllowSpecificMembers &&
              selectedOption === GroupChatRestriction.AllowSpecificMembers
            "
            class="pl-6 inline-block"
          >
            <ListMember
              is-editable
              :members="allowedMembers"
              @click="onAllowedMembersClick"
              @on-open-update-members="onAllowedMembersClick"
            />
          </div>
        </div>
      </div>
    </div>

    <ModalChatRestrictionMembers
      v-if="isOpenModalSelectMembers"
      :group="group"
      @on-close="isOpenModalSelectMembers = false"
      @on-saved="onAllowedMembersSaved"
    />
  </div>
</template>

<style scoped></style>
