export class SendPasswordResetCodeInput {
    EmailAddress: string;
    constructor(data: any) {
        this.EmailAddress = data.EmailAddress || '';
    }
}
export class LoginInput {
    userName: string;
    password: string;
    // organizationId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    loginType: number;
    loginId: string;
    constructor(data: any) {
        this.userName = data.userName || '';
        this.password = data.password || '';
        this.loginType = data.loginType || 1;
        this.loginId = data.loginId || '';
    }
}

export class SignUpInput {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    email: string;
    phone: string;
    avatar: string;
    active: boolean;
    isVerifiedPhone: boolean;
    isVerifiedEmail: boolean;
    adminType: number;
    // organizationId: "3fa85f64-5717-4562-b3fc-2c963f66afa6";
    joinedDate: string;
    jobTitle: string;
    jobTitleName: string;

    constructor(data: any) {
        this.id = data.id || 0;
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.username = data.username || '';
        this.password = data.password || '';
        this.email = data.email || '';
        this.phone = data.phone || '';
        this.avatar = data.avatar || '';
        this.active = data.active || true;
        this.isVerifiedPhone = data.isVerifiedPhone || true;
        this.isVerifiedEmail = data.isVerifiedEmail || true;
        this.adminType = data.adminType || 1;
        this.joinedDate = data.joinedDate;

        this.jobTitle = data.jobTitle;
        this.jobTitleName = data.jobTitleName;
    }
}

export class ValidateResetPasswordInput {
    email: string;
    resetCode: string;
    c: string;
    constructor(data: any) {
        this.email = data.email || '';
        this.resetCode = data.resetCode || '';
        this.c = data.c || '';
    }
}

export class ResetPasswordInput {
    email: string;
    resetToken: string;
    newPassword: string;
    constructor(data: any) {
        this.email = data.email || '';
        this.resetToken = data.resetToken || '';
        this.newPassword = data.newPassword || '';
    }
}
