<script setup lang="ts">
import { computed } from 'vue';
import { colorByAlphabet, convertViToEn, cleanString } from './colorByAlphabet';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const srcLinkMedal = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/medal_ranking/`;
const props = defineProps({
  src: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  rankNumber: {
    type: Number,
    default: 1,
  },
  customClass: {
    type: String,
    default: 'h-16 w-16',
  },
});

const textAva = computed(() => {
  if (!props?.name) return 'A';
  return cleanString(props.name)
    .split(' ')
    [cleanString(props.name).split(' ').length - 1].charAt(0);
});

const sizeAvatarRanking = computed(() => {
  if (props.rankNumber == 1) return 'width: 103px; height: 85px;';
  if (props.rankNumber == 2) return 'width: 100px; height: 90px;';
  return 'width: 80px; height: 85px;';
});

const nameRanking = computed(() => {
  if (props.rankNumber == 1) return 'ranking1.png';
  if (props.rankNumber == 2) return 'ranking2.png';
  return 'ranking3.png';
});
</script>

<template>
  <div class="w-full h-full square flex-center rounded-full">
    <div class="w-full h-full relative flex-center">
      <div class="w-full h-full absolute top-0 flex-center -mt-1">
        <div
          v-if="src"
          class="shadow overflow-hidden rounded-full"
          :class="[customClass]"
        >
          <vig-image
            class="w-full h-full object-cover"
            error-type="AVATAR"
            :path="src"
            size="small"
          />
        </div>
        <div
          v-else
          class="
            object-cover
            flex-center
            rounded-full
            text-white
            shadow
            font-thin
          "
          :class="`${customClass}`"
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
        >
          <span v-if="name" class="uppercase" style="font-size: 1.1em">
            {{ textAva }}
          </span>
        </div>
      </div>
      <div class="absolute top-0 w-full h-full flex-center">
        <vig-image
          class="object-cover"
          :path="srcLinkMedal + nameRanking"
          :style="sizeAvatarRanking"
        />
      </div>
    </div>
    <!-- <div
      class="
        absolute
        -bottom-3
        flex-center
        h-6
        w-6
        rounded-full
        text-white
        font-semibold
      "
      :style="{
        backgroundColor: rankData[rankNumber]?.color,
      }"
    > -->
    <!-- <SynIcon name="medal1"/>
      {{ rankNumber }} -->
    <!-- </div> -->
  </div>
</template>

<style>
.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
</style>
