import { IUserDetail } from '@/application/types/user/user.types';
import ShapeEntity from './ShapeEntity';

export default class ShapeUserEntity extends ShapeEntity {
    sourceData?: IUserDetail;
    constructor(data) {
        super(data);
        this.sourceData =
            typeof data?.sourceData == 'string'
                ? JSON.parse(data?.sourceData)
                : data?.sourceData;
    }
}
