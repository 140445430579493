<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    totalMiniseconds: number;
    autoStart?: boolean;
    format?: string;
    isDay?: boolean;
  }>(),
  {
    totalMiniseconds: 0,
    autoStart: false,
    format: 'hh:mm:ss',
    isDay: false,
  }
);

const emit = defineEmits(['onStop', 'onListenCurrentDistance']);

onMounted(() => {
  if (props.autoStart) countdown();
});

watch(
  () => props.totalMiniseconds,
  () => {
    countdown();
  }
);
// Set the date we're counting down to
const days = ref('00');
const hours = ref('00');
const minutes = ref('00');
const seconds = ref('00');
const distance = ref(0);
const calculatedWatch = () => {
  // Time calculations for days, hours, minutes and seconds
  const _days = Math.floor(distance.value / (1000 * 60 * 60 * 24));
  const _hours = Math.floor(
    (distance.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const _minutes = Math.floor(
    (distance.value % (1000 * 60 * 60)) / (1000 * 60)
  );
  const _seconds = Math.floor((distance.value % (1000 * 60)) / 1000);
  days.value = _days > 0 ? _days + 'd ' : '';
  hours.value = ('0' + _hours).slice(-2);
  minutes.value = ('0' + _minutes).slice(-2);
  seconds.value = ('0' + _seconds).slice(-2);
};
// Update the count down every 1 second
let intervalFunction, timeoutFunction;
const countdown = () => {
  distance.value = 0;
  if (!props.totalMiniseconds) return;

  if (intervalFunction) {
    clearInterval(intervalFunction);
    intervalFunction = null;
  }
  if (timeoutFunction) {
    clearTimeout(timeoutFunction);
    timeoutFunction = null;
  }

  const countDate = Date.now();

  intervalFunction = setInterval(() => {
    // Get current time
    const now = Date.now();
    // Find the distance between now and the count down date
    const distanceValue = props.totalMiniseconds + countDate - now;

    if (distanceValue <= 0) {
      clearInterval(intervalFunction);
      return;
    }

    distance.value = distanceValue;
    emit('onListenCurrentDistance', distanceValue);

    calculatedWatch();
  }, 1000);

  timeoutFunction = setTimeout(function () {
    clearInterval(intervalFunction);
    emit('onStop', distance.value);
  }, props.totalMiniseconds);
};

onUnmounted(() => {
  clearInterval(intervalFunction);
  clearTimeout(timeoutFunction);
});
const isShowHours = computed(() => props.format?.includes('hh'));

defineExpose({
  countdown,
});
</script>
<template>
  <div class="flex justify-center text-center">
    <span v-if="isDay && days" class="w-5">{{ days }}</span>
    <template v-if="isShowHours">
      <span>{{ hours }}</span>
      <span>:</span>
    </template>
    <span>{{ minutes }}</span>
    <span>:</span>
    <span>{{ seconds }}</span>
  </div>
</template>
