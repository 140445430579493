<template>
  <div>WELCOME YOU TO THE OFFICAL SITE OF TICTOP</div>
  <div>A product of iteal-vn</div>
  <div>Your email: {{ route.params.email }}</div>
</template>
<script>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { oaAccessTokenCallback } from './ZaloOALogic';

export default {
  setup() {
    const route = useRoute();
    // const email = computed(() => route.params.email);

    onMounted(() => {
      //	Call api
      const query = route.fullPath.replace(route.path, '');
      oaAccessTokenCallback(query);
    });
    return {
      route,
    };
  },
};
</script>
