import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { covertCamelCaseToSnakeCase } from '@/ui/hooks/commonFunction';
import UserRepository from '@/application/repositories/UserRepository';
import dayjs from 'dayjs';
import { GoogleService } from '@/domain/enums/google.enum';
import GoogleDriveApi from '@/ui/plugins/google/drive-api';
import { GoogleUser } from '@/ui/plugins/google/base-google-api';

type DriveDocumentState = {
    googleUser: GoogleUser | null;
};

export default defineStore({
    id: storeId.DRIVE_DOCUMENT,
    state: () =>
        ({
            googleUser: null,
        } as DriveDocumentState),
    getters: {},
    actions: {
        async initGoogleDrive() {
            GoogleDriveApi.initDriveClient();

            await this._getGoogleDriveAuthToken();
        },

        async signInGoogleDriveAccount() {
            const auth = await GoogleDriveApi.signIn();

            const res =
                await UserRepository.getInstance().exchangeGoogleAuthCodeForToken(
                    GoogleService.DRIVE,
                    auth?.code
                );

            return this._processTokenAndGoogleUser(res?.result);
        },

        async signOutGoogleDriveAccount() {
            const res =
                await UserRepository.getInstance().deleteUserGoogleAuthToken(
                    GoogleService.DRIVE
                );

            if (res?.result) {
                GoogleDriveApi.signOut();
                this.googleUser = null;
            }
        },

        async createGoogleDocument(
            name: string,
            type: string,
            isShared: boolean
        ): Promise<any> {
            await this._verifyGoogleAuthToken();

            return GoogleDriveApi.createFile(name, type, isShared);
        },

        // PRIVATE FUNCTIONS

        async _getGoogleDriveAuthToken() {
            const res =
                await UserRepository.getInstance().getUserGoogleAuthToken(
                    GoogleService.DRIVE
                );

            return this._processTokenAndGoogleUser(res?.result);
        },

        async _processTokenAndGoogleUser(tokenData) {
            const token = covertCamelCaseToSnakeCase(tokenData);

            GoogleDriveApi.setToken(token);

            if (token) this.googleUser = await GoogleDriveApi.getCurrentUser();

            return token;
        },

        async _verifyGoogleAuthToken() {
            const token = GoogleDriveApi.getToken();

            // Token not existed
            if (!token) return this.signInGoogleDriveAccount();

            // Token valid
            const tokenExpireTime = dayjs(token.created_date).add(
                token.expires_in,
                'second'
            );
            if (dayjs().isBefore(tokenExpireTime)) return token;

            // Token expired
            return this._getGoogleDriveAuthToken();
        },
    },
});
