import BaseRepository from '@/base/repositories/BaseRepository';
import { IProjectRepository } from '@/domain/interfaces/repositories/IProjectRepository';
import { translate } from '@/ui/plugins/i18n/myi18n';

export default class ProjectRepository implements IProjectRepository {
    private static instance: ProjectRepository;

    constructor() {}

    public static getInstance(): ProjectRepository {
        if (!ProjectRepository.instance) {
            // Get from local storage
            ProjectRepository.instance = new ProjectRepository();
        }

        return ProjectRepository.instance;
    }
    getProjectDetail(projectId) {
        return BaseRepository.requestWithAuthorize({
            url: `Project/GetProjectDetail?projectId=${projectId}`,
            method: 'get',
        });
    }

    getAll() {
        return BaseRepository.requestWithAuthorize({
            url: `Project/GetAll`,
            method: 'get',
        });
    }

    saveOrUpdateProject(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Project/SaveOrUpdateProject`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    data.id > 0
                        ? 'API_ACTION_MESSAGE_PROJECT_UPDATE_SUCCESS'
                        : 'API_ACTION_MESSAGE_PROJECT_CREATE_SUCCESS'
                ),
            }
        );
    }

    createProject(domain) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Project/CreateProject`,
                method: 'post',
                data: domain,
            },
            {
                message: translate('API_ACTION_MESSAGE_PROJECT_CREATE_SUCCESS'),
            }
        );
    }

    updateProjectInformation(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Project/UpdateProjectInformation`,
                method: 'post',
                data,
            },
            {
                message: translate('API_ACTION_MESSAGE_PROJECT_UPDATE_SUCCESS'),
            }
        );
    }
    updateProjectMembers(domainId, projectId, data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Project/UpdateProjectMembers?domainId=${domainId}&projectId=${projectId}`,
                method: 'post',
                data,
            },
            {
                message: translate('API_ACTION_MESSAGE_PROJECT_UPDATE_SUCCESS'),
            }
        );
    }

    delete(id) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Project/DeleteMsProject?id=${id}`,
                method: 'post',
            },
            {
                message: translate('API_ACTION_MESSAGE_PROJECT_DELETE_SUCCESS'),
            }
        );
    }
    rejectProject(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Project/RejectProject`,
            method: 'post',
            data,
        });
    }
}
