import BaseRepository from '@/base/repositories/BaseRepository';
// import { saveAs } from 'file-saver';
// import axios from 'axios';

export default class ImportRepository {
    private static instance: ImportRepository;

    constructor() {}

    public static getInstance(): ImportRepository {
        if (!ImportRepository.instance) {
            // Get from local storage
            ImportRepository.instance = new ImportRepository();
        }

        return ImportRepository.instance;
    }

    async importTaskData(file: any): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        return BaseRepository.requestWithAuthorize({
            url: `Import/ImportTask`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}
