<template>
  <div
    class="
      h-full
      w-2/3
      bg-white bg-opacity-40
      rounded
      mx-auto
      flex
      items-center
      flex-col
    "
  >
    <!-- <div
      v-if="!isVerify"
      class="relative h-8 w-full flex-center text-blue-600 text-sm bg-blue-100 border border-blue-400 rounded-md my-2 px-2 space-x-2"
    >
      <SynIcon name="email" custom-class="fill-blue-500 w-4 h-4" />
      <span>
        {{
          !numberOfSendCode
            ? $t('VERIFY_EMAIL_ALERT_PROFILE', { email: user.email })
            : $t('LOGIN_LABEL_SUB_HEADER_VERIFY_CODE_EMAIL', {
                email: user.email,
              })
        }}
      </span>
      <span
        v-if="!isLoadingSend"
        class="cursor-pointer text-blue-800 underline hover:text-blue-400"
        @click="onSendEmailCode()"
        >{{
          !numberOfSendCode
            ? $t('COMMON_LABEL_SEND_CODE')
            : $t('LOGIN_LABEL_RESEND_CODE')
        }}
      </span>
      <SynIcon
        v-else
        name="spinner"
        class="animate-spin fill-white w-4 h-4"
      />
      <div
        class="absolute right-0 p-0.5 flex-center rounded-full w-6 h-6 hover:bg-gray-100 cursor-pointer"
        @click="isVerify = !isVerify"
      >
        <SynIcon
          class=""
          name="cancel"
          custom-class="fill-blue-500 w-4 h-4 p-1"
          size="small"
        />
      </div>
    </div> -->
    <section class="w-full h-full">
      <!-- header -->
      <div
        class="
          flex
          mx-2
          py-2
          pl-4
          rounded-t-md
          items-center
          bg-current-50
          justify-between
        "
      >
        <p class="font-semibold text-current-500">
          {{ $t('USERMANAGEMENT_LABEL_INFOMATION') }}
        </p>
      </div>
      <!-- Body -->
      <div
        class="
          mx-2
          p-4
          border
          flex
          bg-white
          overflow-y-scroll
          space-x-4
          small-scrollbar
        "
        style="height: calc(100% - 2rem)"
      >
        <div style="flex: 35%" class="relative">
          <!-- avatar -->
          <div
            v-if="isLoading"
            class="h-32 w-32 bg-gray-200 rounded-full animate-pulse"
          ></div>
          <SynUploadAvatar
            v-else
            v-model="file"
            :avatar-thumbnail-url="user.avatarUrl"
            :is-loading="isSaveInfo"
            :is-upload-direct="isEdit ? false : true"
            is-edit
            @on-save="handleSaveInfo()"
          />
        </div>

        <!-- info -->
        <div class="px-4 pr-4 text-sm" style="flex: 65%">
          <div v-if="isLoading" class="flex-center space-x-3 mt-4">
            <div class="w-16 p-2 bg-gray-200 animate-pulse rounded-lg"></div>
            <div class="w-full p-2 bg-gray-200 animate-pulse rounded-lg"></div>
          </div>
          <div>
            <!-- LOGIN -->
            <div class="font-medium py-2">
              {{ $t('USERMANAGEMENT_LABEL_INFO_LOGIN') }}
            </div>
            <div class="my-2 flex">
              <div class="flex items-center text-sm pr-4 text-current-800 w-44">
                <span>{{ $t('USERMANAGEMENT_TABLE_EMAIL') + '*' }}</span>
              </div>
              <div class="my-2 flex-center space-x-2 w-full">
                <syn-form-input>
                  <syn-input
                    v-model="email"
                    class="w-full"
                    icon-prefix="email"
                    :placeholder="$t('USERMANAGEMENT_TABLE_EMAIL')"
                    is-disabled
                  ></syn-input>
                </syn-form-input>
                <SynIcon
                  name="edit"
                  is-active
                  has-action
                  custom-class="w-7 h-7 p-1 fill-current"
                  @click="isChangeEmail = !isChangeEmail"
                />
              </div>
            </div>
            <div class="my-2 flex">
              <div class="flex items-center text-sm pr-4 text-current-800 w-44">
                <span>{{ $t('USERMANAGEMENT_FORM_PHONE') + '*' }}</span>
              </div>
              <div class="my-2 flex-center space-x-2 w-full">
                <syn-form-input>
                  <syn-input
                    v-model="phone"
                    class="w-full"
                    icon-prefix="phone"
                    :placeholder="$t('USERMANAGEMENT_FORM_PHONE')"
                    is-disabled
                  ></syn-input>
                </syn-form-input>
                <SynIcon
                  name="edit"
                  has-action
                  custom-class="w-7 h-7 p-1 fill-current"
                  @click="isChangePhone = !isChangePhone"
                />
              </div>
            </div>
            <!-- <div class="my-2 flex justify-between">
              <div class="flex items-center text-sm pr-4 text-current-800 w-44">
                <span>{{ $t('LOGIN_FORM_PASSWORD') }}</span>
              </div>
              <syn-button
                custom-class="w-full bg-white border border-current text-current hover:bg-current-50 hover:text-white"
                icon-class="fill-current text-current"
                :label="!isHasPWD ? $t('PROFILE_SET_UP_PASSWORD') : $t('USERMANAGEMENT_LABEL_CHANGE_PASSWORD')"
                name-icon="lock"
                @click="isShowChangePassword = true"
              >
                <span class="text-sm">
                  {{
                    !isHasPWD
                      ? $t('PROFILE_SET_UP_PASSWORD') || 'Add password'
                      : $t('USERMANAGEMENT_LABEL_CHANGE_PASSWORD') ||
                        'Change password'
                  }}
                </span>
              </syn-button>
            </div> -->

            <!-- INFO -->
            <div class="font-medium py-2 border-b flex justify-between w-full">
              <span>{{ $t('USERMANAGEMENT_LABEL_INFO_DIF') }}</span>
              <div class="flex items-center rounded-md">
                <div
                  v-if="!isEdit"
                  v-vig-tooltip="$t('COMMON_LABEL_EDIT')"
                  class="
                    hover:bg-current hover:text-white
                    p-1
                    rounded-md
                    flex
                    items-center
                    cursor-pointer
                    justify-center
                  "
                >
                  <SynIcon
                    name="edit"
                    class="fill-current hover:fill-white"
                    @click="onEdit"
                  />
                </div>
                <div v-else class="text-xs flex space-x-2 mr-4">
                  <syn-button
                    v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
                    :label="$t('COMMON_LABEL_CANCEL')"
                    type-text
                    color="gray"
                    :disabled="isSaveInfo"
                    @click="onCancel"
                  >
                  </syn-button>
                  <syn-button
                    v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
                    :label="$t('COMMON_LABEL_SAVE')"
                    :is-loading="isSaveInfo"
                    :disabled="
                      !isChange || !firstName || !lastName ? true : false
                    "
                    @click="onSaveInfoUser"
                  >
                  </syn-button>
                </div>
              </div>
            </div>
            <div class="my-2 flex">
              <div class="flex items-center text-sm pr-4 text-current-800 w-44">
                <span>{{ $t('USERMANAGEMENT_TABLE_FIRST_NAME') + '*' }}</span>
              </div>
              <div class="w-full">
                <syn-form-input v-if="isEdit" :error-message="firstNameError">
                  <syn-input
                    v-model="firstName"
                    :placeholder="
                      firstName || $t('USERMANAGEMENT_TABLE_FIRST_NAME')
                    "
                    :error-message="firstNameError"
                  ></syn-input>
                </syn-form-input>
                <div
                  v-else
                  class="text-gray-800 font-normal px-4 py-1 border-b w-full"
                >
                  {{ firstName || $t('USERMANAGEMENT_TABLE_FIRST_NAME') }}
                </div>
              </div>
            </div>
            <div class="my-2 flex">
              <div class="flex items-center text-sm pr-4 text-current-800 w-44">
                <span>{{ $t('USERMANAGEMENT_TABLE_LAST_NAME') + '*' }}</span>
              </div>
              <div class="w-full">
                <syn-form-input v-if="isEdit" :error-message="lastNameError">
                  <syn-input
                    v-model="lastName"
                    :placeholder="
                      lastName || $t('USERMANAGEMENT_TABLE_LAST_NAME')
                    "
                    :error-message="lastNameError"
                  ></syn-input>
                </syn-form-input>
                <div
                  v-else
                  class="text-gray-800 font-normal px-4 py-1 border-b w-full"
                >
                  {{ lastName || $t('USERMANAGEMENT_TABLE_LAST_NAME') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <modal-verify
    v-if="isChangeEmail || isChangePhone"
    :login="isChangeEmail ? user.email : user.phone"
    :type="isChangeEmail ? 'EMAIL' : 'PHONE'"
    @cancel="(value) => onCancelChange(value)"
  ></modal-verify>

  <form-change-password
    v-if="isShowChangePassword"
    :type="!isHasPWD ? 'setup' : 'change'"
    :email="user.email"
    @cancel="
      (data) => {
        isShowChangePassword = data.value;
      }
    "
  />
</template>
<script>
import { computed, ref } from 'vue';
import { useField } from 'vee-validate';
import authStore from '@/store/auth';
import userStore from '@/store/user';
import orgStore from '@/store/organization';
import { setLocalStorage, getLocalStorage } from '@/ui/hooks/storageHook';
import { isValidEmail, isValidPhoneNumber } from '@/ui/hooks/commonFunction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { SignUpInput } from '@/domain/entities/PayloadClass/AuthPayloadClass';
import ModalVerify from '@/ui/pages/profile/usercomponent/ModalChangeLogin.vue';
import FormChangePassword from '@/ui/pages/profile/usercomponent/FormChangePassword.vue';
export default {
  name: 'ProfilePublic',
  components: { ModalVerify, FormChangePassword },
  prop: {},
  setup() {
    // const userProfile = computed(() => myProfileStore().myProfile);
    const userProfile = ref({});
    const user = ref(new SignUpInput({}));
    const _authStore = authStore();
    const _userStore = userStore();
    const _orgStore = orgStore();
    const isChangeEmail = ref(false);
    const isChangePhone = ref(false);
    const errorMessage = ref('');
    const errorMessagePhone = ref('');
    const isHasPWD = ref(false);
    const isShowChangePassword = ref(false);
    const isChange = ref(false);
    const init = async () => {
      const profile = await _orgStore.getUserProfile();
      userProfile.value = profile.result;
      user.value = userProfile.value;
      const res = await _authStore.checkEmail(user.value?.email);
      if (res?.isActivated) {
        isVerify.value = true;
      } else {
        isVerify.value = false;
      }
      const resCheckHas = await _authStore.checkUserHasPasswordByEmail(
        user.value?.email
      );
      if (resCheckHas) {
        isHasPWD.value = true;
      } else {
        isHasPWD.value = false;
      }
      phone.value = userProfile.value.phone;
      email.value = userProfile.value.email;
      firstName.value = userProfile.value.firstName;
      lastName.value = userProfile.value.lastName;
    };
    init();
    const { value: phone, errorMessage: phoneError } = useField('data.phone');

    const { value: email, errorMessage: emailError } = useField('data.email');

    const { value: firstName, errorMessage: firstNameError } = useField(
      'data.firstName',
      function (value) {
        if (!value) {
          isChange.value = false;
          return translate('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED');
        }
        if (value !== userProfile.value.firstName) {
          isChange.value = true;
        } else {
          isChange.value = false;
        }
        return true;
      }
    );

    const { value: lastName, errorMessage: lastNameError } = useField(
      'data.lastName',
      function (value) {
        if (!value) {
          isChange.value = false;
          return translate('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED');
        }
        if (value !== userProfile.value.lastName) {
          isChange.value = true;
        } else {
          isChange.value = false;
        }
        return true;
      }
    );

    const isEdit = ref(false);
    const fileName = computed(() => _orgStore.fileName);
    const file = ref();
    const isSaveInfo = ref(false);
    const onEdit = () => {
      isEdit.value = true;
    };
    const onCancel = async () => {
      isEdit.value = false;
      file.value = '';
      init();
    };

    const updateValue = () => {
      user.value.firstName = firstName.value;
      user.value.lastName = lastName.value;
      user.value.phone = phone.value;
      user.value.email = email.value;
      user.value.userName = phone.value;
    };
    const onSaveInfoUser = async () => {
      isSaveInfo.value = true;
      await handleSaveInfo();
      isSaveInfo.value = false;
      isEdit.value = false;
    };

    const handleSaveInfo = async () => {
      isSaveInfo.value = true;
      updateValue();
      if (file.value) {
        await _orgStore.uploadAvatarAws(file.value);
        user.value.avatar = fileName.value;
      }
      await _authStore.saveUserProfile(user.value);
      await _orgStore.getUserProfile();
      isSaveInfo.value = false;
    };

    const onChangePhone = (phoneObj, phoneN) => {
      if (phoneObj.number) {
        phone.value = phoneObj.number;
      } else {
        phone.value = phoneN;
      }
    };

    const isVerify = ref(true);
    const numberOfSendCode = ref();
    numberOfSendCode.value = getLocalStorage('NUM_SEND_CODE');
    const isLoadingSend = ref(false);
    const onSendEmailCode = async () => {
      // handle send email code
      isLoadingSend.value = true;
      const res = await _userStore.sendVerificationUserEmail();
      if (!res?.result) {
        isLoadingSend.value = false;
      } else {
        setLocalStorage('NUM_SEND_CODE', 1);
        numberOfSendCode.value = 1;
        isLoadingSend.value = false;
      }
    };
    const onCancelChange = (value) => {
      isChangeEmail.value = value;
      isChangePhone.value = value;
    };

    return {
      isEdit,
      userProfile,
      user,
      file,
      isSaveInfo,
      errorMessage,
      errorMessagePhone,
      phone,
      phoneError,
      email,
      emailError,
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      isLoadingSend,
      onSendEmailCode,
      onEdit,
      onCancel,
      onSaveInfoUser,
      handleSaveInfo,
      onChangePhone,
      isValidEmail,
      isValidPhoneNumber,
      isVerify,
      isChangeEmail,
      isChangePhone,
      onCancelChange,
      isHasPWD,
      isShowChangePassword,
      isChange,
    };
  },
};
</script>
