<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0,0,256,256"
  >
    <g transform="translate(-38.4,-38.4) scale(1.3,1.3)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.33333,5.33333)">
          <rect x="13" y="13" width="22" height="22" fill="#ffffff"></rect>
          <path
            d="M25.68,20.92l1.008,1.44l1.584,-1.152v8.352h1.728v-10.944h-1.44z"
            fill="#1e88e5"
          ></path>
          <path
            d="M22.943,23.745c0.625,-0.574 1.013,-1.37 1.013,-2.249c0,-1.747 -1.533,-3.168 -3.417,-3.168c-1.602,0 -2.972,1.009 -3.33,2.453l1.657,0.421c0.165,-0.664 0.868,-1.146 1.673,-1.146c0.942,0 1.709,0.646 1.709,1.44c0,0.794 -0.767,1.44 -1.709,1.44h-0.997v1.728h0.997c1.081,0 1.993,0.751 1.993,1.64c0,0.904 -0.866,1.64 -1.931,1.64c-0.962,0 -1.784,-0.61 -1.914,-1.418l-1.687,0.276c0.262,1.636 1.81,2.87 3.6,2.87c2.007,0 3.64,-1.511 3.64,-3.368c0,-1.023 -0.504,-1.941 -1.297,-2.559z"
            fill="#1e88e5"
          ></path>
          <path d="M34,42h-20l-1,-4l1,-4h20l1,4z" fill="#fbc02d"></path>
          <path d="M38,35l4,-1v-20l-4,-1l-4,1v20z" fill="#4caf50"></path>
          <path
            d="M34,14l1,-4l-1,-4h-25c-1.657,0 -3,1.343 -3,3v25l4,1l4,-1v-20z"
            fill="#1e88e5"
          ></path>
          <path d="M34,34v8l8,-8z" fill="#e53935"></path>
          <path
            d="M39,6h-5v8h8v-5c0,-1.657 -1.343,-3 -3,-3z"
            fill="#1565c0"
          ></path>
          <path d="M9,42h5v-8h-8v5c0,1.657 1.343,3 3,3z" fill="#1565c0"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
