<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{
  task: any;
  readonly: boolean;
}>();

const emits = defineEmits(['onNameChange']);

const edittingName = ref<string>();
const isEditting = ref<boolean>();

watch(
  () => props.task?.id,
  () => {
    isEditting.value = false;
  }
);

const onRenameTaskConfirm = () => {
  emits('onNameChange', edittingName.value);

  isEditting.value = false;
};

const onEditClick = () => {
  edittingName.value = props.task?.name;

  isEditting.value = true;
};
</script>

<template>
  <div v-if="isEditting">
    <SynInputArea
      v-model="edittingName"
      :disabled-enter-warning="$t('TASK_NAME_SHOULD_BE_WRITTEN_SEAMLESSLY')"
      autofocus
      max-length="200"
    />
    <div class="flex items-center justify-end gap-2">
      <SynButton
        :label="$t('COMMON_LABEL_CLOSE') || 'Close'"
        type-text
        color="gray"
        custom-class="px-2 py-1 rounded text-xs"
        @click="isEditting = false"
      />
      <SynButton
        :label="$t('COMMON_LABEL_SAVE') || 'Save'"
        custom-class="px-2 py-1 rounded text-xs"
        :disabled="!edittingName"
        @click="onRenameTaskConfirm"
      />
    </div>
  </div>

  <div
    v-else
    class="
      p-2
      rounded
      cursor-text
      text-gray-800
      flex
      items-start
      justify-between
      gap-2
      group
    "
    :class="{ 'hover:bg-gray-100': !readonly }"
  >
    <span class="break-words font-medium text-xl">{{ task?.name }}</span>
    <SynIcon
      v-if="!readonly"
      name="edit"
      is-active
      class="
        fill-current
        hover:fill-current-800
        cursor-pointer
        invisible
        group-hover:visible
      "
      :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
      @click="onEditClick"
    />
  </div>
</template>
