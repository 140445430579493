<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 34.25 34.25"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.125,0C7.668,0,0,7.667,0,17.125S7.668,34.25,17.125,34.25c9.459,0,17.125-7.667,17.125-17.125S26.584,0,17.125,0z    M23.445,11.422c1.354,0,2.453,1.099,2.453,2.453s-1.1,2.453-2.453,2.453c-1.354,0-2.453-1.099-2.453-2.453   S22.091,11.422,23.445,11.422z M10.804,11.422c1.355,0,2.453,1.099,2.453,2.453s-1.098,2.453-2.453,2.453   c-1.354,0-2.453-1.099-2.453-2.453S9.451,11.422,10.804,11.422z M26.365,23.079c-2.098,3.046-5.553,4.864-9.24,4.864   c-3.689,0-7.145-1.818-9.24-4.865c-0.47-0.682-0.297-1.616,0.386-2.086c0.683-0.471,1.616-0.297,2.086,0.386   c1.536,2.233,4.067,3.565,6.769,3.565s5.231-1.332,6.771-3.565c0.47-0.681,1.402-0.854,2.086-0.385   C26.662,21.463,26.834,22.396,26.365,23.079z"
        ></path>
      </g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
