import {
    ByWeekDay,
    CalendarReminderRepeatType,
} from '@/ui/common/constants/calendar';
import { RRule } from 'rrule';

export function getTaskFilterOptions() {
    return {
        urgencies: [],
        myTask: 0,
        imAssignTask: 0,
    };
}

export function getMetaData(data) {
    return {
        participantIds: data.participantIds || [],
        workspace: data.workspace || '',
        meetingDuration: data.meetingDuration || 15,
        repeatSpecificDaysOfWeek: data.repeatSpecificDaysOfWeek || [],
        notificationType: data.notificationType || 'NONE',
        meetingType: data.meetingType || 1,
        attachments: {
            files: data.files || [],
            notes: data.notes || [],
        },
        allDay: data.allDay || false,
        repeatType: data.repeatType || 0,
    };
}

export function getExtendedPropsEvent(data) {
    return {
        calendarId: data.calendarId,
        createdBy: data.createdBy,
        createdDate: data.createdDate,
        description: data.description,
        sourceId: data.sourceId,
        sourceType: data.sourceType,
        status: data.status,
        type: data.type,
        updateDate: data.updateDate,
        updatedBy: data.updatedBy,
        workspace: data.workspace,
        meetingType: data.meetingType,
        repeatType: data.repeatType,
        allDay: !!data.allDay,
    };
}

export function getRRuleItemCalendar(
    startDate,
    repeatType,
    repeatSpecificDaysOfWeek,
    repeatRRules
) {
    if (!repeatType || repeatType === CalendarReminderRepeatType.DoNotRepeat) {
        return null;
    }

    let rrule: RRule | null = null;

    switch (repeatType) {
        case CalendarReminderRepeatType.EveryDay:
            rrule = new RRule({
                freq: RRule.DAILY,
                dtstart: new Date(startDate),
            });
            break;
        case CalendarReminderRepeatType.EveryWeekDays:
            rrule = new RRule({
                freq: RRule.WEEKLY,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
                dtstart: new Date(startDate),
            });
            break;
        case CalendarReminderRepeatType.EverySpecificDayOfWeek:
            rrule = new RRule({
                freq: RRule.WEEKLY,
                dtstart: new Date(startDate),
            });
            break;
        case CalendarReminderRepeatType.EverySpecificDayInMonth:
            rrule = new RRule({
                freq: RRule.MONTHLY,
                dtstart: new Date(startDate),
            });
            break;
        case CalendarReminderRepeatType.CustomSpecificDayOfWeek:
            rrule = new RRule({
                freq: RRule.WEEKLY,
                byweekday: repeatSpecificDaysOfWeek?.map(
                    (d) => ByWeekDay[d].key
                ),
                dtstart: new Date(startDate),
            });
            break;
        case CalendarReminderRepeatType.CustomRRule: {
            // rrule = rrulestr(repeatRRules?.join('\n'));
            const options = RRule.parseString(repeatRRules?.join('\n'));
            options.dtstart = new Date(startDate);

            rrule = new RRule(options);
            break;
        }
        default:
            break;
    }
    return rrule ? rrule.toString() : null;
}

export function getRRuleItemSymtemCalendar(startDate, endDate) {
    return {
        freq: RRule.WEEKLY, // 'yearly',
        // byweekday: RRuleCalendar.DoNotRepeat.byweekday,
        dtstart: startDate,
        until: endDate,
    };
}
