<script setup lang="ts">
import { ref } from 'vue';
import InformationPopupFromLink from '@/ui/components/popup/InformationPopupFromLink.vue';
import { isValidTaskCode } from '@/ui/hooks/taskHook';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';

defineProps<{ description: string }>();

const informationPopupRef = ref<any>(null);

const onHoverTextMessage = async (event) => {
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.onHoverTextMessage == 'function'
  )
    informationPopupRef.value?.onHoverTextMessage(event);
};

const onMouseLeaveTextMessage = (event) => {
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.onMouseLeaveTextMessage == 'function'
  )
    informationPopupRef.value?.onMouseLeaveTextMessage(event);
};

const onClickText = async (event) => {
  const href = event?.target?.href;
  console.log(event);

  if (!href || !href?.includes('/tasks/')) return;

  const taskCode = href.split('/').pop();

  if (!isValidTaskCode(taskCode)) return;

  event.preventDefault();

  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.destroyCard == 'function'
  )
    informationPopupRef.value?.destroyCard();
};
</script>
<template>
  <span
    class="task-detail_description break-words text-gray-500"
    @mouseover="onHoverTextMessage"
    @mouseleave="onMouseLeaveTextMessage"
    @click="onClickText"
    v-html="
      description ||
      $t('COMMON_LABEL_PLACEHOLDER_ADD_DESCRIPTION') ||
      'Add description ...'
    "
  ></span>
  <InformationPopupFromLink ref="informationPopupRef" />
</template>
