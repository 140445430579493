import { ref } from 'vue';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
import {
    getCurrentUserId,
    getLocalStorage,
    StorageConstant,
} from '@/ui/hooks/storageHook';
import { setDataFirebase } from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import notificationStore from '@/store/notification/notification.store';

export default function realtimefbNotificationComposables() {
    const _notificationStore = notificationStore();

    let fbLatestNotifClass: UserDynamicModel;
    let fbTotalNotifClass: UserDynamicModel;
    let fbTotalMyTaskNotifClass: UserDynamicModel;
    let unscribleLatestNotification;
    let unscribleTotalNotification;
    let unscibleTotalMyTaskNotification;

    const userId = ref();
    userId.value = getCurrentUserId();
    const latestNotification = ref(0);

    const initSubscriberNotificationCount = async () => {
        onListenLatestNotificationFirebase();
        onListenTotalNotificationFirebase();
        onListenTotalMyTaskNotificationFirebase();
    };

    const onListenLatestNotificationFirebase = () => {
        if (unscribleLatestNotification) unscribleLatestNotification();
        if (!userId.value) return;

        const userPath = `user_${userId.value}`;
        fbLatestNotifClass = new UserDynamicModel(
            `${userPath}/latestNotification`
        );

        unscribleLatestNotification = fbLatestNotifClass.onChange(
            async (objectNotification) => {
                const lastNoti = objectNotification?.notificationActionTime;

                if (latestNotification.value !== lastNoti) {
                    latestNotification.value = lastNoti;
                    switch (objectNotification?.notificationAction) {
                        case 'read_all':
                            _notificationStore.handleReadAllNotificationLocal();
                            break;
                        case 'delete':
                            _notificationStore.handleDeleteNotificationLocal(
                                objectNotification?.notificationId
                            );
                            break;
                        case 'read':
                            _notificationStore.handleReadNotificationLocal(
                                objectNotification?.notificationId
                            );
                            break;
                        case 'new':
                            await _notificationStore.getNotificationById(
                                objectNotification?.notificationId
                            );
                            await _notificationStore.getNotificationUnread();
                            break;
                        default:
                            break;
                    }
                }
            }
        );
    };

    const onListenTotalNotificationFirebase = () => {
        if (unscribleTotalNotification) unscribleTotalNotification();
        if (!userId.value) return;

        const userPath = `user_${userId.value}`;
        fbTotalNotifClass = new UserDynamicModel(
            `${userPath}/totalNotification`
        );

        unscribleTotalNotification = fbTotalNotifClass.onChange(
            async (data) => {
                _notificationStore.setNumberTotalNotificationUnread(data || 0);
                _notificationStore.sendNotificationToDesktopApp('');
            }
        );
    };

    const onListenTotalMyTaskNotificationFirebase = () => {
        if (unscibleTotalMyTaskNotification) unscibleTotalMyTaskNotification();
        if (!userId.value) return;

        const userPath = `user_${userId.value}`;
        fbTotalMyTaskNotifClass = new UserDynamicModel(
            `${userPath}/totalMyTaskNotification`
        );

        unscibleTotalMyTaskNotification = fbTotalMyTaskNotifClass.onChange(
            async (data) => {
                _notificationStore.setNumberTotalNotificationMyTask(data || 0);
                // if (!isMyTask.value) {
                //   _notificationStore.setNumberTotalNotificationMyTask(data.val() || 0);
                // } else {
                //   const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
                //   const companyId = `organization_${organizationId}`;
                //   let numberTotal =
                //     notificationCount.value - notificationCountMyTask.value <= 0
                //       ? 0
                //       : notificationCount.value - notificationCountMyTask.value;
                //   await setDataFirebase(
                //     `users_dynamic_info/${companyId}/${userPath}/totalNotification`,
                //     numberTotal
                //   );
                //   await setDataFirebase(
                //     `users_dynamic_info/${companyId}/${userPath}/totalMyTaskNotification`,
                //     0
                //   );
                // }
                _notificationStore.sendNotificationToDesktopApp('');
            }
        );
    };

    const updateDataFirebaseWhenClose = async () => {
        const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
        const userId = getCurrentUserId();
        if (!organizationId || !userId) return;
        const companyId = `organization_${organizationId}`;
        const userPath = `user_${userId}`;
        await setDataFirebase(
            `users_dynamic_info/${companyId}/${userPath}/totalNotification`,
            0
        );
        await setDataFirebase(
            `users_dynamic_info/${companyId}/${userPath}/totalMyTaskNotification`,
            0
        );
    };

    const unscribleListenFB = () => {
        unscribleLatestNotification && unscribleLatestNotification();
        unscribleTotalNotification && unscribleTotalNotification();
        unscibleTotalMyTaskNotification && unscibleTotalMyTaskNotification();
    };

    return {
        initSubscriberNotificationCount,
        updateDataFirebaseWhenClose,
        unscribleListenFB,
    };
}
