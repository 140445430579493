import { getDefaultUserNotification } from '@/application/parsers/UserParser';
import { InstantMessagingType } from '@/ui/common/constants/constant';
export class UserCreatePayload {
    id: number;
    firstName: string;
    lastName: string;
    login: string;
    avatar: string;
    active: boolean;
    timeOt: string;
    birthday: string;
    address: string;
    identityCard: string;
    description: string;
    holiday: string;
    email: string;
    skyper: string;
    phone: string;
    utc: string;
    adminType: number;
    positionId: number;
    password: string;
    notificationInfos: [
        {
            id: number;
            memberId: number;
            member: string;
            memberType: number;
            instantMessagingType: InstantMessagingType.Slack;
            url: string;
            phone: string;
            isOn: boolean;
        },
        {
            id: number;
            memberId: number;
            member: string;
            memberType: number;
            instantMessagingType: InstantMessagingType.Zalo;
            url: string;
            phone: string;
            isOn: boolean;
        }
    ];

    constructor(data: any) {
        this.id = data.id || 0;
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.login = data.login || '';
        this.avatar = data.avatar || '';
        this.active = data.active || true;
        this.timeOt = data.timeOt || '';
        this.birthday = data.birthday || '';
        this.address = data.address || '';
        this.identityCard = data.identityCard || '';
        this.description = data.description || '';
        this.holiday = data.holiday || '';
        this.email = data.email || '';
        this.skyper = data.skyper || '';
        this.phone = data.phone || '';
        this.utc = data.utc || '';
        this.adminType = data.adminType || 3;
        this.positionId = data.positionId || 0;
        this.password = data.password || '';
        this.notificationInfos =
            data.notificationInfos || getDefaultUserNotification();
    }
}

export class UserNotification {
    id: number;
    memberId: number;
    member: string;
    memberType: number;
    instantMessagingType: number;
    url: string;
    phone: string;
    isOn: boolean;
    constructor(data: any) {
        this.id = data.id || 0;
        this.memberId = data.memberId || 0;
        this.member = data.member || '';
        this.memberType = data.memberType || 1;
        this.instantMessagingType = data.instantMessagingType || 0;
        this.url = data.url || '';
        this.phone = data.phone || '';
        this.isOn = data.isOn || false;
    }
}
