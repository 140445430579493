<script setup lang="ts">
import { ref, computed } from 'vue';

import DomainTag from '@/ui/modules/task/components/DomainTag.vue';
import scopeStore from '@/store/scope';
import userStore from '@/embed/store/user-store';
import groupStore from '@/embed/store/group-store';
import { cloneDeep } from '@/ui/hooks/commonFunction';

const props = defineProps({
  userId: {
    type: Number,
    default: null,
  },
  groupId: {
    type: Number,
    default: null,
  },
  taskDetail: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['change']);

const _scopeStore = scopeStore();
const _userStore = userStore();
const _groupStore = groupStore();

const domainDetail = ref({
  id: 0,
  name: '',
} as any);
const projectDetail = ref({ id: 0, name: '' } as any);
const subprojectDetail = ref({ id: 0, name: '' } as any);
const initData = async () => {
  domainList.value = await getDomainByOwnerId(props.userId, props.groupId);
  domainDetail.value = _scopeStore.getDomainDetailById(
    props.taskDetail?.domainId
  );
  projectDetail.value = _scopeStore.getProjectDetailById(
    props.taskDetail?.projectId
  );
  subprojectDetail.value = _scopeStore.getProjectDetailById(
    props.taskDetail?.subprojectId
  );
};

const getDomainByOwnerId = async (userId, groupId) => {
  if (!userId) return [];

  const domainOfUser = await _userStore.getScopeListByUserId(userId);

  if (groupId > 0) {
    const groupDomains = _groupStore.getGroupDomainByGroupId(groupId);
    return cloneDeep(
      domainOfUser.length > 0
        ? groupDomains.map((domain) => {
            const index = domainOfUser.findIndex(
              (d) => d.domainId == domain.domainId
            );
            domain.projectScopeList = domainOfUser[index].projectScopeList;
            return domain;
          })
        : groupDomains
    );
  }

  return domainOfUser;
};

const domainList = ref([] as any[]);
const userProjects = computed(() => {
  let projects = [] as any[];
  const index = domainList.value.findIndex(
    (o) => o.domainId == domainDetail.value?.id
  );
  if (index > -1) {
    projects = domainList.value[index].projectScopeList?.filter(
      (o) => !o.parentId
    );
  }
  return projects;
});
const userSubProjects = computed(() => {
  let subProjects = [] as any[];
  const index = domainList.value.findIndex(
    (o) => o.domainId == domainDetail.value?.id
  );
  if (index > -1) {
    subProjects = domainList.value[index].projectScopeList?.filter(
      (o) => o.parentId && o.parentId == projectDetail.value?.id
    );
  }
  return subProjects;
});
const removeDomain = (type = 'domain') => {
  if (type == 'domain') {
    domainDetail.value = {};
    projectDetail.value = {};
    subprojectDetail.value = {};
  }
  if (type == 'domain' || type == 'project') {
    projectDetail.value = {};
    subprojectDetail.value = {};
  }
  if (type == 'domain' || type == 'project' || type == 'subProject') {
    subprojectDetail.value = {};
  }
};
const onChooseDomain = (type = 'domain', item) => {
  if (type == 'domain') {
    domainDetail.value = {
      id: item.domainId,
      name: item.domainName,
    };
  }
  if (type == 'project') {
    projectDetail.value = {
      id: item.projectId,
      name: item.projectName,
    };
  }
  if (type == 'subProject') {
    subprojectDetail.value = {
      id: item.projectId,
      name: item.projectName,
    };
  }

  emit('change', {
    domainId: domainDetail.value?.id,
    projectId: projectDetail.value?.id,
    subprojectId: subprojectDetail.value?.id,
  });
};

const onChangeProject = (data) => {
  projectDetail.value = {
    id: data.projectId,
    name: data.projectName,
  };
  emit('change', {
    domainId: domainDetail.value?.id,
    projectId: projectDetail.value?.id,
    subprojectId: subprojectDetail.value?.id,
  });
};
const onChangeSubProject = (data) => {
  subprojectDetail.value = {
    id: data.projectId,
    name: data.projectName,
  };
  emit('change', {
    domainId: domainDetail.value?.id,
    projectId: projectDetail.value?.id,
    subprojectId: subprojectDetail.value?.id,
  });
};
initData();
</script>
<template>
  <div class="flex-center space-x-2 relative">
    <div
      v-if="domainDetail?.id || projectDetail?.id || subprojectDetail?.id"
      class="flex items-center space-x-2"
    >
      <domain-tag v-if="domainDetail?.id" removeable @remove="removeDomain()">
        <span>
          {{ domainDetail?.name }}
        </span>
      </domain-tag>
      <domain-tag
        v-if="projectDetail?.id"
        removeable
        @remove="removeDomain('project')"
      >
        <span>
          {{ projectDetail?.name }}
        </span>
      </domain-tag>
      <domain-tag
        v-if="subprojectDetail?.id"
        removeable
        @remove="removeDomain('subProject')"
      >
        <span>
          {{ subprojectDetail?.name }}
        </span>
      </domain-tag>
    </div>
    <div
      v-if="!domainDetail?.id || !projectDetail?.id || !subprojectDetail?.id"
      class="flex-center h-10"
    >
      <template v-if="!domainDetail?.id">
        <template v-if="domainList?.length > 5">
          <vig-select
            :placeholder="
              $t('TICKET_TASK_FORM_LABEL_TYPE_OR_CATEGORY') || 'Domain'
            "
            key-search="domainName"
            key-label="domainName"
            :origin-options="domainList"
            @on-choose="(item) => onChooseDomain('domain', item)"
          />
        </template>
        <template v-else>
          <div
            v-for="item in domainList"
            :key="item.id"
            class="float-left px-2"
          >
            <syn-radio
              :value="item.domainId"
              :label="item.domainName"
              @change="onChooseDomain('domain', item)"
            />
          </div>
        </template>
      </template>
      <template v-if="!projectDetail?.id && userProjects?.length > 0">
        <template v-if="userProjects?.length > 5">
          <vig-select
            :placeholder="$t('TASK_TABLE_LABEL_PROJECT') || 'Project'"
            key-search="projectName"
            key-label="projectName"
            :origin-options="userProjects"
            @on-choose="(item) => onChooseDomain('project', item)"
          />
        </template>
        <template v-else>
          <div
            v-for="project in userProjects"
            :key="project.projectId"
            class="float-left p-1"
          >
            <syn-radio
              :value="project.projectId"
              :label="project.projectName"
              @change="onChangeProject(project)"
            />
          </div>
        </template>
      </template>
      <template v-if="!subprojectDetail?.id && userSubProjects?.length > 0">
        <template v-if="userSubProjects?.length > 5">
          <vig-select
            :placeholder="
              $t('PROJECTMANAGEMENT_LABEL_SUBPROJECT') || 'Sub Project'
            "
            key-search="projectName"
            key-label="projectName"
            :origin-options="userSubProjects"
            @on-choose="(item) => onChooseDomain('subProject', item)"
          />
        </template>
        <template v-else>
          <div
            v-for="project in userSubProjects"
            :key="project.projectId"
            class="float-left p-1"
          >
            <syn-radio
              :value="project.projectId"
              :label="project.projectName"
              :name="project.projectName"
              @change="onChangeSubProject(project)"
            />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
