import BaseRequest from '@/base/api/base-request';
import { CalendarsDayoffFilter } from '@/domain/entities/calendars/CalendarsOverviewFiltersClass';
import { AxiosInstance } from 'axios';

export default class DayoffRepository {
    private static instance: DayoffRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): DayoffRepository {
        if (!DayoffRepository.instance) {
            // Get from local storage
            DayoffRepository.instance = new DayoffRepository();
        }

        return DayoffRepository.instance;
    }

    getListDayoffs(data: CalendarsDayoffFilter): Promise<any> {
        return this.request({
            url: `Dayoff/Search`,
            method: 'post',
            data,
        });
    }
    getDetailById(id): Promise<any> {
        return this.request({
            url: `Dayoff/GetDetail?id=${id}`,
            method: 'get',
        });
    }
    createRequest(data): Promise<any> {
        return this.request({
            url: `/DayOff/CreateRequest`,
            method: 'post',
            data,
        });
    }

    modifyRequest(data): Promise<any> {
        return this.request({
            url: `/DayOff/UpdateRequest`,
            method: 'post',
            data,
        });
    }
    remove(id): Promise<any> {
        return this.request({
            url: `/DayOff/DeleteRequest?requestId=${id}`,
            method: 'post',
        });
    }

    getListByStatus(data): Promise<any> {
        return this.request({
            url: `/DayOff/GetByStatus`,
            method: 'post',
            data,
        });
    }
    getMyActiveRequest(year): Promise<any> {
        return this.request({
            url: `/DayOff/GetMyActiveRequest${year ? '?year=' + year : ''}`,
            method: 'get',
        });
    }
    acceptRequest(id): Promise<any> {
        return this.request({
            url: `/DayOff/AcceptRequest?requestId=${id}`,
            method: 'post',
        });
    }
    rejectRequest(id, data): Promise<any> {
        return this.request({
            url: `/DayOff/RejectRequest?requestId=${id}`,
            method: 'post',
            data,
        });
    }

    cancelRequest(id): Promise<any> {
        return this.request({
            url: `/DayOff/CancelRequest?requestId=${id}`,
            method: 'post',
        });
    }
    agreeUnusedRequest(id): Promise<any> {
        return this.request({
            url: `/DayOff/AgreeUnusedRequest?requestId=${id}`,
            method: 'post',
        });
    }
    disagreeUnusedRequest(id): Promise<any> {
        return this.request({
            url: `/DayOff/DisagreeUnusedRequest?requestId=${id}`,
            method: 'post',
        });
    }
    reopenRequest(id): Promise<any> {
        return this.request({
            url: `/DayOff/ReopenRequest?requestId=${id}`,
            method: 'post',
        });
    }
    closeRequest(id): Promise<any> {
        return this.request({
            url: `/DayOff/CloseRequest?requestId=${id}`,
            method: 'post',
        });
    }
    deleteRequest(id): Promise<any> {
        return this.request({
            url: `/DayOff/DeleteRequest?requestId=${id}`,
            method: 'post',
        });
    }
    genDateOffRange(data): Promise<any> {
        return this.request({
            url: `/DayOff/GenDateOffRange`,
            method: 'post',
            data,
        });
    }
    getMembersOffWorkByDate(date): Promise<any> {
        return this.request({
            url: `/DayOff/GetMembersOffWorkByDate?date=${date}`,
            method: 'get',
        });
    }
    getDayoffUsedByUser(fromDate, toDate, userId): Promise<any> {
        return this.request({
            url: `/DayOff/GetDayoffUsedByUser?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}`,
            method: 'get',
        });
    }
    getStatisticByUserAndRange(fromDate, toDate, userId): Promise<any> {
        return this.request({
            url: `/DayOff/GetStatisticByUserAndRange?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}`,
            method: 'get',
        });
    }
}
