<script setup lang="ts">
import { computed } from 'vue';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';

const _permissionStore = permissionStore();

const allSystemConfigs = computed(() => _permissionStore.allSystemConfigs);

const deadlineLocking = computed(() => {
  return {
    enabled:
      allSystemConfigs.value[
        systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED
      ] == '1',
    by: allSystemConfigs.value[
      systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_BY
    ]
      ?.split(';')
      .reduce((obj, key) => {
        obj[key] = true;
        return obj;
      }, {}),
  };
});

const onDeadlineLockingEnabledChange = () => {
  _permissionStore.setPermissions({
    key: systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED,
    value: deadlineLocking.value?.enabled ? '0' : '1',
  });
};

const onDeadlineLockingByChange = () => {
  const lockingBy = Object.entries(deadlineLocking.value.by)
    .filter(([key, isChecked]) => key == 'OWNER_ADMIN' || isChecked)
    .map(([key]) => key)
    .join(';');

  _permissionStore.setPermissions({
    key: systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_BY,
    value: lockingBy,
  });
};
</script>
<template>
  <MenuSettingRowItem
    name-icon="LockDeadline"
    :title-menu="$t('SETTING_DEADLINE_LOCKING_ENABLE_LOCK')"
    :sub-title="$t('SETTING_DEADLINE_LOCKING_ENABLE_LOCK_TITLE')"
  >
    <template #action>
      <AtomSwitch
        :model-value="deadlineLocking?.enabled"
        @change="onDeadlineLockingEnabledChange"
      />
    </template>
    <template #subMenu>
      <div
        v-if="deadlineLocking?.enabled"
        class="px-8 py-2 border-t border-dashed"
      >
        <div class="font-medium text-gray-800">
          {{ $t('SETTING_DEADLINE_LOCKING_CHOOSE_HAVING_PERMISSION_PERSONS') }}
        </div>
        <div class="text-xs text-gray-600 mb-2">
          {{
            $t(
              'SETTING_DEADLINE_LOCKING_CHOOSE_HAVING_PERMISSION_PERSONS_DESCRIPTION'
            )
          }}
        </div>
        <VigCheckbox
          v-model="deadlineLocking.by['OWNER_ADMIN']"
          disabled
          @on-change="onDeadlineLockingByChange"
        >
          <span class="text-sm">
            {{ $t('COMMON_LABEL_ADMIN_OR_OWNER') }}
          </span>
        </VigCheckbox>
        <VigCheckbox
          v-model="deadlineLocking.by['CREATOR']"
          @on-change="onDeadlineLockingByChange"
        >
          <span class="text-sm">
            {{ $t('TASK_TABLE_LABEL_CREATOR') }}
          </span>
        </VigCheckbox>
        <VigCheckbox
          v-model="deadlineLocking.by['ASSIGNEE']"
          @on-change="onDeadlineLockingByChange"
        >
          <span class="text-sm">
            {{ $t('COMMON_LABEL_ASSIGNEE') }}
          </span>
        </VigCheckbox>
      </div>
    </template>
  </MenuSettingRowItem>
</template>
