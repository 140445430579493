<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';
// import { formatDate } from '@/ui/hooks/calendarHook.ts';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
// import { TaskUrgency } from '@/ui/hooks/taskHook';
// import myProfileStore from '@/store/auth/my-profile';
// import { CalendarType } from '@/ui/common/constants/calendar';
import {
  colorByAlphabet,
  convertViToEn,
  cleanString,
} from '@/ui/common/molecules/SynAvatar/colorByAlphabet';
import { DayoffType, DayoffExceptionType } from '@/domain/enums/DayoffEnums';
import { DayoffDetailItemClass } from '@/domain/entities/dayoff/DayoffCalendarItemClass';
// import { DayoffTypeUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import { DayoffStatusUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { getDayOffTitle } from '@/ui/components/calendars/event-item/dayoff-render-component';

dayjs.extend(utc);
dayjs.extend(tz);
const props = defineProps<{
  data: DayoffDetailItemClass;
  iconComponent: any;
  options?: any;
}>();

// const utcDate = computed(() => myProfileStore().getTimezone);

// const timezone = computed(() => {
//   if (utcDate.value == '+7') return 'Asia/Bangkok';
//   if (utcDate.value == '-5') return 'America/New_York';
//   else return 'Europe/Paris';
// });

const textAva = computed(() => {
  // if (!props?.data?.userId) return 'A';
  // return '';
  return cleanString(props?.data?.userName)
    .split(' ')
    [cleanString(props?.data?.userName).split(' ').length - 1].charAt(0);
});
// const time = computed(() =>
//   dayjs(props.startDate).tz(timezone.value).format('hh:mm A')
// );
const dayoffTitle = computed(() =>
  getDayOffTitle(props.data.type, props.data?.exception?.type)
);
</script>

<template>
  <div
    class="
      flex
      justify-between
      items-center
      space-x-1
      px-1.5
      p-2
      rounded
      cursor-pointer
      w-full
      text-xs
    "
    :style="[
      {
        backgroundColor: DayoffStatusUI[data?.status]
          ? DayoffStatusUI[data?.status].backgroundColor
          : '',
      },
      {
        background: DayoffStatusUI[data?.status]
          ? DayoffStatusUI[data?.status].backgroundColor
          : '',
      },
    ]"
  >
    <div v-if="!options?.isShowIconOnly" class="flex-center space-x-2 truncate">
      <div>
        <div
          v-if="data?.userAvatar"
          class="rounded-full overflow-hidden w-5 h-5 shadow-sm flex-center"
        >
          <img
            class="w-5 h-5 object-cover rounded-full"
            :src="data?.userAvatar"
          />
        </div>
        <div
          v-else
          class="
            object-cover
            flex-center
            text-white
            shadow
            font-thin
            rounded-full
            w-5
            h-5
          "
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
        >
          <span v-if="textAva" class="uppercase" style="font-size: 0.75em">{{
            textAva
          }}</span>
        </div>
      </div>

      <!-- <span class="text-gray-800"> {{ data?.userName }} Xin nghỉ </span> -->
      <span
        class="truncate font-medium"
        :style="{
          color: DayoffStatusUI[data?.status]
            ? DayoffStatusUI[data?.status].color
            : '',
        }"
        :title="dayoffTitle"
      >
        {{ dayoffTitle }}
      </span>
    </div>
    <!-- UGENTCY -->
    <div class="flex-center flex-center rounded-full">
      <!--      <component-->
      <!--        :is="iconComponent"-->
      <!--        class="w-3 h-3"-->
      <!--        :style="{-->
      <!--          fill: DayoffTypeUI[data?.type].color,-->
      <!--        }"-->
      <!--      >-->
      <component
        :is="iconComponent"
        class="w-3 h-3"
        :is-active="true"
        :style="{
          fill: DayoffStatusUI[data?.status]
            ? DayoffStatusUI[data?.status].color
            : '',
        }"
      >
      </component>
    </div>
  </div>
</template>
