<script setup lang="ts">
import { computed } from 'vue';
import { taskStates } from '@/ui/hooks/taskHook';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskBoardCardStatus from '@/ui/modules/task/board/board-container/TaskBoardCardStatus.vue';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = withDefaults(
  defineProps<{
    currentContactId: any;
    type: 'USER' | 'GROUP' | 'DOMAIN' | 'IA';
  }>(),
  {
    currentContactId: getCurrentUserId(),
    type: 'USER',
  }
);
const listStates = computed<ICustomTaskFilter[]>(() => {
  if (!taskStates) return [];
  const stateList = Object.values(taskStates).sort((a, b) => {
    return a.index - b.index;
  });
  return stateList?.map((o, index) => {
    return {
      id: o.key,
      name: translate(o.nameCode),
      index,
      color: o.colorHex,
      visible: true,
      filterObject: {
        isDefault: false,
        listOwnerId: props.currentContactId,
        assignorId: null,
        urgency: null,
        keyword: null,
        groupIds: null,
        domainId: null,
        domainIds: null,
        creatorIds: null,
        assigneeIds: null,
        createdFromDate: null,
        createdToDate: null,
        updatedFromDate: null,
        updatedToDate: null,
        status: `${o.key}`,
      },
    };
  });
});
</script>

<template>
  <div
    v-drag-to-scroll="{ targetClass: 'drag-to-scroll-target' }"
    class="h-full flex overflow-auto"
  >
    <template v-for="card in listStates" :key="card">
      <TaskBoardCardStatus
        class="text-xs mx-1.5"
        :type="type"
        :card-id="card?.id"
        :card-information="card"
        :source-id="currentContactId"
      />
    </template>
  </div>
</template>
