<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { isImage } from '@/ui/hooks/fileHook';

import taskStore from '@/ticket/store/task-store';
import { allowedExtensionString } from '@/ui/modules/task/task-global-state';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import noteLogic from '@/ui/modules/task/general/note-logic';
import attachmentLogic from '@/ui/modules/task/general/attachment-logic';
import audioLogic from '@/ui/modules/task/general/audio-logic';
import TicketGroupById from '@/ticket/modules/user/TicketGroupById.vue';
import { TicketSingleton } from '@/ticket/services/organization-service';
import organizationStore from '@/ticket/store/organization-store';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ticketPlugin from '@/ticket/services/plugin-services';
import { TicketPayloadClass } from '@/ticket/domain/TicketPayload';
import { activeState } from '@/ticket/pages/layouts/ticket-layout-state';
import { TaskLife } from '@/domain/enums/taskEnum';
import { isValidEmail } from '@/ui/hooks/commonFunction';

const _taskStore = taskStore();
const _organizationStore = organizationStore();
const formData = ref(
  new TicketPayloadClass({
    isTicket: true,
    taskLife: TaskLife.Todo,
    important: true,
    priority: 1,
    creatorId: TicketSingleton.getInstance().userId,
    domainId: TicketSingleton.getInstance().domainId,
    groupId: TicketSingleton.getInstance().groupId,
    externalRootUID: TicketSingleton.getInstance().externalRootUID,
    externalUID: TicketSingleton.getInstance().externalUID,
    externalFirstName: TicketSingleton.getInstance().externalFirstName,
    externalLastName: TicketSingleton.getInstance().externalLastName,
    assigneeId: 1103, // Bertrand - Need to replace when clear the business
    externalEmail: TicketSingleton.getInstance().externalEmail,
  }) as TicketPayloadClass
);

const {
  isCreateNote,
  currentNote,
  isLoadingDrawData,
  onSaveNote,
  onCreateNote,
  onCancelNote,
  onRemoveNote,
  onEditNote,
} = noteLogic(formData);

const isDraging = ref(false);
const {
  addFiles,
  onChooseFiles,
  attachedFiles,
  attachedAudios,
  onRemoveAttachments,
} = attachmentLogic(formData, isDraging);

const { isOpenRecording, onCreateAudioDone, onCreateAudioCancel } =
  audioLogic(formData);

const onPasteImage = async (event) => {
  if (isCreateNote.value) {
    return;
  }
  var files = (event.clipboardData || event.originalEvent.clipboardData).files;
  // 1. if image file => open note
  if (files?.length == 1 && files[0].type.includes('image')) {
    onCreateNote(true, files);
    return;
  }
  // 2. If not image file => attachment
  if (files?.length > 0) {
    addFiles(files, null);
  }
};
onMounted(() => {
  initData();
});

watch(
  () => activeState.value,
  (value) => {
    if (value !== 'opened') return;
    checkFocus();
  }
);

const isValidate = ref(false);

const isHighPriority = ref(false);
const onChangePriority = (value) => {
  formData.value.changePriority(value);
};

const onResetForm = () => {
  isValidate.value = false;
  formData.value = new TicketPayloadClass({
    isTicket: true,
    important: true,
    priority: 1,
    creatorId: TicketSingleton.getInstance().userId,
    domainId: TicketSingleton.getInstance().domainId,
    groupId: TicketSingleton.getInstance().groupId,
    externalRootUID: TicketSingleton.getInstance().externalRootUID,
    externalUID: TicketSingleton.getInstance().externalUID,
    externalFirstName: TicketSingleton.getInstance().externalFirstName,
    externalLastName: TicketSingleton.getInstance().externalLastName,
  });
  isHighPriority.value = false;
  onChangePriority(isHighPriority.value);
};

const isCreatingLoading = ref(false);
const isResetForm = ref(false);

const errorMessageName = computed(() =>
  !formData.value?.name
    ? translate('TICKET_TASK_FORM_LABEL_SHORT_DESCRIPTION_REQUIRE') ||
      'Short description is require'
    : ''
);
const errorMessageDomain = computed(() =>
  !formData.value?.projectId
    ? translate('TICKET_TASK_FORM_LABEL_TYPE_OR_CATEGORY_REQUIRE') ||
      'Type or category is require'
    : ''
);
const successMessage = ref('');
const openNotification = (taskCode) => {
  successMessage.value = translate('TICKET_TASK_FORM_LABEL_CREATE_SUCCESS', {
    taskCode: formatTaskCode(taskCode),
  });

  setTimeout(() => {
    successMessage.value = '';
  }, 4 * 1000);
};

const nameInputRef = ref(null as any);

const onSubmitForm = async () => {
  if (
    errorMessageName.value ||
    errorMessageDomain.value ||
    errorMessageFirstName.value ||
    formData.value?.urgency == null ||
    formData.value?.taskSchedule?.scheduleOptionKey == null ||
    isCreatingLoading.value ||
    (formData.value?.externalEmail &&
      !isValidEmail(formData.value?.externalEmail))
  ) {
    isValidate.value = true;
    return;
  }
  isValidate.value = false;
  try {
    isCreatingLoading.value = true;
    formData.value = {
      ...formData.value,

      name: `${formData.value.name} - (${formData.value?.externalFirstName} ${formData.value?.externalLastName})`,
    };

    const newTask = await _taskStore.create(formData.value);
    if (!newTask) return;
    nameInputRef.value && nameInputRef.value.focus();
    TicketSingleton.setExternalUser(
      null,
      formData.value?.externalFirstName,
      formData.value?.externalLastName
    );

    onResetForm();
    isCreatingLoading.value = false;

    openNotification(newTask.code);
  } catch (error) {
    console.log(error);
    isCreatingLoading.value = false;
  }
};

const isPreview = ref(false);
const currentFileIndex = ref(0);
const onOpenFiles = (index) => {
  currentFileIndex.value = index;
  isPreview.value = true;
};

const onCollapseNote = () => {
  ticketPlugin.collapseNote();
};

const reportFirstNameRef = ref<any>(null);
const reportLastNameRef = ref<any>(null);

const errorMessageFirstName = computed(() => {
  if (!formData.value?.externalFirstName && !formData.value?.externalLastName)
    return;
  translate('TICKET_TASK_FORM_LABEL_NAME_REQUIRE') || 'Name is require';
  if (
    formData.value?.externalEmail &&
    !isValidEmail(formData.value?.externalEmail)
  )
    return translate('COMMON_INVALID_EMAIL') || 'Email is require';

  return '';
});
const groupId = computed(() => TicketSingleton.getInstance().groupId);

const projectList = computed(() => _organizationStore.projects);

const initData = () => {
  formData.value.externalFirstName =
    TicketSingleton.getInstance().externalFirstName;
  formData.value.externalLastName =
    TicketSingleton.getInstance().externalLastName;
  checkFocus();
};

const checkFocus = () => {
  if (!formData.value || !formData.value?.name) nameInputRef.value?.focus();
  if (!formData.value.externalLastName) reportLastNameRef.value?.focus();
  if (!formData.value.externalFirstName) reportFirstNameRef.value?.focus();
};
const onCancelForm = () => {
  ticketPlugin.collapse();
};
</script>
<template>
  <div
    v-if="!isResetForm"
    class="
      w-full
      h-full
      overflow-y-auto
      flex flex-col
      text-sm
      bg-white
      relative
    "
    style="max-width: 50rem; min-width: 410px"
    @paste="onPasteImage"
  >
    <div
      class="
        flex-1
        min-h-0
        overflow-y-auto
        small-scrollbar
        p-4
        flex flex-col
        space-y-4
      "
    >
      <div class="flex items-center space-x-1">
        <span> {{ $t('TICKET_HEADER_1') }} </span>

        <span class="font-semibold">
          <TicketGroupById :id="groupId" is-hidden-avatar />
        </span>
        <span>{{ $t('TICKET_HEADER_2') }} </span>

        <span>
          <SynIcon has-action name="film" :title="`Vidéo d'instructions`" />
        </span>
      </div>
      <div class="flex font-semibold">
        <TicketGroupById :id="groupId" />
      </div>
      <div class="flex justify-between items-center">
        <SynFormInput
          class="w-full"
          :error-message="isValidate && errorMessageFirstName"
        >
          <div class="w-full flex justify-between items-center">
            <div class="flex-center space-x-2">
              <SynInput
                ref="reportFirstNameRef"
                v-model="formData.externalFirstName"
                autofocus
                :placeholder="
                  $t('USERMANAGEMENT_TABLE_FIRST_NAME') || 'First Name'
                "
              />
              <SynInput
                ref="reportLastNameRef"
                v-model="formData.externalLastName"
                :placeholder="
                  $t('USERMANAGEMENT_TABLE_LAST_NAME') || 'Last Name'
                "
              />
              <!-- Email -->
              <SynInput
                v-model="formData.externalEmail"
                :placeholder="$t('LOGIN_FORM_EMAIL') || 'Email'"
              />
            </div>
          </div>
        </SynFormInput>
      </div>
      <!-- Name -->
      <SynFormInput :error-message="isValidate && errorMessageName">
        <SynInputArea
          ref="nameInputRef"
          v-model="formData.name"
          autofocus
          :max-length="200"
          :rows="1"
          :placeholder="
            $t('TICKET_TASK_FORM_LABEL_DESCRIPTION') || 'Short description'
          "
        />
      </SynFormInput>

      <div class="flex justify-between items-center">
        <div class="p-2 rounded">
          <!-- <SynIcon name="private" style="fill: #128f90" /> -->
          <AtomSwitch v-model="isHighPriority" @change="onChangePriority">
            <span class="text-current-800">
              {{
                $t('TICKET_TASK_FORM_LABEL_PRIORITY_HIGH') || 'High priority'
              }}
            </span>
          </AtomSwitch>
        </div>
        <SynFormInput
          class="w-max justify-end"
          :error-message="isValidate && errorMessageDomain"
        >
          <SynSelect
            v-model="formData.projectId"
            :options="projectList"
            key-field="id"
            text-field="name"
            :placeholder="
              $t('TICKET_TASK_FORM_LABEL_TYPE_OR_CATEGORY') || 'Select type'
            "
          />
        </SynFormInput>
      </div>
      <!-- Desciption -->
      <div class="w-full flex flex-col">
        <div class="relative">
          <SynInputArea
            v-model="formData.description"
            style-name="max-height: 500px"
            class-name="bg-gray-100"
            :placeholder="
              $t('TICKET_TASK_FORM_LABEL_DETAILED_COMMENTARY') ||
              'Detailed commentary'
            "
            :rows="3"
          />
        </div>
      </div>
      <!-- Files -->
      <div class="w-full flex items-center h-10">
        <div class="flex-center">
          <div>
            <label
              for="task-detail_attachment"
              class="
                flex-center
                space-x-2
                cursor-pointer
                hover:bg-current-100
                px-2
                py-1
                rounded-md
              "
              :title="
                $t('TASK_CREATE_FORM_LABEL_ADD_ATTACHMENT') || 'Add attachment'
              "
            >
              <SynIcon name="attach" custom-class="fill-blue-500 h-4 w-4" />

              <span> {{ $t('TASK_CREATE_FORM_FILE') || 'Files' }} </span>
            </label>
            <input
              id="task-detail_attachment"
              type="file"
              multiple
              class="hidden opacity-0"
              :accept="allowedExtensionString"
              @change="onChooseFiles"
            />
          </div>
          <div
            class="
              flex-center
              space-x-2
              cursor-pointer
              hover:bg-current-100
              px-2
              py-1
              rounded-md
            "
            :title="$t('TICKET_TASK_FORM_LABEL_SKETCH') || 'Sketch'"
            @click="onCreateNote(false)"
          >
            <SynIcon
              name="noter"
              is-active
              custom-class="fill-orange-500 h-4 w-4"
            />
            <span>
              {{ $t('TICKET_TASK_FORM_LABEL_SKETCH') || 'Sketch' }}
            </span>
          </div>

          <!-- <SynIcon
              :title="$t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'"
              is-active
              name="microphone"
              color="blue"
              has-action
              @click="isOpenRecording = true"
            /> -->
        </div>
      </div>
      <div class="w-full items-center flex flex-wrap">
        <UploadPreview
          v-for="(file, index) in attachedFiles"
          :key="file"
          :file="file"
          @on-select="onOpenFiles(index)"
          @remove="onRemoveAttachments(file)"
        />
        <UploadPreview
          v-for="(file, index) in formData.notes?.filter((o) => !o?.isDeleted)"
          :key="index"
          is-note
          :file="file"
          @on-select="
            onEditNote(
              file,
              attachedAudios.filter((o) => o?.noteAudioId)
            )
          "
          @remove="onRemoveNote(file)"
        />
      </div>
    </div>
    <div class="flex items-center justify-between px-4 py-2 w-full bg-white">
      <syn-button
        :label="$t('COMMON_LABEL_RESET')"
        class="bg-gray-100"
        color="gray"
        type-text
        name-icon="reload"
        @click.stop="onResetForm()"
      />
      <span
        v-if="successMessage"
        class="
          font-semibold
          text-current-800
          bg-current-100
          rounded-md
          px-4
          py-2
        "
      >
        {{ successMessage }}
      </span>
      <div class="flex-center space-x-2">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          color="gray"
          type-text
          @click="onCancelForm"
        />
        <SynButton
          :title="$t('COMMON_LABEL_CREATE')"
          :is-loading="isCreatingLoading"
          :label="$t('COMMON_LABEL_CREATE') || 'Create'"
          class="w-40"
          @click="onSubmitForm"
        />
      </div>
    </div>
    <!-- END -->
  </div>

  <CreateNoteModal
    v-if="isCreateNote"
    :draw-note-data="{
      ...currentNote,
      isLoading: isLoadingDrawData,
      images: attachedFiles.filter((o) => isImage(o)) || [],
      originFile: '',
      readonly: false,
    }"
    is-hidden-close
    @on-close="onCancelNote"
    @on-save="onSaveNote"
    @on-collapse="onCollapseNote"
  >
  </CreateNoteModal>

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onCreateAudioDone"
    @on-cancel="onCreateAudioCancel"
  />

  <ModalMultipleFileViewer
    v-if="isPreview"
    :start-index="currentFileIndex"
    :files="attachedFiles"
    @on-close="isPreview = false"
  />
</template>
