<script setup lang="ts">
import ModalDownloadFiles from '@/ui/modules/ged/download/ModalDownloadFiles.vue';
import { ref } from 'vue';
import {
  attachmentToTaskGlobal,
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
} from '@/ui/modules/task/task-global-state';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { canDownload, isFolder } from '@/ui/helpers/file-utils';
import { getDomainUrl } from '@/ui/hooks/commonHook';

const props = withDefaults(
  defineProps<{
    files: any[];
    trigger: string;
    placement: string;
    arrow: boolean;
    space: number;
    appendToBody: boolean;
  }>(),
  {
    trigger: 'click',
    placement: 'bottom',
    arrow: true,
    space: 10,
    appendToBody: true,
  }
);

const emit = defineEmits(['onComplete']);

const taskFilesToDownload = ref<any[]>();

let isCreateNewTask: boolean;
let taskDescription: string;

const onCreateNewTaskClick = () => {
  if (!props.files?.length) return;

  isCreateNewTask = true;
  taskDescription = '';

  _processFiles(props.files);

  // If no file and only text, create task
  if (!taskFilesToDownload.value?.length && taskDescription) {
    _openTaskCreate(taskDescription, []);
  }
};

const onSendToExistedTaskClick = () => {
  if (!props.files?.length) return;

  isCreateNewTask = false;
  taskDescription = '';

  _processFiles(props.files);

  // If no file and only text, open find task
  if (!taskFilesToDownload.value?.length && taskDescription) {
    _openTaskFindAndUpdate(taskDescription, []);
  }
};

const onTaskFilesDownloaded = (files: any[]) => {
  console.log('🚀 Tictop ~ files:', files?.length);
  if (!files?.length) return;

  if (isCreateNewTask) {
    _openTaskCreate(taskDescription, files);
  } else {
    _openTaskFindAndUpdate(taskDescription, files);
  }
};

const _processFiles = (files: any[]) => {
  // Process non-download files
  const filesNotDownload = files?.filter(
    (file) => !canDownload(file?.contentType)
  );

  let texts: any[] = [];
  filesNotDownload?.forEach((file) => {
    if (isFolder(file?.contentType)) {
      const folderLink = `${getDomainUrl()}/resources?folder=${file?.id}`;

      texts.push(
        `<a target="_blank" rel="noopener noreferrer" href="${folderLink}">${
          file?.name || 'Folder'
        }</a>`
      );
    } else if (file?.link) {
      texts.push(
        `<a target="_blank" rel="noopener noreferrer" href="${file.link}">${
          file?.name || 'Link'
        }</a>`
      );
    }
  });

  taskDescription = texts.join(', ');
  if (taskDescription) taskDescription = `<p>${taskDescription}</p>`;

  // Process download files
  taskFilesToDownload.value = files?.filter((file) =>
    canDownload(file?.contentType)
  );
};

const _openTaskCreate = (description: string, files: any[]) => {
  newTaskDefaultPayload.value = {
    assigneeId: getCurrentUserId(),
    files,
    description,
  };

  isOpenCreateTaskDrawer.value = true;

  emit('onComplete');
};

const _openTaskFindAndUpdate = (description: string, files: any[]) => {
  attachmentToTaskGlobal.value = {
    isOpen: true,
    attachments: files,
    description,
  };

  emit('onComplete');
};
</script>

<template>
  <VigDropdown
    :trigger="trigger"
    :placement="placement"
    :arrow="arrow"
    :space="space"
    :append-to-body="appendToBody"
  >
    <template #dropdown-toggle>
      <slot />
    </template>
    <template #dropdown-menu>
      <div class="w-max text-sm">
        <VigButton
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="onCreateNewTaskClick"
        >
          <SynIcon name="TaskAdd" />
          <span class="font-normal">{{
            $t('CHAT_CREATE_NEW_TASK_WITH_THIS_FILE')
          }}</span>
        </VigButton>
        <VigButton
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="onSendToExistedTaskClick"
        >
          <SynIcon name="TaskEdit" />
          <span class="font-normal">{{
            $t('CHAT_MOVE_THIS_FILE_TO_A_TASK')
          }}</span>
        </VigButton>
      </div>
    </template>
  </VigDropdown>

  <ModalDownloadFiles
    v-if="taskFilesToDownload?.length"
    :files="taskFilesToDownload"
    @on-close="
      taskFilesToDownload = [];
      onTaskFilesDownloaded($event);
    "
  />
</template>

<style scoped></style>
