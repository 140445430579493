<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g id="link3" transform="translate(-2 -2)">
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="14"
        cy="14"
        r="14"
        transform="translate(2 2)"
        fill="#E5E7EB"
      />
      <g id="Group_1" data-name="Group 1">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M12.937,22.7a3.625,3.625,0,0,0,2.572-1.064l1.915-1.914a.93.93,0,0,0-1.315-1.315L14.194,20.32a1.778,1.778,0,0,1-2.514-2.514l1.915-1.915a.93.93,0,0,0-1.315-1.315l-1.915,1.915A3.635,3.635,0,0,0,12.937,22.7Z"
          fill="currentColor"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M21.635,10.365a3.641,3.641,0,0,0-5.143,0L14.577,12.28a.93.93,0,1,0,1.315,1.315l1.915-1.915a1.778,1.778,0,0,1,2.514,2.514l-1.914,1.915a.93.93,0,1,0,1.315,1.315l1.914-1.915A3.641,3.641,0,0,0,21.635,10.365Z"
          fill="currentColor"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M18.572,13.428a.929.929,0,0,0-1.315,0l-3.829,3.829a.93.93,0,1,0,1.315,1.315l3.829-3.829a.929.929,0,0,0,0-1.315Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
