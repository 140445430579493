<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M436.3,75.7C388,27.401,324.101,0,256,0C115.343,0,0,115.116,0,256c0,140.958,115.075,256,256,256			c140.306,0,256-114.589,256-256C512,187.899,484.6,123.999,436.3,75.7z M256,451c-107.786,0-195-86.985-195-195			c0-42.001,13.2-81.901,37.5-114.901l272.401,272.1C337.899,437.8,298.001,451,256,451z M413.2,370.899L141.099,98.5			C174.101,74.2,213.999,61,256,61c107.789,0,195,86.985,195,195C451,297.999,437.8,337.899,413.2,370.899z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
