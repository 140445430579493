<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import userStore from '@/store/user';
import MemberUpdateGroupModal from '@/ui/modules/member/detail/MemberUpdateGroupModal.vue';
import groupStore from '@/store/group';
import MyGroups from '@/ui/components/group/MyGroups.vue';

const props = defineProps<{
  userId: number;
}>();

const _groupStore = groupStore();
const allUserDetailByIds = computed<any>(() => {
  return _userStore.allUserDetailByIds;
});

const loadingData = reactive<{
  init: boolean;
}>({ init: true });

const allUserGroups = ref([] as any[]);
const initComponent = async () => {
  if (
    allUserDetailByIds.value &&
    Object.prototype.hasOwnProperty.call(allUserDetailByIds.value, props.userId)
  ) {
    const userDetail = allUserDetailByIds.value[props.userId];

    allUserGroups.value = userDetail.groups;
  } else {
    loadingData.init = true;
    const result = await _groupStore.getAllUserMemberGroups(props.userId);
    allUserGroups.value = result;
    _userStore.allUserDetailByIds = {
      ..._userStore.allUserDetailByIds,
      [props.userId]: {
        ..._userStore.allUserDetailByIds[props.userId],
        groups: result,
      },
    };
  }
  loadingData.init = false;
};

const _userStore = userStore();

const isEditGroup = ref(false);

const onUpdateGroup = async (value) => {
  if (value) {
    const groups = await _groupStore.getAllUserMemberGroups(props.userId);
    _userStore.allUserDetailByIds[props.userId].groups = groups;
    allUserGroups.value = groups;
  }
};

initComponent();
</script>
<template>
  <div v-permission-model class="w-1/2 flex flex-col h-full">
    <div class="flex flex-1 flex-col h-2/3">
      <div
        class="
          flex
          bg-current-50
          justify-between
          p-2
          pl-4
          rounded-t-md
          items-center
        "
      >
        <p class="font-semibold text-current-500 py-1">
          {{ $t('COMMON_MODULE_GROUP_MANAGEMENT') }}
        </p>
        <div
          v-vig-tooltip="$t('COMMON_LABEL_EDIT')"
          class="
            hover:bg-current-100
            p-2
            rounded-full
            flex
            items-center
            cursor-pointer
            justify-center
          "
          @click="isEditGroup = true"
        >
          <SynIcon name="edit" class="fill-current" is-active />
        </div>
      </div>
      <div
        class="
          h-full
          border
          bg-white
          p-2
          border-gray-100
          text-current-600
          overflow-y-auto
          small-scrollbar
          justify-between
          rounded-b-md
        "
      >
        <div v-if="loadingData.init" class="flex-center space-x-3">
          <div class="p-2 w-16 bg-gray-200 animate-pulse rounded-full"></div>
          <div class="p-2 w-full bg-gray-200 animate-pulse rounded-full"></div>
        </div>
        <MyGroups v-else :groups="allUserGroups" />
      </div>
    </div>
  </div>

  <MemberUpdateGroupModal
    v-if="isEditGroup"
    :user-id="userId"
    :group-members="allUserGroups"
    @on-cancel="isEditGroup = false"
    @on-update-success="onUpdateGroup"
  />
</template>
