import { ref, onUnmounted } from 'vue';

export default function listenFirebaseRealtime() {
    const unsubscribeCallbacks = ref<Function[]>([]);
    onUnmounted(() => {
        unsubscribeAll();
    });

    const latestActionTime = ref(Date.now());
    let timeoutToExecute;
    const delayTime = {
        delayUpdate: 1000,
        maxDelayUpdate: 10000,
    };

    const handleDelayExecuteSomeThings = (actionTime, callback) => {
        latestActionTime.value = actionTime;
        const isWithinLimitExtendTime =
            actionTime &&
            Date.now() - actionTime - delayTime.delayUpdate <
                delayTime.maxDelayUpdate;
        if (timeoutToExecute && isWithinLimitExtendTime)
            clearTimeout(timeoutToExecute);

        timeoutToExecute = setTimeout(() => {
            if (callback && typeof callback == 'function') callback();
        }, delayTime.maxDelayUpdate);
    };

    const unsubscribeAll = () => {
        if (unsubscribeCallbacks.value?.length > 0) {
            unsubscribeCallbacks.value.forEach((unsubscribe) => {
                if (unsubscribe && typeof unsubscribe == 'function')
                    unsubscribe();
            });
        }
    };

    return {
        unsubscribeCallbacks,
        handleDelayExecuteSomeThings,
        unsubscribeAll,
    };
}
