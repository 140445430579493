<script setup>
import { onMounted, ref } from 'vue';
import { useTextareaAutosize } from '@vueuse/core';
import { translate } from '@/ui/plugins/i18n/myi18n';
import $constants from '@/ui/plugins/constants';

const props = defineProps({
  file: {
    type: Object,
    default: null,
  },
  inputClass: {
    type: String,
    default: 'leading-5',
  },
  hideButtons: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onCancel', 'onRename']);

const { textarea, input } = useTextareaAutosize();

const showErrorMsg = ref();

onMounted(() => {
  input.value = props.file?.name;

  setTimeout(() => {
    if (textarea.value) {
      textarea.value.style.height = 'auto';
      textarea.value.style.height = `${textarea.value.scrollHeight}px`;

      textarea.value.focus();
    }
  });
});

const onRenameInputFocus = (event) => {
  if (
    props.file?.contentType === $constants.FILE.TYPE.FOLDER ||
    props.file?.contentType === $constants.FILE.TYPE.LINK
  ) {
    return event.target.setSelectionRange(0, input.value?.length);
  }

  event.target.setSelectionRange(0, input.value?.lastIndexOf('.'));
};

const onRenameInputBlur = () => {
  if (props.hideButtons) onRenameSubmit();
  // console.log('File: FileRename.vue - L: 53 - ', event.target);
  // else emit('onCancel');
};

const onRenameInputPressEnter = (event) => {
  if (props.hideButtons) {
    event.target.blur();
  } else {
    onRenameSubmit();
  }
};

const onRenameSubmit = async () => {
  const fileName = input.value?.trim();

  if (!fileName) {
    return (showErrorMsg.value = translate('GED_FILE_NAME_REQUIRED_MSG'));
  }

  const limitChars = 200;
  if (fileName?.length > limitChars) {
    return (showErrorMsg.value = translate('GED_FILE_NAME_MAX_LENGTH_MSG', {
      number: limitChars,
    }));
  }

  const specialChars = /[<>:"\\/|?*]/;
  if (specialChars.test(fileName)) {
    return (showErrorMsg.value = translate('GED_FILE_NAME_SPECIAL_CHARS_MSG'));
  }

  if (fileName === props.file?.name) return emit('onCancel');

  emit('onRename', fileName);
};

const onErrorModalClose = () => {
  showErrorMsg.value = null;

  textarea.value?.focus();
};
</script>

<template>
  <!--  <div class="">-->
  <div class="relative">
    <vig-dropdown
      placement="right-end"
      hide-on-click="outside"
      auto-open
      :space="0"
      :arrow="false"
      :append-to-body="false"
      @on-dropdown-close="$emit('onCancel')"
    >
      <template #dropdown-toggle>
        <textarea
          ref="textarea"
          v-model="input"
          rows="1"
          class="
            vig-input
            flex-1
            px-2
            py-1
            break-all
            overflow-hidden
            resize-y
            min-h-full
            relative
          "
          :class="inputClass"
          @focus="onRenameInputFocus"
          @blur="onRenameInputBlur"
          @keydown.enter.prevent="onRenameInputPressEnter"
          @keydown.esc="$emit('onCancel')"
        />
      </template>
      <template #dropdown-menu>
        <div v-if="!hideButtons" class="p-1 flex-center space-x-2">
          <vig-button color="red" padding="p-1" @click="$emit('onCancel')">
            <SynIcon name="Close" />
          </vig-button>
          <vig-button color="green" padding="p-1" @click="onRenameSubmit">
            <SynIcon name="Check" />
          </vig-button>
        </div>
      </template>
    </vig-dropdown>
  </div>
  <!--  </div>-->

  <syn-question-modal
    v-if="showErrorMsg"
    z-index="z-50"
    :visible="true"
    :header-text="$t('COMMON_LABEL_ALERT')"
    :content-text="showErrorMsg"
    :show-cancel-btn="false"
    :show-confirm-btn="true"
    :show-delete-btn="false"
    @on-confirm="onErrorModalClose"
    @cancel="onErrorModalClose"
  />
</template>
