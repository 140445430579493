import { ISecurityService } from '@/domain/interfaces/services/ISecurityService';
import SecurityRepository from '@/application/repositories/SecurityRepository';
export default class SecurityService implements ISecurityService {
    private static instance: SecurityService;
    _secutityRepository: SecurityRepository;
    constructor() {
        this._secutityRepository = SecurityRepository.getInstance();
    }

    public static getInstance(): SecurityService {
        if (!SecurityService.instance) {
            // Get from local storage
            SecurityService.instance = new SecurityService();
        }

        return SecurityService.instance;
    }

    async getBlockingStatus(): Promise<any> {
        const res = await this._secutityRepository.getBlockingStatus();
        return res['result'] || {};
    }
}
