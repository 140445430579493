<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 600.84 633.18"
  >
    <defs>
      <linearGradient
        id="b"
        x1="300.42"
        y1="558.65"
        x2="300.42"
        y2="32.66"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fcb33a" />
        <stop offset="1" stop-color="#c67c29" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="300.42"
        y1="558.65"
        x2="300.42"
        y2="32.66"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#b46928" />
        <stop offset="1" stop-color="#ffd97b" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="300.42"
        y1="558.65"
        x2="300.42"
        y2="295.66"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#d1a24d" />
        <stop offset="1" stop-color="#010101" />
      </linearGradient>
      <linearGradient
        id="e"
        x1="300.42"
        y1="295.66"
        x2="300.42"
        y2="0"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#010101" />
        <stop offset="1" stop-color="#d1a24d" />
      </linearGradient>
      <radialGradient
        id="f"
        cx="318.92"
        cy="328.49"
        fx="318.92"
        fy="328.49"
        r="331.25"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#f26322" />
        <stop offset=".38" stop-color="#f78c1e" />
        <stop offset=".7" stop-color="#febf10" />
        <stop offset=".84" stop-color="#ffc808" />
        <stop offset=".88" stop-color="#fdcc0c" />
        <stop offset=".91" stop-color="#fbd515" />
        <stop offset=".97" stop-color="#ed8b1e" />
        <stop offset="1" stop-color="#e55c25" />
      </radialGradient>
      <radialGradient
        id="g"
        cx="144.96"
        cy="429.25"
        fx="144.96"
        fy="429.25"
        r="107.85"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fbaa19" />
        <stop offset="1" stop-color="#010101" />
      </radialGradient>
      <radialGradient
        id="h"
        cx="38.7"
        fx="38.7"
        r="331.25"
        gradientTransform="translate(320.62) rotate(-180) scale(1 -1)"
        xlink:href="#f"
      />
      <radialGradient
        id="i"
        cx="-135.26"
        fx="-135.26"
        r="107.85"
        gradientTransform="translate(320.62) rotate(-180) scale(1 -1)"
        xlink:href="#g"
      />
      <radialGradient
        id="j"
        cx="129.07"
        cy="379.07"
        fx="129.07"
        fy="379.07"
        r="191.82"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".45" stop-color="#fff" />
        <stop offset=".51" stop-color="#fdf9f2" />
        <stop offset=".62" stop-color="#f8e9d0" />
        <stop offset=".77" stop-color="#f0d199" />
        <stop offset=".95" stop-color="#e6af4e" />
        <stop offset="1" stop-color="#e3a639" />
      </radialGradient>
      <radialGradient
        id="k"
        cx="89.8"
        cy="554.3"
        fx="89.8"
        fy="554.3"
        r="219.44"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".45" stop-color="#fff" />
        <stop offset=".48" stop-color="#fef3e9" />
        <stop offset=".55" stop-color="#fbcea2" />
        <stop offset=".63" stop-color="#f9b06a" />
        <stop offset=".69" stop-color="#f79b41" />
        <stop offset=".73" stop-color="#f68d28" />
        <stop offset=".76" stop-color="#f6891f" />
      </radialGradient>
      <radialGradient
        id="l"
        cx="278.26"
        cy="428.3"
        fx="278.26"
        fy="428.3"
        r="208.96"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#febc11" />
        <stop offset=".73" stop-color="#febd14" />
        <stop offset=".93" stop-color="#ffdb5d" />
        <stop offset=".95" stop-color="#fcd65b" />
        <stop offset=".96" stop-color="#f5ca56" />
        <stop offset=".97" stop-color="#eab44e" />
        <stop offset=".98" stop-color="#da9743" />
        <stop offset=".99" stop-color="#c67135" />
        <stop offset="1" stop-color="#b04725" />
      </radialGradient>
      <radialGradient
        id="m"
        cx="192.58"
        cy="462.42"
        fx="192.58"
        fy="462.42"
        r="142.58"
        xlink:href="#j"
      />
      <radialGradient
        id="n"
        cx="144.65"
        cy="609.02"
        fx="144.65"
        fy="609.02"
        r="166.91"
        xlink:href="#k"
      />
      <radialGradient
        id="o"
        cx="-14"
        fx="-14"
        r="191.82"
        gradientTransform="translate(457.77) rotate(-180) scale(1 -1)"
        xlink:href="#j"
      />
      <radialGradient
        id="p"
        cx="-53.27"
        fx="-53.27"
        gradientTransform="translate(457.77) rotate(-180) scale(1 -1)"
        xlink:href="#k"
      />
      <radialGradient
        id="q"
        cx="135.19"
        fx="135.19"
        r="208.96"
        gradientTransform="translate(457.77) rotate(-180) scale(1 -1)"
        xlink:href="#l"
      />
      <radialGradient
        id="r"
        cx="49.51"
        cy="462.42"
        fx="49.51"
        fy="462.42"
        r="142.58"
        gradientTransform="translate(457.77) rotate(-180) scale(1 -1)"
        xlink:href="#j"
      />
      <radialGradient
        id="s"
        cx="1.57"
        cy="609.02"
        fx="1.57"
        fy="609.02"
        r="166.92"
        gradientTransform="translate(457.77) rotate(-180) scale(1 -1)"
        xlink:href="#k"
      />
      <linearGradient
        id="t"
        x1=".12"
        y1="463.98"
        x2="300.42"
        y2="463.98"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff9d3" />
        <stop offset="1" stop-color="#faa71a" />
      </linearGradient>
      <linearGradient
        id="u"
        x1="300.42"
        y1="463.98"
        x2="600.73"
        y2="463.98"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#faa71a" />
        <stop offset="1" stop-color="#fff9d3" />
      </linearGradient>
      <linearGradient
        id="v"
        x1="300.42"
        y1="625.72"
        x2="300.42"
        y2="504.82"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fcb33a" />
        <stop offset="1" stop-color="#b35126" />
      </linearGradient>
      <linearGradient
        id="w"
        x1="300.42"
        y1="633.18"
        x2="300.42"
        y2="497.35"
        xlink:href="#b"
      />
      <linearGradient
        id="x"
        x1="300.42"
        y1="633.18"
        x2="300.42"
        y2="497.35"
        xlink:href="#c"
      />
      <radialGradient
        id="y"
        cx="279.77"
        cy="529.5"
        fx="279.77"
        fy="529.5"
        r="10.9"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset=".27" stop-color="#a1cff0" />
        <stop offset=".38" stop-color="#84a8cf" />
        <stop offset=".55" stop-color="#5a6fa0" />
        <stop offset=".7" stop-color="#3b457e" />
        <stop offset=".82" stop-color="#282c68" />
        <stop offset=".89" stop-color="#222361" />
      </radialGradient>
      <radialGradient
        id="a`"
        cx="264.03"
        cy="544.62"
        fx="264.03"
        fy="544.62"
        r="7.96"
        xlink:href="#y"
      />
      <radialGradient
        id="aa"
        cx="336.89"
        cy="586.5"
        fx="336.89"
        fy="586.5"
        r="7.96"
        xlink:href="#y"
      />
    </defs>
    <g class="bh">
      <g id="a">
        <g>
          <path
            class="ai"
            d="M29.49,289.22v121.85c-27.84-34.21-49.22-89.63,0-121.85h0Z"
          />
          <path
            class="ai"
            d="M571.35,289.22v121.85c27.84-34.21,49.22-89.63,0-121.85h0Z"
          />
          <path
            class="ai"
            d="M92.3,458.05l20.94,71.05c-20.01-10.16-29.07-27.42-31.46-34.52-4.19-12.47-3.51-27.34,10.52-36.53h0Z"
          />
          <g>
            <path
              class="b`"
              d="M300.42,65.33c127.21,0,230.33,103.12,230.33,230.33s-103.12,230.33-230.33,230.33-230.33-103.12-230.33-230.33S173.22,65.33,300.42,65.33h0Zm262.99,230.33c0-145.25-117.75-262.99-262.99-262.99S37.43,150.41,37.43,295.66s117.74,262.99,262.99,262.99,262.99-117.75,262.99-262.99h0Z"
            />
            <path
              class="ao"
              d="M300.42,558.65c145.25,0,262.99-117.75,262.99-262.99S445.67,32.66,300.42,32.66,37.43,150.41,37.43,295.66s117.74,262.99,262.99,262.99h0Zm295.66-262.99c0,163.29-132.37,295.66-295.66,295.66S4.76,458.95,4.76,295.66,137.13,0,300.42,0s295.66,132.37,295.66,295.66h0Z"
            />
            <path
              class="ba"
              d="M300.42,525.99c-127.21,0-230.33-103.12-230.33-230.33-4.81,.75-10.38,1.18-16.33,1.18s-11.53-.43-16.33-1.18c0,145.25,117.74,262.99,262.99,262.99s262.99-117.75,262.99-262.99c-4.8,.75-10.38,1.18-16.33,1.18s-11.53-.43-16.33-1.18c0,127.21-103.12,230.33-230.33,230.33h0Z"
            />
            <path
              class="bb"
              d="M37.43,295.66c0-145.25,117.74-262.99,262.99-262.99s262.99,117.75,262.99,262.99c4.81-.75,10.38-1.18,16.33-1.18s11.53,.43,16.33,1.18C596.08,132.37,463.71,0,300.42,0S4.76,132.37,4.76,295.66c4.8-.75,10.38-1.18,16.33-1.18s11.53,.43,16.33,1.18h0Z"
            />
            <path
              class="ax"
              d="M300.42,32.66c70.31,0,134.18,27.59,181.37,72.54-46.7-47.33-110.73-76.52-181.37-76.52-33.24,0-65.01,6.46-94.21,18.24-4.63-7.9-15.74-10.55-25.51-5.86-10.02,4.81-14.9,15.54-11.13,24.21-4.88,2.95-9.65,6.07-14.32,9.34-2.93-3.35-8.48-3.58-12.69-.42-4.25,3.18-5.57,8.64-3.1,12.39-7.12,5.84-13.93,12.05-20.4,18.62,47.18-44.95,111.05-72.54,181.37-72.54h0Z"
            />
          </g>
          <g>
            <path
              class="aq"
              d="M300.42,587.78c-3.53-11.8-26.55-70.7-60.32-85.73-64.85-28.86-127.39-51.13-167.56-76.85C36.22,401.95,2.95,375.36,.07,339.57c-1.2,37.01,14.32,94.85,23.46,106.28,31.79,39.77,108.76,64.15,205.9,101.36,29.36,11.25,45.91,22.5,71,40.57h0Z"
            />
            <path
              class="aj"
              d="M300.42,587.78c-3.53-11.8-26.55-70.7-60.32-85.73-64.85-28.86-127.39-51.13-167.56-76.85C36.22,401.95,2.95,375.36,.07,339.57c-1.2,37.01,14.32,94.85,23.46,106.28,31.79,39.77,108.76,64.15,205.9,101.36,29.36,11.25,45.91,22.5,71,40.57h0Z"
            />
          </g>
          <g>
            <path
              class="an"
              d="M300.42,587.78c3.53-11.8,26.55-70.7,60.32-85.73,64.85-28.86,127.39-51.13,167.56-76.85,36.32-23.25,69.6-49.84,72.48-85.63,1.2,37.01-14.32,94.85-23.46,106.28-31.79,39.77-108.76,64.15-205.9,101.36-29.36,11.25-45.91,22.5-71,40.57h0Z"
            />
            <path
              class="al"
              d="M300.42,587.78c3.53-11.8,26.55-70.7,60.32-85.73,64.85-28.86,127.39-51.13,167.56-76.85,36.32-23.25,69.6-49.84,72.48-85.63,1.2,37.01-14.32,94.85-23.46,106.28-31.79,39.77-108.76,64.15-205.9,101.36-29.36,11.25-45.91,22.5-71,40.57h0Z"
            />
          </g>
          <path
            class="ad"
            d="M41.67,449.77c-9.19-16.16-16.61-42.27-21.59-66.76C8.88,370.05,1.37,355.75,.07,339.57c-1.2,37.01,14.32,94.85,23.46,106.28,13.2,16.52,34.2,30.38,61.2,43.61-24.68-16.78-37.71-30.27-43.06-39.69h0Z"
          />
          <path
            class="as"
            d="M224.19,508.32c4.2,8.98,1.94,28.21-10.89,29.73-2.29,.27-4.73,.39-7.13,.34,7.61,2.87,15.36,5.8,23.25,8.83,29.36,11.25,45.91,22.5,71,40.57-3.53-11.8-26.55-70.7-60.32-85.73-16.57-7.37-32.99-14.32-48.92-21,13.34,8.08,27.6,15.69,33.01,27.27h0Z"
          />
          <path
            class="bd"
            d="M300.42,593.64c-58.18-24.23-127.35-47.43-178.44-66.74-16.27-6.15-30.89-18.28-40.21-32.32,8.9,33.38,25.04,54.02,38.91,60,46.22,19.91,124.76,42.17,179.73,68.93v-29.86h0Z"
          />
          <path
            class="at"
            d="M130.61,557.84c-16.36-8.63-28.98-31.32-35.06-47.34-5.31-4.81-9.98-10.21-13.77-15.93,8.9,33.38,25.04,54.02,38.91,60,6.17,2.66,12.92,5.36,20.12,8.11-3.44-1.53-6.87-3.09-10.2-4.84h0Z"
          />
          <path
            class="be"
            d="M222.06,570.54c2.67,3.66,6.51,12.83,4.13,16.19-1.91,2.69-7.66,2.68-12.1,2.15,30.13,10.71,60.78,22.18,86.34,34.62v-29.86c-30.7-12.79-64.47-25.29-97.01-37.08,7.58,4.22,14.76,8.65,18.65,13.98h0Z"
          />
          <path
            class="au"
            d="M559.17,449.77c9.19-16.16,16.61-42.27,21.59-66.76,11.2-12.96,18.72-27.26,20.02-43.44,1.2,37.01-14.32,94.85-23.46,106.28-13.2,16.52-34.2,30.38-61.2,43.61,24.68-16.78,37.71-30.27,43.06-39.69h0Z"
          />
          <path
            class="am"
            d="M376.65,508.32c-4.2,8.98-1.94,28.21,10.89,29.73,2.29,.27,4.73,.39,7.13,.34-7.61,2.87-15.36,5.8-23.25,8.83-29.36,11.25-45.91,22.5-71,40.57,3.53-11.8,26.55-70.7,60.32-85.73,16.57-7.37,32.99-14.32,48.92-21-13.34,8.08-27.6,15.69-33.01,27.27h0Z"
          />
          <path
            class="ah"
            d="M300.42,593.64c58.18-24.23,127.35-47.43,178.44-66.74,16.27-6.15,30.89-18.28,40.21-32.32-8.9,33.38-25.04,54.02-38.91,60-46.22,19.91-124.76,42.17-179.73,68.93v-29.86h0Z"
          />
          <path
            class="aw"
            d="M470.23,557.84c16.36-8.63,28.98-31.32,35.06-47.34,5.31-4.81,9.98-10.21,13.77-15.93-8.9,33.38-25.04,54.02-38.91,60-6.17,2.66-12.92,5.36-20.12,8.11,3.44-1.53,6.87-3.09,10.2-4.84h0Z"
          />
          <path
            class="ak"
            d="M378.78,570.54c-2.67,3.66-6.51,12.83-4.13,16.19,1.91,2.69,7.66,2.68,12.1,2.15-30.13,10.71-60.78,22.18-86.34,34.62v-29.86c30.7-12.79,64.47-25.29,97.02-37.08-7.58,4.22-14.76,8.65-18.65,13.98h0Z"
          />
          <g>
            <path
              class="ae"
              d="M240.1,502.05c-64.85-28.86-127.4-51.13-167.56-76.85C36.43,402.08,3.32,375.66,.12,340.18c4.8,53.88,58.82,83.24,102.56,105.64,43.91,22.49,89.29,37.97,133.93,58.01,32.53,14.61,52.8,56.79,63.82,83.95-3.53-11.8-26.55-70.7-60.32-85.73h0Z"
            />
            <path
              class="ar"
              d="M360.74,502.05c64.85-28.86,127.39-51.13,167.56-76.85,36.12-23.12,69.22-49.54,72.43-85.02-4.8,53.88-58.82,83.24-102.56,105.64-43.91,22.49-89.29,37.97-133.93,58.01-32.53,14.61-52.8,56.79-63.82,83.95,3.53-11.8,26.55-70.7,60.32-85.73h0Z"
            />
          </g>
          <g>
            <path
              class="ab"
              d="M360.87,565.27c0-33.39-27.06-60.45-60.45-60.45s-60.45,27.06-60.45,60.45,27.06,60.45,60.45,60.45,60.45-27.06,60.45-60.45h0Z"
            />
            <path
              class="ac"
              d="M300.42,497.35c37.51,0,67.91,30.41,67.91,67.91s-30.4,67.91-67.91,67.91-67.91-30.41-67.91-67.91,30.41-67.91,67.91-67.91h0Zm0,14.93c-29.27,0-52.98,23.71-52.98,52.98s23.71,52.98,52.98,52.98,52.98-23.71,52.98-52.98-23.72-52.98-52.98-52.98h0Z"
            />
            <path
              class="bc"
              d="M368.33,565.27c0-37.51-30.4-67.91-67.91-67.91s-67.91,30.41-67.91,67.91,30.41,67.91,67.91,67.91,67.91-30.41,67.91-67.91h0Zm-67.91-60.45c33.38,0,60.45,27.06,60.45,60.45s-27.06,60.45-60.45,60.45-60.45-27.06-60.45-60.45,27.06-60.45,60.45-60.45h0Z"
            />
          </g>
          <g>
            <path
              class="av"
              d="M300.42,510.79c-2.9,.18-8.07,1.17-13.87,2.73-5.8,1.55-10.77,3.28-13.37,4.57-2.42,1.61-6.4,5.05-10.64,9.29-4.25,4.25-7.69,8.23-9.29,10.65-1.29,2.6-3.02,7.57-4.57,13.37-1.55,5.8-2.54,10.97-2.73,13.87,.18,2.9,1.17,8.07,2.73,13.87,1.55,5.8,3.28,10.77,4.57,13.37,1.61,2.42,5.05,6.4,9.29,10.64,4.25,4.25,8.23,7.69,10.64,9.3,2.6,1.29,7.57,3.02,13.37,4.57,5.8,1.55,10.97,2.54,13.87,2.73,2.9-.18,8.07-1.17,13.87-2.73,5.8-1.55,10.77-3.28,13.37-4.57,2.42-1.61,6.4-5.05,10.64-9.3,4.25-4.25,7.69-8.23,9.3-10.64,1.29-2.6,3.02-7.57,4.57-13.37,1.55-5.8,2.54-10.97,2.73-13.87-.18-2.9-1.17-8.07-2.73-13.87-1.55-5.8-3.28-10.77-4.57-13.37-1.61-2.42-5.05-6.4-9.3-10.65s-8.23-7.69-10.64-9.29c-2.6-1.29-7.57-3.02-13.37-4.57-5.8-1.55-10.97-2.54-13.87-2.73h0Z"
            />
            <path
              class="av"
              d="M300.42,523.97c-3.3,.34-7.43,1.42-10.47,2.24-3.04,.81-7.16,1.94-10.18,3.3-2.69,1.94-5.72,4.95-7.95,7.17-2.23,2.23-5.23,5.26-7.17,7.95-1.36,3.02-2.48,7.14-3.3,10.18-.81,3.04-1.9,7.17-2.24,10.47,.34,3.3,1.42,7.43,2.24,10.47,.81,3.04,1.94,7.16,3.3,10.18,1.94,2.69,4.95,5.72,7.17,7.95,2.22,2.23,5.26,5.23,7.95,7.17,3.02,1.36,7.14,2.48,10.18,3.3,3.04,.81,7.17,1.9,10.47,2.24,3.3-.34,7.43-1.42,10.47-2.24,3.04-.81,7.16-1.94,10.18-3.3,2.69-1.94,5.72-4.94,7.95-7.17,2.22-2.22,5.23-5.26,7.17-7.95,1.36-3.02,2.48-7.14,3.3-10.18,.81-3.04,1.9-7.17,2.24-10.47-.34-3.3-1.42-7.43-2.24-10.47-.81-3.04-1.94-7.16-3.3-10.18-1.94-2.69-4.95-5.72-7.17-7.95-2.22-2.22-5.26-5.23-7.95-7.17-3.02-1.36-7.14-2.48-10.18-3.3-3.04-.81-7.17-1.9-10.47-2.24h0Z"
            />
            <path
              class="av"
              d="M271.83,536.67c2.22-2.22,5.26-5.23,7.95-7.17l-6.59-11.41c-2.42,1.61-6.4,5.05-10.64,9.29-4.25,4.25-7.69,8.23-9.29,10.65l11.41,6.59c1.94-2.69,4.94-5.72,7.17-7.95h0Z"
            />
            <path
              class="av"
              d="M279.77,529.5c3.02-1.36,7.14-2.48,10.18-3.3,3.04-.81,7.17-1.9,10.47-2.24v-13.18c-2.9,.18-8.07,1.17-13.87,2.73-5.8,1.55-10.77,3.28-13.37,4.57l6.59,11.41h0Z"
            />
            <path
              class="av"
              d="M300.42,619.74v-13.18c3.3-.34,7.43-1.42,10.47-2.24,3.04-.81,7.16-1.94,10.18-3.3l6.59,11.41c-2.6,1.29-7.57,3.02-13.37,4.57-5.8,1.55-10.97,2.54-13.87,2.73h0Z"
            />
            <path
              class="av"
              d="M327.66,612.45l-6.59-11.41c2.68-1.94,5.72-4.94,7.95-7.17,2.22-2.22,5.23-5.26,7.17-7.95l11.41,6.59c-1.61,2.42-5.05,6.4-9.3,10.64-4.25,4.25-8.23,7.69-10.64,9.3h0Z"
            />
            <path
              class="av"
              d="M347.6,592.51l-11.41-6.59c1.36-3.02,2.48-7.14,3.3-10.18,.81-3.04,1.9-7.17,2.24-10.47h13.18c-.18,2.9-1.17,8.07-2.73,13.87-1.55,5.8-3.28,10.77-4.57,13.37h0Z"
            />
            <path
              class="av"
              d="M354.9,565.27h-13.18c-.34-3.3-1.42-7.43-2.24-10.47-.81-3.04-1.94-7.16-3.3-10.18l11.41-6.59c1.29,2.6,3.02,7.57,4.57,13.37,1.55,5.8,2.54,10.97,2.73,13.87h0Z"
            />
            <path
              class="av"
              d="M347.6,538.03l-11.41,6.59c-1.94-2.69-4.95-5.72-7.17-7.95-2.22-2.23-5.26-5.23-7.95-7.17l6.59-11.41c2.42,1.61,6.4,5.05,10.64,9.29s7.69,8.23,9.3,10.65h0Z"
            />
            <path
              class="av"
              d="M327.66,518.09l-6.59,11.41c-3.02-1.36-7.14-2.48-10.18-3.3-3.04-.81-7.17-1.9-10.47-2.24v-13.18c2.9,.18,8.07,1.17,13.87,2.73,5.8,1.55,10.77,3.28,13.37,4.57h0Z"
            />
            <path
              class="av"
              d="M300.42,619.74v-13.18c-3.3-.34-7.43-1.42-10.47-2.24-3.04-.81-7.16-1.94-10.18-3.3l-6.59,11.41c2.6,1.29,7.57,3.02,13.37,4.57,5.8,1.55,10.97,2.54,13.87,2.73h0Z"
            />
            <path
              class="av"
              d="M273.18,612.45l6.59-11.41c-2.69-1.94-5.72-4.94-7.95-7.17-2.22-2.22-5.23-5.26-7.17-7.95l-11.41,6.59c1.61,2.42,5.05,6.4,9.29,10.64,4.25,4.25,8.23,7.69,10.64,9.3h0Z"
            />
            <path
              class="av"
              d="M253.24,592.51l11.41-6.59c-1.36-3.02-2.48-7.14-3.3-10.18-.81-3.04-1.9-7.17-2.24-10.47h-13.18c.18,2.9,1.17,8.07,2.73,13.87,1.55,5.8,3.28,10.77,4.57,13.37h0Z"
            />
            <path
              class="av"
              d="M245.95,565.27h13.18c.34-3.3,1.42-7.43,2.24-10.47,.81-3.04,1.94-7.16,3.3-10.18l-11.41-6.59c-1.29,2.6-3.02,7.57-4.57,13.37-1.55,5.8-2.54,10.97-2.73,13.87h0Z"
            />
            <path
              class="ag"
              d="M273.18,518.09c6.59,11.41-8.53,26.53-19.94,19.94l11.41,6.59c1.94-2.69,4.94-5.72,7.17-7.95,2.22-2.22,5.26-5.23,7.95-7.17l-6.59-11.41h0Z"
            />
            <path
              class="af"
              d="M253.24,538.03c11.41,6.59,5.88,27.24-7.3,27.24h13.18c.34-3.3,1.42-7.43,2.24-10.47,.81-3.04,1.94-7.16,3.3-10.18l-11.41-6.59h0Z"
            />
            <path
              class="ap"
              d="M245.95,565.27c13.18,0,21.56,18.98,10.7,25.28l8.02-4.63c-1.36-3.02-2.48-7.14-3.3-10.18-.81-3.04-1.9-7.17-2.24-10.47h-13.18Z"
            />
            <path
              class="ap"
              d="M327.66,612.45c-6.59-11.41,8.53-26.53,19.94-19.94l-11.41-6.59c-1.94,2.69-4.95,5.72-7.17,7.95-2.22,2.23-5.26,5.23-7.95,7.17l6.59,11.41h0Z"
            />
            <path
              class="ap"
              d="M347.6,592.51c-11.41-6.59-5.88-27.24,7.3-27.24h-13.18c-.34,3.3-1.42,7.43-2.24,10.47-.81,3.04-1.94,7.16-3.3,10.18l11.41,6.59h0Z"
            />
            <path
              class="ap"
              d="M354.9,565.27c-13.18,0-21.56-18.98-10.7-25.28l-8.02,4.63c1.36,3.02,2.48,7.14,3.3,10.18,.81,3.04,1.9,7.17,2.24,10.47h13.18Z"
            />
            <path
              class="ag"
              d="M293.88,525.19c-9.85,.75-15.16,.71-20.7-7.1l6.59,11.41c3.02-1.36,7.14-2.48,10.18-3.3,1.18-.32,2.53-.67,3.93-1.01h0Z"
            />
            <path
              class="ag"
              d="M306.96,605.34c9.85-.76,15.16-.72,20.7,7.1l-6.59-11.41c-3.02,1.36-7.14,2.48-10.18,3.3-1.18,.32-2.53,.67-3.93,1.01h0Z"
            />
            <path
              class="ag"
              d="M258.66,566.4c1.24,6.32,2.68,12.53,5.07,18.61l-.28,1.01c-.98,.76-1.97,1.53-2.96,2.3,1.39-.8,2.77-1.6,4.16-2.4-1.36-3.02-2.48-7.14-3.3-10.18-.81-3.04-1.9-7.17-2.24-10.47,.34-3.3,1.42-7.43,2.24-10.47,.81-3.04,1.94-7.16,3.3-10.18,1.94-2.69,4.94-5.72,7.17-7.95,2.22-2.22,5.26-5.23,7.95-7.17,3.02-1.36,7.14-2.48,10.18-3.3,1.82-.49,4.02-1.07,6.2-1.53-5.12,.7-10.43,1.66-15.52,3.37l-.99-.33c-2.15-3.21-4.31-6.42-6.46-9.62,1.68,3.53,3.28,6.87,4.95,10.37l-.24,.99c-5.2,3.98-9.39,8.75-13.05,13.79l-.97,.23c-3.46-1.76-6.91-3.53-10.37-5.29,3.21,2.11,6.43,4.23,9.64,6.34l.3,.97c-2.51,6.14-4.07,12.37-4.86,18.67l-.74,.68c-3.34,.14-6.68,.28-10.01,.42,3.35,.15,6.7,.31,10.05,.46l.78,.67h0Z"
            />
            <path
              class="ag"
              d="M337.12,587.14c3.31,1.69,6.61,3.38,9.92,5.07-3.15-2-6.25-3.96-9.4-5.95l-.34-.97c2.15-6.2,3.78-12.5,4.95-18.86l.77-.68c3.15-.16,6.3-.32,9.46-.47l-9.46-.53-.8-.68c-.38-1.51-.91-2.91-1.48-4.29,.43,1.88,.8,3.8,.98,5.5-.34,3.3-1.42,7.43-2.24,10.47-.81,3.04-1.94,7.16-3.3,10.18-1.94,2.69-4.95,5.72-7.17,7.95-2.22,2.23-5.26,5.23-7.95,7.17-2.26,1.01-5.13,1.9-7.7,2.62,2.33-.42,4.66-.9,6.99-1.62l.98,.35c1.66,2.57,3.31,5.15,4.97,7.72-1.39-2.78-2.73-5.44-4.12-8.2l.21-1.01c4.73-3.94,9.3-8.41,13.71-13.56l1.01-.2h0Z"
            />
            <path
              class="ag"
              d="M264.66,585.92l35.76-20.65-35.76-20.65c-1.36,3.02-2.48,7.14-3.3,10.18-.81,3.04-1.9,7.17-2.24,10.47,.34,3.3,1.42,7.43,2.24,10.47,.81,3.04,1.94,7.16,3.3,10.18h0Z"
            />
            <path
              class="ag"
              d="M264.66,544.62l35.76,20.65v-41.3c-3.3,.34-7.43,1.42-10.47,2.24-3.04,.81-7.16,1.94-10.18,3.3-2.69,1.94-5.72,4.95-7.95,7.17-2.23,2.23-5.23,5.26-7.17,7.95h0Z"
            />
            <path
              class="ag"
              d="M300.42,523.97v41.3l35.76-20.65c-1.94-2.69-4.95-5.72-7.17-7.95-2.22-2.22-5.26-5.23-7.95-7.17-3.02-1.36-7.14-2.48-10.18-3.3-3.04-.81-7.17-1.9-10.47-2.24h0Z"
            />
            <path
              class="ag"
              d="M336.19,544.62l-35.76,20.65,35.76,20.65c1.36-3.02,2.48-7.14,3.3-10.18,.81-3.04,1.9-7.17,2.24-10.47-.34-3.3-1.42-7.43-2.24-10.47-.81-3.04-1.94-7.16-3.3-10.18h0Z"
            />
            <path
              class="ag"
              d="M336.19,585.92l-35.76-20.65v41.3c3.3-.34,7.43-1.42,10.47-2.24,3.04-.81,7.16-1.94,10.18-3.3,2.69-1.94,5.72-4.94,7.95-7.17,2.22-2.22,5.23-5.26,7.17-7.95h0Z"
            />
            <path
              class="ag"
              d="M300.42,606.57v-41.3l-35.76,20.65c1.94,2.69,4.95,5.72,7.17,7.95,2.22,2.23,5.26,5.23,7.95,7.17,3.02,1.36,7.14,2.48,10.18,3.3,3.04,.81,7.17,1.9,10.47,2.24h0Z"
            />
            <path
              class="ag"
              d="M300.42,523.97c-3.14,.32-7.03,1.32-10.02,2.12l-29.16,29.16c-.8,2.99-1.8,6.88-2.12,10.02,.34,3.3,1.42,7.43,2.24,10.47,.81,3.04,1.94,7.16,3.3,10.18,.64,.88,1.39,1.8,2.19,2.71l56.94-56.94c-.91-.8-1.83-1.55-2.71-2.19-3.02-1.36-7.14-2.48-10.18-3.3-3.04-.81-7.17-1.9-10.47-2.24h0Z"
            />
            <path
              class="ag"
              d="M338.1,549.92l-53.03,53.03c1.72,.53,3.42,.99,4.88,1.38,2.37,.64,5.42,1.44,8.2,1.92l43.24-43.24c-.48-2.79-1.28-5.83-1.92-8.2-.39-1.46-.85-3.16-1.38-4.88h0Z"
            />
            <path
              class="ap"
              d="M300.42,510.79c-2.9,.18-8.07,1.17-13.87,2.73-5.8,1.55-10.77,3.28-13.37,4.57-2.42,1.61-6.4,5.05-10.64,9.29-4.25,4.25-7.69,8.23-9.29,10.65-1.29,2.6-3.02,7.57-4.57,13.37-1.55,5.8-2.54,10.97-2.73,13.87,.18,2.9,1.17,8.07,2.73,13.87,1.55,5.8,3.28,10.77,4.57,13.37,1.61,2.42,5.05,6.4,9.29,10.64,4.25,4.25,8.23,7.69,10.64,9.3,2.6,1.29,7.57,3.02,13.37,4.57,5.8,1.55,10.97,2.54,13.87,2.73,2.9-.18,8.07-1.17,13.87-2.73,5.8-1.55,10.77-3.28,13.37-4.57,2.42-1.61,6.4-5.05,10.64-9.3,4.25-4.25,7.69-8.23,9.3-10.64,1.29-2.6,3.02-7.57,4.57-13.37,1.55-5.8,2.54-10.97,2.73-13.87-.18-2.9-1.17-8.07-2.73-13.87-1.55-5.8-3.28-10.77-4.57-13.37-1.61-2.42-5.05-6.4-9.3-10.65s-8.23-7.69-10.64-9.29c-2.6-1.29-7.57-3.02-13.37-4.57-5.8-1.55-10.97-2.54-13.87-2.73h0Z"
            />
          </g>
          <path
            class="ay"
            d="M290.67,529.5c0,6.02-4.88,10.9-10.9,10.9s-10.9-4.88-10.9-10.9,4.88-10.9,10.9-10.9,10.9,4.88,10.9,10.9Z"
          />
          <circle class="bg" cx="264.03" cy="544.62" r="7.96" />
          <circle class="bf" cx="336.89" cy="586.5" r="7.96" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.ab {
  fill: url(#v);
}
.ab,
.ac,
.ad,
.ae,
.af,
.ag,
.ah,
.ai,
.aj,
.ak,
.al,
.am,
.an,
.ao,
.ap,
.aq,
.ar,
.as,
.at,
.au,
.av,
.aw,
.ax,
.ay,
.b`,
.ba,
.bb,
.bc,
.bd,
.be {
  fill-rule: evenodd;
}
.ac {
  fill: url(#w);
}
.ad {
  fill: url(#j);
}
.ad,
.ak,
.at,
.au,
.aw,
.be {
  mix-blend-mode: multiply;
}
.ae {
  fill: url(#t);
}
.bf {
  fill: url(#aa);
}
.bf,
.af,
.ag,
.aj,
.al,
.ap,
.ax,
.ay,
.ba,
.bb,
.bg {
  mix-blend-mode: screen;
}
.af {
  opacity: 0.65;
}
.af,
.ag,
.ap,
.av {
  fill: #8c433c;
}
.bh {
  isolation: isolate;
}
.ah {
  fill: url(#q);
}
.ai {
  fill: #a0653f;
}
.aj {
  fill: url(#g);
}
.ak {
  fill: url(#s);
}
.al {
  fill: url(#i);
}
.am {
  fill: url(#p);
}
.am,
.as {
  mix-blend-mode: darken;
}
.an {
  fill: url(#h);
}
.ao {
  fill: url(#c);
}
.ap {
  opacity: 0.5;
}
.aq {
  fill: url(#f);
}
.ar {
  fill: url(#u);
}
.as {
  fill: url(#k);
}
.at {
  fill: url(#m);
}
.au {
  fill: url(#o);
}
.aw {
  fill: url(#r);
}
.ax {
  fill: #fef6b8;
}
.ay {
  fill: url(#y);
}
.b` {
  fill: url(#b);
}
.ba {
  fill: url(#d);
}
.bb {
  fill: url(#e);
}
.bc {
  fill: url(#x);
}
.bd {
  fill: url(#l);
}
.be {
  fill: url(#n);
}
.bg {
  fill: url(#a`);
}
</style>
