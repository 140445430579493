<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M0 512h512V0H0Z" opacity="1" data-original="#000000"></path>
        </clipPath>
      </defs>
      <path
        d="M0 0h163.021"
        style="
          stroke-width: 15;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        transform="matrix(1.33333 0 0 -1.33333 153.358 404.561)"
        fill="none"
        stroke="#000000"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M0 0h197.178"
        style="
          stroke-width: 15;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        transform="matrix(1.33333 0 0 -1.33333 153.358 476.023)"
        fill="none"
        stroke="#000000"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M0 0h163.021"
        style="
          stroke-width: 15;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        transform="matrix(1.33333 0 0 -1.33333 153.358 333.1)"
        fill="none"
        stroke="#000000"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M0 0h98.589"
        style="
          stroke-width: 15;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        transform="matrix(1.33333 0 0 -1.33333 153.358 261.637)"
        fill="none"
        stroke="#000000"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        class=""
      ></path>
      <path
        d="m0 0-65.821 31.324"
        style="
          stroke-width: 15;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        transform="matrix(1.33333 0 0 -1.33333 511.734 398.584)"
        fill="none"
        stroke="#000000"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        class=""
      ></path>
      <path
        d="m0 0 65.821-31.324"
        style="
          stroke-width: 15;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        transform="matrix(1.33333 0 0 -1.33333 553.725 83.644)"
        fill="none"
        stroke="#000000"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        class=""
      ></path>
      <path
        d="m0 0-35.887 17.081"
        style="
          stroke-width: 15;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        transform="matrix(1.33333 0 0 -1.33333 467.967 439.214)"
        fill="none"
        stroke="#000000"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        class=""
      ></path>
      <g clip-path="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
        <path
          d="M0 0h-77.074c-3.742 0-3.317.142-6.634-1.561l-69.874-35.948c-3.741-1.39-5.556-.341-5.357 3.489l4.054 74.678c.085.596.226.681-.171 1.022-37.757 32.146-61.823 79.983-61.823 133.183v7.575c0 96.185 78.605 174.863 174.699 174.863h147.601c22.28 0 43.625-4.228 63.269-11.945m61.342-40.687c30.926-31.607 50.089-74.791 50.089-122.231v-7.575C280.121 78.678 201.487 0 105.421 0H33.279"
          style="
            stroke-width: 15;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          transform="translate(224.38 57.096)"
          fill="none"
          stroke="#000000"
          stroke-width="15"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-dasharray="none"
          stroke-opacity=""
          data-original="#000000"
          class=""
        ></path>
        <path
          d="m0 0 65.934 138.829c5.386 11.349 20.098 15.747 32.655 9.76l10.063-4.795 10.063-4.766c12.558-5.987 18.426-20.174 13.04-31.523L19.587-128.671l-65.622-60.946c-2.891-2.27-5.924-.822-5.981 2.866l5.783 89.403 31.578 66.478"
          style="
            stroke-width: 15;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          transform="translate(364.213 341.733)"
          fill="none"
          stroke="#000000"
          stroke-width="15"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-dasharray="none"
          stroke-opacity=""
          data-original="#000000"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
