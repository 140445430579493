import router from '@/ui/router';
import { filterRoutes, filterRoutesAsync } from '@/ui/router';

export default class RouterInstanceClass {
    private static instance: RouterInstanceClass;

    permissionCreated: boolean;
    dynamicRouteCreated: boolean;
    constructor() {
        this.permissionCreated = false;
        this.dynamicRouteCreated = false;
    }

    public static getInstance(): RouterInstanceClass {
        if (!RouterInstanceClass.instance) {
            // Get from local storage
            RouterInstanceClass.instance = new RouterInstanceClass();
        }

        return RouterInstanceClass.instance;
    }

    generateRoutes() {
        filterRoutes();
        this.permissionCreated = true;
        return true;
    }

    async generateDynamicRoutes() {
        await filterRoutesAsync();
        this.dynamicRouteCreated = true;
        return true;
    }
    async updateRouterByKey(routeName, action) {
        if (action == 'REMOVE') {
            router.removeRoute(routeName);
        }
    }
}
