import { ELicenseType, EPlanState } from './../../types/plan.types';
import { EPaymentPurpose } from '@/application/enums/PaymentEnums';

export interface PaymentTransactionLog {
    id: string;
    organizationId: string;
    userId: string;
    provider: string;
    responseCode: string;
    transactionStatus: string;
    internalStatus: string;
    bankCode: string;
    bankTranNo: string;
    cardType: string;
    payDate: string;
    transactionNo: string;
    paymentRedirectUrl: string;
    orderInfo: IOrderInfoTransactionLog;
    billInfo: IBillInfoTransactionLog;
    invoiceInfo: IInvoiceInfoTransactionLog;
    voucherInfo: IPaymentVoucherDetail;
    licenseInfo: ILicenseInfo;
    createdDate: string;
    createdBy: string;
    updatedDate: string;
    updatedBy: string;
    providerData: IProviderDataTransactionLog;
    paymentPurpose: EPaymentPurpose;
    payerName: string;
    payerPhone: string;
    payerEmail: string;
    payerAvatar: string;
    paymentPlanId: string;
    previousPlanId: string;
    paymentStatus: string;
    currentStateCode: EPlanState;
    invoiceFiles: Array<InvoiceFile>;
}

export interface IBillInfo {
    mobile: string;
    email: string;
    firstName: string;
    lastName: string;
}
export interface ILicenseInfo {
    id: string;
    licenseKey: string;
    type: ELicenseType;
    phone: string;
    email: string;
    planId: number;
    totalMember: number;
    totalAmount: number;
    priceUnit: string;
}

export interface IOrderInfoTransactionLog {
    orderId: string;
    amount: number;
    orderDesc: string;
    createdDate: string;
    startDate: string;
    endDate: string;
    expireDate: string;
    ipAddress: string;
    locate: string;
    bankCode: null;
    planTime: number;
    currentStateCode: string;
    billingCycle: string;
    period: number;
    periodUnit: string;
    planMarket: string;
    totalDaysApplied: number;
    planPrice: number;
    quantity: number;
    amountBeforeDiscount: number;
}

export interface IBillInfoTransactionLog {
    mobile: string;
    email: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    country: string;
    state: string;
}

export interface IInvoiceInfoTransactionLog {
    phone: string;
    email: string;
    customer: string;
    address: string;
    company: string;
    taxCode: string;
    type: 'I' | 'O'; // - I: Cá nhân - O: Tổ chức
}

export enum EPaymentVoucherResponseCode {
    VOUCHER_NOT_FOUND = 'VOUCHER_NOT_FOUND',
    VOUCHER_INVALID = 'VOUCHER_INVALID',
    VOUCHER_LIMIT_REACHED = 'VOUCHER_LIMIT_REACHED',
    VOUCHER_EXPIRED = 'VOUCHER_EXPIRED',
    VOUCHER_TOO_EARLY = 'VOUCHER_TOO_EARLY',
    VOUCHER_BELONG_TO_OTHER_PHONE_NUMBER = 'VOUCHER_BELONG_TO_OTHER_PHONE_NUMBER',
    VOUCHER_BELONG_TO_OTHER_EMAIL_ADDRESS = 'VOUCHER_BELONG_TO_OTHER_EMAIL_ADDRESS',
    VOUCHER_BELONG_TO_OTHER_ORGANIZATION = 'VOUCHER_BELONG_TO_OTHER_ORGANIZATION',
    VOUCHER_BELONG_TO_OTHER_USER = 'VOUCHER_BELONG_TO_OTHER_USER',
    VOUCHER_VALID = 'VOUCHER_VALID',
}

export interface IPaymentVoucherDetail {
    code: string;
    valueInPercent: number;
    valueInMoney: number;
    currency: string;
    name: string;
    description: string;
    validFrom: string;
    validTo: string;
    isValid: boolean;
    messageCode: EPaymentVoucherResponseCode | null;
    message: string;
    category: 'VOUCHER' | 'COUPON';
}

export interface IProviderDataTransactionLog {
    queryParams: string;
    rawData: any;
}

export interface InvoiceFile {
    capacity: string;
    contentType: string;
    creationTime: string;
    extension: string;
    hostUrl: string;
    id: string;
    language: string;
    name: string;
    path: string;
    pathUrl: string;
}
