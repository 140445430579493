<script setup lang="ts">
import { NotificationType } from '@/domain/enums/notification-enums';
import NotificationTaskItem from '@/ui/modules/notification/NotificationTaskItem.vue';
import NotificationGroupItem from '@/ui/modules/notification/NotificationGroupItem.vue';
import NotificationDayoffItem from '@/ui/modules/notification/NotificationDayoffItem.vue';
import NotificationGedItem from '@/ui/modules/notification/NotificationGedItem.vue';
import NotificationEventItem from '@/ui/modules/notification/NotificationEventItem.vue';
import NotificationDepartmentItem from '@/ui/modules/notification/NotificationDepartmentItem.vue';
// import NotificationTaskWorkflow from '@/ui/modules/notification/NotificationTaskWorkflow.vue';
import { INotification } from '@/application/types/notification.types';
const props = defineProps<{
  allNotifications: INotification[];
  latestNotification?: { today: string; yesterday: string; older: string };
  isLoading?: boolean;
  isLoadingMore?: boolean;
  isConditionReadAll?: boolean;
  isHiddenActionUser?: boolean;
  canLoadMore?: boolean;
  category?: string;
  currentNotification?: INotification;
}>();

const emit = defineEmits<{
  (e: 'onLoadMore'): void;
  (
    e: 'onClickNotification',
    notification: INotification,
    options?: {
      view: string;
      isOpenNewTab: boolean;
    }
  ): void;
  (e: 'onMarkReadNotification', notification: INotification): void;
  (e: 'onDeleteNotification', notification: INotification): void;
  (
    e: 'onReactionNotification',
    notification: INotification,
    reactions: any
  ): void;
  (e: 'onCloseDropdown', type: any): void;
  (e: 'onKeydown', key: string): void;
  (e: 'onOpenGroupDetailModal', data: any): void;
}>();

const onScrollTo = (notification) => {
  if (!notification || !notification?.id) return;

  const notificationElm = document.getElementById(
    `notification-button-id_${notification?.id}`
  );

  if (notificationElm) notificationElm.scrollIntoView();
};

const handleLoadMore = () => {
  if (!props.canLoadMore) return;

  emit('onLoadMore');
};
</script>
<template>
  <div class="flex-1 min-h-0 flex flex-col">
    <div
      v-scroll-infinite="handleLoadMore"
      class="flex-1 small-scrollbar overflow-y-auto overflow-x-hidden"
    >
      <template v-if="!isLoading">
        <template v-if="!allNotifications || allNotifications.length === 0">
          <div class="text-center p-20">
            <SynAnimation
              name="noNotification"
              stype="width: 200px; height: 200px;"
            />
            <span>{{
              !isConditionReadAll
                ? $t('NOTIFICATION_DOES_NOT_EXIST') ||
                  "You don't have any notifications"
                : $t('NOTIFICATION_LABEL_ALERT_READ_ALL_WHEN_SHOW_UNREAD')
            }}</span>
          </div>
        </template>

        <template v-else>
          <template
            v-for="notification in allNotifications"
            :key="notification?.id"
          >
            <div
              v-if="notification?.id == latestNotification?.today"
              class="px-3 pt-2 pb-3 text-sm font-medium text-current-600"
            >
              {{ $t('NOTIFICATION_TODAY') || 'Today' }}
            </div>
            <div
              v-if="notification?.id == latestNotification?.yesterday"
              class="px-3 pt-2 pb-3 text-sm font-medium text-current-600"
            >
              {{ $t('NOTIFICATION_YESTERDAY') || 'Yesterday' }}
            </div>
            <div
              v-if="notification?.id == latestNotification?.older"
              class="px-3 pt-2 pb-3 text-sm font-medium text-current-600"
            >
              {{ $t('NOTIFICATION_OLDER') || 'Older' }}
            </div>
            <button
              :id="`notification-button-id_${notification?.id}`"
              class="flex-1 w-full relative focus:outline-none"
              :class="
                notification?.id == currentNotification?.id ? 'bg-gray-50' : ''
              "
              @keydown.up.stop.prevent="$emit('onKeydown', 'ArrowUp')"
              @keydown.down.stop.prevent="$emit('onKeydown', 'ArrowDown')"
              @keydown.left.stop.prevent="$emit('onKeydown', 'ArrowLeft')"
              @keydown.right.stop.prevent="$emit('onKeydown', 'ArrowRight')"
            >
              <NotificationEventItem
                v-if="
                  notification?.type?.toUpperCase() ===
                  NotificationType.CALENDAR
                "
                prop-id="dropdown"
                :notification-item="notification"
                :category="category"
                @click.stop.prevent="$emit('onClickNotification', notification)"
                @handle-click-mark-read="
                  $emit('onMarkReadNotification', notification)
                "
                @handle-delete-notification="
                  $emit('onDeleteNotification', notification)
                "
                @on-user-action-click="
                  (value) => $emit('onCloseDropdown', value)
                "
              />
              <NotificationGedItem
                v-if="
                  notification?.type?.toUpperCase() === NotificationType.GED
                "
                prop-id="dropdown"
                :notification-item="notification"
                :category="category"
                @click.stop.prevent="$emit('onClickNotification', notification)"
                @handle-click-mark-read="
                  $emit('onMarkReadNotification', notification)
                "
                @handle-delete-notification="
                  $emit('onDeleteNotification', notification)
                "
                @on-user-action-click="
                  (value) => $emit('onCloseDropdown', value)
                "
              />
              <NotificationGroupItem
                v-if="
                  notification?.type?.toUpperCase() === NotificationType.GROUP
                "
                prop-id="dropdown"
                :notification-item="notification"
                @on-open-detail="
                  (group) => $emit('onOpenGroupDetailModal', group)
                "
                @handle-click-mark-read="
                  $emit('onMarkReadNotification', notification)
                "
                @handle-delete-notification="
                  $emit('onDeleteNotification', notification)
                "
              />

              <NotificationTaskItem
                v-else-if="
                  notification?.type?.toUpperCase() === NotificationType.TASK
                "
                prop-id="dropdown"
                :notification-item="notification"
                :category="category"
                :is-hidden-action-user="isHiddenActionUser"
                @click.stop.prevent="
                  $emit('onClickNotification', notification, {
                    view: 'MODAL',
                    isOpenNewTab: false,
                  })
                "
                @handle-click-mark-read="
                  $emit('onMarkReadNotification', notification)
                "
                @handle-delete-notification="
                  $emit('onDeleteNotification', notification)
                "
                @on-user-action-click="
                  (value) => $emit('onCloseDropdown', value)
                "
                @on-update-reaction="
                  (value) =>
                    $emit('onReactionNotification', notification, value)
                "
                @on-scroll-to="onScrollTo(notification)"
              />
              <NotificationDayoffItem
                v-if="
                  notification?.type?.toUpperCase() === NotificationType.DAYOFF
                "
                prop-id="dropdown"
                :notification-item="notification"
                :category="category"
                @click.stop.prevent="$emit('onClickNotification', notification)"
                @handle-click-mark-read="
                  $emit('onMarkReadNotification', notification)
                "
                @handle-delete-notification="
                  $emit('onDeleteNotification', notification)
                "
                @on-user-action-click="
                  (value) => $emit('onCloseDropdown', value)
                "
              />
              <NotificationDepartmentItem
                v-if="
                  notification?.type?.toUpperCase() ===
                  NotificationType.DEPARTMENT
                "
                prop-id="dropdown"
                :notification-item="notification"
                :category="category"
                @click.stop.prevent="$emit('onClickNotification', notification)"
                @handle-click-mark-read="
                  $emit('onMarkReadNotification', notification)
                "
                @handle-delete-notification="
                  $emit('onDeleteNotification', notification)
                "
              />

              <span
                v-if="notification?.id == currentNotification?.id"
                class="absolute left-0 top-0 h-full border-2 border-current"
              />
            </button>
          </template>

          <div v-if="isLoadingMore" class="px-3 py-2 flex-center">
            <SynIcon
              name="Spinner"
              class="w-5 h-5 fill-gray-500 animate-spin"
            />
          </div>
          <div v-else-if="canLoadMore" class="px-3 py-2 flex-center">
            <AtomButton
              class="rounded-full border"
              @click.stop="$emit('onLoadMore')"
            >
              <span class="text-sm px-2">
                {{ $t('COMMON_LABEL_VIEW_MORE') }}
              </span>
            </AtomButton>
          </div>
        </template>
      </template>

      <template v-else>
        <div v-if="isLoading" style="max-height: 650px">
          <div
            v-for="item in 2"
            :key="item"
            class="animate-pulse flex items-center space-x-2 w-full p-2"
          >
            <div class="w-8 h-8 rounded-full bg-gray-200 animate-pulse"></div>
            <div class="flex-1 space-y-4 py-1">
              <div class="space-y-2">
                <div
                  class="h-2.5 bg-gray-200 rounded-full w-2/3 animate-pulse"
                ></div>
                <div class="h-2.5 bg-gray-200 rounded-full animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
