import { EFunctionPermission } from '@/application/types/block-function.types';
import { checkPermissionByAdminType } from '../hooks/permission/permission-by-admin-type';

export default {
    install: (app) => {
        app.directive('permission-by-admin-type', {
            mounted(element, binding) {
                if (!element || !binding) return;
                const functionName = binding.value?.functionName;
                if (!functionName) return;

                const permission = checkPermissionByAdminType(functionName);

                switch (permission) {
                    case EFunctionPermission.HIDDEN:
                        {
                            element?.parentNode?.removeChild(element);
                        }
                        break;

                    default:
                        break;
                }
            },
        });
    },
};
