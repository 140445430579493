<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 510.144 510.144"
    style="enable-background: new 0 0 510.144 510.144"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M404.395,318.528c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333h105.6    c-4.544-54.571-26.155-104.277-59.627-143.808l-44.437,44.459c-4.181,4.181-9.621,6.251-15.083,6.251    c-5.461,0-10.923-2.069-15.083-6.251c-8.341-8.32-8.341-21.824,0-30.165l44.437-44.459    c-39.509-33.451-89.237-55.083-143.808-59.605v105.579c0,11.776-9.536,21.333-21.333,21.333c-11.776,0-21.333-9.557-21.333-21.333    V42.283c-54.549,4.523-104.277,26.155-143.787,59.605l44.437,44.459c8.341,8.341,8.341,21.845,0,30.165    c-4.16,4.181-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.069-15.083-6.251l-44.437-44.459    C26.304,171.584,4.693,221.291,0.149,275.861h105.579c11.797,0,21.333,9.557,21.333,21.333s-9.536,21.333-21.333,21.333H0    c4.352,51.776,24.405,101.397,57.621,141.589c4.032,4.907,10.069,7.744,16.427,7.744h362.048c6.357,0,12.395-2.837,16.427-7.744    c33.216-40.192,53.269-89.813,57.621-141.589H404.395z M258.731,339.861L198.4,279.531l120.661-60.352L258.731,339.861z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 512.009 512.009"
    style="enable-background: new 0 0 512.009 512.009"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M256.009,42.671c-0.002,0-0.005,0-0.007,0c-0.001,0-0.001,0-0.002,0c-0.001,0-0.002,0-0.003,0     c-70.671,0.003-134.648,28.637-180.967,74.935c-0.016,0.016-0.034,0.029-0.05,0.045c-0.017,0.017-0.03,0.035-0.047,0.052     C28.688,163.976,0.072,227.867,0.011,298.445C0.011,298.521,0,298.595,0,298.671c0,0.073,0.01,0.143,0.011,0.215     c0.05,60.201,20.962,117.239,58.515,162.704c4.053,4.907,10.084,7.748,16.448,7.748h362.048c6.364,0,12.395-2.841,16.448-7.748     c37.607-45.53,58.539-102.65,58.539-162.919C512.009,157.289,397.391,42.671,256.009,42.671z M426.68,426.671H85.316     c-23.281-30.977-37.712-67.661-41.583-106.667h62.934c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333     H43.734c4.259-42.905,21.23-82.066,47.091-113.671l14.32,14.32c8.331,8.331,21.839,8.331,30.17,0     c8.331-8.331,8.331-21.839,0-30.17l-14.321-14.321c31.605-25.864,70.765-42.837,113.672-47.098v62.941     c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333V86.396c42.906,4.259,82.068,21.232,113.676,47.096     l-14.325,14.325c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0l14.326-14.326     c25.867,31.607,42.842,70.771,47.103,113.677h-62.95c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333     h62.95C464.409,359.001,449.97,395.686,426.68,426.671z"
          />
          <polygon
            points="259.661,341.338 319.991,220.655 199.33,281.007    "
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
