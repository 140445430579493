<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import ProfileById from '@/ui/components/user/ProfileById.vue';
import UserService from '@/application/services/UserService';
import settingStore from '@/store/setting';

const props = defineProps<{
  profileId: number;
}>();
const _settingStore = settingStore();

const emit = defineEmits(['cancel']);

const showUserPhoneEnabled = computed(() => _settingStore.showUserPhoneEnabled);

const userInfo = ref();
const isLoadingProfile = ref(false);
onMounted(async () => {
  isLoadingProfile.value = true;
  const res = await UserService.getInstance().getDetail(props.profileId);
  userInfo.value = res?.result;
  isLoadingProfile.value = false;
});

const onCancel = () => {
  emit('cancel', false);
};

const keysNotShow = computed(() => {
  let result = [
    'login',
    'firstName',
    'lastName',
    'skyper',
    'active',
    'identityCard',
    'positionId',
    'phone',
    'birthday',
    'address',
  ];

  if (showUserPhoneEnabled.value) result = result.filter((o) => o !== 'phone');
  return result;
});
</script>

<template>
  <syn-modal
    z-index="z-50"
    container-class="w-max"
    container-style="width: 32rem;"
    is-hidden-header
    is-hidden-footer
    style-body="px-0 pt-0 pb-8"
    @cancel="onCancel"
  >
    <template #body>
      <section
        v-if="isLoadingProfile"
        class="flex animate-pulse justify-center flex-wrap space-y-6"
      >
        <div
          class="relative w-full flex justify-center h-32 rounded-t-md"
          :style="{
            'background-color': `rgb(229, 231, 235)`,
            'background-size': 'cover',
          }"
        >
          <div
            class="
              absolute
              top-10
              z-10
              w-32
              h-32
              bg-gray-200
              border-4 border-white
              rounded-full
            "
          ></div>
        </div>
        <div class="w-3/4 space-y-6 flex flex-wrap">
          <div
            class="font-medium w-full mt-8 px-6 py-2 rounded-lg bg-gray-200"
          ></div>
          <div class="w-full h-48 px-6 py-2 rounded-lg bg-gray-200"></div>
        </div>
      </section>
      <ProfileById
        v-else-if="userInfo?.id"
        :user-id="profileId"
        :info="userInfo"
        :key-not-show="keysNotShow"
        @close="onCancel"
      />
    </template>
    <template #footer>
      <slot></slot>
    </template>
  </syn-modal>
</template>
