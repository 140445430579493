<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import groupStore from '@/store/group';
import GroupTable from '@/ui/modules/group/table/GroupTable.vue';
import CreateGroupTask from '@/ui/modules/group/create/CreateGroupTask.vue';
import GroupDetailPage from '@/ui/modules/group/detail/GroupDetailPage.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import { IGroupDetail } from '@/application/types/group/group.types';

const _groupStore = groupStore();
const groupTabs = ref<any[]>([]);
const viewMode = ref<'list' | 'detail'>('list');
const currentGroupId = ref();
const groupDetail = ref<IGroupDetail>();
const isShowAdd = ref(false);
const isUpdateAvt = ref(false);
const currentTab = ref('list');
const previousTab = ref('list');

onMounted(() => {});

const onDeleteGroupSuccess = (groupId) => {
  groupTabs.value = groupTabs.value.filter((o) => o.id !== groupId);
};

const deleteGroupInDetailTab = () => {
  closeTab(groupDetail.value);
};

// create
const onCreateGroup = () => {
  isShowAdd.value = true;
};

const onChooseGroup = async (group: IGroupDetail) => {
  const groupId = group?.id;
  groupDetail.value = group;
  const index = groupTabs.value.findIndex((o) => o.id == groupId);
  if (index == -1) {
    if (groupTabs.value?.length == 5) groupTabs.value.shift();
    groupTabs.value = [...groupTabs.value, group];
  }
  viewMode.value = 'detail';
  previousTab.value = currentTab.value;
  currentTab.value = viewMode.value;
  currentGroupId.value = groupId;
};

const chooseListTab = () => {
  viewMode.value = 'list';
  previousTab.value = currentTab.value;
  currentTab.value = viewMode.value;
};

const chooseDetailTab = (group) => {
  viewMode.value = 'detail';
  previousTab.value = currentTab.value;
  currentTab.value = viewMode.value;
  currentGroupId.value = group?.id;
  groupDetail.value = group;
};

const closeTab = (group) => {
  if (group.id == currentGroupId.value) {
    currentGroupId.value = null;
    viewMode.value = 'list';
    previousTab.value = currentTab.value;
    currentTab.value = viewMode.value;
  }
  groupTabs.value = groupTabs.value.filter((o) => o.id !== group.id);
};

onUnmounted(() => _groupStore.resetFilter());

const updateInformationSuccess = (updatedGroupInformation) => {
  const index = groupTabs.value?.findIndex(
    (g) => g?.id == updatedGroupInformation?.id
  );
  if (index > -1) groupTabs.value[index] = updatedGroupInformation;
};

const onCreateGroupSuccess = (newGroup) => {
  _groupStore.getAllGroup();

  onChooseGroup(newGroup);
};
</script>
<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.ADMIN.GROUP,
      isPage: true,
    }"
    class="h-full w-full pt-2 flex flex-col"
  >
    <div class="flex-1 flex justify-between space-x-2 items-end">
      <ul class="flex-1 min-w-0 flex cursor-pointer text-sm space-x-0.5">
        <li
          class="
            py-2
            px-4
            font-semibold
            rounded-t-md
            items-center
            flex
            shrink-0
          "
          :class="
            viewMode === 'list'
              ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
              : 'text-gray-500 bg-gray-100 '
          "
          :style="
            viewMode === 'list' ? 'margin-bottom: -1px' : 'margin-bottom: 0px'
          "
          @click="chooseListTab"
        >
          {{ $t('GROUP_LABEL_ALL') }}
        </li>
        <li
          v-for="group in groupTabs"
          :key="group"
          class="py-2 px-2 rounded-t-lg min-w-0"
          :class="
            viewMode == 'detail' && currentGroupId == group?.id
              ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
              : 'text-gray-500 bg-gray-100 '
          "
          :style="
            viewMode == 'detail' && currentGroupId == group?.id
              ? 'margin-bottom: -1px'
              : 'margin-bottom: 0px'
          "
          @click="chooseDetailTab(group)"
        >
          <div class="w-full flex-center space-x-2">
            <SynAvatar
              class="cursor-pointer text-current-800 flex-1 min-w-0"
              :src="
                isUpdateAvt
                  ? group?.avatar?.replace('original', 'original')
                  : group?.avatar?.replace('original', 'mini')
              "
              :name="group?.name"
              has-name
              custom-class="w-6 h-6"
            />
            <span
              class="
                w-6
                h-6
                rounded-full
                hover:bg-gray-50
                flex-center
                hover:fill-gray-500
              "
            >
              <SynIcon
                class="hover:text-current"
                name="close"
                @click.stop="closeTab(group)"
              />
            </span>
          </div>
        </li>
      </ul>

      <!-- syn button -->
      <div class="text-sm flex-center space-x-2 w-max mb-1.5 pr-2">
        <SynButton
          :label="$t('TASK_CREATE_GROUP_FORM_CREATE_GROUP') || 'Create Group'"
          name-icon="group-add"
          @click="onCreateGroup"
        />
      </div>
    </div>

    <div
      class="
        h-full
        shadow-md
        bg-white
        flex flex-col
        space-y-4
        overflow-y-hidden
        border-t border-current-100
      "
    >
      <div v-show="viewMode == 'list'" class="w-full h-full">
        <GroupTable
          @show-detail="onChooseGroup"
          @deleted-success="onDeleteGroupSuccess"
        />
      </div>

      <div v-show="viewMode == 'detail'" class="w-full h-full">
        <GroupDetailPage
          v-if="groupDetail?.id"
          :key="groupDetail?.id"
          :group-id="groupDetail?.id"
          :group-detail="groupDetail"
          @is-update-avt="(value) => (isUpdateAvt = value)"
          @deleted-success="deleteGroupInDetailTab"
          @update-information-success="updateInformationSuccess"
        />
      </div>
    </div>
  </div>

  <CreateGroupTask
    v-if="isShowAdd"
    @cancel="isShowAdd = false"
    @create-success="onCreateGroupSuccess"
  />
</template>
