<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import GoogleUserInfo from '@/ui/components/google/GoogleUserInfo.vue';
import calendarSyncStore from '@/store/calendar/calendar-sync';
import { GoogleService } from '@/domain/enums/google.enum';
import myProfileStore from '@/store/auth/my-profile';
import dayjs from 'dayjs';

const emits = defineEmits(['onClose']);

const _calendarSyncStore = calendarSyncStore();
const _myProfileStore = myProfileStore();

const isFetching = ref<boolean>(false);
const syncEnabled = ref<boolean>(false);
const syncDateFrom = ref<any>(null);

const syncInfo = computed(() => _calendarSyncStore.syncInfo);
const timezone = computed(() => _myProfileStore.timezone);

onMounted(() => {
  _calendarSyncStore.initGoogleCalendar();
  _getUserCalendarSync();
});

const onGoogleSignInClick = async () => {
  _calendarSyncStore.signInUserCalendarSync();
};

const onSaveSyncSettingsClick = async (buttonRef) => {
  buttonRef?.setProcessing(true);

  try {
    const result = await _calendarSyncStore.saveUserCalendarSync({
      enabled: syncEnabled.value,
      syncDateFrom: dayjs(syncDateFrom.value)?.startOf('day').toDate(),
    });

    if (result) emits('onClose');
  } catch (e) {
    console.log(e);
  }

  buttonRef?.setProcessing(false);
};

const _getUserCalendarSync = async () => {
  isFetching.value = true;

  try {
    const sync = await _calendarSyncStore.getUserCalendarSync();

    syncEnabled.value = sync?.enabled;
    syncDateFrom.value = sync?.syncDateFrom;
  } catch (e) {
    console.log(e);
  }

  isFetching.value = false;
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-5/6 md:w-[26rem]"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      {{ $t('CALENDAR_SYNC_SETTINGS') }}
    </template>
    <template #body>
      <div class="px-4">
        <div
          v-if="!isFetching && !syncInfo?.syncAccount"
          class="flex-center flex-col gap-2 py-5"
        >
          <SynIcon name="GoogleCalendar" custom-class="w-12 h-12" />
          <div class="flex items-center gap-3 mb-2">
            <span class="text-lg">{{ $t('CALENDAR_SYNC_LOGIN_GOOGLE') }}</span>
          </div>
          <VigButton @click="onGoogleSignInClick">
            <span>{{ $t('COMMON_MODULE_LOGIN') }}</span>
          </VigButton>
        </div>

        <template v-if="!isFetching && syncInfo?.syncAccount">
          <div>
            <GoogleUserInfo
              :user="syncInfo?.syncAccount"
              :service="GoogleService.CALENDAR"
              has-actions
            />
          </div>

          <div class="mt-5">
            <VigSwitch v-model="syncEnabled" align="between">
              <template #prepend>
                <div class="flex-center space-x-3">
                  <SynIcon
                    name="GoogleCalendar"
                    custom-class="w-5 h-5 mx-1.5"
                  />
                  <span>{{ $t('CALENDAR_SYNC_LABEL') }}</span>
                </div>
              </template>
            </VigSwitch>
          </div>

          <div class="mt-5 flex items-center justify-between gap-3">
            <div class="flex items-center space-x-3">
              <SynIcon
                name="ClockStart"
                custom-class="w-5 h-5 mx-1.5 fill-gray-500"
              />
              <span class="">{{ $t('CALENDAR_SYNC_FROM') }}</span>
            </div>
            <div class="w-40">
              <AtomDatePicker
                v-model="syncDateFrom"
                :timezone="timezone"
                min-date="2023-01-01"
              />
            </div>
          </div>
        </template>

        <div v-if="isFetching" class="h-40 flex-center">
          <SynIcon
            name="Spinner"
            custom-class="w-8 h-8 animate-spin fill-gray-500"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div
        v-if="!isFetching && syncInfo?.syncAccount"
        class="p-4 flex items-center justify-end gap-2 mt-1"
      >
        <VigButton
          ghost
          color="gray"
          label="COMMON_LABEL_CANCEL"
          @click="$emit('onClose')"
        />
        <VigButton
          ref="buttonSave"
          :label="syncEnabled ? 'CALENDAR_SYNC_AND_SAVE' : 'COMMON_LABEL_SAVE'"
          :disabled="!syncDateFrom"
          @click="onSaveSyncSettingsClick($refs.buttonSave)"
        />
      </div>
    </template>
  </SynModal>
</template>

<style scoped></style>
