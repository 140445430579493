<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36.001"
    height="35.999"
    viewBox="0 0 36.001 35.999"
  >
    <path
      id="Icon_awesome-pen"
      data-name="Icon awesome-pen"
      d="M20.443,6.556l9,9L9.9,35.1l-8.025.886A1.688,1.688,0,0,1,.01,34.125L.9,26.094,20.443,6.556Zm14.569-1.34L30.785.989a3.377,3.377,0,0,0-4.775,0L22.034,4.965l9,9,3.976-3.976a3.377,3.377,0,0,0,0-4.775Z"
      transform="translate(0.001 -0.001)"
    />
  </svg>
</template>
