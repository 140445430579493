<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 381 275"
  >
    <path
      d="M466.43,279H280.56C256.05,279,236,302,236,330.2V502.8C236,531,256.05,554,280.56,554H466.44C491,554,511,531,511,502.8V330.21C511,301.53,490.94,279,466.43,279ZM452.59,451a9.94,9.94,0,0,1-8.74,2.69c-5.06-.83-9.3-5.93-9.48-11L432,373.16,309.25,495.89c-4.35,4.36-11.95,3.66-16-1.87a10.54,10.54,0,0,1,.87-13.71L416.6,357.78l-69.78-2.39a11.38,11.38,0,0,1-10.8-12.6,9.84,9.84,0,0,1,10.37-8.43l94.88,3.26a11.43,11.43,0,0,1,10.88,10.88l3.26,95.17A9.77,9.77,0,0,1,452.59,451Z"
      transform="translate(-236 -279)"
    />
    <path
      d="M589.74,307.89a16.39,16.39,0,0,0-6.27,3.58L526,357.38V475.72l57.84,45.92c10.69,8.67,23.95,3.57,30.21-11.23a40.8,40.8,0,0,0,3-15.3V337.48C617,318.61,604.11,303.3,589.74,307.89Z"
      transform="translate(-236 -279)"
    />
  </svg>
</template>
