<script setup lang="ts">
import { ref, computed } from 'vue';
import { UserStatusEnum } from '@/domain/enums/UserStatusEnum';
import dashboardStore from '@/store/dashboard';
import { AdminType } from '@/ui/common/constants/constant';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';

const props = defineProps<{
  userInfo: any;
  isHiddenInfoDetail?: boolean;
}>();

defineEmits(['clickName']);

const _dashboardStore = dashboardStore();
const isShowProfile = ref(false);
// const currentUserId = ref(0);

const currentPreview = ref({} as any);
// const openDocument = (doc) => {
//   currentPreview.value = doc;
// };
const closePreview = () => {
  currentPreview.value = {};
};
const myCallStatus = computed(() =>
  _dashboardStore.getUserStatus(props.userInfo?.id)
);

const onClickAvatar = () => {
  isShowProfile.value = true;
};
</script>

<template>
  <div
    :title="$t('COMMON_LABEL_PROFILE')"
    class="flex justify-center relative"
    :class="{
      'cursor-pointer': !isHiddenInfoDetail,
    }"
  >
    <div class="relative w-16 h-16" @click.stop="onClickAvatar">
      <syn-avatar
        class="cursor-pointer"
        custom-class="w-16 h-16"
        size="small"
        :is-on-call="myCallStatus?.includes(UserStatusEnum.OnCall)"
        :src="userInfo?.avatarUrl?.replace('original', 'small')"
        :name="userInfo?.firstName"
      />
    </div>
  </div>
  <div
    class="
      flex-center flex-col
      space-x-2
      font-semibold
      cursor-pointer
      my-1
      text-current-700 text-lg
    "
    @click="$emit('clickName', true)"
  >
    <span>
      {{ userInfo?.firstName }}
    </span>
    <SynTag
      v-if="userInfo?.adminType === AdminType.Visitor"
      size="tiny"
      custom-class="px-1 border border-current-100 bg-white text-gray-800"
    >
      <span class="italic">
        {{ $t('COMMON_LABEL_VISITOR') || 'Visitor' }}
      </span>
    </SynTag>
  </div>
  <SynPreviewDoc
    v-if="currentPreview && currentPreview.avatarUrl"
    :src="currentPreview.avatarUrl"
    is-image
    @close-preview="closePreview"
  />

  <ModalProfileUser
    v-if="isShowProfile"
    :profile-id="userInfo?.userId || userInfo?.id"
    :hidden-detail="isHiddenInfoDetail"
    @cancel="isShowProfile = false"
  />
</template>
