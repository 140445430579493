<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { getCompanyOwnName } from '@/ui/hooks/commonFunction';
import myProfileStore from '@/store/auth/my-profile';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconCustom.vue';
import { adminTypeStyle } from '@/ui/common/constants/user';
import {
  EPlanId,
  getUiByPlanSubState,
} from '@/application/constants/plan.const';
import { EPlanState } from '@/application/types/plan.types';

const props = withDefaults(
  defineProps<{
    orgInfo: any;
    members: any[];
    isActive?: boolean;
    invitationInfo?: any;
  }>(),
  {
    isActive: false,
    invitationInfo: {},
  }
);

const emit = defineEmits<{
  (e: 'onSelect'): void;
  (e: 'onAcceptInvitation', token: string): void;
}>();

const isShowMembers = ref(false);
onMounted(() => {
  setTimeout(() => {
    isShowMembers.value = true;
  }, 50);
});

const myProfile = computed(() => myProfileStore().myProfile);
const organizationInfo = computed(() => props.orgInfo);
const isInvitation = computed(() => props.invitationInfo?.isInvitation);

// const classByType = computed(() => {
//   if (isInvitation.value) return 'border-dashed border-orange-500';
//   if (props.isActive) return 'border-current border-2 ring ring-current ring-opacity-30';
//   return 'border-gray-300 hover:border-current cursor-pointer';
// });

const prepareUIbyStateCode = computed(() => {
  let result = getUiByPlanSubState(props.orgInfo);

  return {
    ...result,
    style: {
      ...result?.style,
      borderCard: isInvitation.value
        ? 'border-dashed border-orange-500'
        : props.isActive
        ? 'border-current border-2 ring ring-current ring-opacity-30'
        : result?.style?.borderCard
        ? result?.style?.borderCard
        : 'border-current-100 hover:border-current cursor-pointer',
    },
  };
});

const onJoinNow = () => {
  const path1 = props.invitationInfo?.invitationLink?.split('c%3D')?.pop();

  const token = path1?.split('&apn=')[0];

  emit('onAcceptInvitation', token);
};

const onClickCard = () => {
  if (isInvitation.value || props.isActive) return;
  emit('onSelect');
};
// const hasNotificationInNotActiveOrganization = computed(() => {
//   return (
//     organizationInfo.value &&
//     (organizationInfo.value?.notificationCount > 0 ||
//       organizationInfo.value?.unSeenConversationsCount > 0)
//   );
// });
</script>
<template>
  <div
    class="
      w-full
      relative
      hover-to-show__parent
      border
      rounded-xl
      flex flex-col
      px-1
      shadow
    "
    :class="`${prepareUIbyStateCode?.style?.borderCard}`"
    style="height: 13rem"
    @click="onClickCard"
  >
    <div class="flex-1 p-4 flex flex-col">
      <SynAvatar
        has-name
        custom-class="w-12 h-12 border border-gray "
        :src="organizationInfo?.logoUrl"
        :name="getCompanyOwnName(organizationInfo?.name)"
      >
        <template #name>
          <div class="flex items-center space-x-2">
            <span
              class="
                font-semibold
                overflow-hidden
                flex-nowrap
                text-ellipsis text-overflow-hidden-line
              "
              :class="isActive ? 'text-current-600' : 'text-gray-600'"
            >
              {{ getCompanyOwnName(organizationInfo?.name) }}
            </span>
            <AtomPremiumLabel
              v-if="
                organizationInfo?.planId == EPlanId.PRO ||
                organizationInfo?.planId == EPlanId.VIP
              "
              :label="organizationInfo?.planId"
            />
            <SynTag
              v-else
              size="small"
              class="bg-current-50 text-current-500 rounded-full"
            >
              <span class="px-2">
                {{ organizationInfo?.planId }}
              </span>
            </SynTag>
          </div>
          <div class="flex items-center justify-between w-full">
            <div class="text-sm text-center text-gray-500">
              {{ organizationInfo?.typeName }}
            </div>
            <span
              v-if="organizationInfo?.modelName"
              class="bg-current-50 text-current-500 px-2 py-0.5 rounded text-xs"
            >
              {{ organizationInfo?.modelName }}</span
            >
          </div>
        </template>
      </SynAvatar>

      <div
        v-if="isShowMembers"
        class="flex-1 flex items-center pt-1 justify-between"
      >
        <span
          v-if="
            members?.length === 1 &&
            members?.filter((user) => user.userId == myProfile.id)?.length > 0
          "
          class="text-xs text-gray-400"
        >
          {{ $t('COMMON_LABEL_ONLY_MEMBER') || 'You are the only member' }}
        </span>
        <SynListAvatar
          v-else
          :users="members"
          :custom-class="'w-7 h-7 text-2xs'"
          :max-length="5"
          trigger="mouseenter"
        />
      </div>
      <div v-else class="flex-1 flex items-center pt-1">
        <div class="rounded-full w-24 h-4 animate-pulse bg-gray-200"></div>
      </div>

      <!-- ROLE & LAST LOGIN TIME -->
      <div class="flex mt-1 items-center justify-between">
        <div
          class="px-1.5 py-0.5 rounded-md text-2xs text-white"
          style="width: fit-content"
          :class="adminTypeStyle[organizationInfo?.adminType].backgroundColor"
        >
          <span>{{
            $t(adminTypeStyle[organizationInfo?.adminType].labelKey)
          }}</span>
        </div>
        <SynButton
          v-if="isInvitation"
          :title="'Create a new workspace'"
          class="rounded-full bg-current"
          @click.stop="onJoinNow"
        >
          <span class="text-white text-xs">
            {{ $t('COMMON_LABEL_JOIN_NOW') || 'Join now' }}
          </span>
        </SynButton>
        <template v-else>
          <div
            v-if="organizationInfo?.lastLoginDate"
            class="text-xs flex-center space-x-1 text-gray-500"
          >
            <span> {{ $t('COMMON_LABEL_LAST_LOGIN') || 'Last logged' }} </span>:
            <SynLabelDateTime
              format="date_time"
              :datetime="organizationInfo?.lastLoginDate"
            />
          </div>

          <!--        <span>-->
          <!--          {{ $filters.dayjs(organizationInfo?.lastLoginDate) }}-->
          <!--        </span>-->
        </template>
      </div>
    </div>

    <!-- NOTIFICATION INFO -->

    <div
      class="
        flex
        items-center
        space-x-4
        border-t border-gray-100
        justify-between
        h-10
        p-4
      "
    >
      <template v-if="isInvitation">
        <div class="flex space-x-2 items-center text-xs">
          <span class="text-gray-500">
            {{ $t('ORGANIZATION_ITEM_INVITATION_EXPIRATION_LABEL') }}
          </span>
          <span class="text-red-500">
            <SynLabelDateTime
              format="date_time"
              :datetime="invitationInfo?.tokenExpirationDate"
            />
          </span>
        </div>
      </template>

      <div v-else class="flex items-center justify-between w-full">
        <div class="flex items-center space-x-4">
          <div
            v-if="organizationInfo?.unSeenConversationsCount"
            class="flex-center space-x-1 rounded-full relative"
          >
            <SynIcon
              name="Message"
              custom-class=" fill-current text-current"
              style="width: 1.125rem; height: 1.125rem"
              size="medium"
            />
            <span
              class="
                absolute
                -right-2.5
                p-2
                rounded-full
                bg-red-500
                text-white
                border border-white
                w-4
                h-4
                flex-center
              "
              style="top: -0.3rem; font-size: 10px"
            >
              {{
                organizationInfo?.unSeenConversationsCount > 9
                  ? '+9'
                  : organizationInfo?.unSeenConversationsCount
              }}
            </span>
          </div>
          <div
            v-if="organizationInfo?.notificationCount"
            class="flex-center hover:fill-current rounded-full relative"
          >
            <SynIcon
              name="bell"
              custom-class="text-current-500 fill-current"
              style="width: 1.125rem; height: 1.125rem"
              size="medium"
            />
            <span
              class="
                absolute
                -right-2.5
                p-2
                rounded-full
                bg-red-500
                text-white
                border border-white
                w-4
                h-4
                flex-center
              "
              style="top: -0.3rem; font-size: 10px"
            >
              {{
                organizationInfo?.notificationCount > 9
                  ? '+9'
                  : organizationInfo?.notificationCount
              }}
            </span>
          </div>
        </div>
        <div class="flex items-center space-x-4 justify-end w-2/3">
          <!--          {{ prepareUIbyStateCode.code }}-->

          <span
            v-if="
              organizationInfo?.planId !== EPlanId.FREE &&
              organizationInfo?.planId !== EPlanId.BASIC
            "
            v-vig-tooltip="
              $t(prepareUIbyStateCode?.message?.shortContent, {
                remainDays: organizationInfo?.planRemainDays,
                planId: organizationInfo?.planId,
              })
            "
            :class="`${prepareUIbyStateCode?.style?.colorText} text-xs italic truncate`"
            v-html="
              $t(prepareUIbyStateCode?.message?.shortContent, {
                remainDays: organizationInfo?.planRemainDays,
                planId: organizationInfo?.planId,
              })
            "
          >
          </span>
          <span
            v-else-if="organizationInfo?.stateCode == EPlanState.INIT"
            class="text-xs italic truncate text-current-500"
          >
            {{ $t('PAYMENT_LABEL_SELECT_PLAN') }}
          </span>
        </div>
      </div>
      <!--      <span-->
      <!--        v-if="hasNotificationInNotActiveOrganization"-->
      <!--        class="flex relative h-3 w-3"-->
      <!--      >-->
      <!--        <span-->
      <!--          class="-->
      <!--            animate-ping-->
      <!--            absolute-->
      <!--            inline-flex-->
      <!--            h-full-->
      <!--            w-full-->
      <!--            rounded-full-->
      <!--            bg-red-400-->
      <!--            opacity-75-->
      <!--          "-->
      <!--        ></span>-->
      <!--        <span-->
      <!--          class="relative inline-flex rounded-full h-3 w-3 bg-red-500"-->
      <!--        ></span>-->
      <!--      </span>-->
      <!--      <span class="h-3 w-3 bg-red-500 rounded-full border border-white"></span>-->
    </div>
  </div>
</template>
