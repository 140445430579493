import { ROUTER_GROUP } from './../../application/constants/system/router.const';
// Dashboard
import OverviewPage from '@/ui/pages/overview/OverviewPage.vue';
import OverviewMonitorPage from '../pages/overview/OverviewMonitorPage.vue';
import OverviewMembers from '../pages/overview/OverviewMembers.vue';
import OverviewGroups from '../pages/overview/OverviewGroups.vue';
import OverviewDomains from '../pages/overview/OverviewDomains.vue';
import OverviewFinishedTasks from '@/ui/pages/overview/FinishedTaskPage.vue';
import OverviewCreatedTasks from '@/ui/pages/overview/CreatedTaskPage.vue';
import PerformanceOverviewPage from '@/ui/pages/overview/PerformanceOverviewPage.vue';

import OrganizationInviteUser from '../pages/organization/org-invite-user.vue';
import TaskDetail from '../pages/task-detail/TaskDetailPage.vue';
import TasksPage from '../pages/tasks/TasksPage.vue';
import UserTasks from '../pages/tasks/UserTasks.vue';
import MyTasks from '../pages/tasks/MyTasks.vue';
import GroupTasks from '../pages/tasks/GroupTasks.vue';
import DomainTasks from '../pages/tasks/DomainTasks.vue';
import GanttChart from '../pages/tasks/GanttChart.vue';
import WorkingTime from '../pages/overview/working-time/WorkingTimePage.vue';
import UserManagement from '../pages/usermanagement/UserManagement.vue';
import GroupManagement from '../pages/groupmanagement/GroupManagement.vue';
import DepartmentsManagement from '@/ui/pages/managements/departments/DepartmentsManagement.vue';
import ProjectManagement from '../pages/project-management/ProjectManagement.vue';
import OrganizationSetting from '../pages/settings/OrganizationSetting.vue';
import Settings from '../pages/settings/index.vue';
import Notification from '../pages/notification/Notification.vue';
import Calendars from '@/ui/pages/calendars/Calendars.vue';
import PersonalSetting from '../pages/settings/PersonalSettings.vue';
import Profile from '../pages/profile/Profile.vue';
import UserDetailPage from '../pages/usermanagement/UserDetailPage.vue';
import ZaloCallback from '../pages/zalo/ZaloCallback.vue';
// import VideoTutorial from '../pages/videotutorial/VideoTutorial.vue';
import DayoffDetail from '@/ui/pages/dayoff-detail/index-dayyoff-detail.vue';
import InformationOrgSetting from '@/ui/modules/settings/organizations/InformationOrgSetting.vue';
import PlansSetting from '../pages/settings/PlansSetting.vue';
import MessagesSupport from '@/ui/pages/messages/MessagesSupport.vue';
import TasksManagement from '@/ui/pages/task-management/TasksManagement.vue';
import SharedTasksManagement from '@/ui/pages/task-management/SharedTasksManagement.vue';
import TaskWorkflowManagement from '@/ui/pages/task-management/TaskWorkflowManagement.vue';
import WhiteboardPage from '@/ui/pages/whiteboards/WhiteboardPage.vue';
import WorkflowStepManagement from '@/ui/pages/task-management/WorkflowStepManagement.vue';
import WorkflowStepTypeManagement from '@/ui/pages/task-management/WorkflowStepTypeManagement.vue';
import DesktopDrawer from '@/ui/components/drawer/DesktopDrawer.vue';
import WhiteboardList from '@/ui/pages/whiteboards/WhiteboardList.vue';
import OverviewWorkflows from '@/ui/pages/overview/OverviewWorkflows.vue';
import StartWorkingSettings from '@/ui/modules/settings/start-working/StartWorkingSettings.vue';
import systemConfig from '@/application/constants/system-config.const';

export const RouterByModuleName = {
    USER_TASKS: 'UserTasks',
    GROUP_TASKS: 'GroupTasks',
};
export default [
    {
        path: '/dashboard',
        name: 'OverviewPage',
        component: OverviewPage,
        redirect: { name: 'OverviewMembers' },
        children: [
            {
                path: 'monitor',
                name: 'OverviewMonitorPage',
                component: OverviewMonitorPage,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,

                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.FEATURE,
                        index: 0,
                    },
                    titleKey: 'COMMON_LABEL_OVERVIEW',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_ACTIVITIES_OVERVIEW',
                    title: 'Overview',
                    icon: 'home',
                },
            },
            {
                path: 'members',
                name: 'OverviewMembers',
                component: OverviewMembers,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.FEATURE,
                        index: 7,
                    },
                    titleKey: 'SIDEBAR_LABEL_MY_TEAM',
                    descriptionKey: 'QUICK_MENU_DESCRIPTION_USERS_AND_GROUPS',
                    title: 'Team',
                    icon: 'user',
                },
            },
            {
                path: 'groups',
                name: 'OverviewGroups',
                component: OverviewGroups,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.FEATURE,
                        index: 5,
                    },
                    titleKey: 'SIDEBAR_LABEL_MY_GROUPS',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_DISCUSSIONS_AND_TASKS_GROUPS',
                    title: 'My Groups',
                    icon: 'groups',
                },
            },
            {
                path: 'domains',
                name: 'OverviewDomains',
                component: OverviewDomains,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.FEATURE,
                        index: 6,
                    },
                    titleKey: 'COMMON_MODULE_PROJECT_MANAGEMENT',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_DOMAINS_AND_PROJECTS',
                    title: 'My Domains',
                    icon: 'project',
                },
            },
            {
                path: 'finished-tasks',
                name: 'OverviewFinishedTasks',
                component: OverviewFinishedTasks,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: false,
                    titleKey: 'OVERVIEW_FINISHED_TASKS',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_TODAY_S_FINISHED_TASKS',
                    title: 'Finished tasks',
                    icon: 'CheckCircle',
                },
            },
            {
                path: 'created-tasks',
                name: 'OverviewCreatedTasks',
                component: OverviewCreatedTasks,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: false,
                    titleKey: 'OVERVIEW_CREATED_TASKS',
                    descriptionKey: 'QUICK_MENU_DESCRIPTION_TODAY_S_NEW_TASKS',
                    title: 'Created tasks',
                    icon: 'note',
                },
            },
            {
                path: 'performance',
                name: 'PerformanceOverview',
                component: PerformanceOverviewPage,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                },
            },
            {
                path: 'workflows',
                name: 'OverviewWorkflows',
                component: OverviewWorkflows,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.FEATURE,
                        index: 9,
                    },
                    titleKey: 'OVERVIEW_WORKFLOWS',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_TASKS_AND_ACTIVITIES_WORKFLOW',
                    title: 'Workflows',
                    icon: 'Flow',
                },
            },
            {
                path: 'performance',
                name: 'PerformanceOverview',
                component: PerformanceOverviewPage,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.FEATURE,
                        index: 10,
                    },
                    titleKey: 'COMMON_LABEL_PERFORMANCE',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_PERFORMANCE_WORK_PROGRESS',
                    title: 'Performance',
                    icon: 'timer',
                },
            },
        ],
    },

    {
        path: '/invitation',
        name: 'Inviation',
        component: OrganizationInviteUser,
        meta: { layout: 'empty' },
    },

    {
        path: '/notifications',
        name: 'Notifications',
        component: Notification,
        meta: {
            layout: 'dashboard',
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.FEATURE,
                index: 8,
            },
            titleKey: 'COMMON_NOTIFICATION_TITLE',
            descriptionKey:
                'QUICK_MENU_DESCRIPTION_TASKS_AND_ACTIVITIES_NOTIFICATIONS',
            title: 'Notification',
            icon: 'Bell',
        },
    },
    {
        path: '/calendars',
        name: 'Calendars',
        component: Calendars,
        meta: {
            layout: 'dashboard',
            isShowTaskDrawer: true,

            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.FEATURE,
                index: 3,
            },
            titleKey: 'CALENDAR_TITLE',
            descriptionKey:
                'QUICK_MENU_DESCRIPTION_EVENTS_AND_HOLIDAYS_CALENDAR',
            title: 'Calendar',
            icon: 'calendars',
        },
    },
    {
        path: '/group-management',
        name: 'GroupManagement',
        component: GroupManagement,
        meta: {
            layout: 'dashboard',
            isSettingMenu: true,
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.MANAGEMENT,
                index: 1,
            },
            titleKey: 'DOCUMENT_TITLE_GROUP',
            descriptionKey: 'QUICK_MENU_DESCRIPTION_WORK_GROUPS_MANAGEMENT',
            title: 'Group Management',
            icon: 'Group',
        },
    },
    {
        path: '/departments-management',
        name: 'DepartmentsManagement',
        component: DepartmentsManagement,
        meta: {
            layout: 'dashboard',
            isSettingMenu: true,
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.MANAGEMENT,
                index: 3,
            },
            titleKey: 'COMMON_LABEL_DEPARTMENT',
            descriptionKey:
                'QUICK_MENU_DESCRIPTION_DEPARTMENTS_AND_SERVICES_MANAGEMENT',
            title: 'Department Management',
            icon: 'Department',
        },
    },
    {
        path: '/working-time',
        name: 'WorkingTime',
        component: WorkingTime,
        meta: {
            layout: 'dashboard',
            isSettingMenu: true,
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.MANAGEMENT,
                index: 4,
            },
            titleKey: 'COMMON_MODULE_DASHBOARD',
            descriptionKey: 'QUICK_MENU_DESCRIPTION_WORKING_TIME_MANAGEMENT',
            title: 'Working Time Management',
            icon: 'dashboard',
        },
    },
    {
        path: '/tasks-management',
        name: 'TasksManagement',
        component: TasksManagement,
        meta: {
            layout: 'dashboard',
            isSettingMenu: true,
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.MANAGEMENT,
                index: 5,
            },
            titleKey: 'COMMON_MODULE_TASK',
            descriptionKey: 'QUICK_MENU_DESCRIPTION_TASKS_MANAGEMENT',
            title: 'Task Management',
            icon: 'note',
        },
        redirect: { name: 'SharedTasksManagement' },
        children: [
            {
                path: 'shared-tasks',
                name: 'SharedTasksManagement',
                component: SharedTasksManagement,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: true,
                },
            },
            {
                path: 'workflow',
                name: 'TaskWorkflowManagement',
                component: TaskWorkflowManagement,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: false,
                },
            },
            {
                path: 'workflow-steps',
                name: 'WorkflowStepManagement',
                component: WorkflowStepManagement,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: false,
                },
            },
            {
                path: 'workflow-step-types',
                name: 'WorkflowStepTypeManagement',
                component: WorkflowStepTypeManagement,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: false,
                },
            },
        ],
    },
    {
        path: '/tasks',
        // path: '/tasks/',
        name: 'Tasks', //  Used this name field in TeamSidebar.vue
        component: TasksPage,
        meta: { layout: 'dashboard', isOverview: true, isShowTaskDrawer: true },
        children: [
            {
                path: 'users/:id',
                name: RouterByModuleName.USER_TASKS,
                component: UserTasks,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                },
            },
            {
                path: 'my-tasks',
                name: 'MyTasks',
                component: MyTasks,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,

                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.FEATURE,
                        index: 1,
                    },
                    titleKey: 'TASKTAB_LABEL_MYTASK',
                    descriptionKey: 'QUICK_MENU_DESCRIPTION_MY_TASKS',
                    title: 'My Tasks',
                    icon: 'note',
                },
            },
            {
                path: 'groups/:id',
                name: RouterByModuleName.GROUP_TASKS,
                component: GroupTasks,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                },
            },
            {
                path: 'domains/:id',
                name: 'DomainTasks',
                component: DomainTasks,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                },
            },
            {
                path: 'gantt/:id',
                name: 'GanttChart',
                component: GanttChart,
                meta: {
                    layout: 'dashboard',
                    isOverview: true,
                    isShowTaskDrawer: true,
                },
            },
        ],
    },
    {
        path: '/tasks/:taskCode',
        // path: '/tasks/',
        name: 'TaskDetail', //  Used this name field in TeamSidebar.vue
        component: TaskDetail,
        meta: { layout: 'TaskDetail' },
    },
    {
        path: '/calendars/:id',
        name: 'DayoffDetail', //  Used this name field in TeamSidebar.vue
        component: DayoffDetail,
        meta: { layout: 'TaskDetail' },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            layout: 'dashboard',
            isProfile: true,

            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.MANAGEMENT,
                index: 6,
            },
            titleKey: 'COMMON_LABEL_MY_PROFILE',
            descriptionKey:
                'QUICK_MENU_DESCRIPTION_ACCOUNT_AND_PERSONAL_INFORMATIONS',
            title: 'My Profile',
            icon: 'Profile',
        },
    },
    {
        path: '/zalo/callback',
        name: 'ZaloAccessToken',
        component: ZaloCallback,
        meta: { layout: 'zalo' },
    },

    {
        path: '/user-management',
        name: 'UserManagement',
        component: UserManagement,
        meta: {
            layout: 'dashboard',
            isSettingMenu: true,
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.MANAGEMENT,
                index: 0,
            },
            titleKey: 'COMMON_MODULE_USER_MANAGEMENT',
            descriptionKey:
                'QUICK_MENU_DESCRIPTION_USERS_AND_GROUPS_MANAGEMENT',
            title: 'User Management',
            icon: 'UserManagement',
        },
        isShowChildMenu: false,
    },
    {
        path: '/user-management/:idUser',
        name: 'UserDetailPage',
        component: UserDetailPage,
        meta: { layout: 'dashboard' },
    },
    {
        path: '/project-management',
        name: 'ProjectManagement',
        component: ProjectManagement,
        meta: {
            layout: 'dashboard',
            isSettingMenu: true,
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.MANAGEMENT,
                index: 2,
            },
            titleKey: 'COMMON_MODULE_PROJECT_MANAGEMENT',
            descriptionKey:
                'QUICK_MENU_DESCRIPTION_DOMAINS_AND_PROJECTS_MANAGEMENT',
            title: 'Project Management',
            icon: 'Project',
        },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            layout: 'dashboard',
            isSettingMenu: true,
            isShowTaskDrawer: true,
        },
        redirect: { name: 'OrganizationSetting' },
        children: [
            {
                path: 'organization',
                name: 'OrganizationSetting',
                component: OrganizationSetting,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.SETTING,
                        index: 0,
                    },
                    titleKey: 'COMMON_LABEL_ORGANIZATION',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_ORGANIZATION_SETTINGS',
                    title: 'Organization Infomation',
                    icon: 'Organization',
                },
            },
            {
                path: 'personal',
                name: 'PersonalSetting',
                component: PersonalSetting,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.SETTING,
                        index: 1,
                    },
                    titleKey: 'COMMON_LABEL_PERSONAL_SETTINGS',
                    descriptionKey: 'QUICK_MENU_DESCRIPTION_PRIVACY_SETTINGS',
                    title: 'Personal settings',
                    icon: 'Personal',
                },
            },
            {
                path: 'start-working',
                name: 'StartWorkingSetting',
                component: StartWorkingSettings,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.SETTING,
                        index: 2,
                    },
                    titleKey: 'SETTING_SW_CONDITION_TITLE',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_START_WORKING_SETTINGS',
                    title: 'Start working settings',
                    icon: 'Start',
                },
            },
            {
                path: 'plans',
                name: 'PlansSetting',
                component: PlansSetting,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: true,
                    isShowInQuickMenu: true,
                    group: {
                        ...ROUTER_GROUP.SETTING,
                        index: 3,
                    },
                    titleKey: 'COMMOM_LABEL_PLANS_SETTING',
                    descriptionKey:
                        'QUICK_MENU_DESCRIPTION_PACKAGE_AND_PAYMENT',
                    title: 'Plans and Billing',
                    icon: 'Premium',
                },
            },
            {
                path: 'organization/information',
                name: 'InformationOrgSetting',
                component: InformationOrgSetting,
                meta: {
                    layout: 'dashboard',
                    isSettingMenu: true,
                    isShowTaskDrawer: true,
                },
            },
        ],
    },
    // {
    //     path: '/project-management',
    //     name: 'SetupProjectManagement',
    //     component: ProjectManagement,
    //     meta: { layout: 'SetupTeam' },
    // },
    // {
    //     path: '/profile',
    //     name: 'SetupProfile',
    //     component: Profile,
    //     meta: { layout: 'SetupTeam', isProfile: true },
    // },
    // {
    //     path: '/video-tutorial',
    //     name: 'VideoTutorial',
    //     component: VideoTutorial,
    //     meta: { layout: 'SetupTeam' },
    // },
    {
        path: '/chat-support/:memberId',
        name: 'MessagesSupport',
        component: MessagesSupport,
        meta: { layout: 'EmptyAuth' },
    },
    {
        path: '/whiteboards',
        name: 'WhiteboardPage',
        component: WhiteboardPage,
        meta: {
            layout: 'dashboard',
            isShowTaskDrawer: true,
            isShowInQuickMenu: true,
            group: {
                ...ROUTER_GROUP.FEATURE,
                index: 10,
            },
            titleKey: 'COMMON_LABEL_MY_DESKTOP',
            descriptionKey: 'QUICK_MENU_DESCRIPTION_WHITEBOARD_FREE_DESIGN',
            title: 'My whiteboard',
            icon: 'desktop',
            permissionBySettings: [
                {
                    functionCode: systemConfig.WHITEBOARD_FUNCTION,
                    functionValue: '1',
                },
            ],
        },
        redirect: { name: 'WhiteboardList' },
        children: [
            {
                path: 'list',
                name: 'WhiteboardList',
                component: WhiteboardList,
                meta: { layout: 'dashboard', isShowTaskDrawer: true },
            },
            {
                path: 'detail/:id',
                name: 'WhiteboardDetail',
                component: DesktopDrawer,
                meta: { layout: 'dashboard', isShowTaskDrawer: true },
            },
            {
                path: 'fp-list',
                name: 'FpWhiteboardList',
                component: WhiteboardList,
                meta: { layout: 'EmptyAuth', isShowTaskDrawer: true },
            },
            {
                path: 'fp-detail/:id',
                name: 'FpWhiteboardDetail',
                component: DesktopDrawer,
                meta: { layout: 'EmptyAuth', isShowTaskDrawer: true },
            },
        ],
    },
];
