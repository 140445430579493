import { computed } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import myProfileStore from '@/store/auth/my-profile';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

const utcDate = computed(() => myProfileStore().getTimezone);
const timezone = computed(() => {
    if (utcDate.value == '+7') return 'Asia/Bangkok';
    if (utcDate.value == '-5') {
        return 'America/New_York';
    } else {
        return 'Europe/Paris';
    }
});

const timezoneFromUtc = (utc) => {
    if (utc == '+7') return 'Asia/Bangkok';
    if (utc == '-5') {
        return 'America/New_York';
    } else {
        return 'Europe/Paris';
    }
};

// Chuyển sang giờ theo timezone user profile
export const formatDate = (date) => {
    const hour = dayjs(date).tz(timezone.value).get('hour');
    const minute = dayjs(date).tz(timezone.value).get('minute');
    const obj = {
        date: dayjs(date).tz(timezone.value).format('MMM DD, YYYY'),
        time: dayjs(date).tz(timezone.value).format('hh:mm A'),
        day: dayjs(date).tz(timezone.value).format('MM-DD-YYYY'),
        formatDDMMYYY: dayjs(date).tz(timezone.value).format('DD/MM/YYYY'),
        formatddddMMMMDYYYY: dayjs(date)
            .tz(timezone.value)
            .format('dddd, MMMM D, YYYY'),
        hour: hour,
        minute: minute,
        minuteValue: hour * 60 + minute,
        datetime: new Date(date),
    };
    return obj;
};

// Giờ hiện tại của timezone current user profile
export const currentTimeUser = () => {
    const hour = dayjs(new Date()).tz(timezone.value).get('hour');
    const minute = dayjs(new Date()).tz(timezone.value).get('minute');
    const objDate = {
        hour: hour,
        minute: minute,
        minuteValue: hour * 60 + minute,
        day: dayjs(new Date()).tz(timezone.value).format('MM-DD-YYYY'),
        timezone: timezone.value,
        utcDate: utcDate.value,
    };
    return objDate;
};

// Giờ hiện tại của timezone thiết bị
export const currentTimeDevice = () => {
    const datecurrent = new Date();
    const utc = -(datecurrent.getTimezoneOffset() / 60);
    const timezoneName =
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Bangkok';
    const hour = dayjs(new Date()).get('hour');
    const minute = dayjs(new Date()).get('minute');
    const objDate = {
        hour: hour,
        minute: minute,
        minuteValue: hour * 60 + minute,
        day: dayjs(new Date()).format('MM-DD-YYYY'),
        timezone: timezoneName,
        utcDate: utc,
    };
    return objDate;
};

// Giờ hiện tại của user theo utc
export const formatCurrentDateWithUtc = (utc) => {
    const hour = dayjs(new Date()).tz(timezoneFromUtc(utc)).get('hour');
    const minute = dayjs(new Date()).tz(timezoneFromUtc(utc)).get('minute');
    const objDate = {
        hour: hour,
        minute: minute,
        minuteValue: hour * 60 + minute,
        day: dayjs(new Date()).tz(timezoneFromUtc(utc)).format('MM-DD-YYYY'),
        timezone: timezoneFromUtc(utc),
        utcDate: utc,
        millisecondValue: new Date().getTime(),
    };
    return objDate;
};

// Chuyển sang giờ theo timezone user profile
export const formatDateUser = (date, utc) => {
    const hour = dayjs(date).tz(timezoneFromUtc(utc)).get('hour');
    const minute = dayjs(date).tz(timezoneFromUtc(utc)).get('minute');
    const obj = {
        date: dayjs(date).tz(timezoneFromUtc(utc)).format('MMM DD, YYYY'),
        time: dayjs(date).tz(timezoneFromUtc(utc)).format('hh:mm A'),
        day: dayjs(date).tz(timezoneFromUtc(utc)).format('MM-DD-YYYY'),
        formatDDMMYYY: dayjs(date)
            .tz(timezoneFromUtc(utc))
            .format('DD/MM/YYYY'),
        hour: hour,
        minute: minute,
        minuteValue: hour * 60 + minute,
        datetime: new Date(date),
        utcDate: utc,
        millisecondValue: new Date(date).getTime(),
    };
    return obj;
};

export const getDayInUtc = (value) => {
    if (!value) return null;

    return dayjs(value).startOf('day').utc(true).format();
};

export const getDayOfWeekString = (value, format = 'dddd') => {
    if (!value) return null;
    return dayjs(value).format(format);
};

export const getOnDayOfWeekString = (value) => {
    const lang = dayjs.locale();
    switch (lang) {
        case 'vi':
            return `vào ${getDayOfWeekString(value)}`;
        case 'fr':
            return `le ${getDayOfWeekString(value)}`;
        default:
            return `on ${getDayOfWeekString(value)}`;
    }
};

export const getOnDateOfMonthString = (value) => {
    if (!value) return null;
    const lang = dayjs.locale();
    switch (lang) {
        case 'vi':
            return `vào ngày ${dayjs(value).format('Do')}`;
        case 'fr':
            return `le ${dayjs(value).format('Do')}`;
        default:
            return `on the ${dayjs(value).format('Do')}`;
    }
};

export const getOnDateOfYearString = (value) => {
    if (!value) return null;
    const lang = dayjs.locale();
    switch (lang) {
        case 'vi':
            return `vào ngày ${dayjs(value).format('D MMMM')}`;
        case 'fr':
            return `le ${dayjs(value).format('D MMMM')}`;
        default:
            return `on ${dayjs(value).format('MMMM D')}`;
    }
};
