<script setup lang="ts">
import GetCode from '@/ui/pages/auth/GetCode.vue';
import { computed, onMounted, ref } from 'vue';
import registerStore from '@/store/auth/register';
import { ErrorNotificationLogin } from '@/ui/common/constants/constant';
import { initGoogleCaptcha } from '@/ui/components/layouts/logic/init-google-capcha';
import { clearLocalStorage } from '@/ui/hooks/storageHook';

const props = defineProps<{
  phoneNumber: string;
  sessionInfoDefault: any;
  locale: string;
}>();
const emit = defineEmits<{
  (e: 'onVerifySuccess', code: string);
  (e: 'onResendCode');
}>();

const _registerStore = registerStore();

// GET CODE
const isResend = ref(false);
const isLoadingGetCode = ref(false);
const sessionInfo = ref({});

onMounted(() => {
  sessionInfo.value = props.sessionInfoDefault;
});

const onVerify = async (code) => {
  if (isLoadingGetCode.value) return;

  responseErrorCode.value = '';
  isLoadingGetCode.value = true;
  const data = {
    phoneNumber: props.phoneNumber,
    code: code,
    sessionInfo: sessionInfo.value,
  };
  try {
    await _registerStore.verifyUserPhoneNumber(data);
    emit('onVerifySuccess', code);
  } catch (response: any) {
    if (response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      onResendCode();
      return;
    }

    parserErrorMessage(response);
    isResend.value = false;
  } finally {
    isLoadingGetCode.value = false;
  }
};
const isShowModalError = ref(false);

const parserErrorMessage = (response) => {
  const errorCode = response?.error?.message;

  if (errorCode == ErrorNotificationLogin.LOGIN_BY_CODE_BLOCKED) {
    responseErrorCode.value = 'SIGNUP_BY_CODE_BLOCKED';
    return;
  }

  if (errorCode in ErrorNotificationLogin) {
    responseErrorCode.value = ErrorNotificationLogin[errorCode];
    isShowModalError.value =
      errorCode == ErrorNotificationLogin.SEND_VERIFICATION_FAILED;

    return;
  }

  responseErrorCode.value = 'SEND_VERIFICATION_FAILED';
};

const responseErrorCode = ref('');
const isBlockLoginByCode = computed(() => {
  return (
    responseErrorCode.value == ErrorNotificationLogin.SEND_PHONE_CODE_BLOCKED
  );
});
const isBlockSendPhoneCode = computed(() => {
  return (
    responseErrorCode.value == ErrorNotificationLogin.LOGIN_BY_CODE_BLOCKED
  );
});

const onResendCode = async () => {
  if (isBlockLoginByCode.value || isBlockSendPhoneCode.value) return;
  emit('onResendCode');

  const capchaTokenText = await initGoogleCaptcha(
    'g-recaptcha_register',
    'register_capcha-container'
  );

  isResend.value = true;
  responseErrorCode.value = '';
  try {
    const res = await _registerStore.reSendVerificationCodeByPhoneNumber({
      phoneNumber: props.phoneNumber,
      captchaToken: capchaTokenText,
      locale: props.locale,
      type: 'sign_up',
    });
    sessionInfo.value = res?.result.sessionInfo;
    isResend.value = false;
  } catch (response: any) {
    if (response.error.message == ErrorNotificationLogin.CAPTCHA_CHECK_FAILED) {
      onResendCode();
    } else if (
      response.error.message == ErrorNotificationLogin.SEND_VERIFICATION_FAILED
    ) {
      parserErrorMessage(response);
      isShowModalError.value = true;
      isResend.value = false;
    } else {
      isResend.value = false;
      parserErrorMessage(response);
    }
  }
};

const isLoadingRefresh = ref(false);
const onRefreshPage = async () => {
  isLoadingRefresh.value = true;
  clearLocalStorage({ isSwitchingOrg: false });
  await window.location.reload();
  isLoadingRefresh.value = false;
};
</script>

<template>
  <section>
    <GetCode
      :phone="phoneNumber"
      type="signup"
      :block-code-login="isBlockLoginByCode"
      :block-sending-code="isBlockSendPhoneCode"
      :is-loading="isLoadingGetCode"
      :is-loading-resend="isResend"
      :error-code="$t(responseErrorCode)"
      @on-verify="onVerify"
      @resend-code="onResendCode"
    />
  </section>

  <SynQuestionModal
    :visible="isShowModalError"
    :show-confirm-btn="false"
    :delete-label="$t('COMMON_LABEL_TRY_AGAIN')"
    :is-loading="isLoadingRefresh"
    show-delete-btn
    header-text="Oops!!"
    @cancel="isShowModalError = false"
    @on-confirm="onRefreshPage"
  >
    <template #content>
      <syn-animation name="oops" stype="width: 200px;" :loop="false" />
      <div class="text-center text-red-700">
        {{ $t(responseErrorCode) }}
      </div>
    </template>
  </SynQuestionModal>
</template>
