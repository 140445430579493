<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import domainStore from '@/store/scope';
import UpdateDomainMembers from '@/ui/modules/domains/component/UpdateDomainMembers.vue';
import DomainUpdateForm from '@/ui/modules/domains/domain/DomainUpdateForm.vue';
import EditButton from '@/ui/components/general/EditButton.vue';
import DomainDeleteModal from '@/ui/modules/domains/domain/DomainDeleteModal.vue';
// import { UpdateMembersPayload } from '@/application/types/domain/DomainPayload';
import UpdateDomainGroups from '@/ui/modules/domains/domain/UpdateDomainGroups.vue';
import { arrayOrderBy, cloneDeep } from '@/ui/hooks/commonFunction';
import getUserInfo from '@/ui/helpers/user-helper';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import DomainCreateSupplementForm from '@/ui/modules/domains/domain/DomainCreateSupplementForm.vue';
import DomainService from '@/application/services/DomainService';

const props = withDefaults(
  defineProps<{
    domainId: any;
    defaultTab?: 'INFORMATION' | 'MEMBERS' | 'GROUPS';
    editModeDefault?: boolean;
    removeModeDefault?: boolean;
  }>(),
  {
    defaultTab: 'INFORMATION',
  }
);
const emits = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onDeleteSuccess'): void;
  (e: 'onSaveSuccess'): void;
}>();
const _domainStore = domainStore();

const showAlertSystemDomain = ref(false);
const domainByIds = computed(() => _domainStore.domainByIds);
const domainDetail = computed(() => domainByIds.value[props.domainId]);

watch(
  () => props.domainId,
  () => {
    initData();
  }
);

const domainPayload = ref<any>({});
const isLoading = ref<boolean>(true);

const initData = async () => {
  domainPayload.value = {
    name: domainDetail?.value?.name,
    description: domainDetail?.value.description,
    members: arrayOrderBy(
      cloneDeep(
        domainDetail.value?.members?.map((user) => {
          return {
            ...user,
            ...getUserInfo(user?.id),
          };
        })
      ),
      ['anyProjects', (user) => user?.name?.split(' ')?.pop()],
      ['desc', 'asc']
    ),
    groups: cloneDeep(domainDetail?.value.groups),
    supplement: domainDetail?.value.supplement,
  };

  const res = await DomainService.getInstance().getDomainFullDetail(
    props.domainId
  );
  domainPayload.value.supplement = res?.result;

  isLoading.value = false;
};

initData();

const domainDetailTabs = ref({
  INFORMATIONS: {
    key: 'INFORMATIONS',
    label: 'COMMON_LABEL_INFORMATION',
    isActive: props.defaultTab == 'INFORMATION',
    isEditing: false,
  },
  MEMBERS: {
    key: 'MEMBERS',
    label: 'TASK_TABLE_LABEL_MEMBERS',
    isActive: props.defaultTab == 'MEMBERS',
    isEditing: false,
    subContent: '',
  },
  GROUPS: {
    key: 'GROUPS',
    label: 'COMMON_MODULE_GROUP_MANAGEMENT',
    isActive: props.defaultTab == 'GROUPS',
    isEditing: false,
    subContent: '',
  },
  SETTING: {
    key: 'SETTING',
    label: 'COMMON_LABEL_SETTINGS_GROUP',
    isActive: false,
  },
});

const onClickEdit = (keyTab) => {
  if (domainDetail.value.system) {
    domainDetailTabs.value[keyTab].isEditing =
      !domainDetailTabs.value[keyTab].isEditing;
    showAlertSystemDomain.value = true;
  }
};

const updateSubDetailTabs = () => {
  domainDetailTabs.value['MEMBERS'].subContent =
    domainDetail.value?.members?.length > 0
      ? `${domainDetail.value?.members?.length}`
      : '';
  domainDetailTabs.value['GROUPS'].subContent =
    domainDetail.value?.groups?.length > 0
      ? `${domainDetail.value?.groups?.length}`
      : '';
};

onMounted(() => {
  updateSubDetailTabs();
});
const changeTab = (key) => {
  for (const k in domainDetailTabs.value) {
    domainDetailTabs.value[k].isActive = false;
    domainDetailTabs.value[k].isEditing = false;

    if (k == key) domainDetailTabs.value[k].isActive = true;
  }
};

const isSavingInfo = ref(false);
const onSaveInfo = async () => {
  try {
    isSavingInfo.value = true;
    await _domainStore.updateInformation({
      ...domainPayload.value,
      id: props.domainId,
    });
    isSavingInfo.value = false;
    emits('onSaveSuccess');
    onCancelInfo();
  } catch (error) {
    console.log('🚀 ~ onSubmitUpdate ~ error', error);
  }
};
const onCancelInfo = () => {
  updateSubDetailTabs();
  domainDetailTabs.value['INFORMATIONS'].isEditing = false;
  domainDetailTabs.value['MEMBERS'].isEditing = false;
  domainDetailTabs.value['GROUPS'].isEditing = false;
  initData();
};

const isShowRemoveModal = ref(false);
const onClickRemove = () => {
  isShowRemoveModal.value = true;
};

const onSaveMembersInfo = async () => {
  try {
    isSavingInfo.value = true;
    await _domainStore.updateMembers(
      domainDetail.value,
      domainPayload.value?.members
    );
    isSavingInfo.value = false;
    emits('onSaveSuccess');
    onCancelInfo();
  } catch (error) {
    console.log('🚀 ~ onSubmitUpdate ~ error', error);
  }
};
const onSaveGroupsInfo = async () => {
  try {
    isSavingInfo.value = true;
    await _domainStore.updateGroups(
      props.domainId,
      domainPayload.value?.groups
    );
    isSavingInfo.value = false;
    emits('onSaveSuccess');
    onCancelInfo();
  } catch (error) {
    console.log('🚀 ~ onSubmitUpdate ~ error', error);
  }
};

const isShowAlertCannotEditSystemDomain = ref(false);
const onChangeInvisible = () => {
  if (domainDetail.value?.system) {
    isShowAlertCannotEditSystemDomain.value = true;
    return;
  }
  _domainStore.setVisibleDomain(props.domainId);
};

const isEditSupplement = ref<boolean>(false);

const onSaveSupplement = () => {
  _domainStore.updateDomainSupplement({
    domainId: props.domainId,
    startDate: domainPayload.value?.supplement?.startDate,
    endDate: domainPayload.value?.supplement?.endDate,
    budgetMin: domainPayload.value?.supplement?.budgetMin,
    budgetMax: domainPayload.value?.supplement?.budgetMax,
    responsibility: domainPayload.value?.supplement?.responsibility,
  });

  isEditSupplement.value = false;
};
</script>
<template>
  <div class="flex-1 max-h-full overflow-hidden flex flex-col">
    <SynTabs
      :option-status="domainDetailTabs"
      positon="left"
      class="overflow-hidden px-4 bg-white"
      @change-tab="changeTab"
    >
      <template #tabBody>
        <div class="py-2 flex-1 min-h-0 overflow-auto flex flex-col gap-4">
          <!-- INFOMATION -->
          <template v-if="domainDetailTabs['INFORMATIONS'].isActive">
            <div class="flex flex-col">
              <div class="flex justify-end">
                <div class="h-8 flex items-center mx-4">
                  <EditButton
                    v-model="domainDetailTabs['INFORMATIONS'].isEditing"
                    :is-submitting="isSavingInfo"
                    :is-disable-submit="!domainPayload.name"
                    @cancel="onCancelInfo"
                    @save="onSaveInfo"
                    @update:model-value="onClickEdit('INFORMATIONS')"
                  />
                </div>
              </div>
              <DomainUpdateForm
                v-model:name="domainPayload.name"
                v-model:description="domainPayload.description"
                :is-disabled="!domainDetailTabs['INFORMATIONS'].isEditing"
              />
            </div>
            <div
              v-if="
                domainPayload?.supplement?.budgetMin ||
                domainPayload?.supplement?.budgetMin ||
                domainPayload?.supplement?.startDate ||
                domainPayload?.supplement?.endDate
              "
              class="flex flex-col"
            >
              <div class="flex justify-end">
                <div class="h-8 flex items-center mx-4">
                  <EditButton
                    v-model="isEditSupplement"
                    :is-submitting="isSavingInfo"
                    @cancel="isEditSupplement = false"
                    @save="onSaveSupplement"
                  />
                </div>
              </div>
              <DomainCreateSupplementForm
                v-if="!isLoading"
                v-model:startDate="domainPayload.supplement.startDate"
                v-model:endDate="domainPayload.supplement.endDate"
                v-model:budgetMin="domainPayload.supplement.budgetMin"
                v-model:budgetMax="domainPayload.supplement.budgetMax"
                v-model:responsibility="domainPayload.supplement.responsibility"
                :have-supplement="true"
                :readonly="!isEditSupplement"
                is-hidden-collapse
              />
            </div>
          </template>

          <!-- MEMBERS -->
          <template v-if="domainDetailTabs['MEMBERS'].isActive">
            <div class="flex justify-end">
              <div class="h-8 flex items-center mx-4">
                <EditButton
                  v-model="domainDetailTabs['MEMBERS'].isEditing"
                  :is-submitting="isSavingInfo"
                  @cancel="onCancelInfo"
                  @save="onSaveMembersInfo"
                  @update:model-value="onClickEdit('MEMBERS')"
                />
              </div>
            </div>
            <UpdateDomainMembers
              v-model="domainPayload.members"
              class="flex-1"
              autofocus
              :domain-id="domainId"
              :is-editting="domainDetailTabs['MEMBERS'].isEditing"
            />
          </template>
          <!-- GROUPS -->
          <template v-if="domainDetailTabs['GROUPS'].isActive">
            <div class="flex justify-end">
              <div class="h-8 flex items-center mx-4">
                <EditButton
                  v-model="domainDetailTabs['GROUPS'].isEditing"
                  :is-submitting="isSavingInfo"
                  @cancel="onCancelInfo"
                  @save="onSaveGroupsInfo"
                  @update:model-value="onClickEdit('GROUPS')"
                />
              </div>
            </div>
            <UpdateDomainGroups
              v-model="domainPayload.groups"
              class="flex-1"
              autofocus
              :domain-id="domainId"
              :is-editting="domainDetailTabs['GROUPS'].isEditing"
              :domain-name="domainPayload.name"
            />
          </template>

          <!-- SETTING -->
          <template v-if="domainDetailTabs['SETTING'].isActive">
            <!-- SETTING PRIVATE -->
            <div
              class="
                px-4
                py-3
                flex
                items-center
                justify-between
                bg-white
                border-b
                space-x-4
              "
            >
              <div class="flex items-start space-x-4">
                <div class="w-4 h-4">
                  <SynIcon
                    name="eye"
                    class="fill-current"
                    custom-class="w-4 h-4"
                  />
                </div>
                <div class="flex flex-wrap">
                  <span>
                    {{ $t('COMMON_LABEL_VISIBILITY') }}
                  </span>
                  <span class="text-xs text-gray-600 w-full">
                    {{ $t('DOMAIN_LABEL_VISIBILITY_TITLE') }}
                  </span>
                </div>
              </div>
              <AtomSwitch
                :model-value="!domainDetail.invisible"
                @change="onChangeInvisible"
              />
            </div>
            <!-- SETTING DELETE -->
            <div
              class="
                px-4
                py-3
                flex
                items-center
                justify-between
                bg-white
                border-b
                space-x-4
              "
            >
              <div class="flex items-start space-x-4">
                <div class="w-4 h-4">
                  <SynIcon
                    name="trash"
                    class="fill-current"
                    custom-class="w-4 h-4"
                  />
                </div>
                <div class="flex flex-wrap">
                  <span>
                    {{ $t('DOMAIN_LABEL_REMOVE_TITLE') }}
                  </span>
                  <span class="text-xs text-gray-600 w-full">
                    {{ $t('DOMAIN_LABEL_REMOVE_TITLE_SUB') }}
                  </span>
                </div>
              </div>
              <SynButton
                class="text-sm"
                color="red"
                type-outline
                @click="onClickRemove"
              >
                <div class="flex-center space-x-2">
                  <SynIcon
                    name="trash"
                    class="fill-red-500"
                    custom-class="w-4 h-4"
                  />
                  <span>
                    {{
                      $t('DOMAIN_LABEL_REMOVE_DOMAIN') ||
                      'Remove domain/project'
                    }}
                  </span>
                </div>
              </SynButton>
            </div>
          </template>
        </div>
      </template>
    </SynTabs>
  </div>

  <DomainDeleteModal
    v-if="isShowRemoveModal"
    :domain-id="domainId"
    @on-close="isShowRemoveModal = false"
    @on-delete-success="$emit('onDeleteSuccess')"
  />
  <alert-modal
    v-if="showAlertSystemDomain"
    :title-text="$t('DOMAIN_LABEL_EDIT_SYSTEM_DOMAIN_TITLE')"
    :sub-title-text="
      $t('DOMAIN_LABEL_REMOVE_SYSTEM_DOMAIN_CONTENT', {
        domainName: domainDetail.name,
      })
    "
    :is-html-text="true"
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="showAlertSystemDomain = false"
  />

  <AlertModal
    v-if="isShowAlertCannotEditSystemDomain"
    is-html-text
    :title-text="$t('DOMAIN_LABEL_EDIT_SYSTEM_DOMAIN_TITLE')"
    :sub-title-text="
      $t('DOMAIN_LABEL_REMOVE_SYSTEM_DOMAIN_CONTENT', {
        domainName: domainDetail?.name || '',
      })
    "
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="isShowAlertCannotEditSystemDomain = false"
  />
</template>

<style>
.outline-red {
  outline: 1px solid red;
}

.domain-min-height {
  min-height: 82px;
}
</style>
