<script setup lang="ts">
import domainStore from '@/store/scope';
import DomainFullManagement from '@/ui/modules/domains/domain/DomainFullManagement.vue';
import groupStore from '@/store/group';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';

const _domainStore = domainStore();

const initData = () => {
  groupStore().getAllGroup();
  _domainStore.fetchAllDomainProjects();
};

initData();
</script>

<template>
  <div
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.ADMIN.DOMAIN,
      isPage: true,
    }"
    class="h-full w-full pt-1"
  >
    <DomainFullManagement />
  </div>
</template>
