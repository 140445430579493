<script setup lang="ts">
import { CalendarSyncStatus } from '@/domain/enums/CalendarEnum';
import { onMounted, onUnmounted, ref } from 'vue';
import UserDynamicInfoRepository from '@/application/repositories/user-dynamic-info/UserDynamicInfoRepository';
import myProfileStore from '@/store/auth/my-profile';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const emits = defineEmits(['onSyncComplete']);

const isShowProgress = ref<boolean>();
const syncInfo = ref<any>();

let unsub;

onMounted(() => {
  const myProfile = myProfileStore().myProfile;

  unsub = UserDynamicInfoRepository.subscribeCalendarSync(
    myProfile?.organizationId,
    myProfile?.id,
    (val) => _processSyncStatus(val)
  );
});

onUnmounted(() => {
  if (unsub) unsub();
});

const _processSyncStatus = (currSync) => {
  if (currSync?.enabled) {
    switch (currSync?.status) {
      case CalendarSyncStatus.InProcessing:
        isShowProgress.value = true;
        break;
      case CalendarSyncStatus.Completed:
      case CalendarSyncStatus.Failure:
        // If Finish sync, show progress in 5 seconds
        if (syncInfo.value?.status === CalendarSyncStatus.InProcessing) {
          isShowProgress.value = true;
          setTimeout(() => (isShowProgress.value = false), 5000);

          if (currSync?.syncAlert) {
            openNotification({
              duration: 10000,
              title: translate('CALENDAR_SYNC_COMPLETED_MSG'),
              body: translate('CALENDAR_SYNC_COMPLETED_STATS', {
                added: currSync.syncAlert.totalAddedEvents || 0,
                updated: currSync.syncAlert.totalUpdatedEvents || 0,
                deleted: currSync.syncAlert.totalDeletedEvents || 0,
              }),
            });
          }

          emits('onSyncComplete');
        } else {
          isShowProgress.value = false;
        }
        break;
      case CalendarSyncStatus.NotSync:
      default:
        isShowProgress.value = false;
        break;
    }
  } else {
    isShowProgress.value = false;
  }

  syncInfo.value = currSync;
};
</script>

<template>
  <div class="relative">
    <div
      v-if="isShowProgress"
      class="
        absolute
        top-0
        left-0
        z-10
        shadow
        rounded-3xl
        px-3
        py-1.5
        flex-center
        gap-1.5
      "
      :class="[
        {
          'bg-orange-50 text-orange-500 fill-orange-500':
            syncInfo?.status === CalendarSyncStatus.InProcessing,
          'bg-green-50 text-green-500 fill-green-500':
            syncInfo?.status === CalendarSyncStatus.Completed,
          'bg-red-50 text-red-500 fill-red-500':
            syncInfo?.status === CalendarSyncStatus.Failure,
        },
      ]"
    >
      <SynIcon
        v-if="syncInfo?.status === CalendarSyncStatus.InProcessing"
        name="RepeatCircle"
        class="animate-spin"
      />
      <SynIcon
        v-else-if="syncInfo?.status === CalendarSyncStatus.Completed"
        name="Check"
      />
      <SynIcon
        v-else-if="syncInfo?.status === CalendarSyncStatus.Failure"
        name="Warning"
      />
      <span class="text-sm font-semibold whitespace-nowrap">
        <template v-if="syncInfo?.status === CalendarSyncStatus.InProcessing">{{
          $t('CALENDAR_SYNC_STATUS_PROCESSING')
        }}</template>
        <template
          v-else-if="syncInfo?.status === CalendarSyncStatus.Completed"
          >{{ $t('CALENDAR_SYNC_STATUS_COMPLETED') }}</template
        >
        <template v-else-if="syncInfo?.status === CalendarSyncStatus.Failure">{{
          $t('CALENDAR_SYNC_STATUS_FAILURE')
        }}</template>
      </span>
    </div>
  </div>
</template>
