<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted, inject } from 'vue';
import WorkingTime from '@/ui/modules/dashboard/monitor/WorkingTime.vue';
import FinishedTasks from '@/ui/modules/dashboard/monitoring-department/FinishedTasks.vue';
import CreatedTasks from '@/ui/modules/dashboard/monitoring-department/CreatedTasks.vue';
import OverviewDepartment from '@/ui/modules/dashboard/monitor/OverviewDepartment.vue';
import RemainingWorkforceCard from '@/ui/modules/dashboard/monitor/RemainingWorkforceCard.vue';
import MembersStatedWorkingCard from '@/ui/modules/dashboard/monitor/MembersStatedWorkingCard.vue';
import FinishedTasksCard from '@/ui/modules/dashboard/monitor/FinishedTasksCard.vue';
import overviewMonitorStore from '@/store/dashboard/overview-monitor-store';
import overviewMonitoringDepartmentStore from '@/store/dashboard/overview-monitoring-department-store';
import ScoreTodayCard from '@/ui/modules/dashboard/monitor/ScoreTodayCard.vue';
import TaskActivitiesCard from '@/ui/modules/dashboard/monitor/TaskActivitiesCard.vue';
import DayoffStatusTodayCard from '@/ui/modules/dashboard/monitor/DayoffStatusTodayCard.vue';
import settingStore from '@/store/setting';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import { AdminType } from '@/ui/common/constants/constant';
import dayjs from 'dayjs';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import { EScreenPermission } from '@/application/types/block-function.types';
// import TaskDetailDrawer from '@/ui/modules/task/detail/TaskDetailDrawer.vue';

const props = defineProps<{
  departmentId?: number;
}>();

const _overviewMonitorStore = overviewMonitorStore();
const _overviewMonitoringDepartmentStore = overviewMonitoringDepartmentStore();
const _settingStore = settingStore();
const isHiddenBlockPage: any = inject('IS_HIDDEN_BLOCK_PAGE');

const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const organizationSettings = computed(() => _settingStore._organizationSetting);
const isLoading = computed(() => _overviewMonitorStore.isLoadingWorkingStatus);
const timeCapacity = computed(() => _overviewMonitorStore.timeCapacity);
const finishedTaskCapacity = computed(() => _overviewMonitorStore.task);
const workingMember = computed(() => _overviewMonitorStore.workingMember);
const taskActivityData = computed(
  () => _overviewMonitorStore.taskActivityChart
);
const dayoffStatusByDate = computed(
  () => _overviewMonitorStore.dayoffStatusByDate
);

enum EOverviewDetailTabs {
  DEPARTMENTS = 'DEPARTMENTS',
  MEMBERS = 'MEMBERS',
  FINISHED_TASKS = 'FINISHED_TASKS',
  NEW_TASKS = 'NEW_TASKS',
}

const currentTab = ref<EOverviewDetailTabs | null>(null);

const permissionScreen: Function | undefined = inject('permissionScreen');
const overviewDetailTabs = ref<any>([]);

let recalculateInterval;
onMounted(() => {
  recalculateInterval = setInterval(() => {
    if (props.departmentId) {
      _overviewMonitoringDepartmentStore.getWorkingStatus(props.departmentId);
    } else {
      _overviewMonitorStore.getWorkingStatus();
    }
  }, 1 * 60 * 1000);
});

onUnmounted(() => {
  clearInterval(recalculateInterval);
});

const monitorTabs = {
  [EOverviewDetailTabs.MEMBERS]: {
    key: EOverviewDetailTabs.MEMBERS,
    label: 'TASK_TABLE_LABEL_MEMBERS',
    isActive: false,
    isEditing: false,
    subContent: '',
    firebaseConfigKey: 'timeWorking',
    screenKey: ALL_SCREENS.COMMON.OVERVIEW.MEMBER,
  },
  [EOverviewDetailTabs.FINISHED_TASKS]: {
    key: EOverviewDetailTabs.FINISHED_TASKS,
    label: 'OVERVIEW_FINISHED_TASKS',
    isActive: false,
    isEditing: false,
    subContent: '',
    firebaseConfigKey: '',
    screenKey: ALL_SCREENS.COMMON.OVERVIEW.FINISHED_TASK,
  },
  [EOverviewDetailTabs.NEW_TASKS]: {
    key: EOverviewDetailTabs.NEW_TASKS,
    label: 'OVERVIEW_CREATED_TASKS',
    isActive: false,
    firebaseConfigKey: '',
    screenKey: ALL_SCREENS.COMMON.OVERVIEW.NEW_TASK,
  },
};

const initMonitorTabs = () => {
  if (!props.departmentId) {
    if (
      departmentManagementEnabled.value &&
      hasRolePermission(AdminType.Owner) &&
      permissionScreen &&
      permissionScreen(ALL_SCREENS.COMMON.OVERVIEW.DEPARTMENT) !==
        EScreenPermission.HIDDEN &&
      permissionScreen(ALL_SCREENS.COMMON.OVERVIEW.DEPARTMENT) !==
        EScreenPermission.NOT_SET
    ) {
      overviewDetailTabs.value.splice(1, 0, {
        key: EOverviewDetailTabs.DEPARTMENTS,
        label: 'COMMON_MODULE_MANAGEMENT_DEPARTMENT',
        isActive: false,
        isEditing: false,
        subContent: '',
      });
    }
  }
  for (const tabKey of Object.keys(monitorTabs)) {
    const firebaseConfigKey = monitorTabs[tabKey]?.firebaseConfigKey;
    const screenKey = monitorTabs[tabKey]?.screenKey;

    const enabledInRemoteConfig = firebaseConfigKey
      ? organizationSettings.value?.[firebaseConfigKey]?.enabled
      : true;

    const hasPermission = permissionScreen
      ? permissionScreen(screenKey) !== EScreenPermission.HIDDEN &&
        permissionScreen(screenKey) !== EScreenPermission.NOT_SET
      : false;

    if (enabledInRemoteConfig && hasPermission) {
      overviewDetailTabs.value.push(monitorTabs[tabKey]);
    }
  }

  if (departmentManagementEnabled.value && hasRolePermission(AdminType.Owner)) {
    const firstElement = overviewDetailTabs.value.shift();
    overviewDetailTabs.value.splice(1, 0, firstElement);
  }

  currentTab.value = overviewDetailTabs.value[0]?.key;

  if (overviewDetailTabs.value?.length > 0)
    overviewDetailTabs.value[0].isActive = true;
};

const initComponent = () => {
  if (props.departmentId) {
    _overviewMonitoringDepartmentStore.getAllMonitorData(props.departmentId);
  } else {
    _overviewMonitorStore.getAllMonitorData();
  }
  initMonitorTabs();
};

initComponent();
const changeTab = (key) => {
  currentTab.value = key;
  overviewDetailTabs.value.forEach((element) => {
    element.isActive = false;
    element.isEditing = false;

    if (element?.key == key) element.isActive = true;
  });
};

const onChangeDayInDayoffCard = (newDate) => {
  _overviewMonitorStore.getDayoffStatusByDate(
    dayjs(newDate).format('YYYY-MM-DD')
  );
};
</script>
<template>
  <SynPageLoading v-if="isLoading" />

  <div
    v-else
    class="w-full h-full flex flex-col gap-2 overflow-auto small-scrollbar"
  >
    <!-- TOP BLOCK -->
    <div
      class="
        w-full
        min-w-0
        flex
        overflow-x-auto
        small-scrollbar
        relative
        shrink-0
      "
    >
      <div
        v-permission-screen="
          isHiddenBlockPage
            ? false
            : {
                screenCode: ALL_SCREENS.COMMON.OVERVIEW.CHART,
              }
        "
        class="flex-1 h-72 flex space-x-4 p-4"
      >
        <RemainingWorkforceCard :time-capacity="timeCapacity" />
        <MembersStatedWorkingCard
          :time-capacity="timeCapacity"
          :working-member="workingMember"
        />
        <FinishedTasksCard :overview-task="finishedTaskCapacity" />
        <TaskActivitiesCard :task-activity-data="taskActivityData" />
        <DayoffStatusTodayCard
          :dayoff-status-by-date="dayoffStatusByDate"
          @change-day="onChangeDayInDayoffCard"
        />
        <ScoreTodayCard />
      </div>
    </div>

    <!-- BOTTOM BLOCK -->
    <div>
      <SynTabs
        :option-status="overviewDetailTabs"
        positon="left"
        class="overflow-hidden pl-4"
        @change-tab="changeTab"
      >
        <template #tabBody>
          <div class="py-2 flex-1 min-h-0 flex flex-col relative">
            <!-- DEPARTMENT -->
            <template v-if="currentTab == EOverviewDetailTabs.DEPARTMENTS">
              <OverviewDepartment />
            </template>
            <!-- MEMBERS -->
            <template v-if="currentTab == EOverviewDetailTabs.MEMBERS">
              <WorkingTime :current-tab="currentTab" />
            </template>
            <!-- FINISHED_TASKS -->
            <template v-if="currentTab == EOverviewDetailTabs.FINISHED_TASKS">
              <div class="flex-1 min-h-0">
                <FinishedTasks />
              </div>
            </template>
            <!-- NEW_TASKS -->
            <template v-if="currentTab == EOverviewDetailTabs.NEW_TASKS">
              <div class="flex-1 min-h-0">
                <CreatedTasks />
              </div>
            </template>
          </div>
        </template>
      </SynTabs>
    </div>

    <!--    <TaskDetailDrawer />-->
  </div>
</template>
