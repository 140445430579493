<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M45.659 52.505h-.002l-42.659-.027a2.5 2.5 0 0 1 .002-5h.002l42.659.027a2.5 2.5 0 0 1-.002 5z"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M18.93 68.39c-.64 0-1.278-.244-1.767-.731L1.233 51.751a2.504 2.504 0 0 1 .002-3.54L17.19 32.314a2.5 2.5 0 1 1 3.529 3.542L6.54 49.984 20.696 64.12a2.5 2.5 0 0 1-1.766 4.27zM97 52.509l-42.66-.011a2.5 2.5 0 1 1 .001-5h.001l42.659.011a2.5 2.5 0 1 1-.001 5z"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M81.056 68.406a2.5 2.5 0 0 1-1.765-4.271L93.46 50.012 79.293 35.871a2.5 2.5 0 1 1 3.531-3.539l15.942 15.912a2.5 2.5 0 0 1-.002 3.541L82.82 67.676a2.487 2.487 0 0 1-1.764.73z"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>
