<template>
  <div class="flex flex-row items-center p-4 shadow-lg rounded-xl border">
    <div class="flex items-center justify-center h-10 w-10 shrink-0">
      <span class="">
        <svg fill="currentColor" viewBox="0 0 20 20" class="h-6 w-6">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </span>
    </div>
    <div class="ml-4 flex-grow">
      <div class="font-semibold text-lg">Autorisation</div>
      <div class="text-sm text-gray-900">
        Autoriser les autres copropriétaires à m’envoyer des messages.
      </div>
      <div class="text-sm text-gray-500">
        Quel que soit mon choix, je pourrai toujours communiquer avec les
        membres du syndic et ceux du conseil syndical.
      </div>
    </div>
    <slot></slot>
    <button class="pl-4" type="button" onclick="this.parentElement.remove();">
      <SynIcon name="close" />
    </button>
  </div>
</template>
