<script setup lang="ts">
import SelectMembers from '@/ui/modules/member/select-members/SelectMembers.vue';
import { computed } from 'vue';
import permissionStore from '@/store/permission';
import { EOrganizationModel } from '@/application/types/organization/organization.type';
import myProfileStore from '@/store/auth/my-profile';
import { cloneDeep } from '@/ui/hooks/commonFunction';
// import $constants from '@/ui/plugins/constants';
import { translate } from '@/ui/plugins/i18n/myi18n';
// import { AdminType } from '@/ui/common/constants/constant';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import GedFilterCategories from '@/ui/modules/ged/ged-page/filter-categories/GedFilterCategories.vue';
import GedFilterSubDomain from '@/ui/modules/ged/ged-page/filter-sub-domain/GedFilterSubDomain.vue';

defineProps<{
  selectedCategoryId?: any;
  selectedMember?: any;
  isViewTrash?: boolean;
  fileFilters?: any;
}>();

defineEmits([
  'update:selectedMember',
  'update:selectedCategoryId',
  'update:isViewTrash',
  'update:fileFilters',
]);

const _permissionStore = permissionStore();
const _myProfileStore = myProfileStore();
const _userStore = userStore();

const allUsers = computed(() => {
  let members = cloneDeep(_userStore.getDefaultUsers) || [];

  // if (props.selectedCategoryId !== $constants.FILE.CATEGORY.STORAGE) {
  const specialUser = {
    id: null,
    name: translate('GED_FILTER_ALL'),
    nameCode: 'GED_FILTER_ALL',
  };
  if (!members.some((e) => e.id === null)) {
    members.unshift({ ...specialUser });
  }
  // } else {
  //   members = members.filter(
  //     (e) => e.id !== null && e.adminType !== AdminType.Visitor
  //   );
  // }
  // members.unshift({ id: null, name: translate('GED_FILTER_ALL') });
  return members;
});
const allMyGroups = computed(() =>
  groupStore().allMyGroups?.filter((group) => {
    return group?.isMember;
  })
);
const allMyDomains = computed(() => _myProfileStore.allMyDomains);

const isPersonalModel = computed(
  () => _permissionStore.organizationModel === EOrganizationModel.PERSONAL
);
</script>

<template>
  <div class="flex items-center space-x-3 px-3 bg-gray-100">
    <div v-if="isPersonalModel" class="text-xl leading-6 pb-3 pt-2">
      {{ $t('COMMON_LABEL_MY_RESOURCE') || 'Resources' }}
    </div>

    <template v-else>
      <div class="text-xl leading-6 pb-3 pt-2">
        {{ $t('GED_RESOURCES_OF') || 'Resources' }}
      </div>
      <div class="min-w-[16rem] max-w-lg pt-2 pb-3">
        <SelectMembers
          :model-value="selectedMember"
          :members="allUsers"
          :groups="allMyGroups"
          :domains="allMyDomains"
          @update:model-value="$emit('update:selectedMember', $event)"
        />
      </div>
      <div
        v-if="fileFilters?.domainId"
        class="min-w-[12rem] max-w-[20rem] pt-2 pb-3"
      >
        <GedFilterSubDomain
          :file-filters="fileFilters"
          @update:file-filters="$emit('update:fileFilters', $event)"
        />
      </div>
    </template>

    <GedFilterCategories
      class="flex-1"
      :selected-category-id="selectedCategoryId"
      :selected-member="selectedMember"
      @update:selected-category-id="$emit('update:selectedCategoryId', $event)"
    />

    <VigButton
      light
      color="gray"
      padding="p-2"
      @click="$emit('update:isViewTrash', true)"
    >
      <SynIcon name="Trash" custom-class="w-5 h-5" />
      <span>{{ $t('GED_TRASH') }}</span>
    </VigButton>
  </div>
</template>
