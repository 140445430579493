<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import organizationStore from '@/store/organization';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import AttachOrganizationModal from '@/ui/modules/organization/setup/AttachOrganizationModal.vue';
import {
  setLocalStorage,
  getLocalStorage,
  StorageConstant,
  removeLocalStorage,
} from '@/ui/hooks/storageHook';
import CreateNewOrganizationModal from '@/ui/modules/organization/setup/CreateNewOrganizationModal.vue';
import AcceptInvitationModal from '@/ui/modules/member/accept-invitation/AcceptInvitationModal.vue';
import appStore from '@/store/app';
import ModalOrganizationsNotification from '@/ui/modules/organization/modal-organizations-notification/ModalOrganizationsNotification.vue';
import { AdminType } from '@/ui/common/constants/constant';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import SelectOrganization from '@/ui/pages/organization/SelectOrganization.vue';
import { STYLE_BY_ORGANIZATION_MODEL } from '@/application/constants/register.const';

const props = defineProps<{
  isInviting: boolean;
}>();
defineEmits(['onCancel', 'onReload']);

const _appStore = appStore();
const _organizationStore = organizationStore();

const activeOrganization = computed(
  () => _organizationStore.organizationInfo
) as any;
const organizationList = computed(() => {
  return _organizationStore.organizationIds
    ?.map((orgId) => {
      return organizationByIds.value[orgId];
    })
    .filter((org) => {
      return (
        org?.id !== activeOrganization.value?.id &&
        (org?.adminType == AdminType.Admin || org?.adminType == AdminType.Owner)
      );
    });
});
const organizationByIds = computed(() => {
  return _organizationStore.organizationByIds;
});

onMounted(() => {
  if (organizationList.value?.length == 0) {
    updateCurrentStep(2);
    updateStepById(2, {
      previous: 0,
      buttonList: [
        {
          labelCode: 'COMMON_LABEL_NEXT',
          onClick: () => {
            onHandleGoToNextStep();
          },
        },
      ],
    });
  }
});
const isOpenSetupOrAttachOrg = ref(false);
const isOpenAttachOrg = ref(false);
const isOpenSetupOrg = ref(false);
const isOpenAcceptInvitation = ref(false);
const isOpenMessagesNotifs = ref(false);
const inviteToken = ref('');

const gotoOrg = async (orgId) => {
  // switch org
  _appStore.setIsLoading(true);
  const authenSelectOrganization = new AuthenticateSelectOrganization(orgId);
  const res = await authenSelectOrganization.login(true);

  handleLoginSuccess(res);
};

const onChangeOrganization = async (orgInfo) => {
  const orgId = orgInfo?.id;
  if (!orgInfo || !orgId) return;

  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: `<span>
    <span class="">${translate(
      'COMMON_LABEL_SWITCH_ORGANIZATION_CONFIRM'
    )}</span>
    <br />
    <span class="text-current font-semibold">${orgInfo.name}</span>
    </span>`,
    confirmable: true,
    closeable: true,
    confirmLabel: translate('COMMON_LABEL_SWITCH_ORGANIZATION'),
  });
  const confirmed = await ask();
  if (confirmed) {
    gotoOrg(orgId);
  }
};

const handleLoginSuccess = async (result) => {
  if (!result) return;

  getLocalStorage(StorageConstant.HAVE_AVATAR) &&
    removeLocalStorage(StorageConstant.HAVE_AVATAR);

  setLocalStorage(StorageConstant.HAS_LOGIN, 'true');
};

const onAttachOrgSuccess = async (attachedOrganization) => {
  isOpenAttachOrg.value = false;
  isOpenSetupOrg.value = false;
  isOpenSetupOrAttachOrg.value = false;
  isOpenAcceptInvitation.value = false;

  if (!attachedOrganization) return;

  // go to new org
  if (props.isInviting) setLocalStorage(StorageConstant.IS_INVITING, '1');
  gotoOrg(attachedOrganization?.id);
};
const onRejectInvitationSuccess = async () => {
  isOpenAttachOrg.value = false;
  isOpenSetupOrg.value = false;
  isOpenSetupOrAttachOrg.value = false;
  isOpenAcceptInvitation.value = false;

  onReloadOrganization();
};

const onReloadOrganization = async () => {
  // isLoadingOrg.value = true;

  try {
    await _organizationStore.getUserOrganizations();

    // isLoadingOrg.value = false;
  } catch (error) {
    // isLoadingOrg.value = false;
  }
};

const onCloseCreateModal = () => {
  isOpenSetupOrg.value = false;
};
const selectedModel = ref<any>();

const {
  currentStep,
  stepperByKey,
  // onHandleGoToPreviousStep,
  onHandleGoToNextStep,
  updateCurrentStep,
  updateStepById,
} = stepperComposables(
  {
    1: {
      key: 1,
      previous: 0,
      next: 2,
      label: 'ORGANIZATION_LABEL_TYPE',
      title: props.isInviting
        ? 'COMMON_LABEL_UPGRADE_CURRENT_ORGANIZATION_TITLE'
        : 'COMMON_LABEL_UPGRADE_PERSONAL_TITLE',
      subTitle: '',
      buttonList: [],
    },
    2: {
      key: 2,
      previous: 1,
      next: 3,
      label: 'ORGANIZATION_LABEL_TYPE',
      title: props.isInviting
        ? 'COMMON_LABEL_SELECT_ORGANIZATION_TYPE'
        : 'COMMON_LABEL_UPGRADE_PERSONAL_SELECT_ORGANIZATION_TYPE',
      subTitle: 'FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_SUB',
      buttonList: [],
    },
    3: {
      key: 3,
      previous: 2,
      next: 0,
      label: '',
      title: '',
      subTitle: '',
    },
  },
  props.isInviting ? 1 : 2
);

const organizationModels = computed(() => {
  let models = _organizationStore.organizationModels;

  return models?.filter(
    (model) => model.code == 'COMPANY' || model.code == 'TEAM'
  );
});

const onChangeOrgMode = (item) => {
  selectedModel.value = item;
  onHandleGoToNextStep();
};

const checkDisable = (step) => {
  if (step == 2) return !selectedModel.value || !selectedModel.value?.id;
  if (step == 3) return !createMode.value;

  return false;
};

const createMode = ref<any>(null);
const onChangeCreateMode = (mode) => {
  createMode.value = mode;

  isOpenSetupOrg.value = true;
};

const CREATE_NEW_MODES = {
  1: {
    key: 1,
    labelCode: 'COMMON_LABEL_UPGRADE_CURRENT_ORGANIZATION_LABEL_TITLE',
    subLabelCode: 'COMMON_LABEL_UPGRADE_CURRENT_ORGANIZATION_SUB_TITLE',
  },
  2: {
    key: 2,
    labelCode: 'COMMON_LABEL_CREATE_NEW_ORGANIZATION_TITLE',
    subLabelCode: 'COMMON_LABEL_CREATE_NEW_ORGANIZATION_SUB_TITLE',
  },
};

const getIcon = (orgType, mode) => {
  console.log('🚀 Tictop ~ orgType, mode:', orgType, mode);
  if (orgType == 'COMPANY') {
    return mode == 1 ? 'CompanyUpgrade' : 'CompanyAdd';
  }
  return mode == 1 ? 'TeamUpgrade' : 'TeamAdd';
};
</script>
<template>
  <SynModal
    z-index="z-50"
    container-style="width: 650px; max-width: 1200px;"
    disable-click-outside
    @cancel="$emit('onCancel')"
  >
    <template #header>
      <div class="px-4 flex items-center justify-between">
        <div class="flex-1 flex flex-col space-y-4">
          <div class="font-medium text-center">
            {{ $t(stepperByKey[currentStep]?.title) }}
          </div>
          <div class="text-gray-400 text-center font-normal text-base">
            {{ $t(stepperByKey[currentStep]?.subTitle) }}
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex-1 w-full h-full flex flex-col rounded-md">
        <div class="rounded-md">
          <template v-if="currentStep == 1">
            <SelectOrganization
              :organizations="organizationList"
              @on-choose="onChangeOrganization"
            />
          </template>
          <template v-if="currentStep == 2">
            <div class="flex items-stretch space-x-8 justify-around pt-8">
              <div
                v-for="item in organizationModels"
                :key="item?.id"
                class="
                  w-1/3
                  p-4
                  rounded-md
                  flex-center flex-col
                  shadow-md
                  border border-gray-50
                  hover:border-current-50
                  cursor-pointer
                  hover:bg-current-50 hover:shadow-xl
                  relative
                "
                :class="
                  item?.id == selectedModel?.id ? 'ring-4 ring-current' : ''
                "
                @click="onChangeOrgMode(item)"
              >
                <div
                  class="h-16 w-16 flex-center absolute -top-8 rounded-full"
                  :style="{
                    background:
                      STYLE_BY_ORGANIZATION_MODEL[item?.code]?.background,
                  }"
                >
                  <SynIcon
                    :name="item?.code?.toLowerCase()"
                    custom-class="w-10 h-10 fill-white"
                  />
                </div>
                <div
                  class="
                    w-full
                    h-full
                    flex
                    items-center
                    justify-start
                    flex-col
                    pt-8
                  "
                >
                  <span class="uppercase">{{ item?.name }}</span>
                  <span class="text-sm text-gray-500 italic text-center">
                    {{
                      $t(STYLE_BY_ORGANIZATION_MODEL[item?.code]?.subTitle)
                    }}</span
                  >
                </div>
                <div class="flex-center flex-col space-y-2 pt-4">
                  <SynIcon
                    name="medal"
                    class="fill-current"
                    custom-class="w-5 h-5"
                  />
                  <span class="text-current">
                    {{ $t('SELECT_ORGANIZATION_MODEL_FREE_TRIAL_2') }}
                  </span>
                </div>
              </div>
            </div>
          </template>
          <template v-if="currentStep == 3">
            <div class="flex flex-col gap-8">
              <div
                v-for="mode in CREATE_NEW_MODES"
                :key="mode?.key"
                class="
                  w-full
                  p-4
                  rounded-md
                  flex-center flex-col
                  shadow-md
                  border border-gray-50
                  hover:border-current-50
                  cursor-pointer
                  hover:bg-current-50 hover:bg-opacity-50 hover:shadow-xl
                  relative
                "
                :class="mode?.key == createMode ? 'ring-4 ring-current' : ''"
                @click="onChangeCreateMode(mode?.key)"
              >
                <div class="w-full flex items-center gap-4">
                  <div
                    class="h-16 w-16 flex-center rounded-full"
                    :style="{
                      background:
                        STYLE_BY_ORGANIZATION_MODEL[selectedModel?.code]
                          ?.background,
                    }"
                  >
                    <SynIcon
                      :name="getIcon(selectedModel?.code, mode?.key)"
                      custom-class="w-10 h-10 fill-white"
                    />
                  </div>

                  <div class="flex-1 flex items-start justify-start flex-col">
                    <span
                      class="uppercase"
                      v-html="
                        $t(mode?.labelCode, {
                          mode:
                            selectedModel?.name || selectedModel?.code || '',
                        })
                      "
                    >
                    </span>
                    <span
                      class="text-sm text-gray-500 italic"
                      v-html="
                        $t(mode?.subLabelCode, {
                          mode:
                            selectedModel?.name || selectedModel?.code || '',
                        })
                      "
                    >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="
          w-full
          flex
          justify-end
          items-center
          hover:text-current
          cursor-pointer
          space-x-2
          p-4
        "
      >
        <div v-if="currentStep == 1" class="w-full flex-center">
          <div
            class="
              rounded-md
              relative
              bg-current-500
              text-white
              hover:bg-current-600
              cursor-pointer
              p-2
              w-1/2
              space-x-3
              flex-center
              gap-2
            "
            @click="onHandleGoToNextStep"
          >
            <SynIcon name="plus" class="fill-white" />
            <span>
              {{ $t('COMMON_LABEL_CREATE_NEW_ORGANIZATION') }}
            </span>
          </div>
        </div>
        <template
          v-if="
            stepperByKey[currentStep]?.buttonList &&
            stepperByKey[currentStep]?.buttonList?.length > 0
          "
        >
          <AtomButton
            v-for="(button, index) in stepperByKey[currentStep]?.buttonList"
            :key="index"
            :label="$t(button?.labelCode)"
            :type="button?.type"
            :color="button?.color"
            :disabled="checkDisable(currentStep)"
            size="lg"
            @click="
              () => {
                button?.onClick && button?.onClick();
              }
            "
          />
        </template>
      </div>
    </template>
  </SynModal>
  <CreateNewOrganizationModal
    v-if="isOpenSetupOrg"
    is-hidden-personal-model
    :selected-model="selectedModel"
    :is-upgrade-current-org="createMode == 1"
    @on-cancel="onCloseCreateModal"
    @on-finish="onAttachOrgSuccess"
  />
  <AttachOrganizationModal
    v-if="isOpenAttachOrg"
    @on-success="onAttachOrgSuccess"
    @on-cancel="isOpenAttachOrg = false"
  />

  <AcceptInvitationModal
    v-if="isOpenAcceptInvitation"
    :token="inviteToken"
    location="ORGANIZATION_HEADER"
    @on-close="isOpenAcceptInvitation = false"
    @on-accept-success="onAttachOrgSuccess"
    @on-reject-success="onRejectInvitationSuccess"
  />

  <ModalOrganizationsNotification
    v-if="isOpenMessagesNotifs"
    @on-close="isOpenMessagesNotifs = false"
  />
</template>
