export const rankData = {
    1: {
        color: '#FFC400',
        name: 'rank1',
    },
    2: {
        color: '#CECECE',
        name: 'rank2',
    },
    3: {
        color: '#FF8800',
        name: 'rank3',
    },
    4: {
        color: '#FFF',
        name: 'rank4',
    },
};
export const rankDomainData = {
    1: {
        color: '#4bb0b2',
        name: 'rank1',
    },
    2: {
        color: '#FFD13A',
        name: 'rank2',
    },
    3: {
        color: '#FF8800',
        name: 'rank3',
    },
    4: {
        color: '#fca5a5',
        name: 'rank4',
    },
    5: {
        color: '#CECECE',
        name: 'rank5',
    },
};
