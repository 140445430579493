<script setup lang="ts">
import { computed } from 'vue';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';

const props = defineProps<{
  task: any;
  users?: any[];
  groups?: any[];
  readonly?: boolean;
}>();

const taskAssignee = computed(() =>
  props.users?.find((user) => user?.id === props.task?.assigneeId)
);

const taskGroup = computed(() =>
  props.groups?.find((group) => group?.id === props.task?.groupId)
);

const onAssigneeClick = () => {};
</script>

<template>
  <TaskDetailField :is-editable="!readonly">
    <template #fieldName>
      <span>{{ $t('TASK_LABEL_ASSIGNEE') }}</span>
    </template>
    <template #body>
      <div
        class="
          flex-center
          space-x-1
          relative
          rounded
          text-xs
          font-medium
          px-2
          py-2
        "
        :class="{
          'hover:bg-current-50 hover:bg-opacity-60 cursor-pointer': !readonly,
        }"
        @click="onAssigneeClick"
      >
        <div
          v-if="task?.groupId"
          class=""
          :style="task?.assigneeId ? 'flex: 50%' : 'flex: 100%'"
        >
          <GroupById
            :group-id="task?.groupId"
            :group="taskGroup"
            avatar-class="w-6 h-6 text-xs"
          />
        </div>
        <span
          v-if="task?.assigneeId && task?.groupId"
          class="px-2 text-gray-500"
          >→</span
        >
        <div
          v-if="task?.assigneeId"
          class=""
          :style="task?.groupId ? 'flex: 50%' : 'flex: 100%'"
        >
          <UserById
            avatar-class="w-6 h-6 text-xs"
            :user-id="task?.assigneeId"
            :user="taskAssignee"
          />
        </div>
      </div>
    </template>
  </TaskDetailField>
</template>
