import BaseRepository from '@/base/repositories/BaseRepository';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
    DomainAddMemberPayload,
    ProjectAddMemberPayload,
} from '../types/domain/DomainPayload';

export default class DomainRepository {
    private static instance: DomainRepository;

    constructor() {}

    public static getInstance(): DomainRepository {
        if (!DomainRepository.instance) {
            // Get from local storage
            DomainRepository.instance = new DomainRepository();
        }

        return DomainRepository.instance;
    }

    getDomainScopeList(userId) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetDomainScopeList?userId=${userId}`,
            method: 'get',
        });
    }

    getDomainScopeListByUserId(userId) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetDomainScopeListByUserId?userId=${userId}`,
            method: 'get',
        });
    }

    getAll() {
        const lang = getLocalStorage(StorageConstant.LANGUAGE);
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetAll?lang=${lang}`,
            method: 'get',
        });
    }

    createDomain(domain) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/CreateDomain`,
                method: 'post',
                data: domain,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_DOMAIN_CREATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }

    saveOrUpdateDomain(domain) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/SaveOrUpdateDomain`,
                method: 'post',
                data: domain,
            },
            {
                message: translate(
                    domain.id === 0 || domain.id === undefined
                        ? 'API_ACTION_MESSAGE_DOMAIN_CREATE_DOMAIN_SUCCESS'
                        : 'API_ACTION_MESSAGE_DOMAIN_UPDATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }

    updateInformation(domain) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/UpdateInformation`,
                method: 'post',
                data: domain,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_DOMAIN_UPDATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }

    quicklyCreateDomain(domain) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/QuicklyCreateDomain`,
            method: 'post',
            data: domain,
        });
    }

    quicklyCreateProject(project) {
        return BaseRepository.requestWithAuthorize({
            url: `Project/QuicklyCreateProject`,
            method: 'post',
            data: project,
        });
    }

    deleteDomain(domainId) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/DeleteDomain?domainId=${domainId}`,
                method: 'post',
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_DOMAIN_CREATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }

    getAllDomainUserList() {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetAllDomainUserList`,
            method: 'get',
        });
    }

    addMembers(data: DomainAddMemberPayload | ProjectAddMemberPayload) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/AddMember`,
                method: 'post',
                data: data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_DOMAIN_UPDATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }

    updateMembers(domainId, data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/UpdateUserDomains?domainId=${domainId}`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_DOMAIN_UPDATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }
    updateGroups(domainId, data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/UpdateGroupDomains?domainId=${domainId}`,
                method: 'post',
                data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_DOMAIN_UPDATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }
    updateUserDomainRelative(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/UpdateUserDomainRelative`,
                method: 'post',
                data: data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_DOMAIN_UPDATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }

    removeMember(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/RemoveMember`,
                method: 'post',
                data: data,
            },
            {
                message: translate(
                    'API_ACTION_MESSAGE_DOMAIN_UPDATE_DOMAIN_SUCCESS'
                ),
            }
        );
    }

    rejectDomain(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/RejectDomain`,
            method: 'post',
            data,
        });
    }

    getOverviewDomainTasks() {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/OverviewDomainTasks`,
            method: 'get',
        });
    }

    getOverviewDomainFullDetail() {
        const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'en';
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetOverviewDomainFullDetail`,
            method: 'get',
            params: { lang },
        });
    }

    getDomainTodayStatistic(domainId) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetDomainTodayStatistic?domainId=${domainId}`,
            method: 'get',
        });
    }

    getDomainTaskCreateFinishStatistic(domainId, fromDate, toDate) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetDomainTaskCreateFinishStatistic?domainId=${domainId}&fromDate=${fromDate}&toDate=${toDate}`,
            method: 'get',
        });
    }

    getAllDomainTaskOverviews(domainId) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetAllDomainTaskOverviews?domainId=${domainId}`,
            method: 'get',
        });
    }

    getDomainTasksMemberActive(domainId) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetDomainTasksMemberActive?domainId=${domainId}`,
            method: 'get',
        });
    }

    getDomainFullDetail(domainId) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetDomainFullDetail?domainId=${domainId}`,
            method: 'get',
        });
    }
    setVisibleDomain(domainId) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/SetVisibleDomain`,
            method: 'post',
            params: {
                domainId,
            },
        });
    }
    updateDomainSupplement(data) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/UpdateDomainSupplement`,
            method: 'post',
            data,
        });
    }
    getCountTasksByDomain(isIncludeSub) {
        return BaseRepository.requestWithAuthorize({
            url: `Task/GetCountTasksByDomain`,
            method: 'get',
            params: {
                isIncludeSub,
            },
        });
    }
}
