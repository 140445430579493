<script setup lang="ts">
import SynModalNoFocus from '@/ui/common/molecules/SynModal/SynModalNoFocus.vue';
// import { useRouter } from 'vue-router';
import GroupDetail from '@/ui/modules/group/detail/GroupDetail.vue';
import groupStore from '@/store/group';
import { computed } from 'vue';

withDefaults(
  defineProps<{
    groupId: number;
    groupName?: string;
    currentTab?: string;
    position: string;
  }>(),
  {
    position: 'members',
    currentTab: '',
  }
);
const emit = defineEmits([
  'cancel',
  'showDelete',
  'changedPrivateSuccess',
  'onDeleteSuccess',
]);
const _groupStore = groupStore();
const allGroupByIds = computed<any>(() => {
  return _groupStore.allGroupByIds;
});

const onCancel = () => {
  emit('cancel', false);
};
</script>
<template>
  <SynModalNoFocus
    z-index="z-50"
    container-class="w-max h-max"
    style-body="mt-0 pl-2 overflow-hidden"
    disable-click-outside
    is-hidden-footer
    container-style="width: 40rem; height: 45rem; max-height: 90%"
    @cancel="onCancel"
  >
    <template #header>
      <div class="w-full flex items-center space-x-1">
        <span class="text-base w-max">
          {{ $t('DOCUMENT_TITLE_GROUP') + ' - ' }}
        </span>
        <span class="text-base w-max ml-2 text-current-600">
          {{ groupName || allGroupByIds[groupId]?.name || '' }}
        </span>
      </div>
    </template>

    <template #body>
      <GroupDetail
        v-if="groupId"
        :group-id="groupId"
        position="members"
        :current-tab="currentTab"
        @cancel="onCancel"
        @changed-private-success="$emit('changedPrivateSuccess')"
      />
    </template>
  </SynModalNoFocus>
</template>
