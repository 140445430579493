<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import AddTabsModal from '@/ui/modules/task/tabs-list-task/create-new-tabs/AddTabsModal.vue';
import TabTaskTag from '@/ui/modules/task/components/tabs/TabTaskTag.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import taskListStore from '@/store/task/task-list-store';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import TaskFilterCustomTag from '@/ui/modules/task/components/tabs/TaskFilterCustomTag.vue';
import {
  EFilterTaskDefault,
  LIST_FILTER_TASK_DEFAULT,
} from '@/application/constants/task.const';
import { TaskListTab } from '@/domain/enums/taskEnum';
import {
  getLocalStorage,
  setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import domainStore from '@/store/scope';
import groupStore from '@/store/group';
import userStore from '@/store/user';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { ITaskSummaryStatistics } from '@/domain/entities/task/TaskListClass';
import {
  ECustomFilterSourceType,
  ETaskListModule,
} from '@/application/types/task/task.types';
import taskStore from '@/store/task';

const props = defineProps<{
  type: 'MEMBER' | 'GROUP' | 'DOMAIN';
  listTaskOwnerId: number;
  isShowAll: boolean;
  isHiddenCustomTab?: boolean;
  isHiddenOtherTab?: boolean;
  customFilterSourceType?: ECustomFilterSourceType;
}>();
const emits = defineEmits(['onChangeTab']);

const _taskTabViewStore = taskTabViewStore();
const _taskListStore = taskListStore();
const boardShowFinishedTask = computed(
  () => _taskTabViewStore.boardShowFinishedTask
);

const isMyTaskPage = computed(
  () => props.listTaskOwnerId == getCurrentUserId()
);

const activeTab = computed(() => _taskListStore.activeTab);
const userTaskSummary = computed<ITaskSummaryStatistics>(() => {
  if (activeTab.value == TaskListTab.MyAssign)
    return _taskListStore.assignTaskSummaryStatistics;
  if (activeTab.value == TaskListTab.WaitingApprovalTasks)
    return _taskListStore.approvalTaskSummaryStatistics;

  return _taskListStore.taskSummaryStatistics;
});

const sourceType = computed<ETaskListModule>(() => {
  if (props.type == 'DOMAIN') return ETaskListModule.DOMAIN;
  if (props.type == 'GROUP') return ETaskListModule.GROUP;
  return ETaskListModule.USER;
});

const listTaskOwner = computed<any>(() => {
  if (props.type == 'DOMAIN') {
    const domainByIds = domainStore().domainByIds;
    return domainByIds[props.listTaskOwnerId];
  }
  if (props.type == 'GROUP') {
    const allGroupByIds = {
      ...groupStore().allGroupByIds,
      ...groupStore().myGroupByIds,
    };
    return allGroupByIds[props.listTaskOwnerId];
  }
  return userStore().allUserByIds[props.listTaskOwnerId];
});
const containerListTabs = ref(null as any);
const isShowMore = ref(false);
const listCustomTabs = computed<ICustomTaskFilter[]>(
  () => _taskTabViewStore.listCustomTabs
);
const currentTabId = computed<string>(() => _taskTabViewStore.currentTabId);
const listTabs = computed(() =>
  _taskTabViewStore.listTabs
    ?.filter((o) => {
      if (o?.id == EFilterTaskDefault.MY_RELATED)
        return (
          activeTab.value !== TaskListTab.MyAssign && sourceType.value == 'U'
        );
      if (o?.id == EFilterTaskDefault.PINNED)
        return activeTab.value == TaskListTab.MyList && isMyTaskPage.value;

      return true;
    })
    .map((tab) => {
      switch (tab?.id) {
        case EFilterTaskDefault.DEFAULT:
          return {
            ...tab,
            totalTask: userTaskSummary.value?.totalDefault,
          };
        case EFilterTaskDefault.DO_TODAY:
          return {
            ...tab,
            totalTask: userTaskSummary.value?.totalDoToday,
          };
        case EFilterTaskDefault.OVERDUE_DATE:
          return {
            ...tab,
            totalTask: userTaskSummary.value?.totalOverdue,
          };
        case EFilterTaskDefault.MY_RELATED:
          return {
            ...tab,
            totalTask: userTaskSummary.value?.totalRelated,
          };
        case EFilterTaskDefault.FINISHED:
          return {
            ...tab,
            totalTask: userTaskSummary.value?.totalFinished,
          };
        case EFilterTaskDefault.PINNED:
          return {
            ...tab,
            totalTask: userTaskSummary.value?.totalPinned,
          };
        case EFilterTaskDefault.OTHERS:
          return {
            ...tab,
            totalTask: userTaskSummary.value?.totalOther,
          };
        default:
          return { ...tab };
      }
    })
);

const tabActiveInMore = computed<any>(() => {
  const displayed =
    listTabs?.value
      ?.filter((o) => o.visible)
      ?.some((tab) => tab?.id == currentTabId.value) ||
    momentShowTabIds.value?.includes(currentTabId.value);

  if (displayed) return null;

  return [
    ...listTabs.value?.filter((o) => !o.visible),
    ...listCustomTabs.value,
  ].find((tab) => tab?.id == currentTabId.value);
});

onMounted(async () => {
  const cacheMomentShowTabIds = getLocalStorage(
    StorageConstant.TASK_LIST_MOMENT_SHOW_TAB
  );

  momentShowTabIds.value = cacheMomentShowTabIds
    ?.split(',')
    ?.filter((id) => id?.trim());

  const container = document.getElementById('filterTabsId') as any;
  if (containerListTabs.value?.offsetWidth > (container.offsetWidth * 2) / 3) {
    // console.log('containerListTabs', containerListTabs.value?.offsetWidth);
    // console.log(
    //   'filterTabsId',
    //   document.getElementById('filterTabsId')?.offsetWidth
    // );
  }
});

const onChangeTab = (tab) => {
  _taskListStore.filteredTasks = [];
  _taskTabViewStore.changeTabActive(tab?.id);
  emits('onChangeTab', tab);
};
const changeCustomTab = async (tab: ICustomTaskFilter) => {
  _taskTabViewStore.changeCustomTab(tab);
  const filterPayload = {
    ...tab?.filterObject,
  };
  await _taskListStore.getTaskWithFilter(filterPayload);
  _taskListStore.setDefault();
};
const handleRemoveCustomTab = async (tab: ICustomTaskFilter) => {
  settingGlobalModal({
    type: 'notification',
    title: '',
    content:
      translate('TASK_CUSTOM_FILTER_CONFIRM_REMOVE') ||
      'Do you want to remove this tab',
    confirmLabel: translate('COMMON_LABEL_CONFIRM'),
    confirmable: true,
    closeable: true,
    cancelLabel: translate('COMMON_LABEL_CANCEL'),
  });
  const answer = await ask();
  if (answer) {
    _taskTabViewStore.deleteUserCustomTaskFilter(
      sourceType.value || ETaskListModule.USER,
      props.listTaskOwnerId,
      tab.id
    );
    if (currentTabId.value == tab?.id) {
      onChangeTab(listTabs.value[0]);
    }
  }
};
const handleRemoveSuccess = (tab: ICustomTaskFilter) => {
  if (currentTabId.value == tab?.id) {
    onChangeTab(listTabs.value[0]);
  }
};

const momentShowTabIds = ref<string[]>([]);

const momentShowTabs = computed<any>(() => {
  return momentShowTabIds.value?.length > 0
    ? momentShowTabIds.value
        ?.map((tabId) => {
          const defaultIndex = listTabs.value?.findIndex(
            (tab) => tab?.id == tabId
          );
          if (defaultIndex > -1) return listTabs.value[defaultIndex];

          const customIndex = listCustomTabs.value?.findIndex(
            (tab) => tab?.id == tabId
          );

          if (customIndex > -1)
            return {
              ...listCustomTabs.value[customIndex],
              isCustom: true,
            };

          return {};
        })
        ?.filter((tab: any) => tab?.id)
    : [];
});
const onToggleShowTab = (tabId) => {
  if (momentShowTabIds.value?.some((id) => id == tabId)) {
    momentShowTabIds.value = momentShowTabIds.value?.filter(
      (id) => id !== tabId
    );
  } else {
    momentShowTabIds.value.push(tabId);
  }

  setLocalStorage(
    StorageConstant.TASK_LIST_MOMENT_SHOW_TAB,
    momentShowTabIds.value?.toString()
  );
};

const onChangeBoardShowFinishedTask = (value) => {
  _taskTabViewStore.onChangeBoardShowFinishedTask(value);
  if (
    currentTabId.value &&
    Object.prototype.hasOwnProperty.call(
      LIST_FILTER_TASK_DEFAULT,
      currentTabId.value
    )
  )
    onChangeTab(LIST_FILTER_TASK_DEFAULT[currentTabId.value]);
};

const totalFilteredTasks = computed(() => {
  if (_taskListStore?.isGroupSubTasks)
    return (
      (_taskListStore.filteredTasks?.length || 0) +
      ((_taskListStore.taskAllList
        ?.map((t) => taskStore().allTaskByIds[t?.id])
        .reduce((currentTotal: number, currentItem: any) => {
          return currentTotal + (currentItem?.subTasks?.length || 0);
        }, 0) as number) || 0)
    );
  return _taskListStore.filteredTasks?.length || 0;
});

const isLoading = computed(() => _taskListStore.isLoading);
const countTotalTasks = (tab): string => {
  const totalDefault = tab?.totalTask;
  if (tab?.id?.toString() !== currentTabId.value || isLoading.value)
    return totalDefault && totalDefault > 0 ? `${totalDefault}` : '';

  return totalFilteredTasks.value > 0 && totalFilteredTasks.value < totalDefault
    ? `${totalFilteredTasks.value}/${totalDefault}`
    : totalDefault
    ? `${totalDefault}`
    : '';
};
</script>

<template>
  <div ref="containerListTabs" class="flex items-center space-x-1 text-xs">
    <template v-for="tab in listTabs?.filter((o) => o.visible)" :key="tab?.key">
      <TabTaskTag
        :is-active="currentTabId === tab?.id"
        :color="tab.color"
        :name="
          $t(tab?.labelKey, {
            username: listTaskOwner?.isMe
              ? $t('COMMON_LABEL_ME')
              : listTaskOwner?.firstName,
          })
        "
        :total="countTotalTasks(tab)"
        :readonly="tab?.isDefault"
        :tab-detail="tab"
        @on-select-tab="onChangeTab(tab)"
      />
    </template>
    <template v-for="tab in momentShowTabs" :key="tab?.key">
      <TaskFilterCustomTag
        v-if="tab?.isCustom && !isHiddenCustomTab"
        :key="tab.id"
        :is-active="currentTabId === tab?.id"
        :color="tab.color || '#008E8E'"
        :name="tab?.name"
        :list-task-owner-id="listTaskOwnerId"
        :source-type="sourceType"
        :tab-detail="tab"
        @click="changeCustomTab(tab)"
        @on-delete-tab="handleRemoveCustomTab(tab)"
        @on-delete-success="handleRemoveSuccess(tab)"
      />
      <TabTaskTag
        v-else-if="!tab?.isCustom"
        :is-active="currentTabId === tab?.id"
        :color="tab.color"
        :name="
          $t(tab?.labelKey, {
            username: listTaskOwner?.isMe
              ? $t('COMMON_LABEL_ME')
              : listTaskOwner?.firstName,
          })
        "
        :total="countTotalTasks(tab)"
        :readonly="tab?.isDefault"
        :tab-detail="tab"
        @on-select-tab="onChangeTab(tab)"
      />
    </template>

    <VigDropdown
      v-if="!isHiddenOtherTab"
      :arrow="false"
      placement="bottom-end"
      @on-dropdown-open="isShowMore = true"
      @on-dropdown-close="isShowMore = false"
    >
      <template #dropdown-toggle>
        <div
          class="
            flex-center
            cursor-pointer
            rounded-full
            hover:bg-gray-200
            bg-gray-100
          "
          :class="[
            isShowMore
              ? 'ring-2 ring-current-100 ring-opacity-50 border border-current-200 fill-current-400'
              : 'fill-gray-500',
            tabActiveInMore ? 'px-1 py-0.5' : '',
          ]"
          :style="
            tabActiveInMore
              ? `background-color: ${
                  tabActiveInMore?.color || '#008E8E'
                }20; color: ${tabActiveInMore?.color || '#008E8E'};`
              : ``
          "
        >
          <span
            v-if="tabActiveInMore?.name"
            style="max-width: 8rem"
            class="truncate px-1 font-medium"
          >
            {{
              tabActiveInMore?.labelKey
                ? $t(tabActiveInMore?.labelKey, {
                    username: listTaskOwner?.isMe
                      ? $t('COMMON_LABEL_ME')
                      : listTaskOwner?.firstName,
                  })
                : tabActiveInMore?.name
            }}
          </span>
          <div class="w-6 h-6 flex-center">
            <SynIcon
              name="dots-vertical"
              custom-class="w-4 h-4 fill-gray-500 text-gray-500 rotate-90"
            />
          </div>
        </div>
      </template>
      <template #dropdown-menu>
        <div
          class="
            flex flex-col
            dropdown-item
            p-1
            overflow-y-auto
            small-scrollbar
          "
          style="min-width: 14rem; max-height: 40rem"
        >
          <template
            v-for="tabOther in listTabs?.filter((o) => !o.visible)"
            :key="tabOther"
          >
            <div
              class="
                list-li
                flex
                items-center
                space-x-1
                justify-between
                text-xs
                rounded
              "
              :style="
                currentTabId == tabOther?.id
                  ? `background-color: ${tabOther?.color}20; color: ${tabOther?.color}`
                  : ``
              "
              @click="onChangeTab(tabOther)"
            >
              <AtomSwitch
                :model-value="
                  momentShowTabIds?.some((id) => id == tabOther?.id)
                "
                size="small"
                @click="onToggleShowTab(tabOther?.id)"
              />
              <span class="flex-1 truncate">{{
                $t(tabOther?.labelKey, {
                  username: listTaskOwner?.isMe
                    ? $t('COMMON_LABEL_ME')
                    : listTaskOwner?.firstName,
                })
              }}</span>
              <span
                v-if="countTotalTasks(tabOther)"
                class="px-1 rounded-full text-xs"
                style="font-size: 10.5px"
                :style="
                  currentTabId == tabOther?.id
                    ? `background-color: ${tabOther?.color}; color: white;`
                    : `background-color: #9ca3af; color: white;`
                "
              >
                {{ countTotalTasks(tabOther) }}
              </span>
            </div>
          </template>
          <template v-if="listCustomTabs?.length > 0 && !isHiddenCustomTab">
            <template v-for="(tab, index) in listCustomTabs" :key="tab?.id">
              <div
                class="
                  flex
                  items-center
                  space-x-1
                  justify-between
                  text-xs
                  rounded
                "
                :style="
                  currentTabId == tab?.id
                    ? `background-color: ${tab?.color}20; color: ${tab?.color}`
                    : ``
                "
                @click="changeCustomTab(tab)"
              >
                <TaskFilterCustomTag
                  :key="tab.id"
                  :is-active="currentTabId === tab?.id"
                  :color="tab.color || '#008E8E'"
                  :name="tab?.name || `New ${index + 1}`"
                  :list-task-owner-id="listTaskOwnerId"
                  :source-type="sourceType"
                  :tab-detail="tab"
                  ui-type="LI_ITEM"
                  @on-delete-tab="handleRemoveCustomTab(tab)"
                  @on-delete-success="handleRemoveSuccess(tab)"
                >
                  <template #prefix>
                    <AtomSwitch
                      :model-value="
                        momentShowTabIds?.some((id) => id == tab?.id)
                      "
                      size="small"
                      @click="onToggleShowTab(tab?.id)"
                    />
                  </template>
                </TaskFilterCustomTag>
              </div>
            </template>
          </template>
        </div>
      </template>
    </VigDropdown>
    <AddTabsModal
      v-if="isShowAll && !isHiddenCustomTab"
      :source-type="sourceType"
      :custom-filter-source-type="customFilterSourceType"
      :list-task-owner-id="listTaskOwnerId"
    />

    <div
      v-if="
        currentTabId === EFilterTaskDefault.MY_RELATED ||
        currentTabId === EFilterTaskDefault.OVERDUE_DATE
      "
      class="pl-8"
    >
      <AtomSwitch
        :model-value="boardShowFinishedTask"
        :label="$t('TASK_LIST_SHOW_FINISHED_TASKS')"
        @update:model-value="onChangeBoardShowFinishedTask"
      />
    </div>
  </div>
</template>
