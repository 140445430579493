<script setup lang="ts">
import UserById from '@/ui/components/user/UserById.vue';
import { CalendarSourceId } from '@/domain/enums/CalendarEnum';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { computed } from 'vue';

const props = defineProps<{
  event: any;
}>();

const participants = computed(
  () => props.event?.participants || props.event.metadata?.participants
);
</script>

<template>
  <div v-if="participants?.length" class="flex items-start gap-4">
    <div :title="$t('CALENDAR_TITLE_PEOPLE_INVOLVED')" class="flex-center py-2">
      <SynIcon name="Participants" class="fill-gray-500" />
    </div>
    <div class="flex items-center flex-wrap gap-1 text-sm">
      <UserById
        v-for="participant in participants"
        :key="participant?.id"
        :user="participant"
        avatar-class="w-5 h-5"
        class="bg-current-50 bg-opacity-80 rounded-full py-1 pl-1 pr-2 text-xs"
      />
    </div>
  </div>

  <div
    v-else-if="
      event.metadata?.participantIds?.length > 0 ||
      (event.metadata?.participantIds?.length == 1 &&
        // eslint-disable-next-line vue/no-mutating-props
        event.metadata?.participantIds?.pop() !== getCurrentUserId())
    "
    class="flex items-start gap-4"
  >
    <div :title="$t('CALENDAR_TITLE_PEOPLE_INVOLVED')" class="flex-center py-2">
      <SynIcon name="Participants" class="fill-gray-500" />
    </div>
    <div class="flex items-center flex-wrap text-sm">
      <UserById
        v-for="id in event.metadata?.participantIds"
        :key="id"
        :user-id="id"
        avatar-class="w-5 h-5"
        class="
          bg-current-50 bg-opacity-80
          rounded-full
          py-1
          pl-1
          pr-2
          mr-1
          mt-1
          text-xs
        "
        style="font-weight: 400"
      />
    </div>
  </div>
</template>
