import { ref } from 'vue';

export const activeAndFocusOnConversationId = ref('');
export const isForceActive = ref(false);

// export const IS_USING_INDEXEDDB = true;
export const PREPARED_MESSAGE_TOTAL_MAX = 200;
export const PREPARED_MORE_MESSAGE_WHEN_MESSAGE_REMAINS = 100;

// using message page
// export const conversationActive = ref();
// export const chatPanelList = ref<any[]>([]);
