export const systemKey = {
    DEPLOYMENTS: 'deployments',
    ANNOUNCEMENTS: 'announcements',
    choose_organization_model_settings: 'choose_organization_model_settings',
    register_with_phone_or_username: 'register_with_phone_or_username',
    setup_organization_process: 'setup_organization_process',
};

export const typeAnnouncements = {
    NEW_DEPLOYMENT: 'newDeployment',
};

export const stateDeployments = {
    NORMAL: {
        state: 0,
        key: 'NORMAL',
    },
    // Hệ thống sắp deploy
    UPCOMING: {
        state: 1,
        key: 'UPCOMING',
    },
    // Hệ thống đang deploy
    DEPLOYING: {
        state: 2,
        key: 'DEPLOYING',
    },
};

export const typeDeployments = {
    // Hệ thống nâng cấp
    UPGRADE: 1,
    // Hệ thống bảo trì
    MAINTENANCE: 2,
};

export const severity = {
    NORMAL: {
        index: 1,
        key: 'NORMAL',
    },
    HIGH: {
        index: 2,
        key: 'HIGH',
    },
    CRITICAL: {
        index: 3,
        key: 'CRITICAL',
    },
};

export enum EDeploymentState {
    NORMAL = 0,
    UPCOMING = 1, // System deployment is coming
    DEPLOYING = 2, // System deployment is deploying
}
