<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M0,5.1C5.8,10.8,11.6,16.4,17.4,22c1.3,0,2.5,0,3.8,0C14.8,15.7,8.4,9.4,2,3.2C1.3,3.8,0.7,4.5,0,5.1z M5.2,10.7  c0,3.8,0,7.5,0,11.3h16V4h-4l-2.1,2h-3.9l-2-2h-4c0,0.6,0,1.1,0,1.7c-0.7-0.6-1.3-1.2-2-1.8V2h3c1.2,0,2.2-1.1,3-2h8  c0.8,0.9,1.8,2,3,2h3v22h-20c0-5.1,0-10.2-0.1-15.3C3.8,9.3,4.5,10,5.2,10.7z M13.2,2c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1  S13.7,2,13.2,2z"
    />
  </svg>
</template>
