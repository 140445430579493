<template>
  <div class="flex flex-col justify-center">
    <span class="font-medium text-current text-3xl w-2/3">
      {{ $t('SETUP_TEAM_INVITE') || 'Invite people to your Organization:' }}
    </span>
    <div class="flex space-x-2 mt-12 w-full flex-col relative">
      <template v-if="emailPhoneInviteItem">
        <button v-if="isPhoneNumber">
          <span class="absolute -left-12 flex-center">
            <SynIcon name="flag-vietnam" />
            <span class="text-sm"> (+84) </span>
          </span>
        </button>
        <span v-else class="absolute -left-12 flex-center text-sm">
          Email
        </span>
      </template>

      <input
        v-model="emailPhoneInviteItem"
        class="outline-none border-b border-current w-full text-2xl"
        placeholder="Email or mobile phone number"
        @keyup.enter="onAddInvite"
        @input="onChangeInviteInput"
      />
      <div
        v-if="emailPhoneInviteItem"
        class="flex text-xs absolute -bottom-4 w-full"
        :class="errorMessageCode ? 'justify-between' : 'justify-end'"
      >
        <span class="text-red-500">{{ $t(errorMessageCode) }}</span>
        <span class="text-current">press ENTER to add</span>
      </div>
    </div>
    <div class="mt-8 w-96">
      <div
        v-for="data of emailPhoneInvites"
        :key="data"
        class="
          relative
          py-2
          my-1
          rounded
          shadow
          bg-gray-50
          text-sm
          px-2
          flex
          items-center
          space-x-2
        "
        @click.stop="onSelectUser(data)"
      >
        <span>
          {{ data }}
        </span>
        <SynIcon
          class="absolute right-0"
          has-action
          name="close"
          @click="onRemoveItem(data)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import {
  isValidEmail,
  isValidPhoneNumber,
  isNumber,
} from '@/ui/hooks/commonFunction';
export default {
  components: {},
  props: {},
  emits: ['updateListItem'],
  setup(props, { emit }) {
    const emailPhoneInviteItem = ref('');
    const emailPhoneInvites = ref([]);
    const isPhoneNumber = computed(() => isNumber(emailPhoneInviteItem.value));
    const errorMessageCode = ref('');
    const onAddInvite = async () => {
      // 1. Check valid phone/ email
      // 1.1 Check valid
      let isDuplicated = false;
      let itemValue = emailPhoneInviteItem.value;
      if (isPhoneNumber.value) {
        if (!isValidPhoneNumber(emailPhoneInviteItem.value)) {
          errorMessageCode.value = 'COMMON_INVALID_PHONE_NUMBER';
          return;
        }
        itemValue = `+84${itemValue}`;
      } else {
        if (!isValidEmail(emailPhoneInviteItem.value)) {
          errorMessageCode.value = 'COMMON_INVALID_EMAIL';
          return;
        }
      }
      // 1.2 Check duplicated
      isDuplicated = emailPhoneInvites.value?.some(
        (o) => o.toLowerCase() == itemValue
      );
      if (isDuplicated) {
        errorMessageCode.value = isPhoneNumber.value
          ? 'COMMON_INVALID_DUPLICATE_PHONE_NUMBER'
          : 'COMMON_INVALID_DUPLICATE_EMAIL';
        return;
      }
      // 2. Call api check existed
      // const isExisted = await _organizationStore.getCheckEmailPhone(itemValue);
      // console.log(
      //   '🚀 ~ file: OrganizationHome.vue ~ line 175 ~ onAddInvite ~ isExisted',
      //   isExisted
      // );
      // if (isExisted) {
      //   errorMessageCode.value = isPhoneNumber.value
      //     ? 'COMMON_LABEL_REQUIRE_PHONE_IS_EXISTED'
      //     : 'COMMON_LABEL_REQUIRE_EMAIL_IS_EXISTED';
      //   return;
      // }
      // 3. Add to list
      emailPhoneInvites.value.push(itemValue);
      emit('updateListItem', emailPhoneInvites.value);
      emailPhoneInviteItem.value = '';
    };
    const onChangeInviteInput = () => {
      errorMessageCode.value = '';
    };
    const onRemoveItem = (data) => {
      emailPhoneInvites.value = emailPhoneInvites.value.filter(
        (o) => o.toLowerCase() !== data.toLowerCase()
      );
      emit('updateListItem', emailPhoneInvites.value);
    };
    return {
      emailPhoneInviteItem,
      onAddInvite,
      isPhoneNumber,
      errorMessageCode,
      emailPhoneInvites,
      onChangeInviteInput,
      onRemoveItem,
    };
  },
};
</script>
