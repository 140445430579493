<script setup lang="ts">
import { computed } from 'vue';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import permissionStore from '@/store/permission';
import systemConfig from '@/application/constants/system-config.const';

const _permissionStore = permissionStore();

const allSystemConfigs = computed(() => _permissionStore.allSystemConfigs);

const modelValue = computed(() => {
  return (
    allSystemConfigs.value[systemConfig.SHOW_HIDE_THE_TASK_PERFORMANCE_MENU] ==
    '1'
  );
});

const onChange = () => {
  _permissionStore.setPermissions({
    key: systemConfig.SHOW_HIDE_THE_TASK_PERFORMANCE_MENU,
    value: modelValue.value ? '0' : '1',
  });
};
</script>
<template>
  <MenuSettingRowItem
    name-icon="Report"
    :title-menu="$t('SETTING_ORGANIZATION_HIDE_REPORT_MENU_TITLE')"
    :sub-title="$t('SETTING_ORGANIZATION_HIDE_REPORT_MENU_SUB_TITLE')"
  >
    <template #action>
      <div class="flex-center space-x-2">
        <AtomSwitch :model-value="modelValue" @change="onChange" />
      </div>
    </template>
  </MenuSettingRowItem>
</template>
