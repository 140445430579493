<script setup lang="ts">
import { computed } from 'vue';
import userStore from '@/store/user';
import ProjectTreeCheckbox from '@/ui/modules/domains/projects/ProjectTreeCheckbox.vue';

const props = withDefaults(
  defineProps<{
    userId: number;
    isAnyProjects: boolean;
    domainId?: number | string;
    parentId?: number | string | null;
    projectId?: number | string | null;
    domainName?: string;
    userProjectIds?: any[];
    isNeedGetProject: boolean;
  }>(),
  {
    domainName: '',
  }
);

defineEmits<{
  (e: 'update:isAnyProjects', value: boolean): boolean;
  (e: 'update:userProjects', data: any): boolean;
}>();

const _userStore = userStore();

const allUserByIds = computed(() => _userStore.allUserByIds);

const userDetail = computed(() => allUserByIds.value[props.userId]);
</script>

<template>
  <div class="flex-1 min-h-0 flex flex-col overflow-hidden">
    <div class="flex space-x-2">
      <span class="text-current">{{ userDetail?.name }}</span>
      <span> {{ $t('COMMON_LABEL_ASSOCIATION_WITH') }}:</span>
    </div>
    <!-- Edit domain -->
    <section class="h-full flex-1 min-h-0 flex space-y-2">
      <span class="w-8"></span>
      <ProjectTreeCheckbox
        :is-any-projects="isAnyProjects"
        :domain-id="domainId"
        :parent-id="parentId"
        :project-id="projectId"
        :user-id="userId"
        :domain-name="domainName"
        :user-project-ids="userProjectIds"
        :is-need-get-project="isNeedGetProject"
        @update:is-any-projects="
          (value) => $emit('update:isAnyProjects', !!value)
        "
        @update:user-projects="(value) => $emit('update:userProjects', value)"
      />
    </section>
  </div>
</template>
