<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import MessageFile from '@/ui/modules/messaging/chat-panel/chat-message/message-file/MessageFile.vue';
import calculateImageLayoutComposables from '@/ui/composables/chat/calculate-image-layout-composables';

const props = withDefaults(
  defineProps<{
    files: any;
    size: 'sm' | 'lg' | 'md';
    qualityImage: 'original' | 'mini' | 'small' | 'large';
  }>(),
  {
    size: 'md',
    qualityImage: 'large',
  }
);

defineEmits<{
  (e: 'onFileClick', value: any): void;
  (e: 'onMessageDownload', value: any): void;
}>();

const fileByIds = computed(() => {
  return props.files?.reduce((oldValue, currentValue) => {
    return {
      ...oldValue,
      [currentValue.id]: currentValue,
    };
  }, {});
});

const { containerDefaultBySize, calculateImageLayout } =
  calculateImageLayoutComposables({ size: props.size });
const groupMessageFilesContainerRef = ref();
const fileByRows = ref<any>([]);
const isCalculatedLayout = ref(false);
const idPrefix = 'group-comment-file';
onMounted(() => {
  setTimeout(() => {
    const totalFiles = Object.keys(fileByIds.value)?.length;
    if (totalFiles <= 1) return;
    fileByRows.value = calculateImageLayout(fileByIds.value, idPrefix);
    isCalculatedLayout.value = true;
  }, 100);
});
</script>

<template>
  <template v-if="files?.length">
    <div class="relative">
      <div
        v-if="files?.length > 0"
        ref="groupMessageFilesContainerRef"
        class="flex flex-col justify-start"
      >
        <div v-if="!isCalculatedLayout">
          <div
            v-for="fileId in Object.keys(fileByIds)"
            :id="`${idPrefix}-${fileId}`"
            :key="fileId"
            class="w-max cursor-pointer"
            :style="`max-width: ${containerDefaultBySize.containerMaxWidth}px;`"
          >
            <MessageFile
              :file="fileByIds[fileId]"
              :size="size"
              :img-size="qualityImage"
              :is-check-error="true"
              :length-files="files?.length"
              @on-file-click="$emit('onFileClick', fileByIds[fileId])"
            />
          </div>
        </div>
        <template v-else>
          <div
            v-for="(row, index) in fileByRows"
            :key="index"
            class="flex msg-content w-full justify-start"
          >
            <div
              v-for="itemInRow in row"
              :key="itemInRow.fileId"
              class="cursor-pointer"
              :style="`width: ${itemInRow.width}px;max-width: ${containerDefaultBySize.containerMaxWidth}px; margin: 0.1rem;`"
            >
              <MessageFile
                :file="fileByIds[itemInRow.fileId]"
                :size="size"
                :img-size="qualityImage"
                :is-check-error="true"
                :length-files="files?.length"
                @on-file-click="
                  $emit('onFileClick', fileByIds[itemInRow.fileId])
                "
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </template>
</template>
