<script setup lang="ts">
import { computed } from 'vue';
import { styleByPercent } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  percent: number;
  disabled?: boolean;
}>();

const demensionPercent0 = 10;
const demensionRatio = 3.8;

const demensionByPercent = computed(() => {
  return Math.round(demensionPercent0 + props.percent * demensionRatio);
});
</script>
<template>
  <div class="flex-1 flex-center relative">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
      class="w-full"
    >
      <g>
        <!-- <path d="M46.545 162.909H349.09v186.182H46.545z" class=""></path> -->
        <path
          :d="`M46.545 162.909h${demensionByPercent || 0}v186.182H46.545z`"
          class=""
          style="clip-path: inset(0px 0px 0px round 20px)"
          :style="{
            fill: disabled ? '#9ca3af' : styleByPercent(percent)?.bg,
          }"
        ></path>
        <path
          d="M488.727 209.455V162.91c0-25.706-20.839-46.545-46.545-46.545H46.545C20.839 116.364 0 137.203 0 162.909v186.182c0 25.706 20.839 46.545 46.545 46.545h395.636c25.706 0 46.545-20.839 46.545-46.545v-46.545c12.853 0 23.273-10.42 23.273-23.273v-46.545c.001-12.854-10.419-23.273-23.272-23.273zm-23.272 23.272v116.364c0 12.853-10.42 23.273-23.273 23.273H46.545c-12.853 0-23.273-10.42-23.273-23.273V162.909c0-12.853 10.42-23.273 23.273-23.273h395.636c12.853 0 23.273 10.42 23.273 23.273v69.818z"
          class=""
          :style="`fill: ${
            disabled ? '#9ca3af' : styleByPercent(percent)?.bg
          }; fill-opacity: 0.5`"
        ></path>
      </g>
    </svg>
    <span
      class="absolute text-2xl m-auto font-semibold"
      :style="{
        color: disabled ? '#9ca3af' : styleByPercent(percent)?.bg,
        textShadow:
          '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
      }"
    >
      {{ disabled || Number.isNaN(percent) ? '' : percent }} %
    </span>
  </div>
</template>
