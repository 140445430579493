<script setup lang="ts">
import { ref, computed } from 'vue';
import organizationStore from '@/store/organization';
import { ErrorResponseClass } from '@/domain/entities/ResponseClass/CommonResponseClass';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const regexCode = /^(?=.{9}$)[A-Z]*\d[A-Z]*\d[A-Z]*\d[A-Z]*$/;

const props = defineProps<{
  isNotAuthenticated?: boolean;
}>();
const emit = defineEmits(['onCancel', 'onSuccess']);

const _organizationStore = organizationStore();
const attachmentCode = ref('');
const isFetchingJoin = ref(false);
const organizationTypes = computed(() => _organizationStore.organizationTypes);

const handleClickJoinNow = async () => {
  try {
    isFetchingJoin.value = true;
    const payload = {
      code: attachmentCode.value?.toUpperCase(),
    };

    if (props.isNotAuthenticated) {
      emit('onSuccess', payload?.code, 'Register');

      return;
    }
    const attachedOrganization =
      await _organizationStore.attachOrganizationByCode(payload);
    console.log('🚀 Tictop ~ attachedOrganization:', attachedOrganization);

    if (attachedOrganization?.organizationId)
      emit('onSuccess', attachedOrganization);
    else {
      isFetchingJoin.value = false;
      attachmentCodeError.value = 'COMMON_ATTACHMENT_CODE_INVALID';
      textShowError.value = attachedOrganization?.code
        ? `ERROR_EXCEEDING_THE_LIMIT_VISITOR_${attachedOrganization?.code}`
        : attachedOrganization?.message;
    }
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
    isFetchingJoin.value = false;
    attachmentCodeError.value = 'COMMON_ATTACHMENT_CODE_INVALID';
    textShowError.value = 'COMMON_ERROR_ATTACHMENT_CODE';
    openNotification({
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body:
        error?.error?.code == 6109
          ? translate('ERROR_EXCEEDING_THE_LIMIT_VISITOR_6109', {
              orgName: organizationInfoByAttachCode.value?.name || '',
            })
          : error?.error?.message,
      duration: 5000,
    });
  }
};

const organizationInfoByAttachCode = ref({} as any);

const handleCheckCodeError = (errorCode) => {
  const errorClass = new ErrorResponseClass(errorCode);
  isFetchingJoin.value = false;
  attachmentCodeError.value =
    errorClass.data.textCode || errorClass.data.textMessage;
};

const latestEnteredCode = ref<string>('');
const handleCheckCode = async () => {
  const newAttachCode = attachmentCode.value?.toUpperCase();
  if (latestEnteredCode.value && latestEnteredCode.value !== newAttachCode) {
    return;
  }

  try {
    isFetchingJoin.value = true;
    const payload = {
      code: newAttachCode,
    };

    const res = await _organizationStore.checkOrganizationAttachCode(payload);
    if (res?.success) {
      organizationInfoByAttachCode.value = res.result;

      latestEnteredCode.value = newAttachCode;

      setTimeout(() => {
        latestEnteredCode.value = '';
      }, 10 * 1000);
    } else handleCheckCodeError(res.error?.code);

    isFetchingJoin.value = false;
  } catch (error: any) {
    handleCheckCodeError(error?.error?.code);
  }
};

const attachmentCodeError = ref('');
const textShowError = ref('');
const attachmentCodeIsValid = ref(false);
const isChecking = ref(false);
let timer;
const onChangeAttachmentCode = async (event) => {
  // 1. clear error
  attachmentCodeError.value = '';
  attachmentCodeIsValid.value = false;
  // 2. clear timeout
  clearTimeout(timer);
  isChecking.value = true;
  // 3. start timeout
  const value = event.target.value;
  if (value) {
    timer = setTimeout(async () => {
      if (!regexCode.test(value.toUpperCase())) {
        isChecking.value = false;
        attachmentCodeError.value = 'COMMON_INVALID_CODE';
        return;
      }
      isChecking.value = false;
      attachmentCodeIsValid.value = true;
    }, 750);
  } else {
    attachmentCodeError.value = 'COMMON_THIS_FIELD_REQUIRED';
  }
};
const isDisabledButton = computed(() => {
  return attachmentCodeError.value || !attachmentCode.value || isChecking.value;
});

const onResetAttachCode = () => {
  organizationInfoByAttachCode.value = {};
  attachmentCode.value = '';
  attachmentCodeError.value = '';
  attachmentCodeIsValid.value = false;
  textShowError.value = '';
  isChecking.value = false;
};

const addressDetail = computed(() => {
  return organizationInfoByAttachCode.value?.address;
});

const typeOrganization = computed(() => {
  const nameType = organizationTypes?.value?.find(
    (el) => el?.code == organizationInfoByAttachCode.value?.type
  )?.name;
  return nameType || '';
});
</script>

<template>
  <syn-modal
    container-class="w-max p-0"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    disable-click-outside
    @cancel="$emit('onCancel')"
  >
    <template #body>
      <div class="max-w-[48rem] bg-opacity-40 rounded-xl flex space-x-8">
        <div
          v-if="organizationInfoByAttachCode?.id"
          class="flex-1 flex flex-col space-y-4 px-4"
        >
          <span class="text-sm font-semibold pb-4">
            {{
              $t('ATTACH_ORGANIZATION_HEADER') ||
              "Join your team's workspace and boost performance."
            }}
          </span>
          <div class="flex-1 flex flex-col space-y-3">
            <div class="flex space-x-4 items-start">
              <syn-avatar
                :src="organizationInfoByAttachCode?.logoUrl"
                :name="organizationInfoByAttachCode?.name"
                custom-class="h-16 w-16"
              >
              </syn-avatar>
              <div class="flex flex-col space-y-2">
                <span class="font-semibold text-lg text-current-800">
                  {{ organizationInfoByAttachCode?.name }}
                </span>
                <div v-if="typeOrganization" class="flex items-start space-x-2">
                  <SynIcon
                    name="building"
                    custom-class="h-4 w-4 fill-gray-500"
                  />
                  <span class="text-sm">
                    {{ typeOrganization }}
                  </span>
                </div>
                <div v-if="addressDetail" class="flex items-start space-x-2">
                  <SynIcon
                    name="address"
                    custom-class="h-5 w-5 fill-gray-500"
                  />
                  <span class="text-sm">
                    {{ addressDetail }}
                  </span>
                </div>
                <div
                  v-if="organizationInfoByAttachCode?.phone"
                  class="flex items-center space-x-2"
                >
                  <SynIcon name="phone" custom-class="h-4 w-4 fill-gray-500" />
                  <span class="text-sm">
                    {{ organizationInfoByAttachCode?.phone }}
                  </span>
                </div>
                <div
                  v-if="organizationInfoByAttachCode?.email"
                  class="flex items-start space-x-2"
                >
                  <SynIcon name="email" custom-class="h-5 w-5 fill-gray-500" />
                  <span class="text-sm">
                    {{ organizationInfoByAttachCode?.email }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="textShowError">
              <span class="italic text-red-500 text-xs"
                >*
                <span
                  v-html="
                    $t(textShowError, {
                      orgName: '',
                    })
                  "
                ></span
              ></span>
            </div>
          </div>
          <div class="flex items-center justify-between gap-4">
            <span
              class="hover:text-current cursor-pointer font-semibold"
              @click="onResetAttachCode"
            >
              {{ $t('COMMON_LABEL_BACK') || 'Back' }}
            </span>
            <div class="flex-center gap-4">
              <SynButton
                class="w-40"
                :is-loading="isFetchingJoin"
                :disabled="isDisabledButton"
                @click="handleClickJoinNow"
              >
                <span>{{ $t('COMMON_ATTACH_NOW') || 'Attach now' }}</span>
              </SynButton>
            </div>
          </div>
        </div>
        <div v-else class="flex-1 flex flex-col space-y-4 p-4">
          <span class="text-sm font-semibold pb-4">
            {{ $t('SIGN_UP_LABEL_HEADER_STEP_3') }}
          </span>
          <SynFormInput
            :error-message="attachmentCodeError"
            :is-valid="attachmentCodeIsValid"
          >
            <SynInput
              v-model="attachmentCode"
              :custom-class="'uppercase text-center'"
              autofocus
              :placeholder="$t('COMMON_ENTER_CODE_HERE') || 'Enter code here'"
              :error-message="attachmentCodeError"
              @input="onChangeAttachmentCode"
            />
          </SynFormInput>
          <div class="flex items-center justify-end">
            <syn-button
              class="w-40"
              :is-loading="isFetchingJoin"
              :disabled="isDisabledButton"
              @click="handleCheckCode"
            >
              <span>{{ $t('COMMON_LABEL_CONTINUE') || 'Continue' }}</span>
            </syn-button>
          </div>
        </div>
        <syn-animation name="teamwork" stype="width: 220px" />
      </div>
    </template>
  </syn-modal>
</template>
