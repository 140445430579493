import { defineStore } from 'pinia';
import CommonService from '@/application/services/CommonService';
import storeId from '@/base/store/storeId';
import { IMyResourceStatistic } from '@/application/types/CommonTypes';

export default defineStore({
    id: storeId.STATISTIC,
    state: () => ({
        myStatistics: {
            totalMessage: 0,
            totalConversation: 0,
        } as IMyResourceStatistic,
        _commonService: CommonService.getInstance(),
    }),
    getters: {},
    actions: {
        async getMyStatistics() {
            try {
                // need implement store to localStorage

                const res = await this._commonService.getMyStatistics();
                this.myStatistics = {
                    totalMessage: res?.result?.totalMessage || 0,
                    totalConversation: res?.result?.totalConversation || 0,
                };
            } catch (error) {
                console.log('🚀 Tictop ~ error:', error);
            }
        },
    },
});
