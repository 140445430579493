export const wheelToZoom = (elementId, setTransform?) => {
    let scale = 1,
        panning = false,
        pointX = 0,
        pointY = 0,
        start = { x: 0, y: 0 };

    const zoom = document.getElementById(elementId);

    if (!zoom) return;

    zoom.onmousedown = function (e) {
        e.preventDefault();
        start = { x: e.clientX - pointX, y: e.clientY - pointY };
        panning = true;
    };

    zoom.onmouseup = function () {
        panning = false;
    };

    zoom.onmousemove = function (e) {
        e.preventDefault();
        if (!panning) {
            return;
        }
        pointX = e.clientX - start.x;
        pointY = e.clientY - start.y;
        setTransform();
    };

    zoom.onwheel = function (e) {
        e.preventDefault();
        const xs = (e.clientX - pointX) / scale,
            ys = (e.clientY - pointY) / scale,
            delta = -e.deltaY;
        delta > 0 ? (scale *= 1.2) : (scale /= 1.2);
        pointX = e.clientX - xs * scale;
        pointY = e.clientY - ys * scale;

        console.log('🚀 Sample:', { pointX, pointY, scale });
        if (setTransform && typeof setTransform == 'function')
            setTransform({
                pointX,
                pointY,
                scale,
            });
    };
};

const IMAGE_MAX_SIZE = 800;
export const getImageSize = (image) => {
    if (!image?.width || !image.height)
        return {
            width: 64,
            height: 64,
        };

    const ratio = image.width / image.height;

    let newWidth = image.width;

    let newHeight = image.height;

    if (newWidth > newHeight) {
        if (newWidth > IMAGE_MAX_SIZE) {
            newWidth = IMAGE_MAX_SIZE;
            newHeight = newWidth / ratio;
        }
    } else if (newHeight > IMAGE_MAX_SIZE) {
        newHeight = IMAGE_MAX_SIZE;
        newWidth = newHeight * ratio;
    }

    return {
        width: newWidth,
        height: newHeight,
    };
};
