import {
    getCurrentOrganizationId,
    getCurrentUserId,
} from '@/ui/hooks/storageHook';
import NotificationRepository from '@/application/repositories/NotificationRepository';
import UserDynamicInfoRepository from '@/application/repositories/user-dynamic-info/UserDynamicInfoRepository';

export default class NotificationService {
    private static instance: NotificationService;

    _notificationRepository: NotificationRepository;

    constructor() {
        this._notificationRepository = NotificationRepository.getInstance();
    }

    public static getInstance(): NotificationService {
        if (!NotificationService.instance) {
            // Get from local storage
            NotificationService.instance = new NotificationService();
        }

        return NotificationService.instance;
    }

    async getList(
        maxResultCount: number,
        skipCount: number,
        category: string,
        actionby: string,
        groups: string,
        domains: string,
        types: any
    ) {
        return await this._notificationRepository.getList(
            maxResultCount,
            skipCount,
            category,
            actionby,
            groups,
            domains,
            types
        );
    }

    async getOrgUserNotifications(orgId, category, pageIndex, pageSize) {
        return await this._notificationRepository.getOrgUserNotifications(
            orgId,
            category,
            pageIndex,
            pageSize
        );
    }

    async getCountUnread() {
        return await this._notificationRepository.getCountUnread();
    }

    async getNotificationUnread() {
        return await this._notificationRepository.getNotificationUnread();
    }

    async getOrgUserNotificationUnread(orgId) {
        return await this._notificationRepository.getOrgUserNotificationUnread(
            orgId
        );
    }

    async reactionOnNotification(notificationId, reaction) {
        return await this._notificationRepository.reactionOnNotification(
            notificationId,
            reaction
        );
    }

    async updateUserNotifications(data) {
        return await this._notificationRepository.updateUserNotifications(data);
    }

    async updateOrgUserNotifications(orgId, data) {
        return await this._notificationRepository.updateOrgUserNotifications(
            orgId,
            data
        );
    }

    async deleteNotification(data: any[]) {
        return await this._notificationRepository.deleteNotification(data);
    }

    async deleteOrgNotification(orgId, data: any[]) {
        return await this._notificationRepository.deleteOrgNotification(
            orgId,
            data
        );
    }

    getNotificationById(id) {
        return this._notificationRepository.getNotificationById(id);
    }

    async createDeviceTokens(data) {
        return await this._notificationRepository.createDeviceTokens(data);
    }

    async deleteDeviceTokens(deviceToken) {
        const userId = getCurrentUserId();
        const organizationId = getCurrentOrganizationId();
        if (!userId || !organizationId) return;

        return await this._notificationRepository.deleteDeviceTokens({
            deviceToken,
            userId,
            organizationId,
        });
    }

    async resetUserTotalNotifications(orgId, userId) {
        return UserDynamicInfoRepository.resetUserTotalNotifications(
            orgId,
            userId
        );
    }

    async sendChatSupportForOrganization() {
        return this._notificationRepository.sendChatSupportForOrganization();
    }
}
