<script setup lang="ts">
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { computed, onBeforeMount, ref } from 'vue';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { tell } from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import taskListStore from '@/store/task/task-list-store';
import TaskService from '@/application/services/task/TaskService';

const props = defineProps<{
  currentTask?: any;
  isUpdateMultiple?: boolean;
  updateMultiplePayload?: any;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'remove', options: any): void;
}>();

const _taskListStore = taskListStore();

const currentSubTaskList = ref<any[]>([]);
const hiddenSubTaskList = ref<any[]>([]);
const isLoading = ref<boolean>(false);

onBeforeMount(() => {
  _processSubTasksList(props.currentTask?.subTasks);
  _checkToGetSubTasks();
});

const onDelete = async () => {
  isLoading.value = true;
  if (props.isUpdateMultiple) {
    await _taskListStore.updateMultipleTasks({
      ...props.updateMultiplePayload,
      isDeleteRepeat: isDeleteRepeat.value,
      isDeleteSubTasks: isDeleteSubTasks.value,
    });
  } else {
    const _currentTask = new TaskDetailClass(props.currentTask);

    const deleteSubTaskIds = currentSubTaskList.value
      ?.filter((task) => task?.isSelected)
      ?.map((task) => task?.id);

    await _currentTask.delete(
      _currentTask.isRepeat && isDeleteRepeat.value ? true : undefined,
      deleteSubTaskIds
    );

    // const deleteList = currentSubTaskList.value?.filter(
    //   (task) => task?.isSelected
    // );
    // if (deleteList && deleteList?.length > 0) {
    //   Promise.all(
    //     deleteList.map((task) => {
    //       return new TaskDetailClass(task).delete();
    //     })
    //   );
    // }
  }

  tell(true);
  isLoading.value = false;
};

const onToggleSelect = (task) => {
  if (!task?.hasPermission) return;
  task.isSelected = !task.isSelected;
};

const titleString = computed<string>(() => {
  if (props.isUpdateMultiple) {
    return translate('TASK_UPDATE_MULTIPLE_DELETE_TITLE', {
      total: props.updateMultiplePayload?.taskIds?.length || '',
    });
  }
  return translate('TASK_DETAIL_DO_YOU_WANT_TO_REMOVE_TASK', {
    taskCode: formatTaskCode(props.currentTask?.code),
  });
});

const isDeleteRepeat = ref<boolean>(false);
const isDeleteSubTasks = ref<boolean>(false);

const onCancel = () => {
  tell(false);
  emit('cancel');
};

const _checkToGetSubTasks = async () => {
  if (
    props.currentTask?.totalSubTask > 0 &&
    !props.currentTask?.subTasks?.length
  ) {
    const res = await TaskService.getInstance().getSubTasks(
      props.currentTask?.id
    );
    _processSubTasksList(res?.result);
  }
};

const _processSubTasksList = (subTasks) => {
  currentSubTaskList.value = subTasks
    ?.filter((t) => t?.isViewable)
    ?.map((t) => {
      return {
        ...t,
        hasPermission: true,
        isSelected: false,
      };
    });

  hiddenSubTaskList.value = subTasks?.filter((t) => !t?.isViewable);
};

const onUnCheckAll = () => {
  currentSubTaskList.value = currentSubTaskList.value.map((t) => {
    return {
      ...t,
      isSelected: false,
    };
  });
};
const onCheckAll = () => {
  currentSubTaskList.value = currentSubTaskList.value.map((t) => {
    return {
      ...t,
      isSelected: true,
    };
  });
};

const currentSelectedSubTaskList = computed<any>(() => {
  return currentSubTaskList.value?.filter((t) => t?.isSelected);
});

const currentSelectAllState = computed<any>(() => {
  if (
    currentSelectedSubTaskList.value.length >= currentSubTaskList.value?.length
  )
    return {
      key: 'SELECT_ALL',
      class: 'bg-current-300',
      icon: {
        name: 'check',
        class: 'text-white',
      },
      onClick: () => {
        onUnCheckAll();
      },
    };
  if (
    currentSelectedSubTaskList.value.length > 0 &&
    currentSelectedSubTaskList.value.length < currentSubTaskList.value?.length
  )
    return {
      key: 'SELECT_ALL',
      class: 'bg-current-300',
      icon: {
        name: 'minus-line',
        class: 'w-2.5 fill-white',
      },
      onClick: () => {
        onCheckAll();
      },
    };
  return {
    key: 'SELECT_NONE',
    class: '',
    icon: {
      name: '',
      class: '',
    },
    onClick: () => {
      onCheckAll();
    },
  };
});
</script>

<template>
  <div class="flex flex-col">
    <div class="flex-center flex-col h-full px-4" style="max-width: 36rem">
      <div class="w-full flex flex-col">
        <div class="py-4 flex space-x-4 items-start">
          <div>
            <SynIcon name="trash" :custom-class="`w-9 h-9 fill-red-500`" />
          </div>
          <div class="flex-1 min-w-0 flex flex-col">
            <div class="py-2">
              <p class="text-gray-600 space-y-2" v-html="titleString"></p>
            </div>
            <div class="flex-1 min-w-0 flex flex-col gap-2 pt-8 pr-8">
              <template v-if="isUpdateMultiple">
                <template
                  v-if="
                    updateMultiplePayload?.hasParentTask ||
                    updateMultiplePayload?.hasRepeatTask
                  "
                >
                  <!-- SUB TASKS -->
                  <div
                    v-if="updateMultiplePayload?.hasParentTask"
                    class="w-full flex items-start cursor-pointer gap-2"
                  >
                    <div
                      class="h-6 w-6 flex-center group shrink-0"
                      @click="isDeleteSubTasks = !isDeleteSubTasks"
                    >
                      <div
                        class="
                          h-4
                          w-4
                          flex-center
                          rounded
                          border
                          ring-current-300
                          cursor-pointer
                          group-hover:ring-1
                          border-current-300
                        "
                        :class="{
                          'bg-current-300': isDeleteSubTasks,
                        }"
                      >
                        <SynIcon
                          v-if="isDeleteSubTasks"
                          name="check"
                          class="text-white"
                        />
                      </div>
                    </div>
                    <div class="flex-center">
                      <p class="w-full text-sm">
                        {{ $t('TASK_LABEL_REMOVE_MULTIPLE_INCLUDE_SUB_TASKS') }}
                      </p>
                    </div>
                  </div>
                  <!-- REPEAT -->
                  <div
                    v-if="updateMultiplePayload?.hasRepeatTask"
                    class="w-full flex items-center cursor-pointer gap-2"
                  >
                    <div
                      class="h-6 w-6 flex-center group shrink-0"
                      @click="isDeleteRepeat = !isDeleteRepeat"
                    >
                      <div
                        class="
                          h-4
                          w-4
                          flex-center
                          rounded
                          border
                          ring-current-300
                          cursor-pointer
                          group-hover:ring-1
                          border-current-300
                        "
                        :class="{
                          'bg-current-300': isDeleteRepeat,
                        }"
                      >
                        <SynIcon
                          v-if="isDeleteRepeat"
                          name="check"
                          class="text-white"
                        />
                      </div>
                    </div>
                    <div class="flex-center">
                      <p class="w-full truncate text-sm">
                        {{
                          $t(
                            'TASK_LABEL_REMOVE_MULTIPLE_INCLUDE_TURN_OFF_REPEAT',
                            {
                              total:
                                updateMultiplePayload?.taskIds?.length || '',
                            }
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </template>
                <div v-else class="">
                  {{ $t('TASK_UPDATE_MULTIPLE_DELETE_CONTENT') }}
                </div>
              </template>
              <template v-else>
                <template
                  v-if="currentSubTaskList?.length > 0 || currentTask?.isRepeat"
                >
                  <!-- SUB TASKS -->
                  <div v-if="currentSubTaskList?.length > 0" class="w-full">
                    <span class="text-gray-600 space-y-2">
                      {{ $t('TASK_DETAIL_CHOOSE_TASK_TO_REMOVE') }}
                    </span>
                    <div class="w-full flex flex-col gap-2 p-2">
                      <div
                        v-if="currentSubTaskList?.length > 1"
                        class="w-full flex items-center gap-2 relative pb-2"
                      >
                        <div
                          class="h-6 w-6 flex-center cursor-pointer group"
                          @click="() => currentSelectAllState?.onClick()"
                        >
                          <div
                            class="
                              h-4
                              w-4
                              flex-center
                              rounded
                              border border-current-300
                              group-hover:ring-1
                            "
                            :class="currentSelectAllState?.class"
                          >
                            <SynIcon
                              v-if="currentSelectAllState?.icon?.name"
                              :name="currentSelectAllState?.icon?.name"
                              :custom-class="currentSelectAllState?.icon?.class"
                            />
                          </div>
                        </div>

                        <span class="text-gray-800 font-semibold">
                          {{
                            $t('GED_NUM_SELECTED', {
                              number: currentSelectedSubTaskList?.length,
                            })
                          }}
                        </span>
                      </div>
                      <div
                        v-for="task in currentSubTaskList"
                        :key="task?.id"
                        v-vig-tooltip="
                          task?.hasPermission
                            ? undefined
                            : $t('TASK_DETAIL_DO_NOT_HAVE_PERMISSION_TO_REMOVE')
                        "
                        class="w-full flex items-center gap-2 relative"
                      >
                        <div
                          class="h-6 w-6 flex-center group"
                          :class="{
                            ' cursor-not-allowed': !task?.hasPermission,
                            'cursor-pointer ': task?.hasPermission,
                          }"
                          @click="onToggleSelect(task)"
                        >
                          <div
                            class="
                              h-4
                              w-4
                              flex-center
                              rounded
                              border
                              ring-current-300
                            "
                            :class="{
                              'bg-current-300':
                                task?.isSelected && task?.hasPermission,
                              'bg-gray-400':
                                task?.isSelected && !task?.hasPermission,
                              'cursor-pointer group-hover:ring-1 border-current-300':
                                task?.hasPermission,
                            }"
                          >
                            <SynIcon
                              v-if="task?.isSelected"
                              name="check"
                              class="text-white"
                            />
                          </div>
                        </div>
                        <p :title="task?.name" class="w-full truncate text-sm">
                          #{{ formatTaskCode(task?.code) }} -
                          {{ task?.name }}
                        </p>

                        <div
                          v-if="task?.isRepeat"
                          class="absolute -right-9 flex-center"
                        >
                          <SynIcon
                            name="recurrence2"
                            custom-class="h-4 w-4 fill-gray-500"
                          />
                          <AtomHelpDropdown
                            title="TASK_LABEL_REMOVE_SUB_TASK_INCLUDE_REPEAT_CONFIRM_EXPLAIN_TITLE"
                            content="TASK_LABEL_REMOVE_SUB_TASK_INCLUDE_REPEAT_CONFIRM_EXPLAIN_CONTENT"
                          />
                        </div>
                      </div>
                      <div
                        v-if="hiddenSubTaskList?.length > 0"
                        class="w-full flex items-center pt-2"
                      >
                        <span
                          class="text-xs text-gray-500 italic"
                          v-html="
                            $t(
                              'SUB_TASK_LABEL_HIDDEN_SUB_TASKS_THAT_YOU_ARE_NOT_ALLOWED_TO_REMOVE',
                              {
                                total: hiddenSubTaskList?.length || 0,
                              }
                            )
                          "
                        >
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- REPEAT -->
                  <div v-if="currentTask?.isRepeat">
                    <span class="text-gray-600 space-y-2">
                      {{ $t('COMMON_LABEL_TASK_RECURRENCE') }}
                    </span>
                    <div class="w-full flex flex-col">
                      <div
                        class="
                          w-full
                          flex
                          items-center
                          cursor-pointer
                          gap-2
                          p-2
                        "
                      >
                        <div
                          class="h-6 w-6 flex-center group"
                          @click="isDeleteRepeat = !isDeleteRepeat"
                        >
                          <div
                            class="
                              h-4
                              w-4
                              flex-center
                              rounded
                              border
                              ring-current-300
                              cursor-pointer
                              group-hover:ring-1
                              border-current-300
                            "
                            :class="{
                              'bg-current-300': isDeleteRepeat,
                            }"
                          >
                            <SynIcon
                              v-if="isDeleteRepeat"
                              name="check"
                              class="text-white"
                            />
                          </div>
                        </div>
                        <div class="flex-center gap-2">
                          <p class="w-full truncate text-sm">
                            {{ $t('TASK_LABEL_DO_NOT_REPEAT') }}
                          </p>
                          <AtomHelpDropdown
                            title="TASK_LABEL_REMOVE_TASK_CONFIRM_EXPLAIN_CANCEL_REPEAT_TITLE"
                            content="TASK_LABEL_REMOVE_TASK_CONFIRM_EXPLAIN_CANCEL_REPEAT_CONTENT"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-else class="font-semibold">
                  {{ currentTask?.name }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        p-4
        flex
        justify-end
        items-center
        hover:text-current
        cursor-pointer
        space-x-2
        vig-modal-footer
        do-not-close-dropdown
      "
    >
      <AtomButton
        class="do-not-close-dropdown"
        type="TEXT"
        color="gray"
        size="lg"
        :label="$t('COMMON_LABEL_CANCEL')"
        @click.stop="onCancel"
      />
      <AtomButton
        class="do-not-close-dropdown"
        color="red"
        size="lg"
        :is-loading="isLoading"
        :label="$t('COMMON_LABEL_REMOVE')"
        @click.stop="onDelete"
      />
    </div>
  </div>
</template>
