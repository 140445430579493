<script setup lang="ts">
import { ref } from 'vue';
import { useElementHover } from '@vueuse/core';
import { emojisFlash } from '@/ui/common/molecules/VigEmojiPicker/emojis-flash.js';
import { getDynamicPosition } from '@/ui/hooks/commonFunction';

withDefaults(
  defineProps<{
    myReaction?: any;
    customClass?: string;
    isInGroup?: boolean;
  }>(),
  {
    customClass: 'bg-white p-1 w-6 h-6',
    isInGroup: true,
  }
);

const buttonReactionTooltipRef = ref();
const isFocusOnTooltip = useElementHover(buttonReactionTooltipRef);

const cardPosition = ref({ x: 0, y: 0 });
const cardTaskSize = {
  width: 240,
  height: 45,
};

const isShowTooltip = ref(false);
let hoverTimer;
const onHoverReactionButton = (event) => {
  hoverTimer && clearTimeout(hoverTimer);
  hoverTimer = setTimeout(() => {
    const targetPosition = event?.target?.getBoundingClientRect();

    if (!targetPosition || !targetPosition.width || !targetPosition.height) {
      return;
    }
    cardPosition.value = getDynamicPosition({
      x: targetPosition.left,
      y: targetPosition.top,
      width: cardTaskSize.width,
      height: cardTaskSize.height,
      padding: 10,
    });

    isShowTooltip.value = true;
  }, 250);
};
let leaveTimer;

const onMouseLeaveReactionButton = () => {
  hoverTimer && clearTimeout(hoverTimer);
  leaveTimer && clearTimeout(leaveTimer);
  leaveTimer = setTimeout(() => {
    if (isFocusOnTooltip.value) return;
    isShowTooltip.value = false;
  }, 500);
};

const isOpenVigEmojiPicker = ref(false);
const onMouseLeaveTooltip = () => {
  setTimeout(() => {
    if (isOpenVigEmojiPicker.value || isFocusOnTooltip.value) return;
    isShowTooltip.value = false;
  }, 250);
};

defineEmits(['onEmojiClick', 'onReactionAdd']);
</script>

<template>
  <button
    v-if="myReaction?.content"
    class="rounded-full flex-center hover:bg-current-100 hover:bg-opacity-100"
    :class="[!isInGroup ? 'border bg-white' : 'bg-opacity-100 ', customClass]"
    @mouseover="onHoverReactionButton"
    @mouseleave="onMouseLeaveReactionButton"
    @click.stop="$emit('onEmojiClick', myReaction?.content)"
  >
    <span
      v-twemoji="{ size: 'w-4 h-4' }"
      class="w-4 h-4 flex-center"
      v-text="myReaction?.content"
    >
    </span>
  </button>

  <button
    v-else
    class="rounded-full flex-center hover:bg-current-100 hover:bg-opacity-100"
    :class="[!isInGroup ? 'border bg-white' : 'bg-opacity-100', customClass]"
    @click.stop="$emit('onEmojiClick', emojisFlash[0]?.emoji)"
    @mouseover="onHoverReactionButton"
    @mouseleave="onMouseLeaveReactionButton"
  >
    <SynIcon name="Heart" custom-class="w-4 h-4 fill-gray-500" />
  </button>

  <teleport to="body">
    <div
      v-if="isShowTooltip"
      ref="buttonReactionTooltipRef"
      v-twemoji="{ size: 'w-6 h-6' }"
      class="
        absolute
        top-0
        left-0
        z-100
        rounded-full
        shadow
        p-1
        bg-white
        flex-center
      "
      :style="[
        `top: ${cardPosition.y}px`,
        `left: ${cardPosition.x}px`,
        'height: 45px',
        'width: 240px',
      ]"
      @mouseleave="onMouseLeaveTooltip"
    >
      <template v-for="emoji in emojisFlash" :key="emoji">
        <button
          :title="emoji.aliases"
          class="p-1 text-md hover:bg-gray-100 rounded-full hover:scale-125"
          type="button"
          @click.prevent="
            (event) => {
              event?.preventDefault();
              $emit('onEmojiClick', emoji?.emoji);
            }
          "
        >
          {{ emoji?.emoji }}
        </button>
      </template>
      <VigEmojiPicker
        toggle-class="w-6 h-6 rounded-full hover:bg-gray-100 flex-center"
        close-on-select
        @on-emoji-click="$emit('onEmojiClick', $event)"
        @on-open="isOpenVigEmojiPicker = true"
        @on-close="
          () => {
            isOpenVigEmojiPicker = false;
            onMouseLeaveTooltip();
          }
        "
      >
        <button
          type="button"
          class="
            p-1
            text-md
            hover:bg-gray-100
            rounded-full
            flex-center
            w-6
            h-6
            text-gray-500
            fill-gray-500
          "
        >
          <syn-icon name="dots-vertical" class="rotate-90"></syn-icon>
        </button>
      </VigEmojiPicker>
    </div>
  </teleport>
</template>

<style>
.emoji-icon img {
  padding: 0;
  margin: 0 !important;
}
</style>
