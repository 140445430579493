<script setup lang="ts">
import { computed, ref } from 'vue';

import settingStore from '@/store/setting';
import appStore from '@/store/app';

const props = defineProps<{
  functionNames: string[];
}>();
const _appStore = appStore();

const organizationSetting = computed(() => settingStore()._organizationSetting);
const imageWel = computed(() => _appStore.welcomeImage);

const isEnable = ref(false);

const initData = () => {
  props.functionNames.forEach((name) => {
    if (organizationSetting.value[name]) isEnable.value = true;
  });
};

initData();
</script>
<template>
  <div
    v-if="!isEnable"
    class="h-full flex-center overflow-hidden"
    :style="{
      'background-image': `linear-gradient(rgba(255,255,255,1), rgba(255,255,255,.75)), url(${imageWel})`,
      'background-size': 'cover',
      'background-position': 'bottom right',
    }"
  >
    <span class="text-2xl text-gray-700">
      You do not have permission to use this function. Please contact your admin
    </span>
  </div>
  <slot v-else></slot>
</template>
