<script setup lang="ts">
defineProps({
  text: {
    type: String,
    default: '',
  },
  customActiveClass: {
    type: String,
    default: 'bg-current-400 text-white',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['update:modelValue']);
const key = `syn-checkbox-tag-${Math.random() * 100}`;
</script>

<template>
  <div class="flex">
    <input
      :id="key"
      type="checkbox"
      :disabled="disabled"
      :checked="modelValue"
      class="hidden"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label
      class="cursor-pointer rounded-full"
      :for="key"
      :class="modelValue ? customActiveClass : ''"
    >
      <slot>
        <div
          :class="
            modelValue
              ? 'border border-current-400'
              : 'border border-current-100'
          "
          class="
            relative
            whitespace-nowrap
            cursor-pointer
            py-1
            px-4
            rounded-full
            text-sm
          "
          rounded-full
        >
          <div class="flex items-center space-x-1">
            <!-- <SynIcon name="check" v-if="modelValue" class="w-4" /> -->
            <span>{{ text }}</span>
          </div>
        </div>
      </slot>
    </label>
  </div>
</template>
