<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted, createApp, h } from 'vue';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayjs from 'dayjs';
import interactionPlugin from '@fullcalendar/interaction';
import {
  ETaskListModule,
  EActionOnTask,
} from '@/application/types/task/task.types';
import { TaskCalendarViewClass } from '@/domain/entities/full-calendar';
import {
  renderTaskEventComponent,
  renderPlanedTaskComponent,
} from './rendering-task-event-component';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { isOpenDrawer } from '@/ui/pages/tasks/calendar/unschedule-tasks-drawer-state';
import viLocale from '@fullcalendar/core/locales/vi';
import frLocale from '@fullcalendar/core/locales/fr';
import appStore from '@/store/app';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import TaskActions from '@/ui/pages/tasks/calendar/components/TaskActions.vue';
import ReminderCreateModal from '@/ui/modules/calendar/reminder/ReminderCreateModal.vue';
import getUserInfo from '@/ui/helpers/user-helper';
import chatStore from '@/store/chat';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';

const FULL_CALENDAR_VIEW_MODE = {
  DAY_GRID_MONTH: 'dayGridMonth',
  DAY_GRID_WEEK: 'dayGridWeek',
  DAY_GRID_DAY: 'timeGridDay',
  LIST_WEEK: 'listWeek',
};

const props = withDefaults(
  defineProps<{
    // ownerId: number | string;
    sourceType: ETaskListModule;
    events: any[];
    isMultiple?: boolean;
    isDisableInit?: boolean;
    timeZone?: string;
    externalId?: string | number;
    isHiddenTimeColumn?: boolean;
    isShowTimeOnly?: boolean;
  }>(),
  {
    timeZone: 'Asia/Ho_Chi_Minh',
  }
);
const emit = defineEmits<{
  (e: 'ready'): void;
  (e: 'onClickAddButton', dateKey: any): void;
  (e: 'changeStartEndTime', event: any): void;
  (e: 'eventClick', event: any, jsEvent: any): void;
}>();

const isLoadingCalendar = defineModel('isLoading');
const currentViewMode = defineModel('currentViewMode', {
  default: 'timeGridDay',
});
const viewTitle = defineModel('viewTitle');

// const DATE_FORMAT = 'YYYY-MM-DD';

const calendarInstance = ref<any>(null);

let intervalIds: any[] = [];
let timeoutIds: any[] = [];

const startCountdown = (taskId, endTime) => {
  const _updateDom = () => {
    const remainTimeElm = document.getElementById(
      `countdown-id_remain-time_${taskId}`
    );
    if (remainTimeElm)
      remainTimeElm.textContent = dayjs
        .duration(dayjs(endTime).diff(dayjs()))
        .format('HH:mm:ss');
  };

  _updateDom();

  const intervalId = setInterval(() => {
    if (dayjs().isAfter(endTime)) {
      clearInterval(intervalId);
      return;
    }

    _updateDom();
  }, 1000);
  intervalIds.push(intervalId);
};
const handleCountdown = () => {
  clearAllIntervalAndTimeout();

  props.events.forEach((task) => {
    const countdownContainerElm = document.getElementById(
      `countdown-container-id_${task?.id}`
    );
    if (!task?.planningStartTime || !task?.planningEndTime) {
      if (countdownContainerElm) countdownContainerElm.classList.add('hidden');

      return;
    }

    const startTime = dayjs.utc(task?.planningStartTime).format();
    const endTime = dayjs.utc(task?.planningEndTime).format();

    if (
      !startTime ||
      !endTime ||
      (endTime && dayjs().isAfter(dayjs(endTime)))
    ) {
      if (countdownContainerElm) countdownContainerElm.classList.add('hidden');

      return;
    }

    if (startTime && dayjs().isBefore(dayjs(startTime))) {
      if (countdownContainerElm) countdownContainerElm.classList.add('hidden');

      const timeoutId = setTimeout(() => {
        if (countdownContainerElm)
          countdownContainerElm.classList.remove('hidden');

        startCountdown(task?.id, endTime);
      }, dayjs(startTime).diff(Date.now()));

      timeoutIds.push(timeoutId);

      return;
    }

    if (countdownContainerElm) countdownContainerElm.classList.remove('hidden');

    startCountdown(task?.id, endTime);
  });
};

const currentUpdatingTask = ref<any>({});
const isOpenReminderModal = ref();
const isEditReminder = ref(false);
const onReminderTask = async (isEdit) => {
  isOpenReminderModal.value = true;
  isEditReminder.value = isEdit;
};
const onSelectActionOnTask = (actionKey, currentTask) => {
  currentUpdatingTask.value = currentTask;
  switch (actionKey) {
    case EActionOnTask.DISCUSS:
      // chatAboutTaskRef.value?.onOpenChatAboutTaskModal();
      onChatAboutTaskByUserId();
      break;

    case EActionOnTask.REMINDER_TASK:
      onReminderTask(!!currentTask?.taskReminderInfo);

      break;

    default:
      break;
  }
};

// *** PRIVATE FUNCTIONS ***
const { goToConversation } = useChatCommon();
const _onOpenConversation = (conversation) => {
  goToConversation(conversation);

  if (currentUpdatingTask.value?.id) {
    setTimeout(() => chatStore().setChatAboutTask(currentUpdatingTask.value));
  }
};

const onChatAboutTaskByUserId = () => {
  const userId = props.externalId;
  if (!userId) return;
  const conversationId = chatStore().checkExistedConversation(userId);

  const userInfo = getUserInfo(userId);
  if (!userInfo?.name) return;

  _onOpenConversation({
    id: conversationId,
    name: userInfo.name,
    avatar: userInfo.avatarUrl || userInfo.avatar,
    contactId: userId,
  });
};

const addListenClickEvent = (task) => {
  const taskEventContainerElm = document.getElementById(
    `planed_task_container_id_${task?.id}`
  );
  if (taskEventContainerElm) {
    taskEventContainerElm.addEventListener('click', (event) => {
      emit('eventClick', task, event);
    });
  }
};
const addMoreActionTaskElement = (task) => {
  const moreActionElm = document.getElementById(`more_action_id_${task?.id}`);

  if (moreActionElm) {
    const currentTask = new TaskDetailClass(task);

    const appItem = createApp({
      render() {
        return h(TaskActions, {
          currentTask: task,
          actionList: [EActionOnTask.DISCUSS, EActionOnTask.REMINDER_TASK],
          onSelectAction: (key) => {
            onSelectActionOnTask(key, currentTask);
          },
        });
      },
    });
    appItem.mount(moreActionElm);
  }
};
const removeAllEventTabIndex = () => {
  const allEventByClass = document.getElementsByClassName('fc-timegrid-event');
  for (const element of allEventByClass) {
    element.removeAttribute('tabindex');
  }
};

const clearAllIntervalAndTimeout = () => {
  intervalIds.forEach((intervalId) => clearInterval(intervalId));
  intervalIds = []; // Clear the array after clearing intervals

  timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
  timeoutIds = []; // Clear the array after clearing intervals
};

onUnmounted(() => {
  clearAllIntervalAndTimeout();
});

const _prepareEventData = (data) => {
  let startTime = data?.startTime ? data?.startTime : data?.scheduleTime;
  let endTime = data?.scheduleTime;

  if (isTimeBlockingMode.value) {
    startTime = data?.planningStartTime;
    endTime = data?.planningEndTime;
  }

  return new TaskCalendarViewClass({
    ...data,
    title: data?.name,
    start: startTime ? dayjs.utc(startTime).format() : dayjs.utc().format(),
    end: endTime ? dayjs.utc(endTime).format() : dayjs.utc().format(),
    extendedProps: data,
    editable: true,
    eventResizableFromStart: true,
    allDay: !startTime || !endTime,
  });
};
const updateEventSources = () => {
  if (!calendarInstance.value || props.isShowTimeOnly) return;

  const eventSources = calendarInstance.value.getEventSources();
  console.log('🚀 Tictop ~ eventSources:', eventSources);
  if (eventSources?.length > 0)
    eventSources.forEach((eventSource) => {
      eventSource.remove();
    });

  calendarInstance.value.addEventSource(
    props.events
      ?.map((t) => {
        return {
          ...t,
          urgency: getUrgencyValue(t?.priority, t?.important),
        };
      })
      .map((data) => {
        return _prepareEventData(data);
      })
  );

  handleCountdown();
  if (props.isMultiple) {
    props.events.forEach((task) => {
      if (!task?.id) return;
      addListenClickEvent(task);
      addMoreActionTaskElement(task);
    });

    removeAllEventTabIndex();
  }
};
const addOrUpdateEventByTask = (task) => {
  if (!calendarInstance.value) return;

  const calEvents = calendarInstance.value.getEvents();
  // remove old event
  if (calEvents?.length > 0)
    calEvents.forEach((calEvent) => {
      if (calEvent?.extendedProps?.id == task?.id) {
        calEvent.remove();
      }
    });

  calendarInstance.value.addEvent(_prepareEventData(task), true);
  if (props.isMultiple) {
    props.events.forEach((task) => {
      if (!task?.id) return;
      addListenClickEvent(task);
      addMoreActionTaskElement(task);
    });

    removeAllEventTabIndex();
  }

  //handle count down
  handleCountdown();
};
const removeEventByTask = (extendedPropId) => {
  if (!calendarInstance.value) return;

  const calEvents = calendarInstance.value.getEvents();
  // remove old event
  if (calEvents?.length > 0)
    calEvents.forEach((calEvent) => {
      if (calEvent?.extendedProps?.id == extendedPropId) calEvent.remove();
    });
};

const embedAddButton = (startDate, endDate) => {
  let currentDate = startDate;

  while (dayjs(endDate).diff(dayjs(currentDate), 'd') >= 0) {
    const dateKey = dayjs(currentDate).format('YYYY-MM-DD');
    currentDate = dayjs(currentDate).add(1, 'day');
    const element = document.querySelector(`[data-date="${dateKey}"]`);

    if (element) {
      element.classList.add('date-td');
      const button = document.createElement('button');
      button.innerHTML = '<span>&#43;</span>';
      button.classList.add('my-custom-button');

      // Append the button to the event element
      element.appendChild(button);

      // Add click event listener to the button (optional)
      button.addEventListener('click', function () {
        emit('onClickAddButton', dateKey);
      });
    }
  }
};

const calendarId = `task-calendar-view-id_${
  props.externalId || ''
}_${Date.now()}`;

const initCalendar = () => {
  let calendarEl = document.getElementById(calendarId);
  if (!calendarEl) return;

  calendarInstance.value = new Calendar(calendarEl, {
    locale:
      appStore().language == 'vi'
        ? viLocale
        : appStore().language == 'fr'
        ? frLocale
        : 'en',
    // Set the timezone option (this affects event times)
    timeZone: props.timeZone, // Set your desired timezone here

    // Manually set the "now" option to match the timezone
    now: function () {
      return dayjs().tz(props.timeZone).format(); // Get the current time in the specified timezone
    },
    plugins: [
      timeGridPlugin,
      dayGridPlugin,
      interactionPlugin,
      momentTimezonePlugin,
    ],
    initialView: FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY,
    editable: true,
    droppable: true,
    // resources: [
    // ],
    slotDuration: '00:30:00',
    slotLabelInterval: { hours: props.isMultiple ? 0.5 : 1 },
    // scrollTime: '06:30:00',
    events: [],
    dayMaxEventRows: 10,
    fixedWeekCount: false,
    firstDay: 1,
    eventResizableFromStart: true,
    nowIndicator: true,
    height: props.isMultiple ? 'auto' : undefined,
    // dropAccept: '.task-calendar-view-item_accept-drop',
    // eventAllow: function (dropInfo, draggedEvent) {
    //   if (
    //     !draggedEvent?.extendedProps ||
    //     !draggedEvent?.extendedProps ||
    //     !draggedEvent?.extendedProps?.id
    //   )
    //     return false;

    //   return new TaskDetailClass(
    //     draggedEvent?.extendedProps
    //   ).canTaskChangeDeadline();
    // },
    eventOrder: function (eventA: any, eventB: any) {
      // Custom logic to sort events
      // Here, we're ordering by event title, you can adjust to any field like id, etc.

      if (eventA.allDay && eventB.allDay) {
        // Example: order by title alphabetically
        return eventB.urgency - eventA.urgency;
      } else if (eventA.allDay && !eventB.allDay) {
        // Full-day events come before timed events
        return -1;
      } else if (!eventA.allDay && eventB.allDay) {
        // Timed events come after full-day events
        return 1;
      }

      // Otherwise, sort by start time
      return eventA.start - eventB.start;
    },
    eventContent: function (arg) {
      const extendedProps = arg.event.extendedProps;

      const itemEvent = props.isMultiple
        ? renderPlanedTaskComponent(
            extendedProps as TaskCalendarViewClass,
            props.sourceType,
            {
              isTimeBlocking: isTimeBlockingMode.value,
              allDay: arg.event?.allDay,
              timeZone: props.timeZone,
              onClick: () => {
                emit('eventClick', extendedProps, null);
              },
            }
          )
        : renderTaskEventComponent(
            extendedProps as TaskCalendarViewClass,
            props.sourceType,
            {
              isTimeBlocking: isTimeBlockingMode.value,
              allDay: arg.event?.allDay,
              timeZone: props.timeZone,
            }
          );

      itemEvent.style.width = '100%';
      // itemEvent.style.marginBottom = '0.25rem';

      return { domNodes: [itemEvent] };
    },
    eventClick: function (info) {
      emit('eventClick', info.event.extendedProps, info?.jsEvent);
    },
    eventDrop: function (info) {
      const draggedEvent = info?.event;
      const canChange = new TaskDetailClass(
        draggedEvent?.extendedProps
      ).canTaskChangeDeadline();

      if (!canChange) {
        updateEventSources();
        return;
      }

      const allEvents = calendarInstance.value.getEvents();
      const currentEventList = allEvents?.filter(
        (event) => event?.id == draggedEvent?.id
      );

      if (
        currentEventList?.length > 1 &&
        draggedEvent?.remove &&
        typeof draggedEvent?.remove == 'function'
      )
        draggedEvent?.remove();

      emit('changeStartEndTime', draggedEvent);
    },
    eventResize: function (info) {
      const event = info?.event;
      const canChange = new TaskDetailClass(
        event?.extendedProps
      ).canTaskChangeDeadline();
      if (!canChange) {
        if (info?.revert) info?.revert();
        return;
      }
      emit('changeStartEndTime', info?.event);
    },
    eventReceive: async function (info) {
      const event = info?.event;
      const canChange = new TaskDetailClass(
        event?.extendedProps
      ).canTaskChangeDeadline();

      if (!canChange) {
        if (info?.revert) info?.revert();
        return;
      }

      await emit('changeStartEndTime', event);

      if (event.remove && typeof event.remove == 'function') event.remove();

      isOpenDrawer.value = true;
    },
    eventDragStart: function () {
      isOpenDrawer.value = false;
    },
    dateClick: function (info) {
      console.log('🚀 Tictop ~ info:', info);
      // change to day mode
    },
    datesSet: function (dateInfo) {
      embedAddButton(dateInfo?.start, dateInfo?.end);
    },
    eventDragStop: function () {},
    slotLabelFormat: (data) => {
      const hourString = `0${data?.date?.hour}`.slice(-2);
      const minuteString = `0${data?.date?.minute}`.slice(-2);
      return `${hourString}:${minuteString}`;
    },
  });
  calendarInstance.value.render();
  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;

  isLoadingCalendar.value = false;

  updateEventSources();

  emit('ready');
};

const isTimeBlockingMode = computed<boolean>(() => {
  return currentViewMode.value == FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY;
});

const onChangeViewMode = async (viewMode) => {
  if (!calendarInstance.value) return;
  if (viewMode == FULL_CALENDAR_VIEW_MODE.DAY_GRID_DAY) {
    calendarInstance.value.setOption('dayMaxEventRows', 10);
  } else {
    calendarInstance.value.setOption(
      'dayMaxEventRows',
      viewMode == FULL_CALENDAR_VIEW_MODE.DAY_GRID_MONTH ? 3 : false
    );
  }

  const today = dayjs().toDate();
  calendarInstance.value.changeView(viewMode, today);

  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
};

const onNext = () => {
  calendarInstance.value.next();
  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
};

const onPrevious = () => {
  calendarInstance.value.prev();
  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
};

const gotoToday = () => {
  calendarInstance.value.today();
  viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
};
const gotoFirstData = () => {
  if (props.events?.length > 0) {
    const firstEvent = props.events[0];
    if (firstEvent?.scheduleTime) {
      calendarInstance.value.gotoDate(firstEvent?.scheduleTime);
      viewTitle.value = calendarInstance.value?.currentData?.viewTitle;
    }
  }
};

const toggleShowWeekends = (value) => {
  calendarInstance.value.setOption('hiddenDays', value ? [] : [0]);
};

onMounted(() => {
  if (!props.isDisableInit) initCalendar();
});
defineExpose({
  onPrevious,
  onNext,
  gotoToday,
  gotoFirstData,
  toggleShowWeekends,
  onChangeViewMode,
  updateEventSources,
  addOrUpdateEventByTask,
  initCalendar,
  removeEventByTask,
});
</script>

<template>
  <div
    :id="calendarId"
    class="task-time-blocking-single w-full relative"
    :class="[
      isMultiple
        ? 'overflow-hidden h-auto is-multiple'
        : 'h-full overflow-auto small-scrollbar',
      isHiddenTimeColumn ? 'is-hidden-time-column' : '',
      isShowTimeOnly ? 'is-show-time-only' : '',
    ]"
  ></div>

  <ReminderCreateModal
    v-if="isOpenReminderModal"
    :type="'TASK'"
    :is-edit="isEditReminder"
    :task-detail="currentUpdatingTask"
    @cancel="
      isOpenReminderModal = false;
      isEditReminder = false;
    "
    @set-reminder-task-success="isOpenReminderModal = false"
  />
</template>
<style>
.task-time-blocking-single {
  .fc-timegrid-slot {
    background: #fff;
    height: 1.75rem;
  }
  .fc-timegrid-axis,
  .fc-daygrid-day-frame {
    background-color: #fff !important;
  }

  td,
  th {
    border: 2px solid #f8fafc;
    border-bottom: 0;
  }
  .fc-timegrid-axis-cushion,
  .fc-timegrid-slot-label-cushion {
    /* color: #9ca3af; */
  }
}
.task-time-blocking-single.fc-theme-standard .fc-scrollgrid {
  border-top: 0 !important;
}

.task-time-blocking-single.is-multiple {
  .fc-daygrid-day-events {
    height: 8rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .fc-col-header {
    display: none;
  }

  .fc-timegrid-slot {
    background: #fff;
    height: 3.6rem;
    position: sticky;
    left: 0;
  }
}
.task-time-blocking-single.is-hidden-time-column {
  .fc-timegrid-axis,
  .fc-timegrid-slot-label {
    display: none;
  }
}
.task-time-blocking-single.is-show-time-only {
  .fc-timegrid-slot-lane {
    display: none;
  }
}
</style>
