import BaseModel from './BaseModel';
import {
    collection,
    doc,
    DocumentData,
    DocumentReference,
    onSnapshot,
    serverTimestamp,
    setDoc,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export default class ConversationDocModel extends BaseModel {
    docRef: DocumentReference<DocumentData>;

    constructor(conversationId: string) {
        super();

        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        this.docRef = doc(
            collection(
                doc(
                    collection(firestoreDb, 'organizationConversations'),
                    `${organizationId}`
                ),
                'conversations'
            ),
            `${conversationId}`
        );
    }

    getDetail() {
        return this.getDoc(this.docRef);
    }

    setMemberIsTyping(userId, isTyping) {
        const typingMemberCollection = collection(this.docRef, 'typingMembers');
        this.setDoc(
            doc(typingMemberCollection, `${userId}`),
            {
                isTyping: isTyping || false,
                updatedDate: this.serverTimestamp(),
            },
            { merge: false }
        );
    }

    subscibleTypingMembersChanged(callback) {
        const typingMemberCollection = collection(this.docRef, 'typingMembers');

        return onSnapshot(typingMemberCollection, (snapshot) => {
            callback &&
                callback(
                    snapshot.docs?.map((doc) => {
                        return {
                            userId: doc?.id,
                            ...doc?.data(),
                        };
                    })
                );
        });
    }

    removeMessage(messageId, userId) {
        const messageDoc = doc(
            collection(this.docRef, 'deletedMessages'),
            `${messageId}`
        );

        const updateData = {
            removedBy: `${userId}`,
            removedDate: this.serverTimestamp(),
        };

        this.setDoc(messageDoc, updateData, { merge: false });

        return {
            ...updateData,
            removed: true,
        };
    }

    updateSeenMessage(userId, messageId) {
        return setDoc(
            doc(collection(this.docRef, 'seenByMembers'), `${userId}`),
            {
                lastSeenMessageId: messageId,
                lastSeenMessageTime: serverTimestamp(),
            },
            {
                merge: true,
            }
        );
    }
}
