<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g clip-rule="evenodd">
        <path
          d="m8.03033 3.21967c.29289.29289.29289.76777 0 1.06066l-3.96967 3.96967 3.96967 3.9697c.29289.2929.29289.7677 0 1.0606s-.76777.2929-1.06066 0l-4.5-4.49997c-.29289-.29289-.29289-.76777 0-1.06066l4.5-4.5c.29289-.29289.76777-.29289 1.06066 0z"
        ></path>
        <path
          d="m2.25 8.25c0-.41421.33579-.75.75-.75h12.75c1.5913 0 3.1174.63214 4.2426 1.75736 1.1253 1.12524 1.7574 2.65134 1.7574 4.24264s-.6321 3.1174-1.7574 4.2426c-1.1252 1.1253-2.6513 1.7574-4.2426 1.7574h-8.25c-.41421 0-.75-.3358-.75-.75s.33579-.75.75-.75h8.25c1.1935 0 2.3381-.4741 3.182-1.318s1.318-1.9885 1.318-3.182-.4741-2.3381-1.318-3.182c-.8439-.84389-1.9885-1.318-3.182-1.318h-12.75c-.41421 0-.75-.33579-.75-.75z"
        ></path>
      </g>
    </g>
  </svg>
</template>
