<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g>
        <g>
          <path
            d="M407,301c-8.276,0-15,6.724-15,15s6.724,15,15,15s15-6.724,15-15S415.276,301,407,301z"
            class=""
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M497,91H151.245v45c0,8.291-6.709,15-15,15s-15-6.709-15-15V91H15c-8.291,0-15,6.709-15,15v90c0,8.291,6.709,15,15,15
          c24.814,0,45,20.186,45,45c0,24.814-20.186,45-45,45c-8.291,0-15,6.709-15,15v90c0,8.291,6.709,15,15,15h105v-45
          c0-8.291,6.709-15,15-15s15,6.709,15,15v45h347c8.291,0,15-6.709,15-15V106C512,97.709,505.291,91,497,91z M150,316
          c0,8.291-6.709,15-15,15s-15-6.709-15-15v-30c0-8.291,6.709-15,15-15s15,6.709,15,15V316z M150,226c0,8.291-6.709,15-15,15
          s-15-6.709-15-15v-30c0-8.291,6.709-15,15-15s15,6.709,15,15V226z M242,196c0-24.814,20.186-45,45-45c24.814,0,45,20.186,45,45
          c0,24.814-20.186,45-45,45C262.186,241,242,220.814,242,196z M278.68,358.48c-6.899-4.6-8.76-13.901-4.16-20.801l120-180
          c4.585-6.899,13.887-8.745,20.801-4.16c6.899,4.6,8.76,13.901,4.16,20.801l-120,180
          C294.956,361.117,285.689,363.126,278.68,358.48z M407,361c-24.814,0-45-20.186-45-45c0-24.814,20.186-45,45-45
          c24.814,0,45,20.186,45,45C452,340.814,431.814,361,407,361z"
            class=""
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M287,181c-8.276,0-15,6.724-15,15s6.724,15,15,15s15-6.724,15-15S295.276,181,287,181z"
            class=""
          ></path>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
