<script setup lang="ts">
import { computed } from 'vue';
import userStore from '@/store/user';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';

const props = withDefaults(
  defineProps<{
    userId: string | number;
    isHiddenName?: boolean;
    avatarClass?: string;
    shape?: string;
    placeholder?: string;
    isShowFirstName?: boolean;
    isShowDepartment?: boolean;
  }>(),
  {
    avatarClass: 'w-6 h-6',
    shape: '',
    placeholder: '',
    isShowDepartment: false,
  }
);

const currentUserInfo = computed(() => userStore().allUserByIds[props.userId]);
const currentUserDepartmentId = computed(() => {
  if (
    !currentUserInfo.value ||
    !currentUserInfo.value?.departmentIds ||
    currentUserInfo.value?.departmentIds?.length == 0
  )
    return null;
  return currentUserInfo.value?.departmentIds[0];
});
</script>

<template>
  <DepartmentById
    :department-id="currentUserDepartmentId"
    is-hidden-avatar
    class="text-xs text-gray-500"
  />
</template>
