<script setup lang="ts">
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import WorkflowStepNextSteps from '@/ui/modules/workflow/workflow-step/WorkflowStepNextSteps.vue';
import taskDetailWorkflowComposables from '@/ui/modules/task/workflow/task-detail-workflow.composables';

const props = defineProps<{
  modelValue: string;
  taskDetail: ITaskDetailClass;
}>();

defineEmits<{
  (e: 'update:modelValue', id: string): void;
}>();

// const _workflowStore = workflowStore();

// const allStepTypeByIds = computed<any>(() => {
//   return _workflowStore.allStepTypeByIds;
// });
const { stepByIds, currentTaskStep } = taskDetailWorkflowComposables(
  props.taskDetail
);
</script>
<template>
  <div class="flex flex-col rounded">
    <span class="italic cursor-pointer text-sm text-gray-500 inline space-x-1">
      {{ $t('WORKFLOW_LABEL_CHOOSE_NEXT_STEP') }}
    </span>

    <WorkflowStepNextSteps
      :model-value="modelValue"
      :next-steps="currentTaskStep?.nextSteps"
      :step-by-ids="stepByIds"
      view-mode="SELECT"
      @update:model-value="(value) => $emit('update:modelValue', value)"
    />
  </div>
</template>
