import { defineStore } from 'pinia';
import { reject } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import storeId from '@/base/store/storeId';

type DriveDocumentPageState = {
    pageIsVisible: boolean;
    pageTabs: any[];
    pageActiveTab: any;
};

const defaultNewTab = {
    id: uuidv4(),
    name: 'GED_GOOGLE_NEW_TAB',
    isNewTab: true,
};

export default defineStore({
    id: storeId.DRIVE_DOCUMENT_PAGE,
    state: () =>
        ({
            pageIsVisible: false,
            pageTabs: [defaultNewTab],
            pageActiveTab: defaultNewTab,
        } as DriveDocumentPageState),
    getters: {},
    actions: {
        addNewTab() {
            const newTab = {
                id: uuidv4(),
                name: 'GED_GOOGLE_NEW_TAB',
                isNewTab: true,
            };

            this.pageTabs.push(newTab);

            return newTab;
        },
        removePageTab(tab) {
            // Prevent remove last empty tab
            if (this.pageTabs?.length === 1) {
                // Replace last active tab by new tab
                if (!tab?.isNewTab) {
                    this.pageTabs[0] = {
                        id: uuidv4(),
                        name: 'GED_GOOGLE_NEW_TAB',
                        isNewTab: true,
                    };
                }
                return;
            }

            // Remove tab normally
            this.pageTabs = reject(this.pageTabs, { id: tab?.id });
        },
        openDocument(document) {
            if (!document || !this.pageActiveTab) return;

            this.pageActiveTab.id = uuidv4();
            this.pageActiveTab.docId = document.id;
            this.pageActiveTab.name = document.name;
            this.pageActiveTab.link = document.link;
            this.pageActiveTab.contentType = document.contentType;
            this.pageActiveTab.isNewTab = false;
        },
        setPageActiveTab(tab) {
            this.pageActiveTab = tab;
        },
        setPageIsVisible(isVisible) {
            this.pageIsVisible = isVisible;
        },
    },
});
