import BaseRepository from '@/base/repositories/BaseRepository';

class WorkPerformanceRepository {
    constructor() {}

    getStatisticByStatus(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/StatisticByStatus`,
            method: 'post',
            data,
        });
    }

    getWorkloadStatistic(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/WorkloadStatistic`,
            method: 'post',
            data,
        });
    }

    // Day = 1,
    // Week = 2,
    // Month = 3,
    // Year = 4,
    getWorkPerformance(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        viewType: 1 | 2 | 3 | 4;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/WorkPerformance`,
            method: 'post',
            data,
        });
    }
    getDomainTaskStatistic(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        size: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/DomainTaskStatistic`,
            method: 'post',
            data,
        });
    }
    getDomainOverdueTaskStatistic(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        size: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/DomainOverdueTaskStatistic`,
            method: 'post',
            data,
        });
    }
    getUserMostValuable(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        size: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/UserMostValuable`,
            method: 'post',
            data,
        });
    }
    getUserBestPerformance(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        size: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/UserBestPerformance`,
            method: 'post',
            data,
        });
    }
    getUserMostOverdueTask(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        size: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/UserMostOverdueTask`,
            method: 'post',
            data,
        });
    }
    getUserMostActiveTask(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        size: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/UserMostActiveTask`,
            method: 'post',
            data,
        });
    }
    getUserMostCreateTask(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        size: number;
        orderBy: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/UserMostCreateTask`,
            method: 'post',
            data,
        });
    }
    getUserMostAssignedTask(data: {
        dateFrom: string;
        dateTo: string;
        departmentIds: number[] | undefined;
        size: number;
        orderBy: number;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `WorkPerformance/UserMostAssignedTask`,
            method: 'post',
            data,
        });
    }
}

export default new WorkPerformanceRepository();
