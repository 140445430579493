<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import UserById from '@/ui/components/user/UserById.vue';

const props = defineProps<{
  activity: any;
}>();

const beforeUpdate = computed<any>(() => {
  return covertSnakeCaseToCamelCase(props.activity?.beforeUpdate);
});
const afterUpdate = computed<any>(() => {
  return covertSnakeCaseToCamelCase(props.activity?.afterUpdate);
});

// const isEnableCase = computed<any>(() => {
//   return (
//     !beforeUpdate.value?.approvalEnabled && afterUpdate.value?.approvalEnabled
//   );
// });
// const isChangeCase = computed<any>(() => {
//   return (
//     beforeUpdate.value?.approvalEnabled && afterUpdate.value?.approvalEnabled
//   );
// });
// const isCancelCase = computed<any>(() => {
//   return !afterUpdate.value?.approvalEnabled;
// });

onMounted(() => {
  console.log('🚀 Tictop ~ props:', props.activity);
});
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 items-center justify-between pr-2 text-xs">
      <!-- <SynListAvatar
        v-if="beforeUpdate?.approvers?.length > 0"
        :users="
          beforeUpdate?.approvers?.map((o) => covertSnakeCaseToCamelCase(o))
        "
        custom-class="w-6 h-6"
      /> -->
      <UserById
        v-if="beforeUpdate?.approvers?.length > 0"
        :user-id="beforeUpdate?.approvers[0]?.UserId"
        avatar-class="w-5 h-5"
        class="font-medium"
      />

      <span v-else class="text-xs text-gray-500">
        {{ $t('COMMOM_LABEL_NONE') }}
      </span>

      <span>→</span>
    </div>

    <div class="flex w-1/2 justify-start pl-2 text-xs">
      <!-- <SynListAvatar
        v-if="afterUpdate?.approvers?.length > 0"
        :users="
          afterUpdate?.approvers?.map((o) => covertSnakeCaseToCamelCase(o))
        "
        custom-class="w-6 h-6"
      /> -->
      <UserById
        v-if="afterUpdate?.approvers?.length > 0"
        :user-id="afterUpdate?.approvers[0]?.UserId"
        avatar-class="w-5 h-5"
        class="font-medium"
      />

      <span v-else class="text-xs text-gray-500">
        {{ $t('COMMOM_LABEL_NONE') }}
      </span>
    </div>
  </div>
</template>
