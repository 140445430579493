import { ENotificationTaskActionType } from '../types/notification.types';

export enum ENotificationViewType {
    DIFF = 'DIFF',
    COMMENT = 'COMMENT',
    NEW_VALUE = 'NEW_VALUE',
    HIDDEN = 'HIDDEN',
}
export const NOTIFICATION_TASK_UI = {
    [ENotificationTaskActionType.Finished]: {
        icon: {
            name: 'NotificationTaskActionFinished',
            bgColor: '#22c55e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_FINISHED',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.Edit]: {
        icon: {
            name: 'NotificationTaskActionEdit',
            bgColor: '#f97316',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_EDIT',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.AddNew]: {
        icon: {
            name: 'NotificationTaskActionAddNew',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_ADD_NEW',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.Urgent]: {
        icon: {
            name: 'NotificationTaskActionUrgent',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_URGENT',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.Delay]: {
        icon: {
            name: 'NotificationTaskActionDelay',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_DELAY',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.Planned]: {
        icon: {
            name: 'NotificationTaskActionPlanned',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_PLANNED',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangePriority]: {
        icon: {
            name: 'NotificationTaskActionChangePriority',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_PRIORITY',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeAssignee]: {
        icon: {
            name: 'NotificationTaskActionChangeAssignee',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_ASSIGNEE',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.Reopen]: {
        icon: {
            name: 'NotificationTaskActionReopen',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_REOPEN',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.Delete]: {
        icon: {
            name: 'NotificationTaskActionDelete',
            bgColor: '#ef4444',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_DELETE',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeName]: {
        icon: {
            name: 'NotificationTaskActionChangeName',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_NAME',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.ChangeDescription]: {
        icon: {
            name: 'NotificationTaskActionChangeDescription',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_DESCRIPTION',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.MentionDescription]: {
        icon: {
            name: 'NotificationTaskActionChangeDescription',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_MENTION_DESCRIPTION',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.ChangeStatus]: {
        icon: {
            name: 'NotificationTaskActionChangeStatus',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_STATUS',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeDeadline]: {
        icon: {
            name: 'NotificationTaskActionChangeDeadline',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_DEADLINE',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeTodo]: {
        icon: {
            name: 'NotificationTaskActionChangeTodo',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_TODO',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeFinish]: {
        icon: {
            name: 'NotificationTaskActionChangeFinish',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_FINISH',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeAttachments]: {
        icon: {
            name: 'NotificationTaskActionChangeAttachments',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_ATTACHMENTS',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangePrivate]: {
        icon: {
            name: 'NotificationTaskActionChangePrivate',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_PRIVATE',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeDomain]: {
        icon: {
            name: 'NotificationTaskActionChangeDomain',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_DOMAIN',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeNotes]: {
        icon: {
            name: 'NotificationTaskActionChangeNotes',
            bgColor: '#f97316',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_NOTES',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.Comment]: {
        icon: {
            name: 'NotificationTaskActionComment',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_COMMENT',
        viewType: ENotificationViewType.COMMENT,
    },
    [ENotificationTaskActionType.MentionComment]: {
        icon: {
            name: 'NotificationTaskActionMentionComment',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_MENTION_COMMENT',
        viewType: ENotificationViewType.COMMENT,
    },
    [ENotificationTaskActionType.EditComment]: {
        icon: {
            name: 'NotificationTaskActionEditComment',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_EDIT_COMMENT',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.EditCommentAttachments]: {
        icon: {
            name: 'NotificationTaskActionEditCommentAttachments',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_EDIT_COMMENT_ATTACHMENTS',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.PinnedComment]: {
        icon: {
            name: 'NotificationTaskActionPinnedComment',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_PINNED_COMMENT',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.DeleteComment]: {
        icon: {
            name: 'NotificationTaskActionDeleteComment',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_DELETE_COMMENT',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.RepeatGenerate]: {
        icon: {
            name: 'NotificationTaskActionRepeatGenerate',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_REPEAT_GENERATE',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.Clone]: {
        icon: {
            name: 'NotificationTaskActionClone',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CLONE',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.AddCollaborator]: {
        icon: {
            name: 'NotificationTaskActionAddCollaborator',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_ADD_COLLABORATOR',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.RemoveCollaborator]: {
        icon: {
            name: 'NotificationTaskActionRemoveCollaborator',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_REMOVE_COLLABORATOR',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeTodoList]: {
        icon: {
            name: 'NotificationTaskActionChangeTodoList',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_TODO_LIST',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.WORKFLOW]: {
        icon: {
            name: 'flow',
            bgColor: '#8b5cf6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_EDIT',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.WorkflowAttachToTask]: {
        icon: {
            name: 'flow',
            bgColor: '#8b5cf6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_ATTACH_WORKFLOW',
        viewType: ENotificationViewType.HIDDEN,
    },

    [ENotificationTaskActionType.WorkflowStepComplete]: {
        icon: {
            name: 'NotificationTaskActionFinished',
            bgColor: '#22c55e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_FINISHED_STEP',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.WorkflowStepStart]: {
        icon: {
            name: 'flow',
            bgColor: '#8b5cf6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_START_STEP',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ApprovalChange]: {
        icon: {
            name: 'approval',
            bgColor: '#6b7280',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_CHANGED',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ApprovalRequest]: {
        icon: {
            name: 'approval',
            bgColor: '#f97316',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.ApprovalRequestCancel]: {
        icon: {
            name: 'approval',
            bgColor: '#6b7280',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST_CANCEL',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.ApprovalRequestApprove]: {
        icon: {
            name: 'approval',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST_APPROVE',
        viewType: ENotificationViewType.HIDDEN,
    },
    [ENotificationTaskActionType.ApprovalRequestReject]: {
        icon: {
            name: 'approval',
            bgColor: '#ef4444',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_APPROVAL_REQUEST_REJECT',
        viewType: ENotificationViewType.HIDDEN,
    },

    [ENotificationTaskActionType.SubTaskAdded]: {
        icon: {
            name: 'NotificationTaskActionAddNew',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_ADD_NEW_SUB_TASK',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.SubTaskRemoved]: {
        icon: {
            name: 'Link',
            bgColor: '#ea580c',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_REMOVE_SUB_TASK',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ParentTaskAdded]: {
        icon: {
            name: 'NotificationTaskActionAddNew',
            bgColor: '#008e8e',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_ADD_PARENT_TASK',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ParentTaskRemoved]: {
        icon: {
            name: 'Link',
            bgColor: '#ea580c',
            fillColor: '#fff',
        },
        actionLabelKey:
            'NOTIFICATION_CONTENT_BY_ACTION_TYPE_REMOVE_PARENT_TASK',
        viewType: ENotificationViewType.DIFF,
    },
    [ENotificationTaskActionType.ChangeStartEndTime]: {
        icon: {
            name: 'NotificationTaskActionChangeDeadline',
            bgColor: '#3b82f6',
            fillColor: '#fff',
        },
        actionLabelKey: 'NOTIFICATION_CONTENT_BY_ACTION_TYPE_CHANGE_DEADLINE',
        viewType: ENotificationViewType.DIFF,
    },
};
