<script setup lang="ts">
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { ref } from 'vue';

defineEmits(['onChoosePlatform']);

const platformList = ref([
  {
    id: 1,
    text: 'CHAT_REACTIONS_ALL',
    value: '',
  },
  {
    id: 2,
    text: 'Web',
    value: 'WEB',
  },
  {
    id: 3,
    text: 'Mobile',
    value: 'MOBILE',
  },
]);

const platformSelect = ref('');
</script>

<template>
  <vig-dropdown>
    <template #dropdown-toggle>
      <div
        v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER')"
        class="w-6 h-6 flex-center relative hover:bg-current-50 rounded-full"
      >
        <SynIcon
          name="filter2"
          class="cursor-pointer fill-current"
          :custom-class="`w-3.5 h-3.5`"
        />
        <div
          v-if="platformSelect != ''"
          class="w-2.5 h-2.5 rounded-full bg-red-500 absolute top-0 right-0"
        ></div>
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="py-2 px-3 overflow-auto max-h-80 small-scrollbar"
        style="max-width: 180px"
      >
        <div
          v-for="(platform, index) in platformList"
          :key="index"
          class="flex items-center mt-2 cursor-pointer"
          @click="
            platformSelect = platform.value;
            $emit('onChoosePlatform', platformSelect);
          "
        >
          <div class="h-4 w-4 flex-center">
            <input
              type="radio"
              name="default-radio"
              :checked="platform.value === platformSelect"
              class="
                w-4
                h-4
                text-current-500
                bg-gray-100
                border-gray-300
                cursor-pointer
              "
            />
          </div>
          <div
            class="ml-2 flex-1"
            :class="
              platform.value === platformSelect
                ? 'text-current '
                : 'text-gray-500'
            "
          >
            <span v-if="platform.id == 1">{{ $t(platform.text) }}</span>
            <span v-else>{{ platform.text }}</span>
          </div>
        </div>
      </div>
    </template>
  </vig-dropdown>
</template>
