<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 416 416"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g>
        <g>
          <g>
            <path
              d="M325.333,181.333c0-65.6-34.88-120.32-96-134.827V32c0-17.707-14.293-32-32-32s-32,14.293-32,32v14.507
            c-3.093,0.747-6.08,1.6-9.067,2.56c-2.24,0.747-4.373,1.493-6.507,2.347c0,0-0.107,0-0.107,0.107
            c-0.107,0-0.213,0.107-0.32,0.107c-4.907,1.92-9.707,4.16-14.4,6.613c-0.107,0-0.213,0.107-0.32,0.107l190.72,201.493V181.333z"
              class=""
            ></path>
            <path
              d="M368,345.493L108.587,77.867L53.867,21.44l-27.2,27.093L86.4,108.267l0.107,0.107c-11.2,21.12-17.173,46.08-17.173,72.96
            V288l-42.667,42.667V352h292.907l42.667,42.667l27.093-27.2L368,345.493z"
              class=""
            ></path>
            <path
              d="M197.333,416C220.907,416,240,396.907,240,373.333h-85.333C154.667,396.907,173.76,416,197.333,416z"
              class=""
            ></path>
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
