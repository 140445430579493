import { LiveRange, LivePosition } from 'ckeditor5/src/engine';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import $constants from '@/ui/plugins/constants';

export default class PasteLink extends Plugin {
    static get pluginName() {
        return 'PasteLink';
    }

    init() {
        const editor = this.editor;
        const modelDocument = editor.model.document;

        // We need to listen on `Clipboard#inputTransformation` because we need to save positions of selection.
        // After pasting, the content between those positions will be checked for a URL that could be transformed
        // into media.
        this.listenTo(
            editor.plugins.get('ClipboardPipeline'),
            'inputTransformation',
            () => {
                const firstRange = modelDocument.selection.getFirstRange();

                const leftLivePosition = LivePosition.fromPosition(
                    firstRange.start
                );
                leftLivePosition.stickiness = 'toPrevious';

                const rightLivePosition = LivePosition.fromPosition(
                    firstRange.end
                );
                rightLivePosition.stickiness = 'toNext';

                modelDocument.once(
                    'change:data',
                    () => {
                        this._embedMediaBetweenPositions(
                            leftLivePosition,
                            rightLivePosition
                        );

                        leftLivePosition.detach();
                        rightLivePosition.detach();
                    },
                    { priority: 'high' }
                );
            }
        );
    }

    _embedMediaBetweenPositions(leftPosition, rightPosition) {
        const editor = this.editor;

        const urlRange = new LiveRange(leftPosition, rightPosition);
        this._positionToInsert = LivePosition.fromPosition(leftPosition);
        const walker = urlRange.getWalker({ ignoreElementEnd: true });

        let url = '';

        for (const node of walker) {
            // console.log('node', node);
            if (node.item.is('$textProxy')) {
                url += node.item.data;
            }
        }

        url = url.trim();

        // console.log('url', url);

        // If the URL does not match to universal URL regexp, let's skip that.
        if (!url.match($constants.REGEX.LINK)) {
            // urlRange.detach();
            return;
        }

        editor.model.change((writer) => {
            writer.remove(urlRange);
            urlRange.detach();

            let insertionPosition;

            // Check if position where the media element should be inserted is still valid.
            // Otherwise leave it as undefined to use document.selection - default behavior of model.insertContent().
            if (this._positionToInsert.root.rootName !== '$graveyard') {
                insertionPosition = this._positionToInsert;
            }

            this._insertMedia(editor.model, url, insertionPosition);

            this._positionToInsert.detach();
            this._positionToInsert = null;
        });
    }

    _insertMedia(model, url, insertRange) {
        model.change((writer) => {
            const urlstr =
                url?.startsWith('http://') || url?.startsWith('https://')
                    ? url
                    : 'https://' + url;

            const mediaElement = writer.createText(url, {
                linkHref: urlstr,
            });
            const spaceElement = writer.createText(' ');

            model.insertContent(mediaElement, insertRange);
            model.insertContent(spaceElement);

            // After applying attribute to the text, remove given attribute from the selection.
            // This way user is able to type a text without attribute used by auto formatter.
            writer.removeSelectionAttribute('linkHref');
        });
    }
}
