import { computed, ref } from 'vue';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import axios from 'axios';
import chatStore from '@/store/chat';
import fileStorageStore from '@/store/fileStorage';
import ChatMessageRepository from '@/application/repositories/chat/ChatMessageRepository';
import PostMessageService from '@/application/services/PostMessageService';
import { AppMessageTypeEnum } from '@/domain/enums/app-message-type.enum';

export default function attachmentMessageComposables(
    organizationId,
    formChat,
    isEmbeddedApp
) {
    const _chatStore = chatStore();
    const _fileStorageStore = fileStorageStore();

    const previewFile = ref(null as any);
    const filesList = ref([] as any);
    const filesListInCoversation = ref([] as any);
    const indexPageFile = ref(0);
    const isLoadingFile = ref(false);
    const isLoading = ref(false);
    const pageSize = ref(50);
    const indexCurrentFile = ref();
    const totalListFile = ref(0);
    const isDraging = ref(false);

    const contacts = computed(() => _chatStore.getContacts);
    const onPaste = (event) => {
        const files = (event.clipboardData || event.originalEvent.clipboardData)
            .files;

        // files?.forEach((file) => (file.isPasted = true));

        if (files.length) formChat.value?.addFiles(files);
    };

    const setChatAboutTask = (task) => {
        formChat.value?.setTask(task);
    };

    const setChatAboutDayoff = (dayoff) => {
        formChat.value?.setDayoff(dayoff);
    };

    const setChatAboutEvent = (event) => {
        formChat.value?.setEvent(event);
    };

    const setNote = (note) => {
        formChat.value?.setNote(note);
    };

    const openNote = () => {
        formChat.value?.openNote();
    };

    const setStopRecording = (blob) => {
        formChat.value?.setStopRecording(blob);
    };

    const onViewFile = async (conversationId, file) => {
        const indexIdFileList = filesList.value?.findIndex(
            (el) => el?.id === file?.id
        );
        const indexIdFilesListInCoversation =
            filesListInCoversation.value?.findIndex(
                (el) => el?.id === file?.id
            );

        // file not have in list file, but have in messages list => load new file
        if (indexIdFileList == -1 && indexIdFilesListInCoversation !== -1) {
            indexPageFile.value = filesList.value.length;
        }

        isLoading.value = true;
        await _getListFile(
            conversationId,
            pageSize.value,
            indexPageFile.value,
            'new'
        );

        indexCurrentFile.value = filesList.value.findIndex(
            (f) => f?.id === file?.id
        );
        if (indexCurrentFile.value == -1) indexCurrentFile.value = null;

        isLoading.value = false;

        if (isEmbeddedApp.value) {
            return PostMessageService.getInstance().postMessageToParent({
                type: AppMessageTypeEnum.tictopMessageFileClick,
                data: { currentFile: file, files: filesList.value },
            });
        }

        previewFile.value = file;
    };

    const onCloseViewFile = () => {
        previewFile.value = null;
        indexCurrentFile.value = null;
        indexPageFile.value = 0;
        pageSize.value = 50;
    };

    const onViewNextFile = async (conversationId, currentIndex) => {
        if (currentIndex !== filesList.value.length - 1) return;
        isLoadingFile.value = true;

        await _getListFile(
            conversationId,
            pageSize.value,
            filesList.value.length,
            'add'
        );

        indexCurrentFile.value = currentIndex + 1;
        isLoadingFile.value = false;
    };

    const onMessageDownload = (files) => {
        if (files.length < 1) return;

        if (files.length === 1) {
            return axios
                .get(files[0].pathUrl || files[0].url_full, {
                    responseType: 'blob',
                })
                .then((res) => {
                    saveAs(res.data, files[0].name);
                });
        } else {
            const zip = new JSZip();
            files.forEach((file) => {
                zip.file(
                    file.name,
                    axios(file.pathUrl || file.url_full, {
                        responseType: 'blob',
                    }).then((res) => res?.data)
                );
            });
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, `files-${new Date().getTime()}.zip`);
            });
        }
    };

    const setPreviewLinkMessage = (converId, objLink, msg, userId) => {
        const indexLink = msg?.linkObjects?.findIndex(
            (el) => el?.link === objLink?.link
        );

        if (indexLink == -1 || objLink?.index == -1) return;

        const dataObjLinks = msg?.linkObjects;
        dataObjLinks[indexLink || objLink?.index].previewData['isPreview'] =
            false;

        const updData = { linkObjects: dataObjLinks };

        ChatMessageRepository.updateMessage(
            organizationId.value,
            converId,
            msg?.id,
            updData,
            userId
        );
    };

    const dragOver = (value) => {
        isDraging.value = value;
    };

    const onDropImage = (e) => {
        isDraging.value = false;

        formChat.value?.addFiles(Array.from(e.dataTransfer.files));
    };

    const resetData = () => {
        filesList.value = [];
        filesListInCoversation.value = [];
        indexCurrentFile.value = 0;
        indexPageFile.value = 0;
        pageSize.value = 50;
        indexCurrentFile.value = 0;
        totalListFile.value = 0;
    };

    const _getListFile = async (
        conversationId,
        pageSize,
        indexPageFile,
        type
    ) => {
        const contactActive: any = contacts.value[conversationId];
        const isGroup = !!contactActive?.groupUsers;

        if (type == 'new') filesList.value = [];

        const params = {
            orgId: organizationId.value,
            conversationId: conversationId,
            category: 'CHAT',
            pageSize: pageSize,
            pageIndex: indexPageFile,
        } as any;

        if (isGroup) {
            params.groupId = contactActive?.id;
        } else {
            params.userId = contactActive?.id;
        }

        const { files, total } = await _fileStorageStore.searchFiles(params);

        files?.forEach((el) => {
            const index = filesList.value.findIndex((f) => f.id == el.id);
            if (index === -1) filesList.value.push(el);
        });

        if (indexCurrentFile.value == -1) indexCurrentFile.value = null;

        totalListFile.value = total;
    };

    return {
        previewFile,
        filesList,
        filesListInCoversation,
        indexPageFile,
        isLoadingFile,
        isLoading,
        pageSize,
        indexCurrentFile,
        totalListFile,
        isDraging,
        onPaste,
        setChatAboutTask,
        setChatAboutDayoff,
        setChatAboutEvent,
        setNote,
        openNote,
        setStopRecording,
        onViewFile,
        onCloseViewFile,
        onViewNextFile,
        onMessageDownload,
        setPreviewLinkMessage,
        dragOver,
        onDropImage,
        resetData,
    };
}
