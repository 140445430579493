<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M492,236H144.262c-11.046,0-20,8.954-20,20s8.954,20,20,20H492c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z
			"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M492,86H144.262c-11.046,0-20,8.954-20,20s8.954,20,20,20H492c11.046,0,20-8.954,20-20S503.046,86,492,86z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M492,386H144.262c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20H492c11.046,0,20-8.954,20-20
			C512,394.954,503.046,386,492,386z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <circle cx="27" cy="106" r="27"></circle>
        </g>
      </g>
      <g>
        <g>
          <circle cx="27" cy="256" r="27"></circle>
        </g>
      </g>
      <g>
        <g>
          <circle cx="27" cy="406" r="27"></circle>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
