<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    percent: number;
    isAutoRun?: boolean;
    speed?: number;
    maxPercent?: number;
    color: string;
    isShowLabel?: boolean;
    type: 'PORTRAIT' | 'LANDSCAPE';
    tooltipContent?: string;
  }>(),
  {
    speed: 100,
    maxPercent: 100,
    color: '#4bb0b2',
    type: 'LANDSCAPE',
    tooltipContent: '',
  }
);

const percentProcess = ref(props.percent);

let intervalId;

const init = () => {
  if (!props.isAutoRun) return;

  intervalId = setInterval(() => {
    if (percentProcess.value == props.maxPercent)
      return clearInterval(intervalId);
    percentProcess.value++;
  }, props.speed);
};

onUnmounted(() => {
  clearInterval(intervalId);
});
init();

const containerStyleByType = computed<string>(() => {
  if (props.type == 'PORTRAIT')
    return {
      minWidth: '0.25rem',
      height: '100%',
      flexDirection: 'column-reverse',
    };
  return {
    minHeight: '0.25rem',
    width: '100%',
  };
});
const subContainerStyleByType = computed<string>(() => {
  if (props.type == 'PORTRAIT')
    return {
      display: 'flex',
      flexDirection: 'column-reverse',
    };
  return {};
});
const bodyStyleByType = computed<string>(() => {
  if (props.type == 'PORTRAIT')
    return {
      height: `${percentProcess.value}%`,
      width: '100%',
      minWidth: '0.25rem',
      backgroundColor: `${props.color}`,
      borderColor: `${props.color}`,
    };

  return {
    width: `${percentProcess.value}%`,
    height: '100%',
    minHeight: '0.25rem',
    backgroundColor: `${props.color}`,
    borderColor: `${props.color}`,
  };
});
</script>

<template>
  <div
    class="relative rounded-full flex-center flex bg-gray-100"
    :style="containerStyleByType"
  >
    <div
      v-if="percentProcess"
      class="w-full h-full"
      :style="subContainerStyleByType"
    >
      <div
        v-vig-tooltip="
          tooltipContent
            ? {
                content: tooltipContent,
                placement: type == 'PORTRAIT' ? 'right-start' : 'top-start',
                delay: 0,
                theme: 'light',
                animation: 'scale',
                arrow: false,
              }
            : ''
        "
        class="z-10 border rounded-full"
        :style="bodyStyleByType"
      ></div>
    </div>
    <div
      v-if="isShowLabel"
      class="absolute w-full flex-center text-current-500"
    >
      <span>{{ percentProcess }}%</span>
    </div>
  </div>
</template>
