<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 500 500"
    fill="#fff"
  >
    <g
      data-bx-workspace="master"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:bx="https://boxy-svg.com"
      viewBox="0 0 500 500"
    >
      <path
        d="M 466.604 290.039 C 443.159 266.661 405.219 266.661 381.774 290.039 L 271.964 399.579 C 269.638 401.911 267.921 404.778 266.964 407.929 L 243.044 486.649 C 238.566 501.379 251.713 515.434 266.709 511.947 C 266.982 511.883 267.254 511.814 267.524 511.739 L 348.244 489.379 C 351.566 488.459 354.594 486.695 357.034 484.259 L 466.604 374.889 C 490.006 351.447 490.006 313.482 466.604 290.039 Z M 442.844 351.769 L 437.644 356.959 L 399.644 318.959 L 404.834 313.789 C 419.437 299.163 444.397 305.83 449.762 325.79 C 452.252 335.053 449.611 344.941 442.834 351.729 Z"
      ></path>

      <path
        d="M 343.54 -7.298 L 98.72 -7.298 C 54.211 -7.298 18.13 28.783 18.13 73.292 L 18.13 424.112 C 18.13 468.621 54.211 504.702 98.72 504.702 L 234.21 504.702 C 233.641 504.237 233.101 503.74 232.59 503.212 C 227.322 497.824 225.406 489.994 227.59 482.782 L 252.29 401.452 C 253.283 398.197 255.062 395.237 257.47 392.832 L 370.92 279.702 C 384.862 265.739 404.627 259.261 424.13 262.262 L 424.13 73.262 C 424.113 28.765 388.037 -7.298 343.54 -7.298 Z M 243.18 313.072 L 118.07 313.072"
      ></path>

      <g transform="matrix(1, 0, 0, 1, 75, 75)">
        <path
          style="fill: #008e8e"
          d="M369.85,310.37v120a120,120,0,0,1-120,120h0v-240a60,60,0,0,1,60-60h0a60,60,0,0,1,60,60Z"
          transform="translate(-249.85 -250.37)"
        ></path>
        <path
          style="fill: #f2cb05"
          d="M291.66,253a60,60,0,0,0-41.81,57.19V318a60,60,0,0,0,59.5,52.37h60.5V310.21A60,60,0,0,0,314,250.37h-4.67A60.06,60.06,0,0,0,291.66,253Z"
          transform="translate(-249.85 -250.37)"
        ></path>
        <circle style="fill: #005167" cx="60" cy="59.81" r="50"></circle>
        <path
          style="isolation: isolate; fill: #8fcdcd"
          d="M466.72,392.46h0a13.29,13.29,0,0,1,18.14-4.86l11.49,6.64a13.28,13.28,0,0,1,4.87,18.14h0a13.28,13.28,0,0,1-18.14,4.85l-11.49-6.63A13.29,13.29,0,0,1,466.72,392.46Z"
          transform="translate(-249.85 -250.37)"
        ></path>
        <path
          style="isolation: isolate; fill: #61b9b9"
          d="M432.25,433.67h0a13.28,13.28,0,0,1,18.78,0l9.38,9.38a13.27,13.27,0,0,1,0,18.78h0a13.27,13.27,0,0,1-18.77,0l-9.39-9.38A13.28,13.28,0,0,1,432.25,433.67Z"
          transform="translate(-249.85 -250.37)"
        ></path>
        <path
          style="fill: #008e8e"
          d="M410,502.46h0a13.28,13.28,0,0,1-18.15-4.84v0l-6.64-11.5a13.28,13.28,0,0,1,23-13.28l6.64,11.5A13.28,13.28,0,0,1,410,502.46Z"
          transform="translate(-249.85 -250.37)"
        ></path>
        <path
          style="fill: #f2cb05"
          d="M314,250.37a60,60,0,0,1,55.83,59.84v60.16h59.5a120,120,0,0,0,120-120Z"
          transform="translate(-249.85 -250.37)"
        ></path>
      </g>
    </g>
  </svg>
</template>
