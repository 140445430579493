<script setup lang="ts">
import { computed } from 'vue';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});
// const beforeActivity = computed(() => props.beforeUpdate);
const afterActivity = computed(() => props.afterUpdate);
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 items-center justify-between pr-2">
      <span class="text-xs text-gray-500">{{
        $t('TASK_DETAIL_LABEL_UNASSIGNED')
      }}</span>
      <span>→</span>
    </div>
    <div class="flex w-1/2 justify-start pl-2">
      <div class="flex flex-col space-y-1 text-xs">
        <UserById
          v-if="afterActivity?.AssigneeId"
          :user-id="afterActivity?.AssigneeId"
          avatar-class="w-5 h-5"
          class="font-medium"
        />
        <div
          v-if="afterActivity?.GroupId"
          class="flex items-center space-x-2 space-y-1 text-xs"
        >
          <GroupById
            v-if="afterActivity?.GroupId"
            :group-id="afterActivity?.GroupId"
            avatar-class="w-5 h-5"
            class="font-medium"
          >
            <template #description-name>
              <span class="text-gray-500" style="font-size: 0.6rem">{{
                $t('COMMON_LABEL_ASSIGN_GROUP')
              }}</span>
            </template>
          </GroupById>
        </div>
      </div>
    </div>
  </div>
</template>
