<script setup lang="ts">
import { computed, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import SynInput from '@/ui/common/atoms/SynInput/SynInput.vue';
import { taskStates, TaskUrgency } from '@/ui/hooks/taskHook';
import SynCheckboxTag from '@/ui/common/molecules/SynCheckbox/SynCheckboxTag.vue';
import SynTag from '@/ui/common/atoms/SynTag.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import SelectMultiDomain from '@/ui/modules/task/filter/SelectMultiDomain.vue';
import SelectMultiGroup from '@/ui/modules/task/filter/SelectMultiGroup.vue';
import ListSelectMultiGroup from '@/ui/modules/task/filter/ListSelectMultiGroup.vue';
import SelectMultiUser from '@/ui/modules/task/filter/SelectMultiUser.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import { CustomFilters } from '@/application/interfaces/tasks/task-interfaces';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import atomFormInputComposables from '@/ui/common/atoms/SynInput/atom-form-input-composables';
import {
  ECustomFilterSourceType,
  ETaskListModule,
} from '@/application/types/task/task.types';

const props = defineProps<{
  sourceType: ETaskListModule;
  listTaskOwnerId?: number;
  customFilterSourceType?: ECustomFilterSourceType;
}>();

const emit = defineEmits(['onClose']);

const _taskTabViewStore = taskTabViewStore();
const isOpenAddNewTab = ref(false);
const selectedGroupIds = ref([]);
const selectedDomainIds = ref([]);
const selectedUserIds = ref([]);
const haveSupplement = ref(false);
const inputNameTabRef = ref(null as any);
const urgencyList = ref(cloneDeep(Object.values(TaskUrgency)) as any[]);
const nameTabs = ref('');

const {
  validationResult: validationTabName,
  onValidation: onValidationTabName,
  reset: onResetValidationTabName,
} = atomFormInputComposables([
  (value) => {
    return value?.trim() ? '' : 'TASK_CUSTOM_FILTER_NAME_IS_NOT_NULL';
  },
]);

const onBlurTabName = () => {
  onValidationTabName(nameTabs.value);
};
const onUpdateTabName = () => {
  onResetValidationTabName();
};
const statusList = ref(
  cloneDeep(
    Object.values(taskStates).sort((a, b) => {
      return a.index - b.index;
    })
  )
);
const filterDateObj = ref({
  createdFromDate: null,
  createdToDate: null,
  updatedFromDate: null,
  updatedToDate: null,
});
const filterType = computed(() => {
  const urgency: any[] = urgencyList.value
    ?.filter((o) => o.isChecked)
    .map((o) => o.key);
  const status: any[] = statusList.value?.filter((o) => o.isChecked);
  return {
    STATUS: {
      isAny: status?.length <= 0,
      key: 'status',
    },
    URGENCY: {
      isAny: urgency?.length <= 0,
      key: 'urgency',
    },
    GROUP: {
      isAny: selectedGroupIds.value?.length <= 0,
      key: 'group',
    },
    DOMAIN: {
      isAny: selectedDomainIds.value?.length <= 0,
      key: 'domain',
    },
  };
});

const filterMoreNumber = computed(() => {
  let number = 0;
  if (selectedGroupIds?.value?.length) number++;
  if (filterDateObj?.value?.createdFromDate) number++;
  if (filterDateObj?.value?.createdToDate) number++;
  if (filterDateObj?.value?.updatedFromDate) number++;
  if (filterDateObj?.value?.updatedToDate) number++;
  return number;
});

const onShowAddTab = () => {
  isOpenAddNewTab.value = true;
  setTimeout(() => inputNameTabRef.value?.focus());
};

const onChangeAnyCheckBox = (key) => {
  filterType.value[key].isAny = !filterType.value[key].isAny;
  if (!filterType.value[key].isAny) return;
  switch (key) {
    case filterType.value.STATUS.key:
      statusList.value = statusList.value.map((o) => {
        o.isChecked = false;
        return o;
      });
      break;
    case filterType.value.DOMAIN.key:
      selectedDomainIds.value = [];
      break;
    case filterType.value.GROUP.key:
      selectedGroupIds.value = [];
      break;
    case filterType.value.URGENCY.key:
      urgencyList.value = urgencyList.value.map((o) => {
        o.isChecked = false;
        return o;
      });
      break;
    default:
      break;
  }
};

const addTabsModalRef = ref<any>(null);

const isDisabledSave = computed<boolean>(() => {
  return (
    !nameTabs.value ||
    (selectedGroupIds.value?.length == 0 &&
      selectedDomainIds.value?.length == 0 &&
      urgencyList.value?.filter((o) => o.isChecked)?.length == 0 &&
      selectedUserIds.value?.length == 0 &&
      statusList.value?.filter((o) => o.isChecked)?.length == 0)
  );
});
const onHandleFilter = async () => {
  const selectedGroupIdString = selectedGroupIds.value.toString();
  const groupIds =
    props.sourceType === 'G'
      ? selectedGroupIdString
        ? `${selectedGroupIdString},${props.listTaskOwnerId}`
        : `${props.listTaskOwnerId}`
      : selectedGroupIdString;
  const selectedDomainIdString = selectedDomainIds.value.toString();
  const domainIds =
    props.sourceType === 'D'
      ? selectedDomainIdString
        ? `${selectedDomainIdString},${props.listTaskOwnerId}`
        : `${props.listTaskOwnerId}`
      : selectedDomainIdString;

  const filterPayload = {
    isDefault: false,
    listOwnerId: props.sourceType === 'U' ? props.listTaskOwnerId : null,
    urgency: urgencyList.value
      .filter((o) => o.isChecked)
      .map((o) => o.key)
      .toString(),
    groupIds,
    domainIds,
    creatorIds: selectedUserIds.value.toString(),
    status: statusList.value
      .filter((o) => o.isChecked)
      .map((o) => o.key)
      .toString(),
  } as CustomFilters;
  // onFilterMyTasks(filterPayload);

  await _taskTabViewStore.addUserCustomTaskFilter(
    props.customFilterSourceType ||
      props.sourceType ||
      ECustomFilterSourceType.USER,
    props.listTaskOwnerId || 0,
    {
      id: uuidv4(),
      name: nameTabs.value,
      index: 0,
      color: '',
      visible: true,
      filterObject: filterPayload,
    }
  );

  onResetClick();

  addTabsModalRef.value?.onForceClose && addTabsModalRef.value?.onForceClose();
  emit('onClose');
};

const onResetClick = () => {
  isOpenAddNewTab.value = false;
  clearData();
};

const clearData = () => {
  nameTabs.value = '';
  urgencyList.value = urgencyList.value.map((o) => {
    o.isChecked = false;
    return o;
  });
  statusList.value = statusList.value.map((o) => {
    o.isChecked = false;
    return o;
  });
  selectedGroupIds.value = [];
  selectedDomainIds.value = [];
  selectedUserIds.value = [];

  filterDateObj.value = {
    createdFromDate: null,
    createdToDate: null,
    updatedFromDate: null,
    updatedToDate: null,
  };
};
</script>

<template>
  <VigDropdown
    ref="addTabsModalRef"
    :arrow="false"
    placement="right-start"
    @on-dropdown-open="onShowAddTab"
    @on-dropdown-close="isOpenAddNewTab = false"
  >
    <template #dropdown-toggle>
      <div
        class="
          p-1
          w-6
          h-6
          flex-center
          cursor-pointer
          bg-gray-100
          hover:bg-gray-200
          rounded-full
        "
        :class="
          isOpenAddNewTab
            ? 'ring-2 ring-current-100 ring-opacity-50 border border-current-200 fill-current-400'
            : 'fill-gray-500'
        "
      >
        <SynIcon name="plus" custom-class="w-3 h-3" />
      </div>
    </template>

    <template v-if="isOpenAddNewTab" #dropdown-menu>
      <div
        class="flex flex-col rounded"
        style="width: 26rem; max-height: 36rem"
      >
        <span class="font-medium text-md text-gray-800 px-4 pt-4">{{
          $t('TASK_LIST_CREATE_NEW_TAB_LABEL')
        }}</span>

        <!-- NAME  -->
        <div class="flex flex-col pt-5 px-4 pb-2">
          <span class="text-xs tex-gray-300 font-medium">{{
            $t('TASK_LIST_CREATE_TAB_NAME_LABEL')
          }}</span>

          <SynFormInput
            :is-valid="validationTabName.isValid"
            :error-message="validationTabName.message"
          >
            <SynInput
              ref="inputNameTabRef"
              v-model="nameTabs"
              :placeholder="$t('TASK_LIST_CREATE_TAB_NAME_LABEL')"
              class="font-medium pt-1"
              autofocus
              @blur="onBlurTabName"
              @update:model-value="onUpdateTabName"
            />
          </SynFormInput>
        </div>
        <!-- END NAME  -->

        <section
          class="flex flex-col overflow-y-auto small-scrollbar px-4 h-full"
        >
          <!--  STATUS    -->
          <div class="flex flex-col pt-2">
            <div class="flex items-center justify-between">
              <span class="text-xs tex-gray-300 font-medium">{{
                $t('COMMON_LABEL_STATUS')
              }}</span>
              <div
                class="text-xs flex-center space-x-2 cursor-pointer"
                @click="onChangeAnyCheckBox(filterType.STATUS.key)"
              >
                <SynIcon
                  name="checkbox"
                  :is-active="filterType.STATUS.isAny"
                  class="fill-current"
                  custom-class="w-3 h-3"
                />
                <span>{{ $t('COMMON_LABEL_ANY') }}</span>
              </div>
            </div>
            <div v-if="statusList?.length > 0" class="flex flex-wrap pt-1">
              <SynCheckboxTag
                v-for="item in statusList"
                :key="item"
                v-model="item.isChecked"
                class="mr-1 mb-1"
              >
                <SynTag
                  size="circle-small"
                  :custom-class="
                    item.isChecked
                      ? 'flex-center space-x-2 px-2 py-1 text-sm border border-current-50 '
                      : 'flex-center space-x-2 px-2 py-1 text-sm border border-current-50  hover:bg-current-50 hover:text-current'
                  "
                >
                  <span
                    class="p-1 rounded-full"
                    :class="`bg-${item?.colorKey}`"
                  ></span>
                  <span>{{ $t(item?.nameCode) }}</span>
                </SynTag>
              </SynCheckboxTag>
            </div>
          </div>
          <!--  END STATUS    -->

          <!--  URGENCY    -->
          <div class="flex flex-col pt-4">
            <div class="flex items-center justify-between">
              <span class="text-xs tex-gray-300 font-medium">{{
                $t('TASK_TABLE_LABEL_URGENCY')
              }}</span>
              <div
                class="text-xs flex-center space-x-2 cursor-pointer"
                @click="onChangeAnyCheckBox(filterType.URGENCY.key)"
              >
                <SynIcon
                  name="checkbox"
                  :is-active="filterType.URGENCY.isAny"
                  class="fill-current"
                  custom-class="w-3 h-3"
                />
                <span>{{ $t('COMMON_LABEL_ANY') }}</span>
              </div>
            </div>

            <div v-if="urgencyList?.length > 0" class="flex flex-wrap pt-1">
              <SynCheckboxTag
                v-for="item in urgencyList"
                :key="item"
                v-model="item.isChecked"
                class="mr-1 mb-1"
              >
                <SynTag
                  size="circle-small"
                  :custom-class="
                    item.isChecked
                      ? 'flex-center space-x-2 px-2 py-1 text-sm border border-current-50 '
                      : 'flex-center space-x-2 px-2 py-1 text-sm border border-current-50  hover:bg-current-50 hover:text-current'
                  "
                >
                  <PriorityTask :urgency="item.key" size="mini" />
                </SynTag>
              </SynCheckboxTag>
            </div>
          </div>
          <!--  END URGENCY    -->

          <!--  DOMAIN  -->
          <div class="flex flex-col pt-4">
            <div class="flex items-center justify-between">
              <span class="text-xs tex-gray-300 font-medium">{{
                $t('TASK_FILTER_LABEL_SCOPE')
              }}</span>
              <div
                class="text-xs flex-center space-x-2 cursor-pointer"
                @click="onChangeAnyCheckBox(filterType.DOMAIN.key)"
              >
                <SynIcon
                  name="checkbox"
                  :is-active="filterType.DOMAIN.isAny"
                  class="fill-current"
                  custom-class="w-3 h-3"
                />
                <span>{{ $t('COMMON_LABEL_ANY') }}</span>
              </div>
            </div>
            <SelectMultiDomain
              v-model="selectedDomainIds"
              class="mt-1"
              :list-task-owner-id="listTaskOwnerId"
            />
          </div>
          <!--  END DOMAIN  -->

          <!--  CREATOR   -->
          <div class="flex flex-col pt-4">
            <div class="flex items-center justify-between">
              <span class="text-xs tex-gray-300 font-medium">{{
                $t('TASK_TABLE_LABEL_CREATOR')
              }}</span>
            </div>
            <SelectMultiUser v-model="selectedUserIds" />
          </div>
          <!--  END CREATOR   -->

          <div
            class="
              flex-center
              space-x-1
              text-xs text-gray-500
              cursor-pointer
              rounded
              px-2
              py-1
              my-3
              hover:bg-gray-50 hover:text-current-500 hover:fill-current-500
              fill-gray-500
            "
            :class="haveSupplement ? 'border-b border-gray-100' : ''"
            @click="haveSupplement = !haveSupplement"
          >
            <span>{{
              haveSupplement ? 'Hide Information' : 'More Information'
            }}</span>
            <template v-if="!haveSupplement">
              <span
                v-if="filterMoreNumber > 0"
                class="px-1 rounded-full bg-current-400 text-white"
                style="font-size: 0.6rem"
                >{{ filterMoreNumber }}</span
              >
            </template>
            <SynIcon
              name="arrow-right"
              :class="haveSupplement ? 'rotate-90' : ''"
            />
          </div>

          <template v-if="haveSupplement">
            <!--  GROUP  -->
            <div v-if="sourceType !== 'G'" class="flex flex-col pt-4">
              <div class="flex items-center justify-between">
                <span class="text-xs tex-gray-300 font-medium">{{
                  $t('COMMON_LABEL_GROUPS')
                }}</span>
                <div
                  class="text-xs flex-center space-x-2 cursor-pointer"
                  @click="onChangeAnyCheckBox(filterType.GROUP.key)"
                >
                  <SynIcon
                    name="checkbox"
                    :is-active="filterType.GROUP.isAny"
                    class="fill-current"
                    custom-class="w-3 h-3"
                  />
                  <span>{{ $t('COMMON_LABEL_ANY') }}</span>
                </div>
              </div>
              <SelectMultiGroup
                v-model="selectedGroupIds"
                class="mt-1"
                :list-task-owner-id="listTaskOwnerId"
                :max-item-show="6"
              />
              <ListSelectMultiGroup
                v-model="selectedGroupIds"
                class="mt-1"
                :list-task-owner-id="listTaskOwnerId"
                :min-item-show="6"
              />
            </div>
            <!--  END GROUP  -->

            <!--  CREATE DATE  -->
            <div class="flex flex-col pt-4">
              <div class="flex items-center justify-between">
                <span class="text-xs tex-gray-300 font-medium">{{
                  $t('TICKET_TASK_FORM_LABEL_CREATE_DATE')
                }}</span>
              </div>
              <div class="flex space-x-2 pt-1">
                <div class="flex-1">
                  <syn-date-input
                    v-model="filterDateObj.createdFromDate"
                    input-class="text-sm"
                    :placeholder="$t('CHECKIN_LABEL_FROM') || 'Date from'"
                  />
                </div>
                <div class="flex-1">
                  <syn-date-input
                    v-model="filterDateObj.createdToDate"
                    input-class="text-sm"
                    :placeholder="$t('GED_FILTER_DATE_TO') || 'to'"
                  />
                </div>
              </div>
            </div>
            <!--  CREATE DATE END  -->

            <!--  UPDATE DATE  -->
            <div class="flex flex-col pt-4 pb-4">
              <div class="flex items-center justify-between">
                <span class="text-xs tex-gray-300 font-medium">{{
                  $t('TICKET_TASK_FORM_LABEL_UPDATE_DATE')
                }}</span>
              </div>
              <div class="flex space-x-2 pt-1">
                <div class="flex-1">
                  <syn-date-input
                    v-model="filterDateObj.updatedFromDate"
                    input-class="text-sm"
                    :placeholder="$t('CHECKIN_LABEL_FROM') || 'Date from'"
                  />
                </div>
                <div class="flex-1">
                  <syn-date-input
                    v-model="filterDateObj.updatedToDate"
                    input-class="text-sm"
                    :placeholder="$t('GED_FILTER_DATE_TO') || 'to'"
                  />
                </div>
              </div>
            </div>
            <!--  UPDATE DATE END  -->
          </template>
        </section>

        <!--  ACTION      -->
        <section class="flex items-center space-x-2 justify-end py-4 px-4">
          <SynButton
            :label="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            @click="onResetClick"
          />
          <SynButton
            :label="$t('COMMON_LABEL_SAVE')"
            :disabled="isDisabledSave"
            @click="onHandleFilter"
          />
        </section>
      </div>
    </template>
  </VigDropdown>
</template>
