<script setup lang="ts">
import { computed, ref } from 'vue';
import TaskSharingService from '@/application/services/task/TaskSharingService';
import VigButton from '@/ui/common/atoms/VigButton/VigButton.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import {
  TaskSharingPermission,
  TaskSharingStatus,
} from '@/domain/enums/taskEnum';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  task: any;
  sharedInfo: any;
}>();

const emit = defineEmits(['onModifyClick', 'onDeleted', 'onStatusChange']);

const hasPermission = (permissionId) =>
  props.sharedInfo?.permissions?.includes(permissionId);

const isSuspending = ref();
const isResuming = ref();
const isDeleting = ref();

const sharingLink = computed(() =>
  TaskSharingService.prepareSharedTaskLink(props.sharedInfo?.sharedId)
);
const sharingPermissionGroup = computed(() => {
  const getPermission = (permissionId) =>
    TaskSharingService.sharingPermissions.find(
      (group) => group?.id === permissionId
    );

  if (hasPermission(TaskSharingPermission.TASK_MODIFY_ALLOWED)) {
    return getPermission(TaskSharingPermission.TASK_MODIFY_ALLOWED);
  }

  if (hasPermission(TaskSharingPermission.TASK_ADD_ALLOWED)) {
    return getPermission(TaskSharingPermission.TASK_ADD_ALLOWED);
  }

  return getPermission(TaskSharingPermission.TASK_VIEW_ONLY);
});
const sharingPermissions = computed(() =>
  sharingPermissionGroup.value?.children?.filter((permission) =>
    hasPermission(permission?.id)
  )
);

const onCopyLinkClick = () => {
  TaskSharingService.copySharedTaskLink(props.sharedInfo?.sharedId);
};

const onSharingSuspendClick = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: translate('TASK_SHARING_SUSPEND_MSG'),
    confirmable: true,
    confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
    closeable: true,
  });

  const confirmed = await ask();

  if (confirmed) {
    isSuspending.value = true;

    await _updateSharingStatus(TaskSharingStatus.UnSharing);

    isSuspending.value = false;
  }
};

const onSharingContinueClick = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: translate('TASK_SHARING_CONTINUE_MSG'),
    confirmable: true,
    confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
    closeable: true,
  });

  const confirmed = await ask();

  if (confirmed) {
    isResuming.value = true;

    await _updateSharingStatus(TaskSharingStatus.Sharing);

    isResuming.value = false;
  }
};

const onDeleteClick = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: translate('TASK_SHARING_REMOVE_MSG'),
    confirmable: true,
    confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
    closeable: true,
  });

  const confirmed = await ask();

  if (confirmed) {
    isDeleting.value = true;

    try {
      const result = await TaskSharingService.removeTaskShared(
        props.sharedInfo
      );

      if (result) emit('onDeleted');
    } catch (e) {
      console.log(e);
    }

    isDeleting.value = false;
  }
};

const _updateSharingStatus = async (status) => {
  try {
    const updData = {
      ...props.sharedInfo,
      status,
    };

    const result = await TaskSharingService.saveTaskShared(updData);

    emit('onStatusChange', result);
  } catch (e) {
    console.log(e);
  }
};
</script>

<template>
  <div>
    <!--TASK IS SHARING-->
    <div
      v-if="sharedInfo?.status === TaskSharingStatus.Sharing"
      class="flex items-center justify-between gap-2 mb-5"
    >
      <div class="text-2xl font-semibold">
        <span class="text-current-500">{{
          $t('TASK_SHARING_STATUS_SHARING')
        }}</span>
      </div>
    </div>

    <!--TASK SHARING IS SUSPENDED-->
    <div
      v-if="sharedInfo?.status === TaskSharingStatus.UnSharing"
      class="flex items-center justify-between gap-2 mb-5"
    >
      <div class="text-2xl font-semibold">
        <span class="text-orange-600">{{
          $t('TASK_SHARING_STATUS_SUSPENDED')
        }}</span>
      </div>
    </div>

    <div class="flex flex-col gap-5">
      <div class="flex gap-1">
        <input
          type="text"
          class="vig-input flex-1 bg-gray-100"
          disabled
          :value="sharingLink"
        />
        <VigButton
          v-vig-tooltip="$t('TASK_DETAIL_COPY_LINK')"
          outline
          color="gray"
          padding="px-2.5 py-1"
          @click="onCopyLinkClick"
        >
          <SynIcon name="duplicate" custom-class="w-5 h-5" />
        </VigButton>

        <VigButton
          v-if="sharedInfo?.status === TaskSharingStatus.Sharing"
          v-vig-tooltip="$t('TASK_SHARING_SUSPEND')"
          outline
          color="orange"
          padding="px-2.5 py-1"
          @click="onSharingSuspendClick"
        >
          <SynIcon name="Pause" custom-class="w-5 h-5"></SynIcon>
        </VigButton>

        <VigButton
          v-if="sharedInfo?.status === TaskSharingStatus.UnSharing"
          v-vig-tooltip="$t('TASK_SHARING_CONTINUE')"
          outline
          color="green"
          padding="px-2.5 py-1"
          @click="onSharingContinueClick"
        >
          <SynIcon name="Play" custom-class="w-5 h-5"></SynIcon>
        </VigButton>

        <VigButton
          v-vig-tooltip="$t('TASK_SHARING_REMOVE')"
          :processing="isDeleting"
          outline
          padding="px-2.5 py-1"
          color="red"
          @click="onDeleteClick"
        >
          <SynIcon name="Trash" custom-class="w-5 h-5"></SynIcon>
        </VigButton>
      </div>

      <!--PERMISSIONS-->
      <div class="pl-5">
        <div v-for="permission in sharingPermissions" :key="permission?.id">
          - {{ $t(permission?.text) }}
        </div>
      </div>
    </div>
  </div>
</template>
