<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 228.46">
    <polygon
      class="cls-1"
      style="fill: #008e8e"
      points="21.5 45.62 0 61.46 0 228.46 43 228.46 43 61.46 21.5 45.62"
    />
    <polygon
      class="cls-2"
      style="fill: #ffb62f"
      points="43 53.38 43 37.68 21.5 21.46 0 37.68 0 53.46 0.1 53.46 21.6 37.23 43 53.38"
    />
    <polygon
      class="cls-3"
      style="fill: #f2cb05"
      points="43 32.38 43 16.46 21.5 0 0 16.46 0 32.46 0.1 32.46 21.6 16 43 32.38"
    />
  </svg>
</template>
