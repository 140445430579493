import HelpRepository from '@/application/repositories/HelpRepository';

export default class HelpService {
    private static instance: HelpService;
    _helpRepository: HelpRepository;

    constructor() {
        this._helpRepository = HelpRepository.getInstance();
    }

    public static getInstance(): HelpService {
        if (!HelpService.instance) {
            // Get from local storage
            HelpService.instance = new HelpService();
        }
        return HelpService.instance;
    }

    async getPlaylistYoutubeSpecialFiles(): Promise<any> {
        return await this._helpRepository.getPlaylistYoutubeSpecialFiles();
    }

    async getFilesVideo(folderId): Promise<any> {
        return await this._helpRepository.getFilesVideo(folderId);
    }

    async getAllFaqs(): Promise<any> {
        return await this._helpRepository.getAllFaqs();
    }

    async getAllTopics(): Promise<any> {
        return await this._helpRepository.getAllTopics();
    }

    async getByTopic(topicId): Promise<any> {
        return await this._helpRepository.getByTopic(topicId);
    }

    async getQnAByTopic(topicId): Promise<any> {
        return await this._helpRepository.getQnAByTopic(topicId);
    }

    async getSpecialFaqs(pageSize): Promise<any> {
        return await this._helpRepository.getSpecialFaqs(pageSize);
    }

    async getAllWhatNew(pageSize): Promise<any> {
        return await this._helpRepository.getAllWhatNew(pageSize);
    }
}
