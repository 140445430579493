<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue';
import FileIcon from '@/ui/modules/ged/_commons/file-icon/FileIcon.vue';
import { IUploadPreviewPropFile } from './update-preview-type';
import { formatDate } from '@/ui/hooks/calendarHook';
import { ETaskFileStatus } from '@/application/types/task/task.types';

const props = withDefaults(
  defineProps<{
    file: IUploadPreviewPropFile | any;
    readonly?: boolean;
    isNote?: boolean;
    isActive?: boolean;
    hasOpacity?: boolean;
    size?: string;
    customClass?: string;
    objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
    style?: any;
    isHiddenClose?: boolean;
    imageSize?: string;
  }>(),
  {
    size: 'normal',
    objectFit: 'cover',
    customClass: '',
    imageSize: 'small',
  }
);
defineEmits(['update:modelValue', 'remove', 'onSelect']);
const isImage = computed(
  () =>
    props.file.type?.includes('image') ||
    props.file.contentType?.includes('image') ||
    props.isNote ||
    props.file.isNote ||
    props.file.imagePath ||
    props.file.ContentType?.includes('image')
);
const isAudio = computed(
  () =>
    props.file.type?.includes('audio') ||
    props.file.contentType?.includes('audio') ||
    props.file.ContentType?.includes('audio')
);
// base 64 using fileUrl
// origin file using createObjectURL
const fileUrl = computed(() => {
  if (props.file instanceof File) return URL.createObjectURL(props.file);
  if (props.file?.fileBlob instanceof Blob) {
    return URL.createObjectURL(props.file?.fileBlob);
  }
  if (props.file?.blob instanceof Blob) {
    return URL.createObjectURL(props.file?.blob);
  }
  if (props.file?.base64) return props.file?.base64;
  if (props.file?.fileUrl) return props.file?.fileUrl;
  if (props.file?.url_full) return props.file?.url_full;
  if (props.file?.pathUrl) return props.file?.pathUrl;
  if (props.file?.Path) return props.file?.Path;

  return props.file?.imagePath || props.file?.src;
});
// const fileName = computed(() =>
//   props.file?.name ? props.file?.name : `Tictop_${new Date().getTime()}`
// );
const classBySize = computed(() => {
  if (!isImage.value) return 'w-16 h-16';
  if (props.size == 'large') return 'w-48 h-32';
  if (props.size == 'huge') return 'w-96 h-64';
  if (props.size == 'custom') return 'w-full h-full';
  if (props.size == 'auto') return 'w-full h-full';
  return 'w-20 h-16';
});

const timeUpdate = computed(() => {
  if (
    !props.file?.updateTime ||
    props.file?.updateTime === props.file?.creationTime
  )
    return '';

  return (
    formatDate(props.file?.updateTime).date +
    ' ' +
    formatDate(props.file?.updateTime).time
  );
});
const timeCreate = computed(() => {
  if (!props.file?.creationTime) return '';

  return (
    formatDate(props.file?.creationTime).date +
    ' ' +
    formatDate(props.file?.creationTime).time
  );
});

// const nameWidth = computed(() => {
//   let classN = 'w-28';
//   if (!isImage.value) return 'w-20';
//   if (props.size == 'large') classN = 'w-48';
//   if (props.size == 'huge') classN = 'w-96';
//   return classN;
// });
// const fileType = computed(() => {
//   let type = 'pdf';
//   const fileName = props.file?.fileName || props.file?.name;
//   const extension =
//     props.file?.extension?.replace('.', '') || getFileExtension(fileName) || '';

//   if (extension == 'doc' || extension == 'docx') {
//     return 'Doc';
//   }
//   if (extension == 'xls' || extension == 'xlsx') {
//     return 'Excel';
//   }
//   if (extension == 'ppt' || extension == 'pptx') {
//     return 'Ppt';
//   }
//   if (extension == 'zip' || extension == 'rar') {
//     return 'Zip';
//   }
//   return type;
// });

const iconClassByStatus = computed<string>(() => {
  switch (props.file?.status) {
    case ETaskFileStatus.MARK_BLACK:
      return 'fill-black-500';
    case ETaskFileStatus.MARK_RED:
      return 'fill-red-500';
    case ETaskFileStatus.MARK_GREEN:
      return 'fill-green-500';
    case ETaskFileStatus.MARK_BLUE:
      return 'fill-blue-500';
    case ETaskFileStatus.MARK_YELLOW:
      return 'fill-yellow-500';
    case ETaskFileStatus.MARK_PINK:
      return 'fill-pink-500';

    default:
      break;
  }
  return 'fill-green-500';
});

onUnmounted(() => {
  if (fileUrl.value) URL.revokeObjectURL(fileUrl.value);
});

const uploadPreviewDropdownRef = ref<any>(null);
const hideInfoPopup = () => {
  if (
    uploadPreviewDropdownRef.value &&
    typeof uploadPreviewDropdownRef.value?.onForceClose == 'function'
  )
    uploadPreviewDropdownRef.value?.onForceClose();
};
defineExpose({
  hideInfoPopup,
});
</script>

<template>
  <VigDropdown
    ref="uploadPreviewDropdownRef"
    trigger="mouseenter focus"
    placement="bottom-start"
    :delay="[800, null]"
  >
    <template #dropdown-toggle>
      <div class="relative group/image" :class="customClass">
        <div
          class="
            absolute
            -left-2
            -top-2
            items-center
            justify-center
            z-100
            cursor-default
          "
          :class="file?.status ? '' : 'hidden group-hover/image:flex'"
          @mouseover="hideInfoPopup"
        >
          <slot name="changeStatus">
            <div
              v-if="file?.status"
              class="
                w-6
                h-6
                rounded-full
                bg-gray-100
                flex
                items-center
                justify-center
                shadow
                z-10
                cursor-default
              "
            >
              <SynIcon name="tick" is-active :class="iconClassByStatus" />
            </div>
          </slot>
        </div>

        <div
          v-if="isAudio"
          class="
            flex
            space-x-4
            flex-wrap
            h-full
            cursor-pointer
            rounded
            hover:opacity-100
          "
          :class="`${
            isActive ? 'ring-4 ring-orange-400' : hasOpacity ? 'opacity-50' : ''
          }`"
          :style="style"
          @click="$emit('onSelect')"
        >
          <!--      <SynAudio-->
          <!--        :src="fileUrl"-->
          <!--        @on-close="$emit('remove')"-->
          <!--        @click="$emit('onSelect')"-->
          <!--      />-->
          <VigAudio
            :path="fileUrl"
            type="view"
            :size="size"
            :readonly="readonly"
            :style="style"
            :is-hidden-close="isHiddenClose"
            @click="$emit('onSelect')"
            @remove="$emit('remove')"
          />
        </div>
        <div
          v-else
          class="flex flex-col rounded hover:opacity-100"
          :class="[
            classBySize,
            isActive
              ? 'ring-4 ring-orange-400'
              : hasOpacity
              ? 'opacity-50'
              : '',
          ]"
          :style="style"
        >
          <article
            tabindex="0"
            class="
              group
              hasImage
              w-full
              h-full
              rounded-md
              focus:outline-none focus:shadow-outline
              bg-gray-100
              cursor-pointer
              relative
              shadow-sm
              syn-upload-preview
              group/image
            "
            :style="style"
          >
            <VigImage
              v-if="isImage"
              custom-class="w-full h-full sticky rounded-md bg-fixed shadow object-contain"
              :path="fileUrl"
              :size="imageSize"
              :object-fit="objectFit"
              @click="$emit('onSelect')"
            />
            <div
              v-else
              class="
                w-full
                h-full
                sticky
                object-cover
                rounded-md
                bg-fixed
                flex-center
                bg-gray-200
                shadow
              "
              @click="$emit('onSelect')"
            >
              <FileIcon
                :file-type="
                  file?.contentType || file?.type || file?.ContentType
                "
                class-name="w-12 h-12"
              />
            </div>

            <slot name="icon">
              <div
                v-if="file?.isNote"
                class="
                  w-4
                  h-4
                  rounded-full
                  absolute
                  right-0
                  bottom-0
                  flex
                  items-center
                  justify-center
                  shadow
                  z-10
                  cursor-default
                "
                :class="readonly ? '' : 'type-button'"
              >
                <SynIcon name="noter" is-active class="fill-orange-500" />
              </div>
              <div
                v-if="file?.type == 'text'"
                class="
                  w-6
                  h-6
                  rounded-full
                  absolute
                  -right-2
                  -top-2
                  bg-gray-100
                  flex
                  items-center
                  justify-center
                  type-button
                  shadow
                "
              >
                <SynIcon name="comment" style="fill: #128f90" />
              </div>
            </slot>

            <div
              v-if="!isHiddenClose"
              class="
                show-when-hover-button
                flex-center
                absolute
                -right-2
                -top-2
              "
            >
              <div
                v-if="!readonly && file?.type !== 'text'"
                class="w-6 h-6 rounded-full bg-red-100 flex-center z-10"
                @click="$emit('remove')"
              >
                <SynIcon
                  name="close"
                  custom-class="w-3 h-3"
                  class="text-red-500"
                />
              </div>
            </div>
          </article>
        </div>
        <!-- <div
          v-if="!isHiddenInfo"
          class="px-2 text-ellipsis  whitespace-nowrap overflow-hidden"
          :class="nameWidth"
        >
          <span class="text-xs w-full ">{{ file.name }}</span>
        </div>
        <span
          v-if="file.size > 0 && !isHiddenInfo"
          class="text-xs text-gray-400 font-semibold px-2 flex justify-end"
          :class="isActive ? 'text-orange-500' : ''"
        >
          {{ $filters.fileSize(file.size) }}
        </span> -->

        <slot></slot>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="px-3 py-2 text-xs text-gray-600 max-w-xs">
        <div v-if="file?.fileName" class="truncate">
          {{ $t('COMMON_LABEL_NAME_', { name: file?.fileName }) }}
        </div>
        <div v-if="file?.size">
          {{
            $t('COMMON_LABEL_SIZE_', { size: $filters.fileSize(file?.size) })
          }}
        </div>
        <div v-if="timeUpdate">
          {{ $t('COMMON_LABEL_DATE_UPDATED_', { date: timeUpdate }) }}
        </div>
        <div v-else-if="timeCreate">
          {{ $t('COMMON_LABEL_DATE_CREATED_', { date: timeCreate }) }}
        </div>
      </div>
    </template>
  </VigDropdown>
</template>

<style scoped>
.syn-upload-preview .remove-button {
  display: none;
}

.syn-upload-preview .show-when-hover-button {
  display: none;
}

.syn-upload-preview:hover .remove-button {
  display: flex;
}

.syn-upload-preview:hover .show-when-hover-button {
  display: flex;
}

.syn-upload-preview:hover .type-button {
  display: none;
}
</style>
