<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import GroupGridViewCard from '@/ui/modules/overview/GroupGridViewCard.vue';
// import { getCurrentUserId } from '@/ui/hooks/storageHook';
// import TaskCreateLocal from '@/ui/modules/task/create/TaskCreateLocal.vue';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import groupStore from '@/store/group';
import CreateGroupTask from '@/ui/modules/group/create/CreateGroupTask.vue';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
  // onCreateGlobalSuccess,
} from '@/ui/modules/task/task-global-state';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';

const _groupStore = groupStore();
const _overviewGroupStore = overviewGroupStore();
const isHiddenBlockPage: any = inject('IS_HIDDEN_BLOCK_PAGE');

const isLoading = computed(() => _overviewGroupStore.isLoading);

const initComponent = () => {
  _overviewGroupStore.getOverviewGroup(1);
};

// const currentUserId = computed(() => getCurrentUserId());
const groupDetail = ref({} as any);
// const taskCreateLocalRef = ref(null as any);
const onCreateTaskForGroup = async (groupId) => {
  const data = await _groupStore.getDetailGroupById(groupId);
  groupDetail.value = data;

  newTaskDefaultPayload.value = {
    groupId: groupDetail.value.id,
  };
  isOpenCreateTaskDrawer.value = true;
  // isOpenCreateTaskGlobal.value = true;
  // taskCreateLocalRef.value?.onOpenInterface();
};

// show Add group
const isAddGroup = ref(false);

const onAddGroup = () => {
  isAddGroup.value = true;
};
const onChangeSettingSuccess = () => {
  _overviewGroupStore.getOverviewGroupInfo(1, true);
};

const onCreateGroupSuccess = () => {
  _overviewGroupStore.getOverviewGroupInfo(1, true);
};
initComponent();
</script>

<template>
  <div class="flex flex-col h-full w-full relative">
    <div
      v-permission-screen="
        isHiddenBlockPage
          ? false
          : {
              screenCode: ALL_SCREENS.GROUP.OVERVIEW.OVERVIEW,
              isPage: true,
            }
      "
      class="w-full h-full"
    >
      <SynPageLoading v-if="isLoading" />
      <div v-else class="h-full w-full">
        <GroupGridViewCard
          @on-create-task-for-group="onCreateTaskForGroup"
          @on-add="onAddGroup"
          @changed-private-success="onChangeSettingSuccess"
        />
      </div>

      <!--    <TaskCreateLocal-->
      <!--      ref="taskCreateLocalRef"-->
      <!--      :is-group="groupDetail && groupDetail.id"-->
      <!--      :assignee-id="currentUserId"-->
      <!--      :group-id="groupDetail?.id"-->
      <!--      :group-domains="groupDetail?.groupDomains"-->
      <!--    />-->
    </div>
  </div>

  <CreateGroupTask
    v-if="isAddGroup"
    @cancel="isAddGroup = false"
    @create-success="onCreateGroupSuccess"
  />
</template>
