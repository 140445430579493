<template>
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 512 512"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path-->
  <!--        d="m433.798 106.268-96.423-91.222C327.119 5.343 313.695 0 299.577 0H116C85.673 0 61 24.673 61 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55V146.222c0-15.049-6.27-29.612-17.202-39.954zM404.661 120H330c-2.757 0-5-2.243-5-5V44.636zM396 482H116c-13.785 0-25-11.215-25-25V55c0-13.785 11.215-25 25-25h179v85c0 19.299 15.701 35 35 35h91v307c0 13.785-11.215 25-25 25z"-->
  <!--        opacity="1"-->
  <!--      ></path>-->
  <!--      <path-->
  <!--        d="M363 200H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15zM363 280H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15zM215.72 360H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h72.72c8.284 0 15-6.716 15-15s-6.716-15-15-15z"-->
  <!--        opacity="1"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->

  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        fill-rule="evenodd"
        d="M256 278.632a20.782 20.782 0 1 1-20.774 20.782A20.806 20.806 0 0 1 256 278.632zm-37.453 75.127a92.978 92.978 0 0 0-55.4 84.933 17.457 17.457 0 0 0 34.913 0 57.948 57.948 0 0 1 115.9 0 17.457 17.457 0 0 0 34.913 0 92.979 92.979 0 0 0-55.4-84.933l-10.23-4.529 8.563-7.2a55.69 55.69 0 1 0-71.58 0l8.558 7.2-10.226 4.527zm276.329 125.186A17.141 17.141 0 0 0 512 461.821v-240.9H0v240.9a17.143 17.143 0 0 0 17.133 17.124zM17.133 95.54h51.875v39.222a23.3 23.3 0 0 0 23.272 23.272h64.464a23.3 23.3 0 0 0 23.272-23.272V95.54h151.969v39.222a23.311 23.311 0 0 0 23.28 23.272h64.472A23.3 23.3 0 0 0 443 134.762V95.54h51.875A17.143 17.143 0 0 1 512 112.673v73.333H0v-73.333A17.146 17.146 0 0 1 17.133 95.54zm86.779-62.485v90.066h41.2V33.055zm262.985 0v90.066h41.191V33.055z"
        opacity="1"
      ></path>
    </g>
  </svg>
</template>
