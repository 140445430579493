<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { ImageDefault } from '@/ui/components/photo-upload/imageDefault';

const props = defineProps<{
  selectedAvatar: any;
  avatarsList?: any[];
  canUpload?: boolean;
  defaultImageType?: 'USER' | 'GROUP';
}>();

const emits = defineEmits(['update:selectedAvatar', 'onAvatarUpload']);

const defaultImage = ref<{ [category: string]: any }>({});

watch(
  () => props.defaultImageType,
  () => _processDefaultImage()
);

onMounted(() => {
  _processDefaultImage();
});

const onAvatarSelect = (avatar) => {
  emits('update:selectedAvatar', avatar);
};

const onAvatarUpload = (event) => {
  if (!event?.target?.files?.length) return;

  const firstFile = event?.target?.files?.item(0);
  console.log(firstFile);
  const newImage = {
    contentType: firstFile?.type,
    url: URL.createObjectURL(firstFile),
  };

  emits('onAvatarUpload', newImage);

  onAvatarSelect(newImage);

  event.target.value = null;
};

const _processDefaultImage = () => {
  defaultImage.value = props.defaultImageType
    ? ImageDefault(props.defaultImageType)
    : {};

  Object.values(defaultImage.value).forEach((category) => {
    category.objects = (category.objects || []).map((imageUrl) => ({
      url: imageUrl,
      contentType: `image/${imageUrl.split('.').pop()}`,
    }));
  });
};
</script>

<template>
  <div class="h-full py-0.5">
    <div class="flex flex-wrap gap-3">
      <!--BUTTON UPLOAD-->
      <div
        v-if="canUpload"
        :class="{
          'h-96 flex-1 flex-center flex-col gap-3': !avatarsList?.length,
        }"
      >
        <label
          v-vig-tooltip="$t('PHOTO_LABEL_UPLOAD_PHOTO')"
          role="button"
          class="
            w-16
            h-16
            rounded-full
            shadow
            flex-center
            bg-current-50
            hover:bg-current-100
          "
        >
          <SynIcon name="Plus" class="fill-current" />
          <input
            type="file"
            class="hidden"
            accept="image/*"
            @change="onAvatarUpload"
          />
        </label>
        <div v-if="!avatarsList?.length" class="flex-center flex-col">
          <SynIcon
            name="ArrowCurvedUp"
            custom-class="w-16 h-16"
            class="fill-gray-500 mr-4"
          />
          <div>{{ $t('PHOTO_LABEL_LABEL_ICON_UPLOAD_PHOTO') }}</div>
        </div>
      </div>

      <!--CURRENT & UPLOADED IMAGES-->
      <div
        v-for="avatar in avatarsList"
        :key="avatar"
        role="button"
        class="w-16 h-16 rounded-full overflow-hidden relative flex-center"
        :class="{
          'ring-2 ring-current': selectedAvatar?.url === avatar?.url,
        }"
        @click="onAvatarSelect(avatar)"
      >
        <img :src="avatar?.url" alt="" class="w-full h-full object-cover" />
        <div
          v-if="selectedAvatar?.url === avatar?.url"
          class="
            absolute
            w-full
            h-full
            flex-center
            bg-current-800 bg-opacity-40
          "
        >
          <SynIcon name="Check" custom-class="w-8 h-8 text-white" />
        </div>
      </div>
    </div>

    <!--DEFAULT IMAGES-->
    <div v-for="category in defaultImage" :key="category?.key">
      <div class="py-1 border-b font-medium text-xs mb-2">
        {{ category?.key }}
      </div>
      <div class="flex flex-wrap gap-3">
        <div
          v-for="image in category?.objects"
          :key="image"
          role="button"
          class="w-16 h-16 rounded-full overflow-hidden relative flex-center"
          :class="{
            'ring-2 ring-current': selectedAvatar?.url === image?.url,
          }"
          @click="onAvatarSelect(image)"
        >
          <img :src="image?.url" alt="" class="w-full h-full object-cover" />
          <div
            v-if="selectedAvatar?.url === image?.url"
            class="
              absolute
              w-full
              h-full
              flex-center
              bg-current-800 bg-opacity-40
            "
          >
            <SynIcon name="Check" custom-class="w-8 h-8 text-white" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
