<script setup lang="ts">
import { isValidTaskCode } from '@/ui/hooks/taskHook';
import { computed, onMounted, ref } from 'vue';
import {
  openNotification,
  AlertType,
  NotificationTypeEnum,
} from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import taskDetailStore from '@/store/task/detail';
import taskStore from '@/store/task';
import { attachmentToTaskGlobal } from '@/ui/modules/task/task-global-state';
// import TaskCard from '@/ui/modules/overview/TaskCard.vue';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import myProfileStore from '@/store/auth/my-profile';
import { ITaskTodoList } from '@/application/types/task/task.types';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';

defineEmits(['close']);

const myProfile = computed(() => myProfileStore().myProfile);
const _taskStore = taskStore();
const _taskDetailStore = taskDetailStore();
const taskCodeSearch = ref('');
const errorCode = ref('');

const currentCycle = computed(() => _taskStore.currentCycle);
const codePrefix = computed(() =>
  currentCycle.value?.currentCycle
    ? `${currentCycle.value?.currentCycle}-${currentCycle.value?.letter.trim()}`
    : ''
);
const addTabRef = ref(null as any);
const initData = () => {
  taskCodeSearch.value = codePrefix.value;
};
onMounted(() => {
  addTabRef.value?.focus();
});
const isGroupTask = computed(
  () => taskDetail.value.groupId && taskDetail.value.groupId > 0
);
const isGroupMember = computed(() => taskDetail.value?.isGroupMember);
const hasPermission = computed(() => {
  return isGroupTask.value ? isGroupMember.value : true;
});

const taskDetail = ref({} as any);
const onChange = () => {
  errorCode.value = '';
};
const onSearch = async () => {
  if (!isValidTaskCode(taskCodeSearch.value)) {
    openNotification({
      type: AlertType.warning,
      body: translate('TASK_LABEL_CODE_INVALID') || 'Task code invalid',
      duration: 2000,
    });
    return;
  }
  try {
    taskDetail.value = await _taskDetailStore.getDetailByCode(
      taskCodeSearch.value
    );
  } catch (error: any) {
    const code = error?.error?.code;
    errorCode.value = code
      ? `COMMON_ERROR_BY_CODE_${code}`
      : 'COMMON_ERROR_BY_CODE_6403';
  }
};

const onClose = () => {
  attachmentToTaskGlobal.value = {
    isOpen: false,
  };
};

const isMoving = ref(false);

const onMoveToTask = async () => {
  try {
    if (!taskDetail.value?.id || !hasPermission.value || isMoving.value) return;
    const existedTask = new TaskDetailClass(taskDetail.value);
    isMoving.value = true;
    if (
      attachmentToTaskGlobal.value?.attachments &&
      attachmentToTaskGlobal.value?.attachments?.length > 0
    ) {
      await existedTask.addFiles(
        attachmentToTaskGlobal.value?.attachments || []
      );
    }

    if (
      attachmentToTaskGlobal.value?.checklist &&
      attachmentToTaskGlobal.value?.checklist?.length > 0
    ) {
      const newPayload: ITaskTodoList = {
        taskId: taskDetail.value?.id,
        taskTodos: attachmentToTaskGlobal.value?.checklist,
      };

      await _taskDetailStore.createOrUpdateTaskCheckList(newPayload);
    }

    if (attachmentToTaskGlobal.value?.description) {
      const newDescription = [
        existedTask.description || '',
        attachmentToTaskGlobal.value?.description,
      ].join('');

      await existedTask.changeDescription(newDescription);
    }

    isMoving.value = true;
    openNotification({
      metaData: {
        hasAction: true,
        isOpenTaskDetail: true,
        taskCode: existedTask.code,
      },
      notificationValue: {
        ...taskDetail.value,
        actionType: '',
        type: 'task',
        task_name: existedTask.name,
        task_code: existedTask.code,
        isPlannedToTreat: existedTask.taskSchedule.doItToday,
        isPlannedToFinish: existedTask.taskSchedule.finishItToday,
        executor_name: myProfile.value?.firstName,
        icon: myProfile.value?.avatarUrl,
      },
      notificationType: NotificationTypeEnum.task,
    });

    onClose();
  } catch (error: any) {
    const code = error?.error?.code;
    errorCode.value = code
      ? `COMMON_ERROR_BY_CODE_${code}`
      : 'COMMON_ERROR_BY_CODE_6403';
  }
};
initData();
</script>

<template>
  <syn-modal
    style-body="pl-2 py-3"
    container-class="w-2/5"
    container-style="min-width: 40rem; max-width: 1200px"
    disable-click-outside
    @cancel="onClose"
  >
    <template #header>
      <div>
        <span>{{ $t('COMMON_LABEL_GED_MOVE_FILE_TO_TASK') }}</span>
      </div>
    </template>
    <template #body>
      <div class="flex-1 min-h-0 flex flex-col space-y-2 items-center">
        <div class="flex-center">
          <span>
            {{
              $t('TASK_PLEASE_INPUT_TASK_CODE') || 'Please enter a task code'
            }}
          </span>
        </div>
        <div class="flex-center space-x-2">
          <input
            ref="addTabRef"
            v-model="taskCodeSearch"
            v-cusFocus="true"
            placeholder="#code"
            class="
              h-full
              w-20
              outline-none
              p-1
              ring-1
              focus:ring-current
              rounded
            "
            @keyup.enter="onSearch"
            @update:model-value="onChange"
          />

          <SynButton custom-class="h-8 rounded" @click="onSearch">
            <span>{{ $t('COMMON_LABEL_SEARCH') }}</span>
          </SynButton>
        </div>

        <div v-if="taskDetail?.id" class="flex flex-col">
          <div class="flex-center">
            <span v-if="hasPermission" class="text-current italic">
              {{ $t('TASK_PLEASE_SELECT_TO_MOVE_FILE_TO_THIS_TASK') }}
            </span>
            <span v-else class="text-red-500 italic">
              {{ $t('CHAT_YOU_DO_NOT_PERMISSION_TO_UPDATE_THIS_TASK') }}
            </span>
          </div>
          <div
            class="
              m-2
              bg-white
              border border-current
              rounded
              shadow-sm
              w-96
              relative
            "
            :class="hasPermission ? 'cursor-pointer' : 'cursor-not-allowed'"
          >
            <SynLocalLoading v-if="isMoving" />
            <TaskCardItem
              :task-detail="taskDetail"
              class="rounded"
              readonly
              disabled-open-drawer
              @on-click-detail="onMoveToTask"
            />
          </div>
        </div>
        <div
          v-else-if="errorCode"
          class="flex-center flex-1 min-h-0 w-full bg-white"
          style="max-width: 50rem"
        >
          <div class="py-6 px-4 bg-opacity-40 rounded-xl flex flex-col">
            <syn-animation
              name="searchNotFound"
              stype="width: 150px; height:150px"
            />
            <div class="flex flex-col space-y-4 pt-8 text-center">
              <span
                class="font-semibold text-2xl text-current-500"
                v-html="
                  $t(errorCode, {
                    code: formatTaskCode(taskCodeSearch) || '',
                  }) || 'Something wrong'
                "
              >
              </span>
              <span class="text-gray-500">
                {{
                  $t('COMMON_LABEL_PAGE_NOT_FOUND_BODY', { name: 'task' }) ||
                  'We are sorry.'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </syn-modal>
</template>
