<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import TabTaskTag from '@/ui/modules/task/components/tabs/TabTaskTag.vue';
import taskListStore from '@/store/task/task-list-store';
import {
  EFilterTaskDefault,
  FILTER_APPROVAL_TASK_LIST,
} from '@/application/constants/task.const';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import domainStore from '@/store/scope';
import groupStore from '@/store/group';
import userStore from '@/store/user';
// import { getCurrentUserId } from '@/ui/hooks/storageHook';

const props = defineProps<{
  type: 'MEMBER' | 'GROUP' | 'DOMAIN';
  listTaskOwnerId: number;
  isShowAll: boolean;
}>();
const emits = defineEmits(['onChangeTab']);

const _taskTabViewStore = taskTabViewStore();
const _taskListStore = taskListStore();
// const isMyTaskPage = computed(
//   () => props.listTaskOwnerId == getCurrentUserId()
// );

// const activeTab = computed(() => _taskListStore.activeTab);
const approvalTaskSummaryStatistics = computed<{
  totalAll: number;
  totalApproval: number;
  totalApproved: number;
  totalRequesting: number;
}>(() => _taskListStore.approvalTaskSummaryStatistics);

const listTaskOwner = computed<any>(() => {
  if (props.type == 'DOMAIN') {
    const domainByIds = domainStore().domainByIds;
    return domainByIds[props.listTaskOwnerId];
  }
  if (props.type == 'GROUP') {
    const allGroupByIds = {
      ...groupStore().allGroupByIds,
      ...groupStore().myGroupByIds,
    };
    return allGroupByIds[props.listTaskOwnerId];
  }
  return userStore().allUserByIds[props.listTaskOwnerId];
});
const containerListTabs = ref(null as any);
const currentTabId = computed<string>(() => _taskTabViewStore.currentTabId);
const listTabs = computed(() =>
  Object.values(FILTER_APPROVAL_TASK_LIST).map((tab) => {
    switch (tab?.id) {
      case EFilterTaskDefault.DEFAULT:
        return {
          ...tab,
          totalTask: approvalTaskSummaryStatistics.value?.totalRequesting,
        };
      case EFilterTaskDefault.NEED_APPROVAL:
        return {
          ...tab,
          totalTask: approvalTaskSummaryStatistics.value?.totalApproval,
        };
      case EFilterTaskDefault.APPROVED:
        return {
          ...tab,
          totalTask: approvalTaskSummaryStatistics.value?.totalApproved,
        };
      default:
        return { ...tab };
    }
  })
);

onMounted(async () => {
  await nextTick();

  const cacheMomentShowTabIds = getLocalStorage(
    StorageConstant.TASK_LIST_MOMENT_SHOW_TAB
  );

  momentShowTabIds.value = cacheMomentShowTabIds
    ?.split(',')
    ?.filter((id) => id?.trim());

  const container = document.getElementById('filterTabsId') as any;
  if (containerListTabs.value?.offsetWidth > (container.offsetWidth * 2) / 3) {
    // console.log('containerListTabs', containerListTabs.value?.offsetWidth);
    // console.log(
    //   'filterTabsId',
    //   document.getElementById('filterTabsId')?.offsetWidth
    // );
  }
});

const onChangeTab = (tab) => {
  // console.log('tab', tab);
  _taskListStore.filteredTasks = [];
  _taskTabViewStore.changeTabActive(tab?.id);
  emits('onChangeTab', tab);
};

const momentShowTabIds = ref<string[]>([]);

const totalFilteredTasks = computed(
  () => _taskListStore.filteredTasks?.length || 0
);

const isLoading = computed(() => _taskListStore.isLoading);
const countTotalTasks = (tab): string => {
  const totalDefault = tab?.totalTask;
  if (tab?.id?.toString() !== currentTabId.value || isLoading.value)
    return totalDefault && totalDefault > 0 ? `${totalDefault}` : '';

  return totalFilteredTasks.value > 0 && totalFilteredTasks.value < totalDefault
    ? `${totalFilteredTasks.value}/${totalDefault}`
    : totalDefault
    ? `${totalDefault}`
    : '';
};
</script>

<template>
  <div ref="containerListTabs" class="flex items-center space-x-1 text-xs">
    <template v-for="tab in listTabs?.filter((o) => o.visible)" :key="tab?.key">
      <TabTaskTag
        :is-active="currentTabId === tab?.id"
        :color="tab.color"
        :name="
          $t(tab?.labelKey, {
            username: listTaskOwner?.isMe
              ? $t('COMMON_LABEL_ME')
              : listTaskOwner?.firstName,
          })
        "
        :total="countTotalTasks(tab)"
        :readonly="tab?.isDefault"
        :tab-detail="tab"
        @on-select-tab="onChangeTab(tab)"
      />
    </template>
  </div>
</template>
