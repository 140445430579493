<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
// import { v4 as uuidv4 } from 'uuid';
import organizationStore from '@/store/organization';
import myProfileStore from '@/store/auth/my-profile';
import {
  EOrganizationModel,
  IOrganizationInfoPayload,
} from '@/application/types/organization/organization.type';
import { dataByOrganizationModel } from '@/application/constants/register.const';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import RegisterSelectAccountType from '@/ui/modules/organization/setup/RegisterSelectAccountType.vue';
import RegisterDefineOrganization from '@/ui/modules/organization/setup/RegisterDefineOrganization.vue';
import RegisterCompleteOrganizationDetail from '@/ui/modules/organization/setup/RegisterCompleteOrganizationDetail.vue';
import RegisterCompletePersonalDetail from '@/ui/modules/organization/setup/RegisterCompletePersonalDetail.vue';
import appStore from '@/store/app';

const props = defineProps<{
  doNotNeedToCheckPersonal: boolean;
  typeKey: 'workspace_type_select' | 'workspace_type_select_register';
  notAllowPersonal?: boolean;
}>();
const emit = defineEmits(['onCancel', 'onFinish']);
const _organizationStore = organizationStore();

const isCreateFetching = ref(false);

const infoPayload = ref<IOrganizationInfoPayload>({
  name: '',
  logo: '',
  model: EOrganizationModel.PERSONAL,
  type: '',
  size: '',
  address: '',
  addressDetail: '',
  phone: '',
  websiteUrl: '',
  taxCode: '',
  subType: '',
  personalType: props.notAllowPersonal ? 1 : undefined,
  knowAppFrom: undefined,
});
// const currentOrganizationInfo = computed<any>(
//   () => _organizationStore.organizationInfo
// );
// const isShowFullSize = ref<boolean>(false);

enum REGISTER_STEP_KEYS {
  FIRST_STEP = 'FIRST_STEP',
  ORGANIZATION_SETUP = 'ORGANIZATION_SETUP',
  ORGANIZATION_FINISH = 'ORGANIZATION_FINISH',
  PERSONAL_SETUP = 'PERSONAL_SETUP',
}

const isShowError = ref<boolean>(false);

const {
  currentStep,
  stepperByKey,
  updateCurrentStep,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    [REGISTER_STEP_KEYS.FIRST_STEP]: {
      key: REGISTER_STEP_KEYS.FIRST_STEP,
      previous: '',
      previousAction: () => {},
      nextAction: () => {
        if (infoPayload.value.personalType == 1) {
          infoPayload.value.model = EOrganizationModel.COMPANY;

          updateCurrentStep(REGISTER_STEP_KEYS.ORGANIZATION_SETUP);
        } else {
          infoPayload.value.model = EOrganizationModel.PERSONAL;

          updateCurrentStep(REGISTER_STEP_KEYS.PERSONAL_SETUP);
        }
      },
      buttonBack: '',
      buttonNext: 'COMMON_LABEL_NEXT',
    },
    [REGISTER_STEP_KEYS.ORGANIZATION_SETUP]: {
      key: REGISTER_STEP_KEYS.ORGANIZATION_SETUP,
      previous: REGISTER_STEP_KEYS.FIRST_STEP,
      next: REGISTER_STEP_KEYS.ORGANIZATION_FINISH,
      previousAction: () => {},
      nextAction: () => {},
      buttonBack: props.notAllowPersonal ? '' : 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_NEXT',
    },
    [REGISTER_STEP_KEYS.ORGANIZATION_FINISH]: {
      key: REGISTER_STEP_KEYS.ORGANIZATION_FINISH,
      previous: REGISTER_STEP_KEYS.ORGANIZATION_SETUP,
      previousAction: () => {
        isCreateFetching.value = false;
      },
      nextAction: () => {
        if (!infoPayload.value.model) {
          isShowError.value = true;
          return;
        }
        onSubmit();
      },
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_FINISH',
    },
    [REGISTER_STEP_KEYS.PERSONAL_SETUP]: {
      key: REGISTER_STEP_KEYS.PERSONAL_SETUP,
      previous: REGISTER_STEP_KEYS.FIRST_STEP,
      previousAction: () => {
        isCreateFetching.value = false;
        isShowError.value = false;
      },
      nextAction: () => {
        onSubmit();
      },
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_FINISH',
    },
  },
  REGISTER_STEP_KEYS.FIRST_STEP
);

const checkToNextStepInitial = () => {
  if (props.notAllowPersonal) {
    infoPayload.value.personalType = 1;
    onHandleGoToNextStep();
  }
};
onBeforeMount(() => {
  checkToNextStepInitial();
});

const isDisableNextButton = computed(() => {
  if (currentStep.value == REGISTER_STEP_KEYS.FIRST_STEP) {
    return !infoPayload.value.personalType;
  }
  if (currentStep.value == REGISTER_STEP_KEYS.ORGANIZATION_SETUP) {
    return !infoPayload.value?.category || !infoPayload.value?.size;
  }
  if (currentStep.value == REGISTER_STEP_KEYS.ORGANIZATION_FINISH) {
    return !infoPayload.value.name?.trim();
  }
  if (currentStep.value == REGISTER_STEP_KEYS.PERSONAL_SETUP) {
    return false;
  }

  return false;
});
const defaultNameByLang = {
  en: 'My workspace',
  fr: 'Espace de travail personnel',
  vi: 'Không gian cá nhân',
};
const appLanguage = computed(() => appStore().language);
const onSubmit = async () => {
  const currentLanguage = appLanguage.value || 'en';
  isCreateFetching.value = true;
  const data = {
    ...infoPayload.value,
    name: infoPayload.value?.name || defaultNameByLang[currentLanguage],
    model: infoPayload.value?.model || EOrganizationModel.PERSONAL,
    category: infoPayload.value?.subCategory
      ? infoPayload.value?.subCategory
      : infoPayload.value?.category,
    type: infoPayload.value?.type || 'OTHER',
  };

  _organizationStore.updateDecideTime(13);
  await _organizationStore.updateAndFinishSetupOrganization(data);
  myProfileStore().fetchCurrentLoginUser();
  isCreateFetching.value = false;
  emit('onFinish', data);
};
</script>

<template>
  <teleport to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
        z-50
      "
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div
        class="
          p-8
          mx-auto
          border-current
          flex
          items-center
          flex-col
          gap-8
          justify-between
          bg-white
          rounded-2xl
          relative
        "
        style="max-width: 50rem"
      >
        <div class="flex-1 flex flex-col space-y-6 items-center text-gray-800">
          <!-- Body -->
          <div
            class="absolute -top-10 w-20 h-20 z-60 flex-center rounded-full"
            :style="{
              background:
                dataByOrganizationModel[infoPayload?.model]?.background,
            }"
          >
            <SynIcon
              :name="
                currentStep == REGISTER_STEP_KEYS.FIRST_STEP
                  ? 'workspace'
                  : infoPayload?.model?.toLowerCase()
              "
              custom-class="w-12 h-12 fill-white"
            />
          </div>
          <template v-if="currentStep == REGISTER_STEP_KEYS.FIRST_STEP">
            <!-- REGISTER_STEP_KEYS.FIRST_STEP -->
            <RegisterSelectAccountType v-model="infoPayload.personalType" />
          </template>
          <template
            v-else-if="currentStep == REGISTER_STEP_KEYS.ORGANIZATION_SETUP"
          >
            <!-- REGISTER_STEP_KEYS.ORGANIZATION_SETUP -->
            <div class="w-[40rem]">
              <RegisterDefineOrganization
                v-model:size="infoPayload.size"
                v-model:category="infoPayload.category"
                v-model:sub-category="infoPayload.subCategory"
                v-model:type="infoPayload.type"
                v-model:domainName="infoPayload.domainName"
                :organization-model="infoPayload.model"
              />
            </div>
          </template>
          <template
            v-if="currentStep == REGISTER_STEP_KEYS.ORGANIZATION_FINISH"
          >
            <!-- REGISTER_STEP_KEYS.FIRST_STEP -->
            <RegisterCompleteOrganizationDetail
              v-model:model="infoPayload.model"
              v-model:name="infoPayload.name"
              v-model:address="infoPayload.address"
              v-model:addressDetail="infoPayload.addressDetail"
              v-model:type="infoPayload.type"
              v-model:domainName="infoPayload.domainName"
              v-model:websiteUrl="infoPayload.websiteUrl"
              v-model:phone="infoPayload.phone"
              :is-show-error-company-model="isShowError"
              @update:model="isShowError = false"
            />
          </template>
          <template
            v-else-if="currentStep == REGISTER_STEP_KEYS.PERSONAL_SETUP"
          >
            <RegisterCompletePersonalDetail
              v-model:knowAppFrom="infoPayload.knowAppFrom"
              v-model:address="infoPayload.address"
            />
          </template>
        </div>

        <div class="w-full flex justify-between items-center space-x-2">
          <div class="flex items-center gap-4 relative"></div>
          <div class="flex-center gap-2">
            <AtomButton
              v-if="stepperByKey[currentStep]['buttonBack']"
              type="TEXT"
              color="gray"
              :label="$t(stepperByKey[currentStep]['buttonBack'])"
              @click="onHandleGoToPreviousStep"
            />
            <AtomButton
              :label="$t(stepperByKey[currentStep]['buttonNext'])"
              :disabled="isDisableNextButton"
              :is-loading="isCreateFetching"
              @click="onHandleGoToNextStep"
            />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
