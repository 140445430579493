import { DayoffExceptionType, DayoffStatus } from '@/domain/enums/DayoffEnums';
export interface IDayOffCreateDefaultData {
    startDate: any;
}

export enum ECalendarFromMessageCategory {
    DAY_OFF = 'DAY_OFF',
    EVENT = 'EVENT',
    REMINDER = 'REMINDER',
}

export interface IDayOffFromMessage {
    id: string;
    category: ECalendarFromMessageCategory;
    userId: number;
    startDate: number;
    endDate: number;
    startDateSession: number;
    endDateSession: number;
    requestDate: number;
    requestDay: number;
    usedDay: number;
    type: string;
    requestReason: string;
    requestNote: string;
    exception: null;
    createdDate: number;
    createdBy: number;
}

export interface IEventFromMessage {
    id: string;
    category: ECalendarFromMessageCategory;
    allDay: boolean;
    assigneeId: number;
    meetingType: number;
    notificationType: string;
    repeatType: null;
    calendarId: number;
    sourceId: string;
    sourceType: number;
    title: string;
    description: string;
    type: number;
    startDate: string;
    endDate: string;
    createdDate: string;
    createdBy: number;
}

// Generated by https://quicktype.io

export interface IDayOffDetail {
    id: string;
    organizationId: number;
    userId?: number;
    year?: number;
    month?: number;
    startDate: number;
    endDate: number;
    startDateSession: number;
    endDateSession: number;
    requestDate: number;
    requestDay: number;
    usedDay: number;
    type: string;
    status: DayoffStatus;
    currentStatusRequest?: DayoffStatus;
    previousStatus?: DayoffStatus;
    requestReason: string;
    requestNote: string;
    rejectReason: string;
    rejectNote: string;
    dateOffs?: IDateOff[];
    exception?: IDayOffException;
    requestFlow?: IRequestFlow[];
    isDeleted?: boolean;
    createdDate: number;
    createdBy: number;
    updatedDate?: number;
    updatedBy?: number;
    showMarkUnUseAction?: boolean;
    showCloseAction?: boolean;
    changeParser?: null;
}

export interface IDateOff {
    date: number;
    daySession: number;
    dayAmount: number;
    status: string;
}

export interface IRequestFlow {
    status: string;
    actionType: string;
    actionBy: number;
    actionTime: number;
}

// Generated by https://quicktype.io

export interface IDayOffException {
    type: DayoffExceptionType;
    daySession: number;
    startTime: number;
    endTime: number;
}
