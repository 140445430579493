<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m27 30h-10a3.0033 3.0033 0 0 1 -3-3v-10a3.0033 3.0033 0 0 1 3-3h10a3.0033 3.0033 0 0 1 3 3v10a3.0033 3.0033 0 0 1 -3 3zm-10-14a1.0009 1.0009 0 0 0 -1 1v10a1.0009 1.0009 0 0 0 1 1h10a1.0009 1.0009 0 0 0 1-1v-10a1.0009 1.0009 0 0 0 -1-1z"
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m47 30h-10a3.0033 3.0033 0 0 1 -3-3v-10a3.0033 3.0033 0 0 1 3-3h10a3.0033 3.0033 0 0 1 3 3v10a3.0033 3.0033 0 0 1 -3 3zm-10-14a1.0009 1.0009 0 0 0 -1 1v10a1.0009 1.0009 0 0 0 1 1h10a1.0009 1.0009 0 0 0 1-1v-10a1.0009 1.0009 0 0 0 -1-1z"
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m27 50h-10a3.0033 3.0033 0 0 1 -3-3v-10a3.0033 3.0033 0 0 1 3-3h10a3.0033 3.0033 0 0 1 3 3v10a3.0033 3.0033 0 0 1 -3 3zm-10-14a1.0009 1.0009 0 0 0 -1 1v10a1.0009 1.0009 0 0 0 1 1h10a1.0009 1.0009 0 0 0 1-1v-10a1.0009 1.0009 0 0 0 -1-1z"
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m42 50a1 1 0 0 1 -1-1v-14a1 1 0 0 1 2 0v14a1 1 0 0 1 -1 1z"
        class=""
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m49 43h-14a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2z"
        class=""
      />
    </g>
  </svg>
</template>
