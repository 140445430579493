<script setup lang="ts">
import { computed, onMounted, watch } from 'vue';
import fileStorageStore from '@/store/fileStorage';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

import VigProgress from '@/ui/common/atoms/VigProgress/VigProgress.vue';
import {
  NotificationPositionType,
  openNotification,
} from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  files: any[];
  location: any;
}>();

const emits = defineEmits(['onClose']);

const _organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
const _fileStorageStore = fileStorageStore();

const uploadProcess = computed(() => ({
  ..._fileStorageStore.uploadProcess,
  uploadedPercent: Math.round(
    (_fileStorageStore.uploadProcess?.totalUploaded /
      _fileStorageStore.uploadProcess?.totalFiles) *
      100
  ),
}));

watch(
  () => uploadProcess.value?.uploadedPercent,
  (uploadedPercent) => {
    if (uploadedPercent === 100) {
      setTimeout(() => {
        openNotification({
          position: NotificationPositionType.TopRight,
          body: translate('GED_UPLOADED_SUCCESS_MSG', {
            files: `${uploadProcess.value?.totalUploaded}/${uploadProcess.value?.totalFiles}`,
          }),
        });

        emits('onClose');
      }, 500);
    }
  }
);

onMounted(() => {
  _fileStorageStore.uploadFiles(
    parseInt(_organizationId),
    props.files,
    props.location
  );
});
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    :is-hidden-header="true"
    :is-hidden-footer="true"
    :is-hidden-close="true"
  >
    <template #body>
      <div class="h-max p-10 flex-center flex-col">
        <div class="flex flex-col items-center w-full">
          <template
            v-if="uploadProcess?.totalUploaded === uploadProcess?.totalFiles"
          >
            <SynAnimation
              v-if="uploadProcess?.isProcessing"
              name="loadingdot"
              stype="width: 150px; height: 150px;"
            />
            <SynAnimation
              v-else
              name="success"
              stype="width: 150px; height: 150px;"
            />
          </template>

          <SynAnimation
            v-else
            name="uploadingFiles"
            stype="width: 200px; height: 200px;"
          />

          <div class="flex-center mb-6 text-lg">
            <span
              v-if="uploadProcess?.totalUploaded !== uploadProcess?.totalFiles"
            >
              {{ $t('GED_UPLOADING') || 'Uploading' }}
            </span>
            <span v-else>{{ $t('GED_UPLOADED') || 'Uploaded' }}</span>
            <span class="font-bold text-current-500 ml-2">
              {{ uploadProcess?.totalUploaded }}/{{ uploadProcess?.totalFiles }}
            </span>
          </div>
        </div>

        <VigProgress
          :value="uploadProcess?.uploadedPercent"
          class="mb-5 w-full"
        />

        <!--        <div class="flex justify-end items-center w-full pt-6">-->
        <!--          <SynButton-->
        <!--            :label="$t('COMMON_LABEL_FINISH') || 'Finish'"-->
        <!--            :disabled="-->
        <!--              uploadProcess?.totalUploaded !== uploadProcess?.totalFiles ||-->
        <!--              uploadProcess?.isProcessing-->
        <!--            "-->
        <!--            @click="$emit('onClose')"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </template>
  </SynModal>
</template>
