<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: string | number | boolean;
    keyField?: string;
    textField?: string;
    options: any[];
    isNeedTranslate?: boolean;
  }>(),
  {
    keyField: 'id',
    textField: 'name',
    isNeedTranslate: false,
  }
);

const emit = defineEmits(['update:modelValue', 'change']);

const onChange = (value) => {
  emit('update:modelValue', value);
  emit('change', value);
};
</script>

<template>
  <div
    class="
      rounded-full
      flex
      items-center
      justify-between
      transition
      duration-700
      bg-gray-200
    "
  >
    <div
      v-for="option in options"
      :key="option[keyField]"
      class="flex-center rounded-full cursor-pointer relative"
      @click="onChange(option[keyField])"
    >
      <span
        :class="[
          'truncate',
          modelValue == option[keyField]
            ? 'bg-current text-white shadow-xl rounded-full px-8 py-3  flex-center'
            : 'text-gray-600 px-3 py-1 ',
        ]"
      >
        <slot name="label" :item="option">
          {{ isNeedTranslate ? $t(option[textField]) : option[textField] }}
        </slot>
      </span>
      <slot
        :key="option[keyField]"
        name="suffix"
        :active="modelValue == option[keyField]"
      ></slot>
    </div>
  </div>
</template>
