export const bucketTictop = {
    SourceDefault:
        'https://s3.ap-southeast-1.amazonaws.com/tictop.source-default/web',
    AttachmentFile:
        import.meta.env.VITE_AWS_S3_BUCKET_TASK_ATTACHMENT_FILE ||
        'tictop.task-attachment-file',
    AttachmentMedia:
        import.meta.env.VITE_AWS_S3_BUCKET_TASK_ATTACHMENT_MEDIA ||
        'tictop.task-attachment-media',
    AttachmentPhoto:
        import.meta.env.VITE_AWS_S3_BUCKET_TASK_ATTACHMENT_PHOTO ||
        'tictop.task-attachment-photo',
    CalendarAttachmentFile:
        import.meta.env.VITE_AWS_S3_BUCKET_CALENDAR_ATTACHMENT_FILE ||
        'tictop.calendar-attachment-file',
    CalendarAttachmentMedia:
        import.meta.env.VITE_AWS_S3_BUCKET_CALENDAR_ATTACHMENT_MEDIA ||
        'tictop.calendar-attachment-media',
    CalendarAttachmentPhoto:
        import.meta.env.VITE_AWS_S3_BUCKET_CALENDAR_ATTACHMENT_PHOTO ||
        'tictop.calendar-attachment-photo',
    UserAvatar:
        import.meta.env.VITE_AWS_S3_BUCKET_USER_AVATAR || 'tictop.user-avatar',
    StorageFile:
        import.meta.env.VITE_AWS_S3_BUCKET_STORAGE_FILE ||
        'tictop.storage-file',
    StorageMedia:
        import.meta.env.VITE_AWS_S3_BUCKET_STORAGE_MEDIA ||
        'tictop.storage-media',
    StoragePhoto:
        import.meta.env.VITE_AWS_S3_BUCKET_STORAGE_PHOTO ||
        'tictop.storage-photo',
    MessagingFile:
        import.meta.env.VITE_AWS_S3_BUCKET_MESSAGING_FILE ||
        'tictop.messaging-file',
    MessagingMedia:
        import.meta.env.VITE_AWS_S3_BUCKET_MESSAGING_MEDIA ||
        'tictop.messaging-media',
    MessagingPhoto:
        import.meta.env.VITE_AWS_S3_BUCKET_MESSAGING_PHOTO ||
        'tictop.messaging-photo',
};

export const CDN_LINK_BY_BUCKET = {
    TICTOP_TASK_ATTACHMENT_PHOTO: 'https://d2r345m2zsc00i.cloudfront.net',
    TICTOP_MESSAGING_PHOTO: 'https://d11b80wezx96wl.cloudfront.net',
    TICTOP_TASK_ATTACHMENT_FILE: 'https://d2egm2hkjc3gu1.cloudfront.net',
    TICTOP_USER_AVATAR: 'https://d353sipp6elw65.cloudfront.net',
    TICTOP_TASK_ATTACHMENT_MEDIA: 'https://d1gwhdtl7sl1yr.cloudfront.net',
    TICTOP_SOURCE_DEFAULT: 'https://d1tvqetrcurhzb.cloudfront.net',
    TICTOP_STORAGE_PHOTO: 'https://d1wt5fajuj5d1j.cloudfront.net',
    TICTOP_STORAGE_MEDIA: 'https://d1afwtdnq7wrem.cloudfront.net',
    TICTOP_STORAGE_FILE: 'https://dmops4z5tql4w.cloudfront.net',
    TICTOP_MESSAGING_FILE: 'https://drq6jfvavpejx.cloudfront.net',
    TICTOP_MESSAGING_MEDIA: 'https://d2vn6kmcyhllx5.cloudfront.net',
    TICTOP_CALENDAR_ATTACHMENT_PHOTO: 'https://d2kghll0nzhvnk.cloudfront.net',
    TICTOP_CALENDAR_ATTACHMENT_MEDIA: 'https://d16ayq402twxzl.cloudfront.net',
    TICTOP_CALENDAR_ATTACHMENT_FILE: 'https://d3qboi3pkfdhe3.cloudfront.net',
};
