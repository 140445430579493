<script setup lang="ts">
import { computed, markRaw, onMounted, ref } from 'vue';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';

import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';
import Enter from '@ckeditor/ckeditor5-enter/src/enter';
import SelectAll from '@ckeditor/ckeditor5-select-all/src/selectall';
import Typing from '@ckeditor/ckeditor5-typing/src/typing';
import Undo from '@ckeditor/ckeditor5-undo/src/undo';
import ShiftEnterPlugin from '@/ui/plugins/ckeditor/enter/shift-enter-plugin';
import DeletePlugin from '@ckeditor/ckeditor5-typing/src/delete';
import AutoLinkPlugin from '@ckeditor/ckeditor5-link/src/autolink';
import MentionPlugin from '@ckeditor/ckeditor5-mention/src/mention';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';
// New
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import List from '@ckeditor/ckeditor5-list/src/list';

import AutoformatPlugin from '@/ui/plugins/ckeditor/autoformat/autoformat';
import PasteLinkPlugin from '@/ui/plugins/ckeditor/paste-link/paste-link';
import CustomLinkTaskTargetPlugin from '@/ui/plugins/ckeditor/autoformat/customlinktask';
import CustomLinkMentionPlugin from '@/ui/plugins/ckeditor/mentions/customlinkmention';
// import { renderItemListMentionChat } from '@/ui/modules/messaging/chat-panel/chat-form/chat-input/chat-item-mention/RenderComponentChat.js';
import taskStore from '@/store/task';
// import chatStore from '@/store/chat';
import { translate } from '@/ui/plugins/i18n/myi18n';
// import { ignoreUnicode } from '@/ui/plugins/utils';
import { getDomainUrl } from '@/ui/hooks/commonHook';
import remoteConfigStore from '@/store/remoteConfig';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import CkeditorCustomAction from '@/ui/common/plugins/ckeditor/CkeditorCustomAction.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import attachmentFilesComposables from '@/ui/composables/task/attachments/attachment-files-composables';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import { IUploadPreviewPropFile } from '@/ui/components/file-viewer/update-preview-type';
import ModalFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalFileViewer.vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { renderItemListMentionChat } from '@/ui/modules/messaging/chat-panel/chat-form/chat-input/chat-item-mention/RenderComponentChat.js';
import taskCommentStore from '@/store/task/task-comment';

const props = withDefaults(
  defineProps<{
    currentTask: any;
    modelValue: string;
    isValid?: boolean;
    attachments?: any[];
    mentionUserIds?: any[];
    placeholder?: string;
    maxLength?: number;
    isAction?: boolean;
    hasMoreActions?: boolean;
    isHiddenMaxLength?: boolean;
    customClass?: string;
    mentionGroup?: boolean;
  }>(),
  {
    isValid: true,
    attachments: () => [],
    mentionUserIds: () => [],
    placeholder: '',
    maxLength: 2500,
    isAction: true,
    hasMoreActions: true,
    customClass: '',
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void;
  (e: 'update:attachments', value: any): void;
  (e: 'update:isValid', value: any): void;
  (e: 'update:mentionUserIds', value: any): void;
  (e: 'onGetLinkFromMsg', value: any): void;
  (e: 'onKeydown', value: any): void;
  (e: 'onFocus'): void;
  (e: 'onClose'): void;
  (e: 'onSave'): void;
  (e: 'update-cache', textValue: string, attachments: any[]): void;
  (e: 'update:mentionGroup', value: any): void;
}>();

const _taskStore = taskStore();
// const _chatStore = chatStore();
const _remoteConfigStore = remoteConfigStore();
const _taskCommentStore = taskCommentStore();
let ckEditorInstance;

const currentCycle = computed<{
  currentCycle: number;
  letter: string;
  code: number;
}>(() => _taskStore.currentCycle);
const webTaskUploadContentTypes = computed(
  () => _remoteConfigStore.webTaskUploadContentTypes
);
const editorRef = ref();
const classicEditor = ref(ClassicEditor);
const taskDescriptionFileInputId = 'TASK_DESCRIPTION_FILE_INPUT_ID';
const actionList = [
  'link',
  'bold',
  'italic',
  'strikethrough',
  'underline',
  'bulletedList',
  'numberedList',
];

const listMentionUser = computed(() => {
  return _taskCommentStore.getListUserMentionInComment(
    props?.currentTask?.groupId,
    [props?.currentTask?.creatorId, props?.currentTask?.assigneeId],
    props?.currentTask?.isPrivate
  );
});
const editorConfig = computed(() => ({
  plugins: [
    // Essentials,
    Clipboard,
    Enter,
    SelectAll,
    Typing,
    Undo,
    List,
    Underline,
    Strikethrough,
    BoldPlugin,
    ItalicPlugin,
    LinkPlugin,
    ParagraphPlugin,
    DeletePlugin,
    WordCount,
    AutoformatPlugin,
    AutoLinkPlugin,
    MentionPlugin,
    PasteLinkPlugin,
    CustomLinkTaskTargetPlugin,
    CustomLinkMentionPlugin,
    ShiftEnterPlugin,
  ],
  toolbar: {
    items: ['link'],
  },
  language: 'en',
  startupFocus: true,

  placeholder: props.placeholder || translate('TASK_DESCRIPTION_PLACEHOLDER'),
  link: {
    defaultTaskLink: `${getDomainUrl()}/tasks/`,
    currentCycle: currentCycle.value,
    defaultProtocol: 'https://',
    addTargetToExternalLinks: true,
    decorators: {
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
  mention: {
    feeds: [
      {
        marker: '@',
        feed: getFeedItems,
        itemRenderer: customItemRenderer,
      },
    ],
  },
}));

const getFeedItems = (queryText) => {
  const querySearch = ignoreUnicode(queryText);
  if (!queryText) return listMentionUser.value.slice(0, 12);
  return listMentionUser.value.filter(
    (el) =>
      ignoreUnicode(el.name)?.includes(querySearch) ||
      ignoreUnicode(el.id)?.includes(querySearch)
  );
};

const customItemRenderer = (item) => {
  return renderItemListMentionChat(item);
};

const handleGetLinkFromMsg = (message) => {
  if (!message || !message.includes('<a') || !message.includes('</a>')) return;

  let elementMessage = document.createElement('html');
  elementMessage.innerHTML = message;

  let mentionArr = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        link?.getAttribute('data-mention') &&
        link?.getAttribute('userid') &&
        link?.getAttribute('data-type') == 'mention'
    )
    .map((elLink) => elLink?.getAttribute('userid'));

  let mentionGroup = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        link?.getAttribute('data-mention') &&
        link?.getAttribute('userid') &&
        link?.getAttribute('data-type') == 'mention' &&
        link?.getAttribute('data-mention-group') == 'true'
    )
    .map((elLink) => elLink?.getAttribute('userid'));

  let linkArr = Array.prototype.filter
    .call(
      elementMessage.getElementsByTagName('a'),
      (link) =>
        link?.getAttribute('href') &&
        !link?.getAttribute('href').includes('tictop.app') &&
        !link?.getAttribute('href').includes('app.tictop.eu') &&
        !link?.getAttribute('href').includes('fiine.pro') &&
        !link?.getAttribute('data-mention') &&
        !link?.getAttribute('userid') &&
        !link?.getAttribute('data-type')
    )
    .map((elLink, index) => {
      let domainUrl = new URL(elLink.getAttribute('href'));
      let domain = domainUrl.hostname.replace('www.', '');

      return {
        title: domain,
        link: elLink.getAttribute('href'),
        index: index,
        previewData: {
          isPreview: false,
          description: '',
          title: domain,
          imageUrl: '',
          domain: domain,
        },
      };
    });

  const linkObjects = linkArr.filter(
    (a, i) => linkArr.findIndex((s) => a.link === s.link) === i
  );

  const mentionUserIds = mentionArr.filter(
    (a, i) => mentionArr.findIndex((s) => a === s) === i
  );

  if (mentionGroup?.length > 0) emit('update:mentionGroup', true);
  emit('onGetLinkFromMsg', linkObjects);
  emit('update:mentionUserIds', mentionUserIds);
};

onMounted(() => {
  attachmentFiles.value = props.attachments?.filter((file) => !file?.isNote);
  noteList.value = props.attachments?.filter((file) => file?.isNote);
});

const {
  attachmentFiles,
  onChooseFiles,
  isOpenRecording,
  onCreateAudioDone,
  onRemoveFile,
  onAddFiles,

  // note
  isOpenNote,
  drawNoteData,
  onOpenCreateNote,
  onSaveNote,
  noteList,
  onRemoveNote,
  onEditNote,
} = attachmentFilesComposables('TASK', {});

const pastedImage = ref<any>();

const allAttachments = computed<IUploadPreviewPropFile[]>(() => {
  return arrayOrderBy(
    [
      ...attachmentFiles.value?.filter((file) => file.editStatus !== 'REMOVED'),
      ...noteList.value?.filter((note) => note.editStatus !== 'REMOVED'),
    ],
    ['creationTime'],
    ['asc']
  );
});

const onCloseNote = () => {
  // console.log('onCloseNote');
  isOpenNote.value = false;
  pastedImage.value = null;
};

const onChange = (messageText) => {
  emit('update:modelValue', messageText);
  emit('update-cache', messageText, allAttachments.value);
  calcListCommandsActive();
  handleGetLinkFromMsg(messageText);
};

const totalCharacters = ref(0);
const isNotValid = computed(() => totalCharacters.value > props.maxLength);
const onEditorReady = (editor) => {
  ckEditorInstance = editor;
  editorRef.value = markRaw(editor);

  ckEditorInstance.model.change((writer) => {
    writer.setSelection(editor.model.document.getRoot(), 'end');
  });

  ckEditorInstance.editing.view.focus();

  editor.editing.view.document.on('keydown', (evt) => {
    emit('onKeydown', evt);
  });
  totalCharacters.value = editor.plugins.get('WordCount')?.characters;

  editor.plugins.get('WordCount').on('update', (evt, stats) => {
    // console.log(`Characters: ${stats.characters}\nWords:      ${stats.words}`);
    totalCharacters.value = stats.characters;
    emit('update:isValid', totalCharacters.value <= props.maxLength);
  });
};

const focusToEnd = (newValue) => {
  if (!editorRef.value || !ckEditorInstance) return;
  ckEditorInstance.setData(newValue);

  ckEditorInstance.editing.view.focus();
  const model = ckEditorInstance.editing.model;
  const document = model.document;
  model.change((writer) => {
    writer.setSelection(writer.createPositionAt(document.getRoot(), 'end'));
  });
  console.log(' focus:');
};

const insertEmoji = (emoji) => {
  if (!editorRef.value) return;
  editorRef.value?.model.change((writer) => {
    writer.insertText(
      emoji,
      editorRef.value?.model.document.selection.getFirstPosition()
    );
    // writer.setSelection(
    //   writer.createPositionAt(
    //     editorRef.value?.model.document.getRoot(),
    //     'end'
    //   )
    // );
  });

  ckEditorInstance.editing.view.focus();
};

const onInsertText = (text) => {
  if (!editorRef.value || !editorRef.value?.model || !text) return;
  editorRef.value?.model.change((writer) => {
    if (props.modelValue)
      writer.insertElement(
        'paragraph',
        editorRef.value?.model.document.selection.getLastPosition(),
        'end'
      );
    writer.insertText(
      text || '',
      editorRef.value?.model.document.selection.getLastPosition(),
      'end'
    );
  });
};

const execCommand = (
  commandName:
    | 'bold'
    | 'italic'
    | 'strikethrough'
    | 'underline'
    | 'bulletedList'
    | 'numberedList'
) => {
  // ckEditorInstance.execute('bold');
  // ckEditorInstance.ui?.editor?.execute('link');
  ckEditorInstance.execute(commandName);
  calcListCommandsActive();
};
const execLinkCommand = (link: { name: string; path: string }) => {
  // ckEditorInstance.execute('bold');
  // ckEditorInstance.ui?.editor?.execute('link');
  ckEditorInstance.execute('link', link.path);
};

const listCommandsActive = ref([] as any[]);

const calcListCommandsActive = () => {
  if (!ckEditorInstance?.commands) return;

  listCommandsActive.value = actionList.filter(
    (el) => ckEditorInstance?.commands?.get(el).value
  );
};

const currentFileIndex = ref(0);
const isPreview = ref(false);
const currentViewFile = ref<IUploadPreviewPropFile | null>(null);
const onOpenFiles = (file, index) => {
  console.log('🚀 Hyrin ~ onOpenFiles ~ file:', file);
  if (file?.isNote) {
    onEditNote(file);
    return;
  }
  isPreview.value = true;
  currentFileIndex.value = index;
  currentViewFile.value = file;
};

const onRemoveAttachment = (file) => {
  if (file.isNote) {
    onRemoveNote(file);
    const inputImage: HTMLInputElement = document.getElementById(
      taskDescriptionFileInputId
    ) as HTMLInputElement;
    if (inputImage) inputImage.value = '';
  } else onRemoveFile(file);

  emit('update:attachments', allAttachments.value);
  emit('update-cache', props.modelValue, allAttachments.value);
};

const onPasteFiles = async (event) => {
  // console.log('taskcommentInput');
  if (!props.hasMoreActions || !props.isAction) return;

  event.preventDefault();
  event.stopPropagation();

  var files = (event.clipboardData || event.originalEvent.clipboardData).files;

  if (!files || files?.length == 0) return;

  // 1. if image file => open note
  if (files?.length == 1 && files[0].type.includes('image')) {
    onOpenCreateNote(null, true, files);
    pastedImage.value = files[0];
    return;
  }

  // 2. If not image file => attachment

  onAddFiles(files);
};

defineExpose({
  focusToEnd,
  insertEmoji,
  onInsertText,
});
</script>
<template>
  <div
    class="
      task-description-input
      w-full
      flex-1 flex flex-col
      min-h-0
      relative
      border
      cursor-text
    "
    :class="[customClass, isNotValid ? 'border-red-500' : 'border-gray-200']"
    @click.stop="editorRef?.focus()"
    @paste="onPasteFiles"
  >
    <Ckeditor
      :model-value="modelValue"
      :editor="classicEditor"
      :config="editorConfig"
      class="ckeditor-input"
      @ready="onEditorReady"
      @update:model-value="onChange"
      @focus="$emit('onFocus')"
    />
    <!-- Files -->
    <section class="w-full items-center flex flex-wrap px-2">
      <div
        v-for="(file, index) in allAttachments"
        :key="index"
        class="relative w-max h-16 m-1 hover-to-hidden__parent"
      >
        <UploadPreview
          class="m-0"
          :file="file"
          :is-note="file.isNote"
          is-hidden-info
          @on-select="onOpenFiles(file, index)"
          @remove="onRemoveAttachment(file)"
        />
      </div>
    </section>
    <!-- actions button list -->
    <section class="px-2 py-1 flex justify-between items-center">
      <div class="pl-10">
        <CkeditorCustomAction
          :action-list="actionList"
          :command-active="listCommandsActive"
          @execute="execCommand"
          @execute-link="execLinkCommand"
        >
          <template #moreAction>
            <div
              v-if="hasMoreActions && isAction"
              class="flex-center space-x-1"
            >
              <div
                class="
                  w-6
                  h-6
                  rounded
                  cursor-pointer
                  flex-center
                  hover:bg-current-50
                "
              >
                <label
                  :for="taskDescriptionFileInputId"
                  class="
                    w-full
                    h-full
                    flex-center
                    cursor-pointer
                    hover:text-current hover:fill-current
                  "
                  :title="
                    $t('TASK_CREATE_FORM_LABEL_ADD_ATTACHMENT') ||
                    'Add attachment'
                  "
                >
                  <SynIcon
                    name="attach"
                    custom-class="fill-current-500 text-current-500 h-3.5 w-3.5"
                  />
                </label>
              </div>
              <div
                class="
                  w-6
                  h-6
                  rounded
                  cursor-pointer
                  flex-center
                  hover:bg-current-50
                "
                @click="onOpenCreateNote"
              >
                <SynIcon
                  name="noter"
                  is-active
                  custom-class="fill-orange-500 h-3.5 w-3.5"
                  :title="$t('TASK_CREATE_FORM_LABEL_ADD_NOTE') || 'Add note'"
                />
              </div>
              <div
                class="
                  w-6
                  h-6
                  rounded
                  cursor-pointer
                  flex-center
                  hover:bg-current-50
                "
                @click="isOpenRecording = true"
              >
                <SynIcon
                  :title="$t('TASK_DETAIL_LABEL_ADD_AUDIO') || 'Add audio'"
                  is-active
                  name="microphone"
                  custom-class="fill-blue-500 text-blue-500 h-3.5 w-3.5"
                />
              </div>
            </div>
          </template>
        </CkeditorCustomAction>
      </div>
      <div v-if="isAction" class="flex-center space-x-2 text-xs">
        <SynButton
          :label="$t('COMMON_LABEL_CLOSE') || 'Close'"
          type-text
          color="gray"
          @click.stop="$emit('onClose')"
        />
        <SynButton
          :label="$t('COMMON_LABEL_SAVE') || 'Save'"
          :disabled="isNotValid"
          @click.stop="$emit('onSave')"
        />
      </div>
      <div v-else class="flex-center space-x-2 text-xs">
        <SynButton
          :label="$t('COMMON_LABEL_CLOSE') || 'Close'"
          type-text
          color="gray"
          @click.stop="$emit('onClose')"
        />
      </div>
    </section>

    <span
      v-if="!isHiddenMaxLength"
      class="absolute right-1 bottom-10 text-sm"
      :class="isNotValid ? 'text-red-500' : 'text-gray-600'"
    >
      {{ totalCharacters }}/{{ maxLength }}
    </span>
  </div>
  <input
    :id="taskDescriptionFileInputId"
    type="file"
    multiple
    class="hidden opacity-0"
    :accept="webTaskUploadContentTypes"
    @change="
      onChooseFiles($event);
      $emit('update:attachments', allAttachments);
      $emit('update-cache', modelValue, allAttachments);
    "
  />

  <CreateNoteModal
    v-if="isOpenNote"
    :draw-note-data="drawNoteData"
    :pasted-image="pastedImage"
    @on-close="onCloseNote"
    @on-save="
      onSaveNote($event);
      $emit('update:attachments', allAttachments);
      $emit('update-cache', modelValue, allAttachments);
    "
  />

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="
      onCreateAudioDone($event);
      $emit('update:attachments', allAttachments);
      $emit('update-cache', modelValue, allAttachments);
    "
    @on-cancel="isOpenRecording = false"
  />

  <ModalFileViewer
    v-if="isPreview"
    :name="currentViewFile?.name || ''"
    :file="currentViewFile"
    :path="currentViewFile?.fileUrl || ''"
    :content-type="currentViewFile?.type || ''"
    @on-close="isPreview = false"
  />
</template>

<style lang="scss">
.task-description-input {
  .ck.ck-editor {
    position: static;
  }

  .ck-toolbar__items {
    @apply absolute w-8 h-8 bottom-1 z-50;
  }

  .ck-button {
    @apply w-8 h-8 cursor-pointer rounded-md hover:bg-current-50;
  }

  .ck.ck-button:not(.ck-disabled):hover {
    @apply bg-current-50 rounded-md;
  }

  @apply leading-6 rounded-md w-full;

  ul,
  menu {
    // list-style: none;

    li {
      margin-left: 1rem;
    }

    // li::before {
    //   content: '•';
    //   margin-right: 0.5rem;
    // }
  }

  ol {
    list-style: decimal;

    li {
      margin-left: 1rem;
    }
  }

  .ck.ck-editor__main {
    @apply flex-1;
  }

  .ck.ck-reset.ck-editor.ck-rounded-corners {
    @apply flex-1 min-h-0 overflow-auto;
    min-height: 13rem;
  }

  .ck.ck-editor__editable_inline {
    @apply pr-2 pl-4 leading-6 w-full border-current-300 text-gray-600;
  }

  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    @apply bg-white border-0 rounded-t-md shadow-none h-full;
  }

  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    @apply border-gray-300;
  }

  .ck.ck-reset.ck-editor.ck-rounded-corners.ck-rounded-corners {
    @apply flex flex-col-reverse border-0;
  }

  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    @apply border-0 rounded-b-md bg-white pl-4 opacity-80;
  }
}

.task-approval-input {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    min-height: 5rem;
  }
}

//.ck-body-wrapper {
//  display: none !important;
//}

.task-update-description-modal {
  .task-description-input {
    .ck.ck-reset.ck-editor.ck-rounded-corners {
      min-height: 20rem;
    }
  }
}

.task-detail_description,
.task-message-item {
  @apply leading-6 text-gray-600;
  ul,
  menu {
    // list-style: none;

    li {
      margin-left: 1rem;
    }

    // li::before {
    //   content: '•';
    //   margin-right: 0.5rem;
    // }
  }

  ol {
    list-style: decimal;

    li {
      margin-left: 1rem;
    }
  }
}
</style>
