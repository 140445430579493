<script setup lang="ts">
import { computed } from 'vue';
// import {encryptUserId, encryptGroupId} from '@/ui/plugins/crypto/crypto';
// import {useRouter} from 'vue-router';
import { formatDate } from '@/ui/hooks/calendarHook';
import { getGroupTaskListUrl } from '@/ui/hooks/taskHook';
import { currentTimeDevice } from '@/ui/hooks/calendarHook';

import dayjs from 'dayjs';
import { CalendarMeetingType } from '@/ui/common/constants/calendar';
// import userStore from '@/store/user';
import SynListAvatar from '@/ui/common/molecules/SynAvatar/SynListAvatar.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import {
  ENotificationEventSubType,
  INotification,
  INotificationEventMainData,
} from '@/application/types/notification.types';
import { translate } from '@/ui/plugins/i18n/myi18n';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const props = withDefaults(
  defineProps<{
    isView: boolean;
    propId: string;
    category: string;
    notificationItem: any;
    isShowActionUser: boolean;
    users: any;
  }>(),
  {
    category: 'all',
    isShowActionUser: true,
  }
);

const emit = defineEmits([
  'getNotificationLinkUrl',
  'handleClickMarkRead',
  'handleDeleteNotification',
  'onUserActionClick',
]);

// const router = useRouter();
// const _userStore = userStore();

// const avatarUserById = (avatarNotification, userId) => {
//   return _userStore.updateAvatarUserToAvatarCurrent(
//     avatarNotification,
//     userId,
//     'small'
//   );
// };

// const getLinkUrl = (notification, type) => {
//   return type == 'group' && notification?.groupId
//       ? `/tasks/groups/${encryptGroupId(notification?.groupId)}`
//       : `/tasks/users/${encryptUserId(notification?.executorId)}`;
// };
// const onGotoLink = (event, notification, type) => {
//   event.preventDefault();
//   router.push(getLinkUrl(notification, type));
// };

const notification = computed<INotification>(() => {
  return props.notificationItem;
});
const mainData = computed<INotificationEventMainData>(() => {
  return notification.value.mainData;
});

const isGoogleCalendarEvent = computed<boolean>(() => {
  return mainData.value?.subType?.startsWith('GoogleEvent');
});
const eventIcon = computed<string>(() => {
  if (
    mainData.value?.metadata?.meeting_type ==
    CalendarMeetingType.PhysicalMeeting
  )
    return 'event';

  if (
    mainData.value?.metadata?.meeting_type == CalendarMeetingType.OnlineMeeting
  )
    return 'video';

  return 'calendars';
});

const notifExecutorUser = computed(() =>
  props.users?.find((user: any) => user?.id === notification.value?.executorId)
);

const getNotificationLinkUrl = (notification) => {
  try {
    if (!notification || !notification.groupId) {
      return `/`;
    }
    return getGroupTaskListUrl(notification.groupId, true);
  } catch {
    return `/`;
  }
};

const handleCreateTimeNotification = (createdDate) => {
  if (!createdDate) {
    return;
  }
  let utcDate = currentTimeDevice().utcDate;

  const dayTimeZone = dayjs(createdDate).add(utcDate, 'hour');
  return dayjs(dayTimeZone).fromNow();
};

const handleClickMarkRead = (id) => {
  emit('handleClickMarkRead', id);
};
const handleDeleteNotification = (id) => {
  emit('handleDeleteNotification', id);
};

const notificationTitle = computed<string>(() => {
  if (mainData.value?.actionType?.startsWith('GOOGLE_EVENT')) {
    return translate('NOTIFICATION_EVENT_GOOGLE_CALENDAR_TITLE', {
      time: !mainData.value?.metadata?.all_day
        ? translate('NOTIFICATION_EVENT_GOOGLE_CALENDAR_TITLE_AT_TIME', {
            time: formatDate(mainData.value.startDate).time,
          })
        : '',
      day: formatDate(mainData.value.startDate).date,
      action:
        mainData.value?.actionType == ENotificationEventSubType.GOOGLE_EVENT_ADD
          ? translate('NOTIFICATION_EVENT_GOOGLE_CALENDAR_TITLE_ACTION_ADDED')
          : mainData.value?.actionType ==
            ENotificationEventSubType.GOOGLE_EVENT_DELETE
          ? translate('NOTIFICATION_EVENT_GOOGLE_CALENDAR_TITLE_ACTION_DELETED')
          : translate(
              'NOTIFICATION_EVENT_GOOGLE_CALENDAR_TITLE_ACTION_UPDATED'
            ),
    });
  }

  if (mainData.value?.eventStartDate) {
    if (
      mainData.value?.subType?.toLowerCase() ===
      ENotificationEventSubType.UPDATE_EVENT?.toLowerCase()
    )
      return translate('NOTIFICATION_CALENDAR_UPDATE_TITLE', {
        fullname: notification.value?.executorFullName,
        time: formatDate(notification.value?.mainData?.eventStartDate).time,
        day: formatDate(notification.value?.mainData?.eventStartDate).date,
      });

    if (
      mainData.value?.subType?.toLowerCase() ===
      ENotificationEventSubType.DELETE_EVENT?.toLowerCase()
    )
      return translate('NOTIFICATION_CALENDAR_DELETE_TITLE', {
        fullname: notification.value?.executorFullName,
        time: formatDate(notification.value?.mainData?.eventStartDate).time,
        day: formatDate(notification.value?.mainData?.eventStartDate).date,
      });

    return translate('NOTIFICATION_CALENDAR_TITLE', {
      fullname: notification.value?.executorFullName,
      time: formatDate(notification.value?.mainData?.eventStartDate).time,
      day: formatDate(notification.value?.mainData?.eventStartDate).date,
    });
  }
  return `${notification.value?.body}`;
});
</script>

<template>
  <div
    :id="`noti-${notification?.id}-${propId}`"
    :class="!notification?.status ? 'bg-current-50 bg-opacity-30' : ''"
    class="
      item-noti
      relative
      border-b border-gray-200
      flex
      items-center
      px-2
      py-1
      text-sm text-gray-700
      hover:bg-current-50 hover:bg-opacity-50
      cursor-pointer
    "
  >
    <div class="relative flex items-center w-full">
      <!--      <syn-avatar-status-->
      <!--        :name="notification.groupName"-->
      <!--        :src="-->
      <!--          avatarUserById(-->
      <!--            notification?.executorAvatarUrl,-->
      <!--            notification?.executorId || notification?.userId-->
      <!--          )-->
      <!--        "-->
      <!--        color-status="bg-purple-500"-->
      <!--        :name-icon="statusTypeEvent(notification?.metadata?.meetingType)"-->
      <!--      />-->
      <div class="relative">
        <SynIconCustom
          v-if="isGoogleCalendarEvent"
          class="fill-white text-white"
          custom-class="h-10 w-10"
          name="GoogleCalendar"
          is-active
        />
        <UserByAction
          v-else
          :avatar-class="'h-12 w-12'"
          :user-id="notification?.executorId"
          :user="notifExecutorUser"
          :is-show-action-user="isShowActionUser"
          is-hidden-name
          @on-action-click="$emit('onUserActionClick', $event)"
        />
        <div
          v-if="!isGoogleCalendarEvent"
          class="
            absolute
            -bottom-1
            rounded-full
            flex-center
            -right-2
            border border-white
            w-7
            h-7
            bg-purple-500
          "
        >
          <SynIconCustom
            class="fill-white text-white"
            :name="eventIcon"
            is-active
          />
        </div>
      </div>
      <div class="w-full flex flex-col pl-3">
        <!-- P1: BODY NOTIFICATION -->
        <div class="flex flex-col pb-0.5">
          <a :href="getNotificationLinkUrl(notification)">
            <div
              :title="notification?.body ? notification?.body : mainData?.title"
              class="pl-2 text-overflow-hidden"
              :class="
                !notification?.status
                  ? 'text-black font-medium'
                  : 'text-gray-700'
              "
            >
              {{ notificationTitle }}
            </div>
          </a>
        </div>

        <!--  P2: TITLE, WORKSPACE      -->
        <div
          class="flex w-full justify-between items-end space-x-4"
          :class="!notification.status ? 'text-current-800' : 'text-gray-500'"
        >
          <div
            :title="`${mainData?.title} - ${mainData?.metadata?.workspace}`"
            class="flex items-start space-x-1 text-xs pl-2"
          >
            <span class="text-current-800 text-overflow-hidden-line">{{
              mainData?.title
            }}</span>
            <span
              v-if="mainData?.metadata?.workspace"
              class="text-current-800 text-overflow-hidden-line"
              >{{ `- ${mainData?.metadata?.workspace}` }}</span
            >
          </div>
          <syn-list-avatar
            v-if="mainData?.metadata?.participant_ids"
            :users-id="mainData?.metadata?.participant_ids"
            custom-class="w-5 h-5"
            :max-length="3"
            trigger="mouseenter"
          />
        </div>

        <!-- P3: GROUP NAME, EXECUTOR NAME -->
        <div
          class="flex items-center space-x-1 justify-between"
          style="padding-top: 2.5px"
        >
          <div class="flex flex-col">
            <div
              v-if="!isGoogleCalendarEvent"
              style="font-size: 11px"
              class="pl-2 w-full flex items-start space-x-1"
            >
              <span>
                <span>{{ $t('TASK_DETAIL_LABEL_BY') + ' ' }}</span>
                <span class="text-gray-600">
                  {{ notification?.executorFullName }}
                </span>
              </span>
            </div>
          </div>

          <div class="flex space-x-2 items-center">
            <span class="text-gray-500" style="font-size: 12px">
              {{
                handleCreateTimeNotification(
                  notification?.eventActionDate
                    ? notification?.eventActionDate
                    : notification?.createdDate
                )
              }}
            </span>
            <span
              v-if="!notification?.status"
              class="rounded-full w-2 h-2 bg-blue-500 mt-0.5"
            ></span>
          </div>
        </div>

        <!-- BUTTON: ACTION SEEN, REMOVE -->
        <div
          v-if="!isView"
          class="
            item-noti__action
            absolute
            right-0
            top-1
            flex
            items-center
            space-x-2
          "
        >
          <div
            v-if="!notification?.status"
            :title="$t('COMMON_LABEL_SEEN')"
            class="
              border border-green-500
              bg-white bg-opacity-70
              flex-center
              w-6
              h-6
              rounded-full
              text-green-500
              fill-green-500
              hover:fill-white hover:text-white hover:bg-green-500
            "
            @click.stop="handleClickMarkRead(notification?.id)"
          >
            <SynIcon name="check" size="small" />
          </div>

          <div
            :title="$t('NOTIFICATION_DELELTE_NOTIFICATION_SUB')"
            class="
              border border-red-500
              flex-center
              bg-white bg-opacity-70
              w-6
              h-6
              rounded-full
              text-red-500
              fill-red-500
              hover:fill-white hover:text-white hover:bg-red-500
            "
            @click.stop="handleDeleteNotification(notification?.id)"
          >
            <SynIcon
              name="cancel"
              size="small"
              custom-class="w-2.5 h-2.5"
              is-active
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item-noti:hover .item-noti__action {
  /* display: block; */
  visibility: visible;
}

.item-noti__action {
  /* display: none; */
  visibility: hidden;
}

.item-noti:active {
  visibility: visible;
}

.item-noti:active .item-noti__action {
  /* display: block; */
  visibility: visible;
}
</style>
