<script setup lang="ts">
import { computed, ref } from 'vue';
import GroupColorCustomize from '@/ui/modules/messaging/chat-panel/chat-message/group-message/GroupColorCustomize.vue';

const props = withDefaults(
  defineProps<{
    colorCurrent: any;
    isMyMessage: boolean;
    isShowAll: boolean;
    conversation: any;
  }>(),
  {
    colorCurrent: '',
    isShowAll: false,
  }
);
const emits = defineEmits(['isHasAction', 'onChangeBackgroundMsg']);

const isShowMenu = ref(false);
const isPageMessage = computed(() =>
  window.location.href?.includes('messages')
);
const isShow = ref(props?.isShowAll);
const colorChoose = ref(props?.colorCurrent);

const placementPage = computed(() => {
  if (isPageMessage.value) {
    if (props.isMyMessage) {
      return 'top-end';
    } else {
      return 'top-start';
    }
  } else {
    return 'top-end';
  }
});

const openMenu = () => {
  isShowMenu.value = true;
  emits('isHasAction', true);
};

const closeMenu = () => {
  isShowMenu.value = false;
  emits('isHasAction', false);
};

window.addEventListener('scroll', closeMenu, true);

const onSelectItem = (item) => {
  colorChoose.value = item === colorChoose.value ? null : item;

  emits('onChangeBackgroundMsg', {
    color: colorChoose.value,
    isShowAll: isShow.value,
  });
};

const onChangeShowAll = (show) => {
  isShow.value = show;

  emits('onChangeBackgroundMsg', {
    color: colorChoose.value,
    isShowAll: isShow.value,
  });
};
</script>

<template>
  <VigDropdown
    :arrow="false"
    hide-on-click="outside"
    :placement="placementPage"
    @on-dropdown-open="openMenu()"
    @on-dropdown-close="closeMenu()"
  >
    <template #dropdown-toggle>
      <div
        :title="$t('MESSAGE_CUSTOMIZE_SET_BG_COLOR_TITLE')"
        class="p-1 flex-center rounded-full hover:hover:bg-gray-100 w-7 h-7"
        :class="isShowMenu ? 'bg-gray-100' : ''"
      >
        <SynIcon
          name="color-customize"
          custom-class="w-3.5 h-3.5 fill-gray-500 text-gray-500"
        />
      </div>
    </template>
    <template v-if="isShowMenu" #dropdown-menu>
      <div class="flex items-center space-x-2 py-2 px-3">
        <span class="font-medium text-sm">{{
          $t('MESSAGE_CUSTOMIZE_SET_BG_COLOR_TITLE')
        }}</span>
        <syn-explain>
          <div style="max-width: 12rem">
            <span class="text-xs">{{
              $t('MESSAGE_CUSTOMIZE_SET_BG_COLOR_DESCRIPTION')
            }}</span>
          </div>
        </syn-explain>
      </div>

      <GroupColorCustomize
        :color-current="colorCurrent"
        @on-change-background-msg="(value) => onSelectItem(value)"
      />
      <div
        v-if="isMyMessage"
        class="
          w-full
          flex
          items-center
          justify-between
          border-t border-gray-100
          py-2
          px-3
        "
      >
        <span
          class="text-xs text-gray-600 truncate w-full"
          style="max-width: 15rem"
        >
          <template v-if="conversation?.isGroup">
            {{
              $t('MESSAGE_CUSTOMIZE_VISIBLE_GROUP_LABEL', {
                group: conversation?.name,
              })
            }}
          </template>
          <template v-else>
            {{
              $t('MESSAGE_CUSTOMIZE_VISIBLE_CONVERSATION_LABEL', {
                name: conversation?.name,
              })
            }}
          </template>
        </span>
        <AtomSwitch
          :model-value="isShow"
          size="small"
          @change="onChangeShowAll"
        ></AtomSwitch>
      </div>
    </template>
  </VigDropdown>
</template>
