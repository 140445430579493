<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import WorkPerformanceRepository from '@/application/repositories/WorkPerformanceRepository';
import userStore from '@/store/user';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import groupStore from '@/store/group';

const props = defineProps<{
  filterData: {
    dateFrom: string;
    dateTo: string;
    departmentIds: number[];
    size: number;
  };
}>();

const _groupStore = groupStore();
const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    assigneeId: number;
    fullName: string;
    avatar: string;
    activeTasks: number;
    domains: {
      domainId: number;
      domainName: string;
      activeTasks: number;
    }[];
    groups: {
      groupId: number;
      groupName: string;
      activeTasks: number;
    }[];
  }[]
>([]);

const initComponent = async () => {
  isLoading.value = true;
  const res = await WorkPerformanceRepository.getUserMostActiveTask({
    ...props.filterData,
  });
  resultData.value = res?.result?.map((o) => {
    return {
      ...o,
      avatar: userStore().allUserByIds[o.assigneeId].avatar,
    };
  });
  isLoading.value = false;
};

initComponent();

watch(
  () => props.filterData,
  (data, old) => {
    if (
      data.dateFrom == old.dateFrom &&
      data.dateTo == old.dateTo &&
      data.departmentIds == old.departmentIds &&
      data.size == old.size
    )
      return;
    initComponent();
  }
);

const allGroupByIds = computed(() => {
  return {
    ..._groupStore.allGroupByIds,
    ..._groupStore.myGroupByIds,
  };
});
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <div
    v-else
    class="
      flex-1
      min-h-0
      overflow-auto
      small-scrollbar
      w-full
      h-full
      flex
      justify-center
      items-start
    "
  >
    <SynTableCustom v-if="resultData?.length > 0">
      <template #header>
        <SynTr>
          <SynTh class="w-16"></SynTh>
          <SynTh is-center class="text-center">
            {{ $t('COMMON_LABEL_TASK_COUNT') }}
          </SynTh>
          <SynTh is-center class="text-center">
            {{ $t('COMMON_LABEL_DOMAIN_PROJECT') }}
          </SynTh>
          <SynTh is-center class="text-center">
            {{ $t('COMMON_MODULE_GROUP_MANAGEMENT') }}
          </SynTh>
        </SynTr>
      </template>
      <template #body>
        <SynTr
          v-for="(task, index) in resultData"
          :key="task.assigneeId"
          :class="[index % 2 == 0 ? 'bg-gray-50' : '']"
        >
          <SynTd class="relative text-xs py-2">
            <UserByAction
              v-if="task.assigneeId"
              :user-id="task?.assigneeId"
              is-show-action-user
              avatar-class="w-7 h-7"
            />
          </SynTd>

          <SynTd class="text-xs text-center">
            <span> {{ task.activeTasks }} </span>
          </SynTd>
          <SynTd>
            <VigTagList
              :data-list="
                task?.domains?.map((o) => {
                  return {
                    id: o?.domainId,
                    name: o?.domainName,
                  };
                })
              "
              type="DOMAIN"
              :max-length="3"
              custom-class="w-6 h-6"
            />
          </SynTd>

          <SynTd class="text-xs text-center">
            <SynListAvatar
              :groups="
                task?.groups?.map((o) => {
                  return {
                    id: o?.groupId,
                    name: o?.groupName,
                    avatarUrl: allGroupByIds[o?.groupId]?.avatarUrl,
                  };
                })
              "
              type="GROUP"
              :max-length="3"
              custom-class="w-6 h-6"
            />
          </SynTd>
        </SynTr>
      </template>
    </SynTableCustom>
    <div v-else class="w-full h-full flex-center flex-col space-y-6">
      <div class="w-full grid grid-cols-4 gap-2">
        <div class="col-span-2 bg-gray-100 rounded-md h-4"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-4"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-4"></div>
        <!-- body -->
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-2 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
        <div class="col-span-1 bg-gray-100 rounded-md h-8"></div>
      </div>
    </div>
  </div>
</template>
