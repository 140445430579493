import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { IUserWhiteboard } from '@/application/types/whiteboard';
import WhiteboardService from '@/application/services/WhiteboardService';
import {
    settingGlobalModal,
    ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import DesktopIndexedDBSingleton from '@/indexedDB/desktop/DesktopIndexedDBSingleton';
import { ICreateWhiteboardShapeInput } from '@/application/repositories/WhiteboardRepository';
import {
    StorageConstant,
    getLocalStorage,
    setLocalStorage,
} from '@/ui/hooks/storageHook';
import { orderBy } from 'lodash';

export default defineStore({
    id: storeId.WHITEBOARD,
    state: () => ({
        currentWhiteboardId: getLocalStorage(
            StorageConstant.CURRENT_WHITEBOARD_ID
        ) as string,
        allWhiteboards: [] as IUserWhiteboard[],
        recycleShapeList: [] as ICreateWhiteboardShapeInput[],
        attachedTasks: [] as { whiteboardId: string; task: any }[],
    }),
    getters: {},
    actions: {
        async getAllWhiteboardByUser() {
            const res = await WhiteboardService.getAllByUser();

            this.allWhiteboards = orderBy(
                (res?.result || [])?.map((o) => {
                    return {
                        ...o,
                        id: o?.whiteboardId,
                        title: o?.whiteboardTitle,
                    };
                }),
                'creationTime',
                'desc'
            );
        },
        async createWhiteboard(payload) {
            const res = await WhiteboardService.createWhiteboard(payload);
            const newWhiteboard = res?.result;
            if (newWhiteboard?.id) {
                this.allWhiteboards = [newWhiteboard, ...this.allWhiteboards];
            }

            return newWhiteboard;
        },
        async updateWhiteboardTitle(whiteboardId, newTitle) {
            await WhiteboardService.updateWhiteboardTitle(
                whiteboardId,
                newTitle
            );
            const index = this.allWhiteboards?.findIndex(
                (o) => o?.id == whiteboardId
            );
            if (index > -1) this.allWhiteboards[index].title = newTitle;
        },
        async deleteWhiteboard(whiteboardId, whiteboardTitle) {
            settingGlobalModal({
                type: 'confirm',
                title: '',
                content: translate('COMMON_CONFIRM_REMOVE', {
                    type: translate('COMMON_LABEL_MY_DESKTOP') || 'Whiteboard',
                    name: whiteboardTitle || '',
                }),
                confirmable: true,
                confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
                closeable: true,
            });

            const confirmed = await ask();
            if (!confirmed) return Promise.resolve(false);

            const DesktopIndexedDBSingletonInstance =
                DesktopIndexedDBSingleton.getInstance();
            DesktopIndexedDBSingletonInstance.remove(whiteboardId);

            await WhiteboardService.deleteWhiteboard(whiteboardId);

            const index = this.allWhiteboards?.findIndex(
                (o) => o?.id == whiteboardId
            );
            if (index > -1)
                this.allWhiteboards = this.allWhiteboards?.filter(
                    (wb) => wb?.id !== whiteboardId
                );
            return true;
        },
        async addDeletedShapeToBin(deletedShape) {
            this.recycleShapeList = [
                ...(this.recycleShapeList || []),
                deletedShape,
            ];

            await WhiteboardService.addDeletedShapeToBin({
                ...deletedShape,
                sourceId: deletedShape?.sourceId?.toString(),
                sourceData: JSON.stringify(deletedShape?.sourceData),
            });
        },
        async getAllRecycleShapeByUser() {
            const res = await WhiteboardService.getAllRecycleShapeByUser();
            this.recycleShapeList = res?.result?.map((shape) => {
                return {
                    ...shape,
                    sourceData: shape?.sourceData
                        ? JSON.parse(shape?.sourceData)
                        : {},
                };
            });
        },
        async deleteRecycleShape(recycleShapeId) {
            await WhiteboardService.deleteRecycleShape(recycleShapeId);
            this.recycleShapeList = this.recycleShapeList?.filter(
                (o) => o?.id !== recycleShapeId
            );
        },
        setCurrentWhiteboardId(whiteboardId) {
            if (!whiteboardId) return;

            this.currentWhiteboardId = whiteboardId;
            setLocalStorage(
                StorageConstant.CURRENT_WHITEBOARD_ID,
                whiteboardId
            );
        },
        addAttachedTask(newItem) {
            this.attachedTasks = [...this.attachedTasks, newItem];
        },
        updateAttachedTasks(newAttachedTasks) {
            this.attachedTasks = [...newAttachedTasks];
        },
    },
});
