<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue';
import driveDocumentPageStore from '@/store/driveDocument/drive-document-page';
import FileIcon from '@/ui/modules/ged/_commons/file-icon/FileIcon.vue';
import DriveDocumentActionsDropdown from '@/ui/modules/drive-document/drive-document-actions/DriveDocumentActionsDropdown.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { useRoute, useRouter } from 'vue-router';
import { head } from 'lodash';
import { ResourceTab } from '@/domain/enums/resource-enums';

defineProps<{
  isFullscreen: boolean;
}>();

defineEmits(['update:isFullscreen']);

const MAX_TABS = 6;
const _drivePageStore = driveDocumentPageStore();
const _router = useRouter();
const _route = useRoute();

const tabs = computed(() => _drivePageStore.pageTabs);
const activeTab = computed(() => _drivePageStore.pageActiveTab);

watch(
  () => _route.query,
  (query) => {
    if (query?.resourceTab !== ResourceTab.GoogleDocuments) return;

    const tabByQuery = tabs.value?.find((t) => t?.id === query?.driveTab);

    if (tabByQuery) {
      _drivePageStore.setPageActiveTab(tabByQuery);
    } else {
      _checkTabId(activeTab.value?.id || query?.driveTab, true);
    }
  }
);

onBeforeMount(() => {
  if (_route.query?.resourceTab !== ResourceTab.GoogleDocuments) return;

  _checkTabId(_route.query?.driveTab, true);
});

const onTabClick = (tab) => {
  _goToTabUrl(tab?.id);
};

const onTabCloseClick = (tab) => {
  _drivePageStore.removePageTab(tab);

  _checkTabId(_route.query?.driveTab, true);
};

const onTabAddClick = () => {
  if (tabs.value?.length >= MAX_TABS) {
    settingGlobalModal({
      type: 'warning',
      iconName: 'warning',
      title: translate('GED_GOOGLE_TAB_MAX_MSG', { number: MAX_TABS }),
      confirmable: true,
    });
    return ask();
  }

  const newTab = _drivePageStore.addNewTab();

  _goToTabUrl(newTab?.id);
};

const _checkTabId = (tabId, isReplaceUrl = false) => {
  let tab = tabs.value?.find((t) => t?.id === tabId);

  if (!tab) tab = head(tabs.value);

  _goToTabUrl(tab?.id, isReplaceUrl);
};

const _goToTabUrl = (tabId, isReplaceUrl = false) => {
  const query = { ..._route.query, driveTab: tabId, folder: null };

  if (isReplaceUrl) return _router.replace({ query });

  _router.push({ query });
};
</script>

<template>
  <div class="h-10 flex items-center gap-2 pt-1 pr-2">
    <SynIcon
      v-if="isFullscreen"
      name="TictopLogo"
      custom-class="w-6 h-6 ml-4 mr-2"
    />

    <div v-perfect-scrollbar class="h-full flex items-end">
      <div
        v-for="tab in tabs"
        :key="tab?.id"
        role="button"
        class="h-full w-60 px-2 py-1 rounded-t-lg flex items-center border-r"
        :class="{
          'bg-white shadow': tab?.id === activeTab?.id,
          'hover:bg-gray-50': tab?.id !== activeTab?.id,
        }"
        :title="tab?.isNewTab ? $t(tab?.name) : tab?.name"
        @click="onTabClick(tab)"
      >
        <div class="flex-1 overflow-hidden flex items-center gap-2 px-1">
          <FileIcon v-if="tab?.contentType" :file-type="tab?.contentType" />
          <span class="flex-1 truncate text-sm">
            {{ tab?.isNewTab ? $t(tab?.name) : tab?.name }}</span
          >
        </div>

        <div class="flex items-center gap-1" @click.stop>
          <DriveDocumentActionsDropdown
            v-if="!tab?.isNewTab"
            :document="tab"
            :actions="['OPEN_IN_BROWSER', 'COPY_LINK']"
          >
            <VigButton
              ghost
              color="gray"
              padding="p-0.5"
              rounded="rounded-full"
              class="bg-transparent hover:bg-gray-200"
            >
              <SynIcon name="DotsVertical" />
            </VigButton>
          </DriveDocumentActionsDropdown>

          <!--TAB CLOSE-->
          <VigButton
            v-if="tabs?.length > 1 || !tab?.isNewTab"
            ghost
            color="gray"
            padding="p-0.5"
            rounded="rounded-full"
            class="bg-transparent hover:bg-gray-200"
            @click.stop="onTabCloseClick(tab)"
          >
            <SynIcon name="Close" />
          </VigButton>
        </div>
      </div>
    </div>

    <!--ADD TAB-->
    <VigButton
      ghost
      color="gray"
      rounded="rounded-full"
      padding="p-2"
      class="hover:bg-gray-200"
      @click="onTabAddClick"
    >
      <SynIcon name="Plus" custom-class="w-3 h-3" />
    </VigButton>

    <VigButton
      ghost
      color="gray"
      rounded="rounded-full"
      padding="p-2"
      class="ml-auto hover:bg-gray-200"
      @click="$emit('update:isFullscreen', !isFullscreen)"
    >
      <SynIcon
        :name="isFullscreen ? 'FullscreenExit' : 'Fullscreen'"
        custom-class="w-3 h-3"
      />
    </VigButton>
  </div>
</template>

<style scoped></style>
