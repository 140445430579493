export default {
    install: (app) => {
        app.directive('drop-zone', {
            mounted(el, binding) {
                el.dragCounter = 0;

                el.onDragEnterEvent = (event) => {
                    event.preventDefault();

                    el.dragCounter++;

                    binding.value?.isDragging(true);
                };

                el.onDragLeaveEvent = (event) => {
                    event.preventDefault();

                    el.dragCounter--;

                    if (el.dragCounter === 0) binding.value?.isDragging(false);
                };

                el.onDragOverEvent = (event) => {
                    event.preventDefault();
                };

                el.onDropEvent = (event) => {
                    event.preventDefault();

                    el.dragCounter = 0;

                    binding.value?.isDragging(false);
                    binding.value?.onDrop(event);
                };

                el.addEventListener('dragenter', el.onDragEnterEvent);
                el.addEventListener('dragleave', el.onDragLeaveEvent);
                el.addEventListener('dragover', el.onDragOverEvent);
                el.addEventListener('drop', el.onDropEvent);
            },
            unmounted(el) {
                el.removeEventListener('dragenter', el.onDragEnterEvent);
                el.removeEventListener('dragleave', el.onDragLeaveEvent);
                el.removeEventListener('dragover', el.onDragOverEvent);
                el.removeEventListener('drop', el.onDropEvent);
            },
        });
    },
};
