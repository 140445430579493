import { ref, watch } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';

const TypeActivity = {
    COMMENT: 'COMMENT',
    TODO_LIST: 'TODO_LIST',
    TASKLOG: 'TASKLOG',
};

export default function detailTaskComposable({ taskId }) {
    const listActivityOption = ref([
        {
            key: TypeActivity.COMMENT,
            label: translate('TASK_ACTIVITY_LABEL_COMMENT'),
            functionCode: ALL_FUNCTIONS.TASK.COMMENT,
        },
        {
            key: TypeActivity.TODO_LIST,
            label: translate('COMMON_LABEL_CHECK_LIST'),
            functionCode: ALL_FUNCTIONS.TASK.COMMENT,
        },
        {
            key: TypeActivity.TASKLOG,
            label: translate('TASK_ACTIVITY_LABEL_TASK_LOG'),
            functionCode: ALL_FUNCTIONS.TASK.HISTORY,
        },
    ]);

    watch(
        () => taskId,
        async () => {
            // console.log('taskId', taskId);
        }
    );

    return {
        TypeActivity,
        listActivityOption,
    };
}
