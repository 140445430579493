import { ref } from 'vue';
import GroupService from '@/application/services/GroupService';

export const isLoadingValue = ref(false);
export const groupPersonalSetting = ref({ filterPublicGroup: false });

export const fetchGroupPersonalSetting = async () => {
    const res = await GroupService.getInstance().getPublicGroupSetting();
    groupPersonalSetting.value = res.result ?? { filterPublicGroup: false };
};

export const updateGroupPersonalSetting = async () => {
    await GroupService.getInstance().showLessPublicGroup();
};
