<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import taskDrawerStore from '@/store/task/drawer';
import {
  isShowTaskDetailGlobal,
  taskIdGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';

const props = defineProps<{
  activity: any;
  isOpenTaskInModal?: boolean;
}>();

const afterUpdate = computed<any>(() => {
  return covertSnakeCaseToCamelCase(props.activity?.afterUpdate);
});

onMounted(() => {
  console.log('🚀 Tictop ~ props:', props.activity);
});

const onOpenSubTask = () => {
  if (props.isOpenTaskInModal) {
    taskIdGlobal.value =
      afterUpdate.value?.subTaskId || afterUpdate.value?.parentTaskId;
    taskCodeGlobal.value =
      afterUpdate.value?.subTaskCode || afterUpdate.value?.parentTaskCode;
    isShowTaskDetailGlobal.value = true;
    return;
  }
  taskDrawerStore().pushCurrentIds({
    id: afterUpdate.value?.subTaskId || afterUpdate.value?.parentTaskId,
    code: afterUpdate.value?.subTaskCode || afterUpdate.value?.parentTaskCode,
    name: afterUpdate.value?.subTaskName || afterUpdate.value?.parentTaskName,
    tabType: 'DETAIL',
  });
};
</script>
<template>
  <div class="flex items-center gap-1">
    <p
      class="text-gray-500 underline cursor-pointer"
      @click.stop="onOpenSubTask"
    >
      {{
        formatTaskCode(afterUpdate?.subTaskCode || afterUpdate?.parentTaskCode)
      }}
      -
      {{ afterUpdate?.subTaskName || afterUpdate?.parentTaskName }}
    </p>
  </div>
</template>
