<template>
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    viewBox="0 0 24 24"-->
  <!--    stroke="currentColor"-->
  <!--  >-->
  <!--    <path-->
  <!--      stroke-linecap="round"-->
  <!--      stroke-linejoin="round"-->
  <!--      stroke-width="2"-->
  <!--      d="M6 18L18 6M6 6l12 12"-->
  <!--    />-->
  <!--  </svg>-->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        fill-rule="evenodd"
        d="M5 17.586a1 1 0 1 0 1.415 1.415L12 13.414 17.586 19A1 1 0 0 0 19 17.586L13.414 12 19 6.414A1 1 0 0 0 17.585 5L12 10.586 6.414 5A1 1 0 0 0 5 6.414L10.586 12z"
        clip-rule="evenodd"
      ></path>
    </g>
  </svg>
</template>
