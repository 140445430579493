<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M22.947 3.714a3.018 3.018 0 0 0-2.438-.674 2.756 2.756 0 0 0-.618.184A2.995 2.995 0 0 0 17.005 1c-.537 0-1.034.153-1.471.401A2.994 2.994 0 0 0 13.005 0a2.998 2.998 0 0 0-2.886 2.225 2.756 2.756 0 0 0-.618-.184A3 3 0 0 0 6.005 5v8.565l-.955-.758a3.001 3.001 0 0 0-4.236.143c-1.129 1.208-1.066 3.111.13 4.231l3.193 3.079a13.286 13.286 0 0 0 9.266 3.739h1.665c4.928 0 8.937-4.009 8.937-8.937V6c0-.881-.385-1.715-1.058-2.286zm-.942 11.349A6.945 6.945 0 0 1 15.068 22h-1.665a11.293 11.293 0 0 1-7.877-3.179l-3.204-3.089a1.004 1.004 0 0 1-.047-1.416c.375-.403 1.007-.424 1.47.004l4.26 3.391V5a.998.998 0 0 1 1.176-.986c.462.075.824.555.824 1.092v5.893h2V3c0-.551.449-1 1-1s1 .449 1 1v8h2V4c0-.551.449-1 1-1s1 .449 1 1v7h2V6.107c0-.538.362-1.018.825-1.093A.998.998 0 0 1 22.005 6z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
