<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import chatSupportingStore from '@/store/chat/chat-supporting';
import { sortBy } from 'lodash';
import { arrToObj, isSameId } from '@/ui/helpers/utils';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import VigButton from '@/ui/common/atoms/VigButton/VigButton.vue';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import chatStore from '@/store/chat';
import ChatService from '@/application/services/ChatService';
import {
  getLocalStorage,
  setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';
import UserService from '@/application/services/UserService';
import { ChatSupporterStatus } from '@/domain/enums/chat-enums';

const _useRouter = useRouter();
const _useRoute = useRoute();
const _myProfileStore = myProfileStore();
const _chatSupportingStore = chatSupportingStore();
const _chatStore = chatStore();

const orgSupportingInfo = ref<any>();
const orgUserInfo = ref<any>();
const supportingSupporters = ref<any[]>();
const isAskToJoinChat = ref<boolean>(false);
const isSelectSupporters = ref<boolean>(false);
const selectedSupporterById = ref<{ [id: string]: boolean }>({});
const defaultBySupporterId = ref<{ [id: string]: boolean }>({});
const isVerifyingChatSupport = ref<boolean>(true);
const isVerifyingOrgMember = ref<boolean>(true);

const myUserId = computed<number>(() => _myProfileStore.myProfile?.id);
const orgSupporters = computed<any[]>(() =>
  sortBy(
    Object.entries(orgSupportingInfo.value?.supporting?.supporters || {})
      .filter(
        ([supporterId, supporter]: [string, any]) =>
          supporterId && supporter?.status === ChatSupporterStatus.ACTIVE
      )
      .map(([supporterId, supporter]: [string, any]) => ({
        ...supporter,
        supporterId,
      })),
    [(supporter) => (isSameId(supporter?.id, myUserId.value) ? 1 : 2), 'name']
  )
);

let _userSupportingConversation: any;

watch(
  () => _useRoute?.params,
  (params) => {
    isAskToJoinChat.value = false;
    isSelectSupporters.value = false;

    _verifyingChatOrgMember(params?.memberId?.toString());
  }
);

onMounted(async () => {
  await _verifyingChatSupportPermission();
  await _verifyingChatOrgMember(_useRoute?.params?.memberId?.toString());
});

const onSupporterClick = (supporter: any) => {
  selectedSupporterById.value[supporter?.id] =
    !selectedSupporterById.value[supporter?.id];
};

const onStartChatSupportingClick = () => {
  const selectedSupporters = orgSupporters.value?.filter(
    (supporter) => selectedSupporterById.value[supporter?.id]
  );

  const supportingConversation =
    _chatSupportingStore.prepareSupportersChatToOrgMember(
      orgSupportingInfo.value,
      selectedSupporters,
      orgUserInfo.value
    );

  activeAndFocusOnConversationId.value = `${supportingConversation?.id}`;

  _chatStore.activeBubbleChat(supportingConversation);
  _chatStore.setConversationActive(supportingConversation);

  _saveDefaultSupportersToLocal();

  _goToChat(supportingConversation?.id);
};

const onJoinChatSupportingClick = async () => {
  const mySupporterInfo = orgSupporters.value?.find((supporter) =>
    isSameId(supporter?.id, myUserId.value)
  );

  if (!_userSupportingConversation?.id || !mySupporterInfo) return;

  await _chatSupportingStore.addConversationSupporters(
    _userSupportingConversation?.id,
    [mySupporterInfo]
  );

  _goToChat(_userSupportingConversation?.id);
};

const userClickableUrl = computed(() =>
  chatSupportingStore().prepareSupportingUserExternalLink(
    orgUserInfo.value?.publicMemberId
  )
);

const onSupportingUserClick = () => {
  if (!userClickableUrl.value) return;

  window.open(userClickableUrl.value, 'SupportingExternalInfo');
};

const _verifyingChatSupportPermission = async () => {
  isVerifyingChatSupport.value = true;

  try {
    orgSupportingInfo.value =
      await _chatSupportingStore.verifyingChatSupportPermission();
  } catch (e) {
    console.log(e);
    _goToNotFound();
  }

  isVerifyingChatSupport.value = false;
};

const _verifyingChatOrgMember = async (memberId: string) => {
  if (!orgSupportingInfo.value || !memberId) return;

  isVerifyingOrgMember.value = true;

  try {
    orgUserInfo.value = await UserService.getInstance().getUserByPublicMemberId(
      memberId
    );

    if (!orgUserInfo.value) throw new Error('USER_NOT_FOUND');

    _userSupportingConversation =
      await ChatService.getOrgUserConversationSupporting(
        orgUserInfo.value.organizationId,
        orgUserInfo.value.id
      );

    // If has conversation, go to it
    if (_userSupportingConversation?.id) {
      const isMySupporterExisted =
        _userSupportingConversation?.members &&
        _userSupportingConversation?.members[myUserId.value];

      if (isMySupporterExisted) {
        _goToChat(_userSupportingConversation?.id);
      } else {
        _askToJoinChat();
      }
    } else {
      _prepareToSelectSupporters();
    }
  } catch (e) {
    console.log(e);
    _goToNotFound();
  }

  isVerifyingOrgMember.value = false;
};

const _prepareToSelectSupporters = () => {
  selectedSupporterById.value = {
    [myUserId.value]: true,
  };

  const defaultSupporter = getLocalStorage(StorageConstant.SUPPORTERS_DEFAULT);
  const defaultSupporterIds = defaultSupporter
    ? defaultSupporter.split(';')
    : null;

  defaultBySupporterId.value = arrToObj(defaultSupporterIds, null, () => true);

  orgSupporters.value
    ?.filter((supporter) => defaultBySupporterId.value[supporter?.supporterId])
    ?.forEach(
      (supporter) => (selectedSupporterById.value[supporter?.id] = true)
    );

  isSelectSupporters.value = true;
};

const _goToChat = (conversationId) => {
  _useRouter.push({
    name: 'Messages',
    params: { conversationId },
  });
};

const _askToJoinChat = () => {
  if (!_userSupportingConversation?.id) return;

  supportingSupporters.value = Object.values(
    _userSupportingConversation.members || {}
  ).filter((member: any) => member?.isSupporter);
  isAskToJoinChat.value = true;
};

const _goToNotFound = () => {
  _useRouter.push({ name: 'NotFound404' });
};

const _saveDefaultSupportersToLocal = () => {
  const defaultSupporterIds = orgSupporters.value
    ?.filter((supporter) => defaultBySupporterId.value[supporter?.supporterId])
    ?.map((supporter) => supporter?.supporterId)
    ?.join(';');

  setLocalStorage(StorageConstant.SUPPORTERS_DEFAULT, defaultSupporterIds);
};
</script>

<template>
  <SynLoading v-if="isVerifyingChatSupport || isVerifyingOrgMember" />

  <div
    v-else-if="isAskToJoinChat || isSelectSupporters"
    class="h-screen flex-center flex-col text-center gap-5"
  >
    <div class="text-4xl text-current-600">{{ $t('CHAT_SUPPORT_FULL') }}</div>

    <div
      class="
        min-w-[22rem]
        flex-center flex-col
        gap-2
        mb-5
        px-4
        py-3
        bg-green-50
        shadow
      "
      :class="{ 'cursor-pointer': userClickableUrl }"
      @click="onSupportingUserClick"
    >
      <SynAvatar
        custom-class="w-14 h-14"
        :src="orgUserInfo?.avatarUrl"
        :name="orgUserInfo?.fullName"
      />
      <div class="text-xl font-semibold">{{ orgUserInfo?.fullName }}</div>
      <div class="flex items-start gap-2">
        <SynAvatar
          custom-class="w-6 h-6"
          :src="orgUserInfo?.organizationLogoUrl"
          :name="orgUserInfo?.organizationName"
        />
        <div class="text-left leading-5">
          {{ orgUserInfo?.organizationName }}
        </div>
      </div>
    </div>

    <template v-if="isAskToJoinChat">
      <div class="text-xl">{{ $t('CHAT_SUPPORTING_BY_') }}</div>

      <div class="flex flex-col gap-1">
        <div
          v-for="supporter in supportingSupporters"
          :key="supporter?.id"
          class="
            min-w-[22rem]
            flex
            items-center
            justify-between
            gap-4
            px-4
            py-2
            rounded
            bg-current-50
          "
        >
          <div class="flex items-center justify-between gap-4">
            <SynAvatar
              custom-class="w-10 h-10"
              :src="supporter?.avatar"
              :name="supporter?.name"
              has-name
            />
            <span v-if="isSameId(supporter?.id, myUserId)">{{
              `(${$t('COMMON_LABEL_ME')})` || '(Me)'
            }}</span>
          </div>
        </div>
      </div>

      <div class="flex-center">
        <VigButton class="uppercase" @click="onJoinChatSupportingClick">
          {{ $t('CHAT_SUPPORT_JOIN') }}
        </VigButton>
      </div>
    </template>

    <template v-if="isSelectSupporters">
      <div class="text-xl">{{ $t('CHAT_SUPPORTER_SELECT') }}</div>

      <div class="flex flex-col gap-1">
        <div
          v-for="supporter in orgSupporters"
          :key="supporter?.id"
          role="button"
          class="
            min-w-[22rem]
            flex
            items-center
            justify-between
            gap-4
            px-4
            py-2
            rounded
            hover:bg-gray-50
          "
          :class="{
            'opacity-60 pointer-events-none': isSameId(supporter?.id, myUserId),
            'bg-current-50': selectedSupporterById[supporter?.id],
          }"
          @click="onSupporterClick(supporter)"
        >
          <div class="flex items-center justify-between gap-4">
            <SynIcon
              name="Checkbox"
              class="fill-current"
              :is-active="selectedSupporterById[supporter?.id]"
            />
            <SynAvatar
              custom-class="w-10 h-10"
              :src="supporter?.avatar"
              :name="supporter?.name"
              has-name
            />
            <span v-if="isSameId(supporter?.id, myUserId)">{{
              `(${$t('COMMON_LABEL_ME')})` || '(Me)'
            }}</span>
          </div>
          <div class="min-w-[7rem]">
            <AtomSwitch
              v-if="
                selectedSupporterById[supporter?.id] &&
                !isSameId(supporter?.id, myUserId)
              "
              v-model="defaultBySupporterId[supporter?.supporterId]"
            >
              <span class="text-sm" @click.stop>{{
                $t('COMMON_LABEL_DEFAULT')
              }}</span>
            </AtomSwitch>
          </div>
        </div>
      </div>

      <div class="flex-center">
        <VigButton class="uppercase" @click="onStartChatSupportingClick">
          {{ $t('CHAT_SUPPORT_START') }}
        </VigButton>
      </div>
    </template>
  </div>
</template>
