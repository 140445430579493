import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import { rankDomainData } from '@/ui/common/molecules/SynAvatar/ranking-constants';
import { translate } from '@/ui/plugins/i18n/myi18n';

export default defineStore({
    id: storeId.dashboardCreatedTask,
    state: () => ({
        isLoading: true,
        _taskService: TaskService.getInstance(),
        createdTasks: [] as any,
        filterCreatedTasks: [] as any,
        createdTaskRankingByDomain: [] as any,
        createdTaskOverview: [] as any,
        maxCreatedTask: 0,
        todayInfo: {} as any,
    }),
    getters: {},
    actions: {
        async getCreatedTasks() {
            const res: any = await this._taskService.getCreatedTasks();

            if (!res?.result || res.result?.length == 0) return;

            this.createdTasks = res.result.map((o) => {
                return {
                    ...o,
                    urgency: getUrgencyValue(o?.priority, o?.important),
                    creationTime:
                        new Date(o?.creationTime).getTime() -
                        new Date(o?.creationTime).getTimezoneOffset() *
                            60 *
                            1000,
                };
            });
        },
        async getCreatedTaskRankingByDomain() {
            const res: any = await this._taskService.getCreatedTaskByDomain();
            const dataList = res.result?.slice(0, 4);

            if (!dataList || dataList.length == 0) return;
            let otherData: any = {};
            if (res.result?.length > dataList.length) {
                const otherDataList = res.result?.slice(4, res.result?.length);
                otherData =
                    otherDataList?.length > 1
                        ? otherDataList.reduce(
                              (old, current) => {
                                  const numberOfTaskCreated =
                                      old?.data + current?.numberOfTaskCreated;
                                  return {
                                      ...current,
                                      name: 'Other',
                                      data: numberOfTaskCreated,
                                      color: rankDomainData[5]?.color,
                                  };
                              },
                              { data: 0 }
                          )
                        : {
                              ...otherDataList[0],
                              name: otherDataList[0]?.domainName,
                              data: otherDataList[0]?.numberOfTaskCreated,
                              color: rankDomainData[5]?.color,
                          };
            }

            this.createdTaskRankingByDomain = dataList?.map((o, index) => {
                return {
                    ...o,
                    name: o?.domainName,
                    data: o?.numberOfTaskCreated,
                    color: rankDomainData[index + 1]?.color,
                };
            });

            if (otherData?.data)
                this.createdTaskRankingByDomain.push(otherData);
        },
        async getCreatedTaskOverview() {
            const res: any = await this._taskService.getCreatedTaskOverview();

            const data = res?.result;
            if (!data || data.length == 0) return;
            const createdList = data.map((o) => o.numberOfTaskCreated);
            this.maxCreatedTask = Math.max(...createdList);

            this.createdTaskOverview = [
                {
                    label:
                        translate('CHART_LABEL_CREATED_TASK') || 'Created Task',
                    data: createdList,
                    backgroundColor: '#4BB0B2',
                    hoverBackgroundColor: '#008d8d',
                    minBarLength: 1,
                    categoryPercentage: 0.5,
                },
            ];

            const createdToday = data.filter((o) => {
                const today = new Date();
                const checkDay = new Date(o.creationTime);
                return (
                    checkDay.getFullYear() === today.getFullYear() &&
                    checkDay.getMonth() === today.getMonth() &&
                    checkDay.getDate() === today.getDate()
                );
            });
            this.todayInfo = createdToday?.length > 0 ? createdToday[0] : {};
        },

        async getAllDataForCreatedTaskPage() {
            this.isLoading = true;
            await Promise.all([
                this.getCreatedTasks(),
                this.getCreatedTaskRankingByDomain(),
                this.getCreatedTaskOverview(),
            ]);
            this.isLoading = false;
        },
        updateFilterCreatedTasks(arr) {
            this.filterCreatedTasks = arr;
        },
    },
});
