<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from 'vue';
import PlanInfoAndCalculateSimpleCard from '@/ui/modules/settings/payments/package-plan/PlanInfoAndCalculateSimpleCard.vue';
import planStore from '@/store/plans';
import CatalogService from '@/application/services/CatalogService';
import paymentStore from '@/store/payment';
import organizationStore from '@/store/organization';
import { IOrganizationDetail } from '@/application/types/organization/organization.type';
import TictopWebSocket, {
  USER_REG_EVENT,
} from '@/ui/plugins/websocket/ws-instance';
import {
  EPaymentPurpose,
  IPaymentVoucherDetail,
} from '@/application/types/payment.types';
import OrganizationPlan from '@/domain/entities/payment/OrganizationPlan';
import ComparePlansTable from '@/ui/modules/settings/payments/compare-plan/ComparePlansTable.vue';
import SelectPlanButton from '@/ui/modules/plan/SelectPlanButton.vue';
import { EPlanState } from '@/application/types/plan.types';

defineProps<{
  hasBack?: boolean;
  isLoading?: boolean;
  isHiddenTitle?: boolean;
  isShowCompare?: boolean;
  hasUseTrial?: boolean;
}>();
const emit = defineEmits([
  'handleSelectTrial',
  'onPayment',
  'onChosePlan',
  'onBack',
  'use-free-success',
  'useTrial',
]);

let ws: TictopWebSocket;
const _planStore = planStore();
const allPlanByIds = computed<any>(() => _planStore.allPlanByIds);
const planCapacityByKeys = computed<any>(() => _planStore.planCapacityByKeys);
const _paymentStore = paymentStore();
const _organizationStore = organizationStore();

const organizationInfo = computed<IOrganizationDetail>(() => {
  return _organizationStore.organizationInfo;
});
const paymentConfigs = computed(() => _paymentStore.paymentConfigs);

const currentOrgPlan = computed(
  () =>
    new OrganizationPlan(
      _planStore.currentOrgPlan,
      organizationInfo.value,
      paymentConfigs.value,
      planCapacityByKeys.value,
      _planStore.capacityByPlanIds
    )
);

const isCreatingToken = computed<boolean>(
  () => _paymentStore.isCreatingPaymentToken
);

const totalUsers = ref<number>(
  planCapacityByKeys.value?.totalMember?.usage || 10
);
const voucherDetail = ref<IPaymentVoucherDetail>({
  code: '',
  valueInPercent: 0,
  valueInMoney: 0,
  currency: '',
  name: '',
  description: '',
  validFrom: '',
  validTo: '',
  isValid: true,
  messageCode: null,
  message: '',
  excludedBonusDay: false,
  maxUserOffer: 0,
  maxPaymentTermOffer: 0,
});
const availableVouchers = ref<IPaymentVoucherDetail[]>([]);
onMounted(async () => {
  ws = new TictopWebSocket();

  availableVouchers.value = await _paymentStore.getAvailableVouchers();
  if (availableVouchers.value?.length > 0) {
    voucherDetail.value = availableVouchers.value[0];
  }
});

onUnmounted(() => {
  ws?.close();
});

const totalUserOptions = [10, 20, 30, 50, 100, 150];

const selectedPlanId = ref<any>(null);

// const handleUsingTrial = async (planId) => {
//   selectedPlanId.value = planId;
//   try {
//     await CatalogService.getInstance().usingTrialPackage(planId);
//     isShowTrialCongratulation.value = true;

//     emit('handleSelectTrial', planId);
//   } catch (error) {
//     console.log('🚀 Hyrin ~ handleUsingTrial ~ error:', error);
//   }
// };
const handlePayImmediately = async (planId, period) => {
  ws.send(USER_REG_EVENT.CLICK_PAY_AND_GO_PAYMENT_PAGE, null, {
    planId,
    period,
  });
  selectedPlanId.value = planId;
  if (currentOrgPlan.value.stateCode !== EPlanState.INIT) {
    onPayment(planId, period);
    return;
  }
  await CatalogService.getInstance().payImmediately(planId);

  onPayment(planId, period);
};

const onPayment = async (planId, period) => {
  if (!planId) return;
  try {
    // await _paymentStore.checkAndMakeNewPayment({
    //   planId,
    //   purpose: null,
    //   period,
    //   totalUsers: totalUsers.value,
    // });

    _paymentStore.isCreatingPaymentToken = true;
    await new OrganizationPlan(
      _planStore.currentOrgPlan,
      organizationStore().organizationInfo,
      _paymentStore.paymentConfigs,
      _planStore.planCapacityByKeys,
      _planStore.capacityByPlanIds
    ).createPayment({
      planId,
      purpose: null,
      period,
      totalUsers: totalUsers.value,
    });
  } catch (error) {
    console.log(
      '🚀 ~ file: PlanManage.vue ~ line 49 ~ onUpgrade ~ error',
      error
    );
  } finally {
    _paymentStore.isCreatingPaymentToken = false;
  }
};

const isLoading = ref<boolean>(false);
const onCreatePaymentToken = async (
  planId,
  totalUsers,
  period,
  purpose: EPaymentPurpose | null
) => {
  try {
    isLoading.value = true;
    if (purpose == EPaymentPurpose.GET_MORE)
      await _paymentStore.createPaymentToken({
        planId,
        purpose: EPaymentPurpose.GET_MORE,
        period,
        totalUsers,
      });
    else {
      //   await _paymentStore.checkAndMakeNewPayment({
      //   planId,
      //   purpose,
      //   period,
      //   totalUsers,
      // });
      _paymentStore.isCreatingPaymentToken = true;

      await new OrganizationPlan(
        _planStore.currentOrgPlan,
        organizationStore().organizationInfo,
        _paymentStore.paymentConfigs,
        _planStore.planCapacityByKeys,
        _planStore.capacityByPlanIds
      ).createPayment({
        planId,
        purpose,
        period,
        totalUsers,
      });
      _paymentStore.isCreatingPaymentToken = false;
    }
    isLoading.value = false;
  } catch (error) {
    console.log(
      '🚀 ~ file: PlanManage.vue ~ line 49 ~ onUpgrade ~ error',
      error
    );
  }
};
const onGotoWebContact = () => {
  window.open('https://tictop.vn/contact', '_blank');
};

const isDowngradingToFree = ref<boolean>(false);
const handleToUseBasic = async () => {
  isDowngradingToFree.value = true;
  try {
    const result = await currentOrgPlan.value.useFreePackage();

    if (!result?.success) return;

    emit('use-free-success', result?.purpose);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isDowngradingToFree.value = false;
  }
};

const onUpgrade = async (newPlanId, period) => {
  currentOrgPlan.value.upgradeHigherPlan(newPlanId, () => {
    onCreatePaymentToken(
      newPlanId,
      totalUsers.value,
      period,
      EPaymentPurpose.PURCHASE
    );
  });
};

const onDowngrade = async (newPlanId, period) => {
  currentOrgPlan.value.downgradeToLowerPlan(newPlanId, () => {
    onCreatePaymentToken(
      newPlanId,
      totalUsers.value,
      period,
      EPaymentPurpose.PURCHASE
    );
  });
};
</script>
<template>
  <div
    class="flex-1 flex flex-col w-full h-full"
    :class="{
      'space-y-6': isHiddenTitle,
    }"
  >
    <!-- HEADER -->
    <section class="flex-center px-8 pb-8 flex-col relative">
      <span
        v-if="hasBack"
        class="
          absolute
          top-0
          left-6
          px-4
          py-2
          rounded
          hover:bg-gray-100
          flex-center
          space-x-2
          cursor-pointer
        "
        @click="$emit('onBack')"
      >
        <SynIcon name="back" class="fill-current-800" />
        <span class="text-sm text-current-800">
          {{ $t('COMMON_LABEL_BACK') }}
        </span>
      </span>
      <template v-if="!isHiddenTitle">
        <div class="text-gray-800 text-xl pb-2 w-2/3 text-center font-semibold">
          {{ $t('OVERVIEW_TITLE_CHOOSE_PLAN') }}
        </div>
        <div class="text-current-800 pb-2 pt-2">
          {{ $t('OVERVIEW_SUB_TITLE_CHOOSE_PLAN') }}
        </div>
      </template>

      <div class="flex-center space-x-4">
        <span class="w-12 h-12 flex-center rounded-md bg-gray-100">
          <SynIcon name="group" custom-class="w-8 h-8 fill-current-800" />
        </span>
        <span class="h-12 w-32 border border-current rounded-md">
          <SynInputNumber
            v-model="totalUsers"
            :min="1"
            custom-class=" h-12 text-xl"
          />
        </span>
        <span
          v-for="(item, index) in totalUserOptions"
          :key="index"
          class="
            h-12
            px-4
            py-2
            border border-current-200
            rounded-md
            cursor-pointer
            flex-center
            text-xl
          "
          :class="{
            'bg-current text-white': totalUsers == item,
            'text-current-800': totalUsers !== item,
          }"
          @click="totalUsers = item"
        >
          {{ item }}
        </span>
      </div>
      <div class="flex-center pt-4">
        <a href="/user-management" target="_blank" class="text-current">
          {{ $t('ADMIN_LABEL_GO_TO_THE_MEMBER_MANAGEMENT_SCREEN') }}
        </a>
      </div>
    </section>

    <!-- BODY -->
    <section class="flex-1 min-h-0 flex-center text-current-800">
      <div class="flex-center w-full max-w-[1260px] gap-8">
        <div
          v-for="(planId, index) in Object.keys(allPlanByIds)"
          :key="index"
          class="w-1/3"
        >
          <PlanInfoAndCalculateSimpleCard
            :plan-id="planId"
            :total-users="totalUsers"
            :voucher="voucherDetail"
          >
            <template
              #action="{
                currentPlanId,
                currentPlanActive,
                period,
                isOverVoucherLimit,
              }"
            >
              <SelectPlanButton
                :organization-plan="currentOrgPlan"
                :current-plan-id="currentPlanId"
                :current-plan-active="currentPlanActive"
                :voucher-detail="voucherDetail"
                :payment-configs="paymentConfigs"
                :is-over-voucher-limit="isOverVoucherLimit"
                :is-loading="isDowngradingToFree || isCreatingToken"
                :has-use-trial="hasUseTrial"
                @handle-to-use-basic="handleToUseBasic"
                @handle-pay-immediately="
                  handlePayImmediately(currentPlanId, period)
                "
                @on-goto-web-contact="onGotoWebContact"
                @on-create-payment-token="
                  onCreatePaymentToken(currentPlanId, totalUsers, period, null)
                "
                @on-upgrade="onUpgrade(currentPlanId, period)"
                @on-downgrade="onDowngrade(currentPlanId, period)"
                @on-chose-plan="$emit('onChosePlan', currentPlanId)"
                @use-trial="$emit('useTrial')"
              />
            </template>
          </PlanInfoAndCalculateSimpleCard>
        </div>
      </div>
    </section>

    <!-- COMPARE -->
    <template v-if="isShowCompare">
      <!-- COMPARE   -->
      <SynSectionTitle
        :title="$t('OVERVIEW_TITLE_COMPARE_PLANS')"
        custom-class="hidden md:flex pt-16"
        sub-title=""
      />

      <div class="hidden md:flex w-full flex-center pb-24 bg-white">
        <ComparePlansTable>
          <template #action-button="{ key: currentPlanId }">
            <SelectPlanButton
              :organization-plan="currentOrgPlan"
              :current-plan-id="currentPlanId"
              current-plan-active
              :voucher-detail="voucherDetail"
              :payment-configs="paymentConfigs"
              :is-loading="isDowngradingToFree || isCreatingToken"
              :has-use-trial="hasUseTrial"
              @handle-to-use-basic="handleToUseBasic"
              @handle-pay-immediately="handlePayImmediately(currentPlanId, 6)"
              @on-goto-web-contact="onGotoWebContact"
              @on-create-payment-token="
                onCreatePaymentToken(currentPlanId, totalUsers, 6, null)
              "
              @on-upgrade="onUpgrade(currentPlanId, 6)"
              @on-downgrade="onDowngrade(currentPlanId, 6)"
              @on-chose-plan="$emit('onChosePlan', currentPlanId)"
              @use-trial="$emit('useTrial')"
            />
          </template>
        </ComparePlansTable>
      </div>
    </template>
  </div>
</template>
