<script setup lang="ts">
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
// import {useRouter} from 'vue-router';
import taskStore from '@/store/task';
import notificationStore from '@/store/notification/notification.store';
// import userStore from '@/store/user';
// import {encryptUserId, encryptGroupId} from '@/ui/plugins/crypto/crypto';
import { taskStates } from '@/ui/hooks/taskHook';
import { TaskLife } from '@/domain/enums/taskEnum';
import {
  getBgClass,
  getDetailTaskUrl,
  getUrgencyValue,
} from '@/ui/hooks/taskHook';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import {
  INotification,
  INotificationMainDataTask,
  INotificationTaskWorkflow,
  ITaskNotificationHistory,
  ENotificationTaskActionType,
} from '@/application/types/notification.types';
// import NotificationTaskLastState from '@/ui/modules/notification/NotificationTaskLastState.vue';
import CommentReactionList from '@/ui/modules/task/components/activity-comment/CommentReactionList.vue';
import NotificationReactionButton from '@/ui/modules/notification/components/NotificationReactionButton.vue';
import ListReactionsInCommentModal from '@/ui/modules/task/components/activity-comment/ListReactionsInCommentModal.vue';
import { TASK_PIN_BY_CODE } from '@/application/constants/task.const';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import NotificationTaskHistory from '@/ui/modules/notification/task/NotificationTaskHistory.vue';
import TaskWorkflowStepTitle from '@/ui/modules/task/workflow/step/TaskWorkflowStepTitle.vue';
import { NOTIFICATION_TASK_UI } from '@/application/constants/notification.const';
import { ITaskWorkflowEntity } from '@/domain/entities/task/TaskWorkflowEntity';
import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import taskDetailStore from '@/store/task/detail';
import { NotificationKey } from '@/ui/common/constants/notifications';

const RECENTLY_NOTIFICATION_MAX_COUNT = 3;
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const props = withDefaults(
  defineProps<{
    propId?: string;
    category?: string;
    notificationItem?: any;
    isView?: boolean;
    isHiddenActionUser?: boolean;
    users?: any[];
  }>(),
  {
    category: 'all',
    isHiddenActionUser: false,
  }
);
const emit = defineEmits([
  'getNotificationLinkUrl',
  'handleClickMarkRead',
  'handleDeleteNotification',
  'onUserActionClick',
  'onUpdateReaction',
  'onScrollTo',
]);

// const _userStore = userStore();
const _taskStore = taskStore();
// const router = useRouter();
const _taskDetailStore = taskDetailStore();

const notification = computed<INotification>(() => props.notificationItem);
const mainData = computed<INotificationMainDataTask>(() => {
  return notification.value?.mainData;
});
const taskWorkflowDetail = computed<ITaskWorkflowEntity | undefined>(() => {
  const taskWorkflow: INotificationTaskWorkflow | undefined =
    mainData.value?.taskWorkflow;
  return {
    id: taskWorkflow?.workflowId,
    taskId: taskWorkflow?.taskWorkflowId
      ? parseInt(taskWorkflow?.taskWorkflowId)
      : 0,
    code: taskWorkflow?.workflowCode,
    name: taskWorkflow?.workflowName,
    steps: taskWorkflow?.workflowSteps?.map((ws) => {
      return {
        id: ws?.stepId,
        name: ws?.stepName,
        stepOrder: ws?.stepOrder,
        status: ws?.stepStatus,
        taskId: ws?.taskId,
      };
    }),
  };
});

const currentStepDetail = computed<ITaskWorkflowStep | undefined>(() => {
  return taskWorkflowDetail.value?.steps?.find(
    (step) => step?.taskId && step?.taskId?.toString() == mainData.value?.taskId
  );
});

const notifUser = computed(() =>
  props.users?.find((user: any) => user?.id === notification.value?.userId)
);

const getNotificationLinkUrl = (notification) => {
  try {
    const taskCode = notification?.mainData?.taskCode;
    if (!notification || !taskCode) {
      return `/`;
    }
    return getDetailTaskUrl(taskCode, false);
  } catch {
    return `/`;
  }
};

// const getLinkUrl = (notification, type) => {
//   return type == 'group' && notification?.groupId
//       ? `/tasks/groups/${encryptGroupId(notification?.groupId)}`
//       : `/tasks/users/${encryptUserId(notification?.executorId)}`;
// };

// const onGotoLink = (event, notification, type) => {
//   event.preventDefault();
//   const linkUrl = getLinkUrl(notification, type);
//   router.push(linkUrl);
// };

const getTaskConfigName = (sitKey, typeKey) => {
  return _taskStore.getConfigName(sitKey, typeKey);
};

const reactionListByNotificationId = ref({} as any);

const isOpenViewReactionsModal = ref(false);
const onOpenViewReactionsModal = (reactions, id) => {
  reactionListByNotificationId.value.reactions = reactions;
  reactionListByNotificationId.value.id = id;
  isOpenViewReactionsModal.value = true;
};

const onRemoveReaction = async (id, value) => {
  await handleReactionOnNotificationItem(id, value?.content, true);

  if (reactionListByNotificationId.value.reactions?.length <= 0) {
    isOpenViewReactionsModal.value = false;
  }
};

const onAddReaction = (id, value) => {
  handleReactionOnNotificationItem(id, value, false);
};

const handleReactionOnNotificationItem = async (
  notificationId: string,
  content: string,
  remove: boolean
) => {
  try {
    const result = await notificationStore().reactionOnNotification(
      notificationId,
      content,
      remove
    );
    const reactions = result?.reactions || [];
    reactionListByNotificationId.value.reactions = reactions;
    emit('onUpdateReaction', reactions);
  } catch (error) {
    console.log(error);
  }
};

const currentPin = computed<any>(() => {
  if (!mainData.value?.pinnedColor) return TASK_PIN_BY_CODE.UN_PIN;
  return TASK_PIN_BY_CODE[mainData.value?.pinnedColor];
});

const recentlyNotifications = computed<ITaskNotificationHistory[]>(() => {
  if (
    notification.value?.histories?.length ==
    RECENTLY_NOTIFICATION_MAX_COUNT + 1
  )
    return notification.value?.histories;
  return notification.value?.histories?.slice(
    0,
    RECENTLY_NOTIFICATION_MAX_COUNT
  );
});

const hasNotificationUnread = computed<any>(() => {
  return notification.value?.histories?.some((his) => !his.status);
});
const otherNotifications = computed<ITaskNotificationHistory[]>(() => {
  return notification.value?.histories?.slice(RECENTLY_NOTIFICATION_MAX_COUNT);
});
const hasUnreadNotificationInOtherList = computed<boolean>(() => {
  return otherNotifications.value?.some((history) => !history?.status);
});
const isShowOlderNotifications = ref<boolean>(false);

const onToggleCollapse = () => {
  if (!isShowOlderNotifications.value) isShowOlderNotifications.value = true;
  else onCloseCollapse();

  emit('onScrollTo');
};
const onCloseCollapse = () => {
  isShowOlderNotifications.value = false;

  emit('onScrollTo');
};
const listTaskLifeShow = [
  TaskLife.Finished,
  TaskLife.Duplicated,
  TaskLife.Cancelled,
  TaskLife.Archived,
];
const lisCategory = {
  all: 'all',
  new: 'new',
  updated: 'updated',
  finished: 'finished',
};
const isShowSticker = computed(() => {
  if (mainData.value?.taskDeleted) return true;
  if (!mainData.value?.currentTaskLife) return false;
  if (!listTaskLifeShow?.includes(mainData.value?.currentTaskLife))
    return false;

  if (props.category !== lisCategory.finished) {
    return !(
      mainData.value?.currentTaskLife === TaskLife.Finished &&
      mainData.value?.keyNotification === NotificationKey.Finished
    );
  } else return false;

  // return true;
});

const onClickHistory = (history) => {
  if (history?.actionType == ENotificationTaskActionType.Comment) {
    const commentId =
      history?.taskCommentObject?.task_comment_id ||
      history?.taskCommentObject?.taskCommentId;
    _taskDetailStore.updateHighlightCommentIds([commentId]);
    _taskDetailStore.setNavigatePoint('comment', commentId);
  }
};
const onClickHeader = () => {
  const notificationOfCommentIds = notification.value?.histories
    ?.filter((n) => {
      return n?.actionType == ENotificationTaskActionType.Comment;
    })
    ?.map((o) => {
      return (
        o?.taskCommentObject?.task_comment_id ||
        o?.taskCommentObject?.taskCommentId
      );
    });
  if (notificationOfCommentIds && notificationOfCommentIds?.length > 0)
    _taskDetailStore.updateHighlightCommentIds(notificationOfCommentIds);

  const taskCommentId = mainData.value?.taskCommentObject?.task_comment_id;

  if (taskCommentId)
    _taskDetailStore.setNavigatePoint('comment', taskCommentId);
};
</script>

<template>
  <div
    :id="`notification-id_${notification?.id}`"
    v-bind="$attrs"
    class="
      item-noti
      relative
      border-b border-gray-300
      flex-center flex-col
      text-sm text-gray-700
      hover:bg-current-50 hover:bg-opacity-30
      cursor-pointer
    "
  >
    <!-- HEADER -->
    <div
      class="flex items-start space-x-2 w-full pt-2 pb-1"
      :class="[
        notificationItem?.status ? '' : 'bg-current-50 bg-opacity-50 shadow-sm',
      ]"
      @click="onClickHeader"
    >
      <div class="w-14 pl-3">
        <UserByAction
          :avatar-class="'h-12 w-12'"
          :user-id="notificationItem?.executorId"
          :user-avatar="notificationItem?.executorAvatar"
          :user="notifUser"
          :is-show-action-user="!isHiddenActionUser"
          is-hidden-name
          @on-action-click="$emit('onUserActionClick', $event)"
        />
      </div>

      <!-- TASK INFORMATION -->
      <div class="flex-1 rounded pt-1 relative">
        <!-- PIN ICON -->
        <span
          v-if="currentPin?.code !== 'UN_PIN'"
          class="absolute -left-1 -top-2 z-10 -rotate-90"
          @click.stop.prevent="() => {}"
        >
          <SynIcon
            name="pin"
            custom-class="w-5 h-5"
            :is-active="currentPin?.code !== 'UN_PIN'"
            :style="{
              fill: currentPin?.color,
            }"
          />
        </span>
        <!-- P1: BODY NOTIFICATION -->
        <div class="flex flex-col">
          <span
            v-if="taskWorkflowDetail?.id"
            class="
              w-full
              pl-2
              items-center
              inline-flex
              text-purple-600
              relative
            "
          >
            <TaskWorkflowStepTitle
              :workflow-detail="taskWorkflowDetail"
              :current-step-detail="currentStepDetail"
            />
          </span>

          <span v-if="mainData?.taskDeleted" class="pl-2 text-overflow-hidden">
            {{ mainData?.taskName }}
          </span>
          <a v-else :href="getNotificationLinkUrl(notification)">
            <div
              :title="mainData?.taskName"
              class="pl-2 text-gray-600 text-overflow-hidden"
              :class="!notification?.status ? 'font-semibold' : ''"
            >
              {{ mainData?.taskName }}
            </div>
          </a>
        </div>

        <div
          v-if="
            mainData?.actionType == 'Finished' ||
            mainData?.actionType == 'AddNew'
          "
          class="
            flex
            space-x-2
            items-center
            justify-end
            shrink-0
            absolute
            top-0
            right-0
            do-not-close-dropdown
          "
        >
          <CommentReactionList
            :reactions-list="notification?.reactions || []"
            @click.stop="
              onOpenViewReactionsModal(
                notification?.reactions || [],
                notification?.id
              )
            "
          />
          <NotificationReactionButton
            :reactions-list="notification?.reactions || []"
            @on-emoji-click="(value) => onAddReaction(notification?.id, value)"
          />
        </div>

        <!-- P2: TASK CODE, PRIORITY -->
        <div
          class="flex w-full justify-between items-end space-x-4"
          :class="!notification.status ? 'text-current-800' : 'text-gray-500'"
        >
          <div class="flex items-end space-x-3 text-2xs pl-2">
            <span class="">{{ formatTaskCode(mainData?.taskCode) }}</span>
            <div
              v-if="taskStates[mainData?.taskLife]?.key !== TaskLife.Finished"
              class="min-w-max text-gray-800"
              :class="taskStates[mainData?.taskLife]?.color"
            >
              {{
                $t(taskStates[mainData?.taskLife]?.nameCode) ||
                $t('TASK_STATE_CREATED')
              }}
            </div>
            <div class="flex-center space-x-1 min-w-max">
              <div
                v-if="mainData?.isPlannedToTreat"
                style="font-size: 10px"
                class="flex-center px-2 py-0.25 rounded-full"
                :class="getBgClass(1, 'planType')"
              >
                {{ getTaskConfigName(1, 'planType') }}
              </div>
              <div
                v-if="mainData?.isPlannedToFinish"
                class="flex-center px-2 py-0.25 rounded-full"
                style="font-size: 10px"
                :class="getBgClass(2, 'planType')"
              >
                {{ getTaskConfigName(2, 'planType') }}
              </div>
              <PriorityTask
                v-if="mainData?.important !== null"
                :urgency="
                  getUrgencyValue(mainData?.priority, mainData?.important)
                "
                size="tiny"
                is-hidden-label
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col" style="width: 65%">
          <div
            style="font-size: 11px"
            class="pl-2 w-full flex items-start space-x-1"
          >
            <span
              v-if="mainData?.groupName"
              :title="mainData?.groupName"
              class="text-gray-600 truncate"
            >
              {{ mainData?.groupName?.substring(2) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <template
      v-for="history in recentlyNotifications"
      :key="history?.notificationId"
    >
      <NotificationTaskHistory
        :history="history"
        @click="onClickHistory(history)"
      />
    </template>

    <!-- OTHER NOTIFICATION -->

    <div
      v-if="otherNotifications?.length > 1"
      class="
        w-full
        flex-center
        space-x-2
        text-gray-800
        hover:text-current-500
        sticky
        -top-1
        z-10
      "
      :class="[
        hasUnreadNotificationInOtherList ? 'bg-current-50 bg-opacity-50' : '',
      ]"
      @click.stop="onToggleCollapse"
    >
      <div class="relative">
        <span class="text-xs">
          {{
            $t(
              isShowOlderNotifications
                ? 'NOTIFICATION_HIDDEN_MORE_WITH_TOTAL'
                : 'NOTIFICATION_SEE_MORE_WITH_TOTAL',
              {
                total: otherNotifications?.length || '',
              }
            )
          }}
        </span>

        <span
          v-if="hasUnreadNotificationInOtherList"
          class="
            absolute
            -top-1
            -right-1
            rounded-full
            w-2
            h-2
            bg-blue-500
            mt-0.5
          "
        ></span>
      </div>

      <SynIcon
        :name="'sort-down'"
        custom-class="h-2 w-2 fill-gray-500"
        :class="isShowOlderNotifications ? '' : '-rotate-90'"
      />
    </div>
    <template v-if="isShowOlderNotifications">
      <NotificationTaskHistory
        v-for="history in otherNotifications"
        :key="history?.notificationId"
        :history="history"
        @click="onClickHistory(history)"
      />
    </template>

    <!-- LAST STATE TASK  -->
    <!-- <NotificationTaskLastState
      :current-state="
        actionType == ENotificationTaskActionType.AddNew
          ? 'NEW'
          : actionType == ENotificationTaskActionType.Finished
          ? 'FINISHED'
          : 'MODIFIED'
      "
      class="absolute right-0 top-0"
    /> -->

    <!-- LAST STATE TASK  -->
    <div v-if="isShowSticker" class="min-w-max absolute right-6 top-1">
      <div class="flex-center flex-row flex-nowrap -rotate-12">
        <SynStickerLabel
          :label="
            mainData?.taskDeleted
              ? $t('COMMON_LABEL_DELETED')
              : $t(taskStates[mainData?.currentTaskLife]?.nameCode) || ''
          "
          :is-arrow="false"
          :custom-class="`border-2 border-${
            mainData?.taskDeleted
              ? 'red'
              : taskStates[mainData?.currentTaskLife]?.colorKey
                  ?.split('-')
                  ?.shift()
          }-400 text-${
            mainData?.taskDeleted
              ? 'red-400'
              : taskStates[mainData?.currentTaskLife]?.colorKey
          }`"
        />
      </div>
    </div>

    <!-- MARK AS SEEN OR CANCEL -->
    <div
      v-if="!isView"
      class="
        item-noti__action
        absolute
        right-0
        bottom-2
        flex
        items-center
        space-x-2
      "
    >
      <div
        v-if="hasNotificationUnread"
        :title="$t('COMMON_LABEL_SEEN')"
        class="
          border border-green-500
          bg-white bg-opacity-70
          flex-center
          w-6
          h-6
          rounded-full
          text-green-500
          fill-green-500
          hover:fill-white hover:text-white hover:bg-green-500
        "
        @click.stop="$emit('handleClickMarkRead')"
      >
        <SynIcon name="check" size="small" />
      </div>

      <div
        :title="$t('NOTIFICATION_DELELTE_NOTIFICATION_SUB')"
        class="
          border border-red-500
          flex-center
          bg-white bg-opacity-70
          w-6
          h-6
          rounded-full
          text-red-500
          fill-red-500
          hover:fill-white hover:text-white hover:bg-red-500
        "
        @click.stop="$emit('handleDeleteNotification')"
      >
        <SynIcon
          name="cancel"
          size="small"
          custom-class="w-2.5 h-2.5"
          is-active
        />
      </div>
    </div>
  </div>
  <ListReactionsInCommentModal
    v-if="isOpenViewReactionsModal"
    :reactions="reactionListByNotificationId?.reactions"
    @on-cancel="isOpenViewReactionsModal = false"
    @on-reaction-remove="
      (value) => onRemoveReaction(reactionListByNotificationId.id, value)
    "
  />
</template>

<style scoped lang="scss">
.item-noti:hover .item-noti__action {
  /* display: block; */
  visibility: visible;
}

.item-noti__action {
  /* display: none; */
  visibility: hidden;
}

.item-noti:active {
  visibility: visible;
}

.item-noti:active .item-noti__action {
  /* display: block; */
  visibility: visible;
}
.notification-task-item_comment :deep(br) {
  display: none;
}
</style>
