<script setup lang="ts">
import { ref, watch } from 'vue';
import WorkflowStepTypeEntity from '@/domain/entities/workflow/WorkflowStepTypeEntity';
import { IWorkflowStepType } from '@/application/types/workflow/workflow.types';

const props = withDefaults(
  defineProps<{
    viewMode: 'VIEW' | 'EDIT';
    stepTypeDetail: IWorkflowStepType;
    readonly?: boolean;
  }>(),
  {
    viewMode: 'VIEW',
  }
);

const emit = defineEmits<{
  (e: 'update:stepTypeDetail', step: WorkflowStepTypeEntity): void;
  (e: 'remove'): void;
}>();
const workflowStepTypeData = ref<WorkflowStepTypeEntity>(
  new WorkflowStepTypeEntity(props.stepTypeDetail || {})
);

const initComponent = () => {};

watch(
  () => props.stepTypeDetail?.id,
  () => {
    if (workflowStepTypeData.value?.id !== props.stepTypeDetail?.id)
      workflowStepTypeData.value = new WorkflowStepTypeEntity(
        props.stepTypeDetail || {}
      );
  }
);
const updatePropData = () => {
  emit(
    'update:stepTypeDetail',
    new WorkflowStepTypeEntity({
      ...workflowStepTypeData.value,
    })
  );
};

initComponent();
</script>
<template>
  <div class="w-full h-full flex flex-col space-y-4 p-4">
    <div class="flex-1 flex flex-col space-y-4">
      <template v-if="viewMode == 'EDIT'">
        <!-- Name -->
        <section class="flex w-full items-center justify-center space-x-4">
          <div class="w-full">
            <SynFormInput
              :title="$t('COMMON_LABEL_NAME')"
              :error-message="
                workflowStepTypeData.name?.trim() === ''
                  ? 'TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED'
                  : ''
              "
            >
              <SynInput
                v-model="workflowStepTypeData.name"
                class="w-full"
                autofocus
                placeholder="e.g. MARKETING, SALE ..."
                :max-length="80"
                @update:model-value="updatePropData"
              />
            </SynFormInput>
          </div>
        </section>
      </template>
      <template v-if="viewMode == 'VIEW'">
        <!-- Name -->
        <section class="flex w-full items-center justify-center space-x-4">
          <div class="w-full">
            <SynFormInput :title="$t('COMMON_LABEL_NAME')">
              <span class="px-2 py-1 bg-gray-50 w-full h-10">
                {{ workflowStepTypeData?.name }}
              </span>
            </SynFormInput>
          </div>
        </section>

        <!-- Total used -->
        <section class="w-full flex flex-col space-y-2">
          <div class="w-full text-gray-500">
            <span>
              {{ $t('WORKFLOW_LABEl_STEP_IN_USE') }}
            </span>
          </div>

          <div
            v-if="
              workflowStepTypeData?.usedStepTemplates &&
              workflowStepTypeData?.usedStepTemplates?.length > 0
            "
            class="flex items-center"
          >
            <span
              v-for="(
                workflow, index
              ) in workflowStepTypeData?.usedStepTemplates"
              :key="workflow?.id"
              class="text-xs text-current-600 pr-1"
              :title="workflow?.name"
            >
              {{ workflow?.name }}

              <template
                v-if="
                  index < workflowStepTypeData?.usedStepTemplates?.length - 1
                "
              >
                <span>,</span>
              </template>
            </span>
          </div>
        </section>
      </template>
    </div>
  </div>
</template>
