import { v4 as uuidv4 } from 'uuid';
import { IDesktopDrawerShape, IDesktopDrawerShapeGroup } from '../schema';

export default class ShapeGroupEntity implements IDesktopDrawerShapeGroup {
    id: string;
    name?: string;
    translateX: number;
    translateY: number;
    width: number;
    height: number;
    shapes: IDesktopDrawerShape[];

    constructor(data) {
        this.id = data?.id || uuidv4();
        this.name = data?.name || '';
        this.translateX = data?.translateX || 0;
        this.translateY = data?.translateY || 0;
        this.width = data?.width || 0;
        this.height = data?.height || 0;
        this.shapes = data?.shapes || [];
    }

    setMembers(newShapes: IDesktopDrawerShape[]) {
        this.shapes = [...newShapes];

        this.updatePositionAndSize();
    }
    addMembers(newShapes: IDesktopDrawerShape[]) {
        this.shapes = [...this.shapes, ...newShapes];

        this.updatePositionAndSize();
    }
    removeMembers(removeShapes: IDesktopDrawerShape[]) {
        this.shapes =
            this.shapes?.filter(
                (shape) =>
                    !removeShapes?.some((_shape) => _shape?.id == shape.id)
            ) || [];

        this.updatePositionAndSize();
    }

    updatePositionAndSize() {
        if (this.shapes?.length < 2) {
            this.translateX = 0;
            this.translateY = 0;
            this.width = 0;
            this.height = 0;
            return;
        }

        this.translateX = Math.min(...this.shapes?.map((o) => o.translateX));
        this.translateY = Math.min(...this.shapes?.map((o) => o.translateY));

        this.width =
            Math.max(
                ...this.shapes?.map((o) => {
                    return o.translateX + o.width;
                })
            ) - this.translateX;
        this.height =
            Math.max(
                ...this.shapes?.map((o) => {
                    return o.translateY + o.height;
                })
            ) - this.translateY;
    }
    updateSize(newWidth, newHeight) {
        this.width = newWidth;
        this.height = newHeight;
    }
}
