<script setup lang="ts">
defineProps<{
  modelValue: boolean;
  isSubmitting: boolean;
  isDisableSubmit?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'cancel'): void;
  (e: 'save'): void;
}>();

const onCancel = () => {
  emit('update:modelValue', false);
  emit('cancel');
};
</script>

<template>
  <div class="h-8 flex items-center mx-2">
    <div
      v-if="!modelValue"
      class="
        flex-center
        space-x-1
        hover:bg-current-50
        p-1
        rounded
        cursor-pointer
      "
      @click="$emit('update:modelValue', true)"
    >
      <SynIcon name="edit" is-active class="text-current-500 fill-current" />
      <span class="font-base text-xs">{{ $t('COMMON_LABEL_EDIT') }}</span>
    </div>
    <div v-else class="text-xs flex space-x-2">
      <SynButton
        v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
        :disabled="isSubmitting"
        :label="$t('COMMON_LABEL_CANCEL')"
        type-text
        color="gray"
        @click="onCancel"
      >
      </SynButton>
      <SynButton
        v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
        :label="$t('COMMON_LABEL_SAVE')"
        :is-loading="isSubmitting"
        :disabled="isDisableSubmit"
        @click="$emit('save')"
      >
      </SynButton>
    </div>
  </div>
</template>
