<script setup lang="ts">
import { computed } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import MessageReactions from '@/ui/modules/messaging/chat-panel/chat-message/message-reactions/MessageReactions.vue';
import ButtonReactions from '../button-reactions/ButtonReactions.vue';
import { chain, orderBy } from 'lodash';
import { ChatMessageReactionModel } from '@/application/models/chat/ChatMessageModel';

const props = defineProps<{
  reactions: { [reactionId: string]: ChatMessageReactionModel };
  members: any;
  isMyMessage: boolean;
  isInGroup: boolean;
}>();

defineEmits(['onReactionAdd', 'onReactionsClick']);

const reactionsList = computed(() => {
  const reactions = Object.values(props.reactions || {});

  const totalReaction = (reaction) =>
    reactions.filter((r) => r?.content === reaction?.content).length;

  return orderBy(
    reactions,
    [totalReaction, 'createdDate', 'id'],
    ['desc', 'desc', 'asc']
  );
});

const members = computed(() => props.members || {});

const myLastReaction = computed(() =>
  chain(reactionsList.value)
    .orderBy('createdDate', 'desc')
    .find(['createdBy', getCurrentUserId().toString()])
    .value()
);

const reactionPersonList = computed(() => [
  ...new Set(reactionsList.value.map((x) => x.createdBy)),
]);

const reactionMessageDiv = computed(() => {
  let html = "<div class='p-0.5'>";
  if (reactionPersonList.value.length <= 5) {
    reactionPersonList.value.forEach((userId) => {
      html += `<span>${members.value[userId!]?.name}</span></br>`;
    });
  } else {
    const arrShow = reactionPersonList.value.slice(0, 5);
    const arrHidden = reactionPersonList.value.slice(5);
    arrShow.forEach((userId) => {
      html += `<span>${members.value[userId!]?.name}</span></br>`;
    });
    html += `<span>${arrHidden?.length} người khác</span>`;
  }

  html += '</div>';
  return html;
});
</script>

<template>
  <div
    class="flex items-center space-x-1"
    :class="!isMyMessage ? 'flex-row-reverse space-x-reverse' : 'flex-row'"
  >
    <!--    ButtonReactions-->
    <ButtonReactions
      custom-class="w-6 h-6 bg-white"
      :my-reaction="myLastReaction"
      :is-in-group="isInGroup"
      @on-emoji-click="$emit('onReactionAdd', $event)"
    />

    <!--    MessageReactions-->
    <button
      v-if="reactionsList?.length"
      v-vig-tooltip="reactionMessageDiv"
      class="h-6"
      @click="$emit('onReactionsClick', reactionsList)"
    >
      <MessageReactions :reactions="reactionsList" :is-in-group="isInGroup" />
    </button>
  </div>
</template>
