import BaseRequest from '@/base/api/base-request';
import { AxiosInstance } from 'axios';

export default class DashboardRepository {
    private static instance: DashboardRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): DashboardRepository {
        if (!DashboardRepository.instance) {
            // Get from local storage
            DashboardRepository.instance = new DashboardRepository();
        }

        return DashboardRepository.instance;
    }

    getDashboardOverview(departmentId?: number) {
        return this.request({
            url: `Dashboard/OverviewGetAllMembers`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }
    getOverviewTeamTasks(departmentId?: number) {
        return this.request({
            url: `Dashboard/OverviewGetAllTasks`,
            method: 'get',
            params: {
                dptId: departmentId,
            },
        });
    }
    getMemberOnday() {
        return this.request({
            url: `Dashboard/MemberOnday`,
            method: 'get',
        });
    }
}
