<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="m17.84998 30.79999c.25.19.56.27997.87.27997.23004 0 .47003-.04999.68005-.15997.5-.26001.81995-.77997.81995-1.34003v-27.15998c0-.56-.31995-1.08002-.81995-1.34003-.49005-.25-1.10004-.20996-1.55005.12006l-10.56 7.52997h-5.28998c-.83002 0-1.5.67999-1.5 1.5v11.53998c0 .82001.66998 1.5 1.5 1.5h5.28998z"
        ></path>
        <path
          d="m31.06055 12.05707c-.58594-.58594-1.53516-.58594-2.12109 0l-1.82178 1.82159-1.82178-1.82159c-.58594-.58594-1.53516-.58594-2.12109 0s-.58594 1.53564 0 2.12109l1.82172 1.82153-1.82172 1.82153c-.58594.58594-.58594 1.53516 0 2.12109.29297.29297.67676.43945 1.06055.43945s.76758-.14648 1.06055-.43945l1.82178-1.82159 1.82178 1.82159c.29297.29297.67676.43945 1.06055.43945s.76758-.14648 1.06055-.43945c.58594-.58594.58594-1.53516 0-2.12109l-1.82172-1.82153 1.82172-1.82153c.58591-.58545.58591-1.53515-.00002-2.12109z"
        ></path>
      </g>
    </g>
  </svg>
</template>
