<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useElementBounding } from '@vueuse/core';
import { IStepTemplateEntity } from '@/domain/entities/workflow/StepTemplateEntity';
import {
  WORKFLOW_STEP_CONFIGS_BY_VIEW_MODE,
  EFunctionList,
} from '@/application/constants/task/workflow.const';
// import SelectMultipleUsers from '@/ui/components/user/SelectMultipleUsers.vue';
import UserById from '@/ui/components/user/UserById.vue';
// import { ITaskWorkflowStepEntity } from '@/domain/entities/workflow/TaskWorkflowEntity';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import TaskDetailAttachmentInfo from '@/ui/modules/task/components/task-list/TaskDetailAttachmentInfo.vue';
import workflowStepComposables from '@/ui/composables/workflow/workflow-step.composables';
import { IConfigType } from '@/application/types/task/task-configs.types';
import taskStore from '@/store/task';
import { ScheduleDayOptions } from '@/domain/enums/taskEnum';
import { translate } from '@/ui/plugins/i18n/myi18n';
import settingStore from '@/store/setting';
import UpdateDomainDropdown from '@/ui/modules/task/detail/UpdateDomainDropdown.vue';
import ManageCollaborator from '@/ui/modules/task/detail/assign/ManageCollaborator.vue';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';

const props = withDefaults(
  defineProps<{
    viewMode?: 'VIEW' | 'EDIT' | 'TASK_VIEW' | 'ATTACH_TO_TASK';
    workflowStep: IStepTemplateEntity;
    isRemovable?: boolean;
    selectable?: boolean;
    actionList?: string[];
  }>(),
  {
    viewMode: 'VIEW',
    actionList: () => ['EDIT'],
  }
);

const emits = defineEmits<{
  (e: 'removeStep'): void;
  (e: 'toggleFinishedStep'): void;
  (e: 'addStepToRight'): void;
  (e: 'chooseExistedStep'): void;
  (e: 'update:workflowStep', data: IStepTemplateEntity): void;
  (e: 'choose'): void;
  (e: 'updateSuccess', newData: any): void;
  (e: 'onOpenUpdateStepModal'): void;
}>();

// const allActiveUsers = computed(() => userStore().allActiveUsers);

const _taskStore = taskStore();
const _settingStore = settingStore();

const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting.taskManagement?.multipleAssigneeSetting
);
const taskDataConfigScheduleDayOptions = computed<IConfigType[]>(() => {
  return (
    _taskStore.dataConfigs?.scheduleDayOptions
      ?.filter((o) => o?.active && o.key !== ScheduleDayOptions.OtherTime)
      ?.map((o) => {
        let customText = '';
        switch (o?.key) {
          case ScheduleDayOptions.OnDay:
            customText = translate(`COMMON_LABEL_DEADLINE_SAME_DAY`);
            break;
          case ScheduleDayOptions.D1:
          case ScheduleDayOptions.D2:
          case ScheduleDayOptions.D3:
            customText = translate(`COMMON_LABEL_DEADLINE_AFTER_DAY`, {
              totalDays: o?.key || '',
              suffix: o?.key > 1 ? 's' : '',
            });
            break;

          default:
            customText = o.text;
            break;
        }
        return {
          ...o,
          text: customText,
        };
      }) || []
  );
});

const configsByViewMode = computed<{ enableFunctions: EFunctionList[] }>(() => {
  return WORKFLOW_STEP_CONFIGS_BY_VIEW_MODE[props.viewMode];
});

const {
  workflowStepData,
  currentTaskTemplate,
  // initData,
  updateWorkflowStepFromTaskTemplate,
} = workflowStepComposables({
  ...props.workflowStep,
});

onMounted(() => {});

const updateWorkflowStep = () => {
  emits('update:workflowStep', workflowStepData.value);
};

const onChangeUrgency = (urgency) => {
  currentTaskTemplate.value.urgency = urgency;
  updateWorkflowStepFromTaskTemplate(updateWorkflowStep);
};

const isOpenUpdateDomainModal = ref<boolean>(false);
const onOpenUpdateDomainModal = () => {
  isOpenUpdateDomainModal.value = true;
};

// const onCancelUpdateDomain = () => {
//   isOpenUpdateDomainModal.value = false;
// };

const updateDirectly = async (takeActionFn) => {
  if (!takeActionFn || typeof takeActionFn !== 'function') return;

  takeActionFn();

  updateWorkflowStepFromTaskTemplate(updateWorkflowStep);
};

const changeAssignPayload = ref({} as any);

const fixConflictDomain = ref({
  domain: false,
  project: false,
  subProject: false,
} as any);

const onChangeAssign = async (group, user, { domain }, collaborators) => {
  updateDirectly(() => {
    changeAssignPayload.value = {
      groupId: group?.id,
      assigneeId: user?.id || user?.userId,
      collaborators,
    };
    fixConflictDomain.value = { domain };

    if (domain) {
      onOpenUpdateDomainModal();
      return;
    }

    currentTaskTemplate.value.groupId = group?.id;
    currentTaskTemplate.value.assigneeId = user?.id;

    currentTaskTemplate.value.collaborators =
      currentTaskTemplate.value.collaborators?.filter(
        (c) => c?.memberUserId !== user?.id
      );
  });
};

const onSaveDomain = (domainData) => {
  if (
    (!domainData || !domainData?.domainId) &&
    (props.viewMode == 'ATTACH_TO_TASK' || props.viewMode == 'TASK_VIEW')
  )
    return;

  updateDirectly(() => {
    currentTaskTemplate.value.domainId = domainData?.domainId;
  });
};

const currentSchedule = computed<IConfigType>(() => {
  return taskDataConfigScheduleDayOptions.value?.find(
    (o) => o.key == currentTaskTemplate.value?.taskSchedule?.scheduleOptionKey
  );
});

const onSaveDeadline = (deadlineDate) => {
  updateDirectly(() => {
    currentTaskTemplate.value.taskSchedule.scheduleOptionKey =
      deadlineDate.scheduleOptionKey;
    currentTaskTemplate.value.taskSchedule.otherTime =
      deadlineDate.otherTimeKey;
    currentTaskTemplate.value.scheduleTime = deadlineDate.scheduleTime;
  });
};
const onSaveStartTime = (startTime) => {
  updateDirectly(() => {
    currentTaskTemplate.value.startTime = startTime;
  });
};
const isEditCollaborator = ref<boolean>(false);
const onChangeCollaborator = async (collaborators) => {
  updateDirectly(() => {
    const payload = collaborators?.map((user) => {
      return {
        ...user,
        memberUserId: user?.id || user?.userId,
      };
    });
    currentTaskTemplate.value.addCollaboratorsInTask(payload);
  });
};
const onRemoveCollaborator = async (userId) => {
  updateDirectly(() => {
    currentTaskTemplate.value.deleteCollaboratorsInTask(userId);
  });
};

const isOpenFull = ref<boolean>(false);
const workflowStepDescriptionRef = ref<any>(null);
const { height: descriptionHeight } = useElementBounding(
  workflowStepDescriptionRef
);
const needShowMoreDescription = computed<boolean>(() => {
  if (!descriptionHeight.value) return false;

  const currentHeight = isOpenFull.value ? 156 : 32;
  return descriptionHeight.value > currentHeight;
});

const onOpenUpdateStepModal = () => {
  emits('onOpenUpdateStepModal');
};
</script>
<template>
  <div
    class="
      w-full
      flex-1 flex flex-col
      items-start
      overflow-x-hidden overflow-y-auto
      small-scrollbar
    "
  >
    <!-- STEP DESCRIPTION -->
    <!-- <section
        v-if="
          configsByViewMode?.enableFunctions?.includes(
            EFunctionList.EDIT_DESCRIPTION
          )
        "
        class="w-full flex text-sm items-center pt-2"
        :title="workflowStep.taskDescription"
        :style="`max-width: ${width - 20}px`"
      >
        <div class="w-full">
          <SynInputArea
            v-model="workflowStepData.taskDescription"
            class="fill-current h-8"
            rows="2"
            :placeholder="$t('COMMON_LABEL_DESCRIPTION')"
            @update:model-value="updateWorkflowStep"
          />
        </div>
      </section> -->

    <section class="flex items-center gap-2 pt-2">
      <!-- DOMAIN -->
      <div
        :class="[
          'h-6 min-w-[2rem]',
          currentTaskTemplate.domainId
            ? ''
            : configsByViewMode?.enableFunctions?.includes(
                EFunctionList.EDIT_DOMAIN
              )
            ? 'border border-red-500 rounded p-1 hover:bg-gray-50'
            : '',
        ]"
      >
        <UpdateDomainDropdown
          v-if="
            configsByViewMode?.enableFunctions?.includes(
              EFunctionList.EDIT_DOMAIN
            ) && !isOpenUpdateDomainModal
          "
          :task-detail="currentTaskTemplate"
          layout="horizontal"
          is-update-domain-only
          is-update-instance
          @on-save="onSaveDomain"
        />
        <DomainProjectById
          v-else-if="currentTaskTemplate.domainId"
          :domain-id="currentTaskTemplate.domainId"
          layout="horizontal"
        />
      </div>

      <!-- PRIORITY -->
      <VigDropdown
        v-if="
          configsByViewMode?.enableFunctions?.includes(
            EFunctionList.EDIT_URGENCY
          )
        "
        :arrow="false"
      >
        <template #dropdown-toggle>
          <div
            class="
              flex
              font-medium
              items-center
              space-x-2
              hover:bg-current-50
              cursor-pointer
              p-1
              rounded
              hover-to-show__parent
            "
            style="min-width: 8rem"
          >
            <PriorityTask
              is-label
              size="mini"
              :urgency="currentTaskTemplate.urgency"
            />
          </div>
        </template>
        <template #dropdown-menu>
          <div
            class="w-50 py-1 z-100 list-ul rounded cursor-pointer dropdown-item"
          >
            <div
              v-for="(item, index) in [4, 3, 2, 1]"
              :key="index"
              class="list-li flex text-left justify-start pr-2"
              :class="currentTaskTemplate.urgency == item ? 'bg-gray-100' : ''"
              @click="onChangeUrgency(item)"
            >
              <PriorityTask is-label :urgency="item" size="mini" />
            </div>
          </div>
        </template>
      </VigDropdown>
      <PriorityTask
        v-else
        :urgency="currentTaskTemplate.urgency"
        is-label
        size="mini"
      />
      <VigDropdown
        v-if="
          configsByViewMode?.enableFunctions?.includes(
            EFunctionList.EDIT_DEADLINE
          )
        "
        :arrow="false"
      >
        <template #dropdown-toggle>
          <SynTag
            size="small"
            :custom-class="[
              'py-1 px-2 hover:bg-current-50 rounded cursor-pointer',
              currentSchedule?.text || currentTaskTemplate.scheduleTime
                ? 'text-green-500'
                : 'text-gray-500',
            ]"
          >
            <span v-if="currentSchedule?.text">{{
              currentSchedule?.text
            }}</span>
            <SynLabelDateTime
              v-else-if="currentTaskTemplate.scheduleTime"
              format="date"
              :datetime="currentTaskTemplate.scheduleTime"
            />
            <span v-else-if="viewMode == 'ATTACH_TO_TASK'">
              {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') }}
            </span>
          </SynTag>
        </template>
        <template #dropdown-menu>
          <div
            class="
              w-50
              py-1
              z-100
              list-ul
              rounded
              cursor-pointer
              dropdown-item
              px-2
            "
          >
            <DeadlineInput
              :schedule-key="
                currentTaskTemplate?.taskSchedule?.scheduleOptionKey
              "
              :other-time="currentTaskTemplate.taskSchedule.otherTime"
              :schedule="currentTaskTemplate.scheduleTime"
              :user-id="currentTaskTemplate.assigneeId"
              :group-id="currentTaskTemplate.groupId"
              :start-time="currentTaskTemplate.startTime"
              is-hidden-start-time
              view-mode="EDIT"
              @save="onSaveDeadline"
              @on-change-start-time="onSaveStartTime"
            />
          </div>
        </template>
      </VigDropdown>
      <SynTag
        v-else
        size="small"
        custom-class="py-1 px-2 hover:bg-current-50 rounded cursor-pointer text-green-500"
      >
        <span>{{ currentSchedule?.text }}</span>
      </SynTag>
    </section>

    <!-- STEP ASSIGNEES -->
    <section class="w-full flex items-center flex-wrap">
      <div
        v-if="
          configsByViewMode?.enableFunctions?.includes(
            EFunctionList.EDIT_ASSIGNEE
          ) && !isOpenUpdateDomainModal
        "
      >
        <ChangeAssign
          class="text-xs"
          is-update-instant
          is-multi-assign
          can-remove-all
          is-workflow
          :group-id="currentTaskTemplate?.groupId || 0"
          :user-id="currentTaskTemplate?.assigneeId || 0"
          :domain-id="workflowStepData?.domainId || 0"
          @on-save="onChangeAssign"
        />
      </div>
      <div v-else class="px-2">
        <div
          v-if="currentTaskTemplate?.groupId || currentTaskTemplate?.assigneeId"
          class="
            flex
            items-center
            space-x-1
            relative
            rounded
            text-xs
            font-medium
            px-2
            py-2
          "
        >
          <div v-if="currentTaskTemplate?.groupId" class="">
            <GroupById
              :group-id="currentTaskTemplate?.groupId"
              avatar-class="w-6 h-6 text-xs"
            />
          </div>
          <span
            v-if="
              currentTaskTemplate?.assigneeId && currentTaskTemplate?.groupId
            "
            class="px-2 text-gray-500"
            >→</span
          >
          <div v-if="currentTaskTemplate?.assigneeId" class="">
            <UserById
              is-show-first-name
              avatar-class="w-6 h-6 text-xs"
              :user-id="currentTaskTemplate?.assigneeId"
            />
          </div>
        </div>
      </div>

      <!-- COLLABORATORS -->
      <template
        v-if="
          multipleAssigneeSetting?.systemEnable &&
          multipleAssigneeSetting?.organizationEnable
        "
      >
        <div class="flex-1">
          <ManageCollaborator
            ref="manageCollaboratorRef"
            v-model:is-edit-mode="isEditCollaborator"
            class="flex-1"
            :group-id="workflowStepData.groupId || 0"
            :assignee-id="workflowStepData.assigneeId || 0"
            :collaborators="
              workflowStepData.collaborators?.map((u) => {
                return {
                  memberUserId: u?.userId,
                  memberRoleId: u?.roleId,
                };
              })
            "
            :readonly="viewMode == 'TASK_VIEW' || viewMode == 'VIEW'"
            @on-add="onChangeCollaborator"
            @on-remove="onRemoveCollaborator"
          />
        </div>
      </template>
    </section>

    <!-- DESCRIPTION -->
    <template v-if="needShowMoreDescription && isOpenFull">
      <div class="w-full relative">
        <div
          class="
            w-full
            flex
            items-center
            justify-end
            absolute
            -bottom-4
            right-2
          "
        >
          <div
            class="
              flex-center
              gap-1
              rounded-full
              bg-white
              px-2
              py-0.5
              cursor-pointer
            "
            @click="isOpenFull = false"
          >
            <span class="text-xs text-gray-500 italic underline cursor-pointer">
              {{ $t('TASK_CREATE_FORM_SHOW_LESS') }}
            </span>

            <SynIcon
              :name="'sort-down'"
              custom-class="h-2 w-2 fill-gray-500"
              :class="!isOpenFull ? '-rotate-90' : ''"
            />
          </div>
        </div>
      </div>
    </template>
    <section
      v-if="currentTaskTemplate?.description"
      class="
        flex-1
        min-h-0
        overflow-auto
        w-full
        flex flex-col
        pt-1
        small-scrollbar
      "
      :class="isOpenFull ? 'max-h-40' : ''"
    >
      <span
        v-vig-tooltip="
          isOpenFull ? { isDestroyed: true } : currentTaskTemplate?.description
        "
        class="w-full text-xs italic text-gray-500"
        :class="isOpenFull ? '' : 'text-overflow-hidden'"
        v-html="currentTaskTemplate?.description"
      >
      </span>

      <span
        ref="workflowStepDescriptionRef"
        class="w-full absolute text-xs"
        style="bottom: 9999px; right: 9999px"
        v-html="currentTaskTemplate?.description"
      >
      </span>
      <template v-if="needShowMoreDescription && !isOpenFull">
        <div class="w-full flex items-center justify-end">
          <div
            class="
              flex-center
              gap-1
              rounded-full
              bg-white
              px-2
              py-0.5
              cursor-pointer
            "
            @click="isOpenFull = true"
          >
            <span class="text-xs text-gray-500 italic underline">
              {{ $t('NOTIFICATION_SEE_MORE') }}
            </span>
            <SynIcon
              :name="'sort-down'"
              custom-class="h-2 w-2 fill-gray-500"
              :class="!isOpenFull ? '-rotate-90' : ''"
            />
          </div>
        </div>
      </template>
    </section>

    <!-- ATTACHMENTS -->
    <TaskDetailAttachmentInfo
      v-if="
        (workflowStepData?.totalFiles && workflowStepData?.totalFiles > 0) ||
        (workflowStepData?.totalNotes && workflowStepData?.totalNotes > 0) ||
        (workflowStepData?.totalTodoLists &&
          workflowStepData?.totalTodoLists > 0)
      "
      :total-files="workflowStepData?.totalFiles"
      :total-notes="workflowStepData?.totalNotes"
      :total-todo-lists="workflowStepData?.totalTodoLists"
      @on-open-attachment-detail="onOpenUpdateStepModal"
    />
  </div>
</template>
<style lang="scss"></style>
