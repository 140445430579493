<script setup lang="ts">
import { onMounted, computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { decryptUserId } from '@/ui/plugins/crypto/crypto';
import userStore from '@/store/user';
import taskListStore from '@/store/task/task-list-store';
import settingStore from '@/store/setting';
import { TaskListTab } from '@/domain/enums/taskEnum';

const props = withDefaults(
  defineProps<{
    isGroup?: boolean;
    tabList?: any;
  }>(),
  {
    isGroup: false,
    tabList: ['TASKS', 'RESOURCES'],
  }
);
const emit = defineEmits(['tabSelected']);

const _settingStore = settingStore();
const _taskListStore = taskListStore();
const totalFilteredTasks = computed(
  () => _taskListStore.filteredTasks?.length || 0
);

const gedEnabled = computed(() => _settingStore.gedEnabled);

const route = useRoute();
const _userStore = userStore();

const activeTab = computed(() => _taskListStore.activeTab);
const userList = computed(() => _userStore.listShortInfosUser);
const userFirstName = computed(() => {
  if (
    !!route.params?.id &&
    decryptUserId(route.params?.id) != getCurrentUserId() &&
    !props.isGroup
  ) {
    return userList.value?.find((o) => o.id == decryptUserId(route.params?.id))
      ?.firstName;
  } else {
    return '';
  }
});
const activeTabClass = (tab) => {
  return tab === activeTab.value
    ? 'text-current-500 font-medium tab_item_active relative'
    : '';
};
const onClickTab = (key: TaskListTab) => {
  emit('tabSelected', key);
};
onMounted(() => {});

const isLoading = computed(() => _taskListStore.isLoading);
const totalDefaultTasks = computed<string>(() => {
  const totalDefault = _taskListStore.taskSummaryStatistics?.totalDefault;
  if (!totalDefault) return '';

  if (
    activeTab.value !== TaskListTab.MyList ||
    isLoading.value ||
    !totalFilteredTasks.value ||
    totalFilteredTasks.value >= totalDefault
  )
    return totalDefault && totalDefault > 0 ? `${totalDefault}` : '';

  return `${totalFilteredTasks.value}/${totalDefault}`;
});
const filteredAssignedTasks = computed(() => {
  const totalDefault = _taskListStore.assignTaskSummaryStatistics?.totalDefault;
  if (!totalDefault) return '';

  if (
    activeTab.value !== TaskListTab.MyAssign ||
    isLoading.value ||
    !totalFilteredTasks.value ||
    totalFilteredTasks.value >= totalDefault
  )
    return totalDefault && totalDefault > 0 ? `${totalDefault}` : '';

  return `${totalFilteredTasks.value}/${totalDefault}`;
});
const approvalTaskSummaryStatistics = computed<{
  totalAll: number;
  totalApproval: number;
  totalApproved: number;
  totalRequesting: number;
}>(() => {
  return _taskListStore.approvalTaskSummaryStatistics;
});
const filteredApprovalTasks = computed(() => {
  const totalDefault = approvalTaskSummaryStatistics.value?.totalRequesting;
  if (!totalDefault) return '';

  return `${totalDefault}`;
});
</script>

<template>
  <div class="flex cursor-pointer space-x-2 text-sm">
    <router-link
      :to="{
        path: route.path,
        query: { tab: TaskListTab.MyList },
      }"
      class="tab_item relative px-2 pt-2 pb-3 hover:text-current-500 space-x-2"
      :class="`${activeTabClass(TaskListTab.MyList)}`"
      @click="onClickTab(TaskListTab.MyList)"
    >
      <span v-if="userFirstName">
        {{ $t('TASKTAB_LABEL_OTHERTASK', { username: userFirstName }) }}
      </span>
      <span v-else>
        {{ $t(isGroup ? 'TASKTAB_LABEL_TASKS' : 'TASKTAB_LABEL_MYTASK') }}
      </span>
      <span
        v-if="totalDefaultTasks"
        class="px-1 text-xs rounded"
        :class="
          activeTab === TaskListTab.MyList
            ? 'bg-current-500 text-white'
            : 'bg-gray-100 text-gray-500'
        "
      >
        {{ totalDefaultTasks }}
      </span>
    </router-link>
    <router-link
      v-if="!isGroup"
      v-permission-model
      :to="{
        path: route.path,
        query: { tab: TaskListTab.MyAssign },
      }"
      class="px-2 pt-2 pb-3 hover:text-current-500 space-x-2"
      :class="activeTabClass(TaskListTab.MyAssign)"
      @click="onClickTab(TaskListTab.MyAssign)"
    >
      <span v-if="userFirstName">
        {{ $t('TASKTAB_LABEL_OTHERASSIGN', { username: userFirstName }) }}
      </span>
      <span v-else>
        {{ $t('TASKTAB_LABEL_IASSIGN') }}
      </span>
      <span
        v-if="filteredAssignedTasks"
        class="px-1 text-xs rounded"
        :class="
          activeTab === TaskListTab.MyAssign
            ? 'bg-current-500 text-white'
            : 'bg-gray-100 text-gray-500'
        "
      >
        {{ filteredAssignedTasks || 0 }}
      </span>
    </router-link>
    <router-link
      v-if="
        tabList?.includes('WAITING_APPROVAL_TASKS') &&
        approvalTaskSummaryStatistics?.totalAll > 0
      "
      v-permission-model
      :to="{
        path: route.path,
        query: { tab: TaskListTab.WaitingApprovalTasks },
      }"
      class="px-2 pt-2 pb-3 hover:text-current-500 space-x-2"
      :class="activeTabClass(TaskListTab.WaitingApprovalTasks)"
      @click="onClickTab(TaskListTab.WaitingApprovalTasks)"
    >
      <span>
        {{
          $t('TASK_LABEL_WAITING_APPROVAL_TASKS', { username: userFirstName })
        }}
      </span>

      <span
        v-if="filteredApprovalTasks"
        class="px-1 text-xs rounded"
        :class="
          activeTab === TaskListTab.WaitingApprovalTasks
            ? 'bg-current-500 text-white'
            : 'bg-gray-100 text-gray-500'
        "
      >
        {{ filteredApprovalTasks || 0 }}
      </span>
    </router-link>

    <router-link
      v-if="gedEnabled && tabList?.includes('RESOURCES')"
      :to="{
        path: route.path,
        query: { tab: TaskListTab.Resources },
      }"
      class="px-2 pt-2 pb-3 hover:text-current-500 space-x-2"
      :class="activeTabClass(TaskListTab.Resources)"
      @click="onClickTab(TaskListTab.Resources)"
    >
      <span>
        {{ $t('SIDEBAR_LABEL_RESOURCES') }}
      </span>
    </router-link>
    <div
      v-if="tabList?.includes('MANAGEMENT')"
      class="px-2 pt-2 pb-3 hover:text-current-500 space-x-2"
      :class="activeTabClass(TaskListTab.Management)"
      @click="onClickTab(TaskListTab.Management)"
    >
      <span>
        {{ $t('SIDEBAR_LABEL_MANAGEMENT') || 'Management' }}
      </span>
    </div>
    <div
      v-if="tabList?.includes('STATISTIC')"
      class="px-2 pt-2 pb-3 hover:text-current-500 space-x-2"
      :class="activeTabClass(TaskListTab.Statistic)"
      @click="onClickTab(TaskListTab.Statistic)"
    >
      <span>
        {{ $t('SIDEBAR_LABEL_STATISTIC') || 'Statistic' }}
      </span>
    </div>
  </div>
</template>

<style scoped>
.tab_item_active:before {
  background: #4cb0b0;
  opacity: 1;
  width: calc(100% - 26px);
  height: 4px;
  left: 13px;
  bottom: 0;
  border: 0;
  border-radius: 5px 5px 0 0;
  box-shadow: none;
}

.tab_item_active:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
}
</style>
