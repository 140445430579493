import BaseRequest from '@/base/api/base-request';
import Axios, { AxiosInstance } from 'axios';
import { ICommonRepository } from '@/domain/interfaces/repositories/ICommonRepository';
import BaseRepository from '@/base/repositories/BaseRepository';

export default class CommonRepository implements ICommonRepository {
    private static instance: CommonRepository;
    request: AxiosInstance;

    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): CommonRepository {
        if (!CommonRepository.instance) {
            // Get from local storage
            CommonRepository.instance = new CommonRepository();
        }

        return CommonRepository.instance;
    }

    getSystemLanguages() {
        return this.request({
            url: `Common/GetSystemLanguages`,
            method: 'get',
        });
    }

    getLocationViaIP() {
        return Axios.get(
            `https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location`
        );
    }

    getCurrentUserSetting() {
        return this.request({
            url: `Common/GetCurrentUserSetting`,
            method: 'get',
        });
    }

    getOrgCurrentUserSetting(orgId) {
        return this.request({
            url: `Common/GetCurrentUserSetting`,
            method: 'get',
            params: { orgId },
        });
    }

    getUserSettingByKey(userId: number, key: string) {
        return this.request({
            url: `Common/GetUserSettingByKey`,
            method: 'get',
            params: { userId, key },
        });
    }

    updateCurrentUserSetting(data: IUserSettingPayload) {
        return this.request({
            url: `Common/UpdateCurrentUserSetting`,
            method: 'post',
            data,
        });
    }

    updateOrgCurrentUserSetting(orgId, data) {
        return this.request({
            url: `Common/UpdateCurrentUserSetting`,
            method: 'post',
            data,
            params: { orgId },
        });
    }

    getPreviewLinkInfo(link) {
        const data = {
            link: link,
        };
        return this.request({
            url: `Common/preview-link-info`,
            method: 'post',
            data,
        });
    }

    getMyStatistics() {
        return BaseRepository.requestWithAuthorize({
            url: `Statistic/GetMessageStatistic`,
            method: 'get',
        });
    }

    setCurrentUserSetting(data: { key: string; value: string }) {
        return BaseRepository.requestWithAuthorize({
            url: `Common/SetCurrentUserSetting`,
            method: 'post',
            data,
        });
    }

    setMultiUsersSetting(data: {
        userIds: number[];
        key: string;
        value: string;
    }) {
        return BaseRepository.requestWithAuthorize({
            url: `Common/SetMultiUsersSetting`,
            method: 'post',
            data,
        });
    }
}

// Generated by https://quicktype.io

export interface IUserSettingPayload {
    id?: number;
    userId: number;
    organizationId: number;
    key: string;
    value: boolean | string;
}
