<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import organizationStore from '@/store/organization';
import TictopWebSocket, {
  USER_REG_EVENT,
} from '@/ui/plugins/websocket/ws-instance';
import remoteConfigStore from '@/store/remoteConfig';
import SystemsInfoModel from '@/ui/plugins/firebases/realtime-database/model/SystemsInfoModel';
import { systemKey } from '@/ui/common/constants/system';
import myProfileStore from '@/store/auth/my-profile';
import {
  GOOGLE_ANALYTICS_ELEMENT_ID,
  STYLE_BY_ORGANIZATION_MODEL,
} from '@/application/constants/register.const';
import SetupOrganizationModel from '@/ui/modules/organization/setup/SetupOrganizationModel.vue';
import { EOrganizationModel } from '@/application/types/organization/organization.type';

const props = withDefaults(
  defineProps<{
    customClass?: string;
    isSubmitting: boolean;
    doNotNeedToCheckPersonal: boolean;
    typeKey: 'workspace_type_select' | 'workspace_type_select_register';
    isHiddenPersonalModel?: boolean;
    selectedModel?: any;
    isUpgradeCurrentOrg?: boolean;
    isHomePage?: boolean;
  }>(),
  {
    customClass: '',
    doNotNeedToCheckPersonal: false,
    typeKey: 'workspace_type_select',
  }
);

defineEmits<{
  (e: 'onCancel'): void;
  (e: 'onSubmit', data: { mode: number; data: any }): void;
}>();

let ws: TictopWebSocket;

const _remoteConfigStore = remoteConfigStore();
const _organizationStore = organizationStore();

const userProfile = computed(() => myProfileStore().myProfile);

let fbSystemsInfoClass: SystemsInfoModel;

const workspaceTypeSelect = computed<{
  company: boolean;
  team: boolean;
  personal: boolean;
  limit_personal: boolean;
}>(() => {
  if (props.typeKey === 'workspace_type_select_register')
    return _remoteConfigStore.workspaceTypeSelectRegister;

  return _remoteConfigStore.workspaceTypeSelect;
});

const organizationModels = computed(() => {
  let models = _organizationStore.organizationModels?.map((o) => {
    let elementId = '';
    if (props.typeKey == 'workspace_type_select_register') {
      switch (o.code) {
        case 'COMPANY':
          elementId =
            GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
              .setting_org_select_company;
          break;
        case 'TEAM':
          elementId =
            GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
              .setting_org_select_team;
          break;
        case 'PERSONAL':
          elementId =
            GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
              .setting_org_select_personal;
          break;

        default:
          break;
      }
    }
    return { ...o, elementId };
  });

  if (props.isHiddenPersonalModel)
    models = models?.filter((model) => model.code !== 'PERSONAL');

  return models?.filter(
    (model) =>
      model.code !== 'FAMILY' &&
      (!workspaceTypeSelect.value ||
        (workspaceTypeSelect.value[model.code?.toLowerCase()] &&
          (model.code !== 'PERSONAL' ||
            (model.code == 'PERSONAL' &&
              settingInRealtimeDb.value?.personal !== -1))))
  );
});

const currentOrganizationModel = ref<EOrganizationModel | null>(
  props.selectedModel?.code || null
);

const settingInRealtimeDb = ref({
  personal: 0,
});
onMounted(async () => {
  ws = new TictopWebSocket(
    USER_REG_EVENT.PAGE_INPUT_ORGANIZATION_INFO_INIT,
    null,
    {
      ...userProfile?.value,
      model: 'PERSONAL',
    }
  );
  if (props.typeKey === 'workspace_type_select_register') {
    fbSystemsInfoClass = new SystemsInfoModel(
      systemKey.choose_organization_model_settings
    );
    fbSystemsInfoClass.getOnce((value) => {
      settingInRealtimeDb.value = value;

      if (value === 0) return;
      fbSystemsInfoClass.updateData({
        personal: settingInRealtimeDb.value?.personal * -1,
      });
    });
  }
});

onUnmounted(async () => {
  ws?.close();
});

const organizationNameRef = ref<any>(null);
const isLoadingChoosePersonal = ref<boolean>(false);
const isOpenLimitPersonalModel = ref<boolean>(false);

const currentChoice = ref<any>({});

const updateDecideTime = () => {
  if (props.typeKey === 'workspace_type_select_register') {
    _organizationStore.updateDecideTime(selectPersonalCount.value);
  }
};
const onChangeOrgMode = (item) => {
  currentChoice.value = item;
  isOpenLimitPersonalModel.value = false;

  if (item.code == 'PERSONAL') {
    ws.send(USER_REG_EVENT.CLICK_SELECT_ORGANIZATION_MODEL_PERSONAL);
    if (workspaceTypeSelect.value?.limit_personal) {
      selectPersonalCount.value += 1;
    }

    if (selectPersonalCount.value == 1) {
      isLoadingChoosePersonal.value = true;

      setTimeout(() => {
        isLoadingChoosePersonal.value = false;
      }, 3000);
      updateDecideTime();
      return;
    }
    if (selectPersonalCount.value == 2) {
      isOpenLimitPersonalModel.value = true;
      updateDecideTime();
      return;
    }
  }

  updateDecideTime();

  currentOrganizationModel.value = item.code;

  switch (item.code) {
    case 'COMPANY':
      ws.send(USER_REG_EVENT.CLICK_SELECT_ORGANIZATION_MODEL_COMPANY);
      break;
    case 'TEAM':
      ws.send(USER_REG_EVENT.CLICK_SELECT_ORGANIZATION_MODEL_TEAM);
      break;
  }

  organizationNameRef.value?.focus();
};

const selectPersonalCount = ref<number>(0);
const onClosePersonalLimit = () => {
  isOpenLimitPersonalModel.value = false;
  ws.send(USER_REG_EVENT.CLICK_SELECT_ORGANIZATION_MODEL_BACK);
};
</script>

<template>
  <div
    class="
      p-8
      mx-auto
      border-current
      flex
      items-center
      flex-col
      justify-between
      bg-white
      rounded-2xl
      relative
    "
    :class="customClass"
    style="max-width: 50rem"
  >
    <SetupOrganizationModel
      v-if="currentOrganizationModel"
      :key="currentOrganizationModel"
      :organization-model="currentOrganizationModel"
      :do-not-need-to-check-personal="doNotNeedToCheckPersonal"
      :is-submitting="isSubmitting"
      :has-update-current-organization="isHiddenPersonalModel"
      :selected-model="selectedModel"
      :is-upgrade-current-org="isUpgradeCurrentOrg"
      :is-home-page="isHomePage"
      @choose-other-model="currentOrganizationModel = null"
      @on-submit="(data) => $emit('onSubmit', data)"
    />
    <template v-else>
      <div class="flex flex-col space-y-20 p-8" style="min-width: 40rem">
        <!-- HEADER -->
        <div class="flex flex-col space-y-4">
          <div
            class="font-medium text-center"
            style="font-size: 1.75rem; line-height: 2rem"
          >
            {{ $t('SELECT_ORGANIZATION_MODEL_TITLE') }}
          </div>
          <div class="text-gray-400 text-center">
            {{ $t('FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_SUB') }}
          </div>
        </div>
        <!-- BODY -->
        <div class="flex items-stretch space-x-8 justify-center">
          <div
            v-for="item in organizationModels"
            :id="item?.elementId"
            :key="item?.id"
            class="
              w-1/3
              p-4
              rounded-md
              flex-center flex-col
              shadow-md
              border border-gray-50
              hover:border-current-50
              cursor-pointer
              hover:bg-current-50 hover:shadow-xl
              relative
            "
            :class="{
              'cursor-wait': isLoadingChoosePersonal,
            }"
            @click="onChangeOrgMode(item)"
          >
            <div
              class="h-16 w-16 flex-center absolute -top-8 rounded-full"
              :style="{
                background: STYLE_BY_ORGANIZATION_MODEL[item?.code]?.background,
              }"
            >
              <SynIcon
                :name="item?.code?.toLowerCase()"
                custom-class="w-10 h-10 fill-white"
              />
            </div>
            <div
              class="
                w-full
                h-full
                flex
                items-center
                justify-start
                flex-col
                pt-8
              "
            >
              <span class="uppercase">{{ item?.name }}</span>
              <span class="text-sm text-gray-500 italic text-center">
                {{
                  $t(STYLE_BY_ORGANIZATION_MODEL[item?.code]?.subTitle)
                }}</span
              >
            </div>
            <div class="flex-center flex-col space-y-2 pt-4">
              <SynIcon
                name="medal"
                class="fill-current"
                custom-class="w-5 h-5"
              />
              <span class="text-current">
                {{ $t('SELECT_ORGANIZATION_MODEL_FREE_TRIAL_2') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
  <SynModal
    v-if="isOpenLimitPersonalModel"
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-class="w-max"
    container-style="max-height: 90%; max-width: 90%;min-width: 36rem;max-width: 40rem"
    disable-click-outside
    is-hidden-header
    is-hidden-footer
    :cancel-text="$t('SELECT_ORGANIZATION_MODEL_LIMIT_PERSONAL_RETRY')"
    @cancel="onClosePersonalLimit"
  >
    <template #body>
      <div class="flex-center flex-col space-y-4 py-8">
        <div class="text-xl text-gray-800 font-semibold">
          {{ $t('SELECT_ORGANIZATION_MODEL_LIMIT_PERSONAL_WARNING') }}
        </div>
        <div class="flex-center flex-col">
          <span class="text-gray-600">
            {{
              $t('SELECT_ORGANIZATION_MODEL_LIMIT_PERSONAL_TITLE', {
                orgModel: currentChoice?.name,
              })
            }}
          </span>
          <span class="text-sm text-gray-600">
            {{ $t('SELECT_ORGANIZATION_MODEL_LIMIT_PERSONAL_OTHER_CHOICE') }} :
          </span>
        </div>

        <div class="pt-8 flex items-stretch space-x-16 justify-center">
          <div
            v-for="item in organizationModels?.filter(
              (m) => m?.code !== 'PERSONAL'
            )"
            :id="
              item?.code == 'COMPANY'
                ? GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_personal_2nd_select_organization
                : GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                    .setting_org_select_personal_2nd_select_team
            "
            :key="item?.id"
            class="
              w-1/3
              p-4
              rounded-md
              flex-center flex-col
              shadow-md
              border border-gray-50
              hover:border-current-50
              cursor-pointer
              hover:bg-current-50 hover:shadow-xl
              relative
            "
            :class="{
              'cursor-wait': isLoadingChoosePersonal,
            }"
            @click="onChangeOrgMode(item)"
          >
            <div
              class="h-16 w-16 flex-center absolute -top-8 rounded-full"
              :style="{
                background: STYLE_BY_ORGANIZATION_MODEL[item?.code]?.background,
              }"
            >
              <SynIcon
                :name="item?.code?.toLowerCase()"
                custom-class="w-10 h-10 fill-white"
              />
            </div>
            <div
              class="
                w-full
                h-full
                flex
                items-center
                justify-start
                flex-col
                pt-8
              "
            >
              <span class="uppercase">{{ item?.name }}</span>
              <span class="text-sm text-gray-500 italic">
                {{
                  $t(STYLE_BY_ORGANIZATION_MODEL[item?.code]?.subTitle)
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="pt-8 flex items-stretch space-x-16 justify-center">
          <SynButton
            :id="
              GOOGLE_ANALYTICS_ELEMENT_ID.SELECT_ORG_MODEL
                .setting_org_select_personal_2nd_back
            "
            type-text
            color="gray"
            :label="$t('COMMON_LABEL_BACK')"
            @click="onClosePersonalLimit"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
