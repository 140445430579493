<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 66 66"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M47.5 65.3c-1.1 0-2.2-.4-3.1-1.3l-4.1-4.1c-.4-.4-.4-1 0-1.4l18.2-18.2c.4-.4 1-.4 1.4 0l4.1 4.1c1.7 1.7 1.7 4.4 0 6.1L50.5 64c-.8.8-1.9 1.3-3 1.3zm-5.1-6.1 3.4 3.4c.9.9 2.4.9 3.3 0l13.5-13.5c.9-.9.9-2.4 0-3.3l-3.4-3.4z"
      ></path>
      <path
        d="M43.2 57.9H43c-2.5-.5-4.8-1.6-6.5-3.4l-3.6-3.6c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l3.6 3.6c1.5 1.5 3.4 2.4 5.5 2.8.5.1.9.6.8 1.2-.1.5-.5.8-1 .8zM57.1 44c-.5 0-.9-.4-1-.8l-.2-1.2c-.4-2.6-1-6.8-1.8-8.8l-7.4-7.4c-.8-.8-2-1.3-3.4-1.3-.6 0-1-.4-1-1s.4-1 1-1c1.9 0 3.6.7 4.8 1.9l7.5 7.5c.1.1.2.2.2.3.9 2.2 1.6 6.6 2 9.5l.2 1.2c.1.5-.3 1.1-.8 1.1zM18.6 65.3c-1.1 0-2.2-.4-3.1-1.3L2 50.5C.3 48.8.3 46.1 2 44.3l4.1-4.1c.2-.2.4-.3.7-.3.3 0 .5.1.7.3l18.2 18.3c.4.4.4 1 0 1.4L21.6 64c-.8.8-1.9 1.3-3 1.3zm-11.8-23-3.4 3.4c-.9 1-.9 2.5 0 3.4l13.5 13.5c.9.9 2.4.9 3.3 0l3.4-3.4z"
      ></path>
      <path
        d="M22.8 57.9c-.5 0-.9-.3-1-.8s.3-1.1.8-1.2c2.1-.4 4-1.4 5.5-2.8l13.2-13.2c.4-.4 1-.4 1.4 0s.4 1 0 1.4L29.5 54.5c-1.8 1.8-4 2.9-6.5 3.4h-.2zM8.9 44h-.2c-.5-.1-.9-.6-.8-1.1l.2-1.2c.4-2.9 1.1-7.3 2-9.5 0-.1.1-.2.2-.3l2.5-2.5c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-2.4 2.4c-.8 2-1.4 6.3-1.8 8.8l-.2 1.2c0 .4-.4.8-.9.8zM59.2 26c-.3 0-.5-.1-.7-.3L40.2 7.5c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7L44.3 2C46.1.3 48.8.3 50.5 2L64 15.5c1.7 1.7 1.7 4.4 0 6.1l-4.1 4.1c-.2.2-.4.3-.7.3zM42.3 6.8l16.9 16.8 3.4-3.4c.9-.9.9-2.4 0-3.3L49.1 3.4c-.9-.9-2.4-.9-3.4 0z"
      ></path>
      <path
        d="M52.4 31.2c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.4-1.4c1.5-1.5 2.4-3.4 2.8-5.5.1-.5.6-.9 1.2-.8.5.1.9.6.8 1.2-.5 2.5-1.6 4.8-3.4 6.5l-1.4 1.4c-.2.2-.4.3-.7.3zM33.4 12c-.4 0-.7-.2-.9-.6-.2-.5 0-1.1.5-1.3 2.3-1 6.1-1.6 9-2l.9-.1c.6-.1 1.1.3 1.1.8.1.5-.3 1.1-.8 1.1l-1 .1c-2.6.4-6.4 1-8.4 1.9-.1.1-.3.1-.4.1zM6.8 26c-.3 0-.5-.1-.7-.3L2 21.6c-.8-.8-1.3-1.9-1.3-3.1s.5-2.2 1.3-3.1L15.5 2C17.2.3 19.9.3 21.7 2l4.1 4.1c.2.2.3.4.3.7s-.1.5-.3.7L7.5 25.7c-.2.2-.4.3-.7.3zM18.6 2.7c-.6 0-1.2.2-1.7.7L3.4 16.9c-.4.4-.7 1-.7 1.6s.2 1.2.7 1.6l3.4 3.4L23.7 6.8l-3.4-3.4c-.5-.4-1.1-.7-1.7-.7zM35.2 43.8c-1 0-1.9-.4-2.7-1.1L22.8 33c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l9.7 9.7c.7.7 1.8.7 2.5 0 .3-.3.5-.8.5-1.2 0-.5-.2-.9-.5-1.2-.4-.4-.4-1 0-1.4s1-.4 1.4 0c.7.7 1.1 1.7 1.1 2.7s-.4 1.9-1.1 2.7c-.7.6-1.7.9-2.6.9z"
      ></path>
      <path
        d="M41 41.8c-1 0-1.9-.4-2.7-1.1L26.6 29.1c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l11.7 11.6c.7.7 1.8.7 2.5 0s.7-1.8 0-2.5L30.5 25.2c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l11.7 11.6c1.5 1.5 1.5 3.8 0 5.3-.7.8-1.7 1.1-2.6 1.1z"
      ></path>
      <path
        d="M42.8 36c-1 0-1.9-.4-2.7-1.1-.4-.4-.4-1 0-1.4s1-.4 1.4 0c.7.7 1.8.7 2.5 0 .3-.3.5-.8.5-1.2 0-.5-.2-.9-.5-1.2l-6.3-6.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l6.3 6.3c.7.7 1.1 1.7 1.1 2.7s-.4 1.9-1.1 2.7c-.6.5-1.6.9-2.6.9zM29.5 46c-1 0-1.9-.4-2.7-1.1L11.5 29.5c-1.8-1.8-2.9-4-3.4-6.5-.1-.5.3-1.1.8-1.2s1.1.3 1.2.8c.4 2.1 1.4 4 2.8 5.5l15.4 15.4c.7.7 1.8.7 2.5 0s.7-1.8 0-2.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0c1.5 1.5 1.5 3.8 0 5.3-.7.8-1.7 1.1-2.7 1.1z"
      ></path>
      <path
        d="M40.9 27.5c-.3 0-.5-.1-.7-.3l-8.1-8.1c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l7.3 7.3c1.2-1.8.9-4.1-.6-5.7l-7.4-7.5c-2-.8-6.3-1.4-8.8-1.8l-1.2-.2c-.5-.1-.9-.6-.8-1.1s.6-.9 1.1-.8l1.2.2c2.9.4 7.3 1.1 9.5 2 .1.1.2.1.3.2l7.5 7.6c2.6 2.6 2.6 6.7 0 9.3-.2.2-.4.3-.7.3zM47.5 65.3c-1.1 0-2.2-.4-3.1-1.3l-4.1-4.1c-.4-.4-.4-1 0-1.4l18.2-18.2c.4-.4 1-.4 1.4 0l4.1 4.1c1.7 1.7 1.7 4.4 0 6.1L50.5 64c-.8.8-1.9 1.3-3 1.3zm-5.1-6.1 3.4 3.4c.9.9 2.4.9 3.3 0l13.5-13.5c.9-.9.9-2.4 0-3.3l-3.4-3.4z"
      ></path>
      <path
        d="M43.2 57.9H43c-2.5-.5-4.8-1.6-6.5-3.4l-3.6-3.6c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l3.6 3.6c1.5 1.5 3.4 2.4 5.5 2.8.5.1.9.6.8 1.2-.1.5-.5.8-1 .8zM57.1 44c-.5 0-.9-.4-1-.8l-.2-1.2c-.4-2.6-1-6.8-1.8-8.8l-7.4-7.4c-.8-.8-2-1.3-3.4-1.3-.6 0-1-.4-1-1s.4-1 1-1c1.9 0 3.6.7 4.8 1.9l7.5 7.5c.1.1.2.2.2.3.9 2.2 1.6 6.6 2 9.5l.2 1.2c.1.5-.3 1.1-.8 1.1zM18.6 65.3c-1.1 0-2.2-.4-3.1-1.3L2 50.5C.3 48.8.3 46.1 2 44.3l4.1-4.1c.2-.2.4-.3.7-.3.3 0 .5.1.7.3l18.2 18.3c.4.4.4 1 0 1.4L21.6 64c-.8.8-1.9 1.3-3 1.3zm-11.8-23-3.4 3.4c-.9 1-.9 2.5 0 3.4l13.5 13.5c.9.9 2.4.9 3.3 0l3.4-3.4z"
      ></path>
      <path
        d="M22.8 57.9c-.5 0-.9-.3-1-.8s.3-1.1.8-1.2c2.1-.4 4-1.4 5.5-2.8l13.2-13.2c.4-.4 1-.4 1.4 0s.4 1 0 1.4L29.5 54.5c-1.8 1.8-4 2.9-6.5 3.4h-.2zM8.9 44h-.2c-.5-.1-.9-.6-.8-1.1l.2-1.2c.4-2.9 1.1-7.3 2-9.5 0-.1.1-.2.2-.3l2.5-2.5c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-2.4 2.4c-.8 2-1.4 6.3-1.8 8.8l-.2 1.2c0 .4-.4.8-.9.8zM59.2 26c-.3 0-.5-.1-.7-.3L40.2 7.5c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7L44.3 2C46.1.3 48.8.3 50.5 2L64 15.5c1.7 1.7 1.7 4.4 0 6.1l-4.1 4.1c-.2.2-.4.3-.7.3zM42.3 6.8l16.9 16.8 3.4-3.4c.9-.9.9-2.4 0-3.3L49.1 3.4c-.9-.9-2.4-.9-3.4 0z"
      ></path>
      <path
        d="M52.4 31.2c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.4-1.4c1.5-1.5 2.4-3.4 2.8-5.5.1-.5.6-.9 1.2-.8.5.1.9.6.8 1.2-.5 2.5-1.6 4.8-3.4 6.5l-1.4 1.4c-.2.2-.4.3-.7.3zM33.4 12c-.4 0-.7-.2-.9-.6-.2-.5 0-1.1.5-1.3 2.3-1 6.1-1.6 9-2l.9-.1c.6-.1 1.1.3 1.1.8.1.5-.3 1.1-.8 1.1l-1 .1c-2.6.4-6.4 1-8.4 1.9-.1.1-.3.1-.4.1zM6.8 26c-.3 0-.5-.1-.7-.3L2 21.6c-.8-.8-1.3-1.9-1.3-3.1s.5-2.2 1.3-3.1L15.5 2C17.2.3 19.9.3 21.7 2l4.1 4.1c.2.2.3.4.3.7s-.1.5-.3.7L7.5 25.7c-.2.2-.4.3-.7.3zM18.6 2.7c-.6 0-1.2.2-1.7.7L3.4 16.9c-.4.4-.7 1-.7 1.6s.2 1.2.7 1.6l3.4 3.4L23.7 6.8l-3.4-3.4c-.5-.4-1.1-.7-1.7-.7zM35.2 43.8c-1 0-1.9-.4-2.7-1.1L22.8 33c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l9.7 9.7c.7.7 1.8.7 2.5 0 .3-.3.5-.8.5-1.2 0-.5-.2-.9-.5-1.2-.4-.4-.4-1 0-1.4s1-.4 1.4 0c.7.7 1.1 1.7 1.1 2.7s-.4 1.9-1.1 2.7c-.7.6-1.7.9-2.6.9z"
      ></path>
      <path
        d="M41 41.8c-1 0-1.9-.4-2.7-1.1L26.6 29.1c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l11.7 11.6c.7.7 1.8.7 2.5 0s.7-1.8 0-2.5L30.5 25.2c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l11.7 11.6c1.5 1.5 1.5 3.8 0 5.3-.7.8-1.7 1.1-2.6 1.1z"
      ></path>
      <path
        d="M42.8 36c-1 0-1.9-.4-2.7-1.1-.4-.4-.4-1 0-1.4s1-.4 1.4 0c.7.7 1.8.7 2.5 0 .3-.3.5-.8.5-1.2 0-.5-.2-.9-.5-1.2l-6.3-6.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l6.3 6.3c.7.7 1.1 1.7 1.1 2.7s-.4 1.9-1.1 2.7c-.6.5-1.6.9-2.6.9zM29.5 46c-1 0-1.9-.4-2.7-1.1L11.5 29.5c-1.8-1.8-2.9-4-3.4-6.5-.1-.5.3-1.1.8-1.2s1.1.3 1.2.8c.4 2.1 1.4 4 2.8 5.5l15.4 15.4c.7.7 1.8.7 2.5 0s.7-1.8 0-2.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0c1.5 1.5 1.5 3.8 0 5.3-.7.8-1.7 1.1-2.7 1.1z"
      ></path>
      <path
        d="M40.9 27.5c-.3 0-.5-.1-.7-.3l-8.1-8.1c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l7.3 7.3c1.2-1.8.9-4.1-.6-5.7l-7.4-7.5c-2-.8-6.3-1.4-8.8-1.8l-1.2-.2c-.5-.1-.9-.6-.8-1.1s.6-.9 1.1-.8l1.2.2c2.9.4 7.3 1.1 9.5 2 .1.1.2.1.3.2l7.5 7.6c2.6 2.6 2.6 6.7 0 9.3-.2.2-.4.3-.7.3z"
      ></path>
    </g>
  </svg>
</template>
