<script setup lang="ts">
import { ref, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    name: string;
    description: string;
    isDisabled?: boolean;
  }>(),
  {
    isDisabled: false,
  }
);

defineEmits<{
  (e: 'update:name', name: string): void;
  (e: 'update:description', description: string): void;
}>();

const isValidate = ref(false);
const errorMessageName = computed(() =>
  !props?.name ? 'TICKET_TASK_FORM_LABEL_NAME_REQUIRE' : ''
);

const onBlurNameInput = () => {
  isValidate.value = true;
};
</script>
<template>
  <div class="w-full flex-1">
    <div class="flex flex-col">
      <span class="py-1 text-gray-600">
        {{ $t('PROJECTMANAGEMENT_LABEL_NAME') }}
      </span>
      <SynFormInput :error-message="isValidate && errorMessageName">
        <SynInput
          :model-value="name"
          autofocus
          :placeholder="$t('PROJECTMANAGEMENT_LABEL_TYPEDOMAINNAME')"
          :is-disabled="isDisabled"
          @update:model-value="(value) => $emit('update:name', value)"
          @blur="onBlurNameInput"
        />
      </SynFormInput>
    </div>

    <!-- DESCRIPTION -->
    <div class="flex flex-col">
      <span class="py-1 text-gray-600">
        {{ $t('PROJECTMANAGEMENT_LABEL_DESCRIPTION') }}
      </span>
      <SynInputArea
        :model-value="description"
        :placeholder="$t('PROJECTMANAGEMENT_LABEL_TYPEDOMAINDESCRIPTION')"
        style="min-height: 100px"
        :is-disabled="isDisabled"
        @update:model-value="(value) => $emit('update:description', value)"
      />
    </div>
  </div>
</template>

<style>
.outline-red {
  outline: 1px solid red;
}
.domain-min-height {
  min-height: 82px;
}
</style>
