<template>
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 32 32"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path xmlns="http://www.w3.org/2000/svg" d="m14 15h4v2h-4z"></path>-->
  <!--      <path-->
  <!--        xmlns="http://www.w3.org/2000/svg"-->
  <!--        d="m29.785 14.866-13.785-12.866-13.785 12.866c-.435.406-.148 1.134.447 1.134h3.338v14h20v-14h3.338c.595 0 .882-.728.447-1.134zm-7.785 10.634c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-4.5h12zm0-5.5h-12v-2.5c0-.276.224-.5.5-.5h2.5v-2c0-.551.448-1 1-1h4c.552 0 1 .449 1 1v2h2.5c.276 0 .5.224.5.5z"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->

  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 512 512"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--  >-->
  <!--    <g>-->
  <!--      <g data-name="20 WFH">-->
  <!--        <path-->
  <!--          d="m486.21 231.67-208.36-190a32.29 32.29 0 0 0-43.7 0l-208.36 190A26.17 26.17 0 0 0 17 251.15c0 13.28 10 27 26.8 27a26.71 26.71 0 0 0 18-7l2.78-2.53L234.21 114a32.18 32.18 0 0 1 43.58 0l172.4 157.17a26.68 26.68 0 0 0 18 7 26.73 26.73 0 0 0 18-46.49z"-->
  <!--          opacity="1"-->
  <!--        ></path>-->
  <!--        <path-->
  <!--          d="M242.29 122.88 74.64 275.74v171.79a31.26 31.26 0 0 0 31.22 31.22h300.28a31.26 31.26 0 0 0 31.22-31.22v-171.8L269.71 122.87a20.26 20.26 0 0 0-27.42.01zm99.78 270.71a18.26 18.26 0 0 1-18.24 18.24H188.16a18.26 18.26 0 0 1-18.23-18.24v-51.11h23.12v12.24A10.94 10.94 0 0 0 204 365.66h7.3a10.94 10.94 0 0 0 11-10.94v-12.24h67.52v12.24a10.94 10.94 0 0 0 10.94 10.94H308a10.94 10.94 0 0 0 11-10.94v-12.24h23.12zm0-84.91v21.8H169.93v-21.8a18.26 18.26 0 0 1 18.23-18.24h135.67a18.26 18.26 0 0 1 18.24 18.24zm-40.67-25.13a5 5 0 0 1-4 5.81 5.45 5.45 0 0 1-.89.08 5 5 0 0 1-4.92-4.12c-2.9-16.12-17.86-27.83-35.56-27.83s-32.66 11.71-35.56 27.83a5 5 0 1 1-9.84-1.77c3.76-20.89 22.86-36.06 45.4-36.06s41.61 15.17 45.37 36.06z"-->
  <!--          opacity="1"-->
  <!--        ></path>-->
  <!--      </g>-->
  <!--    </g>-->
  <!--  </svg>-->

  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 511.971 511.971"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M15.174 272.587a14.95 14.95 0 0 0 10.611-4.398l26.837-26.863v.424L247.637 47.987a15.002 15.002 0 0 1 10.572-4.359h.059a15 15 0 0 1 10.597 4.443L458.369 239.34v-.085l26.606 26.884c5.827 5.888 15.325 5.938 21.213.11s5.938-15.325.109-21.213L268.192 4.449A14.995 14.995 0 0 0 257.566 0h-.035a15 15 0 0 0-10.611 4.398l-96.828 96.92V62.542c0-6.893-5.588-12.481-12.481-12.481H80.128c-6.893 0-12.481 5.588-12.481 12.481v121.3L4.563 246.986c-5.855 5.861-5.851 15.358.01 21.213a14.948 14.948 0 0 0 10.601 4.388zM174.905 273.337h162.161v131.839H174.905z"
        opacity="1"
      ></path>
      <path
        d="M496.796 481.971h-38.427V281.963L258.126 79.856 52.623 284.041v197.93h-37.24c-8.284 0-15 6.716-15 15s6.716 15 15 15h481.413c8.284 0 15-6.716 15-15s-6.715-15-15-15zm-127.6-46.795H142.775c-8.284 0-15-6.716-15-15s6.716-15 15-15h2.13V258.337c0-8.284 6.716-15 15-15h192.161c8.284 0 15 6.716 15 15v146.839h2.13c8.284 0 15 6.716 15 15s-6.716 15-15 15z"
        opacity="1"
      ></path>
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
