const toDate = (input: Date | string | number): Date => {
    if (input instanceof Date) return input;
    if (!isNaN(parseInt(input.toString())) || /^\d+$/.test(input.toString()))
        return new Date(parseInt(input.toString()));
    input = (input.toString() || '')
        .trim()
        .replace(/\.\d+/, '') // remove milliseconds
        .replace(/-/, '/')
        .replace(/-/, '/')
        .replace(/(\d)T(\d)/, '$1 $2')
        .replace(/Z/, ' UTC') // 2017-2-5T3:57:52Z -> 2017-2-5 3:57:52UTC
        .replace(/([+-]\d\d):?(\d\d)/, ' $1$2'); // -04:00 -> -0400

    return new Date(input);
};

const diffSec = (date: Date, relativeDate: Date): number => {
    const relDate = relativeDate ? toDate(relativeDate) : new Date();
    return (+relDate - +toDate(date)) / 1000;
};

const SEC_ARRAY = [
    60, // 60 seconds in 1 min
    60, // 60 mins in 1 hour
    24, // 24 hours in 1 day
    7, // 7 days in 1 week
    365 / 7 / 12, // 4.345238095238096 weeks in 1 month
    12, // 12 months in 1 year
];
export function nextInterval(diff: number): number {
    let rst = 1,
        i = 0,
        d = Math.abs(diff);
    for (; diff >= SEC_ARRAY[i] && i < SEC_ARRAY.length; i++) {
        diff /= SEC_ARRAY[i];
        rst *= SEC_ARRAY[i];
    }
    d = d % rst;
    d = d ? rst - d : rst;
    return Math.ceil(d);
}

export const runUpdateTimeAgo = (date, opts, timer, callback) => {
    timer && clearTimeout(timer);

    const relativeDate = opts.relativeDate,
        minInterval = opts.minInterval;
    // get diff seconds
    const diff = diffSec(date, relativeDate);
    // console.log(
    //   '🚀 ~ file: time-ago.ts ~ line 51 ~ runUpdateTimeAgo ~ diff',
    //   diff
    // );

    // render
    //   node.innerText = formatDiff(diff, localeFunc);
    callback();

    timer = setTimeout(function () {
        runUpdateTimeAgo(date, opts, timer, callback);
    }, Math.min(
        Math.max(nextInterval(diff), minInterval || 1) * 1000,
        0x7fffffff
    ));

    // there is no need to save node in object. Just save the key
    //   TIMER_POOL[tid] = 0;
    //   setTimerId(node, tid);
};
