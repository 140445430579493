<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="564.516"
    height="137.794"
    viewBox="0 0 564.516 137.794"
  >
    <g
      id="Group_4275"
      data-name="Group 4275"
      transform="translate(-676.359 -183.881)"
    >
      <g
        id="Group_4269"
        data-name="Group 4269"
        transform="translate(1027.697 206.936) rotate(-25)"
      >
        <g id="Layer_2" data-name="Layer 2" transform="translate(1.25 1.25)">
          <rect
            id="Rectangle_793"
            data-name="Rectangle 793"
            width="67.041"
            height="60.165"
            rx="1.5"
            transform="translate(0 5.157)"
            fill="#abdedb"
          />
          <circle
            id="Ellipse_271"
            data-name="Ellipse 271"
            cx="18.05"
            cy="18.05"
            r="18.05"
            transform="translate(37.818 37.818)"
            fill="#ffcb27"
          />
          <path
            id="Path_1570"
            data-name="Path 1570"
            d="M7.829,13.283A2.613,2.613,0,0,1,5.25,10.7V3.829a2.579,2.579,0,0,1,5.157,0V10.7a2.613,2.613,0,0,1-2.579,2.579Zm17.19,0A2.613,2.613,0,0,1,22.44,10.7V3.829a2.579,2.579,0,1,1,5.157,0V10.7A2.613,2.613,0,0,1,25.019,13.283Zm17.19,0A2.613,2.613,0,0,1,39.63,10.7V3.829a2.579,2.579,0,0,1,5.157,0V10.7A2.613,2.613,0,0,1,42.209,13.283Z"
            transform="translate(8.502 -1.25)"
            fill="#71bbb6"
          />
          <g
            id="Group_4268"
            data-name="Group 4268"
            transform="translate(12.893 21.488)"
          >
            <circle
              id="Ellipse_272"
              data-name="Ellipse 272"
              cx="3.438"
              cy="3.438"
              r="3.438"
              fill="#fff"
            />
            <circle
              id="Ellipse_273"
              data-name="Ellipse 273"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(0 12.033)"
              fill="#fff"
            />
            <circle
              id="Ellipse_274"
              data-name="Ellipse 274"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(0 24.066)"
              fill="#fff"
            />
            <circle
              id="Ellipse_275"
              data-name="Ellipse 275"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(17.19)"
              fill="#fff"
            />
            <circle
              id="Ellipse_276"
              data-name="Ellipse 276"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(17.19 12.033)"
              fill="#fff"
            />
            <circle
              id="Ellipse_277"
              data-name="Ellipse 277"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(17.19 24.066)"
              fill="#fff"
            />
            <circle
              id="Ellipse_278"
              data-name="Ellipse 278"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(34.38)"
              fill="#fff"
            />
            <path
              id="Path_1571"
              data-name="Path 1571"
              d="M22.767,31.946a2.578,2.578,0,0,1-2.1-1.1l-3.438-4.779a2.647,2.647,0,0,1-.481-1.513V17.679a2.579,2.579,0,1,1,5.157,0v6.051l2.957,4.126a2.578,2.578,0,0,1-.584,3.438,2.51,2.51,0,0,1-1.513.653Z"
              transform="translate(23.647 11.029)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_4271"
        data-name="Group 4271"
        transform="matrix(0.809, 0.588, -0.588, 0.809, 1188.758, 223.67)"
      >
        <path
          id="Path_1572"
          data-name="Path 1572"
          d="M68.75,11.384v36.1a2.351,2.351,0,0,1-.691,1.664l-15.7,15.7a2.351,2.351,0,0,1-1.664.691H9.884A8.627,8.627,0,0,1,1.25,56.907V11.384A8.627,8.627,0,0,1,9.884,2.75H60.116a8.627,8.627,0,0,1,8.634,8.634Z"
          transform="translate(0 3.209)"
          fill="#efeded"
        />
        <path
          id="Path_1573"
          data-name="Path 1573"
          d="M68.75,11.384v10.2H1.25v-10.2A8.627,8.627,0,0,1,9.884,2.75H60.116a8.627,8.627,0,0,1,8.634,8.634Z"
          transform="translate(0 3.209)"
          fill="#abdedb"
        />
        <g
          id="Group_4270"
          data-name="Group 4270"
          transform="translate(13.808 1.25)"
        >
          <path
            id="Path_1574"
            data-name="Path 1574"
            d="M20.163,15.378a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,1,1,4.709,0v9.419A2.355,2.355,0,0,1,20.163,15.378Zm-12.558,0A2.355,2.355,0,0,1,5.25,13.023V3.6a2.355,2.355,0,0,1,4.709,0v9.419A2.355,2.355,0,0,1,7.6,15.378Zm37.674,0a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,0,1,4.709,0v9.419A2.355,2.355,0,0,1,45.279,15.378Zm-12.558,0a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,1,1,4.709,0v9.419a2.355,2.355,0,0,1-2.355,2.355Z"
            transform="translate(-5.25 -1.25)"
            fill="#ffde50"
          />
        </g>
        <path
          id="Path_1575"
          data-name="Path 1575"
          d="M16.844,28.477a2.336,2.336,0,0,1-1.664-.691L8.9,21.508a2.355,2.355,0,0,1,3.328-3.328l4.615,4.612L27.738,11.9a2.355,2.355,0,0,1,3.328,3.328L18.508,27.787a2.336,2.336,0,0,1-1.664.691Z"
          transform="translate(15.017 21.435)"
          fill="#319f98"
        />
        <path
          id="Path_1576"
          data-name="Path 1576"
          d="M36.657,16.25V18.6a2.352,2.352,0,0,1-.691,1.664l-15.7,15.7a2.352,2.352,0,0,1-1.664.691H16.25V18.6A2.37,2.37,0,0,1,18.6,16.25Z"
          transform="translate(32.093 32.093)"
          fill="#bec7d6"
        />
      </g>
      <g
        id="Group_4272"
        data-name="Group 4272"
        transform="translate(791.696 206.936) rotate(-25)"
      >
        <g id="Layer_2-2" data-name="Layer 2" transform="translate(1.25 1.25)">
          <rect
            id="Rectangle_793-2"
            data-name="Rectangle 793"
            width="67.041"
            height="60.165"
            rx="1.5"
            transform="translate(0 5.157)"
            fill="#abdedb"
          />
          <circle
            id="Ellipse_271-2"
            data-name="Ellipse 271"
            cx="18.05"
            cy="18.05"
            r="18.05"
            transform="translate(37.818 37.818)"
            fill="#ffcb27"
          />
          <path
            id="Path_1570-2"
            data-name="Path 1570"
            d="M7.829,13.283A2.613,2.613,0,0,1,5.25,10.7V3.829a2.579,2.579,0,0,1,5.157,0V10.7a2.613,2.613,0,0,1-2.579,2.579Zm17.19,0A2.613,2.613,0,0,1,22.44,10.7V3.829a2.579,2.579,0,1,1,5.157,0V10.7A2.613,2.613,0,0,1,25.019,13.283Zm17.19,0A2.613,2.613,0,0,1,39.63,10.7V3.829a2.579,2.579,0,0,1,5.157,0V10.7A2.613,2.613,0,0,1,42.209,13.283Z"
            transform="translate(8.502 -1.25)"
            fill="#71bbb6"
          />
          <g
            id="Group_4268-2"
            data-name="Group 4268"
            transform="translate(12.893 21.488)"
          >
            <circle
              id="Ellipse_272-2"
              data-name="Ellipse 272"
              cx="3.438"
              cy="3.438"
              r="3.438"
              fill="#fff"
            />
            <circle
              id="Ellipse_273-2"
              data-name="Ellipse 273"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(0 12.033)"
              fill="#fff"
            />
            <circle
              id="Ellipse_274-2"
              data-name="Ellipse 274"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(0 24.066)"
              fill="#fff"
            />
            <circle
              id="Ellipse_275-2"
              data-name="Ellipse 275"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(17.19)"
              fill="#fff"
            />
            <circle
              id="Ellipse_276-2"
              data-name="Ellipse 276"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(17.19 12.033)"
              fill="#fff"
            />
            <circle
              id="Ellipse_277-2"
              data-name="Ellipse 277"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(17.19 24.066)"
              fill="#fff"
            />
            <circle
              id="Ellipse_278-2"
              data-name="Ellipse 278"
              cx="3.438"
              cy="3.438"
              r="3.438"
              transform="translate(34.38)"
              fill="#fff"
            />
            <path
              id="Path_1571-2"
              data-name="Path 1571"
              d="M22.767,31.946a2.578,2.578,0,0,1-2.1-1.1l-3.438-4.779a2.647,2.647,0,0,1-.481-1.513V17.679a2.579,2.579,0,1,1,5.157,0v6.051l2.957,4.126a2.578,2.578,0,0,1-.584,3.438,2.51,2.51,0,0,1-1.513.653Z"
              transform="translate(23.647 11.029)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_4273"
        data-name="Group 4273"
        transform="matrix(0.809, 0.588, -0.588, 0.809, 952.757, 223.67)"
      >
        <path
          id="Path_1572-2"
          data-name="Path 1572"
          d="M68.75,11.384v36.1a2.351,2.351,0,0,1-.691,1.664l-15.7,15.7a2.351,2.351,0,0,1-1.664.691H9.884A8.627,8.627,0,0,1,1.25,56.907V11.384A8.627,8.627,0,0,1,9.884,2.75H60.116a8.627,8.627,0,0,1,8.634,8.634Z"
          transform="translate(0 3.209)"
          fill="#efeded"
        />
        <path
          id="Path_1573-2"
          data-name="Path 1573"
          d="M68.75,11.384v10.2H1.25v-10.2A8.627,8.627,0,0,1,9.884,2.75H60.116a8.627,8.627,0,0,1,8.634,8.634Z"
          transform="translate(0 3.209)"
          fill="#abdedb"
        />
        <g
          id="Group_4270-2"
          data-name="Group 4270"
          transform="translate(13.808 1.25)"
        >
          <path
            id="Path_1574-2"
            data-name="Path 1574"
            d="M20.163,15.378a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,1,1,4.709,0v9.419A2.355,2.355,0,0,1,20.163,15.378Zm-12.558,0A2.355,2.355,0,0,1,5.25,13.023V3.6a2.355,2.355,0,0,1,4.709,0v9.419A2.355,2.355,0,0,1,7.6,15.378Zm37.674,0a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,0,1,4.709,0v9.419A2.355,2.355,0,0,1,45.279,15.378Zm-12.558,0a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,1,1,4.709,0v9.419a2.355,2.355,0,0,1-2.355,2.355Z"
            transform="translate(-5.25 -1.25)"
            fill="#ffde50"
          />
        </g>
        <path
          id="Path_1575-2"
          data-name="Path 1575"
          d="M16.844,28.477a2.336,2.336,0,0,1-1.664-.691L8.9,21.508a2.355,2.355,0,0,1,3.328-3.328l4.615,4.612L27.738,11.9a2.355,2.355,0,0,1,3.328,3.328L18.508,27.787a2.336,2.336,0,0,1-1.664.691Z"
          transform="translate(15.017 21.435)"
          fill="#319f98"
        />
        <path
          id="Path_1576-2"
          data-name="Path 1576"
          d="M36.657,16.25V18.6a2.352,2.352,0,0,1-.691,1.664l-15.7,15.7a2.352,2.352,0,0,1-1.664.691H16.25V18.6A2.37,2.37,0,0,1,18.6,16.25Z"
          transform="translate(32.093 32.093)"
          fill="#bec7d6"
        />
      </g>
      <g
        id="Group_4274"
        data-name="Group 4274"
        transform="matrix(0.809, 0.588, -0.588, 0.809, 715.758, 225.645)"
      >
        <path
          id="Path_1572-3"
          data-name="Path 1572"
          d="M68.75,11.384v36.1a2.351,2.351,0,0,1-.691,1.664l-15.7,15.7a2.351,2.351,0,0,1-1.664.691H9.884A8.627,8.627,0,0,1,1.25,56.907V11.384A8.627,8.627,0,0,1,9.884,2.75H60.116a8.627,8.627,0,0,1,8.634,8.634Z"
          transform="translate(0 3.209)"
          fill="#efeded"
        />
        <path
          id="Path_1573-3"
          data-name="Path 1573"
          d="M68.75,11.384v10.2H1.25v-10.2A8.627,8.627,0,0,1,9.884,2.75H60.116a8.627,8.627,0,0,1,8.634,8.634Z"
          transform="translate(0 3.209)"
          fill="#abdedb"
        />
        <g
          id="Group_4270-3"
          data-name="Group 4270"
          transform="translate(13.808 1.25)"
        >
          <path
            id="Path_1574-3"
            data-name="Path 1574"
            d="M20.163,15.378a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,1,1,4.709,0v9.419A2.355,2.355,0,0,1,20.163,15.378Zm-12.558,0A2.355,2.355,0,0,1,5.25,13.023V3.6a2.355,2.355,0,0,1,4.709,0v9.419A2.355,2.355,0,0,1,7.6,15.378Zm37.674,0a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,0,1,4.709,0v9.419A2.355,2.355,0,0,1,45.279,15.378Zm-12.558,0a2.355,2.355,0,0,1-2.355-2.355V3.6a2.355,2.355,0,1,1,4.709,0v9.419a2.355,2.355,0,0,1-2.355,2.355Z"
            transform="translate(-5.25 -1.25)"
            fill="#ffde50"
          />
        </g>
        <path
          id="Path_1575-3"
          data-name="Path 1575"
          d="M16.844,28.477a2.336,2.336,0,0,1-1.664-.691L8.9,21.508a2.355,2.355,0,0,1,3.328-3.328l4.615,4.612L27.738,11.9a2.355,2.355,0,0,1,3.328,3.328L18.508,27.787a2.336,2.336,0,0,1-1.664.691Z"
          transform="translate(15.017 21.435)"
          fill="#319f98"
        />
        <path
          id="Path_1576-3"
          data-name="Path 1576"
          d="M36.657,16.25V18.6a2.352,2.352,0,0,1-.691,1.664l-15.7,15.7a2.352,2.352,0,0,1-1.664.691H16.25V18.6A2.37,2.37,0,0,1,18.6,16.25Z"
          transform="translate(32.093 32.093)"
          fill="#bec7d6"
        />
      </g>
    </g>
  </svg>
</template>
