import { AxiosInstance } from 'axios';
import BaseRequest from '@/base/api/base-request';
import { ISecurityRepository } from '@/domain/interfaces/repositories/ISecurityRepository';

export default class SecurityRepository implements ISecurityRepository {
    private static instance: SecurityRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): SecurityRepository {
        if (!SecurityRepository.instance) {
            // Get from local storage
            SecurityRepository.instance = new SecurityRepository();
        }

        return SecurityRepository.instance;
    }

    getBlockingStatus() {
        return this.request({
            url: `Security/GetBlockingStatus`,
            method: 'get',
        });
    }
}
