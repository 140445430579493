import SettingRepository from '@/application/repositories/SettingRepository';
import TaskRepository from '@/application/repositories/TaskRepository';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { IOrganizationSettingPayload } from '../types/organization/org-setting.types';
export default class SettingService {
    private static instance: SettingService;

    constructor() {}

    public static getInstance(): SettingService {
        if (!SettingService.instance) {
            // Get from local storage
            SettingService.instance = new SettingService();
        }

        return SettingService.instance;
    }

    getDataConfig(): Promise<any> {
        return TaskRepository.getInstance().getDataConfig();
    }
    updateTaskConfig(data): Promise<any> {
        const language = getLocalStorage(StorageConstant.LANGUAGE);

        return TaskRepository.getInstance().updateTaskConfig({
            ...data,
            name: data.text,
            language: language ?? 'en',
        });
    }
    async getOrganizationSettings(): Promise<any> {
        const res =
            await SettingRepository.getInstance().getOrganizationSettings();
        return res['result'];
    }

    async getAllTimezoneSettings(): Promise<any> {
        return await SettingRepository.getInstance().getAllTimezoneSettings();
    }

    async updateOrganizationSetting(
        data: IOrganizationSettingPayload
    ): Promise<any> {
        return await SettingRepository.getInstance().updateOrganizationSetting(
            data
        );
    }

    async updateOrganizationDepartmentSettings(data): Promise<any> {
        return await SettingRepository.getInstance().updateOrganizationDepartmentSettings(
            data
        );
    }
    async updateOrganizationSettingsByModule(data): Promise<any> {
        return await SettingRepository.getInstance().updateOrganizationSettingsByModule(
            data
        );
    }
    getSystemConfig(): Promise<any> {
        return SettingRepository.getInstance().getSystemConfig();
    }
    setSystemConfig(data): Promise<any> {
        return SettingRepository.getInstance().setSystemConfig(data);
    }
    getTictopConfig(): Promise<any> {
        return SettingRepository.getInstance().getTictopConfig();
    }
}
