<script setup lang="ts">
import { ref } from 'vue';
import UserById from '@/ui/components/user/UserById.vue';
import TaskCommentInput from '@/ui/common/plugins/ckeditor/TaskCommentInput.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import AttachmentClass from '@/application/services/attachment/AttachmentClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import NoteClass from '@/application/services/attachment/NoteClass';

interface ICommentAttachmentInput {
  //files
  addedFiles: any[];
  removedFiles: any[];
  //notes
  addedNotes: any[];
  editedNotes: any[];
  removedNotes: any[];
}

const emits = defineEmits<{
  (
    e: 'onCreateComment',
    messageData: {
      content: string;
      attachments: {
        files: any[];
        notes: any[];
      };
      mentionUserIds: any[];
      mentionGroup: any;
    }
  ): void;
  (e: 'update:modelValue', value: any): void;
}>();
const commentTaskInputRef = ref<any>();
const commentObjectCreate = ref({
  content: '',
  attachments: {
    files: [],
    notes: [],
  },
  mentionUserIds: [],
  mentionGroup: false,
});

const isLoading = ref<boolean>(false);
const resetData = () => {
  commentObjectCreate.value = {
    content: '',
    attachments: {
      files: [],
      notes: [],
    },
    mentionUserIds: [],
    mentionGroup: false,
  };
};
const onUpdateContentValue = (content) => {
  // console.log('----onUpdateContentValue', content);
  commentObjectCreate.value.content = content;
};

const refreshCommentCreate = () => {
  commentObjectCreate.value.content = '';
  commentObjectCreate.value.attachments.notes = [];
  commentObjectCreate.value.attachments.files = [];
  commentObjectCreate.value.mentionUserIds = [];
  commentObjectCreate.value.mentionGroup = false;
};

const onUploadFiles = async (attachmentFiles, uploadNotes) => {
  //1. upload to aws
  // const uploadFiles = await uploadTaskAttachments(files);
  const promiseAll: Promise<any>[] = [];
  const promiseNoteAll: Promise<any>[] = [];
  Array.from(attachmentFiles).forEach((file) => {
    promiseAll.push(
      new AttachmentClass(ModuleSourceFileEnum.task, file).upload()
    );
  });
  Array.from(
    uploadNotes?.map((note) => {
      return { ...note, id: '00000000-0000-0000-0000-000000000000' };
    })
  ).forEach((file) => {
    // console.log('🚀 Hyrin ~ TaskServiceSingleton ~ ).forEach ~ file', file);
    promiseNoteAll.push(
      new NoteClass(ModuleSourceFileEnum.task, file).upload()
    );
  });
  const files = await Promise.all(promiseAll);
  const notes = await Promise.all(promiseNoteAll);
  return {
    files,
    notes,
  };
};

const onCreateComment = async (attachments: ICommentAttachmentInput) => {
  isLoading.value = true;
  // console.log(' mentionUserIds mentionGroup', commentObjectCreate.value?.mentionUserIds, commentObjectCreate.value?.mentionGroup);

  if (commentObjectCreate.value?.mentionUserIds?.length > 0)
    commentObjectCreate.value.mentionGroup = false;
  const uploadedAttachments: { files: any; notes: any } = await onUploadFiles(
    attachments?.addedFiles,
    attachments?.addedNotes
  );

  emits('onCreateComment', {
    content: commentObjectCreate.value.content,
    attachments: {
      files: uploadedAttachments?.files,
      notes: uploadedAttachments?.notes,
    },
    mentionUserIds: commentObjectCreate.value.mentionUserIds,
    mentionGroup: commentObjectCreate.value.mentionGroup,
  });
  refreshCommentCreate();
  isLoading.value = false;

  commentTaskInputRef.value?.resetForm();
};

const currentId = getCurrentUserId();

const onOpenInputComment = () => {
  // _taskDetailStore.setNavigatePoint('other', 'commentTaskInputRefId');
};

const onCloseInputComment = () => {
  resetData();
  commentTaskInputRef.value?.resetForm();
};
const focusOnInput = () => {
  if (commentTaskInputRef.value?.focusToInput)
    commentTaskInputRef.value?.focusToInput();
};

defineExpose({
  focusOnInput,
});
</script>

<template>
  <div class="flex w-full h-full items-start">
    <!--  CREATOR  -->
    <div class="w-8 h-8 pt-0.5 mr-3">
      <UserById :user-id="currentId" is-hidden-name avatar-class="w-8 h-8" />
    </div>
    <TaskCommentInput
      ref="commentTaskInputRef"
      v-model="commentObjectCreate.content"
      v-model:mentionUserIds="commentObjectCreate.mentionUserIds"
      v-model:mentionGroup="commentObjectCreate.mentionGroup"
      v-model:attachments="commentObjectCreate.attachments"
      :notes="commentObjectCreate?.attachments?.notes"
      :files="commentObjectCreate?.attachments?.files"
      edit-mode="CREATE"
      is-auto-focus
      :is-loading-send="isLoading"
      @update:model-value="onUpdateContentValue"
      @on-save="onCreateComment"
      @on-open="onOpenInputComment"
      @on-close="onCloseInputComment"
      @on-focus="onOpenInputComment"
    />
  </div>
</template>
