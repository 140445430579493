export const PermissionNameString = {
    geolocation: 'geolocation',
    notifications: 'notifications',
    push: 'push',
    midi: 'midi',
    camera: 'camera',
    microphone: 'microphone',
    speakerSelection: 'speaker-selection',
    deviceInfo: 'device-info',
    backgroundFetch: 'background-fetch',
    backgroundSync: 'background-sync',
    bluetooth: 'bluetooth',
    persistentStorage: 'persistent-storage',
    ambientLightSensor: 'ambient-light-sensor',
    accelerometer: 'accelerometer',
    gyroscope: 'gyroscope',
    magnetometer: 'magnetometer',
    clipboardRead: 'clipboard-read',
    clipboardWrite: 'clipboard-write',
    displayCapture: 'display-capture',
    nfc: 'nfc',
};

export enum PermissionState {
    granted = 'granted',
    denied = 'denied',
    prompt = 'prompt',
}

export const checkNavigatorPermission = (name) => {
    return navigator.permissions.query({ name: name });
};
