<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { TaskUrgency } from '@/ui/hooks/taskHook';
import { CalendarType } from '@/ui/common/constants/calendar';
import {
  cleanString,
  colorByAlphabet,
  convertViToEn,
} from '@/ui/common/molecules/SynAvatar/colorByAlphabet';

import datetimeComposable from '@/ui/composables/common/datetime-composable';
import { EventDetailItemClass } from '@/domain/entities/calendars/CalendarsClass';
import { getComponent } from '@/ui/components/calendars/event-item/render-component';

dayjs.extend(utc);
dayjs.extend(tz);

const props = withDefaults(
  defineProps<{
    event: EventDetailItemClass;
    options?: any;
  }>(),
  {}
);

const { formatDate } = datetimeComposable();

const nameIcon = computed(() =>
  getComponent(
    props.event?.type,
    props.event?.urgency,
    props.event?.meetingType,
    props.event?.sourceId
  )
);

const textAva = computed(() => {
  if (!props?.event?.nameUser) return 'A';
  return cleanString(props.event?.nameUser)
    .split(' ')
    [cleanString(props.event?.nameUser).split(' ').length - 1].charAt(0);
});

const convertedTime = computed<any>(() => {
  // if (dayjs(props.event.startDate).isSame(dayjs(props.event.endDate))) {
  return formatDate(props.event?.startDate, 'time', {
    isIgnoreTimezone: true,
  });
  // }
  // return `${formatDate(props.event.startDate, 'time', {
  //   isIgnoreTimezone: true,
  // })} - ${formatDate(props.event.endDate, 'time', {
  //   isIgnoreTimezone: true,
  // })}`;
});

// const eventItemColor = {
//   reminder: 'green',
//   meeting: 'purple',
//   system: 'indigo',
// };
</script>

<template>
  <div
    v-if="event"
    :title="event.titleEvent"
    class="w-full min-h-full cursor-pointer rounded"
    :class="[
      {
        'bg-green-50': event.type == CalendarType.Reminder,
      },
      {
        'bg-purple-50': event.type == CalendarType.Meeting,
      },
      {
        'bg-red-200':
          event.type == CalendarType.Task &&
          event.urgency == TaskUrgency.UrgentImportant.key,
      },
      {
        'bg-blue-200':
          event.type == CalendarType.Task &&
          event.urgency !== TaskUrgency.UrgentImportant.key,
      },
      {
        'bg-cyan-100': event.system,
      },
    ]"
  >
    <div class="flex justify-between items-center space-x-1 px-1.5 py-2">
      <!-- UGENTCY -->
      <div class="flex items-center space-x-2 overflow-hidden">
        <template v-if="!event.system">
          <!-- ICON EVENT -->
          <div
            v-if="event.type !== CalendarType.Task"
            class="w-5 h-5 flex-center shadow-sm rounded-full"
            :class="[
              {
                'bg-green-200': event.type == CalendarType.Reminder,
              },
              {
                'bg-purple-200': event.type == CalendarType.Meeting,
              },
              {
                'bg-red-200':
                  event.type == CalendarType.Task &&
                  event.urgency == TaskUrgency.UrgentImportant.key,
              },
              {
                'bg-blue-200':
                  event.type == CalendarType.Task &&
                  event.urgency !== TaskUrgency.UrgentImportant.key,
              },
              {
                'bg-cyan-200': event.system,
              },
            ]"
          >
            <component
              :is="nameIcon"
              :is-active="true"
              class="w-3 h-3"
              :class="
                event.type == CalendarType.Reminder
                  ? 'text-green-500 fill-green-500'
                  : 'text-purple-500 fill-purple-500'
              "
            >
            </component>
          </div>

          <!-- ICON TASK -->
          <div
            v-if="
              (event.type == CalendarType.Task && event.urgency !== 0) ||
              (event.type == CalendarType.Task && event.urgency !== 1)
            "
            class="w-4 h-4 flex-center"
          >
            <component
              :is="nameIcon"
              :is-active="true"
              class="inline-block w-3 h-3 fill-red-500"
            >
            </component>
          </div>
        </template>

        <div
          v-if="event.system"
          class="w-5 h-5 flex-center bg-cyan-200 rounded-full shadow-sm"
        >
          <component
            :is="nameIcon"
            :is-active="true"
            class="w-3 h-3 fill-cyan-500"
          >
          </component>
        </div>

        <!-- TIME, TITLE, DESCRIPTION -->
        <div v-if="!options?.isShowIconOnly" class="flex-1 overflow-hidden">
          <div class="truncate text-black flex items-baseline">
            <span
              v-if="!event.allDay"
              class="pr-2 italic text-xs text-gray-500"
            >
              {{ convertedTime }}
            </span>
            <span
              class="font-medium truncate text-xs"
              :class="[
                {
                  'text-green-500': event.type == CalendarType.Reminder,
                },
                {
                  'text-purple-500': event.type == CalendarType.Meeting,
                },
                {
                  'text-red-500':
                    event.type == CalendarType.Task &&
                    event.urgency == TaskUrgency.UrgentImportant.key,
                },
                {
                  'text-blue-500':
                    event.type == CalendarType.Task &&
                    event.urgency !== TaskUrgency.UrgentImportant.key,
                },
                {
                  'text-cyan-500': event.system,
                },
              ]"
            >
              {{ event.titleEvent }}
            </span>
          </div>

          <!-- DESCRIPTION -->
          <div
            v-if="options?.isShowDescription && event?.description"
            class="truncate text-black text-xs"
            v-html="event.description"
          ></div>
        </div>
      </div>

      <!-- TASK -->
      <div v-if="event.type == CalendarType.Task" class="pr-1">
        <div
          v-if="event.srcAvatar"
          class="rounded-full overflow-hidden w-5 h-5"
        >
          <img
            class="w-full h-full object-cover shadow"
            :src="event.srcAvatar"
          />
        </div>
        <div
          v-else
          class="
            object-cover
            flex-center
            text-white
            shadow
            font-thin
            rounded-full
            w-5
            h-5
          "
          :style="`background: ${
            colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
          }`"
        >
          <span
            v-if="event.nameUser"
            class="uppercase"
            style="font-size: 0.75em"
            >{{ textAva }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
