<script setup lang="ts">
import { onMounted, ref } from 'vue';
import whiteboardStore from '@/store/whiteboard';

const props = defineProps<{
  whiteboardId: string;
  whiteboardTitle: string;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'updateTitleSuccess', newWhiteboard): void;
}>();
const whiteboardTitlePayload = ref<string>(props.whiteboardTitle);

const onSave = async () => {
  await whiteboardStore().updateWhiteboardTitle(
    props.whiteboardId,
    whiteboardTitlePayload.value
  );
  emit('updateTitleSuccess', whiteboardTitlePayload.value);
};

const inputRef = ref<any>(null);
onMounted(() => {
  inputRef.value?.focus();
});
</script>

<template>
  <SynModal
    disable-click-outside
    style-body="p-6 flex flex-col space-y-2 "
    z-index="z-40"
    :container-class="'w-3/12 min-h-max'"
    :container-style="`min-width: 32rem`"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    @cancel="$emit('cancel')"
    @confirm="onSave"
  >
    <template #header>
      <div class="w-full flex">
        <span>Update whiteboard title</span>
      </div>
    </template>
    <template #body>
      <!-- TITLE -->
      <div class="flex flex-col">
        <input
          ref="inputRef"
          v-model="whiteboardTitlePayload"
          class="
            bg-white
            w-full
            h-full
            focus:outline-none
            pb-2
            border-b-2
            focus:border-b-2 focus:border-current
            placeholder-gray-500 placeholder-opacity-75
            text-xl
            border-gray-200
          "
          :placeholder="$t('CALENDAR_LABEL_INPUTVALUE_TITLE')"
          required
        />
      </div>
    </template>
  </SynModal>
</template>
