export default {
    install: (app) => {
        app.directive('drag-to-scroll', {
            mounted(el, binding) {
                el.style.overflow = 'auto';

                const collections = Array.from(
                    document.getElementsByClassName(binding.value?.targetClass)
                );

                let pos = { top: 0, left: 0, x: 0, y: 0 };

                const mouseDownHandler = function (e) {
                    pos = {
                        // The current scroll
                        left: el.scrollLeft,
                        top: el.scrollTop,
                        // Get the current mouse position
                        x: e.clientX,
                        y: e.clientY,
                    };
                    el.style.cursor = 'grabbing';

                    document.addEventListener('mousemove', mouseMoveHandler);
                    document.addEventListener('mouseup', mouseUpHandler);
                };
                const mouseMoveHandler = function (e) {
                    // How far the mouse has been moved
                    const dx = e.clientX - pos.x;
                    const dy = e.clientY - pos.y;

                    // Scroll the element
                    el.scrollTop = pos.top - dy;
                    el.scrollLeft = pos.left - dx;
                };
                const mouseUpHandler = function () {
                    document.removeEventListener('mousemove', mouseMoveHandler);
                    document.removeEventListener('mouseup', mouseUpHandler);

                    el.style.cursor = 'default';
                };

                collections.forEach((element: any) => {
                    element.addEventListener('mousedown', mouseDownHandler);
                    element.style.cursor = 'grab';
                });
            },
        });
    },
};
