import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import taskStore from '@/store/task';
import TaskService from '@/application/services/task/TaskService';
import { ITaskTodo, ITaskTodoList } from '@/application/types/task/task.types';
import { ITaskReminder } from '@/application/interfaces/tasks/task-interfaces';

const partIdElementDetail = {
    comment: 'detail-task-comment-',
};

export default defineStore({
    id: storeId.taskDetail,
    state: () => ({
        _taskStore: taskStore(),
        _taskService: TaskService.getInstance(),
        navigatePoint: {
            type: '',
            id: '',
            elmId: '',
            isScroll: false,
            isScrollBottom: false,
        } as {
            type: string;
            id: string;
            elmId: string;
            isScroll: boolean;
            isScrollBottom: boolean;
        },
        cacheStateById: {},
        updatedTaskWorkflowId: '',
        taskReminder: {} as ITaskReminder,
        highlightCommentIds: [] as string[],
    }),
    getters: {},
    actions: {
        setNavigatePoint(type, id) {
            this.navigatePoint.type = type;
            switch (type) {
                case 'comment':
                    this.navigatePoint.id = id;
                    this.navigatePoint.elmId = `${partIdElementDetail.comment}${id}`;
                    this.navigatePoint.isScrollBottom = false;
                    break;
                default:
                    this.navigatePoint.id = id;
                    this.navigatePoint.isScrollBottom = true;
                    break;
            }

            // setTimeout(() => {
            //     const el = document.getElementById(
            //         this.navigatePoint.elmId
            //     ) as any;
            //     const container = document.getElementById(
            //         'tagOptionActivity'
            //     ) as any;
            //     if (!el) return;
            //     this.navigatePoint.isScroll = true;
            //     if (this.navigatePoint.isScrollBottom) {
            //         container.scrollTop = container.scrollHeight;
            //     } else {
            //         el.scrollIntoView({
            //             behavior: 'smooth',
            //             block: 'center',
            //         });
            //     }
            // }, 500);

            // setTimeout(() => {
            //     this.navigatePoint.type = '';
            //     this.navigatePoint.id = '';
            //     this.navigatePoint.isScroll = false;
            //     this.navigatePoint.isScrollBottom = false;
            // }, 2000);
        },
        updateNavigatePoint(data) {
            this.navigatePoint = {
                ...this.navigatePoint,
                ...data,
            };
        },

        async onChangedTaskDetail(changeMethod: Promise<boolean>) {
            try {
                const success = await changeMethod;
                if (!success) return Promise.resolve(false);
                Promise.resolve(true);
            } catch (error) {
                Promise.reject(error);
            }
        },

        getDetailByCode(taskCode) {
            return this._taskService.getDetailByCode(taskCode);
        },

        setCacheState(
            taskId: any,
            data: {
                description?: {
                    isEdit: boolean;
                    draft: string;
                    attachments: any[];
                };
                draftTodoList?: ITaskTodo[];
            }
        ) {
            if (!taskId) return;
            this.cacheStateById = {
                ...this.cacheStateById,
                [taskId]: {
                    ...this.cacheStateById[taskId],
                    ...data,
                },
            };
        },

        //todo list
        getTaskCheckListByTaskId(taskId, orgId?: number) {
            return this._taskService.getTaskCheckListByTaskId(taskId, orgId);
        },
        createOrUpdateTaskCheckList(data: ITaskTodoList, orgId?: number) {
            return this._taskService.createOrUpdateTaskCheckList(data, orgId);
        },
        removeTaskCheckList(
            data: { taskId: number; taskTodoIds: string[] },
            orgId?: number
        ) {
            return this._taskService.removeTaskCheckList(data, orgId);
        },
        removeItemsInTaskCheckList(
            data: {
                taskId: number;
                taskTodoId: string;
                todoItemIds: string[];
            },
            orgId?: number
        ) {
            return this._taskService.removeItemsInTaskCheckList(data, orgId);
        },

        async getReminderByTaskId(taskId) {
            if (!taskId) return;
            const res = await TaskService.getInstance().getReminderByTaskId(
                taskId
            );
            this.taskReminder = res?.result;
        },
        updateHighlightCommentIds(idList) {
            this.highlightCommentIds = [...idList];
        },
    },
});
