<script setup lang="ts">
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import { computed, ref } from 'vue';
import settingStore from '@/store/setting';
import dayjs from 'dayjs';
import organizationStore from '@/store/organization';
import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';

const props = defineProps<{
  scheduleTime: any;
  myTimezone?: string;
  scheduleByHour?: boolean;
  scheduleTimezone?: string;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onChange', data: { dateTime: any; timezone: string }): void;
  (e: 'onChangeTimezone', newValue): void;
  (e: 'onRemoveTime', data: { dateTime: any }): void;
}>();
const changeDeadlineTime = ({ dateTime, timezone }) => {
  emit('onChange', { dateTime, timezone });
};
const onRemoveTime = () => {
  emit('onRemoveTime', {
    dateTime: dayjs(props.scheduleTime)
      .tz(props.scheduleTimezone || props.myTimezone)
      .hour(0)
      .minute(0)
      .utc(true)
      .format(),
  });
};

const atomTimeInputRef = ref<any>(null);
const onOpenAddTime = () => {
  if (
    atomTimeInputRef.value &&
    typeof atomTimeInputRef.value?.openTimeInput == 'function'
  )
    atomTimeInputRef.value?.openTimeInput();
};

const timezoneByKey = computed(() => {
  return settingStore().timezoneList?.reduce((currentObj, currentMember) => {
    return {
      ...currentObj,
      [currentMember?.timezone]: currentMember,
    };
  }, []);
});
const timezoneList = computed<{ text: string; timezone: string }[]>(() => {
  const lang = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';

  // const convertLang = lang == 'vi' ? 'vn' : lang == 'fr' ? 'fr' : 'us';
  return organizationStore().allTimezones?.map((o) => {
    return {
      timezone: o,
      text: `(UTC ${dayjs().tz(o).format('Z')}) ${
        timezoneByKey.value[o]?.countryName[lang] || ''
      }`,
    };
  });
});

const currentDateTime = computed<any>(() => {
  const myTimezone = dayjs.tz.guess();

  return props.scheduleTime
    ? dayjs
        .utc(props.scheduleTime)
        .tz(props.scheduleTimezone || myTimezone || 'Asia/Ho_Chi_Minh')
    : '';
});

const isDestroyed = ref<boolean>(false);
const onDestroy = () => {
  isDestroyed.value = true;
};
</script>
<template>
  <div
    v-if="scheduleTime && !isDestroyed"
    v-permission-function="{
      functionCode: ALL_FUNCTIONS.TASK.DEADLINE_ADD_TIME,
      neverHidden: scheduleByHour,
      onContinue: () => {
        onOpenAddTime();
      },
    }"
    v-system-config="[
      {
        functionCode: 'ADVANCED_TIME_ADV_DEADLINE_TIME_ALLOWED',
        functionValue: '1',
        neverHidden: scheduleByHour,
        onDestroy: () => {
          onDestroy();
        },
      },
      {
        functionCode: 'ADVANCED_TIME_ADV_DEADLINE_TIME_ALLOWED_SYSTEM',
        functionValue: '1',
        neverHidden: scheduleByHour,
        onDestroy: () => {
          onDestroy();
        },
      },
    ]"
  >
    <template v-if="readonly">
      <SynLabelDateTime2
        v-if="scheduleByHour"
        format="time"
        :datetime="currentDateTime"
        :timezone="scheduleTimezone || myTimezone || 'Asia/Ho_Chi_Minh'"
      />
    </template>
    <AtomTimeInput
      v-else
      v-bind="$attrs"
      ref="atomTimeInputRef"
      :has-time="scheduleByHour"
      :datetime="scheduleTime"
      :timezone="scheduleTimezone || myTimezone || 'Asia/Ho_Chi_Minh'"
      :options="{
        timezoneList: timezoneList,
      }"
      class="h-6"
      @on-change="changeDeadlineTime"
      @remove-time="onRemoveTime"
    />
  </div>
</template>
