<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <circle
        cx="256"
        cy="256"
        r="256"
        fill="#f0f0f0"
        opacity="1"
        data-original="#f0f0f0"
        class=""
      ></circle>
      <path
        fill="#d80027"
        d="M512 256c0-110.071-69.472-203.906-166.957-240.077v480.155C442.528 459.906 512 366.071 512 256z"
        opacity="1"
        data-original="#d80027"
      ></path>
      <path
        fill="#0052b4"
        d="M0 256c0 110.071 69.473 203.906 166.957 240.077V15.923C69.473 52.094 0 145.929 0 256z"
        opacity="1"
        data-original="#0052b4"
        class=""
      ></path>
    </g>
  </svg>
</template>
