<template>
  <vig-dropdown z-index="60" placement="right">
    <template #dropdown-toggle>
      <vig-button type="button" ghost color="blue" padding="p-1">
        <SynIcon name="Edit" />
      </vig-button>
    </template>
    <template #dropdown-menu>
      <div
        class="w-72 p-1 space-y-1 overflow-y-scroll small-scrollbar"
        style="max-height: 70vh"
      >
        <vig-button
          :ghost="groupId"
          :light="!groupId"
          :color="!groupId ? 'current' : 'gray'"
          padding="px-4 py-1"
          class="w-full justify-start dropdown-item"
          @click="$emit('onLocationClick', null)"
        >
          <SynAvatar
            :src="myProfile?.avatarThumbnailUrl || myProfile?.avatarUrl"
            :name="myProfile?.name"
            class="mr-3"
          />
          <span>{{ $t('GED_MY_STORAGE') }}</span>
        </vig-button>
        <hr />
        <vig-button
          v-for="group in allMyGroups"
          :key="group"
          :ghost="group?.id !== groupId"
          :light="group?.id === groupId"
          :color="group?.id === groupId ? 'current' : 'gray'"
          padding="px-4 py-1"
          class="w-full py-1 justify-start dropdown-item"
          @click="$emit('onLocationClick', group?.id)"
        >
          <SynAvatar :src="group?.avatar" :name="group?.name" class="mr-3" />
          <span class="block truncate">{{ group?.name }}</span>
        </vig-button>
      </div>
    </template>
  </vig-dropdown>
</template>

<script>
import { defineComponent, computed } from 'vue';
import groupStore from '@/store/group';
import myProfileStore from '@/store/auth/my-profile';

export default defineComponent({
  props: {
    groupId: {
      type: Number,
      default: null,
    },
  },
  emits: ['onLocationClick'],
  setup() {
    const _groupStore = groupStore();

    const myProfile = computed(() => myProfileStore().myProfile);
    const allMyGroups = computed(() =>
      _groupStore.allMyGroups?.filter((group) => group?.isMember)
    );

    return {
      myProfile,
      allMyGroups,
    };
  },
});
</script>
