import {
    SendPasswordResetCodeInput,
    LoginInput,
    ValidateResetPasswordInput,
    ResetPasswordInput,
} from '@/domain/entities/PayloadClass/AuthPayloadClass';

import BaseRequest from '@/base/api/base-request';
import { AxiosInstance } from 'axios';

export default class AuthRepository {
    private static instance: AuthRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }
    public static getInstance(): AuthRepository {
        if (!AuthRepository.instance) {
            // Get from local storage
            AuthRepository.instance = new AuthRepository();
        }

        return AuthRepository.instance;
    }

    async selectOrganization(data): Promise<any> {
        return this.request({
            url: `selectOrganization`,
            method: 'post',
            data,
        });
    }

    forgotPassword(payload: SendPasswordResetCodeInput): Promise<any> {
        return this.request({
            url: `Account/ForgotPassword`,
            method: 'post',
            data: payload,
        });
    }
    async validateResetPasswordCode(
        payload: ValidateResetPasswordInput
    ): Promise<any> {
        return this.request({
            url: `Account/ValidateResetPasswordCode`,
            method: 'post',
            data: payload,
        });
    }
    resetPassword(payload: ResetPasswordInput): Promise<any> {
        return this.request({
            url: `Account/ResetPassword`,
            method: 'post',
            data: payload,
        });
    }
    loginWithPassword(payload: LoginInput): Promise<any> {
        return this.request({
            url: `authenticate`,
            method: 'post',
            data: payload,
        });
    }

    getCurrentLoginInformations() {
        return this.request({
            url: `Session/GetCurrentLoginInformations`,
            method: 'get',
        });
    }

    checkPhone(data: string): Promise<any> {
        return this.request({
            url: `Catalog/CheckPhone?phone=${encodeURIComponent(data)}`,
            method: 'get',
            data,
        });
    }
    checkEmail(data: string): Promise<any> {
        return this.request({
            url: `Catalog/CheckEmail?email=${data}`,
            method: 'get',
            data,
        });
    }
    saveUserProfile(data: any): Promise<any> {
        return this.request({
            url: 'Catalog/SaveUserProfile',
            method: 'post',
            data,
        });
    }
    verifyUserPhoneNumber(data: any): Promise<any> {
        return this.request({
            url: 'Verification/VerifyUserPhoneNumber',
            method: 'post',
            data,
        });
    }
    reSendVerificationCodeByPhoneNumber(data: any): Promise<any> {
        return this.request({
            url: 'Verification/ReSendVerificationCodeByPhoneNumber',
            method: 'post',
            data,
        });
    }
    sendVerificationCodeByPhoneNumber(data: any): Promise<any> {
        return this.request({
            url: 'Verification/SendVerificationCodeByPhoneNumber',
            method: 'post',
            data,
        });
    }
    loginByPhoneCode(data: any): Promise<any> {
        return this.request({
            url: 'Verification/LoginByPhoneCode',
            method: 'post',
            data,
        });
    }
    verifyUserEmail(data: any): Promise<any> {
        return this.request({
            url: 'Verification/VerifyUserEmail',
            method: 'post',
            data,
        });
    }
    sendVerificationCodeByEmail(data: any): Promise<any> {
        return this.request({
            url: 'Verification/SendVerificationCodeByEmail',
            method: 'post',
            data,
        });
    }
    reSendVerificationCodeByEmail(data: any): Promise<any> {
        return this.request({
            url: 'Verification/ReSendVerificationCodeByEmail',
            method: 'post',
            data,
        });
    }
    checkUserHasPasswordByEmail(data: string): Promise<any> {
        return this.request({
            url: `Catalog/CheckUserHasPasswordByEmail?email=${encodeURIComponent(
                data
            )}`,
            method: 'get',
            data,
        });
    }
    checkUserHasPasswordByPhone(data: string): Promise<any> {
        return this.request({
            url: `Catalog/CheckUserHasPasswordByPhone?phone=${encodeURIComponent(
                data
            )}`,
            method: 'get',
            data,
        });
    }
    loginByEmailCode(data: any): Promise<any> {
        return this.request({
            url: 'Verification/LoginByEmailCode',
            method: 'post',
            data,
        });
    }
    submitVerificationUserEmailToken(data: any): Promise<any> {
        return this.request({
            url: 'Verification/VerifyCurrentUserEmail',
            method: 'post',
            data,
        });
    }
    submitVerificationEmailUserTokenForForgotPassword(data: any): Promise<any> {
        return this.request({
            url: 'Account/ValidateResetPasswordCode',
            method: 'post',
            data,
        });
    }
    refresh(data: any): Promise<any> {
        return this.request({
            url: 'refresh',
            method: 'post',
            data,
        });
    }
    async logout(data: any): Promise<any> {
        return this.request({
            url: 'logout',
            method: 'post',
            data,
        });
    }
    getUserOrganizationName(loginId: any): Promise<any> {
        return this.request({
            url: `Catalog/GetUserOrganizationName?id=${encodeURIComponent(
                loginId
            )}`,
            method: 'get',
        });
    }
    getCurrentSubscriptionProcess(): Promise<any> {
        return this.request({
            url: `Catalog/GetCurrentSubscriptionProcess`,
            method: 'get',
        });
    }
    getCurrentSubscriptionProcess2(): Promise<any> {
        return this.request({
            url: `Catalog/GetCurrentSubscriptionProcess2`,
            method: 'get',
        });
    }
}
