import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import SystemsInfoModel from '@/ui/plugins/firebases/realtime-database/model/SystemsInfoModel';
import { systemKey, stateDeployments } from '@/ui/common/constants/system';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import { limitToLast, onValue, orderByKey, query } from 'firebase/database';
import { StorageConstant } from '@/ui/hooks/storageHook';
import { IIpInformation } from '@/application/types/common/common.types';

type SystemState = {
    deploymentInfo: any;
    keyDeploymentActive: string;
    stateDeploymentActive: number;
    isLoading: boolean;
    ipInformation: IIpInformation;
    lastTimeReload: any;
    lastNewDeployment: any;
    isShowAnnouncementsNewDeployment: boolean;
    isNewlyDeployed: boolean;
};

let fbSystemsInfoClass: SystemsInfoModel;
let fbAnnouncementsSystemsDeploy: SystemsInfoModel;
let unscriblleChangeAnnouncement;
let unscriblleChildAdded;
let unscriblleChildChange;
let fullpathbefore = '' as string;

export default defineStore({
    id: storeId.system,
    state: () =>
        ({
            deploymentInfo: {},
            keyDeploymentActive: '',
            stateDeploymentActive: 0,
            ipInformation: {},
            isLoading: false,
            lastTimeReload: new Date().getTime(),
            lastNewDeployment: 0,
            isShowAnnouncementsNewDeployment: false,
            isNewlyDeployed: false,
        } as SystemState),
    getters: {
        getStateDeployment(state): number {
            return state.stateDeploymentActive;
        },
    },
    actions: {
        async initData(router) {
            this.isLoading = true;
            this.lastTimeReload = new Date().getTime();
            // console.log('lastTimeReload', this.lastTimeReload, this.isNewlyDeployed);

            if (
                JSON.parse(
                    localStorage.getItem(StorageConstant.NEW_DEPLOY) || 'false'
                ) &&
                !this.isNewlyDeployed
            ) {
                // console.log('update false');
                localStorage.setItem(StorageConstant.NEW_DEPLOY, 'false');
            }

            await Promise.all([
                this.getCurrentDeploymentState(router),
                this.getLocationViaIP(),
            ]);

            this.isLoading = false;
        },
        async getLocationViaIP() {
            try {
                const locationRes =
                    await UnAuthenticateService.getInstance().getIpInfo();
                this.ipInformation = locationRes.result;
                // const locationData = locationRes.result;
                // const isFrench =
                //     locationData.country === 'FR' ||
                //     locationData.countryCode === 'FR';

                // const defaultPath =
                //     import.meta.env.VITE_DOMAIN_DEFAULT?.split('://').pop();
                // const isDefaultDomain = window.location.href.includes(
                //     defaultPath || 'tictop.app'
                // );
                // if (isFrench && isDefaultDomain) {
                //     location.href = location.href.replace(
                //         'tictop.app',
                //         'app.tictop.eu'
                //     );
                // }
            } catch (error) {
                console.log('🚀 ~ getLocationViaIP ~ error', error);
            }
        },
        setDeploymentInfo(info) {
            this.deploymentInfo = info;
        },

        setKeyDeploymentActive(key) {
            this.keyDeploymentActive = key;
        },

        setStateDeploymentActive(state) {
            this.stateDeploymentActive = state;
        },

        getCurrentDeploymentState(router) {
            fbSystemsInfoClass = new SystemsInfoModel(systemKey?.DEPLOYMENTS);
            const q = query(
                fbSystemsInfoClass.dataRef,
                orderByKey(),
                limitToLast(1)
            ).ref;
            onValue(
                q.ref,
                (snapshot) => {
                    this.keyDeploymentActive = Math.max(
                        ...Object.keys(snapshot.val())?.map((el) =>
                            parseInt(el)
                        )
                    )?.toString();
                    // this.keyDeploymentActive = Object.keys(snapshot.val())[0];
                    this.deploymentInfo =
                        snapshot.val()[this.keyDeploymentActive];
                    this.stateDeploymentActive = this.deploymentInfo?.state;

                    if (
                        this.stateDeploymentActive ==
                        stateDeployments.DEPLOYING.state
                    )
                        router.push('/system-deployment');
                },
                {
                    onlyOnce: true,
                }
            );
        },

        getSystemSettingDashboardGroups() {
            return localStorage.getItem(StorageConstant.DASHBOARD_GROUPS);
        },
        setSystemSettingDashboardGroups(value: string) {
            localStorage.setItem(StorageConstant.DASHBOARD_GROUPS, value);
        },

        getSystemSettingDashboardTeams() {
            return localStorage.getItem(StorageConstant.DASHBOARD_TEAMS);
        },
        setSystemSettingDashboardTeams(value: string) {
            localStorage.setItem(StorageConstant.DASHBOARD_TEAMS, value);
        },

        onListenStateAnnouncementDeploy() {
            if (fbAnnouncementsSystemsDeploy)
                this.offListenAnnouncementsSystemFirebase;

            fbAnnouncementsSystemsDeploy = new SystemsInfoModel(
                systemKey.ANNOUNCEMENTS
            );

            unscriblleChangeAnnouncement =
                fbAnnouncementsSystemsDeploy.onChange((data) => {
                    this.lastNewDeployment = data.newDeployment;
                    this.isShowAnnouncementsNewDeployment =
                        this.lastNewDeployment > this.lastTimeReload;
                });
        },

        updateDataFromFirebase(data, key) {
            this.deploymentInfo = data;
            this.keyDeploymentActive = key;
            this.stateDeploymentActive = data?.state;
        },
        onListenDeploymentSystemFirebase(router, route) {
            if (fbSystemsInfoClass) this.offListenDeploymentSystemFirebase();

            fbSystemsInfoClass = new SystemsInfoModel(systemKey?.DEPLOYMENTS);

            unscriblleChildAdded = fbSystemsInfoClass.onChildAdded(
                (data, key) => {
                    this.updateDataFromFirebase(data, key);
                }
            );

            unscriblleChildChange = fbSystemsInfoClass.onChildChanged(
                (data, key) => {
                    this.updateDataFromFirebase(data, key);
                    if (data.state !== stateDeployments.UPCOMING.state)
                        this.handleCheckRouterDeployment(
                            data,
                            key,
                            router,
                            route
                        );
                }
            );

            this.getCurrentDeploymentState(router);
        },

        offListenDeploymentSystemFirebase() {
            unscriblleChildAdded && unscriblleChildAdded();
            unscriblleChildChange && unscriblleChildChange();
        },

        offListenAnnouncementsSystemFirebase() {
            unscriblleChangeAnnouncement && unscriblleChangeAnnouncement();
        },

        async handleCheckRouterDeployment(data, key, router, route) {
            const currentState = data?.state;
            const lastState = data?.lastState;

            if (currentState == null || lastState == null) return;

            switch (currentState) {
                case stateDeployments.NORMAL.state:
                    if (lastState === stateDeployments.DEPLOYING.state) {
                        localStorage.setItem(
                            StorageConstant.NEW_DEPLOY,
                            'true'
                        );
                        this.isNewlyDeployed = true;
                        if (
                            fullpathbefore &&
                            fullpathbefore !== '/system-deployment'
                        ) {
                            router.push(fullpathbefore);
                            return;
                        }
                        history.replaceState({}, '', '/');
                        await window.location.reload();
                    }
                    break;
                case stateDeployments.DEPLOYING.state:
                    fullpathbefore = route?.fullPath;
                    router.push('/system-deployment');
                    break;
                default:
                    break;
            }
        },
    },
});
