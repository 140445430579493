<template>
  <svg
    id="Group_1833"
    data-name="Group 1833"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.996 22.037"
  >
    <g id="Glyph">
      <path
        id="Path_380"
        data-name="Path 380"
        d="M3.975,18a.988.988,0,0,0,.975-1V13A.975.975,0,1,0,3,13v4A.988.988,0,0,0,3.975,18Z"
        transform="translate(-1.506 -0.963)"
      />
      <ellipse
        id="Ellipse_178"
        data-name="Ellipse 178"
        cx="1.462"
        cy="1.5"
        rx="1.462"
        ry="1.5"
        transform="translate(1.007 19.037)"
      />
      <path
        id="Path_381"
        data-name="Path 381"
        d="M12.62,1A10.731,10.731,0,0,0,1.509,8.71a1.021,1.021,0,0,0,.162.9A.965.965,0,0,0,2.484,10H4.911A1.975,1.975,0,0,1,6.86,12v9.15a1,1,0,0,0,.556.91,10.491,10.491,0,0,0,10.358-.971,11.093,11.093,0,0,0,4.681-9.529A10.969,10.969,0,0,0,12.62,1Zm3.012,12h-3.9a.988.988,0,0,1-.975-1V6a.975.975,0,1,1,1.949,0v5h2.924a1,1,0,0,1,0,2Z"
        transform="translate(-1.467 -0.963)"
      />
    </g>
  </svg>
</template>
