<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import { NotificationSettingFunction } from '@/ui/common/constants/constant';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import dayjs from 'dayjs';

const props = defineProps({
  group: {
    type: Object,
    default: () => {},
  },
});

const _groupStore = groupStore();
const refId = computed(() => props.group?.id);
const refName = computed(() => props.group?.name);
const _notificationSettingStore = notificationSettingStore();

const isShowNotificationSetting = computed(() => notificationSetting.value?.id);
const notificationSetting = computed(() => {
  formatNotification(_notificationSettingStore?.notificationSetting);
  return _notificationSettingStore?.notificationSetting;
});

const notificationSettingFunc = ref('');
const notificationSettingMsg = ref('');
const isLoadEnabled = ref(false);

watch(
  () => props.group?.id,
  async () => {
    await init();
  }
);

const init = async () => {
  if (refId.value) {
    await _notificationSettingStore.getSettingNotification(
      refId.value,
      'GROUP'
    );
  }
  formatNotification(notificationSetting.value);
};

const onEnabled = async () => {
  isLoadEnabled.value = true;
  const enableResult =
    await _notificationSettingStore.enableSettingNotification(
      notificationSetting.value.id
    );
  if (enableResult) {
    _groupStore.setValueSettingGroup({}, props.group?.id);
    overviewGroupStore().updateNotificationByGroupId({}, props.group?.id);

    isLoadEnabled.value = false;
    openNotification({
      title: translate('COMMON_LABEL_SUCCESS'),
      body:
        translate('NOTIFICATION_SETTING_ENABLE_SUCCESS_LABEL', {
          key:
            notificationSetting.value.function !==
            NotificationSettingFunction.All
              ? translate(notificationSettingFunc.value)?.toLowerCase()
              : '',
          group: refName.value,
        }) || 'Turn on the notification of the group again',
    });
    await init();
  } else {
    openNotification({
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body:
        translate('NOTIFICATION_SETTING_ERROR_LABEL') ||
        'Notification settings failed',
    });
  }
};

const formatNotification = (notification) => {
  if (notification && notification?.id) {
    let unlimitedTimeOff = dayjs('3000-01-01T00:00:00+00:00');
    let timeOff = dayjs(notification?.offTime);
    let timeOffStr = 0;
    if (unlimitedTimeOff.diff(timeOff) === 0) {
      timeOffStr = 0;
    } else {
      let fomatOffTime = 'MMM DD, YYYY hh:mm A';
      if (unlimitedTimeOff.diff(timeOff, 'day') === 0) {
        fomatOffTime = 'hh:mm A';
      }
      timeOffStr = dayjs(notification?.offTime).format(fomatOffTime);
    }
    switch (notification?.function) {
      case NotificationSettingFunction.Messaging:
        notificationSettingFunc.value = 'NOTIFICATION_FUNC_LABEL_OPTION_1';
        break;
      case NotificationSettingFunction.Task:
        notificationSettingFunc.value = 'NOTIFICATION_FUNC_LABEL_OPTION_2';
        break;
      case NotificationSettingFunction.All:
        notificationSettingFunc.value = 'NOTIFICATION_FUNC_LABEL_OPTION_3';
        break;
      default:
        break;
    }
    if (timeOffStr !== 0) {
      if (notification?.function !== NotificationSettingFunction.All) {
        notificationSettingMsg.value = translate(
          'NOTIFICATION_SETTING_MESSAGE_ALERT_LABEL',
          {
            timeOff: `<span class='font-semibold text-current-600'>${timeOffStr}</span></br>`,
            key: translate(notificationSettingFunc.value)?.toLowerCase(),
          }
        );
      } else {
        notificationSettingMsg.value = translate(
          'NOTIFICATION_SETTING_MESSAGE_ALERT_LABEL_OPT_ALL',
          {
            timeOff: `<span class='font-semibold text-current-600'>${timeOffStr}</span></br>`,
          }
        );
      }
    } else {
      if (notification?.function !== NotificationSettingFunction.All) {
        notificationSettingMsg.value = translate(
          'NOTIFICATION_SETTING_MESSAGE_ALERT_LABEL_OPT_UNTIL_TURN_ON',
          {
            key: translate(notificationSettingFunc.value)?.toLowerCase(),
          }
        );
      } else {
        notificationSettingMsg.value = translate(
          'NOTIFICATION_SETTING_MESSAGE_ALERT_UNTIL_TURN_ON',
          {
            key: translate(notificationSettingFunc.value)?.toLowerCase(),
          }
        );
      }
    }
  } else {
    notificationSettingFunc.value = '';
  }
};

const isShowLess = ref(false);
</script>

<template>
  <div
    v-if="isShowNotificationSetting"
    class="bg-current-50 bg-opacity-40 shadow rounded-b-xl w-max py-1 px-1"
  >
    <div class="flex-center space-x-1">
      <div
        class="cursor-pointer hover:bg-current-100 rounded flex-center"
        :title="
          !isShowLess
            ? $t('TASK_CREATE_FORM_SHOW_LESS')
            : $t('TASK_CREATE_FORM_SHOW_MORE')
        "
        @click="isShowLess = !isShowLess"
      >
        <SynIcon
          :name="!isShowLess ? 'arrow-right' : 'arrow-left'"
          custom-class="fill-current text-current-500 w-5 h-5 p-1"
        />
      </div>

      <SynIcon name="bell-mute" class="fill-gray-500" />
      <span v-if="!isShowLess" class="text-xs text-gray-600 w-full">
        <span v-html="notificationSettingMsg"></span>
      </span>
      <div>
        <button
          class="
            focus:outline-none
            font-semibold
            py-1
            px-2
            text-xs
            space-x-2
            text-current-500
            hover:bg-current-100
            rounded
            flex-center
            min-w-max
            bg-current-50
          "
          @click="onEnabled"
        >
          <SynIcon
            v-if="isLoadEnabled"
            class="border-none animate-spin"
            custom-class="text-current-500 fill-current w-3 h-3"
            name="spinner"
          />
          <span>{{ $t('NOTIFICATION_TITLE_ENABLED') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
