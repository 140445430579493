<script setup lang="ts">
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import TaskBoardCardLoading from '@/ui/modules/task/board/task-card/TaskBoardCardLoading.vue';
import { onMounted, ref, toRefs, watch } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import useWorkflowTasksPaging from '@/ui/modules/workflow-task/_composables/workflow-tasks-paging.composable';
import WorkflowStepCodeLabel from '@/ui/modules/workflow/workflow-step/WorkflowStepCodeLabel.vue';

const props = defineProps<{
  workflowSteps: any[];
  tasksByStep: { [stepId: string]: any[] };
  isLoading: boolean;
}>();

const PAGE_SIZE = 12;
const LOAD_MORE_SIZE = 10;

const { tasksByStep } = toRefs(props);
const divLayout = ref();

const {
  tasksByStepPaging,
  remainingTasksCountByStep,
  onLoadMoreStepTasks,
  updateStepTasks,
} = useWorkflowTasksPaging(tasksByStep, PAGE_SIZE);
useResizeObserver(divLayout, () => _calculateColTasksHeight());

watch(
  () => props.workflowSteps,
  () => {
    _updateListTasks();
    setTimeout(() => _calculateColTasksHeight());
  }
);

watch(
  () => props.tasksByStep,
  () => {
    _updateListTasks();
  }
);

onMounted(() => {
  _updateListTasks();
  setTimeout(() => _calculateColTasksHeight());
});

const _updateListTasks = () => {
  props.workflowSteps?.forEach((step) => {
    updateStepTasks(step?.id);
  });
};

const _calculateColTasksHeight = () => {
  if (!divLayout.value) return;

  const headerEl = divLayout.value.querySelector('table thead');

  divLayout.value.querySelectorAll('table .col-tasks')?.forEach((col) => {
    col.style.height =
      divLayout.value.clientHeight - headerEl?.offsetHeight - 2 + 'px';
  });
};
</script>

<template>
  <div ref="divLayout" v-perfect-scrollbar class="h-full bg-gray-50">
    <table class="w-full">
      <thead>
        <tr class="bg-white border-b">
          <td
            v-for="(step, index) in workflowSteps"
            :key="step?.id"
            class="w-1/4 min-w-[20rem] h-1 p-2"
            :class="{
              'pl-4': index === 0,
              'pr-4': index === workflowSteps?.length - 1,
            }"
          >
            <div
              class="
                h-full
                rounded
                border border-dashed
                bg-gray-100
                px-3
                py-2
                flex flex-col
                justify-between
                items-end
              "
            >
              <div class="w-full flex align-center gap-2">
                <div class="h-6 flex-center">
                  <WorkflowStepCodeLabel
                    :model-value="step?.code || ''"
                    readonly
                  />
                </div>
                <div class="flex-1 leading-5 mt-0.5">{{ step?.name }}</div>
              </div>
              <div v-if="!isLoading" class="text-gray-500 text-xs">
                {{
                  $t('WORKFLOW_NUM_OF_TASKS', {
                    number: tasksByStep[step?.id]?.length || 0,
                  })
                }}
              </div>
              <div v-else class="h-4 w-16 rounded bg-gray-200"></div>
            </div>
          </td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td v-for="(step, index) in workflowSteps" :key="step?.id">
            <div
              v-perfect-scrollbar="{
                onScrollToEnd: () =>
                  onLoadMoreStepTasks(step?.id, LOAD_MORE_SIZE),
              }"
              class="col-tasks p-2"
              :class="{
                'pl-4': index === 0,
                'pr-4': index === workflowSteps?.length - 1,
              }"
            >
              <TaskCardItem
                v-for="task in tasksByStepPaging[step?.id]"
                :key="task?.id"
                class="mb-2"
                :task-detail="task"
              />

              <TaskBoardCardLoading v-if="isLoading" />

              <div
                v-if="!tasksByStep[step?.id]?.length && !isLoading"
                class="px-3 text-gray-500 italic text-sm"
              >
                {{ $t('WORKFLOW_LABEL_NO_TASK') }}
              </div>

              <!--SHOW MORE-->
              <div
                v-if="remainingTasksCountByStep[step?.id] > 0"
                class="flex-center"
              >
                <VigButton
                  light
                  color="gray"
                  rounded="rounded-2xl"
                  padding="px-3 py-1"
                  @click="onLoadMoreStepTasks(step?.id, LOAD_MORE_SIZE)"
                  ><span class="text-sm font-normal">{{
                    $t('WORKFLOW_VIEW_MORE_NUM_TASKS', {
                      number: remainingTasksCountByStep[step?.id],
                    })
                  }}</span>
                </VigButton>
              </div>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped></style>
