import { translate } from '@/ui/plugins/i18n/myi18n';

/* eslint-disable no-unused-vars */
export enum AdminType {
    Admin = 1,
    Visitor = 2,
    User = 3,
    Manager = 4,
    Owner = 99,
}

export const getAdminTypeName = (adminType: AdminType) => {
    let key = '';
    switch (adminType) {
        case AdminType.Admin:
            key = 'COMMON_LABEL_ADMIN';
            break;
        case AdminType.Owner:
            key = 'COMMON_LABEL_OWNER';
            break;
        case AdminType.User:
            key = 'USERMANAGEMENT_FORM_TYPE_USER';
            break;
        case AdminType.Visitor:
            key = 'USERMANAGEMENT_FORM_TYPE_VISITOR';
            break;
        case AdminType.Manager:
            key = 'USERMANAGEMENT_FORM_TYPE_MANAGER';
            break;

        default:
            break;
    }
    return translate(key);
};

export enum InstantMessagingType {
    Slack = 1,
    Skype = 2,
    Zalo = 3,
}

export enum CurrentUserConfig {
    FILTER_PUBLIC_GROUP = 'FILTER_PUBLIC_GROUP',
    OnlyUnreadNotification = 'ONLY_UNREAD_NOTIFICATION',
    NotifyWorkingStatus = 'NOTIFY_WORKING_STATUS',
    OnlyUnreadAllNotification = 'ONLY_UNREAD_ALL_NOTIFICATION',
    OnlyUnreadNewNotification = 'ONLY_UNREAD_NEW_NOTIFICATION',
    OnlyUnreadUpdatedNotification = 'ONLY_UNREAD_UPDATED_NOTIFICATION',
    OnlyUnreadFinishedNotification = 'ONLY_UNREAD_FINISHED_NOTIFICATION',
    OnlyUnreadOtherNotification = 'ONLY_UNREAD_OTHER_NOTIFICATION',
    ViewTaskListDefault = 'VIEW_TASK_LIST_DEFAULT',
    StartWorkingConditionsSpecific = 'START_WORKING_CONDITIONS_SPECIFIC',
}

export enum AuthenticateType {
    UsernamePassword = 1,
    PhoneCode = 2,
    EmailPhonePassword = 3,
}

export enum ErrorNotificationLogin {
    USER_PASSWORD_INVALID = 'USER_PASSWORD_INVALID',
    USER_DELETE_REQUESTED = 'USER_DELETE_REQUESTED',
    UNVERFIED_AFTER_INTERVAL_TIME = 'UNVERFIED_AFTER_INTERVAL_TIME',
    INVALID_CODE = 'CODE_INVALID',
    CODE_EXPIRED = 'CODE_EXPIRED',
    SESSION_EXPIRED = 'CODE_EXPIRED',
    INVALID_PHONE_OR_CODE = 'INVALID_PHONE_OR_CODE',
    INVALID_EMAIL_OR_CODE = 'INVALID_EMAIL_OR_CODE',
    INVALID_PARAM = 'INVALID_PARAM',
    TOKEN_EXPIRED = 'TOKEN_EXPIRED',
    PHONE_UNVERIFIED = 'PHONE_UNVERIFIED',
    EMAIL_UNVERIFIED = 'EMAIL_UNVERIFIED',
    NOT_ALLOW = 'NOT_ALLOW',
    INVALID_SESSION_INFO = 'CODE_INVALID',
    LOGIN_BY_CODE_BLOCKED = 'LOGIN_BY_CODE_BLOCKED',
    SEND_PHONE_CODE_BLOCKED = 'SEND_PHONE_CODE_BLOCKED',
    CAPTCHA_CHECK_FAILED = 'CAPTCHA_CHECK_FAILED',
    SEND_VERIFICATION_FAILED = 'SEND_VERIFICATION_FAILED',
    SUSPICIOUS_REQUEST_DETECTED = 'SUSPICIOUS_REQUEST_DETECTED',
    EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
    EMAIL_NOT_EXISTED = 'EMAIL_NOT_EXISTED',
    PHONE_ALREADY_EXISTS = 'PHONE_ALREADY_EXISTS',
    PHONE_NOT_EXISTED = 'PHONE_NOT_EXISTED',
    SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}

export enum ResponseStatusCode {
    LoginFailed = 4001,
    LoginBlocked = 4002,
    OrganizationInactive = 4003,
    NotFinishSetup = 4004, //  For mobile only
    SendPhoneCodeBlocked = 4005,
    CaptchaCheckFailed = 4006,
    InvalidPhoneNumber = 4007,
    InvalidSessionInfo = 4008,
    SendVerificationFailed = 4009,
    CodeInvalid = 4010,
    WaitingActivationOrganization = 4011,
    SuspiciousRequest = 5000,

    TaskDeleted = 6000,

    InviteTokenInvalid = 6100,
    InviteTokenExpired = 6101,
    InviteTokenCanceled = 6102,
    InviteUserAttached = 6103,
    InviteJoinedByOtherMethods = 6104,
    InviteUserDeleted = 6105,
}

export const FavoriteType = {
    Member: 'member',
    Group: 'group',
    Domain: 'domain',
    Menu: 'menu',
};

export const NotificationSettingFunction = {
    Messaging: 'MESSAGING',
    Task: 'TASK',
    All: 'ALL',
};

export enum GroupMemberRole {
    Admin = 1,
    User = 2,
}

export enum GroupChatRestriction {
    NoRestriction = 'NO_RESTRICTION',
    AllowAdminOnly = 'ALLOW_ADMIN_ONLY',
    AllowSpecificMembers = 'ALLOW_SPECIFIC_MEMBERS',
}

export enum SettingProgress {
    SettingOrganization = 'SETTING_ORGANIZATION',
    SettingTeam = 'SETTING_TEAM',
    WaitingForActivation = 'WAITING_FOR_ACTIVATION',
    SettingFinished = 'SETTING_FINISHED',
}

export enum SettingProgressEnum {
    SelectInvitation = 'SELECT_INVITATION',
    SettingOrganization = 'SETTING_ORGANIZATION',
    SettingFinished = 'SETTING_FINISHED',
    NoWorkspace = 'NO_WORKSPACE',
}

export enum UserWorkingStatusType {
    Online = 'online',
    Pause = 'absent',
    Offline = 'offline',
}

export const UserActivitiesType = {
    StartWorking: 'START_WORKING',
    Login: 'LOGIN',
    Logout: 'LOGOUT',
    Logon: 'LOGON',
    CreateTask: 'CREATE_TASK',
    CreateGroup: 'CREATE_GROUP',
    UserApp: 'USEAPP',
    DeleteAccount: 'DELETE_ACCOUNT',
    InActiveAccount: 'INACTIVE_ACCOUNT',
};

export const UserSubActivitiesType = {
    Start: 'START',
    Pause: 'PAUSE',
    Continue: 'CONTINUE',
    Stop: 'STOP',
};
