<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { chain, sortBy } from 'lodash';
import { useElementHover } from '@vueuse/core';
import MessageReply from '@/ui/modules/messaging/chat-panel/chat-message/message-reply/MessageReply.vue';
import MessageCall from '@/ui/modules/messaging/chat-panel/chat-message/message-call/MessageCall.vue';
import MessageNotification from '@/ui/modules/messaging/chat-panel/chat-message/message-notification/MessageNotification.vue';
import { isValidTaskCode } from '@/ui/hooks/taskHook';
// import TaskCard from '@/ui/modules/overview/TaskCard.vue';
import taskDetailStore from '@/store/task/detail';
import UserSmallCard from '@/ui/components/user/UserSmallCard.vue';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import { getAllDomainUrl } from '@/ui/hooks/commonHook';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import GroupMessage from './group-message/GroupMessage.vue';
import MessageSeen from '@/ui/modules/messaging/chat-panel/message-seen/MessageSeen.vue';
import myProfileStore from '@/store/auth/my-profile';
import dynamicCardPosition from '@/ui/composables/app/dynamic-card-position';
import DiscussAboutMessage from '@/ui/modules/messaging/chat-panel/chat-message/group-message/DiscussAboutMessage.vue';
import GroupMessageFiles from '@/ui/modules/messaging/chat-panel/chat-message/group-message/GroupMessageFiles.vue';
import ButtonCustomizeBgColorMsg from '@/ui/modules/messaging/chat-panel/chat-message/button-customize/ButtonCustomizeBgColorMsg.vue';
import ButtonActionMore from '@/ui/modules/messaging/chat-panel/chat-message/button-action-more/ButtonActionMore.vue';
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import MessagePinned from '@/ui/modules/messaging/chat-panel/chat-message/message-pinned/MessagePinned.vue';
import datetimeComposable from '@/ui/composables/common/datetime-composable';
import ChatConversationRepository from '@/application/repositories/chat/ChatConversationRepository';
import ChatMessageRepository from '@/application/repositories/chat/ChatMessageRepository';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import { decryptUserId } from '@/ui/plugins/crypto/crypto';
import MessageCreatedTasks from '@/ui/modules/messaging/chat-panel/chat-message/message-created-tasks/MessageCreatedTasks.vue';
import MessageDetailEntity from '@/domain/entities/messages/MessageDetailEntity';
import DayoffCreateNewModal from '@/ui/components/dayoff/DayoffCreateNewModal.vue';
import EventCreateModal from '@/ui/modules/calendar/event/EventCreateModal.vue';
import ReminderCreateModal from '@/ui/modules/calendar/reminder/ReminderCreateModal.vue';
import ChatService from '@/application/services/ChatService';
import { ECalendarFromMessageCategory } from '@/application/types/calendar/day-off.types';
import MessageCreatedCalendars from '@/ui/modules/messaging/chat-panel/chat-message/message-created-calendars/MessageCreatedCalendars.vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';

const _taskDetailStore = taskDetailStore();
const props = defineProps<{
  messageId?: string;
  conversationId?: string;
  organizationId?: number;
  message?: ChatMessageModel;
  conversation?: ChatConversationModel;
  replyToMsg?: any;
  members?: any;
  contact?: any;
  size?: string;
  isMyMessage?: boolean;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  isErrorSend?: boolean;
  isPendingSend?: boolean;
  seenByMembers?: any;
  isDisabled?: boolean;
  isShowPinnedOnly?: boolean;
  allowedActions?: ChatMessageAction[];
}>();

const emit = defineEmits([
  'onMessageDelete',
  'onMessageForward',
  'onFileClick',
  'onReactionAdd',
  'onReactionsClick',
  'onReplyClick',
  'onTaskClick',
  'onDayoffClick',
  'onEventClick',
  'onClickMessageReply',
  'onMessageDownload',
  'onClosePreviewLink',
  'onChangeBackgroundMsg',
  'onMessageCreateTask',
  'onGoToMessage'
]);
const chatMessageRef = ref();
const isOnHovering = useElementHover(chatMessageRef);
const { formatDate } = datetimeComposable();
const createdDate = computed(() => {
  const dateValue = props.message?.createdDate
    ? new Date(props.message.createdDate)
    : new Date();

  return formatDate(dateValue, 'date_time', {
    isIgnoreTimezone: true,
  });
});
const myProfile = computed(() => myProfileStore().myProfile);

const membersSeenList = computed(() =>
  chain(props.seenByMembers)
    .filter(
      (member) =>
        member?.memberId !== `${myProfile.value?.id}` &&
        props.members &&
        !props.members[member?.memberId]?.removed
    )
    .map((member) => ({
      ...props.members[member?.memberId],
      ...member,
      userId: member?.memberId,
    }))
    .value()
);

const files = computed(() => {
  const msgFiles = Object.entries(props.message?.files || {}).map(
    ([id, file]: [any, any]) => ({ ...file, id })
  );
  return sortBy(msgFiles, ['index']);
});

const isExpandViewFile = ref(true);
const currentTask = ref(null as any);
const cardUserId = ref(0 as number);
const isLoadingTask = ref(false);
const currentUserProfileId = ref(null);
const isHasAction = ref(false);

const isGroupChat = computed(() => props.conversationId?.startsWith('G-'));
const cardUser = computed(() => props.members[decryptUserId(cardUserId.value)]);

const resetTaskCard = () => {
  currentTask.value = null;
  cardUserId.value = 0;
};
const taskCardSize = {
  width: 280,
  height: 90,
};

const {
  updatePosition: updateTaskCardPosition,
  cardPosition: taskCardPosition,
  isShowCard: isShowTaskCard,
  cardElementRef: taskCardElementRef,
  isFocusOnCard: isFocusOnTaskCard,
} = dynamicCardPosition(250, resetTaskCard);

const onClickTextMessage = async (event) => {
  const href = event?.target?.href;
  console.log(event);

  if (!href || !href?.includes('/tasks/')) return;

  const taskCode = href.split('/').pop();

  if (!isValidTaskCode(taskCode)) return;

  event.preventDefault();

  emit('onTaskClick', taskCode);
};

const fetchDataForTaskCard = async (href) => {
  try {
    isLoadingTask.value = true;
    const isUserCase = href.includes('/users/');
    if (isUserCase) {
      cardUserId.value = href.split('users/').pop();
      currentTask.value = null;
    } else {
      cardUserId.value = 0;
      const taskCode = href.split('/').pop();
      if (!isValidTaskCode(taskCode)) return;
      currentTask.value = await _taskDetailStore.getDetailByCode(taskCode);
    }
  } finally {
    isLoadingTask.value = false;
  }
};

let timeoutDrawTask;
const onHoverTextMessage = async (event) => {
  if (isLoadingTask.value) return;
  if (timeoutDrawTask) clearTimeout(timeoutDrawTask);

  timeoutDrawTask = setTimeout(() => {
    const href = event?.target?.href;
    const baseUrls = getAllDomainUrl();

    const isIncludeBaseUrl = Object.values(baseUrls).some((urlName) =>
      href?.includes(urlName)
    );
    if (!href || !isIncludeBaseUrl || !href?.includes('/tasks')) return;
    resetTaskCard();

    updateTaskCardPosition(event, taskCardSize, () => {
      fetchDataForTaskCard(href);
    });
  }, 800);
};

const onMouseLeaveTextMessage = () => {
  // setTimeout(() => {
  //   if (isFocusOnTaskCard.value) return;
  //   resetTaskCard();
  // }, 250);
  if (isFocusOnTaskCard.value) return;
  resetTaskCard();
  if (timeoutDrawTask) clearTimeout(timeoutDrawTask);
};

const onClosePreviewLink = (link) => {
  emit('onClosePreviewLink', link);
};

const onMessagePinToggle = () => {
  if (!props.conversationId || !props.messageId) return;

  const userId = myProfile.value?.id;

  if (props.message?.pinned) {
    ChatConversationRepository.removePinnedMessage(
      props.organizationId,
      props.conversationId,
      props.messageId
    );
    ChatMessageRepository.unpinMessage(
      props.organizationId,
      props.conversationId,
      props.messageId,
      userId
    );
  } else {
    ChatConversationRepository.addPinnedMessage(
      props.organizationId,
      props.conversationId,
      props.messageId,
      props.message,
      userId
    );
    ChatMessageRepository.pinMessage(
      props.organizationId,
      props.conversationId,
      props.messageId,
      userId
    );
  }
};

const openModalStatus = reactive<{
  dayOff: boolean;
  event: boolean;
  reminder: boolean;
}>({
  dayOff: false,
  event: false,
  reminder: false,
});
const createReminderDefaultData = ref<{
  name?: string;
  description?: string;
  startDate?: number;
  endDate?: number;
  files?: any[];
}>({});

const prepareDataForCreateCalendar = async (isKeepHtmlTag?) => {
  // const elementMessage = document.createElement('html');
  // elementMessage.innerHTML = props.message?.comment
  //   ? (props.message?.comment || '')?.replaceAll('<br>', '\n ')
  //   : (props.message?.text || '')?.replaceAll('<br>', '\n ');

  // createReminderDefaultData.value = {
  //   name:
  //     elementMessage.innerText?.length < 200 ? elementMessage.innerText : '',
  //   description:
  //     elementMessage.innerText?.length >= 200 ? elementMessage.innerText : '',
  // };

  const CurrentMessageEntity = new MessageDetailEntity({
    ...props.message,
  });

  createReminderDefaultData.value =
    await CurrentMessageEntity.getDataForCreateCalendar(isKeepHtmlTag);
};

const handleOpenCalendarModal = async (type) => {
  switch (type) {
    case 'DAYOFF':
      await prepareDataForCreateCalendar();
      openModalStatus.dayOff = true;
      break;
    case 'EVENT':
      await prepareDataForCreateCalendar(true);
      openModalStatus.event = true;
      break;
    case 'REMINDER':
      await prepareDataForCreateCalendar();
      openModalStatus.reminder = true;
      break;

    default:
      break;
  }
};

const onCreateCalendarSuccess = (
  newData,
  category: ECalendarFromMessageCategory
) => {
  if (!props.organizationId || !props.conversationId || !props.messageId) {
    return;
  }

  ChatService.addCreatedCalendarToMessage(
    props.organizationId,
    props.conversationId,
    props.messageId,
    {
      ...newData,
      category,
    },
    myProfile.value?.id
  );

  openModalStatus.dayOff = false;
  openModalStatus.event = false;
  openModalStatus.reminder = false;
};

const emitClickEvent = (eventId) => {
  emit('onEventClick', eventId);
};
</script>

<template>
  <div
    :class="[
      size === 'sm' ? 'px-1' : 'px-3',
      isLastInGroup ? 'mb-3' : 'mb-0.5',
    ]"
  >
    <MessageNotification
      v-if="message?.type"
      :message="message"
      :members="members"
      :is-disabled="isDisabled"
      :allowed-actions="allowedActions"
      @on-reaction-add="$emit('onReactionAdd', $event)"
      @on-reactions-click="$emit('onReactionsClick', $event)"
    />

    <div
      v-else
      :class="{
        'bg-orange-50 py-1': message?.pinned && !isShowPinnedOnly,
      }"
    >
      <div
        :id="`msg-conversation-${conversationId}-${messageId}`"
        ref="chatMessageRef"
        class="flex items-end group"
        :class="[isMyMessage ? 'flex-row-reverse' : 'flex-row']"
        style="overflow-anchor: auto"
      >
        <!--AVATAR-->
        <div v-if="!isMyMessage" class="h-8 w-8 mr-2">
          <UserByAction
            v-if="isLastInGroup && message?.createdBy"
            avatar-class="w-8 h-8 "
            :user="members ? members[message?.createdBy] : null"
            :user-id="message?.createdBy"
            :is-show-action-user="
              allowedActions?.includes(ChatMessageAction.USER_ACTIONS)
            "
            is-hidden-name
          />
        </div>

        <!--DELETED-->
        <div
          v-if="message?.removed"
          class="flex msg-content"
          :class="isMyMessage ? 'flex-row-reverse' : 'flex-row'"
        >
          <span
            class="py-2 px-4 rounded bg-gray-50 italic text-gray-400"
            :class="{
              'rounded-l-3xl': isMyMessage,
              'rounded-r-3xl': !isMyMessage,
              'rounded-t-3xl': isFirstInGroup,
              'rounded-b-3xl': isLastInGroup,
            }"
          >
            <span
              v-if="
                conversation?.isGroup &&
                message?.removedBy?.toString() !==
                  message?.createdBy?.toString() &&
                message?.removedBy?.toString() !== myProfile?.id?.toString()
              "
            >
              {{
                $t('CHAT_MSG_DELETED_BY_ADMIN') || 'Message has been deleted'
              }}</span
            >
            <span v-else>{{
              $t('CHAT_MSG_DELETED') || 'Message has been deleted'
            }}</span>
          </span>
        </div>

        <div v-else class="flex-1 min-w-0 relative">
          <!--PINNED-->
          <MessagePinned
            v-if="message?.pinned"
            :message="message"
            :members="members"
            :is-my-message="isMyMessage"
            :can-unpin="allowedActions?.includes(ChatMessageAction.PIN)"
            @on-unpin-click="onMessagePinToggle"
          />

          <!--REPLY TO-->
          <MessageReply
            v-if="message?.replyTo"
            :reply-to-msg="replyToMsg"
            :reply-to-msg-id="message?.replyTo"
            :conversation-id="conversationId"
            :is-my-message="isMyMessage"
            :members="members"
            @on-click-message-reply="(id) => emit('onClickMessageReply', id)"
          />

          <!-- GROUP MESSAGE -->
          <div
            class="flex items-center space-x-2 relative"
            :class="
              isMyMessage ? 'flex-row-reverse space-x-reverse' : 'flex-row'
            "
          >
            <div
              :title="createdDate"
              class="relative hover-to-show__parent max-w-2xl"
            >
              <!--CALL-->
              <MessageCall
                v-if="message?.call"
                :message="message"
                :is-my-message="isMyMessage"
                :is-first-in-group="isFirstInGroup"
                :is-last-in-group="isLastInGroup"
              />

              <!-- MESSAGE CONTENT -->
              <template v-else>
                <!-- TASK/ DAYOFF/ EVENT -->
                <DiscussAboutMessage
                  :message="message"
                  :is-my-message="isMyMessage"
                  :members="members"
                  :is-first-in-group="isFirstInGroup"
                  :is-last-in-group="isLastInGroup"
                  :is-error-send="isErrorSend"
                  :allowed-actions="allowedActions"
                  @on-reaction-add="$emit('onReactionAdd', $event)"
                  @on-reactions-click="$emit('onReactionsClick', $event)"
                  @on-task-click="(taskCode) => $emit('onTaskClick', taskCode)"
                  @on-dayoff-click="
                    (dayoffId) => $emit('onDayoffClick', dayoffId)
                  "
                  @on-event-click="(eventId) => emitClickEvent(eventId)"
                />
                <!-- TEXT -->
                <GroupMessage
                  v-model:is-expand-view-file="isExpandViewFile"
                  :message="message"
                  :size="size"
                  :is-my-message="isMyMessage"
                  :is-first-in-group="isFirstInGroup"
                  :is-last-in-group="isLastInGroup"
                  :is-error-send="isErrorSend"
                  :is-pending-send="isPendingSend"
                  :members="members"
                  :is-on-hovering="isOnHovering"
                  :is-disabled="isDisabled"
                  :allowed-actions="allowedActions"
                  :is-show-name="
                    isGroupChat &&
                    isFirstInGroup &&
                    !isMyMessage &&
                    !!members &&
                    !!message?.createdBy
                  "
                  @on-click-text-message="onClickTextMessage"
                  @on-hover-text-message="onHoverTextMessage"
                  @on-mouse-leave-text-message="onMouseLeaveTextMessage"
                  @on-close-preview-link="onClosePreviewLink"
                  @on-task-click="$emit('onTaskClick')"
                  @on-dayoff-click="$emit('onDayoffClick')"
                  @on-reaction-add="$emit('onReactionAdd', $event)"
                  @on-reactions-click="$emit('onReactionsClick', $event)"
                  @on-file-click="(file) => $emit('onFileClick', file)"
                  @on-message-download="
                    (files) => $emit('onMessageDownload', files)
                  "
                />
                <!-- FILE -->
                <GroupMessageFiles
                  v-if="
                    files?.length > 1 ||
                    (!message.text && !message.comment) ||
                    ((message.task || message.dayoff) && !message.comment)
                  "
                  :is-expand-view-file="isExpandViewFile"
                  :message="message"
                  :size="size"
                  :is-my-message="isMyMessage"
                  :members="members"
                  :is-first-in-group="isFirstInGroup"
                  :is-last-in-group="isLastInGroup"
                  :is-error-send="isErrorSend"
                  :is-show-name="
                    isGroupChat &&
                    isFirstInGroup &&
                    !isMyMessage &&
                    !!members &&
                    !!message?.createdBy
                  "
                  :allowed-actions="allowedActions"
                  @on-reactions-click="$emit('onReactionsClick', $event)"
                  @on-reaction-add="$emit('onReactionAdd', $event)"
                  @on-file-click="(file) => $emit('onFileClick', file)"
                />
              </template>
            </div>
            <!--ACTIONS-->
            <div
              v-if="!isDisabled"
              class="flex"
              :class="{
                'flex-row-reverse pl-3': isMyMessage,
                'flex-row pr-3': !isMyMessage,
              }"
              style="min-width: 6rem"
            >
              <template
                v-if="
                  isHasAction ||
                  (!isErrorSend && !isPendingSend && isOnHovering)
                "
              >
                <ButtonCustomizeBgColorMsg
                  v-if="
                    allowedActions?.includes(
                      ChatMessageAction.SET_BACKGROUND
                    ) &&
                    !message?.call &&
                    !isDisabled
                  "
                  :is-my-message="isMyMessage"
                  :color-current="
                    message?.customizes?.backgroundColor[myProfile?.id]?.hex ||
                    ''
                  "
                  :conversation="conversation"
                  :is-show-all="
                    message?.customizes?.backgroundColor[myProfile?.id]
                      ?.isShowAll === 'true'
                  "
                  @is-has-action="(value) => (isHasAction = value)"
                  @on-change-background-msg="
                    $emit('onChangeBackgroundMsg', $event)
                  "
                />

                <button
                  v-if="
                    allowedActions?.includes(ChatMessageAction.REPLY) &&
                    !message?.call &&
                    !isDisabled &&
                    !conversation?.isGroupChatDisallowed
                  "
                  class="w-7 h-7 rounded-full hover:bg-gray-100 flex-center"
                  @click="$emit('onReplyClick')"
                >
                  <SynIcon name="Reply" class="fill-gray-500" />
                </button>

                <ButtonActionMore
                  v-if="!message?.call || files?.length > 0 || isMyMessage"
                  :organization-id="organizationId"
                  :conversation-id="conversationId"
                  :conversation="conversation"
                  :message-id="messageId"
                  :message="message"
                  :contact="contact"
                  :files="files"
                  :is-my-message="isMyMessage"
                  :is-disabled="isDisabled"
                  :allowed-actions="allowedActions"
                  @is-has-action="(value) => (isHasAction = value)"
                  @on-message-reply="$emit('onReplyClick')"
                  @on-message-forward="$emit('onMessageForward')"
                  @on-message-create-task="$emit('onMessageCreateTask')"
                  @on-message-delete="$emit('onMessageDelete')"
                  @on-message-pin-toggle="onMessagePinToggle"
                  @on-open-create-new-event="handleOpenCalendarModal"
                  @on-go-to-message="$emit('onGoToMessage')"
                />
              </template>
            </div>
          </div>

          <div
            class="flex min-w-0 w-full overflow-hidden"
            :class="!!isMyMessage ? 'justify-end' : 'justify-start'"
          >
            <div
              class="max-w-full flex flex-col"
              :class="!!isMyMessage ? 'justify-start' : 'flex-row'"
            >
              <MessageCreatedTasks
                :is-my-message="!!isMyMessage"
                :message="message"
                :conversation="conversation"
                @on-task-click="onClickTextMessage"
                @on-task-mouse-over="onHoverTextMessage"
                @on-task-mouse-leave="onMouseLeaveTextMessage"
              />
              <MessageCreatedCalendars
                :is-my-message="!!isMyMessage"
                :message="message"
                :conversation="conversation"
                @on-task-click="onClickTextMessage"
                @on-task-mouse-over="onHoverTextMessage"
                @on-task-mouse-leave="onMouseLeaveTextMessage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Seen -->
    <div
      v-if="membersSeenList?.length > 0"
      class="w-full flex justify-end py-1"
    >
      <MessageSeen :members-seen="membersSeenList" />
    </div>
  </div>

  <teleport to="body">
    <div
      v-if="isShowTaskCard && (cardUser || currentTask?.id)"
      ref="taskCardElementRef"
      class="absolute top-0 left-0 z-100 bg-white rounded shadow-md"
      :style="[
        `top: ${taskCardPosition.y}px`,
        `left: ${taskCardPosition.x}px`,
        `width: ${taskCardSize.width}px`,
        `height: ${taskCardSize.height}px`,
      ]"
    >
      <div v-if="cardUser">
        <UserSmallCard
          :user="cardUser"
          @open-profile="(userId) => (currentUserProfileId = userId)"
        />
      </div>
      <TaskCardItem v-else :task-detail="currentTask" readonly />
    </div>
  </teleport>

  <ModalProfileUser
    v-if="currentUserProfileId"
    :profile-id="currentUserProfileId"
    @cancel="currentUserProfileId = null"
  />

  <DayoffCreateNewModal
    v-if="openModalStatus.dayOff"
    :default-data="{
      requestNote:
        createReminderDefaultData?.name ||
        createReminderDefaultData?.description,
    }"
    @cancel="openModalStatus.dayOff = false"
    @on-create-success="
      (newData) =>
        onCreateCalendarSuccess(newData, ECalendarFromMessageCategory.DAY_OFF)
    "
  />

  <EventCreateModal
    v-if="openModalStatus.event"
    :default-data="{
      title: createReminderDefaultData?.name,
      description: createReminderDefaultData?.description,
      startDate: createReminderDefaultData?.startDate || Date.now(),
      files: createReminderDefaultData?.files,
    }"
    @cancel="openModalStatus.event = false"
    @on-create-success="
      (newData) =>
        onCreateCalendarSuccess(newData, ECalendarFromMessageCategory.EVENT)
    "
  />

  <ReminderCreateModal
    v-if="openModalStatus.reminder"
    :default-data="{
      title:
        createReminderDefaultData?.name ||
        createReminderDefaultData?.description?.slice(0, 200),
      startDate: createReminderDefaultData?.startDate || Date.now(),
    }"
    @cancel="openModalStatus.reminder = false"
    @on-create-success="
      (newData) =>
        onCreateCalendarSuccess(newData, ECalendarFromMessageCategory.REMINDER)
    "
  />
</template>

<style>
.msg-text a {
  @apply text-current-500;
}

.msg-text a:hover {
  @apply underline;
}

.msg-text .mention {
  @apply text-current-500 bg-blue-50 bg-opacity-30 bg-current-100 bg-opacity-50 hover:bg-opacity-80;
}

.msg-text .mention {
  border-radius: 4px !important;
}

.msg-content {
  @apply relative;
}

@keyframes change {
  0% {
    background-color: rgb(64, 153, 158);
  }
  35% {
    background-color: #9edfe2;
  }
  70% {
    background-color: #7bd1d1;
  }
  100% {
    background-color: rgb(64, 153, 158);
  }
}

.msg-focus {
  @apply border border-current ring ring-current ring-opacity-30;
}
</style>
