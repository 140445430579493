<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m6.3 14.71a1 1 0 0 0 .7.29 1 1 0 0 0 .71-.3 1 1 0 0 0 0-1.41l-3.35-3.29h6.64a8 8 0 0 1 8 8v2a1 1 0 0 0 2 0v-2a10 10 0 0 0 -10-10h-6.62l3.32-3.29a1 1 0 0 0 -1.4-1.42l-5.07 5a1 1 0 0 0 0 1.42z"
        class=""
      ></path>
    </g>
  </svg>
</template>
