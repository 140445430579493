import { Ref, ref, UnwrapRef } from 'vue';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { TaskReccuringTemplateClass } from '@/domain/entities/task/TaskReccuringTemplateClass';
import useLimitation from '@/ui/composables/limitation/limitation-composable';

export default function updateAttachmentLogic(
    attachmentCurrentTask: Ref<
        UnwrapRef<TaskDetailClass | TaskReccuringTemplateClass>
    >,
    uploadFileInputId: string
) {
    const isOpenNote = ref(false);
    const drawNoteData = ref({
        isLoading: false,
        drawData: {},
        images: [],
        originFile: '',
        readonly: false,
    } as any);

    const uploadError = ref(false);
    const uploadingFiles = ref([] as any[]);
    const isPasted = ref(false);
    const isLoadingFile = ref(false);

    const onCancelNote = () => {
        isOpenNote.value = false;
        drawNoteData.value = {
            isLoading: false,
            drawData: {},
            images:
                attachmentCurrentTask.value.files.filter((o) =>
                    o.type?.startsWith('image')
                ) || [],
            originFile: '',
            readonly: false,
        };
    };
    const onFetchCreateNote = async (noteData: {
        audios: any[];
        base64: string;
        drawData: any;
    }) => {
        try {
            onCancelNote();
            uploadError.value = false;
            const drawObjects = noteData?.drawData?.objects;

            const hasOnlyOneImage =
                drawObjects?.length == 1 && drawObjects[0]?.type == 'image';

            uploadingFiles.value.push({
                name: '',
                drawData: noteData?.drawData,
                fileUrl: noteData?.base64,
                base64: noteData?.base64,
                size: noteData?.base64?.length * (3 / 4) - 2,
                type: 'image/jpeg',
                isBase64: true,
                isNote: hasOnlyOneImage ? false : true,
            });
            isPasted.value = false;
            if (hasOnlyOneImage)
                await attachmentCurrentTask.value.addFiles([
                    {
                        name: '',
                        fileUrl: drawObjects[0].src,
                        base64: noteData?.base64,
                        size: noteData?.base64?.length * (3 / 4) - 2,
                        type: 'image/jpeg',
                        extension: '.jpeg',
                        isBase64: true,
                    },
                ]);
            else await attachmentCurrentTask.value.addNote(noteData);

            uploadingFiles.value = uploadingFiles.value.filter(
                (file) => file.fileUrl !== noteData?.base64
            );
        } catch (error) {
            uploadError.value = true;
            console.log('🚀 ~ onCreateNote ~ error', error);
        }
    };

    const onFetchUpdateNote = async (noteData) => {
        isLoadingFile.value = true;
        await attachmentCurrentTask.value.updateNote(noteData);
        onCancelNote();
        isLoadingFile.value = false;
    };

    const onOpenCreateNote = (defaultData) => {
        drawNoteData.value = {
            isLoading: false,
            drawData: defaultData?.drawData || {},
            images:
                attachmentCurrentTask.value.files.filter((o) =>
                    o.type?.startsWith('image')
                ) || [],
            originFile: '',
            readonly: false,
        };
        isOpenNote.value = true;
    };

    const onUploadFiles = async (files) => {
        try {
            const newFiles = [...files];

            const _useLimitation = useLimitation('TASK');
            const validFiles = _useLimitation.verifySelectedFiles(
                Array.from(files),
                files
            );

            if (!validFiles || validFiles?.length == 0)
                return Promise.resolve(false);

            uploadingFiles.value = [...uploadingFiles.value, ...validFiles];

            await attachmentCurrentTask.value.addFiles(validFiles);

            uploadingFiles.value = uploadingFiles.value?.filter(
                (file) => !newFiles?.some((o) => o.path == file?.path)
            );
        } catch (error) {
            console.log('🚀 ~ onUploadFiles ~ error', error);
            uploadError.value = true;
        }

        const inputImage: HTMLInputElement = document.getElementById(
            uploadFileInputId
        ) as HTMLInputElement;
        if (!inputImage) return;
        inputImage.value = '';
    };

    const onChooseFiles = (event) => {
        uploadError.value = false;
        const files = event.target?.files;
        onUploadFiles(files);
    };

    // audio playback
    const isOpenRecording = ref(false);
    const onCreateAudioDone = (recordings) => {
        // formData.value.files = [...formData.value.files, ...recordings];
        isOpenRecording.value = false;
        onUploadFiles(recordings);
    };
    const onCreateMultiNotes = (notes) => {
        return Promise.all(notes.map((note) => onFetchCreateNote(note)));
    };

    return {
        isOpenNote,
        drawNoteData,
        uploadError,
        uploadingFiles,
        isPasted,
        onFetchCreateNote,
        onCancelNote,
        onFetchUpdateNote,
        onOpenCreateNote,
        onUploadFiles,
        onChooseFiles,
        isOpenRecording,
        onCreateAudioDone,
        onCreateMultiNotes,
        isLoadingFile,
    };
}
