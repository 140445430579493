<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import dashboardStore from '@/store/dashboard';
import principleStore from '@/store/principle';
import TimeWorking from '../../../modules/working/TimeWorking.vue';
import dayjs from 'dayjs';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import {
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import CheckPermissionContainer from '@/ui/components/setting/permissions/CheckPermissionContainer.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import router from '@/ui/router';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import {
  ALL_FUNCTIONS,
  ALL_SCREENS,
} from '@/ui/hooks/permission/permission-by-function';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import OverviewTimeWorkingMembersByDate from '@/ui/pages/overview/working-time/OverviewTimeWorkingMembersByDate.vue';
import SynButton from '@/ui/common/atoms/SynButton/SynButton.vue';
import FilterWorkingTimeDetail from '@/ui/pages/overview/working-time/FilterWorkingTimeDetail.vue';
import ModalExportWorkingTimeFile from '@/ui/pages/overview/working-time/ModalExportWorkingTimeFile.vue';
import OverviewLastActivityByDate from '@/ui/pages/overview/working-time/OverviewLastActivityByDate.vue';
import appStore from '@/store/app';

const _dashboardStore = dashboardStore();
const _principleStore = principleStore();
const _appStore = appStore();

const lang = computed(() => _appStore.language);
const userWorkingStatus = computed(() => _dashboardStore.userWorkingStatus);
const overviewWorkInfoList = computed(() => _principleStore.overviewWorkInfo);
const userActivitiesList = ref([] as any);

const detailByUserId = computed(() => _principleStore.detailByUserId);
const usersPrinciple = computed(() => {
  if (!keySearch.value) return listUsersPrinciple.value;
  let newArr = (listUsersPrinciple.value || []).filter((user: any) =>
    stringNomalize(user.fullname?.toLowerCase()).includes(
      stringNomalize(keySearch.value?.toLowerCase())
    )
  );
  // let arrResult = unique(newArr);
  // return arrResult;
  return newArr;
});
const usersWorkInfoList = computed(() => {
  if (!keySearch.value) return overviewWorkInfoList.value;
  let newArr = (overviewWorkInfoList.value || []).filter((user: any) =>
    stringNomalize(user.fullName?.toLowerCase()).includes(
      stringNomalize(keySearch.value?.toLowerCase())
    )
  );
  return newArr;
});

// const unique = (arr) => {
//   const newArr = [] as any;
//   for (var i = 0; i < arr.length; i++) {
//     if (newArr?.findIndex((el: any) => el?.userId === arr[i]?.userId) === -1) {
//       newArr.push(arr[i]);
//     }
//   }
//   return newArr;
// };
const currentUserId = ref();
const isLoading = ref(false);
const userTabs = ref([] as any);
const mode = ref<'generality' | 'members' | 'activity' | 'detail'>(
  'generality'
);
const keySearch = ref('');
const dateConfig = ref({ type: 'string', mask: 'YYYY-MM-DD' });
const listUsersPrinciple = computed(() => _principleStore.usersPrinciple);
const filterObj = ref({
  dateFrom: new Date(),
  dateTo: new Date(),
  fileTypes: {},
});

const isOpenExportWTModal = ref(false);

const filterObjOverviewTimeWorking = ref({
  fromDate: null as any,
  toDate: null as any,
});

const selectDateFilterList = [
  {
    text: 'LABEL_LAST_7_DAYS',
    key: 1,
  },
  {
    text: 'LABEL_LAST_15_DAYS',
    key: 2,
  },
  {
    text: 'LABEL_LAST_1_MONTH',
    key: 3,
  },
  {
    text: 'LABEL_CUSTOM_DATE',
    key: 4,
  },
];

const selectDateDefault = ref(1);

const loadingWorkingTimeDetail = (value) => {
  isLoading.value = value;
};

const selectDateFilter = (value) => {
  selectDateDefault.value = value;
  switch (selectDateDefault.value) {
    case 1:
      getWorkInfoByDateRange(6, 'days'); // last 7 days
      break;
    case 2:
      getWorkInfoByDateRange(14, 'days'); // last 15 days
      break;
    case 3:
      getWorkInfoByDateRange(1, 'month'); // last 1 month
      break;
    case 4:
      filterObjOverviewTimeWorking.value.fromDate = undefined;
      filterObjOverviewTimeWorking.value.toDate = undefined;
      break;
    default:
      break;
  }
};

onMounted(() => {
  fetchData();
});

const fetchData = (date?: string | null) => {
  keySearch.value = '';
  isLoading.value = true;
  filterObjOverviewTimeWorking.value.toDate = dayjs()
    .utc()
    .format('YYYY-MM-DD') as any;
  filterObjOverviewTimeWorking.value.fromDate = dayjs
    .utc()
    .subtract(6, 'days')
    .format('YYYY-MM-DD') as any;
  let promise = [
    _dashboardStore.getMemberOnday(),
    _principleStore.getAllPrinciple(date),
    _principleStore.getWorkInfoByDateRange(filterObjOverviewTimeWorking.value),
  ];
  Promise.all(promise).then(() => {
    isLoading.value = false;
  });
  // console.log(usersPrinciple.value);
};

const getWorkInfoByDateRange = async (numberDate, type) => {
  isLoading.value = true;
  filterObjOverviewTimeWorking.value.toDate = dayjs()
    .utc()
    .format('YYYY-MM-DD') as any;
  filterObjOverviewTimeWorking.value.fromDate = dayjs()
    .utc()
    .subtract(numberDate, type)
    .format('YYYY-MM-DD') as any;
  await _principleStore
    .getWorkInfoByDateRange(filterObjOverviewTimeWorking.value)
    .then(() => {
      isLoading.value = false;
    });
};

const onChooseUser = async (principle: any) => {
  const userId = principle?.userId;
  const index = userTabs.value.findIndex((o: any) => o.userId == userId);
  if (index == -1) {
    if (userTabs.value?.length == 5) userTabs.value.shift();
    userTabs.value = [...userTabs.value, principle];
  }
  mode.value = 'detail';
  currentUserId.value = userId;
};

const changeTab = (user) => {
  mode.value = 'detail';
  currentUserId.value = user.userId;
};

const closeTab = (user) => {
  if (user.userId == currentUserId.value) {
    currentUserId.value = null;
    mode.value = 'generality';
  }
  userTabs.value = userTabs.value.filter((o) => o.userId !== user.userId);
};

const chooseUser = (userId) => {
  const principle = listUsersPrinciple.value.find((e) => e.userId == userId);
  onChooseUser(principle);
};

const calculateDuration = (principle: any) => {
  if (
    principle?.start &&
    principle?.pauseList.length == 0 &&
    !principle?.stop &&
    dayjs.utc().startOf('day').valueOf() ===
      dayjs.utc(principle?.idDay).startOf('day').valueOf()
  ) {
    let duration;
    const currentTime = dayjs.utc().valueOf();
    duration = currentTime - dayjs.utc(principle?.start).valueOf();
    const durationObj = dayjs(duration).valueOf();
    const hours =
      Math.floor(durationObj / 3600000) > 0
        ? `${Math.floor(durationObj / 3600000)}h`
        : '';
    const minutes = `${Math.floor((durationObj % 3600000) / 60000)}m`;
    return `${hours} ${minutes}`;
  } else if (
    principle?.start &&
    principle?.pauseList.length > 0 &&
    !principle?.stop &&
    dayjs.utc().startOf('day').valueOf() ===
      dayjs.utc(principle?.idDay).startOf('day').valueOf()
  ) {
    let duration;
    const currentTime = dayjs.utc().valueOf();
    duration =
      currentTime - dayjs.utc(principle?.pauseList[0].checkIn).valueOf();
    const totalDuration = (
      duration / (1000 * 60 * 60) +
      principle.duration
    ).toFixed(2);

    return principle?.pauseList[0].checkIn == null
      ? formatDecimal(principle.duration)
      : formatDecimal(totalDuration);
  } else
    return principle.duration > 0
      ? formatDecimal(principle.duration)
      : principle.duration;
};

const formatDecimal = (duration) => {
  const hours = Math.floor(duration);
  const minutes = Math.floor((duration % 1) * 60);
  const durationObj = dayjs.duration({ hours, minutes });
  return hours > 0
    ? durationObj.format('H[h] m[m]')
    : durationObj.format('m[m]');
};

const onReload = async () => {
  onResetDate();
  if (mode.value !== 'members') return;
  await fetchData();
};

const onChangeDate = async () => {
  if (mode.value !== 'members') return;
  const date = filterObj.value?.dateFrom
    ? dayjs(filterObj.value?.dateFrom).startOf('day').format('YYYY-MM-DD')
    : null;
  await fetchData(date);
};

const onFilterByRangeDate = async () => {
  if (!isDateValid()) return;
  isLoading.value = true;
  const obj = {
    fromDate: dayjs(filterObjOverviewTimeWorking.value.fromDate)
      .startOf('day')
      .format('YYYY-MM-DD'),
    toDate: dayjs(filterObjOverviewTimeWorking.value.toDate)
      .startOf('day')
      .format('YYYY-MM-DD'),
  };
  await _principleStore.getWorkInfoByDateRange(obj).then(() => {
    isLoading.value = false;
  });
};

const onResetFromDate = () => {
  filterObjOverviewTimeWorking.value.fromDate = undefined;
};

const onResetToDate = () => {
  filterObjOverviewTimeWorking.value.toDate = undefined;
};
const onResetDate = () => {
  filterObj.value.dateFrom = new Date();
};

const isDateValid = () => {
  return (
    dayjs(filterObjOverviewTimeWorking.value.toDate).startOf('day').valueOf() -
      dayjs(filterObjOverviewTimeWorking.value.fromDate)
        .startOf('day')
        .valueOf() >=
    0
  );
};

const onOpenTaskDetail = (taskCode) => {
  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;
};

const sortDataOrderByKey = computed(() => _principleStore.sortDataOrderByKey);

const onClickSort = (key, name) => {
  _principleStore.genSortDataList({ key, name });
  window.scrollTo(0, 0);
};

const inputSearchWorkingTime = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath.includes('working-time')
  ) {
    inputSearchWorkingTime.value?.focus();
    e.preventDefault();
  }
});
</script>
<template>
  <!-- <syn-coming-soon></syn-coming-soon> -->
  <CheckPermissionContainer :function-names="['timeWorkingEnabled']">
    <div
      v-permission-screen="{
        screenCode: ALL_SCREENS.COMMON.ADMIN.WORKING_TIME,
        isPage: true,
      }"
      class="h-full w-full pt-3 flex flex-col"
    >
      <div class="flex-1">
        <ul class="flex cursor-pointer text-sm space-x-0.5">
          <!--          GENERALITY TAB-->
          <li
            class="py-2 px-4 font-semibold rounded-t-md items-center flex"
            :class="
              mode === 'generality'
                ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
                : 'text-gray-500 bg-gray-100 '
            "
            :style="
              mode === 'generality'
                ? 'margin-bottom: -1px'
                : 'margin-bottom: 0px'
            "
            @click="
              mode = 'generality';
              keySearch = '';
            "
          >
            {{ $t('DASHBOARD_LABEL_GENERALITY') }}
          </li>

          <!--          WORKING TIME TAB-->
          <li
            class="py-2 px-4 font-semibold rounded-t-md items-center flex"
            :class="
              mode === 'members'
                ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
                : 'text-gray-500 bg-gray-100 '
            "
            :style="
              mode === 'members' ? 'margin-bottom: -1px' : 'margin-bottom: 0px'
            "
            @click="
              mode = 'members';
              keySearch = '';
              filterObj.dateFrom = new Date();
            "
          >
            {{ $t('DOCUMENT_TITLE_WORKING_TIME') }}
          </li>

          <!--          ACTIVITY TAB-->
          <li
            v-system-config="{
              functionCode: 'DRAFT_USER_ACT_USER_ACTIVITIES_FUNCTION_ALLOW',
              functionValue: '1',
            }"
            class="py-2 px-4 font-semibold rounded-t-md items-center flex"
            :class="
              mode === 'activity'
                ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
                : 'text-gray-500 bg-gray-100 '
            "
            :style="
              mode === 'activity' ? 'margin-bottom: -1px' : 'margin-bottom: 0px'
            "
            @click="
              mode = 'activity';
              keySearch = '';
              filterObj.dateFrom = new Date();
            "
          >
            {{ $t('USERMANAGEMENT_LABEL_ACTIVITY') }}
          </li>

          <!--          USER DETAIL TAB-->
          <li
            v-for="user in userTabs"
            :key="user"
            class="py-2 px-2 rounded-t-md"
            :class="
              mode === 'detail' && currentUserId === user.userId
                ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
                : 'text-gray-500 bg-gray-100'
            "
            :style="
              mode === 'detail' && currentUserId === user.userId
                ? 'margin-bottom: -1px'
                : 'margin-bottom: 0px'
            "
            @click="changeTab(user)"
          >
            <div class="flex-center space-x-2">
              <span>
                <SynAvatar
                  class="cursor-pointer text-current-800"
                  :src="user?.avatar?.replace('original', 'mini')"
                  :name="user?.fullname"
                  has-name
                  custom-class="w-6 h-6"
                />
              </span>
              <span>
                <SynIcon
                  class="hover:text-current"
                  name="close"
                  has-action
                  @click.stop="closeTab(user)"
                />
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div
        :key="mode"
        v-permission-screen="
          mode == 'activity'
            ? {
                screenCode: ALL_SCREENS.COMMON.MANAGEMENT.WORKING_TIME.ACTIVITY,
                isPage: true,
              }
            : false
        "
        class="
          h-full
          bg-white
          flex flex-col
          space-y-1
          p-2
          overflow-y-hidden
          border-t border-current-100
        "
      >
        <!-- SEARCH/ FILTER -->
        <div
          v-if="
            mode === 'members' || mode === 'generality' || mode == 'activity'
          "
          class="flex w-full items-center justify-between pt-1 pb-2"
        >
          <div class="flex space-x-2 items-center">
            <div class="flex-center w-64">
              <VigSearchBox
                ref="inputSearchWorkingTime"
                v-model="keySearch"
                class="w-full"
                :placeholder="$t('COMMON_LABEL_SEARCH_PLACEHOLDER_MEMBER')"
              />
            </div>
            <div
              v-if="mode === 'members' || mode == 'activity'"
              class="z-20 sticky flex items-center"
            >
              <v-date-picker
                v-model="filterObj.dateFrom"
                :locale="appStore().language || 'en'"
                color="current"
                :model-config="dateConfig"
                :attributes="[
                  {
                    key: 'today',
                    dot: {
                      color:
                        dayjs(new Date()).format('MM-DD-YYYY') ===
                        dayjs(filterObj.dateFrom).format('MM-DD-YYYY')
                          ? 'yellow'
                          : 'current',
                      class:
                        dayjs(new Date()).format('MM-DD-YYYY') ===
                        dayjs(filterObj.dateFrom).format('MM-DD-YYYY')
                          ? 'mb-1 z-70'
                          : '',
                    },
                    dates: new Date(),
                  },
                ]"
                :popover="{ visibility: 'click' }"
                :max-date="filterObj.dateTo"
                :first-day-of-week="2"
                @input="onChangeDate"
                @click="onChangeDate"
              >
                <template #default="{ inputValue, inputEvents }">
                  <div class="relative text-xs">
                    <input
                      type="text"
                      class="vig-input h-10"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                    <button
                      v-if="filterObj.dateFrom"
                      class="
                        absolute
                        top-1/2
                        right-1
                        -mt-3
                        w-6
                        h-6
                        flex-center
                        rounded-full
                        hover:bg-gray-100
                      "
                      @click="onResetDate"
                    >
                      <SynIcon name="Close" />
                    </button>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div
              v-else-if="mode === 'generality'"
              class="z-20 sticky flex items-center space-x-2"
            >
              <SynSelect
                v-model="selectDateDefault"
                width-class="w-42 text-sm"
                custom-class="py-2"
                is-translate
                :options="selectDateFilterList"
                key-field="key"
                text-field="text"
                @change="selectDateFilter"
              />
              <div
                v-if="selectDateDefault == 4"
                class="flex items-center space-x-2"
              >
                <v-date-picker
                  v-model="filterObjOverviewTimeWorking.fromDate"
                  :locale="appStore().language || 'en'"
                  color="current"
                  :model-config="dateConfig"
                  :attributes="[
                    {
                      key: 'today',
                      dot: {
                        color:
                          dayjs(new Date()).format('MM-DD-YYYY') ===
                          dayjs(filterObjOverviewTimeWorking.fromDate).format(
                            'MM-DD-YYYY'
                          )
                            ? 'yellow'
                            : 'current',
                        class:
                          dayjs(new Date()).format('MM-DD-YYYY') ===
                          dayjs(filterObjOverviewTimeWorking.fromDate).format(
                            'MM-DD-YYYY'
                          )
                            ? 'mb-1 z-70'
                            : '',
                      },
                      dates: new Date(),
                    },
                  ]"
                  :popover="{ visibility: 'click' }"
                  :max-date="new Date()"
                  :first-day-of-week="2"
                >
                  <template #default="{ inputValue, inputEvents }">
                    <div class="relative text-xs">
                      <input
                        type="text"
                        class="vig-input text-sm"
                        :value="inputValue"
                        :placeholder="$t('COMMON_LABEL_FROM_DATE')"
                        v-on="inputEvents"
                      />
                      <button
                        v-if="filterObjOverviewTimeWorking.fromDate"
                        class="
                          absolute
                          top-1/2
                          right-1
                          -mt-3
                          w-6
                          h-6
                          flex-center
                          rounded-full
                          hover:bg-gray-100
                        "
                        @click="onResetFromDate"
                      >
                        <SynIcon name="Close" />
                      </button>
                    </div>
                  </template>
                </v-date-picker>
                <v-date-picker
                  v-model="filterObjOverviewTimeWorking.toDate"
                  :locale="appStore().language || 'en'"
                  color="current"
                  :model-config="dateConfig"
                  :attributes="[
                    {
                      key: 'today',
                      dot: {
                        color:
                          dayjs(new Date()).format('MM-DD-YYYY') ===
                          dayjs(filterObjOverviewTimeWorking.toDate).format(
                            'MM-DD-YYYY'
                          )
                            ? 'yellow'
                            : 'current',
                        class:
                          dayjs(new Date()).format('MM-DD-YYYY') ===
                          dayjs(filterObjOverviewTimeWorking.toDate).format(
                            'MM-DD-YYYY'
                          )
                            ? 'mb-1 z-70'
                            : '',
                      },
                      dates: new Date(),
                    },
                  ]"
                  :popover="{ visibility: 'click' }"
                  :max-date="new Date()"
                  :first-day-of-week="2"
                >
                  <template #default="{ inputValue, inputEvents }">
                    <div class="relative text-xs">
                      <input
                        type="text"
                        class="vig-input text-sm"
                        :value="inputValue"
                        :placeholder="$t('COMMON_LABEL_TO_DATE')"
                        v-on="inputEvents"
                      />
                      <button
                        v-if="filterObjOverviewTimeWorking.toDate"
                        class="
                          absolute
                          top-1/2
                          right-1
                          -mt-3
                          w-6
                          h-6
                          flex-center
                          rounded-full
                          hover:bg-gray-100
                        "
                        @click="onResetToDate"
                      >
                        <SynIcon name="Close" />
                      </button>
                    </div>
                  </template>
                </v-date-picker>
                <SynButton
                  :disabled="
                    !filterObjOverviewTimeWorking.fromDate ||
                    !filterObjOverviewTimeWorking.toDate ||
                    !isDateValid()
                  "
                  class="text-sm"
                  @click="onFilterByRangeDate"
                >
                  OK
                </SynButton>
              </div>
            </div>
          </div>
          <div v-if="mode === 'members' || mode === 'activity'">
            <div v-if="mode === 'members'" class="flex items-end space-x-1">
              <span
                class="
                  text-xs
                  rounded
                  py-1
                  px-2
                  border border-current-400
                  text-current-500
                "
              >
                {{ `${$t('COMMON_LABEL_TOTAL')}: ${usersPrinciple.length}` }}
              </span>
              <span>|</span>
              <span class="rounded text-xs py-1 px-2 bg-current text-white">
                {{
                  `${$t('COMMON_LABEL_START_ONDAY')}: ${
                    usersPrinciple.filter(
                      (el) => userWorkingStatus[el?.userId]?.status === 'online'
                    ).length || 0
                  }`
                }}
              </span>
              <span class="rounded text-xs py-1 px-2 bg-orange-400 text-white">
                {{
                  `${$t('COMMON_LABEL_PAUSE_ONDAY')}: ${
                    usersPrinciple.filter(
                      (el) => userWorkingStatus[el?.userId]?.status === 'absent'
                    ).length || 0
                  }`
                }}
              </span>
              <span class="rounded text-xs py-1 px-2 bg-gray-400 text-white">{{
                $t('COMMON_LABEL_STOP_ONDAY') +
                ': ' +
                usersPrinciple.filter(
                  (el) => userWorkingStatus[el?.userId]?.status == 'offline'
                ).length
              }}</span>
            </div>
            <div v-else class="flex items-end space-x-1">
              <span
                class="
                  text-xs
                  rounded
                  py-1
                  px-2
                  border border-current-400
                  text-current-500
                "
              >
                {{
                  `${$t('COMMON_LABEL_TOTAL')}: ${userActivitiesList?.length}`
                }}
              </span>
              <span>|</span>
              <span class="rounded text-xs py-1 px-2 bg-current text-white">
                {{
                  `${$t('COMMON_LABEL_HAVE_WORKED')}: ${
                    userActivitiesList?.filter((e) => e.lastActivity)?.length ||
                    0
                  }`
                }}
              </span>
              <span class="rounded text-xs py-1 px-2 bg-gray-400 text-white">{{
                `${$t('COMMON_LABEL_NOT_WORKING')}: ${
                  userActivitiesList?.filter((e) => !e.lastActivity)?.length ||
                  0
                }`
              }}</span>
            </div>
          </div>
          <div v-else-if="mode === 'generality'" class="flex items-end">
            <div class="flex items-center space-x-2">
              <div
                v-system-config="{
                  functionCode: 'TIME_WOR_TIMEKEEP_FUNCTION_ENABLE',
                  functionValue: '1',
                }"
                v-permission-function="{
                  functionCode: ALL_FUNCTIONS.WK_TIME.EXPORT_TW,
                  onContinue: () => (isOpenExportWTModal = true),
                }"
              >
                <button
                  v-vig-tooltip="
                    $t('COMMON_LABEL_EXPORT_FILE_TIMEKEEPING') ||
                    'Export timekeeping file'
                  "
                  class="
                    flex-center
                    space-x-1
                    rounded-md
                    bg-gray-100
                    hover:bg-gray-200
                    px-2
                    py-1.5
                  "
                  @click="isOpenExportWTModal = true"
                >
                  <SynIcon name="export" class="w-4 h-4 fill-gray-500" />
                  <span class="text-sm text-gray-500">{{
                    $t('COMMON_LABEL_EXPORT_FILE_TIMEKEEPING')
                  }}</span>
                </button>
              </div>

              <button
                v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                class="
                  flex-center
                  rounded-md
                  bg-gray-100
                  hover:bg-gray-200
                  fill-gray-500
                  p-2
                "
                @click="
                  selectDateDefault == 4
                    ? onFilterByRangeDate()
                    : selectDateFilter(selectDateDefault)
                "
              >
                <SynIcon name="reload" class="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>

        <FilterWorkingTimeDetail
          v-else
          :user-id="currentUserId"
          :user-detail="userTabs?.find((x) => x.userId == currentUserId)"
          :user-tab="userTabs"
          class="w-full pb-2"
          @loading="loadingWorkingTimeDetail"
        />

        <div class="flex-1 overflow-y-auto small-scrollbar">
          <!-- TABLE SHOW LIST USER ON DASHBOARD -->
          <syn-table-custom v-if="mode == 'members'" class="rounded">
            <template #header>
              <syn-tr>
                <SynTh
                  :label="$t('COMMON_LABEL_MEMBERS')"
                  is-sort
                  :order-data="sortDataOrderByKey['fullName']"
                  @on-sort="onClickSort('fullName', $t('COMMON_LABEL_MEMBERS'))"
                ></SynTh>
                <!--                <SynTh-->
                <!--                  :label="$t('DASHBOARD_TABLE_LABEL_TIME_UTC')"-->
                <!--                  is-sort-->
                <!--                  :order-data="sortDataOrderByKey['utcZone']"-->
                <!--                  @on-sort="-->
                <!--                    onClickSort('utcZone', $t('DASHBOARD_TABLE_LABEL_TIME_UTC'))-->
                <!--                  "-->
                <!--                ></SynTh>-->
                <SynTh :label="$t('OVERVIEW_LABEL_START_TO_WORK')"></SynTh>
                <SynTh
                  :label="$t('DASHBOARD_LABEL_DESCRIPTION_PAUSE_TIME')"
                ></SynTh>
                <SynTh :label="$t('OVERVIEW_LABEL_STOP_TO_WORK')"></SynTh>
                <SynTh
                  :label="$t('DASHBOARD_TABLE_LABEL_DUR') + '.(h)'"
                  is-sort
                  :order-data="sortDataOrderByKey['duration']"
                  @on-sort="
                    onClickSort('duration', $t('DASHBOARD_TABLE_LABEL_DUR'))
                  "
                ></SynTh>
                <SynTh :label="$t('LABEL_STATISTIC_TASK_ON_DAY')"></SynTh>
                <!--                <SynTh-->
                <!--                  :label="-->
                <!--                    $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_ON_DAY')-->
                <!--                  "-->
                <!--                  is-sort-->
                <!--                  :order-data="sortDataOrderByKey['countTaskOnday']"-->
                <!--                  @on-sort="-->
                <!--                    onClickSort(-->
                <!--                      'countTaskOnday',-->
                <!--                      $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_ON_DAY')-->
                <!--                    )-->
                <!--                  "-->
                <!--                ></SynTh>-->
                <!--                <SynTh-->
                <!--                  :label="-->
                <!--                    $t(-->
                <!--                      'DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_FINISH_TODAY'-->
                <!--                    )-->
                <!--                  "-->
                <!--                  is-sort-->
                <!--                  :order-data="sortDataOrderByKey['countTaskOnFinish']"-->
                <!--                  @on-sort="-->
                <!--                    onClickSort(-->
                <!--                      'countTaskOnFinish',-->
                <!--                      $t(-->
                <!--                        'DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_FINISH_TODAY'-->
                <!--                      )-->
                <!--                    )-->
                <!--                  "-->
                <!--                ></SynTh>-->
                <SynTh>
                  <button
                    v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                    class="
                      flex-center
                      rounded-full
                      hover:fill-current
                      fill-gray-500
                      w-6
                      h-6
                    "
                    @click="onReload"
                  >
                    <SynIcon name="reload" class="w-4 h-4" />
                  </button>
                </SynTh>
              </syn-tr>

              <!-- <syn-table-th>
                                                                                                                                              {{ $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_PENDING') }}
                                                                                                                                            </syn-table-th> -->
            </template>
            <template #body>
              <syn-table-tr-loading v-if="isLoading">
                <div v-for="item in 7" :key="item" class="table-cell p-2">
                  <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
              </syn-table-tr-loading>

              <template
                v-for="principle in usersPrinciple"
                v-else-if="usersPrinciple.length > 0"
                :key="principle.userId"
              >
                <syn-tr
                  v-if="usersPrinciple.length > 0"
                  class="hover:bg-gray-50 hover:text-current-500 cursor-pointer"
                  @click="onChooseUser(principle)"
                >
                  <!--                  <syn-td v-if="isShowOrderStartWD()" style="width: 50px">-->
                  <!--                    <div-->
                  <!--                      class="-->
                  <!--                        w-6-->
                  <!--                        h-6-->
                  <!--                        rounded-full-->
                  <!--                        flex-center-->
                  <!--                        font-semibold-->
                  <!--                        text-white-->
                  <!--                        bg-green-500-->
                  <!--                      "-->
                  <!--                    >-->
                  <!--                      {{ principle.startIndex }}-->
                  <!--                    </div>-->
                  <!--                  </syn-td>-->
                  <syn-td style="width: 350px">
                    <span
                      class="
                        cursor-pointer
                        flex
                        items-center
                        space-x-2
                        font-medium
                      "
                    >
                      <syn-avatar-notification
                        :src="
                          principle?.avatar?.replace('original', 'mini') ||
                          principle?.avatarUrl?.replace('original', 'mini')
                        "
                        custom-class="w-8 h-8"
                        :name="principle.fullname"
                        has-name
                        :status="userWorkingStatus[principle?.userId]?.status"
                      >
                      </syn-avatar-notification>
                      <span>{{ principle.fullname }}</span>
                    </span>
                  </syn-td>

                  <!--                  <syn-td style="width: 200px">-->
                  <!--                    <syn-tag-->
                  <!--                      class="w-max bg-current-50"-->
                  <!--                      position="flex item-center justify-center"-->
                  <!--                    >-->
                  <!--                      {{-->
                  <!--                        $filters.dayjsByZone(-->
                  <!--                          new Date(),-->
                  <!--                          principle?.utcZone || '7',-->
                  <!--                          'hh:mm A'-->
                  <!--                        )-->
                  <!--                      }}-->
                  <!--                    </syn-tag>-->
                  <!--                  </syn-td>-->

                  <syn-td>
                    <div
                      v-if="principle.start"
                      class="
                        border
                        flex
                        items-center
                        rounded-full
                        bg-white
                        w-28
                        py-1
                        space-x-2
                        px-1
                      "
                    >
                      <div
                        class="w-6 h-6 bg-current-500 flex-center rounded-full"
                      >
                        <SynIcon
                          name="start"
                          custom-class="w-3 h-3 fill-white text-white"
                        />
                      </div>
                      <span class="ms-2">
                        <SynLabelDateTime
                          format="time"
                          :datetime="principle.start"
                        />
                      </span>
                    </div>
                    <div
                      v-else
                      class="
                        border
                        rounded-full
                        bg-gray-50
                        w-28
                        flex
                        items-center
                        py-1
                        px-1
                        space-x-4
                      "
                    >
                      <div
                        class="
                          w-6
                          h-6
                          bg-current-500 bg-opacity-70
                          flex-center
                          rounded-full
                        "
                      >
                        <SynIcon
                          name="start"
                          custom-class="w-3 h-3 fill-white text-white"
                        />
                      </div>
                      <span> --:-- </span>
                    </div>
                  </syn-td>

                  <syn-td>
                    <time-working
                      :pause-list="principle.pauseList"
                      :timezone="principle?.utcZone"
                      :time-limit="principle?.timeLimit"
                    >
                    </time-working>
                  </syn-td>

                  <syn-td>
                    <div
                      v-if="principle.stop"
                      class="
                        border
                        flex
                        items-center
                        rounded-full
                        bg-white
                        w-28
                        py-1
                        space-x-2
                        px-1
                      "
                    >
                      <div class="w-6 h-6 bg-gray-600 flex-center rounded-full">
                        <SynIcon
                          name="stop-video"
                          custom-class="w-3 h-3 fill-white text-white"
                        />
                      </div>
                      <span class="ms-2">
                        <SynLabelDateTime
                          format="time"
                          :datetime="principle.stop"
                        />
                      </span>
                    </div>
                    <div
                      v-else
                      class="
                        border
                        rounded-full
                        bg-gray-50
                        w-28
                        flex
                        items-center
                        py-1
                        px-1
                        space-x-4
                      "
                    >
                      <div class="w-6 h-6 bg-gray-500 flex-center rounded-full">
                        <SynIcon
                          name="stop-video"
                          custom-class="w-3 h-3 fill-white text-white"
                        />
                      </div>
                      <span> --:-- </span>
                    </div>
                  </syn-td>

                  <!--                  <syn-td class="w-full flex justify-start">-->
                  <!--                    <time-working-->
                  <!--                      :start-time="principle.start"-->
                  <!--                      :pause-list="principle.pauseList"-->
                  <!--                      :stop-time="principle.stop"-->
                  <!--                      :timezone="principle?.utcZone"-->
                  <!--                    ></time-working>-->
                  <!--                  </syn-td>-->

                  <syn-td class="" style="width: 200px">
                    <span class="ms-4">{{ calculateDuration(principle) }}</span>
                  </syn-td>

                  <syn-td style="width: 300px">
                    <div class="flex items-center space-x-3">
                      <div v-if="principle.countTaskOnday > 0" class="">
                        <VigDropdown class="relative" trigger="mouseenter">
                          <template #dropdown-toggle>
                            <div
                              class="
                                bg-blue-100
                                rounded-md
                                flex
                                align-center
                                space-x-1
                                p-1
                              "
                            >
                              <div
                                class="
                                  h-5
                                  w-5
                                  rounded-md
                                  bg-blue-500
                                  text-white
                                  flex-center
                                "
                              >
                                {{ principle.countTaskOnday }}
                              </div>
                              <span class="text-blue-800">{{
                                $t('TASK_LIST_VIEW_DO_TODAY_LABEL')
                              }}</span>
                            </div>
                          </template>
                          <template #dropdown-menu>
                            <div
                              class="
                                w-max
                                bg-white
                                rounded-md
                                flex flex-col
                                item-center
                                justify-center
                                overflow-auto
                                small-scrollbar
                              "
                              style="max-height: 400px"
                            >
                              <div
                                v-for="item in principle.taskCodesOnday"
                                :key="item"
                                class="
                                  py-2
                                  px-3
                                  border-b
                                  cursor-pointer
                                  dropdown-item
                                  hover:bg-gray-50
                                "
                              >
                                <div
                                  class="text-current-500"
                                  @click="onOpenTaskDetail(item)"
                                >
                                  <span class="text-xs font-bold">
                                    {{ item }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </VigDropdown>
                      </div>
                      <div
                        v-if="principle.countTaskOnFinish > 0"
                        class="flex-center"
                      >
                        <VigDropdown class="relative" trigger="mouseenter">
                          <template #dropdown-toggle>
                            <div
                              class="
                                bg-red-100
                                rounded-md
                                flex
                                align-center
                                space-x-1
                                p-1
                              "
                            >
                              <div
                                class="
                                  h-5
                                  w-5
                                  rounded-md
                                  bg-red-500
                                  text-white
                                  flex-center
                                "
                              >
                                {{ principle.countTaskOnFinish }}
                              </div>
                              <span class="text-red-800">{{
                                $t('TASK_CREATE_FORM_FINISH_TODAY')
                              }}</span>
                            </div>
                          </template>
                          <template #dropdown-menu>
                            <div
                              class="
                                w-max
                                bg-white
                                rounded-md
                                flex flex-col
                                item-center
                                justify-center
                                overflow-auto
                                small-scrollbar
                              "
                              style="max-height: 400px"
                            >
                              <div
                                v-for="item in principle.taskCodesOnFinish"
                                :key="item"
                                class="
                                  py-2
                                  px-3
                                  border-b
                                  cursor-pointer
                                  dropdown-item
                                  hover:bg-gray-50
                                "
                              >
                                <div
                                  class="text-current-500"
                                  @click="onOpenTaskDetail(item)"
                                >
                                  <span class="text-xs font-bold">
                                    {{ item }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </VigDropdown>
                      </div>
                    </div>
                  </syn-td>
                  <!--                  <syn-td style="width: 150px">-->
                  <!--                  </syn-td>-->
                  <syn-td style="width: 50px"></syn-td>
                </syn-tr>
                <syn-tr
                  v-else
                  class="h-full border-b-none"
                  hover="hover:bg-current-50"
                  :class="index % 2 == 0 ? 'bg-blue-50' : ''"
                >
                  <syn-td :colspan="6">
                    <syn-animation
                      name="searchNotFound"
                      stype="width: 200px; height: 200px"
                    ></syn-animation>
                  </syn-td>
                </syn-tr>
              </template>
              <template v-else>
                <syn-tr class="h-full" hover="hover:bg-current-50">
                  <syn-td :colspan="6" style="height: calc(100vh - 20rem)">
                    <syn-animation
                      name="searchIcon"
                      stype="width: 200px; height: 200px"
                      :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                    />
                  </syn-td>
                </syn-tr>
              </template>
            </template>
          </syn-table-custom>

          <!--            OVERVIEW MEMBER'S TIME WORKING -->
          <OverviewTimeWorkingMembersByDate
            v-else-if="mode == 'generality'"
            :overview-list="usersWorkInfoList"
            :is-loading="isLoading"
            @choose-user="chooseUser"
          >
          </OverviewTimeWorkingMembersByDate>

          <OverviewLastActivityByDate
            v-else-if="mode == 'activity'"
            :key-search="keySearch"
            :date-filter="filterObj.dateFrom"
            @emit-user-activities="(value) => (userActivitiesList = value)"
          />

          <!-- TABLE SHOW ONE USER -->
          <syn-table-custom v-else class="rounded" style="width: 100%">
            <template #header>
              <syn-tr>
                <syn-th :label="$t('DASHBOARD_TABLE_LABEL_DATE')"></syn-th>
                <!--                <syn-th :label="$t('DASHBOARD_TABLE_LABEL_TIME_WD')"></syn-th>-->
                <SynTh :label="$t('OVERVIEW_LABEL_START_TO_WORK')"></SynTh>
                <SynTh
                  :label="$t('DASHBOARD_LABEL_DESCRIPTION_PAUSE_TIME')"
                ></SynTh>
                <SynTh :label="$t('OVERVIEW_LABEL_STOP_TO_WORK')"></SynTh>
                <syn-th
                  :label="$t('DASHBOARD_TABLE_LABEL_DUR') + '.(h)'"
                ></syn-th>
                <syn-th
                  :label="
                    $t('DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_ON_DAY')
                  "
                ></syn-th>
                <syn-th
                  :label="
                    $t(
                      'DASHBOARD_TABLE_LABEL_DASHBOARD_TABLE_LABEL_FINISH_TODAY'
                    )
                  "
                ></syn-th>
              </syn-tr>
            </template>
            <template #body>
              <syn-table-tr-loading v-if="isLoading">
                <div v-for="item in 5" :key="item" class="table-cell p-2">
                  <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
              </syn-table-tr-loading>
              <template
                v-for="principle in detailByUserId[currentUserId]"
                v-else
                :key="principle.idDay"
              >
                <syn-tr>
                  <syn-td style="width: 250px">
                    <syn-tag class="bg-current-50" style="width: 80%">
                      {{ $filters.dayjsFormatFullTime(principle?.idDay, lang) }}
                    </syn-tag>
                  </syn-td>
                  <!--                  <syn-td style="width: 650px">-->
                  <!--                    <time-working-->
                  <!--                      style="width: 90%"-->
                  <!--                      :start-time="principle.start"-->
                  <!--                      :pause-list="principle.pauseList"-->
                  <!--                      :stop-time="principle.stop"-->
                  <!--                      :timezone="principle?.utcZone"-->
                  <!--                    ></time-working>-->
                  <!--                  </syn-td>-->
                  <syn-td>
                    <div
                      v-if="principle.start"
                      class="
                        border
                        flex
                        items-center
                        rounded-full
                        bg-white
                        w-28
                        py-1
                        space-x-2
                        px-1
                      "
                    >
                      <div
                        class="w-6 h-6 bg-current-500 flex-center rounded-full"
                      >
                        <SynIcon
                          name="start"
                          custom-class="w-3 h-3 fill-white text-white"
                        />
                      </div>
                      <span class="ms-2">
                        <SynLabelDateTime
                          format="time"
                          :datetime="principle.start"
                        />
                      </span>
                    </div>
                    <div
                      v-else
                      class="
                        border
                        rounded-full
                        bg-gray-50
                        w-28
                        flex
                        items-center
                        py-1
                        px-1
                        space-x-4
                      "
                    >
                      <div
                        class="
                          w-6
                          h-6
                          bg-current-500 bg-opacity-70
                          flex-center
                          rounded-full
                        "
                      >
                        <SynIcon
                          name="start"
                          custom-class="w-3 h-3 fill-white text-white"
                        />
                      </div>
                      <span> --:-- </span>
                    </div>
                  </syn-td>

                  <syn-td>
                    <time-working
                      :pause-list="principle.pauseList"
                      :timezone="principle?.utcZone"
                      :time-limit="principle?.timeLimit"
                    >
                    </time-working>
                  </syn-td>

                  <syn-td>
                    <div
                      v-if="principle.stop"
                      class="
                        border
                        flex
                        items-center
                        rounded-full
                        bg-white
                        w-28
                        py-1
                        space-x-2
                        px-1
                      "
                    >
                      <div class="w-6 h-6 bg-gray-600 flex-center rounded-full">
                        <SynIcon
                          name="stop-video"
                          custom-class="w-3 h-3 fill-white text-white"
                        />
                      </div>
                      <span class="ms-2">
                        <SynLabelDateTime
                          format="time"
                          :datetime="principle.stop"
                        />
                      </span>
                    </div>
                    <div
                      v-else
                      class="
                        border
                        rounded-full
                        bg-gray-50
                        w-28
                        flex
                        items-center
                        py-1
                        px-1
                        space-x-4
                      "
                    >
                      <div class="w-6 h-6 bg-gray-500 flex-center rounded-full">
                        <SynIcon
                          name="stop-video"
                          custom-class="w-3 h-3 fill-white text-white"
                        />
                      </div>
                      <span> --:-- </span>
                    </div>
                  </syn-td>
                  <syn-td class="" style="width: 150px">
                    <span class="ms-4">{{ calculateDuration(principle) }}</span>
                  </syn-td>
                  <syn-td class="text-left" style="width: 150px">
                    <div
                      v-if="principle?.totalTaskOnDayList.length > 0"
                      class="relative w-full"
                    >
                      <div class="flex-center">
                        <VigDropdown class="relative" trigger="mouseenter">
                          <template #dropdown-toggle>
                            <syn-tag
                              position="flex item-center justify-center"
                              class="
                                bg-current-50
                                w-max
                                items-center
                                cursor-pointer
                              "
                              size="small"
                            >
                              {{ principle?.totalTaskOnDayList.length }}
                            </syn-tag>
                          </template>
                          <template #dropdown-menu>
                            <div
                              class="
                                w-max
                                bg-white
                                rounded-md
                                flex flex-col
                                item-center
                                justify-center
                                overflow-auto
                                small-scrollbar
                              "
                              style="max-height: 400px"
                            >
                              <div
                                v-for="item in principle.totalTaskOnDayList"
                                :key="item"
                                class="
                                  py-2
                                  px-3
                                  border-b
                                  cursor-pointer
                                  dropdown-item
                                  hover:bg-gray-50
                                "
                              >
                                <div
                                  class="text-current-500"
                                  @click="onOpenTaskDetail(item.code)"
                                >
                                  <span class="text-xs font-bold">
                                    {{ item.code }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </VigDropdown>
                      </div>
                    </div>
                  </syn-td>
                  <syn-td class="text-left" style="width: 150px">
                    <div
                      v-if="principle?.totalTaskFinishedOnDayList.length > 0"
                      class="relative w-full"
                    >
                      <div class="flex-center">
                        <VigDropdown class="relative" trigger="mouseenter">
                          <template #dropdown-toggle>
                            <syn-tag
                              position="flex item-center justify-center"
                              class="bg-current-50 w-max items-center"
                              size="small"
                            >
                              {{ principle?.totalTaskFinishedOnDayList.length }}
                            </syn-tag>
                          </template>
                          <template #dropdown-menu>
                            <div
                              class="
                                w-max
                                bg-white
                                rounded-md
                                flex flex-col
                                item-center
                                justify-center
                                overflow-auto
                                small-scrollbar
                              "
                              style="max-height: 400px"
                            >
                              <div
                                v-for="item in principle?.totalTaskFinishedOnDayList"
                                :key="item"
                                class="
                                  py-2
                                  px-3
                                  border-b
                                  cursor-pointer
                                  dropdown-item
                                  hover:bg-gray-50
                                "
                              >
                                <div
                                  class="text-current-500"
                                  @click="onOpenTaskDetail(item.code)"
                                >
                                  <span class="text-xs font-bold">
                                    {{ item.code }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </VigDropdown>
                      </div>
                    </div>
                  </syn-td>
                </syn-tr>
              </template>
            </template>
          </syn-table-custom>
        </div>
      </div>
    </div>
  </CheckPermissionContainer>

  <ModalExportWorkingTimeFile
    v-if="isOpenExportWTModal"
    :overview-list="usersWorkInfoList"
    @on-close="isOpenExportWTModal = false"
  />
</template>
