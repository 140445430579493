<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import InviteUserModal from '@/ui/modules/member/invite-user/InviteUserModal.vue';
import organizationStore from '@/store/organization';
import { gotoDefaultPageByUserRole } from '@/ui/hooks/commonHook';
import { SettingProgressEnum } from '@/ui/common/constants/constant';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

const _organizationStore = organizationStore();
const router = useRouter();
onMounted(() => {
  const settingProgress =
    MyOrganizationSingleton.getInstance().getOrganizationSettingProgress();

  if (settingProgress == SettingProgressEnum.SettingFinished) {
    gotoDefaultPageByUserRole(router);
  }
});

const onInviteSuccess = () => {
  MyOrganizationSingleton.setOrganizationSettingProgress(
    SettingProgressEnum.SettingFinished
  );
  setTimeout(() => {
    location.reload();
  }, 3000);
};
const onFetching = ref(false);
const onSkipInviteStep = async () => {
  if (onFetching.value) return;
  onFetching.value = true;
  try {
    await _organizationStore.skipAddMember();
  } catch (error) {
    console.log(
      '🚀 ~ file: org-invite-user.vue ~ line 66 ~ onSkipInviteStep ~ error',
      error
    );
    onFetching.value = false;
  }
  // Skip success
  onInviteSuccess();
};
</script>

<template>
  <div class="h-screen w-screen bg-current-200">
    <!-- <syn-img class="w-full h-full object-fill" :src="bg_url" /> -->
  </div>
  <InviteUserModal
    is-hidden-close
    cancel-code="COMMON_LABEL_SKIP_THIS_STEP"
    @on-invite-success="onInviteSuccess"
    @on-cancel="onSkipInviteStep"
  />
</template>
