import BaseModel from './BaseModel';
import {
    collection,
    doc,
    DocumentData,
    DocumentReference,
    onSnapshot,
    setDoc,
    updateDoc,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export default class UserConversationDocModel extends BaseModel {
    docRef: DocumentReference<DocumentData>;
    userId: number;
    organizationId: number;
    conversationId: string;
    constructor(userId: number, conversationId: string) {
        super();

        this.organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;
        this.userId = userId;
        this.conversationId = conversationId;
        this.docRef = doc(
            collection(
                doc(
                    collection(
                        doc(
                            collection(firestoreDb, 'organizationUsers'),
                            `${this.organizationId}`
                        ),
                        'users'
                    ),
                    `${userId}`
                ),
                'conversations'
            ),
            `${conversationId}`
        );
    }

    subscribleDataChange(callback) {
        return onSnapshot(this.docRef, (snapshot) => {
            callback && callback(snapshot.data());
        });
    }

    async getOnce() {
        const docSnap = await this.getDoc(this.docRef);

        if (docSnap.exists()) return docSnap.data();

        return {};
    }

    setUnSeen(totalUnseen) {
        const promiseAll = [updateDoc(this.docRef, { unSeen: totalUnseen })];
        if (totalUnseen == 0) {
            const organizationId =
                MyOrganizationSingleton.getInstance().activeOrganization;

            const userDoc = doc(
                collection(
                    doc(
                        collection(firestoreDb, 'organizationUsers'),
                        `${organizationId}`
                    ),
                    'users'
                ),
                `${this.userId}`
            );
            promiseAll.push(
                setDoc(
                    userDoc,
                    {
                        unSeenConversations: {
                            [`${this.conversationId}`]: false,
                        },
                    },
                    { merge: true }
                )
            );
        }
        return Promise.all(promiseAll);
    }

    setSeenAll() {
        const organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        const userDoc = doc(
            collection(
                doc(
                    collection(firestoreDb, 'organizationUsers'),
                    `${organizationId}`
                ),
                'users'
            ),
            `${this.userId}`
        );
        return Promise.all([
            updateDoc(this.docRef, { unSeen: 0 }),
            setDoc(
                userDoc,
                { unSeenConversations: { [`${this.conversationId}`]: false } },
                { merge: true }
            ),
        ]);
    }

    archive() {
        const userDoc = doc(
            collection(
                doc(
                    collection(firestoreDb, 'organizationUsers'),
                    `${this.organizationId}`
                ),
                'users'
            ),
            `${this.userId}`
        );

        return Promise.all([
            updateDoc(this.docRef, { closed: true }),
            setDoc(
                userDoc,
                { unSeenConversations: { [`${this.conversationId}`]: false } },
                { merge: true }
            ),
        ]);
    }
    unArchive() {
        return updateDoc(this.docRef, { closed: false });
    }

    setLastMessage(lastMessage) {
        const updateData = {
            lastMessage: `${lastMessage}`,
        };

        return this.updateDoc(this.docRef, updateData);
    }
}
