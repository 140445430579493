<script setup lang="ts">
withDefaults(
  defineProps<{
    isEditable?: boolean;
    isEdit?: boolean;
    isSubmitting?: boolean;
    isDisabledSubmitButton?: boolean;
    title?: string;
    customClass?: string;
  }>(),
  {
    title: '',
    customClass: 'overflow-y-scroll small-scrollbar',
  }
);
const emit = defineEmits<{
  (e: 'update:isEdit', value: boolean): void;
  (e: 'onClickSave'): void;
  (e: 'cancel'): void;
}>();

const updateEditMode = (status) => {
  emit('update:isEdit', status);
};
const onCancelEdit = () => {
  updateEditMode(false);
  emit('cancel');
};
</script>
<template>
  <div class="flex flex-1 min-h-0 flex-col h-full">
    <div
      class="
        h-12
        flex
        py-2
        px-4
        rounded-t-md
        items-center
        bg-current-50
        justify-between
        space-x-1
      "
    >
      <div class="flex-1">
        <slot name="header">
          <span class="font-semibold text-current-500">
            {{ title }}
          </span>
        </slot>
      </div>
      <div v-if="isEditable" class="flex items-center rounded-md">
        <div
          v-if="!isEdit"
          v-vig-tooltip="$t('COMMON_LABEL_EDIT')"
          class="
            hover:bg-current-100
            p-2
            rounded-full
            flex
            items-center
            cursor-pointer
            justify-center
          "
          @click="updateEditMode(true)"
        >
          <SynIcon name="edit" class="fill-current" is-active />
        </div>
        <div v-else class="text-xs flex space-x-2">
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
            type-text
            color="gray"
            :label="$t('COMMON_LABEL_CANCEL')"
            @click="onCancelEdit"
          />
          <SynButton
            v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
            :label="$t('COMMON_LABEL_SAVE')"
            :is-loading="isSubmitting"
            :disabled="isDisabledSubmitButton"
            @click="$emit('onClickSave')"
          />
        </div>
      </div>
    </div>

    <div
      class="
        flex-1
        min-h-0
        p-2
        bg-white
        text-sm
        border border-gray-100
        flex
        space-x-4
      "
      :class="[customClass]"
    >
      <slot name="body"></slot>
    </div>
  </div>
</template>
