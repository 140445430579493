import { UserShortInfo } from './UserPresentClass';

export default class ProjectPresentClass {
    creationTime: string;
    creatorId: number;
    description: string;
    domainId: number;
    domainName: string;
    id: number;
    lastModificationTime: string;
    lastModifierId: number;
    lastModifierLoginName: string;
    name: string;
    parentId: number;
    parentName: string;
    status: number;
    statusDescription: string;
    creator: UserShortInfo;
    type: string;
    members: UserShortInfo[];
    subProjects: ProjectPresentClass[];

    constructor(data: any) {
        this.creationTime = data?.creationTime;
        this.creatorId = data?.creatorId;
        this.description = data?.description;
        this.domainId = data?.domainId;
        this.domainName = data?.domainName;
        this.id = data?.id;
        this.lastModificationTime = data?.lastModificationTime;
        this.lastModifierId = data?.lastModifierId;
        this.lastModifierLoginName = data?.lastModifierLoginName;
        this.name = data?.name;
        this.parentId = data?.parentId;
        this.parentName = data?.parentName;
        this.status = data?.status;
        this.statusDescription = data?.statusDescription;
        this.creator = new UserShortInfo(data?.creator);
        this.type = data?.type || 'domain';
        this.members =
            data?.members?.length > 0
                ? data?.members.reduce((old, current) => {
                      return {
                          ...old,
                          [current.id]: current,
                      };
                  }, {})
                : {};
        this.subProjects =
            data?.subProjects?.length > 0
                ? data?.subProjects.reduce((old, current) => {
                      return {
                          ...old,
                          [current.id]: current,
                      };
                  }, {})
                : {};
    }
}
