import { INotificationSettingService } from '@/domain/interfaces/services/INotificationSettingService';
import NotificationSettingRepository from '@/application/repositories/NotificationSettingRepository';

export default class NotificationSettingService
    implements INotificationSettingService
{
    private static instance: NotificationSettingService;

    _fileStorageRepository: NotificationSettingRepository;
    constructor() {
        this._fileStorageRepository =
            NotificationSettingRepository.getInstance();
    }

    public static getInstance(): NotificationSettingService {
        if (!NotificationSettingService.instance) {
            // Get from local storage
            NotificationSettingService.instance =
                new NotificationSettingService();
        }

        return NotificationSettingService.instance;
    }

    getSettingNotification(refId, scope) {
        return this._fileStorageRepository.getSettingNotification(refId, scope);
    }
    setSettingNotification(data) {
        return this._fileStorageRepository.setSettingNotification(data);
    }
    async enableSettingNotification(data) {
        return this._fileStorageRepository.enableSettingNotification(data);
    }
    getUserNotificationSettingInGroups() {
        return this._fileStorageRepository.getUserNotificationSettingInGroups();
    }
    notificationNegative(data) {
        return this._fileStorageRepository.notificationNegative(data);
    }
    getFolderSettingNotification(data) {
        return this._fileStorageRepository.getFolderSettingNotification(data);
    }
    setFolderSettingNotification(data) {
        return this._fileStorageRepository.setFolderSettingNotification(data);
    }
    turnOffFolderSettingNotification(data) {
        return this._fileStorageRepository.turnOffFolderSettingNotification(
            data
        );
    }
    reactionOnMessage(data) {
        return this._fileStorageRepository.reactionOnMessage(data);
    }
}
