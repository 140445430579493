<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.468-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.467-5.5-5.5-5.5z"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M17.5 21a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v4a.5.5 0 0 1-.5.5z"
        opacity="1"
        data-original="#000000"
      ></path>
      <circle
        cx="17.5"
        cy="14.5"
        r=".5"
        opacity="1"
        data-original="#000000"
      ></circle>
      <path
        d="M9.5 21h-7A2.503 2.503 0 0 1 0 18.5v-13C0 4.121 1.122 3 2.5 3h2a.5.5 0 0 1 0 1h-2C1.673 4 1 4.673 1 5.5v13c0 .827.673 1.5 1.5 1.5h7a.5.5 0 0 1 0 1z"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M11.5 6h-6C4.673 6 4 5.327 4 4.5v-2a.5.5 0 0 1 .5-.5h1.55C6.283.86 7.292 0 8.5 0s2.217.86 2.45 2h1.55a.5.5 0 0 1 .5.5v2c0 .827-.673 1.5-1.5 1.5zM5 3v1.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V3h-1.5a.5.5 0 0 1-.5-.5C10 1.673 9.327 1 8.5 1S7 1.673 7 2.5a.5.5 0 0 1-.5.5zM13.5 9h-10a.5.5 0 0 1 0-1h10a.5.5 0 0 1 0 1zM10.5 12h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM8.5 15h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1z"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M16.5 9a.5.5 0 0 1-.5-.5v-3c0-.827-.673-1.5-1.5-1.5h-2a.5.5 0 0 1 0-1h2C15.878 3 17 4.121 17 5.5v3a.5.5 0 0 1-.5.5z"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>
