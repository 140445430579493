<script setup lang="ts">
import { ref, inject } from 'vue';
// import dayjs from 'dayjs';

defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(['update:modelValue']);
const dayjs: any = inject('dayjs');
const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const DAYS = [
  'COMMON_LABEL_SUN',
  'COMMON_LABEL_MON',
  'COMMON_LABEL_TUE',
  'COMMON_LABEL_WED',
  'COMMON_LABEL_THU',
  'COMMON_LABEL_FRI',
  'COMMON_LABEL_SAT',
];

const showDatepicker = ref(false);
const datepickerValue = ref('');

const month = ref(0);
const year = ref(0);
const no_of_days = ref([] as any[]);
const blankdays = ref([] as any[]);

const initDate = () => {
  let today = new Date();
  month.value = today.getMonth();
  year.value = today.getFullYear();
  datepickerValue.value = dayjs(
    new Date(year.value, month.value, today.getDate()),
    'DD/MM/YYYY'
  );
};
initDate();

const isToday = (date) => {
  const today = new Date();
  const d = new Date(year.value, month.value, date);

  return today.toDateString() === d.toDateString() ? true : false;
};
const getDateValue = (date) => {
  let selectedDate = new Date(year.value, month.value, date);
  datepickerValue.value = dayjs(selectedDate, 'DD/MM/YYYY');
  // emit(
  //   "update:modelValue",
  //   `${selectedDate.getFullYear()}-${("0" + selectedDate.getMonth()).slice(
  //     -2
  //   )}-${("0" + selectedDate.getDate()).slice(-2)}`
  // );
  emit('update:modelValue', selectedDate);
  showDatepicker.value = false;
};

const getNoOfDays = () => {
  let daysInMonth = new Date(year.value, month.value + 1, 0).getDate();

  // find where to start calendar day of week
  let dayOfWeek = new Date(year.value, month.value).getDay();
  let blankdaysArray = [] as any[];
  for (var i = 1; i <= dayOfWeek; i++) {
    blankdaysArray.push(i);
  }

  let daysArray = [] as any[];
  for (let i = 1; i <= daysInMonth; i++) {
    daysArray.push(i);
  }

  blankdays.value = blankdaysArray;
  no_of_days.value = daysArray;
};
getNoOfDays();
const positionTop = ref(0);
const positionLeft = ref(0);
const onShowDatepicker = (event) => {
  const position = event.target.getBoundingClientRect();
  const parentBottom = screen.height - position.top;
  if (parentBottom < 320) {
    positionTop.value = position.top - 320;
  } else {
    positionTop.value = position.top;
  }
  positionLeft.value = position.left;
  showDatepicker.value = true;
};
</script>

<template>
  <div class="antialiased sans-serif">
    <div>
      <div class="container mx-auto">
        <div class="w-64 relative">
          <div>
            <input type="hidden" name="date" :value="modelValue" />
            <input
              v-model="datepickerValue"
              type="text"
              readonly
              class="
                w-full
                pl-4
                pr-10
                py-3
                leading-none
                rounded-lg
                shadow-sm
                focus:outline-none focus:shadow-outline
                text-gray-800
                font-medium
                border-current
              "
              placeholder="Select date"
              @click="onShowDatepicker"
              @keydown="showDatepicker = false"
            />

            <div class="absolute top-0 right-0 px-3 py-2">
              <svg
                class="h-6 w-6 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>

            <!-- <div x-text="no_of_days.length"></div>
                          <div x-text="32 - new Date(year, month, 32).getDate()"></div>
                          <div x-text="new Date(year, month).getDay()"></div> -->

            <teleport to="body">
              <div
                v-if="showDatepicker"
                class="fixed inset-0 h-full w-full z-20"
                @click="showDatepicker = false"
              ></div>
              <div
                v-if="showDatepicker"
                class="z-50 bg-white mt-12 rounded-lg shadow p-4 absolute"
                style="width: 17rem"
                :style="`top: ${positionTop}px; left: ${positionLeft}px`"
              >
                <div class="flex justify-between items-center mb-2">
                  <div>
                    <span class="text-lg font-bold text-gray-800">{{
                      MONTH_NAMES[month]
                    }}</span>
                    <span class="ml-1 text-lg text-gray-600 font-normal">{{
                      year
                    }}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      class="
                        transition
                        ease-in-out
                        duration-100
                        inline-flex
                        cursor-pointer
                        hover:bg-gray-200
                        p-1
                        rounded-full
                      "
                      :class="{ 'cursor-not-allowed opacity-25': month == 0 }"
                      :disabled="month == 0 ? true : false"
                      @click="
                        month--;
                        getNoOfDays();
                      "
                    >
                      <svg
                        class="h-6 w-6 text-gray-500 inline-flex"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="
                        transition
                        ease-in-out
                        duration-100
                        inline-flex
                        cursor-pointer
                        hover:bg-gray-200
                        p-1
                        rounded-full
                      "
                      :class="{ 'cursor-not-allowed opacity-25': month == 11 }"
                      :disabled="month == 11 ? true : false"
                      @click="
                        month++;
                        getNoOfDays();
                      "
                    >
                      <svg
                        class="h-6 w-6 text-gray-500 inline-flex"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div class="flex flex-wrap mb-3 -mx-1">
                  <template v-for="(day, index) in DAYS" :key="index">
                    <div style="width: 14.26%" class="px-1">
                      <div
                        class="text-gray-800 font-medium text-center text-xs"
                      >
                        {{ $t(day) }}
                      </div>
                    </div>
                  </template>
                </div>

                <div class="flex flex-wrap -mx-1">
                  <template v-for="(blankday, index) in blankdays" :key="index">
                    <div
                      style="width: 14.28%"
                      class="text-center border p-1 border-transparent text-sm"
                    ></div>
                  </template>
                  <template
                    v-for="(date, dateIndex) in no_of_days"
                    :key="dateIndex"
                  >
                    <div style="width: 14.28%" class="px-1 mb-1">
                      <div
                        class="
                          flex-center
                          cursor-pointer
                          text-center text-sm
                          leading-none
                          rounded-full
                          transition
                          ease-in-out
                          duration-100
                          h-6
                          w-6
                        "
                        :class="{
                          'bg-current-500 text-white': isToday(date) == true,
                          'text-gray-700 hover:bg-current-200':
                            isToday(date) == false,
                        }"
                        @click="getDateValue(date)"
                      >
                        {{ date }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </teleport>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
