<script setup lang="ts">
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import { nextTick, ref } from 'vue';

withDefaults(
  defineProps<{
    listSubMenu?: any[];
    routerKey?: string;
    labelKey?: string;
    label?: string;
    currentLinkActive?: string;
  }>(),
  {
    listSubMenu: () => [],
    routerKey: 'router',
    labelKey: 'titleKey',
    label: 'title',
    currentLinkActive: '',
  }
);

const containerMenuSub = ref();

nextTick(() => {
  // let el = document.querySelector(
  //   '#containerMenuSub .tippy-content'
  // ) as HTMLElement;
  // el.style.transition = 'opacity 1s ease-in-out';
});

const onDropdownOpen = () => {
  // let el = document.querySelector('#containerMenuSub .tippy-content') as HTMLElement;
  // el.style.opacity = '100';
};

const onDropdownClose = () => {
  // let el = document.querySelector('#containerMenuSub .tippy-content') as HTMLElement;
  // el.style.opacity = '0';
};
</script>

<template>
  <VigDropdown
    id="containerMenuSub"
    ref="containerMenuSub"
    :placement="'right-start'"
    :arrow="false"
    :trigger="'mouseenter'"
    :delay="[600, 100]"
    :duration="[500, 0]"
    @on-dropdown-open="onDropdownOpen"
    @on-dropdown-close="onDropdownClose"
  >
    <template #dropdown-toggle>
      <slot name="menu-parent"></slot>
    </template>
    <template #dropdown-menu>
      <div
        class="rounded bg-white p-2 shadow flex flex-col text-sm font-medium"
        style="width: 15rem"
      >
        <RouterLink
          v-for="menu in listSubMenu"
          :key="menu"
          :to="{ name: menu.name }"
        >
          <div
            class="
              rounded-md
              dropdown-item
              px-2
              py-3
              my-0.5
              hover:bg-current-50
              text-gray-600
              hover:text-current-500
              w-full
              cursor-pointer
            "
            :class="`${
              currentLinkActive?.toLowerCase() === menu.name?.toLowerCase()
                ? 'bg-current-50 text-current-500'
                : ''
            }`"
          >
            {{ $t(menu[labelKey]) }}
          </div>
        </RouterLink>
      </div>
    </template>
  </VigDropdown>
</template>
