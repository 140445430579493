<template>
  <!-- <div class="bg-current-50 flex-center flex-wrap h-screen w-screen">
    <div class="text-center flex-center w-full flex-wrap">
      <div class="flex flex-col mb-4">
        <syn-logo />
      </div>
      <syn-animation name="verifyMail" stype="width: 500px; height: 500px" />
      <div class="font-thin text-xl w-full" v-if="!hasError">
        {{ $t('VERIFY_ACCOUT_SUCCESS_LABEL') }}
      </div>
      <div class="font-thin text-xl w-full" v-else>
        {{ $t('TOKEN_EXPIRED') || 'Token has expired!' }}
      </div>
    </div>
    <div class="flex-center mt-4 w-full">
      <syn-button
        ><span>{{ $t('COMMON_LABEL_BACK') }}</span></syn-button
      >
    </div>
  </div> -->
  <div class="absolute w-full h-full bg-current-50">
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div
          class="w-full relative lg:w-1/3 px-4 bg-white"
          style="
            border-radius: 20px;
            padding: 3rem;
            box-shadow: 0 0 20px #b1dcde;
          "
        >
          <div class="flex flex-col mb-4">
            <syn-logo />
          </div>

          <div
            class="
              flex flex-col
              content-center
              text-center
              justify-center
              w-full
              my-1
              font-roboto font-thin
              mb-8
            "
          >
            <span class="text-xl w-full">{{
              $t('COMMON_LABEL_VERIFY_EMAIL')
            }}</span>
            <span class="text-sm">{{
              !hasError
                ? $t('VERIFY_ACCOUT_SUCCESS_LABEL')
                : $t('TOKEN_EXPIRED') || 'Token has expired!'
            }}</span>
          </div>

          <div class="flex-center flex-wrap">
            <syn-animation
              v-if="!hasError"
              class="w-full"
              :name="'success'"
              stype="width:200px;"
            />
            <syn-animation
              v-else
              class="w-full"
              :name="'infomation'"
              stype="width:200px;"
            />
            <div class="flex-auto py-10 pt-0 w-full">
              <!-- <div class="text-center mt-6">
                <syn-button
                  class="w-full m-auto"
                  :label="$t('COMMON_LABEL_CONTINUE')"
                  :is-loading="isLoadingSubmitButton"
                  @click="onSubmit()"
                >
                </syn-button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import authStore from '@/store/auth';

export default {
  setup() {
    const _authStore = authStore();
    const route = useRoute();
    const router = useRouter();
    const hasError = ref(false);
    const msgError = ref('');
    const cToken = computed(() => route.query?.c);
    const submitToken = async () => {
      if (!cToken.value) {
        return;
      }
      try {
        let response = await _authStore.submitVerificationUserEmailToken({
          c: encodeURI(cToken.value),
          code: '',
          email: '',
        });

        if (
          response &&
          !response.verifiedEmail &&
          response.message &&
          response.message === 'TOKEN_EXPIRED'
        ) {
          hasError.value = true;
          msgError.value = 'TOKEN_EXPIRED';
        }
      } catch (response) {
        hasError.value = true;
        if (
          response &&
          !response.success &&
          response.error &&
          response.error.message === 'TOKEN_EXPIRED'
        ) {
          msgError.value = 'TOKEN_EXPIRED';
        }
      }
    };

    submitToken();

    const onSubmit = () => {
      router.push({
        name: 'Home',
      });
    };

    return {
      hasError,
      onSubmit,
    };
  },
};
</script>
