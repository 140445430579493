import {
    IExportDataPayload,
    IExportField,
    IExportFilters,
    IFilterData,
    IFilterDataByKey,
    ISortDataOrderByKey,
} from '@/application/types/task/task-export-data.types';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

export default class ExportTaskPayloadClass implements IExportDataPayload {
    filters: IExportFilters;
    hasHeaderRecord: boolean;
    language: string;
    delimiter: string;
    fields: { [key: string]: IExportField };
    keySearch: string;
    filterDataByKey: IFilterDataByKey;
    sortDataOrderByKey: { [key: string]: ISortDataOrderByKey };
    tasks: any[];

    constructor(data: any) {
        this.filters = data?.filters;
        this.hasHeaderRecord = true;
        this.language = getLocalStorage(StorageConstant.LANGUAGE);
        this.delimiter = ',';
        this.fields = data?.fields;
        this.filterDataByKey = this._prepareFilterDataByKey(
            data?.filterDataByKey
        );
        this.sortDataOrderByKey = data?.sortDataOrderByKey;
        this.keySearch = data?.keySearch || '';
        this.tasks = data.tasks || [];
    }

    _prepareFilterDataByKey(data: IFilterDataByKey): IFilterDataByKey {
        const lastModificationTime: IFilterData = {
            start: undefined,
            end: undefined,
        };

        if (data?.lastModificationTime?.start)
            lastModificationTime.start =
                typeof data?.lastModificationTime?.start == 'number'
                    ? data?.lastModificationTime?.start
                    : new Date(data?.lastModificationTime?.start).getTime();
        if (data?.lastModificationTime?.end)
            lastModificationTime.end =
                typeof data?.lastModificationTime?.end == 'number'
                    ? data?.lastModificationTime?.end
                    : new Date(data?.lastModificationTime?.end).getTime();

        return {
            urgency: data?.urgency,
            assigneeId: data?.assigneeId,
            domainId: data?.domainId,
            projectId: data?.projectId,
            groupId: data?.groupId,
            creatorId: data?.creatorId,
            taskLife: data?.taskLife,
            scheduleTime: data?.scheduleTime,
            creationTime: data?.creationTime,
            lastModificationTime,
        };
    }
}

// export class ExportTaskFieldItemClass {
//     export: boolean;
//     displayName: string;

//     constructor(data: any) {
//         this.export = data?.export;
//         this.displayName = data?.displayName;
//     }
// }
