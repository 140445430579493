import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { IUploadPreview } from '@/ui/components/file-viewer/update-preview-type';
import TaskService from '@/ticket/services/task-services';
import ticketPlugin from '@/ticket/services/plugin-services';

export interface IDrawNoteDetail extends IUploadPreview {
    type: string;
    base64: string;
    drawData: any;
    isNote: boolean;
    editStatus: 'ORIGINAL' | 'ADDED' | 'EDITED' | 'REMOVED';
}
export default function drawNoteComposables(
    defaultNotes: any[],
    pushImageFromNote
) {
    const isOpenNote = ref(false);
    const noteList = ref<IDrawNoteDetail[]>(
        defaultNotes?.length > 0
            ? defaultNotes?.map((note) => {
                  return {
                      id: note?.id || uuidv4(),
                      name: note?.name || `Note-Task ${Date.now()}`,
                      fileUrl: note?.imagePath || note?.base64,
                      base64: note?.base64,
                      type: 'image/jpeg',
                      drawData: note?.drawData,
                      isNote: true,
                      creationTime: note?.creationTime || new Date(),
                      editStatus: 'ORIGINAL',
                  };
              })
            : []
    );
    const drawNoteData = ref({
        id: '',
        isLoading: false,
        drawData: {},
        images:
            // currentTask.value.files.filter((o) => o.type?.startsWith('image')) ||
            [],
        originFile: '',
        readonly: false,
    } as any);

    const onCancelNote = () => {
        isOpenNote.value = false;
        drawNoteData.value = {
            isLoading: false,
            drawData: {},
            images:
                // currentTask.value.files.filter((o) => o.type?.startsWith('image')) ||
                [],
            originFile: '',
            readonly: false,
        };

        ticketPlugin.closeNote();
    };

    // const handlePasteAndSave = (noteData) => {
    //   const drawObjects = noteData?.drawData?.objects;

    //   const hasOnlyOneImage =
    //     drawObjects?.length == 1 && drawObjects[0]?.type == 'image';
    //   return hasOnlyOneImage
    //     ? {
    //         name: '',
    //         fileUrl: drawObjects[0].src,
    //         size: noteData?.base64?.length * (3 / 4) - 2,
    //         type: 'image/jpeg',
    //         isBase64: true,
    //       }
    //     : {};
    // };

    const onSaveNote = (noteData) => {
        console.log('🚀 Hyrin ~ onSaveNote ~ noteData', noteData);
        try {
            // const onlyOneFile = handlePasteAndSave(noteData);

            // if (onlyOneFile?.fileUrl) return;

            const currentNoteId = drawNoteData.value?.id;
            if (currentNoteId) {
                const noteIndex = noteList.value.findIndex(
                    (o) => o.id == currentNoteId
                );
                if (noteIndex > -1) {
                    noteList.value[noteIndex].fileUrl = noteData?.base64;
                    noteList.value[noteIndex].base64 = noteData?.base64;
                    noteList.value[noteIndex].drawData = noteData?.drawData;
                    if (noteList.value[noteIndex].editStatus === 'ORIGINAL')
                        noteList.value[noteIndex].editStatus = 'EDITED';
                }
            } else {
                const hasOnlyOneImage =
                    noteData?.drawData?.objects?.length == 1 &&
                    noteData?.drawData?.objects[0]?.type == 'image';

                const newNote: IDrawNoteDetail = {
                    id: uuidv4(),
                    name: `Note-Task ${Date.now()}`,
                    fileUrl: noteData?.base64,
                    base64: noteData?.base64,
                    type: 'image/jpeg',
                    drawData: noteData?.drawData,
                    isNote: hasOnlyOneImage ? false : true,
                    creationTime: new Date(),
                    editStatus: 'ADDED',
                };

                if (hasOnlyOneImage) pushImageFromNote(newNote);
                else noteList.value.push(newNote);
            }
        } catch (error) {
            console.log('🚀 ~ onCreateNote ~ error', error);
        } finally {
            onCancelNote();
        }
    };

    const onFetchUpdateNote = async (noteData) => {
        console.log(noteData);
        // await currentTask.value.updateNote(noteData);

        onCancelNote();
    };

    const getDrawDataFromServer = async (noteId) => {
        const resDraw: any = await TaskService.getInstance().getDrawDataById(
            noteId
        );

        return JSON.parse(resDraw?.result?.drawData);
    };

    const onOpenCreateNote = async (defaultData) => {
        let drawData = defaultData?.drawData;

        if (defaultData?.id && !drawData) {
            drawData = await getDrawDataFromServer(defaultData?.id);
        }

        drawNoteData.value = {
            id: defaultData?.id,
            name: defaultData?.name,
            isLoading: false,
            drawData: drawData,
            images:
                // currentTask.value.files.filter((o) => o.type?.startsWith('image')) ||
                [],
            originFile: '',
            readonly: false,
        };
        isOpenNote.value = true;

        ticketPlugin.openNote();
    };

    const onRemoveNote = async (note) => {
        if (!noteList.value?.length) return;

        const noteIndex = noteList.value.findIndex((o) => o.id == note.id);
        if (noteIndex > -1) {
            noteList.value[noteIndex].editStatus = 'REMOVED';
        }
    };

    const onEditNote = (data) => {
        drawNoteData.value = data;
        isOpenNote.value = true;
    };

    return {
        isOpenNote,
        drawNoteData,
        onSaveNote,
        onCancelNote,
        onFetchUpdateNote,
        onOpenCreateNote,
        noteList,
        onRemoveNote,
        onEditNote,
    };
}
