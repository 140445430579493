export const DriveProvider = {
    Google: 'GOOGLE',
    Microsoft: 'MICROSOFT',
};

export const FileUrlBase = {
    Document: 'https://docs.google.com/document',
    Spreadsheet: 'https://docs.google.com/spreadsheets',
    Presentation: 'https://docs.google.com/presentation',
};
