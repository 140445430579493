<template>
  <slot name="header"></slot>
  <slot v-if="show" name="content"></slot>
  <div class="h-8 w-8 rounded-full bg-current" @click="show = !show">
    toggle
  </div>
</template>
<script>
import { ref } from 'vue';

export default {
  name: 'SynCollapse',
  components: {},
  props: {},
  setup: () => {
    let show = ref(false);
    return { show };
  },
};
</script>
