<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';
import { useElementBounding } from '@vueuse/core';
import PROVIDER_IDS, {
  TDesktopDrawer,
  TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
// import { computed, inject } from 'vue';
import ShapeTextEntity from '@/ui/components/drawer/entities/ShapeTextEntity';
import ShapeActions from '@/ui/components/drawer/components/ShapeActions.vue';

const props = defineProps<{
  shapeEntity: ShapeTextEntity;
}>();
const textareaRef = ref<any>(null);
const textareaMeasureRef = ref<any>(null);

const desktopDrawerShapeInjected = inject<TDesktopDrawerShape>(
  PROVIDER_IDS.DESKTOP_DRAWER_SHAPE
);
const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);

const { width, height } = useElementBounding(textareaMeasureRef);

const updateShapeSize = () => {
  if (!props.shapeEntity?.updateSize || width.value == 0 || height.value == 0)
    return;
  props.shapeEntity?.updateSize(width.value, height.value);
};
watch([() => width.value, () => height.value], () => {
  updateShapeSize();
});

const desktopDrawerStatus = computed<any>(() => {
  return desktopDrawerInjected?.desktopDrawerInstance.value?.status;
});

watch(
  () => desktopDrawerInjected?.desktopDrawerInstance.value?.status,
  (newStatus) => {
    if (newStatus == 'TEXT_EDITING') {
      isEditing.value = true;
      setTimeout(() => {
        textareaRef.value?.focus();
      });
    } else onTurnOffEditMode();
  }
);

const textValue = ref<string>(props.shapeEntity?.sourceData?.text || '');
const textMeasureValue = computed<string>(() => {
  return textValue.value ? textValue.value.replaceAll('\n', '\n ') : '';
});
const isEditing = ref<boolean>(false);

const onTurnOffEditMode = () => {
  isEditing.value = false;
};

const onBlur = () => {
  if (textValue.value) {
    desktopDrawerInjected?.desktopDrawerInstance.value?.updateShape(
      props.shapeEntity?.id,
      'SOURCE_DATA',
      {
        ...props.shapeEntity?.sourceData,
        text: textValue.value,
      }
    );
    desktopDrawerInjected?.addMoreHistory();
  } else {
    desktopDrawerInjected?.desktopDrawerInstance.value?.removeShapeById(
      props.shapeEntity?.id,
      () => {
        desktopDrawerInjected?.addMoreHistory();
      }
    );
  }
};

const onMouseDown = (event) => {
  // clickTargetPosition.value = event;
  desktopDrawerShapeInjected?.onMouseDown(event);
};

const onClickRemove = () => {
  desktopDrawerInjected?.desktopDrawerInstance.value?.removeShapeById(
    props.shapeEntity?.id,
    () => {
      desktopDrawerInjected?.addMoreHistory();
    }
  );
};

const onEditText = () => {
  if (props.shapeEntity?.type !== 'TEXT') return;

  desktopDrawerInjected?.desktopDrawerInstance.value?.updateStatus(
    'TEXT_EDITING'
  );
  desktopDrawerInjected?.unSelectAll();
};
</script>
<template>
  <div class="tl-html-container">
    <div
      class="w-full h-full tl-text-shape__wrapper tl-text-shadow"
      :class="isEditing ? 'bg-white' : ''"
      data-font="draw"
      data-align="middle"
      data-hastext="false"
      data-isediting="true"
      data-textwrap="true"
      style="
        font-size: 24px;
        line-height: 32.4px;
        transform: scale(1);
        transform-origin: left top;
        color: rgb(29, 29, 29);
      "
      :style="{
        width: `${shapeEntity?.width}px`,
        height: `${shapeEntity?.height}px`,
      }"
    >
      <template v-if="!isEditing">
        <div v-if="textValue" class="tl-text tl-text-content">
          {{ textValue }}
        </div>
        <div v-else class="tl-text tl-text-content italic text-gray-500">
          {{ $t('TASK_DETAIL_ADD_COMMENT') }}...
        </div>
      </template>
      <textarea
        v-if="isEditing"
        ref="textareaRef"
        v-model="textValue"
        class="tl-text tl-text-input focus:ring-current rounded-sm"
        name="text"
        tabindex="-1"
        autocomplete="false"
        autocapitalize="false"
        autocorrect="false"
        autosave="false"
        placeholder=""
        spellcheck="true"
        wrap="off"
        datatype="wysiwyg"
        dir="ltr"
        autofocus
        @blur="onBlur"
      ></textarea>
      <div
        v-if="isEditing"
        ref="textareaMeasureRef"
        class="absolute tl-text-measure tl-text"
        style="pointer-events: none; top: 999999; opacity: 0"
        dir="ltr"
        :style="{
          width: `${shapeEntity?.width}px`,
        }"
      >
        {{ textMeasureValue }}
      </div>
    </div>
  </div>
  <div
    v-if="desktopDrawerStatus !== 'TEXT_EDITING'"
    class="w-full h-full absolute inset-0 rounded-sm drawer-shape-mask"
    :class="{
      'border border-current': shapeEntity?.type !== 'ARROW',
    }"
    @mousedown.left="onMouseDown"
    @dblclick="onEditText"
  >
    <ShapeActions
      class="absolute top-0 right-0 z-100"
      :action-list="['REMOVE']"
      @on-click-remove="onClickRemove"
    />
  </div>
</template>
<style>
.tl-text-shape__wrapper[data-font='draw'] {
  font-family: var(--tl-font-draw);
}
.tl-text-shape__wrapper[data-align='middle'] {
  text-align: center;
}
.tl-text-shape__wrapper {
  position: relative;
  font-weight: 400;
  min-width: 1px;
  padding: 0;
  margin: 0;
  border: none;
  height: 100%;
  font-feature-settings: normal;
  font-variant: normal;
  font-style: normal;
  pointer-events: all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  text-shadow: var(--tl-text-outline);
}

.tl-text-input {
  resize: none;
  -moz-user-select: all;
  user-select: all;
  -webkit-user-select: text;
  overflow: hidden;
  cursor: var(--tl-cursor-text);
}
.tl-text-content,
.tl-text-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1px;
  min-height: 1px;
  overflow: visible;
}

.tl-text,
.tl-text-measure {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.tl-text {
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  caret-color: var(--color-text);
  background: none;
  -o-border-image: none;
  border-image: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-style: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  text-shadow: inherit;
  outline: none;
  pointer-events: all;
  text-rendering: auto;
  text-transform: none;
  text-indent: 0;
  display: inline-block;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  -moz-column-count: auto !important;
  -moz-column-count: initial !important;
  column-count: auto !important;
  -ms-writing-mode: lr-tb !important;
  writing-mode: horizontal-tb !important;
  word-spacing: 0;
}
.tl-hidden-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  caret-color: var(--color-text);
  background: none;
  -o-border-image: none;
  border-image: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-style: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  text-shadow: inherit;
  outline: none;
  pointer-events: all;
  text-rendering: auto;
  text-transform: none;
  text-indent: 0;
  display: inline-block;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  -moz-column-count: auto !important;
  -moz-column-count: initial !important;
  column-count: auto !important;
  -ms-writing-mode: lr-tb !important;
  writing-mode: horizontal-tb !important;
  word-spacing: 0;
}

.tl-text-measure {
  position: absolute;
  z-index: 999999;
  top: -999999;
  right: -999999;
  opacity: 0;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  pointer-events: none;
  line-break: normal;
  resize: none;
  border: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}
</style>
