export const fileByExtension = {
    jpg: { contentType: 'image/jpeg' },
    jpeg: { contentType: 'image/jpeg' },
    png: { contentType: 'image/png' },
    svg: { contentType: 'image/svg+xml' },
    gif: { contentType: 'image/gif' },
    bmp: { contentType: 'image/bmp' },
    txt: { contentType: 'text/plain' },
    rtf: { contentType: 'application/rtf' },
    pdf: { contentType: 'application/pdf' },
    doc: { contentType: 'application/msword' },
    docx: {
        contentType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    ppt: { contentType: 'application/vnd.ms-powerpoint' },
    pptx: {
        contentType:
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    csv: { contentType: 'text/csv' },
    xls: { contentType: 'application/vnd.ms-excel' },
    xlsx: {
        contentType:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    xml: { contentType: 'application/xml' },
    js: { contentType: 'text/javascript' },
    json: { contentType: 'application/json' },
    mp3: { contentType: 'audio/mpeg' },
    mp4: { contentType: 'application/mp4' },
    wav: { contentType: 'audio/wav' },
    oga: { contentType: 'audio/ogg' },
    ogv: { contentType: 'video/ogg' },
    mpeg: { contentType: 'video/mpeg' },
    zip: { contentType: '	application/zip' },
    rar: { contentType: 'application/vnd.rar' },
    '7z': { contentType: 'application/x-7z-compressed' },

    jwt: { contentType: 'application/jwt' },
    MF4: { contentType: 'application/MF4' },
    mp21: { contentType: 'application/mp21' },
    mxf: { contentType: 'application/mxf' },
    node: { contentType: 'application/node' },
    nasdata: { contentType: 'application/nasdata' },
    nss: { contentType: 'application/nss' },
    mikey: { contentType: 'application/mikey' },
    mipc: { contentType: 'application/mipc' },
    A2L: { contentType: 'application/A2L' },
    AML: { contentType: 'application/AML' },
    ATF: { contentType: 'application/ATF' },
    ATFX: { contentType: 'application/ATFX' },
    ATXML: { contentType: 'application/ATXML' },
    cbor: { contentType: 'application/cbor' },
    cccex: { contentType: 'application/cccex' },
    cdni: { contentType: 'application/cdni' },
    CEA: { contentType: 'application/CEA' },
    cfw: { contentType: 'application/cfw' },
    clr: { contentType: 'application/clr' },
    cms: { contentType: 'application/cms' },
    commonground: { contentType: 'application/commonground' },
    cose: { contentType: 'application/cose' },
    csrattrs: { contentType: 'application/csrattrs' },
    cwt: { contentType: 'application/cwt' },
    cybercash: { contentType: 'application/cybercash' },
    dashdelta: { contentType: 'application/dashdelta' },
    DCD: { contentType: 'application/DCD' },
    dicom: { contentType: 'application/dicom' },
    DII: { contentType: 'application/DII' },
    DIT: { contentType: 'application/DIT' },
    dns: { contentType: 'application/dns' },
    dvcs: { contentType: 'application/dvcs' },
    EDIFACT: { contentType: 'application/EDIFACT' },
    efi: { contentType: 'application/efi' },
    encaprtp: { contentType: 'application/encaprtp' },
    eshop: { contentType: 'application/eshop' },
    example: { contentType: 'application/example' },
    exi: { contentType: 'application/exi' },
    express: { contentType: 'application/express' },
    fastinfoset: { contentType: 'application/fastinfoset' },
    fastsoap: { contentType: 'application/fastsoap' },
    fdf: { contentType: 'application/fdf' },
    fits: { contentType: 'application/fits' },
    flexfec: { contentType: 'application/flexfec' },
    gzip: { contentType: 'application/gzip' },
    H224: { contentType: 'application/H224' },
    http: { contentType: 'application/http' },
    hyperstudio: { contentType: 'application/hyperstudio' },
    iges: { contentType: 'application/iges' },
    index: { contentType: 'application/index' },
    IOTP: { contentType: 'application/IOTP' },
    ipfix: { contentType: 'application/ipfix' },
    ipp: { contentType: 'application/ipp' },
    ISUP: { contentType: 'application/ISUP' },
};
