<script setup lang="ts">
// *** IMPORTS ***
import { computed } from 'vue';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import systemConfig from '@/application/constants/system-config.const';

// *** PROPS, EMITS ***
const props = defineProps<{
  settings: any;
}>();

const emit = defineEmits(['onSettingChange']);

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***

// *** COMPUTED ***

const startWorkingHasTimeBlocking = computed({
  get() {
    return (
      props.settings &&
      props.settings[
        systemConfig.START_WO_FUNCTION_START_WORKING_SCREENS
      ]?.includes('TIME_BLOCKING')
    );
  },
  set(value) {
    const currentList = Object.prototype.hasOwnProperty.call(
      props.settings,
      systemConfig.START_WO_FUNCTION_START_WORKING_SCREENS
    )
      ? props.settings[
          systemConfig.START_WO_FUNCTION_START_WORKING_SCREENS
        ]?.split(';')
      : ['HELLO_SCREEN', 'PLAN_TODAY_SCREEN'];

    emit('onSettingChange', {
      key: systemConfig.START_WO_FUNCTION_START_WORKING_SCREENS,
      value: value
        ? [...new Set([...currentList, 'TIME_BLOCKING'])].join(';')
        : currentList?.filter((o) => o !== 'TIME_BLOCKING')?.join(';'),
    });
  },
});

const startWoFunctionMandatoryToPlanTimeForEac = computed({
  get() {
    return (
      props.settings &&
      props.settings[
        systemConfig.START_WO_FUNCTION_MANDATORY_TO_PLAN_TIME_FOR_EAC
      ] === '1'
    );
  },
  set(value) {
    emit('onSettingChange', {
      key: systemConfig.START_WO_FUNCTION_MANDATORY_TO_PLAN_TIME_FOR_EAC,
      value: value ? '1' : '0',
    });
  },
});

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div>
    <MenuSettingRowItem
      name-icon="Timeline"
      :icon-is-active="false"
      :title-menu="$t('COMMON_LABEL_PLAN_FOR_TODAY')"
      :sub-title="$t('COMMON_LABEL_PLAN_FOR_TODAY_DESCRIPTION')"
    >
      <template #action>
        <AtomSwitch v-model="startWorkingHasTimeBlocking" />
      </template>
      <template #subMenu>
        <div
          v-if="startWorkingHasTimeBlocking"
          class="pl-2 border-t border-dashed"
        >
          <MenuSettingRowItem
            :title-menu="$t('COMMON_LABEL_PLAN_FOR_TODAY_REQUIRED')"
            :sub-title="$t('COMMON_LABEL_PLAN_FOR_TODAY_REQUIRED_DESCRIPTION')"
          >
            <template #action>
              <AtomSwitch v-model="startWoFunctionMandatoryToPlanTimeForEac" />
            </template>
          </MenuSettingRowItem>
        </div>
      </template>
    </MenuSettingRowItem>
  </div>
</template>

<style scoped></style>
