// import { useIntersectionObserver } from '@vueuse/core';
// import listenScrollComposables from '../composables/app/listen-scroll-composables';

export default {
    install: (app) => {
        app.directive('listen-scroll', {
            /*mounted(el, binding) {
        const { callback } = binding.value;

        // const observerElement: any = document.createElement('span');
        // el.classList.add('relative');
        // observerElement.classList.add('absolute');
        // observerElement.style.top = `40%`;
        // el.appendChild(observerElement);

        // useIntersectionObserver(observerElement, ([{ isIntersecting }]) => {
        //   observerElement.visible = isIntersecting;
        //   if (!isIntersecting) return;

        //   if (callback && typeof callback == 'function') callback();
        // });

        // handle scroll
        el.visible = true;
        el.onScrollEvent = () => {
          if (!el || !el?.scrollHeight) return;
          const scrollTop = el?.scrollTop;

          const remainScrollPercent = scrollTop / el?.scrollHeight;
          // if (!observerElement?.visible) return;

          // const st = el?.scrollTop;

          // if (!el?.lastScrollTop) {
          //   el.lastScrollTop = st <= 0 ? 0 : st;
          //   return;
          // }

          // if (st > el?.lastScrollTop) return;

          // el.lastScrollTop = st <= 0 ? 0 : st;
          // if (el?.scrollTop !== 0 ) return;

          if (
            (scrollTop !== 0 && scrollTop > 400) ||
            (remainScrollPercent !== 0 && remainScrollPercent > 0.3)
          ) {
            el.visible = false;
            return;
          }

          if (scrollTop !== 0 && el.visible) return;

          el.visible = true;
          // keep current scroll stage
          if (scrollTop == 0) {
            el.scrollTop = 5;
          }

          // run callback function
          if (callback && typeof callback == "function") callback();
        };
        el.addEventListener("scroll", el.onScrollEvent);
      },*/
            mounted(el, binding) {
                const { onScroll, onScrollUp } = binding.value;
                let { distance } = binding.value;

                if (!distance) distance = 30; // percent

                let lastElementId =
                    el.id || `scroll-panel-${new Date().getTime()}`;
                let lastScrollTop = Math.abs(el.scrollTop || 0);
                let scrollTriggered = false;
                let scrollUpTriggered = false;

                el.onScrollEvent = async () => {
                    // Reset triggered when ID changed
                    if (el.id !== lastElementId) {
                        lastElementId = el.id;
                        lastScrollTop = 0;
                        scrollTriggered = false;
                        scrollUpTriggered = false;
                    }

                    const scrollTop = Math.abs(el.scrollTop || 0);
                    const scrollHeight = el.scrollHeight || 0;
                    const offsetHeight = el.offsetHeight || 0;

                    // Scroll up
                    if (scrollTop < lastScrollTop) {
                        const currentDistance =
                            (scrollTop / (scrollHeight || 1)) * 100;

                        if (currentDistance <= distance && !scrollUpTriggered) {
                            scrollUpTriggered = true;
                            try {
                                if (onScrollUp) await onScrollUp();
                            } catch (e) {
                                console.log(e);
                            }
                            scrollUpTriggered = false;
                        }
                    }
                    // Scroll down
                    else {
                        const currentDistance =
                            ((scrollHeight - offsetHeight - scrollTop) /
                                (scrollHeight || 1)) *
                            100;

                        if (currentDistance <= distance && !scrollTriggered) {
                            scrollTriggered = true;
                            try {
                                if (onScroll) await onScroll();
                            } catch (e) {
                                console.log(e);
                            }
                            scrollTriggered = false;
                        }
                    }

                    lastScrollTop = scrollTop;

                    // keep current scroll stage
                    // if (scrollTop === 0) {
                    //   el.scrollTop += 1;
                    // }
                };

                el.addEventListener('scroll', el.onScrollEvent);
            },
            unmounted(el) {
                el.removeEventListener('scroll', el.onScrollEvent);
            },
        });
    },
};
