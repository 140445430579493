export enum PaymentStatusEnum {
    InitTransaction = -1,
    SuccessfulTransaction = 11,
    TransactionIsNotCompleted = 22,
    ErrorTransaction = 33,
    HandlingTransactions = 44,
}
export enum EPaymentPurpose {
    PURCHASE = 'PURCHASE',
    Upgrade = 'UPGRADE',
    Renew = 'RENEW',
    Extend = 'EXTEND',
    BuyMore = 'GET_MORE',
    AppliedVoucher = 'APPLY_VOUCHER',
    APPLY_LICENSE = 'APPLY_LICENSE',
    BUY_LICENSE = 'BUY_LICENSE',
    DOWNGRADE = 'DOWNGRADE',
}
