<script setup lang="ts">
import { computed } from 'vue';
import {
  getConfigScheduleColor,
  getBgClass,
  getDetailTaskUrl,
} from '@/ui/hooks/taskHook';
import { TaskLife } from '@/domain/enums/taskEnum';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { ScheduleDayOptions } from '@/domain/enums/taskEnum';
import { PlanTypeEnum } from '@/domain/enums/taskEnum';
import taskStore from '@/store/task';
import groupStore from '@/store/group';
import myProfileStore from '@/store/auth/my-profile';
import DomainById from '@/ui/modules/domains/component/DomainById.vue';

const _taskStore = taskStore();
const _groupStore = groupStore();

const props = withDefaults(
  defineProps<{
    task: TaskDetailClass;
    isReadonly?: boolean;
    isMyMessage?: boolean;
    isFirstInGroup?: boolean;
    isLastInGroup?: boolean;
    isComment?: boolean;
    customClassTitle?: string;
    isErrorSend?: boolean;
    members?: any;
    customClass?: string;
  }>(),
  {
    taskDetail: () => new TaskDetailClass({}),
    isReadonly: false,
    isMyMessage: true,
    isComment: false,
    isErrorSend: false,
    customClass: '',
    customClassTitle: '',
    members: () => [],
  }
);

defineEmits(['onOpenTask']);

const taskDetailShow = computed(() => new TaskDetailClass(props.task) as any);
const allMyGroup = computed(() => _groupStore.myGroups);

const myTimezone = computed(() => myProfileStore().getTimezone);
const taskCreator = computed(() => {
  if (
    taskDetailShow.value?.creatorId &&
    props.members[taskDetailShow.value?.creatorId]
  ) {
    return translate('TASK_DETAIL_BY_MEM', {
      member: props.members[taskDetailShow.value?.creatorId].name,
    });
  }
  return '';
});

const taskAssignee = computed(() => {
  if (
    taskDetailShow.value?.assigneeId &&
    props.members[taskDetailShow.value?.assigneeId]
  ) {
    return translate('TASK_DETAIL_TO_MEM', {
      member: props.members[taskDetailShow.value?.assigneeId].name,
    });
  }
  return '';
});

const groupName = computed(() => {
  const index = allMyGroup.value?.findIndex(
    (o) => o.id == taskDetailShow.value?.groupId
  );
  return index > -1 ? allMyGroup.value[index].name : '';
});

const getConfigName = (sitKey, typeKey) => {
  return _taskStore.getConfigName(sitKey, typeKey);
};

const getConfigScheduleName = (data) => {
  return _taskStore.getConfigScheduleName(data);
};
</script>

<template>
  <div
    :title="taskDetailShow?.name"
    class="px-3 py-2 mb-0.5 bg-white rounded relative xl:min-w-xs"
    :class="{
      'cursor-pointer hover:bg-current-50': !isReadonly,
      'border border-current-500': isMyMessage,
      'border border-gray-500': !isMyMessage,
      'border border-red-500': isErrorSend,
    }"
    style="max-width: 27rem"
    @click.stop.prevent="$emit('onOpenTask', taskDetailShow?.code)"
  >
    <div>
      <a
        :href="getDetailTaskUrl(taskDetailShow?.code, false)"
        class="break-words text-overflow-hidden pb-1 cursor-pointer"
      >
        <div class="flex items-center space-x-1">
          <span class="text-overflow-hidden" :class="customClassTitle">
            {{ taskDetailShow?.name }}
          </span>
        </div>
      </a>
    </div>
    <div class="flex justify-between items-center py-1">
      <div class="flex space-x-1 pr-3 items-center">
        <PriorityTask :urgency="taskDetailShow?.urgency" size="tiny" />
        <SynTag
          v-if="
            taskDetailShow?.taskSchedule?.planType !== PlanTypeEnum.Nothing &&
            taskDetailShow?.taskLife !== TaskLife.Finished
          "
          class="font-normal text-xs px-2"
          size="tiny"
          :class="
            getBgClass(taskDetailShow?.taskSchedule?.planType, 'planType')
          "
        >
          {{
            getConfigName(taskDetailShow?.taskSchedule?.planType, 'planType')
          }}
        </SynTag>
        <SynTag
          v-else-if="taskDetailShow?.taskLife == TaskLife.Finished"
          size="tiny"
          class="text-xs px-2"
          :class="getBgClass(taskDetailShow?.taskLife, 'taskLife')"
        >
          {{ getConfigName(taskDetailShow?.taskLife, 'taskLife') }}
        </SynTag>
        <SynTag
          v-if="
            getConfigScheduleName(taskDetailShow) &&
            taskDetailShow?.planType !== PlanTypeEnum.ToFinish &&
            taskDetailShow?.taskSchedule?.scheduleOptionKey !==
              ScheduleDayOptions.Nothing
          "
          class="border bg-white text-xs px-2"
          size="tiny"
          :class="getConfigScheduleColor(taskDetailShow?.taskSchedule)"
        >
          {{
            taskDetailShow.assigneeId && getConfigScheduleName(taskDetailShow)
              ? getConfigScheduleName(taskDetailShow)
              : $filters.dayjs(
                  taskDetailShow.scheduleTime,
                  'MMM DD, YYYY',
                  myTimezone || 0
                ) ||
                $t('TASK_DETAIL_LABEL_NO_SCHEDULE') ||
                'No schedule'
          }}
        </SynTag>
      </div>
      <div class="flex flex-col items-center space-x-1">
        <span class="text-current-800 text-xs font-bold">
          <DomainById :domain-id="taskDetailShow?.domainId" />
        </span>
      </div>
    </div>
    <div class="flex items-center justify-between space-x-2">
      <div class="italic text-gray-500" style="font-size: 11px">
        <div>
          <span v-if="taskCreator">{{ taskCreator }}</span>

          <span v-if="taskAssignee">{{ ' - ' + taskAssignee }}</span>
        </div>
        <span v-if="groupName">{{
          $t('TASK_DETAIL_IN_GROUP', {
            group: groupName,
          })
        }}</span>
      </div>
      <span class="text-xs font-medium">
        {{
          taskDetailShow?.code?.substring(
            taskDetailShow?.code.indexOf('-') > -1
              ? taskDetailShow?.code.indexOf('-') + 1
              : 0,
            taskDetailShow?.code.length
          )
        }}
      </span>
    </div>
  </div>
</template>
