export function calculatePaymentPrice(
    basePrice,
    discountByUsers,
    { period, discount, totalUsers }
) {
    const calculatedPaymentInfo: {
        monthPrice: any;
        unitPrice: number;
        discountPercent: number;
    } = _getPriceByTotalUser(totalUsers, basePrice, discountByUsers);

    const lastTotalAmount =
        calculatedPaymentInfo?.monthPrice * period * (1 - discount);

    const lastTotalAmountNotDiscount = basePrice * totalUsers * period;

    let discountPercent =
        ((lastTotalAmountNotDiscount - lastTotalAmount) /
            lastTotalAmountNotDiscount) *
        100;
    if (discountPercent > 0 && discountPercent < 1)
        discountPercent =
            Math.floor((discountPercent + Number.EPSILON) * 10) / 10;
    else discountPercent = Math.floor(discountPercent);

    return {
        lastTotalAmount,
        priceActualForOneUserInOneMonth:
            totalUsers > 0 ? lastTotalAmount / totalUsers / period : 0,
        lastTotalAmountNotDiscount,
        discountPercent,
    };
}
export const getLastTotalAmountAfterApplyVoucher = (
    voucher,
    lastTotalAmount
) => {
    if (!voucher?.code) return 0;
    const discount = Math.min(
        (lastTotalAmount * voucher?.valueInPercent) / 100,
        voucher?.valueInMoney
    );
    return Math.max(lastTotalAmount - discount, 1000);
};

const _getPriceByTotalUser = (totalUser, basePrice, discountByTotalUser) => {
    const price = discountByTotalUser
        .filter((item) => item.from <= totalUser)
        .reduce((oldValue, currentItem, currentIndex, array) => {
            const totalUserInThisLevel =
                currentIndex < array.length - 1 && currentItem.to !== null
                    ? currentItem.to - currentItem.from + 1
                    : totalUser - currentItem.from + 1;

            const priceInCurrentLevel = _formatPriceAfterDiscount(
                basePrice,
                currentItem.discount
            );

            return oldValue + totalUserInThisLevel * priceInCurrentLevel;
        }, 0);

    return {
        monthPrice: price,
        unitPrice: price / totalUser,
        discountPercent: Math.round(
            ((basePrice - price / totalUser) / basePrice) * 100
        ),
    };
};

const _formatPriceAfterDiscount = (basePrice: number, discount) => {
    if (discount == 0) return basePrice;

    return Math.floor((basePrice / 100) * (1 - discount)) * 100;
};
