<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  name: string;
  path: string;
  params: any;
  query: any;
  target: 'blank' | 'self';
}>();

const targetRoute = computed(() => {
  if (props.path)
    return {
      path: props.path,
    };

  return {
    name: props.name,
    params: props.params,
    query: props.query,
  };
});
</script>
<template>
  <router-link :to="targetRoute" target="_blank">
    <slot />
  </router-link>
</template>
