<script setup lang="ts">
import { computed, ref, onMounted, toRaw, watch } from 'vue';
// import TaskService from '@/application/services/task/TaskService';
import TaskWorkflowEntity, {
  ITaskWorkflowEntity,
} from '@/domain/entities/task/TaskWorkflowEntity';
import TaskWorkflowModal from '@/ui/modules/task/workflow/TaskWorkflowModal.vue';
// import workflowStore from '@/store/workflow';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import ApplyWorkFlowModal from '@/ui/modules/task/workflow/ApplyWorkFlowModal.vue';
// import { ITaskWorkflowStep } from '@/domain/entities/task/TaskWorkflowStepEntity';
import taskDetailStore from '@/store/task/detail';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import TaskTodoListAll from '@/ui/modules/task/components/check-list/TaskTodoListAll.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import TaskWorkflowStepDetailModal from '@/ui/modules/task/workflow/step/TaskWorkflowStepDetailModal.vue';
import taskDetailWorkflowComposables from '@/ui/modules/task/workflow/task-detail-workflow.composables';
import { genStyleByStepStatus } from '@/application/constants/task/workflow.const';
import WorkflowStepCodeLabel from '@/ui/modules/workflow/workflow-step/WorkflowStepCodeLabel.vue';
import { EWorkflowTaskStepStatus } from '@/application/types/workflow/workflow.types';

const props = defineProps<{
  viewMode?: 'TASK_VIEW' | 'ATTACH_TO_TASK';
  taskId?: number;
  taskDetail: ITaskDetailClass;
  defaultTaskWorkflow?: ITaskWorkflowEntity;
  isShowIconOnly?: boolean;
  readonly?: boolean;
  hasJustNextStep?: boolean;
  showCodeOnly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'onAttach', data): void;
  (e: 'detach'): void;
  (e: 'blinked'): void;
  (e: 'detachSuccess'): void;
  (e: 'updateTask', data: any): void;
  (e: 'chooseStep', step: any): void;
  (e: 'updateCurrentStep', step: any, workflowStepData: any): void;
}>();

const _taskDetailStore = taskDetailStore();
// const INIT_TIME = Date.now();
// const _workflowStore = workflowStore();

// const allStepTypeByIds = computed<any>(() => {
//   return _workflowStore.allStepTypeByIds;
// });

const {
  currentTaskStep,
  taskWorkflowData,
  currentStepInWorkflow,
  initComponent,
} = taskDetailWorkflowComposables(props.taskDetail);

const totalByKeys = computed<any>(() => {
  return {
    DESCRIPTION: currentTaskStep.value?.taskDescription ? 1 : 0,
    FILE: currentTaskStep.value?.totalFiles || 0,
    NOTE: currentTaskStep.value?.totalNotes || 0,
    CHECKLIST: currentTaskStep.value?.totalTodoLists || 0,
    WORKFLOW: 0,
    COMMENT: 0,
  };
});

const currentStepHasAttachment = computed<boolean>(() => {
  return (
    totalByKeys.value?.DESCRIPTION > 0 ||
    totalByKeys.value?.FILE > 0 ||
    totalByKeys.value?.NOTE > 0 ||
    totalByKeys.value?.CHECKLIST > 0
  );
});

const currentStepAttachments = computed<any[]>(() => {
  return arrayOrderBy(
    [
      ...(currentTaskStep.value?.files || []),
      ...(currentTaskStep.value?.notes?.map((n) => {
        return {
          ...n,
          isNote: true,
          type: 'image/jpeg',
        };
      }) || []),
    ],
    ['creationTime'],
    ['asc']
  );
});

const updatedTaskWorkflowId = computed<any>(() => {
  return _taskDetailStore.updatedTaskWorkflowId;
});
watch(
  () => updatedTaskWorkflowId.value,
  (newValue) => {
    if (!props.taskDetail?.workFlowTaskId) return;
    if (newValue.split(':').pop() !== props.taskDetail?.workFlowTaskId) return;

    initComponent(props.taskDetail);
  }
);

const detailCardRef: any = ref(null);
// const detailCardPosition = ref<{ translateX: number }>({
//   translateX: 0,
// });
// const initDropdownCurrentStep = () => {
//   if (!currentTaskStep.value?.id) return;

//   const containerElement = document.getElementById('workflow-step-container');
//   const currentStepElement = document.getElementById(
//     `${currentTaskStep.value?.id}_${INIT_TIME}`
//   );
//   const containerBounding = containerElement?.getBoundingClientRect();
//   if (!currentStepElement || !containerBounding) return;

//   const currentStepLeft = currentStepElement?.offsetLeft + 25;
//   const detailCardElement = detailCardRef.value?.getBoundingClientRect();
//   const centerPoint = detailCardElement?.width / 2;

//   detailCardPosition.value.translateX = Math.min(
//     Math.max(0, currentStepLeft - centerPoint),
//     containerBounding?.width - detailCardElement?.width
//   );
// };
onMounted(async () => {
  if (props.isShowIconOnly) return;

  // await initComponent(props.taskDetail);

  // setTimeout(() => {
  //   initDropdownCurrentStep();
  // }, 100);

  if (props.hasJustNextStep) startBlinkStepName();
});

const isBlinkStepName = ref<boolean>(false);
const startBlinkStepName = () => {
  isBlinkStepName.value = true;
  setTimeout(() => {
    isBlinkStepName.value = false;
    emit('blinked');
  }, 3 * 1000);
};

const isOpenDetail = ref<boolean>(false);
const isOpenWorkFlow = ref<boolean>(false);

const onOpenDetailModal = async () => {
  if (props.isShowIconOnly) {
    await initComponent(props.taskDetail);
  }

  if (props.taskDetail?.id) isOpenDetail.value = true;
  else isOpenWorkFlow.value = true;
};

const isNeedUpdateTask = ref<boolean>(false);

const onCloseDetailModal = async () => {
  isOpenDetail.value = false;
  if (isNeedUpdateTask.value)
    setTimeout(() => {
      emit('updateTask', taskWorkflowData.value);
    });
  // if (!props.isShowIconOnly) await initComponent();
};

const updateTaskWorkflow = (newData) => {
  taskWorkflowData.value = new TaskWorkflowEntity(newData);
  isOpenWorkFlow.value = false;

  emit('onAttach', taskWorkflowData.value);
};

const isShowCurrentStepAttachment = ref<boolean>(false);

const currentFileIndex = ref(0);
const isPreview = ref(false);
const onOpenFiles = (file) => {
  currentFileIndex.value = currentStepAttachments.value.findIndex(
    (o) => o.id == file.id
  );
  isPreview.value = true;
};

const toggleShowAttachment = () => {
  isShowCurrentStepAttachment.value = !isShowCurrentStepAttachment.value;
};
const removeWorkflowFromTask = () => {
  emit('detach');
};

const onChooseStep = async (step) => {
  if (!props.taskId) {
    onOpenDetailModal();
    return;
  }
  if (!step?.taskId) {
    settingGlobalModal({
      type: 'confirm',
      title: translate('TASK_WORKFLOW_STEP_DO_NOT_IMPLEMENTED_TITLE'),
      content: translate('TASK_WORKFLOW_STEP_DO_NOT_IMPLEMENTED'),
      closeable: false,
      confirmable: true,
      confirmLabel: translate('COMMON_LABEL_GOT_IT'),
    });
    await ask();

    return;
  }

  if (step?.taskId == props.taskId) return;
  emit('chooseStep', step);
};

const isOpenUpdateTaskWorkflowStepDetailModal = ref<boolean>(false);
const onOpenUpdateStepModal = () => {
  isOpenUpdateTaskWorkflowStepDetailModal.value = true;
};

const updateSuccess = (data, workflowStepData) => {
  isOpenUpdateTaskWorkflowStepDetailModal.value = false;
  // if (props.viewMode == 'TASK_VIEW') initComponent(props.taskDetail);

  // const index = taskWorkflowData.value?.steps?.findIndex(
  //   (s) => s?.id == currentTaskStep.value?.id
  // );
  // if (taskWorkflowData.value && index !== undefined && index > -1) {
  //   taskWorkflowData.value.steps[index] = workflowStepData;
  // }
  emit('updateCurrentStep', data, workflowStepData);
};

const goToCurrentStep = () => {
  onChooseStep(currentStepInWorkflow.value);
};

const isOpenStepList = ref<boolean>(false);
defineExpose({
  initComponent,
  onOpenDetailModal,
  goToCurrentStep,
});
</script>
<template>
  <template v-if="showCodeOnly">
    <WorkflowStepCodeLabel
      :model-value="currentTaskStep?.code || ''"
      :custom-class="isBlinkStepName ? 'animate-bounce bg-orange-500' : ''"
      readonly
    />
  </template>
  <div
    v-else
    class="
      w-full
      flex
      items-center
      bg-orange-100
      hover:bg-orange-50
      rounded
      px-0.5
      text-purple-50
      relative
      h-10
      cursor-pointer
    "
  >
    <div
      class="
        flex
        items-center
        h-full
        px-1
        pr-2
        gap-1
        hover:bg-gray-100
        min-w-[30%]
        max-w-[50%]
      "
      @click.stop="onOpenDetailModal"
    >
      <span class="flex-center h-5 w-5">
        <SynIcon custom-class="w-3.5 h-3.5 fill-purple-500" name="flow" />
      </span>
      <span class="text-sm font-semibold truncate text-purple-500">
        {{ taskWorkflowData?.name }}
      </span>
    </div>
    <div class="flex-1 px-2 truncate">
      <VigDropdown
        v-model:is-open="isOpenStepList"
        :arrow="false"
        placement="bottom-start"
      >
        <template #dropdown-toggle>
          <div
            class="
              flex
              items-center
              justify-between
              p-2
              hover:bg-current-50
              cursor-pointer
              rounded
            "
          >
            <div class="w-full flex-center gap-2">
              <WorkflowStepCodeLabel
                :model-value="currentTaskStep?.code || ''"
                :custom-class="
                  isBlinkStepName
                    ? 'animate-bounce bg-orange-500'
                    : currentTaskStep?.status ==
                      EWorkflowTaskStepStatus.COMPLETED
                    ? 'bg-green-500'
                    : currentTaskStep?.status == EWorkflowTaskStepStatus.TODO
                    ? 'bg-blue-500'
                    : ''
                "
                readonly
              />
              <div
                class="flex-1 truncate font-semibold"
                :class="
                  isBlinkStepName
                    ? 'animate-bounce font-semibold text-orange-500'
                    : currentTaskStep?.status ==
                      EWorkflowTaskStepStatus.COMPLETED
                    ? 'text-green-500'
                    : currentTaskStep?.status == EWorkflowTaskStepStatus.TODO
                    ? 'text-blue-500'
                    : 'text-gray-800'
                "
              >
                {{ currentTaskStep?.name }}
              </div>

              <SynIcon
                name="ArrowDown"
                :custom-class="
                  isOpenStepList
                    ? 'h-2.5 w-2.5 fill-blue-500 rotate-180'
                    : 'h-2.5 w-2.5 fill-gray-500 '
                "
              />
            </div>
          </div>
        </template>
        <template
          v-if="taskWorkflowData?.steps && taskWorkflowData?.steps?.length > 0"
          #dropdown-menu
        >
          <div class="p-4 w-[25.25rem]">
            <div class="flex flex-col gap-0.5">
              <div
                v-for="item in taskWorkflowData.steps"
                :key="item?.id"
                class="
                  px-2
                  py-2
                  rounded
                  hover:bg-current-50
                  cursor-pointer
                  border
                "
                :class="currentTaskStep?.id == item?.id ? 'bg-current-50' : ''"
                :style="
                  genStyleByStepStatus(
                    item?.status,
                    item?.id == defaultTaskWorkflow?.currentStepId,
                    item?.id == taskDetail?.workflowStepId
                  ).header
                "
                @click="onChooseStep(item)"
              >
                <div class="flex items-center gap-1">
                  <WorkflowStepCodeLabel
                    :model-value="item?.code || ''"
                    readonly
                  />
                  <span class="truncate text-gray-800 font-semibold text-sm">
                    {{ item?.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </VigDropdown>
    </div>

    <div
      v-if="viewMode !== 'ATTACH_TO_TASK'"
      class="flex-center justify-between gap-2 cursor-pointer"
      @click.stop="toggleShowAttachment"
    >
      <AtomButton color="white" class="w-8 h-8">
        <SynIcon
          name="DocInfo"
          :custom-class="
            currentStepHasAttachment
              ? 'w-5 h-5 fill-purple-500'
              : 'w-4 h-4 fill-gray-500'
          "
        />
      </AtomButton>
    </div>
    <div
      v-if="viewMode == 'ATTACH_TO_TASK'"
      class="flex-center justify-between gap-2 cursor-pointer"
      @click.stop="removeWorkflowFromTask"
    >
      <AtomButton color="white" class="w-8 h-8">
        <SynIcon name="trash" :custom-class="'w-4 h-4 fill-gray-500'" />
      </AtomButton>
    </div>

    <div
      v-if="currentTaskStep && currentTaskStep?.id"
      ref="detailCardRef"
      :key="updatedTaskWorkflowId"
      class="
        px-4
        py-2
        border
        rounded-md
        flex flex-col
        gap-4
        absolute
        top-0
        left-0
        w-full
        bg-white
        border-purple-500 border-dashed
        z-100
      "
      :class="[isShowCurrentStepAttachment ? 'translate-y-10' : 'hidden']"
    >
      <div class="flex flex-col gap-2 relative">
        <!-- DESCRIPTION -->
        <div class="w-full flex items-center justify-end absolute top-0">
          <AtomButton
            color="white"
            class="w-8 h-8"
            @click.stop="onOpenUpdateStepModal"
          >
            <SynIcon name="edit" custom-class="w-4 h-4 fill-gray-500" />
          </AtomButton>
        </div>
        <section>
          <div class="w-full flex items-center space-x-2">
            <span class="text-sm flex items-center text-gray-500">
              {{ $t('COMMON_LABEL_DESCRIPTION') || 'Description' }}
            </span>
          </div>

          <div class="w-full items-center flex flex-wrap pb-1">
            <span
              class="task-detail_description break-words text-gray-500 italic"
              v-html="
                currentTaskStep.taskDescription ||
                $t('COMMON_LABEL_PLACEHOLDER_ADD_DESCRIPTION') ||
                'Add description ...'
              "
            ></span>
          </div>
        </section>

        <!-- FILES -->
        <section v-if="currentStepAttachments?.length > 0">
          <div class="w-full flex items-center space-x-2">
            <span class="text-sm flex items-center text-gray-500">
              {{
                `${$t('TASK_DETAIL_LABEL_ATTACHMENT') || 'Attachments'} ${
                  totalByKeys?.FILE + totalByKeys?.NOTE > 0
                    ? '(' + (totalByKeys?.FILE + totalByKeys?.NOTE) + ')'
                    : ''
                }`
              }}
            </span>
          </div>

          <div
            v-if="currentStepAttachments?.length > 0"
            class="w-full items-center flex flex-wrap pb-1"
          >
            <div
              v-for="(file, index) in currentStepAttachments"
              :key="index"
              class="relative w-max h-16 mr-2 mb-2 hover-to-hidden__parent"
            >
              <UploadPreview
                class="m-0"
                :file="file"
                readonly
                :is-note="file.isNote"
                is-hidden-info
                @on-select="onOpenFiles(file)"
              />
            </div>
          </div>
        </section>

        <!-- CHECK LIST -->
        <section v-if="totalByKeys?.CHECKLIST > 0">
          <div class="w-full flex items-center space-x-2 pb-1">
            <span class="text-sm flex items-center text-gray-500">
              {{
                `${$t('COMMON_LABEL_CHECKLIST') || 'Attachments'} ${
                  totalByKeys?.CHECKLIST > 0
                    ? '(' + totalByKeys?.CHECKLIST + ')'
                    : ''
                }`
              }}
            </span>
          </div>
          <section style="max-height: 28rem">
            <TaskTodoListAll
              ref="taskTodoListAllRef"
              :task-id="currentTaskStep?.taskId"
              :todo-list="currentTaskStep?.todoLists"
              readonly
            />
          </section>
        </section>
      </div>
    </div>

    <div
      v-if="isBlinkStepName"
      class="
        max-w-[50%]
        absolute
        right-0
        h-full
        bg-current
        text-white
        flex-center flex-wrap
        gap-2
        px-4
        truncate
      "
    >
      <span>
        {{ $t('WORKFLOW_LABEL_YOU_HAVE_MOVED_TO_STEP') }}
      </span>
      <WorkflowStepCodeLabel
        :model-value="currentTaskStep?.code || ''"
        readonly
      />
      <span class="font-semibold">
        {{ currentTaskStep?.name }}
      </span>
    </div>
  </div>

  <TaskWorkflowModal
    v-if="isOpenDetail"
    :task-workflow="taskWorkflowData"
    :task-detail="taskDetail"
    @update:workflow-steps="isNeedUpdateTask = true"
    @cancel="onCloseDetailModal"
  />

  <ApplyWorkFlowModal
    v-if="isOpenWorkFlow"
    :task-workflow="toRaw(taskWorkflowData)"
    :domain-id="taskDetail?.domainId"
    :group-id="taskDetail?.groupId"
    :task-detail="taskDetail"
    @cancel="isOpenWorkFlow = false"
    @on-attach="updateTaskWorkflow"
  />

  <ModalMultipleFileViewer
    v-if="isPreview && currentStepAttachments?.length > 0"
    ref="modalMultipleFileViewerRef"
    :start-index="currentFileIndex"
    :files="currentStepAttachments"
    :editable="false"
    :current-task="currentTaskStep"
    @on-close="isPreview = false"
  >
    <template #title>
      <span v-html="`${currentTaskStep?.code}. ${currentTaskStep?.name}`">
      </span>
    </template>
  </ModalMultipleFileViewer>

  <TaskWorkflowStepDetailModal
    v-if="isOpenUpdateTaskWorkflowStepDetailModal"
    :step-detail="{
      ...currentTaskStep,
      taskId: taskDetail?.id || 0,
    }"
    :view-mode="viewMode"
    :task-detail="taskDetail"
    :task-workflow-id="taskDetail?.taskWorkflowId"
    :readonly="readonly"
    is-update-only-attachment
    @close="isOpenUpdateTaskWorkflowStepDetailModal = false"
    @update-success="updateSuccess"
  />
</template>
