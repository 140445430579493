<script setup lang="ts">
import { computed, ref } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { parseHTML } from '@/ui/hooks/commonFunction';
import orgStore from '@/store/organization';
import getUserInfo from '@/ui/helpers/user-helper';
import getGroupInfo from '@/ui/helpers/group-helper';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
import settingStore from '@/store/setting';
import ManageCollaborator from '@/ui/modules/task/detail/assign/ManageCollaborator.vue';
import UpdateDomain from '@/ui/modules/task/detail/UpdateDomain.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';

defineProps<{}>();
const emit = defineEmits(['onSave', 'cancel']);

const _orgStore = orgStore();
const _settingStore = settingStore();

const isValidate = ref<boolean>(false);
const formData = ref<{
  groupId?: number;
  assigneeId?: number;
  collaboratorIds?: number[];
  domainId: number;
  projectId?: number;
  subprojectId?: number;
  collaborators?: any[];
}>({
  assigneeId: getCurrentUserId(),
  domainId: 0,
});

const ownerTaskInfo = computed<any>(() => {
  if (formData.value?.groupId) return getGroupInfo(formData.value?.groupId);
  return getUserInfo(formData.value?.assigneeId);
});

const modePrivate = computed<
  | 'USER_TASK_PUBLIC'
  | 'USER_TASK_PRIVATE'
  | 'GROUP_TASK_PUBLIC'
  | 'GROUP_TASK_PRIVATE'
>(() => {
  if (formData.value?.groupId) {
    return ownerTaskInfo.value?.isPrivate
      ? 'GROUP_TASK_PRIVATE'
      : 'GROUP_TASK_PUBLIC';
  }
  return 'USER_TASK_PUBLIC';
});

const orgInfo = computed(() => _orgStore.organizationInfo);
const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting?.taskManagement?.multipleAssigneeSetting
);
const textPrivateParser = computed(() => {
  // GROUP_TASK_PRIVATE
  if (modePrivate.value == 'GROUP_TASK_PRIVATE')
    return parseHTML(
      translate('TASK_CREATE_IS_PUBLIC_GROUP', {
        groupName: getGroupInfo(formData.value?.groupId)?.name,
      })
    );

  return parseHTML(
    translate('TASK_CREATE_IS_PUBLIC', { orgName: orgInfo.value?.name })
  );
});
const isOpenDropdown = ref<boolean>(false);

const updateDomainRef = ref(null as any);
const onSaveAssign = async (group, user) => {
  // isOpenChangeAssign.value = false;
  const groupId = group?.id;
  const userId = user?.userId;

  formData.value.assigneeId = userId || null;
  formData.value.groupId = groupId || null;

  updateDomainRef.value?.updateDomainAfterAssigneeChanged(userId, groupId);
};

const isEditCollaborator = ref<boolean>(false);
const manageCollaboratorRef = ref<any>(null);

const onCloseEditCollaborator = () => {
  manageCollaboratorRef.value?.onCloseEditMode();
};

const onChangeCollaborator = async (collaborators) => {
  const newCollaborators = [
    ...(formData.value?.collaborators || []),
    ...collaborators?.map((user) => {
      return {
        ...user,
        memberUserId: user?.id || user?.userId,
      };
    }),
  ];
  const collaboratorByIds = newCollaborators?.reduce(
    (currentObj, currentItem) => {
      return {
        ...currentObj,
        [currentItem?.memberUserId]: currentItem,
      };
    },
    {}
  );

  formData.value.collaboratorIds = [
    ...new Set(newCollaborators?.map((data) => data?.memberUserId)),
  ];

  formData.value.collaborators = formData.value.collaboratorIds?.map(
    (userId) => collaboratorByIds[userId]
  );
};
const onRemoveCollaborator = async (userId) => {
  formData.value.collaboratorIds = formData.value.collaboratorIds?.filter(
    (id) => id !== userId
  );

  formData.value.collaborators = formData.value.collaborators?.filter(
    (col) => col?.memberUserId !== userId
  );
};

const onSaveDomain = ({ domainId, projectId, subprojectId }) => {
  formData.value.domainId = domainId;
  formData.value.projectId = projectId;
  formData.value.subprojectId = subprojectId;
};

const onCancelForm = () => {
  emit('cancel');
};

const isLoadingSubmitButton = ref<boolean>(false);
const onSubmitForm = () => {
  isValidate.value = false;

  if (
    !formData.value?.domainId ||
    (!formData.value?.groupId && !formData.value?.assigneeId)
  ) {
    isValidate.value = true;
    return;
  }

  isLoadingSubmitButton.value = true;
  emit('onSave', formData.value);
};
</script>
<template>
  <div
    class="flex flex-col space-y-2 relative"
    style="max-width: 50rem"
    @click="onCloseEditCollaborator"
  >
    <button
      :title="$t('COMMON_LABEL_CANCEL')"
      class="
        w-8
        h-8
        cursor-pointer
        hover:text-current-500
        absolute
        top-0
        right-0
        flex-center
      "
      @click="$emit('cancel')"
    >
      <SynIcon name="close" custom-class="w-4 h-4 do-not-close-dropdown" />
    </button>
    <div
      class="flex items-center space-x-2 p-4"
      :class="isOpenDropdown ? 'bg-gray-100' : ''"
    >
      <SynIcon
        :name="
          modePrivate == 'GROUP_TASK_PRIVATE' ||
          modePrivate == 'USER_TASK_PRIVATE'
            ? 'private'
            : 'global'
        "
        class="fill-gray-500"
        custom-class="h-3 w-3"
      />
      <span class="text-sm text-gray-600">
        {{ textPrivateParser }}
      </span>
    </div>
    <!-- CHANGE ASSIGNEE -->
    <section v-permission-model class="w-full px-4 flex items-center space-x-4">
      <span class="text-xs w-28 text-gray-500 flex items-center h-8">
        {{ $t('TASK_LABEL_ASSIGNEE') || 'Responsible' }}
      </span>
      <div
        class="flex-1 flex space-x-1"
        :class="isEditCollaborator ? 'flex-col items-start' : 'items-center'"
      >
        <div style="max-width: 26rem; min-width: 10rem">
          <ChangeAssign
            ref="changeAssignRef"
            v-permission="'FunctionTaskAssign'"
            custom-class="bg-gray-50 mr-2 px-1 rounded do-not-close-dropdown"
            is-update-instant
            can-remove-all
            :group-id="formData.groupId"
            :user-id="formData.assigneeId"
            @on-save="onSaveAssign"
          />
        </div>
        <!-- COLLABORATOR -->
        <template
          v-if="
            multipleAssigneeSetting?.systemEnable &&
            multipleAssigneeSetting?.organizationEnable
          "
        >
          <div class="flex-center">
            <ManageCollaborator
              ref="manageCollaboratorRef"
              v-model:is-edit-mode="isEditCollaborator"
              class="flex-1"
              :group-id="formData.groupId || 0"
              :assignee-id="formData.assigneeId || 0"
              :collaborators="formData.collaborators"
              :total-show-item="2"
              is-not-close-dropdown-parent
              @on-add="onChangeCollaborator"
              @on-remove="onRemoveCollaborator"
            />
          </div>
        </template>
      </div>
    </section>
    <!-- END ASSIGNMENT -->

    <!-- DOMAIN  -->
    <section v-permission-model class="w-full px-4 flex items-center space-x-4">
      <div class="w-28 flex items-center justify-between space-x-1 relative">
        <span class="text-xs text-gray-500 flex items-center h-8">
          {{ $t('DOMAIN_LABEL_TITLE') }}
        </span>
      </div>

      <div class="flex flex-col">
        <div class="flex space-x-1 text-xs">
          <UpdateDomain
            ref="updateDomainRef"
            :task-detail="formData"
            @save="onSaveDomain"
          />
        </div>
        <span
          v-if="isValidate && !formData.domainId"
          class="text-red-500 text-xs"
        >
          *
          {{
            $t('TASK_CREATE_FORM_TASK_CREATE_FORM_IS_REQUIRED') || 'is required'
          }}
        </span>
      </div>
    </section>
    <!-- END DOMAIN -->

    <!-- Footer zone -->
    <div class="flex items-center justify-end px-4 py-2 w-full bg-white">
      <div class="flex-center space-x-2">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL') || 'Cancel'"
          color="gray"
          type-text
          @click.stop="onCancelForm"
        />
        <SynButton
          v-vig-tooltip="$t('COMMON_LABEL_UPDATE') || 'Update'"
          :is-loading="isLoadingSubmitButton"
          @click="onSubmitForm"
        >
          <div class="flex items-center justify-between px-1 space-x-1">
            <span class="text-medium text-white">{{
              $t('COMMON_LABEL_UPDATE') || 'Update'
            }}</span>
          </div>
        </SynButton>
      </div>
    </div>
  </div>
</template>
