import { Plugin } from 'ckeditor5/src/core';

export default class CustomLinkMentionPlugin extends Plugin {
    init() {
        const editor = this.editor;

        editor.conversion.for('downcast').attributeToElement({
            model: 'mention',
            view: (modelAttributeValue, { writer }) => {
                if (!modelAttributeValue) {
                    return;
                }
                const userId = modelAttributeValue.userId;

                return writer.createAttributeElement(
                    'a',
                    {
                        class: 'mention',
                        rel: 'noopener noreferrer',
                        'data-mention': modelAttributeValue.id,
                        'data-user-id': userId,
                        'data-type': 'mention',
                        'data-mention-group': modelAttributeValue.mentionGroup,
                        href: modelAttributeValue.link,
                        userId: userId,
                        target: '_blank',
                    },
                    {
                        priority: 20,
                        id: modelAttributeValue.uid,
                    }
                );
            },
            converterPriority: 'high',
        });
    }
}
