<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 490.667 490.667"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          style=""
          d="M96,224c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571   L195.115,99.115c4.237-4.092,10.99-3.975,15.083,0.262c3.992,4.134,3.992,10.687,0,14.82L103.531,220.864   C101.535,222.866,98.827,223.994,96,224z"
          fill="#455a64"
          data-original="#455a64"
        ></path>
        <path
          style=""
          d="M394.667,224c-2.831,0.005-5.548-1.115-7.552-3.115L280.448,114.219   c-4.092-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l106.667,106.667c4.159,4.172,4.149,10.926-0.024,15.085   C400.18,222.875,397.482,223.994,394.667,224z"
          fill="#455a64"
          data-original="#455a64"
        ></path>
      </g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M53.333,202.667h384c29.455,0,53.333,23.878,53.333,53.333v128c0,29.455-23.878,53.333-53.333,53.333  h-384C23.878,437.333,0,413.455,0,384V256C0,226.545,23.878,202.667,53.333,202.667z"
        fill="#05b3b0"
        data-original="#4caf50"
        class=""
      ></path>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          style=""
          d="M53.333,394.667c-5.891,0-10.667-4.776-10.667-10.667V256c0-5.891,4.776-10.667,10.667-10.667h64   c5.891,0,10.667,4.776,10.667,10.667c0,5.891-4.776,10.667-10.667,10.667H64V384C64,389.891,59.224,394.667,53.333,394.667z"
          fill="#fafafa"
          data-original="#fafafa"
          class=""
        ></path>
        <path
          style=""
          d="M96,330.667H53.333c-5.891,0-10.667-4.776-10.667-10.667c0-5.891,4.776-10.667,10.667-10.667H96   c5.891,0,10.667,4.776,10.667,10.667C106.667,325.891,101.891,330.667,96,330.667z"
          fill="#fafafa"
          data-original="#fafafa"
          class=""
        ></path>
        <path
          style=""
          d="M224,394.667c-2.831,0.005-5.548-1.115-7.552-3.115l-45.781-45.803V384   c0,5.891-4.776,10.667-10.667,10.667s-10.667-4.776-10.667-10.667V256c0-5.891,4.776-10.667,10.667-10.667h32   c23.564,0,42.667,19.103,42.667,42.667S215.564,330.667,192,330.667h-6.251l45.781,45.781c4.171,4.16,4.179,10.914,0.019,15.085   C229.549,393.539,226.833,394.666,224,394.667z M170.667,309.333H192c11.782,0,21.333-9.551,21.333-21.333   c0-11.782-9.551-21.333-21.333-21.333h-21.333V309.333z"
          fill="#fafafa"
          data-original="#fafafa"
          class=""
        ></path>
        <path
          style=""
          d="M330.667,394.667h-64c-5.891,0-10.667-4.776-10.667-10.667V256c0-5.891,4.776-10.667,10.667-10.667   h64c5.891,0,10.667,4.776,10.667,10.667c0,5.891-4.776,10.667-10.667,10.667h-53.333v106.667h53.333   c5.891,0,10.667,4.776,10.667,10.667C341.333,389.891,336.558,394.667,330.667,394.667z"
          fill="#fafafa"
          data-original="#fafafa"
          class=""
        ></path>
        <path
          style=""
          d="M309.333,330.667h-42.667c-5.891,0-10.667-4.776-10.667-10.667c0-5.891,4.776-10.667,10.667-10.667   h42.667c5.891,0,10.667,4.776,10.667,10.667C320,325.891,315.224,330.667,309.333,330.667z"
          fill="#fafafa"
          data-original="#fafafa"
          class=""
        ></path>
        <path
          style=""
          d="M437.333,394.667h-64c-5.891,0-10.667-4.776-10.667-10.667V256c0-5.891,4.776-10.667,10.667-10.667   h64c5.891,0,10.667,4.776,10.667,10.667c0,5.891-4.776,10.667-10.667,10.667H384v106.667h53.333   c5.891,0,10.667,4.776,10.667,10.667C448,389.891,443.224,394.667,437.333,394.667z"
          fill="#fafafa"
          data-original="#fafafa"
          class=""
        ></path>
        <path
          style=""
          d="M416,330.667h-42.667c-5.891,0-10.667-4.776-10.667-10.667c0-5.891,4.776-10.667,10.667-10.667H416   c5.891,0,10.667,4.776,10.667,10.667C426.667,325.891,421.891,330.667,416,330.667z"
          fill="#fafafa"
          data-original="#fafafa"
          class=""
        ></path>
      </g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M245.333,160C215.878,160,192,136.122,192,106.667s23.878-53.333,53.333-53.333  s53.333,23.878,53.333,53.333S274.789,160,245.333,160z"
        fill="#607d8b"
        data-original="#607d8b"
        class=""
      ></path>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
