<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import domainManagementStore from '@/store/scope/domain-management-store';
import getUserInfo from '@/ui/helpers/user-helper';
import DoughnutChart from '@/ui/common/plugins/chart/DoughnutChart.vue';
import ListActiveMember from '@/ui/components/statistics/ListActiveMember.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconCustom.vue';
import LineChartDomainManagement from '@/ui/common/plugins/chart/LineChartDomainManagement.vue';
import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';
import SynListAvatar from '@/ui/common/molecules/SynAvatar/SynListAvatar.vue';
import DomainDetailModal from '@/ui/modules/domains/domain/DomainDetailModal.vue';
import SynCoundTimeRemain from '@/ui/common/molecules/SynWatch/SynCoundTimeRemain.vue';
import { useRouter } from 'vue-router';
import myProfile from '@/store/auth/my-profile';

const props = defineProps({
  domainId: {
    type: String,
    default: '',
  },
});

const emits = defineEmits(['onChangeTab', 'onUpdateInfomation']);

const router = useRouter();
const _domainManagementStore = domainManagementStore();
const _myProfile = myProfile();

const domainId = computed(() => props?.domainId);
const isOwner = computed(() => _myProfile.isOwner);
const isLoadingData = computed(() => _domainManagementStore.isLoadingData);
const domainTodayStatistic = computed(
  () => _domainManagementStore.domainTodayStatistic
);
const domainTaskStatisticChart = computed(
  () => _domainManagementStore.domainTaskStatisticChart
);
const labelDomainTaskStatisticChart = computed(
  () => _domainManagementStore.labelDomainTaskStatisticChart
);
const domainTaskOverview = computed(
  () => _domainManagementStore.domainTaskOverview
);
const domainTaskMemberActive = computed(
  () => _domainManagementStore.domainTaskMemberActive
);
const domainFullDetail = computed(
  () => _domainManagementStore.domainFullDetail
);
const creatorDomain = computed(() =>
  getUserInfo(domainFullDetail.value.creatorId)
);
const timeDomainActivedData = computed(
  () => _domainManagementStore.timeDomainActivedData
);
// const domainTaskOverviewStatisticData = computed(() => _domainManagementStore.domainTaskOverviewStatisticData);

const isOpenEditDomain = ref({
  isOpen: false,
  tab: '',
});

const initData = async () => {
  await _domainManagementStore.getAllDataDomainManagement(domainId.value);
  // console.log('creatorDomain', getUserInfo(domainFullDetail.value.creatorId));
};

initData();

onMounted(() => {});

const onOpenEditDomain = (tab, isOpen) => {
  isOpenEditDomain.value.isOpen = isOpen;
  isOpenEditDomain.value.tab = tab;
};

const onUpdateInformation = async () => {
  await _domainManagementStore.getDomainFullDetail(domainId.value);
  emits('onUpdateInfomation');
  isOpenEditDomain.value.isOpen = false;
  isOpenEditDomain.value.tab = '';
};

const onDeleteSuccess = () => {
  router.replace({
    name: 'OverviewDomains',
  });
};

const onChangeTabTask = () => {
  emits('onChangeTab');
};
</script>

<template>
  <div
    class="
      w-full
      h-full
      bg-gray-50
      grid grid-cols-1
      xl:grid-cols-3
      gap-4
      p-4
      overflow-y-auto overflow-x-hidden
      small-scrollbar
    "
  >
    <!-- INFORMATION LEFT   -->
    <div class="grid grid-cols-1 gap-4 xl:col-span-2">
      <div class="grid grid-cols-1 gap-4">
        <!--  SECTION LEFT 1  - STATISTIC  -->
        <div class="grid grid-cols-2 gap-4">
          <!--  STATISTIC TODAY  -->
          <div class="rounded-md shadow bg-white p-6 flex flex-col h-full">
            <span class="font-medium text-xl text-gray-700">{{
              $t('CALENDAR_TITLE_TODAY')
            }}</span>
            <div v-if="isLoadingData" class="flex-center">
              <div class="flex flex-col space-y-2 w-2/3">
                <div class="flex flex-col space-y-1">
                  <span
                    class="bg-gray-200 rounded-full animate-pulse h-4 w-1/3"
                  ></span>
                  <span
                    class="bg-gray-200 rounded-full animate-pulse h-4 w-2/3"
                  ></span>
                </div>
              </div>
              <div
                class="
                  w-48
                  h-48
                  flex-center
                  rounded-full
                  bg-gray-100
                  animate-pulse
                "
              >
                <div
                  class="flex-center flex-col rounded-full bg-white"
                  style="width: 80%; height: 80%"
                ></div>
              </div>
            </div>

            <div v-else class="flex-center">
              <div class="hidden 2xl:flex flex-col space-y-2 w-2/3">
                <div
                  v-for="item in domainTodayStatistic?.dataChart"
                  :key="item"
                  class="flex items-start space-x-3"
                >
                  <span
                    class="rounded w-4 h-4 mt-3"
                    :style="`background-color: ${item?.color}`"
                  ></span>
                  <div
                    class="flex flex-col cursor-pointer"
                    @click="onChangeTabTask"
                  >
                    <div
                      class="flex items-end space-x-1 text-gray-700 font-medium"
                    >
                      <span class="text-3xl">{{ item.data }}</span>
                      <span class="">{{ $t('TASKTAB_LABEL_TASKS') }}</span>
                    </div>
                    <span class="text-sm text-gray-500">{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <div class="flex-center">
                <DoughnutChart
                  :prop-data="domainTodayStatistic?.dataChart"
                  :label-no-data="$t('COMMOM_LABEL_NOT_DATA_YET')"
                  :is-show-description="false"
                  is-show-progress
                  custom-class="w-48 h-48"
                  :progress="domainTodayStatistic?.progress"
                  id-chart="chart-task-today"
                />
              </div>
            </div>
          </div>

          <!--  ACTIVE MEMBER  -->
          <div class="rounded-md shadow bg-white p-6 flex flex-col h-full">
            <span class="font-medium text-xl text-gray-700 pb-2">{{
              $t('OVERVIEW_FINISHED_TASKS_ACTIVE_MEMBER')
            }}</span>
            <div
              v-if="isLoadingData"
              class="w-full h-full grid grid-cols-3 gap-3 rounded-md"
            >
              <div
                v-for="item in 3"
                :key="item"
                class="h-full bg-gray-200 rounded animate-pulse"
              ></div>
            </div>
            <div v-else class="flex-center w-full h-full">
              <ListActiveMember :user-list="domainTaskMemberActive" />
            </div>
          </div>
        </div>

        <!--  SECTION LEFT 2 - OVERVIEW TASK IN DOMAIN -->
        <div class="grid grid-cols-3 gap-4">
          <template v-if="isLoadingData">
            <div
              v-for="item in 3"
              :key="item"
              class="
                flex
                items-center
                space-x-3
                h-20
                p-4
                rounded-md
                bg-gray-200
                rounded
                animate-pulse
              "
            ></div>
          </template>
          <template v-else>
            <div
              v-for="item in Object.values(domainTaskOverview)"
              :key="item"
              :class="`flex items-center space-x-3 rounded-md cursor-pointer shadow bg-${item?.bgColor}-50 p-4`"
              @click="item.key === 'ACTIVE_TASK' ? onChangeTabTask() : ''"
            >
              <div
                :class="`flex-center w-12 h-12 rounded-full bg-${item?.color}`"
              >
                <SynIcon
                  :name="item?.icon"
                  is-active
                  custom-class="w-5 h-5 fill-white text-white"
                />
              </div>
              <div class="flex flex-col">
                <div class="flex space-x-2 items-end">
                  <span :class="`text-3xl font-medium text-${item?.color}`">
                    {{ item?.data }}
                  </span>
                  <span class="font-medium">
                    {{ $t('TASK_TABLE_LABEL_TASKS') }}
                  </span>
                </div>
                <span class="text-sm text-gray-500 text-overflow-hidden-line">
                  {{ $t(item?.label)?.toLowerCase() }}
                </span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!--  SECTION LEFT 3 - ACTIVITY  -->
      <div class="flex-1 h-full w-full items-center">
        <div class="rounded-md shadow bg-white p-6 flex flex-col w-full h-full">
          <div class="flex items-center justify-between w-full pb-4 px-2">
            <span class="font-medium text-xl text-gray-700">{{
              $t('COMMOM_LABEL_ACTIVITY')
            }}</span>
            <div class="flex items-center space-x-4">
              <div
                v-for="legend in domainTaskStatisticChart"
                :key="legend"
                class="flex-center space-x-1"
              >
                <span
                  class="w-2.5 h-2.5 rounded-full"
                  :style="`background-color: ${legend.borderColor}`"
                ></span>
                <span
                  class="font-medium text-sm"
                  :style="`color: ${legend.borderColor}`"
                  >{{ legend?.title }}</span
                >
              </div>
            </div>
          </div>
          <div
            v-if="isLoadingData"
            class="flex-1 items-center w-full h-full bg-gray-200 animate-pulse"
            style="max-height: 250px"
          ></div>
          <div
            v-else
            class="flex-1 items-center w-full h-full"
            style="max-height: 250px"
          >
            <LineChartDomainManagement
              :datasets="domainTaskStatisticChart"
              :labels-data="labelDomainTaskStatisticChart"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- INFORMATION RIGHT   -->
    <div
      class="
        h-full
        grid
        xl:grid-cols-1
        grid-cols-2
        gap-4
        order-first
        xl:order-last
      "
    >
      <!--  INFO DOMAIN    -->
      <div
        class="
          flex flex-col
          space-y-2
          bg-current-50 bg-opacity-30
          border border-current
          rounded-md
          p-6
          items-stretch
        "
      >
        <div v-if="isLoadingData" class="flex flex-col space-y-1">
          <span class="bg-gray-200 rounded-full animate-pulse h-4 w-1/3"></span>
          <span class="bg-gray-200 rounded-full animate-pulse h-4 w-2/3"></span>
        </div>
        <!--  INFORMATION  -->
        <div v-if="!isLoadingData" class="flex-1 w-full">
          <div class="flex items-start justify-between">
            <div class="flex flex-col space-y-4 w-2/3">
              <!-- NAME  -->
              <span
                :title="domainFullDetail?.name"
                class="font-medium text-2xl text-overflow-hidden-line"
              >
                {{ domainFullDetail?.name }}
              </span>
              <!-- DESCRIPTION  -->
              <span
                v-if="domainFullDetail?.description"
                :title="
                  domainFullDetail?.description || $t('COMMON_LABEL_NOTHING')
                "
                class="text-overflow-hidden text-gray-600 text-sm"
                >{{
                  domainFullDetail?.description || $t('COMMON_LABEL_NOTHING')
                }}
              </span>
              <!-- CREATED BY  -->
              <div class="flex items-center space-x-2">
                <SynAvatar
                  v-if="creatorDomain"
                  :src="creatorDomain?.avatarUrl || creatorDomain?.avatar"
                  :custom-class="`w-8 h-8 border border-current`"
                  :name="
                    creatorDomain?.fullName ||
                    `${creatorDomain?.lastName} ${creatorDomain?.firstName}`
                  "
                />
                <div class="flex flex-col text-xs">
                  <span class="text-gray-500 text-2xs"
                    >{{ $t('COMMON_LABEL_CREATED_BY') }}
                  </span>
                  <span class="font-medium"
                    >{{
                      domainFullDetail?.responsibilityFirstName
                        ? `${domainFullDetail?.responsibilityFirstName} ${domainFullDetail?.responsibilityLastName}`
                        : creatorDomain?.name
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="domainFullDetail?.responsibility"
              class="flex flex-col items-center"
            >
              <SynAvatar
                :src="domainFullDetail?.responsibilityAvatarUrl"
                :custom-class="`w-24 h-24 border border-current`"
                shape="rounded"
                :name="`${domainFullDetail?.responsibilityFirstName} ${domainFullDetail?.responsibilityLastName}`"
              />
              <span class="font-medium text-current-800 pt-1 text-center">{{
                `${domainFullDetail?.responsibilityFirstName} ${domainFullDetail?.responsibilityLastName}`
              }}</span>
              <span class="text-xs text-gray-500">{{
                $t('TASK_LABEL_ASSIGNEE')
              }}</span>
            </div>
          </div>
        </div>
        <!--  DATE START-END  -->
        <div
          v-if="!isLoadingData && domainFullDetail?.startDate"
          class="flex items-center space-x-4"
        >
          <div class="flex flex-col text-sm space-y-1">
            <span class="text-xs text-gray-500">{{
              $t('DOMAIN_LABEL_START_DATE') || 'Start date'
            }}</span>
            <span class="bg-current-100 bg-opacity-50 rounded py-0.5 px-1">
              <SynLabelDateTime
                format="date"
                :datetime="domainFullDetail?.startDate"
              />
            </span>
          </div>
          <div
            v-if="domainFullDetail?.endDate"
            class="flex flex-col text-sm space-y-1"
          >
            <span class="text-xs text-gray-500">{{
              $t('DOMAIN_LABEL_END_DATE') || 'End date'
            }}</span>
            <span class="bg-current-100 bg-opacity-50 rounded py-0.5 px-1">
              <SynLabelDateTime
                format="date"
                :datetime="domainFullDetail?.endDate"
              />
            </span>
          </div>
        </div>
        <!-- TIME REMAINING  -->
        <div
          v-if="!isLoadingData && timeDomainActivedData?.totalMiniSecondRemain"
          class="flex items-center"
        >
          <div class="flex flex-col" style="min-width: 60%">
            <span class="text-gray-500 text-2xs">{{
              timeDomainActivedData?.state === 'WAITING'
                ? $t('COMMOM_LABEL_PLANNDED_TIME')
                : $t('SYSTEM_DEPLOYMENT_SAVE_FLOW_WORKING')
            }}</span>
            <div class="flex items-end py-2">
              <SynCoundTimeRemain
                :total-miniseconds="
                  timeDomainActivedData?.totalMiniSecondRemain
                "
                auto-start
                is-day
                :is-pause="timeDomainActivedData?.state === 'WAITING'"
                :custom-class-value="
                  timeDomainActivedData.state === 'FINISHED'
                    ? 'font-semibold text-5xl 3xl:text-6xl text-red-400'
                    : 'font-semibold text-5xl 3xl:text-6xl text-current-500'
                "
                custom-class-text="font-medium text-xl 3xl:text-2xl text-current-600"
                format="dd:hh:mm"
              />
            </div>
          </div>
          <div
            class="flex-1 w-full items-center justify-center hidden 2xl:flex"
          >
            <DoughnutChart
              v-if="timeDomainActivedData.state === 'RUNNING'"
              id-chart="chart-time-remain"
              :prop-data="timeDomainActivedData?.dataChart"
              :progress="timeDomainActivedData?.progress"
              :is-show-description="false"
              :label-no-data="$t('COMMOM_LABEL_NOT_DATA_YET')"
              is-show-progress
              custom-class="w-32 h-32 3xl:w-36 3xl:h-36"
              text-class="text-2xl"
              :cut-out="50"
            />
            <div
              v-else
              class="w-36 h-36 flex-center rounded-full"
              :class="{
                'bg-blue-50': timeDomainActivedData.state === 'WAITING',
                'bg-red-100': timeDomainActivedData.state === 'FINISHED',
              }"
            >
              <div
                class="flex-center flex-col rounded-full bg-white"
                style="width: 80%; height: 80%"
              >
                <span class="text-xs text-gray-500 text-center px-1">
                  {{
                    timeDomainActivedData.state === 'WAITING'
                      ? $t('DOMAIN_MANAGEMENT_NOT_START_DOMAIN_YET')
                      : $t('DOMAIN_MANAGEMENT_DOMAIN_OVERDUE_DATE')
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  MEMBERS - GROUPS   -->
      <div class="grid grid-cols-1 flex-1 gap-4">
        <!-- MEMBERS  -->
        <div class="rounded-md bg-white shadow p-6 flex-center flex-col w-full">
          <div v-if="isLoadingData" class="flex flex-col space-y-1 w-full">
            <span
              class="bg-gray-200 rounded-full animate-pulse h-4 w-1/3"
            ></span>
            <span
              class="bg-gray-200 rounded-full animate-pulse h-4 w-2/3"
            ></span>
          </div>

          <div
            v-if="!isLoadingData"
            class="flex-center flex-wrap justify-between w-full"
          >
            <div class="flex space-x-2 items-center mb-6">
              <span class="font-medium text-5xl text-current-400">{{
                domainFullDetail?.memberCount
              }}</span>
              <div class="flex flex-col">
                <span class="font-medium text-current-400">{{
                  $t('DASHBOARD_TABLE_LABEL_MEMBER')
                }}</span>
                <span class="text-gray-500 text-sm">{{
                  $t('DOMAIN_MANAGEMENT_WORKING_ON_LABEL')
                }}</span>
              </div>
            </div>
            <div class="flex-center">
              <SynListAvatar
                :users="domainFullDetail?.members"
                is-slot-number
                slot-number-key="taskCount"
                custom-class="w-12 h-12"
                :max-length="5"
              />
              <div
                class="
                  flex-center
                  mx-2
                  mb-6
                  cursor-pointer
                  w-12
                  h-12
                  border border-dashed border-current-100
                  rounded-full
                "
                @click="onOpenEditDomain('MEMBERS', true)"
              >
                <syn-icon
                  name="Plus"
                  custom-class="w-4 h-4 fill-current text-current-500"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- GROUPS  -->
        <div class="rounded-md bg-white shadow p-6 flex-center flex-col w-full">
          <div v-if="isLoadingData" class="flex flex-col space-y-1 w-full">
            <span
              class="bg-gray-200 rounded-full animate-pulse h-4 w-1/3"
            ></span>
            <span
              class="bg-gray-200 rounded-full animate-pulse h-4 w-2/3"
            ></span>
          </div>

          <div
            v-if="!isLoadingData"
            class="flex-center flex-wrap justify-between w-full"
          >
            <div class="flex space-x-2 items-center mb-6">
              <span class="font-medium text-5xl text-current-400">{{
                domainFullDetail?.groupCount
              }}</span>
              <div class="flex flex-col">
                <span class="font-medium text-current-400">{{
                  $t('DOMAIN_LABEL_GROUPS')
                }}</span>
                <span class="text-gray-500 text-sm">{{
                  $t('DOMAIN_MANAGEMENT_RELATED_ON_LABEL')
                }}</span>
              </div>
            </div>
            <div class="flex-center">
              <SynListAvatar
                :users="domainFullDetail?.groups"
                is-slot-number
                slot-number-key="taskCount"
                custom-class="w-12 h-12"
                :max-length="5"
              />
              <div
                class="
                  flex-center
                  mx-2
                  mb-6
                  cursor-pointer
                  w-12
                  h-12
                  border border-dashed border-current-100
                  rounded-full
                "
                @click="onOpenEditDomain('GROUPS', true)"
              >
                <syn-icon
                  name="Plus"
                  custom-class="w-4 h-4 fill-current text-current-500"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- DEPARTMENT  -->
        <div
          v-if="isOwner"
          class="rounded-md bg-white shadow p-6 flex-center flex-col w-full"
        >
          <div v-if="isLoadingData" class="flex flex-col space-y-1 w-full">
            <span
              class="bg-gray-200 rounded-full animate-pulse h-4 w-1/3"
            ></span>
            <span
              class="bg-gray-200 rounded-full animate-pulse h-4 w-2/3"
            ></span>
          </div>

          <div
            v-if="!isLoadingData"
            class="flex-center flex-wrap justify-between w-full"
          >
            <div class="flex space-x-2 items-center mb-6">
              <span class="font-medium text-5xl text-current-400">{{
                domainFullDetail?.departmentCount
              }}</span>
              <div class="flex flex-col">
                <span class="font-medium text-current-400">{{
                  $t('COMMON_MODULE_MANAGEMENT_DEPARTMENT')
                }}</span>
                <span class="text-gray-500 text-sm">{{
                  $t('DOMAIN_MANAGEMENT_RELATED_ON_LABEL')
                }}</span>
              </div>
            </div>
            <div class="flex-center pr-2">
              <template v-if="isOwner && domainFullDetail?.departments?.length">
                <SynListAvatar
                  :groups="domainFullDetail?.departments"
                  type="GROUP"
                  custom-class="w-12 h-12"
                  :max-length="5"
                />
              </template>

              <span
                v-if="!domainFullDetail?.departments?.length"
                class="text-gray-400 text-sm"
                >{{ $t('DEPARTMENT_LABEL_USER_HAS_NOT_DEPARTMENT') }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <DomainDetailModal
    v-if="isOpenEditDomain?.isOpen"
    :domain-id="domainFullDetail?.id"
    :default-tab="isOpenEditDomain?.tab"
    @on-close="onOpenEditDomain('', false)"
    @on-save-success="onUpdateInformation"
    @on-delete-success="onDeleteSuccess"
  />
</template>
