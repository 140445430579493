/* eslint-disable no-unused-vars */
export const ringingTime = 60;
export type ConferenceHosterType = {
    id: number;
    name: string;
    avatar: string;
};
export enum CallParticipantStatus {
    Ringing = 'ringing',
    OnCall = 'on_call',
    FailedToStart = 'failed_to_start',
    Missed = 'missed',
    Hangup = 'hangup',
}
export enum CallHostStatus {
    Started = 'started',
    OnCall = 'on_call',
    FailedToStart = 'failed_to_start',
    Hangup = 'hangup',
}
export enum AudioVideoCallStatusEnum {
    Started = 'started',
    OnCall = 'on_call',
    FailedToStart = 'failed_to_start',
    Hangup = 'hangup',
}

export enum ConferenceTypeEnum {
    Audio = 'audio',
    Video = 'video',
}
export enum CallActionTypeEnum {
    Invited = 'Invited',
}
export const DurationList = [5, 10, 15, 30, 45];

export enum ActionOnCallType {
    Accept = 1,
    Decline = 2,
    Missed = 3,
}

export enum CallType {
    Audio = 1,
    Video = 2,
}

export enum AnswerState {
    Accept = 1,
    Decline = 2,
}

export enum CallDeviceState {
    Open = 1,
    Close = 2,
}

export interface UserCallingStatus {
    id: number;
    status: string;
    joinToken: string;
    callId: string;
}
