<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import myProfileStore from '@/store/auth/my-profile';
import userStore from '@/store/user';
import overviewTeamStore from '@/store/dashboard/overview-team-store';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
import { lastUpdateFromRealtime } from '@/ui/pages/calendars/calendar-state';
import groupStore from '@/store/group';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const currentRoute = useRoute();
const _overviewGroupStore = overviewGroupStore();
const _groupStore = groupStore();
let { unsubscribeCallbacks } = listenFirebaseRealtime();

const isShowNotification = ref(false);
const _userStore = userStore();
const _overviewTeamStore = overviewTeamStore();
const _myProfileStore = myProfileStore();

const myDepartment = computed<any>(() => _myProfileStore.myDepartment);
const handleLoadDataAfterChangeDepartment = async () => {
  const promises: any = [];
  _overviewTeamStore.isLoading = true;
  _overviewGroupStore.isLoading = true;
  await Promise.all([
    myProfileStore().fetchCurrentLoginUser(),
    _userStore.getShortInfosUser(),
    _groupStore.fetchMyGroups(),
  ]);

  switch (currentRoute.name) {
    case 'OverviewMembers':
      promises.push(_overviewTeamStore.getOverviewTeam());
      break;
    case 'OverviewGroups':
      {
        promises.push(_overviewGroupStore.getOverviewGroup(1));
      }
      break;
    case 'Calendars':
      lastUpdateFromRealtime.value = Date.now();
      break;

    default:
      break;
  }
  Promise.all(promises);
};
const currentDataRealtime = ref<{
  action:
    | 'ADD_MEMBER'
    | 'REMOVE_MEMBER'
    | 'MOVE_MEMBER'
    | 'ADD_DEPARTMENT'
    | 'REMOVE_DEPARTMENT';
  actionTime: number;
  newDepartment: any;
  oldDepartment: any;
} | null>();

const onListen = () => {
  const currentUserId = myProfileStore().myProfile?.id;
  if (!currentUserId) return;

  const fbClass = new UserDynamicModel(`user_${currentUserId}/department`);

  unsubscribeCallbacks.value.push(
    fbClass.onChange((data) => {
      const seen =
        data?.action == 'REMOVE_DEPARTMENT' || data?.action == 'REMOVE_MEMBER'
          ? !myDepartment.value || !myDepartment.value?.departmentId
          : data?.newDepartment?.id == myDepartment.value?.departmentId;

      if (Date.now() - data?.actionTime > 60 * 1000 || seen) {
        currentDataRealtime.value = null;
        return;
      }

      currentDataRealtime.value = data;
      isShowNotification.value = true;
      handleLoadDataAfterChangeDepartment();
    })
  );
};

const onCancel = () => {
  isShowNotification.value = false;
};
const onConfirm = () => {
  isShowNotification.value = false;
};

onListen();
</script>
<template>
  <SynModal
    v-if="isShowNotification && currentDataRealtime"
    container-class="w-max"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    is-hidden-close
    @cancel="onCancel"
  >
    <template #body>
      <div class="flex-center flex-col space-y-8" style="width: 40rem">
        <div class="flex-center w-full pt-4 text-current-400">
          <div class="w-20">
            <VigImage
              :path="`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/images/department_active.png`"
            />
          </div>
        </div>
        <div class="flex flex-col text-center space-y-4 px-8">
          <!-- ADD MEMBERS -->
          <span
            v-if="
              currentDataRealtime?.action == 'ADD_MEMBER' ||
              currentDataRealtime?.action == 'ADD_DEPARTMENT'
            "
            class="text-gray-500"
            v-html="
              $t('DEPARTMENT_REALTIME_USER_ADD', {
                newDepartmentName:
                  currentDataRealtime?.newDepartment?.name || '',
              })
            "
          >
          </span>

          <!-- MOVE MEMBERS -->
          <span
            v-if="currentDataRealtime?.action == 'MOVE_MEMBER'"
            class="text-gray-500"
            v-html="
              $t('DEPARTMENT_REALTIME_USER_MOVE', {
                oldDepartmentName:
                  currentDataRealtime?.oldDepartment?.name || '',
                newDepartmentName:
                  currentDataRealtime?.newDepartment?.name || '',
              })
            "
          >
          </span>

          <!-- REMOVE MEMBERS -->
          <span
            v-if="
              currentDataRealtime?.action == 'REMOVE_DEPARTMENT' ||
              currentDataRealtime?.action == 'REMOVE_MEMBER'
            "
            class="text-gray-500"
            v-html="
              $t('DEPARTMENT_REALTIME_USER_REMOVE', {
                newDepartmentName:
                  currentDataRealtime?.oldDepartment?.name || '',
              })
            "
          >
          </span>
        </div>
        <div class="w-full flex items-center justify-end space-x-2">
          <SynButton
            :title="'Join an existed workspace'"
            class="rounded-full bg-current"
            @click="onConfirm"
          >
            <span>
              {{ $t('COMMON_LABEL_GOT_IT') || 'Got it' }}
            </span>
          </SynButton>
        </div>
      </div>
    </template>
  </SynModal>
</template>
