<script setup lang="ts">
import { GoogleUser } from '@/ui/plugins/google/drive';
import { GoogleService } from '@/domain/enums/google.enum';
import driveDocumentStore from '@/store/driveDocument';
import calendarSyncStore from '@/store/calendar/calendar-sync';

const props = defineProps<{
  service: GoogleService;
  user: GoogleUser | any;
  hasActions: boolean;
  actionClass: string;
}>();

const _driveDocumentStore = driveDocumentStore();
const _calendarSyncStore = calendarSyncStore();

const onChangeAccountClick = () => {
  switch (props.service) {
    case GoogleService.CALENDAR:
      return _calendarSyncStore.signInUserCalendarSync();
    case GoogleService.DRIVE:
      return _driveDocumentStore.signInGoogleDriveAccount();
  }
};

const onSignOutClick = () => {
  switch (props.service) {
    case GoogleService.CALENDAR:
      return _calendarSyncStore.signOutUserCalendarSync();
    case GoogleService.DRIVE:
      return _driveDocumentStore.signOutGoogleDriveAccount();
  }
};
</script>

<template>
  <div class="flex gap-3 items-start">
    <img
      :src="user?.driveUserAvatar || user?.avatar"
      alt=""
      class="w-8 h-8 rounded-full overflow-hidden mt-1.5"
    />
    <div class="flex-1 overflow-hidden">
      <div>{{ user?.driveUserName || user?.displayName || user?.name }}</div>
      <div
        :class="[
          'flex flex-wrap gap-x-3 gap-y-1',
          actionClass || 'items-center',
        ]"
      >
        <div class="text-sm">{{ user?.driveUserEmail || user?.email }}</div>
        <div
          v-if="user && hasActions"
          class="text-xs text-current-500 truncate"
        >
          <a href="javascript:" @click="onChangeAccountClick()">
            {{ $t('GED_GOOGLE_CHANGE_ACCOUNT') }}
          </a>
          <span class="text-gray-500"> · </span>
          <a href="javascript:" @click="onSignOutClick()">
            {{ $t('GED_GOOGLE_SIGN_OUT') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
