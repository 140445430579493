import { translate } from '@/ui/plugins/i18n/myi18n';
import { FileUrlBase } from '@/ui/common/constants/drive';

export const useFormValidation = () => {
    const isValidUrl = (url: string) => {
        if (!url?.trim()) {
            return translate('GED_LINK_URL_REQUIRED_MSG');
        }

        const limitChars = 500;
        if (url?.length > limitChars) {
            return translate('GED_LINK_URL_MAX_LENGTH_MSG', {
                number: limitChars,
            });
        }

        const linkRegex =
            /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&%'()*+,;=]+$/;
        if (!url?.match(linkRegex)) {
            return translate('GED_LINK_URL_INVALID_MSG');
        }

        return true;
    };

    const isValidGoogleDocUrl = (url: string) => {
        const isValid = isValidUrl(url);

        if (isValid !== true) return isValid;

        const validBaseUrls = Object.values(FileUrlBase);

        if (!validBaseUrls.some((baseUrl) => url?.startsWith(baseUrl))) {
            return translate('GED_GOOGLE_URL_INVALID_MSG');
        }

        return true;
    };

    const isValidLinkName = (name) => {
        if (!name?.trim()) {
            return translate('GED_LINK_NAME_REQUIRED_MSG');
        }

        const limitChars = 200;
        if (name?.length > limitChars) {
            return translate('GED_LINK_NAME_MAX_LENGTH_MSG', {
                number: limitChars,
            });
        }

        const specialChars = /[<>:"\\/|?*]/;
        if (specialChars.test(name)) {
            return translate('GED_LINK_NAME_SPECIAL_CHARS_MSG');
        }

        return true;
    };

    return {
        isValidUrl,
        isValidGoogleDocUrl,
        isValidLinkName,
    };
};
