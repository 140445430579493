<script setup lang="ts">
import datetimeComposable, {
  FormatType,
} from '@/ui/composables/common/datetime-composable';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    format?: FormatType;
    datetime: any;
    ignoreTimezone?: boolean;
    timezone?: number;
  }>(),
  {
    format: 'date',
    datetime: Date.now(),
    ignoreTimezone: false,
  }
);

const { formatDate } = datetimeComposable();

const convertedTime = computed<any>(() => {
  return formatDate(props.datetime, props.format, {
    isIgnoreTimezone: props.ignoreTimezone,
    timezone: props.timezone,
  });
});
</script>

<template>
  <span :title="convertedTime">
    {{ convertedTime }}
  </span>
</template>
