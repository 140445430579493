export const initializeLottiePlayer = () => {
    const script = document.createElement('SCRIPT');
    script.setAttribute(
        'src',
        '/js/plugins/lottie-player-2.4.0/lottie-player.js'
    );
    // script.setAttribute('crossorigin', 'anonymous');

    document.body.appendChild(script);
};
