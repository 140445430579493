<script setup lang="ts">
import { ref, computed } from 'vue';
import DayoffDetailModal from '@/ui/components/dayoff/DayoffDetailModal.vue';
import calendarsOverviewStore from '@/store/calendars/calendars-overview';
import DayoffDetailClass from '@/domain/entities/dayoff/DayoffDetailClass';
// import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
// import { AdminType, GroupMemberRole } from '@/ui/common/constants/constant';
import DayoffListItemVer2 from '@/ui/components/dayoff/atoms/DayoffListItemVer2.vue';
// import myProfileStore from '@/store/auth/my-profile';
// import settingStore from '@/store/setting';

// const _settingStore = settingStore();
// const _myProfileStore = myProfileStore();
// const myDepartment = computed(() => _myProfileStore.myDepartment);
// const departmentManagementEnabled = computed(
//   () => _settingStore._organizationSetting.departmentManagement.enabled
// );

// const isAdmin = computed(() => {
//   return (
//     hasRolePermission(AdminType.Admin) ||
//     hasRolePermission(AdminType.Owner) ||
//     (departmentManagementEnabled.value &&
//       myDepartment.value?.role == GroupMemberRole.Admin)
//   );
// });

const _calendarsOverviewStore = calendarsOverviewStore();

// const initData = () => {
//   if (!isAdmin.value) return;
//   _calendarsOverviewStore.getListPending();
// };

const isOpenVigDrawer = ref(true);

const currentDayoffDetailId = ref('');
const onOpenDetail = (dayoff: DayoffDetailClass) => {
  currentDayoffDetailId.value = dayoff.id;
};
const onCloseDetail = () => {
  currentDayoffDetailId.value = '';
};

const pendingList = computed<DayoffDetailClass[]>(
  () => _calendarsOverviewStore.pendingList
);

// initData();
</script>
<template>
  <div
    class="
      flex
      h-full
      transition
      transform
      flex-1
      min-h-0
      overflow-y-auto
      small-scrollbar
      bg-white
      p-4
      flex-col
      space-y-4
    "
    :class="[
      isOpenVigDrawer ? 'translate-x-0 ease-out' : 'translate-x-full ease-in',
    ]"
    style="width: 100%; max-width: 100%"
  >
    <!-- <div class="flex-col">
        <div class="uppercase">pending requests</div>
        <div class="text-gray-600 text-sm">All employees pending requests</div>
      </div> -->
    <div v-if="pendingList?.length > 0" class="flex flex-col divide-y">
      <div
        v-for="(dayoff, index) in pendingList"
        :key="index"
        class="flex items-center justify-between py-2 cursor-pointer"
        @click="onOpenDetail(dayoff)"
      >
        <DayoffListItemVer2 :dayoff-data="dayoff" />
      </div>
    </div>
    <div v-else class="h-full flex flex-col space-y-2">
      <SynAnimation :name="'no-ticket-data'" :stype="'width: 200px;'" />
    </div>
  </div>

  <DayoffDetailModal
    v-if="currentDayoffDetailId"
    :id="currentDayoffDetailId"
    @close="onCloseDetail"
  />
</template>

<style lang="scss" scoped></style>
