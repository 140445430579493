<script setup lang="ts">
import { computed } from 'vue';
import dashboardStore from '@/store/dashboard';
import { genColorByName } from './colorByAlphabet';
import userStore from '@/store/user';
import myProfileStore from '@/store/auth/my-profile';
import { getShortName } from '@/ui/common/molecules/SynAvatar/syn-avatar.const.ts';

const props = withDefaults(
  defineProps<{
    type?: 'group' | 'user' | 'domain';
    isHiddenName?: boolean;
    isOnCall?: boolean;
    customClass?: string;
    id?: string | number;
    avatar?: string;
    name?: string;
    isGroup?: boolean;
    isUser?: boolean;
    isVisitor?: boolean;
    isDeactive?: boolean;
    isDeleted?: boolean;
    isExternal?: boolean;
    isSupporting?: boolean;
    isDisallowed?: boolean;
    shortName?: string;
  }>(),
  {
    type: 'user',
    customClass: 'h-8 w-8',
  }
);

defineEmits(['isOpenUpdateAvatar']);

const _userStore = userStore();
const _dashboardStore = dashboardStore();
const imVisitor = computed(() => _userStore.isVisitor);
const userWorkingStatus = computed(() => _dashboardStore.userWorkingStatus);
const myProfile = computed(() => myProfileStore().myProfile);

const statusColor = computed(() => {
  let status = userWorkingStatus.value[props.id]?.status || 'offline';

  if (status == 'offline') {
    return 'bg-gray-300';
  }
  if (status == 'absent') {
    return 'bg-orange-400';
  }
  return 'bg-green-400';
});

const textAva = computed(() => {
  if (!props?.name) return '';
  if (props.shortName) return props.shortName;
  return getShortName(props?.name);
});

const nameIconDefault = computed(() => {
  switch (props.type) {
    case 'user':
      return 'profile';
    case 'group':
      return 'group';
    case 'domain':
      return 'project';
    default:
      return 'project';
  }
});
</script>

<template>
  <div :title="name" class="flex items-center font-sans relative w-full">
    <!-- Avatar   -->
    <div class="relative shadow rounded-full" :class="customClass">
      <vig-image
        v-if="avatar"
        class="w-full h-full object-cover rounded-full"
        error-type="AVATAR"
        :path="avatar"
        :size="'small'"
      />
      <!-- <img
        v-if="avatarUser"
        class="w-full h-full object-cover rounded-full"
        :class="customClass"
        :src="avatarUser"
        :onError="onError"
      /> -->
      <div
        v-else
        class="
          object-cover
          w-full
          h-full
          flex-center
          text-white
          shadow
          font-thin
          rounded-full
        "
        :class="`${customClass} ${
          type !== 'domain' ? 'shadow' : 'border border-current'
        }`"
        :style="`background: ${
          type !== 'domain'
            ? textAva
              ? genColorByName(textAva)
              : '#4bb0b2'
            : '#ffff'
        }`"
      >
        <span
          v-if="textAva && type !== 'domain'"
          class="uppercase text-base overflow-hidden"
          >{{ textAva }}</span
        >
        <SynIconCustom
          v-else
          :name="nameIconDefault"
          is-active
          :custom-class="
            type !== 'domain'
              ? 'w-3 h-3 fill-white text-white'
              : 'w-3 h-3 fill-current text-current'
          "
        />
      </div>
      <div
        v-if="isOnCall"
        class="
          rounded-full
          bg-current-400 bg-opacity-40
          flex-center
          absolute
          top-0
        "
        :class="['rounded-md', customClass]"
      >
        <SynIcon custom-class="w-1/3 h-1/3 fill-white" name="phone" />
      </div>
      <div
        v-if="isDeleted"
        class="
          absolute
          bottom-0
          -right-1
          w-1/3
          h-1/3
          my-1
          border border-red-500
          bg-white
          rounded-full
          text-red-500
          flex-center
          font-medium
        "
      >
        <span class="pb-0.5">×</span>
      </div>
      <div
        v-else-if="isDeactive"
        class="
          absolute
          bottom-0
          -right-1
          w-1/3
          h-1/3
          my-1
          border border-red-500
          bg-white
          rounded-full
          text-red-500
          flex-center
          font-medium
        "
      >
        <span class="pb-0.5">-</span>
      </div>
      <div
        v-else-if="isExternal"
        class="
          absolute
          bottom-0
          -right-0.5
          bg-yellow-300
          flex-center
          p-1
          rounded-full
          shadow
          overflow-hidden
        "
      >
        <SynIcon custom-class="w-2.5 h-2.5 fill-yellow-900" name="external" />
      </div>
      <div
        v-else-if="isSupporting"
        class="
          absolute
          bottom-0
          -right-0.5
          flex-center
          bg-green-200
          rounded-full
          shadow
          overflow-hidden
        "
      >
        <SynIcon custom-class="w-5 h-5 fill-green-600" name="CustomerSupport" />
      </div>
      <!--      GROUP-->
      <div
        v-else-if="isGroup && !isSupporting"
        class="
          absolute
          bottom-0
          -right-0.5
          bg-white
          flex-center
          p-0.5
          rounded-full
          shadow
          overflow-hidden
        "
      >
        <SynIcon
          custom-class="w-3.5 h-3.5 fill-gray-500"
          name="group"
          is-active
        />
      </div>
      <div
        v-else-if="isUser"
        class="
          absolute
          bottom-0
          right-0
          w-1/4
          h-1/4
          my-1
          border border-white
          rounded-full
        "
        :class="statusColor"
      ></div>

      <slot name="avatar-suffix"></slot>
    </div>

    <!-- Name   -->
    <slot name="contact-name">
      <div v-if="!isHiddenName" class="flex flex-col flex-1">
        <div
          class="
            flex-1 flex
            items-center
            text-overflow-hidden-line
            space-x-2
            pl-2
          "
        >
          <span v-if="name"> {{ name }} </span>
          <span v-else class="italic">Tictop User</span>
          <slot name="suffix-name">
            <SynTag
              v-if="isUser && id == myProfile?.id"
              size="tiny"
              custom-class="px-1 border border-current-100 bg-white text-gray-800"
            >
              <span class="italic">
                {{ $t('COMMON_LABEL_ME') || 'Me' }}
              </span>
            </SynTag>
            <SynTag
              v-if="isVisitor && !imVisitor"
              size="tiny"
              custom-class="px-1 border border-current-100 bg-white text-gray-800"
            >
              <span class="italic">
                {{ $t('COMMON_LABEL_VISITOR') || 'Visitor' }}
              </span>
            </SynTag>
            <SynTag
              v-if="isDeleted"
              size="tiny"
              custom-class="px-2 bg-gray-100 text-gray-800 text-gray-800"
            >
              <span class="italic">
                {{ $t('COMMON_LABEL_DELETED') }}
              </span>
            </SynTag>
            <SynTag
              v-else-if="isDeactive"
              size="tiny"
              custom-class="px-2 bg-gray-100 text-gray-800 text-gray-800"
            >
              <span class="italic">
                {{ $t('USERMANAGEMENT_FORM_DEACTIVE') }}
              </span>
            </SynTag>
            <SynTag
              v-else-if="isDisallowed"
              size="tiny"
              custom-class="px-2 bg-gray-100 text-gray-800 text-gray-800"
            >
              <span class="italic">
                {{ $t('CHAT_NOT_ALLOWED') }}
              </span>
            </SynTag>
          </slot>
        </div>
        <slot name="information-more"></slot>
      </div>
    </slot>
    <slot name="suffix"></slot>
  </div>
</template>
