<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue';
import VerticalBarChart from '@/ui/common/plugins/chart/VerticalBarChart.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import taskDrawerStore from '@/store/task/drawer';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import UserByAction from '@/ui/components/user/UserByAction.vue';
// import TaskCreateLocal from '@/ui/modules/task/create/TaskCreateLocal.vue';
import { formatTaskCode, getDetailTaskUrl } from '@/ui/hooks/taskHook';
import { isOpenTaskDrawer } from '@/ui/modules/task/task-global-state';
import { taskStates } from '@/ui/hooks/taskHook';
import FilterTasks from '@/ui/modules/dashboard/FilterTasks.vue';
import createdTasksComposable from '@/ui/modules/dashboard/monitoring-department/created-tasks-composable';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import CustomLollipopChart from '@/ui/common/plugins/chart/CustomLollipopChart.vue';
import userStore from '@/store/user';
import domainStore from '@/store/scope';
import { orderBy } from 'lodash';

const props = defineProps<{
  departmentId?: number;
}>();

const _taskDrawerStore = taskDrawerStore();

const {
  isLoading,
  // getAllDataForCreatedTaskPage,
  createdTasks,
  createdTaskList,
  createdTaskByCreator,
  getCreatedTasks,
  createdTaskRankingByDomain,
  getCreatedTaskRankingByDomain,
  createdTaskLabels,
  createdTaskDatasets,
  todayInfo,
  getCreatedTaskOverview,
} = createdTasksComposable(props.departmentId);

const initData = () => {};

let fbUserDynamicModel: UserDynamicModel;
let fbOrganizationDynamicModel: OrganizationDynamicModel;
onMounted(() => {
  onListenUserDashboard();
  onListenOrganizationDashboard();
});

onUnmounted(() => {
  unscribleUserLastesTaskActivity && unscribleUserLastesTaskActivity();
  unscribleOrganizationLastesTaskActivity &&
    unscribleOrganizationLastesTaskActivity();
});

let lastUpdateTime: number;
let unscribleUserLastesTaskActivity;
const onListenUserDashboard = () => {
  const myUserId = getCurrentUserId();
  if (!myUserId) return;

  if (unscribleUserLastesTaskActivity) unscribleUserLastesTaskActivity();
  fbUserDynamicModel = new UserDynamicModel(
    `user_${myUserId}/dashboard/latestNewTaskActivity`
  );

  unscribleUserLastesTaskActivity = fbUserDynamicModel.onChange(() => {
    if (!lastUpdateTime) return (lastUpdateTime = Date.now());

    if (Date.now() - lastUpdateTime < 500) return;

    lastUpdateTime = Date.now();

    getCreatedTasks();
  });
};

let lastOrgUpdateTime: number;
let unscribleOrganizationLastesTaskActivity;
const onListenOrganizationDashboard = () => {
  if (unscribleOrganizationLastesTaskActivity)
    unscribleOrganizationLastesTaskActivity();

  fbOrganizationDynamicModel = new OrganizationDynamicModel(
    `dashboard/latestNewTaskActivity`
  );

  unscribleOrganizationLastesTaskActivity = fbOrganizationDynamicModel.onChange(
    () => {
      if (!lastOrgUpdateTime) return (lastOrgUpdateTime = Date.now());

      if (Date.now() - lastOrgUpdateTime < 500) return;

      lastOrgUpdateTime = Date.now();

      Promise.all([getCreatedTaskRankingByDomain(), getCreatedTaskOverview()]);
    }
  );
};

initData();

const openTaskDetail = (task) => {
  _taskDrawerStore.pushCurrentIds({
    id: task.taskId,
    name: task.name,
    code: task.code,
    tabType: 'DETAIL',
  });
};

// const assigneeId = ref(getCurrentUserId());
const currentTabId = computed(() => _taskDrawerStore.currentTabId);

const finishedByUserChartData = computed<
  {
    total: number;
    id: number;
    name: string;
    avatar: string;
  }[]
>(() => {
  return orderBy(createdTaskByCreator.value, ['createdTasksTotal'], ['desc'])
    ?.slice(0, 3)
    .map((o: any) => {
      return {
        total: o.createdTasksTotal,
        id: o.id,
        name: userStore().allUserByIds[o.id].name,
        avatar: userStore().allUserByIds[o.id].avatar,
      };
    });
});

const domainByIds = computed(() => domainStore().domainByIds);
const byDomainLabels = computed<string[]>(() => {
  const dataList = orderBy(
    createdTaskRankingByDomain.value,
    ['numberOfTaskCreated'],
    ['desc']
  )?.slice(0, 3);

  return dataList?.map((o) => o?.name || domainByIds.value[o.domainId]?.name);
});
const byDomainDatasets = computed<any[]>(() => {
  const dataList = orderBy(
    createdTaskRankingByDomain.value,
    ['numberOfTaskCreated'],
    ['desc']
  )?.slice(0, 5);
  return [
    {
      key: 'completedTasks',
      label: 'COMMON_LABEL_STATUS_FINISHED',
      data: dataList?.map((o) => o?.numberOfTaskCreated || 0),
      backgroundColor: '#4bb0b2',
      borderColor: '#4bb0b2',
      hoverBackgroundColor: '#008e8e',
      minBarLength: 2,
    },
  ];
});
</script>

<template>
  <SynLoading v-if="isLoading" />
  <div
    v-else
    v-permission-screen="{
      screenCode: ALL_SCREENS.COMMON.OVERVIEW.NEW_TASK,
    }"
    class="w-full h-full flex flex-col lg:flex-row relative overflow-hidden"
  >
    <div class="h-full w-full lg:w-1/2 p-2">
      <div
        class="w-full h-full flex flex-col gap-4 bg-white shadow rounded-lg p-4"
      >
        <div
          class="flex items-center justify-between space-x-2 font-semibold px-4"
        >
          <span class="text-gray-600 font-semibold text-sm">
            {{ $t('OVERVIEW_CREATED_TASKS_TODAY') || 'Tasks created today' }}
          </span>
          <!--        <SynTag-->
          <!--            v-if="createdTasks?.length || todayInfo?.numberOfTaskCreated"-->
          <!--            class="rounded-full w-max px-2 text-xs"-->
          <!--            size="small"-->
          <!--            :class="getBgClass(0, 'taskLife')"-->
          <!--        >-->
          <!--          <div class="flex-center space-x-2">-->
          <!--            <span>-->
          <!--              {{ $t('OVERVIEW_FINISHED_TASKS_YOU_SEE') || 'You see' }}-->
          <!--              ({{ createdTasks?.length || 0 }})-->
          <!--            </span>-->

          <!--            <span v-if="todayInfo?.numberOfTaskCreated">-->
          <!--              / {{ $t('COMMON_LABEL_TOTAL') || 'Total' }} ({{-->
          <!--                todayInfo.numberOfTaskCreated-->
          <!--              }})-->
          <!--            </span>-->
          <!--          </div>-->
          <!--        </SynTag>-->
          <div
            v-if="createdTasks?.length || todayInfo?.numberOfTaskCreated"
            class="flex items-center"
          >
            <div
              class="
                rounded-full
                w-max
                px-2
                py-1
                text-xs text-current-700
                bg-current-50
              "
            >
              <div class="flex-center space-x-2">
                <span>
                  {{ $t('OVERVIEW_FINISHED_TASKS_YOU_SEE') || 'You see' }}
                  ({{ createdTasks?.length || 0 }})
                </span>

                <span v-if="todayInfo?.numberOfTaskCreated">
                  / {{ $t('COMMON_LABEL_TOTAL') || 'Total' }} ({{
                    todayInfo.numberOfTaskCreated
                  }})
                </span>
              </div>
            </div>
            <FilterTasks
              v-if="createdTaskList?.length > 0"
              v-model:task-list="createdTasks"
            />
          </div>
          <!-- <div class="flex-center space-x-2">
          <SynIcon has-action name="filter" />
        </div> -->
        </div>
        <div class="w-full h-full flex flex-col pt-2">
          <SynTableCustom v-if="createdTasks?.length > 0">
            <template #body>
              <SynTr
                v-for="(task, index) in createdTasks"
                :key="task.id"
                :class="[
                  index % 2 == 0 ? 'bg-gray-50' : '',
                  currentTabId == task?.taskId && isOpenTaskDrawer
                    ? 'font-semibold'
                    : '',
                ]"
              >
                <SynTd class="relative text-xs py-2">
                  <span
                    v-if="currentTabId == task?.taskId && isOpenTaskDrawer"
                    class="absolute left-0 top-0 h-full border-2 border-current"
                  >
                  </span>
                  <UserByAction
                    v-if="task?.creatorId"
                    :user-id="task?.creatorId"
                    is-hidden-name
                    is-show-action-user
                    avatar-class="w-7 h-7"
                  />
                </SynTd>
                <SynTd
                  class="w-1/2 cursor-pointer hover:text-current"
                  @click.stop.prevent="openTaskDetail(task)"
                >
                  <a
                    :title="task.name"
                    :href="getDetailTaskUrl(task.code, false)"
                    class="w-full py-1 hover:text-current-500 cursor-pointer"
                  >
                    <div class="px-4 py-1 w-full h-full">
                      <!--                      <div class="flex items-center space-x-1">-->
                      <!--                        <div class="flex items-center space-x-1">-->
                      <!--                          <span class="text-overflow-hidden">-->
                      <!--                            {{ formatTaskCode(task?.code) }}-->
                      <!--                          </span>-->
                      <!--                          <div v-if="task?.isPrivate">-->
                      <!--                            <SynIcon-->
                      <!--                              name="private"-->
                      <!--                              class="fill-current"-->
                      <!--                              custom-class="w-2.5 h-2.5"-->
                      <!--                            />-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                        <span class="mx-1">-</span>-->
                      <!--                        <span class="text-overflow-hidden-line">{{-->
                      <!--                          task?.name-->
                      <!--                        }}</span>-->
                      <!--                      </div>-->
                      <span class="overflow-hidden"
                        >{{ formatTaskCode(task?.code) }}
                        <span v-if="task?.isPrivate">
                          <SynIcon
                            name="private"
                            class="fill-current"
                            custom-class="w-2.5 h-2.5"
                          />
                        </span>
                        - <span v-encode-html="{ text: task?.name }"> </span>
                      </span>
                      <div v-if="task.groupId" class="flex w-full justify-end">
                        <div class="flex items-center text-gray-500">
                          <GroupById
                            :group-id="task.groupId"
                            class="px-2 text-xs"
                            avatar-class="w-3 h-3"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </SynTd>
                <SynTd class="text-xs py-2 relative">
                  <UserByAction
                    v-if="task?.assigneeId"
                    :user-id="task?.assigneeId"
                    is-hidden-name
                    is-show-action-user
                    avatar-class="w-7 h-7"
                  />
                </SynTd>

                <SynTd class="text-xs">
                  <div class="w-full flex-center">
                    <SynTag
                      v-if="task.taskLife"
                      class="rounded-full w-max px-2 text-xs"
                      size="small"
                      :class="[
                        taskStates[task?.taskLife]?.background,
                        taskStates[task?.taskLife]?.color,
                      ]"
                    >
                      {{ $t(taskStates[task?.taskLife]?.nameCode) }}
                    </SynTag>
                  </div>
                </SynTd>

                <SynTd class="text-xs">
                  <PriorityTask
                    :urgency="task?.urgency"
                    size="mini"
                    is-hidden-label
                  />
                </SynTd>

                <SynTd class="text-right text-gray-500" style="min-width: 4rem">
                  <span v-if="task?.creationTime" class="text-xs">
                    {{ $filters.fromNow(task?.creationTime) }}
                    {{ $t('COMMON_LABEL_AGO') || 'ago' }}
                  </span>
                </SynTd>
              </SynTr>
            </template>
          </SynTableCustom>
          <div v-else class="flex-center flex-col h-1/3 space-y-6">
            <SynIcon
              name="finished-flag"
              custom-class="h-16 w-16 fill-current"
              color="current"
            />
            <div>
              <span class="text-gray-500 text-xl">
                {{ $t('OVERVIEW_CREATED_TASKS_HAVE_NO_TASK') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 h-full w-full lg:w-1/2 flex flex-col lg:flex-auto">
      <div class="flex flex-wrap">
        <div class="w-full 2xl:w-1/2 h-[15rem] p-2">
          <div
            class="
              w-full
              h-full
              flex flex-col
              gap-2
              p-4
              bg-white
              rounded-lg
              shadow
              min-w-[15rem]
            "
          >
            <div class="text-gray-600 font-semibold text-sm">
              {{
                $t('OVERVIEW_CREATED_TASKS_ACTIVE_MEMBER') || 'Active members'
              }}
            </div>
            <div class="flex-1 min-h-0 w-full flex-center">
              <CustomLollipopChart :chart-data="finishedByUserChartData" />
            </div>

            <!-- <div v-else class="flex-center text-gray-500 h-1/2">
              {{ $t('OVERVIEW_FINISHED_TASKS_HAVE_NO_ACTIVE_MEMBER') }}
            </div> -->
          </div>
        </div>
        <div class="w-full 2xl:w-1/2 h-[15rem] p-2">
          <div
            class="
              w-full
              h-full
              flex flex-col
              gap-2
              p-4
              bg-white
              rounded-lg
              shadow
              min-w-[15rem]
            "
          >
            <div class="text-gray-600 font-semibold text-sm">
              {{ $t('COMMON_LABEL_DOMAIN_PROJECT') || 'Domain' }}
            </div>
            <div
              v-if="createdTaskRankingByDomain?.length > 0"
              class="flex-1 min-h-0 w-full relative"
            >
              <VerticalBarChart
                :labels="byDomainLabels"
                :datasets="byDomainDatasets"
                is-hidden-note-y
              />
            </div>
            <div v-else class="flex-center text-gray-500 h-1/2">
              {{ $t('OVERVIEW_FINISHED_TASKS_HAVE_NO_INFORMATION') }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-full p-2 h-[15rem] max-h-[30rem] 2xl:h-auto 2xl:flex-1">
        <div
          class="
            w-full
            h-full
            flex flex-col
            gap-2
            p-4
            bg-white
            rounded-lg
            shadow
            min-w-[18rem]
          "
        >
          <div class="text-gray-600 font-semibold text-sm">
            {{ $t('PERFORMANCE_LABEL_STATISTIC_IN_WEEK') || 'Domain' }}
          </div>
          <div class="flex-1 min-h-0 w-full relative">
            <VerticalBarChart
              :datasets="createdTaskDatasets"
              :labels="createdTaskLabels"
              is-hidden-note-y
            />
          </div>
          <!-- <div v-else class="flex-center text-gray-500 h-1/2">
            {{ $t('OVERVIEW_FINISHED_TASKS_HAVE_NO_INFORMATION') }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
