<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { TaskLife } from '@/domain/enums/taskEnum';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import { taskStates } from '@/ui/hooks/taskHook';
import { TaskApprovalStatus } from '@/application/types/task/task.types';
import UserById from '@/ui/components/user/UserById.vue';
import TaskApprovalModal from '@/ui/modules/task/approval/TaskApprovalModal.vue';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import TaskEvidenceInfo from '@/ui/modules/task/providing-evidence/TaskEvidenceInfo.vue';
import taskDetailWorkflowComposables from '@/ui/modules/task/workflow/task-detail-workflow.composables';
import TaskWorkflowChooseNextStep from '@/ui/modules/task/workflow/TaskWorkflowChooseNextStep.vue';
import TaskStateStepperItem from '@/ui/modules/task/components/TaskStateStepperItem.vue';

const props = withDefaults(
  defineProps<{
    taskDetail?: ITaskDetailClass | any;
    mode?: string;
    readonly?: boolean;
    objectLoading?: any;
    isLoading?: boolean;
    stateForm?: string;
    hasPermission?: boolean;
    isHiddenRemove?: boolean;
  }>(),
  {
    stateForm: 'DETAIL', // CREATE, DETAIL
  }
);

const emit = defineEmits<{
  (e: 'updateEvidenceSettings', data: any): void;
  (e: 'onChangeState', data: any, comment: any): void;
  (e: 'delete'): void;
  (e: 'onChangeApprovalStatus', data: any, comment: any): void;
  (e: 'onNextStepInWorkflow', comment: any): void;
  (e: 'onRequestApproval', stepId: string | null): void;
}>();

const statusStepper = {
  Active: 2,
  DeActive: 0,
  InProcess: 1,
};

const states = ref([
  {
    key: TaskLife.Todo,
    type: 'Todo',
    label: 'TASK_STATE_TODO',
    color: 'blue',
    classActive: 'bg-blue-500',
    classInProcess: 'border border-blue-500',
    colorText: 'text-blue-500',
    statusStepper: statusStepper.Active,
    disabled: false,
  },
  {
    key: TaskLife.InProcess,
    type: 'InProcess',
    label: 'TASK_STATE_IN_PROCESS',
    color: 'pink',
    classActive: 'bg-pink-500',
    classInProcess: 'border border-pink-500',
    colorText: 'text-pink-500',
    statusStepper: statusStepper.DeActive,
    disabled: false,
  },
  {
    key: TaskLife.Finished,
    type: 'Finished',
    label: 'TASK_STATE_FINISHED',
    color: 'green',
    classActive: 'bg-green-500',
    classInProcess: 'border border-green-500',
    colorText: 'text-green-500',
    statusStepper: statusStepper.DeActive,
    disabled: props?.stateForm === 'CREATE',
  },
]);
watch(
  () => props.taskDetail?.taskLife,
  () => {
    processHandleStepper();
  }
);

const { currentTaskStep, getStepName } = taskDetailWorkflowComposables(
  props.taskDetail
);

const taskStateList = computed(() => {
  if (!taskStates) return [];
  const stateList =
    props?.stateForm === 'CREATE'
      ? Object.values(taskStates)?.filter(
          (o) =>
            ![
              TaskLife.Cancelled,
              TaskLife.Finished,
              TaskLife.Duplicated,
            ]?.includes(o.key)
        )
      : Object.values(taskStates);
  return stateList.sort((a, b) => {
    return a.index - b.index;
  });
});

const processHandleStepper = () => {
  [1, 2].forEach(
    (el) => (states.value[el].statusStepper = statusStepper.DeActive)
  );
  states.value[0].statusStepper = statusStepper.InProcess;
  if (props.taskDetail?.taskLife === TaskLife.Finished)
    states.value[2].statusStepper = statusStepper.InProcess;
  if (
    props.taskDetail?.taskLife === TaskLife.Finished ||
    props.taskDetail?.taskLife === TaskLife.InProcess
  )
    states.value[1].statusStepper = statusStepper.InProcess;
  let currentStatesIndex = states.value.findIndex(
    (el) => el?.key === props.taskDetail?.taskLife
  );
  if (currentStatesIndex !== -1)
    states.value[currentStatesIndex].statusStepper = statusStepper.Active;
};

const approver = computed<any>(() => {
  return props.taskDetail?.approvers && props.taskDetail?.approvers?.length > 0
    ? props.taskDetail?.approvers[0]
    : {};
});

const needToProvideEvidenceWhenFinish = computed<boolean>(() => {
  return !!props.taskDetail?.finishProofEnabled;
});

const isOpenProvideEvidenceModal = ref<boolean>(false);
const viewModeProvideEvidence = ref<
  | 'FINISH_TASK_WITH_EVIDENCE'
  | 'REQUEST_APPROVAL_WITH_EVIDENCE'
  | 'APPROVE_OR_REJECT'
>('FINISH_TASK_WITH_EVIDENCE');

const handleChangeState = (state) => {
  if (
    state == TaskLife.Finished &&
    props.taskDetail?.id &&
    props.taskDetail?.workFlowTaskId
  ) {
    return;
  }

  if (
    state == TaskLife.Finished &&
    props.taskDetail?.id &&
    needToProvideEvidenceWhenFinish.value
  ) {
    viewModeProvideEvidence.value = 'FINISH_TASK_WITH_EVIDENCE';
    isOpenProvideEvidenceModal.value = true;
    return;
  }

  emit('onChangeState', state, undefined);
};

processHandleStepper();

const onChangeTaskStateWithEvidence = (comment) => {
  isOpenProvideEvidenceModal.value = false;

  if (viewModeProvideEvidence.value == 'FINISH_TASK_WITH_EVIDENCE') {
    emit('onChangeState', TaskLife.Finished, comment);
  } else if (
    viewModeProvideEvidence.value == 'REQUEST_APPROVAL_WITH_EVIDENCE'
  ) {
    onChangeApprovalStatus(comment);
  }
};
const onChangeApprovalStatus = (comment) => {
  emit('onChangeApprovalStatus', TaskApprovalStatus.WAITING, comment);
};
const selectedNextStepId = ref<string>('');

const onNextStepInWorkflow = (nextStepId) => {
  emit('onNextStepInWorkflow', { nextStepId });
};
const onRequestApproval = (item, nextStepId) => {
  if (props.readonly || item?.disabled || props.objectLoading?.isLoading)
    return;

  if (props.taskDetail?.id && needToProvideEvidenceWhenFinish.value) {
    viewModeProvideEvidence.value = 'REQUEST_APPROVAL_WITH_EVIDENCE';
    isOpenProvideEvidenceModal.value = true;
    return;
  }

  emit('onRequestApproval', nextStepId);
};
</script>

<template>
  <div class="flex items-center space-x-0">
    <div
      v-if="
        !taskDetail?.taskLife ||
        ![TaskLife.Todo, TaskLife.InProcess, TaskLife.Finished]?.includes(
          taskDetail?.taskLife
        )
      "
      class="flex-center space-x-1.5 py-1 pr-2 pl-1 rounded-full"
      :class="[
        taskDetail?.taskLife
          ? taskStates[taskDetail?.taskLife]?.backgroundStep
          : 'bg-current-200',
        isLoading ? 'cursor-pointer animate-pulse' : '',
      ]"
    >
      <div class="w-3.5 h-3.5 rounded-full flex-center">
        <syn-icon
          v-if="objectLoading?.isLoading"
          name="spinner"
          class="animate-spin"
          custom-class="w-4 h-4 fill-white text-white fill-white"
        />
        <syn-icon
          v-else
          name="check"
          custom-class="w-4 h-4 fill-white text-white"
        />
      </div>
      <span class="text-xs text-white">
        {{
          taskDetail?.taskLife
            ? $t(taskStates[taskDetail?.taskLife]?.nameCode)
            : $t('TASK_STATE_CREATED')
        }}
      </span>
    </div>

    <template v-else>
      <template v-for="(item, index) in states" :key="item?.key">
        <template v-if="item?.key == TaskLife.Finished">
          <AtomTooltip v-if="taskDetail?.taskLife == TaskLife.Finished">
            <template #trigger>
              <TaskStateStepperItem
                :task-detail="taskDetail"
                :data-item="item"
                :readonly="readonly"
                :is-loading="isLoading"
                :object-loading="objectLoading"
              />
            </template>
            <template #body>
              <div class="flex flex-col p-4 gap-2">
                <div class="flex flex-col gap-2 text-sm">
                  <span>
                    {{ $t('TASK_STATE_FINISHED_BY') }}
                  </span>

                  <UserById
                    :key="taskDetail?.finisherId"
                    :user-id="taskDetail?.finisherId"
                  />
                </div>
              </div>
            </template>
          </AtomTooltip>

          <AtomTooltip v-else-if="taskDetail?.approvalEnabled">
            <template #trigger>
              <template
                v-if="currentTaskStep && currentTaskStep?.nextSteps?.length > 1"
              >
                <VigDropdown>
                  <template #dropdown-toggle>
                    <TaskStateStepperItem
                      :task-detail="taskDetail"
                      :data-item="item"
                      :readonly="readonly"
                      :is-loading="isLoading"
                      :object-loading="objectLoading"
                    >
                      <span>
                        {{ $t('COMMON_LABEL_APPROVAL_REQUEST') }}
                      </span>
                    </TaskStateStepperItem>
                  </template>
                  <template #dropdown-menu>
                    <div class="flex flex-col p-4 gap-2">
                      <TaskWorkflowChooseNextStep
                        v-model="selectedNextStepId"
                        view-mode="TASK_VIEW"
                        :task-id="taskDetail?.id"
                        :task-detail="taskDetail"
                        @update:model-value="
                          (value) => onRequestApproval(item, value)
                        "
                      />
                    </div>
                  </template>
                </VigDropdown>
              </template>
              <TaskStateStepperItem
                v-else
                :task-detail="taskDetail"
                :data-item="item"
                :readonly="readonly"
                :is-loading="isLoading"
                :object-loading="objectLoading"
                @click="
                  onRequestApproval(
                    item,
                    currentTaskStep && currentTaskStep?.nextSteps?.length == 1
                      ? currentTaskStep?.nextSteps[0]?.id
                      : undefined
                  )
                "
              >
                <span
                  v-if="currentTaskStep?.nextSteps?.length == 1"
                  v-html="
                    $t('WORKFLOW_LABEL_REQUEST_NEXT_STEP_TO', {
                      stepName: getStepName(currentTaskStep?.nextSteps[0]?.id),
                    })
                  "
                >
                </span>
                <span v-else>
                  {{ $t('COMMON_LABEL_APPROVAL_REQUEST') }}
                </span>
              </TaskStateStepperItem>
            </template>
            <template #body>
              <div class="flex flex-col p-4 gap-2">
                <div class="flex flex-col gap-2 text-sm">
                  <span>
                    {{ $t('TASK_LABEL_NEED_TO_APPROVAL_BY') }}
                  </span>
                  <UserById :user-id="approver?.userId" />
                </div>
              </div>
            </template>
          </AtomTooltip>
          <template v-else-if="currentTaskStep && currentTaskStep?.id">
            <VigDropdown v-if="currentTaskStep?.nextSteps?.length > 1">
              <template #dropdown-toggle>
                <TaskStateStepperItem
                  :task-detail="taskDetail"
                  :data-item="item"
                  :readonly="readonly"
                  :is-loading="isLoading"
                  :object-loading="objectLoading"
                />
              </template>
              <template #dropdown-menu>
                <div class="flex flex-col p-4 gap-2">
                  <TaskWorkflowChooseNextStep
                    v-model="selectedNextStepId"
                    view-mode="TASK_VIEW"
                    :task-id="taskDetail?.id"
                    :task-detail="taskDetail"
                    @update:model-value="(value) => onNextStepInWorkflow(value)"
                  />
                </div>
              </template>
            </VigDropdown>
            <AtomTooltip v-else-if="currentTaskStep?.nextSteps?.length == 1">
              <template #trigger>
                <TaskStateStepperItem
                  :task-detail="taskDetail"
                  :data-item="item"
                  :readonly="readonly"
                  :is-loading="isLoading"
                  :object-loading="objectLoading"
                  @click.stop="
                    onNextStepInWorkflow(currentTaskStep?.nextSteps[0]?.id)
                  "
                >
                  <span
                    class="max-w-[12rem] truncate"
                    v-html="
                      $t('WORKFLOW_LABEL_NEXT_STEP_TO', {
                        stepName: getStepName(
                          currentTaskStep?.nextSteps[0]?.id
                        ),
                      })
                    "
                  >
                  </span>
                </TaskStateStepperItem>
              </template>
              <template #body>
                <div class="flex flex-col p-4 gap-2">
                  <span
                    class="text-xs"
                    v-html="
                      $t('WORKFLOW_LABEL_NEXT_STEP_TO', {
                        stepName: getStepName(
                          currentTaskStep?.nextSteps[0]?.id
                        ),
                      })
                    "
                  >
                  </span>
                </div>
              </template>
            </AtomTooltip>
            <TaskStateStepperItem
              v-else
              :task-detail="taskDetail"
              :data-item="item"
              :readonly="readonly"
              :is-loading="isLoading"
              :object-loading="objectLoading"
              @click.stop="onNextStepInWorkflow(null)"
            />
          </template>
          <TaskStateStepperItem
            v-else
            :task-detail="taskDetail"
            :data-item="item"
            :readonly="readonly"
            :is-loading="isLoading"
            :object-loading="objectLoading"
            @click="
              !readonly && !item?.disabled && !objectLoading?.isLoading
                ? handleChangeState(item?.key)
                : ''
            "
          />
        </template>
        <TaskStateStepperItem
          v-else
          :task-detail="taskDetail"
          :data-item="item"
          :readonly="readonly"
          :is-loading="isLoading"
          :object-loading="objectLoading"
          @click="
            !readonly && !item?.disabled && !objectLoading?.isLoading
              ? handleChangeState(item?.key)
              : ''
          "
        />

        <div v-if="index < states?.length - 1" class="flex-center w-8 h-4">
          <div
            class="
              w-6
              flex-center
              items-center
              relative
              border-b border-gray-200
            "
          ></div>
        </div>
      </template>
    </template>
    <TaskEvidenceInfo
      v-if="taskDetail?.finishProofEnabled"
      :task="taskDetail"
      @save="(data) => $emit('updateEvidenceSettings', data)"
    />

    <!-- TASK LIFE DROPDOWN LIST -->
    <VigDropdown v-if="!readonly && !isLoading" placement="bottom-end">
      <template #dropdown-toggle>
        <div
          class="
            w-6
            h-6
            flex-center
            rounded-full
            ml-1.5
            cursor-pointer
            text-gray-500
            border border-gray-200
            hover:border-current-400 hover:text-current
          "
        >
          <SynIcon
            name="dots-vertical"
            class="rotate-90"
            custom-class="w-3.5 h-3.5"
          />
        </div>
      </template>
      <template #dropdown-menu>
        <div v-for="status in taskStateList" :key="status?.key">
          <div
            class="
              p-2
              cursor-pointer
              dropdown-item
              text-sm
              font-medium
              truncate
            "
            :class="`hover:${status?.background} ${
              taskDetail?.taskLife === status?.key ? status?.background : ''
            }`"
            @click="handleChangeState(status?.key)"
          >
            <div class="flex items-center justify-between gap-2 text-xs">
              <span :class="status?.color" :title="$t(status?.nameCode)">
                <span
                  v-if="
                    taskDetail?.approvalEnabled &&
                    status?.key == TaskLife.Finished &&
                    taskDetail?.taskLife !== TaskLife.Finished
                  "
                >
                  {{ $t('COMMON_LABEL_APPROVAL_REQUEST') }}
                </span>
                <span v-else>
                  {{ $t(status?.nameCode) }}
                </span>
              </span>
              <SynIcon
                :name="status?.iconName"
                :custom-class="`shrink-0 h-4 w-4 ${status?.fill}`"
              />
            </div>
          </div>
        </div>
        <div
          v-if="hasPermission && !isHiddenRemove"
          class="
            list-li
            flex
            border-t
            shadow-sm
            justify-start
            items-center
            space-x-2
            text-sm
            border-b
          "
          @click="$emit('delete')"
        >
          <SynIcon class="fill-red-500" is-active name="trash" />
          <span class="text-red-500">
            {{ $t('COMMON_LABEL_DELETE') }}
          </span>
        </div>
      </template>
    </VigDropdown>
  </div>

  <TaskApprovalModal
    v-if="isOpenProvideEvidenceModal"
    :task="taskDetail"
    :view-mode="viewModeProvideEvidence"
    @on-close="isOpenProvideEvidenceModal = false"
    @confirm="onChangeTaskStateWithEvidence"
  />
</template>
