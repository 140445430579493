<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { StartWorkingConditionModel } from '@/application/models/setting/StartWorkingConditionModel';
import MenuSettingRowItem from '@/ui/components/setting/MenuSettingRowItem.vue';
import systemConfig from '@/application/constants/system-config.const';

const props = defineProps<{
  startWorkingCondition: StartWorkingConditionModel;
  disabled?: boolean;
  settings?: any;
}>();

defineEmits(['onSettingChange']);

const allowWeb = ref<boolean>();
const allowMobile = ref<boolean>();
const allowRestart = ref<boolean>();

const allowRestartSystem = computed(
  () =>
    props.settings &&
    props.settings[
      systemConfig.START_WO_FUNCTION_START_WORKING_FUNCTIONS
    ]?.includes('RESTART')
);

watch(
  () => props.startWorkingCondition,
  () => _setValues()
);

onMounted(() => _setValues());

const _setValues = () => {
  allowWeb.value = props.startWorkingCondition?.allowWeb;
  allowMobile.value = props.startWorkingCondition?.allowMobile;
  allowRestart.value = props.startWorkingCondition?.allowRestart;
};
</script>

<template>
  <div v-if="startWorkingCondition?.enabled">
    <div class="px-6 pb-2 pt-2 text-gray-700 text-lg bg-gray-100 mt-4">
      {{ $t('SETTING_SW_CONDITION_COMMON') }}
    </div>
    <div class="">
      <MenuSettingRowItem
        name-icon="Screen"
        :title-menu="$t('SETTING_SW_CONDITION_COMMON_WEB_ALLOW')"
        :sub-title="$t('SETTING_SW_CONDITION_COMMON_WEB_ALLOW_DESC')"
      >
        <template #action>
          <AtomSwitch
            v-model="allowWeb"
            :disabled="disabled"
            @change="$emit('onSettingChange', { allowWeb })"
          />
        </template>
      </MenuSettingRowItem>
      <MenuSettingRowItem
        name-icon="Mobile"
        :title-menu="$t('SETTING_SW_CONDITION_COMMON_MOBILE_ALLOW')"
        :sub-title="$t('SETTING_SW_CONDITION_COMMON_MOBILE_ALLOW_DESC')"
      >
        <template #action>
          <AtomSwitch
            v-model="allowMobile"
            :disabled="disabled"
            @change="$emit('onSettingChange', { allowMobile })"
          />
        </template>
      </MenuSettingRowItem>
      <MenuSettingRowItem
        v-if="allowRestartSystem"
        name-icon="Reload2"
        :title-menu="$t('SETTING_SW_CONDITION_COMMON_RESTART_ALLOW')"
        :sub-title="$t('SETTING_SW_CONDITION_COMMON_RESTART_ALLOW_DESC')"
        custom-class=""
      >
        <template #action>
          <AtomSwitch
            v-model="allowRestart"
            :disabled="disabled"
            @change="$emit('onSettingChange', { allowRestart })"
          />
        </template>
      </MenuSettingRowItem>
    </div>
  </div>
</template>
