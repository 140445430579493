<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import DomainService from '@/application/services/DomainService';
import myProfileStore from '@/store/auth/my-profile';
import groupStore from '@/store/group';

const props = defineProps<{
  modelValue: any;
  listTaskOwnerId?: number;
  groupId?: number;
  isShowResultOnly?: boolean;
}>();
const emit = defineEmits([
  'onClose',
  'onConversationClick',
  'update:modelValue',
]);

const _groupStore = groupStore();
const userDomains = ref([] as any[]);
const allGroupByIds = computed(() => {
  return {
    ..._groupStore.allGroupByIds,
    ..._groupStore.myGroupByIds,
  };
});
const initData = async () => {
  let domains: any[] = [];
  if (props.groupId) {
    if (
      !allGroupByIds.value ||
      !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
    )
      return;

    domains = allGroupByIds.value[props.groupId]?.groupDomains;
  } else {
    const res = await DomainService.getInstance().getDomainScopeListByUserId(
      props.listTaskOwnerId || myProfileStore().myProfile?.id
    );

    domains = res?.result;
  }
  userDomains.value =
    domains?.length > 0
      ? domains.map((o) => {
          return {
            ...o,
            isSelected: props.modelValue?.some((x) => x == o.domainId),
          };
        })
      : [];
};
initData();

watch(
  () => props.listTaskOwnerId,
  () => {
    initData();
  }
);

watch(
  () => props.modelValue,
  () => {
    initData();
  }
);

const onUpdateModelValue = () => {
  const selectedGroups = userDomains.value.filter((o) => o.isSelected);
  emit(
    'update:modelValue',
    selectedGroups.map((o) => o.domainId)
  );
};
</script>
<template>
  <div class="flex flex-col">
    <div v-if="userDomains?.length > 0" class="flex flex-wrap">
      <SynCheckboxTag
        v-for="domain in isShowResultOnly
          ? userDomains?.filter((o) => o.isSelected)
          : userDomains"
        :key="domain.domainId"
        v-model="domain.isSelected"
        class="mr-1 mb-1"
        @update:model-value="onUpdateModelValue"
      >
        <div
          class="
            rounded-full
            border border-current-100
            text-xs
            px-2
            py-1
            flex-center
            w-max
            float-left
          "
          :class="domain.isSelected ? 'bg-current-500' : 'hover:bg-current-50'"
        >
          <span>{{ domain?.domainName }}</span>
          <SynIcon
            v-if="isShowResultOnly && domain.isSelected"
            name="CloseLine"
            custom-class="w-2.5 h-2.5 fill-white"
          />
        </div>
      </SynCheckboxTag>
    </div>
  </div>
</template>
