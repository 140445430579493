<script setup lang="ts">
import { ref, onUnmounted, computed } from 'vue';
import getGroupInfo from '@/ui/helpers/group-helper';
import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';
import ActionById from '@/ui/components/user/ActionById.vue';
import GroupDetailModal from '@/ui/modules/group/detail/GroupDetailModal.vue';

const props = withDefaults(
  defineProps<{
    groupId: number | null;
    // user?: any;
    isHiddenName?: boolean;
    avatarClass?: string;
    textNameClass?: string;
    isShowAction?: boolean;
    // isShowFirstName: boolean;
  }>(),
  {
    avatarClass: 'w-6 h-6',
    textNameClass: 'text-sm',
  }
);

defineEmits(['onActionClick']);

const groupInfo = computed(() => {
  return getGroupInfo(props.groupId);
});

const isOpenProfileGroup = ref<boolean>(false);

onUnmounted(() => {});
</script>

<template>
  <VigDropdown :delay="[500, 0]" trigger="mouseenter" :append-to-body="false">
    <template #dropdown-toggle>
      <div class="flex items-center space-x-2 font-sans justify-start">
        <SynAvatar
          :src="groupInfo?.avatar?.replace('original', 'mini')"
          :name="groupInfo?.name"
          :custom-class="avatarClass"
        />
        <div v-if="!isHiddenName" :class="textNameClass">
          <span class="text-overflow-hidden-line flex flex-col flex-wrap">
            <span class="text-overflow-hidden-line">{{ groupInfo?.name }}</span>
          </span>
        </div>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="w-max bg-white rounded-md py-2 px-4">
        <div class="flex items-center space-x-4 font-sans py-1">
          <div class="cursor-pointer" @click.stop="isOpenProfileGroup = true">
            <SynAvatar
              :src="groupInfo?.avatar?.replace('original', 'mini')"
              :name="groupInfo?.name"
              custom-class="h-14 w-14"
            />
          </div>
          <div class="flex flex-col space-y-2">
            <div class="flex space-x-2 items-center">
              <div class="flex-center space-x-2">
                <span class="font-medium text-sm">
                  {{ groupInfo?.name }}
                </span>
              </div>
            </div>

            <div class="flex space-x-2">
              <ActionById
                v-if="groupInfo?.id || groupId"
                :contact-id="groupInfo?.id || groupId"
                :types="['favourite', 'list-task', 'chat']"
                type-contact="group"
                @on-action-click="$emit('onActionClick', $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </VigDropdown>

  <GroupDetailModal
    v-if="isOpenProfileGroup"
    position="members"
    :group-id="groupInfo?.id || groupId"
    @cancel="isOpenProfileGroup = false"
  />
</template>
