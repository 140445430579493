<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m374.851562 114.226562-109.828124-109.832031c-2.8125-2.816406-6.628907-4.394531-10.609376-4.394531h-239.414062c-8.285156 0-15 6.714844-15 15v482c0 8.285156 6.714844 15 15 15h349.246094c8.285156 0 15-6.714844 15-15v-372.167969c0-3.976562-1.578125-7.792969-4.394532-10.605469zm-105.4375-63.011718 58.621094 58.617187h-58.621094zm-239.414062 430.785156v-452h84.621094v39.378906h-13.792969c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h13.792969v39.378906h-13.792969c-8.285156 0-15 6.71875-15 15 0 8.285157 6.714844 15 15 15h13.792969v39.378907h-13.792969c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15h13.792969v39.296875c-12.347656 5.292968-21.3125 16.960937-22.667969 31.183594l-8.253906 86.574218c-.703125 7.394532 1.753906 14.785156 6.75 20.277344 4.996093 5.496094 12.121093 8.648438 19.546875 8.648438h39.253906c7.425781 0 14.550781-3.152344 19.542969-8.648438 4.996093-5.492188 7.457031-12.882812 6.753906-20.273438l-8.253906-86.578124c-1.355469-14.222657-10.320313-25.890626-22.671875-31.183594v-39.296875h13.796875c8.285156 0 15-6.714844 15-15 0-8.285157-6.714844-15-15-15h-13.796875v-39.378907h13.796875c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15h-13.796875v-39.378906h13.796875c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-13.796875v-39.378906h94.792968v94.832031c0 8.285157 6.714844 15 15 15h94.832032v342.167969zm91.816406-170.535156c.386719-4.046875 3.742188-7.097656 7.808594-7.097656 4.0625 0 7.417969 3.050781 7.804688 7.097656l7.878906 82.652344h-31.367188zm0 0"
      ></path>
    </g>
  </svg>
</template>
