<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512.009 512.009"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <g>
            <rect x="79.893" y="81.322" width="59.374" height="59.337"></rect>
            <rect x="274.652" y="391.915" width="59.337" height="59.374"></rect>
            <rect x="452.672" y="332.569" width="59.337" height="59.337"></rect>
            <polygon
              points="393.289,332.569 333.989,332.569 333.989,273.26 274.652,273.26 274.652,332.597 333.989,332.597
				333.989,391.906 393.326,391.906 393.326,332.597 452.645,332.597 452.645,273.26 393.289,273.26 			"
            ></polygon>
            <rect x="393.289" y="451.243" width="59.355" height="59.355"></rect>
            <path
              d="M17.139,220.589h184.89c9.461,0,17.13-7.669,17.139-17.13V18.55c0-9.47-7.669-17.139-17.139-17.139H17.139
				C7.669,1.411,0,9.08,0,18.55v184.909C0,212.92,7.669,220.589,17.139,220.589z M34.269,35.68H184.89v150.64H34.269V35.68z"
            ></path>
            <rect x="352.261" y="81.322" width="59.337" height="59.337"></rect>
            <path
              d="M289.47,220.589h184.928c9.461,0,17.13-7.669,17.139-17.13V18.55c0-9.47-7.669-17.139-17.139-17.139H289.47
				c-9.47,0-17.139,7.669-17.139,17.139v184.9C272.331,212.92,280,220.589,289.47,220.589z M306.6,35.68h150.659v150.64H306.6V35.68
				z"
            ></path>
            <rect x="80.292" y="353.255" width="59.337" height="59.337"></rect>
            <path
              d="M202.429,273.325H17.501c-9.47,0-17.139,7.669-17.139,17.139v184.909c0,9.47,7.669,17.139,17.139,17.139h184.928
				c9.461,0,17.13-7.678,17.139-17.139V290.464C219.568,280.994,211.899,273.325,202.429,273.325z M185.29,458.234H34.631V307.603
				H185.29V458.234z"
            ></path>
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
