import { ref } from 'vue';
import { TaskTemplateEntity } from '@/domain/entities/task/TaskTemplateEntity';
import WorkflowStepEntity from '@/domain/entities/workflow/WorkflowStepEntity';
import { getObjectDiff } from '@/ui/hooks/commonFunction';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';

export default function workflowStepComposables(workflowStepDefault) {
    const originalWorkflowStepData = ref<WorkflowStepEntity>({
        ...workflowStepDefault,
    });
    const workflowStepData = ref<WorkflowStepEntity>(
        new WorkflowStepEntity({
            ...workflowStepDefault,
        })
    );

    const currentTaskTemplate = ref<TaskTemplateEntity>(
        new TaskTemplateEntity({
            id: 'draft',
            groupId: workflowStepDefault?.groupId || undefined,
            assigneeId: workflowStepDefault?.assigneeId || undefined,
            domainId: workflowStepDefault?.domainId,
            important: workflowStepDefault?.important,
            priority: workflowStepDefault?.priority,
            description: workflowStepDefault?.taskDescription,
            startTime: workflowStepDefault?.startTime,
            scheduleByHour: workflowStepDefault?.scheduleByHour,
            scheduleTime: workflowStepDefault?.scheduleTime,
            scheduleTimezone: workflowStepDefault?.scheduleTimezone,
            taskSchedule: {
                scheduleOptionKey: workflowStepDefault?.scheduleDayOption,
                scheduleDateValue: workflowStepDefault?.scheduleTime,
            },
            files:
                workflowStepDefault?.files?.map((file) => {
                    return { ...file, editStatus: 'ORIGINAL' };
                }) || [],
            notes:
                workflowStepDefault?.notes?.map((file) => {
                    return { ...file, editStatus: 'ORIGINAL' };
                }) || [],
            taskTodos: workflowStepDefault?.todoLists,
            collaborators: workflowStepDefault?.collaborators
                ? workflowStepDefault?.collaborators?.map((u) => {
                      return {
                          memberUserId: u?.userId,
                          memberRoleId: u?.roleId,
                      };
                  })
                : [],
        })
    );

    const initData = (workflowStep) => {
        originalWorkflowStepData.value = new WorkflowStepEntity({
            ...workflowStep,
        });
        workflowStepData.value = new WorkflowStepEntity({
            ...workflowStep,
        });

        currentTaskTemplate.value = new TaskTemplateEntity({
            id: 'draft',
            groupId: workflowStep?.groupId || undefined,
            assigneeId: workflowStep?.assigneeId || undefined,
            domainId: workflowStep?.domainId,
            important: workflowStep?.important,
            priority: workflowStep?.priority,
            description: workflowStep?.taskDescription,
            scheduleByHour: workflowStepDefault?.scheduleByHour,
            scheduleTime: workflowStep?.scheduleTime,
            scheduleTimezone: workflowStepDefault?.scheduleTimezone,
            taskSchedule: {
                scheduleOptionKey: workflowStep?.scheduleDayOption,
                scheduleDateValue: workflowStep?.scheduleTime,
            },
            files:
                workflowStep?.files?.map((file) => {
                    return { ...file, editStatus: 'ORIGINAL' };
                }) || [],
            notes:
                workflowStep?.notes?.map((file) => {
                    return { ...file, editStatus: 'ORIGINAL' };
                }) || [],
            taskTodos: workflowStep?.todoLists,
            collaborators: workflowStepDefault?.collaborators
                ? workflowStepDefault?.collaborators?.map((u) => {
                      return {
                          memberUserId: u?.userId,
                          memberRoleId: u?.roleId,
                      };
                  })
                : [],
        });
    };
    const updateWorkflowStepFromTaskTemplate = (callback?) => {
        workflowStepData.value.domainId = currentTaskTemplate.value?.domainId;

        const currentFiles = currentTaskTemplate.value?.files?.filter(
            (file) => file?.editStatus !== 'REMOVED'
        );
        const currentNotes = currentTaskTemplate.value?.notes?.filter(
            (file) => file?.editStatus !== 'REMOVED'
        );
        workflowStepData.value.priority =
            currentTaskTemplate.value?.urgency &&
            currentTaskTemplate.value?.urgency > 0
                ? convertUrgencyToPriority(currentTaskTemplate.value?.urgency)
                : currentTaskTemplate.value?.priority || 0;

        workflowStepData.value.important =
            currentTaskTemplate.value?.urgency &&
            currentTaskTemplate.value?.urgency > 0
                ? convertUrgencyToImportant(currentTaskTemplate.value?.urgency)
                : !!currentTaskTemplate.value?.important;
        workflowStepData.value.startTime =
            currentTaskTemplate.value?.startTime || undefined;
        workflowStepData.value.scheduleDayOption =
            currentTaskTemplate.value?.taskSchedule?.scheduleOptionKey;
        workflowStepData.value.scheduleByHour =
            currentTaskTemplate.value?.scheduleByHour || false;
        workflowStepData.value.scheduleTime =
            currentTaskTemplate.value?.scheduleTime || undefined;
        workflowStepData.value.scheduleTimezone =
            currentTaskTemplate.value?.scheduleTimezone || '';

        workflowStepData.value.taskDescription =
            currentTaskTemplate.value?.description;
        workflowStepData.value.totalFiles = currentFiles?.length || 0;
        workflowStepData.value.totalNotes = currentNotes?.length || 0;
        workflowStepData.value.totalTodoLists =
            currentTaskTemplate.value?.taskTodos?.length || 0;
        workflowStepData.value.groupId = currentTaskTemplate.value?.groupId;
        workflowStepData.value.assigneeId =
            currentTaskTemplate.value?.assigneeId;

        // Attachments
        workflowStepData.value.files = [...currentFiles];
        workflowStepData.value.notes = [...currentNotes];
        workflowStepData.value.todoLists =
            currentTaskTemplate.value?.taskTodos || [];
        workflowStepData.value.collaborators = currentTaskTemplate.value
            ?.collaborators
            ? currentTaskTemplate.value?.collaborators?.map((u) => {
                  return {
                      userId: u?.memberUserId,
                      roleId: u?.memberRoleId,
                  };
              })
            : [];

        // updateFields
        let differences = getObjectDiff(
            originalWorkflowStepData.value,
            workflowStepData.value
        );

        const fullUpdateFields = [
            'groupId',
            'assigneeId',
            'domainId',
            'priority',
            'important',
            'startTime',
            'scheduleDayOption',
            'scheduleByHour',
            'scheduleTime',
            'scheduleTimezone',
            'taskDescription',
            'collaborators',
            // 'files',
            // 'notes',
            // 'todoLists',
        ];
        differences = differences.filter((field) =>
            fullUpdateFields?.includes(field)
        );

        if (
            differences?.includes('groupId') ||
            differences?.includes('assigneeId') ||
            differences?.includes('domainId')
        ) {
            differences = [
                ...new Set([
                    ...differences,
                    'groupId',
                    'assigneeId',
                    'domainId',
                ]),
            ];
        }

        if (
            currentTaskTemplate.value?.files?.filter(
                (file) =>
                    file.editStatus == 'ADDED' || file.editStatus == 'REMOVED'
            )?.length > 0
        )
            differences.push('files');
        if (
            currentTaskTemplate.value?.notes?.filter(
                (file) =>
                    file.editStatus == 'ADDED' ||
                    file.editStatus == 'EDITED' ||
                    file.editStatus == 'REMOVED'
            )?.length > 0
        )
            differences.push('notes');
        if (currentTaskTemplate.value?.updatedTodoList)
            differences.push('todoLists');

        workflowStepData.value.updateFields = differences;

        callback && callback();
    };

    return {
        workflowStepData,
        currentTaskTemplate,
        updateWorkflowStepFromTaskTemplate,
        initData,
    };
}
