<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import Cropper from 'cropperjs';
// import axios from 'axios';

const props = defineProps<{
  defaultImages: string[];
  isReset: boolean;
}>();

const emits = defineEmits(['chooseImage']);

const photoChoseUpload = ref();
const listSrcImage = ref([] as any[]);
let _imgCropper;
const isChooseImage = ref(false);
const fileUploadPhotoRef = ref(null as any);

const initComponent = () => {
  if (props.defaultImages?.length > 0) {
    listSrcImage.value = [...listSrcImage.value, ...props.defaultImages].filter(
      (o) => o
    );
  }
};

onMounted(() => {
  if (listSrcImage.value?.length > 0) chooseImageUploadCut(0);
});

watch(
  () => props.isReset,
  () => {
    if (props.isReset) {
      listSrcImage.value = [];
      photoChoseUpload.value = null;
      isChooseImage.value = false;
      _imgCropper && _imgCropper.destroy();
    }
  }
);

const _initViewer = (src) => {
  _imgCropper && _imgCropper.destroy();
  fileUploadPhotoRef.value.src = src;
  _imgCropper = new Cropper(fileUploadPhotoRef.value, {
    checkCrossOrigin: true,
    dragMode: 'move',
    aspectRatio: 1 / 1,
    autoCrop: true,
    responsive: true,
    background: false,
    toggleDragModeOnDblclick: false,
    ready() {
      _zoomToOriginal();
    },
  });
};

const updateCutFile = async () => {
  const result = await onCutClick();
  emits('chooseImage', result);
};

const onZoomClick = (value) => {
  _imgCropper && _imgCropper.zoom(value);
  emits('chooseImage');
};

const onResetClick = () => {
  _imgCropper && _imgCropper.reset();
  _zoomToOriginal();
  updateCutFile();
};

const _zoomToOriginal = () => {
  const canvasData = _imgCropper.getCanvasData();
  if (canvasData.naturalWidth < canvasData.width) {
    _imgCropper.zoomTo(1);
  }
  updateCutFile();
};

const chooseImageUploadCut = (index) => {
  photoChoseUpload.value = index;
  isChooseImage.value = true;
  indexPhotoCutUpload.value = index;

  _initViewer(listSrcImage.value[index]);
};

const chooseAvatarFromComputer = async (e) => {
  listSrcImage.value.push(URL.createObjectURL(e.target.files[0]));
  chooseImageUploadCut(listSrcImage.value.length - 1);
  updateCutFile();
};

const indexPhotoCutUpload = ref();

const onCutClick = async () => {
  const croppedCanvas = await _imgCropper.getCroppedCanvas({
    fillColor: '#fff',
  });
  if (!croppedCanvas) return;

  const file = croppedCanvas.toDataURL('image/jpeg');
  let binary;
  binary = new File(
    [new Buffer.from(file?.replace(/^data:image\/\w+;base64,/, ''), 'base64')],
    'avatar.png',
    {
      type: 'image/png',
    }
  );
  return binary;
};

initComponent();
</script>
<template>
  <section class="h-full w-96 flex-col flex-center">
    <div class="flex flex-col h-96 w-96" style="flex: 100%">
      <div class="h-full bg-gray-800 relative p-1">
        <div class="h-full">
          <img ref="fileUploadPhotoRef" class="max-w-full h-full hidden" />
        </div>

        <div class="absolute right-5 bottom-5">
          <button
            class="
              p-2
              flex-center
              rounded-full
              shadow-lg
              bg-black bg-opacity-50
              hover:bg-opacity-100
              mb-2
            "
            @click="onResetClick"
          >
            <SynIcon
              name="Refresh"
              custom-class="w-5 h-5 text-white fill-white"
            />
          </button>
          <button
            class="
              p-2
              flex-center
              rounded-full
              shadow-lg
              bg-black bg-opacity-50
              hover:bg-opacity-100
              mb-2
            "
            @click="onZoomClick(0.5)"
          >
            <SynIcon name="Plus" custom-class="w-5 h-5 fill-white" />
          </button>
          <button
            class="
              p-2
              flex-center
              rounded-full
              shadow-lg
              bg-black bg-opacity-50
              hover:bg-opacity-100
            "
            @click="onZoomClick(-0.5)"
          >
            <SynIcon name="Minus" custom-class="w-5 h-5 fill-white" />
          </button>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col pt-2">
      <div class="flex items-center space-x-2 h-20">
        <div class="relative">
          <div class="shadow-md rounded-full bg-white w-12 h-12">
            <input
              ref="fileInputCreate"
              type="file"
              class="invisible"
              accept="image/png, image/jpeg"
              @change="chooseAvatarFromComputer"
            />
          </div>
          <div
            v-vig-tooltip="$t('PHOTO_LABEL_UPLOAD_PHOTO')"
            class="
              w-12
              h-12
              p-4
              absolute
              top-0
              bg-current-50
              rounded-full
              flex-center
              cursor-pointer
              fill-current
              hover:bg-current-400 hover:fill-white
            "
            @click="$refs.fileInputCreate.click()"
          >
            <SynIconCustom name="plus" />
          </div>
        </div>
        <div
          class="
            flex
            overflow-x-auto
            space-x-2
            overflow-y-hidden
            py-1
            small-scrollbar
          "
          style="flex: 100%"
        >
          <div
            v-for="(photo, index) in listSrcImage"
            :key="photo"
            class="cursor-pointer relative"
            @click="chooseImageUploadCut(index)"
          >
            <div
              v-if="photoChoseUpload == index"
              class="
                w-12
                h-12
                rounded-full
                absolute
                top-0
                z-10
                flex-center
                bg-current-800 bg-opacity-40
              "
            >
              <SynIcon name="check" custom-class="w-8 h-8 text-white" />
            </div>
            <div class="w-12 h-12">
              <img
                style="clip-path: ellipse(45% 45% at 50% 50%)"
                class="object-cover w-12 h-12"
                :src="photo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
