<script setup>
import { computed, onMounted, ref } from 'vue';
import $constants from '@/ui/plugins/constants';
import fileStorageStore from '@/store/fileStorage';
import groupStore from '@/store/group';
import userStore from '@/store/user';
import SelectMembers from '@/ui/modules/member/select-members/SelectMembers.vue';
import FileCard from '../../files-list/file-card/FileCard.vue';
import FolderBreadcrumb from '../folder-breadcrumb/FolderBreadcrumb.vue';
import ModalFolderAdd from '../modal-folder-add/ModalFolderAdd.vue';
import myProfileStore from '@/store/auth/my-profile';
import FileStorageRepository from '@/application/repositories/FileStorageRepository';
import AddFileLocation from '@/ui/modules/ged/_commons/add-file-location/AddFileLocation.vue';
import GedFilterSubDomain from '@/ui/modules/ged/ged-page/filter-sub-domain/GedFilterSubDomain.vue';

const props = defineProps({
  currentLocation: {
    type: Object,
    default: null,
  },
  ignoredFolderIds: {
    type: Array,
    default: null,
  },
  functionName: {
    type: String,
    default: null,
  },
  confirmFunction: {
    type: Function,
    default: null,
  },
  zIndex: {
    type: Number,
    default: 40,
  },
  isDriveDocument: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onClose', 'onConfirm', 'onConfirmProcessed']);

const _fileStorageStore = fileStorageStore();
const _groupStore = groupStore();
const _userStore = userStore();
const _myProfileStore = myProfileStore();

const myProfile = computed(() => _myProfileStore.myProfile);
const allMyGroups = computed(() => _groupStore.allMyGroups);
const allUsers = computed(() => _userStore.listShortInfosUser);
const allMyDomains = computed(() => _myProfileStore.myDomains);

const fileFilters = ref({});
const selectedMember = ref();
const selectedFolder = ref();
const currentFolder = ref();
const folders = ref();
const isLoading = ref();
const isConfirming = ref();
const isAddFolder = ref();
const canShowMore = ref();

const selectedLocation = computed(() => ({
  userId:
    !selectedMember.value?.isGroup && !selectedMember.value?.isDomain
      ? selectedMember.value?.id
      : null,
  groupId: selectedMember.value?.isGroup ? selectedMember.value?.id : null,
  domainId: selectedMember.value?.isDomain ? selectedMember.value?.id : null,
  projectId: selectedMember.value?.isDomain
    ? fileFilters.value?.projectId
    : null,
  subProjectId: selectedMember.value?.isDomain
    ? fileFilters.value?.subProjectId
    : null,
  folderId: selectedFolder.value?.id,
  folder: selectedFolder.value,
}));

const isDisableConfirm = computed(() => {
  if (!props.currentLocation) return false;

  return (
    selectedLocation.value?.folderId === props.currentLocation?.folderId &&
    selectedLocation.value?.groupId === props.currentLocation?.groupId &&
    selectedLocation.value?.domainId === props.currentLocation?.domainId &&
    selectedLocation.value?.projectId === props.currentLocation?.projectId &&
    selectedLocation.value?.subProjectId === props.currentLocation?.subProjectId
  );
});

const customZindex = computed(() => `z-${props.zIndex || 40}`);

onMounted(() => {
  fileFilters.value.projectId = null;
  fileFilters.value.subProjectId = null;

  if (props.currentLocation?.groupId) {
    const group = allMyGroups.value?.find(
      (g) => g?.id === props.currentLocation?.groupId
    );
    selectedMember.value = group ? { ...group, isGroup: true } : null;
  } else if (props.currentLocation?.domainId) {
    const domain = allMyDomains.value?.find(
      (d) => d?.id === props.currentLocation?.domainId
    );
    selectedMember.value = domain ? { ...domain, isDomain: true } : null;
    fileFilters.value.projectId = props.currentLocation?.projectId;
    fileFilters.value.subProjectId = props.currentLocation?.subProjectId;
  } else {
    selectedMember.value = { id: myProfile.value?.id };
  }

  if (props.currentLocation?.folderId) {
    currentFolder.value = props.currentLocation?.folder;
    selectedFolder.value = currentFolder.value;
  } else {
    currentFolder.value = null;
    selectedFolder.value = null;
  }

  _getFolders();
});

const onMemberChange = () => {
  fileFilters.value.domainId = selectedMember.value?.isDomain
    ? selectedMember.value?.id
    : null;
  fileFilters.value.projectId = null;
  fileFilters.value.subProjectId = null;

  onFolderGoToClick(null);
};

const onSubDomainChange = () => {
  onFolderGoToClick(null);
};

const onFolderClick = (folder) => {
  selectedFolder.value =
    folder?.id !== selectedFolder.value?.id ? folder : currentFolder.value;
};

const onFolderGoToClick = (folder) => {
  currentFolder.value = folder;
  selectedFolder.value = folder;

  if (currentFolder.value) {
    if (selectedMember.value?.isGroup) {
      const currGroup = (allMyGroups.value || []).find(
        (group) => group?.id === selectedMember.value.id
      );
      currentFolder.value.storageName = currGroup?.name;
    } else if (selectedMember.value?.isDomain) {
      const currDomain = (allMyDomains.value || []).find(
        (domain) => domain?.id === selectedMember.value.id
      );
      currentFolder.value.storageName = currDomain?.name;
    } else {
      const currUser = (allUsers.value || []).find(
        (user) => user?.id === selectedMember.value.id
      );
      currentFolder.value.storageName = currUser?.name;
    }
  }

  _getFolders();
};

const onFolderAdded = () => {
  isAddFolder.value = false;

  _getFolders();
};

const onLoadMore = () => {
  if (canShowMore.value && !isLoading.value) {
    _getFolders(false);
  }
};

const onConfirmClick = async () => {
  // If no confirm function, emit location
  if (!props.confirmFunction) return emit('onConfirm', selectedLocation.value);

  // Else, Do confirm function
  isConfirming.value = true;

  try {
    emit(
      'onConfirmProcessed',
      await props.confirmFunction(selectedLocation.value)
    );
  } catch (e) {
    console.log(e);
  }

  isConfirming.value = false;
};

const _getFolders = async (isRefresh = true) => {
  isLoading.value = true;

  const params = {
    category: $constants.FILE.CATEGORY.STORAGE,
    fileTypes: ['FOLDER'],
    ignoredFileIds: props.ignoredFolderIds,
    pageSize: 20,
  };

  params.groupId = selectedMember.value?.isGroup
    ? selectedMember.value?.id
    : null;
  params.domainId = selectedMember.value?.isDomain
    ? selectedMember.value?.id
    : null;
  params.projectId = selectedMember.value?.isDomain
    ? fileFilters.value?.projectId
    : null;
  params.subProjectId = selectedMember.value?.isDomain
    ? fileFilters.value?.subProjectId
    : null;
  params.userId =
    !selectedMember.value?.isGroup && !selectedMember.value?.isDomain
      ? selectedMember.value?.id
      : null;
  params.folderId = currentFolder.value?.id;
  params.pageIndex = isRefresh ? null : folders.value?.length;

  try {
    let result;
    if (props.isDriveDocument) {
      const res = await FileStorageRepository.getInstance().getDriveDocuments(
        params
      );
      result = res?.result;
    } else {
      result = await _fileStorageStore.searchFiles(params);
    }

    folders.value = isRefresh
      ? result?.files
      : folders.value
          ?.concat(result?.files || [])
          .filter(
            (fd, index, arr) =>
              arr.findIndex((item) => item?.id === fd?.id) === index
          );

    canShowMore.value = folders.value?.length < result?.total;
  } catch (e) {
    console.log(e);
  }

  isLoading.value = false;
};
</script>

<template>
  <syn-modal
    :z-index="customZindex"
    container-class="w-5/6 md:w-2/3 xl:w-[50rem]"
    header-class="px-4 py-2"
    style-body="p-0"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div
        class="flex items-center justify-between whitespace-nowrap space-x-3"
      >
        <div class="flex items-center space-x-3 overflow-auto">
          <div>{{ $t('GED_FOLDER_SELECT') }}</div>
          <div class="min-w-[16rem] max-w-[20rem] text-base text-black">
            <SelectMembers
              v-model="selectedMember"
              :members="allUsers"
              :groups="allMyGroups"
              :domains="allMyDomains"
              :show-nav-buttons="true"
              :show-only-my-groups="true"
              :show-other-members="false"
              toggle-class="rounded border px-2 py-1"
              @update:model-value="onMemberChange"
            />
          </div>
          <div
            v-if="selectedMember?.isDomain"
            class="min-w-[12rem] max-w-[20rem] text-base text-black font-normal"
          >
            <GedFilterSubDomain
              v-model:file-filters="fileFilters"
              toggle-class="rounded border px-2 py-1"
              @update:file-filters="onSubDomainChange"
            />
          </div>
        </div>
        <vig-button
          v-vig-tooltip="$t('GED_FOLDER_NEW')"
          ghost
          padding="p-2"
          rounded="rounded-full"
          @click="isAddFolder = true"
        >
          <SynIcon name="FolderAdd" custom-class="w-5 h-5" />
        </vig-button>
      </div>
    </template>

    <template #body>
      <div class="h-full flex flex-col" style="min-height: 24rem">
        <FolderBreadcrumb
          v-if="currentFolder"
          class="px-3 py-1 bg-current-50"
          :folder="currentFolder"
          :is-drive-document="isDriveDocument"
          @on-folder-click="onFolderGoToClick"
        />
        <div
          v-scroll-infinite="onLoadMore"
          class="p-3 flex-1 overflow-auto small-scrollbar"
        >
          <div
            class="grid auto-rows-max gap-3"
            style="grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr))"
          >
            <FileCard
              v-for="fd in folders"
              :key="fd"
              style="min-height: 9rem"
              :file="fd"
              :is-selected="selectedFolder?.id === fd?.id"
              :show-checkbox="false"
              :show-actions-button="false"
              :show-go-to-button="true"
              @on-file-click="onFolderClick(fd)"
              @on-go-to-click="onFolderGoToClick(fd)"
            />
          </div>

          <div v-if="isLoading" class="px-3 py-2 flex-center">
            <SynIcon
              name="Spinner"
              class="w-5 h-5 fill-gray-500 animate-spin"
            />
          </div>

          <div v-if="!isLoading && !folders?.length" class="px-3 py-2">
            <em class="text-gray-700">
              {{ $t('GED_FOLDER_NO_MSG') }}
            </em>
          </div>

          <div
            v-if="!isLoading && canShowMore"
            class="px-3 py-2 mt-2 flex-center"
          >
            <button
              class="px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300"
              @click="onLoadMore"
            >
              {{ $t('GED_SHOW_MORE') }}
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="px-3 py-2 flex items-center space-x-2">
        <div class="flex-1 overflow-auto flex items-center space-x-2">
          <div>{{ functionName }}</div>
          <AddFileLocation
            :location="selectedLocation"
            :is-drive-document="isDriveDocument"
            class="font-semibold"
          />
        </div>
        <vig-button ghost color="gray" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') }}
        </vig-button>
        <vig-button
          :processing="isConfirming"
          :disabled="isDisableConfirm"
          @click="onConfirmClick"
        >
          <span>{{ $t('COMMON_LABEL_CONFIRM') }}</span>
        </vig-button>
      </div>
    </template>
  </syn-modal>

  <ModalFolderAdd
    v-if="isAddFolder"
    :location="selectedLocation"
    :is-drive-folder="isDriveDocument"
    @on-close="isAddFolder = false"
    @on-folder-added="onFolderAdded"
  />
</template>
