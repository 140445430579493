<script setup lang="ts">
import dayjs from 'dayjs';
import { ref } from 'vue';

const emit = defineEmits(['change']);
const currentTime = ref(dayjs());

const gotoPreviousDay = () => {
  currentTime.value = dayjs(currentTime.value).subtract(1, 'day');
  emit('change', currentTime.value);
};
const gotoNextDay = () => {
  currentTime.value = dayjs(currentTime.value).add(1, 'day');
  emit('change', currentTime.value);
};
</script>
<template>
  <div class="flex-1 flex w-full h-full" style="max-width: 16rem">
    <div class="w-12 flex-center">
      <SynIcon
        name="ArrowLeft"
        has-action
        custom-class="h-8 w-8 fill-current-500"
        @click="gotoPreviousDay"
      />
    </div>
    <div class="w-full flex-1 flex-center flex-col">
      <div class="w-full flex-center bg-current px-2 py-1 rounded-t-xl">
        <span class="text-white capitalize">
          {{ $filters.dayjs(currentTime, 'MMMM, YYYY') }}
        </span>
      </div>
      <div
        class="w-full px-2 py-1 flex-center flex-col bg-current-50 rounded-b-xl"
      >
        <span class="text-2xl font-semibold text-current-800">
          {{ $filters.dayjs(currentTime, 'DD') }}
        </span>
        <span class="text-current-600 capitalize">
          {{ $filters.dayjs(currentTime, 'dddd') }}
        </span>
      </div>
    </div>
    <div class="w-12 flex-center">
      <SynIcon
        name="ArrowRight"
        has-action
        custom-class="h-8 w-8 fill-current-500"
        @click="gotoNextDay"
      />
    </div>
  </div>
</template>
