<script setup lang="ts">
import { INotificationMainDataTaskUpdate } from '@/application/types/notification.types';
import UserById from '@/ui/components/user/UserById.vue';
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import { computed } from 'vue';
const props = defineProps<{
  beforeUpdate: any;
  afterUpdate: any;
  isHiddenBefore?: boolean;
  isHiddenAfter?: boolean;
}>();

const beforeData = computed<INotificationMainDataTaskUpdate>(() => {
  const approvers =
    props.beforeUpdate?.approvers?.length > 0
      ? props.beforeUpdate?.approvers?.map((o) => covertSnakeCaseToCamelCase(o))
      : [];
  return { ...covertSnakeCaseToCamelCase(props.beforeUpdate), approvers };
});
const beforeApprover = computed<any>(() => {
  return beforeData.value?.approvers && beforeData.value?.approvers?.length > 0
    ? beforeData.value?.approvers[0]
    : {};
});

const afterData = computed<INotificationMainDataTaskUpdate>(() => {
  const approvers =
    props.afterUpdate?.approvers?.length > 0
      ? props.afterUpdate?.approvers?.map((o) => covertSnakeCaseToCamelCase(o))
      : [];
  return { ...covertSnakeCaseToCamelCase(props.afterUpdate), approvers };
});
const afterApprover = computed<any>(() => {
  return afterData.value?.approvers && afterData.value?.approvers?.length > 0
    ? afterData.value?.approvers[0]
    : {};
});
</script>
<template>
  <div class="inline-flex items-center space-x-1 w-full">
    <!-- BEFORE -->
    <UserById
      v-if="beforeApprover?.userId"
      :user-id="beforeApprover?.userId"
      is-show-first-name
    />

    <template v-if="!isHiddenAfter">
      <p v-if="beforeApprover?.userId && !isHiddenBefore">&#8594;</p>

      <!-- AFTER -->
      <UserById
        v-if="afterApprover?.userId"
        :user-id="afterApprover?.userId"
        is-show-first-name
      />
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </template>
  </div>
</template>
