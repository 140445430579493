<script setup lang="ts">
import { onUnmounted, ref, watch, computed } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import TaskCreateEntity from '@/domain/entities/task/TaskCreateEntity';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { TaskActionType, TaskLife } from '@/domain/enums/taskEnum';

import {
  ITaskTodoItemStatus,
  TaskApprovalStatus,
} from '@/application/types/task/task.types';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import {
  ITaskDetailClass,
  TaskDetailClass,
} from '@/domain/entities/task/TaskPresentClass';
import SubTaskListItemActions from '@/ui/modules/task/detail/sub-tasks/SubTaskListItemActions.vue';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { checkSystemConfig } from '@/ui/hooks/permission/permission-by-function';
import { EFunctionPermission } from '@/application/types/block-function.types';
import AtomNormalChecklist from '@/ui/common/atoms/checklist/AtomNormalChecklist.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import SearchTasksModal from '@/ui/modules/task/components/search/SearchTasksModal.vue';
import taskListStore from '@/store/task/task-list-store';
import { configsByTaskIds } from '../../task-global-state';

const props = defineProps<{
  parentTask?: ITaskDetailClass;
  domainId?: number;
  updateSubTaskId?: number;
  readonly?: boolean;
  isNotGroupMember?: boolean;
  isSelectMode?: boolean;
}>();
const emit = defineEmits([
  'onCancel',
  'onCreateSuccess',
  'onSave',
  'update:subTasks',
  'onAddNew',
  'goToTask',
  'detach',
]);

const todoListPayload = ref<
  {
    id: number;
    title: string;
    status: ITaskTodoItemStatus;
    index: number;
  }[]
>([]);
const hiddenTaskList = ref<any[]>([]);

const initComponent = () => {
  todoListPayload.value =
    props.parentTask?.subTasks
      ?.filter((t) => t?.isViewable || props.parentTask?.isCreateListAssignees)
      ?.map((t, index) => {
        return {
          ...t,
          id: t?.id,
          title: t?.name,
          status:
            t?.taskLife == TaskLife.Finished
              ? ITaskTodoItemStatus.FINISHED
              : ITaskTodoItemStatus.PENDING,
          index: index,
        };
      }) || [];

  hiddenTaskList.value =
    props.parentTask?.subTasks?.filter(
      (t) => !t?.isViewable && !props.parentTask?.isCreateListAssignees
    ) || [];
};

const atomChecklistRef = ref<any>(null);

const updateTodoList = () => {
  emit(
    'update:subTasks',
    todoListPayload.value?.map((item) => {
      return {
        ...item,
        taskLife:
          item?.status == ITaskTodoItemStatus.FINISHED
            ? TaskLife.Finished
            : TaskLife.Todo,
      };
    })
  );
};
const onAdd = (newItem) => {
  new TaskCreateEntity({
    ...newItem,
    id: undefined,
    parentId: props.parentTask?.id,
    assigneeId: props.parentTask?.assigneeId,
    domainId: props.parentTask?.domainId,
    projectId: props.parentTask?.projectId,
    subprojectId: props.parentTask?.subprojectId,
    priority: props.parentTask?.priority,
    important: props.parentTask?.important,
    urgency: props.parentTask?.urgency,
    name: newItem?.title,
    creatorId: getCurrentUserId(),
    groupId: props.isNotGroupMember ? null : props.parentTask?.groupId,
    taskLife:
      props.parentTask?.taskLife == TaskLife.Finished
        ? TaskLife.Todo
        : props.parentTask?.taskLife,
    workflowId: undefined,
    taskWorkflow: undefined,
    isRepeat: false,
    scheduleTime: '',
    isPrivate: !!props.parentTask?.isPrivate,
  }).create();
};
const onClone = (cloneItem) => {
  console.log('🚀 Tictop ~ cloneItem:', cloneItem);
  if (!cloneItem || !cloneItem?.id) return;

  new TaskCreateEntity({
    ...cloneItem,
    id: undefined,
  }).create();
};

const taskTodoListListenScrollRef = ref<any>();
useResizeObserver(taskTodoListListenScrollRef, (entries) => {
  const entry = entries[0];
  // const { width, height } = entry.contentRect
  // text.value = `width: ${width}, height: ${height}`
  const hasVerticalScrollbar =
    entry?.target?.scrollHeight > entry?.target?.clientHeight;

  if (hasVerticalScrollbar) stopBubbleEvent();
  else openBubbleEvent();
});

const stopPropagation = (event) => {
  event.stopPropagation();
};

const stopBubbleEvent = () => {
  const divEl = document.getElementById('task-todo-list-detail');
  if (!divEl) return;
  divEl.addEventListener('scroll', stopPropagation);
  divEl.addEventListener('wheel', stopPropagation);
  divEl.addEventListener('mousewheel', stopPropagation);
};
const openBubbleEvent = () => {
  const divEl = document.getElementById('task-todo-list-detail');
  if (!divEl) return;
  divEl.removeEventListener('scroll', stopPropagation);
  divEl.removeEventListener('wheel', stopPropagation);
  divEl.removeEventListener('mousewheel', stopPropagation);
};

onUnmounted(() => {
  openBubbleEvent();
});

const focusOnItem = (id) => {
  if (
    atomChecklistRef.value &&
    typeof atomChecklistRef.value?.focusOnItem == 'function'
  )
    atomChecklistRef.value?.focusOnItem(id);
};

initComponent();

const updateItemName = (item) => {
  if (item?.creationTime)
    new TaskDetailClass({
      ...item,
    }).rename(item?.title);
  else onAdd(item);
};

const latestUpdatedTime = ref<number>(Date.now());
watch(
  () => props.updateSubTaskId,
  () => {
    initComponent();

    latestUpdatedTime.value = Date.now();
  }
);

const onCloneSubTask = (task) => {
  if (
    atomChecklistRef.value &&
    typeof atomChecklistRef.value?.onClone == 'function'
  )
    atomChecklistRef.value?.onClone(task);
};

const totalFinishedSubTasks = computed<number>(() => {
  return (
    props.parentTask?.subTasks?.filter((o) => o?.taskLife == TaskLife.Finished)
      ?.length || 0
  );
});
const totalSubTasks = computed<number>(() => {
  return props.parentTask?.subTasks?.length;
});

const onGoToTask = (task) => {
  if (!task?.isViewable) {
    settingGlobalModal({
      type: 'notification',
      title: translate('DECIDE_PAYMENT_HAS_VOUCHER_CONFIRM_SELECT_TRIAL_TITLE'),
      content:
        translate('TASK_LABEL_YOU_ARE_NOT_ALLOWED_TO_VIEW_THIS_TASK') ||
        'You are on another call',
      confirmLabel: translate('COMMON_LABEL_CLOSE'),
      confirmable: true,
    });
    ask();
    return;
  }

  emit('goToTask', task);
};

const doNotNeedCheckSubtaskPermission = ref<boolean>(false);

const ignoreCheckSubtaskPermission = () => {
  doNotNeedCheckSubtaskPermission.value = true;
};

const isOpenSearchTaskModal = ref<boolean>(false);

const addSubTasks = (tasks) => {
  console.log('🚀 Tictop ~ tasks:', tasks);
  isOpenSearchTaskModal.value = false;

  taskListStore().updateMultipleTasks({
    taskIds: tasks,
    actionType: TaskActionType.ParentTaskAdded,
    extraData: {
      parentId: props.parentTask?.id,
    },
  });
};

const isHiddenFinished = computed<boolean>(() => {
  const taskId = props.parentTask?.id;

  if (
    !taskId ||
    !Object.prototype.hasOwnProperty.call(
      configsByTaskIds.value,
      props.parentTask?.id
    )
  )
    return false;
  return configsByTaskIds.value[taskId]?.isHiddenFinishedSubTask;
});

const updateIsHiddenFinishedDefault = (value) => {
  const taskId = props.parentTask?.id;

  if (!taskId) return;

  configsByTaskIds.value = {
    ...configsByTaskIds.value,
    [taskId]: {
      ...(configsByTaskIds.value[taskId] || {}),
      isHiddenFinishedSubTask: value,
    },
  };
};
defineExpose({
  focusOnItem,
});
</script>
<template>
  <div
    v-if="
      (checkSystemConfig('ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM', '1') !==
        EFunctionPermission.HIDDEN &&
        checkSystemConfig('ADVANCED_SUB_TASK_SUB_TASK_ALLOWED', '1') !==
          EFunctionPermission.HIDDEN) ||
      totalSubTasks > 0
    "
    :key="doNotNeedCheckSubtaskPermission?.toString()"
    v-permission-function="{
      functionCode: doNotNeedCheckSubtaskPermission
        ? ''
        : ALL_FUNCTIONS.TASK.SUB_TASK,
      needToShowPremiumIcon: true,

      onContinue: () => {
        ignoreCheckSubtaskPermission();
      },
    }"
    class="w-full h-full flex flex-col"
  >
    <section
      id="task-todo-list-detail"
      class="flex-1 min-h-0 w-full h-full flex flex-col gap-1"
    >
      <div
        id="task-todo-list-detail_checklist"
        class="flex-1 min-h-0 overflow-auto small-scrollbar"
      >
        <!-- ITEM -->
        <AtomNormalChecklist
          ref="atomChecklistRef"
          v-model="todoListPayload"
          :max-title-length="200"
          :need-to-update-item-id="latestUpdatedTime"
          :is-select-mode="isSelectMode"
          :readonly="readonly"
          :is-hidden-add-new="
            checkSystemConfig(
              'ADVANCED_SUB_TASK_SUB_TASK_ALLOWED_SYSTEM',
              '1'
            ) == EFunctionPermission.HIDDEN ||
            checkSystemConfig('ADVANCED_SUB_TASK_SUB_TASK_ALLOWED', '1') ==
              EFunctionPermission.HIDDEN
          "
          :is-hidden-finished-default="isHiddenFinished"
          is-disabled-edit-title
          :show-max-item="3"
          @update:model-value="updateTodoList"
          @update:is-hidden-finished-default="updateIsHiddenFinishedDefault"
          @add="onAdd"
          @clone="onClone"
          @update-item-name="updateItemName"
          @on-click-title="(item) => onGoToTask(item)"
        >
          <template #title>
            <div class="flex-center gap-2">
              <span class="text-xs text-gray-500 flex items-center h-6">
                {{ $t('COMMON_LABEL_SUB_TASK') || 'Sub tasks' }}
                <template v-if="!isSelectMode && totalSubTasks > 0"
                  >(<span class="lowercase pr-1">{{
                    $t('COMMON_LABEL_FINISH')
                  }}</span
                  >{{ totalFinishedSubTasks }}/{{ totalSubTasks }})</template
                >
              </span>
              <div
                v-if="false"
                v-vig-tooltip="$t('TASK_LABEL_SELECT_FROM_THE_TASK_LIST')"
                class="
                  w-5
                  h-5
                  rounded-md
                  cursor-pointer
                  hover:bg-current-50
                  flex-center
                  border border-gray-200
                "
                @click="isOpenSearchTaskModal = true"
              >
                <SynIcon
                  name="SelectList"
                  custom-class="h-4 w-4 fill-gray-500"
                />
              </div>
            </div>
          </template>
          <template #more-action>
            <div
              class="list-li flex justify-start items-center text-sm"
              @click="isOpenSearchTaskModal = true"
            >
              <span class="text-gray-500 pl-2">
                {{ $t('TASK_LABEL_SELECT_FROM_THE_TASK_LIST') }}
              </span>
            </div>
          </template>
          <template v-if="!isSelectMode" #prefix-icon="defaultProps">
            <div class="flex-center gap-1">
              <div class="h-10 w-10 flex-center cursor-pointer">
                <SubTaskListItemActions
                  :action-list="
                    defaultProps?.item?.approvalStatus ==
                    TaskApprovalStatus.WAITING
                      ? ['APPROVAL']
                      : ['FINISH']
                  "
                  :task="defaultProps?.item"
                  :readonly="readonly"
                />
              </div>

              <span
                class="text-sm pb-0.5 min-w-[2rem]"
                :class="
                  defaultProps?.item?.taskLife == TaskLife.Finished
                    ? 'text-green-500'
                    : defaultProps?.item?.taskLife == TaskLife.Archived ||
                      defaultProps?.item?.taskLife == TaskLife.Cancelled ||
                      defaultProps?.item?.taskLife == TaskLife.Draft ||
                      defaultProps?.item?.taskLife == TaskLife.Duplicated ||
                      defaultProps?.item?.taskLife == TaskLife.Pending
                    ? 'text-gray-400'
                    : 'text-gray-500'
                "
                >{{ formatTaskCode(defaultProps?.item?.code) }}
              </span>
            </div>
          </template>
          <template #action="defaultProps">
            <div class="flex items-center gap-2 h-10 pr-2">
              <SubTaskListItemActions
                :task="defaultProps?.item"
                :readonly="readonly"
                @on-clone="onCloneSubTask"
              />

              <div class="w-6 h-6 flex-center">
                <div
                  v-if="!isSelectMode"
                  v-vig-tooltip="$t('COMMON_LABEL_SUB_TASK_REMOVE_AS_SUB_TASK')"
                  class="
                    fill-gray-500
                    hover:fill-current
                    w-6
                    h-6
                    rounded-full
                    hover:bg-gray-50
                    flex-center
                  "
                  :class="
                    defaultProps?.item?.isViewable
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed'
                  "
                  @click="$emit('detach', defaultProps?.item)"
                >
                  <SynIcon
                    name="CloseLine"
                    custom-class="w-4 h-4 hover-to-show__children"
                  />
                </div>
              </div>
            </div>
          </template>
        </AtomNormalChecklist>

        <div v-if="hiddenTaskList?.length > 0" class="w-full flex items-center">
          <span
            class="text-xs text-gray-500 italic"
            v-html="
              $t(
                isSelectMode
                  ? 'TASK_LABEL_TOTAL_SUB_TASK_WILL_NOT_BE_DUPLICATED'
                  : 'SUB_TASK_LABEL_HIDDEN_SUB_TASKS_THAT_YOU_ARE_NOT_ALLOWED_TO_VIEW',
                {
                  total: hiddenTaskList?.length || 0,
                }
              )
            "
          >
          </span>
        </div>
      </div>
    </section>
  </div>

  <SearchTasksModal
    v-if="isOpenSearchTaskModal"
    :title="
      $t('TASK_LABEL_ADD_SUBTASKS_FOR_TASK', {
        taskCode: `#${formatTaskCode(parentTask?.code) || ''}`,
      })
    "
    is-select-multiple
    :filter-function="
      (t) => {
        return (
          !t?.totalSubTask &&
          t?.id !== parentTask?.id &&
          !todoListPayload?.some((st) => st?.id == t?.id)
        );
      }
    "
    @on-choose="addSubTasks"
    @on-close="isOpenSearchTaskModal = false"
  />
</template>
