<template>
  <div
    class="
      bg-white
      rounded-full
      hover:bg-current-100 hover:bg-opacity-100
      flex-center
      px-2
      py-1
      space-x-1
    "
    :class="!isInGroup ? 'border' : ' bg-opacity-100 '"
  >
    <span class="font-medium text-sm leading-none text-black">
      {{ totalReaction }}
    </span>
    <span
      v-for="reaction in firstReactionsContent"
      :key="reaction?.content"
      v-twemoji="{ size: 'w-4 h-4' }"
      class="w-4 h-4 flex-center"
      v-text="reaction?.content"
    >
    </span>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { chain } from 'lodash';

export default defineComponent({
  components: {},
  props: {
    reactions: {
      type: Array,
      default: () => [],
    },
    isInGroup: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const firstReactionsContent = computed(() =>
      chain(props.reactions).uniqBy('content').slice(0, 3).value()
    );

    const totalReaction = computed(() => props.reactions?.length);

    return {
      firstReactionsContent,
      totalReaction,
    };
  },
});
</script>
