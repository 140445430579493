<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 48 48"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="m24.62 5.22c-.37-.29-.88-.29-1.25 0l-20 16c-.43.34-.5.97-.16 1.41.34.43.98.5 1.41.16l2.38-1.91v21.12c0 .55.45 1 1 1h32c.55 0 1-.45 1-1v-21.12l2.38 1.9c.18.15.4.22.62.22.29 0 .58-.13.78-.38.34-.43.27-1.06-.16-1.41zm14.38 35.78h-30v-21.72l15-12 15 12z"
          class=""
        ></path>
        <path
          d="m19 23c0 1.53.7 2.88 1.79 3.8-.99.52-1.86 1.26-2.51 2.2h-3.28c-.31 0-.6.14-.79.38s-.26.56-.18.86l2 8c.11.45.51.76.97.76h14c.46 0 .86-.31.97-.76l2-8c.08-.3.01-.62-.18-.86s-.48-.38-.79-.38h-3.28c-.23-.33-.48-.65-.77-.94-.51-.52-1.09-.94-1.72-1.27 1.07-.92 1.77-2.27 1.77-3.79 0-2.76-2.24-5-5-5s-5 2.24-5 5zm12.72 8-1.5 6h-12.44l-1.5-6zm-10.71-2c.85-.64 1.9-1 2.99-1s2.12.36 2.97 1zm5.99-6c0 1.65-1.35 3-3 3s-3-1.35-3-3 1.35-3 3-3 3 1.35 3 3z"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
