import {
    DayoffActionType,
    DayoffDateSession,
    DayoffReason,
    DayoffType,
    DayoffStatus,
} from '@/domain/enums/DayoffEnums';

export default class DayoffNotificationItemClass {
    title: string;
    requesterId: number;
    requesterName: string;
    requesterAvatar: string;
    actionerId: number;
    actionerName: string;
    actionerAvatar: string;
    dayoffLink: string;
    dayoffId: string;
    type: string;
    dayoffStatus: DayoffStatus;
    status: boolean;
    actionType = DayoffActionType;
    organizationId: number;
    requestDate: number;
    requestType: DayoffType;
    startDate: number;
    endDate: number;
    startDateSession: DayoffDateSession;
    endDateSession: DayoffDateSession;
    requestDay: number;
    requestReason: DayoffReason;
    requestNote: string;
    rejectReason: string;
    rejectNote: string;
    currentStatusRequest: string;
    groupedContent: string;
    exception: object;

    constructor(data) {
        this.title = data?.title || '';
        this.requesterId = data?.requesterId || data?.requester_id;
        this.requesterName = data?.requesterName || data?.requester_name;
        this.requesterAvatar = data?.requesterAvatar || data?.requester_avatar;
        this.actionerId = data?.actionerId || data?.actioner_id;
        this.actionerName = data?.actioner_name;
        this.actionerAvatar = data?.actioner_avatar;
        this.dayoffLink = data?.dayoffLink || data?.dayoff_link;
        this.dayoffId = data?.dayoffId || data?.dayoff_id;
        this.type = data?.type;
        this.dayoffStatus = data?.request_status || '';
        this.status = data?.status || false;
        this.actionType = data?.actionType || data?.action_type;
        this.organizationId = data?.organizationId || data?.organization_id;
        this.requestDate = data?.requestDate || data?.request_date;
        this.requestType = data?.requestType || data?.request_type;
        this.startDate = data?.startDate || data?.start_date;
        this.endDate = data?.endDate || data?.end_date;
        this.startDateSession =
            data?.startDateSession || data?.start_date_session;
        this.endDateSession = data?.endDateSession || data?.end_date_session;
        this.requestDay = data?.requestDay || data?.request_day;
        this.requestReason = data?.requestReason || data?.request_reason;
        this.requestNote = data?.requestNote || data?.request_note;
        this.rejectReason = data?.rejectReason || data?.reject_reason;
        this.rejectNote = data?.rejectNote || data?.reject_note;
        this.groupedContent = data?.groupedContent || '';
        this.exception = data?.dayoffException || null;
        this.currentStatusRequest =
            data?.currentStatusRequest || data?.current_status_request;
    }
}
