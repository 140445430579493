<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  message: any;
}>();

const createdDate = computed(() => {
  if (!props.message?.createdDate) return new Date();

  return new Date(props.message.createdDate).getTime();
});
</script>

<template>
  <SynLabelDateTime format="time" :datetime="createdDate" ignore-timezone />
</template>
