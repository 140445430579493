<script setup lang="ts">
import { computed } from 'vue';
import domainStore from '@/store/scope';
// import { DomainStatus } from '@/domain/enums/domain-enums';

const props = defineProps<{
  domainId: number | string;
}>();

const _domainStore = domainStore();
const domainByIds = computed(() => _domainStore.domainByIds);
const domainDetail = computed(() => domainByIds.value[props.domainId]);
</script>

<template>
  <span class="flex-center space-x-2 w-max max-w-full relative">
    <span v-vig-tooltip="domainDetail?.name" class="truncate">
      <span v-if="domainDetail?.name">{{ domainDetail?.name }}</span>
      <span v-else class="text-gray-300">{{
        $t('COMMON_LABEL_DOMAIN_PROJECT')
      }}</span>
      <slot name="subName"></slot>
      <span v-if="domainDetail?.invisible" class="text-xs italic">
        ({{ $t('COMMON_LABEL_INVISIBLE') }})
      </span>
    </span>
    <!-- <span
      v-if="domainDetail?.status === DomainStatus.Created"
      class="flex-center"
      :title="
        $t('DOMAIN_NEED_TO_VERIFY_EXPLAIN') ||
        'Domain or Project which was created by user need to verified'
      "
    >
      <SynIcon name="warning" class="fill-orange-500 flex-none" />
    </span> -->
  </span>
</template>
