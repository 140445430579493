<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import FormInfo from './FormInfo.vue';
import { fetchAllProfile, fetchAllPosition } from './UserState';
// import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import userStore from '@/store/user';
import ButtonAvatars from '@/ui/modules/avatars/ButtonAvatars.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import departmentStore from '@/store/department';
import myProfileStore from '@/store/auth/my-profile';

const props = withDefaults(
  defineProps<{
    userId: string | number;
    info: any;
    keyNotShow: string[];
    keyDisable: any[];
    isEdit?: boolean;
  }>(),
  {
    keyNotShow: () => [
      'login',
      'firstName',
      'lastName',
      'skyper',
      'active',
      'identityCard',
      'positionId',
    ],
  }
);

const emit = defineEmits(['update:info', 'update:file', 'close']);

const _userStore = userStore();

const userInfo = computed(() => props?.info);

const userDepartment = computed<any>(() => {
  if (
    !userInfo.value ||
    !userInfo.value?.id ||
    !userInfo.value?.departmentInfo ||
    userInfo.value?.departmentInfo?.length == 0
  )
    return {};

  const departmentId = userInfo.value?.departmentInfo[0]?.departmentId;
  return departmentStore().allDepartmentByIds[departmentId];
});
const canViewInfo = computed(() => {
  if (
    !userDepartment.value ||
    !userDepartment.value?.id ||
    userDepartment.value?.id == myProfileStore().myDepartment?.departmentId ||
    myProfileStore().isOwner
  )
    return true;

  return (
    userDepartment.value?.departmentSetting?.visibleForAllMember &&
    userDepartment.value?.departmentSetting?.visiblePersonalData
  );
});
const typeAdmin = computed(() => _userStore.typeAdmin);
const position = computed(() => _userStore.position);

onMounted(async () => {
  if (typeAdmin.value.length < 1) await fetchAllProfile();
  if (position.value.length < 1) await fetchAllPosition();
});

const changeInfo = (value) => {
  emit('update:info', {
    info: value.info,
  });
};
const currentPreview = ref({} as any);
const openDocument = (doc) => {
  if (!canViewInfo.value) return;

  currentPreview.value = doc;
};
const closePreview = () => {
  currentPreview.value = {};
};

const closeModal = () => {
  emit('close', false);
};

const newAvatar = ref('');

const updateAvatar = (avatars) => {
  newAvatar.value = avatars?.avatarUrl || avatars?.avatarThumbnailUrl || '';
};
</script>

<template>
  <section class="flex justify-center flex-wrap space-y-4">
    <div
      class="relative w-full h-32 rounded-t-md"
      style="
        background-image: linear-gradient(-225deg, #b7f8db 0%, #50a7c2 100%);
      "
    >
      <div class="absolute top-10 z-10 flex-center w-full">
        <div class="hover-to-show__parent cursor-pointer">
          <SynAvatarStatusActive
            :id="userInfo?.id || userInfo?.userId"
            :avatar="newAvatar || userInfo.avatarUrl"
            :name="userInfo.lastName + ' ' + userInfo.firstName"
            is-hidden-name
            custom-class="w-32 h-32"
            is-user
            @click="openDocument(userInfo)"
          />

          <ButtonAvatars
            v-if="userInfo?.id == getCurrentUserId()"
            size="w-1/3 h-1/3"
            :user-id="userInfo?.id || userInfo?.userId"
            :avatar="userInfo.avatarUrl"
            @update-avatar="updateAvatar"
          />
        </div>
      </div>
      <div
        v-if="userInfo?.id == getCurrentUserId()"
        class="
          absolute
          right-1
          bottom-1
          rounded-md
          z-20
          border border-white
          cursor-pointer
          text-center
          h-7
          w-7
          hover:bg-current-200
        "
        @click="closeModal"
      >
        <router-link :to="{ name: 'Profile' }">
          <SynIcon
            name="settings"
            custom-class="h-4 w-4 fill-white"
            is-active
          ></SynIcon>
        </router-link>
      </div>
    </div>

    <div class="font-medium w-full text-center text-lg text-current-800 pt-8">
      {{ userInfo.lastName + ' ' + userInfo.firstName }}
    </div>

    <div class="text-sm px-4">
      <FormInfo
        :info="userInfo"
        :key-not-show="
          userInfo?.id !== getCurrentUserId()
            ? keyNotShow
            : [
                'login',
                'firstName',
                'lastName',
                'skyper',
                'active',
                'identityCard',
                'positionId',
              ]
        "
        :key-disable="keyDisable"
        :is-edit="isEdit"
        :hidden-detail="!canViewInfo"
        @update:info="(value) => changeInfo(value)"
      />
    </div>
  </section>

  <syn-preview-doc
    v-if="currentPreview && currentPreview.avatarUrl"
    :src="currentPreview.avatarUrl"
    is-image
    @close-preview="closePreview"
  ></syn-preview-doc>
</template>
