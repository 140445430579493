<script setup lang="ts">
import UploadAvatarIcon from '@/ui/components/photo-upload/UploadAvatarIcon.vue';
import userStore from '@/store/user';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { computed, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import organizationStore from '@/store/organization';

defineProps<{
  knowAppFrom: string | undefined;
  address: string | undefined;
  isHiddenSelectPosition?: boolean;
}>();

defineEmits<{
  (e: 'update:knowAppFrom', value: string): void;
  (e: 'update:address', value: string): void;
}>();

const PLATFORM_LIST = [
  {
    key: 'FACEBOOK',
    label: 'Facebook',
    uiClass: 'border-current-500 text-current-500',
  },
  {
    key: 'INSTAGRAM',
    label: 'Instagram',
    uiClass: 'border-orange-500 text-orange-500',
  },
  {
    key: 'GOOGLE',
    label: 'Google',
    uiClass: 'border-gray-500 text-gray-500',
  },
  {
    key: 'YOUTUBE',
    label: 'Youtube',
    uiClass: 'border-red-500 text-red-500',
  },
  {
    key: 'TIKTOK',
    label: 'Tiktok',
    uiClass: 'border-gray-700 text-gray-700',
  },
  {
    key: 'BNI',
    label: 'BNI',
    uiClass: 'border-sky-500 text-sky-500',
  },
  {
    key: 'ACQUAINTANCE',
    label: 'Acquaintance',
    uiClass: 'border-green-500 text-green-500',
  },
  {
    key: 'OTHERS',
    label: 'Others',
    uiClass: 'border-gray-500 text-gray-500',
  },
];
const _userStore = userStore();
const jobTitles = computed<any[]>(() => {
  return organizationStore().jobTitles;
});
const avatarFile = ref();
const isAddAvatar = ref(false);

const myProfile = computed<any>(() => {
  return myProfileStore().myProfile;
});
const onUploadAvatar = async (file) => {
  if (!file) return;

  avatarFile.value = file;

  let fileName = '';

  try {
    const result = await _userStore.uploadAvatarAws(file);
    fileName = result?.path;
    // info.value.avatar = fileName.value;
    // infoUserUpdate.value.avatar = fileName.value;
  } catch (error) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('COMMON_ERROR_UPLOAD_AVATAR'),
      confirmable: true,
      closeable: true,
    });
    ask();
  }

  if (!fileName) return;

  try {
    await _userStore.updateAvatarUser(myProfile.value?.id, fileName, undefined);

    isAddAvatar.value = true;
  } catch (error) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('COMMON_ERROR_UPLOAD_AVATAR'),
      confirmable: true,
      closeable: true,
    });
    ask();
  }
};

const selectedJobTitle = ref<any>({ id: 0, name: '' });
const onSelectJobTitle = (role) => {
  selectedJobTitle.value = role;
};
const onClearJobTitle = () => {
  selectedJobTitle.value = { id: 0, name: '' };
};

const countryString = ref<string>('');
const cityString = ref<string>('');
</script>

<template>
  <div class="flex flex-col gap-2 w-[32rem]">
    <!-- REGISTER_STEP_KEYS.PERSONAL_SETUP -->
    <div class="text-center">
      {{ $t('FIRSTTIME_SETUP_PERSONAL_ORGANIZATION_ADD_AVATAR') }}
    </div>

    <div class="w-full flex gap-8">
      <div class="w-32">
        <UploadAvatarIcon
          is-show-button
          :file="avatarFile"
          :name="myProfile.fullName?.trim() || myProfile.firstName?.trim()"
          size="medium"
          :text-style="`font-size: 2.5rem; font-weight: 600;`"
          type-upload="GROUP"
          type="group"
          z-index="z-50"
          :is-update-success="isAddAvatar"
          @upload-avatar="onUploadAvatar"
        />
      </div>
      <div class="flex-1 flex flex-col pt-4 gap-2">
        <div>
          {{ myProfile?.fullName }}
        </div>
        <div v-if="!isHiddenSelectPosition">
          <VigSelect
            :placeholder="'Select your role'"
            key-search="name"
            key-label="name"
            toggle-class="py-1"
            :label-value="selectedJobTitle.name"
            :origin-options="jobTitles"
            @on-choose="onSelectJobTitle"
            @on-clear="onClearJobTitle"
          />
        </div>
        <div class="flex-center gap-2">
          <SynFormInput>
            <SynInput
              ref="organizationNameRef"
              v-model="countryString"
              custom-class="text-sm h-10"
              class="h-10"
              :placeholder="$t('COMMON_LABEL_COUNTRY') || ''"
              @update:model-value="
                $emit('update:address', `${cityString}, ${countryString}`)
              "
            />
          </SynFormInput>
          <SynFormInput>
            <SynInput
              ref="organizationNameRef"
              v-model="cityString"
              custom-class="text-sm h-10"
              class="h-10"
              :placeholder="$t('COMMON_LABEL_PROVINCE_CITY') || ''"
              @update:model-value="
                $emit('update:address', `${cityString}, ${countryString}`)
              "
            />
          </SynFormInput>
        </div>
      </div>
    </div>
    <div class="w-full flex gap-8">
      <div class="w-32">
        <span class="text-sm">
          {{ $t('REGISTER_PROCESS_C_LEARNED_ABOUT_TICTOP_FROM') }}
        </span>
      </div>
      <div class="flex-1 flex gap-1 flex-wrap">
        <SynRadio
          v-for="item in PLATFORM_LIST"
          :key="item?.key"
          :model-value="knowAppFrom"
          :value="item?.key"
          is-hidden-radio-button
          @update:model-value="(value) => $emit('update:knowAppFrom', value)"
        >
          <span
            :class="[
              item?.uiClass,
              ' rounded-md border px-2 py-1 text-xs h-6',
              knowAppFrom == item?.key ? 'bg-current-100' : 'bg-white',
            ]"
            >{{ item?.label }}</span
          >
        </SynRadio>
      </div>
    </div>
  </div>
</template>
