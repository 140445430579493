<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  percent: number;
}>();

/*
min dimension : -144
max dimension: 56
100% ~ 200
1% ~ 2
*/

const dimensionByPercent = computed(() => {
  return Math.round(props.percent * 2 - 144);
});
</script>
<template>
  <div class="flex-center">
    <svg viewBox="0 0 268 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.661 39.4742C27.1712 51.9246 17.2391 66.7376 10.4335 83.065C3.62781 99.3924 0.0823108 116.914 1.26226e-06 134.625C-0.00058364 135.665 0.20211 136.695 0.596494 137.656C0.990878 138.617 1.56922 139.491 2.29844 140.226C3.02767 140.962 3.89348 141.545 4.84637 141.943C5.79926 142.341 6.82054 142.545 7.85182 142.544H55.4848C56.5161 142.545 57.5374 142.341 58.4902 141.943C59.4431 141.545 60.3089 140.962 61.0382 140.226C61.7674 139.491 62.3457 138.617 62.7401 137.656C63.1345 136.695 63.3372 135.665 63.3366 134.625C63.331 125.266 65.1585 115.998 68.7142 107.354C72.2699 98.7089 77.4837 90.8579 84.0563 84.2515L79.2212 63.5361L66.0715 50.2718L39.6625 39.4742H39.661Z"
        fill="url(#paint0_linear_3_19)"
      />
      <path
        d="M134 -1.48867e-06C134 2.59093 126.148 5.11304 126.148 7.5813V55.4357C126.148 58.0116 134 60.6444 134 63.3535C143.279 63.3476 152.469 65.1908 161.04 68.7771C169.611 72.3634 177.395 77.6222 183.945 84.2515L191.088 84.9665L223.967 51.8051L228.34 39.4742C215.968 26.9517 201.265 17.0181 185.075 10.2439C168.885 3.46966 151.528 -0.0116286 134 -1.48867e-06Z"
        fill="url(#paint1_linear_3_19)"
      />
      <path
        d="M39.661 39.4742L84.0503 84.2515C90.6004 77.6224 98.3844 72.3636 106.955 68.7773C115.526 65.1911 124.715 63.3478 133.994 63.3535V-6.19707e-06C116.468 -0.0106479 99.1116 3.47111 82.9231 10.2453C66.7346 17.0195 52.0324 26.9526 39.661 39.4742Z"
        fill="url(#paint2_linear_3_19)"
      />
      <path
        d="M228.337 39.4742L183.944 84.25C190.516 90.8565 195.73 98.7074 199.286 107.352C202.842 115.997 204.669 125.265 204.663 134.623C204.663 135.664 204.866 136.694 205.26 137.655C205.654 138.616 206.233 139.489 206.962 140.225C207.691 140.96 208.557 141.543 209.51 141.941C210.463 142.339 211.484 142.543 212.515 142.543H260.154C261.185 142.543 262.207 142.339 263.16 141.941C264.112 141.543 264.978 140.96 265.708 140.225C266.437 139.489 267.015 138.616 267.409 137.655C267.804 136.694 268.007 135.664 268.006 134.623C267.922 116.912 264.376 99.3906 257.569 83.0635C250.762 66.7363 240.828 51.9238 228.337 39.4742Z"
        fill="url(#paint3_linear_3_19)"
      />
      <g
        :style="`transform: rotate(${dimensionByPercent}deg); transform-origin: 50% 85%`"
      >
        <path
          d="M177.028 84.1598C176.234 83.2652 175.17 82.659 174 82.4347C172.83 82.2104 171.62 82.3804 170.555 82.9186C152.316 92.1926 138.498 99.1615 120.27 108.325C117.344 109.804 114.784 111.925 112.777 114.532C110.77 117.139 109.367 120.167 108.672 123.393C107.977 126.618 108.008 129.96 108.761 133.172C109.514 136.385 110.972 139.386 113.026 141.956C116.146 145.88 120.503 148.609 125.373 149.691C130.244 150.772 135.334 150.141 139.8 147.902C143.486 145.949 146.57 143.013 148.715 139.414C159.245 121.778 167.239 108.422 177.767 90.7903C178.362 89.7504 178.612 88.5456 178.479 87.3524C178.346 86.1592 177.837 85.0404 177.028 84.1598Z"
          fill="#495A79"
        />
        <path
          d="M130.892 137.972C137.036 137.972 142.016 132.949 142.016 126.753C142.016 120.557 137.036 115.533 130.892 115.533C124.749 115.533 119.769 120.557 119.769 126.753C119.769 132.949 124.749 137.972 130.892 137.972Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3_19"
          x1="60.3524"
          y1="60.1913"
          x2="19.2307"
          y2="136.314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FECDD3" />
          <stop offset="1" stop-color="#EF4444" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_19"
          x1="202.69"
          y1="64.4046"
          x2="137.108"
          y2="34.3892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CFFAFE" />
          <stop offset="1" stop-color="#FCD34D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3_19"
          x1="133.994"
          y1="33.7848"
          x2="68.3585"
          y2="69.8249"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FCD34D" />
          <stop offset="1" stop-color="#FECACA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3_19"
          x1="225.975"
          y1="142.543"
          x2="195.745"
          y2="67.6922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2DD4BF" />
          <stop offset="1" stop-color="#CFFAFE" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>
