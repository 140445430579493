<template>
  <div ref="tictopSwiper" class="swiper-container h-full relative">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div
          class="bg-current-400 h-full flex-center flex-col"
          :style="`background-image: linear-gradient(
              -225deg,
              #b7f8db 0%,
              #50a7c2 100%
            );`"
        >
          <span
            class="text-4xl text-white text-center pb-8"
            v-html="$t('INTRODUTION_LOGIN_LABEL_01')"
          ></span>
          <img :src="srcImage + 'image-qc.png'" class="w-4/6" />
        </div>
      </div>
      <div class="swiper-slide">
        <div
          class="bg-current-400 h-full flex-center flex-col"
          :style="`background-image: linear-gradient(
              to right,
              #74ebd5 0%,
              #9face6 100%
            );`"
        >
          <span
            class="text-4xl text-white text-center pb-8"
            v-html="$t('INTRODUTION_LOGIN_LABEL_02')"
          ></span>
          <img
            :src="srcImage + '11012021-feature_overview.png'"
            class="w-10/12"
          />
        </div>
      </div>
      <div class="swiper-slide">
        <div
          class="bg-current-400 h-full flex-center flex-col"
          :style="`background-image: linear-gradient(
              -225deg,
              #b7f8db 0%,
              #50a7c2 100%
            );`"
        >
          <span
            class="text-4xl text-white text-center pb-8"
            v-html="$t('INTRODUTION_LOGIN_LABEL_03')"
          ></span>
          <img
            :src="srcImage + '21012021-feature_createtask.png'"
            class="w-10/12"
          />
        </div>
      </div>
      <div class="swiper-slide">
        <div
          class="bg-current-400 h-full flex-center flex-col"
          :style="`background-image: linear-gradient(
              -225deg,
              #7de2fc 0%,
              #b9b6e5 100%
            );`"
        >
          <span
            class="text-4xl text-white text-center pb-8"
            v-html="$t('INTRODUTION_LOGIN_LABEL_04')"
          ></span>
          <img
            :src="srcImage + '11012021-feature_startwkday.png'"
            class="w-10/12"
          />
        </div>
      </div>
    </div>
    <div
      class="swiper-pagination absolute bottom-0 h-16 w-full flex-center"
      style="z-index: 999; width: 100%"
    ></div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
const SRC_PUBLIC = 'https://d1tvqetrcurhzb.cloudfront.net/web';
const srcImage = `${SRC_PUBLIC}/image_introdution/`;
Swiper.use([Navigation, Pagination, Autoplay]);

export default {
  components: {},
  setup() {
    let tictopSwiper = ref(null);

    onMounted(() => {
      new Swiper(tictopSwiper.value, {
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });
    });

    return {
      tictopSwiper,
      srcImage,
    };
  },
};
</script>
