<script setup lang="ts">
import { computed, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import settingStore from '@/store/setting';
import permissionStore from '@/store/permission';

import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';

defineProps<{
  message?: ChatMessageModel;
}>();
const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'createNewDayOff'): void;
  (e: 'createNewEvent'): void;
  (e: 'createNewReminder'): void;
}>();

const _settingStore = settingStore();

const sizeIcon = ref('w-4 h-4');

const dayoffEnabled = computed(() => _settingStore.dayoffEnabled);
const imVisitor = computed(() => myProfileStore().isVisitor);
const calendarEnabled = computed(() => _settingStore.calendarEnabled);

const allCalendarTypes = {
  DAYOFF: {
    key: 'DAYOFF',
    colorText: 'gray',
    textCode: 'COMMON_MODULE_DAYOFF',
    textDefault: 'Dayoff',
    iconName: 'Calendars',
    onClick: async () => {
      emit('createNewDayOff');
    },
  },
  EVENT: {
    key: 'EVENT',
    colorText: 'purple',
    textCode: 'CALENDAR_FILTER_TITLE_TYPE_EVENT',
    textDefault: 'Event',
    iconName: 'Event',
    onClick: async () => {
      emit('createNewEvent');
    },
  },
  REMINDER: {
    key: 'REMINDER',
    colorText: 'green',
    textCode: 'CALENDAR_FILTER_TITLE_TYPE_REMINDER',
    textDefault: 'Reminder',
    iconName: 'AlarmClock',
    onClick: async () => {
      emit('createNewReminder');
    },
  },
};
const calendarsTypeList = computed(() => {
  let result: any[] = [];
  if (
    dayoffEnabled.value &&
    permissionStore().organizationModel !== 'PERSONAL' &&
    !imVisitor.value
  ) {
    result = [allCalendarTypes.DAYOFF, ...result];
  }
  if (calendarEnabled.value) {
    result = [...result, allCalendarTypes.EVENT, allCalendarTypes.REMINDER];
  }
  return result;
});
</script>
<template>
  <VigDropdown placement="right" trigger="mouseenter focus click">
    <template #dropdown-toggle>
      <button
        class="
          p-2
          dropdown-item
          w-full
          whitespace-nowrap
          hover:bg-gray-100
          flex
          items-center
          space-x-3
          text-gray-600 text-sm
        "
      >
        <SynIcon
          name="Reminder"
          custom-class="w-3.5 h-3.5"
          class="fill-gray-500"
        />
        <span class="flex-1 text-left">
          {{
            $t('CALENDAR_CREATE_NEW_REMINDER') ||
            'Create event from this message'
          }}
        </span>
        <SynIcon name="ChevronRight" custom-class="w-3 h-3 fill-gray-500" />
      </button>
    </template>
    <template #dropdown-menu>
      <div class="w-full flex-center flex-col p-1">
        <div
          v-for="calType in calendarsTypeList"
          :key="calType?.key"
          class="
            w-full
            flex
            items-center
            rounded
            cursor-pointer
            relative
            hover:bg-gray-100
            gap-2
            px-2
            py-1
            pr-4
          "
          :class="[`text-${calType.colorText}-800`]"
          @click.stop="calType.onClick"
        >
          <SynIcon
            :name="calType.iconName"
            is-active
            :custom-class="`${sizeIcon}`"
            :class="`fill-${calType.colorText}-500`"
          />

          <span class="text-sm">
            {{ $t(calType.textCode) || calType.textDefault }}
          </span>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
