<script setup lang="ts">
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { ref } from 'vue';
import _ from 'lodash';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';

const emit = defineEmits(['onFilter']);

defineProps<{
  departmentList: [];
}>();

const departmentSelectedList = ref([] as any);
const chooseDepartment = (department: any) => {
  if (departmentSelectedList.value.some((e) => e == department)) {
    _.remove(departmentSelectedList.value, (e) => e == department);
  } else departmentSelectedList.value.push(department);

  emit('onFilter', departmentSelectedList.value);
};

const reset = () => {
  departmentSelectedList.value = [];
  emit('onFilter', departmentSelectedList.value);
};
</script>
<template>
  <vig-dropdown>
    <template #dropdown-toggle>
      <div
        v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER')"
        class="w-6 h-6 flex-center relative hover:bg-current-50 rounded-full"
      >
        <SynIcon
          name="filter2"
          class="cursor-pointer fill-current"
          :custom-class="`w-3.5 h-3.5`"
        />
        <div
          v-if="departmentSelectedList.length > 0"
          class="w-2.5 h-2.5 rounded-full bg-red-500 absolute top-0 right-0"
        ></div>
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="py-2 px-3 overflow-auto max-h-80 small-scrollbar"
        style="max-width: 250px"
      >
        <div class="flex justify-end">
          <div
            class="
              py-1
              px-2
              rounded
              cursor-pointer
              hover:bg-gray-50
              bg-gray-100
              text-gray-500 text-xs
            "
            @click="reset()"
          >
            {{ $t('COMMON_LABEL_RESET_FILTER') }}
          </div>
        </div>
        <div
          v-for="(department, index) in departmentList"
          :key="index"
          class="flex items-center mt-2 py-1"
        >
          <div class="h-4 w-4 mr-3 flex-center cursor-pointer">
            <SynIcon
              class="fill-current text-current"
              custom-class="w-4 h-4"
              name="checkbox"
              :is-active="
                departmentSelectedList.findIndex((o) => o === department) !== -1
              "
              @click="chooseDepartment(department)"
            />
          </div>

          <DepartmentById
            :department-id="department"
            avatar-class="w-5 h-5"
            :is-name-center="false"
            class="text-sm text-overflow-hidden-line flex-1"
            :class="
              departmentSelectedList.findIndex((o) => o === department) !== -1
                ? 'text-current font-medium'
                : ''
            "
          />
        </div>
        <div class="flex items-center mt-2">
          <div class="flex-center h-4 w-4 mr-3 cursor-pointer">
            <SynIcon
              class="fill-current text-current"
              custom-class="w-4 h-4"
              name="checkbox"
              :is-active="
                departmentSelectedList.findIndex((o) => o === null) !== -1
              "
              @click="chooseDepartment(null)"
            />
          </div>
          <div
            class="
              bg-gray-400
              rounded-full
              border
              flex-center
              overflow-hidden
              h-5
              w-5
              mr-2
            "
          >
            <SynIcon name="user" custom-class="h-5 w-5 fill-white" />
          </div>
          <span
            class="text-sm text-overflow-hidden-line"
            :class="
              departmentSelectedList.findIndex((o) => o === null) !== -1
                ? 'text-current font-medium'
                : ''
            "
            >{{ $t('DEPARTMENT_LABEL_USER_HAS_NOT_DEPARTMENT') }}</span
          >
        </div>
      </div>
    </template>
  </vig-dropdown>
</template>
