<script setup lang="ts">
import { ref, computed } from 'vue';
import getDynamicPosition from '@/ui/composables/app/dynamic-position';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    placeholder: string;
    data: any[];
    inputClass: string;
    dropdownClass: string;
  }>(),
  {
    inputClass:
      'border border-gray-300 py-2 px-3 rounded-md focus:outline-none focus:shadow-outline',
    dropdownClass: '',
  }
);

const emit = defineEmits([
  'update:modelValue',
  'update:id',
  'chosen',
  'enter',
  'blur',
]);

const showOptions = ref(false);
const searchTerm = ref('');
const searchResults = computed(() => {
  return props.data?.filter((item: any) => {
    return item?.name.toLowerCase().includes(searchTerm.value?.toLowerCase());
  });
}) as any;

const totalResult = computed(() => searchResults.value?.length);

const reset = () => {
  emit('update:modelValue', '');
};
const currentIndex = ref(null as number | null);
const handleInput = (evt) => {
  emit('update:modelValue', evt.target.value);
  searchTerm.value = evt.target.value;
  showOptions.value = true;
  currentIndex.value = totalResult.value > 0 ? 0 : null;
};

const inputRef = ref(null as any);
const dropdownRef = ref(null as any);

const { dynamicPosition } = getDynamicPosition(inputRef, dropdownRef);
const choseItem = (item) => {
  emit('update:modelValue', item.name);
  emit('update:id', item.id);
  emit('chosen', item);
  showOptions.value = false;
  inputRef.value?.focus();
  emit('enter');
};

const clickedOutside = () => {
  showOptions.value = false;
};
const focus = () => {
  inputRef.value?.focus();
};

const onScrollToCurrentItem = () => {
  const element = document.getElementById(
    `atom-auto-complete-item-id_${currentIndex.value}`
  ) as HTMLDivElement;

  element?.scrollIntoView({ block: 'center' });
};

const onKeyDownSearch = () => {
  if (!totalResult.value || currentIndex.value === null) return;
  if (currentIndex.value == totalResult.value - 1) {
    currentIndex.value = 0;
  } else {
    currentIndex.value = currentIndex.value + 1;
  }

  setTimeout(onScrollToCurrentItem);
};

const onKeyUpSearch = () => {
  if (!totalResult.value || currentIndex.value === null) return;
  if (currentIndex.value == 0) {
    currentIndex.value = totalResult.value;
  } else {
    currentIndex.value = currentIndex.value - 1;
  }
  setTimeout(onScrollToCurrentItem);
};

const onKeyDownEnter = () => {
  if (currentIndex.value !== null && currentIndex.value >= 0) {
    choseItem(searchResults.value[currentIndex.value]);
  } else emit('enter');
};

defineExpose({
  focus,
});
</script>

<template>
  <div v-click-outside="clickedOutside" class="relative hover-to-show__parent">
    <input
      ref="inputRef"
      :value="modelValue"
      :placeholder="placeholder"
      tabindex="0"
      :class="inputClass"
      style="width: 200px"
      @input="handleInput"
      @keyup.enter="onKeyDownEnter"
      @blur="$emit('blur')"
      @keyup.down="onKeyDownSearch"
      @keyup.up="onKeyUpSearch"
    />
    <div
      v-if="modelValue"
      class="
        hover-to-show__children
        absolute
        inset-y-0
        right-0
        pr-3
        flex
        items-center
        cursor-pointer
      "
      @click.prevent="reset()"
    >
      <SynIcon name="close" custom-class="w-3 h-3 " />
    </div>
  </div>
  <teleport to="body">
    <div
      v-show="modelValue && showOptions && searchResults?.length > 0"
      ref="dropdownRef"
      tabindex="0"
      class="
        absolute
        w-full
        z-50
        bg-white
        border border-gray-300
        mt-1
        mh-48
        overflow-y-auto
        small-scrollbar
        rounded-md
        shadow-md
      "
      :style="[
        `top: ${dynamicPosition.y}px`,
        `left: ${dynamicPosition.x}px`,
        `width: 200px`,
      ]"
      @focusout="showOptions = false"
    >
      <ul class="py-1">
        <li
          v-for="(item, index) in searchResults"
          :id="`atom-auto-complete-item-id_${index}`"
          :key="index"
          :class="index == currentIndex ? 'bg-gray-100' : ''"
          class="px-3 py-2 cursor-pointer hover:bg-gray-200"
          @click="choseItem(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </teleport>
</template>

<style scoped>
.mh-48 {
  max-height: 10rem;
}
</style>
