<script setup lang="ts">
import {
  computed,
  onMounted,
  onUnmounted,
  onUpdated,
  reactive,
  ref,
  toRaw,
  watch,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import UserById from '@/ui/components/user/UserById.vue';
import ChangeAssign from '@/ui/modules/task/detail/ChangeAssign.vue';
import {
  formatTaskCode,
  getConfigScheduleColor,
  getDetailTaskUrl,
  getScheduleTimeAfterChangeDate,
} from '@/ui/hooks/taskHook';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import taskStore from '@/store/task';
import taskListStore from '@/store/task/task-list-store';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { arrayOrderBy, copyToClipBroard } from '@/ui/hooks/commonFunction';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import GroupById from '@/ui/components/group/GroupById.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import UpdateDeadline from '@/ui/modules/task/detail/UpdateDeadline.vue';
import taskDrawerStore from '@/store/task/drawer';
import taskDetailStore from '@/store/task/detail';
import domainStore from '@/store/scope';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import {
  ScheduleDayOptions,
  TaskActionType,
  TaskLife,
} from '@/domain/enums/taskEnum';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import ChangeGroupAndUser from '../general/ChangeGroupAndUser.vue';
import UploadDropZone from '@/ui/modules/ged/upload/upload-drop-zone/UploadDropZone.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import UpdateDomainModal from '@/ui/modules/task/detail/UpdateDomainModal.vue';
import settingStore from '@/store/setting';
import { isEdittingName } from '@/ui/modules/task/detail/task-detail-global-state';
import commentLogic from '@/ui/modules/task/general/comment-logic';
import AttachmentGroupButton from '@/ui/modules/task/components/AttachmentGroupButton.vue';
import updateAttachmentLogic from '@/ui/modules/task/detail/update-attachment-logic';
import AttachmentUploading from '@/ui/modules/task/components/AttachmentUploading.vue';
import customDocumentTitle from '@/ui/composables/app/document-title';
import DomainProjectById from '@/ui/modules/domains/component/DomainProjectById.vue';
import UpdateDomainDropdown from '@/ui/modules/task/detail/UpdateDomainDropdown.vue';
import TaskLogActivity from '@/ui/modules/task/detail/TaskLogActivity.vue';
import taskLogActivityComposable from '@/ui/composables/task/history-task';
import TaskDescriptionInput from '@/ui/common/plugins/ckeditor/TaskDescriptionInput.vue';
import remoteConfigStore from '@/store/remoteConfig';
import TaskDetailField from '@/ui/modules/task/components/TaskDetailField.vue';
import detailTaskComposable from '@/ui/composables/task/detail-task';
import ListTagOption from '@/ui/common/atoms/SynOption/ListTagOption.vue';
import TaskComment from '@/ui/modules/task/detail/TaskComment.vue';
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import TaskStateStepper from '@/ui/modules/task/components/TaskStateStepper.vue';
// import TaskCard from '@/ui/modules/overview/TaskCard.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import {
  ETaskRepeatType,
  ITaskReccurring,
} from '@/application/interfaces/tasks/task-interfaces';
import { repeatTypeByKeys } from '@/ui/modules/task/constants/constant-task-repeats';
import SetRepeatsModal from '@/ui/modules/task/detail/SetRepeatsModal.vue';
import ApplyWorkFlowModal from '@/ui/modules/task/workflow/ApplyWorkFlowModal.vue';
import PerfectScrollbar from '@/ui/plugins/scrollbar/perfect-scrollbar';
import TaskService from '@/application/services/task/TaskService';
import TaskCommentBottom from '@/ui/modules/task/detail/TaskCommentBottom.vue';
import GroupAttachmentAction from '@/ui/modules/task/components/GroupAttachmentAction.vue';
import commentTaskComposable from '@/ui/composables/task/comment-task';
import AtomSwitch from '@/ui/common/atoms/switchs/AtomSwitch.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import permissionStore from '@/store/permission';
import myProfileStore from '@/store/auth/my-profile';
import ManageCollaborator from '@/ui/modules/task/detail/assign/ManageCollaborator.vue';
import TaskTodoFlatList from '@/ui/modules/task/components/check-list/TaskTodoFlatList.vue';
import SetPinTask from '@/ui/modules/task/components/pin/SetPinTask.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import {
  EFilterTaskDefault,
  TASK_PIN_BY_CODE,
} from '@/application/constants/task.const';
import TaskSharingModal from '@/ui/modules/task/sharing/modal-task-sharing/TaskSharingModal.vue';
import TaskSnapshotsModal from '@/ui/modules/task/sharing/modal-task-shapshots/TaskSnapshotsModal.vue';
import SetPrivateTask from '@/ui/modules/task/detail/action-on-task/SetPrivateTask.vue';
import ActionOnTask from '@/ui/modules/task/detail/action-on-task/ActionOnTask.vue';
import {
  EActionOnTask,
  ETaskListModule,
  TaskApprovalStatus,
  ETaskFileStatus,
} from '@/application/types/task/task.types';
import TaskWorkflowCurrentStep from '@/ui/modules/task/workflow/TaskWorkflowCurrentStep.vue';
// import { newTaskDefaultPayload } from '@/ui/modules/task/task-global-state';
import taskCreateFormStore from '@/store/task/create-form';
import {
  isOpenCreateTaskDrawer,
  isOpenTaskDrawer,
  isShowTaskDetailGlobal,
  taskCodeGlobal,
} from '@/ui/modules/task/task-global-state';
import {
  IAttachmentFileDetail,
  uploadFilesToAws,
} from '@/application/services/attachment/AttachmentClass';
import {
  IDrawNoteDetail,
  uploadNotesToAws,
} from '@/application/services/attachment/NoteClass';
import { ModuleSourceFileEnum } from '@/ui/plugins/awss3/AwsS3Type';
import UpdateDeadlineTime from '@/ui/modules/task/detail/UpdateDeadlineTime.vue';
import { GET_TASK_DETAIL_ERROR_CODE } from '@/application/repositories/TaskRepository';
import TaskSetApprovalModal from '@/ui/modules/task/approval/TaskSetApprovalModal.vue';
import TaskApprovalInfo from '@/ui/modules/task/approval/TaskApprovalInfo.vue';
import TaskApprovalShortInfo from '@/ui/modules/task/approval/TaskApprovalShortInfo.vue';
import ReminderCreateModal from '@/ui/modules/calendar/reminder/ReminderCreateModal.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import datetimeComposable, {
  FormatType,
} from '@/ui/composables/common/datetime-composable';
import TaskReminderDropdown from '@/ui/modules/task/detail/task-reminder/TaskReminderDropdown.vue';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import { ILatestTaskActivity } from '@/ui/plugins/firebases/realtime-database/types/organization-dynamic-model.types';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import SubTaskList from '@/ui/modules/task/detail/sub-tasks/SubTaskList.vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import TaskDeadlineBlockingShortInfo from '@/ui/modules/task/deadline-blocking/TaskDeadlineBlockingShortInfo.vue';
import TaskDescriptionPreview from '@/ui/modules/task/detail/task-description/TaskDescriptionPreview.vue';
import TaskSettingEvidenceModal from '@/ui/modules/task/providing-evidence/TaskSettingEvidenceModal.vue';
import SearchTasksModal from '@/ui/modules/task/components/search/SearchTasksModal.vue';
import TaskChatAbout from '@/ui/modules/task/detail/task-chat-about/TaskChatAbout.vue';
import FilesDownload from '@/ui/modules/ged/file-actions/files-download/FilesDownload.vue';
import SetAttachmentStatus from '@/ui/modules/task/components/attachment/SetAttachmentStatus.vue';

dayjs.extend(utc);

const props = defineProps<{
  taskId?: string | number;
  taskCode?: string;
  isPlaceModal?: boolean;
  isModal?: boolean;
}>();

const emit = defineEmits([
  'onClose',
  'onOpenCreateNew',
  'closeModal',
  'onToggleChatModal',
  'goToTask',
]);

const taskDetailFileInputId = `task-detail_attachment_${props.taskId}`;
const _taskStore = taskStore();
const _taskListStore = taskListStore();
const _taskDetailStore = taskDetailStore();
const _taskDrawerStore = taskDrawerStore();
const _domainStore = domainStore();
const _remoteConfigStore = remoteConfigStore();
const _settingStore = settingStore();
const _taskCreateFormStore = taskCreateFormStore();
const currentTaskId = ref<any>(props.taskId);

const multipleAssigneeSetting = computed(
  () =>
    _settingStore._organizationSetting.taskManagement?.multipleAssigneeSetting
);

const chatAboutTaskRef = ref();
const isOpenTaskSharing = ref();
const isOpenTaskSnapshots = ref();
const isOpenReminderModal = ref();
const isEditReminder = ref(false);
const currentTask = ref({} as TaskDetailClass);

const myProfile = computed(() => myProfileStore().myProfile);
const myTimezone = computed(() => myProfileStore().timezone);
const iAmACollaborator = computed(() =>
  currentTask.value?.collaborators?.some(
    (user) => user?.id == myProfile.value?.id
  )
);

const webTaskUploadContentTypes = computed(
  () => _remoteConfigStore.webTaskUploadContentTypes
);
// Enable function
const isPersonalModel = computed<boolean>(
  () => permissionStore().organizationModel === 'PERSONAL'
);
const chatEnabled = computed(() => _settingStore.chatEnabled);
const totalAttachmentFiles = computed(
  () => currentTask.value?.attachmentCount + currentTask.value?.noteCount
);
const taskForClone = ref({} as TaskDetailClass);
const isLoadingInit = ref(false);
const errorCode = ref('');
const { updateDocumentTitle, resetToLastestTitle } = customDocumentTitle();

const commentAndTaskLogRef = ref<any>();
const taskDescriptionRef = ref<any>(null);
const myCommentValid = ref(true);
const {
  isInputComment,
  commentObjectCreate,
  attachmentsInDescription,
  onOpenComment,
  onSaveComment,
  onCancelComment,
  onFocus,
} = commentLogic(true, (value) => {
  taskDescriptionRef.value?.onInsertText(value);
});

const {
  isOpenNote,
  drawNoteData,
  uploadError,
  uploadingFiles,
  onFetchCreateNote,
  onFetchUpdateNote,
  onOpenCreateNote,
  onUploadFiles,
  onChooseFiles,
  isOpenRecording,
  onCreateAudioDone,
  onCreateMultiNotes,
} = updateAttachmentLogic(currentTask, taskDetailFileInputId);

const {
  currentTaskHistoryId,
  isOpenTaskHistory,
  onCloseHistoryTask,
  onOpenHistoryTask,
} = taskLogActivityComposable({
  taskId: currentTaskId.value,
  orgId: 0,
});

const { getAllComments } = commentTaskComposable(emit);

const { TypeActivity, listActivityOption } = detailTaskComposable({
  taskId: currentTaskId.value,
});
let ps;
onMounted(() => {
  ps = taskDetailRef.value
    ? new PerfectScrollbar(taskDetailRef.value, {
        useBothWheelAxes: false,
      })
    : null;

  isOpenCreateTaskDrawer.value = false;
});

onUpdated(() => {
  ps?.update();
  // ps?.eventElement(document?.getElementById('planToday'));
  // taskDetailRef.value.scrollTop = 0;
  // setTimeout(() => {
  //   const element = (document as any).querySelector('#planToday');
  //   element.scrollTop = 0;
  // });
});

onUnmounted(() => {
  resetToLastestTitle();
});

const isLoadingDataTask = ref({
  detail: false,
  files: false,
  comment: false,
  taskReminder: false,
});

// ACTIVITY
const activeTag = ref(TypeActivity.COMMENT);

const updateCurrentTask = async () => {
  const taskDetail = currentTaskId.value
    ? await _taskDrawerStore.getDetailById(
        parseInt(currentTaskId.value.toString())
      )
    : await _taskDetailStore.getDetailByCode(props.taskCode);

  if (
    taskDetail?.errorCode == GET_TASK_DETAIL_ERROR_CODE.HAS_NOT_PERMISSION &&
    currentTask.value?.workFlowTaskId &&
    backUpTaskId.value !== currentTaskId.value
  ) {
    currentTaskId.value = backUpTaskId.value;

    settingGlobalModal({
      type: 'notification',
      title: translate(
        'TASK_LABEL_YOU_ARE_NOT_PERMISSION_TO_VIEW_TASK_IN_THIS_STEP_TITLE'
      ),
      content:
        translate(
          'TASK_LABEL_YOU_ARE_NOT_PERMISSION_TO_VIEW_TASK_IN_THIS_STEP'
        ) || 'You are on another call',

      closeable: false,
      confirmLabel: translate('COMMON_LABEL_GOT_IT'),
      confirmable: true,
    });
    ask();

    initComponent();
    return;
  }

  if (
    workflowNextStepTaskId.value &&
    taskDetail?.assigneeId !== myProfile.value?.id
  ) {
    currentTaskId.value = backUpTaskId.value;
    workflowNextStepTaskId.value = null;
    initComponent();
    return;
  }

  if (taskDetail?.errorCode) {
    errorCode.value = taskDetail?.errorCode
      ? `COMMON_ERROR_BY_CODE_${taskDetail?.errorCode}`
      : 'COMMON_ERROR_BY_CODE_6403';

    isLoadingDataTask.value.detail = false;

    return;
  }
  errorCode.value = '';
  currentTask.value = new TaskDetailClass(taskDetail);

  return taskDetail;
};

const initAttachment = async () => {
  if (!currentTask.value || !currentTask.value?.getAttachmentData) return;
  isLoadingDataTask.value.files = true;
  await currentTask.value.getAttachmentData();
  isLoadingDataTask.value.files = false;
};
const initTodoList = async () => {
  if (!currentTask.value || !currentTask.value?.getTodoList) return;
  await currentTask.value?.getTodoList();
  taskTodoListAllRef.value?.initComponent();
};

const initTaskReminder = async () => {
  // isLoadingDataTask.value.taskReminder = true;
  // await _taskDetailStore.getReminderByTaskId(props.taskId);
  // isLoadingDataTask.value.taskReminder = false;

  await getReminderByTaskId();
  // if (!currentTask.value) return;
};
const initSubTasks = async () => {
  if (
    !currentTask.value ||
    !currentTask.value?.totalSubTask ||
    currentTask.value?.totalSubTask <= 0
  ) {
    loadingData.subTask = false;

    return;
  }

  await currentTask.value?.getSubTasks();

  updateSubTaskLatestTime.value = Date.now();
  loadingData.subTask = false;
};

const getReminderByTaskId = async () => {
  if (!currentTask.value || !currentTask.value?.getReminderByTaskId) return;
  isLoadingDataTask.value.taskReminder = true;
  await currentTask.value.getReminderByTaskId();
  // await _taskDetailStore.getReminderByTaskId(props.taskId);
  isLoadingDataTask.value.taskReminder = false;
  // if (!currentTask.value) return;
};

const loadingData = reactive<{
  workflow: boolean;
  approval: TaskApprovalStatus | undefined;
  subTask: boolean;
}>({
  workflow: false,
  approval: undefined,
  subTask: true,
});
const initTaskWorkflow = async () => {
  if (!currentTask.value?.workFlowTaskId) return;
  loadingData.workflow = true;
  await currentTask.value.getTaskWorkflow();
  loadingData.workflow = false;

  _taskDetailStore.updatedTaskWorkflowId = `${Date.now()}:${
    currentTask.value?.workFlowTaskId
  }`;
};

let { unsubscribeCallbacks, unsubscribeAll } = listenFirebaseRealtime();

const updateTaskRealtime = async (actionType) => {
  const res = await TaskService.getInstance().getDetailTaskIncognitoById(
    currentTaskId.value
  );
  const taskDetail = res['result'];

  if (`${taskDetail?.id}` !== `${currentTaskId.value}`) return;

  if (!taskDetail || !taskDetail?.id) {
    errorCode.value = 'COMMON_ERROR_BY_CODE_6403';
    return;
  }

  currentTask.value.updateProperties({
    ...taskDetail,
    subTasks: currentTask.value?.subTasks || [],
  });
};
const updateSubTaskLatestTime = ref<number>(0);
const updateSubTaskId = ref<number>(0);

const addTaskToSubTasks = async (_taskDetail) => {
  currentTask.value.totalSubTask = (currentTask.value.totalSubTask || 0) + 1;
  currentTask.value.subTasks = [
    ...(currentTask.value?.subTasks || []),
    _taskDetail,
  ];
  // updateSubTaskLatestTime.value = Date.now();
};
const updateTaskToSubTasks = async (data) => {
  const taskDetail = data?.taskDetail;
  switch (data?.action) {
    case TaskActionType.Finished:
      {
        const index = currentTask.value?.subTasks?.findIndex(
          (t) => t?.id == data?.taskDetail?.id
        );
        if (currentTask.value.subTasks && index > -1) {
          currentTask.value.subTasks[index] = {
            ...currentTask.value.subTasks[index],
            taskLife: TaskLife.Finished,
          };
        }
      }
      break;
    case TaskActionType.Delete:
    case TaskActionType.ParentTaskRemoved:
      currentTask.value.subTasks = currentTask.value?.subTasks?.filter(
        (t) => t?.id !== data?.taskDetail?.id
      );
      break;

    default:
      {
        const res = await TaskService.getInstance().getDetailTaskIncognitoById(
          taskDetail?.id
        );
        if (!res?.result) return;

        const _taskDetail = {
          ...res?.result,
          isViewable: true,
        };
        const index = currentTask.value?.subTasks?.findIndex(
          (t) => t?.id == taskDetail?.id
        );
        if (currentTask.value.subTasks && index > -1) {
          currentTask.value.subTasks[index] = {
            ...currentTask.value.subTasks[index],
            ..._taskDetail,
          };
        } else {
          addTaskToSubTasks(_taskDetail);
        }
      }
      break;
  }

  updateSubTaskId.value = taskDetail?.id;
};

const actionTime = ref<number>(Date.now());
const subscribeTaskChange = () => {
  unsubscribeAll();
  const LatestTaskActivityFirebaseModel = new OrganizationDynamicModel(
    `latestTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    LatestTaskActivityFirebaseModel.onChange((data: ILatestTaskActivity) => {
      if (isLoadingInit.value) return;
      const taskDetail = data?.taskDetail;
      switch (data?.action) {
        case TaskActionType.WorkflowStepComplete:
        case TaskActionType.ApprovalRequestApprove:
          if (
            currentTask.value?.taskWorkflow?.steps?.some(
              (step) => step?.taskId == data?.taskDetail?.id
            )
          ) {
            initTaskWorkflow();
          }

          break;

        default:
          break;
      }

      if (taskDetail?.id == currentTaskId.value) {
        if (actionTime.value > data?.actionTime) {
          actionTime.value = data?.actionTime;
          return;
        }

        actionTime.value = data?.actionTime;
        if (data?.action == TaskActionType.Delete) {
          if (backUpTaskId.value) {
            goToTask(backUpTaskId.value);
            return;
          }
          errorCode.value = 'COMMON_ERROR_BY_CODE_6403';
          return;
        }

        updateTaskRealtime(data?.action);
      } else {
        if (
          taskDetail?.parentId &&
          currentTaskId.value &&
          taskDetail?.parentId == currentTaskId.value
        ) {
          updateTaskToSubTasks(data);
        }
      }
    })
  );
};

const initComponent = async () => {
  try {
    isLoadingInit.value = true;
    loadingData.subTask = true;
    isLoadingDataTask.value.detail = true;
    activeTag.value = TypeActivity.COMMENT;
    errorCode.value = '';
    onCloseEditName();
    onCancelComment();
    clearCurrentTaskHistoryId();
    if (ps) ps.update();

    if (!currentTaskId.value && !props.taskCode) return;

    subscribeTaskChange();

    const taskDetail = await updateCurrentTask();

    if (!taskDetail?.totalComment && taskDetail?.taskTodoTotal > 0) {
      activeTag.value = TypeActivity.TODO_LIST;
    }

    isLoadingDataTask.value.detail = false;
    isLoadingInit.value = false;

    // await Promise.all([
    //   initAttachment(),
    //   initTodoList(),
    //   initTaskWorkflow(),
    //   initTaskReminder(),
    // ]);

    // init attachment
    initAttachment();
    // Update todo list
    initTodoList();
    // Update Workflow
    initTaskWorkflow();
    // initTaskReminder
    initTaskReminder();
    // Sub tasks
    initSubTasks();

    if (_domainStore.allDomains.length == 0) {
      _domainStore.fetchAllDomainProjects();
    }
    handleChangeDocumentTitle();

    handleLoadCacheState(taskDetail?.id);
  } catch (error: any) {
    console.log('🚀 error', error);
    const code = error?.error?.code;
    isLoadingDataTask.value.detail = false;
    isLoadingDataTask.value.files = false;
    errorCode.value = code
      ? `COMMON_ERROR_BY_CODE_${code}`
      : 'COMMON_ERROR_BY_CODE_6403';
  } finally {
    isLoadingDataTask.value.detail = false;
    isLoadingDataTask.value.files = false;
    isLoadingInit.value = false;
  }
};

const handleLoadCacheState = (taskId) => {
  const currentTaskCacheState = _taskDetailStore.cacheStateById[taskId];
  if (currentTaskCacheState?.description?.isEdit) {
    onOpenComment(
      currentTaskCacheState?.description?.draft,
      currentTaskCacheState?.description?.attachments,
      true
    );
  }
};

const handleChangeDocumentTitle = () => {
  if (!currentTask.value || !currentTask.value?.id) {
    updateDocumentTitle(
      `${translate('COMMON_LABEL_NOT_FOUND')} | Task | TicTop`
    );
    return;
  }

  const domainDetail = _domainStore.getDomainDetailById(
    currentTask.value?.domainId
  );

  let keyInfo = `${formatTaskCode(currentTask.value?.code)} - ${
    domainDetail?.name
  }`;
  keyInfo = keyInfo.length > 20 ? `${keyInfo.substring(0, 16)}...` : keyInfo;

  let nameInfo = `[${keyInfo}] ${currentTask.value?.name}`;

  nameInfo =
    nameInfo.length > 55 ? `${nameInfo.substring(0, 56)}...` : nameInfo;

  updateDocumentTitle(`${nameInfo} | Task | TicTop`);
};

watch(
  () => props.taskId,
  (newId) => {
    currentTaskId.value = newId;
    initComponent();
  }
);

watch(
  () => props.taskCode,
  () => {
    currentTaskId.value = undefined;
    initComponent();
  }
);

watch(
  () => _taskDrawerStore.lastUpdateTaskReminderTime[currentTask.value?.id],
  () => {
    getReminderByTaskId();
  }
);
// Assign
const isOpenChangeAssign = ref(false);

const onOpenChangeAssign = () => {
  if (!isEditable.value) return;
  isOpenChangeAssign.value = true;
};

const onCancelAssign = () => {
  if (isOpenUpdateDomainModal.value) return;
  isOpenChangeAssign.value = false;
};

const changeAssignPayload = ref({} as any);
const fixConflictDomain = ref({
  domain: false,
  project: false,
  subProject: false,
} as any);

const onChangeAssign = async (
  group,
  user,
  { domain, project, subProject },
  collaborators
) => {
  const userId = user?.id || user?.userId;
  if (!group?.id && !userId) return;

  if (currentTask.value?.approvalEnabled) {
    const isValid = await currentTask.value.checkNewResponsibleWithApprover(
      group,
      user
    );
    if (!isValid) return;
  }

  changeAssignPayload.value = {
    groupId: group?.id,
    assigneeId: userId,
    collaborators: collaborators
      ? collaborators
      : currentTask.value?.collaborators,
  };
  fixConflictDomain.value = { domain, project, subProject };
  if (domain || project || subProject) {
    onOpenUpdateDomainModal();
    return;
  }
  onChangeAssignee();
};

const onChangeCollaborator = async (collaborators) => {
  const payload = collaborators?.map((user) => {
    return {
      ...user,
      memberUserId: user?.id || user?.userId,
    };
  });
  await onChangedTaskDetail(currentTask.value.addCollaboratorsInTask(payload));
};
const onRemoveCollaborator = async (userId) => {
  onChangedTaskDetail(currentTask.value.deleteCollaboratorsInTask([userId]));
};
// const synchronizeTaskToWorkflowCurrentStep = async () => {
//   if (!currentTask.value?.taskWorkflow?.id) return;
//   await currentTask.value.synchronizeTaskToWorkflowCurrentStep();
//   loadingData.workflow = false;
// };
const onChangeAssignee = async () => {
  const isOnlyChangeUser =
    changeAssignPayload.value?.groupId == currentTask.value?.groupId &&
    changeAssignPayload.value?.assigneeId !== currentTask.value?.assigneeId;

  if (currentTask.value?.isPrivate) {
    const isValid =
      await currentTask.value.checkValidChangeResponsibleInPrivateTask(
        changeAssignPayload.value?.groupId,
        changeAssignPayload.value?.assigneeId
      );
    if (!isValid) {
      onCancelAssign();
      return;
    }
  }
  const promiseAll: any[] = [
    currentTask.value.changeAssignee(
      changeAssignPayload.value?.groupId,
      changeAssignPayload.value?.assigneeId,
      changeAssignPayload.value?.collaborators
    ),
  ];
  if (
    !changeAssignPayload.value?.assigneeId &&
    currentTask.value.taskSchedule.doItToday
  ) {
    promiseAll.push(currentTask.value.doItToday(false));
  }
  onChangedTaskDetail(Promise.all(promiseAll));

  if (isOnlyChangeUser) onCancelAssign();
};

const allFiles = computed(() => currentTask.value?.attachments);
//const taskReminder = computed(() => _taskDetailStore?.taskReminder);
const fileShowList = computed<any[]>(() => {
  return arrayOrderBy(
    currentTask.value?.attachments,
    ['creationTime'],
    ['asc']
  );
});

const currentFileIndex = ref(0);
const isPreview = ref(false);

const allAttachments = computed<any[]>(() => {
  return arrayOrderBy(
    [
      ...currentTask.value?.attachments,
      ...(currentTask.value?.description
        ? [
            {
              id: `TASK_ATTACHMENT_DETAIL__DESCRIPTION`,
              type: 'text',
              data: currentTask.value?.description,
            },
          ]
        : []),
      ...(currentTask.value.taskTodoTotal > 0
        ? [
            {
              id: `TASK_ATTACHMENT_DETAIL__TODO_LIST`,
              type: 'todo_list',
              data: '',
            },
          ]
        : []),
      {
        id: `TASK_ATTACHMENT_DETAIL__COMMENT`,
        type: 'comment',
        data: '',
      },
    ],
    ['creationTime'],
    ['asc']
  );
});

const onOpenFiles = (file) => {
  currentFileIndex.value = allFiles.value.findIndex((o) => o.id == file.id);
  isPreview.value = true;
};

// notes
const filePreview = ref({});
const isOpenMobileNote = ref(false);

const onRemoveNote = async (note, isNeedConfirm) => {
  await currentTask.value.removeNote(note, isNeedConfirm);
  getDataLogTaskActivity();
  if (allFiles.value?.length == 0) isPreview.value = false;
};

const onRemoveAttachment = (file, isNeedConfirm = true) => {
  if (file.isNote) {
    return onRemoveNote(file, isNeedConfirm);
  } else {
    return onRemoveFile(file);
  }
};

const onRemoveFile = (file) => {
  onChangedTaskDetail(currentTask.value.removeFile(file));
};

const closePreview = () => {
  isOpenMobileNote.value = false;
};

const isGroupMember = computed<boolean>(
  () => currentTask.value?.isGroupMember || false
);
const isGroupTask = computed(
  () => currentTask.value.groupId && currentTask.value.groupId > 0
);
const hasPermission = computed<boolean>(() => {
  if (
    currentTask.value?.creatorId == myProfile.value?.id ||
    currentTask.value?.assigneeId == myProfile.value?.id
  ) {
    return true;
  }

  return isGroupTask.value ? isGroupMember.value : true;
});
const isEditable = computed<boolean>(() => {
  if (
    currentTask.value?.workFlowTaskId &&
    currentTask.value?.taskLife == TaskLife.Finished
  ) {
    return false;
  }
  if (
    currentTask.value?.approvalEnabled &&
    (currentTask.value?.approvalStatus == TaskApprovalStatus.WAITING ||
      currentTask.value?.approvalStatus == TaskApprovalStatus.APPROVED)
  ) {
    return false;
  }

  if (currentTask.value?.isCreateListAssignees) {
    return (
      currentTask.value?.creatorId == myProfile.value?.id ||
      currentTask.value?.assigneeId == myProfile.value?.id
    );
  }

  return hasPermission.value;
});
const isNotGroupMember = computed<boolean>(() => {
  return isGroupTask.value && !isGroupMember.value;
});

const onDoItToday = async (value) => {
  onChangedTaskDetail(currentTask.value.doItToday(value));

  // close other component
  onCancelAssign();
};

const updateDeadlineRef = ref<any>(null);
const onFinishItToday = async (value) => {
  if (
    value &&
    updateDeadlineRef.value?.updateScheduleTime &&
    typeof updateDeadlineRef.value?.updateScheduleTime == 'function'
  ) {
    updateDeadlineRef.value?.updateScheduleTime(ScheduleDayOptions.OnDay);
  }

  onChangedTaskDetail(currentTask.value.finishItToday(value));

  // onUpdateDeadline({
  //   otherTimeKey: null,
  //   scheduleOptionKey: value
  //     ? ScheduleDayOptions.OnDay
  //     : ScheduleDayOptions.Nothing,
  //   scheduleTime: null,
  // });

  // close other component
  onCancelAssign();
};

const isUpdatingTask = ref(false);
const edittingName = ref('');
const taskLogActivityRef = ref();
const isLoadingWhenUpdateNote = ref(false);

const onChangedTaskDetail = async (changeMethod: Promise<any>) => {
  isUpdatingTask.value = true;
  loadingData.workflow = true;

  await changeMethod;

  loadingData.workflow = false;

  // synchronizeTaskToWorkflowCurrentStep();

  _taskStore.updateAllTaskByIds(currentTask.value?.id, currentTask.value);
  isUpdatingTask.value = false;
  getDataLogTaskActivity();

  if (currentTask.value?.workFlowTaskId) {
    _taskDetailStore.updatedTaskWorkflowId = `${Date.now()}:${
      currentTask.value?.workFlowTaskId
    }`;
  }

  return true;
};

const getDataLogTaskActivity = () => {
  if (isOpenTaskHistory) {
    setTimeout(async () => {
      await taskLogActivityRef.value?.initData();
    }, 200);
  }
};

const onRenameTask = async () => {
  isEdittingName.value = false;

  if (!edittingName.value) return;
  await onChangedTaskDetail(currentTask.value.rename(edittingName.value));
  _taskDrawerStore.updateDataByTabId(currentTask.value?.id, currentTask.value);
};

const onOpenEditName = () => {
  edittingName.value = currentTask.value?.name;
  isEdittingName.value = true;
  isInputComment.value = false;
  onCancelComment();
  onCancelAssign();
};

const onCloseEditName = () => {
  isEdittingName.value = false;
};

const onChangeUrgency = (urgency) => {
  onChangedTaskDetail(currentTask.value.changeUrgency(urgency));
};

const onSaveCacheForm = (localId, data) => {
  if (!localId) {
    return;
  }

  _taskCreateFormStore.setCachedFormData(localId, {
    ...data,
    taskTodos: data?.todoList,
  });
  _taskDrawerStore.onUpdateCache();
};

const onCloneTask = async () => {
  // taskForClone.value = new TaskDetailClass(currentTask.value);

  const localId = Date.now();

  let cloneCurrentTask = {
    ...currentTask.value,
    id: undefined,
    creatorId: getCurrentUserId(),
    groupId: isNotGroupMember.value ? null : currentTask.value?.groupId,
    taskLife:
      currentTask.value?.taskLife == TaskLife.Finished
        ? TaskLife.Todo
        : currentTask.value?.taskLife,
    workflowId: undefined,
    taskWorkflow: undefined,
    isRepeat: false,
    isClone: true,
  };

  if (cloneCurrentTask?.subTasks?.length > 0) {
    cloneCurrentTask.subTasks = cloneCurrentTask?.subTasks?.map((st) => {
      return {
        ...st,
        taskLife: TaskLife.Finished,
      };
    });
  }

  const currentAllAttachments = allAttachments.value?.filter(
    (attachment: any) =>
      attachment?.type !== 'text' && attachment?.type !== 'comment'
  );

  if (currentAllAttachments?.length > 0) {
    const currentTime = Date.now();
    currentAllAttachments.forEach((attachment, index) => {
      if (attachment?.isNote) {
        const indexNote = cloneCurrentTask?.notes?.findIndex(
          (note) => note?.id == attachment?.id
        );
        if (indexNote > -1) {
          console.log('🚀 Tictop ~ indexNote:', indexNote);
          cloneCurrentTask.notes[indexNote].creationTime = new Date(
            currentTime + index
          );
        }
      } else {
        const indexFile = cloneCurrentTask?.files?.findIndex(
          (file) => file?.id == attachment?.id
        );
        if (indexFile > -1) {
          cloneCurrentTask.files[indexFile].creationTime = new Date(
            currentTime + index
          );
        }
      }
    });
  }

  if (cloneCurrentTask?.files?.length > 0) {
    cloneCurrentTask.files = await uploadFilesToAws(
      ModuleSourceFileEnum.task,
      cloneCurrentTask?.files?.map((file) => {
        const newId = uuidv4();
        return {
          ...file,
          id: newId,
          localId: newId,
          name: file?.name,
          type: file?.type,
          fileUrl: file?.fileUrl,
          creationTime: file?.creationTime,
          blob: file,
          size: file.size,
          editStatus: 'ORIGINAL',
        } as IAttachmentFileDetail;
      }),
      {
        isClone: true,
      }
    );
  }
  if (cloneCurrentTask?.notes?.length > 0) {
    cloneCurrentTask.notes = await uploadNotesToAws(
      cloneCurrentTask?.notes?.map((file) => {
        const newId = uuidv4();
        return {
          ...file,
          id: newId,
          localId: newId,
          name: file?.name,
          type: file?.type,
          fileUrl: file?.fileUrl,
          creationTime: file?.creationTime,
          blob: file,
          size: file.size,
          editStatus: 'ORIGINAL',
        } as IDrawNoteDetail;
      }),
      {
        isClone: true,
      }
    );
  }

  onSaveCacheForm(localId, cloneCurrentTask);

  _taskDrawerStore.pushCurrentIds(
    {
      id: `${localId}`,
      code: 'NEW_TASK',
      name: currentTask.value?.name,
      tabType: 'NEW',
    },
    false
  );
  _taskDrawerStore.updateCurrentTabId(localId);
  if (props.isModal) {
    emit('closeModal');
    isOpenTaskDrawer.value = !isOpenTaskDrawer.value;
  }
  // emit('onOpenCreateNew');
};

const onSaveCloneTask = async (group, assignee) => {
  isUpdatingTask.value = true;
  taskForClone.value.groupId = group.id;
  taskForClone.value.assigneeId = assignee.userId;

  await taskForClone.value.clone();
  taskForClone.value = new TaskDetailClass({});

  isUpdatingTask.value = false;
  // _taskDrawerStore.onCloseTab(currentTask.value);
};

const onDeleteTask = async () => {
  const success = await currentTask.value.remove();
  if (!success) return;

  _taskDrawerStore.onCloseTab(currentTask.value?.id);
  emit('onClose');
  openNotification({
    body: translate('COMMON_LABEL_DELETED'),
    duration: 2000,
  });
};

const onCopyLink = () => {
  const taskCode = currentTask.value?.code;
  if (!taskCode) return;
  const url = getDetailTaskUrl(taskCode, true);
  const copied = copyToClipBroard(url);
  if (copied) {
    openNotification({
      body: translate('TASK_DETAIL_ACTION_COPIED_LINK'),
      duration: 2000,
    });
  }
};
const onCopyCode = () => {
  const taskCode = formatTaskCode(currentTask.value?.code);
  if (!taskCode) return;
  const copied = copyToClipBroard(`#${taskCode}`);
  if (copied) {
    openNotification({
      body: translate('TASK_DETAIL_ACTION_COPIED_CODE'),
      duration: 2000,
    });
  }
};

// Description
const onChangeDescription = (newDes) => {
  let newDescriptionData;
  let attachmentsInDescription;
  onSaveComment((data, attachments) => {
    newDescriptionData = data;
    attachmentsInDescription = attachments;
  });

  if (typeof newDes == 'string') newDescriptionData.content = newDes;

  onChangedTaskDetail(
    currentTask.value.changeDescription(newDescriptionData?.content, {
      ...newDescriptionData,
    })
  );

  onUploadFiles(attachmentsInDescription?.filter((file) => !file.isNote));
  onCreateMultiNotes(attachmentsInDescription?.filter((file) => file.isNote));
  onResetCacheStateDescription();
};

const onOpenEditDescription = () => {
  onOpenComment(currentTask.value?.description);

  onCloseEditName();
  onCancelAssign();
};

const onCloseEditDescription = () => {
  onCancelComment();
  onResetCacheStateDescription();
};

const clearCurrentTaskHistoryId = () => {
  onCloseHistoryTask();
};

const isOpenUpdateDeadline = ref(false);
// const isOpenOtherTimeOption = ref(false);
const scheduleRef = ref(null as any);

const onSaveDeadline = async (deadlineDate) => {
  console.log('🚀 Tictop ~ isOpenChangeDeadline:', isOpenChangeDeadline.value);
  scheduleRef.value?.onForceClose();
  await onChangedTaskDetail(currentTask.value.changeDeadline(deadlineDate));

  if (updateDeadlineRef.value?.initComponent) {
    updateDeadlineRef.value?.initComponent();
  }
  // updateCurrentTask();
};

const onUpdateScheduleDate = async (newScheduleDate) => {
  currentTask.value.scheduleTime = getScheduleTimeAfterChangeDate(
    currentTask.value.scheduleTime,
    newScheduleDate,
    currentTask.value.scheduleByHour,
    currentTask.value.scheduleTimezone
  );
};
const changeDeadlineTime = async (data: {
  dateTime: string;
  timezone: string;
}) => {
  scheduleRef.value?.onForceClose();
  await onChangedTaskDetail(
    currentTask.value.changeDeadlineTime({
      scheduleTime: data?.dateTime,
      scheduleByHour: true,
      scheduleTimezone: data?.timezone,
    })
  );
};

const onRemoveScheduleTime = async ({ dateTime }) => {
  await onChangedTaskDetail(
    currentTask.value.changeDeadlineTime({
      scheduleTime: dateTime,
      scheduleByHour: false,
      scheduleTimezone: '',
    })
  );
};

// const onCancelDeadline = () => {
//   isOpenUpdateDeadline.value = false;
//
//   scheduleRef.value?.onForceClose();
// };

const onOpenEditDomain = () => {
  onCancelAssign();
};

const onSaveDomain = (domainData) => {
  onChangedTaskDetail(currentTask.value.changeDomain(domainData));
};

const onSavePrivate = (value) => {
  onChangedTaskDetail(currentTask.value.changePrivate(value));
};

const isCollaborate = computed(
  () =>
    currentTask.value?.assigneeId == getCurrentUserId() ||
    currentTask.value?.creatorId == getCurrentUserId()
);

const isChangeState = ref({
  isLoading: false,
  state: currentTask.value.taskLife,
});

const taskWorkflowBreadcrumbRef = ref<any>(null);
const onChangeTaskState = async (state: TaskLife, comment: any) => {
  if (state == currentTask.value.taskLife || isChangeState.value.isLoading) {
    return;
  }

  isChangeState.value.isLoading = true;
  isChangeState.value.state = state;
  await onChangedTaskDetail(currentTask.value.changeState(state, comment));

  isChangeState.value.isLoading = false;

  if (currentTask.value?.workFlowTaskId) {
    // updateTaskFromWorkflowCurrentStep({});
    if (taskWorkflowBreadcrumbRef.value?.initComponent) {
      taskWorkflowBreadcrumbRef.value?.initComponent();
    }
    setTimeout(() => {
      if (taskWorkflowBreadcrumbRef.value?.goToCurrentStep) {
        taskWorkflowBreadcrumbRef.value?.goToCurrentStep();
      }
    });
  }
};
const onRequestApproval = async (nextStepId: string | undefined) => {
  isChangeState.value.isLoading = true;
  isChangeState.value.state = TaskLife.Finished;

  await onChangedTaskDetail(
    currentTask.value.changeApprovalStatus(
      TaskApprovalStatus.WAITING,
      {
        isCreateComment: false,
        content: '',
        attachments: {
          files: [],
          notes: [],
        },
        mentionUserIds: [],
      },
      nextStepId
    )
  );

  isChangeState.value.isLoading = false;
};

const onCheckPermission = async () => {
  if (isEditable.value) return true;
  settingGlobalModal({
    type: 'notification',
    title: '',
    content: translate('COMMON_LABEL_YOU_HAVE_NO_PERMISSION'),
    confirmable: true,
  });
  await ask();
};

const taskDetailBodyRef = ref<any>(null);

const onPasteFiles = async (event) => {
  if (
    event?.target &&
    ((document.getElementById('taskComment') &&
      document.getElementById('taskComment')?.contains(event?.target)) ||
      (document.getElementById('task-detail-comment-bottom') &&
        document
          .getElementById('task-detail-comment-bottom')
          ?.contains(event?.target)))
  )
    return;

  const _hasPermission = await onCheckPermission();
  if (!_hasPermission) return;

  if (isPreview.value) {
    return;
  }
  var files = (event.clipboardData || event.originalEvent.clipboardData).files;

  if (!files || files?.length == 0) return;

  // 1. if image file => open note
  if (files?.length == 1 && files[0].type.includes('image')) {
    onOpenCreateNote(null, files);

    return;
  }

  // 2. If not image file => attachment
  await onUploadFiles(files);
  getDataLogTaskActivity();
};

const isDraging = ref(false);

const dragOver = (value) => {
  isDraging.value = value;
};

const onDropImage = async (e) => {
  isDraging.value = false;
  const _hasPermission = await onCheckPermission();
  if (!_hasPermission) return;
  const files = e.dataTransfer.files;
  if (!files || files?.length == 0) return;

  await onUploadFiles(files);
  getDataLogTaskActivity();
};

const getConfigScheduleName = (task) => {
  return _taskStore.getConfigScheduleName(task);
};

const modalMultipleFileViewerRef = ref(null as any);

const onOpenCommentModal = () => {
  modalMultipleFileViewerRef.value?.onOpenEditDescription();
};

const isOpenUpdateDomainModal = ref(false);

const onOpenUpdateDomainModal = () => {
  isOpenUpdateDomainModal.value = true;
  onCancelAssign();
};

const onCloseUpdateDomainModal = () => {
  isOpenUpdateDomainModal.value = false;
  onCancelAssign();
};

const onSaveDomainAfterChangeGroup = async (domainData) => {
  const isOnlyChangeUser =
    changeAssignPayload.value?.groupId == currentTask.value?.groupId &&
    changeAssignPayload.value?.assigneeId !== currentTask.value?.assigneeId;

  const promiseAll = [
    currentTask.value.changeDomain(domainData),
    currentTask.value.changeAssignee(
      changeAssignPayload.value?.groupId,
      changeAssignPayload.value?.assigneeId
    ),
  ];

  if (
    !changeAssignPayload.value?.assigneeId &&
    currentTask.value?.taskSchedule.doItToday
  ) {
    promiseAll.push(currentTask.value.doItToday(false));
  }

  await onChangedTaskDetail(Promise.all(promiseAll));

  changeAssignPayload.value = {};
  isOpenUpdateDomainModal.value = false;

  if (isOnlyChangeUser) onCloseUpdateDomainModal();
};

const isHasJustCreatedFile = (file) => {
  const createTime = new Date(file?.creationTime).getTime();
  const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const overTime = Date.now() + timeOffset - createTime;
  return overTime < 15 * 60 * 1000;
};

const onSaveCreateNote = async (noteData) => {
  await onFetchCreateNote(noteData);
  getDataLogTaskActivity();
};

const onSaveUpdateNote = async (noteData) => {
  isLoadingWhenUpdateNote.value = true;
  await onFetchUpdateNote(noteData);
  getAllComments(0, currentTask.value?.id);
  getDataLogTaskActivity();
  isLoadingWhenUpdateNote.value = false;
};

const onSaveRecorder = async (recordings) => {
  await onCreateAudioDone(recordings);
  getDataLogTaskActivity();
};

const onChooseTaskActivityOption = (key) => {
  activeTag.value = key;
  switch (key) {
    case TypeActivity.TASKLOG:
      onOpenHistoryTask(currentTask.value?.id || currentTaskId.value);
      break;
    case TypeActivity.COMMENT:
      break;
    default:
      break;
  }
};

// const onLoadingElement = () => {
//   isLoadingInit.value = true;
//   setTimeout(() => {
//     isLoadingInit.value = false;
//   }, 100);
// };

const taskDetailRef = ref(null as any);

const onMountedTaskComment = () => {
  if (!isNeedUpdateCommentScroll.value) return;

  isNeedUpdateCommentScroll.value = false;
  onScrollToBottom();
};
const onScrollToBottom = () => {
  setTimeout(() => {
    if (!taskDetailRef.value) return;
    taskDetailRef.value.scrollTop = taskDetailRef.value?.scrollHeight;
  }, 0);
};

const isNeedUpdateCommentScroll = ref<boolean>(false);

const onSaveCommentSuccess = (isNeedUpdateAttachments) => {
  isNeedUpdateCommentScroll.value = true;
  onChooseTaskActivityOption(TypeActivity.COMMENT);
  currentTask.value.totalComment += 1;
  setTimeout(() => {
    if (!taskDetailRef.value) return;
    taskDetailRef.value.scrollTop = taskDetailRef.value?.scrollHeight;
  }, 0);
  if (isNeedUpdateAttachments) currentTask.value?.getAttachmentData();
  // onResetCacheStateComment();
};

const onCommentScrollTo = (position) => {
  if (!position || !taskDetailRef.value) return;

  taskDetailRef.value.scrollTop =
    commentAndTaskLogRef.value?.offsetTop + position;
};
const onDeleteSuccess = () => {
  currentTask.value.totalComment -= 1;
};

const onSaveRepeats = async (data: ITaskReccurring) => {
  if (
    !currentTask.value.taskRepeat ||
    !currentTask.value.taskRepeat?.templateId
  ) {
    await currentTask.value?.createTaskRepeatInfo(data);
  } else {
    await currentTask.value?.updateTaskRepeatInfo(data);
  }

  isOpenSetRepeats.value = false;
};
const isOpenSetRepeats = ref(false);
const onOpenSetRepeats = async (isUpdateTemplate) => {
  if (!isEditable.value) return;

  isUpdateRepeatTemplate.value = isUpdateTemplate;
  const ressponse = await TaskService.getInstance().getRepeatDetail(
    currentTask.value?.id
  );

  currentTask.value.taskRepeat = ressponse?.result;
  isOpenSetRepeats.value = true;
};
const isOpenWorkFlow = ref(false);
const onOpenWorkFlow = async () => {
  if (
    currentTask.value?.workFlowTaskId &&
    typeof taskWorkflowBreadcrumbRef.value?.onOpenDetailModal == 'function'
  ) {
    taskWorkflowBreadcrumbRef.value?.onOpenDetailModal();
    return;
  }

  isOpenWorkFlow.value = true;
};

const onReminderTask = async (type) => {
  if (type == 'CREATE') {
    isOpenReminderModal.value = true;
    isEditReminder.value = false;
  } else {
    isOpenReminderModal.value = true;
    isEditReminder.value = true;
  }
};

const removeReminder = async () => {
  await _taskStore.removeReminderByTaskId(currentTask.value?.id);
  await getReminderByTaskId();
  // await initTaskReminder();
};

const updateTaskFromWorkflowCurrentStep = async () => {
  await initComponent();
};

const onSaveWorkflow = async (taskWorkflow: any) => {
  isOpenWorkFlow.value = false;
  if (taskWorkflow?.id) currentTask.value.workFlowTaskId = taskWorkflow?.id;

  if (currentTask.value?.workFlowTaskId) {
    updateTaskFromWorkflowCurrentStep();
    await initTaskWorkflow();
    setTimeout(() => {
      if (taskWorkflowBreadcrumbRef.value?.initComponent) {
        taskWorkflowBreadcrumbRef.value?.initComponent();
      }
    });
  }
};
const onReload = () => {
  initComponent();
};
const onCloseSnapshot = () => {
  isOpenTaskSnapshots.value = false;
  initComponent();
};

const onClickToBody = () => {
  onCancelAssign();
  onCloseEditCollaborator();
};

const onUpdateCacheStateDescription = (textValue, attachments) => {
  _taskDetailStore.setCacheState(currentTask.value?.id, {
    description: {
      isEdit: true,
      draft: textValue,
      attachments,
    },
  });
};

const onResetCacheStateDescription = () => {
  _taskDetailStore.setCacheState(currentTask.value?.id, {
    description: {
      isEdit: false,
      draft: '',
      attachments: [],
    },
  });
};

// const onUpdateCacheStateComment = (value) => {
//   _taskDetailStore.setCacheState(currentTask.value?.id, {
//     comment: {
//       draft: value,
//     },
//   });
// };
//
// const onResetCacheStateComment = () => {
//   _taskDetailStore.setCacheState(currentTask.value?.id, {
//     comment: {
//       draft: '',
//     },
//   });
// };

const isUpdateRepeatTemplate = ref<boolean>(false);

const toggleFollow = () => {
  if (isUpdatingTask.value) return;

  if (iAmACollaborator.value) {
    onRemoveCollaborator(myProfile.value?.id);
  } else {
    onChangeCollaborator([myProfile.value]);
  }
};

const isEditCollaborator = ref<boolean>(false);
const manageCollaboratorRef = ref<any>(null);

const onCloseEditCollaborator = () => {
  manageCollaboratorRef.value?.onCloseEditMode();
};

const taskTodoListAllRef = ref<any>();
const onAddTodoList = () => {
  onChooseTaskActivityOption(TypeActivity.TODO_LIST);

  setTimeout(() => {
    taskTodoListAllRef.value?.onClickNewTab();
  });
};

const isDraggingTodoList = ref<boolean>(false);

const taskCommentBottomRef = ref<any>();
const onAddNewComment = () => {
  if (taskCommentBottomRef.value?.focusOnInput) {
    taskCommentBottomRef.value?.focusOnInput();
  }
};

const updateTaskListAfterPin = (action: TaskActionType) => {
  _taskListStore.getTaskSummaryStatistics(
    ETaskListModule.USER,
    getCurrentUserId()
  );
  const currentTabInTaskList = taskTabViewStore().currentTabId;
  if (currentTabInTaskList === EFilterTaskDefault.PINNED) {
    _taskListStore.updateListTaskById(currentTask.value?.id, action);
  }
};

const onChangePin = async (pin) => {
  await currentTask.value.changePin(pin);
  updateTaskListAfterPin(TaskActionType.MoveIn);
};
const onUnpin = async () => {
  await currentTask.value.unpin();
  updateTaskListAfterPin(TaskActionType.MoveOut);
};
const onToggleLockDeadline = (value?) => {
  currentTask.value.toggleLockDeadline(value);
};

const detachWorkflowSuccess = () => {
  currentTask.value.workFlowTaskId = undefined;
  currentTask.value.taskWorkflow = undefined;
};

const onUpdateChecklist = (data) => {
  currentTask.value.todoList = data;

  // currentTask.value.synchronizeTaskToWorkflowCurrentStep();
};

const backUpTaskId = ref<number>(0);
const onChooseStep = async (step) => {
  if (!step?.taskId) return;
  goToTask(step?.taskId);
};

const goToTask = (taskId) => {
  backUpTaskId.value = currentTaskId.value;
  currentTaskId.value = taskId;

  initComponent();
};

const isOpenApprovalModal = ref<boolean>(false);
const onOpenApproval = async () => {
  // const isValid = await currentTask.value.checkBeforeMaskAsApproval();
  // if (!isValid) return;
  isOpenApprovalModal.value = true;
};

const workflowNextStepTaskId = ref<number | null>(null);
const onChangeApprovalStatus = async (
  approvalStatus: TaskApprovalStatus,
  comment: any
) => {
  isChangeState.value.isLoading = true;
  loadingData.approval = approvalStatus;

  await onChangedTaskDetail(
    (async function () {
      workflowNextStepTaskId.value =
        await currentTask.value.changeApprovalStatus(
          approvalStatus,
          comment,
          undefined
        );

      goToTask(workflowNextStepTaskId.value);
      return Promise.resolve(true);
    })()
  );

  isChangeState.value.isLoading = false;
  loadingData.approval = undefined;
};

const onSetApprover = async (approverIds) => {
  isOpenApprovalModal.value = false;
  isChangeState.value.isLoading = true;
  await onChangedTaskDetail(
    currentTask.value.setTaskApproval({
      approvalEnabled: true,
      approverIds,
    })
  );

  isChangeState.value.isLoading = false;
};

const unMaskAsApproval = async () => {
  isChangeState.value.isLoading = true;
  await onChangedTaskDetail(
    currentTask.value.setTaskApproval({
      approvalEnabled: false,
      approverIds: [],
    })
  );

  isChangeState.value.isLoading = false;
};
const onCancelApprovalRequest = async () => {
  isChangeState.value.isLoading = true;
  await onChangedTaskDetail(currentTask.value.cancelRequestTaskApproval());

  isChangeState.value.isLoading = false;
};
const onSetReminderForTask = async () => {
  await getReminderByTaskId();
  // await initTaskReminder();
  isOpenReminderModal.value = false;
};

const { formatDate } = datetimeComposable();

const convertedTime = (dateTime, format: FormatType) => {
  return formatDate(dateTime, format, {
    isIgnoreTimezone: true,
  });
};

// const myTimezoneOffset = computed(() => myProfileStore().getTimezone);
// const timezoneOffsetUser = computed(
//   () => parseInt(myTimezoneOffset.value) || 0
// );

const reminderDateText = () => {
  let reminderText;
  if (!currentTask.value?.taskReminderInfo?.startDate) return '';

  const taskReminderTime = dayjs(
    currentTask.value?.taskReminderInfo?.startDate
  );

  const diffDate = taskReminderTime.diff(dayjs().format('YYYY-MM-DD'), 'day');
  switch (true) {
    case taskReminderTime.isToday():
      reminderText = translate('COMMON_LABEL_TODAY');
      break;
    case diffDate == 1:
      reminderText = translate('COMMON_LABEL_TOMORROW');
      break;
    default:
      reminderText =
        taskReminderTime.year() !== dayjs().year()
          ? convertedTime(
              currentTask.value?.taskReminderInfo?.startDate,
              'date'
            )
          : convertedTime(
              currentTask.value?.taskReminderInfo?.startDate,
              'day_month'
            );
      break;
  }

  return reminderText;
};

const onSelectActionOnTask = (actionKey) => {
  switch (actionKey) {
    case EActionOnTask.DISCUSS:
      chatAboutTaskRef.value?.onOpenChatAboutTaskModal();
      break;
    case EActionOnTask.CLONE:
      onCloneTask();
      break;
    case EActionOnTask.RELOAD:
      onReload();
      break;
    case EActionOnTask.SET_REPEAT:
      onOpenSetRepeats(false);
      break;
    case EActionOnTask.COPY_LINK:
      onCopyLink();
      break;
    case EActionOnTask.SHARE:
      isOpenTaskSharing.value = true;
      break;
    case EActionOnTask.SNAPSHOT:
      isOpenTaskSnapshots.value = true;
      break;
    case EActionOnTask.WORKFLOW:
      onOpenWorkFlow();
      break;
    case EActionOnTask.APPROVAL:
      onOpenApproval();
      break;
    case EActionOnTask.REMINDER_TASK:
      onReminderTask(currentTask.value?.taskReminderInfo ? 'UPDATE' : 'CREATE');

      break;
    case EActionOnTask.PIN:
      if (
        !currentTask.value?.pinnedColor ||
        currentTask.value.pinnedColor == 'UN_PIN'
      ) {
        onChangePin(TASK_PIN_BY_CODE.PIN_GRAY);
      } else {
        onUnpin();
      }
      break;
    case EActionOnTask.EXPAND:
      onOpenTaskModal(currentTask.value?.code);
      break;
    case EActionOnTask.LOCK_UPDATE_DEADLINE:
      onToggleLockDeadline();
      break;
    case EActionOnTask.PROVIDE_EVIDENCE_WHEN_FINISH:
      isOpenSettingFinishEvidenceModal.value = true;
      break;

    default:
      break;
  }
};

const onOpenTaskModal = (taskCode) => {
  taskCodeGlobal.value = taskCode;
  isShowTaskDetailGlobal.value = true;
};

const canChangeDeadline = computed<boolean>(() => {
  return currentTask.value?.canTaskChangeDeadline(true);
});

const removeAsSubTask = async () => {
  await onChangedTaskDetail(
    currentTask.value.removeAsSubTask(currentTask.value?.parentName || '')
  );
};
const setParentTask = async (task) => {
  isOpenSearchTaskModal.value = false;
  await onChangedTaskDetail(currentTask.value.setParentTask(task));
};

const openNewTab = (task, openInModal?) => {
  if (props.isModal) {
    emit('goToTask', { ...task, tabType: 'DETAIL' });
  } else if (openInModal) {
    onOpenTaskModal(task?.code);
  } else {
    _taskDrawerStore.pushCurrentIds({ ...task, tabType: 'DETAIL' });
  }
};

const isOpenSettingFinishEvidenceModal = ref<boolean>(false);

const updateEvidenceSettings = (data: {
  finishProofEnabled: boolean;
  finishProofTypes: string;
  finishProofSkipAllowed: boolean;
}) => {
  isOpenSettingFinishEvidenceModal.value = false;
  onChangedTaskDetail(currentTask.value.updateEvidenceSettings(data));
};
const handleFinishAndNextStep = async (nextStepId) => {
  isChangeState.value.state = TaskLife.Finished;
  isChangeState.value.isLoading = true;
  await onChangedTaskDetail(
    (async function () {
      workflowNextStepTaskId.value =
        await currentTask.value.finishTaskInWorkflow(nextStepId);

      goToTask(workflowNextStepTaskId.value);
    })()
  );
  isChangeState.value.isLoading = false;
};
const updateFileStatus = (isNote, fileId, status) => {
  if (isNote)
    onChangedTaskDetail(currentTask.value.updateNoteStatus(fileId, status));
  else onChangedTaskDetail(currentTask.value.updateFileStatus(fileId, status));
};

const onDetachSubTask = (subTask) => {
  console.log('🚀 Tictop ~ subTask:', subTask);
  new TaskDetailClass(subTask).removeAsSubTask();
};

const isOpenSearchTaskModal = ref<boolean>(false);
const isOpenChangeDeadline = ref<boolean>(false);
const isHiddenDescriptionTitle = ref<boolean>(false);

const deadlineClassString = computed<string>(() => {
  return getConfigScheduleColor(currentTask.value?.taskSchedule);
});

initComponent();
</script>

<template>
  <!--  <div v-if="isLoadingInit" class="w-full h-full p-4">-->
  <!--    <syn-task-loading/>-->
  <!--  </div>-->
  <!--  <template>-->
  <!--  ERROR CODE  -->
  <div
    v-if="
      !isLoadingInit &&
      errorCode &&
      Object.values(isLoadingDataTask)?.filter((el) => el === true)?.length < 1
    "
    id="task-detail-page"
    class="flex-center flex-1 min-h-0 w-full bg-white"
    style="max-width: 50rem"
  >
    <div class="py-6 px-4 bg-opacity-40 rounded-xl flex flex-col">
      <syn-animation name="searchNotFound" stype="width: 150px; height:150px" />
      <div class="flex flex-col space-y-4 pt-8 text-center">
        <span
          class="font-semibold text-2xl text-current-500"
          v-html="
            $t(errorCode, {
              code: formatTaskCode(taskCode) || '',
            }) ||
            $t('TASK_LABEL_CAN_NOT_FIND_OUT_TASK') ||
            'Something wrong'
          "
        >
        </span>
        <template v-if="errorCode !== 'COMMON_ERROR_BY_CODE_6000'">
          <span
            class="text-gray-500"
            v-html="
              $t('TASK_LABEL_NOT_FOUND', {
                taskName: formatTaskCode(taskCode) || '',
              }) || 'We are sorry.'
            "
          >
          </span>
        </template>
        <SynButton
          type-text
          :label="$t('COMMON_LABEL_REFRESH')"
          @click.stop="initComponent"
        />
      </div>
    </div>
  </div>
  <!-- HAVE TASK   -->
  <div
    v-if="!errorCode"
    ref="taskDetailBodyRef"
    v-drop-zone="{ isDragging: dragOver, onDrop: onDropImage }"
    class="w-full flex-1 min-h-0 flex flex-col text-sm bg-white relative"
    style="max-width: 50rem"
    @paste="onPasteFiles"
    @click="onClickToBody"
  >
    <div
      v-if="isLoadingDataTask?.detail"
      class="absolute w-full z-50 h-full flex-center bg-gray-50 bg-opacity-30"
    >
      <syn-icon
        custom-class="animate-spin w-4 h-4 fill-current"
        name="spinner"
      />
    </div>
    <UploadDropZone
      v-if="!isDraggingTodoList && isDraging"
      class="absolute w-full top-0 left-0 right-0 bottom-0 z-20"
    />

    <div
      v-if="currentTask?.workFlowTaskId"
      v-system-config="[
        {
          functionCode: 'ADVANCED_WORKFLOW_WORKFLOW_ALLOWED_BY_SYSTEM',
          functionValue: '1',
        },
        {
          functionCode: 'DRAFT_WORKFLOW_WORKFLOW_ALLOWED',
          functionValue: '1',
        },
      ]"
      class="w-full flex flex-col items-start pb-2 space-y-1"
    >
      <!-- <span class="text-xs w-28 text-gray-500 flex items-center pt-2">
  {{ $t('TASK_ACTION_WORK_FLOW') }}
</span> -->
      <div class="flex-1 min-w-0 w-full flex items-center relative">
        <TaskWorkflowCurrentStep
          v-if="!isLoadingInit"
          :key="loadingData.latestUpdateWorkflow"
          ref="taskWorkflowBreadcrumbRef"
          view-mode="TASK_VIEW"
          :task-id="currentTask?.id"
          :task-detail="currentTask"
          :default-task-workflow="currentTask.taskWorkflow"
          :has-just-next-step="!!workflowNextStepTaskId"
          @detach-success="detachWorkflowSuccess"
          @update-task="updateTaskFromWorkflowCurrentStep"
          @choose-step="onChooseStep"
          @update-current-step="initTaskWorkflow"
          @blinked="workflowNextStepTaskId = null"
        />
        <div
          v-if="loadingData.workflow"
          class="w-full h-full cursor-wait absolute top-0 left-0 z-10"
        ></div>
      </div>
    </div>
    <!-- HEADER ACTION -->
    <section
      class="
        w-full
        flex
        items-center
        justify-between
        py-2
        px-4
        border-b border-gray-100
      "
    >
      <div
        v-vig-tooltip="$t('TASK_DETAIL_COPY_ID') || 'Click to copy'"
        class="flex-center space-x-3 text-xs"
      >
        <!-- ID & STATE -->
        <span
          class="bg-gray-100 px-2 py-1.5 rounded cursor-pointer"
          :class="isLoadingInit ? 'animate-pulse' : ''"
          @click="onCopyCode"
        >
          <div v-if="isLoadingInit" class="h-4 w-12"></div>
          <span v-else class="font-medium text-gray-600">
            {{ `Id: ${formatTaskCode(currentTask.code)}` }}
          </span>
        </span>
      </div>

      <!-- STEPPER STATE -->
      <section
        class="flex-1"
        :class="
          [TaskLife.Todo, TaskLife.InProcess, TaskLife.Finished]?.includes(
            currentTask?.taskLife
          )
            ? 'flex-center'
            : 'ml-2'
        "
      >
        <TaskApprovalInfo
          v-if="
            currentTask?.approvalEnabled &&
            currentTask?.approvalStatus &&
            currentTask?.approvalStatus == TaskApprovalStatus.WAITING
          "
          :task="currentTask"
          :approval-enabled="currentTask?.approvalEnabled"
          :approval-status="currentTask?.approvalStatus"
          :approvers="currentTask?.approvers"
          :approver-id="currentTask?.approverId"
          :loading-status="loadingData.approval"
          @on-change-approval-status="onChangeApprovalStatus"
        />
        <TaskStateStepper
          v-else
          :key="loadingData.workflow"
          :is-loading="isLoadingInit"
          :task-detail="currentTask"
          :task-life="currentTask?.taskLife"
          :finisher-id="currentTask?.finisherId"
          :object-loading="isChangeState"
          :readonly="!isEditable"
          :approval-enabled="currentTask?.approvalEnabled"
          :approvers="currentTask?.approvers"
          @on-change-state="onChangeTaskState"
          @delete="onDeleteTask"
          @on-change-approval-status="onChangeApprovalStatus"
          @update-evidence-settings="updateEvidenceSettings"
          @on-next-step-in-workflow="
            (comment) => handleFinishAndNextStep(comment?.nextStepId)
          "
          @on-request-approval="onRequestApproval"
        />
      </section>

      <!-- OPTION -->
      <div class="flex items-center space-x-1">
        <template v-if="isLoadingInit">
          <div
            v-for="item in 3"
            :key="item"
            class="w-8 h-8 rounded bg-gray-100 animate-pulse"
          ></div>
        </template>
        <template v-else>
          <!-- <div
            :title="$t('TASK_DETAIL_COPY_LINK') || 'Copy link'"
            class="
              flex-center
              w-8
              h-8
              rounded-md
              p-2
              hover:bg-gray-100 hover:text-current-800
              text-gray-200
              cursor-pointer
            "
          >
            <SynIcon
              name="link"
              style="width: 1.2rem; height: 1.2rem"
              @click="onCopyLink"
            />
          </div> -->
          <ActionOnTask
            :current-task="currentTask"
            :chat-enabled="chatEnabled"
            :readonly="!isEditable"
            :task-code="taskCode"
            :action-list="[
              EActionOnTask.DISCUSS,
              EActionOnTask.WORKFLOW,
              EActionOnTask.CLONE,
              EActionOnTask.RELOAD,
              EActionOnTask.SET_REPEAT,
              EActionOnTask.SHARE,
              EActionOnTask.SEND_TO_WHITEBOARD,
              EActionOnTask.SNAPSHOT,
              EActionOnTask.PIN,
              EActionOnTask.COPY_LINK,
              EActionOnTask.APPROVAL,
              EActionOnTask.REMINDER_TASK,
              EActionOnTask.LOCK_UPDATE_DEADLINE,
              EActionOnTask.SUB_TASK,
              EActionOnTask.PROVIDE_EVIDENCE_WHEN_FINISH,
            ]"
            @select-action="onSelectActionOnTask"
            @delete="onDeleteTask"
            @open-approval-modal="onOpenApproval"
            @un-mask-as-approval="unMaskAsApproval"
            @cancel-approval-request="onCancelApprovalRequest"
            @remove-as-sub-task="removeAsSubTask"
            @set-parent-task="setParentTask"
          />
        </template>
      </div>
    </section>

    <!-- TASK BODY -->
    <section
      id="tagOptionActivity"
      ref="taskDetailRef"
      class="
        flex-1
        min-h-0
        h-full
        flex flex-col
        space-y-2.5
        relative
        overflow-y-auto
        small-scrollbar
      "
      :style="'height: 200px;'"
    >
      <!-- CREATE DATE & QUICK ACTION -->
      <section
        class="
          w-full
          flex
          items-center
          justify-between
          py-2
          text-sm
          px-4
          bg-gray-50
          flex-wrap
        "
      >
        <div class="flex-center gap-2">
          <span class="text-gray-500"
            >{{ $t('TASK_TABLE_LABEL_CREATED_DATE') }}:</span
          >
          <SynLabelDateTime
            class="text-gray-500"
            format="date_time"
            :datetime="currentTask.creationTime"
          />
          <div class="flex-center">
            <SetPrivateTask
              v-if="!isPersonalModel"
              :current-task="currentTask"
              @change="onSavePrivate"
            />
            <TaskApprovalShortInfo
              :approval-enabled="currentTask?.approvalEnabled"
              :approval-status="currentTask?.approvalStatus"
              :approvers="currentTask?.approvers"
              :approver-id="currentTask?.approverId"
              :readonly="!hasPermission"
              is-show-sticker
              is-task-detail-page
              @on-edit="isOpenApprovalModal = true"
              @on-remove="unMaskAsApproval"
              @on-cancel-approval-request="onCancelApprovalRequest"
            />

            <TaskReminderDropdown
              v-if="currentTask?.taskReminderInfo?.id"
              :reminder-text="`${reminderDateText()}, ${convertedTime(
                currentTask?.taskReminderInfo?.startDate,
                'time'
              )}`"
              :text-tooltip="
                $t('COMMON_LABEL_REMINDER_TASK_TOOLTIP', {
                  time: convertedTime(
                    currentTask?.taskReminderInfo?.startDate,
                    'date_time'
                  ),
                })
              "
              @on-edit="onReminderTask('UPDATE')"
              @on-remove="removeReminder()"
            />

            <!-- <div class="flex-center gap-1">
              <
              <SynCountDown
                class="p-0 text-pink-500"
                format="hh:mm:ss"
                auto-start
                :total-miniseconds="1729158679000 - new Date().getTime()"
              />
            </div> -->
          </div>
        </div>

        <ActionOnTask
          view-mode="QUICK_ACTION"
          :current-task="currentTask"
          :chat-enabled="chatEnabled"
          :readonly="!isEditable"
          :is-hidden-expand="!!isPlaceModal"
          :action-list="[
            EActionOnTask.DISCUSS,
            EActionOnTask.WORKFLOW,
            EActionOnTask.CLONE,
            EActionOnTask.RELOAD,
            EActionOnTask.SET_REPEAT,
            EActionOnTask.SHARE,
            EActionOnTask.SNAPSHOT,
            EActionOnTask.PIN,
            EActionOnTask.COPY_LINK,
            EActionOnTask.APPROVAL,
            EActionOnTask.REMINDER_TASK,
            EActionOnTask.LOCK_UPDATE_DEADLINE,
            EActionOnTask.SUB_TASK,
            EActionOnTask.PROVIDE_EVIDENCE_WHEN_FINISH,
            ...(isPlaceModal ? [] : [EActionOnTask.EXPAND]),
          ]"
          @select-action="onSelectActionOnTask"
          @attach-workflow="onSaveWorkflow"
          @update:loading="(value) => (isUpdatingTask = value)"
          @delete="onDeleteTask"
          @open-approval-modal="onOpenApproval"
          @un-mask-as-approval="unMaskAsApproval"
          @cancel-approval-request="onCancelApprovalRequest"
          @remove-as-sub-task="removeAsSubTask"
        >
          <template #discuss>
            <TaskChatAbout
              ref="chatAboutTaskRef"
              :current-task="currentTask"
              :is-place-modal="isPlaceModal"
              @on-start-chat="emit('onClose')"
            />
          </template>

          <template #pin>
            <SetPinTask
              :current-pin-code="currentTask?.pinnedColor"
              @on-change-pin="onChangePin"
              @on-unpin="onUnpin"
            />
          </template>
        </ActionOnTask>
      </section>
      <section
        v-if="isNotGroupMember"
        class="
          w-full
          items-center
          px-4
          py-1
          gap-1
          bg-orange-50
          inline-flex
          text-xs text-orange-500
        "
        style="margin-top: -0.5rem"
      >
        <SynIcon name="private" custom-class="h-3 w-3 fill-orange-500" />
        <p class="inline">
          {{ $t('TASK_ONLY_CAN_EDIT_BY_GROUP_MEMBER') }}
        </p>
        <GroupById
          :group-id="currentTask?.groupId"
          avatar-class="w-4 h-4 text-xs"
          class="w-max font-semibold"
        />
      </section>
      <section
        v-if="currentTask?.parentId"
        class="
          w-full
          items-center
          px-4
          inline-flex
          text-sm
          gap-2
          hover:underline
          cursor-pointer
        "
        @click="
          openNewTab({
            id: currentTask?.parentId,
            code: currentTask?.parentCode,
            name: currentTask?.parentName,
          })
        "
      >
        <p class="inline text-gray-600 truncate">
          {{ currentTask?.parentName }}
        </p>
        <SynIcon name="SortDownThin" custom-class="h-2 w-2 fill-gray-600" />
        <SynIcon
          v-if="isEditable"
          name="edit"
          is-active
          class="fill-gray-500 cursor-pointer hover:fill-current-500"
          :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
          @click.stop="isOpenSearchTaskModal = true"
        />
      </section>

      <!-- NAME TASK -->
      <section
        class="flex items-center space-x-1 justify-between text-xl px-2 pb-2"
      >
        <div v-if="currentTask?.creatorId" class="flex-center pl-2">
          <UserByAction
            :user-id="currentTask?.creatorId"
            is-show-action-user
            is-hidden-name
            avatar-class="w-10 h-10 text-xs font-medium"
          />
        </div>
        <div
          v-if="isLoadingInit"
          class="w-1/2 h-12 rounded bg-gray-100 animate-pulse"
        ></div>
        <div
          v-else-if="isEdittingName"
          class="flex-1 flex-center flex-col space-x-2 z-20"
        >
          <SynInputArea
            v-model="edittingName"
            :disabled-enter-warning="
              $t('TASK_NAME_SHOULD_BE_WRITTEN_SEAMLESSLY') ||
              'The task name should be written seamlessly'
            "
            autofocus
            max-length="200"
          />
          <div
            class="w-full flex items-center justify-end space-x-1 text-xs px-2"
          >
            <SynButton
              :label="$t('COMMON_LABEL_CLOSE') || 'Close'"
              type-text
              color="gray"
              custom-class="px-2 py-1 rounded"
              @click.stop="onCloseEditName"
            />
            <SynButton
              :label="$t('COMMON_LABEL_SAVE') || 'Save'"
              custom-class="px-2 py-1 rounded"
              :disabled="!edittingName"
              @click.stop="onRenameTask"
            />
          </div>
        </div>
        <div
          v-else
          class="
            flex-1 flex
            items-center
            space-x-2
            px-2
            py-2
            rounded
            cursor-text
            w-2/3
            hover:bg-gray-100
            hover-to-show__parent
            text-gray-800
            relative
          "
        >
          <TaskWorkflowCurrentStep
            v-if="!isLoadingInit && currentTask.workFlowTaskId"
            :key="loadingData.latestUpdateWorkflow"
            ref="taskWorkflowBreadcrumbRef"
            view-mode="TASK_VIEW"
            :task-id="currentTask?.id"
            :task-detail="currentTask"
            :default-task-workflow="currentTask.taskWorkflow"
            :has-just-next-step="!!workflowNextStepTaskId"
            show-code-only
            @detach-success="detachWorkflowSuccess"
            @update-task="updateTaskFromWorkflowCurrentStep"
            @choose-step="onChooseStep"
          />
          <span
            v-encode-html="{ text: currentTask?.name }"
            class="break-words font-medium"
          >
          </span>
          <SynIcon
            v-if="isEditable"
            name="edit"
            is-active
            class="
              hover-to-show__children
              fill-current
              cursor-pointer
              absolute
              hover:fill-current-800
              top-2
              right-1
            "
            :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
            @click.stop="onOpenEditName"
          />
        </div>
      </section>
      <!-- <div class="flex items-center justify-between px-4 py-2 bg-gray-100 mb-2">
        <div class="inline-flex items-center gap-1 text-sm">
          Công việc này cần phê duyệt bởi <UserById :user-id="5923" />
        </div>
        <div class="flex-center gap-2">
          <div
            class="
              h-8
              w-8
              flex-center
              gap-2
              hover:bg-current-50
              cursor-pointer
              dropdown-item
            "
          >
            <SynIcon name="edit" class="fill-blue-500" custom-class="h-3 w-3" />
          </div>
          <div
            class="
              h-8
              w-8
              flex-center
              gap-2
              hover:bg-current-50
              cursor-pointer
              dropdown-item
            "
          >
            <SynIcon name="trash" class="fill-red-500" custom-class="h-3 w-3" />
          </div>
        </div>
      </div> -->

      <!-- CREATOR +  ASSIGNMENT -->

      <div v-permission-model class="w-full px-4 flex items-center">
        <span class="text-xs w-28 text-gray-500 flex items-center h-8">
          {{ $t('TASK_LABEL_ASSIGNEE') }}
        </span>
        <div class="flex items-center space-x-1">
          <section
            v-permission-model
            class="w-full flex items-center space-x-2 justify-between"
            :class="isOpenChangeAssign ? 'w-full' : ''"
          >
            <!-- <span class="text-xs w-28 text-gray-500 flex items-center h-8">
            {{ $t('TASK_LABEL_ASSIGNEE') || 'Responsible' }}
          </span> -->
            <TaskDetailField
              :is-editable="isEditable"
              :is-focus="isOpenChangeAssign"
              :style="!isOpenChangeAssign ? '' : 'flex: 70%;'"
            >
              <template #fieldName>
                <span>{{ $t('TASK_LABEL_ASSIGNEE') }}</span>
              </template>
              <template #body>
                <template v-if="isLoadingInit">
                  <div
                    class="flex text-xs h-full items-center space-x-1 py-2 px-2"
                  >
                    <div
                      class="w-6 h-6 rounded-full bg-gray-100 animate-pulse"
                    ></div>
                    <div
                      class="flex-1 h-3 rounded-full bg-gray-100 animate-pulse"
                    ></div>
                  </div>
                </template>
                <template v-else>
                  <div style="max-width: 22rem; min-width: 10rem">
                    <ChangeAssign
                      v-if="isOpenChangeAssign"
                      v-permission="'FunctionTaskAssign'"
                      class="text-xs"
                      is-update-instant
                      is-multi-assign
                      :group-id="currentTask.groupId || 0"
                      :user-id="currentTask.assigneeId || 0"
                      :domain-id="currentTask.domainId || 0"
                      :project-id="currentTask.projectId || 0"
                      :subproject-id="currentTask.subprojectId || 0"
                      :collaborators="currentTask?.collaborators"
                      @on-cancel="onCancelAssign"
                      @on-save="onChangeAssign"
                    />
                    <div
                      v-else
                      class="
                        flex-center
                        space-x-1
                        relative
                        rounded
                        text-xs
                        font-medium
                        px-2
                        py-2
                      "
                      :class="
                        isEditable
                          ? 'hover:bg-current-50 hover:bg-opacity-60 cursor-pointer'
                          : ''
                      "
                      @click.stop="onOpenChangeAssign"
                    >
                      <div
                        v-if="currentTask?.groupId"
                        class=""
                        :style="
                          currentTask?.assigneeId ? 'flex: 50%' : 'flex: 100%'
                        "
                      >
                        <GroupById
                          :group-id="currentTask?.groupId"
                          avatar-class="w-6 h-6 text-xs"
                        />
                      </div>
                      <span
                        v-if="currentTask?.assigneeId && currentTask?.groupId"
                        class="px-2 text-gray-500"
                        >→</span
                      >
                      <div
                        v-if="currentTask?.assigneeId"
                        class=""
                        :style="
                          currentTask?.groupId ? 'flex: 50%' : 'flex: 100%'
                        "
                      >
                        <UserById
                          is-show-first-name
                          avatar-class="w-6 h-6 text-xs"
                          :user-id="currentTask?.assigneeId"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </TaskDetailField>
            <template
              v-if="
                multipleAssigneeSetting?.systemEnable &&
                multipleAssigneeSetting?.organizationEnable &&
                !currentTask?.isPrivate &&
                (isEditable || currentTask?.collaborators?.length > 0)
              "
            >
              <TaskDetailField
                :is-editable="isEditable"
                :is-focus="isOpenChangeAssign"
              >
                <template #fieldName>
                  <span>{{ $t('COMMON_LABEL_COLLABORATORS') }}</span>
                </template>
                <template #body>
                  <div
                    class="
                      flex-1
                      px-4
                      flex
                      items-center
                      justify-between
                      space-x-6
                    "
                  >
                    <div class="flex-1 flex-center space-x-2">
                      <ManageCollaborator
                        ref="manageCollaboratorRef"
                        v-model:is-edit-mode="isEditCollaborator"
                        class="flex-1"
                        :group-id="currentTask.groupId || 0"
                        :assignee-id="currentTask.assigneeId || 0"
                        :creator-id="currentTask.creatorId || 0"
                        :collaborators="currentTask.collaborators"
                        :readonly="!isEditable"
                        @on-add="onChangeCollaborator"
                        @on-remove="onRemoveCollaborator"
                      />
                    </div>
                    <button
                      v-if="
                        !isEditCollaborator &&
                        !isCollaborate &&
                        !currentTask.isCreateListAssignees
                      "
                      class="flex-center space-x-2 bg-gray-100 p-1 px-2 rounded"
                      @click="toggleFollow"
                    >
                      <div class="w-4 h-4 flex-center">
                        <SynIcon name="bell" />
                      </div>
                      <span
                        v-vig-tooltip="{
                          content: iAmACollaborator
                            ? $t('COMMON_LABEL_STOP_GETTING_NOTIFICATION_TASK')
                            : $t('COMMON_LABEL_GET_NOTIFICATION_TASK'),
                        }"
                        class="text-xs text-gray-600 text-overflow-hidden"
                      >
                        {{
                          $t(
                            iAmACollaborator
                              ? 'TASK_FOLLOW_LEAVE'
                              : 'TASK_FOLLOW_JOIN'
                          )
                        }}
                      </span>
                    </button>
                  </div>
                </template>
              </TaskDetailField>
            </template>
          </section>
        </div>
      </div>

      <!-- DOMAIN  -->
      <div v-permission-model class="w-full px-4 flex items-center">
        <span class="text-xs w-28 text-gray-500 flex items-center h-8">{{
          $t('DOMAIN_LABEL_TITLE')
        }}</span>
        <div class="flex items-center space-x-1">
          <div class="font-medium flex space-x-1 text-xs">
            <div v-if="isLoadingInit">
              <div
                class="h-3 w-24 rounded-full bg-gray-100 animate-pulse"
              ></div>
            </div>
            <template v-else>
              <UpdateDomainDropdown
                v-if="isEditable"
                :task-detail="currentTask"
                layout="horizontal"
                @on-open="onOpenEditDomain"
                @on-save="onSaveDomain"
              />
              <DomainProjectById
                v-else
                :domain-id="currentTask.domainId"
                :project-id="currentTask.projectId"
                :sub-project-id="currentTask.subprojectId"
                layout="horizontal"
              />
            </template>
          </div>
        </div>
      </div>

      <!-- URGENCY + STATE  -->
      <div class="w-full px-4 flex items-center">
        <span class="text-xs w-28 text-gray-500 flex items-center h-8">{{
          $t('TASK_TABLE_LABEL_URGENCY') || 'Urgency'
        }}</span>
        <div class="flex items-center space-x-6">
          <div v-if="isLoadingInit">
            <div class="h-3 w-24 rounded-full bg-gray-100 animate-pulse"></div>
          </div>
          <template v-else>
            <div class="flex" style="width: 9.5rem">
              <VigDropdown v-if="isEditable" :arrow="false">
                <template #dropdown-toggle>
                  <div
                    class="
                      flex
                      font-medium
                      items-center
                      space-x-2
                      hover:bg-current-50
                      cursor-pointer
                      p-1
                      rounded
                      hover-to-show__parent
                    "
                    style="min-width: 8rem"
                  >
                    <PriorityTask size="mini" :urgency="currentTask.urgency" />
                  </div>
                </template>
                <template #dropdown-menu>
                  <div
                    class="
                      w-50
                      py-1
                      z-100
                      list-ul
                      rounded
                      cursor-pointer
                      dropdown-item
                    "
                  >
                    <div
                      v-for="(item, index) in [4, 3, 2, 1]"
                      :key="index"
                      class="list-li flex text-left justify-start pr-2"
                      :class="currentTask.urgency == item ? 'bg-gray-100' : ''"
                      @click="onChangeUrgency(item)"
                    >
                      <PriorityTask :urgency="item" size="mini" />
                    </div>
                  </div>
                </template>
              </VigDropdown>
              <PriorityTask
                v-else
                style="min-width: 8rem"
                :urgency="currentTask.urgency"
                size="mini"
              />
            </div>
          </template>
        </div>
      </div>

      <!--  PLAN TODAY -->
      <div
        v-if="
          currentTask.assigneeId && currentTask.taskLife !== TaskLife.Finished
        "
        id="planToday"
        class="w-full px-4 flex items-center"
      >
        <span class="text-xs w-28 text-gray-500 flex items-center h-8">{{
          $t('TASK_FILTER_LABEL_PLANNED')
        }}</span>
        <div class="flex items-center rounded-md px-1 space-x-4">
          <div v-if="isLoadingInit">
            <div class="h-3 w-24 rounded-full bg-gray-100 animate-pulse"></div>
          </div>
          <template v-else>
            <div class="flex items-center space-x-2">
              <AtomSwitch
                v-model="currentTask.taskSchedule.doItToday"
                :disabled="!isEditable"
                :is-loading="isUpdatingTask"
                @change="onDoItToday"
              >
                <span class="text-current-800 text-xs">
                  {{ $t('TASK_CREATE_FORM_DOING_TODAY') || 'Do today' }}
                </span>
              </AtomSwitch>
            </div>
            <div class="flex items-center space-x-2">
              <AtomSwitch
                v-model="currentTask.taskSchedule.finishItToday"
                :disabled="!isEditable"
                :is-loading="isUpdatingTask"
                @change="onFinishItToday"
              >
                <span class="text-current-800 text-xs">
                  {{ $t('TASK_CREATE_FORM_FINISH_TODAY') || 'Finish today' }}
                </span>
              </AtomSwitch>
            </div>
          </template>
        </div>
      </div>

      <!--  DEADLINE -->
      <div class="w-full px-4 flex items-center">
        <div class="w-28 h-8 flex items-center gap-2">
          <span class="text-xs text-gray-500">{{
            $t('TASK_TABLE_LABEL_DEADLINE')
          }}</span>
          <TaskDeadlineBlockingShortInfo
            v-if="currentTask?.scheduleTimeLocked"
            :readonly="!isEditable || !canChangeDeadline"
            @on-cancel-block-deadline="onToggleLockDeadline(false)"
          />
        </div>
        <div class="flex-center space-x-4">
          <div v-if="isLoadingInit">
            <div class="h-3 w-24 rounded-full bg-gray-100 animate-pulse"></div>
          </div>
          <template v-else>
            <VigDropdown
              v-if="isEditable && canChangeDeadline"
              ref="scheduleRef"
              v-model:isOpen="isOpenChangeDeadline"
              hide-on-click="outside"
              :arrow="false"
            >
              <template #dropdown-toggle>
                <div
                  class="hover:bg-current-50 text-sm px-1 py-0.5 rounded"
                  :class="[
                    getConfigScheduleColor(currentTask?.taskSchedule),
                    isEditable ? 'cursor-pointer' : '',
                    isOpenUpdateDeadline ? 'bg-gray-100 px-2 py-1' : '',
                  ]"
                >
                  <span
                    v-if="
                      currentTask.assigneeId &&
                      getConfigScheduleName(currentTask)
                    "
                  >
                    {{ getConfigScheduleName(currentTask) }}
                  </span>
                  <SynLabelDateTime
                    v-else-if="currentTask.scheduleTime"
                    format="date"
                    :datetime="currentTask.scheduleTime"
                  />
                  <span v-else>
                    {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
                  </span>
                </div>
              </template>
              <template #dropdown-menu>
                <div
                  class="flex flex-col py-4 px-3"
                  :style="{
                    width: currentTask.assigneeId ? '26rem' : '16rem',
                  }"
                >
                  <span class="text-sm font-medium text-gray-700 pb-3">{{
                    $t('TASK_TABLE_LABEL_DEADLINE')
                  }}</span>

                  <UpdateDeadline
                    v-if="isOpenChangeDeadline"
                    ref="updateDeadlineRef"
                    class="text-sm"
                    :schedule-key="currentTask?.taskSchedule?.scheduleOptionKey"
                    :other-time="currentTask.taskSchedule.otherTime"
                    :schedule-timezone="currentTask.scheduleTimezone"
                    :schedule="currentTask.scheduleTime"
                    :user-id="currentTask.assigneeId"
                    :group-id="currentTask.groupId"
                    :start-time="currentTask.startTime"
                    @save="onSaveDeadline"
                    @on-change-schedule-time="onUpdateScheduleDate"
                  />
                </div>
              </template>
            </VigDropdown>

            <div
              v-else
              class="text-sm px-1 py-0.5 rounded"
              :class="[
                deadlineClassString,
                isOpenUpdateDeadline ? 'bg-gray-100 px-2 py-1' : '',
              ]"
            >
              <span
                v-if="
                  currentTask.assigneeId && getConfigScheduleName(currentTask)
                "
              >
                {{ getConfigScheduleName(currentTask) }}
              </span>
              <SynLabelDateTime
                v-else-if="currentTask.scheduleTime"
                format="date"
                :datetime="currentTask.scheduleTime"
              />
              <span v-else>
                {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule' }}
              </span>
            </div>
            <UpdateDeadlineTime
              v-if="currentTask?.scheduleTime"
              :schedule-time="currentTask?.scheduleTime"
              :schedule-by-hour="currentTask?.scheduleByHour"
              :schedule-timezone="currentTask?.scheduleTimezone"
              :my-timezone="myTimezone"
              :readonly="!isEditable || !canChangeDeadline"
              @on-change="changeDeadlineTime"
              @on-remove-time="onRemoveScheduleTime"
            />

            <!-- RECURRING TASK -->
            <template
              v-if="
                currentTask.isRepeat &&
                currentTask.taskRepeat?.repeatType !== ETaskRepeatType.None
              "
            >
              <div class="flex-center space-x-2">
                <div
                  v-permission-function="{
                    functionCode: ALL_FUNCTIONS.TASK.REPEAT,
                    onContinue: () => {
                      onOpenSetRepeats(false);
                    },
                  }"
                >
                  <div
                    class="
                      flex-center
                      px-2
                      rounded
                      text-current-400
                      fill-current-400
                    "
                    :class="
                      isEditable ? 'cursor-pointer hover:bg-current-50' : ''
                    "
                    @click="onOpenSetRepeats(false)"
                  >
                    <div class="flex-center h-8 w-8 rounded">
                      <SynIcon name="recurrence" class="h-5 w-5" />
                    </div>
                    <span class="text-xs">
                      {{
                        $t(
                          repeatTypeByKeys[currentTask.taskRepeat?.repeatType]
                            ?.nameCode
                        ) ||
                        repeatTypeByKeys[currentTask.taskRepeat?.repeatType]
                          ?.name
                      }}
                    </span>
                  </div>
                </div>

                <template v-if="isEditable">
                  <span class="text-sm text-gray-400">|</span>
                  <div
                    v-permission-function="{
                      functionCode: ALL_FUNCTIONS.TASK.REPEAT,
                      onContinue: () => {
                        onOpenSetRepeats(true);
                      },
                    }"
                  >
                    <span
                      class="
                        text-gray-500
                        underline
                        text-xs
                        px-2
                        py-1
                        cursor-pointer
                      "
                      @click="onOpenSetRepeats(true)"
                    >
                      {{ $t('TASK_LABEL_REPEAT_CHANGE_TEMPLATE_TITLE') }}
                    </span>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>

      <!-- DESCRIPTION -->
      <div class="w-full px-4 flex items-start">
        <span
          v-if="!isHiddenDescriptionTitle"
          class="text-xs w-28 text-gray-500 flex items-center h-8"
        >
          {{ $t('COMMON_LABEL_DESCRIPTION') || 'Description' }}
        </span>
        <div
          class="flex-1 flex-center relative"
          style="min-width: 0; max-width: 100%"
        >
          <span
            v-if="isInputComment"
            class="
              absolute
              z-10
              top-0
              cursor-pointer
              hover:bg-current-100
              rounded
              h-6
              w-6
              flex-center
            "
            :class="
              isHiddenDescriptionTitle
                ? '-rotate-90 left-0'
                : 'rotate-90 -left-6'
            "
            @click="isHiddenDescriptionTitle = !isHiddenDescriptionTitle"
          >
            <SynIcon :name="isHiddenDescriptionTitle ? 'Expand4' : 'Expand4'" />
          </span>
          <TaskDescriptionInput
            v-if="isInputComment"
            ref="taskDescriptionRef"
            v-model="commentObjectCreate.content"
            v-model:attachments="attachmentsInDescription"
            v-model:is-valid="myCommentValid"
            v-model:mention-user-ids="commentObjectCreate.mentionUserIds"
            v-model:mention-group="commentObjectCreate.mentionGroup"
            :current-task="currentTask"
            @on-focus="onFocus"
            @on-close="onCloseEditDescription"
            @on-save="onChangeDescription"
            @update-cache="onUpdateCacheStateDescription"
          />
          <div
            v-else
            class="
              whitespace-pre-wrap
              rounded
              border border-transparent
              inline-block
              w-full
              hover-to-show__parent
              leading-6
            "
            :class="[
              isEditable && !currentTask.description
                ? 'cursor-pointer hover:border-gray-300'
                : 'cursor-text',
              currentTask.description ? 'p-1 pl-1' : 'px-2 py-1',
            ]"
            :style="
              currentTask.description
                ? 'padding-top: 12px; padding-bottom: 12px'
                : ''
            "
            @click.stop="
              isEditable && !currentTask.description && onOpenEditDescription()
            "
          >
            <div v-if="isLoadingInit">
              <div
                class="h-3 w-24 rounded-full bg-gray-100 animate-pulse"
              ></div>
            </div>
            <template v-else>
              <TaskDescriptionPreview
                v-if="isEditable || currentTask?.description"
                :description="currentTask?.description"
              />
              <SynIcon
                v-if="isEditable"
                name="edit"
                is-active
                class="
                  hover-to-show__children
                  absolute
                  top-1
                  right-1
                  fill-current
                  cursor-pointer
                  hover:fill-current-800
                "
                :title="$t('COMMON_LABEL_EDIT') || 'Edit'"
                @click.stop="onOpenEditDescription"
              />
            </template>
          </div>
        </div>
      </div>
      <!-- SUB TASK -->
      <div v-if="!currentTask?.parentId" class="w-full px-4 flex flex-col">
        <template v-if="loadingData.subTask">
          <div
            v-if="currentTask?.totalSubTask && currentTask?.totalSubTask > 0"
            class="w-full flex flex-col gap-1"
          >
            <div
              v-for="item in currentTask?.totalSubTask"
              :key="item"
              class="rounded w-full flex items-center h-10 gap-2 animate-pulse"
            >
              <div class="bg-gray-50 w-6 h-6 rounded-full"></div>
              <div class="bg-gray-50 w-20 h-6 rounded"></div>
              <div class="bg-gray-50 flex-1 h-6 rounded"></div>
              <div class="bg-gray-50 w-20 h-6 rounded"></div>
              <div class="bg-gray-50 w-6 h-6 rounded-full"></div>
            </div>
          </div>
        </template>
        <SubTaskList
          v-else
          :key="updateSubTaskLatestTime"
          :update-sub-task-id="updateSubTaskId"
          :parent-task="currentTask"
          :domain-id="currentTask?.domainId"
          :readonly="!isEditable"
          @go-to-task="(task) => openNewTab(task, true)"
          @detach="onDetachSubTask"
        />
      </div>

      <!-- FILES -->
      <section class="px-4 pt-2">
        <div class="w-full flex items-center space-x-2">
          <span
            class="text-sm flex font-medium items-center"
            style="color: #128f90"
          >
            {{
              `${$t('TASK_DETAIL_LABEL_ATTACHMENT') || 'Attachments'} ${
                totalAttachmentFiles > 0 ? '(' + totalAttachmentFiles + ')' : ''
              }`
            }}
          </span>
          <FilesDownload
            v-if="totalAttachmentFiles > 2"
            :files="fileShowList"
            :confirm-data="{
              title: 'COMMON_LABEL_NOTE',
              content: 'DO_YOU_WANT_TO_DOWNLOAD_ALL_THE_FILES_FOR_THIS_TASK',
            }"
            :zip-file-name="`task_#${formatTaskCode(
              currentTask?.code
            )}_attachments_${dayjs().format('DD-MMM-YYYY_hh-mmA')}`"
          >
            <AtomButton
              v-vig-tooltip="$t('COMMON_LABEL_DOWNLOAD')"
              type="TEXT"
              class="flex-center h-8 w-8"
            >
              <SynIcon name="Download" />
            </AtomButton>
          </FilesDownload>
          <div
            v-if="isEditable && totalAttachmentFiles < 1"
            class="flex-center space-x-1"
          >
            <GroupAttachmentAction
              :has-permission="isEditable"
              :input-file-id="taskDetailFileInputId"
              @handle-click-note="onOpenCreateNote"
              @handle-click-audio="isOpenRecording = true"
              @on-add-todo-list="onAddTodoList"
            />
          </div>
        </div>

        <div
          v-if="totalAttachmentFiles > 0 || uploadingFiles?.length > 0"
          class="w-full items-center flex flex-wrap gap-4"
        >
          <div
            v-if="isLoadingDataTask.files || isLoadingInit"
            class="flex flex-wrap"
          >
            <div
              v-for="item in totalAttachmentFiles"
              :key="item"
              class="w-20 h-16 animate-pulse bg-gray-100 rounded-md shadow-md"
            ></div>
          </div>
          <template v-else>
            <div
              v-for="(file, index) in fileShowList"
              :key="index"
              class="relative w-max h-16 hover-to-hidden__parent"
            >
              <UploadPreview
                :file="file"
                :readonly="!isEditable"
                :is-note="file.isNote"
                is-hidden-info
                @on-select="onOpenFiles(file)"
                @remove="onRemoveAttachment(file)"
              >
                <template #changeStatus>
                  <SetAttachmentStatus
                    :current-status="file?.status"
                    @toggle-change-status="
                      updateFileStatus(
                        file?.isNote,
                        file?.id,
                        file?.status ? null : ETaskFileStatus.MARK_GREEN
                      )
                    "
                    @on-change-status="
                      (status) =>
                        updateFileStatus(file?.isNote, file?.id, status)
                    "
                  />
                </template>
              </UploadPreview>
              <div
                v-if="isHasJustCreatedFile(file)"
                class="
                  new-sticker
                  absolute
                  top-3
                  -left-0
                  rounded
                  w-max
                  text-white
                  px-1
                  bg-current
                  -rotate-45
                  hover-to-hidden__children
                "
                style="font-size: 0.6rem"
              >
                {{ $t('COMMON_LABEL_NEW') }}
              </div>
            </div>
            <template v-if="uploadingFiles.length > 0">
              <AttachmentUploading
                :uploading-files="uploadingFiles"
                :upload-error="uploadError"
                @on-select-note="onOpenCreateNote"
              />
            </template>
            <AttachmentGroupButton
              v-if="isEditable"
              :input-file-id="taskDetailFileInputId"
              @handle-click-note="onOpenCreateNote"
              @handle-click-audio="isOpenRecording = true"
              @handle-click-comment="onOpenEditDescription"
              @on-add-todo-list="onAddTodoList"
            />
          </template>
        </div>
      </section>

      <!-- COMMENT - TASK LOG -->
      <div
        ref="commentAndTaskLogRef"
        class="
          mx-2
          flex
          bg-white
          items-center
          justify-start
          space-x-2
          py-2
          px-2
          sticky
          top-0
          z-10
        "
        style="margin-right: 20px"
      >
        <ListTagOption
          v-if="!isLoadingInit"
          :tag-list="listActivityOption"
          :tag-active="activeTag"
          :data-by-key="{
            [TypeActivity.COMMENT]: {
              totalItem: currentTask?.totalComment || 0,
            },
            [TypeActivity.TODO_LIST]: {
              totalItem: currentTask?.taskTodoTotal || 0,
            },
          }"
          @on-select-option="onChooseTaskActivityOption"
        />
      </div>

      <template v-if="!isLoadingInit && currentTask?.id">
        <!-- CONTENT_ACTIVITY  -->
        <div
          v-if="activeTag === TypeActivity.COMMENT"
          id="taskComment"
          class="w-full h-full flex flex-col justify-between relative"
        >
          <TaskComment
            v-if="currentTask?.totalComment > 0"
            :task-id="currentTask?.id"
            :current-task="currentTask"
            @on-save-success="onSaveCommentSuccess"
            @on-save-update-note="onSaveUpdateNote"
            @on-ready="onMountedTaskComment"
            @on-scroll-to="onCommentScrollTo"
            @delete-success="onDeleteSuccess"
          />
          <div
            v-else-if="isEditable"
            class="w-full px-4 py-2 text-gray-500 cursor-pointer"
            @click="onAddNewComment"
            v-html="$t('TASK_DETAIL_HAS_NO_COMMENT')"
          ></div>
          <div v-else class="w-full px-4 py-2 text-gray-500">
            {{ $t('TASK_DETAIL_HAS_NO_COMMENT_WITHOUT_ACTION') }}
          </div>
        </div>
        <!-- TODO LIST  -->
        <div
          v-if="
            activeTag === TypeActivity.TODO_LIST &&
            !isLoadingInit &&
            currentTask?.id
          "
          class="w-full h-full flex flex-col justify-between relative"
        >
          <!-- CHECK LIST -->
          <template v-if="!currentTask.taskTodoTotal">
            <div
              v-if="isEditable"
              class="w-full px-4 py-2 text-gray-500 cursor-pointer"
              @click="onAddTodoList"
              v-html="$t('TASK_DETAIL_HAS_NO_CHECKLIST')"
            ></div>
            <div v-else class="w-full px-4 py-2 text-gray-500">
              {{ $t('TASK_DETAIL_HAS_NO_CHECKLIST_WITHOUT_ACTION') }}
            </div>
          </template>

          <section
            v-drop-zone="{
              isDragging: (value) => {
                isDraggingTodoList = value;
              },
              onDrop: () => {
                isDraggingTodoList = false;
              },
            }"
            class="px-4 pr-6"
          >
            <TaskTodoFlatList
              ref="taskTodoListAllRef"
              v-model:total-todo-list="currentTask.taskTodoTotal"
              :task-id="currentTask?.id"
              :todo-list="currentTask?.todoList"
              :readonly="!isEditable"
              @on-create-success="(data) => (currentTask.todoList = data)"
              @on-save="onUpdateChecklist"
              @on-scroll-to-bottom="onScrollToBottom"
            />
          </section>
        </div>

        <template v-if="activeTag === TypeActivity.TASKLOG">
          <div
            v-if="currentTaskHistoryId && isOpenTaskHistory"
            v-permission-function="{
              functionCode: ALL_FUNCTIONS.TASK.HISTORY,
            }"
            class="px-4 pt-2"
          >
            <TaskLogActivity
              ref="taskLogActivityRef"
              :task-id="currentTask?.id"
            />
          </div>
        </template>
      </template>
    </section>

    <!-- COMMENT BOTTOM   -->
    <section
      v-if="hasPermission"
      id="task-detail-comment-bottom"
      class="
        w-full
        flex
        items-start
        justify-between
        pb-3
        pt-4
        px-4
        border-t border-gray-100
      "
      style="max-height: 25rem"
    >
      <TaskCommentBottom
        ref="taskCommentBottomRef"
        :key="currentTaskId"
        :task-id="currentTaskId"
        :current-task="currentTask"
        @on-save-success="onSaveCommentSuccess"
      />
      <!--      <div class="pl-2 pt-2 flex-center">-->
      <!--        <GroupAttachmentAction-->
      <!--          :has-permission="isEditable"-->
      <!--          :input-file-id="taskDetailFileInputId"-->
      <!--          @handle-click-note="onOpenCreateNote"-->
      <!--          @handle-click-audio="isOpenRecording = true"-->
      <!--        />-->
      <!--      </div>-->
    </section>

    <!-- END -->
  </div>
  <!--  </template>-->

  <input
    v-if="isEditable"
    :id="taskDetailFileInputId"
    type="file"
    multiple
    class="hidden opacity-0"
    :accept="webTaskUploadContentTypes"
    @change="onChooseFiles"
  />

  <ModalMultipleFileViewer
    v-if="isOpenMobileNote"
    :file="filePreview"
    @on-close="closePreview"
  />

  <ModalMultipleFileViewer
    v-if="isPreview && allAttachments?.length > 0"
    ref="modalMultipleFileViewerRef"
    :start-index="currentFileIndex"
    :files="allAttachments"
    :editable="isEditable"
    :current-task="currentTask"
    :is-loading="isLoadingWhenUpdateNote"
    is-show-mark-action
    @on-close="isPreview = false"
    @on-save-note="onSaveUpdateNote"
    @on-save-description="onChangeDescription"
    @on-remove="onRemoveAttachment"
    @on-update-file-status="updateFileStatus"
  >
    <template #title>
      <span
        v-html="`${formatTaskCode(currentTask?.code)} - ${currentTask?.name}`"
      >
      </span>
    </template>
    <template #footer-action>
      <template v-if="uploadingFiles.length > 0">
        <AttachmentUploading
          :uploading-files="uploadingFiles"
          :upload-error="uploadError"
        />
      </template>
      <AttachmentGroupButton
        v-if="isEditable"
        class="flex-none bg-opacity-50"
        :input-file-id="taskDetailFileInputId"
        @handle-click-note="onOpenCreateNote"
        @handle-click-audio="isOpenRecording = true"
        @handle-click-comment="onOpenCommentModal"
      />
    </template>
  </ModalMultipleFileViewer>

  <CreateNoteModal
    v-if="isOpenNote"
    :draw-note-data="drawNoteData"
    @on-close="isOpenNote = false"
    @on-save="onSaveCreateNote"
  />

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onSaveRecorder"
    @on-cancel="isOpenRecording = false"
  />

  <ChangeGroupAndUser
    v-if="taskForClone.id > 0"
    v-permission="'FunctionTaskAssign'"
    :group-id="currentTask?.groupId || null"
    :user-id="currentTask?.assigneeId || getCurrentUserId()"
    :is-loading-save="isUpdatingTask"
    @on-cancel="taskForClone = {}"
    @on-save="onSaveCloneTask"
  >
    <div class="mx-2 border rounded">
      <TaskCardItem :task-detail="currentTask" readonly />
    </div>
  </ChangeGroupAndUser>

  <UpdateDomainModal
    v-if="isOpenUpdateDomainModal"
    :task-detail="{
      ...currentTask,
      assigneeId: changeAssignPayload.assigneeId,
      groupId: changeAssignPayload.groupId,
      domainId: fixConflictDomain.domain ? null : currentTask.domainId,
      projectId:
        fixConflictDomain.domain || fixConflictDomain.project
          ? null
          : currentTask.projectId,
      subprojectId:
        fixConflictDomain.domain ||
        fixConflictDomain.project ||
        fixConflictDomain.subProject
          ? null
          : currentTask.subprojectId,
    }"
    :is-updated="isUpdatingTask"
    @cancel="onCloseUpdateDomainModal"
    @save="onSaveDomainAfterChangeGroup"
  />

  <SetRepeatsModal
    v-if="isOpenSetRepeats"
    :task-repeat="toRaw(currentTask.taskRepeat)"
    :start-date="currentTask.scheduleTime"
    :origin-template="currentTask"
    :is-update-template="isUpdateRepeatTemplate"
    @cancel="isOpenSetRepeats = false"
    @on-save="onSaveRepeats"
  />

  <ApplyWorkFlowModal
    v-if="isOpenWorkFlow"
    :task-workflow="toRaw(currentTask?.taskWorkflow)"
    :task-id="currentTask?.id"
    :task-code="formatTaskCode(currentTask?.code)"
    :domain-id="currentTask?.domainId"
    :group-id="currentTask?.groupId"
    :task-detail="currentTask"
    @cancel="isOpenWorkFlow = false"
    @on-save="onSaveWorkflow"
  />

  <TaskSharingModal
    v-if="isOpenTaskSharing"
    :task="currentTask"
    @on-close="isOpenTaskSharing = false"
  />

  <TaskSnapshotsModal
    v-if="isOpenTaskSnapshots"
    :task="currentTask"
    @on-close="onCloseSnapshot"
  />

  <TaskSetApprovalModal
    v-if="isOpenApprovalModal"
    :task="currentTask"
    @on-close="isOpenApprovalModal = false"
    @on-save="onSetApprover"
  />
  <ReminderCreateModal
    v-if="isOpenReminderModal"
    :type="'TASK'"
    :is-edit="isEditReminder"
    :task-detail="currentTask"
    @cancel="
      isOpenReminderModal = false;
      isEditReminder = false;
    "
    @set-reminder-task-success="onSetReminderForTask"
  />

  <TaskSettingEvidenceModal
    v-if="isOpenSettingFinishEvidenceModal"
    :task="currentTask"
    @on-close="isOpenSettingFinishEvidenceModal = false"
    @on-save="updateEvidenceSettings"
  />

  <SearchTasksModal
    v-if="isOpenSearchTaskModal"
    :title="$t('SUB_TASK_SELECT_A_TASK_TO_MAKE_THIS_TASK_A_SUBTASK')"
    :filter-function="
      (t) => {
        return (
          !t?.parentId &&
          t?.id !== currentTask?.id &&
          t?.id !== currentTask?.parentId
        );
      }
    "
    @on-choose="setParentTask"
    @on-close="isOpenSearchTaskModal = false"
  />
</template>

<style lang="scss">
.new-sticker {
  transform: perspective(10px) rotateX(1deg);
}

.task-detail_description {
  a {
    @apply text-current-500;
  }

  a:hover {
    @apply underline;
  }
}

#tagOptionActivity:not([data-scroll='0']) {
  background-color: transparent !important;
}
</style>
