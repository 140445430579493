<script setup lang="ts">
import { TaskApprovalStatus } from '@/application/types/task/task.types';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { TaskLife } from '@/domain/enums/taskEnum';

const props = defineProps<{
  currentTask: ITaskDetailClass;
  action: any;
}>();

const emit = defineEmits<{
  (e: 'openApprovalModal'): void;
  (e: 'unMaskAsApproval'): void;
  (e: 'cancelApprovalRequest'): void;
}>();

const myProfile = computed<any>(() => {
  return myProfileStore().myProfile;
});
const approvalEnabled = computed<boolean>(() => {
  return !!props.currentTask?.approvalEnabled;
});
const approvalStatus = computed<TaskApprovalStatus>(() => {
  return props.currentTask?.approvalStatus || TaskApprovalStatus.NOT_REQUESTED;
});
const isApprover = computed<any>(() => {
  return props.currentTask?.approvers?.some(
    (user) => user?.userId == myProfile.value?.id
  );
});

const onClick = () => {
  if (!approvalEnabled.value) {
    // mask as approval
    emit('openApprovalModal');
  } else {
    switch (approvalStatus.value) {
      case TaskApprovalStatus.NOT_REQUESTED:
      case TaskApprovalStatus.REJECTED:
        emit('unMaskAsApproval');

        break;
      case TaskApprovalStatus.WAITING:
        emit('cancelApprovalRequest');
        break;

      default:
        break;
    }
  }
};
</script>
<template>
  <div
    v-if="
      !(approvalEnabled && approvalStatus == TaskApprovalStatus.APPROVED) &&
      !(
        currentTask?.approvalStatus == TaskApprovalStatus.WAITING && isApprover
      ) &&
      currentTask?.taskLife !== TaskLife.Finished
    "
    v-permission-function="{
      functionCode: action?.functionCode,
      needToShowPremiumIcon: true,
      onContinue: () => onClick(),
    }"
    class="
      list-li
      flex
      text-gray-600
      justify-start
      items-center
      space-x-2
      text-sm
      relative
    "
    @click="onClick"
  >
    <SynIcon class="fill-gray-500" :name="action?.iconName" />
    <span v-if="!approvalEnabled">
      {{ $t('TASK_LABEL_MASK_AS_NEED_APPROVAL') }}
    </span>
    <span v-else-if="approvalStatus == TaskApprovalStatus.NOT_REQUESTED">
      {{ $t('TASK_LABEL_UNMASK_AS_NEED_APPROVAL') }}
    </span>
    <span v-else-if="approvalStatus == TaskApprovalStatus.WAITING">
      {{ $t('TASK_LABEL_CANCEL_APPROVAL_REQUEST') }}
    </span>
    <span v-else-if="approvalStatus == TaskApprovalStatus.REJECTED">
      {{ $t('TASK_LABEL_UNMASK_AS_NEED_APPROVAL') }}
    </span>
  </div>
</template>
