<script setup lang="ts">
import ApplyWorkFlow from '@/ui/modules/task/workflow/ApplyWorkFlow.vue';
import { ITaskWorkflowEntity } from '@/domain/entities/task/TaskWorkflowEntity';
import { ITaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { ref } from 'vue';

const props = defineProps<{
  taskWorkflow?: ITaskWorkflowEntity;
  taskId?: number;
  taskCode?: string;
  domainId?: number;
  groupId?: number;
  taskDetail: ITaskDetailClass;
}>();

defineEmits<{
  (e: 'cancel'): void;
  (e: 'onSave', data: any): void;
  (e: 'onAttach', data: any): void;
}>();

const applyWorkFlowRef = ref<any>(null);
const onClose = () => {
  if (applyWorkFlowRef.value?.onClose) applyWorkFlowRef.value?.onClose();
};

const currentSize = ref<'SM' | 'MD'>(props.taskWorkflow?.id ? 'MD' : 'SM');
const changeSize = (size: 'SM' | 'MD') => {
  currentSize.value = size || 'SM';
};
</script>
<template>
  <SynModal
    z-index="z-50"
    :container-class="currentSize == 'SM' ? 'min-w-max' : ''"
    disable-click-outside
    is-hidden-footer
    is-hidden-header
    disable-element-active
    header-class="p-4 border-b"
    footer-class="border-t"
    :container-style="
      currentSize == 'SM'
        ? 'max-height: 90%; max-width: 90%;min-width: 48rem'
        : 'height: 90%; width: 90%;min-width: 48rem'
    "
    style-body-string="position: static; overflow: hidden; padding: 0; min-height: 20rem; min-width: 56rem"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :confirm-text="$t('COMMON_LABEL_SAVE')"
    @cancel="onClose"
  >
    <template #header>
      <div class="font-semibold text-current-800">
        {{ $t('COMMON_LABEL_TASK_WORK_FLOW') || 'Task workflow' }}
      </div>
    </template>
    <template #body>
      <ApplyWorkFlow
        ref="applyWorkFlowRef"
        :task-workflow="taskWorkflow"
        :task-id="taskDetail?.id"
        :task-code="taskDetail?.code"
        :domain-id="domainId"
        :group-id="groupId"
        :task-detail="taskDetail"
        @cancel="$emit('cancel')"
        @on-save="(value) => $emit('onSave', value)"
        @on-attach="(value) => $emit('onAttach', value)"
        @change-size="changeSize"
      />
    </template>
  </SynModal>
</template>
