import { convertClassToJSON } from '@/ui/hooks/commonFunction';

export const _prepareDesktopData = (desktopId: string, formData: any) => {
    return {
        id: desktopId,
        title: formData?.title,
        translateX: formData?.translateX,
        translateY: formData?.translateY,
        desktopId,
        shapeGroups: formData?.shapeGroups?.map((data) =>
            convertClassToJSON(data)
        ),
        selectedShapeGroup: convertClassToJSON(formData?.selectedShapeGroup),
        draftShapeGroup: convertClassToJSON(formData?.draftShapeGroup),
        selectedShapeIds: formData?.selectedShapeIds,
        shapeList: formData?.shapeList?.map((data) => convertClassToJSON(data)),
        maxIndex: formData?.maxIndex || 2000,
        status: formData?.status,
        lastModificationTime: formData?.lastModificationTime || Date.now(),
        lastAccessTime: formData?.lastAccessTime || Date.now(),
        configs: formData?.configs,
    };
};
