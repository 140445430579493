import { ask } from './../common/molecules/SynModal/syn-confirm-modal-service';
import { checkPersonalConfig } from '@/ui/hooks/permission/permission-by-function';
import { EFunctionPermission } from '@/application/types/block-function.types';
import { settingGlobalModal } from '../common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '../plugins/i18n/myi18n';

export default {
    install: (app) => {
        app.directive('personal-config', {
            mounted(element, binding) {
                if (!element || !binding.value) return;

                const checkList = Array.isArray(binding.value)
                    ? binding.value
                    : [{ ...binding.value }];
                checkList.forEach((func) => {
                    const functionCode = func?.functionCode;

                    if (functionCode == 'COMING_SOON') {
                        element.style.position = 'relative';
                        const div = _getFrame();

                        element.appendChild(div);
                        div.addEventListener('click', async () => {
                            settingGlobalModal({
                                type: 'notification',
                                title: '',
                                content: translate(
                                    'PERMISSION_BY_FUNCTION_COMING_SOON'
                                ),
                                confirmable: true,
                                confirmLabel: translate('COMMON_LABEL_GOT_IT'),
                                iconName: 'info',
                            });

                            await ask();
                        });
                        return;
                    }
                    const functionValue = func?.functionValue;
                    const neverHidden = func?.neverHidden;

                    const permission = checkPersonalConfig(
                        functionCode,
                        functionValue
                    );
                    switch (permission) {
                        case EFunctionPermission.HIDDEN:
                            if (neverHidden) {
                                element.style.position = 'relative';
                                const div = _getFrame();

                                element.appendChild(div);
                                div.addEventListener('click', async () => {
                                    settingGlobalModal({
                                        type: 'notification',
                                        title: '',
                                        content: translate(
                                            'PERMISSION_BY_FUNCTION_DESCRIPTION'
                                        ),

                                        confirmable: true,
                                        confirmLabel: translate(
                                            'COMMON_LABEL_GOT_IT'
                                        ),
                                        iconName: 'info',
                                    });

                                    await ask();
                                });
                            } else element.parentNode?.removeChild(element);

                            break;

                        default:
                            break;
                    }
                });
            },
        });

        app.provide('checkPersonalConfig', (checkList) => {
            return !checkList?.some((func) => {
                const functionCode = func?.functionCode;
                const functionValue = func?.functionValue;

                const permission = checkPersonalConfig(
                    functionCode,
                    functionValue
                );
                switch (permission) {
                    case EFunctionPermission.HIDDEN:
                        return true;

                    default:
                        return false;
                }
            });
        });
    },
};

const _getFrame = () => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.zIndex = '999';
    div.style.top = '0';
    div.style.left = '0';
    div.style.cursor = 'pointer';

    return div;
};
