<template>
  <div class="flex space-x-2 justify-center">
    <div class="flex content-center justify-center">
      <SynIcon name="tictop-logo" :custom-class="classLogo" />
      <!-- <syn-img :src="srcLogo" :class="classLogo" /> -->
    </div>
    <div class="flex flex-col w-max">
      <!-- <syn-img :src="srcLogoLabel" :class="classLabel" /> -->
      <SynIcon name="tictop-logo-label" :custom-class="classLabel" />
      <div
        class="min-w-max tracking-widest text-current-700 font-thin"
        :class="classSubText"
      >
        Working is fun!
      </div>
    </div>
  </div>
</template>
<script>
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';
const srcLogo = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/logo.svg`;
const srcLogoLabel = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/logo_title.svg`;
export default {
  props: {
    classLogo: {
      type: String,
      default: 'h-14',
    },
    classLabel: {
      type: String,
      default: 'w-28',
    },
    classSubText: {
      type: String,
      default: 'text-sm',
    },
  },
  setup() {
    return {
      srcLogo,
      srcLogoLabel,
    };
  },
};
</script>
