<template>
  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 382.632 382.632"
    style="enable-background: new 0 0 382.632 382.632"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <polygon
            points="118.632,109.948 141.264,87.316 70.632,16.684 0,87.316 22.632,109.948 54.632,77.948 54.632,304.684 
				22.632,272.684 0,295.316 70.632,365.948 141.264,295.316 118.632,272.684 86.632,304.684 86.632,77.948 			"
          />
          <rect x="174.632" y="15.316" width="208" height="32" />
          <rect x="174.632" y="175.316" width="208" height="32" />
          <rect x="174.632" y="95.316" width="144" height="32" />
          <rect x="174.632" y="255.316" width="144" height="32" />
          <rect x="174.632" y="335.316" width="208" height="32" />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
