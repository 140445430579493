<script setup lang="ts">
import { ref } from 'vue';
import { useField } from 'vee-validate';
import { isValidPhoneNumber } from '@/ui/hooks/commonFunction';
import CatalogService from '@/application/services/CatalogService';
import chatExternalStore from '@/store/chat/chat-external';
import chatStore from '@/store/chat';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import router from '@/ui/router';
import { useRoute } from 'vue-router';
import { getCurrentOrganizationId } from '@/ui/hooks/storageHook';
import { checkShowChatWidget } from '@/ui/hooks/system-config/chat.system-config';

const emits = defineEmits(['onClose', 'onExternalChatClick']);

const _useRoute = useRoute();
const _chatStore = chatStore();

const usersResult = ref<any>();
const isSearched = ref<boolean>();

const { value: phoneNumber, errorMessage: phoneError } = useField(
  'data.phone',
  async function (value: string) {
    if (!value || (value && !isValidPhoneNumber(value))) {
      usersResult.value = [];
      isSearched.value = false;
      return false;
    }

    if (isValidPhoneNumber(value) && value && value[0] === '+') {
      const searchRes =
        await CatalogService.getInstance().searchAnyUsersByPhoneAndEmail(value);

      usersResult.value = searchRes?.result;
      isSearched.value = true;
    }
    return true;
  }
) as any;

const onChangePhone = (phoneObj, phoneN) => {
  phoneNumber.value = phoneObj?.number || phoneN;
};

const onExternalUserClick = (user) => {
  let converInfo;

  // If current org, go to internal chat
  if (user?.organizationId === getCurrentOrganizationId()) {
    converInfo = _chatStore.getConversationByContact(user, false);
  }
  // Else, go to external chat
  else {
    converInfo = chatExternalStore().getExternalUserConversation(user);
  }

  if (!checkShowChatWidget()) {
    router.push({
      name: 'Messages',
      params: { conversationId: converInfo?.id },
    });
  } else if (_useRoute?.path?.includes('/messages')) {
    activeAndFocusOnConversationId.value = `${converInfo?.id}`;

    _chatStore.activeBubbleChat(converInfo);
    _chatStore.setConversationActive(converInfo);

    router.replace({ params: { conversationId: converInfo.id } });
  } else {
    _chatStore.addBubbleChat(converInfo);
  }

  emits('onExternalChatClick', converInfo);
};
</script>

<template>
  <SynModal
    z-index="z-50"
    container-class="w-11/12 max-w-md"
    container-style=""
    style-body="px-0 py-2"
    :disable-click-outside="true"
    :is-hidden-footer="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>{{ 'New external chat' }}</div>
    </template>

    <template #body>
      <div class="px-4 mb-2">
        <div class="text-sm text-current-600 font-medium mb-1">
          Enter phone number
        </div>
        <SynTelInput
          :value="phoneNumber"
          :error-message="phoneError"
          autofocus
          @input="onChangePhone"
        />
      </div>
      <div v-if="isSearched && usersResult?.length" class="px-4 mb-2">
        Results ({{ usersResult?.length }}):
      </div>
      <div class="flex-1 overflow-auto small-scrollbar min-h-[30vh]">
        <div
          v-for="user in usersResult"
          :key="user"
          role="button"
          class="flex align-center gap-3 px-4 py-2 hover:bg-gray-50"
          @click="onExternalUserClick(user)"
        >
          <SynAvatar
            :src="user?.avatarUrl"
            :name="user?.fullName"
            custom-class="w-12 h-12"
          />
          <div>
            <div class="font-medium">{{ user?.fullName }}</div>
            <div class="flex align-center gap-2 mt-1">
              <SynAvatar
                :src="user?.organizationLogoUrl"
                :name="user?.organizationName"
                custom-class="w-5 h-5"
              />
              <div class="text-sm text-gray-600">
                {{ user?.organizationName }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="isSearched && !usersResult?.length" class="px-4 italic">
          No data
        </div>
      </div>
    </template>
  </SynModal>
</template>
