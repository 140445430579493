<script setup lang="ts">
import { ref } from 'vue';
import FormAddScope from '@/ui/modules/group/detail/FormAddScope.vue';

defineProps<{
  groupId: number;
}>();

const emit = defineEmits(['onClose', 'onConfirmed']);

const selectedScopes = ref();

const onConfirm = async () => {
  emit('onConfirmed', selectedScopes.value);
};
</script>

<template>
  <SynModal
    :container-style="'max-height: 80%;min-height: 24rem; min-width: 32rem; max-width: 40rem'"
    style-body="p-0"
    z-index="z-50"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <span class="text-black">
        {{ $t('TASK_CREATE_GROUP_FORM_SCOPE_GROUP') }}
      </span>
    </template>
    <template #body>
      <FormAddScope
        :group-id="groupId"
        is-select-scope-only
        @on-selected-scopes-change="selectedScopes = $event"
      />
    </template>
    <template #footer>
      <div class="p-2 flex justify-end items-center">
        <SynButton
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          @click="$emit('onClose')"
        />
        <div class="px-2 hover:text-red-600 cursor-pointer">
          <SynButton
            :disabled="!selectedScopes?.length"
            :label="$t('COMMON_LABEL_CONFIRM')"
            type-fill
            color="current"
            @click="onConfirm"
          />
        </div>
      </div>
    </template>
  </SynModal>
</template>
