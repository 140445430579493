<script setup lang="ts">
import { computed, ref } from 'vue';
import $constants from '@/ui/plugins/constants';
import {
  AudioVideoCallStatusEnum,
  CallParticipantStatus,
} from '@/domain/enums/VideoCallEnum';
import getUserInfo from '@/ui/helpers/user-helper';
import MessageInCallModal from '@/ui/modules/messaging/chat-panel/chat-message/message-call/MessageInCallModal.vue';
import myProfileStore from '@/store/auth/my-profile';

const MessageCallTypeEnum = {
  Ringing: 'ringing',
  Inprogess: 'inprogress',
  Missed: 'missed',
  Hangup: 'hangup',
};

const MessageByCallStatus = {
  ringing: {
    title: 'Calling...',
  },
  inprogress: {
    title: 'On Call...',
  },
  missed: {
    title: 'Missed Call',
  },
  hangup: {
    title: 'Call Ended',
  },
};

const IconNameByTypeConstant = {
  video: {
    ringing: 'VideoIn',
    inprogess: 'VideoIn',
    missed: 'VideoMiss',
    hangup: 'VideoIn',
  },
  audio: {
    ringing: 'CallIn',
    inprogess: 'CallIn',
    missed: 'CallMiss',
    hangup: 'CallIn',
  },
};

const props = defineProps<{
  message: any;
  isMyMessage?: boolean;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
}>();

const callType = computed(
  () => props.message?.call?.type?.toLowerCase() || 'video'
);
const myProfile = computed(() => myProfileStore().myProfile);

const messageCallStatus = computed(() => props.message?.call?.callStatus);
const messageCallMessagesInCall = computed(() => props.message?.call?.messages);
const messageCallParticipants = computed(() => {
  if (!props.message?.call?.participants) return [];
  const idList = Object.keys(props.message?.call?.participants);
  if (!idList || idList.length == 0) return [];
  return idList.map((id) => {
    return getUserInfo(id);
  });
});

const messageParticipantStatus = computed(() => {
  const _participants = props.message?.call?.participants;
  if (!_participants) return 'hangup';
  return _participants[myProfile.value?.id]?.status;
});

const messageCallType = computed(() => {
  if (messageCallStatus.value == AudioVideoCallStatusEnum.Started)
    return MessageCallTypeEnum.Ringing;

  if (messageCallStatus.value == AudioVideoCallStatusEnum.OnCall)
    return messageParticipantStatus.value == CallParticipantStatus.Ringing
      ? MessageCallTypeEnum.Ringing
      : MessageCallTypeEnum.Inprogess;

  if (messageCallStatus.value == AudioVideoCallStatusEnum.FailedToStart)
    return MessageCallTypeEnum.Missed;

  if (messageCallStatus.value == AudioVideoCallStatusEnum.Hangup) {
    let result = MessageCallTypeEnum.Hangup;

    switch (messageParticipantStatus.value) {
      case CallParticipantStatus.FailedToStart:
      case CallParticipantStatus.Ringing:
      case CallParticipantStatus.Missed:
        result = MessageCallTypeEnum.Missed;
        break;

      default:
        break;
    }

    return result;
  }

  return MessageCallTypeEnum.Hangup;
});

const iconNameByType = computed(
  () => IconNameByTypeConstant[`${callType.value}`][`${messageCallType.value}`]
);

const messageTitleByStatus = computed(
  () => MessageByCallStatus[`${messageCallType.value}`]['title']
);

const isOpenChatInCall = ref(false);
</script>

<template>
  <div class="flex" :class="isMyMessage ? 'flex-row-reverse' : 'flex-row'">
    <div
      class="flex flex-col space-y-2 py-2 pr-4 pl-2 rounded"
      :class="{
        'rounded-l-3xl': isMyMessage,
        'rounded-r-3xl': !isMyMessage,
        'rounded-t-3xl': isFirstInGroup,
        'rounded-b-3xl': isLastInGroup,
      }"
      :style="`background-color: #edeff1`"
    >
      <div class="p-0 flex items-start space-x-2">
        <div class="w-10 h-10 p-1 rounded-full flex-center bg-white">
          <SynIcon
            v-if="isMyMessage"
            :name="
              callType === $constants.CALL.TYPE.VIDEO ? 'VideoOut' : 'CallOut'
            "
            custom-class="w-4 h-4"
          />
          <SynIcon v-else :name="iconNameByType" custom-class="w-4 h-4" />
        </div>
        <div class="flex flex-col">
          <span class="font-semibold">
            {{ messageTitleByStatus }}
          </span>
          <div class="text-2xs text-gray-600">
            <span>
              <!-- {{ $filters.dayjs(message?.call?.createdDate, 'hh:mm A') }} -->
              <SynLabelDateTime
                format="time"
                :datetime="message?.call?.createdDate"
              />
            </span>
            <span v-if="message?.call?.actualDuration">
              - Duration:
              {{
                $filters.duration(message?.call?.actualDuration, 'm[m] ss[s]')
              }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="
          messageCallMessagesInCall?.length > 0 ||
          messageCallParticipants?.length > 2
        "
        class="flex justify-between"
      >
        <div>
          <SynListAvatar
            v-if="messageCallParticipants?.length > 2"
            :users="messageCallParticipants"
            :custom-class="'w-6 h-6 text-xs'"
            :max-length="2"
          />
        </div>
        <div v-if="messageCallMessagesInCall?.length > 0">
          <button
            class="h-6 w-8 flex-center hover:fill-current rounded-full relative"
            @click="isOpenChatInCall = true"
          >
            <SynIcon
              name="Message"
              custom-class="h-5 w-5 fill-current text-current-500 p-0"
              size="medium"
              has-action
              is-active
            />
            <!--Un seen conversations count-->
            <div class="absolute -right-1 -top-1 rounded-full bg-white p-0.5">
              <div
                class="
                  rounded-full
                  bg-red-500
                  text-white
                  w-4
                  h-4
                  flex
                  justify-center
                  items-center
                "
                style="font-size: 10px"
              >
                {{ messageCallMessagesInCall.length }}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <MessageInCallModal
    v-if="isOpenChatInCall"
    :messages="messageCallMessagesInCall"
    :created-date="message?.call?.createdDate"
    @close="isOpenChatInCall = false"
  />
</template>
