import { computed, ref } from 'vue';
import { Unsubscribe } from '@firebase/firestore';
import { ChatConversationMemberModel } from '@/application/models/chat/ChatConversationModel';
import ChatConversationRepository from '@/application/repositories/chat/ChatConversationRepository';
import { arrToObj } from '@/ui/helpers/utils';

export default function useChatMembers(organizationId, conversationId, users) {
    let _unsubscribeMembers: Unsubscribe;

    const chatMembers = ref<{
        [memberId: string]: ChatConversationMemberModel;
    }>({});

    const members = computed(() => {
        const userById = arrToObj(users.value, 'id');

        Object.keys(chatMembers.value || {})
            ?.filter(
                (memberId) =>
                    !chatMembers.value[memberId]?.externalChat &&
                    !chatMembers.value[memberId]?.supportingChat
            )
            ?.forEach((memberId) => {
                chatMembers.value[memberId] = {
                    ...chatMembers.value[memberId],
                    ...userById[memberId],
                };
            });

        return chatMembers.value;
    });

    const initMembersFromCache = async (converId) => {
        return _getMembers(converId, true);
    };

    const initMembersFromServer = (converId) => {
        return _getMembers(converId, false);
    };

    const subscribeMembers = (converId) => {
        if (_unsubscribeMembers) _unsubscribeMembers();

        _unsubscribeMembers = ChatConversationRepository.subscribeMembers(
            organizationId.value,
            converId,
            (snapshot) => {
                if (converId !== conversationId.value) return;

                const membersObj = { ...chatMembers.value };

                snapshot.docChanges().forEach((change) => {
                    switch (change?.type) {
                        case 'added':
                        case 'modified':
                            membersObj[change.doc.id] = _prepare(
                                change.doc.id,
                                change.doc.data()
                            );
                            break;
                        case 'removed':
                            membersObj[change.doc.id] = {};
                            break;
                    }
                });

                chatMembers.value = membersObj;
            }
        );
    };

    const resetMembers = () => {
        if (_unsubscribeMembers) _unsubscribeMembers();
    };

    const _getMembers = async (converId, fromCache = false) => {
        const snapshots = await (fromCache
            ? ChatConversationRepository.getMembersFromLocal(
                  organizationId.value,
                  converId
              )
            : ChatConversationRepository.getMembersFromServer(
                  organizationId.value,
                  converId
              ));

        if (converId !== conversationId.value) return;

        const memberObj = {};

        snapshots?.forEach((doc) => {
            memberObj[doc.id] = _prepare(doc.id, doc.data());
        });

        chatMembers.value = memberObj;
    };

    const _prepare = (memberId, member) => {
        if (!member) return null;

        return {
            ...member,
            id: memberId,
            createdDate: member?.createdDate?.toDate
                ? member?.createdDate?.toDate()
                : member?.createdDate,
        };
    };

    return {
        members,
        resetMembers,

        initMembersFromCache,
        initMembersFromServer,
        subscribeMembers,
    };
}
