import { translate } from '@/ui/plugins/i18n/myi18n';

export enum EUpdateType {
    RESPONSIBLE = 'RESPONSIBLE',
    COLLABORATORS = 'COLLABORATORS',
    DOMAIN = 'DOMAIN',
    DEADLINE = 'DEADLINE',
    URGENCY = 'URGENCY',
    APPROVAL = 'APPROVAL',
}
export const TYPE_LIST_BY_IDS = {
    [EUpdateType.RESPONSIBLE]: {
        id: EUpdateType.RESPONSIBLE,
        name: translate('DOMAIN_LABEL_RESPONSIBLE'),
        nameCode: 'DOMAIN_LABEL_RESPONSIBLE',
        elementList: ['RESPONSIBLE'],
    },
    [EUpdateType.COLLABORATORS]: {
        id: EUpdateType.COLLABORATORS,
        name: translate('COMMON_LABEL_COLLABORATORS'),
        nameCode: 'COMMON_LABEL_COLLABORATORS',
        elementList: ['COLLABORATORS'],
    },
    [EUpdateType.DOMAIN]: {
        id: EUpdateType.DOMAIN,
        name: translate('COMMON_LABEL_DOMAIN_PROJECT'),
        nameCode: 'COMMON_LABEL_DOMAIN_PROJECT',
        elementList: ['DOMAIN'],
    },
    [EUpdateType.URGENCY]: {
        id: EUpdateType.URGENCY,
        name: translate('TASK_EXPORT_COLUMNS_URGENCY'),
        nameCode: 'TASK_EXPORT_COLUMNS_URGENCY',
        elementList: ['URGENCY'],
    },
    [EUpdateType.DEADLINE]: {
        id: EUpdateType.DEADLINE,
        name: translate('TASK_UPDATE_MULTIPLE_CHANGE_DEADLINE'),
        nameCode: 'TASK_UPDATE_MULTIPLE_CHANGE_DEADLINE',
        elementList: ['DEADLINE'],
    },
    [EUpdateType.APPROVAL]: {
        id: EUpdateType.APPROVAL,
        nameCode: 'COMMON_LABEL_APPROVAL',
        name: translate('COMMON_LABEL_APPROVAL'),
        elementList: [],
    },
};
