<script setup lang="ts">
// *** IMPORTS ***
import { onMounted, ref } from 'vue';
import ModalCalendarSharingSetup from '@/ui/modules/calendar/calendar-sharing/modal-calendar-sharing-setup/ModalCalendarSharingSetup.vue';
import CalendarService from '@/application/services/CalendarService';
import { CalendarSharingModel } from '@/application/models/calendar/CalendarSharingModel';
import SynLabelDateTime from '@/ui/common/molecules/SynDateTime/SynLabelDateTime.vue';
import CalendarSharingStatus from '@/ui/modules/calendar/calendar-sharing/calendar-sharing-status/CalendarSharingStatus.vue';
import CalendarSharingLink from '@/ui/modules/calendar/calendar-sharing/calendar-sharing-link/CalendarSharingLink.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

// *** PROPS, EMITS ***
defineEmits(['onClose']);

// *** PRIVATE VARIABLES ***
const _baseImageUrl = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web`;

// *** COMPOSABLES ***

// *** REFS ***
const sharings = ref<CalendarSharingModel[]>();
const isOpenSharingSetup = ref<boolean>();
const sharingEditing = ref<CalendarSharingModel | null>();

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***
onMounted(() => {
  _getUserCalendarSharings();
});

// *** HANDLER FUNCTIONS ***
const onSharingAddClick = () => {
  sharingEditing.value = null;
  isOpenSharingSetup.value = true;
};

const onSharingEditClick = (sharing) => {
  sharingEditing.value = sharing;
  isOpenSharingSetup.value = true;
};

const onSharingDeleteClick = async (sharing) => {
  if (!sharing?.id) return;

  settingGlobalModal({
    type: 'warning',
    title: translate('CALENDAR_SHARING_DELETE_MSG'),
    content: '',
    confirmable: true,
    closeable: true,
  });
  const confirmed = await ask();

  if (confirmed) {
    const res = await CalendarService.getInstance().deleteUserCalendarSharing(
      sharing?.id
    );
    if (res?.result) {
      sharings.value = sharings.value?.filter((s) => s?.id !== sharing?.id);
    }
  }
};

const onSharingSaved = (sharing: CalendarSharingModel) => {
  isOpenSharingSetup.value = false;

  // Update local sharing
  const sharingIdx = (sharings.value || []).findIndex(
    (s) => s?.id === sharing?.id
  );
  if (sharingIdx > -1) {
    sharings.value[sharingIdx] = sharing;
  }
  // Add sharing to local
  else {
    sharings.value = [sharing].concat(sharings.value || []);
  }
};

// *** PRIVATE FUNCTIONS ***
const _getUserCalendarSharings = async () => {
  const res = await CalendarService.getInstance().getUserCalendarSharings();
  sharings.value = res?.result;
};

// *** EXPOSES ***
</script>

<template>
  <SynModal
    is-hidden-footer
    container-class="w-11/12 md:w-[47rem]"
    style-body="px-4 pb-4"
    @cancel="$emit('onClose')"
  >
    <template #header> {{ $t('CALENDAR_SHARING') }}</template>
    <template #body>
      <div class="flex-1 overflow-hidden min-h-[20rem]">
        <template v-if="sharings?.length">
          <table class="w-full table-auto mb-3">
            <thead>
              <SynTr>
                <SynTh :label="$t('GED_FILE_LINK')"></SynTh>
                <SynTh :label="$t('COMMON_LABEL_CREATED_DATE')"></SynTh>
                <SynTh :label="$t('COMMON_LABEL_STATUS')"></SynTh>
                <SynTh></SynTh>
              </SynTr>
            </thead>
            <tbody>
              <tr v-for="sharing in sharings" :key="sharing?.id">
                <td class="pr-2 py-1">
                  <CalendarSharingLink
                    class="max-w-[25rem]"
                    :sharing="sharing"
                  />
                </td>
                <td class="px-2 py-1">
                  <SynLabelDateTime :datetime="sharing?.createdDate" />
                </td>
                <td class="px-2 py-1">
                  <CalendarSharingStatus :sharing="sharing" />
                </td>
                <td class="pl-2 py-1">
                  <div class="flex-center flex-nowrap gap-1">
                    <VigButton
                      v-vig-tooltip="$t('CALENDAR_SHARING_UPDATE')"
                      ghost
                      color="gray"
                      icon="Edit"
                      @click="onSharingEditClick(sharing)"
                    />
                    <VigButton
                      v-vig-tooltip="$t('CALENDAR_SHARING_DELETE')"
                      ghost
                      color="red"
                      icon="Trash"
                      @click="onSharingDeleteClick(sharing)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <VigButton @click="onSharingAddClick">
            <SynIcon name="Plus" />
            <span>{{ $t('CALENDAR_SHARING_ADD') }}</span>
          </VigButton>
        </template>

        <div v-else class="h-full flex-center flex-col">
          <VigImage
            :path="_baseImageUrl + '/images/no_file.jpg'"
            custom-class="w-64"
          />
          <VigButton @click="onSharingAddClick">
            <SynIcon name="Plus" />
            <span>{{ $t('CALENDAR_SHARING_ADD') }}</span>
          </VigButton>
        </div>
      </div>
    </template>
  </SynModal>

  <ModalCalendarSharingSetup
    v-if="isOpenSharingSetup"
    :sharing="sharingEditing"
    @on-close="isOpenSharingSetup = false"
    @on-saved="onSharingSaved"
  />
</template>

<style scoped></style>
