<script setup lang="ts">
import { computed } from 'vue';
import userStore from '@/store/user';

const props = defineProps<{
  afterUpdate: any;
}>();

const showMembers = computed(() => {
  return props.afterUpdate?.Collaborators?.map((user) => {
    return {
      ...user,
      id: user?.MemberUserId,
      ...userStore().allUserByIds[user?.id || user?.MemberUserId],
    };
  });
});
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex w-1/2 justify-start pl-4 text-xs">
      <SynListAvatar
        :users="showMembers"
        :custom-class="'w-6 h-6 text-sm'"
        :max-length="5"
      />
    </div>
  </div>
</template>
