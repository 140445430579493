<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import LollipopChart from '@/ui/common/plugins/chart/LollipopChart.vue';
import UserByAction from '@/ui/components/user/UserByAction.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { roundToNearestMultipleOfFive } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  chartData: {
    total: number;
    id: number;
    name: string;
    avatar: string;
  }[];
  options?: any;
}>();

const isLoading = ref<boolean>(true);
const resultData = ref<
  {
    total: number;
    id: number;
    name: string;
    avatar: string;
  }[]
>([]);

const initComponent = async () => {
  isLoading.value = true;
  resultData.value = props.chartData?.filter((o) => o.total > 0);

  prepareDataForChart();
  isLoading.value = false;
};

watch(
  () => props.chartData,
  () => {
    // initComponent();
  }
);

const datasets = ref<any>([]);
const labels = ref<string[]>([]);
const prepareDataForChart = () => {
  xAxisPositions.value = [];

  labels.value = resultData.value?.map((data) => data?.name);

  datasets.value = [
    {
      label: '',
      data: resultData.value?.map((data) => data?.total),
      backgroundColor: 'rgba(75, 176, 178, 0.2)',
      borderColor: 'rgba(75, 176, 178, 0.2)',
      hoverBackgroundColor: 'rgba(75, 176, 178, 0.5)',
      barThickness: 10,
      metaData: resultData.value,
      ...props.options,
    },
  ];
};

const datasetsOptions = computed(() => {
  const maxValue = Math.max(
    ...resultData.value?.map((data) => {
      return Math.max(...[data.total]) || 0;
    })
  );

  return {
    color: props.options?.color,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        border: {
          display: false,
        },
        grid: {
          color: function () {
            return '#e5e7eb';
          },
          drawBorder: false,
        },

        min: 0,
        suggestedMax: roundToNearestMultipleOfFive(maxValue + maxValue * 0.1, {
          min: maxValue + 1,
          isRoundUp: true,
        }),
        grace: '5%',
        ticks: {
          callback: (tickValue) => {
            if (Number.isInteger(tickValue)) return tickValue;
          },
        },
      },
      x: {
        ticks: {
          color: '#fff',
          padding: 10,
        },
        offset: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        displayColors: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: '#6b7280',
        bodyColor: '#6b7280',
        borderWidth: 1,
        borderColor: '#e5e7eb',
        callbacks: {
          label: (tooltipItems) => {
            return (
              tooltipItems?.formattedValue +
              ' ' +
              translate('LABEL_TOTAL_TASK').toLowerCase()
            );
          },
        },
      },
    },
  };
});

const xAxisPositions = ref<any[]>([]);
const xAxisLabelsPlugin = {
  id: 'xAxisLabels',
  afterDatasetsDraw(chart) {
    const {
      ctx,
      data,
      scales: { x, y },
    } = chart;
    ctx.save();
    const metaData = chart.getDatasetMeta(0)._dataset?.metaData;

    for (let i = 0; i < data.labels.length; i++) {
      const xPosition = x.getPixelForValue(i) - 14;
      const yPosition = y.height + 28;
      const id = metaData[i]?.id;
      const userIndex = xAxisPositions.value?.findIndex((o) => o?.id == id);
      if (userIndex > -1) {
        xAxisPositions.value[userIndex] = {
          id,
          top: yPosition,
          left: xPosition,
        };
      } else
        xAxisPositions.value.push({
          id,
          top: yPosition,
          left: xPosition,
        });
    }
  },
};

const hasData = computed<boolean>(() => {
  return resultData.value?.length > 0;
});

initComponent();
</script>
<template>
  <AtomCardLoading v-if="isLoading" />
  <div v-else class="flex-1 w-full h-full flex-center">
    <div class="flex-1 w-full h-full relative flex-center">
      <LollipopChart
        :labels="labels"
        :datasets="datasets"
        :options="datasetsOptions"
        :plugins="[xAxisLabelsPlugin]"
        is-hidden-legend
      />
      <template v-if="hasData">
        <span
          v-for="item in xAxisPositions"
          :key="item?.id"
          class="absolute"
          :style="{
            top: `${item.top}px`,
            left: `${item.left}px`,
          }"
        >
          <UserByAction
            v-if="item.id"
            :user-id="item?.id"
            is-show-action-user
            avatar-class="w-7 h-7"
            is-hidden-name
          />
        </span>
      </template>
    </div>
  </div>
</template>
