<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { keySearch } from '@/ui/modules/task/table/task-list-state';
import taskListStore from '@/store/task/task-list-store';
import { TaskListTab } from '@/domain/enums/taskEnum';
import router from '@/ui/router';
import SynSearch from '@/ui/common/atoms/SynInput/SynSearch.vue';
import ActionExtendListTaskDropdown from '@/ui/modules/task/filter/ActionExtendListTaskDropdown.vue';
import FilterTaskButton from '@/ui/modules/task/filter/FilterTaskButton.vue';
import {
  EFilterTaskDefault,
  ETaskViewType,
} from '@/application/constants/task.const';
import taskTabView from '@/store/task/task-tab-view';
import { ETaskListModule } from '@/application/types/task/task.types';
import { mapValues } from 'lodash';

const props = withDefaults(
  defineProps<{
    currentTab?: TaskListTab;
    currentChildrenTab?: EFilterTaskDefault;
    listTaskOwnerId?: number;
    userId?: number;
    assignorId?: number;
    groupId?: number;
    domainId?: number;
    isHiddenSearchFilter?: boolean;
    isHiddenFilter?: boolean;
    isShowReload?: boolean;
    isAllowImportTask?: boolean;
    actionHidden?: any[];
    disableExtendActions?: boolean;
    actionList?: string[];
    sourceType?: ETaskListModule;
    isHiddenFilterSub?: boolean;
    showFilterAllCheckboxCode?: string;
    isGroupSubTasks?: boolean;
  }>(),
  {
    actionList: () => ['SEARCH', 'FILTER', 'MORE_ACTIONS', 'RELOAD'],
  }
);

const emit = defineEmits<{
  (e: 'onApplyFilter'): void;
  (e: 'onResetFilter'): void;
  (e: 'onShowProfile'): void;
  (e: 'onExportTask'): void;
  (e: 'onImportTask'): void;
  (e: 'onManagerTab'): void;
  (e: 'onReload'): void;
}>();

const _taskListStore = taskListStore();
const searchInputTask = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath.includes('tasks')
  ) {
    searchInputTask.value?.showSearch();
    e.preventDefault();
  }
});

const customizeView = computed<any>(() => {
  return taskTabView().customizeView;
});

const filteredActionList = computed<string[]>(() => {
  let result = props.actionList;
  if (
    customizeView.value !== ETaskViewType.LIST &&
    customizeView.value !== ETaskViewType.GANTT
  )
    result = result.filter((o) => o !== 'SEARCH');
  if (customizeView.value !== ETaskViewType.LIST)
    result = result.filter((o) => o !== 'FILTER');
  if (customizeView.value !== ETaskViewType.LIST)
    result = result.filter((o) => o !== 'MORE_ACTIONS');
  return result;
});

onMounted(() => {});

const clearData = () => {
  keySearch.value = '';
};

const resetKeyword = () => {
  keySearch.value = '';
  if (taskTabView().customizeView === ETaskViewType.LIST)
    _taskListStore.onSearchLocal('');
};

// let timer;

const onTypingSearch = () => {
  if (taskTabView().customizeView === ETaskViewType.LIST) {
    // timer && clearTimeout(timer);
    // 3. start timeout
    // timer = setTimeout(() => {
    _taskListStore.onSearchLocal(keySearch.value);
    // }, 250);
  }
};

const filterTaskButtonRef = ref<any>(null);
const onResetFilter = () => {
  if (filterTaskButtonRef.value?.reset) filterTaskButtonRef.value?.reset();
  emit('onResetFilter');
};

const onFilterSubTasks = (value) => {
  _taskListStore.setFilterDataByKey('subTasks', value);
  _taskListStore.onFilterTaskOnColumn();
};

const hasSubTasks = computed<boolean>(() => {
  return _taskListStore.hasSubTasks && !_taskListStore.filterPayload?.isSubTask;
});
const filterDataBySubTasks = computed<boolean>(() => {
  return !!_taskListStore.filterDataByKey?.subTasks;
});
const filterDataBySubDomain = computed<boolean>(() => {
  return !!_taskListStore.filterDataByKey?.subDomain;
});
const onFilterSubDomainTasks = (value) => {
  _taskListStore.setFilterDataByKey('subDomain', value);
  _taskListStore.onFilterTaskOnColumn();
};

const isCollapseAll = computed<boolean>(() => {
  return Object.values(_taskListStore.parentTaskByIds)?.some(
    (t: any) => t?.isCollapse
  );
});
const isHasParentTasks = computed<boolean>(() => {
  return Object.values(_taskListStore.parentTaskByIds)?.length > 0;
});

const toggleExpandCollapseAll = () => {
  const _isCollapseAll = isCollapseAll.value;
  _taskListStore.parentTaskByIds = mapValues(
    _taskListStore.parentTaskByIds,
    (item) => {
      return {
        ...item,
        isCollapse: !_isCollapseAll,
      };
    }
  );
};
defineExpose({
  reset: clearData,
});
</script>

<template>
  <div class="flex-center px-2 space-x-1">
    <!-- <div
      class="p-1"
      :class="keySearch ? 'bg-gradient-to-r from-current-300 to-teal-400' : ''"
    >
      
    </div> -->
    <div
      v-if="sourceType == ETaskListModule.DOMAIN && !isHiddenFilterSub"
      class="px-1"
    >
      <AtomSwitch
        :model-value="filterDataBySubDomain"
        :label="$t('TASK_LIST_HIDDEN_SUB_DOMAIN_TASKS')"
        size="small"
        @update:model-value="onFilterSubDomainTasks"
      />
    </div>
    <div v-if="isGroupSubTasks && isHasParentTasks" class="px-1">
      <div
        v-vig-tooltip="
          $t(
            isCollapseAll
              ? 'COMMON_LABEL_EXPAND_ALL'
              : 'COMMON_LABEL_COLLAPSE_ALL'
          )
        "
        class="
          w-8
          h-8
          rounded-md
          cursor-pointer
          flex-center
          border border-gray-100
          hover:shadow
        "
        @click.stop="toggleExpandCollapseAll"
      >
        <SynIcon
          :name="isCollapseAll ? 'zoom-in2' : 'zoom-out2'"
          :custom-class="`h-4 w-4 fill-gray-500`"
        />
      </div>
    </div>
    <div v-if="hasSubTasks" class="px-1">
      <AtomSwitch
        :model-value="filterDataBySubTasks"
        :label="$t('TASK_LIST_HIDDEN_SUB_TASKS')"
        size="small"
        @update:model-value="onFilterSubTasks"
      />
    </div>
    <SynSearch
      v-if="filteredActionList?.includes('SEARCH')"
      ref="searchInputTask"
      v-model="keySearch"
      is-show-short-cut-key
      custom-class="fill-gray text-gray-500 hover:bg-gray-100"
      icon-prefix="search"
      :placeholder="$t('COMMON_LABEL_SEARCH_PLACEHOLDER_TASK')"
      autofocus
      @change="onTypingSearch"
      @enter="onTypingSearch"
    >
      <template v-if="keySearch?.trim()?.length > 0" #suffix>
        <SynIcon
          has-action
          name="close2"
          custom-class="rounded-full w-3 h-3"
          @click="resetKeyword"
        />
      </template>
    </SynSearch>

    <!-- FILTER -->
    <FilterTaskButton
      v-if="filteredActionList?.includes('FILTER')"
      ref="filterTaskButtonRef"
      :list-task-owner-id="listTaskOwnerId"
      :user-id="userId"
      :group-id="groupId"
      :assignor-id="assignorId"
      :domain-id="domainId"
      :current-children-tab="currentChildrenTab"
      :current-tab="currentTab"
      :show-filter-all-checkbox-code="showFilterAllCheckboxCode"
      @on-apply-filter="$emit('onApplyFilter')"
      @on-reset-filter="onResetFilter"
    />

    <ActionExtendListTaskDropdown
      v-if="
        filteredActionList?.includes('MORE_ACTIONS') && !disableExtendActions
      "
      :current-tab="currentTab"
      :current-children-tab="currentChildrenTab"
      :action-hidden="actionHidden"
      :group-id="groupId"
      :domain-id="domainId"
      :user-id="userId"
      :is-allow-import-task="isAllowImportTask"
      @on-show-profile="$emit('onShowProfile')"
      @on-export-task="$emit('onExportTask')"
      @on-import-task="$emit('onImportTask')"
      @on-manager-tab="$emit('onManagerTab')"
    />

    <div
      v-if="filteredActionList?.includes('RELOAD')"
      class="w-8 h-8 flex-center rounded-full hover:bg-gray-100 cursor-pointer"
      @click="$emit('onReload')"
    >
      <SynIcon
        class="text-gray-600 fill-gray-500"
        name="reload"
        :title="$t('TASK_LIST_LABEL_RELOAD') || 'Reload'"
      />
    </div>

    <slot name="tabs"></slot>
    <slot name="otherAction"></slot>
    <slot name="suffix"></slot>
  </div>
</template>
