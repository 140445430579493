<template>
  <div class="flex-center flex-wrap space-x-1 font-medium">
    <div>
      <div
        class="
          font-medium
          px-3
          py-2
          text-sm
          hover:bg-gray-100
          rounded
          xl:rounded-full
          flex-center
          cursor-pointer
          bg-current-50
          text-current-800
          fill-current
        "
      >
        <span>Remove group department</span>
      </div>
    </div>
    <div>
      <div
        class="
          font-medium
          px-3
          py-2
          text-sm
          hover:bg-gray-100
          rounded
          xl:rounded-full
          flex-center
          cursor-pointer
          fill-gray-500
        "
      >
        <span>P. Marketing 2</span>
      </div>
    </div>
    <div>
      <div
        class="
          font-medium
          px-3
          py-2
          text-sm
          hover:bg-gray-100
          rounded
          xl:rounded-full
          flex-center
          cursor-pointer
          fill-gray-500
        "
      >
        <span>P. Nhân sự</span>
      </div>
    </div>
    <div>
      <div
        class="
          font-medium
          px-3
          py-2
          text-sm
          hover:bg-gray-100
          rounded
          xl:rounded-full
          flex-center
          cursor-pointer
          fill-gray-500
        "
      >
        <span>Phòng Nhập Liệu</span>
      </div>
    </div>

    <!--v-if-->
  </div>
</template>
