<script setup lang="ts">
import { useRoute } from 'vue-router';
import Register from '@/ui/pages/auth/Register.vue';
import RegisterProcessD from '@/ui/pages/auth/RegisterProcessD.vue';
import AuthService from '@/application/services/AuthService';
import { computed, ref } from 'vue';
import organizationStore from '@/store/organization';

const route = useRoute();
const inviteCode = computed(() => route.query?.inviteCode?.toString() || '');
const registerWithProcess = ref<{
  currentProcess: 'A' | 'B' | 'C' | 'D';
  allowPersonal: boolean;
}>({
  currentProcess: 'A',
  allowPersonal: true,
});

const initComponent = async () => {
  const res = await AuthService.getInstance().getCurrentSubscriptionProcess();

  registerWithProcess.value = res?.result;

  if (registerWithProcess.value?.currentProcess == 'D') {
    organizationStore().getOrganizationConfigDefault();
  }
};

initComponent();
</script>
<template>
  <RegisterProcessD
    v-if="registerWithProcess?.currentProcess == 'D' || inviteCode"
    :not-allow-personal="!registerWithProcess?.allowPersonal"
  />
  <Register v-else :process-mode="registerWithProcess?.currentProcess" />
</template>
