<script setup lang="ts">
import { computed, ref } from 'vue';
import { isOpenUserBar } from '../../../hooks/useSidebar';
import { useRoute, useRouter } from 'vue-router';

const activeClass = ref('text-current-600 hover:bg-gray-100');
const inactiveClass = ref('hover:bg-gray-100 transform hover:translate-x-1');
const route = useRoute();
const router = useRouter();
const currentRoute = computed(() => route.name);
const isOverview = computed(() => router.currentRoute.value.meta.isOverview);
const isActive = (name) => {
  return currentRoute.value == name;
};
// todo: should get from router.js
const routerList = ref([
  {
    name: 'OrganizationHome',
    meta: {
      titleKey: 'COMMON_MODULE_OVERVIEW',
      iconName: 'home',
    },
  },
  {
    path: 'project-management',
    name: 'SetupProjectManagement',
    meta: {
      titleKey: 'COMMON_MODULE_PROJECT_MANAGEMENT',
      title: 'ProjectManagement',
      layout: 'dashboard',
      iconName: 'project',
    },
  },
  // {
  //   path: 'settings',
  //   name: 'SetupSettings',
  //   meta: {
  //     titleKey: 'COMMON_LABEL_SETTINGS',
  //     title: 'Settings',
  //     layout: 'dashboard',
  //     iconName: 'setting',
  //   },
  // },
  {
    path: 'video-tutorial',
    name: 'VideoTutorial',
    meta: {
      titleKey: 'COMMON_LABEL_VIDEO_TUTORIAL',
      title: 'Videos Tutorial',
      layout: 'SetupTeam',
      iconName: 'video-tutorial',
    },
  },
]);
const handleClickNav = (item) => {
  isOpenUserBar.value = false;
  item.isShowChild = !item.isShowChild;
  if (item.children && item.children.length > 0) {
    return;
  }
  router.push({ name: item.name });
};
</script>

<template>
  <div
    class="flex w-full md:w-64 h-full overflow-y-auto"
    :class="[
      isOverview ? 'absolute z-40 transition duration-300 transform' : '',
      isOpenUserBar ? 'translate-x-0 ease-out' : '-translate-x-full ease-in',
    ]"
  >
    <div
      class="
        h-full
        bg-white
        fixed
        shadow-lg
        w-full
        z-30
        inset-x-0
        bottom-0
        overflow-x-auto
        lg:static lg:inset-0
      "
    >
      <nav class="flex-1 max-h-full overflow-y-hidden cursor-pointer">
        <ul
          class="
            max-h-full
            overflow-y-auto overflow-x-hidden
            neumorphism-shadow
          "
        >
          <!-- Dashboard link -->
          <li
            v-for="item in routerList.filter((o) => $permission(o.name))"
            :key="item.name"
            class="hover:text-current-400 hover:fill-current"
          >
            <div
              class="
                transition
                duration-200
                h-16
                bg-transparent
                flex
                items-center
                w-full
                m-2
                rounded-lg
                p-2
              "
              :class="[isActive(item.name) ? activeClass : inactiveClass]"
              @click="
                () => {
                  handleClickNav(item);
                }
              "
            >
              <div class="h-9 w-9 flex-center bg-gray-100 rounded-full">
                <SynIcon
                  class="m-auto lg:m-0 w-5 h-5"
                  :name="item.meta.iconName"
                  :is-active="isActive(item.name)"
                />
              </div>
              <span class="ml-2 font-medium text-sm">
                {{ $t(item.meta?.titleKey) || item.meta?.title }}
              </span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
