import TaskSharingRepository from '@/application/repositories/TaskSharingRepository';
import { TaskSharingPermission } from '@/domain/enums/taskEnum';
import { getDomainUrl, openNotification } from '@/ui/hooks/commonHook';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';

class TaskSharingService {
    sharingPermissions = [
        {
            id: TaskSharingPermission.TASK_VIEW_ONLY,
            text: 'TASK_SHARING_PERMISSION_VIEW',
            disabled: false,
        },
        {
            id: TaskSharingPermission.TASK_ADD_ALLOWED,
            text: 'TASK_SHARING_PERMISSION_ADD',
            disabled: true,
            children: [
                {
                    id: TaskSharingPermission.TASK_ADD_DESCRIPTION,
                    text: 'COMMON_LABEL_DESCRIPTION',
                },
                {
                    id: TaskSharingPermission.TASK_ADD_FILE,
                    text: 'COMMON_LABEL_FILE',
                },
                {
                    id: TaskSharingPermission.TASK_ADD_NOTE,
                    text: 'COMMON_LABEL_NOTE',
                },
                {
                    id: TaskSharingPermission.TASK_ADD_AUDIO,
                    text: 'COMMON_LABEL_AUDIO',
                },
                {
                    id: TaskSharingPermission.TASK_ADD_CHECKLIST,
                    text: 'COMMON_LABEL_CHECK_LIST',
                },
                {
                    id: TaskSharingPermission.TASK_ADD_COMMENT,
                    text: 'TASK_ACTIVITY_LABEL_COMMENT',
                },
            ],
        },
        {
            id: TaskSharingPermission.TASK_MODIFY_ALLOWED,
            text: 'TASK_SHARING_PERMISSION_MODIFY',
            disabled: true,
            children: [
                {
                    id: TaskSharingPermission.TASK_MODIFY_STATUS,
                    text: 'COMMON_LABEL_STATUS',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_TITLE,
                    text: 'COMMON_LABEL_TITLE',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_URGENCY,
                    text: 'TASK_TABLE_LABEL_URGENCY',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_DEADLINE,
                    text: 'TASK_TABLE_LABEL_DEADLINE',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_DESCRIPTION,
                    text: 'COMMON_LABEL_DESCRIPTION',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_FILE,
                    text: 'COMMON_LABEL_FILE',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_NOTE,
                    text: 'COMMON_LABEL_NOTE',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_AUDIO,
                    text: 'COMMON_LABEL_AUDIO',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_CHECKLIST,
                    text: 'COMMON_LABEL_CHECK_LIST',
                },
                {
                    id: TaskSharingPermission.TASK_MODIFY_COMMENT,
                    text: 'TASK_ACTIVITY_LABEL_COMMENT',
                },
            ],
        },
    ];

    async getTaskSharedByTaskId(taskId) {
        const res = await TaskSharingRepository.getTaskSharedByTaskId(taskId);
        return res?.result;
    }

    async saveTaskShared(data) {
        const res = await TaskSharingRepository.saveTaskShared(data);
        return res?.result;
    }

    async removeTaskShared(data) {
        const res = await TaskSharingRepository.removeTaskShared(data);
        return res?.success;
    }

    async getTaskSnapshots(taskId) {
        const res = await TaskSharingRepository.getTaskSnapshots(taskId);
        return res?.result;
    }

    async saveTaskSnapshot(data) {
        const res = await TaskSharingRepository.saveTaskSnapshot(data);
        return res?.result;
    }

    async removeTaskSnapshot(data) {
        const res = await TaskSharingRepository.removeTaskSnapshot(data);
        return res?.result;
    }

    copySharedTaskLink(sharedId: string) {
        const sharingUrl = this.prepareSharedTaskLink(sharedId);

        const copied = copyToClipBroard(sharingUrl);

        if (copied) {
            openNotification({
                body: translate('TASK_SHARING_URL_COPIED_MSG'),
                duration: 2000,
            });
        }
    }

    prepareSharedTaskLink(sharedId: string) {
        if (!sharedId) return;

        const domain = getDomainUrl();
        return `${domain}/shared/task/${sharedId}`;
    }

    async deleteTaskSnapshot(snapshot: any) {
        settingGlobalModal({
            type: 'confirm',
            title: translate('TASK_SNAPSHOT_DELETE_MSG'),
            confirmable: true,
            confirmLabel: translate('COMMON_LABEL_CONFIRM') || 'Confirm',
            closeable: true,
        });

        const confirmed = await ask();

        if (confirmed) {
            await this.removeTaskSnapshot(snapshot);
            return true;
        }
    }
}

export default new TaskSharingService();
