export async function copyImageToClipBroard(arrayBuffer) {
    if (!arrayBuffer) return false;

    try {
        const contentType = 'image/png';
        const blobData = new Blob([arrayBuffer], { type: contentType });

        const item = new window.ClipboardItem({ ['image/png']: blobData });
        await navigator.clipboard.write([item]);

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}
export function getBlobData(arrayBuffer, contentType = 'image/png') {
    if (!arrayBuffer) return false;

    try {
        return new Blob([arrayBuffer], { type: contentType });
    } catch (error) {
        console.log(error);
        return false;
    }
}
