<script setup language="ts">
import { onMounted, watch, ref } from 'vue';
import FileAttachment from '@/ui/modules/ged/FileAttachment.vue';
import VigButton from '@/ui/common/atoms/VigButton/VigButton.vue';
const props = defineProps({
  files: {
    type: Array,
    default: () => [],
  },
  size: {
    type: String,
    default: '',
  },
});

defineEmits(['onFileDelete', 'onFileClick']);

const filesInMessage = ref();

watch(
  () => props.files,
  () => {
    filesInMessage.value = props.files;
  }
);

onMounted(() => {
  filesInMessage.value = props.files;
});
</script>

<template>
  <div
    class="flex overflow-auto small-scrollbar mx-2"
    :class="size === 'sm' ? 'p-1 pt-2' : 'p-2 pt-3'"
  >
    <div v-for="file in filesInMessage" :key="file" class="relative mr-3 group">
      <FileAttachment
        :file="file"
        class="cursor-pointer"
        @click="$emit('onFileClick', file)"
      />

      <div class="absolute -right-2 -top-2 rounded-full bg-white border">
        <vig-button
          type="button"
          color="gray"
          padding="p-0"
          rounded="rounded-full"
          class="w-5 h-5 hidden group-hover:flex"
          @click="$emit('onFileDelete', file)"
        >
          <SynIcon name="Close" />
        </vig-button>
        <vig-button
          type="button"
          ghost
          color="gray"
          padding="p-0"
          rounded="rounded-full"
          class="w-5 h-5 group-hover:hidden"
        >
          <SynIcon
            v-if="file?.drawData"
            name="noter"
            is-active
            class="fill-orange-500"
          />
          <SynIcon v-else name="Attach" />
        </vig-button>
      </div>
    </div>
  </div>
</template>
