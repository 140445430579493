<script setup lang="ts">
import { computed } from 'vue';
import domainStore from '@/store/scope';
// import { DomainStatus } from '@/domain/enums/domain-enums';

const props = defineProps<{
  projectId: number | string;
}>();

const _domainStore = domainStore();
const projectByIds = computed(() => _domainStore.projectByIds);
const projectDetail = computed(() => projectByIds.value[props.projectId]);
</script>

<template>
  <span
    v-if="projectDetail"
    v-vig-tooltip="projectDetail?.name"
    class="flex-center space-x-2 w-max truncate"
  >
    <span class="truncate">
      {{ projectDetail?.name }}
    </span>
    <slot name="subName"></slot>

    <!--    <div v-if="listSubProjectsId.length > 0">-->
    <!--      &lt;!&ndash;      <div v-for="item in listSubProjectsId">&ndash;&gt;-->
    <!--      &lt;!&ndash;        &ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--    </div>-->

    <!--    <span v-if="projectDetail"> Not project </span>-->
    <!-- <span
      class="flex-center"
      :title="
        $t('DOMAIN_NEED_TO_VERIFY_EXPLAIN') ||
        'Domain or Project which was created by user need to verified'
      "
    >
      <SynIcon
        v-if="projectDetail?.status === DomainStatus.Created"
        name="warning"
        class="fill-orange-500 flex-none"
      />
    </span> -->
  </span>
</template>
