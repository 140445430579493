<template>
  <svg
    id="Group_3021"
    data-name="Group 3021"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_1005"
      data-name="Path 1005"
      d="M415.265,376.85a.625.625,0,0,0-.875.128c-.113.152-.235.3-.36.444a.625.625,0,1,0,.936.828c.149-.169.293-.346.427-.526A.625.625,0,0,0,415.265,376.85Z"
      transform="translate(-400.94 -364.954)"
    />
    <path
      id="Path_1006"
      data-name="Path 1006"
      d="M463.432,286.439a.625.625,0,0,0-.744.478c-.04.186-.089.371-.145.553a.625.625,0,1,0,1.194.369c.066-.215.124-.436.172-.656A.625.625,0,0,0,463.432,286.439Z"
      transform="translate(-448.062 -277.474)"
    />
    <path
      id="Path_1007"
      data-name="Path 1007"
      d="M334.9,442.273c-.166.093-.338.179-.511.257a.625.625,0,1,0,.514,1.139c.205-.093.409-.2.606-.305a.625.625,0,1,0-.609-1.091Z"
      transform="translate(-323.587 -428.375)"
    />
    <path
      id="Path_1008"
      data-name="Path 1008"
      d="M159.278,76.625v4.741l-2.292,2.292a.625.625,0,0,0,.884.884l2.475-2.475a.625.625,0,0,0,.183-.442v-5a.625.625,0,0,0-1.25,0Z"
      transform="translate(-151.903 -73.625)"
    />
    <path
      id="Path_1009"
      data-name="Path 1009"
      d="M15.375,1.344a.625.625,0,0,0-.625.625V3.7A8,8,0,1,0,8,16h.031c.225,0,.453-.009.676-.028a.625.625,0,1,0-.1-1.246c-.189.016-.381.024-.572.024H8A6.75,6.75,0,1,1,13.811,4.563H12.092a.625.625,0,0,0,0,1.25H14a1.988,1.988,0,0,0,1.04-.292.624.624,0,0,0,.056-.036A2,2,0,0,0,16,3.813V1.969A.625.625,0,0,0,15.375,1.344Z"
    />
  </svg>
</template>
