<script setup lang="ts">
import CatalogService from '@/application/services/CatalogService';
import paymentStore from '@/store/payment';
import DecidePaymentHeaderSvg from '@/ui/modules/payments/DecidePaymentHeaderSvg.vue';

import { computed, ref } from 'vue';
import { IPaymentConfig } from '@/application/types/payment-configs.types';
import remoteConfigStore from '@/store/remoteConfig';
import { IPaymentVoucherDetail } from '@/application/types/payment.types';
import {
  ask,
  settingGlobalModal,
  tell,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import datetimeComposable from '@/ui/composables/common/datetime-composable';

const emit = defineEmits(['handleSelectTrial', 'handleSelectPay']);
const _paymentStore = paymentStore();

const offerStartOptions = computed<string>(
  () => remoteConfigStore().paymentOfferStartOptions
);
const totalOrganization = computed<number>(
  () => remoteConfigStore().totalOrganization
);
const availableVouchers = computed<IPaymentVoucherDetail[] | null>(
  () => _paymentStore.availableVouchers
);
const paymentConfigs = computed<IPaymentConfig>(
  () => _paymentStore.paymentConfigs
);

const isShowConfirmUsingTrial = ref<boolean>(false);
const handleUsingTrial = async () => {
  try {
    isShowConfirmUsingTrial.value = true;
    settingGlobalModal({
      type: 'confirm',
      isHiddenIcon: true,
      title: translate('PAYMENT_LABEL_USE_TRIAL'),
      content: translate('PAYMENT_LABEL_CONFIRM_USE_TRIAL_CONTENT'),
      footerButtons: [
        {
          type: 'TEXT',
          labelCode: 'COMMON_LABEL_BACK',
          color: 'gray',
          onClick: () => {
            isShowConfirmUsingTrial.value = false;
            tell(false);
          },
        },

        {
          type: 'FILL',
          labelCode: translate('PAYMENT_LABEL_START_TRIAL', {
            totalDays: paymentConfigs.value?.paymentBonus?.defaultTrial || 7,
          }),
          color: 'current',
          onClick: async () => {
            await CatalogService.getInstance().usingTrialPackage('PRO');
            emit('handleSelectTrial', 'PRO');
            isShowConfirmUsingTrial.value = false;
            tell(true);
          },
        },
      ],
    });
    return ask();
  } catch (error) {
    console.log('🚀 Hyrin ~ handleUsingTrial ~ error:', error);
  }
};

const isHasVoucher = computed<boolean>(() => {
  return availableVouchers.value && availableVouchers.value?.length > 0;
});

const zIndex = ref<number>(9999);

const { formatDate } = datetimeComposable();
const openConfirmTrialFirst = () => {
  zIndex.value = 50;

  if (availableVouchers.value && availableVouchers.value?.length > 0) {
    const voucher = availableVouchers.value[0];
    const expiredDate = formatDate(voucher?.validTo, 'date', {
      isIgnoreTimezone: true,
    });
    settingGlobalModal({
      type: 'confirm',
      title: translate('DECIDE_PAYMENT_HAS_VOUCHER_CONFIRM_SELECT_TRIAL_TITLE'),
      content: translate('DECIDE_PAYMENT_HAS_VOUCHER_CONFIRM_SELECT_TRIAL', {
        expiredDate,
      }),
      footerButtons: [
        {
          type: 'TEXT',
          labelCode: 'COMMON_LABEL_CLOSE',
          color: 'gray',
          onClick: () => {
            zIndex.value = 9999;
            tell(false);
          },
        },

        {
          type: 'FILL',
          labelCode: 'COMMON_LABEL_GOT_IT',
          color: 'current',
          onClick: () => {
            // select trial
            handleUsingTrial();
            tell(true);
          },
        },
      ],
    });

    ask();
  } else {
    // select trial
    handleUsingTrial();
  }
};

const handleSelectPay = () => {
  zIndex.value = 50;
  emit('handleSelectPay');
};
</script>
<template>
  <teleport v-if="!isShowConfirmUsingTrial" to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
      "
      :style="`background: rgba(0, 0, 0, 0.7); z-index: ${zIndex}`"
    >
      <div class="flex flex-col bg-white rounded-xl" style="width: 40rem">
        <!-- HEADER -->
        <section
          class="
            flex-center
            px-8
            py-10
            flex-col
            bg-gradient-to-r
            from-current
            to-green-500
            relative
            rounded-t-xl
          "
        >
          <div class="-mt-20">
            <DecidePaymentHeaderSvg />
          </div>
        </section>
        <!-- BODY -->
        <section
          class="flex-center p-8 pt-6 flex-col space-y-10 text-current-800"
        >
          <!-- MESSAGE -->
          <div class="w-full flex flex-col space-y-2">
            <span
              class="text-center italic"
              style="font-size: 2rem; font-family: serif"
            >
              {{ $t('COMMON_LABEL_CONGRATULATIONS') || 'Congratulations' }}!
            </span>
            <span class="flex flex-col">
              <span
                class="text-sm text-center"
                v-html="
                  $t('DECIDE_PAYMENT_HEADER', {
                    totalOrganization: totalOrganization || 5000,
                  })
                "
              >
              </span>
            </span>
          </div>
          <!-- PAY WITH VOUCHER -->
          <div v-if="isHasVoucher" class="flex-center space-x-4">
            <!-- PAY NOW -->
            <div
              class="
                w-full
                flex-center flex-col
                rounded-xl
                shadow-md
                p-4
                bg-gradient-to-r
                from-[#12B99C]
                to-[#49B0B1]
                relative
              "
            >
              <div class="absolute -top-10">
                <SynIcon
                  name="crown"
                  custom-class=" w-16 h-16"
                  class="fill-orange-500"
                />
              </div>
              <div
                class="
                  flex-1
                  w-full
                  rounded-md
                  bg-[#4CBFB3]
                  text-white
                  py-8
                  px-4
                  mb-4
                  text-center
                "
              >
                <span
                  v-html="
                    $t('DECIDE_PAYMENT_PAY_DESCRIPTION_PAY_WITH_VOUCHER', {
                      percent: 100,
                      maximum: '20 triệu',
                    })
                  "
                >
                </span>
              </div>
              <button
                class="
                  w-full
                  bg-white
                  text-current-500
                  rounded
                  py-4
                  shadow-md
                  hover:bg-current-50
                "
                @click="$emit('handleSelectPay')"
              >
                <span class="text-current-500">
                  {{ $t('DECIDE_PAYMENT_SIMPLE_PAY_WITH_VOUCHER_BUTTON') }}
                </span>
              </button>
              <span
                class="
                  underline
                  text-white
                  pt-4
                  cursor-pointer
                  text-sm
                  hover:text-gray-200
                "
                @click="openConfirmTrialFirst"
              >
                {{ $t('DECIDE_PAYMENT_WANT_TO_EXPERIENCE_FIRST') }}
              </span>
            </div>
          </div>
          <!-- ONLY_TRIAL -->
          <div
            v-else-if="offerStartOptions == 'ONLY_TRIAL'"
            class="flex-center space-x-4"
          >
            <!-- TRIAL -->
            <div
              class="
                w-full
                h-60
                flex-center flex-col
                rounded-xl
                shadow-md
                p-4
                bg-gradient-to-r
                from-[#12B99C]
                to-[#49B0B1]
                relative
              "
            >
              <div class="absolute -top-10">
                <SynIcon
                  name="crown"
                  custom-class=" w-16 h-16"
                  class="fill-orange-500"
                />
              </div>
              <div
                class="
                  flex-1
                  w-full
                  rounded-md
                  bg-[#4CBFB3]
                  text-white
                  py-8
                  px-4
                  mb-4
                  text-center
                "
              >
                <span
                  v-html="
                    $t(
                      'DECIDE_PAYMENT_CONTINUE_WITH_TRIAL_DESCRIPTION_ONLY_TRIAL',
                      {
                        bonusDays:
                          paymentConfigs?.paymentBonus?.defaultTrial || 30,
                      }
                    )
                  "
                >
                </span>
              </div>
              <button
                class="
                  w-full
                  bg-current-50
                  text-current-500
                  rounded
                  py-4
                  shadow-md
                  hover:bg-current-100
                "
                @click="handleUsingTrial"
              >
                <span
                  class="text-current-500"
                  v-html="
                    $t(
                      'DECIDE_PAYMENT_CONTINUE_WITH_TRIAL_BUTTON_ONLY_TRIAL'
                    ) || 'START FREE NOW'
                  "
                >
                </span>
              </button>
            </div>
          </div>

          <!-- ONLY_PAY_NOW -->
          <div
            v-else-if="offerStartOptions == 'ONLY_PAY_NOW'"
            class="flex-center space-x-4"
          >
            <!-- PAY NOW -->
            <div
              class="
                w-full
                h-60
                flex-center flex-col
                rounded-xl
                shadow-md
                p-4
                bg-gradient-to-r
                from-[#12B99C]
                to-[#49B0B1]
                relative
              "
            >
              <div class="absolute -top-10">
                <SynIcon
                  name="crown"
                  custom-class=" w-16 h-16"
                  class="fill-orange-500"
                />
              </div>
              <div
                class="
                  flex-1
                  w-full
                  rounded-md
                  bg-[#4CBFB3]
                  text-white
                  py-8
                  px-4
                  mb-4
                  text-center
                "
              >
                <span
                  v-html="
                    $t('DECIDE_PAYMENT_PAY_DESCRIPTION_ONLY_PAY_NOW', {
                      bonusDays:
                        paymentConfigs?.paymentBonus?.payWhenRegister || 60,
                    })
                  "
                >
                </span>
              </div>
              <button
                class="
                  w-full
                  bg-white
                  text-current-500
                  rounded
                  py-4
                  shadow-md
                  hover:bg-current-50
                "
                @click="handleSelectPay"
              >
                <span class="text-current-500 uppercase">
                  {{ $t('PAYMENT_LABEL_SELECT_PLAN') }}
                </span>
              </button>
            </div>
          </div>
          <!-- ALL -->
          <div v-else class="flex-center space-x-4">
            <!-- TRIAL -->
            <div
              class="
                w-1/2
                h-60
                flex-center flex-col
                space-y-4
                rounded-xl
                shadow-md
                border
                p-4
              "
            >
              <div
                class="
                  flex-1
                  w-full
                  flex-center
                  rounded-md
                  bg-gray-50
                  text-current
                  p-4
                "
              >
                <span
                  class="text-sm"
                  v-html="
                    $t('DECIDE_PAYMENT_PAY_DESCRIPTION', {
                      bonusDays:
                        paymentConfigs?.paymentBonus?.payWhenRegister || 60,
                    })
                  "
                >
                </span>
              </div>
              <button
                class="
                  w-full
                  bg-current-50
                  text-current-500
                  rounded
                  py-4
                  shadow-md
                  hover:bg-current-100
                "
                @click="handleSelectPay"
              >
                <span class="text-current-500 uppercase">
                  {{ $t('PAYMENT_LABEL_SELECT_PLAN') }}
                </span>
              </button>
            </div>
            <!-- PAY NOW -->
            <div
              class="
                w-1/2
                h-60
                flex-center flex-col
                rounded-xl
                shadow-md
                p-4
                bg-gradient-to-r
                from-[#12B99C]
                to-[#49B0B1]
                relative
              "
            >
              <div class="absolute -top-10">
                <SynIcon
                  name="crown"
                  custom-class=" w-16 h-16"
                  class="fill-orange-500"
                />
              </div>
              <div
                class="
                  flex-1
                  w-full
                  rounded-md
                  bg-[#4CBFB3]
                  text-white
                  py-4
                  px-4
                  mb-4
                "
              >
                <span
                  class="text-sm"
                  v-html="
                    $t('DECIDE_PAYMENT_CONTINUE_WITH_TRIAL_DESCRIPTION', {
                      bonusDays:
                        paymentConfigs?.paymentBonus?.defaultTrial || 30,
                    })
                  "
                >
                </span>
              </div>
              <button
                class="
                  w-full
                  bg-white
                  text-current-500
                  rounded
                  py-4
                  shadow-md
                  hover:bg-current-50
                "
                @click="handleUsingTrial"
              >
                <span
                  class="text-current-500 uppercase"
                  v-html="
                    $t('DECIDE_PAYMENT_CONTINUE_WITH_TRIAL_BUTTON', {
                      bonusDays:
                        paymentConfigs?.paymentBonus?.defaultTrial || 30,
                    })
                  "
                >
                </span>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </teleport>
</template>
