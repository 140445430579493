// import { ref } from 'vue';

// const currentElementActive = ref<string>('');

let elementIds: string[] = [];
export default {
    install: (app) => {
        app.directive('element-active', {
            beforeMount(el, binding) {
                // console.log('beforeMount value', {
                //     'binding.value': binding.value,
                //     'el': el
                // });

                const elementId = binding.value?.id;
                if (!elementId) return;

                el.addEventListener('mousedown', () => {
                    elementIds = elementIds.filter((o) => o !== elementId);
                    elementIds.push(elementId);

                    _updateZIndex();
                });

                const disabled = binding.value?.disabled;
                if (disabled) return;

                elementIds.push(elementId);
                setTimeout(() => {
                    _updateZIndex();
                });
            },
            updated(el, binding) {
                const isForceOpen = binding.value?.isForceOpen;
                const elementId = binding.value?.id;

                if (
                    !isForceOpen ||
                    isForceOpen == 'undefined' ||
                    isForceOpen == undefined ||
                    !elementId
                )
                    return;

                elementIds = elementIds.filter((o) => o !== elementId);
                elementIds.push(elementId);

                _updateZIndex();
            },
            unmounted(el, binding) {
                const elementId = binding.value?.id;
                if (!elementId) return;

                elementIds = elementIds?.filter((o) => o !== elementId);

                _updateZIndex();
            },
        });
    },
};

const _updateZIndex = () => {
    for (const elementId of elementIds) {
        const el = document.getElementById(elementId);
        if (el) el.style.zIndex = '40';
    }

    const currentElementActiveId = elementIds.at(-1);
    if (!currentElementActiveId) return;

    const element = document.getElementById(currentElementActiveId);
    if (element) element.style.zIndex = '50';
};
