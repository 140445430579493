<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M400.258,201.829c-1.464-7.289-7.866-12.534-15.3-12.534H22.297c-7.435,0-13.837,5.245-15.3,12.534    C2.354,224.961,0,248.909,0,273.01c0,43.717,7.464,85.461,22.186,124.071c14.181,37.192,34.929,70.018,60.001,94.928    c2.923,2.905,6.878,4.535,10.998,4.535h220.883c4.122,0,8.075-1.63,11-4.536c25.073-24.909,45.821-57.735,60.001-94.928    c14.72-38.61,22.185-80.354,22.185-124.071C407.255,248.91,404.901,224.962,400.258,201.829z"
          ></path>
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M142.932,67.747c0-3.301,0.65-4.164,4.101-8.196c4.62-5.396,11.601-13.552,11.601-28.49    c0-8.618-6.987-15.605-15.605-15.605s-15.605,6.987-15.605,15.605c0,3.3-0.65,4.163-4.101,8.195    c-4.62,5.397-11.601,13.553-11.601,28.491c0,14.939,6.98,23.095,11.599,28.492c3.451,4.032,4.101,4.897,4.101,8.199    s-0.65,4.166-4.102,8.199c-1.748,2.043-3.924,4.586-5.876,7.737c-4.538,7.327-2.276,16.945,5.051,21.483    c2.559,1.584,5.397,2.34,8.201,2.34c5.224,0,10.329-2.623,13.283-7.39c0.689-1.112,1.784-2.393,3.053-3.875    c4.619-5.397,11.6-13.553,11.6-28.492s-6.981-23.095-11.6-28.492C143.582,71.913,142.932,71.049,142.932,67.747z"
          ></path>
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M211.383,67.747c0-3.301,0.65-4.164,4.101-8.196c4.62-5.396,11.601-13.552,11.601-28.49    c0-8.618-6.987-15.605-15.605-15.605s-15.605,6.987-15.605,15.605c0,3.3-0.65,4.163-4.101,8.195    c-4.62,5.397-11.601,13.553-11.601,28.491c0,14.939,6.981,23.095,11.6,28.492c3.451,4.032,4.101,4.897,4.101,8.199    s-0.65,4.166-4.102,8.199c-1.748,2.043-3.924,4.586-5.876,7.737c-4.538,7.327-2.276,16.945,5.051,21.483    c2.559,1.584,5.397,2.34,8.201,2.34c5.224,0,10.328-2.623,13.283-7.39c0.689-1.112,1.784-2.393,3.053-3.875    c4.619-5.397,11.6-13.553,11.6-28.492s-6.981-23.095-11.6-28.492C212.033,71.913,211.383,71.049,211.383,67.747z"
          ></path>
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M279.832,67.747c0-3.301,0.65-4.164,4.101-8.196c4.62-5.396,11.601-13.552,11.601-28.49    c0-8.618-6.987-15.605-15.605-15.605s-15.605,6.987-15.605,15.605c0,3.3-0.65,4.163-4.101,8.195    c-4.62,5.397-11.601,13.553-11.601,28.491c0,14.939,6.981,23.095,11.599,28.492c3.451,4.032,4.101,4.897,4.101,8.199    c0,3.302-0.649,4.166-4.101,8.199c-1.748,2.043-3.924,4.586-5.876,7.738c-4.538,7.327-2.275,16.945,5.052,21.482    c2.558,1.584,5.396,2.34,8.2,2.34c5.224,0,10.329-2.624,13.282-7.391c0.69-1.111,1.785-2.392,3.053-3.874    c4.619-5.397,11.599-13.554,11.599-28.492c0-14.939-6.98-23.095-11.599-28.492C280.482,71.913,279.832,71.049,279.832,67.747z"
          ></path>
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M479.348,228.815c-13.14-6.317-28.3-8.042-43.993-5.479c1.302,10.341,2.197,20.779,2.68,31.27    c11.508-2.257,21.192-0.834,27.791,2.338c9.79,4.706,14.965,13.432,14.965,25.232c0,26.547-18.969,69.12-56.802,96.598    c-2.844,9.969-6.084,19.788-9.758,29.426c-1.965,5.155-4.047,10.237-6.241,15.237c30.583-12.656,52.09-32.335,66.108-49.226    C497.476,346.041,512,310.775,512,282.174C512,258.591,499.794,238.644,479.348,228.815z"
          ></path>
        </g>
      </g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
