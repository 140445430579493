<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.5"
    height="22.5"
    viewBox="0 0 22.5 22.5"
  >
    <path
      id="Icon_ionic-md-add"
      data-name="Icon ionic-md-add"
      d="M29.25,19.5H19.5v9.75h-3V19.5H6.75v-3H16.5V6.75h3V16.5h9.75Z"
      transform="translate(-6.75 -6.75)"
    />
  </svg>
</template>
