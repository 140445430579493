<script setup lang="ts">
const emit = defineEmits<{
  (e: 'onClose'): void;
}>();

defineProps<{
  dataList: [];
}>();

const onClose = () => {
  emit('onClose');
};
</script>
<template>
  <SynModal
    style-body="p-0 flex flex-row flex-wrap"
    z-index="z-50"
    container-class="w-1/4"
    disable-click-outside
    :container-style="'min-height: 400px; min-width: 600px; max-height : 700px'"
    @cancel="onClose"
  >
    <template #header>
      <div class="w-full flex items-center space-x-2">
        <span>
          {{ $t('COMMON_LABEL_DATA_ERROR') || 'Data error' }}
        </span>
      </div>
    </template>
    <template #body>
      <div class="px-3 flex-1 min-h-0 overflow-auto small-scrollbar">
        <SynTableCustom>
          <template #header>
            <SynTr>
              <SynTh label="STT" style="width: 100px" />
              <SynTh :label="$t('COMMON_LABEL_DETAIL')" />
            </SynTr>
          </template>
          <template #body>
            <template v-for="task in dataList" :key="task?.rowIndex">
              <SynTr class="hover:bg-gray-50">
                <SynTd class="text-left">
                  <div class="ml-2">{{ task?.rowIndex }}</div>
                </SynTd>
                <SynTd class="">
                  <span
                    v-if="
                      task?.message.includes(
                        'COLLABORATORS_VALUE_DOES_NOT_MATCH'
                      ) ||
                      task?.message.includes('CREATOR_VALUE_DOES_NOT_MATCH') ||
                      task?.message.includes('ASSIGNEE_VALUE_DOES_NOT_MATCH')
                    "
                    v-html="
                      $t(task?.message.split(':')[0], {
                        phone: task?.message.split(':')[1],
                      })
                    "
                  >
                  </span>
                  <span v-else v-html="$t(task?.message)"></span>
                </SynTd>
              </SynTr>
            </template>
          </template>
        </SynTableCustom>
      </div>
    </template>

    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          type-outline
          :label="$t('COMMON_LABEL_CLOSE')"
          @click="onClose"
        />
      </div>
    </template>
  </SynModal>
</template>
