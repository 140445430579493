import { getStateCodeByRemainDays } from '@/ui/helpers/payment/payment-ui';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

export enum EPlanId {
    FREE = 'FREE',
    BASIC = 'BASIC',
    PRO = 'PRO',
    VIP = 'VIP',
    PERSONAL = 'PERSONAL',
}

const image_url_base = `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/plan-state`;

export const C_MESSAGE_CODE = {
    M1: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M1_NORMAL',
        titleWithBonus: 'PLAN_NOTIFICATION_MESSAGE_M1_NORMAL_BONUS',
        body: 'PLAN_NOTIFICATION_MESSAGE_M1_CONTENT',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M1_SHORT_CONTENT',
        hasPopup: false,
        disableClose: false,
        popupTitle: '',
        popupBody: '',
        iconName: 'info',
        imageUrl: '',
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M1_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M1_LOCK_FUNCTION_BLOCK',
        },
    },
    M1_TODAY: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M1_TODAY',
        titleWithBonus: 'PLAN_NOTIFICATION_MESSAGE_M1_TODAY_BONUS',
        body: 'PLAN_NOTIFICATION_MESSAGE_M1_CONTENT',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M1_SHORT_CONTENT',
        hasPopup: false,
        disableClose: false,
        popupTitle: '',
        popupBody: '',
        iconName: 'info',
        imageUrl: '',
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M1_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M1_LOCK_FUNCTION_BLOCK',
        },
    },
    M2: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M2_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M2_SHORT_CONTENT',
        body: 'PLAN_NOTIFICATION_MESSAGE_M2_CONTENT',
        hasPopup: true,
        disableClose: true,
        popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M2_MODAL_TITLE',
        // TODO: NEED TO TRANSLATE TO EN AND FR
        popupBody: 'PLAN_NOTIFICATION_MESSAGE_M2_MODAL_BODY',
        iconName: 'warning',
        imageUrl: `${image_url_base}/plan-warning-m2.png`,
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M2_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M2_LOCK_FUNCTION_BLOCK',
        },
    },
    M3: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M3_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M3_SHORT_CONTENT',
        body: 'PLAN_NOTIFICATION_MESSAGE_M3_CONTENT',
        hasPopup: false,
        disableClose: false,
        popupTitle: '',
        popupBody: '',
        iconName: 'info',
        imageUrl: '',
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_BLOCK',
        },
    },
    M3_TODAY: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M3_TITLE_TODAY',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M3_SHORT_CONTENT',
        body: 'PLAN_NOTIFICATION_MESSAGE_M3_CONTENT',
        hasPopup: false,
        disableClose: false,
        popupTitle: '',
        popupBody: '',
        iconName: 'info',
        imageUrl: '',
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_BLOCK',
        },
    },
    M4: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M4_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M4_SHORT_CONTENT',
        body: 'PLAN_NOTIFICATION_MESSAGE_M4_CONTENT',
        hasPopup: true,
        disableClose: true,
        popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M4_MODAL_TITLE',
        popupBody: 'PLAN_NOTIFICATION_MESSAGE_M4_MODAL_BODY',
        iconName: 'info',
        imageUrl: `${image_url_base}/plan-warning-m4.png`,
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M4_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M4_LOCK_FUNCTION_BLOCK',
        },
    },
    M4_LOWER: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M4_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M4_SHORT_CONTENT',
        body: 'PLAN_NOTIFICATION_MESSAGE_M4_CONTENT',
        hasPopup: false,
        disableClose: false,
        popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M4_MODAL_TITLE',
        popupBody: 'PLAN_NOTIFICATION_MESSAGE_M4_MODAL_BODY',
        iconName: 'info',
        imageUrl: `${image_url_base}/plan-warning-m4.png`,
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M4_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M4_LOCK_FUNCTION_BLOCK',
        },
    },
    M5: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M5_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M5_SHORT_CONTENT',
        body: 'PLAN_NOTIFICATION_MESSAGE_M5_MODAL_BODY',
        hasPopup: true,
        disableClose: true,
        popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M5_MODAL_TITLE',
        popupBody: 'PLAN_NOTIFICATION_MESSAGE_M5_MODAL_BODY',
        iconName: 'warning',
        imageUrl: `${image_url_base}/plan-warning-m5.png`,
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M5_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M5_LOCK_FUNCTION_BLOCK',
        },
    },
    M6: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M6_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M6_SHORT_CONTENT',
        body: 'PLAN_NOTIFICATION_MESSAGE_M6_MODAL_BODY',
        hasPopup: true,
        disableClose: true,
        popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M6_MODAL_TITLE',
        popupBody: 'PLAN_NOTIFICATION_MESSAGE_M6_MODAL_BODY',
        iconName: 'warning',
        imageUrl: `${image_url_base}/plan-warning-m6.png`,
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M6_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M6_LOCK_FUNCTION_BLOCK',
        },
    },
    M7: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        titleWithBonus: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        body: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        hasPopup: false,
        disableClose: false,
        popupTitle: '',
        popupBody: '',
        iconName: 'info',
        imageUrl: `${image_url_base}/plan-warning-m4.png`,
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_BLOCK',
        },
    },
    M7_NORMAL: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M7_NORMAL',
        titleWithBonus: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        body: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        hasPopup: false,
        disableClose: false,
        popupTitle: '',
        popupBody: '',
        iconName: 'info',
        imageUrl: `${image_url_base}/plan-warning-m4.png`,
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_BLOCK',
        },
    },
    M7_EXPIRED: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        titleWithBonus: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M7_SHORT_CONTENT',
        body: 'PLAN_NOTIFICATION_MESSAGE_M7_TITLE',
        hasPopup: true,
        disableClose: true,
        popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M7_POPUP_TITLE',
        popupBody: 'PLAN_NOTIFICATION_MESSAGE_M7_POPUP_BODY',
        iconName: 'info',
        imageUrl: `${image_url_base}/plan-warning-m4.png`,
        lockFunction: {
            warningMessage:
                'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_WARNING',
            blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_BLOCK',
        },
    },
    M0_NORMAL: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M_FREE_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M_FREE_TITLE',
        body: '',
        hasPopup: false,
        disableClose: false,
        popupTitle: '',
        popupBody: '',
        iconName: 'warning',
        imageUrl: `${image_url_base}/plan-warning-m2.png`,
        lockFunction: {
            warningMessage: '',
            blockMessage: '',
        },
    },
    M0_EXPIRED: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M0_EXPIRED_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M0_EXPIRED_CONTENT',
        body: '',
        hasPopup: true,
        disableClose: true,
        popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M0_EXPIRED_POPUP_TITLE',
        popupBody: 'PLAN_NOTIFICATION_MESSAGE_M0_EXPIRED_POPUP_BODY',
        iconName: 'warning',
        imageUrl: `${image_url_base}/plan-warning-m2.png`,
        lockFunction: {
            warningMessage: '',
            blockMessage: '',
        },
    },
    M0_PERSONAL: {
        title: 'PLAN_NOTIFICATION_MESSAGE_M1_PERSONAL_TITLE',
        titleWithBonus: '',
        shortContent: 'PLAN_NOTIFICATION_MESSAGE_M1_PERSONAL_TITLE',
        body: '',
        hasPopup: false,
        disableClose: false,
        popupTitle: '',
        popupBody: '',
        iconName: 'warning',
        imageUrl: `${image_url_base}/plan-warning-m2.png`,
        lockFunction: {
            warningMessage: '',
            blockMessage: '',
        },
    },
};

export enum EPlanAction {
    EXTEND = 'EXTEND',
    CONTACT = 'CONTACT',
    SELECT_PLAN = 'SELECT_PLAN',
}

const PURPOSE_PAYMENT_MESSAGE = {
    M1: 'PURPOSE_PAYMENT_MESSAGE_M1',
    M2: 'PURPOSE_PAYMENT_MESSAGE_M2',
    M3: 'PURPOSE_PAYMENT_MESSAGE_M3',
    M4: 'PURPOSE_PAYMENT_MESSAGE_M4',
};

const STYLE_BY_WARNING_LEVEL = {
    L1: {
        mainColor: '#E0F1F2',
        contrastColor: '#FB923C',
        borderCard: 'border-gray-100 hover:border-current cursor-pointer',
        colorText: 'text-yellow-500',
    },
    L1_5: {
        mainColor: '#076260',
        contrastColor: '#B1DCDE',
        borderCard: 'border-gray-100 hover:border-current cursor-pointer',
        colorText: 'text-orange-500',
    },
    L2: {
        mainColor: '#FFEDD5',
        contrastColor: '#F97316',
        borderCard: 'border-orange-100 hover:border-current cursor-pointer',
        colorText: 'text-orange-500',
    },
    L2_5: {
        mainColor: '#FB923C',
        contrastColor: '#FFF',
        borderCard: 'border-orange-100 hover:border-current cursor-pointer',
        colorText: 'text-orange-500',
    },
    L3: {
        mainColor: '#F87171',
        contrastColor: '#FFF',
        borderCard: 'border-red-100 hover:border-current cursor-pointer',
        colorText: 'text-red-500',
    },
    L3_5: {
        mainColor: '#DC2626',
        contrastColor: '#FFF',
        borderCard: 'border-red-100 hover:border-current cursor-pointer',
        colorText: 'text-red-500',
    },
};
const ACTION_BY_KEYS: {
    [key: string]: {
        key: EPlanAction;
        labelCode: string;
    };
} = {
    [EPlanAction.SELECT_PLAN]: {
        key: EPlanAction.SELECT_PLAN,
        labelCode: 'PAYMENT_LABEL_SELECT_PLAN',
    },
    // [EPlanAction.PURCHASE]: {
    //     key: EPlanAction.PURCHASE,
    //     labelCode: 'PAYMENT_LABEL_PURCHASE',
    // },
    [EPlanAction.EXTEND]: {
        key: EPlanAction.EXTEND,
        labelCode: 'PAYMENT_EXTEND',
    },
    // [EPlanAction.RENEW]: {
    //     key: EPlanAction.RENEW,
    //     labelCode: 'PAYMENT_RENEW',
    // },
    [EPlanAction.CONTACT]: {
        key: EPlanAction.CONTACT,
        labelCode: 'PAYMENT_CONTACT',
    },
};
export const UI_BY_PLAN_STATE = {
    INIT: {
        name: 'Decide payment',
        code: 'INIT',
        nameCode: 'PLAN_STATE_LABEL_DECIDE_PAYMENT',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M1,
        defaultTotalUserBy: '',
        action: {
            defaultActionKey: null,
            defaultActionLabelCode: null,
            actionOnCurrentPlanLabelCode: null,
            actionOnHigherPlanLabelCode: null,
            actionOnLowerPlanLabelCode: null,
        },
        recurringOptions: {
            endDateBy: '',
            messageCode: '',
            maxDays: 0,
        },
        DEFAULT: {
            style: null,
            message: null,
        },
        INCOMING_EXPIRED: {
            style: null,
            message: null,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: null,
            message: null,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: null,
            message: null,
        },
        OVERDUE_LOWER: {
            style: null,
            message: null,
        },
        EXPIRED: {
            style: null,
            message: null,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                shortMessage: '',
            },
        },
    },
    FREE: {
        name: 'Paid',
        code: 'PAID',
        nameCode: 'COMMOM_LABEL_FREE',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M2,
        purposeBuyMoreMessage: PURPOSE_PAYMENT_MESSAGE.M4,
        defaultTotalUserBy: '',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].labelCode,
            actionOnCurrentPlanLabelCode: '',
            actionOnHigherPlanLabelCode: 'PAYMENT_LABEL_UPGRADE_FOR_PLAN',
            actionOnLowerPlanLabelCode: 'PAYMENT_LABEL_DOWNGRADE_TO_PLAN',
        },
        recurringOptions: {
            endDateBy: 'DATE_NUMBER',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_DAYS',
            maxDays: 30,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M0_NORMAL,
        },
        INCOMING_EXPIRED: {
            style: null,
            message: null,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: null,
            message: null,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: null,
            message: null,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M0_EXPIRED,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M0_EXPIRED,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: null,
                shortMessage: null,
            },
        },
    },
    PERSONAL_FREE: {
        name: 'Paid',
        code: 'PAID',
        nameCode: 'COMMOM_LABEL_FREE',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M2,
        purposeBuyMoreMessage: PURPOSE_PAYMENT_MESSAGE.M4,
        defaultTotalUserBy: '',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].labelCode,
            actionOnCurrentPlanLabelCode: '',
            actionOnHigherPlanLabelCode: 'PAYMENT_LABEL_UPGRADE_FOR_PLAN',
            actionOnLowerPlanLabelCode: 'PAYMENT_LABEL_DOWNGRADE_TO_PLAN',
        },
        recurringOptions: {
            endDateBy: 'DATE_NUMBER',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_DAYS',
            maxDays: 30,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M0_PERSONAL,
        },
        INCOMING_EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M0_PERSONAL,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M0_PERSONAL,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M0_PERSONAL,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M0_PERSONAL,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M0_PERSONAL,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: null,
                shortMessage: null,
            },
        },
    },
    OVERDUE: {
        name: 'BASIC',
        code: 'BASIC',
        nameCode: 'COMMON_LABEL_BASIC',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M2,
        purposeBuyMoreMessage: PURPOSE_PAYMENT_MESSAGE.M4,
        defaultTotalUserBy: '',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].labelCode,
            actionOnCurrentPlanLabelCode: '',
            actionOnHigherPlanLabelCode: 'PAYMENT_LABEL_UPGRADE_FOR_PLAN',
            actionOnLowerPlanLabelCode: 'PAYMENT_LABEL_DOWNGRADE_TO_PLAN',
        },
        recurringOptions: {
            endDateBy: 'DATE_NUMBER',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_DAYS',
            maxDays: 30,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M0_NORMAL,
        },
        INCOMING_EXPIRED: {
            style: null,
            message: null,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: null,
            message: null,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: null,
            message: null,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M0_EXPIRED,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M0_EXPIRED,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: null,
                shortMessage: null,
            },
        },
    },
    TRIAL: {
        name: 'Trial',
        code: 'TRIAL',
        nameCode: 'PLAN_STATE_LABEL_TRIAL',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M1,
        defaultTotalUserBy: 'USAGE',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].labelCode,
            actionOnCurrentPlanLabelCode: 'PAYMENT_LABEL_PURCHASE',
            actionOnHigherPlanLabelCode:
                'SETTING_PLAN_MANAGE_PLAN_UPGRADE_BTN_LABEL',
            actionOnLowerPlanLabelCode: 'PAYMENT_LABEL_DOWNGRADE_TO_PLAN',
        },
        recurringOptions: {
            endDateBy: 'END_DATE',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_STATE_TRIAL',
            maxDays: null,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M1,
        },
        INCOMING_EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M1,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M1,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M1_TODAY,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M2,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M2,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                shortMessage: '',
            },
        },
    },

    OVERDUE_TRIAL: {
        name: 'Overdue trial',
        code: 'OVERDUE_TRIAL',
        nameCode: 'PLAN_STATE_LABEL_OVERDUE_TRIAL',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M1,
        defaultTotalUserBy: 'USAGE',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].labelCode,
            actionOnCurrentPlanLabelCode: 'PAYMENT_LABEL_PURCHASE_FOR_THE_PLAN',
            actionOnHigherPlanLabelCode: 'PAYMENT_LABEL_UPGRADE_FOR_PLAN',
            actionOnLowerPlanLabelCode: 'PAYMENT_LABEL_DOWNGRADE_TO_PLAN',
        },
        recurringOptions: {
            endDateBy: 'DATE_NUMBER',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_DAYS',
            maxDays: 10,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M2,
        },
        INCOMING_EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M2,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M2,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M2,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M2,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M2,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_TRIAL_LONG',
                shortMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_TRIAL_SHORT',
            },
        },
    },
    PAID: {
        name: 'Paid',
        code: 'PAID',
        nameCode: 'PLAN_STATE_LABEL_PAID',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M2,
        purposeBuyMoreMessage: PURPOSE_PAYMENT_MESSAGE.M4,
        defaultTotalUserBy: 'LIMITATION',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.EXTEND].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.EXTEND].labelCode,
            actionOnCurrentPlanLabelCode:
                ACTION_BY_KEYS[EPlanAction.EXTEND].labelCode,
            actionOnHigherPlanLabelCode:
                'SETTING_PLAN_MANAGE_PLAN_UPGRADE_BTN_LABEL',
            actionOnLowerPlanLabelCode: 'PAYMENT_LABEL_DOWNGRADE_TO_PLAN',
        },
        recurringOptions: {
            endDateBy: 'END_DATE',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_STATE_PAID',
            maxDays: null,
        },
        DEFAULT: {
            style: null,
            message: null,
        },
        INCOMING_EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M3,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M3,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M3_TODAY,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M4_LOWER,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M4,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                shortMessage: '',
            },
        },
    },

    OVERDUE_PAID: {
        name: 'Overdue paid',
        code: 'OVERDUE_PAID',
        nameCode: 'PLAN_STATE_LABEL_OVERDUE_PAID',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M3,
        defaultTotalUserBy: 'USAGE',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].labelCode,
            actionOnCurrentPlanLabelCode: 'PAYMENT_LABEL_PURCHASE_FOR_THE_PLAN',
            actionOnHigherPlanLabelCode: 'PAYMENT_LABEL_UPGRADE_FOR_PLAN',
            actionOnLowerPlanLabelCode: 'PAYMENT_LABEL_DOWNGRADE_TO_PLAN',
        },
        recurringOptions: {
            endDateBy: 'DATE_NUMBER',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_DAYS',
            maxDays: 10,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M4,
        },
        INCOMING_EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M4,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M4,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M4,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M4_LOWER,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M4,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_PAID_LONG',
                shortMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_PAID_SHORT',
            },
        },
    },
    BLOCK: {
        name: 'Block',
        code: 'BLOCK',
        nameCode: 'PLAN_STATE_LABEL_BLOCK',
        purposeMessage: '',
        defaultTotalUserBy: 'USAGE',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.SELECT_PLAN].labelCode,
            actionOnCurrentPlanLabelCode: 'PAYMENT_LABEL_PURCHASE',
            actionOnHigherPlanLabelCode:
                'SETTING_PLAN_MANAGE_PLAN_UPGRADE_BTN_LABEL',
            actionOnLowerPlanLabelCode: 'PAYMENT_LABEL_DOWNGRADE_TO_PLAN',
        },
        recurringOptions: {
            endDateBy: 'DATE_NUMBER',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_DAYS',
            maxDays: 10,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L3,
            message: C_MESSAGE_CODE.M5,
        },
        INCOMING_EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L3,
            message: C_MESSAGE_CODE.M5,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: STYLE_BY_WARNING_LEVEL.L3,
            message: C_MESSAGE_CODE.M5,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: STYLE_BY_WARNING_LEVEL.L3,
            message: C_MESSAGE_CODE.M5,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L3,
            message: C_MESSAGE_CODE.M5,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L3,
            message: C_MESSAGE_CODE.M5,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                shortMessage: '',
            },
        },
    },
    DEFINITIVE_BLOCK: {
        name: 'DEFINITIVE BLOCK',
        code: 'DEFINITIVE_BLOCK',
        nameCode: 'PLAN_STATE_LABEL_BLOCK',
        purposeMessage: '',
        defaultTotalUserBy: '',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.CONTACT].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.CONTACT].labelCode,
            actionOnCurrentPlanLabelCode:
                ACTION_BY_KEYS[EPlanAction.CONTACT].labelCode,
            actionOnHigherPlanLabelCode:
                ACTION_BY_KEYS[EPlanAction.CONTACT].labelCode,
            actionOnLowerPlanLabelCode:
                ACTION_BY_KEYS[EPlanAction.CONTACT].labelCode,
        },
        recurringOptions: {
            endDateBy: 'DATE_NUMBER',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_DAYS',
            maxDays: 10,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L3_5,
            message: C_MESSAGE_CODE.M6,
        },
        INCOMING_EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L3_5,
            message: C_MESSAGE_CODE.M6,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: STYLE_BY_WARNING_LEVEL.L3_5,
            message: C_MESSAGE_CODE.M6,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: STYLE_BY_WARNING_LEVEL.L3_5,
            message: C_MESSAGE_CODE.M6,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L3_5,
            message: C_MESSAGE_CODE.M6,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L3_5,
            message: C_MESSAGE_CODE.M6,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                shortMessage: '',
            },
        },
    },
    EVALUATION: {
        name: 'EVALUATION',
        code: 'EVALUATION',
        nameCode: 'PLAN_STATE_LABEL_EVALUATION',
        purposeMessage: PURPOSE_PAYMENT_MESSAGE.M2,
        purposeBuyMoreMessage: PURPOSE_PAYMENT_MESSAGE.M4,
        defaultTotalUserBy: 'LIMITATION',
        action: {
            defaultActionKey: ACTION_BY_KEYS[EPlanAction.CONTACT].key,
            defaultActionLabelCode:
                ACTION_BY_KEYS[EPlanAction.CONTACT].labelCode,
            actionOnCurrentPlanLabelCode:
                ACTION_BY_KEYS[EPlanAction.CONTACT].labelCode,
            actionOnHigherPlanLabelCode:
                ACTION_BY_KEYS[EPlanAction.CONTACT].labelCode,
            actionOnLowerPlanLabelCode:
                ACTION_BY_KEYS[EPlanAction.CONTACT].labelCode,
        },
        recurringOptions: {
            endDateBy: 'END_DATE',
            messageCode: 'TASK_LABEL_REPEAT_END_DATE_LIMIT_BY_STATE_EVALUATION',
            maxDays: null,
        },
        DEFAULT: {
            style: STYLE_BY_WARNING_LEVEL.L1,
            message: C_MESSAGE_CODE.M7_NORMAL,
        },
        INCOMING_EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M7,
        },
        INCOMING_EXPIRED_HIGHER: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M7,
        },
        INCOMING_EXPIRED_HIGHER_TODAY: {
            style: STYLE_BY_WARNING_LEVEL.L2,
            message: C_MESSAGE_CODE.M7,
        },
        OVERDUE_LOWER: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M7_EXPIRED,
        },
        EXPIRED: {
            style: STYLE_BY_WARNING_LEVEL.L2_5,
            message: C_MESSAGE_CODE.M7_EXPIRED,
        },
        BLOCK_SCREEN: {
            style: null,
            message: {
                longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
                shortMessage: '',
            },
        },
    },
};

export const UI_BY_PLAN_ID = {
    FREE: {
        style: {
            background: '#008d8d',
            text: '#fff',
        },
        stateByKeys: {
            INIT: UI_BY_PLAN_STATE['INIT'],
            FREE: UI_BY_PLAN_STATE['FREE'],
            OVERDUE: UI_BY_PLAN_STATE['OVERDUE'],
        },
    },
    BASIC: {
        style: {
            background: '#008d8d',
            text: '#fff',
        },
        stateByKeys: {
            INIT: UI_BY_PLAN_STATE['INIT'],
            FREE: UI_BY_PLAN_STATE['FREE'],
            OVERDUE: UI_BY_PLAN_STATE['OVERDUE'],
        },
    },
    PRO: {
        style: {
            background: '#00b0b8',
            text: '#fff',
        },
        stateByKeys: {
            DECIDE_PAYMENT: UI_BY_PLAN_STATE['DECIDE_PAYMENT'],
            PAID: UI_BY_PLAN_STATE['PAID'],
            TRIAL: UI_BY_PLAN_STATE['TRIAL'],
            OVERDUE_PAID: UI_BY_PLAN_STATE['OVERDUE_PAID'],
            OVERDUE_TRIAL: UI_BY_PLAN_STATE['OVERDUE_TRIAL'],
            BLOCK: UI_BY_PLAN_STATE['BLOCK'],
            DEFINITIVE_BLOCK: UI_BY_PLAN_STATE['DEFINITIVE_BLOCK'],
            EVALUATION: UI_BY_PLAN_STATE['EVALUATION'],
        },
    },
    VIP: {
        style: {
            background: '#008d8d',
            text: '#fff',
        },
        stateByKeys: {
            DECIDE_PAYMENT: UI_BY_PLAN_STATE['DECIDE_PAYMENT'],
            PAID: UI_BY_PLAN_STATE['PAID'],
            TRIAL: UI_BY_PLAN_STATE['TRIAL'],
            OVERDUE_PAID: UI_BY_PLAN_STATE['OVERDUE_PAID'],
            OVERDUE_TRIAL: UI_BY_PLAN_STATE['OVERDUE_TRIAL'],
            BLOCK: UI_BY_PLAN_STATE['BLOCK'],
            DEFINITIVE_BLOCK: UI_BY_PLAN_STATE['DEFINITIVE_BLOCK'],
            EVALUATION: UI_BY_PLAN_STATE['EVALUATION'],
        },
    },
    PERSONAL: {
        style: {
            background: '#008d8d',
            text: '#fff',
        },
        stateByKeys: {
            FREE: UI_BY_PLAN_STATE['PERSONAL_FREE'],
        },
    },
};

export const getUiByPlanState = (planId, stateCode) => {
    if (!planId || !Object.prototype.hasOwnProperty.call(UI_BY_PLAN_ID, planId))
        return {};
    const uiByPlanState = UI_BY_PLAN_ID[planId]?.stateByKeys;
    if (
        !stateCode ||
        !Object.prototype.hasOwnProperty.call(uiByPlanState, stateCode)
    )
        return {};

    return uiByPlanState[stateCode];
};
export const getUiByPlanSubState = (org) => {
    const subStateCode =
        getStateCodeByRemainDays(
            org?.planRemainDays,
            org?.lastDateAllowedToUse || new Date()
        ) || 'DEFAULT';

    const uiByPlanState = getUiByPlanState(org?.planId, org?.stateCode);

    return uiByPlanState[subStateCode];
};
