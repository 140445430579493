<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="m478.939 338.108-352.128 62.09c-21.756 3.836-42.502-10.691-46.338-32.446l-38.76-219.817c-3.836-21.756 10.691-42.502 32.446-46.338l352.128-62.09c21.756-3.836 42.502 10.691 46.338 32.446l38.76 219.817c3.837 21.755-10.69 42.502-32.446 46.338z"
        fill="#3555a2"
        data-original="#3555a2"
      ></path>
      <path
        d="m426.288 39.506-176.064 31.045 52.651 298.602 176.064-31.045c21.756-3.836 36.283-24.582 32.446-46.338l-38.76-219.817c-3.835-21.756-24.581-36.283-46.337-32.447z"
        fill="#1b3555"
        data-original="#1b3555"
        class=""
      ></path>
      <path
        d="m390 473.108h-350c-22.091 0-40-17.909-40-40v-217.718c0-22.091 17.909-40 40-40h350c22.091 0 40 17.909 40 40v217.718c0 22.091-17.909 40-40 40z"
        fill="#53d8e3"
        data-original="#53d8e3"
      ></path>
      <path
        d="m390 175.39h-175v297.718h175c22.091 0 40-17.909 40-40v-217.718c0-22.091-17.909-40-40-40z"
        fill="#18a8c9"
        data-original="#18a8c9"
        class=""
      ></path>
      <path
        d="m0 243.44h430v68.05h-430z"
        fill="#ecf6fd"
        data-original="#ecf6fd"
      ></path>
      <path
        d="m119.087 407.07h-68.05c-8.284 0-15 6.716-15 15s6.716 15 15 15h68.05c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
        fill="#eaf6ff"
        data-original="#eaf6ff"
      ></path>
      <path
        d="m215 243.44h215v68.05h-215z"
        fill="#d6e8fe"
        data-original="#d6e8fe"
        class=""
      ></path>
    </g>
  </svg>
</template>
