import {
    addDoc,
    CollectionReference,
    DocumentData,
    DocumentReference,
    getDoc,
    onSnapshot,
    Query,
    query,
    QueryConstraint,
    serverTimestamp,
    setDoc,
    SetOptions,
    updateDoc,
    deleteDoc,
    writeBatch,
} from 'firebase/firestore';
import { QuerySnapshot } from '@firebase/firestore';

export default class FirestoreBaseModel {
    constructor() {}

    protected getDoc(docRef: DocumentReference<any>) {
        return getDoc(docRef);
    }

    protected docChanges(query: Query, callback: (data) => void) {
        return onSnapshot(query, (snapshot: QuerySnapshot) =>
            callback(snapshot.docChanges())
        );
    }

    protected addDoc(docRef: CollectionReference<any>, data: any) {
        return addDoc(docRef, data);
    }

    protected setDoc(
        docRef: DocumentReference<any>,
        data: any,
        options: SetOptions
    ) {
        return setDoc(docRef, data, options);
    }

    protected updateDoc(
        docRef: DocumentReference<DocumentData>,
        data: any,
        options?: any
    ) {
        return updateDoc(docRef, data, options);
    }

    protected getQuery(q: Query, ...queryConstraints: QueryConstraint[]) {
        return query(q, ...queryConstraints);
    }

    protected deleteDoc(docRef: DocumentReference<DocumentData>) {
        console.log('🚀 ~ deleteDoc ~ docRef', docRef);
        return deleteDoc(docRef);
    }

    protected writeBatch(firestoreDb) {
        return writeBatch(firestoreDb);
    }

    protected serverTimestamp() {
        return serverTimestamp();
    }
}
