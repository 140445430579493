export default function dynamicPositionDayoff(x, y, element, elementClick) {
    const widthElementClick = elementClick.offsetWidth;
    const heightElementClick = elementClick.offsetHeight;
    const elementHeight = element.offsetHeight;
    const elementWidth = element.offsetWidth;
    const widthBody = document.body.clientWidth;
    const heightBody = document.body.clientHeight;
    const padding = 20;
    const onTopTargetY = y - elementHeight + 35;
    const onBottomTargetY = y - elementHeight / 4;
    const onLeftTargetX = x - elementWidth - padding;
    const onRightTargetX = x + widthElementClick + padding;
    const fitPositionX = x;
    const fitPositionYBottom = y + heightElementClick + padding;
    const fitPositionYTop = y - elementHeight - padding * 2;

    const overScreen = widthBody - (x + widthElementClick + padding);

    const positionX =
        elementWidth >= overScreen ? onLeftTargetX : onRightTargetX;
    const positionY =
        elementHeight > y
            ? onBottomTargetY
            : Math.max(
                  Math.max(onTopTargetY, 0) -
                      Math.max(onTopTargetY + elementHeight - heightBody, 0),
                  0
              );

    //SHOW ABOVE
    if (
        elementHeight > heightBody - (y + elementClick + 20) &&
        elementWidth > x - padding &&
        elementWidth > widthBody - (x + widthElementClick + padding)
    ) {
        return {
            x: fitPositionX,
            y: Math.max(
                Math.max(fitPositionYTop, 0) -
                    Math.max(fitPositionYTop + elementHeight - heightBody, 0),
                0
            ),
        };
    } //SHOWN BELOW
    else if (
        elementWidth > x + padding &&
        elementWidth > widthBody - (x + widthElementClick + padding)
    ) {
        return {
            x: fitPositionX,
            y: Math.max(
                Math.max(fitPositionYBottom, 0) -
                    Math.max(
                        fitPositionYBottom + elementHeight - heightBody,
                        0
                    ),
                0
            ),
        };
    }
    //SHOWN RIGHT OR LEFT
    else {
        return {
            x: positionX,
            y: positionY,
        };
    }
}
