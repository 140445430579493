<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M255.209 15c133.101 0 241 107.899 241 241s-107.899 241-241 241"
        style="
          stroke-width: 30;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        "
        fill="none"
        stroke="#000000"
        stroke-width="30"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        data-original="#000000"
      ></path>
      <path
        d="M255.209 105.375V256l120.5 120.5"
        style="
          stroke-width: 30;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        "
        fill="none"
        stroke="#000000"
        stroke-width="30"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        data-original="#000000"
      ></path>
      <path
        d="M168.049 31.255s5.814-2.327 12.237-4.334c6.423-2.007 13.872-4.111 13.872-4.111M94.862 76.012s4.63-4.216 9.944-8.345c5.314-4.129 11.554-8.709 11.554-8.709M41.983 143.565s2.861-5.571 6.392-11.299c3.531-5.729 7.772-12.204 7.772-12.204M16.11 225.358s.729-6.22 2.03-12.822 3.006-14.153 3.006-14.153M20.521 311.033s-1.496-6.081-2.589-12.721-2.14-14.309-2.14-14.309M54.656 389.737s-3.531-5.172-6.88-11.009c-3.35-5.837-7.016-12.654-7.016-12.654M114.192 451.503s-5.118-3.608-10.3-7.902c-5.182-4.294-11.003-9.395-11.003-9.395M191.589 488.507s-6.058-1.587-12.415-3.795-13.597-4.947-13.597-4.947"
        style="
          stroke-width: 30;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: 30, 57;
        "
        fill="none"
        stroke="#000000"
        stroke-width="30"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="30,57"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>
