<script setup lang="ts">
import { computed } from 'vue';
import GroupReaction from './GroupReaction.vue';
import MessageTask from '@/ui/modules/messaging/chat-panel/chat-message/message-task/MessageTask.vue';
import DayoffListItem from '@/ui/components/dayoff/atoms/DayoffListItem.vue';
import { DayoffStatusUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import { styleCustomizeMessage } from '@/ui/modules/messaging/chat-panel/chat-message/button-customize/calculate-color-customizes';
import ButtonReactions from '@/ui/modules/messaging/chat-panel/chat-message/button-reactions/ButtonReactions.vue';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import EventListItem from '@/ui/components/calendars/atoms/EventListItem.vue';

const props = defineProps<{
  message?: any;
  isMyMessage?: boolean;
  members?: any;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  isErrorSend?: boolean;
  allowedActions?: ChatMessageAction[];
}>();

const emit = defineEmits<{
  (e: 'onReactionsClick', value: any): void;
  (e: 'onReactionAdd', value: any): void;
  (e: 'onTaskClick', value: any): void;
  (e: 'onDayoffClick', value: any): void;
  (e: 'onEventClick', value: any): void;
}>();

const hasReactions = computed(
  () => !!Object.keys(props.message?.reactions || {})?.length
);

const hasFiles = computed(
  () => () => !!Object.keys(props.message?.files || {})?.length
);

const styleMsg = computed(() =>
  styleCustomizeMessage(
    props?.message,
    props?.isMyMessage,
    props.allowedActions?.includes(ChatMessageAction.SET_BACKGROUND)
  )
);

const emitEventId = (eventId) => {
  console.log(eventId, 'eventId');
  emit('onEventClick', eventId);
};

const createdDate = computed(() => {
  if (!props.message?.createdDate) return new Date();

  return new Date(props.message.createdDate).getTime();
});
</script>

<template>
  <template v-if="message?.task || message?.dayoff || message?.eventData">
    <div class="relative">
      <div
        class="msg-content flex flex-wrap"
        :class="isMyMessage ? 'flex-row-reverse' : 'flex-row'"
      >
        <!--        <section-->
        <!--          v-if="message?.task"-->
        <!--          class="flex-center rounded"-->
        <!--          :style="-->
        <!--            !message?.comment-->
        <!--              ? `border: 2px solid ${styleMsg?.hexColor}; padding: 2px 2px 0px 2px;`-->
        <!--              : ''-->
        <!--          "-->
        <!--        >-->
        <MessageTask
          v-if="message?.task"
          :task="message?.task"
          :is-my-message="isMyMessage"
          :is-first-in-group="isFirstInGroup"
          :is-last-in-group="isLastInGroup"
          :is-comment="!!message?.comment"
          :is-error-send="isErrorSend"
          :members="members"
          @on-open-task="(code) => $emit('onTaskClick', code)"
        />
        <!--        </section>-->

        <section
          v-if="message?.dayoff"
          class="flex-center rounded"
          :style="
            !message?.comment
              ? `border: 2px solid ${styleMsg?.hexColor}; padding: 0px 2px 0px 2px;`
              : ''
          "
        >
          <div
            class="
              my-0.5
              rounded
              border border-orange-500
              cursor-pointer
              w-max
              overflow-hidden
            "
            :style="{
              borderColor: DayoffStatusUI[message?.dayoff?.status]?.color,
              maxWidth: '21rem',
              minWidth: '17rem',
            }"
            @click="$emit('onDayoffClick', message?.dayoff?.id)"
          >
            <DayoffListItem
              v-if="message?.dayoff && message?.dayoff?.id"
              :dayoff-data="message?.dayoff"
              is-show-title
              is-hidden-name
            />
          </div>
        </section>

        <!--        EVENT-->
        <section v-if="message?.eventData" class="flex-center rounded">
          <div
            class="my-0.5 rounded border border-purple-500 cursor-pointer w-max"
            :style="{
              maxWidth: '21rem',
              minWidth: '17rem',
            }"
            @click="emitEventId(message?.eventData?.id)"
          >
            <EventListItem
              v-if="message?.eventData && message?.eventData?.id"
              :event-data="message?.eventData"
            />
          </div>
        </section>
      </div>

      <!-- BUTTON REACTION -->
      <template v-if="!message?.comment && !message?.text && !hasFiles">
        <div
          class="text-2xs text-gray-500"
          :class="isMyMessage ? 'text-end' : 'text-start'"
        >
          <SynLabelDateTime format="time" :datetime="createdDate" />
        </div>

        <div
          v-if="allowedActions?.includes(ChatMessageAction.REACTION)"
          class="absolute bottom-1 z-10"
          :class="isMyMessage ? 'left-1' : 'right-1'"
        >
          <GroupReaction
            v-if="hasReactions"
            :reactions="message?.reactions"
            :members="members"
            :is-in-group="false"
            @on-reaction-add="$emit('onReactionAdd', $event)"
            @on-reactions-click="$emit('onReactionsClick', $event)"
          />

          <ButtonReactions
            v-else
            custom-class="bg-white border w-6 h-6 hover-to-show__children"
            @on-emoji-click="$emit('onReactionAdd', $event)"
          />
        </div>
      </template>
    </div>
  </template>
</template>
