<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.717"
    height="22.979"
    viewBox="0 0 22.717 22.979"
  >
    <g id="Group_4425" data-name="Group 4425" transform="translate(-3 1.209)">
      <path
        id="Layer_25"
        data-name="Layer 25"
        d="M14.359,3A11.339,11.339,0,0,0,4.314,9.122a11.6,11.6,0,0,0,.665,11.843L3.705,24.823a.351.351,0,0,0,.068.342.342.342,0,0,0,.328.11l4.465-.9a11.257,11.257,0,0,0,13.026-1.027A11.573,11.573,0,0,0,25.046,10.6,11.367,11.367,0,0,0,14.359,3ZM13.327,8.222h2.065a.348.348,0,0,1,0,.7H13.327a.348.348,0,0,1,0-.7ZM14.359,21.8a1.385,1.385,0,0,1-1.377-1.393h2.754A1.385,1.385,0,0,1,14.359,21.8Zm4.974-2.3a.681.681,0,0,1-.5.216H9.884a.685.685,0,0,1-.5-.217.7.7,0,0,1-.188-.517.735.735,0,0,1,.747-.658.981.981,0,0,0,.974-.985V13.1a3.5,3.5,0,0,1,1.007-2.463,3.418,3.418,0,0,1,2.435-1.018h.065a3.548,3.548,0,0,1,3.377,3.61v4.108a.981.981,0,0,0,.974.985.735.735,0,0,1,.747.658.7.7,0,0,1-.189.519Z"
        transform="translate(0 -4.209)"
      />
    </g>
  </svg>
</template>
