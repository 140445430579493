<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import TaskDetail2 from '@/ui/modules/task/detail/task-detail-2/TaskDetail2.vue';
import TaskServiceSingleton from '@/application/services/task/TaskService';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  organizationId: number;
  taskId?: number;
  taskCode?: string;
  users?: any[];
  groups?: any[];
  domains?: any[];
  projects?: any[];
  isOrgPersonal?: boolean;
}>();

const _taskService = TaskServiceSingleton.getInstance();

const taskDetail = ref<any>();
const taskFiles = ref<any[]>();
const taskNotes = ref<any[]>();
const taskComments = ref<any[]>();
const taskError = ref<any>();

watch(
  () => [props.taskId, props.taskCode],
  ([taskId, taskCode]) => {
    _getTaskDetail(props.organizationId, taskId, taskCode);
  }
);

onMounted(() => {
  _getTaskDetail(props.organizationId, props.taskId, props.taskCode);
});

const onStateChange = async (taskLife) => {
  const updTask = {
    taskId: taskDetail.value?.id,
    taskLife,
  };

  await _taskService.changeOrgTaskState(props.organizationId, updTask);

  taskDetail.value = { ...taskDetail.value, ...updTask };
};

const onPrivateChange = async (isPrivate) => {
  const updTask = {
    taskId: taskDetail.value?.id,
    isPrivate,
  };

  await _taskService.changeOrgTaskPrivate(props.organizationId, updTask);

  taskDetail.value = { ...taskDetail.value, ...updTask };
};

const onNameChange = async (name) => {
  const updTask = {
    id: taskDetail.value?.id,
    name,
  };

  await _taskService.renameOrgTask(props.organizationId, updTask);

  taskDetail.value = { ...taskDetail.value, ...updTask };
};

const onAssigneeChange = async (assignee) => {
  const updTask = {
    id: taskDetail.value?.id,
    assigneeId: assignee?.assigneeId,
    groupId: assignee?.groupId,
  };

  await _taskService.setOrgTaskAssignee(props.organizationId, updTask);

  taskDetail.value = { ...taskDetail.value, ...updTask };
};

const onDomainChange = async (domain) => {
  const updTask = {
    taskId: taskDetail.value?.id,
    domainId: domain?.domainId,
    domainName: domain?.domainName,
    projectId: domain?.projectId,
    projectName: domain?.projectName,
    subprojectId: domain?.subprojectId,
    subprojectName: domain?.subprojectName,
  };

  await _taskService.changeOrgTaskDomain(props.organizationId, updTask);

  taskDetail.value = { ...taskDetail.value, ...updTask };
};

const onPlanningChange = async (planType) => {
  const updTask = {
    taskId: taskDetail.value?.id,
    planType,
  };

  const res = await _taskService.setOrgTaskPlanned(
    props.organizationId,
    updTask
  );

  taskDetail.value = { ...taskDetail.value, ...res?.result };
};

const onDeadlineChange = async (deadline) => {
  const orgId = props.organizationId;
  const taskId = taskDetail.value?.id;

  const updTask = {
    taskId,
    ...deadline,
  };

  await _taskService.changeOrgTaskScheduleTime(props.organizationId, updTask);

  if (orgId === props.organizationId && taskId === taskDetail.value?.id) {
    taskDetail.value = await _taskService.getOrgTaskDetail(orgId, taskId);
  }
};

const onUrgencyChange = async (urgency) => {
  const updTask = {
    id: taskDetail.value?.id,
    priority: urgency?.priority,
    important: urgency?.important,
  };

  await _taskService.changeOrgTaskUrgency(props.organizationId, updTask);

  taskDetail.value = { ...taskDetail.value, ...updTask };
};

const onDescriptionChange = async (description) => {
  const updTask = {
    id: taskDetail.value?.id,
    description,
  };

  await _taskService.changeOrgTaskDescription(props.organizationId, updTask);

  taskDetail.value = { ...taskDetail.value, ...updTask };
};

const onAttachmentsChange = async () => {
  _getTaskAttachments(props.organizationId, taskDetail.value?.id);
};

const onCommentAdd = async () => {
  _getTaskComments(props.organizationId, taskDetail.value?.id);
};

const _getTaskDetail = async (orgId, taskId, taskCode) => {
  if (!orgId || (!taskId && !taskCode)) return;

  taskError.value = null;

  try {
    taskDetail.value = taskId
      ? await _taskService.getOrgTaskDetail(orgId, taskId)
      : await _taskService.getOrgTaskDetailByCode(orgId, taskCode);

    taskFiles.value = [];
    taskNotes.value = [];
    taskComments.value = [];

    // Get task attachments
    if (taskDetail.value?.attachmentCount || taskDetail.value?.noteCount) {
      _getTaskAttachments(orgId, taskDetail.value?.id);
    }

    // Get task comments
    if (taskDetail.value?.totalComment) {
      _getTaskComments(orgId, taskDetail.value?.id);
    }
  } catch (e: any) {
    const code = formatTaskCode(taskCode);

    taskError.value = {
      title: translate(`COMMON_ERROR_BY_CODE_${e?.error?.code}`, { code }),
      description: translate('TASK_LABEL_NOT_FOUND', { taskName: code }),
    };
  }
};

const _getTaskAttachments = async (orgId, taskId) => {
  if (!orgId || !taskId) return;

  const result = await _taskService.getOrgTaskMedia(orgId, taskId);

  taskFiles.value = result?.files;
  taskNotes.value = result?.notes;
};

const _getTaskComments = async (orgId, taskId) => {
  if (!orgId || !taskId) return;

  const res = await _taskService.getAllComments(orgId, taskId);

  taskComments.value = res?.result;
};
</script>

<template>
  <TaskDetail2
    v-if="!taskError && taskDetail"
    :organization-id="organizationId"
    :task-detail="taskDetail"
    :task-files="taskFiles"
    :task-notes="taskNotes"
    :task-comments="taskComments"
    :users="users"
    :groups="groups"
    :domains="domains"
    :projects="projects"
    :is-org-personal="isOrgPersonal"
    @on-state-change="onStateChange"
    @on-private-change="onPrivateChange"
    @on-name-change="onNameChange"
    @on-assignee-change="onAssigneeChange"
    @on-domain-change="onDomainChange"
    @on-planning-change="onPlanningChange"
    @on-deadline-change="onDeadlineChange"
    @on-urgency-change="onUrgencyChange"
    @on-description-change="onDescriptionChange"
    @on-attachments-change="onAttachmentsChange"
    @on-comment-add="onCommentAdd"
  />

  <!--TASK ERROR-->
  <div
    v-if="taskError"
    class="h-full flex-center flex-col gap-4 px-4 text-center"
  >
    <syn-animation name="searchNotFound" stype="width: 150px; height:150px" />
    <div
      class="font-semibold text-2xl text-current-500"
      v-html="taskError?.title"
    ></div>
    <div class="text-gray-500" v-html="taskError?.description"></div>
  </div>
</template>
