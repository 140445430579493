import EmbedTaskApi from '@/embed/api/task-api';
import { FilterQuery } from '@/domain/entities/task/FilterTask';
import {
    removeAwsFile,
    uploadTaskAttachments,
    uploadTaskSingleNote,
} from '@/ui/modules/task/helper/task-upload-helper';
import { TaskMedia } from '@/domain/entities/task/TaskResponseClass';
import { bucketTictop } from '@/ui/plugins/awss3/AwsS3Config';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';

export default class EmbedTaskServices {
    private static instance: EmbedTaskServices;
    taskApi: EmbedTaskApi;

    constructor() {
        this.taskApi = EmbedTaskApi.getInstance();
    }

    public static getInstance(): EmbedTaskServices {
        if (!EmbedTaskServices.instance) {
            // Get from local storage
            EmbedTaskServices.instance = new EmbedTaskServices();
        }

        return EmbedTaskServices.instance;
    }

    // async create(payloadForm: TaskCreateUpdatePayload): Promise<any> {
    //   let payloadUploadedToAws: any[] = [];

    //   // const attachmentServiceClass = new AttachmentServiceClass(
    //   //   'TASK_ATTACHMENT'
    //   // );

    //   try {
    //     // 1. Add files which has not ID
    //     if (payloadForm.files?.length > 0) {
    //       // const uploadFiles = await attachmentServiceClass.uploads(
    //       //   payloadForm.files
    //       // );
    //       const uploadFiles = await uploadTaskAttachments(payloadForm.files);

    //       payloadUploadedToAws = [...payloadUploadedToAws, ...uploadFiles];
    //       payloadForm.files = cloneDeep(uploadFiles);
    //     }
    //     // 2. Upload Note capture to aws
    //     payloadForm.notes = payloadForm.notes.filter(
    //       (o) => !o.id || (o.id && (o.isDeleted || o.isUpdated))
    //     );
    //     if (payloadForm.notes?.length > 0) {
    //       const uploadNotes = await uploadTaskAttachmentNotes(payloadForm.notes);
    //       payloadUploadedToAws = [...payloadUploadedToAws, ...uploadNotes];
    //       payloadForm.notes = cloneDeep(uploadNotes);
    //     }
    //     const res: any = await this.taskApi.createTask(payloadForm);

    //     const newTask = res?.result;

    //     if (newTask?.id) {
    //       const taskCode = newTask.code;
    //       const taskCodeShort = taskCode.substring(
    //         taskCode.indexOf('-') > -1 ? taskCode.indexOf('-') + 1 : 0,
    //         taskCode.Length
    //       );

    //       openNotification(
    //         AlertType.success,
    //         translate('COMMON_LABEL_SUCCESS'),
    //         `${taskCodeShort} - ${newTask.name}`,
    //         5000,
    //         NotificationPositionType.TopRight,
    //         {
    //           hasAction: true,
    //           isOpenTaskDetail: true,
    //           taskCode,
    //         }
    //       );
    //     }

    //     return Promise.resolve(newTask);
    //   } catch (error) {
    //     payloadUploadedToAws.forEach((item) => {
    //       awsS3Delete({
    //         Bucket: item.bucket,
    //         Key: item.path,
    //       });
    //     });

    //     console.log('🚀 TaskState', error);
    //     // remove file which uploaded to aws

    //     settingGlobalModal(
    //       'notification',
    //       '',
    //       translate('COMMON_ERROR_MESSAGE_CREATE_TASK') ||
    //         'Đã xảy ra lỗi khi tạo task, vui lòng thử lại.',
    //       '',
    //       ''
    //     );
    //     await ask();

    //     return Promise.reject(false);
    //   }
    // }
    async finish(taskId: number): Promise<any> {
        return await this.taskApi.finishTask(taskId);
    }
    changeState(taskId: number, state: number): Promise<any> {
        return this.taskApi.changeState({ taskId, state });
    }
    async setPlanned(taskId: number, planType: number): Promise<any> {
        return await this.taskApi.setPlannedTask({
            taskId,
            planType,
        });
    }
    async setAssignee(
        taskId: number,
        assigneeId: number | null,
        assignToMe: boolean
    ): Promise<any> {
        return await this.taskApi.setAssignee({
            taskId,
            assigneeId,
            assignToMe,
        });
    }
    async delete(taskId: number): Promise<any> {
        return await this.taskApi.deleteTask(taskId);
    }
    getTaskWithFilter(filter: any): Promise<any> {
        const filterData = new FilterQuery(filter);
        if (
            !filterData.listOwnerId &&
            !filterData.groupIds &&
            !filterData.assignorId
        )
            return Promise.resolve([]);

        return this.taskApi.getTaskWithFilter(filterData);
    }
    async getTaskMedia(taskId: number): Promise<TaskMedia> {
        const res = await this.taskApi.getAttachmentNoteByTaskId(taskId);
        return res['result'];
    }
    async getDetailByCode(taskCode: string): Promise<any> {
        const res = await this.taskApi.getDetailTaskByCode(taskCode);
        return res['result'];
    }
    async getDetailByID(taskId: number): Promise<any> {
        const res = await this.taskApi.getDetailTaskById(taskId);
        return res['result'];
    }

    getDetailTaskIncognitoById(taskId: number): Promise<any> {
        return this.taskApi.getDetailTaskIncognitoById(taskId);
    }

    reopenTask(taskId: number): Promise<any> {
        return this.taskApi.reopen(taskId);
    }
    renameTask(data: any): Promise<any> {
        return this.taskApi.renameTask(data);
    }
    removeAwsFile(bucket, filePath): any {
        let key = filePath;
        if (filePath.includes(bucket)) {
            key = filePath.split(bucket + '/').pop();
            key = key
                ?.replace('xlarge', 'original')
                ?.replace('small', 'original')
                ?.replace('medium', 'original')
                ?.replace('mini', 'original');
        }
        removeAwsFile(bucketTictop.AttachmentPhoto, key);
    }

    getCurrentTaskCycle(): Promise<any> {
        return this.taskApi.getCurrentTaskCycle();
    }
    changeScheduleTime(data: any): Promise<any> {
        return this.taskApi.changeScheduleTime(data);
    }
    async updateFiles(taskId, files) {
        //1. upload to aws
        const uploadFiles = await uploadTaskAttachments(files);
        //2. update db
        return this.taskApi.updateFiles({
            id: taskId,
            files: uploadFiles,
        });
    }
    removeFile(taskId, file) {
        return this.taskApi.updateFiles({
            id: taskId,
            files: [
                {
                    ...file,
                    isDeleted: true,
                },
            ],
        });
    }
    async updateNote(taskId, noteData) {
        // const { base64, drawData, audios } = noteData;
        const { base64, drawData, id } = noteData;
        // 1. update image, audios
        const payloadNote = await uploadTaskSingleNote({
            id,
            fileUrl: base64,
            type: 'image/jpeg',
            isBase64: true,
            isDeleted: false,
        });
        // TODO
        // uploadMultipleFiles(audios);
        // 2. save note data to database
        return this.taskApi.updateNote({
            id: taskId,
            note: {
                ...payloadNote,
                drawData: JSON.stringify(drawData),
                id,
            },
        });
    }
    removeNote(taskId, noteId) {
        return this.taskApi.updateNote({
            id: taskId,
            note: {
                id: noteId,
                isDeleted: true,
            },
        });
    }
    changeDescription(data: any): Promise<any> {
        return this.taskApi.changeDescription(data);
    }
    changeAssigneeTask(data: any): Promise<any> {
        return this.taskApi.changeAssigneeTask(data);
    }
    changeUrgency(taskId: number, urgency: number): Promise<any> {
        const payload = {
            id: taskId,
            priority: convertUrgencyToPriority(urgency),
            important: convertUrgencyToImportant(urgency),
        };
        return this.taskApi.changeUrgency(payload);
    }

    changeDomain(payload): Promise<any> {
        return this.taskApi.changeDomain(payload);
    }

    changePrivate(payload): Promise<any> {
        return this.taskApi.changePrivate(payload);
    }

    async getTaskListOverview(): Promise<any> {
        const res = await this.taskApi.getTaskListOverview();
        return res['result'];
    }
    async getTaskListPlannedToTreat(): Promise<any> {
        const res = await this.taskApi.getTaskListPlannedToTreat();
        return res['result'];
    }
    async getTaskListPlannedToFinish(): Promise<any> {
        const res = await this.taskApi.getTaskListPlannedToFinish();
        return res['result'];
    }
    getDataConfig(): Promise<any> {
        return this.taskApi.getDataConfig();
    }
}
