<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="m400.24 347.307-83.63 49.601-250.933 70.522-10.616-10.631-10.553-10.582 69.974-249.914 56.977-78.171 110.722 110.917z"
          fill="#ce4963"
          data-original="#ce4963"
          class=""
        ></path>
        <path
          d="m400.24 347.307-83.63 49.601-250.933 70.522-10.616-10.631 227.12-227.75z"
          fill="#ad1e55"
          data-original="#ad1e55"
        ></path>
        <path
          d="m442.648 361.483-126.038 35.425-.001-45.731h-77.282l-.721-78.163h-.269l-76.293.723-.007-77.434h-47.555l35.772-127.785 146.063 146.355z"
          fill="#ff6969"
          data-original="#ff6969"
          class=""
        ></path>
        <path
          d="m76.781 498.822c-17.536 17.571-46.063 17.571-63.599 0l-.014-.014c-8.489-8.512-13.168-19.824-13.168-31.859 0-12.049 4.679-23.375 13.182-31.888 17.529-17.563 46.035-17.57 63.578-.021l.035.035c8.489 8.512 13.168 19.824 13.168 31.874 0 12.034-4.679 23.361-13.182 31.873z"
          fill="#fde56f"
          data-original="#fde56f"
        ></path>
        <g>
          <path
            d="m277.68 0-28.999 7.658c.127.484 12.248 48.849-27.947 86.825l20.564 21.886c53.024-50.098 37.086-113.687 36.382-116.369z"
            fill="#00874a"
            data-original="#00874a"
          ></path>
          <path
            d="m329.739 39.593h29.987v30.07h-29.987z"
            fill="#fde56f"
            data-original="#fde56f"
          ></path>
          <path
            d="m454.42 133.687h29.987v30.07h-29.987z"
            fill="#ffd371"
            data-original="#ffd371"
          ></path>
          <path
            d="m439.426 275.359h29.988v30.071h-29.988z"
            fill="#ffd371"
            data-original="#ffd371"
          ></path>
          <path
            d="m512 208.969c-47.037-16.097-84.861-7.64-108.308 2.29-25.604 10.844-40.189 25.85-40.797 26.483l21.547 20.913c.115-.117 11.737-11.828 32.089-20.182 26.801-11.001 55.663-11.352 85.784-1.044z"
            fill="#006635"
            data-original="#006635"
          ></path>
          <path
            d="m451.184.301c-11.275 49.904-38.772 91.573-81.727 123.85-32.599 24.495-62.354 34.105-62.651 34.199l4.499 14.343 4.472 14.351c1.377-.431 34.139-10.879 70.718-38.122 48.655-36.239 81.137-85.333 93.934-141.977z"
            fill="#ff6969"
            data-original="#ff6969"
            class=""
          ></path>
        </g>
        <path
          d="m89.963 466.949c0 12.035-4.679 23.361-13.182 31.874-17.536 17.571-46.063 17.571-63.599 0l-.014-.014 63.592-63.769.035.035c8.489 8.512 13.168 19.824 13.168 31.874z"
          fill="#ffd371"
          data-original="#ffd371"
        ></path>
        <path
          d="m442.648 361.483-126.038 35.425-.001-45.731h-77.282l-.721-78.163h-.269l57.98-58.141z"
          fill="#ce4963"
          data-original="#ce4963"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
