<template>
  <div class="h-screen w-full flex-center bg-current-50">
    <div class="h-full flex-center flex-col space-y-2">
      <span
        class="text-7xl font-bold tracking-wider uppercase text-white"
        style="text-shadow: 0px 0px 5px #1a9793"
      >
        {{ $t('COMMON_LABEL_SORRY') }}
      </span>
      <span
        class="text-4xl text-white"
        style="text-shadow: 0px 0px 5px #1a9793"
      >
        {{ $t('COMMON_LABEL_PAGE_NOT_FOUND') }}
      </span>
      <span class="font-thin text-2xl w-1/2 text-center text-current-700">
        {{ $t('COMMON_LABEL_PAGE_NOT_FOUND_SUB') }}
      </span>
      <syn-animation name="404" />
      <div class="flex space-y-2 flex-col">
        <!-- <router-link to="/"> -->
        <!-- <div @click="$router.go()"> -->
        <syn-button
          :label="$t('COMMON_LABEL_BACK_TO_HOME')"
          class="w-full px-8 py-2 border-2"
          @click="reloadPage"
        >
        </syn-button>
        <!-- </div> -->
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const reloadPage = () => {
      router.push('/');
    };
    return {
      reloadPage,
    };
  },
};
</script>
