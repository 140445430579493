<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import dashboardStore from '@/store/dashboard';
import userStore from '@/store/user';
import { cloneDeep } from '@/ui/hooks/commonFunction';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { ConferenceClass } from '@/application/entities/VideoCallClass';
import { UserStatusEnum } from '@/domain/enums/UserStatusEnum';
import {
  settingGlobalModal,
  ask,
  tell,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import myProfileStore from '@/store/auth/my-profile';
import {
  AudioVideoCallStatusEnum,
  ConferenceTypeEnum,
  DurationList,
  ConferenceHosterType,
} from '@/domain/enums/VideoCallEnum';

const props = defineProps({
  durationTimeCall: {
    type: Number,
    default: 15,
  },
  listReceivers: {
    type: Array,
    default: () => [],
  },
  isHiddenAudioCall: {
    type: Boolean,
    default: false,
  },
  isHiddenVideoCall: {
    type: Boolean,
    default: false,
  },
  isHiddenSwitchTime: {
    type: Boolean,
    default: false,
  },
  isAddParticipant: {
    type: Boolean,
    default: true,
  },
});
const emits = defineEmits(['cancel']);

const _dashboardStore = dashboardStore();
const _userStore = userStore();

const myProfile = computed(() => myProfileStore().myProfile);
const hostUserId = computed(() => myProfile.value?.id);
const hostUserName = computed(() => myProfile.value?.fullName);
const hostUserAvatar = computed(() => myProfile.value?.avatarUrl);
const durationTime = ref(props?.durationTimeCall);
const isLoadingCall = ref(false);
const callType = ref(ConferenceTypeEnum.Audio);
const allUsers = ref([] as any[]);
const listUserCall = ref<any[]>(props?.listReceivers || []);
const isOpenAddParticipant = ref(false);

watch(
  () => props?.durationTimeCall,
  () => {
    durationTime.value = props?.durationTimeCall;
  }
);

watch(
  () => props?.listReceivers,
  () => {
    listUserCall.value = props?.listReceivers;
  }
);

onMounted(async () => {
  if (
    !_userStore.listShortInfosUser ||
    _userStore.listShortInfosUser.length == 0
  ) {
    await _userStore.getShortInfosUser;
  }
  allUsers.value = cloneDeep(
    _userStore.listShortInfosUser
      .filter((o) => o.id !== getCurrentUserId())
      .map((user) => {
        return {
          ...user,
          callStatus: _dashboardStore.getUserStatus(user.id),
        };
      })
  );
});

const onCallFromFooter = async (call_type) => {
  callType.value = call_type;
  let receivers;
  const myCallStatus: string = _dashboardStore.getUserStatus(
    getCurrentUserId()
  );

  if (myCallStatus?.includes(UserStatusEnum.OnCall)) {
    settingGlobalModal({
      type: 'notification',
      title: '',
      content:
        translate('COMMON_LABEL_YOU_ARE_ON_ANOTHER_CALL') ||
        'You are on another call',
      confirmable: true,
      closeable: false,
    });
    await ask();
    return;
  }
  if (
    listUserCall.value?.length <= 0 ||
    (listUserCall.value?.length === 1 &&
      listUserCall.value.pop() === getCurrentUserId())
  ) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('CALENDAR_LABEL_ALERT_CANT_CALL'),
      cancelLabel: translate('COMMON_LABEL_CANCEL'),
      confirmLabel: props.isAddParticipant
        ? translate('CALENDAR_LABEL_ADD_PARTICIPANT')
        : '',
      confirmable: true,
      closeable: true,
    });
    const confirm = await ask();
    if (confirm && props.isAddParticipant) {
      tell(false);
      isOpenAddParticipant.value = true;
    }
    return;
  }
  if (listUserCall.value?.length > 0) {
    receivers = allUsers.value
      .filter((u) => {
        let index = listUserCall.value.findIndex(
          (p) => p === u.userId || p === u.id
        );
        if (index !== -1 && u.userId !== hostUserId.value) return u;
      })
      .map((user) => {
        return {
          ...user,
          id: user.userId || user.id,
        };
      });
  }
  onCallByTypeAndReceivers(receivers);
};

const onCallByTypeAndReceivers = async (receivers) => {
  if (!receivers || receivers.length <= 0) return;
  isLoadingCall.value = true;

  try {
    const freeReceivers = receivers.filter((user) => {
      const userCallStatus = _dashboardStore.getUserStatus(user.userId);
      return (
        !userCallStatus || !userCallStatus?.includes(UserStatusEnum.OnCall)
      );
    });

    const newConference = new ConferenceClass(
      callType.value,
      {
        id: hostUserId.value,
        name: hostUserName.value,
        avatar: hostUserAvatar.value,
      } as ConferenceHosterType,
      AudioVideoCallStatusEnum.Started,
      durationTime.value,
      freeReceivers
    );

    await newConference.start();
  } catch (error) {
    console.log(error);
  }

  isLoadingCall.value = false;
  emits('cancel');
};

const onCallWithType = async () => {
  await onCallFromFooter(callType.value);
};

const onChooseMember = async (members) => {
  listUserCall.value = [];
  members.forEach((el) => {
    listUserCall.value.push(el?.id);
  });
  await onCallWithType();
  listUserCall.value = props?.listReceivers || [];
  isOpenAddParticipant.value = false;
};
const onCancel = () => {
  listUserCall.value = props?.listReceivers || [];
  isOpenAddParticipant.value = false;
};

const selectedUsers = computed<any[]>(() => {
  return listUserCall.value?.map((id) => {
    return userStore().allUserByIds[id];
  });
});
</script>
<template>
  <div class="flex items-center space-x-2">
    <syn-input-number v-model="durationTime">
      <vig-dropdown :arrow="false" placement="bottom" @click.stop="() => {}">
        <template #dropdown-toggle>
          <div class="flex-center space-x-1 cursor-pointer px-2">
            <span class="h-8 flex-center">
              {{ durationTime }}
            </span>
            <span>minute</span>
            <SynIcon
              name="arrow-down"
              class="fill-gray-500"
              custom-class="w-2 h-2"
            />
          </div>
        </template>
        <template #dropdown-menu>
          <div v-perfect-scrollbar class="list-ul text-xs w-full">
            <div
              v-for="(item, index) in DurationList"
              :key="index"
              class="
                list-li
                dropdown-item
                px-4
                py-2
                text-xs text-black
                hover:bg-gray-100 hover:text-current-700
                cursor-pointer
                break-words
              "
              @click.stop="durationTime = item"
            >
              <span>
                {{ item }}
              </span>
              <span>min</span>
            </div>
          </div>
        </template>
      </vig-dropdown>
    </syn-input-number>
    <syn-button
      name-icon="phone"
      :title="$t('COMMON_LABEL_START_CALL')"
      :disabled="durationTime === 0 || isLoadingCall"
      :is-loading="isLoadingCall && callType == ConferenceTypeEnum.Audio"
      @click="onCallFromFooter(ConferenceTypeEnum.Audio)"
    ></syn-button>
    <syn-button
      name-icon="video"
      :title="$t('COMMON_LABEL_START_CALL')"
      :disabled="durationTime === 0 || isLoadingCall"
      :is-loading="isLoadingCall && callType == ConferenceTypeEnum.Video"
      @click="onCallFromFooter(ConferenceTypeEnum.Video)"
    ></syn-button>
  </div>

  <SelectMultipleUserModal
    v-if="isOpenAddParticipant"
    :selected-users="selectedUsers"
    :must-have-users="[myProfile]"
    is-ignore-my-self
    @cancel="onCancel"
    @save="onChooseMember"
  />
</template>
