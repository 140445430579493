import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { AttachmentInTask } from '@/domain/entities/PresentClass/RemoteConfigClass';
import {
    getRemoteConfigValueByKey,
    RemoteConfigKey,
} from '@/ui/plugins/firebases/firebase-remote-config';
import { Value } from '@firebase/remote-config';

interface RemoteState {
    publicEncryptKey: string;
    encodeUserId: boolean;
    encodeGroupId: boolean;
    verificationPhoneProvider: string;
    configAttachmentInTask: AttachmentInTask;
    allowExtensionDefault: string;
    webTaskUploadContentTypes: string;
    webTaskUploadMaxFilesize: number;
}

export default defineStore({
    id: storeId.ticketRemoteConfig,
    state: () =>
        ({
            publicEncryptKey: '',
            encodeUserId: false,
            encodeGroupId: false,
            verificationPhoneProvider: '',
            configAttachmentInTask: new AttachmentInTask({}),
            webTaskUploadMaxFilesize: 25,
            allowExtensionDefault:
                '.jpg, .jpeg, .png, .svg,.gif, .bmp, .txt, .rtf, .pdf, .doc, .docx, .ppt, .pptx, .csv, .xls, .xlsx, .xml, .js, .json, .mp3, .mp4, .oga, .ogv, .mpeg, .zip, .rar, .7z',
        } as RemoteState),
    getters: {
        getVerificationPhoneProvider(state): string {
            return state.verificationPhoneProvider;
        },
        getConfigAttachmentInTask(state): AttachmentInTask {
            return state.configAttachmentInTask;
        },
    },
    actions: {
        fetchRemoteConfigs() {
            const configString = getRemoteConfigValueByKey(
                RemoteConfigKey.ConfigAttachmentInTask
            ).asString();

            if (!configString) return;
            this.configAttachmentInTask = JSON.parse(configString);

            this.webTaskUploadContentTypes = getRemoteConfigValueByKey(
                RemoteConfigKey.WebTaskUploadContentTypes
            ).asString();

            this.webTaskUploadMaxFilesize = getRemoteConfigValueByKey(
                RemoteConfigKey.WebTaskUploadMaxFilesize
            ).asNumber();
        },
        getRemoteConfig(key): Value {
            return getRemoteConfigValueByKey(key);
        },
        getPublicEncryptKey(): string {
            const isEncode = getRemoteConfigValueByKey(
                RemoteConfigKey.PublicEncryptKey
            );
            return isEncode.asString();
        },
        getEncodeUserId(): boolean {
            const isEncode = getRemoteConfigValueByKey(
                RemoteConfigKey.EncodeUserId
            );
            return isEncode.asBoolean();
        },
        getEncodeGroupId(): boolean {
            const isEncode = getRemoteConfigValueByKey(
                RemoteConfigKey.EncodeGroupId
            );
            return isEncode.asBoolean();
        },
    },
});
