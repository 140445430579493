<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    label?: string;
    disabled?: boolean;
    readonly?: boolean;
    size?: 'sm' | 'md' | 'lg';
    align?: 'center' | 'start' | 'between';
  }>(),
  {
    size: 'md',
    align: 'start',
  }
);

const emit = defineEmits(['update:modelValue', 'onChange']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const onValueChange = () => {
  emit('update:modelValue', !value.value);
  emit('onChange', !value.value);
};
</script>

<template>
  <label
    class="cursor-pointer group/switch"
    :class="[
      disabled ? 'opacity-60 pointer-events-none' : '',
      readonly ? 'pointer-events-none' : '',
    ]"
  >
    <span class="flex items-center gap-2" :class="'justify-' + align">
      <input
        v-model="value"
        :disabled="disabled"
        type="checkbox"
        class="hidden"
        @change="onValueChange"
      />

      <slot name="prepend"></slot>

      <!--TOGGLE-->
      <span
        class="p-0.5 rounded-full flex"
        :class="{
          'bg-current group-hover/switch:bg-current-500': value,
          'bg-gray-400 group-hover/switch:bg-gray-500': !value,
          'w-8': size === 'sm',
          'w-9': size === 'md',
          'w-[3.25rem]': size === 'lg',
          'opacity-60': disabled || readonly,
        }"
      >
        <span
          class="bg-white rounded-full transition-transform"
          :class="{
            'w-3.5 h-3.5': size === 'sm',
            'w-4 h-4': size === 'md',
            'w-6 h-6': size === 'lg',
            'translate-x-3.5': value && size === 'sm',
            'translate-x-4': value && size === 'md',
            'translate-x-6': value && size === 'lg',
          }"
        ></span>
      </span>

      <slot>
        <span v-if="label">{{ $t(label) || label }}</span>
      </slot>
    </span>
  </label>
</template>
