import { CalendarsType } from '@/domain/enums/CalendarsEnums';

export class EventDetailItemClass {
    id: string;
    calendarId: number;
    createdBy: number;
    createdDate: string;
    description: string;
    sourceId: string;
    sourceType: number;
    updateDate: string;
    updatedBy: number;
    workspace: string;
    meetingType: number;
    repeatType: number;

    srcAvatar: string;
    nameUser: string;
    titleEvent: string;
    urgency: number;
    startDate: number;
    endDate: number;
    allDay: boolean;
    system: boolean;
    type: string;
    status: string;
    calendarType: CalendarsType;
    constructor(data) {
        this.id = data?.calendarId;
        this.calendarId = data?.calendarId;
        this.createdBy = data?.createdBy;
        this.createdDate = data?.createdDate;
        this.description = data?.description;
        this.sourceId = data?.sourceId;
        this.sourceType = data?.sourceType;
        this.updateDate = data?.updateDate;
        this.updatedBy = data?.updatedBy;
        this.workspace = data?.workspace;
        this.meetingType = data?.meetingType;
        this.repeatType = data?.repeatType;

        this.srcAvatar = data?.srcAvatar || data?.src;
        this.nameUser = data?.nameUser || data?.name;
        this.titleEvent = data?.titleEvent || data?.title;
        this.urgency = data?.urgency;
        this.startDate = data?.startDate || data?.start;
        this.endDate = data?.endDate || data?.end;
        this.allDay = data?.allDay;
        this.system = data?.system;
        this.type = data?.type;
        this.status = data?.status;
        this.calendarType = CalendarsType.EVENT;
    }
}
