<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  beforeUpdate: {
    type: Object,
    default: () => {},
  },
  afterUpdate: {
    type: Object,
    default: () => {},
  },
});
const beforeActivity = computed(() => props.beforeUpdate);
const afterActivity = computed(() => props.afterUpdate);
</script>
<template>
  <div class="flex items-start justify-between">
    <div class="flex w-1/2 items-center justify-between">
      <div
        v-if="
          beforeActivity?.Description ||
          beforeActivity?.TaskName ||
          beforeActivity?.CommentContent
        "
        class="flex items-center text-xs rounded"
      >
        <span v-if="beforeActivity?.TaskName" style="word-break: break-word">{{
          beforeActivity?.TaskName
        }}</span>
        <span
          v-if="beforeActivity?.Description"
          style="word-break: break-word"
          v-html="beforeActivity?.Description"
        ></span>
        <span
          v-if="beforeActivity?.CommentContent"
          style="word-break: break-word"
          class="msg-text italic text-xs text-gray-600"
          v-html="beforeActivity?.CommentContent"
        ></span>
      </div>
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </div>
    <span class="px-2">→</span>
    <div
      v-vig-tooltip="{
        content:
          afterActivity?.Description ||
          afterActivity?.TaskName ||
          afterActivity?.CommentContent,
      }"
      class="flex w-1/2 justify-start"
    >
      <div
        v-if="
          afterActivity?.Description ||
          afterActivity?.TaskName ||
          afterActivity?.CommentContent
        "
        class="flex items-center text-xs rounded"
      >
        <span v-if="afterActivity?.TaskName" style="word-break: break-word">
          {{ afterActivity?.TaskName }}
        </span>
        <span
          v-if="afterActivity?.Description"
          style="word-break: break-word"
          v-html="afterActivity?.Description"
        ></span>
        <span
          v-if="afterActivity?.CommentContent"
          style="word-break: break-word"
          class="msg-text italic text-xs text-gray-600"
          v-html="afterActivity?.CommentContent"
        ></span>
      </div>
      <span v-else class="text-xs text-gray-500">{{
        $t('COMMOM_LABEL_NONE')
      }}</span>
    </div>
  </div>
</template>
