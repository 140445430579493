<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M55.896 48.479v11.956c0 1.594-1.3 2.877-2.894 2.877H35.223l-1.196-13.62v-.017c-.087-.572.104-1.144.451-1.612a.348.348 0 0 0 .069-.104l1.542-2.686a1.158 1.158 0 0 0 0-1.161l-1.542-2.669a1.158 1.158 0 0 0-1.005-.589h-3.084c-.416 0-.797.225-1.005.589l-1.542 2.669a1.158 1.158 0 0 0 0 1.161l1.542 2.686a.368.368 0 0 0 .069.104c.347.468.537 1.04.451 1.612v.017l-1.196 13.62H10.998a2.884 2.884 0 0 1-2.894-2.877V48.479c0-4.003 2.374-7.555 5.909-9.149a9.684 9.684 0 0 1 1.733-.606l6.567-2.357c0-.069.017-.139.052-.208l1.161-2.911a1.008 1.008 0 0 1 1.456-.502l4.193 2.53L32 36.973l2.825-1.698 4.176-2.513c.537-.329 1.248-.104 1.473.485l1.161 2.911a.457.457 0 0 1 .052.208l6.567 2.357a9.79 9.79 0 0 1 1.733.606 10.05 10.05 0 0 1 5.909 9.15zM43.437 12.055v3.154c0 .797-.052 1.525-.139 2.201a21.363 21.363 0 0 1-.762 3.5 20.14 20.14 0 0 1-3.483 6.533 20 20 0 0 1-.797.918l-.918.988a6.298 6.298 0 0 1-4.644 2.062h-1.386a6.292 6.292 0 0 1-4.644-2.062l-.918-.988a13.109 13.109 0 0 1-.78-.918 19.14 19.14 0 0 1-2.582-4.176 19.475 19.475 0 0 1-1.664-5.874 14.646 14.646 0 0 1-.156-2.114v-3.154c0-1.074.139-2.131.433-3.154C22.383 4.102 26.906.688 32 .688s9.617 3.414 11.003 8.3c.295.97.434 2.028.434 3.067z"
        data-original="#000000"
      />
    </g>
  </svg>
</template>
