<template>
  <div id="example-2" class="starburst example">
    <span>
      <slot />
    </span>
  </div>
</template>
<style scoped>
.starburst {
  font: 0.8em/1.5 'Lobster', georgia, serif;
  width: 1.75em;
  height: 1.75em;
  text-align: center;
}

.starburst,
.starburst span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.starburst span {
  width: 100%;
  height: 100%;
  background: inherit;
  transform: rotate(45deg);
}

.starburst:before,
.starburst:after,
.starburst span:before,
.starburst span:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  transform: rotate(30deg);
}

.starburst:after {
  transform: rotate(-30deg);
}

.starburst span:after {
  transform: rotate(30deg);
}
.starburst span:before {
  transform: rotate(-30deg);
}

#example-1 {
  left: 25%;
}

#example-2 {
  left: 75%;
  transform: rotate(-45deg);
}
</style>
