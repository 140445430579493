<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import permissionStore from '@/store/permission';
import SelectMultipleUserModal from '@/ui/components/user/SelectMultipleUserModal.vue';
import myProfileStore from '@/store/auth/my-profile';
import UserById from '@/ui/components/user/UserById.vue';

const emit = defineEmits(['onChooseMember', 'cancel']);
const props = defineProps<{
  defaultUsers?: any[];
  callType?: string;
  isLoadingCall?: boolean;
}>();
const isLoadingCallBtn = ref(props.isLoadingCall);

const searchText = ref(null);
const selectedMemberList = ref<any[]>([]);

watch(
  () => props?.isLoadingCall,
  () => {
    isLoadingCallBtn.value = props?.isLoadingCall;
    if (!isLoadingCallBtn.value) {
      isOpenEdit.value = false;
    }
  }
);

const myProfile = computed<any>(() => {
  return myProfileStore().myProfile;
});

onMounted(async () => {
  selectedMemberList.value = [
    {
      ...myProfile.value,
    },
    ...(props.defaultUsers?.filter((u) => u?.id !== myProfile.value?.id) || []),
  ];
});

// const filteredGroups = computed(() => {
//   const searchTxt = ignoreUnicode(searchText.value);

//   if (!searchTxt) return allMyGroups;

//   return (allMyGroups || []).filter(
//     (group) => group?.name && ignoreUnicode(group?.name).includes(searchTxt)
//   );
// });

const isOpenEdit = ref(false);

const onSave = (selectedUsers) => {
  isOpenEdit.value = false;
  selectedMemberList.value = [...selectedUsers];
  emit('onChooseMember', selectedMemberList.value);
};

const onCancel = () => {
  isOpenEdit.value = false;
};

const isPersonalWarning = ref<boolean>(false);
const onOpenAddMore = () => {
  if (permissionStore().organizationModel === 'PERSONAL') {
    isPersonalWarning.value = true;
    return;
  }

  isOpenEdit.value = true;
};

const onRemoveMember = (id) => {
  selectedMemberList.value = selectedMemberList.value.filter(
    (u) => u?.id !== id
  );
};
</script>

<template>
  <div class="flex items-center flex-wrap">
    <template v-if="selectedMemberList?.length > 0">
      <div
        v-for="contact in selectedMemberList"
        :key="contact?.id"
        class="
          rounded-full
          shadow
          bg-current-50
          py-1
          pl-1
          pr-2
          mr-1
          mt-1
          space-x-2
          flex-center
          relative
          hover-to-show__parent
        "
      >
        <UserById :user-id="contact.id" />
        <button
          v-if="contact?.id !== getCurrentUserId()"
          class="
            p-1
            rounded-full
            bg-current-100
            flex-center
            absolute
            right-1
            hover-to-show__children
          "
          @click="onRemoveMember(contact?.contactId)"
        >
          <SynIcon name="Close" custom-class="w-3 h-3" />
        </button>
      </div>
    </template>

    <div
      class="
        rounded-full
        w-7
        h-7
        hover:bg-current-100
        fill-gray-500
        flex-center
        cursor-pointer
        bg-current-50
        py-1
        ml-1
        mt-1
      "
      @click="onOpenAddMore"
    >
      <SynIcon
        name="plus"
        class="fill-current text-current-600"
        custom-class="w-3 h-3"
        is-active
      />
    </div>
  </div>

  <SynModal
    v-if="isPersonalWarning"
    z-index="z-60"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    container-style="height: 80vh"
    style-body="p-0 flex flex-col"
    @cancel="isPersonalWarning = false"
  >
    <template #header>
      <div>
        {{ $t('CALENDAR_TITLE_ADD_PEOPLE_INVOLVED') || 'Add involved people' }}
      </div>
    </template>

    <template #body>
      <div class="p-3">
        <VigSearchBox v-model="searchText" autofocus />
      </div>
      <div
        class="
          flex-1 flex-center flex-col
          px-8
          space-y-4
          overflow-auto
          small-scrollbar
        "
      >
        <span class="text-sm text-gray-600 text-center">
          {{ $t('PERSONAL_LABEL_HAVE_NOT_MEETING_ADD_MEMBER') }}
        </span>
        <span class="text-sm text-gray-600">
          {{ $t('PERSONAL_LABEL_PLEASE_ADD_NEW_FRIEND_TO_ADD_MEMBER') }}
        </span>
      </div>
    </template>
  </SynModal>
  <SelectMultipleUserModal
    v-if="isOpenEdit"
    :selected-users="selectedMemberList"
    :must-have-users="[myProfile]"
    is-ignore-my-self
    @cancel="onCancel"
    @save="onSave"
  />
</template>
