<script setup lang="ts">
import OrganizationPlan from '@/domain/entities/payment/OrganizationPlan';
import PlansOverview from '@/ui/modules/settings/payments/overview/PlansOverview.vue';
import { computed, ref } from 'vue';
import plansStore from '@/store/plans';
import { IOrganizationDetail } from '@/application/types/organization/organization.type';
import organizationStore from '@/store/organization';
import paymentStore from '@/store/payment';
import PaymentResult from '@/ui/modules/payments/PaymentResult.vue';
import { EPaymentPurpose } from '@/application/types/payment.types';

defineProps<{
  isDisablePayButton?: boolean;
}>();
defineEmits<{
  (e: 'onClose'): void;
  (e: 'onChoosePlan', planId: string): void;
}>();
const _planStore = plansStore();
const _paymentStore = paymentStore();
const organizationInfo = computed<IOrganizationDetail>(
  () => organizationStore().organizationInfo
);
const paymentConfigs = computed(() => _paymentStore.paymentConfigs);
const planCapacityByKeys = computed<any>(() => _planStore.planCapacityByKeys);
const currentOrgPlan = computed<OrganizationPlan>(
  () =>
    new OrganizationPlan(
      _planStore.currentOrgPlan,
      organizationInfo.value,
      paymentConfigs.value,
      planCapacityByKeys.value,
      _planStore.capacityByPlanIds
    )
);

const isLoading = ref<boolean>(false);
const onUpgrade = async (planId, isBuyMore) => {
  if (!planId) return;
  isLoading.value = true;
  if (isBuyMore) {
    const canBuyMore = await currentOrgPlan.value.canBuyMore();
    if (!canBuyMore?.continue) return;
    isBuyMore = canBuyMore?.isBuyMore;
  }

  try {
    // const totalUsers = currentOrgPlan.value.getTotalUserBySize();
    // await _paymentStore.checkAndMakeNewPayment({
    //   planId,
    //   purpose: isBuyMore ? EPaymentPurpose.GET_MORE : null,
    //   period: 3,
    //   totalUsers,
    // });
    _paymentStore.isCreatingPaymentToken = true;

    await new OrganizationPlan(
      _planStore.currentOrgPlan,
      organizationStore().organizationInfo,
      paymentConfigs.value,
      _planStore.planCapacityByKeys,
      _planStore.capacityByPlanIds
    ).createPayment({
      planId: currentOrgPlan.value?.planId,
      purpose: isBuyMore ? EPaymentPurpose.GET_MORE : null,
      period: null,
      totalUsers: null,
    });
  } catch (error) {
    console.log('🚀 ~ error', error);
  } finally {
    _paymentStore.isCreatingPaymentToken = false;
  }

  isLoading.value = false;
};
const onChoosePlanToUpgrade = (planId) => {
  onUpgrade(planId, false);
};
const isDowngradingToFreeSuccess = ref<boolean>(false);
const paymentPurpose = ref<EPaymentPurpose>(EPaymentPurpose.DOWNGRADE);
const useFreeSuccess = (purpose) => {
  paymentPurpose.value = purpose;
  isDowngradingToFreeSuccess.value = true;
};
</script>

<template>
  <PaymentResult
    v-if="isDowngradingToFreeSuccess"
    :current-plan-id="'FREE'"
    :previous-plan-id="'FREE'"
    :total-users="5"
    :action-name="paymentPurpose"
    need-to-reload-page
  />
  <SynModal
    v-else
    is-hidden-footer
    is-hidden-header
    disable-click-outside
    disable-esc-to-close
    disable-element-active
    z-index="z-50"
    style-body="p-0"
    :cancel-text="$t('COMMON_LABEL_CANCEL')"
    :container-style="`max-height: 1920px;
        max-width: 1920px;
        min-height:500px;
        min-width:600px;
        width: 100%; 
        height: 100%;
        `"
    :background-style="`padding: 20px;`"
    @cancel="$emit('onClose')"
  >
    <template #body>
      <PlansOverview
        :is-disable-pay-button="isDisablePayButton || isLoading"
        @on-choose-plan="onChoosePlanToUpgrade"
        @use-free-success="useFreeSuccess"
      />
    </template>
  </SynModal>
</template>
