<script setup lang="ts">
import { onMounted, ref } from 'vue';
import GroupUpdateMemberList from '@/ui/modules/group/components/GroupUpdateMemberList.vue';
import GroupDetailEntity, {
  IGroupDetailEntity,
} from '@/domain/entities/group/GroupDetailEntity';

const props = withDefaults(
  defineProps<{
    groupDetail: IGroupDetailEntity;
  }>(),
  {}
);
const emit = defineEmits(['onCancel', 'onUpdateSuccess']);

const groupDetailDataRef = ref<GroupDetailEntity>(
  new GroupDetailEntity(props.groupDetail)
);
onMounted(() => {
  groupDetailDataRef.value = new GroupDetailEntity(props.groupDetail);
});

const onCancel = () => {
  emit('onCancel');
};

const isSaveUser = ref(false);

const onSaveUpdateMembers = async () => {
  try {
    isSaveUser.value = true;

    const isUpdateSuccess = await groupDetailDataRef.value.updateMembers([
      ...groupDetailDataRef.value?.groupUsers,
    ]);

    if (!isUpdateSuccess) return;

    emit('onUpdateSuccess', groupDetailDataRef.value?.groupUsers);
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  } finally {
    isSaveUser.value = false;
  }
};
</script>
<template>
  <SynModal
    z-index="z-50"
    container-class="md:w-1/4"
    container-style="min-width: 44rem;max-height: 48rem"
    disable-click-outside
    @cancel="onCancel"
  >
    <template #header>
      <div class="w-full flex items-center text-base space-x-2">
        <span class="w-max">
          {{ $t('TASK_CREATE_GROUP_FORM_INVITE_COLLEAGUE') + ':' }}
        </span>
        <span class="text-current">{{ groupDetail?.name }}</span>
      </div>
    </template>
    <template #body>
      <GroupUpdateMemberList
        v-model="groupDetailDataRef.groupUsers"
        :group-detail="groupDetailDataRef"
      />
    </template>
    <template #footer>
      <div class="pr-4 flex py-4 justify-end space-x-2">
        <SynButton
          color="gray"
          type-text
          :label="$t('COMMON_LABEL_CANCEL')"
          :class="isSaveUser ? 'cursor-not-allowed' : ''"
          @click="onCancel"
        />
        <SynButton
          :is-loading="isSaveUser"
          :label="$t('COMMON_LABEL_SAVE')"
          :disabled="groupDetailDataRef.groupUsers?.length < 1 || isSaveUser"
          @click="onSaveUpdateMembers"
        />
      </div>
    </template>
  </SynModal>
</template>
