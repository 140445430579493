<script setup lang="ts">
import { ref } from 'vue';
import SearchTaskOwners from '@/ui/components/search/SearchTaskOwners.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskSearchingList from '@/ui/modules/task/table/TaskSearchingList.vue';

const props = withDefaults(
  defineProps<{
    isSelectMultiple?: boolean;
    viewTaskMode?: 'CARD' | 'LIST';
    filterFunction?: Function;
  }>(),
  {
    viewTaskMode: 'CARD',
    filterFunction: undefined,
  }
);
const emit = defineEmits(['onClose', 'onConfirmSelect', 'onSelectOne']);

const currentTaskOwner = ref<{
  avatar: string;
  id: number;
  name: string;
  type: 'USER' | 'GROUP' | 'DOMAIN';
}>({
  avatar: '',
  id: getCurrentUserId(),
  name: '',
  type: 'USER',
});

const onChooseOwner = (owner) => {
  currentTaskOwner.value = owner;
};
const selectedTaskIds = ref<string[]>([]);
const onClickDetail = (taskDetail) => {
  if (!props.isSelectMultiple) {
    emit('onSelectOne', taskDetail);

    return;
  }
  const isExisted = selectedTaskIds.value?.some((id) => id == taskDetail?.id);

  if (isExisted)
    selectedTaskIds.value = selectedTaskIds.value?.filter(
      (id) => id !== taskDetail?.id
    );
  else selectedTaskIds.value?.push(taskDetail?.id);
};

const onConfirmSelect = (allTaskByIds) => {
  emit('onConfirmSelect', [...selectedTaskIds.value], allTaskByIds);

  selectedTaskIds.value = [];
};
</script>
<template>
  <div
    class="
      flex-1
      min-h-0
      flex
      h-full
      overflow-hidden
      items-stretch
      relative
      bg-white
    "
  >
    <div style="width: 20rem">
      <SearchTaskOwners
        :selected-ids="[`USER_${currentTaskOwner?.id}`]"
        :owner-type-list="['ITS_ME', 'USER', 'GROUP']"
        is-hidden-action-item
        is-hidden-quickly-scroll
        @on-choose-owner="onChooseOwner"
        @on-close="$emit('onClose')"
        @on-confirm-select="onConfirmSelect"
      />
    </div>
    <div class="flex-1">
      <TaskSearchingList
        :key="currentTaskOwner?.id"
        is-readonly
        is-only-search
        :view-task-mode="viewTaskMode"
        :type="currentTaskOwner?.type || 'USER'"
        :owner-id="currentTaskOwner?.id"
        :selectable="isSelectMultiple"
        :selected-task-ids="selectedTaskIds"
        :filter-function="filterFunction"
        class="rounded-md"
        @on-click-detail="onClickDetail"
        @on-close="$emit('onClose')"
        @on-confirm-select="onConfirmSelect"
      />
    </div>
  </div>
</template>
