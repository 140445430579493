import BaseRequestEmbed from '@/base/api/base-request-embed';

export default class EmbedGroupApi extends BaseRequestEmbed {
    private static instance: EmbedGroupApi;
    constructor() {
        super();
    }

    public static getInstance(): EmbedGroupApi {
        if (!EmbedGroupApi.instance) {
            // Get from local storage
            EmbedGroupApi.instance = new EmbedGroupApi();
        }

        return EmbedGroupApi.instance;
    }

    createAndUpdate(data) {
        return this.request({
            url: `Group/UpdateGroup`,
            method: 'post',
            data,
        });
    }
    getAllGroup() {
        return this.request({
            url: `Group/GetAll`,
            method: 'get',
        });
    }
    getSameGroupOfUsers(userId) {
        return this.request({
            url: `/Group/GetSameGroupOfUsers?userId=${userId}`,
            method: 'get',
        });
    }
    getAllGroupByUser(userId = 0) {
        return this.request({
            url: `Group/GetGroupByUser?userId=${userId}`,
            method: 'get',
        });
    }
    getGroupListOverview() {
        return this.request({
            url: `Group/GetGroupListOverview`,
            method: 'get',
        });
    }
    fetchGroupDetail(groupId) {
        return this.request({
            url: `Group/GetGroupDetail?groupId=${groupId}`,
            method: 'get',
        });
    }

    showLessPublicGroup() {
        return this.request({
            url: `Group/ShowLessPublicGroup`,
            method: 'post',
        });
    }

    getPublicGroupSetting() {
        return this.request({
            url: `Group/GetPublicGroupSetting`,
            method: 'get',
        });
    }
    addUserToGroup(data) {
        return this.request({
            url: `Group/AddUserToGroup`,
            method: 'post',
            data,
        });
    }

    deleteGroup(data) {
        return this.request({
            url: `Group/DeleteGroup`,
            method: 'post',
            data,
        });
    }
}
