<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { companySizeRange } from '@/ui/common/constants/org';
import organizationStore from '@/store/organization';
import { IOrganizationCategory } from '@/application/types/common/register.types';

const props = defineProps<{
  size: string | undefined;
  organizationModel: string;
  category: string | undefined;
  subCategory: string | undefined;
  type: string | undefined;
  domainName: string | undefined;
  isProcessD?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:size', value: string): void;
  (e: 'update:category', value: string): void;
  (e: 'update:subCategory', value: string): void;
  (e: 'update:type', value: string): void;
  (e: 'update:domainName', value: string): void;
}>();

const organizationCategories = computed<IOrganizationCategory[]>(() => {
  return organizationStore().organizationCategories;
});

const currentCategory = ref<IOrganizationCategory | undefined>(undefined);
const currentSubCategory = ref<IOrganizationCategory | undefined>(undefined);

const memberSizeRangeByMode = computed<any>(() => {
  // if (infoPayload.value?.model == 'TEAM') return teamSizeRange;
  // if (isShowFullSize.value) return companySizeRange;
  // return teamSizeRange?.slice(0, 4);
  return companySizeRange;
});

onMounted(() => {
  if (props.category) {
    currentCategory.value = organizationCategories.value?.find(
      (ca) => ca?.code == props.category
    );
  }

  if (props.subCategory && currentCategory.value?.id) {
    currentSubCategory.value = currentCategory.value?.children?.find(
      (ca) => ca?.code == props.subCategory
    );
  }
});

const organizationTypes = computed(() => organizationStore().organizationTypes);
const onChooseCategory = (category) => {
  currentCategory.value = category;
  emit('update:category', category?.code);
  emit('update:type', category?.domain);
  emit(
    'update:domainName',
    organizationTypes.value?.find((o) => o?.code == category?.domain)?.name ||
      ''
  );
};
const onChooseSubCategory = (category) => {
  currentSubCategory.value = category;
  emit('update:subCategory', category?.code);
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="w-full text-2xl text-center">
      <span> {{ $t('REGISTER_LABEL_YOUR_ORGANIZATION_IS') }}: </span>
    </div>
    <div class="w-full">
      <span> {{ $t('REGISTER_LABEL_MATCH_YOUR_USAGE_NEEDS') }} </span>
    </div>
    <!--   category        -->
    <div class="flex flex-col gap-1">
      <div class="flex items-center text-sm w-44 text-gray-600">
        <span>
          {{ $t('COMMON_LABEL_TYPE_OF_ORGANIZATION') }}
          *
        </span>
      </div>
      <div
        class="flex divide-x border p-2 rounded"
        :style="{
          maxHeight: isProcessD ? '16rem' : '20rem',
        }"
      >
        <div
          class="
            overflow-auto
            small-scrollbar
            flex flex-col
            w-1/2
            gap-1
            max-h-full
          "
        >
          <span
            v-for="category in organizationCategories"
            :key="category?.id"
            class="px-2 py-1 hover:bg-gray-100 cursor-pointer"
            :class="[category.id == currentCategory?.id ? 'bg-current-50' : '']"
            @click="onChooseCategory(category)"
          >
            {{ category?.name }}
          </span>
        </div>
        <div
          class="
            overflow-auto
            small-scrollbar
            flex flex-col
            w-1/2
            pl-2
            gap-1
            max-h-full
          "
        >
          <div v-if="currentCategory?.code == 'OTHERS'" class="p-2">
            <SynInput
              :model-value="subCategory"
              class="w-96"
              :max-length="100"
              :placeholder="$t('COMMON_LABEL_TYPE_OF_ORGANIZATION')"
              autofocus
              @update:model-value="
                (value) => $emit('update:subCategory', value)
              "
            />
          </div>
          <template
            v-else-if="
              currentCategory &&
              currentCategory?.children &&
              currentCategory?.children?.length > 0
            "
          >
            <span
              v-for="subCate in currentCategory?.children"
              :key="subCate?.id"
              class="px-2 py-1 hover:bg-gray-100 cursor-pointer"
              :class="[
                subCate.id == currentSubCategory?.id ? 'bg-current-50' : '',
              ]"
              @click="onChooseSubCategory(subCate)"
            >
              {{ subCate?.name }}
            </span>
          </template>
          <div v-else class="text-center py-2 text-sm italic text-gray-600">
            {{
              $t('REGISTER_PROCESS_C_PLEASE_SELECT_YOUR_TYPE_OF_ORGANIZATION')
            }}
          </div>
        </div>
      </div>
    </div>

    <!--  SIZE        -->
    <div class="my-2 flex flex-col space-y-1">
      <div class="flex items-center text-sm w-44 text-gray-600">
        <span>
          {{
            $t(
              organizationModel == 'TEAM'
                ? 'ORGANIZATION_LABEL_TEAM_SIZE'
                : 'ORGANIZATION_LABEL_ORGANIZATION_SIZE'
            )
          }}
          *
        </span>
      </div>

      <SynFormInput>
        <div class="flex items-center flex-wrap gap-1 w-full">
          <div
            class="
              w-max
              flex-center
              rounded-full
              px-3
              py-1.5
              cursor-pointer
              text-xs
              mb-2
            "
            :class="
              size === '1'
                ? 'text-white bg-current-500  '
                : 'border border-gray-300'
            "
            style="min-width: 4rem"
            @click="$emit('update:size', '1')"
          >
            1
          </div>
          <template v-for="range in memberSizeRangeByMode" :key="range.key">
            <div
              class="
                w-max
                flex-center
                rounded-full
                px-3
                py-1.5
                cursor-pointer
                text-xs
                mb-2
              "
              :class="
                size === range.value
                  ? 'text-white bg-current-500  '
                  : 'border border-gray-300'
              "
              style="min-width: 4rem"
              @click="$emit('update:size', range.value)"
            >
              {{ range.key }}
            </div>
          </template>
        </div>
      </SynFormInput>
    </div>
  </div>
</template>
