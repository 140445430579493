// export interface IReportCard {
//     id: string;
//     title: string;
//     type: EReportCardType;
//     index: number;
//     width: string;
//     height: string;
//     filters: any; // need define more
//     metadata: any;
// }

import {
    EReportCardType,
    IReportCard,
} from '@/application/types/report/report.types';

// export enum EReportCardType {
//     COLUMN = 'COLUMN',
//     LINE = 'LINE',
//     DONUT = 'DONUT',
//     NUMBER = 'NUMBER',
//     LOLLIPOP = 'LOLLIPOP',
//     TABLE = 'TABLE',
// }

export const REPORT_CARD_LIST: IReportCard[] = [
    {
        id: 'column_1',
        title: 'Total Tasks',
        titleCode: 'REPORT_LABEL_TOTAL_TASKS',
        type: EReportCardType.TOTAL_TASKS,
        index: 0,
        width: '25%',
        height: '12rem',
        filters: null, // need define more
        metadata: null,
        configs: {
            modal: {
                bodyStyle: 'width: 550px; height: 350px; ',
            },
        },
    },
    {
        id: 'column_2',
        title: 'Completed Tasks',
        titleCode: 'REPORT_LABEL_COMPLETED_TASKS',
        type: EReportCardType.COMPLETED_TASKS,
        index: 1,
        width: '25%',
        height: '12rem',
        filters: null, // need define more
        metadata: null,
        configs: {
            modal: {
                bodyStyle: 'width: 550px; height: 350px; ',
            },
        },
    },
    {
        id: 'column_3',
        title: 'Incomplete Tasks',
        titleCode: 'REPORT_LABEL_INCOMPLETE_TASKS',
        type: EReportCardType.INCOMPLETE_TASKS,
        index: 2,
        width: '25%',
        height: '12rem',
        filters: null, // need define more
        metadata: null,
        configs: {
            modal: {
                bodyStyle: 'width: 550px; height: 350px; ',
            },
        },
    },
    {
        id: 'column_4',
        title: 'Overdue Tasks',
        titleCode: 'REPORT_LABEL_OVERDUE_TASKS',
        type: EReportCardType.OVERDUE_TASKS,
        index: 3,
        width: '25%',
        height: '12rem',
        filters: null, // need define more
        metadata: null,
        configs: {
            modal: {
                bodyStyle: 'width: 550px; height: 350px; ',
            },
        },
    },

    {
        id: 'column_5',
        title: 'Task status',
        titleCode: 'COMMON_LABEL_TASK_BY_STATUS',
        type: EReportCardType.TASK_STATUS,
        index: 4,
        width: '50%',
        height: '28rem',
        filters: null, // need define more
        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_6',
        title: 'Performance',
        titleCode: 'COMMON_LABEL_PERFORMANCE_OVER_TIME',
        type: EReportCardType.TASK_PERFORMANCE,
        index: 5,
        width: '50%',
        height: '28rem',
        filters: null, // need define more
        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_7',
        title: 'Domain tasks',
        titleCode: 'REPORT_LABEL_DOMAIN_TASKS',
        type: EReportCardType.DOMAIN_TASKS,
        index: 6,
        width: '50%',
        height: '28rem',
        filters: {
            size: {
                default: 3,
                title: 'COMMON_LABEL_SELECT_TOP_SIZE',
                options: [3, 5, 10],
                objectKey: 'DOMAIN_LABEL_TITLE',
                subObjectKey: 'DOMAIN_PROJECT_TITLE',
            },
        },
        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_8',
        title: 'Domain overdue tasks',
        titleCode: 'REPORT_LABEL_DOMAIN_OVERDUE_TASKS',
        type: EReportCardType.DOMAIN_OVERDUE_TASKS,
        index: 6,
        width: '50%',
        height: '28rem',
        filters: {
            size: {
                default: 3,
                title: 'COMMON_LABEL_SELECT_TOP_SIZE',
                options: [3, 5, 10],
                objectKey: 'DOMAIN_LABEL_TITLE',
                subObjectKey: 'DOMAIN_PROJECT_TITLE',
            },
        },
        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_9',
        title: 'Excellence Users',
        titleCode: 'REPORT_LABEL_EXCELLENCE_USERS',
        type: EReportCardType.EXCELLENCE_USERS,
        index: 6,
        width: '50%',
        height: '28rem',
        metadata: null,
        filters: {
            size: {
                title: 'REPORT_LABEL_EXCELLENCE_USERS',
                default: 5,
                options: [5, 10, 20],
                objectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
                subObjectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
            },
        },
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_10',
        title: 'Active users',
        titleCode: 'REPORT_LABEL_ACTIVE_USERS',
        type: EReportCardType.ACTIVE_USERS,
        index: 6,
        width: '50%',
        height: '28rem',
        filters: {
            size: {
                title: 'REPORT_LABEL_ACTIVE_USERS',
                default: 10,
                options: [5, 10, 20],
                objectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
                subObjectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
            },
        },
        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_13',
        title: 'Creating most',
        titleCode: 'REPORT_LABEL_CREATING_MOST',
        type: EReportCardType.CREATING_MOST,
        index: 6,
        width: '50%',
        height: '28rem',
        filters: {
            size: {
                title: 'REPORT_LABEL_CREATING_MOST',
                default: 10,
                options: [5, 10, 20],
                objectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
                subObjectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
            },
        },
        orders: [
            {
                key: 1,
                titleCode: 'REPORT_LABEL_ORDER_BY_CREATING_MOST',
            },
            {
                key: 2,
                titleCode: 'REPORT_LABEL_ORDER_BY_MOST_RECENTLY_CREATED',
            },
        ],
        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_14',
        title: 'Assigned most',
        titleCode: 'REPORT_LABEL_ASSIGNED_MOST',
        type: EReportCardType.ASSIGNED_MOST,
        index: 6,
        width: '50%',
        height: '28rem',
        filters: {
            size: {
                title: 'REPORT_LABEL_ASSIGNED_MOST',
                default: 10,
                options: [5, 10, 20],
                objectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
                subObjectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
            },
        },
        orders: [
            {
                key: 1,
                titleCode: 'REPORT_LABEL_ORDER_BY_MOST_ASSIGNED',
            },
            {
                key: 2,
                titleCode: 'REPORT_LABEL_ORDER_BY_MOST_RECENTLY_ASSIGNED',
            },
        ],
        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_11',
        title: 'Most overdue',
        titleCode: 'REPORT_LABEL_MOST_OVERDUE',
        type: EReportCardType.MOST_OVERDUE,
        index: 6,
        width: '50%',
        height: '28rem',
        filters: {
            size: {
                title: 'REPORT_LABEL_MOST_OVERDUE',
                default: 5,
                options: [5, 10, 20],
                objectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
                subObjectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
            },
        },
        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
    {
        id: 'column_12',
        title: 'Most active tasks',
        titleCode: 'REPORT_LABEL_MOST_ACTIVE_TASKS',
        type: EReportCardType.MOST_TASKS,
        index: 6,
        width: '50%',
        height: '28rem',
        filters: {
            size: {
                title: 'REPORT_LABEL_MOST_ACTIVE_TASKS',
                default: 10,
                options: [5, 10, 20],
                objectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
                subObjectKey: 'USERMANAGEMENT_FORM_TYPE_USER',
            },
        },

        metadata: null,
        configs: {
            modal: {
                bodyStyle:
                    'width: 50%; min-width: 720px; max-width: 1200px; min-height: 720px; ',
            },
        },
    },
];

export const DATASETS_BY_REPORT_TYPE = {
    [EReportCardType.DOMAIN_TASKS]: [
        {
            key: 'totalTasks',
            label: 'TASK_LABEL_FILTER_ALL',
            data: [],
            backgroundColor: '#60a5fa',
            borderColor: '#60a5fa',
            hoverBackgroundColor: '#3b82f6',
            minBarLength: 2,
        },
        {
            key: 'completedTasks',
            label: 'COMMON_LABEL_STATUS_FINISHED',
            data: [],
            backgroundColor: '#4bb0b2',
            borderColor: '#4bb0b2',
            hoverBackgroundColor: '#008e8e',
            minBarLength: 2,
        },
    ],
};

export const COLOR_LIST = [
    '#4bb0b2',
    '#60a5fa',
    '#FDC89D',
    '#fca5a5',
    '#9ca3af',
    '#fcd34d',
    '#94a3b8',
    '#f87171',
    '#22d3ee',
    '#0e7490',
    '#c084fc',
    '#fb7185',
    '#6ee7b7',
    '#38bdf8',
    '#6F9B91',
    '#475569',
    '#B9ACFA',
    '#F2B705',
    '#0d9488',
    '#56BEE3',
];
