<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import overviewDomainStore from '@/store/dashboard/overview-domains-store';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { ILatestTaskActivity } from '@/ui/plugins/firebases/realtime-database/types/organization-dynamic-model.types';
import DomainTree from '@/ui/modules/domains/component/DomainTree.vue';
import { ECustomFilterSourceType } from '@/application/types/task/task.types';
import DomainTasks from '@/ui/pages/tasks/DomainTasks.vue';
import taskListRealtime from '@/ui/modules/task/composables/task-list-realtime';
import DomainCreateModal from '@/ui/modules/domains/domain/DomainCreateModal.vue';
import localConfigsStore from '@/store/local-configs';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
} from '@/ui/modules/task/task-global-state';
// import taskListStore from '@/store/task/task-list-store';
import myProfileStore from '@/store/auth/my-profile';
import OverviewDomainTabs from '@/ui/modules/overview/domain/OverviewDomainTabs.vue';
import { DomainTab } from '@/domain/enums/domain-enums';
import GedPage from '@/ui/modules/ged/ged-page/GedPage.vue';

defineProps<{
  isHiddenSetDefault?: boolean;
}>();

defineEmits([
  'openTaskDetail',
  'openCreateTaskForUser',
  'onAdd',
  'onCreateTaskForGroup',
]);

const _overviewDomainStore = overviewDomainStore();
const _localConfigsStore = localConfigsStore();

const activeTab = ref<any>({ id: DomainTab.Tasks });

const isOwner = computed<any>(() => {
  return myProfileStore().isOwner;
});
const myDomains = computed(() => {
  if (isOwner.value) return _overviewDomainStore.overviewDomainFullDetail;
  return myProfileStore().myDomains?.map((o) => {
    return {
      ...o,
      projects: o?.projectScopeList?.map((p) => p?.projectId),
    };
  });
});

const isCreateDomain = computed(() => _overviewDomainStore.isCreateDomain);
const searchText = computed(() => _overviewDomainStore.keySearchDomain);
const filteredDomains = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  const overviewDomainInfos: any[] = myDomains.value || [];
  console.log('🚀 Tictop ~ overviewDomainInfos:', overviewDomainInfos);

  if (!searchTxt || overviewDomainInfos?.length < 1) return overviewDomainInfos;

  return overviewDomainInfos.reduce((arr, domain) => {
    const isMatchName = !!(
      domain.name && ignoreUnicode(domain.name).includes(searchTxt)
    );
    const isMatchUser = (domain.members || []).filter(
      (domainName) =>
        domainName.fullName &&
        ignoreUnicode(domainName.fullName).startsWith(searchTxt)
    );
    const isMatchGroup = (domain.groups || []).filter(
      (group) => group?.name && ignoreUnicode(group?.name).startsWith(searchTxt)
    );

    if (isMatchName || isMatchGroup?.length > 0 || isMatchUser?.length > 0) {
      domain.isMatchName = isMatchName;
      domain.textMatchName = isMatchName ? searchTxt : '';
      domain.arrayMatchUser = isMatchUser?.length > 0 ? isMatchUser : [];
      domain.arrayMatchGroup = isMatchGroup?.length > 0 ? isMatchGroup : [];
      arr.push(domain);
    }
    return arr;
  }, []);
});

const resourceFixedMember = computed(() => ({
  id: currentIds.value?.domainId,
  projectId: currentIds.value?.projectId,
  subProjectId: currentIds.value?.subprojectId,
  isDomain: true,
}));

const domainListRef = ref(null as any);

let { unsubscribeCallbacks } = listenFirebaseRealtime();
onMounted(() => {
  initData();

  const LatestTaskActivityFirebaseModel = new OrganizationDynamicModel(
    `latestTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    LatestTaskActivityFirebaseModel.onChange((data: ILatestTaskActivity) => {
      if (Date.now() - data?.actionTime > 5 * 1000) return;

      _overviewDomainStore.updateDomainTasksRealtime(
        data?.action,
        data.taskDetail
      );
    })
  );
});

const initData = async () => {
  if (
    _localConfigsStore.localConfigs?.domain?.isIncludeSubDomain == undefined
  ) {
    onFilterSubDomainTasks(true);
  } else {
    onFilterSubDomainTasks(
      !!_localConfigsStore.localConfigs?.domain?.isIncludeSubDomain
    );
  }
};

const onOpenCreateModal = () => {
  _overviewDomainStore.setValueCreateDomain(true);
};

const itemActiveKey = ref('');

const currentDomain = ref<any>({});

const { onListenFirebase, onListenMultiTask } = taskListRealtime();

const currentIds = ref<{
  domainId: number;
  projectId: number;
  subprojectId: number;
}>({
  domainId: 0,
  projectId: 0,
  subprojectId: 0,
});

const customFilterSourceType = ref<ECustomFilterSourceType>(
  ECustomFilterSourceType.OVERVIEW_DOMAIN
);
const changeViewMode = async (key, id, parentId, domain) => {
  itemActiveKey.value = `${key}_${id}`;
  currentIds.value = {
    domainId: 0,
    projectId: 0,
    subprojectId: 0,
  };
  currentIds.value.domainId = domain?.id;
  if (currentDomain.value?.id !== domain?.id) {
    currentDomain.value = domain;

    onListenFirebase('domain', id);
    onListenMultiTask('domain', id);
  }

  if (key == 'domain') {
    customFilterSourceType.value = ECustomFilterSourceType.OVERVIEW_DOMAIN;
  } else if (key == 'project') {
    customFilterSourceType.value = ECustomFilterSourceType.OVERVIEW_PROJECT;
    currentIds.value.projectId = id;
  } else {
    customFilterSourceType.value = ECustomFilterSourceType.OVERVIEW_SUB_PROJECT;
    currentIds.value.projectId = parentId;
    currentIds.value.subprojectId = id;
  }
};

const onCloseCreateDomain = () => {
  _overviewDomainStore.setValueCreateDomain(false);
};

const onCreateSuccessDomain = async () => {
  onCloseCreateDomain();
};

const isViewDefault = computed<boolean>(() => {
  return (
    _localConfigsStore.localConfigs?.domain?.overviewDomainViewDefault == 'LIST'
  );
});

const setDefault = () => {
  _localConfigsStore.setLocalConfigs('domain', {
    overviewDomainViewDefault: 'LIST',
  });
};

const addNewTask = () => {
  console.log('🚀 Tictop ~ currentIds.value:', currentIds.value);
  newTaskDefaultPayload.value = {
    domainId: currentIds.value?.domainId || 0,
    projectId: currentIds.value?.projectId,
    subprojectId: currentIds.value?.subprojectId,
  };
  isOpenCreateTaskDrawer.value = true;
};

const isIncludeSubDomain = ref<boolean>(true);
const onFilterSubDomainTasks = (value) => {
  isIncludeSubDomain.value = value;
  _localConfigsStore.setLocalConfigs('domain', {
    isIncludeSubDomain: value,
  });
  _overviewDomainStore.getCountTasksByDomain(true, value);
  // _taskListStore.setFilterDataByKey('subDomain', value);
  // _taskListStore.onFilterTaskOnColumn();
};
</script>

<template>
  <section class="w-full h-full relative flex flex-col">
    <div class="relative flex-1 min-h-0">
      <span class="absolute top-2 right-4 z-10">
        <AtomButton
          v-if="!isHiddenSetDefault && !isViewDefault && !currentDomain?.id"
          size="sm"
          type="TEXT"
          :label="$t('COMMON_LABEL_SET_DEFAULT')"
          @click="setDefault"
        />
      </span>
      <template v-if="filteredDomains?.length <= 0 && searchText">
        <div class="py-16 h-full w-full text-center">
          <syn-animation
            name="searchIcon"
            :loop="false"
            stype="width: 200px; height: 200px;"
          />
          <span class="font-medium text-lg text-gray-600">{{
            $t('COMMON_LABEL_ALERT_NO_SEARCH')
          }}</span>
        </div>
      </template>
      <template v-else>
        <div ref="domainListRef" class="w-full h-full flex">
          <template v-if="filteredDomains.length < 1">
            <div
              class="w-full h-full px-8 pb-16 relative hover-to-show__parent"
              style="height: 24rem"
            >
              <div
                class="
                  w-full
                  h-full
                  p-3
                  rounded-3xl
                  bg-white
                  flex-center flex-col
                  cursor-pointer
                  hover:border hover:border-current
                "
                @click="onOpenCreateModal"
              >
                <SynAnimation name="setupTeam" stype="width: 120px;" />
                <span class="font-medium text-gray-700 pt-4">{{
                  $t('OVERVIEW_DOMAIN_ADD_NEW_PROJECT_TITLE')
                }}</span>
                <span
                  class="flex-center text-center text-sm text-gray-400 pt-2"
                  >{{ $t('OVERVIEW_DOMAIN_ADD_NEW_PROJECT_SUB_TITLE') }}</span
                >
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="
                bg-white
                w-96
                h-full
                overflow-auto
                small-scrollbar
                flex flex-col
                gap-0.5
                py-4
                pl-4
                pt-4
                border-r-4 border-gray-100
                shadow-md
              "
            >
              <div class="px-1 flex pb-3">
                <AtomSwitch
                  :model-value="isIncludeSubDomain"
                  :label="
                    $t('OVERVIEW_DOMAIN_LABEL_INCLUDES_TASKS_IN_THE_SUB_DOMAIN')
                  "
                  size="small"
                  @update:model-value="onFilterSubDomainTasks"
                />
              </div>
              <DomainTree
                v-for="domain in filteredDomains"
                :key="domain.id"
                :domain-detail="domain"
                :item-active-key="itemActiveKey"
                is-readonly
                :is-show-children-default="false"
                :is-show-all="isOwner"
                @change-item-active="
                  (key, id, parentId) =>
                    changeViewMode(key, id, parentId, domain)
                "
              />
            </div>
            <div class="bg-white flex-1 h-full overflow-auto small-scrollbar">
              <template v-if="!currentDomain?.id">
                <div class="flex flex-col flex-center swiper-slide">
                  <VigImage
                    :path="'https://d1tvqetrcurhzb.cloudfront.net/common/images/messager-intro-page1.png'"
                    custom-class="w-max"
                    style="height: 28rem"
                  />
                  <div class="flex flex-col flex-center">
                    <span class="text-current-700" style="font-size: 1.2rem">
                      {{ $t('OVERVIEW_DOMAIN_HAVE_NOT_SELECTED_DOMAIN') }}
                    </span>
                    <span class="text-gray-500 text-sm py-2">
                      {{
                        $t(
                          'OVERVIEW_DOMAIN_HAVE_NOT_SELECTED_DOMAIN_DESCRIPTION'
                        )
                      }}
                    </span>
                  </div>
                </div>
              </template>
              <div v-else class="h-full flex flex-col">
                <OverviewDomainTabs v-model:active-tab="activeTab" />
                <div class="flex-1 overflow-hidden">
                  <DomainTasks
                    v-if="activeTab?.id === DomainTab.Tasks"
                    :domain-id="currentIds?.domainId"
                    :project-id="currentIds?.projectId"
                    :sub-project-id="currentIds?.subprojectId"
                    :create-task-domain-id="currentDomain?.id"
                    is-show-tasks-only
                    :is-include-sub="isIncludeSubDomain"
                    is-hidden-filter-sub
                    :custom-filter-source-type="customFilterSourceType"
                    @add-new-task="addNewTask"
                  />
                  <GedPage
                    v-if="activeTab?.id === DomainTab.Resources"
                    :fixed-member="resourceFixedMember"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </section>

  <DomainCreateModal
    v-if="isCreateDomain"
    @on-close="onCloseCreateDomain"
    @on-create-success="onCreateSuccessDomain"
  />
</template>
