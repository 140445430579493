import { computed, ref, watch } from 'vue';
import notificationStore from '@/store/notification/notification.store';
import commonStore from '@/store/common/commonStore';
import { NotificationKey } from '@/ui/common/constants/notifications';
import { CurrentUserConfig } from '@/ui/common/constants/constant';
import { INotification } from '@/application/types/notification.types';

export default function bindingNotificationComposables() {
    const _notificationStore = notificationStore();
    const _commonStore = commonStore();

    const isLoading = ref(true);
    const skipCount = ref(0);
    const defaultMaxResultCount = ref(13);
    const isLoadingMore = ref(false);
    const rawNotifications = computed<any[]>(
        () => _notificationStore.rawNotifications
    );
    const allNotifications = computed<INotification[]>(
        () => _notificationStore.allNotifications
    );

    const latestNotification = computed<any>(() => {
        return _notificationStore.latestNotification;
    });
    const notificationTotalCount = computed(
        () => _notificationStore.totalNotification
    );
    const category = computed(() => _notificationStore.category);
    const userConfigByKeys = computed(() => _commonStore.userConfigByKeys);
    const notificationType = computed(
        () => _notificationStore.notificationType
    ) as any;
    const totalNotification = computed(
        () => _notificationStore.totalNotification
    );
    const notificationCount = computed(
        () => _notificationStore.getNumberTotalNotificationUnread
    );
    const notificationCountMyTask = computed(
        () => _notificationStore.getNumberTotalNotificationMyTask
    );
    const isConditionReadAll = computed(
        () =>
            (notificationType.value[category.value].isOnlyUnread &&
                allNotifications.value.length == 0) ||
            notificationType.value[category.value].totalUnread === 0
    );

    watch(
        () => userConfigByKeys?.value,
        () => {
            Object.entries(userConfigByKeys.value).forEach(([key, value]) => {
                switch (key) {
                    case CurrentUserConfig.OnlyUnreadAllNotification:
                        _notificationStore.setValueOnlyUnread(
                            NotificationKey.All,
                            Boolean(value)
                        );
                        break;
                    case CurrentUserConfig.OnlyUnreadFinishedNotification:
                        _notificationStore.setValueOnlyUnread(
                            NotificationKey.Finished,
                            Boolean(value)
                        );
                        break;
                    case CurrentUserConfig.OnlyUnreadNewNotification:
                        _notificationStore.setValueOnlyUnread(
                            NotificationKey.New,
                            Boolean(value)
                        );
                        break;
                    case CurrentUserConfig.OnlyUnreadUpdatedNotification:
                        _notificationStore.setValueOnlyUnread(
                            NotificationKey.Updated,
                            Boolean(value)
                        );
                        break;
                    case CurrentUserConfig.OnlyUnreadOtherNotification:
                        _notificationStore.setValueOnlyUnread(
                            NotificationKey.Other,
                            Boolean(value)
                        );
                        break;
                    default:
                        break;
                }
            });
        }
    );

    const initData = async () => {
        isLoading.value = true;
        defaultMaxResultCount.value = 13;
        skipCount.value = 0;
        // console.log(category.value, '-----------------');
        await _commonStore.getCurrentUserSetting();
        await _notificationStore.getNotificationUnread();

        await getNotificationsData();
        isLoading.value = false;
    };

    const getNotificationsData = async () => {
        try {
            const result = await _notificationStore.getNotifications(
                defaultMaxResultCount.value,
                skipCount.value,
                category.value
            );

            if (
                result?.length > 0 &&
                _notificationStore.allNotifications?.length < 10
            )
                loadMoreNotification();
        } catch (e) {
            console.log(e);
        }
    };

    const onChangeCategory = async (item) => {
        _notificationStore.setCategory(item.key);

        isLoading.value = true;
        defaultMaxResultCount.value = 13;
        skipCount.value = 0;
        await getNotificationsData();
        isLoading.value = false;
    };

    const removeFilter = async () => {
        await _notificationStore.removeAllValueFilter(category.value);
    };

    const onFilterUserIdNotification = async (userId) => {
        await _notificationStore.setValueFilterActionBy(category.value, userId);
        defaultMaxResultCount.value = 13;
        skipCount.value = 0;
        isLoading.value = true;
        await getNotificationsData();
        isLoading.value = false;
    };

    const onFilterGroupIdNotification = async (groupId) => {
        await _notificationStore.setValueFilterGroup(category.value, groupId);
        defaultMaxResultCount.value = 13;
        skipCount.value = 0;
        isLoading.value = true;
        await getNotificationsData();
        isLoading.value = false;
    };

    const onFilterDomainIdNotification = async (domainId) => {
        await _notificationStore.setValueFilterDomain(category.value, domainId);
        defaultMaxResultCount.value = 13;
        skipCount.value = 0;
        isLoading.value = true;
        await getNotificationsData();
        isLoading.value = false;
    };

    const onFilterTypesNotification = async (types) => {
        await _notificationStore.setValueFilterTypes(category.value, types);
        defaultMaxResultCount.value = 13;
        skipCount.value = 0;
        isLoading.value = true;
        await getNotificationsData();
        isLoading.value = false;
    };

    const loadMoreNotification = async () => {
        if (notificationTotalCount.value == 0) return;
        isLoadingMore.value = true;
        skipCount.value = skipCount.value + 13;
        await _notificationStore.getNotifications(
            defaultMaxResultCount.value,
            skipCount.value,
            category.value
        );

        isLoadingMore.value = false;
    };

    const onShowOnlyUnread = async (value) => {
        let configKeyUpdate;

        switch (category.value) {
            case NotificationKey.All:
                configKeyUpdate = {
                    key: CurrentUserConfig.OnlyUnreadAllNotification,
                    value,
                };
                break;
            case NotificationKey.Updated:
                configKeyUpdate = {
                    key: CurrentUserConfig.OnlyUnreadUpdatedNotification,
                    value,
                };
                break;
            case NotificationKey.New:
                configKeyUpdate = {
                    key: CurrentUserConfig.OnlyUnreadNewNotification,
                    value,
                };
                break;
            case NotificationKey.Finished:
                configKeyUpdate = {
                    key: CurrentUserConfig.OnlyUnreadFinishedNotification,
                    value,
                };
                break;
            case NotificationKey.Other:
                configKeyUpdate = {
                    key: CurrentUserConfig.OnlyUnreadOtherNotification,
                    value,
                };
                break;
            default:
                break;
        }

        if (!configKeyUpdate) return;

        defaultMaxResultCount.value = 13;
        skipCount.value = 0;
        // await _commonStore.setCurrentUserSetting(configKeyUpdate);
        await _commonStore.updateCurrentUserSetting(configKeyUpdate);
        await getNotificationsData();
    };

    const handleChangePagination = async () => {
        if (totalNotification.value === 0) return;
        isLoadingMore.value = true;
        skipCount.value = skipCount.value + 13;
        await _notificationStore.getNotifications(
            defaultMaxResultCount.value,
            skipCount.value,
            category.value
        );
        isLoadingMore.value = false;
    };

    return {
        rawNotifications,
        allNotifications,
        totalNotification,
        isConditionReadAll,
        isLoading,
        skipCount,
        defaultMaxResultCount,
        isLoadingMore,
        category,
        notificationType,
        notificationCount,
        notificationCountMyTask,
        latestNotification,
        notificationTotalCount,
        initData,
        getNotificationsData,
        onFilterGroupIdNotification,
        onFilterDomainIdNotification,
        onChangeCategory,
        onFilterUserIdNotification,
        onFilterTypesNotification,
        loadMoreNotification,
        onShowOnlyUnread,
        handleChangePagination,
        removeFilter,
    };
}
