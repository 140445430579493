<script setup lang="ts">
import { computed } from 'vue';
import $constants from '@/ui/plugins/constants';
import { canDownload } from '@/ui/helpers/file-utils';
import FilesSendToTask from '@/ui/modules/ged/file-actions/files-send-to-task/FilesSendToTask.vue';
import FilesSendToChat from '@/ui/modules/ged/file-actions/files-send-to-chat/FilesSendToChat.vue';
import FilesDownload from '@/ui/modules/ged/file-actions/files-download/FilesDownload.vue';

const props = defineProps<{
  file: any;
}>();

defineEmits([
  'onViewInfoClick',
  'onRenameClick',
  'onMoveToClick',
  'onDownloadClick',
  'onCopyLinkClick',
  'onNotificationSettingClick',
  'onMoveToTrashClick',
  'onDeletePermanentlyClick',
  'onRestoreClick',
  'onSaveToResource',
]);

const canModify = computed(
  () =>
    !props.file?.deleted &&
    props.file?.sourceFrom === $constants.FILE.SOURCE_FROM.STORAGE &&
    props.file?.systemType !== $constants.FILE.SYSTEM_TYPE.SHARED_FOLDER &&
    props.file?.systemType !== $constants.FILE.SYSTEM_TYPE.GOOGLE_DRIVE_FOLDER
);

const canDownloadFile = computed(() => canDownload(props.file?.contentType));

const canSaveToResource = computed(
  () =>
    !props.file?.deleted &&
    props.file?.contentType !== $constants.FILE.TYPE.FOLDER &&
    props.file?.category !== $constants.FILE.CATEGORY.STORAGE
);
</script>

<template>
  <vig-dropdown :append-to-body="false" :space="0">
    <template #dropdown-toggle>
      <div class="p-1">
        <vig-button
          ghost
          color="gray"
          padding="p-0"
          rounded="rounded-full"
          class="bg-white hover:border-gray-200 w-8 h-8"
        >
          <SynIcon name="DotsVertical" />
        </vig-button>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="w-max text-sm">
        <vig-button
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onViewInfoClick')"
        >
          <SynIcon name="Info" />
          <span class="font-normal">{{ $t('GED_FILE_VIEW_INFO') }}</span>
        </vig-button>

        <vig-button
          v-if="canModify"
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onRenameClick')"
        >
          <SynIcon name="Edit" />
          <span class="font-normal">{{ $t('GED_FILE_RENAME') }}</span>
        </vig-button>

        <vig-button
          v-if="canModify"
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onMoveToClick')"
        >
          <SynIcon name="Back" class="transform rotate-180" />
          <span class="font-normal">{{ $t('GED_FILE_MOVE_TO_FOLDER') }}</span>
        </vig-button>

        <FilesDownload v-if="!file?.deleted && canDownloadFile" :files="[file]">
          <vig-button
            ghost
            color="gray"
            class="w-full space-x-3 justify-start dropdown-item"
          >
            <SynIcon name="Download" />
            <span class="font-normal">{{ $t('COMMON_LABEL_DOWNLOAD') }}</span>
          </vig-button>
        </FilesDownload>

        <vig-button
          v-if="!file?.deleted"
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onCopyLinkClick')"
        >
          <SynIcon name="Link" />
          <span class="font-normal">{{ $t('GED_FILE_COPY_EXT_LINK') }}</span>
        </vig-button>

        <vig-button
          v-if="
            !file?.deleted && file?.contentType === $constants.FILE.TYPE.FOLDER
          "
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onNotificationSettingClick')"
        >
          <SynIcon :name="!file?.turnOnNotification ? 'Bell' : 'BellMute'" />
          <span v-if="!file?.turnOnNotification" class="font-normal">
            {{
              $t('COMMON_LABEL_TURN_ON_NOTIFICATION') || 'Turn On Notification'
            }}
          </span>
          <span v-else class="font-normal">
            {{
              $t('NOTIFICATION_GED_BUTTON_TURN_OFF') || 'Turn Off Notification'
            }}
          </span>
        </vig-button>

        <vig-button
          v-if="!file?.deleted && canSaveToResource"
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onSaveToResource')"
        >
          <SynIcon name="Folder" />
          <span class="font-normal">{{
            $t('COMMON_LABEL_SAVE_TO_RESOURCE')
          }}</span>
        </vig-button>

        <FilesSendToChat v-if="!file?.deleted" :files="[file]">
          <vig-button
            ghost
            color="gray"
            class="w-full space-x-3 justify-start dropdown-item"
          >
            <SynIcon name="Chat" />
            <span class="font-normal">{{
              $t('COMMON_LABEL_SEND_TO_CHAT')
            }}</span>
          </vig-button>
        </FilesSendToChat>

        <FilesSendToTask
          v-if="!file?.deleted"
          trigger="mouseenter focus"
          placement="right-start"
          :arrow="0"
          :space="0"
          :append-to-body="false"
          :files="[file]"
        >
          <VigButton ghost color="gray" class="w-full space-x-3 justify-start">
            <SynIcon name="Note" />
            <span class="font-normal flex-1 text-left">{{
              $t('COMMON_LABEL_SEND_TO_TASK')
            }}</span>
            <SynIcon name="ChevronRight" custom-class="w-3 h-3" />
          </VigButton>
        </FilesSendToTask>

        <vig-button
          v-if="file?.deleted"
          ghost
          color="gray"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onRestoreClick')"
        >
          <SynIcon name="Reload2" />
          <span class="font-normal">{{ $t('GED_FILE_RESTORE') }}</span>
        </vig-button>

        <hr />

        <vig-button
          v-if="canModify"
          ghost
          color="red"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onMoveToTrashClick')"
        >
          <SynIcon name="Trash" />
          <span class="font-normal">{{ $t('GED_FILE_MOVE_TO_TRASH') }}</span>
        </vig-button>

        <vig-button
          v-if="file?.deleted"
          ghost
          color="red"
          class="w-full space-x-3 justify-start dropdown-item"
          @click.stop="$emit('onDeletePermanentlyClick')"
        >
          <SynIcon name="Trash" />
          <span class="font-normal">{{ $t('GED_FILE_DELETE_PERMANENT') }}</span>
        </vig-button>
      </div>
    </template>
  </vig-dropdown>
</template>
