<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="padding: 3px; enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m256 2.5c-95.117 0-172.5 77.383-172.5 172.5v312a22.5 22.5 0 0 0 45 0v-141h255v141a22.5 22.5 0 0 0 45 0v-312c0-95.117-77.383-172.5-172.5-172.5zm-127.5 298.5v-126a127.5 127.5 0 0 1 255 0v126z"
      ></path>
    </g>
  </svg>
</template>
