<template>
  <div class="w-full flex flex-col cursor-text">
    <input
      v-if="isInputting"
      v-cusFocus="true"
      class="focus:outline-none px-2 rounded"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="isInputting = false"
      @keyup.enter.prevent="isInputting = false"
    />
    <span v-else @click="isInputting = true">
      {{ modelValue }}
    </span>
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
  name: 'SynEditableLabel',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: 'Syn input',
    },
    modelValue: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup: () => {
    const isInputting = ref(false);
    return { isInputting };
  },
};
</script>
