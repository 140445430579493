<script setup lang="ts">
import UserById from '@/ui/components/user/UserById.vue';
import myProfileStore from '@/store/auth/my-profile';
import { computed } from 'vue';
defineProps<{
  messages: any[];
  createdDate: any;
}>();

defineEmits(['close']);
const myProfile = computed(() => myProfileStore().myProfile);
const isMyMessage = (userId) => {
  return userId == myProfile.value?.id;
};
</script>

<template>
  <syn-modal
    style-body="p-0"
    container-class="w-2/5"
    container-style="min-width: 40rem; max-width: 1200px"
    @cancel="$emit('close')"
  >
    <template #header>
      <div class="flex space-x-2">
        <span>
          {{ $t('CALL_CHAT_IN_CALL_TITLE') }}
        </span>
        <span>
          <SynLabelDateTime format="time" :datetime="createdDate" />
        </span>
      </div>
    </template>
    <template #body>
      <div class="flex flex-col p-4 space-y-0.5">
        <div
          v-for="(message, index) in messages"
          :key="index"
          class="flex items-end space-x-2"
          :class="
            isMyMessage(message?.userId) ? 'flex-row-reverse' : 'flex-row '
          "
        >
          <UserById
            v-if="!isMyMessage(message?.userId)"
            :user-id="message?.userId"
            is-hidden-name
          />
          <div
            class="rounded-2xl p-2 max-w-2xl"
            :class="{
              'bg-current text-white rounded-l-3xl': isMyMessage(
                message?.userId
              ),
              'bg-gray-200 rounded-r-3xl': !isMyMessage(message?.userId),
            }"
          >
            {{ message.data }}
          </div>
        </div>
      </div>
    </template>
  </syn-modal>
</template>
