import { ignoreUnicode } from '@/ui/plugins/utils';
import { chain, uniq } from 'lodash';
import { formatDate } from '@/ui/plugins/filters';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import remoteConfigStore from '@/store/remoteConfig';
import { RemoteConfigKey } from '@/ui/plugins/firebases/firebase-remote-config';
import $constants from '@/ui/plugins/constants';

export const genDirectConversationId = (userId, contactId) => {
    return [`${userId}`, `${contactId}`].sort().join('-');
};

export const genGroupConversationId = (groupId) => {
    return `G-${groupId}`;
};

export const prepareMessage = (msgId, message) => {
    const preparedMessage = {
        ...message,
        id: msgId,
        createdDate: _prepareMessageDate(message?.createdDate),
        files: _prepareMessageFilesUrl(message?.files),
        reactions: _prepareMessageReactions(message?.reactions),
    };

    if (preparedMessage.pinned) {
        preparedMessage.pinnedDate = _prepareMessageDate(message?.pinnedDate);
    }

    return preparedMessage;
};

export const processMessageFilesName = (
    converId,
    message,
    allGroups,
    allUsers
) => {
    if (!message?.files?.length) return;

    const pastedPhotoIdxs = message.files.reduce((arr, file, index) => {
        if (file?.isPasted && file?.name === 'image.jpeg') arr.push(index);
        return arr;
    }, []);

    const fileName = _genMessageFileName(converId, allGroups, allUsers);

    if (pastedPhotoIdxs.length === 1) {
        const photoIdx = pastedPhotoIdxs[0];
        const msgFile = message.files[photoIdx];
        message.files[photoIdx] = new File([msgFile], fileName, {
            type: msgFile?.type,
        });
    } else {
        pastedPhotoIdxs.forEach((photoIdx, index) => {
            const msgFile = message.files[photoIdx];
            const msgFileName = fileName.replace(
                '.jpeg',
                ` (${index + 1}).jpeg`
            );
            message.files[photoIdx] = new File([msgFile], msgFileName, {
                type: msgFile?.type,
            });
        });
    }
};

export const downloadMessageFiles = (files) => {
    if (!files?.length) return;

    if (files.length === 1) {
        return axios
            .get(files[0].pathUrl || files[0].url_full, {
                responseType: 'blob',
            })
            .then((res) => {
                saveAs(res.data, files[0].name);
            });
    } else {
        const zip = new JSZip();
        files.forEach((file) => {
            zip.file(
                file.name,
                axios(file.pathUrl || file.url_full, {
                    responseType: 'blob',
                }).then((res) => res?.data)
            );
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `files-${new Date().getTime()}.zip`);
        });
    }
};

const _genMessageFileName = (converId, allGroups, allUsers) => {
    let chatName;

    if (converId?.startsWith('G-')) {
        const groupName: any = chain(allGroups)
            .filter(
                (group: any) => genGroupConversationId(group?.id) === converId
            )
            .map('name')
            .first()
            .value();

        chatName = ignoreUnicode(groupName, false);
    } else {
        const memberIds = uniq(converId?.split('-'));

        const memberName: any = chain(allUsers)
            .filter((user: any) => memberIds.includes(`${user?.id}`))
            .uniqBy('id')
            .map((user: any) => user?.name?.split(' ').pop())
            .sortBy()
            .join('-')
            .value();

        chatName = ignoreUnicode(memberName, false);
    }

    const time = formatDate(new Date(), 'YYYY-MM-DD hh.mm A');

    return `Photo-Chat ${chatName} ${time}.jpeg`;
};

const _prepareMessageFilesUrl = (messageFiles) => {
    if (!messageFiles) return null;

    const _remoteConfigStore = remoteConfigStore();

    Object.entries(messageFiles).forEach(([fileId, file]: any) => {
        if (
            !file?.url ||
            ['http://', 'https://', 'blob:'].some((prefix) =>
                file.url.startsWith(prefix)
            ) ||
            file.url.includes(';base64,')
        ) {
            return (messageFiles[fileId].url_full = file?.url);
        }

        let pathPrefix = _remoteConfigStore.getRemoteConfig(
            RemoteConfigKey.CommonAwsMessagingPathFile
        );

        if (file.contentType?.startsWith($constants.FILE.TYPE.IMAGE)) {
            pathPrefix = _remoteConfigStore.getRemoteConfig(
                RemoteConfigKey.CommonAwsMessagingPathPhoto
            );
        } else if (
            file.contentType?.startsWith($constants.FILE.TYPE.AUDIO) ||
            file.contentType?.startsWith($constants.FILE.TYPE.VIDEO)
        ) {
            pathPrefix = _remoteConfigStore.getRemoteConfig(
                RemoteConfigKey.CommonAwsMessagingPathMedia
            );
        }

        return (messageFiles[fileId].url_full = `${pathPrefix.asString()}/${
            file?.url
        }`);
    });

    return messageFiles;
};

const _prepareMessageReactions = (reactionsObj) => {
    if (!reactionsObj) return null;

    return Object.entries(reactionsObj || {}).reduce(
        (obj, [reactionId, reaction]: [string, any]) => {
            obj[reactionId] = {
                ...reaction,
                id: reactionId,
                createdDate: _prepareMessageDate(reaction?.createdDate),
            };
            return obj;
        },
        {}
    );
};

const _prepareMessageDate = (date) => {
    return date?.toDate ? date?.toDate() : date || new Date();
};
