<script setup lang="ts">
// *** IMPORTS ***
import { ChatConversationModel } from '@/application/models/chat/ChatConversationModel';
import ModalConversationDeleteConfirm from '@/ui/modules/messaging/chat-action/conversation-delete/ModalConversationDeleteConfirm.vue';
import { ref } from 'vue';

// *** PROPS, EMITS ***
defineProps<{
  conversation: ChatConversationModel;
}>();

// *** PRIVATE VARIABLES ***

// *** COMPOSABLES ***

// *** REFS ***
const isOpenModalConfirm = ref<boolean>();

// *** COMPUTED ***

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <div
    v-if="!conversation?.deleted && conversation?.createdDate"
    role="button"
    @click="isOpenModalConfirm = true"
  >
    <slot />
  </div>

  <ModalConversationDeleteConfirm
    v-if="isOpenModalConfirm"
    :conversation="conversation"
    @on-close="isOpenModalConfirm = false"
  />
</template>

<style scoped></style>
