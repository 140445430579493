<script setup lang="ts">
import { ref } from 'vue';
// import { DomainStatus } from '@/domain/enums/domain-enums';
// import DomainManagementByDomains from '@/ui/modules/domains/managements/DomainManagementByDomains.vue';
import DomainManagementByUsers from '@/ui/modules/domains/managements/DomainManagementByUsers.vue';
import DomainManagementByGroups from '@/ui/modules/domains/managements/DomainManagementByGroups.vue';
import DomainListTable from '@/ui/modules/domains/domain/DomainListTable.vue';
import DomainCreateModal from '@/ui/modules/domains/domain/DomainCreateModal.vue';

const managementByData = {
  domain: {
    translateKey: '',
    name: 'View by Domain',
    nameCode: 'DOMAIN_LABEL_VIEW_BY_DOMAIN',
  },
  user: {
    translateKey: '',
    name: 'View by User',
    nameCode: 'DOMAIN_LABEL_VIEW_BY_USER',
  },
  group: {
    translateKey: '',
    name: 'View by Group',
    nameCode: 'DOMAIN_LABEL_VIEW_BY_GROUP',
  },
};
const managementBy = ref('domain');
const managementByTabs = ['domain', 'user', 'group'];

const changeTab = (key) => {
  managementBy.value = key;
};

const isOpenCreateDomainModal = ref(false);
const onCreate = () => {
  isOpenCreateDomainModal.value = true;
};
const onClose = () => {
  isOpenCreateDomainModal.value = false;
};
</script>
<template>
  <div class="h-full pt-2 flex flex-col">
    <ul class="w-full flex cursor-pointer text-sm space-x-0.5 relative">
      <li
        v-for="key in managementByTabs"
        :key="key"
        class="py-2 px-4 font-semibold rounded-t-md"
        :class="
          managementBy == key
            ? 'bg-white text-current-500 border-t border-x border-current-100 z-10'
            : 'text-gray-500 bg-gray-100 '
        "
        :style="
          managementBy == key ? 'margin-bottom: -1px' : 'margin-bottom: 0px'
        "
        @click="changeTab(key)"
      >
        <div class="flex-center space-x-2">
          <span>
            {{
              $t(managementByData[key]?.nameCode) || managementByData[key]?.name
            }}
          </span>
        </div>
      </li>
      <div class="absolute right-2 -top-1">
        <SynButton
          :label="$t('DOMAIN_LABEL_CREATE_NEW') || 'Create new domain/project'"
          name-icon="add"
          @click="onCreate"
        />
      </div>
    </ul>
    <div
      class="
        h-full
        flex-1
        min-h-0
        overflow-hidden
        p-2
        bg-white
        flex-center
        border-t border-current-100
      "
    >
      <template v-if="managementBy == 'domain'">
        <DomainListTable />
      </template>
      <template v-if="managementBy == 'user'">
        <DomainManagementByUsers />
      </template>
      <template v-if="managementBy == 'group'">
        <DomainManagementByGroups />
      </template>
    </div>
  </div>
  <DomainCreateModal
    v-if="isOpenCreateDomainModal"
    @on-close="onClose"
    @on-create-success="onClose"
  />
</template>
