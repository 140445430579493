import BaseRequestEmbed from '@/base/api/base-request-embed';

export default class EmbedAuthApi extends BaseRequestEmbed {
    private static instance: EmbedAuthApi;
    constructor() {
        super();
    }

    public static getInstance(): EmbedAuthApi {
        if (!EmbedAuthApi.instance) {
            // Get from local storage
            EmbedAuthApi.instance = new EmbedAuthApi();
        }

        return EmbedAuthApi.instance;
    }

    selectOrganization(data) {
        return this.request({
            url: `selectOrganization`,
            method: 'post',
            data,
        });
    }

    loginWithPassword(data) {
        return this.request({
            url: `authenticate`,
            method: 'post',
            data,
        });
    }

    getCurrentLoginInformations() {
        return this.request({
            url: `Session/GetCurrentLoginInformations`,
            method: 'get',
        });
    }
    forgotPassword(data) {
        return this.request({
            url: `Account/ForgotPassword`,
            method: 'post',
            data,
        });
    }
    validateResetPasswordCode(data) {
        return this.request({
            url: `Account/ValidateResetPasswordCode`,
            method: 'post',
            data,
        });
    }
    resetPassword(data) {
        return this.request({
            url: `Account/ResetPassword`,
            method: 'post',
            data,
        });
    }

    checkPhone(data) {
        return this.request({
            url: `Catalog/CheckPhone?phone=${encodeURIComponent(data)}`,
            method: 'get',
            data,
        });
    }
    checkEmail(data) {
        return this.request({
            url: `Catalog/CheckEmail?email=${data}`,
            method: 'get',
            data,
        });
    }

    checkUserHasPasswordByEmail(data) {
        return this.request({
            url: `Catalog/CheckUserHasPasswordByEmail?email=${encodeURIComponent(
                data
            )}`,
            method: 'get',
            data,
        });
    }

    checkUserHasPasswordByPhone(data) {
        return this.request({
            url: `Catalog/CheckUserHasPasswordByPhone?phone=${encodeURIComponent(
                data
            )}`,
            method: 'get',
            data,
        });
    }

    saveUserProfile(data) {
        return this.request({
            url: 'Catalog/SaveUserProfile',
            method: 'post',
            data,
        });
    }

    verifyUserPhoneNumber(data) {
        return this.request({
            url: 'Verification/VerifyUserPhoneNumber',
            method: 'post',
            data,
        });
    }

    verifyUserEmail(data) {
        return this.request({
            url: 'Verification/VerifyUserEmail',
            method: 'post',
            data,
        });
    }

    sendVerificationCodeByPhoneNumber(data) {
        return this.request({
            url: 'Verification/SendVerificationCodeByPhoneNumber',
            method: 'post',
            data,
        });
    }

    sendVerificationCodeByEmail(data) {
        return this.request({
            url: 'Verification/SendVerificationCodeByEmail',
            method: 'post',
            data,
        });
    }

    reSendVerificationCodeByPhoneNumber(data) {
        return this.request({
            url: 'Verification/ReSendVerificationCodeByPhoneNumber',
            method: 'post',
            data,
        });
    }

    reSendVerificationCodeByEmail(data) {
        return this.request({
            url: 'Verification/ReSendVerificationCodeByEmail',
            method: 'post',
            data,
        });
    }

    loginByPhoneCode(data) {
        return this.request({
            url: 'Verification/LoginByPhoneCode',
            method: 'post',
            data,
        });
    }

    loginByEmailCode(data) {
        return this.request({
            url: 'Verification/LoginByEmailCode',
            method: 'post',
            data,
        });
    }
    submitVerificationUserEmailToken(data) {
        return this.request({
            url: 'Verification/VerifyCurrentUserEmail',
            method: 'post',
            data,
        });
    }
    submitVerificationUserEmailTokenForForgotPassword(data) {
        return this.request({
            url: 'Account/ValidateResetPasswordCode',
            method: 'post',
            data,
        });
    }
    refresh(data) {
        return this.request({
            url: 'refresh',
            method: 'post',
            data,
        });
    }
    logout(data) {
        return this.request({
            url: 'logout',
            method: 'post',
            data,
        });
    }
    getUserOrganizationName(loginId) {
        return this.request({
            url: `Catalog/GetUserOrganizationName?id=${encodeURIComponent(
                loginId
            )}`,
            method: 'get',
        });
    }
}
