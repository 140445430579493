import { DayoffStatus, DayoffType } from '@/domain/enums/DayoffEnums';

export class DayoffCalendarItemClass {
    id: string;
    title: string;
    start: string;
    end: string;
    editable: boolean;
    allDay: boolean;
    extendedProps: DayoffDetailItemClass;

    constructor(data: any) {
        this.id = data?.id;
        this.title = data?.title || '';
        this.start = data?.start || '';
        this.end = data?.end || '';
        this.editable = false;
        this.allDay = data?.allDay || false;
        this.extendedProps = new DayoffDetailItemClass(data?.extendedProps);
    }
}

export class DayoffDetailItemClass {
    id: string;
    userId: number;
    userName: string;
    userAvatar: string;
    startDate: number;
    endDate: number;
    type: DayoffType;
    status: DayoffStatus;
    calendarType: 'EVENT' | 'DAYOFF';
    exception: any;

    constructor(data) {
        this.id = data?.id;
        this.userId = data?.userId;
        this.userName = data?.userName;
        this.userAvatar = data?.userAvatar;
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.type = data?.type;
        this.status = data?.status || DayoffStatus.PENDING;
        this.calendarType = 'DAYOFF';
        this.exception = data?.exception;
    }
}
