<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M435.542 56.015H76.47c-38.852 0-70.461 31.609-70.461 70.461v259.047c0 38.852 31.609 70.461 70.461 70.461h359.072c38.84 0 70.45-31.609 70.45-70.461V126.476c-.001-38.852-31.61-70.461-70.45-70.461zm46.641 329.509c0 25.727-20.926 46.652-46.641 46.652H76.47c-25.727 0-46.652-20.926-46.652-46.652V126.476c0-25.727 20.926-46.652 46.652-46.652h359.072c25.715 0 46.641 20.926 46.641 46.652z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M343.934 257.622c-12.718-10.358-31.063-9.44-42.63 2.162a31.743 31.743 0 0 0-2.151 42.63l53.035 64.997c13.837 16.926 39.352 18.286 54.872 2.767l4.638-4.638c15.497-15.497 14.179-41.03-2.767-54.86zm50.931 91.084-4.639 4.639c-5.835 5.809-14.842 4.809-19.6-.988l-53.023-64.986a8.011 8.011 0 0 1 .535-10.753c2.954-2.933 7.536-3.151 10.742-.546l64.986 53.046c6.067 4.94 6.534 14.053.999 19.588zM95.501 168.362h224.45c6.58 0 11.904-5.324 11.904-11.904s-5.324-11.904-11.904-11.904H95.501c-6.58 0-11.904 5.324-11.904 11.904s5.324 11.904 11.904 11.904zM227.437 208.109H95.501c-6.58 0-11.904 5.324-11.904 11.904s5.324 11.904 11.904 11.904h131.936c6.58 0 11.904-5.324 11.904-11.904s-5.325-11.904-11.904-11.904z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
