import {
    collection,
    deleteField,
    doc,
    endBefore,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    startAfter,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { chain } from 'lodash';

class UserConversationRepository {
    async getRecentUserConversationsAll(
        orgId: number,
        userId: number,
        startAfterVal?: any,
        pageSize?: number
    ) {
        let q = query(
            this._getCollectionRef(orgId, userId),
            where('deleted', '==', false),
            where('closed', '==', false),
            orderBy('lastMessageTime', 'desc')
        );

        if (startAfterVal) {
            q = query(q, startAfter(startAfterVal));
        }

        if (pageSize) {
            q = query(q, limit(pageSize));
        }

        return getDocs(q);
    }

    async getRecentUserConversationsTypeNormal(
        orgId: number,
        userId: number,
        startAfterVal?: any,
        pageSize?: number
    ) {
        let q = query(
            this._getCollectionRef(orgId, userId),
            where('chatAboutTaskId', '==', null),
            or(
                where('supportingChat', '==', false),
                where('supportingOrgId', '==', null)
            ),
            where('externalChat', '==', false),
            orderBy('lastMessageTime', 'desc')
        );

        if (startAfterVal) {
            q = query(q, startAfter(startAfterVal));
        }

        if (pageSize) {
            q = query(q, limit(pageSize));
        }

        return getDocs(q);
    }

    async getRecentUserConversationsTypeTask(
        orgId: number,
        userId: number,
        startAfterVal?: any,
        pageSize?: number
    ) {
        let q = query(
            this._getCollectionRef(orgId, userId),
            where('chatAboutTaskId', '!=', null),
            orderBy('lastMessageTime', 'desc')
        );

        if (startAfterVal) {
            q = query(q, startAfter(startAfterVal));
        }

        if (pageSize) {
            q = query(q, limit(pageSize));
        }

        return getDocs(q);
    }

    async getRecentUserConversationsTypeSupporting(
        orgId: number,
        userId: number,
        startAfterVal?: any,
        pageSize?: number
    ) {
        let q = query(
            this._getCollectionRef(orgId, userId),
            where('supportingChat', '==', true),
            where('supportingOrgId', '!=', null),
            orderBy('lastMessageTime', 'desc')
        );

        if (startAfterVal) {
            q = query(q, startAfter(startAfterVal));
        }

        if (pageSize) {
            q = query(q, limit(pageSize));
        }

        return getDocs(q);
    }

    async getRecentUserConversationsTypeExternal(
        orgId: number,
        userId: number,
        startAfterVal?: any,
        pageSize?: number
    ) {
        let q = query(
            this._getCollectionRef(orgId, userId),
            where('externalChat', '==', true),
            orderBy('lastMessageTime', 'desc')
        );

        if (startAfterVal) {
            q = query(q, startAfter(startAfterVal));
        }

        if (pageSize) {
            q = query(q, limit(pageSize));
        }

        return getDocs(q);
    }

    async getPinnedUserConversations(orgId: number, userId: number) {
        let q = query(
            this._getCollectionRef(orgId, userId),
            where('pinned', '==', true),
            orderBy('pinnedIndex', 'asc')
        );

        return getDocs(q);
    }

    async subscribeLastUpdatedUserConversation(
        orgId: number,
        userId: number,
        onNext: (snapshot) => void
    ) {
        let q = query(
            this._getCollectionRef(orgId, userId),
            orderBy('updatedDate', 'desc'),
            limit(1)
        );

        return onSnapshot(q, onNext);
    }

    async getOrgUserConversationSupporting(orgId: number, userId: number) {
        const collectionRef = this._getCollectionRef(orgId, userId);

        const q = query(collectionRef, where('supportingChat', '==', true));

        const querySnapshot = await getDocs(q);

        return chain(querySnapshot.docs)
            .map((doc) => doc?.data())
            .filter((conver) => !conver?.supportingOrgId)
            .head()
            .value();
    }

    subscribeUserConversations(
        orgId: number,
        userId: number,
        onNext: (snapshot) => void
    ) {
        return onSnapshot(query(this._getCollectionRef(orgId, userId)), onNext);
    }

    subscribeUserData(orgId: number, userId: number, onNext: (doc) => void) {
        const userDocRef = this._getUserDocRef(orgId, userId);

        return onSnapshot(userDocRef, onNext);
    }

    updateUserData(orgId: number, userId: number, updData: any) {
        const userDocRef = this._getUserDocRef(orgId, userId);

        return setDoc(userDocRef, updData, { merge: true });
    }

    setSeenUserConversation(orgId: number, userId: number, converId: string) {
        const docRef = doc(this._getCollectionRef(orgId, userId), converId);

        const userDocRef = this._getUserDocRef(orgId, userId);

        return Promise.all([
            updateDoc(docRef, { unSeen: 0 }),
            setDoc(
                userDocRef,
                { unSeenConversations: { [converId]: false } },
                { merge: true }
            ),
        ]);
    }

    resetUserUnSeenConversations(orgId: number, userId: number) {
        const userDocRef = this._getUserDocRef(orgId, userId);

        return setDoc(
            userDocRef,
            { unSeenConversations: null },
            { merge: true }
        );
    }

    //<editor-fold desc="ARCHIVED CONVERSATION">

    async setConversationArchived(
        orgId: number,
        userId: number,
        converId: string,
        archived: boolean
    ) {
        const updInfo: any = {
            updatedBy: `${userId}`,
            updatedDate: serverTimestamp(),
        };

        if (archived) {
            updInfo.closed = true;

            // Also unpin conversation
            updInfo.pinned = deleteField();
            updInfo.pinnedBy = deleteField();
            updInfo.pinnedDate = deleteField();
            updInfo.pinnedIndex = deleteField();
        } else {
            updInfo.closed = false;
        }

        // Update archived conversation
        const converDocRef = this._getUserConversationRef(
            orgId,
            userId,
            converId
        );
        await updateDoc(converDocRef, updInfo);

        // Set unseen conversation
        const updUser = { unSeenConversations: { [`${converId}`]: false } };

        const userDocRef = this._getUserDocRef(orgId, userId);
        await setDoc(userDocRef, updUser, { merge: true });

        return updInfo;
    }

    //</editor-fold>

    //<editor-fold desc="PINNED CONVERSATION">

    async getPinnedConversations(orgId: number, userId: number) {
        const collectionRef = this._getCollectionRef(orgId, userId);

        const q = query(collectionRef, where('pinned', '==', true));

        const querySnapshot = await getDocs(q);

        return chain(querySnapshot.docs)
            .map((doc) => doc?.data())
            .value();
    }

    async setConversationPinned(
        orgId: number,
        userId: number,
        converId: string,
        pinned: boolean
    ) {
        const updInfo: any = {
            updatedBy: `${userId}`,
            updatedDate: serverTimestamp(),
        };

        if (pinned) {
            updInfo.pinned = true;
            updInfo.pinnedBy = `${userId}`;
            updInfo.pinnedDate = serverTimestamp();
            updInfo.pinnedIndex = -1 * new Date().getTime();
        } else {
            updInfo.pinned = deleteField();
            updInfo.pinnedBy = deleteField();
            updInfo.pinnedDate = deleteField();
            updInfo.pinnedIndex = deleteField();
        }

        const docRef = this._getUserConversationRef(orgId, userId, converId);
        await updateDoc(docRef, updInfo);

        return updInfo;
    }

    async updateConversationsPinnedIndex(
        orgId: number,
        userId: number,
        convers: any[]
    ) {
        if (!convers?.length) return;

        const batch = writeBatch(firestoreDb);

        convers.forEach((conver) => {
            const docRef = this._getUserConversationRef(
                orgId,
                userId,
                conver?.id
            );
            const setConverInfo = {
                pinnedIndex: conver?.pinnedIndex || null,
                updatedBy: `${userId}`,
                updatedDate: serverTimestamp(),
            };

            batch.set(docRef, setConverInfo, { merge: true });
        });

        return batch.commit();
    }

    //</editor-fold>

    private _getCollectionRef(orgId: number, userId: number) {
        return collection(
            firestoreDb,
            'organizationUsers',
            `${orgId}`,
            'users',
            `${userId}`,
            'conversations'
        );
    }

    private _getUserConversationRef(
        orgId: number,
        userId: number,
        converId: string
    ) {
        return doc(
            firestoreDb,
            'organizationUsers',
            `${orgId}`,
            'users',
            `${userId}`,
            'conversations',
            converId
        );
    }

    private _getUserDocRef(orgId: number, userId: number) {
        return doc(
            firestoreDb,
            'organizationUsers',
            `${orgId}`,
            'users',
            `${userId}`
        );
    }
}

export default new UserConversationRepository();
