import taskListStore from '@/store/task/task-list-store';
import { TaskListTab } from '@/domain/enums/taskEnum';
import { TabTask } from '@/application/constants/task.const';
import {
    ECustomFilterSourceType,
    ETaskListModule,
} from '@/application/types/task/task.types';

export default function tableListTaskComposable() {
    const _taskListStore = taskListStore();

    const initListData = (
        ownerId,
        sourceType: ETaskListModule,
        options?: {
            customFilterSourceType?: ECustomFilterSourceType;
            isReload?: boolean;
        }
    ) => {
        return _taskListStore.initListData(ownerId, sourceType, options);
    };

    const onChangeParentTab = (tab: TaskListTab) => {
        return _taskListStore.onChangeParentTab(tab);
    };

    const onChangeChildTab = (childTab: TabTask | undefined) => {
        if (!childTab) return;

        _taskListStore.onChangeChildTab(childTab);
    };
    const getTaskSummaryStatisticByType = (
        sourceType: ETaskListModule,
        listOwnerId: number
    ) => {
        _taskListStore.getTaskSummaryStatistics(sourceType, listOwnerId);
    };

    return {
        initListData,
        onChangeParentTab,
        onChangeChildTab,
        getTaskSummaryStatisticByType,
    };
}
