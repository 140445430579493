import { ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import ChatMessageRepository from '@/application/repositories/chat/ChatMessageRepository';
import { doc, getDoc } from 'firebase/firestore';
import $constants from '@/ui/plugins/constants';

export default function reactionMessagesComposables(organizationId) {
    const reactionsViewMsgId = ref('');

    const onReactionAdd = (converId, msgId, emoji) => {
        reactionsViewMsgId.value = '';

        const userId = myProfileStore().myProfile?.id;

        activeAndFocusOnConversationId.value = `${converId}`;

        if (!converId || !userId) {
            return;
        }

        ChatMessageRepository.addMessageReaction(
            organizationId.value,
            converId,
            msgId,
            userId,
            emoji
        );

        _handleMessageReactionNotification(
            organizationId.value,
            converId,
            userId,
            msgId,
            emoji
        );
    };

    const onReactionRemove = async (converId, reactionIds) => {
        if (!converId || !reactionsViewMsgId.value) {
            return;
        }

        const userId = myProfileStore().myProfile?.id;

        return await ChatMessageRepository.removeMessageReactions(
            organizationId.value,
            converId,
            reactionsViewMsgId.value,
            userId,
            reactionIds
        );
    };

    const _handleMessageReactionNotification = async (
        orgId: number,
        converId: string,
        actionerId: number,
        msgId: string,
        reaction: any
    ) => {
        try {
            const docRef = doc(
                ChatMessageRepository.getCollectionRef(orgId, converId),
                msgId
            );
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const msg = docSnap.data();
                // console.log('aaaaaffffffffffffff ', msg);
                if (msg.createdBy != actionerId) {
                    const d = document.createElement('div');
                    d.innerHTML = msg.comment || msg.text || _getMsgData(msg);
                    const textContent = d.textContent || d.innerText;
                    notificationSettingStore().reactionOnMessage({
                        organizationId: orgId,
                        conversationId: converId,
                        messageId: msgId,
                        actionerId,
                        reaction,
                        content: textContent,
                        createdBy: msg.createdBy,
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    //  This function same logic in CFUNCTION-FIRESTORE project
    const _getMsgData = (message) => {
        // if (message?.call) {}
        if (message?.files) {
            const filesList: any[] = Object.values(message?.files) || [];

            if (filesList.length === 1) {
                if (
                    filesList[0]?.contentType?.startsWith(
                        $constants.FILE.TYPE.IMAGE
                    )
                ) {
                    return '🖼️ Photo';
                }
                if (
                    filesList[0]?.contentType?.startsWith(
                        $constants.FILE.TYPE.AUDIO
                    )
                ) {
                    return '🔉 Sound';
                }
                if (
                    filesList[0]?.contentType?.startsWith(
                        $constants.FILE.TYPE.VIDEO
                    )
                ) {
                    return '🎬 Video';
                }
            }

            return '📎 Attachments';
        }

        return '';
    };
    return {
        reactionsViewMsgId,
        onReactionAdd,
        onReactionRemove,
    };
}
