import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VCalendar from 'v-calendar';

import Root from '@/ui/Root.vue';
// import './ui/interceptor-os';
import './ui/interceptor';

import router from './ui/router';
import { declare } from './ui/hooks/declareComponents';
import filters from './ui/plugins/filters';
import { myi18n } from './ui/plugins/i18n/myi18n';
import './ui/index.css';
import 'nprogress/nprogress.css';
import '@/ui/plugins/scrollbar/perfect-scrollbar.css';
import { detectLocation } from '@/ui/app-global-state';
import customDirective from '@/ui/directive';
import '@/base/systems/check-network';
import 'rss-time-picker/dist/style.css';
import $constants from '@/ui/plugins/constants';

document.title = 'Fiine';

detectLocation();

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $t: any;
        $constants: any;
        $locale: any;
    }
}

const app = createApp(Root);
app.directive('cusFocus', {
    // When the bound element is mounted into the DOM...
    mounted(el, binding) {
        // Focus the element
        if (binding.value) {
            el.focus();
        }
    },
});
app.config.globalProperties.$constants = $constants;
app.use(createPinia());
app.use(myi18n);

app.use(VCalendar, {
    // locales: {
    //     vi: {
    //         firstDayOfWeek: 2, // Monday as the first day
    //         masks: {
    //             L: 'DD/MM/YYYY',
    //             title: 'MMMM YYYY',
    //         },
    //         // Optional, you can also define your month names here
    //         monthNames: [
    //             'Tháng 1',
    //             'Tháng 2',
    //             'Tháng 3',
    //             'Tháng 4',
    //             'Tháng 5',
    //             'Tháng 6',
    //             'Tháng 7',
    //             'Tháng 8',
    //             'Tháng 9',
    //             'Tháng 10',
    //             'Tháng 11',
    //             'Tháng 12',
    //         ],
    //     },
    // },
    // locale: 'vi',
});
app.use(filters);
// declare custom directive
customDirective(app);

// declare(requireCommon, app);
// declare(iconComponent, app);
// declare(layoutComponent, app);

declare(
    import.meta.glob(
        [
            './ui/common/**/{Syn,Vig,Atom,Molecule}*.vue',
            './ui/components/icons/*.vue',
            './ui/components/layouts/*.vue',
        ],
        { eager: true }
    ),
    app
);

app.use(router);
app.mount('#app');
