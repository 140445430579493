<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 128 128"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M59.232 70.45a2.468 2.468 0 0 0-3.849-.075C43.535 84.6 30.551 96.137 13.263 93.177a2.473 2.473 0 0 0-2.833 1.853l-.013.053a2.475 2.475 0 0 0 1.969 3.017 37.055 37.055 0 0 0 6.318.551c16.582 0 29.348-11.7 40.508-25.064a2.473 2.473 0 0 0 .051-3.094zM68.091 57.42a2.468 2.468 0 0 0 3.879-.008c11.517-14.724 22.8-27.467 36.945-25.734L98.091 42.5a2.6 2.6 0 0 0-.242 3.48 2.509 2.509 0 0 0 1.915.9 2.461 2.461 0 0 0 1.76-.74l15.39-15.39a2.5 2.5 0 0 0 0-3.539l-15.281-15.294a2.6 2.6 0 0 0-3.51-.229 2.51 2.51 0 0 0-.129 3.66l11.32 11.33c-16.6-1.974-29.27 12.3-41.273 27.635a2.477 2.477 0 0 0 .013 3.061z"
      ></path>
      <path
        d="m116.918 97.222-15.391-15.393a2.5 2.5 0 0 0-3.535 3.535l10.924 10.926C93.357 98.2 81.253 82.575 68.5 66.088 53.791 47.062 37.181 25.582 12.4 29.866a2.48 2.48 0 0 0-1.977 3.02l.013.054a2.473 2.473 0 0 0 2.834 1.85c21.822-3.74 36.794 15.618 51.284 34.355 12.325 15.938 25.011 32.345 41.694 32.345a26.067 26.067 0 0 0 3.075-.2L97.99 112.623a2.5 2.5 0 0 0 0 3.535 2.5 2.5 0 0 0 3.535 0l15.392-15.4a2.5 2.5 0 0 0 .001-3.536z"
      ></path>
    </g>
  </svg>
</template>
