import { AdminType } from '@/ui/common/constants/constant';
export const permissionByRoleName = {
    OverviewPage: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    OverviewMonitorPage: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: false,
        [AdminType.Visitor]: false,
    },
    OverviewMembers: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
    OverviewGroups: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    OverviewDomains: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    OverviewFinishedTasks: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    OverviewCreatedTasks: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    OverviewWorkflows: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
        [AdminType.Owner]: true,
    },
    Tasks: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    UserTasks: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    MyTasks: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    GroupTasks: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    DomainTasks: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    DayOff: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
    TaskDetail: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    UserTaskDetail: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    GroupTaskDetail: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    Settings: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    PersonalSetting: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    Profile: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    ZaloAccessToken: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    Notifications: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    Messages: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    Resources: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    Calendar: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    Video: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    Calendars: {
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
        [AdminType.Owner]: true,
    },
    UserManagement: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: false,
        [AdminType.Visitor]: false,
    },
    DomainManagement: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: false,
        [AdminType.Visitor]: false,
    },
    MessagesSupport: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
    WhiteboardPage: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    WhiteboardList: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    WhiteboardDetail: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    // Function
    FunctionTaskAssign: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: true,
    },
    FunctionGroupInfoEdit: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
    FunctionGroupDomainModify: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
    FunctionGroupAdd: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
    FunctionGroupUserInvite: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
    FunctionSeeMemberTask: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },

    FunctionUploadAvatarUser: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
    FunctionUploadAvatarGroup: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: false,
        [AdminType.Visitor]: false,
    },
    PerformanceOverview: {
        [AdminType.Owner]: true,
        [AdminType.Admin]: true,
        [AdminType.User]: true,
        [AdminType.Visitor]: false,
    },
};
