<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import groupStore from '@/store/group';
import FormAddUser from '@/ui/modules/group/detail/FormAddUser.vue';
import FormAddScope from '@/ui/modules/group/detail/FormAddScope.vue';
import FormSetting from '@/ui/modules/group/detail/setting/FormSetting.vue';
import GroupDetailInformation from '@/ui/modules/group/detail/GroupDetailInformation.vue';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import CommonInitComponent from '@/ui/components/common/CommonInitComponent.vue';
import { IGroupDetailEntity } from '@/domain/entities/group/GroupDetailEntity';
import { EGroupType } from '@/application/types/group/group.types';

const props = withDefaults(
  defineProps<{
    groupId: number;
    currentTab?: string;
  }>(),
  {
    currentTab: '',
  }
);
defineEmits([
  'cancel',
  'showDelete',
  'changedPrivateSuccess',
  'onDeleteSuccess',
]);
const _groupStore = groupStore();

const allGroupByIds = computed<{ number: IGroupDetailEntity }>(() => {
  return _groupStore.allGroupByIds;
});
const groupDetail = computed<IGroupDetailEntity>(() => {
  if (
    !allGroupByIds.value ||
    !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
  )
    return {};

  return allGroupByIds.value[props.groupId];
});

const countUser = ref(0);
const countDomain = ref(0);
const showAlertSystemGroup = ref(false);

watch(
  () => groupDetail.value,
  () => {
    _processDisabledTab();
  },
  { deep: true }
);
watch(
  () => groupDetail.value?.code,
  () => {
    latestUpdatedTime.value = Date.now();
  }
);

const latestUpdatedTime = ref<number>(Date.now());

const init = async () => {
  if (!props.groupId) throw new Error();

  await _groupStore.getDetailGroupById(props.groupId);

  countUser.value = groupDetail.value?.groupUsers?.length;
  countDomain.value = groupDetail.value?.groupDomains?.length;
  if (props.currentTab) {
    changeTab(props.currentTab);
  }

  _processDisabledTab();
};

const detailGroupStatus = ref({
  INFO_GROUP: {
    key: 'INFO_GROUP',
    label: 'COMMON_LABEL_INFORMATION',
    isActive: true,
  },
  MEMBERS: {
    key: 'MEMBERS',
    label: 'TASK_TABLE_LABEL_MEMBERS',
    exp: countDomain.value,
    isActive: false,
  },
  SCOPE_GROUP: {
    key: 'SCOPE_GROUP',
    label: 'TASK_CREATE_GROUP_FORM_SCOPE_GROUP',
    exp: countUser.value,
    isActive: false,
    disabled: false,
  },
  SETTING: {
    key: 'SETTING',
    label: 'COMMON_LABEL_SETTINGS_GROUP',
    isActive: false,
  },
});

const changeTab = (key) => {
  for (var k in detailGroupStatus.value) {
    if (k == key) {
      detailGroupStatus.value[k].isActive = true;
    } else {
      detailGroupStatus.value[k].isActive = false;
    }
  }
};

const onSaveSetting = async (value) => {
  await _groupStore.createAndUpdate({
    id: groupDetail.value.id,
    name: groupDetail.value.name,
    description: groupDetail.value.description,
    isPrivate: value,
    email: groupDetail.value.email,
    avatar: groupDetail.value.avatar,
    type: groupDetail.value.type,
    // userIds: groupDetail.value.groupUsers.map(el => el.userId),
    memberInfos: groupDetail.value.groupUsers,
    domainIds: groupDetail.value.groupDomains.map((el) => el.domainId),
  });
};
const _processDisabledTab = () => {
  detailGroupStatus.value.SCOPE_GROUP.disabled =
    groupDetail.value?.type === EGroupType.CHAT;
};
</script>
<template>
  <CommonInitComponent :key="latestUpdatedTime" :init-component="init">
    <SynTabs
      :option-status="detailGroupStatus"
      positon="center"
      class="overflow-hidden pb-4"
      @change-tab="(key) => changeTab(key)"
    >
      <template #tabBody>
        <!-- INFOMATION -->
        <section v-if="detailGroupStatus['INFO_GROUP'].isActive" class="h-full">
          <GroupDetailInformation
            :group-id="groupId"
            @system-group-alert="showAlertSystemGroup = true"
          />
        </section>

        <!-- MEMBERS -->
        <template v-if="detailGroupStatus['MEMBERS'].isActive">
          <FormAddUser
            :group-id="groupId"
            @save-user="latestUpdatedTime = Date.now()"
            @system-group-alert="showAlertSystemGroup = true"
          />
        </template>

        <!-- DOMAIN -->
        <template v-if="detailGroupStatus['SCOPE_GROUP'].isActive">
          <FormAddScope
            :group-id="groupId"
            @save-scope="latestUpdatedTime = Date.now()"
            @system-group-alert="showAlertSystemGroup = true"
          />
        </template>

        <!-- SETTING -->
        <template v-if="detailGroupStatus['SETTING'].isActive">
          <template v-if="groupDetail.code !== 'DELETED_GROUP'">
            <FormSetting
              :group-id="groupId"
              @save-setting="onSaveSetting"
              @deleted-success="[$emit('onDeleteSuccess'), $emit('cancel')]"
              @system-group-alert="showAlertSystemGroup = true"
              @changed-private-success="$emit('changedPrivateSuccess')"
            />
          </template>
          <template v-else>
            <SynAnimation name="setting" stype="width: 150px; height: 150px;" />
            <span class="text-center italic text-sm">
              {{ $t('GROUP_DETAIL_TITLE_ALERT_DELETE_GROUP') }}
            </span>
          </template>
        </template>
      </template>
    </SynTabs>
  </CommonInitComponent>
  <AlertModal
    v-if="showAlertSystemGroup"
    :title-text="$t('GROUP_LABEL_EDIT_SYSTEM_GROUP_TITLE')"
    :sub-title-text="
      $t('GROUP_LABEL_REMOVE_SYSTEM_GROUP_CONTENT', {
        groupName: groupDetail.name,
      })
    "
    :is-html-text="true"
    container-class="w-[40rem]"
    :container-color="'current'"
    @cancel="showAlertSystemGroup = false"
  />
</template>
