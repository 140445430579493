import CalendarSharingPage from '@/ui/pages/calendar-sharing/CalendarSharingPage.vue';

export default [
    {
        path: 'calendar/:sharingId',
        name: 'CalendarSharingPage',
        component: CalendarSharingPage,
        meta: { layout: 'Shared' },
    },
];
