<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskAttachmentDetail from '@/ui/modules/task/detail/TaskAttachmentDetail.vue';
import TaskBoardCardLoading from '@/ui/modules/task/board/task-card/TaskBoardCardLoading.vue';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';
import {
  EFilterTaskBoard,
  EFilterTaskDefault,
  ETaskListMenuTab,
  ITaskSoftLocal,
  TASK_SORT_BY,
} from '@/application/constants/task.const';
import { arrayOrderBy, stringNomalize } from '@/ui/hooks/commonFunction';
import { getUrgencyValue, sortDefaultTaskList } from '@/ui/hooks/taskHook';
import { TASK_PIN_BY_CODE } from '@/application/constants/task.const';
import taskTabViewStore from '@/store/task/task-tab-view';
import {
  isOpenTaskDrawer,
  isOpenTaskPinnedDrawer,
} from '@/ui/modules/task/task-global-state';
import taskListStore from '@/store/task/task-list-store';
import pinnedStore from '@/store/task/pinned.store';
import taskStore from '@/store/task';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import myProfileStore from '@/store/auth/my-profile';
import UserDynamicModel from '@/ui/plugins/firebases/realtime-database/model/UserDynamicModel';
import { TaskActionType } from '@/domain/enums/taskEnum';
import TaskService from '@/application/services/task/TaskService';
import { isToday } from '@/ui/plugins/filters';
import { translate } from '@/ui/plugins/i18n/myi18n';

const MAX_ROW_FIRST_TIME = 20;
const props = withDefaults(
  defineProps<{
    type: 'USER' | 'GROUP' | 'DOMAIN';
    ownerId?: number | string;
  }>(),
  {
    type: 'USER',
    ownerId: getCurrentUserId(),
  }
);

defineEmits(['onCollapseBoard', 'onOpenTaskDetail', 'onAddNewTask', 'onClose']);
const router = useRouter();
const route = useRoute();

const _taskTabViewStore = taskTabViewStore();
const _taskListStore = taskListStore();
const _pinnedStore = pinnedStore();
let { unsubscribeCallbacks } = listenFirebaseRealtime();

const allTaskByIds = computed(() => taskStore().allTaskByIds);
const allPinnedTaskIds = computed(() => _pinnedStore.allPinnedTaskIds);
const currentRoute = computed(() => route.name);

const currentSortData = ref<ITaskSoftLocal>({
  ...TASK_SORT_BY.DEFAULT,
} as ITaskSoftLocal);

const sortedTaskIds = ref<any[]>([]);
const isLoading = ref<boolean>(false);

const listTaskIdShowing = ref<string[]>([]);

const initComponent = async () => {
  isLoading.value = true;

  await _pinnedStore.getAllPinnedTasks();

  isLoading.value = false;
};

const updateTaskListRealtime = async (taskId, taskAction) => {
  if (taskAction == TaskActionType.Unpinned) {
    onUnpinSuccess(taskId);
    return;
  }
  if (
    taskAction !== TaskActionType.Edit &&
    taskAction !== TaskActionType.Pinned
  ) {
    return;
  }

  const res = await TaskService.getInstance().getDetailTaskIncognitoById(
    taskId
  );
  const taskDetail = res['result'];

  const taskDetailUpdate = {
    ...taskDetail,
    urgency: getUrgencyValue(taskDetail?.priority, taskDetail?.important),
  };
  taskStore().updateAllTaskByIds(taskDetailUpdate.id, taskDetailUpdate);

  if (taskAction == TaskActionType.Pinned) onPinSuccess(taskId);
};
const onListenFirebase = async () => {
  const myProfile = myProfileStore().myProfile;
  const latestTaskActivity = new UserDynamicModel(
    `user_${myProfile?.id}/latestTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    latestTaskActivity.onChange(
      (updatedTask: {
        taskAction: TaskActionType;
        taskActionTime: number;
        taskId: number;
      }) => {
        console.log(
          '🚀 Hyrin ~ latestTaskActivity.onChange ~ updatedTask:',
          updatedTask
        );
        if (!isToday(updatedTask?.taskActionTime)) return;

        updateTaskListRealtime(updatedTask?.taskId, updatedTask?.taskAction);
      }
    )
  );
};

let lastMultiUpdateTime;

const onListenMultiTask = async () => {
  const myProfile = myProfileStore().myProfile;
  const latestTaskActivity = new UserDynamicModel(
    `user_${myProfile?.id}/latestMultiTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    latestTaskActivity.onChange(
      (snapshot: {
        action: TaskActionType;
        actionTime: number;
        taskIds: number[];
        data: any;
      }) => {
        if (
          Date.now() - lastMultiUpdateTime < 500 ||
          !isToday(snapshot?.actionTime)
        )
          return;

        lastMultiUpdateTime = Date.now();
        if (snapshot?.taskIds?.length == 0) return;

        for (const taskId of snapshot.taskIds) {
          const taskAction = snapshot?.action;

          if (taskAction == TaskActionType.Unpinned) {
            onUnpinSuccess(taskId);
            return;
          }
          if (
            taskAction !== TaskActionType.Edit &&
            taskAction !== TaskActionType.Pinned
          ) {
            return;
          }

          const updatedData =
            snapshot?.action == TaskActionType.ChangePriority
              ? {
                  ...snapshot?.data,
                  urgency: getUrgencyValue(
                    snapshot?.data?.priority,
                    snapshot?.data?.important
                  ),
                }
              : snapshot?.data;
          taskStore().updateAllTaskByIds(updatedData.id, updatedData);

          if (taskAction == TaskActionType.Pinned) onPinSuccess(taskId);
        }
      }
    )
  );
};

onMounted(async () => {
  onListenFirebase();
  onListenMultiTask();

  await initComponent();

  onSortData();
  onFilterList();
});

watch(
  () => props.ownerId,
  () => {
    initComponent();
  }
);

watch(
  () => isLoading.value,
  () => {
    onSortData();
  }
);

const isHasOpenAttachment = ref(null as any);
const currentTaskId = ref(0 as number);
const isOpenAttachmentDetail = ref(false);
const onOpenAttachmentDetail = (taskId) => {
  if (isHasOpenAttachment.value) isHasOpenAttachment.value = null;
  currentTaskId.value = taskId;
  isHasOpenAttachment.value = taskId;
  isOpenAttachmentDetail.value = true;
};

const onCloseAttachment = () => {
  isOpenAttachmentDetail.value = false;
};

const isHovered = ref<boolean>(false);
const onHoverCard = () => {
  if (isHovered.value) return;
  onLoadMore();

  isHovered.value = true;
};
const onLoadMore = () => {
  listTaskIdShowing.value =
    sortedTaskIds.value?.length > 0
      ? [
          ...sortedTaskIds.value?.slice(
            0,
            listTaskIdShowing.value?.length + MAX_ROW_FIRST_TIME
          ),
        ]
      : [];
};

const onSortData = () => {
  const currentIterate =
    currentSortData.value?.key === 'DEFAULT'
      ? []
      : currentSortData.value?.orderData?.iterate;
  const currentOrder =
    currentSortData.value?.key === 'DEFAULT'
      ? []
      : currentSortData.value?.orderData?.order;

  sortedTaskIds.value = arrayOrderBy(
    allPinnedTaskIds.value?.map((taskId) => allTaskByIds.value[taskId]),
    [...currentIterate, ...sortDefaultTaskList.iterate],
    [...currentOrder, ...sortDefaultTaskList.order]
  )?.map((task: any) => task?.id);

  listTaskIdShowing.value =
    sortedTaskIds.value?.length > 0
      ? [...sortedTaskIds.value?.slice(0, MAX_ROW_FIRST_TIME)]
      : [];
};

const currentFilterByPinCode = computed(
  () => _pinnedStore.currentFilterByPinCode
);

const currentSearchValue = ref<string>('');

const onFilterList = () => {
  let filteringTasks = allPinnedTaskIds.value?.map(
    (taskId) => allTaskByIds.value[taskId]
  );
  if (currentSearchValue.value) {
    filteringTasks = filteringTasks.filter(
      (t) =>
        stringNomalize(t.name?.toLowerCase()).includes(
          stringNomalize(currentSearchValue.value?.toLowerCase())
        ) ||
        stringNomalize(t.code?.toLowerCase()).includes(
          stringNomalize(currentSearchValue.value?.toLowerCase())
        ) ||
        stringNomalize(t.displayName?.toLowerCase()).includes(
          stringNomalize(currentSearchValue.value?.toLowerCase())
        )
    );
  }
  if (currentFilterByPinCode.value !== 'ALL') {
    filteringTasks = filteringTasks?.filter(
      (task) => task?.pinnedColor == currentFilterByPinCode.value
    );
  }

  sortedTaskIds.value = [...filteringTasks?.map((task) => task?.id)];
  listTaskIdShowing.value =
    sortedTaskIds.value?.length > 0
      ? [...sortedTaskIds.value?.slice(0, MAX_ROW_FIRST_TIME)]
      : [];
};

const prepareDataUIOfTab = computed(() => {
  let result = {} as any;
  const linkImg = `https://d1tvqetrcurhzb.cloudfront.net/common/images/`;
  result = {
    imgUrl: `${linkImg}all-task-mylist.png`,
    imgSize: 'h-40 w-auto',
    titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_TITLE',
    subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_SUB_TITLE',
  };
  return result;
});

const listCustomize = {
  LIST: {
    key: 'LIST',
    nameIcon: 'redirect-to-list',
    label: 'TASK_LIST_VIEW_LIST_LABEL',
  },
  BOARD: {
    key: 'BOARD',
    nameIcon: 'RedirectToBoard',
    label: 'TASK_LIST_VIEW_BOARD_LABEL',
  },
};

const onFilterByPin = (pin) => {
  _pinnedStore.currentFilterByPinCode = pin?.code;
  onFilterList();
};
const onResetFilter = () => {
  _pinnedStore.currentFilterByPinCode = 'ALL';
  onSortData();
};

const gotoMyTasks = () => {
  isOpenTaskDrawer.value = false;
  router.push({ name: 'MyTasks' });
};

const onRedirect = (viewCode) => {
  console.log('🚀 Hyrin ~ onFilterByPin ~ pin:', viewCode);
  if (currentRoute.value !== 'MyTasks') gotoMyTasks();
  _taskTabViewStore.setCustomizeViewActive(viewCode);

  if (viewCode == 'LIST')
    _taskListStore.onChangeChildTab({
      id: EFilterTaskDefault.PINNED,
      menuTab: ETaskListMenuTab.PINNED,
      active: false,
      name: translate('COMMON_LABEL_PIN'),
      key: 'Pinned',
      labelKey: 'COMMON_LABEL_PIN',
      code: '',
      value: '2',
      color: '#ec4899',
      totalTask: 0,
      isDefault: true,
      visible: false,
      isCollapse: false,
    });
  else _taskTabViewStore.changeCustomTabInBoard(EFilterTaskBoard.PINNED);

  isOpenTaskPinnedDrawer.value = false;
  // _taskListStore.lastUpdatedTime = Date.now();
};

const onPinSuccess = (taskId: any) => {
  if (allPinnedTaskIds.value?.some((id) => id == taskId)) return;

  listTaskIdShowing.value.unshift(taskId);
  sortedTaskIds.value.unshift(taskId);

  _pinnedStore.allPinnedTaskIds.push(taskId);
};
const onUnpinSuccess = (taskId) => {
  listTaskIdShowing.value = listTaskIdShowing.value?.filter(
    (id) => id !== taskId
  );
  _pinnedStore.allPinnedTaskIds = _pinnedStore.allPinnedTaskIds?.filter(
    (id) => id !== taskId
  );

  sortedTaskIds.value = sortedTaskIds.value?.filter((id) => id !== taskId);
};
</script>

<template>
  <div
    v-bind="$attrs"
    class="flex flex-col h-full hover-to-show__parent"
    style="width: 25rem"
    @mouseover="onHoverCard"
  >
    <div class="flex items-center justify-between px-2 py-2 bg-white">
      <div class="w-max flex-center space-x-0.5 p-1">
        <div
          class="
            flex-center
            rounded-md
            w-max
            h-8
            p-2
            hover:bg-gray-100
            cursor-pointer
            dropdown-item
          "
          :class="currentFilterByPinCode == 'ALL' ? 'bg-current-50' : ''"
          @click="onResetFilter"
        >
          <span class="text-sm">
            {{ $t('COMMON_LABEL_ALL') }}
          </span>
        </div>
        <template
          v-for="(pin, index) in Object.values(TASK_PIN_BY_CODE)?.filter(
            (pin) => pin.code !== 'UN_PIN'
          )"
          :key="index"
        >
          <div
            class="
              flex-center
              rounded-md
              w-8
              h-8
              p-2
              hover:bg-gray-100
              cursor-pointer
              dropdown-item
            "
            :class="currentFilterByPinCode == pin.code ? 'bg-current-50' : ''"
            @click="onFilterByPin(pin)"
          >
            <SynIcon
              name="pin"
              is-active
              custom-class="w-5 h-5"
              :style="{
                fill: pin?.color,
              }"
            />
          </div>
        </template>
      </div>

      <!-- REDIRECT ACTION BUTTON -->
      <div class="flex-center space-x-1">
        <template v-for="view in Object.values(listCustomize)" :key="view">
          <div
            v-vig-tooltip="$t(view?.label)"
            class="
              flex-center
              rounded-md
              w-8
              h-8
              p-2
              hover:bg-gray-100
              cursor-pointer
              dropdown-item
            "
            @click="onRedirect(view.key)"
          >
            <SynIcon :name="view?.nameIcon" />
          </div>
        </template>
      </div>
    </div>

    <div
      v-scroll-infinite="onLoadMore"
      class="bg-gray-50 rounded flex-1 overflow-y-auto small-scrollbar relative"
    >
      <TaskBoardCardLoading v-if="isLoading" class="m-2" />

      <template v-else-if="listTaskIdShowing?.length > 0">
        <TaskCardItem
          v-for="taskId in listTaskIdShowing"
          :key="taskId"
          class="m-2"
          :task-detail="allTaskByIds[taskId]"
          @on-show-files="onOpenAttachmentDetail(taskId)"
          @un-pin-success="onUnpinSuccess(taskId)"
        />

        <div
          v-if="!isLoading && listTaskIdShowing?.length < sortedTaskIds?.length"
          class="px-3 py-2 mt-2 flex-center"
        >
          <button
            class="px-3 py-1 rounded-full bg-gray-200 hover:bg-gray-300"
            @click="onLoadMore"
          >
            {{ $t('GED_SHOW_MORE') }}
          </button>
        </div>
      </template>

      <template v-else>
        <div class="flex-center flex-col pb-4 pt-12">
          <VigImage
            :path="prepareDataUIOfTab?.imgUrl"
            :custom-class="`${prepareDataUIOfTab?.imgSize || 'h-42 w-96'}`"
          />
          <span
            class="text-current-700 pt-8 text-sm"
            v-html="$t(prepareDataUIOfTab?.titleKey)"
          ></span>
          <span
            class="text-gray-500 text-xs py-2"
            v-html="$t(prepareDataUIOfTab?.subTitleKey)"
          ></span>
        </div>
      </template>
    </div>
  </div>

  <TaskAttachmentDetail
    v-if="isOpenAttachmentDetail"
    :task-id="currentTaskId"
    @on-close="onCloseAttachment"
  />
</template>
