<!-- <template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="#008E8E"
    class="w-6 h-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
    />
  </svg>
</template> -->
<template>
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 24 24"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--    class=""-->
  <!--  >-->
  <!--    <g>-->
  <!--      <g id="Layer_24" data-name="Layer 24">-->
  <!--        <path-->
  <!--          d="m23 11v7.2a2.91 2.91 0 0 1 -3 2.8h-16a2.91 2.91 0 0 1 -3-2.8v-7.2a1 1 0 0 1 2 0v7.2a.93.93 0 0 0 1 .8h16a.93.93 0 0 0 1-.8v-7.2a1 1 0 0 1 2 0zm-13.41-3.17 1.41-1.42v8.59a1 1 0 0 0 2 0v-8.59l1.41 1.42a1 1 0 0 0 1.42 0 1 1 0 0 0 .29-.71 1 1 0 0 0 -.29-.71l-3.12-3.12a1 1 0 0 0 -.64-.29h-.19a1 1 0 0 0 -.54.24h-.05l-3.12 3.17a1 1 0 1 0 1.42 1.42z"-->
  <!--          class=""-->
  <!--        ></path>-->
  <!--      </g>-->
  <!--    </g>-->
  <!--  </svg>-->
  <!--  <svg-->
  <!--    xmlns="http://www.w3.org/2000/svg"-->
  <!--    version="1.1"-->
  <!--    xmlns:xlink="http://www.w3.org/1999/xlink"-->
  <!--    xmlns:svgjs="http://svgjs.com/svgjs"-->
  <!--    width="512"-->
  <!--    height="512"-->
  <!--    x="0"-->
  <!--    y="0"-->
  <!--    viewBox="0 0 512 512"-->
  <!--    style="enable-background: new 0 0 512 512"-->
  <!--    xml:space="preserve"-->
  <!--  >-->
  <!--    <g>-->
  <!--      <path-->
  <!--        d="M512 480c0 17.673-14.327 32-32 32H32c-17.673 0-32-14.327-32-32s14.327-32 32-32h448c17.673 0 32 14.327 32 32zM233.373 378.628c6.249 6.249 14.437 9.373 22.627 9.373 8.188 0 16.38-3.125 22.627-9.373l113.378-113.377c12.497-12.497 12.497-32.758 0-45.255s-32.758-12.497-45.255 0L288 278.746V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v246.746l-58.75-58.75c-12.497-12.497-32.758-12.497-45.255 0s-12.497 32.758 0 45.255z"-->
  <!--      ></path>-->
  <!--    </g>-->
  <!--  </svg>-->

  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M30 24v4c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2v-4a1 1 0 0 1 2 0v4h24v-4a1 1 0 0 1 2 0z"
      ></path>
      <path
        d="M8.293 16.707a.999.999 0 1 1 1.414-1.414L15 20.586V3a1 1 0 0 1 2 0v17.586l5.293-5.293a.999.999 0 1 1 1.414 1.414l-7 7a1.001 1.001 0 0 1-1.415 0z"
      ></path>
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
