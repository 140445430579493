import DateoffExceptionClass from './DateoffExceptionClass';

export default class DayoffModifyPayloadClass {
    id: string;
    startDate: number;
    endDate: number;
    startDateSession: number;
    endDateSession: number;
    requestReason: string; // keyword
    requestNote: string;
    exception: DateoffExceptionClass;
    constructor(data) {
        this.id = data?.id;
        this.startDate = data?.startDate;
        this.endDate = data?.endDate;
        this.startDateSession = data?.startDateSession;
        this.endDateSession = data?.endDateSession;
        this.requestReason = data?.requestReason;
        this.requestNote = data?.requestNote;
        this.exception = data?.exception;
    }
}
