<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M13.51 24a.5.5 0 0 1-.459-.304l-3.06-7.149-3.752 3.269A.75.75 0 0 1 5 19.25V.75c0-.588.747-.964 1.242-.571l13.542 13.538c.185.23.216.412.216.533a.75.75 0 0 1-.75.75h-5.022l2.962 6.923a.499.499 0 0 1-.263.656l-3.22 1.38a.5.5 0 0 1-.197.041zm-3.33-8.78a.499.499 0 0 1 .459.304l3.133 7.32 2.301-.986-3.063-7.161a.501.501 0 0 1 .46-.697h5.188L6 1.347V18.7l3.852-3.357a.5.5 0 0 1 .328-.123zm-4.59 3.837a.856.856 0 0 0-.033.029zm13.447-4.678h.01z"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
