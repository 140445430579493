<script setup lang="ts">
import { computed } from 'vue';
// import taskTabViewStore from '@/store/task/task-tab-view';
import { taskStates } from '@/ui/hooks/taskHook';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import TaskBoardCardPinned from '@/ui/modules/task/board/board-container/TaskBoardCardPinned.vue';
import { ICustomTaskFilter } from '@/application/interfaces/tasks/task-interfaces';
import { TASK_PIN_BY_CODE } from '@/application/constants/task.const';

const props = withDefaults(
  defineProps<{
    currentContactId: any;
    type: string;
  }>(),
  {
    currentContactId: getCurrentUserId(),
    type: 'USER',
  }
);

const listStates = computed<ICustomTaskFilter[]>(() => {
  if (!taskStates) return [];
  const stateList = Object.values(TASK_PIN_BY_CODE).filter(
    (o) => o.code !== 'UN_PIN'
  );
  return stateList?.map((o, index) => {
    return {
      id: o.code,
      name: '',
      index,
      color: o.color,
      visible: true,
      filterObject: {
        isDefault: false,
        listOwnerId: props.currentContactId,
        pinnedColor: o.code,
      },
    };
  });
});
</script>

<template>
  <div
    v-drag-to-scroll="{ targetClass: 'drag-to-scroll-target' }"
    class="h-full flex overflow-auto"
  >
    <template v-for="card in listStates" :key="card">
      <TaskBoardCardPinned
        class="text-xs mx-1.5"
        :type="type"
        :card-id="card?.id"
        :card-information="card"
        :source-id="currentContactId"
      />
    </template>
  </div>
</template>
