<script setup lang="ts">
import VigDropdown from '@/ui/common/atoms/VigDropdown/VigDropdown.vue';
import SynAvatar from '@/ui/common/molecules/SynAvatar/SynAvatar.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const emit = defineEmits(['onChangeDepartment']);
defineProps({
  departments: {
    default: [] as any,
    type: Array,
  },
  departmentSelect: {
    default: '',
    type: String || Number,
  },
});

const onChangeDepartment = (dpm) => {
  emit('onChangeDepartment', dpm);
};
</script>

<template>
  <VigDropdown>
    <template #dropdown-toggle>
      <div
        v-vig-tooltip="$t('TASK_FILTER_LABEL_FILTER')"
        class="w-6 h-6 flex-center relative"
      >
        <SynIcon
          name="filter2"
          class="cursor-pointer"
          :custom-class="`w-3.5 h-3.5 ${
            departmentSelect !== '' ? 'fill-current' : 'fill-gray-500'
          } `"
        />
        <!--        <span-->
        <!--          v-if="totalFilterItem"-->
        <!--          class="absolute h-2 w-2 bg-current-500 rounded-full right-0.5 top-0.5"-->
        <!--        ></span>-->
      </div>
    </template>
    <template #dropdown-menu>
      <div
        class="flex flex-col overflow-auto small-scroll-bar"
        style="max-height: 25rem; width: 15rem"
      >
        <div
          class="
            pl-2
            py-2
            hover:bg-gray-50
            cursor-pointer
            dropdown-item
            text-sm
            flex
            items-center
            space-x-2
          "
          :class="
            departmentSelect == ''
              ? 'bg-current-50 font-medium text-current-500'
              : ''
          "
          @click="onChangeDepartment('')"
        >
          <div
            class="
              bg-current
              rounded-full
              border
              flex-center
              overflow-hidden
              h-5
              w-5
            "
          >
            <SynIcon name="department" custom-class="h-4 w-4 fill-white" />
          </div>
          <span>{{ $t('COMMON_LABEL_ALL') }}</span>
        </div>
        <div
          v-for="department in departments"
          :key="department.id"
          class="
            pl-2
            py-2
            space-x-2
            flex
            items-center
            hover:bg-gray-50
            cursor-pointer
            dropdown-item
            text-sm
          "
          :class="
            departmentSelect == department.id
              ? 'bg-current-50 font-medium text-current-500'
              : ''
          "
          @click="onChangeDepartment(department.id)"
        >
          <SynAvatar
            :src="department.avatar"
            :name="department.name"
            custom-class="w-5 h-5"
          />
          <span>{{ department.name }}</span>
        </div>
        <div
          class="
            pl-2
            py-2
            hover:bg-gray-50
            cursor-pointer
            dropdown-item
            text-sm
            flex
            items-center
            space-x-2
          "
          :class="
            departmentSelect == -1
              ? 'bg-current-50 font-medium text-current-500'
              : ''
          "
          @click="onChangeDepartment(-1)"
        >
          <div
            class="
              bg-gray-400
              rounded-full
              border
              flex-center
              overflow-hidden
              h-5
              w-5
            "
          >
            <SynIcon name="user" custom-class="h-3 w-3 fill-white" />
          </div>
          <span>{{ $t('DEPARTMENT_LABEL_USER_HAS_NOT_DEPARTMENT') }}</span>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
