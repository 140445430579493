import { getAuth, RecaptchaVerifier } from 'firebase/auth';

export const initGoogleCaptcha = async (containerId, parentContainerId) => {
    try {
        destroyGoogleCaptcha(containerId);

        if (!(window as any).recaptchaVerifier) {
            const parentContainer = document.getElementById(parentContainerId);
            const container = document.createElement('div');
            container.id = containerId;

            parentContainer?.appendChild(container);

            const auth = getAuth();
            (window as any).recaptchaVerifier = new RecaptchaVerifier(
                containerId,
                {
                    size: 'invisible',
                },
                auth
            );
        }

        await (window as any).recaptchaVerifier.render();
        return (window as any).recaptchaVerifier.verify();
    } catch (error) {
        console.log('error', error);
        return '';
    }
};

export const destroyGoogleCaptcha = (containerId = null) => {
    try {
        if (containerId) {
            const element = document.getElementById(containerId);
            element?.remove();
        }
        if ((window as any).recaptchaVerifier) {
            (window as any).recaptchaVerifier.clear();
            (window as any).recaptchaVerifier = null;
        }
    } catch (error) {
        console.log('🚀 ~ destroy google captcha ~ error', error);
    }
};
