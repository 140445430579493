<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import DepartmentRemoveModal from '@/ui/modules/departments/components/DepartmentRemoveModal.vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import departmentStore from '@/store/department';
import router from '@/ui/router';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
import { IDepartmentDetail } from '@/application/types/department/department.types';
import DepartmentHasNotAdminWarning from '@/ui/modules/departments/components/DepartmentHasNotAdminWarning.vue';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

const emit = defineEmits([
  'changeStatus',
  'showDetail',
  'confirmDeleted',
  'onCreateNew',
]);

const isLoadingGroup = ref(false);
const _departmentStore = departmentStore();

const allDepartments = computed<IDepartmentDetail[]>(
  () => _departmentStore.allDepartments
);

onMounted(async () => {
  isLoadingGroup.value = true;
  await _departmentStore.getAll();
  isLoadingGroup.value = false;
});

const filteredDepartments = computed(() => {
  const searchTxt = ignoreUnicode(keySearch.value);

  let result = allDepartments.value;
  if (searchTxt)
    result = result?.filter(
      (department) =>
        department?.name && ignoreUnicode(department?.name).includes(searchTxt)
    );

  return result;
});

const onReload = async () => {
  keySearch.value = '';
  isLoadingGroup.value = true;
  await _departmentStore.getAll();
  isLoadingGroup.value = false;
};

// search
const keySearch = ref('');

// statusActive
const statusActive = [
  {
    status: false,
    label: 'COMMON_LABEL_PUBLIC',
    name: 'Public',
    key: 0,
  },
  {
    status: true,
    label: 'COMMON_LABEL_PRIVATE',
    name: 'Private',
    key: 1,
  },
  {
    status: null,
    label: 'COMMON_LABEL_ALL',
    name: 'All',
    key: -1,
  },
];

// FIRLTER STATUS
// const totalFilterItem = ref(false);

const onChangeStatusGroup = (idGroup, status) => {
  emit('changeStatus', {
    id: idGroup,
    status: status,
  });
};

const showDeleteGroup = ref(false);
const groupDeleteId = ref(0);
const groupDeleteName = ref('');
const groupDeleteCode = ref('');
const alertAdminSystemNotHasPermission = ref(false);
const showModalRemove = (id, name, group) => {
  showDeleteGroup.value = true;
  groupDeleteId.value = id;
  groupDeleteName.value = name;
  groupDeleteCode.value = group.code;
};
// show detail
const showDetail = (group) => {
  emit('showDetail', group);
};

const inputSearchDepartment = ref();

window.addEventListener('keydown', (e) => {
  if (
    e.ctrlKey &&
    e.keyCode === 83 &&
    router.currentRoute.value.fullPath.includes('departments-management')
  ) {
    inputSearchDepartment.value?.focus();
    e.preventDefault();
  }
});
</script>

<template>
  <div class="h-full bg-white flex flex-col space-y-2 p-2 overflow-y-hidden">
    <template v-if="allDepartments.length > 0">
      <div class="flex w-full justify-between items-center py-1">
        <!-- search -->
        <div class="flex items-center w-80">
          <VigSearchBox
            ref="inputSearchDepartment"
            v-model="keySearch"
            class="w-full"
            :placeholder="$t('DEPARTMENT_CREATE_FORM_NAME') + '...'"
          />
        </div>

        <div class="flex items-center space-x-1">
          <span
            class="
              text-xs
              rounded
              py-1
              px-2
              border border-current-400
              text-current-500
            "
          >
            {{
              `${$t('COMMON_LABEL_TOTAL')}: ${filteredDepartments?.length || 0}`
            }}
          </span>
          <!-- <span>|</span>
        <span class="rounded text-xs py-1 px-2 bg-orange-400 text-white">
          {{
            `${$t('COMMON_LABEL_PRIVATE')}: ${
              filteredDepartments.filter((el) => el.isPrivate)?.length || 0
            }`
          }}
        </span>
        <span class="rounded text-xs py-1 px-2 bg-current text-white">
          {{
            `${$t('COMMON_LABEL_PUBLIC')}: ${
              filteredDepartments.filter((el) => !el.isPrivate)?.length || 0
            }`
          }}
        </span> -->
        </div>
      </div>

      <!-- table -->
      <div class="flex-1 overflow-y-auto small-scrollbar">
        <SynTableCustom>
          <template #header>
            <SynTr>
              <!-- <SynTh>{{ $t('TASK_TABLE_LABEL_NUM') }}</SynTh> -->
              <SynTh
                :label="$t('COMMON_MODULE_MANAGEMENT_DEPARTMENT')"
                style="max-width: 8rem"
              />
              <SynTh
                :label="$t('COMMON_LABEL_DESCRIPTION')"
                style="max-width: 12rem"
              />
              <SynTh :label="$t('TASK_TABLE_LABEL_MEMBERS')" />
              <SynTh
                :label="$t('GROUP_LABEL_CREATE_DATE')"
                style="width: 12rem"
              />
              <SynTh position-text-header="justify-center" style="width: 5rem">
                <div class="flex-center w-full">
                  <button
                    v-vig-tooltip="$t('NOTIFICATION_RELOAD') || 'Reload'"
                    class="
                      flex-center
                      rounded-full
                      hover:fill-current
                      fill-gray-500
                      w-6
                      h-6
                    "
                    @click="onReload"
                  >
                    <SynIcon name="reload" class="w-4 h-4" />
                  </button>
                </div>
              </SynTh>
            </SynTr>
          </template>

          <template #body>
            <SynTableTrLoading v-if="isLoadingGroup">
              <div v-for="item in 5" :key="item" class="table-cell p-2 h-96">
                <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
              </div>
            </SynTableTrLoading>

            <template
              v-for="group in filteredDepartments"
              v-else-if="filteredDepartments.length > 0"
              :key="group.id"
            >
              <SynTr
                :row-id="'group-' + group?.id"
                class="hover:bg-gray-50"
                style="max-width: 8rem"
              >
                <SynTd
                  class="cursor-pointer text-left"
                  @click="showDetail(group)"
                >
                  <div class="flex items-center space-x-2">
                    <SynAvatar
                      class="cursor-pointer text-current-800"
                      :src="group.avatarUrl?.replace('original', 'mini')"
                      :name="group.name"
                      custom-class="w-8 h-8"
                    />
                    <span
                      v-vig-tooltip="group.name"
                      class="
                        hover:text-current
                        font-semibold
                        overflow-hidden
                        text-overflow-hidden-line
                      "
                    >
                      {{ group.name }}
                    </span>

                    <DepartmentHasNotAdminWarning
                      v-if="group.isHasNotAnyAdmin"
                    />
                  </div>
                </SynTd>
                <SynTd class="cursor-pointer truncate overflow-hidden pr-2">
                  <span v-vig-tooltip="group.description" class="truncate">
                    {{ group.description }}
                  </span>
                </SynTd>

                <SynTd>
                  <div class="flex">
                    <SynListAvatar
                      :users="group?.departmentUsers"
                      type="USER"
                      :max-length="5"
                      custom-class="w-6 h-6"
                    />
                  </div>
                </SynTd>

                <SynTd v-if="false">
                  <SynSelect
                    v-model="group.isPrivate"
                    text-field="label"
                    key-field="status"
                    is-translate
                    key-item-not-show="status"
                    :value-item-not-show="[null]"
                    :is-show-icon="false"
                    :width-class="
                      !group.isPrivate
                        ? ' flex text-xs space-x-4 text-green-500 border-green-500'
                        : ' flex text-xs space-x-4 text-gray-500 border-gray-500'
                    "
                    :placeholder="
                      !group.isPrivate
                        ? $t('COMMON_LABEL_PRIVATE')
                        : $t('COMMON_LABEL_PUBLIC')
                    "
                    :options="statusActive"
                    @update:model-value="
                      onChangeStatusGroup(group.id, group.isPrivate)
                    "
                  />
                </SynTd>
                <!-- <SynTd v-else class="min-w-max">
                <span
                  class="px-2 py-0.5 rounded-full text-xs flex-center w-max"
                  :class="
                    group.isPrivate
                      ? 'bg-orange-400 text-white'
                      : 'bg-current text-white'
                  "
                >
                  {{
                    group.isPrivate
                      ? $t('COMMON_LABEL_PRIVATE')
                      : $t('COMMON_LABEL_PUBLIC')
                  }}
                </span>
              </SynTd> -->
                <SynTd class="max-w-max">
                  <SynLabelDateTime
                    format="date_time"
                    :datetime="group?.creationTime"
                  />
                </SynTd>
                <SynTd>
                  <ThreeDotActions
                    type="action"
                    size="small"
                    class="flex-center"
                  >
                    <div
                      class="list-li space-x-2 flex justify-start items-center"
                      @click="showDetail(group)"
                    >
                      <SynIcon
                        is-active
                        custom-class="fill-gray-500 w-4 h-4"
                        name="info"
                      />
                      <span class="text-gray-600">
                        {{ $t('COMMON_LABEL_VIEW_DETAIL') }}
                      </span>
                    </div>
                    <div
                      class="
                        list-li
                        border-t
                        space-x-2
                        flex
                        justify-start
                        items-center
                        text-red-600
                        fill-red-500
                      "
                      @click="showModalRemove(group.id, group.name, group)"
                    >
                      <SynIcon
                        name="trash"
                        is-active
                        custom-class="fill-red-500 w-4 h-4"
                      />
                      <span>
                        {{ $t('COMMON_LABEL_DELETE') }}
                      </span>
                    </div>
                  </ThreeDotActions>
                </SynTd>
              </SynTr>
            </template>
            <template v-else>
              <SynTr class="h-full border-b-none" hover="hover:bg-current-50">
                <SynTd :colspan="10" style="height: calc(100vh - 16rem)">
                  <syn-animation
                    v-if="allDepartments.length > 0"
                    name="searchIcon"
                    stype="width: 200px; height: 200px"
                    :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                  />
                  <syn-animation
                    v-else
                    name="searchNotFound"
                    stype="width: 200px; height: 200px"
                    :label="$t('GROUP_TABLE_ALERT_NO_LIST_GROUP')"
                  />
                </SynTd>
              </SynTr>
            </template>
          </template>
        </SynTableCustom></div
    ></template>

    <template v-else>
      <div class="flex-center flex-col pt-8">
        <div class="w-64">
          <VigImage
            :path="`${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/images/department.jpg`"
          />
        </div>
        <div class="font-semibold pt-4">
          {{ $t('DEPARTMENT_LABEL_NO_DATA') }}
        </div>
        <div class="text-gray-500">
          {{ $t('DEPARTMENT_LABEL_NO_DATA_SUB') }}
        </div>
        <div class="pt-4">
          <SynButton
            :label="$t('DEPARTMENT_LABEL_CREATE_NEW') || 'Create'"
            name-icon="department"
            @click="$emit('onCreateNew')"
          />
        </div>
      </div>
    </template>
  </div>

  <!-- delete group -->
  <DepartmentRemoveModal
    v-if="showDeleteGroup"
    :department-id="groupDeleteId"
    :department-name="groupDeleteName"
    @cancel="showDeleteGroup = false"
    @deleted-success="
      [
        $emit('confirmDeleted', { id: groupDeleteId }),
        (showDeleteGroup = false),
      ]
    "
  />
  <alert-modal
    v-if="alertAdminSystemNotHasPermission"
    :title-text="$t('COMON_NOT_ALLOW_PERFORM_ACTION')"
    :sub-title-text="$t('GROUP_DELETE_ALLOW_ADMIN_GROUP_ONLY')"
    container-class="w-1/3"
    :container-color="'current'"
    @cancel="alertAdminSystemNotHasPermission = false"
  />
</template>
