import { IDesktopDrawerShape, ShapeType } from '../schema';

export default class ShapeEntity implements IDesktopDrawerShape {
    id: string;
    groupId?: string;
    type: ShapeType;
    translateX: number;
    translateY: number;
    zIndex: number;
    width: number;
    height: number;
    sourceId?: number;
    sourceData?: any;

    constructor(data) {
        this.id = data?.id || '';
        this.groupId = data?.groupId || '';
        this.type = data?.type || 'TEXT_NOTE';
        this.translateX = data?.translateX || 0;
        this.translateY = data?.translateY || 0;
        this.zIndex = data?.zIndex || 2000;
        this.width = data?.width || 0;
        this.height = data?.height || 0;
        this.sourceId = data?.sourceId || 0;
        this.sourceData =
            typeof data?.sourceData == 'string'
                ? JSON.parse(data?.sourceData)
                : data?.sourceData;
    }

    changePosition(newTranslateX, newTranslateY) {
        this.translateX = newTranslateX;
        this.translateY = newTranslateY;
    }
    resize(newWidth, newHeight) {
        this.width = newWidth;
        this.height = newHeight;
    }

    setGroup(_groupId) {
        this.groupId = _groupId;
    }

    actionUnGroup() {
        this.groupId = undefined;
    }
    updateSize(newWidth, newHeight) {
        this.width = newWidth;
        this.height = newHeight;
    }

    updateSourceData(newData) {
        this.sourceData = newData;
    }
}
