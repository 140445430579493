<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 179.87 105.24"
  >
    <g>
      <circle class="cls-2" cx="25.25" cy="79.01" r="25" />
      <path
        id="image-plus"
        class="cls-5"
        d="M37.36,78.38v7.45c.27,2.31-1.39,4.39-3.69,4.66-.32,.04-.65,.04-.97,0h-14.91c-2.31,.27-4.39-1.39-4.66-3.69-.04-.32-.04-.65,0-.97v-12.42c-.27-2.31,1.39-4.39,3.69-4.66,.32-.04,.65-.04,.97,0h8.69c.51,0,.93,.42,.93,.93s-.42,.93-.93,.93h-8.69c-1.96,0-2.79,.84-2.79,2.79v11.49l3.15-3.15c.49-.48,1.28-.48,1.76,0l1.17,1.17c.24,.24,.63,.24,.87,0l6.14-6.14c.49-.48,1.28-.48,1.76,0l5.64,5.64v-4.04c0-.51,.42-.93,.93-.93s.93,.42,.93,.93h0Zm-17.09-4.04c-.86,0-1.55,.7-1.55,1.56,0,.86,.7,1.55,1.56,1.55s1.55-.7,1.55-1.56c0-.86-.7-1.55-1.55-1.55h0Zm11.19-2.48h1.55v1.55c0,.51,.42,.93,.93,.93s.93-.42,.93-.93v-1.55h1.55c.51,0,.93-.42,.93-.93s-.42-.93-.93-.93h-1.55v-1.55c0-.51-.42-.93-.93-.93s-.93,.42-.93,.93v1.55h-1.55c-.51,0-.93,.42-.93,.93s.42,.93,.93,.93Z"
      />
    </g>
    <g>
      <circle class="cls-2" cx="56.52" cy="26" r="25" />
      <path
        id="Icon_awesome-microphone"
        data-name="Icon awesome-microphone"
        class="cls-5"
        d="M56.52,29.81c2.1,0,3.81-1.71,3.81-3.81v-6.35c0-2.1-1.71-3.81-3.81-3.81s-3.81,1.71-3.81,3.81v6.35c0,2.1,1.71,3.81,3.81,3.81Zm6.35-6.35h-.63c-.35,0-.63,.28-.63,.63v1.9c0,2.81-2.28,5.08-5.09,5.08-.17,0-.33,0-.5-.02-2.65-.34-4.62-2.61-4.57-5.28v-1.68c0-.35-.28-.63-.63-.63h-.63c-.35,0-.63,.28-.63,.63v1.59c-.05,3.56,2.51,6.63,6.03,7.21v1.35h-2.22c-.35,0-.63,.28-.63,.63,0,0,0,0,0,0v.63c0,.35,.28,.63,.63,.63h6.35c.35,0,.63-.28,.63-.63v-.63c0-.35-.28-.63-.63-.63h-2.22v-1.34c3.45-.48,6.02-3.43,6.03-6.91v-1.9c0-.35-.28-.63-.63-.63Z"
      />
    </g>
    <g id="image-gallery-plus">
      <path
        class="cls-1"
        d="M112.35,75.38l-2.67,19.1c-.58,4.01-2.78,6.12-6.41,6.12-.29,0-.58-.02-.89-.04,.06-.43,.09-.87,.09-1.31v-19.3c.4-2.87-1.6-5.52-4.47-5.92-.49-.07-.99-.07-1.48,0h-22.19s.02-.11,.02-.16l.53-3.74c.14-1.92,1.05-3.71,2.52-4.96,1.61-1.06,3.58-1.44,5.46-1.05l23.51,3.29c1.92,.15,3.7,1.07,4.94,2.54,1.07,1.59,1.45,3.56,1.04,5.43h0Z"
      />
      <g class="cls-3">
        <path
          class="cls-4"
          d="M66.84,99.25v-19.3c-.41-2.87,1.59-5.53,4.46-5.93,.49-.07,.99-.07,1.48,0h23.75c2.87-.41,5.53,1.59,5.93,4.46,.07,.49,.07,.98,0,1.47v19.3c.41,2.87-1.59,5.53-4.46,5.94-.49,.07-.99,.07-1.48,0h-23.75c-2.87,.41-5.53-1.58-5.94-4.45-.07-.49-.07-.99,0-1.49Z"
        />
      </g>
      <path
        class="cls-1"
        d="M90.21,87.93h-3.9v-3.9c0-.92-.75-1.67-1.67-1.67s-1.67,.75-1.67,1.67v3.9h-3.9c-.92,0-1.67,.75-1.67,1.67s.75,1.67,1.67,1.67h3.9v3.9c0,.92,.75,1.67,1.67,1.67s1.67-.75,1.67-1.67v-3.9h3.9c.92,0,1.67-.75,1.67-1.67s-.75-1.67-1.67-1.67Z"
      />
    </g>
    <g>
      <circle class="cls-2" cx="125" cy="25.25" r="25" />
      <g>
        <path
          class="cls-5"
          d="M127.66,15.95h-11.86c-1.09,0-1.96,.88-1.96,1.96v7.85c0,1.09,.88,1.96,1.96,1.96h.65v1.96c0,.47,.49,.81,.97,.58,.01-.01,.04-.01,.05-.03,3.6-2.41,2.79-1.88,3.6-2.41,.1-.07,.22-.1,.35-.1h6.24c1.09,0,1.96-.88,1.96-1.96v-7.85c0-1.09-.88-1.96-1.96-1.96Zm-5.23,7.85h-5.32c-.86,0-.87-1.31,0-1.31h5.32c.86,0,.87,1.31,0,1.31Zm3.93-2.62h-9.25c-.86,0-.87-1.31,0-1.31h9.25c.86,0,.87,1.31,0,1.31Z"
        />
        <path
          class="cls-5"
          d="M134.2,21.19h-3.27v4.58c0,1.81-1.47,3.27-3.27,3.27h-6.04l-1.24,.82v1.14c0,1.09,.88,1.96,1.96,1.96h6.43l3.76,2.51c.51,.28,1.02-.06,1.02-.55v-1.96h.65c1.09,0,1.96-.88,1.96-1.96v-7.85c0-1.09-.88-1.96-1.96-1.96Z"
        />
      </g>
    </g>
    <g>
      <circle class="cls-2" cx="154.62" cy="79.62" r="25" />
      <path
        class="cls-5"
        d="M162.08,72.69h-14.92c-.82,0-1.49,.67-1.49,1.49v14.92c0,.82,.67,1.49,1.49,1.49h10.13c.4,0,.78-.16,1.05-.44l4.78-4.78c.28-.28,.44-.66,.44-1.05v-10.13c0-.82-.67-1.49-1.49-1.49Zm-4.48,16.1v-4.17h4.17l-4.17,4.17Z"
      />
    </g>
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: #00aba0;
}

.cls-2 {
  fill: none;
  stroke: #d1d3d4;
  stroke-dasharray: 0 0 3.02 3.02;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}

.cls-3 {
  opacity: 0.4;
}

.cls-4 {
  fill: #99d6d4;
}

.cls-5 {
  fill: #87d0cc;
}
</style>
