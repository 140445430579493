<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        fill-rule="evenodd"
        d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM6 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm9-9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM12 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1.5 13.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm6-6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1.5-9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM19.5 21a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
        clip-rule="evenodd"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>
