<script setup lang="ts">
import { watch, onUnmounted, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import taskTabViewStore from '@/store/task/task-tab-view';
import taskListStore from '@/store/task/task-list-store';
import { encryptUserId } from '@/ui/plugins/crypto/crypto';

const _route = useRoute();
const _router = useRouter();
const _taskTabViewStore = taskTabViewStore();

watch(
  () => _route.name,
  () => {
    resetTabView();
  }
);

watch(
  () => _route.query,
  () => {
    _checkToRedirectUserTasksPage();
  }
);

onBeforeMount(() => {
  _checkToRedirectUserTasksPage();
});

onUnmounted(() => {
  resetTabView();
});

const resetTabView = () => {
  _taskTabViewStore.$reset();
  taskListStore().$reset();

  taskListStore().resetTabView();
};

const _checkToRedirectUserTasksPage = () => {
  if (_route.fullPath?.startsWith('/tasks?userId=') && _route.query?.userId) {
    const path = `/tasks/users/${encryptUserId(_route.query?.userId)}`;
    _router.push({ path });
  }
};
</script>

<template>
  <router-view></router-view>
</template>
