import { loginAgain } from './../../ui/hooks/commonHook';
import axios, { AxiosResponse } from 'axios';

import { openNotification, AlertType } from '@/ui/hooks/commonHook';

import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import { ResponseStatusCode } from '@/ui/common/constants/constant';
import { translate } from '@/ui/plugins/i18n/myi18n';
import router from '@/ui/router';
import Qs from 'qs';
import { handleRefreshToken } from './auto-refresh-token';

const debug = import.meta.env.MODE !== 'production';

const customeErrorCodes = [
    ResponseStatusCode.LoginFailed,
    ResponseStatusCode.LoginBlocked,
    ResponseStatusCode.SendPhoneCodeBlocked,
    ResponseStatusCode.CaptchaCheckFailed,
    ResponseStatusCode.InvalidSessionInfo,
    ResponseStatusCode.SendVerificationFailed,
    ResponseStatusCode.CodeInvalid,
    ResponseStatusCode.InviteTokenInvalid,
    ResponseStatusCode.InviteTokenExpired,
    ResponseStatusCode.InviteTokenCanceled,
    ResponseStatusCode.InviteUserAttached,
    ResponseStatusCode.InviteJoinedByOtherMethods,
];
// create an axios instance
const service = axios.create({
    baseURL: `${import.meta.env.VITE_TICTOP_SERVER}/api/web/`,
    // // withCredentials: true, // send cookies when cross-domain requests
    timeout: debug ? 100000 : 20000, // request timeout
    paramsSerializer: (params) =>
        Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
});

// request interceptor
service.interceptors.request.use(
    async (config) => {
        const token = getLocalStorage(StorageConstant.TOKEN);
        const requestTime = Date.now();
        // do something before request is sent
        const location = getLocalStorage(StorageConstant.LOCATION);
        const language = getLocalStorage(StorageConstant.LANGUAGE);

        config.headers = {
            ...config.headers,
            'x-request-time': requestTime,
            'x-location': location,
            'x-lang': language,
        };

        if (token && typeof token == 'string')
            config.headers = {
                ...config.headers,
                Authorization: 'Bearer ' + token,
            };

        return config;
    },
    (error) => {
        // do something with request error
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    (response: AxiosResponse<any>) => {
        const { success, error } = response.data;
        if (
            success ||
            response.headers['content-type'] == 'application/octet-stream'
        ) {
            const actionKey = response.config.headers?.Action;
            if (actionKey) {
                openNotification({
                    title: translate('COMMON_LABEL_SUCCESS'),
                    body: translate(actionKey) || '',
                });
            }
            return response.data;
        }

        const errorCode = error?.code;
        switch (errorCode) {
            case 403:
                {
                    router.push({
                        name: 'Notfound',
                    });
                }
                break;
            case ResponseStatusCode.OrganizationInactive:
                {
                    router.push({
                        name: 'OrganizationInactive',
                    });
                }
                break;
            case ResponseStatusCode.WaitingActivationOrganization:
                {
                    router.push({
                        name: 'WaitingActivation',
                    });
                }
                break;
            case ResponseStatusCode.SuspiciousRequest:
                {
                    router.push({
                        name: 'SuspiciousRequest',
                    });
                }
                break;
            case ResponseStatusCode.LoginFailed:
                {
                    if (error?.message === 'USER_DEACTIVATED')
                        router.push({
                            name: 'AccountDeactivated',
                            query: {
                                id: error.details,
                            },
                        });
                }
                break;

            default: {
                if (!customeErrorCodes.includes(errorCode ?? 0) && debug) {
                    openNotification({
                        type: AlertType.error,
                        title: translate('COMMON_LABEL_ERROR'),
                        body: error?.message,
                    });
                }
                // new ErrorResponseClass(errorCode)
                return Promise.reject(response.data);
            }
        }
        return Promise.reject(response.data);
    },
    async (error) => {
        if (error && error.response) {
            const { status, headers } = error.response;
            console.log('🚀 ~ api error: ', {
                error: error,
                response: error?.response,
            });
            // 401: Token expired;
            switch (status) {
                case 401:
                    {
                        if (checkCallRefreshToken(headers)) {
                            await handleRefreshToken(() => {});
                            error.config.headers['Authorization'] =
                                'Bearer ' +
                                getLocalStorage(StorageConstant.TOKEN);
                            const res = await axios.request(error.config);

                            return res.data;
                        } else {
                            openNotification({
                                type: AlertType.error,
                                title: translate('COMMON_LABEL_ERROR'),
                                body: translate('COMMON_ERROR_401'),
                                duration: 3000,
                            });
                        }
                        // Show modal: You dont have permission for this action
                        // settingGlobalModal(
                        //   'notification',
                        //   '',
                        //   translate('COMMON_ERROR_401'),
                        //   '',
                        //   ''
                        // );
                        // ask();
                    }
                    break;
                // case 444: {
                //   await handleRefreshToken(store);
                //   error.config.headers['Authorization'] =
                //     'Bearer ' + getLocalStorage(StorageConstant.TOKEN);
                //   const res = await axios.request(error.config);

                //   return res.data;
                // }
                case 440:
                    {
                        loginAgain();
                    }
                    break;

                case 404:
                    {
                        if (import.meta.env.VITE_BUILD_MODE !== 'PRODUCT')
                            router.push({ name: 'Notfound' });
                    }
                    break;

                default:
                    break;
            }
        }
        return Promise.reject(error);
    }
);

const checkCallRefreshToken = (headers) => {
    const authHeader = headers['www-authenticate'];
    console.log('WWW-Authenticate: ', authHeader);
    //  ex: WWW-Authenticate: Bearer error="invalid_token", error_description="The token expired at '04/14/2022 09:07:30'"
    return (
        authHeader.includes('Bearer') &&
        authHeader.includes('error') &&
        authHeader.includes('invalid_token')
    );
};

export default service;
