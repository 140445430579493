import { createApp, h } from 'vue';
import DayoffEventItem from '@/ui/components/calendars/event-item/DayoffEventItem.vue';
import { DayoffDetailItemClass } from '@/domain/entities/dayoff/DayoffCalendarItemClass';
import VigImage from '@/ui/common/atoms/VigImage/VigImage.vue';
import { DayoffExceptionType, DayoffType } from '@/domain/enums/DayoffEnums';
// import Schedule from '@/ui/components/icons/Schedule.vue';
import EventDayOff from '@/ui/components/icons/EventDayOff.vue';
import BreakDayOff from '@/ui/components/icons/BreakDayOff.vue';
import WfhDayOff from '@/ui/components/icons/WfhDayOff.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
// import WorkFromHome from '@/ui/components/icons/WorkFromHome.vue';
// import BreakTime from '@/ui/components/icons/BreakTime.vue';

export function renderDayoffItemEvent(
    dayoffDetailItem: DayoffDetailItemClass,
    options?: any
) {
    const iconComponent = getIconTypeComponent(dayoffDetailItem.type);

    const appItem = createApp({
        render() {
            return h(
                DayoffEventItem,
                {
                    data: dayoffDetailItem,
                    iconComponent: iconComponent,
                    avatarComponent: VigImage,
                    options,
                },
                () => [h(iconComponent, {}), h(VigImage, {})]
            );
        },
    });
    const tmpEl = document.createElement('div');
    const mountedApp = appItem.mount(tmpEl);
    const el = document.createElement('div');
    el.classList.add('w-full', 'h-full');
    el.innerHTML = mountedApp.$el.outerHTML;
    return el;
}

export function getIconTypeComponent(type: DayoffType) {
    let component;
    switch (type) {
        case DayoffType.SCHEDULE:
            component = EventDayOff;
            break;
        case DayoffType.WFH:
            component = WfhDayOff;
            break;
        case DayoffType.EXCEPTION:
            component = BreakDayOff;
            break;
        default:
            break;
    }
    return component;
}

export function getDayOffTitle(
    type: DayoffType,
    exceptionType: DayoffExceptionType
) {
    switch (type) {
        case DayoffType.EXCEPTION:
            if (!exceptionType) {
                return translate('DAYOFF_REQUEST_TITLE');
            }
            if (exceptionType === DayoffExceptionType.BREAK_TIME) {
                return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME');
            } else if (exceptionType === DayoffExceptionType.START_LATE) {
                return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME_START_LATE');
            } else {
                return translate('DAYOFF_REQUEST_TITLE_BREAK_TIME_STOP_EARLY');
            }
        case DayoffType.WFH:
            return translate('DAYOFF_REQUEST_TITLE_WORK_FROM_HOME');
        default:
            return translate('DAYOFF_REQUEST_TITLE') || 'Dayoff request';
    }
}
