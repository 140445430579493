import { ComputedRef, computed } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import settingStore from '@/store/setting';
import userByIdComposable from '@/ui/components/user/user-by-id.composable';
import departmentStore from '@/store/department';
import groupStore from '@/store/group';
import domainStore from '@/store/scope';

export default function taskMultipleAssigneeComposable(
    formData: ComputedRef<{
        creatorId: number;
        assigneeId: number;
        groupId?: number;
        domainId: number;
        projectId?: number;
        subprojectId?: number;
    }>
) {
    // *** PRIVATE VARIABLES ***

    // *** COMPOSABLES ***
    const _settingStore = settingStore();

    // *** REFS ***

    // *** COMPUTED ***
    const departmentManagementEnabled = computed(
        () => _settingStore._organizationSetting.departmentManagement.enabled
    );

    // *** WATCHES ***

    // *** HOOKS ***
    const { currentUserDepartmentId } = userByIdComposable(
        computed(() => {
            return {
                userId: formData.value?.assigneeId,
                user: undefined,
            };
        })
    );
    // *** HANDLER FUNCTIONS ***

    // *** PRIVATE FUNCTIONS ***

    // *** EXPOSES ***

    const multipleAssignType = computed<
        'ORGANIZATION' | 'GROUP' | 'DEPARTMENT'
    >(() => {
        if (formData.value?.groupId) return 'GROUP';

        if (departmentManagementEnabled.value) return 'DEPARTMENT';

        return 'ORGANIZATION';
    });

    const scopeTitle = computed<string>(() => {
        return translate(
            multipleAssignType.value == 'DEPARTMENT'
                ? 'TASK_CREATE_FORM_MULTIPLE_ASSIGNEE_SAME_DEPARTMENT'
                : multipleAssignType.value == 'GROUP'
                ? 'TASK_CREATE_FORM_MULTIPLE_ASSIGNEE_SAME_GROUP'
                : 'TASK_LABEL_ASSIGN_USER_LIST',
            {
                groupName: '',
            }
        );
    });

    const synchronizeAssigneeWithDomain = (
        assigneeList,
        domainId,
        projectId,
        subprojectId
    ) => {
        if (!assigneeList || assigneeList?.length == 0) return [];
        if (!domainId) return assigneeList;
        return assigneeList?.map((assignee) => {
            const hasDomain =
                domainId &&
                Object.prototype.hasOwnProperty.call(
                    domainStore()?.domainByIds,
                    domainId
                )
                    ? domainStore()?.domainByIds[domainId]?.members?.some(
                          (user) => user?.id == assignee?.assigneeId
                      )
                    : false;

            const hasProject =
                domainId &&
                projectId &&
                Object.prototype.hasOwnProperty.call(
                    domainStore()?.projectByIds,
                    projectId
                )
                    ? domainStore()?.projectByIds[projectId]?.members?.some(
                          (userId) => userId == assignee?.assigneeId
                      )
                    : false;

            const hasSubProject =
                domainId &&
                projectId &&
                subprojectId &&
                Object.prototype.hasOwnProperty.call(
                    domainStore()?.projectByIds,
                    subprojectId
                )
                    ? domainStore()?.projectByIds[subprojectId]?.members?.some(
                          (userId) => userId == assignee?.assigneeId
                      )
                    : false;
            return {
                ...assignee,
                domainId: hasDomain ? domainId : assignee?.domainId,
                projectId: hasProject ? projectId : null,
                subprojectId: hasSubProject ? subprojectId : null,
            };
        });
    };
    const synchronizeAssigneeWithTaskOwner = (assigneeList, groupId) => {
        if (groupId) {
            const allGroupByIds = {
                ...groupStore().allGroupByIds,
                ...groupStore().myGroupByIds,
            };
            return assigneeList?.map((assignee) => {
                const isMember =
                    groupId &&
                    Object.prototype.hasOwnProperty.call(allGroupByIds, groupId)
                        ? allGroupByIds[groupId]?.groupMembers?.some(
                              (user) => user?.userId == assignee?.assigneeId
                          )
                        : false;
                return {
                    ...assignee,
                    isSelecting: !!isMember,
                };
            });
        }

        const allDepartmentByIds = departmentStore().allDepartmentByIds;

        return assigneeList?.map((assignee) => {
            const isMember =
                currentUserDepartmentId.value &&
                Object.prototype.hasOwnProperty.call(
                    allDepartmentByIds,
                    currentUserDepartmentId.value
                )
                    ? allDepartmentByIds[
                          currentUserDepartmentId.value
                      ]?.departmentUsers?.some(
                          (user) => user?.userId == assignee?.assigneeId
                      )
                    : false;
            return {
                ...assignee,
                isSelecting: !!isMember,
            };
        });
    };

    return {
        multipleAssignType,
        scopeTitle,
        currentUserDepartmentId,
        synchronizeAssigneeWithDomain,
        synchronizeAssigneeWithTaskOwner,
    };
}
