<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M234.667,263.019V32c0-5.888-4.779-10.667-10.667-10.667c-123.52,0-224,110.059-224,245.333S110.059,512,245.333,512
				c60.523,0,93.269-10.965,134.784-45.099c4.459-3.669,5.184-10.219,1.643-14.784L234.667,263.019z M245.333,490.667
				c-123.52,0-224-100.48-224-224c0-119.552,85.184-217.536,192-223.701v223.701c0,2.368,0.789,4.672,2.261,6.549l142.848,183.659
				C324.757,482.603,296.661,490.667,245.333,490.667z"
          />
          <path
            d="M266.667,256h234.667c5.888,0,10.667-4.779,10.667-10.667C512,110.059,401.941,0,266.667,0
				C260.779,0,256,4.779,256,10.667v234.667C256,251.221,260.779,256,266.667,256z M277.333,21.589
				c115.051,5.419,207.659,98.027,213.077,213.077H277.333V21.589z"
          />
          <path
            d="M501.333,277.333H288c-4.096,0-7.872,2.368-9.643,6.08c-1.771,3.712-1.237,8.107,1.344,11.285l138.667,171.669
				c1.856,2.325,4.587,3.733,7.552,3.947c0.256,0.021,0.491,0.021,0.747,0.021c2.688,0,5.291-1.024,7.275-2.859
				C483.541,421.205,512,355.797,512,288C512,282.112,507.221,277.333,501.333,277.333z M427.584,443.819L310.336,298.667h180.075
				C487.787,352.896,465.344,404.757,427.584,443.819z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
