<script setup lang="ts">
import { computed, ref } from 'vue';
import groupStore from '@/store/group';
import myProfileStore from '@/store/auth/my-profile';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import {
  genDirectConversationId,
  genGroupConversationId,
} from '@/ui/plugins/firebases/firestore-database/constants';
import { translate } from '@/ui/plugins/i18n/myi18n';
import userStore from '@/store/user';
import chatStore from '@/store/chat';
import { openNotification } from '@/ui/hooks/commonHook';
import { AdminType } from '@/ui/common/constants/constant';
import favoriteStore from '@/store/favorite/favoriteStore';
import { FavoriteType } from '@/ui/common/constants/constant';
import { chain } from 'lodash';
import { arrToObj } from '@/ui/helpers/utils';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const props = defineProps({
  message: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(['onClose']);

const _groupStore = groupStore();
const _userStore = userStore();
const _chatStore = chatStore();
const _favoriteStore = favoriteStore();

const organizationId = getLocalStorage(StorageConstant.ACTIVE_ORG);
const myProfile = computed(() => myProfileStore().myProfile);
const allUsers = computed(() => _userStore.listShortInfosUser);
const allGroupByIds = _groupStore.allGroupByIds;

const isSending = ref(false);
const searchText = ref(null);
const selectedContacts = ref({});

const filteredGroups = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  const groups: any[] = chain(Object.values(allGroupByIds || {}))
    .filter((g: any) => g?.isMember)
    .sortBy('name')
    .value();

  if (!searchTxt) return groups;

  return (groups || []).filter(
    (group) => group?.name && ignoreUnicode(group?.name).includes(searchTxt)
  );
});

const filteredUsers = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return allUsers.value;

  return (allUsers.value || []).filter(
    (user) => user?.name && ignoreUnicode(user?.name).includes(searchTxt)
  );
});

const myFilteredFavorites = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  if (!searchTxt) return _favoriteStore.getMyFavorites;

  return _favoriteStore.getMyFavorites?.filter(
    (favorite) =>
      favorite?.type !== 'domain' &&
      favorite?.name &&
      ignoreUnicode(favorite?.name).includes(searchTxt)
  );
}) as any;

const selectedContactsArr = computed(() => {
  return Object.entries(selectedContacts.value || {})
    .filter(([key, val]) => key && val)
    .reduce((arr: any, [key]) => {
      if (key?.startsWith('G-')) {
        const selectedGroup = allGroupByIds[key.replace('G-', '')];
        if (
          selectedGroup &&
          !restrictedGroupChatById.value[selectedGroup?.id]
        ) {
          arr.push({
            contactId: key,
            id: selectedGroup.id,
            name: selectedGroup.name,
            avatar: selectedGroup.avatar,
            isGroup: true,
          });
        }
      }
      if (key?.startsWith('U-')) {
        const selectedUser = (allUsers.value || []).find(
          (user) => `U-${user?.userId}` === key
        );
        selectedUser &&
          arr.push({
            contactId: key,
            id: selectedUser.userId,
            name: selectedUser.name,
            avatar: selectedUser.avatar,
          });
      }

      return arr;
    }, []);
});

const conferenceMenuList = {
  HISTORY: {
    key: 'forward-histories',
    label: 'History',
    labelKey: 'COMMON_LABEL_RECENTLY',
  },
  FAVORITES: {
    key: 'forward-favorites',
    label: 'Favorites',
    labelKey: 'SIDEBAR_LABEL_MY_FAVORITES',
  },
  MEMBERS: {
    key: 'forward-members',
    label: 'Members',
    labelKey: 'TASK_TABLE_LABEL_MEMBERS',
  },
  GROUPS: {
    key: 'forward-groups',
    label: 'Groups',
    labelKey: 'COMMON_LABEL_GROUPS',
  },
};

const menuList = computed(() => {
  let data = [] as any;
  if (myFilteredFavorites.value?.length) {
    data = [...data, conferenceMenuList?.FAVORITES];
  }
  if (filteredUsers.value?.length) {
    data = [...data, conferenceMenuList?.MEMBERS];
  }
  if (filteredGroups.value?.length) {
    data = [...data, conferenceMenuList?.GROUPS];
  }
  return data;
});

const currentTab = ref(menuList.value?.length ? menuList.value[0]?.key : null);

const restrictedGroupChatById = computed(() =>
  arrToObj(
    _chatStore.usersChatInfo?.filter(
      (chat) => chat?.isGroup && !chat?.isGroupChatAllowed
    ),
    'id',
    () => true
  )
);

const onSendClick = async () => {
  const userId = myProfile?.value?.id;

  if (
    !props.message ||
    !organizationId ||
    !userId ||
    !selectedContactsArr.value?.length
  ) {
    return;
  }

  isSending.value = true;

  await Promise.all(
    selectedContactsArr.value.map((contact) => {
      const conversationId = contact?.isGroup
        ? genGroupConversationId(contact?.id)
        : genDirectConversationId(userId, contact?.id);

      // If no conversation, create new one
      _chatStore.checkConversationCreated(organizationId, conversationId);

      return _chatStore.forwardMessage(
        organizationId,
        conversationId,
        userId,
        props.message
      );
    })
  );

  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('CHAT_FORWARD_MSG_SUCCESS') || 'Message has been sent',
  });

  isSending.value = false;

  emit('onClose');
};
</script>

<template>
  <syn-modal
    z-index="z-50"
    container-class="w-11/12 md:w-1/2 xl:w-1/3 min-h-[50vh]"
    style-body="p-0 flex flex-col"
    :disable-click-outside="true"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>{{ $t('CHAT_FORWARD_MSG') || 'Forward message' }}</div>
    </template>

    <template #body>
      <SynTabScroll v-model="currentTab" :menu-list="menuList">
        <template #prefix-header>
          <div class="p-3">
            <VigSearchBox v-model="searchText" autofocus />
          </div>
        </template>
        <template #body>
          <!--FAVORITES-->
          <div
            v-if="myFilteredFavorites?.length"
            :id="`syn-tab-scroll-item-${conferenceMenuList?.FAVORITES?.key}`"
          >
            <div
              class="
                px-4
                pt-6
                pb-1
                uppercase
                text-xs
                font-semibold
                tracking-widest
                text-gray-500
              "
            >
              {{ $t(conferenceMenuList?.FAVORITES?.labelKey) || 'Favorites' }}
            </div>
            <label
              v-for="(contact, index) in myFilteredFavorites"
              :key="index"
              class="
                px-4
                py-2
                flex
                items-center
                justify-start
                space-x-4
                cursor-pointer
                hover:bg-gray-100
                hover-to-show__parent
              "
              :for="
                'msg-forward-favourite-' +
                contact?.favoriteType +
                '-' +
                contact?.id
              "
            >
              <input
                :id="
                  'msg-forward-favourite-' +
                  contact?.favoriteType +
                  '-' +
                  contact?.id
                "
                v-model="
                  selectedContacts[
                    contact?.favoriteType === FavoriteType.Group
                      ? `G-${contact?.id}`
                      : `U-${contact?.id}`
                  ]
                "
                type="checkbox"
                class="vig-checkbox"
              />
              <SynAvatarStatusActive
                :id="contact?.id"
                :avatar="contact?.avatar"
                custom-class="h-11 w-11"
                :name="contact?.name"
                :is-user="contact?.favoriteType === 'member'"
                :is-visitor="contact?.adminType === AdminType.Visitor"
              />
            </label>
          </div>

          <!--USERS-->
          <div
            v-if="filteredUsers?.length"
            :id="`syn-tab-scroll-item-${conferenceMenuList?.MEMBERS?.key}`"
          >
            <div
              class="
                px-4
                pt-6
                pb-1
                uppercase
                text-xs
                font-semibold
                tracking-widest
                text-gray-500
              "
            >
              {{ $t(conferenceMenuList?.MEMBERS?.key) || 'Members' }}
            </div>
            <label
              v-for="(user, index) in filteredUsers"
              :key="index"
              class="
                px-4
                py-2
                flex
                items-center
                justify-start
                space-x-4
                cursor-pointer
                hover:bg-gray-100
                relative
                dropdown-item
              "
              :for="'msg-forward-user-' + user?.userId"
            >
              <input
                :id="'msg-forward-user-' + user?.userId"
                v-model="selectedContacts[`U-${user?.userId}`]"
                type="checkbox"
                class="vig-checkbox"
              />
              <SynAvatarStatusActive
                :id="user?.id || user?.userId"
                :avatar="user?.avatar"
                custom-class="h-11 w-11"
                :name="user?.name"
                :is-visitor="user?.adminType === AdminType.Visitor"
                is-user
              />
            </label>
          </div>

          <!--GROUPS-->
          <div
            v-if="filteredGroups?.length"
            :id="`syn-tab-scroll-item-${conferenceMenuList?.GROUPS?.key}`"
          >
            <div
              class="
                px-4
                pt-6
                pb-1
                uppercase
                text-xs
                font-semibold
                tracking-widest
                text-gray-500
              "
            >
              {{ $t(conferenceMenuList?.GROUPS?.key) || 'Groups' }}
            </div>
            <label
              v-for="(group, index) in filteredGroups"
              :key="index"
              class="
                px-4
                py-2
                flex
                items-center
                justify-start
                space-x-4
                cursor-pointer
                hover:bg-gray-100
                relative
                dropdown-item
              "
              :for="'msg-forward-group-' + group?.id"
              :class="{ 'opacity-60': restrictedGroupChatById[group?.id] }"
            >
              <input
                :id="'msg-forward-group-' + group?.id"
                v-model="selectedContacts[`G-${group?.id}`]"
                type="checkbox"
                class="vig-checkbox"
                :disabled="restrictedGroupChatById[group?.id]"
              />
              <div class="flex-center space-x-2">
                <SynAvatar
                  :src="group?.avatar?.replace('original', 'mini')"
                  :name="group?.name"
                  :short-name="group?.shortName"
                  custom-class="h-11 w-11"
                />
                <div>
                  <div>{{ group?.name }}</div>
                  <div
                    v-if="restrictedGroupChatById[group?.id]"
                    class="text-xs"
                  >
                    <SynIcon name="NotAllow" custom-class="w-3 h-3" />
                    {{ $t('CHAT_GROUP_NOT_ALLOWED_MSG') }}
                  </div>
                </div>
              </div>
            </label>
          </div>

          <!--NO DATA-->
          <div
            v-if="
              !myFilteredFavorites?.length &&
              !filteredUsers?.length &&
              !filteredGroups?.length
            "
            class="py-16"
          >
            <syn-animation
              name="searchIcon"
              :loop="false"
              stype="width: 100px; height: 100px"
              :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
            />
          </div>
        </template>
      </SynTabScroll>
    </template>

    <template #footer>
      <div class="flex items-center px-3 py-2">
        <div
          class="flex-1 max-h-32 overflow-auto small-scrollbar flex flex-wrap"
        >
          <div
            v-for="contact of selectedContactsArr"
            :key="contact"
            class="
              rounded-full
              shadow
              bg-current
              text-white text-sm
              px-2
              py-1
              mx-1
              my-0.5
              flex-center
              space-x-2
            "
          >
            <syn-avatar
              custom-class="w-5 h-5"
              :src="contact?.avatar?.replace('original', 'mini')"
              :name="contact?.name"
            ></syn-avatar>
            <span>{{ contact?.name }}</span>
            <button
              class="
                p-0.5
                rounded-full
                bg-current-500
                hover:bg-current-600
                flex-center
              "
              @click="selectedContacts[contact?.contactId] = false"
            >
              <SynIcon name="Close" custom-class="w-3.5 h-3.5 fill-gray-100" />
            </button>
          </div>
        </div>
        <div class="flex-center space-x-2">
          <syn-button
            v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
            color="gray"
            type-text
            :label="$t('COMMON_LABEL_CANCEL')"
            @click="$emit('onClose')"
          >
          </syn-button>
          <syn-button
            v-vig-tooltip="$t('COMMON_LABEL_SEND')"
            :label="$t('COMMON_LABEL_SEND')"
            :disabled="!selectedContactsArr?.length || isSending"
            @click="onSendClick"
          ></syn-button>
        </div>
      </div>
    </template>
  </syn-modal>
</template>
