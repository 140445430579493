import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import FileStorageService from '@/application/services/FileStorageService';
import storeId from '@/base/store/storeId';
import $constants from '@/ui/plugins/constants';
import { cloneDeep } from '@/ui/hooks/commonFunction';

type FileStorageState = {
    _fileStorageService: FileStorageService;
    files: any[];
    originalFiles: any[];
    totalFiles: number;

    isFetchingFiles: boolean;
    uploadProcess: any;
    renamingFile: any;
    listFileAddNew: any;
    isViewList: boolean;
    // isSharedFolder: boolean;
};

export default defineStore({
    id: storeId.fileStorage,
    state: () =>
        ({
            _fileStorageService: FileStorageService.getInstance(),
            files: [],
            originalFiles: [],
            totalFiles: 0,
            isFetchingFiles: false,
            uploadProcess: {
                totalFiles: 0,
                totalUploaded: 0,
                isProcessing: false,
            },
            isViewList: false,
            // isSharedFolder: false,
            renamingFile: null,
            listFileAddNew: null,
        } as FileStorageState),
    actions: {
        async setListFileAddNew(listFile) {
            if (this.listFileAddNew) this.listFileAddNew = [];
            this.listFileAddNew = listFile.map((el) => el.id);
        },
        async getFiles(params: any, isRefresh = true, isLoading = true) {
            if (isLoading) this.isFetchingFiles = true;

            if (isRefresh && isLoading) this.files = [];

            const apiParams = {
                ...params,
                pageIndex: isRefresh ? null : this.files?.length,
            };

            // if (params?.folderId) {
            //     const folderInfo = await this.getFolder(params?.folderId);
            //     this.isSharedFolder = folderInfo?.systemType == 'SHARED_FOLDER';
            // } else {
            //     this.isSharedFolder = false;
            // }
            let result;
            try {
                const res = await this._fileStorageService.getFiles(apiParams);

                result = res?.result;

                if (isRefresh) {
                    this.files = result?.files || [];
                } else {
                    this.files = this.files
                        ?.concat(result?.files || [])
                        .filter(
                            (file, index, arr) =>
                                arr.findIndex(
                                    (item) => item?.id === file?.id
                                ) === index
                        );
                }

                this.totalFiles = result?.total || 0;
                this.originalFiles = cloneDeep(this.files);
                //
                // this.files = !this.isViewList
                //     ? _.orderBy(
                //           this.files,
                //           [
                //               (file) =>
                //                   file.contentType ==
                //                   $constants.FILE.TYPE.FOLDER,
                //               (file) => stringNomalize(file.name),
                //           ],
                //           ['desc', 'asc']
                //       )
                //     : this.originalFiles;
            } catch (e) {
                console.log(e);
            }

            this.isFetchingFiles = false;

            return result;
        },
        async searchFiles(params: any) {
            const res = await this._fileStorageService.getFiles(params);

            return res?.result;
        },
        async uploadFiles(organizationId: number, files: any[], location: any) {
            this.uploadProcess = {
                totalFiles: files?.length,
                totalUploaded: 0,
                isProcessing: false,
            };

            const uploadedFiles = await Promise.all(
                files?.map(async (file) => {
                    const id = uuidv4();

                    const { path } =
                        await this._fileStorageService.uploadFileToAws(
                            organizationId,
                            id,
                            file
                        );

                    this.uploadProcess.totalUploaded++;

                    return {
                        id,
                        name: file?.name,
                        contentType: file?.type,
                        size: file?.size,
                        path,
                        category: $constants.FILE.CATEGORY.STORAGE,
                    };
                })
            );

            this.uploadProcess.isProcessing = true;

            const res = await this._fileStorageService.uploadFiles({
                files: uploadedFiles,
                location,
            });

            this.uploadProcess.isProcessing = false;

            return res?.result;
        },
        async deleteFiles(files: any[]) {
            const delFiles = _prepareDeleteFiles(files);

            const res = await this._fileStorageService.deleteFiles({
                files: delFiles,
            });

            if (res.success) _deleteFilesInLocalList(this, delFiles);

            return res?.result;
        },
        async moveFilesToTrash(files: any[]) {
            const trashFiles = _prepareDeleteFiles(files);

            const res = await this._fileStorageService.moveFilesToTrash({
                files: trashFiles,
            });

            if (res.success) _deleteFilesInLocalList(this, trashFiles);

            return res?.result;
        },
        async restoreFilesFromTrash(files: any[]) {
            const restoreFiles = _prepareDeleteFiles(files);

            const res = await this._fileStorageService.restoreFilesFromTrash({
                files: restoreFiles,
            });

            if (res.success) _deleteFilesInLocalList(this, restoreFiles);

            return res?.result;
        },
        async deleteAllFilesFromTrash() {
            const res =
                await this._fileStorageService.deleteAllFilesFromTrash();

            if (res.success) _deleteFilesInLocalList(this, this.files);

            return res?.result;
        },
        async moveFilesToFolder(files: any[], location: any) {
            const moveFiles = files?.map(({ id, contentType }) => ({
                id,
                contentType,
            }));

            const res = await this._fileStorageService.moveFilesToFolder({
                files: moveFiles,
                location,
            });

            return res?.result;
        },
        async saveFileToMyResource(file: any, location: any) {
            const res = await this._fileStorageService.saveFileToMyResource({
                file: file,
                location,
            });

            return res;
        },
        async renameFile(file) {
            const res = await this._fileStorageService.renameFile(file);

            // Rename file in local list
            const fileIdx = this.files.findIndex(({ id }) => id === file?.id);
            const fileIdxInOriginalFiles = this.originalFiles.findIndex(
                ({ id }) => id === file?.id
            );
            if (fileIdx > -1) {
                this.files[fileIdx] = {
                    ...this.files[fileIdx],
                    name: res?.result?.name,
                };
            }

            if (fileIdxInOriginalFiles > -1) {
                this.originalFiles[fileIdxInOriginalFiles] = {
                    ...this.originalFiles[fileIdxInOriginalFiles],
                    name: res?.result?.name,
                };
            }

            return res?.result;
        },
        async addFolder(folder: any) {
            const { result } = await this._fileStorageService.addFolder(folder);

            return result;
        },
        getFolder(folderId: any) {
            return this._fileStorageService.getFolder(folderId);
        },
        async addLink(link: any) {
            const { result } = await this._fileStorageService.addLink(link);

            return result;
        },
        async addDriveDocument(driveDoc: any) {
            const { result } = await this._fileStorageService.addDriveDocument(
                driveDoc
            );

            return result;
        },
        setRenamingFile(file: any) {
            this.renamingFile = file;
        },

        changeView() {
            this.isViewList = !this.isViewList;

            // this.files = !this.isViewList
            //     ? _.orderBy(
            //           this.files,
            //           [
            //               (file) =>
            //                   file.contentType == $constants.FILE.TYPE.FOLDER,
            //               (file) => stringNomalize(file.name),
            //           ],
            //           ['desc', 'asc']
            //       )
            //     : this.originalFiles;
        },
    },
});

const _prepareDeleteFiles = (files) => {
    return files?.map(({ id, contentType, path, sourceFrom }) => ({
        id,
        contentType,
        path,
        sourceFrom,
    }));
};

const _deleteFilesInLocalList = (state, delFiles) => {
    state.totalFiles -= delFiles.length;

    const delFileIds = delFiles.map(({ id }) => id);
    state.files = state.files?.filter((file) => !delFileIds.includes(file?.id));

    state.originalFiles = state.originalFiles?.filter(
        (file) => !delFileIds.includes(file?.id)
    );
};
