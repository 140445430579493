<script setup lang="ts">
import { onMounted, ref } from 'vue';
import TaskSharingService from '@/application/services/task/TaskSharingService';
import TaskSnapshotCreateModal from '@/ui/modules/task/sharing/modal-task-shapshot-create/TaskSnapshotCreateModal.vue';
import TaskSnapshotDetailModal from '@/ui/modules/task/sharing/modal-task-shapshot-detail/TaskSnapshotDetailModal.vue';
import { formatDate } from '@/ui/plugins/filters';
import { arrToObj } from '@/ui/helpers/utils';
import { TaskSharingStatus } from '@/domain/enums/taskEnum';
import { openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  task: any;
}>();

defineEmits(['onClose']);

const taskSnapshots = ref();
const isFetching = ref(true);
const isShowModalSnapshotCreate = ref();
const selectedSnapshotToEdit = ref();
const selectedSnapshotToShowDetail = ref();
const isActiveBySnapshot = ref({});

onMounted(async () => {
  try {
    await _getTaskSnapshots();
  } catch (e) {
    console.log(e);
  }

  isFetching.value = false;
});

const onSnapshotActiveChange = async (snapshot) => {
  await TaskSharingService.saveTaskSnapshot({
    ...snapshot,
    status: isActiveBySnapshot.value[snapshot?.sharedId]
      ? TaskSharingStatus.Sharing
      : TaskSharingStatus.UnSharing,
  });

  openNotification({
    body: translate('TASK_SNAPSHOT_UPDATED_MSG'),
    duration: 2000,
  });
};

const onCopySnapshotLinkClick = (snapshot) => {
  TaskSharingService.copySharedTaskLink(snapshot?.sharedId);
};

const onDeleteSnapshotClick = async (snapshot) => {
  const result = await TaskSharingService.deleteTaskSnapshot(snapshot);

  if (result) {
    taskSnapshots.value = taskSnapshots.value?.filter(
      (s) => s?.sharedId !== snapshot?.sharedId
    );
  }
};

const onSnapshotSaved = (snapshot) => {
  const snapshotIndex = taskSnapshots.value?.findIndex(
    (s) => s?.sharedId === snapshot?.sharedId
  );

  if (snapshotIndex > -1) {
    taskSnapshots.value[snapshotIndex] = {
      ...taskSnapshots.value[snapshotIndex],
      ...snapshot,
    };
  } else {
    taskSnapshots.value = [...taskSnapshots.value, snapshot];
  }

  isActiveBySnapshot.value[snapshot?.sharedId] =
    snapshot?.status === TaskSharingStatus.Sharing;

  isShowModalSnapshotCreate.value = false;
  selectedSnapshotToEdit.value = null;
};

const _getTaskSnapshots = async () => {
  if (!props.task?.id) return;

  taskSnapshots.value = await TaskSharingService.getTaskSnapshots(
    props.task?.id
  );

  isActiveBySnapshot.value = arrToObj(
    taskSnapshots.value,
    'sharedId',
    (snapshot) => snapshot?.status === TaskSharingStatus.Sharing
  );
};
</script>

<template>
  <SynModal
    container-class="w-11/12 max-w-2xl"
    container-style=""
    style-body="px-4"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>{{ $t('TASK_SNAPSHOTS') }}</div>
    </template>

    <template #body>
      <div v-if="!isFetching" class="min-h-[10rem]">
        <table v-if="taskSnapshots?.length" class="w-full mb-3">
          <thead>
            <tr class="border-b-2 font-normal">
              <th class="p-1 font-semibold">{{ $t('COMMON_LABEL_NAME') }}</th>
              <th class="p-1 font-semibold">
                {{ $t('COMMON_LABEL_CREATED_DATE') }}
              </th>
              <th class="p-1 font-semibold">{{ $t('COMMON_LABEL_ACTIVE') }}</th>
              <th class="w-0"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="snapshot of taskSnapshots"
              :key="snapshot?.sharedId"
              class="border-b hover:bg-gray-50 group"
            >
              <td class="p-1">
                <a
                  href="javascript:"
                  class="text-current-500 font-semibold"
                  @click="selectedSnapshotToShowDetail = snapshot"
                >
                  {{ snapshot?.name }}</a
                >
                <VigButton
                  ghost
                  color="blue"
                  padding="py-0.5 px-1.5"
                  class="inline-block ml-2 invisible group-hover:visible"
                  @click="
                    selectedSnapshotToEdit = snapshot;
                    isShowModalSnapshotCreate = true;
                  "
                >
                  <SynIcon name="Edit" />
                </VigButton>
              </td>
              <td class="p-1 text-sm">
                {{
                  formatDate(snapshot?.creationTime, 'MMM DD, YYYY, hh:mm A')
                }}
              </td>
              <td class="p-1">
                <VigSwitch
                  v-model="isActiveBySnapshot[snapshot?.sharedId]"
                  @on-change="onSnapshotActiveChange(snapshot)"
                />
              </td>
              <td class="p-1">
                <div class="flex flex-nowrap gap-1">
                  <VigButton
                    ghost
                    color="black"
                    padding="p-2"
                    @click="onCopySnapshotLinkClick(snapshot)"
                  >
                    <SynIcon name="Duplicate" />
                  </VigButton>
                  <VigButton
                    ghost
                    color="red"
                    padding="p-2"
                    @click="onDeleteSnapshotClick(snapshot)"
                  >
                    <SynIcon name="Trash" />
                  </VigButton>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else class="italic text-gray-500 py-4 mb-3">
          {{ $t('TASK_SNAPSHOT_NO_DATA') }}
        </div>

        <VigButton
          label="COMMON_LABEL_ADD"
          @click="isShowModalSnapshotCreate = true"
        >
          <SynIcon name="Plus" />
          <span>{{ $t('TASK_SNAPSHOT_NEW') }}</span>
        </VigButton>
      </div>

      <VigLoading v-else class="h-56" />
    </template>
  </SynModal>

  <TaskSnapshotCreateModal
    v-if="isShowModalSnapshotCreate"
    :task="task"
    :task-snap-shots="taskSnapshots"
    :snapshot="selectedSnapshotToEdit"
    @on-close="
      isShowModalSnapshotCreate = false;
      selectedSnapshotToEdit = null;
    "
    @on-saved="onSnapshotSaved"
  />

  <TaskSnapshotDetailModal
    v-if="selectedSnapshotToShowDetail"
    :snapshot="selectedSnapshotToShowDetail"
    @on-close="selectedSnapshotToShowDetail = null"
  />
</template>
