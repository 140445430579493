<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g id="Docs_3_">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="m451 135-105-30-30-105h-210c-24.853 0-45 20.147-45 45v422c0 24.853 20.147 45 45 45h300c24.853 0 45-20.147 45-45z"
                    fill="#38bfc5"
                    data-original="#4086f4"
                    style=""
                    class=""
                  />
                </g>
              </g>
            </g>
          </g>
          <path
            d="m451 135v332c0 24.853-20.147 45-45 45h-150v-512h60l30 105z"
            fill="#00b0b7"
            data-original="#4175df"
            style=""
            class=""
          />
          <path
            d="m451 135h-105c-16.5 0-30-13.5-30-30v-105c3.9 0 7.8 1.5 10.499 4.501l120 120c3.001 2.699 4.501 6.599 4.501 10.499z"
            fill="#98c5c7"
            data-original="#80aef8"
            style=""
            class=""
          />
          <g>
            <path
              d="m346 241h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15h180c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
              fill="#fff5f5"
              data-original="#fff5f5"
              style=""
              class=""
            />
          </g>
          <g>
            <path
              d="m346 301h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15h180c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
              fill="#fff5f5"
              data-original="#fff5f5"
              style=""
              class=""
            />
          </g>
          <g>
            <path
              d="m346 361h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15h180c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
              fill="#fff5f5"
              data-original="#fff5f5"
              style=""
              class=""
            />
          </g>
          <g>
            <path
              d="m286 421h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
              fill="#fff5f5"
              data-original="#fff5f5"
              style=""
              class=""
            />
          </g>
        </g>
        <g id="Docs_4_">
          <g>
            <g>
              <path
                d="m256 421h30c8.291 0 15-6.709 15-15s-6.709-15-15-15h-30z"
                fill="#e3e7ea"
                data-original="#e3e7ea"
                style=""
              />
            </g>
            <g>
              <path
                d="m256 361h90c8.291 0 15-6.709 15-15s-6.709-15-15-15h-90z"
                fill="#e3e7ea"
                data-original="#e3e7ea"
                style=""
              />
            </g>
            <g>
              <path
                d="m256 301h90c8.291 0 15-6.709 15-15s-6.709-15-15-15h-90z"
                fill="#e3e7ea"
                data-original="#e3e7ea"
                style=""
              />
            </g>
            <g>
              <path
                d="m256 241h90c8.291 0 15-6.709 15-15s-6.709-15-15-15h-90z"
                fill="#e3e7ea"
                data-original="#e3e7ea"
                style=""
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

  <svg
    v-else
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m446.605 124.392-119.997-119.997c-2.801-2.802-6.624-4.395-10.608-4.395h-210c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-332c0-4.09-1.717-7.931-4.395-10.608zm-115.605-73.179 68.787 68.787h-53.787c-8.271 0-15-6.729-15-15zm75 430.787h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h195v75c0 24.813 20.187 45 45 45h75v317c0 8.271-6.729 15-15 15z"
      />
      <path
        d="m346 212h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
      />
      <path
        d="m346 272h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
      />
      <path
        d="m346 332h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
      />
      <path
        d="m286 392h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
