import taskActivityStore from '@/store/task/task-activity';
import { computed, ref, watch } from 'vue';

export default function taskLogActivityComposable({ taskId, orgId }) {
    const _taskActivityStore = taskActivityStore();
    const detailTaskHistory = ref({} as any);
    const isLoadingDetail = ref(false);
    const orderByTask = ref('desc');
    const isOpenTaskHistory = ref(false);
    const currentTaskHistoryId = computed(
        () => _taskActivityStore.currentTaskHistoryId
    );

    watch(
        () => taskId,
        async () => {
            await initData();
        }
    );

    const initData = async () => {
        isLoadingDetail.value = true;

        detailTaskHistory.value = {};

        if (taskId !== currentTaskHistoryId.value) {
            _taskActivityStore.setCurrentTaskHistoryId(taskId);
        }
        const res = await _taskActivityStore.getTaskGroupActivityById(
            orgId,
            taskId,
            orderByTask.value
        );

        if (!res) return;

        for (const [key, value] of Object.entries(res)) {
            detailTaskHistory.value[key] = {
                date: key,
                isCollapse: false,
                histories: {},
            };

            (value as any[])?.forEach((el) => {
                detailTaskHistory.value[key].histories[el?.start] = {
                    start: el?.start,
                    end: el?.end,
                    actioner: el?.actioner,
                    activities: el?.activities?.filter((o) => {
                        return !(
                            o?.afterUpdate?.AssigneeId &&
                            o?.afterUpdate?.GroupId &&
                            o?.afterUpdate?.AssigneeId ===
                                el?.beforeUpdate?.AssigneeId &&
                            o?.afterUpdate?.GroupId ===
                                el?.beforeUpdate?.GroupId
                        );
                    }),
                };
            });
        }

        // console.log('detailTaskHistory.value', detailTaskHistory.value);

        isLoadingDetail.value = false;
    };

    const onCollapse = (date) => {
        detailTaskHistory.value[date].isCollapse =
            !detailTaskHistory.value[date]?.isCollapse;
    };

    const onChangeOrderBy = async () => {
        orderByTask.value == 'desc'
            ? (orderByTask.value = 'asc')
            : (orderByTask.value = 'desc');
        await initData();
    };

    const onCloseHistoryTask = () => {
        if (!isOpenTaskHistory.value || !currentTaskHistoryId.value) return;
        isOpenTaskHistory.value = false;
        _taskActivityStore.setCurrentTaskHistoryId(null);
    };

    const onOpenHistoryTask = (id) => {
        isOpenTaskHistory.value = true;
        _taskActivityStore.setCurrentTaskHistoryId(taskId || id);
    };

    initData();

    return {
        detailTaskHistory,
        currentTaskHistoryId,
        isLoadingDetail,
        orderByTask,
        isOpenTaskHistory,
        onChangeOrderBy,
        onCloseHistoryTask,
        onOpenHistoryTask,
        onCollapse,
        initData,
    };
}
