<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m11.429 16a5.715 5.715 0 1 0 -5.715-5.714 5.72 5.72 0 0 0 5.715 5.714z"
      ></path>
      <path
        d="m18.905 20.721a10.434 10.434 0 0 0 -17.905 7.279 1 1 0 0 0 1 1h18.86a1 1 0 0 0 1-1 10.067 10.067 0 0 0 -.485-3.124 10.36 10.36 0 0 0 -2.47-4.155z"
      ></path>
      <circle cx="23.5" cy="12.25" r="4.25"></circle>
      <path
        d="m23.5 17.67a7.482 7.482 0 0 0 -3.806 1.057c.217.194.436.385.641.595a12.388 12.388 0 0 1 2.952 4.966 11.488 11.488 0 0 1 .437 1.882h6.276a1 1 0 0 0 1-1 7.508 7.508 0 0 0 -7.5-7.5z"
      ></path>
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M11.429,16a5.715,5.715,0,1,0-5.715-5.714A5.72,5.72,0,0,0,11.429,16Zm0-9.429a3.715,3.715,0,1,1-3.715,3.715A3.718,3.718,0,0,1,11.429,6.571Z"
      ></path>
      <path
        d="M23.5,16.5a4.25,4.25,0,1,0-4.25-4.25A4.255,4.255,0,0,0,23.5,16.5Zm0-6.5a2.25,2.25,0,1,1-2.25,2.25A2.252,2.252,0,0,1,23.5,10Z"
      ></path>
      <path
        d="M23.5,17.67a7.52,7.52,0,0,0-5.4,2.31A10.432,10.432,0,0,0,1,28a1,1,0,0,0,1,1H20.86a1,1,0,0,0,1-1,10.423,10.423,0,0,0-.167-1.83H30a1,1,0,0,0,1-1A7.508,7.508,0,0,0,23.5,17.67ZM3.059,27a8.424,8.424,0,0,1,16.41-1.521A7.954,7.954,0,0,1,19.8,27Zm18.052-2.83A10.392,10.392,0,0,0,19.5,21.408a5.493,5.493,0,0,1,9.411,2.762Z"
      ></path>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
