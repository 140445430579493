<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useMouseInElement } from '@vueuse/core';
import { useElementHover } from '@vueuse/core';
import UserById from '@/ui/components/user/UserById.vue';
import { DayoffTypeUI } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import { DayoffType } from '@/domain/enums/DayoffEnums';
import { formatDuration } from '@/ui/hooks/commonFunction';
import {
  CapacityMember,
  ERemainingWorkforceStatus,
} from '@/store/dashboard/overview-monitor-store';
import {
  getRemainWorkingTimeByUser,
  getRemainWorkingforcePercent,
} from '@/ui/modules/dashboard/helpers/remaining-workforce-helper';
import { styleByPercent } from '@/ui/hooks/commonFunction';

const props = defineProps<{
  timeCapacity: {
    members: CapacityMember[];
  };
}>();

const allMemberStartedToday = computed<any[]>(() => {
  return props.timeCapacity?.members;
});

const totalRendering = ref(0);
const pageTotalItem = 8;
const allMemberStartedRenders = ref<CapacityMember[]>([]);

const percentByTimeCapacity = ref(0);
let intervalFunction;
onMounted(() => {
  onCalculateRemainingWorkforcePercent();
  loadMoreItem();

  intervalFunction = setInterval(
    onCalculateRemainingWorkforcePercent,
    60 * 1000
  );
});
onUnmounted(() => {
  clearInterval(intervalFunction);
});

const loadMoreItem = () => {
  if (totalRendering.value == pageTotalItem) {
    allMemberStartedRenders.value = allMemberStartedToday.value;
    return;
  }

  totalRendering.value += pageTotalItem;

  const currentTotalItem = Math.min(
    allMemberStartedToday.value?.length,
    totalRendering.value
  );
  allMemberStartedRenders.value = allMemberStartedToday.value?.slice(
    0,
    currentTotalItem
  );
};

const totalWorkedTime = ref(0);
const totalWorkingForceToday = ref(0);
const onCalculateRemainingWorkforcePercent = () => {
  totalWorkedTime.value = allMemberStartedToday.value?.reduce(
    (oldValue, currentUserData) => {
      const { workingTime } = getRemainWorkingTimeByUser(currentUserData);

      return oldValue + workingTime;
    },
    0
  );
  // todo: user's working time : 8hrs/a day
  totalWorkingForceToday.value = allMemberStartedToday.value?.reduce(
    (oldValue, currentUserData) => {
      const { realIntentTime } = getRemainWorkingTimeByUser(currentUserData);
      return oldValue + realIntentTime;
    },
    0
  );
  percentByTimeCapacity.value = getRemainWorkingforcePercent(
    totalWorkedTime.value,
    totalWorkingForceToday.value
  );
};
const { x: mouseX, y: mouseY } = useMouseInElement(document.body);

const isOpenDetail = ref(false);
const dementionByMouse = ref<any>({});
let delayToShowDetail;
const detailModalRef = ref();
const isHoveredDetailModal = useElementHover(detailModalRef);
const onOpenDetail = () => {
  if (isOpenDetail.value) return;
  if (delayToShowDetail) clearTimeout(delayToShowDetail);

  delayToShowDetail = setTimeout(() => {
    isOpenDetail.value = true;
    dementionByMouse.value = {
      top: `${mouseY.value + 10}px`,
      left: `${mouseX.value + 10}px`,
    };
  }, 750);
};

const onMouseleaveCard = () => {
  if (delayToShowDetail) clearTimeout(delayToShowDetail);
  setTimeout(() => {
    if (isHoveredDetailModal.value) return;
    isOpenDetail.value = false;
  }, 500);
};
const onCloseDetailModal = () => {
  isOpenDetail.value = false;
};
</script>
<template>
  <div
    class="w-52 flex flex-col bg-white justify-between"
    style="box-shadow: 0px 2px 6px #9ca3af47; border-radius: 5px"
    @mouseover="onOpenDetail"
    @mouseleave="onMouseleaveCard"
  >
    <span class="text-gray-600 font-semibold text-sm text-center p-4">
      {{ $t('MONITOR_STATISTIC_REMAINING_WORK_TIME') }}
    </span>
    <div class="px-8 flex-1 flex flex-col">
      <AtomBattery
        :percent="percentByTimeCapacity || 0"
        :disabled="!totalWorkingForceToday"
      />
    </div>
  </div>

  <AtomDialog v-if="isOpenDetail" :dimension="dementionByMouse">
    <template #body>
      <div
        ref="detailModalRef"
        class="w-full p-4 py-6 flex flex-col"
        style="min-width: 36rem"
        @mouseleave="onCloseDetailModal"
      >
        <!-- HEADER -->
        <section class="flex items-center justify-between">
          <span class="text-gray-800 font-semibold">
            {{ $t('MONITOR_STATISTIC_REMAINING_WORK_TIME') }}
          </span>
          <!-- <div>
            <SynIcon name="search" />
          </div> -->
        </section>

        <!-- body -->
        <div
          class="
            w-full
            overflow-x-hidden overflow-y-auto
            small-scrollbar
            pr-2
            my-4
            flex flex-col
            space-y-1
          "
          style="max-height: 20rem"
        >
          <template v-if="allMemberStartedRenders.length > 0">
            <table>
              <tbody>
                <tr
                  v-for="(item, index) in allMemberStartedRenders"
                  :key="index"
                  class="w-full h-full border-b-none"
                >
                  <td class="pb-2">
                    <span class="flex-1 flex items-center space-x-2 text-sm">
                      <UserById :user-id="item.id" is-show-working-status />
                      <SynIcon
                        v-if="item?.dayOffInfo?.length > 0"
                        :name="DayoffTypeUI[DayoffType.SCHEDULE]?.iconName"
                        :style="{
                          fill: DayoffTypeUI[DayoffType.SCHEDULE]?.color,
                        }"
                      />
                    </span>
                  </td>
                  <td class="pb-2">
                    <div class="w-64 flex items-center space-x-2 pr-8 pl-1">
                      <span class="flex-1">
                        <AtomProcess
                          :color="item?.styleData?.bg"
                          :percent="item?.remainPercent"
                          class="h-2.5"
                        />
                      </span>
                      <span
                        v-if="
                          item.workingStatus !==
                          ERemainingWorkforceStatus.offToday
                        "
                        class="w-8 text-gray-600"
                      >
                        {{ item?.remainPercent }}%
                      </span>
                      <span v-else class="w-8 text-gray-600"> </span>
                    </div>
                  </td>
                  <td class="pb-2 flex">
                    <span
                      class="
                        w-full
                        px-2
                        py-1
                        rounded
                        text-gray-600 text-sm text-center
                      "
                      :style="{
                        backgroundColor: item?.styleData?.bg,
                        color: item?.styleData?.text,
                      }"
                    >
                      {{ formatDuration(item?.realIntentTime * 1000) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>

          <div
            v-if="
              allMemberStartedRenders?.length < allMemberStartedToday.length
            "
            class="flex-center"
          >
            <span
              class="text-current-600 text-sm cursor-pointer underline"
              @click="loadMoreItem"
              >{{ $t('COMMON_LABEL_VIEW_ALL') }}</span
            >
          </div>
        </div>

        <!-- footer -->
        <section class="flex border border-current-100 rounded p-1">
          <div class="w-1/5 flex-center">
            <span
              class="text-3xl font-semibold flex-center"
              :style="{
                color: styleByPercent(percentByTimeCapacity)?.bg,
              }"
            >
              {{ percentByTimeCapacity }}%
            </span>
          </div>
          <div
            class="
              flex-1
              rounded
              bg-current-50
              flex flex-col
              space-y-2
              px-4
              py-2
            "
          >
            <div class="w-full flex justify-between">
              <span class="text-sm text-gray-600">
                {{ $t('MONITOR_STATISTIC_REMAINING_WORK_TIME_TOTAL_INTENDED') }}
              </span>
              <span class="font-semibold text-current">
                {{ formatDuration(totalWorkingForceToday * 1000) }}
              </span>
            </div>
            <div class="w-full flex justify-between">
              <span class="text-sm text-gray-600">
                {{ $t('MONITOR_STATISTIC_REMAINING_WORK_TIME_TOTAL_WORKING') }}
              </span>
              <span class="font-semibold text-current">
                {{ formatDuration(totalWorkedTime * 1000) }}
              </span>
            </div>
          </div>
        </section>
      </div>
    </template>
  </AtomDialog>
</template>
