// Generated by https://quicktype.io

import { NotificationType } from '@/domain/enums/notification-enums';
import { TaskLife } from '@/domain/enums/taskEnum';

export interface INotificationTask {
    organizationId: number;
    id: string;
    mainData: any;
    groupedContent: string;
    userId: number;
    title: string;
    body: string;
    status: boolean;
    data: string;
    createdDate: string;
    modifiedDate: string;
    eventActionDate: string;
    isDeleted: boolean;
    executorId: number;
    type: string;
    executorFirstName: null;
    executorLastName: null;
    executorFullName: string;
    executorAvatar: string;
    executorAvatarUrl: string;
    executorAvatarThumbnail: string;
    taskName: string;
    scheduleByHour?: string;
    scheduleTime: string;
    scheduleTimezone?: string;
    executorName: string;
    taskId: string;
    isPrivate: boolean;
    organizationCode: string;
    domainId: number;
    groupOrMemberInfo: string;
    taskComment: string;
    domainName: string;
    groupOrMemberInfoVi: string;
    taskCode: string;
    assigneeName: string;
    isPlannedToTreat: boolean;
    taskCreatorName: string;
    taskCreatorAvatar: string;
    groupName: string;
    isPlannedToFinish: boolean;
    taskDeleted: boolean;
    taskLife: number;
    priority: number;
    taskCreatorId: number;
    taskLink: string;
    important: boolean;
    currentTaskLife: number;
    actionType: ENotificationTaskActionType;
    groupId: null;
    taskExtendInfo: string;
    taskCommentObject: TaskCommentObject;
    relativePath: string;
    groupOrMemberInfoFr: string;
    taskCodeShort: string;
    typeNotification: string;
    keyNotification: string;
    reactions: NotificationReactionObject[];
    taskWorkflow?: INotificationTaskWorkflow;
}

export interface TaskCommentObject {
    task_comment: string;
    task_comment_id: string;
    task_comment_note_count: number;
    task_comment_file_count: number;
}

export interface NotificationReactionObject {
    id: string;
    content: string;
    count: number;
    createdBy: number;
    createdDate: string;
}

export enum ENotificationTaskActionType {
    Finished = 'Finished',
    Edit = 'Edit',
    AddNew = 'AddNew',
    Urgent = 'Urgent',
    Delay = 'Delay',
    Planned = 'Planned',
    ChangePriority = 'ChangePriority',
    ChangeAssignee = 'ChangeAssignee',
    Reopen = 'Reopen',
    Delete = 'Delete',
    // MoveOut = 'MoveOut',
    // MoveIn = 'MoveIn',
    // Assigned = 'Assigned',
    // Mention = 'Mention',
    ChangeName = 'ChangeName',
    ChangeDescription = 'ChangeDescription',
    MentionDescription = 'MentionDescription',
    ChangeStatus = 'ChangeStatus',
    ChangeDeadline = 'ChangeDeadline',
    ChangeTodo = 'ChangeTodo',
    ChangeFinish = 'ChangeFinish',
    ChangeAttachments = 'ChangeAttachments',
    ChangePrivate = 'ChangePrivate',
    ChangeDomain = 'ChangeDomain',
    ChangeNotes = 'ChangeNotes',
    Comment = 'Comment',
    MentionComment = 'MentionComment',
    EditComment = 'EditComment',
    EditCommentAttachments = 'EditCommentAttachments',
    PinnedComment = 'PinnedComment',
    DeleteComment = 'DeleteComment',
    RepeatGenerate = 'RepeatGenerate',
    Clone = 'Clone',
    AddCollaborator = 'AddCollaborator',
    RemoveCollaborator = 'RemoveCollaborator',
    ChangeTodoList = 'ChangeTodoList',
    WORKFLOW = 'WORKFLOW',
    WorkflowAttachToTask = 'WorkflowAttachToTask',
    WorkflowStepComplete = 'WorkflowStepComplete',
    WorkflowStepStart = 'WorkflowStepStart',
    ApprovalChange = 'ApprovalChange',
    ApprovalRequest = 'ApprovalRequest',
    ApprovalRequestCancel = 'ApprovalRequestCancel',
    ApprovalRequestApprove = 'ApprovalRequestApprove',
    ApprovalRequestReject = 'ApprovalRequestReject',
    SubTaskAdded = 'SubTaskAdded',
    SubTaskRemoved = 'SubTaskRemoved',
    ParentTaskAdded = 'ParentTaskAdded',
    ParentTaskRemoved = 'ParentTaskRemoved',
    ChangeStartEndTime = 'ChangeStartEndTime',
}

// Generated by https://quicktype.io

export interface INotificationMainDataTask {
    taskName: string;
    scheduleTime: string;
    executorName: string;
    taskId: string;
    organizationId: number;
    isPrivate: boolean;
    type: string;
    groupedContentFr: string;
    groupedContent: string;
    organizationCode: string;
    domainId: number;
    groupOrMemberInfo: string;
    taskComment: null;
    domainName: string;
    groupOrMemberInfoVi: string;
    taskCode: string;
    assigneeName: string;
    isPlannedToTreat: boolean;
    taskCreatorName: string;
    afterUpdate: INotificationMainDataTaskUpdate;
    taskCreatorAvatar: string;
    groupName: string;
    groupedContentVi: string;
    isPlannedToFinish: boolean;
    taskDeleted: null;
    taskLife: number;
    priority: number;
    taskCreatorId: number;
    groupedContentEn: string;
    taskLink: string;
    important: boolean;
    currentTaskLife: number;
    actionType: string;
    groupId: null;
    taskExtendInfo: string;
    taskCommentObject: TaskCommentObject;
    relativePath: string;
    groupOrMemberInfoFr: string;
    beforeUpdate: INotificationMainDataTaskUpdate;
    pinnedColor: string;
    taskWorkflow?: INotificationTaskWorkflow;
    keyNotification?: string;
}

export interface INotificationMainDataTaskUpdate {
    scheduleTime?: string;
    scheduleByHour?: string;
    scheduleTimezone?: string;
    taskLife?: TaskLife;
    taskName?: string;
    noteAction?: string;
    noteId?: number;
    contentType?: string;
    noteImage?: string;
    addedFiles?: string;
    deletedFiles?: string;
    todoListInput?: string;
    description?: string;
    priority?: string;
    important?: boolean;
    domainId?: number;
    domainName?: string;
    projectId?: number;
    projectName?: string;
    subprojectId?: number;
    subProjectName?: string;
    isPrivate?: boolean;
    planType?: string;
    planTypeText?: string;
    assigneeId?: number;
    assigneeName?: string;
    assigneeAvatar?: string;
    groupId?: number;
    groupName?: string;
    groupAvatar?: string;
    commentContent?: string;
    workflowStepId?: string;
    workflowStepName?: string;
    workflowStepOrder?: number;
    approvalEnabled?: boolean;
    approvers?: any[];
}

// Generated by https://quicktype.io

export interface INotificationTaskWorkflow {
    workflowStatus: string;
    workflowId: string;
    workflowCode: string;
    workflowName: string;
    taskWorkflowId: string;
    workflowSteps: WorkflowStep[];
}

export interface WorkflowStep {
    stepStatus: string;
    stepOrder: number;
    stepName: string;
    taskId: number;
    stepId: string;
}

export interface ITaskNotificationHistory {
    notificationId: string;
    actionType: ENotificationTaskActionType;
    executorId: number;
    executorFirstName: string;
    executorLastName: string;
    executorFullName: string;
    executorAvatar: string;
    executorAvatarUrl: string;
    executorAvatarThumbnail: string;
    eventActionDate: string;
    createdDate: string;
    modifiedDate: string;
    beforeUpdate: any;
    afterUpdate: any;

    groupedContent?: string;
    title?: string;
    status?: boolean;
    taskComment?: string;
    taskCommentObject?: any;
}

// Generated by https://quicktype.io

export interface ITaskNotificationReaction {
    id: string;
    content: string;
    count: number;
    createdBy: number;
    createdDate: string;
}

export interface INotification {
    id: string;
    type: NotificationType;
    executorId: number;
    executorFirstName: null;
    executorLastName: null;
    executorFullName: string;
    executorAvatar: string;
    executorAvatarUrl: string;
    executorAvatarThumbnail: string;
    eventActionDate: string;
    createdDate: string;
    body?: string;
    modifiedDate: string;
    status: boolean;
    mainData: any;
    mainDataId: number | string;
    reactions: ITaskNotificationReaction[];
    histories?: ITaskNotificationHistory[];
}

// Generated by https://quicktype.io

export interface INotificationEventMainData {
    metadata: INotificationEventMainDataMetadata;
    actionType: string;
    description: string;
    type: string;
    title: string;
    eventType: number;
    subType: ENotificationEventSubType;
    actionName: string;
    id: string;
    createDate: string;
    startDateText: string;
    startTimeText: string;
    eventEndDate: string;
    endTime: string;
    sourceType: number;
    actionAvatar: string;
    notificationId: string;
    assigneesId: string;
    updateDate: string;
    eventUrl: string;
    startTime: string;
    eventId: string;
    organizationId: number;
    sourceId: string;
    eventStartDate: string;
    status: number;
    startDate: string;
}

export interface INotificationEventMainDataMetadata {
    repeat_specific_days_of_week: any[];
    notification_type: string;
    workspace: string;
    attachments: INotificationEventMainDataAttachments;
    participant_ids: number[];
    repeat_type: number;
    notification_minutes: null;
    all_day: boolean;
    meeting_type: number;
    meeting_duration: number;
}

export interface INotificationEventMainDataAttachments {
    Files: any[];
    Notes: any[];
}

export enum ENotificationEventSubType {
    UPDATE_EVENT = 'UpdateEvent',
    DELETE_EVENT = 'DeleteEvent',
    GOOGLE_EVENT_ADD = 'GOOGLE_EVENT_ADD',
    GOOGLE_EVENT_UPDATE = 'GOOGLE_EVENT_UPDATE',
    GOOGLE_EVENT_DELETE = 'GOOGLE_EVENT_DELETE',
}
