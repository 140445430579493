<script setup lang="ts">
import { ref, computed } from 'vue';
import organizationStore from '@/store/organization';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { AuthenticateSelectOrganization } from '@/application/services/authenticate/authenticate-service';
import AttachOrganizationModal from '@/ui/modules/organization/setup/AttachOrganizationModal.vue';
import {
  setLocalStorage,
  getLocalStorage,
  StorageConstant,
  removeLocalStorage,
} from '@/ui/hooks/storageHook';
import CreateNewOrganizationModal from '@/ui/modules/organization/setup/CreateNewOrganizationModal.vue';
import OrganizationCard from '@/ui/modules/organization/components/OrganizationCard.vue';
import RegisterOrAttachOrganizationCard from '@/ui/modules/organization/components/RegisterOrAttachOrganizationCard.vue';
import AcceptInvitationModal from '@/ui/modules/member/accept-invitation/AcceptInvitationModal.vue';
import appStore from '@/store/app';
import ModalOrganizationsNotification from '@/ui/modules/organization/modal-organizations-notification/ModalOrganizationsNotification.vue';
import RegisterOrAttachOrganizationButton from '@/ui/modules/organization/components/RegisterOrAttachOrganizationButton.vue';
import tictopConfig from '@/application/constants/tictop-config.const';
import permissionStore from '@/store/permission';

defineEmits(['onCancel', 'onReload']);

const _appStore = appStore();
const _organizationStore = organizationStore();

const activeOrganization = computed(
  () => _organizationStore.organizationInfo
) as any;
const organizationIds = computed(() => _organizationStore.organizationIds);
const organizationByIds = computed(() => {
  return _organizationStore.organizationByIds;
});
const hasNotif = computed(() =>
  organizationIds.value?.some(
    (orgId) =>
      orgId !== activeOrganization.value?.id &&
      organizationByIds.value &&
      (organizationByIds.value[orgId]?.notificationCount ||
        organizationByIds.value[orgId]?.unSeenConversationsCount)
  )
);

const isOpenSetupOrAttachOrg = ref(false);
const isOpenAttachOrg = ref(false);
const isOpenSetupOrg = ref(false);
const isLoadingOrg = computed(() => _organizationStore.isLoadingOrg);
const isOpenAcceptInvitation = ref(false);
const isOpenMessagesNotifs = ref(false);
const inviteToken = ref('');
const isHiddenPersonalModel = computed<boolean>(() => {
  return (
    permissionStore().tictopConfigs[
      tictopConfig.SYSTEM_SUBSCRIP_WEB_WORKSPACE_ALLOW_PERSONAL
    ] !== '1'
  );
});

const onChangeOrganization = async (orgInfo) => {
  const orgId = orgInfo?.id;
  if (!orgInfo || !orgId) return;

  settingGlobalModal({
    type: 'confirm',
    title: '',
    content: `<span>
    <span class="">${translate(
      'COMMON_LABEL_SWITCH_ORGANIZATION_CONFIRM'
    )}</span>
    <br />
    <span class="text-current font-semibold">${orgInfo.name}</span>
    </span>`,
    confirmable: true,
    closeable: true,
    confirmLabel: translate('COMMON_LABEL_SWITCH_ORGANIZATION'),
  });
  const confirmed = await ask();
  if (confirmed) {
    // switch org
    _appStore.setIsLoading(true);
    const authenSelectOrganization = new AuthenticateSelectOrganization(orgId);
    const res = await authenSelectOrganization.login(true);

    handleLoginSuccess(res);
  }
};

const handleLoginSuccess = async (result) => {
  if (!result) return;

  getLocalStorage(StorageConstant.HAVE_AVATAR) &&
    removeLocalStorage(StorageConstant.HAVE_AVATAR);

  setLocalStorage(StorageConstant.HAS_LOGIN, 'true');
};

const onAttachOrgSuccess = async (attachedOrganization) => {
  isOpenAttachOrg.value = false;
  isOpenSetupOrg.value = false;
  isOpenSetupOrAttachOrg.value = false;
  isOpenAcceptInvitation.value = false;

  if (!attachedOrganization) return;

  onReloadOrganization();
};
const onRejectInvitationSuccess = async () => {
  isOpenAttachOrg.value = false;
  isOpenSetupOrg.value = false;
  isOpenSetupOrAttachOrg.value = false;
  isOpenAcceptInvitation.value = false;

  onReloadOrganization();
};

const onReloadOrganization = async () => {
  // isLoadingOrg.value = true;

  try {
    await _organizationStore.getUserOrganizations();

    // isLoadingOrg.value = false;
  } catch (error) {
    // isLoadingOrg.value = false;
  }
};

const onClickAcceptInvite = (token) => {
  isOpenAcceptInvitation.value = true;
  inviteToken.value = token;
};
</script>
<template>
  <SynModal
    z-index="z-50"
    disable-click-outside
    style="
      max-height: 1920px;
      max-width: 1820px;
      min-height: 500px;
      min-width: 600px;
      width: 100%;
      height: 100%;
    "
    @cancel="$emit('onCancel')"
  >
    <template #header>
      <div class="px-4 flex items-center justify-between">
        <span class="text-lg font-semibold">
          {{ $t('COMMON_LABEL_WORKSPACE') }}
        </span>

        <div class="flex items-center gap-3">
          <RegisterOrAttachOrganizationButton
            @on-register="isOpenSetupOrg = true"
            @on-attach="isOpenAttachOrg = true"
          />

          <button
            v-if="organizationIds?.length > 1"
            type="button"
            class="
              px-4
              py-2
              text-current-600 text-sm
              hover:bg-current-50
              border
              shadow-md
              rounded-lg
              flex-center
              gap-3
            "
            @click="isOpenMessagesNotifs = true"
          >
            <span class="relative">
              <SynIcon
                name="ChatBell"
                custom-class="w-5 h-5 fill-current-600"
              />
              <span
                v-if="hasNotif"
                class="
                  w-2.5
                  h-2.5
                  block
                  rounded-full
                  bg-red-500
                  shadow
                  absolute
                  -right-1.5
                  top-0
                "
              ></span>
            </span>
            <span>{{ $t('NOTIFICATION_GLOBAL_VIEW') }}</span>
          </button>

          <VigButton
            ghost
            color="gray"
            padding="p-2"
            @click="$emit('onReload')"
          >
            <SynIcon
              :name="isLoadingOrg ? 'spinner' : 'reload'"
              :class="{ 'animate-spin': isLoadingOrg }"
            />
          </VigButton>
        </div>
      </div>
    </template>
    <template #body>
      <div
        class="flex-1 w-full h-full flex flex-col rounded-md"
        style="min-height: 545px"
      >
        <div class="rounded-md">
          <!-- LIST ORG -->
          <!-- <div class="flex hover:bg-white items-center space-x-2">
            <div
              class="p-4 w-full h-full grid auto-rows-max overflow-hidden gap-5"
              style="
                grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
              "
            >
              <div
                v-for="item in 3"
                :key="item"
                style="height: 13rem"
                class="w-full relative border rounded-xl flex flex-col"
              >
                <div class="flex-1 p-4 flex flex-col">
                  <div class="flex space-x-2">
                    <div
                      class="w-12 h-12 rounded-full bg-gray-200 animate-pulse"
                    ></div>
                    <div class="flex flex-col space-y-2">
                      <div
                        class="rounded-full w-24 h-4 animate-pulse bg-gray-200"
                      ></div>
                      <div
                        class="rounded-full w-48 h-2 animate-pulse bg-gray-200"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div
            class="p-4 w-full h-full grid auto-rows-max overflow-hidden gap-5"
            style="grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr))"
          >
            <RegisterOrAttachOrganizationCard
              @on-register="isOpenSetupOrg = true"
              @on-attach="isOpenAttachOrg = true"
            />
            <OrganizationCard
              v-for="orgId in organizationIds"
              :key="orgId"
              :org-info="organizationByIds[orgId]"
              :is-active="orgId === activeOrganization?.id"
              :members="organizationByIds[orgId]?.members"
              :invitation-info="{
                isInvitation: organizationByIds[orgId]?.isInvitation,
                invitationLink: organizationByIds[orgId]?.invitationLink,
                tokenExpirationDate:
                  organizationByIds[orgId]?.tokenExpirationDate,
              }"
              @on-select="onChangeOrganization(organizationByIds[orgId])"
              @on-accept-invitation="onClickAcceptInvite"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="p-8"></div>
      <!--      &lt;!&ndash; ACTION &ndash;&gt;-->
      <!--      <div class="py-4 flex-center flex-col">-->
      <!--        <SynButton-->
      <!--            class="text-xs"-->
      <!--            type-outline-->
      <!--            color="gray"-->
      <!--            :label="$t('COMMOM_LABEL_REFRESH_ORG')"-->
      <!--            @click="$emit('onReload')"-->
      <!--        />-->
      <!--      </div>-->
    </template>
  </SynModal>
  <CreateNewOrganizationModal
    v-if="isOpenSetupOrg"
    :is-hidden-personal-model="isHiddenPersonalModel"
    @on-cancel="isOpenSetupOrg = false"
    @on-finish="onAttachOrgSuccess"
  />
  <AttachOrganizationModal
    v-if="isOpenAttachOrg"
    @on-success="onAttachOrgSuccess"
    @on-cancel="isOpenAttachOrg = false"
  />

  <AcceptInvitationModal
    v-if="isOpenAcceptInvitation"
    :token="inviteToken"
    location="ORGANIZATION_HEADER"
    @on-close="isOpenAcceptInvitation = false"
    @on-accept-success="onAttachOrgSuccess"
    @on-reject-success="onRejectInvitationSuccess"
  />

  <ModalOrganizationsNotification
    v-if="isOpenMessagesNotifs"
    @on-close="isOpenMessagesNotifs = false"
  />
</template>
