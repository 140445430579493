<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { clearLocalStorage } from '@/ui/hooks/storageHook';
import { useField } from 'vee-validate';
import { useRoute, useRouter } from 'vue-router';
import authStore from '@/store/auth';
import SettingLanguageSmall from '@/ui/components/setting/SettingLanguageSmall.vue';

const _authStore = authStore();
const route = useRoute();
const router = useRouter();
const showPWD = ref(false);
const tokenResetPassword = ref({} as any);
const isLoadingSubmitButton = ref(false);
const hasError = ref(false);
const fetchDone = ref(false);
const msgError = ref('');
const cToken = computed(() => route.query?.c);
const queryEmail = computed(() => route.query?.email);
const queryToken = computed(() => route.query?.token);

onMounted(() => {
  tokenResetPassword.value = {
    email: queryEmail.value,
    token: queryToken.value,
  };
  submitToken();
});
const { value: password, errorMessage: passError } = useField(
  'data.password',
  function (value) {
    if (!value) return translate('COMMON_THIS_FIELD_REQUIRED');
    if (value.length < 6) return translate('COMMON_LABEL_ALERT_PASS_CHARACTER');
    if (value !== passwordAgain.value && passwordAgain.value) {
      return translate('COMMON_LABEL_ALERT_ERROR_PASS');
    }
    return true;
  }
);

const { value: passwordAgain, errorMessage: passAgainError } = useField(
  'data.passwordAgain',
  function (value) {
    if (!value) return translate('COMMON_THIS_FIELD_REQUIRED');
    if (value.length < 6) return translate('COMMON_LABEL_ALERT_PASS_CHARACTER');
    if (value !== password.value && password.value) {
      return translate('COMMON_LABEL_ALERT_ERROR_PASS');
    }
    return true;
  }
);

const submitToken = async () => {
  if (!cToken.value) {
    return;
  }
  try {
    const validToken =
      await _authStore.submitVerificationEmailUserTokenForForgotPassword({
        c: encodeURI(cToken.value?.toString()),
        resetCode: '',
      });

    if (validToken && validToken.token) {
      tokenResetPassword.value = validToken;
      fetchDone.value = true;
      return;
    }

    if (validToken && !validToken.verifiedEmail && validToken.message) {
      fetchDone.value = true;
      hasError.value = true;
      msgError.value = validToken.message;
    }
  } catch (response: any) {
    hasError.value = true;
    fetchDone.value = true;
    if (
      response &&
      !response.success &&
      response.error &&
      response.error.message === 'TOKEN_EXPIRED'
    ) {
      msgError.value = 'TOKEN_EXPIRED';
    }
  }
};

const handleChangePWD = async () => {
  if (!password.value) {
    msgError.value = 'Password is not null';
    return;
  }

  isLoadingSubmitButton.value = true;

  try {
    const res = await _authStore.resetPassword({
      email: tokenResetPassword.value?.email,
      resetToken: tokenResetPassword.value?.token,
      newPassword: password.value,
    });

    if (res.error) {
      msgError.value = res.title;
      return;
    }

    clearLocalStorage({ isSwitchingOrg: false });
    router.push('/login');
  } catch (error: any) {
    msgError.value = error?.message;
  } finally {
    isLoadingSubmitButton.value = false;
  }
};
</script>

<template>
  <div class="absolute w-full h-full bg-current-50">
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div
          class="w-full relative lg:w-1/3 px-4 bg-white"
          style="
            border-radius: 20px;
            padding: 3rem;
            box-shadow: 0 0 20px #b1dcde;
          "
        >
          <div class="flex flex-col mb-4">
            <syn-logo />
          </div>
          <div
            class="
              flex flex-col
              content-center
              text-center
              justify-center
              w-full
              my-1
              font-roboto font-thin
              mb-8
            "
          >
            <span class="text-xl w-full">
              {{ $t('USERMANAGEMENT_LABEL_CHANGE_PASSWORD') }}
            </span>
            <span class="w-full text-current font-semibold my-1">
              {{ tokenResetPassword?.email }}
            </span>
            <span class="text-sm" :class="hasError ? 'text-red-500' : ''">{{
              !hasError
                ? $t('VERIFY_ACCOUT_SUCCESS_LABEL')
                : $t('TOKEN_EXPIRED') || 'Token has expired!'
            }}</span>
          </div>

          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              w-full
              mb-6
              rounded-lg
              border-0
              lg:items-center
            "
          >
            <syn-animation
              v-if="hasError"
              class="w-full"
              :name="'infomation'"
              stype="width:100px;"
            />

            <div v-else class="flex-auto py-10 pt-0 w-full">
              <form v-if="!hasError">
                <div class="relative w-full flex mb-3 mt-3">
                  <div
                    class="
                      flex
                      items-center
                      min-w-max
                      pr-4
                      text-sm text-current-800
                      w-64
                    "
                  >
                    <span>{{ $t('USERMANAGEMENT_LABEL_NEW_PASSWORD') }}</span>
                  </div>
                  <syn-form-input :error-message="passError">
                    <syn-input
                      v-model="password"
                      class="w-full"
                      autofocus
                      :icon-right="showPWD ? 'eye' : 'eye-close'"
                      :input-type="showPWD ? 'text' : 'password'"
                      :placeholder="$t('USERMANAGEMENT_LABEL_NEW_PASSWORD')"
                      :error-message="passError"
                      @on-action="
                        (value) => {
                          showPWD = !showPWD;
                        }
                      "
                    ></syn-input>
                  </syn-form-input>
                </div>
                <div class="relative flex w-full mb-3 mt-3">
                  <div
                    class="
                      flex
                      items-center
                      min-w-max
                      pr-4
                      text-sm text-current-800
                      w-64
                    "
                  >
                    <span>{{
                      $t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM')
                    }}</span>
                  </div>
                  <syn-form-input :error-message="passAgainError">
                    <syn-input
                      v-model="passwordAgain"
                      class="w-full"
                      input-type="password"
                      :placeholder="
                        $t('USERMANAGEMENT_LABEL_NEW_PASSWORD_COMFIRM')
                      "
                      :error-message="passAgainError"
                      @enter="handleChangePWD()"
                    ></syn-input>
                  </syn-form-input>
                </div>
              </form>
              <div v-if="!hasError" class="text-center mt-6">
                <syn-button
                  class="w-full m-auto"
                  :label="$t('USERMANAGEMENT_LABEL_CHANGE_PASSWORD')"
                  :is-loading="isLoadingSubmitButton"
                  :disabled="
                    !password || !passwordAgain || password !== passwordAgain
                      ? true
                      : false
                  "
                  @click="handleChangePWD()"
                >
                </syn-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <setting-language-small class="flex-center absolute bottom-0 my-2" />
  </div>
</template>
