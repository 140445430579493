<script setup lang="ts">
import { computed } from 'vue';
import UserById from '@/ui/components/user/UserById.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import { INotificationMainDataTaskUpdate } from '@/application/types/notification.types';

const props = defineProps<{
  beforeUpdate: INotificationMainDataTaskUpdate;
  afterUpdate: INotificationMainDataTaskUpdate;
}>();
const afterActivity = computed(() => props.afterUpdate);
</script>

<template>
  <div class="inline-flex space-x-1 text-overflow-hidden-line">
    <p>
      {{ $t('COMMON_LABEL_CHANGE_TO') }}
    </p>
    <div
      class="
        flex
        items-center
        space-x-2
        font-semibold
        italic
        uppercase
        text-underline-container
      "
    >
      <span>
        <GroupById
          v-if="afterActivity?.groupId"
          :group-id="afterActivity?.groupId"
          :group-name="afterActivity?.groupName"
          :group-avatar="afterActivity?.groupAvatar"
          avatar-class="w-5 h-5"
        />
      </span>
      <UserById
        v-if="afterActivity?.assigneeId"
        :user-id="afterActivity?.assigneeId"
        avatar-class="w-5 h-5"
      />
    </div>
  </div>
</template>

<style lang="scss">
.text-underline-container {
  .text-underline {
    text-decoration-line: underline;
  }
}
</style>
