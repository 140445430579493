import PaymentRepository from '@/application/repositories/PaymentRepository';
import { IPaymentPayload } from '@/application/types/payment.types';

export default class PaymentService {
    private static instance: PaymentService;
    _paymentRepository: PaymentRepository;

    constructor() {
        this._paymentRepository = PaymentRepository.getInstance();
    }

    public static getInstance(): PaymentService {
        if (!PaymentService.instance)
            PaymentService.instance = new PaymentService();
        return PaymentService.instance;
    }

    getPaymentConfigs() {
        return this._paymentRepository.getPaymentConfigs();
    }

    createPaymentToken(data: IPaymentPayload): Promise<any> {
        return this._paymentRepository.createPaymentToken(data);
    }

    getPaymentHistories() {
        return this._paymentRepository.getPaymentHistories();
    }

    getTransactionDetail(id): Promise<any> {
        return this._paymentRepository.getTransactionDetail(id);
    }

    getAvailableVouchers(): Promise<any> {
        return this._paymentRepository.getAvailableVouchers();
    }
    checkLicense(data: { licenseKey: string }): Promise<any> {
        return this._paymentRepository.checkLicense(data);
    }
    verifyLicense(data: { licenseKey: string }): Promise<any> {
        return this._paymentRepository.verifyLicense(data);
    }
    applyLicense(data: { licenseKey: string; code: string }): Promise<any> {
        return this._paymentRepository.applyLicense(data);
    }

    usingFreePackage(): Promise<any> {
        return this._paymentRepository.usingFreePackage();
    }
}
