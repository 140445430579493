<template>
  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 511.992 511.992"
    height="512"
    viewBox="0 0 511.992 511.992"
    width="512"
  >
    <path
      d="m383.996 0h-256c-48.52 0-88 39.48-88 88v256c0 48.52 39.48 88 88 88h70.94l43.34 72.23c6.187 10.319 21.216 10.381 27.44 0l43.34-72.23h70.94c48.52 0 88-39.48 88-88v-256c0-48.52-39.48-88-88-88zm6.92 303.74-33.94 33.94c-9.52 9.52-22.61 14.31-38.35 14.31-99.588 0-250.016-178.484-187.92-240.58l33.94-33.94c6.231-6.251 16.397-6.254 22.63 0l62.22 62.22c6.25 6.25 6.25 16.38 0 22.63l-29.93 29.94c7.651 18.274 38.175 48.831 56.56 56.56l29.94-29.93c6.268-6.268 16.396-6.234 22.63 0l62.22 62.22c6.25 6.25 6.25 16.38 0 22.63z"
    />
    <path
      d="m294.756 275.46c-18.969 18.97-54.583-9.333-73.54-28.29-18.945-18.946-47.265-54.586-28.28-73.54l22.62-22.63-39.6-39.59-22.62 22.62c-7.03 7.03-6.48 24.19 1.44 44.77 20.424 53.103 81.369 114.256 134.81 134.81 20.58 7.92 37.74 8.47 44.77 1.44l22.62-22.62-39.6-39.6z"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 511.992 511.992"
    height="512"
    viewBox="0 0 511.992 511.992"
    width="512"
  >
    <path
      d="m328.695 218.887c-6.245-6.245-16.38-6.248-22.628 0l-29.937 29.938c-18.296-7.687-48.832-38.156-56.568-56.568l29.938-29.938c6.248-6.248 6.248-16.379 0-22.627l-62.226-62.226c-6.243-6.244-16.38-6.248-22.627 0l-33.94 33.941c-16.831 16.83-18.89 44.844-5.798 78.881 37.719 98.07 179.074 200.389 232.072 147.393l33.941-33.941c6.248-6.248 6.248-16.379 0-22.627zm5.657 96.167c-7.029 7.027-24.183 6.476-44.767-1.442-53.186-20.455-114.294-81.469-134.811-134.811-7.918-20.585-8.47-37.738-1.442-44.767l22.627-22.627 39.598 39.598-22.626 22.627c-6.032 6.031-10.769 17.639.923 38.683 17.154 30.877 75.768 88.271 100.9 63.141l22.627-22.627 39.598 39.598z"
    />
    <path
      d="m383.996 0h-256c-48.523 0-88 39.477-88 88v256c0 48.523 39.477 88 88 88h70.941l43.339 72.231c6.19 10.317 21.212 10.379 27.439 0l43.34-72.231h70.941c48.523 0 88-39.477 88-88v-256c0-48.523-39.476-88-88-88zm56 344c0 30.879-25.122 56-56 56h-80c-5.62 0-10.828 2.949-13.72 7.769l-34.28 57.133-34.28-57.133c-2.892-4.819-8.1-7.769-13.72-7.769h-80c-30.878 0-56-25.121-56-56v-256c0-30.879 25.122-56 56-56h256c30.878 0 56 25.121 56 56z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
