<script setup lang="ts">
import { computed, ref, nextTick } from 'vue';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import { openNotification, AlertType } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';

const _groupStore = groupStore();

const props = defineProps({
  refId: {
    default: '',
    type: [String, Number],
  },
  name: {
    default: '',
    type: String,
  },
  scope: {
    default: 'GROUP',
    type: String,
  },
  notificationGroup: {
    type: Object,
    default: () => {},
  },
  classNotification: {
    type: String,
    default: 'w-5 h-5 fill-gray-500',
  },
});
const _notificationSettingStore = notificationSettingStore();

const listSettingNotificationOfGroup = computed(
  () => _notificationSettingStore.listNotificationGroupOfCurrentUser
);

const notificationByGroupId = computed(() =>
  listSettingNotificationOfGroup.value?.find((el) => el.refId == props.refId)
);

const notificationSetting = computed(() =>
  listSettingNotificationOfGroup.value?.find((el) => el.refId == props.refId)
);
const isLoadingEnable = ref({
  result: false,
  id: null,
});

nextTick(async () => {
  if (!listSettingNotificationOfGroup.value) {
    await _notificationSettingStore.getUserNotificationSettingInGroups();
  }
});

const onEnableNotification = async () => {
  isLoadingEnable.value.result = true;
  isLoadingEnable.value.id = props.refId;
  const enableResult =
    await _notificationSettingStore.enableSettingNotification(
      props.notificationGroup
        ? props.notificationGroup?.id
        : notificationByGroupId.value?.id
    );
  if (enableResult) {
    openNotification({
      title: translate('COMMON_LABEL_SUCCESS'),
      body:
        translate('NOTIFICATION_SETTING_ENABLE_SUCCESS_LABEL', {
          group: props?.name,
        }) || 'Turn on the notification of the group again',
    });

    _groupStore.setValueSettingGroup({}, props.refId);

    overviewGroupStore().updateNotificationByGroupId({}, props.refId);

    isLoadingEnable.value.result = false;
    isLoadingEnable.value.id = null;
  } else {
    isLoadingEnable.value.result = false;
    isLoadingEnable.value.id = null;
    openNotification({
      type: AlertType.error,
      title: translate('COMMON_LABEL_ERROR'),
      body:
        translate('NOTIFICATION_SETTING_ERROR_LABEL') ||
        'Notification settings failed',
    });
  }
};
</script>

<template>
  <div
    v-if="notificationSetting"
    v-vig-tooltip="$t('NOTIFICATION_SETTING_ENABLE_LABEL')"
    class="
      hover:bg-current-50
      rounded-full
      p-1
      text-gray-500
      fill-gray-500
      flex-center
      hover:fill-current hover:text-current-500
    "
    @click.stop="onEnableNotification()"
  >
    <SynIcon
      :custom-class="classNotification"
      is-active
      :name="
        isLoadingEnable.result && isLoadingEnable.id == refId
          ? 'spinner'
          : 'bell-mute'
      "
      :class="
        isLoadingEnable.result && isLoadingEnable.id == refId
          ? 'animate-spin'
          : ''
      "
    />
  </div>
</template>
