<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    totalMiniseconds: number;
    autoStart?: boolean;
    format?: string;
    isDay?: boolean;
    customClassValue?: string;
    customClassText?: string;
    isPause: boolean;
  }>(),
  {
    totalMiniseconds: 0,
    isPause: false,
    autoStart: false,
    format: 'hh:mm:ss',
    isDay: false,
    customClassValue: '',
    customClassText: '',
  }
);

const emit = defineEmits(['onStop', 'onListenCurrentDistance']);

onMounted(() => {
  if (props?.isPause) {
    const countDate = Date.now();
    // Get current time
    const now = Date.now();
    // Find the distance between now and the count-down date
    const distanceValue = props.totalMiniseconds + countDate - now;

    if (distanceValue <= 0) {
      clearInterval(intervalFunction);
      return;
    }

    distance.value = distanceValue;

    calculatedWatch();
  }
  if (!props?.isPause && props.autoStart) countdown();
});

watch(
  () => props.totalMiniseconds,
  () => {
    if (props?.isPause) {
      const countDate = Date.now();
      // Get current time
      const now = Date.now();
      // Find the distance between now and the count-down date
      const distanceValue = props.totalMiniseconds + countDate - now;

      if (distanceValue <= 0) {
        clearInterval(intervalFunction);
        return;
      }

      distance.value = distanceValue;

      calculatedWatch();
    }
    if (!props?.isPause && props.autoStart) countdown();
  }
);
// Set the date we're counting down to
const days = ref('00');
const hours = ref('00');
const minutes = ref('00');
const seconds = ref('00');
const distance = ref(0);

const calculatedWatch = () => {
  // Time calculations for days, hours, minutes and seconds
  const _days = Math.floor(distance.value / (1000 * 60 * 60 * 24));
  const _hours = Math.floor(
    (distance.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const _minutes = Math.floor(
    (distance.value % (1000 * 60 * 60)) / (1000 * 60)
  );
  const _seconds = Math.floor((distance.value % (1000 * 60)) / 1000);
  days.value = _days > 0 ? _days + '' : '0';
  hours.value = ('0' + _hours).slice(-2);
  minutes.value = ('0' + _minutes).slice(-2);
  seconds.value = ('0' + _seconds).slice(-2);
};
// Update the count down every 1 second
let intervalFunction, timeoutFunction;
const countdown = () => {
  distance.value = 0;
  if (!props.totalMiniseconds) return;

  if (intervalFunction) {
    clearInterval(intervalFunction);
    intervalFunction = null;
  }
  if (timeoutFunction) {
    clearTimeout(timeoutFunction);
    timeoutFunction = null;
  }

  const countDate = Date.now();

  intervalFunction = setInterval(() => {
    // Get current time
    const now = Date.now();
    // Find the distance between now and the count-down date
    const distanceValue = props.totalMiniseconds + countDate - now;

    if (distanceValue <= 0) {
      clearInterval(intervalFunction);
      return;
    }

    distance.value = distanceValue;
    emit('onListenCurrentDistance', distanceValue);

    calculatedWatch();
  }, 1000);

  timeoutFunction = setTimeout(function () {
    clearInterval(intervalFunction);
    emit('onStop', distance.value);
  }, props.totalMiniseconds);
};

onUnmounted(() => {
  clearInterval(intervalFunction);
  clearTimeout(timeoutFunction);
});
const isShowHours = computed(() => props.format?.includes('hh'));
const isShowSecond = computed(() => props.format?.includes('ss'));

defineExpose({
  countdown,
});
</script>
<template>
  <div class="flex justify-center text-center">
    <div v-if="isDay" class="w-max px-1">
      <span :class="customClassValue">{{ days }}</span>
      <span :class="customClassText">d</span>
    </div>

    <div v-if="isShowHours" class="px-1">
      <span :class="customClassValue">{{ hours }}</span>
      <span :class="customClassText">h</span>
    </div>

    <div class="px-1">
      <span :class="customClassValue">{{ minutes }}</span>
      <span :class="customClassText">m</span>
    </div>

    <div v-if="isShowSecond" class="px-1">
      <span :class="customClassValue">{{ seconds }}</span>
      <span :class="customClassText">s</span>
    </div>
  </div>
</template>
