export interface IDomainDetail {
    id: number;
    name: string;
    description: string;
    type: string;
    creationTime: string;
    createdProjectTotal: number;
    creatorId: number;
    lastModifierId: number;
    lastModificationTime: string;
    members: { id: number; anyProjects: boolean }[];
    anyProjectMembers: number[];
    groups: number[];
    projects: number[];
    system: boolean;
    invisible: boolean;
    isChecked?: boolean;
}
export default class DomainDetailClass implements IDomainDetail {
    id: number;
    name: string;
    description: string;
    type: string;
    creationTime: string;
    createdProjectTotal: number;
    creatorId: number;
    lastModifierId: number;
    lastModificationTime: string;
    members: { id: number; anyProjects: boolean }[];
    anyProjectMembers: number[];
    groups: number[];
    projects: number[];
    system: boolean;
    invisible: boolean;

    constructor(data: any) {
        this.id = data?.id;
        this.name = data?.name;
        this.description = data?.description;
        this.type = data?.type;
        this.creationTime = data?.creationTime;
        this.createdProjectTotal = data?.createdProjectTotal;
        this.creatorId = data?.creatorId;
        this.lastModifierId = data?.lastModifierId;
        this.lastModificationTime = data?.lastModificationTime;
        this.members = data?.members;
        this.anyProjectMembers = data?.anyProjectMembers;
        this.groups = data?.groups;
        this.projects = data?.projects;
        this.system = data?.system || false;
        this.invisible = data?.invisible || false;
    }
}
