<script setup lang="ts">
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import { ref } from 'vue';

defineEmits(['onEdit', 'onRemove']);

defineProps<{
  reminderText: string;
  textTooltip: string;
}>();

const taskReminder = ref(null as any);
</script>

<template>
  <VigDropdown ref="taskReminder" :arrow="true">
    <template #dropdown-toggle>
      <div
        v-vig-tooltip="textTooltip"
        class="
          flex-center
          px-2
          py-1
          bg-current-50
          rounded-full
          text-current-500
          cursor-pointer
          space-x-1
        "
      >
        <SynIcon name="bell" custom-class="w-3 h-3 fill-current" is-active />
        <span class="text-xs"> {{ reminderText }}</span>
      </div>
    </template>
    <template #dropdown-menu>
      <div class="flex flex-col p-2 w-32 bg-white text-sm rounded">
        <div
          class="
            hover:bg-gray-50
            p-2
            cursor-pointer
            rounded
            text-gray-700
            flex
            items-center
            space-x-2
          "
          @click="
            $emit('onEdit');
            taskReminder.onForceClose();
          "
        >
          <!--          <SynIcon name="edit" custom-class="w-4 h-4 fill-gray-500" />-->
          <span>{{ $t('COMMON_LABEL_EDIT') }}</span>
        </div>
        <div
          class="
            hover:bg-gray-50
            p-2
            cursor-pointer
            rounded
            text-red-500
            flex
            items-center
            space-x-2
          "
          @click="
            $emit('onRemove');
            taskReminder.onForceClose();
          "
        >
          <!--          <SynIcon name="trash" custom-class="w-4 h-4 fill-red-500" />-->
          <span>{{ $t('COMMON_LABEL_CANCEL_REMINDER') }}</span>
        </div>
      </div>
    </template>
  </VigDropdown>
</template>
