import PerfectScrollbar from '@/ui/plugins/scrollbar/perfect-scrollbar';

export default {
    install: (app) => {
        app.directive('perfect-scrollbar', {
            mounted(el, binding) {
                if (!el) return;
                el.classList.add('relative', 'overflow-hidden');
                el.ps = new PerfectScrollbar(el);

                el.onResize = () => {
                    el.ps?.update();
                };

                el.onScrollToTop = () => {
                    if (binding.value?.onScrollToTop)
                        binding.value?.onScrollToTop();
                };

                el.onScrollToEnd = () => {
                    if (binding.value?.onScrollToEnd)
                        binding.value?.onScrollToEnd();
                };

                el.addEventListener('ps-y-reach-start', el.onScrollToTop);
                el.addEventListener('ps-y-reach-end', el.onScrollToEnd);

                window.addEventListener('resize', el.onResize);
            },
            updated(el) {
                if (!el) return;
                el.ps?.update();
            },
            unMounted(el) {
                if (!el) return;

                el.removeEventListener('ps-y-reach-start', el.onScrollToTop);
                el.removeEventListener('ps-y-reach-end', el.onScrollToEnd);

                window.removeEventListener('resize', el.onResize);
            },
        });
    },
};
