<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import domainStore from '@/store/scope';
import { ignoreUnicode } from '@/ui/plugins/utils';

const props = withDefaults(
  defineProps<{
    name: string;
    description: string;
    domainId?: number | null;
    domainName: string;
    parentId?: number | null;
    parentName: string;
    isDisabled?: boolean;
    isProject: boolean;
  }>(),
  {
    domainId: null,
    parentId: null,
    isDisabled: false,
  }
);

const emit = defineEmits<{
  (e: 'update:name', name: string): void;
  (e: 'update:description', description: string): void;
  (e: 'update:domainId', domainId: number | null): void;
  (e: 'update:domainName', domainName: string): void;
  (e: 'update:parentId', parentId: number | null): void;
  (e: 'update:parentName', parentName: string): void;
  (e: 'update:isProject', isProject: boolean): void;
  (e: 'isReadyNameDomain', isCheck: boolean): void;
  (e: 'nameInput', name: string): void;
}>();

const _domainStore = domainStore();

const allDomains = computed(() => {
  return _domainStore.allDomains;
});
const domainByIds = computed(() => {
  return _domainStore.domainByIds;
});
const projectByIds = computed(() => {
  return _domainStore.projectByIds;
});
// const listSubProject = ref([]);

const listParentProjectByDomainId = computed(() => {
  if (!props.domainId) return [];
  return domainByIds.value[props.domainId]?.projects?.map(
    (projectId) => projectByIds.value[projectId]
  );
});

onMounted(() => {
  if (props.domainId) {
    emit('update:isProject', true);
    onSelectParentDomain(domainByIds.value[props.domainId]);
  }
  if (props.parentId) {
    emit('update:isProject', true);
    onSelectParentProject(projectByIds.value[props.parentId]);
  }
});

watch(
  () => props.domainId,
  () => {
    checkName(props.name);
  }
);

const isValidate = ref(false);
const errorMessageName = computed(() =>
  !props?.name ? 'TICKET_TASK_FORM_LABEL_NAME_REQUIRE' : ''
);

const onBlurNameInput = () => {
  isValidate.value = true;
};

const listSubProjectByParentId = ref([] as any);
const listProjectSuggest = ref([]);
const listProjectCheck = ref([]);
const listSubProjectCheck = ref([]);
const listSubProjectSuggest = ref([]);
const listAllDomainNameSuggest = ref([] as any);
const listAllDomainNameCheck = ref([] as any);
const onSelectParentId = ref(false);
const getDomainName = (id) => {
  return domainByIds.value[id].name;
};
const subdomainName = (parentId) => {
  return projectByIds.value[parentId].name;
};

const arrNameSubProject = computed(() => {
  if (listSubProjectByParentId.value?.length <= 0) return;
  return listSubProjectByParentId.value?.map((el) => ({
    name: el.name,
    domainId: el.domainId,
    parentId: el.parentId,
  }));
});

const arrDomainName = computed(() => {
  if (listParentProjectByDomainId.value?.length <= 0) return;
  return listParentProjectByDomainId.value?.map((e) => ({
    name: e.name,
    domainId: e.domainId,
  }));
});

const checkName = (value) => {
  if (value.trim().length > 0) {
    emit('isReadyNameDomain', true);
    emit('nameInput', value);
  } else {
    emit('isReadyNameDomain', false);
  }
  let arrAllDomainName = [] as any;
  if (allDomains.value?.length > 0) {
    arrAllDomainName = allDomains.value?.map((name) => name.name);
  }
  setTimeout(() => {
    if (value.trim().length == 0) {
      listProjectSuggest.value = [];
      listProjectCheck.value = [];
      listSubProjectSuggest.value = [];
      listSubProjectCheck.value = [];
      listAllDomainNameCheck.value = [];
      listAllDomainNameSuggest.value = [];
      emit('isReadyNameDomain', false);
      return;
    }

    if (value.trim().length < 2) {
      listProjectSuggest.value = [];
      listProjectCheck.value = [];
      listSubProjectSuggest.value = [];
      listSubProjectCheck.value = [];
      listAllDomainNameCheck.value = [];
      listAllDomainNameSuggest.value = [];
      emit('isReadyNameDomain', true);
      return;
    }
    if (arrNameSubProject.value?.length > 0) {
      listSubProjectSuggest.value = arrNameSubProject.value?.filter(
        (e) => ignoreUnicode(e.name).indexOf(ignoreUnicode(value)) > -1
      );
      listSubProjectCheck.value = arrNameSubProject.value?.filter(
        (el) => ignoreUnicode(el.name) == ignoreUnicode(value)
      );
    } else if (arrDomainName.value?.length > 0 && !onSelectParentId.value) {
      listProjectSuggest.value = arrDomainName.value?.filter(
        (e) => ignoreUnicode(e.name).indexOf(ignoreUnicode(value)) > -1
      );
      listProjectCheck.value = arrDomainName.value?.filter(
        (el) => ignoreUnicode(el.name) == ignoreUnicode(value)
      );
    } else if (!props?.domainId) {
      listAllDomainNameSuggest.value = arrAllDomainName?.filter(
        (e) => ignoreUnicode(e).indexOf(ignoreUnicode(value)) > -1
      );
      listAllDomainNameCheck.value = arrAllDomainName?.filter(
        (el) => ignoreUnicode(el) == ignoreUnicode(value)
      );
    } else return;
    if (
      listSubProjectCheck.value.length == 0 &&
      listProjectCheck.value.length == 0 &&
      listAllDomainNameCheck.value.length == 0
    )
      emit('isReadyNameDomain', true);
    else emit('isReadyNameDomain', false);
  }, 300);
  emit('nameInput', value);
};

const onSelectParentDomain = (domain) => {
  emit('update:domainId', domain?.id);
  emit('update:domainName', domain?.name);
  listAllDomainNameSuggest.value = [];
  listAllDomainNameCheck.value = [];
  // listProjectSuggest.value = [];
  // listProjectCheck.value = [];
  if (props?.domainId !== domain?.id) onClearParentProject();
  checkName(props?.name);
};
const onClearParentDomain = () => {
  emit('update:domainId', null);
  emit('update:domainName', '');
  listProjectSuggest.value = [];
  listProjectCheck.value = [];
  onClearParentProject();
};
const onSelectParentProject = (project) => {
  onSelectParentId.value = true;
  emit('update:parentId', project?.id);
  emit('update:parentName', project?.name);
  const listSubProject = projectByIds.value[project?.id]?.subProjects;
  const listSubProjectId = listSubProject?.map((e) => parseInt(e));
  listSubProjectByParentId.value = listSubProjectId?.map(
    (e) => projectByIds.value[e]
  );
  checkName(props.name);
};
const onClearParentProject = () => {
  onSelectParentId.value = false;
  listSubProjectByParentId.value = [];
  listSubProjectCheck.value = [];
  listSubProjectSuggest.value = [];
  listSubProjectSuggest.value = [];
  emit('update:parentId', null);
  emit('update:parentName', '');
};
</script>
<template>
  <div class="w-full flex-1 flex flex-col space-y-2" style="max-width: 500px">
    <div
      class="flex flex-col rounded"
      :class="isProject ? 'bg-current-50 space-y-3 p-3' : ''"
    >
      <div class="flex items-center justify-end space-x-2">
        <AtomSwitch
          :model-value="isProject"
          @update:model-value="(value) => $emit('update:isProject', value)"
        >
          <span class="text-sm text-gray-800">
            {{ $t('DOMAIN_LABEL_HAVE_SUB') || 'Is sub domain/project' }}
          </span>
        </AtomSwitch>
        <VigDropdown z-index="60">
          <template #dropdown-toggle>
            <div class="flex-center">
              <SynIcon
                name="question"
                custom-class="h-4 w-4 hover:fill-current cursor-pointer"
              />
            </div>
          </template>
          <template #dropdown-menu>
            <div class="p-4 w-96 bg-gray-50 rounded-lg">
              <span
                class="text-left text-sm"
                v-html="$t('DOMAIN_LABEL_EXPLAIN')"
              >
              </span>
            </div>
          </template>
        </VigDropdown>
      </div>
      <!-- Parent -->
      <div v-if="isProject" class="flex flex-col">
        <div class="flex space-x-2 justify-between">
          <VigSelect
            :placeholder="
              $t('DOMAIN_LABEL_SELECT_DOMAIN') || 'Select domain/project'
            "
            key-search="name"
            key-label="name"
            toggle-class="py-1"
            :label-value="domainName"
            :origin-options="allDomains"
            @on-choose="onSelectParentDomain"
            @on-clear="onClearParentDomain"
          />
          <VigSelect
            :placeholder="
              $t('DOMAIN_LABEL_SELECT_SUB_DOMAIN') ||
              'Select sub domain/project'
            "
            key-search="name"
            key-label="name"
            toggle-class="py-1"
            :label-value="parentName"
            :origin-options="listParentProjectByDomainId"
            @on-choose="onSelectParentProject"
            @on-clear="onClearParentProject"
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <span class="py-1 text-current-600 text-sm font-semibold">
        {{ $t('PROJECTMANAGEMENT_LABEL_NAME') }} *
      </span>
      <SynFormInput :error-message="isValidate && errorMessageName">
        <SynInput
          :model-value="name"
          autofocus
          :placeholder="
            $t(
              domainId
                ? 'PROJECTMANAGEMENT_LABEL_TYPEPROJECTNAME'
                : 'PROJECTMANAGEMENT_LABEL_TYPEDOMAINNAME'
            )
          "
          :is-disabled="isDisabled"
          :error-message="isValidate && errorMessageName"
          @update:model-value="(value) => $emit('update:name', value)"
          @blur="onBlurNameInput"
          @input="checkName(name)"
        />
      </SynFormInput>
      <div
        v-if="
          (listProjectSuggest.length > 0 && listProjectCheck.length === 0) ||
          (listSubProjectSuggest.length > 0 &&
            listSubProjectCheck.length === 0) ||
          (listAllDomainNameSuggest.length > 0 &&
            listAllDomainNameCheck.length === 0)
        "
        class="
          text-sm
          p-2
          mt-1
          max-h-28
          small-scrollbar
          overflow-auto
          rounded-md
          bg-orange-100
          flex flex-col
          text-orange-500
          italic
        "
      >
        <span
          v-if="
            listSubProjectSuggest.length > 0 || listProjectSuggest.length > 0
          "
        >
          {{ $t('SUBDOMAIN_LABEL_SUGGEST') }}
        </span>
        <span
          v-if="
            listAllDomainNameSuggest.length > 0 &&
            listSubProjectSuggest.length === 0 &&
            listProjectCheck.length === 0
          "
        >
          {{ $t('DOMAIN_LABEL_SUGGEST') }}
        </span>
        <ul v-if="listProjectSuggest.length > 0">
          <li
            v-for="item in listProjectSuggest"
            :key="item"
            class="ml-5 list-disc"
          >
            {{ getDomainName(item.domainId) }}/ {{ item.name }}
          </li>
        </ul>
        <ul v-if="listSubProjectSuggest.length > 0">
          <li
            v-for="item in listSubProjectSuggest"
            :key="item"
            class="ml-5 list-disc"
          >
            {{ getDomainName(item.domainId) }}/
            {{ subdomainName(item.parentId) }}/
            {{ item.name }}
          </li>
        </ul>
        <ul v-if="listAllDomainNameSuggest.length > 0">
          <li
            v-for="item in listAllDomainNameSuggest"
            :key="item"
            class="ml-5 list-disc"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div
        v-if="listProjectCheck.length > 0 || listSubProjectCheck.length > 0"
        class="italic text-sm text-red-500"
      >
        <span v-html="$t('SUBDOMAIN_LABEL_CHECK')"></span>
      </div>
      <div
        v-if="
          listAllDomainNameCheck.length > 0 &&
          listProjectCheck.length === 0 &&
          listSubProjectCheck.length === 0
        "
        class="italic text-sm text-red-500"
      >
        <span v-html="$t('DOMAIN_LABEL_CHECK')"></span>
      </div>
    </div>

    <!-- DESCRIPTION -->
    <div class="flex flex-col space-y-1 pt-2">
      <span class="text-current-600 text-sm font-semibold">
        {{ $t('PROJECTMANAGEMENT_LABEL_DESCRIPTION') }}
      </span>
      <SynInputArea
        class-name="w-1/2"
        :model-value="description"
        :placeholder="
          $t(
            domainId
              ? 'PROJECTMANAGEMENT_LABEL_TYPEPROJECTDESCRIPTION'
              : 'PROJECTMANAGEMENT_LABEL_TYPEDOMAINDESCRIPTION'
          )
        "
        style="min-height: 100px"
        :is-disabled="isDisabled"
        @update:model-value="(value) => $emit('update:description', value)"
      />
    </div>
  </div>
</template>

<style>
.outline-red {
  outline: 1px solid red;
}

.domain-min-height {
  min-height: 82px;
}
</style>
