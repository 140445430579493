<script setup lang="ts">
import { ref, watch } from 'vue';
import userStore from '@/store/user';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import DomainTreeCheckbox from '@/ui/modules/domains/component/DomainTreeCheckbox.vue';
import UserById from '@/ui/components/user/UserById.vue';

const props = defineProps<{
  userId: number | string;
}>();
const _userStore = userStore();
const domains = ref([] as any[]);
const isLoading = ref(false);
const init = async (userId) => {
  isLoading.value = true;
  domains.value = await _userStore.getUserDomainListById(
    userId || getCurrentUserId()
  );
  isLoading.value = false;
};

watch(
  () => props.userId,
  (userId) => {
    init(userId);
  }
);

init(props.userId);
</script>
<template>
  <div class="flex-1 pl-1 flex flex-col">
    <div class="flex py-3 px-3 border-b border-current justify-between">
      <div class="flex-center space-x-2">
        <p class="font-semibold text-current-500">
          {{ $t('USERMANAGEMENT_LABEL_DOMAIN') }}
        </p>
        <div class="flex-center p-1 rounded-full bg-current-50">
          <UserById :user-id="userId" avatar-class="w-5 h-5" class="text-sm" />
        </div>
      </div>
      <div class="flex-center">
        <span class="text-xs rounded-md py-1 px-2 border border-current-400">{{
          $t('COMMON_LABEL_TOTAL') +
          ': ' +
          domains.filter((el) => el?.checked).length
        }}</span>
      </div>
    </div>

    <div class="flex-1 min-h-0 p-1 text-sm bg-white overflow-hidden h-full">
      <div v-if="isLoading" class="flex-center space-x-3 mx-4 mt-8">
        <div class="w-8 p-2 bg-gray-200 animate-pulse rounded-lg"></div>
        <div class="w-full p-2 bg-gray-200 animate-pulse rounded-lg"></div>
      </div>
      <!-- add domain -->
      <DomainTreeCheckbox
        v-else-if="domains.find((el) => el.checked)"
        :user-id="userId"
        :domains="domains"
      />
      <div v-else>
        <syn-animation
          name="searchNotFound"
          stype="width: 100px; height: 100px"
          :label="$t('PROFILE_ALERT_NO_DOMAIN')"
        />
      </div>
    </div>
  </div>
</template>
