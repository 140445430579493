import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import HelpService from '@/application/services/contact-help/HelpService';

type StateLoadings = {
    video_special: boolean;
    list_folders: boolean;
    fqa: boolean;
    fqa_special: boolean;
};

type StateSupport = {
    _helpService: HelpService;
    isOpenPanelHelp: boolean;
    videoActive: object;
    folders: any;
    folderVideosActive: any;
    videoSpecialList: any;
    allFaqs: any;
    allTopics: any;
    allSpecialFaq: any;
    allFaqsByIds: any;
    allTopicsByIds: any;
    allSpecialFaqByIds: any;
    allWhatNews: any;
    loadings: StateLoadings;
};

export default defineStore({
    id: storeId.supportCustomer,
    state: () =>
        ({
            _helpService: HelpService.getInstance(),
            isOpenPanelHelp: false,
            videoActive: {},
            folders: [],
            folderVideosActive: [],
            videoSpecialList: [],
            allFaqs: [],
            allSpecialFaq: [],
            allWhatNews: [],
            allFaqsByIds: {},
            allTopicsByIds: {},
            allSpecialFaqByIds: {},
            loadings: {
                video_special: false,
                list_folders: false,
                fqa: false,
            },
        } as StateSupport),
    getters: {},
    actions: {
        setOpenPanelHelp(data) {
            this.isOpenPanelHelp = data;
        },
        setVideoActive(data) {
            this.videoActive = data;
        },
        async getPlaylistYoutubeSpecialFiles() {
            try {
                this.loadings.video_special = true;
                const res =
                    await this._helpService.getPlaylistYoutubeSpecialFiles();
                this.videoSpecialList = res['result'];
                this.loadings.video_special = false;
            } catch (e) {
                this.loadings.video_special = false;
            }
        },

        async getFilesVideo(folderId = '') {
            try {
                this.loadings.list_folders = true;
                const res = await this._helpService.getFilesVideo(folderId);
                if (!folderId) this.folders = res['result'] || [];
                else this.folderVideosActive = res['result'] || [];
                this.loadings.list_folders = false;
                return res['result'] || [];
            } catch (e) {
                this.loadings.list_folders = false;
                return [];
            }
        },

        async getSpecialFaqs() {
            try {
                this.loadings.fqa_special = true;
                const pageSize = 5;
                const res = await this._helpService.getSpecialFaqs(pageSize);
                this.allSpecialFaq = res['result']?.qnas?.map((o) => {
                    return {
                        ...o,
                        topicId: res['result']?.id,
                        topicName: res['result']?.topic,
                        visible: o?.visible,
                        isCollapse: false,
                    };
                });
                this.allSpecialFaqByIds = this.allSpecialFaq?.reduce(
                    (a, v) => ({ ...a, [v?.id]: v }),
                    {}
                );
            } catch (e) {
                console.log(e);
            }
        },

        async getAllFaqs() {
            try {
                this.loadings.fqa = true;
                const res = await this._helpService.getAllFaqs();
                this.allTopics = res['result'];
                res['result']?.forEach((topic) => {
                    this.allFaqs = [
                        ...this.allFaqs,
                        ...topic?.qnas?.map((o) => {
                            return {
                                ...o,
                                topicId: topic?.id,
                                topicName: topic?.topic,
                                visible: topic?.visible,
                                isCollapse: false,
                            };
                        }),
                    ];

                    this.allTopicsByIds[topic?.id] = topic;
                });
                this.allFaqsByIds = this.allFaqs?.reduce(
                    (a, v) => ({ ...a, [v?.id]: v }),
                    {}
                );

                this.loadings.fqa = false;
            } catch (e) {
                this.loadings.fqa = false;
            }
        },

        async getAllWhatNew() {
            try {
                const pageSize = 5;
                const res = await this._helpService.getAllWhatNew(pageSize);
                this.allWhatNews = res['result'];
            } catch (e) {
                console.log(e);
            }
        },
    },
});
