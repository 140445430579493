<template>
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g clip-path="url(#clip40_28)">
      <ellipse
        id="circle"
        cx="87.833374"
        cy="153.599854"
        rx="60.595245"
        ry="85.333313"
        fill-opacity="1.000000"
      />
      <ellipse
        id="circle"
        cx="427.166748"
        cy="153.599854"
        rx="60.595245"
        ry="85.333313"
        fill-opacity="1.000000"
      />
      <ellipse
        id="circle"
        cx="257.500000"
        cy="85.333252"
        rx="60.595245"
        ry="85.333313"
        fill-opacity="1.000000"
      />
      <path
        id="path"
        d="M366.57 324.26L366.57 494.93C366.59 506.34 362.55 512.03 354.45 512L160.54 512C152.44 512.03 148.4 506.34 148.42 494.93L148.42 324.26C148.42 308.42 150.58 293.18 154.88 278.54C159.19 263.91 165.32 250.99 173.27 239.79C181.23 228.58 190.4 219.95 200.79 213.89C211.19 207.83 222.01 204.8 233.26 204.8L281.73 204.8C292.98 204.8 303.8 207.83 314.2 213.89C324.59 219.95 333.77 228.58 341.72 239.79C349.67 250.99 355.8 263.91 360.11 278.54C364.41 293.18 366.57 308.42 366.57 324.26ZM129.53 276.66C123.83 274.26 118.01 273.06 112.07 273.06L63.59 273.06C55.55 273.06 47.83 275.23 40.4 279.56C32.98 283.89 26.42 290.05 20.74 298.06C15.06 306.06 10.68 315.29 7.61 325.74C4.53 336.19 3 347.08 3 358.4L3 494.93C3 506.31 7.03 512 15.11 512L126.27 512C124.88 506.48 124.18 500.79 124.19 494.93L124.19 324.26C124.19 307.64 126.06 291.65 129.53 276.66ZM388.72 512L499.88 512C507.96 512 512 506.31 512 494.93L512 358.4C512 347.08 510.46 336.19 507.38 325.74C504.31 315.29 499.93 306.06 494.25 298.06C488.57 290.05 482.01 283.89 474.59 279.56C467.17 275.23 459.44 273.06 451.4 273.06L402.92 273.06C396.85 273.06 391 274.32 385.46 276.66C388.93 291.65 390.81 307.64 390.81 324.26L390.81 494.93C390.81 500.8 390.09 506.57 388.72 512Z"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
      <circle
        id="circle"
        cx="362.000000"
        cy="362.000000"
        r="150.000000"
        fill-opacity="1.000000"
      />
      <path
        id="path"
        d="M362.05 212C320.64 212.04 285.29 226.69 256.01 255.96C226.72 285.23 212.05 320.57 212 361.97C212.07 403.37 226.74 438.71 256.02 467.98C285.3 497.25 320.64 511.92 362.05 512C403.44 511.92 438.78 497.25 468.04 467.97C497.3 438.69 511.95 403.36 512 361.97C511.95 320.57 497.29 285.24 468.02 255.97C438.74 226.7 403.45 212.04 362.05 212ZM362.05 239.25C395.97 239.18 424.91 251.14 448.89 275.12C472.87 299.11 484.82 328.06 484.73 361.97C484.79 395.88 472.83 424.82 448.84 448.8C424.86 472.78 395.91 484.74 362 484.69C328.08 484.71 299.14 472.74 275.16 448.75C251.18 424.77 239.22 395.83 239.26 361.92C239.23 328.01 251.21 299.08 275.2 275.12C299.19 251.15 328.14 239.2 362.05 239.25ZM361.84 293.62C352.65 293.69 348.14 298.31 348.31 307.5L348.31 348.34L307.52 348.34C299.07 349.37 293.63 354.14 294.08 362.64C294.53 371.14 299.01 375.46 307.52 375.6L348.31 375.6L348.31 416.44C348.31 425.54 352.86 430.09 361.97 430.09C371.07 430.09 375.63 425.54 375.63 416.44L375.63 375.65L416.47 375.65C425.57 375.64 430.11 371.09 430.11 362C430.11 352.9 425.57 348.35 416.47 348.34L375.63 348.34L375.63 307.5C375.8 298.14 371.21 293.51 361.84 293.62Z"
        fill-opacity="1.000000"
        fill="#4BB0B2"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>
