<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 645.46 645.46"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M631.477,502.744L378.572,88.973c-30.706-50.265-81.033-50.265-111.728,0L13.979,502.784     c-30.752,50.367-9.031,91.402,48.142,91.402v-0.034h521.186C640.536,594.146,662.229,553.066,631.477,502.744z M354.848,524.192     h-63.962V460.15h63.956L354.848,524.192L354.848,524.192z M357.312,269.028l-16.355,169.208h-36.232l0,0l-16.679-169.117v-78.273     h69.26L357.312,269.028L357.312,269.028z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
