import SynStepperItem from '@/ui/common/molecules/SynStepper/SynStepperItem';
import DayoffDetailClass from './DayoffDetailClass';
import { DayoffNextStatus } from '@/ui/components/dayoff/atoms/dayoff-ui-constant';
import DayoffService from '@/application/services/DayoffService';

export default class DayoffPresentDetailClass extends DayoffDetailClass {
    presentFlow: any;
    _dayoffService: DayoffService;

    constructor(data) {
        super(data);

        this.setPresentFlow();

        this._dayoffService = DayoffService.getInstance();
    }

    async changeStatus(method: Promise<any>) {
        try {
            const res = await method;
            const newRequest = res?.result;
            if (newRequest) {
                this.status = newRequest?.status || this.status;
                this.requestFlow = newRequest?.requestFlow || this.requestFlow;
            }
            this.setPresentFlow();
        } catch (error) {
            console.log('🚀 ~ accept ~ error', error);
        }
    }

    accept() {
        return this.changeStatus(this._dayoffService.acceptRequest(this.id));
    }

    reject(rejectNote) {
        return this.changeStatus(
            this._dayoffService.rejectRequest(this.id, {
                rejectNote: rejectNote,
            })
        );
    }

    cancel() {
        return this.changeStatus(this._dayoffService.cancelRequest(this.id));
    }

    agreeUnusedRequest() {
        return this.changeStatus(
            this._dayoffService.agreeUnusedRequest(this.id)
        );
    }

    disagreeUnusedRequest() {
        return this.changeStatus(
            this._dayoffService.disagreeUnusedRequest(this.id)
        );
    }

    close() {
        return this.changeStatus(this._dayoffService.closeRequest(this.id));
    }

    reopen() {
        return this.changeStatus(this._dayoffService.reopenRequest(this.id));
    }

    private setPresentFlow() {
        this.presentFlow = this.requestFlow.reduce((old, current, index) => {
            return {
                ...old,
                [index]: new SynStepperItem({
                    key: `${current.actionType}_${current.status}`,
                    label: current.status,
                    title: current.status,
                    isActive: true,
                    isFinished: true,
                    slotProps: {
                        ...current,
                    },
                }),
            };
        }, {});

        const nextStatus = DayoffNextStatus[this.status];

        if (nextStatus) {
            this.presentFlow[this.requestFlow.length] = new SynStepperItem({
                key: `NEXT_${nextStatus}`,
                label: nextStatus,
                title: nextStatus,
                isActive: false,
                slotProps: {},
            });
        }
    }
}
