<template>
  <div
    v-if="fileType?.startsWith($constants.FILE.TYPE.IMAGE)"
    class="h-16 w-16 overflow-hidden rounded border bg-white"
  >
    <img class="w-full object-cover" :src="fileSrc" />
  </div>
  <div
    v-else
    class="
      h-16
      w-48
      overflow-hidden
      rounded
      border
      bg-white
      flex
      items-center
      p-2
    "
    :title="file?.name"
  >
    <file-icon :file-type="fileType" class-name="h-8 w-8 mr-2"></file-icon>
    <div class="flex-1 text-sm text-overflow-hidden leading-4">
      {{ file?.name }}
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import FileIcon from './_commons/file-icon/FileIcon.vue';

export default defineComponent({
  components: { FileIcon },
  props: {
    file: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const fileSrc = computed(
      () => props.file.url || URL.createObjectURL(props.file)
    );

    return {
      fileType: props.file.contentType || props.file.type,
      fileSrc,
    };
  },
});
</script>
