<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';
import changeAssignComposable from '@/ui/modules/task/composables/change-assign-composable';
import settingStore from '@/store/setting';
import myProfileStore from '@/store/auth/my-profile';
import DepartmentById from '@/ui/modules/departments/components/DepartmentById.vue';
import userStore from '@/store/user';
import taskStore from '@/store/task';
import EnterCollaborator from '@/ui/modules/task/detail/assign/EnterCollaborator.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';

const props = withDefaults(
  defineProps<{
    groupId?: number;
    assigneeId?: number;
    creatorId?: number;
    collaborators?: any[];
    isEditMode?: boolean;
    totalShowItem?: number;
    readonly?: boolean;
    isNotCloseDropdownParent?: boolean;
  }>(),
  {
    groupId: undefined,
    assigneeId: undefined,
    creatorId: undefined,
    collaborators: undefined,
    totalShowItem: 5,
  }
);
const emit = defineEmits([
  'onCancel',
  'onSave',
  'onAdd',
  'update:isEditMode',
  'onRemove',
  'focus',
]);
const _settingStore = settingStore();
const _taskStore = taskStore();

const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);

const myDepartmentIds = computed(() => {
  const myId = myProfileStore().myProfile?.id;
  return myId ? userStore().allUserByIds[myId]?.departmentIds : [];
});

const roleCollaboratorId = computed<string>(
  () => _taskStore.roleCollaboratorId
);

const showMembers = computed<any[]>(() => {
  const collaboratorIds = [
    ...new Set(
      props.collaborators?.map((user) => user?.id || user?.memberUserId)
    ),
  ];
  return collaboratorIds?.map((userId) => {
    return {
      ...userStore().allUserByIds[userId],
    };
  });
});

watch(
  () => props.assigneeId,
  () => {
    formData.value.userId = props.assigneeId as any;
    initData();
  }
);
watch(
  () => props.groupId,
  () => {
    formData.value.groupId = props.groupId as any;
    initData();
  }
);

onMounted(() => {
  initData();

  if (departmentManagementEnabled.value && myDepartmentIds.value?.length == 0) {
    toggleSearchAll(true);
  }
});

const formData = ref({ groupId: 0, userId: 0 });

const {
  isSearchAll,
  assignedGroup,
  assignedUser,
  assignableUsers,
  initData: initUpdateAssignee,
  toggleSearchAll,
  isSearchOutsideGroup,
  toggleSearchOutsideGroup,
} = changeAssignComposable(formData);

const collaboratorUsers = computed<any[]>(() => {
  return assignableUsers.value?.filter(
    (user) =>
      user?.id !== assignedUser.value?.id && user?.id !== props.creatorId
  );
});

const synDepartmentAssignRef = ref<any>(null);
const initData = (defaultData?) => {
  const data = defaultData
    ? { groupId: defaultData?.groupId, assigneeId: defaultData?.assigneeId }
    : props;
  formData.value = {
    groupId: data?.groupId,
    userId: data?.assigneeId,
  };

  initUpdateAssignee(formData.value);
};

const isShowDepartment = computed(() => {
  return departmentManagementEnabled.value;
});
const onPushUser = (user) => {
  if (!user || !user?.id) return;

  const collaborators = [
    { ...user, memberUserId: user?.id, memberRoleId: roleCollaboratorId.value },
  ];
  emit('onAdd', collaborators);
};

const onRemoveUser = (user) => {
  const userId = user?.id || user?.memberUserId;
  emit('onRemove', userId);
};

const isSelectMore = ref<boolean>(false);

const onOpenEditMode = () => {
  isSelectMore.value = true;
  emit('update:isEditMode', true);
  setTimeout(() => {
    synDepartmentAssignRef.value?.onOpenDropdown();
  }, 100);

  emit('focus');
};
const onCloseEditMode = () => {
  isSelectMore.value = false;
  if (doNotCheckFunctionPermission.value > 0)
    doNotCheckFunctionPermission.value += 1;
  emit('update:isEditMode', false);
};

const doNotCheckFunctionPermission = ref<number>(0);
const onContinue = () => {
  doNotCheckFunctionPermission.value += 1;
  onOpenEditMode();
};

defineExpose({
  initData,
  onCloseEditMode,
});
</script>
<template>
  <div
    :key="doNotCheckFunctionPermission"
    v-permission-function="{
      functionCode:
        doNotCheckFunctionPermission % 2 == 1
          ? ''
          : ALL_FUNCTIONS.TASK.MULTIPLE_ASSIGNEE,
      onContinue,
    }"
    class="overflow-auto small-scrollbar flex items-center"
    :class="{
      'do-not-close-dropdown': isNotCloseDropdownParent,
    }"
    style="min-height: 2.5rem; max-height: 20rem"
  >
    <template v-if="!isSelectMore">
      <div class="flex-1 flex items-center">
        <SynListAvatar
          :users="showMembers"
          :custom-class="'w-7 h-7 text-sm'"
          :max-length="totalShowItem"
          :can-be-removed="!readonly"
          @remove-item="onRemoveUser"
        />
      </div>
      <button
        v-if="!readonly"
        v-vig-tooltip="{
          content: $t('COMMON_LABEL_ADD_COLLABORATOR'),
        }"
        class="
          text-xl
          font-medium
          w-6
          h-6
          rounded-full
          flex-center
          cursor-pointer
          outline-none
          focus:outline-none
          text-current-700
          hover:bg-current-100
          z-10
        "
        @click.stop="onOpenEditMode"
      >
        <SynIcon name="MultipleAssign" custom-class="w-5 h-5 fill-gray-500" />
      </button>
    </template>
    <template v-else>
      <EnterCollaborator
        ref="synDepartmentAssignRef"
        input-class="text-xs"
        removeable
        :is-show-department="
          isShowDepartment && (isSearchAll || isSearchOutsideGroup)
        "
        :placeholder="$t('COMMON_LABEL_SELECT_USER')"
        :collaborators="showMembers"
        :data="collaboratorUsers"
        :is-not-close-dropdown-parent="isNotCloseDropdownParent"
        @chosen="onPushUser"
        @reset="onRemoveUser"
        @remove="onRemoveUser"
      >
        <template #header>
          <template v-if="assignedGroup?.id">
            <div
              v-if="!assignedGroup?.isPrivate"
              class="p-2 flex items-center"
              :class="{
                'do-not-close-dropdown': isNotCloseDropdownParent,
              }"
            >
              <SynRadioTag
                v-model="isSearchOutsideGroup"
                :value="false"
                name="task-create-form_schedule"
                radio-name="task-create-form_priority"
                class="flex-1 w-2/3 mr-1 mb-0.5 mt-0.5 relative"
                @change="toggleSearchOutsideGroup"
              >
                <SynTag
                  size="circle-small"
                  custom-class="py-1 px-2"
                  :class="{
                    'do-not-close-dropdown': isNotCloseDropdownParent,
                  }"
                >
                  <span
                    class="text-overflow-hidden"
                    :class="{
                      'do-not-close-dropdown': isNotCloseDropdownParent,
                    }"
                  >
                    {{ assignedGroup?.name }}
                  </span>
                  (G)
                </SynTag>
              </SynRadioTag>
              <SynRadioTag
                v-model="isSearchOutsideGroup"
                :value="true"
                :label="'Department'"
                name="task-create-form_schedule"
                radio-name="task-create-form_priority"
                class="mr-1 mb-0.5 mt-0.5 relative"
                @change="toggleSearchOutsideGroup"
              >
                <SynTag size="circle-small" custom-class="py-1 px-2">
                  <span
                    :class="{
                      'do-not-close-dropdown': isNotCloseDropdownParent,
                    }"
                  >
                    {{ $t('COMMON_LABEL_OTHER') }}
                  </span>
                </SynTag>
              </SynRadioTag>
            </div>
          </template>
          <div
            v-else-if="isShowDepartment"
            class="p-2 flex items-center"
            :class="{
              'do-not-close-dropdown': isNotCloseDropdownParent,
            }"
          >
            <SynRadioTag
              v-if="myDepartmentIds?.length > 0"
              v-model="isSearchAll"
              :value="false"
              name="task-create-form_schedule"
              radio-name="task-create-form_priority"
              class="flex-1 w-2/3 mr-1 mb-0.5 mt-0.5 relative"
              :class="{
                'do-not-close-dropdown': isNotCloseDropdownParent,
              }"
              @change="toggleSearchAll"
            >
              <SynTag size="circle-small" custom-class="py-1 px-2">
                <DepartmentById
                  :department-id="myDepartmentIds[0]"
                  is-hidden-avatar
                />(D)
              </SynTag>
            </SynRadioTag>
            <SynRadioTag
              v-model="isSearchAll"
              :value="true"
              :label="'Department'"
              name="task-create-form_schedule"
              radio-name="task-create-form_priority"
              class="mr-1 mb-0.5 mt-0.5 relative"
              :class="{
                'do-not-close-dropdown': isNotCloseDropdownParent,
              }"
              @change="toggleSearchAll"
            >
              <SynTag size="circle-small" custom-class="py-1 px-2">
                <span>
                  {{ $t('COMMON_LABEL_OTHER') }}
                </span>
              </SynTag>
            </SynRadioTag>
          </div>
        </template>
      </EnterCollaborator>
    </template>
  </div>
</template>
