import dayjs from 'dayjs';
import {
    arrayOrderBy,
    styleByPercent,
    STYLE_BY_STATUS,
} from '@/ui/hooks/commonFunction';
import {
    CapacityMember,
    ERemainingWorkforceStatus,
} from '@/store/dashboard/overview-monitor-store';

export const EXTEND_TIME_SECOND = 30 * 60;

export const getAllMemberStartedToday = (
    originData: CapacityMember[]
): CapacityMember[] => {
    // console.log('🚀 Hyrin ~ originData', originData);
    const currentHours = new Date().getHours();

    const overwriteNotStartMembers = originData?.map((member) => {
        const workingStatus: ERemainingWorkforceStatus = member.startedTime
            ? member.lastState == 'offline'
                ? ERemainingWorkforceStatus.stopped
                : ERemainingWorkforceStatus.started
            : currentHours > 9 || member.intentTime == 0
            ? ERemainingWorkforceStatus.offToday
            : ERemainingWorkforceStatus.notYetStated;

        const memberResult = {
            ...member,
            workingStatus,
        };
        const styleData = colorProcessByWorkingStatus(memberResult);
        const { workingTime, realIntentTime } =
            getRemainWorkingTimeByUser(memberResult);

        return {
            ...memberResult,
            styleData,
            workingTime,
            realIntentTime,
            remainPercent: getRemainWorkingforcePercent(
                workingTime,
                realIntentTime
            ),
        };
    });
    // console.log('🚀 Hyrin ~ overwriteNotStartMembers', overwriteNotStartMembers);
    return arrayOrderBy(
        overwriteNotStartMembers,
        [
            (item) => {
                return item?.workingStatus == ERemainingWorkforceStatus.started
                    ? 1
                    : item?.workingStatus == ERemainingWorkforceStatus.stopped
                    ? 2
                    : item?.workingStatus ==
                      ERemainingWorkforceStatus.notYetStated
                    ? 3
                    : 4;
            },
            'remainPercent',
            'realIntentTime',
            'startedTime',
        ],
        ['asc', 'desc', 'desc', 'desc']
    ) as CapacityMember[];
};
export const getRemainWorkingTimeByUser = (userData: CapacityMember) => {
    if (userData.workingStatus === ERemainingWorkforceStatus.offToday)
        return { workingTime: 0, realIntentTime: 0 };

    const workingTime =
        (userData.timeWorking || 0) +
        (userData?.lastState == 'online'
            ? dayjs().diff(userData.lastCheckIn, 'second')
            : 0);

    if (userData.workingStatus === ERemainingWorkforceStatus.stopped)
        return { workingTime, realIntentTime: workingTime };

    const originIntentTime = userData.intentTime || EXTEND_TIME_SECOND;
    let realIntentTime = originIntentTime;

    if (workingTime - originIntentTime >= 0)
        realIntentTime = workingTime + EXTEND_TIME_SECOND;

    return { workingTime, realIntentTime };
};

export const getRemainWorkingforcePercentByUser = (
    userData: CapacityMember
) => {
    // if (userData?.dayOffInfo?.length > 0) return 0;
    // if (userData?.startedTime == '0001-01-01T00:00:00' || !userData?.startedTime)
    //   return 100;

    const { workingTime, realIntentTime } =
        getRemainWorkingTimeByUser(userData);
    return getRemainWorkingforcePercent(workingTime, realIntentTime);
};

export const getRemainWorkingforcePercent = (used, origin = 1) => {
    if (origin <= 0) return 0;
    return Math.max(0, Math.floor(((origin - used) / origin) * 100));
};

export const colorProcessByWorkingStatus = (item: CapacityMember) => {
    if (item?.workingStatus == ERemainingWorkforceStatus.offToday)
        return STYLE_BY_STATUS.hasDayOff;

    if (item?.workingStatus == ERemainingWorkforceStatus.stopped)
        return STYLE_BY_STATUS.stopped;

    if (item?.workingStatus == ERemainingWorkforceStatus.notYetStated)
        return STYLE_BY_STATUS.notYetStatedWorking;

    // Started
    return styleByPercent(item.remainPercent);
};
