<template>
  <svg
    v-if="!isActive"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 469.46 469.56"
  >
    <g id="Layer_2" data-name="Layer 2">
      <path
        d="M400.54,68.84c-91.45-91.7-239.93-91.91-331.63-.45a234.49,234.49,0,0,0-46.55,266l-13,64.71a51.89,51.89,0,0,0,61,61L135,447.15a232.76,232.76,0,0,0,99.64,22.34,240.16,240.16,0,0,0,44.29-4.14A236.39,236.39,0,0,0,466.36,272.46,233.83,233.83,0,0,0,400.54,68.84ZM433,267.3c-17.8,109.44-121,183.69-230.42,165.87a200.83,200.83,0,0,1-59.08-19.31,22.22,22.22,0,0,0-14.61-2l-75,15a9.7,9.7,0,0,1-11.39-7.63h0a9.52,9.52,0,0,1,0-3.76l15-75.07a22.14,22.14,0,0,0-2-14.46C5.09,227.1,44.24,106.15,143,55.71a201,201,0,0,1,59-19.35C311.76,18.5,415.18,93,433,202.67a205.45,205.45,0,0,1,0,64.68Z"
        transform="translate(0.01 0.07)"
      />
      <circle cx="160.49" cy="234.84" r="21.33" />
      <circle
        cx="234.72"
        cy="234.77"
        r="21.33"
        transform="translate(-34.61 428.92) rotate(-80.78)"
      />
      <circle cx="308.97" cy="234.84" r="21.33" />
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 469.44 469.47"
  >
    <path
      d="M547.18,249A234.5,234.5,0,0,0,169,514.53l-13,64.71a51.89,51.89,0,0,0,61,61l64.62-12.93a232.76,232.76,0,0,0,99.64,22.34,240.16,240.16,0,0,0,44.29-4.14A236.38,236.38,0,0,0,613,452.62,233.82,233.82,0,0,0,547.18,249ZM307.12,436.27a21.33,21.33,0,1,1,21.33-21.33A21.33,21.33,0,0,1,307.12,436.27Zm74.23,0a21.33,21.33,0,1,1,21.34-21.33A21.33,21.33,0,0,1,381.35,436.27Zm74.24,0a21.33,21.33,0,1,1,21.33-21.33A21.33,21.33,0,0,1,455.59,436.27Z"
      transform="translate(-146.64 -180.16)"
    />
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
