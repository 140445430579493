<script setup lang="ts">
import { ref } from 'vue';
import { ResourceTab } from '@/domain/enums/resource-enums';
import ToolsSidebar from '@/ui/modules/tools/tools-sidebar/ToolsSidebar.vue';
import GedPage from '@/ui/modules/ged/ged-page/GedPage.vue';

const activeTab = ref();
</script>

<template>
  <div class="h-full w-full flex">
    <ToolsSidebar
      v-model:active-tab="activeTab"
      :class="{ hidden: activeTab?.id === ResourceTab.GoogleDocuments }"
    />

    <div class="flex-1 overflow-hidden">
      <GedPage v-if="activeTab?.id === ResourceTab.Files" class="pt-1 shadow" />
    </div>
  </div>
</template>
