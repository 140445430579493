<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 358.06 130.34"
  >
    <g>
      <polygon
        class="cls-4"
        points="68.04 31.48 59.43 37.83 59.43 104.71 76.65 104.71 76.65 37.83 68.04 31.48"
      />
      <polygon
        class="cls-3"
        points="76.65 35.14 76.65 28.85 68.04 22.35 59.43 28.85 59.43 35.16 59.47 35.16 68.08 28.66 76.65 35.14"
      />
      <polygon
        class="cls-5"
        points="76.65 26.18 76.65 19.8 68.04 13.21 59.43 19.8 59.43 26.21 59.47 26.21 68.08 19.62 76.65 26.18"
      />
    </g>
    <g>
      <path
        class="cls-4"
        d="M257.76,104.38l-14.75-16.35c-3.36-1.15-6.31-3.27-8.47-6.08-2.26-3.01-3.52-6.65-3.6-10.41-.15-3.5,.67-6.98,2.38-10.04,1.42-2.56,3.45-4.73,5.92-6.3,.1-.07,.22-.13,.32-.2l.2-.12c1.95-1.19,4.14-1.93,6.41-2.17,7.03-.74,11.89,3.55,12.82,4.41,2.73,2.56,4.56,5.93,5.21,9.61l15.9,15.1c1.15-3.59,1.73-7.33,1.72-11.1,0-19.46-15.22-35.24-34.04-35.24s-34.04,15.78-34.04,35.24,15.24,35.24,34.04,35.24c2.46-.03,4.91-.29,7.31-.8,1.15-.3,2.09-.6,2.69-.8Z"
      />
      <path
        class="cls-4"
        d="M264.17,66.73c.28,1.32,.42,2.66,.4,4.01,0,1.49-.29,8.54-6.01,13.62-3,2.65-6.81,4.21-10.81,4.41-.64,0-1.3-.05-1.3-.05-1.17-.1-2.32-.33-3.44-.68l14.75,16.35c5.46-1.83,10.39-4.94,14.39-9.08,3.61-3.85,6.31-8.45,7.91-13.47l-15.9-15.1Z"
      />
      <path
        class="cls-1"
        d="M263.37,63.52c.84,2.31,1.25,4.75,1.2,7.21-.03,1.66-.23,7.21-4.41,12.02-1.89,2.09-4.21,3.73-6.81,4.81-1.77,.77-3.68,1.17-5.61,1.2-.11,0-.64,0-1.3-.05-2.51-.26-4.94-1.07-7.11-2.35l15.22,18.82c12.93-2.75,23.08-12.75,26.03-25.63l-17.22-16.02Z"
      />
      <path
        class="cls-2"
        d="M264.57,69.93c.07,1.61-.06,3.23-.4,4.81-.44,2.13-1.26,4.16-2.4,6.01-.32,.51-4.59,7.02-12.42,8.01-1.33,.17-2.68,.17-4.01,0l13.62,15.22c5.19-1.97,9.85-5.13,13.62-9.21,2.91-3.16,5.21-6.83,6.81-10.81l-14.82-14.02Z"
      />
      <path
        class="cls-5"
        d="M230.52,71.07c-.02,1.35,.11,2.69,.4,4.01,.41,1.7,1.08,3.32,2,4.81,1.43,2.37,3.34,4.42,5.61,6.01,.88,.62,1.82,1.15,2.8,1.6,2.49,1.18,5.28,1.59,8.01,1.2,2.72-.32,5.33-1.28,7.61-2.8,2.88-1.98,5.11-4.76,6.41-8.01,1.55-3.81,1.83-8.03,.8-12.02-.66-2.64-1.89-5.1-3.6-7.21-2.3-2.73-5.37-4.68-8.81-5.61-3.17-.81-6.52-.67-9.61,.4-6.17,2.18-8.88,7.35-9.61,8.81-1.34,2.74-2.03,5.76-2,8.81Z"
      />
    </g>
    <path
      class="cls-4"
      d="M8.81,11.39H25.63v26.83h26.03v16.82H25.63v20.83c.51,5.97,4.93,10.88,10.81,12.02h15.22v17.22l-16.42-.4c-14.34-1.59-25.48-13.24-26.43-27.64v-22.03H.4c-.13-5.61-.27-11.21-.4-16.82H8.81V11.39Z"
    />
    <path
      class="cls-4"
      d="M134.1,63.86l12.42-12.82c-.88-1.31-10.86-15.65-28.04-15.62-12.38,.02-20.57,7.49-22.83,9.61-1.71,1.6-10.81,10.47-11.21,24.83-.09,2.99,0,13.08,7.21,22.43,1.36,1.76,10.73,13.49,26.83,13.62,17.26,.14,27.13-13.14,28.04-14.42l-12.42-12.42c-.5,.97-3.94,7.4-11.61,9.21-5.56,1.31-11.81-.17-16.02-4.41-4.52-4.55-4.74-10.3-4.81-12.02-.17-4.3,1.1-8.52,3.6-12.02,.8-1.07,5.66-7.72,13.62-7.61,6.54,.09,12.64,4.72,15.22,11.61Z"
    />
    <path
      class="cls-4"
      d="M163.09,11.39h16.82v26.83h26.03v16.82h-26.03v20.83c.51,5.97,4.93,10.88,10.81,12.02h15.22v17.22l-16.42-.4c-14.34-1.59-25.48-13.24-26.43-27.64v-22.03h-8.41c-.13-5.61-.27-11.21-.4-16.82h8.81V11.39Z"
    />
    <path
      class="cls-4"
      d="M358.05,70.67c-.03-3.53-.14-15.91-10.01-25.63-1.8-1.77-10.51-9.94-24.03-9.61-13.44,.33-21.72,8.81-23.23,10.41l-6.81-8.01h-4.41v92.52h17.22v-30.04c5.05,3.61,11.02,5.7,17.22,6.01,14.82,.63,24.29-9.7,25.63-11.21,8.37-9.44,8.45-20.51,8.41-24.43Zm-18.28,7.21c-1.29,3.25-3.53,6.03-6.41,8.01-2.28,1.52-4.89,2.48-7.61,2.8-1.33,.17-2.68,.17-4.01,0-1.38-.22-2.73-.62-4.01-1.2-.98-.45-1.92-.98-2.8-1.6-2.27-1.59-4.18-3.64-5.61-6.01-.92-1.48-1.6-3.11-2-4.81-.29-1.31-.42-2.66-.4-4.01-.02-3.05,.66-6.07,2-8.81,.74-1.46,3.45-6.63,9.61-8.81,3.1-1.08,6.44-1.21,9.61-.4,3.44,.92,6.52,2.88,8.81,5.61,1.71,2.11,2.94,4.57,3.6,7.21,1.04,3.99,.75,8.2-.8,12.02Z"
    />
    <path
      class="cls-3"
      d="M268.73,9.68c0,.13,0,.25-.03,.38l-2.82,11.29c-.14,.57-.65,.97-1.24,.97l-16.94,.09h0s-16.94,0-16.94,0c-.59,0-1.1-.4-1.24-.97l-2.82-11.33c-.03-.13-.04-.26-.03-.39-1.09-.34-1.89-1.37-1.89-2.57,0-1.49,1.21-2.69,2.69-2.69s2.69,1.21,2.69,2.69c0,.84-.38,1.59-.98,2.08l3.53,3.56c.89,.9,2.13,1.42,3.4,1.42,1.5,0,2.93-.71,3.83-1.9l5.81-7.7c-.49-.49-.79-1.16-.79-1.9,0-1.49,1.21-2.69,2.69-2.69s2.69,1.21,2.69,2.69c0,.72-.29,1.37-.75,1.86,0,0,0,0,0,0l5.77,7.72c.9,1.2,2.33,1.92,3.84,1.92,1.28,0,2.48-.5,3.39-1.4l3.56-3.56c-.61-.49-1.01-1.25-1.01-2.1,0-1.49,1.21-2.69,2.69-2.69s2.69,1.21,2.69,2.69c0,1.17-.75,2.17-1.8,2.54Zm-3.13,16.75c0-.71-.57-1.28-1.28-1.28h-33.19c-.71,0-1.28,.57-1.28,1.28v3.08c0,.71,.57,1.28,1.28,1.28h33.19c.71,0,1.28-.57,1.28-1.28v-3.08Z"
    />
  </svg>
</template>
<style scoped>
.cls-1 {
  opacity: 0.27;
}

.cls-1,
.cls-2 {
  fill: #015d5d;
  isolation: isolate;
}

.cls-2 {
  opacity: 0.18;
}

.cls-3 {
  fill: #fbb430;
}

.cls-4 {
  fill: #008d8d;
}

.cls-5 {
  fill: #f1cb15;
}
</style>
