<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import SingleOtpInput from './SingleOtpInput.vue';
const actionKeys = {
  backspace: 'Backspace',
  delete: 'Delete',
  enter: 'Enter',
  leftArrow: 'ArrowLeft',
  rightArrow: 'ArrowRight',
};

const props = withDefaults(
  defineProps<{
    modelValue: number | string;
    numInputs: number;
    separator: string;
    inputClasses: string;
    inputType: 'number' | 'tel' | 'password' | 'text';
    errorMessage: string;
    shouldAutoFocus: boolean;
    isClear: boolean;
    isAutoUppercase: boolean;
  }>(),
  {
    numInputs: 4,
    shouldAutoFocus: true,
    inputType: 'number',
    isAutoUppercase: false,
  }
);

const emit = defineEmits([
  'update:modelValue',
  'onChange',
  'onComplete',
  'onEnter',
]);

onMounted(() => {
  otp.value = props.modelValue ? props.modelValue?.toString().split('') : [];

  activeInput.value = props.modelValue
    ? props.modelValue?.toString().length - 1
    : 0;
});

watch(
  () => props.isClear,
  (newVal) => {
    // watch it
    if (newVal) {
      otp.value = [];
      activeInput.value = 0;
    }
  }
);

const activeInput = ref(0);
const otp = ref([] as any[]);
const oldOtp = ref([] as any[]);
const isInputComplete = computed(
  () => otp.value.join('').length === props.numInputs
);

const handleOnFocus = (index) => {
  activeInput.value = index;
};
const handleOnBlur = () => {
  activeInput.value = -1;
};
// Helper to return OTP from input
const checkFilledAllInputs = () => {
  if (isInputComplete.value) {
    return emit('onComplete', otp.value.join(''));
  }
  return 'Wait until the user enters the required number of characters';
};
// Focus on input by index
const focusInput = (input) => {
  activeInput.value = Math.max(Math.min(props.numInputs - 1, input), 0);
};
// Focus on next input
const focusNextInput = () => {
  focusInput(activeInput.value + 1);
};

const onUpdateModelValue = () => {
  const newValue = props.isAutoUppercase
    ? otp.value.join('')?.toUpperCase()
    : otp.value.join('');
  console.log('🚀 Hyrin ~ onUpdateModelValue ~ newValue', newValue);
  emit('onChange', newValue);
  emit('update:modelValue', newValue);
};

// Focus on previous input
const focusPrevInput = () => {
  focusInput(activeInput.value - 1);
};
// Change OTP value at focused input
const changeCodeAtFocus = (value) => {
  oldOtp.value = Object.assign([], otp.value);
  // this.$set(otp.value, activeInput.value, value);
  otp.value[activeInput.value] = value;
  if (oldOtp.value.join('') !== otp.value.join('')) {
    onUpdateModelValue();
    checkFilledAllInputs();
  }
};
// Handle pasted OTP
const handleOnPaste = (event) => {
  event.preventDefault();
  const pastedData = event.clipboardData
    .getData('text/plain')
    .slice(0, props.numInputs - activeInput.value)
    .split('');
  if (props.inputType === 'number' && !pastedData.join('').match(/^\d+$/)) {
    return 'Invalid pasted data';
  }
  // Paste data from focused input onwards
  const currentCharsInOtp = otp.value.slice(0, activeInput.value);
  const combinedWithPastedData = currentCharsInOtp.concat(pastedData);
  // this.$set(this, "otp", combinedWithPastedData.slice(0, props.numInputs));
  otp.value = combinedWithPastedData.slice(0, props.numInputs);
  focusInput(combinedWithPastedData.slice(0, props.numInputs).length);

  onUpdateModelValue();

  return checkFilledAllInputs();
};
const handleOnChange = (value) => {
  if (value === '' || value === null) return;
  changeCodeAtFocus(value);
  focusNextInput();
};
// const clearInput = () => {
//   if (otp.value.length > 0) {
//     emit('onChange', '');
//   }
//   otp.value = [];
//   activeInput.value = 0;
// };
// Handle cases of backspace, delete, left arrow, right arrow
const handleOnKeyDown = (event) => {
  switch (event.key) {
    case actionKeys.backspace:
      event.preventDefault();
      changeCodeAtFocus('');
      focusPrevInput();
      break;
    case actionKeys.delete:
      event.preventDefault();
      changeCodeAtFocus('');
      break;
    case actionKeys.leftArrow:
      event.preventDefault();
      focusPrevInput();
      break;
    case actionKeys.rightArrow:
      event.preventDefault();
      focusNextInput();
      break;
    case actionKeys.enter:
      if (!isInputComplete.value) return;
      emit('onEnter');

      break;
    default:
      break;
  }
};
</script>

<template>
  <div class="flex justify-around space-x-3 py-2">
    <!--    To turn off autocomplete when otp-input is password-->
    <input
      v-if="inputType === 'password'"
      autocomplete="off"
      name="hidden"
      type="number"
      style="display: none"
    />
    <SingleOtpInput
      v-for="(item, i) in numInputs"
      :key="item"
      :focus-on="activeInput === i"
      :value="otp[i]"
      :separator="separator"
      :input-type="inputType"
      :input-classes="inputClasses"
      :is-last-child="i === numInputs - 1"
      :should-auto-focus="shouldAutoFocus"
      @on-change="handleOnChange"
      @on-keydown="handleOnKeyDown"
      @on-paste="handleOnPaste"
      @on-focus="handleOnFocus(i)"
      @on-blur="handleOnBlur"
    />
  </div>
  <div
    v-if="errorMessage"
    class="
      flex
      items-center
      font-medium
      tracking-wide
      text-red-500 text-xs
      mt-1
      ml-1
    "
  >
    {{ errorMessage }}
  </div>
</template>
