<script setup lang="ts">
import { covertSnakeCaseToCamelCase } from '@/ui/hooks/commonFunction';
import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';

const props = defineProps<{
  beforeUpdate: any;
  afterUpdate: any;
}>();

const beforeActivity = computed(() => {
  return covertSnakeCaseToCamelCase(props.beforeUpdate);
});
const afterActivity = computed(() => {
  return covertSnakeCaseToCamelCase(props.afterUpdate);
});
const myTimezone = computed(() => myProfileStore().timezone);
</script>

<template>
  <div
    v-if="afterActivity || beforeActivity"
    class="flex items-center justify-between"
  >
    <div class="w-1/2 flex items-center justify-between pr-2">
      <SynTag
        class="border flex items-center space-x-2"
        :class="
          beforeActivity?.scheduleTime !== null
            ? 'bg-orange-50 text-red-500'
            : ''
        "
        size="small"
      >
        <template v-if="beforeActivity?.scheduleTime">
          <SynLabelDateTime2
            v-if="beforeActivity?.scheduleByHour"
            format="date_time"
            :datetime="beforeActivity?.scheduleTime"
            :timezone="beforeActivity?.scheduleTimezone || myTimezone"
          />
          <SynLabelDateTime
            v-else
            format="date"
            :datetime="beforeActivity?.scheduleTime"
          />
        </template>

        <span v-else>
          {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') }}
        </span>
      </SynTag>
      <span>→</span>
    </div>
    <div class="w-1/2 flex justify-start pl-4">
      <SynTag
        class="border flex items-center space-x-2"
        :class="
          afterActivity?.scheduleTime !== null
            ? 'bg-orange-50 text-red-500'
            : ''
        "
        size="small"
      >
        <template v-if="afterActivity?.scheduleTime">
          <SynLabelDateTime2
            v-if="afterActivity?.scheduleByHour"
            format="date_time"
            :datetime="afterActivity?.scheduleTime"
            :timezone="afterActivity?.scheduleTimezone || myTimezone"
          />
          <SynLabelDateTime
            v-else
            format="date"
            :datetime="afterActivity?.scheduleTime"
          />
        </template>
        <span v-else>
          {{ $t('TASK_DETAIL_LABEL_NO_SCHEDULE') }}
        </span>
      </SynTag>
    </div>
  </div>
</template>
