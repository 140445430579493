<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 25 25"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g
        id="Coffee_Break"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Coffee Break"
      >
        <path
          d="m8.973 11.4h-2.889a.5.5 0 0 1 0-1h2.389v-3.313a.5.5 0 0 1 1 0v3.813a.5.5 0 0 1 -.5.5z"
          class=""
        ></path>
        <path
          d="m18.174 22.039h-4.848a2.5 2.5 0 0 1 -2.5-2.5v-8.154a.5.5 0 0 1 .5-.5h8.848a.5.5 0 0 1 .5.5v8.154a2.5 2.5 0 0 1 -2.5 2.5zm-6.348-10.154v7.654a1.5 1.5 0 0 0 1.5 1.5h4.848a1.5 1.5 0 0 0 1.5-1.5v-7.654z"
          class=""
        ></path>
        <path
          d="m21.491 18.957h-1.32a.5.5 0 0 1 0-1h1.32a.5.5 0 0 0 .5-.5v-2.01a.5.5 0 0 0 -.5-.5h-1.32a.5.5 0 0 1 0-1h1.32a1.5 1.5 0 0 1 1.5 1.5v2.01a1.5 1.5 0 0 1 -1.5 1.5z"
          class=""
        ></path>
        <path
          d="m14.837 10.343a.5.5 0 0 1 -.5-.5v-1.611a2.5 2.5 0 0 1 2.5-2.5h1.844a.5.5 0 0 0 .5-.5 2.273 2.273 0 0 1 2.271-2.271h1.039a.5.5 0 0 1 0 1h-1.039a1.272 1.272 0 0 0 -1.271 1.271 1.5 1.5 0 0 1 -1.5 1.5h-1.844a1.5 1.5 0 0 0 -1.5 1.5v1.611a.5.5 0 0 1 -.5.5z"
          class=""
        ></path>
        <path
          d="m19.044 8.337h-2.286a.5.5 0 0 1 0-1h2.286a1.967 1.967 0 0 0 1.965-1.965.5.5 0 0 1 1 0 2.968 2.968 0 0 1 -2.965 2.965z"
          class=""
        ></path>
        <path
          d="m8.969 17.861a6.965 6.965 0 0 1 0-13.93 6.858 6.858 0 0 1 4.765 1.909.5.5 0 1 1 -.691.722 5.863 5.863 0 0 0 -4.074-1.631 5.965 5.965 0 1 0 .062 11.93 1.305 1.305 0 0 0 .216-.013.5.5 0 1 1 .164.986 2.624 2.624 0 0 1 -.442.027z"
          class=""
        ></path>
        <path
          d="m21.388 22.039h-11.276a.5.5 0 0 1 0-1h11.276a.5.5 0 1 1 0 1z"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
