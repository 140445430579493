/* eslint-disable no-unused-vars */
export enum TaskFilterOptions {
    Default = 0,

    LivingTasks = 1,

    JustfinishedTasks = 2,

    OldClosedTasks = 3,

    Custom = 4,
}

export enum TaskLife {
    Created = 0,
    InProcess = 1,
    Pending = 2,
    Finished = 3,
    Archived = 4,
    Cancelled = 5,
    Duplicated = 6,
    Todo = 7,
    Draft = 8,
}

export enum Situation {
    Exclusive = 0,

    Principal = 1,

    Secondary = 2,
}

export enum Priority {
    Low = 2,

    Normal = 1,

    High = 0,
}

export enum ScheduleDayOptions {
    OnDay = 0,

    D1 = 1,

    D2 = 2,

    D3 = 3,

    OtherTime = 4,

    Nothing = 5,
}

export enum ScheduleOtherTimeOptions {
    OneWeek = 1,

    TwoWeeks = 2,

    ThreeWeeks = 3,

    OneMonth = 4,

    TwoMonths = 5,

    ThreeMonths = 6,

    SpecificDate = 99,
}

export enum PlanTypeEnum {
    Nothing = 0,

    ToDo = 1,

    ToFinish = 2,
}

export enum TaskActionType {
    Finished = 1,
    Edit = 2,
    AddNew = 3,
    Urgent = 4,
    Delay = 5,
    Planned = 6,
    ChangePriority = 7,
    ChangeAssignee = 8,
    Reopen = 9,
    Delete = 10,
    MoveOut = 11,
    MoveIn = 12,
    Assigned = 13,
    // new
    Mention = 14,
    ChangeName = 15,
    ChangeDescription = 16,
    ChangeStatus = 17,
    ChangeDeadline = 18,
    ChangeTodo = 19,
    ChangeFinish = 20,
    ChangeAttachments = 21,
    ChangePrivate = 22,
    ChangeDomain = 23,
    ChangeNotes = 24,
    Comment = 25,
    MentionComment = 26,
    EditComment = 27,
    EditCommentAttachments = 28,
    PinnedComment = 29,
    DeleteComment = 30,
    RepeatGenerate = 31,
    Clone = 32,
    AddCollaborator = 33,
    RemoveCollaborator = 34,
    ChangeTodoList = 35,
    Pinned = 36,
    Unpinned = 37,

    WorkflowAttachToTask = 40,
    WorkflowStepComplete = 41,
    WorkflowStepStart = 42,

    ApprovalRequest = 45,
    ApprovalRequestCancel = 46,
    ApprovalRequestApprove = 47,
    ApprovalRequestReject = 48,

    ApproverRemoved = 49,
    ApproverAdded = 50,
    SubTaskAdded = 51,
    SubTaskRemoved = 52,
    ParentTaskAdded = 53,
    ParentTaskRemoved = 54,
}

export enum TaskListTab {
    MyList = 1,
    MyAssign = 2,
    Resources = 3,
    Profile = 4,
    Management = 5,
    Statistic = 6,
    MyRelated = 7,
    WaitingApprovalTasks = 8,
}

export enum TaskSharingStatus {
    UnSharing = 0,
    Sharing = 1,
}

export enum TaskSharingPermission {
    TASK_VIEW_ONLY = 'TASK_VIEW_ONLY',
    TASK_ADD_ALLOWED = 'TASK_ADD_ALLOWED',
    TASK_ADD_DESCRIPTION = 'TASK_ADD_DESCRIPTION',
    TASK_ADD_FILE = 'TASK_ADD_FILE',
    TASK_ADD_NOTE = 'TASK_ADD_NOTE',
    TASK_ADD_AUDIO = 'TASK_ADD_AUDIO',
    TASK_ADD_CHECKLIST = 'TASK_ADD_CHECKLIST',
    TASK_ADD_COMMENT = 'TASK_ADD_COMMENT',
    TASK_MODIFY_ALLOWED = 'TASK_MODIFY_ALLOWED',
    TASK_MODIFY_STATUS = 'TASK_MODIFY_STATUS',
    TASK_MODIFY_TITLE = 'TASK_MODIFY_TITLE',
    TASK_MODIFY_URGENCY = 'TASK_MODIFY_URGENCY',
    TASK_MODIFY_DEADLINE = 'TASK_MODIFY_DEADLINE',
    TASK_MODIFY_DESCRIPTION = 'TASK_MODIFY_DESCRIPTION',
    TASK_MODIFY_FILE = 'TASK_MODIFY_FILE',
    TASK_MODIFY_NOTE = 'TASK_MODIFY_NOTE',
    TASK_MODIFY_AUDIO = 'TASK_MODIFY_AUDIO',
    TASK_MODIFY_CHECKLIST = 'TASK_MODIFY_CHECKLIST',
    TASK_MODIFY_COMMENT = 'TASK_MODIFY_COMMENT',
}
