<script setup lang="ts">
import { ref, onMounted } from 'vue';

withDefaults(
  defineProps<{
    placeholder?: string;
    isDisabled?: boolean;
    modelValue: string;
    inputType?: 'text' | 'password';
    errorMessage?: string;
    name?: string;
    autofocus?: boolean;
    disabledEnterWarning?: string;
    styleName?: string;
    className?: string;
    rows?: number;
    maxLength?: number | string;
  }>(),
  {
    placeholder: 'Enter comment...',
    inputType: 'text',
    rows: 4,
    maxLength: 400,
    isDisabled: false,
    modelValue: '',
    errorMessage: '',
    styleName: '',
    className: '',
    disabledEnterWarning: '',
    name: '',
  }
);

const emit = defineEmits([
  'blur',
  'focus',
  'enter',
  'update:modelValue',
  'change',
]);

const inputRef = ref(null as any);
const warningText = ref('');
// let timer;
onMounted(() => {
  // console.log('props.disabledEnterWarning', props.disabledEnterWarning);
  // if (props.disabledEnterWarning) {
  //   inputRef.value.onkeydown = function (e) {
  //     if (e.keyCode == 13) {
  //       warningText.value = props.disabledEnterWarning;
  //       e.preventDefault();
  //       if (timer) clearTimeout(timer);
  //       timer = setTimeout(() => {
  //         warningText.value = '';
  //       }, 5 * 1000);
  //     }
  //   };
  // }
});
const onChange = (event) => {
  emit('update:modelValue', event.target.value);
  emit('change', event);
};

const focus = () => {
  inputRef.value?.focus();
};
defineExpose({
  focus,
});
</script>

<template>
  <div
    class="
      text-gray-600
      focus-within:text-gray-800
      w-full
      rounded-md
      flex-center flex-col
    "
  >
    <textarea
      ref="inputRef"
      v-cusFocus="autofocus"
      :name="name"
      class="
        h-full
        focus:outline-none
        py-1
        px-2
        small-scrollbar
        relative
        rounded
        vig-input
        placeholder-gray-500 placeholder-opacity-75
      "
      :class="{
        'ring-red-500': errorMessage,
        'bg-gray-100': isDisabled,
        className,
      }"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="isDisabled"
      :type="inputType"
      :style="`width: 99%;min-height: 60px;${styleName}`"
      :rows="rows"
      :maxlength="maxLength"
      @input="onChange"
      @keyup.enter.prevent="$emit('enter')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />

    <div
      v-if="maxLength"
      class="
        text-gray-800
        flex
        items-center
        w-full
        text-sm
        bg-white
        rounded-b
        mt-1
        relative
      "
      :class="warningText ? 'justify-between' : 'justify-end'"
    >
      <span v-if="warningText" class="text-xs text-orange-500">
        {{ warningText }}
      </span>
      <span class="absolute right-0 bottom-3 px-2 text-gray-500 text-xs"
        >{{ modelValue?.length || 0 }}/{{ maxLength }}</span
      >
    </div>
  </div>

  <span
    v-if="errorMessage"
    class="
      flex
      items-center
      font-medium
      tracking-wide
      text-red-500 text-xs
      mt-1
      ml-1
    "
  >
    {{ errorMessage }}
  </span>
</template>
