<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import { useScreens } from 'vue-screen-utils';
import DateInput from '@/ui/common/atoms/date-pickers/DateInput.vue';
import { EFunctionPermission } from '@/application/types/block-function.types';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import appStore from '@/store/app';

dayjs.extend(customParseFormat);

const props = defineProps<{
  scheduleTime?: string;
  startTime?: string;
  isHiddenStartTime?: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'onChangeScheduleTime',
    data: {
      startTime?: string;
      scheduleTime?: string;
      updateMode?: 'START_ONLY' | 'END_ONLY' | 'BOTH';
    }
  ): void;
  (e: 'close'): void;
}>();

// const scheduleTimeInput = ref<string>();
const DATE_FORMAT = 'DD-MM-YYYY';
const RETURN_DATE_FORMAT = 'YYYY-MM-DD';

const { mapCurrent } = useScreens({
  xs: '0px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
});
const columns = mapCurrent({ lg: 2 }, 1);

const scheduleDateRange = ref({
  start: props.startTime ? dayjs.utc(props.startTime).format() : '',
  end: props.scheduleTime ? dayjs.utc(props.scheduleTime).format() : '',
  startString: props.startTime
    ? dayjs(props.startTime).format(DATE_FORMAT)
    : '',
  endString: props.scheduleTime
    ? dayjs(props.scheduleTime).format(DATE_FORMAT)
    : '',
});

const isCurrentDayOffSelect = computed(() =>
  dayjs().isSame(dayjs(scheduleDateRange.value?.end), 'day')
);

const scheduleDate = ref<{
  isFocusingStartTime: boolean;
  isFocusingScheduleTime: boolean;
}>({
  isFocusingStartTime: false,
  isFocusingScheduleTime: true,
});

const onChangeStartTime = (date) => {
  const diffVsEndTime = dayjs(dayjs(date)).diff(
    dayjs(scheduleDateRange.value?.end)
  );

  if (diffVsEndTime <= 0 || !scheduleDateRange.value?.end) {
    scheduleDateRange.value.startString = dayjs(date).format(DATE_FORMAT);

    emit('onChangeScheduleTime', {
      startTime: date ? dayjs(date).format(RETURN_DATE_FORMAT) : '',
      updateMode: 'START_ONLY',
    });
  } else {
    onChangeDateRange({
      start: scheduleDateRange.value?.end,
      end: date,
    });
  }
};
const onChangeEndTime = (date) => {
  const diffVsStartTime = dayjs(dayjs(date)).diff(
    dayjs(scheduleDateRange.value?.start)
  );

  if (diffVsStartTime >= 0 || !scheduleDateRange.value?.start) {
    scheduleDateRange.value.endString = dayjs(date).format(DATE_FORMAT);
    emit('onChangeScheduleTime', {
      scheduleTime: date ? dayjs(date).format(RETURN_DATE_FORMAT) : '',
      updateMode: 'END_ONLY',
    });
  } else {
    onChangeDateRange({
      start: date,
      end: scheduleDateRange.value?.start,
    });
  }
};
const scheduleTimeDateInputRef = ref<any>(null);

const isRange = computed<boolean>(() => {
  return (
    scheduleDate.value?.isFocusingStartTime && !scheduleDateRange.value?.end
  );
});

const onChangeDateRange = (dateRange) => {
  scheduleDateRange.value.startString = dayjs(dateRange?.start).format(
    DATE_FORMAT
  );
  scheduleDateRange.value.endString = dayjs(dateRange?.end).format(DATE_FORMAT);

  emit('onChangeScheduleTime', {
    startTime: dateRange?.start ? dayjs.utc(dateRange?.start).format() : '',
    scheduleTime: dateRange?.end
      ? dayjs(dateRange?.end).format(RETURN_DATE_FORMAT)
      : '',
    updateMode: 'BOTH',
  });
};

const onFocusStartDate = () => {
  // scheduleDateRange.value = {
  //   start: '',
  //   end: '',
  //   startString: '',
  //   endString: '',
  // };
  scheduleDate.value.isFocusingStartTime = true;
};

const startDateInputRef = ref<any>(null);
const focusOnStartDate = () => {
  onFocusStartDate();
  setTimeout(() => {
    if (
      startDateInputRef.value &&
      typeof startDateInputRef.value?.focus == 'function'
    )
      startDateInputRef.value?.focus();
  });
};

const onClearStartDate = () => {
  // scheduleDate.value.isFocusingStartTime = false;
  scheduleDateRange.value.startString = '';
  scheduleDateRange.value.start = '';

  emit('onChangeScheduleTime', { startTime: '', updateMode: 'START_ONLY' });
};
const onClearScheduleTime = () => {
  scheduleDateRange.value.endString = '';
  scheduleDateRange.value.end = '';

  emit('onChangeScheduleTime', {
    scheduleTime: '',
    updateMode: 'END_ONLY',
  });
};

const onInputStartDate = (startTime) => {
  const isValidDate = dayjs(startTime, 'DD-MM-YYYY', true).isValid();

  if (!isValidDate) return;

  onChangeStartTime(dayjs(startTime, 'DD-MM-YYYY'));
};

const onInputScheduleTime = (startTime) => {
  const isValidDate = dayjs(startTime, 'DD-MM-YYYY', true).isValid();

  if (!isValidDate) return;

  onChangeEndTime(dayjs(startTime, 'DD-MM-YYYY'));
};

const onSave = () => {
  const isChangedStart =
    scheduleDateRange.value?.startString !==
    (props.startTime ? dayjs(props.startTime).format(DATE_FORMAT) : '');

  console.log('🚀 Tictop ~ scheduleDateRange.value?.endString:', {
    endString: scheduleDateRange.value?.endString,
    currentEnd: props.scheduleTime
      ? dayjs(props.scheduleTime).format(DATE_FORMAT)
      : '',
    scheduleTime: props.scheduleTime,
  });
  const isChangedEnd =
    scheduleDateRange.value?.endString !==
    (props.scheduleTime ? dayjs(props.scheduleTime).format(DATE_FORMAT) : '');

  const updateMode =
    isChangedStart && isChangedEnd
      ? 'BOTH'
      : isChangedStart
      ? 'START_ONLY'
      : isChangedEnd
      ? 'END_ONLY'
      : '';

  if (!updateMode) {
    emit('close');
    return;
  }

  emit('onChangeScheduleTime', {
    startTime: scheduleDateRange.value?.startString
      ? dayjs(scheduleDateRange.value.startString).format(RETURN_DATE_FORMAT)
      : '',
    scheduleTime: scheduleDateRange.value?.endString
      ? dayjs(scheduleDateRange.value.endString).format(RETURN_DATE_FORMAT)
      : '',
    updateMode,
  });

  emit('close');
};
</script>
<template>
  <div class="flex justify-between space-x-1 items-center">
    <span class="font-medium">{{ $t('TASK_SELECT_SPECIFIC_DATE_LABEL') }}</span>
  </div>
  <slot name="otherTime"></slot>
  <div class="flex flex-col">
    <div class="flex items-center w-full gap-2">
      <div class="flex justify-center items-center gap-2">
        <div
          v-if="
            !isHiddenStartTime &&
            (scheduleDateRange.startString ||
              scheduleDate?.isFocusingStartTime ||
              $permissionFunction(ALL_FUNCTIONS.TASK.START_DATE) ==
                EFunctionPermission.ALLOW)
          "
          class="w-1/2 flex flex-col"
        >
          <DateInput
            ref="startDateInputRef"
            v-model="scheduleDateRange.startString"
            :placeholder="$t('DOMAIN_LABEL_START_DATE')"
            :is-disabled="false"
            @focus="onFocusStartDate"
            @on-clear="onClearStartDate"
            @input="onInputStartDate"
          />
        </div>
        <div
          v-else-if="!isHiddenStartTime"
          v-permission-function="{
            functionCode: ALL_FUNCTIONS.TASK.START_DATE,
            onContinue: () => {
              focusOnStartDate();
            },
          }"
          class="w-1/2 flex flex-col"
        >
          <DateInput
            v-if="
              scheduleDateRange.startString ||
              $permissionFunction(ALL_FUNCTIONS.TASK.START_DATE) ==
                EFunctionPermission.ALLOW
            "
            v-model="scheduleDateRange.startString"
            class="h-8"
            :placeholder="$t('DOMAIN_LABEL_START_DATE')"
            :is-disabled="false"
            @focus="onFocusStartDate"
            @on-clear="onClearStartDate"
            @input="onInputStartDate"
          />
          <span
            v-else
            class="
              w-full
              h-8
              flex
              items-center
              justify-between
              gap-2
              border
              rounded
              px-2
            "
          >
            <span
              :class="
                scheduleDateRange.startString ? '' : 'text-orange-300 text-sm'
              "
            >
              {{
                scheduleDateRange.startString
                  ? scheduleDateRange.startString
                  : $t('DOMAIN_LABEL_START_DATE')
              }}
            </span>
            <SynIcon name="Premium" />
          </span>
        </div>
        <div class="flex flex-col">
          <DateInput
            ref="scheduleTimeDateInputRef"
            v-model="scheduleDateRange.endString"
            class="h-8"
            :placeholder="$t('TASK_TABLE_LABEL_DEADLINE')"
            :is-disabled="false"
            autofocus
            @focus="scheduleDate.isFocusingStartTime = false"
            @on-clear="onClearScheduleTime"
            @input="onInputScheduleTime"
          />
        </div>
      </div>

      <AtomButton
        color="current"
        :label="$t('COMMON_LABEL_SAVE')"
        class="ring-1 ring-current"
        @click="onSave"
      />
    </div>
  </div>

  <div class="w-full">
    <v-date-picker
      v-if="isRange"
      ref="dateSpecificPickerRef"
      v-model.range="scheduleDateRange"
      :locale="appStore().language || 'en'"
      style="font-size: 13px !important"
      class="datepicker_view border border-gray-100 text-sm w-full vig"
      :timezone="'UTC'"
      :columns="columns"
      :config="{ type: 'string', mask: RETURN_DATE_FORMAT }"
      :popover="{ visibility: 'hidden' }"
      color="current"
      :attributes="[
        {
          key: 'today',
          highlight: {
            fillMode: isCurrentDayOffSelect ? 'solid' : 'outline',
          },
          dates: new Date(),
        },
      ]"
      :first-day-of-week="2"
      @update:model-value="onChangeDateRange"
    />
    <template v-else>
      <v-date-picker
        v-if="scheduleDate.isFocusingStartTime"
        ref="dateSpecificPickerRef"
        v-model="scheduleDateRange.start"
        :locale="appStore().language || 'en'"
        style="font-size: 13px !important"
        class="datepicker_view border border-gray-100 text-sm w-full vig"
        :timezone="'UTC'"
        :config="{ type: 'string', mask: RETURN_DATE_FORMAT }"
        :columns="columns"
        :popover="{ visibility: 'hidden' }"
        color="current"
        :attributes="[
          {
            key: 'today',
            highlight: {
              fillMode: isCurrentDayOffSelect ? 'solid' : 'outline',
            },
            dates: new Date(),
          },
        ]"
        :first-day-of-week="2"
        @dayclick="(data) => onChangeStartTime(data?.id)"
      />
      <v-date-picker
        v-else
        ref="dateSpecificPickerRef"
        v-model="scheduleDateRange.end"
        :locale="appStore().language || 'en'"
        style="font-size: 13px !important"
        class="datepicker_view border border-gray-100 text-sm w-full vig"
        :timezone="'UTC'"
        :config="{ type: 'string', mask: RETURN_DATE_FORMAT }"
        :columns="columns"
        :popover="{ visibility: 'hidden' }"
        color="current"
        :attributes="[
          {
            key: 'today',
            highlight: {
              fillMode: isCurrentDayOffSelect ? 'solid' : 'outline',
            },
            dates: new Date(),
          },
        ]"
        :first-day-of-week="2"
        @dayclick="(date) => onChangeEndTime(date?.id)"
      />
    </template>
  </div>
</template>
