<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { TaskLife } from '@/domain/enums/taskEnum';
import TaskCode from '@/ui/modules/task/detail/_commons/task-code/TaskCode.vue';
import TaskStateStepper from '@/ui/modules/task/components/TaskStateStepper.vue';
import TaskName from '@/ui/modules/task/detail/_commons/task-name/TaskName.vue';
import TaskDeadline from '@/ui/modules/task/detail/_commons/task-deadline/TaskDeadline.vue';
import TaskPlaning from '@/ui/modules/task/detail/_commons/task-planing/TaskPlaning.vue';
import TaskDomain from '@/ui/modules/task/detail/_commons/task-domain/TaskDomain.vue';
import TaskUrgency from '@/ui/modules/task/detail/_commons/task-urgency/TaskUrgency.vue';
import TaskDescription from '@/ui/modules/task/detail/_commons/task-description/TaskDescription.vue';
import TaskActivities from '@/ui/modules/task/detail/_commons/task-activities/TaskActivities.vue';
import TaskCommentBottom from '@/ui/modules/task/detail/TaskCommentBottom.vue';
import TaskAssignee from '@/ui/modules/task/detail/_commons/task-assignee/TaskAssignee.vue';
import TaskAttachments from '@/ui/modules/task/detail/_commons/task-attachments/TaskAttachments.vue';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import TaskCreator from '@/ui/modules/task/detail/_commons/task-creator/TaskCreator.vue';
import SetPrivateTask from '@/ui/modules/task/detail/action-on-task/SetPrivateTask.vue';

const props = defineProps<{
  taskDetail: any;
  taskFiles?: any[];
  taskNotes?: any[];
  taskComments?: any[];
  organizationId?: number;
  users?: any[];
  groups?: any[];
  domains?: any[];
  projects?: any[];
  isOrgPersonal?: boolean;
}>();

const emits = defineEmits([
  'onStateChange',
  'onPrivateChange',
  'onNameChange',
  'onAssigneeChange',
  'onDomainChange',
  'onPlanningChange',
  'onDeadlineChange',
  'onUrgencyChange',
  'onDescriptionChange',
  'onAttachmentsChange',
  'onCommentAdd',
]);

const taskBodyRef = ref<HTMLElement>();
const activeActivity = ref<string>('COMMENT');

const hasPermission = computed<boolean>(
  () => !props.taskDetail?.groupId || props.taskDetail?.isGroupMember
);

const isActiveTask = computed(() =>
  [TaskLife.Todo, TaskLife.InProcess, TaskLife.Finished]?.includes(
    props.taskDetail?.taskLife
  )
);

watch(
  () => props.taskDetail?.id,
  () => {
    activeActivity.value = 'COMMENT';
  }
);

const onSaveCommentSuccess = (needUpdateAttachments) => {
  activeActivity.value = 'COMMENT';

  emits('onCommentAdd');

  if (needUpdateAttachments) emits('onAttachmentsChange');
};

const onNotAllowedAction = async () => {
  settingGlobalModal({
    type: 'notification',
    title: 'Action has not allowed yet',
    confirmable: true,
    closeable: false,
  });

  await ask();
};
</script>

<template>
  <div class="h-full flex flex-col">
    <!--HEADER-->
    <div
      class="
        flex
        items-center
        justify-between
        gap-3
        px-4
        py-2
        border-b border-gray-100
      "
    >
      <!--TASK CODE-->
      <TaskCode :task="taskDetail" />

      <!--TASK STATUS STEPS-->
      <div class="flex-1" :class="{ 'flex-center': isActiveTask }">
        <TaskStateStepper
          :task-detail="taskDetail"
          @on-change-state="$emit('onStateChange', $event)"
        />
      </div>

      <!--TASK COMMON ACTIONS-->
      <div></div>
    </div>

    <!--BODY-->
    <div ref="taskBodyRef" class="flex-1 overflow-auto small-scrollbar">
      <!--CREATED DATE & PRIVATE-->
      <div class="flex items-center space-x-2 bg-gray-50 px-4 py-2">
        <span class="text-gray-500 text-sm"
          >{{ $t('TASK_TABLE_LABEL_CREATED_DATE') }}:</span
        >
        <SynLabelDateTime
          class="text-gray-500"
          format="date_time"
          :datetime="taskDetail?.creationTime"
        />
        <SetPrivateTask
          :current-task="taskDetail"
          @change="$emit('onPrivateChange', $event)"
        />
      </div>

      <div class="px-4">
        <!--CREATOR + TASK NAME-->
        <div class="py-2 flex items-center gap-2">
          <TaskCreator :task="taskDetail" :users="users" />

          <div class="flex-1">
            <TaskName
              :task="taskDetail"
              :readonly="!hasPermission"
              @on-name-change="$emit('onNameChange', $event)"
            />
          </div>
        </div>

        <div v-if="!isOrgPersonal" class="flex items-center space-x-6 mt-4">
          <div class="w-20 text-xs text-gray-500">
            {{ $t('TASK_LABEL_ASSIGNEE') }}
          </div>
          <TaskAssignee
            :task="taskDetail"
            :readonly="!hasPermission"
            :users="users"
            :groups="groups"
            @on-assignee-change="$emit('onAssigneeChange', $event)"
            @click="onNotAllowedAction"
          />
        </div>

        <!--DOMAIN-->
        <div v-if="!isOrgPersonal" class="flex items-center space-x-6 mt-4">
          <div class="w-20 text-xs text-gray-500">
            {{ $t('DOMAIN_LABEL_TITLE') }}
          </div>
          <TaskDomain
            :task="taskDetail"
            :readonly="!hasPermission"
            :domains="domains"
            :projects="projects"
            class="font-medium"
            @on-domain-change="$emit('onDomainChange', $event)"
            @click="onNotAllowedAction"
          />
        </div>

        <!--  URGENCY + STATE -->
        <div class="flex items-center space-x-6 mt-4">
          <div class="w-20 text-xs text-gray-500">
            {{ $t('TASK_TABLE_LABEL_URGENCY') }}
          </div>
          <TaskUrgency
            :task="taskDetail"
            :readonly="!hasPermission"
            @on-urgency-change="$emit('onUrgencyChange', $event)"
          />
        </div>

        <!--PLAN TODAY-->
        <div
          v-if="
            taskDetail?.assigneeId && taskDetail?.taskLife !== TaskLife.Finished
          "
          class="flex items-center space-x-6 mt-4"
        >
          <div class="w-20 text-xs text-gray-500">
            {{ $t('TASK_FILTER_LABEL_PLANNED') }}
          </div>
          <TaskPlaning
            :task="taskDetail"
            :readonly="!hasPermission"
            @on-planning-change="$emit('onPlanningChange', $event)"
            @on-state-change="$emit('onStateChange', $event)"
          />
        </div>

        <!--  DEADLINE -->
        <div class="flex items-center space-x-6 mt-4">
          <div class="w-20 text-xs text-gray-500">
            {{ $t('TASK_TABLE_LABEL_DEADLINE') }}
          </div>
          <TaskDeadline
            :task="taskDetail"
            :readonly="!hasPermission"
            @on-deadline-change="$emit('onDeadlineChange', $event)"
          />
        </div>

        <!--  DESCRIPTION -->
        <div class="flex items-start space-x-6 mt-4">
          <div class="w-20 text-xs text-gray-500 pt-1.5">
            {{ $t('COMMON_LABEL_DESCRIPTION') }}
          </div>
          <div class="flex-1">
            <TaskDescription
              :task="taskDetail"
              :readonly="!hasPermission"
              @on-description-change="$emit('onDescriptionChange', $event)"
            />
          </div>
        </div>

        <!-- FILES -->
        <div class="mt-4">
          <TaskAttachments
            :task="taskDetail"
            :task-files="taskFiles"
            :task-notes="taskNotes"
            :readonly="!hasPermission"
          />
        </div>

        <!--COMMENTS + LOGS-->
        <TaskActivities
          v-model:activeActivity="activeActivity"
          :task="taskDetail"
          :organization-id="organizationId"
          :task-comments="taskComments"
          :users="users"
          class="mt-4 text-sm"
          @on-comment-saved="onSaveCommentSuccess"
        />
      </div>
    </div>

    <!--FOOTER COMMENT-->
    <div class="max-h-80 p-3 border-t border-gray-100">
      <TaskCommentBottom
        :task-id="taskDetail?.id"
        :current-task="taskDetail"
        :organization-id="organizationId"
        :users="users"
        @on-save-success="onSaveCommentSuccess"
      />
    </div>
  </div>
</template>
