<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import EnterLicenseCode from '@/ui/modules/settings/payments/license/EnterLicenseCode.vue';
import paymentStore from '@/store/payment';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { CDN_LINK_BY_BUCKET } from '@/ui/plugins/awss3/AwsS3Config';

withDefaults(defineProps<{}>(), {});

const emit = defineEmits<{
  (e: 'onCancel'): void;
  (e: 'onSubmit', data: { mode: number; data: any }): void;
}>();

const _paymentStore = paymentStore();

const licenseKeyInput = ref<string>('');
const licenseCheckedResult = ref<{
  status: string;
  phone: string;
} | null>();
const licenseCheckedBlock = ref<{
  code: 0;
  message: string;
  details: string;
  timeBlockRemaining: string;
} | null>();

const onChangeKeyInput = () => {
  licenseCheckedBlock.value = null;
  licenseCheckedResult.value = null;
};

const onCheckLicenseKey = async () => {
  try {
    const licenseKey = licenseKeyInput.value?.match(/.{1,4}/g)?.join('-');
    if (!licenseKey) return;

    const result = await _paymentStore.checkLicense(licenseKey);
    licenseCheckedResult.value = result;
    if (result?.phone) {
      onVerifyLicenseKey();
      onHandleGoToNextStep();
    }
  } catch (error: any) {
    licenseCheckedBlock.value = error?.error;
    licenseCheckedBlock.value = error?.error;
    timeRemaining.value = parseInt(
      error?.error?.timeBlockRemaining?.toString()
    );
    startCount();
  }
};

const verifyErrorMessageCode = ref('');
const onVerifyLicenseKey = async () => {
  verifyErrorMessageCode.value = '';
  try {
    const licenseKey = licenseKeyInput.value?.match(/.{1,4}/g)?.join('-');
    if (!licenseKey) return;

    await _paymentStore.verifyLicense(licenseKey);
  } catch (error: any) {
    console.log('🚀 Hyrin ~ onVerifyLicenseKey ~ error:', error);
  }
};

const licenseInfo = ref<any>({});
const onApplyLicenseKey = async () => {
  try {
    const licenseKey = licenseKeyInput.value?.match(/.{1,4}/g)?.join('-');
    if (!licenseKey) return;

    licenseInfo.value = await _paymentStore.applyLicense({
      licenseKey,
      code: optCode.value,
    });

    onHandleGoToNextStep();
  } catch (error: any) {
    console.log(
      '🚀 Hyrin ~ onApplyLicenseKey ~ error:',
      error?.error?.timeBlockRemaining
    );
    verifyErrorMessageCode.value = 'TOKEN_INFO_NOT_MATCH';
  }
};

const {
  currentStep,
  stepperByKey,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    ENTER_LICENSE_CODE: {
      key: 'ENTER_LICENSE_CODE',
      previous: 'COMMON_LABEL_CANCEL',
      previousAction: () => {
        emit('onCancel');
      },
      next: 'ENTER_OTP',
      nextAction: () => {},
      label: '',
      buttonBack: 'COMMON_LABEL_CANCEL',
      buttonNext: 'COMMON_LABEL_NEXT',
      title: '',
      subTitle: '',
    },
    ENTER_OTP: {
      key: 'ENTER_OTP',
      previous: 'ENTER_LICENSE_CODE',
      previousAction: () => {},
      next: 'APPLY_SUCCESS',
      nextAction: () => {},
      label: '',
      buttonBack: 'COMMON_LABEL_PREVIOUS',
      buttonNext: 'COMMON_LABEL_COMPLETE',
      title: '',
      subTitle: '',
    },
    APPLY_SUCCESS: {
      key: 'APPLY_SUCCESS',
      previous: 'ENTER_OTP',
      previousAction: () => {
        emit('onCancel');
      },
      next: 'CHOOSE_TYPE',
      nextAction: () => {
        emit('onCancel');
      },
      label: '',
      buttonBack: 'COMMON_LABEL_CANCEL',
      buttonNext: 'COMMON_LABEL_AGREE',
      title: '',
      subTitle: '',
    },
  },
  'ENTER_LICENSE_CODE'
);

const onBack = () => {
  onHandleGoToPreviousStep();
};

const onClickNext = () => {
  switch (currentStep.value) {
    case 'ENTER_LICENSE_CODE':
      onCheckLicenseKey();
      break;
    case 'ENTER_OTP':
      onApplyLicenseKey();
      break;
    case 'APPLY_SUCCESS':
      emit('onCancel');
      break;

    default:
      onHandleGoToNextStep();
      break;
  }
};

const isOpenHelpContact = ref<boolean>(false);

const isDisabledSubmitButton = computed<boolean>(() => {
  return (
    (currentStep.value === 'ENTER_LICENSE_CODE' &&
      (licenseKeyInput.value?.length !== 20 ||
        licenseCheckedResult.value?.status)) ||
    (currentStep.value === 'ENTER_OTP' && optCode.value?.length !== 6)
  );
});

const optCode = ref<string>('');
const changeOtpCode = (code) => {
  optCode.value = code;
  verifyErrorMessageCode.value = '';
};

const timeRemaining = ref(0);
const message = ref('');
let countDownTime;
const startCount = () => {
  countDownTime = setInterval(function () {
    if (timeRemaining.value <= 0) {
      message.value = '';
      clearInterval(countDownTime);
    } else {
      message.value = translate('SUSPICIOUS_REQUEST_COUNTDOWN_TIME', {
        minute: Math.floor(timeRemaining.value / 60),
        second: timeRemaining.value % 60,
      });
    }
    timeRemaining.value -= 1;
  }, 1000);
};

const onEnterAgain = () => {
  licenseCheckedBlock.value = null;
};

onUnmounted(() => {
  clearInterval(countDownTime);
});
</script>

<template>
  <teleport to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
        z-60
      "
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div
        class="
          p-8
          mx-auto
          border-current
          flex
          items-center
          flex-col
          justify-between
          bg-white
          rounded-2xl
          relative
        "
        style="min-width: 40rem"
      >
        <div class="flex-1 flex flex-col space-y-6 items-center text-gray-800">
          <!-- Body -->
          <template v-if="licenseCheckedBlock?.code">
            <SynIcon name="license-block" custom-class="w-28 h-28" />

            <div class="text-gray-800">
              {{ $t('ENTER_LICENSE_KEY_BLOCK') }}
            </div>
            <span v-if="timeRemaining > 0" class="text-orange-600 text-md">
              {{ message }}
            </span>
          </template>
          <template v-else-if="currentStep == 'ENTER_LICENSE_CODE'">
            <div class="flex-center flex-col space-y-4">
              <div
                class="absolute -top-10 w-20 h-20 z-60 flex-center rounded-full"
              >
                <SynIcon name="license" custom-class="w-20 h-20" />
              </div>
              <div class="text-center text-2xl font-semibold text-gray-800">
                {{ $t('ENTER_LICENSE_KEY_TITLE') }}
              </div>
              <div class="flex-center flex-col space-y-2">
                <span class="text-gray-600">
                  {{ $t('ENTER_LICENSE_KEY_SUB_TITLE') }} :
                </span>
              </div>
              <div class="flex-center flex-col space-y-2">
                <SynFormInput :error-message="$t(licenseCheckedResult?.status)">
                  <AtomLicenseInput
                    ref="otpInput"
                    v-model="licenseKeyInput"
                    class="pb-2 mb-2"
                    input-classes="otp-input uppercase"
                    :num-inputs="5"
                    should-auto-focus
                    input-type="text"
                    separator="-"
                    @update:model-value="onChangeKeyInput"
                  />
                </SynFormInput>
              </div>
            </div>
          </template>
          <template v-else-if="currentStep == 'ENTER_OTP'">
            <EnterLicenseCode
              :phone-number="licenseCheckedResult?.phone"
              :error-code="verifyErrorMessageCode"
              @change-code="changeOtpCode"
              @resend-code="onVerifyLicenseKey"
            />
          </template>
          <template v-else-if="currentStep == 'APPLY_SUCCESS'">
            <div class="flex-center flex-col space-y-4">
              <div
                class="h-56"
                :style="{
                  width: '22rem',
                  backgroundImage: `${CDN_LINK_BY_BUCKET.TICTOP_SOURCE_DEFAULT}/web/common/congratulations-banner-template-with-balloons-confetti_619130-1027.png`,
                  backgroundSize: 'cover',
                }"
              ></div>
              <div class="text-center text-2xl text-current font-semibold">
                {{ $t('ENTER_LICENSE_KEY_APPLY_SUCCESS') }}
              </div>
              <div class="text-center text-gray-800 font-semibold">
                {{
                  $t('ENTER_LICENSE_KEY_APPLY_SUCCESS_CONTENT', {
                    totalMember: licenseInfo?.totalMember || 100,
                  })
                }}
              </div>
            </div>
          </template>
        </div>

        <div
          class="
            w-full
            flex
            justify-between
            items-center
            hover:text-current
            cursor-pointer
            mt-8
          "
        >
          <div
            class="flex-center space-x-2 w-max"
            @click="isOpenHelpContact = true"
          >
            <SynIcon name="support" custom-class="w-6 h-6 fill-current" />
            <span class="text-current">
              {{ $t('ENTER_LICENSE_KEY_HELP_SUPPORT') }}
            </span>
          </div>

          <div class="flex-center space-x-2 w-max">
            <template v-if="licenseCheckedBlock?.code">
              <SynButton
                :label="$t('COMMON_LABEL_CANCEL')"
                type-text
                color="gray"
                @click="$emit('onCancel')"
              />
              <SynButton
                v-if="timeRemaining == 0"
                :label="$t('ENTER_LICENSE_KEY_ENTER_AGAIN')"
                @click="onEnterAgain"
              />
            </template>
            <template v-else>
              <SynButton
                v-if="stepperByKey[currentStep]?.['buttonBack']"
                :label="$t(stepperByKey[currentStep]?.['buttonBack'])"
                type-text
                color="gray"
                @click="onBack"
              />

              <SynButton
                :disabled="isDisabledSubmitButton"
                :label="$t(stepperByKey[currentStep]?.['buttonNext'])"
                @click="onClickNext"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <teleport v-if="isOpenHelpContact" to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
        z-60
      "
      style="background: rgba(0, 0, 0, 0.7)"
    >
      <div
        class="
          mx-auto
          border-current
          flex
          items-center
          flex-col
          justify-between
          bg-white
          rounded-2xl
          relative
        "
        style="min-width: 40rem"
      >
        <div class="p-8 flex-center flex-col space-y-4">
          <SynIcon name="support" custom-class="w-20 h-20 fill-current" />
          <span class="text-current text-2xl">
            {{ $t('ENTER_LICENSE_KEY_HELP_SUPPORT') }}
          </span>
          <div class="flex-center space-x-8">
            <!-- Chat -->
            <div class="flex-center space-x-2">
              <SynIcon name="phone" custom-class="w-10 h-10 fill-current" />
              <div class="flex flex-col space-y-1">
                <span class="text-current-500">
                  {{ $t('COMMON_LABEL_PHONE') }}
                </span>
                <span class="text-gray-800"> (+84) 966 268 310 </span>
              </div>
            </div>
            <!-- Email -->
            <div class="flex-center space-x-2">
              <SynIcon name="email" custom-class="w-10 h-10 fill-current" />
              <div class="flex flex-col space-y-1">
                <span class="text-current-500">Email</span>
                <span class="text-gray-800"> services@tictop.app </span>
              </div>
            </div>
            <!-- Hotline -->
            <div class="flex-center space-x-2">
              <SynIcon name="support" custom-class="w-10 h-10 fill-current" />
              <div class="flex flex-col space-y-1">
                <span class="text-current-500">Hotline</span>
                <span class="text-gray-800"> (024) 99 99 96 98 </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            w-full
            p-4
            flex
            justify-end
            items-center
            hover:text-current
            cursor-pointer
            space-x-2
            vig-modal-footer
          "
        >
          <SynButton type-text color="gray" @click="isOpenHelpContact = false">
            <span class="do-not-close-dropdown">
              {{ $t('COMMON_LABEL_CLOSE') }}</span
            >
          </SynButton>
        </div>
        <button
          :title="$t('COMMON_LABEL_CANCEL')"
          class="
            w-8
            h-8
            bg-gray-200
            rounded-full
            cursor-pointer
            hover:text-current-500 hover:bg-gray-100
            absolute
            -top-3
            -right-3
            flex-center
            vig-modal-close
            do-not-close-dropdown
            z-50
          "
          @click="isOpenHelpContact = false"
        >
          <SynIcon name="close" custom-class="w-4 h-4 do-not-close-dropdown" />
        </button>
      </div>
    </div>
  </teleport>
</template>
