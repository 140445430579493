<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.56 118.42">
    <rect
      style="fill: #008e8e; opacity: 0.44"
      x="370.68"
      y="282.2"
      width="26.55"
      height="39.83"
      rx="13.28"
      transform="translate(-353.09 198.06) rotate(-60)"
    />
    <rect
      style="fill: #008e8e; opacity: 0.62"
      x="333.04"
      y="327.54"
      width="26.55"
      height="39.83"
      rx="13.28"
      transform="translate(-427.68 61.13) rotate(-45)"
    />
    <path
      style="fill: #008e8e"
      d="M310,402.16h0a13.27,13.27,0,0,1-18.13-4.86l-6.64-11.5a13.27,13.27,0,1,1,23-13.28l6.64,11.5A13.28,13.28,0,0,1,310,402.16Z"
      transform="translate(-283.43 -285.51)"
    />
  </svg>
</template>
