<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 88 88"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g id="Coupoun">
        <path
          d="m64.6074 14.2056a1.0041 1.0041 0 0 0 -.8691-.1709l-59 16a1 1 0 0 0 -.7383.9653v2a1 1 0 0 0 1 1h59a1 1 0 0 0 1-1v-18a1.0013 1.0013 0 0 0 -.3926-.7944z"
          fill="#b1dcde"
          data-original="#d0dbf7"
          class=""
        />
        <path
          d="m83 43a1 1 0 0 0 1-1v-11a1 1 0 0 0 -1-1h-51a1 1 0 0 0 -.8.4l-2.2 2.9335-2.2-2.9335a1 1 0 0 0 -.8-.4h-21a1 1 0 0 0 -1 1v11a1 1 0 0 0 1 1 9 9 0 0 1 0 18 1 1 0 0 0 -1 1v11a1 1 0 0 0 1 1h21a1 1 0 0 0 .8-.4l2.2-2.9335 2.2 2.9335a1 1 0 0 0 .8.4h51a1 1 0 0 0 1-1v-11a1 1 0 0 0 -1-1 9 9 0 0 1 0-18z"
          fill="#e0f1f2"
          data-original="#e6ecff"
          class=""
        />
        <g fill="#4294ff">
          <circle
            cx="46"
            cy="47"
            r="4"
            fill="#008d8d"
            data-original="#4294ff"
            class=""
          />
          <circle
            cx="56"
            cy="57"
            r="4"
            fill="#008d8d"
            data-original="#4294ff"
            class=""
          />
          <path
            d="m58.707 44.293a.9994.9994 0 0 0 -1.414 0l-14 14a1 1 0 1 0 1.414 1.414l14-14a.9994.9994 0 0 0 0-1.414z"
            fill="#008d8d"
            data-original="#4294ff"
            class=""
          />
          <path
            d="m29 42a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1z"
            fill="#008d8d"
            data-original="#4294ff"
            class=""
          />
          <path
            d="m29 50a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1z"
            fill="#008d8d"
            data-original="#4294ff"
            class=""
          />
          <path
            d="m29 58a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1z"
            fill="#008d8d"
            data-original="#4294ff"
            class=""
          />
          <path
            d="m29 66a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1z"
            fill="#008d8d"
            data-original="#4294ff"
            class=""
          />
        </g>
      </g>
    </g>
  </svg>
</template>
