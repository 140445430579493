import { ref } from 'vue';
import { useWindowFocus } from '@vueuse/core';
import {
    getLocalStorage,
    StorageConstant,
    setLocalStorage,
} from '@/ui/hooks/storageHook';
import { onChangeI18n } from './plugins/i18n/myi18n';
import { updateDeviceToken } from './plugins/firebases/firebaseFcm';
import {
    PermissionNameString,
    checkNavigatorPermission,
    PermissionState,
} from '@/ui/hooks/navigatorPermission';
import systemStore from '@/store/system';
import { IIpInformation } from '@/application/types/common/common.types';

export const isShowDayoffDetail = ref(false);
export const dayoffIdGlobal = ref('');
export const isCreateGroup = ref(false);
export const isOpenModalInvite = ref(false);
export const isShowPageGedDetail = ref(false);
export const gedIdGlobal = ref({
    id: 0,
    isGroup: false,
    folderId: '',
    notification: {},
});
export const eventIdGlobal = ref({
    isShow: false,
    id: '',
});

export const detectLanguageFromBrowserClient = async (location) => {
    // 1. user selected language
    const userSelectedLanguage = getLocalStorage(StorageConstant.LANGUAGE);
    if (userSelectedLanguage && systemSupportLanguage(userSelectedLanguage)) {
        onChangeI18n(userSelectedLanguage);
        return;
    }

    // 3. Get location via IP -- If valid will overwrite case 2
    const ipInformation: IIpInformation = systemStore().ipInformation;

    const langFromIp = getLanguageByCountryCode(ipInformation?.countryCode);

    if (systemSupportLanguage(langFromIp)) {
        setLocalStorage(StorageConstant.LANGUAGE, langFromIp);
        onChangeI18n(langFromIp);
        return;
    }

    // 2. get browser's language
    const browserLanguage = window.navigator.language
        ? window.navigator.language.slice(0, 2).toLowerCase()
        : '';
    if (systemSupportLanguage(browserLanguage)) {
        setLocalStorage(StorageConstant.LANGUAGE, browserLanguage);
        onChangeI18n(browserLanguage);
        return;
    }

    // 4. By default
    const defaultLanguage = getLanguageByCountryCode(location);
    setLocalStorage(StorageConstant.LANGUAGE, defaultLanguage);
    onChangeI18n(defaultLanguage);
};

export const detectLocation = () => {
    const euPath = import.meta.env.VITE_DOMAIN_EU?.split('://').pop();
    const location = window.location.href.includes(euPath || 'app.tictop.eu')
        ? 'EU'
        : 'VN';

    setLocalStorage(StorageConstant.LOCATION, location);

    // detectLanguageFromBrowserClient(location)
};

const getLanguageByCountryCode = (countryCode) => {
    let defaultLanguage = 'en';
    switch (countryCode) {
        case 'EU':
            defaultLanguage = 'en';
            break;
        case 'VN':
            defaultLanguage = 'vi';
            break;

        default:
            defaultLanguage = 'en';
            break;
    }

    return defaultLanguage;
};

const systemSupportLanguage = (lang) => {
    if (!lang) return false;

    const convertLang = lang.slice(0, 2).toLowerCase();
    return ['en', 'vi', 'vn', 'fr'].some((o) => o == convertLang);
};

export const windowFocusStatus = useWindowFocus();
export const browserPermissionState = ref<{
    notification: PermissionState | null;
}>({
    notification: null,
});
export const checkBrowserPermission = () => {
    checkNavigatorPermission(PermissionNameString.notifications).then(
        async (result) => {
            const state: PermissionState = result?.state as PermissionState;
            if (!state) return;
            browserPermissionState.value.notification = state;

            switch (state) {
                case PermissionState.prompt:
                    {
                        handleRequestNotificationPermission();
                    }
                    break;
                case PermissionState.granted:
                    {
                        updateDeviceToken();
                    }
                    break;
                case PermissionState.denied:
                    {
                        // do something else
                    }
                    break;
                default:
                    break;
            }

            result.onchange = () => {
                browserPermissionState.value.notification =
                    result.state as PermissionState;
            };
        }
    );
};

export const handleRequestNotificationPermission = async () => {
    const data: NotificationPermission =
        await window.Notification.requestPermission();
    if (data === PermissionState.granted) {
        browserPermissionState.value.notification = PermissionState.granted;
        updateDeviceToken();
    } else {
        // do something else
        browserPermissionState.value.notification = PermissionState.denied;
    }
    return data;
};

export const dashboardMenuList = ref<any>([]);
export const isShowPlanAndPricingOverviewModal = ref(false);
