<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 770.1 633.18"
  >
    <defs>
      <linearGradient
        id="b"
        x1="387.6"
        y1="558.65"
        x2="387.6"
        y2="32.66"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fcb33a" />
        <stop offset="1" stop-color="#c67c29" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="387.6"
        y1="558.65"
        x2="387.6"
        y2="32.66"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#b46928" />
        <stop offset="1" stop-color="#ffd97b" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="387.6"
        y1="558.65"
        x2="387.6"
        y2="295.66"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#d1a24d" />
        <stop offset="1" stop-color="#010101" />
      </linearGradient>
      <linearGradient
        id="e"
        x1="387.6"
        y1="295.66"
        x2="387.6"
        y2="0"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#010101" />
        <stop offset="1" stop-color="#d1a24d" />
      </linearGradient>
      <linearGradient
        id="f"
        x1="149.89"
        y1="489.46"
        x2="149.89"
        y2="108.05"
        xlink:href="#c"
      />
      <radialGradient
        id="g"
        cx="89.19"
        cy="132.49"
        fx="89.19"
        fy="132.49"
        r="346.45"
        gradientTransform="translate(-29.9 -1.94) scale(1.04 1.09)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#ffd58d" />
        <stop offset="0" stop-color="#feef8f" />
        <stop offset="1" stop-color="#010101" />
      </radialGradient>
      <linearGradient
        id="h"
        x1="40.93"
        y1="313.39"
        x2="40.93"
        y2="214.89"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#ec9f23" />
        <stop offset="1" stop-color="#ffdb59" />
      </linearGradient>
      <linearGradient
        id="i"
        x1="0"
        y1="264.14"
        x2="78.72"
        y2="264.14"
        xlink:href="#b"
      />
      <radialGradient
        id="j"
        cx="20.07"
        cy="199.46"
        fx="20.07"
        fy="199.46"
        r="54.61"
        xlink:href="#g"
      />
      <radialGradient
        id="k"
        cx="55.93"
        cy="203.66"
        fx="55.93"
        fy="203.66"
        r="51.72"
        xlink:href="#g"
      />
      <radialGradient
        id="l"
        cx="73.72"
        cy="215.74"
        fx="73.72"
        fy="215.74"
        r="53.96"
        xlink:href="#g"
      />
      <radialGradient
        id="m"
        cx="58.46"
        cy="214.81"
        fx="58.46"
        fy="214.81"
        r="127.32"
        gradientTransform="translate(-29.9 -1.94) scale(1.04 1.09)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".44" stop-color="#fff" />
        <stop offset="1" stop-color="#d0a14d" />
      </radialGradient>
      <radialGradient
        id="n"
        cx="40.95"
        cy="244.01"
        fx="40.95"
        fy="244.01"
        r="47.14"
        gradientTransform="translate(-29.9 -1.94) scale(1.04 1.09)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fdbd4f" />
        <stop offset="0" stop-color="#feef8f" />
        <stop offset="1" stop-color="#010101" />
      </radialGradient>
      <linearGradient
        id="o"
        x1="82.77"
        y1="316.91"
        x2="-5.29"
        y2="221.45"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#f7a436" />
        <stop offset="1" stop-color="#fef49e" />
      </linearGradient>
      <linearGradient
        id="p"
        x1="126.7"
        y1="351.47"
        x2="126.7"
        y2="241.77"
        xlink:href="#h"
      />
      <linearGradient
        id="q"
        x1="112.02"
        y1="296.62"
        x2="159.21"
        y2="296.62"
        xlink:href="#b"
      />
      <radialGradient
        id="r"
        cx="154.77"
        cy="275.62"
        fx="154.77"
        fy="275.62"
        r="54.18"
        xlink:href="#m"
      />
      <radialGradient
        id="s"
        cx="150.7"
        cy="233.9"
        fx="150.7"
        fy="233.9"
        r="42.86"
        xlink:href="#g"
      />
      <radialGradient
        id="t"
        cx="178.39"
        cy="243.88"
        fx="178.39"
        fy="243.88"
        r="63.49"
        xlink:href="#n"
      />
      <radialGradient
        id="u"
        cx="178.88"
        cy="206.54"
        fx="178.88"
        fy="206.54"
        r="62.47"
        xlink:href="#g"
      />
      <radialGradient
        id="v"
        cx="138.73"
        cy="261.62"
        fx="138.73"
        fy="261.62"
        r="43.84"
        xlink:href="#g"
      />
      <linearGradient
        id="w"
        x1="133.56"
        y1="354.19"
        x2="129.17"
        y2="234.26"
        xlink:href="#o"
      />
      <linearGradient
        id="x"
        x1="52.58"
        y1="202.54"
        x2="52.58"
        y2="101.22"
        xlink:href="#h"
      />
      <radialGradient
        id="y"
        cx="53.86"
        cy="106.2"
        fx="53.86"
        fy="106.2"
        r="56.99"
        xlink:href="#g"
      />
      <linearGradient
        id="a`"
        x1="38.79"
        y1="151.88"
        x2="82.51"
        y2="151.88"
        xlink:href="#b"
      />
      <radialGradient
        id="aa"
        cx="69.91"
        cy="143.39"
        fx="69.91"
        fy="143.39"
        r="53.24"
        xlink:href="#m"
      />
      <radialGradient
        id="ab"
        cx="73.02"
        cy="107.47"
        fx="73.02"
        fy="107.47"
        r="52.95"
        xlink:href="#n"
      />
      <linearGradient
        id="ac"
        x1="71.75"
        y1="221.1"
        x2="32.99"
        y2="105.04"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#f1af56" />
        <stop offset="1" stop-color="#feef7b" />
      </linearGradient>
      <radialGradient
        id="ad"
        cx="88.74"
        cy="114.68"
        fx="88.74"
        fy="114.68"
        r="38.99"
        xlink:href="#n"
      />
      <linearGradient
        id="ae"
        x1="122.14"
        y1="134.83"
        x2="122.14"
        y2="48.43"
        xlink:href="#h"
      />
      <linearGradient
        id="af"
        x1="84.57"
        y1="90.99"
        x2="159.71"
        y2="90.99"
        xlink:href="#b"
      />
      <radialGradient
        id="ag"
        cx="139.08"
        cy="98.02"
        fx="139.08"
        fy="98.02"
        r="28.2"
        xlink:href="#m"
      />
      <radialGradient
        id="ah"
        cx="156.49"
        cy="70.35"
        fx="156.49"
        fy="70.35"
        r="25.06"
        xlink:href="#n"
      />
      <radialGradient
        id="ai"
        cx="98.22"
        cy="111.62"
        fx="98.22"
        fy="111.62"
        r="43.45"
        xlink:href="#n"
      />
      <radialGradient
        id="aj"
        cx="167.83"
        cy="64.3"
        fx="167.83"
        fy="64.3"
        r="59.36"
        xlink:href="#g"
      />
      <radialGradient
        id="ak"
        cx="167.44"
        cy="39.86"
        fx="167.44"
        fy="39.86"
        r="44.07"
        xlink:href="#g"
      />
      <radialGradient
        id="al"
        cx="158.99"
        cy="83.38"
        fx="158.99"
        fy="83.38"
        r="49.6"
        xlink:href="#g"
      />
      <linearGradient
        id="am"
        x1="89.41"
        y1="127.73"
        x2="155.71"
        y2="46.51"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#ffcc5b" />
        <stop offset="1" stop-color="#feef7b" />
      </linearGradient>
      <linearGradient
        id="an"
        x1="114.8"
        y1="250.44"
        x2="114.8"
        y2="153.7"
        xlink:href="#h"
      />
      <radialGradient
        id="ao"
        cx="179.3"
        cy="206.42"
        fx="179.3"
        fy="206.42"
        r="53.95"
        xlink:href="#g"
      />
      <linearGradient
        id="ap"
        x1="70.68"
        y1="240.86"
        x2="146.71"
        y2="151.63"
        xlink:href="#b"
      />
      <radialGradient
        id="aq"
        cx="128.63"
        cy="198.68"
        fx="128.63"
        fy="198.68"
        r="28.66"
        xlink:href="#m"
      />
      <radialGradient
        id="ar"
        cx="137.83"
        cy="130.65"
        fx="137.83"
        fy="130.65"
        r="64.36"
        xlink:href="#n"
      />
      <radialGradient
        id="as"
        cx="160.26"
        cy="139.39"
        fx="160.26"
        fy="139.39"
        r="44.76"
        xlink:href="#g"
      />
      <linearGradient
        id="at"
        x1="87.62"
        y1="252.63"
        x2="149.13"
        y2="160.85"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#f19020" />
        <stop offset="1" stop-color="#feef7b" />
      </linearGradient>
      <linearGradient
        id="au"
        x1="71.28"
        y1="413.52"
        x2="71.28"
        y2="340.27"
        xlink:href="#h"
      />
      <radialGradient
        id="av"
        cx="70"
        cy="310.23"
        fx="70"
        fy="310.23"
        r="37.25"
        xlink:href="#g"
      />
      <radialGradient
        id="aw"
        cx="92.73"
        cy="310.68"
        fx="92.73"
        fy="310.68"
        r="37.39"
        xlink:href="#g"
      />
      <linearGradient
        id="ax"
        x1="17.87"
        y1="377.99"
        x2="124.7"
        y2="377.99"
        xlink:href="#b"
      />
      <radialGradient
        id="ay"
        cx="102"
        cy="336.2"
        fx="102"
        fy="336.2"
        r="63.72"
        xlink:href="#m"
      />
      <radialGradient
        id="b`"
        cx="50.71"
        cy="295.6"
        fx="50.71"
        fy="295.6"
        r="54.87"
        xlink:href="#g"
      />
      <radialGradient
        id="ba"
        cx="63.36"
        cy="320.95"
        fx="63.36"
        fy="320.95"
        r="59.15"
        xlink:href="#n"
      />
      <linearGradient
        id="bb"
        x1="123.14"
        y1="404.2"
        x2="14.29"
        y2="344.86"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#d18a2c" />
        <stop offset="1" stop-color="#fef49e" />
      </linearGradient>
      <linearGradient
        id="bc"
        x1="126.3"
        y1="482.41"
        x2="126.3"
        y2="101"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#c"
      />
      <radialGradient
        id="bd"
        cx="37.16"
        cy="126.02"
        fx="37.16"
        fy="126.02"
        r="346.45"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <linearGradient
        id="be"
        x1="17.33"
        y1="306.33"
        x2="17.33"
        y2="207.83"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#h"
      />
      <linearGradient
        id="bf"
        x1="-23.6"
        y1="257.08"
        x2="55.13"
        y2="257.08"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#b"
      />
      <radialGradient
        id="bg"
        cx="731.3"
        cy="192.98"
        fx="731.3"
        fy="192.98"
        r="54.61"
        xlink:href="#g"
      />
      <radialGradient
        id="bh"
        cx="3.9"
        cy="197.19"
        fx="3.9"
        fy="197.19"
        r="51.72"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <radialGradient
        id="bi"
        cx="21.68"
        cy="209.26"
        fx="21.68"
        fy="209.26"
        r="53.96"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <radialGradient
        id="bj"
        cx="6.42"
        cy="208.34"
        fx="6.42"
        fy="208.34"
        r="127.32"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#m"
      />
      <radialGradient
        id="bk"
        cx="717.43"
        cy="237.54"
        fx="717.43"
        fy="237.54"
        r="47.14"
        xlink:href="#n"
      />
      <linearGradient
        id="bl"
        x1="59.17"
        y1="309.85"
        x2="-28.89"
        y2="214.39"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#o"
      />
      <linearGradient
        id="bm"
        x1="103.1"
        y1="344.41"
        x2="103.1"
        y2="234.71"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#h"
      />
      <linearGradient
        id="bn"
        x1="88.42"
        y1="289.56"
        x2="135.62"
        y2="289.56"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#b"
      />
      <radialGradient
        id="bo"
        cx="102.74"
        cy="269.15"
        fx="102.74"
        fy="269.15"
        r="54.18"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#m"
      />
      <radialGradient
        id="bp"
        cx="98.67"
        cy="227.42"
        fx="98.67"
        fy="227.42"
        r="42.86"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <radialGradient
        id="bq"
        cx="646.21"
        cy="237.4"
        fx="646.21"
        fy="237.4"
        r="63.49"
        xlink:href="#n"
      />
      <radialGradient
        id="br"
        cx="126.85"
        cy="200.06"
        fx="126.85"
        fy="200.06"
        r="62.47"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <radialGradient
        id="bs"
        cx="86.69"
        cy="255.14"
        fx="86.69"
        fy="255.14"
        r="43.84"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <linearGradient
        id="bt"
        x1="109.96"
        y1="347.13"
        x2="105.57"
        y2="227.2"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#o"
      />
      <linearGradient
        id="bu"
        x1="28.99"
        y1="195.48"
        x2="28.99"
        y2="94.16"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#h"
      />
      <radialGradient
        id="bv"
        cx="1.83"
        cy="99.72"
        fx="1.83"
        fy="99.72"
        r="56.99"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <linearGradient
        id="bw"
        x1="15.19"
        y1="144.82"
        x2="58.91"
        y2="144.82"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#b"
      />
      <radialGradient
        id="bx"
        cx="17.88"
        cy="136.92"
        fx="17.88"
        fy="136.92"
        r="53.24"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#m"
      />
      <radialGradient
        id="by"
        cx="20.99"
        cy="101"
        fx="20.99"
        fy="101"
        r="52.95"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#n"
      />
      <linearGradient
        id="c`"
        x1="48.15"
        y1="214.05"
        x2="9.4"
        y2="97.99"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#ac"
      />
      <radialGradient
        id="ca"
        cx="36.71"
        cy="108.2"
        fx="36.71"
        fy="108.2"
        r="38.99"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#n"
      />
      <linearGradient
        id="cb"
        x1="98.54"
        y1="127.77"
        x2="98.54"
        y2="41.37"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#h"
      />
      <linearGradient
        id="cc"
        x1="60.97"
        y1="83.94"
        x2="136.11"
        y2="83.94"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#b"
      />
      <radialGradient
        id="cd"
        cx="87.05"
        cy="91.54"
        fx="87.05"
        fy="91.54"
        r="28.2"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#m"
      />
      <radialGradient
        id="ce"
        cx="104.46"
        cy="63.87"
        fx="104.46"
        fy="63.87"
        r="25.06"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#n"
      />
      <radialGradient
        id="cf"
        cx="46.19"
        cy="105.15"
        fx="46.19"
        fy="105.15"
        r="43.45"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#n"
      />
      <radialGradient
        id="cg"
        cx="115.8"
        cy="57.83"
        fx="115.8"
        fy="57.83"
        r="59.36"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <radialGradient
        id="ch"
        cx="115.4"
        cy="33.38"
        fx="115.4"
        fy="33.38"
        r="44.07"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <radialGradient
        id="ci"
        cx="106.96"
        cy="76.9"
        fx="106.96"
        fy="76.9"
        r="49.6"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <linearGradient
        id="cj"
        x1="65.81"
        y1="120.68"
        x2="132.12"
        y2="39.45"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#am"
      />
      <linearGradient
        id="ck"
        x1="91.2"
        y1="243.38"
        x2="91.2"
        y2="146.65"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#h"
      />
      <radialGradient
        id="cl"
        cx="127.27"
        cy="199.95"
        fx="127.27"
        fy="199.95"
        r="53.95"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <linearGradient
        id="cm"
        x1="47.09"
        y1="233.81"
        x2="123.11"
        y2="144.57"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#b"
      />
      <radialGradient
        id="cn"
        cx="76.6"
        cy="192.21"
        fx="76.6"
        fy="192.21"
        r="28.66"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#m"
      />
      <radialGradient
        id="co"
        cx="85.8"
        cy="124.17"
        fx="85.8"
        fy="124.17"
        r="64.36"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#n"
      />
      <radialGradient
        id="cp"
        cx="108.23"
        cy="132.92"
        fx="108.23"
        fy="132.92"
        r="44.76"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <linearGradient
        id="cq"
        x1="64.03"
        y1="245.58"
        x2="125.54"
        y2="153.79"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#at"
      />
      <linearGradient
        id="cr"
        x1="47.69"
        y1="406.47"
        x2="47.69"
        y2="333.21"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#h"
      />
      <radialGradient
        id="cs"
        cx="17.97"
        cy="303.76"
        fx="17.97"
        fy="303.76"
        r="37.25"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <radialGradient
        id="ct"
        cx="40.7"
        cy="304.21"
        fx="40.7"
        fy="304.21"
        r="37.39"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <linearGradient
        id="cu"
        x1="-5.73"
        y1="370.94"
        x2="101.1"
        y2="370.94"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#b"
      />
      <radialGradient
        id="cv"
        cx="49.97"
        cy="329.72"
        fx="49.97"
        fy="329.72"
        r="63.72"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#m"
      />
      <radialGradient
        id="cw"
        cx="-1.33"
        cy="289.13"
        fx="-1.33"
        fy="289.13"
        r="54.87"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#g"
      />
      <radialGradient
        id="cx"
        cx="11.33"
        cy="314.47"
        fx="11.33"
        fy="314.47"
        r="59.15"
        gradientTransform="translate(745.92 -1.94) rotate(-180) scale(1.04 -1.09)"
        xlink:href="#n"
      />
      <linearGradient
        id="cy"
        x1="99.55"
        y1="397.14"
        x2="-9.3"
        y2="337.81"
        gradientTransform="translate(746.5) rotate(-180) scale(1 -1)"
        xlink:href="#bb"
      />
      <radialGradient
        id="d`"
        cx="406.1"
        cy="328.49"
        fx="406.1"
        fy="328.49"
        r="331.25"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#f26322" />
        <stop offset=".38" stop-color="#f78c1e" />
        <stop offset=".7" stop-color="#febf10" />
        <stop offset=".84" stop-color="#ffc808" />
        <stop offset=".88" stop-color="#fdcc0c" />
        <stop offset=".91" stop-color="#fbd515" />
        <stop offset=".97" stop-color="#ed8b1e" />
        <stop offset="1" stop-color="#e55c25" />
      </radialGradient>
      <radialGradient
        id="da"
        cx="232.14"
        cy="429.25"
        fx="232.14"
        fy="429.25"
        r="107.85"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fbaa19" />
        <stop offset="1" stop-color="#010101" />
      </radialGradient>
      <radialGradient
        id="db"
        cx="144.83"
        fx="144.83"
        r="331.25"
        gradientTransform="translate(513.93) rotate(-180) scale(1 -1)"
        xlink:href="#d`"
      />
      <radialGradient
        id="dc"
        cx="-29.13"
        fx="-29.13"
        r="107.85"
        gradientTransform="translate(513.93) rotate(-180) scale(1 -1)"
        xlink:href="#da"
      />
      <radialGradient
        id="dd"
        cx="216.25"
        cy="379.07"
        fx="216.25"
        fy="379.07"
        r="191.82"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".45" stop-color="#fff" />
        <stop offset=".51" stop-color="#fdf9f2" />
        <stop offset=".62" stop-color="#f8e9d0" />
        <stop offset=".77" stop-color="#f0d199" />
        <stop offset=".95" stop-color="#e6af4e" />
        <stop offset="1" stop-color="#e3a639" />
      </radialGradient>
      <radialGradient
        id="de"
        cx="176.98"
        cy="554.3"
        fx="176.98"
        fy="554.3"
        r="219.44"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".45" stop-color="#fff" />
        <stop offset=".48" stop-color="#fef3e9" />
        <stop offset=".55" stop-color="#fbcea2" />
        <stop offset=".63" stop-color="#f9b06a" />
        <stop offset=".69" stop-color="#f79b41" />
        <stop offset=".73" stop-color="#f68d28" />
        <stop offset=".76" stop-color="#f6891f" />
      </radialGradient>
      <radialGradient
        id="df"
        cx="365.44"
        cy="428.3"
        fx="365.44"
        fy="428.3"
        r="208.96"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#febc11" />
        <stop offset=".73" stop-color="#febd14" />
        <stop offset=".93" stop-color="#ffdb5d" />
        <stop offset=".95" stop-color="#fcd65b" />
        <stop offset=".96" stop-color="#f5ca56" />
        <stop offset=".97" stop-color="#eab44e" />
        <stop offset=".98" stop-color="#da9743" />
        <stop offset=".99" stop-color="#c67135" />
        <stop offset="1" stop-color="#b04725" />
      </radialGradient>
      <radialGradient
        id="dg"
        cx="279.76"
        cy="462.42"
        fx="279.76"
        fy="462.42"
        r="142.58"
        xlink:href="#dd"
      />
      <radialGradient
        id="dh"
        cx="231.82"
        cy="609.02"
        fx="231.82"
        fy="609.02"
        r="166.91"
        xlink:href="#de"
      />
      <radialGradient
        id="di"
        cx="92.13"
        fx="92.13"
        r="191.82"
        gradientTransform="translate(651.08) rotate(-180) scale(1 -1)"
        xlink:href="#dd"
      />
      <radialGradient
        id="dj"
        cx="52.86"
        fx="52.86"
        r="219.44"
        gradientTransform="translate(651.08) rotate(-180) scale(1 -1)"
        xlink:href="#de"
      />
      <radialGradient
        id="dk"
        cx="241.32"
        fx="241.32"
        r="208.96"
        gradientTransform="translate(651.08) rotate(-180) scale(1 -1)"
        xlink:href="#df"
      />
      <radialGradient
        id="dl"
        cx="155.65"
        cy="462.42"
        fx="155.65"
        fy="462.42"
        r="142.58"
        gradientTransform="translate(651.08) rotate(-180) scale(1 -1)"
        xlink:href="#dd"
      />
      <radialGradient
        id="dm"
        cx="107.7"
        cy="609.02"
        fx="107.7"
        fy="609.02"
        r="166.92"
        gradientTransform="translate(651.08) rotate(-180) scale(1 -1)"
        xlink:href="#de"
      />
      <linearGradient
        id="dn"
        x1="87.3"
        y1="463.98"
        x2="387.6"
        y2="463.98"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff9d3" />
        <stop offset="1" stop-color="#faa71a" />
      </linearGradient>
      <linearGradient
        id="do"
        x1="387.6"
        y1="463.98"
        x2="687.9"
        y2="463.98"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#faa71a" />
        <stop offset="1" stop-color="#fff9d3" />
      </linearGradient>
      <linearGradient
        id="dp"
        x1="387.6"
        y1="625.72"
        x2="387.6"
        y2="504.82"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fcb33a" />
        <stop offset="1" stop-color="#b35126" />
      </linearGradient>
      <linearGradient
        id="dq"
        x1="387.6"
        y1="633.18"
        x2="387.6"
        y2="497.35"
        xlink:href="#b"
      />
      <linearGradient
        id="dr"
        x1="387.6"
        y1="633.18"
        x2="387.6"
        y2="497.35"
        xlink:href="#c"
      />
      <radialGradient
        id="ds"
        cx="366.95"
        cy="529.5"
        fx="366.95"
        fy="529.5"
        r="10.9"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset=".27" stop-color="#a1cff0" />
        <stop offset=".38" stop-color="#84a8cf" />
        <stop offset=".55" stop-color="#5a6fa0" />
        <stop offset=".7" stop-color="#3b457e" />
        <stop offset=".82" stop-color="#282c68" />
        <stop offset=".89" stop-color="#222361" />
      </radialGradient>
      <radialGradient
        id="dt"
        cx="351.21"
        cy="544.62"
        fx="351.21"
        fy="544.62"
        r="7.96"
        xlink:href="#ds"
      />
      <radialGradient
        id="du"
        cx="424.07"
        cy="586.5"
        fx="424.07"
        fy="586.5"
        r="7.96"
        xlink:href="#ds"
      />
    </defs>
    <g class="iv">
      <g id="a">
        <g>
          <path
            class="ex"
            d="M116.67,289.22v121.85c-27.84-34.21-49.22-89.63,0-121.85h0Z"
          />
          <path
            class="ex"
            d="M658.53,289.22v121.85c27.84-34.21,49.22-89.63,0-121.85h0Z"
          />
          <path
            class="ex"
            d="M179.48,458.05l20.94,71.05c-20.01-10.16-29.07-27.42-31.46-34.52-4.19-12.47-3.51-27.34,10.52-36.53h0Z"
          />
          <g>
            <path
              class="gs"
              d="M387.6,65.33c127.21,0,230.33,103.12,230.33,230.33s-103.12,230.33-230.33,230.33-230.33-103.12-230.33-230.33,103.12-230.33,230.33-230.33h0Zm262.99,230.33c0-145.25-117.75-262.99-262.99-262.99S124.61,150.41,124.61,295.66s117.74,262.99,262.99,262.99,262.99-117.75,262.99-262.99h0Z"
            />
            <path
              class="ff"
              d="M387.6,558.65c145.25,0,262.99-117.75,262.99-262.99S532.85,32.66,387.6,32.66,124.61,150.41,124.61,295.66s117.74,262.99,262.99,262.99h0Zm295.66-262.99c0,163.29-132.37,295.66-295.66,295.66S91.94,458.95,91.94,295.66,224.31,0,387.6,0s295.66,132.37,295.66,295.66h0Z"
            />
            <path
              class="gt"
              d="M387.6,525.99c-127.21,0-230.33-103.12-230.33-230.33-4.81,.75-10.38,1.18-16.33,1.18s-11.53-.43-16.33-1.18c0,145.25,117.74,262.99,262.99,262.99s262.99-117.75,262.99-262.99c-4.8,.75-10.38,1.18-16.33,1.18s-11.53-.43-16.33-1.18c0,127.21-103.12,230.33-230.33,230.33h0Z"
            />
            <path
              class="gw"
              d="M124.61,295.66c0-145.25,117.74-262.99,262.99-262.99s262.99,117.75,262.99,262.99c4.81-.75,10.38-1.18,16.33-1.18s11.53,.43,16.33,1.18C683.26,132.37,550.89,0,387.6,0S91.94,132.37,91.94,295.66c4.8-.75,10.38-1.18,16.33-1.18s11.53,.43,16.33,1.18h0Z"
            />
            <path
              class="gh"
              d="M387.6,32.66c70.31,0,134.18,27.59,181.37,72.54-46.7-47.33-110.73-76.52-181.37-76.52-33.24,0-65.01,6.46-94.21,18.24-4.63-7.9-15.74-10.55-25.51-5.86-10.02,4.81-14.9,15.54-11.13,24.21-4.88,2.95-9.65,6.07-14.32,9.34-2.93-3.35-8.48-3.58-12.69-.42-4.25,3.18-5.57,8.64-3.1,12.39-7.12,5.84-13.93,12.05-20.4,18.62,47.18-44.95,111.05-72.54,181.37-72.54h0Z"
            />
          </g>
          <g>
            <g>
              <path
                class="fj"
                d="M211.31,487.78c-47.45-29.93-84.88-73.19-108.72-124.11-36.3-77.54-43.48-183.67,4.09-255.61-32.15,73.63-33.67,161.33,10.09,248.05,23.75,47.08,59.67,86.95,104.86,113.95h0c4.75,2.84,6.4,9.18,3.7,14.16-2.71,4.98-8.75,6.72-13.5,3.88-.17-.1-.34-.21-.5-.32h0Z"
              />
              <path
                class="ey"
                d="M177.55,459.35c-24.9-21.42-40.35-39.67-57.61-68.25-24.52-40.6-38.62-85.04-43.54-132.8-4.53-43.92,1.2-73.47,13.04-113.03-9.73,41.55-13.81,71.37-8.81,112.05,5.79,47.08,19.8,90.86,45.05,129.39,18.59,28.37,35.35,41.68,57.78,64.92,4.82,5,4.58,7.35,3.41,9-1.37,1.94-4.78,2.62-9.32-1.29h0Z"
              />
              <path
                class="fd"
                d="M46.66,313.21c-6.9-.97-13.99-3.95-19.2-9.04-10.05-9.81-17.08-19.54-20.87-30.36,3.57,2.39,6.92,3.86,11.64,5.08-4.4-2.25-9.75-6.54-13.13-10.08-.09-.36-.18-.72-.26-1.08-3.44-14.97-2.21-41.33-4.83-52.84,9.81,5.87,21.7,9.88,33.26,14.79,1.71,.73,3.44,1.49,5.18,2.28,.33,2.23,.52,4.75,.83,6.78,.34,2.28,1.67,1.59,2.56-.09,.73-1.37,1.16-3.04,1.32-4.48,19.24,9.4,38.18,23.03,38.69,43.01,.27,10.81-6.13,18.88-3.12,30.47-8.01-.22-22.1,6.95-32.06,5.55h0Z"
              />
              <path
                class="gq"
                d="M45.65,281.43C25.85,261.32,14.18,239.3,0,214.89c2.62,11.51,1.39,37.88,4.83,52.84,.08,.36,.17,.72,.26,1.08,3.38,3.53,8.73,7.83,13.13,10.08-4.72-1.22-8.07-2.69-11.64-5.08,3.79,10.83,10.82,20.55,20.87,30.36,5.22,5.09,12.3,8.07,19.2,9.04,9.96,1.4,24.05-5.77,32.06-5.55-12.37-8.74-22.21-15.2-33.07-26.23h0Z"
              />
              <path
                class="ea"
                d="M4.19,240.27c.16-7.25,1.24-14.17,4.41-15.74,4.26-2.11,14.75,1.36,22.35,4.17-10.82-4.49-21.79-8.35-30.95-13.82,2.62,11.51,1.39,37.88,4.83,52.84,.08,.36,.17,.72,.26,1.08,.53,.56,1.12,1.13,1.73,1.71-2.15-9.83-2.86-20.12-2.63-30.25h0Zm2.4,33.53c.93,2.67,2.07,5.27,3.39,7.83-.82-2.31-1.54-4.66-2.17-7.04-.41-.25-.81-.51-1.22-.78h0Z"
              />
              <path
                class="er"
                d="M33.98,268.54c3.99-13.35,5.35-23.81,3.15-37.18-1.3-.58-2.59-1.14-3.87-1.69-2.24-.95-4.49-1.87-6.73-2.77,6.09,12.11,5.03,29.88,3.07,35.9,1.41,1.98,2.88,3.88,4.39,5.74h0Z"
              />
              <path
                class="gj"
                d="M48.63,236.98c-1.31,12.94-7.1,26.77-12,34.71,4.66,5.36,9.71,10.29,14.99,15.11,7.75-11.23,7.92-30.95,6.17-44.39-2.94-1.94-6.03-3.74-9.16-5.43h0Z"
              />
              <path
                class="fp"
                d="M64.82,259.25c12.16,31.26-22.06,60.02-52.59,26.37,3.87,6.34,8.96,12.43,15.23,18.55,5.22,5.09,12.3,8.07,19.2,9.04,9.96,1.4,24.05-5.77,32.06-5.55-3.01-11.59,3.4-19.66,3.12-30.47-.47-18.43-16.63-31.46-34.23-40.75,8.38,6.91,12.41,10.46,17.21,22.82h0Z"
              />
              <path
                class="hk"
                d="M13.52,287.66c11.91,3.44,25.69,2.79,37.09-1.72-1.46-1.33-2.91-2.69-4.38-4.09-11.96,3.87-25.96,2.96-36.53-.75,1.13,2.22,2.4,4.4,3.82,6.56h0Zm39.38,.34c-10.35,4.73-19.07,6.4-29.81,6.07,.84,.96,1.92,1.98,3.18,2.34,11.26,3.18,21.35,.48,31.09-4.54-1.53-1.3-3-2.58-4.45-3.87h0Z"
              />
              <path
                class="in"
                d="M0,214.89c9.59,16.44,18.5,31.51,29.47,45.79,1.68-12.12,2.09-18.66-1.71-31.01,5.41,11.71,4.48,20.68,3.27,33.02,6.11,7.78,12.88,15.33,20.76,22.75,6.52-8.33,7.55-30.29,6.4-40.37,2.77,9.36,1.99,33.11-4.7,41.96,9.37,8.53,19.77,14.21,29.67,21.59,1.93,1.44,2.61,5.4,2.46,6.72-5.86-7.07-16.45-12.89-23.9-18.61-1.52-1.17-3.42-1.36-5.2-.51-9.84,4.71-20.75,6.86-30.84,4.03,10.57,1.48,21.51-.58,32.22-6.53-2.21-1.78-4.39-3.61-6.5-5.41-2.87-2.45-5.56-4.95-8.1-7.5-.78-.78-1.79-1.09-2.88-.87-10.83,2.13-22.28,1.04-31.12-4.39,10.26,4.01,20.16,4.95,31.06,2.23-5.96-6.35-11.1-13.01-15.8-19.99-.86-1.28-1.91-2.15-3.31-2.73-6.69-2.8-11.4-5.14-16.64-10.8,6.97,4.87,9.66,6.48,17.16,9.27-7.66-11.98-14.3-24.85-21.78-38.62H0Z"
              />
              <path
                class="es"
                d="M152.29,331.17c4.16-7.34,6.24-16.15,6.82-25.56-1.72,3.18-3.99,5.51-6.17,6.62,1.35-2.68,4.88-10.9,6.28-16-.66-18.98-6.33-39.24-12.7-54.46-10.45,7.98-21.96,18.15-31.6,29.67-3.63,4.34-5.63,8.8-4.53,14.37-2.11-.99-3.27-2.37-3.5-3.75-5.66,8.39-10.04,17.3-12.18,26.47-1.49,6.4,.38,13.55,3.86,20.9,3.48,7.34,8.94,14.68,13.46,22.05,6.45-1.85,15.68-3.14,23.34-6.93,7.66-3.79,14.25-8.65,16.92-13.38h0Z"
              />
              <path
                class="eu"
                d="M146.51,241.77c6.37,15.22,12.04,35.48,12.7,54.46-1.4,5.1-4.93,13.32-6.28,16,2.18-1.11,4.45-3.44,6.17-6.62-.58,9.41-2.66,18.21-6.82,25.56-2.68,4.72-9.26,9.59-16.92,13.38-7.66,3.79-16.89,5.08-23.34,6.93,6.14-14.96,14.1-26.86,19.04-41.73,6.95-20.92,11.09-44.92,15.45-67.97h0Z"
              />
              <path
                class="fy"
                d="M123.64,334.63c14.94-3.62,33.29-18.96,35.41-28.07-.66,9.06-2.73,17.51-6.76,24.61-2.68,4.72-9.26,9.59-16.92,13.38-7.66,3.79-16.89,5.08-23.34,6.93-4.52-7.37-9.98-14.71-13.46-22.05-3.48-7.34-5.36-14.49-3.86-20.9,1.45-6.2,3.92-12.28,7.12-18.17-4.88,16.43-3.43,50.39,21.82,44.27h0Z"
              />
              <path
                class="fi"
                d="M127.93,257.6c.87,6.26,2.06,10.87,4.25,16.6-1.68-6.91-2.6-12.54-1.77-18.93,1.37-1.26,2.74-2.5,4.12-3.7-.56,3.04-.69,4.94-.23,8.59,.74,6.02,2.05,10.99,5.35,16.12-1.29,5.4-2.57,10.81-3.86,16.22-6.91-9.18-10.85-19.05-11.21-31.61,1.11-1.11,2.23-2.21,3.35-3.28h0Z"
              />
              <path
                class="ft"
                d="M131.31,309.29c11.64-5.33,23.26-19.01,25.62-32.84,.97,5.17,1.69,10.4,2.06,15.58-6.95,16.17-15.34,23.85-34.07,31.61,2.67-5.02,4.19-9.22,6.39-14.35h0Zm15.11-14.73c-4.68,6-8.73,8.8-14.82,12.88l1.93-6.12c4.25-2.91,7.5-4.81,11.14-8.59,4.38-4.55,7.57-9.73,10.43-15.59-2.34,7.17-4.84,12.51-8.67,17.42h0Z"
              />
              <path
                class="gu"
                d="M159.05,292.83c-2.38-13.15-7.83-40.27-15.29-43.2-5.42-2.12-19.09,11.28-27.55,20.27,9.37-10.89,20.32-20.51,30.31-28.14,5.99,14.32,11.36,33.09,12.54,51.06h0Z"
              />
              <path
                class="ij"
                d="M126.5,321.05l-2.89,5.72c-9.94-11.03-13.59-23.32-13.27-36.37,2,7.27,5.5,12.15,9.54,19.19,2.51,4.38,4.41,6.61,6.63,11.47h0Z"
              />
              <path
                class="e`"
                d="M146.51,241.77c-3.03,11.56-5.1,22.28-7.31,32.81-2.37-4.03-4.88-12.48-5.13-20.77-.69,9.12,1.2,14.19,4.59,23.33-1.85,8.68-3.86,17.27-6.65,26.15-8.5-14.28-9.95-18.67-11.33-36.2-1.2,16.37,1.98,25.29,10.38,39.13-2.53,7.64-5.7,15.52-9.87,23.86-.12,.24-.24,.49-.36,.73-10.59-14.67-13.61-22.46-15.44-39.26,.04,16.58,5.07,28.78,14.38,41.36-3.04,5.98-6.14,12.04-7.75,18.57,2.15-3.43,1.12-1.65,2.99-5.18,3.34-6.31,3.7-5.91,6.97-6.92,13.69-4.2,27.05-11.63,34.44-24.1-10.69,12.87-21.32,18.58-37.29,22.77,1.15-2.36,2.33-4.74,3.59-7.09,2.96-5.52,3.88-6.86,7.23-8.5,12.65-6.19,20.81-12.6,27.37-27.24-7.97,14.59-14.52,19.5-30.1,26.5,2.75-6.26,4.95-12.61,6.85-19.24,1.73-6.05,1.62-5.47,4.32-7.67,9.06-7.39,13.35-12.98,15.82-25.95-4.24,13.4-9,19.9-17.89,25.09,3.66-15.06,6.28-31.83,10.2-52.18h0Z"
              />
              <path
                class="hg"
                d="M65.51,202.54c3.77-12.71,19.06-19.06,16.77-38.75-1.2-10.3-7.45-18.84-16.11-27.93-8.54-8.96-26.98-20.52-27.07-34.64-3.67,11.1-8.84,15.53-11.6,24.57-1.23,4.03-2.4,9.11-3.28,14.62-.68,4.25-.05,8.11,1.93,11.89,.8,1.52,1.75,3.06,2.89,4.76-.82-.54-2.78-2.72-3.4-3.31-.44-.42-1.21-.24-1.71-.58-.4-.27-.73-.6-1.03-.99-.72,11.11,.16,22.29,4.18,29.3,8.7,15.17,22.17,18.88,38.44,21.07h0Z"
              />
              <path
                class="gm"
                d="M28.57,122.76c1.3,6.39,3.25,11.72,7.09,19.62-2.25-9.56-3.34-18.44-3.38-26.93,2.29-3.98,4.78-8.08,6.82-14.24-1.03,20.66,.48,39.65,4.69,59.85-11.64-7.13-15.4-12.12-18.91-24.43,.42-2.22,.88-4.34,1.37-6.32,.76,1.65,1.7,3.17,2.75,4.61-.89-3.18-1.33-5.53-1.73-8.41,.07-.25,.14-.49,.22-.73,.33-1.07,.69-2.07,1.08-3.03h0Z"
              />
              <path
                class="iq"
                d="M45.31,165.61c-6.25-17.63-7.12-55.92-6.21-64.39,.09,14.12,18.53,25.68,27.07,34.64,8.66,9.09,14.91,17.64,16.11,27.93,2.3,19.68-12.99,26.04-16.77,38.75-7-13.19-15.61-23.96-20.2-36.93h0Z"
              />
              <path
                class="ep"
                d="M57.08,188.78c20.05-7.69,22.07-30.11,13.77-47.76,6.18,7.26,10.46,14.44,11.43,22.77,2.3,19.68-12.99,26.04-16.77,38.75-16.27-2.19-29.74-5.9-38.44-21.07-2.68-4.68-3.97-11.21-4.31-18.35,1.68,6.88,3.67,11.71,8.36,17.39,7.15,8.65,17.14,11.65,25.96,8.27h0Z"
              />
              <path
                class="ig"
                d="M59.65,150.99c-.39,12.25-3.49,17.48-9.23,25.79-.56-1.22-1.09-2.36-1.58-3.46,5.26-8.02,7.75-13.62,7.93-23.44-2.26,8.21-4.39,12.37-9.85,18.96-1.81-4.48-3.11-8.63-4.36-14.49,7.19-14.5,9.62-19.05,6.93-33.85,5.59,5.68,10.62,15.67,10.16,30.49h0Z"
              />
              <path
                class="fx"
                d="M39.1,101.22c-2.27,12-.14,43.44,3.37,58.36-10.25-6.85-11.95-8.85-17.19-21.29,3.35,11.76,6.76,15.84,15.15,21.95,3.88,2.82,2.21,1,3.94,5.66,1.6,4.32,3.83,8.85,6.36,13.37-13.5-4.52-20.22-10.73-27.18-21.86,5.33,11.5,13.71,17.35,24.52,22.68,2.58,1.27,3.73,1.12,5.23,3.63,1.13,1.89,2.29,3.76,3.46,5.6-4.64-1.09-17.46-6.17-25.33-13.41,8.15,8.24,16.4,12.04,26.83,15.74,2.52,3.89,14.04,22.19,15.91,26.85,1.31-10.88-1.44-7.03-12.24-25.57,7.59-16.08,18.19-25.84,12.26-45.15,3.91,19.06-5.33,26.21-13.68,43.08-2.23-2.91-5.06-6.94-7.57-11.22-1.01-1.72-1.52-2.49-.38-4.37,5.34-8.8,7.73-17.19,7.04-27.82-.06,12.58-2.77,18.53-9.12,27.73-3.24-6.37-5.4-13.42-6.98-20.43,5.23-11.68,9.35-19.24,6.34-32.51,1.08,14.69-.98,18.08-6.81,30.34-3.69-17.8-4.18-37.69-3.95-51.36h0Z"
              />
              <path
                class="hd"
                d="M61.21,168.27c4.61-12.93,4.39-26.88-.31-37.37,1.96,1.74,3.76,3.39,5.26,4.96,1.31,1.37,2.56,2.73,3.75,4.08,1.73,9.77-1.75,20.67-8.7,28.32h0Z"
              />
              <path
                class="en"
                d="M94.47,133.56c-.44-6-8.83-17.57-9.61-23.66-1.94-15.13,5.79-29.35,15.37-40.1-1.08,3.82-.44,7.53,.63,11.29,.24-4.85,2.35-12.88,5.56-17.14,8.55-6.72,20.43-11.84,30.15-13,7.89-.94,16.02-.81,23.13-2.52-5,4.25-7.74,8.31-9.47,13.45-2.41,3.28-4.34,4.92-7.76,7.96,2.66-.05,4.29-.49,6.09-1.2-1,5.49-1.61,12.23-2.69,21.09-.86,7.02-1.12,14.2-2.98,21-3.15,11.49-13.45,24.11-25.78,24.1-8.06,0-14.7-2.47-22.64-1.27h0Z"
              />
              <path
                class="el"
                d="M100.24,69.79c-9.58,10.76-17.31,24.97-15.37,40.1,.78,6.09,9.16,17.66,9.61,23.66,12.46-13.82,9.55-33.82,24.88-55.08,10.59-14.69,25.66-25.73,40.36-30.05-7.11,1.71-15.24,1.58-23.13,2.52-9.72,1.16-21.6,6.28-30.15,13-3.21,4.26-5.32,12.29-5.56,17.14-1.07-3.76-1.71-7.47-.63-11.29h0Z"
              />
              <path
                class="ia"
                d="M100.76,116.18c-10.44-10.73-9.64-29.55-4.79-41.17-7.46,9.95-12.74,22.09-11.1,34.89,.78,6.09,9.16,17.66,9.61,23.66,7.94-1.2,14.57,1.27,22.64,1.27,12.33,0,22.63-12.61,25.78-24.1,.91-3.32,1.44-6.73,1.84-10.17-5.58,20.14-27.56,32.48-43.97,15.62h0Z"
              />
              <path
                class="eq"
                d="M119.2,64.12c1.38-4.11,2.47-6.55,4.54-9.85-3.15,1.2-6.29,2.68-9.28,4.35-8.4,13.3-10.73,26.18-6.89,42.46,8.19-25.53,27.68-44.77,52.13-52.64-7.11,1.71-15.24,1.58-23.13,2.52-.21,.03-.43,.05-.65,.08-6.94,3.07-10.05,7.57-16.11,15.58-.41,.54-1.01,.21-1.01-.4,0-.71,.17-1.42,.39-2.09h0Z"
              />
              <path
                class="ge"
                d="M91.89,97.42c.89,5.31,2.99,10.14,5.68,15.74,.9,1.88,3.67,7.71,3.44,8.62-.51,1.98-2.16,5.54-4.4,8.79,1.74-2.78,1.89-4.84-.18-9.99-4.14-10.31-5.32-12.52-4.54-23.14h0Z"
              />
              <path
                class="gr"
                d="M129.54,101.33c4.97-1.77,11.9-6.92,16.5-12.98,.19-1.57,.36-3.07,.53-4.5-3.38,4.26-7.05,6.63-12.13,9.28-9.36,4.87-15.17,4.01-25.38,3.86-.79,2.17-1.48,4.26-2.12,6.31,8.46,1.01,16.16,.33,22.6-1.97h0Z"
              />
              <path
                class="gd"
                d="M120.35,65.9c4.57-4.27,11.6-10.02,17.22-12.42,2.63-1.13,9.23-3.17,11.81-.92,.81,.71,1.23,1.84,1.01,3.55-.59,4.48-4.31,9.33-6.82,12.76,2.77-2.45,4.53-4.08,6.68-6.99,1.73-5.14,4.47-9.2,9.47-13.45-7.11,1.71-15.24,1.58-23.13,2.52-.21,.03-.43,.05-.65,.08-6.73,2.98-9.86,7.3-15.57,14.87h0Z"
              />
              <path
                class="he"
                d="M106.12,106.04c-.66,2.59-1.34,5.11-2.08,7.68,3.35,.34,17.48,1.41,22.09-2.19,.98-.77,.75-1.61,2.49-3.37,1.8-1.83,3.98-2.95,5.65-5.09-9.03,3.99-17.17,4.44-28.15,2.98h0Z"
              />
              <path
                class="gg"
                d="M159.71,48.43c-13.46,4.41-26.98,13.56-37.93,27.82,10.85,.43,19.75-4.77,27.06-12.49-7.52,8.64-16.65,14.38-28.13,13.9-2.83,3.84-5.48,8.03-7.9,12.58-1.08,2.03-2.02,4.11-2.88,6.27,16.87,.28,25.69-3.2,36.04-11.69-5.37,6.15-11.81,9.83-17.96,11.85-4.16,1.37-11.16,.72-18.64,1.29-1.98,5.29-3.49,11.02-5.16,17.36-.1,.37-.2,.74-.31,1.1,16.33,1.05,27.6,1.35,38.88-12.2-4.18,6.18-9.03,10.51-14.54,12.46-5.36,1.9-15.71,.85-24.79,1.19-2.29,6.81-6.43,13.51-8.99,15.69,3.09-4.4,4.78-7.94,6.19-11.89-2.25-7.31-6.3-12.37-8.05-18.44-2.02-6.99-1.69-14.7,1.76-23.78-4.62,18.84,.55,24.83,6.81,40.7,.57-1.71,1.11-3.52,1.71-5.53,.87-2.93,1.63-5.91,2.42-8.9,1.18-4.47,1.02-5.16,.8-7.11-.75-6.68-1.44-12.75-.97-18.52,.3-3.71,3.89-11.92,7.95-19.23-6.76,14.42-7.66,21.92-5.64,37.35,.93-2.93,2.02-5.85,3.41-8.75,8.43-17.57,29.49-36.7,48.83-41.04h0Z"
              />
              <path
                class="hs"
                d="M82.88,250.44c.47-2.77,.33-5.66-.12-8.66-1.79-12.07-8.17-32.75,1.64-44.49,10.84-12.97,19.75-16.58,32.85-23.15,8.71-4.37,21.41-12.03,27.22-20.44,.05,8.92,6.49,19.11,6.05,30.94-.47,12.76-6.84,39.47-15.97,48.21-11.73,11.22-32.08,9.1-39.85,10.6-1.46,.28-9.38,4.58-11.82,6.99h0Z"
              />
              <path
                class="ic"
                d="M143.2,217.88c-6.24,2.96-14.74,4.5-24.32,2.41,6.52-.59,13.58-2.49,17.67-8.88-6.79,3.06-13.34,1.96-22.06,.53-5.7,3.29-10.44,5.23-15.24,9.75,14.35,6.1,22.88,10.81,38.54,7.19,2-3.01,3.82-6.82,5.41-10.99h0Z"
              />
              <path
                class="ej"
                d="M99.38,220.34c6.71-5.24,14.7-8.46,21.66-13.33,19.19-13.44,21.89-37.4,23.43-53.31-5.81,8.41-18.52,16.06-27.22,20.44-13.1,6.58-22.01,10.18-32.85,23.15-9.81,11.74-3.43,32.42-1.64,44.49,.45,3,.59,5.88,.12,8.66,3.47-12.28,6.4-22.21,16.51-30.1h0Z"
              />
              <path
                class="hc"
                d="M105.88,237.49c-14.06-5.18-19.47-22.96-15.91-35.96,1.52-5.56,8.96-15.1,17.8-22.65-8.39,4.34-15.35,8.81-23.37,18.41-9.81,11.74-3.43,32.42-1.64,44.49,.45,3,.59,5.88,.12,8.66,2.45-2.41,10.36-6.7,11.82-6.99,6.55-1.27,22.07,.05,33.81-6.26-7.23,2.71-13.7,3.59-22.63,.29h0Z"
              />
              <path
                class="is"
                d="M133.5,164.32c-9.43,10.85-18.64,24.35-17.51,39.75,.13,1.85,.83,3.61,1.24,5.41,.76-.47,1.45-.9,2.12-1.33,.57-.37,1.13-.75,1.69-1.14,2.31-1.62,4.39-3.39,6.25-5.28-.18-8.28,1.08-16.7,3.72-22.4,1.16,6.75,1.72,11.66,.86,16.94,9.54-13.31,11.42-30.32,12.6-42.56-2.6,3.76-6.58,7.38-10.97,10.61h0Z"
              />
              <path
                class="ir"
                d="M122.77,171.2c4.84-1.91,10.97-4.8,15.81-4.44,7.53,.56,9.89,12.15,10.23,18.36,.39,7.04-.7,13.77-1.77,20.61,2.05-7.91,3.28-15.73,3.48-21.09,.44-11.83-5.99-22.02-6.05-30.94-4.67,6.76-13.78,13.02-21.7,17.49h0Z"
              />
              <path
                class="hi"
                d="M144.47,153.7c-1.76,25.47-7.93,42.78-22.55,54.5,12.21-.93,17.09-2.95,26.51-12.87l-3.08,4.1c-6.88,7.72-14.88,9.42-24.21,10.36-.98,.1-2.33,.82-4.99,2.47-7,4.34-11.81,6.86-15.37,8.96,12.08,6.59,20,9.31,34.23,8.37-12.97,2.41-22.35-.66-33.46-6.28-1.54-.78-3.22-.63-4.62,.4-.21,.16-.42,.31-.62,.47-5.12,4.03-8.2,10.18-13.43,26.27,1.96-11.65,5.05-19.78,8.87-24.97,.81-1.09,1.01-2.43,.58-3.73-5.89-17.62,.26-27.5,13.55-39.98-3.26,3.95-.62,.82-4.05,4.73-10.47,11.95-13.35,21.2-7.63,36.15,3.33-3.25,7.7-5.23,12.89-7.83-2.64-14.07,1.07-24.01,9.47-35.19,2.69-3.58,4.09-4.96,7.4-7.8-2.62,2.85-4.58,5.01-6.84,8.24-7.73,11.05-11.34,20.55-8.66,34.05,4.63-2.36,9.88-5.31,15.61-10.09,15.9-13.25,18.21-33.89,20.41-50.33h0Z"
              />
              <path
                class="fg"
                d="M81.55,413.39c-11.45,1.54-29.46-10.06-38.86-24.81-8.54-13.39-18.61-34.28-24.81-46.12,11.79,6.39,38.36-3.64,56.35-2.01,6.97,.63,14.24,4.22,24.56,15.01,1.61-.19,3.89-.34,4.29-.15,2.06,.99,7.67,5.95,10.11,12.04,3.6,8.96,5.72,21.68,7.15,26.63,.84,2.91,2.09,5.54,4.37,8.97-9.27-.61-32.17,8.95-43.15,10.42h0Z"
              />
              <path
                class="fm"
                d="M36.02,345.06c12.83,5.88,17.52,11.97,22.24,23.09,2.66,.92,5.32,1.84,7.98,2.76-3.55-13.54-9.71-22.09-23.01-27.6-3.03,.44-5.99,.82-8.81,1.05,.53,.23,1.06,.46,1.59,.7h0Z"
              />
              <path
                class="fc"
                d="M62.5,345.27c8.39,7.46,12.13,17.23,14.86,27.99,2.01,.7,4.03,1.37,6.02,2.11,2.39,.9,4.76,1.87,7.14,2.81-1.76-14.78-2.88-28.75-15.7-37.67-.2-.02-.4-.04-.6-.06-5.25-.47-11.23,.04-17.35,.85,1.96,1.21,3.9,2.44,5.63,3.97h0Z"
              />
              <path
                class="ei"
                d="M42.68,388.58c9.4,14.75,27.41,26.35,38.86,24.81,10.98-1.48,33.88-11.03,43.15-10.42-11.9-8.78-20.92-19.84-36.82-25.84-23.14-8.74-46.32-9.59-70.01-34.66,6.2,11.84,16.27,32.72,24.81,46.12h0Z"
              />
              <path
                class="hn"
                d="M104.71,359.56c7.49,3.57,4.36,27.67,.5,31.94-17.35,19.2-35.48,17.11-55.59,5.89,9.75,10.24,22.88,17.22,31.92,16,10.98-1.48,33.88-11.03,43.15-10.42-2.27-3.43-3.53-6.07-4.37-8.97-1.43-4.95-3.55-17.67-7.15-26.63-2.44-6.1-8.06-11.05-10.11-12.04-.4-.19-2.68-.04-4.29,.15-3.78-3.95-7.15-6.93-10.24-9.16,3.92,4.21,4.85,6.85,8.23,11.98,2.91,4.41,4.77-.23,7.94,1.28h0Z"
              />
              <path
                class="hq"
                d="M27.99,362.22c-1.6-4.99-2.14-10.7,3.57-13.56,7.58-3.8,18.64-3.37,26.44-3.75,4.99-.24,7.72-4.31,21.67-3.33-1.86-.62-3.66-.97-5.44-1.13-18-1.63-44.56,8.41-56.35,2.01,2.76,5.28,6.29,12.35,10.12,19.76h0Z"
              />
              <path
                class="ga"
                d="M36.71,378.53c8.05,7.41,17.93,13.74,28.89,14.05,13.01,.37,23.9-10.18,36.8-7.65-6.58-4.26-9.8-6.16-13.21-6.68-6.72-1.03-17.82,2.9-25.4,3.27-10.99,.54-20.63-3.63-29.47-10.07-.89-.65-1.74-1.35-2.59-2.06,1.64,3.1,3.32,6.19,4.98,9.14h0Z"
              />
              <path
                class="et"
                d="M17.87,342.46c12.27,12.47,26.3,19.38,38.81,23.83-5.46-11.7-9.1-14.71-20.66-21.23,12.44,4.81,16.95,9.89,22.8,21.97,6.57,2.22,12.64,3.79,17.73,5.18-2.58-9.68-4.98-15.84-14.05-26.94,5.22,5.86,8.42,8.91,10.77,13.22,2.24,4.11,3.69,8.51,5.32,14.28,2.01,.56,3.83,1.1,5.43,1.65,6.14,2.12,11.3,4.38,16.14,7.24,.31-9.02-.11-12.15-1.58-20.61,2.94,8.07,3.73,13.28,3.76,21.95,7.17,4.6,13.86,10.74,22.36,19.95-6.82-5.53-11.8-10.19-17.89-14.32-18.48,19.06-35.14,19.33-56.36,5.3,21.45,11.21,36.47,11.46,54.1-6.77-3.65-2.28-7.8-4.36-12.94-6.43-2.86-1.16-5.41-1.99-11.52-.85-17.39,3.23-29.42,4.24-45.74-8.44,19.55,11.98,27.17,10.15,47.37,5.88-23.46-7.5-46.89-13.66-63.83-34.86h0Z"
              />
            </g>
            <g>
              <path
                class="ih"
                d="M558.79,480.72c47.45-29.93,84.88-73.19,108.72-124.11,36.3-77.54,43.48-183.67-4.09-255.61,32.15,73.63,33.67,161.33-10.09,248.05-23.75,47.08-59.67,86.95-104.86,113.95h0c-4.75,2.84-6.4,9.18-3.7,14.16,2.71,4.98,8.75,6.72,13.5,3.88,.17-.1,.34-.21,.5-.32h0Z"
              />
              <path
                class="dw"
                d="M592.55,452.3c24.9-21.42,40.35-39.67,57.61-68.25,24.52-40.6,38.62-85.04,43.54-132.8,4.53-43.92-1.21-73.47-13.04-113.03,9.74,41.55,13.81,71.37,8.81,112.05-5.79,47.08-19.8,90.86-45.05,129.39-18.59,28.37-35.35,41.68-57.78,64.92-4.82,5-4.58,7.35-3.41,9,1.37,1.94,4.78,2.62,9.32-1.29h0Z"
              />
              <path
                class="if"
                d="M723.44,306.15c6.9-.97,13.99-3.95,19.2-9.04,10.05-9.81,17.09-19.54,20.87-30.36-3.57,2.39-6.92,3.86-11.64,5.08,4.4-2.25,9.75-6.54,13.13-10.08,.09-.36,.18-.72,.26-1.08,3.44-14.97,2.21-41.33,4.83-52.84-9.81,5.87-21.7,9.88-33.26,14.79-1.71,.73-3.44,1.49-5.18,2.28-.33,2.23-.52,4.75-.83,6.78-.34,2.28-1.67,1.59-2.56-.09-.73-1.37-1.16-3.04-1.32-4.48-19.24,9.4-38.18,23.03-38.69,43.01-.27,10.81,6.13,18.88,3.12,30.47,8.01-.22,22.1,6.95,32.06,5.55h0Z"
              />
              <path
                class="ip"
                d="M724.45,274.37c19.8-20.11,31.48-42.13,45.65-66.54-2.62,11.51-1.39,37.88-4.83,52.84-.08,.36-.17,.72-.26,1.08-3.38,3.53-8.73,7.83-13.13,10.08,4.71-1.22,8.07-2.69,11.64-5.08-3.79,10.83-10.82,20.55-20.87,30.36-5.22,5.09-12.3,8.07-19.2,9.04-9.96,1.4-24.05-5.77-32.06-5.55,12.37-8.74,22.21-15.2,33.07-26.23h0Z"
              />
              <path
                class="il"
                d="M765.91,233.22c-.16-7.25-1.24-14.17-4.41-15.74-4.26-2.11-14.75,1.36-22.35,4.17,10.82-4.49,21.79-8.35,30.95-13.82-2.62,11.51-1.39,37.88-4.83,52.84-.08,.36-.17,.72-.26,1.08-.53,.56-1.12,1.13-1.73,1.71,2.15-9.83,2.86-20.12,2.63-30.25h0Zm-2.4,33.53c-.93,2.67-2.07,5.27-3.39,7.83,.82-2.31,1.54-4.66,2.17-7.04,.41-.25,.81-.51,1.22-.78h0Z"
              />
              <path
                class="gp"
                d="M736.12,261.48c-3.99-13.35-5.35-23.81-3.15-37.18,1.3-.58,2.59-1.14,3.87-1.69,2.24-.95,4.49-1.87,6.73-2.77-6.09,12.11-5.03,29.88-3.07,35.9-1.41,1.98-2.88,3.88-4.39,5.74h0Z"
              />
              <path
                class="hp"
                d="M721.47,229.92c1.31,12.94,7.1,26.77,12,34.71-4.66,5.36-9.71,10.29-14.99,15.11-7.75-11.23-7.92-30.95-6.17-44.39,2.94-1.94,6.03-3.74,9.16-5.43h0Z"
              />
              <path
                class="go"
                d="M705.28,252.19c-12.15,31.26,22.06,60.02,52.59,26.37-3.87,6.34-8.96,12.43-15.23,18.55-5.22,5.09-12.3,8.07-19.2,9.04-9.96,1.4-24.05-5.77-32.06-5.55,3.01-11.59-3.4-19.66-3.12-30.47,.47-18.43,16.63-31.46,34.23-40.75-8.38,6.91-12.41,10.46-17.21,22.82h0Z"
              />
              <path
                class="ev"
                d="M756.58,280.6c-11.91,3.44-25.69,2.79-37.1-1.72,1.46-1.33,2.91-2.69,4.38-4.09,11.95,3.87,25.96,2.96,36.53-.75-1.13,2.22-2.4,4.4-3.81,6.56h0Zm-39.38,.34c10.35,4.73,19.07,6.4,29.81,6.07-.84,.96-1.92,1.98-3.18,2.34-11.26,3.18-21.35,.48-31.09-4.54,1.53-1.3,3-2.58,4.45-3.87h0Z"
              />
              <path
                class="fw"
                d="M770.1,207.83c-9.59,16.44-18.5,31.51-29.47,45.79-1.69-12.12-2.09-18.66,1.71-31.01-5.41,11.71-4.48,20.68-3.27,33.02-6.11,7.78-12.88,15.33-20.76,22.75-6.52-8.33-7.55-30.29-6.41-40.37-2.77,9.36-1.99,33.11,4.7,41.96-9.37,8.53-19.77,14.21-29.67,21.59-1.93,1.44-2.61,5.4-2.46,6.72,5.86-7.07,16.45-12.89,23.9-18.61,1.52-1.17,3.42-1.36,5.2-.51,9.84,4.71,20.75,6.86,30.84,4.03-10.57,1.48-21.51-.58-32.22-6.53,2.21-1.78,4.39-3.61,6.5-5.41,2.87-2.45,5.56-4.95,8.1-7.5,.78-.78,1.79-1.09,2.88-.87,10.83,2.13,22.28,1.04,31.12-4.39-10.26,4.01-20.16,4.95-31.06,2.23,5.96-6.35,11.1-13.01,15.8-19.99,.86-1.28,1.91-2.15,3.31-2.73,6.69-2.8,11.4-5.14,16.64-10.8-6.97,4.87-9.66,6.48-17.16,9.27,7.66-11.98,14.3-24.85,21.78-38.62h0Z"
              />
              <path
                class="hl"
                d="M617.81,324.11c-4.16-7.34-6.24-16.15-6.82-25.56,1.72,3.18,3.99,5.51,6.17,6.62-1.35-2.68-4.88-10.9-6.28-16,.66-18.98,6.33-39.24,12.7-54.46,10.45,7.98,21.96,18.15,31.6,29.67,3.63,4.34,5.63,8.8,4.53,14.37,2.11-.99,3.27-2.37,3.5-3.75,5.66,8.39,10.04,17.3,12.18,26.47,1.5,6.4-.38,13.55-3.86,20.9-3.48,7.34-8.94,14.68-13.46,22.05-6.45-1.85-15.68-3.14-23.34-6.93-7.66-3.79-14.25-8.65-16.92-13.38h0Z"
              />
              <path
                class="fo"
                d="M623.59,234.71c-6.37,15.22-12.04,35.48-12.7,54.46,1.4,5.1,4.93,13.32,6.28,16-2.18-1.11-4.45-3.44-6.17-6.62,.58,9.41,2.66,18.21,6.82,25.56,2.68,4.72,9.26,9.59,16.92,13.38,7.66,3.79,16.89,5.08,23.34,6.93-6.14-14.96-14.1-26.86-19.04-41.73-6.95-20.92-11.09-44.92-15.45-67.97h0Z"
              />
              <path
                class="fh"
                d="M646.46,327.57c-14.93-3.62-33.29-18.96-35.41-28.07,.66,9.06,2.74,17.51,6.76,24.61,2.68,4.72,9.26,9.59,16.92,13.38,7.66,3.79,16.89,5.08,23.34,6.93,4.52-7.37,9.98-14.71,13.46-22.05,3.48-7.34,5.36-14.49,3.86-20.9-1.45-6.2-3.92-12.28-7.12-18.17,4.88,16.43,3.43,50.39-21.82,44.27h0Z"
              />
              <path
                class="ek"
                d="M642.17,250.55c-.87,6.26-2.06,10.87-4.25,16.6,1.68-6.91,2.6-12.54,1.77-18.93-1.37-1.26-2.74-2.5-4.12-3.7,.56,3.04,.68,4.94,.23,8.59-.74,6.02-2.05,10.99-5.35,16.12,1.29,5.4,2.57,10.81,3.86,16.22,6.92-9.18,10.85-19.05,11.21-31.61-1.11-1.11-2.23-2.21-3.35-3.28h0Z"
              />
              <path
                class="fb"
                d="M638.79,302.23c-11.64-5.33-23.26-19.01-25.62-32.84-.97,5.17-1.69,10.4-2.06,15.58,6.95,16.17,15.34,23.85,34.07,31.61-2.67-5.02-4.19-9.22-6.39-14.35h0Zm-15.11-14.73c4.68,6,8.73,8.8,14.82,12.88l-1.93-6.12c-4.25-2.91-7.5-4.81-11.14-8.59-4.38-4.55-7.57-9.73-10.43-15.59,2.34,7.17,4.84,12.51,8.67,17.42h0Z"
              />
              <path
                class="ik"
                d="M611.05,285.78c2.38-13.15,7.83-40.27,15.29-43.2,5.42-2.12,19.09,11.28,27.55,20.27-9.37-10.89-20.32-20.51-30.31-28.14-5.99,14.32-11.36,33.09-12.54,51.06h0Z"
              />
              <path
                class="gk"
                d="M643.61,313.99l2.89,5.72c9.94-11.03,13.59-23.32,13.27-36.37-2,7.27-5.5,12.15-9.54,19.19-2.51,4.38-4.41,6.61-6.63,11.47h0Z"
              />
              <path
                class="i`"
                d="M623.59,234.71c3.03,11.56,5.1,22.28,7.31,32.81,2.37-4.03,4.88-12.48,5.13-20.77,.69,9.12-1.2,14.19-4.59,23.33,1.85,8.68,3.86,17.27,6.65,26.15,8.5-14.28,9.96-18.67,11.33-36.2,1.2,16.37-1.98,25.29-10.38,39.13,2.54,7.64,5.7,15.52,9.87,23.86,.12,.24,.24,.49,.36,.73,10.59-14.67,13.61-22.46,15.44-39.26-.04,16.58-5.07,28.78-14.38,41.36,3.04,5.98,6.14,12.04,7.75,18.57-2.15-3.43-1.12-1.65-2.99-5.18-3.34-6.31-3.7-5.91-6.97-6.92-13.69-4.2-27.05-11.63-34.44-24.1,10.69,12.87,21.32,18.58,37.29,22.77-1.15-2.36-2.33-4.74-3.59-7.09-2.96-5.52-3.88-6.86-7.23-8.5-12.65-6.19-20.81-12.6-27.37-27.24,7.97,14.59,14.52,19.5,30.1,26.5-2.75-6.26-4.95-12.61-6.85-19.24-1.73-6.05-1.62-5.47-4.32-7.67-9.06-7.39-13.35-12.98-15.82-25.95,4.24,13.4,9,19.9,17.89,25.09-3.66-15.06-6.28-31.83-10.2-52.18h0Z"
              />
              <path
                class="hw"
                d="M704.59,195.48c-3.77-12.71-19.06-19.06-16.77-38.75,1.2-10.3,7.45-18.84,16.11-27.93,8.54-8.96,26.98-20.52,27.07-34.64,3.67,11.1,8.83,15.53,11.6,24.57,1.23,4.03,2.4,9.11,3.28,14.62,.68,4.25,.05,8.11-1.93,11.89-.8,1.52-1.75,3.06-2.89,4.76,.82-.54,2.78-2.72,3.4-3.31,.44-.42,1.21-.24,1.71-.58,.4-.27,.73-.6,1.04-.99,.72,11.11-.16,22.29-4.18,29.3-8.7,15.17-22.17,18.88-38.44,21.07h0Z"
              />
              <path
                class="ii"
                d="M741.53,115.7c-1.3,6.39-3.25,11.72-7.09,19.62,2.25-9.56,3.34-18.44,3.38-26.93-2.29-3.98-4.78-8.08-6.82-14.24,1.03,20.66-.48,39.65-4.69,59.85,11.64-7.13,15.4-12.12,18.91-24.43-.42-2.22-.88-4.34-1.37-6.32-.76,1.65-1.7,3.17-2.75,4.61,.89-3.18,1.33-5.53,1.73-8.41-.07-.25-.15-.49-.22-.73-.33-1.07-.69-2.07-1.08-3.03h0Z"
              />
              <path
                class="fq"
                d="M724.79,158.55c6.25-17.63,7.12-55.92,6.21-64.39-.09,14.12-18.53,25.68-27.07,34.64-8.66,9.09-14.91,17.64-16.11,27.93-2.3,19.68,12.99,26.04,16.77,38.75,7-13.19,15.61-23.96,20.2-36.93h0Z"
              />
              <path
                class="fa"
                d="M713.02,181.72c-20.05-7.69-22.07-30.11-13.77-47.76-6.18,7.26-10.46,14.44-11.43,22.77-2.3,19.68,12.99,26.04,16.77,38.75,16.27-2.19,29.74-5.9,38.44-21.07,2.68-4.68,3.97-11.21,4.31-18.35-1.68,6.88-3.67,11.71-8.36,17.39-7.15,8.65-17.14,11.65-25.96,8.27h0Z"
              />
              <path
                class="ha"
                d="M710.45,143.94c.39,12.25,3.49,17.48,9.23,25.79,.56-1.22,1.09-2.36,1.58-3.46-5.26-8.02-7.75-13.62-7.93-23.44,2.26,8.21,4.39,12.37,9.85,18.96,1.81-4.48,3.11-8.63,4.36-14.49-7.19-14.5-9.62-19.05-6.93-33.85-5.59,5.68-10.62,15.67-10.16,30.49h0Z"
              />
              <path
                class="fk"
                d="M731,94.16c2.27,12,.14,43.44-3.37,58.36,10.25-6.85,11.95-8.85,17.19-21.29-3.35,11.76-6.76,15.84-15.15,21.95-3.88,2.82-2.21,1-3.94,5.66-1.6,4.32-3.83,8.85-6.36,13.37,13.5-4.52,20.22-10.73,27.18-21.86-5.33,11.5-13.71,17.35-24.52,22.68-2.58,1.27-3.73,1.12-5.23,3.63-1.13,1.89-2.29,3.76-3.46,5.6,4.64-1.09,17.46-6.17,25.33-13.41-8.15,8.24-16.4,12.04-26.83,15.74-2.52,3.89-14.04,22.19-15.91,26.85-1.31-10.88,1.45-7.03,12.24-25.57-7.59-16.08-18.19-25.84-12.26-45.15-3.9,19.06,5.33,26.21,13.69,43.08,2.23-2.91,5.06-6.94,7.57-11.22,1.01-1.72,1.52-2.49,.38-4.37-5.34-8.8-7.73-17.19-7.04-27.82,.06,12.58,2.77,18.53,9.12,27.73,3.24-6.37,5.4-13.42,6.98-20.43-5.23-11.68-9.35-19.24-6.34-32.51-1.08,14.69,.98,18.08,6.81,30.34,3.69-17.8,4.18-37.69,3.95-51.36h0Z"
              />
              <path
                class="gi"
                d="M708.89,161.21c-4.61-12.93-4.39-26.88,.31-37.37-1.96,1.74-3.76,3.39-5.26,4.96-1.31,1.37-2.56,2.73-3.75,4.08-1.73,9.77,1.75,20.67,8.7,28.32h0Z"
              />
              <path
                class="hb"
                d="M675.63,126.5c.44-6,8.83-17.57,9.61-23.66,1.94-15.13-5.79-29.35-15.37-40.1,1.08,3.82,.44,7.53-.63,11.29-.24-4.85-2.35-12.88-5.56-17.14-8.55-6.72-20.43-11.84-30.15-13-7.89-.94-16.02-.81-23.13-2.52,5,4.25,7.74,8.31,9.47,13.45,2.41,3.28,4.34,4.92,7.76,7.96-2.66-.05-4.29-.49-6.09-1.2,1,5.49,1.61,12.23,2.69,21.09,.86,7.02,1.11,14.2,2.98,21,3.15,11.49,13.45,24.11,25.78,24.1,8.06,0,14.7-2.47,22.64-1.27h0Z"
              />
              <path
                class="hv"
                d="M669.86,62.74c9.58,10.76,17.31,24.97,15.37,40.1-.78,6.09-9.16,17.66-9.61,23.66-12.46-13.82-9.55-33.82-24.87-55.08-10.59-14.69-25.66-25.73-40.36-30.05,7.11,1.71,15.24,1.58,23.13,2.52,9.72,1.16,21.6,6.28,30.15,13,3.21,4.26,5.32,12.29,5.56,17.14,1.07-3.76,1.71-7.47,.63-11.29h0Z"
              />
              <path
                class="fn"
                d="M669.34,109.12c10.44-10.73,9.64-29.55,4.79-41.17,7.46,9.95,12.74,22.09,11.1,34.89-.78,6.09-9.16,17.66-9.61,23.66-7.94-1.2-14.57,1.27-22.64,1.27-12.33,0-22.63-12.61-25.78-24.1-.91-3.32-1.44-6.73-1.84-10.17,5.58,20.14,27.56,32.48,43.97,15.62h0Z"
              />
              <path
                class="ib"
                d="M650.9,57.06c-1.38-4.11-2.47-6.55-4.54-9.85,3.15,1.2,6.29,2.68,9.28,4.35,8.4,13.3,10.73,26.18,6.88,42.46-8.19-25.53-27.68-44.77-52.13-52.64,7.11,1.71,15.24,1.58,23.13,2.52,.21,.03,.43,.05,.65,.08,6.94,3.07,10.05,7.57,16.11,15.58,.41,.54,1.01,.21,1.01-.4,0-.71-.17-1.42-.39-2.09h0Z"
              />
              <path
                class="gn"
                d="M678.21,90.36c-.89,5.31-2.99,10.14-5.69,15.74-.9,1.88-3.67,7.71-3.44,8.62,.51,1.98,2.16,5.54,4.4,8.79-1.74-2.78-1.89-4.84,.18-9.99,4.14-10.31,5.32-12.52,4.54-23.14h0Z"
              />
              <path
                class="gc"
                d="M640.56,94.27c-4.97-1.77-11.9-6.92-16.5-12.98-.19-1.57-.36-3.07-.53-4.5,3.38,4.26,7.05,6.63,12.13,9.28,9.36,4.87,15.17,4.01,25.38,3.86,.79,2.17,1.48,4.26,2.12,6.31-8.46,1.01-16.16,.33-22.6-1.97h0Z"
              />
              <path
                class="dx"
                d="M649.75,58.85c-4.57-4.27-11.6-10.02-17.22-12.42-2.64-1.13-9.23-3.17-11.81-.92-.81,.71-1.23,1.84-1.01,3.55,.59,4.48,4.31,9.33,6.82,12.76-2.77-2.45-4.53-4.08-6.68-6.99-1.73-5.14-4.47-9.2-9.47-13.45,7.11,1.71,15.24,1.58,23.13,2.52,.21,.03,.43,.05,.65,.08,6.73,2.98,9.86,7.3,15.57,14.87h0Z"
              />
              <path
                class="gv"
                d="M663.98,98.99c.66,2.59,1.34,5.11,2.08,7.68-3.35,.34-17.49,1.41-22.09-2.19-.98-.77-.75-1.61-2.49-3.37-1.8-1.83-3.98-2.95-5.65-5.09,9.03,3.99,17.17,4.44,28.15,2.98h0Z"
              />
              <path
                class="gx"
                d="M610.39,41.37c13.46,4.41,26.98,13.56,37.93,27.82-10.85,.43-19.75-4.77-27.06-12.49,7.52,8.64,16.65,14.38,28.13,13.9,2.83,3.84,5.48,8.03,7.9,12.58,1.08,2.03,2.02,4.11,2.88,6.27-16.87,.28-25.69-3.2-36.04-11.69,5.37,6.15,11.81,9.83,17.95,11.85,4.16,1.37,11.16,.72,18.64,1.29,1.98,5.29,3.49,11.02,5.15,17.36,.1,.37,.2,.74,.31,1.1-16.33,1.05-27.6,1.35-38.88-12.2,4.18,6.18,9.03,10.51,14.54,12.46,5.36,1.9,15.71,.85,24.79,1.19,2.29,6.81,6.43,13.51,8.99,15.69-3.09-4.4-4.78-7.94-6.19-11.89,2.25-7.31,6.3-12.37,8.05-18.44,2.01-6.99,1.69-14.7-1.76-23.78,4.62,18.84-.55,24.83-6.81,40.7-.57-1.71-1.11-3.52-1.71-5.53-.87-2.93-1.63-5.91-2.42-8.9-1.18-4.47-1.02-5.16-.8-7.11,.75-6.68,1.44-12.75,.97-18.52-.3-3.71-3.89-11.92-7.95-19.23,6.75,14.42,7.66,21.92,5.64,37.35-.93-2.93-2.02-5.85-3.41-8.75-8.43-17.57-29.49-36.7-48.83-41.04h0Z"
              />
              <path
                class="h`"
                d="M687.22,243.38c-.47-2.77-.33-5.66,.11-8.66,1.79-12.07,8.17-32.75-1.64-44.49-10.84-12.97-19.75-16.58-32.85-23.15-8.71-4.37-21.41-12.03-27.22-20.44-.05,8.92-6.49,19.11-6.05,30.94,.47,12.76,6.84,39.47,15.97,48.21,11.73,11.22,32.08,9.1,39.85,10.6,1.46,.28,9.38,4.58,11.82,6.99h0Z"
              />
              <path
                class="fv"
                d="M626.9,210.83c6.24,2.96,14.74,4.5,24.32,2.41-6.52-.59-13.59-2.49-17.67-8.88,6.79,3.06,13.34,1.96,22.06,.53,5.71,3.29,10.44,5.23,15.24,9.75-14.35,6.1-22.88,10.81-38.54,7.19-2-3.01-3.82-6.82-5.41-10.99h0Z"
              />
              <path
                class="fu"
                d="M670.72,213.28c-6.71-5.24-14.7-8.46-21.66-13.33-19.19-13.44-21.89-37.4-23.43-53.31,5.81,8.41,18.52,16.06,27.22,20.44,13.1,6.58,22.01,10.18,32.85,23.15,9.81,11.74,3.43,32.42,1.64,44.49-.44,3-.59,5.88-.11,8.66-3.47-12.28-6.4-22.21-16.51-30.1h0Z"
              />
              <path
                class="ie"
                d="M664.22,230.44c14.06-5.18,19.47-22.96,15.91-35.96-1.52-5.56-8.96-15.1-17.8-22.65,8.39,4.34,15.35,8.81,23.37,18.41,9.81,11.74,3.43,32.42,1.64,44.49-.44,3-.59,5.88-.11,8.66-2.45-2.41-10.36-6.7-11.82-6.99-6.55-1.27-22.07,.05-33.81-6.26,7.23,2.71,13.7,3.59,22.63,.29h0Z"
              />
              <path
                class="hh"
                d="M636.6,157.26c9.43,10.85,18.64,24.35,17.51,39.75-.14,1.85-.83,3.61-1.24,5.41-.76-.47-1.46-.9-2.12-1.33-.57-.37-1.13-.75-1.69-1.14-2.31-1.62-4.39-3.39-6.25-5.28,.18-8.28-1.08-16.7-3.72-22.4-1.16,6.75-1.72,11.66-.86,16.94-9.54-13.31-11.42-30.32-12.6-42.56,2.6,3.76,6.58,7.38,10.97,10.61h0Z"
              />
              <path
                class="f`"
                d="M647.33,164.14c-4.84-1.91-10.97-4.8-15.81-4.44-7.53,.56-9.89,12.15-10.23,18.36-.39,7.04,.7,13.77,1.77,20.61-2.05-7.91-3.28-15.73-3.48-21.09-.44-11.83,5.99-22.02,6.05-30.94,4.67,6.76,13.78,13.02,21.7,17.49h0Z"
              />
              <path
                class="fr"
                d="M625.63,146.65c1.76,25.47,7.93,42.78,22.55,54.5-12.21-.93-17.09-2.95-26.51-12.87l3.08,4.1c6.88,7.72,14.88,9.42,24.21,10.36,.98,.1,2.33,.82,4.99,2.47,7,4.34,11.81,6.86,15.37,8.96-12.08,6.59-20,9.31-34.23,8.37,12.97,2.41,22.35-.66,33.46-6.28,1.54-.78,3.22-.63,4.62,.4,.21,.16,.42,.31,.62,.47,5.12,4.03,8.2,10.18,13.43,26.27-1.96-11.65-5.05-19.78-8.87-24.97-.81-1.09-1.01-2.43-.58-3.73,5.89-17.62-.26-27.5-13.55-39.98,3.26,3.95,.62,.82,4.05,4.73,10.47,11.95,13.35,21.2,7.63,36.15-3.33-3.25-7.7-5.23-12.89-7.83,2.64-14.07-1.07-24.01-9.47-35.19-2.69-3.58-4.08-4.96-7.4-7.8,2.62,2.85,4.59,5.01,6.84,8.24,7.73,11.05,11.34,20.55,8.66,34.05-4.64-2.36-9.88-5.31-15.61-10.09-15.9-13.25-18.21-33.89-20.4-50.33h0Z"
              />
              <path
                class="hf"
                d="M688.56,406.33c11.45,1.54,29.46-10.06,38.86-24.81,8.54-13.39,18.61-34.28,24.81-46.12-11.79,6.39-38.36-3.64-56.35-2.01-6.97,.63-14.24,4.22-24.56,15.01-1.61-.19-3.89-.34-4.29-.15-2.06,.99-7.67,5.95-10.11,12.04-3.6,8.96-5.72,21.68-7.15,26.63-.84,2.91-2.09,5.54-4.37,8.97,9.27-.61,32.17,8.95,43.15,10.42h0Z"
              />
              <path
                class="ed"
                d="M734.08,338c-12.83,5.88-17.52,11.97-22.24,23.09-2.66,.92-5.32,1.84-7.98,2.76,3.55-13.54,9.71-22.09,23.01-27.6,3.03,.44,5.99,.82,8.81,1.05-.53,.23-1.06,.46-1.59,.7h0Z"
              />
              <path
                class="hr"
                d="M707.6,338.21c-8.39,7.46-12.13,17.23-14.86,27.99-2.01,.7-4.03,1.37-6.02,2.11-2.39,.9-4.76,1.87-7.14,2.81,1.76-14.78,2.88-28.75,15.7-37.67,.2-.02,.4-.04,.6-.06,5.25-.47,11.23,.04,17.35,.85-1.95,1.21-3.9,2.44-5.63,3.97h0Z"
              />
              <path
                class="hx"
                d="M727.42,381.52c-9.4,14.75-27.41,26.35-38.86,24.81-10.98-1.48-33.88-11.03-43.15-10.42,11.9-8.78,20.93-19.84,36.82-25.84,23.14-8.74,46.32-9.59,70.01-34.66-6.2,11.84-16.27,32.72-24.81,46.12h0Z"
              />
              <path
                class="em"
                d="M665.39,352.5c-7.49,3.57-4.36,27.67-.5,31.94,17.35,19.2,35.48,17.11,55.59,5.89-9.75,10.24-22.88,17.22-31.92,16-10.98-1.48-33.88-11.03-43.15-10.42,2.27-3.43,3.53-6.07,4.37-8.97,1.43-4.95,3.55-17.67,7.15-26.63,2.44-6.1,8.05-11.05,10.11-12.04,.4-.19,2.68-.04,4.29,.15,3.78-3.95,7.15-6.93,10.24-9.16-3.92,4.21-4.85,6.85-8.23,11.98-2.91,4.41-4.77-.23-7.94,1.28h0Z"
              />
              <path
                class="ew"
                d="M742.11,355.16c1.6-4.99,2.13-10.7-3.57-13.56-7.58-3.8-18.64-3.37-26.44-3.75-4.99-.24-7.72-4.31-21.67-3.33,1.86-.62,3.66-.97,5.44-1.13,18-1.63,44.56,8.41,56.35,2.01-2.76,5.28-6.29,12.35-10.12,19.76h0Z"
              />
              <path
                class="g`"
                d="M733.39,371.47c-8.05,7.41-17.93,13.74-28.89,14.05-13.01,.37-23.9-10.18-36.8-7.65,6.58-4.26,9.8-6.16,13.21-6.68,6.72-1.03,17.82,2.9,25.4,3.27,10.99,.54,20.63-3.63,29.47-10.07,.89-.65,1.74-1.35,2.6-2.06-1.64,3.1-3.32,6.19-4.98,9.14h0Z"
              />
              <path
                class="hy"
                d="M752.23,335.4c-12.27,12.47-26.3,19.38-38.81,23.83,5.46-11.7,9.1-14.71,20.66-21.23-12.44,4.81-16.95,9.89-22.8,21.97-6.57,2.22-12.64,3.79-17.73,5.18,2.58-9.68,4.98-15.84,14.05-26.94-5.22,5.86-8.42,8.91-10.77,13.22-2.24,4.11-3.69,8.51-5.32,14.28-2.01,.56-3.83,1.1-5.43,1.65-6.14,2.12-11.3,4.38-16.14,7.24-.31-9.02,.11-12.15,1.58-20.61-2.94,8.07-3.73,13.28-3.76,21.95-7.17,4.6-13.86,10.74-22.36,19.95,6.82-5.53,11.8-10.19,17.89-14.32,18.48,19.06,35.14,19.33,56.36,5.3-21.46,11.21-36.47,11.46-54.1-6.77,3.65-2.28,7.8-4.36,12.94-6.43,2.86-1.16,5.41-1.99,11.53-.85,17.38,3.23,29.42,4.24,45.74-8.44-19.55,11.98-27.17,10.15-47.37,5.88,23.46-7.5,46.89-13.66,63.83-34.86h0Z"
              />
            </g>
          </g>
          <g>
            <path
              class="id"
              d="M387.6,587.78c-3.53-11.8-26.55-70.7-60.32-85.73-64.85-28.86-127.39-51.13-167.56-76.85-36.32-23.25-69.6-49.84-72.48-85.63-1.2,37.01,14.32,94.85,23.46,106.28,31.79,39.77,108.76,64.15,205.9,101.36,29.36,11.25,45.91,22.5,71,40.57h0Z"
            />
            <path
              class="dy"
              d="M387.6,587.78c-3.53-11.8-26.55-70.7-60.32-85.73-64.85-28.86-127.39-51.13-167.56-76.85-36.32-23.25-69.6-49.84-72.48-85.63-1.2,37.01,14.32,94.85,23.46,106.28,31.79,39.77,108.76,64.15,205.9,101.36,29.36,11.25,45.91,22.5,71,40.57h0Z"
            />
          </g>
          <g>
            <path
              class="ee"
              d="M387.6,587.78c3.53-11.8,26.55-70.7,60.32-85.73,64.85-28.86,127.39-51.13,167.56-76.85,36.32-23.25,69.6-49.84,72.48-85.63,1.2,37.01-14.32,94.85-23.46,106.28-31.79,39.77-108.76,64.15-205.9,101.36-29.36,11.25-45.91,22.5-71,40.57h0Z"
            />
            <path
              class="hj"
              d="M387.6,587.78c3.53-11.8,26.55-70.7,60.32-85.73,64.85-28.86,127.39-51.13,167.56-76.85,36.32-23.25,69.6-49.84,72.48-85.63,1.2,37.01-14.32,94.85-23.46,106.28-31.79,39.77-108.76,64.15-205.9,101.36-29.36,11.25-45.91,22.5-71,40.57h0Z"
            />
          </g>
          <path
            class="fs"
            d="M128.85,449.77c-9.19-16.16-16.61-42.27-21.59-66.76-11.2-12.96-18.72-27.26-20.02-43.44-1.2,37.01,14.32,94.85,23.46,106.28,13.2,16.52,34.2,30.38,61.2,43.61-24.68-16.78-37.71-30.27-43.06-39.69h0Z"
          />
          <path
            class="ht"
            d="M311.37,508.32c4.2,8.98,1.94,28.21-10.89,29.73-2.29,.27-4.73,.39-7.13,.34,7.61,2.87,15.36,5.8,23.25,8.83,29.36,11.25,45.91,22.5,71,40.57-3.53-11.8-26.55-70.7-60.32-85.73-16.57-7.37-32.99-14.32-48.92-21,13.34,8.08,27.6,15.69,33.01,27.27h0Z"
          />
          <path
            class="fl"
            d="M387.6,593.64c-58.18-24.23-127.35-47.43-178.44-66.74-16.27-6.15-30.89-18.28-40.21-32.32,8.9,33.38,25.04,54.02,38.91,60,46.22,19.91,124.76,42.17,179.73,68.93v-29.86h0Z"
          />
          <path
            class="fe"
            d="M217.79,557.84c-16.36-8.63-28.98-31.32-35.06-47.34-5.31-4.81-9.98-10.21-13.77-15.93,8.9,33.38,25.04,54.02,38.91,60,6.17,2.66,12.92,5.36,20.12,8.11-3.44-1.53-6.87-3.09-10.2-4.84h0Z"
          />
          <path
            class="io"
            d="M309.24,570.54c2.67,3.66,6.51,12.83,4.13,16.19-1.91,2.69-7.66,2.68-12.1,2.15,30.13,10.71,60.78,22.18,86.34,34.62v-29.86c-30.7-12.79-64.47-25.29-97.01-37.08,7.58,4.22,14.76,8.65,18.65,13.98h0Z"
          />
          <path
            class="ec"
            d="M646.35,449.77c9.19-16.16,16.61-42.27,21.59-66.76,11.2-12.96,18.72-27.26,20.02-43.44,1.2,37.01-14.32,94.85-23.46,106.28-13.2,16.52-34.2,30.38-61.2,43.61,24.68-16.78,37.71-30.27,43.06-39.69h0Z"
          />
          <path
            class="hu"
            d="M463.83,508.32c-4.2,8.98-1.94,28.21,10.89,29.73,2.29,.27,4.73,.39,7.13,.34-7.61,2.87-15.36,5.8-23.25,8.83-29.36,11.25-45.91,22.5-71,40.57,3.53-11.8,26.55-70.7,60.32-85.73,16.57-7.37,32.99-14.32,48.92-21-13.34,8.08-27.6,15.69-33.01,27.27h0Z"
          />
          <path
            class="ho"
            d="M387.6,593.64c58.18-24.23,127.35-47.43,178.44-66.74,16.27-6.15,30.89-18.28,40.21-32.32-8.9,33.38-25.04,54.02-38.91,60-46.22,19.91-124.76,42.17-179.73,68.93v-29.86h0Z"
          />
          <path
            class="hm"
            d="M557.41,557.84c16.36-8.63,28.98-31.32,35.06-47.34,5.31-4.81,9.98-10.21,13.77-15.93-8.9,33.38-25.04,54.02-38.91,60-6.17,2.66-12.92,5.36-20.12,8.11,3.44-1.53,6.87-3.09,10.2-4.84h0Z"
          />
          <path
            class="gy"
            d="M465.96,570.54c-2.67,3.66-6.51,12.83-4.13,16.19,1.91,2.69,7.66,2.68,12.1,2.15-30.13,10.71-60.78,22.18-86.34,34.62v-29.86c30.7-12.79,64.47-25.29,97.02-37.08-7.58,4.22-14.76,8.65-18.65,13.98h0Z"
          />
          <g>
            <path
              class="ef"
              d="M327.28,502.05c-64.85-28.86-127.4-51.13-167.56-76.85-36.12-23.12-69.22-49.54-72.42-85.02,4.8,53.88,58.82,83.24,102.56,105.64,43.91,22.49,89.29,37.97,133.93,58.01,32.53,14.61,52.8,56.79,63.82,83.95-3.53-11.8-26.55-70.7-60.32-85.73h0Z"
            />
            <path
              class="eb"
              d="M447.92,502.05c64.85-28.86,127.39-51.13,167.56-76.85,36.12-23.12,69.22-49.54,72.43-85.02-4.8,53.88-58.82,83.24-102.56,105.64-43.91,22.49-89.29,37.97-133.93,58.01-32.53,14.61-52.8,56.79-63.82,83.95,3.53-11.8,26.55-70.7,60.32-85.73h0Z"
            />
          </g>
          <g>
            <path
              class="gb"
              d="M448.05,565.27c0-33.39-27.06-60.45-60.45-60.45s-60.45,27.06-60.45,60.45,27.06,60.45,60.45,60.45,60.45-27.06,60.45-60.45h0Z"
            />
            <path
              class="gl"
              d="M387.6,497.35c37.51,0,67.91,30.41,67.91,67.91s-30.4,67.91-67.91,67.91-67.91-30.41-67.91-67.91,30.41-67.91,67.91-67.91h0Zm0,14.93c-29.27,0-52.98,23.71-52.98,52.98s23.71,52.98,52.98,52.98,52.98-23.71,52.98-52.98-23.72-52.98-52.98-52.98h0Z"
            />
            <path
              class="gf"
              d="M455.51,565.27c0-37.51-30.4-67.91-67.91-67.91s-67.91,30.41-67.91,67.91,30.41,67.91,67.91,67.91,67.91-30.41,67.91-67.91h0Zm-67.91-60.45c33.38,0,60.45,27.06,60.45,60.45s-27.06,60.45-60.45,60.45-60.45-27.06-60.45-60.45,27.06-60.45,60.45-60.45h0Z"
            />
          </g>
          <g>
            <path
              class="eh"
              d="M387.6,510.79c-2.9,.18-8.07,1.17-13.87,2.73-5.8,1.55-10.77,3.28-13.37,4.57-2.42,1.61-6.4,5.05-10.64,9.29-4.25,4.25-7.69,8.23-9.29,10.65-1.29,2.6-3.02,7.57-4.57,13.37-1.55,5.8-2.54,10.97-2.73,13.87,.18,2.9,1.17,8.07,2.73,13.87,1.55,5.8,3.28,10.77,4.57,13.37,1.61,2.42,5.05,6.4,9.29,10.64,4.25,4.25,8.23,7.69,10.64,9.3,2.6,1.29,7.57,3.02,13.37,4.57,5.8,1.55,10.97,2.54,13.87,2.73,2.9-.18,8.07-1.17,13.87-2.73,5.8-1.55,10.77-3.28,13.37-4.57,2.42-1.61,6.4-5.05,10.64-9.3,4.25-4.25,7.69-8.23,9.3-10.64,1.29-2.6,3.02-7.57,4.57-13.37,1.55-5.8,2.54-10.97,2.73-13.87-.18-2.9-1.17-8.07-2.73-13.87-1.55-5.8-3.28-10.77-4.57-13.37-1.61-2.42-5.05-6.4-9.3-10.65-4.25-4.25-8.23-7.69-10.64-9.29-2.6-1.29-7.57-3.02-13.37-4.57s-10.97-2.54-13.87-2.73h0Z"
            />
            <path
              class="eh"
              d="M387.6,523.97c-3.3,.34-7.43,1.42-10.47,2.24-3.04,.81-7.16,1.94-10.18,3.3-2.69,1.94-5.72,4.95-7.95,7.17-2.23,2.23-5.23,5.26-7.17,7.95-1.36,3.02-2.48,7.14-3.3,10.18-.81,3.04-1.9,7.17-2.24,10.47,.34,3.3,1.42,7.43,2.24,10.47,.81,3.04,1.94,7.16,3.3,10.18,1.94,2.69,4.95,5.72,7.17,7.95,2.22,2.23,5.26,5.23,7.95,7.17,3.02,1.36,7.14,2.48,10.18,3.3,3.04,.81,7.17,1.9,10.47,2.24,3.3-.34,7.43-1.42,10.47-2.24,3.04-.81,7.16-1.94,10.18-3.3,2.69-1.94,5.72-4.94,7.95-7.17,2.22-2.22,5.23-5.26,7.17-7.95,1.36-3.02,2.48-7.14,3.3-10.18,.81-3.04,1.9-7.17,2.24-10.47-.34-3.3-1.42-7.43-2.24-10.47-.81-3.04-1.94-7.16-3.3-10.18-1.94-2.69-4.95-5.72-7.17-7.95-2.22-2.22-5.26-5.23-7.95-7.17-3.02-1.36-7.14-2.48-10.18-3.3-3.04-.81-7.17-1.9-10.47-2.24h0Z"
            />
            <path
              class="eh"
              d="M359,536.67c2.22-2.22,5.26-5.23,7.95-7.17l-6.59-11.41c-2.42,1.61-6.4,5.05-10.64,9.29-4.25,4.25-7.69,8.23-9.29,10.65l11.41,6.59c1.94-2.69,4.94-5.72,7.17-7.95h0Z"
            />
            <path
              class="eh"
              d="M366.95,529.5c3.02-1.36,7.14-2.48,10.18-3.3,3.04-.81,7.17-1.9,10.47-2.24v-13.18c-2.9,.18-8.07,1.17-13.87,2.73-5.8,1.55-10.77,3.28-13.37,4.57l6.59,11.41h0Z"
            />
            <path
              class="eh"
              d="M387.6,619.74v-13.18c3.3-.34,7.43-1.42,10.47-2.24,3.04-.81,7.16-1.94,10.18-3.3l6.59,11.41c-2.6,1.29-7.57,3.02-13.37,4.57-5.8,1.55-10.97,2.54-13.87,2.73h0Z"
            />
            <path
              class="eh"
              d="M414.84,612.45l-6.59-11.41c2.68-1.94,5.72-4.94,7.95-7.17,2.22-2.22,5.23-5.26,7.17-7.95l11.41,6.59c-1.61,2.42-5.05,6.4-9.3,10.64-4.25,4.25-8.23,7.69-10.64,9.3h0Z"
            />
            <path
              class="eh"
              d="M434.78,592.51l-11.41-6.59c1.36-3.02,2.48-7.14,3.3-10.18,.81-3.04,1.9-7.17,2.24-10.47h13.18c-.18,2.9-1.17,8.07-2.73,13.87-1.55,5.8-3.28,10.77-4.57,13.37h0Z"
            />
            <path
              class="eh"
              d="M442.08,565.27h-13.18c-.34-3.3-1.42-7.43-2.24-10.47-.81-3.04-1.94-7.16-3.3-10.18l11.41-6.59c1.29,2.6,3.02,7.57,4.57,13.37,1.55,5.8,2.54,10.97,2.73,13.87h0Z"
            />
            <path
              class="eh"
              d="M434.78,538.03l-11.41,6.59c-1.94-2.69-4.95-5.72-7.17-7.95-2.22-2.23-5.26-5.23-7.95-7.17l6.59-11.41c2.42,1.61,6.4,5.05,10.64,9.29,4.25,4.25,7.69,8.23,9.3,10.65h0Z"
            />
            <path
              class="eh"
              d="M414.84,518.09l-6.59,11.41c-3.02-1.36-7.14-2.48-10.18-3.3-3.04-.81-7.17-1.9-10.47-2.24v-13.18c2.9,.18,8.07,1.17,13.87,2.73s10.77,3.28,13.37,4.57h0Z"
            />
            <path
              class="eh"
              d="M387.6,619.74v-13.18c-3.3-.34-7.43-1.42-10.47-2.24-3.04-.81-7.16-1.94-10.18-3.3l-6.59,11.41c2.6,1.29,7.57,3.02,13.37,4.57,5.8,1.55,10.97,2.54,13.87,2.73h0Z"
            />
            <path
              class="eh"
              d="M360.36,612.45l6.59-11.41c-2.69-1.94-5.72-4.94-7.95-7.17-2.22-2.22-5.23-5.26-7.17-7.95l-11.41,6.59c1.61,2.42,5.05,6.4,9.29,10.64,4.25,4.25,8.23,7.69,10.64,9.3h0Z"
            />
            <path
              class="eh"
              d="M340.42,592.51l11.41-6.59c-1.36-3.02-2.48-7.14-3.3-10.18-.81-3.04-1.9-7.17-2.24-10.47h-13.18c.18,2.9,1.17,8.07,2.73,13.87,1.55,5.8,3.28,10.77,4.57,13.37h0Z"
            />
            <path
              class="eh"
              d="M333.12,565.27h13.18c.34-3.3,1.42-7.43,2.24-10.47,.81-3.04,1.94-7.16,3.3-10.18l-11.41-6.59c-1.29,2.6-3.02,7.57-4.57,13.37-1.55,5.8-2.54,10.97-2.73,13.87h0Z"
            />
            <path
              class="eo"
              d="M360.36,518.09c6.59,11.41-8.53,26.53-19.94,19.94l11.41,6.59c1.94-2.69,4.94-5.72,7.17-7.95,2.22-2.22,5.26-5.23,7.95-7.17l-6.59-11.41h0Z"
            />
            <path
              class="im"
              d="M340.42,538.03c11.41,6.59,5.88,27.24-7.3,27.24h13.18c.34-3.3,1.42-7.43,2.24-10.47,.81-3.04,1.94-7.16,3.3-10.18l-11.41-6.59h0Z"
            />
            <path
              class="eg"
              d="M333.12,565.27c13.18,0,21.56,18.98,10.7,25.28l8.02-4.63c-1.36-3.02-2.48-7.14-3.3-10.18-.81-3.04-1.9-7.17-2.24-10.47h-13.18Z"
            />
            <path
              class="eg"
              d="M414.84,612.45c-6.59-11.41,8.53-26.53,19.94-19.94l-11.41-6.59c-1.94,2.69-4.95,5.72-7.17,7.95-2.22,2.23-5.26,5.23-7.95,7.17l6.59,11.41h0Z"
            />
            <path
              class="eg"
              d="M434.78,592.51c-11.41-6.59-5.88-27.24,7.3-27.24h-13.18c-.34,3.3-1.42,7.43-2.24,10.47-.81,3.04-1.94,7.16-3.3,10.18l11.41,6.59h0Z"
            />
            <path
              class="eg"
              d="M442.08,565.27c-13.18,0-21.56-18.98-10.7-25.28l-8.02,4.63c1.36,3.02,2.48,7.14,3.3,10.18,.81,3.04,1.9,7.17,2.24,10.47h13.18Z"
            />
            <path
              class="eo"
              d="M381.06,525.19c-9.85,.75-15.16,.71-20.7-7.1l6.59,11.41c3.02-1.36,7.14-2.48,10.18-3.3,1.18-.32,2.53-.67,3.93-1.01h0Z"
            />
            <path
              class="eo"
              d="M394.14,605.34c9.85-.76,15.16-.72,20.7,7.1l-6.59-11.41c-3.02,1.36-7.14,2.48-10.18,3.3-1.18,.32-2.53,.67-3.93,1.01h0Z"
            />
            <path
              class="eo"
              d="M345.84,566.4c1.24,6.32,2.68,12.53,5.07,18.61l-.28,1.01c-.98,.76-1.97,1.53-2.96,2.3,1.39-.8,2.77-1.6,4.16-2.4-1.36-3.02-2.48-7.14-3.3-10.18-.81-3.04-1.9-7.17-2.24-10.47,.34-3.3,1.42-7.43,2.24-10.47,.81-3.04,1.94-7.16,3.3-10.18,1.94-2.69,4.94-5.72,7.17-7.95,2.22-2.22,5.26-5.23,7.95-7.17,3.02-1.36,7.14-2.48,10.18-3.3,1.82-.49,4.02-1.07,6.2-1.53-5.12,.7-10.43,1.66-15.52,3.37l-.99-.33c-2.15-3.21-4.31-6.42-6.46-9.62,1.68,3.53,3.28,6.87,4.95,10.37l-.24,.99c-5.2,3.98-9.39,8.75-13.05,13.79l-.97,.23c-3.46-1.76-6.91-3.53-10.37-5.29,3.21,2.11,6.43,4.23,9.64,6.34l.3,.97c-2.51,6.14-4.07,12.37-4.86,18.67l-.74,.68c-3.34,.14-6.68,.28-10.01,.42,3.35,.15,6.7,.31,10.05,.46l.78,.67h0Z"
            />
            <path
              class="eo"
              d="M424.3,587.14c3.31,1.69,6.61,3.38,9.92,5.07-3.15-2-6.25-3.96-9.4-5.95l-.34-.97c2.15-6.2,3.78-12.5,4.95-18.86l.77-.68c3.15-.16,6.3-.32,9.46-.47l-9.46-.53-.8-.68c-.38-1.51-.91-2.91-1.48-4.29,.43,1.88,.8,3.8,.98,5.5-.34,3.3-1.42,7.43-2.24,10.47-.81,3.04-1.94,7.16-3.3,10.18-1.94,2.69-4.95,5.72-7.17,7.95-2.22,2.23-5.26,5.23-7.95,7.17-2.26,1.01-5.13,1.9-7.7,2.62,2.33-.42,4.66-.9,6.99-1.62l.98,.35c1.66,2.57,3.31,5.15,4.97,7.72-1.39-2.78-2.73-5.44-4.12-8.2l.21-1.01c4.73-3.94,9.3-8.41,13.71-13.56l1.01-.2h0Z"
            />
            <path
              class="eo"
              d="M351.83,585.92l35.76-20.65-35.76-20.65c-1.36,3.02-2.48,7.14-3.3,10.18-.81,3.04-1.9,7.17-2.24,10.47,.34,3.3,1.42,7.43,2.24,10.47,.81,3.04,1.94,7.16,3.3,10.18h0Z"
            />
            <path
              class="eo"
              d="M351.83,544.62l35.76,20.65v-41.3c-3.3,.34-7.43,1.42-10.47,2.24-3.04,.81-7.16,1.94-10.18,3.3-2.69,1.94-5.72,4.95-7.95,7.17-2.23,2.23-5.23,5.26-7.17,7.95h0Z"
            />
            <path
              class="eo"
              d="M387.6,523.97v41.3l35.76-20.65c-1.94-2.69-4.95-5.72-7.17-7.95-2.22-2.22-5.26-5.23-7.95-7.17-3.02-1.36-7.14-2.48-10.18-3.3-3.04-.81-7.17-1.9-10.47-2.24h0Z"
            />
            <path
              class="eo"
              d="M423.36,544.62l-35.76,20.65,35.76,20.65c1.36-3.02,2.48-7.14,3.3-10.18,.81-3.04,1.9-7.17,2.24-10.47-.34-3.3-1.42-7.43-2.24-10.47-.81-3.04-1.94-7.16-3.3-10.18h0Z"
            />
            <path
              class="eo"
              d="M423.36,585.92l-35.76-20.65v41.3c3.3-.34,7.43-1.42,10.47-2.24,3.04-.81,7.16-1.94,10.18-3.3,2.69-1.94,5.72-4.94,7.95-7.17,2.22-2.22,5.23-5.26,7.17-7.95h0Z"
            />
            <path
              class="eo"
              d="M387.6,606.57v-41.3l-35.76,20.65c1.94,2.69,4.95,5.72,7.17,7.95,2.22,2.23,5.26,5.23,7.95,7.17,3.02,1.36,7.14,2.48,10.18,3.3,3.04,.81,7.17,1.9,10.47,2.24h0Z"
            />
            <path
              class="eo"
              d="M387.6,523.97c-3.14,.32-7.03,1.32-10.02,2.12l-29.16,29.16c-.8,2.99-1.8,6.88-2.12,10.02,.34,3.3,1.42,7.43,2.24,10.47,.81,3.04,1.94,7.16,3.3,10.18,.64,.88,1.39,1.8,2.19,2.71l56.94-56.94c-.91-.8-1.83-1.55-2.71-2.19-3.02-1.36-7.14-2.48-10.18-3.3-3.04-.81-7.17-1.9-10.47-2.24h0Z"
            />
            <path
              class="eo"
              d="M425.28,549.92l-53.03,53.03c1.72,.53,3.42,.99,4.88,1.38,2.37,.64,5.42,1.44,8.2,1.92l43.24-43.24c-.48-2.79-1.28-5.83-1.92-8.2-.39-1.46-.85-3.16-1.38-4.88h0Z"
            />
            <path
              class="eg"
              d="M387.6,510.79c-2.9,.18-8.07,1.17-13.87,2.73-5.8,1.55-10.77,3.28-13.37,4.57-2.42,1.61-6.4,5.05-10.64,9.29-4.25,4.25-7.69,8.23-9.29,10.65-1.29,2.6-3.02,7.57-4.57,13.37-1.55,5.8-2.54,10.97-2.73,13.87,.18,2.9,1.17,8.07,2.73,13.87,1.55,5.8,3.28,10.77,4.57,13.37,1.61,2.42,5.05,6.4,9.29,10.64,4.25,4.25,8.23,7.69,10.64,9.3,2.6,1.29,7.57,3.02,13.37,4.57,5.8,1.55,10.97,2.54,13.87,2.73,2.9-.18,8.07-1.17,13.87-2.73,5.8-1.55,10.77-3.28,13.37-4.57,2.42-1.61,6.4-5.05,10.64-9.3,4.25-4.25,7.69-8.23,9.3-10.64,1.29-2.6,3.02-7.57,4.57-13.37,1.55-5.8,2.54-10.97,2.73-13.87-.18-2.9-1.17-8.07-2.73-13.87-1.55-5.8-3.28-10.77-4.57-13.37-1.61-2.42-5.05-6.4-9.3-10.65-4.25-4.25-8.23-7.69-10.64-9.29-2.6-1.29-7.57-3.02-13.37-4.57s-10.97-2.54-13.87-2.73h0Z"
            />
          </g>
          <path
            class="dv"
            d="M377.85,529.5c0,6.02-4.88,10.9-10.9,10.9s-10.9-4.88-10.9-10.9,4.88-10.9,10.9-10.9,10.9,4.88,10.9,10.9Z"
          />
          <circle class="iu" cx="351.21" cy="544.62" r="7.96" />
          <circle class="it" cx="424.07" cy="586.5" r="7.96" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.dv {
  fill: url(#ds);
}
.dv,
.dw,
.dx,
.dy,
.e`,
.ea,
.eb,
.ec,
.ed,
.ee,
.ef,
.eg,
.eh,
.ei,
.ej,
.ek,
.el,
.em,
.en,
.eo,
.ep,
.eq,
.er,
.es,
.et,
.eu,
.ev,
.ew,
.ex,
.ey,
.f`,
.fa,
.fb,
.fc,
.fd,
.fe,
.ff,
.fg,
.fh,
.fi,
.fj,
.fk,
.fl,
.fm,
.fn,
.fo,
.fp,
.fq,
.fr,
.fs,
.ft,
.fu,
.fv,
.fw,
.fx,
.fy,
.g`,
.ga,
.gb,
.gc,
.gd,
.ge,
.gf,
.gg,
.gh,
.gi,
.gj,
.gk,
.gl,
.gm,
.gn,
.go,
.gp,
.gq,
.gr,
.gs,
.gt,
.gu,
.gv,
.gw,
.gx,
.gy,
.h`,
.ha,
.hb,
.hc,
.hd,
.he,
.hf,
.hg,
.hh,
.hi,
.hj,
.hk,
.hl,
.hm,
.hn,
.ho,
.hp,
.hq,
.hr,
.hs,
.ht,
.hu,
.hv,
.hw,
.hx,
.hy,
.i`,
.ia,
.ib,
.ic,
.id,
.ie,
.if,
.ig,
.ih,
.ii,
.ij,
.ik,
.il,
.im,
.in,
.io,
.ip,
.iq,
.ir,
.is {
  fill-rule: evenodd;
}
.dv,
.dw,
.dx,
.dy,
.ea,
.ed,
.eg,
.ek,
.eo,
.eq,
.er,
.ev,
.ew,
.ey,
.f`,
.fb,
.fc,
.fi,
.fm,
.ft,
.fv,
.g`,
.ga,
.gc,
.gd,
.ge,
.gh,
.gi,
.gj,
.gk,
.gm,
.gn,
.gp,
.gr,
.gt,
.gu,
.gv,
.gw,
.ha,
.hd,
.he,
.hh,
.hj,
.hk,
.hp,
.hq,
.hr,
.ib,
.ic,
.it,
.iu,
.ig,
.ii,
.ij,
.ik,
.il,
.im,
.ir,
.is {
  mix-blend-mode: screen;
}
.dw {
  fill: url(#bd);
}
.dx {
  fill: url(#ch);
}
.dy {
  fill: url(#da);
}
.e` {
  fill: url(#w);
}
.ea {
  fill: url(#j);
}
.eb {
  fill: url(#do);
}
.ec {
  fill: url(#di);
}
.ec,
.em,
.ep,
.fa,
.fe,
.fh,
.fn,
.fp,
.fs,
.fy,
.go,
.gy,
.hc,
.hm,
.hn,
.ia,
.ie,
.io {
  mix-blend-mode: multiply;
}
.ed {
  fill: url(#cs);
}
.ee {
  fill: url(#db);
}
.ef {
  fill: url(#dn);
}
.eg {
  opacity: 0.5;
}
.eg,
.eh,
.eo,
.im {
  fill: #23a6d9;
}
.ei {
  fill: url(#ax);
}
.ej {
  fill: url(#ap);
}
.ek {
  fill: url(#bp);
}
.el {
  fill: url(#af);
}
.em {
  fill: url(#cv);
}
.en {
  fill: url(#ae);
}
.ep {
  fill: url(#aa);
}
.eq {
  fill: url(#ah);
}
.er {
  fill: url(#k);
}
.es {
  fill: url(#p);
}
.iv {
  isolation: isolate;
}
.et {
  fill: url(#bb);
}
.eu {
  fill: url(#q);
}
.ev {
  fill: url(#bk);
}
.ew {
  fill: url(#cw);
}
.ex {
  fill: #a0653f;
}
.ey {
  fill: url(#g);
}
.f` {
  fill: url(#cp);
}
.fa {
  fill: url(#bx);
}
.fb {
  fill: url(#bq);
}
.fc {
  fill: url(#aw);
}
.fd {
  fill: url(#h);
}
.fe {
  fill: url(#dg);
}
.ff {
  fill: url(#c);
}
.fg {
  fill: url(#au);
}
.fh {
  fill: url(#bo);
}
.fi {
  fill: url(#s);
}
.fj {
  fill: url(#f);
}
.fk {
  fill: url(#c`);
}
.fl {
  fill: url(#df);
}
.fm {
  fill: url(#av);
}
.fn {
  fill: url(#cd);
}
.fo {
  fill: url(#bn);
}
.fp {
  fill: url(#m);
}
.fq {
  fill: url(#bw);
}
.fr {
  fill: url(#cq);
}
.fs {
  fill: url(#dd);
}
.ft {
  fill: url(#t);
}
.fu {
  fill: url(#cm);
}
.fv {
  fill: url(#cl);
}
.fw {
  fill: url(#bl);
}
.fx {
  fill: url(#ac);
}
.fy {
  fill: url(#r);
}
.g` {
  fill: url(#cx);
}
.ga {
  fill: url(#ba);
}
.gb {
  fill: url(#dp);
}
.gc {
  fill: url(#cg);
}
.gd {
  fill: url(#ak);
}
.ge {
  fill: url(#ai);
}
.gf {
  fill: url(#dr);
}
.gg {
  fill: url(#am);
}
.gh {
  fill: #fef6b8;
}
.gi {
  fill: url(#ca);
}
.gj {
  fill: url(#l);
}
.gk {
  fill: url(#bs);
}
.gl {
  fill: url(#dq);
}
.gm {
  fill: url(#y);
}
.gn {
  fill: url(#cf);
}
.go {
  fill: url(#bj);
}
.gp {
  fill: url(#bh);
}
.gq {
  fill: url(#i);
}
.gr {
  fill: url(#aj);
}
.gs {
  fill: url(#b);
}
.gt {
  fill: url(#d);
}
.gu {
  fill: url(#u);
}
.gv {
  fill: url(#ci);
}
.gw {
  fill: url(#e);
}
.gx {
  fill: url(#cj);
}
.gy {
  fill: url(#dm);
}
.h` {
  fill: url(#ck);
}
.ha {
  fill: url(#by);
}
.hb {
  fill: url(#cb);
}
.hc {
  fill: url(#aq);
}
.hd {
  fill: url(#ad);
}
.he {
  fill: url(#al);
}
.hf {
  fill: url(#cr);
}
.hg {
  fill: url(#x);
}
.hh {
  fill: url(#co);
}
.hi {
  fill: url(#at);
}
.hj {
  fill: url(#dc);
}
.hk {
  fill: url(#n);
}
.hl {
  fill: url(#bm);
}
.hm {
  fill: url(#dl);
}
.hn {
  fill: url(#ay);
}
.ho {
  fill: url(#dk);
}
.hp {
  fill: url(#bi);
}
.hq {
  fill: url(#b`);
}
.hr {
  fill: url(#ct);
}
.hs {
  fill: url(#an);
}
.ht {
  fill: url(#de);
}
.ht,
.hu {
  mix-blend-mode: darken;
}
.hu {
  fill: url(#dj);
}
.hv {
  fill: url(#cc);
}
.hw {
  fill: url(#bu);
}
.hx {
  fill: url(#cu);
}
.hy {
  fill: url(#cy);
}
.i` {
  fill: url(#bt);
}
.ia {
  fill: url(#ag);
}
.ib {
  fill: url(#ce);
}
.ic {
  fill: url(#ao);
}
.id {
  fill: url(#d`);
}
.it {
  fill: url(#du);
}
.ie {
  fill: url(#cn);
}
.iu {
  fill: url(#dt);
}
.if {
  fill: url(#be);
}
.ig {
  fill: url(#ab);
}
.ih {
  fill: url(#bc);
}
.ii {
  fill: url(#bv);
}
.ij {
  fill: url(#v);
}
.ik {
  fill: url(#br);
}
.il {
  fill: url(#bg);
}
.im {
  opacity: 0.65;
}
.in {
  fill: url(#o);
}
.io {
  fill: url(#dh);
}
.ip {
  fill: url(#bf);
}
.iq {
  fill: url(#a`);
}
.ir {
  fill: url(#as);
}
.is {
  fill: url(#ar);
}
</style>
