<script setup lang="ts">
const props = defineProps<{
  label: string;
  modelValue?: string | number;
  value: string | number;
  isDisabled?: boolean;
  isHiddenRadioButton?: boolean;
}>();

const emit = defineEmits(['update:modelValue', 'change', 'update:name']);
const key = `syn-radio-${Math.random() * 100}`;
const onChange = (value) => {
  emit('update:name', props.label);
  emit('update:modelValue', value);
  emit('change', value);
};
</script>

<template>
  <label
    class="flex-center space-x-2 text-xs"
    :class="{
      'cursor-not-allowed': isDisabled,
      'cursor-pointer': !isDisabled,
    }"
    :for="key"
  >
    <input
      :id="key"
      type="radio"
      class="text-current vig-radio"
      :class="{
        'cursor-not-allowed bg-gray-200': isDisabled,
        'cursor-pointer': !isDisabled,
        hidden: isHiddenRadioButton,
      }"
      :checked="modelValue === value"
      :value="value"
      :disabled="isDisabled"
      @change="onChange(value)"
      @keyup.enter.prevent="onChange(value)"
    />
    <slot>
      <label
        v-if="label"
        :for="key"
        :class="{
          'cursor-not-allowed': isDisabled,
          'cursor-pointer': !isDisabled,
        }"
      >
        {{ label }}
      </label>
    </slot>
    <slot name="suffix"></slot>
  </label>
</template>
