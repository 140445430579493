<script setup lang="ts">
import { ref } from 'vue';

defineEmits(['onClose']);

const searchText = ref<string>('');
</script>

<template>
  <SynModal
    z-index="z-60"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    container-style="height: 80vh"
    style-body="p-0 flex flex-col"
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>
        {{ $t('CALENDAR_TITLE_ADD_PEOPLE_INVOLVED') || 'Add involved people' }}
      </div>
    </template>

    <template #body>
      <div class="p-3">
        <VigSearchBox v-model="searchText" autofocus />
      </div>
      <div
        class="
          flex-1 flex-center flex-col
          px-8
          space-y-4
          overflow-auto
          small-scrollbar
        "
      >
        <span class="text-sm text-gray-600 text-center">
          {{ $t('PERSONAL_LABEL_HAVE_NOT_THIS_FUNCTION') }}
        </span>
        <span class="text-sm text-gray-600">
          {{ $t('PERSONAL_LABEL_PLEASE_ADD_NEW_FRIEND') }}
        </span>
      </div>
    </template>
  </SynModal>
</template>
