import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import {
    arrayOrderBy,
    cloneDeep,
    stringNomalize,
    uniq,
} from '@/ui/hooks/commonFunction';
import { getUrgencyValue } from '@/ui/hooks/taskHook';
import { TaskDetailClass } from '@/domain/entities/task/TaskPresentClass';
import { TaskLife } from '@/domain/enums/taskEnum';
import { ignoreUnicode } from '@/ui/plugins/utils';
import myProfileStore from '@/store/auth/my-profile';

const mapKeyForFilter = {
    creatorId: 'id',
    priority: 'key',
    urgency: 'id',
    domainId: 'id',
};

export default defineStore({
    id: storeId.startWorking,
    state: () => ({
        isLoading: true,
        isLoadMore: false,
        planToTreatTasks: [] as any[],
        sortList: [] as any[],
        filterDataByKey: {},
        typeStatus: 'LIST',
        keySearch: null,
        keySearchToFinish: null,
        _taskService: TaskService.getInstance(),
    }),
    getters: {
        sortDataOrderByKey() {
            let result = {};
            const sortLength = this.sortList?.length;
            if (sortLength > 0) {
                const isShowIndex = sortLength > 1;
                result = this.sortList.reduce(
                    (previousValue, currentValue: any, index) => {
                        previousValue = {
                            ...previousValue,
                            ...{
                                [currentValue.key]: {
                                    sortIndex: isShowIndex ? index + 1 : null,
                                    orderBy: currentValue.orderBy,
                                },
                            },
                        };
                        return previousValue;
                    },
                    {}
                );
            }
            return result;
        },
    },
    actions: {
        async fetchListTask() {
            const res = await this._taskService.getTaskListPlannedToTreat();
            this.planToTreatTasks = arrayOrderBy(
                res.map((t) => {
                    return {
                        ...t,
                        taskName: ignoreUnicode(t.name),
                        urgency: getUrgencyValue(t.priority, t.important),
                    };
                }),
                ['urgency', 'scheduleTime', 'name'],
                ['desc', 'asc', 'asc']
            );
            this.setFilterDataOrigin(this.planToTreatTasks);

            return this.planToTreatTasks;
        },

        updateTodoList(newTask) {
            if (newTask.assigneeId !== myProfileStore().myProfile?.id) return;

            const index = this.planToTreatTasks?.findIndex(
                (t) => t?.id == newTask?.id
            );
            if (index > -1) {
                this.planToTreatTasks[index] = {
                    ...newTask,
                    isPlannedToTreat: newTask?.taskSchedule?.doItToday,
                    isPlannedToFinish: newTask?.taskSchedule?.finishItToday,
                };
            } else {
                this.planToTreatTasks = [
                    {
                        ...newTask,
                        isPlannedToTreat: newTask?.taskSchedule?.doItToday,
                        isPlannedToFinish: newTask?.taskSchedule?.finishItToday,
                        urgency: getUrgencyValue(
                            newTask.priority,
                            newTask.important
                        ),
                    },
                    ...this.planToTreatTasks,
                ];
            }
        },

        genSortDataList(sortObj) {
            const { key, name } = sortObj;
            let orderBy = 'asc';
            let sortData = [] as any;
            const indexExisted = this.sortList.findIndex((o) => o.key == key);
            if (indexExisted > -1) {
                const currentOrderBy = this.sortList[indexExisted].orderBy;
                if (currentOrderBy == 'asc') {
                    sortData = [];
                } else {
                    orderBy = currentOrderBy == 'desc' ? 'asc' : 'desc';
                    sortData = [{ key, name, orderBy }];
                }
            } else {
                sortData = [{ key, name, orderBy: 'desc' }];
            }

            this.sortList = sortData;

            return {
                iterate:
                    this.sortList?.length > 0
                        ? [...this.sortList.map((o: any) => o.key)]
                        : ['urgency', 'scheduleTime', 'name'],
                order:
                    this.sortList?.length > 0
                        ? [...this.sortList.map((o: any) => o.orderBy)]
                        : ['desc', 'asc', 'asc'],
            };
        },

        setFilterDataByKey(key, filters) {
            switch (key) {
                case 'scheduleTime':
                case 'creationTime':
                case 'lastModificationTime':
                    this.filterDataByKey[key] = {
                        filterType: filters?.filterType,
                        start: new Date(filters?.start).getTime(),
                        end: new Date(filters?.end).getTime(),
                    };
                    break;
                case 'doToday':
                case 'finishToday':
                    this.filterDataByKey[key] = filters;
                    break;

                case 'domainId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);

                            // const newListProject = o.projectId.map((e) => {
                            //     const isFilterSub = filters.some(
                            //         (u) =>
                            //             u.id == o.id &&
                            //             u.projectId.some(
                            //                 (el) =>
                            //                     el.projectId == e.projectId &&
                            //                     el.selectedId
                            //             )
                            //     );
                            //     e.selectedId = isFilterSub;
                            //     return e;
                            // });
                            // o.subProjectId = newListProject;
                            o.selected = isFilter;
                            return o;
                        });
                    }
                    break;
                case 'projectId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);

                            const newListSubProject = o.subProjectId.map(
                                (e) => {
                                    const isFilterSub = filters.some(
                                        (u) =>
                                            u.id == o.id &&
                                            u.subProjectId.some(
                                                (el) =>
                                                    el.subProjectId ==
                                                        e.subProjectId &&
                                                    el.selectedId
                                            )
                                    );
                                    e.selectedId = isFilterSub;
                                    return e;
                                }
                            );
                            o.subProjectId = newListSubProject;
                            o.selected = isFilter;

                            return o;
                        });
                    }
                    break;

                case 'keySearch':
                case 'name':
                case 'subTasks':
                case 'subDomain':
                    {
                        this.filterDataByKey[key] = filters;
                    }
                    break;

                default:
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const isFilter = filters.some((f) => f.id == o.id);
                            o.selected = isFilter;
                            return o;
                        });
                    }
                    break;
            }
        },

        setCreatorId(originDataList) {
            return uniq(originDataList.map((task) => task.creatorId))
                .filter((o) => o)
                .map((o) => {
                    return {
                        id: o,
                        selected: false,
                    };
                });
        },

        setDomainId(originDataList) {
            return uniq(originDataList.map((task) => task.domainId)).map(
                (o) => {
                    return {
                        id: o,
                        selected: false,
                    };
                }
            );
        },

        setFilterDataOrigin(originDataList) {
            this.filterDataByKey = {
                urgency: [
                    {
                        id: 4,
                        selected: false,
                    },
                    {
                        id: 3,
                        selected: false,
                    },
                    {
                        id: 2,
                        selected: false,
                    },
                    {
                        id: 1,
                        selected: false,
                    },
                ],
                creatorId: originDataList
                    ? this.setCreatorId(originDataList)
                    : [],
                domainId: originDataList
                    ? this.setDomainId(originDataList)
                    : [],
            };
        },
        resetFilterDataOrigin(key) {
            switch (key) {
                case 'scheduleTime':
                case 'creationTime':
                case 'lastModificationTime':
                    {
                        this.filterDataByKey[key] = {
                            start: null,
                            end: null,
                        };
                    }
                    break;
                case 'domainId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            // const listProject = o.projectId.map((u) => {
                            //     u.selectedId = false;
                            //     return u;
                            // });
                            // o.projectId = listProject;
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
                case 'projectId':
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            const listSubProject = o.subProjectId.map((u) => {
                                u.selectedId = false;
                                return u;
                            });
                            o.subProjectId = listSubProject;
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
                default:
                    {
                        this.filterDataByKey[key] = this.filterDataByKey[
                            key
                        ].map((o) => {
                            o.selected = false;
                            return o;
                        });
                    }
                    break;
            }
        },

        onFilterTaskOnColumn(filteredData) {
            const filterData = cloneDeep(this.filterDataByKey);
            if (!filterData) return filteredData;

            Object.entries(filterData).forEach(([key, value]: any) => {
                switch (key) {
                    case 'scheduleTime':
                    case 'creationTime':
                    case 'lastModificationTime':
                        {
                            const filterType:
                                | 'TODAY'
                                | 'OTHER'
                                | 'NOTHING'
                                | null = value?.filterType;
                            if (!filterType) return;
                            if (filterType === 'NOTHING') {
                                filteredData = [
                                    ...(filteredData?.filter((t) => {
                                        return !t[key];
                                    }) || []),
                                ];
                                return;
                            }
                            if (filterType === 'TODAY') {
                                filteredData = [
                                    ...(filteredData?.filter((t) => {
                                        return dayjs(t[key]).isSame(
                                            Date.now(),
                                            'date'
                                        );
                                    }) || []),
                                ];
                                return;
                            }
                            if (!value?.start && !value?.end) return;

                            if (value?.start) {
                                filteredData = [
                                    ...(filteredData?.filter((t) => {
                                        return (
                                            dayjs(t[key]).isSame(
                                                value?.start,
                                                'date'
                                            ) ||
                                            dayjs(t[key]).isAfter(
                                                value?.start,
                                                'date'
                                            )
                                        );
                                    }) || []),
                                ];
                            }
                            if (value?.end) {
                                filteredData = [
                                    ...(filteredData?.filter((t) => {
                                        return (
                                            dayjs(t[key]).isSame(
                                                value?.end,
                                                'date'
                                            ) ||
                                            dayjs(t[key]).isBefore(
                                                value?.end,
                                                'date'
                                            )
                                        );
                                    }) || []),
                                ];
                            }
                        }
                        break;
                    case 'name':
                        {
                            const keySearch = value?.toLowerCase();
                            filteredData = !keySearch
                                ? [...filteredData]
                                : filteredData.filter(
                                      (t) =>
                                          stringNomalize(
                                              t.name?.toLowerCase()
                                          ).includes(
                                              stringNomalize(keySearch)
                                          ) ||
                                          stringNomalize(
                                              t.code?.toLowerCase()
                                          ).includes(
                                              stringNomalize(keySearch)
                                          ) ||
                                          stringNomalize(
                                              t.displayName?.toLowerCase()
                                          ).includes(stringNomalize(keySearch))
                                  );
                        }
                        break;
                    case 'domainId':
                        {
                            const selectedFilter = (value as any).filter(
                                (o) => o.selected
                            );

                            if (selectedFilter.length == 0) return;

                            filteredData = [
                                ...(filteredData?.filter((t) =>
                                    selectedFilter?.some(
                                        (f) => f.id == t.domainId
                                    )
                                ) || []),
                            ];
                        }
                        break;

                    default:
                        {
                            const mapKey = mapKeyForFilter[key] || 'id';
                            const selectedFilter = (value as any).filter(
                                (o) => o.selected
                            );
                            if (selectedFilter.length == 0) return;
                            filteredData = [
                                ...(filteredData?.filter((t) =>
                                    selectedFilter?.some(
                                        (f) =>
                                            f[mapKey] == t[key] ||
                                            (!f[mapKey] && !t[key])
                                    )
                                ) || []),
                            ];
                        }
                        break;
                }
            });

            return filteredData;
        },

        handleFinishTask(task, index) {
            this.planToTreatTasks.splice(index, 1);
            new TaskDetailClass(task).changeState(TaskLife.Finished);
        },

        handleSelectedTask(task) {
            const indexTask = this.planToTreatTasks.findIndex(
                (o) => o.id == task.id
            );
            if (indexTask == -1) return;
            this.planToTreatTasks[indexTask] = task;
        },
        removeTask(task) {
            this.planToTreatTasks = this.planToTreatTasks?.filter(
                (t) => t?.id !== task?.id
            );
        },
    },
});
