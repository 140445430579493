<script setup lang="ts">
import { computed, ref } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import paymentStore from '@/store/payment';
import DetailTransactionModal from '@/ui/modules/payments/billings/DetailTransactionModal.vue';
import { PaymentStatusText } from '@/ui/common/constants/payment';
// import appStore from '@/store/app';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { EPaymentPurpose } from '@/application/enums/PaymentEnums';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const _paymentStore = paymentStore();

const dataHistory = computed(() => {
  const searchText = ignoreUnicode(keySearch.value);
  if (!searchText) return _paymentStore.billingHistory;
  return (_paymentStore.billingHistory || [])?.filter(
    (bill) =>
      bill?.payerName && ignoreUnicode(bill?.payerName).includes(searchText)
  );
});
const isLoadingBilling = computed(() => _paymentStore.isLoadingBillingHistory);
// const language = computed(() => _appStore.language);

const keySearch = ref('');
const isShowDetailTransactionId = ref(null);
const methodPayment = {
  VN_PAY: {
    label: 'VNPAY',
  },
  MOMO: {
    label: 'MOMO',
  },
  VOUCHER: {
    label: 'VOUCHER',
  },
  ZALO_PAY: {
    label: 'ZALOPAY',
  },
};

const translateText = (status) => {
  switch (status) {
    case PaymentStatusText.Paid:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_PAID',
        class: 'border-green-500 text-green-500',
      };
    case PaymentStatusText.Cancelled:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_CANCELLED',
        class: 'border-gray-500 text-gray-500',
      };
    case PaymentStatusText.Failed:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_FAILED',
        class: 'border-red-500 text-red-500',
      };
    case PaymentStatusText.Handling:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_HANDLING',
        class: 'border-orange-500 text-orange-500',
      };
    default:
      return {
        label: 'COMMOM_LABEL_PAYMENT_STATUS_HANDLING',
        class: 'border-orange-500 text-orange-500',
      };
  }
};

const init = async () => {
  await _paymentStore.getPaymentHistories();
};
init();

const onShowDetailTransaction = async (id) => {
  isShowDetailTransactionId.value = id;
};

const onReset = () => {
  init();
};

const transactionPurpose = (data) => {
  if (data?.paymentPurpose == EPaymentPurpose.PURCHASE)
    return translate('BILLING_MODAL_PAYMENT_PURPOSE_PURCHASE', {
      planId: data?.paymentPlanId,
    });
  if (data?.paymentPurpose == EPaymentPurpose.Upgrade)
    return translate('BILLING_MODAL_PAYMENT_PURPOSE', {
      fromPackage: data?.previousPlanId || '',
      toPackage: data?.paymentPlanId || '',
    });
  if (
    data?.paymentPurpose == EPaymentPurpose.Extend ||
    data?.paymentPurpose == EPaymentPurpose.Renew
  )
    return translate('PAYMENT_EXTEND_FOR_PLAN', {
      planId: data?.paymentPlanId,
    });
  if (data?.paymentPurpose == EPaymentPurpose.BuyMore)
    return translate('PAYMENT_BUY_MORE_FOR_PLAN', {
      planId: data?.paymentPlanId,
      totalUsers: '',
      suffix: '',
    });
  if (data?.paymentPurpose == EPaymentPurpose.AppliedVoucher)
    return translate('PAYMENT_APPLIED_VOUCHER_FOR_PLAN', {
      planId: data?.paymentPlanId,
      totalUsers: '',
      voucherCode: data?.voucherCode,
      voucherSource: data?.voucherSource,
    });
  if (data?.paymentPurpose == EPaymentPurpose.APPLY_LICENSE)
    return translate('PAYMENT_APPLIED_LICENSE_FOR_PLAN');
  if (data?.paymentPurpose == EPaymentPurpose.BUY_LICENSE)
    return translate('PAYMENT_BUY_LICENSE');
  return '';
};
</script>

<template>
  <div
    v-if="!isLoadingBilling && dataHistory?.length < 1 && !keySearch"
    class="flex-center flex-col space-y-6 pt-16"
  >
    <syn-animation name="emptyBox" stype="width: 150px; height: 150px" />
    <span class="text-gray-400">
      {{ $t('SETTING_PLAN_BILLING_HISTORY_NO_DATA_LABEL') }}
    </span>
  </div>
  <template v-else>
    <div class="flex items-center justify-between py-3 px-3 w-full">
      <div class="w-1/3 h-10 flex items-center">
        <VigSearchBox
          v-model="keySearch"
          class="w-72 text-xs"
          input
          :placeholder="$t('COMMON_LABEL_SEARCH_PLACEHOLDER_MEMBER')"
        />
      </div>
      <div class="flex-center space-x-2">
        <div
          class="
            flex-center
            p-3
            rounded
            border border-gray-100
            hover:bg-gray-50
            cursor-pointer
          "
          @click="onReset"
        >
          <SynIcon name="reload" custom-class="w-3 h-3 fill-gray-500" />
        </div>
      </div>
    </div>

    <div class="px-3 py-1 flex-1 overflow-y-auto small-scrollbar">
      <syn-table-custom>
        <template #header>
          <SynTr>
            <SynTh :label="$t('BILLING_HISTORY_TABLE_PAYER')"></SynTh>
            <SynTh :label="$t('BILLING_HISTORY_TABLE_PAYER_INFO')"></SynTh>
            <SynTh :label="$t('BILLING_HISTORY_TABLE_DATE')"></SynTh>
            <SynTh :label="$t('BILLING_HISTORY_TABLE_PAYMENT_OPTIONS')"></SynTh>
            <SynTh :label="$t('BILLING_HISTORY_TABLE_PAYMENT_METHOD')"></SynTh>
            <SynTh :label="$t('BILLING_HISTORY_TABLE_STATUS')"></SynTh>
            <SynTh
              custom-class=" text-right"
              :label="$t('BILLING_HISTORY_TABLE_AMOUNT')"
            ></SynTh>
          </SynTr>
        </template>

        <template #body>
          <syn-table-tr-loading v-if="isLoadingBilling">
            <div v-for="item in 7" :key="item" class="table-cell p-2 h-96">
              <div class="p-2 w-3/4 bg-gray-200 rounded-md"></div>
            </div>
          </syn-table-tr-loading>
          <template
            v-if="!isLoadingBilling && dataHistory?.length < 1 && keySearch"
          >
            <SynTr class="h-full border-b-none" hover="hover:bg-current-50">
              <SynTd :colspan="7" style="height: calc(100vh - 16rem)">
                <syn-animation
                  name="searchIcon"
                  stype="width: 200px; height: 200px"
                  :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                />
              </SynTd>
            </SynTr>
          </template>
          <template v-if="!isLoadingBilling">
            <SynTr
              v-for="item in dataHistory"
              :key="item"
              class="cursor-pointer hover:bg-gray-50"
              @click="onShowDetailTransaction(item?.transactionId)"
            >
              <SynTd class="h-full">
                <div class="min-w-max flex items-center px-1">
                  <div
                    v-if="
                      item?.payerId === 1 ||
                      item?.payerName == 'ADMIN TICTOP' ||
                      item?.paymentPurpose == EPaymentPurpose.BUY_LICENSE
                    "
                    class="flex-center space-x-2"
                  >
                    <div class="flex-center rounded-full w-8 h-8 bg-current-50">
                      <SynIcon name="TictopLogo" />
                    </div>
                    <span
                      :title="item?.payerName"
                      class="font-normal text-overflow-hidden-line"
                      style="max-width: 15rem"
                      >{{ item?.payerName }}</span
                    >
                  </div>

                  <div v-else class="flex items-center space-x-2">
                    <syn-avatar
                      :src="item?.payerAvatar"
                      :name="item?.payerName"
                      custom-class="w-8 h-8"
                    />
                    <span class="text-overflow-hidden-line">
                      {{ item?.payerName }}
                    </span>
                  </div>
                </div>
              </SynTd>

              <SynTd>
                <div class="flex flex-col px-1">
                  <div class="flex items-center space-x-2">
                    <SynIcon name="email" custom-class="w-3 h-3 fill-current" />
                    <span>{{ item?.payerEmail }}</span>
                  </div>
                  <div class="flex items-center space-x-2">
                    <SynIcon name="phone" custom-class="w-3 h-3 fill-current" />
                    <span>{{ item?.payerPhone }}</span>
                  </div>
                </div>
              </SynTd>

              <SynTd>
                {{ $filters.dayjs(item.payDate, 'MMM DD, YYYY hh:mm A') }}
              </SynTd>

              <SynTd>
                <span class="px-1">{{ transactionPurpose(item) }}</span>
                <span
                  v-if="
                    item?.paymentPurpose === EPaymentPurpose.APPLY_LICENSE ||
                    item?.paymentPurpose === EPaymentPurpose.BUY_LICENSE
                  "
                  class="
                    px-2
                    mx-2
                    py-1
                    rounded-full
                    bg-orange-50
                    text-orange-500 text-xs
                    font-medium
                  "
                  >LIFETIME VERSION</span
                >
              </SynTd>

              <SynTd>
                <span class="px-1 font-bold text-gray-400">
                  {{
                    methodPayment[item?.paymentMethod]?.label ||
                    item?.paymentMethod
                  }}
                </span>
              </SynTd>

              <SynTd>
                <div
                  class="
                    mx-1
                    min-w-max
                    w-max
                    flex-center
                    px-2
                    py-0.5
                    border
                    text-xs
                    font-medium
                    rounded-full
                  "
                  :class="translateText(item?.paymentStatus)?.class"
                >
                  <span>
                    {{ $t(translateText(item?.paymentStatus)?.label) }}
                  </span>
                </div>
              </SynTd>

              <SynTd class="text-right">
                <template
                  v-if="
                    item?.paymentPurpose === EPaymentPurpose.APPLY_LICENSE ||
                    item?.paymentPurpose === EPaymentPurpose.BUY_LICENSE
                  "
                >
                  <span class="text-base font-medium">
                    {{ $filters.currency(item?.totalAmount, 'vi') }}
                  </span>
                  <div class="flex items-center justify-end space-x-2 w-full">
                    <div class="flex-center space-x-1">
                      <SynIcon
                        name="license"
                        custom-class="h-4 w-4 fill-current"
                      />
                      <span class="text-xs"> License </span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <span class="text-base font-medium">
                    {{ $filters.currency(item?.totalAmount, 'vi') }}
                  </span>
                  <div
                    v-if="item?.voucherCode"
                    class="flex items-center justify-end space-x-2 w-full"
                  >
                    <div class="flex-center space-x-1">
                      <SynIcon
                        name="ticket-voucher"
                        custom-class="h-5 w-5 fill-current"
                      />
                      <span class="text-xs">
                        {{ item?.voucherCode }}
                      </span>
                    </div>
                    <span class="line-through text-sm">
                      {{
                        $filters.currency(item?.totalAmountBeforeDiscount, 'vi')
                      }}
                    </span>
                  </div>
                </template>
              </SynTd>
            </SynTr>
          </template>
        </template>
      </syn-table-custom>
    </div>
  </template>

  <DetailTransactionModal
    v-if="isShowDetailTransactionId"
    :transaction-id="isShowDetailTransactionId"
    @cancel="(value) => (isShowDetailTransactionId = value)"
  />
</template>
