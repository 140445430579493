<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ignoreUnicode } from '@/ui/plugins/utils';
import { markTextSearch } from '@/ui/hooks/commonFunction';
import settingStore from '@/store/setting';
import taskStore from '@/store/task';
import groupStore from '@/store/group';
import overviewGroupStore from '@/store/dashboard/overview-group-store';
// import TaskCard from '@/ui/modules/overview/TaskCard.vue';
import GroupInfoCard from '@/ui/modules/overview/GroupInfoCard.vue';
import GroupDetailModal from '@/ui/modules/group/detail/GroupDetailModal.vue';
import NotificationButton from '@/ui/components/group/notification-button.vue';
import ActionById from '@/ui/components/user/ActionById.vue';
import { taskListRouteByType } from '@/ui/hooks/commonHook';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { ILatestTaskActivity } from '@/ui/plugins/firebases/realtime-database/types/organization-dynamic-model.types';
import useChatCommon from '@/ui/composables/chat/chat-common-composables';
import TaskCardItem from '@/ui/modules/task/board/task-card/TaskCardItem.vue';

const emit = defineEmits([
  'openTaskDetail',
  'openCreateTaskForUser',
  'onAdd',
  'onCreateTaskForGroup',
  'changedPrivateSuccess',
]);

const router = useRouter();
const _groupStore = groupStore();
const _taskStore = taskStore();
const _overviewGroupStore = overviewGroupStore();
const _settingStore = settingStore();

const chatEnabled = computed(() => _settingStore.chatEnabled);
const overviewGroupIds = computed(() => _overviewGroupStore.overviewGroupIds);
const overviewGroupInfo = computed(() => _overviewGroupStore.overviewGroupInfo);
const isHiddenTask = computed(() => _overviewGroupStore.isHiddenTask);
const overviewGroupTasks = computed(
  () => _overviewGroupStore.overviewGroupTasks
);
const overviewGroupNotification = computed(
  () => _overviewGroupStore.overviewGroupNotification
);
const isLoadingGroupTask = computed(
  () => _overviewGroupStore.isLoadingGroupTask
);
const myGroupByIds = computed(() => _groupStore.myGroupByIds);

const searchText = computed(() => _overviewGroupStore.keySearchGroup);

const filteredGroups = computed(() => {
  const searchTxt = ignoreUnicode(searchText.value);

  // if (!searchTxt) return allMyGroups.value;

  const overviewGroupInfos =
    overviewGroupIds.value?.map((groupId) => myGroupByIds.value[groupId]) || [];

  if (!searchTxt || overviewGroupInfos?.length == 0) return overviewGroupInfos;

  const result = overviewGroupInfos.reduce((arr, group) => {
    const isMatchName = !!(
      group.name && ignoreUnicode(group.name).includes(searchTxt)
    );

    const isMatchDomain = (group.groupDomains || []).filter(
      (domainName) =>
        domainName.domainName &&
        ignoreUnicode(domainName.domainName).startsWith(searchTxt)
    );

    const isMatchUser = (group.groupUsers || []).filter((userName) => {
      const fullName1 = userName.lastName + ' ' + userName.firstName;
      const fullName2 = userName.firstName + ' ' + userName.lastName;
      return (
        ignoreUnicode(fullName1).startsWith(searchTxt) ||
        ignoreUnicode(fullName2).startsWith(searchTxt)
      );
    });

    if (isMatchName || isMatchDomain?.length > 0 || isMatchUser?.length > 0) {
      group.isMatchName = isMatchName;
      group.textMatchName = isMatchName ? searchTxt : '';
      group.arrayMatchUser = isMatchUser?.length > 0 ? isMatchUser : [];
      group.arrayMatchDomain = isMatchDomain?.length > 0 ? isMatchDomain : [];
      arr.push(group);
    }
    return arr;
  }, []);

  return result;
});

let { unsubscribeCallbacks } = listenFirebaseRealtime();
onMounted(async () => {
  const LatestTaskActivityFirebaseModel = new OrganizationDynamicModel(
    `latestTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    LatestTaskActivityFirebaseModel.onChange((data: ILatestTaskActivity) => {
      _overviewGroupStore.updateUserTasksRealtime(
        data?.action,
        data?.taskDetail
      );
    })
  );
});

const loadingMore = ref(null);
const currentGroupDetailId = ref(null as any);

const { goToChatFromContact } = useChatCommon();

const onSeeMore = async (groupId) => {
  if (!groupId) return;
  if (overviewGroupTasks.value[groupId].isShowMore) {
    _overviewGroupStore.overviewGroupTasks[groupId].isShowMore = false;
  } else {
    loadingMore.value = groupId;
    const res = await _taskStore.fetchGroupTaskDefault(groupId);
    if (!res || !res.result) return;
    _overviewGroupStore.setOverviewTeamTaskMore(groupId, res.result);
  }
  loadingMore.value = null;
};

const onCreateTaskForGroup = (groupId) => {
  emit('onCreateTaskForGroup', groupId);
};

const onMessageTo = (group) => {
  goToChatFromContact(group, true);
};

const chooseContact = (contact, contactType = 'group') => {
  if (!contact?.id) return;

  const routeType = contactType == 'member' ? 'USER' : 'GROUP';

  router.replace(taskListRouteByType(routeType, contact?.id));
};

const chooseGroup = async (groupId) => {
  router.push(taskListRouteByType('GROUP', groupId));
};

const onShowSettingGroup = (groupId) => {
  currentGroupDetailId.value = groupId;
};

const onMouserOver = (groupId) => {
  _overviewGroupStore.setFullTaskForGroup(groupId);
};
</script>

<template>
  <section class="w-full h-full relative flex flex-col">
    <div class="relative flex-1 min-h-0">
      <template v-if="filteredGroups?.length <= 0 && searchText">
        <div class="py-16 h-full w-full text-center">
          <syn-animation
            name="searchIcon"
            :loop="false"
            stype="width: 200px; height: 200px;"
          />
          <span class="font-medium text-lg text-gray-600">{{
            $t('COMMON_LABEL_ALERT_NO_SEARCH')
          }}</span>
        </div>
      </template>
      <div v-if="overviewGroupIds.length < 1" class="flex-center flex-col py-6">
        <SynAnimation name="setupTeam" stype="width: 250px;" />
        <label class="text-xl text-current-600">
          {{ $t('GROUP_TABLE_ALERT_NO_LIST_GROUP') }}
        </label>
      </div>
      <div
        v-else-if="filteredGroups && filteredGroups.length > 0"
        class="
          px-6
          pt-10
          w-full
          h-full
          grid
          auto-rows-max
          overflow-auto
          small-scrollbar
        "
        style="grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr))"
      >
        <div
          v-for="group in filteredGroups"
          :key="group?.id"
          class="w-full px-4 pb-8 relative hover-to-show__parent"
          :style="`height:${isHiddenTask ? '' : '20rem'}; `"
          @mouseover="onMouserOver(group?.id)"
        >
          <div
            class="
              w-full
              h-full
              rounded-lg
              bg-white
              shadow
              flex flex-col
              divide-y divide-gray-50
            "
          >
            <!--  Result Search-->
            <div v-if="searchText" class="bg-white flex-row rounded-t-lg flex">
              <div
                class="
                  py-1.5
                  px-2
                  w-full
                  text-overflow-hidden-line
                  h-8
                  items-center
                "
              >
                <template v-if="group.arrayMatchUser?.length > 0 && searchText">
                  <template
                    v-for="(user, index) in group.arrayMatchUser"
                    :key="user"
                  >
                    <span
                      class="text-xs"
                      v-html="markTextSearch(user.name, searchText)"
                    ></span>
                    <span
                      v-if="
                        index < group.arrayMatchUser.length - 1 ||
                        group.arrayMatchDomain?.length
                      "
                      >,</span
                    >
                  </template>
                </template>
                <template
                  v-if="group.arrayMatchDomain?.length > 0 && searchText"
                >
                  <template
                    v-for="(domain, index) in group.arrayMatchDomain"
                    :key="domain"
                  >
                    <span
                      class="text-xs"
                      v-html="markTextSearch(domain.domainName, searchText)"
                    ></span>
                    <span v-if="index < group.arrayMatchDomain.length - 1"
                      >,</span
                    >
                  </template>
                </template>
              </div>
            </div>
            <!--  Info group -->
            <div
              class="
                w-full
                flex
                items-center
                justify-between
                border-b
                space-x-2
                relative
                px-1
              "
            >
              <div class="flex-1 flex items-center space-x-1">
                <GroupInfoCard
                  :group-info="overviewGroupInfo[group?.id]"
                  :search-text="searchText || ''"
                  @click-name="chooseGroup(group?.id)"
                />
                <NotificationButton
                  v-if="
                    overviewGroupNotification &&
                    overviewGroupNotification[group?.id]
                  "
                  :ref-id="group?.id"
                  :name="overviewGroupInfo[group?.id]?.name"
                />
              </div>

              <div class="flex-center space-x-2">
                <ActionById
                  v-if="group?.id"
                  :contact-id="group?.id"
                  :types="['favourite']"
                  type-contact="group"
                />

                <VigDropdown placement="bottom-end">
                  <template #dropdown-toggle>
                    <div
                      class="
                        w-7
                        h-7
                        flex-center
                        rounded-full
                        hover:bg-current-50
                        hover:fill-current
                        hover:text-current-500
                      "
                    >
                      <SynIcon name="dots-vertical" />
                    </div>
                  </template>
                  <template #dropdown-menu>
                    <SynDropdownMenu class="right-0 w-44">
                      <SynDropdownItem
                        v-if="group?.isMember"
                        :title="
                          $t('TASK_CREATE_FORM_CREATE_TASK_CREATE_FORM_FOR') +
                          ' ' +
                          group?.name
                        "
                        is-close
                        @click="onCreateTaskForGroup(group?.id)"
                      >
                        <div class="flex items-center">
                          <div class="w-4 h-4 flex-center">
                            <SynIcon
                              name="create-tasks"
                              is-active
                              custom-class="w-4 h-4 fill-gray-500 text-gray-600"
                            />
                          </div>
                          <span class="pl-2 text-gray-600 truncate"
                            >{{
                              $t('TASK_CREATE_FORM_CREATE_TASK_CREATE_FORM_FOR')
                            }}
                            <span class="font-medium pl-1 text-gray-500">{{
                              group?.name
                            }}</span>
                          </span>
                        </div>
                      </SynDropdownItem>
                      <SynDropdownItem
                        v-if="chatEnabled && group?.isMember"
                        :title="
                          $t('LIST_TASK_CHAT_TO_USER', { user: group?.name })
                        "
                        is-close
                        @click="onMessageTo(group)"
                      >
                        <div class="flex items-center">
                          <div class="w-4 h-4 flex-center">
                            <SynIcon
                              name="message"
                              is-active
                              custom-class="w-3.5 h-3.5 fill-gray-500 text-gray-500"
                            />
                          </div>

                          <span class="pl-2 text-gray-600 truncate"
                            >{{
                              $t('LIST_TASK_CHAT_TO_USER', {
                                user: group?.name,
                              })
                            }}
                          </span>
                        </div>
                      </SynDropdownItem>
                      <SynDropdownItem
                        :title="
                          $t('LIST_TASK_VIEW_USER_TASK', {
                            user: group?.name,
                          })
                        "
                        is-close
                        @click="chooseContact(group)"
                      >
                        <div class="flex items-center">
                          <div class="w-4 h-4 flex-center">
                            <SynIcon
                              name="note"
                              is-active
                              custom-class="w-3.5 h-3.5 fill-gray-500 text-gray-600"
                            />
                          </div>

                          <span class="pl-2 text-gray-600 truncate"
                            >{{
                              $t('LIST_TASK_VIEW_USER_TASK', {
                                user: group?.name,
                              })
                            }}
                          </span>
                        </div>
                      </SynDropdownItem>
                      <SynDropdownItem
                        :title="$t('COMMON_LABEL_SETTING')"
                        is-close
                        @click="onShowSettingGroup(group?.id)"
                      >
                        <div class="flex items-center">
                          <div class="w-4 h-4 flex-center">
                            <SynIcon
                              name="settings"
                              is-active
                              custom-class="w-4 h-4 fill-gray-500 text-gray-500"
                            />
                          </div>

                          <span class="pl-2 text-gray-600">{{
                            $t('COMMON_LABEL_SETTING') || 'Settings'
                          }}</span>
                        </div>
                      </SynDropdownItem>
                    </SynDropdownMenu>
                  </template>
                </VigDropdown>
              </div>
            </div>

            <div
              v-if="!isHiddenTask"
              class="flex-1 overflow-y-auto small-scrollbar relative"
            >
              <div
                v-if="isLoadingGroupTask"
                class="
                  absolute
                  bottom-0
                  left-0
                  w-full
                  h-1/2
                  flex flex-col
                  space-y-2
                "
              >
                <div class="flex-center space-x-2">
                  <div class="h-3 w-3 bg-current-100 rounded-full"></div>
                  <div class="h-3 w-3 bg-current-100 rounded-full"></div>
                  <div class="h-3 w-3 bg-current-100 rounded-full"></div>
                  <div class="h-3 w-3 bg-current-100 rounded-full"></div>
                </div>
              </div>
              <div v-else-if="group?.id" class="h-full">
                <template
                  v-if="overviewGroupTasks[group?.id]?.groupTasks?.length > 0"
                >
                  <TaskCardItem
                    v-for="task in overviewGroupTasks[group?.id].groupTasks"
                    :key="task"
                    :task-detail="task"
                    class="border-b border-gray-200"
                    readonly
                  />
                </template>
                <div v-else class="p-4">
                  <div
                    class="
                      bg-gray-50
                      py-4
                      rounded-lg
                      text-center
                      w-full
                      text-sm
                      px-12
                      text-gray-500
                    "
                  >
                    {{
                      $t('TASK_LABEL_ALERT_NO_TASK', {
                        member: overviewGroupInfo[group?.id]?.name,
                      })
                    }}
                  </div>
                </div>
                <div
                  v-if="overviewGroupTasks[group?.id]?.hasMoreTask"
                  class="
                    flex-center
                    text-gray-500 text-sm
                    py-1
                    space-x-2
                    hover:text-current-400
                    cursor-pointer
                    relative
                  "
                  style="border-bottom-width: 1px"
                  @click="onSeeMore(group?.id)"
                >
                  <SynIcon
                    :name="
                      overviewGroupTasks[group?.id]?.isShowMore
                        ? 'chevron-double-up'
                        : 'chevron-double-down'
                    "
                  />
                  <span class="font-semibold">
                    {{
                      overviewGroupTasks[group?.id]?.isShowMore
                        ? $t('TASK_CREATE_FORM_SHOW_LESS') || 'Show less'
                        : $t('TASK_CREATE_FORM_SHOW_MORE') || 'Show more'
                    }}
                  </span>
                  <syn-local-loading v-if="loadingMore == group?.id" />
                </div>
                <template v-if="overviewGroupTasks[group?.id]?.isShowMore">
                  <template
                    v-if="
                      overviewGroupTasks[group?.id].taskListSeemore?.length > 0
                    "
                  >
                    <TaskCardItem
                      v-for="task in overviewGroupTasks[group?.id]
                        .taskListSeemore"
                      :key="task"
                      class="border-b"
                      :task-detail="task"
                      readonly
                    />
                  </template>
                  <div
                    v-else
                    class="w-full flex flex-col justify-center items-center"
                  >
                    <div class="w-12 h-12">
                      <SynIcon name="nodata" />
                    </div>
                    <span>
                      {{ $t('OVERVIEW_TASK_NO_DATA') }}
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <GroupDetailModal
    v-if="currentGroupDetailId"
    position="members"
    :group-id="currentGroupDetailId"
    @cancel="currentGroupDetailId = null"
    @changed-private-success="$emit('changedPrivateSuccess')"
  />
  <slot name="suffix"></slot>
</template>
