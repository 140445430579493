import BaseModel from './BaseModel';
import {
    collection,
    CollectionReference,
    doc,
    DocumentData,
    getDocs,
    getDocsFromCache,
    limit,
    onSnapshot,
    orderBy,
    startAfter,
    endAt,
    endBefore,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';
import {
    PREPARED_MESSAGE_TOTAL_MAX,
    PREPARED_MORE_MESSAGE_WHEN_MESSAGE_REMAINS,
} from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import {
    getDocsFromServer,
    QuerySnapshot,
    Unsubscribe,
} from '@firebase/firestore';

export default class MessageCollectionModel extends BaseModel {
    collectionRef: CollectionReference<DocumentData>;
    organizationId: number;

    constructor(conversationId: string) {
        super();

        this.organizationId =
            MyOrganizationSingleton.getInstance().activeOrganization;

        this.collectionRef = collection(
            doc(
                collection(
                    doc(
                        collection(firestoreDb, 'organizationConversations'),
                        `${this.organizationId}`
                    ),
                    'conversations'
                ),
                `${conversationId}`
            ),
            'messages'
        );
    }

    forward(message, userId) {
        const newMsg = {
            status: 1,
            isForwarded: true,
            createdBy: `${userId}`,
            createdDate: this.serverTimestamp(),
            text: '',
            files: [],
        };

        message?.text && (newMsg.text = message?.text);
        message?.files && (newMsg.files = message?.files);

        return this.addDoc(this.collectionRef, message);
    }

    seenMessages(messageIds, userId) {
        const batch = this.writeBatch(firestoreDb);

        (messageIds || []).map((messageId) =>
            batch.set(
                doc(this.collectionRef, `${messageId}`),
                { seenBy: { [`${userId}`]: this.serverTimestamp() } },
                { merge: true }
            )
        );

        // Commit the batch
        return batch.commit();
    }

    getDefaultListFromCache(filter: {
        limit?: number;
        startAfter?: any;
        endBefore?: string | null;
    }) {
        return getDocsFromCache(this.genarateQuery(filter));
    }

    getDefaultList(filter: {
        limit?: number;
        startAfter?: any;
        endBefore?: any;
    }) {
        return getDocs(this.genarateQuery(filter));
    }

    getUnreadMessages(lastestCreateDate) {
        return getDocs(this.genarateUnreadQuery(lastestCreateDate));
    }

    getMessages(startAfterVal?: any, endBeforeVal?: any, pageSize?: number) {
        let q = this.getQuery(
            this.collectionRef,
            orderBy('createdDate', 'desc')
        );

        if (startAfterVal) {
            q = this.getQuery(q, startAfter(startAfterVal));
        }

        if (endBeforeVal) {
            q = this.getQuery(q, endBefore(endBeforeVal));
        }

        if (pageSize) {
            q = this.getQuery(q, limit(pageSize));
        }

        return getDocs(q);
    }

    getMoreMessages(lastestCreateDate) {
        return getDocs(this.genarateGetMoreQuery(lastestCreateDate));
    }

    subscibleDefaultListChange(
        filter: { limit?: number; startAfter: any },
        callback
    ) {
        return onSnapshot(this.genarateQuery(filter), (snapshot) => {
            snapshot.docChanges().forEach((change: any) => {
                callback && callback(change);
            });
        });
    }

    addMessage(newMsg) {
        return this.addDoc(this.collectionRef, newMsg);
    }

    newMessageDocRef() {
        return doc(this.collectionRef);
    }

    setMessageDoc(docRef, newMsg) {
        return this.setDoc(docRef, newMsg, {});
    }

    private genarateQuery(filter: {
        limit?: number;
        startAfter?: any;
        endAt?: any;
        endBefore?: any;
    }) {
        let q = this.getQuery(
            this.collectionRef,
            orderBy('createdDate', 'desc')
        );

        if (filter.startAfter)
            q = this.getQuery(q, startAfter(filter.startAfter));

        if (filter.endAt) q = this.getQuery(q, endAt(filter.endAt));

        if (filter.endBefore) q = this.getQuery(q, endBefore(filter.endBefore));

        if (filter.limit) q = this.getQuery(q, limit(filter.limit));
        return q;
    }

    private genarateUnreadQuery(lastestCreateDate) {
        let q = this.getQuery(
            this.collectionRef,
            orderBy('createdDate', 'desc')
        );

        if (lastestCreateDate) {
            q = this.getQuery(q, endBefore(new Date(lastestCreateDate)));
        }

        q = this.getQuery(q, limit(PREPARED_MESSAGE_TOTAL_MAX));

        return q;
    }

    private genarateLastestMessageQuery(lastestCreateDate) {
        let q = this.getQuery(
            this.collectionRef,
            orderBy('createdDate', 'desc')
        );

        if (lastestCreateDate) {
            q = this.getQuery(q, endBefore(new Date(lastestCreateDate)));
        }

        q = this.getQuery(q, limit(300));

        return q;
    }

    private genarateGetMoreQuery(lastestCreateDate) {
        let q = this.getQuery(
            this.collectionRef,
            orderBy('createdDate', 'desc')
        );

        if (lastestCreateDate) {
            q = this.getQuery(q, startAfter(new Date(lastestCreateDate)));
        }

        q = this.getQuery(q, limit(PREPARED_MORE_MESSAGE_WHEN_MESSAGE_REMAINS));

        return q;
    }

    getMessagesFromLocal(
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number
    ): Promise<QuerySnapshot<any>> {
        const query = this._prepareMessagesQuery(
            startAfterVal,
            endBeforeVal,
            pageSize
        );

        return getDocsFromCache(query);
    }

    getMessagesFromServer(
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number
    ): Promise<QuerySnapshot<any>> {
        const query = this._prepareMessagesQuery(
            startAfterVal,
            endBeforeVal,
            pageSize
        );

        return getDocsFromServer(query);
    }

    subscribeMessages(
        pageSize: number,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ): Unsubscribe {
        const query = this._prepareMessagesQuery(null, null, pageSize);

        return onSnapshot(query, onNext);
    }

    private _prepareMessagesQuery(
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number
    ) {
        let query = this.getQuery(
            this.collectionRef,
            orderBy('createdDate', 'desc')
        );

        if (startAfterVal) {
            query = this.getQuery(query, startAfter(startAfterVal));
        }

        if (endBeforeVal) {
            query = this.getQuery(query, endBefore(endBeforeVal));
        }

        if (pageSize) {
            query = this.getQuery(query, limit(pageSize));
        }

        return query;
    }
}
