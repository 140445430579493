<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import audioStore from '@/store/audio';
import WaveSurfer from 'wavesurfer.js';
import { generateRandomString } from '@/ui/helpers/utils';

const props = withDefaults(
  defineProps<{
    path: string;
    type: string;
    size: string;
    readonly: boolean;
    isHiddenClose?: boolean;
  }>(),
  {
    type: 'default',
    size: 'normal',
    readonly: true,
  }
);

defineEmits(['remove']);

const _audioStore = audioStore();

const waveForm = ref(null as any);
let waveSurfer;

const audioPlayerId = generateRandomString(12);

// Pause audio if another is playing
watch(
  () => _audioStore.playingAudioId,
  (playingAudioId) => {
    if (!waveSurfer || !isPlay.value) return;

    if (playingAudioId && playingAudioId !== audioPlayerId) {
      waveSurfer.pause();

      isPlay.value = waveSurfer.isPlaying();
    }
  }
);

const playCall = () => {
  if (!waveSurfer) return;

  waveSurfer.playPause();

  isPlay.value = waveSurfer.isPlaying();

  if (isPlay.value) _audioStore.setPlayingAudioId(audioPlayerId);
};

const muteVolume = () => {
  waveSurfer.toggleMute();
  isMute.value = !isMute.value;
};

const isPlay = ref(false);
const isMute = ref(false);
const duration = ref(null as any);
const current = ref(null as any);

const classBySize = computed(() => {
  if (props.size == 'large') return 'w-48 h-32';
  if (props.size == 'huge') return 'w-96 h-64';
  if (props.size == 'custom') return 'w-16 h-full';
  if (props.size == 'auto') return 'w-full h-full';
  return 'w-20 h-16';
});

const timeCalculator = (value) => {
  let second: any = Math.floor(value % 60);
  const minute = Math.floor((value / 60) % 60);
  if (second < 10) {
    second = '0' + second;
  }
  return minute + ':' + second;
};

onMounted(() => {
  waveSurfer = WaveSurfer.create({
    container: waveForm.value,
    barWidth: 1,
    responsive: true,
    // hideScrollbar: true,
    // barRadius: 1,
    barHeight: 2,
    progressColor: '#008e8e',
    barGap: 3,
    height: 30,
    waveColor: '#ccc',
    cursorColor: '#008e8e',
  });

  waveSurfer.load(props.path);

  waveSurfer.on('ready', () => {
    duration.value.textContent = timeCalculator(waveSurfer.getDuration());
  });

  waveSurfer.on('audioprocess', () => {
    current.value.textContent = timeCalculator(waveSurfer.getCurrentTime());
  });

  waveSurfer.on('finish', () => {
    isPlay.value = false;
  });
});

onUnmounted(() => {
  if (waveSurfer) {
    waveSurfer.destroy();
  }
});
</script>

<template>
  <div class="syn-upload-preview relative">
    <template v-if="type === 'default'">
      <div class="flex-center p-4 border border-gray-300 bg-white rounded-xl">
        <button
          class="flex-center mr-3"
          :title="!isPlay ? $t('PLAY') : $t('PAUSE')"
          @click.stop="playCall"
        >
          <SynIcon
            :custom-class="'fill-current'"
            class="h-4 w-4"
            :name="!isPlay ? 'play' : 'pause'"
          ></SynIcon>
        </button>
        <div ref="waveForm" class="w-48 relative">
          <div class="absolute" style="left: 0; bottom: -18px">
            <span ref="current" class="text-xs text-gray-400">0:00</span>
          </div>
          <div class="absolute" style="right: 0; bottom: -18px">
            <span ref="duration" class="text-xs text-gray-400">0:00</span>
          </div>
        </div>
        <button
          class="flex-center ml-3"
          :title="!isMute ? $t('MUTE') : $t('UNMUTE')"
          @click.stop="muteVolume"
        >
          <SynIcon
            class="h-5 w-5"
            :custom-class="'fill-current'"
            :name="!isMute ? 'volume' : 'muteVolume'"
          ></SynIcon>
        </button>
      </div>
    </template>

    <template v-if="type === 'view'">
      <div
        class="
          hover-to-show__parent
          relative
          flex-center flex-col
          p-2
          border border-gray-200
          rounded-md
        "
        :class="classBySize"
      >
        <div class="w-full">
          <div ref="waveForm" class="w-full relative">
            <div class="absolute" style="left: 0; bottom: -18px">
              <span ref="current" class="text-2xs text-gray-400">0:00</span>
            </div>
            <div class="absolute" style="right: 0; bottom: -18px">
              <span ref="duration" class="text-2xs text-gray-400">0:00</span>
            </div>
          </div>
        </div>

        <div
          class="
            hover-to-show__children
            absolute
            bottom-2
            flex-center
            space-x-1
          "
        >
          <button
            class="flex-center bg-current-500 rounded-full p-1.5"
            :title="!isPlay ? $t('PLAY') : $t('PAUSE')"
            @click.stop="playCall"
          >
            <SynIcon
              :custom-class="'fill-white'"
              class="h-3.5 w-3.5"
              :name="!isPlay ? 'play' : 'pause'"
            ></SynIcon>
          </button>
          <button
            class="flex-center bg-current-500 rounded-full p-1.5"
            :title="!isMute ? $t('MUTE') : $t('UNMUTE')"
            @click.stop="muteVolume"
          >
            <SynIcon
              class="h-3.5 w-3.5"
              :custom-class="'fill-white'"
              :name="!isMute ? 'volume' : 'muteVolume'"
            ></SynIcon>
          </button>
        </div>
      </div>
    </template>

    <div
      class="
        absolute
        -right-2
        -top-2
        w-6
        h-6
        cursor-pointer
        rounded-full
        bg-gray-100
        shadow
        flex-center
        z-10
      "
    >
      <SynIcon
        name="microphone"
        is-active
        custom-class="w-3 h-3 fill-blue-500"
      />
    </div>
    <div
      v-if="!readonly && !isHiddenClose"
      class="
        show-when-hover-button
        absolute
        -right-2
        -top-2
        w-6
        h-6
        cursor-pointer
        rounded-full
        bg-red-100
        flex-center
        z-10
      "
      @click.stop="$emit('remove')"
    >
      <SynIcon
        name="close"
        custom-class="w-3 h-3"
        class="remove-button text-red-500"
      />
    </div>
  </div>
</template>

<style scoped>
.syn-upload-preview .remove-button {
  display: none;
}

.syn-upload-preview .show-when-hover-button {
  display: none;
}

.syn-upload-preview:hover .remove-button {
  display: flex;
}

.syn-upload-preview:hover .show-when-hover-button {
  display: flex;
}

.syn-upload-preview:hover .type-button {
  display: none;
}
</style>
