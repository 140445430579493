<script setup lang="ts">
import { NotificationCategory } from '@/domain/enums/notification-enums';

defineProps<{
  selectedCategory: NotificationCategory;
  unreadByCategory: any;
}>();

defineEmits(['update:selectedCategory']);

const notificationCategories = [
  { key: NotificationCategory.ALL, label: 'NOTIFICATION_LABEL_TYPE_ALL' },
  { key: NotificationCategory.NEW, label: 'NOTIFICATION_LABEL_TYPE_NEW' },
  {
    key: NotificationCategory.UPDATED,
    label: 'NOTIFICATION_LABEL_TYPE_UPDATED',
  },
  {
    key: NotificationCategory.FINISHED,
    label: 'NOTIFICATION_LABEL_TYPE_FINISHED',
  },
  { key: NotificationCategory.OTHER, label: 'NOTIFICATION_LABEL_TYPE_OTHER' },
];
</script>

<template>
  <div class="flex item-center space-x-1">
    <div
      v-for="category in notificationCategories"
      :key="category?.key"
      role="button"
      class="
        flex-center
        space-x-2
        pt-0.5
        pb-2
        px-1.5
        text-xs
        hover:text-current-500 hover:font-medium
      "
      :title="$t(category?.label)"
      :class="
        selectedCategory === category?.key
          ? 'border-b-2 border-current text-current-500 font-medium'
          : 'rounded text-gray-500'
      "
      @click="$emit('update:selectedCategory', category?.key)"
    >
      <span class="text-xs text-overflow-hidden-line">
        {{ $t(category?.label) }}</span
      >
      <span
        v-if="unreadByCategory && unreadByCategory[category?.key]"
        class="px-1 text-2xs rounded"
        :class="
          selectedCategory === category?.key
            ? 'bg-current-500 text-white'
            : 'bg-gray-100 text-gray-500'
        "
        >{{ unreadByCategory[category?.key] }}</span
      >
    </div>
  </div>
</template>
