import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import AwsS3Class from '@/ui/plugins/awss3/AwsS3Class';
import {
    KindOfFile,
    ModuleSourceFile,
    ModuleSourceFileEnum,
} from '@/ui/plugins/awss3/AwsS3Type';
import { FilePayloadClass } from '@/domain/entities/PayloadClass/TaskPayloadClass';
import { awsS3Delete } from '@/ui/plugins/awss3/aws-s3-services';
import { IUploadPreview } from '@/ui/components/file-viewer/update-preview-type';
export default class AttachmentClass extends FilePayloadClass {
    localId: string;
    name: string;
    contentType: string;
    fileUrl: string;
    objectUrl: string;
    base64: string;
    fileBlob: any;
    creationTime: any;
    editStatus: 'ORIGINAL' | 'ADDED' | 'REMOVED';
    constructor(sourceFile: ModuleSourceFile, file: File | Blob | any) {
        super({
            bucket: file?.bucket,
            documentType: file?.documentType,
            extension: file?.extension,
            fileName: file?.name || file?.fileName,
            module: sourceFile,
            path: file?.path,
            size: file?.size,
            type: file?.type,
            creationTime: file?.creationTime,
        });
        this.localId = file.localId || uuidv4();
        this.name = file.name;
        this.contentType = file.type || file.contentType;
        this.fileUrl = file?.fileUrl || file?.src;
        this.objectUrl = file?.objectUrl;
        this.base64 = file?.base64;
        this.fileBlob = _getBlobFile(file);
        this.creationTime = file?.creationTime || new Date();
        this.editStatus = file?.editStatus || 'ORIGINAL';
    }

    async upload(): Promise<any> {
        try {
            const fileName = this.name;
            const uploadRes = await new AwsS3Class(this.module).upload(
                this.fileBlob,
                this.contentType,
                this.documentType,
                this.name,
                this.extension
            );
            console.log('🚀 Tictop ~ uploadRes:', uploadRes);

            return Promise.resolve(
                new FilePayloadClass({
                    path: uploadRes.path,
                    fileUrl: uploadRes?.Location,
                    localId: this.localId,
                    bucket: uploadRes.Bucket,
                    documentType: this.documentType.toUpperCase(),
                    extension: this.extension,
                    fileName: fileName,
                    module: this.module,
                    size: this.size,
                    type: this.contentType,
                    creationTime: this.creationTime,
                })
            );
        } catch (error) {
            return Promise.resolve({
                localId: this.localId,
                isUploadedFail: true,
                error,
            });
        }
    }
    async clone(): Promise<any> {
        try {
            const fileName = this.name;

            const documentType = this.documentType?.toUpperCase() as KindOfFile;
            const uploadRes = await new AwsS3Class(this.module).clone(
                this.path,
                documentType,
                this.extension
            );

            return Promise.resolve(
                new FilePayloadClass({
                    localId: this.localId,
                    bucket: uploadRes.Bucket,
                    documentType: this.documentType.toUpperCase(),
                    extension: this.extension,
                    fileName: fileName,
                    module: this.module,
                    path: uploadRes.Key,
                    size: this.size,
                    type: this.contentType,
                    creationTime: this.creationTime,
                    fileUrl: this.fileUrl?.replace(this.path, uploadRes.Key),
                })
            );
        } catch (error) {
            Promise.reject(error);
        }
    }

    remove() {
        if (!this.path || !this.documentType) return Promise.resolve(true);
        return new AwsS3Class(this.module).remove(
            this.path,
            this.documentType?.toLowerCase()
        );
    }
}

const _getBlobFile = (file) => {
    if (file instanceof Blob) return file;
    if (file?.fileBlob instanceof Blob) return file?.fileBlob;
    if (file?.blob instanceof Blob) return file?.blob;
    if (file?.base64)
        return Buffer.from(
            file?.base64?.replace(/^data:image\/\w+;base64,/, ''),
            'base64'
        );
    return null;
};

export interface IAttachmentFileDetail extends IUploadPreview {
    localId?: string;
    blob: File | Blob;
    size: number;
    editStatus: 'ORIGINAL' | 'ADDED' | 'REMOVED';
    originalData?: any;
    contentType?: string;

    bucket?: string;
    documentType?: KindOfFile;
    extension?: string;
    fileName?: string;
    module?: ModuleSourceFile;
    path?: string;
    objectUrl?: string;
    base64?: string;
    fileBlob?: any;
}

export const uploadFilesToAws = async (
    moduleSource: ModuleSourceFileEnum,
    files: IAttachmentFileDetail[],
    options: { isClone: boolean }
) => {
    // upload files
    const promiseAll: Promise<any>[] = [];

    // 1. Remain files
    const keepRemainFiles =
        files
            ?.filter(
                (file) => !file?.editStatus || file?.editStatus == 'ORIGINAL'
            )
            ?.map((o) => o?.originalData || o) || [];
    if (options?.isClone && keepRemainFiles && keepRemainFiles?.length > 0) {
        Array.from(keepRemainFiles).forEach((file) => {
            promiseAll.push(new AttachmentClass(moduleSource, file).clone());
        });
    }

    // 2. add files
    const addFiles = files?.filter((file) => file?.editStatus == 'ADDED');
    if (addFiles && addFiles?.length > 0)
        Array.from(addFiles).forEach((file) => {
            promiseAll.push(new AttachmentClass(moduleSource, file).upload());
        });

    // 3. remove files
    const removeFiles = files?.filter((file) => file?.editStatus == 'REMOVED');
    if (removeFiles && removeFiles?.length > 0)
        Array.from(removeFiles).forEach((file) => {
            promiseAll.push(new AttachmentClass(moduleSource, file).remove());
        });
    const newUploadedFiles = await Promise.all(promiseAll);

    if (options?.isClone) return [...newUploadedFiles];
    else return [...keepRemainFiles, ...newUploadedFiles];
};

export const removeAwsFiles = async (files: FilePayloadClass[]) => {
    return Promise.all(
        Array.from(files).map((file) => {
            return awsS3Delete({
                Bucket: file.bucket,
                Key: file.path,
            });
        })
    );
};
