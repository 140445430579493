import { computed } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import { TaskLife } from '@/domain/enums/taskEnum';
import { getBgClass, getConfigScheduleColor } from '@/ui/hooks/taskHook';
import taskStore from '@/store/task';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { TaskApprovalStatus } from '@/application/types/task/task.types';

dayjs.extend(utc);
dayjs.extend(tz);

export default function bindingInterfaceTaskComposables(currentTask) {
    const _taskStore = taskStore();

    const myTimezone = computed(() => myProfileStore().getTimezone);
    const isGroupMember = computed(() => currentTask?.isGroupMember);
    const isGroupTask = computed(
        () => currentTask?.groupId && currentTask?.groupId > 0
    );
    const myProfile = computed(() => myProfileStore().myProfile);

    const hasPermission = computed<boolean>(() => {
        if (
            currentTask?.creatorId == myProfile.value?.id ||
            currentTask?.assigneeId == myProfile.value?.id
        ) {
            return true;
        }

        return isGroupTask.value ? isGroupMember.value : true;
    });

    const isEditable = computed<boolean>(() => {
        if (
            currentTask?.workFlowTaskId &&
            currentTask?.taskLife == TaskLife.Finished
        )
            return false;

        if (
            currentTask?.approvalEnabled &&
            (currentTask?.approvalStatus == TaskApprovalStatus.WAITING ||
                currentTask?.approvalStatus == TaskApprovalStatus.APPROVED)
        )
            return false;

        if (currentTask?.isCreateListAssignees)
            return (
                currentTask?.creatorId == myProfile.value?.id ||
                currentTask?.assigneeId == myProfile.value?.id
            );

        return hasPermission.value;
    });

    const timezone = computed(() => {
        if (myTimezone.value == '+7') return 'Asia/Bangkok';
        if (myTimezone.value == '-5') return 'America/New_York';
        else return 'Europe/Paris';
    });

    const isTaskHasJustCreate = (task) => {
        const createTime = new Date(task?.creationTime).getTime();
        const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;
        const overTime = Date.now() + timeOffset - createTime; // milliseconds
        return overTime < 180 * 60 * 1000;
    };

    const getConfigName = (sitKey, typeKey) => {
        return _taskStore.getConfigName(sitKey, typeKey);
    };

    const getConfigScheduleName = (data) => {
        return _taskStore.getConfigScheduleName(data);
    };

    const classTaskSchedulePlanType = computed(() =>
        currentTask?.taskLife === TaskLife.Finished
            ? getBgClass(currentTask?.taskLife, 'taskLife')
            : getBgClass(currentTask?.taskSchedule?.planType, 'planType')
    );

    const textTaskSchedulePlanType = computed(() =>
        currentTask?.taskLife === TaskLife.Finished
            ? getConfigName(currentTask?.taskLife, 'taskLife')
            : getConfigName(currentTask?.taskSchedule?.planType, 'planType')
    );

    const classTaskScheduleDeadline = computed(() =>
        getConfigScheduleColor(currentTask?.taskSchedule)
    );

    const textTaskScheduleDeadline = computed(() =>
        currentTask.assigneeId && getConfigScheduleName(currentTask)
            ? getConfigScheduleName(currentTask?.taskSchedule)
            : dayjs(currentTask.scheduleTime)
                  .tz(timezone.value)
                  .format('MMM DD, YYYY')
    );

    return {
        isTaskHasJustCreate,
        hasPermission,
        isEditable,
        getConfigScheduleName,
        classTaskSchedulePlanType,
        textTaskSchedulePlanType,
        textTaskScheduleDeadline,
        classTaskScheduleDeadline,
    };
}
