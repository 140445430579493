import { permissionByRoleName } from '@/ui/hooks/permission/permission-constant';
import { EFunctionPermission } from '@/application/types/block-function.types';
import myProfileStore from '@/store/auth/my-profile';

export const checkPermissionByAdminType = (functionName: string) => {
    const functionPermission = permissionByRoleName[functionName];

    if (!functionPermission) return EFunctionPermission.ALLOW;

    const myAdminType = myProfileStore().myProfile?.adminType;

    return functionPermission[myAdminType]
        ? EFunctionPermission.ALLOW
        : EFunctionPermission.HIDDEN;
};
