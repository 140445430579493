<script setup lang="ts">
import { onMounted, ref } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import permissionStore from '@/store/permission';

const isInitializing = ref<boolean>(true);

onMounted(async () => {
  isInitializing.value = true;

  try {
    await Promise.all([
      myProfileStore().fetchCurrentLoginUser(),
      permissionStore().getPermissionsByUser(),
    ]);
  } catch (e) {
    console.log(e);
  }

  isInitializing.value = false;
});
</script>

<template>
  <SynLoading v-if="isInitializing" />

  <div v-else>
    <slot></slot>
  </div>
</template>
