<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g id="Group_3059" data-name="Group 3059" transform="translate(-596 -476)">
      <g
        id="Group_3057"
        data-name="Group 3057"
        transform="translate(595.5 475.999)"
      >
        <path
          id="Path_1015"
          data-name="Path 1015"
          d="M18.709,336.341l-1.1-.548-5.289,2.645a5.143,5.143,0,0,1-2.254.488,5.141,5.141,0,0,1-2.254-.488l-5.289-2.645-1.1.548a1.316,1.316,0,0,0,0,2.516l6.91,3.455a3.967,3.967,0,0,0,1.73.364,3.969,3.969,0,0,0,1.73-.364l6.91-3.455a1.316,1.316,0,0,0,0-2.516Zm0,0"
          transform="translate(0 -322.675)"
        />
        <path
          id="Path_1016"
          data-name="Path 1016"
          d="M18.709,210.318l-1.1-.548-5.289,2.645a5.139,5.139,0,0,1-2.254.488,5.139,5.139,0,0,1-2.254-.488L2.526,209.77l-1.1.548a1.316,1.316,0,0,0,0,2.516l6.91,3.455a3.967,3.967,0,0,0,1.73.364,3.969,3.969,0,0,0,1.73-.364l6.91-3.455a1.316,1.316,0,0,0,0-2.516Zm0,0"
          transform="translate(0 -201.575)"
        />
        <path
          id="Path_1017"
          data-name="Path 1017"
          d="M1.429,6.336l6.91,3.455a3.952,3.952,0,0,0,1.73.365,3.952,3.952,0,0,0,1.73-.365l6.91-3.455a1.465,1.465,0,0,0,.93-1.258,1.465,1.465,0,0,0-.929-1.258L11.8.365A3.954,3.954,0,0,0,10.069,0a3.952,3.952,0,0,0-1.73.365L1.429,3.82A1.464,1.464,0,0,0,.5,5.078a1.464,1.464,0,0,0,.929,1.258Zm0,0"
        />
      </g>
      <g id="Group_3058" data-name="Group 3058">
        <circle
          id="Ellipse_195"
          data-name="Ellipse 195"
          cx="7.5"
          cy="7.5"
          r="7.5"
          transform="translate(606 486)"
          fill="#fff"
        />
        <path
          id="Icon_awesome-eye-slash"
          data-name="Icon awesome-eye-slash"
          d="M5.625,7.031a2.522,2.522,0,0,1-2.512-2.34L1.269,3.266a5.859,5.859,0,0,0-.645.977.569.569,0,0,0,0,.513,5.638,5.638,0,0,0,5,3.118,5.46,5.46,0,0,0,1.369-.184l-.912-.706a2.534,2.534,0,0,1-.457.046Zm5.516,1.021L9.2,6.55a5.823,5.823,0,0,0,1.428-1.794.569.569,0,0,0,0-.513,5.638,5.638,0,0,0-5-3.118,5.417,5.417,0,0,0-2.59.663L.8.059A.281.281,0,0,0,.4.108L.059.553A.281.281,0,0,0,.109.947L10.451,8.94a.281.281,0,0,0,.395-.049l.345-.444a.281.281,0,0,0-.05-.395Zm-3.229-2.5-.691-.534A1.666,1.666,0,0,0,5.18,2.879a.838.838,0,0,1,.164.5.82.82,0,0,1-.027.176l-1.294-1a2.5,2.5,0,0,1,1.6-.582A2.53,2.53,0,0,1,8.156,4.5a2.471,2.471,0,0,1-.244,1.057Z"
          transform="translate(608.25 489.5)"
        />
      </g>
    </g>
  </svg>
</template>
