import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import { ICustomTaskColumn } from '@/application/interfaces/tasks/task-interfaces';

export default defineStore({
    id: storeId.TASK_CUSTOM_CONFIG,
    state: () => ({
        _taskService: TaskService.getInstance(),
        taskColumnByCode: [] as ICustomTaskColumn[],
    }),
    getters: {},
    actions: {
        async getUserCustomColumnTask() {
            const res = await this._taskService.getUserCustomColumnTask();

            this.taskColumnByCode = res?.result?.reduce(
                (oldValue, currentValue) => {
                    return {
                        ...oldValue,
                        [currentValue?.code]: currentValue,
                    };
                },
                {}
            );

            return res?.result;
        },
        async updateTaskColumnByCode(code, data) {
            this.taskColumnByCode[code] = data;
        },
        async fetchUpdateTaskColumn() {
            this.updateUserCustomTaskColumn(
                Object.values(this.taskColumnByCode)
            );
        },
        addUserCustomTaskColumn(data: ICustomTaskColumn[]) {
            return this._taskService.addUserCustomTaskColumn(data);
        },
        updateUserCustomTaskColumn(data: ICustomTaskColumn[]) {
            return this._taskService.updateUserCustomTaskColumn(data);
        },
        resetUserCustomTaskColumn() {
            return this._taskService.resetUserCustomTaskColumn();
        },
    },
});
