<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g id="_44_Sub" xmlns="http://www.w3.org/2000/svg" data-name="44 Sub">
        <path
          d="m427.18 403.92 7.73-14.81a34.56 34.56 0 0 1 30.76-18.58l16.7.06c13.69.05 19.83-17.13 9.21-25.77l-80.33-65.38c-14.28-11.62-35.26 1.05-31.62 19.1l20.45 101.53c2.71 13.42 20.77 15.98 27.1 3.85z"
        ></path>
        <g>
          <path
            d="m455.45 316.23h-6.37a6 6 0 0 1 -3.78-1.35l-37.83-30.79a13.63 13.63 0 0 0 -22 13.26l2.4 11.65a6 6 0 0 1 -5.89 7.19h-325.44a51.61 51.61 0 0 1 -51.54-51.51v-25.57a6 6 0 0 1 12 0v25.57a39.6 39.6 0 0 0 39.54 39.55h318.11l-.91-4.51a25.63 25.63 0 0 1 41.26-24.94l36.18 29.45h4.23a39.6 39.6 0 0 0 39.59-39.55v-118.85a39.59 39.59 0 0 0 -39.55-39.55h-398.91a39.59 39.59 0 0 0 -39.54 39.55v46.48a6 6 0 0 1 -12 0v-46.48a51.61 51.61 0 0 1 51.54-51.55h398.91a51.6 51.6 0 0 1 51.55 51.55v118.85a51.61 51.61 0 0 1 -51.55 51.55z"
            class=""
          ></path>
          <path
            d="m11 226.21a6 6 0 0 1 -6-6v-9a6 6 0 0 1 12 0v9a6 6 0 0 1 -6 6z"
            class=""
          ></path>
          <path
            d="m414.42 417.73a21.69 21.69 0 0 1 -3-.21 20.3 20.3 0 0 1 -17.23-16.26l-20.44-101.54a25.62 25.62 0 0 1 41.25-24.93l80.33 65.38a20.51 20.51 0 0 1 -12.94 36.42h-.08l-16.7-.06h-.11a28.45 28.45 0 0 0 -25.31 15.35l-7.73 14.81a20.24 20.24 0 0 1 -18.04 11.04zm-15.42-136.73a14 14 0 0 0 -7.18 2 13.34 13.34 0 0 0 -6.31 14.35l20.49 101.54a8.51 8.51 0 0 0 15.89 2.25l7.73-14.81a40.43 40.43 0 0 1 36-21.8h.15l16.7.06a8.52 8.52 0 0 0 5.37-15.12l-80.38-65.37a13.15 13.15 0 0 0 -8.46-3.1z"
            class=""
          ></path>
          <path
            d="m383.2 196.41a26.89 26.89 0 0 0 9.52-20.54v-9.41a27 27 0 0 0 -27-27h-37.3a13.86 13.86 0 0 0 -13.85 13.85v103.85a13.86 13.86 0 0 0 13.88 13.84h36.18a33.87 33.87 0 0 0 33.84-33.83v-12.49a33.84 33.84 0 0 0 -15.27-28.27zm-56.6-43.07a1.85 1.85 0 0 1 1.85-1.85h37.3a15 15 0 0 1 15 15v9.41a15 15 0 0 1 -15 15h-39.15zm59.87 83.83a21.86 21.86 0 0 1 -21.84 21.83h-36.18a1.84 1.84 0 0 1 -1.85-1.84v-54.31h38a21.86 21.86 0 0 1 21.84 21.83z"
            class=""
          ></path>
          <path
            d="m150 199.39a29.92 29.92 0 0 0 -3.54-.24 23.93 23.93 0 1 1 20.38-40.22 6 6 0 1 0 8.69-8.27 35.93 35.93 0 1 0 -30.31 60.43 32.21 32.21 0 0 0 3.54.24 23.93 23.93 0 1 1 -24 35.33 6 6 0 0 0 -10.49 5.82 35.79 35.79 0 0 0 29.17 18.45c.77.05 1.53.07 2.29.07a35.93 35.93 0 0 0 4.27-71.61z"
            class=""
          ></path>
          <path
            d="m280.47 139.49a6 6 0 0 0 -6 6v87.31a26.21 26.21 0 0 1 -52.41.22c-.59-71.42-.09-83.88.12-86a6 6 0 0 0 -10.81-4.84c-1.28 1.93-1.58 2.39-1.59 34.5 0 18 .15 40.65.28 56.45a38.21 38.21 0 0 0 76.41-.32v-87.32a6 6 0 0 0 -6-6z"
            class=""
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
