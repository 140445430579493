<script setup lang="ts">
import UsingTrialCongratulationModal from '@/ui/modules/settings/payments/package-plan/UsingTrialCongratulationModal.vue';
import { IPaymentConfig } from '@/application/types/payment-configs.types';
import { computed, ref, onMounted, onUnmounted } from 'vue';
import paymentStore from '@/store/payment';
import PlanAndPricingOverview from '@/ui/modules/plan/PlanAndPricingOverview.vue';
import DecidePaymentSimpleModal from '@/ui/modules/payments/DecidePaymentSimpleModal.vue';
import TictopWebSocket, {
  USER_REG_EVENT,
} from '@/ui/plugins/websocket/ws-instance';
import NotificationService from '@/application/services/NotificationService';
import PaymentResult from '@/ui/modules/payments/PaymentResult.vue';
import {
  ask,
  settingGlobalModal,
  tell,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import CatalogService from '@/application/services/CatalogService';

const emit = defineEmits(['onSelectedTrial']);

const _notifService = NotificationService.getInstance();

let ws: TictopWebSocket;
const _paymentStore = paymentStore();
const paymentConfigs = computed<IPaymentConfig>(
  () => _paymentStore.paymentConfigs
);

const selectedPlanId = ref<any>(null);
const isShowTrialCongratulation = ref<boolean>(false);

const handleSelectTrialSuccess = (planId) => {
  isShowTrialCongratulation.value = true;
  ws.send(USER_REG_EVENT.SELECT_PAYMENT_PLAN_TRIAL, null, { planId });
  selectedPlanId.value = planId;
  emit('onSelectedTrial');
};
const handleSelectPay = () => {
  ws.send(USER_REG_EVENT.SELECT_PAYMENT_PLAN_PAY_NOW);
  isOpenChoosePlanModal.value = true;
};

const sendChatSupportForOrganization = () => {
  _notifService.sendChatSupportForOrganization();
};
const onCloseTrialCongratulationModal = () => {
  sendChatSupportForOrganization();

  setTimeout(() => {
    window.location.reload();
  }, 500);
};

onMounted(async () => {
  ws = new TictopWebSocket(USER_REG_EVENT.PAGE_SELECT_PAYMENT_PLAN_INIT);
});

onUnmounted(async () => {
  ws?.close();
});

const onBackToSelectPayment = () => {
  ws.send(USER_REG_EVENT.SELECT_PAYMENT_PLAN_BACK_FROM_PAY_NOW);
  isOpenChoosePlanModal.value = false;
};

const isOpenChoosePlanModal = ref<boolean>(false);
const isDowngradingToFreeSuccess = ref<boolean>(false);

const handleUsingTrial = async () => {
  try {
    settingGlobalModal({
      type: 'confirm',
      isHiddenIcon: true,
      title: translate('PAYMENT_LABEL_USE_TRIAL'),
      content: translate('PAYMENT_LABEL_CONFIRM_USE_TRIAL_CONTENT'),
      footerButtons: [
        {
          type: 'TEXT',
          labelCode: 'COMMON_LABEL_BACK',
          color: 'gray',
          onClick: () => {
            tell(false);
          },
        },

        {
          type: 'FILL',
          labelCode: translate('PAYMENT_LABEL_START_X_DAY_TRIAL', {
            totalDays: paymentConfigs.value?.paymentBonus?.defaultTrial || 7,
          }),
          color: 'current',
          onClick: async () => {
            await CatalogService.getInstance().usingTrialPackage('PRO');
            handleSelectTrialSuccess();
            tell(true);
          },
        },
      ],
    });
    return ask();
  } catch (error) {
    console.log('🚀 Hyrin ~ handleUsingTrial ~ error:', error);
  }
};
</script>
<template>
  <UsingTrialCongratulationModal
    v-if="isShowTrialCongratulation"
    :current-plan-id="selectedPlanId"
    :remain-days="paymentConfigs?.paymentBonus?.defaultTrial"
    @cancel="onCloseTrialCongratulationModal"
  />
  <PaymentResult
    v-else-if="isDowngradingToFreeSuccess"
    :action-name="'actionName'"
    :current-plan-id="'FREE'"
    :previous-plan-id="'FREE'"
    :total-users="5"
    need-to-reload-page
    @cancel="sendChatSupportForOrganization"
  />
  <teleport v-else-if="isOpenChoosePlanModal" to="body">
    <div
      class="
        absolute
        w-full
        h-full
        bg-current-800
        inset-0
        overflow-hidden
        flex-center
      "
      style="background: rgba(0, 0, 0, 0.7); z-index: 99"
    >
      <div
        class="flex flex-col bg-white rounded-xl py-4 px-12"
        style="min-width: 80rem; height: 95%; min-height: 40rem"
      >
        <PlanAndPricingOverview
          has-back
          has-use-trial
          @on-back="onBackToSelectPayment"
          @use-free-success="isDowngradingToFreeSuccess = true"
          @use-trial="handleUsingTrial"
        />
      </div>
    </div>
  </teleport>
  <DecidePaymentSimpleModal
    v-else
    @handle-select-trial="handleSelectTrialSuccess"
    @handle-select-pay="handleSelectPay"
  />
</template>
