<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 20 20"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g>
        <path
          d="m10 16c3.9 0 7-3.1 7-7v-9h-2v9c0 2.8-2.2 5-5 5s-5-2.2-5-5v-9h-2v9c0 3.9 3.1 7 7 7z"
          class=""
        ></path>
        <path d="m1 18h18v2h-18z" class=""></path>
      </g>
    </g>
  </svg>
</template>
