import { AxiosInstance } from 'axios';
import BaseRequest from '@/base/api/base-request';

export default class HomeRepository {
    private static instance: HomeRepository;
    request: AxiosInstance;
    constructor() {
        this.request = BaseRequest;
    }

    public static getInstance(): HomeRepository {
        if (!HomeRepository.instance) {
            // Get from local storage
            HomeRepository.instance = new HomeRepository();
        }

        return HomeRepository.instance;
    }

    getWorkingStatus(departmentId?: number) {
        return this.request({
            url: `Home/GetWorkingStatus`,
            method: 'get',
            params: {
                dptId: departmentId || '',
            },
        });
    }
    calculateScorePerformanceOnDay(departmentId?: number) {
        return this.request({
            url: `Home/CalculateScorePerformanceOnDay`,
            method: 'get',
            params: {
                dptId: departmentId || '',
            },
        });
    }
    getTaskActivityChart(departmentId?: number) {
        return this.request({
            url: `Home/GetTaskActivityChart`,
            method: 'get',
            params: {
                dptId: departmentId || '',
            },
        });
    }
    getDayoffStatusByDate(date: string, departmentId?: number) {
        return this.request({
            url: `Home/GetDayoffStatusByDate`,
            method: 'get',
            params: {
                date,
                dptId: departmentId || '',
            },
        });
    }
    getDepartmentsOverview(departmentId?: number) {
        return this.request({
            url: `Home/DepartmentsOverview`,
            method: 'get',
            params: {
                dptId: departmentId || '',
            },
        });
    }
    getDepartmentsOverviewById(departmentId: number) {
        return this.request({
            url: `Department/DepartmentOverviewById`,
            method: 'get',
            params: {
                dptId: departmentId || 0,
            },
        });
    }
}
