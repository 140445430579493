<template>
  <div class="w-full py-4 space-y-2 flex flex-wrap justify-center">
    <syn-animation
      :name="name"
      :speed="2"
      :style="`width: ${width}px; height: ${height}px;`"
    />
    <div class="w-full text-center font-medium text-current-600 text-lg">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Not Found',
    },
    name: {
      type: String,
      default: 'searchNotFound',
    },
    width: {
      type: String,
      default: '200',
    },
    height: {
      type: String,
      default: '200',
    },
  },

  setup() {
    // const imageNotFound = require(`@/ui/assets/images/no_result_found.png`);

    return {
      // imageNotFound,
    };
  },
};
</script>
