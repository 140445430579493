import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import SecurityService from '@/application/services/SecurityService';

export default defineStore({
    id: storeId.security,
    state: () => ({}),
    getters: {},
    actions: {
        async fetchBlockStatus() {
            const status =
                await SecurityService.getInstance().getBlockingStatus();
            return status;
        },
    },
});
