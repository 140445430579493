<script setup lang="ts">
import { computed } from 'vue';
import $constants from '@/ui/plugins/constants';
import { checkIsFileObject } from '@/ui/hooks/commonFunction';
import { copyImageToClipBroard } from '@/ui/components/file-viewer/copyToClipboard';
import { getObjectDataByUrl } from '@/ui/plugins/awss3/aws-s3-services';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import FilesSendToChat from '@/ui/modules/ged/file-actions/files-send-to-chat/FilesSendToChat.vue';
import FilesSendToTask from '@/ui/modules/ged/file-actions/files-send-to-task/FilesSendToTask.vue';
import FilesDownload from '@/ui/modules/ged/file-actions/files-download/FilesDownload.vue';

const props = withDefaults(
  defineProps<{
    file?: any;
    name?: string;
    path?: string;
    contentType?: string;
    hasDownload?: boolean;
    hasTaskActions?: boolean;
    hasImageActions?: boolean;
    isHiddenName?: boolean;
    editable?: boolean;
    removeable?: boolean;
  }>(),
  {
    hasDownload: true,
    hasTaskActions: true,
    hasImageActions: true,
  }
);
defineEmits(['edit', 'remove', 'copyImage', 'close', 'saveToResource']);

const isImage = computed(() =>
  props.contentType?.startsWith($constants.FILE.TYPE.IMAGE)
);

const onCopyImage = async () => {
  try {
    let copied;
    const isFileObject = checkIsFileObject(props.file);
    if (isFileObject) {
      copied = copyImageToClipBroard([props.file]);
    } else {
      const fileUrl =
        props.path ||
        props.file?.fileUrl ||
        props.file?.imagePath ||
        props.file?.url_full ||
        props.file?.pathUrl;

      if (!fileUrl) throw false;

      const imageDataFromS3 = await getObjectDataByUrl(fileUrl, 'arraybuffer');
      copied = copyImageToClipBroard(imageDataFromS3?.data);
    }
    if (copied) {
      openNotification({
        title: translate('COMMON_LABEL_COPIED'),
        body: 2000,
      });
    }
  } catch {
    openNotification({
      type: AlertType.warning,
      body: translate('COMMON_LABEL_CANNOT_COPY'),
      duration: 2000,
    });
  }
};
</script>

<template>
  <div class="flex-center space-x-2">
    <VigButton
      v-if="editable"
      outline
      rounded="rounded-full"
      padding="p-2"
      color="blue"
      @click="$emit('edit')"
    >
      <SynIcon name="Edit" is-active />
    </VigButton>

    <FilesDownload v-if="hasDownload" :files="[file]">
      <VigButton
        v-vig-tooltip="$t('COMMON_LABEL_DOWNLOAD')"
        outline
        rounded="rounded-full"
        padding="p-2"
        icon="Download"
      />
    </FilesDownload>

    <template v-if="hasTaskActions">
      <FilesSendToChat :files="[file]" @on-complete="$emit('close')">
        <VigButton
          v-vig-tooltip="$t('COMMON_LABEL_SEND_TO_CHAT')"
          outline
          rounded="rounded-full"
          padding="p-2"
          icon="Chat"
        />
      </FilesSendToChat>

      <FilesSendToTask :files="[file]" @on-complete="$emit('close')">
        <VigButton
          v-vig-tooltip="$t('COMMON_LABEL_SEND_TO_TASK')"
          outline
          rounded="rounded-full"
          padding="p-2"
          icon="Note"
        />
      </FilesSendToTask>
    </template>

    <vig-dropdown
      v-if="removeable || (isImage && hasImageActions)"
      :z-index="50"
    >
      <template #dropdown-toggle>
        <VigButton outline rounded="rounded-full" padding="p-2">
          <SynIcon name="dots-vertical" />
        </VigButton>
      </template>
      <template #dropdown-menu>
        <div
          class="py-1 z-100 list-ul rounded cursor-pointer dropdown-item"
          style="min-width: 11rem"
        >
          <div
            v-if="isImage"
            class="space-x-2 flex justify-start items-center text-sm list-li"
            @click="onCopyImage"
          >
            <SynIcon
              is-active
              custom-class="fill-blue-500 w-5 h-5"
              name="copy"
            />
            <span class="text-gray-600">
              {{ $t('COMMON_LABEL_COPY_IMAGE') }}
            </span>
          </div>
          <div
            v-if="file.category !== $constants.FILE.CATEGORY.STORAGE"
            class="space-x-2 flex justify-start items-center text-sm list-li"
            @click="$emit('saveToResource')"
          >
            <SynIcon
              is-active
              custom-class="fill-blue-500 w-5 h-5"
              name="Folder"
            />
            <span class="text-gray-600">
              {{ $t('COMMON_LABEL_SAVE_TO_RESOURCE') }}
            </span>
          </div>
          <div
            v-if="removeable"
            class="space-x-2 flex justify-start items-center text-sm list-li"
            @click="$emit('remove')"
          >
            <SynIcon
              is-active
              custom-class="fill-red-500 w-4 h-4"
              name="trash"
            />
            <span class="text-gray-600">
              {{ $t('COMMON_LABEL_DELETE') }}
              <span class="lowercase">
                {{
                  $t(
                    file?.isNote
                      ? 'TASK_CREATE_FORM_NOTE'
                      : 'TASK_CREATE_FORM_FILE'
                  )
                }}
              </span>
            </span>
          </div>
        </div>
      </template>
    </vig-dropdown>
  </div>
</template>
