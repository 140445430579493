<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import ChatService from '@/application/services/ChatService';

const props = defineProps<{
  conversationId: string;
  organizationId: number;
  members: any;
}>();

const TIME_TYPING = 30000;

const typingMembers = ref([] as any);

const myProfile = computed(() => myProfileStore().myProfile);

const typingMember = computed(() => {
  if (!typingMembers.value?.length || !props.members) return null;

  return props.members[typingMembers.value[0]];
});

let _unsubscribeTypingMembers;

watch(
  () => props.conversationId,
  (conversationId) => {
    _subscribeTypingMembers(conversationId);
  }
);

onMounted(() => {
  _subscribeTypingMembers(props.conversationId);
});

onUnmounted(() => {
  _unsubscribeTypingMembers && _unsubscribeTypingMembers();
});

const _subscribeTypingMembers = (conversationId) => {
  if (_unsubscribeTypingMembers) _unsubscribeTypingMembers();

  if (!conversationId) return;

  _unsubscribeTypingMembers = ChatService.subscribeTypingMembers(
    props.organizationId,
    conversationId,
    (snapshot) => {
      _changeTypingArray(
        snapshot?.docs?.map((doc) => ({
          userId: doc?.id,
          ...doc?.data(),
        }))
      );
    }
  );
};

const _changeTypingArray = (dataChanges) => {
  typingMembers.value = dataChanges
    ?.filter((data) => {
      const diffTime = Date.now() - data?.updatedDate?.seconds * 1000;
      return (
        data?.isTyping &&
        parseInt(data?.userId?.toString()) !== myProfile.value?.id &&
        diffTime <= TIME_TYPING - 5000
      );
    })
    .map((data) => data?.userId);

  if (!typingMembers.value || typingMembers.value?.length == 0) return;

  typingMembers.value.forEach((userId) => {
    _listenTypingTimeout(userId);
  });
};

const _listenTypingTimeout = (userId) => {
  setTimeout(() => {
    typingMembers.value = typingMembers.value.filter((el) => el !== userId);
  }, TIME_TYPING);
};
</script>

<template>
  <div
    v-if="typingMembers?.length > 0"
    class="
      px-2
      py-1
      w-max
      flex
      items-center
      bg-white bg-opacity-70
      rounded-full
    "
  >
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="text_typing">
      <div class="flex items-center space-x-1">
        <syn-avatar
          :src="typingMember?.avatar?.replace('original', 'mini')"
          :name="typingMember?.name"
          custom-class="w-4 h-4"
        />
        <span class="text-gray-500" style="font-size: 9px">{{
          typingMembers.length > 1
            ? $t('MESSAGE_TEXT_MANY_MEMBER_ARE_TYPING', {
                name1: typingMember?.firstName || typingMember?.name,
                count: typingMembers.length - 1,
              })
            : $t('MESSAGE_TEXT_IS_TYPING', {
                name: typingMember?.firstName || typingMember?.name,
              })
        }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dot {
  @apply h-1 w-1 mr-1 rounded-full bg-gray-300 inline-block;
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
}

.text_typing {
  @apply h-full w-max mr-1 rounded-full inline-block;
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  animation-delay: 500ms;
}

.dot:nth-child(1) {
  animation-delay: 200ms;
}

.dot:nth-child(2) {
  animation-delay: 300ms;
}

.dot:nth-child(3) {
  animation-delay: 400ms;
}

.dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0);
  }
  28% {
    transform: translateY(-0.25rem);
  }
  44% {
    transform: translateY(0);
  }
}
</style>
