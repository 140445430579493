<script setup lang="ts">
// import ScopeTree from '@/ui/components/scope/ScopeTree.vue';
import { computed, ref } from 'vue';
import groupStore from '@/store/group';
import { stringNomalize } from '@/ui/hooks/commonFunction';
import { translate } from '@/ui/plugins/i18n/myi18n';
import AlertModal from '@/ui/components/modals/AlertModal.vue';
import BreakLinkDepartmentGroupModal from '@/ui/modules/group/detail/BreakLinkDepartmentGroupModal.vue';
import myProfileStore from '@/store/auth/my-profile';
import domainStore from '@/store/scope';
import { IDomainDetail } from '@/domain/entities/domains/DomainDetailClass';

const props = defineProps<{
  groupId: number;
  isSelectScopeOnly?: boolean;
}>();
const emit = defineEmits([
  'saveScope',
  'systemGroupAlert',
  'onSelectedScopesChange',
]);
const _groupStore = groupStore();
const _domainStore = domainStore();
const allGroupByIds = computed<any>(() => {
  return _groupStore.allGroupByIds;
});
const groupDetail = computed<any>(() => {
  if (
    !allGroupByIds.value ||
    !Object.prototype.hasOwnProperty.call(allGroupByIds.value, props.groupId)
  )
    return {};
  return allGroupByIds.value[props.groupId];
});

const domainByIds = computed(() => _domainStore.domainByIds);
const groupDomains = computed<any>(() => {
  return groupDetail.value?.groupDomains?.map((d) => {
    if (
      !domainByIds.value ||
      !Object.prototype.hasOwnProperty.call(domainByIds.value, d?.domainId)
    )
      return d;
    return domainByIds.value[d?.domainId];
  });
});
const isCheckChange = ref(false);
const canEditGroupInfo = computed(() => {
  return (
    (!groupDetail.value?.isPrivate &&
      (myProfileStore().isAdmin || myProfileStore().isOwner)) ||
    groupDetail.value?.isAdminGroup
  );
});
const isShowPopupNotRemove = ref<boolean>(false);
const messageError = ref('');

const init = async () => {
  if (props.isSelectScopeOnly) onClickEdit();

  prepareData();

  emit('onSelectedScopesChange', domainPayloadCheckedList.value);
};

const isOpenAddDomain = ref(false);
const isEdit = ref(false);
const onCancelScope = async () => {
  await init();
  isEdit.value = false;
  isCheckChange.value = false;
  isOpenAddDomain.value = false;
};

const isSaveScope = ref(false);
const onSaveScope = async () => {
  isCheckChange.value = false;
  isSaveScope.value = true;
  var listDomainId = domainPayloadCheckedList.value.map((el) => el.id);
  const res: any = await _groupStore.createAndUpdate({
    id: groupDetail.value?.id,
    name: groupDetail.value?.name,
    description: groupDetail.value?.description,
    isPrivate: groupDetail.value?.isPrivate,
    email: groupDetail.value?.email,
    avatar: groupDetail.value?.avatarPath,
    type: groupDetail.value?.type,
    // userIds: groupDetail.value?.groupUsers.map(el => el.userId),
    memberInfos: groupDetail.value?.groupUsers,
    domainIds: listDomainId,
  });
  console.log('🚀 ~ file: FormAddScope.vue ~ line 59 ~ onSaveScope ~ res', res);

  if (res?.error) {
    isSaveScope.value = false;
    isEdit.value = true;
    isOpenAddDomain.value = true;
    isShowPopupNotRemove.value = true;
    messageError.value = res?.error?.message;
  } else {
    isSaveScope.value = false;
    isEdit.value = false;
    isOpenAddDomain.value = false;
    emit('saveScope', true);
  }
};

const showAlertSystemGroup = ref(false);
const isShowAlertCannotEditDepartmentGroup = ref(false);
const isSystemGroup = computed<boolean>(() => {
  return (
    groupDetail.value?.code == 'GENERAL_GROUP' ||
    groupDetail.value?.code == 'DELETED_GROUP'
  );
});
const onCheckPermissionBeforeUpdate = (): boolean => {
  if (isSystemGroup.value) {
    showAlertSystemGroup.value = true;
    return false;
  }
  if (groupDetail.value?.code?.includes('DEPARTMENT')) {
    isShowAlertCannotEditDepartmentGroup.value = true;
    return false;
  }

  return true;
};

const domainPayloadList = ref<IDomainDetail[]>([]);
const domainPayloadCheckedList = computed<IDomainDetail[]>(() => {
  if (!domainPayloadList.value || domainPayloadList.value?.length == 0)
    return [];
  return domainPayloadList.value.filter((domain) => {
    return domain?.isChecked;
  });
});
const domainPayloadFilteredList = computed<IDomainDetail[]>(() => {
  if (!domainPayloadList.value || domainPayloadList.value?.length == 0)
    return [];

  const uncheckedList = domainPayloadList.value?.filter((d) => !d?.isChecked);
  if (!keySearch.value) return uncheckedList;

  return uncheckedList.filter((o) => {
    return stringNomalize(o?.name?.toLowerCase()).includes(
      stringNomalize(keySearch.value?.toLowerCase())
    );
  });
});

const prepareData = () => {
  domainPayloadList.value = Object.values(domainByIds.value).map(
    (domain: any) => {
      const isChecked = groupDomains.value?.some((d) => d?.id == domain?.id);
      return {
        ...domain,
        isChecked,
      };
    }
  );
};
const onClickEdit = () => {
  if (!onCheckPermissionBeforeUpdate()) return;

  isOpenAddDomain.value = true;
};

const onToggleSelectDomain = (domain) => {
  const id = domain?.id;
  const isChecking = domain?.isChecked;
  let index = domainPayloadList.value?.findIndex((el) => el.id == id);
  if (index > -1) domainPayloadList.value[index].isChecked = !isChecking;

  isCheckChange.value = true;

  emit('onSelectedScopesChange', domainPayloadCheckedList.value);
};
const removeDomain = (domain) => {
  const id = domain?.id;
  isEdit.value = true;
  let index = domainPayloadList.value?.findIndex((el) => el.id == id);
  if (index > -1) domainPayloadList.value[index].isChecked = false;

  isCheckChange.value = true;

  emit('onSelectedScopesChange', domainPayloadCheckedList.value);
};

const keySearch = ref('');

const onShowError = () => {
  isShowPopupNotRemove.value = true;
  messageError.value = translate(
    'TASK_CREATE_GROUP_FORM_LABEL_REQUIRED_DOMAINS'
  );
};
init();
</script>
<template>
  <div class="px-4 font-medium grid grid-cols-2 gap-3">
    <div class="flex text-sm flex-wrap">
      <div v-if="!isSelectScopeOnly" class="text-current-600 font-medium">
        {{ $t('COMMON_LABEL_ASSIGN_GROUP')
        }}<span>{{ ' (' + groupDomains?.length + ')' }}</span>
      </div>
      <span
        v-if="groupDetail?.type === 'DOMAIN'"
        class="w-full text-gray-400 italic"
        >{{ '( ' + $t('GROUP_LABEL_SELECT_ONE_DOMAIN') + ' )' }}</span
      >
      <div v-if="isOpenAddDomain" class="w-full py-2">
        <VigSearchBox v-model="keySearch" autofocus />
      </div>
    </div>
    <div
      v-if="
        groupDetail?.type !== 'DOMAIN' && groupDetail?.code !== 'DELETED_GROUP'
      "
    >
      <div
        v-if="!isEdit && !isOpenAddDomain && canEditGroupInfo"
        v-permission="'FunctionGroupDomainModify'"
        class="
          flex
          justify-end
          items-center
          space-x-1
          font-medium
          text-current-700
          cursor-pointer
        "
        @click="onClickEdit"
      >
        <SynIcon
          has-action
          name="edit"
          is-active
          class="text-current-500 fill-current"
        />
        <span class="font-base text-xs">{{ $t('COMMON_LABEL_EDIT') }}</span>
      </div>
      <div
        v-if="(isEdit || isOpenAddDomain) && !isSelectScopeOnly"
        class="text-xs flex justify-end items-center space-x-2"
      >
        <SynButton
          v-vig-tooltip="$t('COMMON_LABEL_CANCEL')"
          :label="$t('COMMON_LABEL_CANCEL')"
          type-text
          color="gray"
          :disabled="isSaveScope"
          @click="onCancelScope"
        />
        <SynButton
          v-vig-tooltip="$t('COMMON_LABEL_SAVE')"
          :label="$t('COMMON_LABEL_SAVE')"
          :is-loading="isSaveScope"
          :disabled="!isCheckChange"
          @click="isCheckChange ? onSaveScope() : ''"
        />
      </div>
      <div v-if="isOpenAddDomain" class="py-3 px-4">
        <span
          >{{
            $t('GED_NUM_SELECTED', {
              number: domainPayloadCheckedList?.length || 0,
            })
          }}
        </span>
      </div>
    </div>
  </div>

  <div class="pl-2 flex-1 overflow-hidden grid grid-cols-2 gap-3">
    <!-- EDIT MODE -->
    <template v-if="isOpenAddDomain">
      <div class="h-full small-scrollbar overflow-auto px-2">
        <template v-if="domainPayloadFilteredList.length < 1">
          <syn-animation
            name="searchIcon"
            stype="width: 100px; height: 100px"
            :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
          />
        </template>
        <template v-else>
          <div
            v-for="domain in domainPayloadFilteredList"
            :key="domain?.id"
            class="p-2 space-x-2 hover:bg-gray-100 cursor-pointer"
            @click="onToggleSelectDomain(domain)"
          >
            <SynIcon
              name="checkbox"
              class="fill-current"
              custom-class="w-4 h-4"
              :is-active="domain.isChecked"
            />
            <span class="font-normal">
              {{ domain.name }}
            </span>
          </div>
        </template>
      </div>
      <div class="h-full small-scrollbar overflow-auto pr-2">
        <template v-if="domainPayloadCheckedList?.length < 1">
          <syn-animation
            name="searchIcon"
            stype="width: 100px; height: 100px"
            :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
          />
        </template>
        <template v-else>
          <div
            v-for="domain in domainPayloadCheckedList"
            :key="domain?.id"
            class="
              item-domain
              flex
              justify-between
              items-center
              p-2
              cursor-pointer
            "
          >
            <div class="flex items-center space-x-3 px-2">
              <SynIcon
                v-if="!isOpenAddDomain"
                name="project"
                class="text-current fill-current"
              />
              <div class="break-normal text-current-800">{{ domain.name }}</div>
            </div>
            <div v-vig-tooltip="$t('COMMON_LABEL_DELETE')">
              <SynIcon
                v-if="isOpenAddDomain"
                has-action
                color="gray"
                custom-class="fill-gray-500 w-2.5 h-2.5"
                name="cancel"
                @click.stop="
                  domainPayloadCheckedList?.length > 1
                    ? removeDomain(domain)
                    : onShowError()
                "
              />
            </div>
          </div>
        </template>
        <!-- VIEW MODE -->
      </div>
    </template>
    <template v-else>
      <div class="h-full small-scrollbar overflow-auto pr-2">
        <template v-if="groupDomains?.length < 1">
          <syn-animation
            name="searchIcon"
            stype="width: 100px; height: 100px"
            :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
          />
        </template>
        <template v-else>
          <div
            v-for="domain in groupDomains"
            :key="domain?.id"
            class="
              item-domain
              flex
              justify-between
              items-center
              p-2
              my-2
              cursor-pointer
            "
            :class="isOpenAddDomain ? 'bg-gray-50 rounded-md' : ''"
          >
            <div class="flex items-center space-x-3 px-2">
              <SynIcon
                v-if="!isOpenAddDomain"
                name="project"
                class="text-current fill-current"
              />
              <div class="break-normal">{{ domain.name }}</div>
            </div>
            <div v-vig-tooltip="$t('COMMON_LABEL_DELETE')">
              <SynIcon
                v-if="isOpenAddDomain"
                has-action
                color="gray"
                custom-class="fill-gray-500 w-2.5 h-2.5"
                name="cancel"
                @click.stop="
                  domainPayloadCheckedList?.length > 1
                    ? removeDomain(domain)
                    : onShowError()
                "
              />
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
  <AlertModal
    v-if="isShowPopupNotRemove"
    :title-text="$t('COMMON_LABEL_BE_WARNED')"
    :sub-title-text="messageError"
    container-class="w-1/4"
    name-icon="warning"
    :container-color="'orange'"
    @cancel="isShowPopupNotRemove = false"
  />

  <AlertModal
    v-if="showAlertSystemGroup"
    :title-text="$t('GROUP_LABEL_EDIT_SYSTEM_GROUP_TITLE')"
    :sub-title-text="
      $t('GROUP_LABEL_REMOVE_SYSTEM_GROUP_CONTENT', {
        groupName: groupDetail?.name,
      })
    "
    :is-html-text="true"
    container-class="w-1/4"
    :container-color="'current'"
    @cancel="showAlertSystemGroup = false"
  />
  <BreakLinkDepartmentGroupModal
    v-if="isShowAlertCannotEditDepartmentGroup"
    :group="groupDetail"
    @cancel="isShowAlertCannotEditDepartmentGroup = false"
    @update-success="isShowAlertCannotEditDepartmentGroup = false"
  />
</template>
