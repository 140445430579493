<template>
  <syn-modal
    z-index="z-50"
    container-class="w-5/6 md:w-1/2 xl:w-1/3"
    style-body="py-4 px-2 flex flex-col"
    :disable-click-outside="true"
    :disable-esc-to-close="true"
    disable-auto-focus
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div>{{ $t('GED_LINK_NEW') }}</div>
    </template>

    <template #body>
      <Form id="formNewLink" class="p-3" @submit="onFormSubmit">
        <div class="mb-3 flex">
          <div class="w-32 text-gray-500">{{ $t('GED_FOLDER_IN') }}</div>
          <AddFileLocation :location="location" class="font-semibold" />
          <button-change-file-location
            v-if="linkLocationCanChange"
            class="ml-3"
            :group-id="linkLocation"
            @on-location-click="linkLocation = $event"
          />
        </div>
        <div class="mb-3 flex items-center">
          <label for="formNewLinkFieldName" class="block w-32 text-gray-500">{{
            $t('GED_LINK_NAME')
          }}</label>
          <div class="flex-1">
            <Field
              id="formNewLinkFieldName"
              v-model="linkName"
              v-cusFocus="true"
              type="text"
              class="vig-input"
              name="fieldName"
              :rules="isValidLinkName"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              :validate-on-model-update="false"
            />
            <ErrorMessage
              name="fieldName"
              class="block mt-1 text-sm text-red-600"
            />
          </div>
        </div>
        <div class="flex items-center">
          <label for="formNewLinkFieldUrl" class="block w-32 text-gray-500">{{
            $t('GED_LINK_URL')
          }}</label>
          <div class="flex-1">
            <Field
              id="formNewLinkFieldUrl"
              v-model="linkUrl"
              type="text"
              class="vig-input"
              name="fieldUrl"
              :rules="isValidLinkUrl"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              :validate-on-model-update="false"
            />
            <ErrorMessage
              name="fieldUrl"
              class="block mt-1 text-sm text-red-600"
            />
          </div>
        </div>
      </Form>
    </template>

    <template #footer>
      <div class="px-3 py-2 flex justify-end space-x-2">
        <vig-button ghost color="gray" @click="$emit('onClose')">
          {{ $t('COMMON_LABEL_CANCEL') }}
        </vig-button>
        <vig-button type="submit" form="formNewLink" :processing="isSavingLink">
          <span>{{ $t('COMMON_LABEL_SAVE') }}</span>
        </vig-button>
      </div>
    </template>
  </syn-modal>
</template>

<script>
import { ref, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { translate } from '@/ui/plugins/i18n/myi18n';
import fileStorageStore from '@/store/fileStorage';
import groupStore from '@/store/group';
import ButtonChangeFileLocation from '../../_commons/button-change-file-location/ButtonChangeFileLocation.vue';
import $constants from '@/ui/plugins/constants';
import myProfileStore from '@/store/auth/my-profile';
import AddFileLocation from '@/ui/modules/ged/_commons/add-file-location/AddFileLocation.vue';

export default {
  components: {
    AddFileLocation,
    ButtonChangeFileLocation,
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    location: {
      type: Object,
      default: null,
    },
  },
  emits: ['onClose'],
  setup(props, { emit }) {
    const _fileStorageStore = fileStorageStore();
    const _groupStore = groupStore();

    const myProfile = computed(() => myProfileStore().myProfile);
    const allMyGroups = computed(() =>
      _groupStore.allMyGroups?.filter((group) => group?.isMember)
    );

    const linkName = ref();
    const linkUrl = ref();
    const linkLocation = ref(props.location?.groupId);

    const linkLocationName = computed(() => {
      if (!linkLocation?.value) return translate('GED_MY_STORAGE');

      const groupInfo = allMyGroups.value?.find(
        (group) => group?.id === linkLocation.value
      );
      return groupInfo?.name;
    });

    const linkLocationCanChange = computed(
      () =>
        false && // Add by domain resources
        !props.location?.groupId &&
        !props.location?.category &&
        props.location?.userId !== myProfile?.value.id &&
        !props.location?.folderId
    );

    const isSavingLink = ref();

    const onFormSubmit = async () => {
      isSavingLink.value = true;

      const newLink = {
        name: linkName.value,
        link: linkUrl.value?.startsWith('http')
          ? linkUrl.value
          : `http://${linkUrl.value}`,
        folderId: props.location?.folderId,
        category: $constants.FILE.CATEGORY.STORAGE,
        // groupId: linkLocation.value,
        groupId: props.location?.groupId,
        domainId: props.location?.domainId,
      };

      try {
        await _fileStorageStore.addLink(newLink);

        _fileStorageStore.getFiles(props.location);

        emit('onClose');
      } catch (e) {
        console.log(e);
      }

      isSavingLink.value = false;
    };

    return {
      linkName,
      linkUrl,
      linkLocation,
      linkLocationName,
      linkLocationCanChange,
      isSavingLink,
      onFormSubmit,
    };
  },
  methods: {
    isValidLinkName(value) {
      if (!value?.trim()) {
        return translate('GED_LINK_NAME_REQUIRED_MSG');
      }

      const limitChars = 200;
      if (value?.length > limitChars) {
        return translate('GED_LINK_NAME_MAX_LENGTH_MSG', {
          number: limitChars,
        });
      }

      const specialChars = /[<>:"\\/|?*]/;
      if (specialChars.test(value)) {
        return translate('GED_LINK_NAME_SPECIAL_CHARS_MSG');
      }

      return true;
    },
    isValidLinkUrl(value) {
      if (!value?.trim()) {
        return translate('GED_LINK_URL_REQUIRED_MSG');
      }

      const limitChars = 500;
      if (value?.length > limitChars) {
        return translate('GED_LINK_URL_MAX_LENGTH_MSG', {
          number: limitChars,
        });
      }

      const linkRegex =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&%'()*+,;=.]+$/;
      if (!value?.match(linkRegex)) {
        return translate('GED_LINK_URL_INVALID_MSG', {
          number: limitChars,
        });
      }

      return true;
    },
  },
};
</script>
