<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 171.738 289.865"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M114.436 46.504c6.172 14.52 16.728 15.596 23.497 14.509-2.909-2.661-5.309-6.815-6.28-13.259a44.676 44.676 0 0 0-.187-1.145c-4.065 1.019-9.941 1.637-17.03-.105z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M153.038 64.132c.615.239.649 4.167 4.437 4.761 3.788.596 11.671-10.217 10.567-13.292-1.106-3.074-6.74-.981-10.662-1.791-3.923-.811 15.713-12.112-.195-32.921-4.692 13.824-13.93 19.697-13.93 19.697s-.612.474-2.727.009c-2.115-.464 1.23-7.75-4.33-10.714-2.761-1.47-13.69.604-11.107 12.987 3.343-2.887 6.24-6.239 7.884 4.686 2.94 19.521 19.448 16.34 20.063 16.578zm-21.583-28.587a1.508 1.508 0 1 1 3.016 0 1.508 1.508 0 0 1-3.016 0zM30.214 40.124c6.769 1.086 17.325.01 23.497-14.509-7.089 1.742-12.965 1.125-17.03.106-.063.357-.126.737-.187 1.145-.97 6.442-3.371 10.597-6.28 13.258z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M35.172 26.665c1.644-10.925 4.542-7.573 7.884-4.686C45.639 9.596 34.71 7.522 31.949 8.993c-5.56 2.964-2.215 10.25-4.33 10.714-2.114.465-2.727-.009-2.727-.009S15.654 13.824 10.962 0c-15.908 20.809 3.728 32.11-.195 32.921-3.922.81-9.556-1.283-10.662 1.791-1.104 3.074 6.779 13.887 10.567 13.291 3.788-.595 3.822-4.523 4.437-4.761.615-.237 17.124 2.944 20.063-16.577zm.054-13.934a1.55 1.55 0 1 1 0 3.1 1.55 1.55 0 0 1 0-3.1zM159.765 77.837c-32.7 18.704-68.345 23.972-75.429 24.867-.052-.004-.103-.014-.155-.017l-38.567-2.28a7.585 7.585 0 0 0-1.024.015 8.238 8.238 0 0 0-.824-.092c-7.258-.429-9.947 3.847-10.768 5.706-1.064 2.411-1.996 7.286 3.714 12.842.02.02.045.04.065.059l-.054.912.61-.405c6.156 5.585 19.741 12.342 37.562 13.395 12.634.746 27.388-1.376 43.098-8.822a8 8 0 1 0-6.853-14.458c-27.692 13.124-50.585 5.911-59.702.523l2.149-1.427c3.35 1.805 11.087 5.248 22.276 5.91 11.413.675 22.949-1.709 34.292-7.084a10.324 10.324 0 0 1 5.012-.976 10.288 10.288 0 0 1 7.659 4.168c13.637-4.087 29.452-10.122 44.881-18.947a8 8 0 0 0-7.942-13.889z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
      <path
        d="M74.76 135.134c-16.88-.997-30.913-7.17-38.156-13.264l-2.9 49.056-10.16 105.514c-.648 6.734 4.285 12.719 11.02 13.367.399.039.795.058 1.188.058 6.236 0 11.569-4.742 12.179-11.077l9.024-93.714 6.407.379 10.224 93.494c.687 6.272 5.994 10.919 12.163 10.919.444 0 .894-.024 1.347-.073 6.725-.735 11.581-6.784 10.846-13.51l-10.58-96.75c.015-.133.036-.265.044-.4l2.63-44.487a85.02 85.02 0 0 1-15.276.488zM44.101 72.07c-.733 12.405 8.729 23.055 21.133 23.788 12.406.733 23.055-8.728 23.788-21.133S80.295 51.67 67.889 50.937c-12.404-.733-23.055 8.728-23.788 21.133zm37.691 6.846s-7.43 19.159-22.22 4.627c0 0 14.027 1.53 22.22-4.627z"
        opacity="1"
        data-original="#000000"
        class=""
      ></path>
    </g>
  </svg>
</template>
