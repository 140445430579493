<template>
  <svg
    viewBox="0 0 509 502.002"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <desc>Created with Pixso.</desc>
    <defs>
      <clipPath id="clip3_146">
        <rect
          id="Redirect"
          width="215.000000"
          height="277.000000"
          transform="translate(111.999985 149.001953)"
          fill="white"
        />
      </clipPath>
    </defs>
    <path
      id="path"
      d="M354.181 35.1257L448.152 35.1257L340.735 141.12L340.74 141.12C333.783 147.981 333.783 159.139 340.74 166C344.166 169.379 347.431 171.002 352 171.002C356.569 171.002 362.493 169.379 365.92 166L365.915 166.002L473.384 60.0106L473.384 152.689C473.384 162.418 481.38 170.303 491.14 170.303C501.004 170.303 509 162.418 509 152.689L509 17.614C509 7.88538 501.004 0 491.14 0L354.181 0C344.317 0 336.322 7.88538 336.322 17.614C336.426 27.2403 344.317 35.1257 354.181 35.1257Z"
      fill-rule="nonzero"
    />
    <path
      id="path"
      d="M106.846 502.002L402.05 502.002C461.028 502.002 508.896 454.69 508.896 396.625L508.896 259.502C508.896 249.773 500.901 241.99 491.036 241.99C481.172 241.99 473.281 249.875 473.281 259.502L473.281 396.522C473.281 435.232 441.299 466.774 402.05 466.774L106.846 466.774C67.5967 466.774 35.6155 435.232 35.6155 396.522L35.6155 105.481C35.6155 66.7713 67.5967 35.2299 106.846 35.2299L244.636 35.2299C254.5 35.2299 262.391 27.3445 262.391 17.6158C262.391 7.88721 254.396 0.00183105 244.636 0.00183105L106.846 0.00183105C47.868 0.00183105 0 47.314 0 105.379L0 396.522C0 454.69 47.9718 502.002 106.846 502.002Z"
      fill-rule="nonzero"
    />
    <rect
      id="Redirect"
      width="215.000000"
      height="277.000000"
      transform="translate(111.999985 149.001953)"
      fill="#FFFFFF"
    />
    <g clip-path="url(#clip3_146)">
      <path
        id="path"
        d="M138.818 149.002C124.009 149.002 112 161.027 112 175.855L112 387.887C112 402.715 124.009 414.739 138.818 414.739L191.34 414.739C206.149 414.739 218.158 402.715 218.158 387.887L218.158 149.002L138.818 149.002ZM196.926 374.414C196.926 384.95 188.407 393.48 177.884 393.48L152.274 393.48C141.751 393.48 133.232 384.95 133.232 374.414L133.232 189.328C133.232 178.791 141.751 170.261 152.274 170.261L196.926 170.261L196.926 374.414Z"
        fill-rule="nonzero"
      />
      <path
        id="path"
        d="M276.545 149.002L196.926 149.002L196.926 321.731L276.545 321.731C291.208 321.731 303.085 309.84 303.085 295.158L303.085 175.576C303.085 160.894 291.208 149.002 276.545 149.002ZM281.853 282.601C281.853 292.474 273.865 300.472 264.005 300.472L218.158 300.472L218.158 170.261L264.005 170.261C273.865 170.261 281.853 178.26 281.853 188.132L281.853 282.601Z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
