<script setup lang="ts">
// *** IMPORTS ***
import myProfileStore from '@/store/auth/my-profile';
import { computed, ref } from 'vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

// *** PROPS, EMITS ***
const props = withDefaults(
  defineProps<{
    fileFilters: any;
    toggleClass: any;
  }>(),
  {
    toggleClass: 'px-4 py-2.5 rounded shadow bg-white',
  }
);

const emit = defineEmits(['update:fileFilters']);

// *** PRIVATE VARIABLES ***
const _myProfileStore = myProfileStore();

// *** COMPOSABLES ***

// *** REFS ***
const expandedByProject = ref<any>({});
const isDropdownOpened = ref<boolean>();

// *** COMPUTED ***
const myDomains = computed(() => _myProfileStore.myDomains);
const selectedDomain = computed(() =>
  myDomains.value?.find((domain) => domain?.id === props.fileFilters?.domainId)
);
const selectedProject = computed(() =>
  selectedDomain.value?.projectScopeList?.find(
    (project) => project?.projectId === props.fileFilters?.projectId
  )
);
const selectedSubProject = computed(() =>
  selectedDomain.value?.projectScopeList?.find(
    (project) => project?.projectId === props.fileFilters?.subProjectId
  )
);
const projectsByDomain = computed(() =>
  selectedDomain.value?.projectScopeList
    ?.filter((project) => !project?.parentId)
    ?.map((project) => ({
      ...project,
      subProjects: selectedDomain.value?.projectScopeList?.filter(
        (subPrj) => subPrj?.parentId === project?.projectId
      ),
    }))
);

// *** WATCHES ***

// *** HOOKS ***

// *** HANDLER FUNCTIONS ***
const onProjectClick = (project) => {
  if (!project) {
    return emit('update:fileFilters', {
      ...(props.fileFilters || {}),
      projectId: null,
      subProjectId: null,
    });
  }

  if (project?.parentId) {
    return emit('update:fileFilters', {
      ...(props.fileFilters || {}),
      projectId: project?.parentId,
      subProjectId: project?.projectId,
    });
  }

  if (project?.projectId) {
    return emit('update:fileFilters', {
      ...(props.fileFilters || {}),
      projectId: project?.projectId,
      subProjectId: null,
    });
  }
};

const onProjectExpandToggle = (project) => {
  if (!project?.projectId || !project?.subProjects?.length) return;

  expandedByProject.value[project.projectId] =
    !expandedByProject.value[project.projectId];
};

// *** PRIVATE FUNCTIONS ***

// *** EXPOSES ***
</script>

<template>
  <VigDropdown
    ref="dropdownRef"
    v-model:is-open="isDropdownOpened"
    space="0"
    placement="bottom-start"
    :arrow="false"
  >
    <template #dropdown-toggle>
      <div role="button" class="flex items-center gap-2" :class="toggleClass">
        <div class="flex-1 overflow-hidden flex items-center gap-1">
          <span
            v-if="selectedProject"
            :title="selectedProject?.projectName"
            class="font-semibold truncate"
            >{{ selectedProject?.projectName }}</span
          >
          <template v-if="selectedSubProject">
            <span class="text-gray-500">/</span>
            <span
              :title="selectedSubProject?.projectName"
              class="truncate font-semibold"
              >{{ selectedSubProject?.projectName }}</span
            >
          </template>
          <span v-if="!selectedProject" class="text-gray-500 italic">
            Chọn lĩnh vực/dự án con
          </span>
        </div>
        <VigButton
          v-if="selectedProject"
          ghost
          color="gray"
          icon="Close"
          padding="p-1"
          rounded="rounded-full"
          @click.stop="
            onProjectClick(null);
            $refs.dropdownRef.onForceClose();
          "
        />
        <SynIcon
          name="ArrowDown"
          custom-class="w-2 h-2"
          class="fill-gray-500 transform duration-100"
          :class="{ '-rotate-180': isDropdownOpened }"
        />
      </div>
    </template>
    <template #dropdown-menu>
      <div class="max-h-96 min-w-xxs overflow-auto small-scrollbar">
        <div v-for="project in projectsByDomain" :key="project?.projectId">
          <div
            role="button"
            class="pl-2 pr-4 py-2 hover:bg-gray-50 flex items-center gap-2"
            :title="project?.projectName"
            @click="
              onProjectClick(project);
              $refs.dropdownRef.onForceClose();
            "
          >
            <div
              class="w-5 flex-center"
              @click.stop="onProjectExpandToggle(project)"
            >
              <div v-if="project?.subProjects?.length">
                <SynIcon
                  name="ArrowDown"
                  custom-class="w-2 h-2 fill-gray-500"
                  :class="{
                    '-rotate-90': !expandedByProject[project?.projectId],
                  }"
                ></SynIcon>
              </div>
            </div>
            <span class="flex-1 truncate text-blue-500">{{
              project?.projectName
            }}</span>
          </div>
          <template v-if="expandedByProject[project?.projectId]">
            <div
              v-for="subProject in project?.subProjects"
              :key="subProject?.projectId"
              role="button"
              class="pl-12 pr-4 py-2 hover:bg-gray-50 text-orange-500"
              :title="subProject?.projectName"
              @click="
                onProjectClick(subProject);
                $refs.dropdownRef.onForceClose();
              "
            >
              {{ subProject?.projectName }}
            </div>
          </template>
        </div>
        <div
          v-if="!projectsByDomain?.length"
          class="px-4 py-2 italic text-gray-500"
        >
          Không có lĩnh vực/dự án con nào
        </div>
      </div>
    </template>
  </VigDropdown>
</template>

<style scoped></style>
