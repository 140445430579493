<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    width="163.861px"
    height="163.861px"
    viewBox="0 0 163.861 163.861"
    style="enable-background: new 0 0 163.861 163.861"
    xml:space="preserve"
  >
    <g>
      <path
        d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275   c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
      />
    </g>
  </svg>
</template>
