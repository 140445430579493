import { translate } from '@/ui/plugins/i18n/myi18n';
export default {
    organizationModels: [
        {
            id: 1,
            code: 'COMPANY',
            index: 1,
            active: true,
            name: translate('COMMON_LABEL_COMPANY'),
            description: null,
        },
        {
            id: 2,
            code: 'TEAM',
            index: 2,
            active: true,
            name: translate('COMMON_LABEL_TEAM'),
            description: null,
        },
        {
            id: 4,
            code: 'PERSONAL',
            index: 4,
            active: true,
            name: translate('COMMON_LABEL_PERSONAL'),
            description: null,
        },
        {
            active: true,
            code: 'NON-PROFIT_ORGANIZATION',
            description: null,
            id: 6,
            index: 6,
            name: 'Tổ chức phi lợi nhuận',
        },
    ],
    organizationTypes: [
        {
            id: 64,
            code: 'MARKETING_2',
            index: 1,
            active: true,
            system: true,
            name: 'Marketing',
            description: null,
        },
        {
            id: 65,
            code: 'SOFTWARE_DEVELOPER',
            index: 2,
            active: true,
            system: true,
            name: 'Phần mềm',
            description: null,
        },
        {
            id: 72,
            code: 'LUAT_2',
            index: 2,
            active: true,
            system: true,
            name: 'Luật',
            description: null,
        },
        {
            id: 73,
            code: 'TAX_2',
            index: 2,
            active: true,
            system: true,
            name: 'Thuế',
            description: null,
        },
        {
            id: 66,
            code: 'THIET_KE_2',
            index: 3,
            active: true,
            system: true,
            name: 'Thiết kế',
            description: null,
        },
        {
            id: 67,
            code: 'GIAO_DUC_2',
            index: 4,
            active: true,
            system: true,
            name: 'Giáo dục',
            description: null,
        },
        {
            id: 68,
            code: 'CHAM_SOC_SUC_KHOE_2',
            index: 5,
            active: true,
            system: true,
            name: 'Chăm sóc sức khỏe',
            description: null,
        },
        {
            id: 69,
            code: 'BAN_HANG_2',
            index: 6,
            active: true,
            system: true,
            name: 'Bán hàng',
            description: null,
        },
        {
            id: 70,
            code: 'BAT_ÐONG_SAN_2',
            index: 7,
            active: true,
            system: true,
            name: 'Bất động sản',
            description: null,
        },
        {
            id: 71,
            code: 'BAO_HIEM_2',
            index: 8,
            active: true,
            system: true,
            name: 'Bảo hiểm',
            description: null,
        },
        {
            id: 75,
            code: 'HR_RECRUITMENT',
            index: 9,
            active: true,
            system: true,
            name: 'Nhân sự và tuyển dụng',
            description: null,
        },
        {
            id: 78,
            code: 'LOGISTICS',
            index: 10,
            active: true,
            system: true,
            name: 'Logistic',
            description: null,
        },
        {
            id: 76,
            code: 'CONTENT_CREATION',
            index: 11,
            active: true,
            system: true,
            name: 'Sáng tạo nội dung',
            description: null,
        },
        {
            id: 77,
            code: 'RESEARCH_STUDY',
            index: 12,
            active: true,
            system: true,
            name: 'Nghiên cứu',
            description: null,
        },
        {
            id: 79,
            code: 'TOURISM',
            index: 13,
            active: true,
            system: true,
            name: 'Du lịch',
            description: null,
        },
        {
            id: 80,
            code: 'FASHION',
            index: 14,
            active: true,
            system: true,
            name: 'Thời trang',
            description: null,
        },
        {
            id: 82,
            code: 'BEAUTY',
            index: 15,
            active: true,
            system: true,
            name: 'Làm đẹp',
            description: null,
        },
        {
            id: 81,
            code: 'HOSPITALITY',
            index: 16,
            active: true,
            system: true,
            name: 'Khách sạn',
            description: null,
        },
        {
            id: 83,
            code: 'RESTAURANT',
            index: 17,
            active: true,
            system: true,
            name: 'Nhà hàng',
            description: null,
        },
        {
            id: 84,
            code: 'CONSTRUCTION',
            index: 18,
            active: true,
            system: true,
            name: 'Xây dựng',
            description: null,
        },
        {
            id: 85,
            code: 'MANUFACTURING',
            index: 19,
            active: true,
            system: true,
            name: 'Sản xuất',
            description: null,
        },
        {
            id: 86,
            code: 'FINANCE',
            index: 20,
            active: true,
            system: true,
            name: 'Tài chính',
            description: null,
        },
        {
            id: 87,
            code: 'COMMERCE',
            index: 21,
            active: true,
            system: true,
            name: 'Thương mại',
            description: null,
        },
        {
            id: 88,
            code: 'SERVICES',
            index: 22,
            active: true,
            system: true,
            name: 'Dịch vụ',
            description: null,
        },
        {
            id: 89,
            code: 'ARTS_AND_ENTERTAINMENT',
            index: 23,
            active: true,
            system: true,
            name: 'Nghệ thuật và Giải trí',
            description: null,
        },
        {
            id: 90,
            code: 'MEDIA_AND_ADVERTISING',
            index: 24,
            active: true,
            system: true,
            name: 'Truyền thông và Quảng cáo',
            description: null,
        },
        {
            id: 91,
            code: 'NON-PROFIT_ORGANIZATION',
            index: 25,
            active: true,
            system: true,
            name: 'Tổ chức phi lợi nhuận',
            description: null,
        },
        {
            id: 95,
            code: 'ENERGY',
            index: 26,
            active: true,
            system: true,
            name: 'Năng lượng',
            description: null,
        },
        {
            id: 92,
            code: 'GOVERNMENT_AND_PUBLIC_ADMINISTRATION',
            index: 26,
            active: true,
            system: true,
            name: 'Chính phủ và Hành chính công',
            description: null,
        },
        {
            id: 93,
            code: 'AGRICULTURE',
            index: 27,
            active: true,
            system: true,
            name: 'Nông nghiệp',
            description: null,
        },
        {
            id: 96,
            code: 'SCIENCE_AND_TECHNOLOGY',
            index: 28,
            active: true,
            system: true,
            name: 'Khoa học và Công nghệ',
            description: null,
        },
        {
            id: 97,
            code: 'ENVIRONMENT',
            index: 29,
            active: true,
            system: true,
            name: 'Môi trường',
            description: null,
        },
        {
            id: 98,
            code: 'SPORTS',
            index: 30,
            active: true,
            system: true,
            name: 'Thể thao',
            description: null,
        },
        {
            id: 74,
            code: 'OTHER',
            index: 99,
            active: true,
            system: true,
            name: 'Lĩnh vực khác',
            description: null,
        },
    ],

    organizationConfigs: {
        SOFTWARE_DEVELOPER: {
            POSITION: [
                {
                    id: 1,
                    organizationTypeId: 1,
                    group: 'POSITION',
                    code: 'MANAGER',
                    index: 1,
                    active: true,
                    name: 'Quản lý',
                    description: null,
                },
                {
                    id: 2,
                    organizationTypeId: 1,
                    group: 'POSITION',
                    code: 'TESTOR',
                    index: 2,
                    active: true,
                    name: 'Kiểm thử',
                    description: null,
                },
            ],
            TASK_TYPE: [
                {
                    id: 3,
                    organizationTypeId: 1,
                    group: 'TASKTYPE',
                    code: 'CONTENT_WRITING',
                    index: 1,
                    active: true,
                    name: 'Viết nội dung',
                    description: null,
                },
                {
                    id: 4,
                    organizationTypeId: 1,
                    group: 'TASKTYPE',
                    code: 'CODING',
                    index: 2,
                    active: true,
                    name: 'Lập trình',
                    description: null,
                },
            ],
            DOMAIN: [],
        },
        MARKETING: {
            POSITION: [
                {
                    id: 5,
                    organizationTypeId: 2,
                    group: 'POSITION',
                    code: 'DIRECTOR',
                    index: 1,
                    active: true,
                    name: 'Giám đốc',
                    description: null,
                },
            ],
            TASK_TYPE: [
                {
                    id: 6,
                    organizationTypeId: 2,
                    group: 'TASKTYPE',
                    code: 'CUSTOMER_SERVICE',
                    index: 1,
                    active: true,
                    name: 'Hỗ trợ khách hàng',
                    description: null,
                },
            ],
            DOMAIN: [
                {
                    id: 1,
                    organizationTypeId: 2,
                    group: 'TASKTYPE',
                    code: 'CUSTOMER_SERVICE',
                    index: 1,
                    active: true,
                    name: 'Tictop feadback',
                    description: null,
                },
            ],
        },
    },
    organizationCategories: [
        {
            id: 1,
            code: 'ENTERPRISE',
            index: 1,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: null,
            name: 'Doanh nghiệp',
            description: null,
            children: [
                {
                    id: 2,
                    code: 'LIMITED_LIABILITY_COMPANY',
                    index: 2,
                    active: true,
                    system: true,
                    parentId: 1,
                    model: 'COMPANY',
                    domain: null,
                    name: 'Công ty TNHH',
                    description: null,
                },
                {
                    id: 3,
                    code: 'JOINT-STOCK_COMPANY',
                    index: 3,
                    active: true,
                    system: true,
                    parentId: 1,
                    model: 'COMPANY',
                    domain: null,
                    name: 'Công ty Cổ phần',
                    description: null,
                },
                {
                    id: 4,
                    code: 'PRIVATE_ENTERPRISE',
                    index: 4,
                    active: true,
                    system: true,
                    parentId: 1,
                    model: 'COMPANY',
                    domain: null,
                    name: 'Doanh nghiệp tư nhân',
                    description: null,
                },
                {
                    id: 5,
                    code: 'JOINT_VENTURE_COMPANY',
                    index: 5,
                    active: true,
                    system: true,
                    parentId: 1,
                    model: 'COMPANY',
                    domain: null,
                    name: 'Công ty liên doanh',
                    description: null,
                },
            ],
        },
        {
            id: 6,
            code: 'EDUCATION',
            index: 6,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'GIAO_DUC_2',
            name: 'Giáo dục',
            description: null,
            children: [
                {
                    id: 7,
                    code: 'ELEMENTARY_SCHOOL',
                    index: 7,
                    active: true,
                    system: true,
                    parentId: 6,
                    model: 'COMPANY',
                    domain: 'GIAO_DUC_2',
                    name: 'Trường Tiểu học',
                    description: null,
                },
                {
                    id: 8,
                    code: 'MIDDLE_HIGH_SCHOOL',
                    index: 8,
                    active: true,
                    system: true,
                    parentId: 6,
                    model: 'COMPANY',
                    domain: 'GIAO_DUC_2',
                    name: 'Trường Trung học',
                    description: null,
                },
                {
                    id: 9,
                    code: 'UNIVERSITY_COLLEGE',
                    index: 9,
                    active: true,
                    system: true,
                    parentId: 6,
                    model: 'COMPANY',
                    domain: 'GIAO_DUC_2',
                    name: 'Trường Đại học/Cao đẳng',
                    description: null,
                },
                {
                    id: 10,
                    code: 'LANGUAGE_CENTER',
                    index: 10,
                    active: true,
                    system: true,
                    parentId: 6,
                    model: 'COMPANY',
                    domain: 'GIAO_DUC_2',
                    name: 'Trung tâm Ngoại ngữ',
                    description: null,
                },
                {
                    id: 11,
                    code: 'VOCATIONAL_TRAINING_CENTER',
                    index: 11,
                    active: true,
                    system: true,
                    parentId: 6,
                    model: 'COMPANY',
                    domain: 'GIAO_DUC_2',
                    name: 'Trung tâm Đào tạo nghề',
                    description: null,
                },
                {
                    id: 12,
                    code: 'RESEARCH_INSTITUTE',
                    index: 12,
                    active: true,
                    system: true,
                    parentId: 6,
                    model: 'COMPANY',
                    domain: 'GIAO_DUC_2',
                    name: 'Viện nghiên cứu',
                    description: null,
                },
            ],
        },
        {
            id: 13,
            code: 'HEALTHCARE',
            index: 13,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'CHAM_SOC_SUC_KHOE_2',
            name: 'Y tế',
            description: null,
            children: [
                {
                    id: 14,
                    code: 'HOSPITAL',
                    index: 14,
                    active: true,
                    system: true,
                    parentId: 13,
                    model: 'COMPANY',
                    domain: 'CHAM_SOC_SUC_KHOE_2',
                    name: 'Bệnh viện',
                    description: null,
                },
                {
                    id: 15,
                    code: 'GENERAL_CLINIC',
                    index: 15,
                    active: true,
                    system: true,
                    parentId: 13,
                    model: 'COMPANY',
                    domain: 'CHAM_SOC_SUC_KHOE_2',
                    name: 'Phòng khám đa khoa',
                    description: null,
                },
                {
                    id: 16,
                    code: 'SPECIALIZED_CLINIC',
                    index: 16,
                    active: true,
                    system: true,
                    parentId: 13,
                    model: 'COMPANY',
                    domain: 'CHAM_SOC_SUC_KHOE_2',
                    name: 'Phòng khám chuyên khoa',
                    description: null,
                },
                {
                    id: 17,
                    code: 'COMMUNITY_HEALTH_CENTER',
                    index: 17,
                    active: true,
                    system: true,
                    parentId: 13,
                    model: 'COMPANY',
                    domain: 'CHAM_SOC_SUC_KHOE_2',
                    name: 'Trung tâm y tế cộng đồng',
                    description: null,
                },
                {
                    id: 18,
                    code: 'PHARMACY',
                    index: 18,
                    active: true,
                    system: true,
                    parentId: 13,
                    model: 'COMPANY',
                    domain: 'CHAM_SOC_SUC_KHOE_2',
                    name: 'Nhà thuốc',
                    description: null,
                },
            ],
        },
        {
            id: 19,
            code: 'FINANCE',
            index: 19,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'FINANCE',
            name: 'Tài chính',
            description: null,
            children: [
                {
                    id: 20,
                    code: 'BANK',
                    index: 20,
                    active: true,
                    system: true,
                    parentId: 19,
                    model: 'COMPANY',
                    domain: 'FINANCE',
                    name: 'Ngân hàng',
                    description: null,
                },
                {
                    id: 21,
                    code: 'INSURANCE_COMPANY',
                    index: 21,
                    active: true,
                    system: true,
                    parentId: 19,
                    model: 'COMPANY',
                    domain: 'BAO_HIEM_2',
                    name: 'Công ty bảo hiểm',
                    description: null,
                },
                {
                    id: 22,
                    code: 'SECURITIES_COMPANY',
                    index: 22,
                    active: true,
                    system: true,
                    parentId: 19,
                    model: 'COMPANY',
                    domain: 'FINANCE',
                    name: 'Công ty chứng khoán',
                    description: null,
                },
                {
                    id: 23,
                    code: 'FINANCIAL_COMPANY',
                    index: 23,
                    active: true,
                    system: true,
                    parentId: 19,
                    model: 'COMPANY',
                    domain: 'FINANCE',
                    name: 'Công ty tài chính',
                    description: null,
                },
            ],
        },
        {
            id: 24,
            code: 'INFORMATION_TECHNOLOGY',
            index: 24,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'SOFTWARE_DEVELOPER',
            name: 'Công nghệ thông tin',
            description: null,
            children: [
                {
                    id: 25,
                    code: 'SOFTWARE_DEVELOPMENT_COMPANY',
                    index: 25,
                    active: true,
                    system: true,
                    parentId: 24,
                    model: 'COMPANY',
                    domain: 'SOFTWARE_DEVELOPER',
                    name: 'Công ty phát triển phần mềm',
                    description: null,
                },
                {
                    id: 26,
                    code: 'IT_SERVICES_COMPANY',
                    index: 26,
                    active: true,
                    system: true,
                    parentId: 24,
                    model: 'COMPANY',
                    domain: 'SOFTWARE_DEVELOPER',
                    name: 'Công ty dịch vụ IT',
                    description: null,
                },
                {
                    id: 27,
                    code: 'E-COMMERCE_COMPANY',
                    index: 27,
                    active: true,
                    system: true,
                    parentId: 24,
                    model: 'COMPANY',
                    domain: 'SOFTWARE_DEVELOPER',
                    name: 'Công ty thương mại điện tử',
                    description: null,
                },
                {
                    id: 28,
                    code: 'STARTUP_COMPANY',
                    index: 28,
                    active: true,
                    system: true,
                    parentId: 24,
                    model: 'COMPANY',
                    domain: null,
                    name: 'Công ty khởi nghiệp (Startup)',
                    description: null,
                },
            ],
        },
        {
            id: 29,
            code: 'MANUFACTURING_AND_PROCESSING',
            index: 29,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'MANUFACTURING',
            name: 'Sản xuất và Chế biến',
            description: null,
            children: [
                {
                    id: 30,
                    code: 'MANUFACTURING_PLANT',
                    index: 30,
                    active: true,
                    system: true,
                    parentId: 29,
                    model: 'COMPANY',
                    domain: 'MANUFACTURING',
                    name: 'Nhà máy sản xuất',
                    description: null,
                },
                {
                    id: 31,
                    code: 'WORKSHOP',
                    index: 31,
                    active: true,
                    system: true,
                    parentId: 29,
                    model: 'COMPANY',
                    domain: 'MANUFACTURING',
                    name: 'Xưởng chế tạo',
                    description: null,
                },
                {
                    id: 32,
                    code: 'FOOD_PROCESSING_COMPANY',
                    index: 32,
                    active: true,
                    system: true,
                    parentId: 29,
                    model: 'COMPANY',
                    domain: 'MANUFACTURING',
                    name: 'Công ty chế biến thực phẩm',
                    description: null,
                },
                {
                    id: 33,
                    code: 'GARMENT_COMPANY',
                    index: 33,
                    active: true,
                    system: true,
                    parentId: 29,
                    model: 'COMPANY',
                    domain: 'MANUFACTURING',
                    name: 'Công ty may mặc',
                    description: null,
                },
            ],
        },
        {
            id: 34,
            code: 'COMMERCE',
            index: 34,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'COMMERCE',
            name: 'Thương mại',
            description: null,
            children: [
                {
                    id: 35,
                    code: 'SUPERMARKET_SHOPPING_CENTER',
                    index: 35,
                    active: true,
                    system: true,
                    parentId: 34,
                    model: 'COMPANY',
                    domain: 'COMMERCE',
                    name: 'Siêu thị/Trung tâm thương mại',
                    description: null,
                },
                {
                    id: 36,
                    code: 'RETAIL_STORE',
                    index: 36,
                    active: true,
                    system: true,
                    parentId: 34,
                    model: 'COMPANY',
                    domain: 'COMMERCE',
                    name: 'Cửa hàng bán lẻ',
                    description: null,
                },
                {
                    id: 37,
                    code: 'ONLINE_STORE',
                    index: 37,
                    active: true,
                    system: true,
                    parentId: 34,
                    model: 'COMPANY',
                    domain: 'COMMERCE',
                    name: 'Cửa hàng trực tuyến',
                    description: null,
                },
            ],
        },
        {
            id: 38,
            code: 'SERVICES',
            index: 38,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'SERVICES',
            name: 'Dịch vụ',
            description: null,
            children: [
                {
                    id: 39,
                    code: 'TOURISM_COMPANY',
                    index: 39,
                    active: true,
                    system: true,
                    parentId: 38,
                    model: 'COMPANY',
                    domain: 'SERVICES',
                    name: 'Công ty du lịch',
                    description: null,
                },
                {
                    id: 40,
                    code: 'TRANSPORTATION_AND_LOGISTICS_COMPANY',
                    index: 40,
                    active: true,
                    system: true,
                    parentId: 38,
                    model: 'COMPANY',
                    domain: 'SERVICES',
                    name: 'Công ty vận tải và logistics',
                    description: null,
                },
                {
                    id: 41,
                    code: 'SECURITY_COMPANY',
                    index: 41,
                    active: true,
                    system: true,
                    parentId: 38,
                    model: 'COMPANY',
                    domain: 'SERVICES',
                    name: 'Công ty bảo vệ',
                    description: null,
                },
                {
                    id: 42,
                    code: 'CONSULTING_COMPANY',
                    index: 42,
                    active: true,
                    system: true,
                    parentId: 38,
                    model: 'COMPANY',
                    domain: 'SERVICES',
                    name: 'Công ty tư vấn',
                    description: null,
                },
            ],
        },
        {
            id: 43,
            code: 'ARTS_AND_ENTERTAINMENT',
            index: 43,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'ARTS_AND_ENTERTAINMENT',
            name: 'Nghệ thuật và Giải trí',
            description: null,
            children: [
                {
                    id: 44,
                    code: 'CINEMA',
                    index: 44,
                    active: true,
                    system: true,
                    parentId: 43,
                    model: 'COMPANY',
                    domain: 'ARTS_AND_ENTERTAINMENT',
                    name: 'Rạp chiếu phim',
                    description: null,
                },
                {
                    id: 45,
                    code: 'THEATER',
                    index: 45,
                    active: true,
                    system: true,
                    parentId: 43,
                    model: 'COMPANY',
                    domain: 'ARTS_AND_ENTERTAINMENT',
                    name: 'Nhà hát',
                    description: null,
                },
                {
                    id: 46,
                    code: 'ART_GALLERY',
                    index: 46,
                    active: true,
                    system: true,
                    parentId: 43,
                    model: 'COMPANY',
                    domain: 'ARTS_AND_ENTERTAINMENT',
                    name: 'Phòng trưng bày nghệ thuật',
                    description: null,
                },
                {
                    id: 47,
                    code: 'EVENT_MANAGEMENT_COMPANY',
                    index: 47,
                    active: true,
                    system: true,
                    parentId: 43,
                    model: 'COMPANY',
                    domain: 'ARTS_AND_ENTERTAINMENT',
                    name: 'Công ty tổ chức sự kiện',
                    description: null,
                },
            ],
        },
        {
            id: 48,
            code: 'REAL_ESTATE',
            index: 48,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'BAT_ÐONG_SAN_2',
            name: 'Bất động sản',
            description: null,
            children: [
                {
                    id: 49,
                    code: 'REAL_ESTATE_COMPANY',
                    index: 49,
                    active: true,
                    system: true,
                    parentId: 48,
                    model: 'COMPANY',
                    domain: 'BAT_ÐONG_SAN_2',
                    name: 'Công ty bất động sản',
                    description: null,
                },
                {
                    id: 50,
                    code: 'ASSET_MANAGEMENT_COMPANY',
                    index: 50,
                    active: true,
                    system: true,
                    parentId: 48,
                    model: 'COMPANY',
                    domain: 'BAT_ÐONG_SAN_2',
                    name: 'Công ty quản lý tài sản',
                    description: null,
                },
                {
                    id: 51,
                    code: 'CONSTRUCTION_COMPANY',
                    index: 51,
                    active: true,
                    system: true,
                    parentId: 48,
                    model: 'COMPANY',
                    domain: 'BAT_ÐONG_SAN_2',
                    name: 'Công ty xây dựng',
                    description: null,
                },
            ],
        },
        {
            id: 52,
            code: 'MEDIA_AND_ADVERTISING',
            index: 52,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'MEDIA_AND_ADVERTISING',
            name: 'Truyền thông và Quảng cáo',
            description: null,
            children: [
                {
                    id: 53,
                    code: 'ADVERTISING_COMPANY',
                    index: 53,
                    active: true,
                    system: true,
                    parentId: 52,
                    model: 'COMPANY',
                    domain: 'MEDIA_AND_ADVERTISING',
                    name: 'Công ty quảng cáo',
                    description: null,
                },
                {
                    id: 54,
                    code: 'MEDIA_COMPANY',
                    index: 54,
                    active: true,
                    system: true,
                    parentId: 52,
                    model: 'COMPANY',
                    domain: 'MEDIA_AND_ADVERTISING',
                    name: 'Công ty truyền thông',
                    description: null,
                },
                {
                    id: 55,
                    code: 'RADIO_TELEVISION_STATION',
                    index: 55,
                    active: true,
                    system: true,
                    parentId: 52,
                    model: 'COMPANY',
                    domain: 'MEDIA_AND_ADVERTISING',
                    name: 'Đài phát thanh/truyền hình',
                    description: null,
                },
                {
                    id: 56,
                    code: 'PUBLISHING_HOUSE',
                    index: 56,
                    active: true,
                    system: true,
                    parentId: 52,
                    model: 'COMPANY',
                    domain: 'MEDIA_AND_ADVERTISING',
                    name: 'Nhà xuất bản',
                    description: null,
                },
            ],
        },
        {
            id: 57,
            code: 'NON-PROFIT_ORGANIZATIONS',
            index: 57,
            active: true,
            system: true,
            parentId: null,
            model: 'NON-PROFIT_ORGANIZATION',
            domain: 'NON-PROFIT_ORGANIZATION',
            name: 'Tổ chức phi lợi nhuận',
            description: null,
            children: [
                {
                    id: 58,
                    code: 'NON-GOVERNMENTAL_ORGANIZATION',
                    index: 58,
                    active: true,
                    system: true,
                    parentId: 57,
                    model: 'NON-PROFIT_ORGANIZATION',
                    domain: 'NON-PROFIT_ORGANIZATION',
                    name: 'Tổ chức phi chính phủ (NGO)',
                    description: null,
                },
                {
                    id: 59,
                    code: 'CHARITY_ORGANIZATION',
                    index: 59,
                    active: true,
                    system: true,
                    parentId: 57,
                    model: 'NON-PROFIT_ORGANIZATION',
                    domain: 'NULNON-NON-PROFIT_ORGANIZATION',
                    name: 'Tổ chức từ thiện',
                    description: null,
                },
                {
                    id: 60,
                    code: 'SUPPORT_FUND',
                    index: 60,
                    active: true,
                    system: true,
                    parentId: 57,
                    model: 'NON-PROFIT_ORGANIZATION',
                    domain: 'NON-PROFIT_ORGANIZATION',
                    name: 'Quỹ hỗ trợ',
                    description: null,
                },
            ],
        },
        {
            id: 61,
            code: 'GOVERNMENT_AND_PUBLIC_ADMINISTRATION',
            index: 61,
            active: true,
            system: true,
            parentId: null,
            model: 'NON-PROFIT_ORGANIZATION',
            domain: 'GOVERNMENT_AND_PUBLIC_ADMINISTRATION',
            name: 'Chính phủ và Hành chính công',
            description: null,
            children: [
                {
                    id: 62,
                    code: 'STATE_ADMINISTRATIVE_AGENCY',
                    index: 62,
                    active: true,
                    system: true,
                    parentId: 61,
                    model: 'NON-PROFIT_ORGANIZATION',
                    domain: 'GOVERNMENT_AND_PUBLIC_ADMINISTRATION',
                    name: 'Cơ quan hành chính nhà nước',
                    description: null,
                },
                {
                    id: 63,
                    code: 'MINISTRY_DEPARTMENT',
                    index: 63,
                    active: true,
                    system: true,
                    parentId: 61,
                    model: 'NON-PROFIT_ORGANIZATION',
                    domain: 'GOVERNMENT_AND_PUBLIC_ADMINISTRATION',
                    name: 'Bộ/Sở',
                    description: null,
                },
                {
                    id: 64,
                    code: 'GOVERNMENT_AGENCY',
                    index: 64,
                    active: true,
                    system: true,
                    parentId: 61,
                    model: 'NON-PROFIT_ORGANIZATION',
                    domain: 'GOVERNMENT_AND_PUBLIC_ADMINISTRATION',
                    name: 'Cơ quan chính phủ',
                    description: null,
                },
            ],
        },
        {
            id: 65,
            code: 'AGRICULTURE',
            index: 65,
            active: true,
            system: true,
            parentId: null,
            model: 'COMPANY',
            domain: 'AGRICULTURE',
            name: 'Nông nghiệp',
            description: null,
            children: [
                {
                    id: 66,
                    code: 'FARM',
                    index: 66,
                    active: true,
                    system: true,
                    parentId: 65,
                    model: 'COMPANY',
                    domain: 'AGRICULTURE',
                    name: 'Trang trại',
                    description: null,
                },
                {
                    id: 67,
                    code: 'AGRICULTURAL_COMPANY',
                    index: 67,
                    active: true,
                    system: true,
                    parentId: 65,
                    model: 'COMPANY',
                    domain: 'AGRICULTURE',
                    name: 'Công ty nông nghiệp',
                    description: null,
                },
                {
                    id: 68,
                    code: 'AGRICULTURAL_COOPERATIVE',
                    index: 68,
                    active: true,
                    system: true,
                    parentId: 65,
                    model: 'NON-PROFIT_ORGANIZATION',
                    domain: 'AGRICULTURE',
                    name: 'Hợp tác xã nông nghiệp',
                    description: null,
                },
            ],
        },
        {
            id: 69,
            code: 'TEAMS',
            index: 69,
            active: true,
            system: true,
            parentId: null,
            model: 'TEAM',
            domain: null,
            name: 'Đội nhóm',
            description: null,
            children: [
                {
                    id: 70,
                    code: 'TEAM',
                    index: 70,
                    active: true,
                    system: true,
                    parentId: 69,
                    model: 'TEAM',
                    domain: null,
                    name: 'Đội nhóm',
                    description: null,
                },
                {
                    id: 71,
                    code: 'FAMILY',
                    index: 71,
                    active: true,
                    system: true,
                    parentId: 69,
                    model: 'TEAM',
                    domain: null,
                    name: 'Gia đình',
                    description: null,
                },
                {
                    id: 72,
                    code: 'CLUB',
                    index: 72,
                    active: true,
                    system: true,
                    parentId: 69,
                    model: 'TEAM',
                    domain: null,
                    name: 'Câu lạc bộ',
                    description: null,
                },
                {
                    id: 73,
                    code: 'SPORTS_TEAM',
                    index: 73,
                    active: true,
                    system: true,
                    parentId: 69,
                    model: 'TEAM',
                    domain: null,
                    name: 'Đội thể thao',
                    description: null,
                },
                {
                    id: 74,
                    code: 'STUDY_GROUP',
                    index: 74,
                    active: true,
                    system: true,
                    parentId: 69,
                    model: 'TEAM',
                    domain: null,
                    name: 'Nhóm học tập',
                    description: null,
                },
                {
                    id: 75,
                    code: 'PROJECT_TEAM',
                    index: 75,
                    active: true,
                    system: true,
                    parentId: 69,
                    model: 'TEAM',
                    domain: null,
                    name: 'Đội dự án',
                    description: null,
                },
            ],
        },
        {
            id: 76,
            code: 'OTHERS',
            index: 76,
            active: true,
            system: true,
            parentId: null,
            model: 'TEAM',
            domain: 'OTHER',
            name: 'Khác (vui lòng ghi rõ)',
            description: null,
            children: [],
        },
    ],
    jobTitles: [
        {
            id: 1,
            code: 'DIRECTOR',
            index: 1,
            active: true,
            system: true,
            name: 'Giám đốc',
            description: null,
        },
        {
            id: 2,
            code: 'MANAGER',
            index: 2,
            active: true,
            system: true,
            name: 'Quản lý',
            description: null,
        },
        {
            id: 3,
            code: 'PROJECT_MANAGER',
            index: 3,
            active: true,
            system: true,
            name: 'Nhà quản lý dự án',
            description: null,
        },
        {
            id: 4,
            code: 'ENTREPRENEUR',
            index: 4,
            active: true,
            system: true,
            name: 'Doanh nhân',
            description: null,
        },
        {
            id: 5,
            code: 'SMALL_BUSINESS_OWNER',
            index: 5,
            active: true,
            system: true,
            name: 'Chủ doanh nghiệp nhỏ',
            description: null,
        },
        {
            id: 6,
            code: 'CONSULTANT',
            index: 6,
            active: true,
            system: true,
            name: 'Nhà tư vấn',
            description: null,
        },
        {
            id: 7,
            code: 'INFORMATION_TECHNOLOGY_SPECIALIST',
            index: 7,
            active: true,
            system: true,
            name: 'Chuyên gia công nghệ thông tin',
            description: null,
        },
        {
            id: 8,
            code: 'ENGINEER',
            index: 8,
            active: true,
            system: true,
            name: 'Kỹ sư',
            description: null,
        },
        {
            id: 9,
            code: 'MARKETING_SPECIALIST',
            index: 9,
            active: true,
            system: true,
            name: 'Chuyên viên marketing',
            description: null,
        },
        {
            id: 10,
            code: 'ACCOUNTANT_FINANCIAL_SPECIALIST',
            index: 10,
            active: true,
            system: true,
            name: 'Kế toán/Chuyên viên tài chính',
            description: null,
        },
        {
            id: 11,
            code: 'SOFTWARE_DEVELOPER',
            index: 11,
            active: true,
            system: true,
            name: 'Nhà phát triển phần mềm',
            description: null,
        },
        {
            id: 12,
            code: 'HUMAN_RESOURCES_SPECIALIST',
            index: 12,
            active: true,
            system: true,
            name: 'Chuyên gia nhân sự',
            description: null,
        },
        {
            id: 13,
            code: 'RESEARCHER',
            index: 13,
            active: true,
            system: true,
            name: 'Nhà nghiên cứu',
            description: null,
        },
        {
            id: 14,
            code: 'DOCTOR_NURSE',
            index: 14,
            active: true,
            system: true,
            name: 'Bác sĩ/Y tá',
            description: null,
        },
        {
            id: 15,
            code: 'LAWYER',
            index: 15,
            active: true,
            system: true,
            name: 'Luật sư',
            description: null,
        },
        {
            id: 16,
            code: 'ARCHITECT',
            index: 16,
            active: true,
            system: true,
            name: 'Kiến trúc sư',
            description: null,
        },
        {
            id: 17,
            code: 'TECHNICIAN',
            index: 17,
            active: true,
            system: true,
            name: 'Nhân viên kỹ thuật',
            description: null,
        },
        {
            id: 18,
            code: 'LOGISTICS_SPECIALIST',
            index: 18,
            active: true,
            system: true,
            name: 'Chuyên viên logistics',
            description: null,
        },
        {
            id: 19,
            code: 'BANKER',
            index: 19,
            active: true,
            system: true,
            name: 'Nhân viên ngân hàng',
            description: null,
        },
        {
            id: 20,
            code: 'CUSTOMER_SUPPORT_STAFF',
            index: 20,
            active: true,
            system: true,
            name: 'Nhân viên hỗ trợ khách hàng',
            description: null,
        },
        {
            id: 21,
            code: 'JOURNALIST_REPORTER',
            index: 21,
            active: true,
            system: true,
            name: 'Nhà báo/Phóng viên',
            description: null,
        },
        {
            id: 22,
            code: 'OFFICE_WORKER',
            index: 22,
            active: true,
            system: true,
            name: 'Nhân viên văn phòng',
            description: null,
        },
        {
            id: 23,
            code: 'TEACHER',
            index: 23,
            active: true,
            system: true,
            name: 'Giáo viên',
            description: null,
        },
        {
            id: 24,
            code: 'TRAINING_SPECIALIST',
            index: 24,
            active: true,
            system: true,
            name: 'Chuyên viên đào tạo',
            description: null,
        },
        {
            id: 25,
            code: 'ARTIST',
            index: 25,
            active: true,
            system: true,
            name: 'Nghệ sĩ',
            description: null,
        },
        {
            id: 26,
            code: 'DESIGNER',
            index: 26,
            active: true,
            system: true,
            name: 'Nhà thiết kế',
            description: null,
        },
        {
            id: 27,
            code: 'PARENT',
            index: 27,
            active: true,
            system: true,
            name: 'Phụ huynh',
            description: null,
        },
        {
            id: 28,
            code: 'SCIENTIST',
            index: 28,
            active: true,
            system: true,
            name: 'Nhà khoa học',
            description: null,
        },
        {
            id: 29,
            code: 'SALESPERSON',
            index: 29,
            active: true,
            system: true,
            name: 'Nhân viên bán hàng',
            description: null,
        },
        {
            id: 30,
            code: 'LEGAL_STAFF',
            index: 30,
            active: true,
            system: true,
            name: 'Nhân viên pháp lý',
            description: null,
        },
        {
            id: 31,
            code: 'STUDENT',
            index: 31,
            active: true,
            system: true,
            name: 'Học sinh/Sinh viên',
            description: null,
        },
    ],
};
