<script setup lang="ts">
import PersonalSidebarItem from './PersonalSidebarItem.vue';
import { computed } from 'vue';
import settingStore from '@/store/setting';
import appStore from '@/store/app';

defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  currentActive: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
  onAction: {
    type: Function,
    required: false,
    default: () => {
      return {};
    },
  },
  isCollapse: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['on-collapse']);

const _settingStore = settingStore();
const _appStore = appStore();
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
// show setting group
const gedEnabled = computed(() => _settingStore.gedEnabled);
const getMainPageLinkUrl = () => {
  return `/resources`;
};
</script>

<template>
  <div v-if="gedEnabled" v-permission="'Resources'" class="w-full">
    <PersonalSidebarItem
      icon-name="resources"
      :is-active="
        currentActive == 'resources' || currentActive.includes('resources')
      "
      :is-hidden-text="!isOpenMenuBar"
      :item-name="$t('SIDEBAR_LABEL_RESOURCES') || 'Resources'"
      :link-to="getMainPageLinkUrl()"
    />
  </div>
</template>
