<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
const delay = 1000;
const props = defineProps({
  totalMiniseconds: {
    type: Number, // minisecond
    required: true,
    default: 0,
  },
  start: {
    type: Boolean,
    required: true,
    default: false,
  },
  autoStart: {
    type: Boolean,
    required: false,
  },
  format: {
    type: String,
    default: 'hh:mm:ss',
  },
});

const emit = defineEmits(['onStop', 'onListenCurrentDistance']);

onMounted(() => {
  if (props.autoStart) countdown();
});
// Set the date we're counting down to
const hours = ref('00');
const minutes = ref('00');
const seconds = ref('00');
const distance = ref(0);
const calculatedWatch = () => {
  // Time calculations for days, hours, minutes and seconds
  // const _days = Math.floor(distance.value / (1000 * 60 * 60 * 24));
  const _hours = Math.floor(
    (distance.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const _minutes = Math.floor(
    (distance.value % (1000 * 60 * 60)) / (1000 * 60)
  );
  const _seconds = Math.floor((distance.value % (1000 * 60)) / 1000);
  // days.value = _days > 0 ? _days + 'd ' : '';
  hours.value = ('0' + _hours).slice(-2);
  minutes.value = ('0' + _minutes).slice(-2);
  seconds.value = ('0' + _seconds).slice(-2);
};
// Update the count down every 1 second
let intervalFunction, timeoutFunction;
const countdown = () => {
  const countDate = new Date().getTime();
  intervalFunction = setInterval(() => {
    // Get current time
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    distance.value = props.totalMiniseconds + delay + countDate - now;
    emit('onListenCurrentDistance', distance.value);

    calculatedWatch();
  }, 1000);
  timeoutFunction = setTimeout(function () {
    clearInterval(intervalFunction);
    emit('onStop', distance.value);
  }, props.totalMiniseconds + delay);
};

watch(
  () => props.start,
  () => {
    distance.value = 0;
    if (props.start) {
      countdown();
    } else {
      clearInterval(intervalFunction);
      clearTimeout(timeoutFunction);
      emit('onStop', distance.value);
      calculatedWatch();
    }
  }
);
onUnmounted(() => {
  clearInterval(intervalFunction);
  clearTimeout(timeoutFunction);
});
const isShowHours = computed(() => props.format?.includes('hh'));
</script>
<template>
  <div class="flex justify-center text-center">
    <!-- <span v-if="days" class="w-5">{{ days }}</span> -->
    <template v-if="isShowHours">
      <span class="w-5">{{ hours }}</span>
      <span class="w-1">:</span>
    </template>
    <span class="w-5">{{ minutes }}</span>
    <span class="w-1">:</span>
    <span class="w-5">{{ seconds }}</span>
  </div>
</template>
