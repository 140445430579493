<script setup lang="ts">
import { computed, ref } from 'vue';
import SynInput from '@/ui/common/atoms/SynInput/SynInput.vue';

const props = defineProps<{
  isActive: boolean;
  color: string;
  labelKey?: string;
  name: string;
  total?: number;
  readonly?: boolean;
  iconName?: string;
}>();
const emits = defineEmits(['onSelectTab', 'onDeleteTab', 'onChangeName']);

const isOpenTask = ref(false);

const onOpenEditTask = () => {
  if (props?.readonly) return;
  isOpenTask.value = true;
};

const onUpdateTask = () => {
  // console.log('value', nameTab.value);
  isOpenTask.value = false;
  emits('onChangeName', props.name);
};

const nameTab = ref<string>(props.name || '');
const mainColor = computed<string>(() => {
  return props.color || '#008E8E';
});
</script>

<template>
  <div
    class="container-tabs relative cursor-pointer flex items-center space-x-1"
    :style="
      isActive ? `background-color: ${mainColor}20; color: ${mainColor};` : ``
    "
    :class="[
      isActive ? 'font-medium' : 'text-gray-600 bg-gray-100 hover:bg-gray-200',
      total ? 'pr-1 pl-2 py-1.5' : 'px-2 py-1.5',
      isOpenTask ? 'rounded' : 'rounded-full',
    ]"
    @click="$emit('onSelectTab')"
  >
    <slot name="prefix"></slot>
    <template v-if="iconName">
      <SynIcon
        name="pin"
        is-active
        custom-class="w-5 h-5"
        :style="{
          fill: mainColor,
        }"
      />
    </template>
    <span
      v-if="!isOpenTask"
      class="truncate w-max"
      :class="!readonly ? '' : ''"
      :style="!readonly ? `border-color: ${mainColor}` : ''"
      @dblclick.stop="onOpenEditTask"
    >
      {{ name }}
    </span>
    <div
      v-if="isOpenTask && !readonly"
      class="flex-center space-x-1 mx-0.5 my-0.5"
    >
      <SynInput
        v-model="nameTab"
        custom-class="p-0.5 rounded-full text-xs w-max"
        autofocus
        @blur="onUpdateTask"
      />
    </div>
    <span
      v-if="total"
      class="px-1 rounded-full text-xs"
      style="font-size: 10.5px"
      :style="
        isActive
          ? `background-color: ${mainColor || '#008E8E'}; color: white;`
          : ''
      "
      :class="isActive ? '' : 'text-white bg-gray-400'"
    >
      {{ total }}
    </span>

    <div
      v-if="!readonly"
      class="w-4 h-4 flex-center tabs_close group cursor-pointer"
      @click.stop="$emit('onDeleteTab')"
    >
      <SynIcon
        name="close"
        custom-class="w-3 h-3 text-gray-500 group-hover:text-gray-800 "
      />
    </div>
  </div>
</template>

<style scoped>
.container-tabs {
}

.tabs_close {
  display: none;
  /* visibility: hidden;*/
}

.container-tabs:hover .tabs_close {
  display: flex;
  /* visibility: hidden;*/
}
</style>
