<template>
  <svg
    height="512"
    viewBox="0 0 96 96"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_4">
      <g>
        <path
          d="m88 23.073c0-7.845-6.359-14.204-14.204-14.204h-51.592c-7.845 0-14.204 6.359-14.204 14.204v31.301c0 7.844 6.359 14.204 14.204 14.204h22.669l18.806 17.625c.684.641 1.505.928 2.309.928 1.755 0 3.431-1.369 3.431-3.416v-15.138h4.377c7.845 0 14.204-6.36 14.204-14.204zm-5.682 31.301c0 4.699-3.823 8.522-8.522 8.522h-10.058v15.575l-16.619-15.575h-24.915c-4.699 0-8.522-3.823-8.522-8.522v-31.301c0-4.699 3.823-8.522 8.522-8.522h51.592c4.699 0 8.522 3.823 8.522 8.522z"
        />
        <path
          d="m48 19.405c-5.014 0-9.581 1.942-12.995 5.109-.041.036-.166.155-.189.18-3.657 3.486-5.943 8.398-5.943 13.838 0 10.547 8.581 19.128 19.128 19.128 5.448 0 10.367-2.293 13.854-5.96.019-.018.11-.114.138-.145 3.183-3.418 5.136-7.995 5.136-13.023-.001-10.547-8.582-19.127-19.129-19.127zm0 32.573c-7.414 0-13.446-6.032-13.446-13.446 0-2.886.917-5.56 2.471-7.753l18.729 18.728c-2.194 1.554-4.868 2.471-7.754 2.471zm11.6-6.658-18.388-18.388c1.994-1.171 4.313-1.846 6.788-1.846 7.414 0 13.446 6.032 13.446 13.446 0 2.475-.675 4.794-1.846 6.788z"
        />
      </g>
    </g>
  </svg>
</template>
