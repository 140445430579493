<template>
  <svg
    v-if="!isActive"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m21 5h-18a1 1 0 0 0 0 2h2v12a2.9991 2.9991 0 0 0 3 3h8a2.9989 2.9989 0 0 0 3-3v-12h2a1 1 0 0 0 0-2zm-4 14a1.0014 1.0014 0 0 1 -1 1h-8a1.0014 1.0014 0 0 1 -1-1v-12h10z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m10 4h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m11 16v-5a1 1 0 0 0 -2 0v5a1 1 0 0 0 2 0z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m15 16v-5a1 1 0 0 0 -2 0v5a1 1 0 0 0 2 0z"
      ></path>
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m21 5h-18a1 1 0 0 0 0 2h2v12a3.0009 3.0009 0 0 0 3 3h8a3.0008 3.0008 0 0 0 3-3v-12h2a1 1 0 0 0 0-2zm-10 11a1 1 0 0 1 -2 0v-5a1 1 0 0 1 2 0zm4 0a1 1 0 0 1 -2 0v-5a1 1 0 0 1 2 0z"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m10 4h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2z"
      ></path>
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
