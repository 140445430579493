import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import { MyOrganizationSingleton } from '@/application/services/organization/organization-service';

export default defineStore({
    id: storeId.embedOrganization,
    state: () => ({
        organizationInstance: MyOrganizationSingleton.getInstance(),
        activeOrganizationInfo: {} as any,
    }),
    getters: {},
    actions: {
        async getActiveOrganizationInfo() {
            this.activeOrganizationInfo =
                await this.organizationInstance.getActiveOrganization();
        },
    },
});
