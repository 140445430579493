import { stringNomalize } from '@/ui/hooks/commonFunction';

export const getShortName = (name: string) => {
    const conversationName =
        name?.split(' - ')?.length > 1 ? name?.split(' - ')[1] || name : name;
    const shortNameFull = stringNomalize(conversationName)
        ?.replaceAll(/[^a-zA-Z0-9]/g, ' ')
        ?.replaceAll(/\s+/g, ' ')
        ?.trim()
        ?.split(' ')
        .map((firstName) => firstName?.charAt(0))
        .join('');
    return shortNameFull?.length > 4
        ? `${shortNameFull?.substring(0, 3)}.${shortNameFull?.charAt(
              shortNameFull?.length - 1
          )}`
        : shortNameFull;
};
