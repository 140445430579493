import { computed } from 'vue';
import $constants from '@/ui/plugins/constants';
import { useRoute, useRouter } from 'vue-router';
import getUserInfo from '@/ui/helpers/user-helper';

export default function useFileItem(file) {
    const _route = useRoute();
    const _router = useRouter();

    const fileUrl = computed(() => {
        if (file.value?.contentType === $constants.FILE.TYPE.FOLDER) {
            return _router.resolve({
                ..._route,
                query: { ..._route.query, folder: file.value.id },
            }).href;
        }

        return (
            file.value?.link ||
            file.value?.pathUrl ||
            file.value?.path ||
            'javascript:'
        );
    });

    const fileCreator = computed(() => getUserInfo(file.value?.creatorId));

    return {
        fileUrl,
        fileCreator,
    };
}
