import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import EmbedTaskServices from '@/embed/services/task-services';

export default defineStore({
    id: storeId.embedTask,
    state: () => ({
        _taskService: EmbedTaskServices.getInstance(),
        myGroups: [] as any[],
        dataConfig: {} as any,
        currentCycle: '',
    }),
    getters: {},
    actions: {
        async fetchDataConfig(): Promise<any> {
            try {
                const res = await this._taskService.getDataConfig();
                // this.myGroups = res['result'];
                this.dataConfig = res.result;
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async getCurrentTaskCycle() {
            const res = await this._taskService.getCurrentTaskCycle();
            this.currentCycle = res?.result;
        },

        // create(newTask: TaskCreatePayload) {
        //   const payload = new TaskCreateUpdatePayload(newTask);
        //   return this._taskService.create(payload);
        // },
    },
});
