import { ref } from 'vue';
import { Unsubscribe } from '@firebase/firestore';
import { ChatSeenByMemberModel } from '@/application/models/chat/ChatConversationModel';
import notificationSettingStore from '@/store/notificationSetting/notificationSettingStore';
import ChatConversationRepository from '@/application/repositories/chat/ChatConversationRepository';
import myProfileStore from '@/store/auth/my-profile';

export default function useSeenBy(organizationId, conversationId) {
    const _negativeNotifByConversationTimer = {};

    let _unsubscribeSeenBy: Unsubscribe;

    const seenByMemberId = ref<{ [memberId: string]: ChatSeenByMemberModel }>(
        {}
    );

    const initSeenByFromCache = async (converId) => {
        return _getSeenByMembers(converId, true);
    };

    const initSeenByFromServer = (converId) => {
        return _getSeenByMembers(converId, false);
    };

    const subscribeSeenBy = (converId) => {
        if (_unsubscribeSeenBy) _unsubscribeSeenBy();

        _unsubscribeSeenBy = ChatConversationRepository.subscribeSeenByMembers(
            organizationId.value,
            converId,
            (snapshot) => {
                if (converId !== conversationId.value) return;

                const seenByMemberObj = { ...seenByMemberId.value };

                snapshot.docChanges().forEach((change) => {
                    switch (change?.type) {
                        case 'added':
                        case 'modified':
                            seenByMemberObj[change.doc.id] = _prepareSeenBy(
                                change.doc.id,
                                change.doc.data()
                            );
                            break;
                        case 'removed':
                            seenByMemberObj[change.doc.id] = {};
                            break;
                    }
                });

                seenByMemberId.value = seenByMemberObj;

                // _chatPanelStore.updateMembersSeenByInCache(
                //   converId,
                //   seenByMemberId.value
                // );
            }
        );
    };

    const setSeenMessage = async (converId, messageId) => {
        const userId = myProfileStore().myProfile?.id;

        const result = await ChatConversationRepository.updateSeenByMember(
            organizationId.value,
            converId,
            userId,
            messageId
        );

        _processNegativeNotification(converId);

        return result;
    };

    const resetSeenBy = () => {
        if (_unsubscribeSeenBy) _unsubscribeSeenBy();

        // seenByMemberId.value = {};
    };

    const _getSeenByMembers = async (converId, fromCache = false) => {
        const snapshots = await (fromCache
            ? ChatConversationRepository.getSeenByMembersFromLocal(
                  organizationId.value,
                  converId
              )
            : ChatConversationRepository.getSeenByMembersFromServer(
                  organizationId.value,
                  converId
              ));

        if (converId !== conversationId.value) return;

        const seenByMemberObj = {};

        snapshots?.forEach((doc) => {
            seenByMemberObj[doc.id] = _prepareSeenBy(doc.id, doc.data());
        });

        seenByMemberId.value = seenByMemberObj;

        // _chatPanelStore.updateMembersSeenByInCache(
        //   converId,
        //   seenByMemberId.value
        // );
    };

    const _prepareSeenBy = (msgId, seenBy) => {
        if (!seenBy) return null;

        return {
            ...seenBy,
            lastSeenMessageTime: seenBy?.lastSeenMessageTime?.toDate
                ? seenBy?.lastSeenMessageTime?.toDate()
                : seenBy?.lastSeenMessageTime,
        };
    };

    const _processNegativeNotification = (converId) => {
        clearTimeout(_negativeNotifByConversationTimer[converId]);

        _negativeNotifByConversationTimer[converId] = setTimeout(() => {
            notificationSettingStore().notificationNegative({
                sourceId: converId,
                type: 'chat',
            });
        }, 5000);
    };

    return {
        seenByMemberId,
        setSeenMessage,
        resetSeenBy,

        initSeenByFromCache,
        initSeenByFromServer,
        subscribeSeenBy,
    };
}
