import BaseRepository from '@/base/repositories/BaseRepository';
import { IUserRepository } from '@/domain/interfaces/repositories/IUserRepository';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { GoogleService } from '@/domain/enums/google.enum';

export default class UserRepository implements IUserRepository {
    private static instance: UserRepository;

    constructor() {}

    public static getInstance(): UserRepository {
        if (!UserRepository.instance) {
            // Get from local storage
            UserRepository.instance = new UserRepository();
        }

        return UserRepository.instance;
    }

    getShortInfos() {
        return BaseRepository.requestWithAuthorize({
            url: `User/ShortInfos`,
            method: 'get',
        });
    }

    getOrgUsers(orgId: number) {
        return BaseRepository.requestWithAuthorize({
            url: `User/ShortInfos?orgId=${orgId}`,
            method: 'get',
        });
    }

    getDeactivatedUsers() {
        return BaseRepository.requestWithAuthorize({
            url: `User/GetDeactivatedUsers`,
            method: 'get',
        });
    }

    getOrgDeactivatedUsers(orgId: number) {
        return BaseRepository.requestWithAuthorize({
            url: `User/GetDeactivatedUsers?orgId=${orgId}`,
            method: 'get',
        });
    }

    getUsersGroupsForChat(orgId?: number | null) {
        return BaseRepository.requestWithAuthorize({
            url: 'User/GetUsersGroupsForChat',
            method: 'get',
            params: { orgId },
        });
    }

    getDetail(userId) {
        return BaseRepository.requestWithAuthorize({
            url: `User/GetUserInfo?userId=${userId}`,
            method: 'get',
        });
    }

    userShortInfosIncludeDepartment() {
        return BaseRepository.requestWithAuthorize({
            url: `User/UserShortInfosIncludeDepartment`,
            method: 'get',
        });
    }

    getUserIdentity() {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetUserProfile`,
            method: 'get',
        });
    }

    searchUser(query) {
        return BaseRepository.requestWithAuthorize({
            url: `User/SearchUsers?${query}`,
            method: 'get',
        });
    }

    getUserByPublicMemberId(memberId: string) {
        return BaseRepository.requestWithAuthorize({
            url: 'User/GetUserByPublicMemberId',
            method: 'get',
            params: { id: memberId },
        });
    }

    getDomainListById(userId) {
        return BaseRepository.requestWithAuthorize({
            url: `Domain/GetDomainScopeList?userId=${userId}`,
            method: 'get',
        });
    }

    uploadAvatar(file) {
        const formData = new FormData();
        formData.append('fileData', file);

        return BaseRepository.requestWithAuthorize({
            url: `Media/UploadAvatarAws`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    async uploadAvatarUser(
        userId: number,
        avatar: string,
        defaultAvatar: boolean = true
    ): Promise<any> {
        return BaseRepository.requestWithAuthorize(
            {
                url: `User/ChangeAvatar`,
                params: {
                    defaultAvatar,
                },
                method: 'post',
                data: {
                    avatar: avatar,
                    userId: userId,
                },
            },
            {
                message: translate('API_ACTION_USER_CHANGE_AVATAR_SUCCESS'),
            }
        );
    }

    saveProfile(user, defaultAvatar = true) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `User/SaveProfileUser`,
                params: {
                    defaultAvatar,
                },
                method: 'post',
                data: user,
            },
            {
                message: translate(
                    user.id === 0 || user.id === undefined
                        ? 'API_ACTION_USER_CREATE_SUCCESS'
                        : 'API_ACTION_USER_UPDATE_SUCCESS'
                ),
            }
        );
    }

    deleteUser(userId, status) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `User/DeleteUser?userId=${userId}&status=${status}`,
                method: 'post',
            },
            {
                message: translate('API_ACTION_USER_DELETE_SUCCESS'),
            }
        );
    }

    saveUserScopesDto(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Domain/SaveUserScopesDto`,
                method: 'post',
                data: data,
            },
            {
                message: translate('API_ACTION_USER_UPDATE_SUCCESS'),
            }
        );
    }

    fetchAllPosition(lang = '') {
        return BaseRepository.requestWithAuthorize({
            url: `User/GetAllPosition?lang=${lang}`,
            method: 'get',
        });
    }

    updatePositionValue(payload) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `User/UpdatePositionValue`,
                method: 'post',
                data: payload,
            },
            {
                message: translate('API_ACTION_USER_UPDATE_SUCCESS'),
            }
        );
    }

    fetchAllProfile(lang = '') {
        return BaseRepository.requestWithAuthorize({
            url: `User/GetAllProfile?lang=${lang}`,
            method: 'get',
        });
    }

    changePassword(payload) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `Catalog/UpdatePassword`,
                method: 'post',
                data: payload,
            },
            {
                message: translate('API_ACTION_USER_CHANGE_PASSWORD_SUCCESS'),
            }
        );
    }

    updateStatusUser(userId, status) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `User/UpdateStatusUser?userId=${userId}&status=${status}`,
                method: 'post',
            },
            {
                message: translate('API_ACTION_USER_UPDATE_SUCCESS'),
            }
        );
    }

    setPassword(pass) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `User/SetPassword`,
                method: 'post',
                data: JSON.stringify(pass),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            { message: translate('API_ACTION_USER_CHANGE_PASSWORD_SUCCESS') }
        );
    }

    sendVerificationUserEmail() {
        return BaseRepository.requestWithAuthorize({
            url: `User/SendVerificationUserEmail`,
            method: 'post',
        });
    }

    changeUserPhone(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `User/ChangeUserPhone`,
                method: 'post',
                data: data,
            },
            {
                message: translate(
                    'API_ACTION_USER_CHANGE_PHONENUMBER_SUCCESS'
                ),
            }
        );
    }

    changeUserEmail(data) {
        return BaseRepository.requestWithAuthorize(
            {
                url: `User/ChangeUserEmail`,
                method: 'post',
                data: data,
            },
            {
                message: translate('API_ACTION_USER_CHANGE_EMAIL_SUCCESS'),
            }
        );
    }

    checkHasSendVerificationUserEmail() {
        return BaseRepository.requestWithAuthorize({
            url: `User/CheckHasSendVerificationUserEmail`,
            method: 'post',
        });
    }

    getUserGoogleAuthToken(service: GoogleService) {
        return BaseRepository.requestWithAuthorize({
            url: `User/GetUserGoogleAuthToken`,
            method: 'get',
            params: { service },
        });
    }

    exchangeGoogleAuthCodeForToken(service: GoogleService, code: string) {
        return BaseRepository.requestWithAuthorize({
            url: `User/ExchangeGoogleAuthCodeForToken`,
            method: 'post',
            params: { service },
            data: { code },
        });
    }

    deleteUserGoogleAuthToken(service: GoogleService) {
        return BaseRepository.requestWithAuthorize({
            url: `User/DeleteUserGoogleAuthToken`,
            method: 'delete',
            params: { service },
        });
    }

    getUserActivities(query) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetUserActivities?${query}`,
            method: 'get',
        });
    }

    getAllUserActivitiesByDate(query) {
        return BaseRepository.requestWithAuthorize({
            url: `Catalog/GetAllUserActivitiesByDate?${query}`,
            method: 'get',
        });
    }
}
