<template>
  <button
    :type="type"
    class="flex-center space-x-2 font-semibold disabled:opacity-50"
    :class="{
      [`${padding || (icon ? 'p-2' : 'py-2 px-4')}`]: true,
      [`${rounded}`]: true,
      [`bg-${colorObj?.bgColor} hover:bg-${colorObj?.bgColorHover}
        text-${colorObj?.textColor} fill-${colorObj?.fillColor}
        border border-${colorObj?.borderColor} hover:border-${colorObj?.borderColorHover}`]: true,
    }"
    :disabled="disabled || processing || processingRef"
  >
    <SynIcon
      v-if="processing || processingRef"
      name="Spinner"
      class="animate-spin"
    />
    <SynIcon v-if="icon && !(processing || processingRef)" :name="icon" />
    <slot>
      <span v-if="label">{{ $t(label) || label }}</span>
    </slot>
  </button>
</template>
<script>
import { computed, ref } from 'vue';

export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: 'current', // 'blue', 'red', 'orange'...
    },
    rounded: {
      type: String,
      default: 'rounded', // 'rounded-full'
    },
    padding: {
      type: String,
      default: '',
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup: (props) => {
    const processingRef = ref();

    const colorObj = computed(() => {
      if (props.ghost) {
        return {
          bgColor: 'transparent',
          bgColorHover: `${props.color}-100`,
          textColor: `${props.color}-600`,
          fillColor: `${props.color}-600`,
          borderColor: 'transparent',
          borderColorHover: `${props.color}-100`,
        };
      }

      if (props.light) {
        return {
          bgColor: `${props.color}-100`,
          bgColorHover: `${props.color}-200`,
          textColor: `${props.color}-600`,
          fillColor: `${props.color}-600`,
          borderColor: `${props.color}-100`,
          borderColorHover: `${props.color}-200`,
        };
      }

      if (props.outline) {
        return {
          bgColor: 'transparent',
          bgColorHover: `${props.color}-100`,
          textColor: `${props.color}-600`,
          fillColor: `${props.color}-600`,
          borderColor: `${props.color}-200`,
          borderColorHover: `${props.color}-500`,
        };
      }

      if (props.custom) {
        return null;
      }

      return {
        bgColor: `${props.color}-500`,
        bgColorHover: `${props.color}-600`,
        textColor: 'white',
        fillColor: 'white',
        borderColor: `${props.color}-500`,
        borderColorHover: `${props.color}-600`,
      };
    });

    return {
      processingRef,
      colorObj,
    };
  },
  methods: {
    setProcessing(value) {
      this.processingRef = value;
    },
  },
};
</script>
