<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import TaskSharingService from '@/application/services/task/TaskSharingService';
import { translate } from '@/ui/plugins/i18n/myi18n';

const props = defineProps<{
  task: any;
  taskSnapShots: any[];
  snapshot?: any;
}>();

const emit = defineEmits(['onClose', 'onSaved']);

const snapshotName = ref();
const isSaving = ref();

onMounted(() => {
  snapshotName.value = props.snapshot?.name || _generateSnapshotName();
});

const onFormSubmit = async () => {
  isSaving.value = true;

  try {
    const saveData: any = {
      name: snapshotName.value,
      taskId: props.task?.id,
    };

    if (props.snapshot?.sharedId) {
      saveData.sharedId = props.snapshot?.sharedId;
      saveData.taskId = props.snapshot?.taskId;
      saveData.status = props.snapshot?.status;
    }

    const result = await TaskSharingService.saveTaskSnapshot(saveData);

    emit('onSaved', result);
  } catch (e) {
    console.log(e);
  }

  isSaving.value = false;
};

const isValidName = (value) => {
  if (!value?.trim()) {
    return translate('TASK_SNAPSHOT_NAME_REQUIRED_MSG');
  }

  const limitChars = 200;
  if (value?.length > limitChars) {
    return translate('TASK_SNAPSHOT_LIMIT_CHAR_MSG', { limit: limitChars });
  }

  return true;
};

const _generateSnapshotName = () => {
  let maxNumber = 0;

  props.taskSnapShots?.forEach((snapshot) => {
    const number = parseInt(snapshot?.name?.split(' ')?.pop()) || 0;
    if (number > maxNumber) maxNumber = number;
  });

  return `Snapshot ${('0' + (maxNumber + 1)).slice(-2)}`;
};
</script>

<template>
  <SynModal
    container-class="w-11/12 max-w-md"
    container-style=""
    disable-click-outside
    @cancel="$emit('onClose')"
  >
    <template #header>
      <div v-if="snapshot?.sharedId">{{ $t('TASK_SNAPSHOT_EDIT') }}</div>
      <div v-else>{{ $t('TASK_SNAPSHOT_NEW') }}</div>
    </template>

    <template #body>
      <Form id="formNewFolder" @submit="onFormSubmit">
        <label for="field-snapshot-name" class="text-gray-500">{{
          $t('COMMON_LABEL_NAME')
        }}</label>
        <div>
          <Field
            id="field-snapshot-name"
            v-model="snapshotName"
            v-cusFocus="true"
            type="text"
            class="vig-input"
            name="fieldName"
            :rules="isValidName"
            :validate-on-blur="false"
            :validate-on-change="false"
            :validate-on-input="false"
            :validate-on-model-update="false"
          />
          <ErrorMessage
            name="fieldName"
            class="block mt-1 text-sm text-red-600"
          />
        </div>
      </Form>
    </template>

    <template #footer>
      <div class="px-3 py-2 flex justify-end space-x-2">
        <VigButton
          light
          color="gray"
          label="COMMON_LABEL_CANCEL"
          @click="$emit('onClose')"
        />
        <VigButton type="submit" form="formNewFolder" :processing="isSaving">
          {{ $t('COMMON_LABEL_SAVE') }}
        </VigButton>
      </div>
    </template>
  </SynModal>
</template>
