import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import MyConfigsIdbClass from '@/indexedDB/configs/my-configs.idb';

export default defineStore({
    id: storeId.LOCAL_CONFIGS,
    state: () => ({
        localConfigs: {} as any,
    }),
    getters: {},
    actions: {
        async loadAllLocalConfigs() {
            try {
                const dataInCache: any[] =
                    await MyConfigsIdbClass.getInstance().getAll();

                this.localConfigs = dataInCache.reduce(
                    (oldValue, currentValue) => {
                        return {
                            ...oldValue,
                            [currentValue?.code]: currentValue,
                        };
                    },
                    {}
                );
            } catch (error) {
                console.log('🚀 ~ getCachedFromIndexDb ~ error', error);
                return {};
            }
        },
        async setLocalConfigs(
            module: 'task' | 'chat' | 'calendar' | 'domain',
            data: any
        ) {
            // console.log('🚀 ~ setCachedFormData ~ key', key, formData);

            this.localConfigs[module] = {
                ...this.localConfigs[module],
                ...data,
            };
            try {
                await MyConfigsIdbClass.getInstance().saveConfig(
                    module,
                    this.localConfigs[module]
                );
            } catch (error) {
                console.log('🚀 ~ setCachedFormData ~ error', error);
            }
        },
        async removeLocalConfigsByCode(code: string) {
            try {
                await MyConfigsIdbClass.getInstance().remove(code);
            } catch (error) {
                console.log('🚀 ~ setCachedFormData ~ error', error);
            }
        },
    },
});
