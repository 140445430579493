<template>
  <svg
    v-if="isActive"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.75 36"
  >
    <path
      id="Icon_awesome-microphone"
      data-name="Icon awesome-microphone"
      d="M12.375,24.75A6.75,6.75,0,0,0,19.125,18V6.75a6.75,6.75,0,0,0-13.5,0V18A6.75,6.75,0,0,0,12.375,24.75ZM23.625,13.5H22.5a1.125,1.125,0,0,0-1.125,1.125V18a9.01,9.01,0,0,1-9.9,8.956,9.273,9.273,0,0,1-8.1-9.357V14.625A1.125,1.125,0,0,0,2.25,13.5H1.125A1.125,1.125,0,0,0,0,14.625v2.824A12.762,12.762,0,0,0,10.688,30.224v2.4H6.75A1.125,1.125,0,0,0,5.625,33.75v1.125A1.125,1.125,0,0,0,6.75,36H18a1.125,1.125,0,0,0,1.125-1.125V33.75A1.125,1.125,0,0,0,18,32.625H14.063V30.251A12.387,12.387,0,0,0,24.75,18V14.625A1.125,1.125,0,0,0,23.625,13.5Z"
    />
  </svg>
  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 36">
    <path
      id="Icon_awesome-microphone-slash"
      data-name="Icon awesome-microphone-slash"
      d="M44.565,32.21l-11.1-8.575A12.083,12.083,0,0,0,34.875,18V14.625A1.125,1.125,0,0,0,33.75,13.5H32.625A1.125,1.125,0,0,0,31.5,14.625V18a8.745,8.745,0,0,1-.754,3.53l-1.867-1.443A6.619,6.619,0,0,0,29.251,18V6.75a6.75,6.75,0,1,0-13.5,0V9.939L3.2.237a1.126,1.126,0,0,0-1.579.2L.237,2.211a1.125,1.125,0,0,0,.2,1.579L41.8,35.763a1.125,1.125,0,0,0,1.579-.2l1.381-1.777a1.126,1.126,0,0,0-.2-1.579Zm-16.44.415H24.188V30.251a12.243,12.243,0,0,0,2.367-.584l-3.523-2.723a9.067,9.067,0,0,1-1.431.014A9.106,9.106,0,0,1,13.783,19.8l-3.658-2.829v.482A12.762,12.762,0,0,0,20.813,30.224v2.4H16.875A1.125,1.125,0,0,0,15.75,33.75v1.125A1.125,1.125,0,0,0,16.875,36h11.25a1.125,1.125,0,0,0,1.125-1.125V33.75A1.125,1.125,0,0,0,28.125,32.625Z"
      transform="translate(0)"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
</script>
