export default class ProjectDetailClass {
    id: number;
    domainId: number;
    parentId: number | null;
    name: string;
    description: string;
    type: string;
    creationTime: string;
    createdProjectTotal: number;
    creatorId: number;
    lastModifierId: number;
    lastModificationTime: string;
    members: number[];
    anyProjectMembers: number[];
    groups: number[];
    subProjects: number[];

    constructor(data: any) {
        this.id = data?.id;
        this.domainId = data?.domainId;
        this.parentId = data?.parentId;
        this.name = data?.name;
        this.description = data?.description;
        this.type = data?.type;
        this.creationTime = data?.creationTime;
        this.createdProjectTotal = data?.createdProjectTotal;
        this.creatorId = data?.creatorId;
        this.lastModifierId = data?.lastModifierId;
        this.lastModificationTime = data?.lastModificationTime;
        this.members = data?.members;
        this.anyProjectMembers = data?.anyProjectMembers;
        this.groups = data?.groups;
        this.subProjects = data?.subProjects;
    }
}
