import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import TaskService from '@/application/services/task/TaskService';
import {
    CustomFilters,
    ITaskListItem,
} from '@/application/interfaces/tasks/task-interfaces';
import dayjs from 'dayjs';
import { TaskLife, TaskListTab } from '@/domain/enums/taskEnum';
import {
    convertUrgencyToImportant,
    convertUrgencyToPriority,
} from '@/ui/hooks/taskHook';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import taskListStore from '@/store/task/task-list-store';
import taskTabView from '@/store/task/task-tab-view';
import { EFilterTaskDefault } from '@/application/constants/task.const';
import taskStore from '@/store/task';

export default defineStore({
    id: storeId.TASK_SMART_VIEW,
    state: () => ({
        _taskService: TaskService.getInstance(),
        allTaskIds: [] as any[],
        allTaskDefaultList: [] as ITaskListItem[],
        allCollaboratorTasks: [] as ITaskListItem[],
        allPinnedTasks: [] as ITaskListItem[],
        listTaskIdBySourceType: {} as any,
        isLoading: false,
        lastUpdated: Date.now(),
        dataByState: {
            0: {
                isCollapse: false,
            },
            7: {
                isCollapse: false,
            },
            1: {
                isCollapse: false,
            },
            3: {
                isCollapse: false,
            },
            2: {
                isCollapse: false,
            },
            8: {
                isCollapse: false,
            },
            6: {
                isCollapse: false,
            },
            4: {
                isCollapse: false,
            },
            5: {
                isCollapse: false,
            },
        } as any,
    }),
    getters: {},
    actions: {
        updateDefaultList() {
            this.listTaskIdBySourceType = {
                DEFAULT: this.allTaskDefaultList
                    ?.filter((task) => {
                        return (
                            [0, 1, 2, 7].includes(task.taskLife) ||
                            dayjs(task?.finishedTime).isSame(Date.now(), 'd') ||
                            (task.taskLife !== TaskLife.Finished &&
                                dayjs(task?.lastModificationTime).isSame(
                                    Date.now(),
                                    'd'
                                ))
                        );
                    })
                    .map((t) => t?.id),
                DO_TODAY: this.allTaskDefaultList
                    ?.filter((task) => {
                        return task?.isPlannedToTreat === true;
                    })
                    .map((t) => t?.id),
                OVERDUE_DATE: this.allTaskDefaultList
                    ?.filter((task) => {
                        return task?.taskSchedule?.overdue > 0;
                    })
                    .map((t) => t?.id),
                MY_RELATED: this.allCollaboratorTasks?.map((t) => t?.id),
                PINNED: this.allPinnedTasks?.map((t) => t?.id),
                FINISHED: this.allTaskDefaultList
                    ?.filter((task) => {
                        return task?.taskLife === TaskLife.Finished;
                    })
                    .map((t) => t?.id),

                [EFilterTaskDefault.OTHERS]: this.allTaskDefaultList
                    ?.filter((task) => {
                        return (
                            task?.taskLife === TaskLife.Draft ||
                            task?.taskLife === TaskLife.Duplicated ||
                            task?.taskLife === TaskLife.Archived ||
                            task?.taskLife === TaskLife.Cancelled
                        );
                    })
                    .map((t) => t?.id),
            };
        },

        async getAllPinnedTasksForBoard(
            sourceType: 'U' | 'G' | 'D' | 'IA',
            sourceId: number
        ) {
            const currentParentTab = taskListStore().activeTab;
            const filterPayload = {
                isDefault: false,
                listOwnerId:
                    sourceType === 'U' &&
                    currentParentTab === TaskListTab.MyList
                        ? sourceId
                        : null,
                groupIds: sourceType === 'G' ? `${sourceId}` : null,
                domainIds: sourceType === 'D' ? `${sourceId}` : null,
                assignorId:
                    sourceType === 'U' &&
                    currentParentTab === TaskListTab.MyAssign
                        ? sourceId
                        : null,
            } as CustomFilters;
            const promiseList = [
                this._taskService.getPinnedTasks(filterPayload),
            ];
            if (taskTabView().boardShowFinishedTask)
                promiseList.push(
                    this._taskService.getPinnedTasks({
                        ...filterPayload,
                        isDefault: false,
                        status: '3',
                    })
                );
            const resAll: any[] = await Promise.all(promiseList);

            this.allPinnedTasks = resAll.reduce(
                (currentObject, currentItem) => {
                    return [
                        ...currentObject,
                        ...currentItem?.result?.filter(
                            (task) =>
                                !currentObject?.some((t) => t?.id == task?.id)
                        ),
                    ];
                },
                []
            );

            this.listTaskIdBySourceType.PINNED = this.allPinnedTasks?.map(
                (t) => t?.id
            );
        },

        async changePinTask(taskId, pinCode) {
            try {
                const payload = {
                    taskId: taskId,
                    color: pinCode,
                };
                const res = await TaskService.getInstance().changePin(payload);
                if (!res?.success) {
                    return Promise.resolve(false);
                }
                const updatedData: any = {
                    pinnedColor: pinCode,
                    lastModifierId: getCurrentUserId(),
                    lastModificationTime:
                        res?.result?.lastModificationTime || Date.now(),
                };

                taskStore().updateAllTaskByIds(taskId, updatedData);

                return Promise.resolve(true);
            } catch (error) {
                console.log('🚀 ~ error:', error);
            }
        },
        async changeUrgency(taskId, urgency) {
            try {
                const res = await TaskService.getInstance().changeUrgency(
                    taskId,
                    urgency
                );
                if (!res?.success) {
                    return Promise.resolve(false);
                }
                const updatedData: any = {
                    urgency,
                    priority: convertUrgencyToPriority(urgency),
                    important: convertUrgencyToImportant(urgency),
                    lastModifierId: getCurrentUserId(),
                    lastModificationTime:
                        res?.result?.lastModificationTime || Date.now(),
                };

                taskStore().updateAllTaskByIds(taskId, updatedData);

                return Promise.resolve(true);
            } catch (error) {
                console.log('🚀 ~ error:', error);
            }
        },

        deleteTask(id) {
            this.allTaskIds = this.allTaskIds?.filter(
                (taskId) => taskId !== id
            );
            this.allTaskDefaultList = this.allTaskDefaultList?.filter(
                (task) => task?.id !== id
            );
            this.allCollaboratorTasks = this.allCollaboratorTasks?.filter(
                (task) => task?.id !== id
            );
            this.allPinnedTasks = this.allPinnedTasks?.filter(
                (task) => task?.id !== id
            );
            this.updateDefaultList();
            this.lastUpdated = Date.now();
        },
        addTask(taskDetail) {
            taskStore().updateAllTaskByIds(taskDetail?.id, taskDetail);
            this.allTaskIds.push(taskDetail?.id);
            this.allTaskDefaultList.push(taskDetail);
            this.updateDefaultList();
            this.lastUpdated = Date.now();
        },
        addCollaboratorTask(taskDetail) {
            taskStore().updateAllTaskByIds(taskDetail?.id, taskDetail);
            this.allTaskIds.push(taskDetail?.id);
            this.allCollaboratorTasks.push(taskDetail);
            this.updateDefaultList();
            this.lastUpdated = Date.now();
        },
        updateTask(taskDetail) {
            taskStore().updateAllTaskByIds(taskDetail?.id, taskDetail);

            const index = this.allTaskDefaultList?.findIndex(
                (task) => task?.id == taskDetail?.id
            );
            if (index !== -1) this.allTaskDefaultList[index] = taskDetail;

            const index2 = this.allCollaboratorTasks?.findIndex(
                (task) => task?.id == taskDetail?.id
            );
            if (index2 !== -1) this.allCollaboratorTasks[index2] = taskDetail;

            const index3 = this.allPinnedTasks?.findIndex(
                (task) => task?.id == taskDetail?.id
            );
            if (index3 !== -1) this.allCollaboratorTasks[index3] = taskDetail;

            this.updateDefaultList();
            this.lastUpdated = Date.now();
        },

        updateDateByState(state, data) {
            this.dataByState[state] = data;
        },
    },
});
