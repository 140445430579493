<script setup lang="ts">
import { useRoute } from 'vue-router';
import {
  getUiByPlanState,
  EPlanAction,
  EPlanId,
} from '@/application/constants/plan.const';
import { computed, onMounted, ref } from 'vue';
import plansStore from '@/store/plans';
import { ELicenseType } from '@/application/types/plan.types';
import { loginAgain } from '@/ui/hooks/commonHook';
import appStore from '@/store/app';
import BaseNotificationBar from '@/ui/components/notification/BaseNotificationBar.vue';
import { EBaseNotificationBarKey } from '@/ui/components/notification/const/base-notification-bar.const';
import paymentStore from '@/store/payment';
import { IPaymentConfig } from '@/application/types/payment-configs.types';
import PlansOverviewModal from '@/ui/modules/settings/payments/overview/PlansOverviewModal.vue';
import myProfileStore from '@/store/auth/my-profile';
import userStore from '@/store/user';
import { AdminType } from '@/ui/common/constants/constant';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ActionByStateButton from '@/ui/modules/plan/ActionByStateButton.vue';
import OrganizationPlan from '@/domain/entities/payment/OrganizationPlan';
import organizationStore from '@/store/organization';
import { EPaymentPurpose } from '@/application/types/payment.types';
import { IOrganizationDetail } from '@/application/types/organization/organization.type';

// const HIDDEN_REQUEST_MODAL_IN_MILLISECONDS = 10 * 1000;
const _planStore = plansStore();
const route = useRoute();
const _appStore = appStore();

const _paymentStore = paymentStore();

const paymentConfigs = computed<IPaymentConfig>(
  () => _paymentStore.paymentConfigs
);
const isSettingPage = computed(
  () => route.name == 'PlansSetting' || route.name == 'UserManagement'
);

const isShowWarningAgain = ref(false);

ref;
let localStorageKey = '';
const notificationBarCaseId = computed<EBaseNotificationBarKey>(() => {
  return (
    EBaseNotificationBarKey[currentOrgPlan.value?.stateCode] ||
    currentOrgPlan.value?.stateCode
  );
});
onMounted(() => {
  localStorageKey = notificationBarCaseId.value
    ? `OPEN_MODAL_${notificationBarCaseId.value?.toUpperCase()}_AGAIN_SCHEDULE_AT`
    : '';

  const localStorageKeyToShowBar = notificationBarCaseId.value
    ? `OPEN_${notificationBarCaseId.value?.toUpperCase()}_AGAIN_SCHEDULE_AT`
    : '';

  const openBarAgainAt =
    parseInt(localStorage.getItem(localStorageKeyToShowBar) || '') || 0;
  isShowWarningAgain.value = Date.now() - openBarAgainAt >= 0;

  const openModalAgainScheduleAt =
    parseInt(localStorage.getItem(localStorageKey) || '') || 0;
  const isOpenModalAgain = Date.now() - openModalAgainScheduleAt >= 0;

  isOpenModal.value = !openModalAgainScheduleAt || isOpenModalAgain;
});
const organizationInfo = computed<IOrganizationDetail>(
  () => organizationStore().organizationInfo
);
const planCapacityByKeys = computed(() => _planStore.planCapacityByKeys);
const currentOrgPlan = computed<OrganizationPlan>(() => {
  return new OrganizationPlan(
    _planStore.currentOrgPlan,
    organizationInfo.value,
    paymentConfigs.value,
    planCapacityByKeys.value,
    _planStore.capacityByPlanIds
  );
});
const isLoadingCurrentPlan = computed<boolean>(
  () => _planStore.isLoadingCurrentPlan
);

const uiByPlanState = computed(() => {
  return getUiByPlanState(
    currentOrgPlan.value.planId,
    currentOrgPlan.value.stateCode
  );
});
const uiBySubState = computed(() => {
  const subState = currentOrgPlan.value.subStateCode || 'DEFAULT';
  if (
    !uiByPlanState.value ||
    !Object.prototype.hasOwnProperty.call(uiByPlanState.value, subState)
  )
    return null;

  return uiByPlanState.value[subState];
});
const isOpenModal = ref(false);
const handleClickLogout = async () => {
  _appStore.setIsLoading(true);
  loginAgain();
};

const isShowPlansOverview = ref<boolean>(false);
const canPayment = computed<any>(
  () => myProfileStore().isAdmin || myProfileStore().isOwner
);

const defaultOwnerInfo = ref<any>({});

const allActiveUsers = computed(() => userStore().allActiveUsers);
onMounted(() => {
  for (const user of allActiveUsers.value) {
    if (user?.adminType === AdminType.Owner) defaultOwnerInfo.value = user;
  }
});

const convertActionToPurpose = (defaultActionKey) => {
  if (defaultActionKey == EPlanAction.EXTEND) return EPaymentPurpose.EXTEND;

  return null;
};

const onMakePayment = () => {
  const paymentPurpose = convertActionToPurpose(
    uiByPlanState.value?.action?.defaultActionKey
  );

  if (!paymentPurpose) return;

  new OrganizationPlan(
    _planStore.currentOrgPlan,
    organizationStore().organizationInfo,
    paymentConfigs.value,
    _planStore.planCapacityByKeys,
    _planStore.capacityByPlanIds
  ).createPayment({
    planId: currentOrgPlan.value?.planId,
    purpose: paymentPurpose,
    period: null,
    totalUsers: null,
  });
};
</script>

<template>
  <BaseNotificationBar
    v-if="
      !isLoadingCurrentPlan &&
      currentOrgPlan?.license !== ELicenseType.LIFETIME &&
      isShowWarningAgain &&
      uiBySubState?.message?.title
    "
    :case-id="notificationBarCaseId"
    :is-force-open="currentOrgPlan?.subStateCode === 'INCOMING_EXPIRED_HIGHER'"
  >
    <div
      class="h-9 w-full flex-center relative px-2 space-x-2 shadow text-sm"
      :style="{
        color: uiBySubState?.style?.contrastColor,
        background: uiBySubState?.style?.mainColor,
      }"
    >
      <!-- <div v-if="currentOrgPlan?.offer?.remainDays > 0"> -->
      <div
        v-if="
          currentOrgPlan?.offer?.remainDays > 0 &&
          uiBySubState?.message?.titleWithBonus
        "
        v-html="
          $t(uiBySubState?.message?.titleWithBonus, {
            remainDays: currentOrgPlan?.remainDay || 5,
            bonusDays: currentOrgPlan?.offer?.bonusDays || 30,
            planId: currentOrgPlan?.planId,
          })
        "
      ></div>
      <div
        v-else
        v-html="
          $t(uiBySubState.message?.title, {
            remainDays: currentOrgPlan?.remainDay,
            planId: currentOrgPlan?.planId,
            bonusDays: currentOrgPlan?.offer?.bonusDays || 0,
          })
        "
      ></div>
      <template
        v-if="canPayment && currentOrgPlan?.planId !== EPlanId.PERSONAL"
      >
        <ActionByStateButton
          is-bar
          :ui-by-plan-state="uiByPlanState"
          :ui-by-sub-state="uiBySubState"
          @open-plan-overview="isShowPlansOverview = true"
          @make-payment="onMakePayment"
        />
      </template>
    </div>
  </BaseNotificationBar>

  <SynModal
    v-if="
      !isLoadingCurrentPlan &&
      currentOrgPlan?.license !== ELicenseType.LIFETIME &&
      !isSettingPage &&
      uiBySubState?.message?.hasPopup &&
      (uiBySubState?.message?.disableClose ||
        (!uiBySubState?.message?.disableClose && isOpenModal))
    "
    container-class="w-max"
    z-index="z-50"
    is-hidden-header
    is-hidden-footer
    :is-hidden-close="uiBySubState?.message?.disableClose"
    @cancel="isOpenModal = false"
  >
    <template #body>
      <div
        class="flex-center flex-col space-y-8 w-max"
        style="max-width: 32rem"
      >
        <div class="flex flex-col text-center space-y-4 px-4">
          <div class="flex-center p-4">
            <img
              v-if="uiBySubState?.message?.imageUrl"
              :src="uiBySubState?.message?.imageUrl"
              alt=""
              class="w-1/2 object-cover relative"
            />
            <SynIcon
              v-else
              :name="uiBySubState?.message?.iconName"
              :class="
                uiBySubState?.message?.iconName == 'warning'
                  ? 'fill-red-500'
                  : 'fill-orange-500'
              "
              custom-class="w-16 h-16"
            />
          </div>
          <span
            class="text-xl text-center break-normal mb-2"
            :style="{
              color:
                uiBySubState?.style?.contrastColor == '#FFF'
                  ? uiBySubState?.style?.mainColor
                  : uiBySubState?.style?.contrastColor,
            }"
            v-html="
              $t(uiBySubState?.message?.popupTitle, {
                planId: currentOrgPlan?.planId,
              })
            "
          >
          </span>

          <span
            v-if="!canPayment"
            class="text-gray-500 text-sm"
            v-html="
              translate('PLAN_NOTIFICATION_MESSAGE_NO_PERMISSION_PAYMENT', {
                ownerName: defaultOwnerInfo?.name || '',
                adminName: '',
              })
            "
          >
          </span>
          <span
            v-else
            class="
              text-center
              font-normal
              px-4
              text-gray-500 text-sm
              break-normal
            "
            v-html="
              $t(uiBySubState?.message?.popupBody, {
                planId: currentOrgPlan?.planId,
              })
            "
          >
          </span>
        </div>
        <div class="w-full flex-center space-x-2 pt-4">
          <AtomButton
            v-if="uiBySubState?.message?.disableClose"
            type="TEXT"
            :label="$t('COMMON_LABEL_LOGOUT') || 'Log out'"
            @click="handleClickLogout"
          >
            <span class="px-4 font-normal">
              {{ $t('COMMON_LABEL_LOGOUT') }}
            </span>
          </AtomButton>
          <template v-if="canPayment && !isSettingPage">
            <ActionByStateButton
              :ui-by-plan-state="uiByPlanState"
              :ui-by-sub-state="uiBySubState"
              @open-plan-overview="isShowPlansOverview = true"
              @make-payment="onMakePayment"
            />
          </template>
        </div>
      </div>
    </template>
  </SynModal>
  <PlansOverviewModal
    v-if="isShowPlansOverview"
    @on-close="isShowPlansOverview = false"
  />
</template>
