<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 281.332 281.332"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="M198.102,134.449c15.233-11.431,28.497-29.829,28.497-59.239v-0.753c0-18.694-6.274-34.93-18.649-48.258
      c-0.059-0.064-0.12-0.127-0.181-0.189C191.021,8.994,165.96,0,135.294,0H49.656c-4.971,0-9,4.029-9,9v263.332c0,4.971,4.029,9,9,9
      h90.331c29.385,0,54.297-7.214,72.043-20.863c18.741-14.414,28.647-35.157,28.647-59.988v-0.753
      C240.677,170.226,226.043,147.94,198.102,134.449z M140.709,236.624H86.813v-74.919h48.842c19.735,0,35.34,3.551,45.129,10.27
      c8.757,6.011,13.015,14.474,13.015,25.872v0.752C193.799,232.919,156.671,236.624,140.709,236.624z M130.58,117.372H86.813V44.709
      h45.955c29.839,0,46.952,12.351,46.952,33.886v0.752C179.719,112.432,148.932,117.372,130.58,117.372z"
        class=""
      ></path>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
