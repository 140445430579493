import { UserCreatePayload } from '@/domain/entities/PayloadClass/UserPayloadClass';
import { InstantMessagingType } from '@/ui/common/constants/constant';
export class UserDetail extends UserCreatePayload {
    id: number;
    firstName: string;
    lastName: string;
    login: string;
    avatar: string;
    avatarUrl: string;
    avatarThumbnailUrl: string;
    isShowInAdministration: boolean;
    active: boolean;
    timeOt: string;
    birthday: string;
    address: string;
    identityCard: string;
    description: string;
    holiday: string;
    email: string;
    skyper: string;
    phone: string;
    utc: string;
    isWorking: boolean;
    adminType: number;
    positionId: number;
    positionName: string;
    profileId: number;
    gender: number;
    password: string;
    notificationInfos: [
        {
            id: number;
            memberId: number;
            member: string;
            memberType: number;
            instantMessagingType: InstantMessagingType.Slack;
            url: string;
            phone: string;
            isOn: boolean;
        },
        {
            id: number;
            memberId: number;
            member: string;
            memberType: number;
            instantMessagingType: InstantMessagingType.Zalo;
            url: string;
            phone: string;
            isOn: boolean;
        }
    ];

    constructor(data: any) {
        super(data);
        this.id = data?.id;
        this.firstName = data?.firstName;
        this.lastName = data?.lastName;
        this.login = data?.login;
        this.avatar = data?.avatar;
        this.avatarUrl = data?.avatarUrl;
        this.avatarThumbnailUrl = data?.avatarThumbnailUrl;
        this.isShowInAdministration = data?.isShowInAdministration;
        this.active = data?.active;
        this.timeOt = data?.timeOt;
        this.birthday = data?.birthday;
        this.address = data?.address;
        this.identityCard = data?.identityCard;
        this.description = data?.description;
        this.holiday = data?.holiday;
        this.email = data?.email;
        this.skyper = data?.skyper;
        this.phone = data?.phone;
        this.utc = data?.utc;
        this.isWorking = data?.isWorking;
        this.adminType = data?.adminType;
        this.positionId = data?.positionId;
        this.positionName = data?.positionName;
        this.profileId = data?.profileId;
        this.gender = data?.gender;
        this.password = data?.password;
        this.notificationInfos = data?.notificationInfos;
    }
}

export class UserShortInfo {
    avatar: string;
    firstName: string;
    id: number;
    lastName: string;
    profilePictureThumbsUrl: string;
    profilePictureUrl: string;
    constructor(data: any) {
        this.avatar = data?.avatar;
        this.firstName = data?.firstName;
        this.id = data?.id;
        this.lastName = data?.lastName;
        this.profilePictureThumbsUrl = data?.profilePictureThumbsUrl;
        this.profilePictureUrl = data?.profilePictureUrl;
    }
}
