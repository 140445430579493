import { refresh } from '@/application/api/auth';
import {
    StorageConstant,
    getLocalStorage,
    setLocalStorage,
} from '@/ui/hooks/storageHook';

import {
    settingGlobalModal,
    ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { loginAgain } from '@/ui/hooks/commonHook';

let isRefreshing: boolean = false;
let refreshingCall: Promise<any>;
const getNewToken = async (token, refreshToken) => {
    try {
        const refreshingCall: any = await refresh({
            jwtToken: token,
            refreshToken: refreshToken,
        });
        const bearerToken = refreshingCall?.result?.jwtToken;

        setLocalStorage(StorageConstant.TOKEN, bearerToken);
        setLocalStorage(
            StorageConstant.REFRESH_TOKEN,
            refreshingCall?.result?.refreshToken
        );

        // error.config.headers['Authorization'] = bearerToken;

        // store.dispatch('auth/setRefreshingState', false);
        // store.dispatch('auth/setRefreshingCall', undefined);
        isRefreshing = false;

        return Promise.resolve(refreshingCall);
    } catch (error) {
        console.log('🚀 ~ getNewToken ~ error', error);
        settingGlobalModal({
            type: 'notification',
            title: '',
            content: translate('COMMON_NOTIFICATION_SESSION_HAS_EXPPIRED'),
            confirmable: true,
        });

        await ask();

        loginAgain();
    }
};

export const handleRefreshToken = async (cb) => {
    const token = getLocalStorage(StorageConstant.TOKEN);
    const refreshToken = getLocalStorage(StorageConstant.REFRESH_TOKEN);

    try {
        if (isRefreshing) {
            const chained = refreshingCall.then(cb);

            // store.dispatch('auth/setRefreshingCall', chained);
            refreshingCall = chained;

            return chained;
        }
        // store.dispatch('auth/setRefreshingState', true);
        isRefreshing = true;

        refreshingCall = getNewToken(token, refreshToken);

        // store.dispatch('auth/setRefreshingCall', refreshingCall);

        return refreshingCall;
    } catch (ex) {
        console.log('🚀 ~ handleRefreshToken ~ ex', ex);
    }
};
