import { defineStore } from 'pinia';
import storeId from '@/base/store/storeId';
import {
    getMyCallHistories,
    forceEndCallByJoinToken,
    actionOnRingingModal,
    participantAnswer,
} from '@/application/entities/VideoCallClass';
import {
    ActionOnCallType,
    AnswerState,
    CallDeviceState,
    CallType,
} from '@/domain/enums/VideoCallEnum';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

export default defineStore({
    id: storeId.conference,
    state: () => ({
        histories: [] as any[],
        historiesObjects: {},
    }),
    getters: {},
    actions: {
        getDetailById(id, callback) {
            new OrganizationDynamicModel(`audioVideoCall/${id}`).getOnce(
                (data) => {
                    callback && callback(data);
                }
            );
        },
        async getHistories() {
            const res: any = await getMyCallHistories();
            // this.histories = res?.result?.map((his) => {
            //   const lastConference = his?.historyDetail?.shift();
            //   return {
            //     ...lastConference,
            //     histotyDetail: his?.historyDetail,
            //   };
            // });
            const allHistories = res?.result;

            this.historiesObjects = allHistories.reduce((old, current) => {
                const conversationId = current?.participantIds
                    ?.sort()
                    .join('-');
                const newData = old[conversationId]
                    ? arrayOrderBy(
                          [...old[conversationId], current],
                          ['createdDate'],
                          ['desc']
                      )
                    : [current];
                return {
                    ...old,
                    [conversationId]: newData,
                };
            }, {});

            this.histories = arrayOrderBy(
                Object.keys(this.historiesObjects)
                    .map((his) => {
                        return this.historiesObjects[his]?.length > 0
                            ? {
                                  ...this.historiesObjects[his][0],
                                  id: his,
                              }
                            : {};
                    })
                    .filter((o) => o),
                ['createdDate'],
                ['desc']
            );
        },
        setHistories(data) {
            this.histories = data;
        },
        forceEndCall(joinToken) {
            return forceEndCallByJoinToken(joinToken);
        },
        actionOnModal(
            meetingId: string,
            joinToken: string,
            actionOnCallType: ActionOnCallType,
            callType: CallType,
            hosterId
        ) {
            return actionOnRingingModal(
                meetingId,
                joinToken,
                actionOnCallType,
                callType,
                hosterId
            );
        },
        answer(
            joinToken: string,
            answerState: AnswerState,
            cameraState: CallDeviceState
        ) {
            return participantAnswer(joinToken, answerState, cameraState);
        },
    },
});
