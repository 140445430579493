<script setup lang="ts">
import Draggable from '@/ui/components/calendars/Draggable.vue';
import VideoPlayerItem from '@/ui/components/contact-help/helps/VideoPlayerItem.vue';
import { computed, onMounted, ref } from 'vue';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';

const props = defineProps({
  videoActive: {
    type: Object,
    default: () => {},
  },
});

defineEmits(['onExpandVideo', 'onClose']);

const currentLanguage = ref('en');
const langs = ref({
  en: {
    key: 'en',
    label: 'SETTING_LANGUAGE_LABEL_ENGLISH',
    icon: 'flag-united-state',
    isActive: false,
  },
  vi: {
    key: 'vi',
    label: 'SETTING_LANGUAGE_LABEL_VIETNAMESE',
    icon: 'flag-vietnam',
    isActive: false,
  },
  fr: {
    key: 'fr',
    label: 'SETTING_LANGUAGE_LABEL_SEARCH',
    icon: 'flag-france',
    isActive: false,
  },
});
onMounted(() => {
  initData();
});

const sizePlayer = ref({
  isExpand: false,
  width: 450,
  height: 250,
  container: 'w-full h-full',
  bottom: '1px',
  right: '1px',
});

const handleSrcVideoYoutube = computed(() => {
  const srcOrigin = props?.videoActive[currentLanguage.value]?.url;
  if (!srcOrigin) return;
  if (srcOrigin?.includes('/embed')) return srcOrigin;
  return srcOrigin?.replace('https://youtu.be/', 'https://youtube.com/embed/');
});

const initData = () => {
  currentLanguage.value = getLocalStorage(StorageConstant.LANGUAGE) || 'vi';

  Object.values(langs?.value)?.forEach((o) => {
    if (!props?.videoActive[o.key]?.url) langs.value[o.key].isDisable = true;
  });

  if (!langs.value[currentLanguage.value].isDisable)
    langs.value[currentLanguage.value].isActive = true;
  else {
    ['vi', 'en', 'fr']?.forEach((lang) => {
      if (currentLanguage.value === lang && props?.videoActive[lang]?.url) {
        currentLanguage.value = lang;
        langs.value[lang].isActive = true;
        return;
      }
      if (props?.videoActive[lang]?.url) {
        currentLanguage.value = lang;
        langs.value[lang].isActive = true;
        return;
      }
    });
  }
};

const onChangeLanguageVideo = (lang) => {
  currentLanguage.value = lang?.key;
  Object.values(langs.value)?.forEach((el) => {
    el.isActive = el?.key === lang.key;
  });
};

const elementDrag = ref(null as any);
const onResize = () => {
  let elementDraggable: any;
  elementDraggable = document.getElementById('video-player-draggable');
  sizePlayer.value.isExpand = !sizePlayer.value.isExpand;
  sizePlayer.value.width = !sizePlayer.value.isExpand ? 450 : 1400;
  sizePlayer.value.height = !sizePlayer.value.isExpand ? 250 : 768;
  sizePlayer.value.container = !sizePlayer.value.isExpand
    ? 'w-full h-full'
    : 'w-full h-full';
  sizePlayer.value.bottom = !sizePlayer.value.isExpand ? '1px' : '50%';
  sizePlayer.value.right = !sizePlayer.value.isExpand ? '1px' : '50%';
  elementDraggable.style.transform = !sizePlayer.value.isExpand
    ? ''
    : 'translate(50%, 50%)';

  elementDrag.value.enableDragging(!sizePlayer.value.isExpand);
};
</script>

<template>
  <teleport to="body">
    <Draggable
      id="video-player-draggable"
      ref="elementDrag"
      class="shadow z-50 rounded pt-1"
      :style="[
        `bottom: ${sizePlayer.bottom} !important`,
        `right: ${sizePlayer.right} !important`,
        `background: #4cb0b0;`,
      ]"
    >
      <template #move>
        <VideoPlayerItem
          class="px-4 py-2"
          :src-video="
            handleSrcVideoYoutube || videoActive[currentLanguage]?.url
          "
          :title="videoActive[currentLanguage]?.name"
          :thumbnail="videoActive[currentLanguage]?.thumbnailUrl"
          is-pinned
          :is-disabled="langs[currentLanguage]?.isDisable"
          :width="sizePlayer.width"
          :height="sizePlayer.height"
          :video="videoActive"
          :style="[
            `bottom: ${sizePlayer.bottom} !important`,
            `right: ${sizePlayer.right} !important`,
          ]"
        >
          <template #header>
            <div class="flex items-center w-full pb-2 text-sm">
              <div class="flex items-center w-full space-x-2 justify-between">
                <div class="flex-center space-x-2">
                  <div
                    v-for="lang in Object.values(langs)"
                    :key="lang"
                    class="flex-center text-xs cursor-pointer rounded p-1"
                    :class="`${
                      lang?.isActive
                        ? 'bg-gray-100 text-white hover:bg-current-50'
                        : 'text-gray-600 bg-gray-100 bg-opacity-30 hover:bg-gray-200'
                    }`"
                    @click="onChangeLanguageVideo(lang)"
                  >
                    <SynIcon :name="lang?.icon" />
                  </div>
                </div>
                <div class="flex-center space-x-2">
                  <div
                    v-if="!sizePlayer.isExpand"
                    :title="$t('COMMON_LABEL_MOVE')"
                    class="
                      w-6
                      h-6
                      flex-center
                      rounded
                      hover:bg-gray-200
                      fill-gray-100
                      hover:fill-current-400
                      drag-handle
                    "
                  >
                    <SynIcon name="move" custom-class="w-4 h-4" />
                  </div>
                  <div
                    :title="$t('COMMON_LABEL_CLICK_TO_BACK_MENU')"
                    class="
                      w-6
                      h-6
                      flex-center
                      rounded
                      hover:bg-gray-200
                      fill-gray-100
                      hover:fill-current-400
                      cursor-pointer
                    "
                    @click="$emit('onExpandVideo', videoActive)"
                  >
                    <SynIcon name="back-menu" custom-class="w-4 h-4" />
                  </div>
                  <div
                    :title="
                      sizePlayer.isExpand
                        ? $t('COMMON_LABEL_CLICK_TO_COLLAPSE')
                        : $t('COMMON_LABEL_CLICK_TO_EXPAND')
                    "
                    class="
                      w-6
                      h-6
                      flex-center
                      rounded
                      hover:bg-gray-200
                      fill-gray-100
                      hover:fill-current-400
                      cursor-pointer
                    "
                    @click="onResize"
                  >
                    <SynIcon
                      :name="sizePlayer.isExpand ? 'minimize' : 'expand'"
                      custom-class="w-4 h-4"
                    />
                  </div>
                  <div
                    :title="$t('COMMON_LABEL_CLOSE')"
                    class="
                      w-6
                      h-6
                      flex-center
                      rounded
                      hover:bg-gray-200
                      fill-gray-100
                      hover:fill-current-400
                      cursor-pointer
                    "
                    @click="$emit('onClose', true)"
                  >
                    <SynIcon name="close" custom-class="w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #footer>
            <div
              class="flex flex-col py-3"
              :style="`${sizePlayer.isExpand ? 'width: 100%' : 'width: 450px'}`"
            >
              <span class="font-medium text-sm text-center text-gray-100">{{
                videoActive[currentLanguage]?.name
              }}</span>
              <!--              <span-->
              <!--                  v-if="videoActive[currentLanguage]?.description"-->
              <!--                  class="-->
              <!--                  text-center text-xs text-gray-100-->
              <!--                  p-2-->
              <!--                  mt-3-->
              <!--                  mx-2-->
              <!--                  rounded-->
              <!--                  italic-->
              <!--                "-->
              <!--              >-->
              <!--                {{ videoActive[currentLanguage]?.description }}-->
              <!--              </span>-->
            </div>
          </template>
        </VideoPlayerItem>
      </template>
    </Draggable>
  </teleport>
</template>
