<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g data-name="Layer 2">
        <path
          d="M16.377 10.373a2.749 2.749 0 0 1-1.944-4.694 2.814 2.814 0 0 1 3.888 0 2.747 2.747 0 0 1 0 3.888 2.729 2.729 0 0 1-1.944.806zm0-4a1.25 1.25 0 0 0-.884 2.134 1.281 1.281 0 0 0 1.768 0 1.25 1.25 0 0 0-.884-2.134z"
          opacity="1"
          class=""
        ></path>
        <path
          d="M10.72 22.685a3.742 3.742 0 0 1-2.652-1.1l-5.656-5.653a3.755 3.755 0 0 1 0-5.3l8.216-8.216a3.73 3.73 0 0 1 2.94-1.087l5.252.4a3.73 3.73 0 0 1 3.451 3.451l.4 5.252a3.74 3.74 0 0 1-1.087 2.94l-8.216 8.216a3.739 3.739 0 0 1-2.648 1.097zm2.559-19.872a2.247 2.247 0 0 0-1.59.659l-8.217 8.217a2.253 2.253 0 0 0 0 3.182l5.657 5.657a2.253 2.253 0 0 0 3.182 0l8.217-8.217a2.249 2.249 0 0 0 .652-1.764l-.4-5.252a2.239 2.239 0 0 0-2.08-2.071l-5.252-.4a1 1 0 0 0-.169-.011z"
          opacity="1"
          class=""
        ></path>
      </g>
    </g>
  </svg>
</template>
