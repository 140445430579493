<script setup lang="ts">
import GroupReaction from '@/ui/modules/messaging/chat-panel/chat-message/group-message/GroupReaction.vue';
import MessageCreatedTime from '@/ui/modules/messaging/chat-panel/chat-message/message-date/MessageCreatedTime.vue';
import { computed } from 'vue';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import UserByAction from '@/ui/components/user/UserByAction.vue';

const props = defineProps<{
  message: any;
  members: any;
  isDisabled?: boolean;
  allowedActions?: ChatMessageAction[];
}>();

defineEmits(['onReactionAdd', 'onReactionsClick']);

const hasReactions = computed(
  () => Object.values(props.message?.reactions || {}).length
);
</script>

<template>
  <div>
    <div
      v-if="message?.type === $constants.MESSAGE.TYPE.MEMBER_REMOVED"
      class="px-3 text-sm text-gray-500 text-center"
    >
      <span>
        {{
          $t('CHAT_MEMBER_LEFT', { name: message?.text }) ||
          `${message?.text} has left conversation`
        }}</span
      >
    </div>

    <div
      v-else-if="message?.type === $constants.MESSAGE.TYPE.MEMBER_JOINED_ORG"
      class="px-3 flex-center"
    >
      <div
        class="
          flex flex-col
          gap-0.5
          bg-gray-100
          rounded-3xl
          px-4
          py-2
          relative
          group/mem-join
        "
      >
        <div class="flex-center gap-2 text-sm">
          <div class="font-semibold">
            <UserByAction
              :user-id="message?.member?.id"
              :is-show-action-user="
                allowedActions?.includes(ChatMessageAction.USER_ACTIONS)
              "
            />
          </div>
          <div>{{ $t('CHAT_MSG_USER_JOINED_ORG') }}</div>
        </div>
        <div class="w-full flex items-end justify-end gap-2">
          <GroupReaction
            v-if="
              !isDisabled &&
              allowedActions?.includes(ChatMessageAction.REACTION)
            "
            :reactions="message?.reactions"
            :members="members"
            :class="{
              'absolute -bottom-1 right-4 invisible group-hover/mem-join:visible':
                !hasReactions,
            }"
            @on-reaction-add="$emit('onReactionAdd', $event)"
            @on-reactions-click="$emit('onReactionsClick', $event)"
          />
          <MessageCreatedTime
            :message="message"
            class="text-2xs text-gray-500"
          />
        </div>
      </div>
    </div>
  </div>
</template>
