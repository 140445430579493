import { RemoteConfigKey } from '@/ui/plugins/firebases/firebase-remote-config';
import {
    ask,
    settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import remoteConfigStore from '@/store/remoteConfig';
import InvalidFilesLimitation from '@/ui/components/limitation/InvalidFilesLimitation.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { isAudio, isImage, isOffice, isVideo } from '@/ui/helpers/file-utils';

export default function useLimitation(module: 'CHAT' | 'TASK' | 'STORAGE') {
    const _remoteConfigStore = remoteConfigStore();

    const verifySelectedFiles = (currentFiles, selectedFiles) => {
        // Show popup if has exceed limit total files
        const limitTotal = _checkIfExceedLimitTotalFile(
            currentFiles,
            selectedFiles,
            module
        );
        if (limitTotal) {
            return _showPopupExceedLimitTotalFile(limitTotal, module);
        }

        const validFiles: any[] = [];
        const invalidFiles: any[] = [];

        for (const file of selectedFiles) {
            if (_checkFileSizeValid(file, module)) validFiles.push(file);
            else invalidFiles.push(file);
        }

        // Show popup if has invalid size files
        if (invalidFiles?.length > 0) {
            _showPopupExceedLimitFileSize(invalidFiles);
        }

        return validFiles;
    };

    const verifyNote = (currentNotes) => {
        // Show popup if has exceed limit total notes
        const limitTotal = _checkIfExceedLimitTotalNote(currentNotes, module);
        if (limitTotal) {
            _showPopupExceedLimitTotalNote(limitTotal, module);

            return false;
        }

        return true;
    };

    // PRIVATE

    const _checkIfExceedLimitTotalFile = (currentFiles, newFiles, module) => {
        if (!newFiles?.length) return 0;

        const configKey = _getConfigKeyOfFileLimitTotal(module);
        if (!configKey) return 0;

        const limitTotal = _remoteConfigStore
            .getRemoteConfig(configKey)
            .asNumber();
        if (!limitTotal) return 0;

        return currentFiles?.length + newFiles?.length > limitTotal
            ? limitTotal
            : 0;
    };

    const _checkIfExceedLimitTotalNote = (currentNotes, module) => {
        const configKey = _getConfigKeyOfNoteLimitTotal(module);
        if (!configKey) return false;

        const limitTotal = _remoteConfigStore
            .getRemoteConfig(configKey)
            .asNumber();
        if (!limitTotal) return false;

        return currentNotes?.length >= limitTotal;
    };

    const _checkFileSizeValid = (file, module) => {
        if (!file) return false;

        const fileType = file.type;
        const fileSize = file.size;

        const configKey = _getConfigKeyOfFileLimitSize(module, fileType);
        if (!configKey) return true;

        const limitSize = _remoteConfigStore
            .getRemoteConfig(configKey)
            .asNumber();
        if (!limitSize) return true;

        return fileSize <= limitSize * 1024 * 1024;
    };

    const _getConfigKeyOfFileLimitSize = (module, fileType) => {
        switch (module) {
            case 'CHAT':
                if (isImage(fileType)) {
                    return RemoteConfigKey.CommonChatFileImageSizeLimit;
                }
                if (isAudio(fileType)) {
                    return RemoteConfigKey.CommonChatFileAudioSizeLimit;
                }
                if (isVideo(fileType)) {
                    return RemoteConfigKey.CommonChatFileVideoSizeLimit;
                }
                if (isOffice(fileType)) {
                    return RemoteConfigKey.CommonChatFileOfficeSizeLimit;
                }

                return RemoteConfigKey.CommonChatFileSizeLimit;

            case 'TASK':
                return RemoteConfigKey.WebTaskUploadMaxFilesize;

            case 'STORAGE':
                return null;

            default:
                return null;
        }
    };

    const _getConfigKeyOfFileLimitTotal = (module) => {
        switch (module) {
            case 'CHAT':
                return RemoteConfigKey.CommonChatFileQuantityLimit;

            case 'TASK':
                return null;

            case 'STORAGE':
                return null;

            default:
                return null;
        }
    };

    const _getConfigKeyOfNoteLimitTotal = (module) => {
        switch (module) {
            case 'CHAT':
                return RemoteConfigKey.CommonChatNoteQuantityLimit;

            case 'TASK':
                return null;

            case 'STORAGE':
                return null;

            default:
                return null;
        }
    };

    const _showPopupExceedLimitTotalFile = (limitTotal, module) => {
        let content;
        switch (module) {
            case 'CHAT':
                content =
                    translate('CHAT_FILE_LIMIT_EXCEED_MSG', {
                        number: limitTotal,
                    }) ||
                    `Tictop lets you send up to ${limitTotal} files in a message`;
                break;
            default:
                break;
        }

        settingGlobalModal({
            type: 'notification',
            title:
                translate('COMMON_MSG_NUMBER_FILE_EXCEED') ||
                'Maximum number of files exceeded',
            content,
            confirmable: true,
        });
        ask();
    };

    const _showPopupExceedLimitTotalNote = (limitTotal, module) => {
        let content;
        switch (module) {
            case 'CHAT':
                content =
                    translate('CHAT_NOTE_LIMIT_EXCEED_MSG', {
                        number: limitTotal,
                    }) ||
                    `Tictop lets you send up to ${limitTotal} notes in a message`;
                break;
            default:
                break;
        }

        settingGlobalModal({
            type: 'notification',
            title:
                translate('COMMON_MSG_NUMBER_NOTE_EXCEED') ||
                'Maximum number of notes exceeded',
            content,
            confirmable: true,
        });
        ask();
    };

    const _showPopupExceedLimitFileSize = (invalidFiles) => {
        settingGlobalModal({
            type: 'notification',
            title: '',
            contentComponent: InvalidFilesLimitation,
            contentComponentProps: {
                module,
                invalidFiles,
            },
            confirmable: true,
        });
        ask();
    };

    return {
        verifySelectedFiles,
        verifyNote,
    };
}
