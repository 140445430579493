<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { colorByAlphabet, convertViToEn, cleanString } from './colorByAlphabet';

const props = withDefaults(
  defineProps<{
    src: string;
    name?: string;
    type?: string;
    subName?: string;
    hasName?: boolean;
    status?: string;
    customClass?: string;
  }>(),
  {
    customClass: 'h-8 w-8',
  }
);

const isError = ref(false);

const onError = () => {
  isError.value = true;
  srcImg.value = props.src?.replace('mini', 'original');
};

const statusColor = computed(() => {
  if (props.status == 'offline') {
    return 'bg-gray-300';
  }
  if (props.status == 'absent') {
    return 'bg-orange-400';
  }
  return 'bg-green-400';
});

const srcImg = ref(props.src);

watch(
  () => props.src,
  () => {
    srcImg.value = props.src?.replace('original', 'mini');
  }
);

const textAva = computed(() => {
  if (!props?.name) return 'A';
  return cleanString(props.name)
    .split(' ')
    [cleanString(props.name).split(' ').length - 1].charAt(0);
});
</script>
<template>
  <div class="relative" :class="customClass">
    <img
      v-if="srcImg"
      class="w-full h-full object-cover rounded-full shadow"
      :class="customClass"
      :src="srcImg"
      :onError="onError"
    />
    <div
      v-else
      class="object-cover flex-center text-white shadow font-thin rounded-full"
      :class="`${customClass}`"
      :style="`background: ${
        colorByAlphabet[convertViToEn(textAva, true)] || '#4bb0b2'
      }`"
    >
      <span v-if="name" class="uppercase text-base">{{ textAva }}</span>
      <SynIcon
        v-else
        :name="type == 'user' ? 'user' : 'group'"
        class="fill-white text-white"
      />
    </div>
    <slot></slot>
    <div
      class="
        absolute
        bottom-0
        right-0
        w-1/4
        h-1/4
        my-1
        border border-white
        rounded-full
        z-2
      "
      :class="statusColor"
    ></div>
  </div>
</template>
