import { UserShortInfo } from './UserPresentClass';

export default class DomainClass {
    creationTime: string;
    creatorId: number;
    description: string;
    id: number;
    lastModificationTime: string;
    lastModifierId: number;
    name: string;
    creator: UserShortInfo;
    type: string;
    members: any;
    groups: any;
    projects: any;
    constructor(data: any) {
        this.creationTime = data?.creationTime;
        this.creatorId = data?.creatorId;
        this.description = data?.description;
        this.id = data?.id;
        this.lastModificationTime = data?.lastModificationTime;
        this.lastModifierId = data?.lastModifierId;
        this.name = data?.name;
        this.creator = new UserShortInfo(data?.creator);
        this.type = data?.type || 'domain';
        this.members = data?.members;
        this.groups =
            data?.groups?.length > 0
                ? data?.groups.reduce((old, current) => {
                      return {
                          ...old,
                          [current.id]: current,
                      };
                  }, {})
                : {};

        this.projects =
            data?.projects?.length > 0
                ? data?.projects.reduce((old, current) => {
                      return {
                          ...old,
                          [current.id]: current,
                      };
                  }, {})
                : {};
    }
}
