<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <g fill-rule="evenodd" clip-rule="evenodd">
        <path
          fill="#e92b2b"
          d="M256 32C132.288 32 32 132.288 32 256s100.288 224 224 224 224-100.288 224-224S379.712 32 256 32z"
          opacity="1"
          data-original="#e92b2b"
          class=""
        ></path>
        <path
          fill="#fbfbfb"
          d="M450.034 368H61.969a225.026 225.026 0 0 0 45.866 56h296.333a225.025 225.025 0 0 0 45.866-56zM480 256H32c0 19.337 2.45 38.101 7.057 56h433.886c4.607-17.899 7.057-36.663 7.057-56zM450.038 144H61.973a222.623 222.623 0 0 0-22.91 56h433.886a222.614 222.614 0 0 0-22.911-56zM404.145 88H107.812C147.3 53.148 199.17 32 255.979 32s108.678 21.148 148.166 56z"
          opacity="1"
          data-original="#fbfbfb"
          class=""
        ></path>
        <path
          fill="#2a2faf"
          d="M233.606 33.105V256H32c0-116.154 88.409-211.659 201.606-222.894z"
          opacity="1"
          data-original="#2a2faf"
        ></path>
        <path
          fill="#fbfbfb"
          d="M66.158 137.078h9.562l-13.166 9.566 5.029 15.478L56.5 154.07a223.765 223.765 0 0 1 9.658-16.992zM37.313 207.332a220.2 220.2 0 0 1 .7-3.057h11.366l5.03-15.478 5.028 15.478h16.275l-13.167 9.566 5.03 15.478-13.167-9.566-13.166 9.566 5.029-15.478zM111.795 84.587a224.778 224.778 0 0 1 19.531-14.712h11.582l-13.167 9.566 5.029 15.478-13.166-9.566-13.166 9.566z"
          opacity="1"
          data-original="#fbfbfb"
          class=""
        ></path>
      </g>
      <path
        fill="#fbfbfb"
        d="m121.616 121.602 5.029 15.478h16.275l-13.167 9.566 5.03 15.478-13.167-9.566-13.166 9.566 5.029-15.478-13.167-9.566h16.275zM121.616 188.797l5.029 15.478h16.275l-13.167 9.566 5.03 15.478-13.167-9.566-13.166 9.566 5.029-15.478-13.167-9.566h16.275zM188.804 54.398l5.029 15.478h16.274l-13.166 9.566 5.029 15.478-13.166-9.566-13.167 9.566 5.029-15.478-13.166-9.565h16.275zM188.804 121.602l5.029 15.478h16.274l-13.166 9.566 5.029 15.478-13.166-9.566-13.167 9.566 5.029-15.478-13.166-9.566h16.275zM188.804 188.797l5.029 15.478h16.274l-13.166 9.566 5.029 15.478-13.166-9.566-13.167 9.566 5.029-15.478-13.166-9.566h16.275z"
        opacity="1"
        data-original="#fbfbfb"
        class=""
      ></path>
    </g>
  </svg>
</template>
