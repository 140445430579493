<script setup lang="ts">
import { computed, ref } from 'vue';
import { ignoreUnicode } from '@/ui/plugins/utils';
import UserById from '@/ui/components/user/UserById.vue';
import { AdminType } from '@/ui/common/constants/constant';
import myProfileStore from '@/store/auth/my-profile';

const props = withDefaults(
  defineProps<{
    currentId?: string | number;
    type?: 'user' | 'group';
    placeholder?: string;
    data?: any[];
    inputClass?: string;
    dropdownClass?: string;
    objectContact?: object;
    name?: string;
    avatar?: string;
    removeable?: boolean;
    assignGroup?: boolean;
    isHiddenGroup?: boolean;
    functionCode?: string;
  }>(),
  {
    currentId: '',
    type: 'user',
    placeholder: '',
    data: undefined,
    objectContact: undefined,
    name: '',
    avatar: '',
    inputClass:
      'border border-gray-300 py-2 px-3 rounded-md focus:outline-none focus:shadow-outline',
    dropdownClass:
      'absolute z-50 bg-white border border-gray-300 mt-1 mh-48 overflow-hidden overflow-y-auto rounded-md shadow-md',
  }
);
const emit = defineEmits(['chosen', 'reset', 'blur', 'focus']);
const currentUser = computed(() => myProfileStore().myProfile);
const isInputting = ref(false);
const showOptions = ref(false);

const searchTerm = ref('');
const searchResults = computed(() => {
  const searchTxt = ignoreUnicode(searchTerm.value);

  if (!searchTxt) return props.data;

  return (props.data || []).filter(
    (item: any) => item?.name && ignoreUnicode(item?.name).includes(searchTxt)
  );
  // return props.data?.filter((item) => {
  //   return item.name.toLowerCase().includes(searchTerm.value.toLowerCase());
  // });
}) as any;
const reset = () => {
  emit('reset');
  searchTerm.value = '';
  onOpenAssign();
};

const chooseItem = (item) => {
  emit('chosen', item);
  showOptions.value = false;
  isInputting.value = false;
  searchTerm.value = '';
  // $refs.input.focus();
};

const clickedOutside = () => {
  showOptions.value = false;
  isInputting.value = false;
};

const inputRef = ref<any>(null);
const onOpenAssign = () => {
  indexResult.value = -1;
  showOptions.value = true;
  isInputting.value = true;

  inputRef.value?.focus();

  emit('focus');
};

const onClickInput = () => {
  indexResult.value = -1;
  showOptions.value = true;
};

const keyActive = ref('search-assign');
const indexResult = ref(-1);
const onKeyDownSearch = () => {
  indexResult.value++;
  if (indexResult.value == searchResults.value.length) {
    indexResult.value = 0;
  }
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
const onKeyUpSearch = () => {
  indexResult.value--;
  keyActive.value = `search-assign-${indexResult.value}`;
  if (indexResult.value < 0) {
    indexResult.value = searchResults.value.length - 1;
  }
  keyActive.value = `search-assign-${indexResult.value}`;
  let element = document.getElementById(`search-assign-${indexResult.value}`);
  element?.scrollIntoView({ block: 'center' });
};
const onChooseResult = () => {
  if (indexResult.value < 0 || searchResults.value?.length == 0) return;

  let item = searchResults.value[indexResult.value];
  emit('chosen', item);
  showOptions.value = false;
  isInputting.value = false;
  searchTerm.value = '';
};

const onBlur = () => {
  isInputting.value = false;
  emit('blur');
};

const vigDropdownRef = ref<any>(null);
const onForceOpen = () => {
  if (
    vigDropdownRef.value &&
    typeof vigDropdownRef.value.onForceOpen == 'function'
  )
    vigDropdownRef.value.onForceOpen();
};
</script>

<template>
  <VigDropdown
    ref="vigDropdownRef"
    :arrow="false"
    hide-on-click="outside"
    :z-index="60"
    @on-dropdown-open="onOpenAssign"
    @on-dropdown-close="clickedOutside"
    @click.stop="onOpenAssign"
  >
    <template #dropdown-toggle>
      <div class="relative flex-center py-1" @click="showOptions = true">
        <div
          v-permission-function="{
            functionCode: functionCode,
            onContinue: () => {
              onForceOpen();
            },
          }"
          class="flex-1 flex items-center"
        >
          <div class="w-6 h-6 absolute left-2">
            <div class="relative flex items-center">
              <UserById
                v-if="type === 'user'"
                :user-id="currentId"
                is-hidden-name
                avatar-class="w-6 h-6"
                class="w-6 h-6 syn-select-assign-item"
              />

              <SynAvatar
                v-else
                :src="avatar"
                :name="name"
                :type="type"
                custom-class="w-6 h-6"
              />
              <div
                v-if="
                  objectContact &&
                  type === 'group' &&
                  objectContact['isPrivate']
                "
                :title="
                  objectContact['isPrivate']
                    ? $t('COMMON_LABEL_GROUP_PRIVATE')
                    : $t('COMMON_LABEL_GROUP_PUBLIC')
                "
                class="
                  flex-center
                  bg-white
                  absolute
                  -bottom-1
                  -right-1
                  p-0.5
                  rounded-full
                "
              >
                <SynIcon
                  class="fill-current"
                  custom-class="w-2 h-2"
                  :name="objectContact['isPrivate'] ? 'private' : 'global'"
                />
              </div>
            </div>
          </div>

          <input
            v-if="isInputting"
            ref="inputRef"
            v-model="searchTerm"
            tabindex="0"
            class="
              h-8
              w-44
              pl-12
              rounded
              bg-gray-200
              focus:bg-white focus:outline-none focus:border-transparent
              border-0
              focus:ring-0
            "
            :placeholder="placeholder"
            @focus="onClickInput"
            @blur="onBlur"
            @keyup.down="onKeyDownSearch"
            @keyup.up="onKeyUpSearch"
            @keyup.enter="onChooseResult"
          />
          <span
            v-else
            :title="name ? name : ''"
            class="
              text-sm text-gray-500
              h-8
              w-44
              rounded
              cursor-pointer
              hover:bg-gray-100
              focus:bg-white focus:outline-none focus:border-transparent
              flex
              items-center
              pl-10
              pr-8
              text-overflow-hidden-line
            "
            :class="inputClass"
            @click="onOpenAssign()"
          >
            <span v-if="name" class="truncate text-overflow-hidden-line">{{
              name
            }}</span>
            <span v-else>
              {{
                assignGroup
                  ? $t(
                      isHiddenGroup
                        ? 'COMMON_LABEL_GROUP_PRIVATE2'
                        : 'COMMON_LABEL_GROUP'
                    )
                  : placeholder
                  ? placeholder
                  : $t('TASK_LABEL_ASSIGNEE')
              }}
            </span>
          </span>
        </div>

        <div
          v-if="name && removeable"
          v-permission-function="{
            functionCode: functionCode,
            onContinue: () => {
              reset();
            },
          }"
          class="
            flex-center
            h-4
            w-4
            cursor-pointer
            hover:bg-current-50
            absolute
            right-2
            rounded-full
          "
          @click.stop="reset"
        >
          <SynIcon custom-class="h-3 w-3" name="close" />
        </div>
      </div>
    </template>
    <template v-if="showOptions" #dropdown-menu>
      <div class="flex flex-col">
        <div style="width: 12rem">
          <slot name="header"></slot>
        </div>
        <ul
          class="z-100 w-max rounded-md small-scrollbar overflow-auto syn p-2"
          style="width: 12rem; max-height: 24rem"
        >
          <li
            v-for="(item, index) in searchResults"
            :id="`search-assign-${index}`"
            :key="item"
            :class="
              keyActive == `search-assign-${index}` ? 'bg-current-50' : ''
            "
            class="
              flex
              dropdown-item
              items-center
              space-x-2
              px-2
              py-1
              text-gray-700
              hover:bg-current-50
              cursor-pointer
              syn-select-assign-item
              rounded-md
            "
            @click.stop="chooseItem(item)"
          >
            <span class="min-w-max relative">
              <UserById
                v-if="type == 'user'"
                :user-id="item?.id"
                is-hidden-name
                class="syn-select-assign-item"
                avatar-class="h-8 w-8"
              />

              <SynAvatarFavorite
                v-else
                :src="
                  item?.avatar?.replace('original', 'mini') ||
                  item?.avatarUrl?.replace('original', 'mini')
                "
                custom-class="w-8 h-8 syn-select-assign-item"
                :name="item?.name || name"
                :favorite-type="'group'"
                :is-deactive="item?.isDeactive"
              />
              <div
                v-if="type == 'group' && item?.isPrivate"
                :title="
                  item?.isPrivate
                    ? $t('COMMON_LABEL_GROUP_PRIVATE')
                    : $t('COMMON_LABEL_GROUP_PUBLIC')
                "
                class="
                  flex-center
                  bg-white
                  absolute
                  -bottom-1
                  -right-1
                  p-0.5
                  rounded-full
                "
              >
                <SynIcon
                  class="fill-current"
                  custom-class="w-2 h-2"
                  :name="item?.isPrivate ? 'private' : 'global'"
                />
              </div>
            </span>
            <div
              :title="item?.name"
              class="
                w-full
                py-2
                text-sm
                syn-select-assign-item
                flex
                items-center
                justify-between
              "
            >
              <span class="text-overflow-hidden-line syn-select-assign-item">
                {{
                  item?.name ||
                  item?.fullName ||
                  item?.lastName + ' ' + item?.firstName
                }}
              </span>
              <span v-if="item.id == currentUser.id" class="text-xs italic">
                {{ $t('COMMON_LABEL_ME') }}
              </span>
              <span
                v-else-if="item.adminType == AdminType.Visitor"
                class="text-xs italic"
              >
                {{ $t('COMMON_LABEL_VISITOR') }}
              </span>
            </div>
          </li>
          <li v-if="!searchResults?.length" class="p-6 text-center">
            <SynAnimation
              name="searchIcon"
              :loop="false"
              stype="width: 100px; height: 100px"
              :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
            />
          </li>
        </ul>
      </div>
    </template>
  </VigDropdown>
</template>

<style scoped>
.mh-48 {
  max-height: 15rem;
}
</style>
