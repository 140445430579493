import { AdminType } from '@/ui/common/constants/constant';

export const adminTypeStyle = {
    [AdminType.Owner]: {
        key: AdminType.Owner,
        labelKey: 'COMMON_LABEL_OWNER',
        backgroundColor: 'bg-orange-450',
        code: 'owner',
        order: 1,
    },
    [AdminType.Admin]: {
        key: AdminType.Admin,
        labelKey: 'USERMANAGEMENT_FORM_TYPE_ADMIN',
        backgroundColor: 'bg-orange-400',
        code: 'admin',
        order: 2,
    },
    [AdminType.User]: {
        key: AdminType.User,
        labelKey: 'USERMANAGEMENT_FORM_TYPE_USER',
        backgroundColor: 'bg-current',
        code: 'user',
        order: 3,
    },
    [AdminType.Visitor]: {
        key: AdminType.Visitor,
        labelKey: 'USERMANAGEMENT_FORM_TYPE_VISITOR',
        backgroundColor: 'bg-gray-400',
        code: 'visitor',
        order: 4,
    },
};

export const timeUtc = [
    {
        utc: '+7',
        label: 'USERMANAGEMENT_FORM_TIME_UTC_VIETNAM',
        nameIcon: 'flag-vietnam',
        text: 'Viet Nam (GMT +7)',
    },
    {
        utc: '+2',
        label: 'USERMANAGEMENT_FORM_TIME_UTC_FRANCE',
        nameIcon: 'flag-france',
        text: 'France (GMT +2)',
    },
    {
        utc: '-5',
        label: 'USERMANAGEMENT_FORM_TIME_UTC_UNITED_STATE',
        nameIcon: 'flag-united-state',
        text: 'Hoa Ky (GMT -5)',
    },
];
export const statusActive = [
    {
        status: null,
        label: 'COMMON_LABEL_ALL',
        name: 'All',
        key: '',
    },
    {
        status: true,
        label: 'USERMANAGEMENT_FORM_ACTIVE',
        name: 'Active',
        key: 1,
    },
    {
        status: false,
        label: 'USERMANAGEMENT_FORM_DEACTIVE',
        name: 'Deactive',
        key: 0,
    },
];
export const statusOptions = [
    {
        status: true,
        viewLabel: 'USERMANAGEMENT_LABEL_ACTIVATE_USER',
        label: 'USERMANAGEMENT_FORM_ACTIVE',
        name: 'Active',
        key: 1,
    },
    {
        status: false,
        viewLabel: 'USERMANAGEMENT_LABEL_DEACTIVATE_USER',
        label: 'USERMANAGEMENT_FORM_DEACTIVE',
        name: 'Deactive',
        key: 0,
    },
];
