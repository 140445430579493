import { initializeApp } from 'firebase/app';
import {
    initializeFirestore,
    CACHE_SIZE_UNLIMITED,
    persistentLocalCache,
    persistentMultipleTabManager,
} from 'firebase/firestore';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import { getMessaging, isSupported } from 'firebase/messaging';
import { VITE_FIREBASE } from '@/ui/plugins/firebases/firebaseConfig';
import {
    getAnalytics,
    setAnalyticsCollectionEnabled,
} from 'firebase/analytics';

// Init app
export const firebaseApp = initializeApp(VITE_FIREBASE);

// Init Firestore
export const firestoreDb = initializeFirestore(firebaseApp, {
    localCache: persistentLocalCache({
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
        tabManager: persistentMultipleTabManager(),
    }),
});

// Remote config
export const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings = {
    minimumFetchIntervalMillis: parseInt(
        import.meta.env.VITE_FETCH_REMOTE_CONFIG_TIME_MS || ''
    ),
    fetchTimeoutMillis: 60000,
};
fetchAndActivate(remoteConfig);

// FCM
export const getMessagingApp = async () => {
    const supported = await isSupported();
    console.log('init messaging again!!!', supported);
    return supported ? getMessaging(firebaseApp) : null;
};

// Analysic
const analytics = getAnalytics(firebaseApp);
setAnalyticsCollectionEnabled(analytics, true);
