<script setup lang="ts">
import { computed, ref } from 'vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import {
  TaskApprovalStatus,
  ITaskApprover,
} from '@/application/types/task/task.types';
import myProfileStore from '@/store/auth/my-profile';
import UserById from '@/ui/components/user/UserById.vue';
import TaskApprovalModal from '@/ui/modules/task/approval/TaskApprovalModal.vue';

const props = withDefaults(
  defineProps<{
    task?: any;
    approvalStatus?: TaskApprovalStatus;
    loadingStatus?: TaskApprovalStatus;
    approvers?: ITaskApprover[];
    approverId?: number;
  }>(),
  {}
);

const emit = defineEmits(['onChangeApprovalStatus']);

const myProfile = computed<any>(() => {
  return myProfileStore().myProfile;
});

const isApprover = computed<any>(() => {
  return props.approvers?.some((user) => user?.userId == myProfile.value?.id);
});

const onChangeApprovalStatus = (comment) => {
  isOpenConfirmModal.value = false;
  emit('onChangeApprovalStatus', approvalStatusConfirm.value, comment);
};

const isOpenConfirmModal = ref<boolean>(false);
const approvalStatusConfirm = ref<TaskApprovalStatus>(
  TaskApprovalStatus.APPROVED
);
const onOpenConfirmApproval = (state: TaskApprovalStatus) => {
  approvalStatusConfirm.value = state;
  isOpenConfirmModal.value = true;
};

const approver = computed<any>(() => {
  return props.approvers && props.approvers?.length > 0
    ? props.approvers[0]
    : {};
});
</script>

<template>
  <div v-if="approvalStatus" class="flex-1 flex items-center pl-2">
    <template v-if="approvalStatus == TaskApprovalStatus.WAITING">
      <div v-if="isApprover" class="w-full flex-center gap-4">
        <div
          class="
            flex-center
            space-x-1.5
            py-2
            px-2
            rounded-md
            bg-orange-50
            cursor-pointer
          "
          @click="onOpenConfirmApproval(TaskApprovalStatus.REJECTED)"
        >
          <div class="w-3.5 h-3.5 rounded-md flex-center">
            <SynIcon
              v-if="loadingStatus == TaskApprovalStatus.REJECTED"
              name="spinner"
              custom-class="w-4 h-4 fill-orange-500"
              class="animate-spin"
            />
            <SynIcon
              v-else
              name="close"
              custom-class="w-4 h-4 fill-orange-500"
            />
          </div>
          <span class="text-sm text-orange-500">
            {{ $t('COMMON_LABEL_REJECT') }}
          </span>
        </div>
        <div
          class="
            flex-center
            space-x-1.5
            py-2
            px-2
            rounded-md
            bg-current
            cursor-pointer
          "
          @click="onOpenConfirmApproval(TaskApprovalStatus.APPROVED)"
        >
          <div class="rounded-md flex-center">
            <SynIcon
              v-if="loadingStatus == TaskApprovalStatus.APPROVED"
              name="spinner"
              custom-class="w-4 h-4 fill-white text-white"
              class="animate-spin"
            />
            <SynIcon
              v-else
              name="approval"
              custom-class="w-4 h-4 fill-white text-white"
            />
          </div>
          <span class="text-sm text-white">
            {{ $t('COMMON_LABEL_APPROVAL') }}
          </span>
        </div>
      </div>
      <div v-else class="flex-center space-x-1.5">
        <span
          class="text-xs text-gray-500"
          v-html="$t('TASK_LABEL_WAITING_FOR_APPROVAL_BY')"
        >
        </span>
        <UserById
          :user-id="approver?.userId"
          class="text-xs text-gray-500"
          avatar-class="w-5 h-5"
        />
      </div>
    </template>
    <template v-if="approvalStatus == TaskApprovalStatus.APPROVED">
      <div
        class="flex-center space-x-1.5 py-1 pr-2 pl-1 rounded-full bg-green-500"
      >
        <div class="rounded-full flex-center">
          <SynIcon
            name="approval"
            custom-class="w-4 h-4 fill-white text-white"
          />
        </div>
        <UserById
          v-if="approverId"
          :user-id="approverId"
          class="text-white text-xs"
          avatar-class="w-5 h-5"
        />
        <span class="text-xs text-white">đã phê duyệt </span>
      </div>
    </template>
    <template v-if="approvalStatus == TaskApprovalStatus.REJECTED">
      <div
        class="flex-center space-x-1.5 py-1 pr-2 pl-1 rounded-full bg-red-500"
      >
        <div class="rounded-full flex-center">
          <SynIcon name="close" custom-class="w-4 h-4 fill-white text-white" />
        </div>
        <UserById
          v-if="approverId"
          :user-id="approverId"
          class="text-white text-xs"
          avatar-class="w-5 h-5"
        />
        <span class="text-xs text-white">đã từ chối </span>
      </div>
    </template>
  </div>
  <TaskApprovalModal
    v-if="isOpenConfirmModal"
    :task="task"
    :approval-status="approvalStatusConfirm"
    view-mode="APPROVE_OR_REJECT"
    @on-close="isOpenConfirmModal = false"
    @confirm="onChangeApprovalStatus"
  />
</template>
