<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    value: any;
    name: string;
    label?: string;
    disabled?: boolean;
    readonly?: boolean;
    align?: 'center' | 'start';
  }>(),
  {
    align: 'start',
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const currentValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <label
    class="cursor-pointer"
    :class="[
      disabled ? 'opacity-60 pointer-events-none' : '',
      readonly ? 'pointer-events-none' : '',
    ]"
  >
    <span class="flex items-center gap-2" :class="'justify-' + align">
      <input
        v-model="currentValue"
        :disabled="disabled"
        :name="name"
        :value="value"
        type="radio"
        class="
          cursor-pointer
          text-current
          focus:ring-1 focus:ring-current focus:bg-current-50
          hover:bg-current-50
        "
        :class="{ 'opacity-60': disabled || readonly }"
        @change="$emit('update:modelValue', value)"
      />

      <slot>
        <span v-if="label">{{ $t(label) || label }}</span>
      </slot>
    </span>
  </label>
</template>
