<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m6 22v-3a9.011 9.011 0 0 1 9-9h3v3a4 4 0 0 0 8 0v-3h12v3a4 4 0 0 0 8 0v-3h3a9.011 9.011 0 0 1 9 9v3zm14-9a2 2 0 0 0 4 0v-5a2 2 0 0 0 -4 0zm20-5v5a2 2 0 0 0 4 0v-5a2 2 0 0 0 -4 0zm18 16v21a9.011 9.011 0 0 1 -9 9h-34a9.011 9.011 0 0 1 -9-9v-21zm-18.693 11.309h-4.129l-1.278-3.927a2 2 0 0 0 -3.8 0l-1.276 3.927h-4.131a2 2 0 0 0 -1.176 3.618l3.341 2.427-1.276 3.927a2 2 0 0 0 3.078 2.236l3.34-2.427 3.34 2.427a2 2 0 0 0 3.078-2.236l-1.276-3.927 3.341-2.427a2 2 0 0 0 -1.176-3.618z"
      ></path>
    </g>
  </svg>
</template>
